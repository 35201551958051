import React, { useState } from 'react';


export default function RecursiveQuestion(props) {
    const {
        localString, questionArr, background = false
    } = props;


    return (
        <div>
            {questionArr && (questionArr.length > 0) && questionArr.map((question, questionIndex) => <div>
                { question.questionPhrase && <div className='labelText'>
                    {`${!background ? String.fromCharCode(("a".charCodeAt(0))+questionIndex) + '.' : ''} `}{question.questionPhrase}
                </div>}
                { question.isSelect && question?.answers?.length && question?.answers.map((ansObj, ansKey) => <div className={background ? 'ansDiv' : ''}>
                    { ansObj.answerPhrase && <div className=''>
                        {ansObj.answerPhrase}
                    </div>}
                    { ansObj.isSelect && ansObj?.questions?.length && <div style={{ paddingLeft: '20px' }}>
                        <RecursiveQuestion 
                            localString={localString}
                            questionArr={ansObj.questions}
                        /> 
                    </div>}
                </div>)}
            </div>)}
        </div>
    );
}