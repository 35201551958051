import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import {find,isEmpty,findIndex} from 'lodash'
import { returnTimezone,displayError,returnLocalDateTime,SERVICETYPE ,returnLocalSlot,checkEncryption,encrypt} from '../../../../Utility/Utils';
import './NavigationBar.scss';
import RequestConsumerDetails from './ConsumerDetails/RequestConsumerDetails';
import RequestDetails from './RequestDetails/RequestDetails';
import DeviceDetails from './DeviceDetailsTab/DeviceDetails';
import TradeInDetails from './Trade-In/TradeInDetails';
import TradeInDepreciation from './Trade-In/TradeInDepreciation';
import ThirdPartyCharges from './EstimationDetails/ThirdPartyCharges';
import OtherRequest from './OtherRequests/OtherRequest';
import OverrideApproval from './OverrideApproval/OverrideApproval';
import IssueValidation from './IssueValidation/IssueValidation';
import VisitDetails from './VisitDetails/VisitDetails';
import SymptomFaultAction from './SymptomFaultAction/SymptomFaultAction';
import ViewUploadDocuments from './ViewUploadDocumets/ViewUploadDocuments';
import AddCharges from './EstimationDetails/AddCharges';
import AdjudicationChecklist from './AdjudicationChecklist/AdjudicationChecklist';
import ChargeDetails from './ChargeDetails/ChargeDetails';
import RequestDocuments from './RequestDocuments/RequestDocuments';
import BillingDetails from './BillingDetails/BillingDetails';
import AddressDetails from './AddressDetails/AddressDetails';
import PartsRequested from './PartsRequested/PartsRequested';
import TaskList from './TaskList/TaskList';
import DeadOnArrival from './DeadOnArrival/DeadOnArrival';
import LogisticsDetails from './LogisticsDetails/LogisticsDetails';
import SupportingDocuments from './SupportingDocuments/SupportingDocuments';
import ReplacementDetails from './ReplacementDetails/ReplacementDetails';
import ReplacementDeviceDetails from './ReplacementDeviceDetails/ReplacementDeviceDetails';
import PackagingMaterialDetails from './PackagingMaterialDetails/PackagingMaterialDetails';
import BuyoutDetails from './BuyoutDetails/BuyoutDetails';
import RequestCommunicationHistory from './RequestCommunicationHistory/RequestCommunicationHistory';
import RequestConsumerWrapper from './ConsumerDetails/RequestConsumerWrapper';
import LoanerWrapper from './LoanerDetails/LoanerWrapper';
import AdminRaiseRequest from './AdminRaiseRequest/AdminRaiseRequest';
import QuestionLookupTab from './QuestionLookupTab/QuestionLookupTab';
import TransferDetails from './TransferDetails/TransferDetails';
import IntegrationLogDetails from './IntegrationLogDetails/IntegrationLogDetails'
import ProvisionalEstimate from './ProvisionalEstimate/ProvisionalEstimate'
import ActualCost from './ActualCost/ActualCost';
import CommunicationHistory from './RedesignCommunicationHistory/CommunicationHistory';
import EstimationDetails from './NewEstimationDetails/EstimationDetails';
import PartnerStatusUpdate from './PartnerStatusUpdate/PartnerStatusUpdate';
import RefurbishmentDetails from './RefurbishmentDetails/RefurbishmentDetails';
import DispositionDetails from './DispositionDetails/DispositionDetails';
import PaymentDetailsTab from './PaymentDetailsTab/PaymentDetailsTab';
import FinishedGoodsDetails from './FinishedGoodsDetails/FinishedGoodsDetails';
import CaseScore from "../../../../components/CaseScore/CaseScore"
import PageContentWrapper from "../../../../components/PageContentWrapper/PageContentWrapper"
import RemarkDetails from './RemarkTab/RemarkDetails';
import RequestProof from '../../../../components/RequestProof/RequestProof';

const NavigationBar = (props) => {
	const {
		accessFn, localString, consumerDetails, consumerServiceRequest, qFilter, currencySymbol, getRequestById,
		canUpdateServiceRequest, basdefaultCountryLocaleeUrl, requestDeailsID, coverageDetails, checkPaymentStatus,
		checkDecisionMatrixProcessCompleted, defaultCountryLocale, isEmptyBoxServiceable, adjudicationChecklistData,
		showRequestLogs, showRequestValid, consumerProductDocuments, getProductDetails, getInsuranceDetails, loanerDeviceList,
		insurancePlans, getTradeInDetails, tradeInDetails, getTradeInDashboardDetails, tradeInDashboardDetails, highRiskReason,
		selectedBillingDetail, countriesChecks, addedFaultActions, setConsumerServiceRequest, getBillingDetails, requestSFA,
		getPartnerSymptomFaultAction, retrieveAllDocs, getDocumentsMaster, getPendingDocuments, prepareAddressString, getTaskValues,
		logisticsRequestDetailsImage,deviceTransferData,showViewRequestFlags,soldPlanCoverage,claimDamageId,planID,partsRequested,
		setPartsRequested,exception,getCurrencyMapping,currencyData,getPartsForRequest,isSubscriptionEnabled,
		setActiveTab, activeTab, onsiteChecks, actualCost, exceptionLogs,partsList,addedFaultCodes,getPartsList,getFaultList,
		checkOnsiteEwReq, getRefurbishmentRequestDetails, appleDropOffFlow, refurbishmentFlowCheck, apiCall, getCaseScores,
		scoreDetails,setScoreDetails
	} = props;

	const scrollSpyButtons = {
        consumerDetails: true,
        requestDetails: true,
        deviceDetails: true,
        otherRequests: true
    }

	// const scrollspyheaders = (options)=> {
    //     let optionKeys = Object.keys(options);
    //     forEach(optionKeys, (key)=>{
    //         scrollSpyButtons[key] = options[key]
    //     });

    //     // self.update();

    //     // $('[data-spy="scroll"]').each(function () {
    //     // 	var $spy = $(this).scrollspy('refresh')
    //     // })
    // };

	const showClickedTab = (e)=>{
		if(e){
			switch (e.target.id) {
				case "consumer_detail_tab":
					setActiveTab({showConsumrDetails:true})
					//self.fetchConsumerDetail();
					break;
				case "other_requests_tab":
					// setActiveTab({...activeTab,['showOtherRequest']:true})
					setActiveTab({showOtherRequest:true})
					// getServiceHistoryByBrand();
					break;
				case "access_point_tab":
					setActiveTab({showAccessPoint:true})
					break;
				case "parts_tracking_tab":
					// self.getPartLogisticsDetail();
					setActiveTab({showPartsTracking:true})
					break;
				case "consumer_address_tab":
					prepareAddressString();
					setActiveTab({showConsumrAddress:true})
					break;
				case "case_score_tab":
					setActiveTab({showCaseScore:true})
					break;
				case "raise-servicerequest_tab":
					setActiveTab({showRaiseRequest:true})
					break;
				case "device_details_tab":
					setActiveTab({showDeviceDetails:true})
					getProductDetails();
					break;
				case "request_documents_tab":
					setActiveTab({showRequestDocuments:true})
					getDocumentsMaster();
					getPendingDocuments();
					break;
				case "request_proof_tab":
					setActiveTab({showRequestProof: true})
					//getDocumentsMaster();
					//getPendingDocuments();
					break;
				case "supporting_documents_tab":
					setActiveTab({showSupportingDocuments:true})
					getDocumentsMaster();
					break;
				case "issue_validation_tab":
					setActiveTab({showIssueValidation:true})
					// if(self.accessFn('GSX_FLOW')){
						// self.getFaultList();
					// }
					break;
				case "symptom_fault_action_tab":
					setActiveTab({showSymtomFaultAction:true})
					// self.tags['symptom_fault_action'].getPartnerSymptomFaultAction();
					break;
				case "send_issue_gsx_tab":
					setActiveTab({showSendIssueGSX:true})
					// self.getFaultList();
					break;
				case "add_charges_tab":
					setActiveTab({showPartEstimation:true})
					if(accessFn('GSX_FLOW')) {
						getFaultList();
					}

					if(accessFn('WORKSHOP_FLOW')){
						if(showRequestLogs.isWorkStarted) {
					        if(consumerServiceRequest.ServiceTypeID !== SERVICETYPE.TRADE_IN_DROPOFF) {
					            getPartsList();
					        }
					    }else if ([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && (showRequestLogs.isPartsPending || showRequestLogs.isPartRequestCreated)) {
					        getPartsList();
					    }
					}
					// if(self.tags['add_charges_workshop']){
					//     self.tags['add_charges_workshop'].sendIssuesGSXFlag = self.sendIssuesGSXFlag;
					// }
					// if(self.accessFn('GSX_FLOW')){
					//     self.getFaultList();
					// }

					// if(self.accessFn('WORKSHOP_FLOW')) {
					//     var isWorkStarted = _.find(self.consumerServiceRequest.logs, {Status: 'Work in progress'});
					//             var isPartsPending = _.find(self.consumerServiceRequest.logs, {Status: 'Parts pending'});
					//             var isPartRequestCreated = _.find(self.consumerServiceRequest.logs, {Status: 'Parts request accepted'});
					//     if (isWorkStarted) {
					//         if(self.consumerServiceRequest.ServiceTypeID !== self.ServiceTypeConstants.TRADE_IN_DROPOFF) {
					//             self.getPartsList();
					//         }
					//     } else if ([3,5].indexOf(self.consumerServiceRequest.ServiceTypeID) > -1 && isPartsPending ) {
					//         self.getPartsList();
					//     }
					//     else if ([3,5].indexOf(self.consumerServiceRequest.ServiceTypeID) > -1 && isPartRequestCreated) {
					//         self.getPartsList();
					//     }
					// }
					break;
				case "override_approval_history_tab":
					setActiveTab({showOverrideApprovalHistory:true})
					break;
				case "parts_requested_tab":
					getCurrencyMapping();
					setActiveTab({showPartRequested:true})
					break;
				case "dead_arrival_tab":
					setActiveTab({showDeadOnArrival:true})
					// self.tags['dead_arrival'].initialiseDOADevice();
					break;
				case "request_details_tab":
					setActiveTab({showRequestDetails:true})
					// self.tags['request_details'].getConsumerServiceRequestDetails(self.consumerServiceRequest.ConsumerServiceRequestID);
					break;
				case "charge_details_tab":
					setActiveTab({showChargeDetails:true})
					break;
				case "visit_details_tab":
					setActiveTab({showVisitDetails:true})
					// self.tags['visit_details'].getVisitDetails();
					break;
				case "task_list_tab":
					setActiveTab({showTaskList:true})
					getTaskValues();
					break;
				case "logistics_details_tab":
					setActiveTab({showLogisticsDetails:true})
					break;
				// case "communication_history_tab":
				// 	setActiveTab({showCommunicationHistory:true})
				// 	// self.tags['communication_history'].getCommunicationLogs();
				// 	break;
				case "override_approval_tab":
					setActiveTab({showOverrideApproval:true})
					break;
				case "diagnosis_report_tab":
					setActiveTab({showDiagnosisReport:true})
					break;
				case "gst_info_tab":
					setActiveTab({ShowGstInfo:true})
					//getBillingDetails();
					break;
				case "mirror_test_tab":
					setActiveTab({showMirrorTestDetails:true})
					// self.tags['mirror_test'].getMirrorTestDetails();
					break;
				case "trade_in_depreciation_details_tab":
					setActiveTab({showDepreciationDetails:true})
					getTradeInDetails()
					// if (!tradeInDetails) {getTradeInDetails()}
					break;
				case "trade_in_details_tab":
					setActiveTab({showTradeInDetails:true})
					getTradeInDetails();
					getTradeInDashboardDetails()
					//if (!tradeInDetails) {getTradeInDetails()}
					break;
				case "view_upload_doc_tab":
					setActiveTab({showUploadDoc:true})
					retrieveAllDocs();
					break;
				case "provisional_estimation_tab":
					setActiveTab({showProvisionalEstimation:true})
					// self.getProvisionalCharges();
					break;
				case "replacement_details_tab":
					setActiveTab({ShowReplacementRequestDetails:true})
					// self.getReplacementRequestDetails();
					break;
				case "transfer_details_tab":
					setActiveTab({ShowTransferDetails:true})
					break;
				case "refurb_details_tab":
					setActiveTab({showRefurbishmentRequestDetails:true})
					getRefurbishmentRequestDetails();
					break;
				case "loaner_device_tab":
					setActiveTab({showLoanerDeviceDetails:true})
					break;
				case "buyout_details_tab":
					setActiveTab({ShowBuyoutDetails:true})
					// self.getReimbursements();
					break;
				case "upgraded_device_details_tab":
					setActiveTab({showUpgradedDeviceDetails:true})
					break;
				case "question_lookup_tab":
					setActiveTab({showQuestionLookup:true})
					// self.loadQuestionLookup();
					break;
				case "adjudication_checklist_tab":
					setActiveTab({showAdjudicationChecklist:true})
					break;
				case "packaging_material_details_tab":
					setActiveTab({showPackagingMaterialDetails:true})
					prepareAddressString();
					break;
				case "integration_logs_tab":
					setActiveTab({showIntegrationLogs:true})
					break;
				case "actual_cost_details_tab":
					setActiveTab({showActualChargeDetails:true})
					break;
				case "redesign_communication_history_tab":
					setActiveTab({showRedesignCommunicationHistory:true})//
					break;
				case "add_shipment_details_tab":
					setActiveTab({showAddShipmentDetails:true})
					break;
				case "partner_status_update_tab":
					setActiveTab({showPartnerStatusUpdate:true})
					break;
				case "disposition_details_tab":
					setActiveTab({showDispositionDetails:true})
					break;
				case "payment_details_tab":
					setActiveTab({showPaymentDetails:true})
					break;
				case "finished_goods_details_tab":
					setActiveTab({showFinishedGoodsDetails: true})
					break;
				case "remarks_tab":
					setActiveTab({showRemarks: true})
					break;
				default:
				break;
			}
		}else{
		}
	}

	const navbarArray = [
		{
			buttonName:localString("Consumer Details"),
			showButton:consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62,SERVICETYPE.REFUND_CROMA,SERVICETYPE.ESCALATION].indexOf(consumerServiceRequest.ServiceTypeID)>-1 && !accessFn('REQUEST_CONSUMER_DETAILS') && !(accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_SH')),
			customClass:(activeTab.showConsumrDetails?'active tab-li' : 'tab-li'),//showConsumrDetails
			listID:"consumer_details_li",
			tabID:"consumer_detail_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Consumer Details"),
			showButton:consumerServiceRequest && accessFn('REQUEST_CONSUMER_DETAILS') && scrollSpyButtons.consumerDetails && (accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_SH') || [9,13,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1),
			customClass:(activeTab.showConsumrDetails?'active tab-li' : 'tab-li'),//showConsumrDetails
			listID:"consumer_details_li",
			tabID:"consumer_detail_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Access Point"),
			showButton:consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,56,61,62].indexOf(consumerServiceRequest.ServiceTypeID)>-1 && ['VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 && accessFn('WORKSHOP_FLOW'),
			customClass:(activeTab.showAccessPoint?'active tab-li' : 'tab-li'), //showAccessPoint
			listID:"access_point_li",
			tabID:"access_point_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Parts Tracking"),
			showButton:consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,56,61,62].indexOf(consumerServiceRequest.ServiceTypeID)>-1 && ['VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 && accessFn('WORKSHOP_FLOW'),
			customClass:(activeTab.showPartsTracking?'active tab-li' : 'tab-li'),//showPartsTracking
			listID:"parts_tracking_li",
			tabID:"parts_tracking_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Address Details"),
			showButton:consumerDetails && (!accessFn('WORKSHOP_FLOW') || (accessFn('WORKSHOP_FLOW') && accessFn('ACCEPT_RETURN_DEVICE'))) && (accessFn('VIEW_QUEUE_ADMIN')  || accessFn('VIEW_QUEUE_SH') || accessFn('CONSUMER_ADDRESS_DETAILS')) && ([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,9,13,61,62,SERVICETYPE.TRADE_IN_DROPOFF,SERVICETYPE.DEFECTIVE_REFURBISHMENT].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && !accessFn('HIDE_ADDRESS_DETAILS_TAB'),
			customClass:(activeTab.showConsumrAddress?'active tab-li' : 'tab-li'),//showConsumrAddress
			listID:"consumer_address_li",
			tabID:"consumer_address_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Raise Request"),
			showButton:['In-principle approved'].indexOf(consumerServiceRequest.Status) > -1 && accessFn('RAISE_CLAIM_ADMIN_REQUEST') && (isSubscriptionEnabled || checkPaymentStatus()) && checkDecisionMatrixProcessCompleted(),
			customClass:(activeTab.showRaiseRequest?'active tab-li' : 'tab-li'),//showRaiseRequest
			listID:"servicerequest_li",
			tabID:"raise-servicerequest_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Device Details"),
			showButton:(accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_SH') || [9,13,18].indexOf(consumerServiceRequest.ServiceTypeID) == -1) || ([18].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && ['Enquiry initiated'].indexOf(consumerServiceRequest.Status)==-1),
			customClass:(activeTab.showDeviceDetails?'active tab-li' : 'tab-li'),//showDeviceDetails
			listID:"device_details_li",
			tabID:"device_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},

		},{
			buttonName:localString("Upgraded Device Details"),
			showButton:consumerServiceRequest && ([SERVICETYPE.CLAIM_DOOR_TO_DOOR_UPGRADE,SERVICETYPE.DOOR_TO_DOOR_UPGRADE,SERVICETYPE.PICKUP_UPGRADE,SERVICETYPE.CLAIM_UPGRADE_PICKUP,SERVICETYPE.MAIL_IN_UPGRAGE,SERVICETYPE.CLAIM_UPGRADE_MAIL_IN,SERVICETYPE.CLAIM_DOOR_TO_DOOR_REPLACEMENT,SERVICETYPE.CLAIM_REPLACEMENT_PICKUP,SERVICETYPE.CLAIM_REPLACEMENT_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && [SERVICETYPE.CLAIM_DOOR_TO_DOOR_UPGRADE,SERVICETYPE.DOOR_TO_DOOR_UPGRADE,SERVICETYPE.PICKUP_UPGRADE,SERVICETYPE.CLAIM_UPGRADE_PICKUP,SERVICETYPE.MAIL_IN_UPGRAGE,SERVICETYPE.CLAIM_UPGRADE_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1,
			customClass:(activeTab.showUpgradedDeviceDetails?'active tab-li' : 'tab-li'),//showUpgradedDeviceDetails
			listID:"upgraded_device_details_li",
			tabID:"upgraded_device_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Replacement Device Details"),
			showButton:consumerServiceRequest && ([SERVICETYPE.CLAIM_DOOR_TO_DOOR_UPGRADE,SERVICETYPE.DOOR_TO_DOOR_UPGRADE,SERVICETYPE.PICKUP_UPGRADE,SERVICETYPE.CLAIM_UPGRADE_PICKUP,SERVICETYPE.MAIL_IN_UPGRAGE,SERVICETYPE.CLAIM_UPGRADE_MAIL_IN,SERVICETYPE.CLAIM_DOOR_TO_DOOR_REPLACEMENT,SERVICETYPE.CLAIM_REPLACEMENT_PICKUP,SERVICETYPE.CLAIM_REPLACEMENT_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && [SERVICETYPE.CLAIM_DOOR_TO_DOOR_REPLACEMENT,SERVICETYPE.CLAIM_REPLACEMENT_PICKUP,SERVICETYPE.CLAIM_REPLACEMENT_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1,
			customClass:(activeTab.showUpgradedDeviceDetails?'active tab-li' : 'tab-li'),//showUpgradedDeviceDetails
			listID:"upgraded_device_details_li",
			tabID:"upgraded_device_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:accessFn('EDIT_REQUEST_DOCUMENTS') ? localString("View")+' / ' +localString("Upload Documents") : localString("View Documents"),
			showButton:consumerServiceRequest && ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && accessFn('GSX_FLOW'),
			customClass:(activeTab.showUploadDoc?'active tab-li' : 'tab-li'),//showUploadDoc
			listID:"view_upload_doc_li",
			tabID:"view_upload_doc_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Issue Validation"),
			showButton:(([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_DOOR_TO_DOOR_UPGRADE,SERVICETYPE.DOOR_TO_DOOR_UPGRADE,SERVICETYPE.CLAIM_DOOR_TO_DOOR_REPLACEMENT,SERVICETYPE.PICKUP_UPGRADE,SERVICETYPE.CLAIM_REPLACEMENT_PICKUP,SERVICETYPE.CLAIM_REPLACEMENT_MAIL_IN,SERVICETYPE.CLAIM_UPGRADE_PICKUP,SERVICETYPE.MAIL_IN_UPGRAGE,SERVICETYPE.CLAIM_UPGRADE_MAIL_IN,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.RECYCLE_PICK_UP,SERVICETYPE.RECYCLE_DROP_OFF,SERVICETYPE.DEFECTIVE_REFURBISHMENT].indexOf(consumerServiceRequest.ServiceTypeID)== -1) && !accessFn('WORKSHOP_FLOW') && checkOnsiteEwReq()),
			customClass:(activeTab.showIssueValidation?'active tab-li' : 'tab-li'),//showIssueValidation
			listID:"issue_validation_li",
			tabID:"issue_validation_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Issues Reported"),
			showButton:([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,9,13,61,62,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)== -1) && accessFn('WORKSHOP_FLOW') && !consumerServiceRequest.isAnchorRequest && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1) && ['VizioDashboard'].indexOf(consumerServiceRequest.Source) === -1 ,
			customClass:(activeTab.showIssueValidation?'active tab-li' : 'tab-li'),//showIssueValidation
			listID:"issue_validation_li",
			tabID:"issue_validation_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Symptom Fault Action"),
			showButton:(consumerServiceRequest && ([9,13].indexOf(consumerServiceRequest.ServiceTypeID)== -1) && accessFn('WORKSHOP_FLOW') && !accessFn('GSX_FLOW') && showRequestValid.isRequestSFANeeded),
			customClass:(activeTab.showSymtomFaultAction?'active tab-li' : 'tab-li'),//showSymtomFaultAction
			listID:"symptom_fault_action_li",
			tabID:"symptom_fault_action_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Estimation Details"),
			showButton:(accessFn('ADD_NORMAL_CHARGES') && ([9,SERVICETYPE.CLAIM,13,18,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1 || ([SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase))) && !accessFn('WORKSHOP_FLOW'),
			customClass:(activeTab.showPartEstimation?'active tab-li' : 'tab-li'),//showPartEstimation
			listID:"add_charges_li",
			tabID:"add_charges_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Parts and Estimation"),
			showButton:((accessFn('REASSIGN_JOBS') || accessFn('ACCEPT_RETURN_DEVICE') || accessFn('ADD_NORMAL_CHARGES')) && ([9,SERVICETYPE.CLAIM,13,18,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1 || ([SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase))) && accessFn('WORKSHOP_FLOW') && !(['applicable','replacement','replaced', 'certificate'].indexOf(consumerServiceRequest.DOAStatus) > -1) && ['VizioDashboard'].indexOf(consumerServiceRequest.Source) === -1,
			customClass:(activeTab.showPartEstimation?'active tab-li' : 'tab-li'),//showPartEstimation
			listID:"add_charges_li",
			tabID:"add_charges_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Override Approval History"),
			showButton:(([9,13].indexOf(consumerServiceRequest.ServiceTypeID)== -1) && showRequestValid.hasOverridden && accessFn('OVERRIDE_ESTIMATION_APPROVAL')) && accessFn('WORKSHOP_FLOW') && !(['applicable','replacement','replaced', 'certificate'].indexOf(consumerServiceRequest.DOAStatus) > -1),
			customClass:(activeTab.showOverrideApprovalHistory?'active tab-li' : 'tab-li'),//showOverrideApprovalHistory
			listID:"override_approval_history_li",
			tabID:"override_approval_history_tab",
			onButtonClick:()=>{
				showClickedTab()
			},

		},
		{
			buttonName:localString("Parts Requested"), //isWorkStarted
			showButton:(([9,13, 6, 7,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)== -1)) && accessFn('WORKSHOP_FLOW') && !(['applicable','replacement','replaced', 'certificate'].indexOf(consumerServiceRequest.DOAStatus) > -1) && ['VizioDashboard'].indexOf(consumerServiceRequest.Source) === -1 && (showRequestLogs.isWorkStarted || [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,56,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1),
			customClass:(activeTab.showPartRequested?'active tab-li' : 'tab-li'),//showPartRequested
			listID:"parts_requested_li",
			tabID:"parts_requested_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},

		},
		{
			buttonName:localString("Dead on Arrival"),
			showButton:(consumerServiceRequest && ([9,13].indexOf(consumerServiceRequest.ServiceTypeID)== -1) && (['applicable','replacement','replaced','certificate'].indexOf(consumerServiceRequest.DOAStatus) > -1)) && accessFn('WORKSHOP_FLOW'),
			customClass:(activeTab.showDeadOnArrival?'active tab-li' : 'tab-li'),//showDeadOnArrival
			listID:"dead_arrival_li",
			tabID:"dead_arrival_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Other Requests"),
			showButton:((((accessFn('CLAIM_HISTORY_TAB')) || (accessFn('REQUEST_HISTORY') && (accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_SH') || [SERVICETYPE.RECYCLE_PICK_UP,SERVICETYPE.RECYCLE_DROP_OFF,SERVICETYPE.ENQUIRY].indexOf(consumerServiceRequest.ServiceTypeID) == -1)) && ((([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.ENQUIRY,SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID)== -1) && !accessFn('WORKSHOP_FLOW')) || ((consumerServiceRequest && ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.ENQUIRY,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.TRADE_IN_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)== -1)) && accessFn('WORKSHOP_FLOW')))))),
			customClass:(activeTab.showOtherRequest?'active tab-li' : 'tab-li'),//showOtherRequest
			listID:"other_requests_li",
			tabID:"other_requests_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Request Details"),
			showButton:scrollSpyButtons && scrollSpyButtons.requestDetails,
			customClass:(activeTab.showRequestDetails?'active tab-li' : 'tab-li'),//showRequestDetails
			listID:"request_details_li",
			tabID:"request_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Decision Matrix Details"),
			showButton:consumerServiceRequest && consumerServiceRequest.decisionMatrixAttributes,
			customClass:(activeTab.showChargeDetails?'active tab-li' : 'tab-li'),//showRequestDetails
			listID:"charge_details_li",
			tabID:"charge_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Visit Details"),
			showButton: onsiteChecks,
			customClass:(activeTab.showVisitDetails?'active tab-li' : 'tab-li'),//showVisitDetails
			listID:"visit_details_li",
			tabID:"visit_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Tasks"),
			showButton: ((showRequestValid.showTaskTab) && ([SERVICETYPE.DEFECTIVE_REFURBISHMENT].indexOf(consumerServiceRequest.ServiceTypeID)== -1)),
			customClass:(activeTab.showTaskList?'active tab-li' : 'tab-li'),//showTaskList
			listID:"task_list_li",
			tabID:"task_list_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},

		},
		{
			buttonName:localString("Logistics Details"),
			showButton: (logisticsRequestDetailsImage && logisticsRequestDetailsImage.length > 0) && ([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.TRADE_IN_DROPOFF,SERVICETYPE.TRADE_IN_PICKUP].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('HIDE_LOGISTICS_DETAILS_TAB'),
			customClass:(activeTab.showLogisticsDetails?'active tab-li' : 'tab-li'),//showLogisticsDetails
			listID:"logistics_details_li",
			tabID:"logistics_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Override Approval"),
			showButton:consumerServiceRequest && consumerServiceRequest.Status == "Estimation approval" && ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,18,SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) < 0) && !accessFn('WORKSHOP_FLOW'),
			// showButton:true,
			customClass:(activeTab.showOverrideApproval?'active tab-li' : 'tab-li'),//showOverrideApproval
			listID:"override_approval_li",
			tabID:"override_approval_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},

		},
		{
			buttonName:localString("Override Approval"),
			showButton:(accessFn('OVERRIDE_ESTIMATION_APPROVAL') && consumerServiceRequest && consumerServiceRequest.Status == "Estimation approval" && ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,18,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) < 0)) && accessFn('WORKSHOP_FLOW'),
			customClass:(activeTab.showOverrideApproval?'active tab-li' : 'tab-li'),//showOverrideApproval
			listID:"override_approval_li",
			tabID:"override_approval_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},

		},
		// {
		//     buttonName:localString("Diagnosis Report"),
		//     showButton:((accessFn('REPAIR_DEVICE') || accessFn('QC_DEVICE')) && ([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)!==-1)) && accessFn('WORKSHOP_FLOW') && isDiagnosisCompletedFlag,
		//     customClass:(activeTab.showDiagnosisReport?'active tab-li' : 'tab-li'),//showDiagnosisReport
		//     listID:"diagnosis_report_li",
		//     tabID:"diagnosis_report_tab",
		//     onButtonClick:()=>{
		// 		showClickedTab()
		//     },

		// },
		{
			buttonName:localString("Replacement Details"),
			showButton:consumerServiceRequest && ([SERVICETYPE.BER_REPLACEMENT,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && (['BER replacement confirmed'].indexOf(consumerServiceRequest.Status) == -1),
			customClass:(activeTab.ShowReplacementRequestDetails?'active tab-li' : 'tab-li'),//ShowReplacementRequestDetails
			listID:"replacement_details_li",
			tabID:"replacement_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Transfer Details"),
			showButton:consumerServiceRequest.deviceTransferData && consumerServiceRequest.deviceTransferData.isTransferredRequest,
			customClass:(activeTab.ShowTransferDetails?'active tab-li' : 'tab-li'),//ShowTransferDetails
			listID:"transfer_details_li",
			tabID:"transfer_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Refurbishment Details"),
			showButton: ((([65].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && showRequestLogs.isRefurbishmentRequestCreated) || refurbishmentFlowCheck),
			customClass:(activeTab.showRefurbishmentRequestDetails ? 'active tab-li' : 'tab-li'),
			listID:"refurb_details_li",
			tabID:"refurb_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			}
		},{
			buttonName:localString("Reimbursement Details"),
			showButton:([SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && (['Reimbursement approved', 'Service completed'].indexOf(consumerServiceRequest.Status) > -1),
			customClass:(activeTab.ShowBuyoutDetails?'active tab-li' : 'tab-li'),//ShowBuyoutDetails
			listID:"buyout_details_li",
			tabID:"buyout_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Request Documents"),
			showButton:consumerServiceRequest && ([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.ONSITE_REPLACEMENT,SERVICETYPE.UNINSTALLATION,SERVICETYPE.INSPECTION,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('GSX_FLOW') && !accessFn('HIDE_REQUEST_DOCUMENTS') && showRequestValid.checkRequestedDocuments,// ([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,56,61,62,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1)
			customClass:(activeTab.showRequestDocuments?'active tab-li' : 'tab-li'),//showRequestDocuments
			listID:"request_documents_li",
			tabID:"request_documents_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Proof"),
			showButton: consumerServiceRequest && ([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.ONSITE_REPLACEMENT,SERVICETYPE.UNINSTALLATION,SERVICETYPE.INSPECTION,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('GSX_FLOW') && !accessFn('HIDE_REQUEST_DOCUMENTS') && accessFn('SHOW_PROOF_TAB') && showRequestValid.checkRequestedDocuments,// ([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,56,61,62,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1)
			customClass:(activeTab.showRequestProof?'active tab-li' : 'tab-li'),//showRequestProof
			listID:"request_proof_li",
			tabID:"request_proof_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Supporting Documents"),
			showButton:consumerServiceRequest && ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('GSX_FLOW') && !accessFn('HIDE_REQUEST_DOCUMENTS') && (['Supporting documents pending', 'Supporting document validation pending', 'Supporting documents required', 'Claim estimation generated'].indexOf(consumerServiceRequest.Status) > -1 || showRequestLogs.isSupportingDocsRequired),
			customClass:(activeTab.showSupportingDocuments?'active tab-li' : 'tab-li'),//showSupportingDocuments
			listID:"supporting_documents_li",
			tabID:"supporting_documents_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Billing Details"),
			showButton: consumerServiceRequest && countriesChecks.TaxDetailName && ((accessFn('ACCEPT_RETURN_DEVICE') && accessFn('WORKSHOP_FLOW'))|| !accessFn('WORKSHOP_FLOW')) && !consumerServiceRequest.IsWarrantyApplicable && !accessFn('HIDE_BILLING_DETAILS_TAB'),
			customClass:(activeTab.ShowGstInfo?'active tab-li' : 'tab-li'),//ShowGstInfo
			listID:"billing_details_li",
			tabID:"gst_info_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},

		},
		// {
		// 	buttonName:localString("Communication History"),
		// 	showButton:(accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_SH') || [9,13].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && !accessFn('HIDE_COMMUNICATION_HISTORY_TAB'),
		// 	customClass:(activeTab.showCommunicationHistory?'active tab-li' : 'tab-li'),//showCommunicationHistory
		// 	listID:"communication_history_li",
		// 	tabID:"communication_history_tab",
		// 	onButtonClick:(e)=>{
		// 		showClickedTab(e)
		// 	},
		// }
		,{
			buttonName:localString("Depreciation Details"),
			showButton:([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && JSON.parse(qFilter) && JSON.parse(qFilter).tradeInFeatureRights && JSON.parse(qFilter).tradeInFeatureRights.canAnalyseDep,
			customClass:(activeTab.showDepreciationDetails?'active tab-li' : 'tab-li'),//showDepreciationDetails
			listID:"trade_in_depreciation_details_li",
			tabID:"trade_in_depreciation_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Trade-in Details"),
			showButton:([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && JSON.parse(qFilter) && JSON.parse(qFilter).tradeInFeatureRights && JSON.parse(qFilter).tradeInFeatureRights.canAnalyseDep,
			customClass:(activeTab.showTradeInDetails?'active tab-li' : 'tab-li'),//showTradeInDetails
			listID:"trade_in_details_li",
			tabID:"trade_in_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Provisional Estimate"),
			showButton: (accessFn('PROVISIONAL_ESTIMATE') || (accessFn('REPAIR_DEVICE') && appleDropOffFlow && !consumerServiceRequest.ProvisionalGSXNumber)) && showRequestLogs.isDeviceReceived && !showRequestLogs.isServiceCancel,
			customClass:(activeTab.showProvisionalEstimation?'active tab-li' : 'tab-li'),//showProvisionalEstimation
			listID:"provisional_estimation_li",
			tabID:"provisional_estimation_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Guided Troubleshooting Details"),
			showButton:consumerServiceRequest && consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber,
			customClass:(activeTab.showQuestionLookup?'active tab-li' : 'tab-li'),//showQuestionLookup
			listID:"question_lookup_li",
			tabID:"question_lookup_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Loaner Details"),
			showButton: accessFn('LOANER_FLOW') && consumerServiceRequest && consumerServiceRequest.IsWarrantyApplicable && [9,13,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)== -1 && accessFn('WORKSHOP_FLOW') && accessFn('GSX_FLOW') && ['Service accepted','Payment received','QC pending','QC completed','Service invoice generated','Device received','Repair cancel','Repair cancel initiated','Device transferred','Provisional estimate generated','Provisional estimate updated'].indexOf(consumerServiceRequest.Status) === -1 ,
			customClass:(activeTab.showLoanerDeviceDetails?'active tab-li' : 'tab-li'),//showLoanerDeviceDetails
			listID:"loaner_device_li",
			tabID:"loaner_device_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Checklist"),
			showButton:consumerServiceRequest && adjudicationChecklistData && ((adjudicationChecklistData.ChecklistHistory && adjudicationChecklistData.ChecklistHistory.length) || (adjudicationChecklistData.NewChecklist && adjudicationChecklistData.NewChecklist.length)) && accessFn('VIEW_ADJUDICATION_CHECKLIST'),
			customClass:(activeTab.showAdjudicationChecklist?'active tab-li' : 'tab-li'),//showAdjudicationChecklist
			listID:"adjudication_checklist_li",
			tabID:"adjudication_checklist_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},{
			buttonName:localString("Packaging Material Details"),
			showButton:isEmptyBoxServiceable,
			customClass:(activeTab.showPackagingMaterialDetails?'active tab-li' : 'tab-li'),//showPackagingMaterialDetails
			listID:"packaging_material_details_li",
			tabID:"packaging_material_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			}
		},{
			buttonName: (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 65) ? localString("Add Shipment Details") : ([SERVICETYPE.DEFECTIVE_REFURBISHMENT].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)==-1) && localString("Shipment Details"),
			showButton:([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1  || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 46)),
			customClass:(activeTab.showAddShipmentDetails ? 'active tab-li' : 'tab-li'),//showActualChargeDetails
			listID:"add_shipment_details_li",
			tabID:"add_shipment_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			}
		},{
			buttonName:localString("Integration Logs"),
			showButton:accessFn('SHOW_INTEGRATION_LOGS'),
			customClass:(activeTab.showIntegrationLogs ? 'active tab-li' : 'tab-li'),//showIntegrationLogs
			listID:"integration_logs_li",
			tabID:"integration_logs_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			}
		},{
			buttonName:localString("Actual Cost"),
			showButton:actualCost && actualCost.length > 0,
			customClass:(activeTab.showActualChargeDetails ? 'active tab-li' : 'tab-li'),//showActualChargeDetails
			listID:"actual_cost_details_li",
			tabID:"actual_cost_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			}
		},{
			buttonName:localString("Partner Status Update"),
			showButton: ([11,12,23,17].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && consumerServiceRequest.ExternalReferenceID,
			customClass:(activeTab.showPartnerStatusUpdate ? 'active tab-li' : 'tab-li'),
			listID:"partner_status_update_li",
			tabID:"partner_status_update_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			}
		},
		{
			buttonName:localString("Communication History"),
			showButton:(accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_SH') || [9,13].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && !accessFn('HIDE_COMMUNICATION_HISTORY_TAB'),
			customClass:(activeTab.showRedesignCommunicationHistory?'active tab-li' : 'tab-li'),//showCommunicationHistory
			listID:"redesign_communication_history_li",
			tabID:"redesign_communication_history_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Disposition Details"),
			showButton: (([65].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && showRequestLogs.showDispositionDetailsSec),
			customClass:(activeTab.showDispositionDetails ? 'active tab-li' : 'tab-li'),
			listID:"disposition_details_li",
			tabID:"disposition_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},

		{
			buttonName: localString("Payment Details"),
			showButton: accessFn('DISPLAY_PAYMENT_DETAILS_TAB'),
			customClass: (activeTab.showPaymentDetails ? 'active tab-li' : 'tab-li'),
			listID: "payment_details_li",
			tabID: "payment_details_tab",
			onButtonClick:(e) => {
				showClickedTab(e)
			}
		},
		{
			buttonName:localString("Finished Goods Details"),
			showButton:(showRequestLogs.isServiceInitiated),
			customClass:(activeTab.showFinishedGoodsDetails ? 'active tab-li' : 'tab-li'),
			listID:"finished_goods_details_tli",
			tabID:"finished_goods_details_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Case Score"),
			showButton:(consumerServiceRequest.caseID && accessFn('SHOW_CASE_SCORE_ON_REQUEST')),
			customClass:(activeTab.showCaseScore ?'active tab-li' : 'tab-li'),
			listID:"case_score_li",
			tabID:"case_score_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		},
		{
			buttonName:localString("Remarks"),
			showButton:accessFn('FETCH_REMARKS_DETAILS_TAB'),
			customClass:(activeTab.showRemarks?'active tab-li' : 'tab-li'),//showCommunicationHistory
			listID:"remarks_li",
			tabID:"remarks_tab",
			onButtonClick:(e)=>{
				showClickedTab(e)
			},
		}
	]

	return (
		<>
			<div className="request-view-navbar">
				<div className="navbar content remove-border-radius-navbar" id="myNavbar">
					<div className="container-fluid remove-container-padding">
						<div className="navbar-header">
							<button type="button" className="navbar-toggle" data-toggle="collapse"
									data-target="#myNavbar">
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
						</div>
						<div>
							<div className="collapse navbar-collapse navbar-static-top remove-navbar-padding" id="myNavbar">
								<ul className="nav navbar-nav ul-nav">
									{
										navbarArray && navbarArray.map(navItem=>{
											return(
												navItem.showButton ?
												<li className={navItem.customClass} id={navItem.listID}>
												<a className="btn button-servify backtoreqbtn no-border-radius tab-buttons"
													id={navItem.tabID}
													onClick={(e)=>navItem.onButtonClick(e)}
													>{navItem.buttonName}
												</a>
											</li>:""
											)
										})
									}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="content">
				{
					activeTab.showConsumrDetails && consumerServiceRequest &&
					[SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62,SERVICETYPE.REFUND_CROMA,SERVICETYPE.ESCALATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&
					!accessFn('REQUEST_CONSUMER_DETAILS') && !(accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_SH')) &&
					//<RequestConsumerDetails
						//{...props}
						//consumerDetails={consumerDetails}
						//consumerServiceRequest={consumerServiceRequest}
					///>
					<RequestConsumerWrapper
						{...props}
						displayFullGrid={true}
					/>
				}
				{
					activeTab.showConsumrDetails && consumerServiceRequest &&
					(accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_SH') || [9,13].indexOf(consumerServiceRequest.ServiceTypeID) == -1) &&
					<RequestConsumerWrapper
						{...props}
						activeTab={activeTab}
						displayFullGrid={false}
					/>
				}
				{
					activeTab.showRequestDetails &&
					<RequestDetails
						{...props}
						consumerServiceRequest={consumerServiceRequest}
						requestDeailsID={consumerServiceRequest.ConsumerServiceRequestID}
						coverageDetails={coverageDetails.length >0 && coverageDetails}
						getRequestById={getRequestById}
						currencySymbol={currencySymbol}
						canUpdateServiceRequest={canUpdateServiceRequest}
						defaultCountryLocale={defaultCountryLocale}
					/>
				}
				{
					//(consumerServiceRequest && [ServiceTypeConstants.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1 && (accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_SH') || [9,13].indexOf(consumerServiceRequest.ServiceTypeID) == -1))
					activeTab.showDeviceDetails && (consumerServiceRequest && [SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1 && (accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_SH') || [9,13].indexOf(consumerServiceRequest.ServiceTypeID) == -1)) &&
					<DeviceDetails
						{...props}
						consumerServiceRequest={consumerServiceRequest}
						coverageDetails={coverageDetails.length >0 && coverageDetails}
						consumerProductDocuments={consumerProductDocuments}
						getRequestById={getRequestById}
						getProductDetails={getProductDetails}
						getInsuranceDetails={getInsuranceDetails}
						canUpdateServiceRequest={canUpdateServiceRequest}
						loanerDeviceList={loanerDeviceList}
						insurancePlans={insurancePlans}
						defaultCountryLocale={defaultCountryLocale}
						highRiskReason={highRiskReason}
						showClickedTab={showClickedTab}
						setActiveTab={setActiveTab}
					/>
				}
				{
					activeTab.showTradeInDetails &&
					<TradeInDetails
						{...props}
						consumerServiceRequest={consumerServiceRequest}
						tradeInDetails={tradeInDetails}
						defaultCountryLocale={defaultCountryLocale}
						tradeInDashboardDetails={tradeInDashboardDetails}
					/>
				}
				{
					activeTab.showDepreciationDetails &&
					<TradeInDepreciation
						{...props}
						consumerServiceRequest={consumerServiceRequest}
						tradeInDetails={tradeInDetails}
					/>
				}
				{
					activeTab.showPartEstimation && ([18].indexOf(consumerServiceRequest.ServiceTypeID)== -1) && !accessFn('WORKSHOP_FLOW') &&
					<>
						{
							consumerServiceRequest && consumerServiceRequest.externalThirdPartyCharges && consumerServiceRequest.externalThirdPartyCharges.length > 0 &&
							<ThirdPartyCharges
								{...props}
								consumerServiceRequest={consumerServiceRequest}
								currencySymbol={currencySymbol}
							/>
						}
						{/* {
							consumerServiceRequest && !accessFn("WORKSHOP_FLOW") && (accessFn('ADD_NORMAL_CHARGES') || accessFn('SHOW_ESTIMATE')) && ([9,10,13,18].indexOf(consumerServiceRequest.ServiceTypeID) == -1 || ([11,12,14,15,16,23,52,64].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase)) &&
							<AddCharges
								{...props}
								consumerServiceRequest={consumerServiceRequest} 
								currencySymbol={currencySymbol}
								exceptionLogs={exceptionLogs}
								addedFaultCodes={addedFaultCodes}
								isRequestSFANeeded={showRequestValid.isRequestSFANeeded}
								requestSFA={requestSFA}
								partsList={partsList}
								activeTab={activeTab} setActiveTab={setActiveTab}
								coverageDetails={coverageDetails.length >0 && coverageDetails}
							/>
						} */}
						{/* {
							consumerServiceRequest && accessFn("WORKSHOP_FLOW") && (accessFn('REASSIGN_JOBS') || accessFn('ACCEPT_RETURN_DEVICE') || accessFn('ADD_NORMAL_CHARGES')) && ([9,10,13,18].indexOf(consumerServiceRequest.ServiceTypeID) == -1 || ([11,12,14,15,16,23,52].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase)) && 
							<AddCharges
								{...props}
								consumerServiceRequest={consumerServiceRequest} 
								currencySymbol={currencySymbol}
								exceptionLogs={exceptionLogs}
								addedFaultCodes={addedFaultCodes}
								isRequestSFANeeded={showRequestValid.isRequestSFANeeded}
								requestSFA={requestSFA}
								partsList={partsList}
								activeTab={activeTab} setActiveTab={setActiveTab}
								coverageDetails={coverageDetails.length >0 && coverageDetails}
							/>
						} */}

					</>
				}
				{
					activeTab.showPartEstimation && ([SERVICETYPE.ENQUIRY].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && !accessFn('WORKSHOP_FLOW') &&
						<EstimationDetails
							{...props}
							isWorkshopFlow={false}
							titleHeader={localString("Estimation Details")}
							consumerServiceRequest={consumerServiceRequest} 
							currencySymbol={currencySymbol}
							exceptionLogs={exceptionLogs}
							addedFaultCodes={addedFaultCodes}
							isRequestSFANeeded={showRequestValid.isRequestSFANeeded}
							requestSFA={requestSFA}
							partsList={partsList}
							getPartsList={getPartsList}
							getPartsForRequest={getPartsForRequest}
							activeTab={activeTab} setActiveTab={setActiveTab}
							coverageDetails={coverageDetails.length >0 && coverageDetails}
							planID={planID}
						/>
				}
				{
					activeTab.showPartEstimation && (([SERVICETYPE.ENQUIRY, SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1 && !(['applicable','replacement','replaced','certificate'].indexOf(consumerServiceRequest.DOAStatus) > -1)) && accessFn('WORKSHOP_FLOW') && ['VizioDashboard'].indexOf(consumerServiceRequest.Source) === -1) &&
						<EstimationDetails
							{...props}
							isWorkshopFlow={true}
							titleHeader={localString("Parts and Estimation")}
							consumerServiceRequest={consumerServiceRequest} 
							currencySymbol={currencySymbol}
							exceptionLogs={exceptionLogs}
							addedFaultCodes={addedFaultCodes}
							isRequestSFANeeded={showRequestValid.isRequestSFANeeded}
							requestSFA={requestSFA}
							partsList={partsList}
							activeTab={activeTab} setActiveTab={setActiveTab}
							coverageDetails={coverageDetails.length >0 && coverageDetails}
							planID={planID}
						/>
				}
				{
					activeTab.showOtherRequest && ((accessFn('CLAIM_HISTORY_TAB')) || ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.ENQUIRY,SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID)== -1)) &&
					// ((([18].indexOf(consumerServiceRequest.ServiceTypeID)== -1) && !accessFn('WORKSHOP_FLOW')) || ((consumerServiceRequest && ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,18,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.TRADE_IN_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)== -1)) && accessFn('WORKSHOP_FLOW'))) &&
					// [SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,18,SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID)== -1 &&
					<OtherRequest
						{...props}
						consumerServiceRequest={consumerServiceRequest}
						productNotMapped={showRequestValid.productNotMapped}
						canUpdateServiceRequest={canUpdateServiceRequest}
						consumerDetail={consumerDetails}
					/>
				}
				{
					activeTab.showOverrideApproval &&
					<OverrideApproval
						{...props}
						canUpdateServiceRequest={canUpdateServiceRequest}
						consumerServiceRequest={consumerServiceRequest}
						selectedBillingDetail={selectedBillingDetail}
						TaxDetailName={countriesChecks.TaxDetailName}
						getRequestById={getRequestById}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						getBillingDetails={getBillingDetails}
					/>
				}
				{

					// Workshop=> // ([3,5,6,7,9,13,61,62,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)== -1) && accessFn('WORKSHOP_FLOW'))
					activeTab.showIssueValidation &&
					<IssueValidation
						{...props}
						consumerServiceRequest={consumerServiceRequest}
						canUpdateServiceRequest={canUpdateServiceRequest}
						isRequestSFANeeded={showRequestValid.isRequestSFANeeded}
						getRequestById={getRequestById}
						addedFaultActions={addedFaultActions}
						setConsumerServiceRequest={setConsumerServiceRequest}
					/>
				}
				{
					activeTab.showVisitDetails &&
					<VisitDetails
						{...props}
						defaultCountryLocale={defaultCountryLocale}
						consumerServiceRequest={consumerServiceRequest}
					/>
				}
				{
					activeTab.showSymtomFaultAction &&
					<SymptomFaultAction
						{...props}
						isRequestSFANeeded={showRequestValid.isRequestSFANeeded}
						requestSFA={requestSFA}
						getPartnerSymptomFaultAction={getPartnerSymptomFaultAction}
						getRequestById={getRequestById}
					/>
				}
				{
					activeTab.showUploadDoc &&
					<ViewUploadDocuments
						{...props}

					/>
				}
				{
					activeTab.showAdjudicationChecklist &&
					<AdjudicationChecklist
						{...props}
						adjudicationChecklistData={adjudicationChecklistData}
					/>
				}
				{
					activeTab.showChargeDetails &&
					<ChargeDetails
						{...props}
						consumerServiceRequest={consumerServiceRequest}
						currencySymbol={currencySymbol}
					/>
				}
				{
					activeTab.showRequestDocuments && consumerServiceRequest && (([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.ONSITE_REPLACEMENT,SERVICETYPE.UNINSTALLATION,SERVICETYPE.INSPECTION,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('GSX_FLOW') && !accessFn('HIDE_REQUEST_DOCUMENTS') && showRequestValid.checkRequestedDocuments) &&
					<RequestDocuments
						{...props}
					/>
				}
				{
					activeTab.showRequestProof && consumerServiceRequest && (([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.ONSITE_REPLACEMENT,SERVICETYPE.UNINSTALLATION,SERVICETYPE.INSPECTION,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('GSX_FLOW') && !accessFn('HIDE_REQUEST_DOCUMENTS') && accessFn('SHOW_PROOF_TAB') && showRequestValid.checkRequestedDocuments) &&
					<PageContentWrapper 
						bodyClassName="panel-body-no-padding" 
						title={localString("Proof")} 
					>
						<RequestProof  {...props}/>
					</PageContentWrapper>
				}
				{
					activeTab.ShowGstInfo &&
					<BillingDetails
						{...props}
						TaxDetailName={countriesChecks.TaxDetailName}
						TaxDetailValue={countriesChecks.TaxDetailValue}
					/>
				}
				{
					activeTab.showConsumrAddress &&
					<AddressDetails
						{...props}
					/>
				}
				{
					activeTab.showTaskList &&
					<TaskList
						{...props}
					/>
				}
				{
					activeTab.showDeadOnArrival &&
					<DeadOnArrival
						{...props}
					/>
				}
				{
					activeTab.showLogisticsDetails &&
					<LogisticsDetails
						{...props}
					/>
				}
				{
					activeTab.showSupportingDocuments
					&& consumerServiceRequest && [SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !accessFn('GSX_FLOW') && !accessFn('HIDE_REQUEST_DOCUMENTS')
					&& (['Supporting documents pending', 'Supporting document validation pending', 'Supporting documents required', 'Claim estimation generated'].indexOf(consumerServiceRequest.Status) > -1 || showRequestLogs.isSupportingDocsRequired)
					&& <SupportingDocuments
						{...props}
					/>
				}
				{
					activeTab.ShowReplacementRequestDetails &&
					<ReplacementDetails
						{...props}
					/>
				}
				{
					activeTab.showUpgradedDeviceDetails &&
					<ReplacementDeviceDetails
						{...props}
					/>
				}
				{
					activeTab.ShowTransferDetails &&
					<TransferDetails
						{...props}
					/>
				}
				{
					(activeTab.showPackagingMaterialDetails || activeTab.showAddShipmentDetails) &&
					<PackagingMaterialDetails
						{...props}
						addresses={consumerDetails.Addresses}
					/>
				}
				{
					activeTab.ShowBuyoutDetails &&
					([SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) &&
					(['Reimbursement approved', 'Service completed'].indexOf(consumerServiceRequest.Status) > -1) &&
					<BuyoutDetails
						{...props}
					/>
				}
				{/* {
					activeTab.showCommunicationHistory && (accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_SH') ||
					[9,13].indexOf(consumerServiceRequest.ServiceTypeID) == -1) &&
					!accessFn('HIDE_COMMUNICATION_HISTORY_TAB') &&
					<RequestCommunicationHistory
						{...props}
					/>
				} */}
				{
					activeTab.showLoanerDeviceDetails && accessFn('LOANER_FLOW') &&
					<LoanerWrapper
						{...props}
					/>
				}
				{
					activeTab.showRaiseRequest && ([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && (['In-principle approved'].indexOf(consumerServiceRequest.Status) > -1) && accessFn('RAISE_CLAIM_ADMIN_REQUEST') &&
					<AdminRaiseRequest
						{...props}
						planID={planID}
						claimDamageId={claimDamageId}
						currencySymbol={currencySymbol}
						countriesChecks={countriesChecks}
						soldPlanCoverage={soldPlanCoverage}
						consumerServiceRequest={consumerServiceRequest}
					/>
				}
				{
					activeTab.showPartRequested &&
					<PartsRequested
						{...props}
						exception={exception}
						getRequestById={getRequestById}
						partsRequested={partsRequested}
						outletLocationData={currencyData}
						getPartsForRequest={getPartsForRequest}
						consumerServiceRequest={consumerServiceRequest}
						canUpdateServiceRequest={canUpdateServiceRequest}
						productNotMapped={showRequestValid.productNotMapped}
					/>
				}
				{
					activeTab.showQuestionLookup &&
					<QuestionLookupTab
						{...props}
						csrId={consumerServiceRequest.ConsumerServiceRequestID}
					/>
				}
				{
					activeTab.showIntegrationLogs &&
					<IntegrationLogDetails
						{...props}
					/>
				}
				{
					activeTab.showProvisionalEstimation &&
					<ProvisionalEstimate
						{...props}
						isRequestSFANeeded={showRequestValid.isRequestSFANeeded}
						requestSFA={requestSFA}
						canUpdateServiceRequest={canUpdateServiceRequest}
					/>
				}
				{
					activeTab.showActualChargeDetails &&
					<ActualCost
						{...props}
					/>
				}
				{
					activeTab.showRedesignCommunicationHistory && (accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_SH') ||
					[9,13].indexOf(consumerServiceRequest.ServiceTypeID) == -1) &&
					!accessFn('HIDE_COMMUNICATION_HISTORY_TAB') &&
					<CommunicationHistory
						{...props}
					/>
				}
				{activeTab.showPartnerStatusUpdate && 
					<PartnerStatusUpdate
						{...props}
					/>
				}
				{
					activeTab.showRefurbishmentRequestDetails && ((([65].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && showRequestLogs.isRefurbishmentRequestCreated)  || refurbishmentFlowCheck) &&
					<RefurbishmentDetails
						{...props}
					/>
				}
				{
					activeTab.showDispositionDetails && (([65].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && showRequestLogs.showDispositionDetailsSec) &&
					<DispositionDetails
						{...props}
					/>
				}
				{
					activeTab.showPaymentDetails && 
					<PaymentDetailsTab
						{...props}
					/>
				}
				{
					activeTab.showFinishedGoodsDetails && (showRequestLogs.isServiceInitiated) &&
					<FinishedGoodsDetails
						// {...props}
						apiCall={apiCall}
						localString={localString}
						getRequestById={getRequestById}
						showRequestLogs={showRequestLogs}
						consumerServiceRequest={consumerServiceRequest}
					/>
				}
				{
					activeTab.showCaseScore && 
					<PageContentWrapper bodyClassName="panel-body-no-padding" title={"Case Score"}>
						<CaseScore 
							{...props}
							getCaseScores={getCaseScores}
							scoreDetails={scoreDetails}
							setScoreDetails={setScoreDetails}
						/>
					</PageContentWrapper>
				}
				{
					activeTab.showRemarks &&  accessFn('FETCH_REMARKS_DETAILS_TAB') &&
					<RemarkDetails
						{...props}
					/>
				}
			</div>
		</>
	)
}

export default NavigationBar
