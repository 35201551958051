import React, { useState } from 'react';
import { Button, Modal } from 'sfy-react';

import { returnLocalDateTime, trimData } from '../../Utility/Utils';
import Table from '../../components/Table/Table';

const AnnouncementGrid = (props) => {
	const { tableData, localString, accessFn } = props
	const [showModal, setShowModal] = useState(false);
	const [modalData, setModalData] = useState(null);

	const columns = [
		{
			title: localString("Schedule Date"),
			showHeading: !accessFn('ENGINEER_NOTIFICATION'),
			customCell: (row) => returnLocalDateTime(row.ScheduleDate) || '--'
		},
		{
			title: localString("Created Date"),
			showHeading: true,
			customCell: (row) => returnLocalDateTime(row.CreatedDate, 'DD-MM-YYYY HH:mm') || '--',
		},
		{
			title: localString("Content"),
			showHeading: true,
			customCell: (row) => {
				return (
					<div className=''>
						{ row.Content.Title && <><b>{trimData(row.Content.Title, 70)}</b> <br/></>}
						{ row.Content.Message && <><span>{trimData(row.Content.Message, 300)}</span> <br/></>}
						{ row.Content.Attachment && <a href={row.Content.Attachment} target='_blank'>Link to attachment</a> }
						{ row.Documents && row.Documents.length && <a href={row.Documents[0].FilePath} target='_blank'>Link to attachment</a> }
					</div>
				);
			}

		},
		{
			title: localString("Read"),
			showHeading: true,
			field: 'ReadCount',
		},
		{
			title: localString("Unread"),
			showHeading: true,
			field: 'UnReadCount',
		},
		{
			title: localString("Pending"),
			showHeading: !accessFn('ENGINEER_NOTIFICATION'),
			field: 'PendingCount',
		},
		{
			title: localString("Actions"),
			showHeading: true,
			customCell: (row) => {
				return (
					<Button
						type='button'
						classes='button-ghost'
						onClick={() => {
							setModalData(row);
							setShowModal(true);
						}}
					>{localString('Details')}</Button>
				);
			}
		}
	]

	return (
		<>
			<Table columns={columns} data={tableData} localString={localString}/>
			<Modal
				showModal={showModal}
				closeModal={() => {
					setModalData(null);
					setShowModal(false);
				}}
				showCloseBtn={true}
				className='Filter-Modal'
			>
				<Modal.Header>
					<h2> {localString("Notification Details")} </h2>
				</Modal.Header>
				<Modal.Body>
					<div className='padding15'>
						{ modalData && modalData.Content.Title && <><div><b style={{paddingRight: '5px'}}>{localString('Title')}:</b>{modalData.Content.Title}</div><br/></>}
						{ modalData && modalData.Content.Message && <><div><b style={{paddingRight: '5px'}}>{localString('Message')}:</b><span>{modalData.Content.Message}</span></div><br/></>}
						{ modalData && modalData.Content.Attachment && <><div><b style={{paddingRight: '5px'}}>{localString('Attachment')}:</b><a href={modalData.Content.Attachment} target='_blank'>{localString('Link to attachment')}</a></div><br/></>}
						{ modalData && modalData.Documents && modalData.Documents.length && <><div><b style={{paddingRight: '5px'}}>{localString('Document')}:</b><a href={modalData.Documents[0].FilePath} target='_blank'>{localString('Link to attachment')}</a></div></> }
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default AnnouncementGrid;