import React,{useRef} from 'react';
import {Loader,Table,Input,Radio,DatePicker,Button,Dropdown} from 'sfy-react';
import Datetime from 'react-datetime';
import '../NavigationBar.scss';
import './DeviceDetails.scss';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper'
import { returnLocalDateTime } from '../../../../../Utility/Utils';
import moment from 'moment';

const DeviceDetailsTable = (props) => {
	const {
		baseUrl,editDevice,localString,enableInputFunction,selectedFile,inputData,consumerServiceRequest,cancelDetailsClick,
		saveButtonFunction,validateDetailsClick,deviceDetails,showEditButton,setActiveTab
	} = props;

	const getObjectKey=(obj)=> {
		return Object.keys(obj)[0];
	};

	const imageInput1 = useRef(null);
	const imageInput2 = useRef(null);

	const classObject = {
		table:"table table-striped complaint-details-table"
	}

	return (
		<form onsubmit={saveButtonFunction} name="warrantyForm">
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper bodyClassName="panel-body-no-padding" title={localString("Device Details")} showDownloadButtonIcon={showEditButton && !editDevice} editDeviceClick={enableInputFunction}>
						<div className="request-panel-details-tag request-issue-validation-div">
							<div>
								<Table className={classObject}>
									<Table.Body>
									{
										deviceDetails && deviceDetails.map(item=>{
											return(
											<>
												{
													item.showLabel && !item.editable && 
													<Table.Row>
														<Table.Data className="table-keys" >{item.labelText} </Table.Data>
														<Table.Data>{item.currency && <span dangerouslySetInnerHTML={{__html: item.currency}}></span>}{item.value}
														{
															item.loanerButton &&
															<span className="pull-right">{"Available Loaner Device  :" + item.loanerCount} 
																<Button
																	type="button" 
																	onClick={() => setActiveTab({showLoanerDeviceDetails:true})} 
																	isDisabled={item.loanerCount <= 0} 
																	style={{marginLeft:"45px"}} 
																	className="btn button-servify"
																>
																	{localString("Assign Loaner")}
																</Button>
															</span>
														}
														</Table.Data>
													</Table.Row>
												}
												{
													item.deviceAttribute && 
													item.value && item.value.map((attribute)=>{
														return(
															<Table.Row>
																<Table.Data className="table-keys"> {localString(getObjectKey(attribute))} </Table.Data>
																<Table.Data> {attribute[getObjectKey(attribute)] ? attribute[getObjectKey(attribute)] : '-'}</Table.Data>
															</Table.Row>
														)
													})
												}
												{
													item.attributesArrayWithValues && 
													item.value && item.value.map((attribute)=>{
														return(
															<Table.Row>
																<Table.Data className="table-keys"> {localString((attribute.AttributeName))} </Table.Data>
																<Table.Data> {attribute.AttributeValue ? attribute.AttributeValue : '-'}</Table.Data>
															</Table.Row>
														)
													})
												}
												{
													item.showProtectionStatus && 
													<Table.Row>
														<Table.Data className="table-keys"> {item.labelText} </Table.Data>
														<Table.Data>
															{
																item.value && item.value.map(plan=>{
																	return (
																		<span>
																			{plan.PlanName && <p>{localString("Plan Name")} : {plan.PlanName}</p>}
																			{(plan.NoOfRepairsUsed || plan.NoOfRepairsUsed == 0 )&& <p>{localString("No. of Repairs Used")} : {plan.NoOfRepairsUsed}</p>}
																			{plan.NoOfRepairsAllowed && <p>{localString("No. of Repairs Allowed")} : {plan.NoOfRepairsAllowed}</p>}
																			{/* {
																				plan.HighRiskReason && plan.HighRiskReason.length > 0 && 
																				<ul className="ul-without-padding"> {localString("High Risk Reason(s)")}:
																					{
																						plan.HighRiskReason && plan.HighRiskReason.map((highRisk)=>{
																							return (
																							<li>{highRisk.RaisedFlagReasonDescription}</li>)
																						})
																					}
																				</ul>
																			} */}
																		</span>
																		//<hr if={i != 0}/>
																	)
																})
															}
														</Table.Data>
													</Table.Row>
												}
												{
													item.showCoverageDetails && 
													<Table.Row>
														<Table.Data className="table-keys"> {item.labelText} </Table.Data>
														<Table.Data>
															{
																item.value && item.value.map(i=>{
																	return(
																		<div>
																			{i.CoverageType && <p>{localString("Coverage Type")} : {i.CoverageType}</p>}
																			{(i.NoOfRepairsUsed || i.NoOfRepairsUsed == 0) && <p>{localString("No. of Repairs Used")} : {i.NoOfRepairsUsed}</p>}
																			{i.NoOfRepairsAllowed && <p>{localString("No. of Repairs Allowed")} : {i.NoOfRepairsAllowed} {i.CoverageType == 'EW' ? '(' + localString('Upto covered value') + ')' : ''}</p>}
																			{(i.SumInsured || i.SumInsured == 0) && <p>{localString("Covered Value")} :{i.SumInsured}</p>}  
																				{/* <span class="{'currency-plan-' + index}">{setCurrencySymbol(i, index)}</span>{i.maxSumInsured}</p>} */}
																		</div>
																	)
																})
															}
														</Table.Data>
													</Table.Row>
												}
												{
													item.showMirrorTest && 
													<Table.Row>
														<Table.Data className="table-keys text-capitalize">{item.labelText}:</Table.Data>
														<Table.Data>
															<div className="col-sm-12">
																<ul className="remove-list-style">
																	{
																		item.value.ConsumerProductTestResult && item.value.ConsumerProductTestResult.map(url=>{
																			return(
																				<li className="request-documents-li align">
																					<a href={baseUrl + "/document/view?u=" + url.replace('?','%3F').replace(/&/g,'%26')} target = "_blank">
																						<img src={url}  width="100px" height="100px"/>
																					</a>
																				</li>
																			)
																		})
																	}
																	{
																		item.value.SoldPlanTestResult && item.value.SoldPlanTestResult.map(url=>{
																			return (
																				<li className="request-documents-li align">
																					<a href={baseUrl + "/document/view?u=" + url.replace('?','%3F').replace(/&/g,'%26')} target = "_blank">
																						<img src={url}  width="100px" height="100px"/>
																					</a>
																				</li>
																			)
																		})
																	}
																</ul>
															</div>
														</Table.Data>
													</Table.Row>
												}
												{
													item.showProductDoc && !item.editable &&
													<Table.Row>
														<Table.Data className="table-keys">{item.labelText}</Table.Data>
														<div className="device-document-wrapper-parent">
															<Table.Data>
															{
																item.value && item.value.FilePath && item.value.FilePath.map((pics,i)=>{
																	return(
																		<div className="device-document-wrapper" >
																			<a href={pics} target="_blank" className="device-document">
																				<div className="documents-box" style={{textAlign: "center" , width: "100px", minHeight: "100px",maxWidth: "100px", maxHeight: "100px", margin:"0px 10px"}}>
																					<div>
																						<i className="glyphicon glyphicon-file" style={{fontSize:"18px"}}></i>
																						<p className="field-margin">
																							{/* {( (doc.consumerServicerequestDocumentPending && doc.consumerServicerequestDocumentPending.document && doc.consumerServicerequestDocumentPending.document.DocumentType) ? localString(doc.consumerServicerequestDocumentPending.document.DocumentType) : localString(doc.Tag)) || 'NA'} */}
																						</p>
																						<p>{localString("Doc")+ " " + (i+1)}</p>
																						{/* <p>{returnLocalDateTime(doc.CreatedDate,'DD MMM, YYYY hh:mma')}</p> */}
																					</div>
																				</div>
																			</a>
																		</div>
																	)
																})
															}
															</Table.Data>
														</div>
													</Table.Row>
												}
												{
													item.showInspGuide && !item.editable && 
													<Table.Row>
														<Table.Data className="table-keys"> {item.labelText} </Table.Data>
														<Table.Data>
														{
															item.value && item.value.productConfig && item.value.productConfig.InspectionGuideUrl.map((item,i)=>{
																return(
																	<div>
																		<a href={item} className="btn button-servify" target="_blank">{localString("Guide")} {i+1}</a>
																	</div>
																)
															}) 
														}
														</Table.Data>
													</Table.Row>
												}
												{
													item.showProductDoc && item.editable &&
													<Table.Row>
														<Table.Data className="table-keys">{item.labelText}</Table.Data>
														<div className="device-document-parent">
															<Table.Data>
																<div className="device-Document-Select">
																	<Dropdown
																		id='deviceDocumentSelect'
																		className="col-sm-3"
																		value={inputData[item.dataKey]}
																		options={item.documentOptions}
																		handleOnChange={(data) => {
																			item.onInputFunc(data);
																		}}
																		showDownArrow={false}
																	/>
																	<Input
																		handleOnChange={selectedFile}
																		inputType="file"
																		otherProps={{ accept: ".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv" }}
																		id={item.id}
																		className="col-sm-9"
																	/>
																</div>
																
																{/* <input type="file" id="deviceDocFile" name="deviceDocFile"  className="col-sm-9" accept=".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv" /> */}
															</Table.Data>
														</div>
													</Table.Row>
												}
												{
													item.editable && item.showLabel &&
													<Table.Row>
														<Table.Data className="table-keys"> {item.labelText} </Table.Data>
															{
																item.type == "input" &&
																<Table.Data> 
																<Input
																	value={inputData[item.dataKey]}
																	handleOnChange={(e) => {
																		item.onInputFunc(e.target.value);
																		item.checkImei && item.checkImei(e);
																	}}
																	maxLength={item.maxlength}
																	otherProps={{pattern:item.pattern}}
																/>
																</Table.Data> 
															}
															{
																item.type == "dropdown" && 
																<div className={item.edittClass? item.edittClass:"device-details-dropdown"}>
																	<Table.Data >
																		<Dropdown
																			id={item.id}
																			className={item.classname}
																			value={inputData[item.dataKey]}
																			options={item.dropdownList}
																			handleOnChange={(data) => {
																				item.onInputFunc(data);
																			}}
																			showDownArrow={false}
																		/>
																	</Table.Data>
																</div>
																
															}
															{
																item.type == "radio" && 
																<Table.Data className="device-details-radio"> 
																{
																	item.radioButtons && item.radioButtons.map(radio=>{
																		return(
																			<Radio
																				value={radio.value}
																				name={radio.buttonText}
																				selectedValue={radio.isSelect}
																				handleOnUpdate={(e)=>{
																					radio.onclickupdate(e.target.value)
																				}}
																				classes={radio.className}
																			>{radio.buttonText}</Radio>
																		)
																	})
																}
																</Table.Data> 
															}
															{
																item.type == "datePicker" && 
																<div className="deviceDetails-dateofpurchase">
																<Table.Data>
																	<Datetime
																		value={inputData[item.dataKey] || ""}
																		dateFormat={'DD-MM-YYYY'}
																		timeFormat={false}
																		initialValue={inputData[item.dataKey] || ""}
																		onChange={(val) => {
																			item.onInputFunc(moment(new Date(val),'DD-MM-YYYY').format('DD-MM-YYYY'))
																		}}
																		isValidDate={(current) => {
																			if(item && item.maxDay && item.minDay) {
																				return current.isAfter(item.minDay) && current.isBefore(item.maxDay)
																			}else if(item && item.maxDay) {
																				return current.isBefore(item.maxDay)
																			}else if(item && item.minDay) {
																				return current.isAfter(item.minDay)
																			}else {
																				return true;
																			}
																		}}
																		inputProps={{ ref: item.reference == "ref1" ? imageInput1 : imageInput2 }}
																		closeOnSelect={true}
																	/>
																	<span className="input-group-addon"  onClick={() =>  item.reference == "ref1" ? imageInput1.current.click() : imageInput2.current.click()}>
																		<span className="glyphicon glyphicon-calendar"></span>
																	</span>
																</Table.Data>
																</div>
															}
													</Table.Row>
												}
											</>
											)
										})
									}
									</Table.Body>
								</Table>
								{
									editDevice && 
									<div className="row">
										<div className="col-md-12">
											<div className="pull-right">
												{
													!([9,13].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && 
													<Button type='button' className="btn button-servify" handleOnClick={saveButtonFunction} text={localString("Save")} />
												}
												{
													([9,13].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && 
													<Button type='button' className="btn button-servify" handleOnClick={validateDetailsClick} text={localString("Verify")} />
												}
												{
													<Button type='button' className="btn button-servify" handleOnClick={cancelDetailsClick} text={localString("Cancel")} />
												}
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</PageContentWrapper>
				</div>
			</div>            
		</form>
	)
}

export default DeviceDetailsTable
