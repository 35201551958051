import React,{useState, useEffect}  from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import axios from 'axios';
import moment from 'moment';
import Swal from "sweetalert2";
// import { isEmpty,uniqBy,find, findIndex, debounce,omit,filter,map,cloneDeep,pull,findLast } from 'lodash';
import { isEmpty,uniqBy,find, findIndex,omit,cloneDeep,pull,findLast } from 'lodash';
// import { displayError, SERVICETYPE,generateUUID ,encrypt,validateThis,PATTERN,isTheSameObj} from '../../../../../Utility/Utils';
import { displayError, SERVICETYPE,generateUUID ,encrypt,validateThis,PATTERN} from '../../../../../Utility/Utils';
import './ViewButtonFunctionality.scss';

import ActionButtonWrapper from '../../../../../components/ActionButtonWrapper/ActionButtonWrapper';
import CallHistoryModal from '../../../../Consumer/ConsumerList/CallHistoryModal';
import ScheduleButtonModal from '../../ViewButtonModal/ScheduleModal/ScheduleButtonModal';
import ReassignButtonModal from '../../ViewButtonModal/ReassignModal/ReassignButtonModal';
import BillingDetailModal from '../../ViewButtonModal/BillingDetailModal/BillingDetailModal';
import CancelRequsetModal from '../../ViewButtonModal/CancelRequestOnsiteModal/CancelRequestModal';
import CreateVisitButtonModal from '../../ViewButtonModal/CreateVisitModal/CreateVisitButtonModal';
import UpdateJobModal from '../../ViewButtonModal/JobUpdateModal/UpdateJobModal/UpdateJobModal';
import ResolveVisitInput from '../../ViewButtonModal/JobUpdateModal/CloseVisit/ResolveVisitInput';
import UnresolveVisitInput from '../../ViewButtonModal/JobUpdateModal/CloseVisit/UnresolveVisitInput';
import SubmitIrisModal from '../../ViewButtonModal/JobUpdateModal/IrisModal/SubmitIrisModal';
import CloseVisitPendingModal from '../../ViewButtonModal/JobUpdateModal/CloseVisitPendingSpares/CloseVisitPendingModal';
import ResolveModalInput from '../../ViewButtonModal/ResolveModal/ResolveModalInput';
import UnresolvedModalInput from '../../ViewButtonModal/UnresolveModal/UnresolvedModalInput';
import CancelOnsiteModal from '../../ViewButtonModal/CancelOnsiteModal/CancelOnsiteModal';
// import CreateTaskInput from '../../ViewButtonModal/CreateTaskModal/CreateTaskInput';
import CreateTaskModal from '../../ViewButtonModal/CreateTaskModal/CreateTaskModal';
import AddShipmentDetailModal from '../../ViewButtonModal/AddShipmentDetails/AddShipmentDetailModal';
import InitiateDiagnosis from '../../../../../components/InitiateDiagnosis/InitiateDiagnosis';
import DeviceHandoverAndRecieved from '../../ViewButtonModal/DeviceHandoverAndRecieved/DeviceHandoverAndRecieved';
import DocumentPendingModal from '../../ViewButtonModal/DocumentPendingModal/DocumentPendingModal';
import InspectDeviceModal from '../../ViewButtonModal/InspectDeviceModal/InspectDeviceModal';
import CapturePaymentModeModal from '../../ViewButtonModal/CapturePaymentMode/CapturePaymentModeModal';
import PerformQcModal from '../../ViewButtonModal/PerformQC/PerformQcModal';
import PartUniqueModal from '../../ViewButtonModal/PartUniqueModal/PartUniqueModal';
import CallAttemtModal from '../../ViewButtonModal/CallAttemptModal/CallAttemtModal';
import SelectOutcomeModal from '../../ViewButtonModal/SelectOutcome/SelectOutcomeModal';
import FlagFraudModal from '../../ViewButtonModal/FlagFraudModal/FlagFraudModal';
import DecisionFormModal from '../../ViewButtonModal/DecisionFormModal/DecisionFormModal';
import UpdateLogisticModal from '../../ViewButtonModal/UpdateLogisticModal/UpdateLogisticModal';
import GSXRaiseRequestModal from '../../ViewButtonModal/GSXRaiseRequestModal/GSXRaiseRequestModal';
import LoanerDeviceDiagnosisModal from '../../ViewButtonModal/LoanerDeviceDiagnosisModal/LoanerDeviceDiagnosisModal';
import LoanerPaymentModal from '../../ViewButtonModal/LoanerPaymentModal/LoanerPaymentModal';
import NonHardwareClosureModal from '../../ViewButtonModal/NonHardwareClosureModal/NonHardwareClosureModal';
import ApprovalRemarksModal from '../../ViewButtonModal/ApprovalRemarksModal/ApprovalRemarksModal';
import RejectReasonModal from '../../ViewButtonModal/RejectReasonModal/RejectReasonModal';
import SerialNumberModal from '../../ViewButtonModal/SerialNumberModal/SerialNumberModal';
import TokenNumberModal from '../../ViewButtonModal/TokenNumberModal/TokenNumberModal';
import DiagnosisNoteModal from '../../ViewButtonModal/DiagnosisNoteModal/DiagnosisNoteModal';
import ReplacementOptionModal from '../../ViewButtonModal/ReplacementOptionModal/ReplacementOptionModal';
import ReplacementDetailsModal from '../../ViewButtonModal/ReplacementDetailsModal/ReplacementDetailsModal';
import CollectionDatePickerModal from '../../ViewButtonModal/CollectionDatePickerModal/CollectionDatePickerModal';
import UpdateOrderDetailsModal from '../../ViewButtonModal/UpdateOrderDetailsModal/updateOrderDetailsModal';
import PayoutModesModal from '../../../../../components/PayoutModesModal/PayoutModesModal';
import DeviceInspectModal from '../../ViewButtonModal/DeviceInspectRFModal/DeviceInspectModal';
import InitiateBERPaymentModal from '../../ViewButtonModal/InitiateBERPaymentModal/InitiateBERPaymentModal';
import BERProductModal from '../../ViewButtonModal/BERProductModal/BERProductModal';
import UTRPymntDateModal from '../../ViewButtonModal/UTRPymntDateModal/UTRPymntDateModal';
import IssueResolvedModal from '../../ViewButtonModal/issueResolvedModal/issueResolvedModal';
import SelectAddressPopup from '../../ViewButtonModal/SelectAddressPopup/SelectAddressPopup';
import ShowDiagnosticsResultModal from '../../ViewButtonModal/ShowDiagnosticsResultModal/ShowDiagnosticsResultModal';
import FMIPModal from '../../ViewButtonModal/FMIPModal/FMIPModal';
import ApproveDoaModal from '../../ViewButtonModal/ApproveDoaModal/ApproveDoaModal';
import EscalationModal from '../../ViewButtonModal/EscalationModal/EscalationModal';
import DoaChecklistModal from '../../ViewButtonModal/DoaChecklistModal/DoaChecklistModal';
import UpdateServiceModal from '../../ViewButtonModal/UpdateServiceModal/UpdateServiceModal';
import ServiceLocationModal from '../../ViewButtonModal/ServiceLocationModal/ServiceLocationModal';
import MapGNumberModal from '../../ViewButtonModal/MapGNumberModal/MapGNumberModal';
import PlanTypeModal from '../../ViewButtonModal/PlanTypeModal/PlanTypeButtonModal';
import UploadClaimDocumentModal from '../../ViewButtonModal/UploadClaimDocumentModal/UploadClaimDocumentModal';
import RepairNotesModal from '../../ViewButtonModal/RepairNotesModal/RepairNotesModal';
import DeviceUniqueModal from '../../ViewButtonModal/DeviceUniqueModal/DeviceUniqueModal';
import InitiateRefundModal from '../../ViewButtonModal/InitiateRefundModal/InitiateRefundModal';
import CreateRefurbModal from '../../ViewButtonModal/CreateRefurbModal/CreateRefurbModal';
import CompleteAnalyseModal from '../../ViewButtonModal/CompleteAnalyseModal/CompleteAnalyseModal';
import CompleteAnalysisServiceCluster from '../../ViewButtonModal/CompleteAnalysisServiceCluster/CompleteAnalysisServiceCluster';
import ReturnToSenderModal from '../../ViewButtonModal/ReturnToSenderModal/ReturnToSender';
import ReassignServiceCenterInput from '../../ViewButtonModal/ReassignServiceCenter/ReassignServiceCenterInput';
import SelectServiceApproachModal from '../../ViewButtonModal/SelectServiceApproachModal/SelectServiceApproachModal';
import UpdateKGBModal from '../../ViewButtonModal/UpdateKGBModal/UpdateKGBModal';
import ShipToServiceCentreModal from '../../ViewButtonModal/ShipToServiceCentreModal/ShipToServiceCentreModal';
import BERInitiateRefundModal from '../../ViewButtonModal/BERInitiateRefundModal/BERInitiateRefundModal';


const allModals = {
    showSheduleModal:false,
    showSetSheduleModal:false,
    showCallLogModal:false,
    showReassignModal:false,
    // showBillingModal:false,
    showCancelRequestModal:false,
    showCreateResheduleModal:false,
    showUpdateJobModal:false,
    showRatingReasonModal:false,
    showResolveVisitModal:false,
    showUnresolveVisitModal:false,
    showCancelOnsiteDropdown:false,
    showCreateTaskModal:false,
    showIrisModal:false,
    showDropOffShipmentModal:false,
    showDeviceReturnedModal:false,
    showDeviceHandoverModal:false,
    showInitiateDiagnosis:false,
    showDocumentPendingModal:false,
    showDeviceReceivedModal:false,
    showCapturePaymentMode:false,
    showPerformQcModal:false,
    showPartsUniqueModal:false,
    showAdditionalCallModal:false,
    showSelectOutcomeModal:false,
    showFlagFraudsModal:false,
    showDecisionFormModal:false,
    showUpdateLogisticModal: false,
    showLogCallModal:false,
    showReplacementOptionsModal:false,
    showReplacementDetailsModal:false,
    showCollectionDatePicker : false,
    showUpdateOrderDetailsModal :false,
    showPayoutModesModal:false,
    showInspectDeviceRfModal:false,
    showInitiateBERPaymentModal:false,
    showBERProductModal:false,
    showUTRPymntDateModal:false,
    showIssueResolvedModal:false,
    showSelectAddressPopupModal:false,
    gsxRaiseRequestModal: false,
    loanerDeviceDiagnosisModal: false,
    loanerPaymentModal: false,
    showNonHardwareClosureModal: false,
    approvalRemarksModal: false,
    rejectReasonModal: false,
    serialNumberModal: false,
    tokenNumberModal: false,
    createVisitModal: false,
    showDiagnosticsResultModal:false,
    // showFMIPModal:false,
    diagnosisNotesModal: false,
    capturePaymentModalPickupCarryin: false,
    showUpdateServiceModal:false,
    showServiceLocationModal:false,
    showDoaChecklistModal:false,
    approveDoaModal: false,
    showEscalateRequestModal:false,
    showMapGNumberModal: false,
    showPlanTypeModal: false,
    showUploadClaimDocumentModal: false,
    showRepairNotesModal: false,
    showDeviceUniqueModal: false,
    showInitiateRefundModal: false,
    showCreateRefurbModal:false,
    showCompleteAnalyseModal:false,
    showCompleteAnalyseModalServiceCluster: false,
    showReturnToSenderModal:false,
    showReassignServiceCenterModal:false,
    showSelectServiceApproachModal:false,
    updateKGBModal: false,
    ShipToServiceCentreModal:false,
    showInitiateBERRefundModal:false,
}
const ViewButtonFunctionality = (props) => {
    const {
        statusButtons,apiCall,accessFn, localString,customCls,ConsumerServiceRequestID,consumerServiceRequest,
        getRequestById,setShowViewRequestFlags,showButton,claimRequestNext,partsRequested,currencySymbol,baseUrl ,diagnosisUrl,
        isEmptyBoxServiceable,showShipPackagingMaterialButton,emptyBoxPackagingDetails,claimRequestWorkshopNext,
        getNextSteps,getSource,consumerDetails,getDisplayText,isRescheduleApplicable,setShowRequestValid,isCancelApplicable,
        checkDecisionMatrixProcessCompleted,hideNextStepInTradeIn,planID,countryConfig,showViewRequestFlags, checkPaymentStatus,
        adjudicationChecklistData,soldPlanCoverage,useDocServiceInspect,docServiceUrl,authorization,env,countryCode,getSumInsured,
        selectedBillingDetail, countriesChecks, isRequestSFANeeded, requestSFA, defaultCountry,AdvanceChargeApplicable,currencyCode,
        berRequestNext,showRequestLogs,berValid,loanerDetails, fetchLoanerDetailsByCsrId,getBillingDetails,exception,featureConfig,
        arrDiagnosisSuites, diagnosisSuites, csrLogs, claimNewWorkshopChecks, claimChecks, pickupCarryInGSXChecks, pickupCarryInChecks,
        onsiteChecks, berChecks,replacementFlowChecks,warrantyAppicableNonClaimCheck,isBoseFlow, setActiveTab,refundProcessCheck,
        serviceRequestClusterCheck, appleDropOffFlow, refurbishmentFlowCheck, userID, isSubscriptionEnabled, updateConsumerServiceRequestStatus
    } = props;
    const qFilter = JSON.parse(props.qFilter);
    const[callLog,setCallLog]=useState(false);
    const [symptomLoading,setSymptomLoading]=useState(true);
    const [showPartLoader,setShowPartLoader]=useState(true);
    const [showFaultLoader,setShowFaultLoader] = useState(false);
    const [showBillingModal,setShowBillingModal]=useState(false);
	// const [showCallLogPage, setShowCallLogPage] = useState(false);
    const [ showModal,setShowModal]=useState(allModals)
    const [showIrisFlags,setShowIrisFlags]=useState({
        selectedIrisSymptomFlag: true,
        selectedIrisFaultFlag: true,
    })
	// const [showSheduleModal , setShowSheduleModal] = useState(false);
    const [showLoader, setLoader] = useState(false);
    const [engineerLoader, setEngineerLoader] = useState(false);
    const [addUpdateApiLoader, setAddUpdateApiLoader] = useState(false);
    const [scheduleDateArray,setScheduleDateArray]=useState([]);
    const [selectedScheduleDate,setSelectedScheduleDate]=useState([]);
    const [scheduleSlotArray,setScheduleSlotArray]= useState([]);
    const [selectedSlot,setSelectedSlot] = useState([]);
    const [slotAvailable,setSlotAvailable] = useState(false);
    const [tradeInDashboardDetails,setTradeInDashboardDetails]= useState(null);
    const [tradeInDetails,setTradeInDetails]=useState(null);
    const [TaxDetailName,setTaxDetailName]=useState({});
    const [cancelReasonsData,setCancelReasonsData]=useState({});
    const [selectedCancelReason,setSelectedCancelReason]=useState([]);
    const [engineerData,setEngineerData]=useState([]);
    const [cancelReasonComp,setCancelReasonComp]=useState();
    const [disableCancelReasonSave,setDisableCancelReasonSave]=useState(true);
    const [filterCancelrequest,setFilterCancelrequest]=useState("");
    const [filterEngineer,setFilterEngineer]=useState();
    const [cancelText,setCancelText]=useState('');
    const [internalReason, setInternalReason] = useState('');
    const [chargeType,setChargeType]=useState([]);
    const [visitDispositionDataUnPro,setVisitDispositionDataUnPro]=useState([]);
    const [visitDispositionDataPro ,setVisitDispositionDataPro]=useState([]);
    const [selectVisitDispositionPro,setSelectVisitDispositionPro]=useState([]);
    const [selectVisitDispositionUnPro,setSelectVisitDispositionUnPro]=useState([]);
    const [ visitDispositionProStatus,setVisitDispositionProStatus]=useState()
    const [time,setTime]=useState({
        starttime :"",
        endtime :""
    })
    const [visitDispositionSelectedFlag,setVisitDispositionSelectedFlag]=useState({
        resolveVisitFlag:true,
        unresolveVisitFlag:true
    });
    const [jobModalState,setJobModalState]=useState(0);
    const [onsiteFlags,setOnsiteFlags]=useState({
        resolvedFlag:false,
        isOnlyPartsAddStatus:false,
        showsavebutton: false,
        visitDispositionLoaded:false,
        isFetchedIris:false,
        fromIrisFlag:false,
        resolvedModal:false,
        irisSubmission:false
    });
    const [fromJobFlag,setFromJobFlag]=useState(false)
    const [updateJobData, setUpdateJobData] = useState({
        date:moment(new Date()).format("DD/MM/YYYY"),
        picker1BiginTimeVal:"",
        picker1EndTimeVal:"",
        picker2BiginTimeVal:"",
        picker2EndTimeVal:"",
    });
    const[visitUnresolveData,setVisitUnresolveData]=useState({
        visitDispositionStatus:"",
        actionStatus:"",
		visitText:"",
    });
    const[visitResolveData,setVisitResolveData]=useState({
        serialNumber:"",
        visitDispositionStatus:"",
        actionStatus:"",
		visitText:"",
    });
    const [submitIrisData,setSubmitIrisData]=useState({
        selectSymptom:"",
        selectFault :"",
        selectAction:""
    });
    const [partJobFlag,setPartJobFlag]=useState({
        partSelected:false,
    })
    const [availableQuantity,setAvailableQuantity]=useState("")
    const [price,setPrice]=useState("")
    // const [availableQuantity,setAvailableQuantity]=useState()
    const [docArray,setDocArray]=useState([])
    const [selectedIrisActionFlag,setSelectedIrisActionFlag]=useState(true)
    const [visitDispositionLoaded,setVisitDispositionLoaded]=useState(false);
    const [irisMap,setIrisMap]=useState([]);
    const [selectInitIrisSymptom,setSelectInitIrisSymptom]=useState([]);
    const [selectInitIrisFault,setSelectInitIrisFault]=useState([]);
    const [selectInitIrisAction,setSelectInitIrisAction]=useState([]);
    const [irisSelection,setIrisSelection]=useState([]);
    const [actionStatusSelected,setActionStatusSelected]=useState();
    const [actionStatusProductiveSelected,setActionStatusProductiveSelected]=useState("")
    const [pendingForSpares,setPendingForSpares]=useState(false);
    const [replacementFlow,setReplacementFlow]=useState(false);
    const [picMaster,setPicMaster]=useState([]);
    const [selectedPicType,setSelectedPicType]=useState("")
    const [otherArrImagesPreview,setOtherArrImagesPreview]=useState([]);
    const [claimOtherArrImagesPreview,setClaimOtherArrImagesPreview] = useState([]);
    const [otherArrImages ,setOtherArrImages] = useState([])
    const [addJobPart,setAddJobPart]=useState({
        part:"",
        remark:"",
        warranty:""
    });
    const [selectJobParts,setSelectJobParts]=useState([]);
    const [partItem,setpartItem]=useState({});
    const [partsArray,setPartsArray]=useState([]);
    const [actionCode,setActionCode]=useState()
    const [selectActionVisitStatus,setSelectActionVisitStatus]=useState([]);
    const [selectActionUnresolveVisitStatus,setSelectActionUnresolveVisitStatus]=useState([])
    const [ratingModalTitle,setRatingModalTitle]=useState();
    const [selectCancelOnsiteDropdown,setSelectCancelOnsiteDropdown]=useState([]);
    const [cancelOnsiteFilterData,setCancelOnsiteFilterData]=useState()
    const [vendorList,setVendorList]=useState([]);
    const [claimFlags,setClaimFlags]=useState({
        vendorListFetched:false,
        showVendorNameInput:false,
        showAddBtn:false,
        isPartsCalled:false,
        isSelectAll:true,
        partSelected:false,
        showDeviceRecievedGsx:false,
        showClaimCancelRequestBtn:false,
        showClaimBillingModal:false,
        anyPartReturned:false,
        isFullBoxUnit:false,
        //gsx
        finishedGoodAutoSelected:false, 
        productNotMapped:false
    })
    const [priorityArr,setPriorityArr]= useState(
        [
            {"id":1,"PriorityType":"Very High"},
            {"id":2,"PriorityType":"High"},
            {"id":3, "PriorityType": "Medium"},
            {"id":4, "PriorityType": "Normal"}
        ]
    )
    const [selectPriorityType,setSelectPriorityType]=useState();
    const [createTaskInputs,setCreateTaskInputs]=useState({
        createTaskTitle:"",
        creatTaskPriority:"",
        createTaskRemark:"",
        priorityID:""
    })
    const [vender,setVender]=useState({
        venderName:"",
        waybillNumber:""
    });
    const [faultCode,setFaultCode] = useState({
        faultCodeText:""
    });
    const [currentShipmentDetail,setCurrentShipmentDetail]=useState({});
    const[venderOptions,setVenderOption]=useState();
    const[selectedVenderName,setSelectedVenderName]=useState([]);
    const [nextStatus,setNextStatus]=useState('');
    const [selectProductFinishedGood,setSelectProductFinishedGood]=useState([]);
    const [claimSelectedPicType,setClaimSelectedPicType] = useState("");
    const [claimDocArray,setClaimDocArray]=useState([])
    const [finishedGoodID,setFinishedGoodID]=useState('')
    const [paymentModeRadio,setPaymentModeRadio] = useState('cash');
    const [modeOfPayment,setModeOfPayment] = useState('');
    const [showQCChecklistComplete,setShowQCChecklistComplete] = useState(false);
    const [defectivePartsRequested, setDefectivePartsRequested] = useState([]);
    const [faultArray,setFaultArray] = useState([]);
    const [partcheck, setPartcheck] =useState(true);
    const [selectedFault,setSelectedFault] = useState([]);
    const [selectedFaultItems,setSelectedFaultItems] = useState([]);
    const [disableAddBtn,setDisableAddBtn] = useState(true);
    const [addedFraudFlag,setAddedFraudFlag] = useState();
    //flag fraud
    const [flagFraud,setFlagFraud] = useState({
		fraudType:'',
		fraudReason:'',
		fraudDesc:''
	});
    //
    const [header,setHeader] = useState(null);
    //skip form 
    const [documentsArray,setDocumentsArray] = useState([]);
    const [decionApiResponse,setDecionApiResponse] = useState([]);
    //
    //decision form
    const [decisionFormData,setDecisionFormData] = useState({});
    const [otherSource,setOtherSource] = useState({});
    //
    const [replacementFlowFlags,setReplacementFlowFlags] = useState({
        viewOptionsMode :false,
        chooseOptionsMode:false,
        addOptionsMode:false,
        brandListFetched:false,
        showReplacementOptionsProvided:false
    });
    const [optionsArray,setOptionsArray] = useState([]);
    const [deviceConfigInput,setDeviceConfigInput] = useState([]);
    const [ReplacementPrice,setReplacementPrice] = useState(null);
    const [selectedReplacement,setSelectedReplacement] = useState(null);
    const [deviceConfigVal,setDeviceConfigVal] = useState({});
    const [type,setType] = useState(null);
    const [dispositionList,setDispositionList] = useState([]);
    const [currentProductDetail,setCurrentProductDetail] = useState({});
    const [productList,setProductList] = useState([]);
    const [selectProductList,setSelectProductList] = useState([]);
    const [brandList,setBrandList] = useState([]);
    const [selectBrandList,setSelectBrandList] = useState([]);
    const [productAndBrandListFetched,setProductAndBrandListFetched] = useState(false);
    const [disableBrandEdit,setDisableBrandEdit] = useState(true);
    let [productData,setProductData] = useState({
        brandName:'',
        brandId:'',
        productId:'',
        productName:'',
        imeiNumber:'',
        newImeiNumber:'',
        simSku:'',
        simNumber:'',
    })
    //enquiry
    const [selectResolvedResonCat,setSelectResolvedResonCat] = useState([]);
    const [issueResolvedText ,setIssueResolvedText] = useState(null);
    const [issueReason,setIssueReason] = useState('');
    //
    const [replacementSumInsured,setReplacementSumInsured] = useState(null);
    const [gsxCheckList, setGsxCheckList] = useState([]);
    const [loanerPaymentModes, setLoanerPaymentModes] = useState([]);
    const [loanerPayableAmt, setLoanerPayableAmt] = useState('');

    const [refundToken, setRefundToken] = useState(null);
    const [serialNumberRefund, setSerialNumberRefund] = useState(null);
    const [refundRejectionReason, setRefundRejectionReason] = useState(null);
    const [approvalRemarks, setApprovalRemarks] = useState(null);
    const [approvalVariable, setApprovalVariable] = useState(null);
    const [configDescription,setConfigDescription] = useState(null)
    const [diagnosticsResult,setdiagnosticsResult] = useState(null);
    const [updatedpProductId,setUpdatedpProductId] = useState(null);
    const [isGsxDown,setIsGsxDown] = useState(false);
    const [isTransferIncomplete,setIsTransferIncomplete] = useState(false);
    const [checkListAllData,setCheckListAllData] = useState(null);
    const [gsxCheckListPickCarry, setGsxCheckListPickCarry] = useState([]);
    const [pickupCarryinGsxFlag,setPickupCarryinGsxFlag] = useState({
        isNonSerializedProduct:false,
        isStaticNonSerializedProduct:false,
        InwardCheckList :false,
        isUnabletoDiagnose:false
    });
    const [selectedSKUList,setSelectedSKUList] = useState([]);
    const [selectOsVersion,setSelectOsVersion] = useState([]);
    const [isInward ,setIsInward] = useState(false);
    const [generalElsChecklist,setGeneralElsChecklist] = useState([]);
    const [accessoriesList,setAccessoriesList] = useState([]);
    const [gsxElsCheckList,setGsxElsCheckList] = useState([]);
    const [miscellaneousList,setMiscellaneousList] = useState([]);
    const [ picMasterGSX,setPicMasterGSX] = useState([]);
    const [picLoader,setPicLoader] = useState(false);
    const [selectDeviceData,setSelectDeviceData] = useState({
        device:'',
        partCode:'',
        osversion:'',
        osversionObj:{}
    });
    // const [generalElsChecklist,setGeneralElsChecklist] = useState([]);
    const [cosmeticStatus,setCosmeticStatus] = useState('');
    const [selectedSKU , setSelectedSKU] = useState(null);
    const [otherImageLoader,setOtherImageLoader] = useState(false);
    const [showFMIPModal,setShowFMIPModal] = useState(false)

    const [diagnosisLabel, setDiagnosisLabel] = useState([]);
    const [GSXDiagnosisModalObj, setGSXDiagnosisModalObj] = useState({
        diagnosisSteps:"",
        engineerNotes:"",
        diagnosisStepsWords: "",
        diagnosisStepsErrorMessage: "",
        engineerNotesWords: "",
        engineerNotesErrorMessage: ""
    });

    const [paymentModes, setPaymentModes] = useState([]);
    const [paymentAmount, setPaymentAmount] = useState('')
    const [isAdvanceAdded, setIsAdvanceAdded] = useState();
    const [isPaymentReceiveStage, setIsPaymentReceiveStage] = useState();
	const [repairNotes, setRepairNotes] = useState('');
    const [isSameNotes, setIsSameNotes] = useState(false);
    // const [diagnosticsSuitesCalled, setDiagnosticsSuitesCalled] = useState(false)
    // const [diagnosisSuites, setDiagnosisSuites] = useState()
    // const [arrDiagnosisSuites, setArrDiagnosisSuites] = useState()

    const [allTransferLocations, setAllTransferLocations] = useState({
        forward: [],
        reverse: []
    });

    const [reqiredDocumentsMaster, setReqiredDocumentsMaster] = useState([])
    const [alreadyMarked, setAlreadyMarked] = useState()
    const [questionDetails, setQuestionDetails] = useState([]);
	const [mainQuery, setMainQuery] = useState([]);
    const [selectedRepair, setSelectedRepair] = useState(null);
    const [repairCheckList, setRepairCheckList] = useState([]);
    const [displayNotesModal, setDisplayNotesModal] = useState(false);
	const [receivedDateTime, setReceivedDateTime] = useState('');
    const [selectedClassification, setSelectedClassification] = useState(null);
    const [selectedCoverage, setSelectedCoverage] = useState(null);
    const [selectedSPart, setSelectedSPart] = useState(null);
    const [selectRefundDetails,setSelectRefundDetails] = useState();
    const [engineerNotesErrorMessage,setEngineerNotesErrorMessage] = useState('');
    const [diagnosisStepsErrorMessage,setDiagnosisStepsErrorMessage] = useState('');
    const [actionCategoriesList,setActionCategoriesList] = useState(null)
    const [selectActionMaster,setSelectActionMaster] = useState([]);
    const [completeAnalyseDataServiceCluster,setCompleteAnalyseDataServiceCluster]= useState({
		rootCause:'',
		resolution:'',
	})
    const [gsxChecklistData,setGsxChecklistData] = useState([])
    const [ServiceApproach,setServiceApproach] = useState({})
    const [shipToDropOFFLoc, setShipToDropOFFLoc] = useState(false);
    const [dropOffFlag, setDropOffFlag] = useState(false);
    
    const attemptCall =()=>{
        setCallLog(true);
        setShowModal({...showModal,showCallLogModal:true})
    }
    const closeModal = () =>{
        setShowModal(allModals);
        setShipToDropOFFLoc(false);
        setDropOffFlag(false);
    }

    useEffect(()=>{
        if([SERVICETYPE.ENQUIRY].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && !accessFn('READ_ONLY')){
            getIssueResolvedReason()
        }
        initWarrantyDropDown() ;
        if(!replacementFlowFlags.brandListFetched) {
            getBrandList();
        }
    },[])

    useEffect(()=>{
        if((claimNewWorkshopChecks) || ( pickupCarryInChecks && consumerServiceRequest && consumerServiceRequest.consumerProduct &&!consumerServiceRequest.consumerProduct.isAppleBrandID) ){
            if(!claimFlags.isPartsCalled){
                getPart();
            }
        }
        let isLocalNonSerializedProduct = consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig ? consumerServiceRequest.consumerProduct.productConfig.isSerializedProduct === false: false;
        let isLocalStaticNonSerializedProduct = consumerServiceRequest.isStaticNonSerializedProduct
        setPickupCarryinGsxFlag({...pickupCarryinGsxFlag,
            isNonSerializedProduct:isLocalNonSerializedProduct,
            isStaticNonSerializedProduct:isLocalStaticNonSerializedProduct 
        });
    },[consumerServiceRequest])
    useEffect(()=>{
        if( filterCancelrequest && filterCancelrequest =="Other"  ){
            if(cancelText && !isEmpty(cancelText) && cancelText.length >0){
                setDisableCancelReasonSave(false);
            }else{
                setDisableCancelReasonSave(true); 
            }
        }else if(filterCancelrequest && filterCancelrequest !=="Other"){
            if(cancelReasonsData && !cancelReasonsData.OtherReason){
                setDisableCancelReasonSave(false);
            }else{
                setDisableCancelReasonSave(true);
            }
        }else if(cancelReasonsData.OtherReason){
            if(cancelText && !isEmpty(cancelText) && cancelText.length >0){
                setDisableCancelReasonSave(false);
            }else{
                setDisableCancelReasonSave(true); 
            }
        }
    },[cancelText,filterCancelrequest])

    useEffect(()=>{
        if(consumerServiceRequest && consumerServiceRequest.servicelocation ){
        let starttime = moment.utc(consumerServiceRequest.servicelocation.WorkingFrom,"HH:mm:ss");
        let endtime = moment.utc(consumerServiceRequest.servicelocation.WorkingTo,"HH:mm:ss");
            setTime({...time,
                starttime:starttime.local().format('HH:mm')
                ,endtime:endtime.local().format('HH:mm')})
        // starttime = starttime.local().format('HH:mm');
        // endtime = endtime.local().format('HH:mm');
        // serviceLocationTimeCalled = true;
        }
        if(claimNewWorkshopChecks ){
            // getPart();
        }
        let localDisableBrandEdit = consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestDetails && consumerServiceRequest.ConsumerServiceRequestDetails.AllowOtherBrandForReplacement ? false : true;
        setDisableBrandEdit(localDisableBrandEdit);
        let localProductNotMapped = consumerServiceRequest.isProductToBeVerified ? consumerServiceRequest.isProductToBeVerified :false;
        setClaimFlags({...claimFlags,productNotMapped:localProductNotMapped});
    },[consumerServiceRequest])

    useEffect(()=>{
        fromJobVisitDisposition();
    },[visitDispositionDataPro])
    
    useEffect(()=>{
        fromJobVisitDispositionProd();
    },[visitDispositionDataUnPro])

    useEffect(()=>{
        if(actionCode== "Pending for Spares"){
            setPendingForSpares(true);
        }else if(actionCode=="Replacement"){
            setReplacementFlow(true);
        }else if(actionCode=="Requested for product replacement"){
            setReplacementFlow(true)
            setPicMaster( 
            [{ 'picType': "Symptom Defect or Panel Picture" },
            { 'picType': "Back Cover Sl.No Picture" },
            { 'picType': "Panel Sl.No" },
            { 'picType': "PCBI" },
            { 'picType': "Invoice Copy Picture" }])
        }else{
            setPendingForSpares(false);
            setReplacementFlow(false);
        }
    },[actionCode])

    
    // useEffect(()=>{
	// 	if(consumerServiceRequest && consumerServiceRequest.Source == 'VizioDashboard'){
	// 	//    let accessPoints = accessPoints;
	// 	let cancelOnsiteDropdown = ["Cancelled by End User",
	// 			"Cancelled by OEM",
	// 			"End User No Show",
	// 			"Duplicate Work Order",
	// 			"No Tech available",
	// 			"Wrong model",
	// 			"Environmental / Source issue not resolved",
	// 			"No Problem Found"
	// 		];
	// 	}else{
	// 		let	cancelOnsiteDropdown = [
	// 			"Duplicate call",
	// 			"Set not delivered to Customer",
	// 			"No Power",
	// 			"Houselock",
	// 			"Long Appointment - Customer to call back",
	// 			"Estimate given - Customer to Call Back",
	// 			"Customer doesnot want service/No complaint",
	// 			"Customer phone is not reachable & address is not found",
	// 			"Customer Refused to Pay",
	// 			"Non Service Location"
	// 		]
	// 	}
	// },[])

    //flag fraud
    useEffect(()=>{
		if(consumerServiceRequest && consumerServiceRequest.decisionFormQuestions && consumerServiceRequest.decisionFormQuestions.length > 0) {
            setDecionApiResponse(consumerServiceRequest.decisionFormQuestions);
        }
		
	},[])

    useEffect(()=>{
        if (qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && accessFn('GSX_FLOW')) {
           setPicMasterGSX ([
                { 'picType': "Front Image",'Tag': "Front Image",'loader':false ,'imgData':{}},
                { 'picType': "Back Image",'Tag': "Back Image",'loader':false,'imgData':{}  },
                { 'picType': "Top Image",'Tag': "Top Image" ,'loader':false ,'imgData':{}},
                { 'picType': "Bottom Image",'Tag': "Bottom Image",'loader':false  ,'imgData':{}},
                { 'picType': "Left Image",'Tag': "Left Image",'loader':false ,'imgData':{} },
                { 'picType': "Right Image",'Tag': "Right Image",'loader':false  ,'imgData':{}},
                { 'picType': "Device Invoice",'Tag': "Device Invoice",'loader':false  ,'imgData':{}}
            ]);
        }
        else {
            setPicMasterGSX([
                { 'picType': "Front Image",'Tag': "Front Image",'loader':false  ,'imgData':{}},
                { 'picType': "Back Image",'Tag': "Back Image",'loader':false,'imgData':{} },
                { 'picType': "Top Image",'Tag': "Top Image",'loader':false ,'imgData':{}},
                { 'picType': "Bottom Image",'Tag': "Bottom Image" ,'loader':false ,'imgData':{}},
                { 'picType': "Left Image",'Tag': "Left Image",'loader':false ,'imgData':{}},
                { 'picType': "Right Image",'Tag': "Right Image",'loader':false ,'imgData':{}},
                //{ 'picType': "Customer ID",'Tag': "Customer ID",'loader':false ,'imgData':{}},
                { 'picType': "Device Invoice",'Tag': "Device Invoice",'loader':false,'imgData':{} }
            ]);
        }
    },[])

    let picMasterNonGSX =[
        { 'picType': "Front Image" },
        { 'picType': "Back Image" },
        { 'picType': "Top Image" },
        { 'picType': "Bottom Image" },
        { 'picType': "Left Image" },
        { 'picType': "Right Image" },
    ];
    //
    let cancelOnsiteDropdown = [
        "Duplicate call",
        "Set not delivered to Customer",
        "No Power",
        "Houselock",
        "Long Appointment - Customer to call back",
        "Estimate given - Customer to Call Back",
        "Customer doesnot want service/No complaint",
        "Customer phone is not reachable & address is not found",
        "Customer Refused to Pay",
        "Non Service Location"
    ]
    const closeBillingModal = ()=>{
        setShowBillingModal(false)
        // setClaimFlags({claimFlags,showClaimBillingModal:false});
    }
    const getStatus = (id)=> {
        let status;
        switch (id) {
            case 'Mark Customer Arrival':
                status = 'Service initiated';
                break;
            case 'Start Repair':
                status = 'Service initiated';
                break;
            case 'EstimationGenerated':
                status = 'Estimation generated';
                break;
            case 'EstimationApproval':
                status = 'Estimation approval';
                break;
            case 'EstimationRejected':
                status = 'Estimation rejected';
                break;
            case 'Complete Repair':
                status = 'Repair completed';
                break;
            case 'Complete Without Repair':
                status = 'Repair completed';
                break;
            case 'Cancel Repair':
                status = 'Repair cancel';
                break;
            case 'Cancel Service':
                status = 'Service cancel';
                break;
            case 'Complete Service':
                status = 'Service completed';
                break;
            case 'Generate Invoice':
                status = 'Service invoice generated';
                break;
            case 'Initiate logistics request':
                status = 'Device dispatched';
                break;  
            case 'Dispatch Device':
                status = 'Device dispatched';
                break;
            case 'Device Received':
                status = 'Device received';
                break;
            case 'Parts Pending':
                status = 'Parts pending';
                break;
            case 'Parts received':
                status = 'Parts received';
                break;
            case 'Approve Estimation':
                status = 'Parts received';
                break;
            case 'Reject Estimation':
                status = 'Parts received';
                break;
            case 'Device shipped':
                status = 'Device shipped';
                break;
            case 'Diagnosis completed':
                status = 'Diagnosis completed';
                break;
            case 'Cannot be diagnosed':
                status = 'Cannot be diagnosed';
                break;
            case 'Repair initiated':
                status = 'Repair initiated';
                break;
            case 'Repair completed':
                status = 'Repair completed';
                break;
            case 'QC pending':
                status = 'QC pending';
                break;
            case 'Service completed':
                status = 'Service completed';
                break;
            case 'Device received':
                status = 'Device received';
                break;
            case 'Transfer Request':	
                status = 'Transfer Request';	
                break;	
            case 'DOA checklist':	
                status = 'DOA checklist';	
                break; 	
            case 'Receive device from CP':	
                status = 'Receive device from CP';	
                break; 	
            case 'Receive device from Repair Center':	
                status = 'Receive device from Repair Center';	
                break; 
            case 'Initiate Refund':
                status = 'Initiate Refund';
                break; 
            case 'Payment Pending':
                status = 'Payment pending';
				break;
            case 'Payment Received':
				status = 'Payment received';
				break;
            case 'Ready for shipment':
				status = 'Ready for shipment';
				break;
            case 'Ship to service centre':
                status = "Shipped to service centre"
                break;
            case 'Shipped to service centre':
                status = "Shipped to service centre";
                break;
            default:
                status = '-';
                break;
        }
        return status;
    };
    
    const preShowCancelRequestConfirmForPickUpCarryIn =(e)=>{
        Swal.fire({
			title: localString(
				"Please specify the reason..."
			),
			input: "textarea",
			showCancelButton: true,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("Ok"),
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.close();
				let remark = result.value || "";
				if (remark) {
					showCancelRequestConfirmForPickUpCarryIn(e,remark);
				} else {
					Swal.fire({
						title: "Oops...",
						text: localString("Please fill in the cancel reason before cancelling"),
						icon: "error",
					}).then((result) => {
						if (result.isConfirmed) {
                            preShowCancelRequestConfirmForPickUpCarryIn();
						}
					});
				}
			}
		});
    }

    const showCancelRequestConfirmForPickUpCarryIn=(e,remark)=>{
        let data = {
			"ConsumerServiceRequestID": ConsumerServiceRequestID,
			"Remarks": remark || undefined,
            "Status": getStatus(e)
		};
        setLoader(true);
		apiCall({
			url: "servicerequestFulfilment",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    getRequestById();
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }
    const reqestCancelledModal = ()=> {
        Swal.fire({
            title: localString("Request Cancelled"),
            type: 'success',
            icon:"success",
            confirmButtonText: localString("Ok"),
        }).then((result)=> {

            if(result.isConfirmed){
                setShowRequestValid({isRescheduleApplicable : false})
                getRequestById();
                
            }
        });
    }
    const showCancelAnytimeRequestConfirm=(remark)=>{
        let data = {
			"ConsumerServiceRequestID": ConsumerServiceRequestID,
			"Remarks": remark || undefined,
		};
        setLoader(true);
		apiCall({
			url: "cancelRequestWithoutDispatch",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    reqestCancelledModal();
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }
    const preShowCancelAnytimeRequestConfirm=()=>{
        Swal.fire({
			title:localString("Please specify the reason..."),
			input: "textarea",
			showCancelButton: true,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("Ok"),
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.close();
				let remark = result.value || "";
				if (remark) {
					showCancelAnytimeRequestConfirm(remark);
				} else {
					Swal.fire({
						title: "Oops...",
						text: localString("Please fill in the cancel reason before cancelling"),
						icon: "error",
					}).then((result) => {
						if (result.isConfirmed) {
                            preShowCancelAnytimeRequestConfirm();
						}
					});
				}
			}
		});
    }

    const createLogisticRequest = ()=>{
        let data = {
            ReferenceID : consumerServiceRequest && consumerServiceRequest.ReferenceID
        };
        if(claimNewWorkshopChecks){
            data.PreviousServiceTypeID = consumerServiceRequest.PreviousServiceTypeID
        }
		setLoader(true);
		apiCall({
			url:'createLogisticRequest',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRequestById();
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const showCancelRequestConfirm =(remark)=>{
        let data = {
			"ConsumerServiceRequestID": ConsumerServiceRequestID,
			"Remarks": remark || undefined,
		};
        setLoader(true);
		apiCall({
			url: "cancelRequestWithLogistics",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    reqestCancelledModal();
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    const  preShowCancelRequestConfirm = ()=>{
        Swal.fire({
			title: localString(
				"Please specify the reason..."
			),
			input: "textarea",
			showCancelButton: true,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("Ok"),
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.close();
				let remark = result.value || "";
				if (remark) {
					showCancelRequestConfirm(remark);
				} else {
					Swal.fire({
						title: "Oops...",
						text: localString("Please fill in the cancel reason before cancelling"),
						icon: "error",
					}).then((result) => {
						if (result.isConfirmed) {
                            preShowCancelRequestConfirm ();
						}
					});
				}
			}
		});
    }

    const  preProcessRequest = (status,fullfillmentData) => {
        if ((status == 'Complete Without Repair')) {
            let isFlag=true;
			processRequestAfterSwal(status, "No Repair Performed",fullfillmentData,isFlag)
		}else{
            Swal.fire({
                title: localString(
                    "Are you sure? Please specify remarks, if any..."
                ),
                html: '<textarea id="request-remark" class="cancel-remark"></textarea>',
                showCancelButton: true,
                cancelButtonText: localString("Cancel"),
                confirmButtonText: localString("Ok"),
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.close();
                    let remark = document.getElementById('request-remark').value ? document.getElementById('request-remark').value : "";
                    processRequestAfterSwal (status,remark,fullfillmentData);
                }
            });
        }
        
    }
    const processRequestAfterSwal =(statuses,remark,fullfillmentData,isFlag)=>{
        let data={}
        let status=''
        if(fullfillmentData){
            data = fullfillmentData;
            data.Remark = remark;
        }else{
            if(statuses){
                status = getStatus(statuses)
            }
            data = {
                ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                Status: status,
                Remarks: remark,
            };
            if(isFlag){
                data.CompleteWithoutRepair = true;
            }
        }
        setShowBillingModal(false)
        setShowModal({...showModal,showBillingModal:false})
        closeShipmentModal()
        let servicerequestFulfilmentUrl = accessFn('WORKSHOP_FLOW') ? 'servicerequestFulfilmentWorkshop' : 'servicerequestFulfilment';
        setLoader(true);
		apiCall({
			url: servicerequestFulfilmentUrl,
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    getRequestById(remark);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		}); 
    }

    const updateAdjustmentparts = (status) => {
        console.log('updateAdjustmentparts', status);
        Swal.fire({
            title: localString('Are you sure? Please specify remarks, if any...'),
            html: '<textarea id="request-remark" class="cancel-remark"></textarea>',
            showCancelButton: true,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("Ok"),
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.close();
                let remark = document.getElementById('request-remark').value ? document.getElementById('request-remark').value : '';
                let reqObj = {
                    ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                    Remarks: remark
                }
                setLoader(true);
                apiCall({
                    url: 'updateAdjustmentparts',
                    data: reqObj,
                    callBack: (result) => {
                        setLoader(false);
                        if (result.success) {
                            getRequestById(remark);
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: localString(displayError(result)),
                            });
                        }
                    },
                });
            }
        });
    }

    const resendAdvancePaymentLink = () => {
        let data = {
            ConsumerServiceRequestID: ConsumerServiceRequestID,
			checkApiKeyForPlanID: planID,
			CountryID: consumerServiceRequest.CountryID,
            tokenizeFlow:consumerServiceRequest && consumerServiceRequest.SAVE_CARD==="true"?true:undefined
        }
        if((consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE) && (consumerServiceRequest.CARD_HOLD == "FAILED")) {
            data.isSubscriptionEnabled = isSubscriptionEnabled;
            data.AuthorizeOnlyTxn = true;
            data.InitialClaimPPID = consumerServiceRequest.PaymentProcessID;
        }

        if(!replacementFlowChecks && countriesChecks && countriesChecks.countryConfig && countriesChecks.countryConfig.SkipAdvance){
            data.skipLogistics = true;
        }
		setLoader(true);
		apiCall({
			url:'createPaymentLink',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    let viewRequestValid = {...showViewRequestFlags};
                    viewRequestValid.isPaymentLinkSent = true;
                    setShowViewRequestFlags(viewRequestValid)
                    Swal.fire('', localString(result.msg), 'success');
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    };

    const setReschedulable = (isReschedulable)=>{
        consumerServiceRequest.isReschedulable = isReschedulable;
    };

    const openRescheduleServiceModal=(status)=>{
            
            if (!(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)) {
				return;
			}
			if (consumerServiceRequest && [SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN, SERVICETYPE.ON_SITE, SERVICETYPE.BUY_BACK, SERVICETYPE.INSTALLATION, SERVICETYPE.DEMO, SERVICETYPE.SERVICING, SERVICETYPE.DELIVERY, SERVICETYPE.RECYCLE_PICK_UP, 56, SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
				let data = {
                        CurrentDate:moment().format('YYYY-MM-DD') ,
                        CurrentTime: moment().format('HH:mm:ss'),
                        ConsumerServiceRequestID: ConsumerServiceRequestID,
                        Lat: consumerServiceRequest && consumerServiceRequest.Lat,
                        Lng: consumerServiceRequest && consumerServiceRequest.Lng
                };
				if ([SERVICETYPE.PICK_UP, SERVICETYPE.CARRY_IN, SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					data.PartnerServiceLocationID = consumerServiceRequest.PartnerServiceLocationID;
				}
                setLoader(true);
                apiCall({
                    url:'rescheduleSlots',
                    data: data,
                    callBack:(result)=>{
                        setLoader(false);
                        if(result.success){
                            if(status=="Reschedule"){
                                setShowModal({...showModal,showSheduleModal:true})
                            }else if(status="Service reschedule"){
                                setShowModal({...showModal,showCreateResheduleModal:true}) 
                            }else if(status=="Set Schedule"){
                                setShowModal({...showModal,showSetSheduleModal:true}) 
                                //  showSetSheduleModal
                            }
                            
                            // setShowSheduleModal(true)
                            if(result.data && result.data.length > 0){
                                setReschedulable(result.success || false);
                                setScheduleDateArray(result.data)
                                setSelectedScheduleDate(result.data[0])
                                setScheduleSlotArray(result.data[0].slots)
                                let ScheduleSlotArray = result.data[0].slots 
                                // setSelectedSlot(undefined);
                                // 	showSlotSelection = true;
                                setSlotAvailable(false);
                                    for (let i = ScheduleSlotArray.length - 1; i >= 0; i--) {
                                        if ( ScheduleSlotArray[i].IsActive) {
                                            setSelectedSlot(scheduleSlotArray[i])
                                            selectedSlot = scheduleSlotArray[i];
                                            setSlotAvailable(true)
                                        }
                                    }
                                // 	$('#rescheduleservice').modal('show');
							} else {
								setReschedulable(false);
                            }
                        }else {
                            Swal.fire({
                                title:'', 
                                text:localString(displayError(result)),
                                icon:'error'
                            });
                        }
                    }
                });
			}
    }

    const openScheduleServiceModal=()=>{
        setShowModal({...showModal,showSetSheduleModal:true})
        // setShowSheduleModal(true)
    }
    

    const getTradeInDashboardDetails = () => {
        let data = {
            ProductUniqueID: consumerServiceRequest && consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.ProductUniqueID : undefined,
		};
        setLoader(true);
		apiCall({
			url: "getDashboardDetails",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    if(result.data){
                        if (Array.isArray(result.data)) {
                            setTradeInDashboardDetails(result.data[0]);
                        }else {
                            setTradeInDashboardDetails(result.data);
                        }
                    }
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
                        confirmButtonText: localString('OK')
					});
				}
			},
		});
    }

    const getTradeInDetails = (logisticsNeeded) => {
        let data = {
            ProductUniqueID: consumerServiceRequest && consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.ProductUniqueID : undefined,
		};
        setLoader(true);
		apiCall({
			url: "getCalculation",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    if(result.data){
                        if (Array.isArray(result.data)) {
                            setTradeInDetails(result.data[0]);
                        }else {
                            setTradeInDetails(result.data);
                        }
                        if(logisticsNeeded) {
                            setShowModal({...showModal, showUpdateLogisticModal: true })
                        }
                    }
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
                        confirmButtonText: localString('OK')
					});
				}
			},
		});
    }

    const openUploadLogisticsModal = () => {
		if (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 24) {
			if (!tradeInDashboardDetails) {
                getTradeInDashboardDetails();
            }
			if (!tradeInDetails) {
				getTradeInDetails(true)
			} else {
                setShowModal({...showModal, showUpdateLogisticModal: true })
			}
		} else {
            setShowModal({...showModal, showUpdateLogisticModal: true })
		}
	}

    const openUpdateServiceCenterModal = () => {	
        setShowModal({...showModal, showUpdateServiceModal: true })				
	}	

    const openDoaChecklistModal = () => {		
        setShowModal({...showModal, showDoaChecklistModal: true })				
	}	

    const openPlanTypeModal = () => {		
        setShowModal({...showModal, showPlanTypeModal: true })				
	}	


    // const openInitiateRefundModal = () => {	
    //        setShowModal({...showModal, showInitiateRefundModal: true })				
	// }	

    const getDocumentsMaster = (openModal = false)=>{
		setLoader(true);
        apiCall({
			url:'getDocuments',
			method:"GET",
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let documentsMaster = result.data;
					setReqiredDocumentsMaster(documentsMaster)
                    if (openModal == true) {
                        setShowModal({...showModal, showUploadClaimDocumentModal: true })	
                    }		
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const claimCompleteRepair = (status) =>  {
		if(consumerServiceRequest && consumerServiceRequest.autoClaimFulfillmentFlow){
            openUpload()				
		} else {
			claimRequestNonWorkshop(status);
		}
	}

    const openUpload = () => {
        getDocumentsMaster(true);
    }


    const resetTradeInRequest = () => {
		let data = {
			ProductUniqueID:consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductUniqueID,
		}
        setLoader(true);
		apiCall({
			url: "resetTradeIn",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    getRequestById();
                }else {
                    Swal.fire({
                        icon: 'error',
                        text: localString(displayError(result)),
                        type: 'error',
						confirmButtonText: localString('OK')
                    })
                }
				
			},
		});
	}

    const  ReassignModal =()=>{
        setShowModal({...showModal,showReassignModal:true})
    }

//generate invoice button


const changeBillingDetails = () => {
    setShowBillingModal(false);
    setActiveTab({ShowGstInfo:true});
    const element = document.getElementById("Billing_Details_Tab");
    element.scrollIntoView(true);
}

    const openBillingDetailModal =(status)=>{
		if(countriesChecks.TaxDetailName && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.IsWarrantyApplicable){
            setShowBillingModal(true);
            setActiveTab({ShowGstInfo:true});
            // setShowModal({...showModal,showBillingModal:true})
        }else if(countriesChecks.TaxDetailName){ //for gsx
            setShowBillingModal(true);
            setActiveTab({ShowGstInfo:true});
        }else{
            if(consumerServiceRequest.consumerProduct.isAppleBrandID){
                processRequestNextPickUp(status);
            }else if(!consumerServiceRequest.consumerProduct.isAppleBrandID){
                processRequestNextPickUp(status);
            }else if(consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
                preProcessRequest(status);
            }
        }
    }
    //************ONSITE */
    const  preClaimRequest =(e) =>{
        Swal.fire({
			title: localString("Are you sure?"),
			showCancelButton: true,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("Ok"),
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.close();
				let remark = result.value || "";
				if (remark) {
					claimRequest (e);
				} else {
					Swal.fire({
						title: '',
                        text: localString(displayError(result)),
                        icon: 'error',
                        confirmButtonText: localString('OK')
					});
				}
			}
		});
    }

    const claimRequest = (nextStatus) => {
        let data = {
			"ConsumerServiceRequestID":consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            "Status": nextStatus,
            "ServiceTypeID" : consumerServiceRequest && consumerServiceRequest.ServiceTypeID,
            "SoldPlanID" :consumerServiceRequest && consumerServiceRequest.SoldPlanID
		};
        if(['Service cancel', 'Claim rejected', 'Claim withdrawn'].indexOf(nextStatus) > -1) {
            data.Remarks = cancelReasonsData && cancelReasonsData.OtherReason || (filterCancelrequest == 'Other') ? cancelText : filterCancelrequest ? filterCancelrequest : undefined;
        }
        if (accessFn('ALLOW_ADDITIONAL_REASON')) {
            if(internalReason){
                data.AdditionalRemarks = internalReason
            }
        }
        setLoader(true);
		apiCall({
			url: "claimRequest",
			data: data,
			callBack: (result) => {
				setLoader(false);
                setShowModal({...showModal,showCancelRequestModal:false});
				if (result.success) {
                    getRequestById();
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		}); 
    };

    const getReasons = (nextStatus) => {
        let status ;
        if (nextStatus == 'BER cancel') {
			status = 'Repair cancel';
		}
        let data = {
			"Action": status ? status : nextStatus ,
            "ServiceTypeID": consumerServiceRequest && consumerServiceRequest.ServiceTypeID,
            "Status":consumerServiceRequest && consumerServiceRequest.Status
		};
        setFilterCancelrequest()
        setShowModal({...showModal, showCancelRequestModal:true})
        setLoader(true);
		apiCall({
			url: "getReasons",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    setCancelReasonsData(result.data);
                    if(result.data){
                        let cancelReasonRes =  result.data.Reasons &&  result.data.Reasons.length > 0 ? result.data.Reasons : null;
                        initCancelDropdown( cancelReasonRes,nextStatus);
                    }else{
                        if(claimNewWorkshopChecks){
                            showConfirmation(nextStatus)
                        }else if(onsiteChecks){//onsite
                            preClaimRequest(nextStatus);
                        }else if(berChecks){//ber
                            preBerRequestNext(nextStatus)
                        }
                    }
				} else {
                    if(claimNewWorkshopChecks){
                        showConfirmation(nextStatus)
                    }else if(onsiteChecks){//onsite
                        preClaimRequest(nextStatus);
                    }else if(berChecks){//ber
                        preBerRequestNext(nextStatus)
                    }
				}
			},
		}); 
    };

    const initCancelDropdown = (cancelReasonRes,status) =>{
        let cancelReasonData = cancelReasonRes.map(v => ({...v, value: v.Reason}))
        setSelectedCancelReason( cancelReasonData,status);
        getModalHeadingText(cancelReasonData,status)
        setCancelText('');
        setInternalReason('');
        setFilterCancelrequest('');
    }

    const getModalHeadingText = (cancelReasonData,status) => {
        let headers = 'Please select a reason for cancelling';
        if(status == 'Service cancel') {
            headers = cancelReasonData && cancelReasonData.OtherReason ? 'Please fill in the cancel reason before cancelling' : headers;
        } else if(status == 'Claim rejected') {
            if(berChecks || [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
                headers = 'Please select a reason for cancelling'
            }
            else{
                headers = cancelReasonData && cancelReasonData.OtherReason ? 'Please fill in the reason for rejecting claim' : 'Please select a reason for rejecting claim';
            }
            if(replacementFlowChecks){
                headers = cancelReasonsData && cancelReasonsData.OtherReason ? 'Please fill in the reason for rejecting claim' : 'Please select a reason for rejecting claim';
            }
        }else if(status == 'Waiting on customer'){
            headers = cancelReasonsData && cancelReasonsData.OtherReason ? 'Please fill in the reason' : 'Please select a reason';
        }
        if(['Claim withdrawn'].indexOf(status) > -1){
            if(onsiteChecks || replacementFlowChecks){
                headers =  "Please select a reason for withdrawing claim";
            }else{
                headers = 'Please select a reason for cancelling';
            }
        }
        setHeader(headers)
        // $("#cancelModalHeader").html(localString(header));
    };

    //create visit
    const rescheduleService = (status) => {
        if(status=="Reschedule"){
            setShowModal({...showModal,showSheduleModal:true})
        }else if(status="Service reschedule"){
            setShowModal({...showModal,showCreateResheduleModal:true}) 
        }else if(status=="Set Schedule"){
            setShowModal({...showModal,showSetSheduleModal:true}) 
            //  showSetSheduleModal
        }
        getScheduleDateAndSlot(status);
        loadEngineers(
            consumerServiceRequest.PartnerServiceLocationID,
            consumerServiceRequest.ConsumerServiceRequestID,
            consumerServiceRequest.ServiceTypeID
        );
    }
    const getScheduleDateAndSlot = (status) => {
        if ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE, 4,SERVICETYPE.INSTALLATION, 6, 7, 8, 9,61,62,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
            setLoader(true);
            let data = {
                CurrentDate: moment().format('YYYY-MM-DD'),
                CurrentTime: moment().format('HH:mm:ss'),
                ConsumerServiceRequestID:  ConsumerServiceRequestID,
                Lat: consumerServiceRequest && consumerServiceRequest.Lat,
                Lng:  consumerServiceRequest && consumerServiceRequest.Lng,
                PartnerServiceLocationID: consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID
            };
            apiCall({
                url:'rescheduleSlots',
                data: data,
                callBack:(result)=>{
                    setLoader(false);
                    if(result.success){
                        if(status && (status == 'Reschedule Requested')) {
                            setShowModal({...showModal, createVisitModal:true}) 
                        }else {
                            setShowModal({...showModal, showCreateResheduleModal:true}) 
                        }
                        if(result.data && result.data.length > 0){
                            setReschedulable(result.success || false);
                            setScheduleDateArray(result.data)
                            setSelectedScheduleDate(result.data[0])
                            setScheduleSlotArray(result.data[0].slots)
                            setSlotAvailable(false);
                        } else {
                            setReschedulable(false);
                        }
                    }else {
                        Swal.fire({
                            title:'', 
                            text:localString(displayError(result)),
                            icon:'error'
                        });
                    }
                }
            });
        }
    }
    const loadEngineers = (PartnerServiceLocationID, ConsumerServiceRequestID, ServiceTypeID, Filters)=> {
        if (!PartnerServiceLocationID || PartnerServiceLocationID == 0) {
            return;
        }
        if (!ConsumerServiceRequestID || ConsumerServiceRequestID == 0) {
            return;
        }
        if (!ServiceTypeID || ServiceTypeID == 0) {
            return;
        }
        setEngineerLoader(true);
        let params = {
            PartnerServiceLocationID: PartnerServiceLocationID,
            ConsumerServiceRequestID: ConsumerServiceRequestID,
            ServiceTypeID: ServiceTypeID,
            Filters: Filters ? Filters : []
        };
        if(consumerServiceRequest) {
            params.BrandID = consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.BrandID : undefined;
            params.ProductSubCategoryID = consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.ProductSubCategoryID : undefined;
            params.Source = consumerServiceRequest.Source;
            params.Zipcode = consumerServiceRequest.Zipcode;
        }
        apiCall({
			url: "getEngineersByServiceLocations",
			data: params,
			callBack: (result) => {
                setEngineerLoader(false);
				if (result.success) {
                    let engineerData = result.data;
                    if (engineerData && engineerData.length > 0) {
                        let engineerName = engineerData.map(v => ({...v, value: v.Name}))
                        setEngineerData( engineerName);
                    }
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    };

    //job update status

    const toResolvedDisposition = () =>{
        if(updateJobData.picker1BiginTimeVal < time.starttime  || updateJobData.picker1EndTimeVal > time.endtime || updateJobData.picker2BiginTimeVal < time.starttime || updateJobData.picker2EndTimeVal > time.endtime  ){
            Swal.fire({
                text: "Enter correct journey time",
            })
            return;
        }
        setJobModalState(2);
        if (visitDispositionLoaded) {
            fromJobVisitDisposition()
        } else {
            getVisitDispositions()
        }
    }

    const toUnresolvedDisposition=()=>{
        setVisitUnresolveData({...visitUnresolveData,actionStatus:''})
        if(updateJobData.picker1BiginTimeVal < time.starttime  || updateJobData.picker1EndTimeVal > time.endtime || updateJobData.picker2BiginTimeVal < time.starttime || updateJobData.picker2EndTimeVal > time.endtime  ){
            Swal.fire({
                text: "Enter correct journey time",
            })
            return;
        }
        setJobModalState(3);
        
        if (visitDispositionLoaded) {
            fromJobVisitDispositionProd()
        } else {
            getVisitDispositions()
        }
    }

    const showJobUpdate =()=>{
        // setOnsiteFlags({...onsiteFlags,fromJobFlag:true})
        setShowModal({...showModal,showUpdateJobModal:true});
        setJobModalState(1);
        setFromJobFlag(true);
        if(consumerServiceRequest){
            updateChargeType();
        }
    }

    const updateChargeType = ()=> {
        if([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
            let chargeTypeData =[{
                Name: '',
                Placeholder: 'Part name',
                Display: 'Parts',
                Type: 'Part'
                }
            ];
            setChargeType(chargeTypeData)
            setOnsiteFlags({
                ...onsiteFlags,
                isOnlyPartsAddStatus:true,
                showsavebutton:true
            })
        }
    };
    
    const getVisitDispositions = () =>{
        if (consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID && (consumerServiceRequest.ServiceLocationEngineerID || (consumerServiceRequest.servicelocation && !consumerServiceRequest.servicelocation.EngineerApplicable))) {
            let data = {
            "ProductSubCategoryID": consumerServiceRequest.consumerProduct.ProductSubCategoryID,
            "Source": getSource(),
            "ServiceLocationEngineerID": consumerServiceRequest.ServiceLocationEngineerID
        }
        setLoader(true);
		apiCall({
			url: "getVisitDispositions",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    if(result.data){
                        setVisitDispositionLoaded(true);
                        setVisitDispositionDataUnPro(result.data && result.data.Unproductive)
                        setVisitDispositionDataPro(result.data && result.data.Productive);

                            
                        // if(jobModalState == 2 || jobModalState == 3){
                        //Productive
                        // fromJobVisitDisposition(result);
                        //Unproductive
                        // fromJobVisitDispositionProd(result);

                        // }else{
                        //     // initVisitDisposition()
                        //     // initVisitDispositionProductive()
                        // }
                    }
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
            });
        }
    }

    const  fromJobVisitDisposition =()=>{
        let visitDispositionPro  = visitDispositionDataPro && visitDispositionDataPro.map(v =>  ({...v, value: v.VisitStatus}));
        let uniqArrPro = uniqBy(visitDispositionPro, 'VisitStatus');
        setSelectVisitDispositionPro(uniqArrPro);
    }
    const fromJobVisitDispositionProd=()=>{
        let visitDispositionUnPro  = visitDispositionDataUnPro && visitDispositionDataUnPro.map(v =>  ({...v, value: v.VisitStatus}));
        let uniqArrUnPro = uniqBy(visitDispositionUnPro, 'VisitStatus');
        setSelectVisitDispositionUnPro(uniqArrUnPro);                
    }
    
    const backToJobUpdate=()=>{
        setJobModalState(1);
    }
    const closeJobUpdateModal=()=>{
        setJobModalState(0);
    }
    const backToCloseVisit=()=>{
        setJobModalState(2)
    }
    

    const getServiceLocationEngineerId = ()=>{
        if (consumerServiceRequest && consumerServiceRequest.ServiceLocationEngineerID) {
            return consumerServiceRequest.ServiceLocationEngineerID
        } else {
            let logs = consumerServiceRequest.logs;
            let latestDate = "";
            let engineerId = "";
            latestDate = logs.map(function(e) { return e.CreatedDate; }).sort().reverse()[0]
                for (let i = 0; i < logs.length; i++) {
                    if (latestDate === logs[i].CreatedDate) {
                        engineerId = logs[i].ServiceLocationEngineerID;
                        break;
                    }else{
                }
            }
            return engineerId;
        }
    }

    // const switchFlows = (actionCode)=>{
    //     if(actionCode== "Pending for Spares"){
    //         setPendingForSpares(true);
    //     }else if(actionCode=="Replacement"){
    //         setReplacementFlow(true);
    //     }else if(actionCode=="Requested for product replacement"){
    //         setReplacementFlow(true)
    //         setPicMaster( 
    //         [{ 'picType': "Symptom Defect or Panel Picture" },
    //         { 'picType': "Back Cover Sl.No Picture" },
    //         { 'picType': "Panel Sl.No" },
    //         { 'picType': "PCBI" },
    //         { 'picType': "Invoice Copy Picture" }])
    //     }
    // }
    const addDocArray = (e)=>{
        // e.preventDefault();
        setLoader(true);
        setSelectedPicType(e.target.name);
        let selectedPicType = e.target.name
        let newfile = e.target.files;
        if (newfile.length > 0) {
        // showAddBtn = false;
        let orginalName = e.target.files[0].name
        let fileType = newfile[0].type;
        let dataImgType = 'image';
        let docname = newfile[0].name;
        let ext = fileType.split("/").length ? "."+fileType.split("/")[fileType.split("/").length - 1] : "";
        let key = "FEWEBA" + generateUUID() + ext;
        if (fileType == 'application/pdf') {
            dataImgType = 'pdf';
        }
        let doc = {
                ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                FilePath: key,
                Type: dataImgType,
                FileName: key,
                OriginalName: orginalName,
                Tag: selectedPicType,
                DocumentID: 12,
                app: 'WebApp'
            };

        //reader.readAsDataURL(newfile[0]);

        apiCall({
			url: "uploadDocuments",
			data: { Type: fileType, Key: key },
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    let xhr = new XMLHttpRequest();
                    xhr.open("PUT", result.data);
                    xhr.setRequestHeader("Cache-Control", "public,max-age=3600");
                    xhr.setRequestHeader('x-amz-acl', 'public-read');
                    xhr.onload = function() {
                        if (xhr.status === 200) {
                        // e.target.value = "";
                        let totalLength = docArray.length;
                        doc.id=totalLength+1;
                        let fileUrl = result.data.split('?');
                        doc.FileUrl = fileUrl[0];
                        let docData=[...docArray];
                        docData.push(doc)
                        setDocArray(docData);
                        if(selectedPicType == 'Other'){
                            let otherArray =  [...otherArrImagesPreview]
                            otherArray.push(doc)
                            setOtherArrImagesPreview(otherArray);
                        }

                        
                apiCall({
                    url: "addProductDocs",
                    data: [{ConsumerProductID: consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ConsumerProductID, FilePath: key, Type: 'image', DisplayName: selectedPicType}],
                    callBack: (result) => {
                        setLoader(true)
                        if (result.success) {
                            setLoader(false); 
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: localString(displayError(result)),
                            });
                        }
                    },
                    });
                //orginalName = orginalName.substring(orginalName.lastIndexOf('\\') + 1, orginalName.length + 1);
                }
            }.bind(this);
            xhr.onerror = function() {}.bind(this);
            xhr.send(newfile[0]);

            } 
        },
    });
    }
}

const removeAddedDoc = (id)=>{
    let removeDocArray = docArray && docArray.filter(item=>id !==item.id);
    setDocArray(removeDocArray);
    if(selectedPicType == 'Other'){
        let removeOtherArray = otherArrImagesPreview && otherArrImagesPreview.filter(item=>id !==item.id);
        setOtherArrImagesPreview( removeOtherArray);
    }
}

    const submitIris =  ()=> {
        let irisMappingsCodes=selectInitIrisAction && selectInitIrisAction.map(action=>{
            return action.SymptomFaultFaultActionMappingID
        })
        let data = {
            "EngineerID":consumerServiceRequest && consumerServiceRequest.ServiceLocationEngineerID,
            "SymptomFaultFaultActionMappingIDs":irisMappingsCodes,
            "ConsumerServiceRequestID":consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID
        }
        apiCall({
			url: "addSymptomsFaultsAction",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    getRequestById();
                    setJobModalState(0);
                    setShowModal({...showModal,showIrisModal:false});
                        Swal.fire({
                            text:localString('IRIS Codes has been updated successfully.'),
                            icon:'success'
                        })

				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    const addToPartsArray = (e)=> {
        e.preventDefault();
        if (!partItem || partItem == {}) {
            Swal.fire({
                text:localString("Please add a part from dropdown."),
                icon:'error'
            });
            return;
        }
        for (let iCount = 0; iCount < partsArray.length; iCount++) {
            if (partsArray[iCount].PartCode == partItem.PartCode) {
                Swal.fire({
                    text:localString('Part is already added'),
                    icon:'error'
                })
                return;
            }
            if (partsArray[iCount].PartID == consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.FinishedGoodID && partsRequested[iCount].PartTransactionStatusID != 17) {
                Swal.fire({
                    title: 'Sorry',
                    text:localString('Full box unit is already selected.'),
                    icon:'error'
                })
                return;
            }
        }

        if (partsRequested) {
            for (let iCount = 0; iCount < partsRequested.length; iCount++) {
                if (partsRequested[iCount].PartID ==consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.FinishedGoodID && partsRequested[iCount].PartTransactionStatusID != 17) {
                    Swal.fire({
                        title: 'Sorry',
                        text:localString('Full box unit is already selected.'),
                        icon:'error'
                    })
                    return;
                }
            }
        }

        if(consumerServiceRequest && consumerServiceRequest.Source == 'SkyworthDashboard'){
            if(docArray && docArray.length>0){
                //nothing
            }else{
                Swal.fire({
                    text:'Image is required',
                    icon:'error'
                });
                return;
            }
        }

        let partWarranty = true;
        setPrice("");
        setAvailableQuantity("");
        if (consumerServiceRequest && consumerServiceRequest.IsUnderWarranty && consumerServiceRequest.IsWarrantyApplicable) {
            partWarranty = true
        } else {
            // partWarranty = partwarranty == "In warranty" ? true : false;
        }
        // let validPartItem={... partItem}
        if (partWarranty && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID)==-1) {
            partItem.Charge = 0;
            partItem.Price = 0;
        }
        let totalLength= partsArray.length;
        partItem.id=totalLength+1;
        partItem.Remarks = addJobPart.remark;
        partItem.IsDeducted = false;
        partItem.InWarranty = partWarranty;
        partItem.ChargeType = "Credit";
        partItem.Documents = docArray;
        let arrOfPart = [...partsArray];
        arrOfPart.push(partItem);
        setPartsArray(arrOfPart);
        // partItem = undefined;
        // partname = "";
        setpartItem(partItem);
        setAddJobPart({
            ...addJobPart,
            part:"",
            warranty:"",
            remark:""
        });
        setDocArray([]);
        setOtherArrImagesPreview([]);
        setPartJobFlag({...partJobFlag,partSelected:false})
    };
    const completeVisitPartsPending = ()=>{
        let dispositionID;
        if (pendingForSpares && consumerServiceRequest && consumerServiceRequest.Source === 'SkyworthDashboard') {
            dispositionID = actionStatusProductiveSelected?actionStatusProductiveSelected.VisitDispositionID:undefined
        } else {
            dispositionID = actionStatusSelected? actionStatusSelected.VisitDispositionID: undefined
        }
        let data = {
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "VisitDispositionID": dispositionID,
            "EngineerID": consumerServiceRequest.ServiceLocationEngineerID,
            // "Remarks": remarksCloseVisit
        }
        if(pendingForSpares){
            data.Parts = partsArray;
        }
        apiCall({
			url: "createPartRequest",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    getRequestById();
                    setShowModal({...showModal,showUnresolveVisitModal:false})
                //      $("#visit-disposition-modal").modal('hide')
                // $("#visit-disposition-modal-productive").modal('hide')
                // $("#job-update-modal").modal('hide')
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    const completeVisit = (flag) => {
        let dispositionID;
        if (jobModalState == 4 || onsiteFlags.resolvedFlag) {
            dispositionID = actionStatusProductiveSelected?actionStatusProductiveSelected.VisitDispositionID:undefined
        } else {
            dispositionID = actionStatusSelected? actionStatusSelected.VisitDispositionID: undefined
        }
        let data = {
            "ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            "VisitDispositionID": dispositionID,
            "EngineerID": consumerServiceRequest && consumerServiceRequest.ServiceLocationEngineerID,
            "Remarks": flag == 'ResolvedModal' ? visitResolveData.visitText : visitUnresolveData.visitText,
            "EngineerApplicable": consumerServiceRequest.servicelocation && consumerServiceRequest.servicelocation.EngineerApplicable
        }
        if (actionStatusSelected && actionStatusSelected.ExternalReferenceID && actionStatusSelected.ExternalReferenceID == "REQ_REF_CSM") {
            if(consumerServiceRequest && consumerServiceRequest.Source == "SkyworthDashboard") {
                data.RequestStateID = 41;
            }
            if(replacementFlow && consumerServiceRequest && consumerServiceRequest.Source == "SkyworthDashboard"){
                data.Documents = docArray;
            }
            data.Status = 'Replacement Request Generated'
            setLoader(true);
            apiCall({
                url: "processReplacementRequestVisit",
                data: data,
                callBack: (result) => {
                    setLoader(false);
                    if (result.success) {
                        if (onsiteFlags.resolvedFlag || jobModalState == 4) {
                            submitIris()
                        } else {
                            getRequestById()
                        }
                        setShowModal({...showModal,showUnresolveVisitModal:false, showResolveVisitModal: false});
                    // $("#visit-disposition-modal").modal('hide')
                    // $("#visit-disposition-modal-productive").modal('hide')

                    } else {
                        Swal.fire({
                            icon: "error",
                            text: localString(displayError(result)),
                        });
                    }
                },
            });
        } else if ([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && isBoseFlow) {
			if (onsiteFlags.resolvedFlag) {
				data.Status = "Service completed";
			}
			else {
				data.Status = "Pending for assignment";
			}
            setLoader(true);
            apiCall({
                url: "servicerequestFulfilment",
                data: data,
                callBack: (result) => {
                    setLoader(false);
                    if (result.success) {
                        getRequestById();
                        setShowModal({...showModal,showUnresolveVisitModal:false, showResolveVisitModal: false});
                    } else {
                        Swal.fire({
                            title: '',
							text: localString(displayError(result)),
							icon: 'error',
							confirmButtonText: localString('OK')
                        });
                    }
                },
            });
		} else {
            setLoader(true);
            apiCall({
                url: "updateEngineerVisit",
                data: data,
                callBack: (result) => {
                    setLoader(false);
                    if (result.success) {
                        if ((onsiteFlags.resolvedFlag || jobModalState == 4) && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
                            submitIris()
                        } else {
                            getRequestById()
                        }
                        setShowModal({...showModal,showUnresolveVisitModal:false, showResolveVisitModal: false});
                    // $("#visit-disposition-modal").modal('hide')
                    // $("#visit-disposition-modal-productive").modal('hide')

                    } else {
                        Swal.fire({
                            icon: "error",
                            text: localString(displayError(result)),
                        });
                    }
                },
            });
        }
    }

    const updateJobDetails = (flag)=>{
        // setLoader(true);
        let t1, t2, t3, t4, t5;
        let beginJourneyTime ;
        let endJourneyTime ;
        let beginServiceTime ;
        let endServiceTime ;
    
        if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE || (consumerServiceRequest.ServiceTypeID == 3 && isBoseFlow)) {
            completeVisit(flag);
            return;
        } else {
            if (fromJobFlag) {
                t1 = updateJobData.date;
                t2 = updateJobData.picker1BiginTimeVal;
                t3 = updateJobData.picker2EndTimeVal;
                t4 = updateJobData.picker2BiginTimeVal;
                t5 = updateJobData.picker2EndTimeVal;
            } else {
            //   t1 = $('#datepicker').val();
            //   t2 = $('#picker1').val();
            //   t3 = $('#picker2').val();
            //   t4 = $('#picker3').val();
            //   t5 = $('#picker4').val();
            }

            if(t1=='' || t2=='' || t3=='' || t4=='' || t5==''){
                Swal.fire({
                    text:'Select date and time',
                    icon:'error'
                }).then({
                    //return;
                });
                return;
            }

            let beginJourney = moment(t1+' '+t2,"DD/MM/YYYY HH:mm").toDate()
            let endJourney = moment(t1+' '+t3,"DD/MM/YYYY HH:mm").toDate()
            let beginService = moment(t1+' '+t4,"DD/MM/YYYY HH:mm").toDate()
            let endService = moment(t1+' '+t5,"DD/MM/YYYY HH:mm").toDate()


            beginJourneyTime = moment(beginJourney).format('YYYY-MM-DDTHH:mm:ssZ');
            endJourneyTime = moment(endJourney).format('YYYY-MM-DDTHH:mm:ssZ');
            beginServiceTime = moment(beginService).format('YYYY-MM-DDTHH:mm:ssZ');
            endServiceTime = moment(endService).format('YYYY-MM-DDTHH:mm:ssZ');

            let starttime1 = moment.utc(t1+' '+consumerServiceRequest.servicelocation.WorkingFrom,"DD/MM/YYYY HH:mm:ss");
            let endtime1 = moment.utc(t1+' '+consumerServiceRequest.servicelocation.WorkingTo,"DD/MM/YYYY HH:mm:ss");

            let starttime = starttime1.local().format('YYYY-MM-DDTHH:mm:ssZ');
            let endtime = endtime1.local().format('YYYY-MM-DDTHH:mm:ssZ');

            if(!(starttime<=beginJourneyTime && beginJourneyTime<=endJourneyTime && endJourneyTime<=beginServiceTime && beginServiceTime<=endServiceTime && endServiceTime<=endtime)){
            Swal.fire({ 
                text:'Please enter time between service center working hours',
                icon:'error'
            });
            return;
            }
        }
        
        let data = {
            "EngineerID": getServiceLocationEngineerId(),
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "journeyStartTime":beginJourneyTime,
            "journeyEndTime": endJourneyTime,
            "serviceStartTime": beginServiceTime,
            "serviceEndTime": endServiceTime,
            "IsUnderWarranty"  : (consumerServiceRequest && consumerServiceRequest.consumerProduct)?consumerServiceRequest.consumerProduct.IsUnderWarranty:false,
            "IsWarrantyApplicable": (consumerServiceRequest && consumerServiceRequest.IsWarrantyApplicable)?consumerServiceRequest.IsWarrantyApplicable: false
        };
        if (jobModalState == 4 || (jobModalState == 5 && consumerServiceRequest.Source == "SkyworthDashboard") ) {
            data.SerialNumber = visitResolveData.serialNumber
        }

        if(consumerServiceRequest && consumerServiceRequest.Source == "SkyworthDashboard" && jobModalState == 3 && replacementFlow && docArray && docArray.length<1){
            Swal.fire({text:'Image is required',icon:'error'})
            return;
        }
        setLoader(true);
        apiCall({
			url: "updateJobDetails",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    // $('#updateJob').modal('hide');
                    if (fromJobFlag) {
                        setJobModalState(0)
                        if (jobModalState == 4 || jobModalState == 3 ) {
                            completeVisit()
                        }
                        if(jobModalState == 5){
                            completeVisitPartsPending();
                        }
                    }
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    const jumpToIrisSubmission = ()=> {
        if(pendingForSpares){
            jumpToAddParts();
        } else {
            setJobModalState(4)
            if (onsiteFlags.isFetchedIris) {
            // pass since data is cached
            } else{
                getIrisCodes()
            }
        }
    }

    const loadPartsListing=()=>{
        // setLoader(true);
        let data = {
            ConsumerServiceRequestID:consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            ProductID : consumerServiceRequest && consumerServiceRequest.consumerProduct.ProductID,
            ConsumerProductID : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ConsumerProductID,
            "EngineerID": consumerServiceRequest && consumerServiceRequest.ServiceLocationEngineerID,
        };
        
        apiCall({
			url: "getOnsiteParts",
			data: data,
			callBack: (result) => {
                // setShowPartLoader(false)
				// setLoader(false);
				if (result.success) {
                    initParts(result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    const getAvailableQuantity = (part)=> {
        // setLoader(true);
        setShowPartLoader(true);
        //Something went wrong
        if (consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID) {
            let data = {
                "PartnerServiceLocationID": consumerServiceRequest.PartnerServiceLocationID,
                "part": part
            }
            apiCall({
			url: "getAvailableQuantity",
			data: data,
			callBack: (result) => {
				// setLoader(false);
                setShowPartLoader(false);
				if (result.success) {
                    if(result.data){
                        if(result.data.ApprovalRequired){
                            setPicMaster([
                                { 'picType': "Symptom Defect or Panel Picture" },
                                { 'picType': "Back Cover Sl.No Picture" },
                                { 'picType': "Panel Sl.No" },
                                { 'picType': "PCBI" },
                                { 'picType': "Invoice Copy Picture" },
                            ])
                        }else{
                            setPicMaster([]);
                        }
                        setAvailableQuantity(result.data && result.data.AvailableQuantity)
                    }
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
            });
        }
    }
    const initParts=(partslist)=>{
        let jobPart = partslist && partslist.map(v=>({...v,value:`${v.Description}(${v.PartCode})`}))
        setSelectJobParts(jobPart);
        // setPartJobFlag({...partJobFlag,partSelected:true});
    }

    const jumpToAddParts =  ()=> {
        setJobModalState(5)
        loadPartsListing();
    }
    
    const getIrisCodes = ()=> {
        let data= {
            "ProductSubCategoryID": consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID ? consumerServiceRequest.consumerProduct.ProductSubCategoryID : undefined,
            "ServiceLocationEngineerID": consumerServiceRequest && consumerServiceRequest.ServiceLocationEngineerID
        }
        apiCall({
			url: "getSymptomFaultFaultActionMappings",
			data: data,
			callBack: (result) => {
                setSymptomLoading(false);
				if (result.success) {
                    if(result.data && result.data.length > 0){
                        setOnsiteFlags({...onsiteFlags,isFetchedIris:true});
                        setIrisMap(result.data);
                        if(fromJobFlag){//return item.SymptomCode - item.SymptomDescription
                            fromInitIrisSymptom(result.data);
                            } else if (onsiteFlags.fromIrisFlag) {
                            // straightInitIrisSymptom()
                            } else {
                            // initIrisSymptom()
                        }
                    }
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
        });  
    }

    const fromInitIrisSymptom =(result)=>{
        let initIrisSymptom = result.map(v=> ({...v,value:`${v.SymptomCode}-${v.SymptomDescription}`}))
        setSelectInitIrisSymptom(initIrisSymptom);
        // setSelectedIrisActionFlag(false)
    }
    
    const actionVisitStatus=(val)=>{
        const actionStatus =visitDispositionDataPro && visitDispositionDataPro.filter(item=>{
            return val.VisitStatus===item.VisitStatus
        })
        visitActionStatus(actionStatus)
    }
    const visitActionStatus=(actionStatus)=>{
        let visitDispositionActionPro  = actionStatus && actionStatus.map(v =>  ({...v, value: v.ActionCode}));
        setSelectActionVisitStatus(visitDispositionActionPro);
    }

    const actionVisitUnresolveStatus=(val)=>{
        const actionStatus =visitDispositionDataUnPro && visitDispositionDataUnPro.filter(item=>{
            return val.VisitStatus===item.VisitStatus
        })
        visitUnresolveActionStatus(actionStatus)
    }
    const   visitUnresolveActionStatus=(actionStatus)=>{
        let visitDispositionActionPro  = actionStatus && actionStatus.map(v =>  ({...v, value: v.ActionCode}));
        setSelectActionUnresolveVisitStatus(visitDispositionActionPro);
    }

    const showUnresolvedVisitModal=()=>{
        setShowModal({...showModal,showUnresolveVisitModal:true})
        setOnsiteFlags({...onsiteFlags,resolvedFlag:false})
        if(!visitDispositionLoaded){
            getVisitDispositions()
        }
    }
    const showResolvedVisitModal =()=>{
        setShowModal({...showModal,showResolveVisitModal:true});
        setOnsiteFlags({...onsiteFlags,resolvedModal:true,resolvedFlag:true,irisSubmission:false});
        if(!visitDispositionLoaded) {
            getVisitDispositions()
        } else {
            // fromJobVisitDispositionProd()
        }

    }

    const nextModal = () =>{
        setOnsiteFlags({...onsiteFlags,resolvedModal:false,irisSubmission:true})
        if (onsiteFlags.isFetchedIris) {
          // pass since data is cached
        } else{
            getIrisCodes()
        }
    }
    const updateCSRStatus = (e)=>{
        let data = {
                "ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            // "Status":nextStatus
        };
        apiCall({
			url: "updateCSRStatus",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    getRequestById();
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    const acceptRejectService =(type)=> {
        setLoader(true);
        let params = {
                "EngineerID": getServiceLocationEngineerId(),
                "ConsumerServiceRequestID":consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
                "Action": type,
            };

        apiCall({
			url: "acceptRejectRequestsEngg",
			data: params,
			callBack: (result) => {
				setLoader(false);
                // setShowPartLoader(false);
				if (result.success) {

				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
                //location.reload()
			},
		});
    }

    const  preShowClaimApproveEstimationConfirm =() =>{
        Swal.fire({
            html:'<h2  style="font-size:24px; font-weight: 600;">'+localString('Please enter approved claim amount')+'</h2>'+ '<p>'+localString("Total Repair Estimate")+' : <span>'+  currencySymbol + '</span>' + consumerServiceRequest.TotalBilledAmount +'</p><p>'+localString("Covered Value")+' : <span>'+ currencySymbol + '</span>'+(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.DepreciatedValue && consumerServiceRequest.consumerProduct.DepreciatedValue.sumInsured)+'</p><input type="number" min="0" id="approval-remark" class="cancel-remark"/>',
            showCancelButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.close();
                let remark =document.getElementById("approval-remark").value; 
                showClaimApproveEstimationConfirm(remark);
			}
		});
    }
    const showClaimApproveEstimationConfirm =(remark) =>{
        setLoader(true);
        if(remark && remark !==''){
            let isArrayValid = true;
            let invalidArrayParam = '';
            let charge = remark;
            let chargeObj = {
                Name: "Approved claim amount",
                Charge: charge
            };
            let validator = validateThis(chargeObj, [{
                checkType: 'isAlphaNumeric',
                key: 'Name'
            }, {
                checkType: 'isFloat',
                key: 'Charge'
            }]);
            if (!validator.isValid) {
                isArrayValid = false;
                invalidArrayParam = validator.invalidParams[0]
            }
            if (!isArrayValid) {
                setLoader(false);
                Swal.fire({
                    title:'', 
                    text:localString('Please enter correct ') + invalidArrayParam, 
                    icon:'error'
                });
                return;
            }
            if(remark > consumerServiceRequest.consumerProduct.DepreciatedValue.sumInsured){
                setLoader(false);
                Swal.fire({
                    title:'',
                    text:localString('Approved Claim Amount should not be more than Covered Value') + invalidArrayParam, 
                    icon:'error'
                })
                return;
            }
            let finalChargeObj = {
                Name: "Approved claim amount",
                Type: "Claim amount",
                Charge: charge
            }; 
            let params = {
                "ConsumerServiceRequestID":consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
                "updatedCharges": [finalChargeObj],
                ServiceTypeID: consumerServiceRequest && consumerServiceRequest.ServiceTypeID,
                "PartnerServiceLocationID":consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID
            };
            if((remark < consumerServiceRequest.TotalBilledAmount) && consumerServiceRequest.IsBERApplicable){
                setLoader(false);
                Swal.fire({
                    title: localString('If you approve this amount, BER will be triggered'),
                    showCancelButton: true,
                    closeOnConfirm: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    cancelButtonText: localString("Cancel"),
                    confirmButtonText: localString("OK")
                }).then(function(result) {
                    setLoader(false);
                    if(result.isConfirmed){
                        Swal.close();
                        apiCall({
                            url: "updateCharge", //no idea where this button
                            data: params,
                            callBack: (result) => {
                                setLoader(false);
                                if (result.success) {
                                    getRequestById();
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        text: localString(displayError(result)),
                                    });
                                }
                            },
                        });
                    }else{
                        Swal.close();
                    }
                })
            }else{
                apiCall({
                    url: "updateCharge", //no idea where this button
                    data: params,
                    callBack: (result) => {
                        setLoader(false);
                        if (result.success) {
                            getRequestById();
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: localString(displayError(result)),
                            });
                        }
                    },
                });
            }
        }
        else {
            setLoader(false);
            Swal.close();
            Swal.fire({
                title :'',
                text :localString('Please enter approved claim amount'),
                icon :'error'
            }).then(function(result) {
                if (result.isConfirmed) {
                    preShowClaimApproveEstimationConfirm();
                }
            });
        }		
    };
    const  preShowApproveEstimationConfirm =(e) =>{
        Swal.fire({
			title: localString('Please enter approved claim amount'),
            html: '<p>'+localString("Total Repair Estimate")+' : <span>'+ currencySymbol + '</span>' + consumerServiceRequest.TotalBilledAmount +'</p><p>'+localString("Covered Value")+' : <span>'+ currencySymbol + '</span>'+ (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.DepreciatedValue && consumerServiceRequest.consumerProduct.DepreciatedValue.sumInsured)+'</p><input type="number" min="0" id="approval-remark" class="cancel-remark"/>',
            showCancelButton: true,
            closeOnConfirm: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.close();
                let remark =document.getElementById("approval-remark").value; 
                showApproveEstimationConfirm(remark);
			}
		});
    }
    const showApproveEstimationConfirm = (remark)=> {
        if (remark !='') {
            Swal.close();
            let isArrayValid = true;
            let invalidArrayParam = '';
            let charge = remark;
            let chargeObj = {
                Name: "Approved claim amount",
                Charge: charge
            };
            let validator = validateThis(chargeObj, [{
                checkType: 'isAlphaNumeric',
                key: 'Name'
            }, {
                checkType: 'isFloat',
                key: 'Charge'
            }]);
            if (!validator.isValid) {
                isArrayValid = false;
                invalidArrayParam = validator.invalidParams[0]
            }
            if (!isArrayValid) {
                Swal.close();
                setLoader(false);
                // Swal.fire({
                //     title:'', 
                //     text:localString('Please enter correct ') + invalidArrayParam, 
                //     icon:'error'
                // })
                return
            }
            if(remark > consumerServiceRequest.consumerProduct  && consumerServiceRequest.consumerProduct.DepreciatedValue && consumerServiceRequest.consumerProduct.DepreciatedValue.sumInsured){
                Swal.fire({
                    title:'', 
                    text:localString('Approved Claim Amount should not be more than Covered Value') + invalidArrayParam,
                    icon:'error'
                })
                Swal.fire({
                    title:'',
                    text:localString('Please enter approved claim amount'),
                    icon:'error'
                }).then(function(result) {
                    if (result.isConfirmed) {
                        showApproveEstimationConfirm();
                    }
                });
                return;
            }
            let finalChargeObj = {
                Name: "Approved claim amount",
                Type: "Claim amount",
                Charge: charge
            };
            setLoader(true);
            let params = {
                "ConsumerServiceRequestID":consumerServiceRequest.ConsumerServiceRequestID,
                "updatedCharges": [finalChargeObj],
                ServiceTypeID: consumerServiceRequest && consumerServiceRequest.ServiceTypeID,
                "PartnerServiceLocationID": consumerServiceRequest.PartnerServiceLocationID
            };
            apiCall({
                url: "updateCharge", //no idea where this button
                data: params,
                callBack: (result) => {
                    setLoader(false);
                    if (result.success) {
                        getRequestById();
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: localString(displayError(result)),
                        });
                    }
                },
            });
        }else{
            setLoader(false);
            Swal.close();
            Swal.fire({
                title:'',
                text:localString('Please enter approved claim amount'),
                icon:'error'
            }).then(function(result) {
                if (result.isConfirmed) {
                    showApproveEstimationConfirm();
                }
            });
        }
    };

    const  showCancelRequestConfirmModal =()=>{
        setShowModal({...showModal,showCancelOnsiteDropdown:true})
        initialiseCancelOptions();
    }

    const initialiseCancelOptions = ()=>{
        let cancelDropdownData = cancelOnsiteDropdown.map(v=>({...v,value:v}));
        setSelectCancelOnsiteDropdown(cancelDropdownData);
    }

    const cancelServiceWithRemark = ()=> {
        setLoader(true);
        let params = {
            "ConsumerServiceRequestID":consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            "Remarks": cancelOnsiteFilterData
        };
        if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
            params.Status = "Service cancel";
        }
        let url = [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? 'claimRequest' : 'cancelRequestWithoutDispatch';
        apiCall({
            url: url,
            data: params,
            callBack: (result) => {
                setLoader(false);
                setShowModal({...showModal,showCancelOnsiteDropdown:false});
                if (result.success) {
                    getRequestById();
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

    const  createTaskService =()=>{
        setShowModal({...showModal,showCreateTaskModal:true});
    }

    const initWarrantyDropDown=()=>{
        const priorityType = priorityArr.map(v=>({...v,value:v.PriorityType}));
        setSelectPriorityType(priorityType);
    }

    const showPaymentModal =  () =>{
        Swal.fire ({
            title: localString('Are you sure?'),
            text: localString('The payment has been received and service will be completed.'),
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(function(isConfirm) {
            if (isConfirm) {
                receivePaymentCompleteService()
            }
        })
    }

    const receivePaymentCompleteService =  ()=> {
        setLoader(true);
        let data = {
            "EngineerID": getServiceLocationEngineerId(),
            "ConsumerServiceRequestID":consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            "ConsumerProductID":  consumerServiceRequest && consumerServiceRequest.ConsumerProductID,
            "ConsumerAmount":consumerServiceRequest && consumerServiceRequest.ConsumerAmount,
            "ModeOfPayment":"cash payment",
        }
		if (consumerServiceRequest && (['Pending for assignment'].indexOf(consumerServiceRequest.Status) > -1) && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_TREAD') > -1) {
			data.sendHardcodedEngineerID = true;
		}
        apiCall({
            url: "receivePayment", //no idea where this button
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    getRequestById();
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

    const servicemanReached = (e)=>{
        let params = {
            "EngineerID": getServiceLocationEngineerId(),
            "ConsumerServiceRequestID":consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            "Lat": "19.1123143",
            "Lng": "72.8611378"
        };
        apiCall({
            url: "ServicelocationEngineerReached", //no idea where this button
            data: params,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    getRequestById();
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

    const performDiagnosis=()=>{
        setShowModal({...showModal,showInitiateDiagnosis:true});
    }

    const generateInvoice=()=>{
        let data = {
            "EngineerID": getServiceLocationEngineerId(),
            "ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
        };
        if (consumerServiceRequest && consumerServiceRequest.showEngineerVisits) {
            apiCall({
                url: "invoiceGenerateAndsetFinalInvoiceRemark", 
                data: data,
                callBack: (result) => {
                    setLoader(false);
                    if (result.success) {
                        getRequestById();
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: localString(displayError(result)),
                        });
                    }
                },
            });
        } else {
            apiCall({
                url: "setFinalInvoiceRemark", 
                data: data,
                callBack: (result) => {
                    setLoader(false);
                    if (result.success) {
                        window.location.reload();
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: localString(displayError(result)),
                        });
                    }
                },
            });
        }
    }
    const showIrisModal=()=>{
        // $("#iris-modal").modal('show')
        setShowModal({...showModal,showIrisModal:true})
        setOnsiteFlags( {...onsiteFlags,fromIrisFlag:true})
        if (onsiteFlags.isFetchedIris) {
            // pass since data is cached
        } else{
            getIrisCodes()
        }
    }

    const rejectRequestEndTime = (e)=>{
        let  data = {
            "EngineerID": getServiceLocationEngineerId(),
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "PartnerServiceLocationID": consumerServiceRequest.PartnerServiceLocationID
        };
    //   let remark = $('#no-rating-reason').val();
    //   if(remark){
    //     data.Remarks = remark;
    //   }   //pending part doubts
        apiCall({
            url: "RejectRequestEndTime", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                // if(remark){
                //     $("#rating-reason-modal").modal('hide')
                // }
                if (result.success) {
                    window.location.reload();
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

    //claim
    const getVendorList =  () =>{
        let data = {
            pickupCountryCode:consumerServiceRequest && consumerServiceRequest.shipmentPickupCountryCode,
            dropCountryCode: consumerServiceRequest && consumerServiceRequest.shipmentDropCountryCode,
			CountryID:consumerServiceRequest && consumerServiceRequest.CountryID
        }
        apiCall({
            url: "getVendorListByCountry", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    setVendorList(result && result.data);
                    initiateVendorTypeahead(result.data);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    };

    const initiateVendorTypeahead =(result)=>{
        const vendorData = result.map(v=>({...v,value:v.VendorName}));
        setSelectedVenderName(vendorData)
    }

    const saveCurrentShipmentDetails = () =>{
        if(Object.keys(currentShipmentDetail).length > 0) {
            if(replacementFlowChecks){
                let data ={
                    shipmentDetails:currentShipmentDetail,
                    ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                    Status: "Replacement shipment created",
                    CountryID: consumerServiceRequest && consumerServiceRequest.CountryID,
                    shipmentDetails :currentShipmentDetail
                }
                replacementFulfillment(data)
            }else if(claimChecks){
                claimRequestNext("Shipment created",currentShipmentDetail)
            }else if(berChecks){
                let data = {
                    shipmentDetails:currentShipmentDetail
                }
                preBerRequestNext("BER shipment created",data)
            }else if(refurbishmentFlowCheck){
                let currentShipment = {
                    "vendorID": currentShipmentDetail.VendorID,
                    "vendorName": currentShipmentDetail.VendorName,
                    "wayBillNumber": currentShipmentDetail.WaybillNumber
                }
                let data = {
                    consumerServiceRequestID : consumerServiceRequest.ConsumerServiceRequestID,
                    status : consumerServiceRequest.Status == "Repair completed" ? "Shipment created" : "Refurbishment shipment created",
                    shipmentDetails : currentShipment,
                    userID:userID
                }
                refurbishmentFlowNext(data)
            }
            else{
                let data ={
                    shipmentDetails:currentShipmentDetail,
                    ConsumerServiceRequestID:consumerServiceRequest.ConsumerServiceRequestID,
                    Status: "Shipment created"
                }
                preProcessRequest("",data);
            }
            // preProcessRequest("",data);
            closeShipmentModal();
        }

    };
    const closeShipmentModal = () =>{
        setShowModal({...showModal,showDropOffShipmentModal:false});
        setVenderOption("")
        setVender({...vender,venderName:"",waybillNumber:""});
        setClaimFlags({...claimFlags,showVendorNameInput:false});
        setCurrentShipmentDetail({})
    };
    const initiateShipmentModal=()=>{
        if(!claimFlags.vendorListFetched) {
            setClaimFlags({...claimFlags,vendorListFetched:true})
            getVendorList();
        } else {
            initiateVendorTypeahead(vendorList);
        }
        setShowModal({...showModal,showDropOffShipmentModal:true})
        // $('#DropOffShipmentModal').modal('show');
    }

    const acceptRejectJob = (nextStatus)=>{
        setLoader(true);
        let decision = nextStatus;
        let data = {
            "EngineerID": consumerServiceRequest && consumerServiceRequest.ServiceLocationEngineerID,
            "ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            "Action": decision
        };
        if((nextStatus == 'Accept') && appleDropOffFlow) {
            data.SourceType = 'CustomerCare';
        }
        if(nextStatus == 'Reject' && appleDropOffFlow && consumerServiceRequest?.ProvisionalGSXNumber) {
            data.ProvisionalGSXNumber = consumerServiceRequest.ProvisionalGSXNumber;
        }
        apiCall({
            url: "acceptRejectRequestsWorkshop", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    getRequestById();
                }else {
                    if(result.data && result.data.showMessage){
                        Swal.fire({
                            title:'',
                            text: localString(displayError(result)) || localString("Something went wrong"), 
                            icon:'info'
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            text: localString(displayError(result)),
                        });
                    }
                }
            },
        });
    }

    const showDeviceReturnedModal=(nextStatus)=>{
        if((claimNewWorkshopChecks)){
            if('Device returned' == nextStatus) {//claim new workshop
                setShowModal({...showModal,showDeviceReturnedModal:true}); 
            } else {
                setShowModal({...showModal,showDeviceHandoverModal:true})
            }
        }else{
            if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
                if (consumerServiceRequest.DeviceReturnTo.length > 0) { //pick carry in gsx
                    if('Device returned' == nextStatus) {
                        setShowModal({...showModal,showDeviceReturnedModal:true});
                    } else {
                        setShowModal({...showModal,showDeviceHandoverModal:true})
                    }
                } else {
                    Swal.fire({
                        title: '',
                        text: localString('Cannot handover device'),
                        icon: 'error',
                        confirmButtonText: localString('OK')
                    });
                }
            }else{
                if (consumerServiceRequest.DeviceReturnTo.length > 0) { //pick carry in
                    if('Device returned' == nextStatus) {
                        setShowModal({...showModal,showDeviceReturnedModal:true});
                    } else {
                        setShowModal({...showModal,showDeviceHandoverModal:true})
                    }
                } 
                else {
                    Swal.fire({
                        title: '',
                        text: localString('Cannot handover device'),
                        icon: 'info',
                    });
                }
            }
        }
    }

    const showDocumentPendingConfirmModal = (status)=> {
        if (['Call attempted'].indexOf(status) > -1) {
                if(consumerServiceRequest.consumerProduct.isAppleBrandID){
                    setShowModal({...showModal,showAdditionalCallModalGSX:true});
                }else{
                    setShowModal({...showModal,showAdditionalCallModal:true});
                }

            // $('#datetime').datetimepicker({
            //     //pickerPosition: "bottom-left",
            //     format: 'DD-MM-YYYY HH:mm',
            //     widgetParent:'#calenderid'
            // });

            // let today = moment();
            // $('#datetime').data("DateTimePicker").maxDate(today);
            // $('#datetime').data("DateTimePicker").defaultDate(today);

            // //TO DO add mindate as csr created date
            // $('#datetime input').click(function(event) {
            //     $('#datetime').data("DateTimePicker").show();
            // });
        } else if (status == 'Document pending') {
            setShowModal({...showModal,showDocumentPendingModal:true})
        } else if (status == 'Convert request') {
            // $('#mergeRequestsModal').modal();
        }else if(onsiteChecks){
            setShowModal({...showModal,showAdditionalCallModal:true});
        }
        else if(['Service cancel'].indexOf(status) > -1) {
            let remarkId = '#cancel-remark';
            let textArea = '<textarea id="cancel-remark" class="cancel-remark"></textarea>';
            let title = localString("Please fill in the reason for cancelling");
            let errorTitle = localString("Please fill in the cancel reason before cancelling");
            Swal.fire({
                title: title,
                html: textArea,
                showCancelButton: true,
                closeOnConfirm: false,
                allowEscapeKey: false,
                allowOutsideClick: false
            }).then(function(result) {
                if(result.isConfirmed ){
                    let remark =  document.getElementById("cancel-remark").value;
                        if(remark != ''){
                        Swal.close();
                        requestEnquiry(remark);
                    }
                } else {
                Swal.close();
                Swal.fire({
                        title  : 'Oops...',
                        text:title,
                        icon:'error'
                }).then(function(result) {
                    if (result.isConfirmed) {
                    showDocumentPendingConfirmModal(status);
                    }
                });
            }
            });
        }
    };

    const captureAdvancePayment = () => {
        let data = {
            'ConsumerServiceRequestID': consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID
        };
        apiCall({
            url: "capturingAdvancePayment", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    getRequestById();
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

    const processRequestWithoutConfirmation = (nextStatus)=>{
        claimRequestWorkshopNext(nextStatus, '');
    }

    const updatePartsStatus = (nextStatus) =>{
        setLoader(true);
        let action = '';
        if(nextStatus == 'Parts request accepted'){
            action = 'pending';
        } else {
            action = 'cancelled';
        }
        for(let i = 0; i < partsRequested.length; i++){
        partsRequested[i].action = action;
        }
        let data = {
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "parts": partsRequested
        };
        apiCall({
            url: "updatePartStatus", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    getRequestById();
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    };


    const cancelRepair =  (nextStatus) => {
        Swal.fire ({
            title: localString("Are you sure?")+'\n'+localString("Please specify remarks, if any..."),
            html: '<input type = "checkbox" style = "margin-bottom:15px" id = "cancel_check" checked/>'+ localString("This service is a Return without repair")+' (RWR)<input id="cancel-remark" class="cancel-remark"></input>',
            showCancelButton: true,
            customClass: 'swal-wide',
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
        }).then (function(result) {
            if (result.isConfirmed) {
                let remark = document.getElementById("cancel-remark").value ? document.getElementById("cancel-remark").value: ''; 
                if (document.getElementById('cancel_check').checked) {
                    remark = localString("Return without repair (RWR)") + (remark?' - ' + remark:'')
                }
                if (['Service cancel','Repair cancel'].indexOf(nextStatus) > -1 && remark == '') {
                    Swal.fire('', localString("Cannot proceed with this action without providing a reason."), 'error');
                    return;
                }
                Swal.close();
                let status = nextStatus;
                let data = {
                    ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                    Status: status,
                    Remarks: remark,
                };
                setLoader(true);
                apiCall({
                    url: "claimRequest", 
                    data: data,
                    callBack: (result) => {
                        setLoader(false);
                        if (result.success) {
                            getRequestById(remark);
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: localString(displayError(result)),
                            });
                        }
                    },
                });
            }
        })
    }

    const checkChecklistData = (status)=>{
        let actionStatus = [
            'Claim initiated',
            'Claim invalid',
            'Under review',
            'Document pending',
            'In-principle approved',
            'Claim rejected',
            'Repair cancel',
            'Cancel Service Without Dispatch',
            'Claim estimation approved',
            'Service cancel',
            //ber
            'BER confirmed',
            'BER identified',
            'BER approved',
            'BER rejected',
            'Document pending',
            'BER payment completed',
            'Document verification pending',
            'BER documents validated',
            'BER accessories received',
            'BER device received',
            'BER accessories validated',
            'BER payment initiated',
            'Service completed',
            'BER accessories rejected',
            'BER replacement issued',
            'BER shipment created',
            'Add Shipment Details',
            'Device dispatched',
            'BER payment completed'
        ]
        if(actionStatus.indexOf(status) > -1 && adjudicationChecklistData && adjudicationChecklistData.NewChecklist && adjudicationChecklistData.NewChecklist.length){
            return true;
        }else{
            return false;
        }
    };

    const showConfirmation = (status)=> {
        let textArea;
        let title;
        let errorTitle;
        let remarkId;
        switch (status) {
            case 'Service cancel':
                remarkId = 'cancel-remark';
                textArea = '<textarea id="cancel-remark" class="cancel-remark"></textarea>';
                title = replacementFlowChecks ? localString("Please fill in the cancel reason before cancelling"): localString("Please fill in the reason for cancelling");
                errorTitle = localString("Please fill in the cancel reason before cancelling");
                break;
            case 'Repair cancel':
                remarkId = 'cancel-remark';
                textArea = '<textarea id="cancel-remark" class="cancel-remark"></textarea>';
                title = localString("Please fill in the reason for cancelling");
                errorTitle = localString("Please fill in the cancel reason before cancelling");
                break;
            case  'Cancel Service Without Dispatch':
                remarkId = 'cancel-remark';
                textArea = '<textarea id="cancel-remark" class="cancel-remark"></textarea>';
                title = localString("Please fill in the reason for cancelling");
                errorTitle = localString("Please fill in the cancel reason before cancelling");
                break;
            case 'Parts pending':
                remarkId = 'parts-pending-remark';
                textArea = '<textarea id="parts-pending-remark" class="cancel-remark"></textarea>';
                title = localString("Please specify the pending parts");
                errorTitle = localString('Please fill in the cancel reason before cancelling');
                break;
            case 'Claim rejected':
                remarkId = 'reject-remark';
                textArea = '<textarea id="reject-remark" class="cancel-remark"></textarea>';
                title = localString("Please fill in the reason for rejecting claim");
                errorTitle = localString("Please fill in the cancel reason before rejecting");
                break;
            case 'Claim invalid':
                remarkId = 'invalid-remark';
                textArea = '<textarea id="invalid-remark" class="cancel-remark"></textarea>';
                title = localString("Please fill in the reason for the claim being invalid");
                errorTitle = localString("Please fill in the reason for claim being invalid before rejecting");
                break;
            case 'Claim initiated':
                remarkId = 'intimation-remark';
                textArea = '<textarea id="intimation-remark" class="cancel-remark"></textarea>';
                title = localString("Please enter Claim intimation number");
                errorTitle = localString("Please enter Claim intimation number before submitting");
                break;
            case 'Service completed':
                remarkId = 'service-confirm-remark';
                textArea = '<textarea id="service-confirm-remark" style="font-size:14px;padding:5px" class="cancel-remark"></textarea>';
                title = localString("Please Enter Remark");
				errorTitle = localString("Please Enter Remark");
                break;
            case 'Device received':
                remarkId = 'DR-approve-remark';
                textArea = '<textarea id="DR-approve-remark" class="cancel-remark"></textarea>';
                title = localString("Please enter Approve Remark");
                errorTitle = localString("Please enter Approve Remark before submitting");
                break;

            case 'Claim estimation generated':
                remarkId = 'CEG-approve-remark';
                textArea = '<textarea id="CEG-approve-remark" class="cancel-remark"></textarea>';
                title = localString("Please enter Approve Remark");
                errorTitle = localString("Please enter Approve Remark before submitting");
                break;
        }
        Swal.fire({
            title: title,
            html: textArea,
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            customClass:'swal-confirmation',
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("OK")
        }).then((result) =>{
            if (result.isConfirmed) {
                let remark = document.getElementById(remarkId).value;
                //Swal.close();
                if (remark != '' && status == 'Cancel Service Without Dispatch') {
                    let newParams = {
                        "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
                        "Remarks": remark
                    };
                    setLoader(true);
                    apiCall({
                        url: "cancelRequestWithoutDispatch", 
                        data: newParams,
                        callBack: (result) => {
                            setLoader(false);
                            if (result.success) {
                                getRequestById(remark);
                            }else {
                                Swal.close();
                                Swal.fire({
                                    icon: "error",
                                    text: localString(displayError(result)),
                                });
                            }
                        },
                    });
                }  else if ((replacementFlowChecks) && remark != '' && ['Service cancel','Service completed'].indexOf(status) > -1) {
                    Swal.close();
                    let params = {
                        "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
                        "Remarks": remark || undefined,
                        'ServiceTypeID': consumerServiceRequest.ServiceTypeID,
                        "Status": status,
                        'ConsumerProductID':consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ConsumerProductID,
						'CountryID': consumerServiceRequest && consumerServiceRequest.CountryID                   
                    };
                    let url =''
                    if (status == 'Service completed'){
                        url = 'replacementFulfillment'
                    }else{
                        url = 'cancelRequestWithLogistics' //Service cancel
                    }
                    setLoader(true);
                    apiCall({
                        url: url, 
                        data: params,
                        callBack: (result) => {
                            setLoader(false);
                            if (result.success) {
                                getRequestById(remark);
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    text: localString(displayError(result)),
                                });
                            }
                        },
                    });
                }else if (remark != '' || status == 'Parts pending') {
                    Swal.close();
                    let productDetails = consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductDetails &&  consumerServiceRequest.consumerProduct.ProductDetails.length > 0 ? consumerServiceRequest.consumerProduct.ProductDetails[0] : null;
                    let params = {
                        "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
                        "Remarks": (status != 'Claim initiated' ? remark : undefined),
                        "intimationNumber": (status == 'Claim initiated' ? remark : undefined),
                        ServiceTypeID: consumerServiceRequest.ServiceTypeID,
                        "Status": status,
                        'ConsumerProductID': consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ConsumerProductID
                    };
                    if(status == "Claim initiated") {
                        params.SoldPlanID = consumerServiceRequest.SoldPlanID;
                        params.PlanCoverageID = soldPlanCoverage && soldPlanCoverage.PlanCoverageID;
                        params.SoldPlanCoverageID = soldPlanCoverage && soldPlanCoverage.SoldPlanCoverageID;
                    }
                    let url = 'claimRequest'
                    if (['Service cancel'].indexOf(status) > -1 ){
                        url = 'cancelRequestWithLogistics'
                    }
                    setLoader(true);
                    apiCall({
                        url: url, 
                        data: params,
                        callBack: (result) => {
                            setLoader(false);
                            if (result.success) {
                                getRequestById(remark);
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    text: localString(displayError(result)),
                                });
                            }
                        },
                    });
                }  else {
                    Swal.close();
                    Swal.fire({
                        title:'Oops...',
                        text:errorTitle,
                        icon:'error'
                    }).then((result)=> {
                        if (result.isConfirmed) {
                            showConfirmation(status);
                        }
                    });
                }
            }
        });
    };

    const showDeviceReceivedModal = () =>{
        if(!consumerServiceRequest.consumerProduct.isAppleBrandID){
            setClaimFlags({...claimFlags,showAddBtn:true});
            setClaimFlags({...claimFlags,showDeviceReceived:false})
            setShowModal({...showModal, showDeviceReceivedModal:true});
            getPart();
        }else if(claimNewWorkshopChecks){
            setClaimFlags({...claimFlags,showAddBtn:true});
            setClaimFlags({...claimFlags,showDeviceReceived:false})
            setShowModal({...showModal, showDeviceReceivedModal:true});
        }
    };


    const addOtherImagesArr = (imgname) =>{
        let pictypeImg = imgname
        if(pictypeImg == "Other"){
            let pictype = {
                "text": pictypeImg,
                "picType": pictypeImg
            }
            otherArrImages.push(pictype)
        }
    }
    const claimPicMaster = [
        {'picType': "Front Image"},
        {'picType': "Back Image"},
        {'picType': "Top Image"},
        {'picType': "Bottom Image"},
        {'picType': "Left Image"},
        {'picType': "Right Image"},
        {'picType': "Other"}
    ];
   // const claimDocArray = [];
    const deviceAcceptance = [
        'REPAIR_DEVICE',
        'STORE_MANAGEMENT',
        'QC_DEVICE',
        'ACCEPT_RETURN_DEVICE'
    ];

    const getPart =  () =>{
        let url = "getFinishedGood";
        let  allParts ;
        let selectedFinishedGood ;
        let data = {
            "ProductID": consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductID,
            "PartnerServiceLocationID": consumerServiceRequest.PartnerServiceLocationID
        }
        if(accessFn('GSX_FLOW')){
            if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.FinishedGoodID){
                setFinishedGoodID(consumerServiceRequest.consumerProduct.FinishedGoodID)
                setClaimFlags({...claimFlags,finishedGoodAutoSelected:true});
                return;
            }
        }
        if (claimNewWorkshopChecks && consumerServiceRequest && consumerServiceRequest.consumerProduct  && consumerServiceRequest.PartnerServiceLocationID) {//claim new
            setClaimFlags({...claimFlags,isPartsCalled:true});
            setLoader(true);
            apiCall({
                url: url, 
                data: data,
                callBack: (result) => {
                    setLoader(false);
                    if (result.success) {
                        if(result.data){
                            let productFinishedGood = result.data.productFinishedGoodMappings.map(v=>({...v,value:v.part.Name + v.part.PartCode}))
                            setSelectProductFinishedGood(productFinishedGood);
                        }
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: localString(displayError(result)),
                        });
                    }
                },
            });
        }else if(pickupCarryInChecks ){
            setClaimFlags({...claimFlags,isPartsCalled:true})
            if(consumerServiceRequest.consumerProduct && !consumerServiceRequest.consumerProduct.isAppleBrandID){
                if (!claimFlags.productNotMapped) {
                    apiCall({
                        url: url, 
                        data: data,
                        callBack: (result) => {
                            setLoader(false);
                            if (result.success) {
                                if(result.data){
                                    allParts = result.data;
                                    if(consumerServiceRequest && consumerServiceRequest.PartDetails){
                                        selectedFinishedGood = find(allParts.productFinishedGoodMappings, function(o) {return o.part.PartCode == consumerServiceRequest.PartDetails.PartCode});
                                    }
                                    if (selectedFinishedGood) {
                                        setFinishedGoodID(selectedFinishedGood.PartID);
                                        setClaimFlags({...claimFlags,finishedGoodAutoSelected:true});
                                    }else{
                                        setClaimFlags({...claimFlags,finishedGoodAutoSelected:false});
                                    }
                                    let productFinishedGood = result.data.productFinishedGoodMappings.map(v=>({...v,value:v.part.Name + v.part.PartCode}))
                                    setSelectProductFinishedGood(productFinishedGood);
                                }
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    text: localString(displayError(result)),
                                });
                            }
                        },
                    });
                }
            }else{//gsx
                apiCall({
                    url: url, 
                    data: data,
                    callBack: (result) => {
                        setLoader(false);
                        if (result.success) {
                            if(result.data){
                                let allParts = result.data;
								if (allParts.productFinishedGoodMappings && allParts.productFinishedGoodMappings.length == 1) {
									selectedFinishedGood = find(allParts.productFinishedGoodMappings, function(o) {return o.part.VisibilityStatus == 0});
									setClaimFlags({...claimFlags,finishedGoodAutoSelected:true});
								}
								else {
									if (consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.gsxInfo) {
										if(consumerServiceRequest.consumerProduct.gsxInfo.configDescription) {
											selectedFinishedGood = find(allParts.productFinishedGoodMappings, function(o) {return o.part.Name == consumerServiceRequest.consumerProduct.gsxInfo.configDescription});
										}
										if(!selectedFinishedGood && consumerServiceRequest.consumerProduct.gsxInfo.productDescription) {
											selectedFinishedGood = find(allParts.productFinishedGoodMappings, function(o) {return o.part.Name == consumerServiceRequest.consumerProduct.gsxInfo.productDescription});
										}
									}
								}
								if(!selectedFinishedGood && allParts && allParts.productFinishedGoodMappings && configDescription){
									selectedFinishedGood = find(allParts.productFinishedGoodMappings, function(o) {return o.part.Name == configDescription});
								}
								if (selectedFinishedGood) {
                                    setFinishedGoodID(selectedFinishedGood.PartID);
									setClaimFlags({...claimFlags,finishedGoodAutoSelected:true});
								}else{
									setClaimFlags({...claimFlags,finishedGoodAutoSelected:false});
								}

                            }
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: localString(displayError(result)),
                            });
                        }
                    },
                });
            }
        }
    };
    const claimAddDocArray = (e,pic)=> {
        setClaimSelectedPicType(e.target.name);
        let filePicType = e.target.name;
        addOtherImagesArr(e.target.name);
        let newfile = e.target.files;
        if (newfile.length > 0) {
        setClaimFlags({...claimFlags,showAddBtn:false})
        let orginalName = e.target.files[0].name
        let fileType = newfile[0].type;
        let dataImgType = 'image';
        let docname = newfile[0].name;
        let ext = fileType.split("/").length ? "."+fileType.split("/")[fileType.split("/").length - 1] : "";
        let key = "FEWEBA" + generateUUID() + ext;
        if (fileType == 'application/pdf') {
            dataImgType = 'pdf';
        }
        let gsxPicArray = [...picMasterGSX]
        if(accessFn('GSX_FLOW')){
            if(filePicType == 'Other'){
                setOtherImageLoader(true);
            }else{
                let arr = gsxPicArray.map(item=>{
                    if(pic.picType == item.picType){
                        item.loader = true
                    }
                    return item
                })
                setPicMasterGSX(arr)
            }

        }
        if(useDocServiceInspect) {
            if (docServiceUrl == '') {
                return;
            }
            let dataNew = new FormData();
            dataNew.append('file', newfile[0]);
            dataNew.append('docType', 'ConsumerProductDocument');
            dataNew.append('clientRefId', key);
            dataNew.append('entity-type', 'ConsumerProductID');
            dataNew.append('entity-id',consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ConsumerProductID);
            axios.post(docServiceUrl+'internal/document/upload', dataNew, {
                headers:({
                    "App": 'WebApp',
                    "module": 'WebApp',
                    "authorization": authorization
                }),
            })
			.then((result) => {
				if(result.data && result.data.data){
                    let uploadURL = result.data.data.uploadURL;
                    let viewURL = result.data.data.url;
                    let fileNameUUID = result.data.data.docServiceUUID;
                    onUploadSuccess(uploadURL, fileNameUUID, viewURL,filePicType);

                    if(accessFn('GSX_FLOW')){
                        if(filePicType == 'Other'){
                            setOtherImageLoader(false);
                        }else{
                            let arr = gsxPicArray.map(item=>{
                                if(pic.picType == item.picType){
                                    item.loader = false
                                }
                                return item
                            })
                            setPicMasterGSX(arr)
                        }
                    }
                    
                    }else{
                        if(accessFn('GSX_FLOW')){
                            if(filePicType == 'Other'){
                                setOtherImageLoader(false);
                            }else{
                                let arr = gsxPicArray.map(item=>{
                                    if(pic.picType == item.picType){
                                        item.loader = false
                                    }
                                    return item
                                })
                                setPicMasterGSX(arr)
                            }
                        }
					Swal.fire({  
						icon: 'error',  
						text: localString(result.data.message),  
					})							
				}
			})
			.catch((error) => {
				console.log('error: ' + error);
			})
        } else {
            apiCall({
                url: 'uploadDocuments', 
                data: {Type: fileType, Key: key, BrandID:consumerServiceRequest &&consumerServiceRequest.consumerProduct &&consumerServiceRequest.consumerProduct.BrandID ?consumerServiceRequest.consumerProduct.BrandID : undefined},
                callBack: (result) => {
                    //setLoader(false);
                    if (result.success) {
                        let xhr = new XMLHttpRequest();
                        xhr.open("PUT", result.data);
                        xhr.setRequestHeader("Cache-Control", "public,max-age=3600");
                        xhr.setRequestHeader('x-amz-acl', 'public-read');
                        xhr.onload = function () {
                        if (xhr.status === 200) {
                            orginalName = orginalName.substring(orginalName.lastIndexOf('\\') + 1, orginalName.length + 1);
                            onUploadSuccess(key, key);
                            }
                        }.bind(this);
                        xhr.onerror = function () {
                        }.bind(this);
                        xhr.send(newfile[0]);
                        e.target.value = "";
                    } else {
                        e.target.value = null;
                        Swal.fire({
                            icon: "error",
                            text: result.msg ?  localString(result.msg) : localString("Something went wrong"),
                        });
                    }
                },
            });
        }
        } else {
            Swal.fire({
            title:'', 
            text:localString("Please add a picture."), 
            icon:'error'
        });
        return;
    }
};

    const onUploadSuccess = (filePath, fileName, viewURL,filePicType) => {
        let  url= useDocServiceInspect && docServiceUrl ? 'addDocsForDS' : 'addDocs'
        let data = [{
            ConsumerServiceRequestID: consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            FilePath: filePath,
            Type: 'Image',
            FileName: fileName,
            OriginalName: fileName,
            SelectedPicType: filePicType,
            Tag: filePicType,
            DocumentID: 12,
            app: 'WebApp'
        }]
        apiCall({
            url: url, 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    if(result.data){
                        setClaimFlags({...claimFlags, showAddBtn:true});
                        let uploadedImage = result.data[0]
                        uploadedImage.FilePath = uploadedImage?.FilePath;
                        if(filePicType == 'Other'){
                            let otherArrayImage = [...claimOtherArrImagesPreview];
                            otherArrayImage.push(uploadedImage)
                            setClaimOtherArrImagesPreview(otherArrayImage);
                        }else {
                            let docArray = [...claimDocArray];
                            docArray.push(uploadedImage);
                            setClaimDocArray(docArray);
                        }
                    }
                     //checkListItemChecked();
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    };

    const completeDeviceReceivedClicked = (status)=> {
        if(pickupCarryInChecks){
            if(!consumerServiceRequest.consumerProduct.isAppleBrandID){
                closeModal();
                processRequestNextPickUp(status)
            }else{
                if (isTransferIncomplete) {
                    sendInwardGSXChecks(status)
                }
                else {
                    if (accessFn('GSX_FLOW')) {
                        if(pickupCarryinGsxFlag.isNonSerializedProduct && pickupCarryinGsxFlag.InwardCheckList){
                            updateNonSerializedDevice(status, dropOffFlag);
                        }else if(pickupCarryinGsxFlag.isUnabletoDiagnose && pickupCarryinGsxFlag.InwardCheckList){
                            updateSerializedDevice(status, dropOffFlag);
                        }else{
                            updateCheckListAndStatus('',status, dropOffFlag);
                        }
                        return;
                    }
                    if (['Service completed', 'Service completed and rated'].indexOf(status) > -1){
                        completeService(status);
                    }else if(['Initiate logistics request'].indexOf(status) > -1){
                        createLogisticRequest();
                    }else{
                        processRequestNextPickUp(status);
                    }
                }
                closeModal()
            }
        }else if(claimNewWorkshopChecks){
            setShowModal({...showModal,showDeviceReceivedModal:false})
            claimRequestWorkshopNext(status, '','',finishedGoodID);
        }
    };

    const getGSXChecks = function (initiator = false, isInward = true) {
        // setShowModal({...showModal,showFMIPModal:false});
        let sourceLocationID = consumerServiceRequest.PartnerServiceLocationID;
		let destinationLocationID = "";
		// if(initiator && self.tags['service_location_list'].selectedLocation && self.tags['service_location_list'].selectedLocation.PartnerServiceLocationID){
		// 	sourceLocationID = self.tags['service_location_list'].selectedLocation.PartnerServiceLocationID
		// }else if(self.isTransferIncomplete && self.consumerServiceRequest.TranferDetails){
		// 	destinationLocationID = self.consumerServiceRequest.TranferDetails.DestinationID;
		// }
        setIsInward(isInward);
		let data = {
			"BrandID": consumerServiceRequest.consumerProduct.BrandID,
			"ProductID": consumerServiceRequest.consumerProduct.ProductID,
			"ProductSubCategoryID": consumerServiceRequest.consumerProduct.ProductSubCategoryID,
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"ConsumerID":consumerServiceRequest && consumerServiceRequest.ConsumerID,
            "CheckType": isInward ? 'inward' : 'outward'
		}
        // setShowModal({...showModal,showFMIPModal:false});
		// call api
        if(appleDropOffFlow) {
            if(accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')) { // RDO User
                if(isInward) {
                    data["ToEntity"] = 'servicelocation';
                    data["sourceLocationID"] = consumerServiceRequest.OriginServiceLocationID;
                    data["destinationLocationID"] = '';
                }else {
                    data["ToEntity"] = 'consumer';
                    data["sourceLocationID"] = consumerServiceRequest.OriginServiceLocationID;
                    data["destinationLocationID"] = '';
                    data["FromEntity"] = 'servicelocation';
                }
            }else { // CC User
                if(isInward) {
                    data["ToEntity"] = 'servicelocation';
                    data["FromEntity"] = 'servicelocation';
                    data["sourceLocationID"] = consumerServiceRequest.OriginServiceLocationID; 
                    data["destinationLocationID"] = consumerServiceRequest.PartnerServiceLocationID;
                }else {
                    data["ToEntity"] = 'servicelocation';
                    data["FromEntity"] = 'servicelocation';
                    data["sourceLocationID"] = consumerServiceRequest.PartnerServiceLocationID; 
                    data["destinationLocationID"] = consumerServiceRequest.OriginServiceLocationID; 
                }
            }
        }else {
            data["ToEntity"] = "servicelocation";
			data["sourceLocationID"] =  sourceLocationID;
			data["destinationLocationID"] = destinationLocationID;
            if(!isTransferIncomplete && !isInward){
                data.ToEntity = 'consumer';
            }
        }
		setLoader(true);
        apiCall({
            url: "getDeviceCheckList", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    let gsxCheckList = result.data && result.data.gsxCheckList
                    setCheckListAllData(result.data);
                    setGsxCheckListPickCarry(result.data.gsxCheckList)
                    let generalElsGsxChecklist = gsxCheckList && gsxCheckList.generalElsChecklist && gsxCheckList.generalElsChecklist.length>0 ? gsxCheckList.generalElsChecklist:[] ;
                    setGeneralElsChecklist(generalElsGsxChecklist);
                    let accessoriesGsxList = gsxCheckList && gsxCheckList.accessoriesList && gsxCheckList.accessoriesList.length>0 ? gsxCheckList.accessoriesList :[];
                    setAccessoriesList(accessoriesGsxList);
                    let  gsxElsGsxCheckList = gsxCheckList && gsxCheckList.gsxElsCheckList && gsxCheckList.gsxElsCheckList.length>0 ? gsxCheckList.gsxElsCheckList :[];
                    setGsxElsCheckList(gsxElsGsxCheckList);
                    let  miscellaneousGSXList = gsxCheckList && gsxCheckList.miscellaneousList && gsxCheckList.miscellaneousList.length>0 ? gsxCheckList.miscellaneousList :[];
                    setMiscellaneousList(miscellaneousGSXList);
                    if (initiator) {
                        setShowModal({...showModal,showDeviceReceivedModal:true});
					}else {
                        setShowModal({...showModal,showDeviceReceivedModal:true});
						let osVersionData = result.data && result.data.gsxCheckList['osVersion'].length && result.data.gsxCheckList['osVersion'].map(v=>({...v,value:v.Name}));
                        setSelectOsVersion(osVersionData);
					}
                    // setShowModal({...showModal,showFMIPModal:false});
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

    const checkMaskedData = () =>{
		setLoader(true);
        let data =  {
			'BrandID' : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID,
			'imeiOrSerial' : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductUniqueID,
			'ConsumerID': consumerServiceRequest && consumerServiceRequest.ConsumerID,
			'fetchDataFromGSX':true,
			"ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
			"ConsumerProductID": consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ConsumerProductID,
			"verifySerialFromGsx": true
		}
        apiCall({
            url: 'checkMaskedData', 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    if(result.isDataMasked){
                        Swal.fire ({
                                text: localString('We are unable to establish complete device details from GSX. Diagnosis may not have been performed on this device. Please diagnose the device to proceed. Note: Excessive tries without device diagnosis can block your account access.'),
                                icon: 'info',
                                confirmButtonColor: '#4C10C1',
                                confirmButtonText: localString("OK"),
                                showCancelButton: true,
                                cancelButtonText: localString("Unable to Diagnose"),
                                showConfirmButton: true,
                                allowOutsideClick:false,
                            }).then((result)=> {
                                if(result.isConfirmed){

                                }else{
                                    // isUnabletoDiagnose = true;
                                    getSKUForNonSerialized();
                                    getGSXChecks(false, true);
                                    setPickupCarryinGsxFlag({...pickupCarryinGsxFlag,InwardCheckList:true,isUnabletoDiagnose:true});
                                    setShowModal({...showModal,showDeviceReceivedModal:true});
                                }
                            });
                    }else{
                        setConfigDescription(result.configDescription);
                        setUpdatedpProductId(result.ProductID);
                        if(featureConfig && featureConfig.CheckFM){
                            FMIPcheck();
                        }else if(featureConfig && featureConfig.ShowFMPopUp){
                            // setShowModal({...showModal,showFMIPModal:true});
                        }else{
                            getPart();
                            getGSXChecks(false, true);
                        }
                    }
                } else {
                    Swal.fire({
                        title: '',
                        text: localString(result.msg),
                        icon: 'error',
                        confirmButtonText: localString('OK')
                    });
                }
            },
        });
	}

    const getSKUForNonSerialized = function(){
		let data = {
			ProductID: consumerServiceRequest.consumerProduct.ProductID,
			isSerializedProduct: consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.isSerializedProduct
		}
        apiCall({
            url: "getSkuByProductID", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    let skuResult = result.data.skuResult;
                    let selectSKU = skuResult && skuResult.length > 0 && skuResult.map(v=>({...v,value:v.CodeValue}));
                    setSelectedSKUList(selectSKU);
                } else {
                    Swal.fire({
                        title: '',
						text: localString(displayError(result)),
						icon: 'error',
						confirmButtonText: localString('OK')
                    });
                }
            },
        });
	}

    const updateNonSerializedDevice = (status, dropOffFlag)=>{
		setLoader(true);
        let data = {
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			purchaseDate: consumerServiceRequest.consumerProduct.DateOfPurchase,
			productCode: selectedSKU && selectedSKU.ExternalReference ? selectedSKU.ExternalReference : undefined,
			ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID,
			ProductDescription: consumerServiceRequest.consumerProduct.product.ProductName,
			ConfigDescription: selectedSKU && selectedSKU.CodeValue ? selectedSKU.CodeValue:undefined,
			isSerializedProduct: consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.isSerializedProduct,
			isStaticNonSerializedProduct: consumerServiceRequest.isStaticNonSerializedProduct
		}

        apiCall({
            url: 'updateNonSerializedDevice', 
            data: data,
            callBack: (result) => {
                setLoader(false)
                if (result && result.success) {
                    let finishedId = result.data.finishedGoodID;
                    setFinishedGoodID(finishedId);
                    updateCheckListAndStatus('',status, dropOffFlag);
                } else {
                    Swal.fire({
                        title: '',
						text: localString(displayError(result)),
						icon: 'error',
						confirmButtonText: localString('OK')
                    });
                }
            },
        });
	}


    const updateSerializedDevice = (status, dropOffFlag)=>{
        setLoader(true);
		let data = {
			ConsumerServiceRequestID:consumerServiceRequest.ConsumerServiceRequestID,
			purchaseDate:consumerServiceRequest.consumerProduct.DateOfPurchase,
			ProductDescription:selectedSKU && selectedSKU.Description,
			ConfigDescription:selectedSKU && selectedSKU.CodeValue,
			StorageCapacity:selectedSKU && selectedSKU.StorageCapacity,
			isSerializedProduct:consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.isSerializedProduct,
		}
        apiCall({
            url: 'updateSerializedDevice', 
            data: data,
            callBack: (result) => {
                setLoader(false)
                if (result && result.success) {
                    let finishedId = result.data.finishedGoodID;
                    setFinishedGoodID(finishedId);
                    updateCheckListAndStatus('',status, dropOffFlag);
                } else {
                    Swal.fire({
                        title: '',
						text: localString(displayError(result)),
						icon: 'error',
						confirmButtonText: localString('OK')
                    });
                }
            },
        });
	}

    const completeService = (status, additionalData) =>{
		let swaltitle = localString("Are you sure")+'?\n'+localString("Please specify remarks");
		Swal.fire ( {
			title: swaltitle,
			html: '<textarea id="request-remark" class="cancel-remark"></textarea>',
			showCancelButton: true,
			closeOnConfirm: false,
			allowEscapeKey: false,
			allowOutsideClick: false,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("OK")
		}).then( function (result){
			if (result.isConfirmed) {
                let remarkLocal = document.getElementById('request-remark').value;
				if (remarkLocal.length > 0) {
					let remark = remarkLocal ? remarkLocal : '';
					let data = {
						"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
						"Status": status,
						"Remarks": remark
					}
					completeServiceAPiCall(data, additionalData);
					Swal.close();
				} else {
					Swal.fire ( {
						title: '',
						text: localString('Please enter the remarks'),
						icon: 'error',
						showConfirmButton: true,
						showCancelButton: false,
						confirmButtonText: localString('OK')
					}).then(function (result) {
						if (result.isConfirmed) {
							completeService(status);
						}
					})
				}
			} else {
				return
			}
		})
	};
    const FMIPcheck = ()=>{
		let FMIP = false ;
        setLoader(true);
        let data = {
			'BrandID' : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID,
			'imeiOrSerial' :consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductUniqueID,
			'ConsumerID':consumerServiceRequest.ConsumerID,
			'ConsumerProductID':consumerServiceRequest.ConsumerProductID
		}
        apiCall({
            url: 'checkFindMyiPhoneStatus', 
            data: data,
            callBack: (result) => {
                setLoader(false)
                if (result && result.success) {
                    if(result.data){
                        setIsGsxDown(result.data.isGsxDown)
						if(result.data.FindMyiPhone == 'Off'){
							FMIP = false
						} else {
							FMIP = true
						}
					}
					if(FMIP){
						// $('#FMIP-modal').modal('show');
					}else{
						getPart();
						getGSXChecks(false, true);
						// $('#FMIP-modal').modal('hide');
					}
                } else {
                    Swal.fire({
                        title: '',
						text: localString(result.msg || 'Something went wrong'),
						icon: 'error',
						confirmButtonText: localString('OK')
                    });
                }
            },
        });
	}
    // const checkListItemChecked = (e,item) =>{
    //   // for(let i=0; i < deviceReceivedCheckList.length; i++){
    //   //   if(!($('#checkbox_' + i).is(':checked'))) {
    //   //       isSelectAll = false;
    //   //   }
    //   // }
	// 	  //114 being the id of RealMe, 'id' being the countrycode for Indonesia
    //     if((claimFlags.isSelectAll && claimFlags.partSelected && consumerServiceRequest.consumerProduct && [114].indexOf(consumerServiceRequest.consumerProduct.BrandID) > -1 && countryCode && ['id','my','th','ph','pk','eg','mm'].indexOf(countryCode.toLowerCase()) > -1) || (['testing','production','prod','uat'].indexOf(env ) > -1 && accessFn('TESTING_AUTOMATION_FOR_REQUESTS'))){
    //         setClaimFlags({...claimFlags,showDeviceReceived:true})
    //     } else if(claimFlags.isSelectAll && claimDocArray.length > 0 && claimFlags.partSelected){
    //         setClaimFlags({...claimFlags,showDeviceReceived:true})
    //     } else {
    //         setClaimFlags({...claimFlags,showDeviceReceived:false})
    //     }
    //     return;
    // };

    const claimRemoveAddedDoc = (e,i)=> {
        apiCall({
            url: "deleteDocs", 
            data: {UserDocumentID: e.UserDocumentID},
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    if(e.Tag == "Other"){
                        let removeOtherArrImagesPreview = claimOtherArrImagesPreview && claimOtherArrImagesPreview.filter(item=>e.UserDocumentID !==item.UserDocumentID);
                        setClaimOtherArrImagesPreview(removeOtherArrImagesPreview);
                        
                        //claimAddDocArray.splice(e.UserDocumentID, 1);
                        //checkListItemChecked()
                    }else{
                        let removeDocArray = claimDocArray && claimDocArray.filter(item=>e.UserDocumentID !==item.UserDocumentID);
                        setClaimDocArray(removeDocArray);
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    };

    const openReasonsModal = (status) =>{
        setClaimFlags({...claimFlags,showClaimCancelRequestBtn:true})
        if(([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && ((accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY'))|| !accessFn('WORKSHOP_FLOW'))){ //claim new  and claim merge
            if(['Claim initiated', 'Document verification pending', 'Device received', 'Claim estimation generated', 'BER identified', 'In-principle approved', 'Service accepted', 'Payment pending', 'BER approved', 'Pickup Request Accepted', 'Document pending', 'No fault found', 'Device not repairable', 'Device not eligible for repair','Service cancel','DropOff request accepted','Device dispatched','Claim rejected'].indexOf(consumerServiceRequest.Status) > -1) {
                getReasons(status);
            } else {
                showConfirmation(status);
            }
        }else if(berChecks){ //ber
            if(['BER identified', 'BER approved', 'BER documents validated', 'BER document verification pending', 'Document verification pending','Claim rejected','Service cancel','BER payment revision required'].indexOf(consumerServiceRequest.Status) > -1) {
                getReasons(status);
            } else {
                preBerRequestNext(status);
            }
        }
    }

    const continueAfterSelectingReason = (status) => {
        setLoader(true)
        let data = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            Remarks :cancelReasonsData && cancelReasonsData.OtherReason || (filterCancelrequest == 'Other') ? cancelText : filterCancelrequest ? filterCancelrequest : undefined,
            ServiceTypeID: consumerServiceRequest.ServiceTypeID,
            Status: nextStatus,
            ConsumerProductID: consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ConsumerProductID
        };
        if(claimChecks || claimNewWorkshopChecks){
            if (accessFn('ALLOW_ADDITIONAL_REASON') || accessFn('WAITING_ON_CUSTOMER_BUTTON')) {
                data.AdditionalRemarks = internalReason
            }
        }else{
            if (accessFn('ALLOW_ADDITIONAL_REASON')) {
                if(internalReason){
                    data.AdditionalRemarks = internalReason
                }
            }
        }
        apiCall({
            url: nextStatus == 'Service cancel' ? 'cancelRequestWithLogistics' : 'claimRequest', 
            data: data,
            callBack: (result) => {
                setLoader(false)
                if (result && result.success) {
                    setShowModal({...showModal,showCancelRequestModal:false});
                    getRequestById();
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    };

    const capturePaymentMode =  () => {
        setShowBillingModal(false);
        setShowModal({...showModal,showCapturePaymentMode:true})  
    }

    const claimRequestNonWorkshop = (status) =>{
        if(claimChecks){
            setShowBillingModal(false)
        }
        Swal.fire({
            title: localString("Are you sure")+"?",
            html: '',
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("Yes")
        }).then(function(result) {
            if (result.isConfirmed) {
                Swal.close();
                claimRequestNext(status ? status:nextStatus);
            }
        });
    };

    const claimRequestNextWs = (status) =>{
        Swal.fire({
            title: localString("Are you sure? Please specify remarks, if any..."),
            html: '<textarea id="request-remark" class="cancel-remark"></textarea>',
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
        }).then(function (result) {
            if (result.isConfirmed) {
                let remark = document.getElementById("request-remark").value ? document.getElementById("request-remark").value: ''; 
                Swal.close();
                claimRequestWorkshopNext(status, remark, null,'',modeOfPayment);
            }
        });
    };

    const cancelRequestSerivce =  (nextStatus) =>{
        let swaltitle = localString("Are you sure?")+'\n'+localString("Please specify remarks, if any...");
        Swal.fire ({
            title: swaltitle,
            html: '<input type = "checkbox" style = "margin-bottom:15px" id = "cancel_check" checked/><span style = "margin-left: 10px; font-size: 16px;">'+localString("This service is a Return without repair (RWR)")+'<br></span><input id="cancel-remark" class="cancel-remark"></input>',
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
        }).then(function (result) {
            if (result.isConfirmed) {
                let remark = document.getElementById("cancel-remark").value ? document.getElementById("cancel-remark").value: ''; 
                if ( document.getElementById("cancel_check").checked) {
                    remark = "Return without repair (RWR)" + (remark?'-' + remark:'')
                }
                if (['Service cancel','Repair cancel','Cancel Service Without Dispatch'].indexOf(nextStatus) > -1 && remark == '') {
                Swal.fire({
                    title:'',
                    text: localString("Cannot proceed with this action without providing a reason."),
                    error :'error'
                });
                return;
                }
                Swal.close();
                let url = 'servicerequestFulfilmentWorkshop'
                if (['Service cancel'].indexOf(nextStatus) > -1) {
                    url = 'cancelRequestWithLogistics'
                }
                if (['Check whitelisting'].indexOf(nextStatus) > -1) {
					nextStatus = 'Service cancel';
				}
                if(['Cancel Service Without Dispatch'].indexOf(nextStatus) > -1){
                    url = 'cancelRequestWithoutDispatch'   
                }
                let data = {
                    ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                    Status: nextStatus,
                    Remarks: remark,
                }
                setLoader(true);
                apiCall({
                    url: url, 
                    data: data,
                    callBack: (result) => {
                        setLoader(false)
                        if (result && result.success) {
                            if(accessFn('WORKSHOP_FLOW') && !accessFn('GSX_FLOW')){
                                getRequestById()
                            }else{
                                getRequestById(remark);
                            }
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: localString(displayError(result)),
                            });
                        }
                    },
                });
            }
        });
    }
    const showQCCompletedModal = () =>{
        setShowModal({...showModal, showPerformQcModal:true});
    }
    const acceptQc = () => {
        if (showQCChecklistComplete) {
            setNextStatus('QC completed')
            setShowModal({...showModal, showPerformQcModal:false});
            if(claimNewWorkshopChecks){
                claimRequestNextWs('QC completed')
            }else{//non gsx
                processRequestNextPickUp('QC completed')
            }
        } else {
            Swal.fire ({
                title:'Sorry',
                text:localString('Please complete the QC checklist'),
                icon:'error'
            })
            return;
        }
    }

    const rejectQc = ()=>{
        // setNextStatus('QC rejected')
        setShowModal({...showModal, showPerformQcModal:false});
        if(claimNewWorkshopChecks){
            claimRequestNextWs('QC rejected')
        }else{//non gsx
            processRequestNextPickUp('QC rejected')
        }
        
    }

    //complete repair
    const showPartsModalPickUpCarryInGsx = (status) => {
        if (consumerServiceRequest.DOAStatus == 'replacement') {
            // create this
            setShowModal({...showModal, showDeviceUniqueModal:true});
        } else {
            let isAnyPartUsed = false;
            if(partsRequested && partsRequested.length){
                for (let iCount=0; iCount< partsRequested.length; iCount++) {
                    if (partsRequested[iCount].statusDetails.Status == 'received') {
                        isAnyPartUsed = true;
                        break;
                    }
                }
            }
            let isHardwareClosure = find(consumerServiceRequest.logs, {Status: 'Non hardware closure details updated'});

            if (partsRequested && partsRequested.length > 0 && isAnyPartUsed) {
                setShowModal({...showModal, showPartsUniqueModal:true});
            } else{
                if(accessFn('GSX_FLOW') && !isHardwareClosure){
                    openReapirNotesModal();
                }else{
                    repairCompletePickUpCarryInGsx(undefined, status);
                }

            }
        }
    }

    const  isAlreadyRetrieved =(part) =>{
        let isRetrieved = find(partsRequested, function(o) {
            return ((o.statusDetails.Status == 'defective' || o.statusDetails.Status == 'returnCustomer' || o.statusDetails.Status == 'handover') && o.PartCode == part.PartCode)
        })
        if (!isRetrieved) {
            setClaimFlags({claimFlags, anyPartReturned:true});
            return false
        }
        return true
    }
    
    const showPartsModalPickUpCarryIn = () =>{
        let isAnyPartUsed = false;
        for (let iCount =0;iCount< partsRequested.length;iCount++) {
            if (partsRequested[iCount].statusDetails && partsRequested[iCount].statusDetails.Status == 'received' && !isAlreadyRetrieved(partsRequested[iCount])) {
                isAnyPartUsed = true;
                break;
            }
        }
        if(!isRequestSFANeeded){
            loadFaultCode()
        }
        getDefectiveParts();
        if(isRequestSFANeeded){
            if(isAnyPartUsed){
                setShowModal({...showModal, showPartsUniqueModal:true});
            } else {
                repairCompletePickUpCarryIn();
            }
        } else {
            setShowModal({...showModal, showPartsUniqueModal:true});
        }
        if (partsRequested && partsRequested.length > 0 && isAnyPartUsed) {
            for (let iCount = 0;iCount<partsRequested.length;iCount++) {
                if (partsRequested[iCount].PartID == consumerServiceRequest.consumerProduct.FinishedGoodID) {
                    setClaimFlags({...claimFlags, isFullBoxUnit:true});
                    break
                }
            }
        }
    } 

    const repairCompletePickUpCarryIn = (e) => {
        if (e) {
            e.preventDefault();
        }
    
        setShowModal({ ...showModal, showPartsUniqueModal: false });
    
        if (isRequestSFANeeded) {
            if (requestSFA && requestSFA.length > 0) {
                //nothing
            } else {
                Swal.fire({
                    title: "",
                    text: localString("Please add mandatory Symptom Fault Action"),
                    icon: "error",
                });
                return;
            }
        }
    
        let swaltitle = localString(
            "Are you sure?\nPlease specify remarks, if any..."
        );
    
        Swal.fire({
            title: swaltitle,
            html: '<textarea id="request-remark" class="cancel-remark"></textarea>',
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK"),
        }).then(function (result) {
            if (result.isConfirmed) {
                let requestRemarks = document.getElementById("request-remark").value;
                
                if (
                    requestRemarks.length > 24 ||
                    (defaultCountry == "CN" && requestRemarks.length > 0)
                ) {
                    if (faultArray.length > 0) {
                        addFaultAction(requestRemarks);
                    } else {
                        completeRepairProgress(requestRemarks);
                    }
    
                    Swal.close();
                } else {
                    Swal.fire({
                        title: "",
                        text: localString(
                            defaultCountry == "CN"
                                ? "Please enter the remarks with at least 1 characters"
                                : "Please enter the remarks with at least 25 characters"
                        ),
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: localString("OK"),
                    }).then(function (result) {
                        if (result.isConfirmed) {
                            repairCompletePickUpCarryIn();
                        }
                    });
                }
            } else {
                return;
            }
        });
    };
    
    const RepairCompleteAPICall = (data, requestUrl, remark) => {
        setLoader(true);
        apiCall({
            url: requestUrl,
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    setRepairNotes('')
                    setShowModal((prevData) => ({
                        ...prevData,
                        showRepairNotesModal: false,
                    }));
                    setIsSameNotes(false);
                    getRequestById(remark);
                } else {
                    setAlreadyMarked(false);
    
                    if (
                        result.enableUserConsent &&
                        requestUrl == "markReadyForPickup"
                    ) {
                        Swal.fire({
                            title: "",
                            text: localString(displayError(result)),
                            showCancelButton: true,
                            closeOnConfirm: true,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            cancelButtonText: localString("Cancel"),
                            confirmButtonText: localString(
                                "Proceed without GSX update"
                            ),
                        }).then(function (result) {
                            if (result.isConfirmed) {
                                data.isGSXBypass = true;
                                RepairCompleteAPICall(data, requestUrl, remark);
                            }
                        });
                        
                    } else {
                        Swal.fire({
                            title: "",
                            text: localString(displayError(result)),
                            icon: "error",
                            confirmButtonText: localString("OK"),
                        });
                    }
                }
            },
        });
    };
    
    const repairCompletePickUpCarryInGsx = (e, status) => {
        // Integrated repairComplete() function of pick_up_carry_in_gsx.tag file
        if (e) {
            e.preventDefault();
        }
    
        let requestUrl = "";
        if (accessFn("GSX_FLOW") && consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
            requestUrl = "markReadyForPickup";
        } else {
            requestUrl = "servicerequestFulfilmentWorkshop";
        }
    
        //check this
        if (consumerServiceRequest.DOAStatus == "replacement") {
            setShowModal({...showModal, showDeviceUniqueModal: false});
        } else {
            setShowModal((prevData) => ({
                ...prevData,
                showPartsUniqueModal: false,
            }));
        }
    
        let swaltitle = localString("Are you sure") + "?\n" + localString("Please specify remarks") + "...";
    
        if (partsRequested && partsRequested.length > 0) {
            if (accessFn("GSX_FLOW")) {
                // let remark = document.getElementById("repair_notes").value
                //     ? document.getElementById("repair_notes").value
                //     : "";
                let remark = repairNotes || ''
                if (!remark) {
                    setAlreadyMarked(false);
                    Swal.fire({
                        title: "",
                        text: localString("Please enter the Repair Notes"),
                        icon: "error",
                        confirmButtonText: localString("OK"),
                    });
                    return;
                }
    
                // let partsRequestedClone = cloneDeep(partsRequested);
                // for (let iCount = 0; iCount < partsRequested.length; iCount++) {
                //     if (partsRequested[iCount].statusDetails.Status === "consumed") {
                //         //pass
                //     } else {
                //         pull(partsRequestedClone, partsRequested[iCount]);
                //     }
                // }
                // for (let iCount = 0; iCount < partsRequestedClone.length; iCount++) {
                //     partsRequestedClone[iCount]["action"] = "defective";
                //     delete partsRequestedClone[iCount]["PartTransactionStatusID"];
                // }

                let arr = [];
                partsRequested.filter((item) => {
                    if(item?.statusDetails?.Status && item.statusDetails.Status == "consumed") {
                        let obj = {...item};
                        obj["action"] = "defective";
                        delete obj["PartTransactionStatusID"];
                        arr.push(obj);
                    }
                })
    
                // let status = nextStatus;
                let data = {
                    ConsumerServiceRequestID:
                        consumerServiceRequest.ConsumerServiceRequestID,
                    Status: status || nextStatus,
                    repairCompletionNotes: remark,
                    isCheckNotes: isSameNotes,
                    HandoverParts: true,
                    DamagedParts: arr,
                    markReadyForPickup: accessFn("GSX_FLOW")
                        ? !consumerServiceRequest.IsQcPresent
                        : undefined,
                    isMailinRequest: consumerServiceRequest.isMailinRequest,
                };
                RepairCompleteAPICall(data, requestUrl, remark);
            } else {
                Swal.fire({
                    title: swaltitle,
                    html: '<textarea id="request-remark" class="cancel-remark"></textarea>',
                    showCancelButton: true,
                    closeOnConfirm: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    cancelButtonText: localString("Cancel"),
                    confirmButtonText: localString("OK"),
                }).then(function (result) {
                    if (result.isConfirmed) {
                        if (document.getElementById("request-remark").value.length > 0) {
                            Swal.close();
                            let partsRequestedClone = cloneDeep(partsRequested);
                            if ( !accessFn("GSX_FLOW") && faultArray && faultArray.length > 0) {
                                // check this the definition wasnt there in pickupcarryingsx file
                                addFaultAction();
    
                                if (consumerServiceRequest.DOAStatus != "replacement") {
                                    for (let iCount = 0; iCount < partsRequested.length; iCount++) {
                                        // check the partcheck state
                                        if (partcheck) {
                                            // $("#partcheck_" + iCount).is(":checked")                                        
                                            // pass
                                        } else {
                                            pull(partsRequestedClone, partsRequested[iCount]);
                                        }
                                    }
                                }
    
                                for (let iCount = 0; iCount < partsRequestedClone.length; iCount++) {
                                    partsRequestedClone[iCount]["action"] = "defective";
                                    delete partsRequestedClone[iCount]["PartTransactionID"];
                                    delete partsRequestedClone[iCount]["PartTransactionStatusID"];
                                    // didnt find this partsserial state check this later
                                    partsRequestedClone[iCount]["PartSerialNumber"] = '' //$("#partserial_" + iCount).val();
                                    partsRequestedClone[iCount]["SerialNumber"] = ''// partserial$("#partserial_" + iCount).val();
                                }
                            }
    
                            let remark = document.getElementById("request-remark").value
                                ? document.getElementById("request-remark").value
                                : "";
                            // let status = nextStatus;
                            let data = {
                                ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                                Status: status || nextStatus,
                                Remarks: remark,
                                HandoverParts: true,
                                DamagedParts: partsRequestedClone,
                                markReadyForPickup: accessFn("GSX_FLOW") ? !consumerServiceRequest.IsQcPresent : undefined,
                                isMailinRequest:
                                    consumerServiceRequest.isMailinRequest,
                            };
                            RepairCompleteAPICall(data, requestUrl, remark);
                        } else {
                            setAlreadyMarked(false);
                            Swal.fire({
                                title: "",
                                text: localString("Please enter the remarks"),
                                icon: "error",
                                showConfirmButton: true,
                                showCancelButton: false,
                                confirmButtonText: localString("OK"),
                            }).then(function (result) {
                                if (result.isConfirmed) {
                                    repairCompletePickUpCarryInGsx(undefined, status);
                                }
                            });
                        }
                    } else {
                        return;
                    }
                });
            }
        } else {
            let isHardwareClosure = find(consumerServiceRequest.logs, {
                Status: "Non hardware closure details updated",
            });
            if (accessFn("GSX_FLOW") && !isHardwareClosure) {
                let remark = repairNotes ? repairNotes : "";
                if (!remark) {
                    setAlreadyMarked(false);
                    Swal.fire({
                        title: "",
                        text: localString("Please enter the Repair Notes"),
                        icon: "error",
                        confirmButtonText: localString("OK"),
                    });
                    return;
                }
    
                // let status = nextStatus;
                let data = {
                    ConsumerServiceRequestID:
                        consumerServiceRequest.ConsumerServiceRequestID,
                    Status: status || nextStatus,
                    repairCompletionNotes: remark,
                    isCheckNotes: isSameNotes,
                    HandoverParts: false,
                    markReadyForPickup: accessFn("GSX_FLOW")
                        ? !consumerServiceRequest.IsQcPresent
                        : undefined,
                };
    
                RepairCompleteAPICall(data, requestUrl, remark);
            } else {
                Swal.fire({
                    title: swaltitle,
                    html: '<textarea id="request-remark" class="cancel-remark"></textarea>',
                    showCancelButton: true,
                    closeOnConfirm: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    cancelButtonText: localString("Cancel"),
                    confirmButtonText: localString("OK"),
                }).then(function (result) {
                    if (result.isConfirmed) {
                        if (document.getElementById("request-remark").value && (document.getElementById("request-remark").value.length > 0)) {
                            Swal.close();
                            if (!accessFn("GSX_FLOW") && faultArray && faultArray.length > 0 ) {
                                // check this the definition wasnt there in pickupcarryingsx file
                                addFaultAction();
                            }
    
                            let remark = document.getElementById("request-remark").value
                                ? document.getElementById("request-remark").value
                                : "";
                            // let status = nextStatus;
                            let data = {
                                ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                                Status: status || nextStatus,
                                Remarks: remark,
                                HandoverParts: false,
                                markReadyForPickup: accessFn("GSX_FLOW")
                                    ? !consumerServiceRequest.IsQcPresent
                                    : undefined,
                            };
    
                            RepairCompleteAPICall(data, requestUrl, remark);
                        } else {
                            setAlreadyMarked(false);
                            Swal.fire({
                                title: "",
                                text: localString("Please enter the remarks"),
                                icon: "error",
                                showConfirmButton: true,
                                showCancelButton: false,
                                confirmButtonText: localString("OK"),
                            }).then(function (result) {
                                if (result.isConfirmed) {
                                    repairCompletePickUpCarryInGsx(undefined, status);
                                }
                            });
                        }
                    }
                });
            }
        }
    };
    

    const addFaultAction =  (remarks) =>{
		let faults = []
        setLoader(true);
		for (let iCount=0;iCount<faultArray.length;iCount++) {
			let faultObject = {};
			faultObject.FaultID = faultArray[iCount].FaultID;
            faultObject.FaultCode = faultArray[iCount].FaultCode;
			faults.push(faultObject);
		}
		let data  = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"faults": faults
		}
        apiCall({
            url: "addFaultsAction", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    completeRepairProgress(remarks);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
	}


    const completeRepairProgress = (remarks = '') =>{
		let handoverParts = false;
        setLoader(true);
		if (partsRequested && partsRequested.length > 0) {
			let partsRequested = [...partsRequested]
                for (let iCount = 0;iCount< partsRequested.length;iCount++) {
                    if (partcheck.checked){
                    // pass
                    } else {
                        pull (partsRequested, partsRequested[iCount])
                    }
                }
			for (let iCount = 0 ;iCount<partsRequested.length;iCount++) {
				partsRequested[iCount]['action'] = 'defective';
				// partsRequested[iCount]['PartTransactionID'] = 0;
				// partsRequested[iCount]['PartTransactionStatusID'] = 0;
				// delete partsRequested[iCount]['PartTransactionID']
				// delete partsRequested[iCount]['PartTransactionStatusID']
				// partsRequested[iCount]['PartSerialNumber'] = $("#partserial_" + iCount).val()
				// partsRequested[iCount]['SerialNumber'] = $("#partserial_" + iCount).val()
				// partsRequested[iCount]['BadPartSerialNumber'] = $("#partserial_" + iCount).val()
			}
			handoverParts = true;
		}
		let data = {
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			Status:  nextStatus,
			Remarks: remarks,
			HandoverParts: handoverParts,
			DamagedParts: defectivePartsRequested
		};
        apiCall({
            url: "claimRequestWorkshop", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    getRequestById(remarks);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
	}
    const getDefectiveParts = ()=>{
		setLoader(true);
        let defactiveData = {
			'ConsumerServiceRequestID': consumerServiceRequest.ConsumerServiceRequestID
		}
        apiCall({
            url: "getDefectiveParts", 
            data: defactiveData,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    setDefectivePartsRequested(result.data)
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
	}

    const loadFaultCode = ()=>{
        let data = {
            PartnerServiceLocationID:consumerServiceRequest.PartnerServiceLocationID,
            ProductSubCategoryID:consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID
        };
        setShowFaultLoader(true);
        apiCall({
            url: "getFaults", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    let faultData = result.data && result.data.faults.map(v=>({...v,value:v.FaultDescription}));
                    setSelectedFault(faultData);
                    setShowFaultLoader(false);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

    const addToFaultArray =  () => {
        setDisableAddBtn(true);
  		// disableAddBtn = true
        let index = findIndex(faultArray, function(o) {
            return o.FaultID == selectedFault.FaultID
            });
        if (index > -1) {
            Swal.fire({
                title:'Sorry',
                text:localString('This fault code/ cause code has already been selected'),
                icon:'error'
            });
            return
        }
        let frautArr = [...faultArray]
        frautArr.push(selectedFaultItems);
        setFaultArray(frautArr);
        setFaultCode({...faultCode,faultCodeText:''});
    }
    const removeFaultCode = function (fault) {
        const removeFault = faultArray.filter(item=>fault !== item.FaultID)
        setFaultArray(removeFault);
    }

    // const claimRequestInPrinciple = function(status) {
    //     Swal.fire({
    //         title: localString("Are you sure")+"?",
    //         html: '',
    //         showCancelButton: true,
    //         closeOnConfirm: false,
    //         allowEscapeKey: false,
    //         allowOutsideClick: false,
	// 		cancelButtonText: localString("Cancel"),
	// 		confirmButtonText: localString("Yes")
    //     }).then(function(result) {
    //         if (result.isConfirmed) {
    //             Swal.close();
    //             claimRequestNext(status);
    //         }
    //     });
    // };
    const showClaimInitiatedConfirm =(status)=>{
        Swal.fire({
            title: localString("Please enter Claim intimation number"),
            html: '<textarea id="intimation-remark" class="cancel-remark"></textarea>',
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("OK")
        }).then(function(result) {
            if (result.isConfirmed) {
                let remark = document.getElementById("intimation-remark").value; 
                if (remark != '') {
                    Swal.close();
                    claimInitiated(remark);
                } else {
                    Swal.close();
                    Swal.fire({
                        title: '',
                        text:localString("Please enter Claim intimation number before submitting"),
                        icon:'error'
                    }).then(function(result) {
                        if (result.isConfirmed) {
                            showClaimInitiatedConfirm();
                        }
                    });
                }
            }
        });
    }

    const createExchangeOrder = ()=> {
		Swal.fire({
            title: localString("Please verify or edit Product/Model code for Replacement Device"),
            html: '<textarea id="model-number" class="cancel-remark">'+consumerServiceRequest.consumerProduct.ModelNo+'</textarea>',
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
        }).then(function (result) {
            if (result.isConfirmed) {
                setLoader(true); 
                Swal.close();
                apiCall({
                    url: "createOrder", 
                    data:  {
                        "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
                        "ModelNo": document.getElementById('model-number').value != ''? document.getElementById('model-number').value : consumerServiceRequest.consumerProduct.ModelNo
                    },
                    callBack: (result) => {
                        setLoader(false);
                        if (result.success) {
                            getRequestById();
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: localString(displayError(result)),
                            });
                        }
                    },
                });
            }
        });
	}
    const claimInitiated = () => {
        setLoader(true);
        let params = {
            "ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            "intimationNumber": document.getElementById("intimation-remark").value,
            ServiceTypeID: consumerServiceRequest && consumerServiceRequest.ServiceTypeID,
            "Status": 'Claim initiated'
        };
        apiCall({
            url: "claimRequest", 
            data: params,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    getRequestById();
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

    const replacementSecurityChargeDeposit=(status)=>{
        Swal.fire({
            title: localString("Are you sure to charge the customer for non-return fee?"),
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
        }).then(function (result) {
            if (result.isConfirmed) {
                replacementFulfillment(null,status);
                Swal.close();
            }
        });
    }

    const replacementSecurityReleaseDeposit=(status)=>{
        Swal.fire({
            title: localString("Are you sure to release the customer’s non-return-fee?"),
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
        }).then(function (result) {
            if (result.isConfirmed) {
                replacementFulfillment(null,status);
                Swal.close();
            }
        });
    }
    //flag froud
    const getAddedFlag = ()=>{
        setLoader(true);
        let data = {
            "referenceID": consumerServiceRequest.ReferenceID,
        }
        apiCall({
            url: "getFraudFlag", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    setAddedFraudFlag(result.data);
                    openFlagFraudModal();
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

    const openFlagFraudModal = () =>{
        setShowModal({...showModal, showFlagFraudsModal: true});
    }

    //

    //skip form
    const showSkipFormConfirmation = ()=>{
        Swal.fire({
            title: localString('Are you sure you want to skip decision form?'),
            showCancelButton: true,
            closeOnConfirm: true,
            allowEscapeKey: false,
            allowOutsideClick: true,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
        }).then(function(result) {
            if (result.isConfirmed) {
                skipDecisionForm()
            }
        });
    }

    const skipDecisionForm = () => {
        let isSkipFormClicked = true;
        getRecommendedModes(isSkipFormClicked);
    };

    const processDecisionObject = (dataObject) => {
        let modes = decionApiResponse;
        for(let i = 0; i < modes.length; i++) {
            for(let j = 0; j < modes[i].inputOption.length; j++) {
                let decisionObject = modes[i].inputOption[j];
                let inputValue  = ''
                // let inputValue = $('#' + decisionObject.ConstantName).val();
                if(decisionObject.OptionType != 'File') {
                    if(decisionObject.OptionType == 'DropDown' && inputValue == 'Other') {
                       // dataObject[decisionObject.ConstantName] = $('#' + decisionObject.ConstantName + '-other').val();
                    } else {
                        dataObject[decisionObject.ConstantName] = inputValue;
                    }
                }
            }
        }
        return dataObject;
    };
    const getRecommendedModes=(isSkipFormClicked)=>{
        setLoader(true);
        let data = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            PlanCoverageID: consumerServiceRequest.PlanCoverageID ? consumerServiceRequest.PlanCoverageID :  soldPlanCoverage ? soldPlanCoverage.PlanCoverageID : undefined,
            AdvanceChargeApplicable: AdvanceChargeApplicable ? true : false,
            SkipDecisionForm: isSkipFormClicked ? isSkipFormClicked : undefined,
            docs: documentsArray
        };
        let processedObject = {...data,...decisionFormData};
        Object.keys(decisionFormData).map(item=>{
            if(decisionFormData[item] === 'Other'){
                processedObject[item] = otherSource[item]
            }
        })
        
        apiCall({
            url: "getRecommendedModes", 
            data: processedObject,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    setShowModal({...showModal,showDecisionFormModal:false});
                    //closeDecisionForm();
                    getRequestById();
                } else {
                    Swal.fire({
                        title: '',
                        text: result.msg ? localString(result.msg) : localString("Something went wrong"),
                        icon: 'error',
                        confirmButtonText: localString('OK'),
                        cancelButtonText: localString('Cancel')
                    });
                }
            },
        });
    }

    //replacement flow
    const openChequeModal = (status,checkNoFlag) => {
        Swal.fire({
            title: localString('Please enter cheque number'),
            html: '<textarea id="cheque-number" class="cancel-remark" style="width:80%;"></textarea>',
            showCancelButton: true,
            closeOnConfirm: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
        }).then(function(result) {
            if (result.isConfirmed) {
                let chequeNumber = ''
                if(document.getElementById('cheque-number').value) {
                    chequeNumber = document.getElementById('cheque-number').value;
                    status = 'Service completed';
                    if(berChecks){
                        preBerRequestNext('Service completed',{ChequeNumber:chequeNumber})
                    }else{
                        buyoutFulfillment(null,status,chequeNumber);
                    }
                    
                } else {
                    Swal.fire({
                        title:'',
                        text:localString('Enter valid cheque number'),
                        error:'error'
                    })
                    .then(function(result) {
                        if (result.isConfirmed) {
                            // chequeNumber=null;
                            openChequeModal();
                        }
                    });;
                }
            }
        });
    };

    const buyoutFulfillment = (requestParams,status,buyoutChequeNumber,replacementCheckCost)=>{
        let data;
        if(onsiteChecks){
            data = {
                ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                Status: 'Reimbursement approved',
                Attributes: requestParams,//payoutAttributes
                reimbursementConversion: true,
                "DeepLinkURL": window.location.origin + '/internalredirect?u=' + window.location.pathname + window.location.search,
            };
            data.PlanCoverageID =  soldPlanCoverage && soldPlanCoverage.PlanCoverageID ? soldPlanCoverage.PlanCoverageID : undefined;

        }else{//replacement flow
            data = {
                "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
                "Status": status,
                "DeepLinkURL": window.location.origin + '/internalredirect?u=' + window.location.pathname + window.location.search,
                "ChequeNumber": buyoutChequeNumber ? buyoutChequeNumber : undefined
            };
            if(requestParams){
                data = requestParams;
            }
            data.PlanCoverageID =  soldPlanCoverage && soldPlanCoverage.PlanCoverageID ? soldPlanCoverage.PlanCoverageID : undefined;
            if(!consumerServiceRequest.ReplacementCost && consumerServiceRequest.Status == 'Document verification pending' && consumerServiceRequest.ServiceTypeID == SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE) {
                data.Attributes = [{AttrName: 'REPLACEMENT_COST', AttrValue: replacementCheckCost}];
            }
            if(['Pending for reimbursement'].indexOf(consumerServiceRequest.Status) > -1) {
                data.reimbursementConversion = true;
                if(!consumerServiceRequest.ReplacementCost) {
                    data.Attributes = [{AttrName: 'REPLACEMENT_COST', AttrValue: replacementCheckCost}];
                }
            }
        }
        setLoader(true);
        apiCall({
            url: "reimbursementFullfillment", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    setLoader(false);
                    getRequestById();
                } else {
                    Swal.fire({
                        title: '',
                        text: localString(displayError(result)),
                        icon: 'error',
                    });
                }
            },
        });
    }

    const getDiagnosisCompleteLabel = (status) => {
        let data = {
            "ProductSubCategoryID": consumerServiceRequest.consumerProduct.ProductSubCategoryID,
            "EligibleForDiagnosis": consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig ? consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis : ""
        };
        if(['Cannot be diagnosed'].indexOf(status) > -1){
            data.cannotDiagnose =  true;
        }else{
            data.cannotDiagnose = false;
        }
        setLoader(true);
        apiCall({
            url: 'getCompleteDiagnosisRequirements',
            data: data,
            callBack: (result) => {
                setLoader(false); 
                if(result && result.success) {
                    let diagnosisData = result.data;
                    setDiagnosisLabel(diagnosisData)
                    showDiagnosisNotesModal(diagnosisData);
                }else {                    
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(result)), 
                    });
                }
            },
        });
    }

    const openReplacementOptions =()=>{
        let localReplacementSumInsured = getSumInsured();
        setReplacementSumInsured(localReplacementSumInsured);
        setShowModal({...showModal,showReplacementOptionsModal:true})
    }

    const selectOption = (option)=>{
        setSelectedReplacement(option);
    }
    const saveSelectedOption = () => {
        let data = {
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "Status": 'Pending for replacement',
            "SelectedReplacementOption": selectedReplacement.Option,
			'CountryID': consumerServiceRequest && consumerServiceRequest.CountryID
        };
        replacementFulfillment(data);
    };
    const closeOptionsForm = () => {
        setShowModal({...showModal,showReplacementOptionsModal:false});
        setOptionsArray([])
        setSelectedReplacement(null);
        setReplacementFlowFlags({addOptionsMode:false,viewOptionsMode:false,chooseOptionsMode:false});
        // disableOptionSave = true;
        // selectedReplacement = null;
    };
    const replacementFulfillment = (requestParams,status) =>{
        let  data = {
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "Status":status ? status : nextStatus,
            // 'ModeOfPayment': modeOfPayment,
            // 'PaymentMode': modeOfPayment,
            'CountryID': consumerServiceRequest && consumerServiceRequest.CountryID 
        };
        if(requestParams){
            data = requestParams;
        }
        if(soldPlanCoverage && soldPlanCoverage.PlanCoverageID){
            data.PlanCoverageID =  soldPlanCoverage && soldPlanCoverage.PlanCoverageID
        }
        if( modeOfPayment){
            data.ModeOfPayment = modeOfPayment && modeOfPayment;
            data.PaymentMode = modeOfPayment && modeOfPayment;
        }
        setLoader(true);
        apiCall({
            url: "replacementFulfillment", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    getRequestById();
                    closeModal();
                }else {
                    if(nextStatus == 'Security deposit charged'){
                        Swal.fire({
                            title: localString(displayError(result)),
                            showCancelButton: false,
                            closeOnConfirm: false,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            confirmButtonText: localString("OK")
                        }).then(function(result) {
                            if (result.isConfirmed) {
                                getRequestById();
                                Swal.close();
                            }
                        });
                    }else{
                        Swal.fire({
                            title : '',
                            text:localString(displayError(result)),
                            icon:'error'
                        });
                    }
                }
            },
        });
    }

    const createRefurbRequest = () =>{
		Swal.fire({
            title: localString("Are you sure?"),
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
        }).then(function (result) {
            if (result.isConfirmed) {
                Swal.close();
                apiCall({
                    url: "createRefurbishment", 
                    data: {"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID},
                    callBack: (result) => {
                        setLoader(false);
                        if (result.success) {
                            getRequestById();
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: localString(displayError(result)),
                            });
                        }
                    },
                });
            }
        });
	}
    const approveConfirmation = (status) => {
        if(!consumerServiceRequest.ReplacementCost) {
            Swal.fire({
            title: localString('Please enter replacement cost'),
            html: '<textarea id="replacement-cost" class="cancel-remark" style="width:80%;"></textarea>',
            showCancelButton: true,
            closeOnConfirm: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
        }).then(function(result) {
            if (result.isConfirmed) {
                let replacementCheckCost = document.getElementById("replacement-cost").value;
                if(document.getElementById("replacement-cost").value && !document.getElementById("replacement-cost").value.match(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi) ){
                    buyoutFulfillment(null, status,null,replacementCheckCost);
                    Swal.close();
                }
                else {
                    Swal.fire({
                        title:'',
                        text:localString('Enter valid replacement cost'),
                        icon:'error'
                    })
                    .then(function(result) {
                        if (result.isConfirmed) {
                            approveConfirmation(status);
                        }
                    });;
                }
            }
        });
        } else {
            Swal.fire({
                title: localString("Are you sure?"),
                showCancelButton: true,
                closeOnConfirm: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                cancelButtonText: localString("Cancel"),
                confirmButtonText: localString("OK")
            }).then(function (result) {
                if (result.isConfirmed) {
                    buyoutFulfillment(null, status);
                    Swal.close();
                }
            }); 
        }
            
    };
    const allocateReplacement = ()=>{
        let data = {
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "Status":nextStatus,
            "AdvanceChargeApplicable":AdvanceChargeApplicable,
			'CountryID': consumerServiceRequest && consumerServiceRequest.CountryID 
        };
        deviceConfigInput.map(item=>{
            if(item.Editable && item.Value){
                data[item.RequestParameter] = item.Value;
            }
            // document.getElementById(item.ConstantName).value('')
        })
        setShowModal({...showModal,showReplacementDetailsModal:false});
        replacementFulfillment(data);
        setDeviceConfigVal('')
    }

    const deviceConfigData = (e,val)=>{
        if(e.target.value && val.Validation && val.Validation.InputRegex){
            if(PATTERN[(val.Validation.InputRegex).toLowerCase()].test(e.target.value)){
                val.Value = e.target.value;
                setDeviceConfigVal({...deviceConfigVal,[val.ConstantName]:val.Value})
                if(val.ConstantName=='ReplacementPrice'){
                    setReplacementPrice(val.Value);
                }
            }else{
                e.target.value = val && val.Value ? val.Value: "";
                if(val.ConstantName=='ReplacementPrice'){
                    setReplacementPrice(val.Value);
                }
            }
        }else{
            val.Value = e.target.value;
            setDeviceConfigVal({...deviceConfigVal,[val.ConstantName]:val.Value});
            if(val.ConstantName=='ReplacementPrice'){
                setReplacementPrice(val.Value);
            }
        }
        // setDeviceConfigInput({...val,Value:val.Value});
    }

    const onPayoutModeSubmit = (payoutAttributes) => {
        if(['Replacement options provided'].indexOf(consumerServiceRequest.Status) > -1) {
            replacementFulfillment({
                ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                Status: 'Pending for reimbursement',
                reimbursementConversion: true,
                Attributes: payoutAttributes,
				'CountryID': consumerServiceRequest.CountryID
            });
        } else if(berChecks){
            preBerRequestNext('BER confirmed', {Attributes: payoutAttributes}); 
        }else if(onsiteChecks){
            buyoutFulfillment(payoutAttributes);
        }
        else {
            buyoutFulfillment({
                ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                Status: 'Reimbursement approved',
                Attributes: payoutAttributes,
                reimbursementConversion: true,
                DeepLinkURL: window.location.origin + '/internalredirect?u=' + window.location.pathname + window.location.search
            });
        }
    };
    // const processReplacementOptionsObj = () => {
    //     let validArray = optionsArray.filter((obj, index) => {
    //         obj.index = index;
    //         return obj.isArrayValid;
    //     });

    //     validArray.forEach((option) => {
    //         option.OptionValues = [];
    //         deviceConfigInput.forEach((obj) => {
    //             if(obj.ConstantName != 'Attributes') {
    //                 // var id = '#' + obj.ConstantName + '-option-' + option.index;
    //                 // if($(id) && $(id).val()) {
    //                 //     option.OptionValues.push({
    //                 //         "DeviceOptionType": obj.ConstantName,
    //                 //         "DeviceOptionValue": $(id).val()
    //                 //     });
    //                 //     if(obj.ConstantName == 'ProductSubCategory') {
    //                 //         option.OptionValues.push({
    //                 //             "DeviceOptionType": "ProductSubCategoryID",
    //                 //             "DeviceOptionValue": consumerServiceRequest.consumerProduct.ProductSubCategoryID
    //                 //         });
    //                 //     }
    //                 //     if(obj.ConstantName == 'BrandName') {
    //                 //         option.OptionValues.push({
    //                 //             "DeviceOptionType": "BrandID",
    //                 //             "DeviceOptionValue": $(id).data("brandid")
    //                 //         });
    //                 //     }
    //                 //     if(obj.ConstantName == 'ProductName') {
    //                 //         option.OptionValues.push({
    //                 //             "DeviceOptionType": "ProductID",
    //                 //             "DeviceOptionValue": $(id).data("productid")
    //                 //         });
    //                 //     }
    //                 // }
    //             } else if(obj.ConstantName == 'Attributes' && obj.AttributesList && obj.AttributesList.length > 0) {
    //                 // obj.AttributesList.forEach((attrObj, attrIndex) => {
    //                 //     var attrId = '#' + obj.ConstantName + attrIndex + '-option-' + option.index;
    //                 //     if($(attrId) && $(attrId).val()) {
    //                 //         option.OptionValues.push({
    //                 //             "DeviceOptionType": attrObj.ProductSubCategoryAttributeName,
    //                 //             "DeviceOptionValue": $(attrId).val(),
    //                 //             "IsSubCategoryAttribute": true
    //                 //         })
    //                 //     }
    //                 // })
    //             }
    //         });
    //     })
    //     let data = {
    //         "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
    //         "Status": nextStatus,
    //         "DeviceOptions": validArray,
	// 		'CountryID': consumerServiceRequest && consumerServiceRequest.CountryID 
    //     };
    //     replacementFulfillment(data);
    // };

    const processOptionsArray = () => {
        let localOptionsArray = [];
        let NumberOfOptionsAllowed = 3;


        for(let i = 0; i < NumberOfOptionsAllowed; i++) {
            localOptionsArray.push({
                Option: "OPTION_" + (i + 1),
                OptionValues: []
            })
        }
        return setOptionsArray(localOptionsArray);
    };

    const getDeviceConfig = (isReplacementOptions) =>{
        let data = {
            "ConsumerProductID": consumerServiceRequest.ConsumerProductID,
            "Action": isReplacementOptions ? "ReplacementOptions" : "ReplacementAllocation"
        };
        setLoader(true);
        apiCall({
            url: "getNewDeviceConfig", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    setDeviceConfigInput(result.data.InputFields)
                    let deviceConfigInputs = result.data.InputFields;
                    setDeviceConfigInput(deviceConfigInputs);
                    deviceConfigInputs.map(item=>{
                        if(item.ConstantName == 'ReplacementPrice' && !item.Value){
                            item.Value = consumerServiceRequest.SECURITY_DEPOSIT_AMOUNT;
                        }
                    })
                    if(isReplacementOptions) {
                        setReplacementFlowFlags({addOptionsMode:true});
                        processOptionsArray();
                        openReplacementOptions();
                    } else {
                        setShowModal({...showModal,showReplacementDetailsModal:true});
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

    const showDeviceInspectModal = () =>{
        // showAddBtn = true;
        // showDeviceReceived = false;
        setClaimDocArray([]);
        getDispositions();
    };

    const getDispositions = function(){
        setLoader(true);
        apiCall({
            url: "fetchDisposition", 
            data: {"dispositionGroup": refurbishmentFlowCheck ? 'Device delivered' : "Defective Received"},
            callBack: (result) => {
                setLoader(false);
                if (result.success && result.data) {
                    setShowModal({...showModal,showInspectDeviceRfModal:true});
                    setDispositionList(result.data.dispositionList);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

    const preBerRequestNext = (status, data) =>{
        Swal.fire({
            title: 'Are you sure?',
            html: '',
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false
        }).then(function(result) {
            if (result.isConfirmed) {
                Swal.close();
                let Status = status ?status :nextStatus
                berRequestNext(Status, data);
            }
        });
    };

    const getProductList =  (brandId,elementID) =>{
        // if(replacementFlowChecks){
        //     if(!currentBrandID || (currentBrandID && currentBrandID != brandId)) {
        //         let data = {
        //             ProductSubCategoryID: consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID,
        //             BrandID: parseInt(brandId)
        //         }
        //         apiCall({
        //             url: "getProductList", 
        //             data: data,
        //             callBack: (result) => {
        //                 setLoader(false);
        //                 if (result.success) {
        //                     setProductList(result.data);
        //                     setCurrentBrandID(brandId)
        //                     initiateProductTypeahead(result.data);
        //                 } else {
        //                     Swal.fire({
        //                         icon: "error",
        //                         text: localString(displayError(result)),
        //                     });
        //                 }
        //             },
        //         });
        //     } else {
        //         initiateProductTypeahead(elementID);
        //     }
        // }else{
            let data = {
                ProductSubCategoryID: consumerServiceRequest.consumerProduct.ProductSubCategoryID,
                BrandID: brandId
            }
            apiCall({
                url: "getProductList", 
                data: data,
                callBack: (result) => {
                    setLoader(false);
                    if (result.success) {
                        setProductList(result.data);
                        initiateProductTypeahead(result.data);
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: localString(displayError(result)),
                        });
                    }
                },
            });
        // }
    };

    const initiateProductTypeahead = (product)=>{
        let productList = product && product.map(v=>({...v,value:v.ProductName}))
        setSelectProductList(productList);
    }
    const getBrandList = () =>{
        if(replacementFlowChecks){
            setReplacementFlowFlags({...replacementFlowFlags,brandListFetched:true})
        }
        let requestObject = {
            Source: consumerServiceRequest.Source,
            ProductSubCategoryID: consumerServiceRequest &&  consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID,
        }
        apiCall({
            url: "GetBrands", 
            data: requestObject,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    setBrandList(result.data)
                    initiateBrandTypeahead(result.data)
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    };

    const initiateBrandTypeahead = (brand)=>{
        let brandList = brand && brand.map(v=>({...v,value:v.BrandName}))
        setSelectBrandList(brandList);
    }
    const initiateBerProductModal = () =>{
        let brandId = consumerServiceRequest && consumerServiceRequest.consumerProduct.brand.BrandID
        setProductData({...productData,
            ['productName']:consumerServiceRequest.consumerProduct.product.ProductName,
            ['brandName']:consumerServiceRequest.consumerProduct.brand.BrandName
        })
        if(!productAndBrandListFetched) {
            setProductAndBrandListFetched(true);
            getProductList(brandId);
            !disableBrandEdit && getBrandList();
        } else {
            // initiateProductTypeahead();
            // !disableBrandEdit && initiateBrandTypeahead();
        }
        // checkProductDetails();
    };

    const saveProductDetails = () =>{
        let imeiNumber = productData.imeiNumber && productData.imeiNumber;
        // setCurrentProductDetail({...currentProductDetail,ProductUniqueID:imeiNumber})
        let currentProductDetails = {
            BrandName: productData.brandName ? productData.brandName : consumerServiceRequest && consumerServiceRequest.consumerProduct.brand.BrandName,
            BrandID:productData.brandId ? productData.brandId: consumerServiceRequest && consumerServiceRequest.consumerProduct.brand.BrandID,
            ProductID:productData.productId ? productData.productId : consumerServiceRequest && consumerServiceRequest.consumerProduct.ProductID,
            ProductName:productData.productName ? productData.productName : consumerServiceRequest && consumerServiceRequest.consumerProduct.product.ProductName,
            ProductSubCategoryID: consumerServiceRequest && consumerServiceRequest.consumerProduct.ProductSubCategoryID,
        };
        if(imeiNumber){
            currentProductDetails.ProductUniqueID = imeiNumber;
        }
        if(productData && productData.newImeiNumber){
            currentProductDetails.NewAlternateProductUniqueID = productData.newImeiNumber;
        }
        if(productData && productData.simSku){
            currentProductDetails.NewSIMSKU = productData.simSku;
        }
        if(productData && productData.simNumber){
            currentProductDetails.NewSIMUniqueID = productData.simNumber;
        }
        // setCurrentProductDetail(currentProductDetails)
        let data = {
            BERreplacementDeviceDetails: currentProductDetails
        }
        preBerRequestNext('BER replacement issued', data);
        closeModal()
        setProductData({
            imeiNumber:'',
            newImeiNumber:'',
            simNumber:'',
            simSku:''
        })
    };
    const closeBerProductModal = ()=>{
        setProductData({
            productName: consumerServiceRequest && consumerServiceRequest.consumerProduct.product.ProductName,
            brandName:consumerServiceRequest && consumerServiceRequest.consumerProduct.brand.BrandName,
            imeiNumber:''
        })
        setShowModal({showBERProductModal:false});
    }

    const showBERConfirmation = (status) =>{
        let deviceDepreciatedValue = consumerServiceRequest && consumerServiceRequest.consumerProduct.DepreciatedValue.sumInsured;
        let sumInsured = consumerServiceRequest && consumerServiceRequest.consumerProduct.DepreciatedValue.sumInsured;
        let mandatoryDeductible = consumerServiceRequest && consumerServiceRequest.consumerProduct.DepreciatedValue.mandatoryDeductible ? consumerServiceRequest.consumerProduct.DepreciatedValue.mandatoryDeductible : 0;
        let textArea;
        let title;
        let errorTitle;
		switch (status) {
			case 'BER rejected':
				textArea = '<table style="width:90%;"><tr><td class="flag-ber-left">'+localString("Covered Value")+':</td><td class="flag-ber-right">'+currencySymbol +' '+ deviceDepreciatedValue +'</td></tr><tr><td class="flag-ber-left">'+localString("Mandatory Deductible")+':</td><td class="flag-ber-right">'+currencySymbol +' '+ mandatoryDeductible +'</td></tr><tr><td class="flag-ber-left">'+localString("Claim Amount")+':</td><td class="flag-ber-right">'+currencySymbol+'<input type="number" min="0" id="beramount" class="ber-amount"/></td></tr></table>';
				title = localString("Please enter Claim Amount")+':';
				errorTitle = localString("Please enter Claim Amount before submitting");
				break;
			case 'BER approved':
				textArea = '<table style="width:90%;"><tr><td class="flag-ber-left">'+localString("Device Purchase Cost")+':</td><td class="flag-ber-right">'+currencySymbol+' '+ consumerServiceRequest.consumerProduct.DepreciatedValue.devicePurchaseCost +'</td></tr><tr><td class="flag-ber-left">'+localString("Repair Cost")+':</td><td class="flag-ber-right">'+currencySymbol +' '+ consumerServiceRequest.berPaymentDetails.repairCost +'</td></tr><tr><td class="flag-ber-left">'+localString("Covered Value")+':</td><td class="flag-ber-right"> '+currencySymbol+' '+ consumerServiceRequest.berPaymentDetails.coveredValue +'</td></tr><tr><td class="flag-ber-left">'+localString("Mandatory Deductible")+':</td><td class="flag-ber-right"> '+currencySymbol+' '+ consumerServiceRequest.berPaymentDetails.mandatoryDeductible +'</td></tr><tr><td class="flag-ber-left">'+localString("BER Payment Amount")+':</td><td class="flag-ber-right"><span class="flag-ber-right">'+currencySymbol+ ' '+ consumerServiceRequest.berPaymentDetails.berPaymentAmount +'</span></td></tr></table>';
				title = localString("Approve BER");
				errorTitle = localString("Please enter Override Amount before submitting");
		}

        Swal.fire({
            title: title,
            html: textArea,
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false
        }).then(function(result) {
            if (result.isConfirmed) {
                // let amount = document.getElementById('beramount').value ;
                // let amountEntered = parseInt(amount);
                // if(amountEntered > deviceDepreciatedValue && status == 'BER rejected') {
                //     Swal.close();
                //     Swal.fire({
                //         title:   '',
                //         text:localString("Claim amount should be less than the Covered Value."),
                //         icon:'error'
                //     });
                // } else {
                    // if (status == 'BER approved' || status == 'BER rejected') {
                    if (status == 'BER approved') {
                        Swal.close();
                        let ber_amount = consumerServiceRequest.berPaymentDetails.berPaymentAmount;
                        let params = {
                            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
                            "Status": status,
                            "BERamount": ber_amount,
                            "CountryID":consumerServiceRequest.CountryID
                        };
                        berRequestNext(status,params)
                    } else {
                        Swal.close();
                        Swal.fire({
                            title :"",
                            text :localString(errorTitle),
                            icon :'error'
                        });
                    }
                // }
            }
        });
    };

    const BERcontinueAfterSelectingReason = (status) => {
        let data = {
            Remarks :cancelReasonsData && cancelReasonsData.OtherReason || (filterCancelrequest == 'Other') ? cancelText : filterCancelrequest ? filterCancelrequest : undefined,
        };
        berRequestNext(status, data);
        setShowModal({...showModal,showCancelRequestModal:false});
    }

    const createBerShipment = (address)=> {
        let data = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            Status:nextStatus,
            deliveryAddress: address,
            CountryID: consumerServiceRequest.CountryID
        };
        if(replacementFlowChecks){//replacement flow
            replacementFulfillment(data);
        }

        if(berChecks){//ber flow
            preBerRequestNext('BER shipment created', data);
        }
    };

    //
    //enquiry
    const getIssueResolvedReason = ()=>{
        let data = {};
        setLoader(true);
        apiCall({
            url: "getIssueResolutionCategories", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    if(result.data){
                        let resolvedResonCat = result.data && result.data.length>0 && result.data.map(v=>({...v,value:v.Reason}))
                        setSelectResolvedResonCat(resolvedResonCat);
                    }
                } else {
                    Swal.fire({
                        title: 'Oops...',
                        text: localString(displayError(result)),
                        icon: 'error',
                    });
                }
            },
        });
    }
    const preEnquiryRequestNext = (status) =>{
        Swal.fire({
            title: localString('Are you sure')+'?',
            html: '',
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false
        }).then(function(result) {
            if (result.isConfirmed) {
                Swal.close();
                enquiryRequestNext(status);
            }
        });
    };


    const requestEnquiry = (remark)=>{
        let newParams = {
            "ConsumerServiceRequestID":consumerServiceRequest.ConsumerServiceRequestID,
            "Remarks": issueResolvedText ? issueResolvedText : remark,
            "Status": nextStatus,
            "ServiceTypeID": consumerServiceRequest.ServiceTypeID,
            "Reason":issueReason
        };
        setLoader(true);
        apiCall({
            url: "enquiryRequest", 
            data: newParams,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    if(result.data){
                        getRequestById();
                    }
                } else {
                    Swal.fire({
                        title: 'Oops...',
                        text: localString(displayError(result)),
                        icon: 'error',
                    });
                }
            },
        });
    }

    //gsx
    const processRequestNextPickUp = (status) =>{
        let url = 'servicerequestFulfilmentWorkshop';
		let swaltitle = localString("Are you sure")+'?\n'+localString("Please specify remarks, if any")+'...';
		if (['Service cancel', 'Estimation rejected','Repair cancel','QC rejected'].indexOf(status) > -1) {
			swaltitle = localString("Are you sure")+'?\n'+localString("Please specify the reason")+'.';
		}
		Swal.fire({
			title: swaltitle,
			html: '<textarea id="request-remark" class="cancel-remark"></textarea>',
			showCancelButton: true,
			closeOnConfirm: false,
			allowEscapeKey: false,
			allowOutsideClick: false,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("OK")
		}).then(function(result) {
			if (result.isConfirmed) {
                setLoader(false);
				let remark = document.getElementById('request-remark').value ? document.getElementById('request-remark').value : '';
				if (['Service cancel', 'Estimation rejected','Repair cancel','QC rejected'].indexOf(status) > -1 && remark == '') {
					Swal.fire({
						title: '',
						text: localString("Cannot proceed with this action without providing a reason")+'.',
						icon: 'error',
						confirmButtonText: localString('OK')
					});
                    if(!consumerServiceRequest.consumerProduct.isAppleBrandID){
                        if (status == 'QC rejected') {
                            // $("#qc_checklist_modal").modal('show')
                        }
                    }
					return;
				}
                if(!consumerServiceRequest.consumerProduct.isAppleBrandID){
                    if(exception && status == 'Service completed') {
                        addReplacementProduct();
                    }
                }
                
				Swal.close();
				// let status = nextStatus;
				if (accessFn('GSX_FLOW') && status == 'QC completed') {
					url = 'markReadyForPickup'
				}
				let data = {
					ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
					Status: status,
					Remarks: remark,
				};
                if(status == 'Intimate customer' && appleDropOffFlow) {
                    data.Status = 'Device received and customer informed';
                }
                if(finishedGoodID){
                    data.FinishedGoodID = finishedGoodID
                }
                if(modeOfPayment){
                    data.ModeOfPayment = modeOfPayment
                }
                if(consumerServiceRequest.consumerProduct && !consumerServiceRequest.consumerProduct.isAppleBrandID){
                    if(modeOfPayment){
                        data.PaymentMode = modeOfPayment
                    }
                }else{
                    data.markReadyForPickup = (accessFn('GSX_FLOW') && status == 'QC completed') ? true : undefined
                }
                // setLoader(true);
                apiCall({
                    url: url, 
                    data: data,
                    callBack: (result) => {
                        setLoader(false);
                        if (result.success) {
                            getRequestById()
                        } else {
                            Swal.fire({
                                title: '',
                                text: localString(displayError(result)),
                                icon: 'error',
                                confirmButtonText: localString('OK')
                            });
                        }
                    },
                });
			}
		});
	};

    const addReplacementProduct = () =>{
        setLoader(true);
        apiCall({
            url: "addReplacementProduct", 
            data: {
                ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
            },
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                } else {
                    Swal.fire({
                        title: '',
                        text: localString(displayError(result)),
                        icon: 'error',
                    });
                }
            },
        });
    }
    //
    const enquiryRequestNext = (status) =>{
        let data = {};
        data.ConsumerServiceRequestID = consumerServiceRequest.ConsumerServiceRequestID;
        data.Status = status;
        data.ServiceTypeID = consumerServiceRequest.ServiceTypeID;
        setLoader(true);
        apiCall({
            url: "enquiryRequest", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    getRequestById()
                } else {
                    Swal.fire({
                        title: '',
                        text: localString(displayError(result)),
                        icon: 'error',
                        confirmButtonText: localString('OK')
                    });
                }
            },
        });
    };

    

    const showDiagnosticsResultModal = ()=> {
        setLoader(true);
		let serialNum = "";
		if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey){
			serialNum = consumerServiceRequest.consumerProduct.AlternateUniqueKey;
		}else{
			serialNum = consumerServiceRequest.consumerProduct.ProductUniqueID ? consumerServiceRequest.consumerProduct.ProductUniqueID : consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue;
		}
		let data = {
			serialNumber:serialNum,
			ConsumerServiceRequestID:consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
			Status: consumerServiceRequest.Status
		}
        apiCall({
            url: "fetchDiagnosticsData", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    if(result.data){
                        let diagnosticsRes = result.data;
                        setdiagnosticsResult(diagnosticsRes);
						if(diagnosticsRes && diagnosticsRes.diagnosticsEventNumber){
							setShowModal({...showModal,showDiagnosticsResultModal:true});
                        }
                    }
                } else {
                    Swal.fire({
                        title: '',
                        text: localString(displayError(result)),
                        icon: 'error',
                        confirmButtonText: localString('OK')
                    });
                }
            },
        });
	};

    const cancelShipment = ()=>{
        Swal.fire ( {
			title: '',
			text: localString("Are you sure?"),
			icon: 'question',
            iconColor:'#c9dae1',
			showCancelButton: true,
			cancelButtonText: localString('No'),
			showConfirmButton: true,
			confirmButtonText: localString('Yes')
		}).then (function (result) {
			if (result.isConfirmed) {
                setLoader(true);
				let data = {
					ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
					ServiceTypeID: consumerServiceRequest.ServiceTypeID
				};
                apiCall({
                    url: "cancelShipmentRequest", 
                    data: data,
                    callBack: (result) => {
                        setLoader(false);
                        if (result.success) {
                            getRequestById();
                        } else {
                            Swal.fire({
                                title: '',
                                text: result.msg ? localString(result.msg) : localString("Something went wrong"),
                                icon: 'error',
                                confirmButtonText: localString('OK')
                            });
                        }
                    },
                });
			}
		})
	}

    const initiateCancelRepair = (status) =>{
		let hasPendingParts = find(partsRequested, (part)=>{
			return part.statusDetails.Status === 'pending' || part.statusDetails.Status === 'requested';
		});
		let swaltitle = '';
		if(hasPendingParts){
			swaltitle = 'Are you sure you want to proceed?';
		} else {
			swaltitle = localString('All parts consumed will be reverted and the Engineer has to handover these good parts to the Store Manager. Are you sure you want to proceed?');
		}
		Swal.fire({
			//title: swaltitle,
			html:'<p style="font-size:19px;">'+swaltitle+'</p><br><input type = "checkbox" style = "margin-bottom:15px" id = "cancel_check" checked/><span style = "margin-left: 10px; font-size: 16px;">'+localString("This service is a Return without repair (RWR)")+'<br></span><input id="cancel-remark" class="cancel-remark"></input>',
			showCancelButton: true,
            closeOnConfirm: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
		}).then( (result)=>{
			if(result.isConfirmed){
				let remark = document.getElementById('cancel-remark').value ? document.getElementById('cancel-remark').value : '';
                if (document.getElementById('cancel_check').checked) {
                    remark = "Return without repair (RWR)" + (remark? '-' + remark : '')
                }
                if (['Service cancel','Repair cancel initiated'].indexOf(status) > -1 && remark == '') {
                    Swal.fire({
                        title : '',
                        text : localString("Cannot proceed with this action without providing a reason."),
                        icon : 'error'
                    })
                    return;
                }
                Swal.close();
				processRequestNextWithoutConfirmation(status);
			}
		})
	}

    const closeFMIPModal = ()=>{
        setShowFMIPModal(false)
    }
    const overrideFMIPCheck = ()=>{
        getPart();
        setShowFMIPModal(false)
        // setShowModal({...showModal,showFMIPModal:false});
		getGSXChecks(false, true);
        
	}

    const completeServiceAPiCall = (data, additionalData) => {
        setLoader(true);
        if(additionalData) {
			data = {...data, ...additionalData};
		}
        apiCall({
            url: "completeGsxService", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
					Swal.fire({
						title: '',
						text: localString(displayError(result)),
						icon: 'success',
						confirmButtonText: localString('OK')
					});
					getRequestById();
				} else {
					if(result.enableUserConsent) {
						Swal.fire({
                            title: '',
                            text: localString(displayError(result)),
                            showCancelButton: true,
                            closeOnConfirm: true,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            cancelButtonText: localString("Cancel"),
                            confirmButtonText: localString("Proceed without GSX update")
                        }).then(function (result) {
                            if(result.isConfirmed) {
                                completeServiceAPiCall(data, {isGSXBypass: true});
                            }
                        });
					} else {
						Swal.fire({
							title: '',
							text: localString(displayError(result)),
							icon: 'error',
							confirmButtonText: localString('OK')
						});
					}
				}
            },
        });
	};

    const updateCheckListAndStatus = (additionalData, status, dropOffFlow) =>{
		// setLoader(true);
        let swaltitle = localString("Are you sure")+'?\n'+localString("Please specify remarks, if any")+'...';
		Swal.fire({
			title: swaltitle,
			html: '<textarea id="request-remark" class="cancel-remark"></textarea>',
			showCancelButton: true,
			closeOnConfirm: false,
			allowEscapeKey: false,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("OK")
		}).then(function(result) {
			if (result.isConfirmed) {
				let remark = document.getElementById('request-remark').value ? document.getElementById('request-remark').value : '';
				Swal.close();
                setLoader(true);
				let data = {
					"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
					"gsxCheckList":omit(gsxChecklistData, ['osVersion']),
					// "osVersion": selectDeviceData.osversion,
					"ToEntity": checkListAllData.ToEntity,
					"sourceLocationID": checkListAllData.sourceLocationID,
					"destinationLocationID":checkListAllData.destinationLocationID,
					"ConsumerID":consumerServiceRequest.ConsumerID,
					//"ToID": self.checkListAllData.ToID,
					"FromEntity": checkListAllData.FromEntity,
					//"FromID": self.checkListAllData.FromID,
					"CheckType": checkListAllData.CheckType,
					"Remarks": cosmeticStatus
				}
                if(selectDeviceData && selectDeviceData.osversion){
                    if(pickupCarryInChecks && accessFn('GSX_FLOW')){
                        data.osVersion = selectDeviceData.osversionObj
                    }else{
                        data.osVersion =selectDeviceData.osversion;
                    }
                    
                }
                if(appleDropOffFlow) {
                    data.IsRequestCreatedAtDropOffPSL = true;
                }

			    let deviceReceivedAtRC = find(consumerServiceRequest.logs, {Status: 'Received at repair center'});
                if(accessFn('GSX_FLOW') && accessFn('TRANSFER_REQUEST') && deviceReceivedAtRC && !appleDropOffFlow && (checkListAllData.CheckType === 'inward')) {
                    data.IsRequestCreatedAtCP = true;
                }
                apiCall({
                    url: "addUpdateRemoveChecklistForServiceRequest", 
                    data: data,
                    callBack: (result) => {
                        setLoader(false);
                        if (result.success) {
							closeModal()
							// var status = self.nextStatus;
							if(['Initiate logistics request'].indexOf(status) > -1){
								createLogisticRequest();
                            }else if(dropOffFlow) {
                                addUpdateStatusAPI(status);
							}else{
								let url = '';
                                url = 'servicerequestFulfilmentWorkshop';
								if (accessFn('GSX_FLOW') && status == 'QC completed') {
									url = 'markReadyForPickup'
								}
								let data = {
									ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
									Status: status,
									Remarks: remark
								};
								if (['Service completed', 'Service completed and rated'].indexOf(status) > -1){
									url = 'completeGsxService';
									if(additionalData) {
										data = {...data, ...additionalData};
									}
								}else{
									data = {
										ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
										Status: status,
										FinishedGoodID: finishedGoodID,
										Remarks: remark
									};
								}
                                apiCall({
                                    url: url, 
                                    data: data,
                                    callBack: (result) => {
                                        setLoader(false);
                                        if (result.success) {
                                            getRequestById();
                                        } else {
                                            if(['Service completed', 'Service completed and rated'].indexOf(status) > -1 && result.enableUserConsent) {
                                                    Swal.fire({
                                                        title: '',
                                                        text: localString(displayError(result)),
                                                        showCancelButton: true,
                                                        closeOnConfirm: true,
                                                        allowEscapeKey: false,
                                                        allowOutsideClick: false,
                                                        cancelButtonText: localString("Cancel"),
                                                        confirmButtonText: localString("Proceed without GSX update")
                                                    }).then(function (result) {
                                                        if(result.isConfirmed) {
                                                            completeServiceAPiCall(data, {isGSXBypass: true});
                                                        }
                                                    });
											} else {
												Swal.fire({
													title: '',
													text: localString(displayError(result)),
													icon: 'error',
													confirmButtonText: localString('OK')
												});
											}
                                        }
                                    },
                                });
							}
						} else {
                            Swal.fire({
                                title: '',
                                text: result.msg ? localString(result.msg) : localString("Something went wrong"),
                                icon: 'error',
                                confirmButtonText: localString('OK')
                            });
                        }
                    },
                });
			}
		});
	}


    const sendInwardGSXChecks = function (status) {
        setLoader(true);
		let data = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"gsxCheckList": omit(gsxChecklistData, ['osVersion']),
			"ToEntity": checkListAllData.ToEntity,
			"sourceLocationID": checkListAllData.sourceLocationID,
			"destinationLocationID":checkListAllData.destinationLocationID,
			"ConsumerID":consumerServiceRequest.ConsumerID,
			//"ToID": self.checkListAllData.ToID,
			"FromEntity": checkListAllData.FromEntity,
			//"FromID": self.checkListAllData.FromID,
			"CheckType": checkListAllData.CheckType,
			"Remarks": cosmeticStatus
		}
        if(selectDeviceData && selectDeviceData.osversion){
            if(pickupCarryInChecks && accessFn('GSX_FLOW')){
                data.osVersion = selectDeviceData.osversionObj
            }else{
                data.osVersion =selectDeviceData.osversion;
            }
            
        }
        apiCall({
            url: "addUpdateRemoveChecklistForServiceRequest", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    getRequestById();
                } else {
                    Swal.fire({
                        title: '',
                        text: result.msg ? localString(result.msg) : localString("Something went wrong"),
                        icon: 'error',
                        confirmButtonText: localString('OK')
                    });
                }
            },
        });
	}

    const approveException = ()=> {
        setShowModal({...showModal,showEscalateRequestModal:true})
    }
    const initiateDiagnosis = (status) => {
        let diagnosisType, diagnosisData
        if (arrDiagnosisSuites && arrDiagnosisSuites.indexOf(status) > -1) {
            diagnosisType = 'suite'
            diagnosisData = find(diagnosisSuites, function (o) {
                if (o.suiteName == status) {
                    return o
                }
            })
        } else if (['Initiate AST 2 Diagnostics'].indexOf(status) > -1) {
            diagnosisType = 'ast2'
        }
        setLoader(true); 
        let reqObj = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            DiagnosisType: diagnosisType,
            DiagnosisData: diagnosisData,
            userRole: getCurrentRole(),
            currentUserID: qFilter.userID
        };
        if(showRequestLogs.displayDiagnosisStepsToEngFor2ndTime && appleDropOffFlow && accessFn('REPAIR_DEVICE')) {
            reqObj.IsRequestCreatedAtDropOffPSL = true;
        }
        apiCall({
            url: 'initiateGSXDiagnosis',
            data: reqObj,
            callBack: (result) => {
                setLoader(false);
                if(result && result.success) {
                    if(result.data && result.data.diagnosticConsoleURL) {
                        window.open(result.data.diagnosticConsoleURL, '_blank')
                    }
                    getRequestById && getRequestById();
                }else {
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(result)), 
                    });
                }
            },
            errorCB: (err) => {
                setLoader(false);
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(err)),  
                });
            }
        });
    }

    const initiateRunDiagnosis =  (status) => {
        let diagnosisType, diagnosisData

        if (arrDiagnosisSuites && arrDiagnosisSuites.indexOf(status) > -1) {
            diagnosisType = 'suite'
            diagnosisData = find(diagnosisSuites, function (o) {
                if (o.suiteName == status) {
                    return o
                }
            })
        } else if (['Initiate Diagnostics'].indexOf(status) > -1) {
            diagnosisType = 'ast2'
        } else {
            // pass
        }

        let data = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            DiagnosisType: diagnosisType,
            DiagnosisData: diagnosisData,
            userRole: getCurrentRole(),
            currentUserID: qFilter.userID
        }
        
        setLoader(true)
        apiCall({
                url: 'initiateGSXDiagnosis',
                data: data,
                callBack: (result) => {
                        setLoader(false)
                        if (result.success) {
                                getRequestById()
                                //self.getDiagnosisCompleteLabel()
                        } else {
                                 Swal.fire({
                                    title: '',
                                    text: localString(displayError(result)),
                                    icon: 'error',
                                    confirmButtonText: localString('OK')
                                });
                        }
                }
        });
    }

    const takeBackup = () => {
        let swaltitle = localString("Please specify remarks, if any")+'...';
        Swal.fire({
            input: 'textarea',
            inputLabel: swaltitle,
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
        }).then (function (result) {
            if (result.isConfirmed) {
                Swal.close();
                let remark = result.value;
                let data = {
                    ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                    Remarks: remark
                };

                setLoader(true)
                apiCall({
                    url: 'updateBackup',
                    data: data,
                    callBack: ( result)  => {
                        setLoader(false)
                        if (result.success) {
                            getRequestById(remark);
                        } else {
                            Swal.fire({
                                title: '',
                                text: localString(displayError(result)),
                                icon: 'error',
                                confirmButtonText: localString('OK')
                            });
    
                        }
                    }
                });
            }else{
                Swal.close()
            }
        })
    }

    const serviceCompleted = (e)=>{
        let data = {
            "EngineerID": getServiceLocationEngineerId(),
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "Action": 'Accept'
        };
        apiCall({
            url: 'acceptRejectRequestsEngg',
            data: data,
            callBack: (result) => {
                setLoader(false)
                if (result.success) {
                }else {
                    Swal.fire({
                    title: '',
                    text: localString(displayError(result)),
                    icon: 'error',
                    confirmButtonText: localString('OK')
                });
                }
            }
        });
    }
    const isQuestionDetailsValid = () => {
		let valid = true;
		const checkQuery = (questions) => {
			if (questions.length) {
				questions.forEach((obj) => {
					if (!obj.isSelect && !obj.optional) {
						valid = false;
					} else if (
						obj.isSelect &&
						obj.answers &&
						obj.answers.length
					) {
						if (valid) {
							obj.answers.forEach((ans) => {
								if (
									ans.isSelect &&
									ans.questions &&
									ans.questions.length
								) {
									checkQuery(ans.questions);
								}
							});
						}
					}
				});
			}
		};

		questionDetails.forEach((questionObj) => {
			questionObj.trees.forEach((treeObj) => {
				if (treeObj.questions && treeObj.questions.length) {
					if (valid) {
						checkQuery(treeObj.questions);
					}
				}
			});
		});
		return valid;
	};

    const openReapirNotesModal = () => {
		if(questionDetails && questionDetails.length && ((consumerServiceRequest && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId && consumerServiceRequest.gsxRepairFlags.repairTypeId == 3) || (selectedRepair && selectedRepair.repairTypeId == 3))){
			if(!isQuestionDetailsValid()){
                setAlreadyMarked(false)
				Swal.fire({
					title: 'Error',
					text: localString("Please enter Required Fields (*)."),
					icon: 'error',
					confirmButtonText: localString('OK')
				});
				return;
			}
		}
        setShowModal({...showModal, showRepairNotesModal: true})
	}

    const saveRepairNotes = () => {
		if (alreadyMarked) {
			return;
		}else {
			setAlreadyMarked(true);
			if((consumerServiceRequest?.gsxRepairFlags?.repairTypeId && consumerServiceRequest.gsxRepairFlags.repairTypeId == 3) || (selectedRepair && selectedRepair.repairTypeId == 3)){
				let remark = repairNotes ? repairNotes.trim() : '';
				if(!remark){
					Swal.fire({
						icon: "error",
						text: localString("Please enter the Repair Notes"),
						confirmButtonText: localString('OK')
					});
					setAlreadyMarked(false);
					return;
				}
				if(nextStatus && ['Repair completed','DOA completed'].indexOf(nextStatus) > -1) {
					repairCompletePickUpCarryInGsx();
				} else {
					raiseGSXRequest();
				}
			}else{
				repairCompletePickUpCarryInGsx();
			}
		}
	}

    const removeKeys = (questions) => {
		questions.forEach(obj => {
			// delete obj.questionPhrase;
			delete obj.answerIndex;
			delete obj.value;
			if(obj.answers.length){
				obj.answers.forEach(ans => {
					// if(!['FFB', 'INT', 'BBX'].includes(obj.answerType)) {
					// 	delete ans.answerPhrase;
					// }
					if(ans.questions && ans.questions.length) {
						ans.questions = removeKeys(ans.questions);
					}
					
				})
			}
		})
		return questions;
	}

    const constructQuestionData = () => {
        let questionsArr = JSON.parse(JSON.stringify(questionDetails));
		questionsArr.forEach(questionObj => {
			questionObj.trees.forEach(treeObj => {
				if(treeObj.questions && treeObj.questions.length) {
					treeObj.questions = removeKeys(treeObj.questions);
				}
			});
		});
		return questionsArr;
	}

    const closeRepairNotesModal = () => {
        setDisplayNotesModal(false);
        setRepairNotes('');
        setIsSameNotes(false);
    }

	const raiseGSXRequest = (enableRepairConsent=false) => {
		let data = {};
		let remark;
        // let enableRepairConsent;
		if (!selectedRepair) {
			Swal.fire({
				icon: "error",
				text: localString("Please select a repair type"),
				confirmButtonText: localString('OK')
			});
			setAlreadyMarked(false);
			return;
		}
		if(appleDropOffFlow || (selectedRepair.repairTypeId != 3)) {
            if(repairCheckList && repairCheckList.length) {
                for(let i = 0; i <= repairCheckList.length - 1; i++) {
                    if(repairCheckList[i].Type == 'checkBox' && repairCheckList[i].Value) {
                        data[repairCheckList[i].Name] = true;
                        if(repairCheckList[i].required){
                            if(repairCheckList[i].required.Value) {
                                data[repairCheckList[i].required.Name] = repairCheckList[i].required.Value;
                            }else {
                                Swal.fire({
                                    icon: "error",
                                    text: localString("Please enter valid") + " " + repairCheckList[i].required.Description + '.',
                                    confirmButtonText: localString('OK')
                                });
                                setAlreadyMarked(false);
                                return;
                            }
                        }
                    }else {
                        data[repairCheckList[i].Name] = false;
                    }
                    if(repairCheckList[i].Type == 'radioButton' && repairCheckList[i].options) {
                        repairCheckList[i].options.map(opt => {
                            if(opt.checked) {
                                data[repairCheckList[i].Name] = opt;
                            }
                        })
                    }
                }
            }
			// repairCheckList && repairCheckList.length && repairCheckList.map(item => {
			// 	if(item.Type == 'checkBox' && item.Value) {
			// 		data[item.Name] = true;
			// 		if(item.required){
			// 			if(item.required.Value) {
			// 				data[item.required.Name] = item.required.Value;
			// 			}else {
			// 				Swal.fire({
			// 					icon: "error",
			// 					text: localString("Please enter valid") + " " + item.required.Description + '.',
			// 					confirmButtonText: localString('OK')
			// 				});
			// 				setAlreadyMarked(false);
			// 				return;
			// 			}
			// 		}
			// 	}else {
			// 		data[item.Name] = false;
			// 	}
			// 	if(item.Type == 'radioButton' && item.options) {
			// 		item.options.map(opt => {
			// 			if(opt.checked) {
			// 				data[item.Name] = opt;
			// 			}
			// 		})
			// 	}
			// })
		}
        // else {
		// 	data['repairCompletionNotes'] = repairNotes ? repairNotes.trim() : undefined;
		// 	data['isCheckNotes'] = isSameNotes;
		// 	setAlreadyMarked(false);
		// 	closeRepairNotesModal();
		// }
        if(selectedRepair.repairTypeId == 3) {
            data['repairCompletionNotes'] = repairNotes ? repairNotes.trim() : undefined;
			data['isCheckNotes'] = isSameNotes;
			setAlreadyMarked(false);
			closeRepairNotesModal();
        }
		if(questionDetails && questionDetails.length && selectedRepair && (!selectedRepair.isPartsAllowed || partsRequested.length )){
			if(isQuestionDetailsValid()) {
				data['questionDetails'] = constructQuestionData();
			} else {
				Swal.fire({
					icon: "error",
					text: localString("Please enter Required Fields (*)."),
					confirmButtonText: localString('OK')
				});
				setAlreadyMarked(false);
				return;
			}
		}
		data['ConsumerServiceRequestID'] = consumerServiceRequest.ConsumerServiceRequestID;
		data['repairType'] = selectedRepair;
		data['repairType'].classificationCode = selectedClassification;
		data['GsxCoverageOption'] = selectedCoverage;
		if(selectedRepair.repairTypeId == 2){
			//data['requestCompTia'] = comptiaData;
			//data['trackingNumber'] = $('#trackingNumber').val().trim();
			//data['ShipperDetails'] = ShipperDetails;
			data['sPart'] = selectedSPart;
		}
		let receivedTime
		if(consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.length > 0) {
			receivedTime = findLast(consumerServiceRequest.logs, function (o) { return o.Status == 'Device received'}).CreatedDate;
		}
		data['UnitReceivedDateTime'] = receivedDateTime ? moment(receivedDateTime, 'YYYY-MM-DD HH:mm:ss').format() : moment(receivedTime).format('YYYY-MM-DD HH:mm:ss');
		data['isSerializedProduct'] = consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.isSerializedProduct;

        if(enableRepairConsent){
            data.overrideRepairEligibility = true;
        }else{
            data.overrideRepairEligibility = false;
        }
        let url = "createGsxRepairRequest";
        if(selectedRepair.nreType=="SRC"){
            data.DraftProvisionalRepair = true;
            url = "draftProvisionalRepairDetails";
        }

		setLoader(true);
		apiCall({
			url: url,
			data: data,
			callBack: (result) => {
				setLoader(false);
				if(result.success) {
                    getRequestById();
					Swal.fire({
						icon: "success",
						title: selectedRepair.nreType == "SRC" ? localString(displayError(result)) : localString('GSX Confirmation Number')+': '+result.data.repairId,
						text: selectedRepair.nreType == "SRC" ? '' : localString(displayError(result)),
						confirmButtonText: localString('OK')
					});
					closeModal();
				}else {
					setAlreadyMarked(false);
                    if(result.data && !result.data.enableRepairConsent){
                        enableRepairConsent = false;
                        Swal.fire({
                            title: '',
                            text: localString(displayError(result)),
                            type: 'error',
                            confirmButtonText: localString('OK'),
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                        });
                    }else if(result.data && result.data.enableRepairConsent){
                        Swal.fire ({
                            title: '',
                            // text: localString(displayError(result)),
                            html: `<div style="white-space: pre-line;">${localString(displayError(result))}<div/>`,
                            type: 'error',
                            showCancelButton: true,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            cancelButtonText: localString("No"),
                            confirmButtonText: localString("Yes")
                        }).then (function (res) {
                            if (res.isConfirmed) {
                                Swal.close();
                                enableRepairConsent = true;
                                raiseGSXRequest(enableRepairConsent);
                            }
                        });
                    }else{
                        Swal.fire ({
                            title: '',
                            text: localString(displayError(result)),
                            type: 'error',
                            confirmButtonText: localString('OK'),
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                        });
                    }
				}
			},
		});
	}

    const replacementFlowNext = (status)=>{
        Swal.fire({
            title: localString("Are you sure?"),
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
        }).then(function (result) {
            if (result.isConfirmed) {
                replacementFulfillment(null,status);
                Swal.close();
            }
        });
    }

    const getChargeCreditCard = ()=>{
        let Status;
        Swal.fire({
            title: localString("Are you sure to Charge the customer’s credit card"),
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK")
        }).then(function (result) {
            if (result.isConfirmed) {
                Status = "Charge card-hold";
                replacementFulfillment(null ,Status);
                Swal.close();
            }
        });
    }

    const shipPackageMaterial = (e) =>{
		if ([SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
			preProcessRequest(e);
		}
		else if ([SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
			claimRequestNext('Packaging material shipped');
		}
	}

    const updateEstimation = (action, actionID)=> {
        Swal.fire({
            title: 'Please specify the reason...',
            html: '<textarea id="cancel-remark" class="cancel-remark"></textarea>',
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: localString('OK'),
            cancelButtonText: localString("Cancel")
        }).then(function(result) {
            if (result.isConfirmed) {
                let remark = document.getElementById('cancel-remark').value
                if (remark != '') {
                    Swal.close();
                    let data = {
                        ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                        IsApproved: actionID
                    };
                    setLoader(true);
                    apiCall({
                        url: "updateEstimation", 
                        data: data,
                        callBack: (result) => {
                            setLoader(false);
                            if (result.success) {
                                getRequestById();
                                // $('#reason').val('');//no idea
                            } else {
                                Swal.fire({
                                    title: '',
                                    text: localString(displayError(result)),
                                    type: 'error',
                                    confirmButtonText: localString('OK')
                                });
                            }
                        },
                    });
                } else {
                    Swal.close();
                    Swal.fire({
                        title: 'Oops...',
                        text:localString('Please fill in the reason for overriding'),
                        type: 'error',
                        confirmButtonText: localString('OK')
                    }).then(function(result) {
                        if (result.isConfirmed) {
                            updateEstimation(undefined, actionID);
                        }
                    });
                }
            }
        });
    }

    const overideEstimationReject = () => {
        updateEstimation("REJECT", 0)
    }

    const overideEstimationApproval = () => {
        updateEstimation("ACCEPT", 1)
    }

    const assignToSelf = (obj) => {
        let data = obj;
        if(!data){
            let newPSL = qFilter?.serviceLocations && qFilter?.serviceLocations.length && qFilter.serviceLocations[0]; //hack until product takes a descision to make it selectable or something. This one is made for Xiaomi.
            data = {
                "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
                "PartnerID": consumerServiceRequest.PartnerID,
                "TransferredServiceLocationID": newPSL,
                "transferRequest" : true // (always true)
            };
        }
        setLoader(true);
        apiCall({
            url: 'transferServiceLocation',
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    if(shipToDropOFFLoc) {
                        setShipToDropOFFLoc(false);
                        getRequestById();
                        closeModal();
                    }else {
                        Swal.fire({  
                            title: localString("Assigned successfully"),
                            showCancelButton: false,
                            allowEscapeKey: false,
                            confirmButtonText: localString('OK'),
                            icon: 'success',
                        }).then(res => {
                            if(res.isConfirmed) {
                                getRequestById();
                            }
                        })
                    }
                }else {
                    Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
                }
            }
        });
    }

    const completeRepair = (status) => {
        if(consumerServiceRequest.autoClaimFulfillmentFlow){
			openUpload(status);
		} else {
			claimRequestNonWorkshop(status);
		}
    }

    const validSerialNoForFGPart = () => {
        let valid = true;
        consumerServiceRequest?.charges?.length && consumerServiceRequest.charges.map((charge) => {
            if(valid && (charge?.partEstimate?.PartCategoryName === 'FG') && !charge?.partEstimate?.SerialNumber) {
                valid = false;
            }
        })
        if(!valid) {
            setActiveTab({ showFinishedGoodsDetails: true });
            Swal.fire({
                text: localString("Please provide the serial number of the Finished Good in the Finished Good Details tab to complete repair."),
                icon:'error'
            })
        }
        return valid;
    }

    const openReassignServiceCenter = () =>{
        setShowModal({...showModal,showReassignServiceCenterModal:true});
    }
    const openServiceApproachModal = () =>{
        setShowModal({...showModal,showSelectServiceApproachModal:true});
    }

    const handleButtonClick = (e, status) => {
        if(status) {
            setNextStatus(status);
            if(checkChecklistData(status)){
                Swal.fire({
                    title:'',
                    text:localString("Please make sure you have performed all the checks as per 'Checklist'"),
                    icon:'error'
                })
                return;
            }
            if(pickupCarryInGSXChecks && consumerServiceRequest.consumerProduct.isAppleBrandID && ((arrDiagnosisSuites && arrDiagnosisSuites.indexOf(status) > -1) || ['Initiate AST 2 Diagnostics'].indexOf(status) > -1)) {
                initiateDiagnosis(status);
            }else {
                switch(status){
                    case "Log Call":
                        if(onsiteChecks){
                            showDocumentPendingConfirmModal(status)
                        }else{
                            attemptCall();
                        }
                        break;
                    case "Update Logistics Request"	: //to do
                        openUploadLogisticsModal();
                        break;
                    case "Cancel Service Without Dispatch":
                        if(claimNewWorkshopChecks){
                            showConfirmation(status);
                        }else if(claimChecks){
                            showConfirmation(status);
                        }else if(pickupCarryInChecks && consumerServiceRequest.consumerProduct && !consumerServiceRequest.consumerProduct.isAppleBrandID){
                            cancelRequestSerivce(status);
                        }else {
                            preShowCancelAnytimeRequestConfirm();
                        }
                        break;  
                    case "Cancel Request":
                        if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM  && !checkPaymentStatus() && checkDecisionMatrixProcessCompleted()){
                            preShowCancelAnytimeRequestConfirm();
                        }else{
                            preShowCancelRequestConfirm();
                        }
                        break;
                    case "Cancel Repair":
                        preShowCancelRequestConfirmForPickUpCarryIn(status);
                        break;
                    case "Cancel Service" :   
                        preShowCancelRequestConfirmForPickUpCarryIn(status);
                        break;
                    case "Initiate logistics request":
                        if(pickupCarryInGSXChecks && accessFn('GSX_FLOW')){
                            serviceCompleteModal(status);
                        }else{
                            createLogisticRequest();
                        }
                        
                        break;
                    case "Device shipped":
                        preProcessRequest(status);
                        break;
                    // case "Mark Customer Arrival":
                    //     preProcessRequest(status);
                    //     break;
                    case "Start Repair":
                        preProcessRequest(status);
                        break;
                    case "Device Received":


                        if(accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE') && appleDropOffFlow && consumerServiceRequest.gsxRepairFlags.repairTypeId == 1){
                            updateAdjustmentparts(status);
                        }else if(accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE') && appleDropOffFlow) {
                            let data = {
                                ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                                Status: 'Device received at drop-off location'
                            }
                            preProcessRequest(status, data);
                        }else {
                            preProcessRequest(status);
                        }
                        break;
                    case "Dispatch Device":
                        preProcessRequest(status);
                        break;
                    case "Complete Service":                  
                        preProcessRequest(status);
                        break;
                    case "Service completed":
                        if(claimNewWorkshopChecks){
                            claimRequestNextWs(status);
                        }else if(claimChecks){
                            claimRequestNonWorkshop(status);
                        }else if(replacementFlowChecks){
                            showConfirmation(status);
                        }else if(pickupCarryInChecks && !accessFn('GSX_FLOW')){
                            processRequestNextPickUp(status); //gsx and non
                        }else if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.DEMO, SERVICETYPE.SERVICING,56,61,62,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){ //onsite
                            if(['VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1){
                                updateCSRStatus(status);
                            } else if (consumerServiceRequest.showEngineerVisits) {
                                receivePaymentCompleteService(status)
                            } else if (consumerServiceRequest && [3,5].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_TREAD') > -1) {
                                receivePaymentCompleteService(status)
                            } else if ([64].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
                                preClaimRequest(status);
                            } else {
                                serviceCompleted();
                            }
                        }else if(pickupCarryInGSXChecks && accessFn('GSX_FLOW')){
                            serviceCompleteModal(status)
                        }else if(serviceRequestClusterCheck){
                            processRequestNextServiceCluster(null,status)
                        }else if(berChecks){
                            if(berValid.paymentModeCheque ){
                                openChequeModal(status,true);
                            }else{
                                preBerRequestNext(status);
                            }
                        }
                        else if(refundProcessCheck){
                            refundProcessNext(status,false)
                        }else if(refurbishmentFlowCheck){
                            showRefurbConfirmation(status);
                        }else if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BER_REPLACEMENT,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
                            showConfirmation(status);
                        }
                        break;
                    case "In-principle approved":
                        if(claimNewWorkshopChecks){
                            claimRequestNextWs(status);
                        }else if(claimChecks){
                            if([38].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
                                preShowClaimApproveEstimationConfirm();
                            }else{
                                claimRequestNonWorkshop(status);
                            }
                            // claimRequestInPrinciple(status);
                        }
                        break;
                    case "QC pending":
                        if(claimNewWorkshopChecks){
                            claimRequestNextWs(status);
                        }
                        break;
                    case "Cannot be diagnosed":
                        if(claimNewWorkshopChecks){
                            claimRequestNextWs(status);
                        }else if(pickupCarryInChecks){
                            if(consumerServiceRequest.consumerProduct.isAppleBrandID){
                                //GSX Flow
                                getDiagnosisCompleteLabel(status)
                            }else{
                                // NON - GSX //function Call =>  processsNext Pickupcarryin()
                            }
                        }
                        break;
                    case "Device dispatched":
                        if(claimNewWorkshopChecks){
                            claimRequestNextWs(status);
                        }else if(claimChecks){
                            claimRequestNonWorkshop(status);
                        }else if(berChecks){//ber
                            preBerRequestNext(status);
                        }else if([46,63,65,66].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
                            replacementFlowNext(status);
                        }else if(pickupCarryInGSXChecks && accessFn('GSX_FLOW') ){
                            processRequestNextPickUp(status)
                        }
                        break;
                    case "Device received" :
                        if(claimNewWorkshopChecks){
                            claimRequestNextWs(status);
                        }else if(claimChecks){
                            claimRequestNonWorkshop(status);
                        }else if(refurbishmentFlowCheck){
                            showRefurbConfirmation(status)
                        }
                        else{
                            preProcessRequest(status);
                        }
                        break; 
                    case "Diagnosis completed":    
                        if(claimNewWorkshopChecks){
                            claimRequestNextWs(status);
                        }else if(pickupCarryInChecks){
                            if(consumerServiceRequest.consumerProduct.isAppleBrandID){
                                // GSX Flow
                                getDiagnosisCompleteLabel()
                            }else{
                                // NON - GSX // function Call =>  processsNext Pickupcarryin()
                            }
                        }
                        break; 
                    case "Intimate customer" :
                        if(claimNewWorkshopChecks){
                            claimRequestNextWs(status);
                        }else if(pickupCarryInChecks){
                            if(!consumerServiceRequest.consumerProduct.isAppleBrandID){
                                processRequestNextPickUp(status)
                            }else{
                                processRequestNextPickUp(status)
                            }
                        }
                        break;     
                    case "Parts received":
                        if(onsiteChecks){
                            if(showButton){
                                preClaimRequest(status);
                            }
                        }else if(claimChecks){
                            claimRequestNonWorkshop(status);
                        }else if(refurbishmentFlowCheck){
                            showRefurbConfirmation(status)
                        }
                        else{
                            preProcessRequest(status);
                        }
                        break;
                    case "Parts Pending":
                        preProcessRequest(status);
                        if(onsiteChecks){
                            if(showButton){
                                preClaimRequest(status);
                            }
                        }
                        break;
                    case "Resend Payment Link":
                        resendAdvancePaymentLink();
                        break;
                    case "Send payment link":
                        resendAdvancePaymentLink();
                        break;
                    case "Reschedule":
                        openRescheduleServiceModal(status);
                        break;
                    case "Set Schedule":
                        openScheduleServiceModal();
                        break;  
                    case "Reset":
                        resetTradeInRequest();
                        break;
                    case "Reassign":
                        // ReassignModal(); //to do
                        break;
                    case "Generate Invoice":
                        if(countriesChecks.TaxDetailName && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.IsWarrantyApplicable){
                            openBillingDetailModal(status);
                        }else{
                            preProcessRequest(status)
                        }
                        break;
                    case "Complete Repair":
                        if(!validSerialNoForFGPart(status)) return;
                        if(countriesChecks.TaxDetailName && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.IsWarrantyApplicable){
                            openBillingDetailModal(status);
                        }else{
                            preProcessRequest(status)
                        }
                        
                        // 
                        break;
                    case "Service reschedule":
                        if(onsiteChecks){
                            if(showButton){
                                rescheduleService();
                            }
                        }
                        break;
                    case "Device not repairable":
                        if(onsiteChecks){
                            if(showButton){
                                preClaimRequest(status);
                            }
                        }else if(claimChecks){
                            claimRequestNonWorkshop(status);
                        }
                        break;
                    case "Device not eligible for repair":
                        if(onsiteChecks){
                            if(showButton){
                                preClaimRequest(status);
                            }
                        }else if(claimChecks){
                            claimRequestNonWorkshop(status);
                        }
                        break;
                    case "Repair completed":
                        if(!validSerialNoForFGPart(status)) return;
                        if(onsiteChecks){
                            if(showButton){
                                preClaimRequest(status);
                            }
                        }else  if(claimNewWorkshopChecks){
                            showPartsModalPickUpCarryIn(); 
                        }else if(claimChecks){
                            completeRepair(status);
                            // claimRequestNonWorkshop(status);
                        }else if(pickupCarryInChecks && !accessFn('GSX_FLOW')){
                            showPartsModalPickUpCarryIn(); 
                        }else if(pickupCarryInGSXChecks && accessFn('GSX_FLOW')){
                            showPartsModalPickUpCarryInGsx(status); 
                        }else if(refurbishmentFlowCheck){
                            showRefurbConfirmation(status);
                        }
                        break;  
                    case "No fault found":
                        if(onsiteChecks){
                            if(showButton){
                                preClaimRequest(status);
                            }
                        }else if(claimChecks){
                            claimRequestNonWorkshop(status)
                        }
                        break;
                    case "Claim withdrawn":
                        if(onsiteChecks){//onsite
                            if(showButton){
                                if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
                                    getReasons(status);
                                }else{
                                    showCancelRequestConfirmModal()
                                    // preShowCancelRequestConfirm();
                                }
                            }
                        }else if(claimNewWorkshopChecks){ //claim new
                            openReasonsModal(status);
                        }else if(claimChecks){ //claim
                            openReasonsModal(status);
                        }else if(berChecks){//[SERVICETYPE.BER_PAYMENT].indexOf(consumerServiceRequest.ServiceTypeID)> -1 
                            openReasonsModal(status);
                        }else if(replacementFlowChecks){
                            setClaimFlags({...claimFlags,showClaimCancelRequestBtn:true})
                            getReasons(status);
                        }
                        break;
                    case "Service cancel":
                        if(onsiteChecks){
                            if(showButton){
                                if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
                                    // getReasons(e.target.name);
                                    getReasons(status);
                                }else{
                                    showCancelRequestConfirmModal()
                                    // preShowCancelRequestConfirm();
                                }
                            }
                        }else if(claimNewWorkshopChecks){
                            openReasonsModal(status);
                        }else if(pickupCarryInChecks && consumerServiceRequest.consumerProduct && !consumerServiceRequest.consumerProduct.isAppleBrandID){
                            cancelRequestSerivce(status);
                        }
                        else if(replacementFlowChecks){
                            setClaimFlags({...claimFlags,showClaimCancelRequestBtn:true})
                            getReasons(status);
                        }else if(berChecks ){
                            openReasonsModal(status);
                        }
                        else if([SERVICETYPE.ENQUIRY].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
                            showDocumentPendingConfirmModal(status);
                        }else if(pickupCarryInGSXChecks){
                            cancelRequestSerivce(status);
                        }else if(refurbishmentFlowCheck){
                            showRefurbConfirmation(status)
                        }
                        else{
                            if(claimChecks){
                                openReasonsModal(status);
                            }else{ //have doubt
                                showConfirmation();
                            }
                        }
                        break;
                    case "Job updated":
                        if(onsiteChecks){
                            if(showButton){
                                showJobUpdate()
                            }
                        }
                        break;   
                    case "Visit unproductive":
                        if(onsiteChecks){
                            if(showButton){
                                showUnresolvedVisitModal();
                            }
                        }
                        break;  
                    case "RET approved":
                        if(onsiteChecks){
                            if(showButton){
                                updateCSRStatus();
                            }
                        }
                        break;   
                    case "Service cancel initiated":
                        if(onsiteChecks){
                            if(showButton){
                                updateCSRStatus();
                            }
                        }
                        break;  
                    case "Visit productive":
                        if(onsiteChecks){
                            if(showButton){
                                showResolvedVisitModal();
                            }
                        }
                        break;  
                    case "Claim estimation approved":
                        if(onsiteChecks){
                            if(showButton){
                                preShowApproveEstimationConfirm();
                            }
                        }else if(claimChecks){
                            preShowClaimApproveEstimationConfirm();
                        }
                        break;   
                    case "Accept service":
                        if(onsiteChecks){
                            if(showButton){
                                acceptRejectService('Accept');
                            }
                        }
                        break; 
                    case "Reject service":
                        if(onsiteChecks){
                            if(showButton){
                                acceptRejectService('Reject');
                            }
                        }
                        break;  
                    case "Task Created":
                        if(onsiteChecks){
                            if(showButton){
                                setRatingModalTitle("Create Task")
                                createTaskService();
                            }
                        }
                        break;  
                    case "Payment received":
                        if(onsiteChecks){
                            if(showButton){
                                showPaymentModal();
                            }
                        }else if(claimNewWorkshopChecks){
                            if(countriesChecks && countriesChecks.TaxDetailName){
                                setShowBillingModal(true);
                                // setShowModal({...showModal,showBillingModal:true});
                            }else{
                                setType('claimWorkshop')
                                capturePaymentMode();
                            }
                        }else if(claimChecks){
                            if(countriesChecks && countriesChecks.TaxDetailName){
                                setShowBillingModal(true);
                            }else{
                                claimRequestNonWorkshop(status);
                            }
                        }else if(([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
                            setType('replacementFlow')
                            capturePaymentMode();
                        }else if(pickupCarryInGSXChecks){
                            getPaymentModes(status)
                        }
                        break;  
                    case "Serviceman reached":
                        if(onsiteChecks){
                            if(showButton){
                                servicemanReached();
                            }
                        }
                        break; 
                    case "Technician reached":
                        if(onsiteChecks){
                            if(showButton){
                                servicemanReached();
                            }
                        }
                        break; 
                    case "Service invoice generated":
                        if(onsiteChecks){
                            if(showButton){
                                generateInvoice();
                            }
                        }else if(pickupCarryInChecks){
                            if(!consumerServiceRequest.consumerProduct.isAppleBrandID){
                                openBillingDetailModal(status);
                            }else{
                                openBillingDetailModal(status);
                            }
                        }
                        break;  
                    case "Iris submitted":
                        if(onsiteChecks){
                            if(showButton){
                                showIrisModal();
                            }
                        }
                        break; 
                    case "Reject cancel":
                        if(onsiteChecks){
                            if(showButton){
                                rejectRequestEndTime();
                            }
                        }
                        break;  
                    case "Add Shipment Details":
                        if(claimChecks){
                            initiateShipmentModal();
                        }else if(([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){//replacementFlow
                            initiateShipmentModal();
                        }else if(berChecks){//ber
                            initiateShipmentModal();
                        }else if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){//vsr page
                            initiateShipmentModal();
                        }else if(refurbishmentFlowCheck){
                            initiateShipmentModal(status)
                        }
                        else {
                            claimRequestNext(status);
                        }
                        break; 
                    case "Accept":
                        // if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
                            acceptRejectJob(status);
                        // }
                        break;
                    case "Reject":
                        // if(claimChecks){
                            acceptRejectJob(status);
                        // }
                        break;  
                    case "Show device diagnosis":
                        if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
                            window.open(baseUrl + diagnosisUrl + consumerServiceRequest.ConsumerProductID, '_blank');//gsx
                        }else{
                            window.open(baseUrl + diagnosisUrl + encrypt(consumerServiceRequest.ConsumerProductID) + '&&pscid=' + encrypt(consumerServiceRequest.consumerProduct.ProductSubCategoryID), '_blank');
                        }
                    break; 
                    case "Device handed over":
                        showDeviceReturnedModal(status);
                    break;
                case "Reject":
                    // if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,38,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY')){
                        acceptRejectJob(status);
                    // }
                    break;  
                case "Show device diagnosis":
                    if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
                        window.open(baseUrl + diagnosisUrl + consumerServiceRequest.ConsumerProductID, '_blank');//gsx
                    }else{
                        window.open(baseUrl + diagnosisUrl + encrypt(consumerServiceRequest.ConsumerProductID) + '&&pscid=' + encrypt(consumerServiceRequest.consumerProduct.ProductSubCategoryID), '_blank');
                    }
                break; 
                case "Device handed over":
                    showDeviceReturnedModal(status);
                break;
                case "Device returned":
                    showDeviceReturnedModal();
                break;
                case "Diagnosis initiated":
                    if(([1,2,17].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW')){
                        if(!consumerServiceRequest.consumerProduct.isAppleBrandID){
                            //NON GSX Flow
                            performDiagnosis();
                        }
                    }else{
                        performDiagnosis();
                    }
                break; 
                case "Document pending":
                    showDocumentPendingConfirmModal(status); //for replacement flow also
                break; 
                case "Call attempted":
                    if(berChecks){//ber
                        attemptCall()
                    }else if([SERVICETYPE.ENQUIRY].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
                        showDocumentPendingConfirmModal(status);
                    }
                    else{
                        showDocumentPendingConfirmModal(status); //enquiry or pickup
                    }
                break;    
                    case "Device returned":
                        showDeviceReturnedModal();
                    break;
                    case "Diagnosis initiated":
                        performDiagnosis();
                    break; 
                    case "Document pending":
                        showDocumentPendingConfirmModal(status); //for replacement flow also
                    break; 
                    case "Call attempted":
                        if(berChecks){//ber
                            attemptCall()
                        }else if([SERVICETYPE.ENQUIRY].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
                            showDocumentPendingConfirmModal(status);
                        }else{
                            showDocumentPendingConfirmModal(status); //onsite or enquiry
                        }
                    break;    
                    case "Advance collected":
                        if(pickupCarryInChecks){
                            if(consumerServiceRequest.consumerProduct.isAppleBrandID){
                                // GSX
                                getPaymentModes(status)
                            }else{
                                // NON - GSX
                                captureAdvancePayment();
                            }
                        }else{
                            captureAdvancePayment();
                        }
                    break;
                    case "Device collected":
                        if(claimNewWorkshopChecks) { // Claim workshop
                            processRequestWithoutConfirmation(status);
                        }else if(pickupCarryInChecks && !consumerServiceRequest.consumerProduct.isAppleBrandID) { // pick up carry in
                            processRequestNextWithoutConfirmation(status);
                        }else if(pickupCarryInGSXChecks && consumerServiceRequest.consumerProduct.isAppleBrandID) { // pick up carry in gsx
                            processRequestNextWithoutConfirmation(status);
                        }
                        break;
                    case "Device accepted":
                        if(claimNewWorkshopChecks) { // Claim workshop
                            processRequestWithoutConfirmation(status);
                        }else if(pickupCarryInChecks && !consumerServiceRequest.consumerProduct.isAppleBrandID) { // pick up carry in
                            processRequestNextWithoutConfirmation(status);
                        }else if(pickupCarryInGSXChecks && consumerServiceRequest.consumerProduct.isAppleBrandID) { // pick up carry in gsx
                            processRequestNextWithoutConfirmation(status);
                        }
                        break;
                    case "Parts request accepted":
                        updatePartsStatus(status);
                        break;
                    case "Parts request rejected":
                        updatePartsStatus(status);
                        break;
                    case "Repair cancel":
                        if(claimChecks){
                            if( ['No fault found' ,'Device not eligible for repair'].indexOf(consumerServiceRequest.Status) > -1){
                                openReasonsModal(status);
                            }else{
                                showConfirmation(status);
                            }
                            
                        }else if(pickupCarryInChecks){
                            /** Because of same APIs Handled PickupCarryin GSX and Non-GSX both in One function */
                            cancelRequestSerivce(status);
                        }else{
                            cancelRepair(status);
                        }
                    break;
                    case "Parts pending":
                        if(onsiteChecks){
                            if(showButton){
                                preClaimRequest(status);
                            }
                        }else if(refurbishmentFlowCheck){
                            showRefurbConfirmation(status)
                        }
                        else{
                            showConfirmation(status);
                        }
                    break;
                    case "Claim invalid":
                        showConfirmation(status);
                    break;
                    case "Device inspected":
                        if(([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
                            showDeviceInspectModal();
                        }else if(pickupCarryInChecks){
                            if(consumerServiceRequest.consumerProduct.isAppleBrandID){
                                if(accessFn('GSX_FLOW')){
                                    if(consumerServiceRequest.isDataMasked){
                                        checkMaskedData();
                                    }else if(pickupCarryinGsxFlag.isNonSerializedProduct){
                                        if(consumerServiceRequest.consumerProduct.DateOfPurchase){
                                            if(!pickupCarryinGsxFlag.isStaticNonSerializedProduct){
                                                getSKUForNonSerialized();
                                            }
                                            getGSXChecks(false, true);
                                            setPickupCarryinGsxFlag({...pickupCarryinGsxFlag,InwardCheckList:true});
                                        }else{
                                            Swal.fire({
                                                title: '',
                                                text: localString("Please update the device date of purchase in device details section to proceed further."),
                                                icon: 'warning',
                                                confirmButtonText: localString('OK')
                                            });
                                        }
                                    }else{
                                        if(featureConfig && featureConfig.CheckFM){
                                            FMIPcheck();
                                        }else if(featureConfig && featureConfig.ShowFMPopUp){
                                            setShowFMIPModal(true)
                                            // setShowModal({...showModal,showFMIPModal:true});
                                        }else{
                                            getPart();
                                            getGSXChecks(false, true);
                                        }
                                    }
                                }else{
                                    getPart();
                                    getGSXChecks(false, true);
                                }
                            }else{
                                showDeviceReceivedModal();
                            }
                        }else if(refurbishmentFlowCheck){
                            // showRefurbInspectDevice('QC completed')
                            showDeviceInspectModal()
                        }
                        else{
                            showDeviceReceivedModal();
                        }
                        break;
                    case "Claim rejected":
                        if(replacementFlowChecks){
                            setClaimFlags({...claimFlags,showClaimCancelRequestBtn:true})
                            getReasons(status);
                        } if(onsiteChecks){
                            if(showButton){
                                if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
                                    // getReasons(e.target.name);
                                    getReasons(status);
                                }else{
                                    showCancelRequestConfirmModal()
                                    // preShowCancelRequestConfirm();
                                }
                            }
                        }else{
                            openReasonsModal(status);
                        }
                        break;
                    case "Claim initiated":
                        if(claimNewWorkshopChecks) {
                            if([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) == -1){
                                showClaimInitiatedConfirm(status);
                            }
                        }else if(claimChecks) {
                            showConfirmation(status);
                        }
                        break;
                    case "Perform QC":
                        if(pickupCarryInChecks){
                            if(consumerServiceRequest.consumerProduct.isAppleBrandID){
                                // GSX Flow
                                if(accessFn('QC_DEVICE')){
                                    if (consumerServiceRequest.qcList && consumerServiceRequest.qcList.length > 0) {
                                        showQCCompletedModal();
                                    } else {
                                        processRequestNextPickUp(status);
                                    }  
                                }
                            }else{
                                // NON - GSX 
                            }
                        }else{
                            if(accessFn('QC_DEVICE')){
                                if(consumerServiceRequest && consumerServiceRequest.qcList && consumerServiceRequest.qcList.length >0){
                                    showQCCompletedModal()
                                }else{
                                    claimRequestNextWs();
                                }
                            }
                        }
                        break;
                    case "Service initiated":
                        if(claimChecks){
                            claimRequestNonWorkshop(status);
                        }
                        break;
                    case "Select outcome":
                        setShowModal({...showModal,showSelectOutcomeModal:true});
                        break;
                    case  "Flag Fraud":
                            getAddedFlag();
                        break;
                    case "Skip form":
                        showSkipFormConfirmation(); 
                        break; 
                    case "Decision form":
                        setShowModal({...showModal,showDecisionFormModal:true});
                        break;
                    case "Enter check number":    //replacement flow
                        openChequeModal(status);
                        break;
                    case "View options": //replacement flow
                        setReplacementFlowFlags({
                            viewOptionsMode:true,
                            chooseOptionsMode:false,
                            showReplacementOptionsProvided:true
                        })
                        openReplacementOptions();  
                        break;
                    case "Choose option": //replacement flow
                        setReplacementFlowFlags({
                            viewOptionsMode:false,
                            chooseOptionsMode:true,
                            showReplacementOptionsProvided:true
                        })
                        openReplacementOptions();  
                        break;
                    case "Replacement allocated": //replacement flow
                        getDeviceConfig(false);
                        break;   
                    case "Replacement device collected":   //replacement flow
                        setShowModal({...showModal,showCollectionDatePicker:true});
                        break;
                    case "Ready For Collection":   //replacement flow
                        setNextStatus('Ready For Collection');
                        setShowModal({...showModal,showCollectionDatePicker:true});
                        break;
                    case "Collection order created":   //replacement flow
                        setShowModal({...showModal,showUpdateOrderDetailsModal:true});
                        break;
                    case "Convert to reimbursement":   //replacement flow
                        if(['Pending for reimbursement'].indexOf(consumerServiceRequest.Status) > -1) {
                            setNextStatus('Reimbursement approved')
                            approveConfirmation('Reimbursement approved');
                        } else {
                            setShowModal({...showModal,showPayoutModesModal : true});
                        }
                        break;
                    case "Reimbursement approved":
                        approveConfirmation(status); 
                        break;
                    case "Create Refurb Request" :
                        if(replacementFlowChecks){
                            setShowModal({...showModal,showCreateRefurbModal:true});
                        }else{
                            createRefurbRequest();
                        }
                        break;
                    case "Create Exchange Order" :
                        createExchangeOrder();
                        break;
                    case "Security deposit charged":
                        replacementSecurityChargeDeposit(status);
                        break;
                    case "Security deposit released":
                        replacementSecurityReleaseDeposit(status);
                        break;  
                    case "BER payment initiated":
                        // if(showRequestLogs.BERConfirmed && berValid.paymentModeCheque){
                        //     setShowModal({...showModal,showInitiateBERRefundModal:true});
                        // }
                        // else{
                            setShowModal({...showModal,showInitiateBERRefundModal:true});
                            // preBerRequestNext(status)
                        // }
                        break;
                    case "BER replacement issued" :
                        setShowModal({...showModal,showBERProductModal:true});
                        initiateBerProductModal();
                        break;   
                    case "BER accessories received":
                        preBerRequestNext(status);  
                        break;
                    case "BER accessories validated" :
                        preBerRequestNext(status);  
                        break;
                    case "BER accessories rejected":
                        preBerRequestNext(status);  
                        break; 
                    case "BER approved":
                        showBERConfirmation(status) ;
                        break; 
                    case "BER rejected":  
                        showBERConfirmation(status) ;
                        break; 
                    case "BER payment completed":
                        if(!berValid.paymentModeCheque){
                            setShowModal({...showModal,showUTRPymntDateModal:true})
                        }else{
                            let data = {};
                            if(berValid.paymentModeCheque && showRequestLogs.BERConfirmed){
                                data.PayoutMode = "CHEQUE";
                                preBerRequestNext(status,data);
                            }else{
                                preBerRequestNext(status);
                            }
                        };
                        break;
                    case "BER cancel":
                        openReasonsModal(status);
                        break;  
                    case "BER confirmed":
                        setShowModal({...showModal,showPayoutModesModal:true});  
                        break;
                    case "BER repair" :
                        preBerRequestNext(status);
                        break;
                    case "BER replacement confirmed":
                        preBerRequestNext(status);
                        break;
                    case "BER documents validated" :
                        preBerRequestNext(status);
                        break;  
                    case "BER Accessories Received":
                        preBerRequestNext(status);
                        break;
                    case "BER device received":
                        preBerRequestNext(status);
                        break; 
                    case "Issue resolved":
                        setShowModal({...showModal,showIssueResolvedModal:true});
                        break; 
                    case "Show device diagnosis" :  
                        window.open(baseUrl + '/consumers/diagnosis?cpid=' + consumerServiceRequest.ConsumerProductID, '_blank');    
                        break;
                    case "ASC visit advised" :
                        preEnquiryRequestNext(status) ;
                        break;  
                    case "BER shipment created":
                        setShowModal({...showModal,showSelectAddressPopupModal:true});
                        break;
                    case "Advised for RVP":
                        preEnquiryRequestNext(status);
                        break; 
                    case "RVP rejected":
                        preEnquiryRequestNext(status);
                        break;    
                    case "TODO: Create GSX Repair":
                        checkAndCreateGsxrepair();
                        // setShowModal({...showModal, gsxRaiseRequestModal: true})
                        break;
                    case 'Request raised':
                        checkAndCreateGsxrepair();
                        break;
                    case 'Issue Loaner Device':
                        issueLoanerDevice();
                        break;
                    case 'Handover Loaner Device to Customer':
                        handoverLoanerDevice();
                        break;
                    case 'Accept Loaner Device':
                        acceptLoanerDevice();
                        break;
                    case 'Run Diagnosis on Loaner Device':
                        fetchLoanerDeviceChecklist();
                        break;
                    case 'Collect Loaner Payment':
                        getLoanerPaymentModes();
                        break;
                    case 'Loaner Service Complete':
                        updateLoanerStatus();
                        break;
                    case "Non Hardware Closure":
                        setShowModal({...showModal, showNonHardwareClosureModal: true})
                        break;
                    case "Replacement Request Generated":
                        if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.UNINSTALLATION, SERVICETYPE.INSPECTION].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REPLACEMENT_PROCESS')) {
                            processRequestNext();
                        }
                        break;
                    case "Refund Rejected":
                        if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.UNINSTALLATION, SERVICETYPE.INSPECTION].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REPLACEMENT_PROCESS')) {
                            if((['SkyworthDashboard','SmartTechnoDashboard'].indexOf(consumerServiceRequest.Source) > -1 || (consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID == 28 && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,56,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1)) && consumerServiceRequest.Status == "Replacement Request Generated"){
                                setNextStatus('Replacement Rejected');
                                handleApprovalRemarksForFullbox(status);
                            }else if(['SkyworthDashboard','SmartTechnoDashboard'].indexOf(consumerServiceRequest.Source) > -1 && consumerServiceRequest.Status == "High Value Part Requested"){
                                setNextStatus('Replacement Rejected')
                                handleApprovalRemarksForFullbox(status);
                            }else if(refundProcessCheck){
                                refundProcessNext(status,false)
                            }
                            else{
                                setNextStatus('Refund Rejected');
                                processRequestNext('Refund Rejected');
                            }
                        }
                        break;
                    case 'Refund Rejected by Vendor':
                        if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.UNINSTALLATION, SERVICETYPE.INSPECTION].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REPLACEMENT_PROCESS')) {
                            handleRefundRejection(status);
                        }
                        break;
                    case 'Awaiting Supplier Response':
                        if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.UNINSTALLATION, SERVICETYPE.INSPECTION].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REPLACEMENT_PROCESS')) {
                            setNextStatus('Pending for Vendor Approval');
                            // showSerialNumberModal();
                            setShowModal({ ...showModal, serialNumberModal: true });
                        }
                        break;
                    case 'Refund Without Supplier Response':
                        if((['SkyworthDashboard','SmartTechnoDashboard'].indexOf(consumerServiceRequest.Source) > -1  || (consumerServiceRequest && consumerServiceRequest.consumerProduct &&  consumerServiceRequest.consumerProduct.BrandID == 28 &&  [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,56,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ))&& consumerServiceRequest.Status == "Replacement Request Generated"){
                            setNextStatus('Replacement Approved');
                            handleApprovalRemarksForFullbox(status);
                        }else if(['SkyworthDashboard','SmartTechnoDashboard'].indexOf(consumerServiceRequest.Source) > -1  && consumerServiceRequest.Status == "High Value Part Requested") {
                            setNextStatus('High Value Part Approved');
                            handleApprovalRemarksForFullbox(status);
                        }else if(refundProcessCheck){
                            refundProcessNext(status,false)
                        }else {
                            setNextStatus('Refund Approved');
                            processRequestNext('Refund Approved');
                        }
                        break;
                    case 'Reschedule Requested':
                        setShowModal({ ...showModal, createVisitModal: true });
                        rescheduleService(status);
                        break;
                    case 'Refund Approved':
                        setShowModal({ ...showModal, tokenNumberModal: true });
                        break;
                    case 'Skip diagnosis':
                        skipDiagnosis(status);
                        break;
                    case 'Send link to consumer':
                        showLinkToCustomerAlert();
                        break;
                    case 'Validate from GSX':
                        validateProduct();
                        break;
                    case 'Fetch Parts From GSX':
                        fetchPartFromGSX(status);
                        break;
                    case 'Fetch GSX Status':
                        fetchGSXStatus();
                        break;
                    case 'Reject GSX Repair':
                        rejectGSXRepair();
                        break;
                    case 'Fetch Latest Diagnostics Results': //gsx
                        showDiagnosticsResultModal(); 
                        break;  
                    case 'Cancel logistics request'://non gsx
                        cancelShipment();
                        break;
                    case 'Estimation rejected':
                        processRequestNextPickUp(status);  
                        break;
                    case 'Repair cancel initiated': 
                        initiateCancelRepair(status);
                        break;
                    case 'QC completed':
                        if(pickupCarryInChecks){
                            if(consumerServiceRequest.consumerProduct.isAppleBrandID){
                                // GSX Flow
                                if(accessFn('QC_DEVICE')){
                                    if (consumerServiceRequest.qcList && consumerServiceRequest.qcList.length > 0) {
                                        showQCCompletedModal();
                                    } else {
                                        processRequestNextPickUp(status);
                                    }  
                                }
                            }else{
                                // NON - GSX 
                            }
                        }
                        break;
                    case 'DOA approved':
                        openApproveModal();
                        break;
                    case 'Initiate AST 2 Diagnostics':
                        initiateDiagnosis(status)
                        break;
                    case 'Initiate Diagnostics':
                        initiateRunDiagnosis(status)
                        break;
                    case 'Take Backup':
                        takeBackup();
                        break;
                    case 'Map G-Number':
                        setShowModal({...showModal, showMapGNumberModal: true})
                        break;
                    case "Reassign Service Center":	
                        if(([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('UPDATE_PSL_OF_REQUEST')){	
                            openUpdateServiceCenterModal(status);
                        }
                        break;	
                    case "Transfer request":	
                        if(consumerServiceRequest.IsLogisticsRequestCreated && consumerServiceRequest.IsOutwardRequired && !consumerServiceRequest.IsOutwardTaken){	
                            //openServiceLocationModal();	
                            //getInwardOutwardforTransfer()	
                        }else{	
                            getLocationsForTransfer();	
                        }	    
                        break;	
                    case "DOA checklist":	
                        openDoaChecklistModal();	
                        break;	
                    case 'Receive device from CP':	
                        addUpdateStatus(status);
                        break;	
                    case 'Receive device from Repair Center':	
                        addUpdateStatus(status);
                        break;
                    case 'Plans Description':	
                        openPlanTypeModal();
                        break;
                    case 'Initiate Exception':
                        approveException();
                    break;
                    case 'Initiate Refund':	
                        if(accessFn('ALLOW_REFUND')){	
                            getPaymentDetailsForRefund();
                        }
                    break;
                    case 'Waiting on customer':
                        openReasonsModal(status);
                        break;
                    case 'Response Received':
                        let DeviceReceivedIndex = findIndex(consumerServiceRequest.logs, {'Status': 'Device received'});
                        let ClaimEstimationGeneratedIndex = findIndex(consumerServiceRequest.logs, {'Status': 'Claim estimation generated'});
                        if(DeviceReceivedIndex>-1){
                            if(ClaimEstimationGeneratedIndex==-1){
                                showConfirmation('Device received');
                            }else if(ClaimEstimationGeneratedIndex> -1 && DeviceReceivedIndex<ClaimEstimationGeneratedIndex){
                                showConfirmation('Device received');
                            }else if(ClaimEstimationGeneratedIndex> -1 && DeviceReceivedIndex>ClaimEstimationGeneratedIndex){
                                showConfirmation('Claim estimation generated');
                            }
                        }else if (ClaimEstimationGeneratedIndex>-1){
                            showConfirmation('Claim estimation generated');
                        }
                        break;    
                    case 'DOA completed':
                        if(pickupCarryInGSXChecks){
                            //GSX FLOW
                            showPartsModalPickUpCarryInGsx()
                        }else if(pickupCarryInChecks){
                            //NON GSX FLOW
                            showPartsModalPickUpCarryIn()
                        }
                        break;
                    case 'Replacement options provided'  :
                        getDeviceConfig(true);
                        setReplacementFlowFlags({...replacementFlowFlags,showReplacementOptionsProvided:false})
                        break
                    case 'Replacement unavailable':
                        replacementFlowNext(status)
                        break;
                    case 'Replacement shipment created':
                        setShowModal({...showModal,showSelectAddressPopupModal:true});
                        break;  
                    case 'Charge Credit Card':
                        getChargeCreditCard();
                        break; 
                    case 'Replacement delivered':
                        replacementFlowNext(status)
                        break;
                    case 'Defective received': 
                        replacementFlowNext(status)
                        break;
                    case 'Ship Packaging Material':
                        shipPackageMaterial(status);
                        break;   
                    case 'Complete Without Repair' :
                            if(countriesChecks && countriesChecks.TaxDetailName && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.IsWarrantyApplicable){
                                setShowBillingModal(true);
                            }else{
                                preProcessRequest(status);  
                            }
                        break;
                    case 'Approve Estimation':
                        overideEstimationApproval(status);
                        break;
                    case 'Reject Estimation':
                        overideEstimationReject(status);
                        break; 
                    case 'Replacement authorized':
                        replacementFlowNext(status);
                        break;  
                    case 'Payment Received':
                        getLoanerPaymentModes(status);
                        // receivePayment(status);
                        break;
                    case 'Payment Pending':
                        preProcessRequest(status);
                        break;
                    case 'Service completed and rated':
                        if(pickupCarryInGSXChecks && accessFn('GSX_FLOW')){
                            serviceCompleteModal(status);
                        }
                        break;
                    case 'Analysis completed':
                        if(refundProcessCheck){
                            showActionCategories(status) 
                        }else if(serviceRequestClusterCheck){
                            showActionCategoriesServiceCluster(status)
                        }
                        break;
                    case 'Assign':
                        assignToSelf();
                        break;
                    case 'Repair initiated':
                        if(refurbishmentFlowCheck){
                            showRefurbConfirmation(status)
                        }
                        break;
                    case 'Return to Sender':
                        if(refurbishmentFlowCheck){
                            setShowModal({...showModal,showReturnToSenderModal:true})
                            // showRefurbReturnToSender('QC completed')
                        }
                    break;
                    case 'Receive device from RC':
                        receiveDevice();
                        break;    
                    case 'Change Service Center' :
                        openReassignServiceCenter();
                        break;       
                    case 'Select Service Approach':
                        openServiceApproachModal();
                        break;
                    case 'Update KGB':
                        setShowModal({...showModal, updateKGBModal: true});
                        break;
                    case 'Ready for shipment':
                        addUpdateStatus('Ready for shipment');
                            break;
                    case 'Ship to service centre':
                        setShowModal({...showModal, ShipToServiceCentreModal: true});
                        break;
                    case 'Device received at service centre':
                        addUpdateStatus()
                        break;
                    case 'Initiate shipment to drop-off location':
                        setDropOffFlag(true);
                        getPart();
                        getGSXChecks(false, false);
                        break;
                    case 'Ship device to drop-off location':
                        setShipToDropOFFLoc(true);
                        setShowModal({...showModal, ShipToServiceCentreModal: true});
                        break;
                    case 'refurbishment shipment created' :
                        // showRefurbInspectDevice('Device delivered')
                        break;
                    case 'Device delivered':
                        if(refurbishmentFlowCheck){
                            showRefurbConfirmation(status)
                        }
                        break;  
                    case 'Request BER Payment':
                        setShowModal({...showModal,showInitiateBERRefundModal:true});
                        setNextStatus('BER payment requested')
                        break;
                    case 'BER revision required':
                        preBerRequestNext('BER payment revision required');
                        break;    
                    case 'Proof revision required':   
                        updateConsumerServiceRequestStatus('Proof revision required')
                        break;
                    case 'Claim estimation revision required':
                        updateConsumerServiceRequestStatus('Claim estimation revision required')
                        break;
                    default: //Device inspected claimRequestNonWorkshop
                }
            }
        }
    }

    const openApproveModal = () => {
        setShowModal({...showModal, approveDoaModal: true});
    }
    
    const rejectGSXRepair = () => {
        Swal.fire({  
			title: localString('Are you sure..?'),
			showCancelButton: true,
			allowEscapeKey: false,
			allowOutsideClick: false,
			confirmButtonText: localString('OK'),
			cancelButtonText: localString('Cancel')
        }).then(swalResult => {
            if(swalResult.isConfirmed) {
                setLoader(true); 
                let reqObj = {
                    ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
                };
                apiCall({
                    url: 'rejectGsxRepair',
                    data: reqObj,
                    callBack: (result) => {
                        setLoader(false); 
                        if(result && result.success) {
                            getRequestById && getRequestById();
                        }else {                    
                            Swal.fire({  
                                icon: 'error',  
                                text: localString(displayError(result)), 
                            });
                        }
                    },
                    errorCB: (err) => {
                        setLoader(false);
                        Swal.fire({  
                            icon: 'error',  
                            text: localString(displayError(err)),  
                        });
                    }
                });
            }
        });
    }

    const showDiagnosisNotesModal = () => {
        /** diagnosis-notes-modal */
        setShowModal({...showModal, diagnosisNotesModal:true});
	}


    const fetchGSXStatus = () => {
        setLoader(true); 
        let reqObj = {
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
        };
        apiCall({
            url: 'fetchGsxIssueStatusDetails',
            data: reqObj,
            callBack: (result) => {
                setLoader(false); 
                if(result && result.success) {
                    if(result.data.transactionStatus){
                        getRequestById && getRequestById();
                    }else {
                        Swal.fire({  
                            icon: 'error',  
                            text: localString(result.data.statusMessage), 
							confirmButtonText: localString('OK')
                        });
                    }
                }else {                    
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(result)), 
                    });
                }
            },
            errorCB: (err) => {
                setLoader(false);
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(err)),  
                });
            }
        });
    }

    const fetchPartFromGSX = () => {
        setLoader(true); 
        let reqObj = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			ProductName: consumerServiceRequest.consumerProduct.product.ProductName,
			configDescription: consumerServiceRequest.consumerProduct.ConfigDescription,
			serialNumber: consumerServiceRequest.consumerProduct.AlternateUniqueKey ? consumerServiceRequest.consumerProduct.AlternateUniqueKey : consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue
        };
        apiCall({
            url: 'fetchGsxParts',
            data: reqObj,
            callBack: (result) => {
                setLoader(false); 
                if(result && result.success) {
                    getRequestById && getRequestById();
                }else {                    
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(result)), 
                    });
                }
            },
            errorCB: (err) => {
                setLoader(false);
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(err)),  
                });
            }
        });
    }

    const validateProduct = () => {
        setLoader(true); 
        let reqObj = {
            ConsumerID: consumerDetails.ConsumerID,
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			ConsumerProductID: consumerServiceRequest.ConsumerProductID,
			verifySerialFromGsx: true
        };

        if(consumerServiceRequest && consumerServiceRequest.consumerProduct){
			reqObj.BrandID = consumerServiceRequest?.consumerProduct?.BrandID;
			reqObj.imeiOrSerial = consumerServiceRequest?.consumerProduct?.ProductUniqueID;
		} else {
			return
		}
        apiCall({
            url: 'verifyAndCreateProduct',
            data: reqObj,
            callBack: (result) => {
                setLoader(false); 
                if(result && result.success) {
                    Swal.fire({  
                        icon: 'success',  
                        text: localString(displayError(result)), 
                    });
                    getRequestById && getRequestById();
                }else {
                    if(result.isGsxDown){
                        Swal.fire({  
                            icon: 'error',  
                            text: localString("Unable to validate details from GSX currently. Please try again after sometime."),
							confirmButtonText: localString('OK')
                        });
					}else {
                        Swal.fire({  
                            icon: 'error',  
                            text: localString(displayError(result)), 
                        });
					}
                }
            },
            errorCB: (err) => {
                setLoader(false);
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(err)),  
                });
            }
        });
    }

    const showLinkToCustomerAlert = () => {
        Swal.fire({  
			title: localString('Provide link for customer'),
			input: 'textarea',
			showCancelButton: true,
			allowEscapeKey: false,
			allowOutsideClick: false,
			confirmButtonText: localString('OK'),
			cancelButtonText: localString('Cancel'),
        }).then(swalResult => {
            if(swalResult.isConfirmed) {
				let remark = swalResult.value || '';
                if(remark) {
                    setLoader(true); 
                    let reqObj = {
                        ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                        PaymentUrl: remark
                    };
                    apiCall({
                        url: 'sendPaymentUrl',
                        data: reqObj,
                        callBack: (result) => {
                            setLoader(false); 
                            if(result && result.success) {
                                Swal.fire({  
                                    icon: 'info',  
                                    text: localString(displayError(result)), 
                                });
                            }else {
                                Swal.fire({  
                                    icon: 'error',  
                                    text: localString(displayError(result)), 
                                });
                            }
                        },
                        errorCB: (err) => {
                            setLoader(false);
                            Swal.fire({  
                                icon: 'error',  
                                text: localString(displayError(err)),  
                            });
                        }
                    });
                }else {
                    Swal.fire({  
                        title: localString('Please enter the link'),
                        showCancelButton: false,
                        allowEscapeKey: false,
                        confirmButtonText: localString('OK'),
                        icon: 'error',
                    }).then(res => {
                        if(res.isConfirmed) {
                            showLinkToCustomerAlert();
                        }
                    })
                }     
            }
        });
    }

    const skipDiagnosis = () => {
        setLoader(true); 
        let reqObj = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
        };
        apiCall({
            url: 'autoAssignEngineerByConsumerServiceRequestID',
            data: reqObj,
            callBack: (result) => {
                setLoader(false);
                if(result && result.success) {
                    getRequestById && getRequestById();
                    // TODO
                    // if(self.parent.tags['assign_workshop']){
                    //     self.parent.tags['assign_workshop'].loadEngineersCalled = false;
                    // }
                }else {
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(result)), 
                    });
                }
            },
            errorCB: (err) => {
                setLoader(false);
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(err)),  
                });
            }
        });
    }

    const processRequestNextWithoutConfirmation = (status) => {
        setLoader(true); 
        let reqObj = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            Status: status
        };
        apiCall({
            url: 'servicerequestFulfilmentWorkshop',
            data: reqObj,
            callBack: (result) => {
                setLoader(false);
                if(result && result.success) {
                    getRequestById && getRequestById();
                }else {
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(result)), 
                    });
                }
            },
            errorCB: (err) => {
                setLoader(false);
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(err)),  
                });
            }
        });
    }

    const handleApprovalRemarksForFullbox = (status) => {
        if(consumerServiceRequest && ['Replacement Request Generated','High Value Part Requested'].indexOf(consumerServiceRequest.Status) > -1) {
            if(['Replacement Approved', 'High Value Part Approved'].indexOf(status) > -1){ 
                setApprovalVariable("Approval");
            }
            if(['Replacement Rejected', 'High Value Part Rejected'].indexOf(status) > -1){
                setApprovalVariable("Rejection");
            }
            setShowModal({ ...showModal, approvalRemarksModal: true });
        }else {
            processRequestNext(status);
        }
    }

    const handleRefundRejection = (status) => {
        if(consumerServiceRequest && ['Pending for Vendor Approval'].indexOf(consumerServiceRequest.Status) > -1){
            setShowModal({ ...showModal, rejectReasonModal: true });
        }else {
            processRequestNext(status);
        }
    }

    const processRequestNext = (statusVal) => {
        let status = nextStatus ? nextStatus : statusVal;
        setLoader(true); 
        let reqObj = {
            'ConsumerServiceRequestID': consumerServiceRequest.ConsumerServiceRequestID,
            'Status': status,
            'RefundToken': refundToken ? refundToken : undefined,
            'SerialNumber': serialNumberRefund ? serialNumberRefund : undefined
        };
        if(['Refund Rejected by Vendor'].indexOf(status) > -1 && ['Pending for Vendor Approval'].indexOf(consumerServiceRequest.Status) > -1){
            reqObj.Reason = refundRejectionReason.trim();
        }
        if(['Replacement Approved','Replacement Rejected','High Value Part Approved','High Value Part Rejected'].indexOf(status) > -1 && ['Replacement Request Generated','High Value Part Requested'].indexOf(consumerServiceRequest.Status) > -1  && (['SkyworthDashboard','SmartTechnoDashboard'].indexOf(consumerServiceRequest.Source) > -1  || (consumerServiceRequest && consumerServiceRequest.consumerProduct &&  consumerServiceRequest.consumerProduct.BrandID == 28 &&  [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,56,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ))){
            reqObj.Remarks = approvalRemarks.trim();
        }
        apiCall({
            url: 'processReplacementRequest',
            data: reqObj,
            callBack: (result) => {
                setLoader(false);
                if(result && result.success) {
                    getRequestById();
                }else {
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(result)), 
                    });
                }
            },
            errorCB: (err) => {
                setLoader(false);
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(err)),  
                });
            }
        });

    }

    const checkLoanerRequest = () => {
		if(accessFn('GSX_FLOW') && consumerServiceRequest.loanerDetails && consumerServiceRequest.loanerDetails.loanerRequested && (['LOCN', 'LORC'].indexOf(consumerServiceRequest.loanerDetails.currentStatus) == -1)) {
			return true;
		} else {
			return false;
		}
	};

    const issueLoanerDevice = () => {
        Swal.fire({  
			title: localString('Are you sure?'),
            icon: 'question',
            confirmButtonColor: '#4C10C1',
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK"),
            allowEscapeKey: false,
        }).then(swalResult => {
            if(swalResult.isConfirmed){
                setLoader(true); 
                let reqObj = {
					ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
					PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID,
					PartCode: loanerDetails.activeLoaner.PartCode,
					LoanerDeviceSerialNumber: loanerDetails.loanerDeviceSerialNumber,
					LoanerDeviceName: loanerDetails.loanerDeviceName,
					LoanerRequestID: loanerDetails.loanerRequestID,
					PartID: loanerDetails.activeLoaner.PartID,
					LoanerDeviceImei: loanerDetails.loanerDeviceSerialNumber
				};
                apiCall({
                    url: 'issueLoanerDevice',
                    data: reqObj,
                    callBack: (result) => {
                        setLoader(false); 
                        if(result && result.success) {
                            getRequestById();
                            fetchLoanerDetailsByCsrId();
                        }else {
                            Swal.fire({  
                                icon: 'error',  
                                text: localString(displayError(result)), 
                            });
                        }
                    },
                    errorCB: (err) => {
                        setLoader(false);
                        Swal.fire({  
                            icon: 'error',  
                            text: localString(displayError(err)),  
                        });
                    }
                });
            }else {
                window.location.reload();
            }
        });
    }

    const handoverLoanerDevice = () => {
        Swal.fire({  
			title: localString('Are you sure?'),
            icon: 'question',
            confirmButtonColor: '#4C10C1',
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK"),
            allowEscapeKey: false,
        }).then(swalResult => {
            if(swalResult.isConfirmed){
                setLoader(true); 
                let reqObj = {
					ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
					PartCode: loanerDetails.activeLoaner.PartCode,
					LoanerDeviceSerialNumber: loanerDetails.loanerDeviceSerialNumber,
					LoanerRequestID: loanerDetails.loanerRequestID
				};
                apiCall({
                    url: 'handoverLoanerDevice',
                    data: reqObj,
                    callBack: (result) => {
                        setLoader(false); 
                        if(result && result.success) {
                            getRequestById();
                            fetchLoanerDetailsByCsrId();
                        }else {
                            Swal.fire({  
                                icon: 'error',  
                                text: localString(displayError(result)), 
                            });
                        }
                    },
                    errorCB: (err) => {
                        setLoader(false);
                        Swal.fire({  
                            icon: 'error',  
                            text: localString(displayError(err)),  
                        });
                    }
                });
            }else {
                window.location.reload();
            }
        });
    }

    const setLoanerStatus = (status, remarks) => {
        setLoader(true); 
        let reqObj = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            status : status,
            Remarks : remarks,
            LoanerRequestID: loanerDetails.loanerRequestID,
        };
        let setStatusPromise = new Promise(function(resolve, reject) {
            apiCall({
                url: 'setLoanerStatus',
                data: reqObj,
                callBack: (result) => {
                    setLoader(false); 
                    if(result?.success) {
                        resolve(result);
                    }else {
                        reject(result?.msg || 'Something went wrong');
                    }
                },
                errorCB: (err) => {
                    setLoader(false);
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(err)),  
                    });
                }
            });
        });
        return setStatusPromise;
    }

    const acceptLoanerDevice = () => {
        setLoanerStatus('LORV', 'Accepting Loaner Device')
        .then(function(res) {
            if(res.success){
                setLoanerStatus('LORI', 'Loaner return initiated')
                .then(function(res){
                    if(res.success) {
                        fetchLoanerDetailsByCsrId();
                        getRequestById();
                    }else {
                        Swal.fire({  
                            icon: 'error',  
                            text: localString(displayError(res)),  
                        });
                    }
                });
            }else {
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(res)),  
                });
            }
        })
    }

    const updateLoanerStatus = () => {
        setLoanerStatus('LORC', 'Loaner request completed')
        .then(function(res) {
            if(res.success){
                getRequestById();
                fetchLoanerDetailsByCsrId();
            }else {
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(res)),  
                });
            }
        })
    }

    const fetchLoanerDeviceChecklist = () => {
        setLoader(true); 
        let reqObj = {
			"Type": "Loaner"
		}
        apiCall({
            url: 'getLoanerReturnChecklist',
            data: reqObj,
            callBack: (result) => {
                setLoader(false); 
                if(result && result.success) {
                    setGsxCheckList(result.data.CheckList);
                    result?.data?.CheckList?.length && setShowModal({ ...showModal, loanerDeviceDiagnosisModal: true });
                }else {
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(result)), 
                    });
                }
            },
            errorCB: (err) => {
                setLoader(false);
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(err)),  
                });
            }
        });
    }

    const getLoanerPaymentModes = (status) => {
        setLoader(true); 
        let reqObj = {
			'ConsumerServiceRequestID': consumerServiceRequest.ConsumerServiceRequestID,
			'PartnerID': consumerServiceRequest.PartnerID,
			'PartnerServiceLocationID': consumerServiceRequest.PartnerServiceLocationID,
			'Entity': accessFn('GSX_FLOW') ? 'LoanerRequest': 'ConsumerServiceRequest',
		}
        if(!accessFn('GSX_FLOW')){
            reqObj.BrandID = consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.BrandID : undefined
        }
        apiCall({
            url: 'getLoanerPaymentModes',
            data: reqObj,
            callBack: (result) => {
                setLoader(false); 
                if(result && result.success) {
                    setLoanerPaymentModes(result.data.paymentmodes);
                    setLoanerPayableAmt(result.data.totalPayableAmout > 0 ? result.data.totalPayableAmout : '');
                    setShowModal({ ...showModal, loanerPaymentModal: true });
                }else {
                    if(!accessFn('GSX_FLOW') && !accessFn('WORKSHOP_FLOW')){
                        receivePayment(status)
                    }else{
                        Swal.fire({  
                            icon: 'error',  
                            text: localString(displayError(result)), 
                        });
                    }
                }
            },
            errorCB: (err) => {
                setLoader(false);
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(err)),  
                });
            }
        });
    }

    const checkAndCreateGsxrepair = () => {
        if (consumerServiceRequest.gsxRepairFlags && (consumerServiceRequest.gsxRepairFlags.repairTypeId != 2) && partsRequested.length && consumerServiceRequest.isVerifiedProduct && checkLoanerRequest()) {
            Swal.fire({
                icon: "error",
                text: localString('Please cancel the loaner request to select this repair type.'),
            });
        }else {
            // showRequestRaisedModal();
            setShowModal({ ...showModal, gsxRaiseRequestModal: true ,showSelectServiceApproachModal:false});
            if(!partsRequested.length){
                // TODO
                // getPartsList();
                // setActiveTab({...activeTab, showPartEstimation: true});
            }
        }
    }

    const validateDiagnosisInput = () => {
        if(!GSXDiagnosisModalObj.diagnosisSteps && GSXDiagnosisModalObj.diagnosisSteps.length < 0){
            Swal.fire({
                title: '',
				text: localString('Please enter the mandatory field.'),
				icon:"error",
				confirmButtonText: localString('OK')
            })
            return false;
        }
        else if(!GSXDiagnosisModalObj.engineerNotes && GSXDiagnosisModalObj.engineerNotes.length < 0){
            Swal.fire({
                title: '',
				text: localString('Please enter the mandatory field.'),
				icon:"error",
				confirmButtonText: localString('OK')
            })
            return false;
        }
        else{
            return true;
        }
	}

    const closeDiagnosisNotesModal = () =>{
        setShowModal({...showModal,diagnosisNotesModal:false});
        setGSXDiagnosisModalObj({
            diagnosisSteps:'',
            engineerNotes:''
        });
        setDiagnosisStepsErrorMessage('');
        setEngineerNotesErrorMessage('');
    }
    
    const hideDiagnosisNotesModal = (e) => {
        e.preventDefault();
		let validateDiagnosis = validateDiagnosisInput();
		if (!validateDiagnosis) {
			return;
		}
        if ((!(GSXDiagnosisModalObj.diagnosisSteps > 0 && GSXDiagnosisModalObj.diagnosisSteps < 1501)) || (!(GSXDiagnosisModalObj.engineerNotes.length > 0 && GSXDiagnosisModalObj.engineerNotes.length < 1001))) {
			if (!(GSXDiagnosisModalObj.diagnosisSteps.length > 0 && GSXDiagnosisModalObj.diagnosisSteps.length < 1501)) {
				let diagnosisStepsErrorMsg = localString("Please enter steps in less than 1500 characters")
                setDiagnosisStepsErrorMessage(diagnosisStepsErrorMsg);
			}else {
                setDiagnosisStepsErrorMessage('');
            }
			if (!(GSXDiagnosisModalObj.engineerNotes.length > 0 && GSXDiagnosisModalObj.engineerNotes.length < 1001)) {
				let engineerNotesErrorMsg = localString("Please enter notes in less than 1000 characters");
                setEngineerNotesErrorMessage(engineerNotesErrorMsg);
			}else {
                setEngineerNotesErrorMessage('');
            }
		} 
        if(GSXDiagnosisModalObj.engineerNotes && GSXDiagnosisModalObj.diagnosisSteps){
            closeDiagnosisNotesModal();
            saveDiagnosisNotes();
        }
    }

    const getCurrentRole = () => {
        let role;
        if (qFilter) {
            if (qFilter.hasUnrestrictedAccess) {
                role = 'Superadmin';
            } else {
                switch (true) {
                case (accessFn('ACCEPT_RETURN_DEVICE')):
                    role = 'CustomerCare';
                    break;
                case (accessFn('STORE_MANAGEMENT')):
                    role = 'StoreManager';
                    break;
                case (accessFn('REASSIGN_JOBS')):
                    role = 'Supervisor';
                    break;
                case (accessFn('REPAIR_DEVICE')):
                    role = 'ServicelocationEngineer';
                    break;
                case (accessFn('QC_DEVICE')):
                    role = 'QCEngineer';
                    break;
                }
                return role;
            }
        }
        return role;
    }

    const saveDiagnosisNotes = () => {
        let eventData = [];
        diagnosisLabel && diagnosisLabel.map(label=>{
            let obj = {};
            obj.EventID = label && label.value
            obj.Type = label.Type
            eventData.push(obj);
        })
        let data = {
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "DiagnosisSteps": GSXDiagnosisModalObj.diagnosisSteps,
            "EngineerNotes": GSXDiagnosisModalObj.engineerNotes,
            "EventData": eventData,
            "userRole":getCurrentRole(),
            "currentUserID":qFilter.userID
        }
        if(['Cannot be diagnosed'].indexOf(nextStatus) > -1){
            data.cannotDiagnose =  true;
        }else{
            data.cannotDiagnose = false;
        }
        setLoader(true);
		apiCall({
			url: "submitDiagnosis",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    if (accessFn('GSX_FLOW')) {
                        // if(getCurrentRole() == 'CustomerCare' && self.parent.tags['assign_workshop']){
                        //     self.parent.tags['assign_workshop'].loadEngineersCalled = false;
                        // }
                        getRequestById();
                    }
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    /** Advance Collected */

    const getPaymentModes = (status) => {
		let data = {
			'ConsumerServiceRequestID': consumerServiceRequest.ConsumerServiceRequestID,
			'PartnerID': consumerServiceRequest.PartnerID,
			'PartnerServiceLocationID':consumerServiceRequest.PartnerServiceLocationID,
			'isPaymentReceiveStage':false
		};
		if(['Payment received'].indexOf(status) > -1){
			data.isPaymentReceiveStage = true;
		}

        setLoader(true);
		apiCall({
			url: "getAdvancePaymentModes",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    let paymentModes = result.data.paymentmodes;
                    setPaymentModes(paymentModes);
                    // $('#totalAmountPayable').val(result.data.totalAmountPayable > 0 ?result.data.totalAmountPayable:"");
                    let totalAmt = result.data.totalAmountPayable > 0 ? result.data.totalAmountPayable : ""
                    setPaymentAmount(totalAmt)
                    let advanceAdded = result.data.isAdvanceAdded;
                    setIsAdvanceAdded(advanceAdded)
                    let paymentReceiveStage = result.data.isPaymentReceiveStage;
                    setIsPaymentReceiveStage(paymentReceiveStage)
                    setShowModal({...showModal, capturePaymentModalPickupCarryin: true});
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	}

      // const getInwardOutwardforTransfer = () =>  {	
    //    // isTransferIncomplete = true;	
    //     getPart();	
    //     if (accessFn('GSX_FLOW')) {	
    //        // getGSXChecks(true, false);	
    //     }	
    //     else {	
    //         //setShowModal({...showModal, showServiceLocationModal: true});	
    //     }	
    // }	
    /** Transfer request */	
    const getLocationsForTransfer = () => {	
		let data = {	
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID	
		}	
        setLoader(true);	
        apiCall({	
            url: "getMappedServiceLocations",	
            data: data,	
            callBack: (result) => {	
                setLoader(false);	
                if (result.success) {	
                    if (result.data) {	
                        //setAllTransferLocations(result.data);	
                        //update();	
                        setShowModal({...showModal, showServiceLocationModal: true});	
                        setAllTransferLocations(result.data);	
                    }	
                } else {	
                    Swal.fire({	
                        icon: "error",	
                        text: localString(displayError(result)),	
                    });	
                }	
			}	
		});	
	}	

    /* Receive device from CP, Receive device from Repair Center */	
    const addUpdateStatus = () => {	
		let csrLogs = consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.length
        Swal.fire ({	
			title: 'Are you sure? Please specify remarks, if any...',	
			html: '<textarea id="update-remark" class="cancel-remark"></textarea>',	
			showCancelButton: true,	
			closeOnConfirm: false,	
			allowEscapeKey: false,	
			allowOutsideClick: false,	
			cancelButtonText: localString("Cancel"),	
			confirmButtonText: localString("OK")	
        }).then((result)=>{	
            if (result.isConfirmed) {	
                let remark = document.getElementById('update-remark').value ? document.getElementById('update-remark').value : '';	
                Swal.close();	
                let data = {	
					"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,	
				    "Remarks": remark,	
				};    	
                if(consumerServiceRequest.logs[0].Status == "Sent to repair center") {	
					data.Status = "Device received";	
					data.LogStatus = "Received at repair center";	
				} else if(consumerServiceRequest.logs[0].Status == 'Sent to collection point') {	
					data.LogStatus = "Received at collection point";	
				}else if(consumerServiceRequest.Status == 'Device shipment requested'){
                    data.Status = "Ready for shipment";	
                    data.LogStatus = "Ready for shipment";
                }else if(consumerServiceRequest.Status == "Shipped to service centre"){
                    data.Status = "Device received at service centre";
                    data.LogStatus = "Device received at service centre";
                }
                setLoader(true);
				apiCall({	
					url: 'addUpdateStatus',	
					data: data,		
					callBack: (result) => {	
                    setLoader(false);	
						if (result.success) {	
							if (result.data) {	
								getRequestById();	
							}	
						} else {	
							Swal.fire({	
                            title: '',	
                            text: localString(displayError(result)),	
                            icon: 'error',	
                            confirmButtonText: localString('OK')});	
						}	
					}	
				});	
            }	
        });	
	}

    const addUpdateStatusAPI = (status) => {
        let data = {	
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,	
            LogStatus: "Repair completed and checklist updated"
        };
        setAddUpdateApiLoader(true)
        apiCall({	
            url: 'addUpdateStatus',	
            data: data,	
            callBack: (result) => {	
                setAddUpdateApiLoader(false)
                if (result.success) {	
                    if (result.data) {	
                        getRequestById();	
                    }	
                } else {	
                    Swal.fire({	
                    title: '',	
                    text: localString(displayError(result)),	
                    icon: 'error',	
                    confirmButtonText: localString('OK')});	
                }	
            }	
        });	
    }

    /*Initiate Refund*/ 
    const getPaymentDetailsForRefund = () => {
        setLoader(true);
        let data = {	
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID	
        }	
        apiCall({
            url: 'getPaymentDetails',
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result && result.success && result.data) {
                    let refundDetails = result.data;
                    if (result.data.length > 0) {
                        setShowModal({...showModal, showInitiateRefundModal: true});
                        let arr = refundDetails.map(obj=>{
                            let item = {...obj}
                            item.existingAmount = item.amount
                            return item
                        })
                        setSelectRefundDetails(arr);	
                    }
                    else {
                        Swal.fire({
                            icon: 'info',
                            text: localString("No charges found to refund'"),
                        });
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: localString(displayError(result)),
                    });
                }
            }
        });
    }
    const closeReassignModal = ()=>{
        setShowModal({...showModal,showReassignServiceCenterModal:false}) 
    }
    const CloseServiceApproachModal = ()=>{
        setShowModal({...showModal,showSelectServiceApproachModal:false}) 
    }

    const receivePayment = (status) =>{
		Swal.fire({
			title: localString("Please Enter UTR ID"),
			html: '<textarea id="payment-remark" class="cancel-remark"></textarea>',
			showCancelButton: true,
			closeOnConfirm: false,
			allowEscapeKey: false,
			allowOutsideClick: false,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("OK")
		}).then(function (result) {
			if (result.isConfirmed) {
                let statuses = status;
				var remark = document.getElementById('payment-remark').value ? document.getElementById('payment-remark').value : '';
				if (remark == '') {
					Swal.close();
					Swal.fire(
						'',
						localString('Please Enter UTR ID'),
						'error'
					).then(function(result) {
						if (result.isConfirmed) {
							receivePayment();
						}
					});
				}
				else {
					Swal.close();
					processRequestAfterSwal(statuses,remark)
				}
			}
		});
	};

    const serviceCompleteModal =  (status)=> {
        let isHardwareClosure = find(consumerServiceRequest.logs,{Status: 'Non hardware closure details updated'})
        let isDeviceInspected = find(consumerServiceRequest.logs,{Status: 'Device inspected'})
        if(isHardwareClosure && !isDeviceInspected){
            if(['Initiate logistics request'].indexOf(status) > -1){
                createLogisticRequest();
            }else{
                processRequestNextPickUp(status);
            }
        } else {
            if(consumerServiceRequest.ServiceTypeID == 1 && (['Service completed', 'Service completed and rated'].indexOf(status) > -1)){
                completeService(status);
            }else{
                getPart();
                getGSXChecks(false, false);
            }
        }
    }

    const getActionCategories = () => {
        if (consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductID) {
            let data = {
                "ConsumerServiceRequestID":consumerServiceRequest.ConsumerServiceRequestID
            }
            if(consumerServiceRequest && consumerServiceRequest.consumerProduct){
                data.ProductSubCategoryID = consumerServiceRequest.consumerProduct.ProductSubCategoryID;
                data.ProductID = consumerServiceRequest.consumerProduct.ProductID;
            }

            apiCall({
            url: 'getActionCategories',
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result && result.success ) {
                    if(result.data && result.data.length>0){
                        let actionCategory = result.data;
                        setActionCategoriesList(result.data)
                        const actionMaster = actionCategory && actionCategory.length && actionCategory.map(v=>({...v,value:v.actionMaster.ActionDisplayName}));
                        setSelectActionMaster(actionMaster);
                    }else {
                        Swal.fire('Sorry','No categories found.','error');
                        setShowModal({...showModal,showCompleteAnalyseModal:false});
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: localString(displayError(result)),
                    });
                    setShowModal({...showModal,showCompleteAnalyseModal:false});
                }
            }
        });
        }
    }
    const showActionCategories =() =>{
        getActionCategories();
        setShowModal({...showModal,showCompleteAnalyseModal:true});
    }

    const showActionCategoriesServiceCluster = () => {
        setShowModal({...showModal,showCompleteAnalyseModalServiceCluster:true});
    }

    const refundProcessNext = (params,flag)=>{
        let data = {}
        if (flag) {
            data = params && params
        } 
        else {
            data = {
                "ConsumerServiceRequestID":consumerServiceRequest.ConsumerServiceRequestID,
                "Status": params, //nothing but status
            }
        }
        setLoader(true);	
        apiCall({	
            url: "refundRequestFulfilment",	
            data: data,	
            callBack: (result) => {	
                setLoader(false);	
                if (result.success) {	
                    if (result.data) {	
                        getRequestById();
                        closeModal();	
                    }	
                } else {	
                    Swal.fire({	
                        icon: "error",	
                        text: localString(displayError(result)),	
                    });	
                }	
			}	
		});
    }

    const processRequestNextServiceCluster = (e,status) =>  {
        if(e){
            e.preventDefault();
        }
		let data = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"Status": status,
			"RootCauseAnalysis": completeAnalyseDataServiceCluster.rootCause ? completeAnalyseDataServiceCluster.rootCause : undefined,
			'Resolution': completeAnalyseDataServiceCluster.resolution ? completeAnalyseDataServiceCluster.resolution : undefined
		}
		setLoader(true);
		apiCall({
			url: "srRequestUpdate",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					closeModal();
					getRequestById();
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	}
    const refurbishmentFlowNext = (requestParams,status,remark) =>{
        // let  data = {
        //     "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
        //     "Status":status ? status : nextStatus,
        //     "remarks" : remark
        // };
        let data = {};
        if(requestParams){
            data = requestParams;
        }
        setLoader(true);
		apiCall({
			url: "updateRefurbishmentStatus",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					closeModal();
					getRequestById();
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    const receiveDevice = ()=> {
		var data = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID
		}
        setLoader(true);
		apiCall({
			url: "receiveDeviceFromRC",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					getRequestById();
				} else {
					Swal.fire({
						title: '',
						text: localString(displayError(result)),
						type: 'error',
						confirmButtonText: localString('OK')
					});
				}
			},
		});
    }

    const showRefurbConfirmation = (status)=> {
        let textArea;
        let title;
        let errorTitle;
        let remarkId;
        switch (status) {
            case 'Service cancel':
                remarkId = 'cancel-remark';
                textArea = '<textarea id="cancel-remark" class="cancel-remark"></textarea>';
                title = localString("Please fill in the cancel reason before cancelling");
                errorTitle = localString("Please fill in the cancel reason before cancelling");
                break;
            case 'Repair initiated':
                remarkId = 'initiate-remark';
                textArea = '<textarea id="initiate-remark" class="cancel-remark"></textarea>';
                title = localString("Please fill in the reason for repair initiate.");
                errorTitle = localString("Please fill in the reason for repair initiate.");
                break;
            case  'Repair completed':
                remarkId = 'repair-complete-remark';
                textArea = '<textarea id="repair-complete-remark" class="cancel-remark"></textarea>';
                title = localString("Please fill in the reason for repair completed.");
                errorTitle = localString("Please fill in the reason for repair completed");
                break;
            case 'Service completed':
                remarkId = 'service-completed-remark';
                textArea = '<textarea id="service-completed-remark" class="cancel-remark"></textarea>';
                title = localString("Please Enter Remark");
                errorTitle = localString("Please fill in the reason for complete service");
                break;
            case 'Device received':
                remarkId = 'device-recieve-remark';
                textArea = '<textarea id="device-recieve-remark" class="cancel-remark"></textarea>';
                title = localString("Please enter Approve Remark");
                errorTitle = localString("Please enter Approve Remark before submitting");
                break;
            case 'Parts pending':
                remarkId = 'parts-pending-remark';
                textArea = '<textarea id="parts-pending-remark" class="cancel-remark"></textarea>';
                title = localString("Please specify the pending parts");
                errorTitle = localString(("Please fill in the reason for pending part"));
                break;
            case 'Parts received':
                remarkId = 'parts-recieve-remark';
                textArea = '<textarea id="parts-recieve-remark" class="cancel-remark"></textarea>';
                title = localString("Please specify the recieved parts");
                errorTitle = localString(("Please fill in the reason for recieve part"));
                break;
            case 'Device delivered':
                remarkId = 'device-delivered-remark';
                textArea = '<textarea id="device-delivered-remark" class="cancel-remark"></textarea>';
                title = localString("Please specify the delivered device");
                errorTitle = localString(("Please fill in the reason for recieve part"));
                break;
        }
        Swal.fire({
            title: title,
            html: textArea,
            showCancelButton: true,
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            customClass:'swal-confirmation',
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("OK")
        }).then((result) =>{
            if (result.isConfirmed) {
                let remark = document.getElementById(remarkId).value;
                let newParams = {
                    "consumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
                    "status":status,
                    "remarks": remark ? remark : "",
                    "userID":userID
                };
                setLoader(true);
                apiCall({
                    url: "updateRefurbishmentStatus", 
                    data: newParams,
                    callBack: (result) => {
                        setLoader(false);
                        if (result.success) {
                            getRequestById(remark);
                        }else {
                            Swal.close();
                            Swal.fire({
                                title:'',
                                text:localString("Please fill in the reason before cancelling"),
                                icon:'error'
                            }).then(function(isConfirm) {
                                if (isConfirm) {
                                    showRefurbConfirmation(status);
                                }
                            });
                        }
                    },
                });
            }
        });
    };

    const showRefurbInspectDevice = (status) =>{
        Swal.fire({  
			title: localString("Please enter create shipment Remark"),
			html: '<textarea id="device-inspected-remark" class="cancel-remark"></textarea>',
			showCancelButton: true,
			closeOnConfirm: false,
			allowEscapeKey: false,
			allowOutsideClick: false,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("OK")
        }).then(result => {
            if (result.isConfirmed) {
                let remark = document.getElementById('device-inspected-remark').value;
                //Swal.close();
                if (remark != '') {
                    let newParams = {
                        "consumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
                        "status":status,
                        "remarks": remark,
                        "userID":userID
                    };
                    setLoader(true);
                    apiCall({
                        url: "updateRefurbishmentStatus", 
                        data: newParams,
                        callBack: (result) => {
                            setLoader(false);
                            if (result.success) {
                                getRequestById(remark);
                            }else {
                                Swal.close();
                                Swal.fire({
                                    title:'',
                                    text:localString("Please fill in the reason before cancelling"),
                                    icon:'error'
                                }).then(function(isConfirm) {
                                    if (isConfirm) {
                                        showRefurbConfirmation(status);
                                    }
                                });
                            }
                        },
                    });
                }  else {
                    Swal.close();
                    Swal.fire({
                        title:'Oops...',
                        text:localString("Please enter Approve Remark before submitting"),
                        icon:'error'
                    }).then((result)=> {
                        if (result.isConfirmed) {
                            showRefurbConfirmation(status);
                        }
                    });
                }
            }
        });
    }

    ////refurbishment flow end
    return(
        <>
        { (showLoader || engineerLoader || addUpdateApiLoader) && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
        <div className="col-sm-12  tags-tabs">
            {
                ['Service completed and rated', 'Service completed', 'Service cancel','In-principle approved','Claim withdrawn','Initiate Refund'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) == -1
                && (!accessFn('RECYCLE_ADMIN_VIEW_ONLY')) && hideNextStepInTradeIn && <div className='view-btn-padding'>{localString("Next Steps")}</div>
            }
            {
                ['In-principle approved'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) > -1 && <div className='paddingNext'>{checkDecisionMatrixProcessCompleted() && checkPaymentStatus() ? localString("Waiting for the customer to raise a request") : ''}</div>
            }
            { <ActionButtonWrapper
                actionButtons={statusButtons}
                {...props}
                consumerObj={consumerServiceRequest}
                handleButtonClick={handleButtonClick}
                customCls="view-button-wrapper"
                getDisplayText={getDisplayText}
            />}
            {callLog &&
                <CallHistoryModal
                {...props}
                closeModal={closeModal}
                showPage={showModal.showCallLogModal}
                consumerID={consumerServiceRequest.ConsumerID}
                CSRViewRequest={true}
            />}
            {showModal.showSheduleModal &&<ScheduleButtonModal
                scheduleDateArray={scheduleDateArray}
                {...props} 
                showModal={showModal.showSheduleModal}  
                closeModal={closeModal} 
                selectedScheduleDate={selectedScheduleDate} 
                setSelectedScheduleDate={ setSelectedScheduleDate}
                slotAvailable ={slotAvailable} 
                selectedSlot={selectedSlot}
                setSelectedSlot={ setSelectedSlot}
                scheduleSlotArray={scheduleSlotArray} 
                setScheduleSlotArray={ setScheduleSlotArray}
                setSlotAvailable={setSlotAvailable}
                setShowModal={setShowModal}
                hiddenContent={true}
                setLoader={setLoader}
            />}
            {showBillingModal &&<BillingDetailModal 
                {...props}
                showModal={showBillingModal}  
                closeModal={closeBillingModal} 
                processRequest={preProcessRequest}
                changeBillingDetails={changeBillingDetails}
                selectedBillingDetail={selectedBillingDetail}
                consumerServiceRequest={consumerServiceRequest}
                capturePaymentMode={capturePaymentMode}
                claimChecks = {claimChecks}
                claimRequestNonWorkshop = {claimRequestNonWorkshop}
            />}
            {showModal.showCreateResheduleModal &&<CreateVisitButtonModal 
                {...props}
                setLoader={setLoader}
                closeModal={closeModal}
                showModal={showModal.showCreateResheduleModal}
                selectedScheduleDate={selectedScheduleDate} 
                scheduleDateArray={scheduleDateArray}
                setSelectedScheduleDate={ setSelectedScheduleDate}
                slotAvailable ={slotAvailable} 
                selectedSlot={selectedSlot}
                setSelectedSlot={ setSelectedSlot}
                scheduleSlotArray={scheduleSlotArray} 
                setScheduleSlotArray={ setScheduleSlotArray}
                setSlotAvailable={setSlotAvailable}
                setShowModal={setShowModal}
                filterEngineer={filterEngineer}
                setFilterEngineer={setFilterEngineer}
                selectedEngineerData={engineerData}
            />}
            {showModal.showReassignModal &&<ReassignButtonModal {...props} showModal={showModal.showReassignModal} closeModal={closeModal} />}
            {showModal.showCancelRequestModal &&<CancelRequsetModal 
                {...props} 
                showModal={showModal.showCancelRequestModal} 
                closeModal={closeModal}
                selectedCancelReason={selectedCancelReason}
                cancelReasonsData={cancelReasonsData}
                setFilterCancelrequest={setFilterCancelrequest}
                filterCancelrequest={filterCancelrequest}
                cancelReasonComp={cancelReasonComp}
                preClaimRequest={preClaimRequest}
                disableCancelReasonSave={disableCancelReasonSave}
                cancelText={cancelText}
                setCancelText={setCancelText}
                claimRequest={claimRequest}
                claimFlags={claimFlags}
                continueAfterSelectingReason={(([SERVICETYPE.BER_IDENTIFIED].indexOf(consumerServiceRequest.ServiceTypeID)> -1)) ?  BERcontinueAfterSelectingReason : continueAfterSelectingReason }
                header={header}
                nextStatus = {nextStatus}
                internalReason={internalReason}
                setInternalReason={setInternalReason}
            />}
            {showModal.showUpdateJobModal &&<UpdateJobModal
                {...props}
                updateJobData={updateJobData}
                setUpdateJobData={setUpdateJobData}
                consumerServiceRequest={consumerServiceRequest}
                showModal={showModal.showUpdateJobModal}
                closeModal={closeModal}
                backToJobUpdate={backToJobUpdate}
                time={time}
                visitDispositionLoaded={visitDispositionLoaded}
                jobModalState={jobModalState}
                setJobModalState={setJobModalState}
                fromJobVisitDispositionProd={fromJobVisitDispositionProd}
                toResolvedDisposition={toResolvedDisposition}
                toUnresolvedDisposition={toUnresolvedDisposition}
            />}
            {jobModalState==2 &&
            <ResolveVisitInput
                {...props}
                visitData={visitResolveData}
                setVisitData={setVisitResolveData}
                visitDispositionSelectedFlag={ visitDispositionSelectedFlag}
                setVisitDispositionSelectedFlag={setVisitDispositionSelectedFlag}
                jobModalState={jobModalState}
                closeModal={closeJobUpdateModal}
                setJobModalState={setJobModalState}
                visitDispositionDataPro={visitDispositionDataPro}
                selectVisitDispositionPro={selectVisitDispositionPro}
                visitDispositionProStatus={visitDispositionProStatus}
                backToJobUpdate={backToJobUpdate}
                jumpToIrisSubmission={jumpToIrisSubmission}
                actionStatusProductiveSelected={actionStatusProductiveSelected}
                setActionStatusProductiveSelected={ setActionStatusProductiveSelected}
                // switchFlows={switchFlows}
                setActionCode={setActionCode}
                actionVisitStatus={actionVisitStatus}
                selectActionVisitStatus={selectActionVisitStatus}
            />}
            {
                jobModalState==3 && 
                <UnresolveVisitInput
                    {...props}
                    // showButtonText={true}
                    pendingForSpares={pendingForSpares}
                    selectActionUnresolveVisitStatus={ selectActionUnresolveVisitStatus}
                    actionVisitUnresolveStatus={actionVisitUnresolveStatus}
                    closeModal={closeJobUpdateModal}
                    visitDispositionSelectedFlag={visitDispositionSelectedFlag}
                    setVisitDispositionSelectedFlag={setVisitDispositionSelectedFlag}
                    jobModalState={jobModalState}
                    setJobModalState={setJobModalState}
                    visitDispositionDataUnPro={visitDispositionDataUnPro}
                    selectVisitDispositionUnPro={selectVisitDispositionUnPro}
                    backToJobUpdate={backToJobUpdate}
                    updateJobDetails={updateJobDetails}
                    visitData={visitUnresolveData}
                    jumpToIrisSubmission={jumpToIrisSubmission}
                    setVisitData={setVisitUnresolveData}
                    actionStatusSelected={actionStatusSelected}
                    setActionStatusSelected={ setActionStatusSelected}
                    setActionCode={setActionCode}
                    replacementFlow={replacementFlow}
                    picMaster={ picMaster}
                    consumerServiceRequest={consumerServiceRequest}
                    addDocArray={addDocArray}
                    docArray={docArray}
                    selectedPicType={selectedPicType}
                    otherArrImagesPreview={otherArrImagesPreview}
                    removeAddedDoc={removeAddedDoc}
                />
            }
            {
                jobModalState==4 && <SubmitIrisModal
                    {...props}
                    cancelButtonText ={true}
                    closeModal={closeJobUpdateModal}
                    selectInitIrisSymptom={selectInitIrisSymptom}
                    setSelectInitIrisSymptom={setSelectInitIrisSymptom}
                    selectInitIrisFault={selectInitIrisFault}
                    setSelectInitIrisFault={setSelectInitIrisFault}
                    selectInitIrisAction={selectInitIrisAction}
                    setSelectInitIrisAction={setSelectInitIrisAction}
                    irisMap={irisMap}
                    submitIrisData={submitIrisData}
                    setSubmitIrisData={setSubmitIrisData}
                    showIrisFlags={showIrisFlags}
                    setShowIrisFlags={ setShowIrisFlags}
                    updateJobDetails={updateJobDetails}
                    backToCloseVisit={backToCloseVisit}
                    symptomLoading={symptomLoading}
                    selectedIrisActionFlag={selectedIrisActionFlag}
                    setSelectedIrisActionFlag={setSelectedIrisActionFlag}
                    irisSelection={irisSelection}
                    setIrisSelection={setIrisSelection}
                />
            }
            {
                jobModalState==5  && <CloseVisitPendingModal
                    {...props}
                    backToJobUpdate={backToJobUpdate}
                    closeModal={closeJobUpdateModal}
                    addJobPart={addJobPart}
                    setAddJobPart={setAddJobPart}
                    selectJobParts={selectJobParts}
                    setSelectJobParts={setSelectJobParts}
                    consumerServiceRequest={consumerServiceRequest}
                    partJobFlag={partJobFlag}
                    setPartJobFlag={setPartJobFlag}
                    getAvailableQuantity={getAvailableQuantity}
                    availableQuantity={availableQuantity}
                    price={price}
                    setPrice={setPrice}
                    picMaster={picMaster}
                    addToPartsArray={ addToPartsArray}
                    addDocArray={addDocArray}
                    removeAddedDoc={removeAddedDoc}
                    selectedPicType={selectedPicType}
                    docArray={docArray}
                    otherArrImagesPreview={otherArrImagesPreview}
                    partItem={partItem}
                    setpartItem={setpartItem}
                    partsArray={partsArray}
                    updateJobDetails={updateJobDetails}
                    setPartsArray={setPartsArray}
                    setShowPartLoader={setShowPartLoader}
                    showPartLoader={showPartLoader}
                    showLoader={showLoader}
                    pendingForSpares ={ pendingForSpares }
                />
            }
            {
                showModal.showResolveVisitModal && <ResolveModalInput
                    {...props}
                    nextModal={ nextModal}
                    visitData={visitResolveData}
                    setVisitData={setVisitResolveData}
                    visitDispositionSelectedFlag={ visitDispositionSelectedFlag}
                    setVisitDispositionSelectedFlag={setVisitDispositionSelectedFlag}
                    jobModalState={jobModalState}
                    closeModal={closeModal}
                    setJobModalState={setJobModalState}
                    visitDispositionDataPro={visitDispositionDataPro}
                    selectVisitDispositionPro={selectVisitDispositionPro}
                    visitDispositionProStatus={visitDispositionProStatus}
                    consumerServiceRequest={consumerServiceRequest}
                    updateJobDetails={updateJobDetails}
                    actionStatusProductiveSelected={actionStatusProductiveSelected}
                    setActionStatusProductiveSelected={ setActionStatusProductiveSelected}
                    // switchFlows={switchFlows}
                    setActionCode={setActionCode}
                    actionVisitStatus={actionVisitStatus}
                    selectActionVisitStatus={selectActionVisitStatus}
                />
            }
            {
                showModal.showUnresolveVisitModal && <UnresolvedModalInput
                    {...props}
                    selectActionUnresolveVisitStatus={ selectActionUnresolveVisitStatus}
                    actionVisitUnresolveStatus={actionVisitUnresolveStatus}
                    closeModal={closeModal}
                    visitDispositionSelectedFlag={visitDispositionSelectedFlag}
                    setVisitDispositionSelectedFlag={setVisitDispositionSelectedFlag}
                    visitDispositionDataUnPro={visitDispositionDataUnPro}
                    selectVisitDispositionUnPro={selectVisitDispositionUnPro}
                    updateJobDetails={updateJobDetails}
                    visitData={visitUnresolveData}
                    setVisitData={setVisitUnresolveData}
                    actionStatusSelected={actionStatusSelected}
                    setActionStatusSelected={ setActionStatusSelected}
                    setActionCode={setActionCode}
                    consumerServiceRequest={consumerServiceRequest}
                />
            }
            { showModal.showCancelOnsiteDropdown &&<CancelOnsiteModal 
                {...props}
                closeModal={closeModal}
                showModal={showModal.showCancelOnsiteDropdown}
                selectCancelOnsiteDropdown={selectCancelOnsiteDropdown}
                cancelOnsiteFilterData={cancelOnsiteFilterData}
                setCancelOnsiteFilterData={setCancelOnsiteFilterData}
                cancelServiceWithRemark={cancelServiceWithRemark}
            />}
            { showModal.showCreateTaskModal &&<CreateTaskModal 
                {...props}
                closeModal={closeModal} 
                ratingModalTitle={ratingModalTitle}
                showModal={showModal.showCreateTaskModal}
                selectPriorityType={selectPriorityType}
                setSelectPriorityType={setSelectPriorityType}
                createTaskInputs={ createTaskInputs}
                setCreateTaskInputs={setCreateTaskInputs}
                consumerServiceRequest={consumerServiceRequest}
                setLoader={setLoader}
                setShowModal={setShowModal}
            />}
            {
                showModal.showIrisModal && <SubmitIrisModal
                    {...props}
                    showTextButton={true}
                    closeModal={closeModal}
                    showIrisFlags={showIrisFlags}
                    selectInitIrisSymptom={selectInitIrisSymptom}
                    setSelectInitIrisSymptom={setSelectInitIrisSymptom}
                    selectInitIrisFault={selectInitIrisFault}
                    setSelectInitIrisFault={setSelectInitIrisFault}
                    selectInitIrisAction={selectInitIrisAction}
                    setSelectInitIrisAction={setSelectInitIrisAction}
                    irisMap={irisMap}
                    submitIris={submitIris}
                    submitIrisData={submitIrisData}
                    setSubmitIrisData={setSubmitIrisData}
                    symptomLoading={symptomLoading}
                    selectedIrisActionFlag={selectedIrisActionFlag}
                    setSelectedIrisActionFlag={setSelectedIrisActionFlag}
                    irisSelection={irisSelection}
                    setIrisSelection={setIrisSelection}
                />
            }
            { showModal.showDropOffShipmentModal &&
                <AddShipmentDetailModal 
                    {...props}
                    vender={vender}
                    setVender={setVender}
                    closeModal={closeShipmentModal}
                    venderOptions={venderOptions}
                    setVenderOption={setVenderOption}
                    selectedVenderName={selectedVenderName}
                    setClaimFlags={setClaimFlags}
                    claimFlags={claimFlags}
                    setCurrentShipmentDetail={setCurrentShipmentDetail}
                    currentShipmentDetail={currentShipmentDetail}
                    saveCurrentShipmentDetails={saveCurrentShipmentDetails}
                    refurbishmentFlowCheck = {refurbishmentFlowCheck}
                    nextStatus = {nextStatus}
                />
            }
            {
                <DeviceHandoverAndRecieved
                    {...props}
                    closeModal={closeModal}
                    nextStatus={nextStatus}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    consumerServiceRequest={consumerServiceRequest}
                    setLoader= {setLoader}
                />
            }
            {
                showModal.showInitiateDiagnosis && 
                    <InitiateDiagnosis
                        {...props}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        onCloseClick={closeModal}
                        directDiagnosis={false}
                        showQRSection={true}
                        imeiInput={false}
                        notification={true}
                        getRequestById={getRequestById}
                        csrID={consumerServiceRequest.ConsumerServiceRequestID}
                        ConsumerID={consumerServiceRequest.ConsumerID}
                        ConsumerProductID={consumerServiceRequest.ConsumerProductID}
                    />
            }
            {
                showModal.showDocumentPendingModal &&
                    <DocumentPendingModal
                        {...props}
                        getRequestById={getRequestById}
                        closeModal={closeModal}
                        setLoader={setLoader}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        consumerServiceRequest={consumerServiceRequest}
                    />
            }
            {
                showModal.showDeviceReceivedModal &&
                    <InspectDeviceModal
                        {...props}
                        claimFlags={claimFlags}
                        claimRemoveAddedDoc={claimRemoveAddedDoc}
                        setClaimFlags={setClaimFlags}
                        claimOtherArrImagesPreview={claimOtherArrImagesPreview}
                        // checkListItemChecked={checkListItemChecked}
                        closeModal={closeModal}
                        setLoader={setLoader}
                        picMaster={([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW') ? accessFn('GSX_FLOW') ? picMasterGSX : picMasterNonGSX :claimPicMaster}
                        docArray = { claimDocArray}
                        selectProductFinishedGood={selectProductFinishedGood}
                        claimAddDocArray={claimAddDocArray}
                        claimSelectedPicType={claimSelectedPicType}
                        completeDeviceReceivedClicked ={completeDeviceReceivedClicked }
                        setFinishedGoodID={setFinishedGoodID}
                        isTransferIncomplete = {isTransferIncomplete}
                        pickupCarryinGsxFlag = {pickupCarryinGsxFlag}
                        gsxCheckList = {gsxCheckListPickCarry}
                        setGsxChecklistData = {setGsxChecklistData}
                        selectedSKUList = {selectedSKUList}
                        selectOsVersion = {selectOsVersion}
                        checkListAllData = {checkListAllData}
                        isInward = {isInward}
                        generalElsChecklist = {generalElsChecklist}
                        setGeneralElsChecklist = {setGeneralElsChecklist}
                        accessoriesList = {accessoriesList}
                        setAccessoriesList = {setAccessoriesList}
                        gsxElsCheckList = {gsxElsCheckList}
                        setGsxElsCheckList = {setGsxElsCheckList}
                        miscellaneousList = {miscellaneousList}
                        setMiscellaneousList = {setMiscellaneousList}
                        selectDeviceData = {selectDeviceData}
                        setSelectDeviceData = {setSelectDeviceData}
                        cosmeticStatus = {cosmeticStatus}
                        setCosmeticStatus = {setCosmeticStatus}
                        setSelectedSKU = {setSelectedSKU}
                        claimDocArray = { claimDocArray}
                        otherImageLoader = {otherImageLoader}
                        nextStatus={nextStatus}
                        setGsxCheckListPickCarry = {setGsxCheckListPickCarry}
                    />
            }
            {
                showModal && showModal.showCapturePaymentMode && 
                <CapturePaymentModeModal {...props}
                    closeModal={closeModal}
                    claimRequestNextWs={claimRequestNextWs}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    paymentModeRadio = {paymentModeRadio}
                    setPaymentModeRadio = {setPaymentModeRadio}
                    modeOfPayment={modeOfPayment}
                    setModeOfPayment={setModeOfPayment}
                    type = {type}
                    replacementFulfillment={replacementFulfillment}
                />
            }
            {
                showModal.showPerformQcModal && 
                <PerformQcModal
                    {...props}
                    closeModal = {closeModal}
                    acceptQc={acceptQc}
                    rejectQc={rejectQc}
                    showQCChecklistComplete={showQCChecklistComplete}
                    setShowQCChecklistComplete={setShowQCChecklistComplete}
                    processRequestNext = {processRequestNextPickUp}
                />
            }
            {
                showModal.showPartsUniqueModal && 
                <PartUniqueModal
                    {...props}
                    closeModal = {closeModal}
                    anyPartReturned = {claimFlags.anyPartReturned}
                    isFullBoxUnit = {claimFlags.isFullBoxUnit}
                    defectivePartsRequested = {defectivePartsRequested}
                    partcheck = {partcheck}
                    faultArray = {faultArray}
                    repairComplete = {repairCompletePickUpCarryIn}
                    selectedFault= {selectedFault}
                    showFaultLoader={showFaultLoader}
                    faultCode = {faultCode}
                    setFaultCode = {setFaultCode}
                    addToFaultArray = {addToFaultArray}
                    setSelectedFaultItems={setSelectedFaultItems}
                    removeFaultCode = {removeFaultCode}
                    setDisableAddBtn = {setDisableAddBtn}
                    disableAddBtn={disableAddBtn}
                />
            }
            {
                showModal.showAdditionalCallModal && 
                <CallAttemtModal
                    {...props}
                    showModal={showModal}
                    closeModal={closeModal}
                    setLoader={setLoader}
                    setShowModal={setShowModal}
                />
            }
            {
                showModal.showAdditionalCallModalGSX && 
                <CallAttemtModal
                    {...props}
                    showModal={showModal}
                    closeModal={closeModal}
                    setLoader={setLoader}
                    setShowModal={setShowModal}
                />
            }
            {
                showModal.showSelectOutcomeModal && 
                <SelectOutcomeModal
                    {...props}
                    showModal={showModal}
                    closeModal={closeModal}
                    setLoader={setLoader}
                    setShowModal={setShowModal}
                    consumerServiceRequest={consumerServiceRequest}
                />
            }
            {
                showModal.showFlagFraudsModal && 
                <FlagFraudModal
                    {...props}
                    showModal={showModal}
                    closeModal={closeModal}
                    setLoader={setLoader}
                    setShowModal={setShowModal}
                    addedFraudFlag={addedFraudFlag}
                    flagFraud = {flagFraud}
                    setFlagFraud = {setFlagFraud}
                    consumerServiceRequest={consumerServiceRequest}
                    getRequestById={getRequestById}
                    
                    // selectFraudlistReason={selectFraudlistReason}
                />
            }
            {
                showModal.showDecisionFormModal && 
                    <DecisionFormModal 
                        {...props}
                        showModal = {showModal}
                        closeModal = {closeModal}
                        setLoader = {setLoader}
                        setShowModal = {setShowModal}
                        consumerServiceRequest = {consumerServiceRequest}
                        getRecommendedModes = {getRecommendedModes}
                        showSkipFormConfirmation = {showSkipFormConfirmation}
                        documentsArray = {documentsArray}
                        setDocumentsArray = {setDocumentsArray}
                        decisionFormData = {decisionFormData}
                        setDecisionFormData = {setDecisionFormData}
                        otherSource = {otherSource}
                        setOtherSource = {setOtherSource}
                    />
            }
            {
                showModal.showUpdateLogisticModal && 
                    <UpdateLogisticModal 
                        {...props}
                        showModal = {showModal}
                        closeModal = {closeModal}
                        setLoader = {setLoader}
                        setShowModal = {setShowModal}
                        tradeInDetails={tradeInDetails}
                        tradeInDashboardDetails={tradeInDashboardDetails}
                    />
            }
            {
                showModal.showReplacementOptionsModal && 
                    <ReplacementOptionModal
                        {...props}
                        showModal = {showModal}
                        closeModal = {closeOptionsForm}
                        setLoader = {setLoader}
                        setShowModal = {setShowModal}
                        replacementFlowFlags = {replacementFlowFlags}
                        chooseOptionsMode={replacementFlowFlags.chooseOptionsMode}
                        viewOptionsMode = {replacementFlowFlags.viewOptionsMode}
                        selectOption={selectOption}
                        replacementSumInsured = {replacementSumInsured}
                        selectedReplacement = { selectedReplacement}
                        saveSelectedOption = {saveSelectedOption}
                        currencyCode = {currencyCode}
                        optionsArray = {optionsArray}
                        // processReplacementOptionsObj = {processReplacementOptionsObj}
                        deviceConfigInput = {deviceConfigInput}
                        selectBrandList = {selectBrandList}
                        selectProductList = {selectProductList}
                        getProductList={getProductList}
                        nextStatus = {nextStatus}
                        replacementFulfillment = {replacementFulfillment}
                        consumerServiceRequest = {consumerServiceRequest}
                    />
            }
            {
                showModal.showReplacementDetailsModal &&
                <ReplacementDetailsModal
                    {...props}
                    showModal = {showModal}
                    closeModal = {closeModal}
                    setLoader = {setLoader}
                    setShowModal = {setShowModal}
                    deviceConfigInput = {deviceConfigInput}
                    currencySymbol = {currencySymbol}
                    allocateReplacement = {allocateReplacement}
                    deviceConfigData = {deviceConfigData}
                    deviceConfigVal = {deviceConfigVal}
                    ReplacementPrice={ReplacementPrice}
                />
            }
            {
                showModal.showCollectionDatePicker &&
                <CollectionDatePickerModal
                    {...props}
                    nextStatus = {nextStatus}
                    showModal = {showModal}
                    closeModal = {closeModal}
                    setLoader = {setLoader}
                    setShowModal = {setShowModal}
                    replacementFulfillment = {replacementFulfillment}
                    consumerServiceRequest = { consumerServiceRequest}
                />
            }
            {
                showModal.showUpdateOrderDetailsModal && 
                <UpdateOrderDetailsModal
                    {...props}
                    closeModal = {closeModal}
                    replacementFulfillment = {replacementFulfillment}
                    consumerServiceRequest = { consumerServiceRequest}
                />
            }
            {
                showModal.showPayoutModesModal && <PayoutModesModal
                    {...props}
                    showPayoutModal = {showModal.showPayoutModesModal}
                    setShowPayoutModal = {closeModal}
                    customerEmail = {consumerServiceRequest.consumer && consumerServiceRequest.consumer.EmailID}
                    customerName = {consumerServiceRequest.consumer && consumerServiceRequest.consumer.Name}
                    currencyCode = {currencyCode}
                    consumerServiceRequest = {consumerServiceRequest}
                    onPayoutModeSubmit = {onPayoutModeSubmit}
                    berRefundCase={berChecks ? true : false}
                    ServiceTypeID = {consumerServiceRequest.ServiceTypeID}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            }
            {
                showModal.showInspectDeviceRfModal && <DeviceInspectModal
                    {...props}
                    closeModal = {closeModal}
                    replacementFulfillment = {replacementFulfillment}
                    consumerServiceRequest = { consumerServiceRequest}
                    selectedPicType={claimSelectedPicType}
                    addDocArray = {claimAddDocArray }
                    docArray = { claimDocArray}
                    removeAddedDoc = {claimRemoveAddedDoc}
                    dispositionList = {dispositionList}
                    Status = {nextStatus}
                    refurbishmentFlowNext = {refurbishmentFlowNext}
                    refurbishmentFlowCheck={refurbishmentFlowCheck}
                />
            }
            {
                showModal.showInitiateBERPaymentModal && 
                <InitiateBERPaymentModal
                    {...props}
                    showModal = {showModal}
                    setShowModal = {setShowModal}
                    closeModal = {closeModal}
                    preBerRequestNext = {preBerRequestNext}
                />
            }
            {
                showModal.showBERProductModal &&
                <BERProductModal
                    {...props}
                    showModal = {showModal}
                    setShowModal = {setShowModal}
                    closeModal = {closeBerProductModal}
                    selectProductList={selectProductList}
                    selectBrandList = {selectBrandList}
                    productData = {productData}
                    setProductData = {setProductData}
                    disableBrandEdit = {disableBrandEdit}
                    saveProductDetails = {saveProductDetails}
                />
            }
            {
                showModal.showUTRPymntDateModal && 
                <UTRPymntDateModal
                    {...props}
                    showModal = {showModal}
                    setShowModal = {setShowModal}
                    closeModal = {closeModal}
                    setLoader={setLoader}
                />
            }
            {
                showModal.showIssueResolvedModal && 
                <IssueResolvedModal
                    {...props}
                    closeModal = {closeModal}
                    selectResolvedResonCat = {selectResolvedResonCat}
                    nextStatus = {nextStatus}
                    setLoader = {setLoader}
                    requestEnquiry = {requestEnquiry}
                    issueResolvedText = {issueResolvedText}
                    setIssueResolvedText = {setIssueResolvedText}
                    issueReason = {issueReason}
                    setIssueReason = {setIssueReason}
                />
            }
            {
                showModal.showSelectAddressPopupModal && 
                <SelectAddressPopup
                    {...props}
                    closeModal = {closeModal}
                    setLoader = {setLoader}
                    csrid={consumerServiceRequest.ConsumerServiceRequestID}
                    createBerShipment = {createBerShipment}
                    // countryConfig = {countryConfig}
                />
            }
            {
                showModal.gsxRaiseRequestModal && 
                    <GSXRaiseRequestModal
                        {...props}
                        setLoader={setLoader}
                        showModal={showModal}
                        nextStatus={nextStatus}
                        closeModal={closeModal}
                        setShowModal={setShowModal}
                        tradeInDetails={tradeInDetails}
                        getRequestById={getRequestById}
                        checkLoanerRequest={checkLoanerRequest}
                        repairComplete={repairCompletePickUpCarryInGsx}
                        consumerServiceRequest={consumerServiceRequest}
                        tradeInDashboardDetails={tradeInDashboardDetails}
                        repairNotes={repairNotes} setRepairNotes={setRepairNotes}
                        isSameNotes={isSameNotes} setIsSameNotes={setIsSameNotes}
                        questionDetails={questionDetails}
                        mainQuery={mainQuery}
                        alreadyMarked={alreadyMarked}
                        selectedRepair={selectedRepair}
                        setQuestionDetails={setQuestionDetails}
                        setMainQuery={setMainQuery}
                        setAlreadyMarked={setAlreadyMarked}
                        setSelectedRepair={setSelectedRepair}
                        saveRepairNotes={saveRepairNotes}
                        repairCheckList={repairCheckList}
                        setRepairCheckList={setRepairCheckList}
                        displayNotesModal={displayNotesModal}
                        setDisplayNotesModal={setDisplayNotesModal}
                        receivedDateTime={receivedDateTime}
                        setReceivedDateTime={setReceivedDateTime}
                        raiseGSXRequest={raiseGSXRequest}
                        selectedSPart={selectedSPart}
                        setSelectedSPart={setSelectedSPart}
                        selectedCoverage={selectedCoverage}
                        setSelectedCoverage={setSelectedCoverage}
                        selectedClassification={selectedClassification}
                        setSelectedClassification={setSelectedClassification}
                        closeRepairNotesModal = {closeRepairNotesModal}
                        ServiceApproach = {ServiceApproach}
                    />
            }
            {
                showModal.loanerDeviceDiagnosisModal && 
                    <LoanerDeviceDiagnosisModal
                        {...props}
                        showModal={showModal}
                        setLoader={setLoader}
                        closeModal={closeModal}
                        setShowModal={setShowModal}
                        loanerDetails={loanerDetails}
                        getRequestById={getRequestById}
                        consumerServiceRequest={consumerServiceRequest}
                        fetchLoanerDetailsByCsrId={fetchLoanerDetailsByCsrId}
                        gsxCheckList={gsxCheckList} setGsxCheckList={setGsxCheckList}
                    />
            }
            {
                showModal.loanerPaymentModal && 
                    <LoanerPaymentModal
                        {...props}
                        showModal={showModal}
                        setLoader={setLoader}
                        closeModal={closeModal}
                        nextStatus = {nextStatus}
                        setShowModal={setShowModal}
                        loanerDetails={loanerDetails}
                        getRequestById={getRequestById}
                        loanerPayableAmt={loanerPayableAmt}
                        loanerPaymentModes={loanerPaymentModes}
                        consumerServiceRequest={consumerServiceRequest}
                        fetchLoanerDetailsByCsrId={fetchLoanerDetailsByCsrId}
                    />
            }
            {
                showModal.showNonHardwareClosureModal && 
                    <NonHardwareClosureModal
                        {...props}
                        showModal = {showModal}
                        setShowModal = {setShowModal}
                        closeModal = {closeModal}
                        setLoader = {setLoader}
                        consumerServiceRequest={consumerServiceRequest}
                        getRequestById={getRequestById}
                    />
            }
            {
                showModal.approvalRemarksModal && 
                    <ApprovalRemarksModal
                        {...props}
                        showModal={showModal}
                        setLoader={setLoader}
                        closeModal={closeModal}
                        setShowModal={setShowModal}
                        getRequestById={getRequestById}
                        approvalVariable={approvalVariable}
                        processRequestNext={processRequestNext}
                        consumerServiceRequest={consumerServiceRequest}
                        approvalRemarks={approvalRemarks} setApprovalRemarks={setApprovalRemarks}
                    />
            }
            {
                showModal.rejectReasonModal && 
                    <RejectReasonModal
                        {...props}
                        showModal={showModal}
                        setLoader={setLoader}
                        closeModal={closeModal}
                        setShowModal={setShowModal}
                        getRequestById={getRequestById}
                        processRequestNext={processRequestNext}
                        consumerServiceRequest={consumerServiceRequest}
                        refundRejectionReason={refundRejectionReason} setRefundRejectionReason={setRefundRejectionReason}
                    />
            }
            {
                showModal.serialNumberModal && 
                    <SerialNumberModal
                        {...props}
                        showModal={showModal}
                        setLoader={setLoader}
                        closeModal={closeModal}
                        setShowModal={setShowModal}
                        getRequestById={getRequestById}
                        processRequestNext={processRequestNext}
                        consumerServiceRequest={consumerServiceRequest}
                        serialNumberRefund={serialNumberRefund} setSerialNumberRefund={setSerialNumberRefund}
                    />
            }
            {
                showModal.tokenNumberModal && 
                    <TokenNumberModal
                        {...props}
                        showModal={showModal}
                        setLoader={setLoader}
                        closeModal={closeModal}
                        setShowModal={setShowModal}
                        getRequestById={getRequestById}
                        processRequestNext={processRequestNext}
                        consumerServiceRequest={consumerServiceRequest}
                        refundToken={refundToken} setRefundToken={setRefundToken}
                    />
            }
            {
                showModal.createVisitModal && 
                    <CreateVisitButtonModal
                        {...props}
                        // showModal={showModal}
                        setLoader={setLoader}
                        closeModal={closeModal}
                        showModal={showModal.createVisitModal}
                        selectedScheduleDate={selectedScheduleDate} 
                        scheduleDateArray={scheduleDateArray}
                        setSelectedScheduleDate={ setSelectedScheduleDate}
                        slotAvailable ={slotAvailable} 
                        selectedSlot={selectedSlot}
                        setSelectedSlot={ setSelectedSlot}
                        scheduleSlotArray={scheduleSlotArray} 
                        setScheduleSlotArray={ setScheduleSlotArray}
                        setSlotAvailable={setSlotAvailable}
                        setShowModal={setShowModal}
                        filterEngineer={filterEngineer}
                        setFilterEngineer={setFilterEngineer}
                        selectedEngineerData={engineerData}
                    />
            }
            {
                showModal.diagnosisNotesModal && 
                    <DiagnosisNoteModal
                        {...props}
                        closeModal={closeDiagnosisNotesModal}
                        diagnosisLabel= {diagnosisLabel} setDiagnosisLabel={setDiagnosisLabel}
                        hideDiagnosisNotesModal= {hideDiagnosisNotesModal}
                        GSXDiagnosisModalObj= {GSXDiagnosisModalObj} setGSXDiagnosisModalObj= {setGSXDiagnosisModalObj}
                        diagnosisStepsErrorMessage = {diagnosisStepsErrorMessage}
                        engineerNotesErrorMessage = {engineerNotesErrorMessage}
                    />
            }
            {
                showModal.showDiagnosticsResultModal && 
                    <ShowDiagnosticsResultModal
                        {...props}
                        showModal={showModal}
                        setLoader={setLoader}
                        closeModal={closeModal}
                        setShowModal={setShowModal}
                        diagnosticsResult = {diagnosticsResult}
                    />
            }
            {
                showFMIPModal &&
                    <FMIPModal
                        {...props}
                        showModal = {showModal}
                        closeModal={closeFMIPModal}
                        overrideFMIPCheck = {overrideFMIPCheck}
                    />
            }
            {
                showModal.capturePaymentModalPickupCarryin && 
                    <LoanerPaymentModal
                        {...props}
                        showModal={showModal}
                        setLoader={setLoader}
                        closeModal={closeModal}
                        setShowModal={setShowModal}
                        loanerDetails=""
                        getRequestById={getRequestById}
                        loanerPayableAmt={paymentAmount}
                        setPaymentAmount={setPaymentAmount}
                        loanerPaymentModes={paymentModes}
                        consumerServiceRequest={consumerServiceRequest}
                        fetchLoanerDetailsByCsrId=""
                        paymentModal="PickupCarryinGSX"
                        isAdvanceAdded={isAdvanceAdded}
                        isPaymentReceiveStage={isPaymentReceiveStage}
                        nextStatus={nextStatus}
                    />
            }
            {
                showModal.approveDoaModal && 
                    <ApproveDoaModal
                        {...props}
                        showModal={showModal}
                        setLoader={setLoader}
                        closeModal={closeModal}
                        setShowModal={setShowModal} 
                        partsRequested={partsRequested}
                        getRequestById={getRequestById}
                    />
            }
            {	
                showModal.showUpdateServiceModal && 	
                <UpdateServiceModal 	
                    {...props}	
                    showModal = {showModal}	
                    closeModal = {closeModal}	
                    setLoader = {setLoader}	
                    setShowModal = {setShowModal}	
                    ConsumerID={consumerServiceRequest.ConsumerID}	
                    consumerServiceRequest={consumerServiceRequest}	
                   // tradeInDetails={tradeInDetails}	
                    //tradeInDashboardDetails={tradeInDashboardDetails}	
                />	
            }	
            {	
                showModal.showDoaChecklistModal && 	
                <DoaChecklistModal 	
                    {...props}	
                    showModal = {showModal}	
                    closeModal = {closeModal}	
                    setLoader = {setLoader}	
                    setShowModal = {setShowModal}	
                    ConsumerID={consumerServiceRequest.ConsumerID}	
                    consumerServiceRequest={consumerServiceRequest}	
                />	
            }	
            {	
                showModal.showServiceLocationModal && 	
                <ServiceLocationModal 	
                    {...props}	
                    showModal = {showModal}	
                    closeModal = {closeModal}	
                    setLoader = {setLoader}	
                    setShowModal = {setShowModal}	
                    ConsumerID={consumerServiceRequest.ConsumerID}	
                    consumerServiceRequest={consumerServiceRequest}	
                    allTransferLocations={allTransferLocations}	
                />	
            }
            {	
                showModal.showPlanTypeModal && 	
                <PlanTypeModal
                    {...props}	
                    showModal = {showModal}	
                    closeModal = {closeModal}	
                    setLoader = {setLoader}	
                    setShowModal = {setShowModal}	
                    ConsumerID={consumerServiceRequest.ConsumerID}	
                    consumerServiceRequest={consumerServiceRequest}	
                    //SoldPlanCoverageID={SoldPlanCoverageID}
                />	
            }
            {	
                showModal.showUploadClaimDocumentModal && 	
                    <UploadClaimDocumentModal
                        {...props}	
                        showModal = {showModal}	
                        closeModal = {closeModal}	
                        setLoader = {setLoader}	
                        setShowModal = {setShowModal}	
                        nextStatus={nextStatus}
                        ConsumerID={consumerServiceRequest.ConsumerID}	
                        consumerServiceRequest={consumerServiceRequest}	
                        reqiredDocumentsMaster={reqiredDocumentsMaster}
                    />	
            }
            {
                showModal.showMapGNumberModal &&
                    <MapGNumberModal 
                        {...props} 
                        showModal={showModal} 
                        setLoader={setLoader}
                        closeModal={closeModal}
                        setShowModal={setShowModal} 
                        getRequestById={getRequestById}
                    />
            }
            {
                showModal.showEscalateRequestModal &&
                    <EscalationModal
                        {...props}
                        showModal={showModal}
                        setLoader={setLoader}
                        closeModal={closeModal}
                        setShowModal={setShowModal} 
                        exception={exception}
                        consumerServiceRequest = {consumerServiceRequest}
                        getRequestById = {getRequestById}
                    />
            }
            {showModal.showRepairNotesModal &&
                    <RepairNotesModal
                        {...props}
                        setLoader={setLoader}
                        showModal={showModal.showRepairNotesModal} 
                        submit={() => saveRepairNotes()}
                        closeModal={() => setShowModal({...showModal, showRepairNotesModal: false})}
                        consumerServiceRequest={consumerServiceRequest}
                        repairNotes={repairNotes} 
                        setRepairNotes={setRepairNotes}
                        isSameNotes={isSameNotes} 
                        setIsSameNotes={setIsSameNotes}
                    />
            }
            {
                showModal.showDeviceUniqueModal && 
                    <DeviceUniqueModal 
                        showModal={showModal}
                        closeModal={() => setShowModal({...showModal, showDeviceUniqueModal: false})}
                        setLoader={setLoader}
                        consumerServiceRequest={consumerServiceRequest}
                        partsRequested={partsRequested}
                        repairComplete={repairCompletePickUpCarryInGsx}
                    />
            }
            {
                showModal.showInitiateRefundModal && 	
                    <InitiateRefundModal
                        {...props}	
                        showModal = {showModal}	
                        closeModal = {closeModal}	
                        setLoader = {setLoader}	
                        setShowModal = {setShowModal}	
                        ConsumerID={consumerServiceRequest.ConsumerID}	
                        consumerServiceRequest={consumerServiceRequest}	
                        selectRefundDetails = {selectRefundDetails}
                        setSelectRefundDetails = {setSelectRefundDetails}
                    />	
            }
            {
                showModal.showCreateRefurbModal &&
                    <CreateRefurbModal
                        {...props}	
                        showModal = {showModal}	
                        closeModal = {closeModal}	
                        setLoader = {setLoader}	
                        setShowModal = {setShowModal}
                        consumerServiceRequest={consumerServiceRequest}	
                        showLoader={showLoader}
                    />
            }
            {
                showModal.showCompleteAnalyseModal && 
                <CompleteAnalyseModal
                    {...props}	
                    showModal = {showModal}	
                    closeModal = {closeModal}	
                    setLoader = {setLoader}	
                    setShowModal = {setShowModal}
                    showLoader={showLoader}
                    actionCategoriesList = {actionCategoriesList}
                    selectActionMaster = {selectActionMaster}
                    refundProcessNext = {refundProcessNext}
                    nextStatus = {nextStatus}
                />
            }
            {
                showModal.showCompleteAnalyseModalServiceCluster && 
                <CompleteAnalysisServiceCluster
                    {...props}
                    showModal = {showModal}	
                    closeModal = {closeModal}	
                    setShowModal = {setShowModal}
                    nextStatus = {nextStatus}
                    showLoader={showLoader}
                    completeAnalyseData={completeAnalyseDataServiceCluster} 
                    setCompleteAnalyseData={setCompleteAnalyseDataServiceCluster}
                    processRequestNextServiceCluster={processRequestNextServiceCluster}
                />
            }
            {
                showModal.showReturnToSenderModal &&
                <ReturnToSenderModal
                    {...props}
                    showModal = {showModal}	
                    closeModal = {closeModal}	
                    setShowModal = {setShowModal}
                    nextStatus = {nextStatus}
                    showLoader={showLoader}
                    setLoader={setLoader}
                />
            }
            {showModal.showReassignServiceCenterModal && 
                <ReassignServiceCenterInput
                    {...props}	
                    showModal = {showModal}	
                    closeReassignModal = {closeReassignModal}
                    setLoader = {setLoader}	
                    setShowModal = {setShowModal}
                    consumerServiceRequest={consumerServiceRequest}	
                    showLoader={showLoader}
                    getRequestById = {getRequestById}
                />
            }
            {showModal.showSelectServiceApproachModal && 
                <SelectServiceApproachModal
                    {...props}	
                    showModal = {showModal}	
                    CloseServiceApproachModal = {CloseServiceApproachModal}
                    setLoader = {setLoader}	
                    setShowModal = {setShowModal}
                    consumerServiceRequest={consumerServiceRequest}	
                    showLoader={showLoader}
                    getRequestById = {getRequestById}
                    setServiceApproach={setServiceApproach}
                    checkAndCreateGsxrepair={checkAndCreateGsxrepair}
                />
            }
            {
                showModal.updateKGBModal && 
                    <UpdateKGBModal
                        {...props}
                        showModal={showModal.updateKGBModal}
                        setLoader={setLoader}
                        closeModal={closeModal}
                        setShowModal={setShowModal}
                        getRequestById={getRequestById}
                        processRequestNext={processRequestNext}
                        consumerServiceRequest={consumerServiceRequest}
                        serialNumberRefund={serialNumberRefund} setSerialNumberRefund={setSerialNumberRefund}
                    />
            }
            {
                showModal.ShipToServiceCentreModal && 
                    <ShipToServiceCentreModal
                        {...props}
                        showModal={showModal.ShipToServiceCentreModal}
                        setLoader={setLoader}
                        closeModal={closeModal}
                        setShowModal={setShowModal}
                        getRequestById={getRequestById}
                        consumerServiceRequest={consumerServiceRequest}
                        displayError={displayError}
                        shipToDropOFFLoc={shipToDropOFFLoc}
                        assignToSelf={assignToSelf}
                    />
            }
            {
                showModal.showInitiateBERRefundModal && 
                <BERInitiateRefundModal
                    {...props}
                    showModal={showModal.showInitiateBERRefundModal}
                    setLoader={setLoader}
                    closeModal={closeModal}
                    setShowModal={setShowModal}
                    consumerServiceRequest={consumerServiceRequest}
                    nextStatus= {nextStatus}
                />
            }
            {([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && (consumerServiceRequest.Status == 'Service initiated') && (consumerServiceRequest.IsWarrantyApplicable == null || undefined) && <div className='tat guide-text'>{localString('Please update Warranty applicable status in Device Details section.')}</div>}
            { getNextSteps() ? <p className="tat guide-text pull-left clear-both" >{localString(getNextSteps())}</p>:'' } 
            { berChecks && (consumerServiceRequest && (['Document pending'].indexOf(consumerServiceRequest.Status)> -1 || consumerServiceRequest.PendingDoc == 1) && accessFn('BER_CUSTOMER_ACTION')) ? <p className="tat guide-text pull-left clear-both">{localString("Add required documents in 'Request Documents' section below")}.</p>:''}
            { berChecks && (consumerServiceRequest && adjudicationChecklistData && adjudicationChecklistData.NewChecklist && adjudicationChecklistData.NewChecklist.length && accessFn('EDIT_ADJUDICATION_CHECKLIST') && accessFn('BER_CUSTOMER_ACTION')) ? <p className="tat guide-text pull-left clear-both">{localString("Make sure all the checks are done, as per 'Checklist'")}.</p>:''}

        </div>
        {
            !(accessFn('WORKSHOP_FLOW')) && !(accessFn('READ_ONLY')) && accessFn('PACKAGE_DETAILS') && ([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1) &&  (isEmptyBoxServiceable && (!emptyBoxPackagingDetails || !emptyBoxPackagingDetails.length)) && 
            <p className='packagingM'>{localString("Please update the Shipping Details in the Packaging Materials Details Tab.")}</p>
        }

        </>
    )
}
export default ViewButtonFunctionality;