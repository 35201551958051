import { createSlice } from '@reduxjs/toolkit'
import { clearStore } from '../planSales/planSalesSlice'

const initialState = {
    brandList: [],
    subCategoryList: [],
    uploadHistoryList: []
}

const bulkUploadSlice = createSlice({
    name: 'bulkUpload',
    initialState,
    reducers: {
        setBrandList: (state, { payload }) => {
            state.brandList = payload
        },
        setSubCategoryList: (state, { payload }) => {
            state.subCategoryList = payload
        },
        setUploadHistoryList: (state, { payload }) => {
            state.uploadHistoryList = payload
        }
    },
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
})

export const { setBrandList, setSubCategoryList, setUploadHistoryList } = bulkUploadSlice.actions

export default bulkUploadSlice.reducer
