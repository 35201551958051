import React, { useState } from "react";
import ShadowWrapper from "../../ShadowWrapper";
import { dateFormatter } from "sfy-react";
import ViewDataInModal from "../../ViewDataInModal";
import "../ViewCase.scss";

const ClaimCard = (props) => {
	const { caseDetails, baseUrl, localString } = props;
	const [showMore, setShowMore] = useState(false);
	return (
		<div className="card-outer-wrapper">
			<ShadowWrapper classname="rounded">
				{caseDetails.consumerServiceRequest && (
					<div className="card-wrapper">
						<div className="border-bottom card-header-container">
							<div className="card-heading-wrapper">
								<div className="main-heading">
									{localString("Claim Request")}
								</div>
								<div
									className="sub-heading csr-link"
									onClick={(e) => {
										e.stopPropagation();
										let url = `${baseUrl}/servicerequests/view?csrid=${caseDetails.consumerServiceRequest.ConsumerServiceRequestID}`;
										const newWindow = window.open(
											url,
											"_blank",
											"noopener,noreferrer"
										);
										if (newWindow) newWindow.opener = null;
									}}
								>
									{caseDetails.consumerServiceRequest
										.referenceID || "-"}
								</div>
								<div
									className={`score-tablet ${
										caseDetails.consumerServiceRequest
											.score &&
										parseInt(
											caseDetails.consumerServiceRequest
												.score,
											10
										) > 0
											? "green-tablet"
											: "red-tablet"
									}`}
								>
									{caseDetails.consumerServiceRequest.score ||
										"-"}
								</div>
							</div>
						</div>

						<div className="card-body">
							<div className="row body-heading">
								<div className="col-sm-6">
									<div className="col-sm-3">
										{localString("Claim Status")}
									</div>
									<div className="col-sm-3">
										{localString("Creation Date")}
									</div>
									<div className="col-sm-3">
										{localString("Service Type")}
									</div>
									<div className="col-sm-3">
										{localString("Issue")}
									</div>
								</div>
								<div className="col-sm-6">
									<div className="col-sm-4">
										{localString("Service Center")}
									</div>
									<div className="col-sm-3">
										{localString("Damage Date")}
									</div>
									<div className="col-sm-3">
										{localString("IMEI/Serial No.")}
									</div>
									<div className="col-sm-2"></div>
								</div>
							</div>
							<div className="row body-data">
								<div className="col-sm-6">
									<div className="col-sm-3">
										{caseDetails.consumerServiceRequest
											.status || "-"}
									</div>
									<div className="col-sm-3">
										{caseDetails.consumerServiceRequest
											.createdDate
											? dateFormatter(
													new Date(
														caseDetails.consumerServiceRequest.createdDate
													),
													"dd/mm/yyyy"
											  )
											: "-"}
									</div>
									<div className="col-sm-3">
										{caseDetails.consumerServiceRequest
											.type || "-"}
									</div>
									<div className="col-sm-3">
										{caseDetails.consumerServiceRequest
											.issues &&
										caseDetails.consumerServiceRequest
											.issues.length > 1 ? (
											<ViewDataInModal
												content={`${
													caseDetails
														.consumerServiceRequest
														.issues[0]["issueText"]
												}  +${
													caseDetails
														.consumerServiceRequest
														.issues.length - 1
												}  `}
											>
												<>
													{caseDetails.consumerServiceRequest.issues.map(
														(issue, idx) =>
															idx > 0 && (
																<div>
																	{
																		issue.issueText
																	}
																</div>
															)
													)}
												</>
											</ViewDataInModal>
										) : caseDetails.consumerServiceRequest
												.issues &&
										  caseDetails.consumerServiceRequest
												.issues.length === 1 ? (
											caseDetails.consumerServiceRequest
												.issues[0]["issueText"]
										) : (
											"-"
										)}
									</div>
								</div>
								<div className="col-sm-6">
									<div className="col-sm-4">
										{caseDetails.consumerServiceRequest
											.serviceLocationName || "-"}
									</div>
									<div className="col-sm-3">
										{caseDetails.consumerServiceRequest
											.lossDateTime
											? dateFormatter(
													new Date(
														caseDetails.consumerServiceRequest.lossDateTime
													),
													"dd/mm/yyyy"
											  )
											: "-"}
									</div>
									<div className="col-sm-3">
										{caseDetails?.soldPlan
											?.productUniqueID || "-"}
									</div>
									<div className="col-sm-2">
										<span
											className={
												showMore
													? "glyphicon glyphicon-chevron-up"
													: "glyphicon glyphicon-chevron-down"
											}
										></span>
										<span
											className="reassign-btn"
											onClick={() => {
												setShowMore((prev) => !prev);
											}}
										>
											{showMore ? "Less" : "More"}
										</span>
									</div>
								</div>
							</div>
							{showMore && (
								<>
									<div className="row body-heading">
										<div className="col-sm-3">
											{localString("Damage Type")}
										</div>
										<div className="col-sm-3">
											{localString("Damage Location")}
										</div>
										<div className="col-sm-3">
											{localString("Damage Description")}
										</div>
										<div className="col-sm-3">
											{localString("Incident Type")}
										</div>
									</div>

									<div className="row body-data">
										<div className="col-sm-3">
											{caseDetails.consumerServiceRequest
												.damageType || "-"}
										</div>
										<div className="col-sm-3">
											{caseDetails.consumerServiceRequest
												.placeOfDamage || "-"}
										</div>
										<div className="col-sm-3">
											{caseDetails.consumerServiceRequest
												.descriptionOfLoss || "-"}
										</div>
										<div className="col-sm-3">
											{caseDetails.consumerServiceRequest
												.incidentType || "-"}
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				)}
			</ShadowWrapper>
		</div>
	);
};

export default ClaimCard;
