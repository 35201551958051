import React from 'react';
import {useState,useEffect} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { Button } from 'sfy-react'
import './ViewPlanSalesReport.scss';

function ViewPlanSalesReport(props){
		let [loanerList, setLoanerList] = useState([]);
		let [statusType, setStatusType] = useState('open');
	   
		useEffect(() => {
				getLoanerViewDetails();
		}, [statusType]);

		const changeStatus = (status) => {
				setStatusType(status);
		}
		const getLoanerViewDetails = () => {
				let data = {
						"statusType":  statusType
				}
				let options = {
						method: 'POST', 
						body: JSON.stringify(data),
						headers: {
							'Content-Type' :'application/json;charset=UTF-8'    
						}
					  }
				let url = props.baseUrl +  '/api/v1/ConsumerServicerequest/fetchAllLoanerRequestDetails';
				console.log(url,options);
				fetch(url,options)
				.then((result) => {
								console.log(result);
								//setStatusType(result);
						}
				)
		}
		return (
				<>
						<PageHeader titleHeader={props.titleHeader?props.titleHeader:'Loaner Requests'}/>
						<Button type='button' text='Button Link' classes='button-link' />
				</>
		)

}

export default ViewPlanSalesReport;