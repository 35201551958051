import React from "react";

const LogisticsImages = (props) => {
	const {
		logisticsImages,
		localString,
	} = props;
	return (
		<div className="row paddingTB15">
			{logisticsImages &&
				logisticsImages.length > 0 &&
				logisticsImages.map((image, index) => {
					return (
						<div className="col-sm-3 text-center">
							<div className="col-sm-12 image-container">
								<a href={image.Url} target="_blank">
									<img
										src={image.Url}
										className="logistics-images"
									/>
								</a>
							</div>
							<div className="col-sm-12">
								<label>
									{localString(image.DisplayImageText)}
								</label>
							</div>
						</div>
					);
				})}
		</div>
	);
};

export default LogisticsImages;
