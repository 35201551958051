import React, {useEffect} from "react";
import { returnLocalDateTime } from "../../../../Utility/Utils";

import "./CaseLogs.scss";
import "../InvestigationChecklist/InvestigationChecklist.scss";

const CaseLogs = (props) => {
	const {
		setShowCaseLogs,
		showCaseLogs,
		localString,
		setShowLoader,
		getCaseLogs,
		caseLogs
	} = props;

	const activeColor = (index) =>
		index === 0 ? "stepper-bg-primary" : "stepper-bg-white";
	const isFinalStep = (index) => index === caseLogs.length - 1;

	const createReadableTimeString = (time) => {
		let dateString = returnLocalDateTime(time, "DD-MM-YYYY");
		let timeString = returnLocalDateTime(time, "HH:mm");
		return (
			localString("On") +
			" " +
			dateString +
			" " +
			localString("at") +
			" " +
			timeString
		);
	};

	useEffect(() => {
		if (getCaseLogs) getCaseLogs();
	}, []);

	return (
		<div
			className="investigation-checklist-sidebar"
			style={{
				width: showCaseLogs ? "270px" : "0px",
			}}
		>
			<div className="ic-header">
				<div className="ic-title">{localString("Case Logs")}</div>
				<div
					className="close-icon"
					onClick={() => setShowCaseLogs(false)}
				>
					<span className="glyphicon glyphicon-remove"></span>
				</div>
			</div>
			<div className="ic-body-wrapper">
				{caseLogs && caseLogs.length > 0 ? (
					<div className="stepper-outer-wrapper">
						{caseLogs.map((log, index) => (
							<div
								className={`stepper-container ${
									isFinalStep(index) ? "" : "stepper-border"
								}`}
								key={index}
							>
								<span
									className={`stepper-dot ${
										isFinalStep(index)
											? "stepper-last-dot"
											: ""
									} ${activeColor(index)}`}
								></span>
								<p className={`${index === 0 ? 'current-status' : ''} stepper-status`}>{log.status}</p>
								<p className="stepper-date">
									{createReadableTimeString(log.createdDate)}
								</p>
								<p className="stepper-date">Remarks: {log.remarks || "-"}</p>
								<p className="stepper-date">
									By: {log.Assignee || "-"}
								</p>
							</div>
						))}
					</div>
				) : (
					<div className="text-center">
						<div className="text-center">
							{localString("No Case Logs Available")}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default CaseLogs;
