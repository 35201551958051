import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader,Checkbox,Button} from 'sfy-react';
import Swal from "sweetalert2";
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import {displayError,SERVICETYPE} from '../../../../../Utility/Utils';
import './AdjudicationChecklist.scss'

const AdjudicationChecklist = (props) => {
	const {apiCall,consumerServiceRequest,accessFn,localString,adjudicationChecklistData,getAdjudicationChecklist} = props;
	const [loader, setLoader] = useState(false);
	const [checklistID, setChecklistID] = useState("") 
	const [serviceRequestStatusID, setServiceRequestStatusID] = useState("")
	const [checcklistHistory, setCheccklistHistory] = useState([])
	const [newChecklist, setNewChecklist] = useState([])

	// let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)username\s*\=\s*([^;]*).*$)|^.*$/, "$1");
	let cookieValue = document.cookie.split('; ').find(row => row.startsWith('username=')).split('=')[1];

	useEffect(() => {
		let HistoryList =  adjudicationChecklistData.ChecklistHistory;
		setCheccklistHistory(HistoryList)
		let NewList = adjudicationChecklistData.NewChecklist;
		if(!checklistID ){
			NewList.forEach((item)=>{
				item.isChecked = false;
			})
		}
		setNewChecklist(NewList)
	}, [adjudicationChecklistData])

	const selectChecklist = (item)=>{
		if(item){
			item.isChecked = !item.isChecked;
		}
		setChecklistID("")
		setServiceRequestStatusID("")
		if(item.isChecked){
			let listId = item.ChecklistID;
			let statusId = item.ServiceRequestStatusID;
			setServiceRequestStatusID(statusId)
			setChecklistID(listId)
		}
	}

	const saveAdjudicationChecklist = ()=>{
		let data = {
			ConsumerServiceRequestID:consumerServiceRequest.ConsumerServiceRequestID,
			Status: consumerServiceRequest.Status,
			StatusID: serviceRequestStatusID,           
			ChecklistID: checklistID,
			UserName:cookieValue
		};
		setLoader(true)
		apiCall({
			url:'saveAdjudicationChecklist',
			data: data,
			callBack:(result)=>{
				setLoader(false)
				if(result.success){
					setChecklistID("");
					setServiceRequestStatusID("");
					getAdjudicationChecklist();
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	return (
		<>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper bodyClassName="panel-body-no-padding" title={localString("Checklist")} >
						<div className="request-panel-details-tag adjudication-checklist-div col-sm-12">
						{
							checcklistHistory && checcklistHistory.map(item=>{
								return(
									<div className = "col-sm-12" >
										<div className="checklist-heading">
											<Checkbox
												value={true}
												name='repair-checklist'
												isDisabled={true}
											/>
											<span>{item.Status}</span> <span>( {item.UserName} )</span>
										</div>
										<div className="checklist-questions">
										{
											item.Questions && item.Questions.map(subItem=>{
												return(
													<div>
														<label className="check-label-ques">
															<li>{subItem}</li>
														</label>
													</div>
												)
											})
										}
										</div>
									</div>
								)
							})
						}
						{
							newChecklist && newChecklist.map(item=>{
								return(
									accessFn('EDIT_ADJUDICATION_CHECKLIST') && 
									<div className = "col-sm-12" >
										<div className="checklist-heading">
											<Checkbox
												value={item.isChecked}
												name="newChecklist-repair-checklist"
												id="adju-check"
												isDisabled=""
												handleOnChange={() => {
													selectChecklist(item)
												}}
											/>
											<span>{item.Status}</span>
										</div>
										<div className="checklist-questions">
										{
											item.Questions && item.Questions.map(subItem=>{
												return(
													<div>
														<label className="check-label-ques">
															<li>{subItem}</li>
														</label>
													</div>
												)
											})
										}
										</div>
									</div>
								)
							})
						}
						{
							newChecklist && newChecklist.length>0 && accessFn('EDIT_ADJUDICATION_CHECKLIST') &&
							<div className="col-sm-12">
							{
								checklistID && 
								<Button 
									type="button" 
									name="Save"
									id="Save"
									text={localString("Save")}
									className="btn button-servify "
									handleOnClick={saveAdjudicationChecklist}
								/>
							}
							</div>
						}
						</div>
					</PageContentWrapper>
				</div>
			</div>
		</>
	)
}

export default AdjudicationChecklist
