import React, { useEffect, useState } from 'react';
import { Button, Input, Loader } from 'sfy-react';
import Swal from 'sweetalert2';

import { displayError } from '../../../Utility/Utils';

export default function EnterDeviceAttributes(props) {
	const { 
		apiCall, localString, consumerProduct, setLoader, setConsumerProduct, selectedConsumerProduct, setScreenToViewDiv,
		setSelectedConsumerProduct, consumerProductArray, setConsumerProductArray, filteredConsumerProducts, 
		setFilteredConsumerProducts, showAvailableTypesForCP, showDeviceAttributes, setShowDeviceAttributes
	} = props;
	const [isChanged, setIsChanged] = useState(false);
	const [deviceAttributeArray, setDeviceAttributeArray] = useState(deviceAttributeArray || []);
	const [deviceAttributeArrayCopy, setDeviceAttributeArrayCopy] = useState(deviceAttributeArray || []);

	const handleAttributeChange = (attrName, val) => {
		setIsChanged(true);
		let value = val.replace(/[`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, '');
		let attributeArray = [];
		deviceAttributeArray.map(attrObj => {
			let obj = {...attrObj}
			if(attrObj.AttributeName === attrName) {
				obj.AttributeValue = value;
			}
			attributeArray.push(obj);
		})
		setDeviceAttributeArray(attributeArray);
	}

	const fetchAttributesFromSKU = () => {
		setLoader(true)
		let reqObj = {
			ProductSkuID: selectedConsumerProduct.ProductSkuID
		}
		apiCall({
			url: 'fetchSkuAttributes',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result && result.success) {
					let arr = [];
					let  attributesArray = result && result.data && result.data.data;
					attributesArray.map(o => {
						let obj = {
							AttributeName: o.ProductSubCategoryAttributeName,
							AttributeValue: o.ProductSubCategoryAttributeValue
						}
						arr.push(obj);
					})
					setDeviceAttributeArray(arr);
					setDeviceAttributeArrayCopy(arr);
					showAvailableTypesForCP();
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString("Something went wrong"),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});

	}

	const updateAttibuteArray = () => {
		if(selectedConsumerProduct && selectedConsumerProduct.ProductSkuID) {
			fetchAttributesFromSKU();
		}else if(selectedConsumerProduct && selectedConsumerProduct.SubcategoryAttributes && selectedConsumerProduct.SubcategoryAttributes.length > 0) {
			let arr = [];
			let attributes = selectedConsumerProduct.consumerProductAttributes || [];
			selectedConsumerProduct.SubcategoryAttributes.map(attr => {
				if(!['IMEI/Serial No', 'IMEI/SerialNo', 'SerialNo'].includes(attr.ProductSubCategoryAttributeName)) {
					let obj = {
						AttributeName: attr.ProductSubCategoryAttributeName,
						AttributeValue: ''
					}
					attributes.map(o => {
						if(o.AttributeName === attr.ProductSubCategoryAttributeName) {
							obj.AttributeValue = o.AttributeValue;
						}
					})
					arr.push(obj);
				}
			})
			setDeviceAttributeArray(arr);
			setDeviceAttributeArrayCopy(arr);
			if(!arr.length) {
				showAvailableTypesForCP();
			}
        } else {
			setShowDeviceAttributes(false);
			showAvailableTypesForCP();
		}
	}

	useEffect(() => {
		updateAttibuteArray();
	}, [selectedConsumerProduct]);

	const saveAttributes = () => {
		setLoader(true)
		let reqObj = {
			ConsumerProductID: consumerProduct.ConsumerProductID,
			Attributes: deviceAttributeArray
		}
		apiCall({
			url: 'createConsumerProductAttributes',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result && result.success){
					let obj = {...selectedConsumerProduct};
					obj.consumerProductAttributes = deviceAttributeArray;
					// setConsumerProduct(obj);
					setSelectedConsumerProduct(obj);
					let arr1 = consumerProductArray.map(a => {
						let obj1 = {...a};
						if(a.ConsumerID === selectedConsumerProduct.ConsumerID) {
							obj1.consumerProductAttributes = deviceAttributeArray;
						}
						return obj1;
					})
					let arr2 = filteredConsumerProducts.map(a => {
						let obj2 = {...a};
						if(a.ConsumerID === selectedConsumerProduct.ConsumerID) {
							obj2.consumerProductAttributes = deviceAttributeArray;
						}
						return obj2;
					})
					setConsumerProductArray(arr1);
					setFilteredConsumerProducts(arr2);
					showAvailableTypesForCP();
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString("Something went wrong"),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const attrSubmit = () => {
		let callApi = false;
		deviceAttributeArray.map((item, k) => {
			if(!callApi) {
				if((item.AttributeValue != deviceAttributeArrayCopy[k].AttributeValue)) {
					callApi = true;
				}
			}
		})
		if(isChanged && callApi) {
			saveAttributes();
		}else {
			showAvailableTypesForCP();
		}
	}

	useEffect(() => {
		if(showDeviceAttributes && deviceAttributeArray && deviceAttributeArray.length > 0) {
			setScreenToViewDiv('DeviceAttributes')
		}
	}, [showDeviceAttributes, deviceAttributeArray]);

	return (
		<>
			{
				deviceAttributeArray && deviceAttributeArray.length > 0 && 
				<div id='DeviceAttributes' className="raiseReqSection">
					<div className='enterDeviceAttributes'>
						<div className="form-label">
							<label className="text-capitalize">{localString("Enter Device Attributes")}</label>
						</div>
						<div className="row">
							{
								deviceAttributeArray && deviceAttributeArray.length > 0 &&  deviceAttributeArray.map((attribute, k) => <div className="col-sm-3 attribute-input" key={k}>
								<label className="input-label">{localString(attribute.AttributeName)}</label>
								<div className="form-group">
									<Input
										value={attribute.AttributeValue}
										handleOnChange={(e) => handleAttributeChange(attribute.AttributeName, e.target.value)}
										className={{
											formControl: 'form-group',
											containerClass: 'input-container',
											inputClass: 'input'
										}}
										isDisabled={consumerProduct.ProductSkuID || selectedConsumerProduct.ProductSkuID}
									/>
								</div>
							</div>)
							}
						</div>
						<div className="row">
							{
								(!consumerProduct?.ProductSkuID || !selectedConsumerProduct?.ProductSkuID) && 
									<div className="text-right">
										<Button
											className="btn button-servify skip-btn" 
											onClick={() => showAvailableTypesForCP()}

										>
											{localString('Skip')}
										</Button>
										<Button
											className="btn button-servify nextBtn" 
											onClick={attrSubmit}
										>
											<div className="button-next"></div>
										</Button>
									</div>
							}
						</div>
					</div>
				</div>
			}
			
	</>
	);
}