import React, {useState,useEffect} from 'react'
import ReactDOM from "react-dom";
import {checkMobile,checkAlphaNumeric, checkAlphaNumericSymbol, checkNumberPattern, encrypt, checkExternalRefID, displayError, DiagnosisChannels, checkFlipkartRefID} from '../../Utility/Utils';
import FormGroupInput from '../../components/FormGroupInput/FormGroupInput';
import Swal from "sweetalert2";
import SelectConsumerProduct from '../../components/SelectConsumerProduct/SelectConsumerProduct';
import DivLoaderWrapper from '../../components/DivLoaderWrapper/DivLoaderWrapper';
import InitiateDiagnosis from '../../components/InitiateDiagnosis/InitiateDiagnosis';
import AddConsumerDiagnosis from '../../components/AddConsumerDiagnosis/AddConsumerDiagnosis';

const Diagnosis = (props) => {
    const {apiCall,localString,accessFn,baseUrl} = props;
	const qFilter = JSON.parse(props.qFilter);
    const [customerMobile ,setCustomerMobile] = useState('');
	const [isMobileValid ,setIsMobileValid] = useState(false);
    const [customerImei ,setCustomerImei] = useState('');
	const [isImeiValid ,setIsImeiValid] = useState(false);
    const [jobsheetNo ,setJobsheetNo] = useState('');
    const [isJobsheetValid ,setIsJobsheetValid] = useState(false);
	const [refID ,setRefID] = useState('');
    const [isRefIdValid ,setIsRefIdValid] = useState(false);
    const [mobileRadioSelected, setMobileRadioSelected] = useState(true);
    const [selectedCountryData, setSelectedCountryData] = useState('');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [customerData, setCustomerData] = useState(null);
    const [showCustomerDetails, setShowCustomerDetails] = useState(false);
    const [showConsumerProducts, setShowConsumerProducts] = useState(false);
    const imeiDiagnosisPageUrl = '/consumers/diagnosis';
    const mobileIcon = <div className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 1.01L7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14z"/></svg>
                        </div>;
    const laptopIcon = <div className="svg-icon lap-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2h-4zM4 6h16v10H4V6z"/></svg>
                        </div>;
    const wrapperClass = 'fluid-form-mobileno';
    const productSectionHeader = localString("Select a Product to View Diagnosis");
    const [consumerProductArray, setConsumerProductArray] = useState(false);
    const [showDivLoader, setShowDivLoader] = useState(false);
    const [showDiagnosisModal, setShowDiagnosisModal] = useState(false);
    const [showConsumerModal, setShowConsumerModal] = useState(false);
    const [isFlipkartChannel, setIsFlipkartChannel] = useState(false);
    const [mobileMaxLength, setMobileMaxLength] = useState(10);
    const [specificCountryCode, setSpecificCountryCode] = useState('IN');

    const checkJobsheetNo = (val) => {
        setJobsheetNo(val);
        setIsJobsheetValid(checkAlphaNumeric(val) && (val.length > 9 && val.length < 18) ? true : false);
    };

    const checkCustomerImei = (val) => {
        setCustomerImei(val);
        setIsImeiValid(checkAlphaNumericSymbol(val) && (val.length > 6 && val.length < 31) ? true : false);
    };

    const checkmobileNo = (val, countryData) => {
        setCustomerMobile(val);
        setIsMobileValid(checkMobile(val) && (val.length == mobileMaxLength) ? true : false);
        setSelectedCountryData(countryData)
    };

    const checkRefid = (val) => {
        setRefID(val);
        if(isFlipkartChannel) {
            setIsRefIdValid(checkFlipkartRefID(val) && (val.length == 20) ? true : false);
        } else {
            setIsRefIdValid(checkExternalRefID(val) && (val.length == 19) ? true : false);
        }
    };

    const onJobsheetValid = () => {
        window.open(baseUrl + imeiDiagnosisPageUrl +'?jobsheetno=' + encrypt(jobsheetNo), '_blank');
    }

    const onImeiValid = () => {
        if(checkNumberPattern(customerImei) && [15,16].indexOf(customerImei.length) > -1) {
            window.open(baseUrl + imeiDiagnosisPageUrl +'?imei=' + encrypt(customerImei), '_blank');
        } else {
            window.open(baseUrl + imeiDiagnosisPageUrl +'?sno=' + encrypt(customerImei), '_blank');
        }
    }

    const onRefIdValid = () => {
        window.open(baseUrl + imeiDiagnosisPageUrl +'?refno=' + encrypt(refID) + '&deviceMode=' + (mobileRadioSelected ? 'Mobile' : 'Laptop'), '_blank');
    }

    const formInputs = [
        accessFn('SEARCH_BY_MOBILE') && {
            value: customerMobile,
            maxlength: mobileMaxLength,
            changeFunc: (val, countryData) => checkmobileNo(val, countryData),
            label: localString('Mobile number'),
            isValid: isMobileValid,
            showORSection: accessFn('SEARCH_BY_MOBILE') && accessFn('SEARCH_BY_IMEI_SERIAL'),
            intelInput: true,
            defaultCountry: qFilter && qFilter.countrycode && qFilter.countrycode.length > 0 ? qFilter.countrycode[0].toLowerCase()  : "in",
            successFunc: () => existsByMobileNo(),
            onFlagSelect: (countryData) => onCountryCodeChange(countryData)
        },
        accessFn('SEARCH_BY_IMEI_SERIAL') && {
            value: customerImei,
            maxlength: 30,
            changeFunc: (val) => checkCustomerImei(val),
            label: localString('Serial number/IMEI'),
            isValid: isImeiValid,
            successFunc: () => onImeiValid(),
            showORSection: accessFn('SEARCH_BY_JOBSHEET') && (accessFn('SEARCH_BY_IMEI_SERIAL') || accessFn('SEARCH_BY_MOBILE'))
        },
        accessFn('SEARCH_BY_JOBSHEET') && {
            value: jobsheetNo,
            maxlength: 17,
            changeFunc: (val) => checkJobsheetNo(val),
            label: localString('Jobsheet No./Reference ID'),
            isValid: isJobsheetValid,
            successFunc: () => onJobsheetValid(),
            showORSection: accessFn('SEARCH_BY_REFID') && (accessFn('SEARCH_BY_IMEI_SERIAL') || accessFn('SEARCH_BY_MOBILE') || accessFn('SEARCH_BY_JOBSHEET'))
        },
        accessFn('SEARCH_BY_REFID') && {
            value: refID,
            maxlength: isFlipkartChannel ? 20 : 19,
            changeFunc: (val) => checkRefid(val),
            label: localString('External Reference ID'),
            isValid: isRefIdValid,
            successFunc: () => onRefIdValid(),
            showErrorMesage: true,
            errorMessage: isFlipkartChannel ? localString('Please enter in the format OD123456789123456789') : localString('Please enter in the format 123-1234567-7654321'),
            showSwitchButtons: true,
            switchButtonData: [{
                label: localString('Mobile'),
                isSelected: mobileRadioSelected,
                isMobileIconRequired: true,
                onButtonSwitch: (val) => {setIsRefIdValid(false);mobileRadioChanged(val)},
                type: 'Mobile',
                icon: mobileIcon,
                iconAlignment: 'right'
            }, {
                label: localString('Laptop'),
                isSelected: !mobileRadioSelected,
                isLaptopIconRequired: true,
                onButtonSwitch: (val) => {setIsRefIdValid(false);mobileRadioChanged(val)},
                type: 'Laptop',
                icon: laptopIcon,
                iconAlignment: 'left'
            }]
        }
    ];

    const mobileRadioChanged = (val) => {
        setMobileRadioSelected(val === 'Mobile' ? true : false);
        setRefID('');
    };

    const enableDisableSubmit = () => {
        let validInput = formInputs.find(obj => obj && obj.isValid === true);
        setIsSubmitDisabled(validInput ? false : true);
    };

    const onSubmit = (e) => {
        if (e) {
			e.preventDefault();
		}
        let validInput = formInputs.find(obj => obj.isValid === true);
        resetConsumerDetails();
        if(validInput) {
            validInput.successFunc();
        }
    }

    const getCountries = () => {
		let data = {
			specificCountryCode: specificCountryCode,
            countryConfig: true
		};
		apiCall({
			url:'getCountries',
			data: data,
			callBack:(result)=>{
				if(result.success && result.data){
                    let countryData = result.data.countries[0];
                    setMobileMaxLength(parseInt(countryData.AllowedMobileLengths));
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
    };
    
    useEffect(() => {
        if(qFilter && qFilter.countrycode && qFilter.countrycode.length > 0) {
            setSpecificCountryCode(qFilter.countrycode[0] ? qFilter.countrycode[0] : 'IN');
        }
        if(DiagnosisChannels[qFilter.Channel] === 'FK') {
            setIsFlipkartChannel(true);
        }
    }, []);

    useEffect(() => {
        enableDisableSubmit();
    }, [customerMobile, customerImei, jobsheetNo, refID]);

    useEffect(() => {
        accessFn('SEARCH_BY_MOBILE') && getCountries();
    }, [specificCountryCode]);
    
    const existsByMobileNo = () => {
        let data = {
			CountryCode: selectedCountryData.dialCode,
            MobileNo: customerMobile
        };
        setShowDivLoader(true);
		apiCall({
			url:'existsByMobileNo',
			data: data,
			callBack:(result)=>{
                setShowDivLoader(false);
				if(result.success && result.data){
                    setCustomerData(result.data);
                    setShowCustomerDetails(true);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
    }

    const consumerForm = [
        {
            value: customerData ? customerData.Name : '',
            label: localString('Full Name'),
            isReadOnly: true
        },
        {
            value: customerData ? customerData.EmailID : '',
            label: localString('Email ID'),
            isReadOnly: true
        }
    ]; 

    const onDetailsNext = (e) => {
        if (e) {
			e.preventDefault();
		}
        getConsumerProducts();
    }

    const getConsumerProducts = () => {
        let data = {
			ConsumerID: customerData.ConsumerID,
            IsRegistered: true
        };
        setShowDivLoader(true);
		apiCall({
			url:'getConsumerProductsByBrand',
			data: data,
			callBack:(result)=>{
                setShowDivLoader(false);
				if(result.success && result.data){
                    setShowConsumerProducts(true);
                    setConsumerProductArray(result.data.servicibleConsumerProducts);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
    };

    const resetConsumerDetails = () => {
        setCustomerData(null);
        setShowCustomerDetails(false);
        setShowConsumerProducts(false);
        setConsumerProductArray([]);
    };

    const onProductSelect = (product) => {
        window.open(baseUrl + imeiDiagnosisPageUrl +'?cpid=' + encrypt(product.ConsumerProductID)+'&&pscid=' + encrypt(product.ProductSubCategoryID), '_blank');
    };

    const resetFields = () => {
        setCustomerMobile('');
        setIsMobileValid(false);
        setCustomerImei('');
        setIsImeiValid(false);
        setJobsheetNo('');
        setIsJobsheetValid(false);
        setRefID('');
        setIsRefIdValid(false);
        setMobileRadioSelected(true);
        resetConsumerDetails();
    };

    const onCountryCodeChange = (countryData) => {
        if(countryData && countryData.iso2 && countryData.iso2.toUpperCase() !== specificCountryCode) {
            setSpecificCountryCode(countryData.iso2.toUpperCase());
        }
    };

    return (
        <>
        <div className="fluid-form">
            <div className="fluid-form-content fluid-form-active clearfix">
                {accessFn('ADD_DIAGNOSIS_CUSTOMER') && 
                    <div class="btn button-servify header-btn button-stack text-capital" onClick={() => setShowConsumerModal(true)}>
                        <span class="glyphicon glyphicon-plus" aria-hidden="true"></span> {localString("Add Consumer")}
                    </div>
                }
                <div class="btn button-servify header-btn button-stack text-capital" onClick={resetFields}>
                    {localString("Reset")}
                </div>
                {accessFn('DIRECT_DIAGNOSIS') && 
                    <div class="btn button-servify header-btn button-stack text-capital" onClick={() => setShowDiagnosisModal(true)}>
                        {localString("Initiate App Diagnostics")}
                    </div>
                }
            </div>
        </div>
        <div className="fluid-form">
            <div className="fluid-form-container">
                <FormGroupInput formInputs={formInputs} isSubmitDisabled={isSubmitDisabled} wrapperClass={wrapperClass} onSubmitClick={onSubmit}></FormGroupInput>
                {showCustomerDetails &&
                    <FormGroupInput formInputs={consumerForm} onSubmitClick={onDetailsNext} ></FormGroupInput>
                }
                {showConsumerProducts &&
                    <SelectConsumerProduct 
                        localString={localString}
                        servicibleProducts={consumerProductArray} 
                        productSectionHeader={productSectionHeader}
                        onProductClick={(product) => onProductSelect(product)}
                    ></SelectConsumerProduct>
                }
                {showDivLoader && <DivLoaderWrapper></DivLoaderWrapper>}
            </div>
        </div>
        <InitiateDiagnosis 
            showModal={showDiagnosisModal} 
            onCloseClick={() => setShowDiagnosisModal(false)}
            localString={localString}
            accessFn={accessFn}
            showQRSection={true}
            directDiagnosis={true}
            apiCall={apiCall}
            baseUrl={baseUrl}
            imeiDiagnosisPageUrl={imeiDiagnosisPageUrl}
            {...props}
        ></InitiateDiagnosis>
        <AddConsumerDiagnosis 
            showModal={showConsumerModal} 
            onCloseClick={() => setShowConsumerModal(false)}
            localString={localString}
            accessFn={accessFn}
            apiCall={apiCall}
            baseUrl={baseUrl}
            imeiDiagnosisPageUrl={imeiDiagnosisPageUrl}
        ></AddConsumerDiagnosis>
        </>

    )
}

export default Diagnosis;
