import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Swal from "sweetalert2"; 
import { Button, Dropdown, Loader } from 'sfy-react';

import { displayError } from '../../Utility/Utils';

const SchedulerReportFilter = (props) => {
	const { accessFn, localString, apiCall, qFilter, fetchReportList } = props;
	const reportTypeIds = JSON.parse(qFilter).reportTypes;

	const [reportType, setReportType] = useState('');
	const [reportTypeObj, setReportTypeObj] = useState({});
	const [reportTypeOptions, setReportTypeOptions] = useState([]);
	const [reportTypeLoader, setReportTypeLoader] = useState(false)

	// const [dateRange, setDateRange] = useState({
	// 	startDate: new Date(),
	// 	endDate: new Date()
	// })
	// const [tempDateRange, setTempDateRange] = useState({
	// 	startDate: new Date(),
	// 	endDate: new Date()
	// })

	const handleReportTypeChange = (data) => {
		setReportType(data.Name);
		setReportTypeObj(data);
	}
	
	const getReportsType = () => {
		setReportTypeLoader(true)
		apiCall({
			url: 'getReportType',
			callBack: (result) => { 
				setReportTypeLoader(false)
				if(result.success && result.data){
					let reportTypeArr = result.data.filter(v => {
						if(v.Active && v.isScheduleReport && reportTypeIds.includes(v.ReportTypeID)){
							let obj = v;
							obj.value = localString(v.Name);
							return obj;
						} 
					})
					setReportTypeOptions(reportTypeArr)
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}				
			},
			errorCB: (err) => setReportTypeLoader(false)
		});
	}

	useEffect(() => {
		getReportsType();
	}, [])

	return (
		<>
			{ reportTypeLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="row report-filter">
				<div className="col-sm-12 col-md-4 paddingB10">
					<Dropdown
						id='dropdown'
						value={reportType}
						options={reportTypeOptions}
						handleOnChange={handleReportTypeChange}
						filterBy='value'
						showDownArrow={false}
						placeholder={'Select Report Type'}
						classObject={{
							container: 'report-type-dropdown',
						  }}          
					/>
				</div>
				<div className="col-sm-12 col-md-8 report-filter-right-div">
					<div className='row'>
						{/* <div className='col-sm-12 col-md-5 paddingB10'>
							<DatePicker
								placeholder='DD-MM-YYYY'
								format='dd-mm-yyyy'
								dateRange={tempDateRange || '' }
								handleOnChange={(val) => {
									setTempDateRange(val)
								}}
								showFilterBtn = {true}
								onFilterBtnClick={(val) => setDateRange(tempDateRange)}
								showDateRange={true}
								showPreDefinedRange={true}
							/>
						</div> */}
						<div className='col-sm-12 col-md-3 paddingB10'>
							<Button
								handleOnClick={() => fetchReportList(reportTypeObj.ReportTypeID)}
								isDisabled={!reportType}
								type='button'
								classes='button-ghost report-filter-btn'
							>
								{localString('Search')}
							</Button>
						</div>  
					</div>
				</div>
			</div>       
		</>
	);
};

export default SchedulerReportFilter;