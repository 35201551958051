import React,{useState, useEffect} from 'react';
import {Button, Table} from 'sfy-react';

const AddedParts = (props) => {
    const {item, index, accessFn, localString, consumerServiceRequest, removeAddedPart} = props;
    // const [displayPrice, setDisplayPrice] = useState("")

    // useEffect(() => {
    //     let price = "";
    //     if(item.abused == true){
    //         if(item && item.part && item.part.partPrice){
    //             price = item.part.partPrice.MRP
    //         }
    //     }else{
    //         if(item && item.part && item.part.partPrice){
    //             price = item.part.partPrice.Price
    //         }
    //     }
    //     setDisplayPrice(price)
    // }, [item])

    const classObject = {
		table:"table table-bordered table-condensed table-striped added-parts-details"
	}

    return (
        <Table className={classObject} style={{marginBottom:"20px"}}>
            <Table.Row>
                <Table.Heading className="width40Per">{localString("Part Name")}</Table.Heading>
                {
                    !accessFn('GSX_FLOW') && <Table.Data>{item.Name}</Table.Data>
                }
                {
                    accessFn('GSX_FLOW') && !item.part.substitutePart && <Table.Data>{item.part.Name}-{item.part.PartCode}</Table.Data>
                }
                {
                    accessFn('GSX_FLOW') && item.part.substitutePart && <Table.Data>{item.part.Name}-{item.part.OriginalPartCode}</Table.Data>
                }
            </Table.Row>
            <Table.Row>
            {
                !accessFn('GSX_FLOW') && <Table.Heading  className="width40Per" >{localString("Part Code")}</Table.Heading>
            }
            {
                !accessFn('GSX_FLOW') && <Table.Data>{item.PartCode}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
            {
                !accessFn('GSX_FLOW') && <Table.Heading className="width40Per">{localString("HSN Code")}</Table.Heading>
            }
            {
                !accessFn('GSX_FLOW') && <Table.Data>{item.ChargeCode}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
            {
                accessFn('GSX_FLOW') && item.part.substitutePart &&
                <Table.Heading className="width40Per" >{localString("Substitute Part")}</Table.Heading>
            }
            {
                accessFn('GSX_FLOW') && item.part.substitutePart && 
                <Table.Data>{item.part.substitutePart.Name + ' ' + item.part.substitutePart.PartCode}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
            {
                accessFn('GSX_FLOW') && item.part.secondaryPart &&
                <Table.Heading className="width40Per" >{localString("Secondary Part")}</Table.Heading>
            }
            {
                accessFn('GSX_FLOW') && item.part.secondaryPart && 
                <Table.Data>{item.part.secondaryPart.Name + ' ' + item.part.secondaryPart.PartCode}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
            {	
                accessFn('GSX_FLOW') && 
                <Table.Heading className="width40Per">{localString("Component Code")}</Table.Heading>
            }
            {
                accessFn('GSX_FLOW') && 
                <Table.Data>{item.componentCode.reportedSymptomCode + ' ' + item.componentCode.reportedSymptomDesc}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
            {
                accessFn('GSX_FLOW') &&
                <Table.Heading className="width40Per">{localString("Issue Code")}</Table.Heading>
            }
            {
                accessFn('GSX_FLOW') && 
                <Table.Data>{item.issueCode.reportedIssueCode + ' ' + item.issueCode.reportedIssueDesc}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
            {
                accessFn('GSX_FLOW') && 
                <Table.Heading className="width40Per" >{localString("Reproducibility")}</Table.Heading>
            }
            {
                accessFn('GSX_FLOW') && 
                <Table.Data>{item.comptiaModifier.comptiaDescription}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
            {
                accessFn('GSX_FLOW') && 
                <Table.Heading  className="width40Per">{localString("Is Part Abused")}</Table.Heading>
            }
            {
                accessFn('GSX_FLOW') && 
                <Table.Data>{item.abused ? localString('Yes') : localString('No')}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
            {	
                accessFn('GSX_FLOW') && 
                <Table.Heading className="width40Per">{localString("Is Returnable Damage")}</Table.Heading>
            }
            {
                accessFn('GSX_FLOW') &&
                <Table.Data>{item.returnableDamage ? localString('Yes') : localString('No')}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
            {	
                accessFn('GSX_FLOW') && 
                <Table.Heading className="width40Per">{localString("Is Covered By ACPlus")}</Table.Heading>
            }
            {
                accessFn('GSX_FLOW') &&
                <Table.Data>{item.coveredByACPlus ? localString('Yes') : localString('No')}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
            {	
                accessFn('GSX_FLOW') && 
                <Table.Heading className="width40Per">{localString("KBB Serial Number")}</Table.Heading>
            }
            {
                accessFn('GSX_FLOW') &&
                <Table.Data>{item.isSerialized ? item.serialNumber : '-'}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
            {	
                accessFn('GSX_FLOW') && 
                <Table.Heading className="width40Per">{localString("Use from Consigned Inventory")}</Table.Heading>
            }
            {
                accessFn('GSX_FLOW') &&
                <Table.Data>{item.isConsignment ? localString('Yes') : localString('No')}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
            {	
                accessFn('GSX_FLOW') && 
                <Table.Heading className="width40Per">{localString("KGB Serial Number ")}</Table.Heading>
            }
            {
                accessFn('GSX_FLOW') &&
                <Table.Data>{item.consignmentSerialNumber ? item.consignmentSerialNumber : '-'}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
            {	
                accessFn('GSX_FLOW') && 
                <Table.Heading className="width40Per" >{localString("Pricing Option")}</Table.Heading>
            }
            {
                accessFn('GSX_FLOW') &&
                <Table.Data>{item.pricingOption.pricingOptionDescription}</Table.Data>
            }
            </Table.Row>
            {
                consumerServiceRequest && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId==1 &&
                <Table.Row>
                {	
                    accessFn('GSX_FLOW') && 
                    <Table.Heading className="width40Per" >{localString("Coverage Option")}</Table.Heading>
                }
                {
                    accessFn('GSX_FLOW') &&
                    <Table.Data>{item.partCoverageOption.partCoverageOptionDescription}</Table.Data>
                }
                </Table.Row>
            }
            <Table.Row>
            {	
                accessFn('GSX_FLOW') && 
                <Table.Heading className="width40Per">{localString("Part Warranty")}</Table.Heading>
            }
            {
                accessFn('GSX_FLOW') &&
                <Table.Data >{item.InWarranty ? localString("In warranty") : localString("Out of warranty")}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
                <Table.Heading className="width40Per" >{localString("Part Price")}</Table.Heading>
                {
                    !accessFn('GSX_FLOW') && 
                    <Table.Data>{item.Charge ? item.Charge : "-"}</Table.Data>
                }
                {
                    accessFn('GSX_FLOW') && 
                    // <Table.Data>{displayPrice ? displayPrice : "-"}</Table.Data>
                    <Table.Data>{item.part.displayPrice ? item.part.displayPrice : "-"}</Table.Data>
                }
            </Table.Row>
            <Table.Row>
            {
                !accessFn('GSX_FLOW') && 	
                <Table.Heading className="width40Per">{localString("Remarks")}</Table.Heading>
            }
            {
                !accessFn('GSX_FLOW') && 
                <Table.Data>{item.Remarks ? item.Remarks : "-"}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
                <Table.Heading className="width40Per">{localString("Qty")}</Table.Heading>
                <Table.Data>1</Table.Data>
            </Table.Row>
            <Table.Row>
            {
                !accessFn('GSX_FLOW') && 
                <Table.Heading className="width40Per">{localString("Available Qty")}</Table.Heading>
            }
            {
                !accessFn('GSX_FLOW') && 	
                <Table.Data>{item.AvailableQuantity}</Table.Data>
            }
            </Table.Row>
            <Table.Row>
                <Table.Heading className="width40Per">{localString("Action")}</Table.Heading>
                <Table.Data>
                    <Button 
                        className="btn squareBtnGhost" 
                        handleOnClick={() => removeAddedPart(index, item)}
                    >
                        {localString('Remove')}
                    </Button>
                    {/* <div className=" delete-charges-btn action-button-table" onclick={"removeAddedPart"} style={{cursor:"pointer"}}>
                        {localString("Remove")}
                    </div> */}
                </Table.Data>
            </Table.Row>
        </Table>
    )
}

export default AddedParts