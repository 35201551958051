import React,{useState,useEffect} from 'react';
import {Input, Button, Radio, Modal} from 'sfy-react';
import {forEach,flattenDeep, find} from 'lodash';
import { displayError,returnLocalDateTime} from '../../../../../Utility/Utils';
import Swal from 'sweetalert2';
import './DiagnosisNoteModal.scss';
import moment from 'moment';

const DiagnosisNoteModal = (props) => {
    const {showModal, closeModal, localString, diagnosisLabel, setDiagnosisLabel, hideDiagnosisNotesModal, GSXDiagnosisModalObj, countDiagnosisStepWords, accessFn, setGSXDiagnosisModalObj,diagnosisStepsErrorMessage,engineerNotesErrorMessage} = props;

    return (
        <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} id="diagnosis-notes-modal" className=' diagnosis-notes-modal-main Filter-Modal modal-overflow'>
            <Modal.Header>
                <h4> {localString("Diagnosis details")} </h4>
            </Modal.Header>
            <Modal.Body className="diagnosis-body">
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={(e)=>hideDiagnosisNotesModal(e)} >
                            {
                                diagnosisLabel && diagnosisLabel.map((val,index)=>{
                                    return(
                                        <>
                                            <div className = "diagnosis-row row">
                                                <div className = "col-sm-4">
                                                    <label>{localString(val.Label)}{(val.IsMandatory ? '*' : '')}</label>
                                                </div>
                                                <div className = "col-sm-8">
                                                    <Input
                                                        value={val.value || ''}
                                                        handleOnChange={(e) => {
                                                            let arr = [...diagnosisLabel];
                                                            arr[index].value = e.target.value;
                                                            setDiagnosisLabel(arr);
                                                        }}
                                                        id={val.Input+"_"+index}
                                                        className="diagnosis-complete-input form-control"
                                                        maxLength={50}
                                                        otherProps={{required:val.IsMandatory}}
                                                    />
                                                    <div className = "error-message">
                                                        {val.diagnosisLabelErrorMessage}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </>
                                    )
                                })
                            }
                            <div className="diagnosis-row row">
                                <div className = "col-sm-4">
                                    <label>{localString("Diagnosis Steps")} *</label>
                                </div>
                                <div className = "col-sm-8">
                                    <Input
                                        value={GSXDiagnosisModalObj.diagnosisSteps || ""}
                                        handleOnChange={(e)=>{
                                            setGSXDiagnosisModalObj({...GSXDiagnosisModalObj, diagnosisSteps: e.target.value})
                                        }}
                                        id="diagnosis-steps"
                                        otherProps={{ style: { height:"70px" }}}
                                        minLength={1}
                                        maxLength={1500}   
                                        inputComponent='textarea'
                                        className={{
                                            inputContainer: 'height-auto'
                                        }}
                                    />
                                    {GSXDiagnosisModalObj.diagnosisSteps && GSXDiagnosisModalObj.diagnosisSteps.length>0 && <div style={{height: "20px"}} className="word-left">
                                        {GSXDiagnosisModalObj.diagnosisSteps && GSXDiagnosisModalObj.diagnosisSteps.length>0 && GSXDiagnosisModalObj.diagnosisSteps.length + ' ' + localString("/1500 characters entered.")}
                                    </div>}
                                    <div className = "error-message">
                                        {diagnosisStepsErrorMessage}
                                    </div>
                                </div>
                            </div>
                            <div className="row engineer-notes-row">
                                <div className = "col-sm-4">
                                    {accessFn('REPAIR_DEVICE') && accessFn('ACCEPT_RETURN_DEVICE') && <label>{localString("Notes")} *</label>}
                                    {accessFn('REPAIR_DEVICE') && !accessFn('ACCEPT_RETURN_DEVICE') && <label>{localString("Engineer notes")} *</label>}
                                    {accessFn('ACCEPT_RETURN_DEVICE') && !accessFn('REPAIR_DEVICE') && <label>{localString("Customer Care notes")} *</label>}
                                </div>
                                <div className = "col-sm-8">
                                    <Input
                                        value={GSXDiagnosisModalObj.engineerNotes || ""}
                                        handleOnChange={(e)=>{
                                            setGSXDiagnosisModalObj({...GSXDiagnosisModalObj, engineerNotes: e.target.value})
                                        }}
                                        id="diagnosis-steps"
                                        otherProps={{ style: { height:"70px" }}}
                                        minLength={1}
                                        maxLength={1000}
                                        inputComponent='textarea'
                                        className={{
                                            inputContainer: 'height-auto'
                                        }}
                                    />
                                    {GSXDiagnosisModalObj.engineerNotes && GSXDiagnosisModalObj.engineerNotes.length>0 &&<div style={{height:"20px"}} className="word-left">
                                        {GSXDiagnosisModalObj.engineerNotes && GSXDiagnosisModalObj.engineerNotes.length>0 && GSXDiagnosisModalObj.engineerNotes.length + ' ' + localString("/1000 characters entered.")}
                                    </div>}
                                    <div className = "error-message">
                                        {engineerNotesErrorMessage}
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop:"10px"}}>
                                <Button     
                                    type="submit"
                                    className="btn button-servify pull-right"
                                    text={localString("Save")}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
}

export default DiagnosisNoteModal