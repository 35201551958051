import { log } from 'async';
import React from 'react'
import {Table,Button,Pagination} from 'sfy-react';
import {returnLocalDateTime} from '../../../Utility/Utils';
import './ConsumerListData.scss';

const ConsumerListData = (props) => {
    const {headings,baseUrl,clsName,localString,accessFn,groupedConsumers,setCurrentPage,pagination,callLogFunction,getImei} = props;

    const classObject = {
        td:"td-custom-width"
    }
    return (
		<>
		{
			(!groupedConsumers || groupedConsumers.length <= 0) &&
			<div class="text-center consumer-nodata">
				<div class="empty-state-text sub-list">
					{localString("No Data Available")}
				</div>
			</div>
		}
		{
		groupedConsumers && groupedConsumers.length !=0 &&
        <div>
            <Table isResponsive>
				<Table.Header>
					<Table.Row>
						{headings && headings.length>0 && headings.map((head, index) => {
							return (
								head.showHeading && <Table.Heading key={index} className={clsName}>{head.title}</Table.Heading>
							)
						})}
						<Table.Heading></Table.Heading>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{
                        groupedConsumers && groupedConsumers.map((item,index)=>{
                            return(
                                item && item.title ? 
                                <Table.Row  >
                                    <Table.Data className="group-date" otherProps={{colSpan:"8"}}><strong>{item.title}</strong></Table.Data> 
                                </Table.Row>
                                : 
                                <Table.Row key={index}>
                                    <Table.Data className={classObject.td}>{item.name ? item.name : "-"}</Table.Data>
                                    <Table.Data className={classObject.td}>
                                        {item.countryCode+" "}{item.mobileNo ? item.mobileNo : "-"} 
                                        {item.primaryIdentifier == "MOBILE_NUMBER" ? <p style={{color:"#4c10c1", display:"inline-block", fontWeight:"bold"}}>&nbsp;{localString("(LoginID)")}</p> : ""}
                                    </Table.Data>
                                    <Table.Data className={classObject.td}>
                                        {item.emailID ? item.emailID : "-"}
                                        {item.primaryIdentifier == "EMAIL_ID" ? <p style={{color:"#4c10c1", display:"inline-block", fontWeight:"bold"}}>&nbsp;{localString("(LoginID)")}</p> : ""}
                                    </Table.Data>
                                    <Table.Data>{item.firstRegisteredFrom ? item.firstRegisteredFrom : "-"}</Table.Data>
                                    {
                                        accessFn( 'CONSUMER_DETAILS') && 
                                        <Table.Data >
                                            <div className="button-container custom-setting">
                                                <a href={baseUrl+'/consumers/view?cid='+item.consumerID+getImei()} target="_blank" className="a-decor-none">
                                                    <Button  text={localString('View')} className='btn button-servify '></Button>
                                                </a>
                                                <Button className="btn button-servify icon-hover" >
                                                    {
                                                        accessFn('HIDE_CONSUMER_CALL_LOGS') ? '-':<div className="communication-icon" onClick={()=>callLogFunction(item.consumerID)}></div>
                                                    }
                                                </Button>
                                            </div>
                                        </Table.Data>
                                    }
                                    <Table.Data></Table.Data>
                                </Table.Row> 
                            )
                        })
                    }
				</Table.Body>
			</Table>
            {
                pagination.numberOfPages > 1 && <Pagination 
                    itemsPerPage={pagination.itemsPerPage} 
                    pages={pagination.numberOfPages} 
                    totalItems={pagination.count} 
                    activePage={pagination.pageNo} 
                    paginate={(pageNumber) => {setCurrentPage(pageNumber)}}
                    paginationInfo={pagination.consumerCount+" out of " +pagination.count +" consumer(s) are shown above."}
                />
            }
        </div>
		}
		</>
	)
}

export default ConsumerListData
