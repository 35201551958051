import React from "react";
import "./ActionLink.scss";
function ActionLink(props) {
	const { actionLink, localString } = props;
	return (
		<li>
			{actionLink  && actionLink.length > 0 && actionLink.map((link)=>(
				<>
					{link.showLink && link.showAdvanceReceipt  && link.advanceReceipts.map((item,i)=>(
						<a href={ item.FileUrl && item.FileUrl}
							className=""
							target="_blank"
						>{link.text}{link.advanceReceipts.length > 1 && <span>-{i+1}</span>}</a> 
					))}
					{ link.showLink && link.showPrint && 
						<a href={ link.href && link.href}
							isDisabled={link.disabletBtn}
							className=""
							target="_blank"
							onClick={link.onClick}
						>{link.text}</a> 
					}
				</>
			))}
		</li>
	);
}

export default ActionLink;
