import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
    countriesData: [],
    subCategoryList: [],
    subCategoryObj: {},
    brandList: [],
    brandObj: {},
    protectPlans: [],
    securePlans: [],
    productList: []
}

const planSalesSlice = createSlice({
    name: 'planSales',
    initialState,
    reducers: {
        setCountriesData: (state, { payload }) => {
            state.countriesData = payload
        },
        setSubCategoryList: (state, { payload }) => {
            state.subCategoryList = payload
        },
        setSubCategoryObj: (state, { payload }) => {
            state.subCategoryObj = payload
        },
        setBrandList: (state, { payload }) => {
            state.brandList = payload
        },
        setBrandObj: (state, { payload }) => {
            state.brandObj = payload
        },
        setProtectPlans: (state,{payload}) => {
            state.protectPlans = payload
        },
        setSecurePlans: (state,{payload}) => {
            state.securePlans = payload
        },
        setProductList: (state, {payload}) => {
            state.productList = payload
        }
    },
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState)
})

export const { setBrandList, setSubCategoryList, setCountriesData, setBrandObj, setSubCategoryObj, setProtectPlans, setSecurePlans, setProductList } = planSalesSlice.actions

export const clearStore = createAction('CLEAR_STORE');

export default planSalesSlice.reducer;
