import React from 'react';
import {Modal} from 'sfy-react';
import './CallAttemptModal.scss';
import CallAttemptInput from './CallAttemptInput';
const CallAttemtModal =(props)=>{
    const {localString,showModal,closeModal} = props;
		return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='modal-call-log'>
                <Modal.Header>
                    <h4 className='modal-title'> {localString("Enter Call Details")} </h4>
                </Modal.Header>
                <Modal.Body>
                    <CallAttemptInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default CallAttemtModal;