import React from 'react';
import './DivLoaderWrapper.scss';

const DivLoaderWrapper = () => {
    return (
        <div className="loader-wrapper">
            <div class="loader-figure"></div>
            <p class="loader_label">Loading</p>
        </div>
    )
}

export default DivLoaderWrapper
