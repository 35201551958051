import React,{useEffect} from 'react';
import {Input,Button} from 'sfy-react';
const GsxAuthTokenInput=(props)=>{
    const {localString,gsxUrl,setDisableUpdateGSXAuthToken,gsxUserDetails,authToken,setAuthToken,disableUpdateGSXAuthToken,cancelClick,updateGSXAuthToken,Button} = props;

    useEffect(() => {
        if(authToken.length > 9 && authToken.length <= 100) {
			setDisableUpdateGSXAuthToken(false);
        } else {
			setDisableUpdateGSXAuthToken(true);
        }
    }, [authToken])
    return (
		<div  className='gsx-padding'>
            <div>
                <ol className="col-sm-12">
                    <li><p className="text-bold">{localString("Click on the below link to navigate to the Apple Activation Token Site")}<br/><a href={gsxUrl} target="_blank">{gsxUrl}</a></p></li>
                    <li><p><span className="text-bold">{localString("Use GSX ID and Password to login. Post login, you will be able to view you Activation Token")}</span><span>({localString("Note: Generate the Activation Token for GSX ID mapped to your Servify ID only. GSX ID mapped to your Servify User ID is GSX Email ID")}: <span className="text-bold">{gsxUserDetails.username}</span> | {localString("GSX Tech ID Mapped")}: <span className="text-bold">{gsxUserDetails.techId}</span> )</span></p></li>
                    <li><p><span className="text-bold">{localString('Use the "Copy to Clipboard" button to copy the Activation key')}</span> <br/> <span> ({localString('Note: Use the "Copy to Clipboard" button only. Do NOT select manually to copy Activation Token. You are advised NOT to sign out from Apple Activation Token Site before Activation Token is saved in Servify CRM')})</span></p></li>
                    <li><p className="text-bold">{localString("Paste the Activation Token in the text box below & click Save.")}</p></li>
                </ol>
            </div>
            <div className="row">
                <div className="col-sm-12  ">
                    <div className='diagnosis-row'>
                        <Input
                            value={authToken}
                            handleOnChange={(e) => {
                                setAuthToken(e.target.value)
                            }}
                            label={localString("Activation Token")}
                            labelAlign='top'
                            maxLength="100"
                            placeholder={localString('Enter token here...')}
                        />
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <span  className="tooltip-button" data-toggle="tooltip" data-placement="right" title={localString("Activation Token is adds an additional layer of security while fetching information from GSX. This additional parameter of security has been added by Apple for accessing information using API. For more information, you can get in touch with Servify Administrator.")}><span className="glyphicon glyphicon-info-sign"></span>{localString("Why Generate Activation token")}?</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tabButton-container'>
                <button type="button" disabled={disableUpdateGSXAuthToken} onClick={(e)=>updateGSXAuthToken(e)}  className="btn button-servify pull-right">{localString("Save")}</button>
                <button onClick={()=>cancelClick()} type="button" className="btn button-servify pull-right">{localString("Cancel")}</button>
            </div>
        </div>
	)
}
export default GsxAuthTokenInput;
