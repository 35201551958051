import React, { useState,useEffect } from 'react';
import Swal from 'sweetalert2';
import {Input} from 'sfy-react';
import FlagFraudUI from './FlagFraudUI';
import { displayError } from '../../../../../Utility/Utils';
const FlagFraudInput=(props)=>{
    const {localString,apiCall,setLoader,consumerServiceRequest,addedFraudFlag, setShowModal,showModal,getRequestById,flagFraud,setFlagFraud} = props;
	const [fraudType,setFraudType] = useState([{value:'fraud'},{value:'suspicion'}]);
	const [selectFraudType,setSelectFraudType] = useState([]);
	const [selectFraudlistReason,setSelectFraudlistReason] = useState([]);
	const [disableFlagAddUpdateButton,setDisableFlagAddUpdateButton] = useState(true);
	useEffect(() => {
		let fraudTypeData = fraudType  && fraudType.map(v =>({...v,value:v.value}));
		setSelectFraudType(fraudTypeData);
	}, [])

	useEffect(()=>{
		if(flagFraud.fraudType){
			getFraudReasons()
		}
	},[flagFraud.fraudType])

	useEffect(()=>{
        if(flagFraud.fraudReason && flagFraud.fraudType){
			setDisableFlagAddUpdateButton(false);
        }else{
			setDisableFlagAddUpdateButton(true);
        }
	},[flagFraud.fraudReason , flagFraud.fraudType])
	const getFraudReasons = () =>{
        setLoader(true);
		let data = {
            type: flagFraud.fraudType
        }
		apiCall({
            url: 'listReasons', 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
					let fraudlistReasons = result.data && result.data.map(v=>({...v,value:v.reason}));
					setSelectFraudlistReason(fraudlistReasons);
					setFlagFraud({...flagFraud,fraudReason:''})
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

	const saveFraudFlag = () =>{
        setLoader(true);
		let data = {
			'referenceID':consumerServiceRequest.ReferenceID,
            'type': flagFraud.fraudType,
            'reason': flagFraud.fraudReason,
            'remark': flagFraud.fraudDesc,
        }
        let url = 'createFraudFlag';
        if(addedFraudFlag && addedFraudFlag.flagID){
            url = 'updateFraudFlag';
        }
		apiCall({
            url: url, 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
					getRequestById();
					setShowModal({...showModal,showFlagFraudsModal:false})
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

	const deleteFraudFlag = ()=>{
		if(!flagFraud.fraudDesc){
			Swal.fire ({
                title: '',
                text: localString('Please Enter Remark to delete'),
                icon: 'error',
                confirmButtonText: localString('Ok'),
            });
            return;
        }
		setLoader(true);
        let data = {
			'referenceID':consumerServiceRequest.ReferenceID,
			'remark': flagFraud.fraudDesc,
        }
		apiCall({
            url: 'deleteFraudFlag', 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
					getRequestById();
					setShowModal({...showModal,showFlagFraudsModal:false});
					setFlagFraud({...flagFraud,fraudType:'',fraudReason:'',fraudDesc:''});
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

	const flagFraudInputs=[
		{
			dataKey:'fraudType',
			showFlagFraud:true,
			customClass:'col-sm-3',
			type :'Dropdown',
			placeholder:'Type',
			dropdownList:selectFraudType,
			oninputFunc: (val) => {
				setFlagFraud({...flagFraud,
					['fraudType']:val.value,
				});
				
			}
		},
		{
			dataKey:'fraudReason',
			showFlagFraud:true,
			customClass:'col-sm-3 fraud-reason',
			type :'Dropdown',
			placeholder:'Reason',
			dropdownList:selectFraudlistReason,
			oninputFunc: (val) => {
				setFlagFraud({...flagFraud,
					['fraudReason']:val.reason,
				});
			}
		},
		{
			dataKey:'fraudDesc',
			showFlagFraud:true,
			customClass:'col-sm-3',
			type:'input',
			placeholder:'Description/Notes',
			oninputFunc: (val) => {
				setFlagFraud({...flagFraud,
					['fraudDesc']:val,
				});
			}
		},
	]
		return (
		<>
            <FlagFraudUI
				{...props}
				flagFraudInputs={flagFraudInputs}
				flagFraud = {flagFraud}
				setFlagFraud = {setFlagFraud}
				saveFraudFlag={saveFraudFlag}
				deleteFraudFlag={deleteFraudFlag}
				disableFlagAddUpdateButton={disableFlagAddUpdateButton}
			/>
        </>
	)
}
export default FlagFraudInput;

