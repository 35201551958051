import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
	selectedPSL: {},
	partnerLocationList: [],
	soldPlanList: [],
	planDetails: [],
	transactionSummaryDetails: [],
	dropdownValue: "Today",
	dropdownObj: null,
	dateRange: {
		startDate: "",
		endDate: ""
	},
};

const planSalesDashboardSlice = createSlice({
	name: "planSalesDashboard",
	initialState,
	reducers: {
		setSelectedPSL: (state, { payload }) => {
			state.selectedPSL = payload;
		},
		setPartnerLocationList: (state, { payload }) => {
			state.partnerLocationList = payload;
		},
		setUserDetails: (state, { payload }) => {
			state.soldPlanList = payload;
		},
		setPlanDetails: (state, { payload }) => {
			state.planDetails = payload;
		},
		setTransactionSummaryDetails: (state, { payload }) => {
			state.transactionSummaryDetails = payload;
		},
		setDateRange: (state, { payload }) => {
			state.dateRange = payload;
		},
		setDropDownValue: (state, { payload }) => {
			state.dropdownValue = payload;
		},
		setDropdownObj: (state, { payload }) => {
			state.dropdownObj = payload;
		},
	},
	extraReducers: (builder) => builder.addCase(clearStates, () => initialState),
});

export const {
	setUserDetails,
	setSelectedPSL,
	setPartnerLocationList,
	setPlanDetails,
	setTransactionSummaryDetails,
	setDateRange,
	setDropDownValue,
	setDropdownObj,
} = planSalesDashboardSlice.actions;

export const clearStates = createAction("CLEAR_STORE");

export default planSalesDashboardSlice.reducer;
