import React, { useState,useEffect } from 'react';
import { Button,Modal} from 'sfy-react';

import { SERVICETYPE} from '../../../Utility/Utils';
import ConsentChecklist from '../ConsentChecklist/ConsentChecklist';

export default function ServiceMode(props) {
	const {
		localString,publicUrl, serviceMode, setServiceMode, serviceTypeArray, serviceAvailabilityData, setCallGetScheduleDateAndSlotApi, selectedCountryData, singleServiceLocation,
		checkAvailabilityForPickUp, getServiceLocationsForCarryIn, checkAvailabilityForDropOff,
		setAdvanceChargeApplicable, setShowPayoutModal, hideSection, isEmptyBoxServiceable,
		checkForLogisticsModal, accessFn, currencySymbol, type = 'raiseRequest', activeStepIndex, setActiveStepIndex, setActiveStep, setPickUpMode,
		setCarryInMode, getReplacementOptions,legalConfigsList,setLegalConfigsList, getServiceLocationsForLnT, autoClaimFulfillmentFlow,getPaymentActions,setTokenizeFlow
	} = props;
	const [showLegalConfigConsent, setShowLegalConfigConsent] = useState(false);
	const [callAfterServiceModeClick,setCallAfterServiceModeClick] = useState(false);
	const [showConsentPopUp,setShowConsentPopUp] = useState(false)
	const [unSelectedLegalConfig,setUnSelectedLegalConfig] = useState([])

	const checkConsent = (serviceModeObj) => {
		hideSection(['ReplacementOptions', 'ConsentSection', 'SecondaryAddress', 'SlotSelection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		setServiceMode(serviceModeObj);
		if(serviceModeObj && serviceModeObj.legalConfig && serviceModeObj.legalConfig.length > 0 && serviceModeObj.ServiceTypeID){
			setLegalConfigsList(serviceModeObj.legalConfig)
			setShowLegalConfigConsent(true);
		}else{
			setShowLegalConfigConsent(false);
			setTokenizeFlow(false);
			serviceModeClick(serviceModeObj);
		}
	}

	const checkConsentUnselected = function(){
		let unSelectedLegalConfig = legalConfigsList.filter(obj=> !obj.Selected && obj.UnselectedText)
		if(unSelectedLegalConfig.length){
			setShowConsentPopUp(true);
			setUnSelectedLegalConfig(unSelectedLegalConfig)
		}else{
			if(serviceMode.tokenizeFlow){
				getPaymentActions();
			}else{
				setTokenizeFlow(false);
			}
			serviceModeClick(serviceMode);
		}
	}

	const serviceModeClick = (serviceModeObj) => {
		if(type == 'raiseRequest') {
			if(selectedCountryData && selectedCountryData.countryConfig.SkipAdvance){
				if("IsAdvanceChargeApplicable" in serviceModeObj){
					setAdvanceChargeApplicable(serviceModeObj.IsAdvanceChargeApplicable);
				}else{
					setAdvanceChargeApplicable(serviceAvailabilityData.IsAdvanceChargeApplicable);
				}
			}
			if(serviceModeObj.ServiceTypeID == SERVICETYPE.CARRY_IN && singleServiceLocation) {
				// setShowAddress(false);
				setCallGetScheduleDateAndSlotApi(true);
			}else if(serviceModeObj && serviceModeObj.ServiceTypeID && [SERVICETYPE.PICK_UP].includes(serviceModeObj.ServiceTypeID)) {
				checkAvailabilityForPickUp();
			}else if(serviceModeObj && serviceModeObj.ServiceTypeID && [SERVICETYPE.CARRY_IN].includes(serviceModeObj.ServiceTypeID)) {
				getServiceLocationsForCarryIn(serviceModeObj);
			}else if(serviceModeObj && serviceModeObj.ServiceTypeID && [SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].includes(serviceModeObj.ServiceTypeID)) {
				checkAvailabilityForDropOff();
			}else if(serviceModeObj && serviceModeObj.ServiceTypeID && [SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].includes(serviceModeObj.ServiceTypeID)) {
				// setCallRequestFunction(true);
				getReplacementOptions && getReplacementOptions(serviceModeObj);
			}else if (serviceModeObj && serviceModeObj.ServiceTypeID && [SERVICETYPE.ADVANCE_EXCHANGE].includes(serviceModeObj.ServiceTypeID)) {
				getReplacementOptions && getReplacementOptions(serviceModeObj);
			}else if(serviceModeObj && serviceModeObj.ServiceTypeID && [SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE].includes(serviceModeObj.ServiceTypeID)) {
				setShowPayoutModal(true);
			}else {
				setCallGetScheduleDateAndSlotApi(true);
			}
		}else {
			if(serviceModeObj.ServiceTypeID == SERVICETYPE.CARRY_IN && singleServiceLocation && !accessFn('GSX_FLOW')) {
				checkForLogisticsModal()
				// showServiceTypes = false;
			}else if(serviceModeObj && serviceModeObj.ServiceTypeID && [SERVICETYPE.PICK_UP].includes(serviceModeObj.ServiceTypeID)) {
				setPickUpMode(true);
				setCarryInMode(false);
				checkAvailabilityForPickUp();
			}else if(serviceModeObj && serviceModeObj.ServiceTypeID && [SERVICETYPE.CARRY_IN].includes(serviceModeObj.ServiceTypeID)) {
				setPickUpMode(false);
				setCarryInMode(true);
				if(autoClaimFulfillmentFlow) {
					setActiveStepIndex && setActiveStepIndex(Number(activeStepIndex) + 2);
				}else {
					setActiveStepIndex && setActiveStepIndex(Number(activeStepIndex) + 1);
				}
				setActiveStep && setActiveStep('serviceLocation');
				getServiceLocationsForCarryIn(serviceModeObj);
			}else if(serviceModeObj && serviceModeObj.ServiceTypeID && [SERVICETYPE.CLAIM_DROPOFF].includes(serviceModeObj.ServiceTypeID)) {
				setPickUpMode(false);
				setCarryInMode(true);
				checkAvailabilityForDropOff();
			}else if(serviceModeObj && serviceModeObj.ServiceTypeID && [SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE, SERVICETYPE.ADVANCE_EXCHANGE].includes(serviceModeObj.ServiceTypeID)) {
				// setShowRaiseReplacementRequest(true);
				getReplacementOptions && getReplacementOptions(serviceModeObj);
			}else if(serviceModeObj && serviceModeObj.ServiceTypeID && [SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE].includes(serviceModeObj.ServiceTypeID)) {
				setShowPayoutModal(true);
			}else if(serviceModeObj && serviceModeObj.ServiceTypeID && [SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].includes(serviceModeObj.ServiceTypeID)) {
				getServiceLocationsForLnT && getServiceLocationsForLnT(serviceModeObj);
			}else {
				setCallGetScheduleDateAndSlotApi(true);
				setActiveStepIndex && setActiveStepIndex(activeStepIndex + 1);
				setActiveStep && setActiveStep('dateAndSlotSelection');
			}
		}
	}

	useEffect(() => {
		if(callAfterServiceModeClick) {
			checkConsentUnselected();
			setCallAfterServiceModeClick(false);
		}
	}, [callAfterServiceModeClick]);

	return (
		<>
			<div id='ServiceModes' className="raiseReqSection">
				<div className='serviceModes'>						
					<div className="row">
						<div className="col-sm-10 col-xs-8 form-label">
							<label>{localString("Select a Service Mode")}</label>
						</div>
					</div>
					<div className="row service-mode-container">
						{
							serviceTypeArray && serviceTypeArray.length > 0 && serviceTypeArray.map((obj, k) => 
								<Button
									// className="btn button-servify nextBtn" 
									className="col-sm-3 col-xs-4 mode-button" 
									onClick={() => checkConsent(obj)}
									isDisabled={!obj.IsAvailable}
								>
									<div className={`item subcat-item field-tip ${(serviceMode.ServiceTypeID == obj.ServiceTypeID) ? 'item-active-border': ''} ${!obj.IsAvailable ? 'disabled' : ''}`} >
										<img src={obj.ImageUrl} alt={obj.Type} />
										<p className="text-capitalize">{localString(obj.Type)}</p>
										{
											[SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE].indexOf(obj.ServiceTypeID) > -1 && serviceAvailabilityData.BuyoutAmount &&
												<p>{localString("Reimburse consumer by ")} <span className="buyout-currency" dangerouslySetInnerHTML={{__html: currencySymbol || '' }}></span> {serviceAvailabilityData.BuyoutAmount}</p>

										}
    									{	
											(obj.Description && obj.IsAvailable) && <span className="tip-content">{localString(obj.Description)}</span> }
										{
											!obj.IsAvailable && <span className="tip-content">{localString(obj.UnavailabilityRemark)}</span>
										}
									</div>
								</Button>
							)
						}
						{ isEmptyBoxServiceable && <div className="col-sm-12" style={{ padding: '10px 25px' }}>{localString("For Mail-in service, packaging material will be provided to securely pack your device.")}</div> }
					</div>
					{
							showLegalConfigConsent &&
								<ConsentChecklist
									{...props}
									hideSection={hideSection}
									legalConfigsList={legalConfigsList}
									setLegalConfigsList={setLegalConfigsList}
									setCallRequestFunction={setCallAfterServiceModeClick}
								/>
						}
				</div>
			</div>
			{/* {
                ((type !== 'raiseRequest') && showRaiseReplacementRequest && serviceMode && (serviceMode.ServiceTypeID == SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE)) && 
                    <div className='pull-right' style={{ 'paddingBottom': '10px' }}>
                        <Button 
                            className="btn button-servify no-border-radius"
                            onClick={() => scheduleReplacement()}
                            // isDisabled={disableBtn}
                        >
                            {localString("Raise Request")}
                        </Button>
                    </div>
                        
            } */}
			{
				showConsentPopUp && 
					<Modal
						className='iwDisclaimer'
						showModal={showConsentPopUp}
						closeModal={() => setShowConsentPopUp(false)}
						showCloseBtn={true}
					>
						<Modal.Header>
							<h3 id="myModalLabel" className="modal-title title-spacing">
								<img 
									src={publicUrl + "/imgs/WarningSign.png"} 
									width={'40px'} 
									height={'40px'}
									style={{ marginRight: '5px' }}
								/>
								{localString('Attention')}
							</h3>
						</Modal.Header>
						<Modal.Body>
							<div className="modal-body">
							<div>
								{unSelectedLegalConfig &&
									unSelectedLegalConfig.length > 0 &&
									unSelectedLegalConfig.map(
										(legalConfig, idx) => (
											<label
												key={legalConfig.ApplicationLegalConfigID}
												for={`consent-input-${idx}`}
												style={{ marginBottom: "5px" }}
											>
												{legalConfig.UnselectedText}
											</label>
										)
									)}
							</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<div className="form-group-filter-request text-center">
								<Button 
									className="btn button-servify-solid submit-btn" 
									onClick={() => setShowConsentPopUp(false)}
								>
									{localString("Cancel")}
								</Button>
								<Button 
									className="btn button-servify-solid submit-btn save-submit" 
									onClick={() => {
										if(serviceMode.tokenizeFlow){
											getPaymentActions();
										}else{
											setTokenizeFlow(false);
										}
										serviceModeClick(serviceMode);
										setShowConsentPopUp(false)
									}}
								>
									{localString("OK")}
								</Button>
							</div>
						</Modal.Footer>
					</Modal>
			}
		</>
	);
}