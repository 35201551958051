import React from "react";
import "./ActionButtonWrapper.scss";
import {Button} from 'sfy-react';

function ActionButtonWrapper(props) {
	const { actionButtons, localString,customCls, handleButtonClick,getDisplayText} = props;
	return (
		<div className={customCls ? customCls : "action-buttons-wrapper"}>
			{actionButtons && actionButtons.length > 0 && actionButtons.map((button, index) => {
				return (
					<>
						{(!button.type || button.type == "button") && (
							!button.url && !button.text && <Button
								handleOnClick={(e) => {
									let btnName = button.name ? button.name : button;
									button.onClick ? button.onClick(e, btnName) : handleButtonClick(e, btnName);
								}}
								isDisabled={button.disabletBtn}
								classes="button-ghost button-small no-border-radius button-text-capitalize"
								name={button.name ? button.name : button}
							>
							{localString(button.text || button.displayText || getDisplayText(button) || button)}
							</Button>
						)}
						{
							button && button.url && 
							<a href={button.url}
								style={{padding:"8px 18px"}}
								className="button-ghost button-small no-border-radius no-underline"
								target="_blank"
							>{button.text}</a>
						}
						{button.type == "link" && (
							<a href={button.onClick}
								isDisabled={button.disabletBtn}
								className="button-ghost button-small no-border-radius no-underline"
								target="_blank"
							>{button.text}</a>
						)}
					</>
				);
			})}
		</div>
	);
}

export default ActionButtonWrapper;
