import React, { useState } from 'react';
import {Modal,Button,DatePicker,Input} from 'sfy-react';
import Swal from "sweetalert2";
import moment from 'moment';
import Datetime from 'react-datetime';
import { filter } from 'lodash';
const UpdateJob=(props)=>{
    const {localString,updateJobData,consumerServiceRequest,jobModalState,isAllTimeEntered,checkAllTime,filterInputs,toResolvedDisposition,toUnresolvedDisposition,time,setUpdateJobData}=props;
    return(
        <>
            {jobModalState==1 && <div className='modal-body'>
                <div className='form-group register-filter'>
                    <div className='row'>
                        {
                            filterInputs && filterInputs.datePicker && filterInputs.datePicker.map(item=>(
                                <div className='col-md-6 form-group'>
                                    <div className='col-md-3 padding0 text-label'><label className=''>{localString(item.labelText)}</label></div>
                                    <div className='col-md-9 padding0'> 
                                        <DatePicker
                                            date={ updateJobData[item.dataKey]}
                                            placeholder={item.placeholder}
                                            format="dd/mm/yyyy"
                                            maxDay={item.maxDay}
                                            minDay={item.minDay}
                                            handleOnChange={(date) => {
                                                item.oninputFunc(date);
                                            }}
                                        />
                                    </div>
                                </div> 
                            ))
                        }
                    </div>
                    <div className='row'>
                        {
                            filterInputs && filterInputs.details && filterInputs.details.map(item=>(
                                <>
                                {
                                    item.mainlabelText && <div className='main-label'> 
                                        <label>{item.mainlabelText}</label>
                                    </div>
                                }
                                <div className='col-md-6 form-group'>
                                    <div className='col-md-3 padding0 text-label'><label className=''>{localString(item.labelText)}</label></div>
                                        <div className='col-md-9 padding0'> 
                                        <Datetime
                                            inputProps={ {placeholder:item.placeholder}}
                                            value={updateJobData[item.dataKey]}
                                            dateFormat={item.dateFormat}
                                            timeFormat={item.timeFormat}
                                            closeOnSelect={true}
                                            onChange={(val) => 
                                                item.oninputFunc(moment(new Date(val)).format("HH:mm"))
                                            }
                                            defaultValue={updateJobData[item.dataKey]}
                                            
                                            isValidDate={(current) => {
                                                if(item && item.maxDay && item.minDay) {
                                                    return current.isAfter(item.minDay) && current.isBefore(item.maxDay)
                                                }else if(item && item.maxDay) {
                                                    return current.isBefore(item.maxDay)
                                                }else if(item && item.minDay) {
                                                    return current.isAfter(item.minDay)
                                                }else {
                                                    return true;
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                </> 
                            ))
                        }
                    </div>
                    
                    <div class="row">
                        <div class="col-sm-12">
                            <label>{localString("Note : Time must be in between")} {time.starttime} - {time.endtime}</label>
                        </div>
                    </div>
                </div> 
                <div className='modal-footer paddingR0'>
                    <Button className='btn button-servify  pull-right' handleOnClick={()=>toResolvedDisposition()} isDisabled={isAllTimeEntered}>{localString("Resolved Visit")}</Button>
                    <Button className='btn button-servify  pull-right' handleOnClick = {toUnresolvedDisposition} isDisabled={isAllTimeEntered}>{localString("Unresolved Visit")}</Button>
                </div>
            </div>}
        </>
    )
}
export default  UpdateJob;


