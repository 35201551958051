import React from 'react';
import { Button, Modal } from 'sfy-react';

export default function LogisticsPartnerModal(props) {
	const {
		localString, vendor, vendorList, setVendor, setDeliveryMode, showSlotSelection, checkAvailabilityForDropOff, showLogisticsPartnerModal, setShowLogisticsPartnerModal
	} = props;

	const logPartnerSelected = (obj) => {
		setVendor(obj);
		setDeliveryMode(obj.VendorID);
	}

	const logPartnerSubmit = () => {
		checkAvailabilityForDropOff();
		if(showSlotSelection) {
            // setScreenToViewDiv('scheduleDateAndTime');
        }
		setShowLogisticsPartnerModal(false);
	}

	return (
		<>
			<Modal
				className='LogisticsPartnerModal w-600'
				showModal={showLogisticsPartnerModal}
				closeModal={() => setShowLogisticsPartnerModal(false)}
				showCloseBtn={true}
			>
				<Modal.Header>
					<h4 className="modal-title">{localString("Select Logistics Partner")}</h4>
				</Modal.Header>
				<Modal.Body>
					<div className='request-filter'>
						<table className="table noborder table-hover">
							<tbody>
								{
									vendorList && vendorList.map(vendorObj => 
										<tr className="noborder">
											<td className="noborder">
												<input type="radio" name="logisticsPartner" onClick={() => logPartnerSelected(vendorObj)} checked={vendorObj.VendorID == vendor.VendorID} value={vendorObj.VendorID}/>
											</td>
											<td className="noborder">{vendorObj.DisplayName}{ vendorObj.VendorRemark && <span> - {vendorObj.VendorRemark}</span>} {vendorObj.Priority == 1 && <span>({localString('Recommended')}) </span> }
											</td>
										</tr>
									)
								}
							</tbody>
						</table>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button 
						className="btn button-servify" 
						onClick={() => logPartnerSubmit()}
					>
						{localString("Confirm")}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}