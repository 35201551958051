import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, DotProgressBar, Loader } from 'sfy-react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { filter, findIndex } from 'lodash';

import { displayError, encrypt, getLocaleDateString, returnLocalSlot, returnLocalTime, SERVICETYPE } from '../../../../../Utility/Utils';
import MultiStepProgressBar from '../../../../../components/MultiStepProgressBar/MultiStepProgressBar';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import Address from '../../../../RaiseRequest/Address/Address';
import CarryInTypeSelection from '../../../../RaiseRequest/CarryInTypeSelection/CarryInTypeSelection';
import InstructionModal from '../../../../RaiseRequest/Instructions/InstructionModal';
import LogisticsPartnerModal from '../../../../RaiseRequest/LogisticsPartnerModal/LogisticsPartnerModal';
import ServiceLocation from '../../../../RaiseRequest/ServiceLocation/ServiceLocation';
import SlotSelection from '../../../../RaiseRequest/SlotSelection/SlotSelection';
import ServiceMode from '../../../../RaiseRequest/ServiceMode/ServiceMode';
import PayoutModesModal from '../../../../../components/PayoutModesModal/PayoutModesModal';
import ReplacementOptions from '../../../../RaiseRequest/ReplacementOptions/ReplacementOptions';

import './AdminRaiseRequest.scss'

export default function AdminRaiseRequest(props) {

	const { 
		localString, apiCall, accessFn, baseUrl, viewUrl, consumerServiceRequest, countriesChecks, selectedCountryData, 
		soldPlanCoverage, claimDamageId, planID, currencySymbol, isSubscriptionEnabled
	} = props;


	const [pickUpMode, setPickUpMode] = useState(true);
	const [carryInMode, setCarryInMode] = useState(true);
	const [autoClaimFulfillmentFlow, setAutoClaimFulfillmentFlow] = useState(consumerServiceRequest?.autoClaimFulfillmentFlow);
	const pickUpModeArr = [localString("Address"), localString("Service Mode"), localString("Slot and Date")];
	const carryInModeArr = [localString("Address"), localString("Service Mode"), localString("Service Location"), localString("Slot and Date")];
	const autoClaimFulfillmentFlowArr = [localString("Customer Type"), localString("Address"), localString("Service Mode"), localString("Service Location"), localString("Slot and Date")];
	const [activeStep, setActiveStep] = useState('address');
	const [activeStepIndex, setActiveStepIndex] = useState(0);

	const qFilter = JSON.parse(props.qFilter);
	const [loader, setLoader] = useState(false);
	const [compWarningloader, setCompWarningLoader] = useState(false);
	const [defectivePartnerloader, setDefectivePartnerLoader] = useState(false);
	const [countryCodeArr, setCountryCodeArr] = useState(qFilter.countrycode || []);
	const [showEmailOption, setShowEmailOption] = useState(countryCodeArr.includes('US'));
	const [country, setCountry] = useState(countryCodeArr.length ? countryCodeArr[0].toLowerCase() : 'in');
	const [secondaryStepCountry, setSecondaryStepCountry] = useState(country);
	const [isMobileSelected, setIsMobileSelected] = useState(true);
	const [intlTelCountryConfig, setIntlTelCountryConfig] = useState(null);
	// const [selectedCountryData, setSelectedCountryData] = useState(null);
	const [stateList, setStateList] = useState([]);
	const [backToText, setBackToText] = useState('Back to all requests');
	const [error, setError] = useState('');
	const [defaultCountryLocale, setDefaultCountryLocale] = useState(qFilter.languagecode && qFilter.languagecode.length ? qFilter.languagecode[0] : '');
	const [deviceWarningMsg, setDeviceWarningMsg] = useState('');
	const [imeiOrSerialNo, setImeiOrSerialNo] = useState('');
	const [editImei, setEditImei] = useState(true);
	const [mobileMaxLength, setMobileMaxLength] = useState([]);
	const [pinCodeLengthArr, setPinCodeLengthArr] = useState([]);
	const [skipZipCodeCheck, setSkipZipCodeCheck] = useState(false);
	const [isNonSerializedProduct, setIsNonSerializedProduct] = useState(false);
	const [isMinimalConsumerMode, setIsMinimalConsumerMode] = useState(false);
	// const [currencySymbol, setCurrencySymbol] = useState(null);
	const [existingConsumerID, setExistingConsumerID] = useState('');
	const [existingProductID, setExistingProductID] = useState('');
	const [existingConsumerProductID, setExistingConsumerProductID] = useState('');
	
	const [mobile, setMobile] = useState('');
	const [email, setEmail] = useState('');
	const [userDetails, setUserDetails] = useState({
		name: '',
		email: '',
		mobile: '',
		personalId: ''
	});
	const [consumerId, setConsumerId] = useState(consumerServiceRequest?.ConsumerID || '');
	const [consumerObj, setConsumerObj] = useState(consumerServiceRequest?.consumer || null);
	const [isNewConsumer, setIsNewConsumer] = useState(false);
	
	const [brandId, setBrandId] = useState([]);
	const [consumerProduct, setConsumerProduct] = useState(consumerServiceRequest?.consumerProduct || {});
	const [selectedConsumerProduct, setSelectedConsumerProduct] = useState({});
	const [consumerProductArray, setConsumerProductArray] = useState([]);
	const [inRepairConsumerProducts, setInRepairConsumerProducts] = useState([]);
	const [filteredConsumerProducts, setFilteredConsumerProducts] = useState([]);

	const [selectedClaimType, setSelectedClaimType] = useState(false);
	const [modeType, setModeType] = useState(null);
	const [plan, setPlan] = useState(null);
	const [claimEligibilityDetails, setClaimEligibilityDetails] = useState(null);
	const [coverage, setCoverage] = useState(null);
	const [claimForm, setClaimForm] = useState(null);
	const [damageTypeObj, setDamageTypeObj] = useState(null);
	const [selectedIssues, setSelectedIssues] = useState([]);
	const [selectedReasons, setSelectedReasons] = useState([]);
	const [otherIssueDescription, setOtherIssueDescription] = useState('');
	const [otherReasonDescription, setOtherReasonDescription] = useState('');
	// const [secContactDetails, setSecContactDetails] = useState(defaultSecContactDetails);
	const [carryInType, setCarryInType] = useState(null);
	const [address, setAddress] = useState(null);
	const [walkIn, setWalkIn] = useState(false);
	const [consumerFavouriteLocationID, setConsumerFavouriteLocationID] = useState('');
	const [secondaryAddress, setSecondaryAddress] = useState(null);
	const [isSameAddress, setIsSameAddress] = useState(true);
	const [singleServiceLocation, setSingleServiceLocation] = useState(null);
	const [selectedServiceLocation, setSelectedServiceLocation] = useState(null);
	const [serviceMode, setServiceMode] = useState({});
	const [serviceLocation, setServiceLocation] = useState(null);
	const [dropoffLocation, setDropoffLocation] = useState(null);
	const [serviceAvailability, setServiceAvailability] = useState(null);
	const [serviceAvailabilityData, setServiceAvailabilityData] = useState(null);
	const [serviceTypeArray, setServiceTypeArray] = useState(null);
	const [deliveryMode, setDeliveryMode] = useState(null);
	const [partnerID, setPartnerID] = useState(null);
	const [partnerServiceLocationID, setPartnerServiceLocationID] = useState(null);
	const [advanceChargeApplicable, setAdvanceChargeApplicable] = useState(false);
	const [openPayoutModes, setOpenPayoutModes] = useState(false);
	const [FMIP, setFMIP] = useState(false);
	const [vendorList, setVendorList] = useState([]);
	const [vendor, setVendor] = useState(null);
	const [dropoffLocations, setDropoffLocations] = useState([]);
	const [dropoffLocationList, setDropoffLocationList] = useState([]);
	const [serviceLocationList, setServiceLocationList] = useState([]);
	const [secondryAddressServiceAvailabilityData, setSecondryAddressServiceAvailabilityData] = useState(null);
	const [secondryAddressServiceTypeArray, setSecondryAddressServiceTypeArray] = useState(null);
	const [dropOffAvailableForSecondAddress, setDropOffAvailableForSecondAddress] = useState(null);
	const [scheduleDateArray, setScheduleDateArray] = useState(null);
	const [scheduleSlotArray, setScheduleSlotArray] = useState(null);
	const [scheduleDate, setScheduleDate] = useState(null);
	const [slot, setSlot] = useState(null);
	const [slotAvailable, setSlotAvailable] = useState(false);
	const [disableSlotNext, setDisableSlotNext] = useState(false);
	const [isDeviceInWarranty, setIsDeviceInWarranty] = useState(false);
	const [warrantyTillDate, setWarrantyTillDate] = useState(new Date());
	const [isWarrantyApplicable, setIsWarrantyApplicable] = useState(false);
	const [isDeviceWarrantyApplicable, setIsDeviceWarrantyApplicable] = useState(false);
	const [dropOffData, setDropOffData] = useState(null);
	const [instructionsArray, setInstructionsArray] = useState([]);
	const [dropOffInstructionsTitle, setDropOffInstructionsTitle] = useState(null);
	const [decisionMatrix, setDecisionMatrix] = useState(false);
	const [logisticsName, setLogisticsName] = useState(null);
	const [logisticsMobileNo, setLogisticsMobileNo] = useState(null);
	const [payoutAttributes, setPayoutAttributes] = useState(null);
	const [isWallMount, setIsWallMount] = useState(false);
	const [warrantyConsent, setWarrantyConsent] = useState(null);
	const [consentConfirmed, setConsentConfirmed] = useState(false);
	const [paymentAmount, setPaymentAmount] = useState(0);
	const [isEmptyBoxServiceable, setIsEmptyBoxServiceable] = useState(false);
	const [emptyBoxLocationID, setEmptyBoxLocationID] = useState(null);
	const [isAdvanceExchangeApplicable, setIsAdvanceExchangeApplicable] = useState(false);
	const [defectivePartnerServiceLocationID, setDefectivePartnerServiceLocationID] = useState(null);
	const [replacementOptions, setReplacementOptions] = useState([]);
	const [selectedReplacementOption, setSelectedReplacementOption] = useState({});
	const [replacementAllocationType, setReplacementAllocationType] = useState({});

	const [showModeType, setShowModeType] = useState(false);
	const [showPlans, setShowPlans] = useState(false);
	const [showCoverageType, setShowCoverageType] = useState(false);
	const [showCoverage, setShowCoverage] = useState(false);
	const [showClaimForm, setShowClaimForm] = useState(false);
	const [showDOP, setShowDOP] = useState(false);
	const [showReplacementReasons, setShowReplacementReasons] = useState(false);
	const [showIssuesSelection, setShowIssuesSelection] = useState(false);
	const [showOtherIssueTextBox, setshowOtherIssueTextBox] = useState(false);
	const [showOtherReasonTextBox, setshowOtherReasonTextBox] = useState(false);
	const [showSecCommunicationDetails, setShowSecCommunicationDetails] = useState(false);
	const [showCarryInTypeSelection, setShowCarryInTypeSelection] = useState(false);
	const [showAddress, setShowAddress] = useState(false);
	const [showSecondaryAddress, setShowSecondaryAddress] = useState(false);
	const [showSlotSelection, setShowSlotSelection] = useState(false);
	const [showServiceTypes, setShowServiceModes] = useState(false);
	const [showNoService, setShowNoService] = useState(false);
	const [showServiceLocationsCarryIn, setShowServiceLocationsCarryIn] = useState(false);
	const [showServiceLocationsDropOff, setShowServiceLocationsDropOff] = useState(false);
	const [showExternalReferenceID, setShowExternalReferenceID] = useState('');
    const [showFMIPModal, setShowFMIPModal] = useState(false);
    const [showLogisticsPartnerModal, setShowLogisticsPartnerModal] = useState(false);
	const [showIsSameAddress, setShowIsSameAddress] = useState(false);
	const [showInstructionsModal, setShowInstructionsModal] = useState(false);
	const [showPayoutModal, setShowPayoutModal] = useState(false);
	const [showActivation, setShowActivation] = useState(false);
	const [showRaiseReplacementRequest, setShowRaiseReplacementRequest] = useState(false);
	const [showRaiseDeviceExchange, setShowRaiseDeviceExchange] = useState(false);
	const [showReplacementOptions, setShowReplacementOptions] = useState(false);
	const [showAdvanceExchangeConsent, setShowAdvanceExchangeConsent] = useState(false);

	const [callGetScheduleDateAndSlotApi, setCallGetScheduleDateAndSlotApi] = useState(false);
	const [callRequestFunction, setCallRequestFunction] = useState(false);
	const [isSupressLogisticsCutomerToASC, setIsSupressLogisticsCutomerToASC] = useState(false);
	const [legalConfigsList, setLegalConfigsList] = useState([]);
	const [chargeMaxDeductible,setChargeMaxDeductible] = useState(false)
	const [tokenizeFlow,setTokenizeFlow] = useState(false);

	const hideSection = (typeArr) => {
		let sections = typeArr || [];
		// sections.includes('ConsumerDetails') && setShowConsumerDetails(false);
		// sections.includes('ConsumerProducts') && setShowConsumerProducts(false);
		// sections.includes('AddProduct') && setShowAddProduct(false);
		// sections.includes('AddEditSerialNo') && setShowAddEditSerialNo(false);
		// sections.includes('SelectedProduct') && setShowSelectedProduct(false);
		// sections.includes('InspectionGuide') && setShowInspectionGuide(false);
		// sections.includes('DeviceAttributes') && setShowDeviceAttributes(false);
		sections.includes('ModeType') && setShowModeType(false);
		sections.includes('Plans') && setShowPlans(false);
		sections.includes('CoverageType') && setShowCoverageType(false);
		sections.includes('Coverage') && setShowCoverage(false);
		sections.includes('ClaimForm') && setShowClaimForm(false);
		sections.includes('DOP') && setShowDOP(false);
		sections.includes('ReplacementReasons') && setShowReplacementReasons(false);
		sections.includes('IssuesSelection') && setShowIssuesSelection(false);
		sections.includes('OtherIssueTextBox') && setshowOtherIssueTextBox(false);
		sections.includes('OtherReasonTextBox') && setshowOtherReasonTextBox(false);
		sections.includes('SecCommunicationDetails') && setShowSecCommunicationDetails(false);
		sections.includes('CarryInTypeSelection') && setShowCarryInTypeSelection(false);
		sections.includes('Address') && setShowAddress(false);
		sections.includes('SecondaryAddress') && setShowSecondaryAddress(false);
		sections.includes('SlotSelection') && setShowSlotSelection(false);
		sections.includes('ServiceModes') && setShowServiceModes(false);
		sections.includes('NoService') && setShowNoService(false);
		sections.includes('ServiceLocationsCarryIn') && setShowServiceLocationsCarryIn(false);
		sections.includes('ServiceLocationsDropOff') && setShowServiceLocationsDropOff(false);
		sections.includes('ExternalReferenceID') && setShowExternalReferenceID(false);
		sections.includes('FMIPModal') && setShowFMIPModal(false);
		sections.includes('LogisticsPartnerModal') && setShowLogisticsPartnerModal(false);
		sections.includes('IsSameAddress') && setShowIsSameAddress(false);
		sections.includes('InstructionsModal') && setShowInstructionsModal(false);
		sections.includes('PayoutModal') && setShowPayoutModal(false);
		sections.includes('Activation') && setShowActivation(false);
		sections.includes('ReplacementOptions') && setShowReplacementOptions(false);
	}


	useEffect(() => {
		if(callGetScheduleDateAndSlotApi) {
			getScheduleDateAndSlot();
			setCallGetScheduleDateAndSlotApi(false);
		}
	}, [callGetScheduleDateAndSlotApi])

	const checkEmptyBoxAvailbility = (address) => {
		setLoader(true); 
		let reqObj = {
			"Lng": address && address.Lng,
			"Lat": address && address.Lat,
			"CountryID": consumerObj ? consumerObj.CountryID : undefined,
            "CountryCode": selectedCountryData ? selectedCountryData.CountryCode : undefined,
			"ProductSubCategoryID": consumerProduct && consumerProduct.ProductSubCategoryID,
			"ProductID": consumerProduct && consumerProduct.ProductID,
			"BrandID": consumerProduct && consumerProduct.BrandID,
			"State": address && address.State,
			"Zipcode": address && address.PinCode ? address.PinCode : address && address.Zipcode ? address.Zipcode : null,
			"PlanID": planID ? planID : undefined,
		};
		apiCall({
			url: 'getEmptyBoxServiceAvailability',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data) {
					setIsEmptyBoxServiceable(result.data.isEmptyBoxServiceable);
					setEmptyBoxLocationID(result.data.PartnerServiceLocationID);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)), 
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const checkDefectivePartnerAvailbility = (address, serviceTypeArr) => {
		setDefectivePartnerLoader(true); 
		let reqObj = {
			"Lng": address && address.Lng,
			"Lat": address && address.Lat,
			"CountryID": consumerObj ? consumerObj.CountryID : undefined,
            "CountryCode": selectedCountryData ? selectedCountryData.CountryCode : undefined,
			"ProductSubCategoryID": consumerProduct && consumerProduct.ProductSubCategoryID,
			"ProductID": consumerProduct && consumerProduct.ProductID,
			"BrandID": consumerProduct && consumerProduct.BrandID,
			"City": address && address.City,
			"State": address && address.State,
			"Zipcode": address && address.PinCode ? address.PinCode : address && address.Zipcode ? address.Zipcode : null,
			"PlanID": planID ? planID : undefined,
		};
		apiCall({
			url: 'getAEWithoutDefectiveServiceAvailability',
			data: reqObj,
			callBack: (result) => {
				setDefectivePartnerLoader(false); 
				if(result.success) {
					if(result?.data?.PartnerServiceLocationID) {
						setIsAdvanceExchangeApplicable(result.data.isAdvanceExchangeApplicable);
						setDefectivePartnerServiceLocationID(result.data.PartnerServiceLocationID);
					}else {
						let arr = serviceTypeArr.filter(o => o.ServiceTypeID != 65);
						setServiceTypeArray(arr);
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)), 
					});
				}
			},
			errorCB: (err) => {
				setDefectivePartnerLoader(false);
				Swal.fire({
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const setScreenToViewDiv = (id) => {
		// let data = document.querySelector(`#${id}`);
		// if(data && data.offsetTop) {
		// 	window.scrollTo({
		// 		top: data.offsetTop,
		// 		left: 0,
		// 		behavior: 'smooth'
		// 	})
		// }
	}

	const showInstructions = () => {
		let data = showSecondaryAddress ? secondryAddressServiceAvailabilityData : serviceAvailabilityData;
		if(data && data.dropOffInstructions) {
			let dropOffDataObj = data.dropOffInstructions;
			setDropOffData(dropOffDataObj);
			if(dropOffDataObj && Object.keys(dropOffDataObj).length > 0) {
				setDropOffInstructionsTitle(dropOffDataObj.DropOffInstructionsTitle);
				setInstructionsArray(dropOffDataObj.DropOffInstructions);
			}
		}
		setShowInstructionsModal(true);
	}

	const getServiceAvailabilityWithLocations = (addressObj, isSecondryAddress = false) => {
		setLoader(true); 
	    let data = {
			Category: 'repair',
			Lng: addressObj.Lng,
			Lat: addressObj.Lat,
            CountryID: addressObj.CountryID,
			ConsumerProductID: consumerProduct.ConsumerProductID,
			SoldPlanID: consumerServiceRequest.SoldPlanID,
			ProductSubCategoryID: consumerProduct.ProductSubCategoryID,
			ProductID: consumerProduct.ProductID,
			BrandID: consumerProduct.BrandID,
			Address: addressObj.Address,
			Landmark: addressObj.Landmark,
			Radius: 80,
			Partnered: 1,
            State: addressObj.State,
            StateID : addressObj.StateID,
            City: addressObj.City,
			District: addressObj && addressObj.District ? addressObj.District : undefined,
            // Zipcode: addressObj.PinCode ? addressObj.PinCode : addressObj.Zipcode ? addressObj.Zipcode : null,
			allowedModes: consumerServiceRequest.DecisionMatrixModes,
			PlanCoverageID: soldPlanCoverage?.PlanCoverageID,
			SoldPlanCoverageID: soldPlanCoverage?.SoldPlanCoverageID,
			damageId: claimDamageId ? claimDamageId : undefined,
			PlanID:  planID ? planID : undefined,
		};

		if(!countriesChecks.skipZipCodeCheck) {
			data.Zipcode = addressObj.PinCode
		}

        // if(!isSecondryAddress){
        //     // serviceMode = {};
		// 	setServiceMode({});
		// 	// setShowServiceModes(false);
        // }
		setShowSlotSelection(false);
		apiCall({
			url: 'getServiceAvailabilityWithLocations',
			data: data,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					setShowServiceLocationsDropOff(false);
					setShowRaiseReplacementRequest(false);
					if (isSecondryAddress) {
						setActiveStepIndex(activeStepIndex + 1);
						let secAddServiceAvailabilityData = result.data;
						let secAddServiceTypeArray = serviceAvailabilityData.modes;
						secAddServiceTypeArray = secAddServiceTypeArray.filter(o => o.IsAvailable);
						let dropOffAvailableForSecAdd = findIndex(secAddServiceTypeArray, function(o) {
							return [SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].indexOf(o.ServiceTypeID) > -1;
						});
						setSecondryAddressServiceAvailabilityData(secAddServiceAvailabilityData);
						setSecondryAddressServiceTypeArray(secAddServiceTypeArray);
						setDropOffAvailableForSecondAddress(dropOffAvailableForSecAdd);
						if([SERVICETYPE.PICK_UP].indexOf(serviceMode.ServiceTypeID) > -1) {
							setCallGetScheduleDateAndSlotApi(true);
                        }else if(dropOffAvailableForSecAdd > -1 && [SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].indexOf(serviceMode.ServiceTypeID) > -1){
							if (secAddServiceAvailabilityData.isDropOffLocationAvailable) {
								setDropoffLocationList(secAddServiceAvailabilityData.dropOffLocations);
								setShowServiceLocationsDropOff(true);
								setActiveStep && setActiveStep('serviceLocation');
							}else {
								showInstructions();
							}
						}else {
							Swal.fire({  
								icon: 'error',  
								text: localString('This address is not serviceable'),  
							});
						}
					}else {
						// setActiveStep('serviceMode');
						// setActiveStepIndex(activeStepIndex + 1);
						// setServiceMode({});
						let serviceAvailabilityObj = result.data;
						setServiceAvailability(serviceAvailabilityObj);
						setServiceAvailabilityData(serviceAvailabilityObj);
						serviceAvailabilityObj.serviceLocations && setServiceLocationList(serviceAvailabilityObj.serviceLocations); //set service location list
						setServiceTypeArray(serviceAvailabilityObj.modes);
						setShowServiceModes(true);	
						if(serviceAvailabilityObj && serviceAvailabilityObj.DeliveryMode) {
							setDeliveryMode(serviceAvailabilityObj.DeliveryMode);
						}
						if(serviceAvailabilityObj && serviceAvailabilityObj.PartnerID) {
							setPartnerID(serviceAvailabilityObj.PartnerID);
						}
						if (serviceAvailabilityObj && serviceAvailabilityObj.PartnerServiceLocationID) {
							setPartnerServiceLocationID(serviceAvailabilityObj.PartnerServiceLocationID);
						}
						// self.setScreenToViewDiv('serviceModes');			
					}
					let serviceTypeArr = result?.data?.modes?.length ? result.data.modes : [];
					let checkEmptyBox = filter(serviceTypeArr, function(o) {
                        return [SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].indexOf(o.ServiceTypeID) > -1 && o.IsAvailable;
                    });
                    if(checkEmptyBox && checkEmptyBox.length) {
						checkEmptyBoxAvailbility(addressObj);
                    }
					let checkDefectivePartner = filter(serviceTypeArr, function(o) {
						return [SERVICETYPE.ADVANCE_EXCHANGE].indexOf(o.ServiceTypeID) > -1 && o.IsAvailable;
					});

					if(checkDefectivePartner && checkDefectivePartner.length){
						checkDefectivePartnerAvailbility(addressObj, serviceTypeArr);
					}
					// if (self.CurrencySymbol && $('.buyout-currency')) {
                    //     $('.buyout-currency').html(self.CurrencySymbol);
                    // }
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}


	const getServiceLocationForWalkin = () => {
		setLoader(true); 
		let reqObj = {
			"PartnerServiceLocationID": qFilter?.serviceLocations[0]
		};
		apiCall({
			url: 'getServiceLocationsName',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data) {
					setServiceLocation(result.data);
					setCallRequestFunction(true);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)), 
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const getScheduleDateAndSlot = () => {
		setLoader(true);
		let slotObj = {
            CurrentDate: moment().format('YYYY-MM-DD'),
            CurrentTime: moment().format('HH:mm:ss'),
            ServiceTypeID: serviceMode?.ServiceTypeID,
            Lat: address?.Lat,
            Lng: address?.Lng,
			PinCode: address ? address.PinCode : '',
            ConsumerProductID: consumerProduct?.ConsumerProductID,
        }
		if(serviceMode && [SERVICETYPE.PICK_UP].indexOf(serviceMode.ServiceTypeID) > -1) {
            slotObj.DeliveryMode = deliveryMode;
			slotObj.PartnerID = serviceAvailability?.PartnerID;
			slotObj.PartnerServiceLocationID = serviceAvailability?.PartnerServiceLocationID;
        }
        if(serviceMode && (serviceMode.ServiceTypeID == SERVICETYPE.CARRY_IN)) {
			if(serviceLocation && serviceLocation.PartnerServiceLocationID) {
				slotObj.PartnerServiceLocationID = serviceLocation?.PartnerServiceLocationID;
				slotObj.slots = serviceLocation?.slots;
				slotObj.externalSlots = serviceLocation?.externalSlots;
				slotObj.TimezoneString = serviceLocation?.TimezoneString;
			}
        };
		if (serviceMode && serviceMode.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && serviceAvailability && serviceAvailability.OnsiteDetails && serviceAvailability.OnsiteDetails.CLAIM_ONSITE) {
            slotObj.PartnerServiceLocationID = serviceAvailability.OnsiteDetails.CLAIM_ONSITE.PartnerServiceLocationID;
            slotObj.PartnerID =  serviceAvailability.OnsiteDetails.CLAIM_ONSITE.PartnerID;
        };
		apiCall({
			url: 'slotCheckingWithLogistics',
			data: slotObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success) {
					let scheduleDateList = result.data;
					let scheduleSlotList = [];
					setScheduleDateArray(scheduleDateList);
					let selectedScheduleDate;
					let selectedSlot = undefined;
					for (let i = scheduleDateList.length - 1; i >= 0; i--) {
						if (scheduleDateList[i].slots && (scheduleDateList[i].slots.length > 0)) {
							let slotArray = scheduleDateList[i].slots;
							for (let j = slotArray.length - 1; j >= 0; j--) {
								if (slotArray[j].IsActive) {
									selectedScheduleDate = scheduleDateList[i];
								}
							}
						}
					}
					setScheduleDate(selectedScheduleDate);
					if(selectedScheduleDate) {
						scheduleSlotList = selectedScheduleDate.slots;
						setScheduleSlotArray(scheduleSlotList);
						let slotAvailableFlag = false;
						for (let i = scheduleSlotList.length - 1; i >= 0; i--) {
							if (scheduleSlotList[i].IsActive) {
								slotAvailableFlag = true;
								if(walkIn){
									selectedSlot = scheduleSlotList[i];
									setSlot(scheduleSlotList[i])
									setShowSlotSelection(false);						
									setDisableSlotNext(false);
									getServiceLocationForWalkin();
									return;
								}
							}
						}
						setSlot(selectedSlot);
						setSlotAvailable(slotAvailableFlag);
						setShowSlotSelection(true);		
						setActiveStep && setActiveStep('dateAndSlotSelection');
					}else {
						Swal.fire({  
							icon: 'error',  
							text: localString('No slots available'),  
						});
					}
					if(selectedSlot) {
						setDisableSlotNext(false);
					}else {
						setDisableSlotNext(true);
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString('No slots available'),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});

	}

	useEffect(() => {
		if(callRequestFunction) {
			request();
			setCallRequestFunction(false);
		}
	}, [callRequestFunction])

	const request = () => {
		Swal.fire({
			title: localString('Send for raising request') + '?',
			icon: 'question',
			confirmButtonColor: '#4db800',
			confirmButtonText: localString('Yes'),
            cancelButtonText: localString('Cancel'),
            cancelButtonColor: '#d33',
			showCancelButton: true,

		}).then(swalResult => {
			if(swalResult.isConfirmed) {
				if(isSubscriptionEnabled || serviceMode.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE) {
					createGenericRequest();
				}else if(serviceMode.ServiceTypeID == SERVICETYPE.PICK_UP) {
					schedulePickUpRequest();
				}else if(serviceMode.ServiceTypeID == SERVICETYPE.CARRY_IN) {
					scheduleCarryInRequest();
				}else if (serviceMode.ServiceTypeID == SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE) {
					scheduleReplacement();
				}else if(serviceMode.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE) {
					scheduleOnsiteRequest();
				}else if([SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].indexOf(serviceMode.ServiceTypeID) > -1) {
					scheduleDropOffRequest();
				}else {
					scheduleRequest();
				}
			}
		});
	}

	const createGenericRequest = () => {
		setLoader(true); 
		let partnerId, partnerServiceLocationId, dropOffCenter;
		if(serviceMode && [SERVICETYPE.PICK_UP, SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].includes(serviceMode.ServiceTypeID)) {
			partnerId = secondryAddressServiceAvailabilityData && secondryAddressServiceAvailabilityData.dropOffServicePartner ? secondryAddressServiceAvailabilityData.dropOffServicePartner.PartnerID : serviceAvailabilityData && serviceAvailabilityData.dropOffServicePartner && serviceAvailabilityData.dropOffServicePartner.PartnerID;
			partnerServiceLocationId = secondryAddressServiceAvailabilityData && secondryAddressServiceAvailabilityData.dropOffServicePartner? secondryAddressServiceAvailabilityData.dropOffServicePartner.PartnerServiceLocationID : serviceAvailabilityData && serviceAvailabilityData.dropOffServicePartner && serviceAvailabilityData.dropOffServicePartner.PartnerServiceLocationID;
			if([SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].includes(serviceMode.ServiceTypeID)) {
				dropOffCenter = serviceLocation;
			}
		}else if (serviceMode && [SERVICETYPE.ADVANCE_EXCHANGE].indexOf(serviceMode.ServiceTypeID) > -1) {
			partnerId = serviceAvailability.advanceExchangeDetails && serviceAvailability.advanceExchangeDetails.PartnerID;
			partnerServiceLocationId = serviceAvailability.advanceExchangeDetails && serviceAvailability.advanceExchangeDetails.PartnerServiceLocationID;
		}else {
			partnerId = serviceLocation && serviceLocation.PartnerID;
			partnerServiceLocationId = serviceLocation && serviceLocation.PartnerServiceLocationID;
		}

		let data = {
			skipRaiseClaim : true,
			SoldPlanID: consumerServiceRequest.SoldPlanID,
			PlanID: planID,
			ConsumerID: consumerServiceRequest.ConsumerID,
			CountryID: consumerServiceRequest.CountryID ? consumerServiceRequest.CountryID : undefined,
            CountryCode: consumerServiceRequest.CountryCode ? consumerServiceRequest.CountryCode : undefined,
			ConsumerProductID: consumerProduct.ConsumerProductID,
			ServiceTypeID: serviceMode.ServiceTypeID,
			isSubscriptionEnabled: true,
			RequiresClaimForm: true,
  			SkipClaimApproval: true,
            SoldPlanCoverageID: soldPlanCoverage && soldPlanCoverage.SoldPlanCoverageID,
            PlanCoverageID: soldPlanCoverage && soldPlanCoverage.PlanCoverageID,
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			ConsumerServiceRequest: {
				Address: address && address.Address,
				AddressType: address && address.AddressType,
				BrandID: consumerProduct.BrandID,
				ConsumerFavouriteLocationID: address && address.ConsumerFavouriteLocationID,
				ConsumerID: consumerServiceRequest.ConsumerID,
				CountryID: consumerServiceRequest.CountryID ? consumerServiceRequest.CountryID : undefined,
            	CountryCode: consumerServiceRequest.CountryCode ? consumerServiceRequest.CountryCode : undefined,
				ConsumerProductID: consumerProduct.ConsumerProductID,
				DeliveryMode: deliveryMode,
				IsNonPartner: 0,
				IsUnderRepair: false,
				IsUrgent: false,
				Landmark: address && address.Landmark,
				Lat: address && address.Lat,
				Lng: address && address.Lng,
				PartnerID: partnerId,
				PartnerServiceLocationID: partnerServiceLocationId,
				ClientServiceLocationID: serviceLocation ? serviceLocation.ClientServiceLocationID : undefined,
				ClientServiceLocationDetails: serviceLocation && serviceLocation.ClientServiceLocationID ? serviceLocation : undefined,
				ProductSubCategoryID: consumerProduct.ProductSubCategoryID,
				BrandID: consumerProduct.BrandID,
				ProductID: consumerProduct.ProductID,
				ScheduledDateTime: scheduleDate && moment(scheduleDate.date).utc(),
				ScheduledFromTime: slot && slot.StartTimeVal,
				ScheduledToTime: slot && slot.EndTimeVal,
				ExternalTimezoneString: serviceLocation && serviceLocation.TimezoneString ? serviceLocation.TimezoneString : undefined,
				ServiceRequestType: "Now",
				ServiceTypeID: serviceMode.ServiceTypeID,
				deliveryAddress: secondaryAddress,
				DropOffCenter: dropOffCenter,
				walkin: walkIn,
                State: address && address.State,
				StateID : address && address.StateID,
                City: address && address.City,
                District: address && address.District ? address.District : undefined,
                LogisticsName: consumerServiceRequest.consumer.Name,
                LogisticsMobileNo: consumerServiceRequest.consumer.MobileNo,
				ExternalSuppressLogisticsRequest:isSupressLogisticsCutomerToASC,
				isCardHoldSkipped: serviceMode?.isCardHoldSkipped ? serviceMode.isCardHoldSkipped : undefined,
                // Attributes: self.payoutAttributes ? self.payoutAttributes : undefined
			},
			chargeMaxDeductible:chargeMaxDeductible,
			tokenizeFlow:tokenizeFlow
		}
		if(legalConfigsList && legalConfigsList.length){
			data.ConsumerServiceRequest.Consent = legalConfigsList.map((item) => ({
					ApplicationLegalConfigID: item.ApplicationLegalConfigID,
					ConsumerID: consumerServiceRequest.ConsumerID,
					Attributes: [
						{
							AttributeType: "imeiOrSerial",
							AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
						},
					],
					ConsentText: item.ConsentText,
					LegalConfigCode: item.LegalConfigCode,
					Selected:item.Selected,
					UnselectedText:item.UnselectedText
			}));
		}
		if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(serviceMode.ServiceTypeID) > -1){
			if(isAdvanceExchangeApplicable){
				data.isAdvanceExchangeApplicable = true;
			}
			data.ConsumerServiceRequest.DefectivePartnerServiceLocationID = defectivePartnerServiceLocationID;
			if(replacementOptions && replacementOptions.length){
                data.ConsumerServiceRequest.DeviceOptions = replacementOptions;
                data.ConsumerServiceRequest.SelectedReplacementOption = selectedReplacementOption && selectedReplacementOption.Option;
            }
			if(serviceMode && [SERVICETYPE.ADVANCE_EXCHANGE].includes(serviceMode.ServiceTypeID)) {
				data.ConsumerServiceRequest.PartnerServiceLocationID = serviceAvailability.advanceExchangeDetails && serviceAvailability.advanceExchangeDetails.PartnerServiceLocationID;
            	data.ConsumerServiceRequest.PartnerID = serviceAvailability.advanceExchangeDetails && serviceAvailability.advanceExchangeDetails.PartnerID;
			}
		}
		if(serviceMode.ServiceTypeID == SERVICETYPE.PICK_UP){
			data.ServiceTypeID = SERVICETYPE.CLAIM_PICK_UP;
			data.ConsumerServiceRequest.ServiceTypeID = SERVICETYPE.CLAIM_PICK_UP;
		}
		if(serviceMode.ServiceTypeID == SERVICETYPE.CARRY_IN){
			data.ServiceTypeID = SERVICETYPE.CLAIM_CARRY_IN;
			data.ConsumerServiceRequest.ServiceTypeID = SERVICETYPE.CLAIM_CARRY_IN;
		}
		if(serviceMode.logisticsSuppressed || isSupressLogisticsCutomerToASC || [SERVICETYPE.CLAIM_DROPOFF, SERVICETYPE.DROPOFF].includes(serviceMode.ServiceTypeID)){
			data.ConsumerServiceRequest.ScheduledFromTime = "09:00:00";
			data.ConsumerServiceRequest.ScheduledToTime = "14:00:00";
			data.ConsumerServiceRequest.ScheduledDateTime = moment.utc().format();
		}
		apiCall({
			url: 'createRequest',
			data: data,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					onScheduleRequestSuccess(result);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const schedulePickUpRequest = () => {
		setLoader(true); 
        let pickupdrop = {
            Address: address && address.Address,
            AddressType: address && address.AddressType,
            ConsumerFavouriteLocationID: address && address.ConsumerFavouriteLocationID,
            Landmark: address && address.Landmark,
            Lat: address && address.Lat,
            Lng: address && address.Lng,
            // CountryID: address ? address.CountryID : '',
            ProductUniqueID: consumerProduct.Product,
            ScheduledDateTime: scheduleDate && moment(scheduleDate.date).utc(),
            ScheduledFromTime: slot && slot.StartTimeVal,
            ScheduledToTime: slot && slot.EndTimeVal,
            DeliveryMode: deliveryMode,
			ServiceRequestType: "Now",
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            IsUrgent: false,
            PartnerID: serviceAvailability && serviceAvailability.PartnerID,
            PartnerServiceLocationID: serviceAvailability && serviceAvailability.PartnerServiceLocationID,
            ServiceTypeID: SERVICETYPE.CLAIM_PICK_UP,
			IsUnderWarranty: consumerProduct?.IsUnderWarranty,
            ConsumerID: consumerServiceRequest?.ConsumerID,
            ConsumerProductID: consumerProduct?.ConsumerProductID,
            BrandID: consumerProduct?.BrandID, //Extra params
            ProductSubCategoryID: consumerProduct?.ProductSubCategoryID, //Extra params
			IsNonPartner: 0,
            State: address && address.State,
            StateID : address && address.StateID,
            City: address && address.City,
			District: address && address.District ? address.District : undefined,
			deliveryAddress: secondaryAddress,
            LogisticsName: logisticsName,
            LogisticsMobileNo: logisticsMobileNo,
			SoldPlanID: consumerServiceRequest?.SoldPlanID,
			PlanID: planID,
			SoldPlanCoverageID: soldPlanCoverage?.SoldPlanCoverageID,
			PlanCoverageID: soldPlanCoverage?.PlanCoverageID,
			CountryID: consumerServiceRequest?.CountryID,
			CountryCode: consumerServiceRequest?.CountryCode,
			ExternalSuppressLogisticsRequest:isSupressLogisticsCutomerToASC,
			chargeMaxDeductible:chargeMaxDeductible,
			tokenizeFlow:tokenizeFlow
        }

		if(!countriesChecks.skipZipCodeCheck){
			pickupdrop.Zipcode = address.PinCode
		}

		if(countriesChecks.countryConfig && countriesChecks.countryConfig.SkipAdvance){
			if("IsAdvanceChargeApplicable" in serviceMode){
				pickupdrop.AdvanceChargeApplicable = serviceMode.IsAdvanceChargeApplicable;
			} else {
				pickupdrop.AdvanceChargeApplicable = serviceAvailabilityData.IsAdvanceChargeApplicable;
			}
			
			if(pickupdrop.AdvanceChargeApplicable) {
				pickupdrop.skipLogistics = true;
			}
		}
		if(serviceMode.logisticsSuppressed || isSupressLogisticsCutomerToASC) {
			pickupdrop.ScheduledDateTime = moment.utc().format();
			pickupdrop.ScheduledFromTime = '09:00:00';
			pickupdrop.ScheduledToTime = '14:00:00';
		}
		if(legalConfigsList && legalConfigsList.length){
			pickupdrop.Consent = legalConfigsList.map((item) => ({
					ApplicationLegalConfigID: item.ApplicationLegalConfigID,
					ConsumerID: consumerServiceRequest.ConsumerID,
					Attributes: [
						{
							AttributeType: "imeiOrSerial",
							AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
						},
					],
					ConsentText: item.ConsentText,
					LegalConfigCode: item.LegalConfigCode,
					Selected:item.Selected,
					UnselectedText:item.UnselectedText
			}));
		}
		apiCall({
			url: 'scheduleRepair',
			data: pickupdrop,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					onScheduleRequestSuccess(result);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const onScheduleRequestSuccess = (result) => {
		let data = result.data;
        let viewButton = true;
        let ScheduledDateTime = data.ScheduledDateTime ? data.ScheduledDateTime : data.ConsumerServiceRequest && data.ConsumerServiceRequest.ScheduledDateTime ? data.ConsumerServiceRequest.ScheduledDateTime : '';

        if(data.PartnerServiceLocationID && qFilter.servicelocations && qFilter.servicelocations.length > 0 && qFilter.servicelocations.indexOf(data.PartnerServiceLocationID) == -1){
          	viewButton = false;
        }
        let requestArrObj = [data.ScheduledFromTime ? {
            name: localString("Slot Time"),
            // value: moment(data.ScheduledFromTime, 'HH:mm:ss').format('hh:mm a') + ' - ' + moment(data.ScheduledToTime, 'HH:mm:ss').format('hh:mm a')
            value: returnLocalSlot(data.ScheduledFromTime) + ' - ' + returnLocalSlot(data.ScheduledToTime)
        } : '', ScheduledDateTime ? {
            name: localString("Schedule Date"),
            // value: getLocaleDateString(ScheduledDateTime, defaultCountryLocale)
            value: returnLocalTime(ScheduledDateTime, defaultCountryLocale)
        } : '', {
            name: localString("Reference ID"),
            value: data.ReferenceID
        }];

		if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(serviceMode.ServiceTypeID) > -1) {
			requestArrObj = [{
				name: localString('Reference ID'),
				value: data.ReferenceID
			}]
		}

        let requesthtml = '<h3>'+localString("The request was raised successfully")+'.</h3><div class="table-responsive">'+
                          (data.PaymentStatusMessage ? '<h4 style="margin-bottom:15px;margin-top:15px">'+data.PaymentStatusMessage+'</h4>' : '');
        requesthtml += '<table class="table">';
        requesthtml += '<tbody>';


        for (let i = requestArrObj.length - 1; i >= 0; i--) {
            if(requestArrObj[i]) {
                requesthtml += '<tr>';
                requesthtml += '<td>';
                requesthtml += requestArrObj[i].name
                requesthtml += '</td>';
                requesthtml += '<td>';
                requesthtml += requestArrObj[i].value;
                requesthtml += '</td>';
                requesthtml += '</tr>';
            }
        }

        requesthtml += '</tbody>';
        requesthtml += '</table>'
        requesthtml += '</div>';

		Swal.fire({
			html: requesthtml,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#4C10C1',
            confirmButtonText: localString("View"),

		}).then(swalResult => {
			if(swalResult.isConfirmed){
                // window.location.href = baseUrl + viewUrl + encrypt(result.data.ConsumerServiceRequestID);
				window.location.reload();
			}else {
				window.location.reload();
			}
		});
	}

	const scheduleCarryInRequest = () => {
		setLoader(true); 
		let carryInRequestObj = {};
		if(singleServiceLocation) {
			carryInRequestObj = {
				Address: serviceLocation.Address,
				Landmark: serviceLocation.Landmark,
				AddressType: serviceLocation.AddressType,
				Lat: serviceLocation.Lat,
				Lng: serviceLocation.Lng,
				ServiceRequestType: 'Now',
				ScheduledDateTime: scheduleDate && moment(scheduleDate.date).utc(),
				ScheduledFromTime: slot && slot.StartTimeVal,
				ScheduledToTime: slot && slot.EndTimeVal,
				ExternalTimezoneString: serviceLocation && serviceLocation.TimezoneString ? serviceLocation.TimezoneString : undefined,
				BrandID: consumerProduct?.BrandID,
				IsNonPartner: serviceLocation?.IsNonPartner,
				PartnerID: serviceLocation?.PartnerID,
				PartnerServiceLocationID: serviceLocation?.PartnerServiceLocationID,
				ClientServiceLocationID: serviceLocation ? serviceLocation.ClientServiceLocationID : undefined,
				ProductSubCategoryID: consumerProduct?.ProductSubCategoryID,
				ProductID: consumerProduct?.ProductID,
				Documents: [],
				ConsumerID: consumerServiceRequest?.ConsumerID,
				ConsumerProductID: consumerProduct?.ConsumerProductID,
				walkin: walkIn,
				ServiceTypeID: SERVICETYPE.CLAIM_CARRY_IN,
				noAddress: true,
				ConsumerServiceRequestID: consumerServiceRequest?.ConsumerServiceRequestID,
				ProductUniqueID: consumerProduct?.ProductUniqueID,
				State: serviceLocation.State,
				StateID : serviceLocation.StateID,
				City: serviceLocation.City,
				District: serviceLocation.District ? serviceLocation.District : undefined,
				SoldPlanID: consumerServiceRequest?.SoldPlanID,
				CountryID: consumerServiceRequest.CountryID ? consumerServiceRequest.CountryID : undefined,
				CountryCode: consumerServiceRequest.CountryCode ? consumerServiceRequest.CountryCode : undefined,
				PlanID: planID,
				PlanCoverageID: soldPlanCoverage && soldPlanCoverage.PlanCoverageID,
			};

			if(!countriesChecks.skipZipCodeCheck){
				carryInRequestObj.Zipcode = serviceLocation.PinCode ? serviceLocation.PinCode : serviceLocation.ZipCode;
			}
		} else {
			carryInRequestObj = {
				Address: address?.Address,
				Landmark: address?.Landmark,
				AddressType: address?.AddressType,
				ConsumerFavouriteLocationID:address?.ConsumerFavouriteLocationID,
				Lat: address?.Lat,
				Lng: address?.Lng,
				IsUnderWarranty: consumerProduct?.IsUnderWarranty,
				ServiceRequestType: 'Now',
				ScheduledDateTime: scheduleDate && moment(scheduleDate.date).utc(),
				ScheduledFromTime: slot && slot.StartTimeVal,
				ScheduledToTime: slot && slot.EndTimeVal,
				ExternalTimezoneString: serviceLocation && serviceLocation.TimezoneString ? serviceLocation.TimezoneString : undefined,
				BrandID: consumerProduct?.BrandID,
				IsNonPartner: serviceLocation.IsNonPartner,
				PartnerID: serviceLocation.PartnerID,
				PartnerServiceLocationID: serviceLocation.PartnerServiceLocationID,
				ClientServiceLocationID: serviceLocation ? serviceLocation.ClientServiceLocationID : undefined,
				ProductSubCategoryID: consumerProduct?.ProductSubCategoryID,
				ProductID: consumerProduct?.ProductID,
				ServiceTypeID: SERVICETYPE.CLAIM_CARRY_IN,
				Documents: [],
				ConsumerID: consumerServiceRequest?.ConsumerID,
				ConsumerProductID: consumerProduct?.ConsumerProductID,
				walkin: walkIn,
				IsUrgent: false,
				ConsumerServiceRequestID: consumerServiceRequest?.ConsumerServiceRequestID,
				ProductUniqueID: consumerProduct?.ProductUniqueID,
				State: address?.State,
				StateID : address?.StateID,
				City: address?.City,
				District: address?.District,
				SoldPlanID: consumerServiceRequest?.SoldPlanID,
				SoldPlanCoverageID: soldPlanCoverage?.SoldPlanCoverageID,
				CountryID: consumerServiceRequest.CountryID ? consumerServiceRequest.CountryID : undefined,
				CountryCode: consumerServiceRequest.CountryCode ? consumerServiceRequest.CountryCode : undefined,
				PlanID: planID,
				PlanCoverageID: soldPlanCoverage?.PlanCoverageID,
			};

			if(!countriesChecks.skipZipCodeCheck){
				carryInRequestObj.Zipcode = address?.PinCode
			}
		}
		if(legalConfigsList && legalConfigsList.length > 0) {
			carryInRequestObj.Consent = legalConfigsList.map((data) => ({
				ApplicationLegalConfigID: data.ApplicationLegalConfigID,
				ConsumerID: consumerId,
				Attributes: [
					{
						AttributeType: "imeiOrSerial",
						AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
					},
				],
				ConsentText: data.ConsentText,
				LegalConfigCode: data.LegalConfigCode,
				Selected:data.Selected,
				UnselectedText:data.UnselectedText
			}));
		}
		carryInRequestObj.chargeMaxDeductible=chargeMaxDeductible;
		carryInRequestObj.tokenizeFlow=tokenizeFlow;
		apiCall({
			url: 'scheduleRepair',
			data: carryInRequestObj,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					onScheduleRequestSuccess(result);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const scheduleOnsiteRequest = () => {
		setLoader(true);
        let scheduleRequestObj = {}
		if(singleServiceLocation) {
			scheduleRequestObj = {
				Address: address?.Address,
				AddressType: address?.AddressType,
				//Description: 'testing',
				Landmark: address?.Landmark,
				Lat: address?.Lat,
				Lng: address?.Lng,
				ConsumerFavouriteLocationID: address?.ConsumerFavouriteLocationID,
				ServiceRequestType: 'Now',
				ScheduledDateTime: scheduleDate && moment(scheduleDate.date).utc(),
				ScheduledFromTime: slot && slot.StartTimeVal,
				ScheduledToTime: slot && slot.EndTimeVal,
				BrandID: consumerProduct?.BrandID,
				IsNonPartner: 0,
				PartnerID: serviceAvailability?.OnsiteDetails?.CLAIM_ONSITE?.PartnerID,
				PartnerServiceLocationID: serviceAvailability?.OnsiteDetails?.CLAIM_ONSITE?.PartnerServiceLocationID,
				ProductSubCategoryID: consumerProduct?.ProductSubCategoryID,
				ProductID: consumerProduct?.ProductID,
				Documents: [],
				ConsumerID: consumerServiceRequest?.ConsumerID,
				ConsumerProductID: consumerProduct?.ConsumerProductID,
				walkin: walkIn,
				ServiceTypeID: SERVICETYPE.CLAIM_ONSITE,
				ConsumerServiceRequestID: consumerServiceRequest?.ConsumerServiceRequestID,
				ProductUniqueID: consumerProduct?.ProductUniqueID,
				State: address?.State,
				StateID : address?.StateID,
				City: address?.City,
				District: address?.District,
				SoldPlanID: consumerServiceRequest?.SoldPlanID,
				CountryID: consumerServiceRequest?.CountryID,
				CountryCode: consumerServiceRequest?.CountryCode,
				PlanID: planID,
				PlanCoverageID: soldPlanCoverage?.PlanCoverageID,
				chargeMaxDeductible:chargeMaxDeductible,
				tokenizeFlow:tokenizeFlow
			};

			if(!countriesChecks.skipZipCodeCheck){
				scheduleRequestObj.Zipcode = address.PinCode ? address.PinCode : address.ZipCode;
			}
		} else {
			scheduleRequestObj = {
				Address: address?.Address,
				Landmark: address?.Landmark,
				AddressType: address?.AddressType,
				ConsumerFavouriteLocationID :address?.ConsumerFavouriteLocationID,
				Lat: address?.Lat,
				Lng: address?.Lng,
				IsUnderWarranty: consumerProduct?.IsUnderWarranty,
				ServiceRequestType: 'Now',
				ScheduledDateTime: scheduleDate && moment(scheduleDate.date).utc(),
				ScheduledFromTime: slot && slot.StartTimeVal,
				ScheduledToTime: slot && slot.EndTimeVal,
				BrandID: consumerProduct?.BrandID,
				IsNonPartner: 0,
				PartnerID: serviceAvailability?.OnsiteDetails?.CLAIM_ONSITE?.PartnerID,
				PartnerServiceLocationID: serviceAvailability?.OnsiteDetails?.CLAIM_ONSITE?.PartnerServiceLocationID,
				ProductSubCategoryID: consumerProduct?.ProductSubCategoryID,
				ProductID: consumerProduct?.ProductID,
				ServiceTypeID: SERVICETYPE.CLAIM_ONSITE,
				Documents: [],
				ConsumerID: consumerServiceRequest?.ConsumerID,
				ConsumerProductID: consumerProduct?.ConsumerProductID,
				walkin: walkIn,
				IsUrgent: false,
				ConsumerServiceRequestID: consumerServiceRequest?.ConsumerServiceRequestID,
				ProductUniqueID: consumerProduct?.ProductUniqueID,
				State: address?.State,
				StateID : address?.StateID,
				City: address?.City,
				District: address?.District,
				SoldPlanID: consumerServiceRequest?.SoldPlanID,
				SoldPlanCoverageID: soldPlanCoverage?.SoldPlanCoverageID,
				CountryID: consumerServiceRequest?.CountryID,
				CountryCode: consumerServiceRequest?.CountryCode,
				PlanID: planID,
				PlanCoverageID: soldPlanCoverage?.PlanCoverageID,
				chargeMaxDeductible:chargeMaxDeductible,
				tokenizeFlow:tokenizeFlow
			};

			if(!countriesChecks.skipZipCodeCheck){
				scheduleRequestObj.Zipcode = address?.PinCode
			}
		}
		if(legalConfigsList && legalConfigsList.length > 0) {
			scheduleRequestObj.Consent = legalConfigsList.map((data) => ({
				ApplicationLegalConfigID: data.ApplicationLegalConfigID,
				ConsumerID: consumerId,
				Attributes: [
					{
						AttributeType: "imeiOrSerial",
						AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
					},
				],
				ConsentText: data.ConsentText,
				LegalConfigCode: data.LegalConfigCode,
				Selected:data.Selected,
				UnselectedText:data.UnselectedText
			}));
		}

		apiCall({
			url: 'scheduleRepair',
			data: scheduleRequestObj,
			// headers: requestHeaders ? requestHeaders : undefined,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					onScheduleRequestSuccess(result);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const scheduleDropOffRequest = () => {
		setLoader(true); 

		let partnerId = secondryAddressServiceAvailabilityData && secondryAddressServiceAvailabilityData.dropOffServicePartner ? secondryAddressServiceAvailabilityData.dropOffServicePartner.PartnerID : serviceAvailabilityData && serviceAvailabilityData.dropOffServicePartner && serviceAvailabilityData.dropOffServicePartner.PartnerID;
		let partnerServiceLocationId = secondryAddressServiceAvailabilityData && secondryAddressServiceAvailabilityData.dropOffServicePartner ? secondryAddressServiceAvailabilityData.dropOffServicePartner.PartnerServiceLocationID : serviceAvailabilityData && serviceAvailabilityData.dropOffServicePartner && serviceAvailabilityData.dropOffServicePartner.PartnerServiceLocationID;

		let dropoffObject = {
			Address: address?.Address,
			AddressType: address?.AddressType,
			Landmark: address?.Landmark,
			Lat: address?.Lat,
			Lng: address?.Lng,
			Zipcode: address?.PinCode,
			ConsumerFavouriteLocationID :address?.ConsumerFavouriteLocationID,
			ProductUniqueID: consumerProduct?.ProductUniqueID,
			ScheduledDateTime: scheduleDate && moment(scheduleDate.date).utc(),
			ScheduledFromTime: slot && slot.StartTimeVal,
			ScheduledToTime: slot && slot.EndTimeVal,
			DeliveryMode: deliveryMode,
			ServiceRequestType: "Now",
			ConsumerServiceRequestID: consumerServiceRequest?.ConsumerServiceRequestID,
			IsUrgent: false,
			ServiceTypeID: serviceMode?.ServiceTypeID,
			IsUnderWarranty: consumerProduct?.IsUnderWarranty,
			ConsumerID: consumerServiceRequest?.ConsumerID,
			ConsumerProductID: consumerProduct?.ConsumerProductID,
			BrandID: consumerProduct?.BrandID,
			ProductSubCategoryID: consumerProduct?.ProductSubCategoryID,
			IsNonPartner: 0,
			PartnerID: partnerId,
			PartnerServiceLocationID: partnerServiceLocationId,
			State: address?.State,
			StateID : address?.StateID,
            City: address?.City,
            District: address?.District,
			SoldPlanID: consumerServiceRequest?.SoldPlanID,
			SoldPlanCoverageID: soldPlanCoverage?.SoldPlanCoverageID,
			DropOffCenter: serviceLocation,
			deliveryAddress: secondaryAddress,
			CountryID: consumerServiceRequest?.CountryID,
        	CountryCode: consumerServiceRequest?.CountryCode,
			PlanID: planID,
			PlanCoverageID: soldPlanCoverage?.PlanCoverageID,
			ExternalSuppressLogisticsRequest:isSupressLogisticsCutomerToASC,
			chargeMaxDeductible:chargeMaxDeductible,
			tokenizeFlow:tokenizeFlow
		}
		if(isEmptyBoxServiceable && [SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].indexOf(serviceMode.ServiceTypeID) > -1){
            dropoffObject.EmptyBoxLocationID = emptyBoxLocationID;
            dropoffObject.isEmptyBoxServiceable = true;
        }
		//if(serviceMode.logisticsSuppressed || isSupressLogisticsCutomerToASC){
			dropoffObject.ScheduledFromTime = "09:00:00";
			dropoffObject.ScheduledToTime = "14:00:00";
			dropoffObject.ScheduledDateTime = moment.utc().format();
		//}
		if(legalConfigsList && legalConfigsList.length > 0) {
			dropoffObject.Consent = legalConfigsList.map((data) => ({
				ApplicationLegalConfigID: data.ApplicationLegalConfigID,
				ConsumerID: consumerId,
				Attributes: [
					{
						AttributeType: "imeiOrSerial",
						AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
					},
				],
				ConsentText: data.ConsentText,
				LegalConfigCode: data.LegalConfigCode,
				Selected:data.Selected,
				UnselectedText:data.UnselectedText
			}));
		}
		apiCall({
			url: 'scheduleRepair',
			data: dropoffObject,
			// headers: headers,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					onScheduleRequestSuccess(result);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const scheduleReplacement = () => {
		setLoader(true); 
		let replacementObject = {
			Address: address?.Address,
			AddressType: address?.AddressType,
			Landmark: address?.Landmark,
			Lat: address?.Lat,
			Lng: address?.Lng,
			Zipcode: address?.PinCode,
			ConsumerFavouriteLocationID: address?.ConsumerFavouriteLocationID,
			ProductUniqueID: consumerProduct?.ProductUniqueID,
			DeliveryMode: deliveryMode,
			ServiceRequestType: "Now",
			ConsumerServiceRequestID: consumerServiceRequest?.ConsumerServiceRequestID,
			IsUrgent: false,
			ServiceTypeID: serviceMode?.ServiceTypeID,
			IsUnderWarranty: consumerProduct?.IsUnderWarranty,
			ConsumerID: consumerServiceRequest?.ConsumerID,
			ConsumerProductID: consumerProduct?.ConsumerProductID,
			BrandID: consumerProduct?.BrandID,
			ProductSubCategoryID: consumerProduct?.ProductSubCategoryID,
			IsNonPartner: 0,
			PartnerID: serviceAvailability?.PartnerID,
			PartnerServiceLocationID: serviceAvailability?.PartnerServiceLocationID,
			State: address?.State,
			StateID : address?.StateID,
            City: address?.City,
            District: address?.District,
			SoldPlanID: consumerServiceRequest?.SoldPlanID,
			SoldPlanCoverageID: soldPlanCoverage?.SoldPlanCoverageID,
			PlanID: planID,
			PlanCoverageID: soldPlanCoverage?.PlanCoverageID,
			ClientServiceLocationID: serviceLocation ? serviceLocation.ClientServiceLocationID : undefined,
			ClientServiceLocationDetails: serviceLocation && serviceLocation.ClientServiceLocationID ? serviceLocation : undefined,
			chargeMaxDeductible:chargeMaxDeductible,
			tokenizeFlow:tokenizeFlow
		}
		if(replacementOptions && replacementOptions.length){
			replacementObject.DeviceOptions = replacementOptions;
			replacementObject.SelectedReplacementOption = selectedReplacementOption && selectedReplacementOption.Option;
		}
		if (replacementObject.ServiceTypeID == SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE) {
			if (replacementObject.DeviceOptions) {
				for (let i = 0; i < replacementObject.DeviceOptions.length; i++) {
					delete replacementObject.DeviceOptions[i].ServiceLocations
				}
			}
			if (replacementObject.ClientServiceLocationDetails) {
				if (replacementObject.ClientServiceLocationDetails.City) {
					replacementObject.ClientServiceLocationDetails.address = replacementObject.ClientServiceLocationDetails.address + ', ' + replacementObject.ClientServiceLocationDetails.City
				}
				if (replacementObject.ClientServiceLocationDetails.State) {
					replacementObject.ClientServiceLocationDetails.address = replacementObject.ClientServiceLocationDetails.address + ', ' + replacementObject.ClientServiceLocationDetails.State
				}
				if (replacementObject.ClientServiceLocationDetails.Zipcode) {
					replacementObject.ClientServiceLocationDetails.address = replacementObject.ClientServiceLocationDetails.address + ', ' + replacementObject.ClientServiceLocationDetails.Zipcode
				}
				// for country
				// if (replacementObject.ClientServiceLocationDetails.City) {
				// 	replacementObject.ClientServiceLocationDetails.address == replacementObject.ClientServiceLocationDetails.address + ', ' + replacementObject.ClientServiceLocationDetails.City
				// }
			}
		}
		if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(replacementObject.ServiceTypeID) > -1) {
			replacementObject.PartnerID = serviceAvailability?.replacementDetails?.PartnerID;
			replacementObject.PartnerServiceLocationID = serviceAvailability?.replacementDetails?.PartnerServiceLocationID;
		}
		if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(replacementObject.ServiceTypeID) > -1) {
			replacementObject.PartnerID = serviceAvailability?.advanceExchangeDetails?.PartnerID;
			replacementObject.PartnerServiceLocationID = serviceAvailability?.advanceExchangeDetails?.PartnerServiceLocationID;
		}
		if([SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(replacementObject.ServiceTypeID) > -1) {
			replacementObject.PartnerID = serviceAvailabilityData.collectionLocations && serviceAvailabilityData.collectionLocations.length > 0 && serviceAvailabilityData.collectionLocations[0].PartnerID;
			replacementObject.PartnerServiceLocationID = serviceAvailabilityData.collectionLocations && serviceAvailabilityData.collectionLocations.length > 0 && serviceAvailabilityData.collectionLocations[0].PartnerServiceLocationID;
		}
		if(legalConfigsList && legalConfigsList.length > 0) {
			replacementObject.Consent = legalConfigsList.map((data) => ({
				ApplicationLegalConfigID: data.ApplicationLegalConfigID,
				ConsumerID: consumerId,
				Attributes: [
					{
						AttributeType: "imeiOrSerial",
						AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
					},
				],
				ConsentText: data.ConsentText,
				LegalConfigCode: data.LegalConfigCode,
				Selected:data.Selected,
				UnselectedText:data.UnselectedText
			}));
		}
		apiCall({
			url: 'scheduleRepair',
			data: replacementObject,
			// headers: headers,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					onScheduleRequestSuccess(result);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const scheduleRequest = () => {
		setLoader(true); 

        let scheduleRequestObj = {
			Address: address?.Address,
			AddressType: address?.AddressType,
			Issues: [],
			Landmark: address?.Landmark,
			Lat: address?.Lat,
			Lng: address?.Lng,
			ConsumerFavouriteLocationID: address?.ConsumerFavouriteLocationID,
			ScheduledDateTime: scheduleDate && moment(scheduleDate.date).utc(),
			ScheduledFromTime: slot && slot.StartTimeVal,
			ScheduledToTime: slot && slot.EndTimeVal,
			ServiceRequestType: "Now",
			// ConsumerServiceRequestID: 0,
			IsUrgent: false,
			ServiceTypeID: serviceMode?.ServiceTypeID,
			//Zipcode: address?.PinCode,
			IsUnderWarranty: consumerProduct?.IsUnderWarranty,
			ConsumerID: consumerServiceRequest?.ConsumerID,
			Documents: [],
			ConsumerProductID: consumerProduct?.ConsumerProductID,
			ProductSubCategoryID: consumerProduct?.ProductSubCategoryID, //Extra params
			BrandID: consumerProduct?.BrandID, //Extra params
			ProductID: consumerProduct?.ProductID, //Extra params
			State: address?.State,
			StateID : address?.StateID,
			City: address?.City,
			District: address?.District,
			SoldPlanID: consumerServiceRequest?.SoldPlanID,
			CountryID: consumerServiceRequest?.CountryID,
			CountryCode: consumerServiceRequest?.CountryCode,
			chargeMaxDeductible:chargeMaxDeductible,
			tokenizeFlow:tokenizeFlow
		};

		if(!countriesChecks.skipZipCodeCheck){
			scheduleRequestObj.Zipcode = address?.PinCode
		}
		if(legalConfigsList && legalConfigsList.length > 0) {
			scheduleRequestObj.Consent = legalConfigsList.map((data) => ({
				ApplicationLegalConfigID: data.ApplicationLegalConfigID,
				ConsumerID: consumerId,
				Attributes: [
					{
						AttributeType: "imeiOrSerial",
						AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
					},
				],
				ConsentText: data.ConsentText,
				LegalConfigCode: data.LegalConfigCode,
				Selected:data.Selected,
				UnselectedText:data.UnselectedText
			}));
		}
		apiCall({
			url: 'scheduleRequest',
			data: scheduleRequestObj,
			// headers: headers,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					onScheduleRequestSuccess(result);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const checkForLogisticsModal = () => {
		if(serviceAvailability.isDedicatedDialog) {
			let vendorDetails;
			if (serviceAvailability && serviceAvailability.VendorList && serviceAvailability.VendorList.length > 0) {
				vendorDetails = serviceAvailability.VendorList[0]
			}
            let html = ''
            if(vendorDetails && vendorDetails.VendorInstructions && vendorDetails.VendorInstructions.InstructionTitle) {
                html  = '<p style = "text-align:left;padding-left:20px;margin-bottom:10px;">' + localString(vendorDetails.VendorInstructions.InstructionTitle) + '</p>'
                if (vendorDetails && vendorDetails.VendorInstructions && vendorDetails.VendorInstructions.InstructionTitle && vendorDetails.VendorInstructions.InstructionTitle.length > 0 ) {
                    html = html + '<ol style = "font-size:14px;line-height:1.4;">'
                    for (var iCount=0;iCount<vendorDetails.VendorInstructions.Instructions.length;iCount ++ ) {
                        html = html + '<li style = "text-align:left;margin-bottom:5px;">' + localString(vendorDetails.VendorInstructions.Instructions[iCount].text) + '</li>'
                    }
                    html = html + '</ol>'
                }
            }
			Swal.fire({  
				icon: 'warning',
				title: localString("Pickup Drop By")+ " " + (vendorDetails.DisplayName ? localString(vendorDetails.DisplayName) : 'our logistics partner'),
				html: html ? html : '',
				showCancelButton: false,
				showConfirmButton: true,
                confirmButtonText: localString('OK')
			}).then((result) => {
				checkAvailabilityForDropOff();
			});
		}else if(serviceAvailability.isChooseLogisticsPartner) {
			let vendorArr = serviceAvailability.VendorList;
			setVendorList(serviceAvailability.VendorList);
			let vendorObj
			vendorArr.map(a => {
				if(a.Priority == 1) {
					vendorObj = a;
				}
			})
			setVendor(vendorObj);
			setDeliveryMode(vendorObj ? vendorObj.VendorID : serviceAvailability.DeliveryMode)
			setShowLogisticsPartnerModal(true);
		}else {
            checkAvailabilityForDropOff();
			if(showSlotSelection) {
				// setScreenToViewDiv('scheduleDateAndTime');
			}
		}
	}

	const checkAvailabilityForPickUp = () => {
		setShowServiceLocationsCarryIn(false);
		setShowExternalReferenceID(false);
		setShowNoService(false);
		setServiceAvailability(serviceAvailabilityData.logisticPartner);
		checkForLogisticsModal();
	}

	const checkAvailabilityForDropOff = () => {
		setShowServiceLocationsCarryIn(false);
		setShowSlotSelection(false);
		setShowExternalReferenceID(false);
		setShowNoService(false);
		// setIsSameAddress(true);
		setShowIsSameAddress(true)
	}

	const getServicelocationForClaimCarryIn = () => { // Get SCORE based service locations
		setShowSlotSelection(false);
		setLoader && setLoader(true); 
		let reqObj = {
			soldPlanID: consumerServiceRequest?.SoldPlanID,
			countryID: address ? address.CountryID : '',
			brandID:consumerProduct?.BrandID,
		};
		let arr = [];
		if(serviceAvailabilityData?.serviceLocations?.length){
			serviceAvailabilityData.serviceLocations.map(item=>{
				let obj={
					"ClientServiceLocationID": item.ClientServiceLocationID ? item.ClientServiceLocationID :null,
					"PartnerServiceLocationID":item.PartnerServiceLocationID ? item.PartnerServiceLocationID :null
				}
				arr.push(obj);
			})
		}
		reqObj.serviceLocations = arr;
		apiCall({
			url: 'getServiceAvailabilityResolutionRate',
			data:reqObj,
			callBack: (result) => {
				setLoader && setLoader(false); 
				if(result && result.success && result.data && result.data.length ) {
					let arr=[...serviceAvailabilityData.serviceLocations];
					let serviceListArr =[];
					arr.map(item=>{
						let obj ={...item};
						result.data.map(scoreItem=>{
							if((scoreItem.ClientServiceLocationID ? (scoreItem.ClientServiceLocationID == item.ClientServiceLocationID) : (scoreItem.PartnerServiceLocationID == item.PartnerServiceLocationID))){
								obj.score=scoreItem.Score;
								obj.scoreMeta=scoreItem.ScoreMeta;
							}
						})
						serviceListArr.push(obj);
					})
					setServiceLocationList(serviceListArr);
				}
			},
			errorCB: (err) => {
				setLoader && setLoader(false);
			}
		});
		setShowServiceLocationsCarryIn(true);
	}

	const getServiceLocationsForCarryIn = (serviceModeObj) => {
		setShowSlotSelection(false);
		setShowNoService(false);
		if(serviceModeObj?.ServiceTypeID && [SERVICETYPE.CARRY_IN].includes(serviceModeObj.ServiceTypeID)) {
			getServicelocationForClaimCarryIn();
		}else if([SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(serviceModeObj.ServiceTypeID) > -1) {
			setServiceLocationList(replacementOptions && replacementOptions.length ? replacementOptions[0].ServiceLocations : []);
			setShowServiceLocationsCarryIn(true);
		}else {
			if(serviceAvailabilityData && serviceAvailabilityData.serviceLocations) {
				setServiceLocationList(serviceAvailabilityData.serviceLocations);
				setShowServiceLocationsCarryIn(true);
				// setScreenToViewDiv('serviceLocation');
			}else {
				Swal.fire({  
					icon: 'error',  
					text: localString('Something went wrong'),  
				});
			}
		}
	}
	
	// const getServiceLocationsForCarryIn = (serviceModeObj) => {
	// 	setShowSlotSelection(false);
	// 	setShowNoService(false);
	// 	if([SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(serviceModeObj.ServiceTypeID) > -1) {
	// 		setServiceLocationList(replacementOptions && replacementOptions.length ? replacementOptions[0].ServiceLocations : []);
	// 	}else {
	// 		let locations = serviceAvailabilityData && serviceAvailabilityData.serviceLocations
	// 		setServiceLocationList(locations.length ? locations : []);
	// 	}
	// 	setShowServiceLocationsCarryIn(true);
	// 	// if(serviceAvailabilityData && serviceAvailabilityData.serviceLocations) {
	// 	// 	setServiceLocationList(serviceAvailabilityData.serviceLocations);
	// 	// }else {
	// 	// 	Swal.fire({  
	// 	// 		icon: 'error',  
	// 	// 		text: localString('Something went wrong'),  
	// 	// 	});
	// 	// }
	// }

	const slotsNext = () => {
		if([SERVICETYPE.BUY_BACK, SERVICETYPE.INSTALLATION, SERVICETYPE.DEMO, SERVICETYPE.SERVICING, SERVICETYPE.DELIVERY, SERVICETYPE.RECYCLE_PICK_UP, SERVICETYPE.CLAIM].includes(serviceMode.ServiceTypeID)) {
			setCallRequestFunction(true);
		}
	}

	const scheduleBuyout = (payoutAttri) => {
		setLoader(true); 
        let scheduleBuyOutObj = {
			Address: address?.Address,
			AddressType: address?.AddressType,
			Landmark: address?.Landmark,
			Lat: address?.Lat,
			Lng: address?.Lng,
			Zipcode: address?.PinCode,
			ConsumerFavouriteLocationID: address?.ConsumerFavouriteLocationID,
			ProductUniqueID: consumerProduct?.ProductUniqueID,
			DeliveryMode: deliveryMode ? deliveryMode : undefined,
			ServiceRequestType: "Now",
			ConsumerServiceRequestID: consumerServiceRequest?.ConsumerServiceRequestID,
			IsUrgent: false,
			ServiceTypeID: serviceMode?.ServiceTypeID,
			IsUnderWarranty: consumerProduct?.IsUnderWarranty,
			ConsumerID: consumerServiceRequest?.ConsumerID,
			ConsumerProductID:consumerProduct?.ConsumerProductID,
			BrandID: consumerProduct?.BrandID,
			ProductSubCategoryID: consumerProduct?.ProductSubCategoryID,
			IsNonPartner: 0,
			PartnerID: serviceAvailability?.PartnerID,
			PartnerServiceLocationID: serviceAvailability.buyoutDetails && serviceAvailability.buyoutDetails.PartnerServiceLocationID,
			State: address?.State,
			StateID : address?.StateID,
            City: address?.City,
            District: address?.District,
			SoldPlanID: consumerServiceRequest?.SoldPlanID,
			SoldPlanCoverageID: soldPlanCoverage?.SoldPlanCoverageID,
			Attributes: payoutAttri,
			PlanID: planID,
			PlanCoverageID: soldPlanCoverage?.PlanCoverageID,
			chargeMaxDeductible:chargeMaxDeductible,
			tokenizeFlow:tokenizeFlow
		}
		if(legalConfigsList && legalConfigsList.length > 0) {
			scheduleBuyOutObj.Consent = legalConfigsList.map((data) => ({
				ApplicationLegalConfigID: data.ApplicationLegalConfigID,
				ConsumerID: consumerId,
				Attributes: [
					{
						AttributeType: "imeiOrSerial",
						AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
					},
				],
				ConsentText: data.ConsentText,
				LegalConfigCode: data.LegalConfigCode,
				Selected:data.Selected,
				UnselectedText:data.UnselectedText
			}));
		}

		apiCall({
			url: 'scheduleRepair',
			data: scheduleBuyOutObj,
			// headers: headers,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					onScheduleRequestSuccess(result);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const getStates = (CountryID) => {
		// setLoader(true);
		let reqObj = {
			CountryID: CountryID
		}
		apiCall({
			url: 'getStates',
			data: reqObj,
			callBack: (result) => {
				// setLoader(false); 
				if(result.success && result.data){
					setStateList(result.data);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			// errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		if(autoClaimFulfillmentFlow) {
			setActiveStep('carryInType');
		}
		getStates(selectedCountryData?.CountryID);
	}, []);

	const getReplacementOptions = (serviceModeObj) => {
		setLoader(true);
		let reqObj = {
            CountryID: consumerServiceRequest?.consumer?.CountryID ? consumerServiceRequest.consumer.CountryID : undefined,
            ConsumerProductID: consumerProduct?.ConsumerProductID,
            ServiceTypeID: serviceModeObj?.ServiceTypeID,
            PlanID: planID ? planID : undefined,
			PlanCoverageID: soldPlanCoverage && soldPlanCoverage.PlanCoverageID
		}
		if(serviceModeObj && [SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(serviceModeObj.ServiceTypeID) > -1) {
            reqObj.PartnerID = serviceAvailabilityData.replacementDetails && serviceAvailabilityData.replacementDetails.PartnerID;
        }
        if(serviceModeObj && [SERVICETYPE.ADVANCE_EXCHANGE].indexOf(serviceModeObj.ServiceTypeID) > -1) {
            reqObj.PartnerID = serviceAvailabilityData.advanceExchangeDetails && serviceAvailabilityData.advanceExchangeDetails.PartnerID;
        }
		apiCall({
			url: 'getReplacementOptions',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success) {
					setShowReplacementOptions(true);
					//For Advance Exchange Flow setting the Consent Legal Configs
					// if(serviceModeObj && serviceModeObj.LegalConfigs && serviceModeObj.LegalConfigs.length > 0 && serviceModeObj.ServiceTypeID && [SERVICETYPE.ADVANCE_EXCHANGE].indexOf(serviceModeObj.ServiceTypeID) > -1){
					// 	setAdvanceExchangeLegalConfigsList(serviceModeObj.LegalConfigs);
					// }
					if(result.data && result.data.length) {
                        if(result.data[0] && result.data[0].AllocationType) {
                            setReplacementAllocationType(result.data[0].AllocationType);
                        }else {
                            setReplacementAllocationType('EXACT');
                        }
                        setReplacementOptions(result.data);
                        // setSelectedReplacementOption(result.data[0]);
                    }else {
						if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(serviceModeObj.ServiceTypeID) > -1){
							// if(serviceModeObj && serviceModeObj.LegalConfigs && (serviceModeObj.LegalConfigs.length > 0) && [SERVICETYPE.ADVANCE_EXCHANGE].indexOf(serviceModeObj.ServiceTypeID) > -1) {
							// 	setShowAdvanceExchangeConsent(true);
							// }else{
								setShowRaiseDeviceExchange(true);
							//}
						}else if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(serviceModeObj.ServiceTypeID) > -1){
							setShowRaiseReplacementRequest(true);
						}
                    }
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)), 
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const getServiceLocationsForLnT = (serviceModeObj) => {
		setLoader(true);
		let reqObj = {
            CountryID: consumerServiceRequest?.consumer?.CountryID ? consumerServiceRequest.consumer.CountryID : undefined,
			serviceLocations: serviceAvailabilityData?.collectionLocations,
			ServiceTypeID: serviceModeObj.ServiceTypeID,
			SoldPlanID: consumerServiceRequest?.SoldPlanID,
			SoldPlanCoverageID: soldPlanCoverage?.SoldPlanCoverageID,
			PlanID: planID ? planID : undefined,
			PlanCoverageID: soldPlanCoverage?.PlanCoverageID,
			Zipcode: address.PinCode ? address.PinCode : address.Zipcode ? address.Zipcode : null,
			State: address.State,
			StateID : address.StateID,
			ConsumerProductID: consumerServiceRequest.ConsumerProductID,
			ProductID: consumerServiceRequest.consumerProduct.ProductID,
			ProductSubCategoryID: consumerServiceRequest.consumerProduct.ProductSubCategoryID,
			BrandID: consumerServiceRequest.consumerProduct.BrandID,
			Category: 'repair',
			Radius: 80,
			Partnered: 1,
			isInternalModule: false,
		}
		apiCall({
			url: 'getCollectionServiceableLocation',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success) {
					setShowReplacementOptions(true);
					if(result.data && result.data.length) {
                        if(result.data[0] && result.data[0].AllocationType) {
                            setReplacementAllocationType(result.data[0].AllocationType);
                        }else {
                            setReplacementAllocationType('EXACT');
                        }
                        setReplacementOptions(result.data);
                        // setSelectedReplacementOption(result.data[0]);
                    }else {
						if([SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(serviceModeObj.ServiceTypeID) > -1){
							setShowRaiseDeviceExchange(true);
						}
                    }
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)), 
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const checkSuppressLogistics = () => {
		if(serviceMode.logisticsSuppressed || isSupressLogisticsCutomerToASC){
			// slotsNext();
			setCallRequestFunction(true);
		}else{
			if(dropOffAvailableForSecondAddress > -1 && [SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(serviceMode.ServiceTypeID) > -1) {
				if (secondryAddressServiceAvailabilityData && secondryAddressServiceAvailabilityData.isDropOffLocationAvailable) {
					setDropoffLocationList(secondryAddressServiceAvailabilityData.dropOffLocations);
					setShowServiceLocationsDropOff(true);
				}else {
					showInstructions();
				}
			}else{
				setCallGetScheduleDateAndSlotApi(true);
				setShowSlotSelection(true);
			}
		}
	}

	const carryInCustomerTypeSelected = (value) => {
		console.log('carryInCustomerTypeSelected', value)
		setCarryInType(value);
		let singleServiceLocationFlag = false;
		setShowSlotSelection(false);
		setAddress(null);
		setShowAddress(false);
		if(!qFilter.serviceLocations || (qFilter.serviceLocations && qFilter.serviceLocations.length > 1)) {
			singleServiceLocationFlag = false;
		}else if(qFilter.serviceLocations && qFilter.serviceLocations.length == 1){
			singleServiceLocationFlag = true;
			setServiceLocation({ PartnerServiceLocationID: qFilter.serviceLocations[0] });
		}
		if(value === 'radio_walkin') {
			setWalkIn(true);
			setServiceMode({ ServiceTypeID: SERVICETYPE.CARRY_IN });
			setShowSlotSelection(false);
			setShowServiceLocationsCarryIn(false);
			setShowExternalReferenceID(false);
			setShowNoService(false);
			setShowClaimForm(false);
			setShowServiceModes(false);
			if(singleServiceLocationFlag){
				setCallGetScheduleDateAndSlotApi(true);
			}else {
				setActiveStep('address');
				setShowAddress(true);
				setAddress(null);
				setActiveStepIndex(activeStepIndex + 1);
			}
			setSingleServiceLocation(singleServiceLocationFlag);
		}else {
			setWalkIn(false);
			setServiceMode({});
			setShowNoService(false);
			setShowServiceModes(false);
			setShowSlotSelection(false);
			setShowClaimForm(false);
			setActiveStep('address');
			// setActiveStepIndex(activeStepIndex + 1);
			setShowAddress(true);
		}
	}
	const getPaymentActions = function(){
		setLoader(true);
		let reqObj = {
            countryID: consumerObj ? consumerObj.CountryID : undefined,
            planID: planID ? planID : undefined,
			checkForTokenization: true,
			consents:legalConfigsList
		}
		apiCall({
			url: 'getPaymentActions',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data) {
                   setChargeMaxDeductible(result.data.chargeMaxDeductible);
				   setTokenizeFlow(result.data.tokenizeFlow);
				}
			}
		});
	}


	return (
		<>
			{
				(loader || defectivePartnerloader) && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper
						bodyClassName="panel-body-no-padding" 
						title={localString("Raise Request")}
					>
						<div className='AdminRaiseRequest'>
							<MultiStepProgressBar 
								activeStep={activeStepIndex}
								steps={pickUpMode ? pickUpModeArr : autoClaimFulfillmentFlow ? autoClaimFulfillmentFlowArr : carryInModeArr}
							/>
							{/* <DotProgressBar 
								currentIndex={2} 
								progressBarItems={['Enter Details', 'Confirmation', 'Trade-In Value']} 
								showTick={true}
								// showStepCount={true}
							/> */}							
							<div>
								{/* ********** Carry in type selection ********** */}
								{
									activeStep === 'carryInType' &&
										<CarryInTypeSelection
											{...props}
											walkIn={walkIn}
											type={'adminRaiseRequest'}
											carryInType={carryInType} setCarryInType={setCarryInType}
											carryInCustomerTypeSelected={carryInCustomerTypeSelected}
										/>
								}
								{/* ********** Select Or Add Address (Primary Address) ********** */}
								{
									activeStep === 'address' && <>
										{ autoClaimFulfillmentFlow && <div>
											<Button
												className="btn back-button" 
												onClick={() => {
													setActiveStep('carryInType');
													setActiveStepIndex(0)
												}}
												// isDisabled={disableSecondaryBtn}
											/>
										</div> }
										<div style={{ padding: '10px 30px' }}>
											<Address
												{...props}
												walkIn={walkIn}
												country={country}
												setLoader={setLoader}
												stateList={stateList}
												consumerId={consumerId}
												identifier="claimAddress"
												hideSection={hideSection}
												clearAddressStates={false}
												setActiveStep={setActiveStep}
												event="RaiseRequestOnRequestPage"
												activeStepIndex={activeStepIndex}
												setActiveStepIndex={setActiveStepIndex}
												setScreenToViewDiv={setScreenToViewDiv}
												selectedCountryData={selectedCountryData}
												address={address} setAddress={setAddress}
												getScheduleDateAndSlot={getScheduleDateAndSlot}
												skipZipCodeCheck={countriesChecks.skipZipCodeCheck} 
												csrid={consumerServiceRequest.ConsumerServiceRequestID}
												consumerProduct={consumerServiceRequest.consumerProduct}
												showAddress={showAddress} setShowAddress={setShowAddress}
												pinCodeLengthArr={countriesChecks.arrAllowedPinCodeLengths}
												setConsumerFavouriteLocationID={setConsumerFavouriteLocationID}
												getServiceAvailabilityWithLocations={getServiceAvailabilityWithLocations}
												// secondaryAddress={secondaryAddress} setSecondaryAddress={setSecondaryAddress}
											/>
										</div>
									</>
								}
								{/* ********** Select Service Mode ********** */}
								{
									activeStep === 'serviceMode' && <>
										<div>
											<Button
												className="btn back-button" 
												onClick={() => {
													setActiveStep('address');
													setActiveStepIndex(0);
													setServiceMode({});
													setShowIsSameAddress(true);
													setSecondaryAddress(null);
													setIsSameAddress(true);
												}}
												// isDisabled={disableSecondaryBtn}
											/>
										</div>
										<div style={{ padding: '10px 30px' }}>
											<ServiceMode
												{...props}
												request={request}
												type='adminRaiseRequest'
												hideSection={hideSection}
												setActiveStep={setActiveStep}
												setPickUpMode={setPickUpMode}
												setCarryInMode={setCarryInMode}
												currencySymbol={currencySymbol}
												activeStepIndex={activeStepIndex}
												serviceTypeArray={serviceTypeArray}
												setOpenPayoutModes={setOpenPayoutModes}
												setShowPayoutModal={setShowPayoutModal}
												setActiveStepIndex={setActiveStepIndex}
												selectedCountryData={selectedCountryData}
												scheduleReplacement={scheduleReplacement}
												getReplacementOptions={getReplacementOptions}
												isEmptyBoxServiceable={isEmptyBoxServiceable}
												singleServiceLocation={singleServiceLocation}
												setCallRequestFunction={setCallRequestFunction}
												getScheduleDateAndSlot={getScheduleDateAndSlot}
												checkForLogisticsModal={checkForLogisticsModal}
												serviceAvailabilityData={serviceAvailabilityData}
												setShowSecondaryAddress={setShowSecondaryAddress}
												autoClaimFulfillmentFlow={autoClaimFulfillmentFlow}
												getServiceLocationsForLnT={getServiceLocationsForLnT}
												setShowReplacementOptions={setShowReplacementOptions}
												setAdvanceChargeApplicable={setAdvanceChargeApplicable}
												setShowExternalReferenceID={setShowExternalReferenceID}
												checkAvailabilityForPickUp={checkAvailabilityForPickUp}
												serviceMode={serviceMode} setServiceMode={setServiceMode}
												showAddress={showAddress} setShowAddress={setShowAddress}
												checkAvailabilityForDropOff={checkAvailabilityForDropOff}
												showRaiseReplacementRequest={showRaiseReplacementRequest}
												getServiceLocationsForCarryIn={getServiceLocationsForCarryIn}
												setShowRaiseReplacementRequest={setShowRaiseReplacementRequest}
												setCallGetScheduleDateAndSlotApi={setCallGetScheduleDateAndSlotApi}
												showIsSameAddress={showIsSameAddress} setShowIsSameAddress={setShowIsSameAddress}
												showRaiseDeviceExchange={showRaiseDeviceExchange} setShowRaiseDeviceExchange={setShowRaiseDeviceExchange}
												showServiceLocationsCarryIn={showServiceLocationsCarryIn} setShowServiceLocationsCarryIn={setShowServiceLocationsCarryIn}
												showServiceLocationsDropOff={showServiceLocationsDropOff} setShowServiceLocationsDropOff={setShowServiceLocationsDropOff}
												setLegalConfigsList={setLegalConfigsList}
												legalConfigsList={legalConfigsList}
												getPaymentActions={getPaymentActions}
												setTokenizeFlow={setTokenizeFlow}
											/>
											{/* ********** Select Replacement Options ********** */}
											{
												showReplacementOptions &&
													<ReplacementOptions
														{...props}
														type='adminRaiseRequest'
														serviceMode={serviceMode}
														scheduleReplacement={scheduleReplacement}
														setCallRequestFunction={setCallRequestFunction}
														getServiceLocationsForCarryIn={getServiceLocationsForCarryIn}
														replacementOptions={replacementOptions} setReplacementOptions={setReplacementOptions}
														showRaiseDeviceExchange={showRaiseDeviceExchange} setShowRaiseDeviceExchange={setShowRaiseDeviceExchange}
														selectedReplacementOption={selectedReplacementOption} setSelectedReplacementOption={setSelectedReplacementOption}
														replacementAllocationType={replacementAllocationType} setReplacementAllocationType={setReplacementAllocationType}
														showRaiseReplacementRequest={showRaiseReplacementRequest} setShowRaiseReplacementRequest={setShowRaiseReplacementRequest}
														showAdvanceExchangeConsent={showAdvanceExchangeConsent} setShowAdvanceExchangeConsent={setShowAdvanceExchangeConsent}
													/>
											}
											{/* ********** Is same Address Section ********** */}
											{
												showIsSameAddress &&
													<Address
														{...props}
														walkIn={walkIn}
														country={country}
														setLoader={setLoader}
														stateList={stateList}
														consumerId={consumerId}
														serviceMode={serviceMode}
														hideSection={hideSection}
														clearAddressStates={false}
														setActiveStep={setActiveStep}
														identifier="sameAddresSection"
														activeStepIndex={activeStepIndex}
														showInstructions={showInstructions}
														showSlotSelection={showSlotSelection}
														setActiveStepIndex={setActiveStepIndex}
														selectedCountryData={selectedCountryData}
														address={address} setAddress={setAddress}
														getScheduleDateAndSlot={getScheduleDateAndSlot}
														setDropoffLocationList={setDropoffLocationList}
														checkSuppressLogistics={checkSuppressLogistics}
														serviceAvailabilityData={serviceAvailabilityData}
														skipZipCodeCheck={countriesChecks.skipZipCodeCheck} 
														consumerProduct={consumerServiceRequest.consumerProduct}
														showAddress={showAddress} setShowAddress={setShowAddress}
														pinCodeLengthArr={countriesChecks.arrAllowedPinCodeLengths}
														setShowServiceLocationsDropOff={setShowServiceLocationsDropOff}
														setConsumerFavouriteLocationID={setConsumerFavouriteLocationID}
														isSameAddress={isSameAddress} setIsSameAddress={setIsSameAddress}
														getServiceAvailabilityWithLocations={getServiceAvailabilityWithLocations}
														secondaryAddress={secondaryAddress} setSecondaryAddress={setSecondaryAddress}
														showIsSameAddress={showIsSameAddress} setShowIsSameAddress={setShowIsSameAddress}
														showSecondaryAddress={showSecondaryAddress} setShowSecondaryAddress={setShowSecondaryAddress}
														isSupressLogisticsCutomerToASC={isSupressLogisticsCutomerToASC} setIsSupressLogisticsCutomerToASC={setIsSupressLogisticsCutomerToASC}
													/>
											}
											{/* ********** Select Or Add Address (Secondary Address) ********** */}
											{
												showSecondaryAddress &&
													<Address
														{...props}
														walkIn={walkIn}
														country={country}
														setLoader={setLoader}
														stateList={stateList}
														consumerId={consumerId}
														hideSection={hideSection}
														clearAddressStates={false}
														setActiveStep={setActiveStep}
														identifier="secondaryAddress"
														activeStepIndex={activeStepIndex}
														event="RaiseRequestOnRequestPage"
														setActiveStepIndex={setActiveStepIndex}
														selectedCountryData={selectedCountryData}
														address={address} setAddress={setAddress}
														showSecondaryAddress={showSecondaryAddress}
														getScheduleDateAndSlot={getScheduleDateAndSlot}
														checkSuppressLogistics={checkSuppressLogistics}
														skipZipCodeCheck={countriesChecks.skipZipCodeCheck} 
														csrid={consumerServiceRequest.ConsumerServiceRequestID}
														consumerProduct={consumerServiceRequest.consumerProduct}
														showAddress={showAddress} setShowAddress={setShowAddress}
														pinCodeLengthArr={countriesChecks.arrAllowedPinCodeLengths}
														setConsumerFavouriteLocationID={setConsumerFavouriteLocationID}
														isSameAddress={isSameAddress} setIsSameAddress={setIsSameAddress}
														getServiceAvailabilityWithLocations={getServiceAvailabilityWithLocations}
														secondaryAddress={secondaryAddress} setSecondaryAddress={setSecondaryAddress}
														showIsSameAddress={showIsSameAddress} setShowIsSameAddress={setShowIsSameAddress}
														isSupressLogisticsCutomerToASC={isSupressLogisticsCutomerToASC} setIsSupressLogisticsCutomerToASC={setIsSupressLogisticsCutomerToASC}
													/>
											}
										</div>
									</>
								}
								{/* ********** Find my iphone modal ********** */}
								{/* {
									<FindMyIphoneModal
										{...props} 
										FMIPcheck={FMIPcheck}
										showFMIPModal={showFMIPModal} 
										setShowFMIPModal={setShowFMIPModal}
									/>
								} */}
								{/* ********** Select Logistics Partner ********** */}
								{
									<LogisticsPartnerModal
										{...props} 
										vendor={vendor}
										setVendor={setVendor}
										vendorList={vendorList}
										setDeliveryMode={setDeliveryMode}
										showSlotSelection={showSlotSelection}
										checkAvailabilityForDropOff={checkAvailabilityForDropOff}
										showLogisticsPartnerModal={showLogisticsPartnerModal} 
										setShowLogisticsPartnerModal={setShowLogisticsPartnerModal}
									/>
								}
								{/* ********** Select Service Location ********** */}
								{
									activeStep === 'serviceLocation' && (showServiceLocationsCarryIn || showServiceLocationsDropOff) && <>
										<div>
											<Button
												className="btn back-button" 
												onClick={() => {
													setActiveStep('serviceMode');
													setActiveStepIndex(activeStepIndex - 1);
													setServiceLocation(null);
													// setPickUpMode(true);
													// setCarryInMode(false);
												}}
												// isDisabled={disableSecondaryBtn}
											/>
										</div>
										<div style={{ padding: '10px 30px' }}>
											<ServiceLocation
												{...props} 
												address={address}
												type='adminRaiseRequest'
												serviceMode={serviceMode}
												setActiveStep={setActiveStep}
												showNoService={showNoService}
												activeStepIndex={activeStepIndex}
												showInstructions={showInstructions}
												setActiveStepIndex={setActiveStepIndex}
												dropoffLocationList={dropoffLocationList}
												serviceLocationList={serviceLocationList}
												setShowSlotSelection={setShowSlotSelection}
												getScheduleDateAndSlot={getScheduleDateAndSlot}
												setCallRequestFunction={setCallRequestFunction}
												showServiceLocationsCarryIn={showServiceLocationsCarryIn}
												showServiceLocationsDropOff={showServiceLocationsDropOff}
												setCallGetScheduleDateAndSlotApi={setCallGetScheduleDateAndSlotApi}
												serviceLocation={serviceLocation} setServiceLocation={setServiceLocation}
												dropoffLocation={dropoffLocation} setDropoffLocation={setDropoffLocation}
											/>
										</div>
									</>
								}
								{/* ********** Schedule date and slot selection ********** */}
								{
									activeStep === 'dateAndSlotSelection' && <>
										<div>
											<Button
												className="btn back-button" 
												onClick={() => {
													pickUpMode ? setActiveStep('serviceMode') : setActiveStep('serviceLocation');
													setActiveStepIndex(activeStepIndex - 1);
													setSlot(null);
													setScheduleDate(null);
												}}
												// isDisabled={disableSecondaryBtn}
											/>
										</div>
										<div style={{ padding: '10px 30px' }}>
											<SlotSelection
												{...props} 
												type='adminRaiseRequest'
												setActiveStep={setActiveStep}
												slot={slot} setSlot={setSlot}
												setActiveStepIndex={setActiveStepIndex}
												setCallRequestFunction={setCallRequestFunction}
												scheduleDate={scheduleDate} setScheduleDate={setScheduleDate}
												slotAvailable={slotAvailable} setSlotAvailable={setSlotAvailable}
												disableSlotNext={disableSlotNext} setDisableSlotNext={setDisableSlotNext}
												scheduleDateArray={scheduleDateArray} setScheduleDateArray={setScheduleDateArray}
												scheduleSlotArray={scheduleSlotArray} setScheduleSlotArray={setScheduleSlotArray}
											/>
										</div>
									</>
								}
								{/* ********** Display instruction modal on selection of service location ********** */}
								{
									<InstructionModal
										{...props} 
										walkIn={walkIn}
										type='adminRaiseRequest'
										setActiveStep={setActiveStep}
										slot={slot} setSlot={setSlot}
										instructionsArray={instructionsArray}
										setActiveStepIndex={setActiveStepIndex}
										setShowSlotSelection={setShowSlotSelection}
										isEmptyBoxServiceable={isEmptyBoxServiceable}
										getScheduleDateAndSlot={getScheduleDateAndSlot}
										setCallRequestFunction={setCallRequestFunction}
										serviceAvailabilityData={serviceAvailabilityData}
										dropOffInstructionsTitle={dropOffInstructionsTitle}
										serviceMode={serviceMode} setServiceMode={setServiceMode}
										scheduleDate={scheduleDate} setScheduleDate={setScheduleDate}
										slotAvailable={slotAvailable} setSlotAvailable={setSlotAvailable}
										serviceLocation={serviceLocation} setServiceLocation={setServiceLocation}
										scheduleDateArray={scheduleDateArray} setScheduleDateArray={setScheduleDateArray}
										scheduleSlotArray={scheduleSlotArray} setScheduleSlotArray={setScheduleSlotArray}
										showInstructionsModal={showInstructionsModal} setShowInstructionsModal={setShowInstructionsModal}
										isSupressLogisticsCutomerToASC={isSupressLogisticsCutomerToASC}
									/>
								}
								{/* ********** Payment mode modal ********** */}
								{
									showPayoutModal && 
										<PayoutModesModal
											{...props} 
											onPayoutModeSubmit={scheduleBuyout}
											customerName={consumerObj && consumerObj.Name}
											countryId={consumerObj && consumerObj.CountryID}
											customerEmail={consumerObj && consumerObj.EmailID} 
											ServiceTypeID={serviceMode && serviceMode.ServiceTypeID}
											showPayoutModal={showPayoutModal} setShowPayoutModal={setShowPayoutModal}
										/>
								}
							</div>
						</div>
					</PageContentWrapper>
				</div>
			</div>
		</>
	);
}