import React from 'react';
import {Button} from 'sfy-react';
const InitiateBERPaymentInput = (props)=>{
    const {localString,consumerServiceRequest,preBerRequestNext,closeModal} = props;
	
    let chequeDetailsTable = [
        {
            labelText:'Cheque Details',
            berPaymentData:''
        },
        {
            labelText:'Name',
            berPaymentData:consumerServiceRequest && consumerServiceRequest.CONSUMER_LEGAL_NAME
        },
        {
            labelText:'Email ID',
            berPaymentData:consumerServiceRequest && consumerServiceRequest.CONSUMER_LEGAL_EMAIL_ID
        },
        {
            labelText:'BER Refund Amount',
            berPaymentData:consumerServiceRequest && consumerServiceRequest.BER_REFUND_AMOUNT
        },
        {
            labelText:'Covered Amount',
            berPaymentData:consumerServiceRequest?.consumerProduct?.DepreciatedValue?.sumInsured
        },
        {
            labelText:'Deducted Accessory Cost',
            berPaymentData:consumerServiceRequest?.DeductedAccessoriesCost
        }
    ]

    const initiateBerPayment = () =>{
        let data = {};
        data.DeepLinkURL = window.location.origin + '/internalredirect?u=' + window.location.pathname + window.location.search;
        preBerRequestNext('BER payment initiated',data);
        closeModal();
    }
    
    return (
		<>
            <div className=''>
                <table>
                    <tbody>
                        {
                            chequeDetailsTable && chequeDetailsTable.map(item=>(
                                <tr>
                                    { item.labelText && <td className="ber-payment-td ">{item.labelText}</td>}
                                    { <td className="ber-payment-td ">{item.berPaymentData}</td>}
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className="row form-group-filter-request">
                    <div className="request-filter-button-div">
                        <Button className="btn button-servify-solid"  handleOnClick={()=>initiateBerPayment()}>{localString("OK")}</Button>
                    </div>
                </div>
            </div>
        </>
	)
}
export default InitiateBERPaymentInput;