import React, { useEffect } from "react";
import DiagnosticImagesCarousel from "./DiagnosticImagesCarousel";
import "./DiagnosticImages.scss";

const DiagnosticImages = (props) => {
	const { localString, getDiagnosticsData, diagnosticData } = props;

	useEffect(() => {
		if (getDiagnosticsData) {
			getDiagnosticsData();
		}
	}, []);

	return (
		<div>
			{diagnosticData &&
				diagnosticData.length > 0 &&
				diagnosticData.map((obj, idx) => {
					return (
						obj.images.some((d) => d.SuperimposedHeatmaps) && (
							<div
								key={obj.imei}
								className="mb-15 mt-15 diagnostics-images-wrapper"
							>
								<div className="diagnostic-images-header">
									{`${idx + 1}. ${localString("IMEI")}- ${
										obj.imei
									}`}
								</div>
								<DiagnosticImagesCarousel
									{...props}
									imagesArray={obj.images}
								/>
							</div>
						)
					);
				})}
		</div>
	);
};

export default DiagnosticImages;
