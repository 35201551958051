import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import { Loader, Checkbox } from "sfy-react";
import {
	displayError,
	returnLocalDateTime,
} from "../../../../../Utility/Utils";
import PageContentWrapper from "../../../../../components/PageContentWrapper/PageContentWrapper";
import "./IntegrationLogDetails.scss";

const IntegrationLogDetails = (props) => {
	const { apiCall, consumerServiceRequest, localString , qFilter,accessFn} = props;
	const [showLoader, setShowLoader] = useState(false);
	const [integrationLogs, setIntegrationLogs] = useState([]);
	const [integrationLogsFilters, setIntegrationLogsFilters] = useState({
		outgoingLog: true,
		incomingLog: true,
		needUserTriggered: true,
	});

	const getIntegrationLogs = () => {
		const data = {
			ConsumerServiceRequestID:
				consumerServiceRequest.ConsumerServiceRequestID,
		};
		setShowLoader(true);
		apiCall({
			url: "fetchCSRIntegrationLogs",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success && result.data) {
					setIntegrationLogs(result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	useEffect(() => {
		getIntegrationLogs();
	}, []);

	const filteredIntegrationLogs = useMemo(() => {
		let filteredLogs = [...integrationLogs];

		if (!integrationLogsFilters.incomingLog)
			filteredLogs = filteredLogs.filter((log) => !log.IsIncomingCall || log.IsUserTriggred);

		if (!integrationLogsFilters.outgoingLog)
			filteredLogs = filteredLogs.filter((log) => log.IsIncomingCall || log.IsUserTriggred);

		if (!integrationLogsFilters.needUserTriggered)
			filteredLogs = filteredLogs.filter((log) => !log.IsUserTriggred);
		
		return filteredLogs;
	}, [
		integrationLogs,
		integrationLogsFilters.incomingLog,
		integrationLogsFilters.outgoingLog,
		integrationLogsFilters.needUserTriggered,
	]);


	const getIntStatusString = (status) => {
		let statusString = "Pending";
		if (status == 200) {
			statusString = "Success";
		} else {
			statusString = "Failed";
		}
		return statusString;
	};

	const getIntStatusColor = (status) => {
		let color = "gray";
		if (status == 200) {
			color = "green";
		} else {
			color = "red";
		}
		return color;
	};

	const refreshfailedlog = (log) => {
		const data = {
			ConsumerServiceRequestID:
				consumerServiceRequest.ConsumerServiceRequestID,
				CsrIntegrationLog:log
		};
		data.CsrIntegrationLog.UserID = JSON.parse(qFilter).userID;
		setShowLoader(true);
		apiCall({
			url: "reTriggerCSRIntegrationLog",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success && result.data) {
					getIntegrationLogs();
				} else {
					getIntegrationLogs();
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	}

	if (showLoader)
		return ReactDOM.createPortal(
			<Loader />,
			document.getElementById("loader-root")
		);

	return (
		<div className="padding10">
			<div className="row">
				<PageContentWrapper title={localString("Integration Logs")}>
					<div className="row padding10 integration-history-border">
						<div className="checkbox-div">
							<div className="checkbox-div">
								<Checkbox
									value={integrationLogsFilters.incomingLog}
									id="incomingLog"
									name="incomingLog"
									id="incomingLog"
									handleOnChange={() => {
										setIntegrationLogsFilters(
											(prevVal) => ({
												...prevVal,
												incomingLog:
													!prevVal.incomingLog,
											})
										);
									}}
								>
									{localString("Incoming")}
								</Checkbox>
							</div>

							<div className="checkbox-div">
								<Checkbox
									value={integrationLogsFilters.outgoingLog}
									name="outgoingLog"
									id="outgoingLog"
									handleOnChange={() => {
										setIntegrationLogsFilters(
											(prevVal) => ({
												...prevVal,
												outgoingLog:
													!prevVal.outgoingLog,
											})
										);
									}}
								>
									{localString("Outgoing")}
								</Checkbox>
							</div>
							<div className="checkbox-div">
								<Checkbox
									value={integrationLogsFilters.needUserTriggered}
									name="needUserTriggered"
									id="needUserTriggered"
									handleOnChange={() => {
										setIntegrationLogsFilters(
											(prevVal) => ({
												...prevVal,
												needUserTriggered:
													!prevVal.needUserTriggered,
											})
										);
									}}
								>
									{localString("User triggered")}
								</Checkbox>
							</div>
						</div>
					</div>

					{filteredIntegrationLogs?.length > 0 ? (
						filteredIntegrationLogs.map((log) => (
							<div className="row padding10 integration-history-border">
								<div className="col-sm-7">
									<p className="primary">
										<span
											className={
												log.IsIncomingCall
													? "glyphicon glyphicon-arrow-left"
													: "glyphicon glyphicon-arrow-right"
											}
										></span>
										{log.ApiUrl} 
										{log.IntegrationSource &&
											`(${log.IntegrationSource})`}
										{log.IsReTriggerable && accessFn('REFRESH_INTEGRATION_LOG') ? <span
											title="Refresh"
											className="glyphicon glyphicon-refresh cursor-pointer padding10"
											onClick={() => {
                                                refreshfailedlog(log);
                                            }}
										></span>:""}
									</p>
									<p className="text-bold">
										{localString(log.ServiceRequestStatus)}
									</p>
									{log.CreatedDate && (
										<p>
											{`${localString(
												"on"
											)} ${returnLocalDateTime(
												log.CreatedDate,
												"DD-MM-YYYY"
											)} ${localString(
												"at"
											)} ${returnLocalDateTime(
												log.CreatedDate,
												"HH:mm"
											)}`}
										</p>
									)}
									{
										log.UserName && (
											<p>
												By:{log.UserName}
											</p>
										)
									}
								</div>
								<div
									className={`col-sm-5 integrationService-status ${getIntStatusColor(
										log.IntegrationHttpStatus
									)}`}
								>
									<p>
										{localString(
											getIntStatusString(
												log.IntegrationHttpStatus
											)
										)}
									</p>
									<p>
										{log.IntegrationHttpStatus != 200 &&
											log.Remarks}
									</p>
								</div>
							</div>
						))
					) : (
						<div className="text-left">
							<p className="no-data text-capitalize remove-margin">
								{localString("No Logs Found")}
							</p>
						</div>
					)}
				</PageContentWrapper>
			</div>
		</div>
	);
};

export default IntegrationLogDetails;
