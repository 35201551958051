import React, { useState } from "react";
import { Modal, Button } from "sfy-react";

import "./DescriptionModal.scss";

const DescriptionModal = (props) => {
	const {
		localString,
		showModal,
		closeModal,
		selectedDocumentGroupObject,
		addConsumerServiceRequestRecords,
		readOnly,
	} = props;

	const [description, setDescription] = useState(
		(selectedDocumentGroupObject &&
			selectedDocumentGroupObject.consumerServiceRequestProof &&
			selectedDocumentGroupObject.consumerServiceRequestProof.length >
				0 &&
			selectedDocumentGroupObject.consumerServiceRequestProof[0]
				.consumerServiceRequestProofRecords &&
			selectedDocumentGroupObject.consumerServiceRequestProof[0]
				.consumerServiceRequestProofRecords.length > 0 &&
			selectedDocumentGroupObject.consumerServiceRequestProof[0]
				.consumerServiceRequestProofRecords[0].text) ||
			""
	);

	const handleSubmitDescription = () => {
		addConsumerServiceRequestRecords(
			selectedDocumentGroupObject.consumerServiceRequestProof[0]
				.consumerServiceRequestProofID,
			[
				{
					text: description,
				},
			],
			closeModal
		);
	};

	return (
		<Modal
			showModal={showModal}
			closeModal={closeModal}
			showCloseBtn={true}
			className="proof-description-modal"
		>
			<Modal.Header>
				<h4 className="description-modal-heading">
					{localString("Additional Device Description")}
				</h4>
			</Modal.Header>
			<Modal.Body>
				<div className="description-modal-wrapper">
					<div className="description-input-container">
						<label
							htmlFor="description"
							className="description-label"
						>
							{readOnly
								? "Device damage description"
								: "Enter device damage description"}
						</label>
						<textarea
							name="description"
							className="input-border-radius"
							style={{ resize: "none" }}
							id="description"
							cols="40"
							rows="6"
							disabled={readOnly ? true : false}
							value={description}
							onChange={(e) => {
								setDescription(e.target.value);
							}}
						></textarea>
					</div>

					{!readOnly && (
						<div>
							<Button
								classes="btn squareBtnGhost padding15"
								isDisabled={readOnly}
								handleOnClick={() =>
									!readOnly && handleSubmitDescription()
								}
							>
								{localString("Submit")}
							</Button>
						</div>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	);
};
export default DescriptionModal;
