import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Loader, Button, Tooltip } from "sfy-react";
import Swal from "sweetalert2";
import { displayError } from "../../../Utility/Utils";

import ShadowWrapper from "../ShadowWrapper";
import CaseCard from "./CaseCard/CaseCard";
import PlanCard from "./PlanCard/PlanCard";
import ClaimCard from "./ClaimCard/ClaimCard";
import ConsumerDetails from "./ConsumerDetails/ConsumerDetails";
import ConsumerHistory from "./ConsumerHistory/ConsumerHistory";
import Partners from "./Partners/Partners";
import CaseScore from "../../../components/CaseScore/CaseScore";
import Rules from "./Rules/Rules";
import Estimate from "./Estimate/Estimate";
import PageContentWrapper from "../../../components/PageContentWrapper/PageContentWrapper";
import InvestigationChecklist from "./InvestigationChecklist/InvestigationChecklist";
import Clusters from "./Clusters/Clusters";
import CaseLogs from "./CaseLogs/CaseLogs";
import DiagnosticImages from "./DiagnosticImages/DiagnosticImages";

import "./ViewCase.scss";

const TabData = [
	{
		id: "consumer-details",
		label: "Consumer Details",
	},
	{
		id: "consumer-history",
		label: "Consumer History",
	},
	{
		id: "partners",
		label: "Partners",
	},
	{
		id: "estimate",
		label: "Estimate",
	},
	{
		id: "rules",
		label: "Rules",
	},
	{
		id: "clusters",
		label: "Clusters",
	},
	{
		id: "score",
		label: "Score",
	},
	{
		id: "diagnostic-images",
		label: "Diagnostic Images",
	},
];

const DiagnosisIcon = (
	<svg width="20" height="20" viewBox="0 0 16 16">
		<path
			d="M12.4556 14.7675H3.54562C3.2888 14.7669 3.04272 14.6644 2.86135 14.4826C2.67998 14.3007 2.57812 14.0544 2.57813 13.7975V2.57004C2.57813 2.31344 2.68006 2.06735 2.8615 1.88591C3.04294 1.70447 3.28903 1.60254 3.54562 1.60254H5.89562C5.96193 1.60254 6.02552 1.62888 6.0724 1.67576C6.11929 1.72265 6.14562 1.78623 6.14562 1.85254V2.82254C6.14562 2.83315 6.14984 2.84332 6.15734 2.85082C6.16484 2.85832 6.17502 2.86254 6.18562 2.86254H9.81562C9.8267 2.86194 9.83717 2.85727 9.84501 2.84942C9.85285 2.84158 9.85752 2.83112 9.85812 2.82004V1.85254C9.85812 1.78623 9.88446 1.72265 9.93135 1.67576C9.97823 1.62888 10.0418 1.60254 10.1081 1.60254H12.4556C12.7122 1.60254 12.9583 1.70447 13.1397 1.88591C13.3212 2.06735 13.4231 2.31344 13.4231 2.57004V13.7975C13.4231 14.0544 13.3213 14.3007 13.1399 14.4826C12.9585 14.6644 12.7125 14.7669 12.4556 14.7675ZM3.54562 2.10254C3.42164 2.10254 3.30273 2.15179 3.21505 2.23947C3.12738 2.32714 3.07812 2.44605 3.07812 2.57004V13.7975C3.07812 13.9218 3.1273 14.0409 3.2149 14.129C3.3025 14.2171 3.42141 14.2669 3.54562 14.2675H12.4556C12.5798 14.2669 12.6987 14.2171 12.7863 14.129C12.874 14.0409 12.9231 13.9218 12.9231 13.7975V2.57004C12.9231 2.44605 12.8739 2.32714 12.7862 2.23947C12.6985 2.15179 12.5796 2.10254 12.4556 2.10254H10.3581V2.82004C10.3581 2.96392 10.301 3.10191 10.1992 3.20364C10.0975 3.30538 9.9595 3.36254 9.81562 3.36254H6.18562C6.04241 3.36254 5.90506 3.30565 5.80379 3.20438C5.70252 3.10311 5.64562 2.96576 5.64562 2.82254V2.10254H3.54562Z"
			fill="#410099"
			className="diagnosis-icon"
		/>
		<path
			d="M11.9609 13.4502H3.96094C3.89463 13.4502 3.83104 13.4239 3.78416 13.377C3.73728 13.3301 3.71094 13.2665 3.71094 13.2002V2.7002C3.71094 2.63389 3.73728 2.5703 3.78416 2.52342C3.83104 2.47653 3.89463 2.4502 3.96094 2.4502H5.85594C5.92224 2.4502 5.98583 2.47653 6.03271 2.52342C6.0796 2.5703 6.10594 2.63389 6.10594 2.7002V2.8377C6.10524 2.84361 6.10573 2.84961 6.10737 2.85533C6.10901 2.86106 6.11177 2.8664 6.11549 2.87105C6.11921 2.8757 6.12382 2.87957 6.12905 2.88243C6.13427 2.88528 6.14001 2.88707 6.14594 2.8877H9.77344C9.78559 2.88643 9.79679 2.88053 9.80471 2.87121C9.81262 2.8619 9.81665 2.8499 9.81594 2.8377V2.7002C9.81594 2.63389 9.84228 2.5703 9.88916 2.52342C9.93605 2.47653 9.99963 2.4502 10.0659 2.4502H11.9609C12.0272 2.4502 12.0908 2.47653 12.1377 2.52342C12.1846 2.5703 12.2109 2.63389 12.2109 2.7002V13.2002C12.2109 13.2665 12.1846 13.3301 12.1377 13.377C12.0908 13.4239 12.0272 13.4502 11.9609 13.4502ZM4.21094 12.9502H11.7109V2.9502H10.3059C10.2812 3.0733 10.2146 3.18408 10.1176 3.2638C10.0206 3.34352 9.89901 3.38728 9.77344 3.3877H6.14344C6.01829 3.38671 5.8973 3.34268 5.80079 3.26301C5.70427 3.18334 5.63812 3.07289 5.61344 2.9502H4.20844L4.21094 12.9502Z"
			fill="#410099"
			className="diagnosis-icon"
		/>
		<path
			d="M9.81703 3.36238H6.18453C6.04131 3.36238 5.90396 3.30549 5.80269 3.20422C5.70142 3.10295 5.64453 2.9656 5.64453 2.82238V1.60988C5.64453 1.46643 5.70134 1.32883 5.80254 1.22716C5.90374 1.12549 6.04108 1.06804 6.18453 1.06738H9.81453C9.95864 1.06804 10.0966 1.12576 10.1983 1.22789C10.3 1.33003 10.357 1.46827 10.357 1.61238V2.82238C10.357 2.9656 10.3001 3.10295 10.1989 3.20422C10.0976 3.30549 9.96025 3.36238 9.81703 3.36238ZM6.18453 1.56738C6.1737 1.56802 6.16352 1.57278 6.15608 1.58068C6.14865 1.58858 6.14451 1.59903 6.14453 1.60988V2.82238C6.14453 2.83299 6.14875 2.84317 6.15625 2.85067C6.16375 2.85817 6.17392 2.86238 6.18453 2.86238H9.81703C9.82764 2.86238 9.83781 2.85817 9.84532 2.85067C9.85282 2.84317 9.85703 2.83299 9.85703 2.82238V1.61238C9.85705 1.60087 9.85265 1.58979 9.84475 1.58142C9.83684 1.57304 9.82603 1.56802 9.81453 1.56738H6.18453Z"
			fill="#410099"
			className="diagnosis-icon"
		/>
		<path
			d="M11.1501 6.54736H4.76758C4.70127 6.54736 4.63769 6.52102 4.5908 6.47414C4.54392 6.42726 4.51758 6.36367 4.51758 6.29736C4.51758 6.23106 4.54392 6.16747 4.5908 6.12059C4.63769 6.0737 4.70127 6.04736 4.76758 6.04736H11.1501C11.2164 6.04736 11.28 6.0737 11.3269 6.12059C11.3737 6.16747 11.4001 6.23106 11.4001 6.29736C11.4001 6.36367 11.3737 6.42726 11.3269 6.47414C11.28 6.52102 11.2164 6.54736 11.1501 6.54736ZM11.1501 8.05236H4.76758C4.70127 8.05236 4.63769 8.02602 4.5908 7.97914C4.54392 7.93226 4.51758 7.86867 4.51758 7.80236C4.51758 7.73606 4.54392 7.67247 4.5908 7.62559C4.63769 7.5787 4.70127 7.55236 4.76758 7.55236H11.1501C11.2164 7.55236 11.28 7.5787 11.3269 7.62559C11.3737 7.67247 11.4001 7.73606 11.4001 7.80236C11.4001 7.86867 11.3737 7.93226 11.3269 7.97914C11.28 8.02602 11.2164 8.05236 11.1501 8.05236ZM11.1501 9.51236H4.76758C4.70127 9.51236 4.63769 9.48602 4.5908 9.43914C4.54392 9.39226 4.51758 9.32867 4.51758 9.26236C4.51758 9.19606 4.54392 9.13247 4.5908 9.08559C4.63769 9.0387 4.70127 9.01236 4.76758 9.01236H11.1501C11.2164 9.01236 11.28 9.0387 11.3269 9.08559C11.3737 9.13247 11.4001 9.19606 11.4001 9.26236C11.4001 9.32867 11.3737 9.39226 11.3269 9.43914C11.28 9.48602 11.2164 9.51236 11.1501 9.51236ZM11.1501 10.9249H4.76758C4.70127 10.9249 4.63769 10.8985 4.5908 10.8516C4.54392 10.8048 4.51758 10.7412 4.51758 10.6749C4.51758 10.6086 4.54392 10.545 4.5908 10.4981C4.63769 10.4512 4.70127 10.4249 4.76758 10.4249H11.1501C11.2164 10.4249 11.28 10.4512 11.3269 10.4981C11.3737 10.545 11.4001 10.6086 11.4001 10.6749C11.4001 10.7412 11.3737 10.8048 11.3269 10.8516C11.28 10.8985 11.2164 10.9249 11.1501 10.9249Z"
			fill="#410099"
			className="diagnosis-icon"
		/>
	</svg>
);

const ViewCase = (props) => {
	const { apiCall, localString, useQuery, baseUrl } = props;
	const query = useQuery();
	const caseID = query.get("caseid");

	const [showLoader, setShowLoader] = useState(false);
	const [activeTab, setActiveTab] = useState("consumer-details");
	const [activeLabel, setActiveLabel] = useState("Consumer Details");
	const [showInvestigationChecklist, setShowInvestigationChecklist] =
		useState(false);
	const [showCaseLogs, setShowCaseLogs] = useState(false);
	const [investigationChecklist, setInvestigationChecklist] = useState([]);
	const [caseDetails, setCaseDetails] = useState({});
	const [scoreDetails, setScoreDetails] = useState({});
	const [consumerDetails, setConsumerDetails] = useState({});
	const [rulesDetails, setRulesDetails] = useState({});
	const [estimateDetails, setEstimateDetails] = useState([]);
	const [csrDocuments, setCsrDocuments] = useState([]);
	const [consumerHistory, setConsumerHistory] = useState([]);
	const [clustersDetails, setClustersDetails] = useState({
		legendTitle: "",
		clusterData: [],
	});
	const [caseLogs, setCaseLogs] = useState([]);
	const [diagnosticData, setDiagnosticData] = useState({});
	const [rightButtonFlag, setRightButtonFlag] = useState(false);

	const getCaseDetails = () => {
		let data = {
			caseID: caseID,
		};

		setShowLoader(true);
		apiCall({
			url: "getCaseDetails",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					setCaseDetails(result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getConsumerByCaseID = () => {
		let data = {
			caseID: caseID,
		};

		setShowLoader(true);
		apiCall({
			url: "getConsumerByCaseID",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					setConsumerDetails(result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getChargesByCaseID = () => {
		let data = {
			ConsumerServiceRequestID:
				caseDetails?.consumerServiceRequest?.ConsumerServiceRequestID ||
				"",
		};

		setShowLoader(true);
		apiCall({
			url: "getEstimationTabDetails",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					setEstimateDetails(result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getCaseScores = () => {
		let data = {
			caseID: caseID,
		};

		setShowLoader(true);
		apiCall({
			url: "getCaseScores",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					setScoreDetails(result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getCaseChecklist = () => {
		let data = {
			caseID: caseID,
		};

		setShowLoader(true);
		apiCall({
			url: "getCaseChecklist",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					let data = result.data.map((d) => ({
						...d,
						isChecked: d.status,
					}));
					setInvestigationChecklist(data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getCSRDocuments = () => {
		let data = {
			ConsumerServiceRequestID:
				caseDetails?.consumerServiceRequest?.ConsumerServiceRequestID ||
				"",
		};

		setShowLoader(true);
		apiCall({
			url: "getCSRDocuments",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					setCsrDocuments(result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getConsumerClaimHistory = () => {
		let data = {
			consumerID: caseDetails?.soldPlan?.consumerID || "",
		};

		setShowLoader(true);
		apiCall({
			url: "getConsumerClaimHistory",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					setConsumerHistory(result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getClusterDetails = () => {
		let data = {
			referenceID: caseDetails?.consumerServiceRequest?.referenceID,
		};

		setShowLoader(true);
		apiCall({
			url: "getClusterData",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					if (
						result.data &&
						result.data.clusterData &&
						result.data.clusterData.length > 0
					)
						setClustersDetails(result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getCaseLogs = () => {
		let data = {
			caseID: caseID,
		};

		setShowLoader(true);
		apiCall({
			url: "getCaseLogs",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					setCaseLogs(result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getDiagnosticsData = () => {
		let data = {
			consumerID: caseDetails?.soldPlan?.consumerID,
			transactionSubCategoryID: caseDetails?.transactionSubCategoryID,
		};

		setShowLoader(true);
		apiCall({
			url: "getDiagnosticsData",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					setDiagnosticData(result.data.diagnosticsImages);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	useEffect(() => {
		getCaseDetails();
	}, []);

	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}

			<ShadowWrapper classname="rounded-bottom">
				<div className="view-case-main-header">
					<div className="main-heading">
						<span className="glyphicon glyphicon-chevron-left" />
						{localString("Case Manager")}/
						<span className="heading-bold">
							{localString("View CSR")}
						</span>
					</div>

					<div>
						<Button
							classes="button-ghost button-small header-btn-radius"
							handleOnClick={() => setShowCaseLogs(true)}
						>
							{localString("Case Logs")}
						</Button>

						<Button
							id="case-diagnosis-btn"
							classes="button-ghost button-small header-btn-radius"
							handleOnClick={() =>
								setShowInvestigationChecklist(true)
							}
						>
							{DiagnosisIcon}
							{localString("Investigation Checklist")}
						</Button>
					</div>
				</div>
			</ShadowWrapper>

			<CaseCard
				{...props}
				caseDetails={caseDetails}
				getCaseDetails={getCaseDetails}
				setShowLoader={setShowLoader}
			/>
			<PlanCard {...props} caseDetails={caseDetails} />
			<ClaimCard {...props} caseDetails={caseDetails} />

			<div className="case-tabs-section">
				<ShadowWrapper classname="rounded-top">
					<div className="case-navbar-wrapper border-bottom">
						{TabData.map((tab) =>
							[
								"consumer-details",
								"consumer-history",
								"estimate",
								"rules",
								"score",
								"clusters",
								"diagnostic-images",
							].includes(tab.id) ? (
								<div
									key={tab.id}
									onClick={() => {
										setActiveTab(tab.id);
										setActiveLabel(tab.label);
									}}
									className={`case-navbar ${
										activeTab === tab.id
											? "case-navbar-selected"
											: ""
									}`}
								>
									{localString(tab.label)}
								</div>
							) : (
								<Tooltip
									position="bottom"
									content="Coming Soon!"
								>
									<div
										key={tab.id}
										//onClick={() => {
										//	setActiveTab(tab.id);
										//}}
										className={`case-navbar${
											activeTab === tab.id
												? " case-navbar-selected"
												: ""
										}`}
									>
										{localString(tab.label)}
									</div>
								</Tooltip>
							)
						)}
					</div>
				</ShadowWrapper>

				<div className="content">
					<PageContentWrapper
						title={localString(activeLabel)}
						showDownloadButtonIcon={
							activeLabel === "Consumer Details"
						}
						showRightButton={activeTab === "estimate"}
						rightButtonCB={() => {
							setRightButtonFlag(!rightButtonFlag);
						}}
						rightButtonText={
							rightButtonFlag ? "Show Less" : "Show More"
						}
						customSymbol={
							activeLabel === "Consumer Details"
								? "glyphicon glyphicon-share"
								: ""
						}
						editDeviceClick={() => {
							let url = `${baseUrl}/consumers/view?cid=${consumerDetails?.consumer?.consumerID}`;
							const newWindow = window.open(
								url,
								"_blank",
								"noopener,noreferrer"
							);
							if (newWindow) newWindow.opener = null;
						}}
					>
						<>
							{activeTab === "consumer-details" && (
								<ConsumerDetails
									{...props}
									caseID={caseID}
									showLoader={showLoader}
									setShowLoader={setShowLoader}
									getConsumerByCaseID={getConsumerByCaseID}
									consumerDetails={consumerDetails}
									setConsumerDetails={setConsumerDetails}
									getCSRDocuments={getCSRDocuments}
									csrDocuments={csrDocuments}
									caseDetails={caseDetails}
								/>
							)}

							{activeTab === "consumer-history" && (
								<ConsumerHistory
									{...props}
									showLoader={showLoader}
									setShowLoader={setShowLoader}
									getConsumerClaimHistory={
										getConsumerClaimHistory
									}
									consumerHistory={consumerHistory}
									setConsumerHistory={setConsumerHistory}
									caseDetails={caseDetails}
								/>
							)}

							{activeTab === "estimate" && (
								<Estimate
									{...props}
									caseID={caseID}
									showLoader={showLoader}
									setShowLoader={setShowLoader}
									getChargesByCaseID={getChargesByCaseID}
									caseDetails={caseDetails}
									rightButtonFlag={rightButtonFlag}
									getCaseDetails={getCaseDetails}
									estimateDetails={estimateDetails}
									setEstimateDetails={setEstimateDetails}
								/>
							)}

							{activeTab === "rules" && (
								<Rules
									{...props}
									caseID={caseID}
									showLoader={showLoader}
									setShowLoader={setShowLoader}
									getConsumerByCaseID={getConsumerByCaseID}
									rulesDetails={rulesDetails}
									setRulesDetails={setRulesDetails}
									ConsumerServiceRequestID={
										caseDetails?.consumerServiceRequest
											?.ConsumerServiceRequestID || ""
									}
									SoldPlanID={caseDetails?.soldPlanID || ""}
								/>
							)}

							{activeTab === "score" && (
								<CaseScore
									{...props}
									caseID={caseID}
									showLoader={showLoader}
									setShowLoader={setShowLoader}
									getCaseScores={getCaseScores}
									scoreDetails={scoreDetails}
									setScoreDetails={setScoreDetails}
								/>
							)}

							{activeTab === "clusters" && (
								<Clusters
									{...props}
									caseID={caseID}
									showLoader={showLoader}
									setShowLoader={setShowLoader}
									clustersDetails={clustersDetails}
									setClustersDetails={setClustersDetails}
									getClusterDetails={getClusterDetails}
									caseDetails={caseDetails}
								/>
							)}

							{activeTab === "diagnostic-images" && (
								<DiagnosticImages
									{...props}
									caseID={caseID}
									showLoader={showLoader}
									setShowLoader={setShowLoader}
									caseDetails={caseDetails}
									diagnosticData={diagnosticData}
									getDiagnosticsData={getDiagnosticsData}
								/>
							)}
						</>
					</PageContentWrapper>
				</div>
			</div>
			{showInvestigationChecklist && (
				<InvestigationChecklist
					{...props}
					setShowLoader={setShowLoader}
					getCaseChecklist={getCaseChecklist}
					investigationChecklist={investigationChecklist}
					setInvestigationChecklist={setInvestigationChecklist}
					showInvestigationChecklist={showInvestigationChecklist}
					setShowInvestigationChecklist={
						setShowInvestigationChecklist
					}
				/>
			)}

			{showCaseLogs && (
				<CaseLogs
					{...props}
					setShowLoader={setShowLoader}
					showCaseLogs={showCaseLogs}
					setShowCaseLogs={setShowCaseLogs}
					getCaseLogs={getCaseLogs}
					caseLogs={caseLogs}
				/>
			)}
		</>
	);
};

export default ViewCase;
