import React from "react";
import { dateFormatter } from "sfy-react";
import ShadowWrapper from "../../ShadowWrapper";
import "../ViewCase.scss";

const PlanCard = (props) => {
	const { baseUrl, caseDetails, localString } = props;
	return (
		<div className="card-outer-wrapper">
			<ShadowWrapper classname="rounded">
				{caseDetails.soldPlan && (
					<div className="card-wrapper">
						<div className="border-bottom card-header-container">
							<div className="card-heading-wrapper">
								<div className="main-heading">
									{caseDetails.soldPlan.planName || "-"}
								</div>
								<div
									className="sub-heading csr-link"
									onClick={(e) => {
										e.stopPropagation();
										let url = `${baseUrl}/plans/consumer/view?spid=${caseDetails.soldPlanID}`;
										const newWindow = window.open(
											url,
											"_blank",
											"noopener,noreferrer"
										);
										if (newWindow) newWindow.opener = null;
									}}
								>
									{caseDetails.soldPlan.soldPlanCode || "-"}
								</div>
								<div
									className={`score-tablet ${
										caseDetails.soldPlan.score &&
										parseInt(
											caseDetails.soldPlan.score,
											10
										) > 0
											? "green-tablet"
											: "red-tablet"
									}`}
								>
									{caseDetails.soldPlan.score || "-"}
								</div>
							</div>
						</div>

						<div className="card-body">
							<div className="plan-card-row-7 body-heading">
								<div className="consumer-history-heading-text">
									{localString("Plan Status")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Plan Code")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Purchase Date")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Activation Date")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Product")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Consumer")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Retailer/Sales Channel")}
								</div>
							</div>

							<div className="plan-card-row-7 second-row-pad">
								<div>
									{caseDetails.soldPlan.soldPlanStatus || "-"}
								</div>

								<div>
									{caseDetails.soldPlan.planCode || "-"}
								</div>

								<div>
									{caseDetails.soldPlan.planPurchaseDate
										? dateFormatter(
												new Date(
													caseDetails.soldPlan.planPurchaseDate
												),
												"dd/mm/yyyy"
										  )
										: "-"}
								</div>

								<div>
									{caseDetails.soldPlan.planActivationDate
										? dateFormatter(
												new Date(
													caseDetails.soldPlan.planActivationDate
												),
												"dd/mm/yyyy"
										  )
										: "-"}
								</div>

								<div>
									{caseDetails.soldPlan.productName || "-"}
								</div>

								<div>
									{caseDetails.soldPlan.customerName || "-"}
								</div>

								<div>
									{caseDetails.soldPlan.serviceLocationName ||
										caseDetails.soldPlan.source ||
										"-"}
								</div>
							</div>
						</div>
					</div>
				)}
			</ShadowWrapper>
		</div>
	);
};

export default PlanCard;
