import React, { useState } from 'react'
import { Button, Modal } from "sfy-react";
import Swal from 'sweetalert2';
import { displayError } from '../../Utility/Utils';

export default function UserListModal(props) {
	const {
		isEmail,
		apiCall,
		userList,
		setLoader,
		authToken,
		setAuthToken,
		displayUserListModal,
		closeUserListModal,
	} = props;
	const [user, setUser] = useState({});

	const submit = () => {
		setLoader(true);
		let reqObj = {
			userID: user?.userID,
			OTPMode: isEmail ? 'Email' : 'Mobile'
		};
		apiCall({
			url: 'generateNewUserAccessToken',
			headers: authToken ? { authorization: authToken } : {},
			data: reqObj,
			callBack: (result) => {
				if (result.success) {
					window.location.href = '/';
				} else {
					setLoader(false);
					setAuthToken('');
					Swal.fire({
						icon: 'error',
						text: displayError(result),
					}).then(swalResult => {
						window.location.reload(true);
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	return (
		<Modal
			showModal={displayUserListModal}
			closeModal={closeUserListModal}
			showCloseBtn={true}
			className='Filter-Modal loanerPaymentModal'
		>
			<Modal.Header>
				{/* <h2> {localString("Select User")} </h2> */}
				<h2 className='bold'>Select User</h2>
			</Modal.Header>
			<Modal.Body>
				<div className='userListCard'>
					{
						userList?.length && userList.map((userObj) => (
							<div
								className={`userListCardItem ${(userObj.userName === user.userName) ? 'active' : ''}`}
								onClick={(() => setUser(userObj))}
							>
								{userObj.userName}
							</div>)
						)
					}
				</div>
				<div className='text-center'>
					<Button
						handleOnClick={submit}
						isDisabled={!user?.userName}
						className="btn squareBtnGhost"
						text="Continue"
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
			</Modal.Footer>
		</Modal>
	)
}