import React, { useState } from "react";
import Swal from "sweetalert2";

import QRCodeModal from "../QRCodeModal/QRCodeModal";
import DescriptionModal from "../DescriptionModal/DescriptionModal";
import GroupActionButtons from "../ActionButtons/GroupActionButtons";
import DisplayProofImages from "../DisplayProofImages/DisplayProofImages";
import UploadActionButton from "../ActionButtons/UploadActionButton";

import { displayError } from "../../../Utility/Utils";

import "../RequestProof.scss";

const RequestProofWrapper = (props) => {
	const {
		title,
		pendingProofsList,
		localString,
		consumerServiceRequest,
		apiCall,
		setShowLoader,
		getProofsByGroup,
		getRequestById,
	} = props;

	const [showQRCodeModal, setShowQRCodeModal] = useState(false);
	const [showDescriptionModal, setShowDescriptionModal] = useState(false);

	const [showDisplayProofImages, setShowDisplayProofImages] = useState(false);
	const [selectedDocumentGroupObject, setSelectedDocumentGroupObject] =
		useState({});
	const [isDescriptionReadOnly, setIsDescriptionReadOnly] = useState(false);

	const closeQRModal = () => {
		setShowQRCodeModal(false);
		setSelectedDocumentGroupObject({});
	};

	const closeDescriptionModal = () => {
		setSelectedDocumentGroupObject({});
		setIsDescriptionReadOnly(false);
		setShowDescriptionModal(false);
	};

	const closeDisplayProofImages = () => {
		setShowDisplayProofImages(false);
		setSelectedDocumentGroupObject({});
	};

	const handlePreviewClick = (docObject) => {
		setSelectedDocumentGroupObject(docObject);

		if (docObject.proofType === "text") {
			setIsDescriptionReadOnly(true);
			setShowDescriptionModal(true);
		} else {
			setShowDisplayProofImages(true);
		}
	};

	const handleActionButtonClick = (docObject) => {
		setSelectedDocumentGroupObject(docObject);

		if (docObject.proofType === "livecapture") {
			setShowQRCodeModal(true);
		} else if (docObject.proofType === "text") {
			setIsDescriptionReadOnly(false);
			setShowDescriptionModal(true);
		}
	};

	const addConsumerServiceRequestRecords = (
		consumerServiceRequestProofID,
		consumerServiceRequestProofRecords,
		onSuccess
	) => {
		const data = {
			consumerServiceRequestID:
				consumerServiceRequest.ConsumerServiceRequestID,
			consumerServiceRequestProofs: [
				{
					consumerServiceRequestProofID,
					consumerServiceRequestProofRecords,
				},
			],
		};

		setShowLoader(true);
		apiCall({
			url: "addConsumerServiceRequestRecords",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					onSuccess && onSuccess();
					getRequestById();
					getProofsByGroup();
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const handleGroupAdjudicationAction = (proofGroupCode, proofStatus) => {
		Swal.fire({
			title: "Are you sure?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
		}).then((result) => {
			if (result.isConfirmed) {
				let data = {
					ReferenceID: consumerServiceRequest.ReferenceID,
					ProofGroupCode: [proofGroupCode],
					ProofStatus: proofStatus,
				};

				setShowLoader(true);
				apiCall({
					url: "proofsGroupAdjudication",
					data: data,
					callBack: (result) => {
						setShowLoader(false);
						if (result.success) {
							getRequestById();
							getProofsByGroup();
						} else {
							Swal.fire({
								icon: "error",
								text: localString(displayError(result)),
							});
						}
					},
				});
			}
		});
	};

	const handleOpenPreviewClickAfterRefresh = (doc) => {
		getProofsByGroup((pendingProofsListResponse) => {
			const docObject = pendingProofsListResponse.find(
				(pl) => pl.ProofGroupID === doc.ProofGroupID
			);

			if (!docObject) {
				Swal.fire({
					icon: "error",
					title: localString("No proof uploaded yet!"),
				});

				return;
			}

			const isSomeProofUploaded =
				docObject.consumerServiceRequestProof &&
				docObject.consumerServiceRequestProof.length > 0 &&
				docObject.consumerServiceRequestProof.some(
					(csrProof) =>
						csrProof.consumerServiceRequestProofRecords.length > 0
				);

			if (isSomeProofUploaded) {
				handlePreviewClick(docObject);
			} else {
				Swal.fire({
					icon: "error",
					title: localString("No proof uploaded yet!"),
				});
			}
		});
	};

	return (
		<>
			{pendingProofsList && pendingProofsList.map((doc, i) => (
				<>
					<GroupActionButtons
						key={i}
						index={i}
						{...props}
						title={doc.groupName}
						doc={doc}
						setShowQRCodeModal={setShowQRCodeModal}
						setShowDescriptionModal={setShowDescriptionModal}
						handlePreviewClick={handlePreviewClick}
						handleActionButtonClick={handleActionButtonClick}
						handleGroupAdjudicationAction={
							handleGroupAdjudicationAction
						}
						addConsumerServiceRequestRecords={
							addConsumerServiceRequestRecords
						}
					/>

					{consumerServiceRequest.Status ===
						"Service center document pending" &&
						doc.status === "pending" &&
						doc.proofType === "document" &&
						doc.consumerServiceRequestProof &&
						doc.consumerServiceRequestProof.length > 0 &&
						doc.consumerServiceRequestProof.map(
							(consumerServiceRequestProofObject, index) => (
								<UploadActionButton
									key={i}
									{...props}
									title={
										consumerServiceRequestProofObject
											.document.documentType
									}
									consumerServiceRequestProofObject={
										consumerServiceRequestProofObject
									}
									index={index}
									doc={doc}
									addConsumerServiceRequestRecords={
										addConsumerServiceRequestRecords
									}
									setSelectedDocumentGroupObject={
										setSelectedDocumentGroupObject
									}
								/>
							)
						)}
				</>
			))}

			{showQRCodeModal && (
				<QRCodeModal
					{...props}
					showModal={showQRCodeModal}
					closeModal={closeQRModal}
					selectedDocumentGroupObject={selectedDocumentGroupObject}
					handleOpenPreviewClickAfterRefresh={
						handleOpenPreviewClickAfterRefresh
					}
				/>
			)}

			{showDescriptionModal && (
				<DescriptionModal
					{...props}
					readOnly={isDescriptionReadOnly}
					showModal={showDescriptionModal}
					closeModal={closeDescriptionModal}
					selectedDocumentGroupObject={selectedDocumentGroupObject}
					addConsumerServiceRequestRecords={
						addConsumerServiceRequestRecords
					}
				/>
			)}

			{showDisplayProofImages && (
				<DisplayProofImages
					{...props}
					showModal={showDisplayProofImages}
					closeModal={closeDisplayProofImages}
					selectedDocumentGroupObject={selectedDocumentGroupObject}
				/>
			)}
		</>
	);
};

export default RequestProofWrapper;
