import React from 'react';
import {Button} from 'sfy-react';


const UnRegisteredProductDetails = (props) => {
	const {unRegisteredProductArray,baseUrl} = props; 
	
	return(
		<>
			{
				unRegisteredProductArray && unRegisteredProductArray.length > 0 &&  unRegisteredProductArray.map(unReg=>{
					return(
					<>
						{
							!unReg.hide && <div className="form-group clearfix col-sm-6 padding0">
								<div className="row detail col-sm-12">
									{unReg.label && <div class="col-sm-6 padding0">
											<label  className="text-capitalize consumer-label">{unReg.label}:</label>
										</div>
									}
									<div className={unReg.label?"col-sm-6 padding0":"col-sm-12 padding0"}>
										{
											!unReg.showLinks && <p>{unReg.value}</p>
										}
										{
											unReg.showLinks && unReg.linkArray && unReg.linkArray.length > 0 && unReg.linkArray.map((link, index) => {
												return (
													<div>
														<a  href={link} target="_blank">
															<Button  type='button' text={"Doc"+(index+1)} classes='button-ghost'></Button>
														</a>
													</div>
												)
											})
										}
									</div>
								</div>
							</div>
						}
					</>
				)
				})
			}
		</>
	)
	
}

export default UnRegisteredProductDetails
