import React, { useState } from 'react';
import {Modal,Button,Input,Dropdown} from 'sfy-react';
import Swal from "sweetalert2";
import { returnTimezone,displayError } from '../../../../../Utility/Utils';

const CreateTaskInput=(props)=>{
    const {localString,apiCall,showModal,setLoader,setShowModal, createTaskInputs,consumerServiceRequest,setCreateTaskInputs, selectPriorityType,closeModal}=props;
    const createTaskClick=(e)=>{
        e.preventDefault();
        setLoader(true);
        let data = {
        "title" : createTaskInputs.createTaskTitle,
        "priority": createTaskInputs.priorityID,
        "remark" : createTaskInputs.createTaskRemark,
        "ConsumerServiceRequestID" :consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID
        };
        apiCall({
			url: "CreateTask",
			data: data,
			callBack: (result) => {
				setLoader(false);
                setShowModal({...showModal,showCreateTaskModal:false});
				if (result.success) {
                    // getRequestById();
                    window.location.reload();
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    return(
        <>
            <form onSubmit={createTaskClick}>
                <div className='modal-body'>
                    <div className=''>
                        <Input 
                            value={createTaskInputs["createTaskTitle"]}
                            label={localString("Title")}
                            handleOnChange={(e) => {
                                setCreateTaskInputs({...createTaskInputs,["createTaskTitle"]:e.target.value})
                            }}
                        />
                    </div>
                    <div className='input-marginT'>
                        <label className="text-capitalize">{localString("Priority")}</label>
                        <div className=''>
                            <Dropdown
                                id='createTaskDropdown'
                                value={createTaskInputs["creatTaskPriority"]}
                                options={selectPriorityType}
                                visitBy='value'
                                showDownArrow={false}
                                handleOnChange={(data) => {
                                    setCreateTaskInputs({...createTaskInputs,["creatTaskPriority"]:data.value,["priorityID"]:data.id})
                                }}
                            />
                        </div>
                    </div>
                    <div className='input-marginT'>
                        <Input 
                            className=""
                            label={localString("Remark")} 
                            inputComponent='textarea' 
                            value={createTaskInputs["createTaskRemark"]}
                            handleOnChange={(e) => {
                                setCreateTaskInputs({...createTaskInputs,["createTaskRemark"]: e.target.value})
                            }}
                        />
                    </div>
                </div>
                <div class="modal-footer">
                    <Button className="btn button-servify button-stack">{localString("Submit")}</Button>
                    <Button  className="btn button-servify"  handleOnClick={()=>closeModal()}>{localString("Close")}</Button>
                </div>
            </form>
        </>
    )
}
export default CreateTaskInput;

