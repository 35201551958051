import React, { useState, useEffect } from "react";
import { Dropdown } from "sfy-react";
import Snackbar from "./Snackbar";
import moment from "moment";
import "./DateRangePickerPlanSales.scss";
import {
	setDateRange,
	setDropdownObj,
	setDropDownValue,
} from "../../../reduxToolkit/features/planSales/planSalesDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite-no-reset.min.css";

const DateRangePickerPlanSales = (props) => {
	const {
		handleDateRangeApply,
		publicUrl,
		dateClass,
		localString,
		setShowPSLModal,
	} = props;
	const dispatch = useDispatch();
	const { dropdownValue, dropdownObj, dateRange, selectedPSL } = useSelector(
		(state) => state.PlanSalesDashboard
	);
	const [showSnackbar, setShowSnackBar] = useState(false);
	const [openCalendar, setOpenCalendar] = useState(false);
	const [disableApplyButton, setDisableApplyButton] = useState(true);

	const dropDownArray = [
		{
			value: localString("Today"),
			id: 1,
			startDate: moment().toDate(),
			endDate: moment().toDate(),
		},
		{
			value: localString("Last 7 Days"),
			id: 2,
			startDate: moment().subtract(6, "days").toDate(),
			endDate: moment().toDate(),
		},
		{
			value: localString("Last 15 Days"),
			id: 3,
			startDate: moment().subtract(14, "days").toDate(),
			endDate: moment().toDate(),
		},

		{
			value: localString("Last 30 Days"),
			id: 4,
			startDate: moment().subtract(29, "days").toDate(),
			endDate: moment().toDate(),
		},

		{
			value: localString("Custom Date"),
			id: 5,
			startDate: dateRange?.startDate ?? "",
			endDate: dateRange?.endDate ?? "",
		},
	];

	useEffect(() => {
		if (dropdownValue && dropdownObj) {
			dispatch(
				setDateRange({
					startDate: new Date(dropdownObj.startDate),
					endDate: new Date(dropdownObj.endDate),
				})
			);
		} else {
			dispatch(setDropDownValue(dropDownArray[0].value));
			dispatch(setDropdownObj(dropDownArray[0]));
			dispatch(
				setDateRange({
					startDate: new Date(dropDownArray[0].startDate),
					endDate: new Date(dropDownArray[0].endDate),
				})
			);
		}
	}, []);

	const applyBtnCheck = () => {
		let disable = false;
		setDisableApplyButton(disable);
	};

	useEffect(() => {
		applyBtnCheck();
	}, []);

	const valueType = [
		dateRange?.startDate && dateRange?.startDate != ""
			? new Date(dateRange?.startDate)
			: undefined,
		dateRange?.endDate && dateRange?.endDate != ""
			? new Date(dateRange?.endDate)
			: undefined,
	];

	const { afterToday } = DateRangePicker;
	return (
		<div className={dateClass ? dateClass : "dashboard-filter"}>
			<div
				className=""
				style={{ display: "flex", width: "max-content" }}
			>
				<Dropdown
					id="dropdown"
					value={dropdownValue}
					options={dropDownArray}
					placeholder="Select"
					handleOnChange={(item) => {
						dispatch(setDropDownValue(item.value));
						dispatch(setDropdownObj(item));
						let obj = {
							startDate: item.startDate,
							endDate: item.endDate,
						};
						dispatch(setDateRange(obj));
						if (selectedPSL?.PartnerServiceLocationID?.length) {
							handleDateRangeApply(obj);
						} else {
							setShowPSLModal(true);
						}
						if (item.value == localString("Custom Date") && selectedPSL?.PartnerServiceLocationID?.length)
							setOpenCalendar(true);
					}}
					classObject={{
						container: "dropdown-container-home",
						placeholder: "placeholder text-dropdown",
					}}
					filterBy="value"
				/>

				<DateRangePicker
					showOneCalendar
					format="dd/MM/yyyy"
					character=" - "
					id="date"
					ranges={[]}
					value={valueType}
					onChange={(val) => {
						dispatch(
							setDateRange({
								startDate: val[0],
								endDate: val[1],
							})
						);

						var date1 = moment(val[0].toISOString());
						var date2 = moment(val[1].toISOString());
						var days = date2.diff(date1, "days");
						if (days > 29) {
							setShowSnackBar(true);
						} else if (days <= 29) {
							handleDateRangeApply({
								startDate: val[0],
								endDate: val[1],
							});
							setOpenCalendar(false);
						}
						dispatch(
							setDropdownObj({
								startDate: val[0],
								endDate: val[1],
							})
						);
					}}
					disabled={
						selectedPSL?.PartnerServiceLocationID?.length && 
						dropdownValue == localString("Custom Date")
							? false
							: true
					}
					open={
						dropdownValue == localString("Custom Date") &&
						openCalendar
							? true
							: false
					}
					onClick={() => selectedPSL?.PartnerServiceLocationID?.length && setOpenCalendar(!openCalendar)}
					shouldDisableDate={afterToday()}
					style={{ width: 220 }}
				/>
			</div>
			{showSnackbar && (
				<Snackbar
					type="error"
					publicUrl={publicUrl}
					message={localString(
						"You can only select a range within 30 days"
					)}
					callback={() => {
						setShowSnackBar(false);
					}}
				/>
			)}
		</div>
	);
};

export default DateRangePickerPlanSales;
