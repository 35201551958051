import React from 'react';
import {Table} from 'sfy-react';
import './TradeIn.scss';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';


const TradeInDepreciation = (props) => {
	const {tradeInDetails,localString} = props;

	const depreciations = tradeInDetails && tradeInDetails.calculation ? tradeInDetails.calculation.depreciations : undefined;

	const classObject = {
		table:"table table-striped trade-in-details-table table-responsive table-bordered table-condensed trade-in-depreciation"
	}
	return (
		<div className="inner-padding">
			<>
				{
					<div className="row">
						<PageContentWrapper title={localString("Depreciation Details")}>
						<div className="request-panel-details-tag request-issue-validation-div tradein-depreciation">
							{
								depreciations && depreciations.length > 0 && 
								<Table className={classObject}>
									<Table.Header>
										<Table.Heading>{localString("Q Code")}</Table.Heading>
										<Table.Heading>{localString("Question")}</Table.Heading>
										<Table.Heading>{localString("Answer Code")}</Table.Heading>
										<Table.Heading>{localString("Answer")}</Table.Heading>
										<Table.Heading>{localString("Depreciation")}</Table.Heading>
									</Table.Header>
									<Table.Body>
										{
											depreciations && depreciations.length >0 &&  depreciations.map(item=>{
												return(
													<Table.Row>
														<Table.Data>{item.QuestionCode}</Table.Data>
														<Table.Data className="break-word">{item.Question}</Table.Data>
														{
															item.inspectionAnswers && item.inspectionAnswers.length > 0 && 
															<Table.Data>{item.inspectionAnswers[0].AnswerCode}</Table.Data>
														}
														{
															item.inspectionAnswers && item.inspectionAnswers.length > 0 && 
															<Table.Data>{item.inspectionAnswers[0].Answer}</Table.Data>
														}
														{
															item.inspectionAnswers && item.inspectionAnswers.length > 0 && 
															<Table.Data>{item.inspectionAnswers[0].DepreciationValue}</Table.Data>
														}
													</Table.Row>
												)
											})
										}
									</Table.Body>
								</Table>
							}
							{
								!depreciations && 
								<div className="row">
										<p className="charges-panel-subheader text-capitalize remove-margin">{localString("No Depreciations")}</p>
								</div>
							}
						</div>
						</PageContentWrapper>
					</div>
				}
			</>
		</div>
	)
}

export default TradeInDepreciation
