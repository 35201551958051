
import React, { useState } from 'react';
import {Input, Button, Checkbox, Dropdown} from 'sfy-react';
import ApiSearchDropdown from "../../../../../components/SearchInputWithDropdown/ApiSearchDropDown";
import Swal from 'sweetalert2';
import { Loader } from 'sfy-react';
import ReactDOM from 'react-dom';



const AddChargesInputArrays = (props) => {

	const {
        accessFn, saveFunc, selectedChargeTypeArray, localString, chargeArrayValue, removeCharge, isWorkshopFlow,
        taxSlabRequired, submit, isBoseFlow, consumerServiceRequest, showEstimationButtons , partConfig,partCategoryDropdown,displayError,apiCall
    } = props;
    const [loader, setLoader] = useState(false);
    const [isPartItemByIDApiFailed, setIsPartItemByIDApiFailed] = useState(false)
    const taxablePercentage = [{value:"5%"},{value:"12%"},{value:"15%"},{value:"18%"},{value:"28%"}];

    const getPartItemByID = (data,callBack) => {
		let reqParam = {
            "partItemID":data.partItemID,
            "partItemCode":data.partItemCode,
            "partItemDescription":data.partItemDescription,
			"countryID": consumerServiceRequest.CountryID ? consumerServiceRequest.CountryID : undefined,
			"brandID": consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.BrandID : null,
            "productID":consumerServiceRequest.consumerProduct ?consumerServiceRequest.consumerProduct.ProductID:null,
            "productSubCategoryID":consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.ProductSubCategoryID:null,
			"partnerServiceLocationID": consumerServiceRequest.PartnerServiceLocationID,
			"partnerID": consumerServiceRequest && consumerServiceRequest.PartnerID,
            "referenceID": consumerServiceRequest && consumerServiceRequest.ReferenceID
		}
		setLoader(true);
		apiCall({
			url: 'getPartItemByID',
			data: reqParam,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    if(result.statusCode === "SFY.SR.2001"){
                        setIsPartItemByIDApiFailed(true)
                    }else{
                        setIsPartItemByIDApiFailed(false)
                    }
                    
                    if(result.data){
                        callBack(result.data)
                    }
				}else{
                    setIsPartItemByIDApiFailed(true)
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	return ( <>
        {
            (loader) && ReactDOM.createPortal(
                <Loader />,
                document.getElementById("loader-root")
            )
        }
		{ selectedChargeTypeArray && selectedChargeTypeArray.length > 0 &&
            <div className='' style={{ padding: '20px 0px' }}>
                { selectedChargeTypeArray.map((ChargeType, index) => {
                    return(
                        <div className='row' style={{ padding: '10px 0px' }}>
                            {/* {['Part', 'Other','Advance payment','Discount'].indexOf(ChargeType.Type) > -1 && <div className={(ChargeType.Type == 'Other') ? 'col-xs-4' : 'col-xs-2'}> */}
                            {
                              !isWorkshopFlow && ['Part'].indexOf(ChargeType.Type) > -1 && partConfig && partConfig.partConfigEnable &&  <div className='col-xs-2'>
                                    <ApiSearchDropdown
                                        {...props}
                                        index={index}
                                        key={index}
                                        value={ChargeType.partItemCode || ''}
                                        options={[]}
                                        placeholder={localString("Part Code")}
                                        API={"getPartItemMapping"}
                                        APIData ={{
                                            productSubCategoryID:consumerServiceRequest.consumerProduct.ProductSubCategoryID,
                                            brandID:consumerServiceRequest.consumerProduct.BrandID,
                                            productID:consumerServiceRequest.consumerProduct.ProductID,
                                        }}
                                        debounceTime={400}
                                        minSearchLength={2}
                                        searchRequestParam={"partItemCode"}
                                        callAPIForData={true}
                                        addSearchData={(data)=>{
                                            chargeArrayValue(index,"partItemCode",data);
                                            chargeArrayValue(index,"Name",data);
                                        }}
                                        handleOnChange={(data) => {
                                            chargeArrayValue(index,"partItemCode",data.partItemCode);
                                            chargeArrayValue(index,"Name",data.partItemCode);
                                            chargeArrayValue(index,"partItemID",data.partItemID);
                                            getPartItemByID(data,(partData)=>{
                                                chargeArrayValue(index,"partCategory",partData.partCategoryName);
                                                chargeArrayValue(index,"partCategoryID",partData.partCategoryID);
                                                chargeArrayValue(index,"partItemDescription",partData.partItemDescription);
                                                chargeArrayValue(index,"Charge",partData.charge);
                                                chargeArrayValue(index,"externalPartItemReferenceID",partData.externalPartItemReferenceID);
                                                chargeArrayValue(index,"ChargeCode",partData.chargeCode);
                                                if(partData && partData.taxSlab){
                                                    chargeArrayValue(index,"TaxSlab",parseInt(partData.taxSlab)+"%");
                                                }else{
                                                    chargeArrayValue(index,"TaxSlab","");
                                                }
                                                if(partData && partData.partItemAttributes){
                                                    chargeArrayValue(index,"partItemAttributes",partData.partItemAttributes);
                                                }
                                                if(partData && partData.integrationSource){
                                                    chargeArrayValue(index,"integrationSource",partData.integrationSource);
                                                }
                                            });
                                        }}
                                    />
                            </div>
                            }
                            {((isWorkshopFlow && ['Other'].indexOf(ChargeType.Type) > -1) || ((!isWorkshopFlow && ((partConfig && !partConfig.partConfigEnable && ['Part'].indexOf(ChargeType.Type) > -1) || ([ 'Other'].indexOf(ChargeType.Type) > -1))))) && <div className={(ChargeType.Type == 'Other') ? 'col-xs-4' : 'col-xs-2'}>
                                {
                                    <Input
                                        value={ChargeType.Name || ""}
                                        key={index}
                                        handleOnChange={(e) => {
                                            chargeArrayValue(index,"Name",e.target.value)
                                        }}
                                        id={index+ChargeType.Type}
                                        placeholder={ChargeType.Placeholder}
                                    />
                                }
                            </div>
                            }
                             {((!isWorkshopFlow && ['Part'].indexOf(ChargeType.Type) > -1)) && partConfig && partConfig.partConfigEnable && <div className= 'col-xs-2'>
                                {
                                    <Input
                                        value={ChargeType.partItemDescription || ""}
                                        key={index}
                                        handleOnChange={(e) => {
                                            chargeArrayValue(index,"partItemDescription",e.target.value)
                                        }}
                                        id={index+ChargeType.Type}
                                        placeholder={localString("Part Description")}
                                    />
                                }
                            </div>
                            }
                            {!isWorkshopFlow && ['Part'].indexOf(ChargeType.Type) > -1 &&partConfig && partConfig.partConfigEnable && <div className='col-xs-2'>
                                {
                                    <Dropdown
                                        key={index}
                                        value={ChargeType.partCategory || ''}
                                        options={partCategoryDropdown || []}
                                        placeholder={localString("Part Category")}
                                        handleOnChange={(data) => {
                                            chargeArrayValue(index,"partCategory",data.partCategoryName)
                                            chargeArrayValue(index,"partCategoryID",data.partCategoryID)
                                        }}
                                        filterBy='value'
                                        showDownArrow={false}
                                    />
                                }
                            </div>
                            }
                            {(['Part'].indexOf(ChargeType.Type) < 0) && <div className={(ChargeType.Type == 'Other') ? 'col-xs-3' : 'col-xs-4'}>
                                {
                                    <Input
                                        value={ChargeType.Remarks || ""}
                                        key={index}
                                        handleOnChange={(e) => {
                                            chargeArrayValue(index,"Remarks",e.target.value)
                                            //ChargeType.Remarks = (e.target.value);
                                        }}
                                        placeholder={ChargeType.Type == 'Other' ? localString("Reason") : localString("Remarks")}
                                        // placeholder={ChargeType.Placeholder}
                                    />
                                }
                            </div>
                            }
                            {!isWorkshopFlow && ['Part'].indexOf(ChargeType.Type) > -1 && <div className='col-xs-2'>
                                {
                                    <Input
                                        value={ChargeType.Remarks || ""}
                                        key={index}
                                        handleOnChange={(e) => {
                                            chargeArrayValue(index,"Remarks",e.target.value)
                                        }}
                                        placeholder={ChargeType.Type == 'Other' ? localString("Reason") : localString("Remarks")}
                                        // placeholder={ChargeType.Placeholder}
                                    />
                                }
                            </div>
                            }
                            {!isWorkshopFlow && partConfig && !partConfig.partConfigEnable && ['Part'].indexOf(ChargeType.Type) > -1 && <div className='col-xs-2'>
                                {
                                    <Input
                                        value={ChargeType.ChargeCode || ""}
                                        key={index}
                                        handleOnChange={(e) => {
                                            chargeArrayValue(index,"ChargeCode",e.target.value)
                                            //ChargeType.ChargeCode = (e.target.value);
                                        }}
                                        placeholder={localString("HSN Code")}
                                    />
                                }
                            </div>
                            }
                            <div className={(ChargeType.Type == 'Part' && partConfig && partConfig.partConfigEnable) ? 'col-xs-1' : (ChargeType.Type == 'Part'?'col-xs-2':'col-xs-3')}>
                                {
                                    <Input
                                        value={ChargeType.Charge || ""}
                                        key={index}
                                        handleOnChange={(e) => {
                                            chargeArrayValue(index,"Charge",e.target.value)
                                            //ChargeType.priceCharge = (e.target.value);
                                        }}
                                        placeholder={localString("Amount")}
                                    />
                                }
                            </div>
                            {
                                !isWorkshopFlow && ['Part'].indexOf(ChargeType.Type) > -1 && taxSlabRequired && <div className="col-xs-2">
                                    <Dropdown
                                        id='dropdown'
                                        key={index}
                                        // value={ChargeType.TaxSlab || localString('Taxable Amount')}
                                        value={ChargeType.TaxSlab || ''}
                                        options={taxablePercentage}
                                        placeholder={localString("Taxable Amount")}
                                        handleOnChange={(data) => {
                                            chargeArrayValue(index,"TaxSlab",data.value)
                                            //ChargeType.TaxSlab = data.value;
                                        }}
                                        filterBy='value'
                                        showDownArrow={false}
                                    />
                                </div>
                            }
                            {
                                // <div className="col-xs-2"><span style={{top:"10px"}}  className="glyphicon glyphicon-minus-sign delete-charges-btn" onClick={e=>removeCharge(index)}></span></div>
                                <div className={(ChargeType.Type == 'Part') ? 'col-xs-1' : 'col-xs-2'}><span style={{top:"10px"}}  className="glyphicon glyphicon-minus-sign" onClick={e=>removeCharge(index)}></span></div>
                            }
                            {!isWorkshopFlow && ['Part'].indexOf(ChargeType.Type) > -1 && taxSlabRequired && <div className='col-xs-12' style={{ marginTop: '5px', marginLeft: '23px' }}>
                                {
                                    <Checkbox
                                        value={ChargeType.isInclusive || ''}
                                        handleOnChange={(e) => chargeArrayValue(index,"isInclusive",e.target.checked)}
                                        // name='selectAll'
                                        // isDisabled={item.disable}
                                    >
                                        {localString('Tax Inclusive')}
                                    </Checkbox>
                                }
                            </div>
                            }
                        </div>
                    )	
                })}
            </div>
        }
        <div className="text-right">
            { 
               !consumerServiceRequest.allowAddPart &&  selectedChargeTypeArray.length > 0 && 
                    <Button 
                        handleOnClick={(e)=> saveFunc()} 
                        className='btn squareBtnGhost' 
                        isDisabled={isPartItemByIDApiFailed}
                    >
                        {/* {localString('Save')} */}
                        {localString(accessFn('GSX_FLOW') ? "Save" : "Save Draft")}
                    </Button>
            }
            { 
                isWorkshopFlow && [10,11,12,16,23,38,52,64].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && ['Claim estimation generated', 'Supporting documents required'].indexOf(consumerServiceRequest.Status) == -1 && showEstimationButtons && 
                    <Button 
                        handleOnClick={(e)=> submit()} 
                        className='btn squareBtnGhost' 
                        isDisabled={isPartItemByIDApiFailed}
                    >
                        {localString('Submit')}
                    </Button>
            }
            {
                !isWorkshopFlow && ((accessFn('ADD_PART_POST_APPROVAL') && consumerServiceRequest.allowAddPart) || (accessFn('ALLOW_ADDING_DISCOUNT') && consumerServiceRequest.allowDiscount && 
                ['Claim estimation generated'].indexOf(consumerServiceRequest.Status) > -1) || ((([10,11,12,16,23,38,52,64].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && ['Claim estimation generated', 'Supporting documents required'].indexOf(consumerServiceRequest.Status) == -1) || ([3].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && ['Service invoice generated'].indexOf(consumerServiceRequest.Status) > -1 && isBoseFlow)) && showEstimationButtons)) && 
                    <Button 
                        handleOnClick={(e)=> submit()} 
                        className='btn squareBtnGhost' 
                        isDisabled={isPartItemByIDApiFailed}
                    >
                        {localString('Submit')}
                    </Button>
            }
        </div>
	</>) 
}

export default AddChargesInputArrays; 