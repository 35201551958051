import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Table, Button, Loader, Pagination } from 'sfy-react';
import Swal from "sweetalert2"; 
import moment from 'moment';

import CommonTable from '../../components/Table/Table';
import { displayError } from '../../Utility/Utils';

const ReportGrid = (props) => {
	const { accessFn, localString, apiCall, data, report, pagination, setPagination } = props;
	const [loader, setLoader] = useState(false);
	const qFilter = JSON.parse(props.qFilter);

	const downloadReport = (id) => {
		setLoader(true);
		apiCall({
			url: 'getSignedUrlFromID',
			data: { ReportID: id },
			callBack: (result) => { 
				setLoader(false);
				if(result.success && result.data){
					window.open(result.data.signed_url);
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}				
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const downloadSchedulerReport = (row) => {
		if(row.ReportURL && row.ReportURL !== 'No data found') {
			setLoader(true);
			let reqObj = {
				ScheduleReportID: row.ScheduleReportID,
				Status: row.ReportStatus,
				UserID: qFilter.userID
			}
			apiCall({
				url: 'scheduleReportLogger',
				data: reqObj,
				callBack: (result) => { 
					setLoader(false);
					if(result.success && result.data){
						row.ReportURL && window.open(row.ReportURL, '_blank');
					}else{
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(result)),  
						}); 
					}
				},
				errorCB: (err) => setLoader(false)
			});
		}else {
			Swal.fire({  
				icon: 'error',  
				text: localString('No data found'),  
			}); 
		}
	}

	const reportColumns = [
		{
			title: localString("Report Type"),
			showHeading: true,
			customCell: (row) => <p style={{ paddingTop: '10px' }}>{(row && row.fields && row.fields.ResponseBody && row.fields.ResponseBody.data && localString(row.fields.ResponseBody.data.Report)) || '--'}</p>
		},
		{
			title: localString('Report Requested On'),
			showHeading: true,
			customCell: (row) => <p style={{ paddingTop: '10px' }}>{moment(row.fields.CreatedDate).format('YYYY-MM-DD HH:mm:ss') || '--'}</p>,
		},
		{
			title: localString('Report Status'),
			showHeading: true,
			customCell: (row) => <p style={{ paddingTop: '10px' }} className={row.fields.Status === 'Success' ? 'success' : row.fields.Status === 'Pending' ? 'pending' : 'failure'}>{localString(row.fields.Status) || '--'}</p>,
		},
		{
			title: localString('Download'),
			showHeading: true,
			customCell: (row) => <Button classes='button-ghost' style={{ width: '150px' }} type='button' disabled={row.fields.Status !== 'Success'} onClick={() => downloadReport(row.fields.ReportID)}>{localString('Download File')}</Button>,
		}
	];

	const schedulerReportColumns = [
		{
			title: localString("Report Name"),
			showHeading: true,
			customCell: (row) => <p style={{ paddingTop: '10px' }}>{localString(row.ReportName) || '--'}</p>
		},
		{
			title: localString('Report Executed On'),
			showHeading: true,
			customCell: (row) => <p style={{ paddingTop: '10px' }}>{moment(row.CreatedDate).format('YYYY-MM-DD HH:mm:ss') || '--'}</p>,
		},
		{
			title: localString('Report Status'),
			showHeading: true,
			customCell: (row) => <p style={{ paddingTop: '10px' }} className={row.ReportStatus === 'Success' ? 'success' : row.ReportStatus === 'Failed' ? 'failure' : ''}>{row && row.ReportStatus || '--'}</p>
		},
		{
			title: localString('Download'),
			showHeading: true,
			customCell: (row) => 
				(<Button 
					classes='button-ghost'
					style={{ width: '150px' }} 
					type='button' 
					disabled={!row.ReportURL} 
					onClick={() => downloadSchedulerReport(row)}
				>
					{localString('Download File')}
				</Button>),
		}
	];

	return (
		<>
			{
				loader && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<div className='reportGrid'>
				<CommonTable 
					columns={report === 'schedulerReports' ? schedulerReportColumns : reportColumns}
					data={data}
					localString={localString}
				/>
				{
					report === 'schedulerReports' && pagination.numberOfPages > 1 && 
						<Pagination
							itemsPerPage={pagination.itemsPerPage}
							pages={pagination.numberOfPages}
							totalItems={pagination.count}
							activePage={pagination.pageNo}
							paginate={(pageNumber) => setPagination({...pagination, pageNo: pageNumber})}
						/> 
				}
			</div>
		</>
	)
}

export default ReportGrid;