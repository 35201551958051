import React from 'react';
import {Modal} from 'sfy-react';
import './SelectAddressPopup.scss';
import SelectAddressPopupInput from './SelectAddressPopupInput';
const SelectAddressPopup=(props)=>{
    const {localString,showModal,closeModal} = props;
		return ( <>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='AddressPopup-Modal'>
                <Modal.Header>
                    <h4 className='modal-title text-center'>{localString("Verify Address")}</h4>
                </Modal.Header>
                <Modal.Body>
                    <SelectAddressPopupInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </> )
}
export default SelectAddressPopup;