import React, { useEffect,useState }  from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import {Loader} from 'sfy-react';
import Swal from 'sweetalert2';
import { concat,filter,find, compact,pull,push,assign,merge, uniq, pullAll,findIndex,intersection,difference,forEach } from 'lodash';
import ViewButtonFunctionality from './ViewButtonFunctionality';
import { SERVICETYPE } from '../../../../../Utility/Utils';

const SKIP_LOG_LIST = ['Coverage updated', 'Deductible charged successfully',"Refund in-progress","Refund initiated","Refund processed","Payment Refund processed","Authorization refund in-progress","Authorization refund initiated","Authorization refund successful","Documents uploaded"];

//Repair completed have doubt
const ViewButtonMatrix = (props) =>{
    const {
		accessFn, warrantyAppicableNonClaimCheck, consumerServiceRequest, checkPaymentStatus, checkDecisionMatrixProcessCompleted, checkDeviceValueAndDateAdded,showCompleteServiceFunction,
		coveredUnderPlanCheck, accessPoints, getSource, showCancelButtonFuncton, isSymptomMapAdded, taskListArr, checkIfSalesChannelIsequiredAndPresent, partsRequested, isServiceLocationAssigned, showButton, showUpdateLogisticsRequestButton,
		isRescheduleApplicable,showCompleteRefund,isEmptyBoxServiceable,showShipPackagingMaterialButton, emptyBoxPackagingDetails, getRequestById, buttonLogStatus, enableDisableButton, setIsEachPartReceived,
		isEachPartReceived,setPendingForSpares,getDisplayText,getNextSteps,isSupOrEngg,isHandedToEng,isDiagnosisInitiated,isQCRejectedLog,isDiagnosisCompleted,getCurrentRoleInt,viewDiagnosis,showQCInitiateDiagnosis,showLoader, isSubscriptionEnabled,
		insurancePlans,hideIssueLoanerButton,exception,deviceAtLoggedInLoc,featureConfig,showRequestLogs,consumerDetails,isRequestSFANeeded,requestSFA ,showViewRequestFlags,isDOAEligible,arrDiagnosisSuites,showRequestValid,addedFaultActions,checkDOAApplicable,
		isHandedToCC,berFlags,berValid,setBerValid,fetchBERConfiguration,getApplicationFeatureConfig,loanerDetails, fetchLoanerDetailsByCsrId, getDiagnosisSuites,diagnosticsSuitesCalled, claimNewWorkshopChecks, claimChecks, pickupCarryInGSXChecks,isBoseFlow,
		pickupCarryInChecks, onsiteChecks, berChecks,selectPlanBenefit,replacementFlowChecks,paymentAmount,AdvanceChargeApplicable,showRefundButton,soldPlan,refundProcessCheck,serviceRequestClusterCheck, appleDropOffFlow, refurbishmentFlowCheck
	} = props;
	const qFilter =  JSON.parse(props.qFilter) ;
	const [statusButtons,setStatusButtons]= useState([]);
	const logisitcsServiceTypes= [SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_DROPOFF];
	const [isB2BRequest,setIsB2BRequest] = useState(false)
	const [claimMergeStatus,setClaimMergeStatus] = useState({
        pickUpCancel : false,
        hasClaimInitiated : false,
        isPickUp : false,
        pastForteenDays : false,
        mailInCancel : false,
        carryInCancel : false,
        claimRejected : false,
    });
	const [isReplacementPrerequisitesApplied, setIsReplacementPrerequisitesApplied] = useState(false);
	const [berLogsButtons,setBerLogsButtons] = useState([]);
	const [pickupCarryinGsxFlags,setPickupCarryinGsxFlags] = useState({
		issuesValidated:true
	})
	const [cancelAnytime,setCancelAnytime] = useState(false)
	let claimobj ={}
	claimobj.consumerServiceRequest = {...consumerServiceRequest};

	let cancelanytimecheck = [
        'Pickup Request Accepted',
		'DropOff request accepted',
        'Agent assigned',
        'Agent arrived',
        'Device shipped',
        'Device received',
        'Service initiated',
        'Estimation generated',
        'Estimation approval',
        'Parts pending',
        'Parts received',
        'Estimation rejected',
        'Payment received',
        'Repair completed',
		'Repair initiated',
    ];
	
	useEffect(()=>{
		mergeStatuses()
	},[
		consumerServiceRequest,featureConfig,addedFaultActions,arrDiagnosisSuites,isRescheduleApplicable,showRequestValid.isChargeAdded,deviceAtLoggedInLoc,
		isEmptyBoxServiceable,berFlags.defectiveRequired,isSubscriptionEnabled ,checkDecisionMatrixProcessCompleted,checkPaymentStatus,claimMergeStatus.pastForteenDays,
		showShipPackagingMaterialButton,claimMergeStatus.isPickUp,claimMergeStatus.mailInCancel,claimMergeStatus.hasClaimInitiated ,claimMergeStatus.carryInCancel,
		claimMergeStatus.pickUpCancel,claimMergeStatus.claimRejected,consumerServiceRequest.AdvanceCollected,showRequestLogs.isIssuesValidated ,
		showRequestValid.sendIssuesGSXFlag,showRequestLogs.isAllPartReceived,showRequestValid.allowCCToProceedWithService,insurancePlans.selectPlanBenefit,
		loanerDetails
	]) //,showLoader ,arrDiagnosisSuites

	useEffect(()=>{
		if(berChecks){
			if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID){
				fetchBERConfiguration();
			}
		}
		let B2BRequest = consumerServiceRequest && !consumerServiceRequest.hasOwnProperty('SECURITY_DEPOSIT_AMOUNT')
		setIsB2BRequest(B2BRequest)

		if (cancelanytimecheck.indexOf(consumerServiceRequest.Status) > -1) {
			setCancelAnytime(true)
		} else {
			setCancelAnytime(false)
		}
	},[consumerServiceRequest])
	
	
	const mergeStatuses = () => {
		let claimStatus = {...claimMergeStatus}
		if(consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics.length > 0){
			claimStatus.isPickUp=true
			// setClaimMergeStatus({...claimMergeStatus,isPickUp:true})
        }
        consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.forEach(log=>{
			if (log.Status == 'Claim rejected') {
				claimStatus.claimRejected=true
            }
            if (log.Status=='Repair cancel') {
				claimStatus.pickUpCancel=true
                if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					claimStatus.mailInCancel=true
                }
            }
			if (['Repair cancel', 'Device not eligible for repair', 'Device not repairable', 'No fault found'].indexOf(log.Status) > -1) {
				if(SERVICETYPE.CLAIM_CARRY_IN == consumerServiceRequest.ServiceTypeID){
					claimStatus.carryInCancel=true
				}
			}
            if ((['Claim raised', 'Claim initiated', 'Claim rejected'].indexOf(log.Status) > -1)|| ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && log.Status=='Under review')) {
				claimStatus.hasClaimInitiated=true
            }
            if (log.Status=='In-principle approved' && consumerServiceRequest.Status == 'In-principle approved') {
                if (moment(log.CreatedDate, 'YYYY-MM-DD hh:mm:ss').add(7, 'days').format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') || (accessFn('CANCEL_CLAIM_ON_APPROVAL'))) { // As communicated by Darshana K. and Nimesh D.
					claimStatus.pastForteenDays=true
                }
            }
		})

		setClaimMergeStatus(claimStatus);
		// if(consumerServiceRequest && consumerServiceRequest.decisionMatrix && checkDeviceValueAndDateAdded()){}
		refreshButton();
    }

	const checkInitiatedClaim = () =>{
		let claimInitiatedAlready = false;
		if (consumerServiceRequest) {
			if (findIndex(consumerServiceRequest.logs, {
					'Status': 'Claim initiated'
				}) > -1 || findIndex(consumerServiceRequest.logs, {
					'Status': 'Under review'
				}) > -1) {
				claimInitiatedAlready = true;
			}
		}
	};

	let commonButtonFn = ()=>{
		let status =[]
		if((!(showRequestLogs.isServiceCancel || showRequestLogs.isClaimWithdrawn)) && (!accessFn('WORKSHOP_FLOW') || showRequestLogs.enableAttemptCall) && (showRequestLogs.enableAttemptCall || !(([3,5,6,7,8,14,15,35,36,56,60,61,62].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && !accessFn('READ_ONLY'))) && !accessFn('HIDE_COMMUNICATION_HISTORY_TAB')){
			status  = concat(status,'Log Call')
		}
		if(accessFn('FLAG_FRAUD_REQUEST')){
			status  = concat(status,'Flag Fraud')
			// setStatusButtons(fraudStatus)
		}
		if(accessFn('DOWNLOAD_LOGISTICS_LABEL') && (consumerServiceRequest.Status != 'Service completed') && (!accessFn('WORKSHOP_FLOW') || (accessFn('WORKSHOP_FLOW') && accessFn('ACCEPT_RETURN_DEVICE')))){
			if(consumerServiceRequest.slipUrl && consumerServiceRequest.slipUrl.length > 0){
				consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
					item.documents.map(document=>{
						status =concat(status ,document);
					})
				})
			}
		}
		
		if(!(accessFn('WORKSHOP_FLOW')) && !(accessFn('READ_ONLY')) && accessFn('PACKAGE_DETAILS') && ([17,23].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && showShipPackagingMaterialButton){
			status  = concat(status ,'Ship Packaging Material');
		}
		if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && cancelAnytime && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				status = concat(status,'Cancel Service Without Dispatch');
			}
		}
		if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			if(accessFn('ALLOW_REFUND') && showRefundButton()){
				status = concat(status, 'Initiate Refund');
			}
		}

		return status
	}

	const getBtnsBasedOnStatus = () => {
		let btns = []
		let obj = find(statusMatrix, {csrStatus: consumerServiceRequest.Status});
		if(obj) {
			btns = obj.computeNextStatus();
		}
		return btns;
	}

	const getBtnsBasedOnLog = (log) => {
		let btns = []
		let obj = find(logMatrix, {csrlStatus: log});
		if(obj) {
			btns = obj.computeNextStatus();
		}
		return btns;
	}

	const refreshButton = () =>{
		let csrLogs;
		let statusBtn =[]
		let newstatus=[];
		let btnStatus =[]
		let statusBasedbtns = []
		let logBasedbtns = []
		let commonButtons =[] 
		let temp = [];
		if (consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.length > 0) {
			// csrLogs = filter(consumerServiceRequest.logs, function(o) { return o.ConsumerServiceRequestLogID; })
			csrLogs = filter(consumerServiceRequest.logs, function(o) { 
				if(o.ConsumerServiceRequestLogID && !SKIP_LOG_LIST.includes(o.Status)) {
					return o.ConsumerServiceRequestLogID; 
				}
			})
			let obj;
			
			if(csrLogs && csrLogs.length > 0) {
				// console.log("------",{csrStatus: consumerServiceRequest.Status, csrlStatus: csrLogs[0].Status})
				obj = find(statusComputeMatrix, {csrStatus: consumerServiceRequest.Status, csrlStatus: csrLogs[0].Status});
			}
			if (obj) {
				btnStatus = obj.computeNextStatus();
				statusBtn = commonButtonFn();
				temp = obj.computeNextStatus();
				if(temp!=null){
					setStatusButtons(temp);
				}
				newstatus = enableDisableButton(temp)
			}

			// Commenting below code for future use case
			// statusBasedbtns = getBtnsBasedOnStatus();
			// logBasedbtns = getBtnsBasedOnLog(csrLogs?.[0]?.Status);
			// btnStatus = [...btnStatus, ...statusBasedbtns, ...logBasedbtns];

			// setShowRefreshButton(true);
			if(onsiteChecks){
				// if(accessFn('READ_ONLY_WORKSHOP') || (['In-home request accepted', 'Pending for assignment'].indexOf(consumerServiceRequest.Status) > -1 && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && (!checkDeviceValueAndDateAdded() || !coveredUnderPlanCheck()))) {
				// 	newstatus = []
					
				// }
				setStatusButtons([...statusButtons,newstatus])
			}
			//have not
			//claim
			if(claimChecks){
				if(consumerServiceRequest && consumerServiceRequest.blockCancellation){
					btnStatus = pull(btnStatus, 'Service cancel');
					btnStatus = pull(btnStatus, 'Repair cancel');
					btnStatus = pull(btnStatus, 'Cancel Service Without Dispatch');
				}
				if(accessFn('HIDE_SERVICE_COMPLETE')){
					btnStatus = pull(btnStatus, 'Service completed');
				}
				if(accessFn('HIDE_PAYMENT_RECEIVED')){
					btnStatus = pull(btnStatus, 'Payment received');
				}
				if(accessFn('HIDE_REPAIR_COMPLETE')){
					btnStatus = pull(btnStatus, 'Repair completed');
				}

				checkInitiatedClaim()
				// setCommonStatus(status);
			}
			//claim end
			//claim new  workshop
			if(claimNewWorkshopChecks){
				if((accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && (!showRequestLogs.isServiceCompleteRated && !showRequestLogs.isServiceCancel)) {
					btnStatus = concat(btnStatus, 'Call attempted');
				}
				if (!showRequestLogs.isDeviceDispatch && !showRequestLogs.isServiceComplete && !showRequestLogs.isServiceCompleteRated && !showRequestLogs.isServiceCancel) {
					if(consumerServiceRequest.ShowHandOver) {
						if (consumerServiceRequest.ShowHandOverTo) {
							if (getCurrentRoleInt() == consumerServiceRequest.ShowHandOverTo) {
								btnStatus = concat(btnStatus, 'Device handed over');
							}
						}
					} else {
						if (consumerServiceRequest.ShowDeviceAcceptedTo) {
							if (getCurrentRoleInt() == consumerServiceRequest.ShowDeviceAcceptedTo) {
								btnStatus = concat(btnStatus, 'Device accepted');
							}
						}
					}
				}
				if ((isDiagnosisCompleted() || viewDiagnosis) && consumerServiceRequest.consumerProduct && !consumerServiceRequest.consumerProduct.isAppleBrandID && (accessFn('REPAIR_DEVICE') || accessFn('QC_DEVICE'))) {
					if(accessFn('DIAGNOSIS')){
						btnStatus = concat(btnStatus, 'Show device diagnosis');
					}
				}
				if(accessFn('READ_ONLY')) {
					let isServiceCompleteAdded = false
					if (btnStatus && btnStatus.indexOf('Service completed') > -1 ) {
						isServiceCompleteAdded = true
					}
					btnStatus = []
				}
				if(accessFn('HIDE_CANCEL_SERVICE_BUTTON') || consumerServiceRequest?.blockCancellation) {
					if(btnStatus?.length) {
						btnStatus = pull(btnStatus, 'Service cancel', 'Repair cancel', 'Cancel Service Without Dispatch');
					}
				}
				if(accessFn('HIDE_PAYMENT_RECEIVED')){
					btnStatus = pull(btnStatus, 'Payment received');
				}
		//Customer Care Executive (right: ACCEPT_RETURN_DEVICE) or Supervisor(right: REASSIGN_JOBS) can Attempt Call
				// if((accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && (!showRequestLogs.isServiceCompleteRated && !showRequestLogs.isServiceCancel)) {
				// 	claimWorkshopStatus = concat(claimWorkshopStatus, 'Call attempted');
				// }
				if(accessFn('HIDE_REPAIR_COMPLETE')){
					btnStatus = pull(btnStatus, 'Repair completed');
				}
				btnStatus = compact(btnStatus);
			}
			//pickUp carryin
			if(pickupCarryInChecks){
				if(!consumerServiceRequest.consumerProduct.isAppleBrandID){
					if((accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && (!showRequestLogs.isServiceCompleteRated && !showRequestLogs.isServiceCancel)) {
						btnStatus = concat(btnStatus, 'Call attempted');
					}
					if (!showRequestLogs.isDeviceDispatch && !showRequestLogs.isServiceComplete && !showRequestLogs.isServiceCompleteRated && !showRequestLogs.isDOACompleted) {
						if(consumerServiceRequest.ShowHandOver && consumerServiceRequest.DeviceReturnTo && consumerServiceRequest.DeviceReturnTo.length > 0) {
							if (consumerServiceRequest.ShowHandOverTo) {
								if (getCurrentRoleInt() == consumerServiceRequest.ShowHandOverTo)
								{
									btnStatus = concat(btnStatus, 'Device handed over');
								}
							}
						} else {
							if (consumerServiceRequest.ShowDeviceAcceptedTo) {
								if (getCurrentRoleInt() == consumerServiceRequest.ShowDeviceAcceptedTo)
								{
									btnStatus = concat(btnStatus, 'Device accepted');
								}
							}
						}
					}
					if(accessFn('READ_ONLY')) {
						btnStatus = []
					}
					if ((isDiagnosisCompleted() || viewDiagnosis) && consumerServiceRequest.consumerProduct && !consumerServiceRequest.consumerProduct.isAppleBrandID && (accessFn('REPAIR_DEVICE') || accessFn('QC_DEVICE'))) {
						if(accessFn('DIAGNOSIS')){
							btnStatus = concat(btnStatus, 'Show device diagnosis');
						}
					}
					if(accessFn('HIDE_PAYMENT_RECEIVED')){
						btnStatus = pull(btnStatus, 'Payment received');
					}
					if(accessFn('HIDE_REPAIR_COMPLETE')){
						btnStatus = pull(btnStatus, 'Repair completed');
					}
					// if(exception && showRequestLogs.isRepairCompleted) {
					// 	pull(pickUpCarryInStatus, 'Device handed over');
					// }
				}else{
					if(accessFn('GSX_FLOW') && consumerServiceRequest && consumerServiceRequest.allowRejectGSX && (accessFn('REPAIR_DEVICE') || accessFn('STORE_MANAGEMENT'))){
						btnStatus = concat(btnStatus, 'Reject GSX Repair');
					}
					if(deviceAtLoggedInLoc){
						if (!showRequestLogs.isDeviceDispatch && !showRequestLogs.isServiceComplete && !showRequestLogs.isServiceCompleteRated && !showRequestLogs.isDOACompleted) {
							if(consumerServiceRequest.ShowHandOver && consumerServiceRequest.DeviceReturnTo && consumerServiceRequest.DeviceReturnTo.length > 0) {
								if (consumerServiceRequest.ShowHandOverTo) {
									if (getCurrentRoleInt() == consumerServiceRequest.ShowHandOverTo)
									{
										btnStatus = concat(btnStatus, 'Device handed over');
									}
								}
							} else {
								if (consumerServiceRequest.ShowDeviceAcceptedTo) {
									if (getCurrentRoleInt() == consumerServiceRequest.ShowDeviceAcceptedTo)
									{
										btnStatus = concat(btnStatus, 'Device accepted');
									}
								}
							}
						}						
						if(accessFn('GSX_FLOW') && accessFn('REPAIR_DEVICE') && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId){
							let issueAddedValid = true
							if(consumerServiceRequest.issues && consumerServiceRequest.issues.length > 0) {
								for(let i = 0; i < consumerServiceRequest.issues.length; i++){
									if(consumerServiceRequest.issues[i].IsValidated == false) {
										issueAddedValid = false;
										break;
									}
								}
							}
							if (showRequestValid.sendIssuesGSXFlag) {
								setPickupCarryinGsxFlags({...pickupCarryinGsxFlags,issuesValidated:false})
							}
							else {
								if (issueAddedValid) {
									setPickupCarryinGsxFlags({...pickupCarryinGsxFlags,issuesValidated:true})
								}
								else {
									setPickupCarryinGsxFlags({...pickupCarryinGsxFlags,issuesValidated:false})
								}
							}
						}
						if (accessFn('GSX_FLOW') && accessFn('REPAIR_DEVICE') && ['Estimation generated', 'Estimation rejected', 'Parts request accepted', 'Parts request created', 'Parts issued', 'Parts received', 'Parts rejected', 'Work in progress', 'Service in-progress', 'Advance collected'].indexOf(consumerServiceRequest.Status) > -1 && accessFn('ADD_NORMAL_CHARGES') && !consumerServiceRequest.InvoiceGeneratedDate && pickupCarryinGsxFlags.issuesValidated && (consumerServiceRequest.issues.length > 0 || (addedFaultActions && addedFaultActions.length > 0))){
							btnStatus = concat(btnStatus, 'Fetch Parts From GSX');
						}
					}
					if (consumerServiceRequest && consumerServiceRequest.showLatestDiagnosticsResult) {
						btnStatus = concat(btnStatus, 'Fetch Latest Diagnostics Results');
					}
					if(consumerServiceRequest && consumerServiceRequest.ConsumerAmount && consumerServiceRequest.ConsumerAmount > 0 && accessFn('ACCEPT_RETURN_DEVICE') && qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1){
						if ((!showRequestLogs.isQcCompleted || !showRequestLogs.isServiceComplete) && showRequestLogs.isDeviceReceived) {
							btnStatus = concat(btnStatus, 'Send link to consumer');
						}
					}
					if (consumerServiceRequest.IsWarrantyApplicable == null || undefined) {
						btnStatus = pull(btnStatus, 'Non Hardware Closure');
					}

					if(consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.loanerDetails && ['LOHC'].indexOf(consumerServiceRequest.loanerDetails.currentStatus) > -1){
						btnStatus = pull(btnStatus, 'Repair cancel');
					}
					if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL==="true" && find(consumerServiceRequest.logs,{Status:'Shipped to service centre'})){
						btnStatus = pull(btnStatus, 'Service cancel');
						btnStatus = pull(btnStatus, 'Repair cancel');
					}
					if(partsRequested && partsRequested.length == 0 && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId != 3) {
						btnStatus = pull(btnStatus, 'Map G-Number');
					}

					if(consumerServiceRequest.deviceTransferData && consumerServiceRequest.deviceTransferData.isTransferredRequest && ['Repair completed'].indexOf(csrLogs[0].Status) > -1) {
						btnStatus = pull(btnStatus, 'Device handed over');
					}
					if(appleDropOffFlow) { // Remove Transfer request and Device handed over btn in case of dropOff flow(GSX)
						btnStatus = pull(btnStatus, 'Device handed over');
						btnStatus = pull(btnStatus, 'Transfer request');
					}
					btnStatus = concat(btnStatus, 'Call attempted');
				}
				if(accessFn('HIDE_REPAIR_COMPLETE')){
					btnStatus = pull(btnStatus, 'Repair completed');
				}
				// remove falsey values
				btnStatus = compact(btnStatus);
				// setStatusButtons(pickUpCarryInStatus);			
			}
			//end pickUp carryin
			//ber 
			if(berChecks){
				if(!accessFn('APPROVE_BER')){
					btnStatus = pull(btnStatus, 'BER approved');
				}
				if(!accessFn('VALIDATE_BER_DOCS')){
					btnStatus = pull(btnStatus, 'Document pending', 'BER documents validated', 'BER accessories validated','BER accessories rejected');
				}
				if(!accessFn('INITIATE_BER_PAYMENT')){
					btnStatus = pull(btnStatus, 'BER payment initiated');
				}
				if(!accessFn('COMPLETE_BER_PAYMENT')){
					btnStatus = pull(btnStatus, 'BER payment completed');
				}
				if(!accessFn('BER_CUSTOMER_ACTION')){
					btnStatus = pullAll(btnStatus, ['BER confirmed', 'BER repair', 'BER replacement', 'BER cancel']);
				}
				if(!accessFn('RECEIVE_DEVICE_ACCESSORIES')){
					btnStatus = pullAll(btnStatus, ['BER device received', 'BER accessories received']);
				}
				if(!accessFn('BER_REPLACEMENT_ACTION')) {
					btnStatus = pullAll(btnStatus, ['BER shipment created', 'Add Shipment Details', 'BER replacement issued', 'Device dispatched', 'Intimate customer']);
				}
				if(!accessFn('BER_REPLACEMENT_INTIMATE_CUSTOMER')) {
					btnStatus = pull(btnStatus, 'Intimate customer');
				}
				if(consumerServiceRequest.ServiceTypeID == 60 && consumerServiceRequest.ConsumerServiceRequestDetails && consumerServiceRequest.ConsumerServiceRequestDetails.IsBERHubShipmentFailure) {
					btnStatus = pull(btnStatus, 'BER device received');
				}
				if (!accessFn('CLAIM_APPROVAL') || (soldPlan && soldPlan && !soldPlan.allowClaimWithdrawal)) {
					btnStatus = pull(btnStatus,  'Claim withdrawn');
				}
				if (!accessFn('CLAIM_APPROVAL')){
					btnStatus = pull(btnStatus, 'Claim rejected');
				}
				if(consumerServiceRequest.AccessoriesStatus == -1){
					btnStatus = pull(btnStatus, 'BER accessories received');
					btnStatus = pullAll(btnStatus, ['BER accessories validated','BER accessories rejected']);
				}
				if(consumerServiceRequest.PendingDoc && consumerServiceRequest.PendingDoc == 1){
					btnStatus = pullAll(btnStatus, ['Document pending', 'BER documents validated']);
					if(!berValid.paymentModeCheque){
						btnStatus = pull(btnStatus, 'BER payment initiated');
					}
				}
				// if(!isReplacementPrerequisitesApplied && getReplacementButtonPrerequisites[consumerServiceRequest.Status]) {
				// 	setIsReplacementPrerequisitesApplied(true);
				// 	btnStatus = pull(btnStatus, getReplacementButtonPrerequisites[consumerServiceRequest.Status]);
				// }
				// if(!isReplacementPrerequisitesApplied){
				// 	setIsReplacementPrerequisitesApplied(true);
					if( (consumerServiceRequest.Status == 'BER replacement confirmed')){
						btnStatus = pullAll(btnStatus,['BER shipment created', 'Add Shipment Details','Service completed', 'Device dispatched', 'Intimate customer']);
					}else if((consumerServiceRequest.Status == 'BER replacement issued')){
						if(consumerServiceRequest.ConsumerServiceRequestDetails &&  consumerServiceRequest.ConsumerServiceRequestDetails.IsExternalForwardShipment){
							btnStatus = pullAll(btnStatus,['BER replacement confirmed', 'BER replacement issued', 'BER shipment created', 'Add Shipment Details', 'Intimate customer', 'BER accessories received'])
						}else{
							btnStatus = pullAll(btnStatus,['BER replacement confirmed', 'BER replacement issued', 'BER accessories received'])
						}					
					}else if((consumerServiceRequest.Status == 'BER shipment created')){
						if(consumerServiceRequest.ConsumerServiceRequestDetails &&  consumerServiceRequest.ConsumerServiceRequestDetails.IsExternalForwardShipment){
							btnStatus = pullAll(btnStatus,['BER shipment created', 'Add Shipment Details', 'BER replacement confirmed', 'BER replacement issued', 'Device dispatched', 'Intimate customer', 'BER accessories received'])
						}else{
							btnStatus = pullAll(btnStatus,['BER shipment created', 'Add Shipment Details','Service completed', 'BER replacement confirmed', 'BER replacement issued', 'Intimate customer', 'BER accessories received'])
						}
					}else if((consumerServiceRequest.Status == 'Device dispatched')){
						btnStatus = pullAll(btnStatus,['BER shipment created', 'Add Shipment Details', 'BER replacement confirmed', 'Device dispatched', 'BER replacement issued', 'Intimate customer', 'BER accessories received']);
					}else if((consumerServiceRequest.Status == 'Intimate customer')){
						btnStatus = pullAll(btnStatus,['BER replacement confirmed', 'BER replacement issued', 'BER shipment created', 'Add Shipment Details', 'Intimate customer', 'Device dispatched', 'BER accessories received']);
					}
				// }
				
				if(!berValid.accessoriesReceived){
					btnStatus = pullAll(btnStatus, ['BER accessories validated','BER accessories rejected']);
				}
				
				for(let i = 0; i < consumerServiceRequest.logs.length; i++){
					let log = consumerServiceRequest.logs[i];
					if((log.Status != "Document pending") ) {
						btnStatus = pull(btnStatus, log.Status);
					}
					if(log.Status == 'BER accessories received'){
						setBerValid({...berValid,accessoriesReceived:true})
					}
					if(consumerServiceRequest.AccessoriesStatus && (log.Status == 'BER accessories validated' || log.Status == 'BER accessories rejected')){
						btnStatus = pullAll(btnStatus, ['BER accessories validated','BER accessories rejected']);
					}
					if(log.Status == 'BER documents validated'){
						btnStatus = pull(btnStatus, "Document pending");
					}
					// if(!isReplacementPrerequisitesApplied){
					// 	setIsReplacementPrerequisitesApplied(true);
						if( (!(consumerServiceRequest.Status == 'BER replacement confirmed'))   && (log.Status == 'BER replacement confirmed' )){
							if(consumerServiceRequest.Status != 'BER replacement issued' && log.Status != 'BER replacement issued'){
								btnStatus = pullAll(btnStatus,['BER shipment created', 'Add Shipment Details','Service completed', 'Device dispatched', 'Intimate customer']);
							}
							else {
								btnStatus = pullAll(btnStatus,['Device dispatched', 'Intimate customer']);
							}
						}else if((!(consumerServiceRequest.Status == 'BER replacement issued'))  && (log.Status == 'BER replacement issued' )){
							if(consumerServiceRequest.ConsumerServiceRequestDetails &&  consumerServiceRequest.ConsumerServiceRequestDetails.IsExternalForwardShipment){
								btnStatus = pullAll(btnStatus,['BER replacement confirmed', 'BER replacement issued', 'BER shipment created', 'Add Shipment Details', 'Intimate customer', 'BER accessories received'])
							}else{
								btnStatus = pullAll(btnStatus,['BER replacement confirmed', 'BER replacement issued', 'BER accessories received'])
							}					
						}else if((!(consumerServiceRequest.Status == 'BER shipment created'))   && (log.Status == 'BER shipment created' )){
							if(consumerServiceRequest.ConsumerServiceRequestDetails &&  consumerServiceRequest.ConsumerServiceRequestDetails.IsExternalForwardShipment){
								btnStatus = pullAll(btnStatus,['BER shipment created', 'Add Shipment Details', 'BER replacement confirmed', 'BER replacement issued', 'Device dispatched', 'Intimate customer', 'BER accessories received'])
							}else{
								btnStatus = pullAll(btnStatus,['BER shipment created', 'Add Shipment Details','Service completed', 'BER replacement confirmed', 'BER replacement issued', 'Intimate customer', 'BER accessories received'])
							}
						}else if( (!(consumerServiceRequest.Status == 'Device dispatched'))   && (log.Status == 'Device dispatched' )){
							btnStatus = pullAll(btnStatus,['BER shipment created', 'Add Shipment Details', 'BER replacement confirmed', 'Device dispatched', 'BER replacement issued', 'Intimate customer', 'BER accessories received']);
						}else if( (!(consumerServiceRequest.Status == 'Intimate customer'))   && (log.Status == 'Intimate customer' )){
							btnStatus = pullAll(btnStatus,['BER replacement confirmed', 'BER replacement issued', 'BER shipment created', 'Add Shipment Details', 'Intimate customer', 'Device dispatched', 'BER accessories received']);
						}
					// }
				}
				if(consumerServiceRequest.Status == 'BER shipment created') {
					btnStatus = concat(btnStatus,['Device dispatched']);
					btnStatus = uniq(btnStatus)
				}
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.BER_REPLACEMENT && consumerServiceRequest.Status == 'Device dispatched') {
					btnStatus = concat(btnStatus,['Service completed']);
					btnStatus = uniq(btnStatus)
				}
				if(showRequestLogs.BERConfirmed && berValid.paymentModeCheque){
					btnStatus = pullAll(btnStatus, ['Document pending', 'BER documents validated']);
				}
				if(accessFn('HIDE_PAYMENT_RECEIVED')){
					btnStatus = pull(btnStatus, 'Payment received');
				}
				if(accessFn('HIDE_SERVICE_COMPLETE')){
					btnStatus = pull(btnStatus, 'Service completed');
				}
				btnStatus  = compact(btnStatus);
				if(consumerServiceRequest && consumerServiceRequest.logs){
					let Statuses = []; 
					forEach(consumerServiceRequest.logs, function(log) {
						Statuses.push(log.Status);
					});
					forEach(getButtonPrerequisites(), function(value, key){
						let v1 = intersection(value, Statuses);
						if(difference(value, v1).length > 0){
							btnStatus = pull(btnStatus, key);
						}
					});
				}
			}
			//ber end
			//replacement flow
			if(replacementFlowChecks){
				if(consumerServiceRequest && consumerServiceRequest.showChargeHoldButton && accessFn('CHARGE_SECURITY_DEPOSIT')){
					btnStatus =concat(btnStatus,'Charge Credit Card')
				}
				if(accessFn('HIDE_PAYMENT_RECEIVED')){
					btnStatus = pull(btnStatus, 'Payment received');
				}
				if(btnStatus?.length && consumerServiceRequest?.blockCancellation) {
					pull(btnStatus, 'Service cancel');
			   	}
			}
			// end replacement flow

			// proof action buttons
			if(consumerServiceRequest && consumerServiceRequest.Status && ["Service center document verification pending"].includes(consumerServiceRequest.Status)){
				btnStatus = [...btnStatus, 'Repair cancel']

				if(consumerServiceRequest.showClaiamEstimationRevisionRequired){
					btnStatus = [...btnStatus, 'Claim estimation revision required']
				}
				
				if(accessFn("PROOF_ADJUDICATOR_VIEW")) {
					btnStatus = [...btnStatus, 'Proof revision required']
				}

				if(!accessFn('HIDE_CANCEL_SERVICE_BUTTON')) {
					btnStatus = [...btnStatus, 'Service cancel']
				}
			}
		}

		// Loaner specific btns
		let loanerBtns = displayLoanerButtonsInGSXFlow();
		
		if(loanerBtns && loanerBtns.length) {
			commonButtons = concat(statusBtn, btnStatus, loanerBtns)
		}else {
			commonButtons = concat(statusBtn, btnStatus)
		}
		let uniqCommonButtons = uniq(commonButtons)
        setStatusButtons(uniqCommonButtons);
	}

	const getButtonPrerequisites = function(){
        return {
        'BER payment initiated': showRequestLogs.BERConfirmed && (!berFlags.DefectiveRequired)? ['BER confirmed']:(showRequestLogs.BERConfirmed && berFlags.DefectiveRequired)?['BER device received','BER confirmed']:['BER device received','BER documents validated'],
        'BER payment completed': ['BER payment initiated']
        }
    }
	const displayLoanerButtonsInGSXFlow = () => {
		let pickUpCarryInStatusGSX = [];
		if(accessFn('LOANER_FLOW') && consumerServiceRequest && consumerServiceRequest.loanerDetails && consumerServiceRequest.loanerDetails.loanerRequested) {
			let csrLoanerDetails = consumerServiceRequest.loanerDetails;
			if((loanerDetails && loanerDetails.loanerRequested) && (csrLoanerDetails && csrLoanerDetails.enableIssueLoaner) && (consumerServiceRequest && consumerServiceRequest.repairConfirmationNumber) && accessFn('STORE_MANAGEMENT')) {
				pickUpCarryInStatusGSX = concat(pickUpCarryInStatusGSX, 'Issue Loaner Device');
			}
			if((loanerDetails && loanerDetails.loanerRequested) && (csrLoanerDetails && csrLoanerDetails.enableHandoverLoaner) && accessFn('ACCEPT_RETURN_DEVICE') && !showRequestLogs.isRepairCompleted) {
				pickUpCarryInStatusGSX = concat(pickUpCarryInStatusGSX, 'Handover Loaner Device to Customer');	
			}
			if((loanerDetails && loanerDetails.loanerRequested) && (csrLoanerDetails && csrLoanerDetails.enableLoanerReturn) && (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REPAIR_DEVICE')) && showRequestLogs?.enableReturnProcess) {
				pickUpCarryInStatusGSX = concat(pickUpCarryInStatusGSX, 'Accept Loaner Device');	
			}
			if((loanerDetails && loanerDetails.loanerRequested) && (csrLoanerDetails && csrLoanerDetails.enableDiagnosisLoaner) && accessFn('REPAIR_DEVICE')){
				pickUpCarryInStatusGSX = concat(pickUpCarryInStatusGSX, 'Run Diagnosis on Loaner Device');	
			}
			if((loanerDetails && loanerDetails.loanerRequested) && (csrLoanerDetails && loanerDetails.chargetoCustomer && ['LOST','LOAI'].indexOf(csrLoanerDetails.currentStatus) > -1) && accessFn('ACCEPT_RETURN_DEVICE')) {
				pickUpCarryInStatusGSX = concat(pickUpCarryInStatusGSX, 'Collect Loaner Payment');	
			}
			if((loanerDetails && loanerDetails.loanerRequested) && (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REPAIR_DEVICE')) &&  ((!loanerDetails.chargetoCustomer && csrLoanerDetails.currentStatus == "LOAI") || (loanerDetails.chargetoCustomer && csrLoanerDetails.currentStatus == "LOIG"))) {
				pickUpCarryInStatusGSX = concat(pickUpCarryInStatusGSX, 'Loaner Service Complete');	
			}
			// setStatusButtons([...statusButtons, ...pickUpCarryInStatusGSX]);
		}
		return pickUpCarryInStatusGSX;
	}

	// useEffect(() => {
	// 	(consumerServiceRequest && loanerDetails) && displayLoanerButtonsInGSXFlow();
	// }, [consumerServiceRequest, loanerDetails])
	
	const isAdvancePaidCheck = () => {
		let advancePaid = true;
		if (consumerServiceRequest && consumerServiceRequest.charges && consumerServiceRequest.charges.length > 0) {
			for (let iCount=0;iCount<consumerServiceRequest.charges.length;iCount++) {
				if (consumerServiceRequest.charges[iCount].Type == 'Advance payment' && consumerServiceRequest.charges[iCount].IsAdvancePaid == 0) {
					advancePaid = false;
				}
			}
		}
		return advancePaid;
	}

	const logMatrix = [
		{
			csrlStatus: 'Device shipped',
			computeNextStatus: () => {
				let btns = ['Log based btn'];
				return btns
			}
		}
	]

	const statusMatrix = [
		{
			csrStatus: 'Device shipped',
			computeNextStatus: () => {
				let btns = ['Status based btn'];
				return btns
			}
		}
	]
	
	const statusComputeMatrix = [{
        csrStatus: 'Device shipped',
		csrlStatus: 'Device shipped',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))) { //chnages
						statuses = concat(statuses,'Device Received');
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			//----end

			//Initiate Exception comes from  Exception
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					// check for date of purchase and cost is entered or not.
					if (consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						merge(statuses, 'Device received');
					}
				}
			}
			//end claim new
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = concat(claimStatuses, 'Device received', 'Service cancel');
						// }
					}
				}
				// claimStatuses = concat(claimStatuses,'Call attempted');
				statuses = concat(statuses,claimStatuses)
			}
			//claim end
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						statuses = concat(statuses, 'Device received');
						if(consumerServiceRequest.originCenter){
							statuses = concat(statuses, 'Service cancel');
						}
					}
					else {
						statuses = concat(statuses, 'Device received', 'Service cancel', 'Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	// {
    //     csrStatus: 'Device shipped',
	// 	csrlStatus: 'Device shipped',
	// 	computeNextStatus: function() {
	// 		let statuses = [];
	// 		if(pickupCarryInChecks){
	// 			if (accessFn('ACCEPT_RETURN_DEVICE')) {
	// 				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
	// 					statuses = concat(statuses, 'Device received');
	// 					if(consumerServiceRequest.originCenter){
	// 						statuses = concat(statuses, 'Service cancel');
	// 					}
	// 				}
	// 			}
	// 		}
	// 		// Pickup carry in end 
	// 		return statuses;
	// 	}
    // },

	{
        csrStatus: 'Pickup Request Accepted',
		csrlStatus: 'Pickup Request Accepted',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if((!consumerServiceRequest.showEngineerVisits) && (!(accessFn('WORKSHOP_FLOW'))) && showUpdateLogisticsRequestButton()){
					statuses = concat(statuses,'Update Logistics Request')
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses = concat(statuses,document);
			// 	})
			// })
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))) {
						statuses = concat(statuses,'Device Received');
					}
				}
				
				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')  ) {
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON')  && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Device shipped');
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			
			if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}

			//comes from Exception
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim 
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses =  merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
					}
				}
				if(accessFn('SHOW_DEVICE_SHIPPED_BUTTON')){
					if(consumerServiceRequest.showShippedButton){
						if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&  claimMergeStatus.isPickUp)) {
							if (accessFn('ADD_NORMAL_CHARGES')) {
								claimStatuses.push('Device shipped')
							}
						}
					}
				}
				statuses = concat(statuses,claimStatuses)
			}
			//--end claim
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (!consumerServiceRequest.consumerProduct.WhitelistedDevice && qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 && consumerServiceRequest.consumerProduct.gsxInfo.isCellular) {
							statuses = concat(statuses, 'Check whitelisting');
						}
						else {
							statuses = concat(statuses, 'Device received');
							if(consumerServiceRequest.originCenter){
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
					else {
						statuses = concat(statuses, 'Device received', 'Service cancel', 'Cancel Service Without Dispatch');
						if (accessFn('CANCEL_LOGISTICS') && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active) {
							statuses = concat(statuses, 'Cancel logistics request');
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Pickup Request Accepted',
		csrlStatus: 'Payment pending',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))) {
						statuses = concat(statuses,'Device Received');
					}
				}
				
				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')  ) {
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON')  && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Device shipped');
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			
			if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}

			//comes from Exception
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//claim 
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
					}
				}
				if(accessFn('SHOW_DEVICE_SHIPPED_BUTTON')){
					if(consumerServiceRequest.showShippedButton){
						if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&  claimMergeStatus.isPickUp)) {
							if (accessFn('ADD_NORMAL_CHARGES')) {
								claimStatuses.push('Device shipped')
							}
						}
					}
				}
				// claimStatuses = claimStatuses.pull('Device received');
				claimStatuses = pull(claimStatuses, 'Device received');
				statuses = concat(statuses,claimStatuses);
			}
			//--end claim
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			if((!consumerServiceRequest.showEngineerVisits) && (!(accessFn('WORKSHOP_FLOW'))) && showUpdateLogisticsRequestButton()){
					statuses = concat(statuses,'Update Logistics Request')
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses = concat(statuses,document);
			// 	})
			// })
			return statuses;
		}
    },
	{
        csrStatus: 'Pickup Request Accepted',
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))) {
						statuses = concat(statuses,'Device Received');
					}
				}
				
				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')  ) {
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON')  && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Device shipped');
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}

			//comes from Exception
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}

			//claim 
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses =  merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
					}
				}
				if(accessFn('SHOW_DEVICE_SHIPPED_BUTTON')){
					if(consumerServiceRequest.showShippedButton){
						if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&  claimMergeStatus.isPickUp)) {
							if (accessFn('ADD_NORMAL_CHARGES')) {
								claimStatuses.push('Device shipped')
							}
						}
					}
				}
				statuses = concat(statuses,claimStatuses)
			}
			//--end claim
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Device received', 'Service cancel', 'Cancel Service Without Dispatch');
					if (accessFn('CANCEL_LOGISTICS') && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active) {
						statuses = concat(statuses, 'Cancel logistics request');
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Pickup Request Accepted',
		csrlStatus: 'Waiting for Assignment',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))) {
						statuses = concat(statuses,'Device Received');
					}
				}
				
				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')  ) {
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON')  && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Device shipped');
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}

			//comes from Exception
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}

			//claim 
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses =  merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
					}
				}
				if(accessFn('SHOW_DEVICE_SHIPPED_BUTTON')){
					if(consumerServiceRequest.showShippedButton){
						if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&  claimMergeStatus.isPickUp)) {
							if (accessFn('ADD_NORMAL_CHARGES')) {
								claimStatuses.push('Device shipped')
							}
						}
					}
				}
				statuses = concat(statuses,claimStatuses)
			}
			//--end claim
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (!consumerServiceRequest.consumerProduct.WhitelistedDevice && qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 && consumerServiceRequest.consumerProduct.gsxInfo.isCellular) {
							statuses = concat(statuses, 'Check whitelisting');
						}
						else {
							statuses = concat(statuses, 'Device received');
							if(consumerServiceRequest.originCenter){
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
					else {
						statuses = concat(statuses, 'Device received', 'Service cancel', 'Cancel Service Without Dispatch');
						if (accessFn('CANCEL_LOGISTICS') && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active) {
							statuses = concat(statuses, 'Cancel logistics request');
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Pickup Request Accepted',
		csrlStatus: 'Token generated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))) {
						statuses = concat(statuses,'Device Received');
					}
				}
				
				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')  ) {
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON')  && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Device shipped');
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}

			//comes from Exception
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}

			//claim 
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses =  merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
					}
				}
				if(accessFn('SHOW_DEVICE_SHIPPED_BUTTON')){
					if(consumerServiceRequest.showShippedButton){
						if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&  claimMergeStatus.isPickUp)) {
							if (accessFn('ADD_NORMAL_CHARGES')) {
								claimStatuses.push('Device shipped')
							}
						}
					}
				}
				statuses = concat(statuses,claimStatuses)
			}
			//--end claim
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (!consumerServiceRequest.consumerProduct.WhitelistedDevice && qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 && consumerServiceRequest.consumerProduct.gsxInfo.isCellular) {
							statuses = concat(statuses, 'Check whitelisting');
						}
						else {
							statuses = concat(statuses, 'Device received');
							if(consumerServiceRequest.originCenter){
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
					else {
						statuses = concat(statuses, 'Device received', 'Service cancel', 'Cancel Service Without Dispatch');
						if (accessFn('CANCEL_LOGISTICS') && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active) {
							statuses = concat(statuses, 'Cancel logistics request');
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Pickup Request Accepted',
		csrlStatus: 'Device shipped',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))) {
						statuses = concat(statuses,'Device Received');
					}
				}
				
				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')  ) {
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON')  && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Device shipped');
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}

			//comes from Exception
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}

			//claim 
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses =  merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
					}
				}
				statuses = concat(statuses,claimStatuses)
			}
			//--end claim
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Device received', 'Service cancel', 'Cancel Service Without Dispatch');
					if (accessFn('CANCEL_LOGISTICS') && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active) {
						statuses = concat(statuses, 'Cancel logistics request');
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Pickup Request Accepted',
		csrlStatus: 'Reschedule requested',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];	
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))) {
						statuses = concat(statuses,'Device Received');
					}
				}
				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')  ) {
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON')  && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Device shipped');
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}

			//comes from Exception
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//end claim new workshop
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses =  merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
					}
				}
				if(accessFn('SHOW_DEVICE_SHIPPED_BUTTON')){
					if(consumerServiceRequest.showShippedButton){
						if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&  claimMergeStatus.isPickUp)) {
							if (accessFn('ADD_NORMAL_CHARGES')) {
								claimStatuses.push('Device shipped')
							}
						}
					}
				}
				statuses = concat(statuses,claimStatuses)
			}
			//claim end
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Device received', 'Service cancel', 'Cancel Service Without Dispatch');
					if (accessFn('CANCEL_LOGISTICS') && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active) {
						statuses = concat(statuses, 'Cancel logistics request');
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Pickup Request Accepted',
		csrlStatus: 'Shipment created',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if((!consumerServiceRequest.showEngineerVisits) && (!(accessFn('WORKSHOP_FLOW'))) && showUpdateLogisticsRequestButton()){
					statuses = concat(statuses,'Update Logistics Request')
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses = concat(statuses,document);
			// 	})
			// })
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))) {
						statuses = concat(statuses,'Device Received');
					}
				}
				
				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')  ) {
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON')  && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Device shipped');
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			
			if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}

			//comes from Exception
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim 
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses =  merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
					}
				}
				if(accessFn('SHOW_DEVICE_SHIPPED_BUTTON')){
					if(consumerServiceRequest.showShippedButton){
						if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&  claimMergeStatus.isPickUp)) {
							if (accessFn('ADD_NORMAL_CHARGES')) {
								claimStatuses.push('Device shipped')
							}
						}
					}
				}
				statuses = concat(statuses,claimStatuses)
			}
			//--end claim
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (!consumerServiceRequest.consumerProduct.WhitelistedDevice && qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 && consumerServiceRequest.consumerProduct.gsxInfo.isCellular) {
							statuses = concat(statuses, 'Check whitelisting');
						}
						else {
							statuses = concat(statuses, 'Device received');
							if(consumerServiceRequest.originCenter){
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
					else {
						statuses = concat(statuses, 'Device received', 'Service cancel', 'Cancel Service Without Dispatch');
						if (accessFn('CANCEL_LOGISTICS') && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active) {
							statuses = concat(statuses, 'Cancel logistics request');
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Device received',
		csrlStatus: 'Device received',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP ,SERVICETYPE.DROPOFF ,SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Start Repair')
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Device inspected','Service cancel','Cancel Service Without Dispatch');
				}
			}
			// end claim new workshop
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found']);
					}
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'])
							}else{
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'])
							}
						}
					}

				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found']);
					}
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses,  ['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
							}else{
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
							}
						}
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = merge(claimStatuses,  ['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(insurancePlans.selectPlanBenefit){
							claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}else{
							claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found']);
					}

				}
				if(!accessFn('DEVICE_NOT_ELIGIBLE_FOR_REPAIR')){
					claimStatuses = pull(claimStatuses, 'Device not eligible for repair');
				}
				if(!accessFn('DEVICE_NOT_REPAIRABLE')){
					claimStatuses = pull(claimStatuses, 'Device not repairable');
				}
				if(!accessFn('NO_FAULT_FOUND')){
					claimStatuses = pull(claimStatuses, 'No fault found');
				}
				if(!accessFn('WAITING_ON_CUSTOMER_BUTTON')){
					claimStatuses = pull(claimStatuses, 'Waiting on customer');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end

			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected');
								if(!consumerServiceRequest.deviceTransferData || (consumerServiceRequest.deviceTransferData && !consumerServiceRequest.deviceTransferData.isTransferredRequest)) {
									statuses = concat(statuses, 'Service cancel');
								}
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
			
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}

							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses = concat(statuses,'Advance collected');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device inspected', 'Service cancel');
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
					}
				}
			}
			// Pickup carry in end 

			//refurbishment flow
			if(refurbishmentFlowCheck){
				statuses = concat(statuses,'Repair initiated')
			}
			//refurbishment flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Device received',
		csrlStatus: 'Claim estimation generated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP ,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Start Repair')
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Device inspected','Service cancel','Cancel Service Without Dispatch');
				}
			}
			// end claim new workshop
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found']);
					}
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'])
							}else{
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'])
							}
						}
					}

				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found']);
					}
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses,  ['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
							}else{
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
							}
						}
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = merge(claimStatuses,  ['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(insurancePlans.selectPlanBenefit){
							claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}else{
							claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found']);
					}

				}
				if(!accessFn('DEVICE_NOT_ELIGIBLE_FOR_REPAIR')){
					claimStatuses = pull(claimStatuses, 'Device not eligible for repair');
				}
				if(!accessFn('DEVICE_NOT_REPAIRABLE')){
					claimStatuses = pull(claimStatuses, 'Device not repairable');
				}
				if(!accessFn('NO_FAULT_FOUND')){
					claimStatuses = pull(claimStatuses, 'No fault found');
				}
				if(!accessFn('WAITING_ON_CUSTOMER_BUTTON')){
					claimStatuses = pull(claimStatuses, 'Waiting on customer');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			
			return statuses;
		}
    },
	{
        csrStatus: 'Device received',
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP ,SERVICETYPE.DROPOFF ,SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Start Repair')
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Device inspected','Service cancel','Cancel Service Without Dispatch');
				}
				if (accessFn('CLAIM_APPROVAL')) {
					statuses = concat(statuses, 'Claim rejected');
				}
				if(accessFn('WAITING_ON_CUSTOMER_BUTTON')){
					statuses = concat(statuses, 'Waiting on customer');
				}
			}
			// end claim new workshop
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found']);
					}
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'])
							}else{
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'])
							}
						}
					}

				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found']);
					}
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses,  ['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
							}else{
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
							}
						}
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = merge(claimStatuses,  ['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(insurancePlans.selectPlanBenefit){
							claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}else{
							claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found']);
					}

				}
				if(!accessFn('DEVICE_NOT_ELIGIBLE_FOR_REPAIR')){
					claimStatuses = pull(claimStatuses, 'Device not eligible for repair');
				}
				if(!accessFn('DEVICE_NOT_REPAIRABLE')){
					claimStatuses = pull(claimStatuses, 'Device not repairable');
				}
				if(!accessFn('NO_FAULT_FOUND')){
					claimStatuses = pull(claimStatuses, 'No fault found');
				}
				if(!accessFn('WAITING_ON_CUSTOMER_BUTTON')){
					claimStatuses = pull(claimStatuses, 'Waiting on customer');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected');
								if(!consumerServiceRequest.deviceTransferData || (consumerServiceRequest.deviceTransferData && !consumerServiceRequest.deviceTransferData.isTransferredRequest)) {
									statuses = concat(statuses, 'Service cancel');
								}
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
			
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses.push('Advance collected');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device inspected', 'Service cancel');
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Device received',
		csrlStatus: 'Received at repair center',
		computeNextStatus: function() {
			let statuses = [];
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected');
								if(!consumerServiceRequest.deviceTransferData || (consumerServiceRequest.deviceTransferData && !consumerServiceRequest.deviceTransferData.isTransferredRequest)) {
									statuses = concat(statuses, 'Service cancel');
								}
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
			
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses.push('Advance collected');
							}
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Device received',
		csrlStatus: 'Advance collected',
		computeNextStatus: function() {
			let statuses = [];
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected');
								if(!consumerServiceRequest.deviceTransferData || (consumerServiceRequest.deviceTransferData && !consumerServiceRequest.deviceTransferData.isTransferredRequest)) {
									statuses = concat(statuses, 'Service cancel');
								}
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
			
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses.push('Advance collected');
							}
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Device received',
		csrlStatus: 'Advance collected',
		computeNextStatus: function() {
			let statuses = [];
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected');
								if(!consumerServiceRequest.deviceTransferData || (consumerServiceRequest.deviceTransferData && !consumerServiceRequest.deviceTransferData.isTransferredRequest)) {
									statuses = concat(statuses, 'Service cancel');
								}
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
			
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses.push('Advance collected');
							}
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Device received',
		csrlStatus: 'Diagnosis initiated',
		computeNextStatus: function() {
			let statuses = [];
			let suitesStatuses =[];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
						if (accessFn('ACCEPT_RETURN_DEVICE') && consumerServiceRequest.AllowCCDiagnosis) {
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses,'Cannot be diagnosed', 'Diagnosis completed');
								}else{
									statuses = concat(statuses, 'Initiate AST 2 Diagnostics','Cannot be diagnosed', 'Diagnosis completed');
								}
								if(arrDiagnosisSuites && arrDiagnosisSuites.length){
									suitesStatuses = merge(suitesStatuses, arrDiagnosisSuites)
									statuses = concat(statuses,suitesStatuses)
								}else if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey && (!arrDiagnosisSuites || (arrDiagnosisSuites && !arrDiagnosisSuites.length)) && !diagnosticsSuitesCalled) {
									if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
										suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
										statuses = concat(statuses, suitesStatuses)
									}
								}
							}
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Device received',
		csrlStatus: 'Diagnosis completed',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
						if(accessFn('ACCEPT_RETURN_DEVICE')){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected', 'Service cancel');
							}
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
								if(deviceAtLoggedInLoc){
									if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
										statuses = concat(statuses, 'Initiate Diagnostics');
									}
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable  && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses = concat(statuses, 'Advance collected');
							}
			
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Device received',
		csrlStatus: 'Cannot be diagnosed',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
						if(accessFn('ACCEPT_RETURN_DEVICE')){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected', 'Service cancel');
							}
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
								if(deviceAtLoggedInLoc){
									if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
										statuses = concat(statuses, 'Initiate Diagnostics');
									}
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable  && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses = concat(statuses, 'Advance collected');
							}
			
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
		csrStatus: 'Device received',
		csrlStatus: 'Token released',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected');
								if(!consumerServiceRequest.deviceTransferData || (consumerServiceRequest.deviceTransferData && !consumerServiceRequest.deviceTransferData.isTransferredRequest)) {
									statuses = concat(statuses, 'Service cancel');
								}
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
			
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses.push('Advance collected');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device inspected', 'Service cancel');
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
	},
	{
		csrStatus: 'Device received',
		csrlStatus: 'Token generated',
		computeNextStatus: function() {
		let statuses = [];
		// Pickup carry in 
		if(pickupCarryInChecks){
			if (accessFn('ACCEPT_RETURN_DEVICE')) {
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Device inspected');
							if(!consumerServiceRequest.deviceTransferData || (consumerServiceRequest.deviceTransferData && !consumerServiceRequest.deviceTransferData.isTransferredRequest)) {
								statuses = concat(statuses, 'Service cancel');
							}
						}
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
		
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics');
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses.push('Advance collected');
						}
					}
				}
				else {
					statuses =concat(statuses, 'Device inspected', 'Service cancel');
					if(accessFn('NON_HARDWARE_CLOSURE')){
						statuses = concat(statuses, 'Non Hardware Closure');
					}
				}
			}
		}
		// Pickup carry in end 
		return statuses;
		}
	},
	{
        csrStatus: 'Device received',
		csrlStatus: 'Response received',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP ,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Start Repair')
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Device inspected','Service cancel','Cancel Service Without Dispatch');
				}
				if (accessFn('CLAIM_APPROVAL')) {
					statuses = concat(statuses, 'Claim rejected');
				}
				if(accessFn('WAITING_ON_CUSTOMER_BUTTON')){
					statuses = concat(statuses, 'Waiting on customer');
				}
			}
			// end claim new workshop
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('CLAIM_APPROVAL')) {
						if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found']);
						}
					}
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
						if (accessFn('CLAIM_APPROVAL')) {
							claimStatuses = merge(claimStatuses,['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
						if(accessFn('SHOW_PLAN_BENEFITS')){
							claimStatuses = merge(claimStatuses,['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1 ){
							claimStatuses = merge(claimStatuses,['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found']);
						}
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if([SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
						if (accessFn('CLAIM_APPROVAL')) {
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
						if (accessFn('ADD_NORMAL_CHARGES')) {
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
					}
					
					if (accessFn('ADD_NORMAL_CHARGES')) {
						if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found']);
						}
					}
				}
				if(accessFn('SHOW_PLAN_BENEFITS')){
					if(insurancePlans && insurancePlans.selectPlanBenefit){
						if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
							claimStatuses = ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'];
						}
						if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
							claimStatuses = ['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'];
						}
					}else{
						if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
							claimStatuses = ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'];
						}
						if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
							claimStatuses = ['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'];
						}
					}
				}
				if(!accessFn('DEVICE_NOT_ELIGIBLE_FOR_REPAIR')){
					claimStatuses = pull(claimStatuses,'Device not eligible for repair')
				}
				if(!accessFn('DEVICE_NOT_REPAIRABLE')){
					claimStatuses = pull(claimStatuses,'Device not repairable')
				}
				if(!accessFn('NO_FAULT_FOUND')){
					claimStatuses = pull(claimStatuses,'No fault found')
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected');
								if(!consumerServiceRequest.deviceTransferData || (consumerServiceRequest.deviceTransferData && !consumerServiceRequest.deviceTransferData.isTransferredRequest)) {
									statuses = concat(statuses, 'Service cancel');
								}
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
			
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses.push('Advance collected');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device inspected', 'Service cancel');
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Device received',
		csrlStatus: 'Reason received',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP ,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Start Repair')
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('CLAIM_APPROVAL')) {
						if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found']);
						}
					}
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
						if (accessFn('CLAIM_APPROVAL')) {
							claimStatuses = merge(claimStatuses,['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
						if(accessFn('SHOW_PLAN_BENEFITS')){
							claimStatuses = merge(claimStatuses,['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1 ){
							claimStatuses = merge(claimStatuses,['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found']);
						}
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if([SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
						if (accessFn('CLAIM_APPROVAL')) {
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
						if (accessFn('ADD_NORMAL_CHARGES')) {
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
					}
					
					if (accessFn('ADD_NORMAL_CHARGES')) {
						if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found']);
						}
					}
				}
				if(accessFn('SHOW_PLAN_BENEFITS')){
					if(insurancePlans && insurancePlans.selectPlanBenefit){
						if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
							claimStatuses = ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'];
						}
						if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
							claimStatuses = ['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'];
						}
					}else{
						if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
							claimStatuses = ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'];
						}
						if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
							claimStatuses = ['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'];
						}
					}
				}
				if(!accessFn('DEVICE_NOT_ELIGIBLE_FOR_REPAIR')){
					claimStatuses = pull(claimStatuses,'Device not eligible for repair')
				}
				if(!accessFn('DEVICE_NOT_REPAIRABLE')){
					claimStatuses = pull(claimStatuses,'Device not repairable')
				}
				if(!accessFn('NO_FAULT_FOUND')){
					claimStatuses = pull(claimStatuses,'No fault found')
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected');
								if(!consumerServiceRequest.deviceTransferData || (consumerServiceRequest.deviceTransferData && !consumerServiceRequest.deviceTransferData.isTransferredRequest)) {
									statuses = concat(statuses, 'Service cancel');
								}
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
			
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses.push('Advance collected');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device inspected', 'Service cancel');
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Device received',
		csrlStatus: 'Service cancel',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP ,SERVICETYPE.DROPOFF ,SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Start Repair')
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Device inspected','Service cancel','Cancel Service Without Dispatch');
				}
			}
			// end claim new workshop
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found']);
					}
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'])
							}else{
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'])
							}
						}
					}

				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found']);
					}
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses,  ['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
							}else{
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
							}
						}
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = merge(claimStatuses,  ['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(insurancePlans.selectPlanBenefit){
							claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}else{
							claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found']);
					}

				}
				if(!accessFn('DEVICE_NOT_ELIGIBLE_FOR_REPAIR')){
					claimStatuses = pull(claimStatuses, 'Device not eligible for repair');
				}
				if(!accessFn('DEVICE_NOT_REPAIRABLE')){
					claimStatuses = pull(claimStatuses, 'Device not repairable');
				}
				if(!accessFn('NO_FAULT_FOUND')){
					claimStatuses = pull(claimStatuses, 'No fault found');
				}
				if(!accessFn('WAITING_ON_CUSTOMER_BUTTON')){
					claimStatuses = pull(claimStatuses, 'Waiting on customer');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end

			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected');
								if(!consumerServiceRequest.deviceTransferData || (consumerServiceRequest.deviceTransferData && !consumerServiceRequest.deviceTransferData.isTransferredRequest)) {
									statuses = concat(statuses, 'Service cancel');
								}
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
			
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}

							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses = concat(statuses,'Advance collected');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device inspected', 'Service cancel');
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Service accepted',
		csrlStatus: 'Service accepted',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
						statuses = concat(statuses,'Device Received');
					}
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if( accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY') ) {
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_APPROVAL')) {
						statuses = concat(statuses,'Log Call');
					}	
					// if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
					// 	statuses = concat(statuses,'Reassign');
					// }
				}
				if([SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
			}
			if(consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('UPDATE_PSL_OF_REQUEST') && ([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				statuses = concat(statuses,'Reassign Service Center');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//---
		
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			if( accessFn('REQUEST_APPROVAL') ){
				if([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
					statuses = concat(statuses,'Initiate Service');
				}
			}
			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
								statuses = concat(statuses, 'Service reschedule');
						}
						if(taskListArr.length !==0){
							if (showCancelButtonFuncton()) {
								statuses = concat(statuses, 'Service cancel');
							}
						} else {
							if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 || (qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1)){
								if (accessFn('CANCEL_REQUEST_ANYTIME')) {
									statuses = concat(statuses, 'Service cancel');
								} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
									statuses = concat(statuses, 'Service cancel initiated');
								}
							}
						}

						if (accessFn('TECHNICIAN_SUPERVISOR') && !(['CprDashboard','BoseDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1) && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)) {
							statuses = concat(statuses, 'Job updated');
						}
						if(consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_TREAD') > -1){
							statuses = concat(statuses, 'Service completed');
						}
					}
					if (isBoseFlow) {
						statuses = concat(statuses, 'Visit unproductive');
					}
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
							// statuses = concat(statuses, 'Initiate journey');
						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						if(buttonLogStatus.estimationApproved) {
							statuses = concat(statuses, 'Visit productive');
							statuses = concat(statuses, 'Visit unproductive');
						}
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
						// check for date of purchase and cost is entered or not.
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//---end claim new
			//claim
			if(claimChecks){
				if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
					if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) < 0) {
						// statuses.assign('Assign')
						claimStatuses = ['Assign']
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (!consumerServiceRequest.consumerProduct.WhitelistedDevice && qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 && consumerServiceRequest.consumerProduct.gsxInfo.isCellular) {
							statuses = concat(statuses, 'Check whitelisting');
						}
						else {
							statuses = concat(statuses, 'Device received');
							if(consumerServiceRequest.originCenter){
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device received', 'Service cancel');
					}
				}
			}
			// Pickup carry in end
			//change service center for waalk in
			if(accessFn('REASSIGN_ASC') && [SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
				statuses = concat(statuses,'Change Service Center')
			}
			
			return statuses;
		}
    },
	{
        csrStatus: 'Service accepted',
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
						statuses = concat(statuses,'Device Received');
					}
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if( accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY') ) {
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_APPROVAL')) {
						statuses = concat(statuses,'Log Call');
					}	
					// if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
					// 	statuses = concat(statuses,'Reassign');
					// }
				}
				if([SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
			}
			if(consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('UPDATE_PSL_OF_REQUEST') && ([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				statuses = concat(statuses,'Reassign Service Center');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//---
		
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
								statuses = concat(statuses, 'Service reschedule');
						}
						if(taskListArr.length !==0){
							if (showCancelButtonFuncton()) {
								statuses = concat(statuses, 'Service cancel');
							}
						} else {
							if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 || (qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1)){
								if (accessFn('CANCEL_REQUEST_ANYTIME')) {
									statuses = concat(statuses, 'Service cancel');
								} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
									statuses = concat(statuses, 'Service cancel initiated');
								}
							}
						}

						if (accessFn('TECHNICIAN_SUPERVISOR') && !(['CprDashboard','BoseDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1) && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)) {
							statuses = concat(statuses, 'Job updated');
						}
						if(consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_TREAD') > -1){
							statuses = concat(statuses, 'Service completed');
						}
					}
					if (isBoseFlow) {
						statuses = concat(statuses, 'Visit unproductive');
					}
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
							// statuses = concat(statuses, 'Initiate journey');
						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						if(buttonLogStatus.estimationApproved) {
							statuses = concat(statuses, 'Visit productive');
							statuses = concat(statuses, 'Visit unproductive');
						}
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
						// check for date of purchase and cost is entered or not.
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//---end claim new
			//claim
			if(claimChecks){
				if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
					if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) < 0) {
						claimStatuses = ['Assign']
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (!consumerServiceRequest.consumerProduct.WhitelistedDevice && qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 && consumerServiceRequest.consumerProduct.gsxInfo.isCellular) {
							statuses = concat(statuses, 'Check whitelisting');
						}
						else {
							statuses = concat(statuses, 'Device received');
							if(consumerServiceRequest.originCenter){
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device received', 'Service cancel');
					}
				}
			}
			// Pickup carry in 
			//change service center for waalk in
			if(accessFn('REASSIGN_ASC') && [SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
				statuses = concat(statuses,'Change Service Center')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Service accepted',
		csrlStatus: 'Intimate customer',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
				if (accessFn('REQUEST_APPROVAL')) {
					statuses = concat(statuses,'Log Call');
				}
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					// if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') ){
					// 	statuses=concat(statuses,'Mark Customer Arrival');
					// }
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
						statuses = concat(statuses,'Device Received');
					}
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if( accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY') ) {
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_APPROVAL')) {
						statuses = concat(statuses,'Log Call');
					}	
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
				if([SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
				
			}
			if(consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('UPDATE_PSL_OF_REQUEST') && ([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				statuses = concat(statuses,'Reassign Service Center');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//---
		
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			
			//claim
			if(claimChecks){
				if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
					if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) < 0) {
						claimStatuses = ['Assign']
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end

			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
								statuses = concat(statuses, 'Service reschedule');
						}
						if(taskListArr.length !==0){
							if (showCancelButtonFuncton()) {
								statuses = concat(statuses, 'Service cancel');
							}
						} else {
							if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 || (qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1)){
								if (accessFn('CANCEL_REQUEST_ANYTIME')) {
									statuses = concat(statuses, 'Service cancel');
								} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
									statuses = concat(statuses, 'Service cancel initiated');
								}
							}
						}

						if (accessFn('TECHNICIAN_SUPERVISOR') && !(['CprDashboard','BoseDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1) && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)) {
							statuses = concat(statuses, 'Job updated');
						}
						if(consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_TREAD') > -1){
							statuses = concat(statuses, 'Service completed');
						}
					}
					if (isBoseFlow) {
						statuses = concat(statuses, 'Visit unproductive');
					}
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
							// statuses = concat(statuses, 'Initiate journey');
						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			//onsite end
			//change service center for waalk in
			if(accessFn('REASSIGN_ASC') && [SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
				statuses = concat(statuses,'Change Service Center')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Service accepted',
		csrlStatus: 'Device shipped',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
				if (accessFn('REQUEST_APPROVAL')) {
					statuses = concat(statuses,'Log Call');
				}
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					// if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') ){
					// 	statuses=concat(statuses,'Mark Customer Arrival');
					// }
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
						statuses = concat(statuses,'Device Received');
					}
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if( accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY') ) {
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_APPROVAL')) {
						statuses = concat(statuses,'Log Call');
					}	
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
				if([SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
				
			}
			if(consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('UPDATE_PSL_OF_REQUEST') && ([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				statuses = concat(statuses,'Reassign Service Center');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//---
		
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
								statuses = concat(statuses, 'Service reschedule');
						}
						if(taskListArr.length !==0){
							if (showCancelButtonFuncton()) {
								statuses = concat(statuses, 'Service cancel');
							}
						} else {
							if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 || (qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1)){
								if (accessFn('CANCEL_REQUEST_ANYTIME')) {
									statuses = concat(statuses, 'Service cancel');
								} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
									statuses = concat(statuses, 'Service cancel initiated');
								}
							}
						}

						if (accessFn('TECHNICIAN_SUPERVISOR') && !(['CprDashboard','BoseDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1) && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)) {
							statuses = concat(statuses, 'Job updated');
						}
						if(consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_TREAD') > -1){
							statuses = concat(statuses, 'Service completed');
						}
					}
					if (isBoseFlow) {
						statuses = concat(statuses, 'Visit unproductive');
					}
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
							// statuses = concat(statuses, 'Initiate journey');
						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
						// check for date of purchase and cost is entered or not.
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//---end claim new

			//claim
			if(claimChecks){
				if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
					if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) < 0) {
						claimStatuses = ['Assign']
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses =concat(statuses, 'Device received', 'Service cancel');
				}
			}
			// Pickup carry in end
			//change service center for waalk in
			if(accessFn('REASSIGN_ASC') && [SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
				statuses = concat(statuses,'Change Service Center')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Service accepted',
		csrlStatus: 'Token generated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
				if (accessFn('REQUEST_APPROVAL')) {
					statuses = concat(statuses,'Log Call');
				}
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					// if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') ){
					// 	statuses=concat(statuses,'Mark Customer Arrival');
					// }
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
						statuses = concat(statuses,'Device Received');
					}
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if( accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY') ) {
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_APPROVAL')) {
						statuses = concat(statuses,'Log Call');
					}	
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
				if([SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
				
			}
			if(consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('UPDATE_PSL_OF_REQUEST') && ([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				statuses = concat(statuses,'Reassign Service Center');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//---
		
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			
			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
								statuses = concat(statuses, 'Service reschedule');
						}
						if(taskListArr.length !==0){
							if (showCancelButtonFuncton()) {
								statuses = concat(statuses, 'Service cancel');
							}
						} else {
							if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 || (qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1)){
								if (accessFn('CANCEL_REQUEST_ANYTIME')) {
									statuses = concat(statuses, 'Service cancel');
								} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
									statuses = concat(statuses, 'Service cancel initiated');
								}
							}
						}

						if (accessFn('TECHNICIAN_SUPERVISOR') && !(['CprDashboard','BoseDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1) && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)) {
							statuses = concat(statuses, 'Job updated');
						}
						if(consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_TREAD') > -1){
							statuses = concat(statuses, 'Service completed');
						}
					}
					if (isBoseFlow) {
						statuses = concat(statuses, 'Visit unproductive');
					}
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
							// statuses = concat(statuses, 'Initiate journey');
						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
						// check for date of purchase and cost is entered or not.
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//---end claim new
			//claim
			if(claimChecks){
				if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
					if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) < 0) {
						claimStatuses = ['Assign']
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			// Pick up carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (!consumerServiceRequest.consumerProduct.WhitelistedDevice && qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 && consumerServiceRequest.consumerProduct.gsxInfo.isCellular) {
							statuses = concat(statuses, 'Check whitelisting');
						}
						else {
							statuses = concat(statuses, 'Device received');
							if(consumerServiceRequest.originCenter){
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device received', 'Service cancel');
					}
				}
			}
			// Pickup carry in 
			//change service center for waalk in
			if(accessFn('REASSIGN_ASC') && [SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
				statuses = concat(statuses,'Change Service Center')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Service accepted',
		csrlStatus: 'Token released',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
				if (accessFn('REQUEST_APPROVAL')) {
					statuses = concat(statuses,'Log Call');
				}
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					// if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') ){
					// 	statuses=concat(statuses,'Mark Customer Arrival');
					// }
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
						statuses = concat(statuses,'Device Received');
					}
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if( accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY') ) {
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_APPROVAL')) {
						statuses = concat(statuses,'Log Call');
					}	
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
				if([SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
				
			}
			if(consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('UPDATE_PSL_OF_REQUEST') && ([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				statuses = concat(statuses,'Reassign Service Center');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//---
		
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
		
			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
								statuses = concat(statuses, 'Service reschedule');
						}
						if(taskListArr.length !==0){
							if (showCancelButtonFuncton()) {
								statuses = concat(statuses, 'Service cancel');
							}
						} else {
							if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 || (qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1)){
								if (accessFn('CANCEL_REQUEST_ANYTIME')) {
									statuses = concat(statuses, 'Service cancel');
								} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
									statuses = concat(statuses, 'Service cancel initiated');
								}
							}
						}

						if (accessFn('TECHNICIAN_SUPERVISOR') && !(['CprDashboard','BoseDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1) && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)) {
							statuses = concat(statuses, 'Job updated');
						}
						if(consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_TREAD') > -1){
							statuses = concat(statuses, 'Service completed');
						}
					}
					if (isBoseFlow) {
						statuses = concat(statuses, 'Visit unproductive');
					}
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
							// statuses = concat(statuses, 'Initiate journey');
						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
						// check for date of purchase and cost is entered or not.
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//---end claim new

			//claim
			if(claimChecks){
				if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
					if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) < 0) {
						claimStatuses = ['Assign']
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			// Pick up carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (!consumerServiceRequest.consumerProduct.WhitelistedDevice && qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 && consumerServiceRequest.consumerProduct.gsxInfo.isCellular) {
							statuses = concat(statuses, 'Check whitelisting');
						}
						else {
							statuses = concat(statuses, 'Device received');
							if(consumerServiceRequest.originCenter){
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device received', 'Service cancel');
					}
				}
			}
			// Pickup carry in 
			//change service center for waalk in
			if(accessFn('REASSIGN_ASC') && accessFn('REASSIGN_ASC') && accessFn('REASSIGN_ASC') && [SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
				statuses = concat(statuses,'Change Service Center')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Service accepted',
		csrlStatus: 'Reschedule requested',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
				if (accessFn('REQUEST_APPROVAL')) {
					statuses = concat(statuses,'Log Call');
				}
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					// if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') ){
					// 	statuses=concat(statuses,'Mark Customer Arrival');
					// }
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
						statuses = concat(statuses,'Device Received');
					}
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if( accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY') ) {
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_APPROVAL')) {
						statuses = concat(statuses,'Log Call');
					}	
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
				if([SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
			}
			if(consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('UPDATE_PSL_OF_REQUEST') && ([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				statuses = concat(statuses,'Reassign Service Center');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//---
		
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
								statuses = concat(statuses, 'Service reschedule');
						}
						if(taskListArr.length !==0){
							if (showCancelButtonFuncton()) {
								statuses = concat(statuses, 'Service cancel');
							}
						} else {
							if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 || (qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1)){
								if (accessFn('CANCEL_REQUEST_ANYTIME')) {
									statuses = concat(statuses, 'Service cancel');
								} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
									statuses = concat(statuses, 'Service cancel initiated');
								}
							}
						}

						if (accessFn('TECHNICIAN_SUPERVISOR') && !(['CprDashboard','BoseDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1) && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)) {
							statuses = concat(statuses, 'Job updated');
						}
						if(consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_TREAD') > -1){
							statuses = concat(statuses, 'Service completed');
						}
					}
					if (isBoseFlow) {
						statuses = concat(statuses, 'Visit unproductive');
					}
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
							// statuses = concat(statuses, 'Initiate journey');
						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
						// check for date of purchase and cost is entered or not.
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//---end claim new

			//claim
			if(claimChecks){
				if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
					if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) < 0) {
						claimStatuses = ['Assign']
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses =concat(statuses, 'Device received', 'Service cancel');
				}
			}
			// Pickup carry in end
			//change service center for waalk in
			if(accessFn('REASSIGN_ASC') && [SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
				statuses = concat(statuses,'Change Service Center')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Service accepted',
		csrlStatus: 'Sent to repair center',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
				if (accessFn('REQUEST_APPROVAL')) {
					statuses = concat(statuses,'Log Call');
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (!consumerServiceRequest.consumerProduct.WhitelistedDevice && qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 && consumerServiceRequest.consumerProduct.gsxInfo.isCellular) {
							statuses = concat(statuses, 'Check whitelisting');
						}
						else {
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) > -1) {
								statuses = concat(statuses, 'Receive device from CP');
							}
							if(consumerServiceRequest.originCenter){
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
				}
			}
			// Pickup carry in end
			//change service center for waalk in
			if(accessFn('REASSIGN_ASC') && [SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
				statuses = concat(statuses,'Change Service Center')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Service accepted',
		csrlStatus: 'Service centre updated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
						statuses = concat(statuses,'Device Received');
					}
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if( accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY') ) {
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_APPROVAL')) {
						statuses = concat(statuses,'Log Call');
					}	
					// if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
					// 	statuses = concat(statuses,'Reassign');
					// }
				}
				if([SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
			}
			if(consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('UPDATE_PSL_OF_REQUEST') && ([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				statuses = concat(statuses,'Reassign Service Center');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//---
		
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			if( accessFn('REQUEST_APPROVAL') ){
				if([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
					statuses = concat(statuses,'Initiate Service');
				}
			}
			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
								statuses = concat(statuses, 'Service reschedule');
						}
						if(taskListArr.length !==0){
							if (showCancelButtonFuncton()) {
								statuses = concat(statuses, 'Service cancel');
							}
						} else {
							if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 || (qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1)){
								if (accessFn('CANCEL_REQUEST_ANYTIME')) {
									statuses = concat(statuses, 'Service cancel');
								} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
									statuses = concat(statuses, 'Service cancel initiated');
								}
							}
						}

						if (accessFn('TECHNICIAN_SUPERVISOR') && !(['CprDashboard','BoseDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1) && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)) {
							statuses = concat(statuses, 'Job updated');
						}
						if(consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_TREAD') > -1){
							statuses = concat(statuses, 'Service completed');
						}
					}
					if (isBoseFlow) {
						statuses = concat(statuses, 'Visit unproductive');
					}
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
							// statuses = concat(statuses, 'Initiate journey');
						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						if(buttonLogStatus.estimationApproved) {
							statuses = concat(statuses, 'Visit productive');
							statuses = concat(statuses, 'Visit unproductive');
						}
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
						// check for date of purchase and cost is entered or not.
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//---end claim new
			//claim
			if(claimChecks){
				if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
					if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) < 0) {
						claimStatuses = ['Assign']
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (!consumerServiceRequest.consumerProduct.WhitelistedDevice && qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 && consumerServiceRequest.consumerProduct.gsxInfo.isCellular) {
							statuses = concat(statuses, 'Check whitelisting');
						}
						else {
							statuses = concat(statuses, 'Device received');
							if(consumerServiceRequest.originCenter){
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device received', 'Service cancel');
					}
				}
			}
			// Pickup carry in end 
			//change service center for waalk in
			if(accessFn('REASSIGN_ASC') && [SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
				statuses = concat(statuses,'Change Service Center')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Repair completed',
		csrlStatus: 'Repair completed',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			// if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
            //     if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
            //         statuses = concat(statuses,'Dispatch Device')
            //     }
            // }
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck() &&  !accessFn('HIDE_SERVICE_COMPLETE')){
						statuses = concat(statuses,'Complete Service');
					}
				}
				if(([SERVICETYPE.PICK_UP , SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
						if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
							statuses = concat(statuses,'Dispatch Device')
						}
					
					if(!(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled)){
						if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') ){
							statuses = concat(statuses,'Initiate logistics request','Add Shipment Details');
							// if(warrantyAppicableNonClaimCheck()){
							// 	statuses = concat(statuses,'Complete Service');
							// }
						}
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start
			if(onsiteChecks){
				if(showButton){
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Service completed');
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && consumerServiceRequest.IsQcPresent) {
						statuses.push('QC pending');
					}
				}

				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && !consumerServiceRequest.IsQcPresent) {
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) {
							statuses.push('Intimate customer');
						} else if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
							if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}
					}
				}
			}
			//---end claim new

			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID)) > -1 && claimMergeStatus.isPickUp){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = concat(claimStatuses, 'Device dispatched');
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = concat(claimStatuses, 'Device dispatched');
						// }
					}
				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if(hideIssueLoanerButton){
						claimStatuses = [];
					}
					if(!hideIssueLoanerButton){
						claimStatuses = ['Service completed'];
					}
				}
				if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Device dispatched'];
					}
				}
				else {
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				if(claimobj  && claimobj.consumerServiceRequest && claimobj.consumerServiceRequest.autoClaimFulfillmentFlow) {
					claimStatuses =[];
				}

				if ([SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
						claimStatuses = ['Device dispatched'];
					}else{
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1 && [SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
					if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
						claimStatuses = ['Device dispatched'];
					}
					else {
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end 
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if(isSupOrEngg()){
						if (showRequestLogs.isRepairCompleted && consumerServiceRequest.IsQcPresent) {
							statuses = concat(statuses, 'QC pending');
						}
					}
					if (accessFn('STORE_MANAGEMENT') || accessFn('ACCEPT_RETURN_DEVICE')){
						if(accessFn('TRANSFER_REQUEST')){
							if(qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) > -1 && !consumerServiceRequest.originCenter){
								statuses = concat(statuses, 'Transfer request');
							}
						}
						// if(!showRequestValid.allowCCToProceedWithService){
						// 	statuses = concat(statuses, 'Transfer request');
						// }
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (showRequestValid.allowCCToProceedWithService) {
							if(showRequestLogs.isRepairCompleted && !consumerServiceRequest.IsQcPresent){
								let isDeviceReceivedAtDropOffLoc = find(consumerServiceRequest.logs, {Status: 'Device received at drop-off location'});
								if(!appleDropOffFlow || (accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE') && (isDeviceReceivedAtDropOffLoc || (consumerServiceRequest?.gsxRepairFlags?.repairTypeId && (consumerServiceRequest.gsxRepairFlags.repairTypeId == 3)) || showRequestLogs.isHardwareClosure))) {
									if(!consumerServiceRequest.IsWarrantyApplicable){
										if(consumerServiceRequest.showGenerateInvoiceButton) {
											statuses = concat(statuses, 'Service invoice generated');
										}else if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
											statuses = concat(statuses, 'Payment received');
										}
									} else {
										if((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN)){
											// statuses = concat(statuses, 'Intimate customer');
											if(!appleDropOffFlow) {
												statuses = concat(statuses, 'Intimate customer');
											}else {
												statuses = concat(statuses, 'Service completed');
											}
										}else if(consumerServiceRequest.ServiceTypeID == 1){
											if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
												statuses = concat(statuses, 'Device dispatched');
											} else {
												statuses = concat(statuses, 'Initiate logistics request');
											}
										}
									}
								}
							}
						}
						if(appleDropOffFlow && !accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE') && consumerServiceRequest.ProvisionalGSXNumber) {
							statuses = concat(statuses, 'Initiate shipment to drop-off location');
						}
					}
				}else{
					if(isSupOrEngg()){
						if (showRequestLogs.isRepairCompleted && consumerServiceRequest.IsQcPresent && !exception) {
							statuses = concat(statuses, 'QC pending');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(showRequestLogs.isRepairCompleted && !consumerServiceRequest.IsQcPresent) {
							if(!consumerServiceRequest.IsWarrantyApplicable){
								if(consumerServiceRequest.showGenerateInvoiceButton){
									statuses = concat(statuses, 'Service invoice generated');
								}else if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
									statuses = concat(statuses, 'Payment received');
									if(accessFn('SEND_PAYMENT_LINK')){
										statuses = concat(statuses, 'Send payment link');
									}
								}
							}else{
								if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
									statuses = concat(statuses, 'Intimate customer');
								}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
									if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
										statuses = concat(statuses, 'Device dispatched');
									} else {
										statuses = concat(statuses, 'Initiate logistics request');
									}
								}
							}
						}
						if(exception) {
							statuses = concat(statuses, 'Intimate customer');
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end

			//refurbishment flow 
			if(refurbishmentFlowCheck){
				statuses = concat(statuses,'Service completed','Add Shipment Details');//'refurbishment shipment created','Device delivered'
			}
			//refurbishment flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Service accepted',
		csrlStatus: 'Service centre updated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
						statuses = concat(statuses,'Device Received');
					}
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if( accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY') ) {
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if (accessFn('REQUEST_APPROVAL')) {
						statuses = concat(statuses,'Log Call');
					}	
					// if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
					// 	statuses = concat(statuses,'Reassign');
					// }
				}
				if([SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
			}
			if(consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('UPDATE_PSL_OF_REQUEST') && ([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				statuses = concat(statuses,'Reassign Service Center');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//---
		
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			if( accessFn('REQUEST_APPROVAL') ){
				if([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
					statuses = concat(statuses,'Initiate Service');
				}
			}
			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
								statuses = concat(statuses, 'Service reschedule');
						}
						if(taskListArr.length !==0){
							if (showCancelButtonFuncton()) {
								statuses = concat(statuses, 'Service cancel');
							}
						} else {
							if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 || (qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1)){
								if (accessFn('CANCEL_REQUEST_ANYTIME')) {
									statuses = concat(statuses, 'Service cancel');
								} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
									statuses = concat(statuses, 'Service cancel initiated');
								}
							}
						}

						if (accessFn('TECHNICIAN_SUPERVISOR') && !(['CprDashboard','BoseDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1) && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)) {
							statuses = concat(statuses, 'Job updated');
						}
						if(consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_TREAD') > -1){
							statuses = concat(statuses, 'Service completed');
						}
					}
					if (isBoseFlow) {
						statuses = concat(statuses, 'Visit unproductive');
					}
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
							// statuses = concat(statuses, 'Initiate journey');
						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						if(buttonLogStatus.estimationApproved) {
							statuses = concat(statuses, 'Visit productive');
							statuses = concat(statuses, 'Visit unproductive');
						}
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
						// check for date of purchase and cost is entered or not.
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//---end claim new
			//claim
			if(claimChecks){
				if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
					if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) < 0) {
						statuses.assign('Assign')
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel']);
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (!consumerServiceRequest.consumerProduct.WhitelistedDevice && qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 && consumerServiceRequest.consumerProduct.gsxInfo.isCellular) {
							statuses = concat(statuses, 'Check whitelisting');
						}
						else {
							statuses = concat(statuses, 'Device received');
							if(consumerServiceRequest.originCenter){
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device received', 'Service cancel');
					}
				}
			}
			// Pickup carry in end
			//change service center for waalk in
			if(accessFn('REASSIGN_ASC') && [SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
				statuses = concat(statuses,'Change Service Center')
			}
			
			return statuses;
		}
    },
	{
        csrStatus: 'Repair completed',
		csrlStatus: 'Service completed',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Complete Service');
					}
				}
				if(([SERVICETYPE.PICK_UP , SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
					if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Dispatch Device')
					}
					
					if(!(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled)){
						if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') ){
							statuses = concat(statuses,'Initiate logistics request','Add Shipment Details');
							if(warrantyAppicableNonClaimCheck()){
								statuses = concat(statuses,'Complete Service');
							}
						}
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}
	
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start
			if(onsiteChecks){
				if(showButton){
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Service completed');
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && consumerServiceRequest.IsQcPresent) {
						statuses.push('QC pending');
					}
				}

				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && !consumerServiceRequest.IsQcPresent) {
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) {
							statuses.push('Intimate customer');
						} else if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
							if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}
					}
				}
			}
			//---end claim new

			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID)) > -1 && claimMergeStatus.isPickUp){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = concat(claimStatuses, 'Device dispatched');
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = concat(claimStatuses, 'Device dispatched');
						// }
					}
				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if(hideIssueLoanerButton){
						claimStatuses = [];
					}
					if(!hideIssueLoanerButton){
						claimStatuses = ['Service completed'];
					}
				}
				if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Device dispatched'];
					}
				}
				else {
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				if(claimobj  && claimobj.consumerServiceRequest && claimobj.consumerServiceRequest.autoClaimFulfillmentFlow) {
					claimStatuses =[];
				}

				if ([SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
						claimStatuses = ['Device dispatched'];
					}else{
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1 && [SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
					if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
						claimStatuses = ['Device dispatched'];
					}
					else {
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end 
			
			return statuses;
		}
    },
	{
        csrStatus: 'Repair completed',
		csrlStatus: 'Engineer reassigned',
		computeNextStatus: function() {
			let statuses = [];
			//claim new
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && consumerServiceRequest.IsQcPresent) {
						statuses.push('QC pending');
					}
				}

				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && !consumerServiceRequest.IsQcPresent) {
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) {
							statuses.push('Intimate customer');
						} else if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
							if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request','Add Shipment Details');
						}
					}
					}
				}	
			}
			//---end claim new
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if(isSupOrEngg()){
						if (showRequestLogs.isRepairCompleted && consumerServiceRequest.IsQcPresent) {
							statuses = concat(statuses, 'QC pending');
						}
					}
					if (accessFn('STORE_MANAGEMENT') || accessFn('ACCEPT_RETURN_DEVICE')){
						if(!showRequestValid.allowCCToProceedWithService){
							statuses = concat(statuses, 'Transfer request');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (showRequestValid.allowCCToProceedWithService) {
							if(showRequestLogs.isRepairCompleted && !consumerServiceRequest.IsQcPresent){
								if(!consumerServiceRequest.IsWarrantyApplicable){
									if(consumerServiceRequest.showGenerateInvoiceButton) {
										statuses = concat(statuses, 'Service invoice generated');
									}else if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
										statuses = concat(statuses, 'Payment received');
									}
								} else {
									if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN){
										statuses = concat(statuses, 'Intimate customer');
									}else if(consumerServiceRequest.ServiceTypeID == 1){
										if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
											statuses = concat(statuses, 'Device dispatched');
										} else {
											statuses = concat(statuses, 'Initiate logistics request');
										}
									}
								}
							}
						}
					}
				}else{
					if(isSupOrEngg()){
						if (showRequestLogs.isRepairCompleted && consumerServiceRequest.IsQcPresent && !exception) {
							statuses = concat(statuses, 'QC pending');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(showRequestLogs.isRepairCompleted && !consumerServiceRequest.IsQcPresent) {
							if(!consumerServiceRequest.IsWarrantyApplicable){
								if(consumerServiceRequest.showGenerateInvoiceButton){
									statuses = concat(statuses, 'Service invoice generated');
								}else if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
									statuses = concat(statuses, 'Payment received');
									if(accessFn('SEND_PAYMENT_LINK')){
										statuses = concat(statuses, 'Send payment link');
									}
								}
							}else{
								if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
									statuses = concat(statuses, 'Intimate customer');
								}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
									if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
										statuses = concat(statuses, 'Device dispatched');
									} else {
										statuses = concat(statuses, 'Initiate logistics request');
									}
								}
							}
						}
						if(exception) {
							statuses = concat(statuses, 'Intimate customer');
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },{
		csrStatus: 'Repair completed',
		csrlStatus: 'Payment received',
		computeNextStatus: function() {
			let statuses = [];
			//claim new 
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && consumerServiceRequest.IsQcPresent) {
					statuses.push('QC pending');
					}
				}

				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && !consumerServiceRequest.IsQcPresent) {
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) {
							statuses.push('Intimate customer');
						} else if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
							if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request','Add Shipment Details');
							}
						}
					}
				}
			}
			//end claim new 
			//TODO think about more scenarios on this staus
			return statuses;
		}
    },{
  		csrStatus: 'Repair completed', // checked
		csrlStatus: 'Token released',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && consumerServiceRequest.IsQcPresent) {
						statuses.push('QC pending');
					}
				}

				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && !consumerServiceRequest.IsQcPresent) {
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) {
							statuses.push('Intimate customer');
						} else if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
							if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request','Add Shipment Details');
							}
						}
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if(isSupOrEngg()){
						if (showRequestLogs.isRepairCompleted && consumerServiceRequest.IsQcPresent) {
							statuses = concat(statuses, 'QC pending');
						}
					}
					if (accessFn('STORE_MANAGEMENT') || accessFn('ACCEPT_RETURN_DEVICE')){
						if(!showRequestValid.allowCCToProceedWithService){
							statuses = concat(statuses, 'Transfer request');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (showRequestValid.allowCCToProceedWithService) {
							if(showRequestLogs.isRepairCompleted && !consumerServiceRequest.IsQcPresent){
								if(!consumerServiceRequest.IsWarrantyApplicable){
									if(consumerServiceRequest.showGenerateInvoiceButton) {
										statuses = concat(statuses, 'Service invoice generated');
									}else if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
										statuses = concat(statuses, 'Payment received');
									}
								} else {
									if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN){
										statuses = concat(statuses, 'Intimate customer');
									}else if(consumerServiceRequest.ServiceTypeID == 1){
										if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
											statuses = concat(statuses, 'Device dispatched');
										} else {
											statuses = concat(statuses, 'Initiate logistics request');
										}
									}
								}
							}
						}
					}
				}else{
					if(isSupOrEngg()){
						if (showRequestLogs.isRepairCompleted && consumerServiceRequest.IsQcPresent && !exception) {
							statuses = concat(statuses, 'QC pending');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(showRequestLogs.isRepairCompleted && !consumerServiceRequest.IsQcPresent) {
							if(!consumerServiceRequest.IsWarrantyApplicable){
								if(consumerServiceRequest.showGenerateInvoiceButton){
									statuses = concat(statuses, 'Service invoice generated');
								}else if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
									statuses = concat(statuses, 'Payment received');
									if(accessFn('SEND_PAYMENT_LINK')){
										statuses = concat(statuses, 'Send payment link');
									}
								}
							}else{
								if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
									statuses = concat(statuses, 'Intimate customer');
								}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
									if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
										statuses = concat(statuses, 'Device dispatched');
									} else {
										statuses = concat(statuses, 'Initiate logistics request');
									}
								}
							}
						}
						if(exception) {
							statuses = concat(statuses, 'Intimate customer');
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			//TODO think about more scenarios on this staus
			return statuses;
		}
	},{
		csrStatus: 'Repair completed', // checked
		csrlStatus: 'Token generated',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if(isSupOrEngg()){
						if (showRequestLogs.isRepairCompleted && consumerServiceRequest.IsQcPresent) {
							statuses = concat(statuses, 'QC pending');
						}
					}
					if (accessFn('STORE_MANAGEMENT') || accessFn('ACCEPT_RETURN_DEVICE')){
						if(!showRequestValid.allowCCToProceedWithService){
							statuses = concat(statuses, 'Transfer request');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (showRequestValid.allowCCToProceedWithService) {
							if(showRequestLogs.isRepairCompleted && !consumerServiceRequest.IsQcPresent){
								if(!consumerServiceRequest.IsWarrantyApplicable){
									if(consumerServiceRequest.showGenerateInvoiceButton) {
										statuses = concat(statuses, 'Service invoice generated');
									}else if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
										statuses = concat(statuses, 'Payment received');
									}
								} else {
									if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN){
										statuses = concat(statuses, 'Intimate customer');
									}else if(consumerServiceRequest.ServiceTypeID == 1){
										if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
											statuses = concat(statuses, 'Device dispatched');
										} else {
											statuses = concat(statuses, 'Initiate logistics request','Add Shipment Details');
										}
									}
								}
							}
						}
					}
				}else{
					if(isSupOrEngg()){
						if (showRequestLogs.isRepairCompleted && consumerServiceRequest.IsQcPresent && !exception) {
							statuses = concat(statuses, 'QC pending');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(showRequestLogs.isRepairCompleted && !consumerServiceRequest.IsQcPresent) {
							if(!consumerServiceRequest.IsWarrantyApplicable){
								if(consumerServiceRequest.showGenerateInvoiceButton){
									statuses = concat(statuses, 'Service invoice generated');
								}else if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
									statuses = concat(statuses, 'Payment received');
									if(accessFn('SEND_PAYMENT_LINK')){
										statuses = concat(statuses, 'Send payment link');
									}
								}
							}else{
								if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
									statuses = concat(statuses, 'Intimate customer');
								}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
									if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
										statuses = concat(statuses, 'Device dispatched');
									} else {
										statuses = concat(statuses, 'Initiate logistics request');
									}
								}
							}
						}
						if(exception) {
							statuses = concat(statuses, 'Intimate customer');
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
  	},
	{
  		csrStatus: 'Repair completed', // checked
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			//claim new 
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && consumerServiceRequest.IsQcPresent) {
						statuses.push('QC pending');
					}
				}

				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && !consumerServiceRequest.IsQcPresent) {
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) {
							statuses.push('Intimate customer');
						} else if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
							if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request','Add Shipment Details');
							}
						}
					}
				}
			}
			// end claim new 
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if(isSupOrEngg()){
						if (showRequestLogs.isRepairCompleted && consumerServiceRequest.IsQcPresent) {
							statuses = concat(statuses, 'QC pending');
						}
					}
					if (accessFn('STORE_MANAGEMENT') || accessFn('ACCEPT_RETURN_DEVICE')){
						if(!showRequestValid.allowCCToProceedWithService){
							statuses = concat(statuses, 'Transfer request');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (showRequestValid.allowCCToProceedWithService) {
							if(showRequestLogs.isRepairCompleted && !consumerServiceRequest.IsQcPresent){
								if(!consumerServiceRequest.IsWarrantyApplicable){
									if(consumerServiceRequest.showGenerateInvoiceButton) {
										statuses = concat(statuses, 'Service invoice generated');
									}else if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
										statuses = concat(statuses, 'Payment received');
									}
								} else {
									if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN){
										statuses = concat(statuses, 'Intimate customer');
									}else if(consumerServiceRequest.ServiceTypeID == 1){
										if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
											statuses = concat(statuses, 'Device dispatched');
										} else {
											statuses = concat(statuses, 'Initiate logistics request');
										}
									}
								}
							}
						}
					}
				}else{
					if(isSupOrEngg()){
						if (showRequestLogs.isRepairCompleted && consumerServiceRequest.IsQcPresent && !exception) {
							statuses = concat(statuses, 'QC pending');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(showRequestLogs.isRepairCompleted && !consumerServiceRequest.IsQcPresent) {
							if(!consumerServiceRequest.IsWarrantyApplicable){
								if(consumerServiceRequest.showGenerateInvoiceButton){
									statuses = concat(statuses, 'Service invoice generated');
								}else if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
									statuses = concat(statuses, 'Payment received');
									if(accessFn('SEND_PAYMENT_LINK')){
										statuses = concat(statuses, 'Send payment link');
									}
								}
							}else{
								if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
									statuses = concat(statuses, 'Intimate customer');
								}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
									if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
										statuses = concat(statuses, 'Device dispatched');
									} else {
										statuses = concat(statuses, 'Initiate logistics request');
									}
								}
							}
						}
						if(exception) {
							statuses = concat(statuses, 'Intimate customer');
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
  				//TODO think about more scenarios on this staus
			return statuses;
		}
	},
	{
		csrStatus: 'Repair completed', // checked
		csrlStatus: 'Device handed over',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
					if(!accessFn('GSX_FLOW') && accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
			}
			// Pickup carry in end
			return statuses;
		}
	},{
  		csrStatus: 'Repair completed', // checked
		csrlStatus: 'Shipment created',
		computeNextStatus: function() {
			let statuses = [];
			if((!consumerServiceRequest.showEngineerVisits) && (!(accessFn('WORKSHOP_FLOW'))) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
					if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Dispatch Device')
					}
				}
			}
			

			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						statuses = concat(statuses, 'Device dispatched');
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						statuses = concat(statuses, 'Device dispatched');
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if(hideIssueLoanerButton){
						statuses = concat(statuses, '');
					}
					if(!hideIssueLoanerButton){
						statuses = concat(statuses, 'Service completed');
					}
				}
			}
			//claim end
			//refurbishment flow 
			if(refurbishmentFlowCheck){
				// statuses = concat(statuses,'Device delivered');//'refurbishment shipment created' 'Service completed','Add Shipment Details','Device inspected',
			}
			//refurbishment flow end
			return statuses;
		}
	},{
        csrStatus: 'Service initiated', 
		csrlStatus: 'Service initiated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						if( accessFn( 'REQUEST_REASSIGN')){
							if(!accessFn('HIDE_REPAIR_COMPLETE')){
								statuses = concat(statuses,'Complete Repair');
							}
							statuses = concat(statuses,'Complete Service','Complete Without Repair','Cancel Repair');
							if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !consumerServiceRequest.InvoiceGeneratedDate){
								statuses = concat(statuses,'Cancel Service');
							}
							if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
								statuses=concat(statuses,'Generate Invoice')
							}
						}
						if(consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !accessFn('HIDE_PARTS_PENDING_BUTTON') ){
							statuses=concat(statuses,'Parts Pending');
						}
					}
					if((!consumerServiceRequest.isFree) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				
				if( [SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !accessFn('READ_ONLY') && ([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REQUEST_REASSIGN') ){
						statuses=concat(statuses,'Cancel Repair')
					}
					if(!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						if(accessFn( 'REQUEST_REASSIGN') ){
							if(!accessFn('HIDE_REPAIR_COMPLETE')){
								statuses = concat(statuses,'Complete Repair');
							}
							statuses = concat(statuses,'Complete Without Repair','Cancel Repair');
							if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
								statuses=concat(statuses,'Generate Invoice')
							}
						}
						if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate  && !accessFn('HIDE_PARTS_PENDING_BUTTON') ){
							statuses=concat(statuses,'Parts Pending');
						}
					}
					if((!consumerServiceRequest.isFree) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
						if (accessFn('TECHNICIAN_SUPERVISOR') && !isSymptomMapAdded()) {
							if (consumerServiceRequest && consumerServiceRequest.IsDeviceValidated) {
								statuses = concat(statuses, 'Iris submitted');
							}
						} else if (accessFn('TECHNICIAN_SUPERVISOR') && getSource()!='Bose'){
							statuses = concat(statuses, 'Service invoice generated');
						}
					}
					if (isBoseFlow) {
						statuses = concat(statuses, 'Visit productive');
						statuses = concat(statuses, 'Visit unproductive');
					}
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Repair completed');
					}
				}
			}
			//onsite end
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch', 'Repair completed', 'Parts pending']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Service cancel', 'Repair completed', 'Service completed', 'Parts pending']);
						// }
					}
					if(consumerServiceRequest && consumerServiceRequest.autoClaimFulfillmentFlow) {
						claimStatuses =  ['Repair cancel', 'Service cancel', 'Repair completed']
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Service cancel', 'Repair completed', 'Service completed', 'Parts pending']);
						// }
					}
				}
				statuses = concat(statuses,claimStatuses)
			}
			//claim end
			return statuses;
		}
    },

	{
  		csrStatus: 'Repair completed', // checked
		csrlStatus: 'Service completed',
		computeNextStatus: function() {
			let statuses = [];
			//claim new 
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Complete Service');
					}
				}
				if(([SERVICETYPE.PICK_UP , SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
					if( accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')  ){
						statuses=concat(statuses,'Dispatch Device');
					}
					
					if(!(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled)){
						if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') ){
							statuses = concat(statuses,'Initiate logistics request','Add Shipment Details');
							if(warrantyAppicableNonClaimCheck()){
								statuses = concat(statuses,'Complete Service');
							}
						}
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start
			if(onsiteChecks){
				if(showButton){
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Service completed');
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && consumerServiceRequest.IsQcPresent) {
						statuses.push('QC pending');
					}
				}

				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && !consumerServiceRequest.IsQcPresent) {
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) {
							statuses.push('Intimate customer');
						} else if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
							if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}
					}
				}
			}
			//---end claim new

			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID)) > -1 && claimMergeStatus.isPickUp){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						statuses = concat(statuses, 'Device dispatched');
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						statuses = concat(statuses, 'Device dispatched');
					}
				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if(hideIssueLoanerButton){
						statuses = [];
					}
					if(hideIssueLoanerButton){
						statuses = ['Service completed'];
					}
				}
				if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
					statuses = ['Device dispatched'];
				}
				else {
					statuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
				}
				if(claimobj  && claimobj.consumerServiceRequest && claimobj.consumerServiceRequest.autoClaimFulfillmentFlow) {
					statuses =[];
				}

				if ([SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
						statuses = ['Device dispatched'];
					}else{
						statuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1 && [SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
					if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
						statuses = ['Device dispatched'];
					}
					else {
						statuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
			}
			//claim end 
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			return statuses;
		}
	},
	{
        csrStatus: 'Service initiated', 
		csrlStatus: 'Service invoice generated',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						if( accessFn( 'REQUEST_REASSIGN')){
							if(!accessFn('HIDE_REPAIR_COMPLETE')){
								statuses = concat(statuses,'Complete Repair');
							}
							statuses = concat(statuses,'Complete Service','Complete Without Repair','Cancel Repair');
							if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !consumerServiceRequest.InvoiceGeneratedDate){
								statuses = concat(statuses,'Cancel Service');
							}
							if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
								statuses=concat(statuses,'Generate Invoice')
							}
						}
						if(consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !accessFn('HIDE_PARTS_PENDING_BUTTON') ){
							statuses=concat(statuses,'Parts Pending');
						}
					}
					if((!consumerServiceRequest.isFree) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				
				if( [SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !accessFn('READ_ONLY') && ([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REQUEST_REASSIGN') ){
						statuses=concat(statuses,'Cancel Repair')
					}
					if(!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						if(accessFn( 'REQUEST_REASSIGN') ){
							if(!accessFn('HIDE_REPAIR_COMPLETE')){
								statuses = concat(statuses,'Complete Repair');
							}
							statuses = concat(statuses,'Complete Without Repair','Cancel Repair');
							if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
								statuses=concat(statuses,'Generate Invoice')
							}
						}
						if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate  && !accessFn('HIDE_PARTS_PENDING_BUTTON') ){
							statuses=concat(statuses,'Parts Pending');
						}
					}
					if((!consumerServiceRequest.isFree) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	
	{
        csrStatus: 'DropOff request accepted',
		csrlStatus: 'DropOff request accepted',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){ 
						statuses = concat(statuses,'Device Received');
					}
				}

				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON') && !accessFn('READ_ONLY')){
					statuses=concat(statuses,'Device shipped');
				}
			}
			if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL ){
				statuses = concat(statuses,'Resend Dropoff Location Link');
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			// if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL){
			// 	statuses=concat(statuses,'Resend Dropoff Location Link');
			// }
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//end claim new workshop

			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if(accessFn('SHOW_DEVICE_SHIPPED_BUTTON')){
					if(consumerServiceRequest.showShippedButton){
						if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&  claimMergeStatus.isPickUp)) {
							if (accessFn('ADD_NORMAL_CHARGES')) {
								claimStatuses.push('Device shipped')
							}
						}
					}
				}
				if((!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
					claimStatuses = ['Device received', 'Service cancel', 'Cancel Service Without Dispatch'];
				}else{
					claimStatuses = [];
				}

				//new  confused
				if(consumerServiceRequest.showAddShipment){
					claimStatuses = concat(claimStatuses,'Add Shipment Details');
				}else{
					claimStatuses = pull(claimStatuses, 'Add Shipment Details');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(!accessFn('GSX_INFO')){
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						statuses = concat(statuses, 'Device received', 'Service cancel');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'DropOff request accepted',
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses =[];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){ 
						statuses = concat(statuses,'Device Received');
					}
				}

				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON') && !accessFn('READ_ONLY')){
					statuses=concat(statuses,'Device shipped');
				}
			}
			if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL ){
				statuses = concat(statuses,'Resend Dropoff Location Link');
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			// if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL){
			// 	statuses=concat(statuses,'Resend Dropoff Location Link');
			// }
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//end claim new workshop
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if(accessFn('SHOW_DEVICE_SHIPPED_BUTTON')){
					if(consumerServiceRequest.showShippedButton){
						if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&  claimMergeStatus.isPickUp)) {
							if (accessFn('ADD_NORMAL_CHARGES')) {
								claimStatuses.push('Device shipped')
							}
						}
					}
				}
				if((!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
					claimStatuses = ['Device received', 'Service cancel', 'Cancel Service Without Dispatch'];
				}else{
					claimStatuses = [];
				}
				//new  confused
				if(consumerServiceRequest.showAddShipment){
					claimStatuses = concat(claimStatuses,'Add Shipment Details');//instead of push use concat
				}else{
					claimStatuses = pull(claimStatuses, 'Add Shipment Details');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			if(pickupCarryInChecks){
				if(!accessFn('GSX_INFO')){
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						statuses = concat(statuses, 'Device received', 'Service cancel');
					}
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'DropOff request accepted',
		csrlStatus: 'Waiting for Assignment',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses =[];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){ 
						statuses = concat(statuses,'Device Received');
					}
				}

				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON') && !accessFn('READ_ONLY')){
					statuses=concat(statuses,'Device shipped');
				}
			}
			if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL ){
				statuses = concat(statuses,'Resend Dropoff Location Link');
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			// if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL){
			// 	statuses=concat(statuses,'Resend Dropoff Location Link');
			// }
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//end claim new workshop
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if(accessFn('SHOW_DEVICE_SHIPPED_BUTTON')){
					if(consumerServiceRequest.showShippedButton){
						if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&  claimMergeStatus.isPickUp)) {
							if (accessFn('ADD_NORMAL_CHARGES')) {
								claimStatuses.push('Device shipped')
							}
						}
					}
				}
				if((!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
					claimStatuses = ['Device received', 'Service cancel', 'Cancel Service Without Dispatch'];
				}else{
					claimStatuses = [];
				}
				//new  confused
				if(consumerServiceRequest.showAddShipment){
					claimStatuses = concat(claimStatuses,'Add Shipment Details');
				}else{
					claimStatuses = pull(claimStatuses, 'Add Shipment Details');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			if(pickupCarryInChecks){
				if(!accessFn('GSX_INFO')){
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						statuses = concat(statuses, 'Device received', 'Service cancel');
					}
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'DropOff request accepted',
		csrlStatus: 'Token generated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses =[];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){ 
						statuses = concat(statuses,'Device Received');
					}
				}

				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON') && !accessFn('READ_ONLY')){
					statuses=concat(statuses,'Device shipped');
				}
			}
			if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL ){
				statuses = concat(statuses,'Resend Dropoff Location Link');
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			// if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL){
			// 	statuses=concat(statuses,'Resend Dropoff Location Link');
			// }
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//end claim new workshop
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if(accessFn('SHOW_DEVICE_SHIPPED_BUTTON')){
					if(consumerServiceRequest.showShippedButton){
						if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&  claimMergeStatus.isPickUp)) {
							if (accessFn('ADD_NORMAL_CHARGES')) {
								claimStatuses.push('Device shipped')
							}
						}
					}
				}
				if((!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
					claimStatuses = ['Device received', 'Service cancel', 'Cancel Service Without Dispatch'];
				}else{
					claimStatuses = [];
				}
				//new  confused
				if(consumerServiceRequest.showAddShipment){
					claimStatuses = concat(claimStatuses,'Add Shipment Details');
				}else{
					claimStatuses = pull(claimStatuses, 'Add Shipment Details');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			if(pickupCarryInChecks){
				if(!accessFn('GSX_INFO')){
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						statuses = concat(statuses, 'Device received', 'Service cancel');
					}
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'DropOff request accepted',
		csrlStatus: 'Device shipped',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses =[];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){ 
						statuses = concat(statuses,'Device Received');
					}
				}

				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON') && !accessFn('READ_ONLY')){
					statuses=concat(statuses,'Device shipped');
				}
			}
			if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL ){
				statuses = concat(statuses,'Resend Dropoff Location Link');
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			// if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL){
			// 	statuses=concat(statuses,'Resend Dropoff Location Link');
			// }
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//end claim new workshop
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if((!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
					claimStatuses = ['Device received', 'Service cancel', 'Cancel Service Without Dispatch'];
				}else{
					claimStatuses = [];
				}
				//new  confused
				if(consumerServiceRequest.showAddShipment){
					claimStatuses = concat(claimStatuses,'Add Shipment Details');
				}else{
					claimStatuses = pull(claimStatuses, 'Add Shipment Details');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			if(pickupCarryInChecks){
				if(!accessFn('GSX_INFO')){
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						statuses = concat(statuses, 'Device received', 'Service cancel');
					}
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'DropOff request accepted',
		csrlStatus: 'Reschedule requested',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses =[];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){ 
						statuses = concat(statuses,'Device Received');
					}
				}

				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON') && !accessFn('READ_ONLY')){
					statuses=concat(statuses,'Device shipped');
				}
			}
			if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL ){
				statuses = concat(statuses,'Resend Dropoff Location Link');
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			// if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL){
			// 	statuses=concat(statuses,'Resend Dropoff Location Link');
			// }
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//end claim new workshop
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if(accessFn('SHOW_DEVICE_SHIPPED_BUTTON')){
					if(consumerServiceRequest.showShippedButton){
						if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&  claimMergeStatus.isPickUp)) {
							if (accessFn('ADD_NORMAL_CHARGES')) {
								claimStatuses.push('Device shipped')
							}
						}
					}
				}
				if((!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
					claimStatuses = ['Device received', 'Service cancel', 'Cancel Service Without Dispatch'];
				}else{
					claimStatuses = [];
				}
				//new  confused
				if(consumerServiceRequest.showAddShipment){
					claimStatuses = concat(claimStatuses,'Add Shipment Details');
				}else{
					claimStatuses = pull(claimStatuses, 'Add Shipment Details');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			if(pickupCarryInChecks){
				if(!accessFn('GSX_INFO')){
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						statuses = concat(statuses, 'Device received', 'Service cancel');
					}
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'DropOff request accepted',
		csrlStatus: 'Repair cancel',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses =[];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){ 
						statuses = concat(statuses,'Device Received');
					}
				}

				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON') && !accessFn('READ_ONLY')){
					statuses=concat(statuses,'Device shipped');
				}
			}
			if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL ){
				statuses = concat(statuses,'Resend Dropoff Location Link');
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			// if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL){
			// 	statuses=concat(statuses,'Resend Dropoff Location Link');
			// }
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//end claim new workshop
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if(accessFn('SHOW_DEVICE_SHIPPED_BUTTON')){
					if(consumerServiceRequest.showShippedButton){
						if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&  claimMergeStatus.isPickUp)) {
							if (accessFn('ADD_NORMAL_CHARGES')) {
								claimStatuses.push('Device shipped')
							}
						}
					}
				}
				if((!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
					claimStatuses = ['Device received', 'Service cancel', 'Cancel Service Without Dispatch'];
				}else{
					claimStatuses = [];
				}
				//new  confused
				if(consumerServiceRequest.showAddShipment){
					claimStatuses = concat(claimStatuses,'Add Shipment Details');
				}else{
					claimStatuses = pull(claimStatuses, 'Add Shipment Details');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			if(pickupCarryInChecks){
				if(!accessFn('GSX_INFO')){
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						statuses = concat(statuses, 'Device received', 'Service cancel');
					}
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'DropOff request accepted',
		csrlStatus: 'Shipment created',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if (accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && (!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){ 
						statuses = concat(statuses,'Device Received');
					}
				}

				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				if(consumerServiceRequest && consumerServiceRequest.showShippedButton  && accessFn('SHOW_DEVICE_SHIPPED_BUTTON') && !accessFn('READ_ONLY')){
					statuses=concat(statuses,'Device shipped');
				}
			}
			if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL ){
				statuses = concat(statuses,'Resend Dropoff Location Link');
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			// if(([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL){
			// 	statuses=concat(statuses,'Resend Dropoff Location Link');
			// }
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
							// statuses.push('Device received');
						statuses = concat(statuses, 'Service cancel','Cancel Service Without Dispatch');
					if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost && consumerServiceRequest.consumerProduct.DateOfPurchase) {
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			//end claim new workshop

			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Device received', 'Service cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if(accessFn('SHOW_DEVICE_SHIPPED_BUTTON')){
					if(consumerServiceRequest.showShippedButton){
						if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 &&  claimMergeStatus.isPickUp)) {
							if (accessFn('ADD_NORMAL_CHARGES')) {
								claimStatuses.push('Device shipped')
							}
						}
					}
				}
				if((!isEmptyBoxServiceable || (isEmptyBoxServiceable && !showShipPackagingMaterialButton && emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0))){
					claimStatuses = ['Device received', 'Service cancel', 'Cancel Service Without Dispatch'];
				}else{
					claimStatuses = [];
				}

				//new  confused
				if(consumerServiceRequest.showAddShipment){
					claimStatuses = concat(claimStatuses,'Add Shipment Details');
				}else{
					claimStatuses = pull(claimStatuses, 'Add Shipment Details');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(!accessFn('GSX_INFO')){
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						statuses = concat(statuses, 'Device received', 'Service cancel');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Parts received',
		csrlStatus: 'Parts received',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN ,SERVICETYPE.PICK_UP ,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(consumerServiceRequest && accessFn('REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
						if(!consumerServiceRequest.showEngineerVisits){
							statuses = concat(statuses, 'Cancel Service');
						}
						if(!accessFn('HIDE_REPAIR_COMPLETE')){
							statuses = concat(statuses,'Complete Repair');
						}
						statuses = concat(statuses,'Complete Without Repair','Cancel Repair');
					}
					if((!consumerServiceRequest.isFree) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				if([SERVICETYPE.CARRY_IN, SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 && accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && !accessFn('HIDE_GENERATE_INVOICE_BUTTON') && warrantyAppicableNonClaimCheck()){
					if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate){
						statuses=concat(statuses,'Generate Invoice');
					}
				}
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
							if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						if (accessFn('CREATE_VISIT_ACCESS') && !buttonLogStatus.tecnicianReached) {
							statuses = concat(statuses, 'Service reschedule');
						} 
						else if(buttonLogStatus.tecnicianReached) {
							statuses = concat(statuses, 'Visit productive','Visit unproductive');
						}

						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					statuses.push('Repair completed');
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}	
			}
			//end claim new

			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ){
							claimStatuses = concat(claimStatuses,'Repair completed', 'Cancel Service Without Dispatch', 'Service cancel');
						// }
					}

				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ){
							claimStatuses = concat(claimStatuses, 'Service completed', 'Repair completed', 'Repair cancel', 'Service cancel');
						// }
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ){
							claimStatuses = concat(claimStatuses, 'Repair completed', 'Cancel Service Without Dispatch', 'Service cancel');
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end cliam
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if(isSupOrEngg()){
						if(isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber && (!showRequestLogs.receivedPartPresent || showRequestLogs.receivedPartPresent.length < 1)){
								statuses = concat(statuses, 'Request raised');
								if(accessFn('REASSIGN_JOBS')){
									statuses = concat(statuses, 'Map G-Number');
								}
							}
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Update KGB');
								}
								else if(consumerServiceRequest.isMailinRequest && showRequestLogs.isConsignment && showRequestLogs.receivedConsumedConsignmentPart.length > 1 && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Repair completed');
								}else{
									if((!showRequestLogs.receivedPartPresent|| showRequestLogs.receivedPartPresent.length < 1)){
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}else{
					if(isSupOrEngg()){
						if(consumerServiceRequest.DOAStatus == 'replacement') {
						  statuses = concat(statuses, 'Repair completed');
						}else if(isRequestSFANeeded){
							if((requestSFA && requestSFA.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
								statuses = concat(statuses, 'Repair completed');
							}
						}else{
							if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
								statuses = concat(statuses, 'Repair completed');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Cancel Service Without Dispatch');
					}
					if(!accessFn('CANCEL_REVERT_INVENTORY')){
						if(accessFn('ACCEPT_RETURN_DEVICE') && !showRequestLogs.isExceptionApproved){
							statuses = concat(statuses, 'Repair cancel');
						}
					}else if(!showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			//refurbishment flow
			if(refurbishmentFlowCheck){
				statuses = concat(statuses,'Repair completed')
			}
			//refurbishment flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Parts received',
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN ,SERVICETYPE.PICK_UP ,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(consumerServiceRequest && accessFn('REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
						if(!consumerServiceRequest.showEngineerVisits){
							statuses = concat(statuses, 'Cancel Service');
						}
						if(!accessFn('HIDE_REPAIR_COMPLETE')){
							statuses = concat(statuses,'Complete Repair');
						}
						statuses = concat(statuses, 'Complete Without Repair','Cancel Repair');
					}
					if((!consumerServiceRequest.isFree) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				if([SERVICETYPE.CARRY_IN ,SERVICETYPE.PICK_UP ].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 && accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && !accessFn('HIDE_GENERATE_INVOICE_BUTTON') && warrantyAppicableNonClaimCheck()){
					if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate){
						statuses=concat(statuses,'Generate Invoice');
					}
			}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
							if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						if (accessFn('CREATE_VISIT_ACCESS') && !buttonLogStatus.tecnicianReached) {
							statuses = concat(statuses, 'Service reschedule');
						} 
						else if(buttonLogStatus.tecnicianReached) {
							statuses = concat(statuses, 'Visit productive','Visit unproductive');
						}

						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				let statuses = [];
				if (isSupOrEngg()) {
					statuses.push('Repair completed');
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}	
			}
			//end claim new
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if(isSupOrEngg()){
						if(isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber && (!showRequestLogs.receivedPartPresent || showRequestLogs.receivedPartPresent.length < 1)){
								statuses = concat(statuses, 'Request raised');
								if(accessFn('REASSIGN_JOBS')){
									statuses = concat(statuses, 'Map G-Number');
								}
							}
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Update KGB');
								}
								else if(consumerServiceRequest.isMailinRequest && showRequestLogs.isConsignment && showRequestLogs.receivedConsumedConsignmentPart.length > 1 && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Repair completed');
								}else{
									if((!showRequestLogs.receivedPartPresent|| showRequestLogs.receivedPartPresent.length < 1)){
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}else{
					if(isSupOrEngg()){
						if(consumerServiceRequest.DOAStatus == 'replacement') {
							statuses = concat(statuses, 'Repair completed');
						}else if(isRequestSFANeeded){
							if((requestSFA && requestSFA.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
								statuses = concat(statuses, 'Repair completed');
							}
						}else{
							if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
								statuses = concat(statuses, 'Repair completed');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Cancel Service Without Dispatch');
					}
					if(!accessFn('CANCEL_REVERT_INVENTORY')){
						if(accessFn('ACCEPT_RETURN_DEVICE') && !showRequestLogs.isExceptionApproved){
							statuses = concat(statuses, 'Repair cancel');
						}
					}else if(!showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Parts received',
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN ,SERVICETYPE.PICK_UP ,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(consumerServiceRequest && accessFn('REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
						if(!consumerServiceRequest.showEngineerVisits){
							statuses = concat(statuses, 'Cancel Service');
						}
						if(!accessFn('HIDE_REPAIR_COMPLETE')){
							statuses = concat(statuses,'Complete Repair');
						}
						statuses = concat(statuses, 'Complete Without Repair','Cancel Repair');
					}
					if((!consumerServiceRequest.isFree) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				if(([SERVICETYPE.CARRY_IN ,SERVICETYPE.PICK_UP ].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && !accessFn('HIDE_GENERATE_INVOICE_BUTTON') && warrantyAppicableNonClaimCheck()){
					if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate){
						statuses=concat(statuses,'Generate Invoice');
					}
				}
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
							if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						if (accessFn('CREATE_VISIT_ACCESS') && !buttonLogStatus.tecnicianReached) {
							statuses = concat(statuses, 'Service reschedule');
						} 
						else if(buttonLogStatus.tecnicianReached) {
							statuses = concat(statuses, 'Visit productive','Visit unproductive');
						}

						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				let statuses = [];
				if (isSupOrEngg()) {
					statuses.push('Repair completed');
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}	
			}
			//end claim new
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if(isSupOrEngg()){
						if(isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber && (!showRequestLogs.receivedPartPresent || showRequestLogs.receivedPartPresent.length < 1)){
								statuses = concat(statuses, 'Request raised');
								if(accessFn('REASSIGN_JOBS')){
									statuses = concat(statuses, 'Map G-Number');
								}
							}
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
										statuses = concat(statuses, 'Update KGB');
									}
									else if(consumerServiceRequest.isMailinRequest && showRequestLogs.isConsignment && showRequestLogs.receivedConsumedConsignmentPart.length > 1 && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
										statuses = concat(statuses, 'Repair completed');
									}else{
										if((!showRequestLogs.receivedPartPresent|| showRequestLogs.receivedPartPresent.length < 1)){
											statuses = concat(statuses, 'Repair completed');
										}
									}
								}
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}else{
					if(isSupOrEngg()){
						if(consumerServiceRequest.DOAStatus == 'replacement') {
							statuses = concat(statuses, 'Repair completed');
						}else if(isRequestSFANeeded){
							if((requestSFA && requestSFA.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
								statuses = concat(statuses, 'Repair completed');
							}
						}else{
							if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
								statuses = concat(statuses, 'Repair completed');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Cancel Service Without Dispatch');
					}
					if(!accessFn('CANCEL_REVERT_INVENTORY')){
						if(accessFn('ACCEPT_RETURN_DEVICE') && !showRequestLogs.isExceptionApproved){
							statuses = concat(statuses, 'Repair cancel');
						}
					}else if(!showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
		csrStatus: 'Parts received',
		csrlStatus: 'Device handed over',
		computeNextStatus: function() {
			var statuses = [];
			if(!accessFn('GSX_FLOW') && accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
				statuses = concat(statuses, 'Repair cancel initiated');
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(([SERVICETYPE.CARRY_IN ,SERVICETYPE.PICK_UP ].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && !accessFn('HIDE_GENERATE_INVOICE_BUTTON') && warrantyAppicableNonClaimCheck()){
					if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate){
						statuses=concat(statuses,'Generate Invoice');
					}
				}
				if((!consumerServiceRequest.isFree) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
					statuses = concat(statuses,'Payment Pending');
				}
			}
			
			return statuses;
		}
	},
	{
        csrStatus: 'Parts received',
		csrlStatus: 'Service invoice generated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN ,SERVICETYPE.PICK_UP ,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(consumerServiceRequest && accessFn('REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
						if(!consumerServiceRequest.showEngineerVisits){
							statuses = concat(statuses, 'Cancel Service');
						}
						if(!accessFn('HIDE_REPAIR_COMPLETE')){
							statuses = concat(statuses,'Complete Repair');
						}
						statuses = concat(statuses,'Complete Without Repair','Cancel Repair');
					}
					if((!consumerServiceRequest.isFree) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				if([SERVICETYPE.CARRY_IN, SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 && accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && !accessFn('HIDE_GENERATE_INVOICE_BUTTON') && warrantyAppicableNonClaimCheck()){
					if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate){
						statuses=concat(statuses,'Generate Invoice');
					}
				}
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
							if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						if (accessFn('CREATE_VISIT_ACCESS') && !buttonLogStatus.tecnicianReached) {
							statuses = concat(statuses, 'Service reschedule');
						} 
						else if(buttonLogStatus.tecnicianReached) {
							statuses = concat(statuses, 'Visit productive','Visit unproductive');
						}

						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					statuses.push('Repair completed');
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}	
			}
			//end claim new

			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ){
							claimStatuses = concat(claimStatuses,'Repair completed', 'Cancel Service Without Dispatch', 'Service cancel');
						// }
					}

				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ){
							claimStatuses = concat(claimStatuses, 'Service completed', 'Repair completed', 'Repair cancel', 'Service cancel');
						// }
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ){
							claimStatuses = concat(claimStatuses, 'Repair completed', 'Cancel Service Without Dispatch', 'Service cancel');
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end cliam
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if(isSupOrEngg()){
						if(isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber && (!showRequestLogs.receivedPartPresent || showRequestLogs.receivedPartPresent.length < 1)){
								statuses = concat(statuses, 'Request raised');
								if(accessFn('REASSIGN_JOBS')){
									statuses = concat(statuses, 'Map G-Number');
								}
							}
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Update KGB');
								}
								else if(consumerServiceRequest.isMailinRequest && showRequestLogs.isConsignment && showRequestLogs.receivedConsumedConsignmentPart.length > 1 && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Repair completed');
								}else{
									if((!showRequestLogs.receivedPartPresent|| showRequestLogs.receivedPartPresent.length < 1)){
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}else{
					if(isSupOrEngg()){
						if(consumerServiceRequest.DOAStatus == 'replacement') {
						  statuses = concat(statuses, 'Repair completed');
						}else if(isRequestSFANeeded){
							if((requestSFA && requestSFA.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
								statuses = concat(statuses, 'Repair completed');
							}
						}else{
							if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
								statuses = concat(statuses, 'Repair completed');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Cancel Service Without Dispatch');
					}
					if(!accessFn('CANCEL_REVERT_INVENTORY')){
						if(accessFn('ACCEPT_RETURN_DEVICE') && !showRequestLogs.isExceptionApproved){
							statuses = concat(statuses, 'Repair cancel');
						}
					}else if(!showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Device dispatched', 
		csrlStatus: 'Device dispatched',
		computeNextStatus: function() {
			let statuses = [];
			let repairCancelled = false;
			if (consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.length > 1) {
				let cancelLog = find(consumerServiceRequest.logs, {Status: 'Repair cancel'});
				repairCancelled = (cancelLog ? true : false);
			}
			if( ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
						statuses = concat(statuses, 'Complete Service');
					}
				}
				if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(!repairCancelled){
						if(accessFn( 'REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
							statuses = concat(statuses, 'Complete Service');
						}
					}else{
						if(!consumerServiceRequest.showEngineerVisits && accessFn('REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
							statuses = concat(statuses,'Cancel Service');
						}
					}
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if ((claimMergeStatus && claimMergeStatus.pickUpCancel) || (claimMergeStatus && claimMergeStatus.claimRejected)) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (claimMergeStatus.mailInCancel) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					} 
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (claimMergeStatus.carryInCancel) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					}
				}	
			}
			//claim new workshop and pick up carry in
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
						if(showRequestLogs.isRepairCompleted){
							statuses=concat(statuses,'Service cancel')
						}else{
							statuses=concat(statuses,'Service completed');
						}
					}
				}
			}
			//end claim new workshop and pick up carry in
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Service completed', 'BER device received','BER accessories validated','BER accessories rejected', 'Call attempted');
				}
				
			}
			//ber end 

			//replacement flow
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Replacement delivered');
				}else{
					statuses = concat(statuses,'Service completed');
				}
			}
			//end replacement flow
			if(pickupCarryInChecks){
				if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (consumerServiceRequest.ServiceTypeID == 1) {
							if (showRequestLogs.isRepairCompleted) {
								statuses = concat(statuses,'Service completed');
							} else {
								if(consumerServiceRequest.originCenter){
									statuses = concat(statuses, 'Service cancel');
								}
							}
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if([1,17].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if (showRequestLogs.isRepairCancel) {
								statuses = concat(statuses,'Service cancel');
							} else {
								statuses = concat(statuses,'Service completed');
							}
						}
					}
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Device dispatched', 
		csrlStatus: 'Token released',
		computeNextStatus: function() {
			let statuses = [];
			let repairCancelled = false;
			if (consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.length > 1) {
				let cancelLog = find(consumerServiceRequest.logs, {Status: 'Repair cancel'});
				repairCancelled = (cancelLog ? true : false);
			}
			if( ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
						statuses = concat(statuses, 'Complete Service');
					}
				}
				if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(!repairCancelled){
						if(accessFn( 'REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
							statuses = concat(statuses, 'Complete Service');
						}
					}else{
						if(!consumerServiceRequest.showEngineerVisits && accessFn('REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
							statuses = concat(statuses,'Cancel Service');
						}
					}
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim new workshop and pick up carry in
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if ((claimMergeStatus && claimMergeStatus.pickUpCancel) || (claimMergeStatus && claimMergeStatus.claimRejected)) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (claimMergeStatus.mailInCancel) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					} 
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (claimMergeStatus.carryInCancel) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					}
				}	
			}
			//claim new workshop and pick up carry in
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
						if(showRequestLogs.isRepairCompleted){
							statuses=concat(statuses,'Service cancel')
						}else{
							statuses=concat(statuses,'Service completed');
						}
					}
				}
			}

			//end claim new workshop and pick up carry in
			//pick up carry in
			if(pickupCarryInChecks){
				if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (consumerServiceRequest.ServiceTypeID == 1) {
							if (showRequestLogs.isRepairCompleted) {
								statuses = concat(statuses,'Service completed');
							} else {
								if(consumerServiceRequest.originCenter){
									statuses = concat(statuses, 'Service cancel');
								}
							}
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if([1,17].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if (showRequestLogs.isRepairCancel) {
								statuses = concat(statuses,'Service cancel');
							} else {
								statuses = concat(statuses,'Service completed');
							}
						}
					}
				}
			}
			// end pick up carry in
			return statuses;
		}
    },
	{
        csrStatus: 'Device dispatched', 
		csrlStatus: 'Failed to create Exchange order. Please retry',
		computeNextStatus: function() {
			let statuses = [];
			let repairCancelled = false;
			if (consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.length > 1) {
				let cancelLog = find(consumerServiceRequest.logs, {Status: 'Repair cancel'});
				repairCancelled = (cancelLog ? true : false);
			}
			if( ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
						statuses = concat(statuses, 'Complete Service');
					}
				}
				if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(!repairCancelled){
						if(accessFn( 'REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
							statuses = concat(statuses, 'Complete Service');
						}
					}else{
						if(!consumerServiceRequest.showEngineerVisits && accessFn('REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
							statuses = concat(statuses,'Cancel Service');
						}
					}
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim new workshop and pick up carry in
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if ((claimMergeStatus && claimMergeStatus.pickUpCancel) || (claimMergeStatus && claimMergeStatus.claimRejected)) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (claimMergeStatus.mailInCancel) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					} 
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (claimMergeStatus.carryInCancel) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					}
				}	
			}
			//claim new workshop and pick up carry in
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
						if(showRequestLogs.isRepairCompleted){
							statuses=concat(statuses,'Service cancel')
						}else{
							statuses=concat(statuses,'Service completed');
						}
					}
				}
			}
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Service completed', 'BER device received','BER accessories validated','BER accessories rejected', 'Call attempted');
				}
				
			}
			//ber end 

			//replacement flow
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Replacement delivered');
				}else{
					statuses = concat(statuses,'Service completed');
				}
			}
			//end replacement flow
			if(pickupCarryInChecks){
				if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (consumerServiceRequest.ServiceTypeID == 1) {
							if (showRequestLogs.isRepairCompleted) {
								statuses = concat(statuses,'Service completed');
							} else {
								if(consumerServiceRequest.originCenter){
									statuses = concat(statuses, 'Service cancel');
								}
							}
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if([1,17].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if (showRequestLogs.isRepairCancel) {
								statuses = concat(statuses,'Service cancel');
							} else {
								statuses = concat(statuses,'Service completed');
							}
						}
					}
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Device dispatched', 
		csrlStatus: 'Security deposit released',
		computeNextStatus: function() {
			let statuses = [];
			let repairCancelled = false;
			if (consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.length > 1) {
				let cancelLog = find(consumerServiceRequest.logs, {Status: 'Repair cancel'});
				repairCancelled = (cancelLog ? true : false);
			}
			if( ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
						statuses = concat(statuses, 'Complete Service');
					}
				}
				if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(!repairCancelled){
						if(accessFn( 'REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
							statuses = concat(statuses, 'Complete Service');
						}
					}else{
						if(!consumerServiceRequest.showEngineerVisits && accessFn('REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
							statuses = concat(statuses,'Cancel Service');
						}
					}
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if ((claimMergeStatus && claimMergeStatus.pickUpCancel) || (claimMergeStatus && claimMergeStatus.claimRejected)) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (claimMergeStatus.mailInCancel) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					} 
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (claimMergeStatus.carryInCancel) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					}
				}	
			}
			//claim new workshop and pick up carry in
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
						if(showRequestLogs.isRepairCompleted){
							statuses=concat(statuses,'Service cancel')
						}else{
							statuses=concat(statuses,'Service completed');
						}
					}
				}
			}
			//end claim new workshop and pick up carry in
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Service completed', 'BER device received','BER accessories validated','BER accessories rejected', 'Call attempted');
				}
				
			}
			//ber end 

			//replacement flow
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Replacement delivered');
				}else{
					statuses = concat(statuses,'Service completed');
				}
			}
			//end replacement flow
			if(pickupCarryInChecks){
				if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (consumerServiceRequest.ServiceTypeID == 1) {
							if (showRequestLogs.isRepairCompleted) {
								statuses = concat(statuses,'Service completed');
							} else {
								if(consumerServiceRequest.originCenter){
									statuses = concat(statuses, 'Service cancel');
								}
							}
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if([1,17].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if (showRequestLogs.isRepairCancel) {
								statuses = concat(statuses,'Service cancel');
							} else {
								statuses = concat(statuses,'Service completed');
							}
						}
					}
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Device dispatched', 
		csrlStatus: 'Card-hold charge failed',
		computeNextStatus: function() {
			let statuses = [];
			let repairCancelled = false;
			if (consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.length > 1) {
				let cancelLog = find(consumerServiceRequest.logs, {Status: 'Repair cancel'});
				repairCancelled = (cancelLog ? true : false);
			}
			if( ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
						statuses = concat(statuses, 'Complete Service');
					}
				}
				if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(!repairCancelled){
						if(accessFn( 'REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
							statuses = concat(statuses, 'Complete Service');
						}
					}else{
						if(!consumerServiceRequest.showEngineerVisits && accessFn('REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
							statuses = concat(statuses,'Cancel Service');
						}
					}
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if ((claimMergeStatus && claimMergeStatus.pickUpCancel) || (claimMergeStatus && claimMergeStatus.claimRejected)) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (claimMergeStatus.mailInCancel) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					} 
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (claimMergeStatus.carryInCancel) {
						statuses=concat(statuses, 'Service cancel');
					} else {
						statuses=concat(statuses, 'Service completed');
					}
				}	
			}
			//claim new workshop and pick up carry in
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
						if(showRequestLogs.isRepairCompleted){
							statuses=concat(statuses,'Service cancel')
						}else{
							statuses=concat(statuses,'Service completed');
						}
					}
				}
			}
			//end claim new workshop and pick up carry in
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Service completed', 'BER device received','BER accessories validated','BER accessories rejected', 'Call attempted');
				}
				
			}
			//ber end 

			//replacement flow
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Replacement delivered');
				}else{
					statuses = concat(statuses,'Service completed');
				}
			}
			//end replacement flow
			if(pickupCarryInChecks){
				if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (consumerServiceRequest.ServiceTypeID == 1) {
							if (showRequestLogs.isRepairCompleted) {
								statuses = concat(statuses,'Service completed');
							} else {
								if(consumerServiceRequest.originCenter){
									statuses = concat(statuses, 'Service cancel');
								}
							}
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if([1,17].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if (showRequestLogs.isRepairCancel) {
								statuses = concat(statuses,'Service cancel');
							} else {
								statuses = concat(statuses,'Service completed');
							}
						}
					}
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Payment received', 
		csrlStatus: 'Payment received',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if( ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if( !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){//
						if(accessFn('REQUEST_REASSIGN')){
							if(!accessFn('HIDE_REPAIR_COMPLETE')){
								statuses = concat(statuses,'Complete Repair');
							}
							statuses=concat(statuses,'Complete Without Repair','Cancel Repair');
						}
					}
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (accessFn ('TECHNICIAN_SUPERVISOR')) {
						if (showCompleteServiceFunction() && getSource()!='Bose') {
							statuses = concat(statuses, 'Service completed');
						}
					}
					if (isBoseFlow) {
						if (consumerServiceRequest.InvoiceGeneratedDate) {
							statuses = concat(statuses, 'Visit productive');
						}
					}
				}
			}
			//onsite end
			//claim new 
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
				// pass
				}
			}
			//end claim new
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Service initiated', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Service initiated']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = merge(claimStatuses,['Service initiated', 'Repair cancel', 'Cancel Service Without Dispatch']);
					// }
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim new
			// Pickup carry in 
			if(pickupCarryInGSXChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(consumerServiceRequest.showGenerateInvoiceButton){
						statuses = concat(statuses,'Service invoice generated');
					}else{
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							}else{
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == 2 && consumerServiceRequest.CheckInType != 'Walkin') {
							statuses = concat(statuses,'Intimate customer');
						} else if (consumerServiceRequest.ServiceTypeID == 2 && consumerServiceRequest.CheckInType == 'Walkin') {
							statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
				}
			}
			//end pickup carry in
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Replacement shipment created','Add Shipment Details','Service completed');
			}
			//end replacement flow
			return statuses;
		}
    },
	{
        csrStatus: 'Payment received', 
		csrlStatus: 'Refund Failed',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if( ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if( accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){//
						if(accessFn('REQUEST_REASSIGN')){
							if(!accessFn('HIDE_REPAIR_COMPLETE')){
								statuses = concat(statuses,'Complete Repair');
							}
							statuses=concat(statuses,'Complete Without Repair','Cancel Repair');
						}
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (accessFn ('TECHNICIAN_SUPERVISOR')) {
						if (showCompleteServiceFunction() && getSource()!='Bose') {
							statuses = concat(statuses, 'Service completed');
						}
					}
					if (isBoseFlow) {
						if (consumerServiceRequest.InvoiceGeneratedDate) {
							statuses = concat(statuses, 'Visit productive');
						}
					}
				}
			}
			//onsite end
			//claim new 
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
				// pass
				}
			}
			//end claim new
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Service initiated', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Service initiated']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = merge(claimStatuses,['Service initiated', 'Repair cancel', 'Cancel Service Without Dispatch']);
					// }
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim new
			//pickup carry in
			if(pickupCarryInChecks  && !consumerServiceRequest.consumerProduct.isAppleBrandID){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if(consumerServiceRequest.showGenerateInvoiceButton) {
						statuses = concat('Service invoice generated');
					} else {
						if ([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
							if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request');
							}
						} else if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin') {
							statuses.push('Intimate customer');
						} else if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin') {
							statuses = concat(statuses, 'Intimate customer', 'Service completed');
						} else {
							// pass
						}
					}

				}
			}
			//end pickup carry in
			return statuses;
		}
    },
	{
        csrStatus: 'Payment received', 
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			//claim new 
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
				// pass
				}
			}
			return statuses;
		}
    },{
        csrStatus: 'Payment received', 
		csrlStatus: 'Engineer reassigned',
		computeNextStatus: function() {
			let statuses = [];
			//claim new 
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
				// pass
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(consumerServiceRequest.showGenerateInvoiceButton){
						statuses = concat('Service invoice generated');
					}else{
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							}else{
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin') {
							statuses = concat(statuses, 'Intimate customer');
						} else if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin') {
							statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },{
        csrStatus: 'Payment received', 
		csrlStatus: 'Token released',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(consumerServiceRequest.showGenerateInvoiceButton){
						statuses = concat('Service invoice generated');
					}else{
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							}else{
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin') {
							statuses = concat(statuses, 'Intimate customer');
						} else if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin') {
							statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
				}
			}
			// Pickup carry in end
			//end pickup carry in
			return statuses;
		}
    },
	{
        csrStatus: 'Payment received', 
		csrlStatus: 'Token generated',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(consumerServiceRequest.showGenerateInvoiceButton){
						statuses = concat('Service invoice generated');
					}else{
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							}else{
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin') {
							statuses = concat(statuses, 'Intimate customer');
						} else if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin') {
							statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Payment received', 
		csrlStatus: 'Waiting for Assignment',
		computeNextStatus: function() {
			let statuses = [];
			//claim new 
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
				// pass
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(consumerServiceRequest.showGenerateInvoiceButton){
						statuses = concat('Service invoice generated');
					}else{
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							}else{
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin') {
							statuses = concat(statuses, 'Intimate customer');
						} else if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin') {
							statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },{
        csrStatus: 'Payment received', 
		csrlStatus: 'Payment failed',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if( ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if( !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){//
						if(accessFn('REQUEST_REASSIGN')){
							if(!accessFn('HIDE_REPAIR_COMPLETE')){
								statuses = concat(statuses,'Complete Repair');
							}
							statuses=concat(statuses,'Complete Without Repair','Cancel Repair');
						}
					}
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (accessFn ('TECHNICIAN_SUPERVISOR')) {
						if (showCompleteServiceFunction() && getSource()!='Bose') {
							statuses = concat(statuses, 'Service completed');
						}
					}
					if (isBoseFlow) {
						if (consumerServiceRequest.InvoiceGeneratedDate) {
							statuses = concat(statuses, 'Visit productive');
						}
					}
				}
			}
			//onsite end
			//claim new 
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
				// pass
				}
			}
			//end claim new
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Service initiated', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Service initiated']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = merge(claimStatuses,['Service initiated', 'Repair cancel', 'Cancel Service Without Dispatch']);
					// }
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim new
			// Pickup carry in 
			if(pickupCarryInGSXChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(consumerServiceRequest.showGenerateInvoiceButton){
						statuses = concat(statuses,'Service invoice generated');
					}else{
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							}else{
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == 2 && consumerServiceRequest.CheckInType != 'Walkin') {
							statuses = concat(statuses,'Intimate customer');
						} else if (consumerServiceRequest.ServiceTypeID == 2 && consumerServiceRequest.CheckInType == 'Walkin') {
							statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
				}
			}
			//end pickup carry in
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Replacement shipment created','Add Shipment Details','Service completed');
			}
			//end replacement flow
			return statuses;
		}
    },{
        csrStatus: 'Pending for assignment', 
		csrlStatus: 'Pending for assignment',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){ //onsite get 2 log call buttons
					if(accessFn('REQUEST_APPROVAL')){
						statuses=concat(statuses,'Log Call')
					}
				}

				if( accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if((isRescheduleApplicable || (consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) === -1){
					statuses=concat(statuses,'Reschedule');
				}
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			
			if(consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn('REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}
		
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//----------------------start onsite--------
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
							statuses = concat(statuses, 'Service reschedule');
						}

						if(taskListArr && taskListArr.length !==0){
							if (showCancelButtonFuncton()) { //not done because of opencount issue
							statuses = concat(statuses, 'Service cancel');
							}
						} else {
							if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard','CprDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1 || (qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1) || ([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
								if (accessFn('CANCEL_REQUEST_ANYTIME')) {
									statuses = concat(statuses, 'Service cancel');
								} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
									statuses = concat(statuses, 'Service cancel initiated');
								}
							}
						}
				
					}
					if(['VizioDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1 && (consumerServiceRequest && consumerServiceRequest.DropoffDetails || (!consumerServiceRequest.DropoffDetails && accessPoints.length === 0)) && consumerServiceRequest.shiftParts && consumerServiceRequest.shiftParts.length > 0) {
						statuses = concat(statuses, 'Reschedule requested');
					}

					if(accessFn('CREATE_TASK_ACCESS') && ([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1)){
						statuses = concat(statuses, 'Task Created');
					}

					if(consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_TREAD') > -1){
						statuses = concat(statuses, 'Service completed');
					}

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Device not repairable');
					}

					if(accessFn('CREATE_TASK_ACCESS')){
						statuses = concat(statuses, 'Task Created');
					}
				}
				if(accessFn('READ_ONLY_WORKSHOP') || ([ 'Pending for assignment'].indexOf(consumerServiceRequest.Status) > -1 && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && (!checkDeviceValueAndDateAdded() || !coveredUnderPlanCheck()))) {
					statuses = []
				}
			}
			//---------------------end
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			//end claim new workshop
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
						if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')){
							if (showRequestValid.allowCCToProceedWithService) {
								statuses = concat(statuses, 'Transfer request');
							}
							if(!accessFn('STORE_MANAGEMENT')){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Repair cancel');
								}
							}
						}
						if(accessFn('ACCEPT_RETURN_DEVICE')){
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses = concat(statuses, 'Advance collected');
							}
						}
					}
					else {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Pending for assignment', 
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn('REQUEST_APPROVAL')){
						statuses=concat(statuses,'Log Call')
					}
				}

				if( accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if((isRescheduleApplicable || (consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) === -1){
					statuses=concat(statuses,'Reschedule');
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}
			

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//----------------------start onsite--------
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
							statuses = concat(statuses, 'Service reschedule');
						}

						if(taskListArr && taskListArr.length !==0){
							if (showCancelButtonFuncton()) { //not done because of opencount issue
							statuses = concat(statuses, 'Service cancel');
							}
						} else {
							if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard','CprDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1 || (qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1) || ([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
								if (accessFn('CANCEL_REQUEST_ANYTIME')) {
									statuses = concat(statuses, 'Service cancel');
								} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
									statuses = concat(statuses, 'Service cancel initiated');
								}
							}
						}
					}
					if(['VizioDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1 && (consumerServiceRequest && consumerServiceRequest.DropoffDetails || (!consumerServiceRequest.DropoffDetails && accessPoints.length === 0)) && consumerServiceRequest.shiftParts && consumerServiceRequest.shiftParts.length > 0) {
						statuses = concat(statuses, 'Reschedule requested');
					}

					if(accessFn('CREATE_TASK_ACCESS') && ([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1)){
						statuses = concat(statuses, 'Task Created');
					}

					if(consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_TREAD') > -1){
						statuses = concat(statuses, 'Service completed');
					}

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Device not repairable');
					}

					if(accessFn('CREATE_TASK_ACCESS')){
						statuses = concat(statuses, 'Task Created');
					}
				}
				if(accessFn('READ_ONLY_WORKSHOP') || ([ 'Pending for assignment'].indexOf(consumerServiceRequest.Status) > -1 && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && (!checkDeviceValueAndDateAdded() || !coveredUnderPlanCheck()))) {
					statuses = []
				}
			}
			//---------------------end
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			//end claim new workshop
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
						if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')){
							if (showRequestValid.allowCCToProceedWithService) {
								statuses = concat(statuses, 'Transfer request');
							}
							if(!accessFn('STORE_MANAGEMENT')){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Repair cancel');
								}
							}
						}
						if(accessFn('ACCEPT_RETURN_DEVICE')){
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses = concat(statuses, 'Advance collected');
							}
						}
					}
					else {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Pending for assignment', 
		csrlStatus: 'Token released',
		computeNextStatus: function() {
			let statuses = [];
			if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
				if(accessFn('REQUEST_APPROVAL')){
					statuses=concat(statuses,'Log Call')
				}
			}
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			//end claim new workshop
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
						if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')){
							if (showRequestValid.allowCCToProceedWithService) {
								statuses = concat(statuses, 'Transfer request');
							}
							if(!accessFn('STORE_MANAGEMENT')){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Repair cancel');
								}
							}
						}
						if(accessFn('ACCEPT_RETURN_DEVICE')){
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses = concat(statuses, 'Advance collected');
							}
						}
					}
					else {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Pending for assignment', 
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			//claim new workshop
			if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
				if(accessFn('REQUEST_APPROVAL')){
					statuses=concat(statuses,'Log Call')
				}
			}
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			//end claim new workshop
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
						if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')){
							if (showRequestValid.allowCCToProceedWithService) {
								statuses = concat(statuses, 'Transfer request');
							}
							if(!accessFn('STORE_MANAGEMENT')){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Repair cancel');
								}
							}
						}
						if(accessFn('ACCEPT_RETURN_DEVICE')){
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses = concat(statuses, 'Advance collected');
							}
						}
					}
					else {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Pending for assignment', 
		csrlStatus: 'Request waiting for assignment', //logs are different
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn('REQUEST_APPROVAL')){
						statuses=concat(statuses,'Log Call')
					}
				}

				if( accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if((isRescheduleApplicable || (consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) === -1){
					statuses=concat(statuses,'Reschedule');
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//----------------------start--------
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
							statuses = concat(statuses, 'Service reschedule');
						}
						if(taskListArr && taskListArr.length !==0){
							if (showCancelButtonFuncton()) { 
							statuses = concat(statuses, 'Service cancel');
							}
						} else {
							if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard','CprDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1 || (qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1) || ([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
								if (accessFn('CANCEL_REQUEST_ANYTIME')) {
									statuses = concat(statuses, 'Service cancel');
								} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
									statuses = concat(statuses, 'Service cancel initiated');
								}
							}
						}
				
					}
					if(['VizioDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1 && (consumerServiceRequest && consumerServiceRequest.DropoffDetails || (!consumerServiceRequest.DropoffDetails && accessPoints.length === 0)) && consumerServiceRequest.shiftParts && consumerServiceRequest.shiftParts.length > 0) {
						statuses = concat(statuses, 'Reschedule requested');
					}

					if(accessFn('CREATE_TASK_ACCESS') && ([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1)){
						statuses = concat(statuses, 'Task Created');
					}

					if(consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_TREAD') > -1){
						statuses = concat(statuses, 'Service completed');
					}

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Device not repairable');
					}

					if(accessFn('CREATE_TASK_ACCESS')){
						statuses = concat(statuses, 'Task Created');
					}
				}
				if(accessFn('READ_ONLY_WORKSHOP') || ([ 'Pending for assignment'].indexOf(consumerServiceRequest.Status) > -1 && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && (!checkDeviceValueAndDateAdded() || !coveredUnderPlanCheck()))) {
					statuses = []
				}
			}
			//---------------------end
			return statuses;
		}
    },
	{
        csrStatus: 'Pending for assignment', 
		csrlStatus: 'Token released',
		computeNextStatus: function() {
			let statuses = [];
			//claim new workshop
			if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
				if(accessFn('REQUEST_APPROVAL')){
					statuses=concat(statuses,'Log Call')
				}
			}
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			//end claim new workshop
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
						if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')){
							if (showRequestValid.allowCCToProceedWithService) {
								statuses = concat(statuses, 'Transfer request');
							}
							if(!accessFn('STORE_MANAGEMENT')){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Repair cancel');
								}
							}
						}
						if(accessFn('ACCEPT_RETURN_DEVICE')){
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses = concat(statuses, 'Advance collected');
							}
						}
					}
					else {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
		csrStatus: 'Pending for assignment',
		csrlStatus: 'Diagnosis initiated',
		computeNextStatus: function () {
			let statuses = [];
			let suitesStatuses = [];
			//pick up carry in gsx
			if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
				if(accessFn('REQUEST_APPROVAL')){
					statuses=concat(statuses,'Log Call')
				}
			}
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if ((qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter) && accessFn('TRANSFER_REQUEST')) {
							statuses = concat(statuses, 'Transfer request');
						}
						if (!accessFn('STORE_MANAGEMENT')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses,'Cannot be diagnosed', 'Diagnosis completed');
								}else{
									statuses = concat(statuses, 'Initiate AST 2 Diagnostics','Cannot be diagnosed', 'Diagnosis completed');
								}
								if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
									suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
									statuses = concat(statuses, suitesStatuses)
								}else if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.gsxInfo && consumerServiceRequest.consumerProduct.gsxInfo.serialNumber && (!arrDiagnosisSuites || (arrDiagnosisSuites && !arrDiagnosisSuites.length)) && !diagnosticsSuitesCalled) {
									if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
										suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
										statuses = concat(statuses, suitesStatuses)
									}
								}
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses.push('Advance collected')
						}
					}
				}
			}
			//end pick up carry in gsx
			return statuses;
		}
	},
	{
		csrStatus: 'Pending for assignment',
		csrlStatus: 'Diagnosis completed',
		computeNextStatus: function () {
			let statuses = [];
			//pick up carry in gsx
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if ((qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter) && accessFn('TRANSFER_REQUEST')) {
							statuses = concat(statuses, 'Transfer request');
						}
						if (!accessFn('STORE_MANAGEMENT')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses.push('Advance collected')
						}
					}
				}
			}
			// end pick up carry in gsx
			return statuses;
		}
	},
	{
		csrStatus: 'Pending for assignment',
		csrlStatus: 'Advance collected',
		computeNextStatus: function () {
			let statuses = [];
			//pick up carry in gsx
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if ((qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter) && accessFn('TRANSFER_REQUEST')) {
							statuses = concat(statuses, 'Transfer request');
						}
						if (!accessFn('STORE_MANAGEMENT')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics');
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses.push('Advance collected')
						}
					}
				}
			}
			//end pick up carry in gsx
			return statuses;
		}
	},
	{
		csrStatus: 'Pending for assignment',
		csrlStatus: 'Cannot be diagnosed',
		computeNextStatus: function () {
			let statuses = [];
			//pick up carry in gsx
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if ((qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter) && accessFn('TRANSFER_REQUEST')) {
							statuses = concat(statuses, 'Transfer request');
						}
						if (!accessFn('STORE_MANAGEMENT')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses.push('Advance collected')
						}
					}
				}
			}
			//end pick up carry in gsx
			return statuses;
		}
	},
	{
        csrStatus: 'Pending for approval', 
		csrlStatus: 'Pending for approval',
		computeNextStatus: function() { 
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn('REQUEST_APPROVAL')){
						statuses = concat(statuses,'Log Call'); //reassign
					}
					// if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
					// 	statuses = concat(statuses,'Reassign');
					// }	
				}
				if([SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign');
					}
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}	
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}	
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}

			if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//----onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
							statuses = concat(statuses, 'Service reschedule');
						}
						if(taskListArr.length !==0){   //taskList not done
							if (showCancelButtonFuncton()) {
								statuses = concat(statuses, 'Service cancel');
							}
						}
						else {
							if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 || (qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1)){
								if (accessFn('CANCEL_REQUEST_ANYTIME')) {
									statuses = concat(statuses, 'Service cancel');
								} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
									statuses = concat(statuses, 'Service cancel initiated');
								}
							}
						}
					}
				}
			}
			//----onsite end
			return statuses;
		}
    },
	{
        csrStatus: 'Consumer arrived', 
		csrlStatus: 'Consumer arrived',
		computeNextStatus: function() { 
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') ){
						statuses = concat(statuses,'Start Repair');
					}
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Serviceman journey initiated', 
		csrlStatus: 'Serviceman journey initiated',
		computeNextStatus: function() { 
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn('REQUEST_APPROVAL')){
						statuses = concat(statuses,'Log Call','Serviceman Reached');
					}
				}	
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('TECHNICIAN_SUPERVISOR') && !(['CprDashboard','BoseDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1) && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)) {
							statuses = concat(statuses, 'Job updated');
						}
						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
							statuses = concat(statuses, 'Technician reached');
						}
					}
				}
			}
			//onsite end
			return statuses;
		}
    },
	{
        csrStatus: 'Serviceman reached', 
		csrlStatus: 'Serviceman reached',
		computeNextStatus: function() { 
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
						statuses=concat(statuses,'Set Schedule');
					}
					if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
						statuses = concat(statuses,'Reschedule');
					}	
					if( accessFn('REQUEST_APPROVAL') ){
						if([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							statuses = concat(statuses,'Initiate Service');
						}
						statuses= concat(statuses,'Log Call');
					}	
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('TECHNICIAN_SUPERVISOR') && !(['CprDashboard','BoseDashboard'].indexOf(consumerServiceRequest.Source) > -1) && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)){
						statuses = concat(statuses, 'Job updated');
						}

						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
							if(buttonLogStatus.estimationApproved) {
								statuses = concat(statuses, 'Visit productive','Visit unproductive');
							}
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
						}
					} 
				}
			}
			//onsite end
			return statuses;
		}
    },
	{
        csrStatus: 'Serviceman reached', 
		csrlStatus: 'Device details validated',
		computeNextStatus: function() { 
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if( accessFn('REQUEST_APPROVAL') ){
						if([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							statuses = concat(statuses,'Initiate Service');
						}
						statuses= concat(statuses,'Log Call');
					}	
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('TECHNICIAN_SUPERVISOR') && !(['CprDashboard','BoseDashboard'].indexOf(consumerServiceRequest.Source) > -1) && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)){
						statuses = concat(statuses, 'Job updated');
						}

						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
							if(buttonLogStatus.estimationApproved) {
								statuses = concat(statuses, 'Visit productive');
								statuses = concat(statuses, 'Visit unproductive');
							}

							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
						}
					} 
				}
			}
			//onsite end
			return statuses;
		}
    },
	{
        csrStatus: 'Estimation rejected', 
		csrlStatus: 'Estimation rejected',
		computeNextStatus: function() { 
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(!consumerServiceRequest.showEngineerVisits && !accessFn('READ_ONLY') &&  accessFn('REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() ){
						statuses=concat(statuses,'Cancel Service')
					}
				}
				if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(!accessFn('READ_ONLY') && ([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID)> -1)  && accessFn('REQUEST_REASSIGN')){//
						statuses=concat(statuses,'Cancel Repair')
					}
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}	
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
							if(deviceAtLoggedInLoc && consumerServiceRequest.CREATED_AT_DROPOFF_PSL !== "true"){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
					}
					else {
							statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Estimation rejected', 
		csrlStatus: 'Engineer reassigned',
		computeNextStatus: function() { 
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
							if(deviceAtLoggedInLoc && consumerServiceRequest.CREATED_AT_DROPOFF_PSL !== "true"){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
					}
					else {
							statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Estimation rejected', 
		csrlStatus: 'Device details validated',
		computeNextStatus: function() { 
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
							if(deviceAtLoggedInLoc && consumerServiceRequest.CREATED_AT_DROPOFF_PSL !== "true"){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
					}
					else {
							statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Estimation rejected', 
		csrlStatus: 'Device accepted',
		computeNextStatus: function() { 
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
							if(deviceAtLoggedInLoc && consumerServiceRequest.CREATED_AT_DROPOFF_PSL !== "true"){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
					}
					else {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						}
					}
				}
			}
			// Pickup carry in end

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Estimation rejected', 
		csrlStatus: 'Exception rejected',
		computeNextStatus: function() { 
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Estimation approval', 
		csrlStatus: 'Exception rejected',
		computeNextStatus: function() { 
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Estimation generated', 
		csrlStatus: 'Exception rejected',
		computeNextStatus: function() { 
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Parts pending', 
		csrlStatus: 'Parts pending',
		computeNextStatus: function() { 
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(!accessFn('READ_ONLY')){
						if(!consumerServiceRequest.showEngineerVisits  && accessFn('REQUEST_REASSIGN') && warrantyAppicableNonClaimCheck()){
							statuses=concat(statuses,'Cancel Service');
						}
						statuses=concat(statuses,'Parts received');
					}
				}
				
				if([SERVICETYPE.PICK_UP ,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(!accessFn('READ_ONLY')){
						if(([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REQUEST_REASSIGN')){
							statuses=concat(statuses,'Cancel Repair');
						}
						statuses=concat(statuses,'Parts received');
					}
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
							if (partsRequested && partsRequested.length > 0 ) {
									setIsEachPartReceived(true);
								for (let iCount = 0;iCount<partsRequested.length;iCount ++ ) {
									if (['received','rejected','orderRejected','returned','doa','cancelled','alternatePartIssued'].indexOf(partsRequested[iCount].statusDetails.Status) > -1 ) {

									} else {
										setIsEachPartReceived(false);
									}
								}
								if (isEachPartReceived) {
								statuses = concat(statuses, 'Service reschedule');
								}
							} else {
								statuses = concat(statuses, 'Service reschedule');
							}
						}
						
						if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 || (qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1)){
							if (accessFn('CANCEL_REQUEST_ANYTIME') && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
								statuses = concat(statuses, 'Service cancel');
							} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
								statuses = concat(statuses, 'Service cancel initiated');
							}
						}
					}

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Parts received');
					}
				}
			}
			//onsite end
			//claim 
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = concat(claimStatuses, 'Parts received', 'Service cancel');
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = concat(claimStatuses, 'Parts received', 'Service cancel');
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = concat(claimStatuses,'Parts received', 'Repair cancel', 'Service cancel');
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			//refurbishment flow
			if(refurbishmentFlowCheck){
				statuses = concat(statuses,'Parts received')
			}
			//refurbishment flow end
			return statuses;
		}
    },

	{
        csrStatus: 'Repair cancel', 
		csrlStatus: 'Repair cancel',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
						if(consumerServiceRequest && consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
							if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
									statuses = concat(statuses,'Dispatch Device',);
							}
						} 
						if(!(consumerServiceRequest && consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled)){
							if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
								statuses = concat(statuses,'Initiate logistics request');
								if(!consumerServiceRequest.showEngineerVisits && warrantyAppicableNonClaimCheck()){
									statuses = concat(statuses,'Cancel Service');
								}
							} 
							
						}
				}
				if(SERVICETYPE.CARRY_IN == consumerServiceRequest.ServiceTypeID){
					statuses = concat(statuses,'Cancel Service');
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim 
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if(accessFn('ADD_NORMAL_CHARGES')){
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses,['Device dispatched']);
						// }
					}
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses =merge(claimStatuses, ['Service cancel']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses =merge(claimStatuses, ['Device dispatched']);
						// }
					}
				}
				if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Device dispatched'];
					}
				}else{
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Initiate logistics request','Service cancel'];
					}
				}
				if([SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
					if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
						claimStatuses = ['Device dispatched','Service cancel']
					}else{
						claimStatuses = ['Initiate logistics request','Add Shipment Details','Device dispatched','Service cancel']
					}
				}
				if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1 && [SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
					if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
						claimStatuses=['Device dispatched'];
					}else{
						claimStatuses=['Initiate logistics request', 'Service cancel'];
					}
				}
				
				
				statuses = concat(statuses,claimStatuses);
			}
			
			///----------

			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 ) {
						if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
							statuses = concat(statuses, 'Device dispatched');
						} else {
							statuses = concat(statuses, 'Initiate logistics request');
						}
					} else {
						statuses = concat(statuses, 'Service cancel');
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {

						if (accessFn('TRANSFER_REQUEST') && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) > -1 && !consumerServiceRequest.originCenter) {
							statuses = concat(statuses, 'Transfer request');
						}
				
						if (showRequestValid.allowCCToProceedWithService) { //transfer request
							// statuses = concat(statuses, 'Transfer request');
							if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
								if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
									statuses = concat(statuses, 'Device dispatched');
								}else {
									statuses = concat(statuses, 'Initiate logistics request');
								}
							}else{
								if(consumerServiceRequest.originCenter){
									statuses = concat(statuses, 'Service cancel');
								}
							}
						}
					}
				}else{
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							}else{
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else{
						  statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			// Pickup carry in end			
			return statuses;
		}
    },
	{
        csrStatus: 'Repair cancel', 
		csrlStatus: 'Shipment created',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
						if(consumerServiceRequest && consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
							if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
									statuses = concat(statuses,'Dispatch Device',);
							}
						} 
						if(!(consumerServiceRequest && consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled)){
							if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
								statuses = concat(statuses,'Initiate logistics request');
								if(!consumerServiceRequest.showEngineerVisits && warrantyAppicableNonClaimCheck()){
									statuses = concat(statuses,'Cancel Service');
								}
							} 
							
						}
				}
			}
		
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim 
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if(accessFn('ADD_NORMAL_CHARGES')){
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses,['Device dispatched']);
						// }
					}
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = merge(claimStatuses, ['Service cancel']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Device dispatched']);
						// }
					}
				}
				if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Device dispatched'];
					}
				}else{
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Initiate logistics request','Service cancel'];
					}
				}
				if([SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
					if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
							claimStatuses = ['Device dispatched','Service cancel']
						}else{
							claimStatuses = ['Initiate logistics request','Add Shipment Details','Device dispatched','Service cancel']
						}				
					}
				if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1 && [SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
					if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
						claimStatuses=['Device dispatched'];
					}else{
						claimStatuses=['Initiate logistics request', 'Service cancel'];
					}
				}
				statuses = concat(statuses,claimStatuses);
			}

			//-----
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 ) {
						if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
							statuses = concat(statuses, 'Device dispatched');
						} else {
							statuses = concat(statuses, 'Initiate logistics request');
						}
					} else {
						statuses = concat(statuses, 'Service cancel');
					}
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Repair cancel', 
		csrlStatus: 'Token released',
		computeNextStatus: function() {
			let statuses = [];
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 ) {
						if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
							statuses = concat(statuses, 'Device dispatched');
						} else {
							statuses = concat(statuses, 'Initiate logistics request');
						}
					} else {
						statuses = concat(statuses, 'Service cancel');
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						// if (showRequestValid.allowCCToProceedWithService) { //transfer request
							// statuses = concat(statuses, 'Transfer request');
							if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
								if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
									statuses = concat(statuses, 'Device dispatched');
								}else {
									statuses = concat(statuses, 'Initiate logistics request');
								}
							}else{
								if(consumerServiceRequest.originCenter){
									statuses = concat(statuses, 'Service cancel');
								}
							}
						// }
					}
				}else{
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							}else{
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else{
							statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Repair cancel', 
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			//claim new workshop
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW')){
				if (accessFn('ACCEPT_RETURN_DEVICE') && !accessFn('GSX_FLOW')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 ) {
						if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
							statuses = concat(statuses, 'Device dispatched');
						} else {
							statuses = concat(statuses, 'Initiate logistics request');
						}
					} else {
						statuses = concat(statuses, 'Service cancel');
					}
				}
			}
			return statuses;
		}
    },
	{
		csrStatus: 'Repair cancel',
		csrlStatus: 'Received at collection point',
		computeNextStatus: function () {
			let statuses = [];
			//pick up carry in gsx
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (accessFn('TRANSFER_REQUEST')) { //transfer request
							if (qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) > -1 && !consumerServiceRequest.originCenter) {
								statuses = concat(statuses, 'Transfer request');
							}
						}
						
						if (showRequestValid.allowCCToProceedWithService) {
							if (consumerServiceRequest.ServiceTypeID == 1) {
								if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
									statuses = concat(statuses, 'Device dispatched');
								} else {
									statuses = concat(statuses, 'Initiate logistics request');
								}
							} else {
								if(consumerServiceRequest.originCenter){
									statuses = concat(statuses, 'Service cancel');
								}
							}
						}
					}
				}
			}
			
			//end pick up carry in gsx
			return statuses;
		}
	},
	{
		csrStatus: 'Repair cancel',
		csrlStatus: 'Sent to collection point',
		computeNextStatus: function() {
			let statuses = [];
			//pick up carry in gsx
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if(qFilter && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) > -1) {
							statuses = concat(statuses, 'Receive device from Repair Center');
						}
					}
				}
			}		
			//end pick up carry in gsx
			return statuses;
		}
	},
	{
		csrStatus: 'Repair cancel',
		csrlStatus: 'Waiting for Assignment',
		computeNextStatus: function () {
			let statuses = [];
			//pick up carry in gsx
			if (accessFn('ACCEPT_RETURN_DEVICE')) {
				if (consumerServiceRequest.ServiceTypeID == 1) {
					if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
						statuses = concat(statuses, 'Device dispatched');
					} else {
						statuses = concat(statuses, 'Initiate logistics request');
					}

				} else {
					if(consumerServiceRequest.originCenter){
						statuses = concat(statuses, 'Service cancel');
					}
				}
			}
			//end pick up carry in gsx
			return statuses;
		}
	},   
	{
        csrStatus: 'No engineer assigned', 
		csrlStatus: 'No engineer assigned',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') &&  !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}
			

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Temp request confirmed', 
		csrlStatus: 'Temp request confirmed',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){//
				statuses=concat(statuses,'Reschedule');
			}
			
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Service suspended', 
		csrlStatus: 'Service suspended',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Reassign')
					}
				}
				if(accessFn('REQUEST_CANCEL') && !accessFn('READ_ONLY')){
					statuses = concat(statuses,'Cancel Request');
				}
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
					statuses = concat(statuses,'Reschedule');
				}
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
				statuses=concat(statuses,'Reschedule');
			}
		
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()){
						if (accessFn('CANCEL_REQUEST_ANYTIME') && ["VizioDashboard"].indexOf(consumerServiceRequest && consumerServiceRequest.Source)>-1) {
							statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			//onsite end
			return statuses;
		}
    },
	{
        csrStatus: 'Agent assigned', 
		csrlStatus: 'Agent assigned',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Agent arrived', 
		csrlStatus: 'Agent arrived',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Estimation generated', 
		csrlStatus: 'Estimation generated',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			if (accessFn('GSX_FLOW') && isSupOrEngg() && consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !partsRequested.length && consumerServiceRequest.ProvisionalGSXNumber && find(consumerServiceRequest.logs,{Status: 'Estimation rejected'})){
				statuses = concat(statuses, 'Request raised');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Estimation approval', 
		csrlStatus: 'Estimation approval',
		computeNextStatus: function() {
			let statuses = [];
			if(accessFn('OVERRIDE_ESTIMATION_BUTTON')){
				statuses = concat(statuses,'Approve Estimation','Reject Estimation')
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if(!appleDropOffFlow || accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')) {
							statuses = concat(statuses, 'Estimation rejected');
						}
					}else {
						if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) {
							statuses = concat(statuses, 'Estimation rejected', 'Cancel Service Without Dispatch');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Estimation approval', 
		csrlStatus: 'Engineer reassigned',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
							statuses = concat(statuses, 'Estimation rejected');
					}
					else {
						if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) {
							statuses = concat(statuses, 'Estimation rejected', 'Cancel Service Without Dispatch');
						}
					}
				}
			}
			// Pickup carry in end
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Estimation approval', 
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
							statuses = concat(statuses, 'Estimation rejected');
					}
					else {
						if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) {
							statuses = concat(statuses, 'Estimation rejected', 'Cancel Service Without Dispatch');
						}
					}
				}
			}
			// Pickup carry in end
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Estimation approval', 
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
							statuses = concat(statuses, 'Estimation rejected');
					}
					else {
						if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) {
							statuses = concat(statuses, 'Estimation rejected', 'Cancel Service Without Dispatch');
						}
					}
				}
			}
			// Pickup carry in end
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	
	{
        csrStatus: 'In-principle approved', 
		csrlStatus: 'In-principle approved',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if( (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM)  && !isSubscriptionEnabled &&  checkDecisionMatrixProcessCompleted() &&  !checkPaymentStatus() ){
					statuses = concat(statuses,'Resend Payment Link');
				if(accessFn('REQUEST_CANCEL')){
					statuses = concat(statuses,'Cancel Request');
				}
			}
			
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim new
			if(claimNewWorkshopChecks){
				statuses = concat(statuses,'Service cancel')//,'Convert request',
				if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					statuses = concat(statuses, 'Service completed');
				}
			}
			// end claim new
			
			// claim 
			if(claimChecks){
				// claimStatuses = concat(claimStatuses,'Service cancel');
				if (accessFn('CLAIM_APPROVAL')) {
					if([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = concat(claimStatuses,'Service completed' );
					}
					
				}
				// claimStatuses = ['Service cancel'];
				if ([38].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					claimStatuses = merge(claimStatuses, ['Service completed']);
				}
				if(accessFn('CONVERT_CLAIM_REQUEST') && SERVICETYPE.CLAIM === consumerServiceRequest.ServiceTypeID) {
						// claimStatuses = ['Convert request'];
					if (claimMergeStatus.pastForteenDays) {
						claimStatuses = concat(claimStatuses,'Service cancel')
						// claimStatuses = claimStatuses.push('Service cancel')
					}
				}
				else {
					if (claimMergeStatus.pastForteenDays) {
						claimStatuses = ['Service cancel'];
					}
				}
				//decision matrix
				if(consumerServiceRequest && consumerServiceRequest.decisionMatrix && checkDeviceValueAndDateAdded()){
					if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses =concat (claimStatuses,'Decision form', 'Select outcome', 'Skip form') ;
					}
					if((consumerServiceRequest.DecisionMatrixModes || consumerServiceRequest.SkipDecisionForm)) {
						claimStatuses = pullAll(claimStatuses,['Decision form', 'Skip form']);
					}
					if( !accessFn('SELECT_DECISION_OUTCOME_MANUAL')) {
						claimStatuses = pull(claimStatuses, 'Select outcome');
					} else if(accessFn('SELECT_DECISION_OUTCOME_MANUAL') && ((!consumerServiceRequest.SkipDecisionForm) || (consumerServiceRequest.SkipDecisionForm && consumerServiceRequest.DecisionMatrixModes))) {
						claimStatuses = pull(claimStatuses,'Select outcome');
					}
				}
				// end decision matrix
				// claimStatuses = concat(claimStatuses,'Service cancel');
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
				
				
			return statuses;
		}
    },
	{
        csrStatus: 'In-principle approved', 
		csrlStatus: 'Claim initiated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if( (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM)  && !isSubscriptionEnabled &&  checkDecisionMatrixProcessCompleted() &&  !checkPaymentStatus() ){
					statuses = concat(statuses,'Resend Payment Link');
				if(accessFn('REQUEST_CANCEL')){
					statuses = concat(statuses,'Cancel Request');
				}
			}
			
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			// claim 
			if(claimChecks){
				// claimStatuses = concat(claimStatuses,'Service cancel');
				if (accessFn('CLAIM_APPROVAL')) {
					if([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = concat(claimStatuses,'Service completed' );
					}
				}
				// claimStatuses = ['Service cancel'];
				if ([38].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					claimStatuses = merge(claimStatuses, ['Service completed']);
				}
				if(accessFn('CONVERT_CLAIM_REQUEST') && SERVICETYPE.CLAIM === consumerServiceRequest.ServiceTypeID) {
						// claimStatuses = ['Convert request'];
					if (claimMergeStatus.pastForteenDays) {
						claimStatuses = concat(claimStatuses,'Service cancel')
					}
				}
				else {
					if (claimMergeStatus.pastForteenDays) {
						claimStatuses = ['Service cancel'];
					}
				}
				//decision matrix
				if(consumerServiceRequest && consumerServiceRequest.decisionMatrix && checkDeviceValueAndDateAdded()){
					if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses =concat (claimStatuses,'Decision form', 'Select outcome', 'Skip form') ;
					}
					if((consumerServiceRequest.DecisionMatrixModes || consumerServiceRequest.SkipDecisionForm)) {
						claimStatuses = pullAll(claimStatuses,['Decision form', 'Skip form']);
					}
					if( !accessFn('SELECT_DECISION_OUTCOME_MANUAL')) {
						claimStatuses = pull(claimStatuses, 'Select outcome');
					} else if(accessFn('SELECT_DECISION_OUTCOME_MANUAL') && ((!consumerServiceRequest.SkipDecisionForm) || (consumerServiceRequest.SkipDecisionForm && consumerServiceRequest.DecisionMatrixModes))) {
						claimStatuses = pull(claimStatuses,'Select outcome');
					}
				}
				// end decision matrix
				// claimStatuses = concat(claimStatuses,'Service cancel');
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
			//end claim
			return statuses;
		}
    },
	{
        csrStatus: 'In-principle approved', 
		csrlStatus: 'Pending for decision outcome',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if( (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM) && !isSubscriptionEnabled && checkDecisionMatrixProcessCompleted() &&  !checkPaymentStatus() ){
					statuses = concat(statuses,'Resend Payment Link');
				if(accessFn('REQUEST_CANCEL')){
					statuses = concat(statuses,'Cancel Request');
				}
			}
			
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			
			// claim 
			if(claimChecks){
				// claimStatuses = concat(claimStatuses,'Service cancel');
				if (accessFn('CLAIM_APPROVAL')) {
					if([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = concat(claimStatuses,'Service completed' );
					}
				}
				// claimStatuses = ['Service cancel'];
				if ([38].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					claimStatuses = merge(claimStatuses, ['Service completed']);
				}
				if(accessFn('CONVERT_CLAIM_REQUEST') && SERVICETYPE.CLAIM === consumerServiceRequest.ServiceTypeID) {
						// claimStatuses = ['Convert request'];
					if (claimMergeStatus.pastForteenDays) {
						claimStatuses = concat(claimStatuses,'Service cancel')
					}
				}
				else {
					if (claimMergeStatus.pastForteenDays) {
						claimStatuses = ['Service cancel'];
					}
				}
				//decision matrix
				if(consumerServiceRequest && consumerServiceRequest.decisionMatrix && checkDeviceValueAndDateAdded()){
					if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses =concat (claimStatuses,'Decision form', 'Select outcome', 'Skip form') ;
					}
					if((consumerServiceRequest.DecisionMatrixModes || consumerServiceRequest.SkipDecisionForm)) {
						claimStatuses = pullAll(claimStatuses,['Decision form', 'Skip form']);
					}
					if( !accessFn('SELECT_DECISION_OUTCOME_MANUAL')) {
						claimStatuses = pull(claimStatuses, 'Select outcome');
					} else if(accessFn('SELECT_DECISION_OUTCOME_MANUAL') && ((!consumerServiceRequest.SkipDecisionForm) || (consumerServiceRequest.SkipDecisionForm && consumerServiceRequest.DecisionMatrixModes))) {
						claimStatuses = pull(claimStatuses,'Select outcome');
					}
				}
				// end decision matrix
				// claimStatuses = concat(claimStatuses,'Service cancel');
				statuses = concat(statuses,claimStatuses);
			}
			//end claim			
			return statuses;
		}
    },
	{
        csrStatus: 'In-principle approved', 
		csrlStatus: 'Advance collected',
		computeNextStatus: function() {
			let statuses = [];
			if( (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM) && !isSubscriptionEnabled &&  checkDecisionMatrixProcessCompleted() &&  !checkPaymentStatus() ){
				statuses = concat(statuses,'Resend Payment Link');
				if(accessFn('REQUEST_CANCEL')){
					statuses = concat(statuses,'Cancel Request');
				}
			}
			
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'In-principle approved', 
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			if( (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM)  && !isSubscriptionEnabled &&  checkDecisionMatrixProcessCompleted() &&  !checkPaymentStatus() ){
				statuses = concat(statuses,'Resend Payment Link');
				if(accessFn('REQUEST_CANCEL')){
					statuses = concat(statuses,'Cancel Request');
				}
			}
			
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim new
			if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && accessFn('WORKSHOP_FLOW')){
			
				statuses = concat(statuses,'Service cancel','Convert request',)
				if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					statuses = concat(statuses, 'Service completed');
				}
			}
			// end claim new
			return statuses;
		}
    },
	{
        csrStatus: 'In-principle approved', 
		csrlStatus: 'Decision Taken, ready for scheduling',
		computeNextStatus: function() {
			let statuses = [];
			if((consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 10) && !isSubscriptionEnabled && !checkPaymentStatus() && checkDecisionMatrixProcessCompleted() ){//
				statuses = concat(statuses,'Resend Payment Link');
				if(accessFn('REQUEST_CANCEL')){
					statuses = concat(statuses,'Cancel Request');
				}
			}

			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'In-principle approved', 
		csrlStatus: 'Payment failed',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if( (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM)  && !isSubscriptionEnabled &&  checkDecisionMatrixProcessCompleted() &&  !checkPaymentStatus() ){
					statuses = concat(statuses,'Resend Payment Link');
				if(accessFn('REQUEST_CANCEL')){
					statuses = concat(statuses,'Cancel Request');
				}
			}
			
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim new
			if(claimNewWorkshopChecks){
				statuses = concat(statuses,'Service cancel')//,'Convert request',
				if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					statuses = concat(statuses, 'Service completed');
				}
			}
			// end claim new
			
			// claim 
			if(claimChecks){
				// claimStatuses = concat(claimStatuses,'Service cancel');
				if (accessFn('CLAIM_APPROVAL')) {
					if([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = concat(claimStatuses,'Service completed' );
					}
					
				}
				// claimStatuses = ['Service cancel'];
				if ([38].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					claimStatuses = merge(claimStatuses, ['Service completed']);
				}
				if(accessFn('CONVERT_CLAIM_REQUEST') && SERVICETYPE.CLAIM === consumerServiceRequest.ServiceTypeID) {
						// claimStatuses = ['Convert request'];
					if (claimMergeStatus.pastForteenDays) {
						claimStatuses = concat(claimStatuses,'Service cancel')
						// claimStatuses = claimStatuses.push('Service cancel')
					}
				}
				else {
					if (claimMergeStatus.pastForteenDays) {
						claimStatuses = ['Service cancel'];
					}
				}
				//decision matrix
				if(consumerServiceRequest && consumerServiceRequest.decisionMatrix && checkDeviceValueAndDateAdded()){
					if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses =concat (claimStatuses,'Decision form', 'Select outcome', 'Skip form') ;
					}
					if((consumerServiceRequest.DecisionMatrixModes || consumerServiceRequest.SkipDecisionForm)) {
						claimStatuses = pullAll(claimStatuses,['Decision form', 'Skip form']);
					}
					if( !accessFn('SELECT_DECISION_OUTCOME_MANUAL')) {
						claimStatuses = pull(claimStatuses, 'Select outcome');
					} else if(accessFn('SELECT_DECISION_OUTCOME_MANUAL') && ((!consumerServiceRequest.SkipDecisionForm) || (consumerServiceRequest.SkipDecisionForm && consumerServiceRequest.DecisionMatrixModes))) {
						claimStatuses = pull(claimStatuses,'Select outcome');
					}
				}
				// end decision matrix
				// claimStatuses = concat(claimStatuses,'Service cancel');
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
				
				
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Work in progress',
		computeNextStatus: function() {
			let statuses = [];
			let suitesStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn('REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if(SERVICETYPE.ON_SITE){
					if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
						statuses = concat(statuses,'Reschedule');
					}
				}
			}

			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}

			if(SERVICETYPE.ON_SITE){
				if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Reschedule');
				}
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}  

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						statuses = concat(statuses, 'Device not repairable');

						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}
				}
			}
			//onsite end

			//claim new workshop
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
						if(isQCRejectedLog()){
							if(!isDiagnosisInitiated()){
								statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
							}
								statuses.push('Repair completed');
						}
						// buttons when device is with engineer
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			
			//end claim new workshop
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if(isHandedToEng()){
							if(isQCRejectedLog()){
								if ((!showRequestLogs.diagnosisIntiateByEngg || (showRequestLogs.diagnosisIntiateByEngg && !showRequestLogs.diagnosisCompletedByEngg)) && consumerServiceRequest.isVerifiedProduct) {
									if(consumerServiceRequest.backupRequired && !consumerServiceRequest.backupTaken){
										statuses = concat(statuses, 'Take Backup');
									}else{
										if(showRequestLogs.diagnosisIntiateByEngg){
											if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
												statuses = concat(statuses,'Cannot be diagnosed');
											}else{
												statuses = concat(statuses, 'Initiate AST 2 Diagnostics','Cannot be diagnosed');
											}
										}else{
											statuses = concat(statuses, 'Initiate AST 2 Diagnostics');
										}
									}
									if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
										suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
										statuses = concat(statuses, suitesStatuses)
									}
								}
		
								if(!consumerServiceRequest.isVerifiedProduct){
									statuses = concat(statuses, 'Validate from GSX');
								}
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
										statuses = concat(statuses, 'Update KGB');
									}else{
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
							if(isDOAEligible()){
								statuses = concat(statuses, 'DOA checklist');
							}
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
					if (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS')) {
						if (isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if (consumerServiceRequest.gsxRepairRequest && !consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
								if(accessFn('REASSIGN_JOBS')){
									statuses = concat(statuses, 'Map G-Number');
								}else if(accessFn('REPAIR_DEVICE')){
									if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !consumerServiceRequest.ProvisionalGSXNumber){
										if(!consumerServiceRequest.GSX_REPAIR_SERVICE_APPROACH){
											statuses = concat(statuses, 'Select Service Approach');
										}else{
											statuses = concat(statuses, 'Request raised'); 
										}
									}else{
										statuses = concat(statuses, 'Request raised'); 
									}
								}
							}
						}
					}
				}
				else {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if(isHandedToEng()) {
							if(isQCRejectedLog()){
								if(!isDiagnosisInitiated()){
									statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
								}
								statuses = concat(statuses, 'Repair completed');
							}else{
								if((showRequestLogs.isIssuesAddedByEng && isAdvancePaidCheck()) || (showViewRequestFlags.isExceptionCase && showRequestLogs.isExceptionApproved)) {
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
						if(isDOAEligible()) {
							statuses = concat(statuses, 'DOA checklist');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Service accepted',
		computeNextStatus: function() {
			let statuses = [];
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if(SERVICETYPE.ON_SITE){
					if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
						statuses = concat(statuses,'Reschedule');
					}
				}
			}

			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel &&  qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}

			if(SERVICETYPE.ON_SITE){
				if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Reschedule');
				}
			}
			
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}  

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						statuses = concat(statuses, 'Device not repairable');

						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}
				}
			}
			//onsite end
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Issues validated',
		computeNextStatus: function() {
			let statuses = [];
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if(SERVICETYPE.ON_SITE){
					if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
						statuses = concat(statuses,'Reschedule');
					}
				}
			}

			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}

			if(([SERVICETYPE.ON_SITE].consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
				if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Reschedule');
				}
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}  

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						statuses = concat(statuses, 'Device not repairable');

						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}
				}
			}
			//onsite end
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					// if(isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated  && !showRequestValid.sendIssuesGSXFlag  && (addedFaultActions && addedFaultActions.length>0)){
					if(isHandedToEng() && (showRequestLogs.diagnosisCompletedByEngg || showRequestLogs.diagnosisCancelByEngg) && showRequestLogs.isIssuesValidated  && !showRequestValid.sendIssuesGSXFlag  && (addedFaultActions && addedFaultActions.length>0)){
						if(accessFn('REPAIR_DEVICE')) {
							if(consumerServiceRequest.gsxRepairRequest && !consumerServiceRequest.gsxRepairRequest.confirmationNumber && (consumerServiceRequest.gsxRepairSymtomsIssues && consumerServiceRequest.gsxRepairSymtomsIssues.length)){
								if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !consumerServiceRequest.ProvisionalGSXNumber){
									if(!consumerServiceRequest.GSX_REPAIR_SERVICE_APPROACH){
										statuses = concat(statuses, 'Select Service Approach');
									}else{
										statuses = concat(statuses, 'Request raised'); 
									}
								}else{
									statuses = concat(statuses, 'Request raised'); 
								}
							}
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Update KGB');
								}else{
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
						if(accessFn('REASSIGN_JOBS')){
							if(!consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								statuses = concat(statuses, 'Map G-Number');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')){
						if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && accessFn('STORE_MANAGEMENT') && consumerServiceRequest.allowReceiveFromRC){
							statuses = concat(statuses, 'Receive device from RC');
						}
						if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && accessFn('STORE_MANAGEMENT') && consumerServiceRequest.enableFetchGSXStatus) {
							statuses = concat(statuses, 'Fetch GSX Status');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}
				else {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if(isRequestSFANeeded){
							if((showRequestLogs.isIssuesValidated && requestSFA && requestSFA.length > 0) || (showViewRequestFlags.isExceptionCase && showRequestLogs.isExceptionApproved)) {
								statuses = concat(statuses, 'Repair completed');
							}
						}else{
							if((showRequestLogs.isIssuesValidated && consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0) || (showViewRequestFlags.isExceptionCase && showRequestLogs.isExceptionApproved)) {
								statuses = concat(statuses, 'Repair completed');
							}
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Diagnosis initiated',
		computeNextStatus: function() {
			let statuses = [];
			let suitesStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
			
				if(SERVICETYPE.ON_SITE){
					if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
						statuses = concat(statuses,'Reschedule');
					}
				}
			}
			
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(([SERVICETYPE.ON_SITE].consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
				if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Reschedule');
				}
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}  

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						statuses = concat(statuses, 'Device not repairable');

						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}
				}
			}
			//onsite end

			//claim new workshop
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
						statuses = concat(statuses, 'Diagnosis completed', 'Cannot be diagnosed');
						// buttons when device is with engineer
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			//end claim new workshop

			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if (isHandedToEng()) {
							// if (!showRequestLogs.diagnosisIntiateByEngg || (showRequestLogs.diagnosisIntiateByEngg && !showRequestLogs.diagnosisCompletedByEngg)) {
							if((
								(!showRequestLogs.diagnosisIntiateByEngg || (showRequestLogs.diagnosisIntiateByEngg && !showRequestLogs.diagnosisCompletedByEngg)) ||
								(appleDropOffFlow && showRequestLogs.displayDiagnosisStepsToEngFor2ndTime && (!showRequestLogs.diagnosisIntiateByEngg2 || (showRequestLogs.diagnosisIntiateByEngg2 && !showRequestLogs.diagnosisCompletedByEngg2)))
								) && consumerServiceRequest.isVerifiedProduct	
							) {
								if(consumerServiceRequest.backupRequired && !consumerServiceRequest.backupTaken){
									statuses = concat(statuses, 'Take Backup');
								}else{
									if(showRequestLogs.diagnosisIntiateByEngg || (showRequestLogs.displayDiagnosisStepsToEngFor2ndTime && showRequestLogs.diagnosisIntiateByEngg2)){
										if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
											statuses = concat(statuses,'Cannot be diagnosed');
										}else{
											statuses = concat(statuses, 'Initiate AST 2 Diagnostics','Cannot be diagnosed');
										}
									}else{
										statuses = concat(statuses, 'Initiate AST 2 Diagnostics');
									}
								}
								if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
									suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
									statuses = concat(statuses, suitesStatuses)
								}
								statuses = concat(statuses, 'Diagnosis completed');
							}
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
						if(deviceAtLoggedInLoc && (!appleDropOffFlow || accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE'))){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}
				else {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if (isHandedToEng()) {
							statuses = concat(statuses, 'Diagnosis completed', 'Cannot be diagnosed');
						}
						if(showRequestLogs.issuesAddedByEng) {
							statuses = concat(statuses, 'Repair completed');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			let suitesStatuses =[];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if(SERVICETYPE.ON_SITE){
					if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
						statuses = concat(statuses,'Reschedule');
					}
				}
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(([SERVICETYPE.ON_SITE].consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
				if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Reschedule');
				}
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}  

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						statuses = concat(statuses, 'Device not repairable');

						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}
				}
			}
			//onsite end
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
						// buttons when device is with engineer
						if(!isDiagnosisInitiated()){
							statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
						}else {
							if (!isDiagnosisCompleted()) {
								statuses = concat(statuses, 'Diagnosis completed', 'Cannot be diagnosed');
							}
						}
						if (isQCRejectedLog()) {
							statuses = concat(statuses, 'Repair completed');
						}
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
				
			}
			//end claim new workshop
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if(isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)){
						if (isHandedToEng()) {
							if((
									(!showRequestLogs.diagnosisIntiateByEngg || (showRequestLogs.diagnosisIntiateByEngg && !showRequestLogs.diagnosisCompletedByEngg)) ||
									(appleDropOffFlow && showRequestLogs.displayDiagnosisStepsToEngFor2ndTime && (!showRequestLogs.diagnosisIntiateByEngg2 || (showRequestLogs.diagnosisIntiateByEngg2 && !showRequestLogs.diagnosisCompletedByEngg2)))
								) && consumerServiceRequest.isVerifiedProduct	
							) {
								if(consumerServiceRequest.backupRequired && !consumerServiceRequest.backupTaken){
									statuses = concat(statuses, 'Take Backup');
								}else{
									if((showRequestLogs.diagnosisIntiateByEngg && !showRequestLogs.diagnosisCompletedByEngg) || (appleDropOffFlow && showRequestLogs.displayDiagnosisStepsToEngFor2ndTime && showRequestLogs.diagnosisIntiateByEngg2)){
										if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
											statuses = concat(statuses,'Cannot be diagnosed');
										}else{
											statuses = concat(statuses, 'Initiate AST 2 Diagnostics','Cannot be diagnosed');
										}
									}else{
										statuses = concat(statuses, 'Initiate AST 2 Diagnostics');
									}
								}
								if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
									suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
									statuses = concat(statuses, suitesStatuses)
								}else if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.gsxInfo && consumerServiceRequest.consumerProduct.gsxInfo.serialNumber && (!arrDiagnosisSuites || (arrDiagnosisSuites && !arrDiagnosisSuites.length)) && !diagnosticsSuitesCalled) {
									if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
										suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
										statuses = concat(statuses, suitesStatuses)
									}
								}
								// Todo: Debug why getDiagnosisSuites api is not getting called
								// else if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.gsxInfo && consumerServiceRequest.consumerProduct.gsxInfo.serialNumber && (!arrDiagnosisSuites || (arrDiagnosisSuites && !arrDiagnosisSuites.length)) && !diagnosticsSuitesCalled) {
								// 	getDiagnosisSuites();
								// }
							}
							if(!consumerServiceRequest.isVerifiedProduct){
								statuses = concat(statuses, 'Validate from GSX');
							}
							if(isQCRejectedLog()) {
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
										statuses = concat(statuses, 'Update KGB');
									}else{
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}else{
								if(showRequestLogs.issuesAddedByEng && isAdvancePaidCheck()) {
									if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
										statuses = concat(statuses, 'Update KGB');
									}
								}
							}
							if (isDOAEligible()) {
								statuses = concat(statuses, 'DOA checklist');
							}
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
					if (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS')) {
						if (isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
								if(accessFn('REASSIGN_JOBS')){
									statuses = concat(statuses, 'Map G-Number');
								}else if(accessFn('REPAIR_DEVICE')){
									if(appleDropOffFlow && showRequestLogs.displayDiagnosisStepsToEngFor2ndTime && !showRequestLogs.diagnosisIntiateByEngg2) {
										// pass this case
									}else {
										statuses = concat(statuses, 'Request raised');
									}
								}
							}
						}
					}
				}
				else {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if (isHandedToEng()) {
							if(!isDiagnosisInitiated()){
								statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
							}else{
								if(!isDiagnosisCompleted()){
									statuses = concat(statuses, 'Diagnosis completed', 'Cannot be diagnosed');
								}
							}
							if(isQCRejectedLog()){
								statuses = concat(statuses, 'Repair completed');
							}else{
								if((showRequestLogs.issuesAddedByEng && isAdvancePaidCheck()) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)) {
									statuses = concat(statuses, 'Repair completed');
								}
							}
							if (isDOAEligible()) {
								statuses = concat(statuses, 'DOA checklist');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						if(!isAdvancePaidCheck()){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Diagnosis completed',
		computeNextStatus: function() {
			let statuses = [];
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if(([SERVICETYPE.ON_SITE].consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
					if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
						statuses = concat(statuses,'Reschedule');
					}
				}
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(([SERVICETYPE.ON_SITE].consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
				if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Reschedule');
				}
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}  

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						statuses = concat(statuses, 'Device not repairable');

						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}
				}
			}
			//onsite end
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
						if (isQCRejectedLog()) {
							statuses.push('Repair completed');
						}
						// buttons when device is with engineer
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if (isHandedToEng()) {
							if (isQCRejectedLog()) {
								if(accessFn('GSX_FLOW')){
									if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
										if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
											statuses = concat(statuses, 'Update KGB');
										}else{
											statuses = concat(statuses, 'Repair completed');
										}
									}
								}else{
									statuses = concat(statuses, 'Repair completed');
								}
							} else {
								// if(consumerServiceRequest && consumerServiceRequest.issues && consumerServiceRequest.issues.length > 0){
								// 	if (uniq(map(consumerServiceRequest.issues, 'IsValidated')).indexOf(false) < 0) {
								// 		if (!(addedFaultActions && addedFaultActions.length>0)) {
								// 			self.issuesAddedByEng = false;
								// 		}else{
								// 			self.issuesAddedByEng = true;
								// 		}
								// 	} else {
								// 			self.issuesAddedByEng = false;
								// 	}
								// }
								// if(self.issuesAddedByEng) {
								// 	// statuses.push('Repair completed');
								// }
							}
							// buttons when device is with engineer
						}
						if (isDOAEligible()) {
							statuses = concat(statuses, 'DOA checklist');
						}
					}
					if (accessFn('GSX_FLOW') && isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
						if (accessFn('REPAIR_DEVICE')) {
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
								if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !consumerServiceRequest.ProvisionalGSXNumber){
									if(!consumerServiceRequest.GSX_REPAIR_SERVICE_APPROACH){
										statuses = concat(statuses, 'Select Service Approach');
									}else{
										statuses = concat(statuses, 'Request raised'); 
									}
								}else{
									statuses = concat(statuses, 'Request raised'); 
								}
							}
							if (consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses.push('Update KGB');
								}else{
									statuses.push('Repair completed');
								}
							}
						}
						if(accessFn('REASSIGN_JOBS')){
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
								statuses.push('Map G-Number');
							}
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if (accessFn('GSX_FLOW') && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == 2 && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag &&accessFn('STORE_MANAGEMENT') && consumerServiceRequest.allowReceiveFromRC) {
							statuses.push('Receive device from RC');
						}
						if (accessFn('GSX_FLOW') && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == 2 && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && accessFn('STORE_MANAGEMENT') && consumerServiceRequest.enableFetchGSXStatus) {
							statuses.push('Fetch GSX Status');
						}
					}
					if(accessFn('REQUEST_CANCEL') || accessFn('ACCEPT_RETURN_DEVICE')){
						if(deviceAtLoggedInLoc && (!appleDropOffFlow || accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE'))){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			let suitesStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if(([SERVICETYPE.ON_SITE].consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
					if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
						statuses = concat(statuses,'Reschedule');
					}
				}
			}
			
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			if(([SERVICETYPE.ON_SITE].consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
				if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Reschedule');
				}
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}  

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						statuses = concat(statuses, 'Device not repairable');

						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}
				}
			}
			//onsite end
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
						// buttons when device is with engineer
						if(!isDiagnosisInitiated()){
							statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
						}else {
							if (!isDiagnosisCompleted()) {
								statuses = concat(statuses, 'Diagnosis completed', 'Cannot be diagnosed');
							}
						}
						if (isQCRejectedLog()) {
							statuses = concat(statuses, 'Repair completed');
						}
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if(showRequestLogs.isAllPartReceived && isAdvancePaidCheck()) {
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
									statuses = concat(statuses, 'Update KGB');
								}else{
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
						if (isHandedToEng()) {
							if ((!showRequestLogs.diagnosisIntiateByEngg || (showRequestLogs.diagnosisIntiateByEngg && !(showRequestLogs.diagnosisCompletedByEngg || showRequestLogs.diagnosisCancelByEngg))) && consumerServiceRequest.isVerifiedProduct) {
								if(consumerServiceRequest.backupRequired && consumerServiceRequest.backupTaken){
									statuses = concat(statuses, 'Take Backup');
								}else{
									if(showRequestLogs.diagnosisIntiateByEngg){
										if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
											statuses = concat(statuses,'Cannot be diagnosed');
										}else{
											statuses = concat(statuses, 'Initiate AST 2 Diagnostics','Cannot be diagnosed');
										}
									}else{
										statuses = concat(statuses, 'Initiate AST 2 Diagnostics');
									}
								}
								if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
									suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
									statuses = concat(statuses, suitesStatuses)
								}else if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.gsxInfo && consumerServiceRequest.consumerProduct.gsxInfo.serialNumber && (!arrDiagnosisSuites || (arrDiagnosisSuites && !arrDiagnosisSuites.length)) && !diagnosticsSuitesCalled) {
									if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
										suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
										statuses = concat(statuses, suitesStatuses)
									}
								}
							}
							if(!consumerServiceRequest.isVerifiedProduct){
								statuses = concat(statuses, 'Validate from GSX');
							}
						}
						if (isDOAEligible()) {
							statuses = concat(statuses, 'DOA checklist');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
						if(deviceAtLoggedInLoc && (!appleDropOffFlow || accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE'))){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
					if (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS')) {
						if (isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
								if(accessFn('REASSIGN_JOBS')){
									statuses = concat(statuses, 'Map G-Number');
								}else if(accessFn('REPAIR_DEVICE')){
									if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !consumerServiceRequest.ProvisionalGSXNumber){
										if(!consumerServiceRequest.GSX_REPAIR_SERVICE_APPROACH){
											statuses = concat(statuses, 'Select Service Approach');
										}else{
											statuses = concat(statuses, 'Request raised'); 
										}
									}else{
										statuses = concat(statuses, 'Request raised'); 
									}
								}
							}
						}
					}
				}
				else {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if(isRequestSFANeeded && requestSFA && requestSFA.length > 0 && showRequestLogs.isIssuesAddedByEng){
							if((showRequestLogs.isAllPartReceived && isAdvancePaidCheck()) || (showViewRequestFlags.isExceptionCase && (showRequestLogs.isExceptionApproved || showRequestLogs.isExceptionOverriden))) {
								statuses = concat(statuses, 'Repair completed');
							}
						}else{
							if (consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 && showRequestLogs.isIssuesAddedByEng) {
								if((showRequestLogs.isAllPartReceived && isAdvancePaidCheck()) || (showViewRequestFlags.isExceptionCase && (showRequestLogs.isExceptionApproved || showRequestLogs.isExceptionOverriden))) {
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
						if(isHandedToEng()) {
							if(!isDiagnosisInitiated()){
								statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
							}
						}
						if (isDOAEligible()) {
							statuses = concat(statuses, 'DOA checklist');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Engineer reassigned',
		computeNextStatus: function() {
			let statuses = [];
			let suitesStatuses = [];
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
						// buttons when device is with engineer
						if(!isDiagnosisInitiated()){
							statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
						}else {
							if (!isDiagnosisCompleted()) {
								statuses = concat(statuses, 'Diagnosis completed', 'Cannot be diagnosed');
							}
						}
						if (isQCRejectedLog()) {
							statuses = concat(statuses, 'Repair completed');
						}
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// end claim new workshop
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if (isHandedToEng()) {
							if ((!showRequestLogs.diagnosisIntiateByEngg || (showRequestLogs.diagnosisIntiateByEngg && !(showRequestLogs.diagnosisCompletedByEngg || showRequestLogs.diagnosisCancelByEngg))) && consumerServiceRequest.isVerifiedProduct) {
								if(consumerServiceRequest.backupRequired && consumerServiceRequest.backupTaken){
									statuses = concat(statuses, 'Take Backup');
								}else{
									if(showRequestLogs.diagnosisIntiateByEngg){
										if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
											statuses = concat(statuses,'Cannot be diagnosed');
										}else{
											statuses = concat(statuses, 'Initiate AST 2 Diagnostics','Cannot be diagnosed');
										}
									}else{
										statuses = concat(statuses, 'Initiate AST 2 Diagnostics');
									}
								}
								if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
									suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
									statuses = concat(statuses, suitesStatuses)
								}else if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.gsxInfo && consumerServiceRequest.consumerProduct.gsxInfo.serialNumber && (!arrDiagnosisSuites || (arrDiagnosisSuites && !arrDiagnosisSuites.length)) && !diagnosticsSuitesCalled) {
									if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
										suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
										statuses = concat(statuses, suitesStatuses)
									}
								}
							}
							if(!consumerServiceRequest.isVerifiedProduct){
								statuses = concat(statuses, 'Validate from GSX');
							}
						}
						if(isQCRejectedLog()){
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
									statuses = concat(statuses, 'Update KGB');
								}else{
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
						if (isDOAEligible()) {
							statuses = concat(statuses, 'DOA checklist');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
					if (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS')) {
						if (isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
								if(accessFn('REASSIGN_JOBS')){
									statuses = concat(statuses, 'Map G-Number');
								}else if(accessFn('REPAIR_DEVICE')){
									if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !consumerServiceRequest.ProvisionalGSXNumber){
										if(!consumerServiceRequest.GSX_REPAIR_SERVICE_APPROACH){
											statuses = concat(statuses, 'Select Service Approach');
										}else{
											statuses = concat(statuses, 'Request raised'); 
										}
									}else{
										statuses = concat(statuses, 'Request raised'); 
									}
								}
							}
						}
					}
				}
				else {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if (isHandedToEng()) {
							if(!isDiagnosisInitiated()){
								statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
							}else{
								if (!isDiagnosisCompleted()) {
									statuses = concat(statuses, 'Diagnosis completed', 'Cannot be diagnosed');
								}
							}
							if (isQCRejectedLog()) {
								statuses = concat(statuses, 'Repair completed');
							}else{
								if((showRequestLogs.issuesAddedByEng && isAdvancePaidCheck()) || (showViewRequestFlags.isExceptionCase && showRequestLogs.isExceptionApproved)) {
									statuses = concat(statuses, 'Repair completed');
								}
							}
							if(isDOAEligible()) {
								statuses = concat(statuses, 'DOA checklist');
							}
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						if(!isAdvancePaidCheck()){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Exception rejected',
		computeNextStatus: function() {
			let statuses = [];
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
						if(isQCRejectedLog()){
							if(!isDiagnosisInitiated()){
								statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
							}
							statuses.push('Repair completed');
						}
						// buttons when device is with engineer
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// end claim new workshop
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
					if(isHandedToEng()) {
						if(isQCRejectedLog()){
							if(!isDiagnosisInitiated()){
								statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
							}
							statuses = concat(statuses, 'Repair completed');
						}else{
							if((showRequestLogs.isIssuesAddedByEng && isAdvancePaidCheck()) || (showViewRequestFlags.isExceptionCase && showRequestLogs.isExceptionApproved)) {
								statuses = concat(statuses, 'Repair completed');
							}
						}
					}
					if(isDOAEligible()) {
						statuses = concat(statuses, 'DOA checklist');
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Repair estimate approved',
		computeNextStatus: function() {
			let statuses = [];
			let suitesStatuses = [];
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					statuses.push('Repair completed');
				}
				if (accessFn("ACCEPT_RETURN_DEVICE")) {
				// pass
				}
			}
			// end claim new workshop
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if (isHandedToEng()) {
							if ((!showRequestLogs.diagnosisIntiateByEngg || (showRequestLogs.diagnosisIntiateByEngg && !(showRequestLogs.diagnosisCompletedByEngg || showRequestLogs.diagnosisCancelByEngg))) && consumerServiceRequest.isVerifiedProduct) {
								if(consumerServiceRequest.backupRequired && consumerServiceRequest.backupTaken){
									statuses = concat(statuses, 'Take Backup');
								}else{
									if(showRequestLogs.diagnosisIntiateByEngg){
										if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
											statuses = concat(statuses,'Cannot be diagnosed');
										}else{
											statuses = concat(statuses, 'Initiate AST 2 Diagnostics','Cannot be diagnosed');
										}
									}else{
										statuses = concat(statuses, 'Initiate AST 2 Diagnostics');
									}
								}
								if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
									suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
									statuses = concat(statuses, suitesStatuses)
								}
							}
							if(!consumerServiceRequest.isVerifiedProduct){
								statuses = concat(statuses, 'Validate from GSX');
							}
						}
						if(isQCRejectedLog()){
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
									statuses = concat(statuses, 'Update KGB');
								}else{
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
						if (isDOAEligible()) {
							statuses = concat(statuses, 'DOA checklist');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
					if (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS')) {
						if (isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
								if(accessFn('REASSIGN_JOBS')){
									statuses = concat(statuses, 'Map G-Number');
								}else if(accessFn('REPAIR_DEVICE')){
									statuses = concat(statuses, 'Request raised');
								}
							}
						}
					}
				}
				else {
					if(isSupOrEngg()){
						if(showRequestLogs.isAllPartReceived && isAdvancePaidCheck()){
							if(isRequestSFANeeded){
								if((requestSFA && requestSFA.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)) {
									statuses = concat(statuses, 'Repair completed');
								}
							}else{
								if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)) {
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(!isAdvancePaidCheck()){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Device handed over',
		computeNextStatus: function() {
			let statuses = [];
			//claim new workshop
			if(([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// end claim new workshop
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
						if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
					}
					else {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Cannot be diagnosed',
		computeNextStatus: function() {
			let statuses = [];
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// end claim new workshop
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if (isHandedToEng()) {
							if (isQCRejectedLog()) {
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
										statuses = concat(statuses, 'Update KGB');
									}else{
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
						}
						if (isDOAEligible()) {
							statuses = concat(statuses, 'DOA checklist');
						}
					}
					if(isHandedToEng() && (isDiagnosisCompleted() || (showRequestLogs.diagnosisCompletedByEngg2 || showRequestLogs.diagnosisCancelByEngg2)) && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
						if(accessFn('REPAIR_DEVICE')){
							if(!consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !consumerServiceRequest.ProvisionalGSXNumber){
									if(!consumerServiceRequest.GSX_REPAIR_SERVICE_APPROACH){
										statuses = concat(statuses, 'Select Service Approach');
									}else{
										statuses = concat(statuses, 'Request raised'); 
									}
								}else{
									statuses = concat(statuses, 'Request raised'); 
								}
							}
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
									statuses = concat(statuses, 'Update KGB');
								}else{
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
						if(accessFn('REASSIGN_JOBS')){
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								statuses = concat(statuses, 'Map G-Number');
							}
						}
					}
					// if(appleDropOffFlow && isHandedToEng() && accessFn('REPAIR_DEVICE') && showRequestLogs.displayDiagnosisStepsToEngFor2ndTime && (showRequestLogs.diagnosisCompletedByEngg2 || showRequestLogs.diagnosisCancelByEngg2) && !consumerServiceRequest?.gsxRepairRequest?.confirmationNumber) {
					// 	statuses = concat(statuses, 'Request raised'); 
					// }
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')){
						if(accessFn('GSX_FLOW') && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && accessFn('STORE_MANAGEMENT') && consumerServiceRequest.allowReceiveFromRC){
							statuses = concat(statuses, 'Receive device from RC');
						}
						if(accessFn('GSX_FLOW') && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && accessFn('STORE_MANAGEMENT') && consumerServiceRequest.enableFetchGSXStatus) {
							statuses = concat(statuses, 'Fetch GSX Status');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}
				else {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if (isHandedToEng()) {
							if (isQCRejectedLog()) {
								statuses = concat(statuses, 'Repair completed');
							}else{
								if(showRequestLogs.issuesAddedByEng  || (showViewRequestFlags.isExceptionCase && showRequestLogs.isExceptionApproved)) {
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
					}
					if(isDOAEligible()) {
						statuses = concat(statuses, 'DOA checklist');
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Token released',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if (isHandedToEng()) {
							if (isQCRejectedLog()) {
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
										statuses = concat(statuses, 'Update KGB');
									}else{
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
						}
						if (isDOAEligible()) {
							statuses = concat(statuses, 'DOA checklist');
						}
					}
					if(isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
						if(accessFn('REPAIR_DEVICE')){
							if(!consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								statuses = concat(statuses, 'Request raised');
							}
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
									statuses = concat(statuses, 'Update KGB');
								}else{
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
						if(accessFn('REASSIGN_JOBS')){
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								statuses = concat(statuses, 'Map G-Number');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')){
						if(accessFn('GSX_FLOW') && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && accessFn('STORE_MANAGEMENT') && consumerServiceRequest.allowReceiveFromRC){
							statuses = concat(statuses, 'Receive device from RC');
						}
						if(accessFn('GSX_FLOW') && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && accessFn('STORE_MANAGEMENT') && consumerServiceRequest.enableFetchGSXStatus) {
							statuses = concat(statuses, 'Fetch GSX Status');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Diagnosis completed',
		computeNextStatus: function() {
			let statuses = [];
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
						if (isQCRejectedLog()) {
							statuses.push('Repair completed');
						}
						// buttons when device is with engineer
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// end claim new workshop
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if (isHandedToEng()) {
							if (isQCRejectedLog()) {
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
										statuses = concat(statuses, 'Update KGB');
									}else{
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
						}
						if (isDOAEligible()) {
							statuses = concat(statuses, 'DOA checklist');
						}
					}
					if(isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
						if(accessFn('REPAIR_DEVICE')){
							if(!consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !consumerServiceRequest.ProvisionalGSXNumber){
									if(!consumerServiceRequest.GSX_REPAIR_SERVICE_APPROACH){
										statuses = concat(statuses, 'Select Service Approach');
									}else{
										statuses = concat(statuses, 'Request raised'); 
									}
								}else{
									statuses = concat(statuses, 'Request raised'); 
								}
							}
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
									statuses = concat(statuses, 'Update KGB');
								}else{
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
						if(accessFn('REASSIGN_JOBS')){
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								statuses = concat(statuses, 'Map G-Number');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')){
						if(accessFn('GSX_FLOW') && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && accessFn('STORE_MANAGEMENT') && consumerServiceRequest.allowReceiveFromRC){
							statuses = concat(statuses, 'Receive device from RC');
						}
						if(accessFn('GSX_FLOW') && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && accessFn('STORE_MANAGEMENT') && consumerServiceRequest.enableFetchGSXStatus) {
							statuses = concat(statuses, 'Fetch GSX Status');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}
				else {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if (isHandedToEng()) {
							if (isQCRejectedLog()) {
								statuses = concat(statuses, 'Repair completed');
							}else{
								if(showRequestLogs.issuesAddedByEng  || (showViewRequestFlags.isExceptionCase && showRequestLogs.isExceptionApproved)) {
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
					}
					if(isDOAEligible()) {
						statuses = concat(statuses, 'DOA checklist');
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Diagnosis initiated',
		computeNextStatus: function() {
			let statuses = [];
			let suitesStatuses = [];
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
						statuses = concat(statuses, 'Diagnosis completed', 'Cannot be diagnosed');
						// buttons when device is with engineer
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// end claim new workshop
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if (isHandedToEng()) {
							if (!showRequestLogs.diagnosisIntiateByEngg || (showRequestLogs.diagnosisIntiateByEngg && !showRequestLogs.diagnosisCompletedByEngg)) {
								if(consumerServiceRequest.backupRequired && !consumerServiceRequest.backupTaken){
									statuses = concat(statuses, 'Take Backup');
								}else{
									if(showRequestLogs.diagnosisIntiateByEngg){
										if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
											statuses = concat(statuses,'Cannot be diagnosed');
										}else{
											statuses = concat(statuses, 'Initiate AST 2 Diagnostics','Cannot be diagnosed');
										}
									}else{
										statuses = concat(statuses, 'Initiate AST 2 Diagnostics');
									}
								}
								if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
									suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
									statuses = concat(statuses, suitesStatuses)
								}else if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.gsxInfo && consumerServiceRequest.consumerProduct.gsxInfo.serialNumber && (!arrDiagnosisSuites || (arrDiagnosisSuites && !arrDiagnosisSuites.length)) && !diagnosticsSuitesCalled) {
									if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
										suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
										statuses = concat(statuses, suitesStatuses)
									}
								}
								statuses = concat(statuses, 'Diagnosis completed');
							}
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}
				else {
					if (isSupOrEngg() && !(['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) > -1)) {
						if (isHandedToEng()) {
							statuses = concat(statuses, 'Diagnosis completed', 'Cannot be diagnosed');
						}
						if(showRequestLogs.issuesAddedByEng) {
							statuses = concat(statuses, 'Repair completed');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Work in progress', 
		csrlStatus: 'Customer not reachable',
		computeNextStatus: function() {
			let statuses = [];
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(((isRescheduleApplicable && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Set Schedule');
				}
				if(SERVICETYPE.ON_SITE){
					if((isRescheduleApplicable || (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 1 && consumerServiceRequest && consumerServiceRequest.isReschedulable) && accessFn( 'REQUEST_RESCHEDULE')) && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) === -1){
						statuses = concat(statuses,'Reschedule');
					}
				}
			}

			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}

			if(([SERVICETYPE.ON_SITE].consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
				if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 11 && (consumerServiceRequest && consumerServiceRequest.isReschedulable || isRescheduleApplicable) && accessFn( 'REQUEST_RESCHEDULE') && !accessFn('READ_ONLY') && !accessFn('HIDE_RESCHEDULE_BUTTON')){
					statuses=concat(statuses,'Reschedule');
				}
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start 
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}  

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						statuses = concat(statuses, 'Device not repairable');

						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}
				}
			}
			//onsite end
			return statuses;
		}
    },
	{
        csrStatus: 'Exception raised', //incomplete
		csrlStatus: 'Exception raised',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}

			if(accessFn('APPROVE_EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showCompleteRefund){
				statuses=concat(statuses,'Complete Refund'); //not complete
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Service cancel',  //this button not change
		csrlStatus: 'Service cancel',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses =[];
			if(onsiteChecks){
				if(showButton){
					if(([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && soldPlan && soldPlan.allowClaimWithdrawal){
						accessFn('CLAIM_APPROVAL') && (statuses = concat(statuses, 'Claim withdrawn'));
					}
				}
			}

			//claim
			if(claimChecks){
				if (accessFn('CLAIM_APPROVAL')) {
					if (((claimMergeStatus.hasClaimInitiated) || ([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) == -1)) && soldPlan && soldPlan.allowClaimWithdrawal) {
						claimStatuses =['Claim withdrawn'];
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Claim withdrawn');
				}
			}
			//ber end 
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses, 'Claim withdrawn');
				if (soldPlan && !soldPlan.allowClaimWithdrawal) {
					statuses = pull(statuses, 'Claim withdrawn');
				}
			}
			//replacement flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Service cancel',  //this button not change
		csrlStatus: 'Refund processed',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(onsiteChecks){
				if(showButton){
					if(([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1 ) && soldPlan && soldPlan.allowClaimWithdrawal){
						accessFn('CLAIM_APPROVAL') && (statuses = concat(statuses, 'Claim withdrawn'));
					}
				}
			}

			//claim
			if(claimChecks){
				if (accessFn('CLAIM_APPROVAL')) {
					if ((claimMergeStatus.hasClaimInitiated) || ([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && soldPlan && soldPlan.allowClaimWithdrawal) {
						claimStatuses =['Claim withdrawn'];
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Claim withdrawn');
				}
			}
			//ber end 
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses, 'Claim withdrawn');
				if (soldPlan && !soldPlan.allowClaimWithdrawal) {
					statuses = pull(statuses, 'Claim withdrawn');
				}
			}
			//replacement flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Service cancel',  //this button not change
		csrlStatus: 'Refund initiated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(onsiteChecks){
				if(showButton){
					if(([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1)  && soldPlan && soldPlan.allowClaimWithdrawal){
						accessFn('CLAIM_APPROVAL') && (statuses = concat(statuses, 'Claim withdrawn'));
					}
				}
			}

			//claim
			if(claimChecks){
				if (accessFn('CLAIM_APPROVAL')) {
					if ((claimMergeStatus.hasClaimInitiated) || ([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && soldPlan && soldPlan.allowClaimWithdrawal) {
						claimStatuses =['Claim withdrawn'];
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Claim withdrawn');
				}
			}
			//ber end 
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses, 'Claim withdrawn');
				if (soldPlan && !soldPlan.allowClaimWithdrawal) {
					statuses = pull(statuses, 'Claim withdrawn');
				}
			}
			//replacement flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Service cancel',  //this button not change
		csrlStatus: 'Refund failed',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses =[];
			if(onsiteChecks){
				if(showButton){
					if(([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1)  && soldPlan && soldPlan.allowClaimWithdrawal){
						accessFn('CLAIM_APPROVAL') && (statuses = concat(statuses, 'Claim withdrawn'));
					}
				}
			}

			//claim
			if(claimChecks){
				if (accessFn('CLAIM_APPROVAL')) {
					if ((claimMergeStatus.hasClaimInitiated) || ([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && soldPlan && soldPlan.allowClaimWithdrawal) {
						claimStatuses = ['Claim withdrawn'];
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Claim withdrawn');
				}
			}
			//ber end 
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses, 'Claim withdrawn');
				if (soldPlan && !soldPlan.allowClaimWithdrawal) {
					statuses = pull(statuses, 'Claim withdrawn');
				}
			}
			//replacement flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Service cancel',  //this button not change
		csrlStatus: 'Service completed',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(onsiteChecks){
				if(showButton){
					if(([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1)  && soldPlan && soldPlan.allowClaimWithdrawal){
						accessFn('CLAIM_APPROVAL') && (statuses = concat(statuses, 'Claim withdrawn'));
					}
				}
			}

			//claim
			if(claimChecks){
				if (accessFn('CLAIM_APPROVAL')) {
					if ((claimMergeStatus.hasClaimInitiated) || ([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && soldPlan && soldPlan.allowClaimWithdrawal) {
						claimStatuses =['Claim withdrawn'];
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Claim withdrawn');
				}
			}
			//ber end 
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses, 'Claim withdrawn');
				if (soldPlan && !soldPlan.allowClaimWithdrawal) {
					statuses = pull(statuses, 'Claim withdrawn');
				}
			}
			//replacement flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Service cancel',  //this button not change
		csrlStatus: 'Service completed',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(onsiteChecks){
				if(showButton){
					if(([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1)  && soldPlan && soldPlan.allowClaimWithdrawal){
						accessFn('CLAIM_APPROVAL') && (statuses = concat(statuses, 'Claim withdrawn'));
					}
				}
			}

			//claim
			if(claimChecks){
				if (accessFn('CLAIM_APPROVAL')) {
					if ((claimMergeStatus.hasClaimInitiated) || ([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && soldPlan && soldPlan.allowClaimWithdrawal) {
						claimStatuses = ['Claim withdrawn'];
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Claim withdrawn');
				}
			}
			//ber end 
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			return statuses;
		}
    },
	{
        csrStatus: 'Service cancel',  //this button not change
		csrlStatus: 'Failed to create Exchange order. Please retry',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(onsiteChecks){
				if(showButton){
					if(([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1)  && soldPlan && soldPlan.allowClaimWithdrawal){
						accessFn('CLAIM_APPROVAL') && (statuses = concat(statuses, 'Claim withdrawn'));
					}
				}
			}

			//claim
			if(claimChecks){
				if (accessFn('CLAIM_APPROVAL')) {
					if ((claimMergeStatus.hasClaimInitiated) || ([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && soldPlan && soldPlan.allowClaimWithdrawal) {
						claimStatuses =['Claim withdrawn'];
					}
				}
				statuses = concat(statuses,claimStatuses);;
			}
			//claim end
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Claim withdrawn');
				}
			}
			//ber end 
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses, 'Claim withdrawn');
				if (soldPlan && !soldPlan.allowClaimWithdrawal) {
					statuses = pull(statuses, 'Claim withdrawn');
				}
			}
			//replacement flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Service cancel',  //this button not change
		csrlStatus: 'Payment Refund processed',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses =[];
			if(onsiteChecks){
				if(showButton){
					if(([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && soldPlan && soldPlan.allowClaimWithdrawal){
						accessFn('CLAIM_APPROVAL') && (statuses = concat(statuses, 'Claim withdrawn'));
					}
				}
			}

			//claim
			if(claimChecks){
				if (accessFn('CLAIM_APPROVAL')) {
					if ((claimMergeStatus.hasClaimInitiated) || ([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && soldPlan && soldPlan.allowClaimWithdrawal) {
						claimStatuses = ['Claim withdrawn'];
					}
				}
				statuses = concat(statuses,claimStatuses);;
			}
			//claim end
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Claim withdrawn');
				}
			}
			//ber end 
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses, 'Claim withdrawn');
				if (soldPlan && !soldPlan.allowClaimWithdrawal) {
					statuses = pull(statuses, 'Claim withdrawn');
				}
			}
			//replacement flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Service cancel',  //this button not change
		csrlStatus: 'Refund in-progress',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(onsiteChecks){
				if(showButton){
					if(([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) && soldPlan && soldPlan.allowClaimWithdrawal){
						accessFn('CLAIM_APPROVAL') && (statuses = concat(statuses, 'Claim withdrawn'));
					}
				}
			}

			//claim
			if(claimChecks){
				if (accessFn('CLAIM_APPROVAL')) {
					if ((claimMergeStatus.hasClaimInitiated) || ([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && soldPlan && soldPlan.allowClaimWithdrawal) {
						claimStatuses = ['Claim withdrawn'];
					}
				}
				statuses = concat(statuses,claimStatuses);;
			}
			//claim end
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Claim withdrawn');
				}
			}
			//ber end 
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses, 'Claim withdrawn');
				if (soldPlan && !soldPlan.allowClaimWithdrawal) {
					statuses = pull(statuses, 'Claim withdrawn');
				}
			}
			//replacement flow end
			return statuses;
		}
    },
	{
        csrStatus: 'In-home request accepted',  
		csrlStatus: 'In-home request accepted',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){ //in this condition has issue will solve 
				if(showButton){
					if (isServiceLocationAssigned()) {
							if (accessFn('CREATE_VISIT_ACCESS')) {
								statuses = concat(statuses, 'Service reschedule');
							}

							if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
								if (accessFn('CANCEL_REQUEST_ANYTIME')) {
									statuses = concat(statuses, 'Service cancel');
								}
							}
						}

						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
							statuses = concat(statuses, 'Device not repairable', 'Device not eligible for repair','No fault found');
						}
				}
				if(accessFn('READ_ONLY_WORKSHOP') || (['In-home request accepted'].indexOf(consumerServiceRequest.Status) > -1 && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && (!checkDeviceValueAndDateAdded() || !coveredUnderPlanCheck()))) {
					statuses = []
				}
			}
			//end onsite
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'In-home request accepted', //logs diff
		csrlStatus: 'Customer not reachable',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS')) {
						statuses = concat(statuses, 'Service reschedule');
						}

						if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Device not repairable', 'Device not eligible for repair','No fault found');
						// statuses = concat(statuses, 'Device not eligible for repair');
					}
				}
				if(accessFn('READ_ONLY_WORKSHOP') || (['In-home request accepted'].indexOf(consumerServiceRequest.Status) > -1 && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && (!checkDeviceValueAndDateAdded() || !coveredUnderPlanCheck()))) {
					statuses = []
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'In-home request accepted', //logs diff
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS')) {
						statuses = concat(statuses, 'Service reschedule');
						}

						if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Device not repairable', 'Device not eligible for repair','No fault found');
						// statuses = concat(statuses, 'Device not eligible for repair');
					}
				}
				if(accessFn('READ_ONLY_WORKSHOP') || (['In-home request accepted'].indexOf(consumerServiceRequest.Status) > -1 && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && (!checkDeviceValueAndDateAdded() || !coveredUnderPlanCheck()))) {
					statuses = []
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'In-home request accepted', 
		csrlStatus: 'Documents uploaded',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}

						if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Device not repairable', 'Device not eligible for repair','No fault found');
						// statuses = concat(statuses, 'Device not eligible for repair');
					}
				}
				if(accessFn('READ_ONLY_WORKSHOP') || (['In-home request accepted'].indexOf(consumerServiceRequest.Status) > -1 && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && (!checkDeviceValueAndDateAdded() || !coveredUnderPlanCheck()))) {
					statuses = []
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'In-home request accepted', 
		csrlStatus: 'Service accepted',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS')) {
							statuses = concat(statuses, 'Service reschedule');
						}

						if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Device not repairable', 'Device not eligible for repair','No fault found');
						// statuses = concat(statuses, 'Device not eligible for repair');
					}
				}
				if(accessFn('READ_ONLY_WORKSHOP') || (['In-home request accepted'].indexOf(consumerServiceRequest.Status) > -1 && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && (!checkDeviceValueAndDateAdded() || !coveredUnderPlanCheck()))) {
					statuses = []
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Claim estimation generated', 
		csrlStatus: 'Claim estimation generated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
							accessFn('CLAIM_APPROVAL') && (statuses = concat(statuses, 'Claim estimation approved','Claim rejected'));
								statuses = concat(statuses, 'Device not repairable','No fault found');
							// statuses = concat(statuses, 'No fault found');
						}
					}
				}
			}
			// if(claimNewWorkshopChecks){
			// 	if (accessFn('CLAIM_WORKSHOP')) {
			// 		statuses = concat(statuses,'Cancel Service Without Dispatch','Repair cancel');

			// 	}
			// 	if (accessFn('CLAIM_APPROVAL')) {
			// 		statuses = concat(statuses,'Claim estimation approved');
			// 	}
			// }
			//end onsite
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('CLAIM_WORKSHOP')) {
					statuses = concat(statuses,'Cancel Service Without Dispatch','Repair cancel');
				}
				if (accessFn('CLAIM_APPROVAL')) {
					statuses = concat(statuses,'Claim estimation approved', 'Claim rejected');
				}
				if(accessFn('WAITING_ON_CUSTOMER_BUTTON')){
					statuses = concat(statuses, 'Waiting on customer');
				}
			}
			//end claim new
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch', 'Claim estimation approved', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = ['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Plans Description', 'Claim rejected']
							}else{
								claimStatuses = ['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Claim estimation approved', 'Claim rejected']
							}
						}
					}
				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Waiting on customer','Repair cancel', 'Service cancel', 'Claim estimation approved', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses =['Waiting on customer','Repair cancel', 'Service cancel','Plans Description', 'Claim rejected']
							}else{
								claimStatuses =['Waiting on customer','Repair cancel', 'Service cancel','Claim estimation approved', 'Claim rejected']
							}
						}
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = merge(claimStatuses, ['Waiting on customer','Repair cancel', 'Service cancel', 'Claim estimation approved', 'Claim rejected']);
						if(insurancePlans.selectPlanBenefit){
							claimStatuses =['Waiting on customer','Repair cancel', 'Service cancel','Plans Description', 'Claim rejected']
						}else{
							claimStatuses =['Waiting on customer','Repair cancel', 'Service cancel','Claim estimation approved', 'Claim rejected']
						}
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch', 'Claim estimation approved', 'Claim rejected']);
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch', 'Claim estimation approved', 'Claim rejected']);
					}
				}
				// let isChargeAdded = false;
				// if (consumerServiceRequest && consumerServiceRequest.charges && consumerServiceRequest.charges.length>0) {
				// 	for(let i = 0; i < consumerServiceRequest.charges.length; i++) {
				// 		let charge = consumerServiceRequest.charges[i];
				// 		if(['Other', 'Part'].indexOf(charge.Type) > -1){
				// 			isChargeAdded = true;
				// 			break;
				// 		}
				// 	}
				// }

				if(!showRequestValid.isChargeAdded) {
					claimStatuses = pull(claimStatuses, 'Claim estimation approved');
				}
				if(!accessFn('WAITING_ON_CUSTOMER_BUTTON')){
					claimStatuses = pull(claimStatuses, 'Waiting on customer');
				}
				statuses = concat(statuses,claimStatuses)
				
			}
			//claim end
			return statuses;
		}
    },
	{
        csrStatus: 'Claim estimation generated', 
		csrlStatus: 'Reason received',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
							accessFn('CLAIM_APPROVAL') && (statuses = concat(statuses, 'Claim estimation approved','Claim rejected'));
								statuses = concat(statuses, 'Device not repairable','No fault found');
							// statuses = concat(statuses, 'No fault found');
						}
					}
				}
			}
			// if(claimNewWorkshopChecks){
			// 	if (accessFn('CLAIM_WORKSHOP')) {
			// 		statuses = concat(statuses,'Cancel Service Without Dispatch','Repair cancel');

			// 	}
			// 	if (accessFn('CLAIM_APPROVAL')) {
			// 		statuses = concat(statuses,'Claim estimation approved');
			// 	}
			// }
			//end onsite
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('CLAIM_WORKSHOP')) {
					statuses = concat(statuses,'Cancel Service Without Dispatch','Repair cancel');

				}
				if (accessFn('CLAIM_APPROVAL')) {
						statuses = concat(statuses,'Claim estimation approved', 'Claim rejected');
				}
				if(accessFn('WAITING_ON_CUSTOMER_BUTTON')){
				statuses = concat(statuses, 'Waiting on customer');
				}
			}
			//end claim new
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch', 'Claim estimation approved', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = ['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Plans Description', 'Claim rejected']
							}else{
								claimStatuses = ['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Claim estimation approved', 'Claim rejected']
							}
						}
					}
				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Waiting on customer','Repair cancel', 'Service cancel', 'Claim estimation approved', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses =['Waiting on customer','Repair cancel', 'Service cancel','Plans Description', 'Claim rejected']
							}else{
								claimStatuses =['Waiting on customer','Repair cancel', 'Service cancel','Claim estimation approved', 'Claim rejected']
							}
						}
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = merge(claimStatuses, ['Waiting on customer','Repair cancel', 'Service cancel', 'Claim estimation approved', 'Claim rejected']);
						if(insurancePlans.selectPlanBenefit){
							claimStatuses =['Waiting on customer','Repair cancel', 'Service cancel','Plans Description', 'Claim rejected']
						}else{
							claimStatuses =['Waiting on customer','Repair cancel', 'Service cancel','Claim estimation approved', 'Claim rejected']
						}
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch', 'Claim estimation approved', 'Claim rejected']);
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch', 'Claim estimation approved', 'Claim rejected']);
					}
				}
				// let isChargeAdded = false;
				// if (consumerServiceRequest && consumerServiceRequest.charges && consumerServiceRequest.charges.length>0) {
				// 	for(let i = 0; i < consumerServiceRequest.charges.length; i++) {
				// 		let charge = consumerServiceRequest.charges[i];
				// 		if(['Other', 'Part'].indexOf(charge.Type) > -1){
				// 			isChargeAdded = true;
				// 			break;
				// 		}
				// 	}
				// }

				if(!showRequestValid.isChargeAdded) {
					claimStatuses = pull(claimStatuses, 'Claim estimation approved');
				}
				if(!accessFn('WAITING_ON_CUSTOMER_BUTTON')){
					claimStatuses = pull(claimStatuses, 'Waiting on customer');
				}
				statuses = concat(statuses,claimStatuses)
				
			}
			//claim end
			return statuses;
		}
    },
	{
		
        csrStatus: 'Claim estimation generated', 
		csrlStatus: 'Engineer reassigned',
		computeNextStatus: function() {
			let statuses = [];
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('CLAIM_WORKSHOP')) {
					statuses = concat(statuses,'Cancel Service Without Dispatch','Repair cancel');
				}
			}
			//end claim new
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Claim estimation generated', 
		csrlStatus: 'Claim estimation approved',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
							accessFn('CLAIM_APPROVAL') && (statuses = concat(statuses, 'Claim estimation approved'));
								statuses = concat(statuses, 'Device not repairable','No fault found');
							// statuses = concat(statuses, 'No fault found');
						}
					}
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Claim estimation approved',  //to do
		csrlStatus: 'Claim estimation approved',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
						statuses = concat(statuses, 'Service completed');
					}
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && !buttonLogStatus.tecnicianReached) {
							statuses = concat(statuses, 'Service reschedule');
						}

						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							setPendingForSpares(false);
						//   pendingForSpares = false;
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						statuses = concat(statuses, 'Parts pending');
						if(buttonLogStatus.tecnicianReached) { 
							statuses = concat(statuses, 'Visit productive','Visit unproductive');
						}
						// statuses = concat(statuses, 'No fault found');
						}
					}
				}
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Claim estimation approved',  //to do
		csrlStatus: 'Documents uploaded',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && !buttonLogStatus.tecnicianReached) {
							statuses = concat(statuses, 'Service reschedule');
						}

						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							setPendingForSpares(false);
						//   pendingForSpares = false;
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						statuses = concat(statuses, 'Parts pending');
						if(buttonLogStatus.tecnicianReached) {
						    statuses = concat(statuses, 'Visit productive','Visit unproductive');
						}
						statuses = concat(statuses, 'No fault found');
						}
					}
				}
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Issues validated',  
		csrlStatus: 'Issues validated',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Device not repairable',  
		csrlStatus: 'Device not repairable',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = []
			if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
				if(accessFn('CONVERT_TO_REIMBURSEMENT')){ //feature right not present
					statuses = concat(statuses, 'Convert to reimbursement')
				}
			}
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses,['Service cancel'])
						// }
					}
				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Service cancel']);
						// }
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Service cancel']);
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Device not eligible for repair',  
		csrlStatus: 'Device not eligible for repair',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.DEMO, SERVICETYPE.SERVICING,56,61,62,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				if (accessFn('CANCEL_REQUEST_ANYTIME')) {
					statuses = concat(statuses, 'Service cancel');
				}
			}
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses,['Service cancel'])
						// }
					}
				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses,['Service cancel'])
						// }
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses,['Service cancel'])
						// }
					}
				}
				if ([SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					claimStatuses = ['Repair cancel'];
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'No fault found',  
		csrlStatus: 'No fault found',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(onsiteChecks){
				if(showButton){
					if (accessFn('CANCEL_REQUEST_ANYTIME')) {
						statuses = concat(statuses, 'Service cancel');
					}
				}
			}
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses,['Service cancel'])
						// }
					}
				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses,['Service cancel'])
						// }
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses,['Service cancel'])
						// }
					}
				}
				if ([SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					claimStatuses = ['Repair cancel'];
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			return statuses;
		}
    },
	{
        csrStatus: 'Parts request accepted',  
		csrlStatus: 'Parts request accepted',
		computeNextStatus: function() {
			let statuses = [];
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
							if (partsRequested && partsRequested.length > 0 ) {
								setIsEachPartReceived(true);
								for (let iCount = 0;iCount<partsRequested.length;iCount ++ ) {
									if (['received','rejected','returned'].indexOf(partsRequested[iCount].statusDetails.Status) > -1 ) {
									} else {
										setIsEachPartReceived(false);
									}
								}
								if (isEachPartReceived) {
								statuses = concat(statuses, 'Service reschedule');
								}
							} else {
								statuses = concat(statuses, 'Service reschedule');
							}
						}
					}  
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				// if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
						if(accessFn("ACCEPT_RETURN_DEVICE") || accessFn('STORE_MANAGEMENT')){
							if (accessFn("ACCEPT_RETURN_DEVICE") && qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1){
								if(!isAdvancePaidCheck()){
									statuses = concat(statuses, 'Advance collected');
								}
							}
							if(isAdvancePaidCheck() && accessFn('STORE_MANAGEMENT')){
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && consumerServiceRequest.allowReceiveFromRC){
									statuses = concat(statuses, 'Receive device from RC');
								}
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && consumerServiceRequest.enableFetchGSXStatus){
									statuses = concat(statuses, 'Fetch GSX Status');
								}
							}
						}
						if(isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if(accessFn('REPAIR_DEVICE')){
								if(consumerServiceRequest.gsxRepairRequest && !consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Request raised');									
								}
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
									if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
										statuses = concat(statuses, 'Update KGB');
									}
								}
							}
							if(accessFn('REASSIGN_JOBS')){
								if(!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
									statuses = concat(statuses, 'Map G-Number');
								}
							}
						}
					}
					else {
						if (accessFn("ACCEPT_RETURN_DEVICE")) {
							if(!isAdvancePaidCheck()){
								statuses = concat(statuses, 'Advance collected');
							}
						}
						if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
							statuses = concat(statuses, 'Repair cancel initiated');
						}
					}
				// }
			}
			// Pickup carry in end
			
			return statuses;
		}
    },
	{
        csrStatus: 'Parts request accepted',  
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			// Pickup carry in 
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && (accessFn('WORKSHOP_FLOW'))){
				// if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
						if(accessFn("ACCEPT_RETURN_DEVICE") || accessFn('STORE_MANAGEMENT')){
							if (accessFn("ACCEPT_RETURN_DEVICE") && qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1){
								if(!isAdvancePaidCheck()){
									statuses = concat(statuses, 'Advance collected');
								}
							}
							if(isAdvancePaidCheck() && accessFn('STORE_MANAGEMENT')){
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && consumerServiceRequest.allowReceiveFromRC){
									statuses = concat(statuses, 'Receive device from RC');
								}
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && consumerServiceRequest.enableFetchGSXStatus){
									statuses = concat(statuses, 'Fetch GSX Status');
								}
							}
						}
						if(isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if(accessFn('REPAIR_DEVICE')){
								if(consumerServiceRequest.gsxRepairRequest && !consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Request raised');
								}
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
									if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
										statuses = concat(statuses, 'Update KGB');
									}
								}
							}
							if(accessFn('REASSIGN_JOBS')){
								if(!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
									statuses = concat(statuses, 'Map G-Number');
								}
							}
						}
					}
					else {
						if (accessFn("ACCEPT_RETURN_DEVICE")) {
							if(!isAdvancePaidCheck() && showRequestValid.isOutOfWarranty){
								statuses = concat(statuses, 'Advance collected');
							}
					  	}
						if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
							statuses = concat(statuses, 'Repair cancel initiated');
						}
					}
				// }
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Parts request accepted',  
		csrlStatus: 'Repair estimate approved',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			if(pickupCarryInChecks){
				// if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
						if(accessFn("ACCEPT_RETURN_DEVICE") || accessFn('STORE_MANAGEMENT')){
							if (accessFn("ACCEPT_RETURN_DEVICE") && qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1){
								if(!isAdvancePaidCheck()){
									statuses = concat(statuses, 'Advance collected');
								}
							}
							if(isAdvancePaidCheck() && accessFn('STORE_MANAGEMENT')){
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && consumerServiceRequest.allowReceiveFromRC){
									statuses = concat(statuses, 'Receive device from RC');
								}
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && consumerServiceRequest.enableFetchGSXStatus){
									statuses = concat(statuses, 'Fetch GSX Status');
								}
							}
						}
						if(isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if(accessFn('REPAIR_DEVICE')){
								if(consumerServiceRequest.gsxRepairRequest && !consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Request raised');
								}
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
									if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
										statuses = concat(statuses, 'Update KGB');
									}
								}
							}
							if(accessFn('REASSIGN_JOBS')){
								if(!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
									statuses = concat(statuses, 'Map G-Number');
								}
							}
						}
					}
					else {
						if (accessFn("ACCEPT_RETURN_DEVICE")) {
							if(!isAdvancePaidCheck()){
								statuses = concat(statuses, 'Advance collected');
							}
					  	}
						if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
							statuses = concat(statuses, 'Repair cancel initiated');
						}
					}
				// }
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Parts request accepted',  
		csrlStatus: 'Engineer reassigned',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			if(pickupCarryInChecks){
				// if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
						if(accessFn("ACCEPT_RETURN_DEVICE") || accessFn('STORE_MANAGEMENT')){
							if (accessFn("ACCEPT_RETURN_DEVICE") && qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1){
								if(!isAdvancePaidCheck()){
									statuses = concat(statuses, 'Advance collected');
								}
							}
							if(isAdvancePaidCheck() && accessFn('STORE_MANAGEMENT')){
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && consumerServiceRequest.allowReceiveFromRC){
									statuses = concat(statuses, 'Receive device from RC');
								}
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && consumerServiceRequest.enableFetchGSXStatus){
									statuses = concat(statuses, 'Fetch GSX Status');
								}
							}
						}
						if(isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if(accessFn('REPAIR_DEVICE')){
								if(consumerServiceRequest.gsxRepairRequest && !consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Request raised');
								}
								if (consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
									if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
										statuses = concat(statuses, 'Update KGB');
									}
								}
							}
							if(accessFn('REASSIGN_JOBS')){
								if(!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
									statuses = concat(statuses, 'Map G-Number');
								}
							}
						}
					}
					else {
						if (accessFn("ACCEPT_RETURN_DEVICE")) {
							if(!isAdvancePaidCheck() && showRequestValid.isOutOfWarranty){
								statuses = concat(statuses, 'Advance collected');
							}
					  	}
						if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
							statuses = concat(statuses, 'Repair cancel initiated');
						}
					}
				// }
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Parts request accepted',  
		csrlStatus: 'Exception rejected',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			if(pickupCarryInChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(!accessFn('GSX_FLOW')){
						if (accessFn("ACCEPT_RETURN_DEVICE")) {
							if(!isAdvancePaidCheck() && showRequestValid.isOutOfWarranty){
								statuses = concat(statuses, 'Advance collected');
							}
						}
						if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
							statuses = concat(statuses, 'Repair cancel initiated');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Parts request accepted',  
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			if(pickupCarryInChecks){
				// if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
						if(accessFn("ACCEPT_RETURN_DEVICE") || accessFn('STORE_MANAGEMENT')){
							if (accessFn("ACCEPT_RETURN_DEVICE") && qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1){
								if(!isAdvancePaidCheck()){
									statuses = concat(statuses, 'Advance collected');
								}
							}
							if(isAdvancePaidCheck() && accessFn('STORE_MANAGEMENT')){
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && consumerServiceRequest.allowReceiveFromRC){
									statuses = concat(statuses, 'Receive device from RC');
								}
								if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && consumerServiceRequest.enableFetchGSXStatus){
									statuses = concat(statuses, 'Fetch GSX Status');
								}
							}
						}
						if(isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if(accessFn('REPAIR_DEVICE')){
								if(!consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Request raised');
								}
								if(consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
									if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
										statuses = concat(statuses, 'Update KGB');
									}
								}
							}
							if(accessFn('REASSIGN_JOBS')){
								if(!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
									statuses = concat(statuses, 'Map G-Number');
								}
							}
						}
					}
					else {
						if (accessFn("ACCEPT_RETURN_DEVICE")) {
							if(!isAdvancePaidCheck()){
								statuses = concat(statuses, 'Advance collected');
							}
					  	}
						if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
							statuses = concat(statuses, 'Repair cancel initiated');
						}
					}
				// }
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Parts request accepted',  
		csrlStatus: 'Device handed over',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			if(pickupCarryInChecks){
				if(!accessFn('GSX_FLOW')){
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Replacement Rejected',  
		csrlStatus: 'Replacement Rejected',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
							statuses = concat(statuses, 'Service reschedule');
						}
						if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
							statuses = concat(statuses, 'Service cancel initiated');
						}
					}
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'High Value Part Rejected',  
		csrlStatus: 'High Value Part Rejected',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'RET approved',  
		csrlStatus: 'RET approved',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('TECHNICIAN_SUPERVISOR') && !(['CprDashboard','BoseDashboard'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) > -1) && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)) {
							statuses = concat(statuses, 'Job updated');
						}
					}
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Service invoice generated',  
		csrlStatus: 'Service invoice generated',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if (accessFn ('TECHNICIAN_SUPERVISOR')) {
						if (consumerServiceRequest && consumerServiceRequest.IsUnderWarranty) {
							if (showCompleteServiceFunction() && getSource()!='Bose' && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)) {
								statuses = concat(statuses, 'Service completed');
							}
						} else if (getSource() != 'Bose' && !(qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1)) {
							statuses = concat(statuses, 'Payment received');
						}
					}
					if (isBoseFlow) {
						if (consumerServiceRequest.InvoiceGeneratedDate) {
							statuses = concat(statuses, 'Visit productive');
						}
						else {
							statuses = concat(statuses, 'Visit unproductive');
						}
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter){
							if (consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
								statuses = concat(statuses, 'Payment received');
							} else {
								if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
									if(!consumerServiceRequest.CREATED_AT_DROPOFF_PSL || (consumerServiceRequest.CREATED_AT_DROPOFF_PSL !== "true")) {
										statuses = concat(statuses, 'Intimate customer');
									}
								}else if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
									if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
										statuses = concat(statuses, 'Device dispatched');
									} else {
										statuses = concat(statuses, 'Initiate logistics request');
									}
								}
							}
						}
						if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL && (consumerServiceRequest.CREATED_AT_DROPOFF_PSL === "true") && accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')) {
							statuses = concat(statuses, 'Service completed');
						}
					}
				}else{
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else{
							if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
								statuses = concat(statuses, 'Intimate customer');
							}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
								if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
									statuses = concat(statuses, 'Device dispatched');
								} else {
									statuses = concat(statuses, 'Initiate logistics request');
								}
							}
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Service invoice generated',  
		csrlStatus: 'Token released',
		computeNextStatus: function() {
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter){
							if (consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
								statuses = concat(statuses, 'Payment received');
							} else {
								if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
									statuses = concat(statuses, 'Intimate customer');
								}else if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
									if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
										statuses = concat(statuses, 'Device dispatched');
									} else {
										statuses = concat(statuses, 'Initiate logistics request');
									}
								}
							}
						}
					}
				}else{
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else{
							if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
								statuses = concat(statuses, 'Intimate customer');
							}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
								if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
									statuses = concat(statuses, 'Device dispatched');
								}else{
									statuses = concat(statuses, 'Initiate logistics request');
								}
							}
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Service invoice generated',  
		csrlStatus: 'Token generated',
		computeNextStatus: function() {
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter){
							if (consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
								statuses = concat(statuses, 'Payment received');
							} else {
								if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
									statuses = concat(statuses, 'Intimate customer');
								}else if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
									if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
										statuses = concat(statuses, 'Device dispatched');
									} else {
										statuses = concat(statuses, 'Initiate logistics request');
									}
								}
							}
						}
					}
				}else{
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else{
							if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
								statuses = concat(statuses, 'Intimate customer');
							}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
								if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
									statuses = concat(statuses, 'Device dispatched');
								}else{
									statuses = concat(statuses, 'Initiate logistics request');
								}
							}
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	// {
    //     csrStatus: 'Service invoice generated',  
	// 	csrlStatus: 'Device accepted',
	// 	computeNextStatus: function() {
	// 		let statuses = [];
	// 		//replacement flow
	// 		if(replacementFlowChecks){
	// 			statuses = concat(statuses,'Create Refurb Request');
	// 			if(!accessFn('CREATE_REFURB_REQUEST') || !consumerServiceRequest.RETURN_ORDER_NUMBER) {
	// 				statuses = pull(statuses, 'Create Refurb Request');
	// 			}
	// 			if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
	// 				if (showRequestLogs.isDefectiveReceived) {
	// 					statuses = pull(statuses, 'Create Refurb Request');
	// 				}
	// 			}
	// 		}
	// 		// Pickup carry in 
	// 		if(pickupCarryInChecks){
	// 			if(accessFn('ACCEPT_RETURN_DEVICE')){
	// 				if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
	// 					statuses = concat(statuses, 'Payment received');
	// 					if(accessFn('SEND_PAYMENT_LINK')){
	// 						statuses = concat(statuses, 'Send payment link');
	// 					}
	// 				}else{
	// 					if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
	// 						statuses = concat(statuses, 'Intimate customer');
	// 					}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
	// 						if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
	// 							statuses = concat(statuses, 'Device dispatched');
	// 						}else{
	// 							statuses = concat(statuses, 'Initiate logistics request');
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}	
	// 		// Pickup carry in end
	// 		return statuses;
	// 	}
    // },
	{
        csrStatus: 'Service invoice generated',  
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter){
							if (consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
								statuses = concat(statuses, 'Payment received');
							} else {
								if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
									statuses = concat(statuses, 'Intimate customer');
								}else if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
									if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
										statuses = concat(statuses, 'Device dispatched');
									} else {
										statuses = concat(statuses, 'Initiate logistics request');
									}
								}
							}
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Service invoice generated',  
		csrlStatus: 'Waiting for Assignment',
		computeNextStatus: function() {
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter){
							if (consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
								statuses = concat(statuses, 'Payment received');
							} else {
								if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
									statuses = concat(statuses, 'Intimate customer');
								}else if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
									if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
										statuses = concat(statuses, 'Device dispatched');
									} else {
										statuses = concat(statuses, 'Initiate logistics request');
									}
								}
							}
						}
					}
				}else{
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else{
							if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
								statuses = concat(statuses, 'Intimate customer');
							}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
								if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
									statuses = concat(statuses, 'Device dispatched');
								}else{
									statuses = concat(statuses, 'Initiate logistics request');
								}
							}
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Service initiated',  
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses =[];
			if(onsiteChecks){
				if(showButton){
				
				}
			}
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch', 'Repair completed', 'Parts pending']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Service cancel', 'Repair completed', 'Service completed', 'Parts pending']);
						// }
					}
					if(consumerServiceRequest && consumerServiceRequest.autoClaimFulfillmentFlow) {
						claimStatuses =  ['Repair cancel', 'Service cancel', 'Repair completed']
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Service cancel', 'Repair completed', 'Service completed', 'Parts pending']);
						// }
					}
				}
				statuses = concat(statuses,claimStatuses)
			}
			// if(claimChecks){
			// 	if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
			// 		if (accessFn('ADD_NORMAL_CHARGES')) {
			// 			statuses = merge(statuses,['Repair cancel', 'Cancel Service Without Dispatch', 'Repair completed', 'Parts pending']);
			// 		}
			// 	}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
			// 		if (accessFn('ADD_NORMAL_CHARGES')) {
			// 			statuses = merge(statuses,['Repair cancel', 'Service cancel', 'Repair completed', 'Service completed', 'Parts pending']);
			// 		}
			// 		if(claimobj  && claimobj.consumerServiceRequest && claimobj.consumerServiceRequest.autoClaimFulfillmentFlow) {
			// 			statuses = concat(statuses,'Repair cancel', 'Service cancel', 'Repair completed');
			// 		}
			// 	}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
			// 		if (accessFn('ADD_NORMAL_CHARGES')) {
			// 			statuses = merge(statuses,['Repair cancel', 'Service cancel', 'Repair completed', 'Service completed', 'Parts pending']);
			// 		}
			// 	}
			// }
			//end claim
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Service initiated',  
		csrlStatus: 'Issue validated',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					statuses = concat(statuses, 'Repair completed');
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Service initiated', 
		csrlStatus: 'Estimation approved',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						if( accessFn( 'REQUEST_REASSIGN')){
							if(!accessFn('HIDE_REPAIR_COMPLETE')){
								statuses = concat(statuses,'Complete Repair');
							}
							statuses = concat(statuses,'Complete Service','Complete Without Repair','Cancel Repair');
							if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !consumerServiceRequest.InvoiceGeneratedDate){
								statuses = concat(statuses,'Cancel Service');
							}
							if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
								statuses=concat(statuses,'Generate Invoice')
							}
						}
						if(consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !accessFn('HIDE_PARTS_PENDING_BUTTON') ){
							statuses=concat(statuses,'Parts Pending');
						}
					}
					if((!consumerServiceRequest.isFree) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				
				if( [SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !accessFn('READ_ONLY') && ([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REQUEST_REASSIGN') ){
						statuses=concat(statuses,'Cancel Repair')
					}
					if(!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						if(accessFn( 'REQUEST_REASSIGN') ){
							if(!accessFn('HIDE_REPAIR_COMPLETE')){
								statuses = concat(statuses,'Complete Repair');
							}
							statuses = concat(statuses,'Complete Without Repair','Cancel Repair');
							if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
								statuses=concat(statuses,'Generate Invoice')
							}
						}
						if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate  && !accessFn('HIDE_PARTS_PENDING_BUTTON') ){
							statuses=concat(statuses,'Parts Pending');
						}
					}
					if((!consumerServiceRequest.isFree) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
						if (accessFn('TECHNICIAN_SUPERVISOR') && !isSymptomMapAdded()) {
							if (consumerServiceRequest && consumerServiceRequest.IsDeviceValidated) {
								statuses = concat(statuses, 'Iris submitted');
							}
						} else if (accessFn('TECHNICIAN_SUPERVISOR') && getSource()!='Bose'){
							statuses = concat(statuses, 'Service invoice generated');
						}
					}
					if (isBoseFlow) {
						statuses = concat(statuses, 'Visit productive');
						statuses = concat(statuses, 'Visit unproductive');
					}
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Repair completed');
					}
				}
			}
			//onsite end
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch', 'Repair completed', 'Parts pending']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Service cancel', 'Repair completed', 'Service completed', 'Parts pending']);
						// }
					}
					if(consumerServiceRequest && consumerServiceRequest.autoClaimFulfillmentFlow) {
						claimStatuses = ['Repair cancel', 'Service cancel', 'Repair completed']
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Service cancel', 'Repair completed', 'Service completed', 'Parts pending']);
						// }
					}
				}
				statuses = concat(statuses,claimStatuses)
			}
			//claim end
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Refund Rejected',  
		csrlStatus: 'Refund Rejected',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if(isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent()) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Feedback pending',  
		csrlStatus: 'Feedback pending',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Approve','Follow up','Reject');
					}
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'RET requested',  
		csrlStatus: 'RET requested',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if (consumerServiceRequest && consumerServiceRequest.isAnchorRequest) {
						statuses = concat(statuses, 'RET approved', 'Follow up','RET rejected');
					}
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Cancellation requested',  
		csrlStatus: 'Cancellation requested',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if(accessFn('CANCELLATION_APPROVAL')){
						statuses = concat(statuses,'Service cancel', 'Reject cancel');
					}
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Service cancel initiated',  
		csrlStatus: 'Service cancel initiated',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if(accessFn('CANCELLATION_APPROVAL')){
						statuses = concat(statuses,'Service cancel', 'Reject cancel');
					}
				}
			}
			return statuses;
		}
    },
	{
		//onsite
		csrStatus: 'Claim withdrawn',   //doubts
		csrlStatus: 'Claim withdrawn',
		computeNextStatus: function() {
			let statuses = [];
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			return statuses;
		}
	},
	{
		//onsite
		csrStatus: 'Claim withdrawn',   //doubts
		csrlStatus: 'Refund processed',
		computeNextStatus: function() {
			let statuses = [];
			return statuses;
		}
	},
	{
		//onsite
		csrStatus: 'Claim withdrawn',   //doubts
		csrlStatus: 'Refund initiated',
		computeNextStatus: function() {
			let statuses = [];
			return statuses;
		}
	},
	{
		//onsite
		csrStatus: 'Claim withdrawn',   //doubts
		csrlStatus: 'Refund failed',
		computeNextStatus: function() {
			let statuses = [];
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			return statuses;
		}
	},
	{
		//onsite
		csrStatus: 'Claim withdrawn',   //doubts
		csrlStatus: 'Payment Refund processed',
		computeNextStatus: function() {
			let statuses = [];
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			return statuses;
		}
	},
	{
		//onsite
		csrStatus: 'Claim withdrawn',   //doubts
		csrlStatus: 'Refund in-progress',
		computeNextStatus: function() {
			let statuses = [];
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			return statuses;
		}
	},
	{
		//onsite
        csrStatus: 'Replacement Request Generated',  
		csrlStatus: 'Replacement Request Generated',
		computeNextStatus: function() {
			let statuses = [];
			// if((accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && !(showRequestLogs.isServiceCompleteRated) && !(showRequestLogs.isServiceCancel)) {
            //     statuses = concat(statuses, 'Call attempted');
            // }
			if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.UNINSTALLATION, SERVICETYPE.INSPECTION].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REPLACEMENT_PROCESS')) {
				if(accessFn('STORE_REFUND_PROCESS')) {
					if((['SkyworthDashboard','SmartTechnoDashboard'].indexOf(consumerServiceRequest.Source) > -1)  || (consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID == 28 && [SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.DEMO, SERVICETYPE.SERVICING, SERVICETYPE.ONSITE_REPLACEMENT, SERVICETYPE.UNINSTALLATION, SERVICETYPE.INSPECTION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 )){
						statuses = concat(statuses, 'Refund Rejected', 'Refund Without Supplier Response');
					}else {
						statuses = concat(statuses, 'Refund Rejected', 'Awaiting Supplier Response', 'Refund Without Supplier Response');
					}
				}
			}
			
			if(refundProcessCheck){
				if (accessFn('STORE_REFUND_PROCESS')) {
					statuses =concat(statuses, 'Refund Rejected', 'Refund Without Supplier Response');
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'High Value Part Requested',  
		csrlStatus: 'High Value Part Requested',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.UNINSTALLATION, SERVICETYPE.INSPECTION].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REPLACEMENT_PROCESS')) {
				if(accessFn('STORE_REFUND_PROCESS')) {
					if((['SkyworthDashboard','SmartTechnoDashboard'].indexOf(consumerServiceRequest.Source) > -1)){
						statuses = concat(statuses, 'Refund Rejected', 'Refund Without Supplier Response');
					}
					else{
						statuses = concat(statuses, 'Refund Rejected', 'Awaiting Supplier Response', 'Refund Without Supplier Response');
					}
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Refund Rejected by Vendor',  
		csrlStatus: 'Refund Rejected by Vendor',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.UNINSTALLATION, SERVICETYPE.INSPECTION].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REPLACEMENT_PROCESS')) {
				if((accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && !(showRequestLogs.isServiceCompleteRated) && !(showRequestLogs.isServiceCancel)) {
					statuses = concat(statuses, 'Call attempted');
				}
				if(accessFn('STORE_REFUND_PROCESS')) {
					statuses = concat(statuses, 'Refund Rejected', 'Refund Without Supplier Response');
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Pending for Vendor Approval',
		csrlStatus: 'Pending for Vendor Approval',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.UNINSTALLATION, SERVICETYPE.INSPECTION].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REPLACEMENT_PROCESS')) {
				if(accessFn('VENDOR_REFUND')) {
					statuses = concat(statuses, 'Refund Approved', 'Refund Rejected by Vendor');
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Refund Without Supplier Response',
		csrlStatus: 'Refund Without Supplier Response',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.UNINSTALLATION, SERVICETYPE.INSPECTION].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REPLACEMENT_PROCESS')) {
				if(accessFn('STORE_REFUND_PROCESS')) {
					statuses = concat(statuses, 'Refund Approved');
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Awaiting Supplier Response',
		csrlStatus: 'Awaiting Supplier Response',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.UNINSTALLATION, SERVICETYPE.INSPECTION].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REPLACEMENT_PROCESS')) {
				if(accessFn('STORE_REFUND_PROCESS')) {
					statuses = concat(statuses, 'Refund Approved');
				}
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Service completed',  
		csrlStatus: 'Service completed',
		computeNextStatus: function() {
			let statuses = [];
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Create Refurb Request');
				if(!accessFn('CREATE_REFURB_REQUEST') || !consumerServiceRequest.RETURN_ORDER_NUMBER) {
					statuses = pull(statuses, 'Create Refurb Request');
				}
				if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					if (!showRequestLogs.isDefectiveReceived) {
						statuses = pull(statuses, 'Create Refurb Request');
					}
				}
				else {
					statuses = pull(statuses, 'Create Refurb Request');
				}
			}
			//end replacement flow
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Service completed',  
		csrlStatus: 'Failed to create Exchange order. Please retry',
		computeNextStatus: function() {
			let statuses = [];
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Create Refurb Request');
				if(!accessFn('CREATE_REFURB_REQUEST') || !consumerServiceRequest.RETURN_ORDER_NUMBER) {
					statuses = pull(statuses, 'Create Refurb Request');
				}
				if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					if (!showRequestLogs.isDefectiveReceived) {
						statuses = pull(statuses, 'Create Refurb Request');
					}
				}
				else {
					statuses = pull(statuses, 'Create Refurb Request');
				}
			}
			//end replacement flow
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Pending for acceptance',  
		csrlStatus: 'Pending for acceptance',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW')){
				if (isSupOrEngg()) {
					statuses = concat(statuses, 'Accept', 'Reject');
				}
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if ((qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter) && accessFn('TRANSFER_REQUEST')) {
							statuses = concat(statuses, 'Transfer request');
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(deviceAtLoggedInLoc && (!appleDropOffFlow || accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE'))){
							statuses = concat(statuses, 'Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics');
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
					}
				}
			}
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Pending for acceptance',  
		csrlStatus: 'Diagnosis initiated',
		computeNextStatus: function() {
			let statuses = [];
			let suitesStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW')){
				if (isSupOrEngg()) {
					statuses = concat(statuses, 'Accept', 'Reject');
				}
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if (showRequestValid.allowCCToProceedWithService) {
							statuses = concat(statuses, 'Transfer request');
						}
						if (!accessFn('STORE_MANAGEMENT')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses,'Cannot be diagnosed', 'Diagnosis completed');
								}else{
									statuses = concat(statuses, 'Initiate AST 2 Diagnostics','Cannot be diagnosed', 'Diagnosis completed');
								}
								if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
									suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
									statuses = concat(statuses, suitesStatuses)
								}else if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.gsxInfo && consumerServiceRequest.consumerProduct.gsxInfo.serialNumber && (!arrDiagnosisSuites || (arrDiagnosisSuites && !arrDiagnosisSuites.length)) && !diagnosticsSuitesCalled) {
									if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
										suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
										statuses = concat(statuses, suitesStatuses)
									}
								}
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}
			}
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Pending for acceptance',  
		csrlStatus: 'Diagnosis completed',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW')){
				if (isSupOrEngg()) {
					statuses = concat(statuses, 'Accept', 'Reject');
				}
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if (showRequestValid.allowCCToProceedWithService) {
							statuses = concat(statuses, 'Transfer request');
						}
						if (!accessFn('STORE_MANAGEMENT')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}
			}
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel','Document pending','Replacement authorized', 'Reimbursement approved');
				if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
					statuses = pull(statuses, 'Replacement authorized');
				}

				if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !checkDeviceValueAndDateAdded()) {
					statuses = pull(statuses, 'Replacement authorized');
				}

				if([SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !checkDeviceValueAndDateAdded()) {
					statuses = pull(statuses, 'Reimbursement approved');
				}
			}
			//end replacement flow
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Pending for acceptance',  
		csrlStatus: 'Cannot be diagnosed',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW')){
				if (isSupOrEngg()) {
					statuses = concat(statuses, 'Accept', 'Reject');
				}
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if (showRequestValid.allowCCToProceedWithService) {
							statuses = concat(statuses, 'Transfer request');
						}
						if (!accessFn('STORE_MANAGEMENT')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}
			}
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Pending for acceptance',  
		csrlStatus: 'Advance collected',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW')){
				if (isSupOrEngg()) {
					statuses = concat(statuses, 'Accept', 'Reject');
				}
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if (showRequestValid.allowCCToProceedWithService) {
							statuses = concat(statuses, 'Transfer request');
						}
						if (!accessFn('STORE_MANAGEMENT')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Pending for acceptance',  
		csrlStatus: 'Device handed over',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW')){
				if (isSupOrEngg()) {
					statuses = concat(statuses, 'Accept', 'Reject');
				}
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if ((qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter) && accessFn('TRANSFER_REQUEST')) {
							statuses = concat(statuses, 'Transfer request');
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics');
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
					}
				}
			}
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Pending for acceptance',  
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW')){
				if (isSupOrEngg()) {
					statuses = concat(statuses, 'Accept', 'Reject');
				}
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if ((qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter) && accessFn('TRANSFER_REQUEST')) {
							statuses = concat(statuses, 'Transfer request');
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics');
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
					}
				}
			}
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Pending for acceptance',  
		csrlStatus: 'Token released',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW')){
				if (isSupOrEngg()) {
					statuses = concat(statuses, 'Accept', 'Reject');
				}
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if ((qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter) && accessFn('TRANSFER_REQUEST')) {
							statuses = concat(statuses, 'Transfer request');
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics');
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
					}
				}
			}
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Pending for acceptance',  
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW')){
				if (isSupOrEngg()) {
					statuses = concat(statuses, 'Accept', 'Reject');
				}
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('STORE_MANAGEMENT')) {
						if ((qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter) && accessFn('TRANSFER_REQUEST')) {
							statuses = concat(statuses, 'Transfer request');
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics');
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
					}
				}
			}
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Claim raised',  
		csrlStatus: 'Claim raised',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			if(claimNewWorkshopChecks){
				if (accessFn('CLAIM_WORKSHOP')) {
					statuses = concat(statuses, 'Claim initiated', 'Claim invalid');
				}
			}
			//claim
			if(claimChecks){
				if (accessFn('CLAIM_APPROVAL')) {
					if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						claimStatuses = concat(claimStatuses, 'Under review', 'Claim invalid');
					}
					else {
						if(accessFn('REOPEN_SERVICE')){
							if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID)> -1 ){
								claimStatuses = concat(claimStatuses, 'Claim initiated', 'Claim invalid');
							}
						}else{
							if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID)> -1 ){
								claimStatuses = concat(claimStatuses, 'Claim initiated', 'Claim invalid');
							}
						}
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
			return statuses;
		}
    },
	
	{
		//claim new workshop
        csrStatus: 'Claim invalid',  
		csrlStatus: 'Claim invalid',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(claimNewWorkshopChecks){
				if (accessFn('CLAIM_WORKSHOP')) {
					statuses = concat(statuses, 'Service cancel', 'Claim raised');
				}
			}
			//claim 
			if(claimChecks){
				if (accessFn('CLAIM_APPROVAL')) {
					if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						claimStatuses = concat(claimStatuses, 'Service cancel', 'Claim raised');
					}
					else {
						if(accessFn('REOPEN_SERVICE')){
							if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID)> -1 ){
								claimStatuses = concat(claimStatuses, 'Service cancel', 'Claim raised');
							}
						}else{
							if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID)> -1 ){
								claimStatuses = concat(claimStatuses, 'Service cancel');
							}
						}
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
			
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Claim initiated',  
		csrlStatus: 'Claim initiated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(claimNewWorkshopChecks){
				if (accessFn('CLAIM_WORKSHOP')) {
					statuses = concat(statuses, 'Document pending', 'Claim rejected', 'In-principle approved');
				}
			}
			//end claim new workshop
			//claim
			if(claimChecks){
				if(accessFn('CLAIM_APPROVAL')){
					if(accessFn('REOPEN_SERVICE')){
						if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID)> -1 ){
							claimStatuses = concat(claimStatuses,'Document pending', 'In-principle approved', 'Claim rejected');
						}
					}else{
						if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID)> -1 ){
							claimStatuses = concat(claimStatuses,'Document pending', 'In-principle approved', 'Claim rejected');
						}
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
			
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Document pending',  
		csrlStatus: 'Document pending',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			if(claimNewWorkshopChecks){
				if (accessFn('CLAIM_WORKSHOP')) {
					statuses = concat(statuses, 'Claim rejected');
				}
			}
			//claim
			if(claimChecks){
				if(accessFn('CLAIM_APPROVAL')){
					if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						claimStatuses = concat(claimStatuses,'Claim rejected');
					}else {
						if(accessFn('REOPEN_SERVICE')){
							if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
								claimStatuses = concat(claimStatuses,'Claim rejected');
							}
						}else{
							// if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
								claimStatuses = concat(claimStatuses,'Claim rejected');
							// }
						}
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel');
			}
			//end replacement flow
			//ber start
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					if(showRequestLogs.BERConfirmed && berFlags.defectiveRequired){
						statuses = concat (statuses,'BER accessories received','BER device received','BER accessories validated','BER accessories rejected', 'Call attempted');
					}else{
						statuses = concat(statuses,'Call attempted');
					}
				}
			}
			//ber end

			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'BER Repair',  
		csrlStatus: 'BER Repair',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					statuses = concat(statuses, 'Repair completed');
				}
			}
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'BER Repair',  
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					statuses = concat(statuses, 'Repair completed');
				}
			}
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'BER Repair',  
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					statuses = concat(statuses, 'Repair completed');
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'BER approved',  
		csrlStatus: 'BER approved',
		computeNextStatus: function() {
			let statuses = [];
			//claim new workshop
			if(claimNewWorkshopChecks){
				if(accessFn('BER_WORKSHOP')) {
					statuses = concat(statuses, 'BER confirmed', 'BER cancel');
					}
					if (isSupOrEngg()) {
					statuses = concat(statuses, 'Repair cancel');
				}
			}
			//end claim new workshop
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					// if(berFlags.allowRefundBtn){
					// 	statuses = concat(statuses,'BER confirmed','BER repair');
					// }
					berFlags.allowRefundBtn && statuses.push('BER confirmed');
					berFlags.allowRepairBtn && statuses.push('BER repair');
					berFlags.allowReplacementBtn && statuses.push('BER replacement confirmed');
					// if(berFlags.allowReplacementBtn){
					// 	statuses = concat(statuses,'BER replacement confirmed');
					// }
					statuses = concat(statuses,'BER cancel','Call attempted');
				}
			}
			//ber end 
			return statuses;
		}
    },
	{
        csrStatus: 'BER approved',  
		csrlStatus: 'BER identified',
		computeNextStatus: function() {
			let statuses = [];
			//claim new workshop
			if(claimNewWorkshopChecks){
				if(accessFn('BER_WORKSHOP')) {
					statuses = concat(statuses, 'BER confirmed', 'BER cancel');
					}
					if (isSupOrEngg()) {
					statuses = concat(statuses, 'Repair cancel');
				}
			}
			//end claim new workshop
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					if(berFlags.allowRefundBtn){
						statuses = concat(statuses,'BER confirmed','BER repair');
					}
					if(berFlags.allowReplacementBtn){
						statuses = concat(statuses,'BER replacement confirmed');
					}
					statuses = concat(statuses,'BER cancel','Call attempted');
				}
			}
			//ber end 
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'BER identified',  
		csrlStatus: 'BER identified',
		computeNextStatus: function() {
			let statuses = [];
			//claim new 
			if(claimNewWorkshopChecks){
				if(accessFn('BER_WORKSHOP')) {
					statuses = concat(statuses, 'BER confirmed', 'BER cancel');
				}
				if (isSupOrEngg()) {
					statuses = concat(statuses, 'Repair cancel');
				}
			}
			// end claim new
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'BER approved', 'Call attempted','Document pending', 'Claim rejected');
				}
			}
			//ber end 
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Document verification pending',  
		csrlStatus: 'Document verification pending',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses = concat(statuses,document);
			// 	})
			// })
			if(claimNewWorkshopChecks){
				if (accessFn('CLAIM_WORKSHOP')) {
					statuses = concat(statuses, 'Document pending', 'In-principle approved', 'Claim rejected');
				}
			}
			//claim
			if(claimChecks){
				if (accessFn('CLAIM_APPROVAL')) {
					if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						claimStatuses = concat(claimStatuses, 'Document pending', 'In-principle approved', 'Claim rejected');
					}else {
						if(accessFn('REOPEN_SERVICE')){
							if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
								claimStatuses = concat(claimStatuses, 'Document pending', 'In-principle approved', 'Claim rejected');
							}
						}else{
							if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
								claimStatuses = concat(claimStatuses, 'Document pending', 'In-principle approved', 'Claim rejected');
							}
						}
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel','Document pending','Replacement authorized', 'Reimbursement approved');
				if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
					statuses = pull(statuses, 'Replacement authorized');
					if(!checkDeviceValueAndDateAdded()){
						statuses = pull(statuses, 'Replacement authorized');
					}
				}
				if([63].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
					statuses = pull(statuses, 'Reimbursement approved');
				}
				if([SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !checkDeviceValueAndDateAdded()) {
					statuses = pull(statuses, 'Reimbursement approved');
				}
			}
			//end replacement flow
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					if(showRequestLogs.BERConfirmed){
						if(berFlags.defectiveRequired){
							statuses = concat(statuses,'BER documents validated','Document pending','BER accessories received','BER device received','BER accessories validated','BER accessories rejected', 'Claim rejected', 'Call attempted');
						}else{
							statuses = concat(statuses,'BER documents validated','Document pending','Request BER Payment', 'Claim rejected','Call attempted');
						}
					}else{
						statuses = concat(statuses,'BER approved', 'Call attempted','Document pending', 'Claim rejected');
					}
				}
				
			}
			//ber end 
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Parts rejected',  
		csrlStatus: 'Engineer reassigned',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
						statuses = concat(statuses, 'Repair completed');
						// buttons when device is with engineer
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if(isSupOrEngg()){
						if(isHandedToEng()){
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
									statuses = concat(statuses, 'Update KGB');
								}else{
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}else{
					if(isSupOrEngg()){
						if(isHandedToEng()){
						  	statuses = concat(statuses, 'Repair completed');
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Parts rejected',  
		csrlStatus: 'Parts rejected',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
						statuses = concat(statuses, 'Repair completed');
						// buttons when device is with engineer
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if(isSupOrEngg()){
						if(isHandedToEng()){
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
									statuses = concat(statuses, 'Update KGB');
								}else{
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}else{
					if(isSupOrEngg()){
						if(isHandedToEng()){
							if(isRequestSFANeeded){
								if((requestSFA && requestSFA.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)) {
									statuses = concat(statuses, 'Repair completed');
								}
							}else{
								if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)) {
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Parts rejected',  
		csrlStatus: 'Device received',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
						statuses = concat(statuses, 'Repair completed');
						// buttons when device is with engineer
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(!accessFn('GSX_FLOW')){
					if(isSupOrEngg()){
						if(isHandedToEng()){
							if(isRequestSFANeeded){
								if((requestSFA && requestSFA.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)) {
									statuses = concat(statuses, 'Repair completed');
								}
							}else{
								if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)) {
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Parts rejected',  
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
						if (accessFn('GSX_FLOW')){
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb){
									statuses = concat(statuses, 'Update KGB');
								}else{
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}else{
							statuses = concat(statuses, 'Repair completed');
						}
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
					if(deviceAtLoggedInLoc){
						statuses = concat(statuses, 'Repair cancel');
					}
				}
			}
			// Pickup carry in end 
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'QC rejected',  
		csrlStatus: 'Engineer reassigned',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
					// buttons when device is with engineer
							if(!isDiagnosisInitiated()){
							statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
							}
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			if(accessFn('GSX_FLOW')){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'QC rejected',  
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
					// buttons when device is with engineer
							if(!isDiagnosisInitiated()){
							statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
							}
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}else{
					if(isSupOrEngg()){
						if(isHandedToEng()) {
							if(!isDiagnosisInitiated()){
								statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
							}
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'QC rejected',  
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (isHandedToEng()) {
					// buttons when device is with engineer
							if(!isDiagnosisInitiated()){
							statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
							}
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}else{
					if(isSupOrEngg()){
						if(isHandedToEng()) {
							if(!isDiagnosisInitiated()){
								statuses = concat(statuses, 'Diagnosis initiated', 'Cannot be diagnosed');
							}
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
		//claim new workshop
        csrStatus: 'Diagnosis initiated',  
		csrlStatus: 'Diagnosis initiated',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					statuses = concat(statuses, 'Cannot be diagnosed', 'Diagnosis completed');
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}else{
					if (isSupOrEngg()) {
						statuses = concat(statuses, 'Diagnosis completed', 'Cannot be diagnosed');
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		},
	},
	{
		csrStatus: 'Parts issued',
		csrlStatus: 'Parts issued',
		computeNextStatus: function () {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn("REPAIR_DEVICE")) {
						if (showRequestValid.isAllPartsRejected) {
							statuses = concat(statuses, 'Repair completed');
						}
					}
				}else{
					if(accessFn("REPAIR_DEVICE")){
						if(showRequestValid.isAllPartsRejected){
							if(isRequestSFANeeded){
								if((requestSFA && requestSFA.length > 0) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
									statuses = concat(statuses, 'Repair completed');
								}
							}else{
								if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Parts issued',
		csrlStatus: 'Device details validated',
		computeNextStatus: function () {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn("REPAIR_DEVICE")) {
						if (showRequestValid.isAllPartsRejected) {
							statuses = concat(statuses, 'Repair completed');
						}
					}
				}else{
					if(accessFn("REPAIR_DEVICE")){
						if(showRequestValid.isAllPartsRejected){
							if(isRequestSFANeeded){
								if((requestSFA && requestSFA.length > 0) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
									statuses = concat(statuses, 'Repair completed');
								}
							}else{
								if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    }, 
	{
		csrStatus: 'Parts issued',
		csrlStatus: 'Device accepted',
		computeNextStatus: function () {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn("REPAIR_DEVICE")) {
						if (showRequestValid.isAllPartsRejected) {
							statuses = concat(statuses, 'Repair completed');
						}
					}
				}else{
					if(accessFn("REPAIR_DEVICE")){
						if(showRequestValid.isAllPartsRejected){
							if(isRequestSFANeeded){
								if((requestSFA && requestSFA.length > 0) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
									statuses = concat(statuses, 'Repair completed');
								}
							}else{
								if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Parts issued',
		csrlStatus: 'Device handed over',
		computeNextStatus: function () {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(!accessFn('GSX_FLOW')){
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Parts partially issued',
		csrlStatus: 'Parts partially issued',
		computeNextStatus: function () {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(!accessFn('GSX_FLOW')){
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Parts partially issued',
		csrlStatus: 'Device accepted',
		computeNextStatus: function () {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(!accessFn('GSX_FLOW')){
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Parts partially issued',
		csrlStatus: 'Device handed over',
		computeNextStatus: function () {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(!accessFn('GSX_FLOW')){
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
        csrStatus: 'Diagnosis completed', 
		csrlStatus: 'Diagnosis completed',
		computeNextStatus: function() {
			let statuses = [];
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					let isIssuesValidated = true
					for (let iCount = 0;iCount<consumerServiceRequest.issues.length;iCount++) {
						if (consumerServiceRequest.issues[iCount].validityStatus == 0 || !consumerServiceRequest.issues[iCount].isValidated) {
							isIssuesValidated = false;
						}
					}

					let isDeviceValidated = find(consumerServiceRequest.logs,{Status: 'Device details validated'});
					if (isIssuesValidated && isDeviceValidated) {
						statuses = concat(statuses, 'Repair completed');
					}
					// Device details validated and issues validated
					// statuses = concat(statuses, 'Repair complete');
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// end claim new workshop
			
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if(isSupOrEngg()){
						if(showRequestLogs.isIssuesValidated && showRequestLogs.isDeviceValidated){
							statuses = concat(statuses, 'Repair completed');
						}
					}
					if(isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && showRequestValid.sendIssuesGSXFlag){
						if(accessFn('REPAIR_DEVICE')){
							if(!consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !consumerServiceRequest.ProvisionalGSXNumber){
									if(!consumerServiceRequest.GSX_REPAIR_SERVICE_APPROACH){
										statuses = concat(statuses, 'Select Service Approach');
									}else{
										statuses = concat(statuses, 'Request raised'); 
									}
								}else{
									statuses = concat(statuses, 'Request raised'); 
								}
							}
							if(consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Update KGB');
								}else{
									statuses = concat(statuses, 'Repair completed');
								}
							}
						}
						if(accessFn('REASSIGN_JOBS')){
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
								statuses = concat(statuses, 'Map G-Number');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')  || accessFn('STORE_MANAGEMENT')){
						if(consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && accessFn('STORE_MANAGEMENT') && consumerServiceRequest.allowReceiveFromRC){
							statuses = concat(statuses, 'Receive device from RC');
						}
						if(consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId == SERVICETYPE.CARRY_IN && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && accessFn('STORE_MANAGEMENT') && consumerServiceRequest.enableFetchGSXStatus){
							statuses = concat(statuses, 'Fetch GSX Status');
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}else{
					if(isSupOrEngg()){
						if(isRequestSFANeeded){
							if((requestSFA && requestSFA.length > 0 && showRequestLogs.isDeviceValidated) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
								statuses = concat(statuses, 'Repair completed');
							}
						}else{
							if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 && showRequestLogs.isDeviceValidated) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)) {
								statuses = concat(statuses, 'Repair completed');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
		csrStatus: 'Initiate logistics request',
		csrlStatus: 'Initiate logistics request', // checked
		computeNextStatus: function() {
			let statuses = [];
			if(accessFn('ACCEPT_RETURN_DEVICE')){
				if(logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1){
					statuses.push('Device dispatched');
				}
			}
			return statuses;
		}
	},{
		csrStatus: 'Initiate logistics request', // checked
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						statuses.push('Device dispatched');
					}
				}
			}
			// end claim new
			return statuses;
		}
	},{
		csrStatus: 'Intimate customer', // checked
		csrlStatus: 'Intimate customer',
		computeNextStatus: function() {
			let statuses = [];
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) {
						statuses = concat(statuses, 'Intimate customer' , 'Service completed');
					}else {
						statuses.push('Intimate customer');
					}
				}
			}
			// end claim new
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(consumerServiceRequest && !consumerServiceRequest.loanerDetails){
							statuses = concat(statuses, 'Intimate customer');
						}
						if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN){
							if(consumerServiceRequest.IsWarrantyApplicable){
								if(consumerServiceRequest && consumerServiceRequest.loanerDetails  && consumerServiceRequest.loanerDetails.loanerRequested){
									if(['LORC', 'LOCN', 'LOUN'].indexOf(consumerServiceRequest.loanerDetails.currentStatus) > -1){
										statuses = concat(statuses,  'Service completed');
									}
								} else {
									statuses = concat(statuses,  'Service completed');
								}
							}else{
								statuses = concat(statuses,  'Service completed');
							}
						}
					}
				}else{
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						statuses = concat(statuses, 'Intimate customer');
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN) {
							statuses = concat(statuses,  'Service completed');
						}
					}
				}
			}
			
			// Pickup carry in end
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Service completed', 'BER device received', 'BER accessories validated','BER accessories rejected', 'Call attempted');
				}
				if(!isReplacementPrerequisitesApplied ) {
					setIsReplacementPrerequisitesApplied(true)
					statuses = pullAll(statuses, ['BER replacement confirmed', 'BER replacement issued', 'BER shipment created', 'Add Shipment Details', 'Intimate customer', 'Device dispatched', 'BER accessories received']);
				}
			}
			//ber end 
			return statuses;
		}
	}, {
		csrStatus: 'Intimate customer', // checked
		csrlStatus: 'Token released',
		computeNextStatus: function() {
			let statuses = [];
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) {
					statuses = concat(statuses, 'Intimate customer' , 'Service completed');
					}else {
						statuses.push('Intimate customer');
					}
				}
			}
			//end claim new
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						statuses = concat(statuses, 'Intimate customer');
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN) {
							statuses = concat(statuses,  'Service completed');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN) {
							statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
						else {
							statuses.push('Intimate customer');
						}
					}
				}
			}
			
			// Pickup carry in end
			return statuses;
		}
    }, {
		csrStatus: 'Intimate customer', // checked
		csrlStatus: 'Token generated',
		computeNextStatus: function() {
			let statuses = [];
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) {
						statuses = concat(statuses, 'Intimate customer' , 'Service completed');
					}else {
						statuses.push('Intimate customer');
					}
				}
			}
			//end claim new
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN) {
							statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
						else {
							statuses = concat(statuses,'Intimate customer');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN) {
							statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}else {
							statuses = concat(statuses,'Intimate customer');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	}, {
		csrStatus: 'Intimate customer', // checked
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) {
						statuses = concat(statuses, 'Intimate customer' , 'Service completed');
					}else {
						statuses.push('Intimate customer');
					}
				}
			}
			//end claim new
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses,'Intimate customer');
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN) {
							statuses = concat(statuses,'Service completed');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN) {
							statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}else {
							statuses = concat (statuses,'Intimate customer');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},{
		csrStatus: 'Intimate customer', // checked
		csrlStatus: 'Engineer reassigned',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					statuses = concat(statuses, 'Intimate customer');
					if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN) {
						statuses = concat(statuses,  'Service completed');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	}, {
		csrStatus: 'Intimate customer',
		csrlStatus: 'Engineer reassigned',
		computeNextStatus: function() {
			let statuses = [];
			//pick up carry in gsx
			if(pickupCarryInGSXChecks &&  consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat (statuses,'Intimate customer');
					if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE) {
						statuses = concat (statuses,'Service completed');
					}
				}
			}
			//end pick up carry in gsx
			return statuses;
		}
	},{
		csrStatus: 'QC pending', // checked
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('QC_DEVICE')) {
					statuses = concat(statuses, 'Perform QC');

					if(consumerServiceRequest.allowQCDiagnosis && showQCInitiateDiagnosis()) {
					statuses = concat(statuses, 'Diagnosis initiated');
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			//end claim work
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('QC_DEVICE')) {
						statuses = concat(statuses, 'QC rejected', 'QC completed');
					}
				}else{
					if (accessFn('QC_DEVICE')) {
						statuses = concat(statuses, 'Perform QC');
						if(consumerServiceRequest.allowQCDiagnosis && showQCInitiateDiagnosis()) {
							statuses = concat(statuses, 'Diagnosis initiated');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	}, {
		csrStatus: 'QC pending', 
		csrlStatus: 'Engineer reassigned',
		computeNextStatus: function() {
		let statuses = [];
		//claim new 
		if(claimNewWorkshopChecks){
			if (accessFn('QC_DEVICE')) {
				statuses = concat(statuses, 'Perform QC');

				if(consumerServiceRequest.allowQCDiagnosis && showQCInitiateDiagnosis()) {
				statuses = concat(statuses, 'Diagnosis initiated');
				}
			}
			if (accessFn('ACCEPT_RETURN_DEVICE')) {
			statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
			}
		}
		//end claim new
		// Pickup carry in 
		if(pickupCarryInChecks){
			if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
				if (accessFn('QC_DEVICE')) {
					statuses = concat(statuses, 'QC rejected', 'QC completed');
				}
			}else{
				if (accessFn('QC_DEVICE')) {
					statuses = concat(statuses, 'Perform QC');
					if(consumerServiceRequest.allowQCDiagnosis && showQCInitiateDiagnosis()) {
						statuses = concat(statuses, 'Diagnosis initiated');
					}
				}
			}
		}
		// Pickup carry in end
        return statuses;
		}
    }, {
		csrStatus: 'QC pending', 
		csrlStatus: 'Token released',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (accessFn('QC_DEVICE')) {
					statuses = concat(statuses, 'Perform QC');

					if(consumerServiceRequest.allowQCDiagnosis && showQCInitiateDiagnosis()) {
						statuses = concat(statuses, 'Diagnosis initiated');
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('QC_DEVICE')) {
						statuses = concat(statuses, 'QC rejected', 'QC completed'); //not used
					}
				}else{
					if (accessFn('QC_DEVICE')) {
						statuses = concat(statuses, 'Perform QC');
						if(consumerServiceRequest.allowQCDiagnosis && showQCInitiateDiagnosis()) {
							statuses = concat(statuses, 'Diagnosis initiated');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	}, {
		csrStatus: 'QC pending',
		csrlStatus: 'Diagnosis initiated',
		computeNextStatus: function() {
			let statuses = [];
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('QC_DEVICE')) {
					statuses = concat(statuses, 'Diagnosis completed', 'Cannot be diagnosed');
				}
			}
			//end claim new
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(!accessFn('GSX_FLOW')) {
					if (accessFn('QC_DEVICE')) {
						statuses = concat(statuses, 'Diagnosis completed', 'Cannot be diagnosed');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    }, {
		csrStatus: 'QC pending',
		csrlStatus: 'Diagnosis completed',
		computeNextStatus: function() {
			let statuses = [];
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('QC_DEVICE')) {
					statuses = concat(statuses, 'Perform QC');
				}
			}
			//end claim new
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(!accessFn('GSX_FLOW')) {
					if (accessFn('QC_DEVICE')) {
						statuses = concat(statuses, 'Perform QC');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    }, {
		csrStatus: 'QC pending',
		csrlStatus: 'Cannot be diagnosed',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (accessFn('QC_DEVICE')) {
					statuses = concat(statuses, 'Perform QC');
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(!accessFn('GSX_FLOW')) {
					if (accessFn('QC_DEVICE')) {
						statuses = concat(statuses, 'Perform QC');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    }, {
		csrStatus: 'QC pending',
		csrlStatus: 'Device handed over',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (accessFn('QC_DEVICE')) {
					statuses = concat(statuses, 'Perform QC');
				}
			}
			// Pickup carry in 
			// Pickup carry in end
			return statuses;
		}
    },{
		csrStatus: 'QC completed', 
		csrlStatus: 'QC completed',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
						if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
						statuses = concat(statuses, 'Device dispatched');
						} else {
						statuses = concat(statuses, 'Initiate logistics request');
						}
					} else if ((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) && consumerServiceRequest.CheckInType != 'Walkin') {
						// statuses.push('Intimate customer');
						statuses.push('Intimate customer');
					} else if ((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) && consumerServiceRequest.CheckInType == 'Walkin') {
						statuses = concat(statuses, 'Intimate customer' , 'Service completed');
					} else {
						// pass
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(showRequestValid.allowCCToProceedWithService) {
							if(consumerServiceRequest.ServiceTypeID == 1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
								statuses = concat(statuses, 'Payment received');
							}else if(consumerServiceRequest.ServiceTypeID == 1){
								if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
									statuses = concat(statuses, 'Service invoice generated');
								}else if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
									statuses = concat(statuses, 'Device dispatched');
								}else{
									statuses = concat(statuses, 'Initiate logistics request');
								}
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin' && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
								statuses = concat(statuses, 'Payment received');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
								statuses = concat(statuses, 'Intimate customer');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin' && consumerServiceRequest.consumerServiceRequestFeedback && consumerServiceRequest.consumerServiceRequestFeedback.length > 0 && consumerServiceRequest.consumerServiceRequestFeedback[0]['Rating']){
								statuses = concat(statuses,'Service completed');
							}
						}else{
							statuses = concat(statuses, 'Transfer request');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
							statuses = concat(statuses, 'Service invoice generated');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
							statuses = concat(statuses, 'Intimate customer');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin') {
				  			statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			//refurbishment flow
			if(refurbishmentFlowCheck){
				statuses = concat(statuses,'Service completed','Return to Sender')
			}
			//refurbishment flow end
			return statuses;
		}
	},{
		csrStatus: 'QC completed', // checked
		csrlStatus: 'Token released',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
						if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
						statuses = concat(statuses, 'Device dispatched');
						} else {
						statuses = concat(statuses, 'Initiate logistics request');
						}
					} else if ((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) && consumerServiceRequest.CheckInType != 'Walkin') {
						// statuses.push('Intimate customer');
						statuses.push('Intimate customer');
					} else if ((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) && consumerServiceRequest.CheckInType == 'Walkin') {
						statuses = concat(statuses, 'Intimate customer' , 'Service completed');
					} else {
						// pass
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(showRequestValid.allowCCToProceedWithService) {
							if(consumerServiceRequest.ServiceTypeID == 1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
								statuses = concat(statuses, 'Payment received');
							}else if(consumerServiceRequest.ServiceTypeID == 1){
								if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
									statuses = concat(statuses, 'Service invoice generated');
								}else if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
									statuses = concat(statuses, 'Device dispatched');
								}else{
									statuses = concat(statuses, 'Initiate logistics request');
								}
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin' && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
								statuses = concat(statuses, 'Payment received');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
								statuses = concat(statuses, 'Intimate customer');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin' && consumerServiceRequest.consumerServiceRequestFeedback && consumerServiceRequest.consumerServiceRequestFeedback.length > 0 && consumerServiceRequest.consumerServiceRequestFeedback[0]['Rating']){
								statuses = concat(statuses,'Service completed');
							}
						}else{
							statuses = concat(statuses, 'Transfer request');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
							statuses = concat(statuses, 'Service invoice generated');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
							statuses = concat(statuses, 'Intimate customer');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin') {
							statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},{
		csrStatus: 'QC completed', // checked
		csrlStatus: 'Token generated',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
						if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
						statuses = concat(statuses, 'Device dispatched');
						} else {
						statuses = concat(statuses, 'Initiate logistics request');
						}
					} else if ((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) && consumerServiceRequest.CheckInType != 'Walkin') {
						// statuses.push('Intimate customer');
						statuses.push('Intimate customer');
					} else if ((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) && consumerServiceRequest.CheckInType == 'Walkin') {
						statuses = concat(statuses, 'Intimate customer' , 'Service completed');
					} else {
						// pass
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(showRequestValid.allowCCToProceedWithService) {
							if(consumerServiceRequest.ServiceTypeID == 1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
								statuses = concat(statuses, 'Payment received');
							}else if(consumerServiceRequest.ServiceTypeID == 1){
								if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
									statuses = concat(statuses, 'Service invoice generated');
								}else if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
									statuses = concat(statuses, 'Device dispatched');
								}else{
									statuses = concat(statuses, 'Initiate logistics request');
								}
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin' && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
								statuses = concat(statuses, 'Payment received');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
								statuses = concat(statuses, 'Intimate customer');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin' && consumerServiceRequest.consumerServiceRequestFeedback && consumerServiceRequest.consumerServiceRequestFeedback.length > 0 && consumerServiceRequest.consumerServiceRequestFeedback[0]['Rating']){
								statuses = concat(statuses,'Service completed');
							}
						}else{
							statuses = concat(statuses, 'Transfer request');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
							statuses = concat(statuses, 'Service invoice generated');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
							statuses = concat(statuses, 'Intimate customer');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin') {
				  			statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},{
		csrStatus: 'QC completed', // checked
		csrlStatus: 'Payment received',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(!accessFn('GSX_FLOW')){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
							statuses = concat(statuses, 'Service invoice generated');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
							statuses = concat(statuses, 'Intimate customer');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin') {
				  			statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
    {
		csrStatus: 'QC completed', //checked
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
						if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
							statuses = concat(statuses, 'Device dispatched');
						} else {
							statuses = concat(statuses, 'Initiate logistics request');
						}
					} else if ((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) && consumerServiceRequest.CheckInType != 'Walkin') {
					// statuses.push('Intimate customer');
					statuses.push('Intimate customer');
					} else if ((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) && consumerServiceRequest.CheckInType == 'Walkin') {
					statuses = concat(statuses, 'Intimate customer' , 'Service completed');
					} else {
						// pass
					}
				}	
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(showRequestValid.allowCCToProceedWithService) {
							if(consumerServiceRequest.ServiceTypeID == 1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
								statuses = concat(statuses, 'Payment received');
							}else if(consumerServiceRequest.ServiceTypeID == 1){
								if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
									statuses = concat(statuses, 'Service invoice generated');
								}else if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
									statuses = concat(statuses, 'Device dispatched');
								}else{
									statuses = concat(statuses, 'Initiate logistics request');
								}
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin' && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
								statuses = concat(statuses, 'Payment received');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
								statuses = concat(statuses, 'Intimate customer');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin' && consumerServiceRequest.consumerServiceRequestFeedback && consumerServiceRequest.consumerServiceRequestFeedback.length > 0 && consumerServiceRequest.consumerServiceRequestFeedback[0]['Rating']){
								statuses = concat(statuses,'Service completed');
							}
						}else{
							statuses = concat(statuses, 'Transfer request');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
							statuses = concat(statuses, 'Service invoice generated');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
							statuses = concat(statuses, 'Intimate customer');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin') {
				  			statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	}, {
		csrStatus: 'QC completed', //checked
		csrlStatus: 'Waiting for Assignment',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
						if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
							statuses = concat(statuses, 'Device dispatched');
						} else {
							statuses = concat(statuses, 'Initiate logistics request');
						}
					} else if ((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN)&& consumerServiceRequest.CheckInType != 'Walkin') {
					// statuses.push('Intimate customer');
					statuses.push('Intimate customer');
					} else if ((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) && consumerServiceRequest.CheckInType == 'Walkin') {
					statuses = concat(statuses, 'Intimate customer' , 'Service completed');
					} else {
					// pass
					}
				}		
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(showRequestValid.allowCCToProceedWithService) {
							if(consumerServiceRequest.ServiceTypeID == 1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
								statuses = concat(statuses, 'Payment received');
							}else if(consumerServiceRequest.ServiceTypeID == 1){
								if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
									statuses = concat(statuses, 'Service invoice generated');
								}else if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
									statuses = concat(statuses, 'Device dispatched');
								}else{
									statuses = concat(statuses, 'Initiate logistics request');
								}
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin' && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
								statuses = concat(statuses, 'Payment received');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
								statuses = concat(statuses, 'Intimate customer');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin' && consumerServiceRequest.consumerServiceRequestFeedback && consumerServiceRequest.consumerServiceRequestFeedback.length > 0 && consumerServiceRequest.consumerServiceRequestFeedback[0]['Rating']){
								statuses = concat(statuses,'Service completed');
							}
						}else{
							statuses = concat(statuses, 'Transfer request');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
							statuses = concat(statuses, 'Service invoice generated');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
							statuses = concat(statuses, 'Intimate customer');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin') {
				  			statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},{
		csrStatus: 'QC completed', // checked
		csrlStatus: 'Device handed over',
		computeNextStatus: function() {
			let statuses = [];
			if(accessFn('CANCEL_REVERT_INVENTORY') && showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
				statuses = concat(statuses, 'Repair cancel initiated');
			}
			return statuses;
		}
	}, {
		csrStatus: 'QC completed', 
		csrlStatus: 'Security deposit released',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
						if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
						statuses = concat(statuses, 'Device dispatched');
						} else {
						statuses = concat(statuses, 'Initiate logistics request');
						}
					} else if ((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) && consumerServiceRequest.CheckInType != 'Walkin') {
						// statuses.push('Intimate customer');
						statuses.push('Intimate customer');
					} else if ((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) && consumerServiceRequest.CheckInType == 'Walkin') {
						statuses = concat(statuses, 'Intimate customer' , 'Service completed');
					} else {
						// pass
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(showRequestValid.allowCCToProceedWithService) {
							if(consumerServiceRequest.ServiceTypeID == 1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
								statuses = concat(statuses, 'Payment received');
							}else if(consumerServiceRequest.ServiceTypeID == 1){
								if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
									statuses = concat(statuses, 'Service invoice generated');
								}else if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
									statuses = concat(statuses, 'Device dispatched');
								}else{
									statuses = concat(statuses, 'Initiate logistics request');
								}
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin' && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
								statuses = concat(statuses, 'Payment received');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
								statuses = concat(statuses, 'Intimate customer');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin' && consumerServiceRequest.consumerServiceRequestFeedback && consumerServiceRequest.consumerServiceRequestFeedback.length > 0 && consumerServiceRequest.consumerServiceRequestFeedback[0]['Rating']){
								statuses = concat(statuses,'Service completed');
							}
						}else{
							statuses = concat(statuses, 'Transfer request');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
							statuses = concat(statuses, 'Service invoice generated');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
							statuses = concat(statuses, 'Intimate customer');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin') {
				  			statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},{
		csrStatus: 'QC completed', 
		csrlStatus: 'Card-hold charge failed',
		computeNextStatus: function() {
			let statuses = [];
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
						if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
						statuses = concat(statuses, 'Device dispatched');
						} else {
						statuses = concat(statuses, 'Initiate logistics request');
						}
					} else if ((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) && consumerServiceRequest.CheckInType != 'Walkin') {
						// statuses.push('Intimate customer');
						statuses.push('Intimate customer');
					} else if ((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) && consumerServiceRequest.CheckInType == 'Walkin') {
						statuses = concat(statuses, 'Intimate customer' , 'Service completed');
					} else {
						// pass
					}
				}
			}
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(showRequestValid.allowCCToProceedWithService) {
							if(consumerServiceRequest.ServiceTypeID == 1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
								statuses = concat(statuses, 'Payment received');
							}else if(consumerServiceRequest.ServiceTypeID == 1){
								if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
									statuses = concat(statuses, 'Service invoice generated');
								}else if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
									statuses = concat(statuses, 'Device dispatched');
								}else{
									statuses = concat(statuses, 'Initiate logistics request');
								}
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin' && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
								statuses = concat(statuses, 'Payment received');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
								statuses = concat(statuses, 'Intimate customer');
							}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin' && consumerServiceRequest.consumerServiceRequestFeedback && consumerServiceRequest.consumerServiceRequestFeedback.length > 0 && consumerServiceRequest.consumerServiceRequestFeedback[0]['Rating']){
								statuses = concat(statuses,'Service completed');
							}
						}else{
							statuses = concat(statuses, 'Transfer request');
						}
					}
				}else{
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
								statuses = concat(statuses, 'Service invoice generated');
							}else if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
							statuses = concat(statuses, 'Payment received');
							if(accessFn('SEND_PAYMENT_LINK')){
								statuses = concat(statuses, 'Send payment link');
							}
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton){
							statuses = concat(statuses, 'Service invoice generated');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin'){
							statuses = concat(statuses, 'Intimate customer');
						}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin') {
				  			statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},{
		csrStatus: 'BER rejected',
		csrlStatus: 'BER rejected',
		computeNextStatus: function () {
			let statuses = [];
			let claimStatuses = [];
			if(claimNewWorkshopChecks ){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses,'Repair cancel');
				}
			}
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses,['Repair cancel'])
						// }
					}
				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses,['Repair cancel'])
						// }
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses,['Repair cancel'])
						// }
					}
				}
				statuses = concat(statuses,claimStatuses)
			}
			//claim end
			return statuses;
		}
    },
	{
		csrStatus: 'Payment pending',
		csrlStatus: 'Payment pending',
		computeNextStatus: function () {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
					if((!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck())){
						statuses = concat(statuses,'Payment Received')
					}
					if(!consumerServiceRequest.showEngineerVisits  && accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Cancel Service')
					}
				}
			}
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Payment received','Repair cancel','Cancel Service Without Dispatch');
				}
				accessFn('SEND_PAYMENT_LINK') && statuses.push('Send payment link');
			}
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Service cancel']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if(!accessFn('SEND_PAYMENT_LINK')) {
					// claimStatuses = claimStatuses.pull('Send payment link');
					claimStatuses = pull(claimStatuses, 'Send payment link');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Resend Payment Link','Payment received','Service cancel');
				if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					statuses = pull(statuses, 'Payment received');
				}	
			}
			//end replacement flow
			return statuses;
		}
	}, {
		csrStatus: 'Payment pending',
		csrlStatus: 'Device details validated',
		computeNextStatus: function () {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
					if((!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck())){
						statuses = concat(statuses,'Payment Received')
					}
					if(!consumerServiceRequest.showEngineerVisits  && accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Cancel Service')
					}
				}
			}
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Payment received','Repair cancel','Cancel Service Without Dispatch');
				}
				accessFn('SEND_PAYMENT_LINK') && statuses.push('Send payment link');
			}
			return statuses;
		}
    }, {
		csrStatus: 'Payment pending',
		csrlStatus: 'Engineer reassigned',
		computeNextStatus: function () {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
					if((!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck())){
						statuses = concat(statuses,'Payment Received')
					}
					if(!consumerServiceRequest.showEngineerVisits  && accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Cancel Service')
					}
				}
			}
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Payment received','Repair cancel','Cancel Service Without Dispatch');
				}
				accessFn('SEND_PAYMENT_LINK') && statuses.push('Send payment link');
			}

			//claim 
			//end claim
			return statuses;
		}
    }, {
		csrStatus: 'Payment pending',
		csrlStatus: 'Device accepted',
		computeNextStatus: function () {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
					if((!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck())){
						statuses = concat(statuses,'Payment Received')
					}
					if(!consumerServiceRequest.showEngineerVisits  && accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Cancel Service')
					}
				}
			}
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Payment received','Repair cancel','Cancel Service Without Dispatch');
				}
				accessFn('SEND_PAYMENT_LINK') && statuses.push('Send payment link');
			}
			return statuses;
		}
	}, {
		csrStatus: 'Payment pending',
		csrlStatus: 'Exception rejected',
		computeNextStatus: function () {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
					if((!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck())){
						statuses = concat(statuses,'Payment Received')
					}
					if(!consumerServiceRequest.showEngineerVisits  && accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Cancel Service')
					}
				}
			}
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Payment received','Repair cancel','Cancel Service Without Dispatch');
				}
				accessFn('SEND_PAYMENT_LINK') && statuses.push('Send payment link');
			}
			return statuses;
		}
    },
	{
		csrStatus: 'Payment pending',
		csrlStatus: 'Service cancel',
		computeNextStatus: function () {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
					if((!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck())){
						statuses = concat(statuses,'Payment Received')
					}
					if(!consumerServiceRequest.showEngineerVisits  && accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Cancel Service')
					}
				}
			}
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Payment received','Repair cancel','Cancel Service Without Dispatch');
				}
				accessFn('SEND_PAYMENT_LINK') && statuses.push('Send payment link');
			}
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Service cancel']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if(!accessFn('SEND_PAYMENT_LINK')) {
					// claimStatuses = claimStatuses.pull('Send payment link');
					claimStatuses = pull(claimStatuses, 'Send payment link');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Resend Payment Link','Payment received','Service cancel');
				if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					statuses = pull(statuses, 'Payment received');
				}	
			}
			//end replacement flow
			return statuses;
		}
	},
	{
		csrStatus: 'Payment pending',
		csrlStatus: 'Payment received',
		computeNextStatus: function () {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
					if((!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck())){
						statuses = concat(statuses,'Payment Received')
					}
					if(!consumerServiceRequest.showEngineerVisits  && accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Cancel Service')
					}
				}
			}
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Payment received','Repair cancel','Cancel Service Without Dispatch');
				}
				accessFn('SEND_PAYMENT_LINK') && statuses.push('Send payment link');
			}
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Service cancel']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if(!accessFn('SEND_PAYMENT_LINK')) {
					// claimStatuses = claimStatuses.pull('Send payment link');
					claimStatuses = pull(claimStatuses, 'Send payment link');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Resend Payment Link','Payment received','Service cancel');
				if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					statuses = pull(statuses, 'Payment received');
				}	
			}
			//end replacement flow
			return statuses;
		}
	},
	{
		csrStatus: 'Payment pending',
		csrlStatus: 'Card-hold failed',
		computeNextStatus: function () {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
					if((!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck())){
						statuses = concat(statuses,'Payment Received')
					}
					if(!consumerServiceRequest.showEngineerVisits  && accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Cancel Service')
					}
				}
			}
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Payment received','Repair cancel','Cancel Service Without Dispatch');
				}
				accessFn('SEND_PAYMENT_LINK') && statuses.push('Send payment link');
			}
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Service cancel']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if(!accessFn('SEND_PAYMENT_LINK')) {
					// claimStatuses = claimStatuses.pull('Send payment link');
					claimStatuses = pull(claimStatuses, 'Send payment link');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Resend Payment Link','Payment received','Service cancel');
				if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					statuses = pull(statuses, 'Payment received');
				}	
			}
			//end replacement flow
			return statuses;
		}
	},
		{
		csrStatus: 'Payment pending',
		csrlStatus: 'Card-hold charge failed',
		computeNextStatus: function () {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
					if((!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck())){
						statuses = concat(statuses,'Payment Received')
					}
					if(!consumerServiceRequest.showEngineerVisits  && accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Cancel Service')
					}
				}
			}
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Payment received','Repair cancel','Cancel Service Without Dispatch');
				}
				accessFn('SEND_PAYMENT_LINK') && statuses.push('Send payment link');
			}
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Service cancel']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if(!accessFn('SEND_PAYMENT_LINK')) {
					// claimStatuses = claimStatuses.pull('Send payment link');
					claimStatuses = pull(claimStatuses, 'Send payment link');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Resend Payment Link','Payment received','Service cancel');
				if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					statuses = pull(statuses, 'Payment received');
				}	
			}
			//end replacement flow
			return statuses;
		}
	},{
		csrStatus: 'Payment pending',
		csrlStatus: 'Payment failed',
		computeNextStatus: function () {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
					if((!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck())){
						statuses = concat(statuses,'Payment Received')
					}
					if(!consumerServiceRequest.showEngineerVisits  && accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Cancel Service')
					}
				}
			}
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Payment received','Repair cancel','Cancel Service Without Dispatch');
				}
				accessFn('SEND_PAYMENT_LINK') && statuses.push('Send payment link');
			}
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Service cancel']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if(!accessFn('SEND_PAYMENT_LINK')) {
					// claimStatuses = claimStatuses.pull('Send payment link');
					claimStatuses = pull(claimStatuses, 'Send payment link');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Resend Payment Link','Payment received','Service cancel');
				if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					statuses = pull(statuses, 'Payment received');
				}	
			}
			//end replacement flow
			return statuses;
		}
	}, 
	{
		csrStatus: 'Payment pending',
		csrlStatus: 'Authorization refund in-progress',
		computeNextStatus: function () {
			let statuses = [];
			let claimStatuses = [];
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Resend Payment Link','Payment received','Service cancel');
				if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					statuses = pull(statuses, 'Payment received');
				}	
			}
			//end replacement flow
			return statuses;
		}
	},
	{
        csrStatus: 'Claim rejected',   //not completed
		csrlStatus: 'Claim rejected',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//onsite
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
				}
			}
			//onste end
			//claim
			if(claimChecks){
				if(consumerServiceRequest.returnFlow && !(consumerServiceRequest.returnFlow.IsCancelled)){
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Device dispatched']
					}
				}else{
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched']
					}
				}
				if (accessFn('CLAIM_APPROVAL')) {
					if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						claimStatuses = concat(claimStatuses,'Service cancel', 'Under review');
					}
					else {
						if(accessFn('REOPEN_SERVICE')){
							claimStatuses = concat(claimStatuses,'Service cancel', 'Claim initiated');
						}else{
							claimStatuses = concat(claimStatuses,'Service cancel');
						}
					}
					
				}
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses,'Service cancel', 'Device dispatched');
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses, 'Service cancel', 'Device dispatched');
					}
					if (accessFn('ADD_NORMAL_CHARGES')  ) {
						claimStatuses = concat(claimStatuses, 'Service cancel', 'Device dispatched');
					}
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses, 'Service cancel');
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = concat(claimStatuses,'Service cancel');
					}
				}
				
				if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1 && [SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
					if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
						claimStatuses = ['Device dispatched'];
					}else{
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				claimStatuses = uniq(claimStatuses);
				statuses = concat(statuses,claimStatuses)
			}
			//end claim
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Service cancel');
				}
			}
			//ber end 
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel')
			}
			////replacement flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Claim rejected',   //not completed
		csrlStatus: 'Refund initiated',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//onsite
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
				}
			}
			//onste end
			//claim
			if(claimChecks){
				if(consumerServiceRequest.returnFlow && !(consumerServiceRequest.returnFlow.IsCancelled)){
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Device dispatched']
					}
				}else{
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched']
					}
				}
				if (accessFn('CLAIM_APPROVAL')) {
					if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						claimStatuses = concat(claimStatuses,'Service cancel', 'Under review');
					}
					else {
						if(accessFn('REOPEN_SERVICE')){
							claimStatuses = concat(claimStatuses,'Service cancel', 'Claim initiated');
						}else{
							claimStatuses = concat(claimStatuses,'Service cancel');
						}
					}
					
				}
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses,'Service cancel', 'Device dispatched');
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses, 'Service cancel', 'Device dispatched');
					}
					if (accessFn('ADD_NORMAL_CHARGES')  ) {
						claimStatuses = concat(claimStatuses, 'Service cancel', 'Device dispatched');
					}
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses, 'Service cancel');
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = concat(claimStatuses,'Service cancel');
					}
				}
				
				if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1 && [SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
					if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
						claimStatuses = ['Device dispatched'];
					}else{
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				claimStatuses = uniq(claimStatuses);
				statuses = concat(statuses,claimStatuses)
			}
			//end claim
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Service cancel');
				}
			}
			//ber end 
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel')
			}
			////replacement flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Claim rejected',   //not completed
		csrlStatus: 'Refund failed',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//onsite
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
				}
			}
			//onste end
			//claim
			if(claimChecks){
				if(consumerServiceRequest.returnFlow && !(consumerServiceRequest.returnFlow.IsCancelled)){
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Device dispatched']
					}
				}else{
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched']
					}
				}
				if (accessFn('CLAIM_APPROVAL')) {
					if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						claimStatuses = concat(claimStatuses,'Service cancel', 'Under review');
					}
					else {
						if(accessFn('REOPEN_SERVICE')){
							claimStatuses = concat(claimStatuses,'Service cancel', 'Claim initiated');
						}else{
							claimStatuses = concat(claimStatuses,'Service cancel');
						}
					}
					
				}
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses,'Service cancel', 'Device dispatched');
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses, 'Service cancel', 'Device dispatched');
					}
					if (accessFn('ADD_NORMAL_CHARGES')  ) {
						claimStatuses = concat(claimStatuses, 'Service cancel', 'Device dispatched');
					}
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses, 'Service cancel');
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = concat(claimStatuses,'Service cancel');
					}
				}

				if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1 && [SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
					if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
						claimStatuses = ['Device dispatched'];
					}else{
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				claimStatuses = uniq(claimStatuses);
				statuses = concat(statuses,claimStatuses)
			}
			//end claim
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Service cancel');
				}
			}
			//ber end 
			return statuses;
		}
    },
	{
        csrStatus: 'Claim rejected',   //not completed
		csrlStatus: 'Refund processed',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//onsite
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
				}
			}
			//onste end
			//claim
			if(claimChecks){
				if(consumerServiceRequest.returnFlow && !(consumerServiceRequest.returnFlow.IsCancelled)){
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Device dispatched']
					}
				}else{
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched']
					}
				}
				if (accessFn('CLAIM_APPROVAL')) {
					if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						claimStatuses = concat(claimStatuses,'Service cancel', 'Under review');
					}
					else {
						if(accessFn('REOPEN_SERVICE')){
							claimStatuses = concat(claimStatuses,'Service cancel', 'Claim initiated');
						}else{
							claimStatuses = concat(claimStatuses,'Service cancel');
						}
					}
					
				}
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses,'Service cancel', 'Device dispatched');
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses, 'Service cancel', 'Device dispatched');
					}
					if (accessFn('ADD_NORMAL_CHARGES')  ) {
						claimStatuses = concat(claimStatuses, 'Service cancel', 'Device dispatched');
					}
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses, 'Service cancel');
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = concat(claimStatuses,'Service cancel');
					}
				}
				
				if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1 && [SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
					if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
						claimStatuses = ['Device dispatched'];
					}else{
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				claimStatuses = uniq(claimStatuses);
				statuses = concat(statuses,claimStatuses)
			}
			//end claim
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Service cancel');
				}
			}
			//ber end 
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel')
			}
			////replacement flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Claim rejected',   //not completed
		csrlStatus: 'Payment Refund processed',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//onsite
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
				}
			}
			//onste end
			//claim
			if(claimChecks){
				if(consumerServiceRequest.returnFlow && !(consumerServiceRequest.returnFlow.IsCancelled)){
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Device dispatched']
					}
				}else{
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched']
					}
				}
				if (accessFn('CLAIM_APPROVAL')) {
					if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						claimStatuses = concat(claimStatuses,'Service cancel', 'Under review');
					}
					else {
						if(accessFn('REOPEN_SERVICE')){
							claimStatuses = concat(claimStatuses,'Service cancel', 'Claim initiated');
						}else{
							claimStatuses = concat(claimStatuses,'Service cancel');
						}
					}
					
				}
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses,'Service cancel', 'Device dispatched');
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses, 'Service cancel', 'Device dispatched');
					}
					if (accessFn('ADD_NORMAL_CHARGES')  ) {
						claimStatuses = concat(claimStatuses, 'Service cancel', 'Device dispatched');
					}
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses, 'Service cancel');
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = concat(claimStatuses,'Service cancel');
					}
				}
				
				if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1 && [SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
					if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
						claimStatuses = ['Device dispatched'];
					}else{
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				claimStatuses = uniq(claimStatuses);
				statuses = concat(statuses,claimStatuses)
			}
			//end claim
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Service cancel');
				}
			}
			//ber end 
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel')
			}
			////replacement flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Claim rejected',   //not completed
		csrlStatus: 'Refund in-progress',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//onsite
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
						}
					}
				}
			}
			//onste end
			//claim
			if(claimChecks){
				if(consumerServiceRequest.returnFlow && !(consumerServiceRequest.returnFlow.IsCancelled)){
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Device dispatched']
					}
				}else{
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched']
					}
				}
				if (accessFn('CLAIM_APPROVAL')) {
					if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						claimStatuses = concat(claimStatuses,'Service cancel', 'Under review');
					}
					else {
						if(accessFn('REOPEN_SERVICE')){
							claimStatuses = concat(claimStatuses,'Service cancel', 'Claim initiated');
						}else{
							claimStatuses = concat(claimStatuses,'Service cancel');
						}
					}
					
				}
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses,'Service cancel', 'Device dispatched');
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses, 'Service cancel', 'Device dispatched');
					}
					if (accessFn('ADD_NORMAL_CHARGES')  ) {
						claimStatuses = concat(claimStatuses, 'Service cancel', 'Device dispatched');
					}
				}else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('CLAIM_APPROVAL') ) {
						claimStatuses = concat(claimStatuses, 'Service cancel');
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = concat(claimStatuses,'Service cancel');
					}
				}
				
				if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1 && [SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
					if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
						claimStatuses = ['Device dispatched'];
					}else{
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				claimStatuses = uniq(claimStatuses);
				statuses = concat(statuses,claimStatuses)
			}
			//end claim
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Service cancel');
				}
			}
			//ber end 
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel')
			}
			////replacement flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Device details validated',   //not completed
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			return statuses;
		}
    },
	{
        csrStatus: 'Packaging material shipped',   //not completed
		csrlStatus: 'Packaging material shipped',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses,['Device received', 'Service cancel', 'Cancel Service Without Dispatch'])
						// }
					}
				} 
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			return statuses;
		}
    },
	{
        csrStatus: 'Courier details updated',   //not completed
		csrlStatus: 'Courier details updated',
		computeNextStatus: function() {
			let statuses = [];
			//claim
			if(claimChecks){
				if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						statuses = merge(statuses, ['Device received', 'Service cancel']);
					}
				}
			}
			//claim end
			return statuses;
		}
    },
	{
        csrStatus: 'Call attempted',   //not completed
		csrlStatus: 'Call attempted',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			//claim
			if(claimChecks){
				if (accessFn('CLAIM_APPROVAL')) {
					if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						claimStatuses = concat(claimStatuses, 'Under review', 'Claim invalid');
					}
					else {
						if(accessFn('REOPEN_SERVICE')){
							if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
								claimStatuses = concat(claimStatuses, 'Claim initiated', 'Claim invalid');
							}
						}else{
							if([SERVICETYPE.CLAIM].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
								claimStatuses = concat(claimStatuses, 'Claim initiated', 'Claim invalid');
							}
						}
					}
				}
				statuses = (statuses,claimStatuses);
			}
			//end claim
			return statuses;
		}
    },
	{
        csrStatus: 'Under review',   //not completed
		csrlStatus: 'Under review',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses =[];
			//claim
			if(claimChecks){
				if (accessFn('CLAIM_APPROVAL')) {
					if ([SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						claimStatuses = merge(claimStatuses, ['Document pending', 'In-principle approved', 'Claim rejected']);
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement request raised',  
		csrlStatus: 'Replacement request raised',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Service cancel','Replacement authorized');
				}else{
					statuses = concat(statuses,'Service cancel','Document pending','Claim rejected','Replacement authorized');
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement request raised',  
		csrlStatus: 'Subscription created',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Service cancel','Replacement authorized');
				}else{
					statuses = concat(statuses,'Service cancel','Document pending','Claim rejected','Replacement authorized');
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement request raised',  
		csrlStatus: 'Card-hold failed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Service cancel','Replacement authorized');
				}else{
					statuses = concat(statuses,'Service cancel','Document pending','Claim rejected','Replacement authorized');
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement request raised',  
		csrlStatus: 'Card-hold charge failed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Service cancel','Replacement authorized');
				}else{
					statuses = concat(statuses,'Service cancel','Document pending','Claim rejected','Replacement authorized');
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement request raised',  
		csrlStatus: 'Security deposit released',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Service cancel','Replacement authorized');
				}else{
					statuses = concat(statuses,'Service cancel','Document pending','Claim rejected','Replacement authorized');
				}
			}
			return statuses;
		}
	},
	{
		csrStatus: 'Replacement request raised',
		csrlStatus: 'Payment Authorization refund successful',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Service cancel','Replacement authorized');
				}else{
					statuses = concat(statuses,'Service cancel','Document pending','Claim rejected','Replacement authorized');
				}
			}
			return statuses;
		}
	},
	{
        csrStatus: 'Reimbursement request raised',  
		csrlStatus: 'Reimbursement request raised',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel','Document pending');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement authorized',  
		csrlStatus: 'Replacement authorized',
		computeNextStatus: function() {
			let statuses = [];
			let replacementStatuses = []
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE){
					replacementStatuses = ['Service cancel','Replacement allocated', 'Replacement options provided','Replacement unavailable','Create Exchange Order','Claim rejected']
				}else{
					replacementStatuses = ['Service cancel','Replacement allocated', 'Replacement options provided','Replacement unavailable','Create Exchange Order']
				}
				if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !checkDeviceValueAndDateAdded()) {
					replacementStatuses = ['Service cancel'];
				}
				if(!accessFn('ADD_REPLACEMENT_OPTIONS')) {
					replacementStatuses = pull( replacementStatuses, 'Replacement options provided');
				}
				if((consumerServiceRequest.SHOW_CREATE_AE_ORDER!=="true") || (!accessFn('CREATE_EXCHANGE_ORDER'))){
					replacementStatuses = pull(replacementStatuses, 'Create Exchange Order');
				}
				if (!accessFn('ALLOCATE_DEVICE')) {
					replacementStatuses = pull(replacementStatuses, 'Replacement allocated');
				}
				if (!accessFn('REPLACEMENT_UNAVAILABLE')) {
					replacementStatuses = pull(replacementStatuses, 'Replacement unavailable');
				}
				statuses = concat(statuses,replacementStatuses)
			}
			return statuses;
		},
    },
	{
        csrStatus: 'Replacement authorized',  
		csrlStatus: 'Card-hold charge failed',
		computeNextStatus: function() {
			let statuses = [];
			let replacementStatuses = []
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE){
					replacementStatuses = ['Service cancel','Replacement allocated', 'Replacement options provided','Replacement unavailable','Create Exchange Order','Claim rejected']
				}else{
					replacementStatuses = ['Service cancel','Replacement allocated', 'Replacement options provided','Replacement unavailable','Create Exchange Order']
				}
				if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !checkDeviceValueAndDateAdded()) {
					replacementStatuses = ['Service cancel'];
				}
				if(!accessFn('ADD_REPLACEMENT_OPTIONS')) {
					replacementStatuses = pull(replacementStatuses, 'Replacement options provided');
				}
				if((consumerServiceRequest.SHOW_CREATE_AE_ORDER!=="true") || (!accessFn('CREATE_EXCHANGE_ORDER'))){
					replacementStatuses = pull(replacementStatuses, 'Create Exchange Order');
				}
				if (!accessFn('ALLOCATE_DEVICE')) {
					replacementStatuses = pull(replacementStatuses, 'Replacement allocated');
				}
				if (!accessFn('REPLACEMENT_UNAVAILABLE')) {
					replacementStatuses = pull(replacementStatuses, 'Replacement unavailable');
				}
				statuses = concat(statuses,replacementStatuses);
			}
			return statuses;
		},
    },
	{
        csrStatus: 'Replacement authorized',  
		csrlStatus: 'Exchange order created successfully',
		computeNextStatus: function() {
			let statuses = [];
			let replacementStatuses = []
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE){
					replacementStatuses = ['Service cancel','Replacement allocated', 'Replacement options provided','Replacement unavailable','Create Exchange Order','Claim rejected']
				}else{
					replacementStatuses = ['Service cancel','Replacement allocated', 'Replacement options provided','Replacement unavailable','Create Exchange Order']
				}
				if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !checkDeviceValueAndDateAdded()) {
					replacementStatuses = ['Service cancel'];
				}
				if(!accessFn('ADD_REPLACEMENT_OPTIONS')) {
					replacementStatuses = pull( replacementStatuses, 'Replacement options provided');
				}
				if((consumerServiceRequest.SHOW_CREATE_AE_ORDER!=="true") || (!accessFn('CREATE_EXCHANGE_ORDER'))){
					replacementStatuses = pull(replacementStatuses, 'Create Exchange Order');
				}
				if (!accessFn('ALLOCATE_DEVICE')) {
					replacementStatuses = pull(replacementStatuses, 'Replacement allocated');
				}
				if (!accessFn('REPLACEMENT_UNAVAILABLE')) {
					replacementStatuses = pull(replacementStatuses, 'Replacement unavailable');
				}
				statuses = concat(statuses,replacementStatuses)
			}
			return statuses;
		},
    },
	{
        csrStatus: 'Replacement authorized',  
		csrlStatus: 'Security deposit released',
		computeNextStatus: function() {
			let statuses = [];
			let replacementStatuses = [];
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE){
					replacementStatuses = ['Service cancel','Replacement allocated', 'Replacement options provided','Replacement unavailable','Create Exchange Order','Claim rejected']
				}else{
					replacementStatuses = ['Service cancel','Replacement allocated', 'Replacement options provided','Replacement unavailable','Create Exchange Order']
				}
				if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !checkDeviceValueAndDateAdded()) {
					replacementStatuses = ['Service cancel'];
				}
				if(!accessFn('ADD_REPLACEMENT_OPTIONS')) {
					replacementStatuses = pull( replacementStatuses, 'Replacement options provided');
				}
				if((consumerServiceRequest.SHOW_CREATE_AE_ORDER!=="true") || (!accessFn('CREATE_EXCHANGE_ORDER'))){
					replacementStatuses = pull(replacementStatuses, 'Create Exchange Order');
				}
				if (!accessFn('ALLOCATE_DEVICE')) {
					replacementStatuses = pull(replacementStatuses, 'Replacement allocated');
				}
				if (!accessFn('REPLACEMENT_UNAVAILABLE')) {
					replacementStatuses = pull(replacementStatuses, 'Replacement unavailable');
				}
				statuses = concat(statuses,replacementStatuses)
			}
			return statuses;
		},
    },
	{
        csrStatus: 'Replacement authorized',  
		csrlStatus: 'Failed to create Exchange order. Please retry',
		computeNextStatus: function() {
			let statuses = [];
			let replacementStatuses = []
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE){
					replacementStatuses = ['Service cancel','Replacement allocated', 'Replacement options provided','Replacement unavailable','Create Exchange Order','Claim rejected']
				}else{
					replacementStatuses = ['Service cancel','Replacement allocated', 'Replacement options provided','Replacement unavailable','Create Exchange Order']
				}
				if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !checkDeviceValueAndDateAdded()) {
					replacementStatuses = ['Service cancel'];
				}
				if(!accessFn('ADD_REPLACEMENT_OPTIONS')) {
					replacementStatuses = pull( replacementStatuses, 'Replacement options provided');
				}
				if((consumerServiceRequest.SHOW_CREATE_AE_ORDER!=="true") || (!accessFn('CREATE_EXCHANGE_ORDER'))){
					replacementStatuses = pull(replacementStatuses, 'Create Exchange Order');
				}
				if (!accessFn('ALLOCATE_DEVICE')) {
					replacementStatuses = pull(replacementStatuses, 'Replacement allocated');
				}
				if (!accessFn('REPLACEMENT_UNAVAILABLE')) {
					replacementStatuses = pull(replacementStatuses, 'Replacement unavailable');
				}
				statuses = concat(statuses,replacementStatuses)
			}
			return statuses;
		},
    },
	{
        csrStatus: 'Replacement authorized',  
		csrlStatus: 'Payment Authorization refund successful',
		computeNextStatus: function() {
			let statuses = [];
			let replacementStatuses = []
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE){
					replacementStatuses = ['Service cancel','Replacement allocated', 'Replacement options provided','Replacement unavailable','Create Exchange Order','Claim rejected']
				}else{
					replacementStatuses = ['Service cancel','Replacement allocated', 'Replacement options provided','Replacement unavailable','Create Exchange Order']
				}
				if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !checkDeviceValueAndDateAdded()) {
					replacementStatuses = ['Service cancel'];
				}
				if(!accessFn('ADD_REPLACEMENT_OPTIONS')) {
					replacementStatuses = pull( replacementStatuses, 'Replacement options provided');
				}
				if((consumerServiceRequest.SHOW_CREATE_AE_ORDER!=="true") || (!accessFn('CREATE_EXCHANGE_ORDER'))){
					replacementStatuses = pull(replacementStatuses, 'Create Exchange Order');
				}
				if (!accessFn('ALLOCATE_DEVICE')) {
					replacementStatuses = pull(replacementStatuses, 'Replacement allocated');
				}
				if (!accessFn('REPLACEMENT_UNAVAILABLE')) {
					replacementStatuses = pull(replacementStatuses, 'Replacement unavailable');
				}
				statuses = concat(statuses,replacementStatuses)
			}
			return statuses;
		},
    },
	{
		csrStatus: 'Provisional estimate generated',  
		csrlStatus: 'Provisional estimate generated',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected','Repair cancel');
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
			
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses.push('Advance collected');
							}
							if(showRequestLogs.isHardwareClosure && isAdvancePaidCheck()){
								statuses = concat(statuses,'Repair completed');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device inspected', 'Service cancel');
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
        csrStatus: 'Replacement allocated',  
		csrlStatus: 'Replacement allocated',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Replacement shipment created');
				}else if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 66){
					statuses = concat(statuses,'Service completed');
				}else{
					statuses = concat(statuses,'Replacement shipment created','Add Shipment Details','Service completed');
				}
			}
			return statuses;
		},
    },
	{
        csrStatus: 'Replacement allocated',  
		csrlStatus: 'QC completed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Replacement shipment created');
				}else if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 66){
					statuses = concat(statuses,'Service completed');
				}else{
					statuses = concat(statuses,'Replacement shipment created','Add Shipment Details','Service completed');
				}
			}
			return statuses;
		},
    },
	{
        csrStatus: 'Replacement allocated',  
		csrlStatus: 'Service cancel',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Replacement shipment created');
				}else if(consumerServiceRequest.ServiceTypeID == 66){
					statuses = concat(statuses,'Service completed');
				}else{
					statuses = concat(statuses,'Replacement shipment created','Add Shipment Details','Service completed');
				}
			}
			return statuses;
		},
    },
	{
        csrStatus: 'Replacement allocated',  
		csrlStatus: 'Security deposit released',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Replacement shipment created');
				}else if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 66){
					statuses = concat(statuses,'Service completed');
				}else{
					statuses = concat(statuses,'Replacement shipment created','Add Shipment Details','Service completed');
				}
			}
			return statuses;
		},
    },
	{
        csrStatus: 'Replacement allocated',  
		csrlStatus: 'Card-hold charge failed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Replacement shipment created');
				}else if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 66){
					statuses = concat(statuses,'Service completed');
				}else{
					statuses = concat(statuses,'Replacement shipment created','Add Shipment Details','Service completed');
				}
			}
			return statuses;
		},
    },
	{
		csrStatus: 'Provisional estimate generated',  
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected','Repair cancel');
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
			
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses.push('Advance collected');
							}
							if(showRequestLogs.isHardwareClosure && isAdvancePaidCheck()){
								statuses = concat(statuses,'Repair completed');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device inspected', 'Service cancel');
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Provisional estimate generated',  
		csrlStatus: 'Diagnosis initiated',
		computeNextStatus: function() {
			let statuses = [];
			let suitesStatuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if(deviceAtLoggedInLoc){
							if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
								statuses = concat(statuses,'Cannot be diagnosed', 'Diagnosis completed');
							}else{
								statuses = concat(statuses, 'Initiate AST 2 Diagnostics','Cannot be diagnosed', 'Diagnosis completed');
							}
							if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
								suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
								statuses = concat(statuses, suitesStatuses)
							}else if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey && (!arrDiagnosisSuites || (arrDiagnosisSuites && !arrDiagnosisSuites.length)) && !diagnosticsSuitesCalled) {
								if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
									suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
									statuses = concat(statuses, suitesStatuses)
								}
							}
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Provisional estimate generated',  
		csrlStatus: 'Diagnosis completed',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected', 'Repair cancel');
							}
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
								if(deviceAtLoggedInLoc){
									if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
										statuses = concat(statuses, 'Initiate Diagnostics');
									}
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable  && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses = concat(statuses, 'Advance collected');
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
							if(showRequestLogs.isHardwareClosure && isAdvancePaidCheck()){
								statuses = concat(statuses,'Repair completed');
							}
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Provisional estimate generated',  
		csrlStatus: 'Cannot be diagnosed',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected', 'Repair cancel');
							}
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
								if(deviceAtLoggedInLoc){
									if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
										statuses = concat(statuses, 'Initiate Diagnostics');
									}
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable  && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses = concat(statuses, 'Advance collected');
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
							if(showRequestLogs.isHardwareClosure && isAdvancePaidCheck()){
								statuses = concat(statuses,'Repair completed');
							}
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Provisional estimate generated',  
		csrlStatus: 'Advance collected',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected', 'Repair cancel');
							}
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
								if(deviceAtLoggedInLoc){
									if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
										statuses = concat(statuses, 'Initiate Diagnostics');
									}
								}
							}
							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable  && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses = concat(statuses, 'Advance collected');
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
							if(showRequestLogs.isHardwareClosure && isAdvancePaidCheck()){
								statuses = concat(statuses,'Repair completed');
							}
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
        csrStatus: 'Replacement shipment created',  
		csrlStatus: 'Replacement shipment created',
		computeNextStatus: function() {
			let statuses = [];
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Device dispatched');
				}else{
					statuses = concat(statuses,'Device dispatched','Service cancel');
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement shipment created',  
		csrlStatus: 'Failed to create Exchange order. Please retry',
		computeNextStatus: function() {
			let statuses = [];
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Device dispatched');
				}else{
					statuses = concat(statuses,'Device dispatched','Service cancel');
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement shipment created',  
		csrlStatus: 'Security deposit released',
		computeNextStatus: function() {
			let statuses = [];
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Device dispatched');
				}else{
					statuses = concat(statuses,'Device dispatched','Service cancel');
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement shipment created',  
		csrlStatus: 'Card-hold charge failed',
		computeNextStatus: function() {
			let statuses = [];
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			if(replacementFlowChecks){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Device dispatched');
				}else{
					statuses = concat(statuses,'Device dispatched','Service cancel');
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Reimbursement approved',  
		csrlStatus: 'Reimbursement approved',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,'Enter check number');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement options provided',  
		csrlStatus: 'Replacement options provided',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel', 'View options', 'Choose option');
			}
			if(!accessFn('SELECT_REPLACEMENT_OPTION')) {
				statuses = pull(statuses, 'Choose option');
			}
			if(!accessFn('VIEW_REPLACEMENT_OPTIONS')) {
				statuses = pull(statuses, 'View options');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement options provided',  
		csrlStatus: 'Security deposit released',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel', 'View options', 'Choose option');
			}
			if(!accessFn('SELECT_REPLACEMENT_OPTION')) {
				statuses = pull(statuses, 'Choose option');
			}
			if(!accessFn('VIEW_REPLACEMENT_OPTIONS')) {
				statuses = pull(statuses, 'View options');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement options provided',  
		csrlStatus: 'Card-hold charge failed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel', 'View options', 'Choose option');
			}
			if(!accessFn('SELECT_REPLACEMENT_OPTION')) {
				statuses = pull(statuses, 'Choose option');
			}
			if(!accessFn('VIEW_REPLACEMENT_OPTIONS')) {
				statuses = pull(statuses, 'View options');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement options provided',  
		csrlStatus: 'Failed to create Exchange order. Please retry',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel', 'View options', 'Choose option');
			}
			if(!accessFn('SELECT_REPLACEMENT_OPTION')) {
				statuses = pull(statuses, 'Choose option');
			}
			if(!accessFn('VIEW_REPLACEMENT_OPTIONS')) {
				statuses = pull(statuses, 'View options');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Pending for replacement',  
		csrlStatus: 'Pending for replacement',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel', 'Replacement allocated', 'Replacement unavailable');
			}
			if (!accessFn('ALLOCATE_DEVICE')) {
				statuses = pull(statuses, 'Replacement allocated');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Pending for replacement',  
		csrlStatus: 'Failed to create Exchange order. Please retry',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel', 'Replacement allocated', 'Replacement unavailable');
			}
			if (!accessFn('ALLOCATE_DEVICE')) {
				statuses = pull(statuses, 'Replacement allocated');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Defective awaited',  
		csrlStatus: 'Defective awaited',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Defective received","Security deposit charged","Service completed");
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed &&  !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses,'Service completed');
				}
				
			}else{
				statuses = pull(statuses,'Security deposit charged');
			}

			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses,'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses,'Security deposit released');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Defective awaited',  
		csrlStatus: 'Failed to create Exchange order. Please retry',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Defective received","Security deposit charged","Service completed");
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed &&  !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses,'Service completed');
				}
				
			}else{
				statuses = pull(statuses,'Security deposit charged');
			}

			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses,'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses,'Security deposit released');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Defective awaited',  
		csrlStatus: 'Card-hold charge failed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Defective received","Security deposit charged","Service completed");
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed &&  !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses,'Service completed');
				}
				
			}else{
				statuses = pull(statuses,'Security deposit charged');
			}

			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses,'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses,'Security deposit released');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Defective awaited',  
		csrlStatus: 'Security deposit released',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Defective received","Security deposit charged","Service completed");
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed &&  !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses,'Service completed');
				}
				
			}else{
				statuses = pull(statuses,'Security deposit charged');
			}

			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses,'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses,'Security deposit released');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Defective awaited',  
		csrlStatus: 'Security deposit charging failed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Defective received","Security deposit charged","Service completed");
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed &&  !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses,'Service completed');
				}
				
			}else{
				statuses = pull(statuses,'Security deposit charged');
			}

			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses,'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses,'Security deposit released');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Defective awaited',  
		csrlStatus: 'Payment disputed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Defective received","Security deposit charged","Service completed");
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed &&  !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses,'Service completed');
				}
				
			}else{
				statuses = pull(statuses,'Security deposit charged');
			}

			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses,'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses,'Security deposit released');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Defective awaited',  
		csrlStatus: 'Payment Authorization refund successful',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Defective received","Security deposit charged","Service completed");
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed &&  !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses,'Service completed');
				}
			}else{
				statuses = pull(statuses,'Security deposit charged');
			}
			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses,'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses,'Security deposit released');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Defective received',  
		csrlStatus: 'Defective received',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Device inspected","Security deposit charged","Security deposit released","Service completed",'Service cancel');
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed  && !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses, 'Service completed');
				}
				if(showRequestLogs.isQcCompleted){
					statuses = pull(statuses, 'Device inspected');
					if(isB2BRequest){
						statuses = pullAll(statuses,'Security deposit charged','Security deposit released');
					}
				}else{
					statuses = pull(statuses, "Security deposit charged","Security deposit released");
				}
			}else{
				statuses = pull(statuses, 'Device inspected',"Security deposit released",'Security deposit charged');
			}
			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit released');
			}
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			
			return statuses;
		}
    },
	{
        csrStatus: 'Defective received',  
		csrlStatus: 'Security deposit charging failed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Device inspected","Security deposit charged","Security deposit released","Service completed",'Service cancel');
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed && !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses, 'Service completed');
				}
				if(showRequestLogs.isQcCompleted){
					statuses = pull(statuses, 'Device inspected');
					if(isB2BRequest){
						statuses = pull(statuses,'Security deposit charged','Security deposit released');
					}
				}else{
					statuses = pull(statuses, "Security deposit charged","Security deposit released");
				}
			}else{
				statuses = pull(statuses, 'Device inspected',"Security deposit released",'Security deposit charged');
			}
			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit released');
			}
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			return statuses;
		}
    },
	{
        csrStatus: 'Defective received',  
		csrlStatus: 'Security deposit releasing failed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Device inspected","Security deposit charged","Security deposit released","Service completed",'Service cancel');
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed && !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses, 'Service completed');
				}
				if(showRequestLogs.isQcCompleted){
					statuses = pull(statuses, 'Device inspected');
					if(isB2BRequest){
						statuses = pull(statuses,'Security deposit charged','Security deposit released');
					}
				}else{
					statuses = pull(statuses, "Security deposit charged","Security deposit released");
				}
			}else{
				statuses = pull(statuses, 'Device inspected',"Security deposit released",'Security deposit charged');
			}
			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit released');
			}
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			return statuses;
		}
    },
	{
        csrStatus: 'Defective received',  
		csrlStatus: 'Refund initiated',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Device inspected","Security deposit charged","Security deposit released","Service completed",'Service cancel');
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed && !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses, 'Service completed');
				}
				if(showRequestLogs.isQcCompleted){
					statuses = pull(statuses, 'Device inspected');
					if(isB2BRequest){
						statuses = pull(statuses,'Security deposit charged','Security deposit released');
					}
				}else{
					statuses = pull(statuses, "Security deposit charged","Security deposit released");
				}
			}else{
				statuses = pull(statuses, 'Device inspected',"Security deposit released",'Security deposit charged');
			}
			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit released');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Defective received',  
		csrlStatus: 'Refund failed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Device inspected","Security deposit charged","Security deposit released","Service completed",'Service cancel');
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed && !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses, 'Service completed');
				}
				if(showRequestLogs.isQcCompleted){
					statuses = pull(statuses, 'Device inspected');
					if(isB2BRequest){
						statuses = pull(statuses,'Security deposit charged','Security deposit released');
					}
				}else{
					statuses = pull(statuses, "Security deposit charged","Security deposit released");
				}
			}else{
				statuses = pull(statuses, 'Device inspected',"Security deposit released",'Security deposit charged');
			}
			if(!accessFn('CHARGE_SECURITY_DEPOSIT')|| consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT')|| consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit released');
			}
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			return statuses;
		}
    },
	{
        csrStatus: 'Defective received',  
		csrlStatus: 'Failed to create Exchange order. Please retry',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Device inspected","Security deposit charged","Security deposit released","Service completed",'Service cancel');
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed  && !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses, 'Service completed');
				}
				if(showRequestLogs.isQcCompleted){
					statuses = pull(statuses, 'Device inspected');
					if(isB2BRequest){
						statuses = pull(statuses,'Security deposit charged','Security deposit released');
					}
				}else{
					statuses = pull(statuses, "Security deposit charged","Security deposit released");
				}
			}else{
				statuses = pull(statuses, 'Device inspected',"Security deposit released",'Security deposit charged');
			}
			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit released');
			}
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			
			return statuses;
		}
    },
	{
        csrStatus: 'Defective received',  
		csrlStatus: 'Payment Refund processed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Device inspected","Security deposit charged","Security deposit released","Service completed",'Service cancel');
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed  && !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses, 'Service completed');
				}
				if(showRequestLogs.isQcCompleted){
					statuses = pull(statuses, 'Device inspected');
					if(isB2BRequest){
						statuses = pull(statuses,'Security deposit charged','Security deposit released');
					}
				}else{
					statuses = pull(statuses, "Security deposit charged","Security deposit released");
				}
			}else{
				statuses = pull(statuses, 'Device inspected',"Security deposit released",'Security deposit charged');
			}
			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit released');
			}
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			
			return statuses;
		}
    },
	{
        csrStatus: 'Defective received',  
		csrlStatus: 'Refund processed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Device inspected","Security deposit charged","Security deposit released","Service completed",'Service cancel');
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed  && !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses, 'Service completed');
				}
				if(showRequestLogs.isQcCompleted){
					statuses = pull(statuses, 'Device inspected');
					if(isB2BRequest){
						statuses = pullAll(statuses,'Security deposit charged','Security deposit released');
					}
				}else{
					statuses = pull(statuses, "Security deposit charged","Security deposit released");
				}
			}else{
				statuses = pull(statuses, 'Device inspected',"Security deposit released",'Security deposit charged');
			}
			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit released');
			}
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			
			return statuses;
		}
    },
	{
        csrStatus: 'Defective received',  
		csrlStatus: 'Payment Refund processed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Device inspected","Security deposit charged","Security deposit released","Service completed",'Service cancel');
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed  && !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses, 'Service completed');
				}
				if(showRequestLogs.isQcCompleted){
					statuses = pull(statuses, 'Device inspected');
					if(isB2BRequest){
						statuses = pullAll(statuses,'Security deposit charged','Security deposit released');
					}
				}else{
					statuses = pull(statuses, "Security deposit charged","Security deposit released");
				}
			}else{
				statuses = pull(statuses, 'Device inspected',"Security deposit released",'Security deposit charged');
			}
			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit released');
			}
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			
			return statuses;
		}
    },
	{
        csrStatus: 'Defective received',  
		csrlStatus: 'Refund in-progress',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Device inspected","Security deposit charged","Security deposit released","Service completed",'Service cancel');
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed  && !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses, 'Service completed');
				}
				if(showRequestLogs.isQcCompleted){
					statuses = pull(statuses, 'Device inspected');
					if(isB2BRequest){
						statuses = pullAll(statuses,'Security deposit charged','Security deposit released');
					}
				}else{
					statuses = pull(statuses, "Security deposit charged","Security deposit released");
				}
			}else{
				statuses = pull(statuses, 'Device inspected',"Security deposit released",'Security deposit charged');
			}
			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit released');
			}
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			
			return statuses;
		}
    },
	{
        csrStatus: 'Defective received',  
		csrlStatus: 'Card-hold charge failed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Device inspected","Security deposit charged","Security deposit released","Service completed",'Service cancel');
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed  && !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses, 'Service completed');
				}
				if(showRequestLogs.isQcCompleted){
					statuses = pull(statuses, 'Device inspected');
					if(isB2BRequest){
						statuses = pullAll(statuses,'Security deposit charged','Security deposit released');
					}
				}else{
					statuses = pull(statuses, "Security deposit charged","Security deposit released");
				}
			}else{
				statuses = pull(statuses, 'Device inspected',"Security deposit released",'Security deposit charged');
			}
			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit released');
			}
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			
			return statuses;
		}
    },
	{
		csrStatus: 'Non hardware closure details updated',  
		csrlStatus: 'Non hardware closure details updated',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Device inspected','Repair cancel');
						}
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
		
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics');
							}
						}
						if(isAdvancePaidCheck()){
							statuses = concat(statuses,'Repair completed');
						}
						
					}
					else {
						statuses =concat(statuses, 'Device inspected', 'Service cancel');
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
						if(isAdvancePaidCheck()){
							statuses = concat(statuses,'Repair completed');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Non hardware closure details updated',  
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Device inspected','Repair cancel');
						}
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses.push('Advance collected');
						}
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics');
							}
						}
						if(isAdvancePaidCheck()){
							statuses = concat(statuses,'Repair completed');
						}
						
					}
					else {
						statuses =concat(statuses, 'Device inspected', 'Service cancel');
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
						if(isAdvancePaidCheck()){
							statuses = concat(statuses,'Repair completed');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Provisional estimate updated',  
		csrlStatus: 'Provisional estimate updated',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Device inspected','Repair cancel');
						}
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses.push('Advance collected');
						}
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics');
							}
						}
						if(showRequestLogs.isHardwareClosure && isAdvancePaidCheck()){
							statuses = concat(statuses,'Repair completed');
						}
					}
					else {
						statuses =concat(statuses, 'Device inspected', 'Service cancel');
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Provisional estimate updated',  
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Device inspected','Repair cancel');
						}
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses.push('Advance collected');
						}
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics');
							}
						}
						if(showRequestLogs.isHardwareClosure && isAdvancePaidCheck()){
							statuses = concat(statuses,'Repair completed');
						}
						
					}
					else {
						statuses =concat(statuses, 'Device inspected', 'Service cancel');
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Provisional estimate updated',  
		csrlStatus: 'Diagnosis initiated',
		computeNextStatus: function() {
			let statuses = [];
			let suitesStatuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if(consumerServiceRequest.AllowCCDiagnosis) {
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses,'Cannot be diagnosed', 'Diagnosis completed');
								}else{
									statuses = concat(statuses, 'Initiate AST 2 Diagnostics','Cannot be diagnosed', 'Diagnosis completed');
								}
								if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
									suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
									statuses = concat(statuses, suitesStatuses)
								}else if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey && (!arrDiagnosisSuites || (arrDiagnosisSuites && !arrDiagnosisSuites.length)) && !diagnosticsSuitesCalled){
									if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
										suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
										statuses = concat(statuses, suitesStatuses)
									}
								}
								statuses = concat(statuses, 'Repair cancel');
							}
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Provisional estimate updated',  
		csrlStatus: 'Cannot be diagnosed',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Device inspected','Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
						}
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses.push('Advance collected');
						}
						if(showRequestLogs.isHardwareClosure && isAdvancePaidCheck()){
							statuses = concat(statuses,'Repair completed');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Provisional estimate updated',  
		csrlStatus: 'Diagnosis completed',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Device inspected','Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
						}
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses.push('Advance collected');
						}
						if(showRequestLogs.isHardwareClosure && isAdvancePaidCheck()){
							statuses = concat(statuses,'Repair completed');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Provisional estimate updated',  
		csrlStatus: 'Advance collected',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Device inspected','Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
						}
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses.push('Advance collected');
						}
						if(showRequestLogs.isHardwareClosure && isAdvancePaidCheck()){
							statuses = concat(statuses,'Repair completed');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'DOA certificate generated',  
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			if (accessFn('ACCEPT_RETURN_DEVICE') && isHandedToCC()) {
				statuses.push('DOA completed');
			}
			return statuses;
		}
	},
	{
		csrStatus: 'DOA certificate generated',  
		csrlStatus: 'DOA certificate generated',
		computeNextStatus: function() {
			let statuses = [];
			if (accessFn('ACCEPT_RETURN_DEVICE') && isHandedToCC()) {
				statuses.push('DOA completed');
			}
			return statuses;
		}
	},
	{
		csrStatus: 'Advance collected',  
		csrlStatus: 'Engineer reassigned',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						let partsReceived = partsRequested.length == 0 ? false : true;
						for (let iCount = 0;iCount<partsRequested.length;iCount++) {
							if (partsRequested[iCount].statusDetails.Status == 'pending' || partsRequested[iCount].statusDetails.Status == 'issued' || partsRequested[iCount].statusDetails.Status == 'ordered') {
								partsReceived = false;
								break
							}
						}
						if (isSupOrEngg()) {
							if(showRequestLogs.isAllPartReceived) {
								if (consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									if(isHandedToEng() && consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
										statuses = concat(statuses, 'Update KGB');
									}else{
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
						}
						if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
						if (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS')) {
							if (isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
								if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
									if(accessFn('REASSIGN_JOBS')){
										statuses = concat(statuses, 'Map G-Number');
									}
									if(accessFn('REPAIR_DEVICE')){
										if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !consumerServiceRequest.ProvisionalGSXNumber){
											if(!consumerServiceRequest.GSX_REPAIR_SERVICE_APPROACH){
												statuses = concat(statuses, 'Select Service Approach');
											}else{
												statuses = concat(statuses, 'Request raised'); 
											}
										}else{
											statuses = concat(statuses, 'Request raised'); 
										}
									}
								}
							}
						}
					}
					else {
						if(isSupOrEngg()){
							if(showRequestLogs.isAllPartReceived) {
								if(isRequestSFANeeded){
									if((requestSFA && requestSFA.length > 0) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
										statuses = concat(statuses, 'Repair completed');
									}
								}else{
									if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)) {
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
						}
						  if (accessFn('ACCEPT_RETURN_DEVICE')) {
							statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						  }
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Advance collected',  
		csrlStatus: 'Advance collected',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				// if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						let partsReceived = partsRequested.length == 0 ? false : true;
						for (let iCount = 0;iCount<partsRequested.length;iCount++) {
							if (['pending','issued','ordered'].indexOf(partsRequested[iCount].statusDetails.Status) > -1) {
								partsReceived = false;
								break
							}
						}
						if (isSupOrEngg()) {
							if(partsReceived) {
								if (consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									if(isHandedToEng() && consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
										statuses = concat(statuses, 'Update KGB');
									}else{
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
							// else{
							// 	statuses = concat(statuses, 'Repair completed');
							// }
						}
						if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
						// let isIssuesValidated = true;
						// for (let iCount = 0; iCount<consumerServiceRequest.issues.length; iCount++) {
						// 	if (consumerServiceRequest.issues[iCount] && consumerServiceRequest.issues[iCount].validityStatus == 0 || !self.consumerServiceRequest.issues[iCount].IsValidated) {
						// 		isIssuesValidated = false;
						// 	}
						// }
						if (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS')) {
							if (isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
								if (consumerServiceRequest.gsxRepairRequest && !consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
									if(accessFn('REASSIGN_JOBS')){
										statuses = concat(statuses, 'Map G-Number');
									}
									if(accessFn('REPAIR_DEVICE')){
										if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !consumerServiceRequest.ProvisionalGSXNumber){
											if(!consumerServiceRequest.GSX_REPAIR_SERVICE_APPROACH){
												statuses = concat(statuses, 'Select Service Approach');
											}else{
												statuses = concat(statuses, 'Request raised'); 
											}
										}else{
											statuses = concat(statuses, 'Request raised'); 
										}
									}
								}
							}
						}
						
					}
					else {
						if(isSupOrEngg()){
							if(showRequestLogs.isAllPartReceived) {
								if(isRequestSFANeeded){
									if((requestSFA && requestSFA.length > 0) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
										statuses = concat(statuses, 'Repair completed');
									}
								}else{
									if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)) {
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
						}
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						}
					}
				// }
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Advance collected',  
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						let partsReceived = partsRequested.length == 0 ? false : true;
						for (let iCount = 0;iCount<partsRequested.length;iCount++) {
							if (partsRequested[iCount].statusDetails.Status == 'pending' || partsRequested[iCount].statusDetails.Status == 'issued' || partsRequested[iCount].statusDetails.Status == 'ordered') {
								partsReceived = false;
								break
							}
						}
						if (isSupOrEngg()) {
							if(partsReceived) {
								if (consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									if(isHandedToEng() && consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
										statuses = concat(statuses, 'Update KGB');
									}else{
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
						}
						if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
						if (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS')) {
							if (isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
								if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
									if(accessFn('REASSIGN_JOBS')){
										statuses = concat(statuses, 'Map G-Number');
									}
									if(accessFn('REPAIR_DEVICE')){
										if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !consumerServiceRequest.ProvisionalGSXNumber){
											if(!consumerServiceRequest.GSX_REPAIR_SERVICE_APPROACH){
												statuses = concat(statuses, 'Select Service Approach');
											}else{
												statuses = concat(statuses, 'Request raised'); 
											}
										}else{
											statuses = concat(statuses, 'Request raised'); 
										}
									}
								}
							}
						}
					}
					else {
						if(isSupOrEngg()){
							if(showRequestLogs.isAllPartReceived) {
								if(isRequestSFANeeded){
									if((requestSFA && requestSFA.length > 0) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
										statuses = concat(statuses, 'Repair completed');
									}
								}else{
									if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)) {
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
						}
						  if (accessFn('ACCEPT_RETURN_DEVICE')) {
							statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						  }
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Advance collected',  
		csrlStatus: 'Device accepted',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						let partsReceived = partsRequested.length == 0? false : true;
						for (let iCount = 0;iCount<partsRequested.length;iCount++) {
							if (partsRequested[iCount].statusDetails.Status == 'pending' || partsRequested[iCount].statusDetails.Status == 'issued' || partsRequested[iCount].statusDetails.Status == 'ordered') {
								partsReceived = false;
								break
							}
						}
						if (isSupOrEngg()) {
							if(partsReceived) {
								if (consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									if(isHandedToEng() && consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
										statuses = concat(statuses, 'Update KGB');
									}else{
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
						}
						if (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Repair cancel');
							}
						}
						if (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS')) {
							if (isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
								if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
									if(accessFn('REASSIGN_JOBS')){
										statuses = concat(statuses, 'Map G-Number');
									}
									if(accessFn('REPAIR_DEVICE')){
										if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !consumerServiceRequest.ProvisionalGSXNumber){
											if(!consumerServiceRequest.GSX_REPAIR_SERVICE_APPROACH){
												statuses = concat(statuses, 'Select Service Approach');
											}else{
												statuses = concat(statuses, 'Request raised'); 
											}
										}else{
											statuses = concat(statuses, 'Request raised'); 
										}
									}
								}
							}
						}
					}
					else {
						if(isSupOrEngg()){
							if(showRequestLogs.isAllPartReceived) {
								if(isRequestSFANeeded){
									if((requestSFA && requestSFA.length > 0) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
										statuses = concat(statuses, 'Repair completed');
									}
								}else{
									if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)) {
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
						}
						  if (accessFn('ACCEPT_RETURN_DEVICE')) {
							statuses = concat(statuses, 'Repair cancel', 'Cancel Service Without Dispatch');
						  }
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
	},
	{
		csrStatus: 'Advance collected',  
		csrlStatus: 'Replacement request raised',
		computeNextStatus: function() {
			let statuses = [];
			
			return statuses;
		}
	},
	{
		csrStatus: 'Pending for DOA approval',
		csrlStatus: 'Pending for DOA approval',
		computeNextStatus: function(){
			let statuses = [];
			if(accessFn('APPROVE_DOA') && consumerServiceRequest.DOAStatus == 'replacement'){
				statuses = concat(statuses, 'DOA approved');
  		  	}
			return statuses;
		}
	},
	{
		csrStatus: 'DOA rejected',
		csrlStatus: 'DOA rejected',
		computeNextStatus: function(){
			let statuses = [];
			if(accessFn('ACCEPT_RETURN_DEVICE')) {
				statuses = concat(statuses, 'Service cancel', 'Repair initiated');
			}
			return statuses;
		}
	},
	{
		csrStatus: 'DOA completed',
		csrlStatus: 'DOA completed',
		computeNextStatus: function () {
			var statuses = [];
			if (accessFn('ACCEPT_RETURN_DEVICE')) {
				if ([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
					statuses = concat(statuses, 'Intimate customer');
				}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
					if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
						statuses = concat(statuses, 'Device dispatched');
					} else {
						statuses = concat(statuses, 'Initiate logistics request');
					}
        		}
			}
			return statuses;
		}
	},
	{
		csrStatus: 'DOA completed',
		csrlStatus: 'Device accepted',
		computeNextStatus: function () {
			var statuses = [];
			if (accessFn('ACCEPT_RETURN_DEVICE')) {
				if ([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
					statuses = concat(statuses, 'Intimate customer');
				}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
					if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
						statuses = concat(statuses, 'Device dispatched');
					} else {
						statuses = concat(statuses, 'Initiate logistics request');
					}
        		}
			}
			return statuses;
		}
	},
	{
		csrStatus: 'Repair cancel',
		csrlStatus: 'Waiting for Assignment',
		computeNextStatus:function(){
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (showRequestValid.allowCCToProceedWithService) { //transfer request
							statuses = concat(statuses, 'Transfer request');
							if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
								if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
									statuses = concat(statuses, 'Device dispatched');
								}else {
									statuses = concat(statuses, 'Initiate logistics request');
								}
							}else{
								if(consumerServiceRequest.originCenter){
									statuses = concat(statuses, 'Service cancel');
								}
							}
						}
					}
				}else{
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							}else{
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else{
						  statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			// Pickup carry in end	
			return statuses;
		}
	},
	{
		csrStatus: 'Device inspected',
		csrlStatus: 'Device details validated',
		computeNextStatus:function(){
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics','Skip diagnosis');
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable  && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}
			}
			// Pickup carry in end	
			return statuses;
		}
	},
	{
		csrStatus: 'Device inspected',
		csrlStatus: 'Device inspected',
		computeNextStatus:function(){
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics','Skip diagnosis');
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable  && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}
			}
			// Pickup carry in end	
			return statuses;
		}
	},
	{
		csrStatus: 'Device inspected',
		csrlStatus: 'Diagnosis completed',
		computeNextStatus:function(){
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
						if(showRequestValid.allowCCToProceedWithService){
							statuses = concat(statuses, 'Transfer request');
						}		
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable  && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}
			}
			// Pickup carry in end	
			return statuses;
		}
	},
	{
		csrStatus: 'Device inspected',
		csrlStatus: 'Advance collected',
		computeNextStatus:function(){
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics','Skip diagnosis');
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable  && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}
			}
			// Pickup carry in end	
			return statuses;
		}
	},
	{
		csrStatus: 'Device inspected',
		csrlStatus: 'Token released',
		computeNextStatus:function(){
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics','Skip diagnosis');
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable  && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}
			}
			// Pickup carry in end	
			return statuses;
		}
	},
	{
		csrStatus: 'Device inspected',
		csrlStatus: 'Token generated',
		computeNextStatus:function(){
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis){
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Initiate Diagnostics','Skip diagnosis');
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable  && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
					}
				}
			}
			// Pickup carry in end	
			return statuses;
		}
	},
	{
		csrStatus: 'Device inspected',
		csrlStatus: 'Diagnosis initiated',
		computeNextStatus:function(){
			let statuses = [];
			let suitesStatuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE') && consumerServiceRequest.AllowCCDiagnosis) {
						if(deviceAtLoggedInLoc){
							if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
								statuses = concat(statuses,'Cannot be diagnosed', 'Diagnosis completed');
							}else{
								statuses = concat(statuses, 'Initiate AST 2 Diagnostics','Cannot be diagnosed', 'Diagnosis completed');
							}
							if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
								suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
								statuses = concat(statuses, suitesStatuses)
							}else if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.gsxInfo && consumerServiceRequest.consumerProduct.gsxInfo.serialNumber && (!arrDiagnosisSuites || (arrDiagnosisSuites && !arrDiagnosisSuites.length)) && !diagnosticsSuitesCalled) {
								if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
									suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
									statuses = concat(statuses, suitesStatuses)
								}
							}
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}
			}
			// Pickup carry in end	
			return statuses;
		}
	},
	{
		csrStatus: 'Provisional estimate generated',  
		csrlStatus: 'Provisional estimate generated',
		computeNextStatus: function() {
			let statuses = [];
		}
	},
	{
		csrStatus: 'Non hardware closure details updated',
		csrlStatus: 'Diagnosis initiated',
		computeNextStatus:function(){
			let statuses = [];
			let suitesStatuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(deviceAtLoggedInLoc){
							if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
								statuses = concat(statuses,'Cannot be diagnosed', 'Diagnosis completed');
							}else{
								statuses = concat(statuses, 'Initiate AST 2 Diagnostics','Cannot be diagnosed', 'Diagnosis completed');
							}
							if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
								suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
								statuses = concat(statuses, suitesStatuses)
							}else if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey  && (!arrDiagnosisSuites || (arrDiagnosisSuites && !arrDiagnosisSuites.length)) && !diagnosticsSuitesCalled) {
								if (arrDiagnosisSuites && arrDiagnosisSuites.length) {
									suitesStatuses = concat(suitesStatuses, arrDiagnosisSuites)
									statuses = concat(statuses, suitesStatuses)
								}
							}
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}
			}
			// Pickup carry in end	
			return statuses;
		}
	},
	{
		csrStatus: 'Non hardware closure details updated',
		csrlStatus: 'Diagnosis completed',
		computeNextStatus:function(){
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Device inspected', 'Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable  && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
						if(isAdvancePaidCheck()){
							statuses = concat(statuses,'Repair completed');
						}
					}
				}
			}
			// Pickup carry in end	
			return statuses;
		}
	},
	{
        csrStatus: 'Replacement allocated',  
		csrlStatus: 'Replacement allocated',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Replacement shipment created');
				}else{
					statuses = concat(statuses,'Replacement shipment created','Add Shipment Details','Service completed');
				}
			}
			return statuses;
		}
	},
	{
        csrStatus: 'Replacement allocated',  
		csrlStatus: 'Failed to create Exchange order. Please retry',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE){
					statuses = concat(statuses,'Replacement shipment created');
				}else{
					statuses = concat(statuses,'Replacement shipment created','Add Shipment Details','Service completed');
				}
			}
			return statuses;
		}
	},
    {   csrStatus: 'Provisional estimate generated',  
		csrlStatus: 'Device details validated',
		computeNextStatus: function() {
			let statuses = [];
			return statuses;
		}
	},
	{	
		csrStatus: 'Non hardware closure details updated',
		csrlStatus: 'Cannot be diagnosed',
		computeNextStatus:function(){
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Device inspected', 'Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable  && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
						if(isAdvancePaidCheck()){
							statuses = concat(statuses,'Repair completed');
						}
					}
				}
			}
			// Pickup carry in end	
			return statuses;
		}
	},
	{
		csrStatus: 'Non hardware closure details updated',
		csrlStatus: 'Advance collected',
		computeNextStatus:function(){
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Device inspected', 'Repair cancel');
						}
						if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct){
							if(deviceAtLoggedInLoc){
								if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}
						}
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable  && featureConfig && featureConfig.EnableCCEAdavnce){
							statuses = concat(statuses, 'Advance collected');
						}
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
						if(isAdvancePaidCheck()){
							statuses = concat(statuses,'Repair completed');
						}
					}
				}
			}
			// Pickup carry in end	
			return statuses;
		}
	},
	{
		csrStatus: 'Repair completed',
		csrlStatus: 'Sent to collection point',
		computeNextStatus:function(){
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) > -1) {
							statuses = concat(statuses, 'Receive device from Repair Center');
						}
					}
				}
			}
			// Pickup carry in end	
			return statuses;
		}
    },
	{
        csrStatus: 'Ready for collection',  
		csrlStatus: 'Ready for collection',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				statuses = concat(statuses,'Replacement device collected','Service cancel');
			}
			
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement collection request raised',  
		csrlStatus: 'Replacement collection request raised',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				statuses = concat(statuses, 'Replacement allocated', 'Collection order created', 'Service cancel');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Collection order created',  
		csrlStatus: 'Collection order created',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				statuses = concat(statuses,'Ready For Collection','Service cancel');
			}
			
			return statuses;
		}
    },
	
	{
        csrStatus: 'Defective received',  
		csrlStatus: 'QC completed',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,"Device inspected","Security deposit charged","Security deposit released","Service completed",'Service cancel');
			}
			if(consumerServiceRequest.DEVICE_INSPECTION_REQUIRED ){
				if(!showRequestLogs.securityDepositChargeFailed  && !isB2BRequest && !consumerServiceRequest.hideSubscriptionRelease){
					statuses = pull(statuses, 'Service completed');
				}
				if(showRequestLogs.isQcCompleted){
					statuses = pull(statuses, 'Device inspected');
					if(isB2BRequest){
						statuses = pull(statuses,'Security deposit charged','Security deposit released');
					}
				}else{
					statuses = pull(statuses, "Security deposit charged","Security deposit released");
				}
			}else{
				statuses = pull(statuses, 'Device inspected',"Security deposit released",'Security deposit charged');
			}
			if(!accessFn('CHARGE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit charged');
			}
			if(!accessFn('RELEASE_SECURITY_DEPOSIT') || consumerServiceRequest.hideSubscriptionRelease) {
				statuses = pull(statuses, 'Security deposit released');
			}
			// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){
			// 	if(accessFn('ALLOW_REFUND')){
			// 		statuses = concat(statuses, 'Initiate Refund');
			// 	}
			// }
			return statuses;
		},
	},
	{
		csrStatus: 'Repair completed',
		csrlStatus: 'Received at collection point',
		computeNextStatus:function(){
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if(isSupOrEngg()){
						if (showRequestLogs.isRepairCompleted && consumerServiceRequest.IsQcPresent){
							statuses = concat(statuses, 'QC pending');
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(showRequestValid.allowCCToProceedWithService){
							if(showRequestLogs.isRepairCompleted && !consumerServiceRequest.IsQcPresent) {
								if(!consumerServiceRequest.IsWarrantyApplicable){
									if(consumerServiceRequest.showGenerateInvoiceButton){
										statuses = concat(statuses, 'Service invoice generated');
									}else if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
										statuses = concat(statuses, 'Payment received');
									}
								}else{
									if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
										statuses = concat(statuses, 'Intimate customer');
									} else if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
										if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
											statuses = concat(statuses, 'Device dispatched');
										}else{
											statuses = concat(statuses, 'Initiate logistics request','Add Shipment Details');
										}
									}
								}
							}
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
		csrStatus: 'Repair completed',
		csrlStatus: 'Repair completed and checklist updated',
		computeNextStatus:function(){
			var statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if(isSupOrEngg()){
						if (showRequestLogs.isRepairCompleted && consumerServiceRequest.IsQcPresent){
							statuses = concat(statuses, 'QC pending');
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')){
						if(showRequestValid.allowCCToProceedWithService){
							if(showRequestLogs.isRepairCompleted && !consumerServiceRequest.IsQcPresent) {
								let isDeviceReceivedAtDropOffLoc = find(consumerServiceRequest.logs, {Status: 'Device received at drop-off location'});
								if(!appleDropOffFlow || (accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE') && (isDeviceReceivedAtDropOffLoc || (consumerServiceRequest?.gsxRepairFlags?.repairTypeId && (consumerServiceRequest.gsxRepairFlags.repairTypeId == 3)) || showRequestLogs.isHardwareClosure))) {
								// if(!appleDropOffFlow || (accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE') && isDeviceReceivedAtDropOffLoc)) {
									if(!consumerServiceRequest.IsWarrantyApplicable){
										if(consumerServiceRequest.showGenerateInvoiceButton){
											statuses = concat(statuses, 'Service invoice generated');
										}else if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
											statuses = concat(statuses, 'Payment received');
										}
									}else{
										if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
											statuses = concat(statuses, 'Intimate customer');
										} else if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
											if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
												statuses = concat(statuses, 'Device dispatched');
											}else{
												statuses = concat(statuses, 'Initiate logistics request','Add Shipment Details');
											}
										}
									}
								}
							}
						}
						if((consumerServiceRequest.CREATED_AT_DROPOFF_PSL === 'true') && !accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')) {
							statuses = concat(statuses, 'Ship device to drop-off location');
						}
					}
				}
			}
			// Pickup carry in end
			return statuses;
		}
    },
	{
        csrStatus: 'Repair completed',
		csrlStatus: 'Part added',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW')) && showUpdateLogisticsRequestButton()){
				statuses = concat(statuses,'Update Logistics Request')
			}
			// if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
            //     if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
            //         statuses = concat(statuses,'Dispatch Device')
            //     }
            // }
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck() &&  !accessFn('HIDE_SERVICE_COMPLETE')){
						statuses = concat(statuses,'Complete Service');
					}
				}
				if(([SERVICETYPE.PICK_UP , SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
						if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
							statuses = concat(statuses,'Dispatch Device')
						}
					
					if(!(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled)){
						if(accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') ){
							statuses = concat(statuses,'Initiate logistics request','Add Shipment Details');
							// if(warrantyAppicableNonClaimCheck()){
							// 	statuses = concat(statuses,'Complete Service');
							// }
						}
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start
			if(onsiteChecks){
				if(showButton){
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Service completed');
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && consumerServiceRequest.IsQcPresent) {
						statuses.push('QC pending');
					}
				}

				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (find(consumerServiceRequest.logs, {Status: 'Repair completed'}) && !consumerServiceRequest.IsQcPresent) {
						if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) {
							statuses.push('Intimate customer');
						} else if (logisitcsServiceTypes.indexOf(consumerServiceRequest.ServiceTypeID) > -1 || logisitcsServiceTypes.indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1) {
							if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
								statuses = concat(statuses, 'Device dispatched');
							} else {
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}
					}
				}
			}
			//---end claim new

			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID)) > -1 && claimMergeStatus.isPickUp){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = concat(claimStatuses, 'Device dispatched');
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = concat(claimStatuses, 'Device dispatched');
						// }
					}
				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if(hideIssueLoanerButton){
						claimStatuses = [];
					}
					if(!hideIssueLoanerButton){
						claimStatuses = ['Service completed'];
					}
				}
				if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Device dispatched'];
					}
				}
				else {
					if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				if(claimobj  && claimobj.consumerServiceRequest && claimobj.consumerServiceRequest.autoClaimFulfillmentFlow) {
					claimStatuses =[];
				}

				if ([SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
						claimStatuses = ['Device dispatched'];
					}else{
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1 && [SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
					if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
						claimStatuses = ['Device dispatched'];
					}
					else {
						claimStatuses = ['Initiate logistics request', 'Add Shipment Details','Device dispatched'];
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end 
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
					if(isSupOrEngg()){
						if (showRequestLogs.isRepairCompleted && consumerServiceRequest.IsQcPresent) {
							statuses = concat(statuses, 'QC pending');
						}
					}
					if (accessFn('STORE_MANAGEMENT') || accessFn('ACCEPT_RETURN_DEVICE')){
						if(accessFn('TRANSFER_REQUEST')){
							if(qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) > -1 && !consumerServiceRequest.originCenter){
								statuses = concat(statuses, 'Transfer request');
							}
						}
						// if(!showRequestValid.allowCCToProceedWithService){
						// 	statuses = concat(statuses, 'Transfer request');
						// }
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (showRequestValid.allowCCToProceedWithService) {
							if(showRequestLogs.isRepairCompleted && !consumerServiceRequest.IsQcPresent){
								let isDeviceReceivedAtDropOffLoc = find(consumerServiceRequest.logs, {Status: 'Device received at drop-off location'});
								if(!appleDropOffFlow || (accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE') && (isDeviceReceivedAtDropOffLoc || (consumerServiceRequest?.gsxRepairFlags?.repairTypeId && (consumerServiceRequest.gsxRepairFlags.repairTypeId == 3)) || showRequestLogs.isHardwareClosure))) {
									if(!consumerServiceRequest.IsWarrantyApplicable){
										if(consumerServiceRequest.showGenerateInvoiceButton) {
											statuses = concat(statuses, 'Service invoice generated');
										}else if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment){
											statuses = concat(statuses, 'Payment received');
										}
									} else {
										if((consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN)){
											// statuses = concat(statuses, 'Intimate customer');
											if(!appleDropOffFlow) {
												statuses = concat(statuses, 'Intimate customer');
											}else {
												statuses = concat(statuses, 'Service completed');
											}
										}else if(consumerServiceRequest.ServiceTypeID == 1){
											if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
												statuses = concat(statuses, 'Device dispatched');
											} else {
												statuses = concat(statuses, 'Initiate logistics request');
											}
										}
									}
								}
							}
						}
						if(appleDropOffFlow && !accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE') && consumerServiceRequest.ProvisionalGSXNumber) {
							statuses = concat(statuses, 'Initiate shipment to drop-off location');
						}
					}
				}else{
					if(isSupOrEngg()){
						if (showRequestLogs.isRepairCompleted && consumerServiceRequest.IsQcPresent && !exception) {
							statuses = concat(statuses, 'QC pending');
						}
					}
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if(showRequestLogs.isRepairCompleted && !consumerServiceRequest.IsQcPresent) {
							if(!consumerServiceRequest.IsWarrantyApplicable){
								if(consumerServiceRequest.showGenerateInvoiceButton){
									statuses = concat(statuses, 'Service invoice generated');
								}else if(consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
									statuses = concat(statuses, 'Payment received');
									if(accessFn('SEND_PAYMENT_LINK')){
										statuses = concat(statuses, 'Send payment link');
									}
								}
							}else{
								if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
									statuses = concat(statuses, 'Intimate customer');
								}else if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
									if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
										statuses = concat(statuses, 'Device dispatched');
									} else {
										statuses = concat(statuses, 'Initiate logistics request');
									}
								}
							}
						}
						if(exception) {
							statuses = concat(statuses, 'Intimate customer');
						}
					}
					if(accessFn('CANCEL_REVERT_INVENTORY') && !showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end

			//refurbishment flow 
			if(refurbishmentFlowCheck){
				statuses = concat(statuses,'Service completed','Add Shipment Details');//'refurbishment shipment created','Device delivered'
			}
			//refurbishment flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Replacement device collected',  
		csrlStatus: 'Replacement device collected',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				statuses = concat(statuses,'Replacement allocated');
			}
			
			return statuses;
		}
    },
	{
        csrStatus: 'BER replacement',  
		csrlStatus: 'BER replacement',
		computeNextStatus: function() {
			let statuses = [];
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'BER approved',  'Call attempted','Document pending', 'Claim rejected');
				}
			}
			//ber end 
			
			return statuses;
		}
    },
	{
        csrStatus: 'BER replacement issued',  
		csrlStatus: 'BER replacement issued',
		computeNextStatus: function() {
			let statuses = [];
			if(berChecks){
				if(((SERVICETYPE.BER_IDENTIFIED == consumerServiceRequest.ServiceTypeID) || (SERVICETYPE.BER_PAYMENT == consumerServiceRequest.ServiceTypeID) || (SERVICETYPE.BER_REPLACEMENT == consumerServiceRequest.ServiceTypeID)) && accessFn('CLAIM_REQUEST')){
					if(consumerServiceRequest.ConsumerServiceRequestDetails && consumerServiceRequest.ConsumerServiceRequestDetails.IsExternalForwardShipment){
						statuses = concat(statuses,'Service completed', 'BER device received', 'BER accessories validated','BER accessories rejected', 'Call attempted');
					}else{
						statuses = concat(statuses,'Service completed', 'BER device received', 'BER accessories validated','BER accessories rejected', 'BER shipment created', 'Add Shipment Details', 'Intimate customer', 'Call attempted');
					}
				}
				if(!isReplacementPrerequisitesApplied ) {
					setIsReplacementPrerequisitesApplied(true);
					if(consumerServiceRequest.ConsumerServiceRequestDetails &&  consumerServiceRequest.ConsumerServiceRequestDetails.IsExternalForwardShipment){
						statuses = pullAll(statuses,['BER replacement confirmed', 'BER replacement issued', 'BER shipment created', 'Add Shipment Details', 'Intimate customer', 'BER accessories received']);
					}else{
						statuses = pullAll(statuses,['BER replacement confirmed', 'BER replacement issued', 'BER accessories received']);
					}
				}
			}
			
			return statuses;
		}
    },
	{
        csrStatus: 'BER replacement issued',  
		csrlStatus: 'BER shipment created',
		computeNextStatus: function() {
			let statuses = [];
			if(berChecks){
				if(((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID) || (SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID) || (SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID)) && accessFn('CLAIM_REQUEST')){
					if(consumerServiceRequest.ConsumerServiceRequestDetails && consumerServiceRequest.ConsumerServiceRequestDetails.IsExternalForwardShipment){
						statuses = concat(statuses,'Service completed', 'BER device received', 'BER accessories validated','BER accessories rejected', 'Call attempted');
					}else{
						statuses = concat(statuses,'Service completed', 'BER device received', 'BER accessories validated','BER accessories rejected', 'BER shipment created', 'Add Shipment Details', 'Intimate customer', 'Call attempted');
					}
				}
				if(!isReplacementPrerequisitesApplied ) {
					setIsReplacementPrerequisitesApplied(true);
					if(consumerServiceRequest.ConsumerServiceRequestDetails &&  consumerServiceRequest.ConsumerServiceRequestDetails.IsExternalForwardShipment){
						statuses = pullAll(statuses,['BER replacement confirmed', 'BER replacement issued', 'BER shipment created', 'Add Shipment Details', 'Intimate customer', 'BER accessories received']);
					}else{
						statuses = pullAll(statuses,['BER replacement confirmed', 'BER replacement issued', 'BER accessories received']);
					}
				}
			}
			
			return statuses;
		}
    },
	{
        csrStatus: 'BER replacement issued',  
		csrlStatus: 'Request Scheduled',
		computeNextStatus: function() {
			let statuses = [];
			if(berChecks){
				if(((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID) || (SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID) || (SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID)) && accessFn('CLAIM_REQUEST')){
					if(consumerServiceRequest.ConsumerServiceRequestDetails && consumerServiceRequest.ConsumerServiceRequestDetails.IsExternalForwardShipment){
						statuses = concat(statuses,'Service completed', 'BER device received', 'BER accessories validated','BER accessories rejected', 'Call attempted');
					}else{
						statuses = concat(statuses,'Service completed', 'BER device received', 'BER accessories validated','BER accessories rejected', 'BER shipment created', 'Add Shipment Details', 'Intimate customer', 'Call attempted');
					}
				}
				if(!isReplacementPrerequisitesApplied ) {
					setIsReplacementPrerequisitesApplied(true);
					if(consumerServiceRequest.ConsumerServiceRequestDetails &&  consumerServiceRequest.ConsumerServiceRequestDetails.IsExternalForwardShipment){
						statuses = pullAll(statuses,['BER replacement confirmed', 'BER replacement issued', 'BER shipment created', 'Add Shipment Details', 'Intimate customer', 'BER accessories received']);
					}else{
						statuses = pullAll(statuses,['BER replacement confirmed', 'BER replacement issued', 'BER accessories received']);
					}
				}
			}
			
			return statuses;
		}
    },
	{
        csrStatus: 'BER documents validated',  
		csrlStatus: 'BER documents validated',
		computeNextStatus: function() {
			let statuses = [];
			//ber
			if(berChecks){
				if (((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID) || (SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID) || (SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID)) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'BER device received','BER accessories received','Request BER Payment','BER accessories validated','BER accessories rejected', 'Claim rejected', 'Call attempted');
				}
			}
			//ber end 
			
			return statuses;
		}
    },
	{
        csrStatus: 'BER accessories received',  
		csrlStatus: 'BER accessories received',
		computeNextStatus: function() {
			let statuses = [];
			//ber
			if(berChecks){
				if (((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID) || (SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID) || (SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID)) && accessFn('CLAIM_REQUEST')) {
					if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 60){
						statuses = concat(statuses,'Service completed', 'BER device received','BER accessories validated','BER accessories rejected', 'BER replacement issued','BER shipment created', 'Add Shipment Details', 'Device dispatched', 'Call attempted');
					}else{
						statuses = concat(statuses,'Document pending','BER device received','BER documents validated','BER accessories validated','BER accessories rejected', 'Call attempted');
					}
				}
			}
			//ber end 
			
			return statuses;
		}
    },
	{
        csrStatus: 'BER device received',  
		csrlStatus: 'BER device received',
		computeNextStatus: function() {
			let statuses = [];
			//ber
			if(berChecks){
				if (((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID) || (SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID) || (SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID)) && accessFn('CLAIM_REQUEST')) {
					if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 60){
						statuses = concat(statuses,'Service completed', 'BER accessories received', 'BER accessories validated','BER accessories rejected', 'BER replacement issued','BER shipment created', 'Add Shipment Details', 'Device dispatched', 'Call attempted');
					}else{
						statuses = concat(statuses,'Document pending','BER accessories received','Request BER Payment','BER documents validated','BER accessories validated','BER accessories rejected', 'Call attempted');
					}
				}
			}
			//ber end 
			
			return statuses;
		}
    },
	{
        csrStatus: 'BER accessories rejected',  
		csrlStatus: 'BER accessories rejected',
		computeNextStatus: function() {
			let statuses = [];
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 60){
						statuses = concat(statuses,'Service completed', 'BER device received', 'BER replacement issued','BER shipment created', 'Add Shipment Details', 'Device dispatched', 'Call attempted');
					}else{
						statuses = concat(statuses,'Document pending','Request BER Payment','BER documents validated','BER device received', 'Call attempted');
					}
				}
			}
			//ber end 
			
			return statuses;
		}
    },
	{
        csrStatus: 'BER shipment created',  
		csrlStatus: 'BER shipment created',
		computeNextStatus: function() {
			let statuses = [];
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'BER device received','BER accessories validated','BER accessories rejected', 'Device dispatched','Call attempted');
				}
				if(!isReplacementPrerequisitesApplied ) {
					setIsReplacementPrerequisitesApplied(true);
					if(consumerServiceRequest.ConsumerServiceRequestDetails &&  consumerServiceRequest.ConsumerServiceRequestDetails.IsExternalForwardShipment){
							statuses = pullAll(statuses,['BER shipment created', 'Add Shipment Details', 'BER replacement confirmed', 'BER replacement issued', 'Device dispatched', 'Intimate customer', 'BER accessories received']);
						}else{
							statuses = pullAll(statuses,['BER shipment created', 'Add Shipment Details','Service completed', 'BER replacement confirmed', 'BER replacement issued', 'Intimate customer', 'BER accessories received']);
					}
					
				}
			}
			//ber end 
			return statuses;
		}
    },
	{
        csrStatus: 'BER payment initiated',  
		csrlStatus: 'BER payment initiated',
		computeNextStatus: function() {
			let statuses = [];
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'BER payment completed', 'Call attempted');
				}
				
			}
			//ber end 
			return statuses;
		}
    },
	{
        csrStatus: 'BER replacement confirmed',  
		csrlStatus: 'BER replacement confirmed',
		computeNextStatus: function() {
			let statuses = [];
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses = concat(statuses,document);
			// 	})
			// })
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'BER device received','BER accessories received','BER accessories validated','BER accessories rejected', 'BER replacement issued','Call attempted');
				}
				
			}
			//ber end 
			return statuses;
		}
    },
	{
        csrStatus: 'BER confirmed',  
		csrlStatus: 'BER confirmed',
		computeNextStatus: function() {
			let statuses = [];
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					if(berValid.paymentModeCheque){
						if(berFlags.defectiveRequired){
							statuses = concat(statuses,'BER accessories received','BER device received','BER accessories validated','BER accessories rejected', 'Call attempted');
						}else{
							statuses = concat(statuses,'Request BER Payment','Call attempted');
						}
					}else{
						statuses = concat(statuses,'Document pending', 'Call attempted');
					}
				}
				
			}
			//ber end 
			return statuses;
		}
    },
	{
        csrStatus: 'BER accessories validated',  
		csrlStatus: 'BER accessories validated',
		computeNextStatus: function() {
			let statuses = [];
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == 60){
						statuses = concat(statuses,'Service completed', 'BER device received', 'BER replacement issued','BER shipment created', 'Add Shipment Details', 'Device dispatched', 'Call attempted');
					}else{
						statuses = concat(statuses,'Document pending','Request BER Payment','BER documents validated','BER device received', 'Call attempted');
					}
				}
				
			}
			//ber end 
			return statuses;
		}
    },
	{
        csrStatus: 'BER payment completed',  
		csrlStatus: 'BER payment completed',
		computeNextStatus: function() {
			let statuses = [];
			//ber
			if(berChecks){
				if ((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')) {
					statuses = concat(statuses,'Service completed', 'Call attempted');
				}
				
			}
			//ber end 
			return statuses;
		}
    },
	{
        csrStatus: 'Device diagnosed',  
		csrlStatus: 'Device diagnosed',
		computeNextStatus: function() {
			let statuses = [];
			//enquiry
			if(([SERVICETYPE.ENQUIRY].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && !accessFn('READ_ONLY'))) {
				statuses = concat(statuses,'Call attempted')
				if (accessFn('DIAGNOSIS')) {
					statuses= concat(statuses,'Show device diagnosis', 'ASC visit advised', 'Issue resolved', 'Service cancel');
				}
			}
			//end enquiry
			return statuses;
		}
    },
	{
        csrStatus: 'Enquiry initiated',  
		csrlStatus: 'Enquiry initiated',
		computeNextStatus: function() {
			let statuses = [];
			let enquiryStatuses = [];
			if((accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && !(showRequestLogs.isServiceCompleteRated) && !(showRequestLogs.isServiceCancel)) {
				statuses = concat(statuses, 'Call attempted');
			}
			//enquiry
			if(([SERVICETYPE.ENQUIRY].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && !accessFn('READ_ONLY'))) {
				if (accessFn('DIAGNOSIS')) {
					enquiryStatuses= concat(enquiryStatuses,'ASC visit advised', 'Issue resolved', 'Service cancel');
				}
				if(consumerServiceRequest.Remarks && consumerServiceRequest.Remarks.IsRefundable){
					enquiryStatuses = ['Advised for RVP','RVP rejected', 'Issue resolved', 'Service cancel'];
				}
				statuses = concat(statuses,enquiryStatuses);
			}
			//end enquiry
			return statuses;
		}
    },
	{
        csrStatus: 'ASC visit advised',  
		csrlStatus: 'ASC visit advised',
		computeNextStatus: function() {
			let statuses = [];
			let enquiryStatuses = [];
			if((accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && !(showRequestLogs.isServiceCompleteRated) && !(showRequestLogs.isServiceCancel)) {
				statuses = concat(statuses, 'Call attempted');
			}
			//enquiry
			//end enquiry
			return statuses;
		}
    },
	{
        csrStatus: 'Issue resolved',  
		csrlStatus: 'Issue resolved',
		computeNextStatus: function() {
			let statuses = [];
			let enquiryStatuses = [];
			if((accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && !(showRequestLogs.isServiceCompleteRated) && !(showRequestLogs.isServiceCancel)) {
				statuses = concat(statuses, 'Call attempted');
			}
			//enquiry
			//end enquiry
			return statuses;
		}
    },
	{
        csrStatus: 'Diagnosis triggered',  
		csrlStatus: 'Diagnosis triggered',
		computeNextStatus: function() {
			let statuses = [];
			let enquiryStatuses = [];
			if((accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && !(showRequestLogs.isServiceCompleteRated) && !(showRequestLogs.isServiceCancel)) {
				statuses = concat(statuses, 'Call attempted');
			}
			//enquiry
			if(([SERVICETYPE.ENQUIRY].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && !accessFn('READ_ONLY'))) {
				if (accessFn('DIAGNOSIS')) {
					statuses = concat(statuses,'ASC visit advised', 'Issue resolved', 'Service cancel');
				}
			}
			//end enquiry
			return statuses;
		}
    },
	{
		csrStatus: 'Returned loaner diagnosis',
		csrlStatus: 'Returned loaner diagnosis',
		computeNextStatus: function() {
			let statuses = [];
			//pick up carry in gsx
			if(pickupCarryInGSXChecks &&  consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if(consumerServiceRequest && !consumerServiceRequest.loanerDetails){
						statuses.push('Intimate customer');
					}
					if (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN) {
						if(consumerServiceRequest && consumerServiceRequest.IsWarrantyApplicable){
							if(consumerServiceRequest && consumerServiceRequest.loanerDetails  && consumerServiceRequest.loanerDetails.loanerRequested){
								if(['LORC', 'LOCN', 'LOUN'].indexOf(consumerServiceRequest.loanerDetails.currentStatus) > -1){
									statuses.push('Service completed');
								}
							} else {
								statuses.push('Service completed');
							}
						}else{
							statuses.push('Service completed');
						}
						
					}
				}
			}
			//end pick up carry in gsx
			return statuses;
		}
	},
	{
		//kal se start
		csrStatus: 'Device transferred',
		csrlStatus: 'Device transferred',
		computeNextStatus: function () {
			let statuses = [];
			if (accessFn('ACCEPT_RETURN_DEVICE')) {
				if (accessFn('GSX_FLOW')) {
					if (!consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.WhitelistedDevice && qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 && consumerServiceRequest.consumerProduct.gsxInfo.isCellular) {
						statuses = concat(statuses, 'Check whitelisting');
					}
					else {
						if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) > -1 && !consumerServiceRequest.originCenter){
							statuses = concat(statuses, 'Device received');
						}
					}
				}
				else {
					if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) > -1 && !consumerServiceRequest.originCenter){
						statuses = concat(statuses, 'Device received');
					}
				}
			}
			return statuses;
		}
	},
	{
		csrStatus: 'Device returned',
		csrlStatus: 'Device returned',
		computeNextStatus: function() {
			let statuses = [];
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if (accessFn('ACCEPT_RETURN_DEVICE')) {
						if (showRequestValid.allowCCToProceedWithService) {
							if(showRequestLogs.isRepairCancel){
								if (consumerServiceRequest.ServiceTypeID == 1) {
									if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
										statuses = concat(statuses, 'Device dispatched');
									} else {
										statuses = concat(statuses, 'Initiate logistics request');
									}
								} else {
									if(consumerServiceRequest.originCenter){
										statuses = concat(statuses, 'Service cancel');
									}
								}
							}else{
								if (consumerServiceRequest.ServiceTypeID == 1 && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
									statuses.push('Payment received');
								} else if (consumerServiceRequest.ServiceTypeID == 1) {
									if(!consumerServiceRequest.IsWarrantyApplicable  && consumerServiceRequest.showGenerateInvoiceButton){
										statuses = concat(statuses, 'Service invoice generated');
									}else if (consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled) {
										statuses = concat(statuses, 'Device dispatched');
									} else {
										statuses = concat(statuses, 'Initiate logistics request');
									}
								} else if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin' && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ConsumerAmount > 0 && consumerServiceRequest.allowReceivePayment) {
									statuses.push('Payment received');
								}else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.showGenerateInvoiceButton ){
									statuses = concat(statuses, 'Service invoice generated');
								} else if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType != 'Walkin') {
									statuses.push('Intimate customer');
								} else if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && consumerServiceRequest.CheckInType == 'Walkin' && consumerServiceRequest.consumerServiceRequestFeedback && consumerServiceRequest.consumerServiceRequestFeedback.length > 0 && consumerServiceRequest.consumerServiceRequestFeedback[0]['Rating']) {
									statuses.push('Service completed');
								} else {
									// pass
								}
							}
						}
					}
				}
			}
			return statuses;
		}
	},	
	{
        csrStatus: 'Categorisation pending',  
		csrlStatus: 'Categorisation pending',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.DEMO, SERVICETYPE.SERVICING,56,61,62,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
				if((accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && !(showRequestLogs.isServiceCompleteRated) && !(showRequestLogs.isServiceCancel)) {
					statuses = concat(statuses, 'Call attempted');
				}
			}
			if(refundProcessCheck){
				if (accessFn('CLUSTER_MANAGER')) {
					statuses = concat(statuses, 'Analysis completed');
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Waiting on customer',  
		csrlStatus: 'Waiting on customer',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Response Received']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = merge(claimStatuses, ['Response Received']);
							}else{
								claimStatuses = merge(claimStatuses, ['Response Received']);
							}
						}
					}
				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses,['Response Received']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = merge(claimStatuses, ['Response Received']);
							}else{
								claimStatuses = merge(claimStatuses, ['Response Received']);
							}
						}
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = merge(claimStatuses,['Response Received']);
						if(insurancePlans.selectPlanBenefit){
							claimStatuses = merge(claimStatuses, ['Response Received']);
						}else{
							claimStatuses = merge(claimStatuses, ['Response Received']);
						}
					}
				} 
				if(!accessFn('WAITING_ON_CUSTOMER_BUTTON')){
					claimStatuses = pull(claimStatuses, 'Response Received');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			//claim new
			if(claimNewWorkshopChecks){
				if(accessFn('WAITING_ON_CUSTOMER_BUTTON')){
					statuses = concat(statuses, 'Response Received');
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Claim estimation revision required',  
		csrlStatus: 'Claim estimation revision required',
		computeNextStatus: function() {
			let statuses = [];
			return statuses;
		}
    },
	{
        csrStatus: 'Repair initiated',  
		csrlStatus: 'Repair initiated',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if( accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck() ){
						statuses = concat(statuses,'Complete Without Repair','Cancel Repair')
						if(!accessFn('HIDE_SERVICE_COMPLETE')){
							statuses = concat(statuses,'Complete Service');
						}
						if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
							statuses=concat(statuses,'Generate Invoice')
						}
						if(!consumerServiceRequest.showEngineerVisits && !consumerServiceRequest.InvoiceGeneratedDate ){
							statuses = concat(statuses,'Cancel Service')
						}
						if(!accessFn('HIDE_REPAIR_COMPLETE')){
							statuses = concat(statuses,'Complete Repair')
						}
						if(!accessFn('HIDE_PARTS_PENDING_BUTTON') && !consumerServiceRequest.InvoiceGeneratedDate){
							statuses = concat(statuses,'Parts Pending')
						}
					}
					if(!consumerServiceRequest.isFree &&  !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if( accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck() ){
						statuses = concat(statuses,'Complete Without Repair','Cancel Repair')
						if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
							statuses=concat(statuses,'Generate Invoice')
						}
						if(!accessFn('HIDE_REPAIR_COMPLETE')){
							statuses = concat(statuses,'Complete Repair')
						}
						if(!accessFn('HIDE_PARTS_PENDING_BUTTON') && !consumerServiceRequest.InvoiceGeneratedDate){
							statuses = concat(statuses,'Parts Pending')
						}
					}
					if(!accessFn('READ_ONLY')  && accessFn('REQUEST_REASSIGN')){
						if(!consumerServiceRequest.InvoiceGeneratedDate){
								statuses = concat(statuses,'Cancel Repair');
						}
					}
					if(!consumerServiceRequest.isFree &&  !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				if([SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if( accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck() ){
						statuses = concat(statuses,'Complete Without Repair','Cancel Repair')
						if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
							statuses=concat(statuses,'Generate Invoice')
						}
						if(!accessFn('HIDE_REPAIR_COMPLETE')){
							statuses = concat(statuses,'Complete Repair')
						}
						if(!accessFn('HIDE_PARTS_PENDING_BUTTON') && !consumerServiceRequest.InvoiceGeneratedDate){
							statuses = concat(statuses,'Parts Pending')
						}
					}
					if(!accessFn('READ_ONLY')  && accessFn('REQUEST_REASSIGN')){
						if(!consumerServiceRequest.InvoiceGeneratedDate){
								statuses = concat(statuses,'Cancel Repair');
						}
					}
					if(!consumerServiceRequest.isFree &&  !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
			}
			//refurbishment flow
			if(refurbishmentFlowCheck){
				statuses = concat(statuses,'Repair completed','Parts pending')
			}
			//refurbishment flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Repair initiated',  
		csrlStatus: 'Service invoice generated',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if( accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck() ){
						statuses = concat(statuses,'Complete Without Repair','Cancel Repair')
						if(!accessFn('HIDE_SERVICE_COMPLETE')){
							statuses = concat(statuses,'Complete Service');
						}
						if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
							statuses=concat(statuses,'Generate Invoice')
						}
						if(!consumerServiceRequest.showEngineerVisits && !consumerServiceRequest.InvoiceGeneratedDate ){
							statuses = concat(statuses,'Cancel Service')
						}
						if(!accessFn('HIDE_REPAIR_COMPLETE')){
							statuses = concat(statuses,'Complete Repair')
						}
						if(!accessFn('HIDE_PARTS_PENDING_BUTTON') && !consumerServiceRequest.InvoiceGeneratedDate){
							statuses = concat(statuses,'Parts Pending')
						}
					}
					if(!consumerServiceRequest.isFree &&  !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if( accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck() ){
						statuses = concat(statuses,'Complete Without Repair','Cancel Repair')
						if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
							statuses=concat(statuses,'Generate Invoice')
						}
						if(!accessFn('HIDE_REPAIR_COMPLETE')){
							statuses = concat(statuses,'Complete Repair')
						}
						if(!accessFn('HIDE_PARTS_PENDING_BUTTON') && !consumerServiceRequest.InvoiceGeneratedDate){
							statuses = concat(statuses,'Parts Pending')
						}
					}
					if(!accessFn('READ_ONLY')  && accessFn('REQUEST_REASSIGN')){
						if(!consumerServiceRequest.InvoiceGeneratedDate){
								statuses = concat(statuses,'Cancel Repair');
						}
					}
					if(!consumerServiceRequest.isFree &&  !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				if([SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if( accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck() ){
						statuses = concat(statuses,'Complete Without Repair','Cancel Repair')
						if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
							statuses=concat(statuses,'Generate Invoice')
						}
						if(!accessFn('HIDE_REPAIR_COMPLETE')){
							statuses = concat(statuses,'Complete Repair')
						}
						if(!accessFn('HIDE_PARTS_PENDING_BUTTON') && !consumerServiceRequest.InvoiceGeneratedDate){
							statuses = concat(statuses,'Parts Pending')
						}
					}
					if(!accessFn('READ_ONLY')  && accessFn('REQUEST_REASSIGN')){
						if(!consumerServiceRequest.InvoiceGeneratedDate){
								statuses = concat(statuses,'Cancel Repair');
						}
					}
					if(!consumerServiceRequest.isFree &&  !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Refurb Request Created',  
		csrlStatus: 'Refurb Request Created',
		computeNextStatus: function() {
			let statuses = [];
			return statuses;
		}
    },
	
	{
        csrStatus: 'Analysis completed',  
		csrlStatus: 'Analysis completed',
		computeNextStatus: function() {
			let statuses = [];
			if(refundProcessCheck){
				if (accessFn('CLUSTER_MANAGER')) {
					statuses = concat(statuses, 'Service completed');
				}
			}
			if(serviceRequestClusterCheck){
				if (accessFn('COMPLETE_ANALYSIS')) {
					statuses = concat(statuses, 'Service completed');
				}
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Pending for Analysis',  
		csrlStatus: 'Pending for Analysis',
		computeNextStatus: function() {
			let statuses = [];
			if(serviceRequestClusterCheck){
				if (accessFn('COMPLETE_ANALYSIS')) {
					statuses = concat(statuses, 'Analysis completed');
				}
			}
			return statuses;
		}
    },
	{
		csrStatus: 'Refurbishment request created',  
		csrlStatus: 'Refurbishment request created',
		computeNextStatus: function() {
			let statuses = [];
			if(refurbishmentFlowCheck){
				statuses = concat(statuses,'Add Shipment Details','Repair initiated','Service cancel');//,'refurbishment shipment created'
			}
			return statuses;
		}
	},
	{
        csrStatus: 'Pending for reimbursement',  
		csrlStatus: 'Pending for reimbursement',
		computeNextStatus: function() {
			let statuses = [];
			if(replacementFlowChecks){
				statuses = concat(statuses,'Service cancel', 'Convert to reimbursement')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Device shipment requested',  
		csrlStatus: 'Device shipment requested',
		computeNextStatus: function() {
			let statuses = [];
			if(accessFn('ACCEPT_RETURN_DEVICE') && accessFn('GSX_FLOW') && accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')){
				statuses = concat(statuses,'Ready for shipment', 'Repair cancel')
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Refurbishment shipment created',  
		csrlStatus: 'Refurbishment shipment created',
		computeNextStatus: function() {
			let statuses = [];
			if(refurbishmentFlowCheck){
				statuses = concat(statuses,'Device received');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Ready for shipment',  
		csrlStatus: 'Ready for shipment',
		computeNextStatus: function() {
			let statuses = [];
			if(accessFn('ACCEPT_RETURN_DEVICE') && accessFn('GSX_FLOW') && accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')){
				statuses = concat(statuses,'Ship to service centre', 'Repair cancel')
			}
			if(accessFn('REASSIGN_JOBS') && accessFn('GSX_FLOW') && !consumerServiceRequest.ProvisionalGSXNumber && consumerServiceRequest.CREATED_AT_DROPOFF_PSL==="true"){
				statuses = concat(statuses, 'Map G-Number');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Device delivered',  
		csrlStatus: 'Device delivered',
		computeNextStatus: function() {
			let statuses = [];
			if(refurbishmentFlowCheck){
				statuses = concat(statuses,'Device inspected');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Shipped to service centre',  
		csrlStatus: 'Shipped to service centre',
		computeNextStatus: function() {
			let statuses = [];
			if(accessFn('ACCEPT_RETURN_DEVICE') && accessFn('GSX_FLOW') && !accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')){
				statuses = concat(statuses,'Device received at service centre')

			}
			return statuses;
		}
    },
	{
        csrStatus: 'Device received at service centre',
		csrlStatus: 'Device received at service centre',
		computeNextStatus: function() {
			let statuses = [];
			if(accessFn('ACCEPT_RETURN_DEVICE') && accessFn('GSX_FLOW') && !accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')){
				statuses = concat(statuses, 'Device inspected');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Device shipped to drop-off location',
		csrlStatus: 'Device shipped to drop-off location',
		computeNextStatus: function() {
			let statuses = [];
			if(accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')) {
				statuses = concat(statuses, 'Device Received');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Device received at drop-off location',
		csrlStatus: 'Device received at drop-off location',
		computeNextStatus: function() {
			let statuses = [];
			if(accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')) {
				statuses = concat(statuses, 'Intimate customer');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Device received and customer informed',
		csrlStatus: 'Device received and customer informed',
		computeNextStatus: function() {
			let statuses = [];
			if(accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')) {
				if(consumerServiceRequest.IsWarrantyApplicable) {
					statuses = concat(statuses, 'Service completed');
				}else {
					statuses = concat(statuses, 'Payment received');
				}
			}
			return statuses;
		}
    },{
		csrStatus: 'Service initiated', 
		csrlStatus: 'Part added',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						if( accessFn( 'REQUEST_REASSIGN')){
							if(!accessFn('HIDE_REPAIR_COMPLETE')){
								statuses = concat(statuses,'Complete Repair');
							}
							statuses = concat(statuses,'Complete Service','Complete Without Repair');
							if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !consumerServiceRequest.InvoiceGeneratedDate){
								statuses = concat(statuses,'Cancel Service');
							}
							if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
								statuses=concat(statuses,'Generate Invoice')
							}
						}
						if(consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !accessFn('HIDE_PARTS_PENDING_BUTTON') ){
							statuses=concat(statuses,'Parts Pending');
						}
					}
					if((!consumerServiceRequest.isFree) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				
				if( [SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !accessFn('READ_ONLY') && ([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REQUEST_REASSIGN') ){
						statuses=concat(statuses,'Cancel Repair')
					}
					if(!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						if(accessFn( 'REQUEST_REASSIGN') ){
							if(!accessFn('HIDE_REPAIR_COMPLETE')){
								statuses = concat(statuses,'Complete Repair');
							}
							statuses = concat(statuses,'Complete Without Repair');
							if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
								statuses=concat(statuses,'Generate Invoice')
							}
						}
						if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate  && !accessFn('HIDE_PARTS_PENDING_BUTTON') ){
							statuses=concat(statuses,'Parts Pending');
						}
					}
					if((!consumerServiceRequest.isFree) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
						if (accessFn('TECHNICIAN_SUPERVISOR') && !isSymptomMapAdded()) {
							if (consumerServiceRequest && consumerServiceRequest.IsDeviceValidated) {
								statuses = concat(statuses, 'Iris submitted');
							}
						} else if (accessFn('TECHNICIAN_SUPERVISOR') && getSource()!='Bose'){
							statuses = concat(statuses, 'Service invoice generated');
						}
					}
					if (isBoseFlow) {
						statuses = concat(statuses, 'Visit productive');
						statuses = concat(statuses, 'Visit unproductive');
					}
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Repair completed');
					}
				}
			}
			//onsite end
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch', 'Repair completed', 'Parts pending']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Service cancel', 'Repair completed', 'Service completed', 'Parts pending']);
						// }
					}
					if(consumerServiceRequest && consumerServiceRequest.autoClaimFulfillmentFlow) {
						claimStatuses =  ['Repair cancel', 'Service cancel', 'Repair completed']
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Repair cancel', 'Service cancel', 'Repair completed', 'Service completed', 'Parts pending']);
						// }
					}
				}
				statuses = concat(statuses,claimStatuses)
			}
			//claim end
			return statuses;
		}
	},
	{
        csrStatus: 'Claim estimation approved',  
		csrlStatus: 'Part added',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && !buttonLogStatus.tecnicianReached) {
							statuses = concat(statuses, 'Service reschedule');
						}

						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							setPendingForSpares(false);
						//   pendingForSpares = false;
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						statuses = concat(statuses, 'Parts pending');
						if(buttonLogStatus.tecnicianReached) { 
							statuses = concat(statuses, 'Visit productive','Visit unproductive');
						}
						// statuses = concat(statuses, 'No fault found');
						}
					}
				}
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			return statuses;
		}
    },{
		csrStatus: 'Payment pending',
		csrlStatus: 'Part added',
		computeNextStatus: function () {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if(([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) ){
					if((!accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck())){
						statuses = concat(statuses,'Payment Received')
					}
					if(!consumerServiceRequest.showEngineerVisits  && accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Cancel Service')
					}
				}
			}
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Payment received','Repair cancel','Cancel Service Without Dispatch');
				}
				accessFn('SEND_PAYMENT_LINK') && statuses.push('Send payment link');
			}
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Service cancel']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Payment received', 'Send payment link', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}
				if(!accessFn('SEND_PAYMENT_LINK')) {
					// claimStatuses = claimStatuses.pull('Send payment link');
					claimStatuses = pull(claimStatuses, 'Send payment link');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Resend Payment Link','Payment received','Service cancel');
				if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					statuses = pull(statuses, 'Payment received');
				}	
			}
			//end replacement flow
			return statuses;
		}
	},{
        csrStatus: 'Payment received', 
		csrlStatus: 'Part added',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if( ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if( !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){//
						if(accessFn('REQUEST_REASSIGN')){
							if(!accessFn('HIDE_REPAIR_COMPLETE')){
								statuses = concat(statuses,'Complete Repair');
							}
							statuses=concat(statuses,'Complete Without Repair');
						}
					}
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (accessFn ('TECHNICIAN_SUPERVISOR')) {
						if (showCompleteServiceFunction() && getSource()!='Bose') {
							statuses = concat(statuses, 'Service completed');
						}
					}
					if (isBoseFlow) {
						if (consumerServiceRequest.InvoiceGeneratedDate) {
							statuses = concat(statuses, 'Visit productive');
						}
					}
				}
			}
			//onsite end
			//claim new 
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
				// pass
				}
			}
			//end claim new
			//claim
			if(claimChecks){
				if([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Service initiated', 'Repair cancel', 'Cancel Service Without Dispatch']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = merge(claimStatuses, ['Service initiated']);
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
						claimStatuses = merge(claimStatuses,['Service initiated', 'Repair cancel', 'Cancel Service Without Dispatch']);
					// }
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end claim new
			// Pickup carry in 
			if(pickupCarryInGSXChecks){
				if(accessFn('ACCEPT_RETURN_DEVICE')){
					if(consumerServiceRequest.showGenerateInvoiceButton){
						statuses = concat(statuses,'Service invoice generated');
					}else{
						if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							if(consumerServiceRequest.returnFlow && !consumerServiceRequest.returnFlow.IsCancelled){
								statuses = concat(statuses, 'Device dispatched');
							}else{
								statuses = concat(statuses, 'Initiate logistics request');
							}
						}else if(consumerServiceRequest.ServiceTypeID == 2 && consumerServiceRequest.CheckInType != 'Walkin') {
							statuses = concat(statuses,'Intimate customer');
						} else if (consumerServiceRequest.ServiceTypeID == 2 && consumerServiceRequest.CheckInType == 'Walkin') {
							statuses = concat(statuses, 'Intimate customer', 'Service completed');
						}
					}
				}
			}
			//end pickup carry in
			//replacement flow
			if(replacementFlowChecks){
				statuses = concat(statuses,'Replacement shipment created','Add Shipment Details','Service completed');
			}
			//end replacement flow
			return statuses;
		}
    },{
        csrStatus: 'Repair initiated',  
		csrlStatus: 'Part added',
		computeNextStatus: function() {
			let statuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if( accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck() ){
						statuses = concat(statuses,'Complete Without Repair')
						if(!accessFn('HIDE_SERVICE_COMPLETE')){
							statuses = concat(statuses,'Complete Service');
						}
						if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
							statuses=concat(statuses,'Generate Invoice')
						}
						if(!consumerServiceRequest.showEngineerVisits && !consumerServiceRequest.InvoiceGeneratedDate ){
							statuses = concat(statuses,'Cancel Service')
						}
						if(!accessFn('HIDE_REPAIR_COMPLETE')){
							statuses = concat(statuses,'Complete Repair')
						}
						if(!accessFn('HIDE_PARTS_PENDING_BUTTON') && !consumerServiceRequest.InvoiceGeneratedDate){
							statuses = concat(statuses,'Parts Pending')
						}
					}
					if(!consumerServiceRequest.isFree &&  !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				if([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if( accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck() ){
						statuses = concat(statuses,'Complete Without Repair')
						if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
							statuses=concat(statuses,'Generate Invoice')
						}
						if(!accessFn('HIDE_REPAIR_COMPLETE')){
							statuses = concat(statuses,'Complete Repair')
						}
						if(!accessFn('HIDE_PARTS_PENDING_BUTTON') && !consumerServiceRequest.InvoiceGeneratedDate){
							statuses = concat(statuses,'Parts Pending')
						}
					}
					if(!accessFn('READ_ONLY')  && accessFn('REQUEST_REASSIGN')){
						if(!consumerServiceRequest.InvoiceGeneratedDate){
								statuses = concat(statuses,'Cancel Repair');
						}
					}
					if(!consumerServiceRequest.isFree &&  !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				if([SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if( accessFn( 'REQUEST_REASSIGN') && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck() ){
						statuses = concat(statuses,'Complete Without Repair')
						if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree && !accessFn('HIDE_GENERATE_INVOICE_BUTTON')){
							statuses=concat(statuses,'Generate Invoice')
						}
						if(!accessFn('HIDE_REPAIR_COMPLETE')){
							statuses = concat(statuses,'Complete Repair')
						}
						if(!accessFn('HIDE_PARTS_PENDING_BUTTON') && !consumerServiceRequest.InvoiceGeneratedDate){
							statuses = concat(statuses,'Parts Pending')
						}
					}
					if(!accessFn('READ_ONLY')  && accessFn('REQUEST_REASSIGN')){
						if(!consumerServiceRequest.InvoiceGeneratedDate){
								statuses = concat(statuses,'Cancel Repair');
						}
					}
					if(!consumerServiceRequest.isFree &&  !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
			}
			//refurbishment flow
			if(refurbishmentFlowCheck){
				statuses = concat(statuses,'Repair completed','Parts pending')
			}
			//refurbishment flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Parts pending', 
		csrlStatus: 'Part added',
		computeNextStatus: function() { 
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(!accessFn('READ_ONLY')){
						if(!consumerServiceRequest.showEngineerVisits  && accessFn('REQUEST_REASSIGN') && warrantyAppicableNonClaimCheck()){
							statuses=concat(statuses,'Cancel Service');
						}
						statuses=concat(statuses,'Parts received');
					}
				}
				
				if([SERVICETYPE.PICK_UP ,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(!accessFn('READ_ONLY')){
						if(([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REQUEST_REASSIGN')){
							statuses=concat(statuses,'Cancel Repair');
						}
						statuses=concat(statuses,'Parts received');
					}
				}
			}

			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
							if (partsRequested && partsRequested.length > 0 ) {
									setIsEachPartReceived(true);
								for (let iCount = 0;iCount<partsRequested.length;iCount ++ ) {
									if (['received','rejected','orderRejected','returned','doa','cancelled','alternatePartIssued'].indexOf(partsRequested[iCount].statusDetails.Status) > -1 ) {

									} else {
										setIsEachPartReceived(false);
									}
								}
								if (isEachPartReceived) {
								statuses = concat(statuses, 'Service reschedule');
								}
							} else {
								statuses = concat(statuses, 'Service reschedule');
							}
						}
						
						if(consumerServiceRequest && consumerServiceRequest.EnableCancelButton || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard','SingularityDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 || (qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1)){
							if (accessFn('CANCEL_REQUEST_ANYTIME') && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
								statuses = concat(statuses, 'Service cancel');
							} else if (accessFn('INITIATE_CANCEL_REQUEST_ANYTIME')){
								statuses = concat(statuses, 'Service cancel initiated');
							}
						}
					}

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						statuses = concat(statuses, 'Parts received');
					}
				}
			}
			//onsite end
			//claim 
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = concat(claimStatuses, 'Parts received', 'Service cancel');
						// }
					}
				}else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = concat(claimStatuses, 'Parts received', 'Service cancel');
						// }
					}
				}else if(SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							claimStatuses = concat(claimStatuses,'Parts received', 'Repair cancel', 'Service cancel');
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end
			//refurbishment flow
			if(refurbishmentFlowCheck){
				statuses = concat(statuses,'Parts received')
			}
			//refurbishment flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Parts received',
		csrlStatus: 'Part added',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.CARRY_IN ,SERVICETYPE.PICK_UP ,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 ){
					if(consumerServiceRequest && accessFn('REQUEST_REASSIGN')  && warrantyAppicableNonClaimCheck() && !accessFn('READ_ONLY')){
						if(!consumerServiceRequest.showEngineerVisits){
							statuses = concat(statuses, 'Cancel Service');
						}
						if(!accessFn('HIDE_REPAIR_COMPLETE')){
							statuses = concat(statuses,'Complete Repair');
						}
						statuses = concat(statuses,'Complete Without Repair','Cancel Repair');
					}
					if((!consumerServiceRequest.isFree) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
						statuses = concat(statuses,'Payment Pending');
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY') && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
				if([SERVICETYPE.CARRY_IN, SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 && accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY') && !accessFn('HIDE_GENERATE_INVOICE_BUTTON') && warrantyAppicableNonClaimCheck()){
					if( consumerServiceRequest && !consumerServiceRequest.InvoiceGeneratedDate){
						statuses=concat(statuses,'Generate Invoice');
					}
				}
			}
			
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}

			//onsite start
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
							if (accessFn('CREATE_VISIT_ACCESS') && checkIfSalesChannelIsequiredAndPresent() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
							statuses = concat(statuses, 'Service reschedule');
						}
					}

					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						if (accessFn('CREATE_VISIT_ACCESS') && !buttonLogStatus.tecnicianReached) {
							statuses = concat(statuses, 'Service reschedule');
						} 
						else if(buttonLogStatus.tecnicianReached) {
							statuses = concat(statuses, 'Visit productive','Visit unproductive');
						}

						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
					}
				}
			}
			//onsite end
			//claim new
			if(claimNewWorkshopChecks){
				if (isSupOrEngg()) {
					statuses.push('Repair completed');
				}
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Repair cancel','Cancel Service Without Dispatch');
				}	
			}
			//end claim new

			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ){
							claimStatuses = concat(claimStatuses,'Repair completed', 'Cancel Service Without Dispatch', 'Service cancel');
						// }
					}

				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ){
							claimStatuses = concat(claimStatuses, 'Service completed', 'Repair completed', 'Repair cancel', 'Service cancel');
						// }
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('ADD_NORMAL_CHARGES')) {
						// if([SERVICETYPE.MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ){
							claimStatuses = concat(claimStatuses, 'Repair completed', 'Cancel Service Without Dispatch', 'Service cancel');
						// }
					}
				}
				statuses = concat(statuses,claimStatuses);
			}
			//end cliam
			// Pickup carry in 
			if(pickupCarryInChecks){
				if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
					if(isSupOrEngg()){
						if(isHandedToEng() && isDiagnosisCompleted() && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag){
							if (!consumerServiceRequest.gsxRepairRequest.confirmationNumber && (!showRequestLogs.receivedPartPresent || showRequestLogs.receivedPartPresent.length < 1)){
								statuses = concat(statuses, 'Request raised');
								if(accessFn('REASSIGN_JOBS')){
									statuses = concat(statuses, 'Map G-Number');
								}
							}
							if(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
								if(consumerServiceRequest.isMailinRequest && consumerServiceRequest.updateMailinKgb && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Update KGB');
								}
								else if(consumerServiceRequest.isMailinRequest && showRequestLogs.isConsignment && showRequestLogs.receivedConsumedConsignmentPart.length > 1 && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
									statuses = concat(statuses, 'Repair completed');
								}else{
									if((!showRequestLogs.receivedPartPresent|| showRequestLogs.receivedPartPresent.length < 1)){
										statuses = concat(statuses, 'Repair completed');
									}
								}
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_CANCEL')){
						if(deviceAtLoggedInLoc){
							statuses = concat(statuses, 'Repair cancel');
						}
					}
				}else{
					if(isSupOrEngg()){
						if(consumerServiceRequest.DOAStatus == 'replacement') {
						  statuses = concat(statuses, 'Repair completed');
						}else if(isRequestSFANeeded){
							if((requestSFA && requestSFA.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
								statuses = concat(statuses, 'Repair completed');
							}
						}else{
							if((consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 ) || (showRequestValid.isExceptionCase && showRequestLogs.isExceptionApproved)){
								statuses = concat(statuses, 'Repair completed');
							}
						}
					}
					if(accessFn('ACCEPT_RETURN_DEVICE')) {
						statuses = concat(statuses, 'Cancel Service Without Dispatch');
					}
					if(!accessFn('CANCEL_REVERT_INVENTORY')){
						if(accessFn('ACCEPT_RETURN_DEVICE') && !showRequestLogs.isExceptionApproved){
							statuses = concat(statuses, 'Repair cancel');
						}
					}else if(!showRequestLogs.isExceptionApproved && !checkDOAApplicable()){
						statuses = concat(statuses, 'Repair cancel initiated');
					}
				}
			}
			// Pickup carry in end
			//refurbishment flow
			if(refurbishmentFlowCheck){
				statuses = concat(statuses,'Repair completed')
			}
			//refurbishment flow end
			return statuses;
		}
    },
	{
        csrStatus: 'BER payment revision required',
		csrlStatus: 'BER payment revision required',
		computeNextStatus: function() {
			let statuses = [];
			//ber
			if(berChecks){
				if((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')){
					statuses = concat(statuses,'Service cancel','Request BER Payment');
				}
			}
			//ber end 
			return statuses;
		}
    },
	{
        csrStatus: 'Security deposit released',
		csrlStatus: 'Security deposit released',
		computeNextStatus: function() {
			let statuses = [];
			if((SERVICETYPE.ADVANCE_EXCHANGE === consumerServiceRequest.ServiceTypeID) ){
				statuses = concat(statuses,'Service completed');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Security deposit charged',
		csrlStatus: 'Security deposit charged',
		computeNextStatus: function() {
			let statuses = [];
			if((SERVICETYPE.ADVANCE_EXCHANGE === consumerServiceRequest.ServiceTypeID) ){
				statuses = concat(statuses,'Service completed');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'BER payment requested',
		csrlStatus: 'BER payment requested',
		computeNextStatus: function() {
			let statuses = [];
			//ber
			if(berChecks){
				if((SERVICETYPE.BER_IDENTIFIED === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_PAYMENT === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPLACEMENT === consumerServiceRequest.ServiceTypeID) && accessFn('CLAIM_REQUEST')){
					statuses = concat(statuses,'BER payment initiated','BER revision required');
				}
			}
			//ber end 
			return statuses;
		}
    },
	//{
	//	csrStatus: 'Service center document verification pending',
	//	csrlStatus: 'Service center document verification pending',
	//	computeNextStatus: function() {
	//		let statuses = ['Repair cancel'];
			
	//			if(!accessFn('HIDE_CANCEL_SERVICE_BUTTON')) {
	//				statuses = concat(statuses, 'Service cancel');
	//			}
			
	//		return statuses;
	//	}
	//},
	//{
	//	csrStatus: 'Consumer document verification pending',
	//	csrlStatus: 'Consumer document verification pending',
	//	computeNextStatus: function() {
	//		let statuses = ['Repair cancel'];
			
	//			if(!accessFn('HIDE_CANCEL_SERVICE_BUTTON')) {
	//				statuses = concat(statuses, 'Service cancel');
	//			}
			
	//		return statuses;
	//	}
	//},
	//{
	//	csrStatus: 'Service center document verification pending',
	//	csrlStatus: 'Service center document pending',
	//	computeNextStatus: function() {
	//		//let statuses = ['Claim estimation revision required'];
	//		let statuses = ['']
	//		return statuses;
	//	}
	//},
	//{
	//	csrStatus: 'Service center document verification pending',
	//	csrlStatus: 'Estimation generated',
	//	computeNextStatus: function() {
	//		let statuses = ['Claim estimation revision required'];
	//		return statuses;
	//	}
	//},
	{
        csrStatus: 'Device received',
		csrlStatus: 'Service center document verification pending',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.BUY_BACK,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.DELIVERY,SERVICETYPE.DROPOFF,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && !(accessFn('WORKSHOP_FLOW'))){
				if([SERVICETYPE.PICK_UP ,SERVICETYPE.DROPOFF ,SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1){
					if(accessFn('REQUEST_REASSIGN') && !accessFn('READ_ONLY')){
						statuses = concat(statuses,'Start Repair')
					}
				}
				// if(consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && accessFn('CANCEL_REQUEST_ANYTIME') && (!([9,13].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && !accessFn('READ_ONLY')  && warrantyAppicableNonClaimCheck()){
				// 	statuses = concat(statuses,'Cancel Service Without Dispatch');
				// }
			}
			if(([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && accessFn('CANCEL_LOGISTICS') && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && consumerServiceRequest && consumerServiceRequest.consumerServiceRequestLogistics && consumerServiceRequest.consumerServiceRequestLogistics[0] && consumerServiceRequest.consumerServiceRequestLogistics[0].Active){
				statuses = concat(statuses,'Cancel logistics request');
			}
			if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && !showRequestLogs.isServiceCancel && qFilter && qFilter.tradeInFeatureRights && qFilter.tradeInFeatureRights.canReset){
				statuses = concat(statuses,'Reset');
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })

			if(accessFn('EXCEPTION_REQUEST') && accessFn('WORKSHOP_FLOW') && showRequestLogs.showInitiateException && !showRequestLogs.isDOAApplicableLog){
				statuses=concat(statuses,'Initiate Exception')
			}
			//claim new workshop
			if(claimNewWorkshopChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					statuses = concat(statuses, 'Device inspected','Service cancel','Cancel Service Without Dispatch');
				}
			}
			// end claim new workshop
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found']);
					}
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'])
							}else{
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected'])
							}
						}
					}

				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found']);
					}
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses,  ['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
							}else{
								claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
							}
						}
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = merge(claimStatuses,  ['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						if(insurancePlans.selectPlanBenefit){
							claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}else{
							claimStatuses = merge(claimStatuses,['Waiting on customer','Repair cancel', 'Service cancel','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
						}
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found', 'Claim rejected']);
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch','Device not eligible for repair','Device not repairable','No fault found']);
					}

				}
				if(!accessFn('DEVICE_NOT_ELIGIBLE_FOR_REPAIR')){
					claimStatuses = pull(claimStatuses, 'Device not eligible for repair');
				}
				if(!accessFn('DEVICE_NOT_REPAIRABLE')){
					claimStatuses = pull(claimStatuses, 'Device not repairable');
				}
				if(!accessFn('NO_FAULT_FOUND')){
					claimStatuses = pull(claimStatuses, 'No fault found');
				}
				if(!accessFn('WAITING_ON_CUSTOMER_BUTTON')){
					claimStatuses = pull(claimStatuses, 'Waiting on customer');
				}
				statuses = concat(statuses,claimStatuses);
			}
			//claim end

			// Pickup carry in 
			if(pickupCarryInChecks){
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID) {
						if (accessFn('ACCEPT_RETURN_DEVICE')) {
							if(deviceAtLoggedInLoc){
								statuses = concat(statuses, 'Device inspected');
								if(!consumerServiceRequest.deviceTransferData || (consumerServiceRequest.deviceTransferData && !consumerServiceRequest.deviceTransferData.isTransferredRequest)) {
									statuses = concat(statuses, 'Service cancel');
								}
							}
							if(accessFn('NON_HARDWARE_CLOSURE')){
								statuses = concat(statuses, 'Non Hardware Closure');
							}
			
							if(consumerServiceRequest.AllowCCDiagnosis && consumerServiceRequest.isVerifiedProduct && ((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
								if(deviceAtLoggedInLoc){
									statuses = concat(statuses, 'Initiate Diagnostics');
								}
							}

							if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && !consumerServiceRequest.IsWarrantyApplicable && featureConfig && featureConfig.EnableCCEAdavnce){
								statuses = concat(statuses,'Advance collected');
							}
						}
					}
					else {
						statuses =concat(statuses, 'Device inspected', 'Service cancel');
						if(accessFn('NON_HARDWARE_CLOSURE')){
							statuses = concat(statuses, 'Non Hardware Closure');
						}
					}
				}
			}
			// Pickup carry in end 

			//refurbishment flow
			if(refurbishmentFlowCheck){
				statuses = concat(statuses,'Repair initiated')
			}
			//refurbishment flow end
			return statuses;
		}
    },
	{
        csrStatus: 'Claim estimation generated', 
		csrlStatus: 'Service center document verification pending',
		computeNextStatus: function() {
			let statuses = [];
			let claimStatuses = [];
			if(onsiteChecks){
				if(showButton){
					if (isServiceLocationAssigned()) {
						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
							if (accessFn('CANCEL_REQUEST_ANYTIME')) {
								statuses = concat(statuses, 'Service cancel');
							}
							accessFn('CLAIM_APPROVAL') && (statuses = concat(statuses, 'Claim estimation approved','Claim rejected'));
								statuses = concat(statuses, 'Device not repairable','No fault found');
							// statuses = concat(statuses, 'No fault found');
						}
					}
				}
			}
			// if(claimNewWorkshopChecks){
			// 	if (accessFn('CLAIM_WORKSHOP')) {
			// 		statuses = concat(statuses,'Cancel Service Without Dispatch','Repair cancel');

			// 	}
			// 	if (accessFn('CLAIM_APPROVAL')) {
			// 		statuses = concat(statuses,'Claim estimation approved');
			// 	}
			// }
			//end onsite
			//claim new
			if(claimNewWorkshopChecks){
				if (accessFn('CLAIM_WORKSHOP')) {
					statuses = concat(statuses,'Cancel Service Without Dispatch','Repair cancel');
				}
				if (accessFn('CLAIM_APPROVAL')) {
					statuses = concat(statuses,'Claim estimation approved', 'Claim rejected');
				}
				if(accessFn('WAITING_ON_CUSTOMER_BUTTON')){
					statuses = concat(statuses, 'Waiting on customer');
				}
			}
			//end claim new
			//claim
			if(claimChecks){
				if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && claimMergeStatus.isPickUp)) {
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch', 'Claim estimation approved', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses = ['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Plans Description', 'Claim rejected']
							}else{
								claimStatuses = ['Waiting on customer','Repair cancel', 'Cancel Service Without Dispatch','Claim estimation approved', 'Claim rejected']
							}
						}
					}
				} else if (SERVICETYPE.CLAIM_CARRY_IN === consumerServiceRequest.ServiceTypeID || SERVICETYPE.BER_REPAIR === consumerServiceRequest.ServiceTypeID) {
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Waiting on customer','Repair cancel', 'Service cancel', 'Claim estimation approved', 'Claim rejected']);
						if(accessFn('SHOW_PLAN_BENEFITS')){
							if(insurancePlans.selectPlanBenefit){
								claimStatuses =['Waiting on customer','Repair cancel', 'Service cancel','Plans Description', 'Claim rejected']
							}else{
								claimStatuses =['Waiting on customer','Repair cancel', 'Service cancel','Claim estimation approved', 'Claim rejected']
							}
						}
					}
					if(accessFn('SHOW_PLAN_BENEFITS')){
						claimStatuses = merge(claimStatuses, ['Waiting on customer','Repair cancel', 'Service cancel', 'Claim estimation approved', 'Claim rejected']);
						if(insurancePlans.selectPlanBenefit){
							claimStatuses =['Waiting on customer','Repair cancel', 'Service cancel','Plans Description', 'Claim rejected']
						}else{
							claimStatuses =['Waiting on customer','Repair cancel', 'Service cancel','Claim estimation approved', 'Claim rejected']
						}
					}
				} else if(SERVICETYPE.CLAIM_MAIL_IN == consumerServiceRequest.ServiceTypeID){
					if (accessFn('CLAIM_APPROVAL')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch', 'Claim estimation approved', 'Claim rejected']);
					}
					if (accessFn('ADD_NORMAL_CHARGES')) {
						claimStatuses = merge(claimStatuses, ['Repair cancel', 'Cancel Service Without Dispatch', 'Claim estimation approved', 'Claim rejected']);
					}
				}
				// let isChargeAdded = false;
				// if (consumerServiceRequest && consumerServiceRequest.charges && consumerServiceRequest.charges.length>0) {
				// 	for(let i = 0; i < consumerServiceRequest.charges.length; i++) {
				// 		let charge = consumerServiceRequest.charges[i];
				// 		if(['Other', 'Part'].indexOf(charge.Type) > -1){
				// 			isChargeAdded = true;
				// 			break;
				// 		}
				// 	}
				// }

				if(!showRequestValid.isChargeAdded) {
					claimStatuses = pull(claimStatuses, 'Claim estimation approved');
				}
				if(!accessFn('WAITING_ON_CUSTOMER_BUTTON')){
					claimStatuses = pull(claimStatuses, 'Waiting on customer');
				}
				statuses = concat(statuses,claimStatuses)
				
			}
			//claim end
			return statuses;
		}
    },
	{
        csrStatus: 'Defective received',
		csrlStatus: 'Security deposit released',
		computeNextStatus: function() {
			let statuses = [];
			if((SERVICETYPE.ADVANCE_EXCHANGE === consumerServiceRequest.ServiceTypeID) ){
				statuses = concat(statuses,'Service completed');
			}
			return statuses;
		}
    },
	{
		//onsite
        csrStatus: 'Claim estimation approved',  
		csrlStatus: 'Service center document verification pending',
		computeNextStatus: function() {
			let statuses = [];
			if(onsiteChecks){
				if(showButton){
					if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
						statuses = concat(statuses, 'Service completed');
					}
					if (isServiceLocationAssigned()) {
						if (accessFn('CREATE_VISIT_ACCESS') && !buttonLogStatus.tecnicianReached) {
							statuses = concat(statuses, 'Service reschedule');
						}

						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
							setPendingForSpares(false);
						//   pendingForSpares = false;
						if (accessFn('CANCEL_REQUEST_ANYTIME')) {
							statuses = concat(statuses, 'Service cancel');
						}
						statuses = concat(statuses, 'Parts pending');
						if(buttonLogStatus.tecnicianReached) { 
							statuses = concat(statuses, 'Visit productive','Visit unproductive');
						}
						// statuses = concat(statuses, 'No fault found');
						}
					}
				}
			}
			// consumerServiceRequest &&  consumerServiceRequest.slipUrl &&  consumerServiceRequest.slipUrl.map(item=>{
			// 	item.documents.map(document=>{
			// 		statuses=concat(statuses,document);
			// 	})
			// })
			return statuses;
		}
    },
	{
        csrStatus: 'Claim estimation revision required',  // as there was no buttons for 'Claim estimation revision required'
		csrlStatus: 'Service center document verification pending',
		computeNextStatus: function() {
			let statuses = [];
			return statuses;
		}
    },
	{
        csrStatus: 'Defective received',
		csrlStatus: 'Security deposit charged',
		computeNextStatus: function() {
			let statuses = [];
			if((SERVICETYPE.ADVANCE_EXCHANGE === consumerServiceRequest.ServiceTypeID) ){
				statuses = concat(statuses,'Service completed');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Plan transferred',  
		csrlStatus: 'Plan transferred',
		computeNextStatus: function() {
			let statuses = [];
			if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BER_REPLACEMENT,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
				statuses = concat(statuses, 'Service completed');
			}
			return statuses;
		}
    },
	{
        csrStatus: 'Security deposit charging failed',
		csrlStatus: 'Security deposit charging failed',
		computeNextStatus: function() {
			let statuses = [];
			if((SERVICETYPE.ADVANCE_EXCHANGE === consumerServiceRequest.ServiceTypeID) ){
				statuses = concat(statuses,'Service completed');
			}
			return statuses;
		}
    },
    { 
		csrStatus: 'Replacement allocated',  
		csrlStatus: 'Plan transferred',
		computeNextStatus: function() {
			let statuses = [];
			if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BER_REPLACEMENT,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1){
				statuses = concat(statuses, 'Service completed');
			}
			return statuses;
		}
    },
	{ 
		csrStatus: 'BER replacement issued',  
		csrlStatus: 'Plan transferred',
		computeNextStatus: function() {
			let statuses = [];
			if(berChecks){
				if(((SERVICETYPE.BER_IDENTIFIED == consumerServiceRequest.ServiceTypeID) || (SERVICETYPE.BER_PAYMENT == consumerServiceRequest.ServiceTypeID) || (SERVICETYPE.BER_REPLACEMENT == consumerServiceRequest.ServiceTypeID)) && accessFn('CLAIM_REQUEST')){
					if(consumerServiceRequest.ConsumerServiceRequestDetails && consumerServiceRequest.ConsumerServiceRequestDetails.IsExternalForwardShipment){
						statuses = concat(statuses,'Service completed', 'BER device received', 'BER accessories validated','BER accessories rejected', 'Call attempted');
					}else{
						statuses = concat(statuses,'Service completed', 'BER device received', 'BER accessories validated','BER accessories rejected', 'BER shipment created', 'Add Shipment Details', 'Intimate customer', 'Call attempted');
					}
				}
				if(!isReplacementPrerequisitesApplied ) {
					setIsReplacementPrerequisitesApplied(true);
					if(consumerServiceRequest.ConsumerServiceRequestDetails &&  consumerServiceRequest.ConsumerServiceRequestDetails.IsExternalForwardShipment){
						statuses = pullAll(statuses,['BER replacement confirmed', 'BER replacement issued', 'BER shipment created', 'Add Shipment Details', 'Intimate customer', 'BER accessories received']);
					}else{
						statuses = pullAll(statuses,['BER replacement confirmed', 'BER replacement issued', 'BER accessories received']);
					}
				}
			}
			return statuses;
		}
    },
]
    return(
        <>
        {/* { showLoader && ReactDOM.createPortal(
			<Loader />,
			document.getElementById("loader-root")
		)} */}
			<ViewButtonFunctionality
				// showRefreshButton={showRefreshButton}
				{...props}
				getSource={getSource}
				showButton={showButton}
				//getNextSteps={getNextSteps}
				statusButtons={statusButtons}
				getRequestById={getRequestById}
				getDisplayText={getDisplayText}
				consumerDetails={consumerDetails}
				logisitcsServiceTypes={logisitcsServiceTypes}
				fetchLoanerDetailsByCsrId={fetchLoanerDetailsByCsrId}
			/>
        </>
    )
}
export default ViewButtonMatrix;