import React from "react";
import { Table } from "sfy-react";
import get from "lodash/get";

const PlanSalesTable = (props) => {
	const {
		columns,
		data,
		localString,
		tableClasses = {},
		publicUrl,
		emptyStateText,
		baseUrl,
		onClick,
		handleRowClick,
		entireRowClick,
	} = props;

	return (
		<>
			{data && data.length > 0 ? (
				<div className="container-fluid">
					<Table isResponsive className={tableClasses}>
						<Table.Header>
							<Table.Row>
								{columns &&
									columns.length > 0 &&
									columns.map((column, index) => {
										return (
											column.showHeading && (
												<Table.Heading key={index}>
													{column.title}
												</Table.Heading>
											)
										);
									})}
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{data &&
								data.length > 0 &&
								data.map((row, index) => {
									return (
										<Table.Row
											key={index}
											otherProps={
												entireRowClick && {
													onClick: (e) => {
														window.open(
															baseUrl +
																"/plansales/viewcontract?spid=" +
																row.SoldPlanID,
															"_blank"
														);
													},
												}
											}
										>
											{columns &&
												columns.length > 0 &&
												columns.map((col, ind) => {
													return (
														col.showHeading && (
															<Table.Data>
																{col.hasOwnProperty(
																	"customCell"
																)
																	? col.customCell(
																			row,
																			index
																	  )
																	: get(
																			row,
																			col.field,
																			"--"
																	  )}
															</Table.Data>
														)
													);
												})}
										</Table.Row>
									);
									// Todo : Add tableRow to handle actions
								})}
						</Table.Body>
					</Table>
				</div>
			) : (
				<div className="col-sm-12">
					<div className="text-center requests-empty-state">
						<div className="empty-state-text">
							<img
								style={{ marginBottom: "10px" }}
								src={publicUrl + "/imgs/Box.png"}
								width={"30px"}
							/>
							<br />
							{localString(emptyStateText)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default PlanSalesTable;
