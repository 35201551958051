import React, { useEffect, useState } from "react";
import { Button, Checkbox, Input } from "sfy-react";
import Swal from "sweetalert2";
import { displayError } from "../../../../Utility/Utils";

import "./InvestigationChecklist.scss";

const InvestigationChecklist = (props) => {
	const {
		apiCall,
		localString,
		setShowLoader,
		investigationChecklist,
		setInvestigationChecklist,
		getCaseChecklist,
		setShowInvestigationChecklist,
		showInvestigationChecklist,
	} = props;

	useEffect(() => {
		getCaseChecklist();
	}, []);

	const handleSaveRemark = (checklist, index) => {
		let data = {
			caseChecklistID: checklist.caseChecklistID,
			remarks: checklist.remarks.trim(),
		};

		setShowLoader(true);
		apiCall({
			url: "updateCaseChecklist",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					getCaseChecklist();
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const handleStateChange = (name, index, val) => {
		setInvestigationChecklist((prev) => {
			let newData = [...prev];

			if (name === "isChecked") {
				if (val) {
					newData[index][name] = 1;
				} else {
					newData[index][name] = 0;
				}
			} else {
				newData[index][name] = val;
			}
			return newData;
		});
	};

	return (
		<div
			className="investigation-checklist-sidebar"
			style={{
				width: showInvestigationChecklist ? "270px" : "0px",
			}}
		>
			<div className="ic-header">
				<div className="ic-title">{localString("Investigation Checklist")}</div>
				<div
					className="close-icon"
					onClick={() => setShowInvestigationChecklist(false)}
				>
					<span className="glyphicon glyphicon-remove"></span>
				</div>
			</div>
			<div className="ic-body-wrapper">
				{investigationChecklist && investigationChecklist.length > 0 ? (
					investigationChecklist.map((checklist, index) => (
						<div
							key={checklist.caseChecklistID + index}
							className="ic-container"
						>
							<Checkbox
								value={!!checklist.isChecked}
								id={"checklist-checkbox-" + index + 1}
								name={"checklist-checkbox-" + index + 1}
								isDisabled={!!checklist.status}
								handleOnChange={(e) => {
									handleStateChange(
										"isChecked",
										index,
										e.target.checked
									);
								}}
							>
								{checklist.name}
								{checklist.isMandatory ? (
									<span style={{ color: "red" }}>*</span>
								) : (
									""
								)}
							</Checkbox>

							<div>{localString(checklist.description)}</div>

							{!!checklist.isChecked && (
								<>
									<Input
										id={"checklist-remark-" + index + 1}
										name={"checklist-remark-" + index + 1}
										placeholder={localString("Add your remarks here")}
										label={
											!!checklist.status
												? localString("Remark")
												: localString("Add Remark")
										}
										className={{
											input: "customised-border",
										}}
										inputComponent="textarea"
										isDisabled={!!checklist.status}
										value={checklist.remarks || ""}
										otherProps={{
											rows: "5",
										}}
										handleOnChange={(e) => {
											handleStateChange(
												"remarks",
												index,
												e.target.value
											);
										}}
									/>

									{!!!checklist.status && (
										<div className="ic-btn-wrapper">
											<Button
												classes="button-ghost button-small ic-custom-btn"
												isDisabled={
													!checklist.remarks ||
													checklist.remarks.trim()
														.length < 3
												}
												handleOnClick={(e) =>
													handleSaveRemark(
														checklist,
														index
													)
												}
											>
												{localString("Save")}
											</Button>
										</div>
									)}
								</>
							)}
						</div>
					))
				) : (
					<div className="text-center">
						<div className="text-center">
							{localString("No Checklist Available")}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default InvestigationChecklist;
