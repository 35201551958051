import React, { useState, useRef } from "react";
import { Input, Button } from "sfy-react";
import Swal from "sweetalert2";
import axios from "axios";

import { generateUUID } from "../../../Utility/Utils";

import DocPreviewModal from "./UploadedFilePreviewModal/UploadedFilePreviewModal";

const UploadActionButton = (props) => {
	const {
		doc,
		title,
		index,
		localString,
		consumerServiceRequest,
		consumerServiceRequestProofObject,
		setSelectedDocumentGroupObject,
		addConsumerServiceRequestRecords,
		setShowLoader,
		docServiceUrl,
		authorization,
	} = props;

	const docRef = useRef(null);
	const [pendingFile, setPendingFile] = useState(null);
	const [showFilePreviewModal, setShowFilePreviewModal] = useState(false);

	const pendingDocClick = () => {
		docRef.current.click();
	};

	const handleChangeFilePreview = (event) => {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			setPendingFile(file);
			setSelectedDocumentGroupObject(doc);
			setShowFilePreviewModal(true);
		} else {
			closeDocPreviewModal();
		}
	};

	const closeDocPreviewModal = () => {
		setPendingFile(null);
		setSelectedDocumentGroupObject({});
		setShowFilePreviewModal(false);
	};

	const handleClickFileUpload = (e) => {
		e.preventDefault();

		if (pendingFile) {
			let fileType = pendingFile.type;
			let ext = fileType.split("/").length
				? "." + fileType.split("/")[fileType.split("/").length - 1]
				: "";

			let key = "FEWEBA" + generateUUID() + ext;

			let dataNew = new FormData();
			dataNew.append("file", pendingFile);
			dataNew.append("docType", "ConsumerServiceRequestDocument");
			dataNew.append("clientRefId", key);
			dataNew.append("entity-type", "ConsumerServiceRequestID");
			dataNew.append(
				"entity-id",
				consumerServiceRequest.ConsumerServiceRequestID
			);

			if (docServiceUrl == "") {
				return;
			}

			setShowLoader(true);
			axios
				.post(docServiceUrl + "internal/document/upload", dataNew, {
					headers: {
						App: "WebApp",
						module: "WebApp",
						authorization: authorization,
					},
				})
				.then((result) => {
					setShowLoader(false);
					let uploadURL = result.data.data.uploadURL;
					let fileNameUUID = result.data.data.docServiceUUID;

					addConsumerServiceRequestRecords(
						consumerServiceRequestProofObject.consumerServiceRequestProofID,
						[
							{
								filePath: uploadURL,
								fileName: fileNameUUID,
							},
						],
						closeDocPreviewModal
					);
				})
				.catch((error) => {
					setShowLoader(false);
					console.log("error: " + error);
				});
		} else {
			Swal.fire({
				title: "",
				icon: "error",
				text: localString("Please select a document"),
			});
			return;
		}
	};

	//const isSomeProofUploaded =
	//	doc.consumerServiceRequestProof &&
	//	doc.consumerServiceRequestProof.length > 0 &&
	//	doc.consumerServiceRequestProof.some(
	//		(csrProof) => csrProof.consumerServiceRequestProofRecords.length > 0
	//	);

	return (
		<>
			<div
				className="rp-row"
				style={{
					marginLeft: "20px",
					...(index ===
						doc.consumerServiceRequestProof.length - 1 && {
						marginBottom: "20px",
					}),
					...(index === 0 && { marginTop: "20px" }),
				}}
			>
				<div className="rp-row">
					<div className="rp-label" style={{ minWidth: "350px" }}>
						{localString(title)}
						<span
							style={{
								color:
									doc.status === "rejected"
										? "red"
										: doc.status === "accepted"
										? "green"
										: "gray",
							}}
						>
							{` (${localString(
								doc.status.charAt(0).toUpperCase() +
									doc.status.slice(1)
							)})`}
						</span>
					</div>
					<div>
						{doc.status === "pending" &&
							doc.proofType === "document" && (
								<div className="d-flex">
									<Button
										classes="button-ghost button-small rp-btn rp-btn-width"
										handleOnClick={pendingDocClick}
									>
										{consumerServiceRequestProofObject &&
										consumerServiceRequestProofObject
											.consumerServiceRequestProofRecords
											.length > 0
											? localString("Re-Upload")
											: localString("Upload")}
									</Button>
									<Input
										className={{
											inputContainer:
												"file-input-display",
										}}
										reference={docRef}
										inputType="file"
										id="invoiceFile"
										handleOnChange={handleChangeFilePreview}
										otherProps={
											{
												//accept:
												//	pendingDocumentExtension == "jpg"
												//		? ".png,.jpg,.jpeg,.TIFF,.RAW,.HEIC"
												//		: ".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv,.aac,.m4a,.mp4,.ogg,.ogx,.3gp",
											}
										}
									/>
								</div>
							)}
					</div>
				</div>
			</div>

			{showFilePreviewModal && (
				<DocPreviewModal
					{...props}
					pendingFile={pendingFile}
					showModal={showFilePreviewModal}
					closeModal={closeDocPreviewModal}
					consumerServiceRequestProofObject={
						consumerServiceRequestProofObject
					}
					handleClickFileUpload={handleClickFileUpload}
				/>
			)}
		</>
	);
};

export default UploadActionButton;
