import React from 'react';
import SidebarElement from '../../components/SidebarElement/SidebarElement';
import { BRAND } from '../../Utility/constants';
import { getCookie } from "../../Utility/Utils";
import './Sidebar.scss'

const Sidebar = (props) => {
	const showRoutes = props.showRoutes ? JSON.parse(props.showRoutes) : {};
	const accessConfigs = props.qFilter ? JSON.parse(props.qFilter) : {};
	const accessFn = props.accessFn;
	const localString = props.localString;
	const displayHomeTabInBottom = accessConfigs && (((accessConfigs?.brands?.length == 1) && (accessConfigs?.brands?.indexOf(BRAND.ONEPLUS) > -1)) || (accessConfigs && accessConfigs.pageThemeId == 3));

	return (
		<div className="sidebar">
			{accessConfigs &&
				accessConfigs.brands &&
				accessConfigs.brands.length == 1 &&
				(accessConfigs.brands.indexOf(BRAND.ONEPLUS) > -1) && (
					<SidebarElement
						redirectUrl={props.baseUrl + "/" + props.redirectUrl}
						routeText=""
						showSelected={
							props.routeSelection == "home" ? " selected" : ""
						}
						divClass="sidebar-oneplus-logo-div sidebar-div sidebar-size"
						logoClass="sidebar-oneplus-logo sidebar-logo"
						secondaryLogoClass="sidebar-oneplus-main-logo"
						showSecondaryLogo={true}
					/>
				)}
			{accessConfigs && accessConfigs.pageThemeId == 3 && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/" + props.redirectUrl}
					routeText=""
					showSelected={
						props.routeSelection == "home" ? " selected" : ""
					}
					divClass="sidebar-pupa-logo-div sidebar-div sidebar-size"
					logoClass="sidebar-pupa-logo sidebar-logo"
					secondaryLogoClass="sidebar-pupa-main-logo"
					showSecondaryLogo={true}
				/>
			)}
			{showRoutes["home"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/" + props.redirectUrl}
					routeText=""
					showSelected={
						props.routeSelection == "home" ? " selectedhome" : ""
					}
					divClass={`${displayHomeTabInBottom ? 'sidebar-logo-div ' : ''} sidebar-div sidebar-size`}
					logoClass="sidebar-logo"
					secondaryLogoClass="sidebar-main-logo sidebar-logo-height"
					showSecondaryLogo={true}
					showPoweredBy={displayHomeTabInBottom}
				/>
			)}
			{showRoutes["consumers"] &&
				!(accessFn("WORKSHOP_FLOW") &&
				accessFn("CONSUMER_ADDRESS_DETAILS")) && (
					<SidebarElement
						redirectUrl={props.baseUrl + "/consumers"}
						showSelected={
							props.routeSelection == "consumers"
								? " selected"
								: ""
						}
						divClass="sidebar-consumers-div"
						logoClass="sidebar-consumers"
						showText={true}
						routeText={localString(showRoutes["consumers"])}
					/>
				)}
			{showRoutes["rulemanager"] && accessFn('RULE_MANAGER') && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/rulemanager"}
					showSelected={
						props.routeSelection == "rulemanager"
							? " selected"
							: ""
						}
					divClass="sidebar-consumers-div"
					logoClass="sidebar-consumers"
					showText={true}
					routeText={localString(showRoutes["rulemanager"])}
				/>
			)}
			{showRoutes["casemanager"] && accessFn('CASE_MANAGER') && (
				<SidebarElement	
						redirectUrl={props.baseUrl + "/casemanager"}
							showSelected={
								props.routeSelection == "casemanager"
									? " selected"
									: ""
							}
							divClass="sidebar-consumers-div"
							logoClass="sidebar-consumers"
							showText={true}
							routeText={localString(showRoutes["casemanager"])}
				/>
			)}
			{showRoutes["diagnosis"] &&
				(!accessFn("WORKSHOP_FLOW") ||
					(accessFn("WORKSHOP_FLOW") &&
						accessFn("CONSUMER_ADDRESS_DETAILS"))) && (
					<SidebarElement
						redirectUrl={props.baseUrl + "/diagnosis"}
						showSelected={
							props.routeSelection == "diagnosis"
								? " selected"
								: ""
						}
						divClass="sidebar-diagnosis-div"
						logoClass="sidebar-diagnosis"
						showText={true}
						routeText={localString(showRoutes["diagnosis"])}
					/>
				)}
			{showRoutes["notifications"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/notifications"}
					showSelected={
						props.routeSelection == "notifications"
							? " selected"
							: ""
					}
					divClass="sidebar-notifications-div"
					logoClass="sidebar-notifications"
					showText={true}
					routeText={localString(showRoutes["notifications"])}
				/>
			)}
			{showRoutes["coupons"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/coupons"}
					showSelected={
						props.routeSelection == "coupons" ? " selected" : ""
					}
					divClass="sidebar-coupons-div"
					logoClass="sidebar-coupon-height sidebar-coupons"
					showText={true}
					routeText={localString(showRoutes["coupons"])}
				/>
			)}
			{showRoutes["plans"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/plans/consumer/view"}
					showSelected={
						props.routeSelection == "plans" ? " selected" : ""
					}
					divClass="sidebar-plans-div"
					logoClass="sidebar-plans"
					showText={true}
					routeText={localString(showRoutes["plans"])}
				/>
			)}
			{showRoutes["salePlans"] && (
				<SidebarElement
			    	// redirectUrl={props.baseUrl + "/plansales"}
					redirectUrl={props.baseUrl + "/plansales/home"}
					showSelected={
						props.routeSelection == "salePlans" ? " selected" : ""
					}
					divClass="sidebar-saleplans-div"
					logoClass="sidebar-saleplans"
					showText={true}
					routeText={localString(showRoutes["salePlans"])}
				/>
			)}
			
			{/* {showRoutes["salePlansReport"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/sales/brandreport"}
					showSelected={
						props.routeSelection == "salePlansReport"
							? " selected"
							: ""
					}
					divClass="sidebar-saleplansreport-div"
					logoClass="sidebar-saleplansreport"
					showText={true}
					routeText={localString(showRoutes["salePlansReport"])}
				/>
			)} */}
			{showRoutes["mop"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/mop"}
					showSelected={
						props.routeSelection == "mop" ? " selected" : ""
					}
					divClass="sidebar-report-div"
					logoClass="sidebar-report"
					showText={true}
					routeText={localString(showRoutes["mop"])}
				/>
			)}
			{showRoutes["requests"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/servicerequests"}
					showSelected={
						props.routeSelection == "consumerServiceRequest" ? " selected" : ""
					}
					divClass="sidebar-requests-div"
					logoClass="sidebar-requests"
					showText={true}
					routeText={localString(showRoutes["requests"])}
				/>
			)}
			{showRoutes["optinrequest"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/optinrequests"}
					showSelected={
						props.routeSelection == "optinRequest"
							? " selected"
							: ""
					}
					divClass="sidebar-optinrequests-div"
					logoClass="sidebar-optinrequests"
					showText={true}
					routeText={localString(showRoutes["optinrequest"])}
				/>
			)}
			{showRoutes["userlist"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/userlist"}
					showSelected={
						props.routeSelection == "userlist" ? " selected" : ""
					}
					divClass="sidebar-userlist-div"
					logoClass="sidebar-userlist"
					showText={true}
					routeText={localString(showRoutes["userlist"])}
				/>
			)}
			{showRoutes["logistics"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/logistics"}
					showSelected={
						props.routeSelection == "logistics" ? " selected" : ""
					}
					divClass="sidebar-logistics-div"
					logoClass="sidebar-logistics"
					showText={true}
					routeText={localString(showRoutes["logistics"])}
				/>
			)}
			{showRoutes["retailLocations"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/retailLocations"}
					showSelected={
						props.routeSelection == "retailLocations"
							? " selected"
							: ""
					}
					divClass="sidebar-location-div"
					logoClass="sidebar-locations"
					showText={true}
					routeText={localString(showRoutes["retailLocations"])}
				/>
			)}
			{showRoutes["supervisor"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/teamlead"}
					showSelected={
						props.routeSelection == "supervisor" ? " selected" : ""
					}
					divClass="sidebar-supervisor-div"
					logoClass="sidebar-supervisors"
					showText={true}
					routeText={localString(showRoutes["supervisor"])}
				/>
			)}
			{showRoutes["salesman"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/salesman"}
					showSelected={
						props.routeSelection == "salesman" ? " selected" : ""
					}
					divClass="sidebar-salesman-div"
					logoClass="sidebar-salesman"
					showText={true}
					routeText={localString(showRoutes["salesman"])}
				/>
			)}
			{showRoutes["target"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/target"}
					showSelected={
						props.routeSelection == "target" ? " selected" : ""
					}
					divClass="sidebar-target-div"
					logoClass="sidebar-targets"
					showText={true}
					routeText={localString(showRoutes["target"])}
				/>
			)}
			{showRoutes["announcements"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/announcements"}
					showSelected={
						props.routeSelection == "announcements"
							? " selected"
							: ""
					}
					divClass="sidebar-announcement-div"
					logoClass="sidebar-announcement"
					showText={true}
					routeText={localString(showRoutes["announcements"])}
				/>
			)}
			{showRoutes["approvals"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/approvals"}
					showSelected={
						props.routeSelection == "approvals" ? " selected" : ""
					}
					divClass="sidebar-approvals-div"
					logoClass="sidebar-approvals"
					showText={true}
					routeText={localString(showRoutes["approvals"])}
				/>
			)}
			{showRoutes["partnersetting"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/partnersetting"}
					showSelected={
						props.routeSelection == "partnersetting"
							? " selected"
							: ""
					}
					divClass="sidebar-partner-div"
					logoClass="sidebar-partners"
					showText={true}
					routeText={localString(showRoutes["partnersetting"])}
				/>
			)}
			{showRoutes["retailerRequest"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/retailerrequest"}
					showSelected={
						props.routeSelection == "retailerrequest"
							? " selected"
							: ""
					}
					divClass="sidebar-requests-div"
					logoClass="sidebar-requests"
					showText={true}
					routeText={localString(showRoutes["retailerRequest"])}
				/>
			)}
			{showRoutes["inventory"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/inventory"}
					showSelected={
						props.routeSelection == "inventory" ? " selected" : ""
					}
					divClass="sidebar-requests-div"
					logoClass="sidebar-requests"
					showText={true}
					routeText={localString(showRoutes["inventory"])}
				/>
			)}
			{showRoutes["retailerReset"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/retailerReset"}
					showSelected={
						props.routeSelection == "retailerReset"
							? " selected"
							: ""
					}
					divClass="sidebar-reset-div"
					logoClass="sidebar-reset"
					showText={true}
					routeText={localString(showRoutes["retailerReset"])}
				/>
			)}
			{showRoutes["cataloguemanagement"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/cataloguemanagement"}
					showSelected={
						props.routeSelection == "cataloguemanagement"
							? " selected"
							: ""
					}
					divClass="sidebar-catalogue-div"
					logoClass="sidebar-catalogue"
					showText={true}
					routeText={localString(showRoutes["cataloguemanagement"])}
				/>
			)}
			{showRoutes["retailerReports"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/retailerReports"}
					showSelected={
						props.routeSelection == "retailerReports"
							? " selected"
							: ""
					}
					divClass="sidebar-report-div"
					logoClass="sidebar-report"
					showText={true}
					routeText={localString(showRoutes["retailerReports"])}
				/>
			)}
			{showRoutes["engineerLoginReport"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/engineer/report"}
					showSelected={
						props.routeSelection == "engineerLoginReport"
							? " selected"
							: ""
					}
					divClass="sidebar-report-div"
					logoClass="sidebar-report"
					showText={true}
					routeText={localString(showRoutes["engineerLoginReport"])}
				/>
			)}
			{showRoutes["servicelocationVisitReport"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/servicelocation/report"}
					showSelected={
						props.routeSelection == "servicelocationVisitReport"
							? " selected"
							: ""
					}
					divClass="sidebar-report-div"
					logoClass="sidebar-report"
					showText={true}
					routeText={localString(
						showRoutes["servicelocationVisitReport"]
					)}
				/>
			)}
			{showRoutes["mapping"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/mapping"}
					showSelected={
						props.routeSelection == "mapping" ? " selected" : ""
					}
					divClass="sidebar-content-div"
					logoClass="sidebar-content"
					showText={true}
					routeText={localString(showRoutes["mapping"])}
				/>
			)}
			{showRoutes["engineerReset"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/engineer"}
					showSelected={
						props.routeSelection == "engineerReset"
							? " selected"
							: ""
					}
					divClass="sidebar-report-div"
					logoClass="sidebar-reset"
					showText={true}
					routeText={localString(showRoutes["engineerReset"])}
				/>
			)}
			{showRoutes["engineerTracking"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/engineerTracking"}
					showSelected={
						props.routeSelection == "engineerTracking"
							? " selected"
							: ""
					}
					divClass="sidebar-report-div"
					logoClass="sidebar-report"
					showText={true}
					routeText={localString(showRoutes["engineerTracking"])}
				/>
			)}
			{showRoutes["engineerNotifications"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/engineerNotifications"}
					showSelected={
						props.routeSelection == "engineerNotifications"
							? " selected"
							: ""
					}
					divClass="sidebar-report-div"
					logoClass="sidebar-report"
					showText={true}
					routeText={localString(showRoutes["engineerNotifications"])}
				/>
			)}
			{showRoutes["billing"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/billing"}
					showSelected={
						props.routeSelection == "billing" ? " selected" : ""
					}
					divClass="sidebar-report-div"
					logoClass="sidebar-report"
					showText={true}
					routeText={localString(showRoutes["billing"])}
				/>
			)}
			{showRoutes["downloadReports"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/reports"}
					showSelected={
						props.routeSelection == "reports"
							? " selected"
							: ""
					}
					divClass="sidebar-reports-div"
					logoClass="sidebar-report"
					showText={true}
					routeText={localString(showRoutes["downloadReports"])}
				/>
			)}
			{showRoutes["advancePay"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/advancePay"}
					showSelected={
						props.routeSelection == "advancePay"
							? " selected"
							: ""
					}
					divClass="sidebar-advancepay-div"
					logoClass="sidebar-advancepay"
					showText={true}
					routeText={localString(showRoutes["advancePay"])}
				/>
			)}
			{showRoutes["users"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/users"}
					showSelected={
						props.routeSelection == "users" ? " selected" : ""
					}
					divClass="sidebar-userlist-div"
					logoClass="sidebar-userlist"
					showText={true}
					routeText={localString(showRoutes["users"])}
				/>
			)}
			{showRoutes["paytm"] && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/paytm"}
					showSelected={
						props.routeSelection == "paytm" ? " selected" : ""
					}
					divClass="sidebar-paytm-div"
					logoClass="sidebar-paytm"
					showText={true}
					routeText={localString(showRoutes["paytm"])}
				/>
			)}
			{showRoutes["bulkUpload"]  && (
				<SidebarElement
					redirectUrl={props.baseUrl + "/servicerequests/bulkUpload"}
					showSelected={
						props.routeSelection == "bulkUpload" ? " selected" : ""
					}
					divClass="sidebar-bulkupload-div"
					logoClass="sidebar-bulkupload"
					showText={true}
					routeText={localString(showRoutes["bulkUpload"])}
				/>
			)}
			{accessFn("SHOW_PARTS_LINK") && (
				<SidebarElement
					redirectUrl={
						props.baseUrl + "/externalredirect?redirect=parts"
					}
					showSelected=""
					divClass="sidebar-requests-div"
					logoClass="sidebar-requests"
					showText={true}
					routeText={localString("Parts Portal")}
				/>
			)}
			{accessFn("SHOW_ONBOARDING_LINK") && (
				<SidebarElement
					redirectUrl={
						props.baseUrl + "/externalredirect?redirect=onboarding"
					}
					showSelected=""
					divClass="sidebar-requests-div"
					logoClass="sidebar-requests"
					showText={true}
					routeText={localString("Partner Onboarding")}
				/>
			)}
			{(accessFn("SHOW_UNIFIED_PORTAL_LINK") && props.callBackUrl) && (
				<SidebarElement
					// redirectUrl={getCookie("callbackurl")}
					redirectUrl={props.callBackUrl}
					showSelected=""
					divClass="sidebar-redirect-div"
					logoClass="sidebar-redirect"
					iframeredirect={true}
					imageName={'redirectIcon.svg'}
					showText={true}
					routeText={localString("Unified Portal")}
					publicUrl={props.publicUrl}
				/>
			)}
			<a
				href={props.baseUrl + "/logout?cb=" + Math.random(13)}
				class="sidebar-logout-div"
			>
				<p>
					<div class="sidebar-icon sidebar-logout"></div>
					<div class="sidebar-label sidebar-logout-label">
						<div class="sidebar-logout-label-child">
							{localString("Logout")}
						</div>
						<div class="sidebar-logout-label-child font-light">
							{getCookie("username")}
						</div>
					</div>
				</p>
			</a>
		</div>
	);
}

export default Sidebar;
