import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Loader } from "sfy-react";
import Swal from "sweetalert2";
import { displayError, returnLocalDateTime } from "../../../Utility/Utils";
import PageHeader from "../../../components/PageHeader/PageHeader";
import PageContentWrapper from "../../../components/PageContentWrapper/PageContentWrapper";
import ActionButtonWrapper from "../../../components/ActionButtonWrapper/ActionButtonWrapper";
import MapRequestModal from "../../../components/ConfirmationModal/ConfirmationModal";
import RightSidebar from "../../../Partials/RightSidebar/RightSidebar";
import LogisticsDetails from "./LogisticsDetails";
import PackageDetails from "./PackageDetails";
import LogisticsImages from "./LogisticsImages";
import "./ViewLogisticsDetailsPage.scss";

const ViewLogisticsDetailsPage = (props) => {
	const { localString, apiCall, usequery, titleHeader, accessFn } = props;

	let queryParams = usequery();
	let LogisticsRequestID = queryParams.get("id");
	const [logisticsDetails, setLogisticsDetails] = useState();
	const [logisticsImages, setLogisticsImages] = useState();
	const [logisticsPackage, setLogisticsPackage] = useState();
	const [logs, setLogs] = useState();
	const [showLoader, setLoader] = useState(false);
	const [showMapModal, setShowMapModal] = useState(false);
	const [actionButtons, setActionButtons] = useState([]);
	const [modalData, setModalData] = useState([]);
	const [isValidReferenceID, setIsValidReferenceID] = useState([]);
	const [previousReferenceID, setPreviousReferenceID] = useState([]);

	const getLogisticsDetails = () => {
		const data = {
			logisticsRequestID: LogisticsRequestID,
		};
		setLoader(true);
		apiCall({
			url: "getLogisticsRequestDetail",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					setLogisticsDetails(result.data);
					setLogisticsImages(result.data.logisticsImage);
					setLogisticsPackage(result.data.LogisticsPackage);
					// Add buttons logic
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getLogisticsLogs = () => {
		const data = {
			LogisticsRequestID: LogisticsRequestID,
		};
		setLoader(true);
		apiCall({
			url: "getLogisticsStatusList",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					setLogs(result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const handleOnReferenceInput = (value) => {
		setModalData((prevModalData) => {
			return { ...prevModalData, inputValue: value };
		});
	}

	const validateReferenceID = () => {
		let value = modalData.inputValue;
		if (/^[\w\-\s]+$/.test(value)) {
			setPreviousReferenceID(value);
			setIsValidReferenceID(true);
		} else if (value.length == 0) {
			setPreviousReferenceID(value);
			setIsValidReferenceID(false);
		} else {
			setModalData((prevModalData) => { return { ...prevModalData, inputValue: previousReferenceID };});
			setIsValidReferenceID(false);
		}
	}

	const preCancellationConfirmation = () => {
		Swal.fire({
			title: localString(
				"Are you sure?\nPlease specify remarks"
			),
			input: "text",
			showCancelButton: true,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("Ok"),
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.close();
				let remark = result.value || "";
				if (remark) {
					cancelRequest(remark);
				} else {
					Swal.fire({
						title: "",
						text: localString("Please enter the remarks"),
						icon: "error",
					}).then((result) => {
						if (result.isConfirmed) {
							preCancellationConfirmation();
						}
					});
				}
			}
		});
	}

	const openMapRequestModal = () => {
		setModalData({
			heading: "Map Logistics Request",
			inputValue: "",
			labelText: "Please enter the Reference ID of the service request",
			submitButtonText: "Map Logistics Request",
			oninputFunc: handleOnReferenceInput,
			hideClearButton: true
		});
		setShowMapModal(true);
	}

	const cancelRequest = (remark) => {
		let data = {
			LogisticsRequestID: logisticsDetails.LogisticsRequestID,
			VendorID: logisticsDetails.VendorID,
			Remarks: remark || undefined,
		};
		setLoader(true);
		apiCall({
			url: "cancelShipment",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					setLogs(result.data);
					getLogisticsDetails();
					Swal.fire({
						icon: "success",
						text: localString(displayError(result)),
					});
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	}
	const mapRequest = () => {
		let data = {
			LogisticsRequestID: logisticsDetails.LogisticsRequestID,
			WaybillNumber: logisticsDetails.WaybillNumber,
			ReferenceID: modalData && modalData.inputValue,
		};
		setLoader(true);
		apiCall({
			url: "mapRequestToCSR",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					setShowMapModal(false);
					getLogisticsDetails();
					Swal.fire({
						icon: "success",
						text: localString(displayError(result)),
					});
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const handleButtonClick = (e, btnName) => {
		if (btnName) {
			switch (btnName) {
				case "mapRequest":
					openMapRequestModal();
					break;
				case "cancelRequest":
					preCancellationConfirmation();
					break;
				default:
					break;
			}
		}
	}

	const computeActionButtons = () => {
		let actionButtons = [];
		let tempButton = {};
		if (
			logisticsDetails && logisticsDetails.showConvergeLogistics &&
			accessFn("CONVERGE_LOGISTICS_REQUEST")
		) {
			tempButton.displayText = "Map logistics request";
			tempButton.type = "button";
			tempButton.name = "mapRequest";
			tempButton.onClick = handleButtonClick;
			actionButtons.push(tempButton);
			tempButton = {};
		}
		if (logisticsPackage && logisticsPackage.invoiceUrl) {
			tempButton.text = "Download Delivery Challan";
			tempButton.type = "link";
			tempButton.onClick = logisticsPackage.invoiceUrl;
			actionButtons.push(tempButton);
			tempButton = {};
		}
		if (logisticsPackage && logisticsPackage.packingSlipUrl) {
			tempButton.text = "Download Logistics Label";
			tempButton.type = "link";
			tempButton.onClick = logisticsPackage.packingSlipUrl;
			actionButtons.push(tempButton);
			tempButton = {};
		}
		if (
			logisticsDetails &&
			logisticsDetails.Active &&
			logisticsDetails.Client.toLowerCase() == "webapp-logistics" &&
			accessFn("ORGANIZATION_CANCEL_REQUEST")
		) {
			tempButton.displayText = "Cancel request";
			tempButton.type = "button";
			tempButton.name = "cancelRequest";
			tempButton.onClick = handleButtonClick;
			actionButtons.push(tempButton);
			tempButton = {};
		}
		setActionButtons(actionButtons);
	}

	const closeMapModal = () => {
		setShowMapModal(false);
	};

	useEffect(() => {
		getLogisticsDetails();
		getLogisticsLogs();
	}, []);

	useEffect(() => {
		computeActionButtons();
	}, [logisticsDetails, logisticsPackage]);

	useEffect(() => {
		validateReferenceID();
	}, [modalData]);

	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}
			<PageHeader titleHeader={titleHeader}></PageHeader>
			<ActionButtonWrapper
				{...props}
				actionButtons={actionButtons}
			></ActionButtonWrapper>
			<PageContentWrapper title={localString("Request Details")}>
				<LogisticsDetails
					{...props}
					logisticsDetails={logisticsDetails}
				></LogisticsDetails>
			</PageContentWrapper>
			<PageContentWrapper title={localString("Package Details")}>
				<PackageDetails
					{...props}
					logisticsPackage={logisticsPackage}
				></PackageDetails>
			</PageContentWrapper>
			{(logisticsImages && logisticsImages.length > -0) && <PageContentWrapper title={localString("Logistics Images")}>
				<LogisticsImages
					{...props}
					logisticsImages={logisticsImages}
				></LogisticsImages>
			</PageContentWrapper>}
			<RightSidebar {...props} logs={logs} title={"Request History"}></RightSidebar>
			<MapRequestModal
				{...props}
				submitFunction={mapRequest}
				modalData={modalData}
				showModal={showMapModal}
				closeModal={closeMapModal}
				disableApplyBtn={!isValidReferenceID}
			></MapRequestModal>
		</>
	);
};

export default ViewLogisticsDetailsPage;
