import React from "react";
import "./CustomerDetails.scss";
import { Button } from "sfy-react";

const CustomerDetails = (props) => {
	const { localString, paymentData, countryData, baseUrl } = props;

	return (
		<div className="inner-padding">
			<div
				className="row main-row"
				style={{ padding: "12px", margin: "auto" }}
			>
				<div className="col-sm-12 customer-section-main">
					<p className="text-capitalize customer-details-label">
						{localString("Customer Details")}
					</p>
				</div>
				<hr />
				<div class="col-sm-2">
					<p className="text-capitalize customer-details-label">
						{localString("Name")}
					</p>
					<p className="text-dark">
						{paymentData?.PlanDetails?.[0]?.FirstName || "--"}
					</p>
				</div>
				<div class="col-sm-3">
					<p className="text-capitalize customer-details-label">
						{localString("Mobile Number")}
					</p>
					<p className="text-dark">
						{paymentData?.PlanDetails?.[0]?.MobileNo || "--"}
					</p>
				</div>
				<div class="col-sm-3">
					<p className="text-capitalize customer-details-label">
						{localString("Email ID")}
					</p>
					<p className="text-dark">
						{paymentData?.PlanDetails?.[0]?.EmailID || "--"}
					</p>
				</div>
				<div class="col-sm-2">
					<p className="text-capitalize customer-details-label">
						{localString("Residential State")}
					</p>
					<p className="text-dark">
						{paymentData?.PlanDetails?.[0]?.State || "--"}
					</p>
				</div>
				<div class="col-sm-2">
					<p className="text-capitalize customer-details-label">
						{localString("Residential Country")}
					</p>
					<p className="text-dark">
						{countryData?.CountryName || "--"}
					</p>
				</div>
			</div>
		</div>
	);
};

export default CustomerDetails;
