import React from 'react';
import {Button,Input} from 'sfy-react'
const SelectOutcomeUI=(props)=>{
    const {localString,labelInputs,selectOutcomeModes,optionModeClick,selectedOption, currencyCode, setManualModesForAdjudication,closeOptionForm, onCostInput,mandatoryCost,disableOptionSave} = props;
	return (
		<>
			<div class="outcome-instrutions">
				{
					labelInputs && labelInputs.map(item=>{
						return <label>{item.labelText}</label>
					})
				}
			</div>
			<div className="outcome-option-wrapper clearfix">
				{
					selectOutcomeModes && selectOutcomeModes.map(option=>{
						return <div className={selectedOption && selectedOption.ModeID == option.ModeID ? ' item-active col-sm-4 outcome-option' : 'col-sm-4 outcome-option'}>
							<label className="option-label">{localString(option.ModeDescription)}</label>
							<Button className="btn button-servify-solid submit-btn outcome-btn"  handleOnClick={(e)=> optionModeClick(option)}>{localString(option.ModeName)}</Button>
						</div>
					})
				}
			</div>
			{
				selectedOption && selectedOption.IsDevicePriceMandatory && <div className='buyout-input-wrapper clearfix'>
					<div className='replacemement-cost'>
						<label class="option-label">{localString("Replacement Cost")}</label>
						{currencyCode
							&&
							<div className='currency-input'>
								<Input
									value={   currencyCode}
									className={{inputContainer:"decision-input currency-input"}}
									labelAlign='top'
									isDisabled
								/>
							</div>		
						}
						<Input
							value={mandatoryCost}
							className="form-control decision-input"
							labelAlign='top'
							otherProps={{onInput:(e)=>onCostInput(e)}}
						/>
					</div>
				</div>
			}
			<div class="form-group-filter-request text-right">
				<Button className="btn button-servify-solid submit-btn" handleOnClick = {()=> closeOptionForm()}>{localString("Cancel")}</Button>
				<Button className="btn button-servify-solid submit-btn save-submit" isDisabled={disableOptionSave} handleOnClick = {()=> setManualModesForAdjudication()}>{localString("Submit")}</Button>
			</div>
        </>
	)
}
export default  SelectOutcomeUI;

