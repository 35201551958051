import React,{useEffect, useState,useRef,useCallback } from 'react';
import {Modal,Dropdown,Input,DatePicker,Button} from 'sfy-react';
import Swal from 'sweetalert2';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import { exceptionTypeConfig,displayError,generateUUID,returnLocalDateTime,exceptionApproveFlagConfig } from '../../../../../Utility/Utils';
import './EscalationModal.scss';
import moment from 'moment';
import axios from 'axios';
import { find , debounce} from 'lodash';
const EscalationModal =(props)=>{
    const {apiCall,localString,showModal,closeModal,exception,enableEditable,consumerServiceRequest,useDocServiceEscalation,docServiceUrl,authorization,getRequestById,isRequestSFANeeded,requestSFA,setLoader} = props;
    // const [showLoader, setLoader] = useState(false);
    const [selectExceptionTypes,setSelectExceptionTypes] = useState([]);
    const [selectReason,setSelectReason] = useState([]);
    const [selectSKU,setSelectSKU]= useState([]);
    const [selectSkuData,setSelectSkuData] = useState();
    const [selectedException,setSelectedException] = useState({});
    const [showEditIcon,setShowEditIcon] = useState(false);
    const [isEditMode,setIsEditMode] = useState(false);
    const [exceptionLoading,setExceptionLoading] = useState(false);
    const [reasonLoading,setReasonLoading]= useState(false);
    const [skuLoading,setSkuLoading]=useState(false);
    const [isFileUploadValidated,setIsFileUploadValidated]= useState(false);
    const [isFileUploading,setIsFileUploading]=useState(false);
    const [showSKU,setShowSKU] = useState({});
    const [showInputs,setShowInputs] = useState({})
    const [documents,setDocuments] = useState([]);
    const [previousProductPurchaseCost,setPreviousProductPurchaseCost]=useState();
    const [productPurchaseCost,setProductPurchaseCost] = useState();
    const [consumerProduct,setConsumerProduct] = useState();
    const [addedRemarks,setAddedRemarks] = useState();
    const [selectedReason,setSelectedReason]=useState();
    const [selectedDateOfPurchase,setSelectedDateOfPurchase] = useState()
    const [showWarranty,setShowWarranty] = useState(false);
    const [isValidWarrantyUpdateDate,setIsValidWarrantyUpdateDate] = useState(false);
    const [isProductPurchaseCostValidated,setIsProductPurchaseCostValidated] = useState(true);
    const [isRefundAmountValidated,setIsRefundAmountValidated] = useState(false);
    const [fetchingDepriciationPrice,setFetchingDepriciationPrice]= useState(false)
    const [refundAmount,setRefundAmount] = useState()
    const [minRefundValue,setMinRefundValue]= useState()
    const [refundErrorMessage,setRefundErrorMessage]=useState()
    const [showInitiateException,setShowInitiateException] = useState(true);
    const [exceptionRaiseFlag,setExceptionRaiseFlag] = useState(false)
    const [exceptionDatePurchase,setExceptionDatePurchase] = useState('')
    const [raiseExceptions,setRaiseExceptions] = useState('')
    const [exceptionReason,setExceptionReason]= useState('')
    const [exceptionSku,setExceptionSku] = useState('')
    const [exceptionPurchaseCost,setExceptionPurchaseCost] = useState('')
    const [exceptionRefundAmount,setExceptionRefundAmount] = useState()
    const [exceptionRemarks,setExceptionRemarks] = useState('')
    const [exceptionWarrantyDate, setExceptionWarrantyDate]= useState('')
    const [skuPartID,setSkuPartID] = useState('');
    const [debounceTimeout, setDebounceTimeout] = useState(false);
    const [selectedWarrantyUpdateDate,setSelectedWarrantyUpdateDate] = useState()
    const qFilter =  JSON.parse(props.qFilter) ;

    useEffect(() => {
        getExceptionTypeReasons();
        getSKU();
        getProductDetails();
    }, [])
    useEffect(()=>{
        let showEditIcons = consumerServiceRequest.Status === 'Exception raised' && !isEditMode;
        setShowEditIcon(showEditIcons)
    },[])


    useEffect(()=>{
        if (consumerProduct && consumerProduct.DateOfPurchase && moment(consumerProduct.DateOfPurchase)) {
            let selectedDateOfPurchase = moment(consumerProduct.DateOfPurchase).format('YYYY-MM-DD');
            setIsFileUploadValidated(true)
            setSelectedDateOfPurchase(selectedDateOfPurchase)
            setExceptionDatePurchase(moment(selectedDateOfPurchase,'YYYY-MM-DD').format('DD-MM-YYYY'))
            // setEscalationData({...escalationData,['exceptionDatePurchase']:moment(consumerProduct.DateOfPurchase,'YYYY-MM-DD').format('DD-MM-YYYY')})
        }
        if(consumerProduct && consumerProduct.ProductPurchaseCost){
            setExceptionPurchaseCost(consumerProduct.ProductPurchaseCost)
        }
        if(!exception || (exception && exception.ExceptionTypeID !== exceptionTypeConfig.REFUND)){
            getDepreciationPriceDebounce();
        }
    },[consumerProduct])

    useEffect(()=>{
        if(isRequestSFANeeded){
            if(requestSFA && requestSFA.length > 0 ){
            setShowInitiateException(true)
            }else {
            setShowInitiateException(false)
            }
        }
    },[])


    useEffect(()=>{
        if(selectedException.ExceptionTypeID == exceptionTypeConfig.REPLACEMENT){
            if(raiseExceptions && exceptionReason && exceptionRemarks && documents.length >0 ){
                setExceptionRaiseFlag(true)
            }else {
                setExceptionRaiseFlag(false)
            }
        }else if(selectedException.ExceptionTypeID == exceptionTypeConfig.WARRANTY_UPDATE){
            if(raiseExceptions && exceptionWarrantyDate &&  exceptionDatePurchase &&
                exceptionReason && exceptionRemarks && documents.length>0){
                setExceptionRaiseFlag(true)
            }else{
                setExceptionRaiseFlag(false)
            }
        }else if(selectedException.ExceptionTypeID == exceptionTypeConfig.REFUND){
            if(raiseExceptions && exceptionPurchaseCost  && exceptionRefundAmount &&
            exceptionReason && exceptionRemarks  && exceptionDatePurchase  && documents.length>0){
                setExceptionRaiseFlag(true)
            }else{
                setExceptionRaiseFlag(false)
            }
        }
    },[selectedException,raiseExceptions,exceptionReason,exceptionRemarks,
        exceptionDatePurchase,documents,exceptionSku,exceptionWarrantyDate,
        exceptionRefundAmount,exceptionPurchaseCost])

    const handleProductPurhcaseCost = function (e) {
        if (e && e.target) {
            let previousProductPurchaseCost = '';
            let isProductPurchaseCostValidated = true;
            if (e.target.value == '') {
                previousProductPurchaseCost = e.target.value
                isProductPurchaseCostValidated = false
                setIsProductPurchaseCostValidated(false)
            }else if(e.target.value && e.target.value.length>6) {
                setProductPurchaseCost(previousProductPurchaseCost)
                setExceptionPurchaseCost(previousProductPurchaseCost)
            }else if (e.target.value && !isNaN(e.target.value)) {
                previousProductPurchaseCost = e.target.value;
                isProductPurchaseCostValidated = true;
                setIsProductPurchaseCostValidated(true)
                getDepreciationPriceDebounce();
            } else {
                setExceptionPurchaseCost(previousProductPurchaseCost)
            }
        }
        if (isProductPurchaseCostValidated) {
            setProductPurchaseCost(previousProductPurchaseCost)
        } else {
            setProductPurchaseCost('')
        }
       
    }


	const getExceptionTypeReasons = ()=> {
        setLoader(true);
        let exceptionTypeData
        let data = {
        }
        setExceptionLoading(true);
        setReasonLoading(true);
        apiCall({
            url: 'getExceptionTypeReasons',
            data: data,
            callBack: (result) => {
                setLoader(false); 
                if (result.success && result.data) {
                    setExceptionLoading(false);
                    setReasonLoading(false);
                    if (result.data.ExceptionTypes) {

                        // Note: For now filter out WARRANTY_UPDATE.(temporary)
                        exceptionTypeData = result.data.ExceptionTypes.filter((data) =>{
                            return data.ExceptionTypeID !== exceptionTypeConfig.WARRANTY_UPDATE;
                        })
                        let exceptionType = exceptionTypeData && exceptionTypeData.map(v=>({...v,value:v.ExceptionType}));
                        setSelectExceptionTypes(exceptionType) 
                    }
                    if (result.data.Reasons) {
                        let exceptionReason = result.data.Reasons
                        let exceptionReasons = exceptionReason && exceptionReason.map(v=>({...v,value:v.ReasonDescription}));
                        setSelectReason(exceptionReasons)
                    }
                    //Sets default ExceptionTypes and Reasons
                    if(exception) {
                        let selectedExceptions = exceptionTypeData.filter(function(data){
                            return data.ExceptionTypeID === exception.ExceptionTypeID;
                        })[0];
                        setSelectedException(selectedExceptions);
                    }
                } else {
                    if (result.msg) {
                        Swal.fire ('Sorry', localString(displayError(result)), 'error');
                    } else {
                        Swal.fire('Sorry', 'Something went wrong.', 'error');
                    }
                }
            },
        });
    }
    
    const getSKU = ()=> {
        setLoader(true)
        let data = {
            "ConsumerServiceRequestID":consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            "ConsumerProductID": consumerServiceRequest && consumerServiceRequest.ConsumerProductID
        };
        setSkuLoading(true)
        apiCall({
			url: "getPartReplacementMappings",
			data: data,
			callBack: (result) => {
                setLoader(false)
                setSkuLoading(false);
				if (result.success) {
                    let skuList = result.data; 
                    initSKU(skuList);
                    // setSkuListData(skuList[0])
                    // skuList && skuList.map(item=>setEscalationData({...escalationData,['exceptionSku']:item.Name}))
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
            errorCB: (err) => {
                setLoader(false);
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(err)),  
                });
            }
        });
    }

    const initSKU = function (skuList) {
        //set default SKU
        let selectedSKU;
        let defaultSKU;
        if(exception) {
            selectedSKU = skuList.filter(function(data) {
                return data.PartID === exception.Recommendation;
            })[0];

            if(selectedSKU){
                defaultSKU = selectedSKU;
            }
        }else {
            if (skuList && skuList.length > 0 && consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.FinishedGoodID) {
                defaultSKU = find(skuList, function (o) {
                    return consumerServiceRequest.consumerProduct.FinishedGoodID == o.PartID
                })
                if(defaultSKU){
                    selectedSKU = defaultSKU
                }
            }
        }
        if(defaultSKU) {
            setExceptionSku(defaultSKU.Name);
        }
        let skuData = skuList && skuList.length>0 && skuList.map(v=>{
            setExceptionSku(v.Name)
            return {...v,
                value:v.Name
            }
        });
        setSelectSKU(skuData)
    }

    const getProductDetails = function() {
        setLoader(true)
        if (consumerServiceRequest && consumerServiceRequest.ConsumerProductID) {
            // self.isProductDetailsCalled = true
            apiCall({
                url: "getProductDetailsForRegisteredProducts",
                data: {
                    'sendConsumerDetails': true,
                    ConsumerProductID: consumerServiceRequest.ConsumerProductID,
                    "skipDiagnosisData": true
                },
                callBack: (result) => {
                    setSkuLoading(false);
                    setLoader(false)
                    if (result.success) {
                        let previousProductPurchaseCostLocal
                        let consumerData= result.data; 
                        setConsumerProduct(consumerData)
                        // initDateOfPurchase(consumerData)
                        if(result.data){
                            previousProductPurchaseCostLocal = parseFloat(consumerData.ProductPurchaseCost);
                            setPreviousProductPurchaseCost(previousProductPurchaseCostLocal);
                            let productPurchaseCostLocal = parseFloat(consumerData.ProductPurchaseCost);
                            setProductPurchaseCost(productPurchaseCostLocal);
                        }

                        if(exception){
                                    //sets existing remarks
                            if(exception.Remarks){
                                setExceptionRemarks(exception.Remarks)
                                setAddedRemarks(exception.Remarks)
                            }
                            if(exception.Reasons){
                                setExceptionReason(exception.Reasons[0].ReasonDescription)
                                setSelectReason(exception.Reasons[0])
                            }
                            setDocuments(exception.documents)
                            let documents = exception.documents;
                            switch(exception.ExceptionTypeID){
                                case exceptionTypeConfig.REFUND:
                                    // reset selected productPurchaseCost and refundAmount
                                    let previousRefundAmount = exception.Recommendation;
                                    let refundAmount = exception.Recommendation;
                                    setTimeout(function() {
                                        setExceptionRefundAmount(previousRefundAmount);
                                        setExceptionPurchaseCost(previousProductPurchaseCostLocal)
                                    }, 0)
                                    setIsRefundAmountValidated(!!previousRefundAmount);
                                    setIsProductPurchaseCostValidated(!!previousProductPurchaseCostLocal)
                                    break;
                                case exceptionTypeConfig.WARRANTY_UPDATE:
                                    // selectedWarrantyUpdateDate = exception.Recommendation;
                                    setSelectedWarrantyUpdateDate(exception.Recommendation)
                                    let previousProductPurchaseCost = parseFloat(consumerData.ProductPurchaseCost);
                                    setPreviousProductPurchaseCost(previousProductPurchaseCost )
                                    setIsValidWarrantyUpdateDate(exception && exception.exceptionDatePurchase)
                                    setIsProductPurchaseCostValidated(!!previousProductPurchaseCostLocal)
                                    break;
                            }
                        }
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: localString(displayError(result)),
                        });
                    }
                },
                errorCB: (err) => {
                    setLoader(false);
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(err)),  
                    });
                }
            });
        } else {
            // pass
        }
    };

    const getDepreciationPriceDebounce = ((date,cost)=> {
        debounceTimeout && clearTimeout(debounceTimeout);
        setDebounceTimeout(
            setTimeout(() => {
                getMinRefundVal(date,cost);
            }, 1000)
        );
	});

    const getMinRefundVal =(date,cost)=> {
        // setLoader(true)
        let data = {
            DateOfPurchase: date ? moment(date,'DD-MM-YYYY').format('YYYY-MM-DD') :moment(consumerProduct && consumerProduct.DateOfPurchase,'DD-MM-YYYY').format('YYYY-MM-DD'),
            ProductPurchaseCost: cost ? parseFloat(cost) : parseFloat(consumerProduct && consumerProduct.ProductPurchaseCost),
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
        };
        setFetchingDepriciationPrice(true)
        if(consumerProduct && consumerProduct.DateOfPurchase && consumerProduct.ProductPurchaseCost){
            apiCall({
                url: "getDepreciationPrice",
                data: data,
                callBack: (result) => {
                    // setLoader(false)
                    setSkuLoading(false);
                    if (result.success) {
                        let minRefundValues = result.data.depreciatedPrice;
                        setMinRefundValue(minRefundValues)
                        let previousRefundAmount = minRefundValues;
                        let refundAmount = minRefundValues;
                        if(productPurchaseCost && parseFloat(productPurchaseCost)){
                            setExceptionRefundAmount(parseFloat(refundAmount))
                        }
                        setRefundErrorMessage('')
                        setIsRefundAmountValidated(true)
                        setFetchingDepriciationPrice(false);
                        // self.checkRaiseButton();
                    } 
                },
                errorCB: (err) => {
                    setLoader(false);
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(err)),  
                    });
                }
            });
        }
    }
    const handleExceptionDocument = function (e) {
        setIsFileUploadValidated(false);
        setIsFileUploading(true);
        let filePicType = e.target.name;
        let documentFile;
        if (e && e.target && e.target.files && e.target.files.length > 0) {
            documentFile = e.target.files[0]
            let fileType = documentFile.type
            let ext = fileType.split("/").length ? "."+fileType.split("/")[fileType.split("/").length - 1] : "";
            let originalName = documentFile.name
            let key = "FEWEBA" + generateUUID() + ext;
            if(useDocServiceEscalation) {
                if (docServiceUrl == '') {
                    return;
                }
                let dataNew = new FormData();
                dataNew.append('file', documentFile);
                dataNew.append('docType', 'ConsumerProductDocument');
                dataNew.append('clientRefId', key);
                dataNew.append('entity-type', 'ConsumerProductID');
                dataNew.append('entity-id', consumerServiceRequest.consumerProduct.ConsumerProductID);
                axios.post(docServiceUrl+'internal/document/upload', dataNew, {
                headers:({
                        "App": 'WebApp',
                        "module": 'WebApp',
                        "authorization": authorization
                    }),
                })
                .then((result) => {
                    if(result.data && result.data.data){
                        let uploadURL = result.data.data.uploadURL;
                        let viewURL = result.data.data.url;
                        let fileNameUUID = result.data.data.docServiceUUID;
                        onUploadSuccess(uploadURL, fileNameUUID, viewURL,originalName);
                        // Swal.fire({  
                        //     icon: 'error',  
                        //     text: localString(result.data.message),  
                        // })							
                    }
                })
                .catch((error) => {
                    console.log('error: ' + error);
                })
            } else {
                apiCall({
                    url: 'uploadDocuments',
                    data: { Type: fileType, Key: key, BrandID: consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID ? consumerServiceRequest.consumerProduct.BrandID : undefined},
                    callBack: (result) => {
                        if (result.success) {
                            let xhr = new XMLHttpRequest();
                            xhr.open("PUT", result.data);
                            xhr.setRequestHeader("Cache-Control", "public,max-age=3600");
                            xhr.setRequestHeader('x-amz-acl', 'public-read');
                            xhr.onload = function() {
                                if (xhr.status === 200) {
                                    originalName = originalName.substring(originalName.lastIndexOf('\\') + 1, originalName.length + 1);
                                    onUploadSuccess(key, key);
                                } else {
                                    Swal.fire ('', 'Something went wrong while uploading file.', 'error')
                                }
                            }.bind(this);
                            xhr.onerror = function() {}.bind(this);
                            xhr.send(documentFile);
                        } 
                        else {
                            Swal.fire({
                                title:"",
                                text:result.msg ?  localString(result.msg) : localString("Something went wrong"),
                                icon:"error"
                            });
                        }
                    }
                });
            }
        } else {
            Swal.fire ({
                title:'', 
                text:'Something went wrong while uploading file.',
                icon :'error'
            })
        }
    }

    // const handleRefundAmount =  (e) =>{
    //     let previousRefundAmount 
    //     setRefundErrorMessage('')
    //     if (e && e.target.value) {
    //         if (e.target.value == '') {
    //             previousRefundAmount  = e.target.value
    //             setIsRefundAmountValidated(false)
    //         } else if (e.target.value && !isNaN(e.target.value)) {
    //             if(e.target.value.split("").reverse().join("").indexOf(".") > 2) {
    //                 setExceptionRefundAmount( e.target.value)
    //             } else {
    //                 if (productPurchaseCost && parseFloat(productPurchaseCost) < parseFloat(e.target.value)) {
    //                     setRefundErrorMessage('Value cannot be more than Product Purchase Cost')
    //                     setIsRefundAmountValidated(false)
    //                 } else {
    //                     setIsRefundAmountValidated(true)
    //                     setRefundErrorMessage('')
    //                 }
    //                 previousRefundAmount  = e.target.value;
    //             }
    //         } else {
    //             setExceptionRefundAmount( e.target.value)
    //         }
    //     }
    //     if (isRefundAmountValidated) {
    //         setRefundAmount( e.target.value)
    //     } else {
    //         setRefundAmount('')
    //     }
    // }
    const onUploadSuccess = (filePath, fileName, viewURL,originalName) => {
        let  url= useDocServiceEscalation && docServiceUrl ? 'addDocsForDS' : 'addDocs'
        let data = [{
                ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                FilePath: filePath,
                Type: 'Image',
                FileName: fileName,
                OriginalName: fileName,
                DocumentID: 12,
                app: 'WebApp'
        }]
        apiCall({
            url: url, 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    if(result.data){
                        setIsFileUploading(false);
                        setIsFileUploadValidated(true)
                        let arr = {}
                        let uploadedImage = result.data[0];
                        arr.FilePath = filePath ? filePath : uploadedImage.FilePath;
                        arr.link= viewURL ? viewURL : (uploadedImage.link || uploadedImage.FilePath);
                        arr.OriginalName= uploadedImage.OriginalName;
                        arr.UserDocumentID= uploadedImage.UserDocumentID;
                        let docArray = [...documents];
                        docArray.push(arr)
                        setDocuments(docArray);
                        
                    }
                    //checkListItemChecked();
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

    const removeDoc =(doc)=>{
        let removeDocArray = documents && documents.filter(item=>item.UserDocumentID !==doc.UserDocumentID);
        setDocuments(removeDocArray);
    }

    const removeAddedDocs = (docs) =>{
        if(docs.length){
            docs.forEach(function(doc) {
                apiCall({
                    url:'deleteDocs',
                    data: {
                        UserDocumentID: doc.UserDocumentID
                    },
                    callBack:(result)=>{
                        if(result.success){
                        }else{
                            Swal.fire({
                                icon: 'error',
                                text: localString(displayError(result)),
                            });
                        }
                    }
                });
            });
        }
    }
    const raiseException = (e)=> {
        if(e){
        e.preventDefault()
        }
        let recommendationTitle = ''
        let recommendation =''
        let recommendationDisplayValue = ''
        switch (selectedException.ExceptionTypeID) {
            case exceptionTypeConfig.REPLACEMENT:
                recommendation = skuPartID
                recommendationDisplayValue = exceptionSku
                recommendationTitle = 'SKU Name'
                break;
            case exceptionTypeConfig.REFUND:
                recommendation = parseFloat(exceptionRefundAmount)
                recommendationDisplayValue = parseFloat(exceptionRefundAmount)
                recommendationTitle = 'Refund Amount'
                break;
            case exceptionTypeConfig.WARRANTY_UPDATE:
                recommendation = selectedWarrantyUpdateDate
                recommendationDisplayValue = moment(selectedWarrantyUpdateDate).format('DD-MM-YYYY')
                recommendationTitle = 'Warranty Update Date'
                break;
        }
        let exceptionArrObj = [{
            name: 'Exception',
            value: selectedException.ExceptionType
        }, {
            name: 'Reason',
            value: exceptionReason
        }, {
            name: 'Remarks',
            value: exceptionRemarks
        }, {
            name: recommendationTitle,
            value: recommendationDisplayValue
        }];
        // if product purchase cost
        if (exceptionPurchaseCost) {
            exceptionArrObj.push ({
                name: 'Purchase Cost',
                value: productPurchaseCost
            })
        }
        // if date of purchase exists
        if (exceptionDatePurchase) {
            exceptionArrObj.push ({
                name: 'Date of Purchase',
                value: moment(consumerProduct && consumerProduct.DateOfPurchase).format('DD-MM-YYYY')
            })
        }

        let exceptionHtml = '<h3>Are you sure?</h3><div class="table-responsive text-size">';
        exceptionHtml += '<table class="table">';
        exceptionHtml += '<tbody>';
        if (exceptionArrObj) {
            for (let i = exceptionArrObj.length - 1; i >= 0; i--) {
                exceptionHtml += '<tr>';
                exceptionHtml += '<td>';
                exceptionHtml += exceptionArrObj[i].name
                exceptionHtml += '</td>';
                exceptionHtml += '<td>';
                exceptionHtml += exceptionArrObj[i].value;
                exceptionHtml += '</td>';
                exceptionHtml += '</tr>';
            }
        }

        exceptionHtml += '</tbody>';
        exceptionHtml += '</table>'
        exceptionHtml += '</div>';
        Swal.fire ({
            title: 'Initiate Exception',
            html: exceptionHtml,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        })
        .then ( (result) => {
            if (result.isConfirmed) {
                setLoader(true);
                let data = {
                    "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
                    "PartnerServiceLocationID": consumerServiceRequest.PartnerServiceLocationID,
                    'ExceptionTypeID': selectedException.ExceptionTypeID,
                    'DateOfPurchase': moment(exceptionDatePurchase,'DD-MM-YYYY').format('YYYY-MM-DD'),// && moment(exceptionDatePurchase).format('YYYY-MM-DD')
                    'Status':consumerServiceRequest.Status,
                    'Remarks':exceptionRemarks && exceptionRemarks,
                    'Reason': selectedReason && selectedReason,
                    'Recommendation': recommendation,
                    'documents':documents
                }
                if(selectedException && selectedException.ExceptionTypeID == exceptionTypeConfig.REFUND){
                    data.ProductPurchaseCost = parseFloat(productPurchaseCost)
                    data.dateOfPurchase = moment(exceptionDatePurchase,'DD-MM-YYYY').format('YYYY-MM-DD') //&& moment(exceptionDatePurchase).format('YYYY-MM-DD')
                }

                if(selectedException && selectedException.ExceptionTypeID === exceptionTypeConfig.WARRANTY_UPDATE){
                    data.ProductPurchaseCost = parseFloat(productPurchaseCost);
                    data.WarrantyUpdateDate = exceptionWarrantyDate;
                }
                // if(documents.length) { don't know when this is called
                //     removeAddedDocs(documents);
                // }
                apiCall({
                    url:'raiseException',
                    data: data,
                    callBack:(result)=>{
                        setLoader(false);
                        if(result.success){
                            let raiseExceptionResult = result.data;
                            setDocuments([]);
                            removeAddedDocs(documents);
                            closeModal();
                            getRequestById();
                        }else{
                            if(result.msg){
                                Swal.fire({
                                    title:'Sorry',
                                    icon: 'error',
                                    text: localString(displayError(result)),
                                });
                            }else{
                                Swal.fire({
                                    title:'Sorry',
                                    icon: 'error',
                                    text: 'Something went wrong.',
                                });
                            }
                        }
                    }
                });
            }
        })
    }

    const approveException = () =>{
        // setLoader(true)
        Swal.fire({
            title: "Approve Request",
            icon: "warning",
            html: '<textarea id="approve-remark" class="cancel-remark"></textarea>',
            confirmButtonText: "Approve",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: true
        }).then(function(result){
            if (result.isConfirmed) {
                // setLoader(false)
                if(!document.getElementById('approve-remark').value) {
                    Swal.close();
                    Swal.fire({
                        title:'Oops...',
                        text:'Please enter Remark before approving',
                        icon:'error'
                    })
                } else {
                    setLoader(true);
                    let data = {
                        ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                        ExceptionID:exception && exception.ExceptionID,
                        IsApproved: exceptionApproveFlagConfig.APPROVED,
                        Remarks:document.getElementById('approve-remark').value,
                        Status: consumerServiceRequest.Status,
                        IsApprovedBy: qFilter && qFilter.userID
                    };
                    if(exception && exception.ExceptionTypeID == exceptionTypeConfig.REPLACEMENT){
                        data.updatedCharges = [selectSKU];
                        data.updatedCharges.InWarranty = true;
                    }
                    apiCall({
                        url:'updateException',
                        data: data,
                        callBack:(result)=>{
                            setLoader(false);
                            if(result.success){
                                closeModal();
                                getRequestById()
                            }else{
                                Swal.fire({
                                    icon: 'error',
                                    text: localString(displayError(result)),
                                });
                            }
                        },errorCB: (err) => {
                            setLoader(false);
                            Swal.fire({  
                                icon: 'error',  
                                text: 'Something went wrong',  
                            });
                        }
                    });
                }
            }
        });
    }    

    const rejectException =() =>{
        Swal.fire({
            title: "Reject Request",
            icon: "warning",
            showCancelButton: true,
            html: '<textarea id="reject-remark" class="cancel-remark"></textarea>',
            confirmButtonText: "Reject",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: true
        }).then(function(result){
            if (result.isConfirmed) {
                if(!document.getElementById('reject-remark').value) {
                    Swal.close();
                    Swal.fire({
                        title:'Oops...',
                        text:'Please enter Remark before rejecting',
                        icon:'error'
                    })
                } else {
                    setLoader(true)
                    let data = {
                        "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
                        "ExceptionID":exception && exception.ExceptionID,
                        "IsApproved": exceptionApproveFlagConfig.REJECTED,
                        "Remarks": document.getElementById('reject-remark').value,
                        "IsApprovedBy": qFilter && qFilter.userID
                    };
                    apiCall({
                        url:'updateException',
                        data: data,
                        callBack:(result)=>{
                            setLoader(false)
                            if(result.success){
                                closeModal();
                                getRequestById()
                            }else{
                                Swal.fire({
                                    icon: 'error',
                                    text: localString(displayError(result)),
                                });
                            }
                        },errorCB: (err) => {
                            setLoader(false);
                            Swal.fire({  
                                icon: 'error',  
                                text: 'Something went wrong',  
                            });
                        }
                    });
                }
            }
        });
    };

    const overrideException = function (e) {
        let recommendationTitle = ''
        let recommendation =''
        switch (selectedException.ExceptionTypeID) {
            case exceptionTypeConfig.REPLACEMENT:
                recommendation = skuPartID
                recommendationTitle = 'SKU Name'
                break;
            case exceptionTypeConfig.REFUND:
                recommendation = parseFloat(exceptionRefundAmount)
                recommendationTitle = 'Refund Amount'
                break;
            case exceptionTypeConfig.WARRANTY_UPDATE:
                recommendation = exceptionWarrantyDate
                recommendationTitle = 'Warranty Update Date'
                break;
        }
        setLoader(true);
        let data = {
            ExceptionID: exception && exception.ExceptionID,
            ConsumerServiceRequestID:consumerServiceRequest.ConsumerServiceRequestID,
            PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID,
            DateOfPurchase: exceptionDatePurchase,
            Status: consumerServiceRequest.Status,
            IsApprovedBy: qFilter && qFilter.userID,
            IsApproved: exceptionApproveFlagConfig.APPROVED,
            updatedCharges: [selectSKU],
            Remarks: exceptionRemarks,
            updateObj: {
                ExceptionTypeID: selectedException.ExceptionTypeID,
                Recommendation: recommendation,
                Reason: selectedReason,
                documents: documents
            }
        }

        if(selectedException.ExceptionTypeID === exceptionTypeConfig.REFUND){
            data.updateObj.ProductPurchaseCost = parseFloat(exceptionPurchaseCost);
            data.updateObj.dateOfPurchase = exceptionDatePurchase;
        }

        if(selectedException.ExceptionTypeID === exceptionTypeConfig.WARRANTY_UPDATE){
            data.updateObj.ProductPurchaseCost = parseFloat(exceptionPurchaseCost);
            data.updateObj.WarrantyUpdateDate = exceptionWarrantyDate;
        }

        if(selectedException.ExceptionTypeID === exceptionTypeConfig.REPLACEMENT){
            data.updatedCharges = [selectSKU];
            data.updatedCharges.InWarranty = true;
        }

        Swal.fire({
            title: "Approve Request",
            icon: "warning",
            confirmButtonText: "Approve",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: true
        }).then(function(result){
            if(result.isConfirmed) {
                apiCall({
                    url:'updateException',
                    data: data,
                    callBack:(result)=>{
                        setLoader(false);
                        if(result.success){
                            closeModal();
                            getRequestById()
                            if(documents.length) {
                                removeAddedDocs(documents);
                            }
                        }else{
                            Swal.fire({
                                icon: 'error',
                                text: localString(displayError(result)),
                            });
                        }
                    },errorCB: (err) => {
                        setLoader(false);
                        Swal.fire({  
                            icon: 'error',  
                            text: 'Something went wrong',  
                        });
                    }
                });
            }
        })
    }
    
    return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='escalation-modal'>
                <Modal.Header>
                    {!!exception && <h4 className='modal-title exception-modal-title'id="myModalLabel">{localString("Approve Exception")}</h4>}
                    {!exception && showInitiateException && <h4 className='modal-title exception-modal-title' id="myModalLabel">{localString("Initiate Exception")}</h4>}
                    {showEditIcon &&<span className="glyphicon glyphicon-edit " onClick={enableEditable}></span>}

                </Modal.Header>
                <Modal.Body>
                    <div className="request-filter">
                        {
                            <div className = 'row exception-row'>
                                <div className = "col-sm-4">
                                    <label className="escalation-modal-label">
                                        {localString("Raise Exception")}
                                    </label>
                                </div>
                                <div className='col-sm-8 scrollable-dropdown-menu'>
                                    <Dropdown
                                        id='dropdown'
                                        // key={index}
                                        value={raiseExceptions}
                                        options={selectExceptionTypes}
                                        handleOnChange={(val) => {
                                            setSelectedException(val)
                                            setRaiseExceptions(val.ExceptionType);
                                            if(val.ExceptionTypeID == exceptionTypeConfig.REPLACEMENT){
                                                setShowSKU(val);
                                                setShowInputs(val)
                                                setShowWarranty(val);
                                            }else if(val.ExceptionTypeID == exceptionTypeConfig.REFUND){
                                                setShowSKU(val);
                                                setShowInputs(val);
                                                setShowWarranty(val);
                                            }else if(val.ExceptionTypeID == exceptionTypeConfig.WARRANTY_UPDATE){
                                                setShowSKU(val);
                                                setShowInputs(val);
                                                setShowWarranty(val);
                                            }
                                        }}
                                        
                                        visitBy='value'
                                        showDownArrow={false}
                                        isDisabled={showEditIcon || exceptionLoading}
                                        customComponent={exceptionLoading && <span className='input-loader  form-control-feedback-padded loader-position'></span>}
                                    />
                                </div>
                            </div>
                        }
                        {
                            <div className = 'row exception-row'>
                                <div className = "col-sm-4">
                                    <label className="escalation-modal-label">
                                        {localString("Reason")}
                                    </label>
                                </div>
                                <div className='col-sm-8 scrollable-dropdown-menu'>
                                    <Dropdown
                                        id='dropdown'
                                        // key={index}
                                        value={exceptionReason}
                                        options={selectReason}
                                        handleOnChange={(val) => {
                                            setExceptionReason(val.ReasonDescription)
                                            delete val.value
                                            setSelectedReason(val)
                                        }}
                                        visitBy='value'
                                        showDownArrow={false}
                                        isDisabled={showEditIcon || reasonLoading}
                                        customComponent={reasonLoading && <span className='input-loader  form-control-feedback-padded loader-position'></span>}
                                    />
                                </div>
                            </div>
                        }
                        {
                            <div className = 'row exception-row'>
                                <div className = "col-sm-4">
                                    <label className="escalation-modal-label">
                                        {localString("Date of Purchase")}
                                    </label>
                                </div>
                                <div className='col-sm-8 scrollable-dropdown-menu'>
                                    <DatePicker
                                        date={exceptionDatePurchase}
                                        format="dd-mm-yyyy"
                                        handleOnChange={(date) => {
                                            setExceptionDatePurchase(moment(date).format('DD-MM-YYYY'))
                                            getDepreciationPriceDebounce(date,exceptionPurchaseCost)
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        {
                            selectedException.ExceptionTypeID == exceptionTypeConfig.REPLACEMENT && <div className = 'row exception-row'>
                                <div className = "col-sm-4">
                                    <label className="escalation-modal-label">
                                        {localString("Select SKU")}
                                    </label>
                                </div>
                                <div className='col-sm-8 scrollable-dropdown-menu'>
                                    <Dropdown
                                        id='dropdown'
                                        // key={index}
                                        value={exceptionSku}
                                        options={selectSKU}
                                        handleOnChange={(val) => {
                                            setExceptionSku(val.Name)
                                            setSkuPartID(val.PartID)
                                            delete val.value
                                            setSelectSkuData(val)
                                        }}
                                        visitBy='value'
                                        showDownArrow={false}
                                        isDisabled={showEditIcon || skuLoading}
                                        customComponent={skuLoading && <span className='input-loader  form-control-feedback-padded loader-position'></span>}
                                    />
                                </div>
                            </div>
                        }
                        {
                            selectedException.ExceptionTypeID == exceptionTypeConfig.REFUND && <div className = 'row exception-row'>
                                <div className = "col-sm-4">
                                    <label className="escalation-modal-label">
                                        {localString("Product Purchase Cost")}
                                    </label>
                                </div>
                                <div className='col-sm-8 scrollable-dropdown-menu'>
                                    <Input 
                                        value={exceptionPurchaseCost}
                                        handleOnChange={(e) => {
                                            if(e.target.value && !isNaN(e.target.value)){
                                                setExceptionPurchaseCost(e.target.value);
                                                setIsProductPurchaseCostValidated(true);
                                                setProductPurchaseCost(e.target.value)
                                                getDepreciationPriceDebounce(exceptionDatePurchase,e.target.value)
                                            }else if(e.target.value.length == 0){
                                                setIsProductPurchaseCostValidated(false);
                                                setExceptionPurchaseCost('')
                                            }
                                        }}
                                        maxLength = "6"
                                        labelAlign='top'
                                        isDisabled={showEditIcon}
                                        otherProps={{
                                            autocomplete: 'off'
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        {
                            selectedException.ExceptionTypeID == exceptionTypeConfig.REFUND && <div className = 'row exception-row'>
                                <div className = "col-sm-4">
                                    <label className="escalation-modal-label">
                                        {localString("Refund Amount")}
                                    </label>
                                </div>
                                <div className='col-sm-8 scrollable-dropdown-menu'>
                                    <Input 
                                        value={exceptionRefundAmount}
                                        handleOnChange={(e) => {
                                            setRefundErrorMessage('')
                                            if(e.target.value && !isNaN(e.target.value)){
                                                setExceptionRefundAmount(e.target.value);
                                                if (productPurchaseCost && parseFloat(productPurchaseCost) < parseFloat(e.target.value)) {
                                                    setRefundErrorMessage('Value cannot be more than Product Purchase Cost')
                                                    setIsRefundAmountValidated(false)
                                                } 
                                            }else if(e.target.value.length == 0){
                                                setExceptionRefundAmount('')
                                            }
                                        }}
                                        labelAlign='top'
                                        isDisabled={!isProductPurchaseCostValidated || showEditIcon || fetchingDepriciationPrice}
                                        otherProps={{
                                            autocomplete: 'off'
                                        }}
                                        // className ={{
                                        //     inputContainer: isProductPurchaseCostValidated ? 'exception-input text-area-remove-resize input-padding form-control':'exception-input text-area-remove-resize input-padding disable-exception-input form-control'
                                        // }}
                                    />
                                    { !refundErrorMessage && !fetchingDepriciationPrice && <span className="help-block">{localString("Suggested amount is based on Depreciation Price")} {minRefundValue}</span>}
                                    { !!refundErrorMessage && <span className="help-block error-color" >{refundErrorMessage}</span>}
                                </div>
                            </div>
                    
                        }
                        {/* {
                            selectedException && (selectedException.ExceptionTypeID == exceptionTypeConfig.WARRANTY_UPDATE) &&  <div className = 'row exception-row'>
                                <div className = "col-sm-4">
                                    <label className="escalation-modal-label">
                                        {localString("Date of Purchase")}
                                    </label>
                                </div>
                                <div className='col-sm-8 scrollable-dropdown-menu'>
                                    <DatePicker
                                        date={exceptionWarrantyDate}
                                        format="dd-mm-yyyy"
                                        handleOnChange={(date) => {
                                            
                                        }}
                                    />
                                </div>
                            </div>
                        } */}
                        {
                            <div class = 'row exception-row'>
                                <div class = "col-sm-4">
                                    <label class="escalation-modal-label">
                                        {localString("Remarks")}
                                    </label>
                                </div>
                                <div class = "col-sm-8">
                                    <Input 
                                        // className="form-control" 
                                        inputComponent='textarea' 
                                        value={exceptionRemarks}
                                        handleOnChange={(e) => {
                                            setExceptionRemarks(e.target.value);
                                        }}
                                        classObject={{
                                            container: 'text-height',
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        {!showEditIcon && <div  className = 'row exception-row'>
                            <div className = "col-sm-4">
                                <label>
                                    {localString("Upload Document Proof")}
                                </label>
                            </div>
                            <div className = "col-sm-8">
                                <input id = 'exception-document' type = "file" accept=".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv" className  = 'exception-input text-area-remove-resize input-padding exception-file-input' onChange={(e)=>handleExceptionDocument(e)}/>
                                {isFileUploading &&<div className="input-loader form-control-feedback form-control-feedback-padded-doc"  aria-hidden="true"></div>}
                            </div>
                        </div>}
                        {
                            documents && documents.length >0 && <div className = 'row exception-row'>
                                <div className = "col-sm-4">
                                    <label>
                                        {localString("Uploaded Documents")}
                                    </label>
                                </div>
                                {
                                    documents && documents.length>0 && documents.map((doc,i)=>(
                                        <div>
                                            <div className="request-documents-li align">
                                                {
                                                    isEditMode || consumerServiceRequest.Status !== 'Exception raised' &&
                                                    <>
                                                        <span className="glyphicon glyphicon-minus-sign delete-charge-btn" onClick={()=>removeDoc(doc)}></span>
                                                        <a href={doc.link} target="_blank" className="nounderline" style={{display: 'inline-block',cursor: 'pointe'}}>
                                                            <div className="documents-box" style={{textAlign:'center', width: 100, minHeight: 110,maxWidth: 100, maxHeight: 100}}>
                                                                <i className="glyphicon glyphicon-file" style={{fontSize:18}}></i>
                                                                <p>{localString("Doc")+ " " + (i+1)}</p>
                                                                <p>{returnLocalDateTime(doc.CreatedDate,'DD MMM, YYYY hh:mma')}</p>
                                                            </div>
                                                        </a>
                                                    </>  
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className = "col-sm-8">
                                    {documents && documents.length>0 && documents.map((doc,index )=>(
                                        <span>{doc.OriginalName} 
                                        {index!==(documents.length-1) &&<span >,</span>}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                        <div className = "modal-footer">
                            {consumerServiceRequest.Status !== 'Exception raised' && !isEditMode && <Button  className="btn button-servify" isDisabled={!exceptionRaiseFlag} onClick = {(e)=>raiseException(e)} >{localString("Initiate")}</Button>}
                            {consumerServiceRequest.Status === 'Exception raised' && !isEditMode &&<Button  className="btn button-servify"  onClick = {(e)=>approveException(e)} isDisabled={!exceptionRaiseFlag}>{localString("Approve")}</Button>}
                            {consumerServiceRequest.Status === 'Exception raised' && !isEditMode &&<Button  className="btn button-servify"  onClick = {(e)=>rejectException(e)} >{localString("Reject")}</Button>}
                            {consumerServiceRequest.Status === 'Exception raised' && isEditMode &&<Button  className="btn button-servify"  onClick = {(e)=>overrideException(e)} isDisabled={!exceptionRaiseFlag}>{localString("Save")} &amp; {localString("Approve")}</Button>} 
                            {/* <button if={consumerServiceRequest.Status === 'Exception raised' && isEditMode} name="submit" class="btn button-servify" value = "approve" onclick = {reset}>{localString("Revert Changes")}</button>   */}
                        </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default EscalationModal;
//isDisabled={!exceptionRaiseFlag}