import React from 'react';
import {Button} from 'sfy-react';
const ShowDiagnosticsResultInput = (props) => {
    const {localString,closeModal,diagnosticsResult} = props;
		return ( <>
            {
                diagnosticsResult && <div className='row'>
                    <div className='col-sm-12'>
                        {
                            diagnosticsResult.diagnosticsEventNumber && 
                            <div className='row divider-margin'>
                                <div className='col-sm-6'>
                                    <label>{localString("Diagnostics Event Number")}
                                    </label>
                                </div>
                                <div className='col-sm-6'>
                                    {diagnosticsResult.diagnosticsEventNumber}
                                </div>
                            </div>
                        }
                        {
                            diagnosticsResult && diagnosticsResult.result && diagnosticsResult.result.diagnosticTestResults && 
                            diagnosticsResult && diagnosticsResult.result && diagnosticsResult.result.diagnosticTestResults && diagnosticsResult.result.diagnosticTestResults.testResultsData.map(testRes=>(
                                <div className='row divider-margin'>
                                    <div className='col-sm-12'>
                                        <label>{localString(testRes.moduleName)}  </label>
                                    </div>
									<div className='col-sm-6'>
                                        <p>{localString(testRes.testName)}</p>
                                    </div>
                                    <div className={`col-sm-6 ${((testRes.testStatus == 'PASSED' || testRes.testStatus.indexOf("PASSED")>-1) ? 'green' : ((testRes.testStatus == 'FAILED' || testRes.testStatus.indexOf("FAILED")>-1) ? 'red' : 'orange'))}`}>
                                        <p>{localString(testRes.testStatus)}</p>
                                    </div>
                                </div>
                            ))
                        }
                        <div className='pull-right'>
                            <Button type="button" className='btn button-servify' handleOnClick={()=>closeModal()}>{localString("Close")}</Button>
                        </div>
                    </div>
                </div>
            }
        </> )
}
export default ShowDiagnosticsResultInput;

