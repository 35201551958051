import React from "react";

const PackageDetails = (props) => {
	const {
		logisticsPackage,
		localString,
	} = props;
	return (
		<>
			{logisticsPackage && logisticsPackage.packageDetails && (
				<table class="table table-striped">
					<tbody>
						<tr>
							<td class="table-keys text-capitalize">
								{localString("Product Price")}:
							</td>
							<td>
								{logisticsPackage.packageDetails.productPrice ||
									"-"}
							</td>
						</tr>
						<tr>
							<td class="table-keys text-capitalize">
								{localString("IMEI")}:
							</td>
							<td>
								{logisticsPackage.packageDetails.imei || "-"}
							</td>
						</tr>
						<tr>
							<td class="table-keys text-capitalize">
								{localString("Model")}:
							</td>
							<td>
								{logisticsPackage.packageDetails.modelNo || "-"}
							</td>
						</tr>
						<tr>
							<td class="table-keys text-capitalize">
								{localString("Brand Name")}:
							</td>
							<td>
								{logisticsPackage.packageDetails.brandName ||
									"-"}
							</td>
						</tr>
						<tr>
							<td class="table-keys text-capitalize">
								{localString("Product Name")}:
							</td>
							<td>
								{logisticsPackage.packageDetails.productName ||
									"-"}
							</td>
						</tr>
					</tbody>
				</table>
			)}
		</>
	);
};

export default PackageDetails;
