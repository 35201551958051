import React, { useState,useEffect } from 'react';
import Swal from 'sweetalert2';
import { displayError,SERVICETYPE } from '../../../../../Utility/Utils';
import {Input,Button,Dropdown} from 'sfy-react'
import CloseVisitModal from '../JobUpdateModal/CloseVisit/CloseVisitModal';
import { cloneDeep } from 'lodash';

const ReplacementOptionInput = (props) => {
    const {
        apiCall,localString,replacementFlowFlags,deviceConfigInput,savedOptionKeys,savedOptions,selectedReplacement,
        selectOption,saveSelectedOption,replacementSumInsured,currencyCode,optionsArray,consumerServiceRequest,
        selectBrandList,replacementFlowChecks,setLoader,nextStatus,replacementFulfillment,setShowModal,showModal,
        closeModal
    } = props; 

    const [replacementOptionData,setReplacementOptionData] = useState({ })
    const [brandId,setBrandId]= useState(null);
    const [productSubCategory,setProductSubCategory] = useState({})
    const [brandData,setBrandData] = useState({})
    const [productData,setProductData] = useState({})
    const [disableOptionSave,setDisableOptionSave] = useState(true)
    const [productOptions,setProductOptions] = useState([]);

    useEffect(()=>{
        deviceConfigInput && deviceConfigInput.length>0 && deviceConfigInput.map((val,i)=>{
            optionsArray.map((opt,index)=>{
                if(val.Value){
                    productSubCategory[val.ConstantName+index] = val.Value
                    productSubCategory['ProductSubCategoryID'+index] = consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID
                }
            })
        })
    },[])

    const getProductList =  (brandId, index) =>{
        if(replacementFlowChecks){
            let data = {
                ProductSubCategoryID: consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID,
                BrandID: parseInt(brandId)
            }
            setLoader(true)
            apiCall({
                url: "getProductList", 
                data: data,
                callBack: (result) => {
                    setLoader(false);
                    if (result.success) {
                        let productList = result.data && result.data.map(v=>({...v,value:v.ProductName}))
                        let arr = cloneDeep(productOptions);
                        arr[index] = productList;
                        setProductOptions(arr);
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: localString(displayError(result)),
                        });
                    }
                },
            });
        }
    };

    const getReimbursement = () => {
        closeModal();
        setShowModal({...showModal,showPayoutModesModal : true});
    };

    const processReplacementOptionsObj = () => {
        optionsArray && optionsArray.forEach((option,index) => {
            option.index = index;
            option.isArrayValid = true
            deviceConfigInput.map((obj,i) => {
                if(obj.ConstantName != 'Attributes'){
                    if(productSubCategory[obj.ConstantName+index]  && productData['ProductID'+index] && brandData['brandid'+index]){
                        option.OptionValues.push({
                            "DeviceOptionType": obj.ConstantName,
                            "DeviceOptionValue": productSubCategory[obj.ConstantName+index]
                        });
                        option.OptionValues.push({
                            "DeviceOptionType": "ProductSubCategoryID",
                            "DeviceOptionValue":productSubCategory['ProductSubCategoryID'+index]
                        });
                    }
                    if(brandData[obj.ConstantName+index] ==brandData['BrandName'+index] && productData['ProductID'+index]){
                        option.OptionValues.push({
                            "DeviceOptionType": obj.ConstantName,
                            "DeviceOptionValue": brandData[obj.ConstantName+index]
                        });
                        option.OptionValues.push({
                            "DeviceOptionType": 'BrandID',
                            "DeviceOptionValue": brandData['brandid'+index]
                        });
                    }
                    
                    if(productData[obj.ConstantName+index]  && productData['ProductID'+index] && brandData['brandid'+index] ){
                        option.OptionValues.push({
                            "DeviceOptionType": obj.ConstantName,
                            "DeviceOptionValue": productData[obj.ConstantName+index]
                        });
                        option.OptionValues.push({
                            "DeviceOptionType": 'ProductID',
                            "DeviceOptionValue": productData['ProductID'+index]
                        });
                    }
                }else if(obj.ConstantName == 'Attributes' && obj.AttributesList && obj.AttributesList.length > 0) {
                    obj.AttributesList.forEach((attrObj, attrIndex) => {
                        let attrId = obj.ConstantName + attrIndex + '-option-' + index;
                        if(document.getElementById(attrId) && document.getElementById(attrId).value) {
                            option.OptionValues.push({
                                "DeviceOptionType": attrObj.ProductSubCategoryAttributeName,
                                "DeviceOptionValue": document.getElementById(attrId).value,
                                "IsSubCategoryAttribute": true
                            })
                        }
                    })
                }
            });
        })  
        let validArray = []
        optionsArray &&  optionsArray.length>0 && optionsArray.map((valid)=>{
            if(valid.OptionValues.length !=0){
                validArray.push(valid)
            }
        })
        var data = {
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "Status": nextStatus,
            "DeviceOptions": validArray,
			'CountryID': consumerServiceRequest && consumerServiceRequest.CountryID
        };
        replacementFulfillment(data);
    };

    return (
		<div>
            {
                replacementFlowFlags.addOptionsMode &&
                <div className='modal-body clearfix mt0'>
                    {
                        replacementSumInsured && <div className='row'>
                            <label>{localString("Please ensure replacement options are within the covered value " + currencyCode + ' ' + replacementSumInsured)}</label>
                        </div>
                    }
                    <div className='row options-row'>
                        <div className='col-sm-3 header-column'>
                            {/* <div ></div> */}
                            {
                                deviceConfigInput && deviceConfigInput.map(val=>(
                                    <div>
                                        {val.ConstantName != 'Attributes' && <div className='table-elements'>
                                            <label>{val.DisplayText}</label>
                                            {val.Optional && <span>(optional)</span>} 
                                        </div>}
                                            {
                                                val.AttributesList && val.AttributesList.length>0 && val.AttributesList.map(attribute =>(
                                                    val.ConstantName == 'Attributes' && val.AttributesList && val.AttributesList.length > 0 && (['IMEI/Serial No', 'IMEI/SerialNo', 'SerialNo'].indexOf(attribute.ProductSubCategoryAttributeName) == -1) &&
                                                    <div className="table-elements">
                                                        <label>{attribute.ProductSubCategoryAttributeName}</label> 
                                                        {val.Optional && <span>(optional)</span>}
                                                    </div>
                                                ))
        
                                        }
                                    </div>   
                                ))
                            }
                        </div>
                        {
                            optionsArray && optionsArray.length>0 && optionsArray.map((option,index)=>(
                                <div className='col-sm-3 input-column'>
                                    <div class = "table-elements option-label">
                                        <label>{"Option" + (index + 1)}</label>
                                    </div>
                                    {
                                        deviceConfigInput && deviceConfigInput.length>0 && deviceConfigInput.map((val,i)=>(
                                            <div>
                                                { val.ConstantName != 'Attributes' && 
                                                <div className='table-elements'>
                                                {val.Type=='dropdown' &&
                                                    <>
                                                        {val.ConstantName=='ProductSubCategory' &&<Dropdown
                                                            id={val.ConstantName + "-option-" + index}
                                                            value={val.Value}
                                                            key={option.Option}
                                                            isDisabled = {!val.Editable}
                                                            options=''
                                                            handleOnChange={(data) => {
                                                                setProductSubCategory({...productSubCategory,[val.ConstantName+index]:data.value})
                                                            }}
                                                            showDownArrow={false}
                                                            filterBy="value"
                                                            showTooltip={false}
                                                        />}
                                                        {val.ConstantName=='BrandName' &&<Dropdown
                                                            id={val.ConstantName + i + "-option-" + index}
                                                            value={brandData[val.ConstantName+index]}
                                                            key={option.Option}
                                                            options={selectBrandList}
                                                            handleOnChange={(data) => {
                                                                setBrandData({...brandData,[val.ConstantName+index]:data.value,['brandid'+index]:data.BrandID})
                                                                setBrandId(data.BrandID);
                                                                setProductData({...productData,['ProductName'+index]:'',['ProductID'+index]:''})
                                                                getProductList(data.BrandID, index)
                                                            }}
                                                            showDownArrow={false}
                                                            filterBy="value"
                                                            showTooltip={false}
                                                        />} 
                                                        {val.ConstantName=='ProductName' &&<Dropdown
                                                            id={val.ConstantName + "-option-" + index}
                                                            value={productData[val.ConstantName+index]}
                                                            key={option.Option}
                                                            options={productOptions[index] || []}
                                                            handleOnChange={(data) => {
                                                                setProductData({...productData,[val.ConstantName+index]:data.value,['ProductID'+index]:data.ProductID})
                                                                setDisableOptionSave(false)
                                                            }}
                                                            showDownArrow={false}
                                                            filterBy="value"
                                                            showTooltip={false}
                                                        />}
                                                    </>
                                                }
                                                                    {/* {  val.Type=="text" && <input  type="text" class="form-control" value={val.Value} disabled={!val.Editable} 
                                                        maxlength={val.Validation && val.Validation.LengthValidation && val.Validation.InputMaxLength ? val.Validation.InputMaxLength : 100} 
                                                        id={val.ConstantName + "-option-" + i} 
                                                        name={val.ConstantName} 
                                                        // oninput={checkEnteredInput} 
                                                        // onfocus={checkToAddDropdown} 
                                                        data-optionindex={i}
                                                    />} */}
                                                </div>}
                                                {
                                                    <>{ val.AttributesList && val.AttributesList.length>0 && val.AttributesList.map((attribute, attrIndex)=>(
                                                            val.ConstantName == 'Attributes' && val.AttributesList && val.AttributesList.length > 0 && (['IMEI/Serial No', 'IMEI/SerialNo', 'SerialNo'].indexOf(attribute.ProductSubCategoryAttributeName) == -1) && <div className='table-elements'>
                                                            <Input 
                                                                className="form-control" 
                                                                inputType='text'
                                                                id={val.ConstantName + attrIndex + "-option-" + index}  
                                                                value={replacementOptionData[attribute.ProductSubCategoryAttributeName+index]}
                                                                handleOnChange={(e) => {
                                                                    setReplacementOptionData({...replacementOptionData,[attribute.ProductSubCategoryAttributeName+index]:e.target.value})
                                                                }}
                                                                isDisabled={!val.Editable}
                                                                maxLength='16'
                                                                autoComplete='off'
                                                                name={attribute.ProductSubCategoryAttributeName}
                                                            />
                                                        </div>
                                                    ) )}</>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }    
                    </div>
                    <div class="text-right mt20 pt10">
                        <Button class="btn button-servify-solid m0" isDisabled={disableOptionSave} onClick={()=>processReplacementOptionsObj()}>{localString("Submit")}</Button>
                    </div>
                </div>
            }
            {
                ((replacementFlowFlags.chooseOptionsMode) || (replacementFlowFlags.viewOptionsMode)) && <div className='clearfix'>
                    <div className={replacementFlowFlags.chooseOptionsMode ? "choose-mode" : replacementFlowFlags.viewOptionsMode ? "view-mode row options-row" : "row options-row"}>
                        <div className='col-sm-3 header-column'>
                        {
                            savedOptionKeys && savedOptionKeys.map(key=>(
                                <div className='table-elements'>
                                    <label>{key}</label>
                                </div>
                            ))
                        }
                        </div>
                        {
                            savedOptions && savedOptions.map(option=>(
                                <div onClick={()=>selectOption(option)} className='select-option'>
                                    <div className={`col-sm-3 input-column ${selectedReplacement && selectedReplacement.Option == option.Option ? 'active' : ''}`} >
                                        <div className = "table-elements btn-section">
                                            <label>{localString(option.OptionLabel)}</label>
                                        </div>
                                        {
                                            savedOptionKeys && savedOptionKeys.map(key=>(
                                                <div className = "table-elements">
                                                    <label className='form-control label-text'>{option.OptionValues && option.OptionValues[key] ? option.OptionValues[key] : ''}</label>
                                                </div>
                                            ))
                                        }
                                        {
                                            !replacementFlowFlags.viewOptionsMode &&
                                            <div className= "table-elements btn-section" >
                                                <Button className={`btn-width btn ${selectedReplacement && selectedReplacement.Option == option.Option ? 'activeOptionBtn' : 'button-servify-solid-option'} `} handleOnClick={()=>selectOption()}>{localString("Select")}</Button>
                                            </div>
                                        }
                                        
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                
            }
            {(!replacementFlowFlags.viewOptionsMode && replacementFlowFlags.showReplacementOptionsProvided) && <div className="form-group-filter-request text-right borderT">
                {/* {
                    ([65].indexOf(consumerServiceRequest.ServiceTypeID) == -1 ) && <Button className='btn button-servify-solid' handleOnClick={()=>getReimbursement()}>{localString("Get Reimbursed Instead")}</Button>
                } */}
                <Button className='btn button-servify-solid' isDisabled = {!selectedReplacement} handleOnClick={()=>saveSelectedOption()}>{localString("Submit")}</Button>
                
            </div>}
        </div>
	)
}
export default ReplacementOptionInput;

