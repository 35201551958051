import React from 'react';
import {Modal,Checkbox,Button} from 'sfy-react';
import './FlagEscalation.scss'

const FlagEscalationModal = (props) => {
	// removed setDisableSave
	const {escaltionArray,localString,showFlagEscalationModal,updateFlags,selectEscalationMedia,selectFlagEscalation,selectEscalationSource,cancelFunction,disableSave,
		selectEscalationType,selectEscalationSourceValidation,selectEscalationMediaValidation} = props;
	return (
		<div>
			<Modal showModal={showFlagEscalationModal} showCloseBtn={false} className="flag-modal">
				<Modal.Header>
					<div className="custom-header">
						<h4> {localString("Flag Requests")} </h4>
					</div>
				</Modal.Header>
				<Modal.Body className="clearfix">
					<div className="col-sm-12">
						{
							escaltionArray && escaltionArray.length > 0 && escaltionArray.map((item,i)=>{
								return(
								<div className="first-escalation">
									<Checkbox
										value={item.IsSaved}
										className={item.IsSaved ? "clicked":""}
										id={"escalationType-"+i}
										name={item.EscalationName}
										handleOnChange={(e) => {
											selectFlagEscalation(item);
											selectEscalationType(escaltionArray,item);
										}}
									>{item.EscalationName}</Checkbox>
									{
										item.IsSaved && item.EscalationSource && item.EscalationSource.length > 0 &&
										<div className="source-wrapper">
											<div className="source-header">{localString("Select Escalation Source")}</div>
											<div className="source-div">
												{
													item.EscalationSource.map((innerFlag,i)=>{
														return(
															<div className="second-escalation">
																<Checkbox
																	value={innerFlag.IsSaved}
																	id={"escalationSource-"+i}
																	name={innerFlag.SourceName}
																	handleOnChange={(e) => {
																		selectEscalationSource(innerFlag);
																		selectEscalationSourceValidation(item.EscalationSource,innerFlag);
																	}}
																>{innerFlag.SourceName}</Checkbox>
																{
																	innerFlag.IsSaved && innerFlag.EscalationSourceMedia && innerFlag.EscalationSourceMedia.length > 0 &&
																	<div className="source-media-div">
																	{
																		innerFlag.EscalationSourceMedia.map((third,i)=>{
																			return(
																				<div className="media-div">
																					<Checkbox
																						value={third.IsSaved}
																						type={third.IsSaved ? "tabs tabs-selected" : "tabs"}
																						id={"EscalationSourceMediaID-"+i}
																						name={third.MediaName}
																						handleOnChange={(e) => {
																							selectEscalationMedia(third);
																							selectEscalationMediaValidation(innerFlag.EscalationSourceMedia,third);																								
																						}}
																					>{third.MediaName}</Checkbox>
																				</div>
																			)
																		})
																	}
																	</div>
																}
															</div>
														)
													})
												}
											</div>
										</div>
									}
								</div>
								)
							})
						}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<>
						<Button handleOnClick={cancelFunction} type='button' isDisabled="" className="btn cancel-btn">{localString("Cancel")}</Button>
						<Button handleOnClick={updateFlags} type='button' isDisabled={disableSave} className="btn save-this">{localString("Save this")}</Button>
					</>
				</Modal.Footer>
			</Modal>
		</div>
	)
}

export default FlagEscalationModal
