import React  from 'react';
import DateRangePickerWrapper from '../../../components/DateRangePickerWrapper/DateRangePickerWrapper';
import {Button} from 'sfy-react';
import './RequestTask.scss';
const ConsumerRequestTask = (props) =>{
    const { 
        accessFn, setShowCount, minDate, maxDate, displayCountButton, toggleCount, localString, dateRange, 
        consumerRequestTaskCountButton, showCount, openTaskRequestCount, handleDateRangeApply, setDateRange, 
        dateError, requestFilterData
    } = props;

    //show={displayCountButton}  show count buton
    return(
    <div> 
        <div className="request-task-container">
            <div className="row">
                <div className="col-sm-5">
                    {
                        accessFn('TASK_MANAGEMENT') &&  <span className="task-request-open-text">{openTaskRequestCount ? openTaskRequestCount : '0'} {localString('request(s) have open task(s)')}</span> 
                    }
                    {
                        accessFn('TASK_MANAGEMENT') && <span id = 'info-tooltip' className="glyphicon glyphicon-info-sign" data-toggle="tooltip" data-placement="top" title="Only date filter applied"></span>
                    }
                </div>
                    <div className="col-sm-7">
                        {/* {
                            consumerRequestTaskCountButton &&  consumerRequestTaskCountButton.map(button=>(
                                <div className='col-sm-6 '>
                                    {
                                        button.type &&  button.type =='button' &&
                                        <Button
                                            text={ button.labelText}
                                            className='btn button-servify pull-right'
                                            handleOnClick={(val) => {
                                                !showCount ? button.oninputFunc(val) : button.oninputFunc(!val);
                                            }}
                                        />
                                    }
                                </div>
                            ))
                            
                        } */}

                        <div className='col-sm-6 '>
                            { <Button className="btn button-servify pull-right" onClick={()=>toggleCount()}>
                                {showCount ? localString('Hide Count') : localString('Show Count')}
                            </Button>}      
                                                
                        </div> 
                        <div className='col-sm-6 '>
                            <span className='col-sm-3 period-text-margin'>
                                <label className='label-text'>{localString('Period') + ' :'} </label>
                            </span>
                            <DateRangePickerWrapper
                                date={maxDate}
                                minDate={minDate}
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                                handleDateRangeApply={handleDateRangeApply}
                                dateClass="col-sm-6"
                                customFormat="dd-mm-yyyy"
                                customPlaceholder='DD-MM-YYYY TO DD-MM-YYYY'
                                dateWidthClass={"date-range"}
                                showFilterBtn={!requestFilterData?.ReferenceID && !dateError}
                                calendarFooter={<>
                                    <div className='dateTextError'>
                                        {dateError ? localString('Please select a date range within 6 months') : ''}
                                    </div>
                                </>}
                            /> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ConsumerRequestTask;


