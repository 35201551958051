import React from 'react';
import {Input,Button,Modal} from 'sfy-react';
import './OverrideApproval.scss'
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';

const OverrideApprovalWorkshop = (props) => {
	const {localString,accessFn,setOverrideReason,overrideReason,openBillingDetailModal,showModal,closeModal,selectedBillingDetail
		,consumerServiceRequest,changeBillingDetails,save,canUpdateServiceRequest
	} = props;
	return (
		<div>
			
			{
				<div className="row">
					<PageContentWrapper title={localString("Override Approval")}>
					{
						<div className="request-panel" id="approvalArticle">
							<p className="override-sub-header">({localString('Override only if customer instructs to')})*</p>
							{
								(accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY') && canUpdateServiceRequest) && 
								<>
									<div className="row">
										<div className="col-sm-12 override-input-reason" id="approvalReason">
											<Input
												value={overrideReason}
												handleOnChange={(e) => {
													setOverrideReason(e.target.value)
												}}
												label={localString('Reason for Approval')}
												labelAlign='top'
												inputComponent="textarea"
												otherProps={{rows:"3"}}
												className="form-control charges-form-input"
												id="reason"
											/>
										</div>
									</div>
									<Button handleOnClick={openBillingDetailModal} type='Submit' classes=' btn charges-button button-ghost' text={localString('Save')}></Button>
								</>
							}
						</div>
					}
					</PageContentWrapper>
				</div>
			}
			{
				<Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className="modal-dialog custom-override-approval-modal">
					<Modal.Header>
						<h4 className="modal-title"> {localString("Billing Details")} </h4>
					</Modal.Header>
					<Modal.Body>
						<div className="">
							<div className="col-sm-12">
								<b>{localString("Billing Address")}</b>
								<p>{selectedBillingDetail && selectedBillingDetail.BillingName?selectedBillingDetail.BillingName:consumerServiceRequest && consumerServiceRequest.consumer && consumerServiceRequest.consumer.Name}</p>
								{
									(selectedBillingDetail &&  selectedBillingDetail.TaxDetails && selectedBillingDetail.TaxDetails[0] && selectedBillingDetail.TaxDetails[0].TaxDetailValue) && 
									<p>GST Number - {selectedBillingDetail.TaxDetails[0].TaxDetailValue}</p>
								}
								<p className="address-p">{selectedBillingDetail && selectedBillingDetail.Address?selectedBillingDetail.Address:consumerServiceRequest.Address} {selectedBillingDetail && selectedBillingDetail.Landmark?selectedBillingDetail.Landmark:consumerServiceRequest.Landmark} - {selectedBillingDetail && selectedBillingDetail.PinCode?selectedBillingDetail.PinCode:consumerServiceRequest.Zipcode}</p>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button handleOnClick={changeBillingDetails} type='Submit' classes=' btn charges-button button-ghost' text={localString('Change Billing Address')}></Button>
						<Button handleOnClick={save} type='Submit' classes=' btn charges-button button-ghost' text={localString('Continue')}></Button>
					</Modal.Footer>
				</Modal>
			}
		</div>
	)
}

export default OverrideApprovalWorkshop
