import React, { useState, useEffect, useMemo } from "react";
import { Table } from "sfy-react";
import './Clusters.scss'

const Headings = [
	//"Cluster ID",
	"Name",
	"Email",
	"Reference ID",
	"Sold Plan Code",
	"Mobile No",
	"Address",
];

const convertorObject = {
	Name: "name",
	Email: "emailID",
	"Reference ID": "consumerServiceRequestReferenceID",
	"Sold Plan Code": "soldPlanCode",
	"Mobile No": "mobileNo",
	Address: "address",
};

const ASC = 1;
const DESC = -1;

const Clusters = (props) => {
	const { localString, clustersDetails, getClusterDetails, caseDetails } =
		props;

	const [sortConfig, setSortConfig] = useState({
		field: Headings[0],
		order: DESC,
	});

	const sortedData = useMemo(() => {
		let order = sortConfig.order === ASC ? DESC : ASC;
		const currentSortingBasedOn = convertorObject[sortConfig.field];
		let newData = [...clustersDetails.clusterData];

		if (newData) {
			//if (sortConfig.field === "Cluster ID") {
			//	newData = newData.sort((obj1, obj2) => {
			//		return obj1.clusterID.value > obj2.clusterID.value
			//			? order
			//			: obj1.clusterID.value < obj2.clusterID.value
			//			? -order
			//			: 0;
			//	});
			//} else {
			//	newData = newData.sort((obj1, obj2) => {
			//		return obj1[currentSortingBasedOn].groupID >
			//			obj2[currentSortingBasedOn].groupID
			//			? order
			//			: obj1[currentSortingBasedOn].groupID <
			//			  obj2[currentSortingBasedOn].groupID
			//			? -order
			//			: 0;
			//	});
			//}

			newData = newData.sort((obj1, obj2) => {
				return obj1[currentSortingBasedOn].groupID >
					obj2[currentSortingBasedOn].groupID
					? order
					: obj1[currentSortingBasedOn].groupID <
					  obj2[currentSortingBasedOn].groupID
					? -order
					: 0;
			});
		}

		return newData;
	}, [sortConfig.order, sortConfig.field, clustersDetails.clusterData]);

	useEffect(() => {
		if (caseDetails?.consumerServiceRequest?.referenceID)
			getClusterDetails();
	}, []);

	return (
		<div style={{ padding: "10px" }}>
			<Table isResponsive className={{ table: "custom-table" }}>
				<Table.Header>
					<Table.Row>
						{Headings.map((heading, index) => (
							<Table.Heading
								classObject={{
									header: ``,
									sortContainer: "justify-start",
								}}
								key={index}
								onClick={() => {
									let order =
										sortConfig.order === ASC ? DESC : ASC;

									setSortConfig({
										field: heading,
										order,
									});
								}}
								showSortIcons={true}
								activeSortIcon={
									heading === sortConfig.field &&
									(sortConfig.order === ASC
										? "up"
										: sortConfig.order === DESC && "down")
								}
							>
								{heading}
							</Table.Heading>
						))}
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{sortedData && sortedData.length > 0 ? (
						<>
							{sortedData.map((d, i) => (
								<Table.Row key={i}>
									{/*<Table.Data
										otherProps={{
											style: {
												backgroundColor: d.clusterID
													.value
													? `${d.clusterID.hexCode}`
													: "",
											},
										}}
									>
										{d.clusterID.value}
									</Table.Data>*/}
									<Table.Data
										otherProps={{
											style: {
												backgroundColor: d.name.value
													? `${d.name.hexCode}`
													: "",
											},
										}}
									>
										{localString(d.name.value)}
									</Table.Data>
									<Table.Data
										otherProps={{
											style: {
												backgroundColor: d.emailID.value
													? `${d.emailID.hexCode}`
													: "",
											},
										}}
									>
										{d.emailID.value}
									</Table.Data>

									<Table.Data
										otherProps={{
											style: {
												backgroundColor: d
													.consumerServiceRequestReferenceID
													.value
													? `${d.consumerServiceRequestReferenceID.hexCode}`
													: "",
											},
										}}
									>
										{
											d.consumerServiceRequestReferenceID
												.value
										}
									</Table.Data>
									<Table.Data
										otherProps={{
											style: {
												backgroundColor: d.soldPlanCode
													.value
													? `${d.soldPlanCode.hexCode}`
													: "",
											},
										}}
									>
										{d.soldPlanCode.value}
									</Table.Data>
									<Table.Data
										otherProps={{
											style: {
												backgroundColor: d.mobileNo
													.value
													? `${d.mobileNo.hexCode}`
													: "",
											},
										}}
									>
										{d.mobileNo.value}
									</Table.Data>
									<Table.Data
										otherProps={{
											style: {
												backgroundColor: d.address.value
													? `${d.address.hexCode}`
													: "",
											},
										}}
									>
										{d.address.value}
									</Table.Data>
								</Table.Row>
							))}

							<Table.Row>
								<Table.Data
									otherProps={{
										colspan: "7",
									}}
								>
									{clustersDetails.legendTitle}
								</Table.Data>
							</Table.Row>
						</>
					) : (
						<Table.Row>
							<Table.Data
								className="text-center paddingTB15"
								otherProps={{
									colspan: "7",
								}}
							>
								<div className="text-center requests-empty-state">
									{localString("No Data")}
								</div>
							</Table.Data>
						</Table.Row>
					)}
				</Table.Body>
			</Table>
		</div>
	);
};

export default Clusters;
