import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Swal from "sweetalert2";
import { Loader, Pagination } from 'sfy-react';
import moment from 'moment';
import Papa from 'papaparse';

import CommonTable from '../../../../components/Table/Table';
import { displayError, downloadCSV } from '../../../../Utility/Utils';
import ReportPageHeader from '../../ReportPageHeader';
import FilterModal from '../../../../components/FilterModal/FilterModal';
import './DisplayShareReport.scss'

let defaultFilters = {
	shop: '',
	branch: '',
	advisor: '',
	dateRange: {
		startDate: new Date(moment().startOf('month')),
		endDate: new Date()
	},
	brand: [],
	category: '',
	subCategory: ''
}
export default function DisplayShareReport(props) {
	const { accessFn, localString, apiCall, type, titleHeader } = props;
	const theme = JSON.parse(props.theme);
	const [loader, setLoader] = useState(false);
	const [data, setData] = useState([]);
	const [showModal , setShowModal] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [mainFilters, setMainFilters] = useState(defaultFilters);
	const [branchList, setBranchList] = useState([]);
	const [branchObj, setBranchObj] = useState([]);
	const [shopList, setShopList] = useState([]);
	const [shopObj, setShopObj] = useState([]);
	const [brandList, setBrandList] = useState([]);
	const [categoryList, setCategoryList] = useState([]);
	const [categoryObj, setCategoryObj] = useState([]);
	const [subCategoryList, setSubCategoryList] = useState([]);
	const [subCategoryObj, setSubCategoryObj] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [pagination , setPagination] = useState({
		pageNo : 1,
		itemsPerPage : 30,
		count: 0,
		numberOfPages: 0
	});
	const lastDay = moment().format("YYYY-MM-DD");

	const buttonHeader = [
		{
			buttonText: localString('Filter Requests'),
			btnIcon: 'glyphicon glyphicon-filter',
			showButton: true,
			onButtonClick: () => {
				setShowModal(true);
			}
		},
	];

	const getCompetitionSalesReport = (downloadFile) => {
		setLoader(true);
		let BrandListArr = [...brandList];
		BrandListArr.shift();
		let bIds = selectAll ? BrandListArr.map(v => v.BrandID) : filters.brand.map(v => v.BrandID);
		let reqObj = {
			BranchID: filters.branch ? branchObj.BranchID : null,
			BrandIDList: bIds,
			OtherProductIDList: filters.subCategory ? subCategoryObj.OtherProductIDList : [],
			PartnerRetailLocationIDList: filters.shop ? [shopObj.PartnerRetailLocationID] : [],
			ProductIDList: filters.subCategory ? subCategoryObj.ProductIDList : [],
			ProductSubCategoryIDList: filters.category ? [categoryObj.ProductSubCategoryID] : [],
			Type: type,
			eDate: moment(filters.dateRange.endDate).format("DD-MM-YYYY"),
			sDate: moment(filters.dateRange.startDate).format("DD-MM-YYYY")
			// eDate: "31-07-2021",
			// sDate: "01-07-2021",
		}
		if(filters.subCategory) reqObj.ProductName = filters.subCategory;
		if(!downloadFile) {
			reqObj.Limit = pagination.itemsPerPage;
			reqObj.PageNo = pagination.pageNo;
			reqObj.Range = 5
		}
		apiCall({
			url: 'getCompetitionSalesReport',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data && !downloadFile) {
					if(type == 2) {  // If Display share report
						let arr = [];
						result.data.competitionReports.length && result.data.competitionReports.map((res, k) => {
							res.length && res.map(obj => {
								arr.push(obj);
							})
						})
						setData(arr);
					}else {
						setData(result.data.competitionReports);
					}
					setPagination({...pagination, 
						count: result.data && result.data.length,
						numberOfPages: result.data && result.data.numberOfPages
					})
				}else if(result.success && result.data && downloadFile) {
					let arr = result.data.competitionReports
					let downloadCsv = arr.filter(res => {
						let obj = {
							'Date': res.Date ? res.Date : '-',
							'Advisor Code': res.AdvisorCode ? res.AdvisorCode : '-',
							'Advisor Name' : res.Name ? res.Name : '-',
							'Branch': res.Branch ? res.Branch : '-',
							'Shop' : res.Shop ? res.Shop : '-',
							'Category' :res.ProductCategory ? res.ProductCategory : '-',
							'Sub Category': res.ProductSubCategory ? res.ProductSubCategory : '-',
							'Brand': res.Brand ? res.Brand : '-',
							'Quantity': res.Quantity ? res.Quantity : '-'
						}
						return obj;
					})
					let csv = Papa.unparse({
						header: true,
						dynamicTyping: true,
						data: downloadCsv,
					});
					downloadCSV(csv, 'Display Share Reports.csv');
				}else {
					Swal.fire({  
						icon: downloadFile ? 'success' : 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		mainFilters.branch && getCompetitionSalesReport();
	}, [mainFilters, pagination.pageNo])

	const branchMasterGetFilter = () => {
		apiCall({
			url: 'branchMasterGetFilter',
			data: {},
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(val => ({ ...val, value: val.displayDetails}))
					setBranchList(arr);
					if(result.data.length && !filters.branch) {
						setBranchObj(result.data[0]);
						setFilters({ ...filters, branch: result.data[0].displayDetails });
						setMainFilters({ ...filters, branch: result.data[0].displayDetails });
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		// showModal && branchMasterGetFilter();
		branchMasterGetFilter();
	}, [])

	const getAllShopsAndDistinctStates = () => {
		let obj = {
			branchCode: filters.branch ? branchObj.BranchID : null
		}
		apiCall({
			url: 'getAllShopsAndDistinctStates',
			data: obj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data && result.data.shops) {
					let arr = result.data.shops.map(val => ({...val, value: val.displayDetails}))
					setShopList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		showModal && getAllShopsAndDistinctStates();
	}, [showModal, filters.branch]);

	const getBrandList = () => {
		let reqObj = {
			CategoryType: 2,
			Type: type,
			BrandIDList: [],
			ProductSubCategoryIDList: filters.category ? [categoryObj.ProductSubCategoryID] : null,
		}
		if(!filters.category) reqObj.SelectAll = selectAll; 
		apiCall({
			url: 'getProductOrBrandDetails',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(val => ({...val, value: val.BrandName}))
					if(selectAll) {
						setSelectAll(false);
						setFilters({ ...filters, brand: result.data.map(val => ({...val, value: val.BrandName})) })
					}
					arr.unshift({ BrandID: 'selectAll', value: 'Select All' });
					setBrandList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		showModal && getBrandList();
	}, [showModal, filters.category])

	const getCategoryList = () => {
		let BrandListArr = [...brandList];
		BrandListArr.shift();
		let bIds = selectAll ? BrandListArr.map(v => v.BrandID) : filters.brand.map(v => v.BrandID);
		let reqObj = {
			CategoryType: 1,
			Type: type,
			BrandIDList: bIds,
			SelectAll: selectAll 
		}
		apiCall({
			url: 'getProductOrBrandDetails',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(val => ({...val, value: val.ProductSubCategory}))
					setCategoryList(arr)
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		showModal && getCategoryList();
	}, [showModal, filters.brand])

	const getSubCategoryList = () => {
		let BrandListArr = [...brandList];
		BrandListArr.shift();
		let bIds = selectAll ? BrandListArr.map(v => v.BrandID) : filters.brand.map(v => v.BrandID);
		let reqObj = {
			Type: type,
			CategoryType: 3,
			BrandIDList: bIds,
			ProductSubCategoryIDList: filters.category ? [categoryObj.ProductSubCategoryID] : null,
			SelectAll: selectAll,
			PartnerSelected: !!(filters.brand.filter(k => k.value === 'Godrej').length)
		}
		apiCall({
			url: 'getProductOrBrandDetails',
			data: reqObj,
			callBack: (result) => { 
				setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(val => ({...val, value: val.ProductName}))
					setSubCategoryList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		showModal && getSubCategoryList();
	}, [showModal, filters.category, filters.brand])

	const CustomOptionComponent = useCallback(
		(props) => (
			<span className='custom-dropdown-option'>
				{ props.option.ImageUrl && <img src={props.option.ImageUrl} alt='' width='20px' height='20px'/>}
				{props.option.value}
			</span>
		),
		[]
	)
	
	// Table Columns
	const columns = [
		{
			title: localString("Date"),
			showHeading: true,
			customCell: (row) => row?.Date || '--',
		},
		{
			title: localString("Branch"),
			showHeading: true,
			customCell: (row) => row?.Branch || '--',
		},
		{
			title: localString("Shop"),
			showHeading: true,
			customCell: (row) => row?.Shop || '--',
		},
		{
			title: localString("Brand"),
			showHeading: true,
			customCell: (row) => row?.Brand || '--',
		},
		{
			title: localString("Product Category"),
			showHeading: true,
			customCell: (row) => row?.ProductCategory || '--',
		},
		{
			title: localString("Product Subcategory"),
			showHeading: true,
			customCell: (row) => row?.ProductSubCategory || '--',
		},
		{
			title: localString("Quantity"),
			showHeading: true,
			customCell: (row) => row?.Quantity || '--',
		}
	];

	// Filter Handlers
	let filterInputList = [
		{
			labelText: localString('Date'),
			dataKey: "dateRange",
			customClass:'col-sm-12 paddingB10',
			showFilter: true,
			type: 'DateRangePicker',
			maxDay: new Date(lastDay),
			oninputFunc: (val) => {
				setFilters({ ...filters, dateRange: val });
			}
		},
		{
			labelText: localString('Branch'),
			dataKey: "branch",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter: true,
			type: 'Dropdown',
			dropdownList: branchList,
			oninputFunc: (val) => {
				setBranchObj(val);
				setShopObj(null);
				setFilters({ ...filters, branch: val.displayDetails, shop: '' })
			}
		},
		{
			labelText: localString('Shop'),
			dataKey: "shop",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter: true,
			type: 'Dropdown',
			dropdownList: shopList,
			oninputFunc: (val) => {
				setShopObj(val);
				setFilters({ ...filters, shop: val.displayDetails })
			}
		},
		{
			labelText: localString('Brand'),
			dataKey: "brand",
			customClass:'col-sm-12 paddingB10',
			showFilter: true,
			type: 'MultiSelect',
			dropdownList: brandList,
			uniqueKey: 'BrandID',
			onSelect: (val) => {
				if(val.BrandID === 'selectAll') {
					setFilters({ ...filters, brand: [val] })
					setSelectAll(true);
				}else if(filters.brand.filter(k => k.BrandID === 'selectAll').length){
					setFilters({ ...filters, brand: [val] })
					setSelectAll(false);                 
				}else {
					setFilters({ ...filters, brand: [...filters.brand, val] })
					setSelectAll(false);
				}
			},
			onRemove: (e) => {
				let arr = filters.brand.filter(k => k.BrandID.toString() !== e.target.id.toString());
				e.target.id.toString() === 'selectAll' && setSelectAll(false); 
				setFilters({ ...filters, brand: arr });
			},
			onClearAll: () => {
				setFilters({ ...filters, brand: [] })
				setSelectAll(false); 
			},
			isDisabled: !brandList.length,
			OptionsComponent: CustomOptionComponent
		},
		{
			labelText: localString('Category'),
			dataKey: "category",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter: true,
			type: 'Dropdown',
			dropdownList: categoryList,
			oninputFunc: (val) => {
				setCategoryObj(val);
				setSubCategoryObj(null)
				setFilters({ ...filters, category: val.ProductSubCategory, subCategory: '' })
			},
			isDisabled: !categoryList.length,
			OptionsComponent: CustomOptionComponent
		},
		{
			labelText: localString('Sub Category'),
			dataKey: "subCategory",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter: true,
			type: 'Dropdown',
			dropdownList: subCategoryList,
			oninputFunc: (val) => {
				setSubCategoryObj(val);
				setFilters({ ...filters, subCategory: val.ProductName })
			},
			isDisabled: !subCategoryList.length
		},
	]

	return (
		<div className='displayShareReport'> 
			{
				loader && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<ReportPageHeader 
				titleHeader={type == 2 ? `${theme.salesmanName} ${titleHeader}` : `${titleHeader}`}
				localString={localString}
				btnClass='btn button-servify header-btn button-stack text-capital'
				headerButtonArray={buttonHeader} 
				showHeaderContent={true}
				headerContent={() => {
					return (
						<div style={{ display: 'inline-block' }}>
							<span class="header-date-lowercase">
								({moment(filters.dateRange.startDate).format("DD-MM-YYYY")} to {moment(filters.dateRange.endDate).format("DD-MM-YYYY")})
							</span>
							<div className="download-icon" title="Download Report" onClick={() => getCompetitionSalesReport(true)} data-toggle="tooltip" data-placement="right">
								<div className="download-image" id=""></div>
							</div>
						</div>
					);
				}}
			/>
			<FilterModal
				{...props}
				// disableApplyBtn={disableApplyButton}
				filterFunc={(e) => {
					e.preventDefault();
					setMainFilters(filters);
					setPagination({...pagination, pageNo: 1})
					setShowModal(false);
				}}
				filterInputs={filterInputList}
				filterData={filters}
				showModal={showModal}
				closeModal={() => {
					setFilters(mainFilters)
					setShowModal(false);
				}}
				hideClearButton={false}
				clearSearch={() => {
					setFilters({ ...defaultFilters, branch: mainFilters.branch});
					setMainFilters({ ...defaultFilters, branch: mainFilters.branch});
					setShopObj(null)
					setCategoryObj(null);
					setSubCategoryObj(null)
					setPagination({...pagination, pageNo: 1})
				}}
				
			/>
			<CommonTable 
				columns={columns}
				data={data}
				localString={localString}
			/>
			{
				pagination.numberOfPages > 1 && 
					<Pagination
						itemsPerPage={pagination.itemsPerPage}
						pages={pagination.numberOfPages}
						totalItems={pagination.count}
						activePage={pagination.pageNo}
						paginate={(pageNumber) => setPagination({...pagination, pageNo: pageNumber})}
					/> 
			}
		</div>
	);
}