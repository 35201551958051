import React, { useEffect } from 'react';
import './ThirdPartyCharges.scss'


const ChargesRow = (props) => {
	const {
		accessFn,localString,charge,advanceCharges,removeAddedCharge,consumerServiceRequest,showsavebutton,
		showEstimationButtons,editAddedCharge, currencySymbol
	} = props;

	const allowChargeDeletion = (chargeType) => {
		let allow = false; //False for mandatory deductible
		if ((['Claim amount'].indexOf(chargeType) >= 1 ) && accessFn('CLAIM_APPROVAL')) {
			allow = true;
		}
		if ((['Part', 'Other', 'Discount', 'Advance', 'Advance payment'].indexOf(chargeType) > -1) && accessFn('ADD_NORMAL_CHARGES')) {
			allow = true;
		};
		return allow;
	}

	return (
		<>
		{!advanceCharges && <div className='partRow'>
			<div className='row'>
				<div className="col-xs-8">
					{(['Other','Discount'].indexOf(charge.Type) > -1) &&
						<div className="charges-panel-content bold">
							{[3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && charge.Type+" - "+charge.Name}
							{/* {[3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID) == -1 && charge.Name?localString(charge.Name):charge.Remarks ? localString(charge.Remarks) : ''} */}
							{[3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID) == -1 && charge.Name && <span>{localString(charge.Name)}{charge.Remarks ? ' - ' + localString(charge.Remarks) : ''}</span> }
							{
								showEstimationButtons && ['Service Charge','ServiceCharge','WarrantyConcession','OnSiteCharge'].indexOf(charge.Type) == -1 && consumerServiceRequest.Status !='Parts received' && consumerServiceRequest.Status !='Parts received' && ([3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)==-1 || ([3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)>-1 && showsavebutton)) &&
								<>
									{accessFn('GSX_FLOW') && (['Other'].indexOf(charge.Type) == -1) && (!((accessFn('ADD_PART_POST_APPROVAL') && consumerServiceRequest.allowAddPart))) && <span className="glyphicon glyphicon-edit editDeleteIcon" onClick={() => editAddedCharge(charge)}></span>}
									{['Claim estimation generated', 'Supporting documents required'].indexOf(consumerServiceRequest.Status) == -1 && (!((accessFn('ADD_PART_POST_APPROVAL') && consumerServiceRequest.allowAddPart))) ? <span className="glyphicon glyphicon-minus-sign editDeleteIcon" onClick={() => removeAddedCharge(charge)}></span> : ''}
								</>
							}
						</div>
					}
					{(!(['Other','Discount'].indexOf(charge.Type) > -1)) &&
						<div className="charges-panel-content bold">
							{[3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)>-1 && charge.Type=='Part' && <span>Part - </span>}
							{localString(charge.Name)}
							{
								(['Part'].indexOf(charge.Type) > -1) && charge.Remarks &&
								<span> - {charge.Remarks}</span>
							}
							{
								(['Part'].indexOf(charge.Type) > -1) && charge.partEstimate && charge.partEstimate.PartCategoryName &&
								<span> - {charge.partEstimate.PartCategoryName}</span>
							}
							{
								(['Part'].indexOf(charge.Type) > -1) && charge.partEstimate && charge.partEstimate.PartItemDescription &&
								<span> - {charge.partEstimate.PartItemDescription}</span>
							}
							{
								[3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)==-1 && ['Service Charge','ServiceCharge','Part'].indexOf(charge.Type) > -1 && charge.ChargeCode &&
								<span> - ({charge.ChargeCode})</span>
							}
							{
								showEstimationButtons && ['Service Charge','ServiceCharge','WarrantyConcession','OnSiteCharge'].indexOf(charge.Type) == -1 && consumerServiceRequest.Status !='Parts received' && consumerServiceRequest.Status !='Parts received' && ([3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)==-1 || ([3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)>-1 && showsavebutton)) &&
								<>
									{accessFn('GSX_FLOW') && (['Other'].indexOf(charge.Type) == -1) && (!( (accessFn('ADD_PART_POST_APPROVAL') && consumerServiceRequest.allowAddPart))) && <span className="glyphicon glyphicon-edit editDeleteIcon" onClick={() => editAddedCharge(charge)}></span>}
									{['Claim estimation generated', 'Supporting documents required'].indexOf(consumerServiceRequest.Status) == -1 && (!( (accessFn('ADD_PART_POST_APPROVAL') && consumerServiceRequest.allowAddPart))) ? <span className="glyphicon glyphicon-minus-sign editDeleteIcon" onClick={() => removeAddedCharge(charge)}></span> : ''}
								</>
							}
						</div>
					}
				</div>
				<div className="col-xs-4 text-right bold">
					{
						!(['WarrantyConcession'].indexOf(charge.Type) > -1) &&
						<div className="charges-panel-content text-right">
							<span><span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>
								{
									accessFn('WORKSHOP_FLOW')
									? charge.TaxArray && charge.TaxArray.length > 0 ? charge.Charge:charge.TotalAmount
									: !accessFn('WORKSHOP_FLOW') && charge.Charge
								}
							</span>
							{/* {
								accessFn('WORKSHOP_FLOW')
								? charge.TaxArray && charge.TaxArray.length > 0 ? charge.Charge:charge.TotalAmount
								: !accessFn('WORKSHOP_FLOW') && charge.Charge
							} */}
						</div>
					}
					{
						(['WarrantyConcession'].indexOf(charge.Type) > -1) &&
						<div className="charges-panel-content text-right">
							<span><span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>
								<span>- </span>
								{
									accessFn('WORKSHOP_FLOW')
									? charge.TaxArray && charge.TaxArray.length > 0 ? charge.Charge:charge.TotalAmount
									: !accessFn('WORKSHOP_FLOW') && charge.Charge
								}
							</span>
						</div>
					}
				</div>
				{/* {
					showEstimationButtons && ['Service Charge','ServiceCharge','WarrantyConcession','OnSiteCharge'].indexOf(charge.Type) == -1 && consumerServiceRequest.Status !='Parts received' && consumerServiceRequest.Status !='Parts received' && ([3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)==-1 || ([3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)>-1 && showsavebutton)) &&
					<div className="col-xs-4 text-right">
						{accessFn('GSX_FLOW') && <span className="glyphicon glyphicon-edit delete-charges-btn " onClick={() => editAddedCharge(charge)}></span>}
						<span className="glyphicon glyphicon-minus-sign delete-charges-btn" onClick={() => removeAddedCharge(charge)}></span>
					</div>
				} */}
				
			</div>
			{
				['Discount'].indexOf(charge.Type) < 0 && charge.TaxArray && charge.TaxArray.map(tax=>{
					return(
						<>
						{tax.TaxSlab > 0 && <div className='row'>
							<div className="col-xs-8">
								{[3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && <div className="charges-panel-content">{tax.TaxType} ({tax.TaxSlab})%</div>}
								{[3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID) == -1 && <div className="charges-panel-content">{tax.TaxDisplay ? tax.TaxDisplay : tax.TaxType}</div>}
							</div>
							<div className="col-xs-4 text-right">
								<div className="charges-panel-content text-right">
									<span>
										<span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>
										{['WarrantyConcession'].indexOf(charge.Type) > -1 ? "- " + tax.TaxVal : tax.TaxVal}
									</span>
								</div>
							</div>
						</div>
						}
						</>
					)
				})
			}
		</div> }
		{ advanceCharges && <div className='partRow'>
			<div className='row'>
				<div className="col-xs-8">
					<div className="charges-panel-content bold">
						{[3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)==-1 && ['Mandatory deductible', 'Mandatorydeductible', 'Admin fees', 'Claim amount', 'Adminfees', 'RefundAmount'].indexOf(charge.Type) > -1 ? localString(charge.Name) : localString(charge.Remarks)}
						{[3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)>-1 && charge.Type+"-"+charge.Name}
						{showEstimationButtons && (!(charge.Type=='Advance payment' && charge.IsAdvancePaid==1) && (['Claim estimation generated', 'Supporting documents required', 'Parts issued', 'Parts received'].indexOf(consumerServiceRequest.Status) == -1) && ([3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)==-1 || ([3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)>-1 && showsavebutton)) && allowChargeDeletion(charge.Type)) && (!(accessFn('ADD_PART_POST_APPROVAL') && consumerServiceRequest.allowAddPart)) && <span className="glyphicon glyphicon-minus-sign editDeleteIcon" onClick={() => removeAddedCharge(charge)}></span>}
					</div>
				</div>
				<div className="col-xs-4 text-right">
					<div className="charges-panel-content text-right bold">
						<span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>
						{charge.Charge}
					</div>
				</div>
				{/* {showEstimationButtons && <div className="col-xs-4 text-right">
					{(!(charge.Type=='Advance payment' && charge.IsAdvancePaid==1) && ([3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)==-1 || ([3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)>-1 && showsavebutton)) && allowChargeDeletion(charge.Type)) && <span className="glyphicon glyphicon-minus-sign delete-charges-btn" onClick={() => removeAddedCharge(charge)}></span>}
				</div>
				} */}
			</div>
			{
				['Discount'].indexOf(charge.Type) < 0 && charge.TaxArray && charge.TaxArray.map(tax=>{
					return(
						<>
						{
							tax.TaxSlab > 0 && <div className='row'>
								<div className="col-xs-8">
									{[3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)>-1 && <div className="charges-panel-content">{tax.TaxType} ({tax.TaxSlab})%</div>}
									{[3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID)==-1 && <div className="charges-panel-content">{tax.TaxDisplay ? tax.TaxDisplay : tax.TaxType}</div>}
								</div>
								<div className="col-xs-4 text-right">
									<div className="charges-panel-content text-right">
										<span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>
										{['WarrantyConcession'].indexOf(charge.Type)> -1?"-"+tax.TaxVal:tax.TaxVal}
									</div>
								</div>
							</div>
						}
						</>
					)
				})
			}
		</div> }
	</>
	)
}

export default ChargesRow;
