import React, { useState, useEffect } from "react";
import { Modal, Button, Input, DatePicker } from "sfy-react";
import Swal from "sweetalert2";
import { displayError, returnLocalDateTime } from "../../Utility/Utils";
import ShadowWrapper from "./ShadowWrapper";
import AddRule from "./AddRule";
import FilterInputs from "../../components/FilterInputs/FilterInput";

import "./RuleManager.scss";

const AddRuleModal = (props) => {
	const {
		localString,
		showModal,
		setShowLoader,
		closeModal,
		apiCall,
		getRules,
	} = props;

	const [rulesData, setRulesData] = useState([]);
	const [rulesDetails, setRulesDetails] = useState({
		ruleName: "",
		description: "",
		ruleStartDate: "",
		ruleEndDate: "",
		ruleTransactionSubcategory: "",
		ruleType: "",
	});
	const [riskRealmsList, setRiskRealmsList] = useState([]);

	const addToRulesArray = (data) => {
		const newRulesData = [...rulesData, data];
		setRulesData(newRulesData);
	};

	const removeFromRulesArray = (index) => {
		const newRulesData = [...rulesData];
		newRulesData.splice(index, 1);
		setRulesData(newRulesData);
	};

	const getRiskRealms = () => {
		let data = {};

		setShowLoader(true);
		apiCall({
			url: "getRiskRealms",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					if (result.data) {
						let data = result.data.realms.map((v) => ({
							...v,
							value: v.entity_realm_name,
						}));
						setRiskRealmsList(data);
					}
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const createRiskRule = () => {
		if (rulesDetails.ruleStartDate > rulesDetails.ruleEndDate) {
			Swal.fire({
				icon: "error",
				text: "Rule Start Date can not be after Rule End Date!",
			});

			return;
		}

		let newRulesData = rulesData.map(
			({ RuleEntityRealmID, RuleEntityID, RuleOperator }) => ({
				RuleThreshold: 1.0,
				RuleEntityRealmID,
				RuleEntityID,
				RuleOperator,
			})
		);

		let data = {
			Name: rulesDetails.ruleName,
			Risky: 1,
			Probability: 100,
			Description: rulesDetails.description,
			TransactionSubcategory: rulesDetails.ruleTransactionSubcategory,
			RuleType: rulesDetails.ruleType,
			StartDate: returnLocalDateTime(
				rulesDetails.ruleStartDate,
				"YYYY-MM-DD"
			).split(" ")[0],
			EndDate: returnLocalDateTime(
				rulesDetails.ruleEndDate,
				"YYYY-MM-DD"
			).split(" ")[0],
			Rules: newRulesData,
		};

		if (
			!data.Name ||
			!data.Description ||
			!data.StartDate ||
			!data.EndDate
		) {
			Swal.fire({
				icon: "error",
				text: "Enter all Values!",
			});

			return;
		}

		setShowLoader(true);
		apiCall({
			url: "createRiskRule",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					if (result.data) {
						closeModal();
						getRules();
						Swal.fire({
							icon: "success",
							text: result.data.status.toLocaleUpperCase(),
							//timer: 4000,
						});
					}
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	useEffect(() => {
		getRiskRealms();
	}, []);

	const RuleDetailsInput = [
		{
			labelText: localString("Rule Name"),
			dataKey: "ruleName",
			showFilter: true,
			placeholder: localString("Rule Name"),
			className: { input: "customised-border customised-border-radius" },
			oninputFunc: (val) => {
				setRulesDetails((prevData) => ({
					...prevData,
					ruleName: val,
				}));
			},
		},

		{
			labelText: localString("Description"),
			dataKey: "description",
			showFilter: true,
			placeholder: localString("Description"),
			className: { input: "customised-border customised-border-radius" },
			oninputFunc: (val) => {
				setRulesDetails((prevData) => ({
					...prevData,
					description: val,
				}));
			},
		},
		{
			labelText: localString("Rule Type"),
			dataKey: "ruleType",
			showFilter: true,
			placeholder: localString("Rule Type"),
			className: { input: "customised-border customised-border-radius" },
			oninputFunc: (val) => {
				setRulesDetails((prevData) => ({
					...prevData,
					ruleType: val,
				}));
			},
		},
		{
			labelText: localString("Transaction Subcategory"),
			dataKey: "ruleTransactionSubcategory",
			showFilter: true,
			placeholder: localString("Transaction Subcategory"),
			className: { input: "customised-border customised-border-radius" },
			inputType: "number",
			otherProps: {
				min: "0",
				max: "99",
			},
			oninputFunc: (val) => {
				let prevValue = rulesDetails.ruleTransactionSubcategory;

				if (val >= 0 && val <= 99) {
					setRulesDetails((prevData) => ({
						...prevData,
						ruleTransactionSubcategory: val,
					}));
				} else {
					setRulesDetails((prevData) => ({
						...prevData,
						ruleTransactionSubcategory: prevValue,
					}));
				}
			},
		},
		{
			type: "DatePicker",
			labelText: localString("Start Date"),
			dataKey: "ruleStartDate",
			showFilter: true,
			oninputFunc: (val) => {
				setRulesDetails((prevData) => ({
					...prevData,
					ruleStartDate: val,
				}));
			},
			minDay: new Date(Date.now() - 8640000),
			classes: { input: "customised-border customised-border-radius" },
		},
		{
			type: "DatePicker",
			labelText: localString("End Date"),
			dataKey: "ruleEndDate",
			showFilter: true,
			oninputFunc: (val) => {
				setRulesDetails((prevData) => ({
					...prevData,
					ruleEndDate: val,
				}));
			},
			minDay: new Date(Date.now() - 8640000),
			classes: { input: "customised-border customised-border-radius" },
		},
	];

	return (
		<>
			<Modal
				showModal={showModal}
				closeModal={closeModal}
				showCloseBtn={true}
				className="add-rule-modal"
			>
				<Modal.Header>
					<h2>Add Rule Modal</h2>
				</Modal.Header>
				<Modal.Body>
					<div className="rule-wrapper">
						<div className="row">
							<FilterInputs
								{...props}
								filterInputs={RuleDetailsInput}
								filterData={rulesDetails}
								hideSaveButton={true}
								hideClearButton={true}
							/>
						</div>

						<hr className="divider" />

						<AddRule
							{...props}
							riskRealmsList={riskRealmsList}
							setRiskRealmsList={setRiskRealmsList}
							addToRulesArray={addToRulesArray}
						/>

						{rulesData.map((data, index) => (
							<ShadowWrapper
								key={index}
								classname="rule-details-wrapper"
							>
								<div className="rule-details">
									<div className="">{data.RuleVariable}</div>
									<div className="">{data.RuleOperator}</div>
									<div className="">{data.RuleEntityID}</div>

									<div>
										<Button
											variant="outline-danger"
											onClick={() =>
												removeFromRulesArray(index)
											}
										>
											<span className="glyphicon glyphicon-minus-sign"></span>
										</Button>
									</div>
								</div>
							</ShadowWrapper>
						))}

						<div className="add-rule-btns">
							<Button
								type="button"
								classes="button-primary button-small m-top-15 mx-4"
								handleOnClick={() => {
									createRiskRule();
								}}
							>
								Add Rule
							</Button>

							<Button
								type="button"
								classes="button-primary button-small m-top-15 mx-4"
								handleOnClick={() => {
									closeModal();
								}}
							>
								Cancel
							</Button>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
};

export default AddRuleModal;
