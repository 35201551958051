import React from 'react';
import {Table} from 'sfy-react';
import './TransferDetails.scss';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';


const TransferDetails = (props) => {
	const {consumerServiceRequest,localString} = props;
	const classObject = {
		table:"table table-striped complaint-details-table request-table"
	}
	return (
		<div className="inner-padding">
				{
					<div className="row">
						<PageContentWrapper title={localString("Transfer Details")}>
						<div className="request-panel request-complaint-div transfer-details-tab">
						    {
                            (!consumerServiceRequest.deviceTransferData.forwardTransferRemark && !consumerServiceRequest.deviceTransferData.reverseTransferRemark) &&		                    
							<div className="charges-panel-subheader"> {localString("No Transfer details")} </div>
		                    }
								<Table className={classObject}>
									<Table.Body>
										{
											consumerServiceRequest.deviceTransferData.forwardTransferRemark &&
											<Table.Row>
													<Table.Data className="table-keys text-capitalize"> {localString("Forward transfer remarks")} </Table.Data>
													<Table.Data>{consumerServiceRequest.deviceTransferData.forwardTransferRemark}</Table.Data>
												</Table.Row>
                                        }
										{
											consumerServiceRequest.deviceTransferData.reverseTransferRemark &&
											<Table.Row>
													<Table.Data className="table-keys text-capitalize"> {localString("Reverse transfer remarks")} </Table.Data>
													<Table.Data>{consumerServiceRequest.deviceTransferData.reverseTransferRemark}</Table.Data>
												</Table.Row>
                                        } 
									</Table.Body>
								</Table>					
						</div>
						</PageContentWrapper>
					</div>
				}
		</div>
	)
}

export default TransferDetails