import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import { findIndex,find,cloneDeep,filter,isEmpty,differenceBy} from 'lodash';
import {displayError,SERVICETYPE} from '../../../../../Utility/Utils';
import IssueValidationNonWorkshop from './IssueValidationNonWorkshop';
import IssueValidationWorkshop from './IssueValidationWorkshop';
import IssueValidationGSX from './IssueValidationGSX';

const IssueValidation = (props) => {
	const {canUpdateServiceRequest,consumerServiceRequest,localString,accessFn,apiCall,isRequestSFANeeded,qFilter,getRequestById,addedFaultActions,setConsumerServiceRequest} = props;
	const [editIssues, setEditIssues] = useState(false)
	const [loader, setLoader] = useState(false);
	const [isReportedLength, setIsReportedLength] = useState(0);
	const [isValidatedLength, setIsValidatedLength] = useState(0);
	const [issues, setIssues] = useState("")
	const [deviceDetailsEditable, setDeviceDetailsEditable] = useState()
	const [engineerHasDeviceLog, setEngineerHasDeviceLog] = useState("")
	const [disabeAddSymptom, setDisabeAddSymptom] = useState(true)
	const [issueValidity, setIssueValidity] = useState()
	const [warrantyValidity, setwarrantyValidity] = useState()
	const [updateFreshData, setUpdateFreshData] = useState(true)
	const [customerReportedSFA, setCustomerReportedSFA] = useState([])
	const [engineerReportedSFA, setEngineerReportedSFA] = useState([])
	const [disableSFASave, setDisableSFASave] = useState(true)
	const [getSymptomCodesCalled, setGetSymptomCodesCalled] = useState(false)
	const [showCustomerOptions, setShowCustomerOptions] = useState(false)
	const [allSymptoms, setAllSymptoms] = useState([])
	const [compModList, setCompModList] = useState([])
	const [reportedIssues, setReportedIssues] = useState([])
	const [getCompModListCalled, setGetCompModListCalled] = useState(false)
	const [symptomFaultFaultActionMappings, setSymptomFaultFaultActionMappings] = useState([])
	let getFaultActionMappingCalled;
	const [disableValidate, setDisableValidate] = useState(true)
	const [selectedAction, setSelectedAction] = useState("")
	const defaultObjTECH = {
		symptomcodeTECH:"",
		issuecodeTECH:"",
		comptia_mod_RepairTECH:""
	}
	const defaultObjCUST = {
		symptomcodeCUST:"",
		issuecodeCUST:"",
		comptia_mod_RepairCUST:""
	}
	const [issuesList, setIssuesList] = useState([])
	const [symptomList, setSymptomList] = useState([])
	const [engineerCodes, setEngineerCodes] = useState([defaultObjTECH])
	const [customerCodes, setCustomerCodes] = useState([defaultObjCUST])
	const [showIssueCode, setShowIssueCode] = useState(true)
	const [csrIssuesArr, setcsrIssuesArr] = useState(consumerServiceRequest.issues)
	const [customerIssueList, setCustomerIssueList] = useState([])
	const [engineerIssueList, setEngineerIssueList] = useState([])
	const [issueSelectedForAddition, setIssueSelectedForAddition] = useState({})
	const [additionalIssues, setAdditionalIssues] = useState({})

	useEffect(() => {
		if(!isEmpty(issueSelectedForAddition)){
			setDisabeAddSymptom(false)
		}
	}, [issueSelectedForAddition])

	useEffect(() => {
		if(consumerServiceRequest && consumerServiceRequest.issues) {
			let reported = 0;
			let validate= 0;
			let arr = consumerServiceRequest.issues;
			for (let i = 0; i < arr.length; i++) {
				if (arr[i].IsReported) {
					if (arr[i].IsValidated) {
						validate++
					}
					// if(consumerServiceRequest.issues[i].IssueText == 'Other'){
					//     self.otherIssueText = self.parent.otherIssueText
					//   //self.update()
					// }
					reported++
				}
			}
			setIsValidatedLength(validate)
			setIsReportedLength(reported)
		}
		if(accessFn("WORKSHOP_FLOW") && !accessFn("GSX_FLOW")){
			let deviceAcceptedLog;
				// deviceAcceptedLog = _.find(opts.csr.logs, {
				//     Status: 'Device accepted',
				//     DestinationType: 'ServicelocationEngineer'
				// });
	
				let currentRole = getCurrentRole();
	
				if (currentRole === 'CustomerCare') {
					setDeviceDetailsEditable(true)
					// self.deviceDetailsEditable = true;========>  // NOT IN USE
				} else {
					setDeviceDetailsEditable(false)
					// self.deviceDetailsEditable = false;
				}
				if (currentRole === 'Supervisor') {
					setDeviceDetailsEditable(true)
					// self.deviceDetailsEditable = true;
				} else {
					setDeviceDetailsEditable(false)
					// self.deviceDetailsEditable = false;
				}
				if (deviceAcceptedLog) {
					if (currentRole === 'ServicelocationEngineer') {
						setDeviceDetailsEditable(true)
					// self.deviceDetailsEditable = true;
					}
				} else {
					if (currentRole === 'ServicelocationEngineer') {
						setDeviceDetailsEditable(false)
					// self.deviceDetailsEditable = false;
					}
				}
	
				//NOT IN USE  ===>

				// let consumerServiceRequestLogsSorted = _.filter(self.csrid.logs, function(csrl) {
				//     return (csrl.ConsumerServiceRequestLogID != undefined);
				// });
				// self.consumerServiceRequestLogsSorted = _.orderBy(self.consumerServiceRequestLogsSorted, [function(o) {
				//     return moment(o.StatusDate).unix();
				// }], ['desc']);
	
	
				let deviceAccept = {};
				let csrLogs = consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.length;
				for (let iCount = 0; iCount < csrLogs; iCount++) {
					if (consumerServiceRequest.logs[iCount].Status == 'Device accepted') {
						deviceAccept = consumerServiceRequest.logs[iCount]
						break;
					}
				}
				// let deviceAcceptedLog
				if (deviceAccept.DestinationType == 'ServicelocationEngineer') {
					// self.engineerHasDeviceLog = deviceAccept
					setEngineerHasDeviceLog(deviceAccept)
				}
	
				// NOT IN USE ====>

				// self.param = {
				//     updatedData: [],
				//     ConsumerServiceRequestID: this.csrid.ConsumerServiceRequestID
				// }
				// _.forEach(this.csrid.issues, function(issue) {
				//     if (issue.ProductSubCategoryIssueID && issue.ProductSubcategoryIssue) {
				//     self.param.updatedData.push({
				//         ConsumerServiceRequestIssuesID: issue.ConsumerServiceRequestIssuesId,
				//         Issue: issue.IssueText,
				//         ProductSubCategoryIssueID: issue.ProductSubCategoryIssueID,
				//         IssueID: issue.ProductSubcategoryIssue.IssueMasterID
				//     });
				//     }
				// });
				// self.actionLists = {};
				// self.idToaction = {};
	
				// self.status = 0;
				// //debugger
				// if(self.csrid && self.csrid.ConsumerServiceRequestID && self.csrid.issues.length > 0 && self.csrid.issues[0].IsValidated){
				//     //debugger
				//     self.status = self.csrid.IsWarrantyApplicable?1:2;
				// }
	
		}
		if(accessFn("GSX_FLOW")){
			let deviceAccept = {};
			let csrLogs = consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.length;
			for (let iCount = 0; iCount < csrLogs; iCount++) {
				if (consumerServiceRequest.logs[iCount].Status == 'Device accepted') {
					deviceAccept = consumerServiceRequest.logs[iCount]
					break;
				}
			}
			if (deviceAccept.DestinationType == 'ServicelocationEngineer') {
				setEngineerHasDeviceLog(deviceAccept)
			}

			let gsxRepairSymtomsIssues = consumerServiceRequest && consumerServiceRequest.gsxRepairSymtomsIssues;

			if (gsxRepairSymtomsIssues && gsxRepairSymtomsIssues.length > 0 && updateFreshData) {
				let custReportedSFA = cloneDeep(filter(gsxRepairSymtomsIssues, function(o) { return o.Type == 'CUST'}));
				setCustomerReportedSFA(custReportedSFA)
				let enggReportedSFA = cloneDeep(filter(gsxRepairSymtomsIssues, function(o) { return o.Type == 'TECH'}));
				setEngineerReportedSFA(enggReportedSFA)
			}

		}
	}, [consumerServiceRequest])

	useEffect(() => {
		setcsrIssuesArr(consumerServiceRequest.issues)
	}, [consumerServiceRequest.issues])

	useEffect(() => {
		if(accessFn("GSX_FLOW")){
			let engineer =[];
			let customer =[];
			let customerSFA = customerReportedSFA && customerReportedSFA;
			for(let i=0 ; i < customerSFA.length > 0 ; i++){
				if(customerSFA[i].ReportedIssue && customerSFA[i].ReportedIssue.reportedIssueCode){
					customer.push(customerSFA[i].ReportedIssue)
				}
			}

			let enggSFA = engineerReportedSFA && engineerReportedSFA;
			for(let i=0 ; i<enggSFA.length > 0; i++){
				if(enggSFA[i].ReportedIssue && enggSFA[i].ReportedIssue.reportedIssueCode){
					engineer.push(enggSFA[i].ReportedIssue)
				}
			}
			setEngineerIssueList(engineer)
			setCustomerIssueList(customer)
		}
	}, [customerReportedSFA,engineerReportedSFA])

	useEffect(() => {
		if(engineerReportedSFA && engineerReportedSFA.length && !isEmpty(engineerReportedSFA[0].ReportedSymptom) && !isEmpty(engineerReportedSFA[0].ReportedIssue) && !isEmpty(engineerReportedSFA[0].ComptiaModifier) ){
			setDisableSFASave(false)
		}else{
			setDisableSFASave(true)
		}
	}, [engineerReportedSFA])
	const warrantyApplicableCheck = ()=> {
		return consumerServiceRequest && (consumerServiceRequest.IsWarrantyApplicable == null || undefined) && (['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) == -1) && ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) ? false : true;
	};

	const coveredUnderPlanCheck = ()=> {
		return consumerServiceRequest && consumerServiceRequest.EWRequest ? ((consumerServiceRequest.IsPlanEligible != null || undefined) ? true : false) : true;
	};

	const toggleCustomerOptions =()=>{
		setShowCustomerOptions(!showCustomerOptions)
	}

	const isClaimEstimationApproved = () => {
		var estimationApproved = consumerServiceRequest.logs.find((obj) => obj.Status == 'Claim estimation approved');
		return estimationApproved ? true : false;
	}

	const editIssuesClick =  (e)=> {
		setEditIssues(true)
		if(!accessFn("WORKSHOP_FLOW") && !accessFn("GSX_FLOW")){
			loadIssues();
		}
		if(accessFn("WORKSHOP_FLOW")){
			loadSymptoms();
		}
		if(accessFn("GSX_FLOW")){
			setUpdateFreshData(true)
			
			let customerSFA = [...customerReportedSFA]
			let custArr = []
			customerSFA && customerSFA.map((temp)=>{
				let cust = {...temp}
				delete cust.issuecodeTECH
				delete cust.symptomcodeTECH
				delete cust.comptia_mod_RepairTECH
				custArr.push(cust)
			})
			setCustomerReportedSFA(custArr)

			let engineerSFA = [...engineerReportedSFA]
			let enggArr = []
			engineerSFA && engineerSFA.map((temp)=>{
				let engg = {...temp}
				delete engg.issuecodeTECH
				delete engg.symptomcodeTECH
				delete engg.comptia_mod_RepairTECH
				enggArr.push(engg)
			})
			setEngineerReportedSFA(enggArr)

			if(isReportedLength == isValidatedLength){
				IntialiseSymptomsDropdown();
			}
		}
	};

	const IntialiseSymptomsDropdown = ()=>{
		setUpdateFreshData(false)
		setDisableSFASave(true)
		if(!customerReportedSFA || (customerReportedSFA.length < 1)){
			setShowCustomerOptions(false)
			setCustomerReportedSFA([
				{
					ReportedSymptom:{},
					ReportedIssue:{},
					ComptiaModifier:{},
					Type:"CUST",
					Priority:1
				}
			])
		}else{
			setShowCustomerOptions(true)
			let cust = customerReportedSFA && customerReportedSFA.map((item,i)=>{
				let obj = item;
				obj.symptomcodeCUST = (customerReportedSFA[i].ReportedSymptom.reportedSymptomCode+' - '+customerReportedSFA[i].ReportedSymptom.reportedSymptomDesc || "");
				obj.issuecodeCUST = (customerReportedSFA[i].ReportedIssue.reportedIssueCode+' - '+customerReportedSFA[i].ReportedIssue.reportedIssueDesc || "");
				obj.comptia_mod_RepairCUST = (customerReportedSFA[i].ComptiaModifier.modifierCode+' - '+customerReportedSFA[i].ComptiaModifier.comptiaDescription || "");
				// obj.symptomcodeCUST = "";
				// obj.issuecodeCUST = "";
				// obj.comptia_mod_RepairCUST= "";
				return obj
			})
			setCustomerCodes(cust)
		}
		if(!engineerReportedSFA || engineerReportedSFA.length < 1){
			setEngineerReportedSFA([{...engineerReportedSFA,
					ReportedSymptom:{},
					ReportedIssue:{},
					ComptiaModifier:{},
					Type:"TECH",
					Priority:1,
				}
			])
		}else{
			let engg = engineerReportedSFA && engineerReportedSFA.map((item,i)=>{
				let obj = item;
				obj.symptomcodeTECH = (engineerReportedSFA[i].ReportedSymptom.reportedSymptomCode+' - '+engineerReportedSFA[i].ReportedSymptom.reportedSymptomDesc || "");
				obj.issuecodeTECH = (engineerReportedSFA[i].ReportedIssue.reportedIssueCode+' - '+engineerReportedSFA[i].ReportedIssue.reportedIssueDesc || "");
				obj.comptia_mod_RepairTECH = (engineerReportedSFA[i].ComptiaModifier.modifierCode+' - '+engineerReportedSFA[i].ComptiaModifier.comptiaDescription || "");
				// obj.symptomcodeTECH = "";
				// obj.issuecodeTECH = "";
				// obj.comptia_mod_RepairTECH = "";
				return obj
			})
			setEngineerCodes(engg)
		}
		if (accessFn('GSX_FLOW') && consumerServiceRequest && consumerServiceRequest.consumerProduct && (consumerServiceRequest.consumerProduct.isAppleBrandID)) {
			if(!getSymptomCodesCalled){
				getSymptomCodes();
			}else{
				let data = allSymptoms.map(v => ({...v, value: v.reportedSymptomCode + ' - ' + v.reportedSymptomDesc}))
				setAllSymptoms(data)
			}
			if(!getCompModListCalled){
				getCompModList();
			}else{
				let data = compModList.map(v => ({...v, value: v.modifierCode +" - "+ v.comptiaDescription}))
				setCompModList(data)
			}
			if(!getFaultActionMappingCalled){
				getFaultActionMapping();
			}else{
				let data = symptomFaultFaultActionMappings.map(v => ({...v, value: v.FaultActionCode + ' - ' + v.Name}))
				setSymptomFaultFaultActionMappings(data)
			}
			if(addedFaultActions && addedFaultActions.length > 0){
				let fault = addedFaultActions[0].ActionCode+' - '+addedFaultActions[0].ActionDescription
				setSelectedAction({value:fault})
			}
		}
	}

	const cancelIssuesClick = ()=> {
		setEditIssues(false)
		if(accessFn("WORKSHOP_FLOW")){
			setSymptomList("")
		}
		if(accessFn("GSX_FLOW")){
			setUpdateFreshData(true)
			setSelectedAction({})
			let gsxRepairSymtomsIssues = consumerServiceRequest && consumerServiceRequest.gsxRepairSymtomsIssues;

			if (gsxRepairSymtomsIssues && gsxRepairSymtomsIssues.length > 0) {
				let custReportedSFA = cloneDeep(filter(gsxRepairSymtomsIssues, function(o) { return o.Type == 'CUST'}));
				if(custReportedSFA && custReportedSFA.length) {
					setCustomerReportedSFA(custReportedSFA);
				}else {
					setCustomerReportedSFA([]);
				}
				let enggReportedSFA = cloneDeep(filter(gsxRepairSymtomsIssues, function(o) { return o.Type == 'TECH'}));
				if(enggReportedSFA && enggReportedSFA.length) {
					setEngineerReportedSFA(enggReportedSFA);
				}else {
					setEngineerReportedSFA([]);
				}
			}else {
				setCustomerReportedSFA([]);
				setEngineerReportedSFA([]);
			}
			setEngineerCodes([]);
			setCustomerCodes([]);
		}
	}

	const changePriority =(type,index)=>{
		if(type == 'CUST'){ 
			let customerRepo = [...customerReportedSFA]
			let arr = []
			let tempPriority = 1;
			customerRepo.map((item,k)=>{
				let obj = {...item}
				if(k!=index){
					tempPriority = tempPriority+1;
					obj.Priority = tempPriority
				}else{
					obj.Priority = 1
				}
				arr.push(obj)
			})
			setCustomerReportedSFA(arr)
		}else{
			let engineerRepo = [...engineerReportedSFA]
			let arr = []
			let tempPriority = 1;
			engineerRepo.map((item,k)=>{
				let obj = {...item}
				if(k!=index){
					tempPriority = tempPriority+1;
					obj.Priority = tempPriority
				}else{
					obj.Priority = 1
				}
				arr.push(obj)
			})
			setEngineerReportedSFA(arr)
		}
		validateSFA();
	}

	const isDiagnosisCompleted = ()=> {
		if(accessFn('GSX_FLOW')){
			let diagInit = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis initiated',PerformedByRole:'ServicelocationEngineer'});
			let diagComp = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis completed',PerformedByRole:'ServicelocationEngineer'});
			let cannotDiag = findIndex(consumerServiceRequest.logs, {Status: 'Cannot be diagnosed',PerformedByRole:'ServicelocationEngineer'});
			if (diagInit != -1) {
				if (diagComp != -1) {
					return diagComp < diagInit;
				}
				if (cannotDiag != -1) {
					return cannotDiag < diagInit
				}
				return false
			}
			return false
		}else if(!accessFn('GSX_FLOW')){
			let diagInit = findIndex(consumerServiceRequest && consumerServiceRequest.logs, { Status: 'Diagnosis initiated' });
			let diagComp = findIndex(consumerServiceRequest && consumerServiceRequest.logs, { Status: 'Diagnosis completed' });
			let cannotDiag = findIndex(consumerServiceRequest && consumerServiceRequest.logs, { Status: 'Cannot be diagnosed' });
			if (diagInit != -1) {
				if (diagComp != -1) {
					return diagComp < diagInit;
				}
				if (cannotDiag != -1) {
					return cannotDiag < diagInit
				}
				return false
			}
			return true
		}
		
	}

	const checkIfEngineerVisitCreated = () => {
		let serviceManReached = consumerServiceRequest.logs.find((obj) => (obj.Status == 'Serviceman reached'|| obj.Status == 'Service accepted'));
		let engineerApplicable = consumerServiceRequest && consumerServiceRequest.servicelocation && consumerServiceRequest.servicelocation.EngineerApplicable;
        return [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? (((consumerServiceRequest.ServiceLocationEngineerID && engineerApplicable) || !engineerApplicable) && serviceManReached ? true : false) : true; 
	};

	const collectIssues = (val,item)=> {
		let issueArr = [...csrIssuesArr];

		for (let i in issueArr) {
			if (issueArr[i].ConsumerServiceRequestIssuesId == item.ConsumerServiceRequestIssuesId) {
				if(!accessFn("WORKSHOP_FLOW") && !accessFn("GSX_FLOW")){
					issueArr[i].IsValidated = !item.IsValidated
					break; 
				}else{
					issueArr[i].validityStatus = Number(val);
					break; 
				}
				
			}
		}
		setcsrIssuesArr(issueArr)
	
		for(let i =0;i<issueArr.length;i++){
			setDisableValidate(false)
			if(issueArr[i].validityStatus == 0){
				setDisableValidate(true)
				break;
			}
		}
		
	};

	const validate = () => {
		let issuesToValidate = [...csrIssuesArr];
		for (let iCount=0;iCount<issuesToValidate.length;iCount++) {
			if(accessFn("WORKSHOP_FLOW") || accessFn("GSX_FLOW")){
				if (issuesToValidate[iCount].validityStatus == 0 && !issuesToValidate[iCount].IsValidated) {
					Swal.fire({
						icon:"info",
						text:localString('Please validate all the issues')
					})
					return;
				}
			}else if(!accessFn("WORKSHOP_FLOW") && !accessFn("GSX_FLOW")){
				if (issuesToValidate[iCount].IsValidated) {
					issuesToValidate[iCount].validityStatus = 1
				} else {
					// pass
				}
			}else if(accessFn("GSX_FLOW")){
				if (!issuesToValidate[iCount].IsValidated) {
					if (issuesToValidate[iCount].validityStatus == 0) {
						issuesToValidate[iCount].validityStatus = 2
					}
				}
			}
		}
		let data = {
			issues: issuesToValidate, 
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
		};
		setLoader(true);
		apiCall({
			url:'validateIssues',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRequestById()
					if(accessFn("WORKSHOP_FLOW")){
						setEditIssues(false)
					}
					if(accessFn("GSX_FLOW")){
						setEditIssues(true)
						let reported = 0;
						let validate = 0;
						if(result.data && result.data.updatedIssues) {
							let arr = result.data.updatedIssues;
							for (let i = 0; i < arr.length; i++) {
								if (arr[i].IsReported) {
									if (arr[i].IsValidated) {
										validate++
									}
									reported++
								}
								
							}
						}
						if(reported == validate){
							IntialiseSymptomsDropdown();      
						}
						setIsValidatedLength(validate)
						setIsReportedLength(reported)
					}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const loadSymptoms = () => {
		let data = {
			ProductSubCategoryID: consumerServiceRequest.consumerProduct.ProductSubCategoryID,
			BrandID: consumerServiceRequest.BrandID,
		};
		if(consumerServiceRequest.consumerProduct){
			data.BrandID = consumerServiceRequest.consumerProduct.BrandID;
		}
		setLoader(true);
		apiCall({
			url:'getSymptoms',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let symptoms = result.data && result.data && result.data.symptoms.map(v=>({...v,value:v.SymptomCode + ' - ' + v.SymptomDescription})) 
					setSymptomList(symptoms)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const loadIssues = () => {
		let data = {
			ProductSubCategoryID: consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID,
			BrandID: consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID,
			ProductID: consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductID
		};
		
		setLoader(true);
		apiCall({
			url:'getIssueList',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let issuelist =  result.data && result.data.map(v=>({...v,value:v.Issue})) 
					setIssuesList(issuelist)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const addSymptomCode = () => {
		let symptom = [];
		if(consumerServiceRequest && consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0) {
			let isSymptomAlreadyAdded = find(consumerServiceRequest.Symptoms, function(o) {
				return (o.SymptomID == issueSelectedForAddition.SymptomID)
			})
	
			if (isSymptomAlreadyAdded) {
				Swal.fire({
					icon:"error",
					text:localString('Symptom already added by the engineer'),
				})
				return;
			}
		}
		delete issueSelectedForAddition.value
		symptom.push(issueSelectedForAddition)
		
		let data = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"symptoms": symptom
		}
		
		setLoader(true);
		apiCall({
			url:'addSymptoms',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					setSymptomList("")
					getRequestById();
					setEditIssues(false);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initIssueCode = (type,i,symtomCode) =>{
		let reportedSymptomCode;
		if(type== "CUST" && symtomCode){
			// let loader = customerReportedSFA && customerReportedSFA.map(val=>({...val,showIssueCodeLoader : true}))
			// setCustomerReportedSFA(loader)
			reportedSymptomCode = symtomCode
		}else{
			// let loader = engineerReportedSFA && engineerReportedSFA.map(val=>({...val,showIssueCodeLoader : true}))
			// setEngineerReportedSFA(loader)
			reportedSymptomCode = symtomCode
		}
		let data = {
			"reportedSymptomCode": reportedSymptomCode,
			"ConsumerProductID": consumerServiceRequest.consumerProduct.ConsumerProductID,
			"serialNumber": consumerServiceRequest.consumerProduct.AlternateUniqueKey?consumerServiceRequest.consumerProduct.AlternateUniqueKey:consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue
		}
		setLoader(true);
		apiCall({
			url:'fetchReportedIssues',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let activeIssues = result.data;
					let remainingIssues = [...activeIssues];
					if(type=='CUST'){
						remainingIssues = differenceBy(remainingIssues, customerIssueList, 'reportedIssueCode');
					}else{
						remainingIssues = differenceBy(remainingIssues, engineerIssueList, 'reportedIssueCode');
					}
					let data = remainingIssues && remainingIssues.map(v => ({...v, value:v.reportedIssueCode + ' - ' + v.reportedIssueDesc}))
					setReportedIssues(data)
					// // ====Loader false ====
					// if(customerReportedSFA[i] && customerReportedSFA[i].showIssueCodeLoader){
					//     customerReportedSFA[i].showIssueCodeLoader = false;
					// }
					// if(engineerReportedSFA[i] && engineerReportedSFA[i].showIssueCodeLoader){
					//     // engineerReportedSFA[i].showIssueCodeLoader = false;
					// }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
		// validateSFA()
	}

	const deleteSymptoms = (item) => {
		let data = {
			"ConsumerServiceRequestSymptomID": item.ConsumerServiceRequestSymptomID
		}
		setLoader(true);
		apiCall({
			url:'deleteSymptoms',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRequestById()
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const deleteIssue = (item) => {
		let data = {
			ConsumerServiceRequestIssuesId: item.ConsumerServiceRequestIssuesId
		}
		setLoader(true);
		apiCall({
			url:'deleteIssue',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRequestById()
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const add = () =>{
		let issue = {...additionalIssues};
		let newIssueArr=[]

		if (!(issue && issue.ProductSubCategoryIssueID)) {
			setAdditionalIssues("")
			Swal.fire({
				text:localString('Choose from dropdown list only'),
				icon:"error"
			})
			return;
		}
		if(consumerServiceRequest && consumerServiceRequest.issues && consumerServiceRequest.issues.length > 0) {
			let isAlreadyAdded = find(consumerServiceRequest.issues, function(o) {
				return (o.ProductSubCategoryIssueID == additionalIssues.ProductSubCategoryIssueID)
			})

			if (isAlreadyAdded) {
				Swal.fire({
					icon:"error",
					text:localString('You have already added this issue'),
				})
				return;
			}else {
				if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
					issue.IssueText = additionalIssues.Issue;
					issue.IsReported = true;
					issue.IsValidated = false;
					issue.validityStatus = 0;
				} else {
					issue.IssueText = additionalIssues.Issue;
					issue.IsReported = false;
					issue.IsValidated = false;
					issue.validityStatus = 1;
				}
				delete issue.value
				newIssueArr.push(issue)
				let data = {
					ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
					Issues: newIssueArr
				};
				setLoader(true);
				apiCall({
					url:'addMoreIssues',
					data: data,
					callBack:(result)=>{
						setLoader(false);
						if(result.success){
							setAdditionalIssues("")
							getRequestById();
						}else{
							Swal.fire({
								icon: 'error',
								text: localString(displayError(result)),
							});
						}
					}
				});
			}

		}
	};

	const getIssuesValue=(e)=> {
		// if (e.target.value === '') {
		//     // Issue = undefined;
		//     setIssuesList("")
		// }else{
		//     setIssuesList(e.target.value)
		// }
	}

	const getCurrentRole =()=> {
		let role;
		if (qFilter) {
			if (qFilter.hasUnrestrictedAccess) {
				role = 'Superadmin';
			} else {
				switch (true) {
				case (accessFn('ACCEPT_RETURN_DEVICE')):
					role = 'CustomerCare';
					break;
				case (accessFn('STORE_MANAGEMENT')):
					role = 'StoreManager';
					break;
				case (accessFn('REASSIGN_JOBS')):
					role = 'Supervisor';
					break;
				case (accessFn('REPAIR_DEVICE')):
					role = 'ServicelocationEngineer';
					break;
				case (accessFn('QC_DEVICE')):
					role = 'QCEngineer';
					break;
				}
				return role;
			}
		}
		return role;
	}

	// things need to clear ======>

	const getCompModList = () => {
		setGetSymptomCodesCalled(true)
		// self.parent.tabLoader = true;
		setLoader(true);
		apiCall({
			url:'fetchCompTiaModifiers',
			data: {},
			callBack:(result)=>{
				setLoader(false);
				// self.parent.tabLoader = false;
				if (result.success) {
					let data = result.data.map(v => ({...v, value:v.modifierCode+" - "+v.comptiaDescription}))
					setCompModList(data)
					for(let i = 0;i< customerReportedSFA && customerReportedSFA.length;i++){
						initCompModDropDown('CUST',i);
					}
					for(let i = 0;i< engineerReportedSFA && engineerReportedSFA.length;i++){
						initCompModDropDown('TECH',i);
					}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initCompModDropDown =  (type,i)=> {
		// let listLength = self.compModList.length;
		// self.previousCompModItem = "";
		// $('#comptia_mod_Repair'+type+i).typeahead('destroy');
		// $('#comptia_mod_Repair'+type+i).typeahead({
		// 	source: self.compModList,
		// 	displayText: function(item) {
		// 	return item.modifierCode+" - "+item.comptiaDescription;
		// 	},
		// 	items: 15,
		// 	autoSelect: false,
		// 	showHintOnFocus: true,
		// 	afterSelect: function(item) {
		// 		self.updateFreshData = false;
		// 		if(type=='CUST'){
		// 			self.customerReportedSFA[i].ComptiaModifier = item;
		// 		}else{
		// 			self.engineerReportedSFA[i].ComptiaModifier = item;
		// 		}
		// 		self.validateSFA();
		// 		self.update();
		// 	}
		// }).on("input", function(e) {
		// 	var ispresent = false;
		// 	self.CompModItem = false;
		// 	for (var i = self.compModList.length - 1; i >= 0; i--) {
		// 	if (((self.compModList[i].comptiaDescription).toLowerCase()).indexOf(e.target.value.toLowerCase()) > -1 || e.target.value === '') {
		// 		ispresent = true;
		// 		break;
		// 	}
		// 	};
		// 	if (!ispresent) {
		// 		$('#comptia_mod_Repair'+type + i).val(self.previousCompModItem);
		// 	} else {
		// 		self.previousCompModItem = e.target.value;
		// 	}
		// });
		// self.update();
	}

	const getSymptomCodes = () => {
		setGetSymptomCodesCalled(true)
		// self.parent.tabLoader = true;

		let data = {
			"ConsumerProductID": consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ConsumerProductID
		};
		setLoader(true);
		apiCall({
			url:'fetchReportedSymptoms',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				// self.parent.tabLoader = false;
				if (result.success) {
					let data = result.data.map(v => ({...v, value:v.reportedSymptomCode + ' - ' + v.reportedSymptomDesc}))
					setAllSymptoms(data)
					for(let i = 0;i< customerReportedSFA && customerReportedSFA.length;i++){
						initSymptomCode('CUST',i);
					}
					for(let i = 0;i<engineerReportedSFA && engineerReportedSFA.length;i++){
						initSymptomCode('TECH',i);
					}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initSymptomCode = (type,i)=> {
		// self.previousSymptom = "";
		// $('#symptomcode'+type + i).typeahead('destroy');
		// $('#symptomcode'+type + i).typeahead({
		// 	source: self.allSymptoms,
		// 	displayText: function(item) {
		// 		return item.reportedSymptomCode + ' - ' + item.reportedSymptomDesc
		// 	},
		// 	items:self.allSymptoms.length,
		// 	autoSelect: false,
		// 	showHintOnFocus: true,
		// 	afterSelect: function(item) {
		// 		self.updateFreshData = false;
		// 		if(type=='CUST'){
		// 			self.customerReportedSFA[i].ReportedSymptom = item;
		// 			self.customerReportedSFA[i].ReportedIssue = {};
		// 		}else{
		// 			self.engineerReportedSFA[i].ReportedSymptom = item;
		// 			self.engineerReportedSFA[i].ReportedIssue = {};
		// 		}
		// 		$("#issuecode"+ type+ i).prop('disabled', true)
		// 		$("#issuecode"+ type+ i).val('');
		// 		self.initIssueCode(type,i)
		// 		self.validateSFA();
		// 	}
		// }).on("input", function(e) {
		// 	var ispresent = false;
		// 	//self.selectedSymptom = false;
		// 	$("#issuecode"+ type+ i).prop('disabled', true)
		// 	$("#issuecode"+type + i).val('')
		// 	for (var iCount = 0;iCount<self.allSymptoms.length;iCount++) {
		// 		if ((self.allSymptoms[iCount].reportedSymptomCode + ' ' + self.allSymptoms[iCount].reportedSymptomDesc).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 || e.target.value == '') {
		// 			ispresent = true;
		// 			break;
		// 		}
		// 	};
		// 	if (!ispresent) {
		// 		$('#symptomcode'+type+ i).val(self.previousSymptom);
		// 	} else {
		// 		self.previousSymptom = e.target.value;
		// 	}
		// 	self.update();
		// });
	}

	const getFaultActionMapping = () => {
		getFaultActionMappingCalled = true;
		let data = {
			"BrandID": consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID,
			"PartnerServiceLocationID":consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID
		};
		// self.parent.tabLoader = true;
		setLoader(true);
		apiCall({
			url:'getSymptomFaultFaultActionMappingsGSX',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				// self.parent.tabLoader = false;
				if (result.success) {
					if (result.data) {
						let data = result.data.data.faultActions.map(v => ({...v, value:v.FaultActionCode + ' - ' + v.Name}))
						setSymptomFaultFaultActionMappings(data)
					}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const saveSFAMappings = (e)=> {
		e.preventDefault();
		let gsxRepairSymtomsIssuesArray = [];
		customerReportedSFA && customerReportedSFA.map((cust)=>{
			let newObj ={}
			Object.keys(cust).map((obj)=>{
				if(["ComptiaModifier","ReportedIssue","ReportedSymptom"].includes(obj) && !isEmpty(cust[`${obj}`])){
					newObj = {...cust}
					if(cust[`${obj}`].value){
						delete cust[`${obj}`].value
					}
					newObj[`${obj}`] = cust[`${obj}`]
				}
			})
			if(!isEmpty(newObj)){
				gsxRepairSymtomsIssuesArray.push(newObj)
			}
		})
		engineerReportedSFA && engineerReportedSFA.map((engg)=>{
			let newObj ={}
			Object.keys(engg).map((obj)=>{
				if(["ComptiaModifier","ReportedIssue","ReportedSymptom"].includes(obj) && !isEmpty(engg[`${obj}`])){
					newObj = {...engg}
					if(engg[`${obj}`].value){
						delete engg[`${obj}`].value
					}
					newObj[`${obj}`] = engg[`${obj}`]
				}
			})
			if(!isEmpty(newObj)){
				gsxRepairSymtomsIssuesArray.push(newObj)
			}
		})

		let data = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"gsxRepairSymtomsIssues":gsxRepairSymtomsIssuesArray,
			"FaultActionID":selectedAction && selectedAction.FaultActionID?selectedAction.FaultActionID:addedFaultActions && addedFaultActions[0] && addedFaultActions[0].FaultActionID,
			"FaultActionCode": selectedAction && selectedAction.FaultActionCode?selectedAction.FaultActionCode:addedFaultActions && addedFaultActions[0] && addedFaultActions[0].ActionCode,
			"FaultDescription": selectedAction && selectedAction.Description?selectedAction.Description:addedFaultActions && addedFaultActions[0] && addedFaultActions[0].ActionDescription,
		}
		setLoader(true);
		apiCall({
			url:'updateCsrDiagnosis',
			data:data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					setEditIssues(false)
					setUpdateFreshData(true);
					updateCharges()
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const updateCharges = ()=> {
		let data  = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"ProductSubCategoryID": consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID,
			"BrandID": consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID,
			"IsFaultCalculation": false
		}
		if(qFilter && qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1) {
			data.IsFaultCalculation = true
		}

		setLoader(true);
		apiCall({
			url:'updateServiceRequestAmount',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRequestById()
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const removeSFA = (type,key,index)=> {
		// e.preventDefault();
		setUpdateFreshData(false)
		if(type == 'CUST'){
			const reducedArr = customerCodes;
			reducedArr.splice(index, 1);
			if(key.Priority == 1){
				for(let i=0 ; i< reducedArr.length;i++){
					setCustomerCodes(customerCodes[i].Priority = i+1)
				}
			}
			setCustomerCodes(reducedArr)

			const eraseArr = customerReportedSFA;
			eraseArr.splice(index, 1);
			if(key.Priority == 1){
				for(let i=0 ; i< eraseArr.length;i++){
					setCustomerReportedSFA(customerReportedSFA[i].Priority = i+1)
				}
			}
			if(key.Priority == 2){
				for(let i=0 ; i< eraseArr.length;i++){
					setCustomerReportedSFA(customerReportedSFA[i].Priority = i+1)
				}
			}
			let demo = [...eraseArr]
			demo.lengthOfArray = demo.length;
			setCustomerReportedSFA(demo)
		}else{
			const reducedArr = engineerCodes;
			reducedArr.splice(index, 1);
			if(key.Priority == 1){
				for(let i=0 ; i< reducedArr.length;i++){
					setEngineerCodes(engineerCodes[i].Priority = i+1)
				}
			}
			setEngineerCodes(reducedArr)
			// setEngineerReportedSFA(reducedArr)

			const eraseArr = engineerReportedSFA;
			eraseArr.splice(index, 1);
			if(key.Priority == 1){
				for(let i=0 ; i< eraseArr.length;i++){
					setEngineerReportedSFA(engineerReportedSFA[i].Priority = i+1)
				}
			}
			if(key.Priority == 2){
				for(let i=0 ; i< eraseArr.length;i++){
					setEngineerReportedSFA(engineerReportedSFA[i].Priority = i+1)
				}
			}
			let obj = [...eraseArr]
			obj.lengthOfArray = obj.length;
			setEngineerReportedSFA(obj)
		}
		validateSFA();
	}

	const addSFARow = (type,e)=>{
		// e.preventDefault();
		setUpdateFreshData(false)
		if(type == "TECH"){
			const newRow = {
				ReportedSymptom:{},
				ReportedIssue:{},
				ComptiaModifier:{},
				Type:"TECH",
				Priority:engineerReportedSFA && engineerReportedSFA.length + 1
			}
			setEngineerReportedSFA([...engineerReportedSFA,newRow])
			setEngineerCodes([...engineerCodes,defaultObjTECH])
		}else{
			const newRow = {
				ReportedSymptom:{},
				ReportedIssue:{},
				ComptiaModifier:{},
				Type:"CUST",
				Priority:customerReportedSFA && customerReportedSFA.length + 1
			};
			setCustomerReportedSFA([...customerReportedSFA,newRow])
			setCustomerCodes([...customerCodes,defaultObjCUST])
		}
		validateSFA();
	}

	const validateSFA = ()=>{
		setDisableSFASave(false)
		if(!(( selectedAction && selectedAction.FaultActionID && selectedAction.FaultActionID) || (addedFaultActions && addedFaultActions[0] && addedFaultActions[0].FaultActionID))){
			setDisableSFASave(true)
		}
		if(!(engineerReportedSFA && engineerReportedSFA.length)){
			setDisableSFASave(true)
		}else{
			if(!(engineerReportedSFA[0].ReportedSymptom && engineerReportedSFA[0].ReportedSymptom.reportedSymptomCode && engineerReportedSFA[0].ReportedIssue && engineerReportedSFA[0].ReportedIssue.reportedIssueCode &&  engineerReportedSFA[0].ComptiaModifier && engineerReportedSFA[0].ComptiaModifier.modifierCode)){
				setDisableSFASave(true)
			}
			for(let i =0 ; i< engineerReportedSFA.length;i++){
				if(((engineerReportedSFA[i].ReportedSymptom && engineerReportedSFA[i].ReportedSymptom.reportedSymptomCode) || (engineerReportedSFA[i].ReportedIssue && engineerReportedSFA[i].ReportedIssue.reportedIssueCode) || (engineerReportedSFA[i].ComptiaModifier && engineerReportedSFA[i].ComptiaModifier.modifierCode) || engineerReportedSFA[i].Priority == 1 )){
					if(!(engineerReportedSFA[i].ReportedSymptom && engineerReportedSFA[i].ReportedSymptom.reportedSymptomCode && engineerReportedSFA[i].ReportedIssue && engineerReportedSFA[i].ReportedIssue.reportedIssueCode &&  engineerReportedSFA[i].ComptiaModifier && engineerReportedSFA[i].ComptiaModifier.modifierCode)){
						setDisableSFASave(true)
					}
				}
			}
		}
		if(customerReportedSFA && customerReportedSFA.length){
			for(let i =0 ; i< customerReportedSFA.length;i++){
				if(((customerReportedSFA[i].ReportedSymptom && customerReportedSFA[i].ReportedSymptom.reportedSymptomCode) || (customerReportedSFA[i].ReportedIssue && customerReportedSFA[i].ReportedIssue.reportedIssueCode) || (customerReportedSFA[i].ComptiaModifier && customerReportedSFA[i].ComptiaModifier.modifierCode) || (customerReportedSFA[i].Priority == 1 && customerReportedSFA.length>1))){
					if(!((customerReportedSFA[i].ReportedSymptom && customerReportedSFA[i].ReportedSymptom.reportedSymptomCode) && (customerReportedSFA[i].ReportedIssue && customerReportedSFA[i].ReportedIssue.reportedIssueCode) && (customerReportedSFA[i].ComptiaModifier && customerReportedSFA[i].ComptiaModifier.modifierCode))){
						setDisableSFASave(true)
					}
				}
			}
		}
	}

	const custReportedIssue = [{
		parentClass :"symtom-code",
		customClassName:"part-code form-control",
		customId:'symptomcodeCUST',
		customName:"symptomcode",
		dataKey:"symptomcodeCUST",
		dropdownList:allSymptoms,
		onInputFunc:((val,i,type)=>{
			let arr = [...customerCodes]
			arr[i]={...customerCodes[i],["symptomcodeCUST"]:val.value,
				['reportedSymptomCode']:val.reportedSymptomCode,
				['issuecodeCUST']:"",
			}
			setCustomerCodes(arr)
			initIssueCode(type,i,val.reportedSymptomCode)

			let temp = [...customerReportedSFA]
			temp[i]={...customerReportedSFA[i],
					["ReportedSymptom"]:val,
					['ReportedIssue']:{}
				}
			setCustomerReportedSFA(temp)
		})
	},{
		parentClass :"issue-code",
		customClassName:"part-code form-control",
		customId:'issuecodeCUST',
		customName:"issuecode",
		dataKey:"issuecodeCUST",
		dropdownList:reportedIssues && reportedIssues,
		onInputFunc:((val,i)=>{
			let arr = [...customerCodes]
			arr[i]={...customerCodes[i],["issuecodeCUST"]:val.value}
			setCustomerCodes(arr)

			let temp = [...customerReportedSFA]
			temp[i]={...customerReportedSFA[i],["ReportedIssue"]:val}
			setCustomerReportedSFA(temp)
		}),
		isDisables:showIssueCode,
		showIrisLoader:true
	},{
		parentClass :"comptia_mod_Repair",
		customClassName:"part-code form-control",
		customId:'comptia_mod_RepairCUST',
		customName:"comptia_mod_Repair",
		dataKey:"comptia_mod_RepairCUST",
		dropdownList:compModList,
		onInputFunc:((val,i)=>{
			let arr = [...customerCodes]
			arr[i]={...customerCodes[i],["comptia_mod_RepairCUST"]:val.value}
			setCustomerCodes(arr)

			let temp = [...customerReportedSFA]
			temp[i]={...customerReportedSFA[i],["ComptiaModifier"]:val}
			setCustomerReportedSFA(temp)
		})
	}]

	const enggReportedIssue = [{
		parentClass :"symtom-code",
		customClassName:"part-code form-control",
		customId:'symptomcodeTECH',
		customName:"symptomcode",
		dataKey:"symptomcodeTECH",
		dropdownList:allSymptoms ,
		onInputFunc:((val,i,type)=>{
			let arr = [...engineerCodes]
			arr[i]={...engineerCodes[i],["symptomcodeTECH"]:val.value,
				['reportedSymptomCode']:val.reportedSymptomCode,
				['issuecodeTECH']:""
			}
			setEngineerCodes(arr)
			initIssueCode(type,i,val.reportedSymptomCode)

			let temp = [...engineerReportedSFA]
			temp[i]={...engineerReportedSFA[i],
				["ReportedSymptom"]:val,
				['ReportedIssue']:{}
			}
			setEngineerReportedSFA(temp)
		})
	},{
		parentClass :"issue-code",
		customClassName:"part-code form-control",
		customId:'issuecodeTECH',
		customName:"issuecode",
		dataKey:"issuecodeTECH",
		dropdownList:reportedIssues,
		onInputFunc:((val,i,type)=>{
			let arr = [...engineerCodes]
			arr[i]={...engineerCodes[i],["issuecodeTECH"]:val.value}
			setEngineerCodes(arr)

			let temp = [...engineerReportedSFA]
			temp[i]={...engineerReportedSFA[i],["ReportedIssue"]:val}
			setEngineerReportedSFA(temp)
		}),
		isDisabled:showIssueCode,
		showIrisLoader:true
	},{
		parentClass :"comptia_mod_Repair",
		customClassName:"part-code form-control",
		customId:'comptia_mod_RepairTECH',
		customName:"comptia_mod_Repair",
		dataKey:"comptia_mod_RepairTECH",
		dropdownList:compModList,
		onInputFunc:((val,i,type)=>{
			let arr = [...engineerCodes]
			arr[i]={...engineerCodes[i],["comptia_mod_RepairTECH"]:val.value}
			setEngineerCodes(arr)

			let temp = [...engineerReportedSFA]
			temp[i]={...engineerReportedSFA[i],["ComptiaModifier"]:val}
			setEngineerReportedSFA(temp)
		})
	}]

	const issueValidityBtn=[{
		labelText:"Valid",
		id:"issueValidity-valid",
		value:1,
		onclickupdate:(val,item)=>{
			collectIssues(val,item)
		}
	},{
		labelText:"Invalid",
		id:"issueValidity-invalid",
		value:2,
		onclickupdate:(val,item)=>{
			collectIssues(val,item)
		}
	}]

	return (
		<>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			{
				consumerServiceRequest && 
				(([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_DOOR_TO_DOOR_UPGRADE,SERVICETYPE.DOOR_TO_DOOR_UPGRADE,SERVICETYPE.CLAIM_DOOR_TO_DOOR_REPLACEMENT,SERVICETYPE.PICKUP_UPGRADE,SERVICETYPE.CLAIM_REPLACEMENT_PICKUP,SERVICETYPE.CLAIM_REPLACEMENT_MAIL_IN,SERVICETYPE.CLAIM_UPGRADE_PICKUP,SERVICETYPE.MAIL_IN_UPGRAGE,SERVICETYPE.CLAIM_UPGRADE_MAIL_IN,SERVICETYPE.CLAIM_MAIL_IN,9,13].indexOf(consumerServiceRequest.ServiceTypeID)== -1) && 
				!accessFn('WORKSHOP_FLOW')) &&
				<IssueValidationNonWorkshop 
					{...props}
					consumerServiceRequest={consumerServiceRequest}
					getIssuesValue={getIssuesValue}
					editIssues={editIssues}
					isReportedLength={isReportedLength}
					issues={issues}
					add={add}
					deleteIssue={deleteIssue}
					validate={validate}
					collectIssues={collectIssues}
					checkIfEngineerVisitCreated={checkIfEngineerVisitCreated}
					cancelIssuesClick={cancelIssuesClick}
					editIssuesClick={editIssuesClick}
					isClaimEstimationApproved={isClaimEstimationApproved}
					coveredUnderPlanCheck={coveredUnderPlanCheck}
					warrantyApplicableCheck={warrantyApplicableCheck}
					csrIssuesArr={csrIssuesArr}
					issuesList={issuesList}
					additionalIssues={additionalIssues}
					setAdditionalIssues={setAdditionalIssues}
				/>
			}
			{
				consumerServiceRequest && 
				(([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,9,13,61,62,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)== -1) && 
				accessFn('WORKSHOP_FLOW') && !accessFn('GSX_FLOW')) &&
				<IssueValidationWorkshop 
					{...props}
					canUpdateServiceRequest={canUpdateServiceRequest}
					consumerServiceRequest={consumerServiceRequest}
					editIssues={editIssues}
					isValidatedLength={isValidatedLength}
					isReportedLength={isReportedLength}
					issues={issues}
					add={add}
					isDiagnosisCompleted={isDiagnosisCompleted}
					validate={validate}
					collectIssues={collectIssues}
					checkIfEngineerVisitCreated={checkIfEngineerVisitCreated}
					cancelIssuesClick={cancelIssuesClick}
					editIssuesClick={editIssuesClick}
					isClaimEstimationApproved={isClaimEstimationApproved}
					coveredUnderPlanCheck={coveredUnderPlanCheck}
					warrantyApplicableCheck={warrantyApplicableCheck}
					isRequestSFANeeded={isRequestSFANeeded}
					addSymptomCode={addSymptomCode}
					deleteSymptoms={deleteSymptoms}
					engineerHasDeviceLog={engineerHasDeviceLog}
					disabeAddSymptom={disabeAddSymptom}
					issueValidity={issueValidity}
					setIssueValidity={setIssueValidity}
					issueValidityBtn={issueValidityBtn}
					csrIssuesArr={csrIssuesArr}
					symptomList={symptomList}
					setIssueSelectedForAddition={setIssueSelectedForAddition}
					issueSelectedForAddition={issueSelectedForAddition}
				/> 
			}
			{
				consumerServiceRequest && 
				(([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,9,13,61,62,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)== -1) && 
				accessFn('WORKSHOP_FLOW') && accessFn('GSX_FLOW') )&& 
				<IssueValidationGSX 
					{...props}
					canUpdateServiceRequest={canUpdateServiceRequest}
					isDiagnosisCompleted={isDiagnosisCompleted}
					engineerHasDeviceLog={engineerHasDeviceLog}
					warrantyApplicableCheck={warrantyApplicableCheck}
					customerReportedSFA={customerReportedSFA}
					engineerReportedSFA={engineerReportedSFA}
					addedFaultActions={addedFaultActions}
					disableSFASave={disableSFASave}
					isReportedLength={isReportedLength}
					isValidatedLength={isValidatedLength}
					showCustomerOptions={showCustomerOptions}
					editIssuesClick={editIssuesClick}
					editIssues={editIssues}
					validate={validate}
					toggleCustomerOptions={toggleCustomerOptions}
					changePriority={changePriority}
					allSymptoms={allSymptoms}
					custReportedIssue={custReportedIssue}
					removeSFA={removeSFA}
					enggReportedIssue={enggReportedIssue}
					addSFARow={addSFARow}
					engineerCodes={engineerCodes}
					customerCodes={customerCodes}
					setSelectedAction={setSelectedAction}
					selectedAction={selectedAction}
					symptomFaultFaultActionMappings={symptomFaultFaultActionMappings}
					issueValidityBtn={issueValidityBtn}
					saveSFAMappings={saveSFAMappings}
					cancelIssuesClick={cancelIssuesClick}
					csrIssuesArr={csrIssuesArr}
					disableValidate={disableValidate}
				/>
			}
			
		</>
	)
}

export default IssueValidation
