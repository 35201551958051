import React from 'react';
import {Modal} from 'sfy-react';
import './SubmitIrisModal.scss';
import SubmitIrisModalInputs from './SubmitIrisModalInputs';

const SubmitIrisModal =(props)=>{
    const {localString,showModal,closeModal,jobModalState} = props;
		return (
		<>
            <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='Filter-Modal modal-width'>
                <Modal.Header>
                    <h2> {localString("Submit IRIS")} </h2>
                </Modal.Header>
                <Modal.Body>
                    <SubmitIrisModalInputs {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            
        </>
	)
}
export default SubmitIrisModal;