import React from 'react';
import {Modal} from 'sfy-react';
import UploadClaimDocumentInput from './UploadClaimDocumentInput';
import './UploadClaimDocumentModal.scss';

const UploadClaimDocumentModal=(props)=>{
    const {localString,showModal,hiddenContent,closeModal,scheduleDateArray,selectedScheduleDate,scheduleSlotArray,slotAvailable, ConsumerServiceRequestID} = props;
		return (
		<>
            <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='Filter-Modal uploadClaimDocumentModal'>
                <Modal.Header>
                    <h2> { localString("Upload document")} </h2>
                </Modal.Header>
                <Modal.Body>
                    <UploadClaimDocumentInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default UploadClaimDocumentModal;