import React from 'react';
import {Table} from 'sfy-react';

const ReplacementDetailsTable = (props) => {
	const {replacementRequestData, localString, selectedOption} = props;

	const classObject = {
		table:"table table-striped complaint-details-table request-table"
	}

	return (
		<div>
		{
			(!replacementRequestData || (replacementRequestData && Object.keys(replacementRequestData).length == 0)) && 
			<div className="charges-panel-subheader"> {localString("No Replacement details")} </div>
		}
		{
			replacementRequestData && 
			<Table className={classObject}>
				<Table.Body>
				{
					replacementRequestData.OldProductName && 
					<Table.Row>
						<Table.Data className="table-keys text-capitalize">{localString("Old Device Name")}</Table.Data>
						<Table.Data>{replacementRequestData.OldProductName}</Table.Data>
					</Table.Row>
				}
				{
					replacementRequestData.OldBrandName && 
					<Table.Row>
						<Table.Data className="table-keys text-capitalize">{localString("Old Device Brand")}</Table.Data>
						<Table.Data>{replacementRequestData.OldBrandName}</Table.Data>
					</Table.Row>
				}
				{
					replacementRequestData.OldIMEI && 
					<Table.Row>
						<Table.Data className="table-keys text-capitalize">{localString("Old Device IMEI / Serial Number")}</Table.Data>
						<Table.Data>{replacementRequestData.OldIMEI}</Table.Data>
					</Table.Row>
				}
				{
					replacementRequestData.NewProductName && 
					<Table.Row>
						<Table.Data className="table-keys text-capitalize">{localString("New Device Name")}</Table.Data>
						<Table.Data>{replacementRequestData.NewProductName}</Table.Data>
					</Table.Row>
				}
				{
					replacementRequestData.NewBrandName && 
					<Table.Row>
						<Table.Data className="table-keys text-capitalize">{localString("New Device Brand")}</Table.Data>
						<Table.Data>{replacementRequestData.NewBrandName}</Table.Data>
					</Table.Row>
				}
				{
					replacementRequestData.NewIMEI && 
					<Table.Row>
						<Table.Data className="table-keys text-capitalize">{localString("New Device IMEI / Serial Number")}</Table.Data>
						<Table.Data>{replacementRequestData.NewIMEI}</Table.Data>
					</Table.Row>
				}
				{
					replacementRequestData.ReplacementPrice && 
					<Table.Row>
						<Table.Data className="table-keys text-capitalize">{localString("Replacement Price")}</Table.Data>
						<Table.Data>{replacementRequestData.ReplacementPrice}</Table.Data>
					</Table.Row>
				}
				{
					selectedOption && 
					<Table.Row>
						<Table.Data className="table-keys text-capitalize">{localString("Selected Replacement Option")}</Table.Data>
						<Table.Data>
							{
								selectedOption && Object.keys(selectedOption.OptionValues).map((key,i)=>{
									return(
										<div>
											<p> {localString(key)}: {selectedOption.OptionValues[key]} </p>
										</div>
									)
								})
							}
						</Table.Data>
					</Table.Row>
				}
				</Table.Body>
			</Table>
		}
		</div>
	)
}

export default ReplacementDetailsTable
