import React from 'react';
import AllDetails from './AllDetails';
import CallLogDetails from './CallLogDetails';
import EmailDetails from './EmailDetails';
import SMSDetails from './SMSDetails';
import WhatsAppDetails from './WhatsAppDetails';
const CommunicationHistoryDetails =(props)=>{
    const {activeLink,emailLogData,smsLogData,allCommunication,callLogData,whatsAppLogData} = props;

    return(
        <>
            {activeLink=='all' && 
                <AllDetails
                    {...props}
                    allCommunication={allCommunication}
                />
            }

            {activeLink == 'callLogs'&& 
                <CallLogDetails
                    {...props}
                    callLogFilter={callLogData}
                /> 
            }
            {activeLink == 'emails' &&  
                <EmailDetails
                    {...props}
                    emailFilterLog={emailLogData}
                />
            }
            {activeLink == 'sms' &&
                <SMSDetails
                    {...props}
                    smsFilterLog={smsLogData}
                />
            }
            {
                activeLink == 'whatsapp' &&
                <WhatsAppDetails
                    {...props}
                    whatsAppData = {whatsAppLogData}
                />
            }
        </>
    )

}
export default CommunicationHistoryDetails;