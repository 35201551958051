import React,{useState,useEffect,useRef} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import {Loader,Input,Button,Dropdown} from 'sfy-react';
import Swal from "sweetalert2";
import {find} from 'lodash';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import {displayError,returnLocalDateTime,generateUUID, SERVICETYPE} from '../../../../../Utility/Utils';
import './RequestDocuments.scss'

const RequestDocuments = (props) => {
	const {apiCall,consumerServiceRequest,accessFn,localString,docmaster,getPendingDocuments,docpending,useDocServiceReqDoc,docServiceUrl,getRequestById,authorization} = props;
	const [loader, setLoader] = useState(false);
	const [editDocuments, setEditDocuments] = useState(false)
	const [additionalDocuments, setAdditionalDocuments] = useState({})
	const [invoiceFile, setInvoiceFile] = useState({})
	const [fileName, setFileName] = useState('')
	const [pendingDocument, setPendingDocument] = useState()
	const [pendingFile, setPendingFile] = useState({})
	const [pendingFileName, setPendingFileName] = useState('')
	const [selectedAdditionalDoc, setSelectedAdditionalDoc] = useState({
		docTag:""
	});
	const [selectedPendingDoc, setSelectedPendingDoc] = useState({
		docTag:"",
		docID:""
	})
	const [additionalDocumentExtension, setAdditionalDocumentExtension] = useState('');
	const [isAdditionalDocDisabled, setIsAdditionalDocDisabled] = useState(false);
	const [pendingDocumentExtension, setPendingDocumentExtension] = useState('');
	const [isPendingDocDisabled, setIsPendingDocDisabled] = useState(false);

	const fileRef = useRef(null);
	const imageClick = () =>{
		fileRef.current.click();
	}

	const docRef = useRef(null);
	const pendingDocClick = () =>{
		docRef.current.click();
	}

	useEffect(() => {
		let additionalDoc = docmaster && docmaster.map((v)=>({...v,value:v.DocumentType}))
		setAdditionalDocuments(additionalDoc)
	}, [docmaster])

	useEffect(() => {
		let filterDocument = docpending && docpending.filter(item=>{
			if(item.IsPendingDocument){
				return item;
			}
		})
		let pendingDoc = filterDocument && filterDocument.map((item)=>({...item,value:item.document.DocumentType}))
		setPendingDocument(pendingDoc)
	}, [docpending])

	useEffect(() => {
		AdditionalDocSelection()
	}, [invoiceFile, selectedAdditionalDoc])

	useEffect(() => {
		PendingDocSelection()
	}, [selectedPendingDoc, pendingFile])
	
	
	useEffect(() => {
		if(editDocuments){
			setSelectedAdditionalDoc({['docTag']:additionalDocuments && additionalDocuments[0] && additionalDocuments[0].DocumentType ? additionalDocuments[0].DocumentType : ""})
			setSelectedPendingDoc({
				['docTag']:pendingDocument && pendingDocument[0] && pendingDocument[0].document && pendingDocument[0].document.DocumentType ? pendingDocument[0].document.DocumentType : "",
				['docID']:pendingDocument && pendingDocument[0] && pendingDocument[0].PendingDocID ? pendingDocument[0].PendingDocID : "",
		})
		}else{
			cancelDetailsClick()
		}
	}, [editDocuments])

	const editDocumentsClick = ()=> {
		setEditDocuments(true)
	};

	const checkFileUpload = (e)=> {
		let filename = e.target;
		let newfile = e.target.files;
		if (/^\s*$/.test(filename)) {
			setFileName(localString("No file chosen"))
		}
		else {
			let name = newfile[0].name;
			setFileName(localString(name))
		}
	}

	const checkPendingFileUpload = (e)=> {
		let filename = e.target;
		let newfile = e.target.files;
		if (/^\s*$/.test(filename)) {
			setPendingFileName("No file chosen")
		}
		else {
			let name = newfile[0].name;
			setPendingFileName(name)
		}
	}

	const cancelDetailsClick=()=> {
		setEditDocuments(false)
		setFileName("")
		setInvoiceFile({})
		setSelectedAdditionalDoc({docTag:""})
		setSelectedPendingDoc({docTag:"",docID:""})
	}

	const AdditionalDocSelection = () => {
		let docTag = selectedAdditionalDoc.docTag;
		let newfile = invoiceFile;
		let ext = "";
		let fileType = "";
		let AdditionalDocumentExt = '';
		
		if (newfile.length > 0) {
			fileType = newfile[0].type;
			ext = fileType.split("/").length ? "."+fileType.split("/")[fileType.split("/").length - 1] : "";
		}
		let currentDocMaster = find(docmaster, {DocumentType: docTag});
		if (currentDocMaster) {
			AdditionalDocumentExt = currentDocMaster.DocumentExtension;
		};
		if (AdditionalDocumentExt == 'jpg') {
			if ([".png",".jpg",".jpeg",".TIFF",".tiff",".RAW",".raw",".HEIC",".heic"].indexOf(ext) < 0) {
				setIsAdditionalDocDisabled(true)
			}
			else {
				setIsAdditionalDocDisabled(false)
			}
		}
		else {
			setIsAdditionalDocDisabled(false)
		}
		setAdditionalDocumentExtension(AdditionalDocumentExt)
	}

	const PendingDocSelection = () => {
		let docTag = selectedPendingDoc.docTag;
		let newfile = pendingFile;
		let ext = "";
		let fileType = "";
		let PendingDocumentExt = '';
		
		if (newfile.length > 0) {
			fileType = newfile[0].type;
			ext = fileType.split("/").length ? "."+fileType.split("/")[fileType.split("/").length - 1] : "";
		}

		let currentDocMaster = find(docmaster, {DocumentType: docTag});
		if (currentDocMaster) {
			PendingDocumentExt = currentDocMaster.DocumentExtension;
		};
		if (PendingDocumentExt == 'jpg') {
			if ([".png",".jpg",".jpeg",".TIFF",".RAW",".HEIC"].indexOf(ext) < 0) {
				setIsPendingDocDisabled(true)
			}
			else {
				setIsPendingDocDisabled(false);
			}
		}
		else {
			setIsPendingDocDisabled(false);
		}
		setPendingDocumentExtension(PendingDocumentExt)
	}

	const savedocuments = (e)=> {
		e.preventDefault();
		let dataImgType = 'image';
		let docTag = selectedAdditionalDoc.docTag;
		let docId;

		let currentDocMaster = find(docmaster, {DocumentType: docTag});
		if (currentDocMaster) {
			docId = currentDocMaster.DocumentID;
		};
		if (!docTag || docTag == '') {
			Swal.fire({
				title:"",
				icon:"error",
				text:localString('Please select a document type')
			})
			return;
		}

		let newfile = invoiceFile;
		if (newfile.length > 0) {
			let fileType = newfile[0].type;
			let ext = fileType.split("/").length ? "."+fileType.split("/")[fileType.split("/").length - 1] : "";
			let key = "FEWEBA" + generateUUID() + ext;
			if (fileType == 'application/pdf') {
				dataImgType = 'pdf';
			}
			if (useDocServiceReqDoc) {
				if (docServiceUrl == '') {
					return;
				}
				let dataNew = new FormData();
				dataNew.append('file', invoiceFile[0]);
				dataNew.append('docType', 'ConsumerServiceRequestDocument');
				dataNew.append('clientRefId', key);
				dataNew.append('entity-type', 'ConsumerServiceRequestID');
				dataNew.append('entity-id', consumerServiceRequest.ConsumerServiceRequestID);

				axios.post(docServiceUrl + 'internal/document/upload', dataNew, {
					headers:({
						"App": 'WebApp',
						"module": 'WebApp',
						"authorization": authorization
					}),
				})
				.then((result) => {
					let uploadURL = result.data.data.uploadURL;
					let fileNameUUID = result.data.data.docServiceUUID;
					if ([SERVICETYPE.PICK_UP, SERVICETYPE.CARRY_IN, SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, 6, 7, 56].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						apiCall({
							url:'createRequestDocumentsForDS',
							data:[{
								ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
								FilePath: uploadURL,
								FileName: fileNameUUID,
								ServiceTypeID : consumerServiceRequest.ServiceTypeID,
								Type: dataImgType,
								Tag: docTag,
								DocumentID: 12,
								app: 'WebApp'
							}],
							callBack:(result)=>{
								if(result.success){
									// $('#documentSelect').val('');
									setSelectedAdditionalDoc({docTag:""})
									getRequestById();
									cancelDetailsClick();
									getPendingDocuments();
								}else{
									Swal.fire({  
										icon: 'error',  
										text: localString(displayError(result.msg)),  
									})							
								}
							}	
						});
					} else {
						apiCall({
							url:'updateClaimDocumentForDS',
							data:{
								claimDocs: [{
									ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
									ConsumerProductID: consumerServiceRequest.ConsumerProductID,
									DocumentID: docId,
									FilePath: uploadURL,
									FileName: fileNameUUID,
									Type: dataImgType,
									Tag: docTag,
									app: 'WebApp'
								}]
							},
							callBack:(result)=>{
								if(result.success){
									// $('#documentSelect').val('');
									setSelectedAdditionalDoc({docTag:""})
									getRequestById();
									cancelDetailsClick();
								}else{
									Swal.fire({  
										icon: 'error',  
										text: localString(displayError(result.msg)),  
									})							
								}
							}	
						});
					}
				})
				.catch((error) => {
					console.log('error: ' + error);
				})
			}else{
				apiCall({
					url:'uploadDocuments',
					data: {
						Type: fileType,
						Key: key,
						BrandID: consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID ? consumerServiceRequest.consumerProduct.BrandID : undefined
					},
					callBack:(result)=>{
						if(result.success){
							let xhr = new XMLHttpRequest();
							xhr.open("PUT", result.data);
							xhr.setRequestHeader("Cache-Control", "public,max-age=3600");
							xhr.setRequestHeader('x-amz-acl', 'public-read');
							xhr.onload = function () {
							if (xhr.status === 200) {
								if ([SERVICETYPE.PICK_UP, SERVICETYPE.CARRY_IN, SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, 6, 7, 56].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
									apiCall({
										url:'createRequestDocuments',
										data:[{
											ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
											FilePath: key,
											ServiceTypeID : consumerServiceRequest.ServiceTypeID,
											Type: dataImgType,
											FileName: key,
											Tag: docTag,
											DocumentID: 12,
											app: 'WebApp'
									}],
										callBack:(result)=>{
											if(result.success){
												// $('#documentSelect').val('');
												setSelectedAdditionalDoc({docTag:""})
												getRequestById();
												cancelDetailsClick();
												getPendingDocuments();
											}else{
												Swal.fire({  
													icon: 'error',  
													text: localString(displayError(result.msg)),  
												})							
											}
										}	
									});
								} else {
									apiCall({
										url:'updateClaimDocument',
										data:{
												claimDocs:[{
													ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
													ConsumerProductID: consumerServiceRequest.ConsumerProductID,
													DocumentID: docId,
													FilePath: key,
													Type: dataImgType,
													Tag: docTag,
													app: 'WebApp'
													}
												]
											},
										callBack:(result)=>{
											if(result.success){
												// $('#documentSelect').val('');
												setSelectedAdditionalDoc({docTag:""})
												getRequestById();
												cancelDetailsClick();
											}else{
												Swal.fire({  
													icon: 'error',  
													text: localString(displayError(result.msg)),  
												})							
											}
										}	
									});
								}
							}
							}.bind(this);
							xhr.onerror = function () {}.bind(this);
							xhr.send(newfile[0]);
						}else{
							Swal.fire({  
								icon: 'error',  
								text: localString(displayError(result.msg)),  
							})							
						}
					}	
				});
			}
		} else {
			Swal.fire({
				title:"",
				icon:"error",
				text:localString('Please select a document')
			})
			return;
		}
	};

	const savependingdocuments = (e) =>{
		e.preventDefault();
		let dataImgType = 'image';
		let pendingDoc = {};
		let docTag = selectedPendingDoc.docID;

		if (!docTag || docTag == '') {
			Swal.fire({
				title:"",
				icon:"error",
				text:localString('Please select a document type')
			})
			return;
		}

		let pendingDocsArr = docpending;

		for (let i = 0; i < pendingDocsArr.length; i++) {
			if (docTag == pendingDocsArr[i].PendingDocID) {
				pendingDoc = pendingDocsArr[i];
				break;
			}
		}
		let newfile = pendingFile;

		if (newfile.length > 0) {
			let fileType = newfile[0].type;
			let ext = fileType.split("/").length ? "."+fileType.split("/")[fileType.split("/").length - 1] : "";
			let key = "FEWEBA" + generateUUID() + ext;
	
			if (fileType == 'application/pdf') {
				dataImgType = 'pdf';
			}
			if (useDocServiceReqDoc) {
				if (docServiceUrl == '') {
					return;
				}
				let dataNew = new FormData();
				dataNew.append('file', pendingFile[0]); 
				dataNew.append('docType', 'ConsumerServiceRequestDocument');
				dataNew.append('clientRefId', key);
				dataNew.append('entity-type', 'ConsumerServiceRequestID');
				dataNew.append('entity-id', consumerServiceRequest.ConsumerServiceRequestID);
				
				axios.post(docServiceUrl + 'internal/document/upload', dataNew, {
					headers:({
						"App": 'WebApp',
						"module": 'WebApp',
						"authorization": authorization
					}),
				})
				.then((result) => {
					let uploadURL = result.data.data.uploadURL;
					let fileNameUUID = result.data.data.docServiceUUID;
					let data = {
						requestDocs: [{
							PendingDocID: pendingDoc.PendingDocID,
							DocumentID: pendingDoc.DocumentID,
							ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
							ConsumerProductID: consumerServiceRequest.ConsumerProductID,
							FilePath: uploadURL,
							FileName: fileNameUUID,
							Tag: pendingDoc.document.DocumentType,
							Type: dataImgType,
							app: 'WebApp'
						}]
					};
					apiCall({
						url:'createRequestDocumentsForDS',
						data:data,
						callBack:(result)=>{
							if(result.success){
								// $('#documentSelect').val('');
								setPendingFileName('');
								getRequestById();
								cancelDetailsClick();
								getPendingDocuments();
							}else{
								Swal.fire({  
									icon: 'error',  
									text: localString(displayError(result.msg)),  
								})							
							}
						}	
					});
				})
				.catch((error) => {
					console.log('error: ' + error);
				})
			}
			else {
				let data= {
					Type: fileType,
					Key: key,
					BrandID: consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID ? consumerServiceRequest.consumerProduct.BrandID : undefined
				};
				apiCall({
					url:'uploadDocuments',
					data:data,
					callBack:(result)=>{
						if(result.success){
							let data = {
								requestDocs: [{
									PendingDocID: pendingDoc.PendingDocID,
									DocumentID: pendingDoc.DocumentID,
									ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
									ConsumerProductID: consumerServiceRequest.ConsumerProductID,
									FilePath: key,
									Tag: pendingDoc.document.DocumentType,
									Type: dataImgType,
									app: 'WebApp'
								}]
							};
							var xhr = new XMLHttpRequest();
							xhr.open("PUT", result.data);
							xhr.setRequestHeader("Cache-Control", "public,max-age=3600");
							xhr.setRequestHeader('x-amz-acl', 'public-read');
							xhr.onload = function () {
							if (xhr.status === 200) {
								apiCall({
									url:'createRequestDocuments',
									data:data,
									callBack:(result)=>{
										if(result.success){
											// $('#documentSelect').val('');
											setPendingFileName('');
											getRequestById();
											cancelDetailsClick();
											getPendingDocuments();
										}else{
											Swal.fire({  
												icon: 'error',  
												text: localString(displayError(result.msg)),  
											})							
										}
									}	
								});
							}
							}.bind(this);
							xhr.onerror = function () {}.bind(this);
							xhr.send(newfile[0]);
						}else{
							Swal.fire({  
								icon: 'error',  
								text: localString(displayError(result.msg)),  
							})							
						}
					}	
				});
			}
		}else {
			Swal.fire({
				icon:"error",
				title:"",
				text:localString('Please select a document')
			})
			return;
		}
	}

	return (
		<>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper 
						bodyClassName="panel-body-no-padding" 
						title={localString("Request Documents")} 
						customSymbol="glyphicon glyphicon-plus"
						showDownloadButtonIcon={!editDocuments && (['Pickup Request Accepted', 'Service completed', 'Service cancel', 'Claim raised', 'Call attempted'].indexOf(consumerServiceRequest.Status) == -1 && !accessFn('READ_ONLY') && (!accessFn('WORKSHOP_FLOW') || (accessFn('WORKSHOP_FLOW') && (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS'))))) || accessFn('EDIT_REQUEST_DOCUMENTS')} 
						editDeviceClick={editDocumentsClick} >
						<div className="request-panel-details-tag request-documents-div">
						{
							consumerServiceRequest && consumerServiceRequest.documents && 
							<div className="docRow">
								<ul className="remove-list-style">
								{
									consumerServiceRequest.documents.map((doc,index)=>{
										return(
											<li className="request-documents-li align">
												<a href={doc.FilePath} target="_blank" className="nounderline">
													<div className="documents-box">
														<div>
															<i className="glyphicon glyphicon-file" ></i>  
															<p className="field-margin">
																{
																	((doc.consumerServicerequestDocumentPending && doc.consumerServicerequestDocumentPending.document && doc.consumerServicerequestDocumentPending.document.DocumentType) 
																	? localString(doc.consumerServicerequestDocumentPending.document.DocumentType) 
																	: localString(doc.Tag)) || 'NA'
																}
															</p>
															<p>{returnLocalDateTime(doc.CreatedDate,'DD MMM, YYYY hh:mma')}</p>
															{
																(doc.consumerServicerequestDocumentPending && doc.consumerServicerequestDocumentPending.Remark)
																? doc.consumerServicerequestDocumentPending.Remark &&
																<p className="remove-margin-bottom doc-remark" >
																	<label>{localString("Remark")}
																		<span className="glyphicon glyphicon-chevron-down"></span>
																	</label>
																</p>
																:""
															}
														</div>
														{
															(doc.consumerServicerequestDocumentPending && doc.consumerServicerequestDocumentPending.Remark)
															? doc.consumerServicerequestDocumentPending.Remark  &&
															<div className="doc-remark-full text-center">
																<p>
																	{
																		(doc.consumerServicerequestDocumentPending && doc.consumerServicerequestDocumentPending.Remark)
																		? doc.consumerServicerequestDocumentPending.Remark : ''
																	}
																</p>
															</div>
															:""
														}
													</div>
												</a>
											</li>
										)
									})
								}
								</ul>
							</div>
						} 
						{
							editDocuments && 
							<div className="docRow">
								<div className={pendingDocumentExtension == 'jpg'? " col-sm-6  shot-Padding" : "col-sm-6  long-Padding"}>
								{
									docpending && docpending.length>0 && 
									<form onSubmit={(e)=>savependingdocuments(e)} name="pendingdocumentsForm" >
										<div className="row">
											<div className="col-sm-12">
												<p>{localString("Add Pending Documents")}</p>
											</div>
											<div className="col-sm-4">
												<Dropdown
													id='dropdown'
													value={selectedPendingDoc.docTag}
													options={pendingDocument}
													handleOnChange={(data) => {
														setSelectedPendingDoc({['docTag']:data.value,['docID']:data.PendingDocID})
													}}
													filterBy='value'
													showDownArrow={true}
												/>
											</div>
											<div className="col-sm-8">
												<div className="row">
													<label className="col-sm-3 btn button-servify" onClick={pendingDocClick}>{localString("Choose File") }</label>
													<Input
														reference={docRef}
														inputType="file"
														id="pendingFile"
														handleOnChange={(e)=>{
															setPendingFile(e.target.files)
															checkPendingFileUpload(e)
														}}
														otherProps={{accept: pendingDocumentExtension == 'jpg' ? ".png,.jpg,.jpeg,.TIFF,.RAW,.HEIC" : ".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv,.mp4,.3gp,.aac,.m4a,.ogg,.ogx" }}
													/>
													<p className="col-sm-5" id="noPendingFile">{!pendingFileName ?localString("No file chosen") :pendingFileName}</p>
												</div>
											</div>
											{
												pendingDocumentExtension == 'jpg' &&
												<div className="col-sm-12">
													<p style={{paddingTop:"10px", fontWeight:"100"}}>{localString("This document type only accepts images for submission. Please ensure you upload a supported file Extensions (.png,.jpg,.jpeg,.TIFF,.RAW,.HEIC) type for this proof.")}</p>
												</div>
											}
										</div>
										<div>
											<Button type="submit" name="submit" className="btn button-servify" isDisabled={isPendingDocDisabled} text={localString("Save")} />
											<Button handleOnClick={cancelDetailsClick} type="button" name="cancel" className="btn button-servify" text={localString("Cancel")} />
										</div>
									</form>
								}
								{
									(!docpending || docpending.length == 0) && 
									<div>
										<p>{localString("No Pending Document Specified")}</p>
									</div>
								}
								</div>
								<div className={additionalDocumentExtension == 'jpg'? " col-sm-6 additionalDocsDiv shot-Padding" : "col-sm-6 additionalDocsDiv long-Padding" }> 
									<form onSubmit={(e)=>savedocuments(e)} name="documentsForm" className="documentsForm">
										<div className="row">
											<div className="col-sm-12">
												<p>{localString("Add Additional Documents")}</p>
											</div>
											<div className="col-sm-4">
												<Dropdown
													id='dropdown'
													value={selectedAdditionalDoc.docTag}
													options={additionalDocuments}
													handleOnChange={(data) => {
														setSelectedAdditionalDoc({['docTag']:data.value})
													}}
													filterBy='value'
													showDownArrow={true}
												/>
											</div>
											<div className="col-sm-8">
												<div className="row d-flex">
													<label className="btn button-servify" onClick={imageClick}>{localString("Choose File") }</label>
													<Input
														reference={fileRef}
														inputType="file"
														id="invoiceFile"
														handleOnChange={(e)=>{
															setInvoiceFile(e.target.files)
															checkFileUpload(e)
														}}
														otherProps={{accept: additionalDocumentExtension == 'jpg' ? ".png,.jpg,.jpeg,.TIFF,.RAW,.HEIC" : ".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv,.aac,.m4a,.mp4,.ogg,.ogx,.3gp" }}
													/>
													<p className="filePending" id="noFile">{!fileName ?localString("No file chosen") :fileName}</p>
												</div>
											</div>
											{
												additionalDocumentExtension == 'jpg' &&
												<div className="col-sm-12">
													<p style={{paddingTop:"10px", fontWeight:"100"}}>{localString("This document type only accepts images for submission. Please ensure you upload a supported file Extensions (.png,.jpg,.jpeg,.TIFF,.RAW,.HEIC) type for this proof.")}</p>
												</div>
											}
										</div>
										<div className='row'>
											<Button type="submit" name="submit" className="btn button-servify" isDisabled={isAdditionalDocDisabled} text={localString("Save")} />
											<Button handleOnClick={cancelDetailsClick} type="button" name="cancel" className="btn button-servify" text={localString("Cancel")} />
										</div>
									</form>
								</div>
							</div>
						}  
						</div>
					</PageContentWrapper>
				</div>
			</div>
		</>
	)
}

export default RequestDocuments
