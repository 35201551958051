import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader,Table,Button} from 'sfy-react';
import Swal from "sweetalert2";
import {displayError,SERVICETYPE} from '../../../../../Utility/Utils';
import { findIndex,flatMap,uniqBy,find,filter,pullAt,compact,each,remove,map} from 'lodash';
import './SymptomFaultAction.scss'
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import SymptomFaultActionJSX from './SymptomFaultActionJSX';

const SymptomFaultAction = (props) => {
    const {apiCall,qFilter,consumerServiceRequest,accessFn,localString,requestSFA,getPartnerSymptomFaultAction,getRequestById,
        allParts
    } = props; 

    const [loader, setLoader] = useState(false);
    const [isSymptomEditableFlag, setIsSymptomEditableFlag] = useState(false)
    const [structuredSFA, setStructuredSFA] = useState([])
    const [symptomList, setSymptomList] = useState([])
    const [faultList, setFaultList] = useState([])
    const [actionListDropdown, setActionListDropdown] = useState([])
    const [actionList, setActionList] = useState([])
    const [partList, setPartList] = useState([])
    const [irisModal, setIrisModal] = useState(false)
    const [selectedPart, setSelectedPart] = useState({})
    const [availableQuantity, setAvailableQuantity] = useState("")
    const [SFAObject, setSFAObject] = useState({})
    const [addedSFA, setAddedSFA] = useState([])
    const [disableAddButton, setDisableAddButton] = useState(true)
    const [isSelected, setIsSelected] = useState({
        isSymptomSelected:false,
        isFaultSelected:false,
        isActionSelected:false,
        isPartSelected:false,

    })
    let [showRecords, setShowRecords] = useState({
        RecordSymptoms : true,
        RecordFaults : true,
        RecordActions : true,
        RecordParts : true,
        isSymptomEditableFlag : false,
        needPart:false
    })
    let [insideLoaders, setInsideLoaders] = useState({
        showSymptomLoader:false,
        showFaultLoader:false,
        showActionLoader:false,
        showAvailableQuantityLoader:false
    })
    let [filterData, setFilterData] = useState({
        symptomCode:"",
        faultCode:"",
        Action:"",
        partCode:"",
        warrantyCode:""
    })
    const [warrantyType, setWarrantyType] = useState([
        {
            value:'In-warranty',
            Type: 'In-warranty',
            ID: 1
        },
        {
            value:'Out of warranty',
            Type: 'Out of warranty',
            ID: 2
        }
    ])
    const defaultWarranty = {
        "Type": 'Out of warranty',
        "ID": 2
    }
    const UnderWarrantyAndApplicableType = {
        "Type": 'In-warranty',
        "ID": 1
    }
    const [validation, setValidation] = useState()

    useEffect(() => {
        if(requestSFA && requestSFA.length){
            let structSFA = structureSFAHelper()
            setStructuredSFA(structSFA)
        }
    }, [requestSFA])

    useEffect(() => {
        if(filterData.symptomCode && filterData.faultCode && filterData.Action){
            if(actionList){
                actionList.forEach(item=>{
                    if(item.parts && item.parts.length > 0){
                        if(filterData.ActionID == item.ActionID){
                            setValidation(false)
                        }
                        if(filterData.partCode && filterData.warrantyCode && (availableQuantity || availableQuantity == 0)){
                            setDisableAddButton(false)
                        }else{
                            filterData.ActionID == item.ActionID &&
                            setDisableAddButton(true)
                        }
                    }
                    else if(!item.parts || (item.parts && !item.parts.length)){
                        if(filterData.ActionID == item.ActionID){
                            setValidation(true)
                            setDisableAddButton(false)
                        }
                    }                    
                })
            }
        }
    }, [filterData,availableQuantity])

    const checkIfSFAEditable = () => {
        if (!accessFn ('READ_ONLY') && (accessFn('REPAIR_DEVICE') || accessFn ('REASSIGN_JOBS'))) {
          // access check
            if (['Work in progress','Estimation generated', 'Claim estimation generated', 'Parts request accepted', 'Parts issued', 'Parts received','Parts rejected'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) > -1) {
                // status check
                if (isDiagnosisCompleted() && areIssuesValidated() && (deviceWith() == 'ServicelocationEngineer' || deviceWith() == 'Supervisor')) {
                    return true
                } else {
                    // pass
                }
            } else {
              // pass
            }
        } else {
          // pass
        }
        return false
    }

    const isDiagnosisCompleted = ()=> {
        let diagInit = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis initiated',PerformedByRole:'ServicelocationEngineer'});
        let diagComp = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis completed',PerformedByRole:'ServicelocationEngineer'});
        let cannotDiag = findIndex(consumerServiceRequest.logs, {Status: 'Cannot be diagnosed',PerformedByRole:'ServicelocationEngineer'});
        if (diagInit != -1) {
            if (diagComp != -1) {
                return diagComp < diagInit;
            }
            if (cannotDiag != -1) {
                return cannotDiag < diagInit
            }
            return false
        }
        return true
    }

    const areIssuesValidated = () => {
        let issuesValidated = true;
        if(consumerServiceRequest.issues && consumerServiceRequest.issues.length > 0) {
            for(let i = 0; i < consumerServiceRequest.issues.length; i++){
                if(consumerServiceRequest.issues[i].IsValidated == false) {
                    issuesValidated = false;
                break;
                }
            }
        }
    
        if (issuesValidated) {
            return true
        } else {
            return false
        }
    }

    const deviceWith = ()=> {
        let deviceAccept = {};
        let logs = consumerServiceRequest && consumerServiceRequest.logs;
        for (let iCount = 0;iCount<logs.length;iCount++) {
            if (logs[iCount].Status == 'Device accepted') {
                deviceAccept = logs[iCount]
                break;
            }
        }
        return deviceAccept.DestinationType
    }

    const editSymptomClick = () => {
        setIsSymptomEditableFlag(true)
    }

    const cancelSymptomClick = ()=> {
        setIsSymptomEditableFlag(false)
    }

    const closeModal = () =>{
		setIrisModal(false);
	}

    const structureSFAHelper = ()=> {
        let SFAArray = []
        if (requestSFA && requestSFA.length > 0 ) {
            for (let iCount=0;iCount<requestSFA.length;iCount++) {
                if (requestSFA[iCount].parts && requestSFA[iCount].parts.length > 0 ) {
                    for (let jCount=0;jCount<requestSFA[iCount].parts.length;jCount++) {
                        let SFAObject = {}
                        SFAObject['Symptom'] = {
                            "SymptomID": requestSFA[iCount].SymptomID,
                            "SymptomCode": requestSFA[iCount].SymptomCode,
                            "SymptomDescription": requestSFA[iCount].SymptomDescription
                        }
                        SFAObject['Fault']  = {
                            "FaultID": requestSFA[iCount].FaultID,
                            "FaultCode": requestSFA[iCount].FaultCode,
                            "FaultDescription": requestSFA[iCount].FaultDescription
                        }
                        SFAObject['Action'] = {
                            "FaultActionID": requestSFA[iCount].ActionID,
                            "FaultActionCode": requestSFA[iCount].ActionCode,
                            "FaultActionDescription": requestSFA[iCount].ActionDescription
                        }
                        SFAObject['FaultActionID'] = requestSFA[iCount].FaultActionID
                        SFAObject['Part'] = requestSFA[iCount].parts[jCount]
                        SFAArray.push(SFAObject)
                    }
                } else {
                    let SFAObject = {}
                    SFAObject['Symptom'] = {
                    "SymptomID": requestSFA[iCount].SymptomID,
                    "SymptomCode": requestSFA[iCount].SymptomCode,
                    "SymptomDescription": requestSFA[iCount].SymptomDescription
                    }
                    SFAObject['Fault']  = {
                    "FaultID": requestSFA[iCount].FaultID,
                    "FaultCode": requestSFA[iCount].FaultCode,
                    "FaultDescription": requestSFA[iCount].FaultDescription
                    }
                    SFAObject['Action'] = {
                    "FaultActionID": requestSFA[iCount].ActionID,
                    "FaultActionCode": requestSFA[iCount].ActionCode,
                    "FaultActionDescription": requestSFA[iCount].ActionDescription
                    }
                    SFAObject['FaultActionID'] = requestSFA[iCount].FaultActionID
                    SFAArray.push(SFAObject)
                }
            }
        }
        return SFAArray
    }

    const deleteSymptomFaultAction = (symptomFaultAction)=> {
        let data = {
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "ServiceTypeID": consumerServiceRequest.ServiceTypeID,
            "PartnerServiceLocationID": consumerServiceRequest.PartnerServiceLocationID,
            "deleteSymptomFaultActionIDs":[symptomFaultAction.FaultActionID]
        }
        setLoader(true);
		apiCall({
			url:'saveSymptomFaultAction',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    getRequestById()
                    getPartnerSymptomFaultAction()
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
        
    }

    const saveSymptomFaultAction = ()=>{
        let toAddSFA = [];
        if (addedSFA && addedSFA.length > 0) {
            let addSFALength =addedSFA.length ;
            for (let iCount=0;iCount<addSFALength;iCount++) {
                let SymptomID = addedSFA[iCount].Symptom.SymptomID
                let FaultID = addedSFA[iCount].Fault.FaultID
                let FaultActionID = addedSFA[iCount].Action.FaultActionID
                if (addedSFA[iCount].Part) {
                    if(addedSFA[iCount].Warranty.Type === "In-warranty"){
                        addedSFA[iCount].Part.InWarranty = true
                    }
                    else{
                        addedSFA[iCount].Part.InWarranty = false
                    }
                }
                let isSFAPresentIndex = -1
                if (toAddSFA.length > 0) {
                    isSFAPresentIndex = findIndex(toAddSFA, function(o){
                        if (o.SymptomID == SymptomID && o.FaultID == FaultID && o.ActionID == FaultActionID) {
                            return o
                        }
                    })
                }
                if (isSFAPresentIndex > -1) {
                    toAddSFA[isSFAPresentIndex].parts.push(addedSFA[iCount].Part)
                } else {
                    var toAddSFAObject = {
                        "SymptomID": SymptomID,
                        "FaultID": FaultID,
                        "ActionID": FaultActionID,
                        "parts": addedSFA[iCount].Part ? [addedSFA[iCount].Part] :[null],
                        "SymptomFaultFaultActionMappingID": addedSFA[iCount].Action.selectedSFAMappingID
                    }
                    toAddSFA.push(toAddSFAObject)
                }
            }
            let data = {
                "ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
                "ServiceTypeID": consumerServiceRequest && consumerServiceRequest.ServiceTypeID,
                "PartnerServiceLocationID": consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID,
                "symptomFaultActions": toAddSFA,
                "qFilter":JSON.parse(qFilter)
            }
            setLoader(true);
            apiCall({
                url:'saveSymptomFaultAction',
                data: data,
                callBack:(result)=>{
                    setLoader(false);
                    if(result.success){
                        setInsideLoaders({...insideLoaders,showAvailableQuantityLoader:false})
                        clearAllPartInputs()
                        setAddedSFA([])
                        setIrisModal(false)
                        cancelSymptomClick();
                        getPartnerSymptomFaultAction()
                        getRequestById();
                    }else{  
                        Swal.fire({
                            icon: 'error',
                            text: localString(displayError(result)),
                        });
                    }
                }
            });
        }
    }

    // show the modal for the symptom fault action
    const showSymptomFaultActionModal = () => { 
        setIrisModal(true)

        // Either start with symptom or with fault
        if (showRecords.RecordSymptoms) {
            getSymptoms();
        } else if (showRecords.RecordFaults || showRecords.RecordActions) {
            // getSFAMappings()
        }
        if ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
            // let warrantyType = [{
            //     "Type": 'Out of warranty',
            //     "ID": 2
            // }];
            setWarrantyType([{
                "value":'Out of warranty',
                "Type": 'Out of warranty',
                "ID": 2
            }])
        }
        // initWarranty()
    }

    const getSymptoms = ()=> {

        if (consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID && consumerServiceRequest.consumerProduct.BrandID) {
            let data = {
                "ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
                "ProductSubCategoryID": consumerServiceRequest && consumerServiceRequest.consumerProduct.ProductSubCategoryID,
                "BrandID":consumerServiceRequest && consumerServiceRequest.consumerProduct.BrandID
            }
            setInsideLoaders({...insideLoaders,showSymptomLoader:true})
            apiCall({
                url:'getSymptoms',
                data: data,
                callBack:(result)=>{
                    if(result.success){
                        if (result.data.symptoms && result.data.symptoms.length > 0 ) {
                            setInsideLoaders({...insideLoaders,showSymptomLoader:false})
                            let symptoms = result.data.symptoms
                            let uniqueSymptomList = uniqBy(symptoms, 'SymptomID');
                            let uniqueSymptomDropdown = uniqueSymptomList && uniqueSymptomList.length > 0 && uniqueSymptomList.map(v=>({...v,value:v.SymptomCode+' - '+v.SymptomDescription}))
                            setSymptomList(uniqueSymptomDropdown)
                            // initSymptoms()
                        } else {
                            Swal.fire({
                                icon:"info",
                                text:(localString(displayError(result)) || localString('No symptoms found'))
                            })
                        }
                    }else{
                        Swal.fire({
                            icon: 'error',
                            text: localString(displayError(result)),
                        });
                    }
                }
            });
        }
    }

    const getFaults = ()=> {
        let data = {
            "ProductSubcategoryID": consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubcategory && consumerServiceRequest.consumerProduct.ProductSubcategory.ProductSubCategoryID,
            "ProductID": consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductID,
            "Active": true,
            "Archived": false,
            "skipPagination": true,
            "FinishedGoodID": consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.FinishedGoodID,
            "ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID
        }
        if (showRecords.RecordSymptoms) {
            if (isSelected.isSymptomSelected && filterData && filterData.symptomID) {
                data.SymptomID = filterData.symptomID
            }else {
                return ;
            }
    
            if (isSelected.isSymptomSelected && filterData && filterData.oemid) {
                data.OEMID = filterData.oemid
            } else {
                return;
            }
        }else {
            // pass
        }
        if(consumerServiceRequest.PartnerServiceLocationID){
            data.PartnerServiceLocationID = consumerServiceRequest.PartnerServiceLocationID
        }
        setInsideLoaders({...insideLoaders,showFaultLoader:true})
        apiCall({
            url:'getFaultsBySymptom',
            data: data,
            callBack:(result)=>{
                if(result.success){
                    setInsideLoaders({...insideLoaders,showFaultLoader:false})
                    if (result.data && result.data.length > 0 ) {
                        let fault = result.data
                        let uniqueFaultList = uniqBy(fault, 'FaultID');
                        let uniqueFaultDropdown = uniqueFaultList && uniqueFaultList.length > 0 && uniqueFaultList.map(v=>({...v,value:v.FaultCode+' - '+v.FaultDescription}))
                        setFaultList(uniqueFaultDropdown)
                        // initFaults()
                    } else {
                        Swal.fire({
                            icon:"info",
                            text:localString((displayError(result)) || localString('No faults found'))
                        })
                    }
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: localString(displayError(result)),
                    });
                }
            }
        });
    }

    const getActions = ()=> {
        let data = {
            "ProductSubcategoryID": consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubcategory && consumerServiceRequest.consumerProduct.ProductSubcategory.ProductSubCategoryID,
            "ProductID": consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductID,
            "Active": true,
            "Archived": false,
            "skipPagination": true,
            "FinishedGoodID": consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.FinishedGoodID,
            "ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID
        }
        if (showRecords.RecordSymptoms) {
            if (isSelected.isSymptomSelected && filterData && filterData.symptomID) {
                data.SymptomID = filterData.symptomID
            } else {
                return;
            }
    
            if (isSelected.isSymptomSelected && filterData && filterData.oemid) {
                data.OEMID = filterData.oemid
            } else {
                return;
            }
        }
        if (showRecords.RecordFaults) {
            if (isSelected.isFaultSelected && filterData && filterData.faultID) {
                data.FaultID = filterData.faultID
            } else {
                return;
            }
        }
        if(consumerServiceRequest.PartnerServiceLocationID){
            data.PartnerServiceLocationID = consumerServiceRequest.PartnerServiceLocationID
        }
        setInsideLoaders({...insideLoaders,showActionLoader:true})
        apiCall({
            url:'getSFAMappings',
            data: data,
            callBack:(result)=>{
                if(result.success){
                    setInsideLoaders({...insideLoaders,showActionLoader:false})
                    if (result.data && result.data.length > 0 ) {
                        let actionList = [];
                        let actionListArr = result.data
                        // setActionList(actionListArr)
                        actionList = filter(actionListArr, function(e){
                            if(e.faultAction){
                                return e.faultAction.FaultActionID
                            }
                        });
                        actionList = uniqBy(actionListArr,'FaultActionID')
                        setActionList(actionList)
                        let actionDropdown = actionList && actionList.map(v=>({...v,value:v.faultAction && v.faultAction.FaultActionCode +" - "+v.faultAction.Name}))
                        setActionListDropdown(actionDropdown)
                    } else {
                        Swal.fire({
                            icon:"info",
                            text:localString((displayError(result)) || localString('No actions found'))
                        })
                    }
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: localString(displayError(result)),
                    });
                }
            }
        });
    }

    const  getAvailableQuantity = ()=> {
        if (selectedPart && consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID) {
            let data = {
                "PartnerServiceLocationID":consumerServiceRequest.PartnerServiceLocationID,
                "part":selectedPart
            }
            setInsideLoaders({...insideLoaders,showAvailableQuantityLoader:true})
            apiCall({
                url:'getAvailableQuantity',
                data: data,
                callBack:(result)=>{
                    if(result.success){
                        setInsideLoaders({...insideLoaders,showAvailableQuantityLoader:false})
                        let availableQty = result.data && result.data.AvailableQuantity;
                        setAvailableQuantity(availableQty)
                        setSFAObject({...SFAObject,['availableQuantity']:availableQty})
                    }else{
                        Swal.fire({
                            icon: 'error',
                            text: localString(displayError(result)),
                        });
                    }
                }
            });
        }
    }

    const getParts =()=>{
        let actionArrList = [...actionList]
        let partsList = []

        if (SFAObject.Action) {
            partsList = flatMap(actionArrList, (item)=> {
                if (SFAObject.Action.FaultActionID === item.faultAction.FaultActionID ) {
                    if (item.parts) {
                        return item.parts
                    }
                }
            })
        }

        partsList = compact(partsList);
        partsList = uniqBy(partsList, 'PartID');

        let finishedGoodID = consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.FinishedGoodID;
        let fullBoxUnit={};
        if(allParts){
            fullBoxUnit = find(allParts.productFinishedGoodMappings, function (o) {
                return o.PartID == finishedGoodID
            })
        }
        // if (SFAObject.Action.FaultActionCode == 'REP') {
        //     fullBoxUnit.part.Type = 'Part'
        //     fullBoxUnit.part.Charge = fullBoxUnit.Charge
        //     fullBoxUnit.part.TaxSlab = fullBoxUnit.TaxSlab
        //     fullBoxUnit.part.TaxPercentage = fullBoxUnit.TaxPercentage
        //     fullBoxUnit.part.IsTaxable = fullBoxUnit.IsTaxable
        //     if (fullBoxUnit.part && fullBoxUnit.part.Serialized && consumerServiceRequest && [1,2].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
        //         // pass when service type is pickup or carryin and full box is serialised
        //     } else {
        //         partsList.unshift(fullBoxUnit.part)
        //     }
        // }

        let SFAPartList = [];
        each(structuredSFA,(value)=>{
            if (value && value.Part && Object.keys(value.Part) && Object.keys(value.Part).length > 0) {
                SFAPartList.push(value.Part);
            }
        })

        let result = partsList.filter((o1)=>{
            if (SFAPartList && SFAPartList.length > 0) {
                return !SFAPartList.some((o2)=>{
                    return o1.PartID === o2.PartID;
                });
            } else {
                return partsList
            }
        })

        let selectedParts = map(addedSFA,'Part');
        let selectedPartIDs = map(selectedParts,'PartID');
            remove(result,(part)=>{ //remove already selected parts from the dropdown
                return selectedPartIDs.indexOf(part.PartID) > -1;
            });
        partsList = result;
        if (partsList && partsList.length > 0) {
            let partListDropdown = partsList.map(v=>({...v,value:v.PartCode + ' - ' + v.Name + ' - ' + v.Description}))
            setPartList(partListDropdown)
            // setValidation(false)
            setShowRecords({...showRecords,needPart:true})
        } else {
            let partListDropdown = partsList.map(v=>({...v,value:v.PartCode + ' - ' + v.Name + ' - ' + v.Description}))
            setPartList(partListDropdown)
            setShowRecords({...showRecords,needPart:false})
            // setValidation(true)
        }
        // let partsList = actionArrList && actionArrList.filter(item=>{
        //     if(item.faultAction.FaultActionID == filterData.FaultActionID){
        //         if (item.parts) {
        //             return item.parts
        //         }
        //     }
        // })

        // if(partsList && partsList[0] && partsList[0].parts && partsList[0].parts.length){
        //     setValidation(false)
        //     let partListDropdown = partsList[0].parts.map(v=>({...v,value:v.PartCode + ' - ' + v.Name + ' - ' + v.Description}))
        //     setPartList(partListDropdown)
        // }else{
        //     // setValidation(true)
        //     // setPartList([])
        // }
        
    }

    const clearAllPartInputs =  ()=> {
        setFilterData({
            symptomCode:"",
            faultCode:"",
            Action:"",
            partCode:"",
            warrantyCode:""
        })
        setIsSelected({
            isSymptomSelected:false,
            isFaultSelected:false,
            isActionSelected:false,
            isPartSelected:false,
        })
        setAvailableQuantity("")
        setSFAObject({})
        setDisableAddButton(true)
    }

    const removeAddedFaultCode = (e,index)=> {
        e.preventDefault();
        if (index > -1) {
            pullAt(addedSFA, [index])
            setAddedSFA([...addedSFA])
        }
        
        // if(self.selectedAction){ //if sfa is deleted while adding a new SFA, the removed part should be available in the part dropdown
        //     self.getParts();
        // }
    }

    const addSymptomFaultAction = ()=> {
        let arr = [];
        let object = {}
        if (SFAObject.Symptom && showRecords.RecordSymptoms) {
            object.Symptom = SFAObject.Symptom
        }
        if (SFAObject.Fault && showRecords.RecordFaults) {
            object.Fault = SFAObject.Fault
        }
        if (SFAObject.Action && showRecords.RecordActions) {
            object.Action = SFAObject.Action
        }
        if (SFAObject.Part && showRecords.RecordParts ) { //&& showRecords.needPart
            object.Part = SFAObject.Part
            if (SFAObject.Warranty) {
                object.Warranty = SFAObject.Warranty
            } else {
                object.Warranty = defaultWarranty
            }
            object.availableQuantity = SFAObject.availableQuantity
        }
        else {
            object.Warranty = UnderWarrantyAndApplicableType
        }
        if(consumerServiceRequest && consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.IsUnderWarranty){
            object.Warranty = UnderWarrantyAndApplicableType
        }
        arr.push(object)
        setAddedSFA([...addedSFA,...arr])
        clearAllPartInputs()
    }

    useEffect(() => {
        getFaults()        
    }, [filterData.symptomID])

    useEffect(() => {
        getActions();
    }, [filterData.faultID])

    useEffect(() => {
        if(filterData.FaultActionID){
            getParts()
        }
        if(filterData.partCode){
            getAvailableQuantity()
        }
    }, [filterData.FaultActionID,filterData.partCode])

    const dropdownArray = [{
        labelText:localString('Symptom'),
        dataKey:"symptomCode",
        showLabel:showRecords.RecordSymptoms,
        divClass:"col-sm-2",
        id:"symptom-codes", 
        customClass:"symptom-codes custom-sfa-dropdown",
        showSymptomLoader:insideLoaders.showSymptomLoader,
        dropdownList:symptomList,
        onInputFunc:((data)=>{
            setFilterData({...filterData,
                ['symptomCode']:data.value,
                ['symptomID']:data.SymptomID,
                ['oemid']:data.OEMID,
                ['faultCode']:"",
                ['Action']:"",
                ['partCode']:"",
                ['warrantyCode']:"",
            })
            setIsSelected({...isSelected,['isSymptomSelected']:true})
            let obj = {...data}
            delete obj.value
            setSFAObject({...SFAObject,['Symptom']:obj})
            setAvailableQuantity("")
        })
    },{
        labelText:localString('Fault'),
        dataKey:"faultCode",
        showLabel:showRecords.RecordFaults,
        divClass:"col-sm-2",
        id:"fault-codes", 
        customClass:"fault-codes custom-sfa-dropdown",
        showSymptomLoader:insideLoaders.showFaultLoader,
        dropdownList:faultList,
        onInputFunc:((data)=>{
            setFilterData({...filterData,
                ['faultCode']:data.value,
                ['faultID']:data.FaultID,
                ['Action']:"",
                ['partCode']:"",
                ['warrantyCode']:""
            })
            setIsSelected({...isSelected,['isFaultSelected']:true})
            let obj = {...data}
            delete obj.value
            setSFAObject({...SFAObject,['Fault']:obj})
            setAvailableQuantity("")
        })
    },{
        labelText:localString('Action'),
        dataKey:"Action",
        showLabel:showRecords.RecordActions,
        divClass:"col-sm-2",
        id:"action-codes", 
        customClass:"action-codes custom-sfa-dropdown",
        showSymptomLoader:insideLoaders.showActionLoader,
        dropdownList:actionListDropdown,
        onInputFunc:((data)=>{
            setFilterData({...filterData,
                ['Action']:data.value,
                ['ActionID']:data.ActionID,
                ['FaultActionID']:data.faultAction.FaultActionID,
                ['partCode']:"",
                ['warrantyCode']:""
            })
            let obj = {...data}
            delete obj.value
            let tempObj = obj.faultAction
            tempObj.selectedSFAMappingID=obj.SymptomFaultFaultActionMappingID
            setSFAObject({...SFAObject,['Action']:tempObj})
            setAvailableQuantity("")
        })
    },{
        labelText:localString('Part'),
        dataKey:"partCode",
        showLabel:showRecords.RecordParts,
        divClass:"col-sm-2",
        id:"part-codes", 
        customClass:validation ? "part-codes custom-sfa-dropdown sfa-disabled-padding" : "part-codes custom-sfa-dropdown",
        showSymptomLoader:insideLoaders.showPartLoader,
        dropdownList:partList,
        disable:validation,
        onInputFunc:((data)=>{
            setFilterData({...filterData,
                ['partCode']:data.value
            })
            let obj = {...data}
            delete obj.value
            setSelectedPart(obj)
            setSFAObject({...SFAObject,['Part']:obj})
            setAvailableQuantity("")
        })
    },{
        labelText:localString('Warranty'),
        dataKey:"warrantyCode",
        showLabel:showRecords.RecordParts && (consumerServiceRequest && !consumerServiceRequest.IsWarrantyApplicable) || (consumerServiceRequest && !consumerServiceRequest.IsUnderWarranty),
        divClass:"col-sm-2",
        id:"part-symptom_part_warranty", 
        customClass:validation ? "symptom_part_warranty custom-sfa-dropdown sfa-disabled-padding" : "symptom_part_warranty custom-sfa-dropdown",
        dropdownList:warrantyType,
        disable:validation,
        onInputFunc:((data)=>{
            setFilterData({...filterData,
                ['warrantyCode']:data.value
            })
            let obj = {...data}
            delete obj.value
            setSFAObject({...SFAObject,['Warranty']:obj})
        })
    },{
        labelText:localString('Avl. Qty.'),
        avlQty:true,
        showLabel:showRecords.RecordParts ,
        divClass:"col-sm-1 qty-div-loader",
        customClass:"available-quantity-value",
        showSymptomLoader:insideLoaders.showAvailableQuantityLoader,
        availableQuantity:availableQuantity,
    }]

    return (
        <>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper 
                        title={localString("Symptoms Reported")}
                        showDownloadButtonIcon={
                            checkIfSFAEditable() && !isSymptomEditableFlag
                        }
                        editDeviceClick={editSymptomClick}    
                    >
					<SymptomFaultActionJSX 
                        {...props}
                        isSymptomEditableFlag={isSymptomEditableFlag}
                        structuredSFA={structuredSFA}
                        showRecords={showRecords}
                        deleteSymptomFaultAction={deleteSymptomFaultAction}
                        requestSFA={requestSFA}
                        showSymptomFaultActionModal={showSymptomFaultActionModal}
                        cancelSymptomClick={cancelSymptomClick}
                        irisModal={irisModal}
                        closeModal={closeModal}
                        dropdownArray={dropdownArray}
                        filterData={filterData}
                        addSymptomFaultAction={addSymptomFaultAction}
                        addedSFA={addedSFA}
                        removeAddedFaultCode={removeAddedFaultCode}
                        saveSymptomFaultAction={saveSymptomFaultAction}
                        disableAddButton={disableAddButton}
                    />
					</PageContentWrapper>
				</div>
			</div>
		</>
    )
}

export default SymptomFaultAction
