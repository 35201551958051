import React, { useState } from 'react';
import { Button, Input } from "sfy-react";
import { cloneDeep } from 'lodash';
import Swal from "sweetalert2";

import { displayError, validateThis } from '../../../../../Utility/Utils';
import './LoanerEstimation.scss'

const LoanerEstimation = (props) => {
    const {
        loanerChargesResult, loanerCharges, currencySymbol, currentLoanerStatus, localString, accessFn,
        consumerServiceRequest, loanerDetails, setLoader, fetchLoanerDetailsByCsrId, getRequestById, apiCall,
    } = props;
    const qFilter = JSON.parse(props.qFilter);
    const [chargesArray, setChargesArray] = useState([]);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const chargeType = [
        {
            Display: localString("Other Charge"),
            Type: 'Other',
            Placeholder: localString("Other Charge"),
            showCharge: (item) => {
                let arr = cloneDeep(chargesArray);
                let obj = {...item}
                arr.push(obj);
                setChargesArray(arr);
            },
            Name: '',
        },
        {
            Display: 'Discount',
            Type: 'Discount',
            Placeholder: localString("Discount"),
            showCharge: (item) => {
                let arr = cloneDeep(chargesArray);
                let obj = {...item}
                arr.push(obj);
                setChargesArray(arr);
            }
        }
    ];

    const chargeArrayValue = (index, name, value) => {
        let arr = cloneDeep(chargesArray);
        arr[index][name] = value;
        setChargesArray(arr);        
    }

    const removeCharge = (index) => {
		let arr = [...chargesArray];
		arr.splice(index, 1);
		setChargesArray(arr);
    }

    const generateLoanerInvoice = () => {
        let data = {
            "ConsumerServiceRequestID" : consumerServiceRequest.ConsumerServiceRequestID,
            "Entity": "LoanerRequest",
            "EntityRequestID": loanerDetails.loanerRequestID,
            "generatePdf": true
        };
        setLoader(true);
		apiCall({
			url: 'fetchLoanerInvoice',
			data: data,
			callBack:(result) => {
				setLoader(false);
				if(result.success){
                    window.open(result.data.InvoiceUrl);
                    getRequestById();
                    fetchLoanerDetailsByCsrId();
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const saveLoanerCharges = () => {
        let isArrayValid = true;
        let invalidArrayParam = '';
        let chargesArr = cloneDeep(chargesArray);

        chargesArr.forEach(function(charge) {
            charge.ChargeRealm = "Loaner";
            charge.ChargeEntityID = consumerServiceRequest.ConsumerServiceRequestID;
            charge.IsTaxable = true;
            charge.ChargeType = 'Credit';
            let validator = validateThis(charge, [
                {
                    checkType: qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 ? 'isAlphaNumericTurkey' : 'isAlphaNumeric',
                    key: 'Name'
                }, 
                {
                    checkType: 'isFloat',
                    key: 'Charge'
                }, 
                {
                    checkType: qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 ? 'isAlphaNumericTurkey' : 'isAlphaNumeric',
                    key: 'Remarks'
                }
            ]);

            if (!validator.isValid) {
                isArrayValid = false;
                invalidArrayParam = validator.invalidParams[0];
                if (invalidArrayParam === "Charge") {
                    invalidArrayParam = "Amount";
                } else if (invalidArrayParam === "Remarks") {
                    invalidArrayParam = "Reason";
                }
            }
        });

        if (!isArrayValid) {
            Swal.fire({
                icon: 'error',
                text: localString("Please enter correct ") + localString(invalidArrayParam),
                confirmButtonText: localString('OK')
            });
            return;
        }
        let data = {
            ChargeArray: chargesArr,
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            ServiceTypeID: consumerServiceRequest.ServiceTypeID,
            PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID,
            LoanerRequestID: loanerDetails.loanerRequestID,
        };
        setLoader(true);
		apiCall({
			url: 'addLoanerCharges',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    setChargesArray([]);
                    getRequestById();
                    fetchLoanerDetailsByCsrId();
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    };

    return (
        <div className="loaner-estimation">
            <div className="request-charges-div">
                <div className="row custom-padding">
                    <div className="col-sm-12">
                        <h4 className="charges-panel-subheader text-capitalize remove-margin">{localString("Loaner Estimation")}</h4>
                    </div>
                </div>
                <hr className="remove-margin estimation-details-divider"/>
                <div className="row custom-padding">
                    <div className="col-sm-6">
                        <p className="charges-panel-subheader text-capitalize remove-margin">{localString("Type")}</p>
                    </div>
                    <div className="col-sm-4">
                        <p className="charges-panel-subheader text-capitalize remove-margin align-right">{localString("Amount")} (<span className="currency" dangerouslySetInnerHTML={{__html: currencySymbol}}></span>)</p>
                    </div>
                </div>
                <div className="request-panel-padding">
                    <hr className="remove-margin estimation-details-divider"/>
                    {
                        loanerCharges && loanerCharges.map(charge=>{
                            return (
                                <div className="row custom-padding">
                                    <div>
                                        <div className="col-sm-8">
                                        {
                                            (['LoanerCharges','Other','Discount'].indexOf(charge.Type) > -1) && 
                                            <div className="charges-panel-content">{charge.Name ? localString(charge.Name) : charge.Remarks ? localString(charge.Remarks) : ''}</div>
                                        }
                                        </div>
                                        <div className="col-sm-2">
                                            {
                                                !(['WarrantyConcession'].indexOf(charge.Type) > -1) && 
                                                <div className="charges-panel-content align-right">{(charge.TaxArray && charge.TaxArray.length > 0) ? charge.Charge : charge.TotalAmount}</div>
                                            }
                                            {
                                                (['WarrantyConcession'].indexOf(charge.Type) > -1) && 
                                                <div className="charges-panel-content align-right"> - {(charge.TaxArray && charge.TaxArray.length > 0) ? charge.Charge : charge.TotalAmount}</div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        (['Discount'].indexOf(charge.Type) < 0) && 
                                            charge.TaxArray && charge.TaxArray.map(tax=>{
                                                return(
                                                    <div>
                                                    {
                                                        tax.TaxSlab > 0 && 
                                                        <div>
                                                            <div className="col-sm-6">
                                                                <div className="charges-panel-content">{tax.TaxDisplay ? tax.TaxDisplay : tax.TaxType}</div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="charges-panel-content align-right">{tax.TaxVal}</div>
                                                            </div>
                                                        </div>
                                                    }
                                                    </div>
                                                )
                                            })
                                    }
                                </div>
                            )
                        })
                    }
                    <hr className="remove-margin estimation-details-divider"/>
                    <div className="row custom-padding">
                        <div className="col-sm-6">
                            <div className="charges-panel-content">{localString("Total Tax Amount")}</div>
                        </div>
                        <div className="col-sm-4">
                            <div className="charges-panel-content align-right">{loanerChargesResult && loanerChargesResult.TaxAmount}</div>
                        </div>
                    </div>
                    <hr className="remove-margin estimation-details-divider"/>
                    <div className="row custom-padding">
                        <div className="col-sm-6">
                            <div className="charges-panel-content">{localString("Total Amount")}</div>
                        </div>
                        <div className="col-sm-4">
                            <div className="charges-panel-content align-right">{loanerChargesResult && loanerChargesResult.TotalBilledAmount}</div>
                        </div>
                    </div>
                    <hr className="remove-margin estimation-details-divider"/>
                    <div className="row custom-padding">
                        <div className="col-sm-6">
                            <div className="charges-panel-content">{localString("Total Payable")}</div>
                        </div>
                        <div className="col-sm-4">
                            <div className="charges-panel-content align-right">{loanerChargesResult && loanerChargesResult.TotalPayableAmount}</div>
                        </div>
                    </div>
                    <hr class="remove-margin estimation-details-divider"/>
                    <div class="row custom-padding">
                        {
                            chargesArray && (chargesArray.length > 0) && chargesArray.map((charge, k) => <div className="col-sm-12">
                                <div class="row input-box-margin" style={{ padding: '10px' }}>
                                    { ['Other'].indexOf(charge.Type) > -1 && <div className="col-xs-4">
                                        <div className="">
                                            {/* <input 
                                                type="text" 
                                                className="form-control charges-form-input" 
                                                data-required="required" 
                                                onChange={() => {}}
                                                // onkeyup={nameCharge} 
                                                name={charge.Name+charge.i} 
                                                value={charge.Name || ''} 
                                                placeholder={charge.Placeholder} 
                                                data-type="alphanumaric-text"
                                            /> */}
                                            <Input
                                                value={charge.Name || ''}
                                                // key={index}
                                                handleOnChange={(e) => {
                                                    chargeArrayValue(k, 'Name', e.target.value)
                                                }}
                                                name={charge.Name+charge.i} 
                                                id={k+charge.Type}
                                                placeholder={charge.Placeholder}
                                            />
                                        </div>
                                    </div> }
                                    {['Part'].indexOf(charge.Type) < 0 && <div className={(charge.Type == 'Other') ? 'col-xs-3' : 'col-xs-4'}>
                                        <div className="">
                                            {/* <input 
                                                type="text" 
                                                className="form-control charges-form-input" 
                                                data-required="{charge.Type == 'Other' ? 'required' : ''}" 
                                                onChange={() => {}}
                                                // onkeyup={remarkCharge} 
                                                value={charge.Remarks || ''} 
                                                placeholder={charge.Type == 'Other' ? localString('Reason') : localString('Remarks')}
                                                data-type="alphanumaric-text"
                                            /> */}
                                            <Input
                                                value={charge.Remarks || ''}
                                                handleOnChange={(e) => {
                                                    chargeArrayValue(k, 'Remarks', e.target.value)
                                                }}
                                                // name={charge.Name+charge.i} 
                                                id={k+charge.Type}
                                                placeholder={charge.Type == 'Other' ? localString('Reason') : localString('Remarks')}
                                            />
                                        </div>
                                    </div> }
                                
                                    {(['Part'].indexOf(charge.Type) < 0) && <div className="col-xs-3">
                                        <div className="">
                                            {/* <input 
                                                type="text" 
                                                className="form-control charges-form-input" 
                                                // onkeyup={priceCharge} 
                                                onChange={() => {}}
                                                value={charge.Charge || ''} 
                                                data-required="required" 
                                                min="0" 
                                                data-type="numaric" 
                                                placeholder={localString('Amount')}
                                            /> */}
                                            <Input
                                                value={charge.Charge || ''}
                                                handleOnChange={(e) => {
                                                    chargeArrayValue(k, 'Charge', e.target.value)
                                                }}
                                                min="0"
                                                // name={charge.Name+charge.i} 
                                                id={k+charge.Charge}
                                                placeholder={localString('Amount')}
                                            />
                                        </div>
                                    </div>}
                                    {(['Part'].indexOf(charge.Type) < 0) && <div className="col-xs-2" style={{ top: '10px' }}> 
                                        <span 
                                            className="glyphicon glyphicon-minus-sign delete-input-charge-btn" 
                                            onClick={() => removeCharge(k)}
                                        ></span>
                                    </div> }
                                </div>
                            </div>)
                        }
                    </div>
                    { (accessFn('REPAIR_DEVICE') && ['LOST','LOAI'].indexOf(currentLoanerStatus) > -1) && <div>
                        <div className="row custom-padding">
                            <div className="col-sm-12">
                                <p className="charges-panel-subheader text-capitalize remove-margin">{localString("ADD CHARGES")}</p>
                            </div>
                        </div>
                        <div className="row custom-padding">
                            {
                                chargeType && (chargeType.length > 0) && chargeType.map((charge, k) => {
                                    return(
                                        <div className="col-sm-6 custom-charges-button-container" key={k}>
                                            <button className="btn custom-charges-button" onClick={() => charge.showCharge(charge)}>
                                                <span className="glyphicon glyphicon-plus"></span> {localString(charge.Display)}
                                            </button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div> }
                    <div className="content-footer" style={{ paddingTop: '10px' }}>
                        { chargesArray && (chargesArray.length > 0) && 
                            <button 
                                // className="charges-button btn" 
                                className="btn loanerBtn" 
                                type="button" 
                                onClick={saveLoanerCharges}
                            >
                                {localString("Save")} 
                            </button> 
                        }
                        { (['LOPR'].indexOf(currentLoanerStatus) > -1) && 
                            <button 
                                className="btn loanerBtn" 
                                type="button" 
                                onClick={generateLoanerInvoice}
                            >
                                {localString("Generate Invoice")} 
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoanerEstimation
