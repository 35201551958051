import React from 'react';
import {Modal} from 'sfy-react';
import GSXRaiseRequest from './GSXRaiseRequest';

import './GSXRaiseRequestModal.scss'

const GSXRaiseRequestModal = (props) => {
	const {localString, showModal, closeModal} = props;
		return ( <>
			<Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal gsxRaiseRequestModal full-screen-modal'>
				<Modal.Header>
					<h2> {localString("Raise GSX Request")} </h2>
				</Modal.Header>
				<Modal.Body>
					<GSXRaiseRequest {...props}/>
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
		</> )
}
export default GSXRaiseRequestModal;