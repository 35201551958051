import moment from 'moment';
import { sortBy } from 'lodash';
import Papa from "papaparse";
import {isEqual } from 'lodash'

const EMAILPATTERN = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+[.][a-zA-Z]+([.][a-zA-Z]+)*$/;
const MOBILEPATTERN = /^[1-9][0-9]{9}$/;
const IMIEPATTERN = /^[A-Za-z0-9]{5,30}$/;
const NAMEPATTERN = /^[A-Za-z0-9\s]{1,}$/;
const ALPHANUMERICPATTERN = /^[\w]+$/;
const EXTERNALREFIDPATTERN = /^[0-9]{3}-[0-9]{7}-[0-9]{7}$/;
const REFERENCEPATTERN = /^[0-9a-zA-Z]+$/;
const FLIPKARTREFIDPATTERN = /^[A-Z]{2}[0-9]{18}$/;
const NUMBERPATTERN = /^\d+$/;
const FLOATPATTERN = /^[+-]?([0-9]*[.])?[0-9]+$/;
const ALPHANUMERICPATTERNWITHSPACE = /^[\w\s\p{L}]+$/u;
const TURKEYALPHANUMERICPATTERN = /^[\wığüşöçĞÜŞÖÇİ\-\s]+$/;
const NAME = /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/;
const ALPHANUMERICSYMBOL = /^[a-zA-Z0-9-]+$/;
const PANREGEX = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;


const PATTERN = Object.freeze({
	email: EMAILPATTERN,
	number: NUMBERPATTERN,
	numeric: NUMBERPATTERN,
	float: FLOATPATTERN,
	alphanumeric: ALPHANUMERICPATTERN,
	mobile: MOBILEPATTERN,
	name: NAMEPATTERN,
	// name: NAME
});

// const PATTERN = Object.freeze({
// 	email: EMAILPATTERN,
// 	number: NUMBERPATTERN,
// 	numeric: NUMBERPATTERN,
// 	float: FLOATPATTERN,
// 	alphanumeric: ALPHANUMERICPATTERN,
// 	mobile: MOBILEPATTERN,
// 	name: NAME
// });

const returnLocalDateTime = (timeUTC, momentFormat) => {
	if (timeUTC) {
		var timeLocal = moment.utc(timeUTC).toDate();
		var abbrivatedZone = (timeLocal.toString().split("(")[1] && timeLocal.toString().split("(")[1].match(/\b([A-Z])/g)) ? timeLocal.toString().split("(")[1].match(/\b([A-Z])/g).join('') : '';	
		timeLocal = moment(timeLocal).format(momentFormat ? momentFormat : 'DD-MM-YYYY HH:mm:ss');
		return abbrivatedZone ? timeLocal + " (" + abbrivatedZone + ")" : timeLocal;
	} else {
		return '';
	}
}

const getLocalDateTime = (timeUTC, momentFormat) => {
	if (timeUTC) {
		var timeLocal = moment.utc(timeUTC).toDate();
		timeLocal = moment(timeLocal).format(momentFormat ? momentFormat : 'DD-MM-YYYY HH:mm:ss');
		return timeLocal;
	} else {
		return '';
	}
}

const getAbbrivatedZone = (timeUTC) => {
	let date = timeUTC || new Date();
	if (date) {
		let timeLocal = moment.utc(date).toDate();
		let abbrivatedZone = (timeLocal.toString().split("(")[1] && timeLocal.toString().split("(")[1].match(/\b([A-Z])/g)) ? timeLocal.toString().split("(")[1].match(/\b([A-Z])/g).join('') : '';	
		return abbrivatedZone ? abbrivatedZone : '';
	} else {
		return '';
	}
}

const generateUUID = () => {
	//generates 16 digit UUID
	let d = new Date().getTime();
	let uuid = 'xxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		let r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
	return uuid;
}
const getCookie = (cookieName) => {
	let name = cookieName + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	const cookieArray = decodedCookie.split(";");
	for (let i = 0; i < cookieArray.length; i++) {
		let singleCookie = cookieArray[i];
		while (singleCookie.charAt(0) == " ") {
			singleCookie = singleCookie.substring(1);
		}
		if (singleCookie.indexOf(name) == 0) {
			return singleCookie.substring(name.length, singleCookie.length);
		}
	}
	return "";
}
const getLinkUri = (link) => {
	return link.replace('?','%3F').replace(/&/g,'%26');
}
const displayError = (resultObj) => {
	let errorMessage;
	if (resultObj) {
		if (resultObj.msg) {
			errorMessage = resultObj.msg;
		}
		else if (resultObj.message) {
			errorMessage = resultObj.message;
		}
		else {
			errorMessage = 'Something went wrong';
		}
	}
	else {
		errorMessage = 'Something went wrong';
	}
	if (typeof errorMessage == 'string') {
		if (errorMessage == 'Serial Number Not Unique') {
			return 'Device with same Serial Number or IMEI is already added';
		}
		else {
			return errorMessage;
		}
	}
	else {
		return 'Something went wrong';
	}
}
const checkName = function (data) {
	return NAMEPATTERN.test(data);
}
const checkProductUniqueID = function (data) {
	return IMIEPATTERN.test(data);
}
const checkEmail = function (data) {
	return EMAILPATTERN.test(data);
}
const checkMobile = function (data) {
	return MOBILEPATTERN.test(data);
};

const checkExternalRefID = function (data) {
	return EXTERNALREFIDPATTERN.test(data);
};

const checkFlipkartRefID = function (data) {
	return FLIPKARTREFIDPATTERN.test(data);
};

const checkAlphaNumeric = function (data) {
	return ALPHANUMERICPATTERN.test(data);
};
const checkAlphaNumericSymbol = function (data) {
	return ALPHANUMERICSYMBOL.test(data);
};
const checkMobileNumber = function (data) {
	return NUMBERPATTERN.test(data);
};
const checkNumberPattern = function (data) {
	return NUMBERPATTERN.test(data);
};
const checkFloatPattern = function (data) {
	return FLOATPATTERN.test(data);
};
const checkAlphaNumericName = function (data) {
	return ALPHANUMERICPATTERNWITHSPACE.test(data);
}
const checkPlan = function (data) {
	return ALPHANUMERICPATTERNWITHSPACE.test(data);
}
const checkPANNumber = (data) => {
	return data && (data.length == 10) && PANREGEX.test(data);
}
const getValueFromAdressComponent = (addressComponents, field) => {
	if (!Array.isArray(addressComponents) || addressComponents.length <= 0) return ''
	for (let component of addressComponents) {
	  if (component.types.includes(field)) {
		return component
	  }
	}
	return ''
}

const manipulateDate = (value, operator) => {
	let date = new Date();
	if (operator == 'add') {
		date.setDate(date.getDate() + value);
	}
	else if (operator == 'minus') {
		date.setDate(date.getDate() - value);
	}
	return new Date(date);
}

const encrypt =  (val) => {
	if (val) {
		return window.btoa(val);
	} else {
		return;
	}
};

var checkEncryption = function (val) {
	if (val) {
		try {
			if (isNaN(val)) {
				return window.atob(val);
			} else {
				return false;
			}
		} catch (e) {
			return false;
		}
	} else {
		return false;
	}
}

const decrypt =  (val) => {
	if (val) {
		return window.atob(val);
	} else {
		return;
	}
};

const checkReferenceNumber = function (data) {
	return REFERENCEPATTERN.test(data);
};

const DiagnosisChannels = {
	"FK_Laptop_Diag": "FK",
	"BulkDiagnosis": "AZ"
};

const trimData = (val, trimLength) => {
	if(val && (val.length > trimLength)) {
		return val.substring(0, trimLength);
	}else{
		return val;
	}
}

//get params value from the url.
const getQueryStringValue = (key, flag=true) => {
	key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, '\\$&'); // escape RegEx meta chars
	var match = window.location.search.match(new RegExp('[?&]' + key + '=([^&]+)(&|$)'));
	if(flag){
		return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
	}else{
		return match && match[1];
	}
};

const validateAttachments = (validExtensions = [], maxSizeLimit, attachment) => {
	let extension = attachment.type.split('/')[1];
	if(!attachment){
		return false;
	}else if(validExtensions.length && !validExtensions.includes(`.${extension}`)) {
		return false;
	}else if(maxSizeLimit && maxSizeLimit < attachment.size) {
		return false;
	}else {
		return true
	}
}

const returnTimezone = () => {
	let offset = new Date().getTimezoneOffset();
	let sign = '-';
	let offsetInHours;
	let offsetInHoursDecimal = offset / 60 // -5.5
	if (offset < 0) {
		sign = '+'
		offsetInHours = Math.ceil(offsetInHoursDecimal)
	} else {
		offsetInHours = Math.floor(offsetInHoursDecimal)
	}

	let offsetInHoursStr = Math.abs(offsetInHours)
	if (offsetInHours < 10) {
		offsetInHoursStr = '0' + offsetInHoursStr
	}
	let mins = '00'
	if (offset % 60) {
		mins = (Math.abs(offsetInHoursDecimal) - Math.abs(offsetInHours)) * 60
		if (mins < 10) {
			mins = '0' + mins
		}
	}
	return (sign + offsetInHoursStr + ':' + mins)
}

const downloadCSV = (csv, fileName) => {
	let hiddenElement = document.createElement('a');
	hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
	hiddenElement.target = '_blank';
	hiddenElement.download = fileName;
	document.body.appendChild(hiddenElement);
	hiddenElement.click();
}

//Indian Currency Format for applying the commas
const formatIndianCurrencyRs = (num) => {
	if (num !== "" || num !== null) {
		let n1, n2;
		num = num + '' || '';
		// works for integer and floating as well
		n1 = num.split('.');
		n2 = n1[1] || null;
		n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
		num = n2 ? n1 + '.' + n2 : n1;
		return num;
	}
}

const returnLocalSlot=(slotUTC) =>{
	if (slotUTC) {
		let slotLocal = moment.utc(slotUTC, 'HH:mm:ss');
		var slotLocal1 = moment().toDate();
		slotLocal = moment(slotLocal).add(moment().utcOffset(), 'm');
		slotLocal = moment(slotLocal).format('h:mm a');
		var abbrivatedZone = slotLocal1.toString().split("(")[1]?.match(/\b([A-Z])/g)?.join('');
		return abbrivatedZone ? slotLocal + " (" + abbrivatedZone + ")" : slotLocal;
	} else {
		return 'NA';
	}
}

const nationalIdValidation = (id) => {
	let singles = 0;
	let doubles = 0;
	let totalNumbers = 0;
	// id = parseInt(id);
	if (id != '') {
		if (id.substring(0, 1) != '0') {
			if (id.length == 11) {
				for (let i = 0; i < 10; i+=2) {
					let x = id.substr(i, 1);
					singles += parseInt(x);
				}
				for (let j = 1; j < 9; j+=2) {
					let y = id.substr(j, 1);
					doubles += parseInt(y);
				}
				let tenth = (((singles * 7) - (doubles)) % 10);
				for (let k = 0; k < 10; k++) {
					totalNumbers += parseInt(id.substr(k, 1));
				}
				let eleventh = (totalNumbers % 10);
				if (tenth == parseInt(id.substr(9, 1)) && eleventh == parseInt(id.substr(10, 1))) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		}
		else {
			return false;
		}
	}
	else {
		return false;
	}
}

const getLocaleDateString = (inputDate, lang) => {
	// shortedDate=getLocaleDateString(new Date()); sample call
	let languages = {
	"af-ZA" : "yyyy/MM/dd",
	"am-ET" : "d/M/yyyy",
	"ar-AE" : "dd/MM/yyyy",
	"ar-BH" : "dd/MM/yyyy",
	"ar-DZ" : "dd-MM-yyyy",
	"ar-EG" : "dd/MM/yyyy",
	"ar-OM" : "dd/MM/yyyy",
	"ar-IQ" : "dd/MM/yyyy",
	"ar-JO" : "dd/MM/yyyy",
	"ar-KW" : "dd/MM/yyyy",
	"ar-LB" : "dd/MM/yyyy",
	"ar-LY" : "dd/MM/yyyy",
	"ar-MA" : "dd-MM-yyyy",
	"ar-QA" : "dd/MM/yyyy",
	"ar-SA" : "dd/MM/yy",
	"ar-SY" : "dd/MM/yyyy",
	"ar-TN" : "dd-MM-yyyy",
	"ar-YE" : "dd/MM/yyyy",
	"arn-CL" : "dd-MM-yyyy",
	"as-IN" : "dd-MM-yyyy",
	"az-Latn-AZ" : "dd.MM.yyyy",
	"az-Cyrl-AZ" : "dd.MM.yyyy",
	"ba-RU" : "dd.MM.yy",
	"br-FR" : "dd/MM/yyyy",
	"be-BY" : "dd.MM.yyyy",
	"bg-BG" : "dd.M.yyyy",
	"bn-BD" : "dd-MM-yy",
	"bn-IN" : "dd-MM-yy",
	"bo-CN" : "yyyy/M/d",
	"bs-Latn-BA" : "d.M.yyyy",
	"bs-Cyrl-BA" : "d.M.yyyy",
	"ca-ES" : "dd/MM/yyyy",
	"co-FR" : "dd/MM/yyyy",
	"cs-CZ" : "d.M.yyyy",
	"cy-GB" : "dd/MM/yyyy",
	"da-DK" : "dd-MM-yyyy",
	"de-AT" : "dd.MM.yyyy",
	"de-CH" : "dd.MM.yyyy",
	"de-DE" : "dd.MM.yyyy",
	"de-LI" : "dd.MM.yyyy",
	"de-LU" : "dd.MM.yyyy",
	"dsb-DE" : "d. M. yyyy",
	"dv-MV" : "dd/MM/yy",
	"el-GR" : "d/M/yyyy",
	"en" : "dd-MM-yyyy",
	"en-US" : "M/d/yyyy",
	"en-GB" : "dd/MM/yyyy",
	"en-AU" : "d/MM/yyyy",
	"en-CA" : "dd/MM/yyyy",
	"en-NZ" : "d/MM/yyyy",
	"en-IE" : "dd/MM/yyyy",
	"en-ZA" : "yyyy/MM/dd",
	"en-JM" : "dd/MM/yyyy",
	"en-029" : "MM/dd/yyyy",
	"en-BZ" : "dd/MM/yyyy",
	"en-TT" : "dd/MM/yyyy",
	"en-ZW" : "M/d/yyyy",
	"en-PH" : "M/d/yyyy",
	"en-MY" : "d/M/yyyy",
	"en-IN" : "dd-MM-yyyy",
	"en-SG" : "d/M/yyyy",
	"es-MX" : "dd/MM/yyyy",
	"es-ES" : "dd/MM/yyyy",
	"es-GT" : "dd/MM/yyyy",
	"es-CR" : "dd/MM/yyyy",
	"es-PA" : "MM/dd/yyyy",
	"es-DO" : "dd/MM/yyyy",
	"es-VE" : "dd/MM/yyyy",
	"es-CO" : "dd/MM/yyyy",
	"es-PE" : "dd/MM/yyyy",
	"es-AR" : "dd/MM/yyyy",
	"es-EC" : "dd/MM/yyyy",
	"es-CL" : "dd-MM-yyyy",
	"es-UY" : "dd/MM/yyyy",
	"es-PY" : "dd/MM/yyyy",
	"es-BO" : "dd/MM/yyyy",
	"es-SV" : "dd/MM/yyyy",
	"es-HN" : "dd/MM/yyyy",
	"es-NI" : "dd/MM/yyyy",
	"es-PR" : "dd/MM/yyyy",
	"es-US" : "M/d/yyyy",
	"et-EE" : "d.MM.yyyy",
	"eu-ES" : "yyyy/MM/dd",
	"fa-IR" : "MM/dd/yyyy",
	"fi-FI" : "d.M.yyyy",
	"fil-PH" : "M/d/yyyy",
	"fo-FO" : "dd-MM-yyyy",
	"fr-CA" : "yyyy-MM-dd",
	"fr-CH" : "dd.MM.yyyy",
	"fr-FR" : "dd/MM/yyyy",
	"fr-LU" : "dd/MM/yyyy",
	"fr-MC" : "dd/MM/yyyy",
	"fr-BE" : "d/MM/yyyy",
	"fy-NL" : "d-M-yyyy",
	"ga-IE" : "dd/MM/yyyy",
	"gd-GB" : "dd/MM/yyyy",
	"gl-ES" : "dd/MM/yy",
	"gsw-FR" : "dd/MM/yyyy",
	"gu-IN" : "dd-MM-yy",
	"ha-Latn-NG" : "d/M/yyyy",
	"he-IL" : "dd/MM/yyyy",
	"hi-IN" : "dd-MM-yyyy",
	"hr-BA" : "d.M.yyyy.",
	"hr-HR" : "d.M.yyyy",
	"hsb-DE" : "d. M. yyyy",
	"hu-HU" : "yyyy. MM. dd.",
	"hy-AM" : "dd.MM.yyyy",
	"id" : "dd/MM/yyyy",
	"id-ID" : "dd/MM/yyyy",
	"ig-NG" : "d/M/yyyy",
	"ii-CN" : "yyyy/M/d",
	"is-IS" : "d.M.yyyy",
	"it-CH" : "dd.MM.yyyy",
	"it-IT" : "dd/MM/yyyy",
	"iu-Cans-CA" : "d/M/yyyy",
	"iu-Latn-CA" : "d/MM/yyyy",
	"ja-JP" : "yyyy/MM/dd",
	"ka-GE" : "dd.MM.yyyy",
	"kk-KZ" : "dd.MM.yyyy",
	"kl-GL" : "dd-MM-yyyy",
	"km-KH" : "yyyy-MM-dd",
	"kn-IN" : "dd-MM-yy",
	"ko-KR" : "yyyy-MM-dd",
	"kok-IN" : "dd-MM-yyyy",
	"ky-KG" : "dd.MM.yy",
	"lb-LU" : "dd/MM/yyyy",
	"lo-LA" : "dd/MM/yyyy",
	"lt-LT" : "yyyy.MM.dd",
	"lv-LV" : "yyyy.MM.dd.",
	"mk-MK" : "dd.MM.yyyy",
	"mn-MN" : "yy.MM.dd",
	"mn-Mong-CN" : "yyyy/M/d",
	"mi-NZ" : "dd/MM/yyyy",
	"ml-IN" : "dd-MM-yy",
	"moh-CA" : "M/d/yyyy",
	"mr-IN" : "dd-MM-yyyy",
	"ms-BN" : "dd/MM/yyyy",
	"ms-MY" : "dd/MM/yyyy",
	"mt-MT" : "dd/MM/yyyy",
	"nb-NO" : "dd.MM.yyyy",
	"ne-NP" : "M/d/yyyy",
	"nl-BE" : "d/MM/yyyy",
	"nl-NL" : "d-M-yyyy",
	"nn-NO" : "dd.MM.yyyy",
	"nso-ZA" : "yyyy/MM/dd",
	"oc-FR" : "dd/MM/yyyy",
	"or-IN" : "dd-MM-yy",
	"pa-IN" : "dd-MM-yy",
	"pl-PL" : "yyyy-MM-dd",
	"prs-AF" : "dd/MM/yy",
	"ps-AF" : "dd/MM/yy",
	"pt-BR" : "d/M/yyyy",
	"pt-PT" : "dd-MM-yyyy",
	"qut-GT" : "dd/MM/yyyy",
	"quz-BO" : "dd/MM/yyyy",
	"quz-EC" : "dd/MM/yyyy",
	"quz-PE" : "dd/MM/yyyy",
	"rm-CH" : "dd/MM/yyyy",
	"ro-RO" : "dd.MM.yyyy",
	"ru-RU" : "dd.MM.yyyy",
	"rw-RW" : "M/d/yyyy",
	"sa-IN" : "dd-MM-yyyy",
	"sah-RU" : "MM.dd.yyyy",
	"se-FI" : "d.M.yyyy",
	"se-SE" : "yyyy-MM-dd",
	"se-NO" : "dd.MM.yyyy",
	"si-LK" : "yyyy-MM-dd",
	"sk-SK" : "d. M. yyyy",
	"sl-SI" : "d.M.yyyy",
	"sma-SE" : "yyyy-MM-dd",
	"sma-NO" : "dd.MM.yyyy",
	"smj-NO" : "dd.MM.yyyy",
	"smj-SE" : "yyyy-MM-dd",
	"smn-FI" : "d.M.yyyy",
	"sms-FI" : "d.M.yyyy",
	"sr-Cyrl-BA" : "d.M.yyyy",
	"sr-Cyrl-CS" : "d.M.yyyy",
	"sr-Cyrl-ME" : "d.M.yyyy",
	"sr-Cyrl-RS" : "d.M.yyyy",
	"sr-Latn-BA" : "d.M.yyyy",
	"sr-Latn-CS" : "d.M.yyyy",
	"sr-Latn-ME" : "d.M.yyyy",
	"sr-Latn-RS" : "d.M.yyyy",
	"sq-AL" : "yyyy-MM-dd",
	"sv-FI" : "d.M.yyyy",
	"sv-SE" : "yyyy-MM-dd",
	"sw-KE" : "M/d/yyyy",
	"syr-SY" : "dd/MM/yyyy",
	"ta-IN" : "dd-MM-yyyy",
	"te-IN" : "dd-MM-yy",
	"tg-Cyrl-TJ" : "dd.MM.yy",
	"th" : "d/M/yyyy",
	"th-TH" : "d/M/yyyy",
	"tk -TM" : "dd.MM.yy",
	"tn-ZA" : "yyyy/MM/dd",
	"tr-TR" : "dd.MM.yyyy",
	"tt-RU" : "dd.MM.yyyy",
	"tzm-Latn-DZ" : "dd-MM-yyyy",
	"ug-CN" : "yyyy-M-d",
	"ur-PK" : "dd/MM/yyyy",
	"uk-UA" : "dd.MM.yyyy",
	"uz-Cyrl-UZ" : "dd.MM.yyyy",
	"uz-Latn-UZ" : "dd/MM yyyy",
	"vi-VN" : "dd/MM/yyyy",
	"wo-SN" : "dd/MM/yyyy",
	"xh-ZA" : "yyyy/MM/dd",
	"yo-NG" : "d/M/yyyy",
	"zh-CN" : "yyyy/M/d",
	"zh-MO" : "d/M/yyyy",
	"zh-TW" : "yyyy/M/d",
	"zh-SG" : "d/M/yyyy",
	"zh-HK" : "d/M/yyyy",
	"zu-ZA" : "yyyy/MM/dd",
	};

	let currentDate = inputDate ? new Date(inputDate) : new Date();
	let currentLanguage = lang ? lang : 'en-IN',
	year = inputDate ? currentDate.getFullYear() : '1970',
	month = inputDate ? currentDate.getMonth()+1 : '01',
	day = inputDate ? currentDate.getDate() : '01';
	languages = languages[currentLanguage] ? languages[currentLanguage] : "dd/MM/yyyy";
	const replaceDM = (newString) => {
		newString = '' + newString;
		return newString.length > 1 ? newString : '0' + newString;
	}
	languages = languages.replace(/yyyy/,year);
	languages = languages.replace(/yy/,String(year).substr(2));
	languages = languages.replace(/MM/,replaceDM(month));
	languages = languages.replace(/M/,month);
	languages = languages.replace(/dd/,replaceDM(day));
	languages = languages.replace(/d/,day);
	return languages;
}

const getDateFormat = (lang) => {
	let languages = {
	"af-ZA" : "YYYY/MM/DD",
	"am-ET" : "D/M/YYYY",
	"ar-AE" : "DD/MM/YYYY",
	"ar-BH" : "DD/MM/YYYY",
	"ar-DZ" : "DD-MM-YYYY",
	"ar-EG" : "DD/MM/YYYY",
	"ar-OM" : "DD/MM/YYYY",
	"ar-IQ" : "DD/MM/YYYY",
	"ar-JO" : "DD/MM/YYYY",
	"ar-KW" : "DD/MM/YYYY",
	"ar-LB" : "DD/MM/YYYY",
	"ar-LY" : "DD/MM/YYYY",
	"ar-MA" : "DD-MM-YYYY",
	"ar-QA" : "DD/MM/YYYY",
	"ar-SA" : "DD/MM/YY",
	"ar-SY" : "DD/MM/YYYY",
	"ar-TN" : "DD-MM-YYYY",
	"ar-YE" : "DD/MM/YYYY",
	"arn-CL" : "DD-MM-YYYY",
	"as-IN" : "DD-MM-YYYY",
	"az-Latn-AZ" : "DD.MM.YYYY",
	"az-Cyrl-AZ" : "DD.MM.YYYY",
	"ba-RU" : "DD.MM.yy",
	"br-FR" : "DD/MM/YYYY",
	"be-BY" : "DD.MM.YYYY",
	"bg-BG" : "DD.M.YYYY",
	"bn-BD" : "DD-MM-yy",
	"bn-IN" : "DD-MM-yy",
	"bo-CN" : "YYYY/M/D",
	"bs-Latn-BA" : "D.M.YYYY",
	"bs-Cyrl-BA" : "D.M.YYYY",
	"ca-ES" : "DD/MM/YYYY",
	"co-FR" : "DD/MM/YYYY",
	"cs-CZ" : "D.M.YYYY",
	"cy-GB" : "DD/MM/YYYY",
	"da-DK" : "DD-MM-YYYY",
	"de-AT" : "DD.MM.YYYY",
	"de-CH" : "DD.MM.YYYY",
	"de-DE" : "DD.MM.YYYY",
	"de-LI" : "DD.MM.YYYY",
	"de-LU" : "DD.MM.YYYY",
	"dsb-DE" : "D. M. YYYY",
	"dv-MV" : "DD/MM/YY",
	"el-GR" : "D/M/YYYY",
	"en" : "DD-MM-YYYY",
	"en-US" : "M/D/YYYY",
	"en-GB" : "DD/MM/YYYY",
	"en-AU" : "D/MM/YYYY",
	"en-CA" : "DD/MM/YYYY",
	"en-NZ" : "D/MM/YYYY",
	"en-IE" : "DD/MM/YYYY",
	"en-ZA" : "YYYY/MM/DD",
	"en-JM" : "DD/MM/YYYY",
	"en-029" : "MM/DD/YYYY",
	"en-BZ" : "DD/MM/YYYY",
	"en-TT" : "DD/MM/YYYY",
	"en-ZW" : "M/D/YYYY",
	"en-PH" : "M/D/YYYY",
	"en-MY" : "D/M/YYYY",
	"en-IN" : "DD-MM-YYYY",
	"en-SG" : "D/M/YYYY",
	"es-MX" : "DD/MM/YYYY",
	"es-ES" : "DD/MM/YYYY",
	"es-GT" : "DD/MM/YYYY",
	"es-CR" : "DD/MM/YYYY",
	"es-PA" : "MM/DD/YYYY",
	"es-DO" : "DD/MM/YYYY",
	"es-VE" : "DD/MM/YYYY",
	"es-CO" : "DD/MM/YYYY",
	"es-PE" : "DD/MM/YYYY",
	"es-AR" : "DD/MM/YYYY",
	"es-EC" : "DD/MM/YYYY",
	"es-CL" : "DD-MM-YYYY",
	"es-UY" : "DD/MM/YYYY",
	"es-PY" : "DD/MM/YYYY",
	"es-BO" : "DD/MM/YYYY",
	"es-SV" : "DD/MM/YYYY",
	"es-HN" : "DD/MM/YYYY",
	"es-NI" : "DD/MM/YYYY",
	"es-PR" : "DD/MM/YYYY",
	"es-US" : "M/D/YYYY",
	"et-EE" : "D.MM.YYYY",
	"eu-ES" : "YYYY/MM/DD",
	"fa-IR" : "MM/DD/YYYY",
	"fi-FI" : "D.M.YYYY",
	"fil-PH" : "M/D/YYYY",
	"fo-FO" : "DD-MM-YYYY",
	"fr-CA" : "YYYY-MM-DD",
	"fr-CH" : "DD.MM.YYYY",
	"fr-FR" : "DD/MM/YYYY",
	"fr-LU" : "DD/MM/YYYY",
	"fr-MC" : "DD/MM/YYYY",
	"fr-BE" : "D/MM/YYYY",
	"fy-NL" : "D-M-YYYY",
	"ga-IE" : "DD/MM/YYYY",
	"gd-GB" : "DD/MM/YYYY",
	"gl-ES" : "DD/MM/YY",
	"gsw-FR" : "DD/MM/YYYY",
	"gu-IN" : "DD-MM-YY",
	"ha-Latn-NG" : "D/M/YYYY",
	"he-IL" : "DD/MM/YYYY",
	"hi-IN" : "DD-MM-YYYY",
	"hr-BA" : "D.M.YYYY.",
	"hr-HR" : "D.M.YYYY",
	"hsb-DE" : "D. M. YYYY",
	"hu-HU" : "YYYY. MM. DD.",
	"hy-AM" : "DD.MM.YYYY",
	"id" : "DD/MM/YYYY",
	"id-ID" : "DD/MM/YYYY",
	"ig-NG" : "D/M/YYYY",
	"ii-CN" : "YYYY/M/D",
	"is-IS" : "D.M.YYYY",
	"it-CH" : "DD.MM.YYYY",
	"it-IT" : "DD/MM/YYYY",
	"iu-Cans-CA" : "D/M/YYYY",
	"iu-Latn-CA" : "D/MM/YYYY",
	"ja-JP" : "YYYY/MM/DD",
	"ka-GE" : "DD.MM.YYYY",
	"kk-KZ" : "DD.MM.YYYY",
	"kl-GL" : "DD-MM-YYYY",
	"km-KH" : "YYYY-MM-DD",
	"kn-IN" : "DD-MM-YY",
	"ko-KR" : "YYYY-MM-DD",
	"kok-IN" : "DD-MM-YYYY",
	"ky-KG" : "DD.MM.YY",
	"lb-LU" : "DD/MM/YYYY",
	"lo-LA" : "DD/MM/YYYY",
	"lt-LT" : "YYYY.MM.DD",
	"lv-LV" : "YYYY.MM.DD.",
	"mk-MK" : "DD.MM.YYYY",
	"mn-MN" : "YY.MM.DD",
	"mn-Mong-CN" : "YYYY/M/D",
	"mi-NZ" : "DD/MM/YYYY",
	"ml-IN" : "DD-MM-YY",
	"moh-CA" : "M/D/YYYY",
	"mr-IN" : "DD-MM-YYYY",
	"ms-BN" : "DD/MM/YYYY",
	"ms-MY" : "DD/MM/YYYY",
	"mt-MT" : "DD/MM/YYYY",
	"nb-NO" : "DD.MM.YYYY",
	"ne-NP" : "M/D/YYYY",
	"nl-BE" : "D/MM/YYYY",
	"nl-NL" : "D-M-YYYY",
	"nn-NO" : "DD.MM.YYYY",
	"nso-ZA" : "YYYY/MM/DD",
	"oc-FR" : "DD/MM/YYYY",
	"or-IN" : "DD-MM-YY",
	"pa-IN" : "DD-MM-YY",
	"pl-PL" : "YYYY-MM-DD",
	"prs-AF" : "DD/MM/YY",
	"ps-AF" : "DD/MM/YY",
	"pt-BR" : "D/M/YYYY",
	"pt-PT" : "DD-MM-YYYY",
	"qut-GT" : "DD/MM/YYYY",
	"quz-BO" : "DD/MM/YYYY",
	"quz-EC" : "DD/MM/YYYY",
	"quz-PE" : "DD/MM/YYYY",
	"rm-CH" : "DD/MM/YYYY",
	"ro-RO" : "DD.MM.YYYY",
	"ru-RU" : "DD.MM.YYYY",
	"rw-RW" : "M/D/YYYY",
	"sa-IN" : "DD-MM-YYYY",
	"sah-RU" : "MM.DD.YYYY",
	"se-FI" : "D.M.YYYY",
	"se-SE" : "YYYY-MM-DD",
	"se-NO" : "DD.MM.YYYY",
	"si-LK" : "YYYY-MM-DD",
	"sk-SK" : "D. M. YYYY",
	"sl-SI" : "D.M.YYYY",
	"sma-SE" : "YYYY-MM-DD",
	"sma-NO" : "DD.MM.YYYY",
	"smj-NO" : "DD.MM.YYYY",
	"smj-SE" : "YYYY-MM-DD",
	"smn-FI" : "D.M.YYYY",
	"sms-FI" : "D.M.YYYY",
	"sr-Cyrl-BA" : "D.M.YYYY",
	"sr-Cyrl-CS" : "D.M.YYYY",
	"sr-Cyrl-ME" : "D.M.YYYY",
	"sr-Cyrl-RS" : "D.M.YYYY",
	"sr-Latn-BA" : "D.M.YYYY",
	"sr-Latn-CS" : "D.M.YYYY",
	"sr-Latn-ME" : "D.M.YYYY",
	"sr-Latn-RS" : "D.M.YYYY",
	"sq-AL" : "YYYY-MM-DD",
	"sv-FI" : "D.M.YYYY",
	"sv-SE" : "YYYY-MM-DD",
	"sw-KE" : "M/D/YYYY",
	"syr-SY" : "DD/MM/YYYY",
	"ta-IN" : "DD-MM-YYYY",
	"te-IN" : "DD-MM-YY",
	"tg-Cyrl-TJ" : "DD.MM.YY",
	"th" : "D/M/YYYY",
	"th-TH" : "D/M/YYYY",
	"tk -TM" : "DD.MM.YY",
	"tn-ZA" : "YYYY/MM/DD",
	"tr-TR" : "DD.MM.YYYY",
	"tt-RU" : "DD.MM.YYYY",
	"tzm-Latn-DZ" : "DD-MM-YYYY",
	"ug-CN" : "YYYY-M-D",
	"ur-PK" : "DD/MM/YYYY",
	"uk-UA" : "DD.MM.YYYY",
	"uz-Cyrl-UZ" : "DD.MM.YYYY",
	"uz-Latn-UZ" : "DD/MM YYYY",
	"vi-VN" : "DD/MM/YYYY",
	"wo-SN" : "DD/MM/YYYY",
	"xh-ZA" : "YYYY/MM/DD",
	"yo-NG" : "D/M/YYYY",
	"zh-CN" : "YYYY/M/D",
	"zh-MO" : "D/M/YYYY",
	"zh-TW" : "YYYY/M/D",
	"zh-SG" : "D/M/YYYY",
	"zh-HK" : "D/M/YYYY",
	"zu-ZA" : "YYYY/MM/DD",
	};

	return lang && languages[lang] ? languages[lang] : 'DD-MM-YYYY'
}

let returnYesOrNo =  (bool)=> {
	return bool ? "Yes" : "No";
};

const getTzOffset = () => {
	let offset = new Date().getTimezoneOffset(); //-
	let offsetInHours;
	let sign = '-';
	let offsetInHoursDecimal = offset / 60; // -5.5
	if (offset < 0) {
		sign = '+';
		offsetInHours = Math.ceil(offsetInHoursDecimal);
	} else {
		offsetInHours = Math.floor(offsetInHoursDecimal);
	}

	let offsetInHoursStr = Math.abs(offsetInHours);
	if (offsetInHours < 10) {
		offsetInHoursStr = '0' + offsetInHoursStr;
	}
	let mins = '00';
	if (offset % 60) {
		mins = (Math.abs(offsetInHoursDecimal) - Math.abs(offsetInHours)) * 60;
		if (mins < 10) {
			mins = '0' + mins;
		}
	}
	return (sign + offsetInHoursStr + ':' + mins);
};


const regexTest = (regextype, text, incomingPattern = '') => {
	let pattern = PATTERN[regextype] ? PATTERN[regextype] : '';
	if (incomingPattern != '') {
		pattern = new RegExp(incomingPattern);
	}
	if (pattern) {
		return pattern.test(text);
	} else {
		console.error('The regextype you are trying to validate against is not yet declared. Please declare the pattern for ' + regextype);
		return false;
	}
};

const getMobileMaxLength = (countryData) => {
	const countryConfig = countryData.countryConfig;
	const AllowedMobileLengths=countryData.AllowedMobileLengths;
	let maxLength = [];
	if(countryConfig && countryConfig.MobileNumber)  {
		if(countryConfig.MobileNumber && countryConfig.MobileNumber.LENGTH) {
			let lengthArr = countryConfig.MobileNumber.LENGTH && (countryConfig.MobileNumber.LENGTH.length > 1) ? countryConfig.MobileNumber.LENGTH.map(data => parseInt(data)) : [countryConfig.MobileNumber.LENGTH[0]];
			maxLength = sortBy(lengthArr).reverse();
		}
	}else if(AllowedMobileLengths){
		let mobLength = AllowedMobileLengths.split(',');
		let lengthArr = mobLength.map(item => parseInt(item));
		maxLength = sortBy(lengthArr).reverse();
	}else {
		maxLength = [10];
	}
	return maxLength;
}

let SERVICETYPE = Object.freeze({
	PICK_UP: 1,
	CARRY_IN: 2,
	ON_SITE: 3,
	BUY_BACK: 4,
	INSTALLATION: 5,
	DEMO: 6,
	SERVICING: 7,
	DELIVERY: 8,
	RECYCLE_PICK_UP: 9,
	CLAIM: 10,
	CLAIM_PICK_UP: 11,
	CLAIM_CARRY_IN: 12,
	RECYCLE_DROP_OFF: 13,
	BER_IDENTIFIED: 14,
	BER_PAYMENT: 15,
	BER_REPAIR: 16,
	DROPOFF: 17,
	ENQUIRY: 18,
	FIELD_REPAIR_WO: 19,
	UPGRADE_WO:20,
	INSTALL_WO: 21,
	RELOCATION: 22,
	CLAIM_DROPOFF: 23,
	TRADE_IN_DROPOFF: 24,
	MAIL_IN: 25,
	EXTENDED_PICK_UP: 31,
	EXTENDED_CARRY_IN: 32,
	TRADE_IN_PICKUP: 33,
	REFUND_CROMA: 35,
	ESCALATION: 36,
	CLAIM_SELF_REPAIR: 38,
	INTERNAL_JOB: 39,
	CLAIM_DOOR_TO_DOOR_UPGRADE: 40,
	DOOR_TO_DOOR_UPGRADE: 41,
	CLAIM_DOOR_TO_DOOR_REPLACEMENT: 42,
	DOOR_TO_DOOR_REPLACEMENT: 43,
	PICKUP_UPGRADE: 44,
	CLAIM_REPLACEMENT_PICKUP: 45,
	CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE:46,
	MAIL_IN_REPLACEMENT: 47,
	CLAIM_REPLACEMENT_MAIL_IN: 48,
	CLAIM_UPGRADE_PICKUP: 49,
	MAIL_IN_UPGRAGE: 50,
	CLAIM_UPGRADE_MAIL_IN:51,
	CLAIM_MAIL_IN: 52,
	ONSITE_REPLACEMENT: 56,
	DROPOFF_REFUND:57,
	DROPOFF_REPLACEMENT: 58,
	PICKUP_REFUND: 59,
	BER_REPLACEMENT: 60,
	UNINSTALLATION: 61,
	INSPECTION: 62,
	BUYOUT_WITHOUT_DEFECTIVE: 63,
	CLAIM_ONSITE: 64,
	ADVANCE_EXCHANGE: 65,
	COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE: 66,
	LIQUIDATOR_PAYIN: 67,
	DEFECTIVE_REFURBISHMENT:68,
});


//Add festive theme depending on dates
// const isFestiveThemeNeeded = () => {
// 	let todayFullDate = new Date();
// 	let todayDate = todayFullDate.getDate();
// 	let todayMonth = todayFullDate.getMonth();
// 	let todayYear = todayFullDate.getFullYear();
// 	if (todayDate < 10) {
// 		todayDate = '0'+todayDate;
// 	}
// 	if (todayMonth < 10) {
// 		todayMonth = '0'+(todayMonth+1);
// 	}
// 	else {
// 		todayMonth = (todayMonth+1);
// 	}
// 	let todayDDMMYYYY = todayDate +'-'+ todayMonth +'-'+ todayYear;
// }
const returnLocalTimeForADay = (timeval)=> {
	let timevalue = moment.utc(timeval, 'HH:mm:ss').add(moment().utcOffset(), 'm').format('HH:mm:ss');
	return timevalue;
}
const timeConversion = (millisec) =>{

	let hours = parseInt(millisec / (1000 * 60 * 60));

	var timeRemaining_milliseconds = millisec - (hours * 1000 * 60 * 60);

	let minutes = parseInt((timeRemaining_milliseconds / (1000 * 60)));

	var timeRemaining_milliseconds = (timeRemaining_milliseconds - (minutes * 1000 * 60));

	let seconds = parseInt((timeRemaining_milliseconds / 1000));

	if (hours < 10) {
		hours = "0" + hours;
	}
	if (minutes < 10) {
		minutes = "0" + minutes;
	}
	if (seconds < 10) {
		seconds = "0" + seconds;
	}
	let finalTime = hours + ":" + minutes + ":" + seconds;
	return finalTime;
}

const downloadcsvFile = (obj)=> {
	if (!obj) {
		console.error('Parameter for downloading csv not found');
		return false;
	}
	if (!obj.data) {
		console.error('Data for downloading csv not found');
		return false;
	}
	var data = Papa.unparse(
		obj.data
	);

	var hiddenElement = document.createElement('a');
	var blob = new Blob([data], {
		type: 'text/csv;charset=utf-8;'
	});
	var url = URL.createObjectURL(blob);
	hiddenElement.href = url;
	hiddenElement.download = obj.fileName ? obj.fileName + '.csv' : 'download.csv';
	document.body.appendChild(hiddenElement);
	hiddenElement.click();
	document.body.removeChild(hiddenElement);
};

const diagnosisImage = {
	"Microphone": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/microphone.png",
	"Air Temperature": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/sensors.png",
	"Back Camera": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/camera.png",
	"Accelerometer": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/sensors.png",
	"Proximity": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/sensors.png",
	"Magnetic Field": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/sensors.png",
	"Message Service": "",
	"Bluetooth": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/bluetooth.png",
	"Led Notification": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/icon_led.png",
	"Front Camera": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/camera.png",
	"Wifi": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/wifi.png",
	"Speaker": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/speaker.png",
	"Air Pressure": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/sensors.png",
	"Headphone Port": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/earphone.png",
	"GPS": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/sensors.png",
	"IR Sensor": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/sensors.png",
	"Dual Sim": "",
	"Battery": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/battery.png",
	"Call Service": "",
	"Charging Port": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/charging-port.png",
	"NFC": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/sensors.png",
	"Humidity": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/sensors.png",
	"Volume Key Up": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/volume.png",
	"Touch Screen": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/touch-screen.png",
	"Finger Print Sensor": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/sensors.png",
	"Volume Key Down": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/volume.png",
	"Display Screen": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/screen.png",
	"Gravity": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/sensors.png",
	"Heart Rate": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/sensors.png",
	"Flash Light": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/flashlight.png",
	"FlashLight": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/flashlight.png",
	"Gyroscope": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/sensors.png",
	"Default": "https://s3-ap-southeast-1.amazonaws.com/consumer-icons/diagnosis/default.png"
};

const diagnosisGroupConfig = {
	"System Summary": "Basic Info",
	"Application Version": "Basic Info",
	"TypeOfDiagnosis": "Basic Info",
	"Battery Statistics": "Hardware",
	"Wifi Statistics": "Connectivity",
	"Network Info": "Connectivity",
	"Air Pressure": "Sensors",
	"Call Service": "Connectivity",
	"Gyroscope": "Sensors",
	"Compass": "Sensors",
	"Shake Test": "Sensors",
	"Light Sensor": "Sensors",
	"Heart Rate": "Sensors",
	"Humidity": "Sensors",
	"Message Service": "Connectivity",
	"Magnetic Field": "Sensors",
	"IR Sensor": "Sensors",
	"Gravity": "Sensors",
	"Accelerometer": "Sensors",
	"Air Temperature": "Sensors",
	"Bluetooth": "Connectivity",
	"GPS": "Connectivity",
	"RAM": "Hardware",
	"Internal Storage": "Hardware",
	"CPU": "Hardware",
	"Fingerprint": "Hardware",
	"Face ID": "Hardware",
	"Vibrator": "Hardware",
	"Proximity Sensor": "Sensors",
	"Speaker": "Sound",
	"Microphone": "Sound",
	"Headphone": "Ports",
	"USB": "Ports",
	"Charging Type": "Ports",
	"Volume Button": "Buttons",
	"Power Button": "Buttons",
	"Touch Screen": "Display",
	"Dead or White Spots": "Display",
	"Back Camera": "Camera",
	"Front Camera": "Camera",
	"Flashlight": "Camera",
	"WiFi": "Connectivity",
	"Flash Light": "Camera",
	"Battery": "Hardware",
	"Dual SIM": "Connectivity",
	"Sensor": "Sensors",
	"Multi Touch": "Display",
	"Brightness": "Display",
	"Earpiece Speaker": "Sound",
	"Phone Information": "Hardware",
	"External Storage": "Hardware",
	"DiskDrive": "Hardware",
	"Mic": "Sound",
	"MouseTrackpad": "Hardware",
	"Keyboard": "Hardware",
	"TouchScreen": "Hardware",
	"Display": "Hardware",
	"Ethernet Info": "Connectivity",
	"Battery Performance": "Performance",
	"CPU Performance": "Performance",
	"GPU Performance": "Performance",
	"VideoAdapter": "Hardware"
};

const diagnosticStatus = {
	'-1': {
		displayText: 'Not Available',
		image: 'https://consumer-icons.s3.ap-southeast-1.amazonaws.com/diagnosis/na.png'
	},
	'0': {
		displayText: 'Skipped',
		image: 'https://consumer-icons.s3.ap-southeast-1.amazonaws.com/diagnosis/skipped.png'
	},
	'1': {
		displayText: 'Working',
		image: 'https://consumer-icons.s3.ap-southeast-1.amazonaws.com/diagnosis/pass.png'
	},
	'2': {
		displayText: 'Faulty',
		image: 'https://consumer-icons.s3.ap-southeast-1.amazonaws.com/diagnosis/fail.png'
	},
	'total': {
		displayText: 'Total'
	},
	'3': {
		displayText: 'Could not be tested'
	},
	'4': {
		displayText: 'Testing Incomplete'
	}
};

const diagnosisOverallStatus = {
	'pass': {
		displayText: 'Passed',
	},
	'fail': {
		displayText: 'Failed',
	},
	'incomplete': {
		displayText: 'Incomplete',
	}
}

const diagnosticReportStatus = {
	'-1': {
		displayText: 'N/A',
		color: 'black'
	},
	'0': {
		displayText: 'Incomplete',
		color: 'black'
	},
	'1': {
		displayText: 'Passed',
		color: 'green'
	},
	'2': {
		displayText: 'Failed',
		color: 'red'
	},
	'total': {
		displayText: 'Total',
		color: 'black'
	},
	'3': {
		displayText: 'N/A',
		color: 'black'
	},
	'4': {
		displayText: 'Testing Incomplete',
		color: 'black'
	}
}

const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const returnLocalSlotWithMin = (slotUTC)=> {
	if (slotUTC) {
		let slotLocal = moment.utc(slotUTC, 'HH:mm:ss');
		slotLocal = moment(slotLocal).add(moment().utcOffset(), 'm');
		slotLocal = moment(slotLocal).format('hh:mm a');
		return slotLocal;
	} else {
		return 'NA';
	}
}

const unZipper = (file, callback)=> {
	// let reader = new FileReader();
	// reader.readAsBinaryString(file);
	// reader.onloadend = (e)=> {
	// 	let myZip = e.target.result;
	// 	let unzipper = new JSUnzip(myZip);
	// 	unzipper.readEntries();
	// 	let myFiles = unzipper.entries;
	// 	callback(myFiles);
	// }
}

const safeJsonParse = (data, canLogError)=> {
	try {
		return JSON.parse(data)
	} catch(error) {
		canLogError && console.error(error);
	}
}

const safeAccess = (someFn, canLogError)=> {
	try {
		return someFn();
	} catch (error) {
		canLogError && console.error(error);
	}
}

const validateThis =  (data, arr) =>{
	let invalidParams = [];

	let validator = {
		isAlphaNumeric: function (val) {
			return ALPHANUMERICPATTERNWITHSPACE.test(val);
		},
		isAlphaNumericTurkey: function (val) {
			return TURKEYALPHANUMERICPATTERN.test(val);
		},
		isNumeric: function (val) {
			return NUMBERPATTERN.test(val);
		},
		isFloat: function (val) {
			return FLOATPATTERN.test(val);
		},
		isEmail: function (val) {
			return EMAILPATTERN.test(val);
		},
		isNotEmpty: function (val) {
			return val ? true : false;
		},
		isPositive: function (val) {
			return val > 0 ? true : false;
		}
	};

	(arr).forEach(function (obj) {
		if (!(validator[obj.checkType](data[obj.key]))) {
			invalidParams.push(obj.key);
		}
	});

	let returnObj = {
		isValid: true,
		invalidParams: invalidParams
	};

	if (invalidParams.length != 0) {
		returnObj.isValid = false;
	}

	return returnObj;

};
const returnLocalDateTimeYear = (slotUTC) =>{
	if (slotUTC) {
		var slotLocal = moment.utc(slotUTC);
		slotLocal = moment(slotLocal).add(moment().utcOffset(), 'm');
		slotLocal = moment(slotLocal).format('MMM DD YYYY hh:mm A');
		return slotLocal;
	} else {
		return 'NA';
	}
}

const isTheSameObj = (obj1, obj2)=> {
	return isEqual(obj1, obj2);
}

const getStatusString = (status, ServiceTypeID = false, accessFn = false) => {
	switch (status) {
		case 'Payment received':
			if(accessFn && accessFn('WORKSHOP_FLOW')){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(ServiceTypeID) > -1){
					status = 'Payment received';
				}
			}
			else {
				if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(ServiceTypeID) > -1){
					status = 'Payment received';
				}else{
					status = 'Approved for repair';
				}
			}
			break;
		case 'Service cancel':
			status = 'Service cancelled';
			break;
		case 'Repair cancel':
			if([9,13].indexOf(ServiceTypeID) > -1){
				status = 'Recycle Request cancelled';
			}
			else {
				status = 'Repair cancel';
			}
			break;
		case 'Service accepted':
			if([13].indexOf(ServiceTypeID) > -1) {
				status = 'Dropoff Request Registered';
			}
			else {
				status = 'Service accepted';
			}
			break;
		case 'Service initiated':
			if([9,13].indexOf(ServiceTypeID) > -1) {
				status = 'Verification Complete';
			}
			else {
				status = 'Service initiated';
			}
			break;
		case 'Vouchers generated':
			if([9,13].indexOf(ServiceTypeID) > -1) {
				status = 'Green Reward Update';
			}
			else {
				status = 'Vouchers generated';
			}
			break;
		case 'Device recycle initiated':
			if([9,13].indexOf(ServiceTypeID) > -1) {
				status = 'Initial Dismantling Completed';
			}
			else {
				status = 'Device recycle initiated';
			}
			break;
		case 'Service completed':
			if([9,13].indexOf(ServiceTypeID) > -1) {
				status = 'Recycle Completed';
			}
			else {
				status = 'Service completed';
			}
			break;
		case 'QC completed':
			if([SERVICETYPE.TRADE_IN_DROPOFF,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.DEFECTIVE_REFURBISHMENT].indexOf(ServiceTypeID) === -1){
				status = 'Repair Completed, Ready To Inform The Customer';
			}
			break;
		case 'Replacement authorized':
			status = 'Replacement Approved';
			break;
		case 'Pending for assignment':
			if(accessFn && accessFn('WORKSHOP_FLOW')){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(ServiceTypeID) > -1){
					status = 'Inspection Completed, Awaiting Engineer Assignment';
				}
			}
			else {
				status = 'Pending for assignment';
			}
			break;
		case 'QC pending':
			if(accessFn && accessFn('WORKSHOP_FLOW')){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(ServiceTypeID) > -1){
					status = 'Repair completed, QC pending';
				}
			}
			else {}
			break;
		case 'Pending for acceptance':
			if(accessFn && accessFn('WORKSHOP_FLOW')){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(ServiceTypeID) > -1){
					status = 'Pending for Acceptance by Engineer';
				}
			}
			else {}
			break;
		case 'Work in progress':
			if(accessFn && accessFn('WORKSHOP_FLOW')){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(ServiceTypeID) > -1){
					status = 'Work in Progress with Engineer';
				}
			}
			else {}
			break;
		case 'QC rejected':
			if(accessFn && accessFn('WORKSHOP_FLOW')){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(ServiceTypeID) > -1){
					status = 'QC rejected, Work in progress';
				}
			}
			else {}
			break;
		case 'Repair completed and Intimate customer':
			if(accessFn && accessFn('WORKSHOP_FLOW')){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(ServiceTypeID) > -1){
					status = 'Repair completed, Inform customer';
				}
			}
			else {}
			break;
		case 'Intimate customer':
			if(accessFn && accessFn('WORKSHOP_FLOW')){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(ServiceTypeID) > -1){
					status = 'Repair completed and Inform customer';
				}
			}
			else {}
			break;
		case 'Diagnosis Completed':
			if(accessFn && accessFn('WORKSHOP_FLOW')){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(ServiceTypeID) > -1){
					status = 'Diagnosis initiated on device; Work in Progress';
				}
			}
			else {}
			break;
		case 'Device received':
			if(accessFn && accessFn('WORKSHOP_FLOW')){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(ServiceTypeID) > -1){
					status = 'Device received';
				}
			}
			else {}
			break;
		case 'Device accepted':
			if(accessFn && accessFn('WORKSHOP_FLOW')){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(ServiceTypeID) > -1){
					status = 'Device accepted';
				}
			}
			else {}
			break;
		case 'Device handed over':
			if(accessFn && accessFn('WORKSHOP_FLOW')){
				if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(ServiceTypeID) > -1){
					status = 'Device handed over';
				}
			}
			else {}
			break;
		case 'Serviceman journey initiated':
			if([SERVICETYPE.CLAIM_ONSITE].indexOf(ServiceTypeID) > -1){
				status = 'Technician initiated journey';
			} else {}
			break;
		case 'Serviceman reached':
			if([SERVICETYPE.CLAIM_ONSITE].indexOf(ServiceTypeID) > -1){
				status = 'Technician reached';
			} else {}
			break;
		case 'Replacement request raised':
			if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(ServiceTypeID) > -1){
				status = 'Advance Exchange Request Raised';
			}
		break;
		case 'Claim raised':
			status = 'Pending Review';
		break;
		case 'Security deposit released':
			status = 'Subscription released';
		break;
		case 'Security deposit charging failed':
			status = 'Subscription charging failed';
		break;
		case 'Security deposit release failed':
			status = 'Subscription release failed';
		break;
		case 'Security deposit charged successfully':
			status = 'Subscription charged successfully';
		break;
		case 'Security deposit charged':
			status = 'Subscription charged';
		break;
		case 'Security deposit releasing failed':
			status = 'Subscription releasing failed';
		break;
		case 'Security Deposit Releasing failed':
			status = 'Subscription Releasing failed';
		break;
	}
	return status;
};

const createRefIDLikeString = (length) => {
	let result = '';
	let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	let charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

let exceptionTypeConfig = {
	'REPLACEMENT': 1,
	'REFUND': 2,
	'WARRANTY_UPDATE': 3
}
let exceptionApproveFlagConfig = {
	'NORMAL_FLOW': 1,
	'REJECTED': 2,
	'APPROVED': 1
}
const removeHtmltagfromStrings = (item) => {
	let obj = {...item};
	for (const key in obj) {
		if(obj[key] && (typeof obj[key] == "string")) {
			obj[key] = obj[key].replace(/</g, " ").replace(/>/g, " ");
		}
	}
	return obj;
} 

const calculateAging = (inputDate) => {
	const currentDate = new Date();
	const inputDateFormatted = new Date(inputDate)
	let duration = currentDate - inputDateFormatted;
  
	const millisecondsPerSecond = 1000;
	const secondsPerMinute = 60;
	const minutesPerHour = 60;
	const hoursPerDay = 24;
	const daysPerMonth = 30.44; // Average days per month (considering 365 days a year)
  
	const millisecondsPerMinute = millisecondsPerSecond * secondsPerMinute;
	const millisecondsPerHour = millisecondsPerMinute * minutesPerHour;
	const millisecondsPerDay = millisecondsPerHour * hoursPerDay;
	const millisecondsPerMonth = millisecondsPerDay * daysPerMonth;
	const millisecondsPerYear = millisecondsPerDay * 365;
  
	const years = Math.floor(duration / millisecondsPerYear);
	duration -= years * millisecondsPerYear;
  
	const months = Math.floor(duration / millisecondsPerMonth);
	duration -= months * millisecondsPerMonth;
  
	const days = Math.floor(duration / millisecondsPerDay);
	duration -= days * millisecondsPerDay;
  
	const hours = Math.floor(duration / millisecondsPerHour);
	duration -= hours * millisecondsPerHour;
  
	const minutes = Math.floor(duration / millisecondsPerMinute);
	duration -= minutes * millisecondsPerMinute;
  
	const seconds = Math.floor(duration / millisecondsPerSecond);
  
	let result = "";
	if (years > 0) {
	  result += `${years} year${years > 1 ? "s" : ""} `;
	  if (months > 0) result += `${months} month${months > 1 ? "s" : ""} `;
	  if (days > 0) result += `${days} day${days > 1 ? "s" : ""} `;
	} else if (years === 0 && months > 0) {
	  result += `${months} month${months > 1 ? "s" : ""} `;
	  if (days > 0) result += `${days} day${days > 1 ? "s" : ""} `;
	  if (hours > 0) result += `${hours} hour${hours > 1 ? "s" : ""} `;
	} else if (months === 0 && days > 0) {
	  result += `${days} day${days > 1 ? "s" : ""} `;
	  if (hours > 0) result += `${hours} hour${hours > 1 ? "s" : ""} `;
	  if (minutes > 0) result += `${minutes} minute${minutes > 1 ? "s" : ""} `;
	} else {
	  result += `${hours} hour${hours > 1 ? "s" : ""} `;
	  if (minutes > 0) result += `${minutes} minute${minutes > 1 ? "s" : ""} `;
	//  if (seconds > 0) result += `${seconds} second${seconds > 1 ? "s" : ""} `;
	}

	return 'over' + ' ' + result.trim();
  }

// this works for negative range also
const inRange = (low, high, x) => {
	return (x - high) * (x - low) <= 0;
};

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
	var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

	return {
		x: centerX + radius * Math.cos(angleInRadians),
		y: centerY + radius * Math.sin(angleInRadians),
	};
}

function describeArc(x, y, radius, startAngle, endAngle) {
	var start = polarToCartesian(x, y, radius, endAngle);
	var end = polarToCartesian(x, y, radius, startAngle);

	var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	var d = [
		"M",
		start.x,
		start.y,
		"A",
		radius,
		radius,
		0,
		largeArcFlag,
		0,
		end.x,
		end.y,
	].join(" ");

	return d;
}

const calculateAngle = (score) => {
	if (score) {
		// for calculation shifting all score to +1000 to make min score to 0
		const totalScore = 2000;
		const totalAngle = 180; // As the range is half circle
		const relativeScore = score + 1000;

		const percentageScore = (relativeScore / totalScore) * 100;

		const angle = (totalAngle * percentageScore) / 100;

		// due to svg rotation red zone starting angle for pointer is -90deg
		// and green zone ending pointer angle is +90 deg
		// so if taking the red zone starting angle at 0deg we need to substract the 90
		const relativeAngle = angle - 90;

		return relativeAngle;
	} else {
		return 0;
	}
};

const getLanguageCode = () => {
	let val = navigator.language;
	if(navigator.language && navigator.language.includes('en-')) {
		val = 'en'
	}
	return val;
}
  
const returnLocalTime =(timeUTC, defaultCountryLocale)=> {
	if (timeUTC) {
		var timeLocal = moment.utc(timeUTC).toDate();
		timeLocal = moment(timeLocal).format('DD-MM-YYYY');
		return timeLocal
	} else {
		return 'NA';
	}
}

const addValueKey = (questions) => {
	questions.forEach((question, key) => {
		// if(question.isSelect && question.answerType && (question.answerType == 'DPD')) {
			question?.answers?.length && question.answers.forEach((ans) => {
				if(ans.isSelect) {
					question.value = ans.answerPhrase || '';
					if(ans?.questions?.length) {
						ans.questions = addValueKey(ans.questions);
					}
				}
			})
		// }
	})
	return questions;
}

const updateQuestions = (item) => {
	let questionsArr = JSON.parse(JSON.stringify(item));
	questionsArr.forEach(questionObj => {
		questionObj.trees.forEach(treeObj => {
			if(treeObj.questions && treeObj.questions.length) {
				treeObj.questions = addValueKey(treeObj.questions);
			}
		});
	});
	return questionsArr;
}

const copyText = (text) => {
	return navigator.clipboard.writeText(text);
}

const displayAmount = (amount = 0, locale = 'en-GB', currencyCode) => {
	// if(!amount) return
	if(!currencyCode) {
		return Number(amount).toLocaleString(locale).replace(/(\.|,)00$/g, '');
	}else {
		return Number(amount).toLocaleString(locale, {style:"currency", currency: currencyCode}).replace(/(\.|,)00$/g, '');
	}
}

export {
	checkName,
	returnLocalDateTime,
	generateUUID,
	getCookie,
	getLinkUri,
	displayError,
	checkEmail,
	checkMobile,
	checkProductUniqueID,
	checkExternalRefID,
	checkAlphaNumeric,
	getValueFromAdressComponent,
	encrypt,
	decrypt,
	checkReferenceNumber,
	manipulateDate,
	DiagnosisChannels,
	checkFlipkartRefID,
	trimData,
	getQueryStringValue,
	validateAttachments,
	returnTimezone,
	downloadCSV,
	formatIndianCurrencyRs,
	checkMobileNumber,
	nationalIdValidation,
	getTzOffset,
	getMobileMaxLength,
	returnLocalTimeForADay,
	checkNumberPattern,
	SERVICETYPE,
	returnLocalSlot,
	checkEncryption,
	returnYesOrNo,
	diagnosisGroupConfig,
	diagnosticStatus,
	diagnosisOverallStatus,
	diagnosticReportStatus,
	getDateFormat,
	getLocaleDateString,
	timeConversion,
	downloadcsvFile,
	returnLocalSlotWithMin,
	unZipper,
	safeJsonParse,
	safeAccess,
	validateThis,
	checkFloatPattern,
	returnLocalDateTimeYear,
	isTheSameObj,
	createRefIDLikeString,
	PATTERN,
	regexTest,
	exceptionTypeConfig,
	exceptionApproveFlagConfig,
	diagnosisImage,
	checkAlphaNumericSymbol,
	getStatusString,
	capitalizeFirstLetter,
	checkAlphaNumericName,
	checkPlan,
	checkPANNumber,
	removeHtmltagfromStrings,
	getAbbrivatedZone,
	getLocalDateTime,
	calculateAging,
	inRange,
	calculateAngle,
	describeArc,
	polarToCartesian,
	getLanguageCode,
	returnLocalTime,
	updateQuestions,
	copyText,
	displayAmount
};
