import React,{useState} from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import {Table, Loader} from 'sfy-react';
import { displayError,SERVICETYPE } from '../../../../../Utility/Utils';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';

const RefurbishmentDetails = (props) => {
    const {ConsumerServiceRequestID, refurbishmentRequestData, localString, apiCall, getRefurbishmentRequestDetails,consumerServiceRequest} = props;
	const [loader,setLoader] = useState(false);

    const classObject = {
		table:"table table-striped complaint-details-table request-table"
	}

    const getUpdatedData = () => {
		let data = {
			ConsumerServiceRequestID: ConsumerServiceRequestID
		}
        if(SERVICETYPE.DEFECTIVE_REFURBISHMENT == consumerServiceRequest.ServiceTypeID){
			data.ServiceType = consumerServiceRequest.ServiceTypeID 
		}
		setLoader(true);
		apiCall({
			url:'updateRefurbStatus',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRefurbishmentRequestDetails();
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

    return (
        <>
            { loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
            <div style={{margin:"-10px"}}>
				<PageContentWrapper 
					bodyClassName="panel-body-no-padding" 
					title={localString("Refurbishment Details")} 
					customSymbol="glyphicon glyphicon-refresh glyphicon-refresh-animate"
					showDownloadButtonIcon={true}
					editDeviceClick={getUpdatedData}
				>
                <div className="request-panel request-complaint-div">
                    {
                        <Table className={classObject}>
                            <Table.Body>
                                {
                                    refurbishmentRequestData?.ModelNo && 
                                    <Table.Row>
                                        <Table.Data className="table-keys text-capitalize"> {localString("Model Code")} </Table.Data>
                                        <Table.Data> {refurbishmentRequestData.ModelNo} </Table.Data>
                                    </Table.Row>
                                }
                                {
                                    refurbishmentRequestData?.BpNo && 
                                    <Table.Row>
                                        <Table.Data className="table-keys text-capitalize"> {localString("BP Number")} </Table.Data>
                                        <Table.Data> {refurbishmentRequestData.BpNo} </Table.Data>
                                    </Table.Row>
                                }
                                {
                                    refurbishmentRequestData?.StatusLog && refurbishmentRequestData?.StatusLog?.length && 
                                    <Table.Row>
                                        <Table.Data className="table-keys text-capitalize"> {localString("Status")} </Table.Data>
                                        <Table.Data> 
                                            {
                                                refurbishmentRequestData?.StatusLog.map(item=>{
                                                    return(
                                                        <li>
                                                            <span>{item.Status} </span>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </Table.Data>
                                    </Table.Row>
                                }
                                {
                                    refurbishmentRequestData?.Comment && 
                                    <Table.Row>
                                        <Table.Data className="table-keys text-capitalize"> {localString("Comment")} </Table.Data>
                                        <Table.Data> {refurbishmentRequestData.Comment} </Table.Data>
                                    </Table.Row>
                                }
                                {
                                    refurbishmentRequestData?.OrderNo && 
                                    <Table.Row>
                                        <Table.Data className="table-keys text-capitalize"> {localString("Order Number")} </Table.Data>
                                        <Table.Data> {refurbishmentRequestData.OrderNo} </Table.Data>
                                    </Table.Row>
                                }
                                {
                                    refurbishmentRequestData?.ScheduleDate && 
                                    <Table.Row>
                                        <Table.Data className="table-keys text-capitalize"> {localString("Order Date")} </Table.Data>
                                        <Table.Data> {refurbishmentRequestData.ScheduleDate} </Table.Data>
                                    </Table.Row>
                                }
                                {
                                    (refurbishmentRequestData?.ScheduleDate && refurbishmentRequestData?.ScheduleTime) && 
                                    <Table.Row>
                                        <Table.Data className="table-keys text-capitalize"> {localString("Schedule date and time")} </Table.Data>
                                        <Table.Data> {refurbishmentRequestData.ScheduleDate + ' ' + refurbishmentRequestData.ScheduleTime} </Table.Data>
                                    </Table.Row>
                                }
                            </Table.Body>
                        </Table>
                    }
                    {   
                        (!refurbishmentRequestData || (refurbishmentRequestData && Object.keys(refurbishmentRequestData).length == 0)) && 
                        <div className="charges-panel-subheader" style={{margin:"10px"}}>{localString("No Refurbishment details")}</div>
                    }
                </div>
				</PageContentWrapper>
			</div>
        </>
    )
}

export default RefurbishmentDetails