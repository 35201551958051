import React from 'react';
import { Modal } from 'sfy-react';
import QRCode from "qrcode.react";


export default function DisplayQRCode(props) {
	const {
		localString, showModal, closeModal, qrCodeString
 	} = props;

	 return (
		<div className='displayQRCode'>
            <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='Filter-Modal QRCodeModal'>
				<div>
					<div className='qrHeader'>
						<h2 className='qrHead'>{localString("Scan QR Code")}</h2>
						<h4 className='qrSubHead'>{localString("Please scan the QR for getting payment link")}</h4>
					</div>
					<div className="row">
						<div className="col-sm-12 text-center" style={{ padding: '10px 0px 30px 0px' }}>
							<QRCode value={qrCodeString} size={256}/>
						</div>
					</div>
				</div>
            </Modal>
        </div>
	)
}