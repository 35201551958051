import React,{useRef} from 'react';
import {Loader, Dropdown, Button, Input} from 'sfy-react';

const UploadSection = (props) => {
    const  {id, localString, singleObj, index, sectionName, setterOgArr, removeClick, uploadDocuments} = props;

    const fileRef = useRef(null);
    const imageClick = () =>{
        fileRef.current.click();
    }

    return (
        <div className="col-sm-6 file-section">
            <div className="file-btn-container" style={{display:"flex", justifyContent:"center"}}>
                <label className="btn button-servify file-btn" id={id+index} onClick={imageClick} >{localString(singleObj.fileName ? "Select another file" : "Choose File")}</label>
                {
                    singleObj.fileUploaded && 
                    <>
                        <Button 
                            type="button" 
                            id={"remove-"+id+index}
                            className="close"
                            handleOnClick={removeClick}
                        />
                        <span className="glyphicon glyphicon-minus-sign delete-icon" style={{alignSelf: "center"}}></span>
                    </>
                }
            </div>
            <div className="" style={{display:"none"}}>
                <Input
                    reference={fileRef}
                    inputType="file"
                    id={id+index}
                    handleOnChange={(e)=>{
                        let newFile = e.target.files;
                        // let arr = [...originalArr];
                        // arr[index].newFile = newFile
                        // arr[index].fileName = newFile[0].name
                        // arr[index].fileUploaded = true
                        uploadDocuments(newFile,index,sectionName)
                        // setterOgArr(arr);
                    }}
                    otherProps={{accept:".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv,.aac,.m4a,.mp4,.ogg,.ogx,.3gp"}}
                />
            </div>
            {/* <input type="file" style={{display:"none", color:"transparent"}} id={"identity-file-"+i} name={"identity-file-"+i} onClick={(e)=>checkFileInput(e,i)} accept=".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv,.aac,.m4a,.mp4,.ogg,.ogx,.3gp" /> */}
            <p style={{paddingTop:"5px"}} className="file-text">{localString(singleObj.fileName ? singleObj.fileName : "No file chosen")}</p>
        </div>
    )
}

export default UploadSection
