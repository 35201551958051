import React from 'react';
import {Modal} from 'sfy-react';
import CallLogPage from '../../../components/ConsumerCallLogs/CallLogsPage/CallLogPage';
import './CallHistoryModal.scss'

const CallHistoryModal = (props) => {
	// removed hiddenContent
	const {localString,showPage,closeModal} = props;

	return (
		<div>
			<Modal showModal={showPage} closeModal={closeModal} showCloseBtn={true} className="callLog-modal">
				<Modal.Header>
					<h4 className='modal-title'> {localString("Call Logs")} </h4>
				</Modal.Header>
				<Modal.Body>
					<CallLogPage {...props}></CallLogPage>
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
		</div>
	)
}

export default CallHistoryModal
