import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Loader } from "sfy-react";
import Swal from "sweetalert2";
import moment from "moment";
import PageContentWrapper from "../../../components/PageContentWrapper/PageContentWrapper";
import PageHeader from "../../../components/PageHeader/PageHeader";
import { displayError } from "../../../Utility/Utils";
import FilterModal from "../../../components/FilterModal/FilterModal";
import DateRangePickerWrapper from "../../../components/DateRangePickerWrapper/DateRangePickerWrapper";
import LogisticsListData from "./LogisticsListData";
import {LogisticsStatuses} from "../../../Utility/constants";
import { manipulateDate } from "../../../Utility/Utils";

const LogisticsList = (props) => {
	const { accessFn, localString, apiCall, titleHeader, qFilter,baseUrl } = props;
	const [showLoader, setLoader] = useState(false);
	const [logisticsList, setLogisticsList] = useState({});
	const [filterData, setFilterData] = useState({
		WaybillNumber: "",
		PickupName: "",
		PickupPinCode: "",
		PickupContactNo: "",
		DropName: "",
		DropPinCode: "",
		DropContactNo: "",
		Status: "",
		VendorID: "",
		Vendor: "",
		Flow: "",
		FlowID: "",
		IMEI: "",
	});
	const [filterInputs, setFilterInputs] = useState([]);
	const [showClearSearch, setShowClearSearch] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [statusData, setStatusData] = useState(LogisticsStatuses.map((v) => ({
						value: v,
					})));
	const [vendorData, setVendorData] = useState({});
	const [flowData, setFlowData] = useState({});
	const [dateRange, setDateRange] = useState({
		startDate: manipulateDate(2, "minus"),
		endDate: manipulateDate(5, "add"),
	});
	let maxDate = new Date();
	maxDate.setDate(maxDate.getDate() + 5);
	const buttonHeader = [
		{
			buttonText: localString("Filter Requests"),
			btnIcon: "glyphicon glyphicon-filter",
			showButton: accessFn("FILTER_LOGISTIC_REQUEST"),
			onButtonClick: () => {
				setShowModal(true);
			},
		},
		{
			buttonText: localString("Create Request"),
			btnIcon: "glyphicon glyphicon-plus",
			showButton: accessFn("CREATE_LOGISTIC_REQUEST"),
			onButtonClick: () => {
				let url = baseUrl + '/logistics/create';
     			window.location.replace(url);
			},
		},
		{
			buttonText: localString("Upload Status Report"),
			btnIcon: "glyphicon glyphicon-plus",
			showButton: accessFn("UPLOAD_PICKUP"),
			onButtonClick: () => {},
		},
	];

	const clearSearch = () => {
		setShowClearSearch(false);
		// setViewList(false);
		setFilterData({
			WaybillNumber: "",
			PickupName: "",
			PickupPinCode: "",
			PickupContactNo: "",
			DropName: "",
			DropPinCode: "",
			DropContactNo: "",
			Status: "",
			VendorID: "",
			Vendor: "",
			Flow: "",
			FlowID: "",
			IMEI: "",
		});
	};

	const getLogisticsList = (e) => {
		if (e) {
			e.preventDefault();
		}
		let data = {
			qFilter: {
				PickUpName: filterData.PickupName,
				DropName: filterData.DropName,
				WaybillNumber: filterData.WaybillNumber,
				PickUpPincodes: filterData.PickupPinCode,
				DropPincodes: filterData.DropPinCode,
				VendorID: filterData.VendorID,
				Flow: filterData.FlowID,
				imei: filterData.IMEI,
				PickUpContactNo: filterData.PickupContactNo,
				OrganisationID: undefined,
				// OrganisationID: self.isSelectedOrganisation
				// 	? self.selectedOrgnisation.OrganisationID
				// 	: undefined,
				DropContactNo: filterData.DropContactNo,
				Status: filterData.Status,
				scheduledDate: true,
				startDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
				endDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
			},
			pagination: {
				pageNo: 1,
				range: 5,
			},
		};
		setLoader(true);
		apiCall({
			url: "getFilteredLogisticsRequest",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					//setConsumersList({source:result.data.app, consumers:result.data.consumers});
					setLogisticsList(result.data);
					setShowModal(false);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};
	const loadVendors = () => {
		setLoader(true);
		apiCall({
			url: "getVendorList",
			data: {},
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					let data = result.data[0].data.map((v) => ({
						...v,
						value: v.VendorName,
					}));
					setVendorData(data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};
	const loadFlows = () => {
		setLoader(true);
		apiCall({
			url: "getServiceTypeConfig",
			data: {},
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					let data = result.data.map((v) => ({
						...v,
						value: v.LogisticsServiceTypeName,
					}));
					setFlowData(data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};
	useEffect(() => {
		loadVendors();
		loadFlows();
		getLogisticsList();
	}, []);

	useEffect(() => {
		InitialiseFilterInputs();
	}, [statusData, vendorData, flowData]);

	const InitialiseFilterInputs = () => {
		setFilterInputs([
			{
				labelText: localString("Waybill Number"),
				dataKey: "WaybillNumber",
				showFilter: true,
				value: filterData.WaybillNumber,
				customClass: "col-sm-12 paddingB10",
				oninputFunc: (val) => {
					setFilterData((prevFilterData) => {
						return {
							...prevFilterData,
							WaybillNumber: val,
						};
					});
				},
			},
			{
				labelText: localString("Pickup Name"),
				dataKey: "PickupName",
				value: filterData.PickupName,
				showFilter: true,
				oninputFunc: (val) => {
					setFilterData((prevFilterData) => {
						return {
							...prevFilterData,
							PickupName: val,
						};
					});
				},
			},
			{
				labelText: localString("Pickup Pin Code"),
				dataKey: "PickupPinCode",
				value: filterData.PickupPinCode,
				showFilter: true,
				oninputFunc: (val) => {
					setFilterData((prevFilterData) => {
						return {
							...prevFilterData,
							PickupPinCode: val,
						};
					});
				},
			},
			{
				labelText: localString("Pickup Contact No."),
				dataKey: "PickupContactNo",
				value: filterData.PickupContactNo,
				showFilter: true,
				oninputFunc: (val) => {
					setFilterData((prevFilterData) => {
						return {
							...prevFilterData,
							PickupContactNo: val,
						};
					});
				},
			},
			{
				labelText: localString("Drop Name"),
				dataKey: "DropName",
				value: filterData.DropName,
				showFilter: true,
				oninputFunc: (val) => {
					setFilterData((prevFilterData) => {
						return {
							...prevFilterData,
							DropName: val,
						};
					});
				},
			},
			{
				labelText: localString("Drop Pin Code"),
				dataKey: "DropPinCode",
				value: filterData.DropPinCode,
				showFilter: true,
				oninputFunc: (val) => {
					setFilterData((prevFilterData) => {
						return {
							...prevFilterData,
							DropPinCode: val,
						};
					});
				},
			},
			{
				labelText: localString("Drop Contact No."),
				dataKey: "DropContactNo",
				value: filterData.DropContactNo,
				showFilter: true,
				oninputFunc: (val) => {
					setFilterData((prevFilterData) => {
						return {
							...prevFilterData,
							DropContactNo: val,
						};
					});
				},
			},
			{
				labelText: localString("Status"),
				dataKey: "Status",
				type: "Dropdown",
				dropdownList: statusData,
				value: filterData.Status,
				showFilter: true,
				oninputFunc: (val) => {
					console.log(val);
					setFilterData((prevFilterData) => {
						return {
							...prevFilterData,
							Status: val.value,
						};
					});
				},
			},
			{
				labelText: localString("Vendor"),
				dataKey: "Vendor",
				type: "Dropdown",
				value: filterData.Vendor,
				dropdownList: vendorData,
				showFilter: true,
				oninputFunc: (val) => {
					console.log("val", val);
					console.log("filterData", filterData);
					setFilterData((prevFilterData) => {
						return {
							...prevFilterData,
							VendorID: val.VendorID,
							Vendor: val.VendorName,
						};
					});
				},
			},
			{
				labelText: localString("Flow"),
				dataKey: "Flow",
				type: "Dropdown",
				value: filterData.Flow,
				dropdownList: flowData,
				showFilter: true,
				oninputFunc: (val) => {
					setFilterData((prevFilterData) => {
						return {
							...prevFilterData,
							FlowID: val.LogisticsServiceTypeID,
							Flow: val.LogisticsServiceTypeName,
						};
					});
				},
			},
			{
				labelText: localString("IMEI"),
				dataKey: "IMEI",
				value: filterData.IMEI,
				showFilter: true,
				oninputFunc: (val) => {
					setFilterData((prevFilterData) => {
						return {
							...prevFilterData,
							IMEI: val,
						};
					});
				},
			}
		]);
	};
	const closeModal = () => {
		setShowModal(false);
	};
	const headings = [
		{
			title: localString("WayBill Number"),
			showHeading: true,
		},
		{
			title: localString("Reference Number"),
			showHeading: true,
		},
		{
			title: localString("Pickup Name"),
			showHeading: true,
		},
		{
			title: localString("Pickup Address"),
			showHeading: true,
		},
		{
			title: localString("Drop Name"),
			showHeading: true,
		},
		{
			title: localString("Drop Address"),
			showHeading: true,
		},
		{
			title: localString("Status"),
			showHeading: true,
		},
		{
			title: localString("Pickup Contact No"),
			showHeading: true,
		},
		{
			title: localString("Drop Contact No"),
			showHeading: true,
		},
	];
	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}
			<PageHeader
				titleHeader={titleHeader}
				localString={localString}
				btnClass="btn button-servify header-btn button-stack text-capital"
				headerButtonArray={buttonHeader}
			></PageHeader>
			<DateRangePickerWrapper
				handleDateRangeApply={getLogisticsList}
				dateRange={dateRange}
				date={maxDate}
				setDateRange={setDateRange}
			></DateRangePickerWrapper>
			<FilterModal
				{...props}
				filterFunc={getLogisticsList}
				filterInputs={filterInputs}
				filterData={filterData}
				showModal={showModal}
				closeModal={closeModal}
				clearSearch={clearSearch}
			></FilterModal>
			<LogisticsListData
				{...props}
				headings={headings}
				clsName="text-capitalize"
				logisticsList={logisticsList}
			></LogisticsListData>
		</>
	);
};

export default LogisticsList;
