import React from 'react'
import {Button,Dropdown,Loader} from 'sfy-react';

const AdvisorReset = (props) => {
    const {labelArray,advisorDetails,getadvisordetails,submitBtnValidation} = props;
    return (
        <div className="fluid-form-content fluid-form-active reset-shadow">
			<div className="row">
				{
					labelArray && labelArray.map((val,index)=>{
						return(
							<>
							<div className={val.customClass}>
								<label className={val.customLabelClass}>{val.labelText}</label>
							</div>
							{
								val.showFilter &&
								<div className="col-md-5 scrollable-dropdown-menu Retailer-Dropdown">
								<Dropdown
									id='dropdown'
									value={advisorDetails[val.dataKey]}
									key={index}
									options={val.showDropdownList}
									handleOnChange={(e) => {
										val.oninputFunc(e);
									}}
									showDownArrow={false}
									filterBy="value"
								/>
								</div>
							}
							</>
						)
					})
				}
				{
					<Button type='button' isDisabled={submitBtnValidation} className="btn button-servify" text='Submit' handleOnClick={getadvisordetails}/>
				}
			</div>
		</div>
    )
}

export default AdvisorReset
