import React from 'react';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import './ThirdPartyCharges.scss'


const ThirdPartyCharges = (props) => {
	const {localString,currencySymbol,consumerServiceRequest} = props;

	let TotalAmount = 0;

	if (consumerServiceRequest && consumerServiceRequest.externalThirdPartyCharges && consumerServiceRequest.externalThirdPartyCharges.length > 0 && TotalAmount == 0) {
		consumerServiceRequest.externalThirdPartyCharges.forEach(amt=>(TotalAmount = parseFloat(TotalAmount) + parseFloat(amt.Amount)));
	}

	return (
		<div className="inner-padding">
			<div className="row">
				<PageContentWrapper title={localString("Third Party Charges")}>
				<div className="request-panel request-charges-div custom-third-party-charges">
					<div className="row request-panel-padding">
						<div className="col-sm-6">
							<p className="charges-panel-subheader text-capitalize remove-margin">{localString('Type')}</p>
						</div>
						<div className="col-sm-4">
							<p className="charges-panel-subheader text-capitalize remove-margin align-right">{localString('Amount')} (<span className="currency" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>)</p>
						</div>
					</div>
					<div className="request-panel-padding">
						<hr className="remove-margin estimation-details-divider"/>
						{
							consumerServiceRequest && consumerServiceRequest.externalThirdPartyCharges && consumerServiceRequest.externalThirdPartyCharges.length > 0 && 
							consumerServiceRequest.externalThirdPartyCharges.map(charge=>{
								return (
									<div className="row">
										<div className="col-sm-6">
											<div className="charges-panel-content">{localString(charge.ChargeType)}</div>
										</div>
										<div className="col-sm-4">
											<div className="charges-panel-content align-right">{charge.Amount}</div>
										</div>
									</div>
								)
							})
						}
						<hr className="remove-margin estimation-details-divider"/>
						<div className="row">
							<div className="col-sm-6">
								<div className="charges-panel-content">{localString("Total Amount")}</div>
							</div>
							<div className="col-sm-4">
								<div className="charges-panel-content align-right">{(Math.round(TotalAmount * 100) / 100).toFixed(2)}</div>
							</div>
						</div>
					</div>
				</div>
				</PageContentWrapper>
			</div>
		</div>
	)
}

export default ThirdPartyCharges
