import React from 'react';
import {Radio,Input,Button,Checkbox,Table,Dropdown} from 'sfy-react';
import { findIndex,find} from 'lodash';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import {displayError,SERVICETYPE} from '../../../../../Utility/Utils';
import './IssueValidation.scss'


const IssueValidationGSX = (props) => {
	const {warrantyApplicableCheck,editIssuesClick,consumerServiceRequest,localString,accessFn,editIssues,isReportedLength,
		canUpdateServiceRequest,isDiagnosisCompleted,engineerHasDeviceLog,customerReportedSFA,engineerReportedSFA,addedFaultActions,
		disableSFASave,isValidatedLength,showCustomerOptions,publicUrl,validate,toggleCustomerOptions,changePriority,addSFARow,
		custReportedIssue,removeSFA,enggReportedIssue,engineerCodes,customerCodes,setSelectedAction,selectedAction,symptomFaultFaultActionMappings,
		issueValidityBtn,saveSFAMappings,cancelIssuesClick,csrIssuesArr,disableValidate,showRequestLogs
	} = props;

	const classObject = {
		table:"table table-striped table-responsive table-bordered table-condensed issue-validation-gsx"
	}

	return (
		<div className="row">
			<PageContentWrapper 
				title={localString("Issues Reported")} 
				showDownloadButtonIcon={
					!editIssues &&  (!(consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber)) && canUpdateServiceRequest && !accessFn('READ_ONLY_WORKSHOP') && 
					findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis initiated'}) > -1 && consumerServiceRequest.logs[0].Status != 'Diagnosis initiated' && (isDiagnosisCompleted() || (showRequestLogs.diagnosisCompletedByEngg2 || showRequestLogs.diagnosisCancelByEngg2)) 
					&& (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS')) && !consumerServiceRequest.InvoiceGeneratedDate && (engineerHasDeviceLog.DestinationType ==='ServicelocationEngineer' || engineerHasDeviceLog.DestinationType ==='Supervisor') && warrantyApplicableCheck()
				}
				editDeviceClick={editIssuesClick}
			>
			<div className="request-panel-details-tag request-issue-validation-div">
				{
					editIssues && 
					<>
						{
							csrIssuesArr && csrIssuesArr.length > 0 && 
							<div className="row">
								<div className="col-sm-12">
									<p className="issues-table-header text-capitalize remove-margin" style={{fontFamily:"usualbold"}}>{localString("Customer Reported Issues")}</p>
									<div className="table-responsive">
										<Table className={classObject}>
											<Table.Header>
												<Table.Row>
													<Table.Heading >{localString("Issues")}</Table.Heading>
													<Table.Heading >{localString("Validity")}</Table.Heading>
												</Table.Row>
											</Table.Header>
											{
												csrIssuesArr.map((item,index)=>{
													return(
														item.IsReported && 
														<div className="reported-issue-section-gsx">
															<Table.Data>{localString(item.IssueText)}
																{
																	item.IssueRemark && <span> - {localString(item.IssueRemark)}</span>
																}
															</Table.Data>
															<Table.Data>
																<div className="reported-radio-values">
																{
																	!item.IsValidated && 
																	issueValidityBtn && issueValidityBtn.map((button,i)=>{
																		return (
																			<div className="radio-selector">
																				<Radio
																					value={button.value}
																					selectedValue={item.validityStatus}
																					name={"issueValidity"+index}
																					id={item.ConsumerServiceRequestIssuesId+"_"+button.value}
																					handleOnUpdate={(e) => button.onclickupdate(e.target.value,item)}
																				>{button.labelText}</Radio>
																			</div>
																		)
																	})
																}
																</div>
																{
																	item.IsValidated && item.validityStatus == 1 &&
																	<div>{localString("Valid")}</div>
																}
																{
																	item.IsValidated && item.validityStatus == 2 && 
																	<div>{localString("Invalid")}</div>
																}    
															</Table.Data>
														</div>
													)
												})
											}
											
										</Table>
									</div>
								</div>
							</div>
						}
						{
							isReportedLength > 0 && isReportedLength > isValidatedLength && 
							<div className="validate-btn">
								<Button 
									type="submit"
									text={localString("Validate")}
									handleOnClick={validate}
									isDisabled={disableValidate}
									classes='button-ghost float-right capitalize btn'
								/>
							</div>
						}
						{
							consumerServiceRequest && consumerServiceRequest.issues && consumerServiceRequest.issues.length < 1 && 
							<div className="row">
								<div className="col-sm-12">
									<p className="charges-panel-subheader text-capitalize remove-margin">{localString("Customer did not add issues")}</p>
								</div>
							</div>
						}
						{
							isReportedLength == isValidatedLength && 
							<div className="row">
								<div className="col-sm-12 SFA-edit customer-section" style={(customerReportedSFA.length==3 || !showCustomerOptions)? {margin:"50px 0px"}: {marginTop:"50px"}}> 
									<p className="issues-table-header remove-margin" style={{padding:"12px 5px" , display:"inline-block" , fontFamily:"usualbold"}} onClick={toggleCustomerOptions}>
										{
											!showCustomerOptions && 
											<img src={publicUrl+"/imgs/Section_Close.png"} style={{width:"20px"}} />
											
										}
										{
											showCustomerOptions && <img src={publicUrl+"/imgs/Section_Open.png"} style={{width:"20px"}} />
										}
										{   localString("Customer Reported Component / Issue Code(s) [Optional]")   }
									</p>

									{
										showCustomerOptions && 
										<div className="" >
											<Table className={classObject}>
												<Table.Header>
													<Table.Row>
														<Table.Heading >{localString("Primary")}</Table.Heading>
														<Table.Heading >{localString("Component Code")}</Table.Heading>
														<Table.Heading >{localString("Issue Code")}</Table.Heading>
														<Table.Heading >{localString("Reproducibility")}</Table.Heading>
														<Table.Heading ></Table.Heading>
													</Table.Row>
												</Table.Header>
												{
													customerReportedSFA && customerReportedSFA.map((key, index)=>{
														return(
															<Table.Row>
																<Table.Data className="gsx-customer-button-center">
																	<Radio 
																		value={key.Priority}
																		selectedValue={key.Priority == 1 ? 1 : 0}
																		name="send_gsx_CUST"
																		id={'send_gsx_CUST' + index}    
																		handleOnUpdate ={(e)=>{
																			changePriority('CUST',index)
																		}}
																	/>
																</Table.Data>
																{
																	custReportedIssue && custReportedIssue.map((cust,i)=>{
																		return(
																			<Table.Data className={cust.parentClass}>
																				<Dropdown
																					id={cust.customId + index}
																					className={cust.customClassName}
																					name={cust.customName}
																					value={customerCodes?.[index]?.[cust.dataKey] || ''}
																					options={cust.dropdownList}
																					handleOnChange={(data) => {
																						cust.onInputFunc(data,index,"CUST")
																					}}
																					filterBy='value'
																					showDownArrow={false}
																				/>
																				{
																					cust.showIrisLoader && 
																					key.showIssueCodeLoader &&
																					<div className = "iris-loader">
																						<span className="primary-border-top input-loader form-control-feedback form-control-feedback-padded" aria-hidden="true"></span>
																					</div>
																				}
																			</Table.Data>
																		)
																	})
																}
																<Table.Data className="gsx-customer-button-center">
																	<span className="glyphicon glyphicon-minus-sign" onClick={(e)=>removeSFA('CUST',key,index)}></span>
																</Table.Data>
															</Table.Row>
														)
													})
												}
												
											</Table>
										</div>
									}
									{
										customerReportedSFA.length < 3 && showCustomerOptions && 
										<div className="customer-add-button">
											<Button 
												type="button"
												text={localString("Add")}
												handleOnClick={(e)=>addSFARow("CUST")}
												classes='btn button-servify float-right custom-padding'
											/>
										</div>
									}
								</div>
								<div className="col-sm-12 SFA-edit engineer-section" >
									<p className="issues-table-header remove-margin" style={{fontFamily:"usualbold"}}>{localString("Engineer Reported Component / Issue Code(s)")}*</p>
									<div className="table-responsive">
										<Table className={classObject}>
											<Table.Header>
												<Table.Row>
													<Table.Heading >{localString("Primary")}</Table.Heading>
													<Table.Heading >{localString("Component Code")}</Table.Heading>
													<Table.Heading >{localString("Issue Code")}</Table.Heading>
													<Table.Heading >{localString("Reproducibility")}</Table.Heading>
													<Table.Heading ></Table.Heading>
												</Table.Row>
											</Table.Header>
											{
												engineerReportedSFA && engineerReportedSFA.map((key,index)=>{
													return(
														<Table.Row otherProps={{id:index}}>
															<Table.Data className="gsx-engineer-button-center ">
																<Radio 
																	value={key.Priority}
																	selectedValue={key.Priority == 1 ? 1 : 0}
																	name="send_gsx_TECH"
																	id={'send_gsx_TECH' + index}    
																	handleOnUpdate ={(e)=>{
																		changePriority('TECH',index)
																	}}
																/>
															</Table.Data>
															{
																enggReportedIssue && enggReportedIssue.length> 0 && enggReportedIssue.map((engg,i)=>{
																	return(
																		<Table.Data className={engg.parentClass} otherProps={{id:index}}>
																			<Dropdown
																				id={engg.customId + index}
																				className={engg.customClassName}
																				name={engg.customName}
																				value={engineerCodes?.[index]?.[engg.dataKey] || ''}
																				options={engg.dropdownList}
																				handleOnChange={(data) => {
																					engg.onInputFunc(data,index,"TECH")
																				}}
																				filterBy='value'
																				showDownArrow={false}
																			/>
																			{
																				engg.showIrisLoader && 
																				key.showIssueCodeLoader &&
																				<div className = "iris-loader">
																					<span className="primary-border-top input-loader form-control-feedback form-control-feedback-padded" aria-hidden="true"></span>
																				</div>
																			}
																		</Table.Data>
																	)
																})
															}
															<Table.Data className="gsx-engineer-button-center">
																<span className="glyphicon glyphicon-minus-sign" onClick={(e)=>removeSFA('TECH',key,index)}></span>
															</Table.Data>
														</Table.Row>
													)
												})
											}
										</Table>
										{
											engineerReportedSFA.length < 3 &&  
											<div className="engineer-add-button" >
												<Button 
													type="button"
													text={localString("Add")}
													handleOnClick={(e)=>addSFARow("TECH",e)}
													classes='btn button-servify float-right custom-padding'
												/>
											</div>
										}
										{
											<div className='action-code col-sm-12' style={{marginBottom: "8em"}} >
												<label className="input__label">{localString("Action*")}</label>
													<Dropdown
														id="actionCode"
														value={selectedAction&& selectedAction.value || ''}
														options={symptomFaultFaultActionMappings && symptomFaultFaultActionMappings}
														handleOnChange={(data) => {
															setSelectedAction(data)
														}}
														filterBy='value'
														showDownArrow={false}
													/>
											</div>
										}
										{
											<div className=" gsx-save-cancel-button">
												<Button 
													type="button"
													text={localString("Cancel")}
													handleOnClick={cancelIssuesClick}
													classes='btn button-servify float-right custom-button'
												/>
												<Button 
													type="submit"
													text={localString("Save")}
													// isDisabled="" //disableSFASave
													isDisabled={disableSFASave}
													handleOnClick={(e)=>saveSFAMappings(e)}
													classes='btn button-servify custom-button '
												/>
											</div>
										}
									</div>
								</div>
							</div>
						}
					</>
				}
				{
					!editIssues && 
					<div className="issue-validationGSX-read-only-section">
						{    
							consumerServiceRequest && consumerServiceRequest.issues && consumerServiceRequest.issues.length < 1 && ((!customerReportedSFA || customerReportedSFA.length <1) || (!engineerReportedSFA || engineerReportedSFA.length <1)) && 
							<div class="row">
								<div class="col-sm-12">
									<p class="charges-panel-subheader text-capitalize remove-margin">{localString("No issues added")}</p>
								</div>
							</div>
						}
						{
							consumerServiceRequest && consumerServiceRequest.issues && consumerServiceRequest.issues.length >= 1 && 
							<Table isResponsible className={classObject}> 
								<Table.Header>
									<Table.Row>
										<Table.Heading>{localString("Issues")}</Table.Heading>
										<Table.Heading>{localString("Status")}</Table.Heading>
									</Table.Row>
								</Table.Header>
								{
									consumerServiceRequest && consumerServiceRequest.issues && consumerServiceRequest.issues.map(item=>{
										return(
											<Table.Row className="static-csr-issues">
												<Table.Data>{localString(item.IssueText)}{ item.IssueRemark && <span> - {localString(item.IssueRemark)}</span>}</Table.Data>
												<Table.Data>{item.IsValidated && item.validityStatus == 1? localString('Valid') : item.IsValidated && item.validityStatus == 2 ? localString('Invalid') : ''}</Table.Data>
											</Table.Row>
										)
									})
								}
							</Table>
						}
						{
							customerReportedSFA && customerReportedSFA.length > 0  && 
							<>
								<p className="issues-table-header  remove-margin" style={{padding:"12px 5px", fontFamily:"usualbold"}}>{localString("Customer Reported Component / Issue Code(s)")}</p>
								<div className="customer-report-sfa-section">
									<Table className={classObject}>
										<Table.Header>
											<Table.Row>
												<Table.Heading >{localString("Primary")}</Table.Heading>
												<Table.Heading >{localString("Component Code")}</Table.Heading>
												<Table.Heading >{localString("Issue Code")}</Table.Heading>
												<Table.Heading >{localString("Reproducibility")}</Table.Heading>
											</Table.Row>
										</Table.Header>
										{
											customerReportedSFA.map((key,i)=>{
												return(
													<Table.Row>
														<Table.Data className="primary-dot-span">{key.Priority == 1 && <span className="Primary-dot"></span>}</Table.Data>
														<Table.Data>{key.ReportedSymptom.reportedSymptomCode}{key.ReportedSymptom.reportedSymptomDesc && <span> - {key.ReportedSymptom.reportedSymptomDesc}</span>}</Table.Data>
														<Table.Data>{key.ReportedIssue.reportedIssueCode}{key.ReportedIssue.reportedIssueDesc && <span> - {key.ReportedIssue.reportedIssueDesc}</span>}</Table.Data>
														<Table.Data>{key.ComptiaModifier.modifierCode}{key.ComptiaModifier.comptiaDescription && <span> - {key.ComptiaModifier.comptiaDescription}</span>}</Table.Data>
													</Table.Row>
												)
											})
										}
										
									</Table>
								</div>
							</>
						}
						{
							engineerReportedSFA && engineerReportedSFA.length > 0 && 
							<>
								<p className="issues-table-header  remove-margin" style={{padding:"12px 5px" , fontFamily:"usualbold"}}>{localString("Engineer Reported Component / Issue Code(s)")}</p>
								<div className="engineer-report-sfa-section">
									<Table className={classObject}>
										<Table.Header>
											<Table.Row>
												<Table.Heading >{localString("Primary")}</Table.Heading>
												<Table.Heading >{localString("Component Code")}</Table.Heading>
												<Table.Heading >{localString("Issue Code")}</Table.Heading>
												<Table.Heading >{localString("Reproducibility")}</Table.Heading>
											</Table.Row>
										</Table.Header>
										{
											engineerReportedSFA.map((key,i)=>{
												return(
													<Table.Row>
														<Table.Data className="primary-dot-span">{key.Priority == 1 && <span className="Primary-dot"></span>}</Table.Data>
														<Table.Data>{key.ReportedSymptom.reportedSymptomCode}{key.ReportedSymptom.reportedSymptomDesc && <span> - {key.ReportedSymptom.reportedSymptomDesc}</span>}</Table.Data>
														<Table.Data>{key.ReportedIssue.reportedIssueCode}{key.ReportedIssue.reportedIssueDesc && <span> - {key.ReportedIssue.reportedIssueDesc}</span>}</Table.Data>
														<Table.Data>{key.ComptiaModifier.modifierCode}{key.ComptiaModifier.comptiaDescription && <span> - {key.ComptiaModifier.comptiaDescription}</span>}</Table.Data>
													</Table.Row>
												)
											})
										}
									</Table>
								</div>
							</>
						}
						{
							addedFaultActions && addedFaultActions.length > 0&& 
							<div className="action-code-show">
								<div className="action-label">{localString("Action")}</div>
								<div className="action-value">{addedFaultActions[0].ActionCode} - {addedFaultActions[0].ActionDescription}</div>
							</div>
						}   
					</div>
				}
			</div>
			</PageContentWrapper>
		</div>
	)
}

export default IssueValidationGSX
