import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import {displayError,regexTest,returnLocalDateTime} from '../../Utility/Utils';
import AdvisorReset from './AdvisorReset';
import LocationAdvisorDetails from './LocationAdvisorDetails';


const RetailerLocationReset = (props) => {
	const {apiCall,localString,theme} = props;
	const Theme = JSON.parse(theme);
	const advisorLabel = Theme.salesmanName;
	const [showLoader ,setLoader] = useState(false);
	const [showResetButton, setShowResetButton] = useState(false)
	const [resetSucessful, setResetSucessful] = useState(false)
	const [advisorDetailsShow, setAdvisorDetailsShow] = useState(false)
	const [advisorLocationData, setAdvisorLocationData] = useState([])
	const [dropDownList, setDropDownList] = useState([])
	const [totalLogs, setTotalLogs] = useState([])
	const [submitBtnValidation, setSubmitBtnValidation] = useState(true)
	const [advisorDetails, setAdvisorDetails] = useState({
		advisorName:"",
		RetailUserID:""
	})

	const getAdvisorlist = ()=> {
		setLoader(true);
		apiCall({
			url:'getAdvisorsList',
			data: {
				'resetAction': "location"
			},
			callBack:(result)=>{
				if(result.success){
					setLoader(false);
					let advisorListsArr = [];
					result.data && result.data.map(list=>{
						if(list && list.displayDetails){
							advisorListsArr.push({...list,value:list.displayDetails})
						}
					})
					setDropDownList(advisorListsArr)
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const getadvisordetails = ()=> {
		setLoader(true);
		apiCall({
			url:'getPartnerRetaillocationAdvisorDetails',
			data: {
				"RetailUserID": advisorDetails.RetailUserID
			},
			callBack:(result)=>{
				setLoader(false); 
				if(result.success){
					setAdvisorDetailsShow(true)
					setAdvisorLocationData(result.data)
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					})							
					setAdvisorDetailsShow(false)
				}
			}	
		});
	}

	const advisorLocationArray = [{
		Name :	advisorLabel + " Name " ,
		Value : advisorLocationData &&  advisorLocationData.Name
	},{
		Name : "Shop",
		Value : advisorLocationData && (advisorLocationData.ShopCode || advisorLocationData.ShopName) && (advisorLocationData.ShopCode +" - "+ advisorLocationData.ShopName)
	},{
		Name : "Mobile Number",
		Value : advisorLocationData &&  advisorLocationData.MobileNo ,
	},{
		Name : "Last Location Resetted On",
		Value : advisorLocationData && advisorLocationData.lastAggregateData && returnLocalDateTime(advisorLocationData.lastAggregateData.lastUpdatedDate,"DD-MM-YYYY")
	},{
		Name : "No. of Resets Made",
		Value : advisorLocationData && advisorLocationData.lastAggregateData && advisorLocationData.lastAggregateData.Count ,
	}]

	const labelArray =[{
		labelText : localString("Location Reset"),
		customClass : "col-sm-12 col-xs-8 form-label custom-label",
		showFilter:false
	},{
		labelText : localString("Select " + advisorLabel + " Name "),
		customClass : "col-md-2",
		customLabelClass:"pull-right button-handset-div inner-padding-3x",
		dataKey:"advisorName",
		showDropdownList:dropDownList,
		showFilter:true,
		oninputFunc: (val) => {
			setAdvisorDetails({...advisorDetails,advisorName:val.value,RetailUserID:val.RetailUserID})
		}
	}]

	useEffect(() => {
		setTotalLogs(advisorLocationData.totalLogs)
	}, [advisorLocationData])

	useEffect(() => {
		getAdvisorlist()
	}, [])

	useEffect(() => {
		if(advisorLocationData && advisorLocationData.IsLocationVerified !=0){
			setShowResetButton(true)
			setResetSucessful(false)
		}
		if(!advisorLocationData.IsLocationVerified || advisorLocationData.IsLocationVerified == null || advisorLocationData.IsLocationVerified == "" ){
			setResetSucessful(true)
			setShowResetButton(false)
		}
	}, [advisorLocationData])

	useEffect(() => {
		if(advisorDetails.RetailUserID){
			setSubmitBtnValidation(false)
		}
		if(!advisorDetails.RetailUserID){
			setSubmitBtnValidation(true)
		}
	}, [advisorDetails])

	const resetHandsetLocation =(e)	=>{
		e.preventDefault();
		Swal.fire({
			title: "Are you sure you want to Reset Location?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Yes",
			cancelButtonText: "No",
			// closeOnConfirm: false,
			// closeOnCancel: true
		}).then(result=>{
			if(result.isConfirmed){
				setLoader(true);
				apiCall({
					url:'resetLocation',
					data: advisorLocationData,
					callBack:(result)=>{
						setLoader(false);
						if(result.success){
							Swal.fire({
								title: "Reset Successful", 
								icon: "success", 
								confirmButtonText: "Ok"
							})
							setShowResetButton(false)
							setResetSucessful(true)
							getadvisordetails()
						}else{
							Swal.fire({  
								icon: 'error',  
								text: localString(displayError(result)),  
							}); 
						}
					}	
				});
			}
		})
	}

	let currentLocation = [{
		latitude:advisorLocationData && advisorLocationData.Lat,
		longitude:advisorLocationData && advisorLocationData.Lng,
		shop:advisorLocationData && advisorLocationData.ShopCode + " - " + advisorLocationData.ShopName,
		updatedDate:advisorLocationData && advisorLocationData.lastAggregateData && returnLocalDateTime(advisorLocationData.lastAggregateData.lastUpdatedDate,"DD-MM-YYYY")
	}]

	const tableHeader = [{
		title : "Latitude",
	},{
		title : "Longitude",
	},{
		title : "Shop",
	},{
		title : "Updated Date",
	}]


	return (
		<>
		{ showLoader && ReactDOM.createPortal(
			<Loader />,
			document.getElementById("loader-root")
		)}
		<AdvisorReset {...props} labelArray={labelArray} getadvisordetails={getadvisordetails} advisorDetails={advisorDetails} submitBtnValidation={submitBtnValidation}></AdvisorReset>
		{
			advisorDetailsShow && <LocationAdvisorDetails {...props} currentLocation={currentLocation} advisorLocationArray={advisorLocationArray} totalLogs={totalLogs} tableHeader={tableHeader} resetSucessful={resetSucessful} showResetButton={showResetButton} resetHandsetLocation={resetHandsetLocation} advisorLabel={advisorLabel}></LocationAdvisorDetails>
		}
		</>
	)
}

export default RetailerLocationReset
