import React,{useCallback,useRef,useState,useEffect} from 'react';
import './BillingDetails.scss';
import {Input,Button,Dropdown,Radio,setAutocomplete} from 'sfy-react';
import { displayError, regexTest } from '../../../../../Utility/Utils';



const AddNewBillingAddress = (props) => {
    const {localString,inputLabels,inputData,stateList,onFormSaveClick,resetAddBilling,setInputData,checkStateValid,countryConfigArray,
        errors,setErrors,validateFields
    } = props;
    const [autoCompleteVal, setAutoCompleteVal] = useState(null);
    const [updateAddressErrors, setUpdateAddressErrors] = useState({});

    useEffect(() => {
		setErrors({ ...errors, ...updateAddressErrors });
	}, [updateAddressErrors]);

    const landmarkRef = useRef(null)

    useEffect(() => {
        const autocomplete = setAutocomplete(landmarkRef.current, handleLandmarkSelection)
        return () => {
            window.google.maps.event.clearListeners(autocomplete, 'place_changed')
        }
    }, [handleLandmarkSelection])

    useEffect(() => {
		updateAddress(autoCompleteVal);
	}, [autoCompleteVal]);

    const handleLandmarkSelection = useCallback(
        (value) => {
            setAutoCompleteVal(value);
        },[]
    )

    // const validateFields = (key, value, returnValue) => {
	// 	let configObj = countryConfigArray.find(item => (item.keyName === key));
	// 	if(configObj) {
	// 		if(!value && configObj.mandatory) { // Mandatory check
	// 			if(returnValue) {
	// 				let obj = { flag: false, msg: localString(configObj.label + " is required") }
	// 				return obj
	// 			}else {
	// 				setErrors({ ...errors, [key]: localString(configObj.label + " is required") })
	// 			}
	// 		}else if(!value && !configObj.mandatory) { // if value is empty and key in !mandatory then pass
	// 			if(returnValue) {
	// 				let obj = { flag: true, msg: null }
	// 				return obj
	// 			}else {
	// 				setErrors({ ...errors, [key]: null });
	// 			}
	// 		}else if((configObj.regexFunc && ["NUMERIC", "ALPHANUMERIC"].includes(configObj.regexFunc) && !regexTest((configObj.regexFunc).toLowerCase(), value)) || (configObj.length && !configObj.length.includes(value.length))) { //Regex and input length check
	// 			if(returnValue) {
	// 				let obj = { flag: false, msg: localString(configObj.label + " is invalid") }
	// 				return obj
	// 			}else {
	// 				setErrors({ ...errors, [key]: localString(configObj.label + " is invalid") })
	// 			}
	// 		}else if(configObj.keyName == "State" && !checkStateValid(value)) { // State Check
	// 			if(returnValue) {
	// 				let obj = { flag: false, msg: localString(configObj.label + " is invalid") }
	// 				return obj
	// 			}else {
	// 				setErrors({ ...errors, [key]: localString(configObj.label + " is invalid") })
	// 			}
	// 		}
    //         // else if(configObj.keyName == "District" && !checkDistrictValid(value)) { // State Check
	// 		// 	if(returnValue) {
	// 		// 		let obj = { flag: false, msg: localString(configObj.label + " is invalid") }
	// 		// 		return obj
	// 		// 	}else {
	// 		// 		setErrors({ ...errors, [key]: localString(configObj.label + " is invalid") })
	// 		// 	}
	// 		// }
    //         else {
	// 			if(returnValue) {
	// 				let obj = { flag: true, msg: null }
	// 				return obj
	// 			}else {
	// 				setErrors({ ...errors, [key]: null });
	// 			}
	// 		}
	// 	}
	// }

    const updateAddress = (placesObj) => {
		let addressObj = {...inputData};
		let errorsObj = {...updateAddressErrors};
		addressObj.Landmark = placesObj && placesObj.formatted_address;
		placesObj && placesObj.address_components && placesObj.address_components.map(place => {
			if (place && place.types.includes('postal_code') && countryConfigArray.find((obj) => obj.keyName == 'Zipcode')) {
				addressObj.Zipcode = place.short_name ? place.short_name : ''
				let obj = validateFields('Zipcode', addressObj.Zipcode, true);
				errorsObj.Zipcode = obj.msg;
			}

			if (place && place.types.includes('postal_code') && countryConfigArray.find((obj) => obj.keyName == 'PinCode')) {
				addressObj.PinCode = place.short_name ? place.short_name : ''
				let obj = validateFields('PinCode', addressObj.PinCode, true);
				errorsObj.PinCode = obj.msg;
			}

			if (place && place.types.includes('country')) {
				addressObj.Country = place.long_name ? place.long_name : ''
			}

			if (place && place.types.includes('administrative_area_level_1') && countryConfigArray.find((obj) => obj.keyName == 'State')) {
				if(place && place.long_name && checkStateValid(place.long_name)) {
					addressObj.State = place.long_name;
				}else {
					addressObj.State = '';
				}
				let obj = validateFields('State', addressObj.State, true);
				errorsObj.State = obj.msg;
			}

			if (place.types.includes('locality') && countryConfigArray.find((obj) => obj.keyName == 'City')) {
				addressObj.City = place.long_name ? place.long_name : ''
				let obj = validateFields('City', addressObj.City, true);
				errorsObj.City = obj.msg;
			}
		});
		addressObj.Lat = placesObj && placesObj.geometry.location.lat();
		addressObj.Lng = placesObj && placesObj.geometry.location.lng();
		setInputData(addressObj);
		setUpdateAddressErrors(errorsObj);
	}

    return (
        <div class="gst-info-wrapper col-sm-12" >
            {
                inputLabels && inputLabels.map((labels,index)=>{
                    return(
                        <>
                            <div class="entity-type">
                            {
                                labels.type == "radio" && 
                                labels.radioButtons && labels.radioButtons.map(radio=>{
                                    return(
                                        <Radio
                                            value={radio.value}
                                            name={radio.btnName}
                                            selectedValue={radio.isSelect}
                                            handleOnUpdate={(e)=>{
                                                radio.onclickupdate(e.target.value)
                                            }}
                                        >{radio.buttonText}</Radio>
                                    )
                                })
                            }
                            </div> 
                            <div className="gst-info" id="gstInfoBlock-{identifier}">
                                {
                                    labels.type == "input" && 
                                    <div className={labels.customBlockClass}>
                                        {
                                            labels.ref 
                                            ?   <Input
                                                    value={inputData[labels.dataKey]}
                                                    key={index}
                                                    id={labels.id}
                                                    handleOnChange={(e) => {
                                                        labels.oninputFunc(e.target.value);
                                                        validateFields('Landmark', e.target.value)
                                                    }}
                                                    label={labels.labelText}
                                                    labelAlign='top'
                                                    isDisabled={labels.isDisabled}
                                                    maxLength={labels.maxLength}
                                                    placeholder={labels.placeholder}
                                                    reference={landmarkRef}
                                                    message={{type: 'error', value: errors.Landmark}}
                                                /> 
                                            :   <Input
                                                    value={inputData[labels.dataKey]}
                                                    key={index}
                                                    id={labels.id}
                                                    handleOnChange={(e) => {
                                                        labels.oninputFunc(e.target.value);
                                                    }}
                                                    label={labels.labelText}
                                                    labelAlign='top'
                                                    message={{type: 'error', value: errors[labels.dataKey]}}
                                                    isDisabled={labels.isDisabled}
                                                    maxLength={labels.maxLength}
                                                    placeholder={labels.placeholder}
                                                />
                                        }
                                        {
                                            labels.errorMsg && 
                                            <div className="input-error-block">
                                                <span className="msg-text" id="">{localString(labels.errorText)}</span>
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    labels.countryArray && labels.countryArray.map((dev,i)=>{
                                        if(dev.keyName != "Landmark" && dev.keyName != "State" && dev.display == true){
                                            return(
                                                <div className="col-sm-6 padding-top-bottom">
                                                    <Input
                                                        value={inputData[dev.keyName]}
                                                        key={i}
                                                        handleOnChange={(e) => {
                                                            labels.oninputFunc(e.target.value,dev)
                                                            validateFields(dev.keyName, e.target.value)
                                                        }}
                                                        label={dev.label}
                                                        labelAlign='top'
                                                        message={{type: 'error', value: errors[dev.keyName]}}
                                                    />
                                                </div>
                                            )
                                        }else if(dev.keyName === "State" && dev.display == true){
                                            return(
                                                <div className="col-sm-6 padding-top-bottom custom-dropdown-css">
                                                    <label>{localString(dev.label)}</label>
                                                    <Dropdown
                                                        value={inputData[dev.keyName]}
                                                        options={stateList}
                                                        handleOnChange={(data) => {
                                                            labels.oninputFunc(data,dev);
                                                            validateFields(dev.keyName, data.State)
                                                        }}
                                                        filterBy='value'
                                                        showDownArrow={false}
                                                    />
                                                    { errors[dev.keyName] && <span className="error errMessage">{localString(errors[dev.keyName])}</span>}
                                                </div>
                                            )
                                        }
                                        
                                    })
                                }
                            </div>
                        </>
                    )
                })
            }
            <div className="col-sm-12 custom-style">
                <Button className="btn button-servify button-stack" handleOnClick={onFormSaveClick} text={localString("Save")}/> 
                <Button className="btn button-servify button-stack" handleOnClick={resetAddBilling} text={localString("Cancel")}/> 
            </div>
        </div>
    )
}

export default AddNewBillingAddress
