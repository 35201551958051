import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Loader } from 'sfy-react';

import PageHeader from '../../components/PageHeader/PageHeader';
import DownloadReport from './DownloadReports';
import SchedulerReport from './SchedulerReport';
import './Reports.scss';


const Reports = (props) => {
	const { accessFn, localString, apiCall } = props;
	const [reportType, setReportType] = useState('download');
	const [loader, setLoader] = useState(false);

	return (
		<>
			{
				loader && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<PageHeader titleHeader={"Reports"} localString={localString}/>
			<div className="reports container-fluid">
				<div className='row'>
					{
						accessFn('SCHEDULE_REPORTS') &&
							<>
								<div className="col-xs-12 col-sm-6 padding0" onClick={() => setReportType('download')}>
									<div className={`reportType ${reportType === 'download' ? 'active' : ''}`}>
										{localString('Download Reports')}
									</div>
								</div>
								<div className={`col-xs-12 col-sm-6 padding0`} onClick={() => setReportType('scheduler')}>
									<div className={`reportType ${reportType === 'scheduler' ? 'active' : ''}`}>
										{localString('Schedule Reports')}
									</div>
								</div>
							</>
					}
				</div>
			</div>

			{ reportType === 'download' && 
				<DownloadReport 
					{...props}
					report='reports'
					apiCall={apiCall}
					accessFn={accessFn}
					setLoader={setLoader}
					localString={localString}
				/> 
			}
			{ reportType === 'scheduler' && 
				<SchedulerReport 
					{...props}
					apiCall={apiCall}
					accessFn={accessFn}
					report='schedulerReports'
					localString={localString}
				/> 
			}
		</>
	);
}

export default Reports;