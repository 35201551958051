import React from 'react';
import {Radio} from 'sfy-react';

const InspectDeviceRadioButton=(props)=>{
    const {accessFn,localString,radioBtnData,isInward,checkListAllData} = props;
    return (
		<>
            {
                radioBtnData && radioBtnData.map(radioBtn=>(
                    <>
                        {accessFn('GSX_FLOW') && radioBtn.checkList && radioBtn.checkList.length >0 && <h3 className=''>{radioBtn.labelBtnText}</h3>}
                        {!accessFn('GSX_FLOW')  && <h3 className=''>{radioBtn.labelBtnText}</h3>}
                        { radioBtn.checkList && radioBtn.checkList.length >0 && <div className='docRow'>
                            <div className='col-sm-12'>
                                <div className='row'>
                                {
                                    radioBtn.labelTextData  && radioBtn.labelTextData.map(label=>(
                                        
                                            label.showLabel &&<label className={label.customCls}>
                                                {label.labelText}
                                            </label>
                                        
                                    ))
                                }
                                </div>
                                <div className='row'>
                                    {
                                        radioBtn.checkList && radioBtn.checkList.length>0 && radioBtn.checkList.map((item,i)=>(
                                            <div className='col-sm-12'>
                                                <div class="row checkbox-item text-left">
                                                    <label className='col-sm-5 checkboxFeature'>
                                                        {item.Name || item.name}
                                                    </label>
                                                    {(isInward && checkListAllData && checkListAllData.outwardCheckExists) || (!isInward && checkListAllData && checkListAllData.inwardCheckExists) &&<label class="col-sm-2 checkboxFeature" >
                                                        {isInward ? item.Outward == 1 ? localString('Yes') : item.Outward == 2 ? localString('No') : item.Outward == 3 ? localString('NA') : localString('NA') : item.Inward == 1 ? localString('Yes') : item.Inward == 2 ? localString('No') : item.Inward == 3 ? localString('NA') : localString('NA')}
                                                    </label>}
                                                    {
                                                        radioBtn.radioButton && radioBtn.radioButton.map((radio,index)=>(
                                                            <label className = 'check-label'>
                                                                <Radio
                                                                    value={radio.value}
                                                                    selectedValue={isInward ? item.Inward : item.Outward}
                                                                    name={radio.name+i}
                                                                    id={item.Name+index}
                                                                    classes='customClasses'
                                                                    handleOnUpdate={(e)=>{
                                                                        radio.onUpdateRadio(e.target.value,i)
                                                                    }}
                                                                >
                                                                    {localString(radio.radioText)}
                                                                </Radio> 
                                                            </label>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>}
                    </>
                ))
            }
        </>
	)
}
export default InspectDeviceRadioButton;