import React from 'react';
import {Modal} from 'sfy-react';
import UTRPymntDateInput from './UTRPymntDateInput';
import './UTRPymntDateModal.scss'
const UTRPymntDateModal=(props)=>{
    const {localString,showModal,closeModal} = props;
		return ( <div className='UTR-main'>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='UTR-modal' >
                <Modal.Header>
                    <h4 className='modal-title text-center'> {localString("Please enter UTR Number")} &amp; {localString("Payment Date")}</h4>
                </Modal.Header>
                <Modal.Body>
                    <UTRPymntDateInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div> )
}
export default UTRPymntDateModal;