import React, { useState,useEffect } from 'react';
import {Dropdown} from 'sfy-react';
import Swal from 'sweetalert2';
import { Loader } from 'sfy-react';
import ReactDOM from 'react-dom';

const ApiSearchDropdown = (props) => {
    const {API,APIData,debounceTime,minSearchLength,searchRequestParam,options,index,value,apiCall,displayError,localString,handleOnChange,callAPIForData,placeholder,addSearchData} = props;
    const [loader, setLoader] = useState(false);
    const [searchData ,setSearchData] = useState("");
    const [debounceTimeout, setDebounceTimeout] = useState(false);
    const [optionDropDown,setOptionDropdown] = useState(options || []);
    const [filteredOptions,setfilteredOptions] = useState(options || []);
    useEffect(() => {
        if(searchData.length  >= (minSearchLength || 2)){
            debounceTimeout && clearTimeout(debounceTimeout);
                setDebounceTimeout(
                    setTimeout(()=>{
                        APISearch();
                    }, debounceTime || 400)
                );
        }
	}, [searchData])
    const APISearch = () =>{
        let data = {...APIData};
        data[searchRequestParam] = searchData;
        setLoader(true);
        apiCall({
            url: API,
            data: data,
            callBack:(result)=>{
                setLoader(false);
                if(result.success){
                    let data = result.data.map(v => ({...v, value:v.partItemCode}))
                    setOptionDropdown(data)
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: localString(displayError(result)),
                    });
                }
            }
        });
    }
    useEffect(()=>{
        if(searchData.length  >= (minSearchLength || 2)){
            let filterdData = optionDropDown && optionDropDown.filter((obj) =>
            obj.value
                .toLowerCase()
                .includes(
                    searchData.toLowerCase()
                )
            );
            setfilteredOptions(filterdData)
        }
    },[optionDropDown])
    return ( <>
        {
				(loader) && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
		<Dropdown
            id={'apisearch'+index?index:""}
            key={'apisearch'+index?index:""}
            value={value || ''}
            options={optionDropDown}
            placeholder={placeholder}
            handleOnChange={(data) => {
                handleOnChange(data);
            }}
            handleOnSearch={(data)=>{
                if(addSearchData){
                    addSearchData(data.target.value);
                }
                if(callAPIForData && APISearch){
                    setSearchData(data.target.value);
                }
                let filterdData = optionDropDown && optionDropDown.filter((obj) =>
                obj.value
                    .toLowerCase()
                    .includes(
                        data.target.value.toLowerCase()
                    )
                );
                setfilteredOptions(filterdData)
                return filterdData
            }}
            showNoOptionsText={false}
            handleOnFocus={(e)=>{
                e.target.value = value;
            }}
            filterBy='value'
            showDownArrow={false}
        />
        {(filteredOptions?.length<1 && searchData?.length>0)?
        (<p className='error f12'>No data found</p>):null}
	</>) 
}
export default ApiSearchDropdown; 