import React from 'react';
import './AddCharges.scss'
import {Input, Button} from 'sfy-react';


const EstimationButtons = (props) => {
	const {
        localString, chargeType, selectedChargeTypeArray, consumerServiceRequest, qFilter, accessFn, isBoseFlow, 
        isWorkshopFlow, exceptionRaised, exceptionRejected
    } = props;

	return (
		<>
            {/* <div className="row">
                <div className="col-sm-12">
                    <p className="charges-panel-subheader text-capitalize remove-margin">{localString('ADD CHARGES')}</p>
                </div>
            </div> */}
            {/* {(accessFn('ALLOW_ADDING_DISCOUNT') && csrid.allowDiscount && ['Claim estimation generated'].indexOf(csrid.Status) > -1) || ((([3,5,6,7].indexOf(csrid.ServiceTypeID) == -1) || ([3].indexOf(csrid.ServiceTypeID) > -1 && isBoseFlow)) && ['Claim estimation generated', 'Supporting documents required'].indexOf(csrid.Status) == -1)} */}
            {(
                (!isWorkshopFlow && ((accessFn('ADD_PART_POST_APPROVAL') && consumerServiceRequest.allowAddPart) || (accessFn('ALLOW_ADDING_DISCOUNT') && consumerServiceRequest.allowDiscount && ['Claim estimation generated'].indexOf(consumerServiceRequest.Status) > -1) || ((([3,5,6,7].indexOf(consumerServiceRequest.ServiceTypeID) == -1) || ([3].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && isBoseFlow)) && ['Claim estimation generated', 'Supporting documents required'].indexOf(consumerServiceRequest.Status) == -1))) || 
                (isWorkshopFlow && (['Claim estimation generated', 'Supporting documents required'].indexOf(consumerServiceRequest.Status) == -1 && ((accessFn('GSX_FLOW') && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId) || !accessFn('GSX_FLOW') && (!exceptionRaised || (exceptionRaised && exceptionRejected)))))
            ) &&
            <div>
                <div style={{ display: 'flex' }}>
                    { chargeType && chargeType.map((type, index) => {
                        if(type.display) {
                            return(
                                // <div className="col-sm-6 custom-charges-button-container">
                                <div className="">
                                    <Button 
                                        className="btn squareBtnGhost" 
                                        handleOnClick={(e) => {type.onClick(type,index)}} 
                                        isDisabled={type.disabled}
                                    >
                                        <span className="glyphicon glyphicon-plus addBtn"></span> {localString(type.Display)}
                                    </Button>
                                </div>
                            )
                        }
                    })}
                </div>
            </div> }
	    </>
	)
}

export default EstimationButtons;
