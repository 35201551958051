import React, { useEffect, useState } from 'react';
import { Button, Input, Dropdown } from 'sfy-react';
import Swal from 'sweetalert2';
import _ from 'lodash';

import { checkNumberPattern, createRefIDLikeString, displayError, SERVICETYPE } from '../../../../../Utility/Utils';

import './DoaChecklist.scss';

const DoaChecklist = (props) => {
    const {
        localString, consumerServiceRequest, setLoader, apiCall,accessFn,ConsumerServiceRequestID, closeModal, getRequestById
    } = props; 
	const [disableSaveDoaChecklist,setdisableSaveDoaChecklist] = useState(true);

 
    const doaChecklist = _.map(consumerServiceRequest.doaList, function(item) {
		return {
			'checkName': item
		}
	});

    const doaItemYes = (item) => {
		item.checked = true;
		checkDoaItem()
	};

	const doaItemNo = (item) => {
		item.checked = false;
		checkDoaItem()
	}

	const checkDoaItem =() => {
		for (let i = 0; i < doaChecklist.length; i++) {
			if (!_.isBoolean(doaChecklist[i].checked)) {
				setdisableSaveDoaChecklist(true);
				return;
			}
		}
		setdisableSaveDoaChecklist(false);
	}

   const saveDOAChecklist = () => {
        Swal.fire ({
			title: 'Are you sure?',
			icon: 'info',
			showConfirmButton: true,
			showCancelButton: true,
        	cancelButtonText: localString("Cancel"),
        	confirmButtonText: localString("Yes")
		}).then(result=> {
			if (result.isConfirmed) {
				let checklist = []
				for (let iCount = 0;iCount<consumerServiceRequest.doaList.length;iCount++) {
					let obj = {};
					obj['DoaItem'] = consumerServiceRequest.doaList[iCount];
					if (doaChecklist[iCount].checked) {
						obj['DoaStatus'] = 'yes'
					} else {
						obj['DoaStatus'] = 'no'
					}
					checklist.push(obj)
				}
				let data = {
					"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
					"DOAStatus": 'applicable',
					"CheckDoaList": checklist
				}
				apiCall({
						url: 'updateDOAStatus',
						data: data,
						callBack: (result) => {
                            setLoader(true);
								if (result.success) {
									if (result.data.DOAStatus == 'not applicable') {
										Swal.fire({
											title: '',
											text: localString("This device is not eligible for")+ ' DOA',
											icon: 'error',
											confirmButtonText: localString('OK'),
											showConfirmButton: true
										}).then (result=> {
                                           if (result.isConfirmed) {
										   getRequestById();
                                           }
										})
									} else {
										Swal.fire({
											title: '',
											text: localString("This device is eligible for")+' DOA',
											icon: 'success',
											confirmButtonText: localString('OK'),
											showConfirmButton: true
										}).then (result=> {
                                            if (result.isConfirmed) {
                                            getRequestById();
                                            }
										})
									}

								} else {
                                    Swal.fire({
											title: '',
											text: localString(displayError(result)),
											icon: 'error',
											confirmButtonText: localString('OK')
										});
								}
						}
				});
			}
		})
	}
     
    return (
        <>
            <div className='doaChecklist' style={{ padding: '0px 50px' }}>
            <div className="docRow">
                { doaChecklist && doaChecklist.length> 0 &&
				<div className="col-sm-12">
					<div className="row">
						{doaChecklist.map((item, i) => {
						return (
						<div 
						key={i}
						className="col-sm-12" style={{ marginLeft : '6px' }}>
							<div className="row checkbox-item text-left">
								<label for={"checkbox_"+i} className="col-sm-7 checkboxFeature">
									{localString(item.checkName)}
								</label>
								<label className = "check-label">
									<input type="radio" 
									name={"doa-issuecheckList_" + i} 
									onClick={() => doaItemYes(item)} 
									checked={item.checked} 
									id={"doacheckbox_"+i} />
									{localString("Yes")}
								</label>
								<label className='check-label'>
									<input type="radio" 
									name={"doa-issuecheckList_" + i} 
									onClick={() => doaItemNo(item)} 
									id={"doacheckbox_"+i} 
									checked={item.checked != undefined && !item.checked} />
									{localString("No")}
								</label>
							</div>
						</div>
						)
						})
					    }
					</div>
				</div>
                 }
				<div className='text-right' style={{ padding: '15px 90px' }}>
					<Button
						handleOnClick={saveDOAChecklist}
						classes="btn button-servify pull-right"
						isDisabled = {disableSaveDoaChecklist}
					>
						{localString('Complete')}
					</Button> 
			    </div>
			</div>
            </div>
        </>
    )
}
export default DoaChecklist;