import React from 'react';
import {Dropdown,Button,Checkbox,Table} from 'sfy-react';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import { SERVICETYPE } from '../../../../../Utility/Utils';
import './IssueValidation.scss'

const IssueValidationNonWorkshop = (props) => { 
	//removed getIssuesValue, issues
	const {add,deleteIssue,warrantyApplicableCheck,coveredUnderPlanCheck,isClaimEstimationApproved,editIssuesClick,validate,collectIssues,
		checkIfEngineerVisitCreated,cancelIssuesClick,consumerServiceRequest,localString,accessFn,editIssues,isReportedLength,csrIssuesArr,
		issuesList,setAdditionalIssues,additionalIssues,isBoseFlow

	} = props;

	const classObject = {
		table:"table table-striped table-responsive table-bordered table-condensed issue-validation-non-workshop"
	}

	return (
		<div className="row">
			<PageContentWrapper 
				title={localString("Issue Validation")} 
				showDownloadButtonIcon={
					([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) === -1  || ([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && isBoseFlow)) && !consumerServiceRequest.InvoiceGeneratedDate && !accessFn('READ_ONLY') && 
					((['Service initiated','Repair initiated'].indexOf(consumerServiceRequest.Status) > -1 && warrantyApplicableCheck() && coveredUnderPlanCheck()) || (['Pending for assignment', 'In-home request accepted', 'Serviceman reached', 'Service accepted'].indexOf(consumerServiceRequest.Status) > -1 && !isClaimEstimationApproved() && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1))
				}
				editDeviceClick={editIssuesClick}
				bodyClassName="panel-body-no-padding"
			>
			<div className="request-panel-details-tag request-issue-validation-div">
			{
				editIssues && 
				<>
				<div className="row">
					<div className="col-sm-12">
						<p className="charges-panel-subheader text-capitalize remove-margin">{localString('Consumer Raised Issues')}</p>
					</div>
				</div>
				{
					csrIssuesArr && csrIssuesArr.length > 0 &&
					<div className="row">
						<div className="col-sm-12">
							<Table className={classObject}>
								<Table.Header>
									<Table.Row>
										<Table.Heading className="">{localString('Issues')}</Table.Heading>
										{
											checkIfEngineerVisitCreated() && 
											<Table.Heading className="">{localString('Validate')}</Table.Heading>
										}
									</Table.Row>
								</Table.Header>
								{
									csrIssuesArr.map((item,i)=>{
										return(
											item.IsReported && 
											<Table.Row className="issue-validation-nonworkshop-table-row">
												<Table.Data>
													{localString(item.IssueText)}
													{
														item.IssueRemark && 
														<span> -{localString(item.IssueRemark)}</span>
													}
												</Table.Data>
												{
													checkIfEngineerVisitCreated() && 
													<Table.Data className="issue-validation-checkbox">
														<Checkbox
															className="checkboxForIssueValidation"
															value={item.IsValidated}
															id={"issueCheckbox"+i}
															name='issueCheckbox'
															handleOnChange={(e) => {
																collectIssues(null,item)
															}}
															isDisabled={item.IsValidated && item.validityStatus !== 0}
														/>
													</Table.Data>
												}                                                            
											</Table.Row>
										)
									})
								}
								
							</Table>
						</div>
					</div>
				}
				{
					isReportedLength > 0 && checkIfEngineerVisitCreated() &&
					<div className="validate-btn">
						<Button 
							type="submit"
							text={localString("Validate")}
							handleOnClick={validate}
							classes='button-ghost charges-button btn'
						/>
					</div>
				}
				{
					consumerServiceRequest && consumerServiceRequest.issues && consumerServiceRequest.issues.length < 1 && 
					<div className="col-sm-12">
						<p className="charges-panel-subheader text-capitalize remove-margin">{localString('Customer did not add issues')}</p>
					</div>
				}
				<hr/>
				{
					<div className="row">
						<div className="col-sm-12">
							<Table className={classObject}>
								<Table.Header>
									<Table.Row>
										<Table.Heading>{localString('Issues')}</Table.Heading>
									</Table.Row>
								</Table.Header>
								{
									consumerServiceRequest && consumerServiceRequest.issues && consumerServiceRequest.issues.map((item,i)=>{
										return(
											!item.IsReported &&
											<Table.Row className="non-workshop-static-tabel-row">
												<Table.Data className="issue-validation-static-issueList">
													{localString(item.IssueText)}
													{
														item.IssueRemark && <span> - {localString(item.IssueRemark)}</span>
													}
													<Button
														type="button" 
														name="delete"
														id={"delete"+i}
														text={localString("x")}
														className="btn button-servify close-issue-non-workshop"
														handleOnClick={()=>deleteIssue(item)}
													/>
												</Table.Data>
											</Table.Row>
										)
									})
								}
							</Table>
						</div>
					</div>
				}
				<div className="">
					<div className="col-sm-12">
						<p className="charges-panel-subheader text-capitalize remove-margin">{localString('Add New Issues')}</p>
					</div>
				</div>
				<div class="">
					<div class="col-sm-7 form-group typeahead-left-padding">
						<Dropdown
							id="issues"
							value={additionalIssues && additionalIssues.Issue}
							options={issuesList}
							handleOnChange={(data) => {
								setAdditionalIssues(data)
							}}
							filterBy='value'
							showDownArrow={false}
						/>
					</div>
					<div>
						<Button 
							type="button" 
							className="btn button-servify charges-button btn btn-top-margin"
							text={localString('Add')}
							handleOnClick={add}
						/>
					</div>
				</div>
				<div>
					<Button 
						type="button" 
						className="btn custom-issue-cancel-button"
						text={localString('Cancel')}
						handleOnClick={cancelIssuesClick}
					/>
				</div>
				</>
			}
			{
				!editIssues && consumerServiceRequest.showSymptomLine &&
				<>
				{
					consumerServiceRequest && consumerServiceRequest.symptomFaultActions.length < 1 && 
					<div className="row">
						<div className="col-sm-12">
							<p className="charges-panel-subheader text-capitalize remove-margin">{localString("No symptoms added")}</p>
						</div>
					</div>
				}
				{
					consumerServiceRequest && consumerServiceRequest.symptomFaultActions.length>= 1 && 
					<Table isResponsive>
						<Table.Header>
							<Table.Row>
								<Table.Heading>{localString("Symptom Description")}</Table.Heading>
								<Table.Heading>{localString("Fault Code")}</Table.Heading>
								<Table.Heading>{localString("Fault Description")}</Table.Heading>
								<Table.Heading>{localString("Action Description")}</Table.Heading>
							</Table.Row>
						</Table.Header>
						{
							consumerServiceRequest && consumerServiceRequest.symptomFaultActions && consumerServiceRequest.symptomFaultActions.map(symptom=>{
								return(
									<Table.Row>
										<Table.Data>{symptom.SymptomDescription}</Table.Data>
										<Table.Data>{symptom.FaultCode}</Table.Data>
										<Table.Data>{symptom.FaultDescription}</Table.Data>
										<Table.Data>{symptom.ActionDescription}</Table.Data>
									</Table.Row>
								)
							})
						}
					</Table>
				}
				</>
			}
			{
				!editIssues && ([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || ([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && isBoseFlow)) && 
				consumerServiceRequest && consumerServiceRequest.issues && consumerServiceRequest.issues.length > 0 &&
				<div className="row">
					<div className="col-sm-12">
					<Table className={classObject}>
						<Table.Header>
							<Table.Row>
								<Table.Heading >{localString('Issues')}</Table.Heading>
							</Table.Row>
						</Table.Header>
						{
							consumerServiceRequest && consumerServiceRequest.issues && consumerServiceRequest.issues.map(item=>{
								return(
									item.IsReported && 
									<Table.Data className="issuename">{localString(item.IssueText)}
									{
										item.IssueRemark && 
										<span> - {localString(item.IssueRemark)}</span>
									}
									</Table.Data>
								)
							})
						}
					</Table>
					</div>
				</div>
			}
			</div>
			</PageContentWrapper>
		</div>
	)
}

export default IssueValidationNonWorkshop
