import React,{useState} from 'react';
import {Input,Button} from 'sfy-react';

const ReplacementDetailsInput=(props)=>{
    const {localString,showModal,closeModal,deviceConfigInput,currencyCode, allocateReplacement,deviceConfigData,deviceConfigInputData,setDeviceConfigInputData,deviceConfigVal,ReplacementPrice} = props;
	let [disableSubmit,setDisableSubmit] =  useState(true)
	return ( 
		<>
			<div className='request-filter clearfix'>
				{
					deviceConfigInput && deviceConfigInput.map(val=>(
						<div className='col-sm-12 paddingT'>
						{
							val.DisplayText && <div className='col-sm-6'>
							<label>{val.DisplayText}</label> { val.Optional &&<span >(optional)</span>}
							</div>}
							<div class = "col-sm-6 input-display">
								{val.ShowCurrency && <Input 
									type='text'
									className={{inputContainer:'currency-input'}} 
									value={currencyCode ? currencyCode :''}
									isDisabled
								/>}
								{
									<Input 
										type ={val.Type}
										className="input-padding" 
										value={val.Value ? val.Value : deviceConfigVal[val.ConstantName]}
										isDisabled = {!val.Editable}
										maxLength = {val.Validation && val.Validation.LengthValidation && val.Validation.InputMaxLength}
										id={val.ConstantName}
										handleOnChange ={(e)=>{
											deviceConfigData(e,val);
										}}
									/>
									
								}
								
							</div>
						</div>
					))
				}
			</div>
			<div class="form-group-filter-request text-right clearfix">
				<Button className='btn button-servify-solid submit-btn save-submit' isDisabled={ReplacementPrice=='' ? disableSubmit: !disableSubmit} handleOnClick={()=>allocateReplacement()}>{localString("Submit")}</Button>
			</div>
        </>
	)
}
export default ReplacementDetailsInput;
