import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import LoanerDetails from './LoanerDetails';
import {find, remove} from 'lodash';
import {displayError, isTheSameObj} from '../../../../../Utility/Utils';
import LoanerEstimation from './LoanerEstimation';
import './LoanerDetails.scss'
import LoanerAssignment from './LoanerAssignment';

const LoanerWrapper = (props) => {
    const {consumerServiceRequest, accessFn, localString, apiCall, loanerDeviceList, getRequestById,loanerChargesResult, setLoanerChargesResult,
    loanerCharges, setLoanerCharges,currentLoanerStatus, setCurrentLoanerStatus,DOAEntries, setDOAEntries,fetchDetailsCalled, setFetchDetailsCalled,
    loanerDetails, setLoanerDetails,fetchLoanerDetailsByCsrId} = props;
    const [loader, setLoader] = useState(false);
    // const [loanerDetails, setLoanerDetails] = useState([]);
    // const [currentLoanerStatus, setCurrentLoanerStatus] = useState();
    // const [fetchDetailsCalled, setFetchDetailsCalled] = useState(false);
    const [enableEditLoanerDetails, setEnableEditLoanerDetails] = useState(false);
    const [editLoanerDetails, setEditLoanerDetails] = useState(false);
    // const [loanerChargesResult, setLoanerChargesResult] = useState([]);
    // const [loanerCharges, setLoanerCharges] = useState();
    const [loanerDeviceCount, setLoanerDeviceCount] = useState()
    // const [DOAEntries, setDOAEntries] = useState([])
    const [csrLoanerDetails, setCsrLoanerDetails] = useState({})
    const [activeLoanerStatus, setActiveLoanerStatus] = useState()
    const [allSymptomsList, setAllSymptomsList] = useState([])
    const [allIssueList, setAllIssueList] = useState([])
    const [compModifierList, setCompModifierList] = useState([])
    const [loanerDeviceDropdownList, setLoanerDeviceDropdownList] = useState([])
    const [allStatusList, setAllStatusList] = useState([])
    const [loanerSelected, setLoanerSelected] = useState(false)
    const [enableLonaerSave, setEnableLonaerSave] = useState(false)
    const [loanerDispositionModal, setLoanerDispositionModal] = useState(false)
    const [enableSaveDisposition, setEnableSaveDisposition] = useState(false)
    const [selectedLoanerDevice, setSelectedLoanerDevice] = useState()
    const [loanerCancelStatusList, setLoanerCancelStatusList] = useState([])
    const [loanerCancelModal, setLoanerCancelModal] = useState(false)
    const [cancelModalStatus, setCancelModalStatus] = useState("")
    const [selectedReason, setSelectedReason] = useState(false)
    const [inputData, setInputData] = useState({
        loanerDeviceList:"",
        loanerDeviceSerialNumber:"",
        reportedSymptomName:"",
        reportedSymptomCode:"",
        reportedIssueName:"",
        comptiaDescription:"",
        modifierCode:"",
        DispositionDescription:"",
        LoanerReturnDisposition:"",
        VmiStatus:"",
        reportedIssueCode:"",
        code:"",
    })

    const chargeType = [{
        Name: '',
        Placeholder: localString("Other Charge"),
        Display: 'Other Charge',
        Type: 'Other'
    }, {
        Placeholder: localString("Discount"),
        Display: 'Discount',
        Type: 'Discount'
    }];

    const doaDisposition = [{
        "LoanerDispositionId": 1,
        "VmiStatus": "VMI_RED",
        "LoanerReturnDisposition": "DOA",
        "DispositionDescription": "Loaner not Working",
        "value": "Loaner not Working"
    }]

    const loanerRequestedStatus = [{
        val: true,
        name: 'Yes'
    },{
        val: false,
        name: 'No'
    }];

    useEffect(() => {
        if(loanerDeviceList){
            setLoanerDeviceCount(loanerDeviceList.totalQuantity)
        }
    }, [loanerDeviceList])

    useEffect(() => {
        if(consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID && !fetchDetailsCalled){
            fetchLoanerDetailsByCsrId();
        }

        if(consumerServiceRequest){
			let isDeviceAccepted = find(consumerServiceRequest.logs, {Status: 'Device accepted'});
			if(isDeviceAccepted){
                setEnableEditLoanerDetails(true);
			}else{
                setEnableEditLoanerDetails(false);
			}
		}

        let CSRLoanerDetails = consumerServiceRequest && consumerServiceRequest.loanerDetails
        setCsrLoanerDetails(CSRLoanerDetails)

    }, [consumerServiceRequest])

    useEffect(() => {
        if(inputData.reportedSymptomCode && inputData.reportedSymptomCode.length > 0){
            fetchReportedIssues()
        }
        if(inputData.loanerDeviceList && inputData.loanerDeviceList.length > 0 ){
            setLoanerSelected(true)
        }else{
            setLoanerSelected(false)
        }
        if(selectedLoanerDevice && selectedLoanerDevice.inventory.Quantity > 0 ){
            setLoanerSelected(true)
        }
        checkSave();
    }, [inputData, selectedLoanerDevice])

    useEffect(() => {
        if(cancelModalStatus && cancelModalStatus.length > 0){
            setSelectedReason(true)
        }
    }, [cancelModalStatus])

    // const fetchLoanerDetailsByCsrId = () => {
    //     let data = {
    //         ConsumerServiceRequestID: consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
    //         PartnerServiceLocationID: consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID,
    //         ProductID : consumerServiceRequest && consumerServiceRequest.consumerProduct.ProductID
    //     };
    //     setLoader(true);
	// 	apiCall({
	// 		url: 'fetchLoanerDetailsByCsrId',
	// 		data: data,
	// 		callBack:(result)=>{
	// 			setLoader(false);
	// 			if(result.success){
    //                 let loanerDetails = result.data;
    //                 setLoanerDetails(loanerDetails);
    //                 let currentLoanerStatus = loanerDetails.CurrentStatus;
    //                 setCurrentLoanerStatus(currentLoanerStatus)
    //                 let doaEntries = [];
    //                 loanerDetails && loanerDetails.loanerEntryDetails && loanerDetails.loanerEntryDetails.map((value)=> {
    //                     if(value.currentStatus =="LDOA"){
    //                         let doaPart = {};
    //                         doaPart.LoanerDeviceName = value.LoanerDeviceName;
    //                         doaPart.LoanerDeviceSerialNumber = value.LoanerDeviceSerialNumber;
    //                         doaEntries.push(doaPart);
    //                     }
    //                 });
    //                 setDOAEntries(doaEntries);
    //                 if(loanerDetails.chargetoCustomer){
    //                     getLoanerCharges(loanerDetails)
    //                 }
    //                 setFetchDetailsCalled(true);
	// 			}else{
    //                 setFetchDetailsCalled(true);
	// 				Swal.fire({
	// 					icon: 'error',
	// 					text: localString(displayError(result)),
	// 				});
	// 			}
	// 		}
	// 	});
    // }

    // const getLoanerCharges = (loanerData) => {
    //     let data ={
    //         "ConsumerServiceRequestID" : consumerServiceRequest.ConsumerServiceRequestID,
    //         "LoanerRequestID": loanerData.loanerRequestID
    //     }
    //     setLoader(true);
	// 	apiCall({
	// 		url: 'getLoanerCharges',
	// 		data: data,
	// 		callBack:(result)=>{
	// 			setLoader(false);
	// 			if(result.success){
    //                 setLoanerChargesResult(result.data);
    //                 setLoanerCharges(result.data.charges);
	// 			}else{
	// 				Swal.fire({
	// 					icon: 'error',
	// 					text: localString(displayError(result)),
	// 				});
	// 			}
	// 		}
	// 	});
    // }

    const savedLoanerdetails = (e) => {
        e.preventDefault();
        if(activeLoanerStatus == undefined){
            Swal.fire({
                title:"",
                icon: 'error',
                text: localString('Please select Loaner Device Request Status'),
                confirmButtonText:localString('OK')
            });
            return false;
        }
        if(activeLoanerStatus == false && csrLoanerDetails && csrLoanerDetails.loanerNotRequested){
            Swal.fire({
                title:"",
                icon: 'info',
                text: localString('Loaner device not required by customer'),
                confirmButtonText:localString('OK')
            });
            return false;
        } 
        let data ={
            "ConsumerServiceRequestID" : consumerServiceRequest.ConsumerServiceRequestID,
            "isLoaner" : activeLoanerStatus
        }
        setLoader(true);
		apiCall({
			url: 'setLoaner',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    fetchLoanerDetailsByCsrId();
                    if(data.isLoaner == false) {
                        getRequestById();
                    }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const checkGSXRepair = () => {
        if(consumerServiceRequest && 
            ((consumerServiceRequest.repairConfirmationNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId && consumerServiceRequest.gsxRepairFlags.repairTypeId == 2) || !consumerServiceRequest.repairConfirmationNumber)) {
            return true;
        } else {
            return false;
        }
    };

    const triggerStatusParentClick = (val) => {
        let value = JSON.parse(val)
        setActiveLoanerStatus(value)
        // $("#loaner_status_"+e.item.name).prop("checked", true);
    }

    const editLoanerDeviceClick = () => {
        setEditLoanerDetails(true);

        if(accessFn('REPAIR_DEVICE')){
            let displayList = loanerDetails && loanerDetails.deviceList && loanerDetails.deviceList.productProductLoanerMappings  
                && loanerDetails.deviceList.productProductLoanerMappings.map((list)=>{
                    return {...list,value:(list.part.Name +" "+ "("+ "Qty" +" -" + list.inventory.Quantity + ")")}
            })
            setLoanerDeviceDropdownList(displayList);  
        }
        
        if(accessFn('REPAIR_DEVICE') && loanerDetails.loanerDeviceName){
            setInputData({...inputData,['loanerDeviceList']:loanerDetails.loanerDeviceName})
        }
        if(accessFn('REPAIR_DEVICE') && loanerDetails.loanerDeviceSerialNumber){
            setInputData({...inputData,['loanerDeviceSerialNumber']:loanerDetails.loanerDeviceSerialNumber})
        }
    }

    const cancelDetailsClick = function(){
        setEditLoanerDetails(false);
    }

    const setLoanerDeviceList = (data) =>{
        setInputData({
            ...inputData,
            ['loanerDeviceList']:data.value
        })
        setSelectedLoanerDevice(data)
    }

    const checkImeiValueValid = (val) => {
        val = val.replace(/[`~!@#$%^&*()_|+\-=?.;:'",<>\{\}\[\]\\\/]/gi, '');
        val = val.replace(/ /gi, '');
        setInputData({...inputData,['loanerDeviceSerialNumber']: val})
        if(val.length >= 10 &&  val.length <= 12 && loanerSelected ){ 
            setEnableLonaerSave(true)
        }
        else{
            setEnableLonaerSave(false)
        }   
    };

    const showDispositionModal = () => {
        setLoanerDispositionModal(true)
        if(csrLoanerDetails && csrLoanerDetails.enableLoanerDoa){
            setAllStatusList(doaDisposition)
        }else{
            getLoanerDesposition();
        }
        getSymptomCodes();
        getCompModList();
    }

    const closeDispositionModal = ()=>{
        setLoanerDispositionModal(false)
        setEditLoanerDetails(false);
        clearDispostionData()
    }

    const clearDispostionData = ()=>{
        setAllSymptomsList([])
        setAllIssueList([])
        setCompModifierList([])
        setLoanerDeviceDropdownList([])
        setInputData({
            loanerDeviceList:"",
            loanerDeviceSerialNumber:"",
            reportedSymptomName:"",
            reportedSymptomCode:"",
            reportedIssueName:"",
            comptiaDescription:"",
            modifierCode:"",
            DispositionDescription:"",
            LoanerReturnDisposition:"",
            VmiStatus:"",
            reportedIssueCode:"",
            code:"",
        })
        setEnableSaveDisposition(false)
    }

    const checkSave = () => {
		if((inputData.DispositionDescription && inputData.DispositionDescription.length > 0)
			&& (inputData.reportedSymptomCode.length && inputData.reportedSymptomCode.length > 0)
			&& (inputData.reportedIssueCode && inputData.reportedIssueCode.length > 0)
            && (inputData.comptiaDescription && inputData.comptiaDescription.length > 0)){
                setEnableSaveDisposition(true)
        } else {
                setEnableSaveDisposition(false)
        }
	}

    const updateLoanerDetails = (e) => {
        e.preventDefault();
        let data;
        if(selectedLoanerDevice){
            data ={
                "LoanerRequestID": loanerDetails.loanerRequestID,
                "PartID": selectedLoanerDevice.part.PartID,
                "LoanerDeviceName": selectedLoanerDevice.part.Name,
                "PartCode": selectedLoanerDevice.part.PartCode,
                "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
                "PartnerServiceLocationID": consumerServiceRequest.PartnerServiceLocationID,
                "LoanerDeviceSerialNumber": inputData.loanerDeviceSerialNumber,
                "LoanerDeviceImei" : inputData.loanerDeviceSerialNumber
            }
        }else{
            data ={
                "LoanerRequestID":loanerDetails.loanerRequestID,
                "PartID":loanerDetails.PartID,
                "LoanerDeviceName":loanerDetails.loanerDeviceName,
                "PartCode":loanerDetails.PartCode,
                "ConsumerServiceRequestID":consumerServiceRequest.ConsumerServiceRequestID,
                "PartnerServiceLocationID":consumerServiceRequest.PartnerServiceLocationID,
                "LoanerDeviceSerialNumber": inputData.loanerDeviceSerialNumber,
                "LoanerDeviceImei" : inputData.loanerDeviceSerialNumber
            }
        }
        setLoader(true);
		apiCall({
			url: 'updateLoanerRequest',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    setEditLoanerDetails(false);
                    fetchLoanerDetailsByCsrId();
                    getRequestById();
                    // if(self.parent.tags['pick_up_carry_in_gsx']){
                    //     self.parent.tags['pick_up_carry_in_gsx'].tags['loaner_flow'].fetchLoanerDetailsByCsrId();
                    //     self.parent.tags['pick_up_carry_in_gsx'].tags['loaner_flow'].update();
                    // }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const getSymptomCodes = () => {
        let data = {
            "ConsumerProductID": consumerServiceRequest && consumerServiceRequest.consumerProduct.ConsumerProductID
        };
        setLoader(true);
		apiCall({
			url: 'fetchReportedSymptoms',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    let allSymptoms = result.data && result.data.map(v=>({...v,value:v.reportedSymptomCode +" "+ v.reportedSymptomDesc}))
                    setAllSymptomsList(allSymptoms)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const fetchReportedIssues = () => {
        if(inputData.reportedSymptomCode){
            let data = {
                "ConsumerProductID": consumerServiceRequest && consumerServiceRequest.consumerProduct.ConsumerProductID,
                "reportedSymptomCode": inputData.reportedSymptomCode,
            };
            setLoader(true);
            apiCall({
                url: 'fetchReportedIssues',
                data: data,
                callBack:(result)=>{
                    setLoader(false);
                    if(result.success){
                        let allIssues = result.data && result.data.map(v=>({...v,value:v.reportedIssueCode + ' ' + v.reportedIssueDesc}))
                        setAllIssueList(allIssues)
                    }else{
                        Swal.fire({
                            icon: 'error',
                            text: localString(displayError(result)),
                        });
                    }
                }
            });
        }
    }

    const getCompModList = () => {
        setLoader(true);
        apiCall({
            url: 'fetchCompTiaModifiers',
            data: {},
            callBack:(result)=>{
                setLoader(false);
                if(result.success){
                    let compModList = result.data && result.data.map(v=>({...v,value:v.comptiaDescription}))
                    setCompModifierList(compModList)
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: localString(displayError(result)),
                    });
                }
            }
        });
    }

    const getLoanerDesposition = () => {
        let loanerDispositionStatusList = [];
        setLoader(true);
        apiCall({
            url: 'getDispositionStatusList',
            data: {},
            callBack:(result)=>{
                setLoader(false);
                if(result.success){
                    if(currentLoanerStatus == "LOER"){
                        let loanerLostDispositionStatus = find(result.data.LoanerDispositionDetails, function (o) { return o.LoanerReturnDisposition == 'LOST'});
                        loanerDispositionStatusList.push(loanerLostDispositionStatus);
                    }else{
                        loanerDispositionStatusList = remove(result.data.LoanerDispositionDetails, function (o) { return o.LoanerReturnDisposition !== 'LOST'});
                    }   
                    let statusDropdownList = loanerDispositionStatusList.map(v=>({...v,value:v.DispositionDescription}));
                    setAllStatusList(statusDropdownList)
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: localString(displayError(result)),
                    });
                }
            }
        });
    }

    const saveLoanerDisposition = (e) => {
        e.preventDefault();
        if(csrLoanerDetails && csrLoanerDetails.enableLoanerDoa){
            doaFlow();
        }else{
            loanerDispositionReturn();
        }
    }

    const doaFlow = () => {
        let data = {
            ConsumerServiceRequestID: consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            PartnerServiceLocationID: consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID,
            LoanerRequestID: loanerDetails.loanerRequestID,
            PartCode: loanerDetails.activeLoaner && loanerDetails.activeLoaner.PartCode,
            componentCode: inputData.componentCode,
            reproducibility: inputData.modifierCode,
            issueCode: inputData.code,
            LoanerDeviceSerialNumber: loanerDetails.loanerDeviceSerialNumber
        }
        setLoader(true);
		apiCall({
			url: 'loanerDoaFlow',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    closeDispositionModal();
                    fetchLoanerDetailsByCsrId();
                    getRequestById();
                    // if(self.parent.tags['pick_up_carry_in_gsx']){
                    //     self.parent.tags['pick_up_carry_in_gsx'].tags['loaner_flow'].fetchLoanerDetailsByCsrId();
                    //     self.parent.tags['pick_up_carry_in_gsx'].tags['loaner_flow'].update();
                    // }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const loanerDispositionReturn = ()=>{
        let data = {
            ConsumerServiceRequestID: consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            dispositionCode: inputData.LoanerReturnDisposition,
            LoanerRequestID: loanerDetails.loanerRequestID,
            PartNumber: loanerDetails.activeLoaner.PartID,
            PartUsed: loanerDetails.activeLoaner.PartCode,
            componentCode: inputData.componentCode,
            reproducibility: inputData.modifierCode,
            issueCode: inputData.code,
            vmiStatus : inputData.VmiStatus,
            ProductID : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductID,
            PartnerServiceLocationID: consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID,
        }
        setLoader(true);
		apiCall({
			url: 'loanerDispositionReturn',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    closeDispositionModal();
                    fetchLoanerDetailsByCsrId();
                    getRequestById();
                    // if(self.parent.tags['pick_up_carry_in_gsx']){
                    //     self.parent.tags['pick_up_carry_in_gsx'].tags['loaner_flow'].fetchLoanerDetailsByCsrId();
                    //     self.parent.tags['pick_up_carry_in_gsx'].tags['loaner_flow'].update();
                    // }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const getLoanerCancelStatusList = () =>{
        setLoanerCancelModal(true);
        setLoader(true);
		apiCall({
			url: 'getLoanerCancelStatusList',
			data: {},
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    let statusList = result.data && result.data.loanerStatus.map(v=>({...v,value:v.CancelReason}));
                    setLoanerCancelStatusList(statusList);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const closeCancelModal = () => {
        setLoanerCancelModal(false);
        setEditLoanerDetails(false);
        setCancelModalStatus("");
    }

    const cancelLoaner = (e) => {
        e.preventDefault();
        if(['LOIS'].indexOf(currentLoanerStatus) > -1) {
            addLoanerToInventory();
        } else {
            saveLoanerCancelRequest();
        }
    };

    const addLoanerToInventory = () =>{
        let data = {
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "LoanerRequestID": loanerDetails.loanerRequestID,
            "PartStateID":1
        };
        setLoader(true);
		apiCall({
			url: 'addLoanerToInventory',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    saveLoanerCancelRequest();
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const saveLoanerCancelRequest = () =>{
        let data = {
            "ConsumerServiceRequestID" : consumerServiceRequest.ConsumerServiceRequestID,
            "LoanerRequestID": loanerDetails.loanerRequestID,
            "Remarks" : cancelModalStatus
        };
        setLoader(true);
		apiCall({
			url: 'cancelLoanerDevice',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    closeCancelModal();
                    fetchLoanerDetailsByCsrId();
                    getRequestById();
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const dispositionModalDropdown = [{
        labelText:localString("Select Reason"),
        dataKey:"DispositionDescription",
        name:"cancelReasonList",
        id:"loanerDispositionList",
        dropdownList:allStatusList,
        dropdownClass:"form-control filter-request-height",
        placeholder:localString("Select Reason"),
        oninputFunc: (val) => {
            setInputData({...inputData,
                ['DispositionDescription']:val.DispositionDescription,
                ['LoanerReturnDisposition']:val.LoanerReturnDisposition,
                ['VmiStatus']:val.VmiStatus
            });
        }
    },
    {
        labelText:localString('Component Code'),
        dataKey:"reportedSymptomName",
        name:"partsymptomcode",
        id:"partsymptomcode",
        dropdownList: allSymptomsList,
        dropdownClass:"form-control filter-request-height",
        placeholder:localString("Component Code"),
        oninputFunc: (val) => {
            setInputData({...inputData,
                ['reportedSymptomCode']:val.reportedSymptomCode,
                ['componentCode']:val.componentCode,
                ['reportedSymptomName']:val.value
            });
        },
    },
    {
        labelText:localString('Issue Code'),
        dataKey:"reportedIssueName",
        name:"partissuecode",
        id:"partissuecode",
        dropdownList: allIssueList,
        dropdownClass:"form-control filter-request-height",
        placeholder:localString("Issue Code"),
        oninputFunc: (val) => {
            setInputData({...inputData,
                ['reportedIssueCode']:val.reportedIssueCode,
                ['code']:val.code,
                ['reportedIssueName']:val.value
            });
        }
    },
    {
        labelText:localString('Reproducibility'),
        dataKey:"comptiaDescription",
        name:"comp_mod",
        id:"comp_mod",
        dropdownList: compModifierList,
        dropdownClass:"form-control filter-request-height",
        placeholder:localString("Reproducibility"),
        oninputFunc: (val) => {
            setInputData({...inputData,
                ['comptiaDescription']:val.comptiaDescription,
                ['modifierCode']:val.modifierCode,
            });
        }
    }]
    
    return (
        <div className="inner-padding">
            <>
            { loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
            <div className="row">
                <PageContentWrapper 
                    wrapperClassName={consumerServiceRequest && consumerServiceRequest.loanerDetails && accessFn('REQUEST_RAISE') && accessFn('GSX_FLOW') &&  ['LORI'].indexOf(consumerServiceRequest.loanerDetails.currentStatus) > -1 ? 'col-md-8 loaner-details' : 'col-md-12 '}
                    bodyClassName="panel-body-no-padding" 
                    title={localString("Loaner Details")}
                    // showDownloadButtonIcon={!editLoanerDetails}
                    showDownloadButtonIcon={!editLoanerDetails || loanerDetails && loanerDetails.loanerRequested && ((accessFn('ACCEPT_RETURN_DEVICE') && ['LOSE', 'LDOA', 'LORQ', 'LOIS'].indexOf(currentLoanerStatus) > -1)|| (accessFn('REPAIR_DEVICE') && enableEditLoanerDetails && ['LORQ','LOSE','LOIS','LDOA','LOER','LODC'].indexOf(currentLoanerStatus) > -1))}
                    customSymbol="glyphicon glyphicon-edit"
                    editDeviceClick={editLoanerDeviceClick} >
                    <LoanerDetails
                        {...props}
                        loanerDetails={loanerDetails}
                        doaEntries={DOAEntries} loanerSelected={loanerSelected}
                        checkGSXRepair={checkGSXRepair}
                        editLoanerDetails={editLoanerDetails}
                        currentLoanerStatus={currentLoanerStatus}
                        enableEditLoanerDetails={enableEditLoanerDetails}
                        csrLoanerDetails={csrLoanerDetails}
                        loanerRequestedStatus={loanerRequestedStatus}
                        triggerStatusParentClick={triggerStatusParentClick}
                        activeLoanerStatus={activeLoanerStatus}
                        savedLoanerdetails={savedLoanerdetails}
                        loanerDeviceCount={loanerDeviceCount}
                        cancelDetailsClick={cancelDetailsClick}
                        showDispositionModal={showDispositionModal}
                        updateLoanerDetails={updateLoanerDetails}
                        inputData={inputData} setInputData={setInputData}
                        setLoanerDeviceList={setLoanerDeviceList} getLoanerCancelStatusList={getLoanerCancelStatusList}
                        loanerDeviceDropdownList={loanerDeviceDropdownList}
                        checkImeiValueValid={checkImeiValueValid} enableLonaerSave={enableLonaerSave}
                        loanerDispositionModal={loanerDispositionModal} dispositionModalDropdown={dispositionModalDropdown}
                        enableSaveDisposition={enableSaveDisposition} closeDispositionModal={closeDispositionModal}
                        saveLoanerDisposition={saveLoanerDisposition}
                        loanerCancelModal={loanerCancelModal} loanerCancelStatusList={loanerCancelStatusList}
                        cancelModalStatus={cancelModalStatus} setCancelModalStatus={setCancelModalStatus}
                        closeCancelModal={closeCancelModal} cancelLoaner={cancelLoaner}
                        selectedReason={selectedReason} 
                    />
                </PageContentWrapper>
                {
                    (consumerServiceRequest && consumerServiceRequest.loanerDetails && accessFn('REQUEST_RAISE') && accessFn('GSX_FLOW') &&  ['LORI'].indexOf(consumerServiceRequest.loanerDetails.currentStatus) > -1 )&&
                    <PageContentWrapper 
                        wrapperClassName='col-md-4 loaner-assignment'
                        bodyClassName="" 
                        title={localString("Loaner Assignment")}
                    >
                        <LoanerAssignment 
                            {...props}
                            fetchLoanerDetailsByCsrId={fetchLoanerDetailsByCsrId}
                        />
                    </PageContentWrapper>
                }
                {
                    loanerDetails && loanerDetails.loanerRequested && (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REPAIR_DEVICE')) &&  (['LOST','LOPR','LOAI'].indexOf(currentLoanerStatus) > -1) && loanerCharges && loanerCharges.length > 0 &&
                    <LoanerEstimation 
                        {...props}
                        setLoader={setLoader}
                        loanerDetails={loanerDetails}
                        currentLoanerStatus={currentLoanerStatus}
                        loanerRequestedStatus={loanerRequestedStatus}
                        fetchLoanerDetailsByCsrId={fetchLoanerDetailsByCsrId}
                        loanerChargesResult={loanerChargesResult} loanerCharges={loanerCharges}
                    />
                }
            </div>
            </>
        </div>
    )
}

export default LoanerWrapper
