import React, { useCallback } from "react";
import { Dropdown, Tooltip } from "sfy-react";
import AllTransactionsTable from "./AllTransactionsTable";
import ShadowWrapper from "./ShadowWrapper";
import DateRangePickerWrapper from "../../components/DateRangePickerWrapper/DateRangePickerWrapper";

import "./CaseManager.scss";

const AllTransactions = (props) => {
	const {
		getCases,
		dateRange,
		setDateRange,
		filters,
		setFilters,
		assigneeUserList,
		statusList,
		planList,
		caseStatusOptionsList,
		resetPagination,
		resetAllFilters,
		localString,
	} = props;

	const handleDropDownFilterChange = (obj) => {
		resetPagination();
		setFilters((prevData) => ({
			...prevData,
			...obj,
		}));
	};

	const ValueComponent = useCallback((label, props) => {
		return props && props.value ? (
			<Tooltip content={props.value || ""} position="bottom" className={{
				tooltipContainer: "pos-static"
			}}>
				{props.value.length > 8 ? props.value.slice(0, 8) + "..." : props.value}
			</Tooltip>
		) : label ? (
			localString(label)
		) : (
			""
		);
	}, []);

	return (
		<div className="all-transactions">
			<ShadowWrapper classname="rounded">
				<div className="row all-transactions-header">
					<div className="col-sm-3">
						<div className="row">
							<div className="col-sm-6 all-transactions-heading">
								{localString("All Transactions")}
							</div>
							<div
								className="col-sm-6"
								style={{
									fontSize: "18px",
									fontWeight: "700",
									marginTop: "4px",
									textAlign: "center",
								}}
								onClick={() => resetAllFilters()}
							>
								<span className="reassign-btn">
									{localString("Reset Filters")}
								</span>
							</div>
						</div>
					</div>

					<div className="col-sm-9">
						<div className="row w-full">
							<div className="col-sm-4">
								<DateRangePickerWrapper
									date={new Date()}
									dateRange={dateRange}
									setDateRange={setDateRange}
									handleDateRangeApply={getCases}
									dateClass="col-sm-6"
									customFormat="dd-mm-yyyy"
									customPlaceholder="DD-MM-YYYY"
									dateWidthClass={"date-range-casemanager"}
								/>
							</div>

							<div className="col-sm-2">
								<Dropdown
									id="case-status"
									classObject={{
										container:
											"customised-border customised-border-radius",
										optionsSearch:
											"customised-border-radius",
										valueContainer:
											"customised-border-radius",
									}}
									value={filters.caseStatus}
									options={caseStatusOptionsList}
									ValueComponent={(props) =>
										ValueComponent("Case Status", props)
									}
									handleOnChange={(val) => {
										handleDropDownFilterChange({
											caseStatusID: val.caseStatusID,
											caseStatus: val.value,
										});
									}}
									filterBy="value"
									showDownArrow={true}
									showClearButton={true}
									handleOnSearch={(e) =>
										caseStatusOptionsList &&
										caseStatusOptionsList.filter((obj) =>
											obj.value
												.toLowerCase()
												.includes(
													e.target.value.toLowerCase()
												)
										)
									}
									noOptionsText={localString(
										"Cannot find the searched option"
									)}
									searchText={localString("Case Status")}
									placeholder={localString("Case Status")}
								/>
							</div>

							<div className="col-sm-2">
								<Dropdown
									id="assignee-user-filter"
									classObject={{
										container:
											"customised-border customised-border-radius",
										optionsSearch:
											"customised-border-radius",
										valueContainer:
											"customised-border-radius",
									}}
									value={filters.assigneeName}
									ValueComponent={(props) =>
										ValueComponent("Assignee", props)
									}
									options={assigneeUserList}
									handleOnChange={(val) => {
										handleDropDownFilterChange({
											assigneeUserID: val.assigneeUserID,
											assigneeName: val.name,
										});
									}}
									filterBy="value"
									showDownArrow={true}
									showClearButton={true}
									handleOnSearch={(e) =>
										assigneeUserList &&
										assigneeUserList.filter((obj) =>
											obj.value
												.toLowerCase()
												.includes(
													e.target.value.toLowerCase()
												)
										)
									}
									noOptionsText={localString(
										"Cannot find the searched option"
									)}
									searchText={localString("Assignee")}
									placeholder={localString("Assignee")}
								/>
							</div>

							<div className="col-sm-2">
								<Dropdown
									id="plan-filter"
									classObject={{
										container:
											"customised-border customised-border-radius",
										optionsSearch:
											"customised-border-radius",
										valueContainer:
											"customised-border-radius",
									}}
									value={filters.planName}
									ValueComponent={(props) =>
										ValueComponent("Plan", props)
									}
									options={planList}
									handleOnChange={(val) =>
										handleDropDownFilterChange({
											planName: val.value,
											planID: val.PlanID,
										})
									}
									filterBy="value"
									showDownArrow={true}
									showClearButton={true}
									handleOnSearch={(e) =>
										planList &&
										planList.filter((obj) => {
											return obj.value
												.toLowerCase()
												.includes(
													e.target.value.toLowerCase()
												);
										})
									}
									noOptionsText={localString(
										"Cannot find the searched option"
									)}
									searchText={localString("Plan")}
									placeholder={localString("Plan")}
								/>
							</div>

							<div className="col-sm-2">
								<Dropdown
									id="claim-status-filter"
									classObject={{
										container:
											"customised-border customised-border-radius",
										optionsSearch:
											"customised-border-radius",
										valueContainer:
											"customised-border-radius",
									}}
									value={filters.claimStatus}
									ValueComponent={(props) =>
										ValueComponent("Claim Status", props)
									}
									options={statusList}
									handleOnChange={(val) =>
										handleDropDownFilterChange({
											claimStatus: val.value,
										})
									}
									filterBy="value"
									showDownArrow={true}
									showClearButton={true}
									handleOnSearch={(e) =>
										statusList &&
										statusList.filter((obj) =>
											obj.value
												.toLowerCase()
												.includes(
													e.target.value.toLowerCase()
												)
										)
									}
									noOptionsText={localString(
										"Cannot find the searched option"
									)}
									searchText={localString("Claim Status")}
									placeholder={localString("Claim Status")}
								/>
							</div>

							{/*<div className="col-sm-2">
								<Button className="filter-buttons">
									<span className="glyphicon glyphicon-filter"></span>
									More Filters
								</Button>
							</div>*/}
						</div>
					</div>
				</div>

				<AllTransactionsTable {...props} />
			</ShadowWrapper>
		</div>
	);
};

export default AllTransactions;
