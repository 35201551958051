import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Input, Loader } from 'sfy-react';
import Swal from "sweetalert2";
import { sortBy } from 'lodash';

import IntlTelInputWrapper from '../../../components/IntlTelInputWrapper/IntlTelInputWrapper';
import { checkEmail, checkMobileNumber, displayError, nationalIdValidation } from '../../../Utility/Utils';

export default function UserDetails(props) {
	const { 
		localString, publicUrl, mobile, setMobile, email, setEmail, showConsumerDetails, setShowConsumerDetails,
		isMobileSelected, setIsMobileSelected, userDetails, setUserDetails, setIntlTelCountryConfig, checkConsumer,
		getComplianceWarning, checkAddorUpdateConsumer, getCountries, country, setCountry, error, mobileMaxLength,
		isMinimalConsumerMode, secondaryStepCountry, setSecondaryStepCountry, hideSection, setScreenToViewDiv,
        showEmailOption, disableStep1, radioValue, setRadioValue
	} = props;	
	const qFilter = JSON.parse(props.qFilter);
	const [countryCode, setCountryCode] = useState(qFilter.countrycode || []);
	// const [showEmailOption, setShowEmailOption] = useState(countryCode.includes('US'));
    const [errMsg, setErrMsg] = useState({
        name: null,
        mobile: null,
        email: null,
        personalId: null
    })
    const disablePrimaryBtn = (radioValue !== 'email' && (!mobile.length || !mobileMaxLength.includes(mobile.length) || !checkMobileNumber(mobile))) || (radioValue === 'email' && (!email.length || !checkEmail(email)));
    // const disableSecondaryBtn = Object.values(errMsg).some(e => e != null) || !userDetails.name || (isMobileSelected && !userDetails.email) || (!isMobileSelected && !userDetails.mobile) || 
    //     (qFilter && qFilter.flowType && qFilter.flowType.requestRaisingFlow && qFilter.flowType.requestRaisingFlow.length && qFilter.flowType.requestRaisingFlow.includes('REQ_RAISE_TURKEY') && (!userDetails.personalId || (userDetails.personalId && !nationalIdValidation(userDetails.personalId))));
    const disableSecondaryBtn = Object.values(errMsg).some(e => e != null) || !userDetails.name || 
        (qFilter && qFilter.flowType && qFilter.flowType.requestRaisingFlow && qFilter.flowType.requestRaisingFlow.length && qFilter.flowType.requestRaisingFlow.includes('REQ_RAISE_TURKEY') && (!userDetails.personalId || (userDetails.personalId && !nationalIdValidation(userDetails.personalId))));

    const handlePrimarySubmit = () => {
        hideSection(['ConsumerDetails', 'ConsumerProducts', 'AddProduct', 'AddEditSerialNo', 'SelectedProduct', 'InspectionGuide', 'DeviceAttributes', 'ModeType', 'Plans', 'CoverageType', 'Coverage', 'ClaimForm', 'DOP', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation','CustomerVerification'])
        setSecondaryStepCountry(country);
        if(isMobileSelected) {
            checkConsumer();
        }else {
            getComplianceWarning('EMAIL_ISVALID_OR_ISBOUNCE');
        }
    }

    const handleSecondarySubmit = () => {
        hideSection(['ConsumerProducts', 'AddProduct', 'AddEditSerialNo', 'SelectedProduct', 'InspectionGuide', 'DeviceAttributes', 'ModeType', 'Plans', 'CoverageType', 'Coverage', 'ClaimForm', 'DOP', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions',  'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation','CustomerVerification'])
        if(isMobileSelected) {
            getComplianceWarning('EMAIL_ISVALID_OR_ISBOUNCE');
        }else {
            checkAddorUpdateConsumer()
        }
    }

    useEffect(() => {
        if(showConsumerDetails) {
            setScreenToViewDiv('ConsumerDetails');
        }
    }, [showConsumerDetails])

	const handleFlagSelection = (countryData) => {
		setIntlTelCountryConfig(countryData);
		if(country.toUpperCase() != countryData.iso2.toUpperCase()) {
			setCountry(countryData.iso2);
			getCountries(countryData.iso2);
		} 
	}

    const validateConsumerDetails = (type, value) => {
        if(type === 'name') {
            let nameRegex = /^[^{|}|\[|\]|\(|\)|"|@|#|$|%|^|&|*|\/|\\|\||,|!|<|>|?|`|~|_|+|=|;|:]+$/g;
            if(!value || !nameRegex.test(value)) {
                setErrMsg({ ...errMsg, name: localString(`Couldn’t figure out the entered name. Please make sure it does not contain symbols except dot(.), hyphen(-) and apostrophe(\').`) })
            }else {
                setErrMsg({ ...errMsg, name: null  });
            }
        }else if(type === 'mobile') {
            if(!isMobileSelected && !value) {
                // setErrMsg({ ...errMsg, mobile: localString('Please Enter Valid Mobile Number.') });
                setErrMsg({ ...errMsg, mobile: null });
            }else if(!isMobileSelected && value && !mobileMaxLength.includes(value.length)) {
                setErrMsg({ ...errMsg, mobile: localString('Please Enter Valid Mobile Number.') });
            }else if(!checkMobileNumber(value)) {
                setErrMsg({ ...errMsg, mobile: localString('Please Enter Valid Mobile Number.') });
            }else {
                setErrMsg({ ...errMsg, mobile: null });
            }
        }else if(type === 'email') {
            let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]+$/; 
            if(isMobileSelected && !value) {
                // setErrMsg({ ...errMsg, email: localString('Please Enter Valid Email.') });
                setErrMsg({ ...errMsg, email: null });
            }else if(isMobileSelected && value && !emailRegex.test(value)) {
                setErrMsg({ ...errMsg, email: localString('Please Enter Valid Email.') });
            }else {
                setErrMsg({ ...errMsg, email: null });
            }
        }else if(type === 'personalId') {
            if(!value || !nationalIdValidation(value)) {
                setErrMsg({ ...errMsg, personalId: localString('Please Enter Valid National Id') });
            }else {
                setErrMsg({ ...errMsg, personalId: null });
            }
        }
    }

	const handleRadioBtn = (type) => {
		if(type === 'mobile') {
			setRadioValue('mobile');
			setMobile('')
			setIsMobileSelected(true);
			// if(qFilter && qFilter.countrycode && qFilter.countrycode.length) {
			// 	setCountry(qFilter.countrycode[0].toLowerCase());
			// 	getCountries(qFilter.countrycode[0].toLowerCase());
			// }else {
			// 	setCountry('in');
			// 	getCountries('in');
			// }
		}else {
			setRadioValue('email');
			setEmail('');
			setIsMobileSelected(false);
			// setCountry('us');
			// getCountries('us');
		}
	}

	return (
		<>
			<div>
				{/* ********** Enter MobileNo/Email ********** */}
				<div id='customerMobile' className='raiseReqSection' style={{ marginTop: '200px' }}>
					{
						showEmailOption ?
							<>
								<label className="form-label" style={{ padding: '0px' }}>{showEmailOption ? localString("Enter Mobile Number/Email ID") : localString("Enter Mobile Number")}</label>
								<div className="row" style={{ paddingLeft: '35px' }}>
									<div className="radio"> 
										<input
											type="radio"
											className="no-margin"
											name="selectorTypeRadio"
											id="radio_mobile"
											checked={radioValue === 'mobile'} 
											value="mobile" 
											onClick={() => handleRadioBtn('mobile')}
                                            disabled={disableStep1}
										/>
										<label for="radio_mobile" style={{width: '100px', padding: '0'}}>{localString("Mobile")}</label>
										<input
											type="radio"
											className="no-margin"
											name="selectorTypeRadio"
											id="radio_email"
											checked={radioValue === 'email'} 
											value="email" 
											onClick={() => handleRadioBtn('email')}
                                            disabled={disableStep1}
										/>
										<label for="radio_email" style={{width: '100px', padding: '0'}}>{localString("Email ID")}</label>
									</div>
								</div>
								{
									radioValue === 'mobile' && 
										<div className="form-control--top ">
											<IntlTelInputWrapper 
												value={mobile}
												handleOnChange={(val, countryData) => {
													setMobile(val)
													handleFlagSelection(countryData);
												}}
												onFlagSelect={handleFlagSelection}
												maxLength={mobileMaxLength}
												intlTelInputClassWrapper={{
													formControl: 'form-group intel-input',
													label: 'form-label',
													containerClass: `input-container px-10 ${!isMinimalConsumerMode ? 'input-tel-container' : ''}`,
													inputClass: 'input'
												}}
												defaultCountry={country}
												allowDropdown={isMinimalConsumerMode ? false : true}
                                                onKeyDown={ (e) => {
                                                    if(e && (e.key == 'Enter') && !disablePrimaryBtn) {
                                                        handlePrimarySubmit()
                                                    }
                                                }}
                                                disabled={disableStep1}
											/>
										</div>
									}
									{
										radioValue === 'email' && <>
											{/* <IntlTelInputWrapper 
												value={email}
												handleOnChange={(val, countryData) => {
													setEmail(val);
													handleFlagSelection(countryData);
												}}
												onFlagSelect={handleFlagSelection}
												intlTelInputClassWrapper={{
													formControl: 'form-group intel-input',
													label: 'form-label',
													containerClass: 'px-10 input-container',
													// containerClass: `input-container px-10 ${!isMinimalConsumerMode ? 'input-tel-container' : ''}`,
													inputClass: 'input'
												}}
												defaultCountry={country}
												// allowDropdown={isMinimalConsumerMode ? false : true}
												allowDropdown={false}
                                                onKeyDown={ (e) => {
                                                    if(e && (e.key == 'Enter') && !disablePrimaryBtn) {
                                                        handlePrimarySubmit()
                                                    }
                                                }}
                                                disabled={disableStep1}
											/> */}
                                            <div style={{ paddingTop: '30px' }}>
                                                <Input
                                                    value={email}
                                                    handleOnChange={(e) => {
                                                        setEmail(e.target.value);
                                                    }}
                                                    className={{
                                                        formControl: 'form-group intel-input',
                                                        label: 'form-label',
                                                        inputContainer: 'px-10 input-container',
                                                        inputClass: 'input'
                                                    }}
                                                    otherProps={{
                                                        onKeyDown: (e) => {
                                                            if(e && (e.key == 'Enter') && !disablePrimaryBtn) {
                                                                handlePrimarySubmit();
                                                            }
                                                        }
                                                    }}
                                                    isDisabled={disableStep1}
                                                />
                                            </div>
                                        </>
									}
							</>
								: 
							<div className="form-control--top ">
								<IntlTelInputWrapper 
									value={mobile}
									handleOnChange={(val, countryData) => {
										setMobile(val)
										handleFlagSelection(countryData);
									}}
									onFlagSelect={handleFlagSelection}
									label={'Mobile Number'}
									maxLength={mobileMaxLength}
									intlTelInputClassWrapper={{
										formControl: 'form-group intel-input',
										label: 'form-label',
										containerClass: `px-10 input-container ${!isMinimalConsumerMode ? 'input-tel-container' : ''}`,
										inputClass: 'input'
									}}
									defaultCountry={country}
									allowDropdown={isMinimalConsumerMode ? false : true}
                                    onKeyDown={ (e) => {
                                        if(e && (e.key == 'Enter') && !disablePrimaryBtn) {
                                            handlePrimarySubmit()
                                        }
                                    }}
                                    disabled={disableStep1}
								/>
							</div>
					}
                    { error && !isMobileSelected && <div className="textbox-padding error-color">
                        <p style={{marginTop: '5px', display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: 'larger'}}>
                            <img src={publicUrl + "/imgs/StopSign.png"} style={{ height: '22px', paddingRight: '5px' }}/>
                            {error ? error : ''}
                        </p>
                    </div> }
					<div className="text-right">
						<Button 
							className="btn button-servify nextBtn" 
							onClick={() => handlePrimarySubmit()}
							isDisabled={disableStep1 || disablePrimaryBtn}
						>
							<div className="button-next"></div>
						</Button>
					</div>
				</div>

				{/* ********** Consumer Details ********** */}
                {
                    showConsumerDetails && 
                    <div id='ConsumerDetails' className='raiseReqSection'>
                        <div style={{ paddingBottom: '30px' }}>
                            <Input
                                value={userDetails.name}
                                handleOnChange={(e) => {
                                    setUserDetails({ ...userDetails, name: e.target.value });
                                    validateConsumerDetails('name', e.target.value);
                                }}
                                label={localString('Full Name')}
                                labelAlign='top'
                                message={{type: 'error', value: errMsg.name}}
                                className={{
                                    formControl: 'form-group intel-input',
                                    label: 'form-label',
                                    inputContainer: 'px-10 input-container',
                                    inputClass: 'input'
                                }}
                                otherProps={{
                                    onKeyDown: (e) => {
                                        if(e && (e.key == 'Enter') && !disableSecondaryBtn) {
                                            handleSecondarySubmit();
                                        }
                                    }
                                }}
                            />
                        </div>
                        <div style={{ paddingBottom: '30px' }}>
                            <div className="form-control--top ">
                            {
                                isMobileSelected ? 
                                    <IntlTelInputWrapper 
                                        value={userDetails.email}
                                        handleOnChange={(val, countryData) => {
                                            setIntlTelCountryConfig(countryData);
                                            setUserDetails({ ...userDetails, email: val });
                                            validateConsumerDetails('email', val);
                                        }}
                                        label={localString('Email ID')}
                                        intlTelInputClassWrapper={{
                                            formControl: 'form-group intel-input',
                                            label: 'form-label',
                                            containerClass: 'px-10 input-container',
                                            inputClass: 'input'
                                        }}
                                        defaultCountry={secondaryStepCountry}
                                        allowDropdown={false}
                                        onKeyDown={ (e) => {
                                            if(e && (e.key == 'Enter') && !disableSecondaryBtn) {
                                                handleSecondarySubmit();
                                            }
                                        }}
                                    /> 
                                        :
                                    <IntlTelInputWrapper 
                                        value={userDetails.mobile}
                                        handleOnChange={(val, countryData) => {
                                            setIntlTelCountryConfig(countryData);
                                            setUserDetails({ ...userDetails, mobile: val });
                                            validateConsumerDetails('mobile', val);
                                        }}
                                        label={localString('Mobile Number')}
                                        intlTelInputClassWrapper={{
                                            formControl: 'form-group intel-input',
                                            label: 'form-label',
                                            containerClass: 'px-10 input-container',
                                            inputClass: 'input'
                                        }}
                                        maxLength={mobileMaxLength}
                                        defaultCountry={country}
                                        allowDropdown={false}
                                        onKeyDown={(e) => {
                                            if(e && (e.key == 'Enter') && !disableSecondaryBtn) {
                                                handleSecondarySubmit();
                                            }
                                        }}
                                    />   
                            }
                            </div>
                            <div className='error input__message'>{ isMobileSelected ? errMsg.email : errMsg.mobile }</div>
                        </div>
                        { qFilter && qFilter.flowType && qFilter.flowType.requestRaisingFlow && qFilter.flowType.requestRaisingFlow.length && qFilter.flowType.requestRaisingFlow.includes('REQ_RAISE_TURKEY') && 
                            <div style={{ paddingBottom: '30px' }}>
                                <Input
                                    value={userDetails.personalId}
                                    handleOnChange={(e) => {
                                        setUserDetails({ ...userDetails, personalId: e.target.value });
                                        validateConsumerDetails('personalId', e.target.value);
                                    }}
                                    label={localString("National ID")}
                                    labelAlign='top'
                                    className={{
                                        formControl: 'form-group intel-input',
                                        label: 'form-label',
                                        inputContainer: 'px-10 input-container',
                                        inputClass: 'input'
                                    }}
                                    otherProps={{
                                        onKeyDown: (e) => {
                                            if(e && (e.key == 'Enter') && !disableSecondaryBtn) {
                                                handleSecondarySubmit();
                                            }
                                        }
                                    }}
                                    message={{type: 'error', value: errMsg.personalId}}
                                />
                            </div> 
                        }
                        { error && isMobileSelected && <div className="textbox-padding error-color">
                            <p style={{marginTop: '5px', display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: 'larger'}}>
                                <img src={publicUrl + "/imgs/StopSign.png"} style={{ height: '22px', paddingRight: '5px' }}/>
                                {error ? error : ''}
                            </p>
                        </div> }
                        <div className="text-right">
                            <Button
                                className="btn button-servify nextBtn" 
                                onClick={() => handleSecondarySubmit()}
                                isDisabled={disableSecondaryBtn}
                            >
                                <div className="button-next"></div>
                            </Button>
                        </div>
                    </div>
                } 
			</div>
		</>
	);
}