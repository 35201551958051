import React,{useRef, useState, useEffect} from 'react';
import {Input,Dropdown,Button} from 'sfy-react';
import {cloneDeep, differenceBy} from 'lodash'
import { SERVICETYPE } from '../../../../../Utility/Utils';
import InspectDeviceRadioButton from './InspectDeviceRadioButton';
import UploadFile from './UploadFile';
const InspectDeviceInput = (props) =>{
    const {accessFn,localString,picMaster, docArray, claimOtherArrImagesPreview, consumerServiceRequest, selectProductFinishedGood, useDocServiceInspect, claimAddDocArray, claimSelectedPicType, completeDeviceReceivedClicked,env,countryCode,
    claimDocArray, claimFlags, setClaimFlags, claimRemoveAddedDoc, setFinishedGoodID,isTransferIncomplete,pickupCarryinGsxFlag,gsxCheckList ,selectedSKUList,selectOsVersion,isInward,checkListAllData,generalElsChecklist,setGeneralElsChecklist,
    accessoriesList,  setAccessoriesList,  gsxElsCheckList ,setGsxElsCheckList,miscellaneousList,setMiscellaneousList,otherImageLoader,picLoader,selectDeviceData,setSelectDeviceData,cosmeticStatus,setCosmeticStatus,setSelectedSKU,nextStatus,
    claimNewWorkshopChecks,pickupCarryInChecks,setGsxChecklistData} = props;
    const [checkList, setCheckList] = useState([])
    const [showDeviceReceived,setShowDeviceReceived] = useState(false)
    const [showDeviceReceivedGsx ,setShowDeviceReceivedGsx] = useState(false)
    let acceptFileType='.png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv';
    let acceptFileTypeGSX='.png,.jpg,.jpeg,.pdf,.heic,.xlsx,.xls,.csv';
    const fileRef = useRef(null);
    let deviceReceivedCheckList = consumerServiceRequest.checklist;
    const [showButton,setShowButton] = useState(false)
    const [modifiedArray,setModifiedArray] = useState([])
    let picMasterToCheck = [
        { 'picType': "Front Image",'Tag': "Front Image" },
        { 'picType': "Back Image",'Tag': "Back Image" },
        { 'picType': "Top Image",'Tag': "Top Image" },
        { 'picType': "Bottom Image",'Tag': "Bottom Image" },
        { 'picType': "Left Image",'Tag': "Left Image" },
        { 'picType': "Right Image",'Tag': "Right Image" }
    ];

    useEffect(() => {
        checkListItemChecked()
    }, [selectDeviceData.partCode,docArray,claimOtherArrImagesPreview,checkList])
    
    useEffect(()=>{
        checkListItem();
    },[generalElsChecklist,gsxElsCheckList,accessoriesList,miscellaneousList])//selectDeviceData,docArray,claimOtherArrImagesPreview,generalElsChecklist

    useEffect(()=>{
        checkListItemCheckedGsx()
    },[modifiedArray,docArray,claimOtherArrImagesPreview,selectDeviceData.partCode,selectDeviceData.osversion,cosmeticStatus,generalElsChecklist,gsxElsCheckList,accessoriesList,miscellaneousList])

    // useEffect(()=>{
    //     // if(accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
    //         setGsxCheckListPickCarry({
    //             accessoriesList:accessoriesList ? accessoriesList :[],
    //             generalElsChecklist:generalElsChecklist ? generalElsChecklist: [],
    //             gsxElsCheckList:gsxElsCheckList ? gsxElsCheckList :[],
    //             miscellaneousList:miscellaneousList ? miscellaneousList :[]
    //         })
    //     // }
    // },[modifiedArray])

    const checkListItemChecked = (e,item) =>{
        let count = 0;
        checkList && checkList.length>0 && checkList.map(item=>{
            if(item.value){
                return count++
            }
        })

        //complete button for non gsx and claim new
       //for pick up carry in with checklist 

        if(pickupCarryInChecks && checkList && checkList.length== count){
            if((claimFlags.isSelectAll && claimFlags.partSelected && consumerServiceRequest.consumerProduct && [114].indexOf(consumerServiceRequest.consumerProduct.BrandID) > -1 && countryCode && ['id','my','th','ph','pk','eg','mm'].indexOf(countryCode.toLowerCase()) > -1) || (['testing','production','prod','uat'].indexOf(env ) > -1 && accessFn('TESTING_AUTOMATION_FOR_REQUESTS'))){
                setShowDeviceReceived(true)
            } else if(claimFlags.isSelectAll && (docArray.length > 0) && claimFlags.partSelected &&  count == checkList.length){// || claimOtherArrImagesPreview.length>0
                setShowDeviceReceived(true)
            }else {
                setShowDeviceReceived(false)
            }
        }else if(pickupCarryInChecks && checkList && checkList.length !== count){
            if((claimFlags.isSelectAll && claimFlags.partSelected && consumerServiceRequest.consumerProduct && [114].indexOf(consumerServiceRequest.consumerProduct.BrandID) > -1 && countryCode && ['id','my','th','ph','pk','eg','mm'].indexOf(countryCode.toLowerCase()) > -1) || (['testing','production','prod','uat'].indexOf(env ) > -1 && accessFn('TESTING_AUTOMATION_FOR_REQUESTS'))){
                setShowDeviceReceived(true)
            } else if(claimFlags.isSelectAll && (docArray.length > 0) && claimFlags.partSelected &&  count == checkList.length){// || claimOtherArrImagesPreview.length>0
                setShowDeviceReceived(true)
            }else {
                setShowDeviceReceived(false)
            }
        }else{
            if((claimFlags.isSelectAll && claimFlags.partSelected && consumerServiceRequest.consumerProduct && [114].indexOf(consumerServiceRequest.consumerProduct.BrandID) > -1 && countryCode && ['id','my','th','ph','pk','eg','mm'].indexOf(countryCode.toLowerCase()) > -1) || (['testing','production','prod','uat'].indexOf(env ) > -1 && accessFn('TESTING_AUTOMATION_FOR_REQUESTS'))){
                setShowDeviceReceived(true)
            } else if(claimFlags.isSelectAll && docArray.length > 0  && claimFlags.partSelected){
                setShowDeviceReceived(true)
            } else {
                setShowDeviceReceived(false)
            }
        }
        return;
    };
    const checkListItem = () =>{
        setGsxChecklistData({
            accessoriesList:accessoriesList ? accessoriesList :[],
            generalElsChecklist:generalElsChecklist ? generalElsChecklist: [],
            gsxElsCheckList:gsxElsCheckList ? gsxElsCheckList :[],
            miscellaneousList:miscellaneousList ? miscellaneousList :[]
        })
        let arr = generalElsChecklist.concat(accessoriesList,gsxElsCheckList,miscellaneousList) 
        let count = 0;
        let modifyArray = []
        arr.map(item=>{
            if(item){
                modifyArray.push(item)
            }
        })
        setModifiedArray(modifyArray);
        
    }

    const checkListItemCheckedGsx =() =>{
        let count = 0;
        modifiedArray.map(item=>{
            if( isInward ? item.Inward : item.Outward){
                return count++
            }
        })
        let remainingImages = differenceBy(picMasterToCheck, docArray, 'Tag');
        if(remainingImages.length >= picMasterToCheck.length){
            setShowDeviceReceivedGsx(false)
        }else{
            if(count == modifiedArray.length && (cosmeticStatus && cosmeticStatus !='' && cosmeticStatus.length>0)){// && (selectDeviceData.partCode || selectDeviceData.osversion) 
                setShowDeviceReceivedGsx(true)
            }else{
                setShowDeviceReceivedGsx(false)
            }
        }
        // if(count == modifiedArray.length && (cosmeticStatus && cosmeticStatus !='' && cosmeticStatus.length>0) && (remainingImages.length )){//&& selectDeviceData.partCode && selectDeviceData.osversion
        //     setShowDeviceReceivedGsx(true)
        // }else{
        //     if(count == modifiedArray.length && (cosmeticStatus && cosmeticStatus !='' && cosmeticStatus.length>0) && (remainingImages.length) && (selectDeviceData.partCode || selectDeviceData.osversion)){
        //         setShowDeviceReceivedGsx(true)
        //     }else{
        //         setShowDeviceReceivedGsx(false)
        //     }
        // }
    }

    const imageClick = () =>{
        fileRef.current.click();
	}

    useEffect(()=>{
        let radioChecklist = deviceReceivedCheckList && deviceReceivedCheckList.map(v=>({name:v,value:null}));
        setCheckList(radioChecklist);
        
    },[])

    //non gsx
    const radioButtonCheck = (val,item) =>{
        let arr =  [...checkList]
        let radioArr = arr.map(radio=>{
            let obj= {...radio}
            if(obj.name==item.name){
                obj.value = val
            }
            return obj
        })
        setCheckList(radioArr)
    }
//

    const  radioButtonGeneralElsChecklist = (val,i) =>{
        // let arr =  [...generalElsChecklist]
        // let radioArr = arr.map(radio=>{
        //     let obj= {...radio}
        //     if(obj.name==item.name){
        //         obj.value = val
                
        //     }
        //     return obj
        // })
        // setGeneralElsChecklist(radioArr)
        let arr = cloneDeep(generalElsChecklist);
        arr[i][isInward ? 'Inward':'Outward'] = +val
        setGeneralElsChecklist(arr)
    }

    const radioButtonAccessoriesCheck = (val,i)=>{
        // let arr =  [...accessoriesList]
        // let radioArr = arr.map(radio=>{
        //     let obj= {...radio}
        //     if(obj.name==item.name){
        //         obj.value = val
        //     }
        //     return obj
        // })
        let arr = cloneDeep(accessoriesList);
        arr[i][isInward ? 'Inward':'Outward'] = +val
        setAccessoriesList(arr);
    }

    const radioButtonGsxElsCheckListCheck = (val,i) =>{
        // let arr =  [...gsxElsCheckList]
        // let radioArr =arr.length>0 && arr.map(radio=>{
        //     let obj= {...radio}
        //     if(obj.name==item.name){
        //         obj.value = val
        //     }
        //     return obj
        // })
        let arr = cloneDeep(gsxElsCheckList);
        arr[i][isInward ? 'Inward':'Outward'] = +val
        setGsxElsCheckList(arr)
    }

    const radioButtonMiscellaneousCheck = (val,i) =>{
        // let arr =  [...miscellaneousList]
        // let radioArr = arr.length>0 && arr.map(radio=>{
        //     let obj= {...radio}
        //     if(obj.name==item.name){
        //         obj.value = val
        //     }
        //     return obj
        // })
        let arr = cloneDeep(miscellaneousList);
        arr[i][isInward ? 'Inward':'Outward'] = +val
        setMiscellaneousList(arr)
    }
    //non gsx
    const radioBtnData = [
        {
            checkList : checkList ,
            labelBtnText :localString("Complete Checklist"),
            radioButton :[
                {
                    type:'radio',
                    value:'1',
                    name:'checkList_',
                    radioText:'Yes',
                    onUpdateRadio:(val,item)=>{
                        radioButtonCheck(val,item)
                    }
                },
                {
                    type:'radio',
                    value:'2',
                    name:'checkList_',
                    radioText:'No',
                    onUpdateRadio:(val,item)=>{
                        radioButtonCheck(val,item)
                    }
                },
                {
                    type:'radio',
                    value:'3',
                    name:'checkList_',
                    radioText:'Not applicable',
                    onUpdateRadio:(val,item)=>{
                        radioButtonCheck(val,item)
                    }
                }
            ]
        },
    ]
    // for gsx
    const radioBtnGSXData = [
        {
            checkList : generalElsChecklist ,
            labelBtnText : localString("Complete Checklist"),
            labelTextData : [
                {
                    showLabel:true,
                    labelText : localString("Item"),
                    customCls:((isInward && checkListAllData && checkListAllData.outwardCheckExists) || (!isInward && checkListAllData && checkListAllData.inwardCheckExists)) ? 'col-sm-5' : 'col-sm-5'
                },
                {
                    showLabel:((isInward && checkListAllData && checkListAllData.outwardCheckExists) || (!isInward && checkListAllData && checkListAllData.inwardCheckExists))  ,
                    labelText : isInward ? localString("Outward") : localString("Inward"),
                    customCls:'col-sm-2'
                },
                {
                    showLabel:true,
                    labelText :isInward ? localString("Inward") : localString("Outward"),
                    customCls:'col-sm-2 text-center'
                }
            ],
            radioButton :[
                {
                    type:'radio',
                    value:1,
                    name:'checkList_',
                    radioText:'Yes',
                    onUpdateRadio:(val,item)=>{
                        radioButtonGeneralElsChecklist(val,item)
                    }
                },
                {
                    type:'radio',
                    value:2,
                    name:'checkList_',
                    radioText:'No',
                    onUpdateRadio:(val,item)=>{
                        radioButtonGeneralElsChecklist(val,item)
                    }
                },
                {
                    type:'radio',
                    value:3,
                    name:'checkList_',
                    radioText:'Not applicable',
                    onUpdateRadio:(val,item)=>{
                        radioButtonGeneralElsChecklist(val,item, gsxCheckList.generalElsChecklist)
                    }
                }
            ]
        },
        {
            checkList : accessoriesList,
            labelBtnText : localString("Accessories Submitted"),
            labelTextData : [
                {
                    labelText : localString("Item"),
                    showLabel:true,
                    customCls:((isInward && checkListAllData && checkListAllData.outwardCheckExists) || (!isInward && checkListAllData && checkListAllData.inwardCheckExists)) ? 'col-sm-5' : 'col-sm-5'
                },
                {
                    labelText : isInward ? localString("Outward") : localString("Inward"),
                    showLabel:((isInward && checkListAllData && checkListAllData.outwardCheckExists) || (!isInward && checkListAllData && checkListAllData.inwardCheckExists)) ,
                    customCls:'col-sm-2'
                },
                {
                    labelText :isInward ? localString("Inward") : localString("Outward"),
                    showLabel:true,
                    customCls:'col-sm-2 text-center'
                }
            ],
            radioButton : [
                {
                    type:'radio',
                    value:1,
                    name:'accessories_',
                    radioText:'Yes',
                    onUpdateRadio:(val,item)=>{
                        radioButtonAccessoriesCheck(val,item)
                    }
                },
                {
                    type:'radio',
                    value:2,
                    name:'accessories_',
                    radioText:'No',
                    onUpdateRadio:(val,item)=>{
                        radioButtonAccessoriesCheck(val,item)
                    }
                },
                {
                    type:'radio',
                    value:3,
                    name:'accessories_',
                    radioText:'Not applicable',
                    onUpdateRadio:(val,item)=>{
                        radioButtonAccessoriesCheck(val,item)
                    }
                }
            ]
        },
        {
            checkList : gsxElsCheckList,
            labelBtnText : `${localString("ELS")} ${localString("Checklist")}`,
            labelTextData : [
                {
                    labelText : localString("Item"),
                    showLabel:true,
                    customCls:((isInward && checkListAllData && checkListAllData.outwardCheckExists) || (!isInward && checkListAllData && checkListAllData.inwardCheckExists)) ? 'col-sm-5 checkboxFeature' : 'col-sm-5 checkboxFeature'
                },
                {
                    labelText : isInward ? localString("Outward") : localString("Inward"),
                    showLabel:((isInward && checkListAllData && checkListAllData.outwardCheckExists) || (!isInward && checkListAllData && checkListAllData.inwardCheckExists)),
                    customCls:'col-sm-2'
                },
                {
                    labelText :isInward ? localString("Inward") : localString("Outward"),
                    showLabel:true,
                    customCls:'col-sm-2 text-center'
                }
            ],
            radioButton :[
                {
                    type:'radio',
                    value:1,
                    name:'elsChecklist_',
                    radioText:'Yes',
                    onUpdateRadio:(val,item)=>{
                        radioButtonGsxElsCheckListCheck(val,item)
                    }
                },
                {
                    type:'radio',
                    value:2,
                    name:'elsChecklist_',
                    radioText:'No',
                    onUpdateRadio:(val,item)=>{
                        radioButtonGsxElsCheckListCheck(val,item)
                    }
                },
                {
                    type:'radio',
                    value:3,
                    name:'elsChecklist_',
                    radioText:'Not applicable',
                    onUpdateRadio:(val,item)=>{
                        radioButtonGsxElsCheckListCheck(val,item)
                    }
                }
            ]
        },
        {
            checkList :miscellaneousList,
            labelBtnText :localString("Miscellaneous Checklist"),
            labelTextData : [
                {
                    labelText : localString("Item"),
                    showLabel:true,
                    customCls:((isInward && checkListAllData && checkListAllData.outwardCheckExists) || (!isInward && checkListAllData && checkListAllData.inwardCheckExists)) ? 'col-sm-5 checkboxFeature' : 'col-sm-5 checkboxFeature'
                },
                {
                    labelText : isInward ? localString("Outward") : localString("Inward"),
                    showLabel:((isInward && checkListAllData && checkListAllData.outwardCheckExists) || (!isInward && checkListAllData && checkListAllData.inwardCheckExists)),
                    customCls:'col-sm-2'
                },
                {
                    labelText :isInward ? localString("Inward") : localString("Outward"),
                    showLabel:true,
                    customCls:'col-sm-2 text-center'
                }
            ],
            radioButton :[
                {
                    type:'radio',
                    value:1,
                    name:'miscellaneous_',
                    radioText:'Yes',
                    onUpdateRadio:(val,item)=>{
                        radioButtonMiscellaneousCheck(val,item)
                    }
                },
                {
                    type:'radio',
                    value:2,
                    name:'miscellaneous_',
                    radioText:'No',
                    onUpdateRadio:(val,item)=>{
                        radioButtonMiscellaneousCheck(val,item)
                    }
                },
                {
                    type:'radio',
                    value:3,
                    name:'miscellaneous_',
                    radioText:'Not applicable',
                    onUpdateRadio:(val,item)=>{
                        radioButtonMiscellaneousCheck(val,item)
                    }
                }
            ]
        }
    ]

    const inspectDeviceDropdown = [
        {
            labelText: localString('Select SKU'),
            dataKey:"device",
            showGsx:pickupCarryInChecks && !accessFn('GSX_FLOW') ,
            customClass:'marginB20',
            type :'Dropdown',
            dropdownList:selectProductFinishedGood,
            oninputFunc: (val) => {
                setSelectDeviceData({...selectDeviceData,
                    ['device']:val.part.Name + val.part.PartCode,
                });
                setFinishedGoodID(val.part.PartID)
                
            }
        },
        {
            labelText:localString('Select Device'),
            dataKey:"device",
            showGsx:(claimNewWorkshopChecks),
            customClass:'marginB20',
            type :'Dropdown',
            dropdownList:selectProductFinishedGood,
            oninputFunc: (val) => {
                setSelectDeviceData({...selectDeviceData,
                    ['device']:val.part.Name + val.part.PartCode,
                });
                setFinishedGoodID(val.part.PartID)
                
            }
        },
        {
            labelText: localString('Device Name'),
            dataKey:"partCode",
            showGsx:(!claimFlags.finishedGoodAutoSelected && !isTransferIncomplete && (consumerServiceRequest.isVerifiedProduct || pickupCarryinGsxFlag.isNonSerializedProduct || pickupCarryinGsxFlag.isUnabletoDiagnose) && !pickupCarryinGsxFlag.isStaticNonSerializedProduct) &&  accessFn('GSX_FLOW'),
            // showGsx:(!claimFlags.finishedGoodAutoSelected && !isTransferIncomplete )&&  accessFn('GSX_FLOW'),
            customClass:'marginB20',
            type :'Dropdown',
            dropdownList:selectedSKUList,
            oninputFunc: (val) => {
                setSelectedSKU(val);
                setSelectDeviceData({...selectDeviceData,
                    ['partCode']:val.value ,
                });
                
            }
        },
        {
            labelText:localString('Select OS Version'),
            dataKey:"osversion",
            showGsx: gsxCheckList && gsxCheckList['osVersion'] && gsxCheckList['osVersion'].length > 0 && !isTransferIncomplete,
            customClass:'marginB20',
            type :'Dropdown',
            dropdownList:selectOsVersion,
            oninputFunc: (val) => {
                setSelectDeviceData({...selectDeviceData,
                    ['osversion']:val.Name,
                    ['osversionObj']:{Name:val.Name,checkID:val.checkID}
                });
                
            }
        },
    ]
    return(
        <>
            <div className='modal-body'>
                <div className='request-filter'>
                    { !isTransferIncomplete && <h3 className=''>{localString('Upload Device Pictures')}</h3>}
                        {
                            inspectDeviceDropdown && inspectDeviceDropdown.length >0  && inspectDeviceDropdown.map(item=>(
                                item.showGsx && <>
                                {
                                    item.labelText && <div class="col-sm-12 marginT10">
                                        {localString(item.labelText)}
                                    </div>
                                }
                                {
                                    item.type =='Dropdown' && 
                                    <div className='col-sm-12 '>
                                        <div className='finishedGood-dropdown'>
                                            <Dropdown
                                                id='dropdown'
                                                value={selectDeviceData[item.dataKey]}
                                                options={item.dropdownList}
                                                handleOnChange={(data) => {
                                                    item.oninputFunc(data);
                                                    setClaimFlags({...claimFlags,partSelected:true})
                                                }}
                                                // handleOnChange={(item) => {
                                                //     setDevice(item.part.Name + item.part.PartCode);
                                                //     setClaimFlags({...claimFlags,partSelected:true})
                                                //     setFinishedGoodID(item.part.PartID)
                                                // }}
                                                classObject={{
                                                    container: 'device-width'
                                                }}
                                                showTooltip={false}
                                                showDownArrow={false} 
                                                filterBy="value"
                                            />
                                    </div>
                                </div>
                                }
                                </>
                            ))
                        }
                        {
                            !isTransferIncomplete && accessFn('GSX_FLOW')? <div className='picture-type-label col-sm-12'>
                                {localString('Select Picture Type')}
                            </div>:<div className='picture-type-label col-sm-12'>
                                {localString('Select Picture Type')}
                            </div>
                        }
                        <div className='col-sm-12 upload-images-parent'>
                            <ul className='row upload-images-ul'>
                                {
                                    picMaster && picMaster.length >0 && picMaster.map(pic=>(
                                        <li className='image-col pic-list'>
                                            <UploadFile
                                                {...props}
                                                pic={pic}
                                                docArray={docArray}
                                                claimAddDocArray={claimAddDocArray}
                                                claimSelectedPicType={claimSelectedPicType}
                                                claimRemoveAddedDoc={claimRemoveAddedDoc}
                                            />
                                            {/* <div className={`${(pic.picType).replace(/\s+/, '')}  image-field`}>
                                                <Input
                                                    reference={fileRef}
                                                    handleOnChange={(e)=>claimAddDocArray(e,pic)}
                                                    name={pic.picType}
                                                    id={(pic.picType).replace(/\s+/, '')}
                                                    inputType='file'
                                                    className='inputfile'
                                                    otherProps={{ accept:accessFn('GSX_FLOW') ? acceptFileTypeGSX : acceptFileType }}
                                                />
                                                <label for={`${(pic.picType).replace(/\s+/, '')} `}>
                                                    <span className={pic.loader ? 'upload-sign-none' :'glyphicon glyphicon-plus upload-sign'} onClick={imageClick}/>
                                                </label>
                                                {pic.loader &&<div className="pic-loader" >
													<div className="cs-loader-inner">
														<svg className=" spinner-container" width="50px" height="50px" viewBox="0 0 52 52">
														<circle className="path" cx="26px" cy="26px" r="20px" fill="none" stroke-width="3px"></circle>
														</svg>
													</div>
												</div>}
                                            </div>
                                            <p className='image-text'>{localString(pic.picType)}</p>
                                            <div className='imagePosition'>
                                                {
                                                    docArray && docArray.map((doc,i)=>(
                                                        <div className='request-documents-li align' >
                                                            {
                                                                pic.picType === doc.Tag && 
                                                                <><span className='glyphicon glyphicon-minus-sign delete-charges-btn' onClick={()=>claimRemoveAddedDoc(doc,i)} ></span>
                                                                    <a href={doc.FilePath} target='_blank' className='nounderline'>
                                                                        <img src={doc.FilePath} alt={claimSelectedPicType} className='image'/>
                                                                    </a>
                                                                </>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div> */}
                                        </li>
                                    )) 
                                }
                                {
                                    claimOtherArrImagesPreview && claimOtherArrImagesPreview.map(othdocs=>(
                                        <li>
                                            <div>
                                                <div className='otherDoc'>
                                                    <div className='request-otherdocuments-li align'>
                                                        <span className='glyphicon glyphicon-minus-sign delete-charges-btn' onClick={()=>claimRemoveAddedDoc(othdocs)}></span>
                                                        <a href={othdocs.FilePath} target='_blank' className='nounderline'>
                                                            <img src={othdocs.FilePath} alt={claimSelectedPicType} className='image'/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            {othdocs.loader &&<div class="pic-loader" >
												<div class="cs-loader-inner" style="top: -69px;">
													<svg class=" spinner-container" width="50px" height="50px" viewBox="0 0 52 52">
													<circle class="path" cx="26px" cy="26px" r="20px" fill="none" stroke-width="3px"></circle>
													</svg>
												</div>
											</div>}
                                        </li>
                                    ))
                                }
                                <li className='image-col pic-list'>
                                    <div className='image-field'>
                                        <Input
                                            reference={fileRef}
                                            handleOnChange={(e)=>claimAddDocArray(e)}
                                            name='Other'
                                            id='Other'
                                            inputType='file'
                                            className='inputfile'
                                            otherProps={{ accept: acceptFileType }}
                                        />
                                        <label for='Other'>
                                            <span className={otherImageLoader ? 'upload-sign-none' :'glyphicon glyphicon-plus upload-sign'}></span>
                                        </label>
                                        {otherImageLoader &&<div class="pic-loader">
                                            <div class="cs-loader-inner-other">
                                                <svg class=" spinner-container" width="50px" height="50px" viewBox="0 0 52 52">
                                                <circle class="path" cx="26px" cy="26px" r="20px" fill="none" stroke-width="3px"></circle>
                                                </svg>
                                            </div>
                                        </div>}
                                    </div>
                                    <p style={{display:"flex", justifyContent:"center"}}>{localString('Other Image')}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                {/* </div> */}
                {docArray.length == 0 && !accessFn('GSX_FLOW') &&<div className='col-sm-12 padding20' >
                    {localString('No pictures added.')}
                </div>}
                <br/>
                {/* <hr/> */}
                {/* <div className='col-sm-2'> */}
                    <InspectDeviceRadioButton
                        {...props}
                        radioBtnData = {accessFn('GSX_FLOW') ? radioBtnGSXData : radioBtnData}
                        checkListAllData = {checkListAllData}
                        isInward = {isInward}
                    />
                {/* </div> */}
                {/* <hr/> */}
                { accessFn('GSX_FLOW') && <div>
                    <h3 className="">{localString("Cosmetic Status of Device")}</h3>
                    <div className="row">
                        <div className="col-sm-12 cosmetic-container">
                            <Input
                                value={cosmeticStatus}
                                handleOnChange={(e) => setCosmeticStatus(e.target.value)}
                                inputComponent='textarea'
                            />
                        </div>
                    </div>
                </div>}
                <div >
                    { accessFn('GSX_FLOW') && showDeviceReceivedGsx  && <Button className ='btn button-servify  pull-right'  handleOnClick={()=>{completeDeviceReceivedClicked(nextStatus)}} >{localString('Complete')}</Button>}{/* claimFlags.showDeviceReceived */}
                    {!accessFn('GSX_FLOW') &&  showDeviceReceived && <Button className ='btn button-servify  pull-right'  handleOnClick={()=>{completeDeviceReceivedClicked()}} >{localString('Complete')}</Button>}{/* claimFlags.showDeviceReceived */}
                </div>
            </div>
        </>
    )
}
export default InspectDeviceInput;

