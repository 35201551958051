import React, { useState } from 'react';
import { Button, Checkbox, Table } from 'sfy-react';
import Swal from "sweetalert2"; 

import { displayError } from '../../Utility/Utils';

export default function ConsentModal(props) {    
	const {
		localString, apiCall, setLoader, consentModal, setConsentModal, partnerList, setPartnerList, referenceID, 
		defaultPartnerList, panCard, countryCode, countryObj, fetchPANPartners, fetchWalletDetails, displayToast,
		setStep, advancePayClick, consentedPartnerCount, totalPartnerCount
	} = props;
	const [selectAll, setSelectAll] = useState(false);
	const [consent, setConsent] = useState(false);
	const [groupMapping, setGroupMapping] = useState([]);
	const tncLink = 'https://servify.in/termsandconditions/reseller-partner-program-general-tnc-advance-pay';

	const handleSelectAllChange = () => {
		let val = !selectAll;
		let arr = [...partnerList];
		if(val) {
			let newPartnerList = [];
			let mappings = [];
			arr.filter(partner => {
				let obj = {...partner};
				if(!partner.disable) {
					obj.IsConsented = true;
					mappings.push({ PartnerID: obj.PartnerID });
				}
				newPartnerList.push(obj);
			});
			setPartnerList(newPartnerList);
			setGroupMapping(mappings);
		}else {
			setPartnerList(defaultPartnerList);
			setGroupMapping([]);
		}
		setSelectAll(val);
	}
 
	const handleCheckboxChange = (e, item) => {
		let val = e.target.checked;
		let arr = [...partnerList];
		let newPartnerList = [];
		arr.filter(a => {
			let obj = {...a};
			if(a.PartnerID == item.PartnerID) {
				obj.IsConsented = val;
			}
			newPartnerList.push(obj);
		});
		let mappings = getGroupMapping(newPartnerList);
		setPartnerList(newPartnerList);
		setGroupMapping(mappings); 
		if(!mappings || (mappings && !mappings.length)) {
			setSelectAll(false);
		}
	}

	const closeModal = () => {
		setPartnerList(defaultPartnerList);
		setConsentModal(false);
		setSelectAll(false);
		setConsent(false);
		setGroupMapping([]);
		advancePayClick();
		// setStep('dashboard');
	}

	const getGroupMapping = (partnerArr) => {
		let arr = [];
		partnerArr	&& partnerArr.length && partnerArr.map((partner) => {
			if(!partner.disable && partner.IsConsented) {
				arr.push({ PartnerID: partner.PartnerID });
			}
		})
		return arr;
	}

	const createGroupMapping = () => {
		setLoader(true);
		let reqObj = {
			Name: panCard,
			PanNumber: panCard,
			Type: "Wallet",
			ModuleName: "ALL",
			IsDefault: 1,
			CountryID: countryObj && countryObj.CountryID,
			Currency: countryObj && countryObj.CurrencyCode,
			GroupMapping: groupMapping,
			EmailIds: []
		}
		apiCall({
			url: 'checkAndCreateWallet',
			data: reqObj,
			callBack: (result) => { 
				setLoader(false);
				if(result.success) {
					let count = groupMapping.length;
					displayToast('success', localString(`Consent granted for ${count} partners`))
					setConsentModal(false);
					fetchPANPartners();
					fetchWalletDetails();
					setSelectAll(false);
					setConsent(false);
					setGroupMapping([]);
					setStep('dashboard');
				}else {
					displayToast('error', displayError(result));
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const updateGroupMapping = () => {
		setLoader(true);
		let reqObj = {
			ReferenceID: referenceID,
			AddMappings: groupMapping,
		}
		apiCall({
			url: 'updateWalletMapping',
			data: reqObj,
			callBack: (result) => { 
				setLoader(false);
				if(result.success) {
					let count = groupMapping.length;
					displayToast('success', `Consent granted for ${count} partners`)
					setConsentModal(false);
					fetchPANPartners();
					fetchWalletDetails();
					setSelectAll(false);
					setConsent(false);
					setGroupMapping([]);
					setStep('dashboard');
				}else {
					displayToast('error', displayError(result));
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const addConsentSubmit = () => {
		if(!referenceID) {
			createGroupMapping();
		}else {
			updateGroupMapping()
		}
	}

	return (
		<div>
			<div className='consent-body'>
				{
					totalPartnerCount && consentedPartnerCount && totalPartnerCount == consentedPartnerCount ? 
					<p className='listInfo'>{localString('List of Consented Partners')}</p> :
					<p className='listInfo'>{localString('List of Consented & Unconsented Partners. Please select partners & give consent')}</p>
				}
				<Table 
					className={{
						tableContainer: 'consent-table'
					}}
				>
					<Table.Header>
						<Table.Row>
							<Table.Heading 
								classObject={{ header: 'customCheckBox checkboxWidth' }}
							>
								<Checkbox
									value={selectAll}
									id='selectAll'
									name='selectAll'
									handleOnChange={handleSelectAllChange}
								/>
							</Table.Heading>
							<Table.Heading>{localString('Partner Name')}</Table.Heading>
							<Table.Heading>{localString('Partner Reference ID')}</Table.Heading>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{
							partnerList && partnerList.length > 0 && partnerList.map((item, k) => {
								return (
									<Table.Row>
										<Table.Data className='customCheckBox checkboxWidth'>
											<Checkbox
												value={item.IsConsented}
												name='selectAll'
												handleOnChange={(e) => handleCheckboxChange(e, item)}
												isDisabled={item.disable}
											/>
										</Table.Data>
										<Table.Data>{localString(item.Name)}</Table.Data>
										<Table.Data>{localString(item.ExternalRefID)}</Table.Data>
									</Table.Row>
								)
							})
						}
					</Table.Body>
				</Table>
			</div>
			<div className='consent-footer'>
				<div className='customCheckBox'>
					<Checkbox
						value={consent}
						name='consent'
						id='consent'
						handleOnChange={() => {
							setConsent(!consent)
						}}
					>
						<span>{localString('I have read & understood the')} <a href={tncLink} target='_blank' className='consentLink'>{localString('Terms & Conditions & Declaration of Consent')}</a></span>
					</Checkbox>
				</div>
				<div className='container-fluid' style={{ padding: '10px' }}>
					<div className='row'>
						<div className='col-xs-6'>
							<Button
								className="btn button-servify w-100"
								onClick={() => closeModal()}
							>
								{localString('Cancel')}
							</Button> 
						</div>
						<div className='col-xs-6'>
							<Button
								className="btn button-servify w-100"
								onClick={() => addConsentSubmit()}
								isDisabled={!groupMapping || !groupMapping.length || !consent}
							>
								{localString('I Agree')}
							</Button>
						</div>
					</div>
				</div>             
			</div>
		</div>
	);
}