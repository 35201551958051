import React from 'react';
import {Table} from 'sfy-react';

const ReturnAddressDetails = (props) => {
    const {returnAddressHeader, consumerServiceRequest, defectivePartner, localString} = props;

    const classObject = {
		table:"table table-striped complaint-details-table request-table"
	}

    return (
        <>
        {
            (defectivePartner && defectivePartner.ServiceLocationName || consumerServiceRequest && consumerServiceRequest.servicelocation) &&
            <div>
                <p style={{margin: "12px 0", fontSize: "18px", fontWeight: "700"}}>{localString(returnAddressHeader)}</p>
                <Table className={classObject}>
                    <Table.Body>
                    {
                        (defectivePartner && defectivePartner.ServiceLocationName || consumerServiceRequest.servicelocation)&& 
                        <Table.Row>
                            <Table.Data className="table-keys text-capitalize">{localString("Name")}</Table.Data>
                            <Table.Data>{defectivePartner && defectivePartner.ServiceLocationName || consumerServiceRequest.servicelocation.ServiceLocationName || 'NA'}</Table.Data>
                        </Table.Row>
                    }
                    {
                        (defectivePartner && defectivePartner.ContactNo1 || consumerServiceRequest.servicelocation) && 
                        <Table.Row>
                            <Table.Data className="table-keys text-capitalize">{localString("Contact Number")}</Table.Data>
                            <Table.Data>{defectivePartner && defectivePartner.ContactNo1 || consumerServiceRequest.servicelocation.ContactNo1 || 'NA'}</Table.Data>
                        </Table.Row>
                    }
                    {
                        (defectivePartner && defectivePartner.Address || consumerServiceRequest.servicelocation) && 
                        <Table.Row>
                            <Table.Data className="table-keys text-capitalize">{localString("Address")}</Table.Data>
                            <Table.Data>{defectivePartner && defectivePartner.Address || consumerServiceRequest.servicelocation.Address || 'NA'}</Table.Data>
                        </Table.Row>
                    }
                    </Table.Body>
                </Table>
            </div>
        }
        </>
    )
}

export default ReturnAddressDetails
