import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import {displayError,returnLocalDateTime} from '../../Utility/Utils';
import AdvisorReset from './AdvisorReset';
import HandsetAdvisorDetails from './HandsetAdvisorDetails';
import './RetailerReset.scss';

const RetailerHandsetReset = (props) => {
	const {apiCall,localString,theme} = props;
	const Theme = JSON.parse(theme);
	const advisorLabel = Theme.salesmanName;
	const supervisorName = Theme.supervisorName;
	const [showLoader ,setLoader] = useState(false);
	const [dropDownList, setDropDownList] = useState([])
	const [advisorDetailsShow, setAdvisorDetailsShow] = useState(false)
	const [advisorFilterData, setAdvisorFilterData] = useState([])
	const [showResetButton, setShowResetButton] = useState(false)
	const [resetSucessful, setResetSucessful] = useState(false)
	const [submitBtnValidation, setSubmitBtnValidation] = useState(true)
	const [advisorDetails, setAdvisorDetails] = useState({
		advisorName:"",
		RetailUserID:""
	})

	const getAdvisorlist = ()=> {
		setLoader(true);
		apiCall({
			url:'getAdvisorsList',
			data: {
				'resetAction': "handset"
			},
			callBack:(result)=>{
				if(result.success){
					setLoader(false);
					let advisorListsArr = [];
					result.data && result.data.map(list=>{
						if(list && list.displayDetails){
							advisorListsArr.push({...list,value:list.displayDetails})
						}
					})
					setDropDownList(advisorListsArr)
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const getadvisordetails = ()=> {
		setLoader(true);
		apiCall({
			url:'getAdvisorDetails',
			data: {
				"RetailUserID": advisorDetails.RetailUserID
			},
			callBack:(result)=>{
				setLoader(false); 
				if(result.success){
					setAdvisorDetailsShow(true)
					setAdvisorFilterData(result.data)
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	useEffect(() => {
		getAdvisorlist();
	}, [])

	useEffect(() => {
		if(advisorFilterData && advisorFilterData.IMEINo){
			setShowResetButton(true)
			setResetSucessful(false)
		}
		if(!advisorFilterData.IMEINo || advisorFilterData.IMEINo == null || advisorFilterData.IMEINo == "" ){
			setResetSucessful(true)
			setShowResetButton(false)
		}
		
	}, [advisorFilterData])

	useEffect(() => {
		if(advisorDetails.RetailUserID){
			setSubmitBtnValidation(false)
		}
		if(!advisorDetails.RetailUserID){
			setSubmitBtnValidation(true)
		}
	}, [advisorDetails])

	const labelArray =[{
		labelText : localString("Handset Reset"),
		customClass : "col-sm-12 col-xs-8 form-label custom-label",
		showFilter:false
	},{
		labelText : localString("Select " + advisorLabel + " Name "),
		customClass : "col-md-2",
		customLabelClass:"pull-right button-handset-div inner-padding-3x",
		dataKey:"advisorName",
		showDropdownList:dropDownList,
		showFilter:true,
		oninputFunc: (val) => {
			setAdvisorDetails({...advisorDetails,advisorName:val.value,RetailUserID:val.RetailUserID})
		}
	}]

	const advisorDetailsArray = [{
		Name :	advisorFilterData.ShopName !== "" ? supervisorName : advisorLabel + " " + "Name",
		Value : advisorFilterData.Name &&  advisorFilterData.Name 
	},{
		Name : "Shop Name",
		Value : advisorFilterData.ShopName &&  advisorFilterData.ShopName
	},{
		Name : "Mobile Number",
		Value : advisorFilterData.MobileNo &&  advisorFilterData.MobileNo ,
	},{
		Name : "Last Resetted On",
		Value : (advisorFilterData && advisorFilterData.lastDeviceDetails && advisorFilterData.lastDeviceDetails.__data && returnLocalDateTime(advisorFilterData.lastDeviceDetails.__data.UpdatedDate,"DD-MM-YYYY")) || " - "
	},{
		Name : "No of Handsets Resets Made",
		Value : (advisorFilterData && advisorFilterData.lastDeviceDetails &&  advisorFilterData.lastDeviceDetails.__data && advisorFilterData.lastDeviceDetails.__data.Count) || "0"
	},{
		Name : "Current IMEI",
		Value : advisorFilterData.IMEINo &&  advisorFilterData.IMEINo
	}]

	const retailerHandsetReset=(e)=>{
		e.preventDefault();
		Swal.fire({
			title: "Are you sure you want to Reset Handset?",
			icon: "warning",
			showLoaderOnConfirm: true,
			showCancelButton: true,
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Yes",
			cancelButtonText: "No",
			allowOutsideClick: false
		}).then(result=>{
			if(result.isConfirmed){
				setLoader(true);
				apiCall({
					url:'resetIMEI',
					data: advisorFilterData,
					callBack:(result)=>{
						setLoader(false);
						if(result.success){
							Swal.fire({
								title: "Reset Successful", 
								icon: "success", 
								confirmButtonText: "Ok"
							})
							setResetSucessful(true)
							setShowResetButton(false)
							getadvisordetails()
						}else{
							Swal.fire({  
								icon: 'error',  
								text: localString(displayError(result)),  
							}); 
						}
					}	
				});
			}
		})
	}

	return (
		<>
		{ showLoader && ReactDOM.createPortal(
			<Loader />,
			document.getElementById("loader-root")
		)}
		<AdvisorReset {...props} labelArray={labelArray} getadvisordetails={getadvisordetails} advisorDetails={advisorDetails} submitBtnValidation={submitBtnValidation}></AdvisorReset>
		{
			advisorDetailsShow && <HandsetAdvisorDetails {...props} advisorLabel={advisorLabel} showResetButton={showResetButton} resetSucessful={resetSucessful} advisorDetailsArray={advisorDetailsArray} retailerHandsetReset={retailerHandsetReset}></HandsetAdvisorDetails>
		}
		</>
	)
}

export default RetailerHandsetReset
