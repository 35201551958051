import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Input, Loader } from 'sfy-react';
import Swal from 'sweetalert2';
import moment from 'moment';
import { findIndex, filter, sortBy } from 'lodash';

import { checkEmail, checkMobileNumber, decrypt, displayError, encrypt, getDateFormat, getLocaleDateString, getQueryStringValue, getTzOffset, SERVICETYPE } from '../../Utility/Utils';
import UserDetails from './UserDetails/UserDetails';
import ConsumerProducts from './ConsumerProducts/ConsumerProducts';
import AddProduct from './AddProduct/AddProduct';
import EnterDeviceAttributes from './EnterDeviceAttributes/EnterDeviceAttributes';
import ModeType from './ModeType/ModeType';
import IssuesSelection from './ModeType/IssuesSelection';
import SecondaryCommunicationDetails from './SecondaryCommunicationDetails/SecondaryCommunicationDetails';
import CarryInTypeSelection from './CarryInTypeSelection/CarryInTypeSelection';
import Address from './Address/Address';
import ServiceMode from './ServiceMode/ServiceMode';
import ReplacementOptions from './ReplacementOptions/ReplacementOptions';
import FindMyIphoneModal from './FMIP/FindMyIphoneModal';
import LogisticsPartnerModal from './LogisticsPartnerModal/LogisticsPartnerModal';
import SlotSelection from './SlotSelection/SlotSelection';
import ServiceLocation from './ServiceLocation/ServiceLocation';
import InstructionModal from './Instructions/InstructionModal';
import PayoutModesModal from '../../components/PayoutModesModal/PayoutModesModal';

import './RaiseRequest.scss';
import CustomerVerification from './CustomerVerification/CustomerVerification';

const defaultShowObj = {
	consumerDetails: false,
	consumerProducts: false,
	addProduct: false,
	addEditSerialNo: false,
	selectedProduct: false,
	wallMount: false,
	btk: false,
	whiteListing: false,
	inspectionGuide: false,
	enterDeviceAttributes: false
}

const defaultClaimFormData = {
	purchaseDate: '',
	damageDate: '',
	damageTime: '',
	damageType: '',
	damagePlace: '',
	deviceOn: null,
	incidentDamagePlace: '',
	alternateNumber: '',
	damageDetails: '',
	theftDate: '',
	policeDate: '',
	policeFile: '',
	policeNumber: '',
	theftPlace: '',
	theftDetails: '',
	locationOfDamage: ''
}

const defaultSecContactDetails = {
	secondaryName: '',
	secondaryMobileNo: '',
	secondaryEmailID: '',
	secondaryDialCode: ''
}

export default function RaiseRequest(props) {
	const { localString, accessFn, baseUrl, viewUrl, requestPageUrl, apiCall } = props;
	const qFilter = JSON.parse(props.qFilter);
	const [loader, setLoader] = useState(false);
	const [compWarningloader, setCompWarningLoader] = useState(false);
	const [emptyBoxloader, setEmptyBoxLoader] = useState(false);
	const [countryCodeArr, setCountryCodeArr] = useState(qFilter.countrycode || []);
	const [showEmailOption, setShowEmailOption] = useState(false);
	const [country, setCountry] = useState(countryCodeArr.length ? countryCodeArr[0].toLowerCase() : 'in');
	const [secondaryStepCountry, setSecondaryStepCountry] = useState(country);
	const [radioValue, setRadioValue] = useState('mobile');
	const [isMobileSelected, setIsMobileSelected] = useState(true);
	const [intlTelCountryConfig, setIntlTelCountryConfig] = useState(null);
	const [selectedCountryData, setSelectedCountryData] = useState(null);
	const [stateList, setStateList] = useState([]);
	const [backToText, setBackToText] = useState('Back to all requests');
	const [error, setError] = useState('');
	const [defaultCountryLocale, setDefaultCountryLocale] = useState(qFilter.languagecode && qFilter.languagecode.length ? qFilter.languagecode[0] : '');
	const [deviceWarningMsg, setDeviceWarningMsg] = useState('');
	const [imeiOrSerialNo, setImeiOrSerialNo] = useState('');
	const [editImei, setEditImei] = useState(true);
	const [mobileMaxLength, setMobileMaxLength] = useState([]);
	const [pinCodeLengthArr, setPinCodeLengthArr] = useState([]);
	const [skipZipCodeCheck, setSkipZipCodeCheck] = useState(false);
	const [isNonSerializedProduct, setIsNonSerializedProduct] = useState(false);
	const [isMinimalConsumerMode, setIsMinimalConsumerMode] = useState(false);
	const [currencySymbol, setCurrencySymbol] = useState(null);
	const [existingConsumerID, setExistingConsumerID] = useState('');
	const [existingProductID, setExistingProductID] = useState('');
	const [existingConsumerProductID, setExistingConsumerProductID] = useState('');
	
	const [mobile, setMobile] = useState('');
	const [email, setEmail] = useState('');
	const [userDetails, setUserDetails] = useState({
		name: '',
		email: '',
		mobile: '',
		personalId: ''
	});
	const [consumerId, setConsumerId] = useState('');
	const [consumerObj, setConsumerObj] = useState(null);
	const [isNewConsumer, setIsNewConsumer] = useState(false);
	const [disableStep1, setDisableStep1] = useState(false);
	
	const [brandId, setBrandId] = useState([]);
	const [consumerProduct, setConsumerProduct] = useState({});
	const [selectedConsumerProduct, setSelectedConsumerProduct] = useState({});
	const [consumerProductArray, setConsumerProductArray] = useState([]);
	const [inRepairConsumerProducts, setInRepairConsumerProducts] = useState([]);
	const [filteredConsumerProducts, setFilteredConsumerProducts] = useState([]);

	const [selectedClaimType, setSelectedClaimType] = useState(false);
	const [modeType, setModeType] = useState(null);
	const [plan, setPlan] = useState(null);
	const [claimEligibilityDetails, setClaimEligibilityDetails] = useState(null);
	const [coverage, setCoverage] = useState(null);
	const [claimForm, setClaimForm] = useState(null);
	const [claimFormData, setClaimFormData] = useState(defaultClaimFormData);
	const [damageTypeObj, setDamageTypeObj] = useState(null);
	const [selectedIssues, setSelectedIssues] = useState([]);
	const [selectedReasons, setSelectedReasons] = useState([]);
	const [otherIssueDescription, setOtherIssueDescription] = useState('');
	const [otherReasonDescription, setOtherReasonDescription] = useState('');
	const [secContactDetails, setSecContactDetails] = useState(defaultSecContactDetails);
	const [carryInType, setCarryInType] = useState(null);
	const [address, setAddress] = useState(null);
	const [walkIn, setWalkIn] = useState(false);
	const [consumerFavouriteLocationID, setConsumerFavouriteLocationID] = useState('');
	const [secondaryAddress, setSecondaryAddress] = useState(null);
	const [isSameAddress, setIsSameAddress] = useState(true);
	const [isSupressLogisticsCutomerToASC, setIsSupressLogisticsCutomerToASC] = useState(false);
	const [singleServiceLocation, setSingleServiceLocation] = useState(null);
	const [selectedServiceLocation, setSelectedServiceLocation] = useState(null);
	const [serviceMode, setServiceMode] = useState({});
	const [replacementOptions, setReplacementOptions] = useState([]);
	const [selectedReplacementOption, setSelectedReplacementOption] = useState({});
	const [replacementAllocationType, setReplacementAllocationType] = useState({});
	const [serviceLocation, setServiceLocation] = useState(null);
	const [dropoffLocation, setDropoffLocation] = useState(null);
	const [serviceAvailability, setServiceAvailability] = useState(null);
	const [serviceAvailabilityData, setServiceAvailabilityData] = useState(null);
	const [serviceTypeArray, setServiceTypeArray] = useState(null);
	const [deliveryMode, setDeliveryMode] = useState(null);
	const [partnerID, setPartnerID] = useState(null);
	const [partnerServiceLocationID, setPartnerServiceLocationID] = useState(null);
	const [advanceChargeApplicable, setAdvanceChargeApplicable] = useState(false);
	const [openPayoutModes, setOpenPayoutModes] = useState(false);
	const [FMIP, setFMIP] = useState(false);
	const [vendorList, setVendorList] = useState([]);
	const [vendor, setVendor] = useState(null);
	const [dropoffLocations, setDropoffLocations] = useState([]);
	const [dropoffLocationList, setDropoffLocationList] = useState([]);
	const [serviceLocationList, setServiceLocationList] = useState([]);
	const [secondryAddressServiceAvailabilityData, setSecondryAddressServiceAvailabilityData] = useState(null);
	const [secondryAddressServiceTypeArray, setSecondryAddressServiceTypeArray] = useState(null);
	const [dropOffAvailableForSecondAddress, setDropOffAvailableForSecondAddress] = useState(null);
	const [scheduleDateArray, setScheduleDateArray] = useState(null);
	const [scheduleSlotArray, setScheduleSlotArray] = useState(null);
	const [scheduleDate, setScheduleDate] = useState(null);
	const [slot, setSlot] = useState(null);
	const [slotAvailable, setSlotAvailable] = useState(false);
	const [disableSlotNext, setDisableSlotNext] = useState(false);
	const [isDeviceInWarranty, setIsDeviceInWarranty] = useState(false);
	const [warrantyTillDate, setWarrantyTillDate] = useState(new Date());
	const [warrantyTillDateCopy, setWarrantyTillDateCopy] = useState(undefined);
	const [isWarrantyApplicable, setIsWarrantyApplicable] = useState(false);
	const [isDeviceWarrantyApplicable, setIsDeviceWarrantyApplicable] = useState(false);
	const [dropOffData, setDropOffData] = useState(null);
	const [instructionsArray, setInstructionsArray] = useState([]);
	const [dropOffInstructionsTitle, setDropOffInstructionsTitle] = useState(null);
	const [decisionMatrix, setDecisionMatrix] = useState(false);
	const [logisticsName, setLogisticsName] = useState(null);
	const [logisticsMobileNo, setLogisticsMobileNo] = useState(null);
	const [payoutAttributes, setPayoutAttributes] = useState(null);
	const [isWallMount, setIsWallMount] = useState(false);
	const [warrantyConsent, setWarrantyConsent] = useState(null);
	const [consentConfirmed, setConsentConfirmed] = useState(false);
	const [paymentAmount, setPaymentAmount] = useState(0);
	const [isEmptyBoxServiceable, setIsEmptyBoxServiceable] = useState(false);
	const [emptyBoxLocationID, setEmptyBoxLocationID] = useState(null);
	const [isSubscriptionEnabled, setIsSubscriptionEnabled] = useState(false);
	const [isAdvanceExchangeApplicable, setIsAdvanceExchangeApplicable] = useState(false);
	const [getAdvanceDetailsAPICalled, setGetAdvanceDetailsAPICalled] = useState(false);
	const [defectivePartnerServiceLocationID, setDefectivePartnerServiceLocationID] = useState(null);

	const [show, setShow] = useState(defaultShowObj);
	const [showConsumerDetails, setShowConsumerDetails] = useState(false);
	const [showConsumerProducts, setShowConsumerProducts] = useState(false);
	const [showAddProduct, setShowAddProduct] = useState(false);
	const [showAddEditSerialNo, setShowAddEditSerialNo] = useState(false);
	const [showSelectedProduct, setShowSelectedProduct] = useState(false);
	const [showInspectionGuide, setShowInspectionGuide] = useState(false);
	const [showDeviceAttributes, setShowDeviceAttributes] = useState(false);
	const [showModeType, setShowModeType] = useState(false);
	const [showPlans, setShowPlans] = useState(false);
	const [showCoverageType, setShowCoverageType] = useState(false);
	const [showCoverage, setShowCoverage] = useState(false);
	const [showClaimForm, setShowClaimForm] = useState(false);
	const [showDOP, setShowDOP] = useState(false);
	const [showReplacementReasons, setShowReplacementReasons] = useState(false);
	const [showIssuesSelection, setShowIssuesSelection] = useState(false);
	const [showOtherIssueTextBox, setshowOtherIssueTextBox] = useState(false);
	const [showOtherReasonTextBox, setshowOtherReasonTextBox] = useState(false);
	const [showSecCommunicationDetails, setShowSecCommunicationDetails] = useState(false);
	const [showCarryInTypeSelection, setShowCarryInTypeSelection] = useState(false);
	const [showAddress, setShowAddress] = useState(false);
	const [showSecondaryAddress, setShowSecondaryAddress] = useState(false);
	const [showSlotSelection, setShowSlotSelection] = useState(false);
	const [showServiceModes, setShowServiceModes] = useState(false);
	const [showReplacementOptions, setShowReplacementOptions] = useState(false);
	const [showNoService, setShowNoService] = useState(false);
	const [showServiceLocationsCarryIn, setShowServiceLocationsCarryIn] = useState(false);
	const [showServiceLocationsDropOff, setShowServiceLocationsDropOff] = useState(false);
	const [showExternalReferenceID, setShowExternalReferenceID] = useState('');
    const [showFMIPModal, setShowFMIPModal] = useState(false);
    const [showLogisticsPartnerModal, setShowLogisticsPartnerModal] = useState(false);
	const [showIsSameAddress, setShowIsSameAddress] = useState(false);
	const [showInstructionsModal, setShowInstructionsModal] = useState(false);
	const [showPayoutModal, setShowPayoutModal] = useState(false);
	const [showActivation, setShowActivation] = useState(false);
	const [showOtpScreen, setShowOtpScreen] = useState(false);

	const [callComplianceWarning, setCallComplianceWarning] = useState(false);
	const [callConsumerProductClick, setCallConsumerProductClick] = useState(false);
	const [callGetScheduleDateAndSlotApi, setCallGetScheduleDateAndSlotApi] = useState(false);
	const [callRequestFunction, setCallRequestFunction] = useState(false);
	const [callCreateGenericRequestApi, setCallCreateGenericRequestApi] = useState(false);
	const [callRaiseNewClaimApi, setCallRaiseNewClaimApi] = useState(false);
	const [legalConfigsListForCoverage, setLegalConfigsListForCoverage] = useState([]);
	const [legalConfigsList, setLegalConfigsList] = useState([]);
	const [chargeMaxDeductible,setChargeMaxDeductible] = useState(false)
	const [tokenizeFlow,setTokenizeFlow] = useState(false);
	
	//varification
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(60);
	const [disableResendOtpBtn, setDisableResendOtpBtn] = useState(true);
	const setScreenToViewDiv = (id) => {
		let data = document.querySelector(`#${id}`);
		if(data && data.offsetTop) {
			window.scrollTo({
				top: data.offsetTop,
				left: 0,
				behavior: 'smooth'
			})
		}
	}

	useEffect(() => {
		if(showIssuesSelection) {
			setScreenToViewDiv('IssuesSelection');
		}
	}, [showIssuesSelection])

	useEffect(() => {
		if(showSecCommunicationDetails) {
			setScreenToViewDiv('SecCommunicationDetails');
		}
	}, [showSecCommunicationDetails])

	useEffect(() => {
		if(showCarryInTypeSelection) {
			setScreenToViewDiv('CarryInTypeSelection');
		}
	}, [showCarryInTypeSelection])

	useEffect(() => {
		if(showAddress) {
			setScreenToViewDiv('Address');
		}
	}, [showAddress])

	useEffect(() => {
		if(showServiceModes) {
			setScreenToViewDiv('ServiceModes');
		}
	}, [showServiceModes])

	useEffect(() => {
		if(showReplacementOptions) {
			setScreenToViewDiv('ReplacementOptions');
		}
	}, [showReplacementOptions])

	useEffect(() => {
		if(showSecondaryAddress) {
			setScreenToViewDiv('SecondaryAddress');
		}
	}, [showSecondaryAddress])

	useEffect(() => {
		if(showServiceLocationsCarryIn || showServiceLocationsDropOff) {
			setScreenToViewDiv('ServiceLocation');
		}
	}, [showServiceLocationsCarryIn, showServiceLocationsDropOff])

	useEffect(() => {
		if(showSlotSelection) {
			setScreenToViewDiv('SlotSelection');
		}
	}, [showSlotSelection])

	
	const hideSection = (typeArr) => {
		let sections = typeArr || [];
		sections.includes('ConsumerDetails') && setShowConsumerDetails(false);
		sections.includes('ConsumerProducts') && setShowConsumerProducts(false);
		sections.includes('AddProduct') && setShowAddProduct(false);
		sections.includes('AddEditSerialNo') && setShowAddEditSerialNo(false);
		sections.includes('SelectedProduct') && setShowSelectedProduct(false);
		sections.includes('InspectionGuide') && setShowInspectionGuide(false);
		sections.includes('DeviceAttributes') && setShowDeviceAttributes(false);
		sections.includes('ModeType') && setShowModeType(false);
		sections.includes('Plans') && setShowPlans(false);
		sections.includes('CoverageType') && setShowCoverageType(false);
		sections.includes('Coverage') && setShowCoverage(false);
		if(sections.includes('ClaimForm')) {
			setShowClaimForm(false);
			setClaimFormData(defaultClaimFormData);
		}
		sections.includes('DOP') && setShowDOP(false);
		sections.includes('ReplacementReasons') && setShowReplacementReasons(false);
		sections.includes('IssuesSelection') && setShowIssuesSelection(false);
		sections.includes('OtherIssueTextBox') && setshowOtherIssueTextBox(false);
		sections.includes('OtherReasonTextBox') && setshowOtherReasonTextBox(false);
		sections.includes('SecCommunicationDetails') && setShowSecCommunicationDetails(false);
		sections.includes('CarryInTypeSelection') && setShowCarryInTypeSelection(false);
		sections.includes('Address') && setShowAddress(false);
		sections.includes('SecondaryAddress') && setShowSecondaryAddress(false);
		sections.includes('SlotSelection') && setShowSlotSelection(false);
		sections.includes('ServiceModes') && setShowServiceModes(false);
		sections.includes('NoService') && setShowNoService(false);
		sections.includes('ServiceLocationsCarryIn') && setShowServiceLocationsCarryIn(false);
		sections.includes('ServiceLocationsDropOff') && setShowServiceLocationsDropOff(false);
		sections.includes('ExternalReferenceID') && setShowExternalReferenceID(false);
		sections.includes('FMIPModal') && setShowFMIPModal(false);
		sections.includes('LogisticsPartnerModal') && setShowLogisticsPartnerModal(false);
		sections.includes('IsSameAddress') && setShowIsSameAddress(false);
		sections.includes('InstructionsModal') && setShowInstructionsModal(false);
		sections.includes('PayoutModal') && setShowPayoutModal(false);
		sections.includes('Activation') && setShowActivation(false);
		sections.includes('ReplacementOptions') && setShowReplacementOptions(false);
		sections.includes('CustomerVerification') && setShowOtpScreen(false)
	}

	const backToPreviousPage = () => {
		if(existingConsumerID){
			window.location = baseUrl + "/consumers" ;
		} else {
			window.location = baseUrl + requestPageUrl;
		}
	}

	const checkExistingConsumerId = () => {
		let consumer = getQueryStringValue('consumer');
		let product = getQueryStringValue('product');
		let cp = getQueryStringValue('cp'); 
		if(consumer && product && cp) {
			setBackToText(localString("Back to all consumers"));
			setExistingConsumerID(decrypt(consumer));
			setExistingProductID(decrypt(product));
			setExistingConsumerProductID(decrypt(cp));
			setConsumerId(decrypt(consumer));
			if(decrypt(consumer) && decrypt(product)){
				knownConsumer(decrypt(consumer), decrypt(product), decrypt(cp));
			}
		}else {
			setBackToText(localString("Back to all requests"));
		}
	}

	useEffect(() => {
		checkExistingConsumerId();
	}, []);

	const knownConsumer = (id, productId, cpId) => {
		setLoader(true)
		let reqObj = {
			'consumerID': id
		}
		apiCall({
			// url: 'getMinimalConsumerDetails',
			url: 'consumerFetch',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data){
					let consumerData = result.data;
					setConsumerObj(consumerData);
					setEmail(consumerData.emailID ? consumerData.emailID : '');
					setMobile(consumerData.mobileNo ? consumerData.mobileNo : '');
					setUserDetails({ ...userDetails, 
						name: consumerData.name ? consumerData.name : '', 
						email: consumerData.emailID ? consumerData.emailID : '', 
						mobile: consumerData.mobileNo ? consumerData.mobileNo : '',
						personalId: consumerData.identityNumber ? consumerData.identityNumber : '' 
					});
					setIsMinimalConsumerMode(true);
					setIsMobileSelected(consumerData.mobileNo ? true : false);
					let cp = {
						ConsumerID: id,
						ProductID: productId,
						ConsumerProductID: cpId
					}
					setConsumerProduct(cp);
					loadConsumerProducts(id, cpId, consumerData?.countryID);
					setDisableStep1(true);
					setShowConsumerDetails(true);
					if(qFilter?.serviceLocations?.length > 0){
						requestConsumerOtp(consumerData);
					}else{
						setShowConsumerProducts(true);
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const checkConsumer = () => {
		setLoader(true)
		let reqObj = {
			"countryCode": isMobileSelected ? '+' + intlTelCountryConfig.dialCode : undefined,
			"countryID": selectedCountryData.CountryID,
            "phoneCode": isMobileSelected ? intlTelCountryConfig.dialCode : undefined,
			"mobileNo": isMobileSelected ? mobile : undefined,
			"emailID": !isMobileSelected ? email : undefined,
			"primaryIdentifier": isMobileSelected ? "MOBILE_NUMBER" : "EMAIL_ID",
			// "registerOnEmail": !isMobileSelected ? true : undefined,
		}
		apiCall({
			// url: 'existsOrCreate',
			url: 'consumerCreate',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data){
					let consumerData = result.data;
					setIsNewConsumer(consumerData.isNew);
					setConsumerObj(consumerData);
					setDisableStep1(true);
					setShowConsumerDetails(true);
					setUserDetails({ ...userDetails, 
						name: consumerData.name ? consumerData.name : '', 
						email: consumerData.emailID ? consumerData.emailID : '', 
						mobile: consumerData.mobileNo ? consumerData.mobileNo : '',
						personalId: consumerData.identityNumber ? consumerData.identityNumber : '' 
					});
					setConsumerId(consumerData.consumerID);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	// Todo: Add setCountryValidation in helper function
	const setCountryValidation = (countryData) => {
		const countryConfig = countryData.countryConfig;
		const AllowedMobileLengths = countryData.AllowedMobileLengths;
		if(countryConfig && countryConfig.MobileNumber) {
			if(countryConfig.MobileNumber && countryConfig.MobileNumber.LENGTH){
				let lengthArr = countryConfig.MobileNumber.LENGTH && (countryConfig.MobileNumber.LENGTH.length > 1) ? countryConfig.MobileNumber.LENGTH.map(data=>{
					return parseInt(data)
				}): [countryConfig.MobileNumber.LENGTH[0]];
				let dscSort = sortBy(lengthArr).reverse()
				setMobileMaxLength(dscSort);
			}
		}else if(AllowedMobileLengths){
			let mobLength = AllowedMobileLengths.split(',');
			let lengthArr = mobLength.map((item)=> {
				return parseInt(item);
			});
			let dscSort = sortBy(lengthArr).reverse()
			setMobileMaxLength(dscSort)
		}

		if(countryConfig) {
			let pinCodeConfig = countryConfig.addressConfig && countryConfig.addressConfig.find(obj => obj.keyName == "PinCode");
			let allowedPinCodeLengths = [];
			if (pinCodeConfig && pinCodeConfig.length) {
				allowedPinCodeLengths = pinCodeConfig.length;
				allowedPinCodeLengths = allowedPinCodeLengths.map(item => parseInt(item));
			} else if(countryConfig.AllowedPinCodeLengths) {
				allowedPinCodeLengths = countryConfig.AllowedPinCodeLengths && countryConfig.AllowedPinCodeLengths.split(',');
				allowedPinCodeLengths = allowedPinCodeLengths.map(item => parseInt(item));
			}	
			if (pinCodeConfig && pinCodeConfig.display == false) {
				setSkipZipCodeCheck(true)
			}
			setPinCodeLengthArr(allowedPinCodeLengths);
		}
	}
	
	const getCountries = (countryId) => {
		setLoader(true);
		let reqObj = {
			specificCountryCode: countryId ? countryId.toUpperCase() : country.toUpperCase(),
			countryConfig: true,
			CountryID: isMinimalConsumerMode && consumerObj && consumerObj.countryID ? consumerObj.countryID : undefined
		}
		apiCall({
			url: 'getCountries',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success){
					if(result.data) {
						let countryData = result.data.countries.length && result.data.countries[0];
						setSelectedCountryData(countryData);
						if(countryData && countryData.currencies[0]) {
							setCurrencySymbol(countryData.currencies[0].CurrencySymbol);
						}
						setCountryValidation(countryData);
						if(countryData && countryData.countryConfig) getStates(countryData.CountryID);
						if(countryData && countryData.countryConfig) {
							setShowEmailOption(countryData.countryConfig.RaiseClaimUsingEmail);
							if(isMinimalConsumerMode && consumerObj && consumerObj.primaryIdentifier == 'EMAIL_ID') {
								setRadioValue('email');
								setIsMobileSelected(false);
							}else {
								setRadioValue('mobile');
								setIsMobileSelected(true);
							}							
						}else {
							setShowEmailOption(false);
							setRadioValue('mobile');
							setIsMobileSelected(true);
						}
						if(isMinimalConsumerMode) {
							setCountry(countryData.CountryCode.toLowerCase());
							setSecondaryStepCountry(countryData.CountryCode.toLowerCase());
						}
						// self.selectedCountryCode = self.countriesData.CountryCode;
					} else {
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError('No country data found.')),
						});
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		getCountries();
	}, [isMinimalConsumerMode]);
	
	const getStates = (CountryID) => {
		// setLoader(true);
		let reqObj = {
			CountryID: CountryID
		}
		apiCall({
			url: 'getStates',
			data: reqObj,
			callBack: (result) => {
				// setLoader(false); 
				if(result.success && result.data){
					setStateList(result.data);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			// errorCB: (err) => setLoader(false)
		});
	}

	const getComplianceWarning = (type) => {
		setCompWarningLoader(true)
		let reqObj = {
			ComplianceName:type
		}
		if(type === 'EMAIL_ISVALID_OR_ISBOUNCE') {
			if(isMobileSelected) {
				if(consumerObj.emailID === userDetails.email){
					reqObj.ConsumerID = consumerId;
				}else{
					reqObj.EmailID = userDetails.email;
				}
			}else {
				reqObj.EmailID = email;  // Primary emailId
			}
		}else if(type === 'LASTREPAIRCREATION_INDAYS') {
			reqObj.imeiOrSerial = imeiOrSerialNo;
            reqObj.ConsumerID = consumerId;
		}
		apiCall({
			url: 'getComplianceWarning',
			data: reqObj,
			callBack: (result) => {
				setCompWarningLoader(false); 
				if(result.success) {
					setError(null);
					if(type == 'EMAIL_ISVALID_OR_ISBOUNCE'){
						if (isMobileSelected) {
							checkAddorUpdateConsumer();
						}else {
							checkConsumer();
						}
					}
				} else {
					if(result.data && result.data.warningMsg) {
						if(type == 'EMAIL_ISVALID_OR_ISBOUNCE') {
							if(result.data.isBlock) {
								//Invalid Email
								setError(result.data.warningMsg);
							}else {
								if (isMobileSelected) {
									checkAddorUpdateConsumer();
								}
								else {
									checkConsumer();
								}
							}
						}else if(type == 'LASTREPAIRCREATION_INDAYS'){
							setDeviceWarningMsg(result.data.warningMsg);
						}
					}else {
						if(type == 'EMAIL_ISVALID_OR_ISBOUNCE'){
							if (isMobileSelected) {
								checkAddorUpdateConsumer();
							}else {
								checkConsumer();
							}
						}
					}
				}
			},
			errorCB: (err) => {
				setCompWarningLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	useEffect(() => {
		if(callComplianceWarning) {
			getComplianceWarning('LASTREPAIRCREATION_INDAYS');
			setCallComplianceWarning(false);
		}
	}, [callComplianceWarning]);

	const checkAddorUpdateConsumer = () => {
		if (consumerObj && consumerObj.name === userDetails.name && ((isMobileSelected && consumerObj.emailID === userDetails.email) || (!isMobileSelected && consumerObj.mobileNo === userDetails.mobile))) {
            // self.showSubCategories = true;
            if (qFilter && qFilter.flowType && qFilter.flowType.requestRaisingFlow.indexOf('REQ_RAISE_TURKEY') > -1) {
				if (consumerObj.identityNumber === userDetails.personalId) {
					if(qFilter?.serviceLocations?.length > 0){
						requestConsumerOtp(consumerObj);
					}else{
						setShowConsumerProducts(true);
						loadConsumerProducts();
					}
                }
            }else {
				if(qFilter?.serviceLocations?.length > 0){
					requestConsumerOtp(consumerObj);
				}else{
					setShowConsumerProducts(true);
					loadConsumerProducts();
				}
            }
        } else {
			setLoader(true)
			// removed check for consumerID as with existsOrCreate API, consumerID will always be present
			var reqObj = {
				"consumerID": consumerObj.consumerID,
				"updateObj": {
					"name": userDetails.name,
					"emailID": isMobileSelected ? userDetails.email : undefined,
					"mobileNo": !isMobileSelected ? userDetails.mobile : undefined,
					"identityNumber": userDetails.personalId ? userDetails.personalId : undefined
					// "isNew": isNewConsumer,
					// "preferredLanguageCode": qFilter && qFilter.languagecode && qFilter.languagecode.length > 0 ? qFilter.languagecode[0] : navigator.language,
				}
			}
			apiCall({
				// url: 'updateNameEmail',
				url: 'consumerUpdate',
				data: reqObj,
				callBack: (result) => {
					setLoader(false); 
					if (result.success) {
						let userData = result.data;
						setConsumerObj(result.data);
						setConsumerId(result.data.consumerID);
						if(qFilter?.serviceLocations?.length > 0){
							requestConsumerOtp(userData);
						}else{
							setShowConsumerProducts(true);
							loadConsumerProducts();
						}
						
						// if(self.showConsumerProducts)
						// 	self.setScreenToViewDiv('consumerProducts');
					} else {
						// self.message = result.msg;
						// self.showCustomerDetails = true;
						// self.ConsumerID = undefined;
						// self.showLoader = false;
						// self.update();
						// if(self.showCustomerDetails)
						// 	self.setScreenToViewDiv('customerDetails');
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(result)),  
						});
					}
				},
				errorCB: (err) => {
					setLoader(false);
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(err)),  
					});
				}
			});	
		}
	}

	const loadConsumerProducts = (consId, cpId, countryID) => {
		setFilteredConsumerProducts([]);
		setConsumerProductArray([]);
		setInRepairConsumerProducts([]);
		setLoader(true);
		var reqObj = {
            "ConsumerID": consId ? consId : consumerId,
			"BrandID": brandId?.length ? brandId : undefined,
			"ConsumerProductID": cpId ? cpId : existingConsumerProductID ? existingConsumerProductID : undefined,
			"CountryID": countryID ? countryID : consumerObj?.countryID ? consumerObj.countryID : undefined,
		}
		apiCall({
			url: 'getConsumerProductsByBrand',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if (result.success && result.data) {
                    setInRepairConsumerProducts(result.data.inRepairConsumerProducts);
					let arr = result.data.servicibleConsumerProducts
					let consumerProductList = [];
					arr.map(product => {
						let obj = {...product};
						delete obj.ProductDetails
						if (product.ProductDetails && product.ProductDetails.length > 0) {
							obj.ProductDetails = product.ProductDetails.filter(x => x.Status == 1 && (x.PlanType == 'Secure' || x.PlanType == 'Protect' || x.PlanType == 'Combo'));
						}
						consumerProductList.push(obj);
					});
					setFilteredConsumerProducts(consumerProductList);
					setConsumerProductArray(consumerProductList);
					//pending existingConsumerProductID flow
					if(existingConsumerProductID){
						consumerProductList && consumerProductList.length && consumerProductList.map(item => {
							if(existingConsumerProductID == item.ConsumerProductID) {
								setConsumerProduct(item);
								setCallConsumerProductClick(true)
								// consumerProductClick();
							}
						});
					}
					
				} else {
					// loadBrands()
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}
 
	const checkToEnterAttributes = () => {
		setShowDeviceAttributes(true);
	}

	const showAvailableTypesForCP = () => {
		hideSection(['Plans', 'CoverageType', 'Coverage', 'ClaimForm', 'DOP', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'ReplacementOptions', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		setShowModeType(true);
	}

	const secondaryCommunicationSubmit = () => {
		hideSection(['CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		if(showIssuesSelection){
            issueNext();
        }else{
            claimFormSubmit();
        }
	}

	const issueNext = () => {
		if(modeType.ModeTypeID == 2) {
			claimOrContinue();
		}else if(modeType.ModeTypeID == 1) {
			if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.length > 1) {
				setShowAddress(true);
				// self.setScreenToViewDiv('serviceModes');
			}else {
				if(qFilter && !qFilter.serviceLocations) {
					setShowAddress(true);
					// self.setScreenToViewDiv('serviceModes');
				}else {
					setShowCarryInTypeSelection(true);
				}
			}
		}else if(modeType.ModeTypeID == 7) {
			setShowAddress(true);
			// self.setScreenToViewDiv('serviceModes');
		}
	}

	const carryInCustomerTypeSelected = (value) => {
		setCarryInType(value);
		let singleServiceLocationFlag = false;
		setShowSlotSelection(false);
		setAddress(null);
		setShowAddress(false);
		if(!qFilter.serviceLocations || (qFilter.serviceLocations && qFilter.serviceLocations.length > 1)) {
			singleServiceLocationFlag = false;
		}else if(qFilter.serviceLocations && qFilter.serviceLocations.length == 1){
			singleServiceLocationFlag = true;
			setServiceLocation({ PartnerServiceLocationID: qFilter.serviceLocations[0] });
		}
		if(value === 'radio_walkin') {
			setWalkIn(true);
			setServiceMode({ ServiceTypeID: SERVICETYPE.CARRY_IN });
			setShowSlotSelection(false);
			setShowServiceLocationsCarryIn(false);
			setShowExternalReferenceID(false);
			setShowNoService(false);
			setShowClaimForm(false);
			setShowServiceModes(false);
			if(accessFn('GSX_FLOW')){
				//for the gsx flow and it is walkin in
				setShowAddress(true);
			}else if(singleServiceLocationFlag){
				// getScheduleDateAndSlot();

				setCallGetScheduleDateAndSlotApi(true);
			}else {
				setShowAddress(true);
				setAddress(null);
			}
		}else {
			setWalkIn(false);
			setServiceMode({});
			setShowNoService(false);
			setShowServiceModes(false);
			setShowSlotSelection(false);
			setShowClaimForm(false);
			setShowAddress(true);
		}
		setSingleServiceLocation(singleServiceLocationFlag);
	}

	const getServiceAvailabilityWithLocations = (addressObj, isSecondryAddress = false) => {
		setLoader(true); 
	    let data = {
			Category: modeType.Category,
			Lng: addressObj.Lng,
			Lat: addressObj.Lat,
			ConsumerProductID: consumerProduct.ConsumerProductID,
            CountryID: addressObj.CountryID,
			ProductSubCategoryID: consumerProduct.ProductSubCategoryID,
			ProductID: consumerProduct.ProductID,
			BrandID: consumerProduct.BrandID,
			Address: addressObj.Address,
			Landmark: addressObj.Landmark,
			Radius: 80,
			Partnered: 1,
            State: addressObj.State,
            StateID : addressObj.StateID,
            City: addressObj.City,
			District: addressObj && addressObj.District ? addressObj.District : undefined,
            Zipcode: addressObj.PinCode ? addressObj.PinCode : addressObj.Zipcode ? addressObj.Zipcode : null,
			PlanCoverageID: coverage && coverage.PlanCoverageID ? coverage.PlanCoverageID : undefined,
			SoldPlanCoverageID: coverage && coverage.SoldPlanCoverageID ? coverage.SoldPlanCoverageID : undefined,
            PlanID:  plan && plan.PlanID ? plan.PlanID : undefined,
            SoldPlanID: plan && plan.SoldPlanID ? plan.SoldPlanID : undefined,
            SumInsured: coverage && coverage.soldPlanclaimDetails && coverage.soldPlanclaimDetails.SumInsured ? coverage.soldPlanclaimDetails.SumInsured : undefined,
			damageId: coverage && coverage.RequiresClaimForm &&  damageTypeObj ? damageTypeObj.id : 0
		};

        if(modeType.ModeTypeID == 7){
            data.Benefit = "REPLACEMENT";
        }

        if(qFilter && qFilter.originLocation && qFilter.originLocation.length > 0){
            data.OriginServiceLocationID = qFilter.originLocation[0];
        }

    	if (plan && plan.SoldPlanID && plan.PlanID) {
    		data.SoldPlanID = plan.SoldPlanID;
    		data.PlanID = plan.PlanID;
    	}

        if(!isSecondryAddress){
            // serviceMode = {};
			setServiceMode({});
			// setShowServiceModes(false);
        }
		setShowSlotSelection(false);
		apiCall({
			url: 'getServiceAvailabilityWithLocations',
			data: data,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					setShowServiceLocationsDropOff(false);
					let serviceTypeArr = [];
					if (isSecondryAddress) {
						let secAddServiceAvailabilityData = result.data;
						let secAddServiceTypeArray = serviceAvailabilityData.modes;
						secAddServiceTypeArray = secAddServiceTypeArray.filter(o => o.IsAvailable);
						let dropOffAvailableForSecAdd = findIndex(secAddServiceTypeArray, function(o) {
							return [SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].indexOf(o.ServiceTypeID) > -1;
						});
						setSecondryAddressServiceAvailabilityData(secAddServiceAvailabilityData);
						setSecondryAddressServiceTypeArray(secAddServiceTypeArray);
						setDropOffAvailableForSecondAddress(dropOffAvailableForSecAdd);
						if([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(serviceMode.ServiceTypeID) > -1) {
                            // getScheduleDateAndSlot();
							//setCallGetScheduleDateAndSlotApi(true);
							checkSuppressLogistics();
                            if(showSlotSelection) {
                                // setScreenToViewDiv('scheduleDateAndTime');
                            }
                        }else if(dropOffAvailableForSecAdd > -1 && [SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].indexOf(serviceMode.ServiceTypeID) > -1){
							if (secAddServiceAvailabilityData.isDropOffLocationAvailable) {
								setDropoffLocationList(secAddServiceAvailabilityData.dropOffLocations);
								setShowServiceLocationsDropOff(true);
							// }else if(secAddServiceAvailabilityData.dropOffLocationsURL) {
							// 	showInstructions();
							}else {
								showInstructions();
							}
						}else {
							Swal.fire({  
								icon: 'error',  
								text: localString('This address is not serviceable'),  
							});
						}
					}else {
						setServiceMode({});
						let serviceAvailabilityObj = result.data;
						setServiceLocationList(serviceAvailabilityObj.serviceLocations); //set service location list
						setServiceAvailability(serviceAvailabilityObj);
						setServiceAvailabilityData(serviceAvailabilityObj);
						setServiceTypeArray(serviceAvailabilityObj.modes);
						serviceTypeArr = serviceAvailabilityObj.modes;
						setShowServiceModes(true);	
						if(serviceAvailabilityObj && serviceAvailabilityObj.DeliveryMode) {
							setDeliveryMode(serviceAvailabilityObj.DeliveryMode);
						}
						if(serviceAvailabilityObj && serviceAvailabilityObj.PartnerID) {
							setPartnerID(serviceAvailabilityObj.PartnerID);
						}
						if (serviceAvailabilityObj && serviceAvailabilityObj.PartnerServiceLocationID) {
							setPartnerServiceLocationID(serviceAvailabilityObj.PartnerServiceLocationID);
						}
					}
					let checkEmptyBox = filter(serviceTypeArr, function(o) {
                        return [SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].indexOf(o.ServiceTypeID) > -1 && o.IsAvailable;
                    });
                    if(checkEmptyBox && checkEmptyBox.length) {
						checkEmptyBoxAvailbility(addressObj);
                    }
					let checkDefectivePartner = filter(serviceTypeArr, function(o) {
						return [SERVICETYPE.ADVANCE_EXCHANGE].indexOf(o.ServiceTypeID) > -1 && o.IsAvailable;
					});

					if(checkDefectivePartner && checkDefectivePartner.length){
						checkDefectivePartnerAvailbility(addressObj);
					}
					// if (self.CurrencySymbol && $('.buyout-currency')) {
                    //     $('.buyout-currency').html(self.CurrencySymbol);
                    // }
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const getServicelocationForClaimCarryIn = () => { // Get SCORE based service locations
		setShowSlotSelection(false);
		setLoader && setLoader(true); 
		let reqObj = {
			soldPlanID: plan && plan.SoldPlanID,
			countryID: address ? address.CountryID : '',
			brandID: consumerProduct && consumerProduct.BrandID,
		};
		let arr = [];
		if(serviceAvailabilityData?.serviceLocations?.length){
			serviceAvailabilityData.serviceLocations.map(item=>{
				let obj={
					"ClientServiceLocationID": item.ClientServiceLocationID ? item.ClientServiceLocationID :null,
					"PartnerServiceLocationID":item.PartnerServiceLocationID ? item.PartnerServiceLocationID :null
				}
				arr.push(obj);
			})
		}
		reqObj.serviceLocations = arr;
		apiCall({
			url: 'getServiceAvailabilityResolutionRate',
			data:reqObj,
			callBack: (result) => {
				setLoader && setLoader(false); 
				if(result && result.success && result.data && result.data.length ) {
					let arr=[...serviceAvailabilityData.serviceLocations];
					let serviceListArr =[];
					arr.map(item=>{
						let obj ={...item};
						result.data.map(scoreItem=>{
							if((scoreItem.ClientServiceLocationID ? (scoreItem.ClientServiceLocationID == item.ClientServiceLocationID) : (scoreItem.PartnerServiceLocationID == item.PartnerServiceLocationID))){
								obj.score=scoreItem.Score;
								obj.scoreMeta=scoreItem.ScoreMeta;
							}
						})
						serviceListArr.push(obj);
					})
					setServiceLocationList(serviceListArr);
				}
			},
			errorCB: (err) => {
				setLoader && setLoader(false);
			}
		});
		setShowServiceLocationsCarryIn(true);
	}


	const checkDefectivePartnerAvailbility = (address) => {
		setLoader(true); 
		let reqObj = {
			"Lng": address && address.Lng,
			"Lat": address && address.Lat,
			"CountryID": consumerObj ? consumerObj.countryID : undefined,
            "CountryCode": selectedCountryData ? selectedCountryData.CountryCode : undefined,
			"ProductSubCategoryID": consumerProduct && consumerProduct.ProductSubCategoryID,
			"ProductID": consumerProduct && consumerProduct.ProductID,
			"BrandID": consumerProduct && consumerProduct.BrandID,
			"City": address && address.City,
			"State": address && address.State,
			"Zipcode": address && address.PinCode ? address.PinCode : address && address.Zipcode ? address.Zipcode : null,
			"PlanID": plan && plan.PlanID ? plan.PlanID : undefined,
		};
		apiCall({
			url: 'getAEWithoutDefectiveServiceAvailability',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data) {
					if(result?.data?.PartnerServiceLocationID) {
						setIsAdvanceExchangeApplicable(result.data.isAdvanceExchangeApplicable);
						setDefectivePartnerServiceLocationID(result.data.PartnerServiceLocationID);
					}else {
						let arr = serviceTypeArray.filter(o => o.ServiceTypeID != 65);
						setServiceTypeArray(arr);
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)), 
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const checkEmptyBoxAvailbility = (address) => {
		setEmptyBoxLoader(true); 
		let reqObj = {
			"Lng": address && address.Lng,
			"Lat": address && address.Lat,
			"CountryID": consumerObj ? consumerObj.countryID : undefined,
            "CountryCode": selectedCountryData ? selectedCountryData.CountryCode : undefined,
			"ProductSubCategoryID": consumerProduct && consumerProduct.ProductSubCategoryID,
			"ProductID": consumerProduct && consumerProduct.ProductID,
			"BrandID": consumerProduct && consumerProduct.BrandID,
			"State": address && address.State,
			"Zipcode": address && address.PinCode ? address.PinCode : address && address.Zipcode ? address.Zipcode : null,
			"PlanID": plan && plan.PlanID ? plan.PlanID : undefined,
		};
		apiCall({
			url: 'getEmptyBoxServiceAvailability',
			data: reqObj,
			callBack: (result) => {
				setEmptyBoxLoader(false); 
				if(result.success && result.data) {
					setIsEmptyBoxServiceable(result.data.isEmptyBoxServiceable);
					setEmptyBoxLocationID(result.data.PartnerServiceLocationID);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)), 
					});
				}
			},
			errorCB: (err) => {
				setEmptyBoxLoader(false);
				Swal.fire({
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	useEffect(() => {
		if(callGetScheduleDateAndSlotApi) {
			getScheduleDateAndSlot();
			setCallGetScheduleDateAndSlotApi(false);
		}
	}, [callGetScheduleDateAndSlotApi])

	// Todo: Call this function in useEffect of Child component(Child)
	const getScheduleDateAndSlot = () => {
		setLoader(true);
		let slotObj = {
            CurrentDate: moment().format('YYYY-MM-DD'),
            CurrentTime: moment().format('HH:mm:ss'),
            ServiceTypeID: serviceMode && serviceMode.ServiceTypeID,
            Lat: address ? address.Lat : '',
            Lng: address ? address.Lng : '',
            Country: selectedCountryData && selectedCountryData.CountryCode,
            PinCode: address ? address.PinCode : '',
            ConsumerProductID: consumerProduct.ConsumerProductID,
            ProductID: consumerProduct.ProductID,
            CountryID: address ? address.CountryID : '',
			slots: serviceLocation && serviceLocation.slots ? serviceLocation.slots : undefined,
			externalSlots: serviceLocation && serviceLocation.externalSlots ? serviceLocation.externalSlots : undefined,
            TimezoneString: serviceLocation && serviceLocation.TimezoneString ? serviceLocation.TimezoneString : undefined
        }
		if(serviceMode && [SERVICETYPE.PICK_UP, SERVICETYPE.RECYCLE_PICK_UP].indexOf(serviceMode.ServiceTypeID) > -1) {
            slotObj.DeliveryMode = deliveryMode;
            if(serviceAvailability && serviceAvailability.PartnerID) {
                slotObj.PartnerID = serviceAvailability.PartnerID;
            }else if(partnerID) {
                slotObj.PartnerID = partnerID;
            }

            if(serviceAvailability && serviceAvailability.PartnerServiceLocationID) {
                slotObj.PartnerServiceLocationID = serviceAvailability.PartnerServiceLocationID;
            }else if(partnerServiceLocationID) {
                slotObj.PartnerServiceLocationID = partnerServiceLocationID
            }
        }
        if(serviceMode.ServiceTypeID == SERVICETYPE.CARRY_IN || serviceMode.ServiceTypeID == SERVICETYPE.RECYCLE_DROP_OFF) {
            if(serviceLocation && serviceLocation.PartnerServiceLocationID) {
                slotObj.PartnerServiceLocationID = serviceLocation.PartnerServiceLocationID
            }else if(partnerServiceLocationID) {
                slotObj.PartnerServiceLocationID = partnerServiceLocationID
            }
        };
        if(modeType.ModeTypeID == 7 && serviceAvailability && serviceAvailability.doorToDoorDetail){
            slotObj.PartnerServiceLocationID = serviceAvailability.doorToDoorDetail.PartnerServiceLocationID;
            slotObj.PartnerID = serviceAvailability.doorToDoorDetail.PartnerID;
            slotObj.DoorToDoorPincodeMappingID = serviceAvailability.doorToDoorDetail.DoorToDoorPincodeMappingID;
            slotObj.DropPincode = serviceAvailability.doorToDoorDetail.DropPincode;
            slotObj.PickUpPincode= serviceAvailability.doorToDoorDetail.PickUpPincode;
            slotObj.HubPincode = serviceAvailability.doorToDoorDetail.HubPincode;
            slotObj.DeliveryMode = serviceAvailability.doorToDoorDetail.DeliveryMode;
            slotObj.VendorName =  serviceAvailability.doorToDoorDetail.VendorName;
        }
		if (serviceMode && serviceMode.ServiceTypeID == SERVICETYPE.CLAIM_ONSITE && serviceAvailability && serviceAvailability.OnsiteDetails && serviceAvailability.OnsiteDetails.CLAIM_ONSITE) {
            slotObj.PartnerServiceLocationID = serviceAvailability.OnsiteDetails.CLAIM_ONSITE.PartnerServiceLocationID;
            slotObj.PartnerID =  serviceAvailability.OnsiteDetails.CLAIM_ONSITE.PartnerID;
        };
		apiCall({
			url: 'slotCheckingWithLogistics',
			data: slotObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success) {
					let scheduleDateList = result.data;
					let scheduleSlotList = [];
					setScheduleDateArray(scheduleDateList);
					let selectedScheduleDate;
					let selectedSlot = undefined;
					for (let i = scheduleDateList.length - 1; i >= 0; i--) {
						if (scheduleDateList[i].slots && (scheduleDateList[i].slots.length > 0)) {
							let slotArray = scheduleDateList[i].slots;
							for (let j = slotArray.length - 1; j >= 0; j--) {
								if (slotArray[j].IsActive) {
									selectedScheduleDate = scheduleDateList[i];
								}
							}
						}
					}
					setScheduleDate(selectedScheduleDate);
					if(selectedScheduleDate) {
						scheduleSlotList = selectedScheduleDate.slots;
						setScheduleSlotArray(scheduleSlotList);
						let slotAvailableFlag = false;
						for (let i = scheduleSlotList.length - 1; i >= 0; i--) {
							if (scheduleSlotList[i].IsActive) {
								slotAvailableFlag = true;
								if(walkIn){
									selectedSlot = scheduleSlotList[i];
									setSlot(scheduleSlotList[i])
									setShowSlotSelection(false);						
									setDisableSlotNext(false);
									checkUpdateConsumerProduct();
									return;
								}
							}
						}
						setSlot(selectedSlot);
						setSlotAvailable(slotAvailableFlag);
						setShowSlotSelection(true);		
					}else {
						Swal.fire({  
							icon: 'error',  
							text: localString('No slots available'),  
						});
					}
					if(selectedSlot) {
						setDisableSlotNext(false);
					}else {
						setDisableSlotNext(true);
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString('No slots available'),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});

	}

	const checkAvailabilityForPickUp = () => {
		setShowServiceLocationsCarryIn(false);
		setShowExternalReferenceID(false);
		setShowNoService(false);
		setServiceAvailability(serviceAvailabilityData.logisticPartner);
        if(accessFn('GSX_FLOW') && !isNonSerializedProduct){
            FMIPcheck();
        }else{
            checkForLogisticsModal();
        }
	}

	const getServiceLocationsForCarryIn = (serviceModeObj) => {
		setShowSlotSelection(false);
		setShowNoService(false);
		if(modeType.ModeTypeID == 2 && (serviceModeObj?.ServiceTypeID && [SERVICETYPE.CARRY_IN].includes(serviceModeObj.ServiceTypeID))) {
			getServicelocationForClaimCarryIn();
		}else {
			if(serviceAvailabilityData && serviceAvailabilityData.serviceLocations) {
				setServiceLocationList(serviceAvailabilityData.serviceLocations);
				setShowServiceLocationsCarryIn(true);
				// setScreenToViewDiv('serviceLocation');
			}else {
				Swal.fire({  
					icon: 'error',  
					text: localString('Something went wrong'),  
				});
			}
		}
	}

	const getServiceLocationsForDropOff = () => {
		setShowSlotSelection(false);
		setShowNoService(false);

		if(serviceAvailabilityData && serviceAvailabilityData.dropOffLocations) {
			setDropoffLocationList(serviceAvailabilityData.dropOffLocations);
			setShowServiceLocationsDropOff(true);
			// setScreenToViewDiv('serviceLocation');
		}else {
			Swal.fire({  
				icon: 'error',  
				text: localString('Something went wrong'),  
			});
		}
	}

	const getLocations = () => {
        if(showServiceLocationsCarryIn) {
            getServiceLocationsForCarryIn();
        } else if(showServiceLocationsDropOff) {
            getServiceLocationsForDropOff();
        }
    }

	const checkAvailabilityForDropOff = () => {
		setShowServiceLocationsCarryIn(false);
		setShowSlotSelection(false);
		setShowExternalReferenceID(false);
		setShowNoService(false);
		// setIsSameAddress(true);
		setShowIsSameAddress(true)
	}

	const removeBreakline = (str) => {
		let val = str.replace(/\n/ig, '.');
        val = val.replace(new RegExp("\\.\\.+", "g"),".");
        return val;
	}

	useEffect(() => {
		if(callRequestFunction) {
			request();
			setCallRequestFunction(false);
		}
	}, [callRequestFunction])

	const request = () => {
		Swal.fire({
			title: localString('Send for raising request') + '?',
			icon: 'question',
			confirmButtonColor: '#4db800',
			confirmButtonText: localString('Yes'),
            cancelButtonText: localString('Cancel'),
            cancelButtonColor: '#d33',
			showCancelButton: true,
		}).then(swalResult => {
			if(swalResult.isConfirmed){
				if(!serviceMode || !serviceMode.ServiceTypeID) {
					Swal.fire({  
						icon: 'error',  
						text: localString('Something went wrong'),
					});
					return;
				}
				if(([SERVICETYPE.CLAIM, SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].includes(serviceMode.ServiceTypeID)) || 
					(modeType.ModeTypeID == 2 && [SERVICETYPE.PICK_UP, SERVICETYPE.CARRY_IN, SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE, SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE, SERVICETYPE.CLAIM_ONSITE, SERVICETYPE.ADVANCE_EXCHANGE].includes(serviceMode.ServiceTypeID))
				) {
					createGenericRequest(); //Will be used for all in future
				}else if(serviceMode.ServiceTypeID == SERVICETYPE.PICK_UP) {
					schedulePickUpRequest();
				}else if(serviceMode.ServiceTypeID == SERVICETYPE.CARRY_IN) {
					scheduleCarryInRequest();
				}else if(serviceMode.ServiceTypeID == SERVICETYPE.ON_SITE) {
					scheduleOnsiteRequest();
				}else if(serviceMode.ServiceTypeID == SERVICETYPE.DOOR_TO_DOOR_REPLACEMENT && accessFn('RAISE_REPLACEMENT_REQUEST')){
					createReplacementRequest();
				}else {
					scheduleRequest();
				}
			}
		});
	}

	const onPayoutModeSubmit = (payoutAttri) => {
		setPayoutAttributes(payoutAttri);
        setCallRequestFunction(true);
    }

	useEffect(() => {
		if(callCreateGenericRequestApi) {
			createGenericRequest();
			setCallCreateGenericRequestApi(false);
		}
	}, [callCreateGenericRequestApi])

	const createGenericRequest = () => {
		let issues = [];
        let sDate, sTime, tDate, rDate;
		let consumerServiceRequestDetails = {};
		if(selectedIssues.length > 0 && showIssuesSelection){ // Create a common function for issues
			selectedIssues.map((val) => {
				let newIssueObj = {
					ProductSubCategoryIssueID: val.ProductSubCategoryIssueID,
					IssueText: val.Issue
				}
				if(val.IssueID === 55 && showOtherIssueTextBox){
					newIssueObj.IssueRemark = removeBreakline(otherIssueDescription);
				}
				issues.push(newIssueObj);
			});
		}
		if((serviceMode && [SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_CARRY_IN, SERVICETYPE.CLAIM_DROPOFF, SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE, SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE, SERVICETYPE.CLAIM_ONSITE, SERVICETYPE.ADVANCE_EXCHANGE].includes(serviceMode.ServiceTypeID))  ||
            (modeType && serviceMode && modeType.ModeTypeID == 2 && [SERVICETYPE.PICK_UP, SERVICETYPE.CARRY_IN].includes(serviceMode.ServiceTypeID)) ||
            ((coverage && coverage.SkipClaimApproval) && (((advanceChargeApplicable && paymentAmount > 0) || !getAdvanceDetailsAPICalled) || decisionMatrix))
        ) {
			if (claimForm && claimForm.RequiresTimeOfDamage && claimFormData && claimFormData.damageDate && claimFormData.damageTime) {
				sTime = moment(claimFormData.damageDate,'DD-MM-YYYY').format('YYYY-MM-DD') + 'T' + moment.utc(claimFormData.damageTime,'hh:mm').format('HH:mm:ss') + getTzOffset();
			}
			else if (claimForm && claimForm.RequiresDateOfDamage && claimFormData && claimFormData.damageDate) {
				sDate = moment(claimFormData.damageDate,'DD-MM-YYYY').format('YYYY-MM-DD');
			}
			if (claimForm && claimForm.RequiresDateOfTheft) {
				tDate = moment(claimFormData.theftDate,'DD-MM-YYYY').format('YYYY-MM-DD');
			}
			if (claimForm && claimForm.RequiresPoliceReportDate) {
				rDate = moment(claimFormData.policeDate,'DD-MM-YYYY').format('YYYY-MM-DD');
			}
			consumerServiceRequestDetails = {
				lossDateTime: claimForm && claimForm.RequiresTimeOfDamage && moment(sTime).isValid() ? sTime : claimForm && claimForm.RequiresDateOfDamage && moment(sDate).isValid() ? sDate : undefined,
				damageId: coverage && coverage.RequiresClaimForm && damageTypeObj && damageTypeObj.id ? damageTypeObj.id : 0,
				placeOfDevice: claimFormData && claimFormData.incidentDamagePlace ? claimFormData.incidentDamagePlace : undefined,
				deviceSwitchOn: claimFormData && claimFormData.deviceOn ? claimFormData.deviceOn : undefined,
				AlternateMobileNo: claimFormData && claimFormData.alternateNumber ? claimFormData.alternateNumber : undefined,
				dateOfTheft: claimForm && claimForm.RequiresDateOfTheft && moment(tDate).isValid() ? tDate : undefined,
				policeReportDate: claimForm && claimForm.RequiresPoliceReportDate && moment(rDate).isValid() ? rDate : undefined,
				policeReportUrl: claimForm && claimForm.RequiresPoliceReportUrl ? claimFormData.policeFile : undefined,
				policeReportNumber: claimForm && claimForm.RequiresPoliceReportNumber ? claimFormData.policeNumber : undefined,
				placeOfTheft: claimForm && claimForm.RequiresPlaceOfTheft ? claimFormData.theftPlace : undefined,
				theftDetails: claimForm && claimForm.RequiresTheftDetails ? claimFormData.theftDetails : undefined,
				descriptionOfLoss: claimForm && claimForm.RequiresDamageDescriptionText ? claimFormData.damageDetails : undefined,
				placeOfDamage: claimForm && claimForm.RequiresPlaceOfDamage ? claimFormData.damagePlace : undefined,
			};
		}
		if(walkIn) { 
			setIsWarrantyApplicable(isDeviceWarrantyApplicable);
		} else {
			setIsWarrantyApplicable(false);
		}
		let partnerId, partnerServiceLocationId, dropOffCenter;
		if(serviceMode && [SERVICETYPE.PICK_UP, SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].includes(serviceMode.ServiceTypeID)) {
			partnerId = secondryAddressServiceAvailabilityData && secondryAddressServiceAvailabilityData.dropOffServicePartner ? secondryAddressServiceAvailabilityData.dropOffServicePartner.PartnerID : serviceAvailabilityData && serviceAvailabilityData.dropOffServicePartner && serviceAvailabilityData.dropOffServicePartner.PartnerID;
			partnerServiceLocationId = secondryAddressServiceAvailabilityData && secondryAddressServiceAvailabilityData.dropOffServicePartner? secondryAddressServiceAvailabilityData.dropOffServicePartner.PartnerServiceLocationID : serviceAvailabilityData && serviceAvailabilityData.dropOffServicePartner && serviceAvailabilityData.dropOffServicePartner.PartnerServiceLocationID;
			if([SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].includes(serviceMode.ServiceTypeID)) {
				dropOffCenter = serviceLocation;
			}
		}else {
			partnerId = serviceLocation && serviceLocation.PartnerID;
			partnerServiceLocationId = serviceLocation && serviceLocation.PartnerServiceLocationID;
		}
		let data = {
			SoldPlanID: plan ? plan.SoldPlanID : undefined,
			PlanID: plan ? plan.PlanID : undefined,
			ConsumerID: consumerId,
			// CountryID: self.checkConsumerResult ? self.checkConsumerResult.CountryID : self.consumer ? self.consumer.CountryID : undefined,
			CountryID: consumerObj ? consumerObj.countryID : undefined,
            CountryCode: selectedCountryData ? selectedCountryData.CountryCode : undefined,
			ConsumerProductID: consumerProduct.ConsumerProductID,
			ServiceTypeID: serviceMode && (modeType && modeType.ModeTypeID == 2 && [SERVICETYPE.PICK_UP, SERVICETYPE.CARRY_IN, SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_CARRY_IN, SERVICETYPE.RECYCLE_DROP_OFF].includes(serviceMode.ServiceTypeID) ? SERVICETYPE.CLAIM : serviceMode.ServiceTypeID),
			// IncidentDate: sDate,
			consumerServiceRequestDetails : consumerServiceRequestDetails,
            SoldPlanCoverageID: coverage && coverage.SoldPlanCoverageID ? coverage.SoldPlanCoverageID : undefined,
            PlanCoverageID: coverage && coverage.PlanCoverageID ? coverage.PlanCoverageID : undefined,
            RequiresClaimForm: coverage && coverage.RequiresClaimForm != undefined ? coverage.RequiresClaimForm : undefined,
			ConsumerServiceRequest: {
				Address: address && address.Address,
				AddressType: address && address.AddressType,
				BrandID: consumerProduct.BrandID,
				ConsumerFavouriteLocationID:address && address.ConsumerFavouriteLocationID,
				ConsumerID: consumerId,
				// CountryID: self.checkConsumerResult ? self.checkConsumerResult.CountryID : self.consumer ? self.consumer.CountryID : undefined,
				CountryID: consumerObj ? consumerObj.countryID : undefined,
                CountryCode: selectedCountryData ? selectedCountryData.CountryCode : undefined,
				ConsumerProductID: consumerProduct.ConsumerProductID,
				DeliveryMode: deliveryMode,
				IsNonPartner: 0,
				IsUnderRepair: false,
				IsUrgent: false,
				Landmark: address && address.Landmark,
				Lat: address && address.Lat,
				Lng: address && address.Lng,
				PartnerID: partnerId,
				PartnerServiceLocationID: partnerServiceLocationId,
				ClientServiceLocationID: serviceLocation ? serviceLocation.ClientServiceLocationID : undefined,
				ClientServiceLocationDetails: serviceLocation && serviceLocation.ClientServiceLocationID ? serviceLocation : undefined,
				ProductSubCategoryID: consumerProduct.ProductSubCategoryID,
				BrandID: consumerProduct.BrandID,
				ProductID: consumerProduct.ProductID,
				ScheduledDateTime: scheduleDate && scheduleDate.date && moment(scheduleDate.date).utc(),
				ScheduledFromTime: slot && slot.StartTimeVal,
				ScheduledToTime: slot && slot.EndTimeVal,
				ExternalTimezoneString: serviceLocation && serviceLocation.TimezoneString ? serviceLocation.TimezoneString : undefined,
				ServiceRequestType: "Now",
				//ConsumerServiceRequestID: 0, encryption problem
				ServiceTypeID: serviceMode && (serviceMode.ServiceTypeID == SERVICETYPE.PICK_UP ? SERVICETYPE.CLAIM_PICK_UP : serviceMode.ServiceTypeID == SERVICETYPE.CARRY_IN ? SERVICETYPE.CLAIM_CARRY_IN : serviceMode.ServiceTypeID),
				isCardHoldSkipped: serviceMode?.isCardHoldSkipped ? serviceMode.isCardHoldSkipped : undefined,
				deliveryAddress: secondaryAddress,
				DropOffCenter: dropoffLocation,
				walkin: walkIn,
                State: address && address.State ? address.State : undefined,
                StateID : address && address.StateID ? address.StateID : undefined,
                City: address && address.City ? address.City : undefined,
				District: address && address.District ? address.District : undefined,
                AlternateContactDetails: {
					"ConsumerEmailID": secContactDetails && secContactDetails.secondaryEmailID,
					"ConsumerMobileNo": secContactDetails && secContactDetails.secondaryMobileNo,
					"ConsumerName": secContactDetails && secContactDetails.secondaryName,
					"PhoneCode": secContactDetails && secContactDetails.secondaryDialCode ? '+' + secContactDetails.secondaryDialCode : undefined,
				},
                LogisticsName: logisticsName,
                LogisticsMobileNo: logisticsMobileNo,
                Attributes: payoutAttributes ? payoutAttributes : undefined,
				ExternalSuppressLogisticsRequest:isSupressLogisticsCutomerToASC,
				Consent:[],
			},
			tokenizeFlow:tokenizeFlow,
			chargeMaxDeductible:chargeMaxDeductible
		}
		if(dropOffCenter) {
            data.ConsumerServiceRequest.DropOffCenter = dropOffCenter;
        }

		if(issues && issues.length > 0 && data && data.ConsumerServiceRequest) {
			data.ConsumerServiceRequest.Issues = issues;
		}
        if(!selectedCountryData?.countryConfig?.SkipAdvance && 
		(coverage && coverage.SkipClaimApproval) && (((advanceChargeApplicable && paymentAmount > 0) || !getAdvanceDetailsAPICalled) || decisionMatrix)) {
            data.SkipClaimApproval = coverage.SkipClaimApproval;
			data.IncidentDate = moment(sDate).isValid() ? sDate : undefined;
			if (!isSubscriptionEnabled) {
				data.ServiceTypeID = SERVICETYPE.CLAIM;
				data.ConsumerServiceRequest.ServiceTypeID = SERVICETYPE.CLAIM;
				advanceChargeApplicable && (data.AdvanceChargeApplicable = true);
			}
            isSubscriptionEnabled && (data.isSubscriptionEnabled = true);
            decisionMatrix && (data.DecisionMatrix = true);
        }

		if(!decisionMatrix && serviceMode && [SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE].includes(serviceMode.ServiceTypeID) && data.ConsumerServiceRequest.Attributes && data.ConsumerServiceRequest.Attributes.length > 0) {
            serviceAvailability && serviceAvailability.BuyoutAmount && data.ConsumerServiceRequest.Attributes.push({
                AttrName: 'BUYOUT_AMOUNT',
                AttrValue: serviceAvailability.BuyoutAmount
            });
            data.ConsumerServiceRequest.PartnerServiceLocationID = serviceAvailability.buyoutDetails && serviceAvailability.buyoutDetails.PartnerServiceLocationID;
        }

        if(serviceMode && [SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].includes(serviceMode.ServiceTypeID)) {
            data.ConsumerServiceRequest.PartnerServiceLocationID = serviceAvailability.replacementDetails && serviceAvailability.replacementDetails.PartnerServiceLocationID;
        }

		if(serviceMode && [SERVICETYPE.ADVANCE_EXCHANGE].includes(serviceMode.ServiceTypeID)) {
            data.ConsumerServiceRequest.PartnerServiceLocationID = serviceAvailability.advanceExchangeDetails && serviceAvailability.advanceExchangeDetails.PartnerServiceLocationID;
            data.ConsumerServiceRequest.PartnerID = serviceAvailability.advanceExchangeDetails && serviceAvailability.advanceExchangeDetails.PartnerID;
        }
		if(legalConfigsList && legalConfigsList.length > 0) {
			data.ConsumerServiceRequest.Consent = legalConfigsList.map((data) => ({
				ApplicationLegalConfigID: data.ApplicationLegalConfigID,
				ConsumerID: consumerId,
				Attributes: [
					{
						AttributeType: "imeiOrSerial",
						AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
					},
				],
				ConsentText: data.ConsentText,
				LegalConfigCode: data.LegalConfigCode,
				Selected:data.Selected,
				UnselectedText:data.UnselectedText
			}));
		}
		if(legalConfigsListForCoverage && legalConfigsListForCoverage.length > 0) {
			data.Consent = legalConfigsListForCoverage.map((data) => ({
				ApplicationLegalConfigID: data.ApplicationLegalConfigID,
				ConsumerID: consumerId,
				Attributes: [
					{
						AttributeType: "imeiOrSerial",
						AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
					},
				],
				ConsentText: data.ConsentText,
				LegalConfigCode: data.LegalConfigCode,
				Selected:data.Selected,
				UnselectedText:data.UnselectedText
			}));
		}

		if(serviceMode && [SERVICETYPE.CLAIM_ONSITE].includes(serviceMode.ServiceTypeID) && serviceAvailability && serviceAvailability.OnsiteDetails && serviceAvailability.OnsiteDetails.CLAIM_ONSITE) {
            data.ConsumerServiceRequest.PartnerServiceLocationID = serviceAvailability.OnsiteDetails.CLAIM_ONSITE.PartnerServiceLocationID;
            data.ConsumerServiceRequest.PartnerID = serviceAvailability.OnsiteDetails.CLAIM_ONSITE.PartnerID;
            data.PartnerServiceLocationID = serviceAvailability.OnsiteDetails.CLAIM_ONSITE.PartnerServiceLocationID;
            data.PartnerID = serviceAvailability.OnsiteDetails.CLAIM_ONSITE.PartnerID;
        }

        if(address) {
			data.Zipcode = address.PinCode ? address.PinCode : address.Zipcode;
			data.ZipCode = address.PinCode ? address.PinCode : address.Zipcode;
			data.PinCode = address.PinCode ? address.PinCode : address.Zipcode;
        }
        if(selectedCountryData && selectedCountryData.countryConfig.SkipAdvance){
            data.AdvanceChargeApplicable = advanceChargeApplicable ? advanceChargeApplicable : false;
            if([SERVICETYPE.PICK_UP, SERVICETYPE.DROPOFF].includes(serviceMode.ServiceTypeID) && (modeType.ModeTypeID == 2) && ((advanceChargeApplicable && paymentAmount > 0) || !getAdvanceDetailsAPICalled)) {
                data.skipLogistics = true;
            }
        }
		if(consentConfirmed && warrantyConsent) {
            data.Consent.push({
                ApplicationLegalConfigID: warrantyConsent && warrantyConsent.ApplicationLegalConfigID,
                ConsentText: warrantyConsent && warrantyConsent.legalConfig && warrantyConsent.legalConfig.Text,
                ConsumerID: consumerId,
                Attributes: [{
                    AttributeType: "imeiOrSerial",
                    AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
                }],
				Selected:true
            })
        }
		if(isEmptyBoxServiceable && [SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].includes(serviceMode.ServiceTypeID)) {
            data.ConsumerServiceRequest.EmptyBoxLocationID = emptyBoxLocationID;
            data.isEmptyBoxServiceable = true;
        }
		if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE, SERVICETYPE.ADVANCE_EXCHANGE].includes(serviceMode.ServiceTypeID)) {
			if(replacementOptions && replacementOptions.length) {
				data.ConsumerServiceRequest.DeviceOptions = replacementOptions;
            	data.ConsumerServiceRequest.SelectedReplacementOption = selectedReplacementOption?.Option;
			}
			if(isAdvanceExchangeApplicable){
                data.ConsumerServiceRequest.DefectivePartnerServiceLocationID = defectivePartnerServiceLocationID;
			    data.isAdvanceExchangeApplicable = true;
            }
		}
		if(data.ConsumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM && (!data.SoldPlanCoverageID || !data.PlanCoverageID)) {
			Swal.fire({  
  				text: localString('An error occurred while raising request, please verify the details and try again.'),
				icon: 'error',  
			});
			return;
		}
		if ([SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_CARRY_IN, SERVICETYPE.CLAIM_DROPOFF].indexOf(data.ConsumerServiceRequest.ServiceTypeID) > -1 && (!data.ConsumerServiceRequest.ScheduledFromTime || !data.ConsumerServiceRequest.ScheduledToTime)) {
			if( [SERVICETYPE.CLAIM_CARRY_IN].indexOf(data.ConsumerServiceRequest.ServiceTypeID) > -1){
				Swal.fire({  
					text: localString('An error occurred while raising request, please verify the details and try again.'),
					icon: 'error',  
				});
				return;
			}
			if(serviceMode.logisticsSuppressed || isSupressLogisticsCutomerToASC || [SERVICETYPE.CLAIM_DROPOFF, SERVICETYPE.DROPOFF].includes(serviceMode.ServiceTypeID)){
				data.ConsumerServiceRequest.ScheduledFromTime = "09:00:00";
				data.ConsumerServiceRequest.ScheduledToTime = "14:00:00";
				data.ConsumerServiceRequest.ScheduledDateTime = moment.utc().format();
			}
			
		}
		setLoader(true);
		apiCall({
			url: 'createRequest',
			data: data,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					onScheduleRequestSuccess(result);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const schedulePickUpRequest = () => {
		setLoader(true); 
		let issues = [];
		if(selectedIssues.length > 0 && showIssuesSelection){ // Create a common function for issues
			selectedIssues.map((val) => {
				let newIssueObj = {
					ProductSubCategoryIssueID: val.ProductSubCategoryIssueID,
					IssueText: val.Issue
				}
				if(val.IssueID === 55 && showOtherIssueTextBox){
					newIssueObj.IssueRemark = removeBreakline(otherIssueDescription);
				}
				issues.push(newIssueObj);
			});
		}

        let pickupdropRequestWithLogisticsObj = {
            Address: address && address.Address,
            AddressType: address && address.AddressType,
            ConsumerFavouriteLocationID: address && address.ConsumerFavouriteLocationID,
            Landmark: address && address.Landmark,
            Lat: address && address.Lat,
            Lng: address && address.Lng,
            CountryID: address ? address.CountryID : '',
            ProductUniqueID: consumerProduct.Product,
            ScheduledDateTime: scheduleDate && moment(scheduleDate.date).utc(),
            ScheduledFromTime: slot && slot.StartTimeVal,
            ScheduledToTime: slot && slot.EndTimeVal,
            DeliveryMode: deliveryMode,
            IsUrgent: false,
            PartnerID: serviceAvailability && serviceAvailability.PartnerID,
            PartnerServiceLocationID: serviceAvailability && serviceAvailability.PartnerServiceLocationID,
            ServiceTypeID: serviceMode && serviceMode.ServiceTypeID,
            Issues: issues,
            Documents: [],
            ConsumerID: consumerId,
            ConsumerProductID: consumerProduct.ConsumerProductID,
            ProductSubCategoryID: consumerProduct.ProductSubCategoryID, //Extra params
            BrandID: consumerProduct.BrandID, //Extra params
            ProductID: consumerProduct.ProductID, //Extra params,
            Description: otherIssueDescription ? removeBreakline(otherIssueDescription) : undefined,
            State: address && address.State,
            StateID : address && address.StateID,
            City: address && address.City,
			District: address && address.District ? address.District : undefined,
			deliveryAddress: secondaryAddress,
			AlternateContactDetails: {
				"ConsumerEmailID": secContactDetails && secContactDetails.secondaryEmailID,
				"ConsumerMobileNo": secContactDetails && secContactDetails.secondaryMobileNo,
				"ConsumerName": secContactDetails && secContactDetails.secondaryName,
				"PhoneCode": secContactDetails && secContactDetails.secondaryDialCode ? '+' + secContactDetails.secondaryDialCode : undefined,
            },
            LogisticsName: logisticsName,
            LogisticsMobileNo: logisticsMobileNo,
			CountryCode: selectedCountryData ? selectedCountryData.CountryCode : undefined,
			ExternalSuppressLogisticsRequest:isSupressLogisticsCutomerToASC,
			Consent:[],
			tokenizeFlow:tokenizeFlow,
			maxDeductible:chargeMaxDeductible
        }
        if(qFilter && qFilter.originLocation && qFilter.originLocation.length > 0){
            pickupdropRequestWithLogisticsObj.OriginServiceLocationID = qFilter.originLocation[0];
        }
        if(!skipZipCodeCheck){
          pickupdropRequestWithLogisticsObj.Zipcode = address.PinCode ? address.PinCode : address.Zipcode
        }
		if(serviceMode.logisticsSuppressed || isSupressLogisticsCutomerToASC){
			pickupdropRequestWithLogisticsObj.ScheduledFromTime = "09:00:00";
			pickupdropRequestWithLogisticsObj.ScheduledToTime = "14:00:00";
			pickupdropRequestWithLogisticsObj.ScheduledDateTime = moment.utc().format();
		}
		if(legalConfigsList && legalConfigsList.length > 0) {
			pickupdropRequestWithLogisticsObj.Consent = legalConfigsList.map((data) => ({
				ApplicationLegalConfigID: data.ApplicationLegalConfigID,
				ConsumerID: consumerId,
				Attributes: [
					{
						AttributeType: "imeiOrSerial",
						AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
					},
				],
				ConsentText: data.ConsentText,
				LegalConfigCode: data.LegalConfigCode,
				Selected:data.Selected,
				UnselectedText:data.UnselectedText
			}));
		}
		apiCall({
			url: 'PickupdropRequestWithLogistics',
			data: pickupdropRequestWithLogisticsObj,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					onScheduleRequestSuccess(result);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const scheduleCarryInRequest = () => {
		setLoader(true); 
		let issues = [];
        if(walkIn) {
			setIsWarrantyApplicable(isDeviceWarrantyApplicable);
        }else {
			setIsWarrantyApplicable(false);
        }
        if(consumerProduct.IsUnderWarranty){
			setIsWarrantyApplicable(true);
        }else {
			setIsWarrantyApplicable(false);
        }

		if(selectedIssues.length > 0 && showIssuesSelection){ // Create a common function for issues
			selectedIssues.map((val) => {
				let newIssueObj = {
					ProductSubCategoryIssueID: val.ProductSubCategoryIssueID,
					IssueText: val.Issue
				}
				if(val.IssueID === 55 && showOtherIssueTextBox){
					newIssueObj.IssueRemark = removeBreakline(otherIssueDescription);
				}
				issues.push(newIssueObj);
			});
		}

        let carryInRequestObj = {
			ServiceRequestType: 'Now',
			ScheduledDateTime: scheduleDate && moment(scheduleDate.date).utc(),
			ScheduledFromTime: slot && slot.StartTimeVal,
			ScheduledToTime: slot && slot.EndTimeVal,
			ExternalTimezoneString: serviceLocation && serviceLocation.TimezoneString ? serviceLocation.TimezoneString : undefined,
			BrandID: consumerProduct.BrandID,
			IsNonPartner: serviceLocation && serviceLocation.IsNonPartner,
			PartnerID: serviceLocation && serviceLocation.PartnerID,
			PartnerServiceLocationID: serviceLocation && serviceLocation.PartnerServiceLocationID,
			ProductSubCategoryID: consumerProduct.ProductSubCategoryID,
			ProductID: consumerProduct.ProductID,
			ServiceTypeID: serviceMode && serviceMode.ServiceTypeID,
			Documents: [],
			Issues: issues,
			ConsumerID: consumerId,
			ConsumerProductID: consumerProduct.ConsumerProductID,
			walkin: walkIn,
			Description: otherIssueDescription ? removeBreakline(otherIssueDescription) : undefined,
			CountryID: consumerObj ? consumerObj.countryID : undefined,
			CountryCode: selectedCountryData ? selectedCountryData.CountryCode : undefined,
			AlternateContactDetails: {
				"ConsumerEmailID": secContactDetails && secContactDetails.secondaryEmailID,
				"ConsumerMobileNo": secContactDetails && secContactDetails.secondaryMobileNo,
				"ConsumerName": secContactDetails && secContactDetails.secondaryName,
				"PhoneCode": secContactDetails && secContactDetails.secondaryDialCode ? '+' + secContactDetails.secondaryDialCode : undefined,
			},
			Consent:[],
			tokenizeFlow:tokenizeFlow,
			chargeMaxDeductible:chargeMaxDeductible
		};
        if(singleServiceLocation) {
			carryInRequestObj.noAddress = true;
        }else {
			carryInRequestObj.Address = address && address.Address;
			carryInRequestObj.Landmark = address && address.Landmark;
			carryInRequestObj.ConsumerFavouriteLocationID = address && address.ConsumerFavouriteLocationID;
			carryInRequestObj.Lat = address && address.Lat;
			carryInRequestObj.Lng = address && address.Lng;
			carryInRequestObj.State = address && address.State;
			carryInRequestObj.StateID  = address && address.StateID;
			carryInRequestObj.City = address && address.City;
			carryInRequestObj.District = address && address.District ? address.District : undefined;
			if(!skipZipCodeCheck){
				carryInRequestObj.Zipcode = address.PinCode ? address.PinCode : address.Zipcode ? address.Zipcode : null;
			}
        }

        //for the GSX Flow while raising request in pupa for carryin
        if(accessFn('GSX_FLOW')){
			carryInRequestObj.Address = address && address.Address;
			carryInRequestObj.ConsumerFavouriteLocationID = address && address.ConsumerFavouriteLocationID;
			carryInRequestObj.Landmark = address && address.Landmark;
			carryInRequestObj.Lat = address && address.Lat;
			carryInRequestObj.Lng = address && address.Lng;
			carryInRequestObj.Zipcode = address.PinCode ? address.PinCode : address.Zipcode;
			carryInRequestObj.IsNonPartner = 0;
			carryInRequestObj.noAddress = false;
        }
		if(legalConfigsList && legalConfigsList.length > 0) {
			carryInRequestObj.Consent = legalConfigsList.map((data) => ({
				ApplicationLegalConfigID: data.ApplicationLegalConfigID,
				ConsumerID: consumerId,
				Attributes: [
					{
						AttributeType: "imeiOrSerial",
						AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
					},
				],
				ConsentText: data.ConsentText,
				LegalConfigCode: data.LegalConfigCode,
				Selected:data.Selected,
				UnselectedText:data.UnselectedText
			}));
		}
        carryInRequestObj.app = 'WebApp';
        carryInRequestObj.source = 'WebApp';
		apiCall({
			url: 'carryInRequest',
			data: carryInRequestObj,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					onScheduleRequestSuccess(result);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const scheduleOnsiteRequest = () => {
		setLoader(true);
		let issues = [];
		if(selectedIssues.length > 0 && showIssuesSelection){ // Create a common function for issues
			selectedIssues.map((val) => {
				let newIssueObj = {
					ProductSubCategoryIssueID: val.ProductSubCategoryIssueID,
					IssueText: val.Issue
				}
				if(val.IssueID === 55 && showOtherIssueTextBox){
					newIssueObj.IssueRemark = removeBreakline(otherIssueDescription);
				}
				issues.push(newIssueObj);
			});
		}

        let scheduleRequestObj = {
            Address: address && address.Address,
            AddressType: address && address.AddressType,
            Issues: issues,
            Landmark: address && address.Landmark,
            Lat: address && address.Lat,
            Lng: address && address.Lng,
            ConsumerFavouriteLocationID: address && address.ConsumerFavouriteLocationID,
            ScheduledDateTime:scheduleDate && moment(scheduleDate.date).utc(),
            ScheduledFromTime: slot && slot.StartTimeVal,
            ScheduledToTime: slot && slot.EndTimeVal,
            ServiceRequestType: "Now",
            //ConsumerServiceRequestID: 0, encryption problem
            IsUrgent: false,
            ServiceTypeID: serviceMode && serviceMode.ServiceTypeID,
            //Zipcode: address && address.Zipcode,
            IsUnderWarranty: consumerProduct.IsUnderWarranty,
            IsWarrantyApplicable: consumerProduct.IsWarrantyApplicable,
            ConsumerID: consumerId,
            Documents: [],
            ConsumerProductID: consumerProduct.ConsumerProductID,
            ProductSubCategoryID: consumerProduct.ProductSubCategoryID, //Extra params
            BrandID: consumerProduct.BrandID, //Extra params
            ProductID: consumerProduct.ProductID, //Extra params
            State: address && address.State ? address.State : undefined,
            StateID : address && address.StateID ? address.StateID : undefined,
            City: address && address.City ? address.City : undefined,
			District: address && address.District ? address.District : undefined,
			CountryID: consumerObj ? consumerObj.countryID : undefined,
			CountryCode: selectedCountryData ? selectedCountryData.CountryCode : undefined,
            AlternateContactDetails: {
				"ConsumerEmailID": secContactDetails && secContactDetails.secondaryEmailID,
				"ConsumerMobileNo": secContactDetails && secContactDetails.secondaryMobileNo,
				"ConsumerName": secContactDetails && secContactDetails.secondaryName,
				"PhoneCode": secContactDetails && secContactDetails.secondaryDialCode ? '+' + secContactDetails.secondaryDialCode : undefined,
            },
			IsWallMount: isWallMount,
			app: qFilter && qFilter.flowType && qFilter.flowType.requestRaisingFlow && qFilter.flowType.requestRaisingFlow.includes("REQ_RAISE_TURKEY") ? 'PupaWebApp' : 'WebApp',
			source: qFilter && qFilter.flowType && qFilter.flowType.requestRaisingFlow && qFilter.flowType.requestRaisingFlow.includes("REQ_RAISE_TURKEY") ? 'PupaWebApp' : 'WebApp',
			Consent:[],
			tokenizeFlow:tokenizeFlow,
			chargeMaxDeductible:chargeMaxDeductible
        };

        if(!skipZipCodeCheck){
			scheduleRequestObj.Zipcode = address.PinCode ? address.PinCode : address.Zipcode ? address.Zipcode : null;
        }

        let requestHeaders = {};
        if(qFilter && qFilter.flowType && qFilter.flowType.requestRaisingFlow && qFilter.flowType.requestRaisingFlow.length > 0 &&  qFilter.flowType.requestRaisingFlow.indexOf("REQ_RAISE_CPR") > -1){
            requestHeaders.app = (qFilter && qFilter.flowType && qFilter.flowType.requestRaisingFlow && qFilter.flowType.requestRaisingFlow.indexOf("REQ_RAISE_CPR") > -1) ? 'CprDashboard' : 'WebApp';
            requestHeaders.source = (qFilter && qFilter.flowType && qFilter.flowType.requestRaisingFlow && qFilter.flowType.requestRaisingFlow.indexOf("REQ_RAISE_CPR") > -1) ? 'CprDashboard' : 'WebApp';
        }
		if(legalConfigsList && legalConfigsList.length > 0) {
			scheduleRequestObj.Consent = legalConfigsList.map((data) => ({
				ApplicationLegalConfigID: data.ApplicationLegalConfigID,
				ConsumerID: consumerId,
				Attributes: [
					{
						AttributeType: "imeiOrSerial",
						AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
					},
				],
				ConsentText: data.ConsentText,
				LegalConfigCode: data.LegalConfigCode,
				Selected:data.Selected,
				UnselectedText:data.UnselectedText
			}));
		}

		apiCall({
			url: 'scheduleRequest',
			data: scheduleRequestObj,
			headers: requestHeaders ? requestHeaders : undefined,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					onScheduleRequestSuccess(result);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const createReplacementRequest = () => {
		setLoader(true); 
		let ReplacementReason = [];
		if(selectedReasons.length > 0){ // Create a common function for issues
			selectedReasons.map((val) => {
				let newReasonObj = {
					ReturnReasonID: val.ReasonID,
                	ReturnReason: val.Reason
				}
				if(val.ReturnReason == 'Others'){
					newReasonObj.reasonRemark = removeBreakline(otherReasonDescription);
				}
				ReplacementReason.push(newReasonObj);
			});
		}

        let requestObj = {
            Address: address && address.Address,
            AddressType: address && address.AddressType,
            ConsumerFavouriteLocationID:address && address.ConsumerFavouriteLocationID,
            ReplacementReason: ReplacementReason,
            Landmark: address && address.Landmark,
            Lat: address && address.Lat,
            Lng: address && address.Lng,
			Zipcode: address.PinCode ? address.PinCode : address.Zipcode,
            Zipcode:address && address.Zipcode,
            ScheduledDateTime: scheduleDate && moment(scheduleDate.date).utc(),
            ScheduledFromTime: slot && slot.StartTimeVal,
            ScheduledToTime: slot && slot.EndTimeVal,
            IsUrgent: false,
            ServiceTypeID: serviceMode && serviceMode.ServiceTypeID,
            IsUnderWarranty: consumerProduct && consumerProduct.IsUnderWarranty,
            ConsumerID: consumerId,
            Documents: [],
            ConsumerProductID: consumerProduct && consumerProduct.ConsumerProductID,
            ProductSubCategoryID: consumerProduct && consumerProduct.ProductSubCategoryID,
            BrandID: consumerProduct && consumerProduct.BrandID,
            ProductID: consumerProduct && consumerProduct.ProductID,
            PartnerID: serviceAvailability && serviceAvailability.doorToDoorDetail && serviceAvailability.doorToDoorDetail.PartnerID,
            DoorToDoorPincodeMappingID: serviceAvailability && serviceAvailability.doorToDoorDetail && serviceAvailability.doorToDoorDetail.DoorToDoorPincodeMappingID,
            CountryID: address && address.CountryID,
            IsClaimRequest: false,
            deliveryAddress: secondaryAddress,
			DeliveryMode: deliveryMode,
            ProductUniqueID: consumerProduct && consumerProduct.ProductUniqueID,
            PartnerServiceLocationID: serviceAvailability.doorToDoorDetail && serviceAvailability.doorToDoorDetail.PartnerServiceLocationID,
            State: address && address.State ? address.State : undefined,
            StateID : address && address.StateID ? address.StateID : undefined,
            City: address && address.City ? address.City : undefined,
			District: address && address.District ? address.District : undefined,
			CountryCode: selectedCountryData ? selectedCountryData.CountryCode : undefined,
			AlternateContactDetails: {
				"ConsumerEmailID": secContactDetails && secContactDetails.secondaryEmailID,
				"ConsumerMobileNo": secContactDetails && secContactDetails.secondaryMobileNo,
				"ConsumerName": secContactDetails && secContactDetails.secondaryName,
				"PhoneCode": secContactDetails && secContactDetails.secondaryDialCode ? '+' + secContactDetails.secondaryDialCode : undefined,
            },
			Consent:[],
			tokenizeFlow:tokenizeFlow,
			chargeMaxDeductible:chargeMaxDeductible
        };
		if(legalConfigsList && legalConfigsList.length > 0) {
			requestObj.Consent = legalConfigsList.map((data) => ({
				ApplicationLegalConfigID: data.ApplicationLegalConfigID,
				ConsumerID: consumerId,
				Attributes: [
					{
						AttributeType: "imeiOrSerial",
						AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
					},
				],
				ConsentText: data.ConsentText,
				LegalConfigCode: data.LegalConfigCode,
				Selected:data.Selected,
				UnselectedText:data.UnselectedText
			}));
		}
		apiCall({
			url: 'createReplacementRequest',
			data: requestObj,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					onScheduleRequestSuccess(result);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const scheduleRequest = () => {
		setLoader(true); 
		let issues = [];
		if(selectedIssues.length > 0 && showIssuesSelection){ // Create a common function for issues
			selectedIssues.map((val) => {
				let newIssueObj = {
					ProductSubCategoryIssueID: val.ProductSubCategoryIssueID,
					IssueText: val.Issue
				}
				if(val.IssueID === 55 && showOtherIssueTextBox){
					newIssueObj.IssueRemark = removeBreakline(otherIssueDescription);
				}
				issues.push(newIssueObj);
			});
		}

        let scheduleRequestObj = {
            Address: address.Address,
            AddressType: address.AddressType,
            ConsumerFavouriteLocationID: address.ConsumerFavouriteLocationID,
            Issues: issues,
            Landmark: address.Landmark,
            Lat: address.Lat,
            Lng: address.Lng,
            ScheduledDateTime: scheduleDate && moment(scheduleDate.date).utc(),
            ScheduledFromTime: slot && slot.StartTimeVal,
            ScheduledToTime: slot && slot.EndTimeVal,
            ServiceRequestType: "Now",
            //ConsumerServiceRequestID: 0, encryption problem
            IsUrgent: false,
            ServiceTypeID: serviceMode.ServiceTypeID,
            IsUnderWarranty: consumerProduct.IsUnderWarranty,
            ConsumerID: consumerId,
            Documents: [],
            ConsumerProductID: consumerProduct.ConsumerProductID,
            ProductSubCategoryID: consumerProduct.ProductSubCategoryID, //Extra params
            BrandID: consumerProduct.BrandID, //Extra params
            ProductID: consumerProduct.ProductID, //Extra params
            State: address && address.State ? address.State : undefined,
            StateID : address && address.StateID ? address.StateID : undefined,
            City: address && address.City ? address.City : undefined,
			District: address && address.District ? address.District : undefined,
            AlternateContactDetails: {
				"ConsumerEmailID": secContactDetails && secContactDetails.secondaryEmailID,
				"ConsumerMobileNo": secContactDetails && secContactDetails.secondaryMobileNo,
				"ConsumerName": secContactDetails && secContactDetails.secondaryName,
				"PhoneCode": secContactDetails && secContactDetails.secondaryDialCode ? '+' + secContactDetails.secondaryDialCode : undefined,
            },
            LogisticsName: logisticsName,
            LogisticsMobileNo: logisticsMobileNo,
			CountryID: consumerObj ? consumerObj.countryID : undefined,
			CountryCode: selectedCountryData ? selectedCountryData.CountryCode : undefined,
			Consent:[],
			tokenizeFlow:tokenizeFlow,
			chargeMaxDeductible:chargeMaxDeductible
        };

        if(!skipZipCodeCheck){
          scheduleRequestObj.Zipcode = address.PinCode ? address.PinCode : address.Zipcode;
        }
        scheduleRequestObj.IsWallMount = isWallMount;
		let headers = {
			app: 'WebApp',
			source: 'WebApp'
		}
		if(legalConfigsList && legalConfigsList.length > 0) {
			scheduleRequestObj.Consent = legalConfigsList.map((data) => ({
				ApplicationLegalConfigID: data.ApplicationLegalConfigID,
				ConsumerID: consumerId,
				Attributes: [
					{
						AttributeType: "imeiOrSerial",
						AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
					},
				],
				ConsentText: data.ConsentText,
				LegalConfigCode: data.LegalConfigCode,
				Selected:data.Selected,
				UnselectedText:data.UnselectedText
			}));
		}
		apiCall({
			url: 'scheduleRequest',
			data: scheduleRequestObj,
			headers: headers,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					onScheduleRequestSuccess(result);
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const onScheduleRequestSuccess = (result) => {
		let data = result.data;
        let viewButton = true;
        let ScheduledDateTime = data.ScheduledDateTime ? data.ScheduledDateTime : data.ConsumerServiceRequest && data.ConsumerServiceRequest.ScheduledDateTime ? data.ConsumerServiceRequest.ScheduledDateTime : '';

        if(data.PartnerServiceLocationID && qFilter.servicelocations && qFilter.servicelocations.length > 0 && qFilter.servicelocations.indexOf(data.PartnerServiceLocationID) == -1){
          	viewButton = false;
        }

        let requestArrObj = [data.ScheduledFromTime ? {
            name: localString("Slot Time"),
            value: moment(data.ScheduledFromTime, 'HH:mm:ss').format('hh:mm a') + ' - ' + moment(data.ScheduledToTime, 'HH:mm:ss').format('hh:mm a')
        } : '', ScheduledDateTime ? {
            name: localString("Schedule Date"),
            value: getLocaleDateString(ScheduledDateTime, defaultCountryLocale)
        } : '', {
            name: localString("Reference ID"),
            value: data.ReferenceID
        }];

        let requesthtml = '<h3>'+localString("The request was raised successfully")+'.</h3><div class="table-responsive">'+
                          (data.PaymentStatusMessage ? '<h4 style="margin-bottom:15px;margin-top:15px">'+data.PaymentStatusMessage+'</h4>' : '');
        requesthtml += '<table class="table">';
        requesthtml += '<tbody>';


        for (let i = requestArrObj.length - 1; i >= 0; i--) {
            if(requestArrObj[i]) {
                requesthtml += '<tr>';
                requesthtml += '<td>';
                requesthtml += requestArrObj[i].name
                requesthtml += '</td>';
                requesthtml += '<td>';
                requesthtml += requestArrObj[i].value;
                requesthtml += '</td>';
                requesthtml += '</tr>';
            }
        }

        requesthtml += '</tbody>';
        requesthtml += '</table>'
        requesthtml += '</div>';

		Swal.fire({
			html: requesthtml,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#4C10C1',
            confirmButtonText: localString("View"),
            cancelButtonColor: '#d33',
            cancelButtonText: localString("Raise new request")

		}).then(swalResult => {
			if(swalResult.isConfirmed){
                window.location.href = baseUrl + viewUrl + result.data.ConsumerServiceRequestID;
			}else {
				window.location.reload();
			}
		});
	}

	// const checkToShowAddressList = () => {
	// 	setShowAddress(true);
	// }

	const claimFormSubmit = () => {
		Swal.fire({
			title: '',
            text: localString('Are you sure you want to continue') + '?',
			icon: 'question',
			confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
			showCancelButton: true,
		}).then(swalResult => {
			if(swalResult.isConfirmed){
				setLoader(true); 
				let sDate = '';
				let tDate = '';
                if (claimForm && claimForm.RequiresTimeOfDamage) {
                    // sDate = moment($('#damageDate').val(),'DD-MM-YYYY').format('YYYY-MM-DD') + 'T' + moment.utc($('#damageTime').val(),'hh:mm').format('HH:mm:ss') + getTzOffset();
                    sDate = claimFormData && moment(claimFormData.damageDate,'DD-MM-YYYY').format('YYYY-MM-DD') + 'T' + moment.utc(claimFormData.damageTime,'hh:mm').format('HH:mm:ss') + getTzOffset();
                }
                else if (claimForm && claimForm.RequiresDateOfDamage) {
                    sDate = claimFormData && moment(claimFormData.damageDate,'DD-MM-YYYY').format('YYYY-MM-DD');
                }
				if (claimForm && claimForm.RequiresDateOfTheft) {
                    tDate = claimFormData && moment(claimFormData.theftDate,'DD-MM-YYYY').format('YYYY-MM-DD');
				}
				//TODO: discuss this API
				let data = {
					"SoldPlanID": plan && plan.SoldPlanID,
					"SoldPlanCoverageID": coverage && coverage.SoldPlanCoverageID,
					"ConsumerProductID": consumerProduct && consumerProduct.ConsumerProductID,
					"ConsumerID": consumerId,
					"ServiceTypeID": SERVICETYPE.CLAIM,
					"consumerServiceRequestDetails": {
						"lossDateTime": sDate != '' ? sDate : undefined,
						"damageId": coverage && coverage.RequiresClaimForm && damageTypeObj ? damageTypeObj.id : 0,
						"dateOfTheft": tDate != '' ? tDate : undefined,
					}
				};
				apiCall({
					url: 'checkAndRejectClaim',
					data: data,
					callBack: (result) => {
						setLoader(false); 
						if(result.success) {
							if(result.data && !result.data.ClaimRejected) {
								claimOrContinue();
							}else {
								Swal.fire({
									html: "<h3 style='margin-top:30px'>"+localString(result.msg)+"</h3>",
									text: localString('Are you sure you want to continue') + '?',
									icon: 'warning',
									confirmButtonText: localString("View"),
									cancelButtonText: localString("Raise new request"),
									showCancelButton: true,
									confirmButtonColor: '#4C10C1',
									cancelButtonColor: '#d33',
								}).then(res => {
									if(res.isConfirmed){
										window.location.href = baseUrl + viewUrl + result.data.ConsumerServiceRequestID;
									}else {
										window.location.reload();
									}
								});
							}
						}else {
							Swal.fire({  
								icon: 'error',  
								text: localString(displayError(result)),  
							});
						}
					},
					errorCB: (err) => {
						setLoader(false);
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(err)),  
						});
					}
				});
			}
		});
	}

	const claimOrContinue = () => {
		if(coverage && coverage.SkipClaimApproval) {
			checkHighRisk()
        }else {
			setCallRaiseNewClaimApi(true);
        }
	}

	const checkHighRisk = () => {
		setLoader(true); 
		let reqObj = {
			"ConsumerProductID": consumerProduct && consumerProduct.ConsumerProductID,
			"SoldPlanID": plan && plan.SoldPlanID,
			"SoldPlanCoverageID": coverage && coverage.SoldPlanCoverageID,
		};
		apiCall({
			url: 'checkForHighRiskRequest',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result && result.success) {
					if(result.data.highRiskRequest){
						setCallRaiseNewClaimApi(true);
					}else{
						getValueOfDecisionMatrix()
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: result && result.msg ? localString(result.msg) : localString("Something went wrong"), 
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}
 
	const getValueOfDecisionMatrix = () => {
		setLoader(true); 
		let data = {
            BrandID: consumerProduct.BrandID,
            ProductSubCategoryID: consumerProduct.ProductSubCategoryID,
            PlanCoverageID: coverage && coverage.PlanCoverageID,
            SoldPlanID: plan && plan.SoldPlanID,
            skipDecisionFormInputs: true,
            skipModes: true
        };
		apiCall({
			url: 'getValueOfDecisionMatrix',
			data: data,
			callBack: (result) => {
				setLoader(false); 
				if(result.success) {
					// onScheduleRequestSuccess(result);
					let decMatrix = result && result.data && result.data.IsDecisionMatrixEnabled;
					setDecisionMatrix(decMatrix);
                    if(decMatrix) {
                        // createGenericRequest();
						setCallCreateGenericRequestApi(true);
                    } else {
						getSkipAdvanceForPlan();
                    }
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const getSkipAdvanceForPlan = () => {
		setLoader(true); 
		let data = {
			brandID: consumerProduct?.BrandID,
			planCoverageID: coverage?.PlanCoverageID,
			planID: plan?.PlanID,
			countryID: consumerObj ? consumerObj.countryID : undefined,
        };
		apiCall({
			url: 'checkSubscriptionConfiguration',
			data: data,
			callBack: (result) => {
				setLoader(false); 
				if(result.success) {
					setIsSubscriptionEnabled(result.data.isSubscriptionEnabled);
					if(result.data.isSubscriptionEnabled || selectedCountryData?.countryConfig?.SkipAdvance) {
						setShowAddress(true);
					} else {
						checkAdvancePaymentRequired();
					}
                }else {
					Swal.fire({
						icon: 'error',  
						text: result.msg ? localString(result.msg) : localString("Something went wrong"),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const checkAdvancePaymentRequired = () => {
		let data = {
            SoldPlanID : plan && plan.SoldPlanID,
            ConsumerProductID : consumerProduct.ConsumerProductID,
            damageId : coverage && coverage.RequiresClaimForm && damageTypeObj ? damageTypeObj.id : 0,
            CountryID: consumerObj ? consumerObj.countryID : undefined,
            SoldPlanCoverageID: coverage && coverage.SoldPlanCoverageID
        };
        if(!data.SoldPlanCoverageID) {
			Swal.fire({  
				icon: 'error',  
				title: localString('Error'), 
				text: localString('An error occurred while raising request, please verify the details and try again.')
			});
			return
        }
		setLoader(true);
		apiCall({
			url: 'getAdvanceDetails',
			data: data,
			callBack: (result) => {
				setLoader(false); 
				if(result.success) {
					setGetAdvanceDetailsAPICalled(true);
					let responseData = result.data;
                    let advChargeApplicable = responseData.AdvanceChargeApplicable;
					setPaymentAmount(responseData.PaymentAmount);
					setAdvanceChargeApplicable(advChargeApplicable);
                    if(advChargeApplicable && responseData.PaymentAmount > 0) {
						setCallCreateGenericRequestApi(true);
                    }else {
						setShowAddress(true);
                    }
                }else {
					Swal.fire({
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	useEffect(() => {
		if(callRaiseNewClaimApi) {
			raiseNewClaim();
			setCallRaiseNewClaimApi(false);
		}
	}, [callRaiseNewClaimApi])

	const raiseNewClaim = () => {
		setLoader(true); 
		let issues = [];
		if(selectedIssues.length > 0 && showIssuesSelection){ // Create a common function for issues
			selectedIssues.map((val) => {
				let newIssueObj = {
					ProductSubCategoryIssueID: val.ProductSubCategoryIssueID,
					IssueText: val.Issue
				}
				if(val.IssueID === 55 && showOtherIssueTextBox){
					newIssueObj.IssueRemark = removeBreakline(otherIssueDescription);
				}
				issues.push(newIssueObj);
			});
		}

		let rDate, sDate, tDate;
		if (claimForm && claimForm.RequiresTimeOfDamage) {
			// sDate = moment($('#damageDate').val(),'DD-MM-YYYY').format('YYYY-MM-DD') + 'T' + moment.utc($('#damageTime').val(),'hh:mm').format('HH:mm:ss') + getTzOffset();
			sDate = claimFormData && moment(claimFormData.damageDate,'DD-MM-YYYY').format('YYYY-MM-DD') + 'T' + moment.utc(claimFormData.damageTime,'hh:mm').format('HH:mm:ss') + getTzOffset();
		}
		else if (claimForm && claimForm.RequiresDateOfDamage) {
			sDate = claimFormData && moment(claimFormData.damageDate,'DD-MM-YYYY').format('YYYY-MM-DD');
		}
		if (claimForm && claimForm.RequiresPoliceReportDate) {
			rDate = claimFormData && moment(claimFormData.policeDate,'DD-MM-YYYY').format('YYYY-MM-DD');
		}
		if (claimForm && claimForm.RequiresDateOfTheft) {
			tDate = claimFormData && moment(claimFormData.theftDate,'DD-MM-YYYY').format('YYYY-MM-DD');
		}
        let data = {
            "ConsumerID": consumerId,
            "ConsumerProductID": consumerProduct && consumerProduct.ConsumerProductID,
            "ServiceTypeID": SERVICETYPE.CLAIM,
            "SoldPlanID": plan && plan.SoldPlanID,
            "SoldPlanCoverageID": coverage && coverage.SoldPlanCoverageID,
			"SkipClaimApproval": coverage && coverage.SkipClaimApproval,
            "PlanCoverageID": coverage && coverage.PlanCoverageID,
			"RequiresClaimForm": coverage && coverage.RequiresClaimForm != undefined ? coverage.RequiresClaimForm : undefined,
            "Description" : claimFormData && claimFormData.damageDetails,
			"CountryID": consumerObj ? consumerObj.countryID : undefined,
			"CountryCode": selectedCountryData ? selectedCountryData.CountryCode : undefined,
            "consumerServiceRequestDetails" : {
				"Issues": issues,
                "lossDateTime": moment(sDate).isValid() ? sDate : undefined,
				"dateOfTheft": claimForm && claimForm.RequiresDateOfTheft && moment(tDate).isValid() ? tDate : undefined,
				"policeReportDate": claimForm && claimForm.RequiresPoliceReportDate && moment(rDate).isValid() ? rDate : undefined,
				"policeReportUrl": claimForm && claimForm.RequiresPoliceReportUrl ? claimFormData.policeFile : undefined,
				"policeReportNumber": claimForm && claimForm.RequiresPoliceReportNumber ? claimFormData.policeNumber : undefined,
				"placeOfTheft": claimForm && claimForm.RequiresPlaceOfTheft ? claimFormData.theftPlace : undefined,
				"theftDetails": claimForm && claimForm.RequiresTheftDetails ? claimFormData.theftDetails : undefined,
                "damageId" : coverage && coverage.RequiresClaimForm && damageTypeObj && damageTypeObj.id ? damageTypeObj.id : 0, // Doubt => mobileDamageTypeID,
				"descriptionOfLoss": claimForm && claimForm.RequiresDamageDescriptionText ? claimFormData.damageDetails : undefined,
				"placeOfDamage": claimForm && claimForm.RequiresPlaceOfDamage ? claimFormData.damagePlace : undefined,
				"locationOfDamage": claimForm && (claimForm.RequiresPlaceOfDamage || claimForm.RequiresPlaceOfTheft) ? claimFormData.locationOfDamage : undefined,
				"placeOfDevice": claimFormData && claimFormData.incidentDamagePlace ? claimFormData.incidentDamagePlace : undefined,
				"deviceSwitchOn": claimFormData && claimFormData.deviceOn ? claimFormData.deviceOn : undefined,
				"AlternateMobileNo": claimFormData && claimFormData.alternateNumber ? claimFormData.alternateNumber : undefined,
                "CountryID": consumerObj ? consumerObj.countryID : undefined,
				"CountryCode": selectedCountryData ? selectedCountryData.CountryCode : undefined,
            },
			AlternateContactDetails: {
				"ConsumerEmailID": secContactDetails && secContactDetails.secondaryEmailID,
				"ConsumerMobileNo": secContactDetails && secContactDetails.secondaryMobileNo,
				"ConsumerName": secContactDetails && secContactDetails.secondaryName,
				"PhoneCode": secContactDetails && secContactDetails.secondaryDialCode ? '+' + secContactDetails.secondaryDialCode : undefined,
			},
			Consent:[]
        }
		if(legalConfigsListForCoverage && legalConfigsListForCoverage.length > 0) {
			data.Consent = legalConfigsListForCoverage.map((data) => ({
				ApplicationLegalConfigID: data.ApplicationLegalConfigID,
				ConsumerID: consumerId,
				Attributes: [
					{
						AttributeType: "imeiOrSerial",
						AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
					},
				],
				ConsentText: data.ConsentText,
				LegalConfigCode: data.LegalConfigCode,
				Selected:data.Selected,
				UnselectedText:data.UnselectedText
			}));
		}
		if(consentConfirmed && warrantyConsent) {
            data.Consent.push({
                ApplicationLegalConfigID: warrantyConsent && warrantyConsent.ApplicationLegalConfigID,
                ConsentText: warrantyConsent && warrantyConsent.legalConfig && warrantyConsent.legalConfig.Text,
                ConsumerID: consumerId,
                Attributes: [{
                    AttributeType: "imeiOrSerial",
                    AttributeValue: consumerProduct && consumerProduct.ProductUniqueID,
                }],
				Selected:true
            })
        }
		apiCall({
			url: 'raiseNewClaim',
			data: data,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					Swal.fire({  
						html: `<h3 style='margin-top:30px'>${localString("Request Has Been Raised")}</h3>`,
						icon: 'success',
						confirmButtonColor: '#4C10C1',
						allowOutsideClick: false,
						showCancelButton: true,
						cancelButtonText: localString("Raise new request"),
						confirmButtonText: localString("View")
					}).then(swalResult => {
						if(swalResult.isConfirmed){
							window.location.href = baseUrl + viewUrl + result.data.ConsumerServiceRequestID;
						}else {
							window.location.reload();
						}
					});
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const FMIPcheck = () => {
		setLoader(true); 
		let reqObj = {
			BrandID: consumerProduct.BrandID,
			imeiOrSerial: imeiOrSerialNo,
			ConsumerID: consumerProduct.ConsumerID,
			ConsumerProductID: consumerProduct.ConsumerProductID
		};
		apiCall({
			url: 'checkFindMyiPhoneStatus',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					let fmiphone = result.data.FMIP;
					setFMIP(fmiphone);
					if(fmiphone){
						setShowFMIPModal(true);
					}else{
						setShowFMIPModal(false);
                        checkForLogisticsModal();
					}
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const checkForLogisticsModal = () => {
		if(serviceAvailability.isDedicatedDialog) {
			let vendorDetails;
			if (serviceAvailability && serviceAvailability.VendorList && serviceAvailability.VendorList.length > 0) {
				vendorDetails = serviceAvailability.VendorList[0]
			}
            let html = ''
            if(vendorDetails && vendorDetails.VendorInstructions && vendorDetails.VendorInstructions.InstructionTitle) {
                html  = '<p style = "text-align:left;padding-left:20px;margin-bottom:10px;">' + localString(vendorDetails.VendorInstructions.InstructionTitle) + '</p>'
                if (vendorDetails && vendorDetails.VendorInstructions && vendorDetails.VendorInstructions.InstructionTitle && vendorDetails.VendorInstructions.InstructionTitle.length > 0 ) {
                    html = html + '<ol style = "font-size:14px;line-height:1.4;">'
                    for (var iCount=0;iCount<vendorDetails.VendorInstructions.Instructions.length;iCount ++ ) {
                        html = html + '<li style = "text-align:left;margin-bottom:5px;">' + localString(vendorDetails.VendorInstructions.Instructions[iCount].text) + '</li>'
                    }
                    html = html + '</ol>'
                }
            }
			Swal.fire({  
				icon: 'warning',
				title: localString("Pickup Drop By")+ " " + (vendorDetails.DisplayName ? localString(vendorDetails.DisplayName) : 'our logistics partner'),
				html: html ? html : '',
				showCancelButton: false,
				showConfirmButton: true,
                confirmButtonText: localString('OK')
			}).then((result) => {
				checkAvailabilityForDropOff();
			});
		}else if(serviceAvailability.isChooseLogisticsPartner) {
			let vendorArr = serviceAvailability.VendorList;
			setVendorList(serviceAvailability.VendorList);
			let vendorObj
			vendorArr.map(a => {
				if(a.Priority == 1) {
					vendorObj = a;
				}
			})
			setVendor(vendorObj);
			setDeliveryMode(vendorObj ? vendorObj.VendorID : serviceAvailability.DeliveryMode)
			setShowLogisticsPartnerModal(true);
		}else {
            checkAvailabilityForDropOff();
			if(showSlotSelection) {
				// setScreenToViewDiv('scheduleDateAndTime');
			}
		}
	}

	const handleReplacementReasons = () => {
		hideSection(['Plans', 'CoverageType', 'Coverage', 'ClaimForm', 'DOP', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		setWalkIn(false);
		setShowReplacementReasons(true);
	}

	const showInstructions = () => {
		let data = showSecondaryAddress ? secondryAddressServiceAvailabilityData : serviceAvailabilityData;
		if(data && data.dropOffInstructions) {
			let dropOffDataObj = data.dropOffInstructions;
			setDropOffData(dropOffDataObj);
			if(dropOffDataObj && Object.keys(dropOffDataObj).length > 0) {
				setDropOffInstructionsTitle(dropOffDataObj.DropOffInstructionsTitle);
				setInstructionsArray(dropOffDataObj.DropOffInstructions);
			}
		}
		setShowInstructionsModal(true);
	}

	const checkUpdateConsumerProduct = () => {
		if((consumerProduct.IsUnderWarranty != isDeviceInWarranty) || (isDeviceInWarranty && (warrantyTillDateCopy && moment(warrantyTillDateCopy, getDateFormat(defaultCountryLocale)).format("DD-MM-YYYY") != moment(consumerProduct.WarrantyTill).format("DD-MM-YYYY")))){
        	updateConsumerProduct()
        }else {
        	// request();
			setCallRequestFunction(true);
        }
	}

	const updateConsumerProduct = () => {
		setLoader(true);
		let reqObj = {
			ProductUniqueID: consumerProduct.ProductUniqueID,
			ProductID: consumerProduct.ProductID,
			ConsumerID: consumerProduct.ConsumerID,
			BrandID: consumerProduct.BrandID,
			ConsumerProductID: consumerProduct.ConsumerProductID,
			IsUnderWarranty: isDeviceInWarranty,
			IsWarrantyApplicable: isDeviceInWarranty ? isDeviceWarrantyApplicable : false,
			WarrantyTill: isDeviceInWarranty && warrantyTillDate ? moment(warrantyTillDate, getDateFormat(defaultCountryLocale)).format('DD-MM-YYYY') : undefined,
		};
		apiCall({
			url: 'setProductDetailsForRegisteredProducts',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if (result.success) {
					setConsumerProduct(result.data);
					// request();
					setCallRequestFunction(true);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const slotsNext = () => {
		if(([SERVICETYPE.BUY_BACK, SERVICETYPE.INSTALLATION, SERVICETYPE.DEMO, SERVICETYPE.SERVICING, SERVICETYPE.DELIVERY, SERVICETYPE.CLAIM, SERVICETYPE.CLAIM_DROPOFF].includes(serviceMode.ServiceTypeID)) || (modeType.ModeTypeID == 2 && [SERVICETYPE.PICK_UP, SERVICETYPE.CARRY_IN, SERVICETYPE.DROPOFF].includes(serviceMode.ServiceTypeID))) {
			checkUpdateConsumerProduct();
        }else if([SERVICETYPE.DOOR_TO_DOOR_REPLACEMENT].includes(serviceMode.ServiceTypeID) && accessFn('RAISE_REPLACEMENT_REQUEST')){
        	// request();
			setCallRequestFunction(true);
        }else {
            // resetExternalRef();
            checkUpdateConsumerProduct();
        }
	}

	const getReplacementOptions = (serviceModeObj) => {
		setLoader(true);
		let reqObj = {
            CountryID: consumerObj ? consumerObj.countryID : undefined,
            PartnerID: serviceAvailabilityData?.advanceExchangeDetails?.PartnerID,
            ConsumerProductID: consumerProduct?.ConsumerProductID,
            ServiceTypeID: serviceModeObj?.ServiceTypeID,
            PlanID: plan?.PlanID ? plan.PlanID : undefined,
			PlanCoverageID: coverage?.PlanCoverageID
		}
        if(serviceModeObj && [SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(serviceModeObj.ServiceTypeID) > -1) {
            reqObj.PartnerID = serviceAvailabilityData.replacementDetails && serviceAvailabilityData.replacementDetails.PartnerID;
        }
        if(serviceModeObj && [SERVICETYPE.ADVANCE_EXCHANGE].indexOf(serviceModeObj.ServiceTypeID) > -1) {
            reqObj.PartnerID = serviceAvailabilityData.advanceExchangeDetails && serviceAvailabilityData.advanceExchangeDetails.PartnerID;
        }
		apiCall({
			url: 'getReplacementOptions',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data) {
					//For Advance Exchange Flow setting the Consent Legal Configs

                    if(result.data.length) {
						setShowReplacementOptions(true);
                        if(result.data[0] && result.data[0].AllocationType) {
                            setReplacementAllocationType(result.data[0].AllocationType);
                        }else {
                            setReplacementAllocationType('EXACT');
                        }
                        setReplacementOptions(result.data);
                        // setSelectedReplacementOption(result.data[0]);
                    }else {
						setShowReplacementOptions(false);
						setCallRequestFunction(true);  
                    }
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const checkSuppressLogistics = () => {
		if(serviceMode.logisticsSuppressed || isSupressLogisticsCutomerToASC){
			slotsNext();
		}else{
			if(dropOffAvailableForSecondAddress > -1 && [SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(serviceMode.ServiceTypeID) > -1) {
				if (secondryAddressServiceAvailabilityData && secondryAddressServiceAvailabilityData.isDropOffLocationAvailable) {
					setDropoffLocationList(secondryAddressServiceAvailabilityData.dropOffLocations);
					setShowServiceLocationsDropOff(true);
				}else {
					showInstructions();
				}
			}else{
				setCallGetScheduleDateAndSlotApi(true);
				setShowSlotSelection(true);
			}
		}
	}

	const getPaymentActions = function(){
		setLoader(true);
		let reqObj = {
            countryID: consumerObj ? consumerObj.countryID : undefined,
            planID: plan?.PlanID ? plan.PlanID : undefined,
			checkForTokenization: true,
			consents:legalConfigsList
		}
		apiCall({
			url: 'getPaymentActions',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data) {
                   setChargeMaxDeductible(result.data.chargeMaxDeductible);
				   setTokenizeFlow(result.data.tokenizeFlow);
				}
			}
		});
	}

	const requestConsumerOtp = (userData) => {
		setLoader(true)
		let reqObj = {};
		let consumer = userData;
		setDisableResendOtpBtn(true);
		reqObj = {
			partnerServiceLocationIDs: qFilter?.serviceLocations,
			emailID: consumer?.emailID ,
			mobileNo: consumer?.mobileNo,
			countryID: consumer?.countryID,
			countryCode: selectedCountryData?.CountryCode
		}
		if(consumer?.mobileNo){
			reqObj.phoneCode= consumer?.phoneCode
		}
		apiCall({
			url: 'requestConsumerOtp',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success){
                    let isOtp = result?.data?.otpSent
                    if(isOtp){
                        setShowOtpScreen(isOtp)
                        setSeconds(60);
                    }else{
                        setShowOtpScreen(false)
                        setShowConsumerProducts(true);
                        loadConsumerProducts();
                    }
				}else {
					setShowOtpScreen(false)
					Swal.fire({  
						icon: 'error',  
						html: '<h4  style="line-height: normal; font-weight: 500;">'+localString('Too many attempts! Please retry verification in some time.')+'</h4>',
						showCancelButton: false,
						showConfirmButton: true,
						confirmButtonText: localString('OK')
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	return (
		<>
			{
				(loader || compWarningloader || emptyBoxloader) && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<div className='raiseRequest'>
				<div className="content actionBar">
					<div className="col-sm-12">
						<Button className="btn button-servify" onClick={backToPreviousPage}>
							<span className="glyphicon glyphicon-chevron-left"></span>
							{localString(backToText)}
						</Button>
					</div>
				</div>

				{/* ********** Enter MobileNo/Email and User Details section ********** */}
				<UserDetails 
					{...props}
					hideSection={hideSection}
					getCountries={getCountries}
					show={show} setShow={setShow}
					checkConsumer={checkConsumer}
					defaultShowObj={defaultShowObj}
					email={email} setEmail={setEmail}
					error={error} setError={setError}
					mobile={mobile} setMobile={setMobile}
					setScreenToViewDiv={setScreenToViewDiv}
					country={country} setCountry={setCountry}
					getComplianceWarning={getComplianceWarning}
					stateList={stateList} setStateList={setStateList}
					checkAddorUpdateConsumer={checkAddorUpdateConsumer}
					radioValue={radioValue} setRadioValue={setRadioValue}
					consumerId={consumerId} setConsumerId={setConsumerId}
					userDetails={userDetails} setUserDetails={setUserDetails}
					disableStep1={disableStep1} setDisableStep1={setDisableStep1}
					showAddProduct={showAddProduct} setShowAddProduct={setShowAddProduct}
					showEmailOption={showEmailOption} setShowEmailOption={setShowEmailOption}
					mobileMaxLength={mobileMaxLength} setMobileMaxLength={setMobileMaxLength}
					skipZipCodeCheck={skipZipCodeCheck} setSkipZipCodeCheck={setSkipZipCodeCheck}
					isMobileSelected={isMobileSelected} setIsMobileSelected={setIsMobileSelected}
					pinCodeLengthArr={pinCodeLengthArr} setPinCodeLengthArr={setPinCodeLengthArr}
					showConsumerDetails={showConsumerDetails} setShowConsumerDetails={setShowConsumerDetails}
					selectedCountryData={selectedCountryData} setSelectedCountryData={setSelectedCountryData}
					secondaryStepCountry={secondaryStepCountry} setSecondaryStepCountry={setSecondaryStepCountry}
					intlTelCountryConfig={intlTelCountryConfig} setIntlTelCountryConfig={setIntlTelCountryConfig}
					isMinimalConsumerMode={isMinimalConsumerMode} setIsMinimalConsumerMode={setIsMinimalConsumerMode}
				/>
				{/* ********** Consumer Verification ********** */}
				{
					showOtpScreen && 
					<CustomerVerification
						{...props}
						qFilter={qFilter}
						setLoader={setLoader} 
						consumerObj={consumerObj}
						requestConsumerOtp={requestConsumerOtp}
						seconds={seconds} setSeconds={setSeconds}
						minutes={minutes} setMinutes={setMinutes}
						selectedCountryData={selectedCountryData}
						setShowOtpScreen={setShowOtpScreen} showOtpScreen={showOtpScreen}
						disableResendOtpBtn={disableResendOtpBtn} setDisableResendOtpBtn={setDisableResendOtpBtn}
						setShowConsumerProducts={setShowConsumerProducts} loadConsumerProducts={loadConsumerProducts}
					/>
				}
				{/* ********** Consumer Product section ********** */}
				{
					showConsumerProducts && 
						<ConsumerProducts 
							{...props}
							consumerObj={consumerObj}
							hideSection={hideSection}
							show={show} setShow={setShow}
							setShowModeType={setShowModeType}
							loader={loader} setLoader={setLoader}
							setScreenToViewDiv={setScreenToViewDiv}
							loadConsumerProducts={loadConsumerProducts}
							showConsumerProducts={showConsumerProducts}
							defaultCountryLocale={defaultCountryLocale}
							editImei={editImei} setEditImei={setEditImei}
							checkToEnterAttributes={checkToEnterAttributes}
							setShowConsumerProducts={setShowConsumerProducts}
							setShowDeviceAttributes={setShowDeviceAttributes}
							setCallComplianceWarning={setCallComplianceWarning}
							showCarryInTypeSelection={showCarryInTypeSelection}
							consumerId={consumerId} setConsumerId={setConsumerId}
							showClaimForm={showClaimForm} setShowClaimForm={setShowClaimForm}
							showAddProduct={showAddProduct} setShowAddProduct={setShowAddProduct}
							imeiOrSerialNo={imeiOrSerialNo} setImeiOrSerialNo={setImeiOrSerialNo}
							consumerProduct={consumerProduct} setConsumerProduct={setConsumerProduct}
							deviceWarningMsg={deviceWarningMsg} setDeviceWarningMsg={setDeviceWarningMsg}
							warrantyTillDate={warrantyTillDate} setWarrantyTillDate={setWarrantyTillDate}
							isDeviceInWarranty={isDeviceInWarranty} setIsDeviceInWarranty={setIsDeviceInWarranty}
							showSelectedProduct={showSelectedProduct} setShowSelectedProduct={setShowSelectedProduct}
							showInspectionGuide={showInspectionGuide} setShowInspectionGuide={setShowInspectionGuide}
							showAddEditSerialNo={showAddEditSerialNo} setShowAddEditSerialNo={setShowAddEditSerialNo}
							showIssuesSelection={showIssuesSelection} setShowIssuesSelection={setShowIssuesSelection}
							warrantyTillDateCopy={warrantyTillDateCopy} setWarrantyTillDateCopy={setWarrantyTillDateCopy}
							isWarrantyApplicable={isWarrantyApplicable} setIsWarrantyApplicable={setIsWarrantyApplicable}
							consumerProductArray={consumerProductArray} setConsumerProductArray={setConsumerProductArray}
							isNonSerializedProduct={isNonSerializedProduct} setIsNonSerializedProduct={setIsNonSerializedProduct}
							selectedConsumerProduct={selectedConsumerProduct} setSelectedConsumerProduct={setSelectedConsumerProduct}
							callConsumerProductClick={callConsumerProductClick} setCallConsumerProductClick={setCallConsumerProductClick}
							inRepairConsumerProducts={inRepairConsumerProducts} setInRepairConsumerProducts={setInRepairConsumerProducts}
							filteredConsumerProducts={filteredConsumerProducts} setFilteredConsumerProducts={setFilteredConsumerProducts}
							isDeviceWarrantyApplicable={isDeviceWarrantyApplicable} setIsDeviceWarrantyApplicable={setIsDeviceWarrantyApplicable}
						/>
				}
				{/* ********** Addd Product section ********** */}
				{/* {
					show.consumerProducts && 
						<AddProduct 
							{...props}
							show={show} setShow={setShow}
							loader={loader} setLoader={setLoader}
							getComplianceWarning={getComplianceWarning}
							defaultCountryLocale={defaultCountryLocale}
							editImei={editImei} setEditImei={setEditImei}
							consumerId={consumerId} setConsumerId={setConsumerId}
							consumerObj={consumerObj} setConsumerObj={setConsumerObj}
							imeiOrSerialNo={imeiOrSerialNo} setImeiOrSerialNo={setImeiOrSerialNo}
							consumerProduct={consumerProduct} setConsumerProduct={setConsumerProduct}
						/>
				} */}
				{/* ********** Enter Device Attributes ********** */}
				{
					showDeviceAttributes && 
						<EnterDeviceAttributes 
							{...props}
							show={show} setShow={setShow}
							loader={loader} setLoader={setLoader}
							setScreenToViewDiv={setScreenToViewDiv}
							showDeviceAttributes={showDeviceAttributes}
							showAvailableTypesForCP={showAvailableTypesForCP}
							setShowDeviceAttributes={setShowDeviceAttributes}
							consumerProduct={consumerProduct} setConsumerProduct={setConsumerProduct}
							consumerProductArray={consumerProductArray} setConsumerProductArray={setConsumerProductArray}
							selectedConsumerProduct={selectedConsumerProduct} setSelectedConsumerProduct={setSelectedConsumerProduct}
							filteredConsumerProducts={filteredConsumerProducts} setFilteredConsumerProducts={setFilteredConsumerProducts}
						/>
				}
				{/* ********** Select Mode Type ********** */}
				{
					showModeType && 
						<ModeType 
							{...props}
							country={country}
							hideSection={hideSection}
							show={show} setShow={setShow}
							plan={plan} setPlan={setPlan}
							setServiceMode={setServiceMode}
							setShowAddress={setShowAddress}
							claimOrContinue={claimOrContinue}
							setScheduleDate={setScheduleDate}
							loader={loader} setLoader={setLoader}
							setScreenToViewDiv={setScreenToViewDiv}
							showDOP={showDOP} setShowDOP={setShowDOP}
							defaultClaimFormData={defaultClaimFormData}
							setShowSlotSelection={setShowSlotSelection}
							coverage={coverage} setCoverage={setCoverage}
							modeType={modeType} setModeType={setModeType}
							claimForm={claimForm} setClaimForm={setClaimForm}
							showPlans={showPlans} setShowPlans={setShowPlans}
							handleReplacementReasons={handleReplacementReasons}
							showCoverage={showCoverage} setShowCoverage={setShowCoverage}
							showModeType={showModeType} setShowModeType={setShowModeType}
							setShowSecCommunicationDetails={setShowSecCommunicationDetails}
							damageTypeObj={damageTypeObj} setDamageTypeObj={setDamageTypeObj}
							showClaimForm={showClaimForm} setShowClaimForm={setShowClaimForm}
							claimFormData={claimFormData} setClaimFormData={setClaimFormData}
							showActivation={showActivation} setShowActivation={setShowActivation}
							selectedReasons={selectedReasons} setSelectedReasons={setSelectedReasons}
							mobileMaxLength={mobileMaxLength} setMobileMaxLength={setMobileMaxLength}
							consumerProduct={consumerProduct} setConsumerProduct={setConsumerProduct}
							warrantyConsent={warrantyConsent} setWarrantyConsent={setWarrantyConsent}
							consentConfirmed={consentConfirmed} setConsentConfirmed={setConsentConfirmed}
							showCoverageType={showCoverageType} setShowCoverageType={setShowCoverageType}
							selectedClaimType={selectedClaimType} setSelectedClaimType={setSelectedClaimType}
							showIssuesSelection={showIssuesSelection} setShowIssuesSelection={setShowIssuesSelection}
							showOtherReasonTextBox={showOtherReasonTextBox} setshowOtherReasonTextBox={setshowOtherReasonTextBox}
							otherReasonDescription={otherReasonDescription} setOtherReasonDescription={setOtherReasonDescription}
							showReplacementReasons={showReplacementReasons} setShowReplacementReasons={setShowReplacementReasons}
							claimEligibilityDetails={claimEligibilityDetails} setClaimEligibilityDetails={setClaimEligibilityDetails}
							selectedConsumerProduct={selectedConsumerProduct} setSelectedConsumerProduct={setSelectedConsumerProduct}
							legalConfigsList={legalConfigsListForCoverage}
							setLegalConfigsList={setLegalConfigsListForCoverage}
						/>
				}
				{/* ********** Issues Selection ********** */}
				{
					showIssuesSelection &&
						<IssuesSelection
							{...props}
							coverage={coverage}
							setLoader={setLoader}
							hideSection={hideSection}
							consumerProduct={consumerProduct}
							setShowSecCommunicationDetails={setShowSecCommunicationDetails}
							selectedIssues={selectedIssues} setSelectedIssues={setSelectedIssues}
							showOtherIssueTextBox={showOtherIssueTextBox} setshowOtherIssueTextBox={setshowOtherIssueTextBox}
							otherIssueDescription={otherIssueDescription} setOtherIssueDescription={setOtherIssueDescription}
						/>
				}
				{/* ********** Secondary Communication Details ********** */}
				{
					showSecCommunicationDetails &&
						<SecondaryCommunicationDetails
							{...props}
							setLoader={setLoader}
							parentCountry={country}
							consumerId={consumerId}
							consumerObj={consumerObj}
							consumerProduct={consumerProduct}
							parentMobileMaxLength={mobileMaxLength}
							intlTelCountryConfig={intlTelCountryConfig}
							defaultSecContactDetails={defaultSecContactDetails}
							secondaryCommunicationSubmit={secondaryCommunicationSubmit}
							secContactDetails={secContactDetails} setSecContactDetails={setSecContactDetails}
						/>
				}
				{/* ********** Carry in type selection ********** */}
				{
					showCarryInTypeSelection &&
						<CarryInTypeSelection
							{...props}
							carryInType={carryInType} setCarryInType={setCarryInType}
							carryInCustomerTypeSelected={carryInCustomerTypeSelected}
						/>
				}
				{/* ********** Select Or Add Address (Primary Address) ********** */}
				{
					showAddress &&
						<Address
							{...props}
							walkIn={walkIn}
							country={country}
							event='RaiseRequest'
							setLoader={setLoader}
							stateList={stateList}
							consumerId={consumerId}
							identifier="primAddress"
							hideSection={hideSection}
							consumerProduct={consumerProduct}
							mobileMaxLength={mobileMaxLength}
							pinCodeLengthArr={pinCodeLengthArr}
							skipZipCodeCheck={skipZipCodeCheck} 
							setScreenToViewDiv={setScreenToViewDiv}
							selectedCountryData={selectedCountryData}
							address={address} setAddress={setAddress}
							getScheduleDateAndSlot={getScheduleDateAndSlot}
							checkSuppressLogistics={checkSuppressLogistics}
							showAddress={showAddress} setShowAddress={setShowAddress}
							setConsumerFavouriteLocationID={setConsumerFavouriteLocationID}
							getServiceAvailabilityWithLocations={getServiceAvailabilityWithLocations}
							// secondaryAddress={secondaryAddress} setSecondaryAddress={setSecondaryAddress}
						/>
				}
				{/* ********** Select Service Mode ********** */}
				{
					showServiceModes &&
						<ServiceMode
						{...props}
						request={request}
						setLoader={setLoader}
						hideSection={hideSection}
						currencySymbol={currencySymbol}
						serviceTypeArray={serviceTypeArray}
						setOpenPayoutModes={setOpenPayoutModes}
						setShowPayoutModal={setShowPayoutModal}
						selectedCountryData={selectedCountryData}
						setShowSlotSelection={setShowSlotSelection}
						getReplacementOptions={getReplacementOptions}
						isEmptyBoxServiceable={isEmptyBoxServiceable}
						singleServiceLocation={singleServiceLocation}
						setServiceLocationList={setServiceLocationList}
						setCallRequestFunction={setCallRequestFunction}
						getScheduleDateAndSlot={getScheduleDateAndSlot}
						serviceAvailabilityData={serviceAvailabilityData}
						setShowSecondaryAddress={setShowSecondaryAddress}
						setAdvanceChargeApplicable={setAdvanceChargeApplicable}
						setShowExternalReferenceID={setShowExternalReferenceID}
						checkAvailabilityForPickUp={checkAvailabilityForPickUp}
						serviceMode={serviceMode} setServiceMode={setServiceMode}
						showAddress={showAddress} setShowAddress={setShowAddress}
						checkAvailabilityForDropOff={checkAvailabilityForDropOff}
						getServiceLocationsForCarryIn={getServiceLocationsForCarryIn}
						setCallGetScheduleDateAndSlotApi={setCallGetScheduleDateAndSlotApi}
						getServicelocationForClaimCarryIn={getServicelocationForClaimCarryIn}
						showIsSameAddress={showIsSameAddress} setShowIsSameAddress={setShowIsSameAddress}
						showServiceLocationsCarryIn={showServiceLocationsCarryIn} setShowServiceLocationsCarryIn={setShowServiceLocationsCarryIn}
						showServiceLocationsDropOff={showServiceLocationsDropOff} setShowServiceLocationsDropOff={setShowServiceLocationsDropOff}
						legalConfigsList={legalConfigsList}
						setLegalConfigsList={setLegalConfigsList}
						getPaymentActions={getPaymentActions}
						setTokenizeFlow={setTokenizeFlow}
						/>
				}
				
				{/* ********** Select Replacement Options ********** */}
				{
					showReplacementOptions &&
						<ReplacementOptions
							{...props}
							setCallRequestFunction={setCallRequestFunction}
							replacementOptions={replacementOptions} setReplacementOptions={setReplacementOptions}
							replacementAllocationType={replacementAllocationType} setReplacementAllocationType={setReplacementAllocationType}
							selectedReplacementOption={selectedReplacementOption} setSelectedReplacementOption={setSelectedReplacementOption}
							legalConfigsList={legalConfigsList}
						/>
				}
				{/* ********** Find my iphone modal ********** */}
				{
					<FindMyIphoneModal
						{...props} 
						FMIPcheck={FMIPcheck}
						showFMIPModal={showFMIPModal} 
						setShowFMIPModal={setShowFMIPModal}
					/>
				}
				{/* ********** Select Logistics Partner ********** */}
				{
					<LogisticsPartnerModal
						{...props} 
						vendor={vendor}
						setVendor={setVendor}
						vendorList={vendorList}
						setDeliveryMode={setDeliveryMode}
						showSlotSelection={showSlotSelection}
						checkAvailabilityForDropOff={checkAvailabilityForDropOff}
						showLogisticsPartnerModal={showLogisticsPartnerModal} 
						setShowLogisticsPartnerModal={setShowLogisticsPartnerModal}
					/>
				}
				{/* ********** Is same Address Section ********** */}
				{
					showIsSameAddress &&
						<Address
							{...props}
							walkIn={walkIn}
							country={country}
							setLoader={setLoader}
							stateList={stateList}
							consumerId={consumerId}
							serviceMode={serviceMode}
							hideSection={hideSection}
							identifier="sameAddresSection"
							consumerProduct={consumerProduct}
							mobileMaxLength={mobileMaxLength}
							showInstructions={showInstructions}
							pinCodeLengthArr={pinCodeLengthArr}
							skipZipCodeCheck={skipZipCodeCheck} 
							showSlotSelection={showSlotSelection}
							selectedCountryData={selectedCountryData}
							address={address} setAddress={setAddress}
							setDropoffLocationList={setDropoffLocationList}
							getScheduleDateAndSlot={getScheduleDateAndSlot}
							serviceAvailabilityData={serviceAvailabilityData}
							showAddress={showAddress} setShowAddress={setShowAddress}
							setShowServiceLocationsDropOff={setShowServiceLocationsDropOff}
							setConsumerFavouriteLocationID={setConsumerFavouriteLocationID}
							isSameAddress={isSameAddress} setIsSameAddress={setIsSameAddress}
							isSupressLogisticsCutomerToASC={isSupressLogisticsCutomerToASC} setIsSupressLogisticsCutomerToASC={setIsSupressLogisticsCutomerToASC}
							getServiceAvailabilityWithLocations={getServiceAvailabilityWithLocations}
							secondaryAddress={secondaryAddress} setSecondaryAddress={setSecondaryAddress}
							showIsSameAddress={showIsSameAddress} setShowIsSameAddress={setShowIsSameAddress}
							showSecondaryAddress={showSecondaryAddress} setShowSecondaryAddress={setShowSecondaryAddress}
							checkSuppressLogistics={checkSuppressLogistics}
						/>
				}
				{/* ********** Select Or Add Address (Secondary Address) ********** */}
				{
					showSecondaryAddress &&
						<Address
							{...props}
							walkIn={walkIn}
							country={country}
							event='RaiseRequest'
							setLoader={setLoader}
							stateList={stateList}
							consumerId={consumerId}
							hideSection={hideSection}
							identifier="secondaryAddress"
							consumerProduct={consumerProduct}
							mobileMaxLength={mobileMaxLength}
							pinCodeLengthArr={pinCodeLengthArr}
							skipZipCodeCheck={skipZipCodeCheck} 
							selectedCountryData={selectedCountryData}
							address={address} setAddress={setAddress}
							showSecondaryAddress={showSecondaryAddress}
							getScheduleDateAndSlot={getScheduleDateAndSlot}
							showAddress={showAddress} setShowAddress={setShowAddress}
							setConsumerFavouriteLocationID={setConsumerFavouriteLocationID}
							isSameAddress={isSameAddress} setIsSameAddress={setIsSameAddress}
							isSupressLogisticsCutomerToASC={isSupressLogisticsCutomerToASC} setIsSupressLogisticsCutomerToASC={setIsSupressLogisticsCutomerToASC}
							getServiceAvailabilityWithLocations={getServiceAvailabilityWithLocations}
							secondaryAddress={secondaryAddress} setSecondaryAddress={setSecondaryAddress}
							showIsSameAddress={showIsSameAddress} setShowIsSameAddress={setShowIsSameAddress}
							checkSuppressLogistics={checkSuppressLogistics}
						/>
				}
				{/* ********** Select Service Location ********** */}
				{
					(showServiceLocationsCarryIn || showServiceLocationsDropOff) &&
						<ServiceLocation
							{...props} 
							address={address}
							serviceMode={serviceMode}
							getLocations={getLocations}
							showNoService={showNoService}
							showInstructions={showInstructions}
							dropoffLocationList={dropoffLocationList}
							serviceLocationList={serviceLocationList}
							setShowSlotSelection={setShowSlotSelection}
							getScheduleDateAndSlot={getScheduleDateAndSlot}
							showServiceLocationsCarryIn={showServiceLocationsCarryIn}
							showServiceLocationsDropOff={showServiceLocationsDropOff}
							setCallGetScheduleDateAndSlotApi={setCallGetScheduleDateAndSlotApi}
							serviceLocation={serviceLocation} setServiceLocation={setServiceLocation}
							dropoffLocation={dropoffLocation} setDropoffLocation={setDropoffLocation}
							checkSuppressLogistics={checkSuppressLogistics}
							isSupressLogisticsCutomerToASC={isSupressLogisticsCutomerToASC}
						/>
				}
				{/* ********** Schedule date and slot selection ********** */}
				{
					showSlotSelection &&
						<SlotSelection
							{...props} 
							slotsNext={slotsNext}
							slot={slot} setSlot={setSlot}
							scheduleDate={scheduleDate} setScheduleDate={setScheduleDate}
							slotAvailable={slotAvailable} setSlotAvailable={setSlotAvailable}
							disableSlotNext={disableSlotNext} setDisableSlotNext={setDisableSlotNext}
							scheduleDateArray={scheduleDateArray} setScheduleDateArray={setScheduleDateArray}
							scheduleSlotArray={scheduleSlotArray} setScheduleSlotArray={setScheduleSlotArray}
						/>
				}
				{/* ********** Display instruction modal on selection of service location ********** */}
				{
					<InstructionModal
						{...props} 
						walkIn={walkIn}
						slotsNext={slotsNext}
						slot={slot} setSlot={setSlot}
						instructionsArray={instructionsArray}
						setShowSlotSelection={setShowSlotSelection}
						isEmptyBoxServiceable={isEmptyBoxServiceable}
						getScheduleDateAndSlot={getScheduleDateAndSlot}
						serviceAvailabilityData={serviceAvailabilityData}
						dropOffInstructionsTitle={dropOffInstructionsTitle}
						checkUpdateConsumerProduct={checkUpdateConsumerProduct}
						serviceMode={serviceMode} setServiceMode={setServiceMode}
						scheduleDate={scheduleDate} setScheduleDate={setScheduleDate}
						slotAvailable={slotAvailable} setSlotAvailable={setSlotAvailable}
						serviceLocation={serviceLocation} setServiceLocation={setServiceLocation}
						scheduleDateArray={scheduleDateArray} setScheduleDateArray={setScheduleDateArray}
						scheduleSlotArray={scheduleSlotArray} setScheduleSlotArray={setScheduleSlotArray}
						checkSuppressLogistics={checkSuppressLogistics}
						isSupressLogisticsCutomerToASC={isSupressLogisticsCutomerToASC}
						showInstructionsModal={showInstructionsModal} setShowInstructionsModal={setShowInstructionsModal}
					/>
				}
				{/* ********** Payment mode modal ********** */}
				{
					<PayoutModesModal
						{...props} 
						onPayoutModeSubmit={onPayoutModeSubmit}
						customerName={consumerObj && consumerObj.name}
						countryId={consumerObj && consumerObj.countryID}
						customerEmail={consumerObj && consumerObj.emailID} 
						ServiceTypeID={serviceMode && serviceMode.ServiceTypeID}
						showPayoutModal={showPayoutModal} setShowPayoutModal={setShowPayoutModal}
					/>
				}
				
			</div>
		</>
	);
}