import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Loader, Dropdown } from "sfy-react";
import Swal from "sweetalert2";

import { copyText, displayError ,returnLocalDateTime} from "../../../../../Utility/Utils";
import PageContentWrapper from "../../../../../components/PageContentWrapper/PageContentWrapper";
import CommonTable from "../../../../../components/Table/Table";
import DisplayQRCode from "./DisplayQRCode";
import GridActionBtn from "./GridActionBtn";
import Snackbar from "../../../../../components/Snackbar/Snackbar";

import './PaymentDetailsTab.scss'

const PaymentDetailsTab = (props) => {
	const { 
		apiCall, localString, consumerServiceRequest, publicUrl, currencySymbol
	} = props;
	const [showLoader, setShowLoader] = useState(false);
	const [paymentData, setPaymentData] = useState([]);
	const [displayQRCode, setDisplayQRCode] = useState(false);
	const [qrCodeString, setQrCodeString] = useState('');
	const [displayOptions, setDisplayOptions] = useState(false);

	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarType, setSnackbarType] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState(false);
	const getStyle = (status) => {
		if(statusConfig[status]) {
			return {
				color: statusConfig[status]?.color,
				background: statusConfig[status]?.background,
			}
		}else {
			return {
				color: statusConfig['default']?.color,
				background: statusConfig['default']?.background,
			}
		}
	}

	const paymentTypeText = {
		'MinimumDeductibleFee': localString('Minimum Deductible Fee'), // Invalid data - color Red
		'RepairFee': localString('Repair Fee'), // Incorrect template - color Red
		'NonReturnFee': localString('Non Return Fee'), // Successful - color Green
		'MinimumDeductibleFeeRefund': localString('Minimum Deductible Fee Refund'), // Pending - color Yellow
		'RepairFeeRefund': localString('Repair Fee Refund'), // Successful - color Green
		'NonReturnFeeRefund': localString('Non Return Fee Refund'), // Partially Successful - color Orange,
	};

	const statusConfig = {
		'captured': {
			label: 'Captured',
			color: '#4EAF51',
			background: '#E6F3E5' // green
		},
		'failed': {
			label: 'Failed',
			color: '#F54336',
			background: '#FDE4E1' // red
		},
		'paid': {
			label: 'Paid',
			color: '#4EAF51',
			background: '#E6F3E5' // green
		},
		'issue': {
			label: 'Issue',
			color: '#F54336',
			background: '#FDE4E1' // red
		},
		'open': {
			label: 'Open',
			color: '#4EAF51',
			background: '#E6F3E5' // green
		},
		'Open': {
			label: 'Open',
			color: '#4EAF51',
			background: '#E6F3E5' // green
		},
		'refunded': {
			label: 'Refunded',
			color: '#393939',
			background: '#F2F2F2' // grey
		},
		'released': {
			label: 'Released',
			color: '#393939',
			background: '#F2F2F2' // grey
		},
		'hold': {
			label: 'Hold',
			color: '#393939',
			background: '#F2F2F2' // grey
		},
		'default': {
			// label: 'Captured',
			color: '#393939',
			background: '#F2F2F2' // grey
		}
	}

	//success - green
	// color: '#4EAF51';
	// background: '#E6F3E5'

	//warning - orange
	// color: '#FF9300';
	// 'background: #FCF0DF; 

	//error - red
	// color: '#F54336';
	// 'background: #FDE4E1; 

	//text/default - grey
	// color: '#393939';
	// 'background: #F2F2F2; 


	const displayToast = (type, message) => {
		setSnackbarType(type);
		setSnackbarMessage(message);
		setShowSnackbar(true);
	}

	const columns = [
		{
			title: localString("Payment Type"),
			showHeading: true,
			customCell: (row) => paymentTypeText?.[row?.paymentAction] || row?.paymentAction || '--',
		},
		{
			title: localString("Payment ID"),
			showHeading: true,
			customCell: (row) => row.paymentID || '--',
		},
		{
			// title: localString("Amount"),
			title: <span>{localString("Amount")} (<span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>)</span>,
			showHeading: true,
			customCell: (row) => row.amount,
		},
		{
			title: localString("Payment Date"),
			showHeading: true,
			customCell: (row) => returnLocalDateTime(row.createdDate) || '--',
		},
		{
			title: localString("Status"),
			showHeading: true,
			// customCell: (row) => row.status || '--',
			customCell: (row) => <>
				{ 
					row.status ? 
						<span className="paymentStatusPill" style={getStyle(row.status)}>
							{statusConfig[row.status]?.label || row.status}
						</span> 
						: '--' 
				}
			</>,
		},
		{
			title: localString("Remark"),
			showHeading: true,
			customCell: (row) => row.remarks || '--',
		},
		{
			title: localString("Action"),
			showHeading: true,
			customCell: (row) => {
				return (
				<>
					<GridActionBtn
						row={row}
						scanQr={scanQr} 
						copyLink={copyLink}	
						publicUrl={publicUrl}
						localString={localString} 
					/>
				</>
				);
			}
		},
	];

	const scanQr = (row) => {
		setDisplayQRCode(true);
		setQrCodeString(row.shortUrl);
	}

	const copyLink = (row) => {
		copyText(row.shortUrl)
		.then(() => {
			displayToast('success', localString('Link Copied!'));
		});
	}

	const getPaymentHistory = () => {
		let data = {
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
		}
		setShowLoader(true);
		apiCall({
			url: "getPaymentHistory",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					setPaymentData(result.data);
				} else {
					Swal.fire("", localString(displayError(result)), "error");
				}
			},
		});
	};

	useEffect(() => {
		getPaymentHistory();
	}, []);

	return(
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}
			<div className="padding10 paymentDetailsTab">
				<div className="row">
					<PageContentWrapper 
						title={localString('Payment Details')}
						bodyClassName="panel-body-no-padding" 
						showStatusSpan={paymentData.some(paymentDetail => paymentDetail?.isTokenized === "true")}
						statusSpanText="Card Saved"

					>
						<div>
							<CommonTable
								columns={columns}
								data={paymentData}
								localString={localString}
								tableClasses={{}}
							/>
						</div>
					</PageContentWrapper>
				</div>
			</div>
			{
				displayQRCode && 
					<DisplayQRCode
						showModal={displayQRCode}
						localString={localString}
						qrCodeString={qrCodeString}
						displayQRCode={displayQRCode}
						closeModal={() => {
							setDisplayQRCode(false);
							setQrCodeString('');
						}}
					/>
			}
			{
				showSnackbar && 
					<Snackbar
						publicUrl={publicUrl}
						message={localString(snackbarMessage)}
						type={snackbarType}
						callback={() => {
							setShowSnackbar(false);
							setSnackbarMessage('');
							setSnackbarType('');
						}}
					/>
			}
		</>
	)
}
export default PaymentDetailsTab;