import React from 'react';

const ShowProductDetails = (props) => {
	const {ProductDetails,localString, baseUrl} = props; 
	const defaultText = localString("Not Updated");

	const len = ProductDetails && ProductDetails.length;

	return(
		<>
			{ProductDetails && ProductDetails.length>0 && ProductDetails.map((product,i) => {
				return(
					<>  
						<div className="main-product-details-in-reg-products">
							<div className="col-sm-6 padding0">
								<label className="text-camelcase">{len == 1 ? localString("Plan"):localString("Plan "+ (i+1))}:</label>
							</div>
							<div className="col-sm-6 padding0">
								{/* {
									product.PlanName ? 
									<a 	href={baseUrl+'/plans/consumer/view?spid='+product.SoldPlanID} 
										target="_blank">
										{localString(product.PlanName)}
									</a>
								:<p> {defaultText} </p>} */}
								<p> {product.PlanName || defaultText} </p>
							</div>
						</div>
						{/* <div className="">
							<div className="col-sm-6 padding0">
								<label className="text-camelcase">{localString("Validity")}:</label>
							</div>
							<div className="col-sm-6 padding0">
								<p> {product.validityText || defaultText} </p>
							</div>
						</div>
						{product.NoOfRepairsAllowed != undefined  && product.NoOfRepairsAllowed >=0 && <div className="">
							<div className="col-sm-6 padding0">
								<label className="text-camelcase">{localString("Allowed Repairs")}:</label>
							</div>
							<div className="col-sm-6 padding0">
								<p> {product.NoOfRepairsAllowed} </p>
							</div>
						</div>
						}
						{product.NoOfRepairsUsed != undefined  && product.NoOfRepairsUsed >=0 &&  <div className="">
							<div className="col-sm-6 padding0">
								<label className="text-camelcase">{localString("Used Repairs")}:</label>
							</div>
							<div className="col-sm-6 padding0">
								<p> {product.NoOfRepairsUsed} </p>
							</div>
						</div>
						}
						{product.TermsAndConditionsLink && <div className="">
							<div className="col-sm-6 padding0">
								<label className="text-camelcase">{localString("Terms and Conditions")}:</label>
							</div>
							<div className="col-sm-6 padding0">
								<a target="_blank" href={product.TermsAndConditionsLink}>{localString("View")}</a>
							</div>
						</div>
						} */}
						{product.HighRiskReason  && product.HighRiskReason.length > 0 && <div className="">
							<div className="col-sm-6 padding0">
								<label>{localString("High Risk Reason(s)")}:</label>
							</div>
							<div className="col-sm-6 padding0">
								<ul className="ul-without-padding">
								{product.HighRiskReason && product.HighRiskReason.length>0 && product.HighRiskReason.map((reason) => {
									return(
										<li >{reason.RaisedFlagReasonDescription}</li>
									)
								})}
								</ul>
							</div>
						</div>
					}
					</>
				)	
			})}
		</>
	)
}

export default ShowProductDetails;
