import React from 'react';
import CommunicationInput from './CommunicationInput';
const CallLogDetails = (props)=>{
    const {callLogData}=props
    return( 
        <CommunicationInput
            {...props}
            communicationData = {callLogData}
        />
    )
}
export default CallLogDetails;