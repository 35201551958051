import React from 'react'

const UnRegistredProductList = (props) => {
	const {selectedID,unRegisteredProductList,showProductetails} = props; 

	return (
		<>
			<ul class="nav nav-pills nav-stacked product-list text-left">
				{
					unRegisteredProductList && unRegisteredProductList.map((unRegProd)=>{
						return(
							<li role="presentation"  className={(unRegProd.ConsumerUnregisteredProductID === selectedID)?'active nav-no-top-border':""} onClick={() => showProductetails(unRegProd)}>
								<a href class="nav-no-side-border">{unRegProd.ProductName || unRegProd.BrandName || unRegProd.ProductSubcategory}</a>
							</li>
						)
					})
				}
			</ul>
		</>
	)
}

export default UnRegistredProductList
