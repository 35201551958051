
import React, { useState,useEffect } from "react";
const EstimateSubmissionTable = (props) => {
    const {currencySymbol,estimates,publicUrl,id,rightButtonFlag} = props;
    const [displayIndex,setDisplayIndex] = useState([])
   const handleAccordian = (id) =>{
        if(displayIndex.indexOf(id)===-1){
            setDisplayIndex([...displayIndex,id])
        }else{
            let indexCpoy = [...displayIndex];
            indexCpoy.splice(id, 1);
            setDisplayIndex([...indexCpoy]);
        }
   }
   useEffect(() => {
        if(rightButtonFlag){
            setDisplayIndex(estimates.map((item,i)=>item.submissions[0]._id)); 
        }else{
            setDisplayIndex([])
        }
    }, [rightButtonFlag]);
    return (
        <>
            {estimates.map((item,i) => (
                <div className="padding0 row-shadow paddingTB15 col-sm-12 ">
                    <div className="estimate-text col-sm-12">
                        <div className="estimate-text col-sm-2">
                            {item.name}
                        </div>
                        <div className="estimate-text col-sm-2">
                            {item.submissions[0].partDescription}
                        </div>
                        <div className="estimate-text col-sm-2">
                            {item.submissions[0].remark}
                        </div>
                        <div className="estimate-text col-sm-1 text-center">
                            {item.submissions[0].taxType}
                        </div>
                        <div className="estimate-text col-sm-1 text-center">
                            {item.submissions[0].taxSlab}
                        </div>
                        <div className="estimate-text col-sm-1 text-right">
                            <span
								dangerouslySetInnerHTML={{
									__html: currencySymbol,
								}}
							></span>
                            {item.submissions[0].taxAmount.toFixed(2)}
                        </div>
                        <div className="estimate-text col-sm-1 text-right">
                            <span
								dangerouslySetInnerHTML={{
									__html: currencySymbol,
								}}
							></span>
                            {item.submissions[0].totalAmount.toFixed(2)}
                        </div>
                        <div className="estimate-text col-sm-1">
                            latest
                        </div>
                        {item.submissions && item.submissions.length > 1 && ( <div className="estimate-text col-sm-1 cursor-pointer" data-toggle="collapse" data-target={"#accordion"+id+i}>
                            <span className='accordArrow' onClick={() => handleAccordian(item.submissions[0]._id)}>
                                <img 
                                    className='arrowIcons'
                                    src={publicUrl+(displayIndex.indexOf(item.submissions[0]._id)>-1?'/imgs/upArrow.svg':'/imgs/downArrow.svg')}
                                    width={'35px'} 
                                />
                            </span> 
                        </div>)}
                    </div>
                    <div className={displayIndex.indexOf(item.submissions[0]._id)>-1?"estimate-text col-sm-12 padding0 collapse height-inherit in":"estimate-text col-sm-12 padding0 collapse"} id={"accordion"+id+i}>
                        {item.submissions.slice(1).map((estimateRow,index)=>{
                            return (
                                <div className="estimate-text paddingTB15 col-sm-12">
                                    <div className="estimate-text col-sm-2">
                                        
                                    </div>
                                    <div className="estimate-text col-sm-2">
                                        {estimateRow.partDescription}
                                    </div>
                                    <div className="estimate-text col-sm-2">
                                        {estimateRow.remark}
                                    </div>
                                    <div className="estimate-text col-sm-1 text-center">
                                        {estimateRow.taxType}
                                    </div>
                                    <div className="estimate-text col-sm-1 text-center">
                                        {estimateRow.taxSlab}
                                    </div>
                                    <div className="estimate-text col-sm-1 text-right">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: currencySymbol,
                                            }}
                                        ></span>
                                        {estimateRow.taxAmount.toFixed(2)}
                                    </div>
                                    <div className="estimate-text col-sm-1 text-right">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: currencySymbol,
                                            }}
                                        ></span>
                                        {estimateRow.totalAmount.toFixed(2)}
                                    </div>
                                    <div className="estimate-text col-sm-1">
                                        {index+1}
                                    </div>
                                    <div className="estimate-heading col-sm-1"></div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            ))}
        </>
    )
}

export default EstimateSubmissionTable;