import React,{useCallback,useRef,useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader,setAutocomplete} from 'sfy-react';
import Swal from "sweetalert2";
import {findIndex} from 'lodash';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import {displayError,regexTest,generateUUID} from '../../../../../Utility/Utils';
import ChooseConsumerAddress from './ChooseConsumerAddress';
import AddNewAddress from '../../../../../components/AddNewAddress/AddNewAddress';
import './AddressDetails.scss'

const AddressDetails = (props) => {
	const {apiCall,countriesChecks,consumerServiceRequest,selectedCountryData,consumerDetails,localString,canUpdateServiceRequest,accessFn} = props;
	const [loader, setLoader] = useState(false);
	const [newAddress, setNewAddress] = useState(false)
	const [address, setAddress] = useState({})
	const [editAddress, setEditAddress] = useState(false)

	const editSelectedAddress = () =>{
		setNewAddress(true)
	}
	
	const editIndividualAddress = (e,address) =>{
		e.preventDefault();
		let editableData = {}
		if(address){
			if(address.Address){
				editableData.Address = address.Address
			}
			if(address.AddressType){
				editableData.AddressType = address.AddressType
			}
			if(address.City){
				editableData.City = address.City
			}
			if(address.ConsumerFavouriteLocationID){
				editableData.ConsumerFavouriteLocationID = address.ConsumerFavouriteLocationID
			}
			if(address.ConsumerID){
				editableData.ConsumerID = address.ConsumerID
			}
			if(address.Country){
				editableData.Country = address.Country
			}
			if(address.Landmark){
				editableData.Landmark = address.Landmark
			}
			if(address.Country){
				editableData.Country = address.Country
			}
			if(address.Lat){
				editableData.Lat = address.Lat
			}
			if(address.Lng){
				editableData.Lng = address.Lng
			}
			if(address.State){
				editableData.State = address.State
			}
			if(address.StateID){
				editableData.StateID = address.StateID
			}
			if(address.Zipcode){
				editableData.Zipcode = `${address.Zipcode}`
				editableData.PinCode = `${address.Zipcode}`
			}
			if(address.District){
				editableData.District = address.District
			}
			if(address.UserLandmark){
				editableData.UserLandmark = address.UserLandmark
			}
		}
		setAddress(editableData)
		setEditAddress(true)
	}

	return (
		<>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper 
						bodyClassName="panel-body-no-padding" 
						title={localString("Address Details")}
						customSymbol={(newAddress || editAddress)?'hide-address' :"glyphicon glyphicon-plus"}
						showDownloadButtonIcon={canUpdateServiceRequest && !accessFn('RECYCLE_ADMIN_VIEW_ONLY') && !accessFn('READ_ONLY')}
						editDeviceClick={editSelectedAddress}
					>
					<div className="Address-Details-section">
					{
						!newAddress && !editAddress &&
						<ChooseConsumerAddress
							{...props}
							editIndividualAddress={editIndividualAddress}
						/>
					}
					{
						newAddress && 
						<AddNewAddress
							{...props}
							setLoader={setLoader}
							vcsrLayout={true}
							identifier="detailAddress"
							countryConfig={countriesChecks.countryConfig}
							cid={consumerDetails.ConsumerID}
							pinCodeLengthArr={countriesChecks.intAllowedPinCodeLengths}
							skipZipCodeCheck={countriesChecks.skipZipCodeCheck}
							setShowNewAddress={setNewAddress}
							address={address} setAddress={setAddress}
							countryid={selectedCountryData.CountryID}
							countryCode={selectedCountryData.CountryCode}
						/>
					}
					{
						editAddress && 
						<AddNewAddress
							{...props}
							setLoader={setLoader}
							vcsrLayout={true}
							identifier="detailAddress"
							countryConfig={countriesChecks.countryConfig}
							cid={consumerDetails.ConsumerID}
							pinCodeLengthArr={countriesChecks.intAllowedPinCodeLengths}
							skipZipCodeCheck={countriesChecks.skipZipCodeCheck}
							setShowNewAddress={setEditAddress}
							address={address} setAddress={setAddress}
							countryid={selectedCountryData.CountryID}
							countryCode={selectedCountryData.CountryCode}
						/>
					}
					</div>
					</PageContentWrapper>
				</div>
			</div>
		</>
	)
}

export default AddressDetails
