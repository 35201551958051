import React from 'react';
import {Button, Input, Modal} from 'sfy-react';
import {checkAlphaNumeric} from '../../../../../Utility/Utils';
import './TaskList.scss';

const RemarkModal = (props) => {
	const  {localString, remarkModal, closeRemarkModal, remarkValue, setRemarkValue, saveTaskRemark, remarkDisableBtn } = props;

	return (
		<Modal showModal={remarkModal} closeModal={closeRemarkModal} showCloseBtn={true} className="remark-modal">
			<Modal.Header>
				<h4 className="modal-title">{localString("Edit Remark")}</h4>   
			</Modal.Header>
			<Modal.Body>    
				<div className="request-filter">
					<div className="form-group">
						<Input
							value={remarkValue.remarkVal}
							inputType="textarea"
							handleOnChange={(e) => {
								let text = e.target.value;
								if(checkAlphaNumeric(text)){    
									setRemarkValue({...remarkValue,remarkVal:text})
								}else if(text == ""){
									setRemarkValue({...remarkValue,remarkVal:""})
								}
							}}
							id="editRemarkTextArea"
							label={localString('Remark')}
							labelAlign='top'
						/>
					</div>
					<div className="form-group">
						<Button type="submit" isDisabled={remarkDisableBtn} handleOnClick={saveTaskRemark} text={localString('Save')} className='btn button-servify '></Button>
						<Button handleOnClick={closeRemarkModal} text={localString('Cancel')} className='btn button-servify'></Button>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	)
}

export default RemarkModal
