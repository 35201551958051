import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Input } from 'sfy-react';
import Swal from 'sweetalert2';

import IntlTelInputWrapper from '../../../components/IntlTelInputWrapper/IntlTelInputWrapper';
import { checkEmail, checkMobileNumber, checkName, displayError, getMobileMaxLength } from '../../../Utility/Utils';

export default function SecondaryCommunicationDetails(props) {
	const { 
		apiCall, localString, setLoader, secContactDetails, setSecContactDetails, consumerObj, defaultSecContactDetails,
		secondaryCommunicationSubmit, intlTelCountryConfig, parentCountry, parentMobileMaxLength
	} = props;
	const qFilter = JSON.parse(props.qFilter);
	const [countryCodeArr, setCountryCodeArr] = useState(qFilter.countrycode || []);
	const [country, setCountry] = useState(parentCountry ? parentCountry : 'in');
	const [diffContactDetails, setDiffContactDetails] = useState(false);
	const [mobileMaxLength, setMobileMaxLength] = useState([]);

	const isValid =  !diffContactDetails || diffContactDetails && secContactDetails.secondaryName && checkName(secContactDetails.secondaryName) && (
		(secContactDetails.secondaryEmailID && checkEmail(secContactDetails.secondaryEmailID) && secContactDetails.secondaryMobileNo && checkMobileNumber(secContactDetails.secondaryMobileNo) && mobileMaxLength.includes(secContactDetails.secondaryMobileNo.length)) ||
		(!secContactDetails.secondaryMobileNo && secContactDetails.secondaryEmailID && checkEmail(secContactDetails.secondaryEmailID)) ||
		(!secContactDetails.secondaryEmailID && secContactDetails.secondaryMobileNo && checkMobileNumber(secContactDetails.secondaryMobileNo) && mobileMaxLength.includes(secContactDetails.secondaryMobileNo.length))
	)

	const getCountries = () => {
		setLoader(true);
		let reqObj = {
			countryConfig: true,
			specificCountryCode: country.toUpperCase()
		}
		apiCall({
			url: 'getCountries',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success){
					if(result.data) {
						let countryData = result.data.countries.length && result.data.countries[0];
						let maxLength = getMobileMaxLength(countryData);
						setMobileMaxLength(maxLength);
					} else {
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError('No country data found.')),
						});
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		if(parentCountry == country) {
			setMobileMaxLength(parentMobileMaxLength);
		}else {
			getCountries();
		}
	}, [country]);

	const handleSubmit = () => {
		if(!diffContactDetails) {
			setSecContactDetails({
				secondaryName: consumerObj.name,
				secondaryMobileNo: consumerObj.mobileNo,
				secondaryEmailID: consumerObj.emailID,
				secondaryDialCode: intlTelCountryConfig.dialCode
			})
		}
		secondaryCommunicationSubmit()
	}

	return (
		<>
			<div id='SecCommunicationDetails' className="raiseReqSection">
				<div className='secondaryCommunicationDetails'>						
					<div className="form-label">
						<label>{localString("Communication Details")}</label>
					</div>
					<div className="row">
						<div className="col-sm-5 col-xs-11 col-sm-offset-1 col-xs-offset-1 padding-left50">
							<div className="radio">
								<input
									type="radio"
									name="contactDetailsTypeRadio"
									id="sameSecContact"
									onClick={() => {
										setSecContactDetails(defaultSecContactDetails);
										setDiffContactDetails(false);
									}}
									checked={!diffContactDetails}
								/>
								<label for="sameSecContact">{localString("Use Same Contact Details")}</label>
							</div>
						</div>
						<div className="col-sm-5 col-xs-11 col-sm-offset-1 col-xs-offset-1 padding-left50">
							<div className="radio">
								<input
									type="radio"
									name="contactDetailsTypeRadio"
									id="diffSecContact"
									onClick={() => setDiffContactDetails(true)}
									checked={diffContactDetails}
								/>
								<label for="diffSecContact">{localString("Use Different Contact Details")}</label>
							</div>
						</div>
					</div>
					<div className="form-group">
						<label for="secondaryName" className="text-capitalize form-label">{localString("Full name")}</label>
						<div className="textbox-padding">
							{ !diffContactDetails && <p>{consumerObj.name}</p> }
							{ 
								diffContactDetails && 
									<Input
										value={secContactDetails.secondaryName}
										handleOnChange={(e) => {
											setSecContactDetails({ ...secContactDetails, secondaryName: e.target.value });
										}}
										className={{
											containerClass: 'input-container',
											inputClass: 'input'
										}}
									/>
							}
						</div>
					</div>
					<div className="form-group">
						<label for="secondaryMobileNo" className="text-capitalize form-label">{localString("Mobile number")}</label>
						<div className="textbox-padding">
							{ !diffContactDetails && <p>{consumerObj.mobileNo}</p> }
							{ 
								diffContactDetails && 
									<IntlTelInputWrapper 
										value={secContactDetails.secondaryMobileNo}
										handleOnChange={(val, countryData) => {
											setSecContactDetails({  ...secContactDetails, 
												secondaryMobileNo: val, 
												secondaryDialCode: '+' + countryData.dialCode
											});
											setCountry(countryData.iso2);
										}}
										onFlagSelect={(countryData) => {
											setSecContactDetails({ ...secContactDetails, secondaryDialCode: '+' + countryData.dialCode });
											setCountry(countryData.iso2);
										}}
										maxLength={mobileMaxLength}
										intlTelInputClassWrapper={{
											formControl: 'form-group intel-input',
											label: 'form-label',
											containerClass: 'input-container px-10 input-tel-container',
											inputClass: 'input'
										}}
										defaultCountry={country ? country.toLowerCase() : 'in'}
									/>
							}
						</div>
					</div>
					<div className="form-group">
						<label for="secondaryEmailID" className="text-capitalize form-label">{localString("Email ID")}</label>
						<div className="textbox-padding">
							{ !diffContactDetails && <p>{consumerObj.emailID}</p> }
							{ 
								diffContactDetails && 
									<Input
										value={secContactDetails.secondaryEmailID}
										handleOnChange={(e) => {
											setSecContactDetails({ ...secContactDetails, secondaryEmailID: e.target.value });
										}}
										className={{
											containerClass: 'input-container',
											inputClass: 'input'
										}}
									/>
							}
						</div>
					</div>
					<div className="text-right">
						<Button
							className="btn button-servify nextBtn" 
							onClick={() => handleSubmit()}
							isDisabled={!isValid}
						>
							<div className="button-next"></div>
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}