import React, { useState } from "react";
import { Button } from "sfy-react";
import FilterInputs from "../../components/FilterInputs/FilterInput";

const operatorsList = [
	{ value: "==" },
	{ value: "!=" },
	{ value: ">=" },
	{ value: "<=" },
	{ value: ">" },
	{ value: "<" },
	{ value: "in" },
];

const AddRule = (props) => {
	const { addToRulesArray, riskRealmsList, localString } = props;
	const [value, setValue] = useState({
		RuleEntityRealmID: "",
		RuleVariable: "",
		RuleOperator: "",
		RuleEntityID: "",
	});

	const handleSubmit = (e) => {
		if (
			value.RuleEntityRealmID &&
			value.RuleVariable &&
			value.RuleOperator &&
			value.RuleEntityID
		) {
			addToRulesArray(value);
			setValue({
				RuleEntityRealmID: "",
				RuleVariable: "",
				RuleOperator: "",
				RuleEntityID: "",
			});
		} else {
			return;
		}
	};

	let RulesInput = [
		{
			labelText: localString("Variable"),
			dataKey: "RuleVariable",
			showFilter: true,
			type: "Dropdown",
			placeholder: "Variable",
			dropdownList: riskRealmsList,
			classObject: {
				container: "customised-border customised-border-radius",
			},
			oninputFunc: (val) => {
				setValue((prevData) => ({
					...prevData,
					RuleVariable: val.value,
					RuleEntityRealmID: val.entity_realm_id,
				}));
			},
		},
		{
			labelText: localString("Operator"),
			dataKey: "RuleOperator",
			showFilter: true,
			type: "Dropdown",
			placeholder: "Operator",
			dropdownList: operatorsList,
			classObject: {
				container: "customised-border customised-border-radius",
			},
			oninputFunc: (val) => {
				setValue((prevData) => ({
					...prevData,
					RuleOperator: val.value,
				}));
			},
		},
		{
			labelText: localString("Value"),
			dataKey: "RuleEntityID",
			showFilter: true,
			placeholder: "Value",
			className: { input: "customised-border customised-border-radius" },
			oninputFunc: (val) => {
				setValue((prevData) => ({ ...prevData, RuleEntityID: val }));
			},
		},
	];

	return (
		<div className="row add-rule-wrapper">
			<div className="col-sm-9">
				<FilterInputs
					{...props}
					filterInputs={RulesInput}
					filterData={value}
					hideSaveButton={true}
					hideClearButton={true}
				/>
			</div>
			<div className="col-sm-3">
				<Button handleOnClick={handleSubmit}>
					<span className="glyphicon glyphicon-plus-sign"></span>
				</Button>
			</div>
		</div>
	);
};

export default AddRule;
