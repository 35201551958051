import React, { useEffect, useState } from 'react';
import {Modal,Button,Dropdown,Input} from 'sfy-react';
import Swal from "sweetalert2";
import { returnTimezone,displayError } from '../../../../../Utility/Utils';
import moment from 'moment';
import { result } from 'lodash';
const CreateVisitInput=(props)=>{
	const {
		scheduleDateArray,getRequestById,consumerServiceRequest,selectedEngineerData,apiCall,
		closeModal,localString,ConsumerServiceRequestID,selectedScheduleDate,scheduleSlotArray,slotAvailable,setScheduleSlotArray,
		setSelectedScheduleDate,setSelectedSlot, setSlotAvailable,selectedSlot,setLoader,isBoseFlow,countriesChecks
	} = props;

	const [creatVisitText, setCreatVisitText] = useState("");
	const [selectedEngineer, setSelectedEngineer] = useState();
    const [filterEngineer,setFilterEngineer]=useState();
	const [serviceProviderName, setServiceProviderName] = useState('');
	const [phoneNo, setPhoneNo] = useState('');

	useEffect(()=>{
		for (let i = scheduleSlotArray && scheduleSlotArray.length - 1; i >= 0; i--) {
			if ( scheduleSlotArray[i].IsActive) {
				setSelectedSlot(scheduleSlotArray[i])
				setSlotAvailable(true)
			}
		}
	},[scheduleSlotArray])

    const scheduleDateClick=(e)=>{
		setSelectedScheduleDate(e);
		setScheduleSlotArray(e.slots);
		setSlotAvailable(false)
	}

    const scheduleSlotClick=(e) =>{
		if (e.IsActive) {
			setSelectedSlot(e)
		}
	}

    const createVisitEngineer = (remark, ServiceLocationEngineerID) => {
		let data = {
			"ConsumerServiceRequestID": ConsumerServiceRequestID,
			"ScheduledDateTime": selectedScheduleDate && moment(selectedScheduleDate.date).utc(),
			"ScheduledFromTime": selectedSlot && selectedSlot.StartTimeVal,
			"ScheduledToTime": selectedSlot && selectedSlot.EndTimeVal,
			"Remarks": remark,
			"ServiceLocationEngineerID" : ServiceLocationEngineerID && ServiceLocationEngineerID,
			"PartnerServiceLocationID": consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID,
			"ServiceTypeID":consumerServiceRequest && consumerServiceRequest.ServiceTypeID,
			"timezone": returnTimezone(),
			"LocalTimeZone": selectedSlot && selectedSlot.timeZone,
			"EngineerApplicable": consumerServiceRequest && consumerServiceRequest.servicelocation && consumerServiceRequest.servicelocation.EngineerApplicable
		};
		data.autoAssign = isBoseFlow ? true : undefined;
		if(consumerServiceRequest.servicelocation && !consumerServiceRequest.servicelocation.EngineerApplicable){
			data.ServiceProvider = serviceProviderName;
			data.ContactNo = phoneNo;
		}
        setLoader(true);
		closeModal()
		apiCall({
			url: "createVisit",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					Swal.close();
					setCreatVisitText("")
					getRequestById();
				} else {
					Swal.fire({
                        title :'Oops...',
                        text: localString('Something went wrong'),
						icon: "error",
						
					});
				}
			},
		});
    }

    const rescheduleRequest = () => {
		let serviceLocationEngineerId = selectedEngineer?.ServiceLocationEngineerID ;
		if(consumerServiceRequest.servicelocation && !consumerServiceRequest.servicelocation.EngineerApplicable) {
			if(!serviceProviderName)  {
				Swal.fire({
					title:'', 
					text:localString('Please enter service provider name'), 
					icon:'error'
				});
				return;
			} else if(!phoneNo)  {
				Swal.fire(
					{
						title:'', 
						text:localString('Please enter service provider phone number'), 
						icon:'error'
					});
				return;
			}
			if(selectedEngineerData && selectedEngineerData.length) serviceLocationEngineerId = selectedEngineerData[0]?.ServiceLocationEngineerID;
        }
        let reschedule_text = creatVisitText;
		if(slotAvailable && reschedule_text && ((serviceLocationEngineerId) || (consumerServiceRequest.servicelocation && !consumerServiceRequest.servicelocation.EngineerApplicable))){//((self.selectedEngineer && self.selectedEngineer.serviceLocationEngineerId) || (self.consumerServiceRequest.servicelocation && !self.consumerServiceRequest.servicelocation.EngineerApplicable))
			Swal.fire({
			title:localString("Are you sure you want to create a visit?"),
			showCancelButton: true,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("Ok"),
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.close();
				if(reschedule_text){
                    createVisitEngineer(reschedule_text, serviceLocationEngineerId);
                }
			}
		});
		}else if(!reschedule_text){
            Swal.fire({
				title: "",
				text: localString('Please specify the reason for reschedule'),
				icon: "error",
			})
        }else if(!filterEngineer){
            Swal.fire({
				title: "",
				text: localString('Please select an engineer to assign'),
				icon: "error",
			})
        }else if(!slotAvailable){
            Swal.fire({
				title: "",
				text: localString('Please select a slot for reschedule'),
				icon: "error",
			})
        }
    }
    
    return (
		<>
            <div className="modal-fluid-form-container">
                <div className='fluid-form-content fluid-form-active'>
					{consumerServiceRequest && consumerServiceRequest.servicelocation && consumerServiceRequest.servicelocation.EngineerApplicable && <div className='createvisit-input'>
                    <label className="text-capitalize">{localString("Select an Engineer to assign")}</label>
						<div className='label_mb' >
							<Dropdown
								id='dropdown'
								value={filterEngineer}
								options={selectedEngineerData}
								handleOnChange={(item) => {
									setFilterEngineer(item.Name);
									setSelectedEngineer(item);
								}}
								showTooltip={false}
								showDownArrow={false} 
								filterBy='value'
							/>
						</div>
					</div>}
						{consumerServiceRequest && consumerServiceRequest.servicelocation && !consumerServiceRequest.servicelocation.EngineerApplicable && <div className='createvisit-input'>
							<label className="text-capitalize">{localString("Service provider name")}</label>
							<div className="row visit-engineer text-padding">
								<div className= "col-sm-12">
									<Input 
										inputType='text'
										value={serviceProviderName}
										handleOnChange={(e) => {
											setServiceProviderName(e.target.value.replace(/[0-9`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, ''))
										}}
										autoComplete='off'
										id='visit-engineer-name'
										name='visit-engineer-name'
									/>
								</div>
							</div>
										
							<label className="text-capitalize">{localString("Phone")}</label>
							<div className="row visit-engineer text-padding">
								<div  className= "col-sm-12">
									<Input 
										inputType='text'
										value={phoneNo}
										handleOnChange={(e) => {
											setPhoneNo(e.target.value.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",<>.\ \{\}\[\]\\\/]/gi, ''))
										}}
										autoComplete='off'
										id='visit-engineer-phone'
										name='visit-engineer-phone'
										maxLength = {countriesChecks.intAllowedMobileLengths}
									/>
								</div>
							</div>
						</div>
					}
                    <label className="text-capitalize">{localString("Select a Date")}</label>
                    <div className='row'>
							{
								scheduleDateArray && scheduleDateArray.map(day=>(
									<div className='col-sm-3 col-xs-4'>
										<div className={   day.date==selectedScheduleDate.date ?  'item-active item slot-item' : 'item slot-item'   } onClick={()=>{scheduleDateClick(day)}}>
											<p className="">{day.day} {day.month}</p>
										</div>
									</div>
								))
							}
						</div>
                        <label className="text-capitalize">{localString("Select a Slot")}</label>
                        <div className='row'>
							{
								!slotAvailable ?  
									<div className="col-sm-8 col-sm-offset-2 text-center  text-capitalize">
										{localString("No slots Available")}
									</div>:
									<div className="" >
									{
										scheduleSlotArray && scheduleSlotArray.map(slotArray=>(
											<div className='col-sm-3 col-xs-4' onClick={()=> scheduleSlotClick(slotArray)}>
												<div className={`item slot-item ${((slotArray.StartTimeVal == selectedSlot.StartTimeVal) &&
													(slotArray.EndTimeVal == selectedSlot.EndTimeVal)) ?'item-active  '  : ' ' } ${!slotArray.IsActive ?"item-disabled":""}`}  >
													<p className="address-p">{slotArray.StartTime} - {slotArray.EndTime}</p>
												</div>
											</div>
										))
									}
									</div>
								}
						</div>
					</div>
					<div className="text-field">
						<div className="col-sm-12">
							<label className="text-capitalize">
								{localString("Reason for Schedule")}
							</label>
							<div className='textarea'>
								<Input 
									className="reschedule-mesg" 
									inputComponent='textarea' 
									id="reschedule-text-onsite" 
									name="reschedule-request" 
									value={creatVisitText}
									handleOnChange={(e) => {
										setCreatVisitText(e.target.value)
									}}
									/>
							</div>
						</div>
					</div>
					<div className="footer">
						<Button type="button" className="btn button-servify button-stack" onClick={()=>rescheduleRequest()} >{localString("Create")}</Button>
						<Button type="button" className="btn button-servify" onClick={ closeModal}>{localString("Close")}</Button>
					</div>
            </div>
        </>
		)
}
export default CreateVisitInput;


