import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import {displayError} from '../../../../../Utility/Utils';
import ReplacementDetailsTable from './ReplacementDetailsTable';

const ReplacementDetails = (props) => {
	const {apiCall, localString, consumerServiceRequest} = props;
	const [loader, setLoader] = useState(false);
	const [replacementRequestData, setReplacementRequestData] = useState([])
	const [selectedOption, setSelectedOption] = useState(null)

	useEffect(() => {
		getReplacementRequestDetails()
	}, [])

	useEffect(() => {
		if(replacementRequestData && replacementRequestData.ReplacementOptions && replacementRequestData.ReplacementOptions.length > 0 && replacementRequestData.SelectedReplacementOption) {
			getSelectedOption();
		}
	}, [replacementRequestData])

	const getSelectedOption = () => {
		let options = replacementRequestData.ReplacementOptions.find((obj) => {
			return obj.Option == replacementRequestData.SelectedReplacementOption;
		})
		setSelectedOption(options)
	};

	const getReplacementRequestDetails = () => {
		let data = {
			ConsumerServiceRequestID: consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID
		};
		setLoader(true);
		apiCall({
			url:'getReplacementRequestDetails',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let replacementData = result.data;
					setReplacementRequestData(replacementData);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	return (
		<>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper bodyClassName="panel-body-no-padding" title={localString("Replacement Details")}>
						<ReplacementDetailsTable  
							{...props}
							replacementRequestData={replacementRequestData}
							selectedOption={selectedOption}
						/>
					</PageContentWrapper>
				</div>
			</div>
		</>
	)
}

export default ReplacementDetails
