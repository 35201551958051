import React, {useState,useEffect} from 'react'
import ReactDOM from "react-dom";
import { Input} from "sfy-react";
import './FormGroupInput.scss';
import ButtonSwitchInput from '../ButtonSwitchInput/ButtonSwitchInput';
import IntlTelInputWrapper from '../IntlTelInputWrapper/IntlTelInputWrapper'

const FormGroupInput = (props) => {
    const {formInputs, isSubmitDisabled, onSubmitClick, wrapperClass} = props;
    const inputClassWrapper = {
        formControl: 'form-group',
        label: 'form-label',
        inputContainer: 'textbox-padding'
    };
    const intlTelInputClassWrapper = {
        formControl: 'form-group',
        label: 'form-label',
        containerClass: 'input-container textbox-padding',
        inputClass: 'input'
    };
    return (
        <form className={"fluid-form-content fluid-form-active " + (wrapperClass ? wrapperClass : '')} onSubmit={onSubmitClick}>
            {formInputs && formInputs.length > 0 && formInputs.map((obj, index) => {
                return(
                obj && 
                <>
                    {obj.intelInput ? 
                        <IntlTelInputWrapper
                            value = {obj.value}
                            handleOnChange = {(val, countryData) => obj.changeFunc(val, countryData)}
                            onFlagSelect = {(countryData) => obj.onFlagSelect(countryData)}
                            label = {obj.label}
                            maxLength = {obj.maxlength}
                            key={index}
                            intlTelInputClassWrapper={intlTelInputClassWrapper}
                            defaultCountry={obj.defaultCountry}
                        ></IntlTelInputWrapper> : 
                        <Input
                            value = {obj.value}
                            handleOnChange = {(e) => obj.changeFunc(e.target.value)}
                            label = {obj.label}
                            maxLength = {obj.maxlength}
                            labelAlign = 'top'
                            className= {inputClassWrapper}
                            key={index}
                            otherProps={{readOnly: obj.isReadOnly}}
                            message={!obj.isValid && obj.value.length > 0 && obj.showErrorMesage && {type: 'error', value: obj.errorMessage}}
                        >
                        {obj.showSwitchButtons && obj.switchButtonData && obj.switchButtonData.length > 0 &&
                            <ButtonSwitchInput switchButtons={obj.switchButtonData}></ButtonSwitchInput>
                        }
                        </Input>
                    }
                    
                    {obj.showORSection && <div className="row">
                        <div className="col-sm-12"><p className="text-center text-bold separator-style">OR</p></div>
                    </div>}
                </>
            )})}
            <div className="text-right">
                <input type="submit" className="btn button-next" disabled={isSubmitDisabled} value="" />
            </div>
        </form>
    )
}

export default FormGroupInput;
