import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Loader } from 'sfy-react';
import Swal from "sweetalert2";

import './Announcements.scss'
import PageHeader from '../../components/PageHeader/PageHeader';
import NewAnnouncement from './NewAnnouncement/NewAnnouncement';
import { displayError } from '../../Utility/Utils';
import AnnouncementList from './AnnouncementList';
import AnnouncementGrid from './AnnouncementGrid';

const Announcements = (props) => {

	const { accessFn, localString, apiCall } = props;
	const _ = require("lodash");
	const [loader, setLoader] = useState(false);
	const [gridData, setGridData] = useState([]);
	const [newAnnouncement, setNewAnnouncement] = useState(false);
	const [notificationType, setNotificationType] = useState('received');

	const buttonHeader = [
		{
			buttonText: localString("New Announcement"),
			btnIcon: "glyphicon glyphicon-plus",
			showButton: accessFn('SEND_RETAILER_ANNOUNCEMENT'),
			onButtonClick: () => {
				setNewAnnouncement(true);
			}
		}
	];

	const getNotificationInfo = () => {
		setLoader(true);
		let reqObj = {
			Status: ["Read", "Pending", "Unread"],
			notificationType: notificationType
		}
		apiCall({
			url: 'getNotificationInfo',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data){
					let data = result.data
					// setData(result.data);
					let notifdata = _.groupBy(data, function(item) {
						return item.NotificationGroupNumber;
					});
					let notificationData = [];
					let obj = {};
					obj.ReadCount = 0;
					obj.UnReadCount = 0;
					obj.PendingCount = 0;
					for (let val in notifdata) {
						obj.NotificationGroupNumber = val;
						for (let key in notifdata[val][0]) {
							obj[key] = notifdata[val][0][key];
						}
						notificationData.push(_.cloneDeep(obj));
					};
					notificationData = _.orderBy(notificationData, 'NotificationGroupNumber', 'desc');
					for (var i = data.length - 1; i >= 0; i--) {
						for (var j = notificationData.length - 1; j >= 0; j--) {
							if (notificationData[j].NotificationGroupNumber === data[i].NotificationGroupNumber) {
								if (data[i].Status === 'Read' || data[i].IsRead) {
									notificationData[j].ReadCount++;
								}
								if (data[i].Status === 'Pending') {
									notificationData[j].PendingCount++;
								}
								if (data[i].Status === 'Unread' || !data[i].IsRead) {
									notificationData[j].UnReadCount++;
								}
							}
						};
					};
					setGridData(notificationData);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		getNotificationInfo();
	}, [notificationType]);

	return (
		<>
			{
				loader && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<div className='containerHeight'>
				{
					newAnnouncement ? 
						<NewAnnouncement setNewAnnouncement={setNewAnnouncement} {...props}/> : 
						<div className={"panel-warpper"}>
							<div className='announcement-main-div'>
								<PageHeader titleHeader={localString("ANNOUNCEMENT LIST")} localString={localString} btnClass="btn button-servify header-btn button-stack text-capital" headerButtonArray={buttonHeader}/>
								<div className="announcement container-fluid">
									<div className='row'>
										{
											!accessFn('ENGINEER_NOTIFICATION') && 
											<>
												{
													accessFn('SEND_RETAILER_ANNOUNCEMENT') && 
													<div className="col-xs-12 col-sm-6 padding0" onClick={() => setNotificationType('sent')}>
														<div className={`notificationType ${notificationType === 'sent' ? 'active' : ''}`}>
															{localString('Sent')}
														</div>
													</div>
												}
												<div className={`col-xs-12 ${accessFn('SEND_RETAILER_ANNOUNCEMENT') ? 'col-sm-6' : 'col-sm-12'} padding0`} onClick={() => setNotificationType('received')}>
													<div className={`notificationType ${notificationType === 'received' ? 'active' : ''}`}>
														{localString('Received')}
													</div>
												</div>
											</>
										}
										{
											accessFn('ENGINEER_NOTIFICATION') && accessFn('ENGINEER_FEEDBACK') &&
											<>
												{
													accessFn('SEND_RETAILER_ANNOUNCEMENT') && 
													<div className="col-xs-12 col-sm-6 padding0" onClick={() => setNotificationType('notification')}>
														<div className={`notificationType ${notificationType === 'notification' ? 'active' : ''}`}>
															{localString('Notification')}
														</div>
													</div>
												}
												<div className={`col-xs-12 ${accessFn('SEND_RETAILER_ANNOUNCEMENT') ? 'col-sm-6' : 'col-sm-12'} padding0`} onClick={() => setNotificationType('feedback')}>
													<div className={`notificationType ${notificationType === 'feedback' ? 'active' : ''}`}>
														{localString('Feedback')}
													</div>
												</div>
											</>
										}
									</div>
								</div>
								{/* <AnnouncementList columns={columns} rowData={data} localString={localString}/> */}
								<AnnouncementGrid tableData={gridData} localString={localString} accessFn={accessFn}/>
							</div>
						</div>
				}
			</div>
		</>
	)
}

export default Announcements;