import React from "react";
import { returnLocalDateTime,SERVICETYPE,getStatusString } from "../../Utility/Utils";

import "./RightSidebar.scss";

const RightSidebar = (props) => {
	const {requestPage,title,accessFn,logs,localString,consumerServiceRequest} = props;

	const createReadableTimeString = (time) => {
		let dateString = returnLocalDateTime(time, "DD-MM-YYYY");
		let timeString = returnLocalDateTime(time, "HH:mm");
		return (
			localString("On") +
			" " +
			dateString +
			" " +
			localString("at") +
			" " +
			timeString
		);
	}

    const showStatus = (status) =>{
		let currentStatus = getStatusString(status, consumerServiceRequest.ServiceTypeID, accessFn);
        return currentStatus;
    };

    const getIntStatusColor = function (status) {
		let color = 'gray';
		if (status == 200) {
			color = 'green';
		}
		else {
			color = 'red';
		}
		return color;
	}
	const getIntStatusString = function (status) {
		let statusString = 'Pending';
		if (status == 200) {
			statusString = 'Success';
		}
		else {
			statusString = 'Failed';
		}
		return statusString;
	}

	// let showStatus =  (status)=> {
    //     switch (status) {
    //         case 'Payment received':
    //             status = 'Payment received';
    //             break;
    //         case 'Service cancel':
    //             status = 'Service cancelled';
    //             break;
    //         case 'Repair cancel':
    //             if([SERVICETYPE.RECYCLE_PICK_UP,SERVICETYPE.RECYCLE_DROP_OFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
    //                 status = 'Recycle Request cancelled';
    //             }
    //             else {
    //                 status = 'Repair cancel';
    //             }
    //             break;
    //         case 'Service accepted':
    //             if([13].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
    //                 status = 'Dropoff Request Registered';
    //             }
    //             else {
    //                 status = 'Service accepted';
    //             }
    //             break;
    //         case 'Service initiated':
    //             if([9,13].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
    //                 status = 'Verification Complete';
    //             }
    //             else {
    //                 status = 'Service initiated';
    //             }
    //             break;
    //         case 'Vouchers generated':
    //             if([9,13].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
    //                 status = 'Green Reward Update';
    //             }
    //             else {
    //                 status = 'Vouchers generated';
    //             }
    //             break;
    //         case 'Device recycle initiated':
    //             if([9,13].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
    //                 status = 'Initial Dismantling Completed';
    //             }
    //             else {
    //                 status = 'Device recycle initiated';
    //             }
    //             break;
    //         case 'Service completed':
    //             if([9,13].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
    //                 status = 'Recycle Completed';
    //             }
    //             else {
    //                 status = 'Service completed';
    //             }
    //             break;
    //         case 'QC completed':
    //             if([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) === -1){
    //                 status = 'Repair Completed, Ready To Inform The Customer';
    //             }
    //             break;
    //         case 'Device dispatched':
    //             if([40,41,42,43,44,45,46,47,48,49,50,51].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
    //                 status = 'Device dispatched';
    //             }else{
    //                 status = 'Drop Request Created Post Repair';
    //             }
    //             break;
    //         case 'Pending for Assignment':
    //             if(accessFn('WORKSHOP_FLOW')){
    //                 if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
    //                     status = 'Inspection Completed, Awaiting Engineer Assignment';
    //                 }
    //             }
    //             else {
    //                 status = 'Pending for Assignment';
    //             }
    //             break;
    //         case 'QC pending':
    //             if(accessFn('WORKSHOP_FLOW')){
    //                 if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
    //                     status = 'Repair completed, QC pending';
    //                 }
    //             }
    //             else {
    //             }
    //             break;
    //         case 'Pending for Acceptance':
    //             if(accessFn('WORKSHOP_FLOW')){
    //                 if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
    //                     status = 'Pending for Acceptance by Engineer';
    //                 }
    //             }
    //             else {
    //             }
    //             break;
    //         case 'Work in progress':
    //             if(accessFn('WORKSHOP_FLOW')){
    //                 if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
    //                     status = 'Work in Progress with Engineer';
    //                 }
    //             }
    //             else {
    //             }
    //             break;
    //         case 'QC rejected':
    //             if(accessFn('WORKSHOP_FLOW')){
    //                 if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
    //                     status = 'QC rejected, Work in progress';
    //                 }
    //             }
    //             else {
    //             }
    //             break;
    //         case 'Repair completed and Intimate customer':
    //             if(accessFn('WORKSHOP_FLOW')){
    //                 if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
    //                     status = 'Repair completed, Inform customer';
    //                 }
    //             }
    //             else {
    //             }
    //             break;
    //         case 'Intimate customer':
    //             if(accessFn('WORKSHOP_FLOW')){
    //                 if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
    //                     status = 'Repair completed and Inform customer';
    //                 }
    //             }
    //             else {
    //             }
    //             break;
    //         case 'Device received':
    //             if(accessFn('WORKSHOP_FLOW')){
    //                 if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
    //                     status = 'Device received';
    //                 }
    //             }
    //             else {
    //             }
    //             break;
    //         case 'Device accepted':
    //             if(accessFn('WORKSHOP_FLOW')){
    //                 if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
    //                     status = 'Device accepted';
    //                 }
    //             }
    //             else {
    //             }
    //             break;
    //         case 'Device handed over':
    //             if(accessFn('WORKSHOP_FLOW')){
    //                 if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
    //                     status = 'Device handed over';
    //                 }
    //             }
    //             else {
    //             }
    //             break;
    //         case 'Device inspected':
    //             if(accessFn('WORKSHOP_FLOW')){
    //                 if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
    //                     status = 'Inspection in Progress';
    //                 }
    //             }
    //             else {}
    //             break;
    //     }
    //     return status;
    // };

	const statusWithLogisticsRemarks = [
		'Pickup Request Accepted', 
		'DropOff request accepted', 
		'Doorstep Request Accepted', 
		'Shipment created'
	];

	return (
		<>
			<div className="right-sidebar">
				<a className="sidebar-logo-div sidebar-div sidebar-size track-trace-header">
					<p className="sidebar-label track-and-trace-title">
						{localString(title)}
					</p>
				</a>
				<div className="request-log-group">
					{logs &&
						logs.length > 0 &&
						logs.map((log, index) => {
							return (
								<div className="request-log-item">
									<div className="row request-log-indicator-timeline">
										<div className="col-xs-12 zero-left-padding min-height-label">
											<div className="request-log-indicator">
												<div></div>
											</div>
											<div className="request-log-indicator-time">
												<p
													className="request-log-date"
													data-createddate={
														log.CreatedDate
													}
													data-placement="bottom"
													data-html="true"
													data-container="#track_and_trace_id"
												>
													<span>
														<span className="sidebar-label sidebar-date sidebar-label-heading"> 
															{" "}
															{requestPage ? localString(showStatus(log.Status)) : localString(log.Status)}  
														</span> 
														<br />
														{log.Remarks &&<><span className="sidebar-label sidebar-date remark-block">
															{
																!(statusWithLogisticsRemarks.indexOf(log.Status) > -1 && accessFn('HIDE_REQUEST_DETAILS_REMARKS')) && (log.Remarks && log.Remarks != 'hidden') ? (log.Remarks != 'hidden' 
																? localString(log.Remarks.trim()) : '') 
																: (log.Remark ? localString(log.Remark) : "" )
															}
														</span>
														<br /></>}
														{(log.StatusTime || log.CreatedDate) && <><span className="sidebar-label sidebar-date remark-block">
															{log.StatusTime ? createReadableTimeString(log.StatusTime)
																    : log.CreatedDate ? createReadableTimeString(log.CreatedDate) : ''
                                                            } 
														</span><br/></>}
														{(log.Name || log.UserName) &&
															<span className="sidebar-label sidebar-date remark-block">
															{localString('By')}: 
																{log.Name ? localString(log.Name) : localString(log.UserName )}
														</span>}
													</span>
                                                    { requestPage && accessFn('SHOW_INTEGRATION_LOGS') && log.integrationLog && log.integrationLog.length > 0 && log.integrationLog.map((intLog, intLogIndex) => <span>
                                                        <span 
                                                            className="sidebar-label sidebar-date " 
                                                            style={{paddingLeft: '20px'}}
                                                        >
                                                            {intLog.IsIncomingCall ? '<' + intLog.IntegrationSource : intLog.ApiUrl + '>'}
                                                        </span>
                                                        <br />
                                                        <span 
                                                            className={`sidebar-label sidebar-date ${getIntStatusColor(intLog.IntegrationHttpStatus)}`} 
                                                            style={{paddingLeft: '20px'}}
                                                        >
                                                            {getIntStatusString(intLog.IntegrationHttpStatus)}{intLog.IntegrationHttpStatus != 200 ? ': ' + intLog.Remarks : ''}
                                                        </span>
                                                        <br/>
                                                        <span 
                                                            className="sidebar-label sidebar-date" 
                                                            style={{paddingLeft: '20px', fontStyle: 'italic'}}
                                                        >
                                                            {intLog.CreatedDate ? createReadableTimeString(intLog.CreatedDate) : ''}
                                                        </span>
                                                        <br/>
                                                    </span>)

                                                    }
												</p>
											</div>
										</div>
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</>
	);
};

export default RightSidebar;
