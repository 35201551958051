import React, { useState,useEffect } from 'react';
import {Button,Dropdown,Modal} from 'sfy-react';
import Swal from 'sweetalert2';
import { displayError } from '../../../../../Utility/Utils';
import LocationAutoComplete from '../../../../../components/LocationAutoComplete/LocationAutoComplete';
import moment from 'moment';
import './ReassignServiceCenter.scss'
const ReassignServiceCenterInput=(props)=>{
    const {apiCall,publicUrl,localString,showModal,closeReassignModal,consumerServiceRequest,setLoader,setShowModal,getRequestById,countryCode} = props;
    const [serviceCenter,setServiceCenter] = useState([]);
    const [scheduleDateArray,setScheduleDateArray] = useState([]);
    const [scheduleSlotArray,setScheduleSlotArray] = useState([]);
    const [serviceLocation ,setServiceLocation] = useState(null);
    const [serviceCenterName,setServiceCenterName] = useState(null);
    const [scheduleDateVal, setScheduleDateVal] = useState(null);
    const [scheduleDay,setScheduleDay] = useState(null);
    const [selectedSlotVal,setSelectedSlotVal] = useState({
        startTime:'',
        endTime:''
    })
    const [reassignInputField,setReassignInputField] = useState({
        serviceLocation:'',
        serviceCenter:''
    });
    const [formData, setFormData] = useState({
        Landmark: ''
	});
    const [error, setError] = useState({
		Landmark: null,
	});
    const [selectServiceCenter,setSelectServiceCenter] = useState([]);
    const symbol = consumerServiceRequest.CountryCode == 'US' ? 'miles' : 'km';
    const roundOfvalue = (num) => Math.round((num) * 100) / 100;
    useEffect(() => {
        let serviceCenterData = serviceCenter && serviceCenter.map(v=>({...v,value:`${v.ServiceLocationName}, ${v.address} ${v.distance ? '('+roundOfvalue(Number(v.distance))+''+symbol+')' :''}`}));
        setSelectServiceCenter(serviceCenterData);
    }, [serviceCenter])
    
    useEffect(()=>{
        let scheduleDateArr = []
        let slotFirst;
        let dayData;
        let externalSlots = serviceCenter.map(item=>{
            if(item && item.ServiceLocationName == serviceCenterName){
                setServiceLocation(item);
                return scheduleDateArr = item.externalSlots;
            }
        })
        setScheduleDateArray(scheduleDateArr);
        dayData = scheduleDateArr && scheduleDateArr[0];
        slotFirst = dayData && dayData.slots && dayData.slots[0];
        setScheduleDateVal(dayData && dayData)
        setScheduleSlotArray(dayData && dayData.slots)
        setSelectedSlotVal({
            startTime:slotFirst && slotFirst.startTime,
            endTime:slotFirst && slotFirst.endTime
        });
        setScheduleDay(dayData && dayData.date)
    },[reassignInputField.serviceCenter])

    const scheduleDateClick = (day)=>{
        setScheduleDateVal(day)
        setScheduleDay(day.date)
        setScheduleSlotArray(day && day.slots)
        setSelectedSlotVal({
            startTime:day && day.slots[0] && day.slots[0].startTime,
            endTime:day && day.slots[0] && day.slots[0].endTime
        })
    }

    const sheduleSlotClick = (slot)=>{
        setSelectedSlotVal({
            startTime:slot && slot.startTime,
            endTime: slot && slot.endTime
        })
    }
    const updateAddress = (placesObj, field) => {
		if(placesObj && placesObj.formatted_address) {
			let obj = {
				landmark: placesObj.formatted_address
			};
			placesObj.address_components && placesObj.address_components.map(place => {
				if (place && place.types.includes('country')) {
					obj.Country = place.long_name ? place.long_name : ''
				}
                if(place && place.types.includes('administrative_area_level_1')){
                    obj.State = place.long_name ? place.long_name : ''
                }
                if(place && place.types.includes('locality')){
                    obj.City = place.long_name ? place.long_name : ''
                }
                if(place && place.types.includes('postal_code')){
                    obj.Pincode = place.long_name ? place.long_name : ''
                }
			});
			obj.Lat = placesObj.geometry.location.lat();
			obj.Lng = placesObj.geometry.location.lng();
			setFormData({ ...formData, [field]: placesObj?.formatted_address, ...obj});
            checkSelectServiceCenter(obj)
		} else {
			setFormData({ ...formData, [field]: ''});
		}
	}

    const checkSelectServiceCenter = (address) =>{
        let data = {
            ConsumerServiceRequestID:consumerServiceRequest.ConsumerServiceRequestID,
            Lng: address.Lng,
            Lat: address.Lat,
            ConsumerProductID: consumerServiceRequest.ConsumerProductID,
            CountryID: consumerServiceRequest.CountryID,
            ProductSubCategoryID: consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID,
            ProductID:consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductID,
            BrandID:consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID,
            Landmark: address.landmark,
            Radius: 80,
            Zipcode : address.Pincode,
            Category: consumerServiceRequest.servicetype && consumerServiceRequest.servicetype.Category,
            City: address.City,
            PlanCoverageID: consumerServiceRequest.PlanCoverageID,
            SoldPlanCoverageID: consumerServiceRequest.SoldPlanCoverageID,
            SoldPlanID: consumerServiceRequest.SoldPlanID,
            State: address.State,
            Partnered: 1,
            damageId: consumerServiceRequest.ConsumerServiceRequestDetails && consumerServiceRequest.ConsumerServiceRequestDetails.damageId,
            PlanID:consumerServiceRequest.PlanID
        }
        setLoader(true);
		apiCall({
			url: 'getServiceAvailabilityWithLocations',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
                    setServiceCenter(result.data.serviceLocations);
                    let externalSlotArray = []
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    const handleSweetAlert =()=>{
        let serviceCenterArrObj = [{
            name: selectedSlotVal.startTime && selectedSlotVal.endTime ?localString("Scheduled Time Slot"): '-',
            value:  selectedSlotVal.startTime && selectedSlotVal.endTime ? `${selectedSlotVal.startTime} - ${selectedSlotVal.endTime}`: '-'
        },{
            name:scheduleDay ? localString("Scheduled Date") :'-',
            value:scheduleDay ? moment(scheduleDay && scheduleDay).format('DD-MM-YYYY'):'-'
        }, {
            name:reassignInputField && reassignInputField.serviceCenter ? localString("Service Center"): '-',
            value:reassignInputField && reassignInputField.serviceCenter ? reassignInputField.serviceCenter: '-'
        } ,
    ];

        let requesthtml = '<h3 style="margin-bottom:15px;margin-top:0px">'+localString("Service Location is Updated")+'.</h3>'
        requesthtml += '<table class="table" style="margin-bottom:0px">';
        requesthtml += '<tbody>';


        for (let i = serviceCenterArrObj.length - 1; i >= 0; i--) {
            if(serviceCenterArrObj[i]) {
                requesthtml += '<tr>';
                requesthtml += '<td >';
                requesthtml += serviceCenterArrObj[i].name
                requesthtml += '</td>';
                requesthtml += '<td>';
                requesthtml += serviceCenterArrObj[i].value;
                requesthtml += '</td>';
                requesthtml += '</tr>';
            }
        }

        requesthtml += '</tbody>';
        requesthtml += '</table>'
        requesthtml += '</div>';

        Swal.fire({
            icon:'success',
            text:'Service Location is Updated',
            customClass: 'swal-wide',
            showCancelButton: false,	
			closeOnConfirm: false,	
			allowEscapeKey: false,	
			allowOutsideClick: false,		
            confirmButtonText: localString("OK"),
            html:requesthtml	
        })
    }

    const updateServiceAvailability = () =>{
        let data = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            ScheduledDateTime : scheduleDay,
            ScheduledFromTime :selectedSlotVal.startTime ? selectedSlotVal.startTime+':00':undefined,
            ScheduledToTime :selectedSlotVal.endTime ? selectedSlotVal.endTime+':00':undefined,
            Landmark: formData.Landmark,
            Lat: formData.Lat,
            Lng: formData.Lng,
            Zipcode: formData.Pincode,
            reassignPSL: true,
            City:formData.City,

            serviceLocation: {
                ClientServiceLocationID: serviceLocation.ClientServiceLocationID,
                ServiceLocationName: serviceLocation.ServiceLocationName,
                Landmark: serviceLocation.Landmark,
                City: serviceLocation.City,
                State:serviceLocation.State,
                ZipCode: serviceLocation.ZipCode,
                Lat: serviceLocation.Lat,
                Lng: serviceLocation.Lng,
                ContactNo1: serviceLocation.ContactNo1,
                distance: serviceLocation.distance,
                ascGroup: serviceLocation.ascGroup,
                WorkingFrom:serviceLocation.WorkingFrom,
                WorkingTo: serviceLocation.WorkingTo,
                WorkingDays: serviceLocation.WorkingDays,
                TimezoneString: serviceLocation.TimezoneString,
                PartnerServiceLocationID: serviceLocation.PartnerServiceLocationID,
                PartnerID: serviceLocation.PartnerID,
                NameOfFirm: serviceLocation.NameOfFirm,
                address: serviceLocation.address,
                PinCode: serviceLocation.PinCode
            }
        }
        setLoader(true);
		apiCall({
			url: 'updateServiceAvailability',
			data: data,
			callBack: (result) => {
				setLoader(false);
                closeReassignModal();
				if (result.success) {
                    getRequestById();
                    // setUpdateModal(true);
                    handleSweetAlert()
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    return ( <>
        {showModal.showReassignServiceCenterModal &&  <Modal showModal={showModal} closeModal={closeReassignModal} showCloseBtn={true} className='Filter-Modal rejectReasonModal'>
            <Modal.Header>
                <h2> {localString("Change Service Center")} </h2>
            </Modal.Header>
            <Modal.Body>
                <div className='reassign-container'>
                    <div className='ml5 mr5 mt15 mb15'>
                        <label>{localString("Select Location")}</label>
                        <LocationAutoComplete
                            {...props}
                            event='UpdateReassignServiceCenter'
                            value={formData.Landmark}
                            handleOnChange={(e) => {
                                setReassignInputField({...reassignInputField,['serviceCenter']:''});
                                if(!e.target.value) {
                                    setError({ ...error, Landmark: " Please Enter Service Center Location " });
                                    setFormData({ ...formData, Landmark: '' });
                                }else {
                                    setFormData({ ...formData, Landmark: e.target.value,  });
                                    setError({ ...error, Landmark: null });
                                }
                            }}
                            handleSelection={(addressObj) => updateAddress(addressObj, 'Landmark')}
                            dependentStateArr={[formData]}
                        />
                        { error.Landmark && <span className="text-danger">{localString(error.Landmark)}</span>}
                    </div>
                    <div className='reassign-dropdown ml5 mr5 mt15 mb15'>
                        <label>{localString("Select Service Center")}</label>
                        <div className='service-center-input'>
                            <Dropdown
                                value={reassignInputField['serviceCenter']}
                                options={selectServiceCenter}
                                handleOnChange={(data) => {
                                    setServiceCenterName(data.ServiceLocationName);
                                    let value = data.distance  ? `(${roundOfvalue(Number(data.distance))}${symbol})` :''
                                    setReassignInputField({...reassignInputField,['serviceCenter']:`${data.ServiceLocationName}, ${data.address} ${value}`});
                                }}
                                filterBy='value'
                                showDownArrow={true}
                                // showTooltip={true}
                            />
                        </div>
                    </div>
                    <div className='reassign-date-slot ml5 mr5 mt15 mb15'>
                        {
                            scheduleDateArray && scheduleDateArray.length>0 && 
                            <label className="text-capitalize">{localString("Select Date")}</label>
                        }
                        <div className='row'>
                            {
                                scheduleDateArray && scheduleDateArray.length>0 && scheduleDateArray.map(day=>(
                                    <div className='col-sm-3 col-xs-4'>
                                        <div  
                                            className={`item slot-item ${(day.date == (scheduleDateVal && scheduleDateVal.date)) ? 'item-active-border' : ''}`} 
                                            onClick={()=>{scheduleDateClick(day)}}
                                        >
                                            <p className=""> {moment(day.date).format('DD-MM-YYYY')}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='reassign-time-slot ml5 mr5 mt15 mb15'>
                        {
                            scheduleSlotArray && scheduleSlotArray.length>0 && 
                            <label className="text-capitalize">{localString("Select Time Slot")}</label>
                        }
                        {
                            scheduleSlotArray && scheduleSlotArray.length == 0 ?  
                            <div className="col-sm-8 col-sm-offset-2 text-center  text-capitalize">
                                {localString("No slots Available")}
                            </div>
                            :
                            <div className="row" >
                            {
                                scheduleSlotArray  && scheduleSlotArray.map(slot=>(
                                    <div className='col-sm-3 col-xs-4' >
                                        <div 
                                            className={`item slot-item ${(slot.startTime == (selectedSlotVal && selectedSlotVal.startTime)) ? 'item-active-border' : ''}`} 
                                            onClick={()=>sheduleSlotClick(slot)}
                                        >
                                            <p className="address-p">{slot.startTime} - {slot.endTime}</p>
                                        </div>
                                    </div>
                                ))
                            }
                            </div>
                        }
                    </div>
                    <div className='reassign-btn pull-right mt5'>
                        <Button className='btn button-servify' isDisabled={!reassignInputField.serviceCenter} handleOnClick={()=>{updateServiceAvailability()}}>
                            {localString('Update')}
                        </Button>
                        <Button className='btn button-servify' handleOnClick={()=>{closeReassignModal()}}>
                            {localString('Cancel')}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>}
    </> )
}
export default ReassignServiceCenterInput;