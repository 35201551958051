import React,{useState,useEffect} from 'react';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import { SERVICETYPE } from '../../../../../Utility/Utils';
import Assign from '../Assign/Assign';
import RequestConsumerDetails from './RequestConsumerDetails';
import './ConsumerDetails.scss'
import DeviceDetails from '../DeviceDetailsTab/DeviceDetails';
import './ConsumerRequest.scss'

const RequestConsumerWrapper = (props) => { 
	const {
		consumerServiceRequest, accessFn, activeTab, assignObjCheck, deviceAtLoggedInLoc, hideAssignOption,
		displayFullGrid
	} = props;
	const qFilter = JSON.parse(props.qFilter);
	const [contentHeader, setContentHeader] = useState("")
	const [workshopHeader, setWorkshopHeader] = useState("")

	const forAssignedTo = [
		'Service completed',
		'Consumer arrived',
		'Service accepted',
		'Serviceman journey initiated',
		'Serviceman reached',
		'Service initiated',
		'Service suspended',
		'Service invoice generated',
		'Repair completed',
		'Service cancellation initiated',
		'Device received',
		'Device dispatched',
		'Repair cancel',
		'Estimation rejected',
		'Device shipped',
		'Pickup Request Accepted',
		'Service cancel'
	];

	const forAssignedToWorkshop = [
		'Service completed',
		'Consumer arrived',
		'Service accepted',
		'Serviceman journey initiated',
		'Serviceman reached',
		'Service initiated',
		'Service in-progress',
		'Service suspended',
		'Service invoice generated',
		'Repair completed',
		'Service cancellation initiated',
		'Device received',
		'Device received',
		'Device dispatched',
		'Repair cancel',
		'Estimation rejected',
		'Device shipped',
		'Pickup Request Accepted',
		'Service cancel',
		'Work in progress',
		'Estimation generated',
		'Estimation approval',
		'Advance collected',
		'Parts request accepted',
		'Parts issued',
		'Parts received',
		'QC completed'
	];

	useEffect(() => {
		if(consumerServiceRequest &&  forAssignedTo.indexOf(consumerServiceRequest.Status) != -1){
			setContentHeader('Assigned To')
		}
		if(consumerServiceRequest &&  forAssignedTo.indexOf(consumerServiceRequest.Status) == -1){
			setContentHeader('Assign')
		}
		if(consumerServiceRequest &&  forAssignedToWorkshop.indexOf(consumerServiceRequest.Status) != -1){
			setWorkshopHeader('Assigned To')
		}
		if(consumerServiceRequest &&  forAssignedToWorkshop.indexOf(consumerServiceRequest.Status) == -1){
			setWorkshopHeader('Assignment')
		}
	}, [consumerServiceRequest]);

	return (
		consumerServiceRequest && 
		<>
			{ displayFullGrid && // Display only consumer details section
				<div className="inner-padding">
					<div className="row">
						<div className="">
							<div className='non-workshop-consumer-wrapper-main'>
								<div className={'col-md-12 request-panel-col'} >
									<PageContentWrapper bodyClassName="panel-body-no-padding" title={"Consumer Details"}>
										<RequestConsumerDetails {...props}/>
									</PageContentWrapper>
								</div>
							</div> 
						</div>
					</div>
				</div>
			}
			{
				!displayFullGrid && consumerServiceRequest && ((accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_SH') || [9,13].indexOf(consumerServiceRequest.ServiceTypeID) == -1)) &&
				<div className="inner-padding">
					<div className="row">
						<div className="">
						{
							!accessFn('WORKSHOP_FLOW') ?
							<div className='non-workshop-consumer-wrapper-main'>
								<div className={assignObjCheck && assignObjCheck.showassignmenttag  && accessFn('REQUEST_ASSIGN') ? 'col-md-8 request-panel-col ' : 'col-md-12 request-panel-col'} >
								{
									accessFn('REQUEST_CONSUMER_DETAILS') && 
									<PageContentWrapper bodyClassName="panel-body-no-padding" title={"Consumer Details"}>
										<RequestConsumerDetails {...props}/>
									</PageContentWrapper>
								}
								</div>
								{
									assignObjCheck && assignObjCheck.showassignmenttag && accessFn('REQUEST_ASSIGN') &&
									<div className="col-md-4 request-panel-col">
										<PageContentWrapper bodyClassName="request-panel-col" title={workshopHeader}>
											<Assign
												{...props}
											/>
										</PageContentWrapper>								
									</div>
								}
								
							</div> 
							:
							<div className='workshop-consumer-wrapper-main'>
								{
									([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1) &&
									<div 
										class={deviceAtLoggedInLoc && !hideAssignOption && (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REQUEST_ASSIGN') || accessFn('REASSIGN_JOBS') || accessFn('REPAIR_DEVICE') || accessFn('REQUEST_CONSUMER_DETAILS')) && !(['CprDashboard'].indexOf(consumerServiceRequest.Source) > -1) && !(qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1) ? 'col-md-8 request-panel-col' : 'col-md-12 request-panel-col'}
									>
										{
											accessFn('REQUEST_CONSUMER_DETAILS') &&
												<PageContentWrapper bodyClassName="panel-body-no-padding" title={"Consumer Details"}>
													<RequestConsumerDetails {...props}/>
												</PageContentWrapper>
										}									
									</div>
								}
								{
									activeTab.showDeviceDetails && [SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && consumerServiceRequest.ConsumerProductID && 
									<div class={!hideAssignOption && accessFn('REFURBISHMENT_MANAGER') ? 'col-md-8 request-panel-col' : 'col-md-12 request-panel-col'}>
										<DeviceDetails 
											{...props}
										/>
									</div>
								}
								{
									(activeTab.showConsumrDetails || (accessFn('REFURBISHMENT_MANAGER') && activeTab.showDeviceDetails)) && accessFn('WORKSHOP_FLOW') && (!hideAssignOption && (deviceAtLoggedInLoc && (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && !([3,5,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1)) || (([3,5,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && !(qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1) && !(['CprDashboard'].indexOf(consumerServiceRequest.Source) > -1))) &&
									//(accessFn('REFURBISHMENT_MANAGER')) && ((accessFn('WORKSHOP_FLOW') &&  !hideAssignOption && (deviceAtLoggedInLoc && (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && !([3,5,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1)) || (!hideAssignOption && ([3,5,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && !(JSON.parse(qFilter).flowType && JSON.parse(qFilter).flowType.fulfilmentFlow && JSON.parse(qFilter).flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1) && !(['CprDashboard'].indexOf(consumerServiceRequest.Source) > -1))) &&
									<div className={accessFn('REQUEST_CONSUMER_DETAILS') ? 'col-md-4 request-panel-col':'col-md-12 request-panel-col'} >
										<PageContentWrapper bodyClassName="request-panel-col" title={workshopHeader}>
											<Assign
												{...props}
											/>
										</PageContentWrapper>
									</div>
								}
							</div>
						}
						</div>
						{/* {
							<div className='col-md-12 request-panel-col' >
								<PageContentWrapper bodyClassName="request-panel-col" title={"Consumer Details"}>
									<RequestConsumerDetails {...props}/>
								</PageContentWrapper>
								<assign_workshop showEngineerVisits = {consumerServiceRequest.showEngineerVisits} canupdateservicerequest={canUpdateServiceRequest} csr={csr} servicelocationdata={ServiceLocationData} hideassignoption={hideAssignOption}></assign_workshop>
							</div>
						} */}
					</div>
				</div>
			}
		</>
	)
}

export default RequestConsumerWrapper
