
import React,{useState, useEffect,useCallback, useRef}  from 'react';
import ReactDOM from 'react-dom';
import FilterInputs from '../../../components/FilterInputs/FilterInput';
import {displayError,getValueFromAdressComponent} from '../../../Utility/Utils';
import {Loader,setAutocomplete} from 'sfy-react';
import Swal from "sweetalert2"; 

const PickUpDetailsInput = (props) => {
	const {localString,setCreateLogisticsData,createLogisticsData,qFilter,apiCall} = props;
	const [showLoader ,setLoader] = useState(false);
	const pickUpLandmarkRef = useRef(null);
	const [disableCountryCode,setDisableCountryCode] = useState(true);
	const [disableCityState,setDisableCityState] = useState(true);
	const [countriesData,setCountriesData] = useState([]);
   
	let PickUpInput = [
		{
			labelText: localString("Name"),
			dataKey: "PickupName",
			showFilter: true,
			placeholder:"Pickup Name",   
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						PickupName: val
					};
				});
			},
		},
		{
			labelText: localString("Contact No."),
			dataKey: "PickupMobile",
			placeholder:"Pickup Mobile",
			showFilter: true,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						PickupMobile: val
					};
				});
			},
		},
		{
			labelText: localString("Address"),
			dataKey: "PickupAddress",
			placeholder:"Pickup Address",
			showFilter: true,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						PickupAddress: val
					};
				});
			},
		},
		{
			labelText: localString("Landmark"),
			dataKey: "PickupLandmark",
			showFilter: true,
			placeholder:"Pickup Landmark",
			type:"MapAutoComplete",
			Ref:pickUpLandmarkRef,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						PickupLandmark: val
					};
				});
			},
		},
		{
			labelText: localString("Pin Code"),
			dataKey: "PickupPin",
			showFilter: true,
			placeholder:"Pickup Pin Code",
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						PickupPin: val
					};
				});
			},
		},
		{
			labelText: localString("City"),
			dataKey: "PickupCity",
			showFilter: true,
			placeholder:"Pickup City",
			isDisabled:disableCityState,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						PickupCity: val
					};
				});
			},
		},
		{
			labelText: localString("State"),
			dataKey: "PickupState",
			placeholder:"Pickup State",
			showFilter: true,
			isDisabled:disableCityState,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						PickupState: val
					};
				});
			},
		},
		{
			labelText: localString("Country"),
			dataKey: "PickupCountry",
			placeholder:"Pickup Country",
			showFilter: true,
			type :'Dropdown',
			dropdownList:countriesData,
			isDisabled:disableCountryCode,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						PickupCountryCode: val.CountryCode,
						PickupCountry:val.CountryName
					};
				});
			},
		},
	];
	const handleLandmarkSelection = useCallback(
		(value) => {
			let addrComponents = value.address_components
			let pincodeObject = getValueFromAdressComponent(addrComponents, 'postal_code')
			let countryCodeObject = getValueFromAdressComponent(addrComponents, 'country')
			if(countryCodeObject && countryCodeObject.short_name){
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						PickupCountryCode: countryCodeObject.short_name,
						PickupCountry:countryCodeObject.long_name
					};
				});
			}else{
				getCountries();
			}
			if (pincodeObject) {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						PickupPin: pincodeObject.short_name,
					};
				});
			} else {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						PickupPin: '',
						PickupCity:'',
						PickupState:''
					};
				});
			}
			setCreateLogisticsData((prevData) => {
				return {
					...prevData,
					PickupLandmark: value.formatted_address
				};
			});
		},[]
	)
	
	useEffect(() => {
		const autocomplete = setAutocomplete(pickUpLandmarkRef.current, handleLandmarkSelection)
		return () => {
			window.google.maps.event.clearListeners(autocomplete, 'place_changed')
		}
	}, [handleLandmarkSelection]);

	useEffect(() => {
		if(createLogisticsData.PickupPin && createLogisticsData.PickupPin.length == 6){
			checkpickupPin();
		}else{
			setDisableCityState(true);
		}
	}, [createLogisticsData.PickupPin]);

	const checkpickupPin = () => {
		let data = {
			"Pincode":createLogisticsData.PickupPin
		}
		setLoader(true);
		apiCall({
			url:'getCityStateByPincode',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if (result.success) {
					let pickupPinData = result.data;
					setCreateLogisticsData((prevData) => {
						return {
							...prevData,
							PickupCity:pickupPinData.City,
							PickupState:pickupPinData.State
						};
					});
					setDisableCityState(false);
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const getCountries = () => {
		let data = { countryConfig: {}};
		if(countriesData && countriesData.length==0){
			setLoader(true);
			apiCall({
				url:'getCountries',
				data: data,
				callBack:(result)=>{
					setLoader(false);
					if (result.data) {
						let data = result.data.countries.map(v => ({...v, value: v.CountryName}));
						setCountriesData(data);
						setDisableCountryCode(false);
					}
				}	
			});
		}
	}
	
	return (
		<>
		{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
		<div className="padding12">
			<h4>{localString("Pick Up Detail")}</h4>
			<div className="bottom-divider clearfix">
				<FilterInputs {...props} filterInputs={PickUpInput} filterData={createLogisticsData} hideSaveButton={true} hideClearButton=
				{true}></FilterInputs>
			</div>
		</div>
		</>
	) 
}

export default PickUpDetailsInput;