import React, { useState , useEffect,useRef} from 'react';
import {Dropdown,Input,Button} from 'sfy-react'
export default function DecisionFormFileUpload(props) {
    const {localString,formObject,index,checkFileInput,documentsArray,deleteFile} = props;
    const [fileName , setFileName] = useState(null);
    let acceptFileType = ".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv"
    let fileRef = useRef([]);
    const fileOnClick = ()=>{
        fileRef.current.click();
    }

    const fileDelete = (e,constantName)=>{
        setFileName(null);
        deleteFile(e,constantName);
    }
    return (
        <>
            <Input
                reference={fileRef}
                handleOnChange={(e)=>{
                    checkFileInput(e,formObject);
                    setFileName(e.target.files[0]);
                }}
                id={index}
                label = {localString("Upload")}
                inputType='file'
                className = {{formControl:'inputFile'}}
                name={formObject.ConstantName}
                otherProps={{ accept: acceptFileType}}
            />
            <label style={{ marginBottom:'10px' }}>{localString("Upload")}</label>
            <Button className='button-large  uploadFile'
                handleOnClick={ (e)=>{fileOnClick(formObject.ConstantName)}} 
                text = { fileName  && fileName.name  ? fileName.name : formObject.OptionValue} 
                isDisabled = {fileName  && fileName.name ? true : false}
            />
            {
                documentsArray && documentsArray.length > 0 && documentsArray.map(tag=>(
                    tag.Tag == formObject.ConstantName && <> <div className="col-sm-6 color-green" >{localString("File Uploaded")}</div>
                    <span className="delete-file" 
                        onClick={(e)=>{fileDelete(e,formObject.ConstantName)}} 
                    >
                        {localString("Delete")}
                    </span>
                </>
                ))
            }
        </>
    )
}
