import React, { useState,useEffect } from 'react';
import {Input,Button} from 'sfy-react';
import { checkMobileNumber ,checkEmail} from '../../../../../Utility/Utils';
const UpdateOrderDetailsInput=(props)=>{
    const {localString,closeModal,consumerServiceRequest,replacementFulfillment,countriesChecks} = props;
    const [updateOrderDetailData,setUpdateOrderDetailData] = useState({
        orderId:'',
        replacementModelNo:consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ModelNo ? consumerServiceRequest.consumerProduct.ModelNo:'',
        trackingLink:'',
        contactPhone:'',
        contactEmail:'',
        remarks:''
    });

    const saveOrderDetails = () =>{
        let data = {
            "ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            "Status":"Collection order created",
			'CountryID': consumerServiceRequest && consumerServiceRequest.CountryID ,
            "externalOrderId": updateOrderDetailData.orderId,
            "replacementOrderModel" : updateOrderDetailData.replacementModelNo,
            "externalTrackingLink" :updateOrderDetailData.trackingLink,
            "contactPhone" :updateOrderDetailData.contactPhone,
            "contactEmail" : updateOrderDetailData.contactEmail,
            "Remarks":updateOrderDetailData.remarks,
        };
        replacementFulfillment(data);
        closeupdateOrderDetails()
    }

    const closeupdateOrderDetails = () =>{
        setUpdateOrderDetailData({
            orderId:'',
            replacementModelNo:consumerServiceRequest.consumerProduct.ModelNo,
            trackingLink:'',
            contactPhone:'',
            contactEmail:'',
            remarks:''
        })
        closeModal();

    }
    let updateOrderDetailInput =[
        {
            labelText:localString("Order ID"),
            dataKey:"orderId",
            customClass:"order-detail-padding",
            showFilter:true,
            showMendatory:true,
            oninputFunc: (val) => {
                setUpdateOrderDetailData({...updateOrderDetailData,['orderId']:val})
            }
        },{
            labelText:localString("Replacement Model No"),
            dataKey:'replacementModelNo',
            customClass:"order-detail-padding",
            showFilter:true,
            showMendatory:true,
            oninputFunc: (val) => {
                setUpdateOrderDetailData({...updateOrderDetailData,['replacementModelNo']:val})
            }
        },{
            labelText:localString("Tracking Link"),
            dataKey:"trackingLink",
            customClass:"order-detail-padding",
            showFilter:true,
            showMendatory:false,
            oninputFunc: (val) => {
                setUpdateOrderDetailData({...updateOrderDetailData,['trackingLink']:val})
            }
        },{
            labelText:localString("Contact Phone"),
            dataKey:"contactPhone",
            customClass:"order-detail-padding",
            showFilter:true,
            showMendatory:false,
            maxLength :countriesChecks.intAllowedMobileLengths,
            oninputFunc: (val) => {
                setUpdateOrderDetailData({...updateOrderDetailData,['contactPhone']:val.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",<>.\ \{\}\[\]\\\/]/gi, '')})
            } 
        },{
            labelText:localString("Contact Email"),
            dataKey:"contactEmail",
            customClass:"order-detail-padding",
            showFilter:true,
            showMendatory:false,
            oninputFunc: (val) => {
                setUpdateOrderDetailData({...updateOrderDetailData,['contactEmail']:val})
            }
        },
        {
            labelText:localString("Remarks"),
            dataKey:"Remarks",
            customClass:"order-detail-padding",
            showFilter:true,
            showMendatory:false,
            oninputFunc: (val) => {
                setUpdateOrderDetailData({...updateOrderDetailData,['Remarks']:val})
        }
    }]
    return (
		<>
            <div>
                <div className='col-sm-12'>
                    {
                        updateOrderDetailInput && updateOrderDetailInput.length >0 && updateOrderDetailInput.map((item,index)=>(
                            item.showFilter && <div className={`col-sm-12 ${item.customClass}`}>
                                <div className='col-sm-6'>{item.labelText}
                                {
                                    item.showMendatory && <span className="red"> *</span>
                                }
                                </div>
                                <div className='col-sm-6'>
                                    <Input
                                        value={updateOrderDetailData[item.dataKey]}
                                        key={index}
                                        handleOnChange={(e) => {
                                            item.oninputFunc(e.target.value);
                                        }}
                                        labelAlign='top'
                                        isDisabled={item.isDisabled}
                                        maxLength={item.maxLength ? item.maxLength :'' }
                                        placeholder={item.placeholder}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="col-sm-12 clearfix text-right">
                    <Button 
                        className='btn button-servify'
                        isDisabled={!updateOrderDetailData.orderId || !updateOrderDetailData.replacementModelNo} 
                        handleOnClick={()=>saveOrderDetails()}>
                        {localString("Save")}
                    </Button>
                </div>
            </div>
        </>
	)
}
export default  UpdateOrderDetailsInput;

