import React, { useEffect, useState } from 'react';

import './Score.scss';

export default function Score(props) {
    const {
        publicUrl, localString, scoreVal = 0, maxVal = 5, color = '#F12800'
    } = props;
    
    return (
        <>
            <span className='scorePoints'>
                <span className='starIconImg'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path 
                            d="M6.61793 1.68494C6.73432 1.30954 7.26566 1.30954 7.38205 1.68494L8.46342 5.1728C8.51533 5.34022 8.67019 5.45434 8.84546 5.45435L12.383 5.45449C12.7659 5.45451 12.93 5.94072 12.6253 6.17271L9.73163 8.37649C9.598 8.47826 9.54218 8.65269 9.59192 8.81313L10.6894 12.3539C10.8047 12.7257 10.3747 13.0264 10.065 12.7906L7.24232 10.6412C7.09916 10.5322 6.90083 10.5322 6.75767 10.6412L3.93498 12.7906C3.62525 13.0264 3.19533 12.7257 3.31059 12.3539L4.40807 8.81314C4.4578 8.65269 4.40199 8.47826 4.26835 8.37649L1.37465 6.17271C1.07003 5.94072 1.23408 5.45451 1.61699 5.45449L5.15452 5.45435C5.3298 5.45434 5.48466 5.34022 5.53656 5.1728L6.61793 1.68494Z" 
                            fill={color} 
                            stroke={color}
                        />
                    </svg>
                </span>
                <span className='starIconVal' style={color ? {color: color} : {}}>{localString(`${scoreVal}/${maxVal}`)}</span>
            </span>
        </>
    )
}