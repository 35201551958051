import React from 'react';
import {Modal} from 'sfy-react';
import './BERProductModal.scss';
import BERProductInput from './BERProductInput';
const BERProductModal = (props) =>{
    const {localString,showModal,closeModal} = props;
		return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
                <Modal.Header>
                    <h4 class="modal-title text-center"> {localString("Product Details")}</h4>
                </Modal.Header>
                <Modal.Body>
                    <BERProductInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default BERProductModal;