import React, { useEffect, useState } from 'react';
import { Button } from 'sfy-react';
import Swal from 'sweetalert2';
import { orderBy } from 'lodash';

import { displayError, SERVICETYPE } from '../../../Utility/Utils';
import AddNewAddress from '../../../components/AddNewAddress/AddNewAddress';

export default function Address(props) {
	const { 
		apiCall, accessFn, localString, setLoader, consumerProduct, selectedCountryData, stateList,
		address, setAddress, showAddress, setShowAddress, pinCodeLengthArr, skipZipCodeCheck, country, 
		identifier, setConsumerFavouriteLocationID, getServiceAvailabilityWithLocations, walkIn,
		getScheduleDateAndSlot, showIsSameAddress, setShowIsSameAddress, isSameAddress, setIsSameAddress,isSupressLogisticsCutomerToASC,setIsSupressLogisticsCutomerToASC,
		secondaryAddress, setSecondaryAddress, showSecondaryAddress, setShowSecondaryAddress, serviceMode,
		serviceAvailabilityData, setDropoffLocationList, setShowServiceLocationsDropOff, showInstructions,
		showSlotSelection, hideSection, setScreenToViewDiv, consumerId, clearAddressStates = true,
		setActiveStep, activeStepIndex, setActiveStepIndex, event , checkSuppressLogistics
	} = props;

	const countryConfig = selectedCountryData && selectedCountryData.countryConfig;
	const [showNewAddress, setShowNewAddress] = useState(false);
	const isSecondryAddress = identifier == 'secondaryAddress';
	const [addressList, setAddressList] = useState([]);
	const [secondryAddressList, setSecondryAddressList] = useState([]);

	const getAddressList = () => {
		setLoader(true);
		let reqObj = {
			ConsumerID: consumerId,
			ProductID: consumerProduct.ProductID
		}
		apiCall({
			url: 'getUserLocationsForProduct',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success){
					if(result.data) {
						let addressConfig = orderBy(selectedCountryData.countryConfig.addressConfig.filter(o => o.addressPreviewOrder), ['addressPreviewOrder']);
						let tempAddressList = [];
						result.data.map(addressObj => {
							let obj = {...addressObj};
							let addressDisplayString = '';
							addressConfig.map(addressConfigObj => {
								if (addressObj[addressConfigObj.keyName] != null && addressObj[addressConfigObj.keyName] != '') {
									if(addressDisplayString) {
										addressDisplayString = addressDisplayString + ', ' + addressObj[addressConfigObj.keyName];
									}else {
										addressDisplayString = addressObj[addressConfigObj.keyName];
									}
								}else if(addressObj['Landmark'] && addressConfigObj.keyName == 'UserLandmark') {
									if (addressDisplayString != '') {
										addressDisplayString = addressDisplayString + ', ' + addressObj['Landmark'];
									}
									else {
										addressDisplayString = addressObj['Landmark'];
									}
								}
							})
							obj.addressDisplayString = addressDisplayString;
							tempAddressList.push(obj);
						})
						// setAddressList(tempAddressList);
						if(isSecondryAddress){
							setSecondryAddressList(tempAddressList)
							if(tempAddressList.length > 0) {
								setShowNewAddress(false);
							}else {
								setShowNewAddress(true);
							}
						}else {
							setAddressList(tempAddressList);
							if(tempAddressList.length > 0) {
								setShowNewAddress(false);
								// self.isAutoCompleteInitialized = false;
							}else {
								setShowNewAddress(true);
							}
						}
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false); 
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	useEffect(() => {
		if(identifier !== 'sameAddresSection') getAddressList()
		return () => {
			if(clearAddressStates) {
				if(identifier == 'primAddress') {
					setAddress(null);
				}else if(identifier == 'secondaryAddress') {
					setSecondaryAddress(null);
				}else if(identifier == 'sameAddresSection') {
					setIsSameAddress(true);
				}
			}
		}
	}, []);

	const addNewAddress = () => {
		setShowNewAddress(true);
		// setAddress(null);
	}

	const checkMandatoryAddressFields = (addressObj) => {
		if(selectedCountryData && selectedCountryData.countryConfig && selectedCountryData.countryConfig.addressConfig.length > 0) {
			let mandatoryFields = selectedCountryData.countryConfig.addressConfig.filter(obj => obj.mandatory && obj.display);
			let count = 0;
			for(var i = 0; i < mandatoryFields.length; i++) {
                if(addressObj[mandatoryFields[i].keyName] && addressObj[mandatoryFields[i].keyName] != '') {
                    count++
                }
            }
            return count == mandatoryFields.length ? false : true;
		}
	}

	const editIncorrectAddress = (addressObj) => {
		if(identifier == 'primAddress') {
			setAddress(addressObj);
		}else {
			setSecondaryAddress(addressObj);
		}
		addNewAddress();
		Swal.fire({
			text: localString('Some of the details might be missing in the address, please update the address.'),
			icon: 'warning',
			confirmButtonText: localString('OK'),
			cancelButtonText: localString('Cancel')
		});
	}

	const isStateMandatory = () => {
		let stateMandatory;
		if(selectedCountryData.countryConfig && selectedCountryData.countryConfig.addressConfig && selectedCountryData.countryConfig.addressConfig.length > 0) {
            stateMandatory = selectedCountryData.countryConfig.addressConfig.filter((obj)=>{
                return obj.keyName == 'State' && obj.mandatory == true && obj.display == true;
            })
        }
        return stateMandatory ? true : false;
	}

	const checkStateInStateList = (state) => {
		let selectedState = stateList.find((obj) => {
            return (obj.State).toLowerCase() == (state).toLowerCase();
        });
        return selectedState ? true : false;
	}

	const checkCorrectState = (addressObj) => {
		let selectedState = stateList.find((obj) => {
            return (obj.State).toLowerCase() == (addressObj.State).toLowerCase();
        });
        if(selectedState && selectedState.StateID) {
            addressObj.StateID = selectedState.StateID;
            addConsumerAddress(addressObj);
        }else {
            editIncorrectAddress(addressObj);
        }
	}
	
	const addConsumerAddress = (addressObj) => {
		setLoader(true);			
		apiCall({
			url: (addressObj && addressObj.ConsumerFavouriteLocationID ? 'updateLocation' : 'addConsumerLocation'),
			data: addressObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data){
					if(identifier == 'primAddress') { 
						// Primary Address
						let obj = result.data;
						setAddress(obj)
						setConsumerFavouriteLocationID(obj.ConsumerFavouriteLocationID)
						if(walkIn && accessFn("GSX_FLOW")) {
							getScheduleDateAndSlot();
						}else{
							getServiceAvailabilityWithLocations(obj);
						}
					}else if(identifier == 'claimAddress') { 
						let obj = result.data;
						setAddress(obj)
						setConsumerFavouriteLocationID(obj.ConsumerFavouriteLocationID)
						setActiveStepIndex && setActiveStepIndex(activeStepIndex + 1);
						setActiveStep && setActiveStep('serviceMode');
						getServiceAvailabilityWithLocations(obj);
                    } else {
						// Secondary Address
						let obj = result.data;
						setSecondaryAddress(obj);
						setConsumerFavouriteLocationID(obj.ConsumerFavouriteLocationID);
                        getServiceAvailabilityWithLocations(obj, true);
                    }
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString('Something went wrong'),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const afterAddUpdateAddress = (type, addressObj) => {
		if(type == 'secondaryAddress') {
			let obj = addressObj;
			setSecondaryAddress(obj);
			setConsumerFavouriteLocationID(obj.ConsumerFavouriteLocationID);
			getServiceAvailabilityWithLocations(obj, true);
		} else if(type == 'primAddress') {
			addressObj && setAddress(addressObj)
			addressObj && setConsumerFavouriteLocationID(addressObj.ConsumerFavouriteLocationID)
			if(walkIn && accessFn("GSX_FLOW")) {
			    getScheduleDateAndSlot()
			}else{
			    getServiceAvailabilityWithLocations(addressObj);
			}
		} 
		else if(type == 'claimAddress') {
			addressObj && setAddress(addressObj)
			addressObj && setConsumerFavouriteLocationID(addressObj?.ConsumerFavouriteLocationID)
			setActiveStepIndex && setActiveStepIndex(activeStepIndex + 1);
			setActiveStep && setActiveStep('serviceMode');
			getServiceAvailabilityWithLocations(addressObj);
		// 	self.parent.selectedAddress = result.data;
		// 	self.parent.serviceaddress = 'done';
		// 	self.parent.backbutton = true;
		// 	self.parent.showAddAddress = false;
		// 	self.parent.selectedListAddress = null;
		// 	self.parent.getServiceAvailabilityWithLocations();
		}
	}

	const addressClick = (addressObj) => {
		hideSection && hideSection(['SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		if (!addressObj.Lat || addressObj.Lat == 0 || !addressObj.Lng || addressObj.Lng == 0) {
			Swal.fire({  
				icon: 'error',  
				text: localString("There's a problem with this address, please select or add a different address."),  
			});
        }else if(checkMandatoryAddressFields(addressObj)) {
			editIncorrectAddress(addressObj);
		}else if(isStateMandatory() && addressObj.State && (!addressObj.StateID || !checkStateInStateList(addressObj.State))) {
            checkCorrectState(addressObj);
		}else {
			setAddress(addressObj);
			setShowNewAddress(false);
            if(walkIn && accessFn("GSX_FLOW") && identifier !== 'claimAddress') {
                getScheduleDateAndSlot();
            }else {
				// self.serviceAvailabilityData = undefined;
				// self.serviceAvailability = undefined;
				// self.serviceTypeArray = undefined;
				// self.showServiceTypes = false;
				// self.DeliveryMode = undefined;
				// self.isSameAddress = false;
				// self.showAddSecondryAddress = false;
				// self.showServiceLocationsCarryIn = false;
				// self.showExternalReferenceID = false;
				// self.showServiceLocationsDropOff = false;
				// self.serviceLocationList = undefined;
				// self.selectedServiceMode = undefined;
				if(identifier === 'claimAddress') {
					setActiveStepIndex && setActiveStepIndex(activeStepIndex + 1);
					setActiveStep && setActiveStep('serviceMode');
				}
                getServiceAvailabilityWithLocations(addressObj);
            }
		}
		// setAddress(addressObj);
	}

	const checkLogisticsPopUp = (addressObj, sameAddress) =>{
		Swal.fire({
			title: localString('Warning: customer to ASC shipment will not be created'),
			icon: 'warning',
			confirmButtonColor: '#4db800',
			confirmButtonText: localString('Continue'),
            cancelButtonText: localString('Cancel'),
            cancelButtonColor: '#d33',
			showCancelButton: true,
		}).then(swalResult => {
			if(swalResult.isConfirmed){
				secondryAddressClick(addressObj, sameAddress)
			}else{
				return;
			}
		});
	}

	const secondryAddressClick = (addressObj, sameAddress) => {
		setActiveStepIndex && setActiveStepIndex(activeStepIndex + 1);
		// setActiveStep && setActiveStep('dateAndSlotSelection');
		hideSection && hideSection(['SlotSelection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'PayoutModal'])
		if(isSameAddress) {
			setSecondaryAddress(addressObj);
            if([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_PICK_UP].indexOf(serviceMode.ServiceTypeID) > -1){
				if(checkSuppressLogistics){
					checkSuppressLogistics()
				}else{
					getScheduleDateAndSlot();
					setActiveStep && setActiveStep('dateAndSlotSelection');
					if(showSlotSelection) {
						// setScreenToViewDiv('scheduleDateAndTime');
					}
				}
            }else if(serviceAvailabilityData.isDropOffLocationAvailable) {
				setDropoffLocationList(serviceAvailabilityData.dropOffLocations);
				setShowServiceLocationsDropOff(true);
				setActiveStep && setActiveStep('serviceLocation');
			}else if(serviceAvailabilityData.dropOffLocationsURL) {
				showInstructions();
			}else {
				showInstructions();
			}
		}else {
			if (!addressObj.Lat || addressObj.Lat == 0 || !addressObj.Lng || addressObj.Lng == 0) {
				Swal.fire({  
					icon: 'error',  
					text: localString("There's a problem with this address, please select or add a different address."),  
				});
			}else if(checkMandatoryAddressFields(addressObj)) {
				editIncorrectAddress(addressObj);
			}else if(isStateMandatory() && addressObj.State && (!addressObj.StateID || !checkStateInStateList(addressObj.State))) {
				checkCorrectState(addressObj);
			}else {
				setSecondaryAddress(addressObj);
				getServiceAvailabilityWithLocations(addressObj, true);
				setShowNewAddress(false);
			}
		}
	}

	const sameAddressClick = (e) => {
		if(e.target.checked) {
			setIsSameAddress(true);
			setShowSecondaryAddress(false);
		}else {
			setIsSameAddress(false);
			setShowSecondaryAddress(true);
		}
	}
	const supressLogisticsCutomerToAscClick = (e) => {
		if(e.target.checked) {
			setIsSupressLogisticsCutomerToASC(true);
		}else {
			setIsSupressLogisticsCutomerToASC(false);
		}
	}

	return (
		<>
			{ identifier == 'sameAddresSection' &&
				<div className="raiseReqSection" style={{padding: '10px 30px'}}>
					<div className='isSameAddress'>						
						<div className="sameaddress-margin">
							<div className="labelWrapper">
								<label for={"sameaddress"} className="checkboxFeature">
									<input 
										type="checkbox" 
										name="sameaddress"
										id="sameaddress"
										style={{marginRight: '5px'}}
										checked={isSameAddress}
										onClick={sameAddressClick} 
									/>
									{localString("Get the device delivered to the same address.")}
								</label>
								<label for={"suppressLogistics"} className="checkboxFeature">
									<input 
										type="checkbox" 
										name="supressLogisticsCutomerToAscClick"
										id="supressLogisticsCutomerToAsc"
										style={{marginRight: '5px'}}
										checked={isSupressLogisticsCutomerToASC}
										onClick={supressLogisticsCutomerToAscClick} 
									/>
									{localString("Customer to service centre shipment is not needed")}
								</label>
							</div>
							<div className="pull-right" style={{marginTop: '-7px'}}>
								{
									isSameAddress && 
										<Button 
											className="btn button-servify" 
											id="selectSameDropOffAddress"
											onClick={isSupressLogisticsCutomerToASC?(e) => checkLogisticsPopUp(address, true):(e) => secondryAddressClick(address, true)}
										>
											{localString("Proceed")}
										</Button>
								}
							</div>
						</div>
					</div>
				</div> 
			}
			{ (identifier == 'primAddress' || identifier == 'claimAddress') &&
				<div id='Address' className='raiseReqSection'>
					<div className='address'>
						{
							showNewAddress && 
								<AddNewAddress 
									{...props}
									event={event}
									setLoader={setLoader}
									identifier={identifier}
									countryConfig={countryConfig}
									getAddressList={getAddressList}
									cid={consumerId}
									pinCodeLengthArr={pinCodeLengthArr}
									skipZipCodeCheck={skipZipCodeCheck}
									setShowNewAddress={setShowNewAddress}
									addressformtype={'addConsumerLocation'}
									address={address} setAddress={setAddress}
									countryid={selectedCountryData.CountryID}
									countryCode={selectedCountryData.CountryCode}
									afterAddUpdateAddress={afterAddUpdateAddress}
									// arrallowedpincodelengths={arrAllowedPinCodeLengths}
									// intallowedpincodelengths={intAllowedPinCodeLengths}
								/>
						}
						<div className="row">
							<div className="col-sm-12 form-label" style={{ marginBottom: '15px' }}>
								<label>{localString("Select an Address")}</label>
								<Button 
									className="pull-right button-refresh" 
									onClick={() => {
										getAddressList()
									}}
								/>
							</div>
							{
								!showNewAddress && 
									<div className="col-sm-12" id="newaddressdetails">
										<div
											className="item address-item add-address"
											id="newaddressdetailsclick"
											onClick={() => {
												setAddress(null);
												addNewAddress();
												hideSection && hideSection(['SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation']);
											}}
										>
											<div className="text-center addaddressbtn">
												<div className="glyphicon glyphicon-plus gi-3x"></div>
												<div>
													<label for="address">
														{localString("Add New Address")}
													</label>
												</div>
											</div>
										</div>
									</div>
							}
							<div className="col-sm-12" id="addresslist">
								{
									addressList && addressList.length == 0 ?
										<div className="empty-message">
											<p>{localString("No addresses found")}</p>
										</div> : 
										<div className="row fixed-height">
											{
												addressList && addressList.length > 0 && addressList.map((addressObj, k) => 
													<div className="col-sm-4" key={k} onClick={() => addressClick(addressObj)}>
														<div className={`item address-item ${((address && address.ConsumerFavouriteLocationID) == addressObj.ConsumerFavouriteLocationID) ? 'item-active-border' : ''}`}>
															<p className="address-p">{addressObj.addressDisplayString}</p>
															{ addressObj.AddressType && <p className="address-type-p">{localString(addressObj.AddressType)}</p> }
														</div>
													</div>
												)
											}
											
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			}
			{ identifier == 'secondaryAddress' &&
				<div id='SecondaryAddress' className='raiseReqSection'>
					<div className='address'>
						{
							showNewAddress && 
								<AddNewAddress 
									{...props}
									event={event}
									setLoader={setLoader}
									identifier={identifier}
									countryConfig={countryConfig}
									getAddressList={getAddressList}
									cid={consumerId}
									pinCodeLengthArr={pinCodeLengthArr}
									skipZipCodeCheck={skipZipCodeCheck}
									setShowNewAddress={setShowNewAddress}
									addressformtype={'addConsumerLocation'}
									countryid={selectedCountryData.CountryID}
									countryCode={selectedCountryData.CountryCode}
									afterAddUpdateAddress={afterAddUpdateAddress}
									// arrallowedpincodelengths={arrAllowedPinCodeLengths}
									// intallowedpincodelengths={intAllowedPinCodeLengths}
									address={secondaryAddress} setAddress={setSecondaryAddress}
								/>
						}
						<div className="row">
							<div className="col-sm-12 form-label" style={{ marginBottom: '15px' }}>
								<label>{localString("Select an Address")}</label>
								<Button 
									className="pull-right button-refresh" 
									onClick={() => {
										getAddressList()
									}}
								/>
							</div>
							{
								!showNewAddress && 
									<div className="col-sm-12" id="newaddressdetails">
										<div
											className="item address-item add-address"
											id="newaddressdetailsclick"
											onClick={() => {
												// setAddress(null);
												setSecondaryAddress(null);
												addNewAddress();
												hideSection && hideSection(['SlotSelection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'PayoutModal']);
											}}
										>
											<div className="text-center addaddressbtn">
												<div className="glyphicon glyphicon-plus gi-3x"></div>
												<div>
													<label for="address">
														{localString("Add New Address")}
													</label>
												</div>
											</div>
										</div>
									</div>
							}
							<div className="col-sm-12" id="secondryAddressList">
								{
									secondryAddressList && secondryAddressList.length == 0 ?
										<div className="empty-message">
											<p>{localString("No addresses found")}</p>
										</div> : 
										<div className="row fixed-height">
											{
												secondryAddressList && secondryAddressList.length > 0 && secondryAddressList.map((addressObj, k) => 
													<div className="col-sm-4" key={k} onClick={(e) => secondryAddressClick(addressObj)}>
														<div className={`item address-item ${((secondaryAddress && secondaryAddress.ConsumerFavouriteLocationID) == addressObj.ConsumerFavouriteLocationID) ? 'item-active-border' : ''}`}>
															<p className="address-p">{addressObj.addressDisplayString}</p>
															{ addressObj.AddressType && <p className="address-type-p">{localString(addressObj.AddressType)}</p> }
														</div>
													</div>
												)
											}
											
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			}
		</>
	);
}