import React, { useEffect,useState }  from 'react';
import ReactDOM from 'react-dom';
import {find,findIndex} from 'lodash'
import ViewButtonMatrix from './ViewButtonMatrix';
import { getStatusString, SERVICETYPE } from '../../../../../Utility/Utils';
//Repair completed have doubt
const ViewButtons = (props) =>{
    const {
		accessFn,qFilter,show ,localString,warrantyAppicableNonClaimCheck, consumerServiceRequest,checkPaymentStatus,
		checkDecisionMatrixProcessCompleted,hideNextStepInTradeIn,cancelAnytime,isServiceComplete,isCancelApplicable,
		isServiceCancel,isClaimWithdrawn,checkDeviceValueAndDateAdded,showCompleteServiceFunction,coveredUnderPlanCheck,
		accessPoints,getSource,showCancelButtonFuncton,isSymptomMapAdded,taskListArr ,checkIfSalesChannelIsequiredAndPresent,
		partsRequested,isServiceLocationAssigned ,showActionButton,setShowActionButton,showButton,enableAttemptCall,
		showUpdateLogisticsRequestButton,isRescheduleApplicable,showInitiateException,isDOAApplicableLog,showCompleteRefund,
		ConsumerServiceRequestID,getDeviceReceivedHelperText,adjudicationChecklistData,getRequestById,
		setShowRequestValid,planID,countryConfig,setShowViewRequestFlags,showViewRequestFlags,UserID, claimDamageId,
		consumerDetails, deviceAtLoggedInLoc, featureConfig,showRequestLogs,isRequestSFANeeded,requestSFA,isDOAEligible,arrDiagnosisSuites,
		showRequestValid,addedFaultActions,exception,checkDOAApplicable,isHandedToCC,claimNewWorkshopChecks,pickupCarryInChecks,claimChecks,
		onsiteChecks,pickupCarryInGSXChecks,berChecks,replacementFlowChecks,refundProcessCheck,appleDropOffFlow,refurbishmentFlowCheck,
		isDiagnosisCompletedByEng
	} = props;
	const [statusButtons, setStatusButtons] = useState([]);
	const [showRefreshButton, setShowRefreshButton]=useState(true);
	const [buttonLogStatus, setButtonLogStatus]=useState({
		estimationApproved:false,
		partsPending:false,
		partsReceived:false,
		tecnicianReached:false,
		issuesValidated:false,
		proofUploaded:false
	})
	const [isEachPartReceived,setIsEachPartReceived]=useState(false);
	const [pendingForSpares,setPendingForSpares]=useState(false);
	const [berValid,setBerValid] = useState({
		replacementFlow:false,
		paymentModeCheque : false,
		accessoriesReceived :false
	});
	const [claimRevisionRemark,setClaimRevisionRemark] = useState()
	useEffect(()=>{
		statusData()
		let localBerValid = {...berValid};
		let localReplacementFlow = (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == SERVICETYPE.BER_REPLACEMENT);
		localBerValid.replacementFlow = localReplacementFlow ? true : false;
		let localPaymentModeCheque = consumerServiceRequest.CONSUMER_PAYOUT_MODE == "CHEQUE";
		localBerValid.paymentModeCheque = localPaymentModeCheque ? true : false;
		setBerValid(localBerValid );
		setClaimRevisionRemarks();
	},[consumerServiceRequest])

	useEffect(()=>{
		getCurrentRoleInt()
	},[])

	const getDecisiomMatrixHelpText = () => {
        return consumerServiceRequest && consumerServiceRequest.SkipDecisionForm ? "Waiting for the admin team to select service outcome" : "Waiting for the admin team to fill the Decision form to decide service outcome";
    };
	const setClaimRevisionRemarks = () =>{
        let revisionLog = find(consumerServiceRequest.logs, {Status: 'Claim estimation revision required'});
        if(consumerServiceRequest && consumerServiceRequest.Status == 'Claim estimation revision required' && revisionLog) {
            setClaimRevisionRemark(revisionLog.Remarks)
        }
    }

	const isHandedToStore = () => {
		let isHanded = false
		let deviceHanded = find(consumerServiceRequest.logs, {Status: 'Device accepted'})
		if (deviceHanded) {
			if (deviceHanded.DestinationType == 'StoreManager') {
				isHanded = true;
			}
		}
		return isHanded;
	}
	const statusData=()=>{
		let validStatus={...buttonLogStatus}
		if(consumerServiceRequest &&consumerServiceRequest.logs &&consumerServiceRequest.logs.length > 0) {
			if(consumerServiceRequest &&consumerServiceRequest.logs &&consumerServiceRequest.logs.length > 0) {
				consumerServiceRequest.logs && consumerServiceRequest.logs.map((obj) => {
					if(obj.Status == 'Claim estimation approved') {
						validStatus.estimationApproved = true
					} else if (obj.Status == 'Parts pending') {
						validStatus.partsPending = true;
					} else if (obj.Status == 'Parts received') {
						validStatus.partsReceived = true;
					} else if (obj.Status == 'Serviceman reached' || obj.Status == 'Service accepted') {
						validStatus.tecnicianReached = true;
					}else if (obj.Status == 'Issues validated') {
						validStatus.issuesValidated = true;
					}else if (obj.Status == 'Documents uploaded') {
						validStatus.proofUploaded = true;
					}
					setButtonLogStatus(validStatus)
				});
			}
		}
		
	}

	const getServiceCompletedButtonName = ()=>{
        let displayText = "";
        if(berValid.replacementFlow){
            displayText = 'Service completed';
        }else if (berChecks && showRequestLogs.BERConfirmed && berValid.paymentModeCheque){
            displayText = "Enter Check Number";
        }else if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW') ){//gsx and non
			displayText = "Complete Service"	
		}
		else{
            displayText = 'Complete Process';
        }
        return displayText;
    }
	const getNextSteps = (status) =>{
		let nextSteps = {
	    // 'Service accepted': 'Waiting for CC to receive device',
        // 'Pending for Assignment': '',
        // 'Pending for acceptance': 'Waiting for engineer to accept the job.',
        // 'QC rejected': 'Device repair has failed QC. Device is to be given back to the Engineer.',
        // 'Claim Estimation generated': 'Waiting for Estimation Approval. Click on "Send for approval" in "Parts and Estimation" section below.',
        // 'Estimation approval': 'Waiting for customer approval of estimation or override estimation approval in "Parts and Estimation" section below.',
        // 'Estimation rejected': 'Estimation has been rejected, parts may be added if required by the engineer.',
		// // 'Parts request accepted':'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.',
        // 'Advance collected': '',
        // 'QC pending': 'Engineer completed the repair. Handed over the device to QC.',
        // 'Repair cancel': 'Repair has been cancelled.',
		// 'Claim estimation revision required':'Please revise and resubmit your estimate. ' + (claimRevisionRemark ? 'Remarks: ' + claimRevisionRemark : ''),
		// 'In-principle approved': 
		//  consumerServiceRequest && consumerServiceRequest.decisionMatrix && !checkDeviceValueAndDateAdded() ? "Add Device Date of Purchase and Device Value in 'Device Details' below." :
		//  !checkDecisionMatrixProcessCompleted() ? getDecisiomMatrixHelpText() :
		//  !checkPaymentStatus() ? "Waiting for customer to make the payment for advance deductible" : "", 
		'Service center document pending': "Please add the required proof in the Proof tab and submit to proceed for approval.", 
		'Service center document verification pending': "Please review the proof provided by the Service Center in the Proof tab.", 
		};
        
        //trade in dropoff
		// if(([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW')){
		// 	nextSteps['Service accepted'] = 'Waiting for the Refurbishment Hub Manager to receive device and complete mandatory checklist tasks.'
		// 	nextSteps['Repair completed'] = 'Engineer completed the repair.'
		// 	nextSteps['Device received'] = ''
		// }
		if(claimChecks){//claim
			nextSteps['Device received'] = getDeviceReceivedHelperText()
			nextSteps['In-principle approved']=
				consumerServiceRequest && consumerServiceRequest.decisionMatrix && !checkDeviceValueAndDateAdded() ? "Add Device Date of Purchase and Device Value in 'Device Details' below." :
				!checkDecisionMatrixProcessCompleted() ? getDecisiomMatrixHelpText() :
				!checkPaymentStatus() ? "Waiting for customer to make the payment for advance deductible" : ""
			nextSteps['Claim estimation revision required']= 'Please revise and resubmit your estimate. ' + (claimRevisionRemark ? 'Remarks: ' +claimRevisionRemark : '')
			if(adjudicationChecklistData && adjudicationChecklistData.NewChecklist && adjudicationChecklistData.NewChecklist.length && accessFn('EDIT_ADJUDICATION_CHECKLIST')){
				return "Make sure all the checks are done, as per 'Checklist'";
			}
			if (nextSteps[consumerServiceRequest.Status]) {
				return nextSteps[consumerServiceRequest.Status];
			} else {
				return getStatusString(consumerServiceRequest.Status, consumerServiceRequest.ServiceTypeID, accessFn);
			}
		}
		
		//claim new
		if(claimNewWorkshopChecks){ //claim new
			nextSteps['Device received'] = ' Waiting for CC to complete mandatory checklist tasks.'
			nextSteps['In-principle approved'] =
				consumerServiceRequest && consumerServiceRequest.decisionMatrix && !checkDeviceValueAndDateAdded() ? "Add Device Date of Purchase and Device Value in 'Device Details' below." : 
				!checkDecisionMatrixProcessCompleted() ? getDecisiomMatrixHelpText() : 
				!checkPaymentStatus() ? "Waiting for customer to make the payment for advance deductible" : ""
			nextSteps['DropOff request accepted'] = 'DropOff request for the device has been accepted.'			
			nextSteps['Service invoice generated'] = 'Service invoice has been generated, final payment can be received.'					
		    nextSteps['Pickup request accepted'] = 'Pickup request for the device has been accepted.'
			// nextSteps['Pickup request accepted'] = 'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.'
			nextSteps['Parts received'] = 'All parts received, repair is in progress.'
			nextSteps['Payment received'] = 'Payment for the service has been received from the customer.'	
			nextSteps['Parts issued'] = 'Waiting for Engineer to receive parts from store in "Parts Requested" section below.'
			nextSteps['Intimate customer'] = (consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN || consumerServiceRequest.PreviousServiceTypeID == SERVICETYPE.CLAIM_CARRY_IN) ? 'Please complete the service.' : 'Intimate customer'
			nextSteps['Work in progress'] = consumerServiceRequest.EWRequest && (consumerServiceRequest.IsPlanEligible == null || undefined) ? 'Please select if the request is covered under plan in Device Details section.' : 'Work in Progress with Engineer.'
		    nextSteps['Repair completed'] =  'Engineer completed the repair.'
			nextSteps['Service accepted'] = 'Waiting for CC to receive device'
			nextSteps['Pending for Assignment'] = ''
			nextSteps['Pending for acceptance'] = 'Waiting for engineer to accept the job.'
			nextSteps['QC rejected'] = 'Device repair has failed QC. Device is to be given back to the Engineer.'
			nextSteps['Claim Estimation generated'] = 'Waiting for Estimation Approval. Click on "Send for approval" in "Parts and Estimation" section below.'
			nextSteps['Estimation approval'] = 'Waiting for customer approval of estimation or override estimation approval in "Parts and Estimation" section below.'
			nextSteps['Estimation approval'] = 'Estimation has been rejected, parts may be added if required by the engineer.'
			nextSteps['Parts request accepted'] = 'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.'
			nextSteps['Advance collected'] = ''
			nextSteps['QC pending'] = 'Engineer completed the repair. Handed over the device to QC.'
			nextSteps['Repair cancel'] = 'Repair has been cancelled.'
			nextSteps['Claim estimation revision required'] = 'Please revise and resubmit your estimate. ' + (claimRevisionRemark ? 'Remarks: ' + claimRevisionRemark : '')
			if(adjudicationChecklistData && adjudicationChecklistData.NewChecklist && adjudicationChecklistData.NewChecklist.length && accessFn('EDIT_ADJUDICATION_CHECKLIST')){
				return  "Make sure all the checks are done, as per 'Checklist'";
			};

			if (nextSteps[consumerServiceRequest.Status]) {
				return nextSteps[consumerServiceRequest.Status];
			} else {
				return getStatusString(consumerServiceRequest.Status, consumerServiceRequest.ServiceTypeID, accessFn);
			}
		}

		if(onsiteChecks){//onsite
			nextSteps['In-home request accepted']= !checkDeviceValueAndDateAdded() && !consumerServiceRequest.EWRequest ? "Add Device Date of Purchase and Device Value in 'Device Details' below." : 
			!coveredUnderPlanCheck() && consumerServiceRequest.EWRequest ? "Please select if the request is covered under plan in 'Device Details' section." :  
			(consumerServiceRequest.EWRequest ? 'Waiting for Supervisor to review issues, add parts and generate estimation' : 'Waiting for Supervisor to add parts and generate estimation')
			nextSteps['Work in progress']= [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? 'Technician reached, awaiting Service completion.' : ''
			nextSteps['Service accepted'] = [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? (!buttonLogStatus.estimationApproved && consumerServiceRequest.EWRequest ? 'Waiting for Supervisor to validate issues.' : (!buttonLogStatus.estimationApproved && !consumerServiceRequest.EWRequest ? 'Waiting for Supervisor to add parts and generate estimation' : 'Technician reached, awaiting Service Initiation.')) : ''
			nextSteps['Repair completed'] = [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? 'Technician reached, awaiting Service completion.' : ''
			nextSteps['Parts received'] = !buttonLogStatus.tecnicianReached && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? 'Awaiting for Supervisor to assign Technician' : ''
			nextSteps['Claim estimation generated'] = [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? (!buttonLogStatus.proofUploaded ? 'Waiting for Supervisor to upload proof of damage' : 'Awaiting estimation approval from claims team.') : ''
			
			nextSteps['Issues validated']= [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? 'Waiting for Supervisor to Add Parts based on issues validated.' : ''
			nextSteps['Serviceman reached'] = [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1 ? (!buttonLogStatus.estimationApproved && consumerServiceRequest.EWRequest ? 'Waiting for Supervisor to validate issues.' : (!buttonLogStatus.estimationApproved && !consumerServiceRequest.EWRequest ? 'Waiting for Supervisor to add parts and generate estimation' : 'Technician reached, awaiting Service Initiation.')) : ''
			nextSteps['Service initiated'] = [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? 'Technician reached, awaiting Service completion.' : ''
			nextSteps['Claim estimation approved'] = [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? (!buttonLogStatus.tecnicianReached ? 'Waiting for supervisor to create visit once parts are available.' : 'Technician reached, awaiting Service completion.') : ''
            nextSteps['Parts pending'] = 'Parts awaited by Repair team.'
		    nextSteps['Repair completed'] = (consumerServiceRequest && ['VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1) ? 'Please complete service once parts are collected.' : [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? 'Technician reached, awaiting Service completion.' : ''
			nextSteps['Pending for assignment'] = ''
			// nextSteps['Repair completed'] = [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? 'Technician reached, awaiting Service completion.' : ''
			if(consumerServiceRequest && consumerServiceRequest.Status === 'Pending for assignment' && ['VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 && !accessFn('READ_ONLY')){
				nextSteps['Pending for assignment'] = ((consumerServiceRequest && !consumerServiceRequest.DropoffDetails && !consumerServiceRequest.shiftParts) ? 'Please select Vendor and save the Access Point from the panel below and then ' : '') + (!( consumerServiceRequest && consumerServiceRequest.shiftParts && consumerServiceRequest.shiftParts.length > 0) ? 'Add part details for the request' : '');
			}
			if (nextSteps[consumerServiceRequest.Status]) {
				return nextSteps[consumerServiceRequest.Status];
			} else {
				return '';
			}
		}

		//claim and claim new
		// if(adjudicationChecklistData && adjudicationChecklistData.NewChecklist && adjudicationChecklistData.NewChecklist.length && accessFn('EDIT_ADJUDICATION_CHECKLIST')){
		// 	return  "Make sure all the checks are done, as per 'Checklist'";
		// };
		//end//
		
		//pick up carryin start
		if(pickupCarryInChecks && consumerServiceRequest.consumerProduct && !consumerServiceRequest.consumerProduct.isAppleBrandID){//pick up carryin
			nextSteps['Service accepted'] = 'Waiting for the Customer Care Executive to receive device.'
			nextSteps['Device received'] = 'Waiting for the Customer Care Executive to complete mandatory checklist tasks.'
			nextSteps['Pending for acceptance'] = 'Waiting for engineer to accept the job.' + (isDOAEligible()?' (Eligible for DOA)': '')
			nextSteps['Work in progress'] =(isDOAEligible() ?
				(consumerServiceRequest.IsWarrantyApplicable == null || undefined ? 'Please update Warranty applicable status in Device Details section.' : '') + ' (Eligible for DOA)' :
				(consumerServiceRequest.DOAStatus == 'applicable' ?
				(((requestSFA && requestSFA.length > 0)||(consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0)) ? ' Customer Care Executive will choose action for the device from Dead on Arrival section below.' : 'Engineer will add symptoms') :
				(consumerServiceRequest.DOAStatus == 'replacement' ?' Engineer will select the new device from Dead on Arrival section below' :
				(consumerServiceRequest.IsWarrantyApplicable == null || undefined ? 'Please update Warranty applicable status in Device Details section.' :
				'Work in Progress with Engineer.'))))
			nextSteps['QC rejected'] = 'Device repair has failed QC. Device is to be given back to the Engineer.'
			nextSteps['Estimation generated'] = 'Waiting for Estimation Approval. Click on "Send for approval" in "Parts and Estimation" section below.'
			// nextSteps['Parts request accepted'] = 'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.'//doubts
			nextSteps['Parts request accepted'] = (consumerServiceRequest.DOAStatus == 'replacement')?'Waiting for store to issue required device in "Dead on arrival" section below.':'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.'
			nextSteps['Parts issued'] = (consumerServiceRequest.DOAStatus == 'replacement') ? 'Waiting for the Engineer to receive the "Replacement device" from store in "Dead on Arrival" section below.' : 'Waiting for Engineer to receive parts from store in "Parts Requested" section below.'
			nextSteps['Parts received'] = (consumerServiceRequest.DOAStatus == 'replacement') ? 'Waiting for the Engineer to complete DOA by handing over the defective device to Store' : 'All parts received, repair is in progress.'
			nextSteps['Service invoice generated'] = 'Service invoice has been generated.' +(consumerServiceRequest.allowReceivePayment ? 'Final payment can be received.' : ([SERVICETYPE.PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? 'Please create logistics request.' : (([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1) ? 'Please inform the customer.': '')))
			nextSteps['Payment received'] = 'Payment for the service has been received from the customer.' + (consumerServiceRequest.showGenerateInvoiceButton ? 'Service invoice can be generated.' : '')
			nextSteps['Pending for DOA approval'] = 'Waiting for the Supervisor to approve the DOA.'
			nextSteps['DOA certificate generated'] = 'Waiting for the Supervisor to approve the DOA.'
			nextSteps['Intimate customer'] = consumerServiceRequest.ServiceTypeID == 2 ? 'Please complete the service.' : 'Intimate customer'
			nextSteps['Repair completed'] ='Engineer completed the repair.'
			nextSteps['Pending for Assignment'] =''
			nextSteps['Advance collected'] =''
			nextSteps['Estimation approval'] ='Waiting for customer approval of estimation or override estimation approval in "Parts and Estimation" section below.'
			nextSteps['Estimation rejected'] ='Estimation has been rejected, parts may be added if required by the engineer.'
			nextSteps['QC pending'] ='Engineer completed the repair. Handed over the device to QC.'
			nextSteps['Repair cancel'] ='Repair has been cancelled.'
			if (nextSteps[consumerServiceRequest.Status]) {
				return nextSteps[consumerServiceRequest.Status];
			} else {
				return getStatusString(consumerServiceRequest.Status, consumerServiceRequest.ServiceTypeID, accessFn);
			}
		}			
		//pick up carryin end
        
		//pick up gsx
		if(pickupCarryInGSXChecks && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){//pick up gsx
			nextSteps['Service accepted'] = 'Waiting for the Customer Care Executive to receive device.'
			nextSteps['Device received'] = (consumerServiceRequest.IsWarrantyApplicable == null || undefined) ? 'Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, Please update Warranty applicable status in Device Details section to enable Non Hardware Closure.' : 'Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, click on Non Hardware Closure for software repair or use Provisional Estimate to give a repair estimate to customer.'
			nextSteps['Pending for acceptance'] = 'Waiting for engineer to accept the job.' + (isDOAEligible()?' (Eligible for DOA)': '')
			nextSteps['Work in progress'] = (isDOAEligible() ?
				(consumerServiceRequest.IsWarrantyApplicable == null || undefined ? 'Please update Warranty applicable status in Device Details section.' : '') + ' (Eligible for DOA)':
				(consumerServiceRequest.DOAStatus == 'applicable' ? ' Customer Care Executive will choose action for the device from Dead on Arrival section below.':
				(consumerServiceRequest.DOAStatus == 'replacement' ? 'Engineer will select the new device from Dead on Arrival section below':
				(consumerServiceRequest.IsWarrantyApplicable == null || undefined ? 'Please update Warranty applicable status in Device Details section.' :
				'Work in Progress with Engineer.'))))
			nextSteps['Estimation generated'] = 'Waiting for Estimation Approval. Click on "Send for approval" in "Parts and Estimation" section below.'
			nextSteps['Parts request accepted']='Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.'
			nextSteps['Parts request accepted']=(consumerServiceRequest.DOAStatus == 'replacement')?'Waiting for store to issue required device in "Dead on arrival" section below.':'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.'
			nextSteps['Parts request accepted']= consumerServiceRequest.enableFetchGSXStatus ? 'To proceed with request, please check the GSX request status by clicking on “Fetch GSX Status”':'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.'
			nextSteps['Advance collected']= (consumerServiceRequest.IsWarrantyApplicable == null || undefined) ? 'Please update Warranty applicable status in Device Details section.' : ''
			nextSteps['Parts issued']=(consumerServiceRequest.DOAStatus == 'replacement') ? 'Waiting for the Engineer to receive the "Replacement device" from store in "Dead on Arrival" section below.' : 'Waiting for Engineer to receive parts from store in "Parts Requested" section below.'
			nextSteps['Parts received']=(consumerServiceRequest.DOAStatus == 'replacement') ? 'Waiting for the Engineer to complete DOA by handing over the damaged device to Store' : 'All parts received, repair is in progress.'
			nextSteps['Service invoice generated']='Service invoice has been generated.'
			nextSteps['Payment received']='Payment for the service has been received from the customer.'
			nextSteps['DOA certificate generated']=(!isHandedToStore() && !isHandedToCC()?'Waiting for the engineer to handover device to Store': (isHandedToStore()?'Waiting for the Store to handover device to Customer Care Executive':'Waiting for the Customer Care Executive to complete the DOA'))
			nextSteps['Intimate customer']= consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.loanerDetails && ['LORQ','LOSE','LDOA'].indexOf(consumerServiceRequest.loanerDetails.currentStatus) > -1 ? 'Please cancel loaner request in Loaner Details tab.' : consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN ? 'Please complete the service.' : 'Intimate customer.'
			nextSteps['Provisional estimate updated']=(consumerServiceRequest.IsWarrantyApplicable == null || undefined) ? 'Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, Please update Warranty applicable status in Device Details section to enable Non Hardware Closure.' : 'Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, click on Non Hardware Closure for software repair or use Provisional Estimate to give a repair estimate to customer.'
			nextSteps['Provisional estimate generated']=(consumerServiceRequest.IsWarrantyApplicable == null || undefined) ? 'Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, Please update Warranty applicable status in Device Details section to enable Non Hardware Closure.' : 'Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, click on Non Hardware Closure for software repair or use Provisional Estimate to give a repair estimate to customer.'						
			nextSteps['Non hardware closure details updated']='Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, click on Non Hardware Closure for software repair or use Provisional Estimate to give a repair estimate to customer.'
			nextSteps['Repair completed'] = 'Repair has been completed.'
			nextSteps['Repair cancel']= 'Repair has been cancelled.'
			nextSteps['QC pending']= 'Engineer completed the repair. Handed over the device to QC.'
			nextSteps['Estimation rejected']= 'Estimation has been rejected, parts may be added if required by the engineer.'
			nextSteps['Estimation approval']= 'Waiting for customer approval of estimation or override estimation approval in "Parts and Estimation" section below.'
			nextSteps['QC rejected']= 'Device repair has failed QC. Device is to be given back to the Engineer.'
			nextSteps['Pending for Assignment']= ''

			let diagComp = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis completed',PerformedByRole:'ServicelocationEngineer'});
			let cannotDiag = findIndex(consumerServiceRequest.logs, {Status: 'Cannot be diagnosed',PerformedByRole:'ServicelocationEngineer'});
			if(consumerServiceRequest.logs && (diagComp != -1 || cannotDiag != -1)  && accessFn('REPAIR_DEVICE') && !(addedFaultActions && addedFaultActions.length>0)){
				nextSteps['Work in progress'] = (consumerServiceRequest.IsWarrantyApplicable == null || undefined ? 'Please update Warranty applicable status in Device Details section.' : "Waiting for Engineer to Validate Issues and add component code, issue code, action details in 'Issues Reported' section below.");
			}
			if(!isDiagnosisCompleted() && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
				nextSteps['Work in progress'] = (consumerServiceRequest.IsWarrantyApplicable == null || undefined ? 'Please update Warranty applicable status in Device Details section.' : "Device is not eligible for integrated diagnosis, please click on complete diagnosis/cannot diagnose to capture the notes and proceed.");
			}
			if(!isDiagnosisCompletedByCC() && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && !consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
				nextSteps['Device received'] = "Device is not eligible for integrated diagnosis, please click on complete diagnosis/cannot diagnose to capture the notes and proceed.";
				nextSteps['Provisional estimate updated'] = "Device is not eligible for integrated diagnosis, please click on complete diagnosis/cannot diagnose to capture the notes and proceed.";
				nextSteps['Provisional estimate generated'] = "Device is not eligible for integrated diagnosis, please click on complete diagnosis/cannot diagnose to capture the notes and proceed.";
				nextSteps['Non hardware closure details updated'] = "Device is not eligible for integrated diagnosis, please click on complete diagnosis/cannot diagnose to capture the notes and proceed.";
			}
			if(!((consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey) || (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue))){
				if(consumerServiceRequest.IsWarrantyApplicable == null || consumerServiceRequest.IsWarrantyApplicable ==  undefined){
					nextSteps['Device received'] = "Customer Care Executive can Inspect Device to log a hardware repair, Please update Warranty applicable status in Device Details section to enable Non Hardware Closure.";
				}else{
					nextSteps['Device received'] = "Customer Care Executive can Inspect Device to log a hardware repair, or click on Non Hardware Closure to complete the repair as a non-part service repair.";
				}
			}
			let issuesValidatedLog = find(consumerServiceRequest.logs,{Status:'Issues validated'})

			if(consumerServiceRequest.logs && consumerServiceRequest.issues.length && !(consumerServiceRequest.gsxRepairSymtomsIssues && consumerServiceRequest.gsxRepairSymtomsIssues.length) && (addedFaultActions && addedFaultActions.length>0) && issuesValidatedLog && showRequestValid.sendIssuesGSXFlag && accessFn('REPAIR_DEVICE')){
				nextSteps['Work in progress'] = 'Waiting for Engineer to "Select Symptom And Action" in "Report Issues to GSX" section below.';
			}
			// if(!showRequestValid.sendIssuesGSXFlag && accessFn('REPAIR_DEVICE') && partsRequested && !partsRequested.length && (consumerServiceRequest.gsxRepairSymtomsIssues && consumerServiceRequest.gsxRepairSymtomsIssues.length) && (addedFaultActions && addedFaultActions.length>0)){
			if(!appleDropOffFlow && !showRequestValid.sendIssuesGSXFlag && accessFn('REPAIR_DEVICE') && partsRequested && !partsRequested.length && (consumerServiceRequest.gsxRepairSymtomsIssues && consumerServiceRequest.gsxRepairSymtomsIssues.length) && (addedFaultActions && addedFaultActions.length>0)){
				nextSteps['Work in progress'] = 'Please select GSX repair type';
			}
			if(!showRequestValid.sendIssuesGSXFlag && accessFn('REPAIR_DEVICE') && consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true"  && partsRequested && !partsRequested.length && (consumerServiceRequest.gsxRepairSymtomsIssues && consumerServiceRequest.gsxRepairSymtomsIssues.length) && (addedFaultActions && addedFaultActions.length>0)){
				if(consumerServiceRequest.gsxRepairFlags.repairTypeId){
					nextSteps['Work in progress'] = 'Please Create GSX repair type';
				}
			}
			if(consumerServiceRequest.gsxRepairFlags.repairTypeId && partsRequested && partsRequested.length && accessFn('REPAIR_DEVICE') && !consumerServiceRequest.gsxRepairRequest.confirmationNumber){
				nextSteps['Parts request accepted'] = 'Proceed with creating GSX repair';
			}
			if(consumerServiceRequest.gsxRepairFlags.repairTypeId && partsRequested && !partsRequested.length && accessFn('REPAIR_DEVICE') && !consumerServiceRequest.gsxRepairRequest.confirmationNumber){
				nextSteps['Work in progress'] = 'Please add part to Proceed with creating GSX repair';
			}
			if(consumerServiceRequest.gsxRepairFlags.repairTypeId && partsRequested && partsRequested.length && accessFn('ACCEPT_RETURN_DEVICE') && !consumerServiceRequest.gsxRepairRequest.confirmationNumber){
				nextSteps['Parts request accepted'] = 'Waiting for Engineer to create GSX repair';
			}
			if(appleDropOffFlow && accessFn('REPAIR_DEVICE') && !consumerServiceRequest.gsxRepairRequest.confirmationNumber && !consumerServiceRequest.ProvisionalGSXNumber && showRequestLogs.isIssuesValidated && !showRequestValid.sendIssuesGSXFlag && (consumerServiceRequest.gsxRepairSymtomsIssues && consumerServiceRequest.gsxRepairSymtomsIssues.length) && (addedFaultActions && addedFaultActions.length>0)){
				if(consumerServiceRequest.GSX_REPAIR_SERVICE_APPROACH){
					if(consumerServiceRequest.gsxRepairFlags.nreType === 'SRC') {
						nextSteps['Work in progress'] = 'Please verify and validate screening request details to proceed further.';
					}else {
						nextSteps['Work in progress'] = 'Waiting for Engineer to create GSX repair';
					}
				}else{
					nextSteps['Work in progress'] = 'Please select the appropriate service approach or strategy best suited for the repair of this device.';
				}
			}
			if(accessFn('GSX_FLOW') && consumerServiceRequest.CREATED_AT_DROPOFF_PSL == "true" && accessFn("ACCEPT_RETURN_DEVICE")){
				nextSteps['Device shipment requested'] = "Customer care executive at drop-off location to confirm customer’s preference for next steps.";
				nextSteps['Ready for shipment'] = "RDO Customer Care Executive to ship the device to the service centre for Repair.";
				nextSteps['Shipped to service centre'] = "Device shipped to service centre, waiting for customer care executive at service centre to receive device.";
				nextSteps['Device received at service centre'] = "Customer Care Executive at Service Centre can inspect device & assign job to engineer for further examination and repair.";
				if(!accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')) nextSteps['Repair completed'] = "Repair Completed. Complete outward checklist and ship device to drop-off location.";
				if(consumerServiceRequest.logs && consumerServiceRequest.logs?.[0]?.Status && (consumerServiceRequest.logs[0].Status === 'Repair completed and checklist updated')) {
					nextSteps['Repair completed'] = "Repair completed and checklist updated.";
				}
			}
			if(accessFn('GSX_FLOW') && consumerServiceRequest.CREATED_AT_DROPOFF_PSL == "true"){
				nextSteps['Device shipped to drop-off location'] = 'Device Shipped to Drop-off Location. Waiting for Customer Care Executive at Drop-off Location to receive device';
				nextSteps['Estimation rejected']= 'Estimation has been rejected, parts may be added if required by the engineer. Alternatively, please remove parts to enable completion of no service repair.'
				if(find(consumerServiceRequest.logs,{Status: 'Estimation rejected'})){
					nextSteps['Estimation generated'] = 'Click on "Send for approval" in "Parts and Estimation" section below. Alternatively, please remove parts to enable completion of no service repair.'
				}
			}
			if(appleDropOffFlow && accessFn('REPAIR_DEVICE') && showRequestLogs.displayDiagnosisStepsToEngFor2ndTime && (showRequestLogs.diagnosisCompletedByEngg2 || showRequestLogs.diagnosisCancelByEngg2)) {
				nextSteps['Work in progress'] = 'Please verify and validate component code, issue code, action details in “Issue Reported” section below. Post verification, please select the appropriate GSX repair type.'
			}
			if (nextSteps[consumerServiceRequest.Status]) {
				return nextSteps[consumerServiceRequest.Status];
			} else {
				return consumerServiceRequest.Status;
			}
		}
		//pick up gsx end

		//replacement flow start
		if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1){ //replacement flow
			nextSteps['Document pending'] = 'Waiting for customer to provide pending documents'
			nextSteps['Document verification pending'] = 
				!checkDeviceValueAndDateAdded() ? "Add Device Date of Purchase and Device Value in 'Device Details' below." : 
				[SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? "Please verify the document uploaded and as next step either approve or cancel service" : 
				[SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? "Please verify the document uploaded and as next step either approve reimbursement or cancel service" : ''
			nextSteps['Replacement authorized'] = 
				!checkDeviceValueAndDateAdded() ? "Add Device Date of Purchase and Device Value in 'Device Details' below." :
			    'Allocate the replacement device to proceed'
			nextSteps['Replacement allocated'] = [65].indexOf(consumerServiceRequest.ServiceTypeID) > -1?'Please create or Add shipment details for sending replacement and returning defective device.':[66].indexOf(consumerServiceRequest.ServiceTypeID) == -1?'Please create or add shipment details using which replacement device will be shipped':""
			nextSteps['Replacement shipment created'] = 'Waiting for user to dispatch the device'
			nextSteps['Device dispatched'] = [SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1?'Please wait for the Replacement to get delivered; mark it manually if it is not updated automatically.':'Please complete the service once the device has been delivered'
			nextSteps['Payment received'] = 'Please create or add shipment details using which replacement device will be shipped'
			nextSteps['Reimbursement approved'] = 'Please update the check number to close the request'
			nextSteps['Replacement options provided'] = 'Please choose your preferred option to get a replacement.'
			nextSteps['Defective awaited'] = 'Please wait to receive the defective before completing the service else plan will not get transferred to the replaced device.'
			nextSteps['Defective received'] = consumerServiceRequest.DEVICE_INSPECTION_REQUIRED?"":'Please complete the service.'
			if (nextSteps[consumerServiceRequest.Status]) {
				return nextSteps[consumerServiceRequest.Status];
			}else{
				return getStatusString(consumerServiceRequest.Status, consumerServiceRequest.ServiceTypeID, accessFn);
			}
		}
		//replacement flow end

		if(berChecks){
			if (nextSteps[consumerServiceRequest.Status]) {
				return nextSteps[consumerServiceRequest.Status];
			} 
		}

		
	};

	const getDisplayText = (status)=> {
		let displayNames = {
			'Convert to reimbursement':'Convert To Reimbursement',
			'Enter check number':'Enter Check Number',
			'Parts received':'Parts Received',
			// 'Accept': localString("Accept"),
			// 'Device received':accessFn('SHOW_DEVICE_SHIPPED_BUTTON')  || ([10,11,12,16,23,38,52].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW') ? localString('Receive Device'): localString('Receive & Inspect Device'),
			'Device received': onsiteChecks ? localString('Receive & Inspect Device'):localString('Receive Device'),
			'Pending for Assignment': localString('Upload Inspection Documents'),
			'Service initiate': localString('Update Job'),
			'Work in progress': localString('Accept/Reject Job'),
			'Repair completed': (consumerServiceRequest.DOAStatus == 'replacement'? localString('Complete DOA'): localString('Complete Repair')),
			'Service completed': localString('Complete Service'),
			'Repair cancel': localString('Cancel Repair'),
			'Service cancel': ['Cancellation requested','Service cancel initiated'].indexOf(consumerServiceRequest.Status) > -1  ? localString('Approve') : localString('Cancel Service'),
			'Estimation rejected': localString('Reject Estimation'),
			'Advance collected': localString('Collect Advance'),
			'QC completed': claimNewWorkshopChecks ? localString("Complete QC") : localString('QC Completed'),
			'Service invoice generated': localString('Generate Invoice'),
			'Device handed over': localString('Handover Device'),
			'Device accepted': localString('Accept Device'),
			// 'Device returned': 'Complete Job and Handover Device',
			'Diagnosis completed': localString('Diagnosis Completed'),
			'Diagnosis initiated': localString('Initiate Diagnosis'),
			'Cannot be diagnosed': localString('Cannot Diagnose'),
			'Device collected': localString('Collect Device'),
			'Parts request accepted': localString('Accept Parts Request'),
			'Parts request rejected': localString('Reject Parts Request'),
			'Call attempted': localString('Log Call'),
			'DOA checklist': localString('DOA Checklist'),
			'Service reschedule': localString('Create Visit'),
			'Service reassign': localString('Reassign'),
			'Accept service': localString('Accept'),
			'Reject service': localString('Reject'),
			'Service payment received': (consumerServiceRequest==0 ? localString('Rate'):localString('Payment received')),
			'Begin journey': localString('Begin journey'),
			'Serviceman reached': localString('Reached'),
			'Complete Repair' : localString('Complete Repair'),
			'Visit unproductive': localString('Unresolved Visit'),
			'Visit productive': localString('Resolved Visit'),
			'Iris submitted': localString('Submit IRIS'),
			'Payment received': onsiteChecks ? localString('Receive Payment') :localString('Payment received'),
			'Job updated': localString('Update Job Status'),
			'Task Created' : localString('Create Task'),
			'RET approved' : localString('Approve'),
			'RET rejected' : localString('Reject'),
			'Cancel service' : localString('Approve'),
			'Reject cancel' : localString('Reject'),
			'Service cancel initiated': localString('Initiate Service Cancel'),
			'Reschedule requested': (([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.UNINSTALLATION, SERVICETYPE.INSPECTION].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REPLACEMENT_PROCESS')) ? localString('Create Visit') : localString('Schedule request'),
			'Device not repairable' : onsiteChecks ? localString('Unable to repair'):localString('Device not repairable'),
			'Claim estimation approved': localString('Approve Claim Estimation'),
			// 'Repair completed': onsiteChecks ? localString('Complete repair') : localString('Complete Repair'),
			'Claim withdrawn': onsiteChecks ? localString('Withdraw Claim'): localString('Claim withdrawn'),
			'Device handed over': localString("Handover device"),
            'Device accepted': localString("Accept device"),
			'Device inspected': localString('Inspect Device'),
			'Claim initiated': localString("Initiate Claim"),
			'Claim raised': localString("Pending Review"),
			'Claim invalid': claimChecks ? localString("Claim Invalid") :localString("Invalid Claim"),
			'In-principle approved': localString("In-principle Approval"),
            'Service initiated': localString("Initiate Service"),
            'Device returned': localString("Complete Job and Handover Device"),
            'Claim rejected': localString("Reject Claim"),
            'Service cancel': localString("Cancel Service"),
            'BER confirmed': localString("BER Refund"),
            'BER rejected': localString("Reject BER"),
            'Claim estimation generated': localString("Generate estimation"),
            'Cancel Service Without Dispatch':localString("Cancel Service Without Dispatch"),
			'Perform QC': localString("Perform QC"),
            'Cannot be diagnosed': localString("Cannot Diagnose"),
            'Intimate customer': localString('Inform customer'),
			'Replacement authorized':localString('Authorize Replacement'),
            "Replacement shipment created": localString('Create Shipment'),
            'Replacement allocated':localString('Allocate Device'),
            'Replacement unavailable':localString('Replacement Unavailable'),
            'Document pending':localString('Document Pending'),
            'Device dispatched': localString('Device dispatched'),
            'Reimbursement approved':localString('Approve Reimbursement'),
            'Replacement options provided':localString('Provide replacement options'),
            'Security deposit charged':localString("Charge Security Deposit"),
            'Security deposit released':localString("Release Security Deposit"),
			"Replacement device collected":localString("Device Collected"),
			"Collection order created":localString("Update Order details"),
			//ber
			'BER approved': 'Approve BER',
            'BER rejected': 'Reject BER',
            'BER repair': 'BER repair',
            'BER cancel': 'Repair cancel',
            'BER accessories received': 'BER Accessories Received',
            'BER device received': 'BER Device Received',
            'BER payment initiated' : 'Initiate BER Payment',
            'BER payment completed': 'BER Payment Complete',
			'BER revision required':'BER payment revision required',
			// 'BER payment requested': 'Request BER Payment',
            // 'Service completed': getServiceCompletedButtonName(),
            'Call attempted': 'Log Call',
            'BER replacement issued': 'Issue BER Replacement',
            'BER replacement confirmed' : 'BER replacement',
            'BER shipment created': 'Create shipment',
            'Intimate customer': 'Inform customer',
			'Claim rejected': 'Reject Claim',
			'BER documents validated':'BER Documents Validated',
			//pick up
			'Request raised': localString("Select GSX Repair Type"),
			'Analysis completed': localString('Complete Analysis'),
			'Refund request generated': localString('Generate Refund'),
			'Refund Rejected': (['SkyworthDashboard','SmartTechnoDashboard'].indexOf(consumerServiceRequest.Source) > -1  || (consumerServiceRequest && consumerServiceRequest.consumerProduct &&  consumerServiceRequest.consumerProduct.BrandID == 28 &&  [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,56,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 )) ? localString('Reject') : localString("Reject Refund"),
			'Awaiting Supplier Response': localString('Refund with Supplier’s response'),
			'Refund Without Supplier Response': (['SkyworthDashboard','SmartTechnoDashboard'].indexOf(consumerServiceRequest.Source) > -1  || (consumerServiceRequest && consumerServiceRequest.consumerProduct &&  consumerServiceRequest.consumerProduct.BrandID == 28 &&  [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,56,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 )) ? localString('Approve') : localString('Refund without supplier\' response'),
			'Refund Approved': localString('Approve Refund'),
			'Refund Rejected by Vendor': localString('Reject Refund'),
			'Send link to consumer': localString('Send Link'),
			'Repair cancel initiated': localString('Cancel Repair'),
			'Plans Description' : localString('Select Plan Benefits'),
			'Assign': localString('Assign to me'),
			'Ready for shipment':localString('Repair At Service Centre'),
			//refund process

			//refurbishment flow
			'refurbishment shipment created': localString('Create shipment'),
			'Repair initiated':localString('Initiate Repair'),
			'Parts pending':localString('Parts Pending')
		};
		if(refurbishmentFlowCheck){
			displayNames['Device received']=localString('Device Received')
			displayNames['Repair completed']=localString('Repair completed')
		}
		if(refundProcessCheck){
			displayNames['Awaiting Supplier Response']=localString('Awaiting Supplier Response')
			displayNames['Refund Without Supplier Response']=localString('Refund without supplier\' response')
		}
		if(replacementFlowChecks){//ber
			displayNames['Device dispatched']=localString('Dispatch Device')
		}
		if(berChecks){//ber
			displayNames['Device dispatched']='Replacement Device dispatched'
			displayNames['Service completed']= getServiceCompletedButtonName()
		}
		if (accessFn('QC_DEVICE')) {
			displayNames['Device collected'] = 'Perform QC';
		}
		if(consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId && partsRequested && partsRequested.length && consumerServiceRequest.isVerifiedProduct){
			displayNames['Request raised'] = 'Create GSX Repair';
		}
		if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.isAppleBrandID){
			displayNames['Intimate customer'] = 'Intimate customer';
		}
		if(showRequestLogs.isDeviceInspected){
			displayNames['Initiate Diagnostics'] = 'Initiate Diagnosis'
		} else {
			displayNames['Initiate Diagnostics'] = 'Run Diagnosis'
		}
		if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !consumerServiceRequest.ProvisionalGSXNumber && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId){
			if(consumerServiceRequest.gsxRepairFlags.nreType === 'SRC') {
				displayNames['Request raised'] = 'Complete Screening Request';
			}else {
				displayNames['Request raised'] = 'Create GSX Repair';
			}
		}
		if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && consumerServiceRequest.ProvisionalGSXNumber && consumerServiceRequest.Status === 'Estimation generated'){
			displayNames['Request raised'] = 'Complete as No Repair Service';
		}
		if (displayNames[status]) {
			return displayNames[status];
		} else {
			return status;
		}
    };

	const enableDisableButton = (statusButtons) => {
		let allStatus = []
		if(statusButtons) {
			for (let iCount =0;iCount<statusButtons.length; iCount++) {
			let statusObject = {}
			statusObject.name = statusButtons[iCount]
			statusObject.disabledProp = false
			if(statusObject.name === "Service reschedule" && consumerServiceRequest.taskCount){
				if(consumerServiceRequest.taskCount && consumerServiceRequest.taskCount.open === 0){
				statusObject.disabledProp = false
				}else{
				statusObject.disabledProp = true
				}
			}else{
				statusObject.disabledProp = false
			}
			allStatus.push(statusObject)
			}
		}
		return allStatus
	}
	const getCurrentRoleInt =() =>{
        let role;
        if (JSON.parse(qFilter)) {
            if (JSON.parse(qFilter).hasUnrestrictedAccess) {
                role = 'Superadmin';
            } else {
                switch (true) {
                case (accessFn('ACCEPT_RETURN_DEVICE')):
                    role = 4;
                    break;
                case (accessFn('STORE_MANAGEMENT')):
                    role = 2;
                    break;
                case (accessFn('REASSIGN_JOBS')):
                    role = 5; // is not available right now as int. entered for future
                    break;
                case (accessFn('REPAIR_DEVICE')):
                    role = 1;
                    break;
                case (accessFn('QC_DEVICE')):
                    role = 3;
                    break;
                }
                return role;
            }
        }
        return role;
    }
	
	const isSupOrEngg=() =>{
		return (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS'));
	}
	const isQCRejectedLog=()=> {
		let isQcRejected = false;
		let qc = find(consumerServiceRequest.logs, {Status: 'QC rejected'})
		if(qc) {
			isQcRejected = true;
		}
		return isQcRejected;
	}

	const isDiagnosisCompleted=() =>{
		let diagInit = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis initiated'});
		let diagComp = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis completed'});
		let cannotDiag = findIndex(consumerServiceRequest.logs, {Status: 'Cannot be diagnosed'});

		if (diagInit != -1) {
			if (diagComp != -1) {
				return diagComp < diagInit;
			}
			if (cannotDiag != -1) {
				return cannotDiag < diagInit
			}
			return false
		}
		return true
	}
	const isHandedToEng= ()=> {
		let isHanded = false
		let deviceHanded = find(consumerServiceRequest.logs, {Status: 'Device handed over'})
		if (deviceHanded) {
			if (deviceHanded.DestinationType == 'ServicelocationEngineer' || deviceHanded.DestinationType == 'Supervisor') {
				isHanded = true;
			}
		}
		return isHanded;
	}
	const isDiagnosisInitiated=() =>{
		let isDiagnosisInitiated = false
		let diagInit = find(consumerServiceRequest.logs, {Status: 'Diagnosis initiated'})
		if (diagInit) {
			isDiagnosisInitiated = true;
		}
		return isDiagnosisInitiated;
	}

	const showQCInitiateDiagnosis = () => {
		let showQCInitiateDiagnosis = true;
		let diagInit = find(consumerServiceRequest.logs, {Status: 'Diagnosis initiated'});
		let qcReject = find(consumerServiceRequest.logs, {Status: 'QC rejected'});
		let diagInitIndex = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis initiated'});
		let qcRejectIndex = findIndex(consumerServiceRequest.logs, {Status: 'QC rejected'});
		if (diagInit && diagInit.UserID == UserID){
			let showQCInitiateDiagnosis = false;
			if(qcRejectIndex !== -1 && qcRejectIndex < diagInitIndex) {
			showQCInitiateDiagnosis = true;
			}
		}
		return showQCInitiateDiagnosis;
    }

	const isDiagnosisCompletedByCC =()=> {
		let diagInit = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis initiated',PerformedByRole:'CustomerCare'});
		let diagComp = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis completed',PerformedByRole:'CustomerCare'});
		let cannotDiag = findIndex(consumerServiceRequest.logs, {Status: 'Cannot be diagnosed',PerformedByRole:'CustomerCare'});

		if (diagInit != -1) {
			if (diagComp != -1 && cannotDiag != -1) {
				if(diagComp < cannotDiag){
					return diagComp < diagInit;
				} else {
					return cannotDiag < diagInit;
				}
			}
			else if (diagComp != -1) {
				return diagComp < diagInit;
			}
			else if (cannotDiag != -1) {
				return cannotDiag < diagInit
			}
			return false
		}
		return true
	}
    return(
        <>
			<ViewButtonMatrix
				{...props}
				showQCInitiateDiagnosis={showQCInitiateDiagnosis}
				getNextSteps={getNextSteps}
				isSupOrEngg={isSupOrEngg}
				getCurrentRoleInt={getCurrentRoleInt}
				// currencySymbol={currencySymbol}
				showRefreshButton={showRefreshButton}
				setShowRefreshButton={setShowRefreshButton}
				coveredUnderPlanCheck={coveredUnderPlanCheck}
				checkDeviceValueAndDateAdded={checkDeviceValueAndDateAdded}
				checkIfSalesChannelIsequiredAndPresent={checkIfSalesChannelIsequiredAndPresent}
				partsRequested={partsRequested}
				isServiceLocationAssigned ={isServiceLocationAssigned }
				ConsumerServiceRequestID={ConsumerServiceRequestID}
				getRequestById={getRequestById}
                cancelAnytime={showViewRequestFlags.cancelAnytime}
                showShippedButtonForStatus={showViewRequestFlags.showShippedButtonForStatus}
                warrantyAppicableNonClaimCheck={warrantyAppicableNonClaimCheck}
                isCancelApplicable={ showViewRequestFlags.isCancelApplicable}
                isServiceCancel={isServiceCancel}
                consumerServiceRequest={consumerServiceRequest}
                isRescheduleApplicable={isRescheduleApplicable}
				setShowRequestValid={setShowRequestValid}
                checkPaymentStatus={checkPaymentStatus}
				// paymentAmount = {paymentAmount }
                checkDecisionMatrixProcessCompleted={checkDecisionMatrixProcessCompleted}
                hideNextStepInTradeIn={hideNextStepInTradeIn}
                isServiceComplete = {isServiceComplete}
                isClaimWithdrawn={isClaimWithdrawn}
                enableAttemptCall={enableAttemptCall}
				showUpdateLogisticsRequestButton={showUpdateLogisticsRequestButton}
				showInitiateException={showInitiateException}
				isDOAApplicableLog={isDOAApplicableLog}
				showCompleteRefund={showCompleteRefund}
				planID={planID}
				countryConfig={countryConfig}
				showViewRequestFlags={showViewRequestFlags}
				setShowViewRequestFlags={setShowViewRequestFlags}
				showButton={showButton}
				showActionButton={showActionButton}
				getDisplayText={getDisplayText}
				// setShowActionButton={setShowActionButton}
				consumerDetails={consumerDetails}
				taskListArr={taskListArr}
				isSymptomMapAdded={isSymptomMapAdded}
				showCancelButtonFuncton={showCancelButtonFuncton}
				getSource={getSource}
				accessPoints={accessPoints}
				showCompleteServiceFunction={showCompleteServiceFunction}
				enableDisableButton={enableDisableButton}
				isEachPartReceived={isEachPartReceived}
				setIsEachPartReceived={setIsEachPartReceived}
				pendingForSpares={pendingForSpares}
				setPendingForSpares={setPendingForSpares}
				buttonLogStatus={buttonLogStatus}
				setButtonLogStatus={setButtonLogStatus}
				isHandedToEng={isHandedToEng}
				isDiagnosisInitiated={isDiagnosisInitiated}
				isQCRejectedLog={isQCRejectedLog}
				isDiagnosisCompleted={isDiagnosisCompleted}
				deviceAtLoggedInLoc={deviceAtLoggedInLoc}
				featureConfig={featureConfig}
				isRequestSFANeeded={isRequestSFANeeded}
				requestSFA={requestSFA}
				showRequestLogs={showRequestLogs}
				isDOAEligible={isDOAEligible}
				checkDOAApplicable={checkDOAApplicable}
				arrDiagnosisSuites={arrDiagnosisSuites}
				showRequestValid={showRequestValid}
				addedFaultActions={addedFaultActions}
				exception={exception}
				isHandedToCC={isHandedToCC}
				berValid = {berValid}
				setBerValid = {setBerValid}
			/>
        </>
    )
}
export default ViewButtons;



