import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import { Dropdown, Loader, Pagination } from "sfy-react";
import ShadowWrapper from "./ShadowWrapper";
import SearchTransactions from "./SearchTransactions";
import AllTransactions from "./AllTransactions";
import {
	displayError,
	manipulateDate,
	returnLocalDateTime,
} from "../../Utility/Utils";
import "./CaseManager.scss";

const mainHeaderDropDownOptions = [{ value: "Device Insurance" }];

const CaseManager = (props) => {
	const { apiCall, localString, accessFn, qFilter } = props;

	if (!accessFn("CASE_MANAGER")) {
		window.location.href = "/401";
	}

	const [showLoader, setShowLoader] = useState(false);
	const [filters, setFilters] = useState({
		sortBy: "",
		sortOrder: "",
		assigneeUserID: "",
		planID: "",
		claimStatus: "",
		caseStatusID: "",
	});
	const [searchFilters, setSearchFilters] = useState({
		productUniqueID: "",
		caseReferenceID: "",
		soldPlanCode: "",
		referenceID: "",
		mobileNo: "",
		email: "",
	});
	const [dateRange, setDateRange] = useState({
		startDate: manipulateDate(30, "minus"),
		endDate: manipulateDate(5, "add"),
	});
	const [pagination, setPagination] = useState({
		numberOfPages: 10,
		itemsPerPage: 30,
		pageNo: 1,
		range: 5,
	});
	const [cases, setCases] = useState([]);
	const [qFilterData, setqFilterData] = useState(JSON.parse(qFilter));
	const [assigneeUserList, setAssigneeUserList] = useState([]);
	const [planList, setPlanList] = useState([]);
	const [statusList, setStatusList] = useState([]);
	const [caseStatusOptionsList, setCaseStatusOptionsList] = useState([]);

	const getCases = (resetFilters = false) => {
		let data = {
			pagination: {
				itemsPerPage: pagination.itemsPerPage,
				pageNo: pagination.pageNo,
				range: pagination.range,
			},
			sDate: returnLocalDateTime(dateRange.startDate, "YYYY-MM-DD").split(
				" "
			)[0],
			eDate: returnLocalDateTime(dateRange.endDate, "YYYY-MM-DD").split(
				" "
			)[0],
		};

		if (filters.sortBy) {
			data.sortBy = filters.sortBy;
		}

		if (filters.sortOrder) {
			data.sortOrder = filters.sortOrder;
		}

		if (filters.caseStatusID) {
			data.caseStatusID = filters.caseStatusID;
		}

		if (filters.assigneeUserID) {
			data.assigneeUserID = filters.assigneeUserID;
		}

		if (filters.planID) {
			data.planID = filters.planID;
		}

		if (filters.claimStatus) {
			data.claimStatus = filters.claimStatus;
		}

		if (searchFilters.productUniqueID) {
			data.productUniqueID = searchFilters.productUniqueID;
		}

		if (searchFilters.caseReferenceID) {
			data.caseReferenceID = searchFilters.caseReferenceID;
		}

		if (searchFilters.soldPlanCode) {
			data.soldPlanCode = searchFilters.soldPlanCode;
		}

		if (searchFilters.referenceID) {
			data.referenceID = searchFilters.referenceID;
		}

		if (searchFilters.mobileNo) {
			data.mobileNo = searchFilters.mobileNo;
		}

		if (searchFilters.email) {
			data.email = searchFilters.email;
		}

		if (resetFilters) {
			data = {
				pagination: {
					itemsPerPage: 30,
					pageNo: 1,
					range: 5,
				},
				sDate: returnLocalDateTime(
					manipulateDate(30, "minus"),
					"YYYY-MM-DD"
				).split(" ")[0],
				eDate: returnLocalDateTime(
					manipulateDate(5, "add"),
					"YYYY-MM-DD"
				).split(" ")[0],
			};
		}

		setShowLoader(true);
		apiCall({
			url: "getCases",
			data: data,
			callBack: (result) => {
				setShowLoader(false);

				if (result.success) {
					if (result.data) {
						setCases(result.data.cases);
						if (result.data.pagination) {
							let paginationObject = result.data.pagination;
							setPagination((prevFilterData) => {
								return {
									...prevFilterData,
									...paginationObject,
								};
							});
						}
					}
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getServiceOperatorUsers = (
		isForReassignModal = false,
		extraData,
		callBackForReasssignModal
	) => {
		let data = {};

		if (isForReassignModal) {
			data = extraData;
		}

		setShowLoader(true);
		apiCall({
			url: "getServiceOperatorUsers",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					if (result.data) {
						let data = result.data.map((v) => ({
							...v,
							value: v.name,
						}));

						if (isForReassignModal && callBackForReasssignModal) {
							callBackForReasssignModal(data);
						} else {
							setAssigneeUserList(data);
						}
					}
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getStatusList = () => {
		setShowLoader(true);
		apiCall({
			url: "statusList",
			data: {},
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					if (result.data) {
						let data = result.data.map((v) => ({
							...v,
							value: v.DisplayText,
						}));
						setStatusList(data);
					}
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getPlanList = () => {
		let data = {
			qFilter: {},
		};
		data.qFilter.plans = qFilterData.PlanIDs;

		setShowLoader(true);
		apiCall({
			url: "getPlanList",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					if (result.data) {
						let data = result.data.map((v) => ({
							...v,
							value: v.PlanDisplayName,
						}));
						setPlanList(data);
					}
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getCaseStatuses = () => {
		setShowLoader(true);
		apiCall({
			url: "getCaseStatuses",
			data: {},
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					if (result.data) {
						let data = result.data.map((v) => ({
							...v,
							value: v.status,
						}));
						setCaseStatusOptionsList(data);
					}
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const resetPagination = () => {
		setPagination({
			numberOfPages: 10,
			itemsPerPage: 30,
			pageNo: 1,
			range: 5,
		});
	};

	const resetAllFilters = () => {
		setSearchFilters({
			productUniqueID: "",
			caseReferenceID: "",
			soldPlanCode: "",
			referenceID: "",
			mobileNo: "",
			email: "",
		});
		setFilters({
			sortBy: "",
			sortOrder: "",
			assigneeUserID: "",
			planID: "",
			claimStatus: "",
		});
		setDateRange({
			startDate: manipulateDate(30, "minus"),
			endDate: manipulateDate(5, "add"),
		});

		getCases(true);
	};

	useEffect(() => {
		getCases();
	}, [
		pagination.pageNo,
		filters.assigneeUserID,
		filters.claimStatus,
		filters.planID,
		filters.caseStatusID,
	]);

	useEffect(() => {
		getServiceOperatorUsers();
		getStatusList();
		getPlanList();
		getCaseStatuses();
	}, []);

	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}
			<ShadowWrapper classname="rounded-bottom">
				<div className="main-header">
					<div className="main-heading">
						{localString("Case Manager")}
					</div>
					<div>
						<Dropdown
							id="caseType"
							classObject={{
								container:
									"customised-border customised-border-radius",
								optionsSearch: "customised-border-radius",
								valueContainer: "customised-border-radius",
							}}
							value={"Device Insurance"}
							options={mainHeaderDropDownOptions}
							handleOnChange={(val) => {}}
							isDisabled={true}
							showTooltip={true}
							filterBy="value"
							searchText={""}
							showDownArrow={true}
							handleOnSearch={(e) => {}}
							noOptionsText={localString(
								"Cannot find the searched option"
							)}
							placeholder={""}
						/>
					</div>
				</div>
			</ShadowWrapper>
			<SearchTransactions
				{...props}
				getCases={getCases}
				getServiceOperatorUsers={getServiceOperatorUsers}
				cases={cases}
				setCases={setCases}
				assigneeUserList={assigneeUserList}
				statusList={statusList}
				planList={planList}
				caseStatusOptionsList={caseStatusOptionsList}
				pagination={pagination}
				setPagination={setPagination}
				resetPagination={resetPagination}
				resetAllFilters={resetAllFilters}
				showLoader={showLoader}
				setShowLoader={setShowLoader}
				filters={filters}
				setFilters={setFilters}
				searchFilters={searchFilters}
				setSearchFilters={setSearchFilters}
				dateRange={dateRange}
				setDateRange={setDateRange}
			/>
			<AllTransactions
				{...props}
				getCases={getCases}
				getServiceOperatorUsers={getServiceOperatorUsers}
				cases={cases}
				assigneeUserList={assigneeUserList}
				statusList={statusList}
				planList={planList}
				caseStatusOptionsList={caseStatusOptionsList}
				setCases={setCases}
				pagination={pagination}
				setPagination={setPagination}
				resetPagination={resetPagination}
				resetAllFilters={resetAllFilters}
				showLoader={showLoader}
				setShowLoader={setShowLoader}
				filters={filters}
				setFilters={setFilters}
				searchFilters={searchFilters}
				setSearchFilters={setSearchFilters}
				dateRange={dateRange}
				setDateRange={setDateRange}
			/>

			<div className="d-flex justify-center">
				<Pagination
					pages={pagination.numberOfPages}
					activePage={pagination.pageNo}
					paginate={(pageNo) =>
						setPagination((prevData) => ({
							...prevData,
							pageNo: pageNo,
						}))
					}
					className={{ container: "custom-pagination-padding" }}
				/>
			</div>
		</>
	);
};

export default CaseManager;
