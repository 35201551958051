import React from 'react';
import AddConsumer from './AddConsumer';
import {Modal} from 'sfy-react';

const AddConsumerModal = (props) => {
		const {localString,showConsumerModal,closeConsumerModal,customModalClass,message} = props;
		return (
		<div className={customModalClass}>
			<Modal showModal={showConsumerModal} closeModal={closeConsumerModal} showCloseBtn={true} className={customModalClass}>
				<Modal.Header>
					<h2> {localString("Add Consumers")} </h2>
				</Modal.Header>
				<Modal.Body>
					<AddConsumer {...props}></AddConsumer>
					{message && <div className="clearfix paddingLR20">{localString(message)}</div>}
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
		</div>
	)
}
export default AddConsumerModal;