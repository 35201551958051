import React from 'react';
import FilterInputs from '../../components/FilterInputs/FilterInput';
import './RequestSearch.scss'

const RequestSearch = (props) => {
    const {requestFilterData,hideClearButton,clearButtonFunction,requestInputsOnPage,buttonDisable,localString,applyFilter} = props;
    return (
        <div className="content time-period request-search">
            <div className="requestSearch">
                <div className="row form-group-filter-request">
                    <FilterInputs {...props} disableApplyBtn={buttonDisable} filterFunc={applyFilter} filterInputs={requestInputsOnPage} filterData={requestFilterData} hideClearButton={hideClearButton} clearSearch={!hideClearButton ? clearButtonFunction : ""} saveButtonText={localString("Search")}></FilterInputs>
                </div>
            </div>
        </div>
    )
}

export default RequestSearch
