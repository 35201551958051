import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Loader, Button } from "sfy-react";
import Swal from 'sweetalert2';

import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import NewActualCharge from './NewActualCharge';
import { displayError, validateThis } from '../../../../../Utility/Utils';

import './ActualCost.scss';

export default function (props) {
	const {
		actualCost, localString, consumerServiceRequest, showRequestLogs, apiCall, actualCostAllowed, getRequestById
	} = props;
	const [loader, setLoader] = useState(false);
	const [newCharges, setNewCharges] = useState([]);
	const [updateCharge, setUpdateCharge] = useState(false);
	const [currencyOptions, setCurrencyOptions] = useState([]);

	// Display Create, Update and Delete buttons
	const displayCUDBtns = (showRequestLogs.isServiceCancel || showRequestLogs.isServiceComplete) && actualCostAllowed;

	const validate = () => {
		let isArrayValid = true;
		let invalidArrayParam = '';

		newCharges.forEach(function (charge) {
			let validator;
			let arr = [
				{
					checkType: 'isNotEmpty',
					key: 'ChargeType'
				},
				{
					checkType: 'isNotEmpty',
					key: 'ChargeName'
				},
				{
					checkType: 'isNotEmpty',
					key: 'CurrencyCode'
				},
				{
					checkType: 'isNotEmpty',
					key: 'Amount'
				},
				{
					checkType: 'isFloat',
					key: 'Amount'
				},
				{
					checkType: 'isPositive',
					key: 'Amount'
				}
			];
			validator = validateThis(charge, arr);
			if (!validator.isValid) {
				isArrayValid = false;
				invalidArrayParam = validator.invalidParams[0];
			}
		});

		if (!isArrayValid) {
			Swal.fire({
				icon: 'error',
				text: localString("Please enter correct ") + localString(invalidArrayParam),
				confirmButtonText: localString('OK')
			});
			return isArrayValid;
		}
		return isArrayValid;
	}

	const createActualCost = () => {
		if (!validate()) return;
		let reqObj = {
			referenceID: consumerServiceRequest.ReferenceID,
			charges: newCharges
		}
		setLoader(true);
		apiCall({
			url: 'createActualCost',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					setNewCharges([]);
					getRequestById();
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const updateActualCost = () => {
		if (!validate()) return;
		let arr = []
		newCharges.map((charge) => {
			let obj = {
				Amount: charge.Amount,
				ExternalChargeID: charge.ExternalChargeID,
				TaxPercentage: charge.TaxPercentage,
			};
			arr.push(obj);
		});
		let reqObj = {
			referenceID: consumerServiceRequest.ReferenceID,
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			updatedCharges: arr
		}
		setLoader(true);
		apiCall({
			url: 'updateActualCost',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					setUpdateCharge(false);
					setNewCharges([]);
					getRequestById();
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const editCharge = (charge) => {
		setUpdateCharge(true);
		setNewCharges([{ ...charge }]);
	}

	const getCountries = useCallback(() => {
		setLoader(true);
		apiCall({
			url: 'getCountries',
			data: {},
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					if (result.data) {
						let countryDataList = [];
						result.data.countries.length && result.data.countries.map(a => {
							if(a.CurrencyCode && !countryDataList.includes(a.CurrencyCode)) {
								countryDataList.push(a.CurrencyCode);
							}
						});
						setCurrencyOptions(countryDataList.map(a => ({value: a})));
					}
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}, [])

	useEffect(() => {
		getCountries();
	}, [getCountries])

	return (
		<>
			{loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="">
				<PageContentWrapper
					title={localString("Actual Cost")}
					bodyClassName="panel-body-no-padding actual-cost-main"
				>
					<>
						<div className='estimationContent padding15 container-fluid'>
							{(actualCost?.length > 0) && <div className='boxShadowCard mb-15'>
								{actualCost.map((charge) => (
									<div className="partRow">
										<div className="row">
											<div className="col-xs-8">
												<div className={`charges-panel-content bold ${updateCharge && (newCharges[0]?.ChargeReferenceID === charge?.ChargeReferenceID) ? 'servify-purple' : ''}`}>
													{localString(charge.ChargeName) + ' - ' + localString(charge.ChargeType)}
													{displayCUDBtns && (newCharges[0]?.ChargeReferenceID !== charge?.ChargeReferenceID) && <span className="glyphicon glyphicon-edit editDeleteIcon" onClick={() => editCharge(charge)}></span>}
												</div>
											</div>

											<div className="col-xs-4 text-right bold">
												<div className={`charges-panel-content text-right ${updateCharge && (newCharges[0]?.ChargeReferenceID === charge?.ChargeReferenceID) ? 'servify-purple' : ''}`}>
													<span>
														{/* {charge.currencySymbol && <span className="currencySymbol" dangerouslySetInnerHTML={{__html: charge.currencySymbol }}></span>}
														{charge.Amount} */}
														{`${charge.CurrencyCode} ${charge.Amount}`}
													</span>
												</div>
											</div>
										</div>

										{charge.TaxAmount && (
											<div className='row'>
												<div className="col-xs-8">
													<div className="charges-panel-content">
														{charge.TaxType ? charge.TaxType : "Tax Amount"}
													</div>
												</div>
												<div className="col-xs-4 text-right">
													<div className="charges-panel-content text-right">
														<span>
															{/* <span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span> */}
															{`${charge.CurrencyCode} ${charge.TaxAmount}`}
														</span>
													</div>
												</div>
											</div>
										)}
									</div>
								))}

								{(newCharges?.length > 0) &&
									<NewActualCharge
										{...props}
										updateCharge={updateCharge}
										currencyOptions={currencyOptions}
										loader={loader} setLoader={setLoader}
										consumerServiceRequest={consumerServiceRequest}
										newCharges={newCharges} setNewCharges={setNewCharges}
									/>
								}

							</div>}

							{displayCUDBtns && (
								<div className="">
									{(!updateCharge) ?
										<Button
											type="button"
											className="btn squareBtnGhost"
											handleOnClick={() => {
												setNewCharges((prevCharges) => [...prevCharges, {}])
												setUpdateCharge(false);
											}}
										>
											<span className="glyphicon glyphicon-plus addBtn"></span>
											{localString('Add Cost')}
										</Button> :
										<Button
											type="button"
											className="btn squareBtnGhost"
											handleOnClick={() => {
												setNewCharges((prevCharges) => [])
												setUpdateCharge(false);
											}}
										>
											{localString('Cancel')}
										</Button>
									}
									{(newCharges.length > 0) &&
										<Button
											className="btn squareBtnGhost float-right"
											type="button"
											data-type="submit"
											handleOnClick={() => {
												!updateCharge ? createActualCost() : updateActualCost()
											}}
										>
											{localString("Submit")}
										</Button>
									}
								</div>
							)}

						</div>
					</>
				</PageContentWrapper>
			</div>
		</>
	);
}