import { generateUUID, getLanguageCode, returnTimezone } from './Utils';
import axios from 'axios';
const CommonApiCall = (props) => {
	let apis = {
		getSoldPlanCustomers: "Aegis/getSoldPlanCustomers",
		getTransactionSummary: "Aegis/getTransactionSummary",
		cancelPlanOrder: "Aegis/cancelPlanOrder",
		getCancellationOptions: "Aegis/getCancellationOptions",
		checkCancellationEligibility: "Aegis/checkCancellationEligibility",
		getSales: 'Aegis/getSales',
		initiateRefund: "Aegis/initiateRefund",
		updateSoldPlan: "Aegis/updateSoldPlan",
		resendActivationCode: "Aegis/resendActivationCode",
		resendPaymentLink: 'Aegis/resendPaymentLink',
		modifyCustomerDetails: "Aegis/modifyCustomerDetails",
		getPlanList: "Aegis/getPlanList",
		GetBrands: "Consumer/getBrand",
		getSubcategory: "Consumer/getSubcategory",
		GetProducts: "Consumer/getProduct",
		getPaginatedList: "Consumer/getPaginatedList",
		consumerSearch: "Consumer/consumerSearch",
		getMinimalConsumerDetails: "Consumer/getMinimalConsumerDetails",
		setConsumerDetails: "Consumer/setConsumerDetails",
		getConsumerCommunicationLogs: "Communications/getConsumerCommunicationLogs",
		getCallStatus: "Communications/getCallStatus",
		createCommunicationLog: "Communications/createCommunicationLog",
		createConsumer: "Consumer/createConsumer",
		slotCheckingWithLogistics: 'Consumer/slotCheckingWithLogistics',
		getOrganizationOutletLocationList: "OrganizationOutletLocation/getOrganizationOutletLocationList",
		getFilteredLogisticsRequest: "Logistics/getFilteredLogisticsRequest",
		getVendorList: "Logistics/getVendorList",
		getVendorArray: "Logistics/getVendorArray",
		getServiceTypeConfig: "Logistics/getServiceTypeConfig",
		getLogisticsRequestDetail: "Logistics/getLogsticsRequestDetail",
		getLogisticsStatusList: "Logistics/getStatusList",
		cancelShipment: "Logistics/cancelShipment",
		mapRequestToCSR: "Logistics/mapRequestToCSR",
		setProductDetailsForRegisteredProducts: 'Consumer/setProductDetailsForRegisteredProducts',
		getReturnReasons: 'ConsumerServicerequest/getReturnReasons',
		createReplacementRequest: 'ConsumerServicerequest/createReplacementRequest',
		checkForHighRiskRequest:'ConsumerServicerequest/checkForHighRiskRequest',
		verifyAndUpdateSoldPlan: 'ConsumerServicerequest/verifyAndUpdateSoldPlan',
		getStates: 'ConsumerServicerequest/getStateCodes',
		getDistricts: 'ConsumerServicerequest/getDistricts',
		createRequest : 'ConsumerServicerequest/createRequest',
		getValueOfDecisionMatrix: 'ConsumerServicerequest/getValueOfDecisionMatrix',
		getAdvanceDetails: 'ConsumerServiceRequest/getAdvanceDetails',
		getPayoutModes: 'ConsumerServicerequest/getPayoutModes',
		raiseNewClaim: 'ConsumerServicerequest/raiseNewClaim',
		scheduleRequest: 'Consumer/scheduleRequest',
		checkAndRejectClaim: 'Consumer/checkAndRejectClaim',
		PickupdropRequestWithLogistics: 'Consumer/PickupdropRequestWithLogistics',
		carryInRequest: 'Consumer/carryInRequest',
		existsOrCreate: 'Consumer/existsOrCreate',
		consumerCreate: 'Consumer/consumerCreate',
		consumerUpdate: 'Consumer/consumerUpdate',
		consumerFetch: 'Consumer/consumerFetch',
		fetchConsumerProductDetails: 'Consumer/fetchConsumerProductDetails',
		addConsumerProduct: 'Consumer/addConsumerProduct',
		getComplianceWarning: 'Consumer/getComplianceWarning',
		updateNameEmail: 'Consumer/updateNameEmail',
		fetchAllBrandDetails: 'Consumer/fetchAllBrandDetails',
		verifyAndCreateProduct: 'Consumer/verifyAndCreateProduct',
		addConsumerProductInServiceFlow: 'Consumer/addConsumerProductInServiceFlow',
		addConsumerLocation: 'Consumer/addLocation',
		addLocation: 'ConsumerServicerequest/addLocation',
		updateLocation: 'ConsumerServicerequest/updateLocation',
		getConsumerProductDetails: 'ConsumerServicerequest/getConsumerProductDetails',
		createConsumerProductAttributes: 'ConsumerServicerequest/createConsumerProductAttributes',
		checkClaimEligibility: 'ConsumerServicerequest/checkClaimEligibility',
		getUserLocationsForProduct: 'Consumer/getUserLocationsForProduct',
		fetchCustomerPlans: 'ConsumerServicerequest/fetchCustomerPlans',
		getServiceAvailabilityWithLocations: 'ConsumerServicerequest/getServiceAvailabilityWithLocations',
		getIssuesList: 'Consumer/getIssuesList',
		getCityStateByPincode: 'Logistics/getCityStateByPincode',
		checkServiceAvailability: 'Logistics/checkServiceAvailability',
		createShipmentRequestForm: 'Logistics/createShipmentRequestForm',
		sendDocumentsEmail: 'Logistics/sendDocumentsEmail',
		getEmailCommunicationLogs: 'Communications/getEmailCommunicationLogs',
		getNotificationInfo: 'RetailerNotification/getNotificationInfo',
		getAllUsersToSendNotif: 'RetailerNotification/getAllUsersToSendNotif',
		getAllShopsAndDistinctStates: 'RetailerNotification/getAllShopsAndDistinctStates',
		getBranchlist: 'RetailTarget/getBranchlist',
		saveNotificationData: 'RetailerNotification/saveNotificationData',
		sendNotification: 'RetailerNotification/sendNotification',
		sendOfflinePlanNotification: 'Aegis/sendNotification',
		getAttendanceReport: 'RetailerAttendence/getAttendanceReport',
		getBrandConfig: 'Logistics/getBrandConfig',
		getProductConfig: 'Logistics/getProductConfig',
		getConsumerProductsByConsumerID:'Consumer/getConsumerProductsByConsumerID',
		getRequestCategories : 'ConsumerServicerequest/getRequestCategories',
		getProductDetailsForRegisteredProducts: 'Consumer/getProductDetailsForRegisteredProducts',
		createProduct: 'Consumer/createProduct',
		getConsumerServiceRequestsByConsumerID: 'Consumer/getConsumerServiceRequestsByConsumerID',
		getProductDetailsForUnregisteredProducts: 'Consumer/getProductDetailsForUnregisteredProducts',
		setProductDetailsForUnregisteredProducts: 'Consumer/setProductDetailsForUnregisteredProducts',
		getReportType: 'Reports/reportTypeView',
		getAllProductsForReports: 'Reports/getAllProducts',
		getServiceTypes: 'Reports/getServiceTypes',
		getPartnerServiceLocationIDs: 'PartnerServicelocation/fetchPartnerServiceLocations',
		serviceCenterList: 'PartnerServicelocation/getPartnerServiceLocations',
		getPartnerDetails: 'Partner/getPartnerDetails',
		fetchServiceLocations: 'ServiceLocationBrand/fetchServiceLocations',
		fetchLocations: 'ServiceLocationBrand/fetchLocations',
		getPlanIDs: 'Aegis/getPlanList',
		getBrandIDs: 'Consumer/getBrand',
		getEngineerIDs: 'ServicelocationEngineer/getEngineerIDsByServiceLocationIDs',
		getProductCategory: 'ProductCategory/getProductCategory',
		getSubscriptionIDs: 'Subscription/getSubscriptionIDs',
		getOrganizationIDs: 'Organization/getOrganizationIDs',
		getOlcsmNames: 'ServicelocationEngineer/getOlcsmList',
		downloadReport: 'Reports/downloadReports',
		fetchReports: 'Reports/fetchReports',
		getSignedUrlFromID: 'Reports/getSignedUrlFromID',
		fetchReportWithTaskID: 'Reports/fetchReportWithTaskID',
		countUnregisteredProducts: 'Consumer/countUnregisteredProducts',
		uploadDocuments: 'Consumer/uploadDocuments',
		getApplicationLegalConfigs: 'Consumer/getApplicationLegalConfigs',
		uploadProductData: 'ConsumerServicerequest/uploadProductData',
		convertUnregisteredProducts: 'Consumer/convertUnregisteredProducts',
        checkExistingLogisticsRequest: 'Logistics/checkExistingLogisticsRequest',
        cancelDuplicateLogisticsRequest: 'Logistics/cancelDuplicateLogisticsRequest',
        getCountries: 'Consumer/getCountries',
        branchMasterGetFilter: 'BranchMaster/getFilter',
        getCompetitionSalesReport: 'RetailTarget/getCompetitionSalesReport',
        getProductOrBrandDetails: 'RetailTarget/getProductOrBrandDetails',
        resetIMEI: 'RetailUsers/resetIMEI',
        getPartnerRetaillocationAdvisorDetails: 'PartnerRetaillocation/getAdvisorDetails',
        resetLocation: 'PartnerRetaillocation/resetLocation',
        getPlanSubCategories: 'Aegis/getPlanSubCategories',
        getPlanBrandsForSubcategories: 'Aegis/getPlanBrandsForSubcategories',
        getFilteredPlanProducts: 'Aegis/getFilteredPlanProducts',
        fetchPlanTemplateMaster: 'Aegis/fetchPlanTemplateMaster',
        fetchPlanTemplateByID: 'Aegis/fetchPlanTemplateByID',
        fetchFileUploadDetails: 'Aegis/fetchFileUploadDetails',
		uploadBulkSoldPlanFile: 'Aegis/uploadBulkSoldPlanFile',
		getTemplatePlanDetails: 'Aegis/getTemplatePlanDetails',
        getPartnerServiceLocations: 'PartnerServicelocation/getPartnerServiceLocations',
		existsByMobileNo: 'Consumer/existsByMobileNo',
        getConsumerProductsByBrand: 'Consumer/getConsumerProductsByBrand',
        initiateDiagnosis: 'Diagnosis/initiateDiagnosis',
        generateQrCode: 'Diagnosis/generateQrCode',
        checkDiagnosisDetail: 'Diagnosis/checkDiagnosisDetail',
        getIssueList: 'Consumer/getIssuesList',
        getModelList: 'Consumer/getModelList',
		raiseEnquiryRequest: 'Consumer/raiseEnquiryRequest',
		mailReports: 'Reports/mailReports',
		scheduleReportLogger: 'Reports/scheduleReportLogger',
        getPendingAttendanceRecords: 'RetailerAttendence/listOfPendingApprovals',
		timeOutApprovalForSalesman: 'RetailerAttendence/timeOutApprovalForSalesman',
		getTestPlan: 'Diagnosis/getTestPlan',
		claimRequest: 'ConsumerServicerequest/claimRequest',
		getAdvisorsList: 'RetailUsers/getAdvisorsList',
		getSalesReport: 'RetailTarget/getSalesReport',
		getAllTLfieldVisitReport: 'RetailerAttendence/getAllTLfieldVisitReport',
		getAdvisorDetails: 'RetailUsers/getAdvisorDetails',
		getFilteredConsumerServiceRequest: 'Consumer/getConsumerServiceRequest',
		getFilteredConsumerServiceRequestCount: 'Consumer/getConsumerServiceRequestCount',
		getCSRViewDetails: 'Consumer/getCSRViewDetails',
		getEscalations: 'ConsumerServicerequest/getEscalations',
		fetchJobsheet: 'ConsumerServicerequest/fetchJobsheet',
		createTransferLogistics: 'PartnerServicelocation/createTransferLogistics',
		getException: 'ConsumerServicerequest/getException',
		fetchConsumerByCSRID: 'ConsumerServicerequest/fetchConsumerByCSRID',
		getEscalationsByCsrID: 'ConsumerServicerequest/getEscalationsByCsrID',
		getAdvanceReceipts: 'ConsumerServicerequest/getAdvanceReceipts',
		getInvoice: 'Consumer/getInvoice',
		getCSRDetails: 'ConsumerServicerequest/getCSRDetails',
		getConsumerServiceRequestDeliveryDetails: 'ConsumerServicerequest/getConsumerServiceRequestDeliveryDetails',
		fetchInsuranceDetails: 'ConsumerServicerequest/fetchInsuranceDetails',
		addReferenceID: 'ConsumerServicerequest/addReferenceID',
		updateRequestByID: 'ConsumerServicerequest/updateRequestByID',
		submitDiagnosis: 'Diagnosis/submitDiagnosis',
		fetchProvisionalEstimateData: 'ConsumerServicerequest/fetchProvisionalEstimateData',
		fetchServiceEstimateDocument:'ConsumerServicerequest/fetchServiceEstimateDocument',
		fetchDeliveryReport: 'ConsumerServicerequest/fetchDeliveryReport',
		fetchDepotShipperLabel: 'GsxDetails/fetchDepotShipperLabel',
		getPartnerSymptomFaultAction: 'ConsumerServicerequest/getPartnerSymptomFaultAction',
		getAllServiceTypes: 'Consumer/getAllServiceTypes',
		statusList: 'Consumer/getStatusList',
		getLogisticsProviderList: 'Consumer/getLogisticsProviderList',
		getRegistrationSourceList: 'Consumer/getRegistrationSourceList',
		getCityList: 'Consumer/getCityList',
		getEscalationTypes: 'ConsumerServicerequest/getEscalationTypes',
		getEngineerListForFilter:'Consumer/getEngineerList',
		createOrUpdateEscalationServiceRequest: 'ConsumerServicerequest/createOrUpdateEscalationServiceRequest',
		getReports: 'ConsumerServicerequest/getReports',
		getSoldPlan: 'Aegis/getSoldPlan',
		getAdjudicationChecklist:'ConsumerServicerequest/getAdjudicationChecklist',
		getFilteredConsumerServiceRequestForDownload: 'Consumer/getFilteredConsumerServiceRequestForDownload',
		cancelRequestWithoutDispatch: 'ConsumerServicerequest/cancelRequestWithoutDispatch',
		servicerequestFulfilment: 'ConsumerServicerequest/servicerequestFulfilment',
		createLogisticRequest: 'ConsumerServicerequest/createLogisticRequest',
		cancelRequestWithLogistics: 'Consumer/cancelRequestWithLogistics',
		servicerequestFulfilmentWorkshop: 'ConsumerServicerequest/servicerequestFulfilmentWorkshop',
		getApplicationFeatureConfig: 'ConsumerServicerequest/getApplicationFeatureConfig',
		getConsumerDetailsForPickup: 'ConsumerServicerequest/getConsumerDetailsForPickup',
		checkPaymentLinkStatus: 'ConsumerServiceRequest/checkAdvancePaymentStatus',
		getTatIndicator: 'ConsumerServicerequest/getTatIndicator',
		createPaymentLink: 'ConsumerServiceRequest/createPaymentLink',
		rescheduleSlots: 'Consumer/rescheduleSlots',
		rescheduleRequest: 'Consumer/rescheduleRequest',
		getAllLogisticsRequestDetails: 'ConsumerServicerequest/getAllLogisticsRequestDetails',
		resetTradeIn: 'TradeIn/reset',
		getReasons: 'ConsumerServicerequest/getReasons',
		getEngineersByServiceLocations: 'Consumer/getEngineersByServiceLocations',
		createVisit: 'ServicelocationEngineer/createEngineerVisit',
		getVisitDispositions: 'ServicelocationEngineer/getVisitDispositions',
		updateJobDetails: 'ServicelocationEngineer/updateJobDetails',
		getSymptomFaultFaultActionMappings: 'ServicelocationEngineer/getSymptomFaultFaultActionMappings',
		processReplacementRequestVisit: 'ServicelocationEngineer/processReplacementRequestVisit',
		updateEngineerVisit: 'ServicelocationEngineer/updateEngineerVisit',
		addSymptomsFaultsAction: 'ServicelocationEngineer/addSymptomsFaultsAction',
		addProductDocs: 'Consumer/addProductDocs',
		updateConsumerProduct: 'ConsumerServicerequest/updateConsumerProduct',
		convertClaimToNormalRepair: 'ConsumerServicerequest/convertClaimToNormalRepair',
		checkValidDevice: 'Consumer/checkValidDevice',
		fetchLoanerDeviceList: 'GsxDetails/fetchLoanerDeviceList',
		getSalesChannel: 'ConsumerServicerequest/getSalesChannel',
		getCalculation: 'TradeIn/getCalculation',
		getDashboardDetails: 'TradeIn/getDashboardDetails',
		getReasonsforDetectedRaisedflag:'ConsumerServicerequest/getReasonsforDetectedRaisedflag',
		getReasonsforDetectedRaisedflagForCaseManager: 'ConsumerServicerequest/getReasonsforDetectedRaisedflagForCaseManager',
		upload: 'Consumer/upload',
		addNewProductDocs: 'Consumer/addNewProductDocs',
		getServiceHistoryByBrand: 'Consumer/getServiceHistoryByBrand',
		fetchBillingDetails: 'ConsumerServicerequest/fetchBillingDetails',
		updateEstimation: 'ConsumerServicerequest/updateEstimation',
		validateIssues: 'ConsumerServicerequest/validateIssues',
		deleteIssue: 'ConsumerServicerequest/deleteIssue',
		getSymptomFaultActionMappings: 'ConsumerServicerequest/getSymptomFaultActionMappings',
		addSymptoms: 'ConsumerServicerequest/addSymptoms',
		deleteSymptoms: 'ConsumerServicerequest/deleteSymptoms',
		getSymptomFaultAction: 'ConsumerServicerequest/getSymptomFaultAction',
		fetchCompTiaModifiers: 'GsxDetails/fetchCompTiaModifiers',
		fetchReportedSymptoms: 'GsxDetails/fetchReportedSymptoms',
		getSymptomFaultFaultActionMappingsGSX: 'ConsumerServicerequest/getSymptomFaultFaultActionMappings',
		updateServiceRequestAmount: 'ConsumerServicerequest/updateServiceRequestAmount',
		updateCsrDiagnosis: 'Diagnosis/updateCsrDiagnosis',
		fetchReportedIssues: 'GsxDetails/fetchReportedIssues',
		getPartsForRequestGSX: 'GsxDetails/getPartsForRequest',
		getPartsForRequest: 'ConsumerServicerequest/getPartsForRequest',
		getEngineerVisits: 'ServicelocationEngineer/getEngineerVisits',
		getSymptoms: 'ConsumerServicerequest/getSymptoms',
		addMoreIssues: 'ConsumerServicerequest/addMoreIssues',
		saveSymptomFaultAction: 'ConsumerServicerequest/saveSymptomFaultAction',
		getFaultsBySymptom: 'ConsumerServicerequest/getFaultsBySymptom',
		getSFAMappings: 'ConsumerServicerequest/getSFAMappings',
		getAvailableQuantity: 'ConsumerServicerequest/getAvailableQuantity',
		retrieveDocsByCsrID: 'ConsumerServicerequest/retrieveDocsByCsrID',
		documentTypeList: 'ConsumerServicerequest/documentTypeList',
		uploadImagesToGSX: 'GsxDetails/sendDocsToGsx',
		addDocsForDS: 'ConsumerServicerequest/addDocsForDS',
		addDocs: 'ConsumerServicerequest/addDocs',
		checkFindMyiPhoneStatus: 'TradeInRequest/checkFindMyiPhoneStatus',
		getPANList: "AdvancePay/getPANList",
		fetchPANPartners: "AdvancePay/fetchPANPartners",
		fetchWalletDetails: "AdvancePay/fetchWalletDetails",
		checkAndCreateWallet: "AdvancePay/checkAndCreateWallet",
		updateWalletMapping: "AdvancePay/updateWalletMapping",
		loadMoney: "AdvancePay/loadMoney",
		addLogOfApi: "Address/addLogOfApi",
		fetchSkuAttributes:'ConsumerServicerequest/fetchSkuAttributes',
		checkSubscriptionConfiguration: 'ConsumerServicerequest/checkSubscriptionConfiguration',
		getAEWithoutDefectiveServiceAvailability: 'ConsumerServicerequest/getAEWithoutDefectiveServiceAvailability',
		getReplacementOptions:"ConsumerServicerequest/getReplacementOptions",
		getFaultList: 'ConsumerServicerequest/getFaultList',
		getOnsiteParts: 'ServicelocationEngineer/getParts',
		createPartRequest: 'ServicelocationEngineer/createPartRequest',
		updateCSRStatus: 'ConsumerServicerequest/updateCSRStatus',
		chargesUpdate: 'ConsumerServicerequest/updateCharge',
		updateCharge: 'ConsumerServicerequest/updateCharge',
		acceptRejectRequestsEngg: 'ServicelocationEngineer/acceptRejectRequests',
		CreateTask: 'ConsumerServicerequest/createTask',
		receivePayment: 'ServicelocationEngineer/receivePayment',
		ServicelocationEngineerReached: 'ServicelocationEngineer/ServicelocationEngineerReached',
		invoiceGenerateAndsetFinalInvoiceRemark: 'ServicelocationEngineer/invoiceGenerateAndsetFinalInvoiceRemark',
		setFinalInvoiceRemark: 'ServicelocationEngineer/setFinalInvoiceRemark',
		RejectRequestEndTime: 'ServicelocationEngineer/RejectRequestEndTime',
		saveAdjudicationChecklist: 'ConsumerServicerequest/saveAdjudicationChecklist',
		getDocuments: 'ConsumerServicerequest/getDocuments',
		getEmptyBoxServiceAvailability: 'ConsumerServicerequest/getEmptyBoxServiceAvailability',
		getDocumentDetails: 'ConsumerServicerequest/getDocumentDetails',
		createRequestDocumentsForDS: 'ConsumerServicerequest/createRequestDocumentsForDS',
		updateClaimDocumentForDS: 'ConsumerServicerequest/updateClaimDocumentForDS',
		createRequestDocuments: 'ConsumerServicerequest/createRequestDocuments',
		updateClaimDocument: 'ConsumerServicerequest/updateClaimDocument',
		saveRequestMapping: 'ConsumerServicerequest/saveRequestMapping',
		getStateList: 'ConsumerServicerequest/getStateCodes',
		getOrganizationOutletLocations: 'ConsumerServicerequest/getOrganizationOutletLocations',
		getshippedToBuildtoMapping: 'ConsumerServicerequest/getshippedToBuildtoMapping',
		organizationOutletLocationCurrencyMapping: 'ConsumerServicerequest/OrganizationOutletLocationCurrencyMapping',
		updatePartStatus: 'ConsumerServicerequest/updatePartStatus',
		updateReceivedPart: 'GsxDetails/updateReceivedPart',
		getPartItemsBySerialNumber: 'ConsumerServicerequest/getPartItemsBySerialNumber',
		getAlternateParts: 'ConsumerServicerequest/getAlternateParts',
		issueAlternateParts: 'ConsumerServicerequest/issueAlternateParts',
		checkKBBSerialNumberExists: 'GsxDetails/checkKBBSerialNumberExists',
		updateTask: 'ConsumerServicerequest/updateTask',
		closeTask: 'ConsumerServicerequest/closeTask',
		getDoaReplacementStatus: 'ConsumerServicerequest/getDoaReplacementStatus',
		updateRequestAttributesByID: 'ConsumerServicerequest/updateRequestAttributesByID',
		updateDOAStatus: 'ConsumerServicerequest/updateDOAStatus',
		getReplacedDevice: 'ConsumerServicerequest/getReplacedDevice',
		updateChargeWorkshop: 'ConsumerServicerequest/updateChargeWorkshop',
		getPartItemsBySerialNumber: 'ConsumerServicerequest/getPartItemsBySerialNumber',
		updatePartStatus: 'ConsumerServicerequest/updatePartStatus',
		getReplacementRequestDetails: 'ConsumerServiceRequest/getReplacementRequestDetails',
		getRequestDetails: 'ConsumerServicerequest/getRequestDetails',
		getEmptyBoxRequestDetails: 'ConsumerServicerequest/getEmptyBoxRequestDetails',
		fetchDefectivePartner:'ConsumerServicerequest/fetchDefectivePartner',
		getReimbursements: 'ConsumerServicerequest/getReimbursements',
		getCommunicationLogs: 'Communications/getCommunicationLogs',
		getTypesOfDamages: 'Communications/getTypesOfDamages',
		retrieveAllTask: 'ConsumerServicerequest/retrieveAllTask',
		getVendorListByCountry: 'Logistics/getVendorListByCountry',
		getGSXUserDetails: 'GsxDetails/getGSXUserDetails',
		forceAuthenticate: 'GsxDetails/forceAuthenticate',
		fetchDiagnosticsData: 'GsxDetails/fetchDiagnosticsData',
		fetchDiagnosticsSuites: 'GsxDetails/fetchDiagnosticsSuites',
		initiateGSXDiagnosis: 'GsxDetails/initiateDiagnosis',
		acceptRejectRequestsWorkshop: 'ConsumerServicerequest/acceptRejectRequestsWorkshop',
		claimRequestWorkshop: 'ConsumerServicerequest/claimRequestWorkshop',
		getDocuments: 'ConsumerServicerequest/getDocuments',
		createDocuments: 'ConsumerServicerequest/createDocuments',
		capturingAdvancePayment: 'ConsumerServicerequest/capturingAdvancePayment',
		updatePartStatus: 'ConsumerServicerequest/updatePartStatus',
		getFinishedGood: 'ConsumerServicerequest/getFinishedGood',
		deleteDocs: 'ConsumerServicerequest/deleteDocs',
		getDefectiveParts: 'ConsumerServicerequest/getDefectiveParts',
		addFaultsAction: 'ConsumerServicerequest/addFaultsAction',
		getFaults: 'ConsumerServicerequest/getFaults',
		getCallStatus: 'Communications/getCallStatus',
		getStateCodes: 'Aegis/getStateCodes',
		saveRequestAttributesByID: 'ConsumerServicerequest/saveRequestAttributesByID',
		fetchLoanerDetailsByCsrId: 'GsxDetails/fetchLoanerDetailsByCsrId',
		getLoanerCharges: 'GsxDetails/getLoanerCharges',
		getDiagnosisForConsumerProduct: 'Diagnosis/getDiagnosisForConsumerProduct',
		fetchUserFeedback: 'Diagnosis/fetchUserFeedback',
		getUserList: 'user/getUserList',
		getPromoterList: 'user/getPromoterList',
		getUserTypes: 'user/getUserTypes',
		createUser: 'user/createUser',
		updateUser: 'user/updateUser',
		deactivateUser: 'user/deactivate',
		getPartnerList: 'Partner/getPartnerList',
		setManualModesForAdjudication: 'ConsumerServicerequest/setManualModesForAdjudication',
		getFraudFlag:"ConsumerServicerequest/getFraudFlag",
		listReasons:"ConsumerServicerequest/listReasons",
		createFraudFlag:"ConsumerServicerequest/createFraudFlag",
		updateFraudFlag:"ConsumerServicerequest/updateFraudFlag",
		deleteFraudFlag:"ConsumerServicerequest/deleteFraudFlag",
		filterPlansWithMultipleCriteria: 'Aegis/filterPlansWithMultipleCriteria',
		getSoldPlanCustomersData: "Aegis/getSoldPlanCustomersData",
		getPaymentDetailsAegis: "Aegis/getPaymentDetails",
		getSoldPlanDocumentsUrl: "Aegis/getSoldPlanDocumentsUrl",
		requestOtpEmail: 'email/login/requestOTP',
		verifyOtpEmail: 'email/login/verifyOTP',
		requestOtpMobile: 'mobile/login/requestOTP',
		verifyOtpMobile: 'mobile/login/verifyOTP',
		getCountryDetails: 'generics/getCountryDetails',
		generateOtpEmail: 'email/validate/generateOTP',
		validateOtpEmail: 'email/validate/validateOTP',
		generateOtpMobile: 'mobile/validate/generateOTP',
		validateOtpMobile: 'mobile/validate/validateOTP',
		addOrUpdateBillingDetails: 'ConsumerServicerequest/addOrUpdateBillingDetails',
		getDispositionStatusList: 'GsxDetails/getDispositionStatusList',
		loanerDoaFlow: 'GsxDetails/loanerDoaFlow',
		loanerDispositionReturn: 'GsxDetails/loanerDispositionReturn',
		// addOrUpdateBillingDetails: 'ConsumerServicerequest/addOrUpdateBillingDetails',
		setLoaner: 'GsxDetails/setLoaner',
		updateLoanerRequest: 'GsxDetails/updateLoanerRequest',
		getLoanerCancelStatusList: 'GsxDetails/getLoanerCancelStatusList',
		addLoanerToInventory: 'GsxDetails/addLoanerToInventory',
		cancelLoanerDevice: 'GsxDetails/cancelLoanerDevice',
		getSupportingDocuments: 'ConsumerServicerequest/getSupportingDocuments',
		ConsumerAssignServiceRequestToEngineer: 'Consumer/assignServiceRequestToEngineer',
		assignServiceRequestToServiceLocation: 'Consumer/assignServiceRequestToServiceLocation',
		getServiceLocations: 'Consumer/getServiceLocations',
		fetchCSRIntegrationLogs: "ConsumerServicerequest/fetchCSRIntegrationLogs",
		reTriggerCSRIntegrationLog:"ConsumerServicerequest/reTriggerCSRIntegrationLog",
		getRecommendedModes: 'ConsumerServicerequest/getRecommendedModes',
		regenerateLogisticsRequest: 'Logistics/regenerateLogisticsRequest',
		getPartnerServiceLocationById: 'PartnerServicelocation/getPartnerServiceLocationById',
		getDispositionStatusList: 'GsxDetails/getDispositionStatusList',
		loanerDoaFlow: 'GsxDetails/loanerDoaFlow',
		loanerDispositionReturn: 'GsxDetails/loanerDispositionReturn',
		scheduleRepair: 'ConsumerServicerequest/scheduleRepair',
		getServiceLocationsName: 'ConsumerServicerequest/getServiceLocationsName',
		replacementFulfillment: 'ConsumerServicerequest/replacementFulfillment',
		reimbursementFullfillment: 'ConsumerServicerequest/reimbursementFullfillment',
		getDisplayableDeviceOptions: 'ConsumerServicerequest/getDisplayableDeviceOptions',
		getNewDeviceConfig: 'ConsumerServicerequest/getNewDeviceConfig',
		createRefurbishment: 'ConsumerServicerequest/createRefurbishment',
		createOrder: "ConsumerServicerequest/createOrder",
		fetchDisposition:'ConsumerServicerequest/fetchDisposition',
		checkBERDeviceRequired: 'ConsumerServicerequest/checkBERDeviceRequired',
		fetchBERConfiguration: 'ConsumerServicerequest/fetchBERConfiguration',
		BERClaimRequest: 'ConsumerServicerequest/BERClaimRequest',
		getProductList: 'Consumer/getProductList',
		getIssueResolutionCategories: 'ConsumerServicerequest/getIssueResolutionCategories',
		enquiryRequest: 'ConsumerServicerequest/enquiryRequest',
		getRepairTypes: 'GsxDetails/getRepairTypes',
		getMailinCoverageOptions: 'GsxDetails/getMailinCoverageOptions',
		getPartsForMailin: 'GsxDetails/getPartsForMailin',
		getGsxRepairChecklist: 'GsxDetails/getGsxRepairChecklist',
		createGsxRepairRequest: 'GsxDetails/createGsxRepairRequest',
		updateGsxRepairType: 'GsxDetails/updateGsxRepairType',
		gsxQuestionLookupAnswers: 'GsxDetails/gsxQuestionLookupAnswers',
		issueLoanerDevice: 'GsxDetails/issueLoanerDevice',
		handoverLoanerDevice: 'GsxDetails/handoverLoanerDevice',
		setLoanerStatus: 'GsxDetails/setLoanerStatus',
		getLoanerReturnChecklist: 'GsxDetails/getLoanerReturnChecklist',
		addIUpdateLoanerChecklist: 'GsxDetails/addIUpdateLoanerChecklist',
		getLoanerPaymentModes: 'GsxDetails/getLoanerPaymentModes',
		collectLoanerPayment: 'GsxDetails/collectLoanerPayment',
		nonHardwareClosure: 'ConsumerServicerequest/nonHardwareClosure',
		fetchRepairOutcomes: 'ConsumerServicerequest/fetchRepairOutcomes',
		getSkuByProductID: 'ConsumerServicerequest/getSkuByProductID',
		updateNonSerializedDevice: 'GsxDetails/updateNonSerializedDevice',
		updateServiceAvailability:"ConsumerServicerequest/updateServiceAvailability",
		getServiceablePartnerLocations:"ConsumerServicerequest/getServiceablePartnerLocations",
		getConsumerServiceRequestByIdForWorkshop: 'Consumer/getConsumerServiceRequestByIdForWorkshop',
		processReplacementRequest: 'ConsumerServicerequest/processReplacementRequest',
		autoAssignEngineerByConsumerServiceRequestID: 'ConsumerServicerequest/autoAssignEngineerByConsumerServiceRequestID',
		sendPaymentUrl: 'ConsumerServicerequest/sendPaymentUrl',
		addReplacementProduct: 'ConsumerServicerequest/addReplacementProduct',
		fetchGsxParts: 'GsxDetails/fetchGsxParts',
		fetchGsxIssueStatusDetails: 'GsxDetails/fetchGsxIssueStatusDetails',
		rejectGsxRepair: 'GsxDetails/rejectGsxRepair',
		cancelShipmentRequest: 'ConsumerServicerequest/cancelShipment',
		checkMaskedData: 'ConsumerServicerequest/checkMaskedData',
		getDeviceCheckList: 'Consumer/getDeviceCheckList',
		updateSerializedDevice: 'GsxDetails/updateSerializedDevice',
		addUpdateRemoveChecklistForServiceRequest: 'GsxDetails/addUpdateRemoveChecklistForServiceRequest',
		getCompleteDiagnosisRequirements: 'GsxDetails/getCompleteDiagnosisRequirements',
		getAdvancePaymentModes: 'ConsumerServicerequest/getAdvancePaymentModes',
		collectAdvancePayment: 'ConsumerServicerequest/collectAdvancePayment',
		getMappedServiceLocations: 'PartnerServicelocation/getMappedServiceLocations',
		checkLogisticsServiceAvailabilty: 'ConsumerServicerequest/checkLogisticsServiceAvailabilty',
		addUpdateStatus: 'ConsumerServicerequest/addUpdateStatus',
		approveOrRejectDoa: 'ConsumerServicerequest/approveOrRejectDoa',
		getExceptionTypeReasons: 'ConsumerServicerequest/getExceptionTypeReasons',
		getPartReplacementMappings: 'ConsumerServicerequest/getPartReplacementMappings',
		getDepreciationPrice: 'ConsumerServicerequest/getDepreciationPrice',
		updateException: 'ConsumerServicerequest/updateException',
		updateFinishGoodID: 'ConsumerServicerequest/updateFinishGoodID',
		addProvisionalCharges: 'ConsumerServicerequest/addProvisionalCharges',
		removeProvisionalCharges: 'ConsumerServicerequest/removeProvisionalCharges',
		getParts: 'ConsumerServicerequest/getParts',
		getPartsForGSX: 'GsxDetails/getParts',
		getPricingOptions: 'GsxDetails/getPricingOptions',
		sendProvisionalEstimateToCustomer: 'ConsumerServicerequest/sendProvisionalEstimateToCustomer',
		getProvisionalCharges: 'ConsumerServicerequest/getProvisionalCharges',
		updateBackup: 'GsxDetails/updateBackup',
		mapGsxRepairToRequest: 'GsxDetails/mapGsxRepairToRequest',
		getCollectionServiceableLocation: 'ConsumerServicerequest/getCollectionServiceableLocation',
		postEstimationGenerationAction: 'ConsumerServicerequest/postEstimationGenerationAction',
		verifyAddress: 'ConsumerServicerequest/verifyAddress',
		getActualCostConfig: "ConsumerServicerequest/getActualCostConfig",
		getExternalChrgesByRequest: "ConsumerServicerequest/getExternalChrgesByRequest",
		getInventoryDetails: 'ConsumerServicerequest/getInventoryDetails',
		removeParts: 'GsxDetails/removeParts',
		getPaymentDetails: "ConsumerServicerequest/getPaymentDetails",
		refund: "ConsumerServicerequest/refund",
		verifyPAN: 'user/verifyPAN',
		logPANVerificationAttempt: 'user/logPANVerificationAttempt',
		logConsent: 'user/logConsent',
		verifyPAN: 'user/verifyPAN',
		logPANVerificationAttempt: 'user/logPANVerificationAttempt',
		logConsent: 'user/logConsent',
		fetchEntityDetails: 'Aegis/fetchEntityDetails',
		sendPaytmOtp: 'Aegis/sendPaytmOtp',
		verifyPaytmOtp: 'Aegis/verifyPaytmOtp',
		unlinkAccount: 'Aegis/unlinkAccount',
		getTypesOfDamagesRaiseRequest: 'Consumer/getTypesOfDamages',
		getDamageTypes: 'ConsumerServicerequest/getDamageTypes',
		getASCList: "ConsumerServicerequest/getASCList",
		getCases: 'ConsumerServicerequest/getCases',
		getCaseStatuses: "ConsumerServicerequest/getCaseStatuses",
		reassignCase: 'ConsumerServicerequest/reassignCase',
		getServiceOperatorUsers: 'ConsumerServicerequest/getServiceOperatorUsers',
		getCaseDetails: 'ConsumerServicerequest/getCaseDetails',
		updateCase: 'ConsumerServicerequest/updateCase',
		fetchDiagnosisReasons: 'Aegis/fetchDiagnosisReasons',
		getCaseChecklist: 'ConsumerServicerequest/getCaseChecklist',
		updateCaseChecklist: 'ConsumerServicerequest/updateCaseChecklist',
		getConsumerByCaseID: 'ConsumerServicerequest/getConsumerByCaseID',
        sendEligibleRenewalOffers: 'Aegis/sendEligibleRenewalOffers',
		createEligibleRenewalOffers: 'Aegis/createEligibleRenewalOffers',
		findConsumerRenewals: 'Aegis/findConsumerRenewals',
		getPlanPurchaseConfigByProductSubcategory: 'Aegis/getPlanPurchaseConfigByProductSubcategory',
		getSoldPlanDocumentsWithImages: "Aegis/getSoldPlanDocumentsWithImages",
		diagnosisRejectionManual: 'Aegis/diagnosisRejectionManual',
		fetchVerifyAndUpdateSoldPlan: 'Aegis/fetchVerifyAndUpdateSoldPlan',
		sendPlanRenewalNotification: 'Aegis/sendPlanRenewalNotification',
		getFileStatus: 'ConsumerServicerequest/getFileStatus',
		uploadFile: 'ConsumerServicerequest/uploadFile',
		getRenderableUrl:'ConsumerServicerequest/getRenderableUrl',
		getSoldPlanDocumentsWithImages: "Aegis/getSoldPlanDocumentsWithImages",
		fetchVerifyAndUpdateSoldPlan: 'Aegis/fetchVerifyAndUpdateSoldPlan',
		reTrigger : 'Communications/reTrigger',
		getFetch: "ConsumerServicerequest/getFetch",
		getSmsAndEmailLogDetails:'Communications/getSmsAndEmailLogDetails',
		getCommunicationLogsDetails: 'Communications/getCommunicationLogsDetails',
		updatePartsForGsxConsignment: 'GsxDetails/updatePartsForGsxConsignment',
		getServiceAvailabilityResolutionRate: 'ConsumerServicerequest/getServiceAvailabilityResolutionRate',
		getCases: 'ConsumerServicerequest/getCases',
		reassignCase: 'ConsumerServicerequest/reassignCase',
		getServiceOperatorUsers: 'ConsumerServicerequest/getServiceOperatorUsers',
		getRiskRules: 'ConsumerServicerequest/getRiskRules',
		getRiskRealms: 'ConsumerServicerequest/getRiskRealms',
		createRiskRule: 'ConsumerServicerequest/createRiskRule',
		deactivateRiskRule: 'ConsumerServicerequest/deactivateRiskRule',
		getCaseScores: 'ConsumerServicerequest/getCaseScores',
		updateConsumerServiceRequestStatus: 'ConsumerServicerequest/updateConsumerServiceRequestStatus',
		getCaseDetails: 'ConsumerServicerequest/getCaseDetails',
		updateCase: 'ConsumerServicerequest/updateCase',
		fetchDiagnosisReasons: 'Aegis/fetchDiagnosisReasons',
		generateEstimateForInternalJob: 'ConsumerServicerequest/generateEstimateForInternalJob',
		getChargesByCaseID: 'ConsumerServicerequest/getChargesByCaseID',
		getEstimationTabDetails:'ConsumerServicerequest/getEstimationTabDetails',
		getConsentDocument:'ConsumerServicerequest/getConsentDocument',
		fetchCSRLogAttribute: "ConsumerServicerequest/fetchCSRLogAttribute",
		storeEmptyBoxLogisticsDetails: 'ConsumerServicerequest/storeEmptyBoxLogisticsDetails',
		completeGsxService: 'GsxDetails/completeGsxService',
		markReadyForPickup: 'GsxDetails/markReadyForPickup',
		getActionCategories: 'ConsumerServicerequest/getActionCategories',
		refundRequestFulfilment: 'ConsumerServicerequest/refundRequestFulfilment',
		getExternalRequestUpdates: "ConsumerServicerequest/getExternalRequestUpdates",
		getRefurbishment: 'ConsumerServicerequest/getRefurbishment',
		updateRefurbStatus:'ConsumerServicerequest/updateRefurbStatus',
		srRequestUpdate: 'ConsumerServicerequest/srRequestUpdate',
		transferServiceLocation: 'PartnerServicelocation/transferServiceLocation',
		fetchDiscountOffers: "Aegis/fetchDiscountOffers",
		fetchEligiblePlans: 'Aegis/fetchEligiblePlans',
		getDevicePlansFromImei: 'Consumer/getDevicePlansFromImei',
		updateRefurbishmentStatus: 'ConsumerServicerequest/updateRefurbishmentStatus',
		receiveDeviceFromRC: 'GsxDetails/receiveDeviceFromRC',
		getPartItemConfig:'ConsumerServicerequest/getPartItemConfig',
		getPartCategory:"ConsumerServicerequest/getPartCategory",
		getPartItemMapping:"ConsumerServicerequest/getPartItemMapping",
		getPartItemByID:"ConsumerServicerequest/getPartItemByID",
		getCSRDocuments: 'ConsumerServicerequest/getCSRDocuments',
		getConsumerClaimHistory: 'ConsumerServicerequest/getConsumerClaimHistory',
		fetchPlanDetails: 'Aegis/fetchPlanDetails',
		getWarrantyCheckDetails: 'ConsumerServicerequest/getWarrantyCheckDetails',
		updateKgbForMailin: 'GsxDetails/updateKgbForMailin',
		addLoanerCharges: 'GsxDetails/addLoanerCharges',
		fetchLoanerInvoice: 'GsxDetails/fetchLoanerInvoice',
		activateCancelledSoldPlan: 'Aegis/activateCancelledSoldPlan',
		checkPurchaseEligibility: 'Consumer/checkPurchaseEligibility',
		getEligibiltyCheckFields: "Aegis/getEligibiltyCheckFields",
		getPlanProductSKUModelNos: "Aegis/getPlanProductSKUModelNos",
		assignDropOffRequestToServiceEngineer:'Consumer/assignDropOffRequestToServiceEngineer',
		updateRepairServiceApproach: 'GsxDetails/updateRepairServiceApproach',
		draftProvisionalRepairDetails:"GsxDetails/draftProvisionalRepairDetails",
		shipDeviceWithProvisionalRepair:'GsxDetails/shipDeviceWithProvisionalRepair',
		updateAdjustmentparts:'GsxDetails/updateAdjustmentparts',
		getCSRDispositionDetails:"ConsumerServicerequest/getCSRDispositionDetails",
		chargesMaster:'ConsumerServicerequest/chargesMaster',
		createActualCost:'ConsumerServicerequest/createActualCost',
		updateActualCost:'ConsumerServicerequest/updateActualCost',
		getPaymentHistory: 'ConsumerServicerequest/getPaymentHistory',
		getClusterData: 'ConsumerServicerequest/getClusterData',
		generateNewUserAccessToken: 'user/generateNewUserAccessToken',
		updatePartEstimationTransactionDetails: 'ConsumerServicerequest/updatePartEstimationTransactionDetails',
		getCaseLogs: 'ConsumerServicerequest/getCaseLogs',
		getDeviceIdentifierList: 'Aegis/getDeviceIdentifierList',
		requestOTP: 'Consumer/requestOTP',
		verifyOTP: 'Consumer/verifyConsentOTP',
		updateChargePostEstimate:"ConsumerServicerequest/updateChargePostEstimate",
		getPaymentActions:'ConsumerServicerequest/getPaymentActions',
		getRemarks: "ConsumerServicerequest/getRemarks",
		addRemarks: "ConsumerServicerequest/addRemarks",
		getProofsByGroup:'ConsumerServicerequest/getProofsByGroup',
		proofsGroupAdjudication: 'ConsumerServicerequest/proofsGroupAdjudication',
		getProofGroups: 'ConsumerServicerequest/getProofGroups',
		addConsumerServiceRequestRecords: 'ConsumerServicerequest/addConsumerServiceRequestRecords',
		addProofByGroup: 'ConsumerServicerequest/addProofByGroup',
		getDiagnosticsData: "ConsumerServicerequest/getDiagnosticsData",
		requestConsumerOtp :'Otp/requestConsumerOtp',
		verifyConsumerOtp :'Otp/verifyConsumerOtp',
	};

    let baseUrl = props.baseUrl;
    const ApiCall = (requestData) =>  {
        if(!apis[requestData.url]){
            if (requestData.url) {
                console.error('The requests url ' + requestData.url + ' is not available');
            } else {
                console.error('Please send request url');
            }
            return false;
        }
        if (!requestData.data && requestData.method && requestData.method == 'post') {
            console.error('No data received');
            return false;
        }
        if (!requestData.callBack) {
            console.warn('No callback function is specified.');
        }
        let apiVersion = '/api/v1/';
        let UUID = generateUUID();
        let headers = {
            'Content-Type':'application/json; charset=UTF-8',
            'UUID':UUID,
			'timezone': returnTimezone(),
			'languagecode': getLanguageCode()
        };
        if(requestData.headers) headers = { ...headers, ...requestData.headers };
        let url = apis[requestData.url];
        if(url=='userLogin') {
            url = baseUrl + '/' + url
        } else {
			url = baseUrl + apiVersion + url
        }
		if(requestData.method == "GET"){
			axios.get(url)
			.then((result)=>{
				requestData.callBack(result.data)
			}).catch((error)=>{
				console.log("error :" + error);
				if(requestData.errorCB){
					requestData.errorCB(error);
				}
			})
		}else{
			axios.post(url, requestData.data, {
				headers: headers
			})
			.then((result) => {
				requestData.callBack(result.data);
			})
			.catch((error) => {
				console.log('error: ' + error);
				if(requestData.errorCB){
					requestData.errorCB(error);
				}
			})
		}
    }
	return ApiCall;
};
export default CommonApiCall;
