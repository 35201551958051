import React, { useState } from "react";
import "./ViewDataInModal.scss";

const ViewDataInModal = (props) => {
	const [active, setActive] = useState(false);

	const toggleTip = () => {
		setActive(!active);
	};

	return (
		<div
			className="data-modal-wrapper"
			onMouseEnter={toggleTip}
			onMouseLeave={toggleTip}
		>
			<div>
				<span>{props.content}</span>
				<span className="icon">&#9432;</span>
			</div>

			{active && (
				<div className="data-modal-container">{props.children}</div>
			)}
		</div>
	);
};

export default ViewDataInModal;
