import React from "react";
import { Button } from "sfy-react";
import "./ConsentChecklist.scss"

const ConsentChecklist = (props) => {
	const {
		hideSection,
		legalConfigsList,
		setLegalConfigsList,
		setCallRequestFunction,
		type = 'raiseRequest',
	} = props;

	const isAllConsentAccepted = legalConfigsList.every((d) =>
		((d?.Selected && !d.ConsentOptional)|| (d.ConsentOptional))? true : false
	);

	return (
		<div id="ConsentSection" className="raiseReqSection ConsentSection">
			<div className="form-label"><label>Consent Confirmation</label></div>
			<div className="info-div">
				<span>Important: Please ensure that each listed consent is confirmed with the consumer. It is crucial to verify and capture the necessary consents accurately from the customer during your interaction. Your diligence in this process helps maintain compliance and trust. Thank you for your attention to detail.</span>
			</div>
			<div style={{ margin: "20px 0px", padding: "20px 0px" }}>
				<div>
					{legalConfigsList &&
						legalConfigsList.length > 0 &&
						legalConfigsList.map(
							(legalConfig, idx) => (
								<label
									key={legalConfig.ApplicationLegalConfigID}
									for={`consent-input-${idx}`}
									style={{ marginBottom: "5px" }}
								>
									<input
										type="checkbox"
										key={
											legalConfig.ApplicationLegalConfigID
										}
										name={`consent-input-${idx}`}
										id={
											legalConfig.ApplicationLegalConfigID
										}
										style={{ marginRight: "5px" }}
										checked={
											legalConfig?.Selected || false
										}
										onChange={(e) => {
											if (e.target.checked) {
												setLegalConfigsList(
													(prev) => {
														let newState = [
															...prev,
														];
														newState[
															idx
														].Selected = true;
														return newState;
													}
												);
											} else {
												setLegalConfigsList(
													(prev) => {
														let newState = [
															...prev,
														];
														newState[
															idx
														].Selected = false;
														return newState;
													}
												);
											}
											if(hideSection){
												hideSection(['ReplacementOptions', 'ConsentSection', 'SecondaryAddress', 'SlotSelection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
											}
										}}
									/>
									{legalConfig.ConsentText}
									{
										legalConfig.externalReferences && legalConfig.externalReferences.map((item)=>(<a href={item.url} target="_blank" style={{"display":"flex"}}>{item.title}</a>))
									}
								</label>
							)
						)}
				</div>

				{ <div style={{ display: "flex", justifyContent: "flex-end" }}>
					{isAllConsentAccepted && (
						<Button
							className="btn button-servify nextBtn"
							onClick={(e) => setCallRequestFunction(true)}
						>
							<div className="button-next"></div>
						</Button>
					)}
				</div>}

			</div>
		</div>
	);
};

export default ConsentChecklist;
