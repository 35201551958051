import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader,Button} from 'sfy-react';
import Swal from "sweetalert2";
import {returnLocalDateTime,displayError,encrypt, generateUUID} from '../../../../Utility/Utils';
import RegisteredProductList from './RegisteredProductList';
import RegisteredProductDetails from "./RegisteredProductDetails";
import ActionButtonList from "../../../../components/ActionButtonsList/ActionButtonList";
import AddUpdateConsumerProduct from '../AddUpdateConsumerProduct/AddUpdateConsumerProduct';
import './RegisteredProduct.scss';
import moment from 'moment';

const RegisteredProducts = (props) => {
	const {localString, apiCall, ConsumerID,  accessFn, qFilter, baseUrl, userFilterImei, userFilterImei2, useDocServiceConsumer, docServiceUrl, authorization, consumerDetails} = props;
	const minYear = 2000;
	const maxYear = moment().format('YYYY');
	const [showLoader ,setLoader] = useState(false);
	const [editMode,setEditMode] = useState(false);
	const [addMode,setAddMode] = useState(false);
	const [regProductList,setRegProductList] = useState([]);
	const [selectedID,setSelectedID] = useState();
	const [consumerProduct,setConsumerProduct] = useState();
	const [consumerProductArray,setConsumerProductArray] = useState([]);
	const [buttonArray,setButtonArray] = useState([]);
	const [documentsList, setDocumentsList] = useState([])
	const [uploadedDocDetails, setUploadedDocDetails] = useState(null)
	const [countryData, setCountryData] = useState([]);
	const [consumerProductSaveData, setConsumerProductSaveData] = useState({
		BrandID:"",
		ProductSubCategoryID:"",
		ProductID:"",
		BrandName:"",
		ProductSubCategory:"",
		ProductName:"",
		TagName:"",
		ModelNo:"",
		ProductUniqueID:"",
		AlternateUniqueKey: "",
		YearOfPurchase:"",
		WarrantyTill:"",
		DateOfPurchase:"",
		ProductCategoryID:"",
		ProductCategory:"",
		uploadDocumentsDropdown:"",
		uploadDocuments:"",
		uploadedDocumentDocID:""
	})

	const[brandAndSubCat , setBrandAndSubCat] = useState({
		brandNarwal: consumerProduct?.BrandName === "Narwal" && consumerProduct?.ProductSubcategory?.ProductSubCategory === "Robot Vacuum Cleaners",
		//  &&  countryData?.countries?.CountryCode === "US",
		brandLG: consumerProduct?.BrandName === "LG" && (consumerProduct?.ProductSubcategory?.ProductSubCategory === "Washing Machines" ||
		consumerProduct?.ProductSubcategory?.ProductSubCategory === "Televisions" ||  consumerProduct?.ProductSubcategory?.ProductSubCategory === "Air Conditioners" || consumerProduct?.ProductSubcategory?.ProductSubCategory === "Monitors" || consumerProduct?.ProductSubcategory?.ProductSubCategory === "Refrigerators")
		//  && countryData?.countries?.CountryCode === "MX",
	})

	useEffect(() => {
		// Update the state based on conditions
		setBrandAndSubCat((prev) => ({
		  	...prev,
		  	brandNarwal: consumerProduct?.BrandName === "Narwal" && consumerProduct?.ProductSubcategory?.ProductSubCategory === "Robot Vacuum Cleaners",
		  	brandLG: consumerProduct?.BrandName === "LG" && (consumerProduct?.ProductSubcategory?.ProductSubCategory === "Washing Machines" ||
		  	consumerProduct?.ProductSubcategory?.ProductSubCategory === "Televisions" ||  consumerProduct?.ProductSubcategory?.ProductSubCategory === "Air Conditioners" || consumerProduct?.ProductSubcategory?.ProductSubCategory === "Monitors" || consumerProduct?.ProductSubcategory?.ProductSubCategory === "Refrigerators")
		}));
	  }, [consumerProduct]);


	const getRegisteredProducts = () => {
		let data={
			ConsumerID:ConsumerID,
			IsRegistered:true
		};
		if (userFilterImei) {
			data.imei1 = userFilterImei;
		}
		if (userFilterImei2) {
			data.imei2 = userFilterImei2;
		}
		setLoader(true);
		apiCall({
			url:'getConsumerProductsByConsumerID',
			data: data,
			callBack:(result)=>{
				setLoader(false); 
				if(result.success){
					let regProductData = result.data;
					if (regProductData && regProductData.length > 0) {
						setSelectedID(regProductData[0].ConsumerProductID)
						let regProdData = [];
						regProductData.map((regProd)=>{
							let product = {};
							product.ConsumerProductID = regProd.ConsumerProductID;
							product.ProductName = regProd.ProductName;
							if(regProd.ProductSubCategoryID == 12){
								if(regProd.BrandID == 4){
									product.ProductDetails = [
										{
											label:"IMEI No",
											value:regProd.ProductUniqueID,
										},
										{
											label:"Serial No",
											value:regProd.AlternateUniqueKey
										}
									]
								}else{
									product.ProductDetails = [
										{
											label:"IMEI 1",
											value:regProd.ProductUniqueID
										},
										{
											label:"IMEI 2",
											value:regProd.AlternateUniqueKey
										},
										{
											label:"Serial No",
											value:regProd.DownloadedDeviceUniqueKey
										}
									]
								}
							}else{
								product.ProductDetails = [
									{
										label:"Serial No",
										value:regProd.ProductUniqueID || regProd.DownloadedDeviceUniqueKey
									}
								]
							}
							regProdData.push(product);
						});
						setRegProductList(regProdData);
					}
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const fetchProductDetails = (selectedID) => {
		let data={
			ConsumerProductID:selectedID,
			skipDiagnosisData:true
		};
		setLoader(true);
		apiCall({
			url:'getProductDetailsForRegisteredProducts',
			data: data,
			callBack:(result)=>{
				setLoader(false); 
				if(result.success){
					setConsumerProduct(result.data);
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const showProductetails = (product) => {
		setSelectedID(product.ConsumerProductID)
		setEditMode(false)
	}

	useEffect(() => {
		getRegisteredProducts();
	}, [])

	useEffect(() => {
		if (selectedID) {
			fetchProductDetails(selectedID);
		}
	}, [selectedID]);

   
	useEffect(() => {
		if (consumerDetails?.CountryID) {
			getCountries();
		}
	}, [consumerDetails]);


	const setConsumersProductData = () => {
		let defaultText = localString("Not Updated");
		let consumerProductArray = [
			{
				label:(localString("Product Category")),
				hide:!(JSON.parse(qFilter) && JSON.parse(qFilter).flowType && JSON.parse(qFilter).flowType.requestFulfilmentFlow && (JSON.parse(qFilter).flowType.requestFulfilmentFlow.indexOf('REQ_FULFIL_SKYWORTH')>-1)),
				value:(consumerProduct && consumerProduct.ProductCategory) || defaultText
			},
			{
				label:(localString("Product Subcategory")),
				value:(consumerProduct && consumerProduct.ProductSubcategory && consumerProduct.ProductSubcategory.ProductSubCategory) || defaultText
			},
			{
				label:(localString("Brand")),
				value:(consumerProduct && consumerProduct.BrandName) || defaultText
			},
			{
				label:(localString("Product")),
				value:(consumerProduct && consumerProduct.ProductName) || defaultText
			},
			{
				label:(localString("SKU")),
				hide:!(consumerProduct && consumerProduct.partDetails && consumerProduct.partDetails.Name),
				value:(consumerProduct && consumerProduct.partDetails && consumerProduct.partDetails.Name) || defaultText
			},
			{
				label:(localString("Tag Name")),
				value:(consumerProduct && consumerProduct.TagName) || defaultText
			},
			{
				label:(localString("Model No")),
				value:(consumerProduct && consumerProduct.ModelNo) || defaultText
			},
			{
				label:(localString("Created Date")),
				value:(consumerProduct && returnLocalDateTime(consumerProduct.CreatedDate)) || defaultText
			},
			{
				label: (brandAndSubCat?.brandNarwal && localString("Robot Serial Number") ||
				brandAndSubCat?.brandLG && localString("Serial Number") ||
				localString("IMEI")+'/'+ localString("Serial No.")),
				value:(consumerProduct && consumerProduct.ProductUniqueID) || defaultText
			},
			{
				label:(brandAndSubCat?.brandNarwal
				? localString("Charging Dock Serial Number")
				: localString("IMEI 2")),
				hide: brandAndSubCat?.brandNarwal ? false : !(consumerProduct && consumerProduct.AlternateUniqueKey),
				// hide:!(consumerProduct && consumerProduct.AlternateUniqueKey),
				value:(consumerProduct && consumerProduct.AlternateUniqueKey) || defaultText
			},
			{
				label:(localString("Date Of Purchase")),
				value:(consumerProduct && consumerProduct.DateOfPurchase && returnLocalDateTime(consumerProduct.DateOfPurchase,'DD-MM-YYYY HH:mm')) || defaultText
			},
			{
				label:(localString("Year Of Purchase")),
				value:(consumerProduct && consumerProduct.YearOfPurchase) || defaultText
			},
			{
				label:(localString("Warranty Till")),
				value:(consumerProduct && consumerProduct.WarrantyTill && returnLocalDateTime(consumerProduct.WarrantyTill,'DD-MM-YYYY HH:mm')) || defaultText
			},
			{
				label:(localString("Bills/Documents")),
				showLinks:true,
				hide:consumerProduct && (!consumerProduct.FilePath || (consumerProduct.FilePath && (consumerProduct.FilePath.length == 0 || consumerProduct.FilePath[0] == null || consumerProduct.FilePath[0] =="" ))),
				linkArray:consumerProduct && consumerProduct.FilePath
			},
			{
				showProductDetails:consumerProduct && consumerProduct.ProductDetails && consumerProduct.ProductDetails.length>0,
				ProductDetails:consumerProduct && consumerProduct.ProductDetails
			},
		]
		setConsumerProductArray(consumerProductArray);
		setButtonList();
	}

	useEffect(() => {
		setConsumersProductData();
	}, [consumerProduct, brandAndSubCat]);

	const countSerialNumbers = (consumerProduct) => {
		let count = 0;
		if (consumerProduct?.ProductUniqueID?.length) count++;
		if (consumerProduct?.AlternateUniqueKey?.length) count++;
		return count;
	} 

	const setButtonList = () => {
		let buttonList = [];
		if(consumerProduct && !consumerProduct.ConsumerServiceRequestID && accessFn('REQUEST_RAISE')){
			let isDisabled = undefined;
			if (consumerProduct.BrandName === "Narwal" && countSerialNumbers(consumerProduct) < 2) {
				isDisabled = true;
			}
			buttonList = [...buttonList,{
				text:localString("Raise A Request"),
				onClickAction:()=>{
					raiseRequest();
				},
				isDisabled: isDisabled,
			}]
		}
		if(consumerProduct && consumerProduct.ConsumerServiceRequestID && accessFn('REQUEST_RAISE')){
			buttonList = [...buttonList,{
				text:localString("View Request"),
				onClickAction:()=>{
					viewServiceRequest();
				}
			}]
		}
		if(consumerProduct && !consumerProduct.ConsumerServiceRequestID && accessFn('REQUEST_CREATE_SERVICE_REQUESTRAISE')){
			buttonList = [...buttonList,{
				text:localString("Create Request"),
				onClickAction:()=>{
					creatServiceRequest();
				}
			}]
		}
		if((consumerProduct && !consumerProduct.ConsumerServiceRequestID && !(consumerProduct.ProductDetails && consumerProduct.ProductDetails[0] && consumerProduct.ProductDetails[0].PlanID) && accessFn('EDIT_CONSUMER_DETAILS')) || (consumerProduct && !consumerProduct.ConsumerServiceRequestID && consumerProduct.ProductDetails && consumerProduct.ProductDetails[0] && consumerProduct.ProductDetails[0].PlanID && accessFn('EDIT_CONSUMER_DETAILS') && consumerProduct.BrandName === "Narwal" )){
			buttonList = [...buttonList,{
				text:localString("Edit"),
				onClickAction:()=>{
					editProduct();
				}
			}]
		}
		setButtonArray(buttonList);
	}

	const raiseRequest = () => {
		let url = baseUrl + '/servicerequests/raise' + '?consumer=' + encrypt(ConsumerID) + '&product=' + encrypt(consumerProduct.ProductID) + '&cp=' + encrypt(consumerProduct.ConsumerProductID);
		window.open(url,'_blank');
	}

	const viewServiceRequest = () => {
		let url = baseUrl + '/servicerequests/view' + '?csrid=' + consumerProduct.ConsumerServiceRequestID;
		window.open(url,'_blank');
	}

	const  creatServiceRequest = () =>{
		let url = baseUrl + '/servicerequests/requestform' + '?cid=' + ConsumerID + '&cpid=' + selectedID;
		window.open(url,'_blank');
	}

	const validation = () => {
		let isValidate = true;
		if (isValidate) {
			isValidate = false;
			if(consumerProductSaveData.ProductID === "" || consumerProductSaveData.ProductID === null){
				Swal.fire({
					icon:"error",
					text:localString('Please select a Product from the dropdown')
				})
				return;
			}
			if (consumerProductSaveData.ProductSubCategoryID == '' || consumerProductSaveData.ProductSubCategoryID == null) {
				Swal.fire({  
					icon: 'error',  
					text: localString('Please select a Subcategory from the dropdown'),  
				});
				return;
			}
			if (consumerProductSaveData.BrandID == '' || consumerProductSaveData.BrandID == null) {
				Swal.fire({  
					icon: 'error',  
					text: localString('Please select a Brand from the dropdown'),  
				});
				return;
			}
			if(addMode || editMode){
				if (!(consumerProductSaveData.YearOfPurchase && minYear <= parseInt(consumerProductSaveData.YearOfPurchase) && parseInt(consumerProductSaveData.YearOfPurchase) <= maxYear)) {
					Swal.fire({  
						icon: 'error',  
						text: localString("Select a year between")+' ' + minYear + ' '+localString("and")+' ' + maxYear,
					});
					return;
				}
			}
			isValidate = true;
		}
		return isValidate;
	}

	const editProduct = () => {
		setEditMode(true);
		setAddMode(false);
		setConsumerProductSaveData({...consumerProductSaveData,
			BrandID:consumerProduct && consumerProduct.BrandID,
			BrandName:consumerProduct && consumerProduct.BrandName,
			ProductSubCategoryID:consumerProduct && consumerProduct.ProductSubCategoryID,
			ProductSubCategory:consumerProduct && consumerProduct.ProductSubcategory && consumerProduct.ProductSubcategory.ProductSubCategory,
			ProductID:consumerProduct && consumerProduct.ProductID,
			ProductName:consumerProduct && consumerProduct.ProductName,
			TagName:consumerProduct && consumerProduct.TagName,
			ModelNo:consumerProduct && consumerProduct.ModelNo,
			ProductUniqueID:consumerProduct && consumerProduct.ProductUniqueID,
			AlternateUniqueKey: consumerProduct && consumerProduct.AlternateUniqueKey,
			YearOfPurchase:consumerProduct && consumerProduct.YearOfPurchase,
			DateOfPurchase:consumerProduct && consumerProduct.DateOfPurchase,
			ProductCategoryID:consumerProduct && consumerProduct.ProductCategoryID,
			ProductCategory:consumerProduct && consumerProduct.ProductCategory,
			//uploadDocuments:consumerProduct && consumerProduct.WarrantyTill
		})
		if(returnLocalDateTime(consumerProduct && consumerProduct.WarrantyTill,'YYYY-MM-DD') != 'Invalid date'){
			setConsumerProductSaveData((prevState) => {
				return { ...prevState, WarrantyTill:consumerProduct && consumerProduct.WarrantyTill };
			});
		}
		getDocuments();
	}

	const addProduct = (e) => {
		e.preventDefault();
		let isValidate = validation();
		if(isValidate){
			let data = consumerProductSaveData;
			data.ConsumerID = ConsumerID;
			setLoader(true);
			apiCall({
				url:'createProduct',
				data: data,
				callBack:(result)=>{
					setLoader(false); 
					if(result.success){
						cancelProduct();
						setSelectedID(result.data.ConsumerProductID);
						getRegisteredProducts();
						Swal.fire({  
							icon: 'success',  
							text: localString("The product was added"),  
						}); 
					}else{
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(result)),  
						}); 
					}
				}	
			});
		}
	}

	const saveProduct =()=>{
		let isValidate = validation();
		if(isValidate){
			let data = consumerProductSaveData;
			data.ConsumerProductID = selectedID;
			setLoader(true);
			apiCall({
				url:'setProductDetailsForRegisteredProducts',
				data: data,
				callBack:(result)=>{
					setLoader(false); 
					if(result.success){
						fetchProductDetails(result.data.ConsumerProductID)
						getRegisteredProducts();
						cancelProduct();
						Swal.fire({  
							icon: 'success',  
							text: localString("The product Updated"),  
						}); 
					}else{
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(result)),  
						}); 
					}
				}	
			});
		}
	}

	const addProductClick = () =>{
		setAddMode(true);
		setEditMode(false);
		setConsumerProductSaveData({
			BrandID:"",
			ProductSubCategoryID:"",
			ProductID:"",
			BrandName:"",
			ProductSubCategory:"",
			ProductName:"",
			TagName:"",
			ModelNo:"",
			ProductUniqueID:"",
			AlternateUniqueKey: "",
			YearOfPurchase:"",
			WarrantyTill:"",
			DateOfPurchase:"",
			ProductCategoryID:"",
			ProductCategory:"",
			uploadDocumentsDropdown:"",
			uploadDocuments:""
		});
	}

	const cancelProduct = () =>{
		setEditMode(false);
		setAddMode(false);
	}

	const getDocuments = () => {
		setLoader(true);
		apiCall({
			url:'getDocuments',
			method:"GET",
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let documentsMasterList = result.data && result.data.map(v=> ({...v,value:v.DocumentType}))
					setDocumentsList(documentsMasterList)
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const setUploadedDocument = () =>{
        let docTag = consumerProductSaveData.uploadDocumentsDropdown;
        if (!docTag || docTag == '') {
            Swal.fire({
                title:"",
                icon:"error",
                text:localString('Please select a document type')
            })
            return;
        }

        if (uploadedDocDetails.length > 0) {
            let fileType = uploadedDocDetails[0].type;
            let ext = fileType.split("/").length ? "." + fileType.split("/")[fileType.split("/").length - 1] : "";

            if (useDocServiceConsumer) {
                if (docServiceUrl == '') {
                    return;
                }
				let key = "FEWEBA" + generateUUID() + ext;
                let dataNew = new FormData();
                dataNew.append('file', uploadedDocDetails[0]); 
                dataNew.append('docType', 'ConsumerProductDocument');
                dataNew.append('clientRefId', key);
                dataNew.append('entity-type', 'ConsumerID');
                dataNew.append('entity-id', ConsumerID);
                
                let url = docServiceUrl + 'internal/document/upload';
				setLoader(true);
				fetch(url, {
					method: 'POST',
					headers: {
						'App': 'WebApp',
						'module': 'WebApp',
						'authorization': authorization
					},
					body: dataNew
				})
				.then(res => res.json())
				.then(result => {
					setLoader(false);
					if(result.success && result.data) {
						let uploadURL = result.data.uploadURL;
						let fileName = result.data.docServiceUUID;

						setLoader(true);
						apiCall({
							url:'addNewProductDocs',
							isFormData: true,
							data: {
								updatedData: [{
									ConsumerProductID: selectedID,
									DisplayName: consumerProductSaveData.uploadDocumentsDropdown,
									Type: fileType,
									FileName: fileName,
									FilePath: uploadURL,
								}]
							},
							callBack:(result)=>{
								setLoader(false);
								if(result.success){
									setUploadedDocDetails(null);
									Swal.fire({
										icon: "success",
										title: localString(result.msg)
									})
									saveProduct();
								}else{
									Swal.fire({
										icon: 'error',
										text: localString(displayError(result)),
									});
								}
							}
						});
					}else {
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(result)),  
						}); 
					}
				})
				.catch(err => {
					setLoader(false);
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(err)),  
					});
				})
            }
            else {
				let key = "FEWEBA" + generateUUID() + ext;
				let data = {
					Type: fileType, 
					Key: key, 
				}
				setLoader(true);
				apiCall({
					url:'uploadDocuments',
					data: data,
					callBack:(result)=>{
						setLoader(false);
						if (result.success) {
							var xhr = new XMLHttpRequest();
							xhr.open("PUT", result.data);
							xhr.setRequestHeader("Cache-Control", "public,max-age=3600");
							xhr.setRequestHeader('x-amz-acl', 'public-read');
							xhr.onload = function() {
								if (xhr.status === 200) {
									setLoader(true);
									apiCall({
										url:'addProductDocs',
										data: [{
											ConsumerProductID: selectedID, 
											FilePath: key, 
											Type: fileType, 
											DisplayName: consumerProductSaveData.uploadDocumentsDropdown
										}],
										callBack:(result)=>{
											if (result.success) {
												setUploadedDocDetails(null);
												Swal.fire({
													icon: "success",
													title: localString(result.msg)
												})
												saveProduct();
											}else{
												Swal.fire({
													icon: 'error',
													text: localString(displayError(result)),
												});
											}
										}
									})
								}
							}.bind(this);
							xhr.onerror = function() {
							}.bind(this);
							xhr.send(uploadedDocDetails);
						}else{
							Swal.fire({
								icon: 'error',
								text: localString(displayError(result)),
							});
						}
					}
				});
            }
        }else {
            Swal.fire({
                icon:"error",
                title:"",
                text:localString('Please select a document')
            })
            return;
        }
    }

	const saveButtonClick = (e) => {
		e.preventDefault();
		if(uploadedDocDetails && uploadedDocDetails.length){
			setUploadedDocument();
		}else{
			saveProduct();
		}
	}

	const getCountries = () => {
		setLoader(true);
		let data = {
			CountryID: consumerDetails && consumerDetails?.CountryID
		};
		apiCall({
			url: "getCountries",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					let countryData = result.data.countries[0];
					setCountryData(countryData);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};



	return (
		<>
			{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="col-sm-12 text-center clearfix reg-sec">
				<div className="col-sm-3 text-center clearfix prod-list">
					{accessFn('ADD_PRODUCT_IN_CONSUMER_PAGE') && <Button class="btn button-servify" onClick={addProductClick}> + {localString("Add Product")} </Button>}
					{regProductList && regProductList.length ? <RegisteredProductList
						regProductList={regProductList}
						selectedID={selectedID}
						showProductetails={showProductetails}
						{...props}
					></RegisteredProductList>:""
					}		
				</div>
				<div className="col-sm-9 product-details">
					{(!editMode && !addMode ) && 
					<>
						{regProductList && regProductList.length ?	<div>
								<div>
									<RegisteredProductDetails
									{...props}
									consumerProductArray={consumerProductArray}
								></RegisteredProductDetails>
								</div>
								<ActionButtonList buttonArray={buttonArray}></ActionButtonList>
							</div>:<div className="product-list-empty-state"><p>{localString("No registered products found")}</p></div>
						}
					</>
					}
					{
						(editMode || addMode ) && <AddUpdateConsumerProduct brandAndSubCat={brandAndSubCat} cancelProduct={cancelProduct} saveFunction={editMode ? saveButtonClick : addProduct } consumerProductSaveData={consumerProductSaveData} setConsumerProductSaveData={setConsumerProductSaveData} editMode={editMode} addMode={addMode} {...props} ConsumerID={ConsumerID} CPID={selectedID} documentsList={documentsList} setUploadedDocDetails={setUploadedDocDetails} consumerProduct = {consumerProduct}></AddUpdateConsumerProduct>
					}
				</div>
				
			</div>
		</>
	)
}

export default RegisteredProducts;
