import React, { useState } from 'react';
import {Button, Checkbox, Input, Modal} from 'sfy-react';

import './RepairNotesModal.scss'

const RepairNotesModal = (props) => {
    const {
        localString, showModal, closeModal, submit = () => {}, repairNotes, setRepairNotes, consumerServiceRequest,
        isSameNotes, setIsSameNotes
    } = props;
    const maxAllowedLength = 1000;

    return ( <>
        <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal repairNotesModal'>
            <Modal.Header>
                <h2> {localString("Repair Notes")} </h2>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='checkbox'>
                        <Checkbox
                            value={isSameNotes}
                            name='userType'
                            id={'isSameNotes'}
                            handleOnChange={(e) => {
                                if (e.target.checked) {
                                    setIsSameNotes(true);
                                    if(consumerServiceRequest?.gsxRepairRequest?.notes) {
                                        setRepairNotes(consumerServiceRequest?.gsxRepairRequest?.notes);
                                    }
                                }else {
                                    setIsSameNotes(false);
                                    setRepairNotes('');
                                }
                            }}
                        >
                           {localString('Same as Engineer Diagnosis Notes')}
                        </Checkbox>
                    </div>
                    <div className='col-xs-12'>
                        <label>{localString('Repair Notes (for Delivery Report)')}</label>
                        <Input
                            value={repairNotes}
                            handleOnChange={(e) => {
                                let val = (e.target.value).replace(/\s{2,}/gm, ' ');
                                setRepairNotes(val);
                            }}
                            inputComponent='textarea'
                            className={{
                                containerClass: 'input-container',
                                inputClass: 'input',
                                inputContainer: 'heightAuto'
                            }}
                            otherProps={{
                                rows: 3
                            }}
                            maxLength={maxAllowedLength}
                            isDisabled={isSameNotes}
                        />
                        <p>{`${repairNotes.length}/${maxAllowedLength} ${localString('characters entered.')}`}</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    className="btn button-servify"
                    onClick={() => submit()}
                    // isDisabled={!remarks.trim()}
                >
                    {localString("Save")}
                </Button>
            </Modal.Footer>
        </Modal>
    </> )
}
export default RepairNotesModal;