import React from 'react';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import './LogisticsDetails.scss'

const LogisticsDetails = (props) => {
	const {localString, logisticsRequestDetails} = props;

	return (
		<>
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper bodyClassName="panel-body-no-padding" title={localString("Logistics Details")}>
					{
						logisticsRequestDetails && logisticsRequestDetails.map((request,indx)=>{
							return(
								request.logisticsImage && request.logisticsImage.length > 0 &&
								<div className="request-panel request-complaint-div" style={{padding: "10px 0px", overflowY:"auto"}}>
									<div className="col-sm-12">
										<p>{localString(request.LogisticsDisplayText)}</p>
										{
											request.logisticsImage && request.logisticsImage.map((img,i)=>{
												return(
													<div  className="col-sm-2 logistics-details-panel" >
														<div className="col-sm-12">
															<a href={img.Url} target="_blank" className="logistics-document">
																<div class="documents-box">
																	<div>
																		<i class="glyphicon glyphicon-file"></i>
																		<p>{localString("Doc")+ " " + (i+1)}</p>
																	</div>
																</div>
															</a>
														</div>
														<div className="col-sm-12 text-center">
															<label className="padding-innerT">{localString(img.DisplayImageText)}</label>
														</div>
													</div>
												)
											})
										}
									</div>
								</div>
							)
						})
					}
					</PageContentWrapper>
				</div>
			</div>
		</>
	)
}

export default LogisticsDetails
