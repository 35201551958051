import React, { useEffect, useState } from "react";
import { Pagination, Button, Input, Modal, Tooltip } from 'sfy-react';
import Swal from "sweetalert2";
import CommonTable from "../../components/Table/Table";
import { returnLocalDateTime, displayError, downloadCSV, generateUUID, validateAttachments } from "../../Utility/Utils";
import UploadFileModal from './UploadFileModal';

const RequestBulkUploadGrid = (props) => {
	const { localString, pagination, setPagination, setLoader, apiCall,  publicUrl ,bulkUploadList,searchText,setSearchText,selectedValue, setSelectedValue,getFileStatus,clearSearch
	,authorization,docServiceUrl,templateId,importType ,setFirstLoad,activeTab} = props;//uploadFile
	const qFilter = JSON.parse(props.qFilter)
	const [showModal, setShowModal] = useState(false);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [file, setFile] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [confirmUploafile,setConfirmUploafile] = useState(false);
	const validExtensions = [];
	const maxFileSizeLimit = '10000000';
	const openUploadModal = () => { 
		setShowUploadModal(true);
	};
	
	const closeUploadModal = () => {
		setShowUploadModal(false);
	};

	const handleFileUpload = () => {
		if(validateAttachments(validExtensions, maxFileSizeLimit, file)) {
			let ext = file.name.split(".").length ? "."+file.name.split(".")[file.name.split(".").length - 1] : "";
			let key = "FEWEBA" + generateUUID() + ext;
			let reqObj = {
				Type: file.type, 
				Key: key
			};
			if (docServiceUrl == '') {
				return;
			}
			let dataNew = new FormData();
			dataNew.append('file', file);
			dataNew.append('docType', 'SRBulkUploadDocument');
			dataNew.append('clientRefId', key);
			dataNew.append('entity-type', 'userID');//bulk
			dataNew.append('entity-id', qFilter.userID);
			dataNew.append('clientName', 'BatchProcess');
			dataNew.append('clientType', activeTab);//actual_cost //refurb_order
			let url = docServiceUrl + 'internal/document/upload';
			setLoader(true);
			fetch(url, {
				method: 'POST',
				headers: {
					'App': 'WebApp',
					'module': 'WebApp',
					'authorization': authorization
				},
				body: dataNew
			})
			.then(res => res.json())
			.then(result => {
				setLoader(false);
				if(result.success && result.data) {
					let reqObj = {
						DocServiceUUID: result.data.docServiceUUID,
						UserID: qFilter.userID,
						FileName: file.name,
						Source: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp",
						// TemplateCode: template.TemplateCode,
						CountryCode: qFilter?.countrycode?.length ? qFilter.countrycode[0] : 'IN',
					}
					uploadFileData(reqObj);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			})
			.catch(err => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			})
		}else {
			Swal.fire({  
				icon: 'error',  
				text: localString('File size should be less than 10 MB'),  
			});
		}
		
		console.log('Uploading file:', file);
	};

	const uploadFileData = (reqData) => {
		setLoader(true);
		let data = {
            "docID": `docID=${reqData.DocServiceUUID}`,
            "batchTypeID": importType ?  importType :1 ,
            "userID": reqData.UserID
        }
        apiCall({
            url: 'uploadFile',
            data: data,
            callBack: (result) => {
                setLoader(false);
                if(result.success) {
					setFile(null);
					setConfirmUploafile(false)
					getFileStatus(null,importType)
                }else {
					setConfirmUploafile(false)
					getFileStatus(null,importType)
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(result)),  
                    }); 
                }
            },
            errorCB: (err) => {
				setConfirmUploafile(false)
				setLoader(false)
			}
        });
    }

	const getRenderableUrl = (docId) => {
		setLoader(true)
		if (docServiceUrl == '') {
			return;
		}
		let documentId = docId && docId.slice(6)
		let data = {docserviceID:[documentId]}
		let url = docServiceUrl + 'document/getRenderableUrl';
		fetch(url, {
			method: 'POST',
			headers: {
				'App': 'WebApp',
				module:'WebApp',
				"Content-Type":'application/json',
				'user-authorization': authorization
			},
			body: JSON.stringify(data)
		})
		.then(res => res.json())
		.then(result => {
			setLoader(false);
			if(result.success && result.data) {
				const url = result.data[0].url;
				const link = document.createElement('a');
				link.href = url;
				link.download = result.data[0].fileName;
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
				Swal.fire({
					text:'File downloaded successfully',
					icon:'success',
				})
			}else {
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(result)),  
				}); 
			}
		})
		.catch(err => {
			setLoader(false);
			Swal.fire({  
				icon: 'error',  
				text: localString(displayError(err)),  
			});
		})
	}

	const displayCount = (success, total) => {
		return ((success ? success : 0) + '/' + (total ? total : 0));
	}

	const statusBorderColor = {
		'In-Progress': '#91D5FF', 
		'Success': '#B7EB8F', 
		'Failed':'#FFA39E',
		'Error':'#FFA39E'
	};

	const statusColor={
		'In-Progress':'#096DD9',
		'Success':'#389E0D',
		'Failed':'#CF1322',
		'Error':'#CF1322'
	}

	const statusBackgroundColor = {
		'In-Progress': '#E6F7FF', 
		'Success': '#F6FFED', 
		'Failed':'#FFF1F0',
		'Error':'#FFF1F0'
	};

	const columns = [
		{
			title: localString("Batch ID"),
			showHeading: true,
			customCell: (row) => row.batchID || '--',
		},
		{
			title: localString("File Name"),
			showHeading: true,
			customCell: (row) => row.fileName || '--',
		},
		{
			title: localString("Upload Date, Time"),
			showHeading: true,
			customCell: (row) => returnLocalDateTime(row.createdDate) || '--',
		},
		{
			title: localString("No. of records"),
			showHeading: true,
			customCell: (row) => row.totalCount || '--',
		},
		{
			title: localString("Status"),
			showHeading: true,
			customCell: (row) => (
				
				<div className="statusPill" style={{ background: statusBackgroundColor[row.bucket=='Success' && row.failureCount > 0 ? "Error":row.bucket], borderColor : statusBorderColor[row.bucket=='Success' && row.failureCount > 0 ? "Error":row.bucket || 'Error'],color:statusColor[row.bucket=='Success' && row.failureCount > 0 ? "Error":row.bucket],borderRadius:'2'  }}>
					{row.bucket=='In-Progress' && 
					<img src={publicUrl + "/imgs/inProgress.png"}
							className='status-img' 
							alt="inProgress" 
							width='11.25px' 
							height='11.25px'> 
					</img>}
					{row.bucket=='Success' && row.failureCount > 0  && <span className='status-img' >{row.failureCount}</span> }
					{row.bucket=='Success' && row.failureCount > 0  ? 'Error':row.bucket }
				</div>
			),
		},
		{
			title: localString("Action"),
			showHeading: true,
			customCell: (row) => {
				return (
				<>
					{ (row.outputDocID) ? <div className="btn btn-download">
							<div className="gridDownloadBtn download-image" onClick={() => getRenderableUrl(row.outputDocID)} ></div>
							{/* onClick={() => uploadFile(row.OutputDocID)} */}
							</div> : <div className="btn btn-download-disabled">
								<div className="gridDownloadBtn download-image"></div>
						</div>}	
				</>
				);
			}
		},
	]
	
	// Function to handle search input change
	const handleSearchInputChange = (event) => {
		setSearchText(event.target.value);
		setFirstLoad(true)
		if(event.target.value.length>2){
			setPagination({
				...pagination,
				pageNo : 1,
			})
		} 
	};

	const downloadAllTemplates = (templateArr) => {
		if(templateArr.length) {
			templateArr.map(template => {
				window.open(template.TemplateLink, '_blank')
			})
		}
	}

	const handleDropdownItemClick = (value)=>{
		setSelectedValue(value)
		setFirstLoad(true)
		setPagination({
			...pagination,
			pageNo : 1,
		})
	}

	return (
		<div className="bulkUploadGrid">
			<div className="card-body">
				<div className="d-flex">
					<div className="search-box">
						<input
							value={searchText}
							onChange={(e)=>handleSearchInputChange(e)}
							placeholder={localString("Search")}
							className='input'
							type="text"
						/>
						<div className='align-right search-box-icon'></div>
							{<div className='search-clear-icon' onClick={()=>{
								clearSearch()
							}}>x</div>}
						</div>
					{/* searchText != '' &&  */}
						<div className="pl16">
							<div className="dropdown">
								<button
									className="dropdown-status"
									type="button"
									data-toggle="dropdown"
									aria-expanded="false"
								>
								<img
									className='sortImg' 
									src={publicUrl + "/imgs/sorting.png"} 
									alt="sort" 
									width='16px' 
									height='16px'
								/>
									{localString("Sort by ")}{" "}
									{selectedValue === "sortlatest"
									? "Latest"
									: selectedValue === "recordshigh"
									? "Records- High to Low"
									: selectedValue === "recordslow"
									? "Records- Low to High"
									: selectedValue}
									
								</button>
								<ul className="dropdown-menu dropdown-status-menu">
									<li
										className={`dropdown-item ${selectedValue === "sortlatest" ? "active button-not-click" : ""}`}
										onClick={() => 
											handleDropdownItemClick('sortlatest')
										}
									>
										Latest
									</li>
									<li
										className={`dropdown-item ${selectedValue === "recordshigh" ? "active button-not-click" : ""}`}
										onClick={(e) => {
											handleDropdownItemClick("recordshigh")
										}
										}
									>
										Records- High to Low
									</li>
									<li
										className={`dropdown-item ${selectedValue === "recordslow" ? "active button-not-click" : ""}`}
										onClick={(e) => {
											handleDropdownItemClick("recordslow")
											}
										}
									>
										Records- Low to High
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div>
					<Button
						type='button'
						className='downloadBtn pr20'
						onClick={
							()=>getRenderableUrl(templateId)
						}
					>
						{localString('Download Template')}
					</Button>
					<Button
						type='button'
						classes='button-small servify-button'
						onClick={openUploadModal}
						>
						{localString('Upload')}
					</Button>
							{/* Render the UploadFileModal component */}
					<UploadFileModal
						{...props}
						closeModal={closeUploadModal}
						uploadFile={handleFileUpload}
						acceptedFileTypes= ".csv"
						firstModalTitle="Upload File"
						secondModalTitle="Upload"
						browseButtonText="Browse File"
						browseFileClassName="browse-file-button"
						uploadModalClassName="upload-modal"
						progressBackgroundClassName="progress-background d-flex"
						setFile={setFile}
						selectedFile = {selectedFile}
						setSelectedFile = {setSelectedFile}
						showUploadModal = {showUploadModal}
						setShowUploadModal = {setShowUploadModal} 
						setLoader={setLoader}
						file = {file}
						confirmUploafile = {confirmUploafile}
						setConfirmUploafile={setConfirmUploafile}
					/>
				</div>
			</div>
			<CommonTable 
				columns={columns}
				data={bulkUploadList}
				localString={localString}
				searchText={searchText}
			/>
			{
				pagination.numberOfPages > 1 && <Pagination 
					itemsPerPage={pagination.noOfRecords} 
					pages={pagination.numberOfPages} 
					totalItems={pagination.count}
					activePage={pagination.pageNo} 
					paginate={(pageNumber) =>{
							setPagination({ ...pagination, pageNo: pageNumber })
							getFileStatus(pageNumber,importType)
							setFirstLoad(true)
						}
					}
				/>
			}
		</div>
	);
}

export default RequestBulkUploadGrid;