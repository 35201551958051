import React, {useState,useEffect} from 'react'
import ReactDOM from "react-dom";
import './IntlTelInputWrapper.scss';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const IntlTelInputWrapper = (props) => {
	const {
		label, value, handleOnChange, intlTelInputClassWrapper, defaultCountry, allowDropdown, maxLength, placeholder, 
		separateDialCode, onFlagSelect, disabled, onPhoneNumberBlur, onKeyDown
	} = props;
	const telInputProps = {
		maxlength: maxLength,
		onKeyDown: onKeyDown && onKeyDown,
	}
	return (
		<div className={intlTelInputClassWrapper.formControl}>
			<label className={intlTelInputClassWrapper.label}>{label}</label>
			<IntlTelInput
				containerClassName={"intl-tel-input " + intlTelInputClassWrapper.containerClass}
				placeholder={placeholder ? placeholder : ''}
				inputClassName={intlTelInputClassWrapper.inputClass}
				defaultCountry={defaultCountry}
				value={value}
				formatOnInit={false}
				onPhoneNumberChange={(status, val, countryData) => handleOnChange(val, countryData)}
				onSelectFlag = {(num, country) => onFlagSelect && onFlagSelect(country)}
				telInputProps = {telInputProps}
				onPhoneNumberBlur={onPhoneNumberBlur}
				separateDialCode = {separateDialCode}
				allowDropdown = {allowDropdown}
				disabled = {disabled}
			/>  
		</div>
	)
}

export default IntlTelInputWrapper;
