
import React from 'react';
import './NavBar.scss'

const NavBar = (props) => {
    const {localString,activeNav,navItems} = props;
    return (
        <div className="nav-bar">
            <ul class="nav nav-pills nav-justified">
            {
                navItems && navItems.map((nav,index)=>{
                    return(
                        nav.showNav && <li role="presentation" className={(nav.name===activeNav)?'active':""} onClick={() => nav.navClick(nav.name)}>
                            <a href>{localString(nav.displayName)}</a>
                        </li>
                    )
                })
            }
            </ul>
        </div>
    )
}

export default NavBar;
