import { values } from 'lodash';
import React, { useState } from 'react';
import {Modal,Button,Dropdown,Input} from 'sfy-react';
import Swal from "sweetalert2";
import { returnTimezone,displayError } from '../../../../../Utility/Utils';

const CancelRequestInput=(props)=>{
    const {localString,cancelText,setCancelText,disableCancelReasonSave,selectedCancelReason,claimRequest, preClaimRequest,cancelReasonsData,setFilterCancelrequest,
        filterCancelrequest,continueAfterSelectingReason,claimFlags,accessFn,nextStatus,internalReason, setInternalReason,replacementFlowChecks,onsiteChecks,berChecks,
        claimChecks,claimNewWorkshopChecks}=props;
    
    return(
        < >
            <div className="modal-body cancel-modal">
                <div className='row input-margin'>
                    {
                    cancelReasonsData && !cancelReasonsData.OtherReason &&
                        <div className='cancel-modal-input dropdown-input'>
                            <Dropdown
                                id='dropdown'
                                value={filterCancelrequest}
                                options={selectedCancelReason}
                                handleOnChange={(item) => {
                                    setFilterCancelrequest(item.Reason)
                                }}
                                showTooltip={false}
                                showDownArrow={false} 
                                filterBy='value'
                            />
                        </div>
                    }
                    <div className='textarea-padding'>
                        {filterCancelrequest && filterCancelrequest == 'Other' && <label>{localString("Please specify the reason")}</label>} 
                        {(cancelReasonsData && cancelReasonsData.OtherReason) || (filterCancelrequest == 'Other') &&
                        <div className='textarea other-reason-text'>
                            <Input 
                                className="form-control" 
                                inputComponent='textarea' 
                                id="otherCancelReason" 
                                name="otherCancelReason" 
                                value={cancelText}
                                handleOnChange={(e) => {
                                    setCancelText(e.target.value)
                                }}
                                />
                        </div>
                        }
                           {/* for only claim and new claim service type */}
                        {(claimChecks || claimNewWorkshopChecks) ? ((accessFn('ALLOW_ADDITIONAL_REASON') && nextStatus == 'Claim rejected') || accessFn('WAITING_ON_CUSTOMER_BUTTON')) && <label className="label-input marginT10">{localString("Internal Remark")}</label>:
                            ((accessFn('ALLOW_ADDITIONAL_REASON') && nextStatus == 'Claim rejected') ) && <label className="label-input marginT10">{localString("Internal Remark")}</label>}
                        {(claimChecks || claimNewWorkshopChecks) ? ((accessFn('ALLOW_ADDITIONAL_REASON') && nextStatus == 'Claim rejected') || accessFn('WAITING_ON_CUSTOMER_BUTTON')) && <div className='textarea'>
                            <Input 
                                className="form-control" 
                                inputComponent='textarea' 
                                id="internalReason" 
                                name="internalReason" 
                                value={internalReason}
                                handleOnChange={(e) => {
                                    setInternalReason(e.target.value)
                                }}
                                />
                        </div>
                        // {(!claimChecks || !claimNewWorkshopChecks) && ((accessFn('ALLOW_ADDITIONAL_REASON') && nextStatus == 'Claim rejected') ) && <label className="label-input marginT10">{localString("Internal Remark")}</label>}
                            :((accessFn('ALLOW_ADDITIONAL_REASON') && nextStatus == 'Claim rejected') ) && <div className='textarea'>
                            <Input 
                                className="form-control" 
                                inputComponent='textarea' 
                                id="internalReason" 
                                name="internalReason" 
                                value={internalReason}
                                handleOnChange={(e) => {
                                    setInternalReason(e.target.value)
                                }}
                                />
                        </div> }
                    </div>
                </div>
                <div className="button-container btn-left">
					<Button type="button" className="btn button-servify no-border-radius capitalize save-reason" onClick={ ()=>claimFlags.showClaimCancelRequestBtn ? continueAfterSelectingReason(nextStatus) : preClaimRequest(nextStatus)} isDisabled={disableCancelReasonSave}>{localString("Save")}</Button>
				</div>
            </div>
        </>
    )
}
export default CancelRequestInput;

