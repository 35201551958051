import React, { useState } from 'react'
import ReactDOM from 'react-dom';
import { Loader, Table } from 'sfy-react';

import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import FinishedGoodItem from './FinishedGoodItem';

function FinishedGoodsDetails(props) {
	const {
		consumerServiceRequest, localString, apiCall, getRequestById, showRequestLogs
	} = props
	const [loader, setLoader] = useState(false);
	const editable = (showRequestLogs.isServiceInitiated && !showRequestLogs.isRepairCompleted)

	return (
		<>
			{
				(loader) && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper
						bodyClassName="panel-body-no-padding" 
						title={localString('Finished Goods Details')}
					>
						<div className='finishedGoodDetails'>
							<div className="request-panel-details-tag request-issue-validation-div">
								<div>
									<Table 
										className={{
											table: 'table table-striped complaint-details-table'
										}}
									>
									<Table.Header>
										<Table.Row>
											<Table.Heading>{localString('Part Name')}</Table.Heading>
											<Table.Heading>{localString('Serial Number')}</Table.Heading>
										</Table.Row>
									</Table.Header>
										<Table.Body>
											{
												(consumerServiceRequest?.charges?.length > 0) && consumerServiceRequest.charges.map((item, i) => {
													if(item?.partEstimate?.PartCategoryName === 'FG') {
														return (
															<FinishedGoodItem 
																index={i}
																partItem={item}
																apiCall={apiCall}
																editable={editable}
																setLoader={setLoader}
																localString={localString}
																getRequestById={getRequestById}
															/>
														)
													}}
												)
											}
										</Table.Body>
									</Table>
								</div>
							</div>
						</div>
					</PageContentWrapper>
				</div>
			</div>
		</>
	)
}

export default FinishedGoodsDetails