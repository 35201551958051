import React,{useState, useEffect}  from 'react';
import axios from 'axios';
import JSZip from 'jszip';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import {displayError,generateUUID,safeJsonParse,safeAccess} from '../../../../../Utility/Utils';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import ViewUploadDocJSX from './ViewUploadDocJSX';

const ViewUploadDocuments = (props) => {
	const {apiCall,accessFn,documents,localString,qFilter,consumerServiceRequest,useDocServiceViewUpload,docServiceUrl,authorization,retrieveAllDocs} = props;

	const [loader, setLoader] = useState(false);
	const [documentTypeList, setDocumentTypeList] = useState([])
	const [documentListAPICalled, setDocumentListAPICalled] = useState(false)
	const [disableSelection, setDisableSelection] = useState(false)
	const [editDocuments, setEditDocuments] = useState(false)
	const [fileName, setFileName] = useState("")
	const [selectedDocType, setSelectedDocType] = useState()
	const [otherType, setOtherType] = useState("")
	const [newDocument, setNewDocument] = useState()
	const [newDocumentUpload, setNewDocumentUpload] = useState([])
	const [newDocLoader, setNewDocLoader] = useState(false)
	const [isPOPSelected, setIsPOPSelected] = useState(false)
	const [isLoanerSelected, setIsLoanerSelected] = useState(false)
	const [maxSelected, setMaxSelected] = useState(false)
	const [userDocumentID, setUserDocumentID] = useState([])
	const [isPOPUploaded, setIsPOPUploaded] = useState(false)
	const [disableLoanerDocToGSX, setDisableLoanerDocToGSX] = useState(false)

	useEffect(() => {
		if(documents && documents.length){
			documents.forEach((item)=>{
				if(item.isPOPUploaded){
					setIsPOPUploaded(true)
				}
				if(item.isAttachedToLoanerReturn || (!item.isAttachedToLoanerReturn && item.isLoanerDocUploaded)) {
					setDisableLoanerDocToGSX(true)
				}
			});
		}
	}, [documents])

	const editDocumentsClickViewUpload = ()=> {
		cancelSelectionForSendGSX();
		setDisableSelection(true)
		setEditDocuments(true)
		if(!documentListAPICalled){
			apiCall({
				url:'documentTypeList',
				data: {
					qFilter: JSON.parse(qFilter)
				},
				callBack:(result)=>{
					if(result.success){
						setDocumentListAPICalled(true)
						let docTypetList = result.data && result.data.docTypeList && result.data.docTypeList.map(v =>({...v,value:v.DocumentName}));
						setDocumentTypeList(docTypetList)
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		}else{
			// self.initdocumentTypeList();
		}
	};

	const cancelSelectionForSendGSX = ()=>{
		setUserDocumentID([])
		setDisableSelection(false)
		setMaxSelected(false)
		setIsPOPSelected(false)
		setIsLoanerSelected(false)
		documents && documents.forEach((item)=>{
			item.isSelect = false;
		});
	}
	
	const removeNewDoc = (i)=> {
		let remove = [...newDocumentUpload]
		remove.splice(i, 1);
		setNewDocumentUpload(remove)
	}

	const cancelDetailsClick =()=> {
		setEditDocuments(false)
		cancelSelectionForSendGSX();
		setDisableSelection(false)
		setNewDocumentUpload([])
		setNewDocLoader(false)
		setOtherType("")
		setSelectedDocType("")
		// setDocumentTypeList([...documentTypeList])
		setFileName("")
	}

	const uploadNewDocuments = ()=> {
		if(newDocumentUpload.length>0){
			setLoader(true);
			apiCall({
				url:useDocServiceViewUpload && docServiceUrl ? 'addDocsForDS' : 'addDocs',
				data:{"updatedData" : newDocumentUpload },
				callBack:(result)=>{
					setLoader(false); 
					if(result.success){
						retrieveAllDocs();
						Swal.fire({
							title: '',
							text: localString('Uploaded successfully'),
							icon: 'success',
						});
						setNewDocumentUpload([])
						setEditDocuments(false)
						setDisableSelection(false)
						
					}else{
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(result.msg)),  
						})							
					}
				}	
			}); 
		} else {
			Swal.fire({
				title: '',
				text: localString('Please add document'),
				icon: 'error',
				confirmButtonText: localString('OK')
			});
		}
	}

	const addNewDocumentArray = (e)=> {
		e.preventDefault();
		let selectedPicTag = selectedDocType && selectedDocType.DocumentName;
		if(selectedDocType && selectedDocType.DocumentCode == 'DC-004'){
			selectedPicTag = otherType
		}
		if(!selectedPicTag || selectedPicTag == ''){
			setNewDocument("")
			let errMsg = localString("Please select file name, or choose 'Others' to enter a custom file name.");
			if(selectedDocType && selectedDocType.DocumentCode == 'DC-004'){
				errMsg = localString('Please add tag');
			}
			Swal.fire({
				title:"",
				text:errMsg,
				icon:"error"
			})
			return;
		}
		let newfile = e.target.files;
		if (newfile.length > 0) {
			let files = newfile[0];
			let filesize = ((files.size/1024)/1024).toFixed(4);
			if(filesize && filesize>10){
				setNewDocument("")
				Swal.fire({
					title:"",
					text:localString('File size should not be greater than 10 MB'),
					icon:"error"
				})
				return;
			}
			let orginalName = e.target.files[0].name;
			let fileType = newfile[0].type;
			let docname = newfile[0].name;
			let ext = docname.split(".").length ? "."+docname.split(".")[docname.split(".").length - 1] : "";

			let key = "FEWEBA" + generateUUID() + ext;
			let dataImgType = docname.split(".")[docname.split(".").length - 1];
			if(['txt','doc','docx','csv','pdf','jpg','png','jpeg','zip','aac','m4a','mp4','ogg','ogx','xls', 'xlsx'].indexOf(dataImgType) < 0 ){
				Swal.fire({
					title:"",
					text:localString('Uploaded file format not supported. Pls upload file in one of the following formats - aac, csv, doc, docx, jpeg, jpg, m4a, mp4, ogg, ogx, pdf, png, txt, xls, xlsx, zip.'),
					icon:"error"
				})
				setNewDocument("")
				return;
			}
			let ArrayObj = {
				ConsumerServiceRequestID: consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
				FilePath: key,
				Type: dataImgType,
				FileName: key,
				OriginalName: orginalName,
				Tag: selectedPicTag,
				DocumentID: 12,
				app: 'WebApp',
				DocumentCode:selectedDocType && selectedDocType.DocumentCode
			}
			let uploadDocumentsObj = {
				Type: fileType,
				Key: key,
				BrandID: consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID ? consumerServiceRequest.consumerProduct.BrandID : undefined
			}
			if(dataImgType == 'zip'){
				let zipFile = newfile[0]
				let inValidZip = false;
				var new_zip = new JSZip();
				new_zip.loadAsync(zipFile)
				.then(function(zip) {
					let arr1 = Object.keys(zip.files).filter(item=>{
						if(!item.includes("__MACOSX") && !item.includes("/.")){
							return item;
						}
					})
					let fileExt = [];
					if(arr1[0].includes(".")){
						let lastname = arr1[0].split('.');
						let ext = lastname[lastname.length-1];
						fileExt.push(ext);
					}else{
						arr1.filter((item,i)=>{
							let lastElement = item.split("/");
							let name = lastElement[lastElement.length -1]
							if(name && !name == ""){
								let lastName = name.split(".")
								let ext = lastName[lastName.length-1]
								fileExt.push(ext)
							}
						})
					}
					if(fileExt.length){
						for(let i=0; i<fileExt.length; i++) {
							if(['txt','doc','docx','csv','pdf','jpg','png','jpeg','zip','aac','m4a','mp4','ogg','ogx','xls','xlsx'].indexOf(fileExt[i]) < 0 ){
								inValidZip = true;
							}
						} 
						if(inValidZip){
							Swal.fire({
								title:"",
								text:localString('The file format uploaded in your zip file is not supported. Please remove any folders, correct the file formats and try again.'),
								icon:"error"
							})
							setNewDocument("")
							return;
						}else{
							setNewDocLoader(true)
							uploadImageAPICall(ArrayObj,uploadDocumentsObj,newfile)
						}
					}else{
						Swal.fire({
							title:"",
							text:localString('Zip file uploaded has no content. Check and re-upload file.'),
							icon:"error"
						})
						setNewDocument("")
						return;
					}
				});
			}else{
				setNewDocLoader(true)
				uploadImageAPICall(ArrayObj,uploadDocumentsObj,newfile)
			}
		} else {
			Swal.fire({
				title:"",
				text:localString('Please add a picture.'),
				icon:"error"
			})
			setNewDocLoader(false)
			return;
		}
	}

	const uploadImageAPICall = (ArrayObj,uploadDocumentsObj,newfile)=>{
		if (useDocServiceViewUpload) {
			if (docServiceUrl == '') {
				return;
			}
			let dataNew = new FormData();
			dataNew.append('file', newfile[0]);
			dataNew.append('docType', 'ConsumerServiceRequestDocument');
			dataNew.append('clientRefId', uploadDocumentsObj.Key);
			dataNew.append('entity-type', 'ConsumerServiceRequest');
			dataNew.append('entity-id', consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID);
			axios.post(docServiceUrl + 'internal/document/upload', dataNew, {
				headers:({
					"App": 'WebApp',
					"module": 'WebApp',
					"authorization": authorization
				}),
			})
			.then((result) => {
				if(result.data && result.data.success){
					let uploadURL = result.data.data.uploadURL;
					let fileNameUUID = result.data.data.docServiceUUID;
					let url = result.data.data.url;
					ArrayObj.FileUrl = url;
					ArrayObj.FilePath = uploadURL;
					ArrayObj.FileName = fileNameUUID;
					setNewDocLoader(false)
					let arr = [...newDocumentUpload];
					arr.push(ArrayObj)
					setNewDocumentUpload(arr)
					setOtherType("")
					setFileName("")
					setSelectedDocType("")
					// setDocumentTypeList([...documentTypeList])
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(result.data.message),  
					})							
				}
			})
			.catch((error) => {
				console.log('error: ' + error);
			})
		}
		else {
			apiCall({
				url:'uploadDocuments',
				data:uploadDocumentsObj,
				callBack:(result)=>{
					if(result.success){
						if(result.success){
							let xhr = new XMLHttpRequest();
							xhr.open("PUT", result.data);
							xhr.setRequestHeader("Cache-Control", "public,max-age=3600");
							xhr.setRequestHeader('x-amz-acl', 'public-read');
							xhr.onload = function () {
								let fileUrl = result.data.split('?');
								let response = safeJsonParse(response);
								ArrayObj.FileUrl = safeAccess(() => response.data.link) || fileUrl[0];
								setNewDocLoader(false)
								let arr = [...newDocumentUpload];
								arr.push(ArrayObj)
								setNewDocumentUpload(arr)
								setOtherType("")
								setNewDocument("")
								setSelectedDocType("")
								// setDocumentTypeList([...documentTypeList])
								setFileName("")
							};
							xhr.onerror = function () {}.bind(this);
							xhr.send(newfile[0]);
						} else {
							Swal.fire({
								title:"",
								text:result.msg,
								icon:"error"
							})
							setNewDocLoader(false)
							setNewDocument("")
							return;
						}
					}else{
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(result.msg)),  
						})							
					}
				}	
			});
		}
	}

	const sendImageToGSXCheck = (doc)=>{
		let dummyDocuments = [...documents];
		dummyDocuments && dummyDocuments.map((dummy)=>{
			if(dummy.UserDocumentID == doc.UserDocumentID){
				dummy.isSelect = !doc.isSelect;
			}
		})

		let UserDocID =[];
		setIsPOPSelected(false)
		setIsLoanerSelected(false)
		dummyDocuments && dummyDocuments.forEach(item=>{
			if(item.isSelect){
				UserDocID.push(item.UserDocumentID);
				if(item.DocumentCode == 'DC-006'){
					setIsPOPSelected(true)
				} else if (item.DocumentCode == 'DC-007') {
					setIsLoanerSelected(true)
				}
			}
		})
		setMaxSelected(false)
		if(UserDocID.length == 3){
			setMaxSelected(true)
		}
		setUserDocumentID(UserDocID)
	}

	const sendImageToGSX = ()=>{
		let data ={
			ConsumerServiceRequestID: consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
			"UserDocumentID": userDocumentID,
			"SerialNumber":consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey?consumerServiceRequest.consumerProduct.AlternateUniqueKey:consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue
		}
		setLoader(true)
		apiCall({
			url:'uploadImagesToGSX',
			data:data,
			callBack:(result)=>{
				setLoader(false); 
				if(result.success){
					retrieveAllDocs();
					setNewDocumentUpload([])
					setEditDocuments(false)
					cancelSelectionForSendGSX();
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					})							
				}
			}	
		});
	}

	return (
		<>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="inner-padding view-upload-document-component">
				<div className="row">
					<PageContentWrapper 
						title={accessFn('EDIT_REQUEST_DOCUMENTS') ? localString("View")+' / ' +localString("Upload Documents") : localString("View Documents")}
						showDownloadButtonIcon={accessFn('EDIT_REQUEST_DOCUMENTS') && !editDocuments}
						editDeviceClick={editDocumentsClickViewUpload}
						customSymbol={accessFn('EDIT_REQUEST_DOCUMENTS') && "glyphicon glyphicon-plus"}
					>
					<ViewUploadDocJSX 
						{...props}
						editDocuments={editDocuments}
						documentTypeList={documentTypeList}
						setFileName={setFileName}
						fileName={fileName}
						setSelectedDocType={setSelectedDocType}
						selectedDocType={selectedDocType}
						setOtherType={setOtherType}
						otherType={otherType}
						addNewDocumentArray={addNewDocumentArray}
						newDocLoader={newDocLoader}
						newDocumentUpload={newDocumentUpload}
						removeNewDoc={removeNewDoc}
						uploadNewDocuments={uploadNewDocuments}
						cancelDetailsClick={cancelDetailsClick}
						sendImageToGSXCheck={sendImageToGSXCheck}
						userDocumentID={userDocumentID}
						isPOPSelected={isPOPSelected}
						isLoanerSelected={isLoanerSelected}
						disableSelection={disableSelection}
						maxSelected={maxSelected}
						sendImageToGSX={sendImageToGSX}
						cancelSelectionForSendGSX={cancelSelectionForSendGSX}
						isPOPUploaded={isPOPUploaded}
						disableLoanerDocToGSX={disableLoanerDocToGSX}
					/>
					</PageContentWrapper>
				</div>
			</div>
		</>
	)
}

export default ViewUploadDocuments
