import React, { useRef } from 'react';
import {Dropdown,Input,Button,Table} from 'sfy-react';
const CloseVisitPendingUI=(props)=>{ 
    const {localString,partInputs,addJobPart,addToPartsArray, picMaster,removeAddedDoc,selectedPicType,docArray,otherArrImagesPreview,consumerServiceRequest,
	partSelected,addDocArray,partsArray,tableInput,openImage,removeAddedPart,updateJobDetails,backToJobUpdate,showPartLoader }=props;
    let acceptFileType=".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv";
    const fileRef = useRef(null);
    const imageClick = () =>{
        fileRef.current.click();
	}

    return(
        <div className='fluid-form'>
            <div className='col-sm-12 register-filter'>
                {/* <div className="col-sm-12 register-filter"> */}
                    <p className="parts-table-header text-capitalize remove-margin">{localString("Add Parts")}:</p>
                {/* </div> */}
                <form>
                <div className='row form-group-filter-request'>
                    {
                        partInputs && partInputs.length>0 && partInputs.map((part,index)=>(
                            <div>
                                {
                                    part.type && part.type == 'Dropdown'  && part.showPart &&
                                    <div className= {`${part.customClass} parts-dropdown  register-filter scrollable-dropdown-menu`}>
                                        <label className="input__label ">{part.labelText}</label>
										{   <Dropdown
                                                id='dropdown'
                                                key={index}
                                                value={addJobPart[part.dataKey]}
                                                options={part.dropdownList}
                                                handleOnChange={(data) => {
                                                    part.oninputFunc(data);
                                                }}
                                                classObject={{
                                                    container: part.customClass,
                                                }}

                                                partBy='value'
                                                showDownArrow={false}
                                                searchText={part.searchText}
                                                isDisabled={part.isDisabled}
                                                placeholder={part.placeholder}
                                                OptionsComponent={part.OptionsComponent}
                                            />
                                        }
                                        
                                    </div>
                                }
                                {
                                    part.type=='input' && part.showPart &&<div className={part.customClass}>
                                        <Input
											value={addJobPart[part.dataKey]}
											key={index}
											handleOnChange={(e) => {
												part.oninputFunc(e.target.value);
											}}
											label={part.labelText}
											labelAlign='top'
											isDisabled={part.isDisabled}
										/>
                                    </div>
                            }
                            {
								part.type=='text' && part.showPart &&<div className={part.customClass}>
									<label>{part.labelText}</label>
									<div>{part.partSelected}</div>
									{
										//aria-hidden="true" style="position:relative;"
										part.showLoader && <span id="availableLoader" className="input-loader form-control-loader" ></span>
									}
								</div>
                            }
                            {
								part.type=="button" && part.showPart && <div className={`${part.customClass} register-filter`}>
									<Button  className="btn button-servify add_part_btn"  handleOnClick={(e)=>addToPartsArray(e)}>{part.labelText}</Button>
								</div>
                            }
                            </div>
                        ))
                    }
					</div>
					{
                    consumerServiceRequest.Source == 'SkyworthDashboard' && partSelected &&<div className="col-md-12 register-filter">
						<div class="picture-type-label col-sm-12">
							{localString("Select Picture Type")}
						</div>
                        <div class="col-sm-12 upload-images-parent">
							<ul class="row upload-images-ul">
                            {
								picMaster && picMaster.map((pic,index)=>(
									<li className='col-sm-3 pic-list'>
										<div className={`${(pic.picType).replace(/\s+/, "")}  image-field`}>
											<Input
												reference={fileRef}
												handleOnChange={(e)=>addDocArray(e)}
												name={pic.picType}
												id={(pic.picType).replace(/\s+/, "")}
												inputType="file"
												className="inputfile"
												otherProps={{ accept: acceptFileType }}
											/>
											<label for={`${(pic.picType).replace(/\s+/, "")} `}  >
												<span className="glyphicon glyphicon-plus upload-sign" onClick={imageClick} ></span>
											</label>
										</div>
										<p className='image-text'>{localString(pic.picType)}</p>
                                    {
										docArray && docArray.map((doc,index)=>(
                                        <div className='request-documents-li align'>
											{
												pic.picType === doc.Tag && <div>
													<div className="glyphicon glyphicon-minus-sign delete-charges-btn" onClick={()=>removeAddedDoc(doc.id)}></div>
													<a href={doc.FileUrl} target="_blank" className="nounderline">
															<img src={doc.FileUrl} alt={selectedPicType} className='image' />
													</a>
												</div>
											}
                                        </div>
										))
                                    }
									</li>
								))
                            }
                            {
								otherArrImagesPreview && otherArrImagesPreview.map((othdocs,index)=>(
									<li className='pic-list'>
										<div className={picMaster.length>0 ? "request-documents-list align" :"request-documents-otherli align"} >
											<div className="glyphicon glyphicon-minus-sign delete-charges-btn" onClick={()=>removeAddedDoc(othdocs.id)}></div>
											<a href={othdocs.FileUrl} target="_blank" className="nounderline">
													<img src={othdocs.FileUrl} alt={selectedPicType} className="image-other"/>
											</a>
										</div>
									</li>
								))
                            }
                            {
                            <li className=" image-col pic-list">
								<div className='image-field'>
									<Input
										reference={fileRef}
										handleOnChange={(e)=>addDocArray(e)}
										name="Other"
										id="Other"
										inputType="file"
										className="inputfile"
										otherProps={{ accept: acceptFileType }}
									/>
									<label for="Other"> 
										<span className="glyphicon glyphicon-plus upload-sign" onClick={imageClick} ></span>
									</label>
								</div>
								<p className='image-text'>{localString("Other Image")}</p>
							</li>
                            }
							</ul>
                        </div>
                        {
							docArray && docArray.length == 0 && <div className="col-sm-12 padding20" >
								{localString("No pictures added.")}
							</div>
                        }      
                    </div>
					}
					{
					partsArray && partsArray.length >0 && 
						<div>
							<div>
								<p className='parts-table-header text-capitalize remove-margin'>{localString("Added Parts")}:</p>
							</div>
							<Table isResponsive>
								<Table.Header>
									<Table.Row>
										{
											tableInput && tableInput.map((item,index)=>(
												<Table.Heading key={index}>{item.labelText}</Table.Heading>
											))
										} 
										<Table.Heading></Table.Heading>
										<Table.Heading></Table.Heading>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{
									partsArray && partsArray.length >0 && partsArray.map((item,index)=>(
										<Table.Row>
										<Table.Data className={item.customClass}>{item.Name}</Table.Data>
										<Table.Data className={item.customClass}>{item.PartCode}</Table.Data>
										<Table.Data className={item.customClass}>{item.ChargeCode ?item.ChargeCode :""}</Table.Data>
										<Table.Data className={item.customClass}>{item.InWarranty ? "In warranty" : "Out of warranty"}</Table.Data>
										<Table.Data className={item.customClass}>{item.Charge ? item.Charge : "-"}</Table.Data>
										<Table.Data className={item.customClass}>{item.Remarks ? item.Remarks : "-"}</Table.Data>
										<Table.Data className={item.customClass}>1</Table.Data>
										{
											<Table.Data>
											{
												item.Documents && item.Documents.map(doc=>(
													<div className={item.customClass}><Button className="btn button-servify" name={doc.FileUrl}  handleOnClick={(e)=>openImage(e)}>{localString("View Image")}</Button></div>
												))
											}
											</Table.Data>
										}
											<Table.Data className="width5"> <div className=" glyphicon glyphicon-minus-sign delete-table-btn"   onClick={()=>removeAddedPart(item.id)}></div></Table.Data>
										</Table.Row>
									))
									}
								</Table.Body>
							</Table>
						</div>
                }
            </form>
            </div>
            <div className="button-container paddingR0">
				<Button   type='submit' className="btn button-servify"  handleOnClick={()=>updateJobDetails()} isDisabled={!(partsArray && partsArray.length >0)}  >{localString("Complete Visit")}</Button>
				<Button  type='button' className='button-ghost'  handleOnClick={backToJobUpdate} >Back</Button> 
            </div>
        </div>
    )
}
export default  CloseVisitPendingUI;


