import React from 'react';
import { Button, Input } from 'sfy-react';
import Swal from "sweetalert2";

export default function TokenNumber(props) {
    const {
        localString, processRequestNext, closeModal, refundToken, setRefundToken
    } = props;


    const sendTokenNumber = () => {
        if(refundToken.length > 0 ) {
            processRequestNext();
            closeModal();
        }else {
            Swal.fire({
                icon: "error",
                title: localString('Sorry'),
                text: localString('Please enter a token number'),
            });
            return;
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <label className="text-capitalize">
                        {localString("Please enter Token Number.")}
                    </label>
                    <div>
                        <Input
                            value={refundToken}
                            handleOnChange={(e) => setRefundToken(e.target.value)}
                            // inputComponent='textarea'
                            // className={{
                            //     inputContainer: 'lookUpTextBox'
                            // }}
                            otherProps={{
                                onKeyDown: (e) => {
                                    if(e && (e.key == 'Enter')) {
                                        sendTokenNumber();
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='pull-right mt-15'>
                <Button 
                    type='button'
                    className="btn button-servify"
                    onClick={() => sendTokenNumber()}
                    isDisabled={!refundToken || !refundToken.trim().length}
                >
                    {localString("Save")}
                </Button>
            </div>
        </>
    );
}