import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import {displayError} from '../../../../../Utility/Utils';
import TaskListTable from './TaskListTable';
import TaskListModal from './TaskListModal';
import RemarkModal from './RemarkModal';
import CloseRemarkModal from './CloseRemarkModal';
import './TaskList.scss';

const TaskList = (props) => {
	const {apiCall, taskListArr, getRequestById, localString, getTaskValues, ConsumerServiceRequestID} = props;
	const [loader, setLoader] = useState(false);
	const [inEditModeRemark, setInEditModeRemark] = useState(false)
	const [taskAllValue, setTaskAllValue] = useState(false)
	const [remarkModal, setRemarkModal] = useState(false)
	const [closeTaskModal, setCloseTaskModal] = useState(false)
	const [taskListDetail, setTaskListDetail] = useState({})
	const [remarkDisableBtn, setRemarkDisableBtn] = useState(true);
	const [cloaseTaskDisable, setCloaseTaskDisable] = useState(true);
	const [remarkValue, setRemarkValue] = useState({
		remarkVal:"",
		taskReferenceId:""
	})
	const [closeRemark, setCloseRemark] = useState({
		remark:"",
		taskReferenceId:""
	})

	useEffect(() => {
		if(remarkValue.remarkVal && remarkValue.remarkVal.length > 0){
			setRemarkDisableBtn(false)
		}else{
			setRemarkDisableBtn(true)
		}
	}, [remarkValue.remarkVal])

	useEffect(() => {
		if(closeRemark.remark && closeRemark.remark.length > 0){
			setCloaseTaskDisable(false)
		}else{
			setCloaseTaskDisable(true)
		}
	}, [closeRemark.remark])

	const overAllTaskValues = (taskDetail) => {
		let list = {};
		list.taskTitle = taskDetail.title
		list.taskDesc = taskDetail.description
		list.taskexternalReferenceId = taskDetail.externalReferenceId
		list.taskRemark = taskDetail.remark
		list.taskStatus = taskDetail.status
		if(taskDetail.status == "Open"){
			list.taskStatusClass = 'label-success'
		}else{
			list.taskStatusClass = 'label-danger'
		}
		list.taskupdatedDate = (taskDetail.updatedDate ? taskDetail.updatedDate : '-')
		list.taskcreatedDate = taskDetail.createdDate
		list.taskclosedDate = taskDetail.closedDate
		setTaskListDetail(list)
		setTaskAllValue(true)
	}

	const closeTaskListModal = () => {
		setTaskAllValue(false)
		setTaskListDetail({})
	}

	const closeRemarkModal = () => {
		setRemarkModal(false)
		setRemarkValue({})
	}

	const closeButtonModal = () => {
		setCloseTaskModal(false)
		setCloseRemark({})
	}

	const showTaskCloseModal = (data) => {
		setTaskAllValue(false)
		setRemarkModal(false)
		setCloseTaskModal(true)
		setCloseRemark({
			taskReferenceId:data.taskReferenceId,
			remark:data.remark,
			externalReferenceId:data.externalReferenceId
		})
		
	}

	const editRemark = (data) => {
		setTaskAllValue(false)
		setRemarkModal(true)
		setRemarkValue({
			taskReferenceId:data.taskReferenceId,
			remarkVal:data.remark
		})
	}

	const saveTaskRemark = () => {
		let data = {
			taskReferenceId: remarkValue.taskReferenceId,
			remark: remarkValue.remarkVal
		}
		setLoader(true);
		apiCall({
			url:'updateTask',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result){
					setRemarkModal(false)
					Swal.fire({
						title:"",
						icon:"success",
						text: "Remark updated successfully",
						allowEscapeKey:false,
						confirmButtonColor:"#4c10c1"
					}).then(result=>{
						if(result.isConfirmed){
							getRequestById();
						}
					})
				}else{
					setRemarkModal(false)
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const closeTask = () => {
		let remark = closeRemark.remark.trim();
		if(remark.length >= 150){
			let data = {
				"taskReferenceId": closeRemark.taskReferenceId,
				"remark": closeRemark.remark,
				"ConsumerServiceRequestID": ConsumerServiceRequestID
			}
			setLoader(true);
			apiCall({
				url:'closeTask',
				data: data,
				callBack:(result)=>{
					setLoader(false);
					if(result.success) {
						setCloseTaskModal(false)
						let closeTaskResult =  result.data || {};
						let receiptFromCroma = closeTaskResult ? closeTaskResult.receiptFromCroma : undefined
						getTaskValues();        
					}else{
						setCloseTaskModal(false)
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		}else{
			Swal.fire({
				title: '',
				text:localString("Please enter the remarks with at least 150 characters"),
				icon: 'error',
				confirmButtonText: localString('OK')
			});
			return;
		}
	}

	return (
		<>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper bodyClassName="panel-body-no-padding" title={localString("Tasks")}>
						<div className="task-list-content">
						{
							taskListArr && taskListArr.length > 0 && 
							<TaskListTable 
								{...props}
								inEditModeRemark={inEditModeRemark}
								taskListArr={taskListArr}
								overAllTaskValues={overAllTaskValues}
								editRemark={editRemark}
								showTaskCloseModal={showTaskCloseModal}
							/>
						}
						{
							taskListArr && taskListArr.length == 0 && 
							<div className="">
									<p className="charges-panel-subheader text-capitalize remove-margin">{localString("No tasks Available")}</p>
							</div>
						}
						{
							taskAllValue && 
							<TaskListModal 
								{...props}
								taskAllValue={taskAllValue}
								taskListDetail={taskListDetail}
								closeTaskListModal={closeTaskListModal}
							/>
						}
						{
							remarkModal && 
							<RemarkModal 
								{...props}
								remarkModal={remarkModal}
								closeRemarkModal={closeRemarkModal}
								remarkValue={remarkValue} setRemarkValue={setRemarkValue}
								saveTaskRemark={saveTaskRemark}
								remarkDisableBtn={remarkDisableBtn}
							/>
						}
						{
							closeTaskModal && 
							<CloseRemarkModal 
								{...props}
								closeTaskModal={closeTaskModal}
								closeButtonModal={closeButtonModal}
								closeRemark={closeRemark} setCloseRemark={setCloseRemark}
								closeTask={closeTask} 
								cloaseTaskDisable={cloaseTaskDisable}
							/>
						}
						</div>
					</PageContentWrapper>
				</div>
			</div>
		</>
	)
}

export default TaskList
