import React, {useState,useEffect} from 'react'
import ReactDOM from "react-dom";
import './ButtonSwitchInput.scss';

const ButtonSwitchInput = (props) => {
    const {switchButtons, btnWrapperClass} = props;
    return (
        <div className={"btn-group ref-id-radio " + (btnWrapperClass ? btnWrapperClass : '')}>
            {switchButtons && switchButtons.length > 0 && switchButtons.map((obj, index) => {
                return(obj &&
                    <label className={"btn button-servify ref-id-radio-label" + (obj.isSelected ? " active" : '')} key={obj.type + index}>
                        <input className="radio-input" type="radio" name="selected-device-type" checked={obj.isSelected} onChange={() => obj.onButtonSwitch(obj.type)} />
                        {obj.icon && obj.iconAlignment === 'left' ? obj.icon : ''}
                        <div className="label-text">{obj.label}</div>
                        {obj.icon && obj.iconAlignment === 'right' ? obj.icon : ''}
                    </label>
            )})}
        </div>
    )
}

export default ButtonSwitchInput
