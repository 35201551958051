import React from 'react';
import {Input, Button, Modal, Dropdown} from 'sfy-react';

const ActionOnDeviceModal = (props) => {
	const {showModal, localString, firstProduct, closeRemarkModal, getSerializedParts, currentDOAPart, setDeviceIssuing, 
		deviceIssuing, showErrors, validateImeiOne, validateImeiTwo, validateSerialNumber, updateDeviceAction, disableSaveIssue
	} = props;
	return (
		<Modal showModal={showModal} closeModal={closeRemarkModal} showCloseBtn={true} className="modal filtermodal callModal doa-issue-modal">
			<Modal.Header>
				<h4 className="modal-title">{localString("Enter Information for the Device you are issuing")}</h4>   
			</Modal.Header>
			<Modal.Body>    
				<div className="row">
					<div className="col-sm-12">
						<div className="col-sm-12" style={{marginLeft:"6px"}}>
							<div>
							{
								firstProduct && firstProduct.Serialized == 1 &&
								<div className="product-serial-number">
									<label className="custom-label">{firstProduct && firstProduct.Name}:</label>
									<div className="col-sm-6">
										<Dropdown
											id={currentDOAPart.serialNumber}
											value={deviceIssuing.serialNumberByDropdown}
											options={currentDOAPart}
											handleOnChange={(data) => {
												setDeviceIssuing({...deviceIssuing,
												['serialNumberByDropdown']: data.SerialNumber,
												['PartItemID']: data.PartItemID
												})
											}}
											handleOnFocus={() => {
												getSerializedParts()
											}}
											showTooltip
											filterBy='value'
											showDownArrow={false}
											placeholder="Serial Number"
										/>
									</div>
								</div>
							}
							{
								<div className="custom-input-fields">
									<label style={{width:"20%"}}>{firstProduct && firstProduct.Name}:</label>
									<Input
										value={deviceIssuing.IMEI1}
										id="doa-imeione"
										handleOnChange={(e) => {
											setDeviceIssuing({...deviceIssuing,['IMEI1']:e.target.value})
											validateImeiOne(e.target.value)
										}}
										placeholder="IMEI 1"
										maxLength={15}
									/>
									<Input
										value={deviceIssuing.IMEI2}
										id="doa-imeitwo"
										handleOnChange={(e) => {
											setDeviceIssuing({...deviceIssuing,['IMEI2']:e.target.value})
											validateImeiTwo(e.target.value)
										}}
										placeholder="IMEI 2"
										maxLength={15}
									/>
									<Input
										value={deviceIssuing.serialNumber}
										id="serialNumber"
										handleOnChange={(e) => {
											setDeviceIssuing({...deviceIssuing,['serialNumber']:e.target.value})
											validateSerialNumber(e.target.value)
										}}
										placeholder="Serial Number"
										maxLength={20}
									/>
								</div>
							}
							</div>
							<div className = "error-span">
								{
									showErrors.IMEIOne &&
									<span className = 'span-error'>{localString("Please enter correct IMEI 1")}</span>
								}
								{
									showErrors.IMEITwo &&
									<span className = 'span-error'>{localString("Please enter correct IMEI 2")}</span>
								}
								{
									showErrors.serialNumber &&
									<span className = 'span-error'>{localString("Please enter correct Serial Number")}</span>
								}
							</div>
							<br/>
						</div>
					</div>
				</div>
				<div className="modal-save-btn">
					<Button 
						type="submit" 
						name="submitButton"
						id="saveButton"
						isDisabled= {disableSaveIssue}
						text={localString("Save")}
						className={"disableSaveIssue"?'disable-btn btn button-servify float-right':'btn button-servify float-right'}
						handleOnClick={(e)=>updateDeviceAction(e)}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	)
}

export default ActionOnDeviceModal
