import React from 'react';
import FileUpload from '../../components/FileUpload/FileUpload';


const CatalogueFileUpload = (props) => {
	const {localString,numberOfCount,showCount} = props;

	return (
		<div className="request-panel consumer-details-height">
			<div className = "detailsProducts margin-zero">
			{
				showCount &&
				<h4> {localString("Total Unregistered Products")}:
					<span>{numberOfCount}</span>
				</h4>
			}
			</div>
			<FileUpload {...props}></FileUpload>
		</div>
	)
}

export default CatalogueFileUpload
