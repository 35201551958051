import React from 'react';
import { Button, Input } from 'sfy-react';
import Swal from "sweetalert2";

export default function SerialNumberTextBox(props) {
    const {
        localString, processRequestNext, closeModal, serialNumberRefund, setSerialNumberRefund
    } = props;


    const sendSerialNumber = () => {
        if(serialNumberRefund.length > 8 ) {
            processRequestNext();
            closeModal();
        }else {
            Swal.fire({
                icon: "error",
                title: localString('Sorry'),
                text: localString('Please enter correct serial number'),
            });
            return;
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <label className="text-capitalize">
                        {localString("Please enter Serial Number.")}
                    </label>
                    <div>
                        <Input
                            value={serialNumberRefund}
                            handleOnChange={(e) => setSerialNumberRefund(e.target.value)}
                            // inputComponent='textarea'
                            className={{
                                inputContainer: 'lookUpTextBox'
                            }}
                            otherProps={{
                                onKeyDown: (e) => {
                                    if(e && (e.key == 'Enter')) {
                                        sendSerialNumber();
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='pull-right mt-15'>
                <Button 
                    type='button'
                    className="btn button-servify"
                    onClick={() => sendSerialNumber()}
                    isDisabled={!serialNumberRefund || (serialNumberRefund && serialNumberRefund.length < 9)}
                >
                    {localString("Save")}
                </Button>
            </div>
        </>
    );
}