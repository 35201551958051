import React from 'react';
import {Modal} from 'sfy-react';
import './IssueResolvedModal.scss';
import IssueResolvedInput from './issueResolvedInput';
const IssueResolvedModal=(props)=>{
    const {localString,showModal,closeModal} = props;
		return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
                <Modal.Header>
                    <h4 className="text-center">{localString("Issue Resolved")}</h4>
                </Modal.Header>
                <Modal.Body>
                    <IssueResolvedInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default IssueResolvedModal;