import { debounce } from 'lodash';
import React,{useState, useEffect}  from 'react';
import ReactDOM from 'react-dom';
import {Loader, Dropdown, Button, MultiInput} from 'sfy-react';
import Swal from "sweetalert2";
import Header from '../../components/Header/Header';
import IntlTelInputWrapper from '../../components/IntlTelInputWrapper/IntlTelInputWrapper';
import { checkMobileNumber, displayError, getMobileMaxLength } from '../../Utility/Utils';
import './Paytm.scss'

const PaytmWallet = (props) => {
	const {accessFn, localString, apiCall, titleHeader } = props;
	const qFilter = JSON.parse(props.qFilter);
	const [showLoader ,setLoader] = useState(false);
	const [countriesData, setCountriesData] = useState(null);
	const [PSLSearchText, setPSLSearchText] = useState("")
	const [partnerLocationList, setPartnerLocationList] = useState([])
	const [selectedPSL, setSelectedPSL] = useState({});
	const [entityDetails, setEntityDetails] = useState([])
	const [countryCode, setCountryCode] = useState(qFilter && qFilter.countrycode && qFilter.countrycode.length > 0 ? qFilter.countrycode[0].toUpperCase()  : "IN")
	const [newLinking, setNewLinking] = useState(false)
	const [paytmVerifyScreen, setPaytmVerifyScreen] = useState(false)
	const [freshLinkingDetails, setFreshLinkingDetails] = useState({
		MobileNumber: "",
		countryCode: countryCode,
		status: ""
	})
	const [verifyMobileNumberDetails, setVerifyMobileNumberDetails] = useState({})
	const [mobileMaxLength, setMobileMaxLength] = useState([])

	/*******************************************/
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(60);
	const [otp, setOtp] = useState("")

	useEffect(() => {
		if(paytmVerifyScreen){
			const interval = setInterval(() => {
				if(minutes > 0) {
					if(seconds > 0) {
						setSeconds(seconds - 1);
					}else {
						setSeconds(59);
						setMinutes(minutes - 1);
					}
				}else {
					if(seconds > 0) {
						setSeconds(seconds - 1);
					}
				}
			}, 1000);
			if((seconds == 0) && (minutes == 0)) {
				clearInterval(interval);
			}
			return () => {
				clearInterval(interval);
			}
		}
    }, [seconds, paytmVerifyScreen]);

	const getTimerString = () => {
		return minutes === 0 && seconds === 0
				? ""
				: minutes
				+ ":"
				+ (seconds < 10 ? "0" + seconds : seconds)
	}

	/*******************************************/

	useEffect(() => {
		if(entityDetails && entityDetails[0]){
			setFreshLinkingDetails({...freshLinkingDetails,
				MobileNumber: entityDetails[0].MobileNo.toString(),
				status: entityDetails[0].Status
			})
		}
	}, [entityDetails])
	
	useEffect(() => {
		getCountries();

		if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.length == 1){
			loadServiceCenters()
		}
	}, [])
	
	useEffect(() => {
		getServiceCenterValue(PSLSearchText);
	}, [PSLSearchText])

	useEffect(() => {
		(selectedPSL && selectedPSL.PartnerServiceLocationID) && loadFetchEntityDetails()
	}, [selectedPSL])
	
	const getServiceCenterValue = debounce((locationInput)=>{
		if (locationInput && locationInput.length > 2){
			loadServiceCenters(locationInput)
		}
	});

	const getCountries = (country) => {
		let data = {
			specificCountryCode: country ? country.toUpperCase() : countryCode,
			countryConfig: true
		}
		setLoader(true);
		apiCall({
			url:'getCountries',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let countryData = result.data.countries[0];
					setCountriesData(countryData);
					let maxLength = getMobileMaxLength(countryData);
					setMobileMaxLength(maxLength);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const loadServiceCenters = (locationInput) => {
		let data = {}
		if(locationInput){
			data.ServiceLocationNameSubString = locationInput;
			// SearchCountryID: countriesData && countriesData.CountryID ? countriesData.CountryID : undefined,
		}
		setLoader(true);
		apiCall({
			url:'serviceCenterList',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success && result.data){
					if(qFilter && qFilter.serviceLocations && qFilter.serviceLocations.length == 1){
						setSelectedPSL(result.data[0])
					}
					const list = result.data && result.data.map(v => ({...v, value: v.ServiceLocationName}))
					setPartnerLocationList(list)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const loadFetchEntityDetails = () => {
		setEntityDetails([])
		setLoader(true);
		let data = {
			PartnerServiceLocationID: selectedPSL.PartnerServiceLocationID
		}
		apiCall({
			url:'fetchEntityDetails',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success && result.data){
					setEntityDetails(result.data)
				}else{
					setNewLinking(true)
					setFreshLinkingDetails({
						...freshLinkingDetails,
						MobileNumber: "",
						status: ""
					})
					Swal.fire({
						icon: 'info',
						text: localString("Paytm Wallet not found. Please add your Paytm Number to link"),
					});
				}
			}
		});
	}

	const paytmVerifyMobile = () => {
		const data = {
			PartnerServiceLocationID: selectedPSL.PartnerServiceLocationID,
			MobileNo: freshLinkingDetails.MobileNumber,
			CountryCode: qFilter && qFilter.countrycode ? qFilter.countrycode[0] : undefined,
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		};

		setLoader(true);
		apiCall({
			url:'sendPaytmOtp',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let mobileNumberDetails = result.data;
					setVerifyMobileNumberDetails(mobileNumberDetails)
					setPaytmVerifyScreen(true)
					setSeconds(59);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const verifyPaytmOtp = (e) => {
		e.preventDefault();
		const data = {
			Otp: otp,
			PartnerServiceLocationID: selectedPSL.PartnerServiceLocationID,
			State: verifyMobileNumberDetails.State,
			MobileNo: freshLinkingDetails.MobileNumber,
			CountryCode: qFilter && qFilter.countrycode ? qFilter.countrycode[0] : undefined,
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		};
		setLoader(true);
		apiCall({
			url:'verifyPaytmOtp',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					setNewLinking(false);
					setPaytmVerifyScreen(false);
					setOtp("")
					setEntityDetails([])
					loadFetchEntityDetails();
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	};

	const unlinkPaytmAccount = () => {
		const data = {
			PaytmUnlinkRequired: 0,
			PartnerServiceLocationID: selectedPSL.PartnerServiceLocationID,
			CountryCode: qFilter && qFilter.countrycode ? qFilter.countrycode[0] : undefined,
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		};

		Swal.fire({
			title: 'Unlink Paytm Account',
			text: 'Do you really want to unlink your Paytm wallet?',
			icon: 'question',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonColor: '#4c10c1',
			confirmButtonText: localString('Yes'),
			cancelButtonText: localString('No')
		}).then(result=>{
			if(result.isConfirmed){
				setLoader(true);
				apiCall({
					url:'unlinkAccount',
					data: data,
					callBack:(result)=>{
						setLoader(false);
						if(result.success){
							setFreshLinkingDetails({
								MobileNumber: "",
								countryCode: countryCode,
								status: ""
							})
							loadFetchEntityDetails();
						}else{
							Swal.fire({
								icon: 'error',
								text: localString(displayError(result)),
							});
						}
					}
				});
			}
		})
	};

	return(
		<>
			{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className='paytm'>
				<div className='userManagement'>
					<Header titleHeader={titleHeader}/>
					<div className='container-fluid' style={{ 'paddingTop': '30px' }}>
						<div className='advancePayBody'>
							<div className='row userRow'>
								<div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 paddingB10'>
									<div className="userCard animated animatedFadeInLeft fadeInLeft activeUserCard">
										<div className='cardHeader'>
											{localString('Link or Unlink Paytm Wallet')}
										</div>
										<div className='cardBody'>
											<div className='panCardDropdown' style={{paddingBottom: "10px"}}>
												<Dropdown
													id='dropdown'
													value={selectedPSL.ServiceLocationName}
													options={partnerLocationList}
													handleOnChange={(val) => {
														setSelectedPSL(val);
													}}
													handleOnSearch = {(e)=>{
														setPSLSearchText(e.target.value)
														return partnerLocationList;
													}}
													filterBy='value'
													labelFloat={true}
													searchText='Store Name'
													showNoOptionsText={false}
												/>
											</div>
											{
												entityDetails && entityDetails.length > 0 &&
												<div className='userDetails'>
													<div className='userMobileField ' id="mobile-number" style={{margin: "10px 0px", paddingBottom: "10px"}}>
														<IntlTelInputWrapper 
															value={freshLinkingDetails.MobileNumber}
															key="MobileNo"
															handleOnChange={(val, countryData) => {
																setFreshLinkingDetails({...freshLinkingDetails,
																	MobileNumber: val,
																	countryCode: countryData.iso2
																})
															}}
															intlTelInputClassWrapper = {{
																formControl: 'form-control--top ',
																label: 'input__label',
																containerClass: 'input-container',
																inputClass: 'input'
															}}
															onFlagSelect = {(countryData) => {
																setFreshLinkingDetails({...freshLinkingDetails,
																	countryCode: countryData.iso2
																})
																getCountries(countryData.iso2)
															}}
															disabled= {freshLinkingDetails.status == "Linked" ? true : false}
															placeholder= "Mobile Number"
															defaultCountry= {freshLinkingDetails.countryCode? freshLinkingDetails.countryCode.toLowerCase() :countryCode}
															maxLength={mobileMaxLength}
														/>
													</div>
													<div className='walletStatus'>
														<strong>{localString("Status")}</strong>
														<p className={ freshLinkingDetails.status == "Linked" ? "accLinked" : "accNotLinked"} >
															{
																localString(freshLinkingDetails.status)
															}
														</p>
													</div>
													<div className="linking-button">
													{
														accessFn('PAYTM_WALLET') &&
														<Button
															className="btn servify-blue-Btn"
															isDisabled = {(!freshLinkingDetails.MobileNumber || !freshLinkingDetails.MobileNumber.length || !mobileMaxLength.includes(freshLinkingDetails.MobileNumber.length) || !checkMobileNumber(freshLinkingDetails.MobileNumber))}
															onClick={(e) => {
																e.preventDefault(); 
																{ freshLinkingDetails.status == "Linked" ? unlinkPaytmAccount() : paytmVerifyMobile() }
															}}> { freshLinkingDetails.status == "Linked" ? localString('Unlink Wallet') : localString('Link Wallet')}
														</Button>
													} 
													<div className={freshLinkingDetails.status == "Linked" ? "note-block" : "note-block MB"}>
														{
															freshLinkingDetails.status == "Linked" && 
															<p>{localString("Note: In case you want to link your wallet with another mobile number, please unlink first and then link")}</p>
														}
													</div>
													</div>
												</div>
											}
											{
												(!entityDetails || entityDetails.length == 0) && newLinking &&
												<div className='userDetails'>
													<div className='userMobileField'>
														<>
															<IntlTelInputWrapper 
																value={freshLinkingDetails.MobileNumber}
																handleOnChange={(val, countryData) => {
																	setFreshLinkingDetails({...freshLinkingDetails, MobileNumber: val, countryData })
																}}
																onFlagSelect={(countryData)=>{
																	setFreshLinkingDetails({...freshLinkingDetails, countryCode: countryData.iso2 })
																	getCountries(countryData.iso2)
																}}
																intlTelInputClassWrapper= {{
																	formControl: 'form-control--top ',
																	label: 'input__label',
																	containerClass: 'input-container',
																	inputClass: 'input'
																}}
																defaultCountry={countryCode.toLowerCase()}
																maxLength={mobileMaxLength}
															/>
															<div className='walletStatus'>
																<strong>{localString("Status")}</strong>
																<p className="accNotLinked">{localString("Unlinked")}</p>
															</div>
															<div style={{paddingTop:"260px"}}>
																{
																	accessFn('PAYTM_WALLET') &&
																	<Button
																		className="btn servify-blue-Btn"
																		isDisabled = {(!freshLinkingDetails.MobileNumber || !freshLinkingDetails.MobileNumber.length || !mobileMaxLength.includes(freshLinkingDetails.MobileNumber.length) || !checkMobileNumber(freshLinkingDetails.MobileNumber))}
																		onClick={(e) => {
																			e.preventDefault(); 
																			paytmVerifyMobile()
																		}}> 
																		{localString('Link Wallet')}
																	</Button> 
																}
															</div>
														</>
													</div>
												</div>
											}
										</div>												
									</div>
								</div>
								{
									paytmVerifyScreen && 
									<div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 paddingB10'>
										<div className='userCard animated animatedFadeInLeft fadeInLeft'>
											<div className='cardHeader'>
												{localString('Mobile Number Verification')}
											</div>
											<div className='cardBody'>
												<form onSubmit={verifyPaytmOtp}>
													<div>
														<div className="user-info-text">
															<p className='info-msg'>{localString("We have sent an OTP on")}</p>
															<p className='number'>{freshLinkingDetails.MobileNumber}</p>
														</div>
														<div className="text-center">
															<MultiInput
																length={6}
																handleOnChange={(val) => setOtp(val)}
																classNames={{
																	input: "otp-input",
																}}
															/>
															{ (minutes == 0 && seconds == 0) ? <p className='resendBtn' onClick={() => paytmVerifyMobile()}>Resend Verification Code</p> : <p style={{ marginTop: '15px' }}>{getTimerString()}</p>}
														</div>
													</div>
													<div className="" style={{paddingTop: "198px"}}>
														<Button
															handleOnClick={(e)=>{
																verifyPaytmOtp(e)
															}}
															type="submit"
															className="btn servify-blue-Btn"
															text="Verify"
														>
															{localString('Verify')}
														</Button>
													</div>
												</form>
											</div>	
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default PaytmWallet