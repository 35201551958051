import React from 'react';
import {Modal} from 'sfy-react';
import './HandoverModal.scss';
import HandoverModalInput from './HandoverModalInput';

const HandoverModal=(props)=>{
    const {localString, showModal, closeModal, deviceReturnedSave} = props;
		return (
		<div className='handoverDeviceModal'>
            <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
                <Modal.Header>
                    <h4 className="modal-title" style={{float: "left"}}>{localString("Device handover details")}</h4>
                </Modal.Header>
                <Modal.Body>
                    <HandoverModalInput {...props}/>
                </Modal.Body>
                <Modal.Footer >
                    <button type="submit" name="submit" className="btn button-servify pull-right" onClick={(e)=>deviceReturnedSave(e)}>{localString("Save")}</button>
                </Modal.Footer>
            </Modal>
        </div>
	)
}
export default HandoverModal;