import React, { useState,useEffect,useRef } from 'react';
import {Button,Input} from 'sfy-react';
import './UploadClaimDocumentModal.scss';



const ClaimDocumentUpload = (props) => {
	const {localString,publicUrl,selectedFile,sendFile,showUploadButton,acceptFileType,uplodedFile} = props;

	const fileRef = useRef(null);

const imageClick = () =>{
	fileRef.current.click();
}

useEffect(() => {
	if(uplodedFile == ""){
		fileRef.current.value = "";
	}
}, [uplodedFile])

	return (
		<div>
		<div className='upload-claim-section margin-zero'>
             <div className='doc-upload btn'>
				  <div className= 'upload'>
					   <Input
                        reference={fileRef}
                        handleOnChange={selectedFile}
                        inputType='file'
                        otherProps={{ accept: acceptFileType }}
                        />
                    </div>
					<span><img src = {publicUrl +"/imgs/select-any-file.png"} onClick={imageClick}/></span>
			 </div>
        </div>
		{
		showUploadButton &&  
		<div className="sendSection margin-zero">
		<span>{uplodedFile.fileName}</span><Button handleOnClick={sendFile} text={localString("Submit")} type='button' className='btn button-servify' />
		</div>
		}		
</div>
	)
}

export default ClaimDocumentUpload
