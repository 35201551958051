import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import { Input, Button, Loader, MultiInput } from "sfy-react";
import { sortBy } from 'lodash';
import Swal from "sweetalert2";

import CredentialsWrapper from '../../components/CredentialsWrapper/CredentialsWrapper';
import IntlTelInputWrapper from '../../components/IntlTelInputWrapper/IntlTelInputWrapper';
import UserListModal from './UserListModal';
import { displayError } from '../../Utility/Utils';

import './LoginWithOtp.scss';

export default function LoginWithOtp(props) {
    const {
        baseUrl, publicUrl, apiCall
    } = props;

    const [loader, setLoader] = useState(false);
    const [mobileOrEmail, setMobileOrEmail] = useState('');
	const [countryCode, setCountryCode] = useState('IN');
	const [flagData, setFlagData] = useState(null);
	const [mobileMaxLength, setMobileMaxLength] = useState([10]);
    const [disableRequestSubmitBtn, setDisableRequestSubmitBtn] = useState(false);
    const [disableVerifySubmitBtn, setDisableVerifySubmitBtn] = useState(false);
    const [showOtpCard, setShowOtpCard] = useState(false);
    const [otp, setOtp] = useState('');
    const [inputError, setInputError] = useState('');
    const [error, setError] = useState('');
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(60);
	const [isEmail, setIsEmail] = useState(60);
	const [displayUserListModal, setDisplayUserListModal] = useState(false);
	const [userList, setUserList] = useState([]);
	const [authToken, setAuthToken] = useState('');
	const [displayResendBtn, setDisplayResendBtn] = useState(false);
	const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+[.][a-zA-Z]+([.][a-zA-Z]+)*$/; 
	const otpLength = 6;
	const infoText = `We have sent the verification code to your ${isEmail ? 'email ID' : 'mobile number'}`;
	const emailMobileText = (!isEmail && flagData && flagData.dialCode ? ('+' + flagData.dialCode + ' ') : '') + mobileOrEmail

    const checkEmailField = () => {
        if(mobileOrEmail) {
            let reg = /^\d+$/; // Mobile regex
            let isEmailVal = !reg.test(mobileOrEmail);
            setIsEmail(isEmailVal);
            if(isEmailVal) {
                // Email Validation
                if(!emailRegex.test(mobileOrEmail)) {
                    setInputError('Please enter valid email id');
					setDisableRequestSubmitBtn(true);
                }else {
                    setInputError('');
					setDisableRequestSubmitBtn(false);
                }
            } else {
                // mobileNo validation
				if(!mobileMaxLength.includes(mobileOrEmail.length)) {
					setInputError('Please enter valid mobile number');
					setDisableRequestSubmitBtn(true);
				}else {
					setInputError('');
					setDisableRequestSubmitBtn(false);
				}
            }
        }else {
			setInputError('');
		}
    }

    useEffect(() => {
        checkEmailField();
    }, [mobileOrEmail, mobileMaxLength]);

    const getTimerString = () => {
		return minutes === 0 && seconds === 0
				? ""
				: minutes
				+ ":"
				+ (seconds < 10 ? "0" + seconds : seconds)
	}

	const closeUserListModal = () => {
		setDisplayUserListModal(false);
		window.location.reload(true);
	}

	const setCountryValidation = (countryData) => {
		const countryConfig = countryData.countryConfig;
		const AllowedMobileLengths = countryData.AllowedMobileLengths;
		if(countryConfig && countryConfig.length && countryConfig[0].MobileNumber) {
			if(countryConfig[0].MobileNumber && countryConfig[0].MobileNumber.LENGTH){
				let lengthArr = countryConfig[0].MobileNumber.LENGTH && (countryConfig[0].MobileNumber.LENGTH.length > 1) ? countryConfig[0].MobileNumber.LENGTH.map(data=>{
					return parseInt(data)
				}): [countryConfig[0].MobileNumber.LENGTH[0]];
				let dscSort = sortBy(lengthArr).reverse()
				setMobileMaxLength(dscSort);
			}
		}else if(AllowedMobileLengths){
			let mobLength = AllowedMobileLengths.split(',');
			let lengthArr = mobLength.map((item)=> {
				return parseInt(item);
			});
			let dscSort = sortBy(lengthArr).reverse()
			setMobileMaxLength(dscSort)
		}else {
			setMobileMaxLength([10]); // Default value 
		}
	}

    const getCountryDetails = (country) => {
		setLoader(true); 
		let reqObj = {}
		if(country) {
			reqObj.countryCode = country.toUpperCase();
		}
		apiCall({
			url: 'getCountryDetails',
			data: reqObj,
			callBack: (result) => { 
				setLoader(false);
				if(result.success) {
					if(result.data && result.data.countries && result.data.countries.length && country) {
						let countryData = result.data.countries[0];
						setCountryValidation(countryData);
					}else {
						Swal.fire({  
							icon: 'error',  
							text: displayError('No country data found.'),
						});
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: displayError(result),  
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

    useEffect(() => {
        getCountryDetails(countryCode);
    }, []);
    
    const requestOtp = (e) => {
		e.preventDefault();
		if(!mobileOrEmail || disableRequestSubmitBtn) return;
		setLoader(true); 
		let reqObj = {
			countryCode: countryCode.toUpperCase(),
			checkMultipleUser: true
		};
		if(isEmail) {
			reqObj.email = mobileOrEmail;
		}else {
			reqObj.mobileNo = mobileOrEmail;
			reqObj.phoneCode = mobileOrEmail && flagData && flagData.dialCode ? ('+' + flagData.dialCode): undefined
		}
		apiCall({
			url: isEmail ? 'requestOtpEmail' : 'requestOtpMobile',
			data: reqObj,
			callBack: (result) => { 
				setLoader(false);
				if(result.success) {
					setShowOtpCard(true);
					setSeconds(59);
					setError('');
				}else {
					// Swal.fire({  
					// 	icon: 'error',  
					// 	text: displayError(result),  
					// });
					setError(displayError(result));
				}
			},
			errorCB: (err) => setLoader(false)
		});
    } 

    const verifyOtp = (e) => {
		e.preventDefault();
		if(!otp || (otp.length !== otpLength)) return;
		setLoader(true); 
		let reqObj = {
			OTP: otp,
			countryCode: countryCode.toUpperCase(),
			checkMultipleUser: true
		};
		if(isEmail) {
			reqObj.email = mobileOrEmail;
		}else {
			reqObj.mobileNo = mobileOrEmail;
			reqObj.phoneCode = mobileOrEmail && flagData && flagData.dialCode ? ('+' + flagData.dialCode): undefined
		}
		apiCall({
			url: isEmail ? 'verifyOtpEmail' : 'verifyOtpMobile',
			data: reqObj,
			callBack: (result) => { 
				if(result.success) {
					setError('');
					if(result?.data?.userList?.length) {
						setLoader(false);
						setUserList(result.data.userList);
						setDisplayUserListModal(true);
						result?.data?.token && setAuthToken(result.data.token)
					}else {
						window.location.href = '/'
					}
				}else {
					setLoader(false);
					if(result?.reload) {
						Swal.fire({
							icon: 'error',
							text: displayError(result),
						}).then(swalResult => {
							window.location.reload(true);
						});
					}else {
						setError(displayError(result))
					}
				}
			},
			errorCB: (err) => setLoader(false)
		});
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if(minutes > 0) {
                if(seconds > 0) {
                    setSeconds(seconds - 1);
                }else {
                    setSeconds(59);
                    setMinutes(minutes - 1)
                }
            }else {
                if(seconds > 0) {
                    setSeconds(seconds - 1);
                }
            }
        }, 1000);
        if((seconds == 0) && (minutes == 0)) {
            setDisplayResendBtn(true);
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        }
    }, [seconds]);

    return (
        <>
            { loader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
            )}
			<CredentialsWrapper
				title="Login with Verification Code"
				publicUrl={publicUrl}
			>
				{ !showOtpCard ? <form onSubmit={requestOtp}>
					<div className="cred-input-div login-with-otp">
						<div className={`userMobileField ${inputError ? 'errorField' : ''}`}>
							<IntlTelInputWrapper
								value={mobileOrEmail}
								handleOnChange={(val, countryData) => {
									setMobileOrEmail(val);
									setFlagData(countryData);
									setCountryCode(countryData.iso2);
									setError('');
								}}
								onFlagSelect={(data) => {
									setFlagData(data);
									getCountryDetails(data.iso2);
									setCountryCode(data.iso2);
									setError('');
								} }
								intlTelInputClassWrapper={{
									containerClass: 'input-container otp-card',
									inputClass: 'input'
								}}
								separateDialCode={false}
								defaultCountry={'in'}
								// maxLength={mobileMaxLength}
								placeholder={'Email ID/Mobile Number'}
							/>  
							{inputError && <p className="cred-error">{inputError}</p>} 
						</div>
					</div>
					<div className="cred-button-div">
						<Button
							handleOnClick={requestOtp}
							isDisabled={!mobileOrEmail || disableRequestSubmitBtn}
							type="submit"
							classes="button-ghost cred-button button-small"
							text="Send Verification Code"
						/>
					</div>
				</form> :
                <form onSubmit={verifyOtp}>
					<div className="cred-input-div">
                        <div className='cred-info-text'>
							<p className='info-msg'>{infoText}</p>
							<p className='email-number'>{emailMobileText}</p>
						</div>
					</div>
					<div className="cred-input-div mobile-text-center">
                        <MultiInput
                            length={otpLength}
                            handleOnChange={(val) => setOtp(val)}
                            // message={{ value: "Some msg", type: "success" }}
                            classNames={{
                                input: "otp-input",
                                // message: "custom-msg-class",
                            }}
                        />
                		{ (minutes == 0 && seconds == 0) ? <p className='resendBtn' onClick={requestOtp}>Resend Verification Code</p> : <p>{getTimerString()}</p>}
					</div>
					<div className="cred-button-div">
						<Button
							handleOnClick={verifyOtp}
							isDisabled={!otp || (otp.length !== otpLength)}
							type="submit"
							classes="button-ghost cred-button button-small"
							text="Login"
						/>
					</div>
				</form> }
                <div className="text-center" style={{ padding: '5px 0px' }}>
                    <a
                        className="cred-forgot-password-link underline"
                        href={baseUrl}
                    >
                        Login with Password
                    </a>
                </div>
				{error && <p className="cred-error">{error}</p>}
			</CredentialsWrapper>

			{displayUserListModal && 
				<UserListModal 
					apiCall={apiCall}
					isEmail={isEmail}
					userList={userList}
					setLoader={setLoader}
					authToken={authToken}
					setUserList={setUserList}
					setAuthToken={setAuthToken}
					closeUserListModal={closeUserListModal}
					displayUserListModal={displayUserListModal}
					setDisplayUserListModal={setDisplayUserListModal}
				/>
			}
        </>
    );
}