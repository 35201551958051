import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Swal from "sweetalert2";
import { Button, Modal, Loader, Pagination } from 'sfy-react';
import moment from 'moment';
import Papa from 'papaparse';

import CommonTable from '../../../../components/Table/Table';
import { displayError, downloadCSV, encrypt, returnLocalDateTime } from '../../../../Utility/Utils';
import ReportPageHeader from '../../ReportPageHeader';
import FilterModal from '../../../../components/FilterModal/FilterModal';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import './AttendanceReport.scss'

let defaultFilters = {
	shop: '',
	branch: '',
	advisor: '',
	dateRange: {
		startDate: new Date(moment().subtract(1, 'days').startOf('month')),
		endDate: new Date(moment().subtract(1,'days'))
	}
}
export default function AttendanceReport(props) {
	const { accessFn, localString, apiCall, titleHeader } = props;
	const [loader, setLoader] = useState(false);
	const [data, setData] = useState([]);
	const [showModal , setShowModal] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [mainFilters, setMainFilters] = useState(defaultFilters);
	const [branchList, setBranchList] = useState([]);
	const [branchObj, setBranchObj] = useState([]);
	const [shopList, setShopList] = useState([]);
	const [shopObj, setShopObj] = useState([]);
	const [advisorList, setAdvisorList] = useState([]);
	const [advisorObj, setAdvisorObj] = useState([]);
	const [pagination , setPagination] = useState({
		pageNo : 1,
		itemsPerPage : 30,
		count: 0,
		numberOfPages: 0
	});
	const lastDay = moment().subtract(1,'days').format("YYYY-MM-DD");

	const buttonHeader = [
		{
			buttonText: localString('Filter Requests'),
			btnIcon: 'glyphicon glyphicon-filter',
			showButton: true,
			onButtonClick: () => {
				setShowModal(true);
			}
		},
	];

	const getAttendanceReport = (downloadFile) => {
		setLoader(true);
		let reqObj = {
			RetailUserID: filters.advisor ? [advisorObj.RetailUserID] : [],
			ShopCode: filters.shop ? [shopObj.ShopCode] : [],
			Type: "TotalAttendance",
			branchCode: filters.branch ? [branchObj.BranchID] : [],
			eDate: moment(filters.dateRange.endDate).format("YYYY-MM-DD"),
			sDate: moment(filters.dateRange.startDate).format("YYYY-MM-DD"),
		}
		if(!downloadFile) {
			reqObj.Limit = pagination.itemsPerPage;
			reqObj.PageNo = pagination.pageNo;
		}
		apiCall({
			url: 'getAttendanceReport',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data && !downloadFile) {
					setData(result.data.AttendanceDetails);
					setPagination({...pagination, 
						count: result.data && result.data.length,
						numberOfPages: result.data && result.data.numberOfPages
					})
				}else if(result.success && result.data && downloadFile) {
					let arr = result.data.AttendanceDetails
					var csv = Papa.unparse({
						fields: [
							'Name',
							'AdvisorCode',
							'shop',
							'ShopArea',
							'City',
							'Branch',
							'MobileNo',
							'present',
							'leave',
							'Unmarked',
							'weekly off',
							'public holiday',
							'store closed',
							'pending',
							'meeting'
						],
						data: arr,
					});
					let CapsString
					let capital = Papa.parse(csv, {
						beforeFirstChunk: (chunk) => {
							let rows = chunk.split( /\r\n|\r|\n/ );
							let result = [];
							let row = rows[0];
							let a = row.split(',');
							for (let iCount = 0;iCount<a.length;iCount++) {
								let item = a[iCount].replace(/([a-z])([A-Z])/g, '$1 $2');
								result.push(item)
							}
							CapsString = result.toString().toUpperCase();
							rows[0] = CapsString;
							return rows.join("\r\n");
						},
					});
					let csvString = Papa.unparse(capital);
					downloadCSV(csvString, 'AttendanceReport:-'+ moment(filters.dateRange.startDate).format("DD-MM-YYYY") +' - '+ moment(filters.dateRange.endDate).format("DD-MM-YYYY") +'.csv')
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		getAttendanceReport();
	}, [mainFilters, pagination.pageNo])

	const downloadPersonalReport = (RetailUserID, name) => {
		setLoader(true);
		let reObj = {
			RetailUserID: [RetailUserID],
			ShopCode: [],
			Type: "PersonalAttendance",
			branchCode: [],
			eDate: moment(filters.dateRange.endDate).format("YYYY-MM-DD"),
			sDate: moment(filters.dateRange.startDate).format("YYYY-MM-DD"),
		}
		apiCall({
			url: 'getAttendanceReport',
			data: reObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let arr = [];
					result.data.AttendanceDetails && result.data.AttendanceDetails.map(item => {
						let obj = {...item}
						obj.TimeIn = item.TimeIn ? returnLocalDateTime(item.TimeIn, 'HH:mm') : '--';
						obj.TimeOut = item.TimeOut ? returnLocalDateTime(item.TimeOut, 'HH:mm') : '--';
						arr.push(obj);
					});
					let attendanceDetails = Papa.unparse({
						fields: [
							'Date',
							'ShopCode',
							'ShopName',
							'TimeIn',
							'TimeOut',
							'Status',
						],
						data: arr
					});
					// ToDo: Common handler to capitalize first row in reports
					let CapsString;
					let capital = Papa.parse(attendanceDetails, {
						beforeFirstChunk :function(chunk) {
								let rows = chunk.split( /\r\n|\r|\n/ );
								let result = [];
								let row = rows[0];
								let a = row.split(',');
								for (let iCount = 0; iCount < a.length; iCount++) {
									let item = a[iCount].replace(/([a-z])([A-Z])/g, '$1 $2');
									result.push(item)
								}
								CapsString = result.toString().toUpperCase();
								rows[0] = CapsString;
								return rows.join("\r\n");
							},
						});
					let advisorData = result.data.AdvisorDetails;
					let advisorDetails =
						"NAME" +","+(advisorData[0].Name ? advisorData[0].Name : '-') + "\r\n" +
						"BRANCH CODE" +","+(advisorData[0].branchCode ? advisorData[0].branchCode : '-') + "\r\n" +
						"MOBILE NO" +","+(advisorData[0].MobileNo ? advisorData[0].MobileNo : '-') + "\r\n" +
						"SHOP"+","+(advisorData[0].shop ? advisorData[0].shop : "-") + "\r\n" +
						"SHOP AREA" +","+(advisorData[0].ShopArea) + "\r\n" +
						"CITY" +","+(advisorData[0].City ? advisorData[0].City : "-") + "\r\n" +
						"BRANCH" +","+(advisorData[0].Branch ? advisorData[0].Branch :"-");

					var attendanceDetailsCsv = Papa.unparse(capital);
					let csv = advisorDetails + "\r\n" + "\r\n" + attendanceDetailsCsv;
					downloadCSV(csv, name + '.csv')
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const branchMasterGetFilter = () => {
		apiCall({
			url: 'branchMasterGetFilter',
			data: {},
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(val => ({ ...val, value: val.displayDetails}))
					setBranchList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		showModal && branchMasterGetFilter();
	}, [showModal])

	const getAllShopsAndDistinctStates = () => {
		let obj = {
			branchCode: filters.branch ? branchObj.BranchID : null
		}
		apiCall({
			url: 'getAllShopsAndDistinctStates',
			data: obj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data && result.data.shops) {
					let arr = result.data.shops.map(val => ({...val, value: val.displayDetails}))
					setShopList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		showModal && getAllShopsAndDistinctStates();
	}, [showModal, filters.branch])

	const getAdvisorsList = () => {
		let obj = {
			branchCode: filters.branch ? branchObj.BranchID : null,
			resetAction: "location"
		}
		if(filters.shop) {
			obj.PartnerRetailLocationID = shopObj.PartnerRetailLocationID;
		}
		apiCall({
			url: 'getAdvisorsList',
			data: obj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(val => ({...val, value: val.displayDetails}))
					setAdvisorList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		showModal && getAdvisorsList();
	}, [showModal, filters.branch, filters.shop])

	const columns = [
		{
			title: localString("Advisor Name"),
			showHeading: true,
			field: 'Name',
			customCell: (row) => {
				return (
					<>
						<Tooltip
							content={() => {
								return (
									<div>
										<div className='reportTooltipHeader'>{localString('Info')}</div>
										<div className='reportTooltipData'>
											<div>Branch: {row.Branch}</div>
											<div>City: {row.City}</div>
											<div>Shop Area: {row.ShopArea}</div>
											<div>Shop Name: {row.shop}</div>
											<div>Mobile No: {row.MobileNo}</div>
										</div>
									</div>
								)
							}}
							className={{
								tooltipText: 'reportTooltipText'
							}}
							position='right'
						>
							<div style={{ borderBottom: '1px dotted black' }}>{row.Name}</div>
						</Tooltip>
					</>
				);
			}
		},
		{
			title: localString("Advisor Code"),
			showHeading: true,
			field: 'AdvisorCode',
		},
		{
			title: localString("Present"),
			showHeading: true,
			field: 'present',
			customCell: (row) => row?.present || '--',
		},
		{
			title: localString("Weekly Off"),
			showHeading: true,
			field: 'weekly off',
			customCell: (row) => row && row['weekly off'] || '--',
		},
		{
			title: localString("Public Holiday"),
			showHeading: true,
			field: 'public holiday',
			customCell: (row) => row && row['public holiday'] || '--',
		},
		{
			title: localString("Store Closed"),
			showHeading: true,
			field: 'store closed',
			customCell: (row) => row && row['store closed'] || '--',
		},
		{
			title: localString("Leave"),
			showHeading: true,
			field: 'leave',
			customCell: (row) => row?.leave || '--',
		},
		{
			title: localString("Pending"),
			showHeading: true,
			field: 'pending',
			customCell: (row) => row?.pending || '--',
		},
		{
			title: localString("Unmarked"),
			showHeading: true,
			field: 'Unmarked',
			customCell: (row) => row?.Unmarked || '--',
		},
		{
			title: localString("Meeting"),
			showHeading: true,
			field: 'meeting',
			customCell: (row) => row?.meeting || '--',
		},
		{
			title: localString("Download"),
			showHeading: true,
			customCell: (row) => {
				return (
				<>
					{ 
						row.downloadOption ? 
							<div className="download-icon" title="Download Report" onClick={() => downloadPersonalReport(row.RetailUserID, row.Name)} data-toggle="tooltip" data-placement="right">
								<div className="download-image" id=""></div>
							</div> : 
							'--'}
				</>
				);
			}
		},
		{
			title: localString(""),
			showHeading: true,
			customCell: (row) => {
				return (
					<>
						{
							row.downloadOption ? 
								<Button
									type='button'
									classes='button-ghost'
									style={{ width: 'auto' }}
									onClick={() => {
										localStorage.setItem('startDate', filters.dateRange.startDate);
										localStorage.setItem('endDate', filters.dateRange.endDate);
										window.open(`/retailerReports/attendanceReports/view?id=${encrypt(row.RetailUserID)}&name=${encrypt(row.Name)}`);
									}}
								>{localString('View')}</Button> :
								'--'
						}
					</>
				);
			}
		},
	];

	let filterInputList = [
		{
			labelText: localString('Date'),
			dataKey: "dateRange",
			customClass:'col-sm-12 paddingB10',
			showFilter: true,
			type: 'DateRangePicker',
			maxDay: new Date(lastDay),
			oninputFunc: (val) => {
				setFilters({ ...filters, dateRange: val });
			}
		},
		{
			labelText: localString('Branch Code'),
			dataKey: "branch",
			customClass:'col-sm-12 paddingB10',
			showFilter: true,
			type: 'Dropdown',
			dropdownList: branchList,
			oninputFunc: (val) => {
				setBranchObj(val);
				setShopObj(null);
				setAdvisorObj(null);
				setFilters({ ...filters, branch: val.displayDetails, shop: '', advisor: '' })
			}
		},
		{
			labelText: localString('Shop Name'),
			dataKey: "shop",
			customClass:'col-sm-12 paddingB10',
			showFilter: true,
			type: 'Dropdown',
			dropdownList: shopList,
			oninputFunc: (val) => {
				setShopObj(val);
				setAdvisorObj(null);
				setFilters({ ...filters, shop: val.displayDetails, advisor: '' })
			}
		},
		{
			labelText: localString('Advisor'),
			dataKey: "advisor",
			customClass:'col-sm-12 paddingB10',
			showFilter: true,
			type: 'Dropdown',
			dropdownList: advisorList,
			oninputFunc: (val) => {
				setAdvisorObj(val);
				setFilters({ ...filters, advisor: val.displayDetails })
			}
		},
	]

	return (
		<div className='attendanceReport'> 
			{
				loader && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<ReportPageHeader 
				titleHeader={titleHeader}
				localString={localString}
				btnClass='btn button-servify header-btn button-stack text-capital'
				headerButtonArray={buttonHeader} 
				showHeaderContent={true}
				headerContent={() => {
					return (
						<div style={{ display: 'inline-block' }}>
							<span class="header-date-lowercase">
								({moment(filters.dateRange.startDate).format("DD-MM-YYYY")} to {moment(filters.dateRange.endDate).format("DD-MM-YYYY")})
							</span>
							<div className="download-icon" title="Download Report" onClick={() => getAttendanceReport(true)} data-toggle="tooltip" data-placement="right">
								<div className="download-image" id=""></div>
							</div>
						</div>
					);
				}}
			/>
			<FilterModal
				{...props}
				// disableApplyBtn={disableApplyButton}
				filterFunc={(e) => {
					e.preventDefault();
					setMainFilters(filters);
					setPagination({...pagination, pageNo: 1})
					setShowModal(false);
				}}
				filterInputs={filterInputList}
				filterData={filters}
				showModal={showModal}
				closeModal={() => {
					setFilters(mainFilters)
					setShowModal(false);
				}}
				hideClearButton={false}
				clearSearch={() => {
					setFilters(defaultFilters);
					setMainFilters(defaultFilters);
					setPagination({...pagination, pageNo: 1})
				}}
				
			/>
			<CommonTable 
				columns={columns}
				data={data}
				localString={localString}
			/>
			{
				pagination.numberOfPages > 1 && 
					<Pagination
						itemsPerPage={pagination.itemsPerPage}
						pages={pagination.numberOfPages}
						totalItems={pagination.count}
						activePage={pagination.pageNo}
						paginate={(pageNumber) => setPagination({...pagination, pageNo: pageNumber})}
					/> 
			}
		</div>
	);
}