import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Dropdown, Input, Loader, Radio, Multiselect, Checkbox, Modal, ModalHeader, ModalBody, ModalFooter } from 'sfy-react';
import { cloneDeep, sortBy, find, isEqual } from 'lodash';
import Swal from "sweetalert2";

import Header from '../../components/Header/Header';
import VerifyUserDetails from './VerifyUserDetails';
import VerifyPan from './VerifyPan';
import IntlTelInputWrapper from '../../components/IntlTelInputWrapper/IntlTelInputWrapper';
import { checkAlphaNumeric, checkMobileNumber, checkName, checkPANNumber, displayError } from '../../Utility/Utils';

import './User.scss';

const defaultFilters = {
	searchInput: "",
	location: [],
	partnerServiceLocationIDs: [],
	userTypeIDs: [],
	featureRights: [],
	userAccess: {
		sellPLans: false,
		userManagement: false
	},
	tradeInAccess: false
}

const defaultUserAccess = {
	sellPLans: false,
	tradeIn: false,
	userManagement: false
}

const defaultPlanFilters = {
	brandIds: [],
	subCategoryIds: [],
	search: ''
}

const buckets = {
	'1': {
		bucket: 'dashboard',
		displayName: 'Dashboard'
	},
	'2': {
		bucket: 'requests',
		displayName: 'Requests'
	},
	'3': {
		bucket: 'consumers',
		displayName: 'Consumers'
	},
	'4': {
		bucket: 'content',
		displayName: 'Content'
	},
	'5': {
		bucket: 'notifications',
		displayName: 'Notifications'
	},
	'6': {
		bucket: 'coupons',
		displayName: 'Coupons'
	},
	'7': {
		bucket: 'userlist',
		displayName: 'UserList'
	},
	'8': {
		bucket: 'curate',
		displayName: 'Curate'
	},
	'9': {
		bucket: 'diagnosis',
		displayName: 'Diagnosis'
	},
	'10': {
		bucket: 'retailhome',
		displayName: 'Retail home'
	},
	'11': {
		bucket: 'supervisor',
		// displayName: theme? theme.supervisorName : 'Supervisor'
		displayName: 'Supervisor'
	},
	'12': {
		bucket: 'salesman',
		// displayName: theme? theme.salesmanName : 'Salesman'
		displayName: 'Salesman'
	},
	'13': {
		bucket: 'retailLocations',
		displayName: 'Retail Location'
	},
	'14': {
		bucket: 'retailUserList',
		displayName: 'RetailUsers'
	},
	'15': {
		bucket: 'target',
		displayName: 'Targets'
	},
	'16': {
		bucket: 'partnersetting',
		displayName: 'Business Rules'
	},
	'17': {
		bucket: 'regionalManagerUser',
		displayName: 'Regional Manager'
	},
	'18': {
		bucket: 'branchManagerUser',
		displayName: 'Branch Manager'
	},
	'19': {
		bucket: 'distributor',
		displayName: 'Distributor'
	},
	'20': {
		bucket: 'sales',
		displayName: 'Enterprise Sales'
	},
	'21': {
		bucket: 'reseller',
		displayName: 'Reseller'
	},
	'22': {
		bucket: 'announcements',
		displayName: 'Announcements'
	},
	'23': {
		bucket: 'countdetails',
		displayName: 'Count Data'
	},
	'24': {
		bucket: 'approvals',
		displayName: 'Attendance Approval'
	},
	'25': {
		bucket: 'plans',
		displayName: 'Plan Management'
	},
	'26': {
		bucket: 'document',
		displayName: 'View Document'
	},
	'27': {
		bucket: 'retailerRequest',
		displayName: 'Requests'
	},
	'28': {
		bucket: 'salePlans',
		displayName: 'Sell Plans'
	},
	'29': {
		bucket: 'partnerManagement',
		displayName: 'Partner Management'
	},
	'30': {
		bucket: 'logistics',
		displayName: 'Logistics'
	},
	'31': {
		bucket: 'retailerReset',
		displayName: 'Reset'
	},
	'32': {
		bucket: 'retailerReports',
		displayName: 'Retailer Reports'
	},
	'33': {
		bucket: 'engineerNotifications',
		displayName: 'Engineer Notifications'
	},
	'34': {
		bucket: 'logistics',
		displayName: 'Logistics Requests'
	},
	'35': {
		bucket: 'optinrequest',
		displayName: 'Optin Requests'
	},
	'75': {
		bucket: 'mop',
		displayName: 'MOP'
	},
	'40': {
		bucket: 'aprplans',
		displayName: 'APR Plans'
	},
	'50': {
		bucket: 'inventory',
		displayName: 'Inventory'
	},
	'60': {
		bucket: 'cataloguemanagement',
		displayName: 'Catalogue'
	},
	'61': {
		bucket: 'engineerReset',
		displayName: 'Engineer Reset'
	},
	'62': {
		bucket: 'engineerLoginReport',
		displayName: 'Engineer Report'
	},
	'63': {
		bucket: 'servicelocationVisitReport',
		displayName: 'Visit Report'
	},
	'64': {
		bucket: 'mapping',
		displayName: 'Mapping'
	},
	'70': {
		bucket: 'salePlansReport',
		displayName: 'Trade-In'
	},
	'7101': {
		bucket: 'servicerequestdetails',
		displayName: 'Service Requests'
	},
	'72': {
		bucket: 'downloadReports',
		displayName: 'Reports'
	},
	'80': {
		bucket: 'billing',
		displayName: 'Billing'
	},
	'81': {
		bucket: 'advancePay',
		displayName: 'Advance Pay'
	},
	'83': {
		bucket: 'users',
		displayName: 'User Management'
	},
	'85': {
		bucket: 'bulkUpload',
		displayName: 'Bulk Upload'
	}
};

export default function User(props) {
	const {
		localString, apiCall, accessFn, publicUrl
	} = props;

	const qFilter = JSON.parse(props.qFilter);
	if(!(qFilter.hasUnrestrictedAccess || accessFn('PARTNER_USER_MANAGEMENT'))) window.location.href = '/401';

	// const countryCode = (qFilter && qFilter.countrycode && qFilter.countrycode.length) ? qFilter.countrycode[0].toUpperCase() : 'IN';
	const [countryCode, setCountryCode] = useState((qFilter && qFilter.countrycode && qFilter.countrycode.length) ? qFilter.countrycode[0].toUpperCase() : 'IN');

	const [loader, setLoader] = useState(false);
	const [userListLoader, setUserListLoader] = useState(false);
	const [userTypeLoader, setUserTypeLoader] = useState(false);
	const [countryLoader, setCountryLoader] = useState(false);
	const [brandLoader, setBrandLoader] = useState(false);
	const [partnerLoader, setPartnerLoader] = useState(false);

	const [bosUser, setBosUser] = useState(qFilter.hasUnrestrictedAccess || accessFn('BOS_USER') || false);
	// const [bosUser, setBosUser] = useState(accessFn('BOS_USER') || false);
	const [user, setUser] = useState('');
	const [planSearchVal, setPlanSearchVal] = useState('');
	const [promoterSearchText, setPromoterSearchText] = useState('');
	const [partner, setPartner] = useState(null);
	const [partnerSearchVal, setPartnerSearchVal] = useState('');
	const [listType, setListType] = useState('user');
	const [partnerList, setPartnerList] = useState([]);
	const [userList, setUserList] = useState([]);
	const [promoterList, setPromoterList] = useState([]);

	const [displayFilter, setDisplayFilter] = useState(false);
	const [displayPlanFilter, setDisplayPlanFilter] = useState(false);
	const [displayPlans, setDisplayPlans] = useState(false);

	const [filters, setFilters] = useState(defaultFilters);
	const [partnerSearchOptions, setPartnerSearchOptions] = useState([
		{ label: 'PartnerName', value: 'Partner Name' },
		{ label: 'PartnerCode', value: 'Partner Code' },
		{ label: 'GSTNumber', value: 'Partner GST number' }
	]);
	const [partnerSearchObj, setPartnerSearchObj] = useState({ label: 'GSTNumber', value: 'Partner GST number' });
	const [selectedCountryData, setSelectedCountryData] = useState(null);
	const [flagData, setFlagData] = useState(null);

	const [PSLOptions, setPSLOptions] = useState([]);
	const [clonePSLOptions, setClonePSLOptions] = useState([]);
	const [serviceLocations, setServiceLocations] = useState([]);
	const [userTypeOptions, setUserTypeOptions] = useState([]);
	const [featureRightsList, setFeatureRightsList] = useState(qFilter.FeatureRights || []);
	const allowedBuckets = [
		'28', // Sell Plans 
		'70', // Trade-In 
		'83' // User Management
	];
	const [displayBuckets, setDisplayBuckets] = useState([]);
	const [uniqueBucketList, setUniqueBucketList] = useState([]);
	const [selectedBucketList, setSelectedBucketList] = useState([]);
	// const [showTradeInCheckbox, setShowTradeInCheckbox] = useState(false);
	const [userDetails, setUserDetails] = useState({});
	const [existingUserDetails, setExistingUserDetails] = useState({});

	const [planList, setPlanList] = useState([]);
	const [filteredPlanList, setFilteredPlanList] = useState([]);
	const [selectAllPlans, setSelectAllPlans] = useState(false);
	const [selectedPlans, setSelectedPlans] = useState([]);
	const [planFilters, setPlanFilters] = useState(defaultPlanFilters);
	const [plansChanged, setPlansChanged] = useState(false);

	const [subCategoryList, setSubCategoryList] = useState([]);
	const [brandList, setBrandList] = useState([]);
	const [mobileMaxLength, setMobileMaxLength] = useState([10]);
	const [debounceTimeout, setDebounceTimeout] = useState(false);
	const [isPANVerified, setIsPANVerified] = useState(false);
	const [displayVerifyPanCard, setDisplayVerifyPanCard] = useState(false);
	const [panObj, setPanObj] = useState(null);
	const [remainingAttempt, setRemainingAttempt] = useState(null);
	const [consentLoader, setConsentLoader] = useState(false);
	const [consentText, setConsentText] = useState(null);
	const [legalConfig, setLegalConfig] = useState(null);

	const [error, setError] = useState({});
	const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+[.][a-zA-Z]+([.][a-zA-Z]+)*$/; 
	
	const handleFeatureRights = () => {
		let featureRights = qFilter.FeatureRights;
		let buckets = featureRights.map(a => (((a / 100) | 0).toString()));
		let uniBuckets = [...new Set(buckets)];
		let arr = uniBuckets.filter(a => allowedBuckets.includes(a));
		if(arr.includes('28') && !bosUser) getPlanList();
		setUniqueBucketList(arr);
		setDisplayBuckets(arr);
		// setShowTradeInCheckbox(qFilter?.tradeInFeatureRights ? true : false);
	}

	const handlePlansFilter = (e) => {
		let val = e.target.value.toLowerCase();
		setPlanSearchVal(e.target.value);
		if(val) {
			let filteredArr = planList.filter(data => (data?.PlanName.toLowerCase()).includes(val))
			setFilteredPlanList(filteredArr);
		} else{
			setFilteredPlanList(planList);
		}
	}

	const getConsent = () => {
		setConsentLoader(true);
		let reqObj = {
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		}
		apiCall({
			url: 'getApplicationLegalConfigs',
			headers: { languagecode: 'en' },
			data: reqObj,
			callBack: (result) => {
				setConsentLoader(false);
				if(result.success && result.data) {
					if(result.data.LegalConfigs && result.data.LegalConfigs.length) {
						let legalConfigObj = result.data.LegalConfigs.find(obj => {
							return obj.legalConfig && obj.legalConfig.Active && obj.legalConfig.LegalConfigCode && obj.legalConfig.LegalConfigCode === 'USER_PAN_STORAGE'
						});
						if(legalConfigObj) {
							setLegalConfig(legalConfigObj);
							let text = legalConfigObj.legalConfig.Text.replace('@tnc@', '<a href="https://servify.in/termsandconditions/servify" target="_blank">' + localString("Terms of Use") + '</a> ' + localString('and') + ' <a href="https://servify.in/privacy" target="_blank">' + localString("Privacy Policy") + '</a>');
							setConsentText(text);
						}
					} else {
						Swal.fire({  
							icon: 'error',  
							text: localString('Legal Configs not found.'),  
						}); 
					}			
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setConsentLoader(false)
		});
	}

	useEffect(() => {
		getConsent();
	}, [])

	const logConsent = (userID, skipLoader = false) => {
		!skipLoader && setLoader(true);
		let reqObj = {
			userID: userID,
            applicationLegalConfigID: legalConfig.ApplicationLegalConfigID,
            legalConfigText: legalConfig.legalConfig.Text
		}
		apiCall({
			url: 'logConsent',
			data: reqObj,
			callBack: (result) => {
				!skipLoader && setLoader(false);
				if(result.success) {
                    setIsPANVerified(true);
                    setDisplayVerifyPanCard(false);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => !skipLoader && setLoader(false)
		});
	}

	const getPartnerServiceLocations = () => {
		setLoader(true);
		apiCall({
			url: 'getPartnerServiceLocations',
			data: {},
			callBack: (result) => { 
				setLoader(false);
				if(result.success) {
					if(result.data && result.data.length) {
						let arr = result.data.map(a => ({ ...a, value: `${a.ServiceLocationName} - ${a.ServiceLocationCode}` }));
						setPSLOptions(arr);
					} else {
						Swal.fire({  
							icon: 'error',  
							text: localString('No partner service location found'),  
						});
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const getUserTypes = () => {
		setUserTypeLoader(true);
		apiCall({
			url: 'getUserTypes',
			data: {},
			callBack: (result) => { 
				setUserTypeLoader(false);
				if(result.success) {
					if(result.data && result.data.length) {
						let arr = result.data.map(a => ({ ...a, value: a.userType }))
						setUserTypeOptions(arr);
					}
					// else {
					// 	Swal.fire({  
					// 		icon: 'error',  
					// 		text: localString('No partner service location found'),  
					// 	});
					// }
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => setUserTypeLoader(false)
		});
	}

	const getCountries = (country) => {
		setCountryLoader(true);
		let reqObj = {}
		if(country) {
			reqObj.specificCountryCode = country.toUpperCase();
			reqObj.countryConfig = true;
		}
		apiCall({
			url: 'getCountries',
			data: reqObj,
			callBack: (result) => { 
				setCountryLoader(false);
				if(result.success) {
					if(result.data && result.data.countries && result.data.countries.length && country) {
						let countryData = result.data.countries[0];
						setSelectedCountryData(countryData);
						setCountryValidation(countryData);
					}else {
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError('No country data found.')),
						});
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => setCountryLoader(false)
		});
	}

	const getUserList = (filter, locationIds) => {
		let reqObj = {}
		if(filter) {
			if(bosUser) reqObj.serviceLocations = locationIds ? locationIds : serviceLocations;
			reqObj.partnerServiceLocationIDs = filters?.location.map(a => a.PartnerServiceLocationID);
			reqObj.tradeInAccess = filters?.tradeInAccess;
			reqObj.userTypeIDs = filters.userTypeIDs;
			if(selectedBucketList && selectedBucketList.length) {
				let arr = []
				selectedBucketList.map((bucket, k) => {
					featureRightsList.map(item => {
						if((((item / 100) | 0).toString()) == bucket) {
							arr.push(item);
						}
					})
				}) 
				reqObj.featureRights = arr;
			}
		}
		setUserListLoader(true);
		apiCall({
			url: 'getUserList',
			data: reqObj,
			callBack: (result) => { 
				setUserListLoader(false);
				if(result.success && result.data) {
					let arr = [];
					result.data.userList && result.data.userList.length && result.data.userList.map(item => {
						let obj = {...item};
						let val = ''
						if(item.serviceLocations && item?.serviceLocations.length) {
							item.serviceLocations && item.serviceLocations.map((locObj, k) => {
								val = val + `${locObj.serviceLocationName} - ${locObj.serviceLocationCode} ${item.serviceLocations[k + 1] ? ' | ' : ''}`
							});
						}
						obj.locationString = val;
						arr.push(obj);
					});
					setUserList(arr);
				}else {
					setUserList([]);
					// Swal.fire({  
					// 	icon: 'error',  
					// 	text: localString(displayError(result)),  
					// });
				}
			},
			errorCB: (err) => setUserListLoader(false)
		});
	}

	useEffect(() => {
		!bosUser && getPartnerServiceLocations();
		getUserTypes();
		handleFeatureRights();
		getCountries(countryCode);
		!bosUser && getUserList();
	}, []);

	const getPromoterList = () => {
		let reqObj = {
			searchInput: promoterSearchText
		}
		setLoader(true);
		apiCall({
			url: 'getPromoterList',
			data: reqObj,
			callBack: (result) => { 
				setLoader(false);
				if(result.success && result.data) {
					let arr = [];
					result.data && result.data.length && result.data.map(item => {
						let obj = {...item};
						let val = ''
						if(item.serviceLocations && item?.serviceLocations.length) {
							item.serviceLocations && item.serviceLocations.map((locObj, k) => {
								val = val + `${locObj.serviceLocationName} - ${locObj.serviceLocationCode} ${item.serviceLocations[k + 1] ? ' | ' : ''}`
							});
						}
						obj.locationString = val;
						arr.push(obj);
					});
					// setUserList(arr);
					setPromoterList(arr);
				}else {
					setPromoterList([]);
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		if (promoterSearchText.length > 2) {
			debounceTimeout && clearTimeout(debounceTimeout);
			setDebounceTimeout(setTimeout(() => getPromoterList(), 400));
		}
	}, [promoterSearchText]);

	const getPartnerList = () => {
		let reqObj = {}
		reqObj[partnerSearchObj.label] = partnerSearchVal;
		setPartnerLoader(true);
		apiCall({
			url: 'getPartnerList',
			data: reqObj,
			callBack: (result) => { 
				setPartnerLoader(false);
				if(result.success && result.data && result.data.PartnerData) {
					setPartnerList(result.data.PartnerData);
				}else {
					setPartnerList([]);
					// Swal.fire({  
					// 	icon: 'error',  
					// 	text: localString(displayError(result)),  
					// });
				}
			},
			errorCB: (err) => setPartnerLoader(false)
		});
	}

	useEffect(() => {
		if (partnerSearchVal.length > 2) {
			debounceTimeout && clearTimeout(debounceTimeout);
			setDebounceTimeout(setTimeout(() => getPartnerList(), 400));
		}
	}, [partnerSearchVal]);

	const handleAccordian = (val, index, isPromoter) => {
		if(isPromoter) {
			let arr = cloneDeep(promoterList);
			arr[index].display = val;
			setPromoterList(arr);
		}else {
			let arr = cloneDeep(userList);
			arr[index].display = val;
			setUserList(arr);
		}
	}

	const getPlanList = () => {
		setLoader(true);
		let reqObj = {
			'app': 'WebApp',
			'module': 'WebApp'
		};
		apiCall({
			url: 'filterPlansWithMultipleCriteria',
			data: reqObj,
			callBack: (result) => { 
				setLoader(false);
				if(result.success) {
					if(result?.data?.filteredPlans && result?.data?.filteredPlans.length) {
						setPlanList(result.data.filteredPlans);
						setFilteredPlanList(result.data.filteredPlans);
					}else {
						Swal.fire({  
							icon: 'error',  
							text: localString('No Plans found'),  
						});
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const getSelectedPlansDetails = (filter) => {
		if(userDetails?.plans?.length) {
			setLoader(true);
			let reqObj = {
				'app': 'WebApp',
				'module': 'WebApp'
			};
			reqObj.qFilter = {
				plans: filter ? planList.map(a => a.PlanID) : userDetails.plans
			}
			if(filter) {
				reqObj.BrandIDs = planFilters.brandIds
				reqObj.ProductSubCategoryIDs = planFilters.subCategoryIds
			}
			apiCall({
				url: 'filterPlansWithMultipleCriteria',
				data: reqObj,
				callBack: (result) => { 
					setLoader(false);
					if(result.success) {
						if(result?.data?.filteredPlans && result?.data?.filteredPlans.length) {
							let plans = result?.data?.filteredPlans || [];
							if(!filter) {
								if(!bosUser) {
									let arr = [];
									planList.map(planObj => {
										plans.map(obj => {
											if(planObj.PlanCode == obj.PlanCode) {
												arr.push(planObj);
											}
										})
									})
									setSelectedPlans(arr);
								}else {
									setPlanList(plans);
									setFilteredPlanList(plans);
									setSelectedPlans(plans);
								}
							}else {
								setFilteredPlanList(plans);
							}
						}else {
							setFilteredPlanList([]);
							Swal.fire({  
								icon: 'error',  
								text: localString('No Plans found'),  
							});
						}
					}else {
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(result)),  
						});
					}
				},
				errorCB: (err) => setLoader(false)
			})
		} else {
			setSelectedPlans([]);
			if(bosUser) {
				setPlanList([]);
				setFilteredPlanList([]);
			}
		}

	}

	const getPlanSubCategories = () => {
		setLoader(true);
		let reqObj = {
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		}
		apiCall({
			url: 'getPlanSubCategories',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(val => ({ ...val, value: val.ProductSubCategory}))
					setSubCategoryList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const getBrands = () => {
		setBrandLoader(true);
		let reqObj = {};
		reqObj.qFilter = {};
		if (qFilter.brands) {
			reqObj.qFilter.brands = qFilter.brands;
		}
		apiCall({
			url: 'getBrandIDs',
			data: reqObj,
			callBack: (result) => {
				setBrandLoader(false);
				if (result.success && result.data) {
					let arr = result.data.map((v) => ({ ...v, value: v.BrandName, }));
					// if(selectAll) {
					// 	setSelectAll(false);
					// 	setFilters({ ...filters, BrandIDs: result.data.map(val => ({...val, value: val.BrandName})) })
					// }
					setBrandList(arr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setBrandLoader(false),
		});
	};

	useEffect(() => {
		if(displayPlanFilter) {
			if(subCategoryList && !subCategoryList.length) getPlanSubCategories();
			if(brandList && !brandList.length) getBrands();
		}
	}, [displayPlanFilter]);

	const setCountryValidation = (countryData) => {
		const countryConfig = countryData.countryConfig;
		const AllowedMobileLengths = countryData.AllowedMobileLengths;
		if(countryConfig && countryConfig.MobileNumber) {
			if(countryConfig.MobileNumber && countryConfig.MobileNumber.LENGTH){
				let lengthArr = countryConfig.MobileNumber.LENGTH && (countryConfig.MobileNumber.LENGTH.length > 1) ? countryConfig.MobileNumber.LENGTH.map(data=>{
					return parseInt(data)
				}): [countryConfig.MobileNumber.LENGTH[0]];
				let dscSort = sortBy(lengthArr).reverse()
				setMobileMaxLength(dscSort);
			}
		}else if(AllowedMobileLengths){
			let mobLength = AllowedMobileLengths.split(',');
			let lengthArr = mobLength.map((item)=> {
				return parseInt(item);
			});
			let dscSort = sortBy(lengthArr).reverse()
			setMobileMaxLength(dscSort)
		}else {
			setMobileMaxLength([10]); // Default value 
		}
	}

	const validateConsumerDetails = (type, value) => {
        if(type === 'fname') {
            if(!value) {
                setError({ ...error, fname: localString(`This is required field`) })
			}else if(!checkName(value)) {
                setError({ ...error, fname: localString(`Please enter valid name`)  });
            }else {
                setError({ ...error, fname: null  });
            }
		}else if(type === 'lname') {
			if(!value) {
                setError({ ...error, lname: localString(`This is required field`) })
			}else if(!checkName(value)) {
                setError({ ...error, lname: localString(`Please enter valid name`)  });
            }else {
                setError({ ...error, lname: null  });
            }
        }else if(type === 'mobileNo') {
			if(value && !mobileMaxLength.includes(value.length)) {
                setError({ ...error, mobileNo: localString('Please enter valid mobile number') });
            }else if(value && !checkMobileNumber(value)) {
                setError({ ...error, mobileNo: localString('Please enter valid mobile number') });
            }else {
                setError({ ...error, mobileNo: null });
            }
        }else if(type === 'email') {
            if(!value) {
                setError({ ...error, email: localString('This is required field') });
            }else if(value && !emailRegex.test(value)) {
                setError({ ...error, email: localString('Please enter valid email id') });
            }else {
                setError({ ...error, email: null });
            }
		}else if(type === 'panNo') {
			if(value && !checkPANNumber(value)) {
				setError({ ...error, panNo: localString('Please enter valid PAN number') });
			}else {
				setError({ ...error, panNo: null });
			}
		}else if(type === 'username') {
			let usernameRegex = /^\S+$/;
			if(!value) {
                setError({ ...error, username: localString('This is required field') });
			}else if(value && !usernameRegex.test(value)) {
                setError({ ...error, username: localString('Please enter valid username') });
            }else {
                setError({ ...error, username: null });
            }
		}else if(type === 'userType') {
			if(!value) {
                setError({ ...error, userType: localString('This is required field') });
            }else {
                setError({ ...error, userType: null });
            }
		}else if(type === 'location' && user == 'add') {
			if(!value.length) {
                setError({ ...error, location: localString('This is required field') });
            }else {
                setError({ ...error, location: null });
            }
		}
    }

	const validateLocationAndUserType = () => {
		if(user == 'add') {
			if((userDetails?.location?.length > 1) && (userDetails.userTypeID && (userDetails.userTypeID == '1' || userDetails.userTypeID == '2'))) {
				Swal.fire({  
					icon: 'error',  
					text: localString(`${userDetails.userType} can only be mapped to 1 Service Location`),  
				});
				return false
			}else {
				return true
			}
		}else {
			if(userDetails.userTypeID && (userDetails.userTypeID == 1 || userDetails.userTypeID == 2) && userDetails.location?.length > 1) {
				Swal.fire({  
					icon: 'error',  
					text: localString(`${userDetails.userType} can only be mapped to 1 Service Location`),  
				});
				return false
			} else if(userDetails.userTypeID && (userDetails.userTypeID == 1 || userDetails.userTypeID == 3) && !userDetails.location?.length) {
				Swal.fire({  
					icon: 'error',  
					text: localString(`${userDetails.userType} must have atleast 1 Service Location`),  
				});
			} else {
				return true;
			}
		}
		// if(userDetails.userTypeID && userDetails.location && )
	}

	const handleEditUser = (userObj) => {
		let obj = {...userObj};
		let name = userObj?.name?.split(/ (.*)/);
		obj.fname = (name && name.length) ?  name[0] : '';
		obj.lname = (name && name.length && name[1]) ? name[1] : '';
		let arr = [];
		if(userObj.serviceLocations && userObj.serviceLocations.length) {
			PSLOptions && PSLOptions.length && PSLOptions.map(psl => {
				userObj.serviceLocations.map(item => {
					if(item.serviceLocationCode == psl.ServiceLocationCode) {
						arr.push(psl);
					}
				})
			})
		}
		obj.location = arr;
		let featureRights = userObj?.featureRights || [];
		let buckets = featureRights.map(a => (((a / 100) | 0).toString()));
		let uniBuckets = [...new Set(buckets)];
		if(userObj.taxID && userObj.taxIDType && (userObj.taxIDType == 'PAN')) {
			obj.panNo = userObj.taxID;
			setIsPANVerified(true);
		}else {
			setIsPANVerified(false);
		}
		if(bosUser) {
			let displayBuck =  uniBuckets.filter(a => allowedBuckets.includes(a)); // List of allowed buckets
			setDisplayBuckets(displayBuck);
			// setShowTradeInCheckbox(userObj?.tradeInAccess ? true : false);
			obj.selectedBucketList = displayBuck;
		}else {
			obj.selectedBucketList = uniBuckets.filter(a => displayBuckets.includes(a)); // List of allowed buckets and buckets which are mapped to logged in user. 
		}
		if(userObj?.plans?.length) {
			setSelectedPlans(userObj.plans);
		}else {
			setSelectedPlans([]);
		}
		setUserDetails(obj);
		setExistingUserDetails(obj);
		setUser('edit');
		setPlansChanged(false);
		setSelectAllPlans(false);
		setRemainingAttempt(null);
		setPanObj(null);
		setError({});
	}

	const handlePartnerClick = (partnerObj) => {
		setPartner(partnerObj);
		let locationIDs = [];
		if(partnerObj?.ParterServiceLocation?.length) {
			let arr = partnerObj.ParterServiceLocation.map(a => ({...a, value: a.ServiceLocationName}));
			setPSLOptions(arr);
			// setFilters({...filters, location: arr});
			locationIDs = partnerObj.ParterServiceLocation.map(a => a.PartnerServiceLocationID);
			setServiceLocations(locationIDs);
		}
		getUserList(true, locationIDs);
	}

	const addUser = () => {
		let reqObj = {
			name: userDetails.fname + ' ' + userDetails.lname,
			userName: userDetails?.username,
			mobileNumber: userDetails?.mobileNo ? userDetails?.mobileNo : undefined,
			countryCode: (userDetails?.mobileNo && userDetails?.countryCode) ? userDetails.countryCode.toUpperCase() : undefined,
			phoneCode: userDetails?.mobileNo && flagData && flagData.dialCode ? ('+' + flagData.dialCode): undefined,
			email: userDetails?.email,
			PAN: userDetails?.panNo ? userDetails?.panNo : undefined,
			userTypeID: userDetails?.userTypeID ? userDetails?.userTypeID : undefined,
			partnerServiceLocationIDs: (userDetails?.location && userDetails?.location?.length) ? userDetails.location.map(a => a.PartnerServiceLocationID) : [],
			plans: (userDetails?.selectedBucketList?.includes('28') && selectedPlans?.length) ? selectedPlans.map((plan) => plan.PlanID) : [],
			bosUserID: userDetails?.userID ? userDetails?.userID : undefined,
	  	}
		// if(showTradeInCheckbox) reqObj.tradeInAccess = userDetails?.tradeInAccess ? true : false;
		if(bosUser && userDetails.featureRights && userDetails.featureRights.length) { // clone user
			let featureRights = [];
			userDetails.featureRights.map(item => {
				let bucket = ((item / 100) | 0).toString();
				if(!displayBuckets.includes(bucket)) {
					featureRights.push(item);
				}else if(userDetails?.selectedBucketList?.includes(bucket)) {
					featureRights.push(item);
				}
			})
			reqObj.featureRights = [...new Set(featureRights)];
		} else {
			reqObj.featureRights = [];
		}

		if(!bosUser && userDetails.selectedBucketList && userDetails.selectedBucketList.length) {
			let arr = []
			userDetails.selectedBucketList.map((bucket, k) => {
				featureRightsList.map(item => {
					if((((item / 100) | 0).toString()) == bucket) {
						arr.push(item);
					}
				})
			}) 
			reqObj.featureRights = arr;
		}
		reqObj.tradeInAccess = (userDetails?.selectedBucketList?.length && userDetails?.selectedBucketList.includes('70')) ? true : false;
		setLoader(true);
		apiCall({
			url: 'createUser',
			data: reqObj,
			callBack: (result) => { 
				setLoader(false);
				if(result.success) {
					let fname = userDetails.fname;
					setUserDetails({});
					setSelectedPlans([]);
					setSelectAllPlans(false);
					if(result.data && result.data.userID) {
						if(reqObj.PAN) logConsent(result.data.userID, true);
					}
					Swal.fire({
						icon: 'success',  
						title: localString('Congratulations!'),  
						text: localString(fname + ' user has been successfully added.'),
						showCancelButton: true,
						confirmButtonText: localString('Okay'),
						cancelButtonText: localString('Add Another User'),
						allowOutsideClick: false,
						// customClass: 'swal-wide'
					}).then((result) => {
						if (result.isConfirmed) {
							// window.location.reload();
							setDisplayPlans(false);
							setDisplayPlanFilter(false);
							setDisplayFilter(false);
							setUser('');
							getUserList(true);
							setFilteredPlanList(planList);
							setIsPANVerified(false);
							setRemainingAttempt(null);
							setPanObj(null);
						} else {
							setDisplayPlans(false);
							setDisplayPlanFilter(false);
						}
					})
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const checkUpdatedUserDetails = () => {
		let updateObj = {}
		if(existingUserDetails.name !== (userDetails.fname + ' ' + userDetails.lname)) {
			updateObj.name = userDetails.fname + ' ' + userDetails.lname;
		}
		if(existingUserDetails.countryCode?.toLowerCase() !== userDetails?.countryCode?.toLowerCase()) {
			updateObj.countryCode = (userDetails?.mobileNo && userDetails?.countryCode) ? userDetails.countryCode.toUpperCase() : undefined;
		}
		if(existingUserDetails.phoneCode !== ('+' + flagData?.dialCode)) {
			updateObj.phoneCode = userDetails?.mobileNo && flagData && flagData.dialCode ? ('+' + flagData.dialCode) : undefined;
		}
		if(existingUserDetails.mobileNo !== userDetails?.mobileNo) {
			updateObj.mobileNumber = userDetails.mobileNo;
		}
		if(existingUserDetails.userTypeID != userDetails.userTypeID ) {
			updateObj.userTypeID = userDetails.userTypeID;
		}
		if((existingUserDetails.panNo && userDetails.panNo && (existingUserDetails.panNo != userDetails.panNo) || (!existingUserDetails.panNo && userDetails.panNo))) {
			updateObj.PAN = userDetails.panNo ? userDetails.panNo : undefined;
		}
		let plans = (userDetails?.selectedBucketList?.includes('28') && selectedPlans?.length) ? selectedPlans.map((plan) => plan.PlanID) : [];
		// if(!isEqual(existingUserDetails.plans, plans)) {
		if(plansChanged) {
			updateObj.plans = plans;
		}
		let locations = (userDetails?.location && userDetails?.location?.length) ? userDetails.location.map(a => a.PartnerServiceLocationID) : [];
		let existingLocations = existingUserDetails.location.map(a => a.PartnerServiceLocationID);
		if(!isEqual(existingLocations, locations)) {
			updateObj.partnerServiceLocationIDs = locations;
		}
		if(existingUserDetails?.tradeInAccess !== userDetails?.tradeInAccess) {
			updateObj.tradeInAccess = userDetails?.tradeInAccess ? true : false;
		}
		let existingFeatureRights = [];
		if(userDetails.featureRights && userDetails.featureRights.length) {
			userDetails.featureRights.map(item => {
				let bucket = ((item / 100) | 0).toString();
				if(!displayBuckets.includes(bucket)) {
					existingFeatureRights.push(item);
				}
			})
		}
		let arr = []
		if(userDetails.selectedBucketList && userDetails.selectedBucketList.length) {
			userDetails.selectedBucketList.map((bucket, k) => {
				if(bosUser) { // If logged in user is a BOS user then check featureRights of the selected  user BOS user.
					userDetails?.featureRights?.length && userDetails.featureRights.map(item => {
						if((((item / 100) | 0).toString()) == bucket) {
							arr.push(item);
						}
					});
				} else { // If normal partner user is loggedIn then check feature rights of loggedIn user
					featureRightsList.map(item => {
						if((((item / 100) | 0).toString()) == bucket) {
							arr.push(item);
						}
					});
				}
			}) 
		}
		let features = [...existingFeatureRights, ...arr];
		// let sortedFeatures = [...new Set(features)];
		// if(!isEqual(existingUserDetails.featureRights, sortedFeatures)) {
		// 	updateObj.featureRights = sortedFeatures;
		// }
		let existingFeatureArr = [...new Set(existingUserDetails.featureRights)].sort((a, b) => (b - a)).reverse()
		let newFeatureArr = [...new Set(features)].sort((a, b) => (b - a)).reverse();
		if(!isEqual(existingFeatureArr, newFeatureArr)) {
			updateObj.featureRights = newFeatureArr;
		}
		return updateObj;
	}

	const updateUser = () => {
		let updateObj = checkUpdatedUserDetails();
		let reqObj  = {
			userID: userDetails.userID,
			updateObj: updateObj
		};
		setLoader(true);
		apiCall({
			url: 'updateUser',
			data: reqObj,
			callBack: (result) => { 
				setLoader(false);
				if(result.success) {
					let fname = userDetails.fname;
					Swal.fire({  
						icon: 'success',  
						title: localString('Congratulations!'),  
						text: localString(fname + ' user has been successfully updated.'),
						// showCancelButton: true,
						confirmButtonText: localString('Okay'),
						// cancelButtonText: localString('Add Another User'),
						// allowOutsideClick: false,
						// customClass: 'swal-wide'
					}).then((result) => {
						setUserDetails({});
						setExistingUserDetails({});
						setSelectedPlans([]);
						setSelectAllPlans(false);
						setDisplayPlans(false);
						setDisplayPlanFilter(false);
						setDisplayFilter(false);
						setUser('');
						getUserList(true);
						setFilteredPlanList(planList);
						setIsPANVerified(false);
						setRemainingAttempt(null);
						setPanObj(null);
					})
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const deactivateUser = (userObj) => {
		Swal.fire({  
			icon: 'warning',  
			title: localString('Confirm Deactivation'),  
			text: localString('Are you sure you want to deactivate this user account?'),
			showCancelButton: true,
			confirmButtonText: localString('Yes, Continue'),
		}).then((result) => {
			if (result.isConfirmed) {
				setLoader(true);
				apiCall({
					url: 'deactivateUser',
					data: { userID: userObj.userID },
					callBack: (result) => {
						setLoader(false);
						if(result.success) {
							setUserDetails({});
							setSelectedPlans([]);
							setUser('');
							setDisplayPlans(false);
							setDisplayFilter(false);
							setDisplayPlanFilter(false);
							Swal.fire({  
								icon: 'success',  
								title: localString('Deactivation Completed'),  
								text: localString('The requested user account has been deactivated successfully.'),
								confirmButtonText: localString('Okay'),
								// customClass: 'swal-wide'
							}).then((result) => {
								getUserList(true);
							})
						}else {
							Swal.fire({  
								icon: 'error',  
								text: localString(displayError(result)),  
							});
						}
					},
					errorCB: (err) => setLoader(false)
				});
			}
		  })
	}

	const cloneUser = () => {
		let obj = {...existingUserDetails};
		obj.name = '';
		obj.fname = '';
		obj.lname = '';
		obj.mobileNo = '';
		obj.countryCode = '';
		obj.phoneCode = '';
		obj.email = '';
		obj.username = '';
		obj.selectedBucketList = [];
		obj.userType = '';
		obj.userTypeID = '';
		setClonePSLOptions(obj.location);
		obj.location = [];
		// setShowTradeInCheckbox(obj?.tradeInAccess ? true : false);
		obj.tradeInAccess = false;
		setUserDetails(obj);
		setDisplayPlans(false);
		setUser('add');
		setIsPANVerified(false);
		setRemainingAttempt(null);
		setPanObj(null);
		setError({});
	}

	const onBoardUser = (userObj) => {
		if(userObj?.serviceLocations?.length) {
			Swal.fire({  
				icon: 'warning',  
				title: localString('User Mapped with another Partner'),  
				text: localString('Looks like this user is already assigned to another store. Please ask user to contact previous partner/admin to deboard them.'),
				confirmButtonText: localString('Cancel')
			})
		}else {
			handleEditUser(userObj);
		}
	}

	const clearFields = (item) => {
		if(item == 'plans') {
			setDisplayPlans(false);
			setSelectAllPlans(false);
			setPlansChanged(false);
			userDetails?.plans?.length ? setSelectedPlans(userDetails.plans) : setSelectedPlans([]);
		}else if(item == 'bosUser') {
			setPartner(null);
			setPSLOptions([]);
			setServiceLocations([]);
		}else {
			setUserDetails({});
			setError({})
			setSelectedPlans([]);
			setUser('');
			setDisplayPlans(false);
			setDisplayPlanFilter(false);
		}
	}

	const verifyPanNo = () => {
		setLoader(true);
		apiCall({
			url: 'verifyPAN',
			data: {
				PAN: userDetails.panNo
			},
			callBack: (result) => { 
				setLoader(false);
				if(result.success) {
					if(result.data) {
						setPanObj(result.data);
						setDisplayVerifyPanCard(true);
					}
					setRemainingAttempt(null);
				}else {
					setUserDetails({ ...userDetails, panNo: '' });
					setPanObj(null);
					if(!bosUser) {
						if(result.errors && result?.errors?.remainingAttempt) {
							setRemainingAttempt(result.errors.remainingAttempt);
						}else {
							setRemainingAttempt(0);
						}
					}
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const logInvalidAttempt = () => {
		setLoader(true);
		apiCall({
			url: 'logPANVerificationAttempt',
			data: {
				PAN: userDetails.panNo
			},
			callBack: (result) => { 
				setLoader(false);
				if(result.success && result.data) {
					setUserDetails({ ...userDetails, panNo: '' });
					setPanObj(null);
					if(!bosUser) {
						if(result.data) {
							setRemainingAttempt(result.data.remainingAttempt);
						}else {
							setRemainingAttempt(0);
						}
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const isValid = !Object.values(error).some(e => e != null) && 
		(userDetails.fname && checkName(userDetails.fname)) &&
		(userDetails.lname && checkName(userDetails.lname)) &&
		(userDetails.email && emailRegex.test(userDetails.email)) &&
		(!userDetails.panNo || (checkPANNumber(userDetails.panNo) && isPANVerified)) &&
		(!userDetails.mobileNo || (mobileMaxLength.includes(userDetails.mobileNo.length) && checkMobileNumber(userDetails.mobileNo))) &&
		(userDetails.username) &&
		(userDetails.userType) &&
		(user == 'edit' || (user == 'add' && userDetails.location && userDetails.location.length))

	return (
		<>
			{
				(loader || userListLoader || userTypeLoader || countryLoader || brandLoader || partnerLoader || consentLoader) && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<div className='userManagement'>
				{/* ************* Header ************* */}
				<Header 
					showHtmlContent={true}
					htmlContent={() => {
						return (
							<>
								<h3 className='userNav' onClick={() => window.location.reload()}>{localString('User Management')}</h3>
							</>
						)
					}}
				/>
				{/* ************* Body ************* */}
				<div className='container-fluid' style={{ 'paddingTop': '30px' }}>
					<div className='row userRow'>
						{/* ************* BOS user flow => Partner list card ************* */}
						{ bosUser && !partner &&
							<div className={`col-xs-12 col-sm-6 col-md-4 col-lg-3 userCardContainer ${!user ? '' : 'hideCardInMobileView'}`}>
								<div className={`userCard animated animatedFadeInLeft fadeInLeft ${!user ? 'activeUserCard' : ''}`}>
									<div className='cardHeader'>
										{localString('Partner List')}
									</div>
									<div className='cardBody'>
										<div className='partnerSearchText'>
											{localString('You can search Partner by Name, Partner Code, Partner GST number')}    
										</div>
										<div className='partnerSearchBox'>
											<Input 
												value={partnerSearchVal}
												handleOnChange={(e) => setPartnerSearchVal(e.target.value)}
												placeholder={localString(partnerSearchObj.value)}
											>	
												<span style={{ 'marginLeft': '10px' }}>
													<img 
														src={publicUrl+'/imgs/search-icon.png'} 
														width={'20px'} 
													/>
												</span>
												<span className='align-right'>
													<Dropdown
														options={partnerSearchOptions}
														handleOnChange={(val) => {
															setPartnerSearchObj(val);
															setPartnerSearchVal('');
															setPartnerList([]);
														}}
														filterBy='value'
														isSearchable={false}
													/>
												</span>
											</Input>
										</div>
										<div className='partnerList'>
										{
											(partnerList && partnerList.length > 0) ? partnerList.map((partnerObj, k) => {
												return (
													<div className={`accordianCard openCard`}>
														<div className='accordianCardItem'>
															<span className='userListHeader'>{partnerObj?.NameOfFirm}</span>
															<span className='pull-right'>
																{/* { userObj?.display ? <span className='accordArrow' onClick={() => handleAccordian(false, k)} dangerouslySetInnerHTML={{__html: '&#8593'}}></span> : <span className='accordArrow' onClick={() => handleAccordian(true, k)} dangerouslySetInnerHTML={{__html: '&#8595'}}></span> } */}
																<span className='accordArrow'>
																	<img 
																		className='arrowIcons'
																		src={publicUrl+'/imgs/upArrow.svg'} 
																		width={'35px'} 
																		onClick={() => handlePartnerClick(partnerObj)}
																	/>
																</span>
															</span>
														</div>
														<div className='accordianCardItem'>{localString('Owner')}: {partnerObj.OwnerName ? partnerObj.OwnerName : '--'}</div>
														<div className='accordianCardItem'>{localString('Partner Code')}: {partnerObj.PartnerCode ? partnerObj.PartnerCode : ''}</div> 
														<div className='accordianCardItem'>{localString('GST')}: {partnerObj.GSTNumber ? partnerObj.GSTNumber : '--'}</div>
													</div>
												)
											}) : 
											<>{ partnerSearchVal && <h4 className='text-center paddingTB15' style={{ color: '#4c1bc1' }}>{localString('No Partners found')}</h4>}</>
										}
										</div>
									</div>											
								</div>
							</div>
						}
						{/* ************* User/Promoter list card ************* */}
						{ ((bosUser && partner && (user !== 'add')) || (!bosUser && user !== 'add')) && <>
							<div className={`col-xs-12 col-sm-6 col-md-4 col-lg-3 userCardContainer ${!user ? '' : 'hideCardInMobileView'}`}>
								<div className={`userCard animated animatedFadeInLeft fadeInLeft ${!user ? 'activeUserCard' : ''}`}>
									<div className='cardHeader'>
										{localString('Users') + ((listType == 'user' && userList.length > 0) ? (' - ' + userList.length) : (listType == 'promoter' && promoterList.length > 0) ? (' - ' + promoterList.length) : '' )}
										{ (bosUser && !user) && <span className='pull-right crossBtn' onClick={() => clearFields('bosUser') }>x</span> }
									</div>
									<div className='cardBody'>
										<div className='userFilterBtn'>
											<Button
												className={`btn button-servify ${listType == 'user' ? 'activeBtn' : ''}`}
												type='button'
												onClick={() => {
													setListType('user')
												}}
												// isDisabled={true}
											>
												{localString('Users')}
											</Button>
											<Button
												className={`btn button-servify promoterFilterBtn ${listType == 'promoter' ? 'activeBtn' : ''}`}
												type='button'
												onClick={() => {
													setListType('promoter');
													setDisplayFilter(false);
													setUser('');
													setUserDetails({});
													setSelectedPlans([]);
												}}
											>
												{localString('Onboard promoter')}
											</Button>
											{ listType == 'user' && <Button
												className="btn filterBtn"
												type='button'
												onClick={() => setDisplayFilter(!displayFilter)}>
												{/* <img 
													src={publicUrl+'/imgs/filterBtn.svg'} 
													width={'30px'} 
												/> */}
											</Button>}
										</div>
										{ listType == 'user' && <>
											<div className='userList' style={{ height: bosUser ? '400px' : '352px' }}>
												{
													(userList && userList.length > 0) ? userList.map((userObj, k) => {
														return (
															<div className={`accordianCard ${userObj?.display ? 'openCard' : 'closeCard'}`}>
																<div className='accordianCardItem'>
																	<span className='userListHeader'>{userObj?.name || userObj?.username}</span>
																	<span className='pull-right'>
																		{ userObj?.display ?  
																			<span className='accordArrow' onClick={() => handleAccordian(false, k)}>
																				<img 
																					className='arrowIcons'
																					src={publicUrl+'/imgs/upArrow.svg'} 
																					width={'35px'} 
																				/>
																			</span> : 
																			<span className='accordArrow' onClick={() => handleAccordian(true, k)}>
																				<img 
																					className='arrowIcons'
																					src={publicUrl+'/imgs/downArrow.svg'} 
																					width={'35px'} 
																				/>
																			</span> 
																		}
																	</span>
																</div>
																{((userObj.email && !userObj?.emailVerified) || (userObj?.mobileNo && !userObj?.mobileNoVerified)) && 
																	<div className='accordianCardItem' style={{ paddingBottom: userObj?.display ? '0px' : '10px', color: 'red' }}>
																		{localString('Verification Pending')}
																	</div>
																}
																<div className='accordianCardItem' style={{ paddingBottom: userObj?.display ? '0px' : '10px' }}>{localString('Role')}: {userObj.userType ? userObj.userType : '--'}</div>
																<div className='accordianCardItem'>
																	{localString('Phone')}: {`${userObj.phoneCode ? userObj.phoneCode : ''} ${userObj.mobileNo ? userObj.mobileNo : '--'}`}{ (userObj?.mobileNo && userObj?.mobileNoVerified) ? <span className='userVerified'></span> : '' }
																</div> 
																<div className='accordianCardItem'>
																	{localString('Email ID')}: {userObj.email ? userObj.email : '--'}{ (userObj.email && userObj?.emailVerified) ? <span className='userVerified'></span> : '' }
																</div>
																<div className='accordianCardItem'>{localString('Location')}: {userObj.locationString ? userObj.locationString : '--'}</div>
																<div>
																	<div className='row'>
																		<div className='col-xs-6'>
																			{
																				(bosUser && accessFn('UPDATE_USER') && (userDetails?.userID == userObj.userID) && (user == 'edit')) &&
																					<Button
																						className={`btn button-servify w-100`}
																						type='button'
																						onClick={() => cloneUser(userObj)}
																						// isDisabled={true}
																					>
																						{localString('Clone')}
																					</Button>
																			}
																			{
																				(accessFn('UPDATE_USER') && (userDetails?.userID != userObj.userID) || ((userDetails?.userID == userObj.userID) && user == 'verify')) &&
																				<Button
																					className={`btn button-servify w-100`}
																					type='button'
																					onClick={() => {
																						handleEditUser(userObj);
																						setDisplayFilter(false);
																						setDisplayPlans(false);
																					}}
																					// isDisabled={true}
																				>
																					{localString('Edit Profile')}
																				</Button>
																			}
																		</div>
																		<div className='col-xs-6'>
																			{
																				(accessFn('DEACTIVATE_USERS') || (userObj?.userType !== 'Promoter' && accessFn('DEACTIVATE_USERS_EXCEPT_PROMOTER'))) && 
																					<div className='deactivateBtn'>
																						<span onClick={() => deactivateUser(userObj)}>{localString('Deactivate')}</span>
																					</div>
																			}
																			
																		</div>
																		{ ((userDetails?.userID != userObj.userID) || ((userDetails?.userID == userObj.userID) && user == 'edit')) && <div className='verifyBtn'>
																			{
																				((userObj.email && !userObj?.emailVerified) || (userObj?.mobileNo && !userObj?.mobileNoVerified)) && 
																					<Button
																						className={`btn button-servify w-100`}
																						type='button'
																						onClick={() => {
																							setUserDetails(userObj);
																							setExistingUserDetails(userObj);
																							setUser('verify');
																							setDisplayFilter(false);
																							setDisplayPlans(false);
																						}}
																						// isDisabled={true}
																					>
																						{localString('Verify Details')}
																					</Button>
																			}
																			
																		</div> }
																	</div>
																</div>
															</div>
														)
													}) : 
													<h4 className='text-center paddingTB15' style={{ color: '#4c1bc1' }}>{localString('No users found')}</h4>
												}
											</div>
											{ !bosUser && <div style={{ marginTop: '15px'}}>
												<Button
													className="btn servify-blue-Btn"
													type='button'
													onClick={() => {
														setUserDetails({});
														setSelectedPlans([]);
														setDisplayFilter(false);
														setDisplayPlanFilter(false);
														setDisplayPlans(false);
														setUser('add');
														setIsPANVerified(false);
														setRemainingAttempt(null);
														setPanObj(null);
														setError({});
													}}
													isDisabled={!accessFn('CREATE_USER')}
												>
													{localString('Add New Users')}
												</Button>
											</div>}
										</> }
										{ listType == 'promoter' && <div className='searchBox'>
											<Input 
												value={promoterSearchText}
												handleOnChange={(e) => setPromoterSearchText(e.target.value)}
												placeholder={localString('Search by Username')}
											>
												<span className='align-right'>
													<img 
														src={publicUrl+'/imgs/search-icon.png'} 
														width={'20px'} 
													/>
												</span>
											</Input>
											{ !promoterSearchText && <div className='searchText'>
												{localString('You can search Promoters by Username')}    
											</div> }
										</div> }
										{ listType == 'promoter' && <div className='userList promoterList'>
												{
													promoterList && promoterList.length > 0 ? promoterList.map((userObj, k) => {
														return (
															<div className={`accordianCard ${userObj?.display ? 'openCard' : 'closeCard'}`}>
																<div className='accordianCardItem'>
																	<span className='userListHeader'>{userObj?.name || userObj?.username}</span>
																	<span className='pull-right'>
																		{ userObj?.display ?
																			<span className='accordArrow' onClick={() => handleAccordian(false, k, 'promoter')}>
																				<img 
																					className='arrowIcons'
																					src={publicUrl+'/imgs/upArrow.svg'} 
																					width={'35px'} 
																				/>
																			</span> : 
																			<span className='accordArrow' onClick={() => handleAccordian(true, k, 'promoter')}>
																				<img 
																					className='arrowIcons'
																					src={publicUrl+'/imgs/downArrow.svg'} 
																					width={'35px'} 
																				/>
																			</span> 
																		}
																	</span>
																</div>
																<div className='accordianCardItem' style={{ paddingBottom: userObj?.display ? '0px' : '5px' }}>{localString('Role')}: {userObj.userType ? userObj.userType : '--'}</div>
																<div className='accordianCardItem'>{localString('Phone')}: {`${userObj.phoneCode ? userObj.phoneCode : ''} ${userObj.mobileNo ? userObj.mobileNo : '--'}`}</div> 
																<div className='accordianCardItem'>{localString('Email ID')}: {userObj.email ? userObj.email : '--'}</div>
																<div>
																	<div className='row'>
																		<div className='col-xs-6'>
																			{/* {
																				(bosUser && accessFn('UPDATE_USER') && (userDetails?.userID == userObj.userID)) && */}
																				<Button
																					className={`btn button-servify w-100`}
																					type='button'
																					onClick={() => onBoardUser(userObj)}
																					// isDisabled={true}
																				>
																					{localString('Onboard')}
																				</Button>
																			{/* } */}
																		</div>
																		<div className='col-xs-6'>
																			{
																				userObj?.userType !== 'Promoter' && accessFn('DEACTIVATE_USERS_EXCEPT_PROMOTER') && 
																					<div className='deactivateBtn'>
																						<span onClick={() => deactivateUser(userObj)}>{localString('Deactivate')}</span>
																					</div>
																			}
																			
																		</div>
																	</div>
																</div>
															</div>
														)
													}) : 
													<>
														{ promoterSearchText && <h4 className='text-center paddingTB15' style={{ color: '#4c1bc1' }}>{localString('Promoter Not Found')}</h4>}
													</>
												}
										</div> }
									</div>											
								</div>
							</div>
							{ displayFilter && <div className={`col-xs-12 col-sm-6 col-md-4 col-lg-3 userFilterCardContainer ${!user ? '' : 'hideCardInMobileView'}`}>
							{/* { displayFilter && <div className={`col-xs-12 col-sm-6 col-md-4 col-lg-3 userFilterCardContainer hideCardInMobileView`}> */}
								<div className={`userCard userFilterCard`}>
									<div className='filterHeader'>
										<span className=''>{localString('Filters')}</span>
										<span className='pull-right crossBtn' onClick={() => setDisplayFilter(false) }>x</span>
									</div>	
									<div className='filterBody'>
										<div className='locationDropdown'>
											<Multiselect
												options={PSLOptions}
												values={filters.location}
												classObject={{
													chip: 'userChip'
												}}
												onSelect={(val) => {
													val && setFilters({ ...filters, location: [...filters.location, val] });
												}}
												onRemove={(e) => {
													let arr = filters.location.filter(k => k.PartnerServiceLocationID.toString() !== e.target.id.toString());
													setFilters({ ...filters, location: arr });
												}}
												onClearAll={(val) => {
													setFilters({ ...filters, location: [] });
												}}
												filterBy={'value'}
												placeholder={'Search...'}
												labelText={localString('Location')}
												uniqueKey='PartnerServiceLocationID'
												labelFloat
												showValuesInside
												// showTooltip
											/>
										</div>
										{ (userTypeOptions && userTypeOptions.length > 0) && <div className='userType'>
											<div className='bold'>{localString('User Type')}</div>
											<div className='userCheckBoxes'>
												{
													userTypeOptions.map((obj, k) => 
														<Checkbox
															value={filters?.userTypeIDs?.includes(obj.userTypeID) || false}
															name='userType'
															id={obj.userTypeCode}
															handleOnChange={(e) => {
																if (e.target.checked) {
																	setFilters({ ...filters, userTypeIDs: [...filters.userTypeIDs, obj.userTypeID] });
																} else {
																	let arr = filters.userTypeIDs.filter(a => a != obj.userTypeID)
																	setFilters({ ...filters, userTypeIDs: arr });
																}
															}}
														>
															{localString(obj.userType)}
														</Checkbox>)
												}
											</div>
										</div> }										
										{ uniqueBucketList && uniqueBucketList.length > 0 && <div className='userAccess'>
											<div className='bold'>{localString('Access')}</div>
											<div className='userCheckBoxes'>
												{
													uniqueBucketList.map((item, k) => {
														if(buckets[item] && (item != '83' || (item == '83' && accessFn('CAN_GIVE_USER_ACCESS')))) {
															return (
																<Checkbox
																	value={selectedBucketList.includes(item)}
																	name='userAccessFilters'
																	id={'userAccessFilters_' + item}
																	handleOnChange={(e) => {
																		if (e.target.checked) {
																			setSelectedBucketList([...selectedBucketList, item]);
																		} else {
																			let arr = selectedBucketList.filter(a => a != item);
																			setSelectedBucketList(arr);
																		}
																	}}
																>
																	{ (buckets[item]?.displayName) && buckets[item].displayName }
																</Checkbox>
															)
														}
													})
												}
												{/* { accessFn('CAN_GIVE_USER_ACCESS') && showTradeInCheckbox &&
													<Checkbox
														value={filters?.tradeInAccess || false}
														name='tradeInAccessFilter'
														id={'tradeInAccessFilter'}
														handleOnChange={(e) => {
															setFilters({ ...filters, tradeInAccess: e.target.checked });
														}}
													>
														{localString('Trade-In')}
													</Checkbox>
												} */}
											</div>
										</div> }
										<div style={{ marginTop: '35px'}}>
											<div style={{ marginTop: '15px'}}>
												<Button
													className="btn servify-blue-Btn"
													type='button'
													onClick={() => {
														setFilters(defaultFilters);
														setSelectedBucketList([]);
													}}
													// isDisabled={true}
												>
													{localString('Reset')}
												</Button>
											</div>
											<div style={{ marginTop: '15px'}}>
												<Button
													className="btn servify-blue-Btn"
													type='button'
													onClick={() => {
														getUserList(true)
													}}
													// isDisabled={true}
												>
													{localString('Show Results')}
												</Button>
											</div>
										</div>
									   
									</div>						
								</div>
							</div> }
						</> }
						{ user && (user == 'add' || user == 'edit') && <>
							<div className={`col-xs-12 col-sm-6 col-md-4 col-lg-3 userCardContainer ${!displayPlans ? '' : 'hideCardInMobileView'}`}>
								<div className={`userCard animated animatedFadeInLeft fadeInLeft ${ !displayPlans ? 'activeUserCard' : ''}`} >
									<div className='cardHeader'>
										{localString(`${user == 'add' ? 'Add' : 'Edit'} User Details`)}
										{ !displayPlans && <span className='pull-right crossBtn' onClick={() => clearFields() }>x</span> }
									</div>
									<div className='cardBody'>
										<div className='userDetails' style={{ height: displayPlans ? '460px' : '405px' }}>
											<div className={`userInputField ${error.fname ? 'errorField' : ''}`} style={{ marginTop: '6px' }}>
												<Input 
													value={userDetails.fname || ''}
													handleOnChange={(e) => {
														setUserDetails({ ...userDetails, fname: e.target.value });
														validateConsumerDetails('fname', e.target.value);
													}}
													id='fname'
													labelAlign='float'
													label={localString('First Name*')}
													isDisabled={displayPlans}
													otherProps={{
														autocomplete: 'off'
													}}
												/>
												{ error.fname && <div className='errMsg'>{error.fname}</div> }
											</div>
											<div className={`userInputField ${error.lname ? 'errorField' : ''}`}>
												<Input 
													value={userDetails.lname || ''}
													handleOnChange={(e) => {
														setUserDetails({ ...userDetails, lname: e.target.value });
														validateConsumerDetails('lname', e.target.value);
													}}
													id='lname'
													labelAlign='float'
													label={localString('Last Name*')}
													isDisabled={displayPlans}
													otherProps={{
														autocomplete: 'off'
													}}
												/>
												{ error.lname && <div className='errMsg'>{error.lname}</div> }
											</div>
											<div className={`userInputField ${error.email ? 'errorField' : ''}`}>
												<Input 
													value={userDetails.email || ''}
													handleOnChange={(e) => {
														setUserDetails({ ...userDetails, email: e.target.value });
														validateConsumerDetails('email', e.target.value);
													}}
													id='email'
													labelAlign='float'
													label={localString('Email ID*')}
													isDisabled={displayPlans || user == 'edit'}
													otherProps={{
														autocomplete: 'off'
													}}
												/>
												{ error.email && <div className='errMsg'>{error.email}</div> }
											</div>
											<div className={`userMobileField ${error.mobileNo ? 'errorField' : ''}`}>
												<IntlTelInputWrapper 
													value={userDetails.mobileNo || ''}
													handleOnChange={(val, countryData) => {
														setFlagData(countryData);
														setCountryCode(countryData.iso2);
														setUserDetails({ ...userDetails, mobileNo: val, countryCode: countryData.iso2 });
														validateConsumerDetails('mobileNo', val);
													}}
													onFlagSelect={(data) => {
														setFlagData(data);
														getCountries(data.iso2);
														setCountryCode(data.iso2);
														if(data.iso2 == 'in') {
															setUserDetails({ ...userDetails, countryCode: data.iso2, panNo: '' });
														}else {
															setUserDetails({ ...userDetails, countryCode: data.iso2 })
															setIsPANVerified(false);
														}

													} }
													intlTelInputClassWrapper={{
														containerClass: 'input-container',
														inputClass: 'input'
													}}
													disabled={displayPlans}
													separateDialCode={true}
													defaultCountry={userDetails?.countryCode?.toLowerCase() || 'in'}
													maxLength={mobileMaxLength}
												/>   
												{ error.mobileNo && <div className='errMsg'>{error.mobileNo}</div> }
											</div>
											<div className={`userInputField ${error.username ? 'errorField' : ''}`}>
												<Input 
													value={userDetails.username || ''}
													handleOnChange={(e) => {
														let val = e.target.value ? e.target.value.trim() : '';
														setUserDetails({ ...userDetails, username: val });
														validateConsumerDetails('username', val);
													}}
													placeholder={localString('Enter Username')}
													id='username'
													labelAlign='float'
													label={localString('Username*')}
													isDisabled={displayPlans || user == 'edit'}
													otherProps={{
														autocomplete: 'off'
													}}
												/>
												{ error.username && <div className='errMsg'>{error.username}</div> }
											</div>
											{	// Display pancard only for india region
												(!userDetails.countryCode || (userDetails?.countryCode?.toUpperCase() == 'IN')) && <>
													<div className='panNumberDisplayText'>
														{localString('Incentives will be provided for users with valid PAN numbers')}
													</div> 
													<div className={`userInputField ${error.panNo ? 'errorField' : ''}`}>
														<Input 
															value={userDetails.panNo || ''}
															handleOnChange={(e) => {
																let val = e.target.value ? e.target.value.trim().toUpperCase() : '';
																setUserDetails({ ...userDetails, panNo: val });
																validateConsumerDetails('panNo', val);
																setIsPANVerified(false);
															}}
															// placeholder={localString('Enter Username')}
															id='panNo'
															labelAlign='float'
															label={localString('PAN Number')}
															isDisabled={displayPlans || ((user == 'edit') && !(accessFn('UPDATE_USER_PAN_NUMBER') || bosUser)) || (!bosUser && (remainingAttempt == 0)) || isPANVerified}
															maxLength={10}
															otherProps={{
																autocomplete: 'off'
															}}
														/>
														{ error.panNo ? <div className='errMsg'>{error.panNo}</div> :
														// { (userDetails.panNo && checkPANNumber(userDetails.panNo)) &&
															<div className='row' style={{ margin: '0px' }}>
																<div className='col-xs-5 errMsg text-left attemptsLeft'>{ remainingAttempt?.toString() ? `${remainingAttempt} attempts left` : ''}</div>
																<div className='col-xs-7 verifyPanBtnDiv text-right' style={{ padding: '0px' }}>
																	{isPANVerified ? 
																		<Button
																			className="verifyPanBtn"
																			type='button'
																			onClick={() => {}}
																			isDisabled={true}
																		>
																			{localString('Verified')}
																		</Button> :
																		<Button
																			className="verifyPanBtn"
																			type='button'
																			onClick={() => {
																				verifyPanNo();
																			}}
																			isDisabled={!checkPANNumber(userDetails.panNo) || (!bosUser && (remainingAttempt == 0))}
																		>
																			{localString('Verify PAN Number')}
																		</Button>
																	}
																</div>
															</div>
														}
														{(!bosUser && (remainingAttempt == 0)) && <>
															<div className='panNumberDisplayText' style={{ paddingTop: '15px' }}>{localString('Contact Servify support to update your PAN number')}</div>
														</> }
													</div>
												</>
											}
											<div className={`locationDropdown ${error.location ? 'errorField' : ''}`}>
												<Multiselect
													options={(bosUser && user == 'add') ? clonePSLOptions : PSLOptions}
													values={userDetails.location || []}
													classObject={{
														chip: 'userChip'
													}}
													onSelect={(val) => {
														if(val) {
															let arr = (userDetails.location && userDetails.location.length) ? [...userDetails.location, val] : [val]
															setUserDetails({ ...userDetails, location: arr });
															validateConsumerDetails('location', arr);
														}
													}}
													onRemove={(e) => {
														let arr = userDetails.location.filter(k => k.PartnerServiceLocationID.toString() !== e.target.id.toString());
														setUserDetails({ ...userDetails, location: arr });
														validateConsumerDetails('location', arr);
													}}
													onClearAll={(val) => {
														setUserDetails({ ...userDetails, location: [] });
														validateConsumerDetails('location', []);
													}}
													filterBy={'value'}
													placeholder={'Search...'}
													labelText={user == 'add' ? localString('Location*') : localString('Location')}
													uniqueKey='PartnerServiceLocationID'
													labelFloat
													showValuesInside
													isDisabled={displayPlans}
													// showTooltip
												/>
												{ error.location && <div className='errMsg'>{error.location}</div> }
											</div>
											{
												accessFn('ASSIGN_USER_TAG') && 
													<div className={`userDropdownField ${error.userType ? 'errorField' : ''}`}>
														<Dropdown
															id='userTypeDropdown'
															value={userDetails.userType}
															options={userTypeOptions}
															handleOnChange={(val) => {
																setUserDetails({ ...userDetails, userTypeID: val.userTypeID, userType: val.userType });
																validateConsumerDetails('userType', val.userType);
															}}
															filterBy='value'
															labelFloat={true}
															searchText={localString('User Type*')}
															placeholder={localString('User Type*')}
															isDisabled={displayPlans}
														/>
														{ error.userType && <div className='errMsg'>{error.userType}</div> }
													</div>
											}
											{ (displayBuckets && displayBuckets.length > 0) && <div className='userAccess' style={{ paddingLeft: '5px' }}>
												<div className='bold'>{localString('Access')}</div>
												<div className='userCheckBoxes'>
													{
														displayBuckets.map((item, k) => {
															if(buckets[item] && (item != '83' || (item == '83' && accessFn('CAN_GIVE_USER_ACCESS')))) {
																return (
																	<Checkbox
																		value={userDetails?.selectedBucketList?.includes(item) || false}
																		name='userAccess'
																		id={'userAccess_' + item}
																		handleOnChange={(e) => {
																			let arr;
																			if (e.target.checked) {
																				arr = userDetails?.selectedBucketList?.length ? [...userDetails.selectedBucketList, item] : [item];
																				let obj = { ...userDetails, selectedBucketList: arr };
																				if(item == '70') obj.tradeInAccess = true;
																				setUserDetails(obj);
																			} else {
																				arr = userDetails?.selectedBucketList.filter(a => a != item);
																				let obj = { ...userDetails, selectedBucketList: arr };
																				if(item == '70') obj.tradeInAccess = false;
																				setUserDetails(obj);
																			}
																		}}
																		isDisabled={displayPlans}
																	>
																		{ (item && buckets && buckets[item] && buckets[item].displayName) && buckets[item].displayName }
																	</Checkbox>
																)
															}
														})
													}
													{/* { accessFn('CAN_GIVE_USER_ACCESS') && showTradeInCheckbox &&
														<Checkbox
															value={userDetails?.tradeInAccess || false}
															name='tradeInAccess'
															id={'tradeIn'}
															handleOnChange={(e) => {
																setUserDetails({ ...userDetails, tradeInAccess: e.target.checked });
															}}
															isDisabled={displayPlans}
														>
															{localString('Trade-In')}
														</Checkbox>
													} */}
												</div>
											</div> }
										</div>
										{ !displayPlans && <div style={{ marginTop: '15px'}}>
											{
												userDetails?.selectedBucketList?.includes('28') ?
													<Button
														className="btn servify-blue-Btn"
														type='button'
														onClick={() => {
															if(validateLocationAndUserType()) {
																getSelectedPlansDetails();
																setDisplayPlans(true);
																setDisplayPlanFilter(false);
																setPlanFilters(defaultPlanFilters);
																setFilteredPlanList(planList);
																setPlanSearchVal('');
															}
														}}
														isDisabled={!isValid}
													>
														{localString('Continue')}
													</Button> :
													<Button
														className="btn servify-blue-Btn"
														type='button'
														onClick={() => {
															if(validateLocationAndUserType()) {
																if(user == 'add') addUser();
																if(user == 'edit') updateUser();
															}
														}}
														isDisabled={!isValid}
													>
														{localString('Continue')}
													</Button>
											}
										</div> }
									</div>											
								</div>
							</div>
							{
								displayPlans && <>
									<div className={`col-xs-12 col-sm-6 col-md-4 col-lg-3 userCardContainer`}>
										{/* <div className={`userCard animated animatedFadeInLeft fadeInLeft ${ user == 'add' ? 'activeUserCard' : ''}`} > */}
										<div className={`userCard animated animatedFadeInLeft fadeInLeft activeUserCard`} >
											<div className='cardHeader'>
												{localString('Plan Sales Access')}
												<span className='pull-right crossBtn' onClick={() => clearFields('plans')}>x</span>
											</div>
											<div className='cardBody'>
												<div className='searchPlanDiv'>
													<div className='searchPlanBox'>
														<Input 
															value={planSearchVal}
															id={'searchPlans'}
															handleOnChange={handlePlansFilter}
															placeholder={localString('Search')}
														>
															<span className='align-right'>
																<img 
																	src={publicUrl+'/imgs/search-icon.png'} 
																	width={'20px'} 
																/>
															</span>
														</Input>
													</div>
													<div className='pull-right' style={{ paddingTop: '4px' }}>
														<Button
															className="btn filterBtn"
															type='button'
															onClick={() => setDisplayPlanFilter(!displayPlanFilter)}
														/>
													</div>
												</div>
												{/* <div className='bold'>{localString('Plans')}</div> */}
												{ (filteredPlanList && filteredPlanList.length > 0) && <div className='userCheckBoxes planList'>
													{
														filteredPlanList && filteredPlanList.length > 0 && <>
															<Checkbox
																value={selectAllPlans}
																name='selectAllPLans'
																id='selectAllPLans'
																handleOnChange={(e) => {
																	setPlansChanged(true);
																	setSelectAllPlans(e.target.checked);
																	let arr;
																	if (e.target.checked) {
																		arr = planList.map(a =>  a);
																	} else {
																		arr = [];
																	}
																	// setUserDetails({ ...userDetails, plans: arr });
																	setSelectedPlans(arr);
																}}
															>
																{localString('Select All')}
															</Checkbox>
															{
																filteredPlanList.map((plan, k) => {
																	return (
																		<Checkbox
																			value={find(selectedPlans, {PlanCode: plan.PlanCode}) ? true : false}
																			name={plan.PlanCode}
																			id={plan.PlanCode}
																			handleOnChange={(e) => {
																				let arr;
																				if (e.target.checked) {
																					arr = selectedPlans.length ? [...selectedPlans, plan] : [plan];
																				} else {
																					setSelectAllPlans(false);
																					arr = selectedPlans.filter(a => a.PlanCode != plan.PlanCode);
																				}
																				// setUserDetails({ ...userDetails, plans: arr });
																				setSelectedPlans(arr);
																				setPlansChanged(true);
																			}}
																		>
																			{localString(plan.PlanName)}
																		</Checkbox>
																	)
																})
															}
														</>
													}
												</div>}
												{ (filteredPlanList && filteredPlanList.length == 0) && <div className='noPlansFound'>
													<h4 className='text-center paddingTB15' style={{ color: '#4c1bc1' }}>{localString('No Plans found')}</h4>
												</div>}
												
												<div style={{ marginTop: '15px'}}>
													<Button
														className="btn servify-blue-Btn"
														type='button'
														onClick={() => {
															if(user == 'add') addUser();
															if(user == 'edit') updateUser();
														}}
														isDisabled={!selectedPlans.length}
														>
														{localString(`Continue`)}
														{/* { user == 'add' ? localString(`Add User`) : localString(`Continue`)} */}
													</Button>
												</div>
												<div style={{ marginTop: '10px'}}>
													<Button
														className="btn servify-blue-Btn"
														type='button'
														onClick={() => clearFields('plans')}
														// isDisabled={true}
														>
														{localString('Cancel')}
													</Button>
												</div>
											</div>											
										</div>
									</div>
									{ displayPlanFilter && <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 userFilterCardContainer'>
										<div className={`userCard userFilterCard`}>
											<div className='filterHeader'>
												<span className=''>{localString('Filters')}</span>
												<span className='pull-right crossBtn' onClick={() => setDisplayPlanFilter(false) }>x</span>
											</div>	
											<div className='filterBody'>
												<div className='plansFilter'>
													{ (subCategoryList && subCategoryList.length > 0) && <div style={{ marginTop: '10px' }}>
														<div className='bold'>{localString('Category')}</div>
														<div className='userCheckBoxes userCategory'>
															{
																subCategoryList.map((obj, k) => 
																	<Checkbox
																		value={planFilters.subCategoryIds.includes(obj.ProductSubCategoryID)}
																		name='subCategory'
																		id={`subCategory_${obj.ProductSubCategoryID}`}
																		handleOnChange={(e) => {
																			if (e.target.checked) {
																				setPlanFilters({ ...planFilters, subCategoryIds: [...planFilters.subCategoryIds, obj.ProductSubCategoryID] });
																			} else {
																				let arr = planFilters.subCategoryIds.filter(a => a != obj.ProductSubCategoryID)
																				setPlanFilters({ ...planFilters, subCategoryIds: arr });
																			}
																		}}
																	>
																		{localString(obj.ProductSubCategory)}
																	</Checkbox>)
															}
														</div>
													</div> }
													
													{ brandList && brandList.length > 0 && <div style={{ marginTop: '10px' }}>
														<div className='bold'>{localString('Brand')}</div>
														<div className='userCheckBoxes userBrands'>
															{
																brandList.map((obj, k) => {
																	return (
																		<Checkbox
																			value={planFilters.brandIds.includes(obj.BrandID)}
																			name='brands'
																			id={`brand_${obj.BrandID}`}
																			handleOnChange={(e) => {
																				if (e.target.checked) {
																					setPlanFilters({ ...planFilters, brandIds: [...planFilters.brandIds, obj.BrandID] });
																				} else {
																					let arr = planFilters.brandIds.filter(a => a != obj.BrandID)
																					setPlanFilters({ ...planFilters, brandIds: arr });
																				}
																			}}
																		>
																			{localString(obj.BrandName)}
																		</Checkbox>
																	)
																})
															}
														</div>
													</div> }
												</div>
												<div style={{ marginTop: '20px'}}>
													<div style={{ marginTop: '15px'}}>
														<Button
															className="btn servify-blue-Btn"
															type='button'
															onClick={() => {
																setPlanFilters(defaultPlanFilters)
															}}
														>
															{localString('Reset')}
														</Button>
													</div>
													<div style={{ marginTop: '15px'}}>
														<Button
															className="btn servify-blue-Btn"
															type='button'
															onClick={() => {
																getSelectedPlansDetails(true)
															}}
														>
															{localString('Show Results')}
														</Button>
													</div>
												</div>
											
											</div>								
										</div>
									</div> }
								</>
							}
						</>}
						{ user && (user == 'verify') && <>
							<VerifyUserDetails 
								{...props}
								setUser={setUser}
								userList={userList}
								setLoader={setLoader}
								setUserList={setUserList}
								clearFields={clearFields}
								userDetails={userDetails}
								displayPlans={displayPlans}
								setUserDetails={setUserDetails}
								setExistingUserDetails={setExistingUserDetails}
							/>
						</> }
						{
							displayVerifyPanCard &&
								<VerifyPan 
									{...props}
									user={user}
									panObj={panObj}
									setLoader={setLoader}
									logConsent={logConsent}
									consentText={consentText}
									userDetails={userDetails}
									legalConfig={legalConfig}
									setUserDetails={setUserDetails}
									setIsPANVerified={setIsPANVerified}
									logInvalidAttempt={logInvalidAttempt}
									displayVerifyPanCard={displayVerifyPanCard}
									setDisplayVerifyPanCard={setDisplayVerifyPanCard}
								/>
						}
					</div>
				</div>
			</div>
		</>
	)
}