import React from "react";
import "./styles/App.scss";
import "./styles/Override.scss";
import Announcements from "./Pages/Announcements/Announcements";
import AttendanceReport from './Pages/RetailerReports/Advisor/Attendance/AttendanceReport';
import ViewPlanSalesReport from './Pages/PlanSalseReport/ViewPlanSalesReport';
import Login from './Pages/Login/Login';
import LoginWithOtp from "./Pages/LoginWithOtp/LoginWithOtp";
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import Diagnosis from './Pages/Diagnosis/Diagnosis';
import DiagnosisResult from './Pages/Diagnosis/DiagnosisResult';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import Wrapper from './components/Wrapper/Wrapper.js';
import ViewPlanManagement from './Pages/PlanManagement/ConsumerPlanList/ConsumerPlanLIstPage';
import ViewConsumerPlanDetailsPage from './Pages/PlanManagement/ViewConsumerPlanDetails/ViewConsumerPlanDetailsPage';
import Reports from './Pages/Reports/Report';
import CatalogueManagement from './Pages/CatalogueManagement/CatalogueManagement'
import ConsumerList from "./Pages/Consumer/ConsumerList/ConsumerList";
import ConsumerViewDetailsPage from "./Pages/Consumer/ViewConsumerDetails/ConsumerViewDetailsPage";
import LogisticsList from "./Pages/Logistics/LogisticsList/LogisticsList";
import ViewLogisticsDetailsPage from "./Pages/Logistics/ViewLogisticsDetails/ViewLogisticsDetailsPage";
import CreateLogistics from "./Pages/Logistics/CreateLogistics/CreateLogistics";
import RetailerReports from "./Pages/RetailerReports/RetailerReports";
import AttendanceReportView from "./Pages/RetailerReports/Advisor/Attendance/AttendanceReportView";
import SalesAndPerformanceReport from "./Pages/RetailerReports/Advisor/SalesAndPerformance/SalesAndPerformanceReport";
import SalesAndPerformanceReportView from "./Pages/RetailerReports/Advisor/SalesAndPerformance/SalesAndPerformanceReportView";
import TlAttendanceReport from "./Pages/RetailerReports/TeamLead/Attendance/TlAttendanceReport";
import TlAttendanceReportView from "./Pages/RetailerReports/TeamLead/Attendance/TlAttendanceReportView";
import DisplayShareReport from "./Pages/RetailerReports/Advisor/DisplayShare/DisplayShareReport";
import TlDisplayShareReport from "./Pages/RetailerReports/TeamLead/DisplayShare/TlDisplayShareReport";
import RetailerReset from "./Pages/RetailerReset/RetailerReset";
import ConsumerServiceRequest from "./Pages/Request/ConsumerServiceRequest";
import ViewServiceRequest from "./Pages/Request/ViewRequest/ViewServiceRequest";
import RaiseRequest from "./Pages/RaiseRequest/RaiseRequest";
import BulkUpload from "./Pages/SellPlans/BulkUpload";
import AttendanceApproval from "./Pages/RetailerAttendaceApproval/AttendanceApproval";
import AdvancePay from "./Pages/AdvancePay/advancePay";
import User from "./Pages/User/User";
import CaseManager from './Pages/CaseManager/CaseManager';
import RuleManager from "./Pages/RuleManager/RuleManager";
import RequestBulkUpload from "./Pages/RequestBulkUpload/RequestBulkUpload";
import ViewCase from './Pages/CaseManager/ViewCase/ViewCase';

import accessFunction from "./Utility/featureRights";
import languageTranslate from "./Utility/translate";
import CommonApiCall from "./Utility/ApiCall";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import TransactionSummary from "./Pages/PlanSales/TransactionSummary/TransactionSummary";
import PlanSalesHome from "./Pages/PlanSales/PlanSalesHome/PlanSalesHome";
import TransactionSummary from "./Pages/PlanSales/TransactionSummary/TransactionSummary";


// import "./App.scss";
import Paytm from "./Pages/Paytm/PaytmWallet";
import EmailTemplate from "./Pages/Request/ViewRequest/NavBar/RedesignCommunicationHistory/EmailTemplate";
import PlanRenewal from "./Pages/PlanRenewal/PlanRenewal";
import PaymentLinkTransaction from "./Pages/PlanSales/PaymentLinkTransaction/PaymentLinkTransaction";
import ContractDetails from "./Pages/PlanSales/ContractDetails/ContractDetails";
import PlanSales from "./Pages/PlanSales/PlanSales";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const App = (props) => {
	const accessFn = accessFunction(props.accessArray);
	const localString = languageTranslate(props.languagecode);
	const apiCall = CommonApiCall(props);
	const wrapperContentClass = (props.routeSelection === 'diagnosis' || window.location.pathname === "/plansales/home" || window.location.pathname === '/servicerequests/raise' || window.location.pathname === '/plansales/planRenewal' ) ? 'no-background' : '';
	
	
	return (
		<Router>
			<Wrapper {...props} accessFn={accessFn} localString={localString} wrapperContentClass={wrapperContentClass} >
				<Switch>
					<Route exact path="/">
						<Login {...props} />
					</Route>
					<Route path="/loginWithOtp">
						<LoginWithOtp {...props} apiCall={apiCall}/>
					</Route>
					<Route path="/forgotpassword">
						<ForgotPassword {...props} />
					</Route>
					<Route path="/resetpassword">
						<ResetPassword {...props} />
					</Route>
					<Route path="/passwordreset">
						<ResetPassword {...props} />
					</Route>
					<Route path="/sales/brandreport">
						<ViewPlanSalesReport {...props} />
					</Route>
					<Route path="/plans/consumer/view">
						<PlanConsumerWrapper
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
						/>
					</Route>
					<Route path="/diagnosis">
						<Diagnosis {...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
						/>
					</Route>
					<Route path="/consumers/diagnosis">
						<DiagnosisResult {...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
						/>
					</Route>
					<Route exact path="/consumers">
						<ConsumerList
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/casemanager">
						<CaseManager
						{...props}
						accessFn={accessFn}
						localString={localString}
						apiCall={apiCall}
					/>
					</Route>
					<Route exact path="/rulemanager">
						<RuleManager
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
						/>
					</Route>
					<Route exact path="/casemanager/view">
						<ViewCase
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/consumers/view">
						<ConsumerViewDetailsPage
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route path="/reports">
						<Reports
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
						/>
					</Route>
					<Route exact path="/cataloguemanagement">
						<CatalogueManagement
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
						/>
					</Route>
					<Route exact path="/logistics">
						<LogisticsList
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
						/>
					</Route>
					<Route exact path="/logistics/view">
						<ViewLogisticsDetailsPage
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							usequery={useQuery}
						/>
					</Route>
					<Route exact path="/logistics/create">
						<CreateLogistics
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							usequery={useQuery}
						/>
					</Route>
					<Route exact path="/announcements">
						<Announcements
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/retailerReports">
						<RetailerReports
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/retailerReports/attendanceReports">
						<AttendanceReport
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/retailerReports/attendanceReports/view">
						<AttendanceReportView
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/retailerReports/salesReports">
						<SalesAndPerformanceReport
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/retailerReports/salesReports/view">
						<SalesAndPerformanceReportView
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/retailerReports/tlattendance">
						<TlAttendanceReport
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/retailerReports/tlattendanceView/view">
						<TlAttendanceReportView
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/retailerReports/displayshare">
						<DisplayShareReport
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
							type={2}
						/>
					</Route>
					<Route exact path="/retailerReports/competitionreports">
						<DisplayShareReport
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
							type={1}
						/>
					</Route>
					<Route exact path="/retailerReports/tldisplay">
						<TlDisplayShareReport
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/retailerReset">
						<RetailerReset
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/servicerequests/raise">
						<RaiseRequest
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/approvals">
						<AttendanceApproval
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
						/>
					</Route>
					<Route exact path="/servicerequests">
						<ConsumerServiceRequest
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/servicerequests/view">
						<ViewServiceRequest
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/plansales/bulkUpload">
						<BulkUpload
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/servicerequests/bulkUpload">
						<RequestBulkUpload
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/advancePay">
						<AdvancePay
							{...props}
							apiCall={apiCall}
							accessFn={accessFn}
							localString={localString}
						/>
					</Route>
					<Route exact path="/users">
						<User
							{...props}
							apiCall={apiCall}
							accessFn={accessFn}
							localString={localString}
						/>
					</Route>
					<Route exact path="/plansales/home">
						<PlanSalesHome
							{...props}
							apiCall={apiCall}
							accessFn={accessFn}
							localString={localString}
							useQuery={useQuery}
						/>
						</Route>
					<Route exact path="/paytm">
						<Paytm
							{...props}
							apiCall={apiCall}
							accessFn={accessFn}
							localString={localString}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/plansales/report">
						<TransactionSummary
							{...props}
							apiCall={apiCall}
							accessFn={accessFn}
							localString={localString}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/plansales/paymentlink">
						<PaymentLinkTransaction
							{...props}
							apiCall={apiCall}
							accessFn={accessFn}
							localString={localString}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/plansales/viewcontract">
						<ContractDetails
							{...props}
							apiCall={apiCall}
							accessFn={accessFn}
							localString={localString}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/plansales/planRenewal">
						<PlanRenewal
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/plansales">
						<PlanSales
							{...props}
							accessFn={accessFn}
							localString={localString}
							apiCall={apiCall}
							useQuery={useQuery}
						/>
					</Route>
					<Route exact path="/servicerequests/emailSmsPreview">
						<EmailTemplate
							{...props}
							apiCall={apiCall}
							accessFn={accessFn}
							localString={localString}
							useQuery = {useQuery}
						/>
					</Route>
				</Switch>
			</Wrapper>
		</Router>
	);
};
const PlanConsumerWrapper = (props) => {
	let query = useQuery();
	let spid = query.get("spid");
	return (
		<>
			{spid ? (
				<ViewConsumerPlanDetailsPage
					{...props}
					spid={spid}
				></ViewConsumerPlanDetailsPage>
			) : (
				<ViewPlanManagement {...props}></ViewPlanManagement>
			)}
		</>
	);
};

export default App;
