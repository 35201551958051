import React,{useState, useEffect} from 'react';
import './PlanNavBar.scss';
import {filter, isEmpty} from 'lodash';
import PlanConsumerDetails from '../PlanConsumerDetails';
import { returnLocalDateTime, displayError, checkEmail, checkMobile, returnLocalTimeForADay, checkProductUniqueID, displayAmount } from '../../../../Utility/Utils';
import ActionButtonList from '../../../../components/ActionButtonsList/ActionButtonList';
import PlanViewConsumerDetails from './PlanViewConsumerDetails';

const PlanNavBar = (props) => {
	const { 
		localString, consumerDetails, buttonArray, baseUrl, getSoldPlanDocumentsWithImages, soldPlanDocuments, 
		paymentDetails, partnerServiceLocation, loggedInUserSpicificCountryData
	} = props;
	const [planDetails, setPlanDetails] = useState([])
	const [coverageDetails, setCoverageDetails] = useState([])
	const [deviceDetails, setDeviceDetails] = useState([])
	const [claimDetails, setClaimDetails] = useState([])
	const [addressDetails, setAddressDetails] = useState([])
	const [selectedID, setSelectedID] = useState("planDetails")
	const [searchValidation, setSearchValidation] = useState(true);
	const [isDocReceived, setIsDocReceived] = useState(false);
	const [soldPlanStatusObj, setSoldPlanStatusObj] = useState({})
	const [deviceDocuments, setDeviceDocuments] = useState({
		firstAttempt: [],
		secondAttempt: [],
		otherDocs: []
	})

	const [filterData, setFilterData] = useState({
		IMEI:""
	})

	const filterInputs = [{
		labelText: localString('Search by IMEI/Serial Number') + ':',
		dataKey: "IMEI",
		type: "input",
		showFilter: true,
		placeholder: "",
		maxLength:"30",
		customClass: "search-wrapper col-xs-12 request",
		oninputFunc: (val) => {
			setFilterData({ ...filterData, ['IMEI'] : val  });
		}
	}]

	useEffect(() => {
		soldPlanDocuments 
		? (soldPlanDocuments.length > 0) && customisedImagesFormat(soldPlanDocuments)
		: (isEmpty(soldPlanDocuments) || !soldPlanDocuments.length || soldPlanDocuments.length == 0) && setIsDocReceived(true)
	}, [soldPlanDocuments])
	
	useEffect(() => {
		setConsumersData()
	}, [consumerDetails, filterData.IMEI, partnerServiceLocation])

	useEffect(() => {
		if(checkProductUniqueID(filterData.IMEI) && filterData.IMEI.length >= 5){
			setSearchValidation(false)
		}else{
			setSearchValidation(true)
		}
	}, [filterData.IMEI])

	let [activeNav, setActiveNav] = useState({
		planDetails:true,
		coverageDetails:false,
		deviceDetails:false,
		paymentDetails:false,
		claimHistory:false,
		documents:false,
		addressDetails:false
	})

	const bgColor = {
		'On Hold': '#FDE4E1',
		'Test': '#E7F2FA',
		'Downgraded': '#F2F2F2',
		'Cancelled': '#FDE4E1',
		'Expired': '#FDE4E1',
		'Invalid': '#FDE4E1',
		'Purchased': '#FCF0DF',
		'Active': '#E6F3E5',
		'Registered': '#FCF0DF',
		'Cancellation Initiated': '#FDE4E1',
		'Refunded': '#FDE4E1',
		'Upgraded': '#EFEAFC',
		'Enrolled': '#FCF0DF',
		'Diagnosed': '#FCF0DF',
		'Benefits Availed': '#F2F2F2',
		'Verification Pending': '#FCF0DF',
		'Bundled': '#EFEAFC',
		'Under Review': '#FCF0DF',
		'Payment Pending': '#FCF0DF',
		'Under Cancellation': '#FDE4E1',
		'Protected': '#E6F3E5',
		'Not protected': '#FDE4E1',
		'Protection not active': '#EFEAFC',
		'Protection on hold': '#FCF0DF',
		'Replaced': '#F2F2F2',
		'Reactivation_Diagnostics Pending': '#FCF0DF',
		'Reactivation_Under Review': '#FCF0DF',
		'Reactivation_Payment Pending':'#FCF0DF'
	}

	const textColor = {
		'On Hold': '#F54336',
		'Test': "#0073E7",
		'Downgraded': "#393939",
		'Cancelled': "#F54336",
		'Expired': "#F54336",
		'Invalid': "#F54336",
		'Purchased': "#FF9300",
		'Active': "#4EAF51",
		'Registered': "#FF9300",
		'Cancellation Initiated': "#F54336",
		'Refunded': "#F54336",
		'Upgraded': "#410099",
		'Enrolled': "#FF9300",
		'Diagnosed': "#FF9300",
		'Benefits Availed': "#393939",
		'Verification Pending': "#FF9300",
		'Bundled': "#410099",
		'Under Review': "#FF9300",
		'Payment Pending': "#FF9300",
		'Under Cancellation': '#F54336', 
		'Protected': '#4EAF51',
		'Not protected': '#F54336',
		'Protection not active': '#410099',
		'Protection on hold': '#FF9300',
		'Replaced': '#393939',
		'Reactivation_Diagnostics Pending': '#FF9300',
		'Reactivation_Under Review': '#FF9300',
		'Reactivation_Payment Pending':'#FF9300'
	}

	const paymentStatus = [{
        statusID: 0,
        statusText:"Billing Scheduled",
        statusTextColor:"#FF9300",
        statusBackgroundColor:"#FCF0DF"
    },
    {
        statusID: 1,
        statusText:"Payment Completed",
        statusTextColor:"#4EAF51",
        statusBackgroundColor:"#E6F3E5"
    },
    {
        statusID: 2,
        statusText:"Missed Payment",
        statusTextColor:"#F54336",
        statusBackgroundColor:"#FDE4E1"
    },
    {
        statusID: -2,
        statusText:"Payment Failed",
        statusTextColor:"#F54336",
        statusBackgroundColor:"#FDE4E1"
    },
    {
        statusID: -3,
        statusText:"Billing Stopped",
        statusTextColor:"#393939",
        statusBackgroundColor:"#F2F2F2"
    },
    {
        statusID: 4,
        statusText:"Billing Stopped",
        statusTextColor:"#393939",
        statusBackgroundColor:"#F2F2F2"
    },
	{
        statusID: 6,
        statusText:"Payment Pending",
        statusTextColor:"#FF9300",
        statusBackgroundColor:"#FCF0DF"
    },
	{
        statusID: 5,
        statusText:"Payment Initiated",
        statusTextColor:"#FF9300",
        statusBackgroundColor:"#FCF0DF"
    },


]

	const navItems = [
		{
			name: 'planDetails',
			displayName: 'Plan Details',
			navClick: (nav) =>{
				setActiveNav({
					...activeNav,
					planDetails:true,
					coverageDetails:false,
					deviceDetails:false,
					paymentDetails:false,
					claimHistory:false,
					documents:false,
					addressDetails:false
				})
				setSelectedID(nav)
			},
			showNav: true
		},
		{
			name: 'coverageDetails',
			displayName: 'Coverage Details',
			navClick: (nav) =>{
				setActiveNav({
					...activeNav,
					planDetails:false,
					coverageDetails:true,
					deviceDetails:false,
					paymentDetails:false,
					claimHistory:false,
					documents:false,
					addressDetails:false
				})
				setSelectedID(nav)
			},
			showNav: true
		},
		{
			name: 'deviceDetails',
			displayName: 'Product Details',
			navClick: (nav) =>{
				setActiveNav({
					...activeNav,
					planDetails:false,
					coverageDetails:false,
					deviceDetails:true,
					paymentDetails:false,
					claimHistory:false,
					documents:false,
					addressDetails:false
				})
				setSelectedID(nav)
			},
			showNav: true
		},
		{
			name: 'paymentDetails',
			displayName: 'Payment Details',
			navClick: (nav) =>{
				setActiveNav({
					...activeNav,
					planDetails:false,
					coverageDetails:false,
					deviceDetails:false,
					paymentDetails:true,
					claimHistory:false,
					documents:false,
					addressDetails:false
				})
				setSelectedID(nav)
			},
			showNav: true
		},
		{
			name: 'claimHistory',
			displayName: 'Claim History',
			navClick: (nav) =>{
				setActiveNav({
					...activeNav,
					planDetails:false,
					coverageDetails:false,
					deviceDetails:false,
					paymentDetails:false,
					claimHistory:true,
					documents:false,
					addressDetails:false
				})
				setSelectedID(nav)
			},
			showNav: true
		},
		{
			name: 'documents',
			displayName: 'Documents',
			navClick: (nav) =>{
				setActiveNav({
					...activeNav,
					planDetails:false,
					coverageDetails:false,
					deviceDetails:false,
					paymentDetails:false,
					claimHistory:false,
					documents:true,
					addressDetails:false
				})
				setSelectedID(nav);
				getSoldPlanDocumentsWithImages();
			},
			showNav: true
		},
		{
			name: 'addressDetails',
			displayName: 'Address Details',
			navClick: (nav) =>{
				setActiveNav({
					...activeNav,
					planDetails:false,
					coverageDetails:false,
					deviceDetails:false,
					paymentDetails:false,
					claimHistory:false,
					documents:false,
					addressDetails:true
				})
				setSelectedID(nav)
			},
			showNav: true
		}
	]

	const deviceHeaders = [
		{
			label: (localString("IMEI/Serial Number")),
		},
		{
			label: (localString("Product Category")),
		},
		{
			label: (localString("Date of Purchase")),
		},
		{
			label: (localString("Product Purchase Price")),
		},
		{
			label: (localString("Brand")),
		},
		{
			label: (localString("Model")),
		},
		{
			label: (localString("Status")),
		},
		{
			label: (localString("")),
		},
	]

	const claimHeaders = [
		{
			label: (localString("Claim Reference ID")),
		},
		{
			label: (localString("Claimed Device")),
		},
		{
			label: (localString("Approved Claim Amount")),
		},
		{
			label: (localString("Claim Request Date")),
		},
		{
			label: (localString("Claim Approved Date")),
		},
		{
			label: (localString("Claim Status")),
		},
	]

	const getImei=(imei)=>{
		let filterToBeSent = '';
		let filteredIMEI1 = '';
		if (imei) {
			filteredIMEI1 = imei && imei.trim();
		}
		if (filteredIMEI1) {
			filterToBeSent = filterToBeSent + "&imei="+filteredIMEI1
		}
		return filterToBeSent;
	}

	const frequencyObj = (frequency, frequencyUnit) =>{
		if(!frequency && !frequencyUnit){
			return ""
		}else if(!frequency && frequencyUnit){
			return "/"+ frequencyUnit
		}
		if(frequency){
			if(frequency == 3){
				return "/quarter"
			}else if(frequency == 12 && frequencyUnit == "months"){
				return "/year"
			}else if(frequency > 12 && frequency % 12 == 0){
				let x = (frequency/12);
				return "/" + x +" years"
			}else{
				return "/" + frequency +" "+ frequencyUnit
			}
		}
	}


	const status = consumerDetails?.PlanDetails?.[0]?.Status;
	const cancellationDate =
		consumerDetails?.PlanDetails?.[0]?.PlanCancellationDate;

	const statusCheck =
		status == -4 ||
		status == 3 ||
		status == 4 ||
		(status == 1 && cancellationDate);

	const setConsumersData = () => {
		const defaultText = '-';
		const frequency = consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].PaymentFrequency;
		let frequencyUnit = consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].FrequencyUnitDisplay;
		let statusObj = consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].StatusTextObject;
		let frequencyStatus = frequencyObj(frequency,frequencyUnit)
		const planData = [
			{
				label: (localString("Plan Name")),
				value: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].PlanName || defaultText,
				customClass:"col-sm-8",
				showLabel: true
			},
			{
				label: localString("Plan Code"),
				value: (consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].PlanCode) || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: (localString("Order ID")),
				value: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].OrderID || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: localString("Plan Term"),
				value: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0]?.IsFixedTerm == true ? consumerDetails.PlanDetails[0].PlanValidity : localString("Non Fixed Term") || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: (localString("Contract ID")),
				value: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].SoldPlanCode || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: localString("Sales Channel"),
				value: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].Source || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: (localString("Policy ID")),
				value: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].ExternalReferenceID || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: (localString("Plan Purchase Date")),
				value: (consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].DateOfPurchase && returnLocalDateTime( consumerDetails.PlanDetails[0].DateOfPurchase, 'DD-MM-YYYY HH:mm')) || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: (localString("Terms&Conditions")),
				value: consumerDetails && consumerDetails.PlanDetails && <a href={consumerDetails.PlanDetails[0].TermsAndConditionsLink} target="_blank">{localString("View")}</a> || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: localString("Plan Activation Date"),
				value: (consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].DateOfActivation && returnLocalDateTime(consumerDetails.PlanDetails[0].DateOfActivation, 'DD-MM-YYYY HH:mm')) || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: localString("Payment Plan"),
				value: localString(frequency || defaultText),
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: localString("Plan Creation Date"),
				value: (consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].CreatedDate && returnLocalDateTime(consumerDetails.PlanDetails[0].CreatedDate, 'DD-MM-YYYY HH:mm')) || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: localString("Store Location"),
				value: partnerServiceLocation && partnerServiceLocation.length === 1 && partnerServiceLocation[0].ServiceLocationName || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: (localString("Partner Name")),
				value: consumerDetails && consumerDetails?.PartnerDetails && consumerDetails?.PartnerDetails?.PartnerName || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: (localString("User Name")),
				value: consumerDetails && consumerDetails?.UserDetails && consumerDetails?.UserDetails?.UserName || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: localString("Plan Expiry Date"),
				value: (consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].ValidityDate && returnLocalDateTime(consumerDetails.PlanDetails[0].ValidityDate, 'DD-MM-YYYY HH:mm')) || defaultText,
				customClass:"col-sm-6",
				showLabel: (consumerDetails && consumerDetails.PlanDetails && (consumerDetails.PlanDetails[0].IsFixedTerm == true || consumerDetails.PlanDetails[0].IsFixedTerm == null )) ? true : false
			},
			{
				label: localString("Waiting Period"),
				value: consumerDetails 
						&& consumerDetails.PlanDetails 
						&& consumerDetails.PlanDetails[0] 
						&& (consumerDetails.PlanDetails[0].CoolingPeriod 
							?   (consumerDetails.PlanDetails[0].CoolingPeriod == 1 
								? consumerDetails.PlanDetails[0].CoolingPeriod + " day"
								: consumerDetails.PlanDetails[0].CoolingPeriod + " days")
							: 	defaultText),
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: localString("Plan Approval Reason"),
				value: (consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0] && consumerDetails.PlanDetails[0].PlanApprovalReason ) || defaultText,
				customClass:"col-sm-6",
				showLabel: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0] && consumerDetails.PlanDetails[0].PlanApprovalReason
			},
			{
				label: localString("Plan Approval Description"),
				value: (consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0] && consumerDetails.PlanDetails[0].PlanApprovalDescription ) || defaultText,
				customClass:"col-sm-6",
				showLabel: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0] && consumerDetails.PlanDetails[0].PlanApprovalDescription
			},
			{
				label: localString("Plan Rejection Reason"),
				value: (consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0] && consumerDetails.PlanDetails[0].PlanRejectionReason ) || defaultText,
				customClass:"col-sm-6",
				showLabel: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0] && consumerDetails.PlanDetails[0].PlanRejectionReason
			},
			{
				label: localString("Plan Rejection Description"),
				value: (consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0] && consumerDetails.PlanDetails[0].PlanRejectionDescription ) || defaultText,
				customClass:"col-sm-6",
				showLabel: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0] && consumerDetails.PlanDetails[0].PlanRejectionDescription
			},
			{
				label: statusCheck && localString("Plan Cancellation Initiation Date"),
				value: (consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].CancellationInitiationDate && returnLocalDateTime(consumerDetails.PlanDetails[0].CancellationInitiationDate, 'DD-MM-YYYY HH:mm')) || defaultText,
				customClass:"col-sm-6",
				showLabel:  statusCheck && consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].CancellationInitiationDate
			},
			{
				label: statusCheck && localString("Cancellation Reason Description"),
				value: (consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0] && consumerDetails.PlanDetails[0].CancellationReasonDescription) || defaultText,
				customClass:"col-sm-6",
				showLabel: statusCheck && consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0] && consumerDetails.PlanDetails[0].CancellationReasonDescription
			},
			{
				label: statusCheck && localString("Plan Cancellation Date"),
				value: (consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].PlanCancellationDate && returnLocalDateTime(consumerDetails.PlanDetails[0].PlanCancellationDate, 'DD-MM-YYYY HH:mm')) || defaultText,
				customClass:"col-sm-6",
				showLabel: statusCheck && consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].PlanCancellationDate
			},
		]
		let highRisk = consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].HighRiskReason && consumerDetails.PlanDetails[0].HighRiskReason.length > 0 && consumerDetails.PlanDetails[0].HighRiskReason;
		if (highRisk) {
			let reasonsList = highRisk.map((reason, index) => {
				return reason.RaisedFlagReasonDescription;
			});
			planData.push({
				label: (localString("High Risk Reason(s)")),
				value: reasonsList || defaultText,
				sectionId: "reasons",
				customClass:"col-sm-12",
				showLabel: true
			});
			// setShowHighRiskReasons(true);
		}

		const addressData = [
			{
				label: (localString("Address Line 1")),
				value: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].AddressLine1 || defaultText,
				customClass:"col-sm-6",
				customLabelClass:"customAddressLabel",
				showLabel: true
			},
			{
				label: (localString("Address Line 2")),
				value: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].AddressLine2 || defaultText,
				customClass:"col-sm-6",
				customLabelClass:"customAddressLabel",
				showLabel: true
			},
			{
				label: (localString("City")),
				value: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].City || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: (localString("State")),
				value: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].State || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: (localString("Pincode")),
				value: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].ZipCode || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
			{
				label: (localString("Landmark")),
				value: consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].Landmark || defaultText,
				customClass:"col-sm-6",
				showLabel: true
			},
		]

		const deviceData = consumerDetails && consumerDetails.DeviceDetails && consumerDetails.DeviceDetails.map(val=>{
			return [{
				value :(val && (val.ProductUniqueID && val.ProductUniqueID) || (val.AlternateUniqueKey && val.AlternateUniqueKey) || (val.DownloadedDeviceUniqueKey && val.DownloadedDeviceUniqueKey))  || defaultText,
			},{
				value :(val && val.ProductSubcategory && val.ProductSubcategory.ProductSubCategory) || defaultText,
			},{
				value :(val && returnLocalDateTime(val.DateOfPurchase,"DD-MM-YYYY HH:mm")) || defaultText,
			},{
				value :val.ProductPurchaseCost ? 
					displayAmount(
						val.ProductPurchaseCost, 
						(loggedInUserSpicificCountryData?.countryConfig?.Locale?.DEFAULT || navigator?.language)
					)
					: defaultText,
			},{
				value :(val && val.brand && val.brand.BrandName ) || defaultText,
			},{ 
				value :(val && val.product &&  val.product.ProductName ) || defaultText,
			},{
				showStatus: true,
				status :(val && val.DeviceCoverageStatus ? localString(val.DeviceCoverageStatus) : defaultText)
			},{
				showButton:true,
				consumerID : val && val.ConsumerID,
				imei: val && val.ProductUniqueID && getImei(val.ProductUniqueID)
			}]
		})

		const claimData = consumerDetails && consumerDetails.ClaimHistory && consumerDetails.ClaimHistory.map(val=>{
			return [{
				value :(val && val.ClaimReferenceID) && <a href={baseUrl+'/servicerequests/view?csrid='+val.ConsumerServiceRequestID} target="_blank">{localString(val.ClaimReferenceID)}</a> || defaultText,
			},{
				value : val && (val.BrandName + "-" + val.ProductSubCategory) || defaultText,
			},{
				value : (val?.ApprovedClaimAmount) ? 
					displayAmount(
						val.ApprovedClaimAmount, 
						(loggedInUserSpicificCountryData?.countryConfig?.Locale?.DEFAULT || navigator?.language)
					)
					: defaultText,
			},{
				value :(val && returnLocalDateTime(val.CreatedDate,"DD-MM-YYYY")) || defaultText,
			},{
				value :(val && returnLocalDateTime(val.ClaimApprovalDate,"DD-MM-YYYY")) || defaultText,
			},{ 
				value :(val && val.Status ) || defaultText,
			}]
		})

		const coverageData = consumerDetails && consumerDetails.SoldPlanCoverages && consumerDetails.SoldPlanCoverages.map(val=>{
			return [
				{
					label: (localString("Coverage Name")),
					value: (val && val.DisplayText) || defaultText,
					customClass:"col-sm-12"
				},
				{
					label: (localString("Coverage validity starts on")),
					value: (val && val.ValidityStartDate && returnLocalDateTime(val.ValidityStartDate,"DD-MM-YYYY HH:mm")) || defaultText,
					customClass:"col-sm-6"
				},
				{
					label: (localString("Coverage validity ends on")),
					value: (val && val.CoverageEndDate && returnLocalDateTime(val.CoverageEndDate,"DD-MM-YYYY HH:mm")) || defaultText,
					customClass:"col-sm-6"
				},
				{
					label: (localString("No. Of Claims Allowed")),
					value: (val && val.NoOfRepairsAllowed) || defaultText,
					customClass:"col-sm-6"
				},
				{
					label: (localString("Used Claims")),
					value: (val && val.NoOfRepairsUsed) || defaultText,
					customClass:"col-sm-6"
				},
				{
					label: (localString("Claim can be filed from")),
					value: (val && val.ClaimAvailDate && returnLocalDateTime(val.ClaimAvailDate,"DD-MM-YYYY HH:mm")) || defaultText,
					customClass:"col-sm-6"
				},
			]
		})

		setPlanDetails(planData);
		setCoverageDetails(coverageData);
		setDeviceDetails(deviceData);
		setClaimDetails(claimData);
		setAddressDetails(addressData);
		setSoldPlanStatusObj(statusObj)
	}

	const searchButton = () => {
		let deviceData = consumerDetails && consumerDetails.DeviceDetails;
		const defaultText = localString('Not Updated');
		if(deviceData && deviceData.length > 0) {
			let filteredList = filter(deviceData,(val)=> {
				let filterValue = ((val.ProductUniqueID && val.ProductUniqueID) || (val.AlternateUniqueKey && val.AlternateUniqueKey) || (val.DownloadedDeviceUniqueKey && val.DownloadedDeviceUniqueKey));
				if(filterValue == filterData.IMEI){
					return val;
				}else{
					return 
				}
			});

			let filteredDevice = filteredList && filteredList.length > 0 && filteredList.map(val=>{
				return [{
					value :(val && (val.ProductUniqueID && val.ProductUniqueID) || (val.AlternateUniqueKey && val.AlternateUniqueKey) || (val.DownloadedDeviceUniqueKey && val.DownloadedDeviceUniqueKey)) || defaultText,
				},{
					value :(val && val.product && val.product.ProductCategory) || defaultText,
				},{
					value :(val && returnLocalDateTime(val.DateOfPurchase,"DD-MM-YYYY")) || defaultText,
				},{
					value :(val && val.ProductPurchaseCost) || defaultText,
				},{
					value :(val && val.brand && val.brand.BrandName ) || defaultText,
				},{ 
					value :(val && val.product &&  val.product.ProductName ) || defaultText,
				},{
					showButton:true,
					consumerID : val && val.ConsumerID 
				}]
			})
			setDeviceDetails(filteredDevice);
		}
	}

	const paymentStatusFunction = (ID) => {
        const finalStatusObj = paymentStatus.filter(obj=>{
            if(ID == obj.statusID){
                return obj
            }
        })
        const finalStatus = finalStatusObj[0].statusText;
        const statusTextColor = finalStatusObj[0].statusTextColor;
        const statusBackgroundColor = finalStatusObj[0].statusBackgroundColor;
        return { finalStatus, statusTextColor, statusBackgroundColor };
    }

	const customisedImagesFormat = (soldPlanDocuments) => {
		setIsDocReceived(false)
		let firstArr= [];
		let secondArr= [];
		let otherArr= [];
		soldPlanDocuments && soldPlanDocuments.map(item=>{
			if(item.Type === "Diagnosis Images"){
				(item.Attempt === 1) ? firstArr.push(item) : (item.Attempt === 2) && secondArr.push(item)
			}else{
				otherArr.push(item)
			}
		})

		firstArr = firstArr && firstArr.map(item => {
			const imagesNew = Object.entries(item.Images).map(([tag, doc]) => ({
				Tag: tag,
				Doc: Object.entries(doc).map(([type, link]) => ({ Type: type, Link: link }))
			}));
			return {
				Type: item.Type,
				Attempt: item.Attempt,
				DiagnosisUUID: item.DiagnosisUUID,
				DiagnosisAttemptStatus: item.DiagnosisAttemptStatus,
				ImagesNew: imagesNew
			};
		});

		secondArr = secondArr && secondArr.map(item => {
		const imagesNew = Object.entries(item.Images).map(([tag, doc]) => ({
			Tag: tag,
			Doc: Object.entries(doc).map(([type, link]) => ({ Type: type, Link: link }))
		}));
		return {
			Type: item.Type,
			Attempt: item.Attempt,
			DiagnosisUUID: item.DiagnosisUUID,
			DiagnosisAttemptStatus: item.DiagnosisAttemptStatus,
			ImagesNew: imagesNew
		};
		});

		setDeviceDocuments({...deviceDocuments,
			firstAttempt: firstArr,
			secondAttempt: secondArr,
			otherDocs: otherArr
		})
	}

	return (
		<div className="col-sm-12 text-center clearfix plan-navigation">
			<PlanViewConsumerDetails 
				{...props}
				selectedID={selectedID}
				navItems={navItems}
			/>
			<div className="col-sm-9 product-details">
				{
					activeNav.planDetails &&
					<>
						<PlanConsumerDetails 
							consumerDetailArray={planDetails} {...props} 
							showStatus={true}
							statusObject={soldPlanStatusObj}    
							bgColor={bgColor}
							textColor={textColor}
						/>
						{
							buttonArray && buttonArray.length > 0 && 
							<div className="plan-action-button">
								<ActionButtonList buttonArray={buttonArray}></ActionButtonList>
							</div>
						}
					</>
				}
				{
					activeNav.coverageDetails &&
					<PlanConsumerDetails 
						coverageDetails={coverageDetails} {...props} 
						coverageTab={true}
					/>
				}
				{
					activeNav.deviceDetails &&
					<PlanConsumerDetails 
						consumerDetailArray={deviceDetails} 
						filterInputs={filterInputs} 
						saveButtonText="Search" 
						filterFunc={(e)=>{
							e.preventDefault();
							searchButton(e)
						}} 
						filterData={filterData} {...props} 
						showTable={true}
						showSearchFilter={true}
						headers={deviceHeaders}
						searchValidation={searchValidation}
						bgColor={bgColor}
						textColor={textColor}
					/>
				}
				{
					activeNav.paymentDetails &&
					<PlanConsumerDetails 
						paymentDetailArray= {paymentDetails} 
						{...props} 
						paymentTable= {true}
						consumerDetails= {consumerDetails}
						paymentStatusFunction= {paymentStatusFunction}
					/>
				}
				{
					activeNav.claimHistory &&
					<PlanConsumerDetails 
						consumerDetailArray={claimDetails} 
						showTable={true} {...props} 
						headers={claimHeaders}
					/>
				}
				{
					activeNav.documents &&
					<PlanConsumerDetails 
						documentsDetailObject= {deviceDocuments} {...props} 
						documentTab= {true}
						isDocReceived= {isDocReceived}
					/>
				}
				{
					activeNav.addressDetails &&
					<PlanConsumerDetails consumerDetailArray={addressDetails} {...props} />
				}
			</div>
		</div>
	)
}

export default PlanNavBar
