import React from 'react';
import {Table} from 'sfy-react';
import {getLocaleDateString} from '../../../../../Utility/Utils'
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import './TradeIn.scss';


const TradeInDetails = (props) => {
	const {tradeInDetails,defaultCountryLocale,localString,tradeInDashboardDetails,baseUrl} = props;

	const tradeInArray = [{
		showLabel:tradeInDetails && tradeInDetails.tradeInRequest,
		labelText:localString("IMEI"),
		value:tradeInDetails && tradeInDetails.tradeInRequest && tradeInDetails.tradeInRequest.ProductUniqueID
	},{
		showLabel:tradeInDetails && tradeInDetails.tradeInRequest,
		labelText:localString("Reference ID"),
		value:tradeInDetails && tradeInDetails.tradeInRequest && tradeInDetails.tradeInRequest.ReferenceID
	},{
		showLabel:tradeInDetails && tradeInDetails.tradeInRequest,
		labelText:localString("Trade-in Amount"),
		value:tradeInDetails && tradeInDetails.tradeInRequest && tradeInDetails.tradeInRequest.TradeInAmount
	},{
		showLabel:tradeInDetails && tradeInDetails.calculation,
		labelText:localString("Total Depreciation"),
		value:tradeInDetails && tradeInDetails.calculation && tradeInDetails.calculation.TotalDepreciation
	},{
		showLabel:tradeInDetails && tradeInDetails.calculation,
		labelText:localString("Margin Percentage"),
		value:tradeInDetails && tradeInDetails.calculation && tradeInDetails.calculation.MarginPercentage
	},{
		showLabel:tradeInDetails && tradeInDetails.calculation,
		labelText:localString("Margin Amount"),
		value:tradeInDetails && tradeInDetails.calculation && Math.round((tradeInDetails.calculation.MarginAmount + Number.EPSILON) * 100) / 100
	},{
		showLabel:tradeInDetails && tradeInDetails.tradeInRequest,
		labelText:localString("Trade-in Amount Without Margin"),
		value:tradeInDetails && tradeInDetails.tradeInRequest && tradeInDetails.tradeInRequest.TradeInAmountWithoutMargin
	},{
		showLabel:tradeInDetails && tradeInDetails.calculation,
		labelText:localString("MOP"),
		value:tradeInDetails && tradeInDetails.calculation && tradeInDetails.calculation.MOP
	},{
		showLabel:tradeInDetails && tradeInDetails.calculation,
		labelText:localString("NPO"),
		value:tradeInDetails && tradeInDetails.calculation && tradeInDetails.calculation.NPO
	},{
		showLabel:tradeInDetails && tradeInDetails.tradeInRequest,
		labelText:localString("Trade-in Date"),
		value:tradeInDetails &&tradeInDetails.tradeInRequest && getLocaleDateString(tradeInDetails.tradeInRequest.TradeInDate,defaultCountryLocale) || '-'
	},{	
		showImages:true,
		value:tradeInDashboardDetails && tradeInDashboardDetails.downloadLinks, 
	}]


	const classObject = {
		table:"table table-striped trade-in-details-table table-responsive table-bordered table-condensed"
	}
	return (
		<div className="inner-padding">
			<div className="row">
				<PageContentWrapper bodyClassName="panel-body-no-padding" title={localString("Trade-in Details")}>
				<div className="request-panel-details-tag request-issue-validation-div">
					{
						<Table className={classObject}>
							<Table.Body>
							{
								tradeInArray && tradeInArray.map(item=>{
									return(
										<>
										{
											item.showLabel && 
											<Table.Row>
												<Table.Data className="trade-in-label-text">{item.labelText}</Table.Data>
												<Table.Data className="break-word">{item.value}</Table.Data>
											</Table.Row>
										}
										{
											item.showImages &&
											item.value && item.value.map(pics=>{
												return (
													['TradeIn Device Images', 'Requoted Device Images'].indexOf(pics.Name) > -1  &&
													<Table.Row>
														<Table.Data>{pics.Name}</Table.Data>
														<Table.Data className="break-word tradein-device-images">
															<div class="device-document-wrapper">
																{
																	pics && pics.link && pics.link.map(image=>{
																		return(<>
																			{/* <a href={baseUrl +"/document/view?u="+ image.Link.replace('?','%3F').replace(/&/g,'%26')} target="_blank" className="device-document">
																				<iframe src={baseUrl+"/document/view?u="+image.Link.replace('?','%3F').replace(/&/g,'%26')} className="requestDocImg disable-click no-border" width="100px" height="100px"></iframe>
																				<p>{image.Tag}</p>
																			</a> */
																			<a href={image.Link} target="_blank" className="device-document">
																				<div className="documents-box imageDivBox">
																					<i className="glyphicon glyphicon-file" style={{ fontSize: '18px' }}></i>
																					<p style={{ paddingTop: '5px' }}>{image.Tag}</p>
																				</div>
																			</a>}
																		</>)
																	})
																}
															</div>
														</Table.Data>
													</Table.Row>
												)
											}) 
										}
										</>
									)
								})
							}
							</Table.Body>
						</Table>
					}
				</div>
				</PageContentWrapper>
			</div>
		</div>
	)
}

export default TradeInDetails
