import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Swal from "sweetalert2";
import { Button, Loader, Pagination } from 'sfy-react';
import moment from 'moment';
import Papa from 'papaparse';

import { decrypt, displayError, downloadCSV, formatIndianCurrencyRs } from '../../../../Utility/Utils';
import ReportPageHeader from '../../ReportPageHeader';
import './SalesAndPerformanceReportView.scss';
import SalesAndPerformanceGrid from './SalesAndPerformanceGrid';

export default function SalesAndPerformanceReportView(props) {
	const { accessFn, localString, apiCall, useQuery } = props;
	let query = useQuery();
	const name  = decrypt(query.get("name"));
	const RetailUserID  = decrypt(query.get("id"));
	const [loader, setLoader] = useState(false);
	const [data, setData] = useState([]);
	const [salesDetails, setSalesDetails] = useState([]);
	const [advisorDetails, setAdvisorDetails] = useState([]);
	const [date, setDate] = useState(new Date(localStorage.getItem('date')) || new Date());
	const [pagination , setPagination] = useState({
		pageNo : 1,
		itemsPerPage : 30,
		count: 0,
		numberOfPages: 0
	});
	const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
	const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

	const getSalesReport = (downloadFile) => {
		setLoader(true);
		let reqObj = {
			RetailUserID: [RetailUserID],
			ShopCode: [],
			Type: "PersonalSales",
			branchCode: [],
			eDate: moment(endDate).format("YYYY-MM-DD"),
			sDate: moment(startDate).format("YYYY-MM-DD"),
		}
		if(!downloadFile) {
			reqObj.Limit = pagination.itemsPerPage;
			reqObj.PageNo = pagination.pageNo;
		}
		apiCall({
			url: 'getSalesReport',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data && downloadFile) {
					let salesDetailsArr = result.data.SalesDetails;
					let salesRecords = [];
					salesDetailsArr.length && salesDetailsArr.map((detail) => {
						detail.ProductData.length && detail.ProductData.map((productDetail, k) => {
							let obj = {...productDetail};
							obj.Total = (detail.SumBasedOnEachDay && k == 0) ? detail.SumBasedOnEachDay : '';
							delete obj['RetailUserID'];
							delete obj['ProductID'];
							salesRecords.push(obj);
						});
						salesRecords.push({});
					});
					let salesDetails = Papa.unparse({
						fields: [
							'SaleDate',
							'CustomerName',
							'MobileNo',
							'ProductCategory',
							'ProductSubcategory',
							'ModelNo',
							'ModelDesc',
							'InvoiceAmount',
							'Status',
							'Total'
						],
						data: salesRecords
					});
					// ToDo: Common handler to capitalize first row in reports
					let CapsString;
					let capital = Papa.parse(salesDetails, {
						beforeFirstChunk :function(chunk) {
							let rows = chunk.split( /\r\n|\r|\n/ );
							let result = [];
							let row = rows[0];
							let a = row.split(',');
							for (let iCount = 0; iCount < a.length; iCount++) {
								let item = a[iCount].replace(/([a-z])([A-Z])/g, '$1 $2');
								result.push(item)
							}
							CapsString = result.toString().toUpperCase();
							rows[0] = CapsString;
							return rows.join("\r\n");
						},
					});
					let advisorData = result.data.AdvisorDetails;
					let salesAchievement = result.data.SalesAchievement;
					let advisorDetails = "NAME" +","+ (advisorData[0].Name ? advisorData[0].Name : '-') + "\r\n" +
						"BRANCH CODE" +","+ (advisorData[0].branchCode ? advisorData[0].branchCode : '-') + "\r\n" +
						"MOBILE NO" +","+(advisorData[0].MobileNo ? advisorData[0].MobileNo : '-') + "\r\n" +
						"SHOP" +","+ (advisorData[0].shop ? advisorData[0].shop : '-') + "\r\n" +
						"SHOP AREA" +","+ (advisorData[0].ShopArea ? advisorData[0].ShopArea : '-') + "\r\n" +
						"CITY" +","+ (advisorData[0].City ? advisorData[0].City : '-') + "\r\n" +
						"BRANCH" +","+ (advisorData[0].Branch ? advisorData[0].Branch : '-') +
						"\r\n\r\n" +
						"TARGET" +","+ (salesAchievement.Target ? salesAchievement.Target : '-') + "\r\n" +
						"VERIFIED AMOUNT" +","+ (salesAchievement.VerifiedAmount ? salesAchievement.VerifiedAmount : '-') + "\r\n" +
						"UNVERIFIED AMOUNT" +","+ (salesAchievement.UnVerifiedAmount ? salesAchievement.UnVerifiedAmount : '-') + "\r\n" +
						"NO OF PRODUCTS SOLD" +","+ (salesAchievement.NoOfProductSold ? salesAchievement.NoOfProductSold : '-');

					let salesDetailsCsv = Papa.unparse(capital);
					let csv = advisorDetails + "\r\n" + "\r\n" + salesDetailsCsv;
					downloadCSV(csv, name + '.csv')
				}else if(result.success && result.data) {
					let salesDetailsArr = result.data.SalesDetails;
					setData(salesDetailsArr);
					setSalesDetails(salesDetailsArr)
					setAdvisorDetails(result.data.AdvisorDetails)
					setPagination({...pagination, 
						count: result.data && result.data.length,
						numberOfPages: result.data && result.data.numberOfPages
					})
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		getSalesReport();
	}, [pagination.pageNo])

	return (
		<>
			<div className='attendanceReport'> 
				{
					loader && ReactDOM.createPortal(
						<Loader />,
						document.getElementById("loader-root")
					)
				}
				<ReportPageHeader 
					titleHeader={name}
					localString={localString}
					showHeaderContent={true}
					headerContent={() => {
						return (
							<div style={{ display: 'inline-block' }}>
								<span style={{ fontSize: '20px' }}>
									({moment(date).format("MMM-YYYY")})
								</span>
								<div className="download-icon" title="Download Report" onClick={() => getSalesReport(true)} data-toggle="tooltip" data-placement="right">
									<div className="download-image" id=""></div>
								</div>
							</div>
						);
					}}
				/>
				<SalesAndPerformanceGrid
					salesDetails={salesDetails}
					advisorDetails={advisorDetails}
					localString={localString}
				/>
				{
					pagination.numberOfPages > 1 && 
						<Pagination
							itemsPerPage={pagination.itemsPerPage}
							pages={pagination.numberOfPages}
							totalItems={pagination.count}
							activePage={pagination.pageNo}
							paginate={(pageNumber) => setPagination({...pagination, pageNo: pageNumber})}
						/> 
				}
			</div>
		</>
	);
}