import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader, Modal} from 'sfy-react';
import Swal from "sweetalert2";
import {find, filter} from 'lodash';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import {displayError} from '../../../../../Utility/Utils';
import DeadOnArrivalTable from './DeadOnArrivalTable';
import './DeadOnArrival.scss'
import ActionOnDeviceModal from './ActionOnDeviceModal';


const DeadOnArrival = (props) => {
	const {apiCall, localString, consumerServiceRequest, requestSFA, showRequestValid, getRequestById, partsRequested, getPartsForRequest} = props;
	const [loader, setLoader] = useState(false);
	const [isSalesChannelEditable, setIsSalesChannelEditable] = useState(false)
	const [doaOptions, setDoaOptions] = useState([])
	const [isSalesChannelFetched, setIsSalesChannelFetched] = useState(false)
	const [salesChannel, setSalesChannel] = useState([])
	const [isHanded, setIsHanded] = useState(false)
	const [salesChannelValue, setSalesChannelValue] = useState({
		channelName:"",
		channelID:""
	})
	const [deviceReplacementMode, setDeviceReplacementMode] = useState("GenerateDOACertificate")
	const [replacedProduct, setReplacedProduct] = useState([])
	const [chooseReplaceDevice, setChooseReplaceDevice] = useState({
		Description:""
	})
	const [selectedReplacedProduct, setSelectedReplacedProduct] = useState({})
	const [disableDOAInput, setDisableDOAInput] = useState(true)
	const [firstProduct, setFirstProduct] = useState()
	const [showModal, setShowModal] = useState(false)
	const [currentDOAPart, setCurrentDOAPart] = useState([])
	const [disableSaveIssue, setDisableSaveIssue] = useState()
	const [deviceIssuing, setDeviceIssuing] = useState({
		serialNumberByDropdown:"",
		IMEI1:"",
		IMEI2:"",
		serialNumber:"",
		PartItemID:""
	})
	const [showErrors, setShowErrors] = useState({
		IMEIOne:"",
		IMEITwo:"",
		serialNumber:"",
	})

	const doaStatus = consumerServiceRequest.DOAStatus;
	const requestStatus = consumerServiceRequest.Status;

	useEffect(() => {
		let first = partsRequested && partsRequested[0]? partsRequested[0]: null;
		setFirstProduct(first)
	}, [partsRequested])

	useEffect(() => {
		initialiseDOADevice()
	}, [])

	useEffect(() => {
		if(selectedReplacedProduct.length > 0){
			setDisableDOAInput(false)
		}else{
			setDisableDOAInput(true)
		}
	}, [selectedReplacedProduct])

	useEffect(() => {
		if (consumerServiceRequest && !consumerServiceRequest.SalesChannelName && consumerServiceRequest.DOAStatus == 'applicable') {
			if (isSalesChannelFetched) {
				// initSalesChannel()
			} else {
				getSalesChannel()
			}
		} else if (consumerServiceRequest && consumerServiceRequest.SalesChannelName && consumerServiceRequest.DOAStatus == 'applicable') {
			getAvailableDoaOptions()
		}

		isHandedToEng();
	}, [consumerServiceRequest])

	useEffect(() => {
		if((deviceIssuing.IMEI2 && deviceIssuing.IMEI2.length == 15) 
		&& (deviceIssuing.IMEI1 && deviceIssuing.IMEI1.length == 15)){
			setDisableSaveIssue(false)
		}else{
			setDisableSaveIssue(true)
		}
	}, [deviceIssuing])

	const getAvailableDoaOptions = () => {
		let data  = {
			'ConsumerServiceRequestID': consumerServiceRequest.ConsumerServiceRequestID
		}
		setLoader(true);
		apiCall({
			url:'getDoaReplacementStatus',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success && result.data){
					// self.isDoaOptionsFetched = true   => no use of variable
					if (result.data.doaReplacement) {
						let DOAOptions = result.data.doaReplacement;
						setDoaOptions(DOAOptions)
					}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const getSalesChannel = () => {
		let data = {
			'BrandID': (consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID) ? consumerServiceRequest.consumerProduct.BrandID: undefined,
			'CenterCountryID': consumerServiceRequest && consumerServiceRequest.servicelocation && consumerServiceRequest.servicelocation.CountryID ? consumerServiceRequest.servicelocation.CountryID : undefined
		}
		apiCall({
			url:'getSalesChannel',
			data: data,
			callBack:(result)=>{
				if(result.success && result.data){
					let channel = result.data && result.data.map(item=>({...item,value:item.SalesChannelName}))
					setSalesChannel(channel)
					setIsSalesChannelFetched(true)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const saveSalesChannel = () => {
		let data = {
			"ServiceTypeID": consumerServiceRequest.ServiceTypeID,
			"ConsumerProductID": consumerServiceRequest.consumerProduct.ConsumerProductID,
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"updateObj": {
				"SalesChannelID": salesChannelValue.channelID
			},
			"updatedWarranty": {}
		}
		setLoader(true);
		apiCall({
			url:'updateConsumerProduct',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					setIsSalesChannelEditable(false)
					getRequestById();
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const saveReplacementMode = () => {
		let status;
		if (!deviceReplacementMode === "GetNewDevice") {
			status = 'certificate'
			// window.open(baseUrl + '/servicerequests/doacerti?csrid='+encrypt(self.consumerServiceRequest.ConsumerServiceRequestID));
			// Generate DOA document so customer can replace the damaged phone from the place of purchase
		} else {
			// Customer wants the device now!!
			status = 'replacement'
		}
		let data = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"DOAStatus": status
		}
		setLoader(true);
		apiCall({
			url:'updateDOAStatus',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRequestById();
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const closeRemarkModal = () => {
		setShowModal(false)
	}

	const validateImeiOne = (val) => {
		let imeiOne = ''
		let input = val;
		if (input.length === 15) {
			imeiOne = input
			setShowErrors({...showErrors,IMEIOne:false})
		} else {
			setShowErrors({...showErrors,IMEIOne:true})
		}
	}

	const validateImeiTwo = (val) => {
		let imeiTwo = ''
		let input = val;
		if (input.length == 15) {
			imeiTwo = input
			setShowErrors({...showErrors,IMEITwo:false})
		} else {
			setShowErrors({...showErrors,IMEITwo:true})
		}
	}

	const validateSerialNumber = (val) => {
		let serialNumber = ''
		let input = val;
		if (input.length < 21 || input.length == 0) {
			serialNumber = input
			setShowErrors({...showErrors,serialNumber:false})
		} else {
			setShowErrors({...showErrors,serialNumber:true})
		}
	}

	const updateDeviceAction = (e) => {
		e.preventDefault();
		setShowModal(false)
		let action;
		if (requestStatus == 'Parts request accepted') {
			action = 'issued'
		} else if (requestStatus == 'Parts issued') {
			action = 'received'
		} else {
		  // pass
		}
		let device = {
			PartTransactionID: firstProduct ? firstProduct.PartTransactionID : undefined,
			PartID: firstProduct ? firstProduct.PartID : undefined,
			Entity: firstProduct ? firstProduct.Entity : undefined,
			EntityID: firstProduct ? firstProduct.EntityID : undefined,
			RequestedByID: firstProduct ? firstProduct.RequestedByID : undefined,
			IssuedByID: firstProduct ? firstProduct.IssuedByID : undefined,
			Serialized: firstProduct ? firstProduct.Serialized : undefined,
			AvailableQuantity: firstProduct ? firstProduct.AvailableQuantity : undefined,
			PartTransactionStatusID: firstProduct ? firstProduct.PartTransactionStatusID : undefined,
			IsActive: firstProduct ? firstProduct.IsActive : undefined,
			RequiresSerialNumber: firstProduct ? firstProduct.RequiresSerialNumber : undefined,
			ChargeID: firstProduct ? firstProduct.ChargeID : undefined,
			Name: firstProduct ? firstProduct.Name : undefined,
			action: action,
			IMEI1: deviceIssuing.IMEI1,
			IMEI2: deviceIssuing.IMEI2,
			SerialNumber: deviceIssuing.serialNumber
		}
		if(firstProduct.Serialized && action == 'issued'){
			device.PartItemID = deviceIssuing.PartItemID;
		}
		let data = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"parts": [device],
		};
		setLoader(true);
		apiCall({
			url:'updatePartStatus',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRequestById();
					getPartsForRequest();
					// showServiceLocation = false;
					// isPartIssued = false;
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initialiseDOADevice = () => {
		let data = {
			"FinishedGoodID": consumerServiceRequest.consumerProduct.FinishedGoodID,
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID
		}
		setLoader(true);
		apiCall({
			url:'getReplacedDevice',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let sameProductAsReportedDevice = find(result.data, (obj) => {
						return obj.AvailableQuantity > 0 && obj.PartID === consumerServiceRequest.consumerProduct.FinishedGoodID;
					});

					let product = [];
					if(!sameProductAsReportedDevice) {
						product = filter(result.data, (obj) => {
							return obj.AvailableQuantity > 0;
						});
					} else {
						product.push(sameProductAsReportedDevice);
					}
					let dropdownData = product && product.map((item) => ({...item,value:item.Description}));
					setReplacedProduct(dropdownData)
					let disableGetProductRadio = product.length === 0;
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const orderReplacedPart = () => {
		let data = {
			updatedCharges: [selectedReplacedProduct],
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			ServiceTypeID: consumerServiceRequest.ServiceTypeID,
			PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID
		};    
		setLoader(true);
		apiCall({
			url:'updateChargeWorkshop',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRequestById();
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const getSerializedParts = () => {
		let data = {
			part: firstProduct,
			PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID
		};
		// setLoader(true)
		apiCall({
			url:'getPartItemsBySerialNumber',
			data: data,
			callBack:(result)=>{
				// setLoader(false)
				if(result.success){
					let list = result.data && result.data.map((v)=> ({...v,value:v.SerialNumber}));
					setCurrentDOAPart(list)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const checkReplacementMode = () => {
		if(showRequestValid.isRequestSFANeeded){
			if (doaStatus == 'applicable' && consumerServiceRequest && requestSFA && requestSFA.length > 0 ) {
				return true
			}
			return false
		}else{
			if (doaStatus == 'applicable' && consumerServiceRequest && consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 ) {
				return true
			}
			return false
		}
	}

	const editSalesChannel = () => {
		setIsSalesChannelEditable(true)
		if (isSalesChannelFetched) {
			// initSalesChannel()
		} else {
			setSalesChannelValue({...salesChannelValue,channelName:consumerServiceRequest.SalesChannelName})
			getSalesChannel()
		}
	}

	const cancelSaveSalesChannel = () => {
		setIsSalesChannelEditable(false)
	}

	const isHandedToEng = () => {
		setIsHanded(false)
		let deviceHanded = find(consumerServiceRequest.logs, {Status: 'Device accepted'})
		if (deviceHanded) {
			if (deviceHanded.DestinationType == 'ServicelocationEngineer' || deviceHanded.DestinationType == 'Supervisor') {
				setIsHanded(true)
			}
		}
		return isHanded;
	}

	const openModalProduct = (e) => {
		if (requestStatus == 'Parts request accepted') {
			setShowModal(true)
		} else {
			updateDeviceAction(e)
		}
	}

	return (
		<>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper bodyClassName="panel-body-no-padding" title={localString("Dead on Arrival")}>
						<div className="request-panel-details-tag request-issue-validation-div dead-on-arrival-content">
							<div className="">
								<DeadOnArrivalTable 
									{...props}
									isSalesChannelEditable= {isSalesChannelEditable}
									checkReplacementMode= {checkReplacementMode}
									doaOptions= {doaOptions}
									doaStatus= {doaStatus}
									requestStatus= {requestStatus}
									isHanded= {isHanded}
									editSalesChannel= {editSalesChannel} 
									salesChannel= {salesChannel}
									salesChannelValue= {salesChannelValue} 
									setSalesChannelValue= {setSalesChannelValue}
									saveSalesChannel= {saveSalesChannel} cancelSaveSalesChannel={cancelSaveSalesChannel}
									deviceReplacementMode= {deviceReplacementMode} setDeviceReplacementMode={setDeviceReplacementMode} 
									saveReplacementMode= {saveReplacementMode}
									replacedProduct= {replacedProduct}
									chooseReplaceDevice= {chooseReplaceDevice} setChooseReplaceDevice= {setChooseReplaceDevice}
									orderReplacedPart= {orderReplacedPart}
									setSelectedReplacedProduct= {setSelectedReplacedProduct}
									disableDOAInput= {disableDOAInput}
									firstProduct= {firstProduct}
									openModalProduct={openModalProduct}
								/>
							</div>
						</div>
						{
							showModal && 
							<ActionOnDeviceModal 
								{...props}
								showModal= {showModal}
								firstProduct= {firstProduct}
								closeRemarkModal= {closeRemarkModal}
								getSerializedParts= {getSerializedParts}
								currentDOAPart= {currentDOAPart}
								deviceIssuing= {deviceIssuing} setDeviceIssuing= {setDeviceIssuing}
								showErrors= {showErrors}
								validateImeiOne= {validateImeiOne}
								validateImeiTwo= {validateImeiTwo}
								validateSerialNumber= {validateSerialNumber}
								updateDeviceAction= {updateDeviceAction}
								disableSaveIssue={disableSaveIssue}
							/>
						}
					</PageContentWrapper>
				</div>
			</div>
		</>
	)
}

export default DeadOnArrival
