import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Input } from 'sfy-react';
import Datetime from 'react-datetime';
import Swal from 'sweetalert2';
import {find} from 'lodash';
import moment from 'moment';
import _ from 'lodash';

import { displayError, updateQuestions } from '../../../../../Utility/Utils';
import QuestionLookup from '../../../../../components/QuestionLookup/QuestionLookup';
import RepairNotesModal from '../RepairNotesModal/RepairNotesModal';

export default function GSXRaiseRequest(props) {
	const {
		localString, setLoader, apiCall, partsRequested, consumerServiceRequest, checkLoanerRequest, getRequestById,
		closeModal, getPartsList, nextStatus, repairNotes, setRepairNotes,isSameNotes, setIsSameNotes, questionDetails, setQuestionDetails, mainQuery, setMainQuery,
		alreadyMarked, setAlreadyMarked, selectedRepair, setSelectedRepair, saveRepairNotes, repairComplete = () => {}, repairCheckList, setRepairCheckList, displayNotesModal, 
		setDisplayNotesModal,receivedDateTime,setReceivedDateTime,closeRepairNotesModal, raiseGSXRequest, selectedClassification, setSelectedClassification, selectedCoverage,
		setSelectedCoverage, selectedSPart, setSelectedSPart, setConsumerServiceRequest, ServiceApproach, appleDropOffFlow
	} = props;

	const [repairType, setRepairType] = useState('');
	const [allRepairTypes, setAllRepairTypes] = useState('');
	const [allClassificationCodes, setAllClassificationCodes] = useState([]);
	const [coverageOptionRequired, setCoverageOptionRequired] = useState(false);
	const [classificationCodeRequired, setClassificationCodeRequired] = useState(false);
	const [selectedClassificationType, setSelectedClassificationType] = useState(null);
	const [coverageOption, setCoverageOption] = useState('');
	const [coverageOptionObj, setCoverageOptionObj] = useState(null);
	const [coverageOptions, setCoverageOptions] = useState([]);
	const [sPart, setSPart] = useState(null);

	const [sPartList, setSPartList] = useState([]);
	const [disableRepairType, setDisableRepairType] = useState(false);
	const [disableUpdateRepair, setDisableUpdateRepair] = useState(false);
	const [disableCoverageOption, setDisableCoverageOption] = useState(false);
	
	const [minDate, setMinDate] = useState(new Date());
	const [maxDate, setMaxDate] = useState(new Date());
	const [blockNTF, setBlockNTF] = useState(false);

	const handleRepairTypeChange = (repairTypeObj) => {
		setDisableUpdateRepair(false);
		if(checkLoanerRequest() && repairTypeObj && repairTypeObj.repairTypeId != 2) {
			setRepairType('');
			setSelectedRepair(null);
			setCoverageOptionRequired(false);
			Swal.fire({
				icon: "error",
				text: localString('Please cancel the loaner request to select this repair type.'),
			});
			return;
		}
		setRepairType(repairTypeObj.value);
		setSelectedRepair(repairTypeObj);
		if (repairTypeObj.repairTypeId == 3) {
			setCoverageOptionRequired(false);
			setClassificationCodeRequired(repairTypeObj.classificationNeeded);
			loadRepairCheckList(repairTypeObj);
		}else if(repairTypeObj.repairTypeId == 2) {
			setCoverageOptionRequired(true);
			setClassificationCodeRequired(false);
			setSelectedCoverage(null);
			getCoverageCodes();
			getPartsForMailin();
			setSPart('');
			setCoverageOption('')
			setCoverageOptionObj(null);
			setDisableUpdateRepair(true);
		}else {
			setCoverageOptionRequired(false);
			setCoverageOption('');
			setCoverageOptionObj(null);
			setSelectedCoverage(null);
			setSPart('');
			setSelectedSPart(null);
		}

	}
	
	const onSelectRepairType = (repairType) => {
		setSelectedRepair(repairType);
		setRepairType(repairType.repairTypeCode + ' ' + repairType.description);
		setDisableRepairType(true);
		if(repairType.repairTypeId == 3) {
			setCoverageOptionRequired(false);
			setClassificationCodeRequired(repairType.classificationNeeded);
			loadRepairCheckList(repairType);
		}else if(repairType.repairTypeId == 2) {
			setCoverageOptionRequired(true);
			setClassificationCodeRequired(false);
			setSelectedCoverage(null);
			if(consumerServiceRequest?.gsxRepairRequest?.coverageOption) {
				setCoverageOption(consumerServiceRequest.gsxRepairRequest.coverageOption+'-'+consumerServiceRequest.gsxRepairRequest.coverageDescription);
			}
			if(consumerServiceRequest.gsxRepairRequest.sPartCode) {
				setSPart(consumerServiceRequest.gsxRepairRequest.sPartCode+'-'+consumerServiceRequest.gsxRepairRequest.sPartName)
			}
			getCoverageCodes();
			getPartsForMailin();
			loadRepairCheckList(repairType);
		}else {
			setCoverageOptionRequired(false);
			loadRepairCheckList(repairType);
		}

	}

	const loadRepairCheckList = (repairType) => {
		let data = {
			repairTypeId: repairType.repairTypeId,
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			device: {
				id: consumerServiceRequest.consumerProduct.AlternateUniqueKey ? consumerServiceRequest.consumerProduct.AlternateUniqueKey : consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue
			}
		}
		if(appleDropOffFlow) {
			data.flowType = "DROPOFF";
			data.OriginServiceLocationID = consumerServiceRequest.OriginServiceLocationID;
		}
		if(repairType.nreType) data.nreType = repairType.nreType;
        setLoader(true);
		apiCall({
			url: "getGsxRepairChecklist",
			data: data,
			callBack: (result) => {
				setBlockNTF(false);
				setLoader(false);
				if(result.success) {
                    if(result.data) {
						let checkList = [];
						if(result.data.checkList) {
							result.data.checkList.map((item, i) => {
								let obj = {...item}
								if(item.options && item.options.length){
									let index = item.options.findIndex(i => i.Value);
									if(index == -1) {
										obj.options[0].checked = true;
									}else {
										obj.options[index].checked = true;
									}
								}
								checkList.push(obj);
							});
						}
						setRepairCheckList(checkList);
						let arr = updateQuestions(result.data.questionDetails) || [];
						setQuestionDetails(arr);
						setMainQuery(arr);
					}
				}else {
					setBlockNTF(false);
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});

	}

	const getCoverageCodes = () => {
        let data = {}
        setLoader(true);
		apiCall({
			url: "getMailinCoverageOptions",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if(result.success) {
                    if(result.data && result.data.gsxCoverageOptions && result.data.gsxCoverageOptions.length > 0) {
						setCoverageOptionRequired(true);
						let arr = [...result.data.gsxCoverageOptions].map(a => ({...a, value: (a.CoverageOption + ' - ' + a.CoverageDescription)}));
						setCoverageOptions(arr);
						let selCoverage = selectedCoverage;
						if(selCoverage?.CoverageOption) {
							setCoverageOption(selectedCoverage.CoverageOption+'-'+selectedCoverage.CoverageDescription);
						}else if(consumerServiceRequest.gsxRepairFlags.repairTypeId == 2) {
							selCoverage = _.filter(arr, { CoverageOption: consumerServiceRequest.gsxRepairRequest.coverageOption})[0];
						}
						if(selCoverage) {
							setSelectedCoverage(selCoverage);
						}
					}
				}else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

	const getPartsForMailin = () => {
		let data = {}
		data['ConsumerServiceRequestID'] = consumerServiceRequest.ConsumerServiceRequestID;
		//data['GsxCoverageOptionID'] = coverage.GsxCoverageOptionID;
		setLoader(true);
		apiCall({
			url: "getPartsForMailin",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if(result.success) {
					let arr = result.data.sPart.map((a) => ({...a, value: a.PartCode+"-"+a.Description }));
					setSPartList(arr);
					let part;
					if(((selectedRepair?.repairTypeId && (selectedRepair.repairTypeId == 2)) || consumerServiceRequest.gsxRepairFlags.repairTypeId == 2 ) && arr.length) {
						part = _.filter(arr, { 'PartCode': consumerServiceRequest.gsxRepairRequest.sPartCode})[0];
					}
					part && setSelectedSPart(part);
					if(part && part.PartCode) {
						setSPart(part.PartCode+"-"+part.Description);
					}
					// initSPartDropDown();
				}else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	}

	const getRepairTypes = () => {
        let data = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"isSerializedProduct": consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.isSerializedProduct,
			"RepairCreatedAtDropOffLocation":consumerServiceRequest.CREATED_AT_DROPOFF_PSL=="true"?true:undefined,
		}
        setLoader(true);
		apiCall({
			url: "getRepairTypes",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if(result.success) {
                    if(result.data) {
						let arr = result?.data?.repairType?.map(a => ({...a, value: (a.repairTypeCode + ' ' + a.description)}));
						setAllRepairTypes(arr);
						let arr1 = result?.data?.ClassificationCode?.map(a => ({...a, value: a.classificationCode + ' ' + a.description}));
						setAllClassificationCodes(arr1);
						if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && consumerServiceRequest.ProvisionalGSXNumber && find(consumerServiceRequest.logs,{Status: 'Estimation rejected'}) && consumerServiceRequest.Status=="Estimation generated"){
							onSelectRepairType(_.filter(arr, {nreType:"CUA"})[0]);
						}else if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true" && !consumerServiceRequest.ProvisionalGSXNumber){
							if(ServiceApproach.repairTypeId){
								onSelectRepairType(_.filter(arr, { repairTypeId: ServiceApproach.repairTypeId,nreType:ServiceApproach.nreType})[0]);
							}else{
								onSelectRepairType(_.filter(arr, { repairTypeId: consumerServiceRequest.gsxRepairFlags.repairTypeId,nreType:consumerServiceRequest.gsxRepairFlags.nreType})[0]);
							}
							
						}else if(consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId && partsRequested.length){
							onSelectRepairType(_.filter(arr, { repairTypeId: consumerServiceRequest.gsxRepairFlags.repairTypeId})[0]);
						}else {
							if(consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId){
								let selectedRepairType = _.filter(arr, { repairTypeId: consumerServiceRequest.gsxRepairFlags.repairTypeId})[0];
								if(selectedRepairType){
									setRepairType(selectedRepairType.repairTypeCode + ' ' + selectedRepairType.description);
									setSelectedRepair(selectedRepairType);
									if(selectedRepairType.repairTypeId == 2) {
										setCoverageOptionRequired(true);
										setClassificationCodeRequired(false);
										getCoverageCodes();
										getPartsForMailin();
										if(consumerServiceRequest?.gsxRepairRequest?.coverageOption) {
											setCoverageOption(consumerServiceRequest.gsxRepairRequest.coverageOption+'-'+consumerServiceRequest.gsxRepairRequest.coverageDescription)
										}
										if(consumerServiceRequest.gsxRepairRequest.sPartCode){
											setSPart(consumerServiceRequest.gsxRepairRequest.sPartCode+'-'+consumerServiceRequest.gsxRepairRequest.sPartName);
										}
									}
								}
								setDisableRepairType(false);
								setDisableCoverageOption(false);
							}
						}
					}
				}else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

	const getDateValues = () => {
		let dateVal;
		if(consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.length > 0) {
			dateVal = _.findLast(consumerServiceRequest.logs, function (o) { return o.Status == 'Device received'}).CreatedDate;
		}
		let receivedTime = new Date(dateVal);
		let defaultDate = new Date();
		let mindateTime = new Date();
		let min = mindateTime.getMinutes() - 15;
		defaultDate.setMinutes(min);
		setMinDate(receivedTime ? receivedTime : '1970-01-01 00:00:00');
		setReceivedDateTime(receivedTime > defaultDate ? receivedTime : defaultDate);
		setMaxDate(new Date());
	}

	useEffect(() => {
		getRepairTypes();
		getDateValues();
	}, []);

	const repairCheckListClick = (e, i) => {
		let arr = [...repairCheckList];
		arr[i].Value = e.target.checked;
		if(!e.target.checked && arr[i].required) {
			arr[i].required.Value = '';
		}
		setRepairCheckList(arr);
	}

	const handleChange = (e, i) => {
		let arr = [...repairCheckList];
		arr[i].required.Value = e.target.value;
		setRepairCheckList(arr);
	}	

	const handleRadioBtn = (e, index, optionIndex) => {
		let arr = [...repairCheckList];
		let optionsArr = [];
		arr[index].options.map((item, key) => {
			let obj = {...item}
			if(key == optionIndex) {
				obj.checked = true;
				obj.Value = true;
			}else {
				obj.checked = false;
				obj.Value = false;
			}
			optionsArr.push(obj);
		})
		arr[index].options = optionsArr;
		setRepairCheckList(arr);
	}

	const openRepairNotesModal = () => {
		if(questionDetails && questionDetails.length && ((consumerServiceRequest && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId && consumerServiceRequest.gsxRepairFlags.repairTypeId == 3) || (selectedRepair && selectedRepair.repairTypeId == 3))) {
			if(!isQuestionDetailsValid()) {
				Swal.fire({
					icon: "error",
					text: localString("Please enter Required Fields (*)."),
					confirmButtonText: localString('OK')
				});
				setAlreadyMarked(false);
				return;
			}
		}
		setRepairNotes('');
		setIsSameNotes(false);
		setDisplayNotesModal(true);
	}

	const isQuestionDetailsValid = () => {
		let valid = true;
		const checkQuery = (questions) => {
			if(questions.length) {
				questions.forEach(obj => {
					if(!obj.isSelect && !obj.optional) {
						valid = false;
					}else if(obj.isSelect && obj.answers && obj.answers.length) {
						if(valid) {
							obj.answers.forEach(ans => {
								if(ans.isSelect && ans.questions && ans.questions.length) {
									checkQuery(ans.questions);
								}
							})
						}
					}
				})
			}
 		}

		questionDetails.forEach(questionObj => {
			questionObj.trees.forEach(treeObj => {
				if(treeObj.questions && treeObj.questions.length) {
					if(valid) {
						checkQuery(treeObj.questions);
					}
				}
			});
		});
		return valid;
	}


	const updateRepairType = () => {
		setLoader(true);
		let data = {}
		data['ConsumerServiceRequestID'] = consumerServiceRequest.ConsumerServiceRequestID;
		data['repairType'] = selectedRepair;
		data['GsxCoverageOption'] = selectedCoverage ? selectedCoverage : undefined;
		data['sPart'] = selectedSPart ? selectedSPart : undefined;
		apiCall({
			url: "updateGsxRepairType",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if(result.success) {
                    let part = {
						item: {
							Type:"Part"
						},
						repairTypeId: selectedRepair.repairTypeId
					}
					let csr = {...consumerServiceRequest};
					if (consumerServiceRequest && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId) {
						csr.gsxRepairFlags.repairTypeId = selectedRepair.repairTypeId;
					}
					if(consumerServiceRequest && consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.coverageOption && selectedCoverage) {
						csr.gsxRepairRequest.coverageOption = selectedCoverage.CoverageOption;
					}
					if(consumerServiceRequest && consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.coverageDescription && selectedCoverage) {
						csr.gsxRepairRequest.coverageDescription = selectedCoverage.CoverageDescription;
					}
					if(consumerServiceRequest && consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.sPartCode && selectedSPart) {
						csr.gsxRepairRequest.sPartCode = selectedSPart.PartCode;
					}
					if(consumerServiceRequest && consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.sPartName && selectedSPart) {
						csr.gsxRepairRequest.sPartName = selectedSPart.Description;
					}
					if(!consumerServiceRequest.gsxRepairFlags) {
						csr.gsxRepairFlags = { repairTypeId: selectedRepair.repairTypeId};
					}else if(consumerServiceRequest.gsxRepairFlags && !consumerServiceRequest.gsxRepairFlags.repairTypeId) {
						csr.gsxRepairFlags.repairTypeId = selectedRepair.repairTypeId;
					}
					setConsumerServiceRequest(csr);
					if(selectedRepair.isPartsAllowed){
						getPartsList && getPartsList(part);
					}
					closeModal();
				}else {
					setAlreadyMarked(false);
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	}

	return (<>
		<div className='' style={{ padding: '20px 80px' }}>
			<div className='row'>
				{
					consumerServiceRequest.Status !== "Estimation rejected" && partsRequested && (partsRequested.length > 0) && <div style={{ marginBottom: '25px', marginLeft: '15px' }}>
						<p>{localString("If you want to update Repair Type. Please remove parts.")}</p>
					</div>
				}
			</div>
			<div className='row'>
				<div className='col-sm-4'>
					<div className="form-group">
						<label>{localString("Please select Repair Type")}</label>
						<Dropdown
							value={repairType}
							options={allRepairTypes}
							handleOnChange={(data) => handleRepairTypeChange(data)}
							filterBy='value'
							showDownArrow={false}
							isDisabled={disableRepairType}
							showTooltip={true}
						/>
					</div>
				</div>
				{ !!classificationCodeRequired && <div className='col-sm-4'>
					<div className="form-group">
						<label>{localString("Please select Classification Type")}</label>
						<Dropdown
							value={selectedClassificationType}
							options={allClassificationCodes}
							handleOnChange={(data) => {
								setSelectedClassificationType(data.value);
								setSelectedClassification(data);
							}}
							filterBy='value'
							showDownArrow={false}
							showTooltip={true}
							// isDisabled={disableRepairType}
						/>
					</div>
				</div>}
				{ coverageOptionRequired && <div className='col-sm-4'>
					<div className="form-group">
						<label>{localString("Please select Coverage Option")}</label>
						<Dropdown
							value={coverageOption}
							options={coverageOptions}
							handleOnChange={(data) => {
								setCoverageOption(data.value);
								setCoverageOptionObj(data);
								setSelectedCoverage(data);
								//getPartsForMailin(data);
							}}
							filterBy='value'
							showDownArrow={false}
							showTooltip={true}
							// isDisabled={disableRepairType}
						/>
					</div>
				</div>}
				{ coverageOptionRequired && <div className='col-sm-4'>
					<div className="form-group">
						<label>{localString("Please select Tier part")}</label>
						<Dropdown
							value={sPart}
							options={sPartList}
							handleOnChange={(data) => {
								setSPart(data.value);
								setSelectedSPart(data);
								setDisableUpdateRepair(false);
							}}
							filterBy='value'
							showDownArrow={false}
							showTooltip={true}
							// isDisabled={disableRepairType}
						/>
					</div>
				</div>}
				{/* { (selectedRepair && (!selectedRepair.isPartsAllowed || (partsRequested.length > 0))) && <div className="col-sm-4"> */}
				{ ((!selectedRepair?.isPartsAllowed || (partsRequested.length > 0))) && <div className="col-sm-4">
					<div className="form-group raiseRequestDatePicker">
						<label>{localString("Please Select Device Receiving Time")}</label>
						<Datetime
							value={receivedDateTime}
							inputProps={{
								onKeyDown: e => e.preventDefault()
							}}
							dateFormat={'DD-MM-YYYY'}
							timeFormat={true}
							onChange={(val) => {
								setReceivedDateTime(new Date(val));
								// setClaimFormData({ ...claimFormData, purchaseDate: new Date(val) });
								// if(!val) {
								// 	setError({ ...error, purchaseDate: 'Please Select Purchase Date' });
								// }else {
								// 	setError({ ...error, purchaseDate: null });
								// }
							}}
							isValidDate={(current) => current.isAfter(minDate) && current.isBefore(maxDate)}
							closeOnSelect={true}
						/>
					</div>
				</div> }
			</div>
			<div className='row'>
				<div className='col-sm-12'>
					{ selectedRepair && (!selectedRepair.isPartsAllowed || (partsRequested.length > 0) ) && <div className="repair-checklist-header col-sm-12">
						{ consumerServiceRequest.PopDocuments && consumerServiceRequest.PopDocuments.msg && <div className="info-div"><span>{localString("Warning")} : {consumerServiceRequest.PopDocuments.msg}</span></div>}
					</div>}
				</div>
			</div>
			{ (selectedRepair && (!selectedRepair.isPartsAllowed || (partsRequested.length > 0) ) && repairCheckList && repairCheckList.length > 0 && (appleDropOffFlow || (selectedRepair.repairTypeId != 3))) && <div className="row">
				<div className="col-sm-12 text-center" style={{ padding: '25px 0px' }}>
					<h4>{localString("Repair Checklist")}</h4>
				</div>
			</div> }
			{ (selectedRepair && (!selectedRepair.isPartsAllowed || (partsRequested.length > 0) ) && (appleDropOffFlow || (selectedRepair.repairTypeId != 3))) && <div>
				{ repairCheckList && (repairCheckList.length > 0) && repairCheckList.map((value, i) => <div className='row'>
					{ (value.Type == 'checkBox') && <div className=''>
						<div className={value.required ? "col-sm-4" : "col-sm-12"} style={{ margin: '10px 0px' }}>
							<label for={"repair_checklist_" + i}>
								<input 
									onClick={(e) => repairCheckListClick(e, i)} 
									className="repair-checklist" 
									id={"repair_checklist_" + i} 
									type="checkbox" 
									checked={value.Value} 
									disabled={value.Disabled}
								/>
								{/* <span onClick={checkboxClick}>{value.Description}</span> */}
								<span>{value.Description}</span>
							</label>
						</div>
						{ value.required && <div className={"col-sm-4"}>
							<Input
								value={value.required.Value}
								handleOnChange={(e) => handleChange(e, i)}
								isDisabled={value.Disabled || !value.Value}
								// label={'Enter IMEI Number of your device'}
								// labelAlign='top'
								placeholder={value.required.Description}
							/>
						</div>
						}
					</div> }
					{ (value.Type == 'radioButton') && <div className=''>
						<div className="col-sm-12">
							<label for="repair-checklist">
								<span>{value.Description}</span>
							</label>
						</div>
						{ value.options && value.options.length && value.options.map((radioVal, k) => <div className="col-sm-4">
							<label className="check-label">
								<input 
									type="radio" 
									name={radioVal.Name} 
									onClick={(e) => handleRadioBtn(e, i, k)} 
									checked={radioVal.checked} 
									id={radioVal.Name} 
									style={{ margin: '0', marginRight: '5px'}}
								/>{radioVal.Description}
							</label>
						</div>) }
					</div> }
				</div> )}
			</div> }

			{/* ********* Question Lookup Section ********* */}
			{ (questionDetails && (questionDetails.length > 0) && selectedRepair && (!selectedRepair.isPartsAllowed || (partsRequested.length > 0))) && <div>
				<QuestionLookup 
					mainQuery={[...mainQuery]}
					localString={localString}
					questionDetails={questionDetails} setQuestionDetails={setQuestionDetails}
				/>
			</div> }

			<div className='pull-right'>
				{ (!blockNTF && (((selectedClassification && selectedRepair && selectedRepair.repairTypeId == 3 && selectedRepair.classificationNeeded) || (selectedRepair && selectedRepair.repairTypeId == 3 && !selectedRepair.classificationNeeded)) && (receivedDateTime != '')) && (selectedRepair && !selectedRepair.isPartsAllowed || (partsRequested.length > 0) )) && 
					<Button 
						className="btn button-servify"
						onClick={() => {
							if(appleDropOffFlow && selectedRepair && (selectedRepair.repairTypeId == 3) && (selectedRepair.nreType === 'SRC')) {
								raiseGSXRequest();
							}else {
								openRepairNotesModal();
							}
						}}
						isDisabled={disableUpdateRepair}
					>
						{selectedRepair && selectedRepair.nreType=="SRC" ?localString("Save and Proceed"):localString("Raise GSX Request")}
					</Button>
				}
				{ (((repairCheckList && repairCheckList.length > 0) && (receivedDateTime != '')) && (selectedRepair && selectedRepair.repairTypeId != 3 && (!selectedRepair.isPartsAllowed || (partsRequested.length > 0) ))) && 
					<Button 
						className="btn button-servify"
						onClick={() => raiseGSXRequest()}
						isDisabled={disableUpdateRepair}
					>
						{localString("Raise GSX Request")}
					</Button>
				}
				{ (selectedRepair && !!selectedRepair.isPartsAllowed && (partsRequested?.length == 0) ) && 
					<Button 
						className="btn button-servify"
						onClick={() => updateRepairType()}
						isDisabled={disableUpdateRepair}
					>
						{localString("Continue")}
					</Button>
				}
			</div>
		</div>
		<RepairNotesModal
			{...props}
			setLoader={setLoader}
			showModal={displayNotesModal} 
			submit={() => saveRepairNotes()}
			closeModal={closeRepairNotesModal}
			consumerServiceRequest={consumerServiceRequest}
			repairNotes={repairNotes} setRepairNotes={setRepairNotes}
			isSameNotes={isSameNotes} setIsSameNotes={setIsSameNotes}
		/>
	</>)
}