import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input, Loader, Dropdown, Modal, setAutocomplete } from 'sfy-react';
import { add, cloneDeep, filter, find, max, orderBy } from 'lodash';
import Swal from 'sweetalert2';

import { displayError, regexTest, removeHtmltagfromStrings } from '../../Utility/Utils';
import './AddNewAddress.scss';

export default function AddNewAddress(props) {
	const { 
		localString, address, setAddress, apiCall, pinCodeLengthArr, countryConfig, skipZipCodeCheck, addressformtype, cid, 
		identifier, countryid, countryCode, setLoader, setShowNewAddress, afterAddUpdateAddress, getAddressList, event, gmap,
		csrid, vcsrLayout, updateAddressFetchConsumer, pushAddress, publicUrl,sendAddress
	} = props;
	
	const qFilter = JSON.parse(props.qFilter);
	const autoCompleteRef = useRef(null);
	const [countryCodeArr, setCountryCodeArr] = useState(qFilter.countrycode || []);
	const [country, setCountry] = useState(countryCodeArr.length ? countryCodeArr[0] : 'in');
	const [stateList, setStateList] = useState([]);
	const [stateObj, setStateObj] = useState(null);
	const [districtList, setDistrictList] = useState([]);
	const [districtObj, setDistrictObj] = useState(null);
	const [isBaiduOrAMap, setIsBaiduOrAMap] = useState(country == 'CN');
	const [showFormErrors, setShowFormErrors] = useState(false);
	const configArr = countryConfig.addressConfig || [];
	const [addressConfigArr, setaddressConfigArr] = useState(orderBy(configArr, ['displayOrder']));
	const [addressConfigPreviewArr, setaddressConfigPreviewArr] = useState(orderBy(configArr, ['addressPreviewOrder']));
	const [previewAddress, setPreviewAddress] = useState('');
	const [suggestedPreviewAddress, setSuggestedPreviewAddress] = useState('');
	const [errors, setErrors] = useState({});
	const [updateAddressErrors, setUpdateAddressErrors] = useState({});
	const [autoCompleteVal, setAutoCompleteVal] = useState(null);
	const [verificationData, setVerificationData] = useState(null);
	const [confirmedAddress, setConfirmedAddress] = useState(null);

	const AddressTypeArray = ['Home', 'Office', 'Other'];
	const [addressType, setAddressType] = useState(address?.AddressType || 'Home');
	const [showModal, setShowModal] = useState(false);
	const [showSuggestedModal, setShowSuggestedModal] = useState(false);
	const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
	const [displayErrorModal, setDisplayErrorModal] = useState(false);

	// const isValid = !Object.values(errors).some(e => e != null);

	const callLoggingApi = (apiName, value) => {
		let reqObj = {
			apiName: apiName,
			value: value,
			key: gmap,
			event: event,
			identifiers: [{ 'ConsumerID': cid }]
		}
		if (csrid) {
			reqObj.identifiers.push({ 'ConsumerServiceRequestID' : csrid })
		}
		apiCall({
			url: 'addLogOfApi',
			data: reqObj,
			callBack: () => {},
		});
	}

	const getLandmarklabel = () => {
		let element = addressConfigArr.find((obj) => obj.keyName == 'Landmark');
		return element ? element.label : 'Landmark'
	};

	const checkStateValid = (state) => {
        let selectedState = stateList.find((obj) => { 
			if((obj.State).toLowerCase() == (state).toLowerCase()) {
				setStateObj(obj)
				return obj;
			}
        });
        return selectedState ? true : false;
    };

	const checkDistrictValid = (district) => {
        let selectedDistrict = districtList && districtList.length > 0 && districtList.find((obj) => {
			if((obj.DistrictName).toLowerCase() == (district).toLowerCase()) {
				setDistrictObj(obj);
				return obj;
			}
            return (obj.DistrictName).toLowerCase() == (district).toLowerCase();
        });
        return selectedDistrict ? true : false;
    };

	const isDistrictMandatory = () => {
        let isDistrictMandatory = false;
        if(countryConfig && countryConfig.addressConfig && countryConfig.addressConfig.length > 0) {
            countryConfig.addressConfig.filter((obj)=>{
				if((obj.keyName == 'District') && obj.mandatory && obj.display) {
					isDistrictMandatory = true;
				}
            })
        }
        return isDistrictMandatory ? true : false;
    };

	const checkLengthValid = (value, configElement) => {
		if(configElement && configElement.regexFunc) {
			if(regexTest((configElement.regexFunc).toLowerCase(), value.replace(" ",""))) {
				if(configElement.length.indexOf(value.length) > -1) {
					return true;
				}else {
					return;
				}
			}else {
				return;
			}
		}else if (configElement && configElement.minLength && configElement.minLength <= value.length) {
			return true;
		}else {
			return
		}
	}

	const validateFields = (key, value, returnValue) => {
		let configObj = addressConfigArr.find(item => (item.keyName === key));
		if(configObj) {
			if(!value && configObj.mandatory) { // Mandatory check
				if(returnValue) {
					let obj = { flag: false, msg: localString(configObj.label + " is required") }
					return obj
				}else {
					setErrors({ ...errors, [key]: localString(configObj.label + " is required") })
				}
			}else if(!value && !configObj.mandatory) { // if value is empty and key in !mandatory then pass
				if(returnValue) {
					let obj = { flag: true, msg: null }
					return obj
				}else {
					setErrors({ ...errors, [key]: null });
				}
			// }else if((configObj.regexFunc && ["NUMERIC", "ALPHANUMERIC"].includes(configObj.regexFunc) && !regexTest((configObj.regexFunc).toLowerCase(), value)) || (configObj.length && !configObj.length.includes(value.length))) { //Regex and input length check
			}else if((configObj.regexFunc && ["NUMERIC", "ALPHANUMERIC"].includes(configObj.regexFunc) && configObj.length && !checkLengthValid(value, configObj))) { //Regex and input length check
				if(returnValue) {
					let obj = { flag: false, msg: localString(configObj.label + " is invalid") }
					return obj
				}else {
					setErrors({ ...errors, [key]: localString(configObj.label + " is invalid") })
				}
			}else if(configObj.keyName == "State" && !checkStateValid(value)) { // State Check
				if(returnValue) {
					let obj = { flag: false, msg: localString(configObj.label + " is invalid") }
					return obj
				}else {
					setErrors({ ...errors, [key]: localString(configObj.label + " is invalid") })
				}
			}else if(configObj.keyName == "District" && !checkDistrictValid(value)) { // State Check
				if(returnValue) {
					let obj = { flag: false, msg: localString(configObj.label + " is invalid") }
					return obj
				}else {
					setErrors({ ...errors, [key]: localString(configObj.label + " is invalid") })
				}
			}else {
				if(returnValue) {
					let obj = { flag: true, msg: null }
					return obj
				}else {
					setErrors({ ...errors, [key]: null });
				}
			}
		}
	}

	const validate = () => {
		let errorObj = {...errors}
		let flag = {}

		// Landmark validation
		if(!address || (address && !address.Landmark)) {
			flag.Landmark = false;
			errorObj.Landmark = localString("Landmark is required")
		}else if(address.Landmark && (!address.Lat || address.Lat == 0 || !address.Lng || address.Lng == 0)) {
			flag.Landmark = false;
			errorObj.Landmark = localString("There's a problem with this location, please select a different location")
		}else {
			flag.Landmark = true
			errorObj.Landmark = null
		}

		addressConfigArr && addressConfigArr.map(item => {
			if((item.keyName !== 'Landmark') && item.display) {
				let obj = validateFields(item.keyName, address ? address[item.keyName] : '', true);
				flag[item.keyName] = obj.flag;
				errorObj[item.keyName] = obj.msg;
			}
		}) 
		setErrors(errorObj);
		if(Object.values(flag).some(e => !e)) {
			return false;
		}else {
			return true;
		}
	}

	const getMaxLength = (item) => {
		return item.inputLength ? item.inputLength : (["NUMERIC", "ALPHANUMERIC"].indexOf(item.regexFunc) > -1) && item.length ? max(pinCodeLengthArr) : false 
	}

	// const preparePreviewData = () => {
	// 	let addressPreviewString = '';
	// 	addressConfigPreviewArr.map(addressConfigObj => {
	// 		if (address[addressConfigObj.keyName] != null && address[addressConfigObj.keyName] != '') {
	// 			if(addressPreviewString) {
	// 				addressPreviewString = addressPreviewString + ', ' + address[addressConfigObj.keyName];
	// 			}else {
	// 				addressPreviewString = address[addressConfigObj.keyName];
	// 			}
	// 		}else if(address['Landmark'] && addressConfigObj.keyName == 'UserLandmark') {
	// 			if (addressPreviewString != '') {
	// 				addressPreviewString = addressPreviewString + ', ' + address['Landmark'];
	// 			}
	// 			else {
	// 				addressPreviewString = address['Landmark'];
	// 			}
	// 		}
	// 	})
	// 	setPreviewAddress(addressPreviewString);
	// 	setShowModal(true);
	// }

	const preparePreviewData = () => {
		let previewString = '';
		let arr = cloneDeep(addressConfigPreviewArr); 
		let isUserLandmarkPresent = find(arr, function (o) { return o.keyName == 'UserLandmark'});
		if (isUserLandmarkPresent) {
			arr = filter(arr, function (o) { return o.keyName != 'Landmark'});
		}
		for (let i = 0; i < arr.length; i++) {
			if (arr[i].display) {
				if (address[arr[i].keyName] != '') {
					if (previewString != '') {
						previewString = previewString + ', ' + address[arr[i].keyName];
					}else {
						previewString = address[arr[i].keyName];
					}
				}
			}
		}
		setPreviewAddress(previewString);
		setShowModal(true);
	}

	const prepareSuggestionPreviewData = (addressObject) => {
		var previewString = '';
		let arr = cloneDeep(addressConfigPreviewArr); 
		let isUserLandmarkPresent = find(arr, function (o) { return o.keyName == 'UserLandmark'});
		if (isUserLandmarkPresent) {
			arr = filter(arr, function (o) { return o.keyName != 'Landmark'});
		}
		for (let i = 0; i < arr.length; i++) {
			if (arr[i].display) {
				if (addressObject[arr[i].keyName] != '') {
					if (previewString != '') {
						previewString = previewString + ', ' + addressObject[arr[i].keyName];
					}
					else {
						previewString = addressObject[arr[i].keyName];
					}
				}
			}
		}
		setSuggestedPreviewAddress(previewString);
		setShowSuggestedModal(true);
		// return previewString;
	}

	const closeModal = () => {
		setShowModal(false);
	}

	const getInputLength = (item) => {
		return `${(address && address[item.keyName] && (address[item.keyName]).length) || 0}/${item.inputLength}`
	}

	useEffect(() => {
		const autocomplete = setAutocomplete(autoCompleteRef.current, handleLandmarkSelection)
		return () => {
			window.google.maps.event.clearListeners(autocomplete, 'place_changed')
		}
	}, [handleLandmarkSelection])

	const handleLandmarkSelection = useCallback(
		(value) => {
			setAutoCompleteVal(value);
		}, []
	)

	useEffect(() => {
		if(autoCompleteVal) {
			updateAddress(autoCompleteVal);
		}else {
			let addressObj = {...address};
			if(address && !address.Landmark){
				addressObj.Lat = "";
				addressObj.Lng = "";
			}
			setAddress(addressObj);
		}
	}, [autoCompleteVal]);

	const updateAddress = (placesObj) => {
		// let addressObj = {...address};
		let addressObj = {};
		let errorsObj = {...updateAddressErrors};
		if(placesObj){
			addressObj.Landmark = placesObj && placesObj?.formatted_address ;	
			addressObj.Lat = placesObj && placesObj?.geometry?.location.lat() ;
			addressObj.Lng = placesObj && placesObj?.geometry?.location.lng() ;
		}else{
			if(address && !address.Landmark){
				addressObj.Lat = "";
				addressObj.Lng = "";
			}else{	
				addressObj.Landmark = address?.Landmark ;
				addressObj.Lat = address?.Lat;
				addressObj.Lng = address?.Lng;
			}
		}
		addressObj.UserLandmark = '';
		addressObj.Address = '';
		// addressObj.Landmark = placesObj && placesObj.formatted_address ? placesObj.formatted_address : address.Landmark ;
		// addressObj.Landmark = placesObj && placesObj.formatted_address ;
		placesObj && placesObj.address_components && placesObj.address_components.map(place => {
			if (place && place.types.includes('postal_code') && addressConfigArr.find((obj) => (obj.keyName == 'Zipcode' && obj.display))) {
				addressObj.Zipcode = place.short_name ? place.short_name : ''
				let obj = validateFields('Zipcode', addressObj.Zipcode, true);
				errorsObj.Zipcode = obj.msg;
			}

			if (place && place.types.includes('postal_code') && addressConfigArr.find((obj) => (obj.keyName == 'PinCode' && obj.display))) {
				addressObj.PinCode = place.short_name ? place.short_name : ''
				let obj = validateFields('PinCode', addressObj.PinCode, true);
				errorsObj.PinCode = obj.msg;
			}

			if (place && place.types.includes('country')) {
				addressObj.Country = place.short_name ? place.short_name : ''
			}

			if (place && place.types.includes('administrative_area_level_1') && addressConfigArr.find((obj) => (obj.keyName == 'State' && obj.display))) {
				if(place && place.long_name && checkStateValid(place.long_name)) {
					addressObj.State = place.long_name;
				}else {
					addressObj.State = '';
				}
				let obj = validateFields('State', addressObj.State, true);
				errorsObj.State = obj.msg;
			}

			if (place.types.includes('locality') && addressConfigArr.find((obj) => (obj.keyName == 'City' && obj.display))) {
				addressObj.City = place.long_name ? place.long_name : ''
				let obj = validateFields('City', addressObj.City, true);
				errorsObj.City = obj.msg;
			}

			// if (place && place.types.includes('route') && addressConfigArr.find((obj) => obj.keyName == 'UserLandmark')) {
			// 	addressObj.UserLandmark = place.long_name ? place.long_name : ''
			// 	let obj = validateFields('UserLandmark', addressObj.UserLandmark, true);
			// 	errorsObj.PinCode = obj.msg;
			// }
			if (place && place.types.length && (place.types.includes('route') || place.types.includes('street_number')) && addressConfigArr.find((obj) => (obj.keyName == 'UserLandmark' && obj.display))) {
				let val = addressObj.UserLandmark ? addressObj.UserLandmark : '';
				let name = place.long_name ? place.long_name : '';
				addressObj.UserLandmark = val ? (val + ' ' + name) : name;
				let obj = validateFields('UserLandmark', addressObj.UserLandmark, true);
				errorsObj.UserLandmark = obj.msg;
			}

			if (place && place.types.length && (place.types.includes('subpremise') || place.types.includes('premise')) && addressConfigArr.find((obj) => (obj.keyName == 'Address' && obj.display))) {
				let val = addressObj.Address ? addressObj.Address : '';
				let name = place.long_name ? place.long_name : '';
				addressObj.Address = val ? (val + ' ' + name) : name;
				let obj = validateFields('Address', addressObj.Address, true);
				errorsObj.Address = obj.msg;
			}
			
		});
		// addressObj.Lat = placesObj && placesObj.geometry.location.lat() ? placesObj.geometry.location.lat() : address.Lat;
		// addressObj.Lat = placesObj && placesObj.geometry.location.lat() ;
		// addressObj.Lng = placesObj && placesObj.geometry.location.lng() ;
		setAddress(addressObj);
		setUpdateAddressErrors(errorsObj);
		addressObj?.Landmark && callLoggingApi('PlaceService.GetPlaceDetails', addressObj.Landmark);
	}

	useEffect(() => {
		setErrors({ ...errors, ...updateAddressErrors });
	}, [updateAddressErrors]);

	const getStates = () => {
		// setLoader(true);
		let reqObj = {
			CountryID: countryid
		}
		apiCall({
			url: 'getStates',
			data: reqObj,
			callBack: (result) => {
				// setLoader(false);
				if(result.success && result.data){
					let states = result.data.map(a => ({...a, value: a.State}));
					setStateList(states);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			// errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		getStates();
	}, [])

	const getDistricts = () => {
		// setLoader(true);
		let reqObj = {
			CountryCode: countryCode ? countryCode : undefined,
			StateCode: stateObj && stateObj.StateCode  ? stateObj.StateCode.toString() : undefined
		}
		apiCall({
			url: 'getDistricts',
			data: reqObj,
			callBack: (result) => {
				// setLoader(false);
				if(result.success && result.data){
					let districts = result.data.map(a => ({...a, value: a.DistrictName}));
					setDistrictList(districts);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			// errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		if(address && address.State && isDistrictMandatory()) {
			setAddress({ ...address, District: '' });
			getDistricts();
		}
	}, [address && address.State])

	// Todo Pass this function in props
	const addConsumerAddress = () => {
		setLoader(true);
		let reqObj = {
			...address ,
			ConsumerID: cid,
			AddressType: addressType,
		};

		if(confirmedAddress) {
			reqObj.Address = confirmedAddress.Address;
			reqObj.City = confirmedAddress.City;
			reqObj.State = confirmedAddress.State;
			reqObj.UserLandmark = confirmedAddress.UserLandmark;
			reqObj.Zipcode = confirmedAddress.Zipcode;
			reqObj.verificationSource = verificationData.verificationSource;
		}
		if(verificationData) {
			if(verificationData.verificationStatus === 1) {
				reqObj.verificationSource = verificationData.verificationSource;
			}
			if(verificationData.metaObject) {
				reqObj.metaObject = verificationData.metaObject;
			}
		}

		if(address.State) {
            let selectedState = stateList.find((obj) => obj.State == address.State);
            selectedState && selectedState.StateID && (reqObj.StateID = selectedState.StateID);
        }

		if(address.District) {
            let dist = districtList.find((obj) => obj.DistrictName == address.District);
            dist && dist.DistrictID && (reqObj.DistrictID = dist.DistrictID);
        }

        if(address && address.ConsumerFavouriteLocationID) {
            reqObj.ConsumerFavouriteLocationID = address.ConsumerFavouriteLocationID;
        }

		let obj = removeHtmltagfromStrings(reqObj);
		apiCall({
			url: (address && address.ConsumerFavouriteLocationID ? 'updateLocation' : 'addConsumerLocation'),
			data: obj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data){
					afterAddUpdateAddress && afterAddUpdateAddress(identifier, result.data);
					closeModal();
					setShowNewAddress(false);
					if(identifier !== 'claimAddress') getAddressList();
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString('Something went wrong'),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	// Update below function based on its use case
	const saveAddress = () => {
		let reqObj = {
			...address ,
			ConsumerID: cid,
			AddressType: addressType,
		};

		if(confirmedAddress) {
			reqObj.Address = confirmedAddress.Address;
			reqObj.City = confirmedAddress.City;
			reqObj.State = confirmedAddress.State;
			reqObj.UserLandmark = confirmedAddress.UserLandmark;
			reqObj.Zipcode = confirmedAddress.Zipcode;
			reqObj.verificationSource = verificationData.verificationSource;
		}
		if(verificationData) {
			if(verificationData.verificationStatus === 1) {
				reqObj.verificationSource = verificationData.verificationSource;
			}
			if(verificationData.metaObject) {
				reqObj.metaObject = verificationData.metaObject;
			}
		}

        if(address.State) {
            let selectedState = stateList.find((obj) => obj.State == address.State);
            selectedState && selectedState.StateID && (reqObj.StateID = selectedState.StateID);
        }

		if(address.District) {
            let dist = districtList.find((obj) => obj.DistrictName == address.District);
            dist && dist.DistrictID && (reqObj.DistrictID = dist.DistrictID);
        }

        if(address && address.ConsumerFavouriteLocationID) {
            reqObj.ConsumerFavouriteLocationID = address.ConsumerFavouriteLocationID;
        }

		// Update below function based on its use case
		/* select_address_popup tag ==> */

        if(identifier == 'selectedAddressPopup') {
            sendAddress(address);
			// setLoader(false);
            return;
        }

		setLoader(true);
		let obj = removeHtmltagfromStrings(reqObj);
        apiCall({
			url: (address && address.ConsumerFavouriteLocationID ? 'updateLocation' : 'addLocation'),
			data: obj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data){
                    if (address.ConsumerFavouriteLocationID) {
						updateAddressFetchConsumer(result.data); 
                    } else {
						pushAddress(result.data); 
                    }
					setAddress(null)
					setShowNewAddress(false)

					// Update below function based on its use case
					/* select_address_popup tag ==> */

					// self.parent.selectedListAddress && (self.parent.selectedListAddress = null);
					// self.isAddressProcessed = false;
					// self.clearAddressInputs();
                    Swal.fire({
                        icon:"success",
                        title: '',
                        text: localString("Address updated successfully"),
                        confirmButtonText: localString('OK')
                    })
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString('Something went wrong'),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const checkAddressViaIntegration = () => {
		setLoader(true);
		let reqObj = {
			ConsumerID: cid,
			CountryID: countryid ? countryid : null,
			AddressObject: {
				UserLandmark: address.UserLandmark,
				Address: address.Address,
				City: address.City,
				State: address.State,
				Zipcode: address.PinCode,
			}
		}
		apiCall({
			url: 'verifyAddress',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data){
					let verificationObj = result.data;
					setVerificationData(verificationObj);
					if(verificationObj.verificationStatus === 1) {
						addressformtype === 'addConsumerLocation' ? addConsumerAddress() : saveAddress();
					}else if(verificationObj.verificationStatus === 0) {
						if(verificationObj.suggestionObject && Object.keys(verificationObj.suggestionObject).length > 0) {
							prepareSuggestionPreviewData(verificationObj.suggestionObject);
							setConfirmedAddress(verificationObj.suggestionObject);
						}
					}else if (verificationObj.verificationStatus === -1) {
						setDisplayErrorModal(true);
						// Swal.fire({  
						// 	icon: 'error',  
						// 	text: localString(verificationObj.message),  
						// });
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString('Something went wrong'),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});

	}

	const confirmAddress = () => {
		setShowModal(false);
		if(countryConfig.isAddressVerficationRequired) {
			checkAddressViaIntegration();
		}else {
			addressformtype === 'addConsumerLocation' ? addConsumerAddress() : saveAddress();
		}
	}

	const addressSelected = (isSuggestedAddress) => {
		if(isSuggestedAddress) {
			setConfirmedAddress(verificationData.suggestionObject);
		}else {
			setConfirmedAddress(undefined);
		}
	}

	return (
		<div id='addNewAddress' className={vcsrLayout? 'addNewAddress view-CSR-page': 'addNewAddress'}>
			<div className='row'>
				<div className='col-sm-12'>
					<div className={addressformtype == "addConsumerLocation" ? "form-label" : "request-address-form-label"}>
						<label>{address && address.ConsumerFavouriteLocationID ? localString("Edit") : localString("Add")} {localString("an address")}</label>
					</div>
					<div id={`addressForm-${identifier}`} className={(addressformtype == "addConsumerLocation" ? "newAddressDiv" : "") + (showFormErrors ? " show-input-errors" : "")}>
					{/* <div id={`addressForm-${identifier}`} className={(addressformtype == "addConsumerLocation" ? "fluid-form-content fluid-form-active" : "") + (showFormErrors ? " show-input-errors" : "")}> */}
						<div className="row">
							<div className={ `col-sm-8 ${addressformtype == "addConsumerLocation" ? "col-sm-offset-2 " : "add-address-margin "} address-flex-container`}>
								<div className="form-group" style={{ order: '-1' }}>
									<Input
										value={address ? address.Landmark : ''}
										handleOnChange={(e) => {
											setAddress({ ...address, Landmark: e.target.value, Lat: '', Lng: '' });
											validateFields('Landmark', e.target.value);
											callLoggingApi('AutocompletionService.GetPredictions', e.target.value);
										}}
										placeholder={localString("Enter a location")}
										id={'Landmark'}
										label={localString(getLandmarklabel())}
										labelAlign='top'
										message={{type: 'error', value: errors.Landmark}}
										className={{
											formControl: 'form-group',
											label: 'mb-5',
											containerClass: 'input-container',
											inputClass: 'input',
											message: 'errMessage'
										}}
										reference={autoCompleteRef}
									/>
								</div>
								{
									addressConfigArr && addressConfigArr.length > 0 && addressConfigArr.map((item, k) => {
										if(item.keyName != "Landmark" && item.keyName != "State" && item.keyName != "District" && item.display == true) {
											return (
												<div className="form-group" key={k} style={{ order: '-1' }}>
													<Input
														value={(address && address[item.keyName]) ? address[item.keyName] : ''}
														handleOnChange={(e) => {
															setAddress({ ...address, [item.keyName]: e.target.value })
															validateFields(item.keyName, e.target.value)
														}}
														maxLength={getMaxLength(item)}
														label={localString(item.label)}
														id={'addressField-'+k}
														labelAlign='top'
														message={{type: 'error', value: errors[item.keyName]}}
														className={{
															formControl: 'form-group',
															label: 'mb-5',
															containerClass: 'input-container',
															inputClass: 'input',
															message: 'errMessage'
														}}
													>
														{ item.inputLength && <span className='character-limit pull-right'>{ getInputLength(item)}</span>}
													</Input>
												</div>
											)
										}else if(item.keyName === "State" && item.display == true) {
											return (
												<div className="form-group" key={k} style={{ order: '-1' }}>
													<label>{localString(item.label)}</label>
													<Dropdown
														value={address && address[item.keyName]}
														options={stateList}
														id={'state-'+k}
														handleOnChange={(data) => {
															setAddress({ ...address, State: data.State, StateID: data.StateID, District: '' });
															setStateObj(data);
															validateFields(item.keyName, data.State)
														}}
														filterBy='value'
														showDownArrow={false}
													/>
													{ errors[item.keyName] && <span className="error errMessage">{localString(errors[item.keyName])}</span>}
												</div>
											)
										}else if(item.keyName === "District" && item.display == true) {
											return (
												<div className="form-group" key={k} style={{ order: '-1' }}>
													<label>{localString(item.label)}</label>
													<Dropdown
														value={address && address[item.keyName]}
														options={districtList}
														id={'district-'+k}
														handleOnChange={(data) => {
															setAddress({ ...address, District: data.DistrictName });
															validateFields(item.keyName, data.DistrictName);
														}}
														filterBy='value'
														showDownArrow={false}
													/>
													{ errors[item.keyName] && <span className="error errMessage">{localString(errors[item.keyName])}</span>}
												</div>
											)
										}
									})
								}
								<div className="row" style={{ order: '100' }}>
									{
										AddressTypeArray && AddressTypeArray.length > 0 && AddressTypeArray.map((item, k) => 
											<div className="col-xs-4" onClick={() => setAddressType(item)}>
												<Button type="button" className={`btn button-servify selectaddress item address-type-item ${(item === addressType) ? 'item-active' : ''}`}>
													<p>{localString(item)}</p>
												</Button>
											</div>
										)
									}
								</div>
							</div>
						</div>
						<div>
							<div className={`pull-right ${identifier == 'selectedAddressPopup'? 'btnMargin' :''}`}>
								{ addressformtype != "addConsumerLocation" && 
									<Button 
										className="btn button-servify-solid" 
										onClick={() => {
											setAddress(null);
											setErrors({});
										}}
									>
										{localString("Clear")}
									</Button> 
								}
								<Button 
									onClick={() => {
										setShowNewAddress(false);
										setAddress(null);
									}} 
									type="button" 
									className="btn button-servify"
								>
									{localString("Cancel")}
								</Button>
								<Button 
									type="button" 
									onClick={() => {
										if(validate()) {
											preparePreviewData();
										}
									}} 
									className={`btn button-servify ${addressformtype == "addConsumerLocation" ? "button-next" : ""}`}
								>
									{addressformtype != "addConsumerLocation" ? localString("Save") : ""}
								</Button>
							</div>
						</div>
					</div>

				</div>
			</div>
			{/* ********** Preview Address ********** */}
			<Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
				<Modal.Header>
					<h4 className="modal-title text-center">{localString("Preview Address")}</h4>
				</Modal.Header>
				<Modal.Body>
					<p className="">{localString("Please ensure the below address is correct")}</p>
					<div className="address-preview-container">
						<div className="address-preview">{previewAddress}</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="pull-right">
						<Button 
							type="button" 
							className="btn button-servify"
							onClick={() => closeModal()} 
						>
							{localString("Edit")}
						</Button>
						<Button 
							type="button" 
							onClick={() => confirmAddress()} 
							className="btn button-servify"
						>
							{localString("Proceed")}
						</Button>
					</div>				
				</Modal.Footer>
			</Modal>
			{/* ********** Standardized Address ********** */}
			<Modal showModal={showSuggestedModal} closeModal={() => setShowSuggestedModal(false)} showCloseBtn={true} className='Filter-Modal standardAddressModal'>
				<Modal.Body>
					<div className='text-center' style={{ paddingTop: '20px' }}>
						<img 
							src={publicUrl + "/imgs/WarningCircle.svg"}
							width='60px' 
						/>
					</div>
					<h4 className="modal-title">
						{verificationData && verificationData.heading ? localString(verificationData.heading) : ''}
					</h4>
					<p className="text-center" style={{ paddingBottom: '10px' }}>
						{verificationData && verificationData.message ? localString(verificationData.message) : ''}
					</p>
					<div class={confirmedAddress ? "selectedAddress addressBox" : "addressBox"} onClick={() => addressSelected('suggestedAddress')}>
						<div className="">
							<input 
								type="radio" 
								name="addressradio" 
								id="suggested_address" 
								checked={confirmedAddress}
								onClick={() => addressSelected('suggestedAddress')}
							/>
							<label htmlFor="suggested_address" style={{ paddingLeft: '10px' }}>{localString("Standardized Address")}:</label>
						</div>
						{ suggestedPreviewAddress && <div>{suggestedPreviewAddress}</div>}
					</div>
					<div class={!confirmedAddress ? "selectedAddress addressBox" : "addressBox"} onClick={() => addressSelected()}>
						<div className="">
							<input 
								type="radio" 
								name="addressradio" 
								id="entered_Address" 
								checked={!confirmedAddress}
								onClick={() => addressSelected()}
							/>
							<label htmlFor="entered_Address" style={{ paddingLeft: '10px' }}>{localString("Entered Address")}:</label>
						</div>
						<div>{previewAddress}</div>
					</div>
					<div style={{ marginTop: '25px' }}>
						<div className="text-center">
							<Button 
								type="button" 
								className="btn button-servify editBtn"
								onClick={() => setShowSuggestedModal(false)} 
							>
								{localString("Edit Address")}
							</Button>
						</div>		
						<div className='text-center'>
							<Button 
								type="button" 
								// onClick={() => addressformtype === 'addConsumerLocation' ? addConsumerAddress() : saveAddress()} 
								onClick={() => {
									setDisplayConfirmModal(true);
									setShowSuggestedModal(false);
								}} 
								className="btn button-servify proceedBtn"
							>
								{localString("Proceed Anyway")}
							</Button>
						</div>		
					</div>
				</Modal.Body>
			</Modal>
			{/* ********** Confirmation Modal ********** */}
			<Modal showModal={displayConfirmModal} closeModal={() => setDisplayConfirmModal(false)} showCloseBtn={true} className='Filter-Modal standardAddressModal'>
				<Modal.Body>
					<div className='text-center' style={{ paddingTop: '20px' }}>
						<img 
							src={publicUrl + "/imgs/QuestionMark.svg"}
							width='60px' 
						/>
					</div>
					<h4 className="modal-title">
						{localString("Are You Sure You Want to Proceed?")}
					</h4>
					<p className="text-center" style={{ paddingBottom: '10px' }}>
						{/* {verificationData && verificationData.message ? localString(verificationData.message) : ''} */}
						{localString("This address seems to have incomplete or incorrect information. Kindly verify the address with the customer before proceeding.")}
					</p>
					<div style={{ marginTop: '25px' }}>
						<div className="text-center">
							<Button 
								type="button" 
								className="btn button-servify editBtn"
								onClick={() => setDisplayConfirmModal(false)} 
							>
								{localString("Edit Address")}
							</Button>
						</div>		
						<div className='text-center'>
							<Button 
								type="button" 
								// onClick={() => addressformtype === 'addConsumerLocation' ? addConsumerAddress() : saveAddress()} 
								onClick={() => addressformtype === 'addConsumerLocation' ? addConsumerAddress() : saveAddress()} 
								className="btn button-servify proceedBtn"
							>
								{localString("Proceed")}
							</Button>
						</div>		
					</div>
				</Modal.Body>
			</Modal>
			<Modal showModal={displayErrorModal} closeModal={() => setDisplayErrorModal(false)} showCloseBtn={true} className='Filter-Modal standardAddressModal'>
				<Modal.Body>
					<div className='text-center' style={{ paddingTop: '20px' }}>
						<img 
							src={publicUrl + "/imgs/CancelCircle.png"}
							width='60px' 
						/>
					</div>
					<h4 className="modal-title">
						{/* {localString("Are You Sure You Want to Proceed?")} */}
						{verificationData && verificationData.message ? localString(verificationData.message) : ''}
					</h4>
					{/* <p className="text-center" style={{ paddingBottom: '10px' }}>
						{verificationData && verificationData.message ? localString(verificationData.message) : ''}
					</p> */}
					<div className={"addressBox"} style={{ padding: '20px' }}>
						<div>
							{localString('Entered Address')}
						</div>
						{previewAddress}
					</div>
					<div>
					</div>
					<div style={{ marginTop: '25px', marginBottom: '5px' }}>
						<div className="text-center">
							<Button 
								type="button" 
								className="btn button-servify editBtn"
								onClick={() => setDisplayErrorModal(false)} 
							>
								{localString("Edit Address")}
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}
