import React, { useCallback, useMemo } from "react";
import { Carousel } from "sfy-react";

const DiagnosticImagesCarousel = (props) => {
	const { imagesArray } = props;

	const bannerList = useMemo(() => {
		let list = [];

		if (imagesArray && imagesArray.length > 0) {
			list = imagesArray.filter(
				(imgObject) => imgObject.SuperimposedHeatmaps
			);
		}

		return list;
	}, [imagesArray]);

	const Banner = useCallback(({ src }) => {
		return (
			<div className="diagnostic-images-banner-container">
				<div className="diagnostic-image-container">
					<img src={src} alt="image" />
				</div>
			</div>
		);
	}, []);

	return (
		<div className="diagnostic-images-carousel-container">
			<Carousel
				items={bannerList}
				autoPlay={false}
				className={{ arrow: "slider-btn-color" }}
				showDots
				individualBanner={(item) => (
					<Banner src={item.SuperimposedHeatmaps} />
				)}
			/>
		</div>
	);
};

export default DiagnosticImagesCarousel;
