import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'sfy-react';
import Score from '../../../components/Score/Score';
import { SERVICETYPE } from '../../../Utility/Utils';

import './ServiceLocation.scss';

export default function ServiceLocation(props) {
	const {
		localString, showServiceLocationsCarryIn, serviceLocationList, showServiceLocationsDropOff, dropoffLocationList,
		serviceLocation, setServiceLocation, dropoffLocation, setDropoffLocation, showNoService, getLocations, serviceMode,
		showInstructions, getScheduleDateAndSlot, setShowSlotSelection, type='raiseRequest', activeStepIndex, setActiveStepIndex,
		setActiveStep, setCallGetScheduleDateAndSlotApi, checkSuppressLogistics, address, publicUrl, setCallRequestFunction,isSupressLogisticsCutomerToASC
	} = props;

	const [sortedServiceLocationList, setSortedServiceLocationList] = useState([]);
	const [sortVal, setSortVal] = useState('Rating');
	const [sortOptions, setSortOptions] = useState([
		{
			value: 'Distance'
		},
		{
			value: 'Rating'
		}
	]);
	const [ascOrder, setAscOrder] = useState(false);
	const returnCopyFromInt = int => int ? 'Yes' : 'No';

	const serviceLocationClick = (location) => {
		setServiceLocation(location);
        //if ([SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].includes(serviceMode.ServiceTypeID)) {
            //showInstructions();
        // }else {
			// setScreenToViewDiv('scheduleDateAndTime');
        //}
		if(type == 'raiseRequest') {
			checkSuppressLogistics();
		}else {
			if ([SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].includes(serviceMode.ServiceTypeID)) {
				showInstructions();
			}else if([SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(serviceMode.ServiceTypeID) > -1) {
				setCallRequestFunction && setCallRequestFunction(true);
			}else if(serviceMode.logisticsSuppressed || isSupressLogisticsCutomerToASC) {
				setCallRequestFunction && setCallRequestFunction(true);
			}else {
				setActiveStepIndex && setActiveStepIndex(activeStepIndex + 1);
				setActiveStep && setActiveStep('dateAndSlotSelection');
				// getScheduleDateAndSlot();
				setCallGetScheduleDateAndSlotApi(true);
				setShowSlotSelection(true);
			}
		}
	}

	const getWorkingDays = (workingHours) => {
    	let workingDays = '';
    	for (let i = 0; i < workingHours.length; i++) {
			let isActiveSlot = false;
			for (let j = 0; j < workingHours[i].slots.length; j ++) {
				if (workingHours[i].slots[j].IsActive) {
					isActiveSlot = true;
					break;
				}
			}
			if (isActiveSlot == true) {
				workingDays += i == 0 ? workingHours[i].Day : (', '+workingHours[i].Day)
			}
		}
		return workingDays;
    }

	const openGmapLink = (e, location) => {
		e.stopPropagation();
		window.open(`https://www.google.com/maps/dir/?api=1&origin=${address.Lat},${address.Lng}&destination=${location.Lat},${location.Lng}`);
	}

	const sortLocations = () => {
		let arr = [];
		let excludedItems = [];
		if(sortVal == 'Rating') {
			serviceLocationList.map((item) => {
				if(item.hasOwnProperty('score') && (item.score || item.score === 0)) {
					arr.push(item);
				}else {
					excludedItems.push(item);
				}
			})
			if(ascOrder) {
				arr.sort((a, b) => a.score - b.score);
			}else {
				arr.sort((a, b) => b.score - a.score);
			}
		}else {
			serviceLocationList.map((item) => {
				if(item.hasOwnProperty('distance') && (item.distance || item.distance === 0)) {
					arr.push(item);
				}else {
					excludedItems.push(item);
				}
			})
			if(ascOrder) {
				arr.sort((a, b) => a.distance.toFixed(2) - b.distance.toFixed(2));
			}else {
				arr.sort((a, b) => b.distance.toFixed(2) - a.distance.toFixed(2));
			}
		}
		setSortedServiceLocationList([...arr, ...excludedItems]);
	}

	useEffect(() => {
		sortLocations()
	}, [sortVal, ascOrder, serviceLocationList]);

	return (
		<>
			<div id='ServiceLocation' className='raiseReqSection'>
				<div className='serviceLocation'>
					<div className="row">
						<div className="col-sm-6 form-label" style={{ marginBottom: '15px' }}>
							<label>{localString("Select Repair Location")}</label>
						</div>
						<div className="col-sm-6 form-label text-right" style={{ marginBottom: '15px' }}>
							{/* { type == 'raiseRequest' && <Button 
								className="pull-right button-refresh" 
								onClick={() => getLocations()}
							/>} */}
							<span>
								<img 
									className={`sortBtn ${ascOrder ? 'asc' : ''}`} 
									src={publicUrl + "/imgs/sortIcon.svg"}
									onClick={() => setAscOrder(!ascOrder)}
								>
								</img>
							</span>
							<Dropdown
								id='sort'
								value={sortVal}
								options={sortOptions}
								handleOnChange={(data) => {
									setSortVal(data.value);
								}}
								filterBy='value'
								// isSearchable={false}
								classObject={{
									container: 'sortContainer ',
								}}
							/>
						</div>
					</div>  
					<div className='row'>
						{ 
							(showServiceLocationsCarryIn && serviceLocationList && serviceLocationList.length == 0) && (showServiceLocationsDropOff && dropoffLocationList && dropoffLocationList.length == 0) &&
							<div className="col-sm-8 col-sm-offset-2 text-center empty-message">
								{ showServiceLocationsCarryIn ? localString("Oops! There are no verified service centres around.") : showServiceLocationsDropOff ? localString("Oops! There are no dropoff centres around.") : ''}
							</div>
						}
						{/* {
							showServiceLocationsCarryIn && serviceLocationList && serviceLocationList.length && serviceLocationList.map((location, k) => 
								<div className="col-sm-6" key={k} onClick={() => serviceLocationClick(location)}>
									<div className={`item address-item-location  ${(serviceLocation && (location.ClientServiceLocationID ? (serviceLocation.ClientServiceLocationID == location.ClientServiceLocationID) : (serviceLocation.PartnerServiceLocationID == location.PartnerServiceLocationID))) ? 'item-active-border' : ''}`}>
										<div className=''>
											<p className='text-right' style={{ marginBottom: '0px' }}>{location.score && <Score {...props} scoreVal={location.score} color={location?.scoreMeta?.ColourCode}/>}</p>
											<p><label>{localString("Name")}:</label> {location.NameOfFirm}</p>
											<p><label>{localString("Exclusive")}:</label> {returnCopyFromInt(location.IsProductExclusive)}</p>
											<p><label>{localString("Service")}:</label> {localString("Location Name")}: {location.ServiceLocationName}</p>
											<p><label>{localString("Address")}:</label> {location.address}</p>
											<p><label>{localString("Contact")}:</label> {location.ContactNo1}</p>
											<p><label>{localString("Rating")}:</label> {location.rating}</p>
											<p><label>{localString("Brand authorised")}:</label> {returnCopyFromInt(location.BrandAuthorised)}</p>
											<p><label>{localString("Distance from user")}:</label> {location.distance.toFixed(2)} {localString("km")}</p>
											{location && location.Lat && location.Lng && address && <p className='paddingT10'><span onClick={(e) => openGmapLink(e, location)} className='locationLink'>Get Location</span></p>}
										</div>
									</div>
								</div>
								)
						} */}
						{
							// (showServiceLocationsCarryIn || true) && serviceLocationList && serviceLocationList.length && serviceLocationList.map((location, k) => 
							(showServiceLocationsCarryIn) && sortedServiceLocationList && sortedServiceLocationList.length && sortedServiceLocationList.map((location, k) => 
								<div className="col-sm-6" key={k} onClick={() => serviceLocationClick(location)}>
									<div className={`serviceLocationCard ${(serviceLocation && (location.ClientServiceLocationID ? (serviceLocation.ClientServiceLocationID == location.ClientServiceLocationID) : (serviceLocation.PartnerServiceLocationID == location.PartnerServiceLocationID))) ? 'item-active-border' : ''}`}>
										<div>
											<div className='locationHeader'>{localString('Name')}</div>	
											<div className='scoreDistance'>
												{(location.score || location.score === 0) && <span><Score {...props} scoreVal={location.score} color={location?.scoreMeta?.colourCode}/></span>}
												{location.distance && 
													<span className='pill' style={{ marginLeft: '15px' }}>
														<img className='redirectIcon' src={publicUrl + "/imgs/redirectIcon.svg"}></img>
														<span>{location.distance.toFixed(1)} {localString("km")}</span>
													</span>}
											</div>
											<div className='locationContent'>{location.NameOfFirm}</div>
										</div>
										<div style={{ paddingTop: '15px' }}>
											<div className='locationHeader'>{localString('Service Location')}</div>	
											<div className='locationContent'>
												<div>{location.ServiceLocationName}</div>
												<div>{location.address}</div>
											</div>
										</div>
										<div style={{ paddingTop: '15px' }}>
											<div className='locationHeader'>{localString('Contact')}</div>	
											<div className='locationContent'>{location.ContactNo1}</div>
										</div>
										{location.scoreMeta && location.scoreMeta.Label && <div style={{ paddingTop: '15px' }}>
											<div className='locationHeader'>{localString('Label')}</div>	
											<div className='locationContent' style={{margin: "5px 0"}}>
											<span style={{
													...(location?.scoreMeta?.FontColourCode && {color: location.scoreMeta.FontColourCode}),
													...(location?.scoreMeta?.ColourCode && {backgroundColor: location.scoreMeta.ColourCode}),
													padding: "3px 5px",
													borderRadius: "5px"
												}}>{location.scoreMeta?.Label} </span>
											</div>
										</div>}
										<div className='pillBox' style={{ paddingTop: '15px' }}>
											<span className='pill'>{location?.BrandAuthorised ? 'Brand Authorised' : 'Brand Non-Authorised'}</span>
											<span className='pill' style={{ marginLeft: '10px' }}>{location?.IsProductExclusive ? 'Exclusive' : 'Non-Exclusive'}</span>
											{location && location.Lat && location.Lng && address && <span onClick={(e) => openGmapLink(e, location)} className='locationLink float-right' style={{marginTop: '-1px'}}>{localString('Show Location')}</span>}
										</div>
									</div>
								</div>
								)	
						}
						{
							showServiceLocationsDropOff && dropoffLocationList && dropoffLocationList.length && dropoffLocationList.map((location, k) => 
								<div className="col-sm-4" key={k} onClick={() => serviceLocationClick(location)}>
									<div className={`item address-item-location  ${(dropoffLocation && dropoffLocation.LocationID == location.LocationID) ? 'item-active-border' : ''}`}>
										<div>
											<p><label>{localString("Name")}:</label> {location.ServiceLocationName}</p>
											<p><label>{localString("Address")}:</label> {location.address}, {location.City}, {location.StateProvinceCode}, {location.PinCode ? location.PinCode : location.ZipCode}</p>
											<p><label>{localString("Contact")}:</label> {location.ContactNo1}</p>
											{location.WorkingHours && <p><label>{localString("Working Days")}:</label> {getWorkingDays(location.WorkingHours)}</p>}
										</div>
									</div>
								</div>
								)
						}
						{
							((showServiceLocationsCarryIn && showNoService) || (showServiceLocationsDropOff && dropoffLocationList && dropoffLocationList.length == 0)) &&
								<div className="fluid-form-content fluid-form-active text-center empty-message" id="noService">
									{showServiceLocationsCarryIn ? localString("There is no service for this address at this moment") : showServiceLocationsDropOff ? localString("There is no service for this address at this moment") : ''}
								</div>
						}
					</div>
				</div>
				
			</div>
		</>
	)
}