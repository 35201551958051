import React from 'react';
import {Input,Button} from 'sfy-react';
import './ConsumerDetails.scss'


const ConsumerDetails = (props) => {
	const {consumerDataArray,localString,editClickFun,editClick,editSaveData,cancelClickFun,saveClickFun,accessFn} = props;
	return (
		<div className="content consumer-details">
			{
				consumerDataArray && consumerDataArray.map((data,index)=>{
					return(
						<div className="consumer-padding">
							<div className="col-sm-6 col-xs-12">
								<div className="form-group">
									<div className="row">
										{
											<>
												<div className="col-sm-4 col-xs-6">
													<label className="consumer_details_name">{data.labelText} :</label>
												</div>
												<div className="col-sm-8 col-xs-6 custom-consumer-details-edit-label">
													{
														!editClick && <p>{data.value}</p>
													}
													{
														editClick &&
														((data.type && data.type === "input") ?
															<Input
																value={editSaveData[data.dataKey]}
																key={index}
																handleOnChange={(e) => {
																	data.oninputFunc(e.target.value)
																}}
																maxLength={data.maxLength}
															/> : (!data.type) && <p>{data.value}</p>)
													}
												</div>
											</>
										}
									</div>
								</div>
							</div>
						</div>
					)
				})
			}
			{
				!editClick && accessFn('EDIT_CONSUMER_DETAILS') ?
				<div className="col-sm-12 col-xs-12">
					<Button  text={localString('Edit')} handleOnClick={editClickFun}  className='btn button-servify pull-right'></Button>
				</div> : editClick && accessFn('EDIT_CONSUMER_DETAILS') ?
				<div className="col-sm-12 col-xs-12">
					<Button  text={localString('Cancel')} handleOnClick={cancelClickFun} className='btn button-servify pull-right'></Button>
					<Button  text={localString('Save')} handleOnClick={saveClickFun} className='btn button-servify pull-right'></Button>
				</div> :
				<></>
			}
		</div>
	)
}

export default ConsumerDetails
