import React from "react";
import {Loader,Dropdown,Button} from 'sfy-react';
import DateRangePickerWrapper from "../../../../../components/DateRangePickerWrapper/DateRangePickerWrapper";
const CallLogTabs =(props)=>{
    const {callLogTabs,selectStatus,selectCallType,selectCallConnect,activeLink,maxDate,dateRange,handleDateRangeApply,filterCommunication,setFilterCommunication,setCallConnected,
            setCallIncoming,setCsrStatus,setDateRange,localString,handleOnClear,isDisabledClear} = props;
    
    
    return(
        <div className='navbar-tab row'>
            <div className={activeLink =='callLogs' ?'col-sm-3 tab-row' :'col-sm-6 tab-row ml15'}>
                <ul className={`nav ul-nav  ${activeLink =='all'|| activeLink =='callLogs'?'navbar-nav pl15':'navbar-nav'}`}>
                    {
                        callLogTabs && callLogTabs.map(tab=>{
                            return(
                                tab && tab.showTab ?
                                <li className={tab.customClass} id={tab.tabId}>
                                <a className={activeLink == tab.tabId ? 'active-link tab-link':"tab-link"}
                                    id={tab.tabId}
                                    onClick={(e)=>tab.onClickTab(e)}
                                    >{tab.callType}
                                </a>
                            </li>:""
                            )
                        })
                    }
                </ul>
                
            </div>
            <div className={activeLink =='callLogs' ? 'col-sm-8 ': 'col-sm-4 '}>
                <div className={activeLink =='callLogs'  ? 'col-sm-3 status-input' : 'col-sm-6 mr5 status-input'}>
                    <Dropdown
                        value={filterCommunication['CsrStatus']}
                        options={selectStatus}
                        handleOnChange={(data) => {
                            let val = data?.value
                            let status = val ? val :'all'
                            setCsrStatus(status)
                            setFilterCommunication({
                                ...filterCommunication,['CsrStatus']:val
                            })
                        }}
                        filterBy='value'
                        showDownArrow={true}
                        placeholder={'Request Status'}
                        // showClearButton={true}
                    />
                </div>
                {
                    
                    activeLink =='callLogs' &&
                        <div className='col-sm-2 call-type'>
                            <Dropdown
                                value={filterCommunication['CallIncomingVal']|| ''}
                                options={selectCallType}
                                handleOnChange={(data) => {
                                    let val = data?.value ;
                                    setCallIncoming(data.isValid)
                                    setFilterCommunication({
                                        ...filterCommunication,['CallIncomingVal']:val,['CallIncoming']:data.isValid 
                                    })
                                    // communicationFilterData(data)
                                }}
                                filterBy='value'
                                showDownArrow={true}
                                placeholder={'Call Type'}
                                // showClearButton={true}
                            />
                        </div>
                    }
                    { activeLink =='callLogs' && <div className='col-sm-3'>
                            <Dropdown
                                value={filterCommunication['CallConnectedVal']|| ''}
                                options={selectCallConnect} 
                                handleOnChange={(data) => {
                                    let val = data?.value ;
                                    setCallConnected(data.isValid)
                                    setFilterCommunication({
                                        ...filterCommunication,['CallConnectedVal']:val,['CallConnected']:data.isValid
                                    })
                                }}
                                filterBy='value'
                                showDownArrow={true}
                                placeholder={'Call Connected'}
                                // showClearButton={true}
                            />
                        </div>
                    }
                    <DateRangePickerWrapper
                        date={maxDate}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        handleDateRangeApply={handleDateRangeApply}
                        dateClass={activeLink == 'callLogs' ? "col-sm-3" : "col-sm-3"}
                        customFormat="dd-mm-yyyy"
                        customPlaceholder="DD-MM-YYYY to DD-MM-YYYY"
                        dateWidthClass={activeLink == 'callLogs' ? 'date-input-call':'date-input'}
                    />
                    <div >
                </div>
            </div>
            <div className="comms-clear-btn">
                <Button className='btn-clear' isDisabled={isDisabledClear} handleOnClick={()=> handleOnClear()}>{localString('Clear')}</Button>
            </div>
        </div>
    )
}
export default CallLogTabs;