import React from "react";
import { Loader, Pagination } from "sfy-react";
import ReactDOM from "react-dom";
import { displayAmount, getDateFormat } from "../../../Utility/Utils";
import moment from "moment";
import PlanSalesTable from "../PlanSalesTable/PlanSalesTable";
import "./TransactionSummary.scss";

const Subscription = (props) => {
	const {
		localString,
		pagination,
		baseUrl,
		statusColor,
		statusText,
		statusTextColor,
		defaultCountryLocale,
		planDetails,
		viewList,
		showLoader,
		setPagination,
		countriesData
	} = props;

	const columns = [
		{
			title: localString("Contract ID"),
			showHeading: true,
			customCell: (row) => row.SoldPlanCode || "--",
		},
		{
			title: localString("Plan Name"),
			showHeading: true,
			customCell: (row) => row.PlanName || "--",
		},

		{
			title: localString("Plan Purchase Date"),
			showHeading: true,
			customCell: (row) =>
				(row?.DateOfPurchase &&
					moment(row.DateOfPurchase, "YYYY-MM-DDTHH:mm:ssZ").format(
						getDateFormat(defaultCountryLocale)
					)) ||
				"--",
		},

		{
			title: localString("Serial Number") + " / " + localString("IMEI"),
			showHeading: true,
			customCell: (row) => row.ProductUniqueID || "--",
		},
		{
			title: localString("Customer Details"),
			showHeading: true,
			customCell: (row) =>
				(
					<span>
						{row.FirstName}
						<br />
						{row.EmailID}
						<br />
						{row.MobileNo}
					</span>
				) || "--",
		},

		// {
		// 	title: localString("Payment Plan"),
		// 	showHeading: true,
		// 	customCell: (row) => row.PaymentFrequency || "--",
		// },
		{
			title: localString("Transaction Amount"),
			showHeading: true,
			customCell: (row) =>
				!(
					row.TransactionAmount === 0 ||
					!row.TransactionAmount ||
					isNaN(row.TransactionAmount)
				) && (
					<span>
						{/* {(row?.CurrencySymbol && row?.TransactionAmount && (
							<span
								dangerouslySetInnerHTML={{
									__html: row.CurrencySymbol,
								}}
							></span>
						)) ||
							"-"}

						{row.TransactionAmount || "-"} */}
						{displayAmount(row.TransactionAmount, (countriesData?.countryConfig?.Locale?.DEFAULT || navigator?.language), row.CurrencyCode)}
					</span>
				),
		},
		{
			title: localString("Transaction Start Date"),
			showHeading: true,
			customCell: (row) =>
				(row?.TransactionStartDate &&
					moment(row.TransactionStartDate, "YYYY-MM-DDTHH:mm:ssZ").format(
						getDateFormat(defaultCountryLocale)
					)) ||
				"--",
		},
		{
			title: localString("Transaction End Date"),
			showHeading: true,
			customCell: (row) =>
				(row?.TransactionEndDate &&
					moment(row.TransactionEndDate, "YYYY-MM-DDTHH:mm:ssZ").format(
						getDateFormat(defaultCountryLocale)
					)) ||
				"--",
		},
	

		{
			title: localString("Contract Status"),
			showHeading: true,
			customCell: (row) => (
				<span
					className="statusPill"
					style={{
						background: statusColor[row.Status],
						color: statusTextColor[row.Status],
						width: "fit-content",
						display: "inline-flex",
					}}
				>
					{statusText[row.Status] || "--"}

					<span
						className="glyphicon glyphicon-info-sign icon-hover"
						style={{
							paddingLeft: "10px",
							color: statusTextColor[row.Status],
							position: "relative",
							marginTop: "3px",
						}}
					></span>
					<p
						className="hide-text"
						style={{ marginTop: "15px", wordWrap: "break-word" }}
					>
						{row?.StatusTextObject?.displayText}
					</p>
				</span>
			),
		},
		{
			title: localString(""),
			showHeading: true,
			customCell: (row) => (
				<a
					href={
						baseUrl +
						"/plansales/viewcontract?spid=" +
						row.SoldPlanID
					}
					target="_blank"
				>
					<span
						className="glyphicon glyphicon-menu-right cursor-pointer"
						type="button"
					></span>
				</a>
			),
		},
	];

	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}

			<div className="inner-padding doc-title">
				<div className="row content-header-background main-div-transaction">
					<div className="transaction-box">
						<div className="col-sm-6">
							<h3 className="section-text">
								{localString("Subscription Payment")}
							</h3>
						</div>

						<div className="right-content">
							{pagination.totalItems && (
								<Pagination
									className={{
										container: "page-info-transaction",
									}}
									paginationInfo={`${
										(pagination.currentPage - 1) *
											pagination.itemsPerPage +
										1
									} - ${Math.min(
										pagination.currentPage *
											pagination.itemsPerPage,
										pagination.totalItems
									)} of ${pagination.totalItems}`}
								/>
							)}
						</div>
					</div>
					<div className="request-panel request-charges-div custom-third-party-charges pay-box">
						<>
							{viewList && (
								<PlanSalesTable
									{...props}
									columns={columns}
									emptyStateText={localString(
										"Sorry, no subscription payment transactions found"
									)}
									data={planDetails}
									localString={localString}
									tableClasses={{
										tableContainer: "summary-table",
									}}
								/>
							)}

							{pagination.maxPages > 1 && (
								<Pagination
									itemsPerPage={pagination.itemsPerPage}
									pages={pagination.maxPages}
									totalItems={pagination.totalItems}
									activePage={pagination.currentPage}
									paginate={(pageNumber) =>
										setPagination({
											...pagination,
											currentPage: pageNumber,
										})
									}
									className={{ container: "m25" }}
								/>
							)}
						</>
					</div>
				</div>
			</div>
		</>
	);
};

export default Subscription;
