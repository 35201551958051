import React from 'react';
import {Button, Input, Table, Pagination} from 'sfy-react';
import FilterInputs from '../../../../components/FilterInputs/FilterInput';

const ConsumerPlansDetails = (props) => {
	const {filterInputs, filterData, clearSearch, headings, planLabelsArray, baseUrl, localString, pagination, setCurrentPage, 
		getSearchList, searchBtnValidation, showClearButton} = props;

	return (
		<div className="consumer-plan-tab">
			<div className="col-xs-12 main-plan-input">
				<div className="col-sm-4">
					{
						filterInputs && filterInputs.map((filter,i)=>{
							return(
								<Input
									value={filterData[filter.dataKey]}
									key={i}
									handleOnChange={(e) => {
										filter.oninputFunc(e.target.value);
									}}
									label={filter.labelText}
									labelAlign='left'
									maxLength={filter.maxLength}
									placeholder={filter.placeholder}
								/>
							)
						})
					}
				</div>
				<div className="plan-button">
					<Button handleOnClick={getSearchList} isDisabled={searchBtnValidation} type='button' classes='button-ghost' text={localString('Search')}/>
					{   
						showClearButton&& 
						<Button handleOnClick={clearSearch} type='button' classes='button-ghost' text={localString('Clear')}/>
					}
				</div>
			</div>
			<div>
				{
					planLabelsArray && planLabelsArray.length > 0 &&
					<div className="">
						<Table isResponsive>
							<Table.Header>
								<Table.Row >
									{ headings && headings.map((label,index)=>{
											return(
												<Table.Heading key={index} className={label.customClass}>{label.title}</Table.Heading>
											)
										})
									}
									<Table.Heading></Table.Heading>
								</Table.Row>
							</Table.Header>
							<Table.Body>
							{
								planLabelsArray.map((labels)=>{
									return(
										<Table.Row>
										{
											labels && labels.map(inner=>{
												return(
													inner.show ? <Table.Data className={inner.customClass?inner.customClass:""} >{inner.value}</Table.Data> 
													:   <Table.Data>
															<a target="_blank" href={baseUrl+'/plans/consumer/view?spid='+ inner.SoldPlanID}>
																<Button  text={localString('View Details')} className='btn button-servify pull-right'></Button>
															</a>
														</Table.Data>
												)
											})
										}
										</Table.Row>
									)
								})
							}
							</Table.Body>
						</Table>
					</div>
				}
			</div>
			{
				pagination.lastPage > 1 && <Pagination itemsPerPage={pagination.itemsPerPage} pages={pagination.lastPage} totalItems={pagination.totalItems} activePage={pagination.currentPage} paginate={(pageNumber) => {setCurrentPage(pageNumber)}}/>
			}
			<div className="">
				{
					(!planLabelsArray || planLabelsArray.length === 0) && 
					<div className="text-center no-plans no-request">
						<p> {localString("No Plan Found")} </p>
					</div>
				}
			</div>
		</div>
	)
}

export default ConsumerPlansDetails
