import React,{useState,useEffect} from 'react';
import {returnLocalDateTime} from '../../../Utility/Utils';
import './CallHistory.scss'

const CallHistory = (props) => {
	const {communicationLogs,localString,getCallStatus,accessFn} = props;
	const [callHistoryData, setCallHistoryData] = useState();

	const getCommuncationData = () =>{
		let communicationData = communicationLogs.map(v=>{
			return [{
				labelText:"Name",
				value:v.senderName,
			},{
				labelText:"UserName",
				value:v.username,
			},{
				labelText:"Email ID",
				value:(v.email? v.email : "-"),
			},{
				labelText:"Brand",
				value:v.BrandName,
			},{
				labelText:"SubCategory",
				value:v.ProductSubCategoryName,
			},{
				labelText:"Product Name",
				value:v.ProductName,
			},{
				labelText:"Calling Number",
				value:(v.customerCallingNumber && v.customerCallingNumber),
			},{
				labelText:"Support Ticket Number",
				value:v.supportTicketNumber,
			},{
				labelText:"Call type",
				value: v.communicationDetails[0].callIncoming ? "Incoming" : "Outgoing",
			},{
				labelText:"Call Connected",
				value:v.communicationDetails[0].callConnected ? "Yes" : "No",
			},{
				labelText:v.communicationDetails[0].callConnected ? 'Connection Disposition:' : 'Non-Connect Disposition:',
				value:(getCallStatus(v.communicationDetails[0].callStatusId)),
			},{
				labelText:"First Dailer Dispostion",
				value:v.communicationDetails[0].firstDailerDispostion,
			},{
				labelText:"Second Dialer Disposition",
				value:v.communicationDetails[0].secondDailerDispostion,
			},{
				labelText:"Date and Time of Call",
				value:(returnLocalDateTime(v.createdAt,"DD-MM-YY HH:mm:ss")),
			},{
				labelText:"Status at which call was made",
				value:v.communicationDetails[0].status,
			},{
				labelText:"Remarks",
				value:v.communicationDetails[0].remark,
			},
			{
				labelText:"Recording URL",
				value:v.communicationDetails[0].callRecordingUrl && accessFn("SHOW_CALL_RECORDING")?<a href={v.communicationDetails[0].callRecordingUrl} target="_blank">Link</a>:"NA",
			}]
		})
		return communicationData;
	}

	useEffect(() => {
		setCallHistoryData(getCommuncationData());
	}, [communicationLogs])

	return (
		<>
		{
			<div className="main">
			{ 
				callHistoryData && callHistoryData.length > 0 ?
				callHistoryData.map(logs=>{
					return(
					<div className="communication-item">
					{
						logs && logs.map(inner=>{
							return(
							inner.value && 
							<div className="row">
								<div className="col-sm-4">
									<div>
										<label>{localString(inner.labelText)}:</label>
									</div>
								</div>
								<div className="col-sm-8">
									<div>{inner.value}</div>
								</div>
							</div>
							)
						})
					}
					</div>
					)
				}) :
				<div className="communication-item">{localString("No call history")}</div>
			}
			</div>
		}
	</>
	)
}
export default CallHistory

