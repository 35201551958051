import React from 'react';
import {Modal} from 'sfy-react';
import RunDiagnosisInputs from './RunDiagnosisInputs';
import './RunDiagnosisModal.scss';
const RunDiagnosisModal=(props)=>{
    const {localString,showModal,closeModal,refreshModal,publicUrl} = props;
		return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
                <Modal.Header>
                    <div className="p-refresh pull-right"><span onClick={refreshModal}><img className='refresh-image' src={publicUrl+`/imgs/refresh.png`} alt="Refresh"/></span></div>
                    <h2>{localString("Run Diagnosis")}</h2>
                </Modal.Header>
                <Modal.Body>
                    <RunDiagnosisInputs {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default RunDiagnosisModal;



