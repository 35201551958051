import React from 'react';
import {Input, Button, Radio, Dropdown} from 'sfy-react';
import Datetime from 'react-datetime';
import { SERVICETYPE } from '../../../../../Utility/Utils';
const CallAttemptUI=(props)=>{
    const {localString,  callLogFlags,  radioInput, inputData ,callLogsField, checkDamageDetailsValue, enterCallLog, accessFn, consumerServiceRequest, callTypes,
        selectedCallType, setSelectedCallType, pickupCarryInGSXChecks }=props;
    
    return(
        <>
            <div className='request-filter'>
                <form onSubmit={(e)=>enterCallLog(e)} className='call-attempt-form'>
                    {
                        pickupCarryInGSXChecks && consumerServiceRequest.consumerProduct.isAppleBrandID && 
                        accessFn('GSX_FLOW') && 
                        <div  class="form-group" >
                            <p>{localString('Call Type:')}</p>
                            <Dropdown 
                                id='dropdown'
                                value = {selectedCallType}
                                options = {callTypes}
                                handleOnChange = {(val) =>{
                                    setSelectedCallType(val.value);
                                }}
                                filterBy = 'value'
                                showDownArrow = {false}
                            />
                        </div>
                    }
                    {
                        ( !accessFn('GSX_FLOW') || selectedCallType == 'Outbound' ) &&
                        <div className='form-group'>
                            <p>{localString('Connect')}:</p>
                                <div className='radio-group'>
                                    {
                                        radioInput && radioInput.map(radio=>(
                                            radio.type && <Radio
                                                value={radio.value}
                                                selectedValue={radio.isSelect}
                                                tname={radio.name}
                                                classes='customClasses'
                                                handleOnUpdate={(e) => radio.onUpdateRadio(e)}
                                            >
                                                {localString(radio.radioText)}
                                            </Radio>
                                        ))
                                    }
                                </div>
                        </div>
                    }
                    {
                        inputData && inputData.map(item=>(
                            item.showInputField &&  <div >
                                {item.type=='input' && <Input
                                    value={callLogsField[item.value]}
                                    handleOnChange={(e) => {item.onInputFun(e.target.value)}}
                                    label={item.labelText}
                                    labelAlign='top'
                                    placeholder={item.placeholder}
                                    otherProps={{onInput:(e)=>checkDamageDetailsValue(e)}}
                                    className={{inputContainer: item.customCls}}
                                />}
                                {item.characterCount  && item.characterCount != 0 && <b className='desc-count'><span>{item.characterCount}</span></b>}
                                {<b className='desc-count'><span>{item.characterCountText}</span> </b>}
                                
                                {
                                    item.type == 'dropdown' &&
                                    <div className='call-dropdown'>
                                        <label className='marginT10'>{item.labelText}</label>
                                        <Dropdown
                                            id='dropdown'
                                            value = {callLogsField[item.value]}
                                            options = {item.optionList}
                                            handleOnChange = {(val) => item.onInputFun(val)}
                                            isDisabled={item.disable}
                                            filterBy = 'value'
                                            showDownArrow = {false}
                                        />
                                    </div>
                                }
                                {
                                    item.type=='dateTime' && 
                                    <>
                                        <label className='marginT10'>{item.labelText}</label>
                                        <Datetime
                                            value = {callLogsField[item.value]}
                                            dateFormat={item.dateFormat}
                                            timeFormat={item.timeFormat}
                                            closeOnSelect={true}
                                            onChange={(val) => 
                                                item.onInputFun(val)
                                            }
                                            defaultValue={item.value}
                                            isValidDate={(current) => {
                                                if(item && item.maxDay && item.minDay) {
                                                    return current.isAfter(item.minDay) && current.isBefore(item.maxDay)
                                                }else if(item && item.maxDay) {
                                                    return current.isBefore(item.maxDay)
                                                }else if(item && item.minDay) {
                                                    return current.isAfter(item.minDay)
                                                }else {
                                                    return true;
                                                }
                                            }}
                                        />
                                    </>
                                }
                            </div>
                        ))
                    }
                    {callLogFlags.showFields && 
                        <div className='marginT15'>
                            <Button type='submit' className='btn button-servify' isDisabled={callLogFlags.saveCallBtn}>{localString('Save')}</Button>
                        </div>
                    }
                </form>
            </div>
        </>
    )
}
export default CallAttemptUI;


