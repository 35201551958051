import React from 'react';
import {Modal} from 'sfy-react';
import ReassignInput from './ReassignInput';

const ReassignButtonModal=(props)=>{
    const {localString,showModal,closeModal} = props;
		return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
                <Modal.Header>
                    <h2> {localString("Reassign Service")} </h2>
                </Modal.Header>
                <Modal.Body>
                    <ReassignInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default ReassignButtonModal;