import React,{useState} from 'react';
import ReactDOM from 'react-dom';
import {Loader,Input,Button,Modal} from 'sfy-react';
import Swal from "sweetalert2";
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import {displayError,SERVICETYPE} from '../../../../../Utility/Utils';
import './OverrideApproval.scss'
import OverrideApprovalNonWorkshop from './OverrideApprovalNonWorkshop';
import OverrideApprovalWorkshop from './OverrideApprovalWorkshop';

const OverrideApproval = (props) => {
	const {getRequestById, accessFn, apiCall, localString, canUpdateServiceRequest, consumerServiceRequest, selectedBillingDetail,
		TaxDetailName, activeTab, setActiveTab, getBillingDetails, allTabs} = props;
	const [loader, setLoader] = useState(false);
	const [showModal, setShowModal] = useState(false)
	const [overrideReason, setOverrideReason] = useState("")

	const openBillingDetailModal = ()=>{
		if(TaxDetailName){
			setShowModal(true)
		}else{
			save();
		}
	}

	const changeBillingDetails = ()=>{
		setShowModal(false)
		// if($('#gst_info').length > 0){
		//     $('#gst_info')[0].scrollIntoView(true);
		// }
		if(accessFn('WORKSHOP_FLOW')){
			setActiveTab({...allTabs,['ShowGstInfo']: true})
			getBillingDetails();	
		}
	}

	const closeModal =()=>{
		setShowModal(false)
	}

	const save = () => {
		const reason = overrideReason && overrideReason.trim()
		if (reason != '') {
			let data = {
				ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
				IsApproved: 1
			};
			setLoader(true);
			apiCall({
				url:'updateEstimation',
				data: data,
				callBack:(result)=>{
					setLoader(false);
					if(result.success){
						getRequestById();
						setActiveTab({...allTabs,['showConsumrDetails']: true})
						setOverrideReason("")
						setShowModal(false)
						if(accessFn("WORKSHOP_FLOW")){
							setActiveTab({...allTabs,
								['showConsumrDetails']: true,
								['showOverrideApproval']: false
							})
						}
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		} else {
			Swal.fire({
				title: '',
				text: localString('Please enter reason for approval'),
				icon: 'error'
			});
			closeModal();
		}
	}   

	return (
		<>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			{
				//([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) &&
				([18].indexOf(consumerServiceRequest.ServiceTypeID)== -1) && !accessFn('WORKSHOP_FLOW') &&
				(consumerServiceRequest && consumerServiceRequest.Status == "Estimation approval") && 
				([9,SERVICETYPE.CLAIM, SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_CARRY_IN, 13, 18, SERVICETYPE.CLAIM_SELF_REPAIR, SERVICETYPE.CLAIM_MAIL_IN, SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) < 0) && 
				(!consumerServiceRequest.OriginServiceLocationID || 
				(consumerServiceRequest.OriginServiceLocationID == consumerServiceRequest.PartnerServiceLocationID) || 
				(consumerServiceRequest.deviceTransferData && consumerServiceRequest.deviceTransferData.isTransferredRequest && consumerServiceRequest.deviceTransferData.showOverrideApprovalToOriginUser)) &&
				<OverrideApprovalNonWorkshop 
					{...props}
					consumerServiceRequest={consumerServiceRequest}
					setOverrideReason={setOverrideReason}
					overrideReason={overrideReason}
					openBillingDetailModal={openBillingDetailModal}
					showModal={showModal}
					closeModal={closeModal}
					save={save}
					changeBillingDetails={changeBillingDetails}
					selectedBillingDetail={selectedBillingDetail}
				/>
			}
			{
				//([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1)
				([18,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)== -1 && !(['applicable','replacement','replaced','certificate'].indexOf(consumerServiceRequest.DOAStatus) > -1)) && accessFn('WORKSHOP_FLOW') && ['VizioDashboard'].indexOf(consumerServiceRequest.Source) === -1 &&
				accessFn('OVERRIDE_ESTIMATION_APPROVAL') && (consumerServiceRequest && consumerServiceRequest.Status == "Estimation approval")&& 
				([9, SERVICETYPE.CLAIM, SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_CARRY_IN, 13, 18, SERVICETYPE.CLAIM_SELF_REPAIR, SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) < 0) && 
				(!consumerServiceRequest.OriginServiceLocationID || (consumerServiceRequest.OriginServiceLocationID == consumerServiceRequest.PartnerServiceLocationID) || 
				(consumerServiceRequest.deviceTransferData && consumerServiceRequest.deviceTransferData.isTransferredRequest && consumerServiceRequest.deviceTransferData.showOverrideApprovalToOriginUser)) &&
				<OverrideApprovalWorkshop 
					{...props}
					consumerServiceRequest={consumerServiceRequest}
					setOverrideReason={setOverrideReason}
					overrideReason={overrideReason}
					openBillingDetailModal={openBillingDetailModal}
					showModal={showModal}
					closeModal={closeModal}
					save={save}
					changeBillingDetails={changeBillingDetails}
					selectedBillingDetail={selectedBillingDetail}
					canUpdateServiceRequest={canUpdateServiceRequest}
				/>
			}
		</>
	)
}

export default OverrideApproval
