import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Loader } from 'sfy-react';
import Swal from "sweetalert2"; 

import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";
import SchedulerReportFilter from "./SchedulerReportFilter";
import ReportGrid from "./ReportGrid";
import { displayError } from '../../Utility/Utils'; 


export default function SchedulerReport(props) {
	const { accessFn, localString, apiCall, qFilter } = props;
	const [data, setData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [pagination , setPagination] = useState({
		pageNo : 1,
		itemsPerPage : 30,
		count: 0,
		numberOfPages: 0
	});
 
	const fetchReportList = (reportTypeId) => {
		setLoader(true);
		let reqObj = {
			"pagination": {
				"currentPage": pagination.pageNo
			}
		}
		if(reportTypeId) {
			reqObj.ReportTypeID = [reportTypeId];
		}
		apiCall({
			url: 'mailReports',
			data: reqObj,
			callBack: (result) => { 
				setLoader(false);
				if(result.success && result.data) {
					setData(result.data);
					setPagination({...pagination, 
						count: result.pagination && result.pagination.count,
						numberOfPages: result.pagination && result.pagination.pages && result.pagination.pages.length
					})
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}				
			},
			errorCB: (err) => setLoader(false)	
		});
	}

	useEffect(() => {
		fetchReportList()
	}, [pagination.pageNo]);

	return (
		<>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			{/* ------------Search-------------- */}
			<PageContentWrapper title={localString("Search Report")} bodyClassName={'autoMinHeight'}>
				<SchedulerReportFilter { ...props } fetchReportList={fetchReportList}/>
			</PageContentWrapper>
			{/* ------------Listing--------------- */}
			<PageContentWrapper title={localString("Schedule Generated Reports")}>
				<ReportGrid data={data} { ...props } pagination={pagination} setPagination={setPagination}/> 
			</PageContentWrapper>
		</>
	);
}