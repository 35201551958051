const lang = {
    "Pincode": {
        "text": "Zipcode",
        "isAlreadyTranslated": true
    },
    "No pincode found": {
        "text": "No zipcode found",
        "isAlreadyTranslated": true
    },
    "Customer Pincode is not serviceable by Our Logistics Partner. Please send the device to the Customer using other Courier Services near you": {
        "text": "Customer Zipcode is not serviceable by Our Logistics Partner. Please send the device to the Customer using other Courier Services near you",
        "isAlreadyTranslated": true
    },
    "Pincode not found": {
        "text": "Zipcode not found",
        "isAlreadyTranslated": true
    },
    "please provide Pincode and PlanID": {
        "text": "please provide Zipcode and PlanID",
        "isAlreadyTranslated": true
    },
    "FullCusCustomer Pincode is not serviceable by Our Logistics Partner. Please send the device to the Customer using other Courier Services near youtom": {
        "text": "FullCusCustomer Zipcode is not serviceable by Our Logistics Partner. Please send the device to the Customer using other Courier Services near youtom",
        "isAlreadyTranslated": true
    },
    "Enter correct Pincode": {
        "text": "Enter correct Zipcode",
        "isAlreadyTranslated": true
    }
}
export default lang;