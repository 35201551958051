import React, { useState } from "react";
import { Loader } from "sfy-react";
import ReactDOM from "react-dom";
import Tablets from "./Tablets";
import "./Documents.scss";
import PageContentWrapper from "../../../../../components/PageContentWrapper/PageContentWrapper";

const Documents = (props) => {
	const { localString, paymentData, documents } = props;
	const [showLoader, setShowLoader] = useState(false);

	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}

			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper
						title={localString("Documents")}
						headerClassName="custom-header"
						bodyClassName="custom-body"
					>
						<div
							className="row"
							style={{ padding: "15px", margin: "auto" }}
						>
							<Tablets
								{...props}
								paymentData={paymentData}
								documents={documents}
							/>
						</div>
					</PageContentWrapper>
				</div>
			</div>
		</>
	);
};

export default Documents;
