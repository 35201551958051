import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import { Button, Table, Loader, Pagination, Radio } from "sfy-react";
import ShadowWrapper from "./ShadowWrapper";
import AddRuleModal from "./AddRuleModal";
import { displayError } from "../../Utility/Utils";

import "./RuleManager.scss";

const Headings = [
	"Rule Name",
	"Rule Description",
	"Rule Type",
	"Transaction Subcategory",
	"Start Date",
	"End Date",
	"Active",
	"Action",
];

const convertorObject = {
	"Rule Name": "name",
	"Rule Description": "description",
	"Start Date": "rule_start_date",
	"End Date": "rule_end_date",
	Active: "active",
};

const ASC = 1;
const DESC = -1;

const RuleManager = (props) => {
	const { apiCall, localString, accessFn } = props;

	if(!accessFn('RULE_MANAGER')) {
		window.location.href = '/401';
	}

	const [showLoader, setShowLoader] = useState(false);
	const [showAddRuleModal, setShowAddRuleModal] = useState(false);
	const [filter, setFilter] = useState({ user_id: 0 });
	const [rules, setRules] = useState([]);
	const [sortConfig, setSortConfig] = useState({
		field: Headings[4],
		order: DESC,
	});

	const sortedData = useMemo(() => {
		let order = sortConfig.order === ASC ? DESC : ASC;
		let currentSortingBasedOn = convertorObject[sortConfig.field];
		let newData = [...rules];

		if (newData) {
			if (["Start Date", "End Date"].includes(sortConfig.field)) {
				newData = newData.sort((obj1, obj2) => {
					return new Date(obj1[currentSortingBasedOn]) >
						new Date(obj2[currentSortingBasedOn])
						? order
						: new Date(obj1[currentSortingBasedOn]) <
						  new Date(obj2[currentSortingBasedOn])
						? -order
						: 0;
				});
			} else if (
				["Rule Name", "Rule Description"].includes(sortConfig.field)
			) {
				newData = newData.sort((obj1, obj2) => {
					let firstValue = obj1[currentSortingBasedOn]
						? obj1[currentSortingBasedOn]
						: "";
					let secondValue = obj2[currentSortingBasedOn]
						? obj2[currentSortingBasedOn]
						: "";

					return firstValue.localeCompare(secondValue, undefined, {
						numeric: true,
						sensitivity: "base",
					})
						? order
						: -order;
				});
			} else {
				newData = newData.sort((obj1, obj2) => {
					return obj1[currentSortingBasedOn] >
						obj2[currentSortingBasedOn]
						? order
						: obj1[currentSortingBasedOn] <
						  obj2[currentSortingBasedOn]
						? -order
						: 0;
				});
			}
		}

		return newData;
	}, [sortConfig.order, sortConfig.field, rules]);

	const getRules = () => {
		let data = {
			showAllRules: true,
		};

		setShowLoader(true);
		apiCall({
			url: "getRiskRules",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					if (result.data) {
						setRules(result.data.rules);
					}
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	useEffect(() => {
		getRules();
	}, []);

	const closeModal = () => {
		setShowAddRuleModal(false);
	};

	const handeRuleDeactivation = (ruleCode) => {
		let data = {
			RuleCode: ruleCode,
		};

		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, Deactivate it!",
		}).then((result) => {
			if (result.isConfirmed) {
				setShowLoader(true);
				apiCall({
					url: "deactivateRiskRule",
					data: data,
					callBack: (result) => {
						setShowLoader(false);
						if (result.success) {
							if (result.data.status) {
								Swal.fire({
									icon: "success",
									text: result.data.status.toLocaleUpperCase(),
									timer: 2000,
								});
								getRules();
							}
						} else {
							Swal.fire({
								icon: "error",
								text: localString(displayError(result)),
							});
						}
					},
				});
			}
		});
	};

	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}
			<ShadowWrapper classname="rule-manager-header">
				<div className="main-header">
					<div className="main-heading">Rule Manager</div>
					<div>
						<Button
							type="button"
							classes="button-primary button-small m-top-15 mx-4"
							handleOnClick={() => {
								setShowAddRuleModal(true);
							}}
						>
							Add Rule
						</Button>
					</div>
				</div>
			</ShadowWrapper>
			{sortedData && sortedData.length > 0 ? (
				<Table isResponsive className={{ table: "custom-table" }}>
					<Table.Header>
						<Table.Row>
							{Headings.map((heading, index) => (
								<Table.Heading
									classObject={{
										header: "",
										sortContainer: "justify-start",
									}}
									key={index}
									onClick={() => {
										if (!["Action"].includes(heading)) {
											let order =
												sortConfig.order === ASC
													? DESC
													: ASC;

											setSortConfig({
												field: heading,
												order,
											});
										}
									}}
									showSortIcons={
										["Action"].includes(heading)
											? false
											: true
									}
									activeSortIcon={
										heading === sortConfig.field &&
										(sortConfig.order === ASC
											? "up"
											: sortConfig.order === DESC &&
											  "down")
									}
								>
									{heading}
								</Table.Heading>
							))}
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{sortedData.map((rule, i) => (
							<Table.Row key={i}>
								<Table.Data>{rule.name}</Table.Data>
								<Table.Data>{rule.description}</Table.Data>
								<Table.Data>{rule.rule_type || "-"}</Table.Data>
								<Table.Data>{rule.transaction_subCategory || "-"}</Table.Data>
								<Table.Data>{rule.rule_start_date}</Table.Data>
								<Table.Data>{rule.rule_end_date}</Table.Data>
								<Table.Data>
									{
										<span
											style={{
												color: rule.active
													? "green"
													: "red",
												marginLeft: "2px",
											}}
											className={
												rule.active
													? "glyphicon glyphicon-ok"
													: "glyphicon glyphicon-remove"
											}
										></span>
									}
								</Table.Data>
								<Table.Data>
									<Button
										type="button"
										classes="button-ghost"
										isDisabled={!rule.active}
										handleOnClick={() =>
											handeRuleDeactivation(rule.code)
										}
									>
										Deactivate
									</Button>
								</Table.Data>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			) : (
				<div className="text-center consumer-nodata">
					<div className="text-center requests-empty-state">
						{localString("No Data Available")}
					</div>
				</div>
			)}

			{showAddRuleModal && (
				<AddRuleModal
					{...props}
					showModal={showAddRuleModal}
					closeModal={closeModal}
					setShowModal={setShowAddRuleModal}
					setShowLoader={setShowLoader}
					showLoader={showLoader}
					rules={rules}
					setRules={setRules}
					getRules={getRules}
				/>
			)}
		</>
	);
};

export default RuleManager;
