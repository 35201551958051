import React from 'react';

const RegisteredProductList = (props) => {
	const {localString,selectedID,showProductetails,regProductList} = props; 
	return (
		<>
			<ul className="nav nav-pills nav-stacked product-list text-left nav-scroll">
				{
					regProductList && regProductList.map((regProd)=>{
						return(
							<li role="presentation"  className={(regProd.ConsumerProductID===selectedID)?'active':"in-active"}  onClick={() => showProductetails(regProd)}>
								<a href class="nav-no-side-border">{regProd.ProductName}
									<div>
										{
											regProd.ProductDetails && regProd.ProductDetails.map((productDetails,index)=>{
												return (
													productDetails.value && <div>
														<label class="cust-weight-200">{localString(productDetails.label)}</label>
														<label class="cust-weight-200 cust-float-right">{productDetails.value}</label>
													</div>
												)
											})
										}
									</div>
								</a>
							</li>
						)
					})
				}
			</ul>
		</>
	)
}

export default RegisteredProductList;
