import { isArguments, trim } from 'lodash';
import React, { useState, useEffect } from 'react';
import {Modal,Dropdown,Input,Checkbox, Button} from 'sfy-react';

import './CompleteAnalyseModal.scss';

const CompleteAnalyseModal=(props)=>{
    const {localString, showModal, closeModal, selectActionMaster, actionCategoriesList,
        refundProcessNext, consumerServiceRequest, nextStatus, } = props;
    const [selectedAction, setSelectedAction] = useState(null)
	const [completeAnalyseData,setCompleteAnalyseData]= useState({
        category:'',
        serialNumber:'',
        reason:''
    })
    const [checkBoxObj,setCheckBoxObj] = useState({
        electricallyOk:false,
        accessoriesAvailbale:false,
        packingBox:false,
        dentsScratches:false
    })

    const isValidSerialNo = (!completeAnalyseData.serialNumber && !selectedAction?.actionMaster?.IsImeiMandatory)
        || (completeAnalyseData.serialNumber && completeAnalyseData.serialNumber.length > 7 && completeAnalyseData.serialNumber.length < 31)

    const isValidateReason = completeAnalyseData.reason && completeAnalyseData.reason.length > 50 && completeAnalyseData.reason.length < 1001;

    const sendCategory = (e)=>{
        e.preventDefault();
        let selectedToken = selectedAction;
        delete selectedToken.value;
        let data = {
            "ConsumerServiceRequestID":consumerServiceRequest.ConsumerServiceRequestID,
            "Status": nextStatus,
            "actionTaken": selectedToken ? selectedToken : undefined,
            "Remarks" : checkBoxObj,
            "SerialNumber": completeAnalyseData.serialNumber,
            "Reason":  completeAnalyseData.reason? completeAnalyseData.reason : undefined
        }
        refundProcessNext(data,true)
    }

    return (
		<>
            <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='Filter-Modal uploadClaimDocumentModal'>
                <Modal.Header>
                    <h4> {localString("Select Category for the appliance")} </h4>
                </Modal.Header>
                <Modal.Body>
                    <div className='row croma-complete-analysis-modal'>
                        <div className='col-sm-12'>
                            <div>
                                <div className="form-group">
                                    <label>{localString("Select Category")}</label>
                                    <Dropdown
                                        value={completeAnalyseData['category']}
                                        options={selectActionMaster}
                                        handleOnChange={(data) => { 
                                            setCompleteAnalyseData({...completeAnalyseData, 
                                                category: data.actionMaster.ActionDisplayName
                                            })
                                            setSelectedAction(data)    
                                        }}
                                        filterBy='value'
                                        showDownArrow={false}
                                        // showTooltip={true}
                                    />
                                </div>
                                <div className='serial-number-div'>
                                    <label className="text-capitalize">{localString("Serial Number")}</label>
                                    <Input 
                                        value={completeAnalyseData['serialNumber']}
                                        handleOnChange={(e) => {
                                            let val = e.target.value ? e.target.value.trim() : "";
                                            setCompleteAnalyseData({...completeAnalyseData, serialNumber: val})
                                        }}
                                        autoComplete='off'
                                    />
                                </div>
                                <div>
                                    <label className="text-capitalize">{localString("Reason")}</label>
                                    <Input 
                                        value={completeAnalyseData['reason']}
                                        handleOnChange={(e) => {
                                            setCompleteAnalyseData({...completeAnalyseData, reason: e.target.value})
                                        }}
                                        autoComplete='off'
                                        inputComponent='textarea'
                                        className={{
                                            inputContainer: 'height-auto'
                                        }}
                                        maxLength={1000}
                                    />
                                </div>
                                <div style={{paddingTop:"10px"}}>
                                    <div>
                                        <label className="check-label" >{localString("Please select which applies.")}</label>
                                    </div>
                                    <div className='analysis-checklist-div'>
                                        <div className='refund-label'>
                                            <Checkbox
                                                value={checkBoxObj['electricallyOk']}
                                                name='repair-checklist'
                                                handleOnChange={(e) => {
                                                    setCheckBoxObj({...checkBoxObj,electricallyOk:!checkBoxObj.electricallyOk});
                                                }}
                                            >{localString("Is Electrically OK")}</Checkbox>
                                        </div>
                                        <div className='refund-label'>
                                            <Checkbox
                                                value={checkBoxObj['accessoriesAvailbale']}
                                                name='repair-checklist'
                                                handleOnChange={(e) => {
                                                    setCheckBoxObj({...checkBoxObj,accessoriesAvailbale:!checkBoxObj.accessoriesAvailbale});
                                                }}
                                            > {localString("Are Accessories Available")}</Checkbox>
                                        </div>
                                        <div className='refund-label'>
                                            <Checkbox
                                                value={checkBoxObj['dentsScratches']}
                                                name='repair-checklist'
                                                handleOnChange={(e) => {
                                                    setCheckBoxObj({...checkBoxObj,dentsScratches:!checkBoxObj.dentsScratches});
                                                }}
                                            > {localString("Dents/Scratches")}</Checkbox>
                                        </div>
                                        <div className='refund-label'>
                                            <Checkbox
                                                value={checkBoxObj['packingBox']}
                                                name='repair-checklist'
                                                handleOnChange={(e) => {
                                                    setCheckBoxObj({...checkBoxObj,packingBox:!checkBoxObj.packingBox});
                                                }}
                                            >{localString("Packing Box")}</Checkbox>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Button 
                                    className='btn button-servify float-right' 
                                    isDisabled={!selectedAction || (!isValidSerialNo) || !isValidateReason}
                                    handleOnClick = {(e)=>sendCategory(e)}
                                >{localString("Save")}</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default CompleteAnalyseModal;