import React, {useState}from 'react';
import {Button, Input, Modal,Radio} from 'sfy-react';
import Swal from "sweetalert2";

import { displayError } from '../../../../../Utility/Utils';

import './SelectServiceApproachModal.scss'

const SelectServiceApproachModal = (props) => {
    const {localString,showModal,setLoader,consumerServiceRequest,apiCall,setServiceApproach,checkAndCreateGsxrepair,CloseServiceApproachModal,getRequestById} = props;
    const [selectedServiceApproach,setSelectedServiceApproach] = useState({})
    const [remarks, setRemarks] = useState('');
    const radioButtons = [
        {
            value: "COMPLETED_AS_NON_HARDWARE_REPAIR",
            buttonText: localString("Complete as Non-Hardware Repair"),
            isSelect: false
        },
        {
            value: "REPAIR_AT_SERVICE_CENTER",
            buttonText: localString("Repair at Service Centre"),
            isSelect: false
        },
    ]
    const saveServiceApproach = (value) => {
        setLoader(true); 
        let reqObj = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            ServiceApproachCode:selectedServiceApproach.ServiceApproachCode,
            ServiceApproachText:selectedServiceApproach.ServiceApproachText,
            Remark: remarks.trim()
		}
        apiCall({
            url: 'updateRepairServiceApproach',
            data: reqObj,
            callBack: (result) => {
                setLoader(false); 
                if(result && result.success) {
                    setServiceApproach(result.data);
                    getRequestById();
                    checkAndCreateGsxrepair();
                }else {
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(result)), 
                    });
                }
            },
            errorCB: (err) => {
                setLoader(false);
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(err)),  
                });
            }
        });
    }
    const handleRadioChange = (data) => {
        setSelectedServiceApproach({
            ServiceApproachCode:data.value,
            ServiceApproachText:data.buttonText
        });
    }
		return ( <>
            <Modal showModal={showModal} closeModal={CloseServiceApproachModal} showCloseBtn={true} className='Filter-Modal selectServiceApproach'>
                <Modal.Header>
                    <h2> {localString("Select Service Approach")} </h2>
                </Modal.Header>
                <Modal.Body>
                <div className='row'>
                    <div className='col-xs-12'>
                        <div>
                            {
                                radioButtons && radioButtons.map((radio,i)=>{
                                    return(
                                        <div>
                                            <label className="custom-radio">
                                                <input 
                                                    type="radio"
                                                    name="selectServiceApproach" 
                                                    onClick={(e) => handleRadioChange(radio)} 
                                                    id={radio.value}
                                                    className='radio-input'
                                                />
                                                <div className='radio-text'>{radio.buttonText}</div>
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='paddingT10'>
                            <label>{localString('Remarks')}</label>
                            <Input
                                value={remarks}
                                handleOnChange={(e) => {
                                    let val = (e.target.value).replace(/\s{2,}/gm, ' ') //.trim()
                                    setRemarks(val);
                                }}
                                inputComponent='textarea'
                                className={{
                                    containerClass: 'input-container',
                                    inputClass: 'input',
                                    inputContainer: 'autoHeight'
                                }}
                                otherProps={{
                                    rows: 2
                                }}
                            />
                        </div>
                    </div>
                </div>   
                </Modal.Body>
                <Modal.Footer>
                <div className='col-sm-12 text-center pb-15'>
                    <Button 
                        className="btn button-servify"
                        onClick={() => CloseServiceApproachModal()}
                    >
                        {localString("Cancel")}
                    </Button>
                    <Button 
                        className="btn button-servify"
                        onClick={() => saveServiceApproach()}
                        isDisabled={!remarks.trim() || !selectedServiceApproach.ServiceApproachCode}
                    >
                        {localString("Confirm")}
                    </Button>
                </div>
                {/* <div className='col-sm-12 text-center'>
                    <Button 
                        className="btn button-servify"
                        onClick={() => saveServiceApproach()}
                        isDisabled={!remarks.trim() || !selectedServiceApproach.ServiceApproachCode}
                    >
                        {localString("Confirm")}
                    </Button>
                </div> */}
                </Modal.Footer>
            </Modal>
        </> )
}
export default SelectServiceApproachModal;