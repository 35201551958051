import React, { useState, useEffect } from "react";
import { Modal, Dropdown, Button } from "sfy-react";
import Swal from "sweetalert2";
import { displayError } from "../../Utility/Utils";

import "./CaseManager.scss";

const ReassignModal = (props) => {
	const {
		localString,
		showModal,
		setShowLoader,
		closeModal,
		apiCall,
		getServiceOperatorUsers,
		reassignModalData,
		setReassignModalData,
	} = props;

	const [reAssigneeUserList, setReAssigneeUserList] = useState([]);

	const reassignCase = () => {
		let data = {
			caseID: reassignModalData.caseID,
			assigneeUserID: reassignModalData.assigneeUserID,
			remarks: "",
		};

		Swal.fire({
			title: localString("Remarks"),
			input: "text",
			inputAttributes: {
				autocapitalize: "off",
			},
			showCancelButton: true,
			confirmButtonText: localString("Submit"),
			inputValidator: (value) => {
				if (!value) {
					return localString("Remarks can not be empty!");
				}
			},
			showLoaderOnConfirm: true,
		}).then((result) => {
			if (result.isConfirmed) {
				if (result.value) data.remarks = result.value;

				setShowLoader(true);
				apiCall({
					url: "reassignCase",
					data: data,
					callBack: (result) => {
						setShowLoader(false);
						if (result.success) {
							if (result.data) {
								props.getCases && props.getCases();
								props.getCaseDetails && props.getCaseDetails();
								closeModal();
							}
						} else {
							Swal.fire({
								icon: "error",
								text: localString(displayError(result)),
							});
						}
					},
				});
			}
		});
	};

	useEffect(() => {
		getServiceOperatorUsers(
			true,
			{
				countryID:
					reassignModalData.csrCountryID ||
					reassignModalData.soldPlanCountryID,
			},
			(data) => {
				setReAssigneeUserList(data);
			}
		);
	}, []);

	return (
		<>
			<Modal
				showModal={showModal}
				closeModal={closeModal}
				showCloseBtn={true}
				className="reassign-modal"
			>
				<Modal.Header>
					<h2>{localString("Reassign Modal")}</h2>
				</Modal.Header>
				<Modal.Body>
					<div className="reassign-wrapper">
						<div>
							<div className="col-sm-6">
								<div>
									<Dropdown
										id="reassign-dropdown"
										classObject={{
											container:
												"customised-border customised-border-radius",
											optionsSearch:
												"customised-border-radius",
											valueContainer:
												"customised-border-radius",
										}}
										value={
											reassignModalData.assigneeName || ""
										}
										options={reAssigneeUserList}
										handleOnChange={(val) => {
											setReassignModalData(
												(prevData) => ({
													...prevData,
													...val,
													assigneeName: val.name,
												})
											);
										}}
										showTooltip={true}
										filterBy="value"
										searchText={"Assignee"}
										showDownArrow={true}
										handleOnSearch={(e) =>
											reAssigneeUserList &&
											reAssigneeUserList.filter((obj) =>
												obj.value
													.toLowerCase()
													.includes(
														e.target.value.toLowerCase()
													)
											)
										}
										noOptionsText={localString("Cannot find the searched option")}
										placeholder={localString("Assignee")}
									/>
								</div>
							</div>
						</div>
						<div className="mx-4">
							<Button
								type="button"
								classes="button-primary button-small m-top-15 mx-4"
								handleOnClick={() => {
									reassignCase();
								}}
							>
								{localString("Save")}
							</Button>

							<Button
								type="button"
								classes="button-primary button-small m-top-15 mx-4"
								handleOnClick={() => {
									closeModal();
								}}
							>
								{localString("Cancel")}
							</Button>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
};

export default ReassignModal;
