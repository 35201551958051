import React, { useEffect } from "react";
import { Button, Modal, Input, Dropdown, Table } from "sfy-react";
import "./ProvisionalEstimate.scss";

const AddPartsModal = (props) => {
	const {
		localString,
		consumerServiceRequest,
		accessFn,
		getPartsList,
		partsListCalled,
		addNewPart,
		addNewPartToPartsArray,
		cancelAddNewPartToPartsArray,
		removeAddedPart,
		showModal,
		closeModal,
		selectedPart,
		newPartsDropDownOptions,
		handleNewPartsDropDown,
		newPartWarranty,
		newPartWarrantyAndPart_warrantyOptions,
		handleNewPartWarranty,
		newPartTax,
		newPartTaxOptions,
		handleNewPartTaxOptions,
		handlePart_warranty,
		partwarranty,
		selectFlags,
		pricingOption,
		pricingOptionOptions,
		handlePricingOption,
		partRemark,
		setPartRemark,
		partsArray,
		availableQuantity,
		newPartName,
		setNewPartName,
		newPartRemark,
		setNewPartRemark,
		newPartHSN,
		setNewPartHSN,
		newPartPrice,
		setNewPartPrice,
		newPartsDropDown,
		isAbused,
		isReturnable,
		price,
		addToPartsValidation,
		addWorkshopParts,
		saveParts,
		partsList
	} = props;

	useEffect(() => {
		if (
			consumerServiceRequest &&
			consumerServiceRequest.consumerProduct &&
			!partsListCalled
		) {
			getPartsList();
		}
	}, []);

	return (
		<>
			<Modal
				showModal={showModal}
				closeModal={closeModal}
				showCloseBtn={true}
				// className="Filter-Modal gsxRaiseRequestModal full-screen-modal"
				className="part-modal"
			>
				<Modal.Header>
					<h2> {localString("Add Required Parts")} </h2>
				</Modal.Header>
				<Modal.Body>
					<div style={{padding:'15px'}}>
						{/* <form> */}
							{addNewPart && (
								<div className="row form-group-filter-request">
									<div className="col-xs-2  scrollable-dropdown-menu">
										<Input
											id="newPartName"
											ref="newPartName"
											name="newPartName"
											label={localString("Part Name")}
											inputType="text"
											value={newPartName | ""}
											handleOnChange={(e) => {
												setNewPartName(e.target.value);
											}}
										/>
									</div>
									<div className="col-sm-2 ">
										<Input
											ref="newPartRemark"
											name="newPartRemark"
											id="newPartRemark"
											label={localString("Remarks")}
											inputType="text"
											value={newPartRemark || ""}
											handleOnChange={(e) => {
												setNewPartRemark(e.target.value);
											}}
										/>
									</div>
									<div className="col-sm-2 ">
										<Input
											ref="newPartHSN"
											name="newPartHSN"
											id="newPartHSN"
											label={localString("HSN Code")}
											inputType="text"
											value={newPartHSN | ""}
											handleOnChange={(e) => {
												setNewPartHSN(e.target.value);
											}}
										/>
									</div>
									<div className="col-sm-2 ">
										<Input
											ref="newPartPrice"
											name="newPartPrice"
											id="newPartPrice"
											label={localString("Price")}
											inputType="text"
											value={newPartPrice | ""}
											handleOnChange={(e) => {
												setNewPartPrice(e.target.value);
											}}
										/>
									</div>
									{!consumerServiceRequest.IsWarrantyApplicable && (
										<div className="col-xs-2  scrollable-dropdown-menu">
											<label htmlFor="newPartWarranty">
												{localString("Warranty")}
											</label>

											<Dropdown
												name="newPartWarranty"
												id="newPartWarranty"
												value={newPartWarranty || ""}
												options={newPartWarrantyAndPart_warrantyOptions}
												handleOnChange={handleNewPartWarranty}
												showTooltip={true}
												filterBy="value"
												searchText={localString("Warranty")}
												showDownArrow={false}
												// handleOnSearch={(e) =>
												// 	newPartWarrantyAndPart_warrantyOptions.filter(
												// 		(obj) =>
												// 			obj.value
												// 				.toLowerCase()
												// 				.includes(
												// 					e.target.value.toLowerCase()
												// 				)
												// 	)
												// }
												// noOptionsText="Cannot find the searched option"
												placeholder={localString("Warranty")}
											/>
										</div>
									)}
									<div className="col-xs-2  scrollable-dropdown-menu">
										<label htmlFor="newPartTax">
											{localString("Tax Slab")}
										</label>

										<Dropdown
											name="newPartTax"
											id="newPartTax"
											value={newPartTax || ""}
											options={newPartTaxOptions}
											handleOnChange={handleNewPartTaxOptions}
											showTooltip={true}
											filterBy="value"
											searchText={localString("Tax Slab")}
											showDownArrow={false}
											// handleOnSearch={(e) =>
											// 	newPartTaxOptions.filter(
											// 		(obj) =>
											// 			obj.value
											// 				.toLowerCase()
											// 				.includes(
											// 					e.target.value.toLowerCase()
											// 				)
											// 	)
											// }
											// noOptionsText="Cannot find the searched option"
											placeholder={localString("Tax Slab")}
										/>
									</div>
									<div className="col-xs-6 float-right align-right">
										<button
											type="submit"
											name="submit"
											className="btn button-servify add_part_btn align-right"
											value="Add New Part"
											onClick={addNewPartToPartsArray}
										/>
										<button
											type="submit"
											name="cancel"
											className="btn button-servify add_part_btn"
											value="Cancel"
											onClick={
												cancelAddNewPartToPartsArray
											}
										/>
									</div>
								</div>
							)}
							<div className="row form-group-filter-request">
								{/* <!--<div className="row form-group-filter-request" if={partsArray.length > 0}>--> */}
								<div className="col-sm-12">
									{/* <!--<p className="parts-table-header text-capitalize remove-margin">Added Parts:</p>--> */}
								</div>
								{partsArray &&
									partsArray.length > 0 &&
									partsArray.map((part, idx) => (<>
										<Table
											className={{
												table:"table table-bordered table-condensed table-striped added-parts-details"
											}} 
											style={{marginBottom:"20px"}}
											key={part.Name + part.PartCode + idx}
											// className="add-parts-table"
											// style={{marginBottom: '20px'}}
										>
											<Table.Row>
												<Table.Heading className="width40Per">
													{localString("Part Name")}
												</Table.Heading>
												{!accessFn("GSX_FLOW") && (
													<Table.Data>{part.Name}</Table.Data>
												)}
												{accessFn("GSX_FLOW") && (
													<Table.Data>
														{part?.part?.Name}-
														{part?.part?.PartCode}
													</Table.Data>
												)}
											</Table.Row>
											{ !accessFn("GSX_FLOW") && <Table.Row>
													<Table.Heading className="width40Per">
														{localString("Part Code")}
													</Table.Heading>
													<Table.Data>{part.PartCode}</Table.Data>
											</Table.Row> }
											{!accessFn("GSX_FLOW") && (<Table.Row>
													<Table.Heading className="width40Per">
														{localString("HSN Code")}
													</Table.Heading>
													<Table.Data>{part.ChargeCode}</Table.Data>
											</Table.Row>)}
											{accessFn("GSX_FLOW") && (<Table.Row>
												{accessFn("GSX_FLOW") && (
													<Table.Heading className="width40Per">
														{localString("Is Part Abused")}
													</Table.Heading>
												)}
												{accessFn("GSX_FLOW") && (
													<Table.Data>
														{part.abused
															? localString("Yes")
															: localString("No")}
													</Table.Data>
												)}
											</Table.Row>)}
											{accessFn("GSX_FLOW") && (<Table.Row>
												{accessFn("GSX_FLOW") && (
													<>
														{" "}
														<Table.Heading className="width40Per">
															{localString("Is Returnable Damage")}
														</Table.Heading>
														<Table.Data>
															{part.returnableDamage
																? localString("Yes")
																: localString("No")}
														</Table.Data>
													</>
												)}
											</Table.Row>)}
											{accessFn("GSX_FLOW") && (<Table.Row>
												<>
													{" "}
													<Table.Heading className="width40Per">
														{localString("Pricing Option")}
													</Table.Heading>
													<Table.Data>
														{part.pricingOption.pricingOptionDescription}
													</Table.Data>
												</>
											</Table.Row>)}
											<Table.Row>
												<Table.Heading className="width40Per">
													{localString("Part Warranty")}
												</Table.Heading>
												<Table.Data>
													{part.InWarranty ? localString("In warranty") : localString("Out of warranty")}
												</Table.Data>
											</Table.Row>
											<Table.Row>
												<Table.Heading className="width40Per">
													{localString("Part Price")}
												</Table.Heading>
												{!accessFn("GSX_FLOW") && (
													<Table.Data>
														{part.Charge ? part.Charge : "-"}
													</Table.Data>
												)}
												{accessFn("GSX_FLOW") && (
													<Table.Data>
														{part?.part?.displayPrice ? part.part.displayPrice : "-"}
													</Table.Data>
												)}
											</Table.Row>
											{!accessFn("GSX_FLOW") && (<Table.Row>
												<>
													<Table.Heading className="width40Per">
														{localString("Remarks")}
													</Table.Heading>
													<Table.Data>
														{part.Remarks ? part.Remarks : "-"}
													</Table.Data>
												</>
											</Table.Row>)}
											<Table.Row>
												<Table.Heading className="width40Per">
													{localString("Qty")}
												</Table.Heading>
												<Table.Data>1</Table.Data>
											</Table.Row>
											{!accessFn("GSX_FLOW") && (
												<Table.Row>
													<>
														{" "}
														<Table.Heading className="width40Per">
															{localString("Available Qty")}
														</Table.Heading>
														<Table.Data>
															{
																part.AvailableQuantity
															}
														</Table.Data>
													</>
												</Table.Row>
											)}
											<Table.Row>
												<Table.Heading className="width40Per">
													{localString("Action")}
												</Table.Heading>
												<Table.Data>
													<Button
														handleOnClick={(e) => removeAddedPart(e, part, idx)}
														className='btn squareBtnGhost'
														text={localString("Remove")}
													/>
												</Table.Data>
											</Table.Row>
										</Table></>
									))}
								<Table
									className={{
										table:"table table-bordered table-condensed table-striped"
									}} 
								>
									<Table.Row>
										<Table.Heading className="width40Per">
											{localString("Part Name")}
										</Table.Heading>
										<Table.Data className="parts-dropdown scrollable-dropdown-menu">
											<Dropdown
												name="newPartsDropDown"
												id="newPartsDropDown"
												value={newPartsDropDown || ""}
												options={partsList}
												handleOnChange={handleNewPartsDropDown}
												showTooltip={true}
												filterBy="value"
												searchText={localString("Select a Part Name")}
												showDownArrow={false}
												// handleOnSearch={(e) =>
												// 	newPartsDropDownOptions.filter(
												// 		(obj) =>
												// 			obj.value
												// 				.toLowerCase()
												// 				.includes(
												// 					e.target.value.toLowerCase()
												// 				)
												// 	)
												// }
												// noOptionsText="Cannot find the searched option"
												placeholder={localString("Select a Part Name")}
											/>
										</Table.Data>
									</Table.Row>
									{!accessFn("GSX_FLOW") && (
										<Table.Row>
											<Table.Heading className="width40Per">
												{localString("Part Code")}
											</Table.Heading>
											<Table.Data>
												{selectedPart &&
													selectedPart.PartCode}
											</Table.Data>
										</Table.Row>
									)}
									{!accessFn("GSX_FLOW") && (
										<Table.Row>
											<Table.Heading className="width40Per">{localString("HSN Code")}</Table.Heading>
											<Table.Data>
												{selectedPart &&
													selectedPart.ChargeCode}
											</Table.Data>
										</Table.Row>
									)}
									{(!consumerServiceRequest.IsUnderWarranty || !consumerServiceRequest.IsWarrantyApplicable && consumerServiceRequest.ServiceTypeID != 39) && (
										<Table.Row>
											<Table.Heading className="width40Per">
												{localString("Part Warranty")}
											</Table.Heading>
											<Table.Data className="parts-dropdown scrollable-dropdown-menu">
												<Dropdown
													name="part_warranty"
													id="part_warranty"
													value={
														partwarranty ||
														""
													}
													options={
														newPartWarrantyAndPart_warrantyOptions
													}
													handleOnChange={
														handlePart_warranty
													}
													showTooltip={true}
													filterBy="value"
													searchText={localString(
														"Warranty"
													)}
													showDownArrow={
														false
													}
													handleOnSearch={(
														e
													) =>
														newPartWarrantyAndPart_warrantyOptions.filter(
															(obj) =>
																obj.value
																	.toLowerCase()
																	.includes(
																		e.target.value.toLowerCase()
																	)
														)
													}
													// noOptionsText="Cannot find the searched option"
													placeholder={localString(
														"Warranty"
													)}
												/>
											</Table.Data>
										</Table.Row>
									)}
									{accessFn("GSX_FLOW") && (
										<Table.Row>
											<Table.Heading className="width40Per">
												{localString("Is Part Abused")}
											</Table.Heading>
											<Table.Data>
												{/* check this out  */}
												<label className="">
													<input
														type="radio"
														name="abused"
														onClick={selectFlags}
														value="1"
													/>
													{localString("Yes")}
												</label>
												<label className="">
													<input
														type="radio"
														name="abused"
														id="abused"
														onClick={selectFlags}
														checked={!isAbused}
														value="0"
													/>
													{localString("No")}
												</label>
											</Table.Data>
										</Table.Row>
									)}
									{accessFn("GSX_FLOW") && (
										<Table.Row>
											<Table.Heading className="width40Per">
												{localString(
													"Is Returnable Damage"
												)}
											</Table.Heading>
											<Table.Data>
												<label className="">
													<input
														type="radio"
														name="returnable"
														value="1"
														onClick={selectFlags}
													/>
													{localString("Yes")}
												</label>
												<label className="">
													<input
														type="radio"
														name="returnable"
														id="returnable"
														value="0"
														onClick={selectFlags}
														checked={!isReturnable}
													/>
													{localString("No")}
												</label>
											</Table.Data>
										</Table.Row>
									)}
									{accessFn("GSX_FLOW") && (
										<Table.Row>
											<Table.Heading className="width40Per">
												{localString("Pricing Option")}
											</Table.Heading>
											<Table.Data className="parts-dropdown scrollable-dropdown-menu">
												<Dropdown
													name="pricingOption"
													id="pricingOption"
													value={pricingOption || ""}
													options={pricingOptionOptions}
													handleOnChange={handlePricingOption}
													showTooltip={true}
													filterBy="value"
													showDownArrow={false}
													// handleOnSearch={(e) =>
													// 	pricingOptionOptions.filter(
													// 		(obj) =>
													// 			obj.value
													// 				.toLowerCase()
													// 				.includes(
													// 					e.target.value.toLowerCase()
													// 				)
													// 	)
													// }
													// noOptionsText="Cannot find the searched option"
													placeholder={localString("Pricing Option")}
												/>
											</Table.Data>
										</Table.Row>
									)}
									<Table.Row>
										<Table.Heading className="width40Per">
											{localString("Part Price")}
										</Table.Heading>
										<Table.Data>{price ? price : "-"}</Table.Data>
									</Table.Row>
									<Table.Row>
										{!accessFn("GSX_FLOW") && (
											<>
												{" "}
												<Table.Heading className="width40Per">
													{localString("Remarks")}
												</Table.Heading>
												<Table.Data>
													<Input
														name="partRemark"
														id="partRemark"
														inputType="text"
														value={partRemark | ""}
														handleOnChange={(e) => {
															setPartRemark(e.target.value);
														}}
														placeholder={localString("Remark")}
													/>
												</Table.Data>
											</>
										)}
									</Table.Row>
									<Table.Row>
										<Table.Heading className="width40Per">
											{localString("Qty")}
										</Table.Heading>
										<Table.Data>1</Table.Data>
										{/* <!--<Table.Data>-->
                        					<!--<input type="number" name="partQty" id="partQty" autocomplete="off" data-required="one" oninput={checkQuantity} placeholder="Qty" className="form-control filter-request-height remove-border"/>-->
                    					<!--</Table.Data>--> */}
									</Table.Row>
									<Table.Row>
										{!accessFn("GSX_FLOW") && (
											<>
												<Table.Heading className="width40Per">
													{localString("Available Qty")}
												</Table.Heading>
												<Table.Data>{availableQuantity}</Table.Data>
											</>
										)}
									</Table.Row>
									<Table.Row>
										<Table.Heading className="width40Per">
											{localString("Action")}
										</Table.Heading>
										{accessFn("GSX_FLOW") && (
											<Table.Data>
												{/* <button
													className="delete-charges-btn action-button-table"
													onClick={
														addToPartsValidation
													}
												>
													{localString("Add")}
												</button> */}
												<Button
													handleOnClick={addToPartsValidation}
													className='btn squareBtnGhost'
													text={localString("Add")}
												/>
											</Table.Data>
										)}
										{!accessFn("GSX_FLOW") && (
											<Table.Data>
												<Button
													handleOnClick={addWorkshopParts}
													className='btn squareBtnGhost'
													text={localString("Add")}
												/>
											</Table.Data>
										)}
									</Table.Row>
								</Table>
							</div>
							{partsArray.length > 0 && (
								<div className="row form-group-filter-request">
									<div className="request-filter-button-div">
										<Button
											handleOnClick={saveParts}
											className='btn squareBtnGhost'
											text={localString("Save")}
										/>
									</div>
								</div>
							)}
						{/* </form> */}
					</div>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
};

export default AddPartsModal;
