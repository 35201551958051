import React from 'react';
import {Button} from 'sfy-react';
import './CategoryBrandList.scss'

const CategoryBrandList = (props) => {
    const {showBrands, localString, selectedSubCategory, subCategoryList, offersData, discountModalFunction, selectedBrand, brandList} = props;
    return (
        <>
        {
            !showBrands && 
            <div className="sales-home-content sales-home-mobile-content content content-height">
                <div className="d-flex sales-home-content-header">
                    <div>
                        <h6 className="select-category-header">{localString("Select Category")}</h6>
                    </div>
                    {
                        offersData && offersData.length > 0 &&  
                        <div className="discount-offers-div">
                            <Button 
                                handleOnClick={discountModalFunction}
                                type='button' 
                                classes='btn button-servify reverse-btn' 
                                text={localString("View all available offers")}>
                            </Button>  
                        </div>				
                    }
                </div>
                <div className="row sales-home-content-plans">
                {
                    subCategoryList && subCategoryList.map((list,i)=>{
                        return(
                            <div className="single-plan center-block col-md-2 col-xs-6">
                                {/* Categories for various plans */}
                                <div className="sale-plan-card" onClick={(e)=> {e.preventDefault(); selectedSubCategory(list)}}>
                                    <div className=" title-plan"> {list.ProductSubCategory} </div>
                                    <div className="plan-image">
                                        <img className="brand-image center-block img-responsive" src={list.ImageUrl}/>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        }
        {
            showBrands && 
            <div className="sales-home-content sales-home-mobile-content content content-height">
                <div className="d-flex sales-home-content-header"> 
                    <div>
                        <h6 className="select-category-header">{localString("Select Brand")}</h6> 
                    </div>
                    {
                        offersData && offersData.length > 0 &&  
                        <div className="discount-offers-div">
                            <Button 
                                handleOnClick={discountModalFunction}
                                type='button' 
                                classes='btn button-servify reverse-btn' 
                                text={localString("View all available offers")}>
                            </Button>  
                        </div>				
                    }
                </div>
                <div className="row sales-home-content-plans">
                {
                    brandList && brandList.map((list,i)=>{
                        return(
                            <div className="single-plan center-block col-md-2 col-xs-6">
                                {/* Single brand for various plans */}
                                <div className="sale-plan-card" onClick={(e)=> {e.preventDefault(); selectedBrand(list)}}>
                                    <div className=" title-plan"> {list.BrandName} </div>
                                    <div className="plan-image">
                                        <img className="brand-image center-block img-responsive" src={list.ImageUrl}/>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        }
    </>
    )
}

export default CategoryBrandList