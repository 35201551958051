import React, {useState,useEffect} from 'react';
import TabCollapse from '../../components/Tab/TabCollapse';
import moment from 'moment';

const ReliabilityRecords = (props) => {
    const {localString, reliabilityData} = props;
    const [records, setRecords] = useState([]);
    useEffect(() => {
        let data = reliabilityData.find((obj) => obj.name === 'Win32_ReliabilityRecords');
        setRecords(data);
    },[reliabilityData]);
    return (
        <div>
            <p><h4>{localString('Reliability records')}</h4></p>
            {records.value && records.value.length > 0 && records.value.map((obj) => { return(
                <TabCollapse
                headerText= {obj.SourceName}
                date={moment(obj.TimeGenerated).format('DD MMM, YYYY hh:mm a')}
                showIssueCount={false}
            >
                {Object.keys(obj).length > 0 && Object.keys(obj).map((keys) => {return(
                    <div className="margin-bottom-10"><strong>{keys}</strong> : {obj[keys]}</div>
                )})}
            </TabCollapse>
            )})}
        </div>
    )
}

export default ReliabilityRecords
