import React,{useState, useEffect}  from 'react';
import ReactDOM from 'react-dom';
import {Loader,Modal} from 'sfy-react';
import {displayError, getStatusString} from '../../../Utility/Utils';
import Swal from "sweetalert2";
import FilterInputs from '../../../components/FilterInputs/FilterInput';
import './FilterRequestModal.scss'
import { debounce, result } from 'lodash';

const FilterRequestModal = (props) => {
    const {accessFn, localString, hideClearButton, apiCall, buttonDisable, applyFilter, countriesData,loaded,setLoaded,clearButtonFunction, showFilterModal, closeModal, requestFilterData, setRequestFilterData, 
		subcategoryData, setSubcategoryData, brandData, setbrandData, productData, setProductData, statusList, setStatusList, serviceTypes, setServiceTypes,logisticsProviderList, setLogisticsProviderList,
		registrationSourceList, setRegistrationSourceList, cityList, setCityList,callStatus, setCallStatus, escalationTypes, setEscalationTypes, serviceCenters, setServiceCenters, engineerList, setEngineerList,
		locationInput, setLocationInput, searchText, setSearchText, taskStatusType, emptyBoxAvailbility, transferRequestOptions, setRequirePackagingMaterial, disableState, setDisableState, stateDataList, setStateDataList, 
		countryDataList, setCountryDataList, showLoader, setLoader, qFilterData,setCountryOfCustomerList,planList,setPlanList} = props;
	

	useEffect(() => {
		getServiceCenterValue(searchText);
	}, [searchText])

	const loadSubCategories = () => {
		setLoader({...showLoader,showSubCategoryLoader:true});
		let data = {
			qFilter:{}
		}
		if(requestFilterData && requestFilterData.brands){
			data.qFilter.brands = requestFilterData.brands
		}
		apiCall({
			url:'getSubcategory',
			data: data,
			callBack:(result)=>{
				if(result.success){
					initSubCategory(result);
					setLoader({...showLoader,showSubCategoryLoader:false})
					setLoaded({...loaded,subCategoryLoaded:true});
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initSubCategory=(result)=>{
		let data = result.data.map(v => ({...v, value: v.ProductSubCategory}))
		setSubcategoryData(data);
		// setRequestFilterData({...requestFilterData,productName:""})
	}
	const loadBrands = () => {
		let data = {
			qFilter:{}
		}
		if(requestFilterData && requestFilterData.productSubCategories){
			data.qFilter.productSubCategories = requestFilterData.productSubCategories
		}
		setLoader({...showLoader,showBrandLoader:true})
		apiCall({
			url:'GetBrands',
			data: data,
			callBack:(result)=>{
				if(result.success){
					initBrand(result);
					// setIsload(result.data)
					setLoader({...showLoader,showBrandLoader:false});
					setLoaded({...loaded,brandLoaded:true});
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initBrand=(result)=>{
		let data = result.data.map(v => ({...v, value: v.BrandName}))
		setbrandData(data);
	}
	const loadProducts = () => {   	// need to verify about PSL-ID
		setLoader({...showLoader,showProductLoader:true})
		let data = {
			qFilter:{}
		}
		if(requestFilterData && requestFilterData.brands){
			data.qFilter.brands = requestFilterData.brands
		}		
		if(requestFilterData && requestFilterData.productSubCategories){
			data.qFilter.productSubCategories = requestFilterData.productSubCategories
		}
		// if (PartnerServiceLocationID) {
        //     data.qFilter.serviceLocations = [PartnerServiceLocationID];
        // }
		apiCall({
			url:'GetProducts',
			data: data,
			callBack:(result)=>{
				if(result.success){
					initProduct(result);
					setLoader({...showLoader,showProductLoader:false})
					setLoaded({...loaded,productLoaded:true});
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initProduct=(result)=>{
		let data = result.data.map(v => ({...v, value: v.ProductName}))
		setProductData(data);
	}
	const loadAllServiceTypes = () => {
		setLoader({...showLoader,showServiceTypeLoader:true})
		apiCall({
			url:'getAllServiceTypes',
			data: {},
			callBack:(result)=>{
				if(result.success){
					initAllServiceTypes(result);
					setLoader({...showLoader,showServiceTypeLoader:false})
					setLoaded({...loaded,serviceTypeLoaded:true})
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initAllServiceTypes=(result)=>{
		let data = result.data.map(v => ({...v, value: v.Type}))
		setServiceTypes(data)
	}
	const loadServiceCenters = (locationInput) => {
		setLoader({...showLoader,showServiceCenterLoader:true})
		apiCall({
			url:'serviceCenterList',
			data: {
				ServiceLocationNameSubString: locationInput,
				SearchCountryID: countriesData && countriesData.CountryID ? countriesData.CountryID : undefined,   // nned to verify
			},
			callBack:(result)=>{
				if(result.success){
					initServiceCenters(result);
					setLoader({...showLoader,showServiceCenterLoader:false});
					setLoaded({...loaded,serviceCentersLoaded:true})
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}
	
	const initServiceCenters=(result)=>{
		let data = result.data && result.data.map(v => ({...v, value: v.ServiceLocationName}))
		setServiceCenters(data)
	}
	const loadstatusList = () => {
		setLoader({...showLoader,showStatusLoader:true});
		apiCall({
			url:'statusList',
			data: {},
			callBack:(result)=>{
				if(result.success){
					initStatusList(result);
					setLoader({...showLoader,showStatusLoader:false});
					setLoaded({...loaded,statusListLoaded:true});
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initStatusList=(result)=>{
		let data = result.data.map(v => ({...v, value: getStatusString(v.DisplayText)}))
		setStatusList(data);
	}
	const loadLogisticsProviderList = () => {
		setLoader({...showLoader,showLogisticLoader:true});
		apiCall({
			url:'getLogisticsProviderList',
			data: {},
			callBack:(result)=>{
				if(result.success){
					initLogisticsProvider(result);
					setLoader({...showLoader,showLogisticLoader:false});
					setLoaded({...loaded,logisticsProviderLoaded:true});
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initLogisticsProvider=(result)=>{
		let data = result.data.map(v => ({...v, value: v.Vendor}))
		setLogisticsProviderList(data);
	}
	const loadRegistrationSourceList = () => {
		setLoader({...showLoader,showSourceLoader:true});
		apiCall({
			url:'getRegistrationSourceList',
			data: {},
			callBack:(result)=>{
				if(result.success){
					initRegistrationSource(result);
					setLoader({...showLoader,showSourceLoader:false});
					setLoaded({...loaded,registrationSourceLoaded:true});
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initRegistrationSource=(result)=>{
		let data = result.data.map(v => ({...v, value: v.Source}))
		setRegistrationSourceList(data);
	}
	const loadCityList = () => {
		setLoader({...showLoader,showCityLoader:true})
		apiCall({
			url:'getCityList',
			data: {},
			callBack:(result)=>{
				if(result.success){
					initCityList(result);
					setLoader({...showLoader,showCityLoader:false})
					setLoaded({...loaded,cityListLoaded:true});
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initCityList=(result)=>{
		let data = result.data.filter(v => {
			if(v.City != null){
				return v
			}
		})   	// need to eliminate null || "" values
		let cityData=data.map(city=>({...city,value:city.City}))
		setCityList(cityData);
	}
	const loadCallStatus = () => {
		setLoader({...showLoader,showCallStatusLoader:true})
		apiCall({
			url:'getCallStatus',
			data: {"getMergedStatuses": true},
			callBack:(result)=>{
				if(result.success){
					initCallStatus(result);
					setLoader({...showLoader,showCallStatusLoader:false});
					setLoaded({...loaded,callStatusLoaded:true})
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initCallStatus=(result)=>{
		let data = result.data.map(v => ({...v, value: v.displayText}))
		setCallStatus(data);
	}
	const loadEscalationTypes = () => {
		setLoader({...showLoader,showEscalationTypeLoader:true})
		apiCall({
			url:'getEscalationTypes',
			data: {},
			callBack:(result)=>{
				if(result.success){
					initEscalationTypes(result);
					setLoader({...showLoader,showEscalationTypeLoader:false});
					setLoaded({...loaded,escalationTypeLoaded:true});
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initEscalationTypes=(result)=>{
		let data = result.data && result.data.escalationTypes.map(v => ({...v, value: v.EscalationName}))
		setEscalationTypes(data);
	}
	const loadEngineerListForFilter = () => {
		setLoader({...showLoader,showEngineerListLoader:true})
		let data = {}
		if (requestFilterData && requestFilterData.serviceLocations) {
			data.PartnerServiceLocationIDs = requestFilterData.serviceLocations;
		}
		apiCall({
			url:'getEngineerListForFilter',
			data: data,
			callBack:(result)=>{
				if(result.success){
					initEngineerList(result);
					setLoader({...showLoader,showEngineerListLoader:false});
					setLoaded({...loaded,engineerListLoaded:true});
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initEngineerList=(result)=>{
		let data = result.data && result.data.map(v => ({...v, value: v.Name}))
		setEngineerList(data)
	}
	let getServiceCenterValue = debounce(function(val){
		let locationInput = val && val;
		if (locationInput.length > 2){
			// setRequestFilterData({...requestFilterData,engineerListData:''})
			loadServiceCenters(locationInput)
		}
	});

	const loadServiceCenterCountry = () =>{
		// setLoader({...showLoader,showServiceCenterCountryLoading:true,showCountryofCustomerLoading:true})
		let data = {
			// specificCountryCode: $('#mobileNo').intlTelInput('getSelectedCountryData').iso2.toUpperCase(),
			countryConfig: false, //no need of counrty config on this page right now
		};
		apiCall({
			url:'getCountries',
			data: data,
			callBack:(result)=>{
				if(result.success){
					if(result.data){
						let countriesData = result.data.countries;
						setLoader({...showLoader,showServiceCenterCountryLoading:false,showCountryofCustomerLoading:false});
						setLoaded({...loaded,serviceCenterCountryLoaded:true,countryofCustomerLoaded:true});
						initServiceCenterCountryFilter(countriesData)
					}else{
						Swal.fire ({
							title: '',
							text: localString('No country data found.'),
							icon: 'error',
							confirmButtonText: localString('OK'),
							cancelButtonColor: localString('Cancel')
						})
					}
				}else{
					Swal.fire ({
						title: '',
						text: localString(displayError(result)),
						icon: 'error',
						confirmButtonText: localString('OK'),
						cancelButtonText: localString('Cancel')
					});
				}
			}
		});
	}

	const initServiceCenterCountryFilter = (countryList) =>{
		let countryFilter = countryList && countryList.filter(country => country.CountryName)
		let countries = countryFilter.map(v=>({...v,value:v.CountryName}));
		setCountryDataList(countries);
	}


	const loadServiceCenterStates = () =>{
		setLoader({showLoader,showServiceCenterStatesLoading:true});
		let data = { CountryID: requestFilterData.PslCountryID ? requestFilterData.PslCountryID : null}
		apiCall({
			url:'getStateList',
			data: data,
			callBack:(result)=>{
				if(result.success){
					initServiceCenterStateFilter(result.data);
					setLoader({showLoader,showServiceCenterStatesLoading:false});
					setLoaded({...loaded,serviceCenterStatesLoaded:true})
				}
			}
		});
	}
	const initServiceCenterStateFilter = (stateList) =>{
		let states = stateList && stateList.map(v=>({...v, value:v.State}))
		setStateDataList(states);
	}

	const loadPlanId = () => {
		setLoader({...showLoader,showPlansLoading:true})
		let data = {
			qFilter:{}
		}
		data.qFilter.plans = qFilterData.PlanIDs;
		// data.qFilter.applyPlanFilterExplicitly = true; 
		apiCall({
			url:'getPlanList',
			data: data,
			callBack:(result)=>{
				if(result.success){
					let planData = result.data;
					initPlanList(planData);
					setLoader({...showLoader,showPlansLoading:false});
					setLoaded({...loaded,plansLoaded:true});
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initPlanList = (planList)=>{
		let plans = planList && planList.map(v=>({...v, value:v.PlanDisplayName}))
		setPlanList(plans)
	}
	let requestInputs = [
		{
			labelText:localString('Consumer Name'),
			dataKey:"Name",
			showFilter:true,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['Name']:val})
			}
		},
		{
			labelText:localString('Contact No'),
			dataKey:"MobileNo",
			showFilter:true,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['MobileNo']:val})
			}
		},{
			labelText:localString('Reference ID'),
			dataKey:"ReferenceID",
			showFilter:true,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['ReferenceID']:val})
			}
		},{
			labelText:localString('Brand'),
			dataKey:"brandName",
			type :'Dropdown',
			dropdownList:brandData,
			showFilter:true,
			loaded:loaded.brandLoaded,
			showLoader: showLoader.showBrandLoader,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['brandName']:val.BrandName,['brands']:[val.BrandID],productSubCategorieName:"",productName:""})
				setLoaded({...loaded,subCategoryLoaded:false,productLoaded:false});
			},
			oninputFocus: () => {
				if(!loaded.brandLoaded){
					loadBrands();
				}
			},
		},{
			labelText:localString('Subcategory'),
			dataKey:"productSubCategorieName",
			type :'Dropdown',
			dropdownList:subcategoryData,
			loaded:loaded.subCategoryLoaded,
			showLoader: showLoader.showSubCategoryLoader,
			showFilter:true,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['productSubCategorieName']:val.ProductSubCategory,['productSubCategories']:[val.ProductSubCategoryID],productName:""});
				setDisableState({...disableState,productDisable:false})
				setLoaded({...loaded,productLoaded:false});
			},
			oninputFocus: () => {
				if(!loaded.subCategoryLoaded){
					loadSubCategories();
				}
			},
		},{
			labelText:localString('Product'),
			dataKey:"productName",
			type :'Dropdown',
			dropdownList:productData,
			showFilter:true,
			isDisabled:disableState.productDisable,
			loaded:loaded.productLoaded,
			showLoader: showLoader.showProductLoader,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['productName']:val.ProductName,['products']:[val.ProductID]})
			},
			oninputFocus: () => {
				if(!loaded.productLoaded){
					loadProducts();
				}
			},
		},{
			labelText:localString("IMEI/Serial No"),
			dataKey:"ImeiNumber",
			showFilter:true,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['ImeiNumber']:val})
			}
		},{
			labelText:localString('Service Type'),
			dataKey:"serviceTypeName",
			type :'Dropdown',
			dropdownList:serviceTypes,
			loaded:loaded.serviceTypeLoaded,
			showLoader: showLoader.showServiceTypeLoader,
			showFilter:true,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['serviceTypeName']:val.Type,['serviceTypes']:[val.ServiceTypeID]})
			},
			oninputFocus: () => {
				if(!loaded.serviceTypeLoaded){
					loadAllServiceTypes();
				}
			}
		},{
			labelText:localString('Status'),
			dataKey:"statusString",
			type :'Dropdown',
			dropdownList:statusList,
			loaded:loaded.statusListLoaded,
			showLoader: showLoader.showStatusLoader,
			showFilter:true,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['statusString']:val.value,['statusActualString']:val.DisplayText})				
			},
			oninputFocus: () => {
				if(!loaded.statusListLoaded){
					loadstatusList();
				}
			}
		},{
			labelText:`${localString('Country')} (${(localString('Service Center'))})` ,
			dataKey:"countryServiceCenter",
			type :'Dropdown',
			dropdownList:countryDataList,
			loaded:loaded.serviceCenterCountryLoaded,
			showLoader: showLoader.showServiceCenterCountryLoading,
			showFilter:true,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['countryServiceCenter']: val.CountryName,['PslCountryID']:val.CountryID});
				setDisableState({...disableState,stateDisable:false});
				setLoaded({...loaded,serviceCenterStatesLoaded:false})
			},
			oninputFocus: () => {
				setLoader({...Loader,showServiceCenterCountryLoading:true});
				if(countryDataList.length == 0){
					if(!loaded.serviceCenterCountryLoaded ){
						loadServiceCenterCountry();
					}
				}else{}
			}
		},
		{
			labelText:`${localString('State')} (${(localString('Service Center'))})`,
			dataKey:"state",
			type :'Dropdown',
			dropdownList:stateDataList,
			isDisabled:disableState.stateDisable,
			loaded:loaded.serviceCenterStatesLoaded,
			showLoader: showLoader.showServiceCenterStatesLoading,
			showFilter:true,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['state']:val.State})
			},
			oninputFocus: () => {
				if(!loaded.serviceCenterStatesLoaded){
					loadServiceCenterStates();
				}
			}
		},
		{
			labelText:localString('Service Center'),
			dataKey:"serviceLocationName",
			type :'Dropdown',
			showFilter:true,
			dropdownList:serviceCenters,
			showLoader: showLoader.showServiceCenterLoader,
			loaded:loaded.serviceCentersLoaded,
			showSearchFilter:true,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['serviceLocationName']:val.ServiceLocationName,['serviceLocations']:[val.PartnerServiceLocationID],['engineerListData']:''});
				setLoaded({...loaded,engineerListLoaded:false});
				setDisableState({...disableState,assignEngineerDisable:false});
				setEngineerList([]);
			},
			onInputSearch: (val) => {
				setSearchText(val)
				setRequestFilterData({...requestFilterData,['engineerListData']:''});
			},
		},
		{
			labelText:`${localString('Country')} ${localString('(Customer)')}`,
			dataKey:'customerCountry',
			showFilter:true,
			type :'Dropdown',     
			showLoader: showLoader.showCountryofCustomerLoading,
			dropdownList:countryDataList,
			loaded:loaded.countryofCustomerLoaded,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['customerCountry']:val.CountryName,['CustomerCountryID']:val.CountryID})
			},
			oninputFocus: () => {
				setLoader({...Loader,showCountryofCustomerLoading:true});
				if(countryDataList.length == 0){
					if(!loaded.serviceCenterCountryLoaded ){
						loadServiceCenterCountry();
					}
				}else{}
			}
		},
		{
            labelText:localString('Plans'),
            dataKey:"planName",
            showFilter:true,
            type :'Dropdown',     
            showLoader: showLoader.showPlansLoading,
            dropdownList:planList,
            loaded:loaded.plansLoaded,
            oninputFunc: (val) => {
                setRequestFilterData({...requestFilterData,['planName']:val.PlanDisplayName,['plans']:[val.PlanID],['applyPlanFilterExplicitly']:true})
            },
            oninputFocus: () => {
                if(!loaded.plansLoaded){
                    loadPlanId();
                }
            }
        },
		{
			labelText:localString('Logistics Provider'),
			dataKey:"logisticsProvider",
			showFilter:true,
			type :'Dropdown',
			dropdownList:logisticsProviderList,
			showLoader: showLoader.showLogisticLoader,
			loaded:loaded.logisticsProviderLoaded,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['logisticsProvider']:[val.Vendor]})
			},
			oninputFocus: () => {
				if(!loaded.logisticsProviderLoaded){
					loadLogisticsProviderList();
				}
			}
		},{
			labelText:localString('Registration Source'),
			dataKey:"sources",
			showFilter:true,
			showLoader: showLoader.showSourceLoader,
			type :'Dropdown',
			dropdownList:registrationSourceList,
			loaded:loaded.registrationSourceLoaded,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['sources']:[val.Source]})
			},
			oninputFocus: () => {
				if(!loaded.registrationSourceLoaded){
					loadRegistrationSourceList();
				}
			}
		},{
			labelText:localString('Customer City'),
			dataKey:"city",
			showFilter:true,
			type :'Dropdown',     
			showLoader: showLoader.showCityLoader,
			dropdownList:cityList,
			loaded:loaded.cityListLoaded,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['city']:[val.City]})
			},
			oninputFocus: () => {
				if(!loaded.cityListLoaded){
					loadCityList();
				}
			}
		},{
			labelText:localString('Zipcode'),
			dataKey:"zipcodes",
			showFilter:true,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['zipcodes']:[val]})
			}
		},{
			labelText:localString('Incident ID'),
			dataKey:"ExternalReferenceID",
			showFilter:true,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['ExternalReferenceID']:val})
			}
		},{
			labelText:localString('Call Type'),
			dataKey:"callStatusName",
			showFilter:true,
			showLoader: showLoader.showCallStatusLoader,
			type :'Dropdown',
			dropdownList:callStatus,
			loaded:loaded.callStatusLoaded,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['callStatusName']:val.displayText,['callStatus']:[val.id]})
			},
			oninputFocus: () => {
				if(!loaded.callStatusLoaded){
					loadCallStatus();
				}
			}
		},{
			labelText:localString('Task Type'),
			dataKey:"taskStatus",
			showFilter:true,
			type :'Dropdown',
			showLoader: showLoader.showTaskLoader,
			dropdownList:taskStatusType,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['taskStatus']:val.value})
			},
			oninputFocus: () => {
			}
		},{
			labelText:localString('External Reference ID'),   //need to verify
			dataKey:"IntegrationRefID",
			showFilter:accessFn('EXTERNAL_REFERENCE_FILTER'),
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['IntegrationRefID']:val})
			}
		},
		{
			labelText:localString('Escalation Type'),  // need to verify
			dataKey:"EscalationType",
			showFilter:true,
			type :'Dropdown',
			loaded:loaded.escalationTypeLoaded,
			showLoader: showLoader.showEscalationTypeLoader,
			dropdownList:escalationTypes,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['EscalationType']:[val.EscalationName]})
			},
			oninputFocus: () => {
				if(!loaded.escalationTypeLoaded){
					loadEscalationTypes();
				}
			}
		},
		{
			labelText:localString('GSX Confirmation Number'),	// need to verify
			dataKey:"gsxNumber",
			showFilter:accessFn('GSX_FLOW') ,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['gsxNumber']:val})
			}
		},
		{
			labelText:localString('Purchase Order Number'),		// need to verify
			dataKey:"poNumber",
			showFilter:accessFn('GSX_FLOW'),
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['poNumber']:val})
			}
		},
		{
			labelText:localString('Assigned Engineers'),	// need to verify
			dataKey:"engineerListData",
			type :'Dropdown',
			isDisabled:qFilterData.servicelocations ? !disableState.assignEngineerDisable :disableState.assignEngineerDisable,
			showLoader: showLoader.showEngineerListLoader,
			loaded:loaded.engineerListLoaded,
			dropdownList:engineerList,
			showFilter:accessFn('ACCEPT_RETURN_DEVICE'),
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['engineers']:[val.ServiceLocationEngineerID],['engineerListData']:val.Name});
			},
			oninputFocus: () => {
				if(!loaded.engineerListLoaded){
					loadEngineerListForFilter();
				}
			}
		},
		{
			labelText:localString('Packaging Material Required'),
			dataKey:"packagingMaterialRequired",
			type :'Dropdown',
			showLoader: showLoader.showPackagingLoader,
			dropdownList:emptyBoxAvailbility,
			showFilter:accessFn('PACKAGE_DETAILS_FILTER'),
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['packagingMaterialRequired']:val.value})
				setRequirePackagingMaterial(val.isValid)
			},
		},
		{
			labelText:localString('Transfer Requests'),
			dataKey:"pslTransferredRequest",
			type :'Dropdown',
			showLoader: false,
			dropdownList: transferRequestOptions,
			showFilter: true,
			oninputFunc: (val) => {
				setRequestFilterData({...requestFilterData,['pslTransferredRequest']:val.value})
				setRequirePackagingMaterial(val.isValid)
			}
		}
	];


	return (
		<>
		{/* { showLoader && ReactDOM.createPortal(
			<Loader />,
			document.getElementById("loader-root")
		)} */}
		<div className="FilterRequest">
			<Modal showModal={showFilterModal} closeModal={closeModal} showCloseBtn={true} className="FilterRequest-modal">
				<Modal.Header>
					<h2> {localString("Filter Requests")} </h2>
				</Modal.Header>
				<Modal.Body>
                    <FilterInputs
						{...props}
						disableApplyBtn={buttonDisable}
						filterFunc={applyFilter}
						filterInputs={requestInputs}
						filterData={requestFilterData}
						closeModal={closeModal}
						hideClearButton={hideClearButton}
						saveButtonText="Apply"
						clearSearch={!hideClearButton ? clearButtonFunction : "" }
					></FilterInputs>
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
		</div>
	</>
	)
}

export default FilterRequestModal