import React, { useState } from 'react';

const Tab = (props) => {
	const { localString, getFileStatus,activeTab,setActiveTab,setImportType,setSearchText,
		setSelectedValue,setFirstLoad,setPagination,pagination} = props;
	const showClickedTab = (e)=>{
		if(e){
			switch(e.target.id){
				case 'actual_cost':
					getFileStatus(1,1,null)
					setActiveTab(e.target.id)
					setSearchText('')
					setSelectedValue(null)
					setFirstLoad(false)
					setImportType(1)
					setPagination({
						...pagination,
						pageNo : 1,
					})
				break;
				case 'refurb_order':
					getFileStatus(1,2,null)
					setActiveTab(e.target.id)
					setSearchText('');
					setSelectedValue(null);
					setFirstLoad(false);
					setImportType(2)
					setPagination({
						...pagination,
						pageNo : 1,
					})
				break;
				case 'update_actual_cost':
					getFileStatus(1,3,null)
					setActiveTab(e.target.id)
					setSearchText('');
					setSelectedValue(null);
					setFirstLoad(false);
					setImportType(3)
					setPagination({
						...pagination,
						pageNo : 1,
					})
				break;
				case 'cancel_service_request':
					getFileStatus(1,4,null)
					setActiveTab(e.target.id)
					setSearchText('');
					setSelectedValue(null);
					setFirstLoad(false);
					setImportType(4)
					setPagination({
						...pagination,
						pageNo : 1,
					})
				break;
				case 'refurbishment_bulk_shipment':
					getFileStatus(1,5,null)
					setActiveTab(e.target.id)
					setSearchText('');
					setSelectedValue(null);
					setFirstLoad(false);
					setImportType(5)
					setPagination({
						...pagination,
						pageNo : 1,
					})
				break;
			}
		}
	}
	const tabsArray = [
		{
			tabName:localString("Actual Cost"),
			showButton:true,
			tabID:"actual_cost",
			onButtonClick:(e)=>{
				showClickedTab(e);
			},
		},
		{
			tabName:localString("Refurbishment"),
			showButton:true,
			tabID:"refurb_order",
			onButtonClick:(e)=>{
				showClickedTab(e);
			},
		},
		{
			tabName:localString("Update Actual Cost"),
			showButton:true,
			tabID:"update_actual_cost",
			onButtonClick:(e)=>{
				showClickedTab(e);
				// setTabId(e.target.id);
			},
		},
		{
			tabName:localString("Cancel Request"),
			showButton:true,
			tabID:"cancel_service_request",
			onButtonClick:(e)=>{
				showClickedTab(e);
			},
		},
		{
			tabName:localString("Refurbishment Bulk Shipment"),
			showButton:true,
			tabID:"refurbishment_bulk_shipment",
			onButtonClick:(e)=>{
				showClickedTab(e);
			},
		},
	];
	return (
		<div className="tab-navigation">
		{tabsArray.map((tab) => (
			tab.showButton && 
			<button
				id={tab.tabID}
				key={tab.tabName}
				className={`tab-button ${activeTab === tab.tabID ? 'active' : ''}`}
				onClick={(e) => tab.onButtonClick(e)}
			>
				{tab.tabName}
			</button>
		))}
		</div>
	);
};

export default Tab;
