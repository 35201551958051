import React, { useState ,useEffect} from 'react';
import ReactDOM from 'react-dom';
import './AddCharges.scss'
import Swal from "sweetalert2";
import {Input, Button, Dropdown, Modal, Loader, Radio, Table} from 'sfy-react';
import {displayError} from '../../../../../Utility/Utils';
import { clone, cloneDeep, find } from 'lodash';
import AddedParts from './AddedParts';

const defaultPartObj = {
	selectedPart: "",
	selectedPartName: "",
	selectedPartCode: "",
	selectedPartChargeCode: "",
	selectedComponenCode: "",
	selectedComponenCodeName: "",
	selectedIssueCodeName: "",
	selectedIssueCode: "",
	selectedReproducibility: "",
	selectedReproducibilityName: "",
	selectedPartWarranty: "",
	consignmentSerialNumber: "",
	selectedSubstitutePart: "",
	selectedSubstitutePartName: "",
	selectedSecondaryPart: "",
	selectedSecondaryPartName: "",
	partSerial: ""
}

const AddPartModal = (props) => {
	const {
		localString, showPartModal, closePartModal, consumerServiceRequest, apiCall, accessFn, setShowPartModal, getRequestById, 
		editPart, editedPart, setEditPart, isEditPartModal, setIsEditPartModal, typeOfWarranty, getPartWarranty, editallIssuesList,
		comptiaModifierList, pricingOptions, componentCodeList, consigmentSerialNumberList, coverageOption, isEditedConsignmentNeeded, 
		setIsEditedConsignmentNeeded, isEditedConsignmentSerialNeeded, setIsEditedConsignmentSerialNeeded, setConsigmentSerialNumberList,
		setComponentCodeList, setComptiaModifierList, setPricingOptions, setCoverageOption, setEditallIssuesList, editPartObject, 
		setEditPartObject , editSelectedIsPartAbused, setEditSelectedIsPartAbused, editSelectedIsReturnableDamage, 
		setEditSelectedIsReturnableDamage, editSelectedIsCoveredByACPlus, setEditSelectedIsCoveredByACPlus,
		editSelectedConsignmentNeeded, setEditSelectedConsignmentNeeded, updateChargesCall
	} = props;
    const [showLoader ,setLoader] = useState(false);
    const [partObject, setPartObject] = useState(defaultPartObj);
	const [selectedPart, setSelectedPart] = useState({})
	const [partItem, setPartItem] = useState({})
	const [selectedIsPartAbused, setSelectedIsPartAbused] = useState("No")
	const [coveredByACPlus, setCoveredByACPlus] = useState("No")
	const [returnableDamage, setReturnableDamage] = useState("No")
	const [isConsignment, setIsConsignment] = useState("")
    const [finishedGoods,setFinishedGoods] = useState([]);
    const [substitutePartList, setSubstitutePartList] = useState([]);
    const [secondaryPartList, setSecondaryPartList] = useState([]);
    const [issueCodeList,setIssueCodeList] = useState([]);
	const [partArray, setPartArray] = useState([])
	const [price, setPrice] = useState("")
	const [COQty, setCOQty] = useState('');
	const [isConsignmentNeeded, setIsConsignmentNeeded] = useState(true);
	const [isConsignmentSerialNeeded, setIsConsignmentSerialNeeded] = useState(true);
	const [orderFromGSX, setOrderFromGSX] = useState(false);
	const [wholeUnit, setWholeUnit] = useState(false);

	// edit model states
	const [availableQuantity, setAvailableQuantity] = useState();
	const [isSerialized, setIsSerialized] = useState(false);
	const [isConsignmentCheck, setIsConsignmentCheck] = useState(false);
	const [originalPartCode, setOriginalPartCode] = useState('');
	const [originalPartID, setOriginalPartID] = useState('');
	const [enableselectConsignment, setEnableselectConsignment] = useState(false);

	const partsList = (props.partsList && props.partsList.map(v => ({...v, value: `${v.Name} ${v.PartCode}`}))) || [];

	// useEffect(() => {
	// 	if (isEditPartModal && typeOfWarranty && typeOfWarranty.length === 1) {
	// 		setEditPartObject((prevData) => ({
	// 			...prevData,
	// 			editSelectedPartWarranty: typeOfWarranty[0],
	// 		}));
	// 	} else {
	// 		getPartWarranty();
	// 	}
	// }, [typeOfWarranty]);

	useEffect(() => {
		getPartWarranty();
	}, []);

	const getEditPartItemsBySerialNumber = () => {
		setEditPart({ ...editPart, isConsignment: true });

		let dataForConsignment = {
			part: editPart,
			PartnerServiceLocationID:
				consumerServiceRequest.PartnerServiceLocationID,
		};

		setLoader(true);
		apiCall({
			url: "getPartItemsBySerialNumber",
			method: "post",
			data: dataForConsignment,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					let data = result.data.map((v) => ({
						...v,
						value: v.SerialNumber,
					}));
					setConsigmentSerialNumberList(data);
				} else {
					Swal.fire("", localString(displayError(result)), "error");
				}
			},
		});
	};

	const getPartItemsBySerialNumber = () => {
		let obj = {...partItem}
		obj.isConsignment = true;
		setPartItem(obj);
		let reqObj = {
			part: obj,
			PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID,
		}
		setLoader(true);
		apiCall({
			url: "getPartItemsBySerialNumber",
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					let data = result.data.map((v) => ({
						...v,
						value: v.SerialNumber,
					}));
					setConsigmentSerialNumberList(data);
				} else {
					Swal.fire("", localString(displayError(result)), "error");
				}
			},
		});
	}

	const updateEditedParts = (e) => {
		e.preventDefault();
		let editedPartObject = {};
		let partWarranty = true;
		let editedPartsArray = [];

		if (
			consumerServiceRequest.consumerProduct.IsUnderWarranty &&
			consumerServiceRequest.IsWarrantyApplicable
		) {
			partWarranty = true;
		} else {
			if (editPartObject.editSelectedPartWarranty) {
				partWarranty =
				editPartObject.editSelectedPartWarranty === "In warranty" ? true : false;
			} else {
				partWarranty = editPart.IsUnderWarranty;
			}
		}
		editedPartObject.InWarranty = partWarranty;

		if (editSelectedConsignmentNeeded === "Yes") {
			editedPartObject.isConsignment = true;
		} else {
			editedPartObject.isConsignment = false;
		}

		if (editSelectedIsPartAbused === "Yes") {
			editedPartObject.abused = true;
		} else {
			editedPartObject.abused = false;
		}

		if (
			editPartObject.editSelectedPartSerialNumber &&
			editPartObject.editSelectedPartSerialNumber.length < 10 &&
			editPart.Serialized
		) {
			Swal.fire({
				title: "",
				text: localString(
					"Please enter correct KBB serial number of the part"
				),
				type: "error",
				confirmButtonText: localString("OK"),
			});
			return;
		}

		if (
			isConsignmentCheck &&
			editPart.Serialized &&
			consumerServiceRequest.gsxRepairFlags.repairTypeId == 1 &&
			(!editPartObject.editSelectedConsignmentSerialNumber ||
				editPartObject.editSelectedConsignmentSerialNumber == "")
		) {
			Swal.fire({
				title: "",
				text: localString("Please enter the KGB serial number of the part"),
				type: "error",
				confirmButtonText: localString("OK"),
			});
			return;
		}

		if (editSelectedIsReturnableDamage === "Yes") {
			editedPartObject.returnableDamage = true;
		} else {
			editedPartObject.returnableDamage = false;
		}

		if (editSelectedIsCoveredByACPlus === "Yes") {
			editedPartObject.coveredByACPlus = true;
		} else {
			editedPartObject.coveredByACPlus = false;
		}

		if (editPartObject.editSelectedPartSerialNumber) {
			editedPartObject.serialNumber =
				editPartObject.editSelectedPartSerialNumber;
		}

		if (editPartObject.editSelectedConsignmentSerialNumber) {
			editedPartObject.consignmentSerialNumber =
				editPartObject.editSelectedConsignmentSerialNumber;
		}

		editedPartObject.part = {
			PartID: editPart.PartID,
			PartCode: editPart.PartCode,
		};

		editedPartObject.isSerialized = editPart.Serialized;
		editedPartObject.IsDeducted = false;
		editedPartObject.ChargeType = "Credit";

		editedPartObject.comptiaModifier =
			editPartObject.editSelectedReproducibility
				? editPartObject.editSelectedReproducibility
				: {
						modifierCode: editPart.comptiaModifier.comptiaModifierCode,
						comptiaDescription:
							editPart.comptiaModifier.comptiaModifierDescription,
				};

		editedPartObject.issueCode = editPartObject.editSelectedIssue
			? editPartObject.editSelectedIssue
			: {
					code: editPart.IssueCode,
					description: editPart.IssueDescription,
					reportedIssueDesc: editPart.IssueDescription,
					reportedIssueCode: editPart.IssueCode,
			};

		editedPartObject.componentCode = editPartObject.editSelectedComponent
			? editPartObject.editSelectedComponent
			: {
					ComponentCode: editPart.ComponentCode,
					reportedSymptomDesc: editPart.ComponentDescription,
					reportedSymptomCode: editPart.ComponentCode,
					componentDescription: editPart.ComponentDescription,
			};

		editedPartObject.partCoverageOption =
			editPartObject.editSelectedCoverageOption
				? editPartObject.editSelectedCoverageOption
				: editPart.coverageOption;

		editedPartObject.pricingOption = editPartObject.editSelectedPricingOption
			? editPartObject.editSelectedPricingOption
			: editPart.pricingOption;

		editedPartObject.part.PartItemID = editPartObject.editSelectedPartItemID
			? editPartObject.editSelectedPartItemID
			: editPart.PartItemID;

		editedPartsArray.push(editedPartObject);

		let dataToupdate = {
			removeParts: {
				updatedCharges: [editedPart],
				RepairTypeCode:
					consumerServiceRequest.gsxRepairFlags.repairTypeCode,
				ConsumerServiceRequestID:
					consumerServiceRequest.ConsumerServiceRequestID,
				ServiceTypeID: consumerServiceRequest.ServiceTypeID,
				PartnerServiceLocationID:
					consumerServiceRequest.PartnerServiceLocationID,
			},
			updateParts: {
				parts: editedPartsArray,
				ConsumerServiceRequestID:
					consumerServiceRequest.ConsumerServiceRequestID,
				ConsumerProductID: consumerServiceRequest.ConsumerProductID,
				ServiceTypeID: consumerServiceRequest.ServiceTypeID,
				PartnerServiceLocationID:
					consumerServiceRequest.PartnerServiceLocationID,
			},
		};

		setLoader(true);
		apiCall({
			url: "updatePartsForGsxConsignment",
			data: dataToupdate,
			callBack: (result) => {
				setLoader(false);
				if (result.success == true) {
					closePartModal();
					getRequestById();
				} else {
					Swal.fire("", localString(displayError(result)), "error");
				}
			},
		});
	};

	const initEditPartIssueCode =  (editSelectedComponent) => {
        if (editSelectedComponent) {
            let data = {
            reportedSymptomCode: editSelectedComponent.reportedSymptomCode,
            ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID
            }

			setLoader(true)
            apiCall({
                url: 'fetchReportedIssues',
                data: data,
                callBack: (result) => {
					setLoader(false)
                    if (result.success) {
                        let editallIssues = result.data;
                        if(editallIssues && editallIssues.length > 0) {
							let data = editallIssues.map(v => ({...v, value:v.reportedIssueCode + ' ' + v.reportedIssueDesc}))
							setEditallIssuesList(data)

                    	}
                    } else {
                        Swal.fire(
                            '',
                            localString(displayError(result)),
                            'error'
                        );
                    }
                }
            });
        }
    }

	const getAvailableQuantity = (part) => {
		let data = {
			PartnerServiceLocationID:
				consumerServiceRequest.PartnerServiceLocationID,
			part: part,
		};

		if (consumerServiceRequest.consumerProduct) {
			data.BrandID = consumerServiceRequest.consumerProduct.BrandID;
		}

		setLoader(true);
		apiCall({
			url: "getAvailableQuantity",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					setAvailableQuantity(result.data.AvailableQuantity);
				} else {
					Swal.fire({
						title: "",
						text: localString(displayError(result)),
						type: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	// useEffect(() => {
	// 	getFinishedGoods();
	// }, [])

	useEffect(() => {
		if(!isEditPartModal){
			getCompModList();
			getpricingOptionList();
			getSymptomCodes();
			getMailinCoverageList()
		}
	}, [isEditPartModal])

    useEffect(()=>{
        partObject && partObject.selectedComponenCode && partObject.selectedComponenCode.reportedSymptomCode && getReportedIssues();
    },[partObject.selectedComponenCodeName])

	useEffect(() => {
		changePrice();
	}, [
		// partObject?.selectedPartName, partObject?.selectedSubstitutePartName, partObject?.selectedSecondaryPartName, 
		selectedIsPartAbused, coveredByACPlus, returnableDamage, partObject?.selectedPartWarrantyName, partItem
	])

    // useEffect(() => {
    //     if(finishedGoods && finishedGoods.length){

    //     }
	// }, [finishedGoods])

	const changePrice = () => {
		let priceVal = '';
		if(partObject?.selectedPartWarrantyName == 'In warranty') {
            if(selectedIsPartAbused == "Yes") {
                priceVal = partItem.partPrice.MRP;
            }else{
                if(returnableDamage == "Yes") {
                    if (partItem && partItem.partPrice) {
                        if (partItem.partPrice.ExchangePriceMRP >= 0) {
                            priceVal = partItem.partPrice.ExchangePriceMRP;
                        } else if (partItem.partPrice.Price >= 0) {
                            priceVal = partItem.partPrice.Price;
                        } else {
                            // pass
                        }
                    }
                }else{
                    priceVal = '0';
                }
            }
        }else{
            if(selectedIsPartAbused == "Yes"){
				if(partItem?.partPrice?.MRP) {
					priceVal = partItem.partPrice.MRP;
				}
            }else {
                if(partItem && partItem.partPrice) {
                    if(partItem.partPrice.ExchangePriceMRP >= 0) {
                        priceVal = partItem.partPrice.ExchangePriceMRP;
                    }else if(partItem.partPrice.Price >= 0) {
                        priceVal = partItem.partPrice.Price;
                    }else {
                        // pass
                    }
                }
            }
        }
		setPrice(priceVal);
	}

    const getFinishedGoods = () =>{
        let data = {
            "ProductID": consumerServiceRequest.consumerProduct.ProductID,
            "PartnerServiceLocationID": consumerServiceRequest.PartnerServiceLocationID
        }
		setLoader(true);
		apiCall({
			url:'getFinishedGood',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    setFinishedGoods(result.data);
                    // if (!self.accessFn('GSX_FLOW')) {
                    //     var finishedGoodID = self.csrid.consumerProduct.FinishedGoodID;
                    //     var fullBoxUnit;
                    //     if (self.parent.allParts) {
                    //         fullBoxUnit = _.find(self.parent.allParts.productFinishedGoodMappings, function (o) {
                    //         return o.PartID == finishedGoodID
                    //         })
                    //     }
                    //     var isFullBoxAlreadyAddedInPartArray = _.find(self.partsArray, function (o) {
                    //         return o.PartID == finishedGoodID
                    //     })
                    //     var isFullBoxAlreadyAddedInPartList = _.find(partslist, function (o) {
                    //         return o.PartID == finishedGoodID
                    //     })
                    //     if (!isFullBoxAlreadyAddedInPartList && !isFullBoxAlreadyAddedInPartArray && fullBoxUnit && fullBoxUnit.part) {
                    //         fullBoxUnit.part.Type = 'Part'
                    //         fullBoxUnit.part.Charge = fullBoxUnit.Charge
                    //         fullBoxUnit.part.TaxSlab = fullBoxUnit.TaxSlab
                    //         fullBoxUnit.part.TaxPercentage = fullBoxUnit.TaxPercentage
                    //         fullBoxUnit.part.IsTaxable = fullBoxUnit.IsTaxable
                    //         if ((fullBoxUnit.part && fullBoxUnit.part.Serialized && self.csrid && [1,2].indexOf(self.csrid.ServiceTypeID) > -1)) {
                    //         // pass when service type is pickup or carryin and full box is serialised
                    //         } else {
                    //         partslist.unshift(fullBoxUnit.part)
                    //         }
                    //     }

                    // }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const getSymptomCodes = () => {
        let data = {
			"ConsumerProductID": consumerServiceRequest.consumerProduct.ConsumerProductID
        }
        setLoader(true);
        apiCall({
			url:'fetchReportedSymptoms',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    let data = result.data.map(v => ({...v, value:v.reportedSymptomCode + ' ' + v.reportedSymptomDesc}))
                    setComponentCodeList(data);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const getCompModList = () => {
        let data = {}
        setLoader(true);
        apiCall({
			url:'fetchCompTiaModifiers',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    let data = result.data.map(v => ({...v, value:v.comptiaDescription}))
                    setComptiaModifierList(data);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const getReportedIssues = () => {
        let data = {
			"reportedSymptomCode": partObject.selectedComponenCode.reportedSymptomCode,
			"ConsumerProductID":consumerServiceRequest.consumerProduct.ConsumerProductID
        }
        setLoader(true);
        apiCall({
			url:'fetchReportedIssues',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    let data = result.data.map(v => ({...v, value:v.reportedIssueCode + ' ' + v.reportedIssueDesc}))
                    setIssueCodeList(data);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const getpricingOptionList = () => {
        let data = {}
        setLoader(true);
        apiCall({
			url:'getPricingOptions',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    let data = result.data.map(v => ({...v, value:v.pricingOptionDescription}))
                    setPricingOptions(data);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const getMailinCoverageList = () => {
        let data = {}
        setLoader(true);
        apiCall({
			url:'getMailinCoverageOptions',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    let data = result.data && result.data.gsxCoverageOptions && result.data.gsxCoverageOptions.map(v => ({...v, value: v.CoverageOption + ' - ' + v.CoverageDescription}))
                    setCoverageOption(data);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const addToPartsValidation = () => {
		let parts = cloneDeep(partsList);
		let ispresent = false;
        for (let i = parts.length - 1; i >= 0; i--) {
            if ((parts[i].PartCode && (parts[i].Name.toLowerCase() + ' ' + parts[i].PartCode.toLowerCase()).indexOf(partObject?.selectedPartName.toLowerCase()) > -1) || partObject?.selectedPartName.toLowerCase() === '') {
                ispresent = true;
                break;
            }
        }

		if(!partObject?.selectedPartName || selectedPart == {} || !ispresent) {
			Swal.fire({
				icon: 'error',
				text: localString("Please add a part from dropdown."),
                confirmButtonText: localString('OK')
			});
          	return;
        }

		if(!partObject?.selectedComponenCodeName || partObject?.selectedComponenCode == {}) {
			Swal.fire({
				icon: 'error',
				text: localString("Please select a Component Code from dropdown."),
                confirmButtonText: localString('OK')
			});
          	return;
        }

		if(!partObject?.selectedIssueCodeName || partObject?.selectedIssueCode == {}) {
			Swal.fire({
				icon: 'error',
				text: localString("Please select a Issue Code from dropdown."),
                confirmButtonText: localString('OK')
			});
          	return;
        }

		if(!partObject?.selectedReproducibilityName || partObject?.selectedReproducibility == {}) {
			Swal.fire({
				icon: 'error',
				text: localString("Please select a Reproducibility from dropdown."),
                confirmButtonText: localString('OK')
			});
          	return;
        }

		if(isSerialized && partObject?.partSerial && partObject.partSerial.length < 10) {
			Swal.fire({
				icon: 'error',
				text: localString("Please enter correct KBB serial number of the part"),
                confirmButtonText: localString('OK')
			});
          	return;
        }

		if((!partObject?.partCoverageOptionDescription || partObject?.selectedCoverageOption == {}) && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest?.gsxRepairFlags?.repairTypeId==1) {
			Swal.fire({
				icon: 'error',
				text: localString("Please select a Covergage Option Code from dropdown."),
                confirmButtonText: localString('OK')
			});
          	return;
        }

		for(let iCount = 0; iCount < partArray.length; iCount++) {
			if(partArray[iCount].part.PartCode == partItem.PartCode) {
				Swal.fire({
					icon: 'error',
					text: localString("Part is already added"),
					confirmButtonText: localString('OK')
				});
          		return;
			}
		}

		if(COQty && COQty > 0 && !enableselectConsignment && consumerServiceRequest.IsWarrantyApplicable) {
			Swal.fire({
				icon: 'error',
				text: localString("Please select value for ‘Use from Consigned Inventory’ field"),
				confirmButtonText: localString('OK')
			});
			return;
		}

        let isSerialExist = find(partArray, { "serialNumber" : partObject?.partSerial });
		if(!accessFn("GSX_FLOW") && isSerialExist){
			Swal.fire({
				icon: 'error',
				text: localString("Serial Number is already added"),
				confirmButtonText: localString('OK')
			});
			return;
        }

		if(isConsignment && isSerialized && (consumerServiceRequest?.gsxRepairFlags?.repairTypeId == 1) && (!partObject.consignmentSerialNumber || (partObject.consignmentSerialNumber == ''))){
            Swal.fire({
				icon: 'error',
				text: localString("Please enter the KGB serial number of the part"),
				confirmButtonText: localString('OK')
			});
            return;
        }

		if(isSerialized && partObject?.partSerial && partObject?.partSerial.length >= 10 && !wholeUnit){
			let reqObj = {
			  "serialNumber": partObject?.partSerial
			}
			apiCall({
				url: 'checkKBBSerialNumberExists',
				data: reqObj,
				callBack: (result) => {
					setLoader(false);
					if(result.success) {
						if(result.data) {
							addToPartsArray();
						}else {
							Swal.fire({
								icon: 'error',
								text: localString(displayError(result)),
								confirmButtonText: localString('OK')
							});
							setPartObject({ ...partObject, partSerial: '' })
						}
					}else {
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				},
				errorCB: (err) => {
					setLoader(false);
					Swal.fire({
						icon: 'error',
						text: localString('Something went wrong'),
					});
				},
			});
		}else {
			addToPartsArray()
		}
	}

	const addToPartsArray = () => {
		// let part = clone(selectedPart);
		// let partItemObj = clone(partItem)
		let part = clone(partItem);
		delete part.value;
		let comptiaModifier = partObject.selectedReproducibility ? clone(partObject.selectedReproducibility) : undefined;
		comptiaModifier && delete comptiaModifier.value;
		let issueCode = partObject.selectedIssueCode ? clone(partObject.selectedIssueCode) : undefined;
		issueCode && delete issueCode.value;
		let componentCode = partObject.selectedComponenCode ? clone(partObject.selectedComponenCode) : undefined;
		componentCode && delete componentCode.value;
		let partCoverageOption = partObject?.selectedCoverageOption ? clone(partObject.selectedCoverageOption) : undefined;
		partCoverageOption && delete partCoverageOption.value;
		let pricingOption = partObject.selectedPricingOption ? clone(partObject.selectedPricingOption) : undefined;
		pricingOption && delete pricingOption.value;

		let partWarranty = true;
		if(consumerServiceRequest && consumerServiceRequest.IsUnderWarranty && consumerServiceRequest.IsWarrantyApplicable) {
			partWarranty = true;
		}else {
			partWarranty = partObject?.selectedPartWarrantyName == "In warranty" ? true : false;
		}

		if(accessFn('GSX_FLOW')){
			part.displayPrice = price;
		}else{
			if(partWarranty){
				part.Charge = 0;
				part.Price = 0;
			}
		};
		let partArr = [...partArray];
		let collectedObject = {};
		collectedObject.part = part;
		collectedObject.comptiaModifier = comptiaModifier;
		collectedObject.issueCode = issueCode;
		collectedObject.componentCode = componentCode;
		collectedObject.pricingOption = pricingOption;
		collectedObject.partCoverageOption = {
			"partCoverageOptionCode": partCoverageOption && partCoverageOption.CoverageOption ? partCoverageOption.CoverageOption : undefined,
			"partCoverageOptionDescription": partCoverageOption && partCoverageOption.CoverageDescription ? partCoverageOption.CoverageDescription : undefined
		};
		collectedObject.returnableDamage = returnableDamage == "Yes" ? true : false;
		collectedObject.coveredByACPlus = coveredByACPlus  == "Yes" ? true : false;
		collectedObject.IsDeducted = false;
		collectedObject.abused = selectedIsPartAbused  == "Yes" ? true : false;
		collectedObject.ChargeType = "Credit";
		collectedObject.InWarranty = partWarranty;
		collectedObject.isSerialized = isSerialized; // need clarification

		if(!partObject.selectedPricingOption){
			let pricingOptionobj = {};
			pricingOptionobj.pricingOptionCode = "";
			pricingOptionobj.pricingOptionDescription = "None";
			collectedObject.pricingOption = pricingOptionobj
		}else{
			collectedObject.pricingOption = partObject.selectedPricingOption;
		}

		if(partObject?.partSerial?.length) collectedObject.serialNumber = partObject.partSerial;
		if(partObject?.consignmentSerialNumberVal?.length) collectedObject.consignmentSerialNumber = partObject.consignmentSerialNumberVal;
		if(partObject?.consignmentSerialNumber?.PartItemID) collectedObject.part.PartItemID = partObject.consignmentSerialNumber.PartItemID;
		if(isConsignment == 'Yes') collectedObject.isConsignment = true;

		partArr.push(collectedObject)
		setSelectedPart({});
		setSelectedIsPartAbused("No");
		setCoveredByACPlus("No");
		setReturnableDamage("No");
		setIsConsignment("");
		setPrice("");
		setPartObject(defaultPartObj);
		setPartItem({});
		setPartArray(partArr);
	}

	const getInventoryDetails = (part) => {
		setLoader(true);
		let reqObj = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"PartID": [part]
		};
		apiCall({
			url: 'getInventoryDetails',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success) {
					let COQtyVal;
					if(result.data.inventoryDetails && result.data.inventoryDetails.length > 0){
						COQtyVal = result.data.inventoryDetails[0].COGoodQuantity;
						setCOQty(result.data.inventoryDetails[0].COGoodQuantity);
					}
					if(COQtyVal > 0 && consumerServiceRequest.IsWarrantyApplicable){
						setIsConsignmentNeeded(false);
						// self.isConsignmentNeeded = false;
						// $('#consignmentText1').removeClass('disableSpan');
						// $('#consignmentText2').removeClass('disableSpan');
					}else{
						setIsConsignmentNeeded(true);
						setIsConsignmentSerialNeeded(true);
						//  $('input:radio[name="consignmentNeeded"]').removeAttr('checked');
						//  $('input:radio[name="consignmentNeeded"]').prop('checked', false);
					}
				}else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({
					icon: 'error',
					text: localString('Something went wrong'),
				});
			},
		});
	}

	const addWorkshopParts = () => {

	}

	const saveParts = (e, additionalData) => {
		e && e.preventDefault()
		setAvailableQuantity('');
		if(partArray && partArray.length < 0){
			setShowPartModal(false)
			setIsEditPartModal(false)
			return;
		}
		if (accessFn('GSX_FLOW')) {
			let data = {
				"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
				"parts": partArray
			}
			if(additionalData) {
				data = {...data, ...additionalData};
			}
			setLoader(true);
			apiCall({
				url:'updateCsrDiagnosis',
				data: data,
				callBack:(result)=>{
					setLoader(false);
					if(result.success){
						setPartArray([])
						setShowPartModal(false)
						setIsEditPartModal(false)
						getRequestById();
					}else{
						if(result.enableUserConsent){
							Swal.fire({
								title: '',
								text: localString(displayError(result)),
								showCancelButton: true,
								closeOnConfirm: true,
								allowEscapeKey: false,
								allowOutsideClick: false,
								cancelButtonText: localString("Cancel"),
								confirmButtonText: localString("Proceed without GSX update")
							}).then((result)=>{
								if(result.isConfirmed){
									saveParts(undefined, {isGSXBypass: true});
								}
							})
						}else{
							Swal.fire({
								icon: 'error',
								text: localString(displayError(result)),
							});
						}
					}
				}
			});
		} else {
			setShowPartModal(false)
			setIsEditPartModal(false)
			let data = {
				updatedCharges: partArray,
				ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
				ServiceTypeID: consumerServiceRequest.ServiceTypeID,
				PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID
			};
			updateChargesCall && updateChargesCall(data);
		}
	};

	const partChange = (val) => {
		let partObj = {...partObject};
		if(!val?.partPrice?.PriceUpdated){
			Swal.fire({
				title: "",
				text: localString("Part price is not updated for the Selected part")+"-" +val.PartCode+", "+localString("Please contact Admin"),
				icon: "error",
				confirmButtonText: localString("OK"),
			});
			return;
		}
		partObj.selectedPartName = val.value;
		partObj.selectedSubstitutePart = '';
		partObj.selectedSubstitutePartName = '';
		partObj.selectedSecondaryPart = '';
		partObj.selectedSecondaryPartName = '';
		partObj.partSerial = '';
		partObj.consignmentSerialNumber = '';
		partObj.consignmentSerialNumberVal = '';
		setSelectedPart(val);
		setPartItem(val);
		setOriginalPartCode(val.PartCode);
		setOriginalPartID(val.PartID);
		// changePrice();
		// Substitute Part List
		let subsArr = val?.alternateParts?.length ? val.alternateParts.map(item => ({...item, value: item.Name + ' ' + item.PartCode})) : [];
		setSubstitutePartList(subsArr);
		let secondaryArr = val?.secondaryParts?.length ? val.secondaryParts.map(item => ({...item, value: item.Name + ' ' + item.PartCode})) : [];
		setSecondaryPartList(secondaryArr);
		setIsSerialized(val.Serialized);
		setWholeUnit(val.wholeUnit);
		setCOQty(0);
		setIsConsignment('');
		accessFn('GSX_FLOW') && getInventoryDetails(val.PartID);
		if (val.wholeUnit) {
			let serial = consumerServiceRequest?.consumerProduct.AlternateUniqueKey ? consumerServiceRequest?.consumerProduct.AlternateUniqueKey : consumerServiceRequest?.consumerProduct.ProductUniqueID;
			partObj.partSerial = serial;
		} else {
			partObj.partSerial = '';
		}

		if (!accessFn('GSX_FLOW')) {
			getAvailableQuantity(val);
		} 
		setPartObject(partObj);

		// TODO: Other Part flow
		// if(self.PartItem.Name == "Other" || self.PartItem.Name == "Others"){
		// 	self.addNewPart = true;
		// 	self.disableAddBtn = true;
		// 	self.update();
		// 	$('#newPartTax').typeahead('destroy');
		// 	$('#newPartTax').typeahead({
		// 		source: self.taxablePercentage,
		// 		items: self.taxablePercentage.length,
		// 		autoSelect: true,
		// 		showHintOnFocus: true,
		// 		afterSelect: function(item) {
		// 		  self.newPart_Tax = parseInt(item.slice(0, -1));


		// 		}
		// 	});
		// 	self.typeOfWarranty = typeOfWarranty
		// 	$('#newPartWarranty').typeahead('destroy');
		// 	$('#newPartWarranty').typeahead({
		// 		source: typeOfWarranty,
		// 		items: 2,
		// 		displayTex: function (item) {
		// 			return localString(item)
		// 		},
		// 		autoSelect: true,
		// 		showHintOnFocus: true,
		// 		afterSelect: function(item) {
		// 		  self.newPart_Warranty = item;
		// 		}
		// 	});
		// 	if (typeOfWarranty && typeOfWarranty.length == 1) {
		// 		$("#newPartWarranty").val(typeOfWarranty)
		// 	}
		// 	self.update();
		//   } else {
		// 	self.addNewPart = false;
		// 	self.disableAddBtn = false;
		// 	self.update();
		// }
	}

	const substitutePartChange = (val) => {	
		let partObj = {...partObject};
		let partItemObj = {...partItem};
		partObj.selectedSubstitutePart = val;
		partObj.selectedSubstitutePartName = val.value;
		partItemObj.OriginalPartCode = partItem.PartCode;
		partItemObj.OrderedPartType = "SUBSTITUTE";
		partItemObj.OriginalPartID = partItem.PartID;
		partItemObj.substitutePart = cloneDeep(val);
		partItemObj.PartCode = val.PartCode;
		partItemObj.Description = val.Description;
		partItemObj.PartID = val.PartID;
		partItemObj.Repairable = val.Repairable;
		partItemObj.Serialized = val.Serialized;
		partItemObj.Returnable = val.Returnable;
		partItemObj.COSerialized = val.COSerialized;
		partItemObj.displayPrice = val.displayPrice;
		let parentSubPart = find(partsList, function (o) { return o.PartCode == val.PartCode });
		partItemObj.partPrice = cloneDeep(parentSubPart?.partPrice);
		getInventoryDetails(partItemObj.PartID);
		if(!partItemObj?.partPrice?.PriceUpdated){
			setPartObject(partObj);
			setPartItem(partItemObj);
			Swal.fire({
				title: "",
				text: localString("Part price is not updated for the Selected part")+"-" +val.PartCode+", "+localString("Please contact Admin"),
				icon: "error",
				confirmButtonText: localString("OK"),
			});
			return;
		}
		// self.changePrice();
		setCOQty(0);
		setWholeUnit(val.wholeUnit);
		setIsSerialized(val.Serialized);
		if (val.wholeUnit) {
			var serial = consumerServiceRequest?.consumerProduct.AlternateUniqueKey ? consumerServiceRequest?.consumerProduct.AlternateUniqueKey : consumerServiceRequest?.consumerProduct.ProductUniqueID;
			partObj.partSerial = serial;
		} else {
			partObj.partSerial = '';
		}
		// $("#comp_group").val('');
		// $("#comp_code").val('');

		// Todo: Other part handling
		// if (self.PartItem.Name == "Other" || self.PartItem.Name == "Others") {
		// 	self.addNewPart = true;
		// 	self.disableAddBtn = true;
		// 	self.update();
		// 	$('#newPartTax').typeahead('destroy');
		// 	$('#newPartTax').typeahead({
		// 		source: self.taxablePercentage,
		// 		items: self.taxablePercentage.length,
		// 		autoSelect: true,
		// 		showHintOnFocus: true,
		// 		afterSelect: function(item) {
		// 			self.newPart_Tax = parseInt(item.slice(0, -1));
		// 		}
		// 	});
		// 	self.update();
		// } else {
		// 	self.addNewPart = false;
		// 	self.disableAddBtn = false;
		// 	self.update();
		// }
		setPartObject(partObj);
		setPartItem(partItemObj);
	}

	const secondaryPartChange = (val) => {	
		let partObj = {...partObject};
		let partItemObj = {...partItem};
		partObj.selectedSecondaryPart = val;
		partObj.selectedSecondaryPartName = val.value;
		partItemObj.OriginalPartCode = originalPartCode;
		partItemObj.OrderedPartType = "SECONDARY";
		partItemObj.OriginalPartID = originalPartID;
		partItemObj.secondaryPart = cloneDeep(val);
		partItemObj.PartCode = val.PartCode;
		partItemObj.Description = val.Description;
		partItemObj.PartID = val.PartID;
		partItemObj.Repairable = val.Repairable;
		partItemObj.Serialized = val.Serialized;
		partItemObj.Returnable = val.Returnable;
		partItemObj.COSerialized = val.COSerialized;
		partItemObj.displayPrice = val.displayPrice;
		let parentSubPart = find(partsList, function (o) { return o.PartCode == val.PartCode });
		partItemObj.partPrice = cloneDeep(parentSubPart?.partPrice);
		getInventoryDetails(partItemObj.PartID);
		if(!partItemObj?.partPrice?.PriceUpdated){
			setPartObject(partObj);
			setPartItem(partItemObj);
			Swal.fire({
				title: "",
				text: localString("Part price is not updated for the Selected part")+"-" +val.PartCode+", "+localString("Please contact Admin"),
				icon: "error",
				confirmButtonText: localString("OK"),
			});
			return;
		}
		// self.changePrice();
		setCOQty(0);
		setWholeUnit(val.wholeUnit);
		setIsSerialized(val.Serialized);
		if (val.wholeUnit) {
			var serial = consumerServiceRequest?.consumerProduct.AlternateUniqueKey ? consumerServiceRequest?.consumerProduct.AlternateUniqueKey : consumerServiceRequest?.consumerProduct.ProductUniqueID;
			partObj.partSerial = serial;
		} else {
			partObj.partSerial = '';
		}
		// $("#comp_group").val('');
		// $("#comp_code").val('');

		// Todo: Other part handling
		// if (self.PartItem.Name == "Other" || self.PartItem.Name == "Others") {
		// 	self.addNewPart = true;
		// 	self.disableAddBtn = true;
		// 	self.update();
		// 	$('#newPartTax').typeahead('destroy');
		// 	$('#newPartTax').typeahead({
		// 		source: self.taxablePercentage,
		// 		items: self.taxablePercentage.length,
		// 		autoSelect: true,
		// 		showHintOnFocus: true,
		// 		afterSelect: function(item) {
		// 			self.newPart_Tax = parseInt(item.slice(0, -1));
		// 		}
		// 	});
		// 	self.update();
		// } else {
		// 	self.addNewPart = false;
		// 	self.disableAddBtn = false;
		// 	self.update();
		// }
		setPartObject(partObj);
		setPartItem(partItemObj);
	}

	const removeAddedPart = (index, part) => {
		let arr = cloneDeep(partArray);
        arr.splice(index, 1);
		setPartArray(arr);
	}

    const addPartInputObject = [
		{
			labelText: localString('Part Name'),
			dataKey: "selectedPartName",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter: true,
			type: 'Dropdown',
			dropdownList: partsList || [],
            placeholder:"Select a Part Name",
            inputField:true,
			oninputFunc: (val) => partChange(val)
		},
        {
			labelText: localString('Part Code'),
			dataKey: "selectedPartCode",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: !accessFn('GSX_FLOW'),
			inputField: false,
            value: "",// selectedPart && selectedPart.PartCode ? selectedPart.PartCode: '-'
		},
        {
			labelText: localString('HSN Code'),
			dataKey: "selectedPartChargeCode",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter: !accessFn('GSX_FLOW'),
			inputField:false,
            value:"",// selectedPart && selectedPart.ChargeCode ? selectedPart.ChargeCode:'-'
		},
        {
			labelText: localString('Substitute Part'),
			dataKey: "selectedSubstitutePartName",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter: accessFn('GSX_FLOW'),	
			type: 'Dropdown',
			// dropdownList: selectedPart?.alternateParts?.length ? selectedPart.alternateParts : [],
			dropdownList: substitutePartList,
            placeholder:"Select a Substitute Part",
            // isDisabled: !selectedPart?.alternateParts?.length,
            isDisabled: (!partObject?.selectedPartName || !substitutePartList?.length || partObject?.selectedSecondaryPartName),
            inputField:true,
			oninputFunc: (val) => substitutePartChange(val)
		},
		{
			labelText: localString('Secondary Part'),
			dataKey: "selectedSecondaryPartName",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter: accessFn('GSX_FLOW'),	
			type: 'Dropdown',
			// dropdownList: selectedPart?.alternateParts?.length ? selectedPart.alternateParts : [],
			dropdownList: secondaryPartList,
            placeholder:"Select a Secondary Part",
            // isDisabled: !selectedPart?.alternateParts?.length,
            isDisabled: (!partObject?.selectedPartName || !secondaryPartList?.length || partObject?.selectedSubstitutePartName),
            inputField:true,
			oninputFunc: (val) => secondaryPartChange(val)
		},
        {
			labelText: localString('Component Code'),
			dataKey: "selectedComponenCodeName",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter: accessFn('GSX_FLOW'),
			type: 'Dropdown',
			dropdownList: componentCodeList,
            placeholder:"Select Component Code",
            inputField:true,
			oninputFunc: (val) => {
				setPartObject({...partObject,
					['selectedComponenCode']: val,
					['selectedComponenCodeName']: val.value
				});
			}
		},
        {
			labelText: localString('Issue Code'),
			dataKey: "selectedIssueCodeName",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter: accessFn('GSX_FLOW'),
			type: 'Dropdown',
			dropdownList: issueCodeList,
            placeholder:"Select Issue Code",
            inputField:true,
			oninputFunc: (val) => {
				setPartObject({...partObject,
					['selectedIssueCode']: val,
					['selectedIssueCodeName']: val.value
				});
			}
		},
        {
			labelText: localString('Reproducibility'),
			dataKey: "selectedReproducibilityName",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter: accessFn('GSX_FLOW'),
			type: 'Dropdown',
			dropdownList: comptiaModifierList,
            placeholder:"Reproductivity",
            inputField:true,
			oninputFunc: (val) => {
				setPartObject({...partObject,
					// ['selectedReproducibilityCode']:val,
					['selectedReproducibility']: val,
					['selectedReproducibilityName']: val.comptiaDescription
				});
			}
		},
        {
			labelText: localString('Part Warranty'),
			dataKey: "selectedPartWarrantyName",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter:((consumerServiceRequest.consumerProduct && !consumerServiceRequest.consumerProduct.IsUnderWarranty) || (consumerServiceRequest && !consumerServiceRequest.IsWarrantyApplicable)) && consumerServiceRequest.ServiceTypeID != 39,
			type: 'Dropdown',
			dropdownList: [10,11,12,23].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ?[{value:"Out of warranty"}]:[{value:"Out of warranty"}, {value:"In warranty"}],
            placeholder:"Select Part Warranty",
            inputField:true,
			oninputFunc: (val) => {
				setPartObject({...partObject,
					['selectedPartWarranty']:val,
					['selectedPartWarrantyName']:val.value,
				});
			}
		},
        {
			labelText: localString('Is Part Abused'),
			dataKey: "selectedIsPartAbused",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter:accessFn('GSX_FLOW'),
			type: 'Radio',
			radioButtons:[{
				buttonText: "Yes",
				value:"Yes",
				isSelect:selectedIsPartAbused,
				onclickupdate:(val)=>{
					setSelectedIsPartAbused(val)
				}
			},
			{
				buttonText: "No",
				value:"No",
				isSelect: selectedIsPartAbused,
				onclickupdate:(val)=>{
					setSelectedIsPartAbused(val)
				}
			}],
            inputField:true,
		},
        {
			labelText: localString('Is Returnable Damage'),
			dataKey: "returnableDamage",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter:accessFn('GSX_FLOW'),
			type: 'Radio',
			radioButtons:[{
				buttonText: "Yes",
				value:"Yes",
				isSelect: returnableDamage,
				onclickupdate:(val)=>{
					setReturnableDamage(val)
				}
			},
			{
				buttonText: "No",
				value:"No",
				isSelect: returnableDamage,
				onclickupdate:(val)=>{
					setReturnableDamage(val)
				}
			}],
            inputField:true,
		},
        {
			labelText: localString('Is Covered By ACPlus'),
			dataKey: "coveredByACPlus",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter:accessFn('GSX_FLOW'),
			type: 'Radio',
			radioButtons:[{
				buttonText: "Yes",
				value:"Yes",
				isSelect: coveredByACPlus,
				onclickupdate:(val)=>{
					setCoveredByACPlus(val)
				}
			},
			{
				buttonText: "No",
				value:"No",
				isSelect: coveredByACPlus,
				onclickupdate:(val)=>{
					setCoveredByACPlus(val)
				}
			}],
            inputField:true,
		},
        {
			labelText: localString('Use from Consigned Inventory'),
			dataKey: "isConsignment",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter:accessFn('GSX_FLOW'),
			isDisabled: isConsignmentNeeded, //isConsignmentNeeded,
			type: 'Radio',
			radioButtons:[{
				buttonText: "Yes",
				value:"Yes",
				isSelect: isConsignment,
				onclickupdate: (val) => {
					setIsConsignment(val)
					setOrderFromGSX(false);
					if(isSerialized && consumerServiceRequest?.gsxRepairFlags?.repairTypeId == 1){
						setIsConsignmentSerialNeeded(false);
						getPartItemsBySerialNumber();
					}
					setEnableselectConsignment(true);
				}
			},
			{
				buttonText: "No",
				value:"No",
				isSelect: isConsignment,
				onclickupdate: (val) => {
					setIsConsignment(val);
					setOrderFromGSX(true);
					setIsConsignmentSerialNeeded(true);
					setPartObject({ ...partObject,
						['consignmentSerialNumber']: {},
						['consignmentSerialNumberVal']: ''
					})
					setEnableselectConsignment(true);
				}
			}],
            inputField: true,
			spanText: orderFromGSX ? `(${localString('Order from GSX')})` : ''
		},
        // {
		// 	labelText: localString('KGB Serial Number (Consignment Part Serial No)'),
		// 	dataKey: "consignmentSerialNumber",
		// 	customClass:'col-sm-12 col-md-6 paddingB10',
		// 	showFilter:accessFn('GSX_FLOW'),
		// 	isDisabled: isConsignmentSerialNeeded,//isConsignmentSerialNeeded
		// 	type: 'Input',
        //     maxLength:20,
        //     minLength:10,
		// 	oninputFunc:(val)=>{
		// 		setPartObject({...partObject,
		// 			['consignmentSerialNumber']:val
		// 		})
		// 	},
        //     inputField:true,
        //     placeholder: localString("KGB Serial Number")
		// },
		{
			labelText: localString("KGB Serial Number (Consignment Part Serial No)"),
			dataKey: "consignmentSerialNumberVal",
			customClass: "col-sm-12 col-md-6 paddingB10",
			showFilter: accessFn("GSX_FLOW"),
			isDisabled: isConsignmentSerialNeeded, // disableEditconsignmentSerial
			dropdownList: consigmentSerialNumberList,
			type: "Dropdown",
			oninputFunc: (val) => {
				setPartObject({...partObject,
					['consignmentSerialNumber']:val,
					['consignmentSerialNumberVal']:val.value
				})
			},
			inputField: true,
			placeholder: localString("KGB Serial Number"),
		},
        {
			labelText: localString('KBB Serial Number'),
			dataKey: "partSerial",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: accessFn('GSX_FLOW'),
			type: 'Input',
            maxLength: 20,
            minLength: 10,
			oninputFunc:(val)=>{
				setPartObject({...partObject,
					['partSerial']:val
				})
			},
            inputField:true,
            // isDisabled:true,//isSerialized && !wholeUnit? '' : 'disabled'
            isDisabled: (isSerialized && !wholeUnit) ? false : true,
            placeholder: localString("Serial Number")
		},
        {
			labelText: localString('Pricing Option'),
			dataKey: "pricingOptionDescription",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter:accessFn('GSX_FLOW'),
			dropdownList: pricingOptions,
			type: 'Dropdown',
			oninputFunc: (val) => {
				setPartObject({...partObject,
					['pricingOptionDescription']:val.pricingOptionDescription,
					['selectedPricingOption']: val
				});
			},
            inputField:true,
		},
        {
			labelText: localString('Coverage Option'),
			dataKey: "partCoverageOptionDescription",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter: consumerServiceRequest && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId==1 && accessFn('GSX_FLOW'),
			type: 'Dropdown',
			dropdownList: coverageOption,
			oninputFunc: (val) => {
				setPartObject({...partObject,
					['partCoverageOptionDescription']: val.value,
					['selectedCoverageOption']: val
				});
			},
            inputField:true,
		},
        // { //need to verify
		// 	labelText: localString('Part Warranty'),
		// 	dataKey: "",
		// 	customClass:'col-sm-12 col-md-6 paddingB10',
		// 	showFilter: consumerServiceRequest && (!consumerServiceRequest.IsUnderWarranty || !consumerServiceRequest.IsWarrantyApplicable),
		// 	type: 'input',
        //     value:"",//InWarranty ? localString("In warranty") : localString("Out of warranty"),
        //     inputField:false,
		// },
        {
			labelText: localString('Part Price'),
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter: true,
            value: price ? price : "-",
            inputField:false,
		},
        { //need to verify
			labelText: localString('Remarks'),
			dataKey: "",
			customClass:'col-sm-12 col-md-6 paddingB10',
			showFilter: !accessFn('GSX_FLOW'),
			type: 'Input',
            value:"",// Remarks ? Remarks : "-",
            inputField:true,
		},
        {
			labelText: localString('Qty'),
			showFilter: true,
            value: 1,
			inputField:false,
		},
        { //need to verify
			labelText: localString('Available Qty'),
			showFilter: !accessFn('GSX_FLOW'),
            value:"",// AvailableQuantity && AvailableQuantity,
		},
        {
			labelText: localString('Action'),
            customClass:'btn squareBtnGhost',
			showButton:true,
			showFilter:true,
            buttonText: localString("Add"),
            handleOnClick : () => {
				if(accessFn('GSX_FLOW')){
					// addToPartsValidation();
					addToPartsValidation();
				}else if(!accessFn('GSX_FLOW')){
					addWorkshopParts();
				}
            },
		},
	]
	
	const editPartInputObject = [
		{
			labelText: localString("Part Name"),
			// showFilter: !accessFn("GSX_FLOW"),
			showFilter: true,
			value: editPart.Name,
		},
		{
			labelText: localString("Component Code"),
			dataKey: "editSelectedComponentCodeName",
			customClass: "col-sm-12 col-md-6 paddingB10",
			showFilter: accessFn("GSX_FLOW"),
			type: "Dropdown",
			dropdownList: componentCodeList,
			placeholder: localString("Component Code"),
			inputField: true,
			oninputFunc: (val) => {
				setEditPartObject({
					...editPartObject,
					editSelectedComponent: val,
					editSelectedComponentCodeName: val.value,
				});
				initEditPartIssueCode(val);
			},
		},
		{
			labelText: localString("Issue Code"),
			dataKey: "editSelectedIssueCodeName",
			customClass: "col-sm-12 col-md-6 paddingB10",
			showFilter: accessFn("GSX_FLOW"),
			type: "Dropdown",
			dropdownList: editallIssuesList,
			placeholder: localString("Issue Code"),
			inputField: true,
			oninputFunc: (val) => {
				setEditPartObject({
					...editPartObject,
					editSelectedIssue: val,
					editSelectedIssueCodeName: val.value,
				});
			},
		},
		{
			labelText: localString("Reproducibility"),
			dataKey: "editSelectedReproducibilityName",
			customClass: "col-sm-12 col-md-6 paddingB10",
			showFilter: accessFn("GSX_FLOW"),
			type: "Dropdown",
			dropdownList: comptiaModifierList,
			placeholder: localString("Reproducibility"),
			inputField: true,
			oninputFunc: (val) => {
				setEditPartObject({
					...editPartObject,
					editSelectedReproducibility: val,
					editSelectedReproducibilityName: val.comptiaDescription,
				});
			},
		},
		{
			labelText: localString("Part Warranty"),
			dataKey: "editSelectedPartWarranty",
			customClass: "col-sm-12 col-md-6 paddingB10",
			showFilter:
				((consumerServiceRequest.consumerProduct &&
					!consumerServiceRequest.consumerProduct.IsUnderWarranty) ||
					(consumerServiceRequest &&
						!consumerServiceRequest.IsWarrantyApplicable)) &&
				consumerServiceRequest.ServiceTypeID != 39,
			type: "Dropdown",
			dropdownList: typeOfWarranty.map((data) => ({
				value: localString(data),
			})),
			placeholder: localString("Warranty"),
			inputField: true,
			oninputFunc: (val) => {
				setEditPartObject({
					...editPartObject,
					editSelectedPartWarranty: val.value,
				});
			},
		},
		{
			labelText: localString("Is Part Abused"),
			dataKey: "editSelectedIsPartAbused",
			customClass: "col-sm-12 col-md-6 paddingB10",
			showFilter: accessFn("GSX_FLOW"),
			type: "Radio",
			radioButtons: [
				{
					buttonText: "Yes",
					value: "Yes",
					isSelect: editSelectedIsPartAbused,
					onclickupdate: (val) => {
						setEditSelectedIsPartAbused(val);
					},
				},
				{
					buttonText: "No",
					value: "No",
					isSelect: editSelectedIsPartAbused,
					onclickupdate: (val) => {
						setEditSelectedIsPartAbused(val);
					},
				},
			],
			inputField: true,
		},
		{
			labelText: localString("Is Returnable Damage"),
			dataKey: "editSelectedIsReturnableDamage",
			customClass: "col-sm-12 col-md-6 paddingB10",
			showFilter: accessFn("GSX_FLOW"),
			type: "Radio",
			radioButtons: [
				{
					buttonText: "Yes",
					value: "Yes",
					isSelect: editSelectedIsReturnableDamage,
					onclickupdate: (val) => {
						setEditSelectedIsReturnableDamage(val);
					},
				},
				{
					buttonText: "No",
					value: "No",
					isSelect: editSelectedIsReturnableDamage,
					onclickupdate: (val) => {
						setEditSelectedIsReturnableDamage(val);
					},
				},
			],
			inputField: true,
		},
		{
			labelText: localString("Is Covered By ACPlus"),
			dataKey: "editSelectedIsCoveredByACPlus",
			customClass: "col-sm-12 col-md-6 paddingB10",
			showFilter: accessFn("GSX_FLOW"),
			type: "Radio",
			radioButtons: [
				{
					buttonText: "Yes",
					value: "Yes",
					isSelect: editSelectedIsCoveredByACPlus,
					onclickupdate: (val) => {
						setEditSelectedIsCoveredByACPlus(val);
					},
				},
				{
					buttonText: "No",
					value: "No",
					isSelect: editSelectedIsCoveredByACPlus,
					onclickupdate: (val) => {
						setEditSelectedIsCoveredByACPlus(val);
					},
				},
			],
			inputField: true,
		},
		{
			labelText: localString("Use from Consigned Inventory"),
			dataKey: "editSelectedConsignmentNeeded",
			customClass: "col-sm-12 col-md-6 paddingB10",
			showFilter: accessFn("GSX_FLOW"),
			isDisabled: isEditedConsignmentNeeded, //isEditedConsignmentNeeded,
			type: "Radio",
			radioButtons: [
				{
					buttonText: "Yes",
					value: "Yes",
					isSelect: editSelectedConsignmentNeeded,
					onclickupdate: (val) => {
						setEditSelectedConsignmentNeeded(val);

						if (
							(isSerialized || (editPart && editPart.Serialized)) &&
							consumerServiceRequest.gsxRepairFlags.repairTypeId == 1
						) {
							if (editPart) {
								setEditPartObject({
									...editPart,
									isConsignment: true,
								});
								setIsEditedConsignmentSerialNeeded(false);
								getEditPartItemsBySerialNumber();
							}
						}
						setIsConsignmentCheck(true);
						// self.orderFromGSX = false;
					},
				},
				{
					buttonText: "No",
					value: "No",
					isSelect: editSelectedConsignmentNeeded,
					onclickupdate: (val) => {
						setEditSelectedConsignmentNeeded(val);

						if (editPartObject.editSelectedConsignmentSerialNumber) {
							setEditPartObject({
								...editPartObject,
								editSelectedConsignmentSerialNumber: "",
							});
						}
						if (editPart) {
							setEditPartObject({
								...editPart,
								isConsignment: false,
							});
							setIsEditedConsignmentSerialNeeded(true);
						}
						setIsConsignmentCheck(false);
						//  self.orderFromGSX = true ;
					},
				},
			],
			inputField: true,
		},
		{
			labelText: localString("KGB Serial Number (Consignment Part Serial No)"),
			dataKey: "editSelectedConsignmentSerialNumber",
			customClass: "col-sm-12 col-md-6 paddingB10",
			showFilter: accessFn("GSX_FLOW"),
			isDisabled: isEditedConsignmentSerialNeeded, // disableEditconsignmentSerial
			dropdownList: consigmentSerialNumberList,
			type: "Dropdown",
			oninputFunc: (val) => {
				setEditPartObject({
					...editPartObject,
					editSelectedConsignment: val,
					editSelectedPartItemID: val.PartItemID,
					editSelectedConsignmentSerialNumber: val.SerialNumber,
				});
			},
			inputField: true,
			placeholder: localString("KGB Serial Number"),
		},
		{
			labelText: localString("KBB Serial Number"),
			dataKey: "editSelectedPartSerialNumber",
			customClass: "col-sm-12 col-md-6 paddingB10",
			showFilter: accessFn("GSX_FLOW"),
			isDisabled: editPart && editPart.Serialized == 1 ? false : true,
			type: "Input",
			maxLength: 20,
			minLength: 10,
			oninputFunc: (val) => {
				setEditPartObject({
					...editPartObject,
					editSelectedPartSerialNumber: val,
				});
			},
			inputField: true,
			placeholder: localString("Serial Number"),
		},
		{
			labelText: localString("Pricing Option"),
			dataKey: "editSelectedPricingOptionDescription",
			customClass: "col-sm-12 col-md-6 paddingB10",
			showFilter: accessFn("GSX_FLOW"),
			dropdownList: pricingOptions,
			type: "Dropdown",
			oninputFunc: (val) => {
				setEditPartObject({
					...editPartObject,
					editSelectedPricingOptionDescription:
						val.pricingOptionDescription,
					editSelectedPricingOption: val,
				});
			},
			inputField: true,
		},
		{
			labelText: localString("Coverage Option"),
			dataKey: "editSelectedCoverageOptionDescription",
			customClass: "col-sm-12 col-md-6 paddingB10",
			showFilter:
				consumerServiceRequest &&
				consumerServiceRequest.gsxRepairFlags &&
				consumerServiceRequest.gsxRepairFlags.repairTypeId == 1 &&
				accessFn("GSX_FLOW"),
			type: "Dropdown",
			dropdownList: coverageOption,
			oninputFunc: (val) => {
				setEditPartObject({
					...editPartObject,
					editSelectedCoverageOptionDescription: val.value,
					editSelectedCoverageOption: {
						partCoverageOptionCode: val.CoverageOption,
						partCoverageOptionDescription: val.CoverageDescription,
					},
				});
			},
			inputField: true,
		},
		{
			labelText: localString("Part Price"),
			customClass: "col-sm-12 col-md-6 paddingB10",
			showFilter: true,
			value:
				editPart && editPart.chargeDetails
					? editPart.chargeDetails.Charge
					: "-",
			inputField: false,
		},
		{
			labelText: localString("Qty"),
			showFilter: true,
			value: 1,
			inputField: false,
		},
		{
			labelText: localString("Available Qty"),
			showFilter: !accessFn("GSX_FLOW"),
			value: availableQuantity ? availableQuantity : "",
		},
	];

	return (
		<>
			<Modal showModal={showPartModal} closeModal={closePartModal} showCloseBtn={true} className="part-modal">
                <Modal.Header>
                    <h2> {isEditPartModal ? localString("Edit Parts") : localString("Add Required Parts")} </h2>
                </Modal.Header>
                <Modal.Body>
				{ isEditPartModal ? (
				<>
					<Table className="table table-bordered table-condensed table-striped">
						{editPartInputObject &&
							editPartInputObject.length > 0 &&
							editPartInputObject.map((item, index) => {
								return (
									item.showFilter && (
										<Table.Row>
											<Table.Heading className="width40Per">
												{localString(item.labelText)}
											</Table.Heading>
											{item.inputField && !item.showButton && (
												<Table.Data className="parts-dropdown scrollable-dropdown-menu">
													{item.type &&
														item.type == "Dropdown" && (
															<Dropdown
																id="dropdown"
																key={index}
																value={
																	editPartObject[item.dataKey]
																}
																options={item.dropdownList}
																handleOnChange={(data) => {
																	item.oninputFunc(data);
																}}
																filterBy="value"
																showDownArrow={false}
																isDisabled={item.isDisabled}
																placeholder={
																	item.placeholder
																}
															/>
														)}
													{item.type && item.type === "Input" && (
														<Input
															value={editPartObject[item.dataKey]}
															key={index}
															handleOnChange={(e) => {
																item.oninputFunc(
																	e.target.value
																);
															}}
															isDisabled={item.isDisabled}
															maxLength={item.maxLength}
															placeholder={item.placeholder}
														/>
													)}
													{item.type && item.type === "Radio" && (
														<div className="subclass-radio">
															{item.radioButtons &&
																item.radioButtons.map(
																	(radio, i) => {
																		return (
																			<div className="custom-radio">
																				<Radio
																					value={radio.value}
																					name={item.dataKey}
																					selectedValue={radio.isSelect}
																					handleOnUpdate={(e) => radio.onclickupdate(e.target.value)}
																					classes={radio.className}
																					isDisabled={item.isDisabled}
																				>
																					{radio.buttonText}
																				</Radio>
																			</div>
																		);
																	}
																)}
														</div>
													)}
												</Table.Data>
											)}
											{!item.inputField && !item.showButton && (
												<Table.Data>{item.value}</Table.Data>
											)}
											{item.showButton && (
												<Table.Data>
													<Button
														handleOnClick={item.handleOnClick}
														type="submit"
														classes="btn squareBtnGhost"
														text={localString(item.buttonText)}
													/>
												</Table.Data>
											)}
										</Table.Row>
									)
								);
							})}
					</Table>
					<div className="row form-group-filter-request">
						<div className="request-filter-button-div">
							<Button
								handleOnClick={updateEditedParts}
								type='submit'
								className='btn squareBtnGhost' 
								text={localString("Save")}
							/>
						</div>
					</div>
				</>
				) : (
					<>
						{
							partArray && partArray.map((item,i)=>{
								return (
									item &&
										<AddedParts 
											{...props}
											item={item}
											index={i}
											removeAddedPart={removeAddedPart}
										/>
									)
							})
						}
						<Table className="table table-bordered table-condensed table-striped">
						{
							addPartInputObject && addPartInputObject.length > 0 && addPartInputObject.map((item, index) => {
								return (
									item.showFilter && <Table.Row>
										<Table.Heading className="width40Per">{localString(item.labelText)}</Table.Heading>
										{item.inputField && !item.showButton && <Table.Data className="parts-dropdown scrollable-dropdown-menu">
											{
												(item.type && item.type=='Dropdown') && <Dropdown
													id='dropdown'
													key={index}
													value={partObject[item.dataKey]}
													options={item.dropdownList}
													handleOnChange={(data) => {
														item.oninputFunc(data);
													}}
													filterBy='value'
													showDownArrow={false}
													isDisabled={item.isDisabled}
													placeholder={item.placeholder}
												/>
											}
											{
												(item.type && item.type === 'Input') &&
												<Input
													value={partObject[item.dataKey]}
													key={index}
													handleOnChange={(e) => {
														item.oninputFunc(e.target.value);
													}}
													isDisabled={item.isDisabled}
													maxLength={item.maxLength}
													placeholder={item.placeholder}
												/>
											}
											{
												(item.type && item.type === 'Radio') &&
													<div className="subclass-radio">
														{item.radioButtons && item.radioButtons.map((radio,i)=>{
																return(
																	<div className="custom-radio">
																		<Radio
																			value={radio.value}
																			name={item.dataKey}
																			selectedValue={radio.isSelect}
																			handleOnUpdate={(e)=>{
																				radio.onclickupdate(e.target.value)
																			}}
																			classes={radio.className}
																			isDisabled={item.isDisabled}
																		>{radio.buttonText}</Radio>
																	</div>
																)

															})
														}
														{item.spanText ? <span style={{ marginTop: '13px' }}>{item.spanText}</span> : ''}
													</div>
											}
										</Table.Data>}
										{!item.inputField && !item.showButton && <Table.Data>{item.value}</Table.Data>}
										{
											item.showButton &&
											<Table.Data>
												<Button
													handleOnClick={item.handleOnClick}
													type='submit'
													className='btn squareBtnGhost'
													text={localString(item.buttonText)}
												/>
											</Table.Data>
										}
									</Table.Row>
								)
							})
						}
						</Table>
						{
							partArray && partArray.length > 0 &&
							<div className="row form-group-filter-request">
								<div className="request-filter-button-div">
									<Button
										handleOnClick={(e) => saveParts(e)}
										type='submit'
										className='btn squareBtnGhost'
										text={localString("Save")}
									/>
								</div>
							</div>
						}
					</>
				)}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
			</Modal>
            {(showLoader || !editPart) && ReactDOM.createPortal(
                <Loader />,
                document.getElementById("loader-root")
            )}
		</>
	)
}

export default AddPartModal;
