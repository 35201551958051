
import React, { useEffect,  useState } from 'react';
import Sidebar from '../../Partials/Sidebar/Sidebar';
import { useDispatch } from 'react-redux';
// import Loader from '../../Partials/Loader/Loader';
import Footer from '../../Partials/Footer/Footer';
import { BRAND } from '../../Utility/constants';
import { clearStates } from '../../reduxToolkit/features/planSales/planSalesDashboardSlice';
import languageTranslate from "../../Utility/translate";


import './Wrapper.scss';

const planSaleModuleRoute = [
	// '/plansales/home', 
];

const Wrapper = (props) => {
	const dispatch = useDispatch();
	const [showSideBar, setShowSidebar] = useState(false);
	const path = window.location.pathname;
	const viewPage = (path == "/logistics/view") || (path == "/servicerequests/view");
	const localString = languageTranslate(props.languagecode);
	const redirectToHomePage = () => {
		window.location.href = '/';
	}
	const accessConfigs = props.qFilter ? JSON.parse(props.qFilter) : {};
	const brandIconClass = accessConfigs && (((accessConfigs?.brands?.length == 1) && (accessConfigs?.brands?.indexOf(BRAND.ONEPLUS) > -1))) ? 'oneplus-icon' : (accessConfigs && accessConfigs.pageThemeId == 3) ? 'pupa-icon' : 'servify-icon';

	useEffect(() => {
		if(path == '/') { // Clear entire redux state
			dispatch(clearStates());
			sessionStorage.removeItem('PlanSalesAddressObj');
		}else if(path == '/plansales/home'){
			sessionStorage.removeItem('PlanSalesAddressObj')
		}else if(!planSaleModuleRoute.includes(path)) { // clear plan sales specific redux state
			// console.log('*********  CLEARED PLAN SALE DATA *********');
		}
	}, [path]);

	return (
		<>
			{props.nakedPage ? (
				<div className="wrapper-content clearfix">{props.children}</div>
			) : (
				<>
					{/* *********** Web screen sidebar *********** */}
					<div className='web-screen'>
						<Sidebar {...props} /> 
					</div>
					{/* *********** Mobile screen sidebar and navbar *********** */}
					<div className='mobile-screen'>
						<div className='mobile-screen-navbar' style={(accessConfigs?.pageThemeId == 3) ? { padding: '10px' } : {}}>
							<div class="hamburger-icon-wrapper" onClick={() => setShowSidebar(!showSideBar)}>
								<div class="hamburger-icon"></div>
							</div>
							<div className='logo-div'>
								<div className={brandIconClass} onClick={redirectToHomePage}></div>
							</div>
						</div>
						{ showSideBar && <Sidebar {...props} /> }
						{ showSideBar && <div className='overlay' onClick={() => setShowSidebar(false)}></div>}
					</div>
					<div className={`${viewPage ? "view-wrapper-offset" : "wrapper-offset"} ${window.location.pathname === '/servicerequests/raise' ? 'wrapper-custom-offset' : ''}`}>
						<div className={"wrapper-content clearfix " + (props.wrapperContentClass ? props.wrapperContentClass : '' )}>{props.children}</div>
					</div>
				</>
			)}
			{/* <Loader /> */  }
			<Footer localString={localString}/>
		</>
	);
}

export default Wrapper;
