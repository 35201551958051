import React, { useRef } from "react";
import {Input} from 'sfy-react';

export default function UploadFile(props) {
    const {
        localString,accessFn, pic, claimAddDocArray, docArray, claimRemoveAddedDoc, claimSelectedPicType
    } = props;
    const fileRef = useRef(null);
    let acceptFileType='.png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv';
    let acceptFileTypeGSX='.png,.jpg,.jpeg,.pdf,.heic,.xlsx,.xls,.csv';
    
    const imageClick = () => {
        fileRef.current.click();
    }

    return (
        <>
            <div className={`${(pic.picType).replace(/\s+/, '')}  image-field`}>
                <Input
                    reference={fileRef}
                    handleOnChange={(e)=>claimAddDocArray(e,pic)}
                    name={pic.picType}
                    id={(pic.picType).replace(/\s+/, '')}
                    inputType='file'
                    className='inputfile'
                    otherProps={{ accept:accessFn('GSX_FLOW') ? acceptFileTypeGSX : acceptFileType }}
                />
                <label for={`${(pic.picType).replace(/\s+/, '')} `}>
                    <span className={pic.loader ? 'upload-sign-none' :'glyphicon glyphicon-plus upload-sign'} onClick={imageClick}/>
                </label>
                {pic.loader &&<div className="pic-loader" >
                    <div className="cs-loader-inner">
                        <svg className=" spinner-container" width="50px" height="50px" viewBox="0 0 52 52">
                        <circle className="path" cx="26px" cy="26px" r="20px" fill="none" stroke-width="3px"></circle>
                        </svg>
                    </div>
                </div>}
            </div>
            <p className='image-text'>{localString(pic.picType)}</p>
            <div className='imagePosition'>
                {
                    docArray && docArray.map((doc,i)=>(
                        <div className='request-documents-li align' >
                            {
                                pic.picType === doc.Tag && 
                                <><span className='glyphicon glyphicon-minus-sign delete-charges-btn' onClick={()=>claimRemoveAddedDoc(doc,i)} ></span>
                                    <a href={doc.FileUrl} target='_blank' className='nounderline'>
                                        <img src={doc.FileUrl} alt={claimSelectedPicType} className='image'/>
                                    </a>
                                </>
                            }
                        </div>
                    ))
                }
            </div>
        </>
    );
}