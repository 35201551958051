import React from "react";
import { Modal } from "sfy-react";
import FilterInputPlan from "../FilterInputPlan/FilterInputPlan";
import "./FilterModalTransaction.scss";

const FilterModalTransaction = (props) => {
	const {
		localString,
		showFilterModal,
		filterData,
		closeModal,
		disableApplyButton,
		clearSearch,
		disableClearButton,
		setFilterData,
		filterFunc,
		countryCode,
		mobileMaxLength,
		getCountries,
	} = props;

	let filterInputs = [
		{
			labelText: localString("Customer Name"),
			dataKey: "FirstName",
			placeholder: localString("Customer Name"),
			showFilter: true,
			oninputFunc: (val) => {
				setFilterData({
					...filterData,
					["FirstName"]: val,
				});
			},
		},

		{
			labelText: localString("Customer Mobile Number"),
			dataKey: "MobileNo",
			maxlength: mobileMaxLength,
			placeholder: localString("Customer Mobile Number"),
			type: "intelInput",
			showFilter: true,
			defaultCountry: filterData.countryCode
				? filterData.countryCode.toLowerCase()
				: countryCode,
			changeFunc: (val, countryData) => {
				setFilterData({
					...filterData,
					["MobileNo"]: val,
					["countryCode"]: countryData.iso2,
				});
			},
			onFlagSelect: (countryData) => {
				setFilterData({
					...filterData,
					["countryCode"]: countryData.iso2,
				});
				getCountries(countryData.iso2);
			},
		},
		{
			labelText: localString("Customer Email ID"),
			dataKey: "EmailID",
			showFilter: true,
			placeholder: localString("Customer Email ID"),
			oninputFunc: (val) => {
				setFilterData({
					...filterData,
					["EmailID"]: val,
				});
			},
		},
		{
			labelText:
				localString("Serial Number") + " / " + localString("IMEI"),
			dataKey: "ProductUniqueID",
			showFilter: true,
			placeholder:
				localString("Serial Number") + " / " + localString("IMEI"),
			oninputFunc: (val) => {
				setFilterData({
					...filterData,
					["ProductUniqueID"]: val,
				});
			},
		},
		{
			labelText: localString("IMEI 2"),
			dataKey: "Imei2",
			placeholder: localString("IMEI 2"),
			showFilter: true,
			oninputFunc: (val) => {
				setFilterData({
					...filterData,
					["Imei2"]: val,
				});
			},
		},
		{
			labelText: localString("Contract ID"),
			dataKey: "SoldPlanCode",
			showFilter: true,
			placeholder: localString("Contract ID"),
			oninputFunc: (val) => {
				setFilterData({
					...filterData,
					["SoldPlanCode"]: val,
				});
			},
		},

		{
			labelText: localString("Order ID"),
			dataKey: "OrderID",
			showFilter: true,
			placeholder: localString("Order ID"),
			oninputFunc: (val) => {
				setFilterData({
					...filterData,
					["OrderID"]: val,
				});
			},
		},
		{
			labelText: localString("Policy ID"),
			dataKey: "ExternalRefenrenceID",
			placeholder: localString("Policy ID"),
			showFilter: true,
			oninputFunc: (val) => {
				setFilterData({
					...filterData,
					["ExternalRefenrenceID"]: val,
				});
			},
		},
	];

	return (
		<>
			<div className="FilterTransaction">
				<Modal
					showModal={showFilterModal}
					closeModal={closeModal}
					showCloseBtn={true}
					className="FilterTransaction-modal"
				>
					<Modal.Header>
						<h2> {localString("More Filters")} </h2>
					</Modal.Header>
					<Modal.Body>
						<FilterInputPlan
							{...props}
							filterData={filterData}
							closeModal={closeModal}
							disableApplyBtn={disableApplyButton}
							disableClearButton={disableClearButton}
							clearSearch={clearSearch}
							filterFunc={filterFunc}
							filterInputs={filterInputs}
						></FilterInputPlan>
					</Modal.Body>
					<Modal.Footer></Modal.Footer>
				</Modal>
			</div>
		</>
	);
};

export default FilterModalTransaction;
