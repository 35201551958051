import React, { useState } from "react";
import Overview from "./OverView/Overview";
import PaymentDetails from "./PaymentDetails/PaymentDetails";
import Documents from "./Documents/Documents";
import "./ViewContractNavBar.scss";


const ViewContractNavBar = (props) => {
	const {
		localString,
		showNavButton,
		paymentData,
		paymentDetails,
		documents,
		pagination,
		setPagination
	} = props;

	let [activeTab, setActiveTab] = useState({
		showOverview: true,
		showPaymentDetails: false,
		showDocuments: false,
	});

	const setFunction = (val) => {
		Object.keys(activeTab).forEach((item) => {
			setActiveTab((prevFilterData) => {
				return {
					...prevFilterData,
					[item]: false,
				};
			});
		});
		setActiveTab((prevFilterData) => {
			return {
				...prevFilterData,
				[val]: true,
			};
		});
	};

	const showClickedTab = (e) => {
		if (e) {
			switch (e.target.id) {
				case "overview_tab":
					setActiveTab({ ...activeTab, ["showOverview"]: true });
					setFunction("showOverview");

					break;
				case "paymentdetails_tab":
					setFunction("showPaymentDetails");

					break;
				case "doc_tab":
					setFunction("showDocuments");
					break;

				default:
					break;
			}
		} else {
		}
	};

	const navbarArray = [
		{
			buttonName: localString("Overview"),
			showButton: showNavButton,
			customClass: activeTab.showOverview ? "active tab-li" : "tab-li",
			listID: "overview_li",
			tabID: "overview_tab",
			onButtonClick: (e) => {
				showClickedTab(e);
			},
		},

		{
			buttonName: localString("Payment Details"),
			showButton: showNavButton,
			customClass: activeTab.showPaymentDetails
				? "active tab-li"
				: "tab-li",
			listID: "paymentdetails_li",
			tabID: "paymentdetails_tab",
			onButtonClick: (e) => {
				showClickedTab(e);
			},
		},

		{
			buttonName: localString("Documents"),
			showButton:showNavButton,
			customClass: activeTab.showDocuments ? "active tab-li" : "tab-li",
			listID: "docdetails_li",
			tabID: "doc_tab",
			onButtonClick: (e) => {
				showClickedTab(e);
			},
		},
	];

	return (
		<>
			<div className="request-view-navbar">
				<div
					className="navbar content remove-border-radius-navbar"
					id="myNavbar"
				>
					<div className="container-fluid remove-container-padding">
						<div className="navbar-header">
							<button
								type="button"
								className="navbar-toggle"
								data-toggle="collapse"
								data-target="#myNavbar"
							>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
						</div>
						<div>
							<div
								className="collapse navbar-collapse navbar-static-top remove-navbar-padding"
								id="myNavbar"
							>
								<ul className="nav navbar-nav ul-nav">
									{navbarArray &&
										navbarArray.map((navItem) => {
											return navItem.showButton ? (
												<li
													className={
														navItem.customClass
													}
													id={navItem.listID}
												>
													<a
														className="btn button-servify backtoreqbtn no-border-radius tab-buttons"
														id={navItem.tabID}
														onClick={(e) =>
															navItem.onButtonClick(
																e
															)
														}
													>
														{navItem.buttonName}
													</a>
												</li>
											) : (
												""
											);
										})}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="content">
				{activeTab.showOverview && (
					<Overview
						{...props}
						paymentData={paymentData}
						activeTab={activeTab}
						showNavButton={showNavButton}
					/>
				)}
				{activeTab.showPaymentDetails && (
					<PaymentDetails
						{...props}
						paymentData={paymentData}
						activeTab={activeTab}
						pagination={pagination}
						setPagination={setPagination}
						paymentDetails = {paymentDetails}
						showNavButton={showNavButton}
					/>
				)}
				{activeTab.showDocuments && (
					<Documents
						{...props}
						documents={documents}
						paymentData={paymentData}
						activeTab={activeTab}
						showNavButton={showNavButton}
					/>
				)}
			</div>
		</>
	);
};

export default ViewContractNavBar;
