import React, { useState } from 'react';
import _ from 'lodash';
import Swal from "sweetalert2"; 
import { Button, Dropdown, Modal, Input } from 'sfy-react';

import { displayError } from '../../../../../Utility/Utils';

export default function IssuePartModal(props) {
    const {
        apiCall, accessFn, localString, setLoader, showIssuePartModal, setShowIssuePartModal, partArr, setPartArr,
        consumerServiceRequest, issuePartsWithSerial, serializedPartOptions, setSerializedPartOptions,
        selectedSerialNumberArray, setSelectedSerialNumberArray, clearOptionsAndValueStates
    } = props;

    const getSerializedParts = (part, index) => {
        let options = _.find(serializedPartOptions, function (o) { return o?.[0].PartID == part.PartID});
		if(serializedPartOptions && serializedPartOptions.length && options && options.length) {
			let arr = [...serializedPartOptions];
			arr[index] = options;
			setSerializedPartOptions(arr);
		} else {
			setLoader(true);
			let reqObj = {
				part: part, 
				PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID
			};
			apiCall({
				url: 'getPartItemsBySerialNumber',
				data: reqObj,
				callBack: (result) => {
					setLoader(false);
					if (result.success && result.data) {
						let arr = [...serializedPartOptions];
						if(result.data && result.data.length) {
							arr[index] = result.data.map(a => ({...a, value: a.SerialNumber}));
						}
						setSerializedPartOptions(arr);
					} else {
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				},
				errorCB: (err) => setLoader(false),
			});
		}
	}

    const disableBtn = partArr.map(part => ((part.action == 'issued' && (!part.isConsignment || (part.isConsignment && consumerServiceRequest.gsxRepairFlags.repairTypeId == 2)) && (part.Serialized == 1 || part.RequiresSerialNumber)) ? part.issuedPartValue : 'test')).some(b => !b);

    return (
        <>
            <Modal
                className='issuePartModal'
                showModal={showIssuePartModal}
                closeModal={() => {
                    setShowIssuePartModal(false);
                    clearOptionsAndValueStates();
                }}
                showCloseBtn={true}
            >
                <Modal.Header>
                    <h4 className="modal-title">{localString("Enter Information for the parts you are issuing")}</h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body">
                        <div className="padding15">
                            {
                                partArr && partArr.length > 0 && partArr.map((part, k) => {
                                    if(part.action == 'issued' && (!part.isConsignment || (part.isConsignment && consumerServiceRequest.gsxRepairFlags.repairTypeId == 2)) && (part.Serialized == 1 || part.RequiresSerialNumber)) {
                                        return (
                                            <div className='row'>
                                                { 
                                                    part.Serialized == 1 && <>
                                                        <div className='col-xs-6'>
                                                            <label for={k + '_issuedPart'}>{part.Name}:</label>
                                                        </div>
                                                        <div className='col-xs-6'>
                                                            <div className="form-group">
                                                                <Dropdown
                                                                    value={part.issuedPartValue}
                                                                    options={serializedPartOptions[k] || []}
                                                                    handleOnChange={(data) => {
                                                                        let arr = _.cloneDeep(partArr);
                                                                        arr[k].issuedPartValue = data.value;
                                                                        setPartArr(arr);
                                                                        let arr1 = _.cloneDeep(selectedSerialNumberArray);
                                                                        arr1[k] = data;
                                                                        setSelectedSerialNumberArray(arr1);
                                                                    }}
                                                                    handleOnFocus={() => {
                                                                        getSerializedParts(part, k);
                                                                    }}
                                                                    filterBy='value'
                                                                    showDownArrow={false}
                                                                    placeholder={localString('Serial Number')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </> 
                                                }
                                                { 
                                                    part && part.RequiresSerialNumber && <>
                                                            <div className='col-xs-3'>
                                                                <label for={k + '_issuedPart'}>{part.Name}:</label>
                                                            </div>
                                                            <div className='col-xs-3'>
                                                                <div className="form-group">
                                                                    <Input
                                                                        value={part.productUniqueValue || ''}
                                                                        handleOnChange={(e) => {
                                                                            let arr = _.cloneDeep(partArr);
                                                                            arr[k].productUniqueValue = e.target.value;
                                                                            setPartArr(arr);
                                                                        }}
                                                                        maxLength={15}
                                                                        placeholder={'IMEI 1'}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-xs-3'>
                                                                <div className="form-group">
                                                                    <Input
                                                                        value={part.altUniqueValue || ''}
                                                                        handleOnChange={(e) => {
                                                                            let arr = _.cloneDeep(partArr);
                                                                            arr[k].altUniqueValue = e.target.value;
                                                                            setPartArr(arr);
                                                                        }}
                                                                        maxLength={15}
                                                                        placeholder={'IMEI 2'}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-xs-3'>
                                                                <div className="form-group">
                                                                    <Input
                                                                        value={part.serialNumberValue || ''}
                                                                        handleOnChange={(e) => {
                                                                            let arr = _.cloneDeep(partArr);
                                                                            arr[k].serialNumberValue = e.target.value;
                                                                            setPartArr(arr);
                                                                        }}
                                                                        placeholder={localString('Serial Number')}
                                                                    />
                                                                </div>
                                                            </div>
                                                    </>
                                                }
                                            </div>
                                        );
                                    }
                                })
                            }								
                        </div>
                        <div className='paddingB10'>
                            <Button 
                                className="btn button-servify pull-right" 
                                onClick={() => issuePartsWithSerial(partArr)}
                                disabled={disableBtn && accessFn('GSX_FLOW')}
                                // disabled={accessFn('GSX_FLOW')}
                            >
                                {localString("Save")}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}