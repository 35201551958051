import React, {useState, useEffect} from 'react';
import {Input,Dropdown,Button} from 'sfy-react';
import { displayError } from '../../../../../Utility/Utils';
import Swal from 'sweetalert2';
import { isEmpty } from 'lodash';
const CreateRefurbInput=(props)=>{
    const {apiCall,localString,consumerServiceRequest,setLoader,closeModal,getRequestById} = props;
	const [refurbImei,setRefurbImei] = useState(consumerServiceRequest.consumerProduct.ProductUniqueID ? consumerServiceRequest.consumerProduct.ProductUniqueID : '');
	const [ascForRefurb,setAscForRefurb] = useState('');
	const [ASCList,setASCList] = useState()
	const [selectAscForRefurb,setSelectAscForRefurb] = useState([]);
	const [disableRefurbOrder,setDisableRefurbOrder] = useState(true);
	const [ascLoading,setAscLoading] = useState(false)
	const [ascLoaded,setAscLoaded] = useState(false)
	const [selectedASC,setSelectedASC] = useState(null)
	
	useEffect(() => {
		if(refurbImei && !isEmpty(refurbImei)  && refurbImei.length>0 && ascForRefurb){
			setDisableRefurbOrder(false);
		}
		else{
			setDisableRefurbOrder(true); 
		}
	}, [refurbImei.length>0,ascForRefurb])
	const getASCList =  () => {
		if (!ASCList) {
			setAscLoaded(false);
			setAscLoading(true)
		}
		apiCall({
            url: "getASCList", 
            data: {
				"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			},
            callBack: (result) => {
				if (result.success) {
					let ASCListData = result.data.ASCList;
					setASCList(ASCListData)
					let ASCListArr = ASCListData && ASCListData.length>0 && ASCListData.map(v=>({...v,value:v.ServiceLocationName}))
					setSelectAscForRefurb(ASCListArr)
					setAscLoaded(true);
					setAscLoading(false);
				} else {
					Swal.fire({
						title: '',
						text: localString(displayError(result)),
						icon: 'error',
						confirmButtonText: localString('OK')
					});
				}
			},
		})
	}
	
	const createRefurbRequest = ()=>{
		let data = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"PartnerServiceLocationID": selectedASC.PartnerServiceLocationID,
			"imei": refurbImei
		};
		Swal.fire({
			title: localString("Are you sure?"),
			showCancelButton: true,
			closeOnConfirm: false,
			allowEscapeKey: false,
			allowOutsideClick: false,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("OK")
		}).then( (result) =>{
			if (result.isConfirmed) {
				Swal.close();
				setLoader(true)
				apiCall({
					url: 'createRefurbishment',
					data: data,
					callBack: (result) => {
						setLoader(false);
						if (result.success && result.data) {
								closeModal()
								getRequestById();
						}else {
							Swal.fire({
								icon: 'error',
								text: localString(displayError(result)),
							});
						}
					},errorCB: (err) => {
							setLoader(false);
							Swal.fire({  
								icon: 'error',  
								text: localString("Something went wrong"),  
							});
						}
				});
			}
		});
	}
	return (
		<>
			<div className='col-sm-12 imie-input'>
				<Input
					value={refurbImei}
					id="refurbImei"
					name="refurbImei"
					inputType="text"
					label={localString("Please confirm IMEI of the device")}
					handleOnChange={(e) => {
						setRefurbImei(e.target.value);
					}}
				/>
			</div>
			<div className='col-sm-12  register-filter scrollable-dropdown-menu ASC-dropdown'>
				<label for="ascForRefurb">{localString("Please select ASC")}</label>
				<Dropdown
					value={ascForRefurb}
					options={selectAscForRefurb}
					handleOnChange={(data) => {
						setSelectedASC(data)
						setAscForRefurb(data.value);
					}}
					handleOnFocus={() => {
						// if(!ascLoaded){
							getASCList()
						// }
					}}
					filterBy='value'
					showDownArrow={false}
					showNoOptionsText={false}
				/>
				{ !ascLoaded && ascLoading && <span class="input-loader form-control-feedback form-control-feedback-padded" aria-hidden="true"></span>}
			</div>
			<div class="col-sm-12 clearfix text-right">
				<Button className='btn button-servify float-right' isDisabled={disableRefurbOrder} handleOnClick = {()=>{createRefurbRequest()}}>{localString("Save")}</Button>
			</div>
        </>
	)
}
export default CreateRefurbInput;
