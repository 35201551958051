import React from 'react';
import {Modal} from 'sfy-react';
import CreateVisitInput from './CreateVisitInput';
import './CreateVisitButtonModal.scss';

const CreateVisitButtonModal=(props)=>{
    const {localString,showModal,closeModal,scheduleDateArray,selectedScheduleDate,scheduleSlotArray,slotAvailable, ConsumerServiceRequestID} = props;
		return (
		<>
            <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
                <Modal.Header>
                    <h2 className='heading modal-title text-capitalize'> { localString("Create Visit")} </h2>
                </Modal.Header>
                <Modal.Body>
                    <CreateVisitInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default CreateVisitButtonModal;