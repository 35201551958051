import React from "react";
import { Modal, Button } from "sfy-react";
import QRCode from "qrcode.react";
import "../RequestProof.scss";

const QRCodeModal = (props) => {
	const {
		localString,
		showModal,
		closeModal,
		consumerServiceRequest,
		selectedDocumentGroupObject,
		handleOpenPreviewClickAfterRefresh,
	} = props;

	return (
		<>
			<Modal
				showModal={showModal}
				closeModal={closeModal}
				showCloseBtn={true}
				className="qr-modal-width"
			>
				<Modal.Header>
					<div className="rp-modal-header">
						<h4 className="rp-modal-heading">
							{localString("Scan QR Code")}
						</h4>
						<p className="rp-modal-para">
							{localString(
								`Scan this QR code from the mServ app to upload ${selectedDocumentGroupObject.groupName}`
							)}
						</p>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="rp-qr-code-wrapper">
						<QRCode
							value={JSON.stringify({
								ReferenceID: consumerServiceRequest.ReferenceID,
								ProofGroupCode:
									selectedDocumentGroupObject.proofGroupCode,
								Status: selectedDocumentGroupObject.status,
							})}
							size={256}
						/>

						<Button
							classes="btn squareBtnGhost"
							handleOnClick={() => {
								closeModal();
								handleOpenPreviewClickAfterRefresh(selectedDocumentGroupObject);
							}}
						>
							{localString("View External Proof")}
						</Button>

						<div>{`Click once ${selectedDocumentGroupObject.groupName} upload is complete`}</div>
					</div>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
};
export default QRCodeModal;
