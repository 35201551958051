import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Loader } from 'sfy-react';
import Swal from "sweetalert2";
import PageHeader from '../../components/PageHeader/PageHeader';
import { displayError } from "../../Utility/Utils";
import { isEmpty } from "lodash";
import PSLModal from "./PSLModal/PSLModal";
import { debounce } from "lodash";
import CategoryBrandList from "./CategoryBrandList/CategoryBrandList";
import ProductList from "./ProductList/ProductList";
import ViewOffersModal from "./ViewOffersModal/ViewOffersModal";
import { useDispatch, useSelector } from "react-redux";
import {
	setSelectedPSL,
	setPartnerLocationList,
} from "../../reduxToolkit/features/planSales/planSalesDashboardSlice";

const PlanSales = (props) => {
	const { accessFn, localString, titleHeader, apiCall, useQuery } = props;
	const dispatch = useDispatch();
	const qFilter = JSON.parse(props.qFilter);
	const [loader, setLoader] = useState(false);
	const [subCategoryList, setSubCategoryList] = useState([]);
	const [subCategoryObj, setSubCategoryObj] = useState({});
	const [countriesData, setCountriesData] = useState([]);
	const [brandList, setBrandList] = useState([]);
	const [brandObj, setBrandObj] = useState({});
	const [showBrands, setShowBrands] = useState(false);
	const [showSalePlan, setShowSalePlan] = useState(false);
	const [locationInput, setLocationInput] = useState(null)
	const [showServicelocationModal, setShowServicelocationModal] = useState(false);
	// const [partnerLocationList, setPartnerLocationList] = useState([])
	const [PSLSearchText, setPSLSearchText] = useState("");
	const [planDiscountOffers, setPlanDiscountOffers] = useState([])
	const [openOffersModal, setOpenOffersModal] = useState(false)
	const [PSLData, setPSLData] = useState({
		PartnerServiceLocationID: "",
		PartnerID: "",
		ServiceLocationName: ""
	})
	// const [selectedPSL, setSelectedPSL] = useState({});
	const [showClearOffers, setShowClearOffers] = useState(false);
	const { selectedPSL, partnerLocationList, dateRange } = useSelector(
		(state) => state.PlanSalesDashboard
	);
	const [firstCall, setFirstCall] = useState(true);
	const [limitationOnAPICall, setLimitationOnAPICall] = useState(false);

	let query = useQuery();
	const pslidFromQuery = query.get("pslid");

	const [offersSubCategory, setOffersSubCategory] = useState({});
	const [offersBrandObj, setoffersBrandObj] = useState({});
	const [offersSubcategoryList, setOffersSubcategoryList] = useState([]);
	const [pagination , setPagination] = useState({
		currentPage: 1,
		itemsPerPage: 10,
		maxPages: 0,
		totalItems: 0,
	});

	const buttonHeader = [
	{
		showPSL: true,
		mainDivClass: "location-name-container-header pull-right",
		innerDivLabelClass: "location-name-header",
		linkTextClass: "change-location-header",
		PSLName: PSLData.ServiceLocationName,
		onClickEvent: () => {
			setShowServicelocationModal(true)
		}
	}];

	useEffect(() => {
		!firstCall && loadOffers();
	}, [pagination.currentPage]);

	useEffect(() => {
		getCountries();
		getPlanSubCategories();
	}, [])

	useEffect(() => {
		getServiceCenterValue(PSLSearchText);
	}, [PSLSearchText]);
	
	useEffect(() => {
		if(!pslidFromQuery ){
			setShowServicelocationModal(true)
		}else{
			loadServiceLocations();
		}
	}, [pslidFromQuery])

	useEffect(() => {
		if(!isEmpty(subCategoryObj) && !limitationOnAPICall)
			getBrands()
	}, [subCategoryObj, limitationOnAPICall])

	useEffect(() => {
		if(selectedPSL && firstCall){
			loadOffers();
		}
	}, [selectedPSL])

	const getServiceCenterValue = debounce((locationInput) => {
		if (locationInput && locationInput.length > 2) {
			loadServiceLocations(locationInput);
		}
	}, 200);

	const closeModal =()=>{
		setShowServicelocationModal(false);
		setOpenOffersModal(false)
	}
	
	const selectedSubCategory = (selectedCategory) => {
		setSubCategoryObj(selectedCategory)
	}

	const discountModalFunction = () => {
		// setOpenOffersModal(true)
		setOpenOffersModal(!openOffersModal)
	}

	const selectedBrand = (selectedBrand) => {
		if(!isEmpty(selectedBrand)){
			setBrandObj(selectedBrand);
			setShowSalePlan(true)
		}
	}

	const clearOffersFilters = () => {
		setOffersSubCategory({});
		setoffersBrandObj({});
		setShowClearOffers(false);
		loadOffers(true);
	};

	{/** ********** API Calls ********** */}

	const getCountries = () => {
		let data = { 
			specificCountryCode: qFilter?.countrycode?.[0] ? qFilter.countrycode[0] : 'IN',
			countryConfig: true
		};
		setLoader(true);
		apiCall({
			url: 'getCountries',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data && result.data?.countries?.length) {
					let countries = result.data.countries[0];
					setCountriesData(countries)
				}else {
					Swal.fire({  
						title: '',
						text: localString('No country data found.'),
						type: 'error',
						confirmButtonText: localString('OK'),
						cancelButtonColor: localString('Cancel')
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	};

	const getPlanSubCategories = () => {
		let reqObj = {
			CountryCode: qFilter.countrycode ? qFilter.countrycode[0]: undefined,
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		}
		setLoader(true);
		apiCall({
			url: 'getPlanSubCategories',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(val => ({ ...val, value: val.ProductSubCategory}));
					setSubCategoryList(arr);
					if(arr && arr.length === 1) {
						selectedSubCategory(arr[0]);
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const getBrands = (discountOfferID,offersModal) => {
		let data = {
			"CountryCode" : qFilter.countrycode ? qFilter.countrycode[0]: undefined,
			"salesChannel": qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		}
		if (discountOfferID) {
			data.ProductSubCategoryID = discountOfferID;
		}
		else {
			// allBrands = [];  =>> have to set all brands as empty array
			data.ProductSubCategoryID = subCategoryObj && subCategoryObj.ProductSubCategoryID
		}
		setLoader(true);
		apiCall({
			url: 'getPlanBrandsForSubcategories',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let allBrands = result.data;
					if(offersModal){
						setShowBrands(false);
					}else {
						setShowBrands(true);
					}
					if(allBrands && allBrands.length > 0) {
						if(allBrands.length === 1) {
							selectedBrand(allBrands[0]);
						}
						let arr = allBrands.map(val => ({ ...val, value: val.BrandName}))
						setBrandList(arr)
					} else {
						Swal.fire({
							icon: "error",
							title: localString("Error"),
							text: localString("errorMessage")  // Remaining errorMesage handling
						})
					}
				}else {
					Swal.fire({  
						icon: "error",
						title: localString("Error"),
						text: localString(displayError(result))
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	};

	const loadOffers = (clearAllClick) => {
		setFirstCall(false);
		let reqObj = {
			PartnerServiceLocationID: selectedPSL.PartnerServiceLocationID,
			PartnerID: selectedPSL.PartnerID,
			getPlanDiscounts: true,
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		}
		reqObj.pagination = pagination;
		if(clearAllClick){
			reqObj.ProductSubCategoryID =  undefined;
			reqObj.BrandID = undefined;
		}else {
			reqObj.ProductSubCategoryID = offersSubCategory ? offersSubCategory?.ProductSubCategoryID : undefined;
			reqObj.BrandID = offersBrandObj ? offersBrandObj?.BrandID : undefined;
		}
		if(PSLData.PartnerServiceLocationID || PSLData.PartnerID){
			reqObj.PartnerServiceLocationID = PSLData.PartnerServiceLocationID
			reqObj.PartnerID = PSLData.PartnerID
		}
		setLoader(true);
		apiCall({
			url: 'fetchDiscountOffers',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				setFirstCall(false);
				if(result.success && result.data) {
					let offersData = result.data.PlanDiscountOffers;
					setPlanDiscountOffers(offersData);
					setPagination({
						...pagination,
						totalItems: result?.data?.pagination?.totalItems,
						maxPages: result?.data?.pagination?.maxPages,
					});
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const loadServiceLocations = (locationInput) => {
		let reqObj = {
			SearchCountryID: countriesData && qFilter && qFilter.countrycode ? countriesData.CountryID : undefined,
			CountryCode: qFilter.countrycode ? qFilter.countrycode[0]: undefined,
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp",
			serviceLocations: !locationInput && pslidFromQuery ? [pslidFromQuery] : undefined
		}
		if (locationInput) {
			reqObj.ServiceLocationNameSubString = locationInput;
		}
		setLoader(true);
		apiCall({
			url: 'serviceCenterList',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					if (qFilter && qFilter.serviceLocations && qFilter.serviceLocations.length == 1 || (pslidFromQuery && !PSLSearchText)) {
						setPSLData({
							...PSLData,
							ServiceLocationName: result.data[0].ServiceLocationName,
							PartnerServiceLocationID: result.data[0].PartnerServiceLocationID,
							PartnerID: result.data[0].PartnerID
						})
						// setPartnerLocationList(result.data[0])
						dispatch(setSelectedPSL(result.data[0]));
					}
					const list = result.data && result.data.map((v) => ({ ...v, value: v.ServiceLocationName}));
					// setPartnerLocationList(list)
					dispatch(setPartnerLocationList(list));
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	{/** ********** END API Calls ********** */}

	return (
		<div className="PlanSales PlanSales-Mobile">
			{
				(loader) && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			{
				showServicelocationModal && 
				<PSLModal 
					{...props}
					showServicelocationModal = {showServicelocationModal}
					closeModal = {closeModal}
					PSLData = {PSLData} setPSLData = {setPSLData}
					partnerLocationList = {partnerLocationList}
					setPSLSearchText = {setPSLSearchText}
					// setSelectedPSL = {setSelectedPSL} 
					selectedPSL = {selectedPSL}
					loadOffers = {loadOffers}
				/>
			}
			{
				openOffersModal && 
				<ViewOffersModal
					{...props}
					offersModal={openOffersModal}
					setOffersModal={setOpenOffersModal}
					subCatValue={(offersSubCategory?.value) || ""}
					brandValue={(offersBrandObj?.value) || ""}
					subCatOptions={subCategoryList}
					brandOptions={brandList}
					handleOnCategoryChange={(data) => {
						setOffersSubCategory(data)
						setoffersBrandObj(null)
						getBrands(data.ProductSubCategoryID,true);
					}}
					handleOnBrandChange={(data) => {
						setoffersBrandObj(data);
					}}
					isDisabled={isEmpty(offersSubCategory)}
					handleOnClick={(e) => {
						e.preventDefault();
						loadOffers();
						setShowClearOffers(true);
					}}
					onClick={() => clearOffersFilters()}
					offersData={planDiscountOffers}
					footerButtonFunc={() => {
						setOpenOffersModal(false);
						// onSellPlanClick(brandObj,subCategoryObj);
					}}
					// footerButtonText="Sell Plans Now"
					showClearOffers={showClearOffers}
					pagination={pagination}
					setPagination={setPagination}
				/>
			}
			{
				!showSalePlan && 
				<>
					<PageHeader
						titleHeader = {titleHeader}
						localString = {localString}
						headerButtonArray = {buttonHeader} 
						btnClass = 'btn button-servify header-btn button-stack text-capital'
					/>
					<CategoryBrandList
						{...props}
						showBrands = {showBrands}
						subCategoryList = {subCategoryList}
						selectedSubCategory = {selectedSubCategory}
						offersData = {planDiscountOffers}
						discountModalFunction = {discountModalFunction}
						brandList = {brandList} 
						selectedBrand = {selectedBrand}
					/>
				</>
			}
			{
				showSalePlan && 
				<ProductList
					{...props}
					PSLData = {PSLData}
					brandObj = {brandObj}
					subCategoryObj = {subCategoryObj} setSubCategoryObj = {setSubCategoryObj}
					subCategoryList = {subCategoryList}
					pslidFromQuery = {pslidFromQuery}
					setLimitationOnAPICall = {setLimitationOnAPICall}
					countriesData = {countriesData}
				/>
			}
		</div>
	)
}

export default PlanSales