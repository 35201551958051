import React from "react";
import ReactECharts from "echarts-for-react";
import "./DonutChart.scss";

function DonutChart(props) {
	const { upfrontSales, subscriptionPlans , localString} = props;

	var colorPalette = ["#4c10c1", "#8F6EEB"];
	const option = {
		tooltip: {
			trigger: "item",
		},
		legend: {
			top: "5%",
			left: "center",
			show: false,
		},
		series: [
			{
				type: "pie",
				// name: "access form",
				radius: ["50%", "65%"],
				avoidLabelOverlap: true,
				label: {
					show: false,
				},
				emphasis: {
					label: {
						show: false,
						fontSize: 10,
						fontWeight: "bold",
					},
				},
				color: colorPalette,
				data: [
					{ value: upfrontSales, name: localString("Upfront Payment") },
					{ value: subscriptionPlans, name: localString("Subscription Payment") },
				],
			},
		],
	};

	return <ReactECharts option={option} className="donutChart hidden-xs" />;
}

export default DonutChart;
