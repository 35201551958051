import React from 'react';
import {Modal} from 'sfy-react';
import ServiceLocationList from './ServiceLocationList';
import './ServiceLocationModal.scss';

const ServiceLocationModal=(props)=>{
    const {localString,showModal,closeModal} = props;
		return ( <div className="serviceLocationModal">
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal Fullscreen-Modal'>
                <Modal.Header>
                    <h2 style={{ textAlign: 'left' }}> {localString("Transfer Details")} </h2>
                </Modal.Header>
                <Modal.Body>
                    <ServiceLocationList {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div> )
}
export default ServiceLocationModal;