
import React, { useState,useEffect } from "react";
import ReactDOM from 'react-dom';
import {Loader,Button} from 'sfy-react';
import { displayError } from "../../../../../Utility/Utils";
import Swal from "sweetalert2";
import './EmailSmsTemplat.scss'
import { returnLocalDateTime } from "../../../../../Utility/Utils";
const EmailTemplate = (props)=>{
    const {qFilter,apiCall,publicUrl,showModal,setShowModal,handleResent,localString,showConfirmModal,setShowConfirmModal,authorization,docServiceUrl,useQuery} = props;
	const [emailEncodeDecodeData,setEmailEncodeDecodeData] = useState(null);
	const [smsData,setSmsData] = useState(null);
	const [type,setType] = useState('');
    const [loader, setLoader] = useState(false);
	const [attachedLoader, setAttachedLoader] = useState(false);
	const [docAttachment,setDocAttachment] = useState();
	const [smsAndEmailDetails,setSmsAndEmailDetails] = useState();
    let query = useQuery();
    let commsid = query.get("commsid");
    useEffect(() => {
        getSmsAndEmailLogDetails();
    }, [commsid])

    const getSmsAndEmailLogDetails =() =>{
        let data = {
			'CommsHistoryID':commsid
		}
		setLoader(true);
		apiCall({
			url: 'getSmsAndEmailLogDetails',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let documentAtt = result.data && result.data.Attachments && JSON.parse(result.data.Attachments)
					setSmsAndEmailDetails(result.data)
					if(result.data && result.data.CommsType=='sms'){
						if(result.data.SMS){
							setSmsData(result.data.SMS)
							setType(result.data.CommsType);
						}else{
							Swal.fire({
								icon: 'error',
								text: localString('SMS not found'),
							});
						}
					}
					if(result.data && result.data.CommsType=='email' ||  result.data.CommsType=='whatsapp'){
					
						setType(result.data.CommsType);
						if(result.data && result.data.Link){
							getRenderableUrl(result.data.Link)
						}else{
							Swal.fire({
								icon: 'error',
								text: localString('Email not found'),
							});
						}
					}
					if(result.data && result.data.CommsType=='email' && documentAtt && documentAtt.length>0){
						let docID=documentAtt.map(doc=>{
							return doc.DocumentID
						})
						getRenderableUrlForAttachment(docID)
					}
                }else{
                    Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
                }
			}
		});
    }

    const getRenderableUrl = (docId) => {
		if (docServiceUrl == '') {
			return;
		}
		setLoader(true)
		// let documentId = docId;
		let data = {docserviceID:[docId]}
		let url = docServiceUrl + 'document/getRenderableUrl';
		fetch(url, {
			method: 'POST',
			headers: {
				'App': 'WebApp',
				'module':'WebApp',
				"Content-Type":'application/json',
				'user-authorization': authorization
			},
			body: JSON.stringify(data)
		})
		.then(res => res.json())
		.then(result => {
			setLoader(false);
			if(result.success && result.data) {
				const url = result.data[0].url;
                fetch(
                    url,
                    {
                        method: 'GET'
                    }
                )
                .then(res => res.text())
		        .then(result => {
                    let decoded = decodeURI(result)
                    setEmailEncodeDecodeData(decoded);
                }
                )
				
			}else {
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(result)),  
				}); 
			}
		})
		.catch(err => {
			setLoader(false);
			Swal.fire({  
				icon: 'error',  
				text: localString(displayError(err)),  
			});
		})
	}


	const getRenderableUrlForAttachment = (docId) => {
		if (docServiceUrl == '') {
			return;
		}
		setAttachedLoader(true)
		// let documentId = docId;
		let data = {docserviceID:docId}
		let url = docServiceUrl + 'document/getRenderableUrl';
		fetch(url, {
			method: 'POST',
			headers: {
				'App': 'WebApp',
				'module':'WebApp',
				"Content-Type":'application/json',
				'user-authorization': authorization
			},
			body: JSON.stringify(data)
		})
		.then(res => res.json())
		.then(result => {
			setAttachedLoader(false);
			if(result.success && result.data) {
				const url = result.data.url;
				setDocAttachment(result.data)
			}else {
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(result)),  
				}); 
			}
		})
		.catch(err => {
			setLoader(false);
			Swal.fire({  
				icon: 'error',  
				text: localString(displayError(err)),  
			});
		})
	}

    const printDiv = () => {
		let printButton = document.getElementById("printReportButton");
		printButton.style.visibility = "hidden";
		window.print();
		printButton.style.visibility = "visible";
	};
    return(
        <>
            { loader || attachedLoader && ReactDOM.createPortal(
                <Loader />,
                document.getElementById("loader-root")
            )}
			
            <div className="top-printbar mt20 " id="topPrintbar">
                <Button
					id="printReportButton"
					type="button"
					className="btn btn-primary printBtn"
					onClick={(e) => printDiv(e)}
				>
					{" "}
					<img
						className="printBtnIcon" 
						src={publicUrl + `/imgs/sprite-5.png`} 
						alt="" 
					/>
					{" "}
					{localString("Print")}
				</Button>
				{
					(emailEncodeDecodeData || smsData) &&
					<div className="attachment-main">
						{type=='email' && <div className="">
							{localString("From")}: { smsAndEmailDetails?.From
							? localString(smsAndEmailDetails?.From)
							:''}
						</div>}
						<div className=" ">
							{localString("To")}: {smsAndEmailDetails?.Recipient
							? localString(smsAndEmailDetails?.Recipient):''}
						</div>
						{type=='email' && <div className="">
							{localString("Subject")}: {localString(smsAndEmailDetails?.Subject)}
						</div>}
						<div className="">
							{localString("Timestamp")}: {returnLocalDateTime(smsAndEmailDetails && smsAndEmailDetails.CreatedDate,"DD-MM-YYYY") }| {returnLocalDateTime(smsAndEmailDetails && smsAndEmailDetails.CreatedDate,"HH:mm:ss")}
						</div>
					</div>
				}
				{(type=='email' || type=='whatsapp') && emailEncodeDecodeData && <div className='' dangerouslySetInnerHTML={{__html:emailEncodeDecodeData }}>
                </div>}
				{
					type=='sms' && smsData && 
					<div className="sms-container">
						{/* <label>{localString('SMS')}</label> */}
						<div>{smsData}</div>
					</div>
				}
				
            </div>
			{emailEncodeDecodeData && docAttachment && docAttachment.length>0 && <div className="attachment-main">
				<div className="attachment-header">{localString('Attachments')}:</div>
				<div className="mt10">
					{
						docAttachment && docAttachment.map(item=>(
							<div className="attachment-file">
								
								<a className='' href={item.url} target='_blank'>
									<img 
										src={publicUrl+'/imgs/Download.png'} 
										alt="attached file"
										width={'30px'} 
									/>
								</a>
								<span className="pl20">
									{item.fileName}
								</span>
							</div>
						))
					}
				</div>
			</div>}
        </>
    )
}
export default EmailTemplate;