import React from "react";
import { Input, Button } from "sfy-react";
import { returnLocalDateTime } from "../../../Utility/Utils";

const LogisticsDetails = (props) => {
	const {
		logisticsDetails,
		localString,
		// editClickFun,
		// editClick,
		// editSaveData,
		// cancelClickFun,
		// saveClickFun,
	} = props;
	return (
		<>
			{logisticsDetails && <table class="table table-striped">
				<tbody>
					<tr>
						<td class="table-keys text-capitalize">
							{localString("WayBill Number")}:
						</td>
						<td>{logisticsDetails.WaybillNumber || "-"}</td>
					</tr>
					<tr>
						<td class="table-keys text-capitalize">
							{localString("Reference Number")}:
						</td>
						<td>
							{(logisticsDetails.ReferenceID) ||
								"-"}
						</td>
					</tr>
					<tr>
						<td class="table-keys text-capitalize">
							{localString("Pickup Name")}:
						</td>
						<td>{logisticsDetails.PickUpName || "-"}</td>
					</tr>
					<tr>
						<td class="table-keys text-capitalize">
							{localString("Pickup Address")}:
						</td>
						<td>{logisticsDetails.PickUpAddress || "-"}</td>
					</tr>
					<tr>
						<td class="table-keys text-capitalize">
							{localString("Pickup Pincode")}:
						</td>
						<td>{logisticsDetails.PickUpPincode || "-"}</td>
					</tr>
					<tr>
						<td class="table-keys text-capitalize">
							{localString("Pickup Date")}:
						</td>
						<td>
							{returnLocalDateTime(
								logisticsDetails.ScheduledPickUpFromTime,
								"DD-MM-YYYY"
							) || "-"}
						</td>
					</tr>
					<tr>
						<td class="table-keys text-capitalize">
							{localString("Pickup Time")}:
						</td>
						<td>
							{returnLocalDateTime(
								logisticsDetails.ScheduledPickUpFromTime,
								"hh:mm a"
							) +
								" to " +
								returnLocalDateTime(
									logisticsDetails.ScheduledPickUpToTime, "hh:mm a") || "-"}
						</td>
					</tr>
					<tr>
						<td class="table-keys text-capitalize">
							{localString("Pickup Contact No")}.:
						</td>
						<td>{logisticsDetails.PickUpContactNo || "-"}</td>
					</tr>
					<tr>
						<td class="table-keys text-capitalize">
							{localString("Drop Name")}:
						</td>
						<td>{logisticsDetails.DropName || "-"}</td>
					</tr>
					<tr>
						<td class="table-keys text-capitalize">
							{localString("Drop Address")}:
						</td>
						<td>{logisticsDetails.DropAddress || "-"}</td>
					</tr>
					<tr>
						<td class="table-keys text-capitalize">
							{localString("Drop Pincode")}:
						</td>
						<td>{logisticsDetails.DropPincode || "-"}</td>
					</tr>

					<tr>
						<td class="table-keys text-capitalize">
							{localString("Drop Contact No")}.:
						</td>
						<td>{logisticsDetails.DropContactNo || "-"}</td>
					</tr>
					<tr>
						<td class="table-keys text-capitalize">
							{localString("Status")}:
						</td>
						<td>{logisticsDetails.StatusDisplayText || "-"}</td>
					</tr>
				</tbody>
			</table>}
		</>
	);
};

export default LogisticsDetails;
