import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { displayError} from '../../../../../Utility/Utils';
import _ from 'lodash';
import { Button, Checkbox } from 'sfy-react';

const InitiateRefundlInput = (props) => {
	const {
		apiCall, setLoader, closeModal,localString, getRequestById, consumerServiceRequest,selectRefundDetails, 
		setSelectRefundDetails
	} = props;

	const [disableRefund, setDisableRefund] = useState(false);

	useEffect(()=>{
        if(selectRefundDetails && selectRefundDetails.length){
			let disable = false;
			selectRefundDetails.filter((refund,i)=>{
				if(!disable){
					if((refund && !refund.amount ) || (refund && refund.amount <= 0 ) || (refund.amount > refund.existingAmount)   ){
						disable = true
					}
				}
			})
			setDisableRefund(disable)
        }
        
    },[selectRefundDetails])

	const saveRefundDetails = () => {
		let chargeToBeRefunded = [];
		let data = {
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			refundArray: chargeToBeRefunded,
		};
		for (let i = 0; i < selectRefundDetails.length; i++) {
			if (selectRefundDetails[i].amount && selectRefundDetails[i].isSelected) {
					chargeToBeRefunded.push({
					paymentProcessID: selectRefundDetails[i].paymentProcessID,
					amount: selectRefundDetails[i].amount.toString(), // Used updated newamount value here
				});
			}
		}

		if (chargeToBeRefunded.length > 0) {
			setLoader(true);
			apiCall({
				url: 'refund',
				data: data,
				callBack: (result) => {
					setLoader(false);
					if (result && result.success) {
						closeModal();
						getRequestById();
					} else {
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				},
			});
		} else {
			Swal.fire({
				icon: 'info',
				text: localString('No charges found to refund'),
			});
		}
	};

	return (
		<div>
			{selectRefundDetails && (
				<div>
					<div style={{ marginBottom: '10px' }}>{localString('Enter the amount for refund')}</div>
					{selectRefundDetails.map((ChargeType, i) => (
					<div className="" key={i}>
						<div class="col-xs-8">
							<Checkbox
								value={ChargeType.isSelected || false}
								name="refundPurpose"
								id={'selectRefundDetails' + i}
								isDisabled={false}
								handleOnChange={(e) => {
									let arr = _.cloneDeep(selectRefundDetails)
									arr[i].isSelected = e.target.checked
									setSelectRefundDetails(arr)
								}}
								>
								{ChargeType.purpose}
							</Checkbox>
						</div>
						<div class="col-xs-4">
							<label className="currency-in-input">{ChargeType.CurrencyCode}</label>
							<input
								className="charges-form-input refund-input input"
								value={ChargeType.amount || ''}//newamount['amount'+i]
								type='number'
								id={'refundAmount' + i}
								onChange={(e) => {
									let arr = _.cloneDeep(selectRefundDetails)
									arr[i].amount = e.target.value
									setSelectRefundDetails(arr)
								}}
							/>
						</div>
					</div>
					))}
				</div>
			)}
			<div>
				<Button 
					handleOnClick={saveRefundDetails} 
					className="btn button-servify pull-left" 
					isDisabled={disableRefund} 
				>
					{localString('Submit')}
				</Button>
			</div>
		</div>
	)
}
export default InitiateRefundlInput;









