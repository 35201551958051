import React from 'react';
import {Modal} from 'sfy-react';
import PlanTypeButtonInput from './PlanTypeButtonInput';
import './PlanTypeButtonModal.scss';

const PlanTypeButtonModal=(props)=>{
    //removed hiddenContent, scheduleDateArray, selectedScheduleDate, scheduleSlotArray, slotAvailable, ConsumerServiceRequestID
    const {localString,showModal,closeModal} = props;
		return (
		<>
            <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='Filter-Modal planTypeModal'>
                <Modal.Header>
                    <h2> { localString("Plan Types")} </h2>
                </Modal.Header>
                <Modal.Body>
                    <PlanTypeButtonInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default PlanTypeButtonModal;