import { useState, useMemo } from "react";
const ASC = 1;
const DESC = -1;

let convertorObject = {
	"Case No.": "caseReferenceID",
	"Reference No.": ["soldPlanCode", "referenceID"],
	"Claim Status": ["claimStatus", "soldPlanStatus"],
	Plan: "planName",
	Product: "productName",
	Aging: "aegingTimeStamp",
	Consumer: "name",
	Rules: "",
	Score: "score",
	"Case Status": "caseStatus",
	Assignee: "assigneeName",
};

const useSortedData = () => {
	const [array, setArray] = useState([]);
	const [config, setConfig] = useState({});

	const sortedData = useMemo(() => {
		let order = config.order === ASC ? DESC : ASC;
		let currentSortingBasedOn = convertorObject[config.field];
		let newData = [...array];

		if (newData) {
			if (config.field === "Case Status") {
				newData = newData.sort((obj1, obj2) => {
					return obj1["caseStatusID"] > obj2["caseStatusID"]
						? order
						: obj1["caseStatusID"] < obj2["caseStatusID"]
						? -order
						: 0;
				});
			} else if (config.field === "Aging") {
				newData = newData.sort((obj1, obj2) => {
					return obj1["aegingTimeStamp"] > obj2["aegingTimeStamp"]
						? order
						: obj1["aegingTimeStamp"] < obj2["aegingTimeStamp"]
						? -order
						: 0;
				});
			} else if (
				["Reference No.", "Claim Status"].includes(config.field)
			) {
				newData = newData.sort((obj1, obj2) => {
					let firstValue = obj1[currentSortingBasedOn[0]]
						? obj1[currentSortingBasedOn[0]]
						: obj1[currentSortingBasedOn[1]]
						? obj1[currentSortingBasedOn[1]]
						: "";
					let secondValue = obj2[currentSortingBasedOn[0]]
						? obj2[currentSortingBasedOn[0]]
						: obj2[currentSortingBasedOn[1]]
						? obj2[currentSortingBasedOn[1]]
						: "";

					return config.field === "Reference No."
						? firstValue.localeCompare(secondValue, undefined, {
								numeric: true,
								sensitivity: "base",
						  })
							? order
							: -order
						: firstValue > secondValue
						? order
						: firstValue < secondValue
						? -order
						: 0;
				});
			//} else if (config.field === "Case No.") {
			//	newData = newData.sort((obj1, obj2) => {
			//		return obj1[currentSortingBasedOn].localeCompare(
			//			obj2[currentSortingBasedOn],
			//			undefined,
			//			{
			//				numeric: true,
			//				sensitivity: "base",
			//			}
			//		)
			//			? order
			//			: -order;
			//	});
			} else {
				newData = newData.sort((obj1, obj2) => {
					return obj1[currentSortingBasedOn] >
						obj2[currentSortingBasedOn]
						? order
						: obj1[currentSortingBasedOn] <
						  obj2[currentSortingBasedOn]
						? -order
						: 0;
				});
			}
		}

		return newData;
	}, [array, config.order, config.field]);

	const updateData = (data = {}) => {
		if (data.config) setConfig(data.config);
		if (data.data) setArray(data.data);
	};

	return [sortedData, config, updateData];
};

export default useSortedData;
