import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import {displayError} from '../../../../../Utility/Utils';
import BuyoutDetailsTable from './BuyoutDetailsTable';
import './BuyoutDetails.scss'

const BuyoutDetails = (props) => {
	const {consumerServiceRequest, apiCall, localString } = props;
	const [loader, setLoader] = useState(false)
	const [buyoutRequestData, setBuyoutRequestData] = useState([])

	useEffect(() => {
		getReimbursements();
	}, [])

	const getReimbursements = () => {
		let data = {
			ConsumerServiceRequestID: consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID
		};
		setLoader(true);
		// tabLoader = true;
		apiCall({
			url: "getReimbursements",
			data: data,
			callBack:(result)=>{
				// tabLoader = true;
				setLoader(false);
				if(result.success){
					let buyoutData = result.data && result.data[0]
					setBuyoutRequestData(buyoutData)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	return (
		<>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper bodyClassName="panel-body-no-padding" title={localString("Reimbursement Details")}>
						<BuyoutDetailsTable
							{...props}
							buyoutRequestData={buyoutRequestData}
						/>
					</PageContentWrapper>
				</div>
			</div>
		</>
	)
}

export default BuyoutDetails
