import { all } from 'bluebird';
import React,{useState,useEffect} from 'react';
import { returnLocalDateTime ,encrypt} from '../../../../../Utility/Utils';
import accessFunction from '../../../../../Utility/featureRights';

const CommunicationInput = (props)=>{
    const {publicUrl,baseUrl,localString,communicationData,handleResend,activeLink,typeCheck,getCallStatus,accessFn}=props
    const [showMore,setShowMore] = useState(0);
    const [newCommsHistoryData,setNewCommsHistoryData] = useState([]);
    const [communicationDetails,setCommunicationDetails] = useState({})
    const collapseData = (id,communicationData)=>{
        setShowMore(id)
        setCommunicationDetails(communicationData)
    }

	const statusColor={
		'Pending':'#096DD9',
		'Success':'#389E0D',
		'Failed':'#CF1322',
	}

    useEffect(()=>{
        let commsArray = communicationData && communicationData.length && communicationData.filter(comms=>{
            if(comms.CommsType == typeCheck.callLog || comms.CommsType == typeCheck.email || comms.CommsType == typeCheck.sms){
                return comms
            }
        })
        setNewCommsHistoryData(commsArray)
    },[communicationData])

    
    const callLogRowOne = [
        {   
            type:'call log',
            localText:'Type',
            customClass:'col-sm-2'
        },
        {
            type:'call log',
            localText:'Request Status',
            customClass:'col-sm-3'
        },
        {
            type:'call log',
            localText:'Calling No',
            customClass:'col-sm-3'
        },
        {
            type:'call log',
            localText:'Status',
            customClass:'col-sm-4'
        },
        {   
            type:'email',
            localText:'Type',
            customClass:'col-sm-2'
        },
        {
            type:'email',
            localText:'Request Status',
            customClass:'col-sm-3'
        },
        {
            type:'email',
            localText:'Email ID',
            customClass:'col-sm-3'
        },
        {
            type:'email',
            localText:'Status',
            customClass:'col-sm-4'
        },
        {   
            type:'sms',
            localText:'Type',
            customClass:'col-sm-2'
        },
        {
            type:'sms',
            localText:'Request Status',
            customClass:'col-sm-3'
        },
        {
            type:'sms',
            localText:'Mobile No.',
            customClass:'col-sm-3'
        },
        
        {
            type:'sms',
            localText:'Status',
            customClass:'col-sm-4'
        },
        {   
            type:'whatsapp',
            localText:'Type',
            customClass:'col-sm-2'
        },
        {
            type:'whatsapp',
            localText:'Request Status',
            customClass:'col-sm-3'
        },
        {
            type:'whatsapp',
            localText:'Mobile No.',
            customClass:'col-sm-3'
        },
        
        {
            type:'whatsapp',
            localText:'Status',
            customClass:'col-sm-4'
        },
    ]
    const callLogRowTwo = [
        {
            type:'call log',
            localText:'Support Ticket No.',
            customClass:'col-sm-2'
        },
        {
            type:'call log',
            localText:'Call Type',
            customClass:'col-sm-3'
        },
        {
            type:'call log',
            localText:'Call Connected',
            customClass:'col-sm-3'
        },
        {
            type:'call log',
            localText:'Agent Name',
            customClass:'col-sm-2'
        },
        {
            type:'call log',
            localText:communicationDetails.CallConnected== true ? 'Connect Disposition' : 'Non-Connect Disposition',
            customClass:'col-sm-2'
        },
    ]
    const callLogRowThree=   [
        {
            type:'call log',
            localText:'First Dailer Disposition',
            customClass:'col-sm-2'
        },
        {
            type:'call log',
            localText:'Second Dailer Disposition',
            customClass:'col-sm-3'
        },
        {
            type:'call log',
            localText:'Remark',
            customClass:'col-sm-3'
        },
        {
            type:'call log',
            localText:'Recording URL',
            customClass:'col-sm-4'
        },
        
    ]

    return( 
        communicationData && communicationData.length ? 
        <div className='comm-margin'>
            {
                communicationData && communicationData.length && communicationData.map(filter=>{
                    let isOpenCall = filter.CommsType== typeCheck.callLog ? filter.communicationId==showMore:filter.CommsHistoryID==showMore
                    return(
                    <div div className='pl15 pr15 pb5 communication-main'> 
                        <div className='row '>
                            {activeLink =='all' &&<div className='col-sm-1'>
                            {filter.CommsType== typeCheck.callLog &&
                            <div>
                                <img 
                                    className='eclips'
                                    src={publicUrl+'/imgs/Ellipse.svg'} 
                                    width={'30px'} 
                                />
                                <img 
                                    className='calllog'
                                    src={publicUrl+'/imgs/call.svg'} 
                                    width={'30px'} 
                                />
                                {isOpenCall ?<img 
                                    className='line'
                                    src={publicUrl+'/imgs/Line-call.svg'} 
                                    width={'3px'} 
                                />:<img 
                                    className='line'
                                    src={publicUrl+'/imgs/Line.svg'} 
                                    width={'3px'} 
                                />}
                            </div>}
                            {
                                filter.CommsType==typeCheck.email &&<div>
                                    <img 
                                        className='eclips'
                                        src={publicUrl+'/imgs/Ellipse.svg'} 
                                        width={'30px'} 
                                    />
                                    <img 
                                        className='calllog'
                                        src={publicUrl+'/imgs/Email.svg'} 
                                        width={'30px'} 
                                    />
                                    {isOpenCall ?<img 
                                        className='line'
                                        src={publicUrl+'/imgs/Line-call.svg'} //publicUrl+'/imgs/Line.svg'
                                        width={'3px'} 
                                    />:<img 
                                        className='line'
                                        src={publicUrl+'/imgs/Line.svg'} 
                                        width={'3px'} 
                                    />}
                                </div>
                            }
                            {
                                filter.CommsType==typeCheck.sms &&<div>
                                    <img 
                                        className='eclips'
                                        src={publicUrl+'/imgs/Ellipse.svg'} 
                                        width={'30px'} 
                                    />
                                    <img 
                                        className='sms-log'
                                        src={publicUrl+'/imgs/Sms.svg'} 
                                        width={'15px'} 
                                    />
                                    {isOpenCall ?<img 
                                        className='line'
                                        src={publicUrl+'/imgs/Line-call.svg'} 
                                        width={'3px'} 
                                    />:<img 
                                        className='line'
                                        src={publicUrl+'/imgs/Line.svg'} 
                                        width={'3px'} 
                                    />}
                                </div>
                            }
                            {
                                filter.CommsType==typeCheck.whatsapp &&<div>
                                    <img 
                                        className='eclips'
                                        src={publicUrl+'/imgs/Ellipse.svg'} 
                                        width={'30px'} 
                                    />
                                    <img 
                                        className='whatsapp-log'
                                        src={publicUrl+'/imgs/whatsapp.svg'} 
                                        width={'12px'} 
                                    />
                                    {isOpenCall ?<img 
                                        className='line'
                                        src={publicUrl+'/imgs/Line-call.svg'} 
                                        width={'3px'} 
                                    />:<img 
                                        className='line'
                                        src={publicUrl+'/imgs/Line.svg'} 
                                        width={'3px'} 
                                    />}
                                </div>
                            }
                            </div>}
                        
                            <div className={activeLink=='all'?'call-log-history col-sm-11':'col-sm-12'}>
                                <span className='date-time'>
                                    {returnLocalDateTime(filter && filter.CreatedDate,"DD-MM-YYYY") }|{returnLocalDateTime(filter && filter.CreatedDate,"HH:mm:ss") }
                                </span>
                                {
                                    <div className={activeLink=='all' ? 'content-marginR content action-bar tags-tabs mt15':'content action-bar tags-tabs mt15'}>
                                        
                                        <div className='row'>
                                            {/* start for all log */}
                                            <div className='col-sm-12 p20'>
                                                {
                                                    callLogRowOne.map(header=>{
                                                        return header.type == filter.CommsType && <div  className={`${header.customClass} heading-text pb5`}>{header.localText}</div>
                                                    })
                                                }
                                                <div className='col-sm-2'>
                                                    {filter.CommsType ? localString(filter.CommsType):'-'}
                                                </div>
                                                <div className='col-sm-3'>
                                                    {filter.CsrStatus ? localString(filter.CsrStatus):'-'}
                                                </div>
                                                {filter.CommsType ==typeCheck.callLog && filter.customerCallingNumber && 
                                                <div className='col-sm-3'>
                                                    { filter.customerCallingPhoneCode && '+'+localString(filter.customerCallingPhoneCode)}
                                                    {filter.customerCallingNumber ? localString(filter.customerCallingNumber ):'-'}
                                                </div>}
                                                {filter.CommsType ==typeCheck.email &&
                                                <div className='col-sm-3'>
                                                    {filter && filter.EmailID ? localString(filter.EmailID) : '-'}
                                                </div>}
                                                {(filter.CommsType ==typeCheck.sms || filter.CommsType ==typeCheck.whatsapp) &&
                                                <div className='col-sm-3'>
                                                    {filter && filter.MobileNo ? '+'+localString(filter.MobileNo):'-'}
                                                </div>}
                                                {(filter.CommsType ==typeCheck.email || filter.CommsType ==typeCheck.sms || filter.CommsType ==typeCheck.whatsapp) && 
                                                <div className='col-sm-4 status-pill' style={{color:statusColor[filter.Status]  }}>
                                                    {filter && filter.Status ? localString(filter.Status ):'-'}
                                                </div>}
                                                <div className='show-logs'>
                                                    {(filter.CommsType ==typeCheck.sms || filter.CommsType ==typeCheck.email || filter.CommsType ==typeCheck.whatsapp) && 
                                                    <div className={isOpenCall ? 'col-sm-4 text-link pt20 show-resent':'col-sm-4 text-link show-resent'} onClick={()=>handleResend(filter.CommsHistoryID,filter.CommsType)}>
                                                        {localString("Resend")}
                                                    </div>}

                                                    {isOpenCall ? <div className={filter.CommsType ==typeCheck.callLog ?'col-sm-12 text-link pt20 show-call':'col-sm-8 text-link pt20 show-call'}  onClick={(e) => collapseData(0,filter)}> {localString('Show Less')}  
                                                        <img 
                                                            src= {publicUrl + '/imgs/Cheveronup.svg'}
                                                            width={'16px'}
                                                            height={'16px'} 
                                                            className='ml5'
                                                        />
                                                    </div>
                                                    :<div className={filter.CommsType ==typeCheck.callLog ?'col-sm-12 text-link  show-call':'col-sm-8 text-link  show-call'}  onClick={(e) => collapseData(filter.CommsType==typeCheck.callLog ?filter.communicationId:filter.CommsHistoryID,filter)}>{localString('Show More')}  
                                                        <img 
                                                            src={publicUrl + '/imgs/Arrowdown.svg'}
                                                            width={'16px'}
                                                            height={'16px'} 
                                                            className='ml5'
                                                        />
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                             {/* end */}
                                            {/* start for call only 2nd row */}
                                            {isOpenCall &&//ilter.CommsType ==typeCheck.callLog 
                                            <div className='col-sm-12 pl20 pb20'>
                                                {
                                                    callLogRowTwo.map(header=>{
                                                        return header.type == filter.CommsType && 
                                                        <div  className={`${header.customClass} heading-text pb5`}>
                                                            {localString(header.localText)}
                                                        </div>
                                                    })
                                                }
                                                {filter.CommsType ==typeCheck.callLog &&
                                                <div>
                                                    <div className='col-sm-2'>
                                                        {filter.supportTicketNumber ? localString(filter.supportTicketNumber):'-'}
                                                    </div>
                                                    <div className='col-sm-3'>
                                                        {localString(filter.CallIncoming ? 'Incoming':'Outgoing')}
                                                    </div>
                                                    <div className='col-sm-3'>
                                                        {localString(filter.CallConnected ? 'Yes':'No')}
                                                    </div>
                                                    <div className='col-sm-2'>
                                                        {filter.senderName ? localString(filter.senderName):'-'}
                                                    </div>
                                                    <div className='col-sm-2'>
                                                        {filter.communicationDetails[0] ? getCallStatus(filter.communicationDetails[0].callStatusId):'-'}
                                                    </div> 
                                                </div>}
                                                {
                                                    (filter.CommsType ==typeCheck.email || filter.CommsType ==typeCheck.sms || filter.CommsType ==typeCheck.whatsapp ) && filter.Status == 'Failed' && <div>
                                                        <div className='col-sm-12 heading-text pb5'> {localString("Reason")}</div>
                                                            <div className='col-sm-12'>
                                                                {filter.ErrorMessage ? localString(filter.ErrorMessage) : '-'}
                                                            </div>
                                                    </div>
                                                }
                                            </div>}
                                            {/* end */}

                                            {/* start for call only 3rd row */}
                                            {isOpenCall && filter.CommsType ==typeCheck.callLog &&
                                                <div className='col-sm-12 pl20 pb20'>
                                                {
                                                    callLogRowThree.map(header=>{
                                                        return header.type == filter.CommsType && 
                                                        <div  className={`${header.customClass} heading-text pb5`}>
                                                            {localString(header.localText)}
                                                        </div>
                                                    })
                                                }                                                
                                                <div className='col-sm-2'>
                                                    {filter.communicationDetails[0].firstDailerDispostion ? localString(filter.communicationDetails[0].firstDailerDispostion):'-'}
                                                </div>
                                                <div className='col-sm-3'>
                                                    {filter.communicationDetails[0].secondDailerDispostion ? localString(filter.communicationDetails[0].secondDailerDispostion):'-'}
                                                </div>
                                                <div className='col-sm-3'>
                                                    {filter.communicationDetails[0].remark ? localString(filter.communicationDetails[0].remark):'-'}
                                                </div>
                                                <div className='col-sm-4'>
                                                {filter.communicationDetails[0].callRecordingUrl && accessFn("SHOW_CALL_RECORDING")? <a href={filter.communicationDetails[0].callRecordingUrl} target="_blank">Link</a>:"NA"}
                                                </div>
                                            </div>}
                                            {/* end */}
                                            {/* start for email and sms only */}
                                        
                                            {isOpenCall && (filter.CommsType ==typeCheck.email || filter.CommsType ==typeCheck.sms || filter.CommsType ==typeCheck.whatsapp) && 
                                            <div>
                                                <div className={isOpenCall ? ' pb20 non-collapsed-text' : 'collapsed-text'}>
                                                    <div className='heading-text view-comms '>
                                                        { filter.CommsType ==typeCheck.email ?localString("Email"): filter.CommsType ==typeCheck.sms ? localString("SMS") : localString("WhatsApp")}
                                                    </div>
                                                    <span className='view-comms'>
                                                        <img
                                                            src={publicUrl + '/imgs/Email.svg'}
                                                            width={'20px'} 
                                                        />
                                                    </span>
                                                        <span className='pl15 text-link'>
                                                            <a  className='' href={`${baseUrl}/servicerequests/emailSmsPreview?commsid=${filter.CommsHistoryID}`} target='_blank'>
                                                                {
                                                                    filter.CommsType ==typeCheck.email
                                                                    ? localString('View Email')
                                                                    : filter.CommsType ==typeCheck.sms ? localString('View SMS') : localString('View WhatsApp')
                                                                }
                                                            </a>
                                                        </span>
                                                </div>
                                            </div>}
                                            {/* end*/}
                                        </div>
                                    </div> 
                                }
                            </div>
                        </div>
                    </div>
                    )
                })
            }
        </div>
        :<div class="text-comm">
            <p class='comm-text-size text-capitalize remove-margin'>{localString("No Logs Found")} </p>
        </div>
    )
}
export default CommunicationInput;