

import React from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import './CatalogueManagement.scss';
import Catalogue from './Catalogue';

const CatalogueManagement = (props) => {
	const {titleHeader} = props;

	const SalesSampleData = {
		"ORDER_DAY" : "15/10/17",
		"ORDER_ID" : "407-9140287-3216321",
		"ModelNo" : "HQGFBU0000004",
		"POSTAL_CODE" : "603210",
		"CITY" : "Kanchipuram",
		"IMEI1" : "91260225034327",
		"IMEI2" : "912602250343270",
		"Inv No" : "SFY/17-18/S0001",
		"IsPrime":"Yes"
	}

	const ShipmentSampleData = {
		"Folder" : "Holland-1-26820pcs",
		"Document" : "IMEI LIST- 60PCS Beyond Black",
		"IMEI1" : "912602250343270",
		"IMEI2" : "912602250343270",
		"From" : "China",
		"Model" : "HQGFBU0000004",
		"Configuration" : "",
		"Configuration 2" : "",
		"BILLDATE":"01/10/18"
	}

	const detailsSectionSampleData={
		"ProductName":"SM-S127DL",
		"ProductID":"25683"
	}
	return (
		<div className="catalogue-container">
			<PageHeader titleHeader={titleHeader}></PageHeader>
			<div className="col-xs-12 padding0">
				<div className="col-xs-6 padding0">
					<Catalogue 
						{...props}
						title="Upload Catalogue Details"  
						fileDataType="catalogueData"
						sampleCSVData={detailsSectionSampleData}
						sampleFileName='Sample_details.csv'
					></Catalogue>
				</div>
				<div className="col-xs-6 padding0">
					<Catalogue
						{...props}
						title="Upload Sales Data" 
						fileDataType="SalesData" 
						sampleCSVData={SalesSampleData}
						sampleFileName='Sample_sales.csv'
					></Catalogue>
				</div>
			</div>
			<div className="col-xs-12 padding0">
				<Catalogue 
					{...props}
					title="Upload Shipment Data" 
					fileDataType="ShipmentData" 
					sampleCSVData={ShipmentSampleData}
					sampleFileName='Sample_shipment.csv'
				></Catalogue>
			</div>
		</div>
	) 
}

export default CatalogueManagement;