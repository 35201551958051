import React from 'react';
import {Modal, Dropdown, Button} from 'sfy-react';
import './LoanerDispositionModal.scss'

const LoanerDispositionModal = (props) => {
    const {loanerDispositionModal, closeDispositionModal, localString, dispositionModalDropdown, inputData, 
        enableSaveDisposition, saveLoanerDisposition
    } = props;
    return (
        <div>
            <Modal showModal={loanerDispositionModal} closeModal={closeDispositionModal} showCloseBtn={true} className="filtermodal disposition-modal" id="loaner_disposition_modal">
				<Modal.Header>
					<h4> {localString("Loaner Disposition")} </h4>
				</Modal.Header>
				<Modal.Body>
                    <div className="">
                        <div className="col-sm-12">
                            <form onSubmit={saveLoanerDisposition} name="">
                                {
                                    dispositionModalDropdown && dispositionModalDropdown.map((filter,index)=>{
                                        return( 
                                            <div className="">
                                                <div className="col-sm-12" style={{ marginBottom: "20px"}}>
                                                    <label> {localString(filter.labelText)}</label>
                                                    <Dropdown
                                                        id='dropdown'
                                                        name={filter.name}
                                                        classObject={
                                                            filter.dropdownClass
                                                        }
                                                        key={index}
                                                        value={inputData[filter.dataKey]}
                                                        options={filter.dropdownList}
                                                        handleOnChange={(data) => {
                                                            filter.oninputFunc(data);
                                                        }}
                                                        filterBy='value'
                                                        showDownArrow={false}
                                                        isDisabled={filter.isDisabled}
                                                        placeholder={filter.placeholder}
                                                    >
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div style={{marginTop: "10px"}}>
                                    <Button handleOnClick={closeDispositionModal} type='button' classes='button-ghost btn button-servify pull-right' text={localString('Cancel')}/>
                                    <Button handleOnClick={saveLoanerDisposition} isDisabled={!enableSaveDisposition} type='submit' classes='button-ghost btn button-servify pull-right' text={localString('Save Disposition')}/>
                                </div>
                            </form>
                        </div>
                    </div>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
        </div>
    )
}

export default LoanerDispositionModal