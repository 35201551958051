import React from "react";
import { Pagination } from 'sfy-react';

import CommonTable from "../../components/Table/Table";
import { returnLocalDateTime } from "../../Utility/Utils";

export default function BulkUploadGrid(props) {
	const { localString, uploadHistoryList, pagination, setPagination, getUploadHistory } = props;

	const displayCount = (success, total) => {
		return ((success ? success : 0) + '/' + (total ? total : 0));
	}

	const statusText = {
		'-3': localString('Invalid Data'), // Invalid data - color Red
		'-2': localString('Insufficient Parameters'), // Incorrect template - color Red
		'-1': localString('Failed'), // Failed - color Red
		'0': localString('Pending'), // Pending - color Yellow
		'1': localString('Successful'), // Successful - color Green
		'2': localString('Partially Successful'), // Partially Successful - color Orange,
		'3': localString('In Progress') // Yellow
	};

	const statusColor = {
		'-3': '#ff00009c', 
		'-2': '#ff00009c', 
		'-1': '#ff00009c',
		'0': 'yellow',
		'1': '#4cd801',
		'2': 'orange',
		'3': 'yellow',
	};

	const columns = [
		{
			title: localString("Reference Number"),
			showHeading: true,
			customCell: (row) => row.ReferenceID || '--',
		},
		{
			title: localString("Upload Date"),
			showHeading: true,
			customCell: (row) => returnLocalDateTime(row.CreatedDate) || '--',
		},
		{
			title: localString("File Name"),
			showHeading: true,
			customCell: (row) => row.FileName || '--',
		},
		{
			title: localString("Plan Creation Status"),
			showHeading: true,
			customCell: (row) => (
				<span className="statusPill" style={{ background: statusColor[row.UploadStatus] }}>
					{statusText[row.UploadStatus] || '--'}
				</span>
			),
		},
		{
			title: localString("Plans Created(Success/Total)"),
			showHeading: true,
			customCell: (row) => (row.UploadStatus == 0 || row.UploadStatus == 3) ? '--' : displayCount(row.SuccessCount, row.TotalCount)
		},
		{
			title: localString("Action"),
			showHeading: true,
			customCell: (row) => {
				return (
				<>
					{ (row.ProcessedFileDocumentID || row.ProcessedFileURL) ? <div className="gridDownloadBtn" onClick={() => getUploadHistory(row.ReferenceID)}>{localString('Download')}</div> : '--'}					
				</>
				);
			}
		},
	]
	return (
		<div className="bulkUploadGrid">
			<h4 className='bulkUploadhHeader'>{localString('Upload History')}</h4>
			<CommonTable 
				columns={columns}
				data={uploadHistoryList}
				localString={localString}
			/>
			{
				pagination.numberOfPages > 1 && 
					<Pagination
						itemsPerPage={pagination.itemsPerPage}
						pages={pagination.numberOfPages}
						totalItems={pagination.count}
						activePage={pagination.pageNo}
						paginate={(pageNumber) => setPagination({...pagination, pageNo: pageNumber})}
					/> 
			}
		</div>
	);
}