import React from 'react';
import {Modal} from 'sfy-react';
import InitiateRefundInput from './InitiateRefundInput';
import './InitiateRefundModal.scss';

const InitiateRefundModal=(props)=>{
    const {
        localString, showModal, closeModal
    } = props;
		return (
		<>
            <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='Filter-Modal InitiateRefundModal'>
                <Modal.Header>
                    <h4 className='modal-title'> { localString("Refund Details")} </h4>
                </Modal.Header>
                <Modal.Body>
                    <InitiateRefundInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default InitiateRefundModal;