import React from 'react';
import {Modal} from 'sfy-react';
import DeviceInspectInput from './DeviceInspectInput';

import './DeviceInspect.scss';
const DeviceInspectModal=(props)=>{
    const {localString,showModal,closeModal} = props;
    return (
        <div className='inspect-device-Modal'>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='full-screen-modal'>
                <Modal.Header>
                    <h4 className="modal-title text-center">{localString("Upload Device Pictures and Select Disposition")}</h4>
                </Modal.Header>
                <Modal.Body>
                    <DeviceInspectInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
	)
}
export default DeviceInspectModal;