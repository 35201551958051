import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import FilterInputs from '../../../../components/FilterInputs/FilterInput';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2"; 
import {checkAlphaNumeric, displayError,generateUUID,getLocalDateTime} from '../../../../Utility/Utils';

const AddUpdateConsumerProduct = (props) => {
	const {editMode, consumerDetails,  consumerProduct, setConsumerProductSaveData, unRegistered, consumerProductSaveData, localString, qFilter, apiCall, cancelProduct, saveFunction, documentsList, setUploadedDocDetails , brandAndSubCat} = props;
	const [showLoader ,setLoader] = useState(false);
	const [categoryData,setCategoryData] = useState({});
	const [subcategoryData,setSubcategoryData] = useState({});
	const [brandData,setbrandData] = useState({});
	const [productData,setProductData] = useState({});
	const [loaded, setLoaded] = useState ({
		categoriesLoaded:false,
		brandLoaded:false,
		productLoaded:false,
		subCategoryLoaded:false
	})
	const [disableProduct, setDisableProduct] = useState(true);
	

	useEffect(() => {
		if((consumerProductSaveData.ProductSubCategory && consumerProductSaveData.ProductSubCategory.length > 0) 
		&& (consumerProductSaveData.BrandName && consumerProductSaveData.BrandName.length > 0)){
			setDisableProduct(false)
		}else{
			setDisableProduct(true)
		}
	}, [consumerProductSaveData])
	
	useEffect(() => {
		if(documentsList && documentsList.length){
			setConsumerProductSaveData((prevData) => {
				return {
					...prevData,
					['uploadDocumentsDropdown'] : documentsList[0].DocumentType
					// [''] : val.  
				};
			});
		}
	}, [documentsList])
	

	const loadCategories = () => {
		setLoader(true);
		apiCall({
			url:'getRequestCategories',
			data: {},
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let data = result.data.map(v => ({...v, value: v.ProductCategory}))
					setCategoryData(data);
					setLoaded({...loaded,categoriesLoaded:true})
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const loadSubCategories = () => {
		let data={
			qFilter: {
                CountryID: (consumerDetails && consumerDetails.CountryID)
            },
            CountryID: (consumerDetails && consumerDetails.CountryID)
		};
		if(consumerProductSaveData.ProductCategoryID){
			data.qFilter.categories = [consumerProductSaveData.ProductCategoryID]
		}
		setLoader(true);
		apiCall({
			url:'getSubcategory',
			data: {},
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let data = result.data.map(v => ({...v, value: v.ProductSubCategory}))
					setSubcategoryData(data);
					setLoaded({...loaded,subCategoryLoaded:true})
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const loadBrands = () => {
		setLoader(true);
		let data = {
			qFilter:{}
		}
		if(consumerProductSaveData.ProductSubCategoryID){
			data.qFilter.productSubCategories = [consumerProductSaveData.ProductSubCategoryID]
		}
		apiCall({
			url:'GetBrands',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let data = result.data.map(v => ({...v, value: v.BrandName}))
					setbrandData(data);
					setLoaded({...loaded,brandLoaded:true})
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const loadProducts = () => {
		setLoader(true);
		let data = {
			qFilter: {
                CountryID: (consumerDetails && consumerDetails.CountryID)
            },
            CountryID: (consumerDetails && consumerDetails.CountryID)
		}
		if(consumerProductSaveData.BrandID){
			data.qFilter.brands = [consumerProductSaveData.BrandID]
		}
		if(consumerProductSaveData.ProductSubCategoryID){
			data.qFilter.productSubCategories = [consumerProductSaveData.ProductSubCategoryID]
		}
		apiCall({
			url:'GetProducts',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let data = result.data.map(v => ({...v, value: v.ProductName}))
					setProductData(data);
					setLoaded({...loaded,productLoaded:true});
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const isSaveDisabled = () => {
		let minLen = 0;
		let	maxLen = Infinity;
		if (brandAndSubCat.brandNarwal) {
			minLen = 19;
			maxLen = 19;
		} else if (brandAndSubCat.brandLG) {
			minLen = 6;
			maxLen = 30;
		}
		if ( //check for imei
			consumerProductSaveData.ProductUniqueID &&
			consumerProductSaveData.ProductUniqueID.length > 0
		) {
			if (
				consumerProductSaveData.ProductUniqueID.length < minLen ||
				consumerProductSaveData.ProductUniqueID.length > maxLen
			) {
				return true;
			}
		}
		if ( //check for imei2
			consumerProductSaveData.AlternateUniqueKey &&
			consumerProductSaveData.AlternateUniqueKey.length > 0
		) {
			if (
				consumerProductSaveData.AlternateUniqueKey.length < minLen ||
				consumerProductSaveData.AlternateUniqueKey.length > maxLen
			) {
				return true;
			}
		}
		return false;
	};

	let consumerProductInputLabels = [
		{
			labelText:localString('Product Category'),
			placeholder:localString('Product Category'),
			dataKey:"ProductCategory",
			dropdownList:categoryData,
			type:'Dropdown',
			showFilter:(unRegistered ? false : (JSON.parse(qFilter) && JSON.parse(qFilter).flowType && JSON.parse(qFilter).flowType.requestFulfilmentFlow && (JSON.parse(qFilter).flowType.requestFulfilmentFlow.indexOf('REQ_FULFIL_SKYWORTH')>-1) ? true :false)),
			customClass:'col-sm-6 paddingB10',
			oninputFunc: (val) => {
				setConsumerProductSaveData((prevData) => {
					return {
						...prevData,
						['ProductCategory']:val.ProductCategory,
						['ProductCategoryID']:val.ProductCategoryID,
						['ProductSubCategoryID'] : "",
						['ProductSubCategory']:"",
						['BrandID'] : "",
						['BrandName']:"",
						['ProductID'] : "",
						['ProductName']:""
					};
				});
			},
			oninputFocus: () => {
				if(!loaded.subCategoryLoaded){
					loadCategories();
				}
			},
		},
		{
			labelText:localString('Product SubCategory')+"*",
			placeholder:localString('Product SubCategory'),
			dataKey:"ProductSubCategory",
			isDisabled: editMode && (brandAndSubCat && (brandAndSubCat.brandNarwal || brandAndSubCat?.brandLG) ? (consumerProduct?.ProductSubcategory?.ProductSubCategory ?? disableProduct) : disableProduct ),
			dropdownList:subcategoryData,
			showFilter:true,
			type :'Dropdown',
			dropdownList:subcategoryData,
			customClass:'col-sm-6 paddingB10',
			oninputFunc: (val) => {
				setConsumerProductSaveData((prevData) => {
					return {
						...prevData,
						['ProductSubCategoryID'] : val.ProductSubCategoryID,
						['ProductSubCategory']:val.ProductSubCategory,
						['BrandID'] : "",
						['BrandName']:"",
						['ProductID'] : "",
						['ProductName']:""
					};
				});
				setLoaded({...loaded,brandLoaded:false,productLoaded:false})
			},
			oninputFocus: () => {
				if(!loaded.subCategoryLoaded){
					loadSubCategories();
				}
			},
		},
		{
			labelText:localString('Brand')+"*",
			placeholder:localString('Brand'),
			dataKey:"BrandName",
			isDisabled: editMode && (brandAndSubCat && (brandAndSubCat.brandNarwal || brandAndSubCat?.brandLG) ? (consumerProduct?.BrandName ?? disableProduct) : disableProduct) ,
			showFilter:true,
			type :'Dropdown',
			dropdownList:brandData,
			customClass:'col-sm-6 paddingB10',
			oninputFunc: (val) => {
				setConsumerProductSaveData((prevData) => {
					return {
						...prevData,
						['BrandID'] : val.BrandID,
						['BrandName']: val.BrandName,
						['ProductID'] : "",
						['ProductName']: ""
					};
				});
				setLoaded({...loaded,productLoaded:false})
			},
			oninputFocus: () => {
				if(!loaded.brandLoaded){
					loadBrands();
				}
			},
		},
		{
			labelText:localString('Product Name')+"*",
			placeholder:localString('Product Name'),
			dataKey:"ProductName",
			isDisabled:  editMode && ( brandAndSubCat && (brandAndSubCat.brandNarwal || brandAndSubCat.brandLG) ? (consumerProduct?.ProductName ?? disableProduct) : disableProduct),
			showFilter:true,
			type :'Dropdown',
			dropdownList:productData,
			customClass:'col-sm-6 paddingB10',
			oninputFunc: (val) => {
				setConsumerProductSaveData((prevData) => {
					return {
						...prevData,
						['ProductID']: val.ProductID,
						['ProductName']: val.ProductName
					};
				});
			},
			oninputFocus: () => {
				if(!loaded.productLoaded){
					loadProducts();
				}
			},
		},
		{
			labelText:localString('Tag Name'),
			placeholder:localString('Tag Name'),
			dataKey:"TagName",
			type:"input",
			showFilter:true,
			customClass:'col-sm-6 paddingB10',
			oninputFunc: (val) => {
				setConsumerProductSaveData((prevData) => {
					return {
						...prevData,
						['TagName'] : val
					};
				});
			}
		},
		{
			labelText:localString('Model No'),
			placeholder:localString('Model No'),
			dataKey:"ModelNo",
			type:"input",
			isDisabled:  editMode && ( brandAndSubCat && (brandAndSubCat.brandNarwal || brandAndSubCat.brandLG) ? (consumerProduct?.ModelNo ?? disableProduct) : disableProduct),
			showFilter:true,
			customClass:'col-sm-6 paddingB10',
			oninputFunc: (val) => {
				setConsumerProductSaveData((prevData) => {
					return {
						...prevData,
						['ModelNo'] : val
					};
				});
			}
		},
		{
			labelText:brandAndSubCat?.brandNarwal
				? localString("Robot Serial Number")
				: brandAndSubCat?.brandLG
				? localString("Serial Number")
				: localString("IMEI / Serial No."),
			placeholder:  brandAndSubCat?.brandNarwal
				? localString("Robot Serial Number")
				: brandAndSubCat?.brandLG
				? localString("Serial Number")
				: localString("IMEI / Serial No."),
			dataKey:"ProductUniqueID",
			type:"input",
			maxLength:
				brandAndSubCat?.brandNarwal && 19 ||
				brandAndSubCat?.brandLG && 30 ||
				"",
			minLength:
				brandAndSubCat?.brandNarwal && 19 ||
				brandAndSubCat?.brandLG && 6 ||
				"",
			showFilter:true,
			isDisabled:  editMode && ( brandAndSubCat && (brandAndSubCat.brandNarwal || brandAndSubCat?.brandLG) ? (consumerProduct?.ProductUniqueID ?? disableProduct) : disableProduct) ,
			customClass:'col-sm-6 paddingB10',
			oninputFunc: (val) => {
				if (val.length > 0 && !checkAlphaNumeric(val)) {
					return;
				}
				setConsumerProductSaveData((prevData) => {
					return {
						...prevData,
						['ProductUniqueID'] : val
					};
				});
			}
		},
		{
			labelText: brandAndSubCat && brandAndSubCat.brandNarwal && localString("Charging Dock Serial Number"),
			placeholder: brandAndSubCat && brandAndSubCat.brandNarwal &&  localString("Charging Dock Serial Number") ,
			dataKey:"AlternateUniqueKey",
			type:"input",
			showFilter:  brandAndSubCat && brandAndSubCat.brandNarwal ,
			isDisabled:   editMode && (brandAndSubCat && brandAndSubCat.brandNarwal ? (consumerProduct?.AlternateUniqueKey ?? disableProduct) : disableProduct) ,
			customClass:'col-sm-6 paddingB10',
			maxLength:19,		
			minLength:19,
			oninputFunc: (val) => {	
				if (val.length > 0 && !checkAlphaNumeric(val)) {
					return;
				}
				setConsumerProductSaveData((prevData) => {
					return {
						...prevData,
						['AlternateUniqueKey'] : val
					};
				});
			}
		},
		{
			labelText:localString("Year Of Purchase")+"*",
			placeholder:localString('Year Of Purchase'),
			dataKey:"YearOfPurchase",
			isDisabled:  editMode && ( brandAndSubCat && (brandAndSubCat.brandNarwal || brandAndSubCat.brandLG) ? (consumerProduct?.YearOfPurchase ?? disableProduct) : disableProduct),
			type:"input",
			showFilter:true,
			customClass:'col-sm-6 paddingB10',
			oninputFunc: (val) => {
				setConsumerProductSaveData((prevData) => {
					return {
						...prevData,
						['YearOfPurchase'] : val
					};
				});
			}
		},
		{
			labelText:localString("Date Of Purchase"),
			placeholder:localString("Date Of Purchase"),
			dataKey:"DateOfPurchase", 
			isDisabled:  (editMode &&  (brandAndSubCat?.brandNarwal || brandAndSubCat?.brandLG)) ? true : false ,
			showFilter:(unRegistered ?false :true),
			type:"DatePicker",
			customClass: (brandAndSubCat?.brandNarwal || brandAndSubCat?.brandLG) ? 'col-sm-6 paddingB10 dateinput-disabled' : "col-sm-6 paddingB10",
			oninputFunc: (val) => {
				setConsumerProductSaveData((prevData) => {
					return {
						...prevData,
						['DateOfPurchase'] : (getLocalDateTime(val,'YYYY-MM-DD'))
					};
				});
			}
		},
		{
			labelText:localString("Warranty Till"),
			placeholder:localString("Warranty Till"),
			dataKey:"WarrantyTill",
			isDisabled:  (editMode &&  (brandAndSubCat?.brandNarwal || brandAndSubCat?.brandLG)) ? true : false ,
			showFilter:true,
			type:"DatePicker",
			customClass: (brandAndSubCat?.brandNarwal || brandAndSubCat?.brandLG) ? 'col-sm-6 paddingB10 dateinput-disabled' : "col-sm-6 paddingB10",
			oninputFunc: (val) => {
				setConsumerProductSaveData((prevData) => {
					return {
						...prevData,
						['WarrantyTill'] : (getLocalDateTime(val,'YYYY-MM-DD'))  
					};
				});
			}
		},
		{
			labelText:localString("Upload Documents"),
			dataKey:"uploadDocumentsDropdown",
			showFilter: editMode ? (unRegistered ? false : true) : false,
			customClass:'col-sm-3 paddingB10',
			type:"Dropdown",
			dropdownList: documentsList,
			oninputFunc: (val) => {
				setConsumerProductSaveData((prevData) => {
					return {
						...prevData,
						['uploadDocumentsDropdown'] : val.DocumentType,
					};
				});
			}
		},
		{
			labelText:localString(""),
			showLabel:true,
			dataKey:"uploadDocuments",
			showFilter: editMode ? (unRegistered ? false : true) : false,
			customClass:'col-sm-3 paddingB10 consumer-file-upload-section',
			type:"File",
			acceptFileType: ".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv",
			oninputFunc: (e) => {
				const file = e.target.files;
				setUploadedDocDetails(file)
			}
		}
	];

	return (
		<div>
			{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="col-sm-12 paddingTB15">
				<FilterInputs {...props} saveButtonText={editMode?"Save":"Add"} disableApplyBtn={isSaveDisabled()} cancelButtonText="Cancel" filterFunc={saveFunction} clearSearch={cancelProduct} filterInputs={consumerProductInputLabels} filterData={consumerProductSaveData}></FilterInputs>
			</div>
		</div>
	) 
}

export default AddUpdateConsumerProduct;