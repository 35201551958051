import React, { useEffect, useState } from "react";
import { Loader, Button } from 'sfy-react';
import ReactDOM from 'react-dom';
import Swal from "sweetalert2";
import PageHeader from "../../components/PageHeader/PageHeader";
import RequestBulkUploadGrid from "./RequestBulkUploadGrid";
import Tab from "./Tabs";
import { displayError } from "../../Utility/Utils";
import './RequestBulkUpload.scss'

const RequestBulkUpload = (props) => {
	const { accessFn, localString, titleHeader, publicUrl, apiCall,userID } = props;
	const qFilter = JSON.parse(props.qFilter)
	if(!(qFilter.hasUnrestrictedAccess || accessFn('BULK_UPLOAD_DOWNLOAD'))) window.location.href = '/401';
	
	const [templateList, setTemplateList] = useState([]);
	const [templateId,setTemplateId] = useState(null)
	const [bulkUploadList, setBulkUploadList] = useState([]);
	const [searchText,setSearchText]= useState('')
	const [showLoader, setLoader] = useState(false);
	const [selectedValue, setSelectedValue] = useState(null);
	const [pagination , setPagination] = useState({
		pageNo : 1,
		noOfRecords : 20,
		numberOfPages: 0,
		count: 0,
	});
	const [activeTab, setActiveTab] = useState('actual_cost');
	const [importTypes,setImportTypes] = useState(null)
	const [firstLoad,setFirstLoad] = useState(true)
	useEffect(()=>{
		if( searchText && searchText.length>2){
			getFileStatus(1,importTypes)
		}else if(searchText=='' && firstLoad){
			getFileStatus(1,importTypes)
		}
	},[searchText,selectedValue])

	const getFileStatus = (pageNo,importType) => {
		let data = {
			"importType":importType ? importType:1,
			"userID": userID,
			"pagination":{
				noOfRecords:pagination.noOfRecords,
				pageNo: pageNo ? pageNo :pagination.pageNo
			},
		}
		if(searchText.length>2 && importType==importTypes){
			data.searchText=searchText
		}
		if(selectedValue == 'sortlatest'  && importType==importTypes ){
			data.order ={
			"fieldName":"CreatedDate",
			// "orderBy":"ASC"
			}
		}else if(selectedValue=='recordshigh' && importType==importTypes){
			data.order ={
			"fieldName":"TotalCount",
			"orderBy":"DESC"
			}
		}else if(selectedValue=='recordslow' && importType==importTypes){
			data.order ={
			"fieldName":"TotalCount",
			"orderBy":"ASC"
			}
		}
		setLoader(true);
		apiCall({
			url: 'getFileStatus',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if(result.success) {
					setFirstLoad(true)
					setBulkUploadList(result.data && result.data.importResults);
					setTemplateId(result.data.template)
					let totalCount = result.data.totalNoOfRecords;
					let numberOfpage = 0;
					numberOfpage = Math.ceil(totalCount / pagination.noOfRecords);
					setPagination((prevFilterData) => {
						return {
							...prevFilterData,
							numberOfPages:numberOfpage,
							count:result.data.totalNoOfRecords,
						};
					});
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const clearSearch =()=>{
		setSearchText('')
		setFirstLoad(true)
		setPagination({
			...pagination,
			pageNo : 1,
		})
	}

	return (
		<div className='request-bulkUpload'>
			{
				(showLoader) &&
					ReactDOM.createPortal(
				<Loader />,
					document.getElementById("loader-root")
				)
			}
			<PageHeader titleHeader={titleHeader} 
				localString={localString}
				btnClass='btn button-servify header-btn button-stack text-capital' 
			/>
			<div className='container-fluid bulkUploadContainer'>
				{/* Tab Navigation */}
				<div className="tab-navigation pb20">
					<Tab 
						{...props}
						activeTab = {activeTab}
						setActiveTab ={setActiveTab}
						setImportType={setImportTypes}
						importType = {importTypes}
						getFileStatus={getFileStatus}
						setSearchText={setSearchText}
						setSelectedValue ={setSelectedValue}
						setFirstLoad={setFirstLoad}
						setPagination={setPagination}
						pagination={pagination}
					// tabs={tabs} 
					// defaultTab={activeTab} 
					// onTabChange={handleTabChange} 
					/>
				</div>
				<div className='card'>
					{/* Tab Content */}
					<div className="tab-content">
						{/* Grid Tab Content */}
						{/* {activeTab === "Actual Cost" && ( */}
						<div className="grid-tab-content">
							<RequestBulkUploadGrid {...props}
								publicUrl={publicUrl} setLoader={setLoader}
								localString={localString}
								bulkUploadList={bulkUploadList}
								pagination={pagination}
								setPagination={setPagination}
								templateList={templateList}
								setTemplateList={setTemplateList} 
								searchText={searchText}
								setSearchText={setSearchText}
								selectedValue = {selectedValue}
								setSelectedValue = {setSelectedValue}
								clearSearch = {clearSearch}
								getFileStatus={getFileStatus}
								templateId = {templateId}
								activeTab = {activeTab}
								setActiveTab ={setActiveTab}
								importType={importTypes}
								setFirstLoad={setFirstLoad}
							/>
						</div>
						{/* )} */}

						{/* Other Tab Content
						{activeTab === "otherTab" && (
						<div className="other-tab-content">
							{/* Add the content for the other tab */}
							{/* ... */}
						{/* </div> */} 
						{/* )} */}

						{/* Add more tab content sections as needed */}
					</div>
				</div>
			</div>
</div>
	);
}

export default RequestBulkUpload;