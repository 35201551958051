import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import { Loader } from "sfy-react";
import {displayError} from "../../../../../Utility/Utils";
import PageContentWrapper from "../../../../../components/PageContentWrapper/PageContentWrapper";
import "./DispositionDetails.scss"

const DispositionDetails = (props) => {
	const { apiCall, consumerServiceRequest, localString , qFilter,accessFn} = props;
	const [showLoader, setShowLoader] = useState(false);
	const [dispositionDetails, setDispositionDetails] = useState();
	const [issueText, setIssueText] = useState("");
	const getDispositionDetails = () => {
		const data = {
			consumerServiceRequestID:
				consumerServiceRequest.ConsumerServiceRequestID,
		};
		setShowLoader(true);
		apiCall({
			url: "getCSRDispositionDetails",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success && result.data) {
					setDispositionDetails(result.data);
					// let issueTextarr = result.data.issues.map((record)=>{
					// 	return record.issueText;
					// })
					// setIssueText(issueTextarr.join(', '));
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	useEffect(() => {
		getDispositionDetails();
	}, []);
	if (showLoader)
		return ReactDOM.createPortal(
			<Loader />,
			document.getElementById("loader-root")
		);

	return (
		<div className="padding10  disposition-section">
			<div className="row disposition-detail-section">
				<PageContentWrapper title={localString("Disposition Details")}>
					{dispositionDetails && dispositionDetails.disposition ?(<>
                            <div className="col-sm-12">
                                <div className="col-sm-12 row padding12">
                                    <label>{localString("DISPOSITION")} <label className="dispoition-label">{dispositionDetails.disposition}</label></label>
                                </div>
                            </div>
							<div className="col-sm-12">
								<div className="col-sm-2">
									<label>{localString("Disposition Code")}</label><p>{dispositionDetails.dispositionCode || '-'}</p>
								</div>
								<div className="col-sm-2">
									<label>{localString("Remark")} </label><p>{dispositionDetails?.remarks || '-'}</p>
								</div>
								<div className="col-sm-2">
									<label>{localString("Coverage Type")}</label> <p>{dispositionDetails?.coverageType || '-'}</p>
								</div>
								<div className="col-sm-4">
									<label>{localString("Issues")} </label><p>{dispositionDetails?.issues || '-'}</p>
								</div>
							</div>
							<div className="col-sm-12 row ">
								<label className="padding12">{localString("DEFECTIVE DEVICE")} </label>
							</div>
							<div className="col-sm-12">
								<div className="col-sm-2">
									<label>{localString("Brand Code")}</label><p>{dispositionDetails?.defectiveDevice?.brandCode || '-'}</p>
								</div>
								<div className="col-sm-2">
									<label>{localString("Device SKU")} </label><p>{dispositionDetails?.defectiveDevice?.deviceSKU || '-'}</p>
								</div>
								<div className="col-sm-2">
									<label>{localString("Model Name")}</label> <p>{dispositionDetails?.defectiveDevice?.modelName || '-'}</p>
								</div>
								<div className="col-sm-2">
									<label>{localString("Sub-category Code")} </label><p>{dispositionDetails?.defectiveDevice?.productSubCategoryCode || '-'}</p>
								</div>
								<div className="col-sm-2">
									<label>{localString("Product Unique ID")} </label><p>{dispositionDetails?.defectiveDevice?.productUniqueID || '-'}</p>
								</div>
							</div>
							<div className="col-sm-12 row">
								<label className="padding12">{localString("IMAGES")} </label>
							</div>
							<div className="col-sm-12 paddingB10">
								{
									dispositionDetails?.images
									? dispositionDetails?.images?.map((item)=>{
										return (
											<div className="col-sm-1">
												<a href={item.url} target="_blank">
													<img src={item.url} height="90" width="90" />
												</a>
											</div>
										)
									}):<div className="text-left">
										<p className="no-data remove-margin">
											{localString("Images not available")}
										</p>
									</div>
								}
							</div>
						</>
						)
					: (
						<div className="text-left">
							<p className="no-data remove-margin">
								{localString("Disposition details not available")}
							</p>
						</div>
                    )}
				</PageContentWrapper>
			</div>
		</div>
	);
};

export default DispositionDetails;
