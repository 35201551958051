const lang = {
	"Consumers": {
		"text": "Consumatori",
		"isAlreadyTranslated": true
	},
	"Clear Search": {
		"text": "Ștergere căutare",
		"isAlreadyTranslated": true
	},
	"Filter Consumer": {
		"text": "Filtrare consumator",
		"isAlreadyTranslated": true
	},
	"Add Consumers": {
		"text": "Adăugare consumatori",
		"isAlreadyTranslated": true
	},
	"Mobile": {
		"text": "Mobil",
		"isAlreadyTranslated": true
	},
	"Device Type": {
		"text": "Tip dispozitiv",
		"isAlreadyTranslated": true
	},
	"Mobile No.": {
		"text": "Nr. de mobil",
		"isAlreadyTranslated": true
	},
	"Model": {
		"text": "Model",
		"isAlreadyTranslated": true
	},
	"Alternate No.": {
		"text": "Nr. alternativ",
		"isAlreadyTranslated": true
	},
	"Landline No": {
		"text": "Nr. telefon fix",
		"isAlreadyTranslated": true
	},
	"View Consumer": {
		"text": "Vizualizare consumator",
		"isAlreadyTranslated": true
	},
	"Add": {
		"text": "Adăugare",
		"isAlreadyTranslated": true
	},
	"Send": {
		"text": "Trimitere",
		"isAlreadyTranslated": true
	},
	"SMS": {
		"text": "SMS",
		"isAlreadyTranslated": true
	},
	"Email": {
		"text": "E-mail",
		"isAlreadyTranslated": true
	},
	"First": {
		"text": "Primul",
		"isAlreadyTranslated": true
	},
	"Last": {
		"text": "Ultimul",
		"isAlreadyTranslated": true
	},
	"Filter": {
		"text": "Filtru",
		"isAlreadyTranslated": true
	},
	"Customer": {
		"text": "Client",
		"isAlreadyTranslated": true
	},
	"Close": {
		"text": "Închidere",
		"isAlreadyTranslated": true
	},
	"Call": {
		"text": "Apelare",
		"isAlreadyTranslated": true
	},
	"logs": {
		"text": "jurnale",
		"isAlreadyTranslated": true
	},
	"Consumer": {
		"text": "Consumator",
		"isAlreadyTranslated": true
	},
	"Subcategory": {
		"text": "Subcategorie",
		"isAlreadyTranslated": true
	},
	"Brand": {
		"text": "Marcă",
		"isAlreadyTranslated": true
	},
	"Product": {
		"text": "Produs",
		"isAlreadyTranslated": true
	},
	"Apply": {
		"text": "Aplicare",
		"isAlreadyTranslated": true
	},
	"Dashboard": {
		"text": "Tablou de bord",
		"isAlreadyTranslated": true
	},
	"Requests": {
		"text": "Solicitări",
		"isAlreadyTranslated": true
	},
	"View Document": {
		"text": "Vizualizare document",
		"isAlreadyTranslated": true
	},
	"Logout": {
		"text": "Deconectare",
		"isAlreadyTranslated": true
	},
	"Notifications": {
		"text": "Notificări",
		"isAlreadyTranslated": true
	},
	"Coupons": {
		"text": "Cupoane",
		"isAlreadyTranslated": true
	},
	"UserList": {
		"text": "UserList",
		"isAlreadyTranslated": true
	},
	"Curate": {
		"text": "Curate",
		"isAlreadyTranslated": true
	},
	"Diagnosis": {
		"text": "Diagnostic",
		"isAlreadyTranslated": true
	},
	"Distributor": {
		"text": "Distribuitor",
		"isAlreadyTranslated": true
	},
	"Enterprise Sales": {
		"text": "Vânzări pentru întreprinderi",
		"isAlreadyTranslated": true
	},
	"Reseller": {
		"text": "Revânzător",
		"isAlreadyTranslated": true
	},
	"Plan Management": {
		"text": "Gestionare plan",
		"isAlreadyTranslated": true
	},
	"Sell Plans": {
		"text": "Vânzare de planuri",
		"isAlreadyTranslated": true
	},
	"Partner Management": {
		"text": "Gestionare parteneri",
		"isAlreadyTranslated": true
	},
	"Logistics Requests": {
		"text": "Solicitări de logistică",
		"isAlreadyTranslated": true
	},
	"Catalogue": {
		"text": "Catalog",
		"isAlreadyTranslated": true
	},
	"Source": {
		"text": "Sursă",
		"isAlreadyTranslated": true
	},
	"Device Count": {
		"text": "Număr de dispozitive",
		"isAlreadyTranslated": true
	},
	"Reg/Unreg": {
		"text": "Înregistrare/Anulare înregistrare",
		"isAlreadyTranslated": true
	},
	"Phone Number": {
		"text": "Număr de telefon",
		"isAlreadyTranslated": true
	},
	"Mobile No": {
		"text": "Nr. mobil",
		"isAlreadyTranslated": true
	},
	"Edit": {
		"text": "Editare",
		"isAlreadyTranslated": true
	},
	"View Request": {
		"text": "Vizualizare solicitare",
		"isAlreadyTranslated": true
	},
	"Raise A Request": {
		"text": "Adresați o solicitare",
		"isAlreadyTranslated": true
	},
	"Bills/Documents": {
		"text": "Facturi/Documente",
		"isAlreadyTranslated": true
	},
	"Explore Device Lifecycle": {
		"text": "Explorare ciclu de viață al dispozitivului",
		"isAlreadyTranslated": true
	},
	"History": {
		"text": "Istoric",
		"isAlreadyTranslated": true
	},
	"Year Of Purchase": {
		"text": "Anul achiziției",
		"isAlreadyTranslated": true
	},
	"Date Of Purchase": {
		"text": "Data achiziției",
		"isAlreadyTranslated": true
	},
	"IMEI": {
		"text": "IMEI",
		"isAlreadyTranslated": true
	},
	"Serial No.:": {
		"text": "Număr de serie:",
		"isAlreadyTranslated": true
	},
	"Initiate diagnosis": {
		"text": "Inițiere diagnostic",
		"isAlreadyTranslated": true
	},
	"Initiate diagnosis directly in the Servify App": {
		"text": "Inițiați diagnosticul direct în aplicația Servify",
		"isAlreadyTranslated": true
	},
	"No diagnosis performed": {
		"text": "Nu s-a efectuat niciun diagnostic",
		"isAlreadyTranslated": true
	},
	"View Diagnostic Result": {
		"text": "Vizualizați rezultatul diagnosticului",
		"isAlreadyTranslated": true
	},
	"Last Diagnosis Performed on": {
		"text": "Ultimul diagnostic efectuat la",
		"isAlreadyTranslated": true
	},
	"No Issues Found": {
		"text": "Nu s-au găsit probleme",
		"isAlreadyTranslated": true
	},
	"Found": {
		"text": "Găsit",
		"isAlreadyTranslated": true
	},
	"Issue": {
		"text": "Problemă",
		"isAlreadyTranslated": true
	},
	"Created Date": {
		"text": "Data creării",
		"isAlreadyTranslated": true
	},
	"Model No": {
		"text": "Nr. model",
		"isAlreadyTranslated": true
	},
	"Tag Name": {
		"text": "Nume etichetă",
		"isAlreadyTranslated": true
	},
	"Product Subcategory": {
		"text": "Subcategorie de produs",
		"isAlreadyTranslated": true
	},
	"Created On": {
		"text": "Creat la",
		"isAlreadyTranslated": true
	},
	"Alternate Mobile No": {
		"text": "Nr. mobil alternativ",
		"isAlreadyTranslated": true
	},
	"Add Product": {
		"text": "Adăugare produs",
		"isAlreadyTranslated": true
	},
	"Upload Documents": {
		"text": "Încărcați documente",
		"isAlreadyTranslated": true
	},
	"Product Name": {
		"text": "Denumire produs",
		"isAlreadyTranslated": true
	},
	"Product SubCategory": {
		"text": "Subcategorie de produs",
		"isAlreadyTranslated": true
	},
	"View Consumer Details": {
		"text": "Vizualizare detalii despre consumatori",
		"isAlreadyTranslated": true
	},
	"Call History": {
		"text": "Istoric apeluri",
		"isAlreadyTranslated": true
	},
	"Unregistered Products": {
		"text": "Produse neînregistrate",
		"isAlreadyTranslated": true
	},
	"Registered Products": {
		"text": "Produse înregistrate",
		"isAlreadyTranslated": true
	},
	"No unregistered products found": {
		"text": "Nu s-au găsit produse neînregistrate",
		"isAlreadyTranslated": true
	},
	"Content": {
		"text": "Conținut",
		"isAlreadyTranslated": true
	},
	"Customer Name": {
		"text": "Nume client",
		"isAlreadyTranslated": true
	},
	"Reference ID": {
		"text": "ID referință",
		"isAlreadyTranslated": true
	},
	"Scheduled Date": {
		"text": "Dată programată",
		"isAlreadyTranslated": true
	},
	"Type": {
		"text": "Tip",
		"isAlreadyTranslated": true
	},
	"Add call log": {
		"text": "Adăugați jurnalul de apeluri",
		"isAlreadyTranslated": true
	},
	"Call type": {
		"text": "Tip de apel",
		"isAlreadyTranslated": true
	},
	"Remarks": {
		"text": "Observații",
		"isAlreadyTranslated": true
	},
	"No call history": {
		"text": "Fără istoric apeluri",
		"isAlreadyTranslated": true
	},
	"Please enter alphabets only": {
		"text": "Introduceți numai caractere alfabetice",
		"isAlreadyTranslated": true
	},
	"Please enter correct EmailID": {
		"text": "Introduceți EmailID corect",
		"isAlreadyTranslated": true
	},
	"Please enter correct Name": {
		"text": "Introduceți numele corect",
		"isAlreadyTranslated": true
	},
	"Please enter correct Alternate Mobile Number": {
		"text": "Introduceți numărul de mobil alternativ corect",
		"isAlreadyTranslated": true
	},
	"Incoming": {
		"text": "Intrare",
		"isAlreadyTranslated": true
	},
	"Outgoing": {
		"text": "Ieșire",
		"isAlreadyTranslated": true
	},
	"No": {
		"text": "Nu",
		"isAlreadyTranslated": true
	},
	"Call history": {
		"text": "Istoric apeluri",
		"isAlreadyTranslated": true
	},
	"No Data Available": {
		"text": "Nu există date disponibile",
		"isAlreadyTranslated": true
	},
	"Select a model": {
		"text": "Selectați un model",
		"isAlreadyTranslated": true
	},
	"Customer Alternate Mobile No": {
		"text": "Nr. mobil alternativ al clientului",
		"isAlreadyTranslated": true
	},
	"Incident ID": {
		"text": "ID incident",
		"isAlreadyTranslated": true
	},
	"Select an issue": {
		"text": "Selectați o problemă",
		"isAlreadyTranslated": true
	},
	"Please enter Customer's Name": {
		"text": "Introduceți numele clientului",
		"isAlreadyTranslated": true
	},
	"Please select a model from the dropdown": {
		"text": "Selectați un model din lista verticală",
		"isAlreadyTranslated": true
	},
	"Please enter at least one issue": {
		"text": "Introduceți cel puțin o problemă",
		"isAlreadyTranslated": true
	},
	"Please select a Device Type": {
		"text": "Selectați un tip de dispozitiv",
		"isAlreadyTranslated": true
	},
	"Please enter Mobile Number": {
		"text": "Introduceți numărul de telefon mobil",
		"isAlreadyTranslated": true
	},
	"Please enter correct Mobile Number": {
		"text": "Introduceți numărul de telefon mobil corect",
		"isAlreadyTranslated": true
	},
	"Please enter correct E-mail ID": {
		"text": "Introduceți ID-ul de e-mail corect",
		"isAlreadyTranslated": true
	},
	"Consumer successfully added and SMS sent": {
		"text": "Consumator adăugat cu succes și SMS trimis",
		"isAlreadyTranslated": true
	},
	"Something went wrong when trying to create a consumer. Please try again": {
		"text": "Ceva nu a funcționat corect când ați încercat să creați un consumator. Încercați din nou",
		"isAlreadyTranslated": true
	},
	"You have already added this issue type": {
		"text": "Ați adăugat deja acest tip de problemă",
		"isAlreadyTranslated": true
	},
	"Please select a Product from the dropdown": {
		"text": "Selectați un produs din lista verticală",
		"isAlreadyTranslated": true
	},
	"Please select a Brand from the dropdown": {
		"text": "Selectați o marcă din lista verticală",
		"isAlreadyTranslated": true
	},
	"Please select a Subcategory from the dropdown": {
		"text": "Selectați o subcategorie din lista verticală",
		"isAlreadyTranslated": true
	},
	"Select a year between": {
		"text": "Selectați un an între",
		"isAlreadyTranslated": true
	},
	"and": {
		"text": "și",
		"isAlreadyTranslated": true
	},
	"Please select a document type": {
		"text": "Selectați un tip de document",
		"isAlreadyTranslated": true
	},
	"Please select a document": {
		"text": "Selectați un document",
		"isAlreadyTranslated": true
	},
	"Oops... Something went wrong": {
		"text": "Ups... Ceva nu a funcționat corect",
		"isAlreadyTranslated": true
	},
	"No requests found": {
		"text": "Nu s-au găsit solicitări",
		"isAlreadyTranslated": true
	},
	"OK": {
		"text": "OK",
		"isAlreadyTranslated": true
	},
	"REQUESTS": {
		"text": "SOLICITĂRI",
		"isAlreadyTranslated": true
	},
	"Claim Request": {
		"text": "Solicitare despăgubire",
		"isAlreadyTranslated": true
	},
	"In Progress": {
		"text": "În desfășurare",
		"isAlreadyTranslated": true
	},
	"Closed": {
		"text": "Închis",
		"isAlreadyTranslated": true
	},
	"Cancelled": {
		"text": "Anulat",
		"isAlreadyTranslated": true
	},
	"Open": {
		"text": "Deschis",
		"isAlreadyTranslated": true
	},
	"Period": {
		"text": "Perioadă",
		"isAlreadyTranslated": true
	},
	"Filter Requests": {
		"text": "Filtrare solicitări",
		"isAlreadyTranslated": true
	},
	"Create Bulk Request": {
		"text": "Creare solicitare în bloc",
		"isAlreadyTranslated": true
	},
	"Raise a request": {
		"text": "Adresați o solicitare",
		"isAlreadyTranslated": true
	},
	"External Requests": {
		"text": "Solicitări externe",
		"isAlreadyTranslated": true
	},
	"Parts Queue": {
		"text": "Coadă piese",
		"isAlreadyTranslated": true
	},
	"Loading requests": {
		"text": "Încărcare solicitări",
		"isAlreadyTranslated": true
	},
	"Service type": {
		"text": "Tip de service",
		"isAlreadyTranslated": true
	},
	"Schedule": {
		"text": "Planificare",
		"isAlreadyTranslated": true
	},
	"Download requests in csv format": {
		"text": "Descărcare solicitări în format csv",
		"isAlreadyTranslated": true
	},
	"Created": {
		"text": "Creat",
		"isAlreadyTranslated": true
	},
	"Show graph": {
		"text": "Afișare grafic",
		"isAlreadyTranslated": true
	},
	"Hide graph": {
		"text": "Ascundere grafic",
		"isAlreadyTranslated": true
	},
	"out of": {
		"text": "din",
		"isAlreadyTranslated": true
	},
	"service requests are shown above.": {
		"text": "solicitările de service sunt prezentate mai sus.",
		"isAlreadyTranslated": true
	},
	"Created or found consumer": {
		"text": "Consumator creat sau găsit",
		"isAlreadyTranslated": true
	},
	"Not updated": {
		"text": "Neactualizat",
		"isAlreadyTranslated": true
	},
	"Please select a call disposition": {
		"text": "Selectați o dispoziție pentru apel",
		"isAlreadyTranslated": true
	},
	"Please enter a name": {
		"text": "Introduceți un nume",
		"isAlreadyTranslated": true
	},
	"Please enter remarks": {
		"text": "Introduceți observații",
		"isAlreadyTranslated": true
	},
	"Username": {
		"text": "Nume utilizator",
		"isAlreadyTranslated": true
	},
	"Call Connected": {
		"text": "Apel conectat",
		"isAlreadyTranslated": true
	},
	"Connection Disposition:": {
		"text": "Dispoziție conexiune:",
		"isAlreadyTranslated": true
	},
	"Non-Connect Disposition:": {
		"text": "Dispoziție non-conexiune",
		"isAlreadyTranslated": true
	},
	"Date and Time of loss/Damage": {
		"text": "Data și ora pierderii/deteriorării",
		"isAlreadyTranslated": true
	},
	"Description of Loss/Damage": {
		"text": "Descrierea pierderii/deteriorării",
		"isAlreadyTranslated": true
	},
	"Date and Time of Call": {
		"text": "Data și ora apelului",
		"isAlreadyTranslated": true
	},
	"Status at which call was made": {
		"text": "Starea la care s-a efectuat apelul",
		"isAlreadyTranslated": true
	},
	"BER Refund": {
		"text": "Rambursare BER",
		"isAlreadyTranslated": true
	},
	"Action Status": {
		"text": "Starea acțiunii",
		"isAlreadyTranslated": true
	},
	"Action taken": {
		"text": "Acțiune întreprinsă",
		"isAlreadyTranslated": true
	},
	"Rating": {
		"text": "Evaluare",
		"isAlreadyTranslated": true
	},
	"Reassign": {
		"text": "Reatribuire",
		"isAlreadyTranslated": true
	},
	"Parts received": {
		"text": "Piese primite",
		"isAlreadyTranslated": true
	},
	"Address Details": {
		"text": "Detalii adresă",
		"isAlreadyTranslated": true
	},
	"Raise Request": {
		"text": "Solicitare de creștere",
		"isAlreadyTranslated": true
	},
	"Please fill in the cancel reason before cancelling": {
		"text": "Completați motivul anulării înainte de a anula",
		"isAlreadyTranslated": true
	},
	"Add required documents in 'Request Documents' section below": {
		"text": "Adăugați documentele necesare în secțiunea „Solicitare documente” de mai jos",
		"isAlreadyTranslated": true
	},
	"Customer Details": {
		"text": "Detalii client",
		"isAlreadyTranslated": true
	},
	"Mobile Number": {
		"text": "Număr de telefon mobil",
		"isAlreadyTranslated": true
	},
	"First Registered From": {
		"text": "Prima înregistrare de la",
		"isAlreadyTranslated": true
	},
	"No saved Address": {
		"text": "Nicio adresă salvată",
		"isAlreadyTranslated": true
	},
	"Under warranty": {
		"text": "În garanție",
		"isAlreadyTranslated": true
	},
	"Plan Name": {
		"text": "Nume plan",
		"isAlreadyTranslated": true
	},
	"Purchase Price": {
		"text": "Preț de achiziție",
		"isAlreadyTranslated": true
	},
	"Reason": {
		"text": "Motiv",
		"isAlreadyTranslated": true
	},
	"Registration Documents": {
		"text": "Documente de înregistrare",
		"isAlreadyTranslated": true
	},
	"Validated": {
		"text": "Validat",
		"isAlreadyTranslated": true
	},
	"Not Validated": {
		"text": "Nevalidat",
		"isAlreadyTranslated": true
	},
	"Verify": {
		"text": "Verificare",
		"isAlreadyTranslated": true
	},
	"Please enter IMEI/serial number": {
		"text": "Introduceți numărul IMEI/numărul de serie",
		"isAlreadyTranslated": true
	},
	"Please enter serial number": {
		"text": "Introduceți numărul de serie",
		"isAlreadyTranslated": true
	},
	"Please enter warranty till date": {
		"text": "Introduceți data de încheiere a garanției",
		"isAlreadyTranslated": true
	},
	"Please upload a warranty supporting document": {
		"text": "Încărcați un document justificativ al garanției",
		"isAlreadyTranslated": true
	},
	"Please choose if the warranty is applicable or not": {
		"text": "Selectați dacă garanția se aplică sau nu",
		"isAlreadyTranslated": true
	},
	"Please enter the reason for warranty not being applicable": {
		"text": "Indicați motivul pentru care garanția nu se aplică",
		"isAlreadyTranslated": true
	},
	"Remark": {
		"text": "Observație",
		"isAlreadyTranslated": true
	},
	"Add Pending Documents": {
		"text": "Adăugați documente în așteptare",
		"isAlreadyTranslated": true
	},
	"Add Additional Documents": {
		"text": "Adăugați documente suplimentare",
		"isAlreadyTranslated": true
	},
	"Add New Issues": {
		"text": "Adăugați probleme noi",
		"isAlreadyTranslated": true
	},
	"Total Payable": {
		"text": "Total de plătit",
		"isAlreadyTranslated": true
	},
	"Covered Value": {
		"text": "Valoare acoperită",
		"isAlreadyTranslated": true
	},
	"Send For Approval": {
		"text": "Trimiteți spre aprobare",
		"isAlreadyTranslated": true
	},
	"Advance cannot be greater than": {
		"text": "Avansul nu poate fi mai mare decât",
		"isAlreadyTranslated": true
	},
	"Discount cannot be greater than": {
		"text": "Reducerea nu poate fi mai mare decât",
		"isAlreadyTranslated": true
	},
	"Reassigned": {
		"text": "Reatribuit",
		"isAlreadyTranslated": true
	},
	"Pickup Address": {
		"text": "Adresă de preluare",
		"isAlreadyTranslated": true
	},
	"Address": {
		"text": "Adresă",
		"isAlreadyTranslated": true
	},
	"Telecon Version": {
		"text": "Versiune Telecon",
		"isAlreadyTranslated": true
	},
	"Recycle Certificate": {
		"text": "Certificat de reciclare",
		"isAlreadyTranslated": true
	},
	"Pick Up Confirmation Number": {
		"text": "Număr de confirmare a preluării",
		"isAlreadyTranslated": true
	},
	"Pickup Charge": {
		"text": "Taxă de preluare",
		"isAlreadyTranslated": true
	},
	"Payable": {
		"text": "De plată",
		"isAlreadyTranslated": true
	},
	"Place of Damage": {
		"text": "Locul deteriorării",
		"isAlreadyTranslated": true
	},
	"Please enter issue description": {
		"text": "Descrieți problema",
		"isAlreadyTranslated": true
	},
	"Request History": {
		"text": "Istoricul solicitărilor",
		"isAlreadyTranslated": true
	},
	"Waiting for Assignment": {
		"text": "Atribuire în așteptare",
		"isAlreadyTranslated": true
	},
	"BER confirmed": {
		"text": "BER confirmat",
		"isAlreadyTranslated": true
	},
	"BER approved": {
		"text": "BER aprobat",
		"isAlreadyTranslated": true
	},
	"BER identified": {
		"text": "BER identificat",
		"isAlreadyTranslated": true
	},
	"Device received": {
		"text": "Dispozitiv primit",
		"isAlreadyTranslated": true
	},
	"Pickup Request Accepted": {
		"text": "Solicitare de preluare acceptată",
		"isAlreadyTranslated": true
	},
	"We have received your claim request. Our representative will connect with you within the next 1-2 business days to process it further": {
		"text": "Am primit solicitarea dvs. de despăgubire. Reprezentantul nostru va lua legătura cu dvs. în următoarele 1-2 zile lucrătoare pentru continua procesarea.",
		"isAlreadyTranslated": true
	},
	"Device has been received by the service centre and is currently being diagnosed.": {
		"text": "Dispozitivul a fost primit de către centrul de service și este în curs de diagnosticare.",
		"isAlreadyTranslated": true
	},
	"Looks like the repair cost is more than the depreciated value of your device. Please select the option you would like to proceed with.": {
		"text": "Se pare că reparația implică un cost mai mare decât valoarea amortizată a dispozitivului dvs. Selectați opțiunea cu care doriți să continuați.",
		"isAlreadyTranslated": true
	},
	"Carry in": {
		"text": "Transport asigurat",
		"isAlreadyTranslated": true
	},
	"Assigned To": {
		"text": "Atribuit la",
		"isAlreadyTranslated": true
	},
	"ADD CHARGES": {
		"text": "ADĂUGARE COSTURI",
		"isAlreadyTranslated": true
	},
	"Amount": {
		"text": "Sumă",
		"isAlreadyTranslated": true
	},
	"Attempt Call": {
		"text": "Încercare de apelare",
		"isAlreadyTranslated": true
	},
	"Add Device Date of Purchase and Device Purchase Cost in 'Device Details' below": {
		"text": "Adăugați data de achiziție a dispozitivului și costul de achiziție a dispozitivului în „Detalii dispozitiv” de mai jos",
		"isAlreadyTranslated": true
	},
	"Enter type of document to add": {
		"text": "Introduceți tipul de document de adăugat",
		"isAlreadyTranslated": true
	},
	"Please enter Claim intimation number": {
		"text": "Introduceți numărul solicitării de despăgubire",
		"isAlreadyTranslated": true
	},
	"Please enter Claim intimation number before submitting": {
		"text": "Introduceți numărul sesizării de despăgubire înainte de a trimite",
		"isAlreadyTranslated": true
	},
	"Data displayed": {
		"text": "Date afișate",
		"isAlreadyTranslated": true
	},
	"from": {
		"text": "de la",
		"isAlreadyTranslated": true
	},
	"till": {
		"text": "până la",
		"isAlreadyTranslated": true
	},
	"Please upload a clear copy of cancelled cheque with the claimant (your) name on it.": {
		"text": "Încărcați o copie clară a cecului anulat pe care să apară numele solicitantului (dvs.).",
		"isAlreadyTranslated": true
	},
	"Customer Mobile number": {
		"text": "Numărul de telefon mobil al clientului",
		"isAlreadyTranslated": true
	},
	"TRACK EXTERNAL REQUEST": {
		"text": "URMĂRIRE SOLICITARE EXTERNĂ",
		"isAlreadyTranslated": true
	},
	"Oops... Something went wrong!": {
		"text": "Ups... Ceva nu a funcționat corect!",
		"isAlreadyTranslated": true
	},
	"an address": {
		"text": "o adresă",
		"isAlreadyTranslated": true
	},
	"Pincode": {
		"text": "Cod PIN",
		"isAlreadyTranslated": true
	},
	"Clear": {
		"text": "Ștergere",
		"isAlreadyTranslated": true
	},
	"Cancel": {
		"text": "Anulare",
		"isAlreadyTranslated": true
	},
	"Please enter Landmark": {
		"text": "Introduceți tara",
		"isAlreadyTranslated": true
	},
	"Please enter Customer's address": {
		"text": "Introduceți adresa clientului",
		"isAlreadyTranslated": true
	},
	"Please enter pincode": {
		"text": "Introduceți codul PIN",
		"isAlreadyTranslated": true
	},
	"Enter correct Pincode": {
		"text": "Introduceți codul PIN corect",
		"isAlreadyTranslated": true
	},
	"Address updated successfully": {
		"text": "Adresa a fost actualizată cu succes",
		"isAlreadyTranslated": true
	},
	"Documents Pending": {
		"text": "Documente în așteptare",
		"isAlreadyTranslated": true
	},
	"Date and Time of loss/damage": {
		"text": "Data și ora pierderii/deteriorării",
		"isAlreadyTranslated": true
	},
	"Please enter UTR Number": {
		"text": "Introduceți numărul UTR",
		"isAlreadyTranslated": true
	},
	"Payment Date": {
		"text": "Data plății",
		"isAlreadyTranslated": true
	},
	"UTR Number": {
		"text": "Număr UTR",
		"isAlreadyTranslated": true
	},
	"Service Charge": {
		"text": "Taxa de service",
		"isAlreadyTranslated": true
	},
	"SGST": {
		"text": "SGST",
		"isAlreadyTranslated": true
	},
	"CGST": {
		"text": "CGST",
		"isAlreadyTranslated": true
	},
	"TESTING": {
		"text": "TESTARE",
		"isAlreadyTranslated": true
	},
	"Mandatory deductible": {
		"text": "Deductibilitate obligatorie",
		"isAlreadyTranslated": true
	},
	"Admin fees": {
		"text": "Taxe administrative",
		"isAlreadyTranslated": true
	},
	"Claim amount": {
		"text": "Sumă de despăgubire solicitată",
		"isAlreadyTranslated": true
	},
	"Advance payment": {
		"text": "Plată în avans",
		"isAlreadyTranslated": true
	},
	"Advance": {
		"text": "Avans",
		"isAlreadyTranslated": true
	},
	"Alternate Mobile Number": {
		"text": "Număr de telefon mobil alternativ",
		"isAlreadyTranslated": true
	},
	"Please enter correct mobile Number": {
		"text": "Introduceți numărul de telefon mobil corect",
		"isAlreadyTranslated": true
	},
	"Please Enter Minimum 75 Characters": {
		"text": "Introduceți minimum 75 de caractere",
		"isAlreadyTranslated": true
	},
	"Add an address": {
		"text": "Adăugați o adresă",
		"isAlreadyTranslated": true
	},
	"Add New Address": {
		"text": "Adăugați o adresă nouă",
		"isAlreadyTranslated": true
	},
	"Oops! There are no verified service centres around": {
		"text": "Ups! Nu există centre de service verificate în apropiere",
		"isAlreadyTranslated": true
	},
	"Cannot select more than": {
		"text": "Nu se pot selecta mai mult de",
		"isAlreadyTranslated": true
	},
	"issues": {
		"text": "probleme",
		"isAlreadyTranslated": true
	},
	"Add a Product": {
		"text": "Adăugați un produs",
		"isAlreadyTranslated": true
	},
	"Add Selected Product": {
		"text": "Adăugați produsul selectat",
		"isAlreadyTranslated": true
	},
	"Device In Warranty": {
		"text": "Dispozitiv în garanție",
		"isAlreadyTranslated": true
	},
	"Please Select Damage Date": {
		"text": "Selectați data de producere a deteriorării",
		"isAlreadyTranslated": true
	},
	"Is your device switching ON": {
		"text": "Dispozitivul dvs. pornește",
		"isAlreadyTranslated": true
	},
	"Characters must be numeric": {
		"text": "Caracterele trebuie să fie numerice",
		"isAlreadyTranslated": true
	},
	"products under service": {
		"text": "produse în service",
		"isAlreadyTranslated": true
	},
	"Please Select Time": {
		"text": "Selectați ora",
		"isAlreadyTranslated": true
	},
	"Please Select Damage type": {
		"text": "Selectați tipul de deteriorare",
		"isAlreadyTranslated": true
	},
	"Please Enter Place of Damage": {
		"text": "Introduceți locația deteriorării",
		"isAlreadyTranslated": true
	},
	"Please Select an option": {
		"text": "Selectați o opțiune",
		"isAlreadyTranslated": true
	},
	"Consumer Name": {
		"text": "Numele consumatorului",
		"isAlreadyTranslated": true
	},
	"Contact No": {
		"text": "Nr. de contact",
		"isAlreadyTranslated": true
	},
	"Logistics Provider": {
		"text": "Furnizor de servicii de logistică",
		"isAlreadyTranslated": true
	},
	"Registration Source": {
		"text": "Sursă de înregistrare",
		"isAlreadyTranslated": true
	},
	"Customer City": {
		"text": "Orașul clientului",
		"isAlreadyTranslated": true
	},
	"Call Type": {
		"text": "Tip de apel",
		"isAlreadyTranslated": true
	},
	"Re-apply": {
		"text": "Reaplicare",
		"isAlreadyTranslated": true
	},
	"something went wrong": {
		"text": "ceva nu a funcționat corect",
		"isAlreadyTranslated": true
	},
	"Today": {
		"text": "Azi",
		"isAlreadyTranslated": true
	},
	"Yesterday": {
		"text": "Ieri",
		"isAlreadyTranslated": true
	},
	"Last 7 Days": {
		"text": "Ultimele 7 zile",
		"isAlreadyTranslated": true
	},
	"Last 30 Days": {
		"text": "Ultimele 30 zile",
		"isAlreadyTranslated": true
	},
	"This Month": {
		"text": "Luna aceasta",
		"isAlreadyTranslated": true
	},
	"Last Month": {
		"text": "Luna trecută",
		"isAlreadyTranslated": true
	},
	"Custom Range": {
		"text": "Interval particularizat",
		"isAlreadyTranslated": true
	},
	"Add a part charge in 'Estimation Details' below to generate estimation": {
		"text": "Adăugați un cost pentru piesă în câmpul „Detalii de estimare” de mai jos pentru a genera o estimare",
		"isAlreadyTranslated": true
	},
	"Pick Up Request Reference ID": {
		"text": "ID de referință pentru solicitarea de preluare",
		"isAlreadyTranslated": true
	},
	"Convert request": {
		"text": "Conversie solicitare",
		"isAlreadyTranslated": true
	},
	"No Data Available To Plot The Graph": {
		"text": "Nu există date disponibile pentru a reprezenta grafic",
		"isAlreadyTranslated": true
	},
	"to": {
		"text": "la",
		"isAlreadyTranslated": true
	},
	"Download requests in csv format.": {
		"text": "Descărcați solicitările în format csv.",
		"isAlreadyTranslated": true
	},
	"Service cancelled": {
		"text": "Service anulat",
		"isAlreadyTranslated": true
	},
	"Search consumer products": {
		"text": "Căutare produse consumator",
		"isAlreadyTranslated": true
	},
	"Serial number": {
		"text": "Număr de serie",
		"isAlreadyTranslated": true
	},
	"Enter Serial Number": {
		"text": "Introduceți numărul de serie",
		"isAlreadyTranslated": true
	},
	"Select Customer Type": {
		"text": "Selectați tipul de client",
		"isAlreadyTranslated": true
	},
	"Walk-in Customer": {
		"text": "Client fără programare",
		"isAlreadyTranslated": true
	},
	"Future Appointment": {
		"text": "Programare viitoare",
		"isAlreadyTranslated": true
	},
	"Tablet": {
		"text": "Tabletă",
		"isAlreadyTranslated": true
	},
	"Computer": {
		"text": "Computer",
		"isAlreadyTranslated": true
	},
	"Audio Product": {
		"text": "Produs audio",
		"isAlreadyTranslated": true
	},
	"Streaming Device": {
		"text": "Dispozitiv de redare în flux",
		"isAlreadyTranslated": true
	},
	"Smart Watch": {
		"text": "Ceas inteligent",
		"isAlreadyTranslated": true
	},
	"Serial number is mandatory for Apple Products.\nPlease enter valid serial number": {
		"text": "Numărul de serie este obligatoriu pentru produsele Apple.\nIntroduceți un număr de serie valid",
		"isAlreadyTranslated": true
	},
	"Back camera issue": {
		"text": "Problemă la camera de pe spate",
		"isAlreadyTranslated": true
	},
	"Charger not working": {
		"text": "Încărcătorul nu funcționează",
		"isAlreadyTranslated": true
	},
	"Noise issue": {
		"text": "Problemă legată de zgomot",
		"isAlreadyTranslated": true
	},
	"Network Issue": {
		"text": "Problemă legată de rețea",
		"isAlreadyTranslated": true
	},
	"External storage issue": {
		"text": "Problema de stocare externă",
		"isAlreadyTranslated": true
	},
	"Internal data storage issue": {
		"text": "Problemă de stocare internă a datelor",
		"isAlreadyTranslated": true
	},
	"Blur display": {
		"text": "Ecran neclar",
		"isAlreadyTranslated": true
	},
	"Flickering": {
		"text": "Pâlpâire",
		"isAlreadyTranslated": true
	},
	"Improper display": {
		"text": "Afișare necorespunzătoare",
		"isAlreadyTranslated": true
	},
	"Spot on display": {
		"text": "Pată pe ecran",
		"isAlreadyTranslated": true
	},
	"Electric Shock": {
		"text": "Șoc electric",
		"isAlreadyTranslated": true
	},
	"Body damaged": {
		"text": "Carcasă deteriorată",
		"isAlreadyTranslated": true
	},
	"Display damaged": {
		"text": "Ecran deteriorat",
		"isAlreadyTranslated": true
	},
	"Intermittently powering off": {
		"text": "Oprire intermitentă",
		"isAlreadyTranslated": true
	},
	"Not charging": {
		"text": "Nu se încarcă",
		"isAlreadyTranslated": true
	},
	"Not powering off": {
		"text": "Nu se stinge",
		"isAlreadyTranslated": true
	},
	"Cracking sound": {
		"text": "Sunet de fisurare",
		"isAlreadyTranslated": true
	},
	"Less sound": {
		"text": "Sunet redus",
		"isAlreadyTranslated": true
	},
	"Slow processing or hangs": {
		"text": "Procesare lentă sau blocaje",
		"isAlreadyTranslated": true
	},
	"Pickup Drop By": {
		"text": "Preluare livrată de",
		"isAlreadyTranslated": true
	},
	"Qty": {
		"text": "Cant.",
		"isAlreadyTranslated": true
	},
	"Available Quantity": {
		"text": "Cantitate disponibilă",
		"isAlreadyTranslated": true
	},
	"Issued By": {
		"text": "Emis de",
		"isAlreadyTranslated": true
	},
	"Handover Device": {
		"text": "Predare dispozitiv",
		"isAlreadyTranslated": true
	},
	"Not applicable": {
		"text": "Nu se aplică",
		"isAlreadyTranslated": true
	},
	"Pending for assignment": {
		"text": "Atribuire în așteptare",
		"isAlreadyTranslated": true
	},
	"Device handover details": {
		"text": "Detalii privind predarea dispozitivului",
		"isAlreadyTranslated": true
	},
	"Diagnosis details": {
		"text": "Detalii de diagnosticare",
		"isAlreadyTranslated": true
	},
	"Diagnosis Steps": {
		"text": "Etape de diagnosticare",
		"isAlreadyTranslated": true
	},
	"Engineer notes": {
		"text": "Note ale Inginerului",
		"isAlreadyTranslated": true
	},
	"Device Transaction Details": {
		"text": "Detalii privind tranzacția dispozitivului",
		"isAlreadyTranslated": true
	},
	"Upload Device Pictures and Complete Checklist": {
		"text": "Încărcați imagini cu dispozitivul și completați lista de verificare",
		"isAlreadyTranslated": true
	},
	"Upload Device Pictures": {
		"text": "Încărcați imagini cu dispozitivul",
		"isAlreadyTranslated": true
	},
	"Select OS Version": {
		"text": "Selectați versiunea sistemului de operare",
		"isAlreadyTranslated": true
	},
	"Select Picture Type": {
		"text": "Selectați tipul de imagine",
		"isAlreadyTranslated": true
	},
	"No pictures added.": {
		"text": "Nu au fost adăugate imagini.",
		"isAlreadyTranslated": true
	},
	"Complete Checklist": {
		"text": "Listă de verificare completată",
		"isAlreadyTranslated": true
	},
	"Accessories Submitted": {
		"text": "Accesorii trimise",
		"isAlreadyTranslated": true
	},
	"Checklist": {
		"text": "Lista de verificare",
		"isAlreadyTranslated": true
	},
	"Miscellaneous Checklist": {
		"text": "Listă de verificare diverse",
		"isAlreadyTranslated": true
	},
	"Complete": {
		"text": "Finalizat",
		"isAlreadyTranslated": true
	},
	"Select All": {
		"text": "Selectați toate",
		"isAlreadyTranslated": true
	},
	"Raise in": {
		"text": "Creștere în",
		"isAlreadyTranslated": true
	},
	"Request in": {
		"text": "Solicitare în",
		"isAlreadyTranslated": true
	},
	"Please select Repair Type": {
		"text": "Selectați tipul de reparație",
		"isAlreadyTranslated": true
	},
	"Please select Classification Type": {
		"text": "Selectați tipul de clasificare",
		"isAlreadyTranslated": true
	},
	"Repair Checklist": {
		"text": "Listă de verificare pentru reparații",
		"isAlreadyTranslated": true
	},
	"Raise": {
		"text": "Creștere",
		"isAlreadyTranslated": true
	},
	"Request": {
		"text": "Solicitare",
		"isAlreadyTranslated": true
	},
	"Diagnostics Result": {
		"text": "Rezultatul diagnosticării",
		"isAlreadyTranslated": true
	},
	"Please mention the incident in not less than 75 characters on how, when & where the damage happened": {
		"text": "Menționați incidentul în cel puțin 75 de caractere, precizând cum, când și unde s-a produs deteriorarea.",
		"isAlreadyTranslated": true
	},
	"Parts transaction details": {
		"text": "Detalii privind tranzacția cu piese",
		"isAlreadyTranslated": true
	},
	"Handing over the parts to store": {
		"text": "Predarea pieselor la magazin",
		"isAlreadyTranslated": true
	},
	"Handing over the damaged device to store": {
		"text": "Predarea dispozitivului deteriorat la magazin",
		"isAlreadyTranslated": true
	},
	"Details of the New Replaced Device": {
		"text": "Detalii despre noul dispozitiv înlocuit",
		"isAlreadyTranslated": true
	},
	"Mode of payment": {
		"text": "Metodă de plată",
		"isAlreadyTranslated": true
	},
	"Please select mode of payment": {
		"text": "Selectați metoda de plată",
		"isAlreadyTranslated": true
	},
	"Cash": {
		"text": "Numerar",
		"isAlreadyTranslated": true
	},
	"Card": {
		"text": "Card",
		"isAlreadyTranslated": true
	},
	"Accessories will not be returned": {
		"text": "Accesoriile nu vor fi returnate",
		"isAlreadyTranslated": true
	},
	"Documents are yet to be uploaded.": {
		"text": "Documentele nu au fost încă încărcate.",
		"isAlreadyTranslated": true
	},
	"Report Issues": {
		"text": "Probleme de raportare",
		"isAlreadyTranslated": true
	},
	"Re-assign": {
		"text": "Reatribuire",
		"isAlreadyTranslated": true
	},
	"Add Required Parts": {
		"text": "Adăugați piesele necesare",
		"isAlreadyTranslated": true
	},
	"Price": {
		"text": "Preț",
		"isAlreadyTranslated": true
	},
	"Tax Slab": {
		"text": "Secțiune taxă",
		"isAlreadyTranslated": true
	},
	"Is Part Abused": {
		"text": "Este o piesă utilizată abuziv",
		"isAlreadyTranslated": true
	},
	"Is Returnable Damage": {
		"text": "Este un prejudiciu returnabil",
		"isAlreadyTranslated": true
	},
	"Part Warranty": {
		"text": "Garanția piesei",
		"isAlreadyTranslated": true
	},
	"Part Price": {
		"text": "Prețul piesei",
		"isAlreadyTranslated": true
	},
	"Available Qty": {
		"text": "Cant. disponibilă",
		"isAlreadyTranslated": true
	},
	"Action": {
		"text": "Acțiune",
		"isAlreadyTranslated": true
	},
	"Report Issues to": {
		"text": "Raportați problemele la",
		"isAlreadyTranslated": true
	},
	"Receive & Inspect Device": {
		"text": "Recepție și inspectare dispozitiv",
		"isAlreadyTranslated": true
	},
	"Please select a picture type from dropdown": {
		"text": "Selectați un tip de imagine din lista verticală",
		"isAlreadyTranslated": true
	},
	"Waybill": {
		"text": "Foaie de parcurs",
		"isAlreadyTranslated": true
	},
	"Vendor": {
		"text": "Furnizor",
		"isAlreadyTranslated": true
	},
	"Pick Up": {
		"text": "Preluare",
		"isAlreadyTranslated": true
	},
	"Is Covered By ACPlus": {
		"text": "Este acoperit de ACPlus",
		"isAlreadyTranslated": true
	},
	"Re-assign Service Engineer": {
		"text": "Reatribuiți Inginerul de service",
		"isAlreadyTranslated": true
	},
	"Device Received & Inspection in Progress": {
		"text": "Dispozitiv primit; inspecție în desfășurare",
		"isAlreadyTranslated": true
	},
	"Service accepted": {
		"text": "Service acceptat",
		"isAlreadyTranslated": true
	},
	"COMPTIA Modifier": {
		"text": "Modificator COMPTIA",
		"isAlreadyTranslated": true
	},
	"Send Issues to": {
		"text": "Transmiteți problemele la",
		"isAlreadyTranslated": true
	},
	"Engineer validated issues": {
		"text": "Probleme validate de Inginer",
		"isAlreadyTranslated": true
	},
	"Part(s) request under review by storekeeper.": {
		"text": "Magazionerul analizează solicitarea de piesă (piese)",
		"isAlreadyTranslated": true
	},
	"ChargeCode": {
		"text": "ChargeCode",
		"isAlreadyTranslated": true
	},
	"TaxSlab": {
		"text": "TaxSlab",
		"isAlreadyTranslated": true
	},
	"Are you sure?": {
		"text": "Sunteți sigur?",
		"isAlreadyTranslated": true
	},
	"Are you sure..?": {
		"text": "Sunteți sigur... ?",
		"isAlreadyTranslated": true
	},
	"Part name": {
		"text": "Denumire piesă",
		"isAlreadyTranslated": true
	},
	"Ref Id": {
		"text": "ID referință",
		"isAlreadyTranslated": true
	},
	"Validation Status": {
		"text": "Starea de validare",
		"isAlreadyTranslated": true
	},
	"Is warranty applicable": {
		"text": "Se aplică garanția",
		"isAlreadyTranslated": true
	},
	"Issue Code": {
		"text": "Cod problemă",
		"isAlreadyTranslated": true
	},
	"Symptom Code": {
		"text": "Cod simptom",
		"isAlreadyTranslated": true
	},
	"Other Charge": {
		"text": "Alte taxe",
		"isAlreadyTranslated": true
	},
	"Discount": {
		"text": "Reducere",
		"isAlreadyTranslated": true
	},
	"Engineer completed the repair. Handed over the device to QC.": {
		"text": "Inginerul a finalizat reparația. Dispozitiv predat departamentului QC.",
		"isAlreadyTranslated": true
	},
	"Device handed over": {
		"text": "Dispozitiv predat",
		"isAlreadyTranslated": true
	},
	"Repair completed, QC pending": {
		"text": "Reparație finalizată, QC în desfășurare",
		"isAlreadyTranslated": true
	},
	"Repair complete": {
		"text": "Reparație finalizată",
		"isAlreadyTranslated": true
	},
	"Device details validated": {
		"text": "Detalii dispozitiv validate",
		"isAlreadyTranslated": true
	},
	"Issues validated": {
		"text": "Probleme validate",
		"isAlreadyTranslated": true
	},
	"Work in Progress with Engineer": {
		"text": "Lucrare în desfășurare la Inginer",
		"isAlreadyTranslated": true
	},
	"Cannot be diagnosed": {
		"text": "Nu poate fi diagnosticat",
		"isAlreadyTranslated": true
	},
	"Parts issued": {
		"text": "Piese emise",
		"isAlreadyTranslated": true
	},
	"Pending for acceptance": {
		"text": "Acceptare în așteptare",
		"isAlreadyTranslated": true
	},
	"Device accepted": {
		"text": "Dispozitiv acceptat",
		"isAlreadyTranslated": true
	},
	"Parts request": {
		"text": "Solicitare de piese",
		"isAlreadyTranslated": true
	},
	"Valid": {
		"text": "Valabil",
		"isAlreadyTranslated": true
	},
	"Validity": {
		"text": "Validitate",
		"isAlreadyTranslated": true
	},
	"validity": {
		"text": "valabilitate",
		"isAlreadyTranslated": true
	},
	"Invalid": {
		"text": "Nevalid",
		"isAlreadyTranslated": true
	},
	"Engineer Raised Issues": {
		"text": "Probleme ridicate de Inginer",
		"isAlreadyTranslated": true
	},
	"Are you sure": {
		"text": "Sunteți sigur",
		"isAlreadyTranslated": true
	},
	"Please specify remarks, if any": {
		"text": "Introduceți eventualele observații",
		"isAlreadyTranslated": true
	},
	"This service is a Return without repair": {
		"text": "Acest serviciu este o returnare fără reparație",
		"isAlreadyTranslated": true
	},
	"Others": {
		"text": "Altele",
		"isAlreadyTranslated": true
	},
	"Service completed and rated": {
		"text": "Serviciu finalizat și evaluat",
		"isAlreadyTranslated": true
	},
	"Drop Request Created Post Repair": {
		"text": "Solicitare de predare creată post-reparație",
		"isAlreadyTranslated": true
	},
	"Payment received": {
		"text": "Plată primită",
		"isAlreadyTranslated": true
	},
	"Service invoice generated": {
		"text": "Factură de service generată",
		"isAlreadyTranslated": true
	},
	"Repair Completed, Ready To Inform The Customer": {
		"text": "Reparație finalizată, clientul poate fi informat",
		"isAlreadyTranslated": true
	},
	"Repair completed": {
		"text": "Reparație finalizată",
		"isAlreadyTranslated": true
	},
	"WarrantyConcession": {
		"text": "WarrantyConcession",
		"isAlreadyTranslated": true
	},
	"Estimation generated": {
		"text": "Estimare generată",
		"isAlreadyTranslated": true
	},
	"Estimation approval": {
		"text": "Aprobarea estimării",
		"isAlreadyTranslated": true
	},
	"Parts request accepted": {
		"text": "Solicitare de piese acceptată",
		"isAlreadyTranslated": true
	},
	"Repair estimate approved": {
		"text": "Deviz de reparație aprobat",
		"isAlreadyTranslated": true
	},
	"Damaged Serial No": {
		"text": "Nr. de serie deteriorat",
		"isAlreadyTranslated": true
	},
	"Replaced Serial No": {
		"text": "Nr. de serie înlocuit",
		"isAlreadyTranslated": true
	},
	"Damaged IMEI": {
		"text": "IMEI deteriorat",
		"isAlreadyTranslated": true
	},
	"Replaced IMEI": {
		"text": "IMEI înlocuit",
		"isAlreadyTranslated": true
	},
	"Initiate logistics request": {
		"text": "Inițiere solicitare de logistică",
		"isAlreadyTranslated": true
	},
	"Payment for the service has been received from the customer.": {
		"text": "Plata pentru serviciu a fost primită de la client.",
		"isAlreadyTranslated": true
	},
	"BER payment completed": {
		"text": "Plată BER finalizată",
		"isAlreadyTranslated": true
	},
	"BER payment initiated": {
		"text": "Plată BER inițiată",
		"isAlreadyTranslated": true
	},
	"BER device received": {
		"text": "Dispozitiv BER primit",
		"isAlreadyTranslated": true
	},
	"BER accessories validated": {
		"text": "Accesorii BER validate",
		"isAlreadyTranslated": true
	},
	"BER accessories received": {
		"text": "Accesorii BER primite",
		"isAlreadyTranslated": true
	},
	"BER documents validated": {
		"text": "Documente BER validate",
		"isAlreadyTranslated": true
	},
	"Pickup rescheduled": {
		"text": "Preluare reprogramată",
		"isAlreadyTranslated": true
	},
	"Reschedule requested": {
		"text": "Reprogramare solicitată",
		"isAlreadyTranslated": true
	},
	"Claim Pickup": {
		"text": "Preluare solicitare de despăgubire",
		"isAlreadyTranslated": true
	},
	"Invalid IMEI or serial number": {
		"text": "Număr IMEI sau număr de serie nevalid",
		"isAlreadyTranslated": true
	},
	"No serial number for product": {
		"text": "Nu există un număr de serie pentru produs",
		"isAlreadyTranslated": true
	},
	"Invalid product serial number": {
		"text": "Număr de serie al produsului nevalid",
		"isAlreadyTranslated": true
	},
	"Waiting for the Engineer to raise the request.": {
		"text": "Solicitare din partea Inginerului în așteptare.",
		"isAlreadyTranslated": true
	},
	"getting": {
		"text": "obținere în curs",
		"isAlreadyTranslated": true
	},
	"Claim Carryin": {
		"text": "Solicitare transport",
		"isAlreadyTranslated": true
	},
	"Approved claim amount": {
		"text": "Valoarea aprobată a solicitării de despăgubire",
		"isAlreadyTranslated": true
	},
	"Payment pending": {
		"text": "Plată în așteptare",
		"isAlreadyTranslated": true
	},
	"Work in Progress with Engineer.": {
		"text": "Lucrare în desfășurare la Inginer.",
		"isAlreadyTranslated": true
	},
	"Diagnosis completed": {
		"text": "Diagnostic finalizat",
		"isAlreadyTranslated": true
	},
	"Diagnosis initiated": {
		"text": "Diagnostic inițiat",
		"isAlreadyTranslated": true
	},
	"To proceed, update Device Date of Purchase and Device Purchase Cost in 'Device Details' below.": {
		"text": "Pentru a continua, actualizați data de achiziție a dispozitivului și costul de achiziție a dispozitivului în „Detalii dispozitiv” de mai jos.",
		"isAlreadyTranslated": true
	},
	"Connect Disposition:": {
		"text": "Dispoziție conexiune:",
		"isAlreadyTranslated": true
	},
	"Handing over the": {
		"text": "Predarea",
		"isAlreadyTranslated": true
	},
	"full box unit": {
		"text": "tuturor pieselor din",
		"isAlreadyTranslated": true
	},
	"parts": {
		"text": "piese",
		"isAlreadyTranslated": true
	},
	"to store.": {
		"text": "pentru stocare.",
		"isAlreadyTranslated": true
	},
	"Damaged Part Imei": {
		"text": "IMEI piesă deteriorată",
		"isAlreadyTranslated": true
	},
	"Select Fault Code based on the validated issues.": {
		"text": "Selectați Codul de defecțiune pe baza problemelor validate.",
		"isAlreadyTranslated": true
	},
	"Fault Code": {
		"text": "Cod de defecțiune",
		"isAlreadyTranslated": true
	},
	"Please check following items before completing the QC.": {
		"text": "Verificați următoarele elemente înainte de a finaliza QC.",
		"isAlreadyTranslated": true
	},
	"QC Checklist": {
		"text": "Listă de verificare QC",
		"isAlreadyTranslated": true
	},
	"Oops": {
		"text": "Ups",
		"isAlreadyTranslated": true
	},
	"Please enter type of damage": {
		"text": "Introduceți tipul de deteriorare",
		"isAlreadyTranslated": true
	},
	"Please enter atleast one document": {
		"text": "Introduceți cel puțin un document",
		"isAlreadyTranslated": true
	},
	"Please enter description of loss/damage": {
		"text": "Introduceți descrierea pierderii/deteriorării",
		"isAlreadyTranslated": true
	},
	"Please enter Date and Time of loss/damage": {
		"text": "Introduceți data și ora pierderii/deteriorării",
		"isAlreadyTranslated": true
	},
	"Please enter Disposition reason": {
		"text": "Introduceți motivul dispoziției",
		"isAlreadyTranslated": true
	},
	"Please enter your name": {
		"text": "Introduceți numele dvs.",
		"isAlreadyTranslated": true
	},
	"Please enter the reason for call": {
		"text": "Introduceți motivul apelului",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for cancelling": {
		"text": "Completați motivul anulării",
		"isAlreadyTranslated": true
	},
	"Please specify the pending parts": {
		"text": "Specificați piesele în așteptare",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for rejecting claim": {
		"text": "Completați motivul respingerii solicitării de despăgubire",
		"isAlreadyTranslated": true
	},
	"Please fill in the cancel reason before rejecting": {
		"text": "Completați motivul de anulare înainte de respingere",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for the claim being invalid": {
		"text": "Completați motivul pentru care solicitarea de despăgubire nu este validă",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for claim being invalid before rejecting": {
		"text": "Completați motivul pentru care solicitarea de despăgubire nu este validă înainte de respingere",
		"isAlreadyTranslated": true
	},
	"You have not chosen any option to handover": {
		"text": "Nu ați ales nici o opțiune de predare",
		"isAlreadyTranslated": true
	},
	"Please enter approved claim amount": {
		"text": "Introduceți suma aprobată în urma solicitării de despăgubire",
		"isAlreadyTranslated": true
	},
	"Please add a picture.": {
		"text": "Adăugați o fotografie.",
		"isAlreadyTranslated": true
	},
	"Please specify remarks, if any...": {
		"text": "Introduceți eventualele observații...",
		"isAlreadyTranslated": true
	},
	"Cannot proceed with this action without providing a reason.": {
		"text": "Nu se poate continua cu această acțiune fără o justificare.",
		"isAlreadyTranslated": true
	},
	"Cannot proceed with this action without providing a reason": {
		"text": "Nu se poate continua cu această acțiune fără o justificare",
		"isAlreadyTranslated": true
	},
	"Device transaction details": {
		"text": "Detalii privind tranzacția dispozitivului",
		"isAlreadyTranslated": true
	},
	"Select Device": {
		"text": "Selectați dispozitivul",
		"isAlreadyTranslated": true
	},
	"Account Details": {
		"text": "Detalii cont",
		"isAlreadyTranslated": true
	},
	"Add Customer's Mobile Number": {
		"text": "Adăugați numărul de telefon mobil al clientului",
		"isAlreadyTranslated": true
	},
	"Re - Enter Mobile Number": {
		"text": "Introduceți din nou numărul de telefon mobil",
		"isAlreadyTranslated": true
	},
	"Approve Claim Estimation": {
		"text": "Aprobați estimarea solicitării de despăgubire",
		"isAlreadyTranslated": true
	},
	"Are you sure? Please specify remarks, if any...": {
		"text": "Sunteți sigur? Introduceți eventualele observații...",
		"isAlreadyTranslated": true
	},
	"Ask for Documents Pending": {
		"text": "Solicitați documentele în așteptare",
		"isAlreadyTranslated": true
	},
	"Assign": {
		"text": "Atribuire",
		"isAlreadyTranslated": true
	},
	"Assigned": {
		"text": "Atribuit",
		"isAlreadyTranslated": true
	},
	"Assignment": {
		"text": "Atribuire",
		"isAlreadyTranslated": true
	},
	"Attempt": {
		"text": "Încercare",
		"isAlreadyTranslated": true
	},
	"Available": {
		"text": "Disponibil",
		"isAlreadyTranslated": true
	},
	"Back": {
		"text": "Înapoi",
		"isAlreadyTranslated": true
	},
	"Back to all consumers": {
		"text": "Înapoi la toți consumatorii",
		"isAlreadyTranslated": true
	},
	"Back to all requests": {
		"text": "Înapoi la toate solicitările",
		"isAlreadyTranslated": true
	},
	"BER Case Found": {
		"text": "Caz BER găsit",
		"isAlreadyTranslated": true
	},
	"Brand authorised": {
		"text": "Marcă autorizată",
		"isAlreadyTranslated": true
	},
	"Broker Name": {
		"text": "Numele brokerului",
		"isAlreadyTranslated": true
	},
	"Call attempted": {
		"text": "Apel încercat",
		"isAlreadyTranslated": true
	},
	"Cancel Repair": {
		"text": "Anulare reparație",
		"isAlreadyTranslated": true
	},
	"Cancel Request": {
		"text": "Anulare solicitare",
		"isAlreadyTranslated": true
	},
	"Cancel Service": {
		"text": "Anulare service",
		"isAlreadyTranslated": true
	},
	"Cancel Service Without Dispatch": {
		"text": "Anularea serviciului fără expediere",
		"isAlreadyTranslated": true
	},
	"Cannot select more than issues.": {
		"text": "Nu se pot selecta mai multe probleme.",
		"isAlreadyTranslated": true
	},
	"Characters must be numbers": {
		"text": "Caracterele trebuie să fie numere",
		"isAlreadyTranslated": true
	},
	"Characters must be alphabets": {
		"text": "Caracterele trebuie să fie alfabetice",
		"isAlreadyTranslated": true
	},
	"Characters must be alphanumeric": {
		"text": "Caracterele trebuie să fie alfanumerice",
		"isAlreadyTranslated": true
	},
	"Characters must be alphabets (at least 2 alphabets are mandatory)": {
		"text": "Caracterele trebuie să fie alfabetice (cel puțin 2 caractere alfabetice sunt obligatorii)",
		"isAlreadyTranslated": true
	},
	"Characters must be numeric.": {
		"text": "Caracterele trebuie să fie numerice.",
		"isAlreadyTranslated": true
	},
	"Choose from dropdown list only": {
		"text": "Alegeți numai din lista verticală",
		"isAlreadyTranslated": true
	},
	"Claim": {
		"text": "Solicitare de despăgubire",
		"isAlreadyTranslated": true
	},
	"Claim estimation approved": {
		"text": "Estimarea solicitării de despăgubire a fost aprobată",
		"isAlreadyTranslated": true
	},
	"Claim estimation generated": {
		"text": "Estimarea solicitării de despăgubire a fost generată",
		"isAlreadyTranslated": true
	},
	"Claim initiated": {
		"text": "Solicitare de despăgubire inițiată",
		"isAlreadyTranslated": true
	},
	"Claim invalid": {
		"text": "Solicitare de despăgubire nevalidă",
		"isAlreadyTranslated": true
	},
	"Claim raised": {
		"text": "Solicitare de despăgubire formulată",
		"isAlreadyTranslated": true
	},
	"Claim rejected": {
		"text": "Solicitare de despăgubire respinsă",
		"isAlreadyTranslated": true
	},
	"Communication History": {
		"text": "Istoric comunicare",
		"isAlreadyTranslated": true
	},
	"Complete Repair": {
		"text": "Finalzare reparație",
		"isAlreadyTranslated": true
	},
	"Complete Service": {
		"text": "Finalizare service",
		"isAlreadyTranslated": true
	},
	"COMPTIA Code": {
		"text": "Cod COMPTIA",
		"isAlreadyTranslated": true
	},
	"COMPTIA Group": {
		"text": "Grup COMPTIA",
		"isAlreadyTranslated": true
	},
	"Confirm": {
		"text": "Confirmare",
		"isAlreadyTranslated": true
	},
	"Connect": {
		"text": "Conectare",
		"isAlreadyTranslated": true
	},
	"Connect Disposition": {
		"text": "Dispoziție conexiune",
		"isAlreadyTranslated": true
	},
	"Consumer Details": {
		"text": "Detalii despre consumator",
		"isAlreadyTranslated": true
	},
	"Consumer Raised Issues": {
		"text": "Probleme ridicate de consumatori",
		"isAlreadyTranslated": true
	},
	"Contact": {
		"text": "Contact",
		"isAlreadyTranslated": true
	},
	"Contract Type": {
		"text": "Tip de contract",
		"isAlreadyTranslated": true
	},
	"Current Log": {
		"text": "Jurnal curent",
		"isAlreadyTranslated": true
	},
	"Current Request Reference ID": {
		"text": "ID-ul de referință al solicitării curente",
		"isAlreadyTranslated": true
	},
	"Current Status": {
		"text": "Stare actuală",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive": {
		"text": "Director departament de asistență clienți",
		"isAlreadyTranslated": true
	},
	"Customer did not add issues": {
		"text": "Clientul nu a adăugat probleme",
		"isAlreadyTranslated": true
	},
	"Customer's Version": {
		"text": "Versiunea clientului",
		"isAlreadyTranslated": true
	},
	"DAMAGE DETAILS": {
		"text": "DETALII PRIVIND DETERIORAREA",
		"isAlreadyTranslated": true
	},
	"Damage Type": {
		"text": "Tipul deteriorării",
		"isAlreadyTranslated": true
	},
	"Date and Time of loss/damage:": {
		"text": "Data și ora pierderii/deteriorării:",
		"isAlreadyTranslated": true
	},
	"When did your device get damaged?": {
		"text": "Când a fost deteriorat dispozitivul dvs.?",
		"isAlreadyTranslated": true
	},
	"Please tell us how was your device damaged": {
		"text": "Descrieți cum a fost deteriorat dispozitivul dvs.",
		"isAlreadyTranslated": true
	},
	"Date of Purchase": {
		"text": "Data achiziției",
		"isAlreadyTranslated": true
	},
	"Dead on Arrival": {
		"text": "Închis la sosire",
		"isAlreadyTranslated": true
	},
	"Description of loss/damage": {
		"text": "Descrierea pierderii/deteriorării",
		"isAlreadyTranslated": true
	},
	"Detail Description of Damage": {
		"text": "Descriere detaliată a deteriorării",
		"isAlreadyTranslated": true
	},
	"Device": {
		"text": "Dispozitiv",
		"isAlreadyTranslated": true
	},
	"Device Added Date": {
		"text": "Data adăugării dispozitivului",
		"isAlreadyTranslated": true
	},
	"Device Details": {
		"text": "Detalii dispozitiv",
		"isAlreadyTranslated": true
	},
	"Device dispatched": {
		"text": "Dispozitiv expediat",
		"isAlreadyTranslated": true
	},
	"Device In Warranty?": {
		"text": "Dispozitiv în garanție?",
		"isAlreadyTranslated": true
	},
	"Device switching on": {
		"text": "Pornirea dispozitivului",
		"isAlreadyTranslated": true
	},
	"device_details": {
		"text": "detalii_dispozitiv",
		"isAlreadyTranslated": true
	},
	"Dispatch Device": {
		"text": "Expediere dispozitiv",
		"isAlreadyTranslated": true
	},
	"Device shipped": {
		"text": "Dispozitiv transportat",
		"isAlreadyTranslated": true
	},
	"Device Received": {
		"text": "Dispozitiv primit",
		"isAlreadyTranslated": true
	},
	"Distance from user": {
		"text": "Distanța față de utilizator",
		"isAlreadyTranslated": true
	},
	"Distributor Name": {
		"text": "Numele distribuitorului",
		"isAlreadyTranslated": true
	},
	"doc": {
		"text": "doc",
		"isAlreadyTranslated": true
	},
	"Document pending": {
		"text": "Document în așteptare",
		"isAlreadyTranslated": true
	},
	"Document verification pending": {
		"text": "Verificarea documentului este în desfășurare",
		"isAlreadyTranslated": true
	},
	"Documents are yet to be uploaded": {
		"text": "Documentele nu au fost încă încărcate",
		"isAlreadyTranslated": true
	},
	"Download Jobsheet Label": {
		"text": "Descărcați eticheta fișei de lucru",
		"isAlreadyTranslated": true
	},
	"Download Logistics Label": {
		"text": "Descărcați eticheta logistică",
		"isAlreadyTranslated": true
	},
	"DD-MM-YYYY": {
		"text": "ZZ-LL-AAAA",
		"isAlreadyTranslated": true
	},
	"Email ID": {
		"text": "ID e-mail",
		"isAlreadyTranslated": true
	},
	"End on": {
		"text": "Încheiere la",
		"isAlreadyTranslated": true
	},
	"Engineer": {
		"text": "Inginer",
		"isAlreadyTranslated": true
	},
	"Enter Call Details": {
		"text": "Introduceți detaliile apelului",
		"isAlreadyTranslated": true
	},
	"Enter correct Mobile Number": {
		"text": "Introduceți numărul de mobil corect",
		"isAlreadyTranslated": true
	},
	"Enter IMEI or Serial Number": {
		"text": "Introduceți numărul IMEI sau numărul de serie",
		"isAlreadyTranslated": true
	},
	"Enter Information for the parts you are issuing": {
		"text": "Introduceți informațiile pentru piesele pe care le eliberați",
		"isAlreadyTranslated": true
	},
	"Enter Mobile Number": {
		"text": "Introduceți numărul de telefon mobil",
		"isAlreadyTranslated": true
	},
	"Enter Reference ID": {
		"text": "Introduceți ID-ul de referință",
		"isAlreadyTranslated": true
	},
	"Enter Reference IDs": {
		"text": "Introduceți ID-urile de referință",
		"isAlreadyTranslated": true
	},
	"Error": {
		"text": "Eroare",
		"isAlreadyTranslated": true
	},
	"Estimation Details": {
		"text": "Detalii de estimare",
		"isAlreadyTranslated": true
	},
	"Exclusive": {
		"text": "Exclusiv",
		"isAlreadyTranslated": true
	},
	"External Reference ID": {
		"text": "ID de referință externă",
		"isAlreadyTranslated": true
	},
	"External Reference ID has to be between 6 - 20 characters.": {
		"text": "ID-ul de referință externă trebuie să aibă între 6 și 20 de caractere.",
		"isAlreadyTranslated": true
	},
	"External ReferenceID added successfully": {
		"text": "ReferenceID extern adăugat cu succes",
		"isAlreadyTranslated": true
	},
	"Full name": {
		"text": "Nume complet",
		"isAlreadyTranslated": true
	},
	"Generate Invoice": {
		"text": "Generați factura",
		"isAlreadyTranslated": true
	},
	"Green Rewards": {
		"text": "Recompense verzi",
		"isAlreadyTranslated": true
	},
	"Green Rewards Chosen": {
		"text": "Recompense verzi alese",
		"isAlreadyTranslated": true
	},
	"GSX Validation Status": {
		"text": "Stare de validare GSX",
		"isAlreadyTranslated": true
	},
	"Home": {
		"text": "Pagină de pornire",
		"isAlreadyTranslated": true
	},
	"HSN Code": {
		"text": "Cod HSN",
		"isAlreadyTranslated": true
	},
	"ID Proof": {
		"text": "Dovada identității",
		"isAlreadyTranslated": true
	},
	"IMEI 1": {
		"text": "IMEI 1",
		"isAlreadyTranslated": true
	},
	"IMEI Number": {
		"text": "Număr IMEI",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial Number": {
		"text": "Număr IMEI/număr de serie",
		"isAlreadyTranslated": true
	},
	"In-principle Approval": {
		"text": "Aprobare de principiu",
		"isAlreadyTranslated": true
	},
	"In-principle approved": {
		"text": "Aprobat de principiu",
		"isAlreadyTranslated": true
	},
	"Initiate Claim": {
		"text": "Inițierea solicitării de despăgubire",
		"isAlreadyTranslated": true
	},
	"Initiate Service": {
		"text": "Inițierea service-ului",
		"isAlreadyTranslated": true
	},
	"Insurance Status": {
		"text": "Stare asigurare",
		"isAlreadyTranslated": true
	},
	"Insurance team will reach out to you for further action.": {
		"text": "Echipa de asigurări va lua legătura cu dvs. pentru măsuri suplimentare.",
		"isAlreadyTranslated": true
	},
	"Insurer Name": {
		"text": "Numele asigurătorului",
		"isAlreadyTranslated": true
	},
	"Invalid email id": {
		"text": "ID e-mail nevalid",
		"isAlreadyTranslated": true
	},
	"Invoice": {
		"text": "Factură",
		"isAlreadyTranslated": true
	},
	"Is Warranty Applicable": {
		"text": "Se aplică garanția",
		"isAlreadyTranslated": true
	},
	"Is Warranty Applicable for this request?": {
		"text": "Se aplică garanția pentru această solicitare?",
		"isAlreadyTranslated": true
	},
	"Issue description": {
		"text": "Descrierea problemei",
		"isAlreadyTranslated": true
	},
	"Issue Validation": {
		"text": "Validarea problemei",
		"isAlreadyTranslated": true
	},
	"Issued": {
		"text": "Emis",
		"isAlreadyTranslated": true
	},
	"Issues": {
		"text": "Probleme",
		"isAlreadyTranslated": true
	},
	"Issues Reported": {
		"text": "Probleme raportate",
		"isAlreadyTranslated": true
	},
	"Landmark": {
		"text": "Tara",
		"isAlreadyTranslated": true
	},
	"Loading": {
		"text": "Încărcare",
		"isAlreadyTranslated": true
	},
	"Loading request": {
		"text": "Solicitare de încărcare",
		"isAlreadyTranslated": true
	},
	"Location": {
		"text": "Locație",
		"isAlreadyTranslated": true
	},
	"Logistics - From Customer's Address To Service Center": {
		"text": "Logistică - De la adresa clientului la centrul de service",
		"isAlreadyTranslated": true
	},
	"Logistics - From Customer's Address To Servify Hub": {
		"text": "Logistică - De la adresa clientului la hubul Servify",
		"isAlreadyTranslated": true
	},
	"Logistics - From Drop-Off Center to Servify Hub": {
		"text": "Logistică - De la centrul de preluare la hubul Servify",
		"isAlreadyTranslated": true
	},
	"Logistics - From Servify Hub to Recycle Hub": {
		"text": "Logistică - De la hubul Servify la hubul de reciclare",
		"isAlreadyTranslated": true
	},
	"Logistics - Return to Customer": {
		"text": "Logistică - Returnare la client",
		"isAlreadyTranslated": true
	},
	"Logistics Details": {
		"text": "Detalii logistice",
		"isAlreadyTranslated": true
	},
	"Loss DateTime": {
		"text": "DateTime pentru pierdere",
		"isAlreadyTranslated": true
	},
	"Loss Description": {
		"text": "Descriere pierdere",
		"isAlreadyTranslated": true
	},
	"Mark Customer Arrival": {
		"text": "Marcați sosirea clientului",
		"isAlreadyTranslated": true
	},
	"Merge": {
		"text": "Îmbinare",
		"isAlreadyTranslated": true
	},
	"Min 75 Characters": {
		"text": "Min. 75 de caractere",
		"isAlreadyTranslated": true
	},
	"Mobile number": {
		"text": "Număr de telefon mobil",
		"isAlreadyTranslated": true
	},
	"NA": {
		"text": "NA",
		"isAlreadyTranslated": true
	},
	"Name": {
		"text": "Nume",
		"isAlreadyTranslated": true
	},
	"Name of the caller": {
		"text": "Numele apelantului",
		"isAlreadyTranslated": true
	},
	"Next Steps": {
		"text": "Pașii următori",
		"isAlreadyTranslated": true
	},
	"No addresses found": {
		"text": "Nu s-au găsit adrese",
		"isAlreadyTranslated": true
	},
	"No brands found": {
		"text": "Nu s-au găsit mărci",
		"isAlreadyTranslated": true
	},
	"No documents uploaded": {
		"text": "Niciun document încărcat",
		"isAlreadyTranslated": true
	},
	"No engineers found": {
		"text": "Nu s-au găsit Ingineri",
		"isAlreadyTranslated": true
	},
	"No issues added": {
		"text": "Nu au fost adăugate probleme",
		"isAlreadyTranslated": true
	},
	"No issues found": {
		"text": "Nu s-au găsit probleme",
		"isAlreadyTranslated": true
	},
	"No other requests found": {
		"text": "Nu s-au găsit alte solicitări",
		"isAlreadyTranslated": true
	},
	"No parts requested": {
		"text": "Nu sunt solicitate piese",
		"isAlreadyTranslated": true
	},
	"No Pending Document Specified": {
		"text": "Nu se specifică niciun document în așteptare",
		"isAlreadyTranslated": true
	},
	"No products found": {
		"text": "Nu s-au găsit produse",
		"isAlreadyTranslated": true
	},
	"No servicable consumer product found": {
		"text": "Nu s-a găsit niciun produs al consumatorului care poate fi reparat în service",
		"isAlreadyTranslated": true
	},
	"No service locations found": {
		"text": "Nu s-au găsit locații de service",
		"isAlreadyTranslated": true
	},
	"No Slots Available": {
		"text": "Nu există intervale disponibile",
		"isAlreadyTranslated": true
	},
	"No subcategories found": {
		"text": "Nu s-au găsit subcategorii",
		"isAlreadyTranslated": true
	},
	"No such service request exists": {
		"text": "Nu există o astfel de solicitare de service",
		"isAlreadyTranslated": true
	},
	"No supported mode found": {
		"text": "Nu s-a găsit niciun mod acceptat",
		"isAlreadyTranslated": true
	},
	"No supported modes found": {
		"text": "Nu s-au găsit moduri acceptate",
		"isAlreadyTranslated": true
	},
	"No. of Repairs Allowed": {
		"text": "Nr. de reparații permise",
		"isAlreadyTranslated": true
	},
	"No. of Repairs Used": {
		"text": "Nr. de reparații utilizate",
		"isAlreadyTranslated": true
	},
	"Non-Connect Disposition": {
		"text": "Dispoziție non-conexiune",
		"isAlreadyTranslated": true
	},
	"Office": {
		"text": "Birou",
		"isAlreadyTranslated": true
	},
	"Okay": {
		"text": "În regulă",
		"isAlreadyTranslated": true
	},
	"Oops! There are no verified service centres around.": {
		"text": "Ups! Nu există centre de service verificate în zonă.",
		"isAlreadyTranslated": true
	},
	"Oops... Something went wrong.": {
		"text": "Ups... Ceva nu a funcționat corect.",
		"isAlreadyTranslated": true
	},
	"OR": {
		"text": "SAU",
		"isAlreadyTranslated": true
	},
	"Other": {
		"text": "Altele",
		"isAlreadyTranslated": true
	},
	"other": {
		"text": "altele",
		"isAlreadyTranslated": true
	},
	"Other Requests": {
		"text": "Alte solicitări",
		"isAlreadyTranslated": true
	},
	"Out of Warranty": {
		"text": "În afara garanției",
		"isAlreadyTranslated": true
	},
	"Override Approval": {
		"text": "Aprobare înlocuire",
		"isAlreadyTranslated": true
	},
	"Override Approval History": {
		"text": "Istoric de aprobare a înlocuirii",
		"isAlreadyTranslated": true
	},
	"Override only if customer instructs to": {
		"text": "Înlocuiți numai dacă clientul dă instrucțiuni în acest sens",
		"isAlreadyTranslated": true
	},
	"override_approval": {
		"text": "suprascriere_aprobare",
		"isAlreadyTranslated": true
	},
	"Paid": {
		"text": "Plătit",
		"isAlreadyTranslated": true
	},
	"Part Code": {
		"text": "Cod piesă",
		"isAlreadyTranslated": true
	},
	"Part Name": {
		"text": "Denumire piesă",
		"isAlreadyTranslated": true
	},
	"Parts and Estimation": {
		"text": "Piese și estimare",
		"isAlreadyTranslated": true
	},
	"Parts Pending": {
		"text": "Piese în așteptare",
		"isAlreadyTranslated": true
	},
	"Parts Received": {
		"text": "Piese primite",
		"isAlreadyTranslated": true
	},
	"Parts Requested": {
		"text": "Piese solicitate",
		"isAlreadyTranslated": true
	},
	"Parts Supply Location": {
		"text": "Locația de aprovizionare cu piese de schimb",
		"isAlreadyTranslated": true
	},
	"Pickup Drop By Fedex": {
		"text": "Pickup Drop By Fedex",
		"isAlreadyTranslated": true
	},
	"Please enter at least one document": {
		"text": "Introduceți cel puțin un document",
		"isAlreadyTranslated": true
	},
	"Please enter correct": {
		"text": "Introduceți datele corecte",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI": {
		"text": "Introduceți IMEI corect",
		"isAlreadyTranslated": true
	},
	"Please enter reason for approval": {
		"text": "Introduceți motivul pentru aprobare",
		"isAlreadyTranslated": true
	},
	"Please select a subcategory first": {
		"text": "Selectați mai întâi o subcategorie",
		"isAlreadyTranslated": true
	},
	"Please specify the reason": {
		"text": "Specificați motivul",
		"isAlreadyTranslated": true
	},
	"QC Engineer": {
		"text": "Inginer QC",
		"isAlreadyTranslated": true
	},
	"Raise Consumer Service Request": {
		"text": "Formulați o solicitare de service pentru consumatori",
		"isAlreadyTranslated": true
	},
	"Raise new request": {
		"text": "Formulați o solicitare nouă",
		"isAlreadyTranslated": true
	},
	"Reason for Approval": {
		"text": "Motivul aprobării",
		"isAlreadyTranslated": true
	},
	"Receive Device": {
		"text": "Recepționare dispozitiv",
		"isAlreadyTranslated": true
	},
	"Reject Claim": {
		"text": "Respingeți solicitarea de despăgubire",
		"isAlreadyTranslated": true
	},
	"Repair cancel": {
		"text": "Anulare reparații",
		"isAlreadyTranslated": true
	},
	"Report Issues to GSX": {
		"text": "Raportați problemele la GSX",
		"isAlreadyTranslated": true
	},
	"Request Cancelled": {
		"text": "Solicitare anulată",
		"isAlreadyTranslated": true
	},
	"Request Details": {
		"text": "Solicitare detalii",
		"isAlreadyTranslated": true
	},
	"Request Documents": {
		"text": "Solicitare documente",
		"isAlreadyTranslated": true
	},
	"Request Has Been Raised": {
		"text": "Solicitarea a fost formulată",
		"isAlreadyTranslated": true
	},
	"Request is being processed": {
		"text": "Solicitarea este în curs de procesare",
		"isAlreadyTranslated": true
	},
	"Request Scheduled for": {
		"text": "Solicitare programată pentru",
		"isAlreadyTranslated": true
	},
	"Requested By": {
		"text": "Solicitat de",
		"isAlreadyTranslated": true
	},
	"Requires customer to print documents and safely pack the device": {
		"text": "Clientul trebuie să imprime documentele și să împacheteze corespunzător dispozitivul",
		"isAlreadyTranslated": true
	},
	"Reschedule": {
		"text": "Reprogramare",
		"isAlreadyTranslated": true
	},
	"Retailer Name": {
		"text": "Numele vânzătorului",
		"isAlreadyTranslated": true
	},
	"Rewards updated successfully": {
		"text": "Recompensele au fost actualizate cu succes",
		"isAlreadyTranslated": true
	},
	"Role": {
		"text": "Rol",
		"isAlreadyTranslated": true
	},
	"Save": {
		"text": "Salvare",
		"isAlreadyTranslated": true
	},
	"Schedule Date": {
		"text": "Dată programare",
		"isAlreadyTranslated": true
	},
	"Select a Brand": {
		"text": "Selectați o marcă",
		"isAlreadyTranslated": true
	},
	"Select a Consumer Product": {
		"text": "Selectați un produs al consumatorului",
		"isAlreadyTranslated": true
	},
	"Select a Date": {
		"text": "Selectați o dată",
		"isAlreadyTranslated": true
	},
	"Select a Product": {
		"text": "Selectați un produs",
		"isAlreadyTranslated": true
	},
	"Select a Reward": {
		"text": "Selectați o recompensă",
		"isAlreadyTranslated": true
	},
	"Select a service center": {
		"text": "Selectați un centru de service",
		"isAlreadyTranslated": true
	},
	"Select a service location": {
		"text": "Selectați o locație de service",
		"isAlreadyTranslated": true
	},
	"Select a Service Mode": {
		"text": "Selectați un mod de service",
		"isAlreadyTranslated": true
	},
	"Select a Slot": {
		"text": "Selectați un interval",
		"isAlreadyTranslated": true
	},
	"Select a Subcategory": {
		"text": "Selectați o subcategorie",
		"isAlreadyTranslated": true
	},
	"Select an Address": {
		"text": "Selectați o adresă",
		"isAlreadyTranslated": true
	},
	"Select an engineer": {
		"text": "Selectați un Inginer",
		"isAlreadyTranslated": true
	},
	"Select issues": {
		"text": "Selectați problemele",
		"isAlreadyTranslated": true
	},
	"Select Logistics Partner": {
		"text": "Selectați partenerul logistic",
		"isAlreadyTranslated": true
	},
	"Select type of damage": {
		"text": "Selectați tipul de deteriorare",
		"isAlreadyTranslated": true
	},
	"Selected Product": {
		"text": "Produs selectat",
		"isAlreadyTranslated": true
	},
	"Send for raising a request?": {
		"text": "Trimiteți pentru a formula o solicitare?",
		"isAlreadyTranslated": true
	},
	"Search issues": {
		"text": "Căutare probleme",
		"isAlreadyTranslated": true
	},
	"Search brands": {
		"text": "Căutare mărci",
		"isAlreadyTranslated": true
	},
	"Search products": {
		"text": "Căutare produse",
		"isAlreadyTranslated": true
	},
	"Search subcategories": {
		"text": "Căutare subcategorii",
		"isAlreadyTranslated": true
	},
	"Serial No": {
		"text": "Nr. de serie",
		"isAlreadyTranslated": true
	},
	"Serial Number": {
		"text": "Număr de serie",
		"isAlreadyTranslated": true
	},
	"Service": {
		"text": "Service",
		"isAlreadyTranslated": true
	},
	"Service cancel": {
		"text": "Anulare service",
		"isAlreadyTranslated": true
	},
	"Service Center": {
		"text": "Centru de service",
		"isAlreadyTranslated": true
	},
	"Service completed": {
		"text": "Service finalizat",
		"isAlreadyTranslated": true
	},
	"Service Engineer": {
		"text": "Inginer de service",
		"isAlreadyTranslated": true
	},
	"Service Type": {
		"text": "Tip de service",
		"isAlreadyTranslated": true
	},
	"Slot": {
		"text": "Interval",
		"isAlreadyTranslated": true
	},
	"Slot Time": {
		"text": "Timp interval",
		"isAlreadyTranslated": true
	},
	"Something went wrong": {
		"text": "Ceva nu a funcționat corect",
		"isAlreadyTranslated": true
	},
	"Something went wrong while adding the product": {
		"text": "Ceva nu a funcționat corect la adăugarea produsului",
		"isAlreadyTranslated": true
	},
	"Something went wrong while raising service request": {
		"text": "Ceva nu a funcționat corect la formularea solicitării de service",
		"isAlreadyTranslated": true
	},
	"Start Repair": {
		"text": "Inițiere reparație",
		"isAlreadyTranslated": true
	},
	"Started On": {
		"text": "Inițiată la",
		"isAlreadyTranslated": true
	},
	"Status": {
		"text": "Stare",
		"isAlreadyTranslated": true
	},
	"Storage Capacity": {
		"text": "Capacitate de stocare",
		"isAlreadyTranslated": true
	},
	"Store Manager": {
		"text": "Manager de magazin",
		"isAlreadyTranslated": true
	},
	"Submit": {
		"text": "Trimitere",
		"isAlreadyTranslated": true
	},
	"success": {
		"text": "succes",
		"isAlreadyTranslated": true
	},
	"Success": {
		"text": "Succes",
		"isAlreadyTranslated": true
	},
	"Superadmin": {
		"text": "Superadmin",
		"isAlreadyTranslated": true
	},
	"Supervisor": {
		"text": "Supervizor",
		"isAlreadyTranslated": true
	},
	"Telecon Version:": {
		"text": "Versiune Telecon:",
		"isAlreadyTranslated": true
	},
	"The Green Reward cash amount will be credited to the account associated with this number": {
		"text": "Suma în numerar a Recompensei verzi va fi creditată în contul asociat cu acest număr",
		"isAlreadyTranslated": true
	},
	"The request was raised successfully": {
		"text": "Solicitarea a fost transmisă cu succes",
		"isAlreadyTranslated": true
	},
	"The two numbers do not match": {
		"text": "Cele două numere nu corespund",
		"isAlreadyTranslated": true
	},
	"There is no service for this address at this moment": {
		"text": "Nu există niciun serviciu pentru această adresă în acest moment",
		"isAlreadyTranslated": true
	},
	"There's a problem with this landmark, please select a different landmark": {
		"text": "Există o problemă cu acest tara, selectați un alt tara",
		"isAlreadyTranslated": true
	},
	"Time of Damage": {
		"text": "Când s-a produs deteriorarea",
		"isAlreadyTranslated": true
	},
	"Total Amount": {
		"text": "Suma totală",
		"isAlreadyTranslated": true
	},
	"Select Damage Type": {
		"text": "Selectați tipul de deteriorare",
		"isAlreadyTranslated": true
	},
	"Type Of Program": {
		"text": "Tipul de program",
		"isAlreadyTranslated": true
	},
	"Under Warranty": {
		"text": "În garanție",
		"isAlreadyTranslated": true
	},
	"Validate": {
		"text": "Validare",
		"isAlreadyTranslated": true
	},
	"Validity Text": {
		"text": "Text valabilitate",
		"isAlreadyTranslated": true
	},
	"Verification Code": {
		"text": "Cod de verificare",
		"isAlreadyTranslated": true
	},
	"View": {
		"text": "Vizualizare",
		"isAlreadyTranslated": true
	},
	"View Advance Receipt": {
		"text": "Vizualizare chitanță avans",
		"isAlreadyTranslated": true
	},
	"View DOA Certificate": {
		"text": "Vizualizare certificat de stare nefuncțională la primire",
		"isAlreadyTranslated": true
	},
	"View Invoice": {
		"text": "Vizualizare factură",
		"isAlreadyTranslated": true
	},
	"View Jobsheet": {
		"text": "Vizualizare fișă de lucru",
		"isAlreadyTranslated": true
	},
	"View Parts Document": {
		"text": "Vizualizare document privind piesele",
		"isAlreadyTranslated": true
	},
	"Waiting for the customer to raise a request": {
		"text": "Se așteaptă formularea unei solicitări de către client",
		"isAlreadyTranslated": true
	},
	"Warranty": {
		"text": "Garanție",
		"isAlreadyTranslated": true
	},
	"Warranty Applicable": {
		"text": "Garanție aplicabilă",
		"isAlreadyTranslated": true
	},
	"Warranty Status": {
		"text": "Starea garanției",
		"isAlreadyTranslated": true
	},
	"Warranty Till": {
		"text": "Garanție valabilă până la",
		"isAlreadyTranslated": true
	},
	"Waybill Number": {
		"text": "Număr foaie de parcurs",
		"isAlreadyTranslated": true
	},
	"Year of Purchase": {
		"text": "Anul achiziției",
		"isAlreadyTranslated": true
	},
	"Yes": {
		"text": "Da",
		"isAlreadyTranslated": true
	},
	"You have already added this document type in the list of pending documents": {
		"text": "Ați adăugat deja acest tip de document în lista de documente în așteptare",
		"isAlreadyTranslated": true
	},
	"You have already added this issue": {
		"text": "Ați adăugat deja această problemă",
		"isAlreadyTranslated": true
	},
	"Zipcode": {
		"text": "Cod poștal",
		"isAlreadyTranslated": true
	},
	"Raise Claim": {
		"text": "Formulați solicitarea de despăgubire",
		"isAlreadyTranslated": true
	},
	"Invalid Claim": {
		"text": "Solicitare de despăgubire nevalidă",
		"isAlreadyTranslated": true
	},
	"Reject Estimation": {
		"text": "Respingeți estimarea",
		"isAlreadyTranslated": true
	},
	"Collect Advance": {
		"text": "Colectare avans",
		"isAlreadyTranslated": true
	},
	"Complete QC": {
		"text": "QC finalizat",
		"isAlreadyTranslated": true
	},
	"Handover device": {
		"text": "Predare dispozitiv",
		"isAlreadyTranslated": true
	},
	"Accept device": {
		"text": "Acceptare dispozitiv",
		"isAlreadyTranslated": true
	},
	"Complete Job and Handover Device": {
		"text": "Finalizare lucrare și predare dispozitiv",
		"isAlreadyTranslated": true
	},
	"Collect Device": {
		"text": "Colectare dispozitiv",
		"isAlreadyTranslated": true
	},
	"Accept/Reject Job": {
		"text": "Acceptare/respingere lucrare",
		"isAlreadyTranslated": true
	},
	"Generate estimation": {
		"text": "Generare estimare",
		"isAlreadyTranslated": true
	},
	"characters entered.": {
		"text": "caractere introduse.",
		"isAlreadyTranslated": true
	},
	"Your GSX Request has been created": {
		"text": "Solicitarea dvs. GSX a fost creată",
		"isAlreadyTranslated": true
	},
	"Upload Inspection Documents": {
		"text": "Încărcați documentele de inspecție",
		"isAlreadyTranslated": true
	},
	"Complete DOA": {
		"text": "Completați stare nefuncțională la primire",
		"isAlreadyTranslated": true
	},
	"QC Completed": {
		"text": "QC finalizat",
		"isAlreadyTranslated": true
	},
	"Create GSX request": {
		"text": "Creați solicitare GSX",
		"isAlreadyTranslated": true
	},
	"DOA Checklist": {
		"text": "Listă de verificare stare nefuncțională la primire",
		"isAlreadyTranslated": true
	},
	"Reject Parts Request": {
		"text": "Respingerea solicitării de piese",
		"isAlreadyTranslated": true
	},
	"Accept Parts Request": {
		"text": "Acceptarea solicitării de piese",
		"isAlreadyTranslated": true
	},
	"Cannot Diagnose": {
		"text": "Nu se poate diagnostica",
		"isAlreadyTranslated": true
	},
	"Initiate Diagnosis": {
		"text": "Inițiere diagnostic",
		"isAlreadyTranslated": true
	},
	"Diagnosis Completed": {
		"text": "Diagnostic finalizat",
		"isAlreadyTranslated": true
	},
	"Accept Device": {
		"text": "Acceptare dispozitiv",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer to accept the job.": {
		"text": "Se așteaptă ca Inginerul să accepte lucrarea.",
		"isAlreadyTranslated": true
	},
	"Transfer Request": {
		"text": "Solicitare de transfer",
		"isAlreadyTranslated": true
	},
	"Accept and Complete QC": {
		"text": "Acceptați și finalizați QC",
		"isAlreadyTranslated": true
	},
	"Reject QC": {
		"text": "Respingeți QC",
		"isAlreadyTranslated": true
	},
	"Confirm BER": {
		"text": "Confirmați BER",
		"isAlreadyTranslated": true
	},
	"Reject BER": {
		"text": "Respingeți BER",
		"isAlreadyTranslated": true
	},
	"Perform QC": {
		"text": "Efectuați QC",
		"isAlreadyTranslated": true
	},
	"Fault Codes": {
		"text": "Coduri de defecțiune",
		"isAlreadyTranslated": true
	},
	"Actions": {
		"text": "Acțiuni",
		"isAlreadyTranslated": true
	},
	"Engineer Added Faults": {
		"text": "Inginerul a adăugat defecțiuni",
		"isAlreadyTranslated": true
	},
	"Faults": {
		"text": "Defecțiuni",
		"isAlreadyTranslated": true
	},
	"Action Code": {
		"text": "Cod de acțiune",
		"isAlreadyTranslated": true
	},
	"N/A": {
		"text": "N/A",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to receive device and complete mandatory checklist tasks.": {
		"text": "Se așteaptă ca Specialistul departamentului de asistență clienți să primească dispozitivul și să finalizeze activitățile obligatorii din lista de verificare.",
		"isAlreadyTranslated": true
	},
	"Waiting for CC to receive device and complete mandatory checklist tasks.": {
		"text": "Se așteaptă ca CC să primească dispozitivul și să finalizeze activitățile obligatorii din lista de verificare.",
		"isAlreadyTranslated": true
	},
	"Yes, the device is whitelisted. Please upload screenshot of the same": {
		"text": "Da, dispozitivul se află pe lista albă. Încărcați o captură de ecran cu dispozitivul",
		"isAlreadyTranslated": true
	},
	"No pictures added": {
		"text": "Nu au fost adăugate imagini",
		"isAlreadyTranslated": true
	},
	"No, the device is not whitelisted. Please cancel the request and return the device if you have it": {
		"text": "Nu, dispozitivul nu se află pe lista albă. Anulați solicitarea și returnați dispozitivul, dacă îl aveți.",
		"isAlreadyTranslated": true
	},
	"Next": {
		"text": "Următorul",
		"isAlreadyTranslated": true
	},
	"Device Name": {
		"text": "Numele dispozitivului",
		"isAlreadyTranslated": true
	},
	"ELS": {
		"text": "ELS",
		"isAlreadyTranslated": true
	},
	"QC": {
		"text": "QC",
		"isAlreadyTranslated": true
	},
	"Please select Coverage Option": {
		"text": "Selectați opțiunea de acoperire",
		"isAlreadyTranslated": true
	},
	"DOA": {
		"text": "Stare nefuncțională la primire",
		"isAlreadyTranslated": true
	},
	"Device Whitelisting Information": {
		"text": "Informații privind listarea dispozitivului pe lista albă",
		"isAlreadyTranslated": true
	},
	"KBB Serial Number": {
		"text": "Număr de serie KBB (placă logică defectă cunoscută)",
		"isAlreadyTranslated": true
	},
	"Inspection Completed, Awaiting Engineer Assignment": {
		"text": "Inspecție finalizată, în așteptarea atribuirii Inginerului",
		"isAlreadyTranslated": true
	},
	"Verification Complete": {
		"text": "Verificare finalizată",
		"isAlreadyTranslated": true
	},
	"Dropoff Request Registered": {
		"text": "Solicitare de predare înregistrată",
		"isAlreadyTranslated": true
	},
	"Service initiated": {
		"text": "Service inițiat",
		"isAlreadyTranslated": true
	},
	"Device dropped": {
		"text": "Dispozitiv predat",
		"isAlreadyTranslated": true
	},
	"Recycle Dropoff": {
		"text": "Predare la reciclare",
		"isAlreadyTranslated": true
	},
	"Pledge request created": {
		"text": "Solicitare de angajament creată",
		"isAlreadyTranslated": true
	},
	"QC completed": {
		"text": "QC finalizat",
		"isAlreadyTranslated": true
	},
	"sdv": {
		"text": "sdv",
		"isAlreadyTranslated": true
	},
	"Intimate customer": {
		"text": "Informare client",
		"isAlreadyTranslated": true
	},
	"Repair has been cancelled.": {
		"text": "Reparația a fost anulată.",
		"isAlreadyTranslated": true
	},
	"BTB socket": {
		"text": "Priză BTB",
		"isAlreadyTranslated": true
	},
	"SIM Card needle": {
		"text": "Ac pentru cartela SIM",
		"isAlreadyTranslated": true
	},
	"SIM card holder EB101 grey aluminum alloy": {
		"text": "Suport pentru cartela SIM EB101 din aliaj de aluminiu gri",
		"isAlreadyTranslated": true
	},
	"Pending for Acceptance by Engineer": {
		"text": "În așteptarea acceptării de către Inginer",
		"isAlreadyTranslated": true
	},
	"microphone": {
		"text": "microfon",
		"isAlreadyTranslated": true
	},
	"for Token generated": {
		"text": "pentru tokenul generat",
		"isAlreadyTranslated": true
	},
	"Advance collected": {
		"text": "Avans colectat",
		"isAlreadyTranslated": true
	},
	"pin added": {
		"text": "pin adăugat",
		"isAlreadyTranslated": true
	},
	"Trade In Dropoff": {
		"text": "Schimbul la predare",
		"isAlreadyTranslated": true
	},
	"No, the device is not whitelisted. Please return the device if you have it": {
		"text": "Nu, dispozitivul nu se află pe lista albă. Returnați dispozitivul, dacă îl aveți",
		"isAlreadyTranslated": true
	},
	"Download Open/Inprogress requests.": {
		"text": "Descărcați solicitări deschise/în curs de soluționare.",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer to accept the job. (Eligible for DOA)": {
		"text": "Se așteaptă ca Inginerul să accepte lucrarea. (Eligibil pentru încadrare în stare nefuncțională la primire)",
		"isAlreadyTranslated": true
	},
	"(Eligible for DOA)": {
		"text": " (Eligibil pentru încadrare în stare nefuncțională la primire)",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive will choose action for the device from Dead on Arrival section below.": {
		"text": "Specialistul departamentului de asistență clienți va alege o acțiune pentru dispozitiv din secțiunea Stare nefuncțională la primire de mai jos.",
		"isAlreadyTranslated": true
	},
	"Engineer will select the new device from Dead on Arrival section below": {
		"text": "Inginerul va selecta noul dispozitiv din secțiunea Stare nefuncțională la primire de mai jos",
		"isAlreadyTranslated": true
	},
	"Device repair has failed QC. Device is to be given back to the Engineer.": {
		"text": "Reparația dispozitivului nu a trecut testul QC. Dispozitivul va fi returnat Inginerului.",
		"isAlreadyTranslated": true
	},
	'Waiting for Estimation Approval. Click on "Send for approval" in "Parts and Estimation" section below.': {
		"text": "„Se așteaptă aprobarea estimării. Faceți clic pe „Trimiteți spre aprobare” în secțiunea „Piese și estimare” de mai jos.",
		"isAlreadyTranslated": true
	},
	'Waiting for customer approval of estimation or override estimation approval in "Parts and Estimation" section below.': {
		"text": "Se așteaptă aprobarea estimării de către client sau înlocuirea aprobării estimării în secțiunea „Piese și estimare” de mai jos.",
		"isAlreadyTranslated": true
	},
	"Estimation has been rejected, parts may be added if required by the engineer.": {
		"text": "Estimarea a fost respinsă, se pot adăuga piese dacă Inginerul solicită acest lucru.",
		"isAlreadyTranslated": true
	},
	'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.': {
		"text": "Se așteaptă ca magazinul să elibereze piesele solicitate în secțiunea „Piese solicitate” de mai jos. Nu se poate finaliza reparația deoarece există piese în așteptare.",
		"isAlreadyTranslated": true
	},
	'Waiting for store to issue required device in "Dead on arrival" section below.': {
		"text": "Se așteaptă ca magazinul să elibereze dispozitivul solicitat în secțiunea „Stare nefuncțională la primire” de mai jos.",
		"isAlreadyTranslated": true
	},
	'Waiting for the Engineer to receive the "Replacement device" from store in "Dead on Arrival" section below.': {
		"text": "Se așteaptă ca Inginerul să primească „Dispozitivul înlocuitor” de la magazin în secțiunea „Stare nefuncțională la primire” de mai jos.",
		"isAlreadyTranslated": true
	},
	'Waiting for Engineer to receive parts from store in "Parts Requested" section below.': {
		"text": "Se așteaptă ca Inginerul să primească piesele de la magazin în secțiunea „Piese solicitate” de mai jos.",
		"isAlreadyTranslated": true
	},
	"Waiting for the Engineer to complete DOA by handing over the damaged device to Store": {
		"text": "Se așteaptă ca Inginerul să finalizeze evaluarea Stare nefuncțională la primire prin predarea dispozitivului deteriorat către Magazin",
		"isAlreadyTranslated": true
	},
	"All parts received, repair is in progress.": {
		"text": "Toate piesele au fost primite, reparația este în desfășurare.",
		"isAlreadyTranslated": true
	},
	"Engineer completed the repair.": {
		"text": "Inginerul a finalizat reparația.",
		"isAlreadyTranslated": true
	},
	"Service invoice has been generated, final payment can be received.": {
		"text": "Factura de service a fost generată, plata finală poate fi primită.",
		"isAlreadyTranslated": true
	},
	"Waiting for the engineer to handover device to Store": {
		"text": "Se așteaptă ca Inginerul să predea dispozitivul la Magazin",
		"isAlreadyTranslated": true
	},
	"Waiting for the Store to handover device to Customer Care Executive": {
		"text": "Se așteaptă ca Magazinul să predea dispozitivul către Specialistul departamentului de asistență clienți",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to complete the DOA": {
		"text": "Se așteaptă ca Specialistul departamentului de asistență clienți să finalizeze evaluarea Stare nefuncțională la primire",
		"isAlreadyTranslated": true
	},
	"Please complete the service.": {
		"text": "Finalizați operațiunea de service.",
		"isAlreadyTranslated": true
	},
	"Waiting for the customer to rate the service": {
		"text": "Se așteaptă ca clientul să evalueze operațiunea de service",
		"isAlreadyTranslated": true
	},
	"Intimate customer.": {
		"text": "Informare client.",
		"isAlreadyTranslated": true
	},
	"Logistics": {
		"text": "Logistică",
		"isAlreadyTranslated": true
	},
	"Send link": {
		"text": "Trimitere link",
		"isAlreadyTranslated": true
	},
	"Other Image": {
		"text": "Altă imagine",
		"isAlreadyTranslated": true
	},
	"Item": {
		"text": "Articol",
		"isAlreadyTranslated": true
	},
	"Transfer Details": {
		"text": "Detalii de transfer",
		"isAlreadyTranslated": true
	},
	"Schedule a Logistic Request": {
		"text": "Programați o solicitare logistică",
		"isAlreadyTranslated": true
	},
	"Forward": {
		"text": "Înainte",
		"isAlreadyTranslated": true
	},
	"Reverse": {
		"text": "Înapoi",
		"isAlreadyTranslated": true
	},
	"Transfer Details": {
		"text": "Detalii de transfer",
		"isAlreadyTranslated": true
	},
	"Forward": {
		"text": "Înainte",
		"isAlreadyTranslated": true
	},
	"Reverse": {
		"text": "Înapoi",
		"isAlreadyTranslated": true
	},
	"Track Logistics Requests": {
		"text": "Urmăriți solicitările de logistică",
		"isAlreadyTranslated": true
	},
	"Pickup Pincode": {
		"text": "Cod PIN preluare",
		"isAlreadyTranslated": true
	},
	"Recycle Request Schedule Date": {
		"text": "Data de programare a solicitării de reciclare",
		"isAlreadyTranslated": true
	},
	"Recycle Request Created Date": {
		"text": "Data de creare a solicitării de reciclare",
		"isAlreadyTranslated": true
	},
	"Schedule Logistics Request": {
		"text": "Programați solicitarea de logistică",
		"isAlreadyTranslated": true
	},
	"Hub's Address Details": {
		"text": "Detalii privind adresa hubului",
		"isAlreadyTranslated": true
	},
	"Now": {
		"text": "Acum",
		"isAlreadyTranslated": true
	},
	"Later": {
		"text": "Mai târziu",
		"isAlreadyTranslated": true
	},
	"Recycle Hub Details": {
		"text": "Detaliile hubului de reciclare",
		"isAlreadyTranslated": true
	},
	"This request will be added to a queue. You can schedule a logistics request later.": {
		"text": "Această solicitare va fi adăugată la o coadă de așteptare. Puteți programa o solicitare de logistică mai târziu.",
		"isAlreadyTranslated": true
	},
	"Request Added to Queue Successfully": {
		"text": "Solicitare adăugată cu succes la coadă",
		"isAlreadyTranslated": true
	},
	"Create Bulk Requests": {
		"text": "Creați solicitări în masă",
		"isAlreadyTranslated": true
	},
	"Logistics Status": {
		"text": "Stare logistică",
		"isAlreadyTranslated": true
	},
	"Drop Pincode": {
		"text": "Cod PIN predare",
		"isAlreadyTranslated": true
	},
	"Logistic Request Created Date": {
		"text": "Data de creare a solicitării logistice",
		"isAlreadyTranslated": true
	},
	"Logistic Vendor": {
		"text": "Vânzător de servicii de logistică",
		"isAlreadyTranslated": true
	},
	"Bulk Logistic Reference ID": {
		"text": "ID de referință pentru logistică masivă",
		"isAlreadyTranslated": true
	},
	"Not Covered": {
		"text": "Neacoperit",
		"isAlreadyTranslated": true
	},
	"Queue": {
		"text": "Coadă",
		"isAlreadyTranslated": true
	},
	"LOGISTICS REQUESTS": {
		"text": "SOLICITĂRI DE LOGISTICĂ",
		"isAlreadyTranslated": true
	},
	"Work in progress": {
		"text": "Lucrare în desfășurare",
		"isAlreadyTranslated": true
	},
	"Recycle Pickup": {
		"text": "Preluare reciclare",
		"isAlreadyTranslated": true
	},
	"Recycle Request": {
		"text": "Solicitare de reciclare",
		"isAlreadyTranslated": true
	},
	"Beyond Economic Repair": {
		"text": "Reparație costisitoare",
		"isAlreadyTranslated": true
	},
	"Device collected": {
		"text": "Dispozitiv colectat",
		"isAlreadyTranslated": true
	},
	"Green Reward Update": {
		"text": "Actualizare privind recompensa verde",
		"isAlreadyTranslated": true
	},
	"Vouchers generation pending": {
		"text": "Generarea cupoanelor în așteptare",
		"isAlreadyTranslated": true
	},
	"Recycle Request cancelled": {
		"text": "Solicitare de reciclare anulată",
		"isAlreadyTranslated": true
	},
	"Recycle Completed": {
		"text": "Reciclare finalizată",
		"isAlreadyTranslated": true
	},
	"Vouchers generated": {
		"text": "Cupoane generate",
		"isAlreadyTranslated": true
	},
	"Initial Dismantling Completed": {
		"text": "Demontarea inițială finalizată",
		"isAlreadyTranslated": true
	},
	"Device recycle initiated": {
		"text": "Reciclarea dispozitivului inițiată",
		"isAlreadyTranslated": true
	},
	"Claim withdrawn": {
		"text": "Solicitare de despăgubire retrasă",
		"isAlreadyTranslated": true
	},
	"Pending Verification": {
		"text": "Verificare în așteptare",
		"isAlreadyTranslated": true
	},
	"Screen damage": {
		"text": "Deteriorarea ecranului",
		"isAlreadyTranslated": true
	},
	"Print": {
		"text": "Imprimare",
		"isAlreadyTranslated": true
	},
	"Pupa Service Record": {
		"text": "Pupa Service Record",
		"isAlreadyTranslated": true
	},
	"User Name": {
		"text": "Nume de utilizator",
		"isAlreadyTranslated": true
	},
	"Telephone": {
		"text": "Telefon",
		"isAlreadyTranslated": true
	},
	"Product Model": {
		"text": "Model de produs",
		"isAlreadyTranslated": true
	},
	"Purchase Date": {
		"text": "Data achiziției",
		"isAlreadyTranslated": true
	},
	"Carry-in Time": {
		"text": "Ora de ridicare",
		"isAlreadyTranslated": true
	},
	"Pick-up Time": {
		"text": "Ora de preluare",
		"isAlreadyTranslated": true
	},
	"Key Account Name": {
		"text": "Numele contului cheie",
		"isAlreadyTranslated": true
	},
	"Service Offering": {
		"text": "Oferta de service",
		"isAlreadyTranslated": true
	},
	"In warranty": {
		"text": "În garanție",
		"isAlreadyTranslated": true
	},
	"Problem Description by User": {
		"text": "Descrierea problemei de către utilizator",
		"isAlreadyTranslated": true
	},
	"Parts replacement": {
		"text": "Înlocuirea pieselor",
		"isAlreadyTranslated": true
	},
	"New IMEI/SN": {
		"text": "IMEI/nr. serie nou",
		"isAlreadyTranslated": true
	},
	"Part Number": {
		"text": "Număr piesă",
		"isAlreadyTranslated": true
	},
	"Model Number": {
		"text": "Număr model",
		"isAlreadyTranslated": true
	},
	"Replacement Name": {
		"text": "Nume înlocuitor",
		"isAlreadyTranslated": true
	},
	"Engineer Signature": {
		"text": "Semnătura Inginerului",
		"isAlreadyTranslated": true
	},
	"Finish Date": {
		"text": "Data de finalizare",
		"isAlreadyTranslated": true
	},
	"Very Satisfied": {
		"text": "Foarte mulțumit(ă)",
		"isAlreadyTranslated": true
	},
	"Satisfied": {
		"text": "Mulțumit(ă)",
		"isAlreadyTranslated": true
	},
	"Neutral": {
		"text": "Neutru",
		"isAlreadyTranslated": true
	},
	"Dissatisfied": {
		"text": "Nemulțumit(ă)",
		"isAlreadyTranslated": true
	},
	"Very Dissatisfied": {
		"text": "Foarte nemulțumit(ă)",
		"isAlreadyTranslated": true
	},
	"User Signature": {
		"text": "Semnătura utilizatorului",
		"isAlreadyTranslated": true
	},
	"Date": {
		"text": "Data",
		"isAlreadyTranslated": true
	},
	"Thank you for choosing Pupa": {
		"text": "Vă mulțumim că ați ales Pupa",
		"isAlreadyTranslated": true
	},
	"Pupa Service Form": {
		"text": "Formularul de service Pupa",
		"isAlreadyTranslated": true
	},
	"Recovery Method": {
		"text": "Metoda de recuperare",
		"isAlreadyTranslated": true
	},
	"Defective Model Name": {
		"text": "Denumirea modelului defect",
		"isAlreadyTranslated": true
	},
	"Defective Part Name": {
		"text": "Denumirea piesei defecte",
		"isAlreadyTranslated": true
	},
	"Coverage Type": {
		"text": "Tipul de acoperire",
		"isAlreadyTranslated": true
	},
	"Service Ticket No": {
		"text": "Tichet de service nr.",
		"isAlreadyTranslated": true
	},
	"Customer Care": {
		"text": "Asistență clienți",
		"isAlreadyTranslated": true
	},
	"Are you satisfied with Pupa service experience": {
		"text": "Sunteți mulțumit de experiența cu service-ul Pupa?",
		"isAlreadyTranslated": true
	},
	"Please comment if you are dissatisfied": {
		"text": "Comentați dacă sunteți nemulțumit(ă)",
		"isAlreadyTranslated": true
	},
	"Front Image": {
		"text": "Imagine din față",
		"isAlreadyTranslated": true
	},
	"Back Image": {
		"text": "Imagine din spate",
		"isAlreadyTranslated": true
	},
	"Top Image": {
		"text": "Imagine de sus",
		"isAlreadyTranslated": true
	},
	"Bottom Image": {
		"text": "Imagine de jos",
		"isAlreadyTranslated": true
	},
	"Left Image": {
		"text": "Imagine din stânga",
		"isAlreadyTranslated": true
	},
	"Right Image": {
		"text": "Imagine din dreapta",
		"isAlreadyTranslated": true
	},
	"Device Invoice": {
		"text": "Factură dispozitiv",
		"isAlreadyTranslated": true
	},
	"Cosmetic Status of Device": {
		"text": "Starea estetică a dispozitivului",
		"isAlreadyTranslated": true
	},
	"Latest diagnostics result": {
		"text": "Cel mai recent rezultat al diagnosticării",
		"isAlreadyTranslated": true
	},
	"Purchase Order Number": {
		"text": "Numărul comenzii de achiziție",
		"isAlreadyTranslated": true
	},
	"National ID": {
		"text": "ID național",
		"isAlreadyTranslated": true
	},
	"Last Repair Date": {
		"text": "Data ultimei reparații",
		"isAlreadyTranslated": true
	},
	"Product Unique ID": {
		"text": "ID unic al produsului",
		"isAlreadyTranslated": true
	},
	"We were unable to find details from GSX, please select product manually": {
		"text": "Nu am reușit să găsim detalii de la GSX, selectați manual produsul",
		"isAlreadyTranslated": true
	},
	"No serviceable consumer product found": {
		"text": "Nu s-a găsit niciun produs al consumatorului care poate fi reparat în service",
		"isAlreadyTranslated": true
	},
	"Is Warranty Applicable for this request": {
		"text": "Se aplică garanția pentru această solicitare?",
		"isAlreadyTranslated": true
	},
	"Where was the device kept at the time of incident": {
		"text": "Unde se afla dispozitivul în momentul incidentului?",
		"isAlreadyTranslated": true
	},
	"min 100 characters": {
		"text": "min. 100 de caractere",
		"isAlreadyTranslated": true
	},
	"LEFT": {
		"text": "STÂNGA",
		"isAlreadyTranslated": true
	},
	"Please Enter Minimum 100 Characters": {
		"text": "Introduceți minimum 100 de caractere",
		"isAlreadyTranslated": true
	},
	"Enter Other Issue Description": {
		"text": "Introduceți altă descriere a problemei",
		"isAlreadyTranslated": true
	},
	"Send for raising request": {
		"text": "Trimiteți pentru a formula solicitarea",
		"isAlreadyTranslated": true
	},
	"Send for raising request?": {
		"text": "Trimiteți pentru a formula solicitarea?",
		"isAlreadyTranslated": true
	},
	"Air Conditioner": {
		"text": "Aer condiționat",
		"isAlreadyTranslated": true
	},
	"STORAGE": {
		"text": "DEPOZITARE",
		"isAlreadyTranslated": true
	},
	"TELECOM SERVICES": {
		"text": "SERVICII DE TELECOMUNICAȚII",
		"isAlreadyTranslated": true
	},
	"Trade In Pickup": {
		"text": "Schimbul la preluare",
		"isAlreadyTranslated": true
	},
	"Vibrator": {
		"text": "Vibrator",
		"isAlreadyTranslated": true
	},
	"Sound": {
		"text": "Sunet",
		"isAlreadyTranslated": true
	},
	"Display": {
		"text": "Ecran",
		"isAlreadyTranslated": true
	},
	"Accessories": {
		"text": "Accesorii",
		"isAlreadyTranslated": true
	},
	"Physical Damage": {
		"text": "Deteriorare fizică",
		"isAlreadyTranslated": true
	},
	"System Performance": {
		"text": "Performanța sistemului",
		"isAlreadyTranslated": true
	},
	"Power": {
		"text": "Putere",
		"isAlreadyTranslated": true
	},
	"Built in Camera": {
		"text": "Cameră încorporată",
		"isAlreadyTranslated": true
	},
	"Data Storage": {
		"text": "Stocarea datelor",
		"isAlreadyTranslated": true
	},
	"Communication/Networking": {
		"text": "Comunicare/rețele",
		"isAlreadyTranslated": true
	},
	"Consumer Product already exists with Serial/IMEI number": {
		"text": "Există deja produsul consumatorului cu număr de serie/IMEI",
		"isAlreadyTranslated": true
	},
	"Oops. We don’t service this device at this location. But we’re hard at work and you should get some good news soon. Try later?": {
		"text": "Ups. Nu asigurăm service pentru acest dispozitiv în această locație. Dar lucrăm din greu și ar trebui să primiți vești bune în curând. Încercați mai târziu?",
		"isAlreadyTranslated": true
	},
	"Enter Issue Decsription": {
		"text": "Introduceți descrierea problemei",
		"isAlreadyTranslated": true
	},
	"office": {
		"text": "birou",
		"isAlreadyTranslated": true
	},
	"Enter a location": {
		"text": "Introduceți o locație",
		"isAlreadyTranslated": true
	},
	"Accept": {
		"text": "Acceptare",
		"isAlreadyTranslated": true
	},
	"Reject": {
		"text": "Respingere",
		"isAlreadyTranslated": true
	},
	"Warranty Concession": {
		"text": "Concesiune de garanție",
		"isAlreadyTranslated": true
	},
	"No order lines found for given request": {
		"text": "Nu s-au găsit linii de comandă pentru solicitarea respectivă",
		"isAlreadyTranslated": true
	},
	"Toggle Warranty": {
		"text": "Comutare garanție",
		"isAlreadyTranslated": true
	},
	"Provide link for customer": {
		"text": "Furnizați un link pentru client",
		"isAlreadyTranslated": true
	},
	"Accept or Reject Request": {
		"text": "Acceptarea sau respingerea solicitării",
		"isAlreadyTranslated": true
	},
	"Inward": {
		"text": "Interior",
		"isAlreadyTranslated": true
	},
	"Outward": {
		"text": "Exterior",
		"isAlreadyTranslated": true
	},
	"Max number of retry attempts exceeded": {
		"text": "Numărul maxim de încercări a fost depășit",
		"isAlreadyTranslated": true
	},
	"Please Enter Incident Place of Damage": {
		"text": "Introduceți locul în care s-a produs deteriorarea",
		"isAlreadyTranslated": true
	},
	"Please enter the link": {
		"text": "Introduceți linkul",
		"isAlreadyTranslated": true
	},
	"Please Select Device Receiving Time": {
		"text": "Selectați ora de primire a dispozitivului",
		"isAlreadyTranslated": true
	},
	"Protection Status": {
		"text": "Starea protecției",
		"isAlreadyTranslated": true
	},
	"Config Description": {
		"text": "Descrierea configurării",
		"isAlreadyTranslated": true
	},
	"Please specify the reason...": {
		"text": "Specificați motivul...",
		"isAlreadyTranslated": true
	},
	"An error has occurred. Please contact support@servify.in for help.": {
		"text": "S-a produs o eroare. Contactați support@servify.in pentru ajutor.",
		"isAlreadyTranslated": true
	},
	"The parts will be returned to Store": {
		"text": "Piesele vor fi returnate la Magazin",
		"isAlreadyTranslated": true
	},
	"Select a Part Name": {
		"text": "Selectați un nume de piesă",
		"isAlreadyTranslated": true
	},
	"Restore from Backup History": {
		"text": "Restaurare din istoricul de rezervă",
		"isAlreadyTranslated": true
	},
	"PASSED": {
		"text": "TRECUT",
		"isAlreadyTranslated": true
	},
	"Backup History": {
		"text": "Istoric copie de rezervă",
		"isAlreadyTranslated": true
	},
	"WARNING : This device has not been backed up in the last two weeks.": {
		"text": "AVERTISMENT: Nu s-a efectuat o copie de rezervă pentru acest dispozitiv în ultimele două săptămâni.",
		"isAlreadyTranslated": true
	},
	"Sensors": {
		"text": "Senzori",
		"isAlreadyTranslated": true
	},
	"Temperature History": {
		"text": "Istoric temperatură",
		"isAlreadyTranslated": true
	},
	"Accelerometer": {
		"text": "Accelerometru",
		"isAlreadyTranslated": true
	},
	"Accelerometer Sensor Test": {
		"text": "Testul senzorului de accelerometru",
		"isAlreadyTranslated": true
	},
	"ALS": {
		"text": "ALS",
		"isAlreadyTranslated": true
	},
	"Ambient Light Sensor Test": {
		"text": "Testul senzorului de lumină ambientală",
		"isAlreadyTranslated": true
	},
	"Bluetooth": {
		"text": "Bluetooth",
		"isAlreadyTranslated": true
	},
	"Bluetooth Scan Test": {
		"text": "Test de scanare Bluetooth",
		"isAlreadyTranslated": true
	},
	"Camera": {
		"text": "Cameră",
		"isAlreadyTranslated": true
	},
	"Camera Sensor Test": {
		"text": "Testul senzorului camerei",
		"isAlreadyTranslated": true
	},
	"Gyro": {
		"text": "Gyro",
		"isAlreadyTranslated": true
	},
	"Gyro Sensor Test": {
		"text": "Testul senzorului giroscopic",
		"isAlreadyTranslated": true
	},
	"Touch ID": {
		"text": "Touch ID",
		"isAlreadyTranslated": true
	},
	"Presence Test": {
		"text": "Test de prezență",
		"isAlreadyTranslated": true
	},
	"Wi-Fi": {
		"text": "Wi-Fi",
		"isAlreadyTranslated": true
	},
	"Software": {
		"text": "Software",
		"isAlreadyTranslated": true
	},
	"Version Test": {
		"text": "Test versiune",
		"isAlreadyTranslated": true
	},
	"System": {
		"text": "Sistem",
		"isAlreadyTranslated": true
	},
	"Usage Profile": {
		"text": "Profil de utilizare",
		"isAlreadyTranslated": true
	},
	"Battery": {
		"text": "Baterie",
		"isAlreadyTranslated": true
	},
	"Health Check": {
		"text": "Verificarea stării de bună funcționare",
		"isAlreadyTranslated": true
	},
	"PASSED : Testing completed and no issues were found on this device.": {
		"text": "REUȘITĂ Testarea a fost finalizată și nu s-au constatat probleme la acest dispozitiv.",
		"isAlreadyTranslated": true
	},
	"App Crash History": {
		"text": "Istoricul de căderi ale aplicației",
		"isAlreadyTranslated": true
	},
	"Unexpected Shutdown History": {
		"text": "Istoric de închidere neașteptată",
		"isAlreadyTranslated": true
	},
	"Power Button": {
		"text": "Butonul de alimentare",
		"isAlreadyTranslated": true
	},
	"Home Button": {
		"text": "Buton pagină de pornire",
		"isAlreadyTranslated": true
	},
	"Volume Down Button": {
		"text": "Butonul de reducere a volumului",
		"isAlreadyTranslated": true
	},
	"LCD Screen": {
		"text": "Ecran LCD",
		"isAlreadyTranslated": true
	},
	"Ear Piece": {
		"text": "Cască",
		"isAlreadyTranslated": true
	},
	"Back Camera": {
		"text": "Camera din spate",
		"isAlreadyTranslated": true
	},
	"Network Connection": {
		"text": "Conexiune de rețea",
		"isAlreadyTranslated": true
	},
	"IMEI Check": {
		"text": "Verificare IMEI",
		"isAlreadyTranslated": true
	},
	"Incoming call": {
		"text": "Apel primit",
		"isAlreadyTranslated": true
	},
	"Microphone": {
		"text": "Microfon",
		"isAlreadyTranslated": true
	},
	"USB Connectivity": {
		"text": "Conectivitate USB",
		"isAlreadyTranslated": true
	},
	"Battery Storage": {
		"text": "Stocarea bateriilor",
		"isAlreadyTranslated": true
	},
	"LED Flash": {
		"text": "LED Flash",
		"isAlreadyTranslated": true
	},
	"TAB Button": {
		"text": "Buton TAB",
		"isAlreadyTranslated": true
	},
	"Unlock Button": {
		"text": "Buton de deblocare",
		"isAlreadyTranslated": true
	},
	"Full Diagnosis": {
		"text": "Diagnostic complet",
		"isAlreadyTranslated": true
	},
	"Proper Assembly": {
		"text": "Asamblare corectă",
		"isAlreadyTranslated": true
	},
	"Back Button": {
		"text": "Buton înapoi",
		"isAlreadyTranslated": true
	},
	"Volume Up Button": {
		"text": "Butonul de creștere a volumului",
		"isAlreadyTranslated": true
	},
	"Dead Spots": {
		"text": "Puncte moarte",
		"isAlreadyTranslated": true
	},
	"Charging Port": {
		"text": "Port de încărcare",
		"isAlreadyTranslated": true
	},
	"Loud Speaker": {
		"text": "Difuzor",
		"isAlreadyTranslated": true
	},
	"Front Camera": {
		"text": "Cameră frontală",
		"isAlreadyTranslated": true
	},
	"Outgoing call": {
		"text": "Apel efectuat",
		"isAlreadyTranslated": true
	},
	"Software Version": {
		"text": "Versiune de software",
		"isAlreadyTranslated": true
	},
	"GSX Confirmation Number": {
		"text": "Număr de confirmare GSX",
		"isAlreadyTranslated": true
	},
	"Services": {
		"text": "Servicii",
		"isAlreadyTranslated": true
	},
	"Check If Device Box IMEI Matches With Device IMEI": {
		"text": "Verificați dacă nr. IMEI de pe caseta dispozitivului corespunde cu nr. IMEI al dispozitivului",
		"isAlreadyTranslated": true
	},
	"Check If Device IMEI Matches With Internal IMEI": {
		"text": "Verificați dacă nr. IMEI al dispozitivului corespunde cu nr. IMEI intern",
		"isAlreadyTranslated": true
	},
	"Check If Touch/Display Is Broken": {
		"text": "Verificați dacă ecranul tactil/ecran-ul este spart",
		"isAlreadyTranslated": true
	},
	"Check If SIM Tray Is Missing Or Broken": {
		"text": "Verificați dacă tava SIM lipsește sau este defectă",
		"isAlreadyTranslated": true
	},
	"Check If Back Cover Is Damaged": {
		"text": "Verificați dacă carcasa din spate este deteriorată",
		"isAlreadyTranslated": true
	},
	"Check If Screw Heads Are Damaged": {
		"text": "Verificați dacă capetele șuruburilor sunt deteriorate",
		"isAlreadyTranslated": true
	},
	"Check If There Are Dents Or Internal Cracks In Display": {
		"text": "Verificați dacă ecranul este lovit sau are fisuri interne",
		"isAlreadyTranslated": true
	},
	"Check If Volume Keys Are Loose Or Not Functioning": {
		"text": "Verificați dacă tastele de volum sunt slăbite sau nu funcționează",
		"isAlreadyTranslated": true
	},
	"Check If Power Keys Are Loose Or Not Functioning": {
		"text": "Verificați dacă tastele de pornire/oprire sunt slăbite sau nu funcționează",
		"isAlreadyTranslated": true
	},
	"Check If Camera's Auto Focus Is Not Working": {
		"text": "Verificați dacă focalizarea automată a camerei nu funcționează",
		"isAlreadyTranslated": true
	},
	"Check If Photos Clicked Are Blurred": {
		"text": "Verificați dacă fotografiile pe care ați făcut clic sunt neclare",
		"isAlreadyTranslated": true
	},
	"Check If USB Port Is Damaged": {
		"text": "Verificați dacă portul USB este deteriorat",
		"isAlreadyTranslated": true
	},
	"Check If Accessories Are As Mentioned On The Sales Pack": {
		"text": "Verificați dacă accesoriile sunt conforme cu informațiile de pe ambalajul de vânzare",
		"isAlreadyTranslated": true
	},
	"Check If There Are Functional Defects": {
		"text": "Verificați dacă există defecte funcționale",
		"isAlreadyTranslated": true
	},
	"Ear Pods": {
		"text": "Căști de tip ear pod",
		"isAlreadyTranslated": true
	},
	"Power Adapter": {
		"text": "Adaptor de alimentare",
		"isAlreadyTranslated": true
	},
	"Lightning to USB Cable": {
		"text": "Cablu Lightning-USB",
		"isAlreadyTranslated": true
	},
	"Display Damaged": {
		"text": "Ecran deteriorat",
		"isAlreadyTranslated": true
	},
	"Battery Damaged": {
		"text": "Baterie deteriorată",
		"isAlreadyTranslated": true
	},
	"Backup Required": {
		"text": "Copie de rezervă necesară",
		"isAlreadyTranslated": true
	},
	"Find my iPhone Active": {
		"text": "Aplicația Găsește-mi iPhone-ul este activă",
		"isAlreadyTranslated": true
	},
	"Unlock.": {
		"text": "Deblocare.",
		"isAlreadyTranslated": true
	},
	"Functional Defects": {
		"text": "Defecte funcționale",
		"isAlreadyTranslated": true
	},
	"Accessories as per Content printed on sales pack": {
		"text": "Accesorii conform conținutului specificat pe ambalajul de vânzare",
		"isAlreadyTranslated": true
	},
	"USB Port Damaged": {
		"text": "Port USB deteriorat",
		"isAlreadyTranslated": true
	},
	"Camera AF & Blurr Issues": {
		"text": "Probleme de autofocalizare și neclaritate ale camerei",
		"isAlreadyTranslated": true
	},
	"Power/Volume Keys Loose or Not Functioning": {
		"text": "Tastele de alimentare/volum sunt slăbite sau nu funcționează",
		"isAlreadyTranslated": true
	},
	"Any Dents or Internal Crack in display": {
		"text": "Orice denivelări sau fisuri interne în ecran",
		"isAlreadyTranslated": true
	},
	"Screw heads Damaged": {
		"text": "Capete de șuruburi deteriorate",
		"isAlreadyTranslated": true
	},
	"Back Cover Damaged": {
		"text": "Carcasa din spate este deteriorată",
		"isAlreadyTranslated": true
	},
	"SIM Tray Missing or Broken": {
		"text": "Lipsește sau este ruptă tava SIM",
		"isAlreadyTranslated": true
	},
	"Touch/LCD Broken": {
		"text": "Ecran tactil/LCD spart",
		"isAlreadyTranslated": true
	},
	"Unit IMEI Vs Internal IMEI": {
		"text": "IMEI unitate vs. IMEI intern",
		"isAlreadyTranslated": true
	},
	"Box IMEI Vs Unit IMEI": {
		"text": "IMEI casetă vs. IMEI unitate",
		"isAlreadyTranslated": true
	},
	"Please enter alphabets or numbers only": {
		"text": "Introduceți numai caractere alfabetice sau cifre",
		"isAlreadyTranslated": true
	},
	"Please enter numbers only": {
		"text": "Introduceți numai cifre",
		"isAlreadyTranslated": true
	},
	"Consumer Email": {
		"text": "E-mail pentru consumatori",
		"isAlreadyTranslated": true
	},
	"IMEI Number 1": {
		"text": "Număr IMEI 1",
		"isAlreadyTranslated": true
	},
	"IMEI Number 2": {
		"text": "Număr IMEI 2",
		"isAlreadyTranslated": true
	},
	"Reassign Service": {
		"text": "Reatribuire service",
		"isAlreadyTranslated": true
	},
	"Select a service location from the drop down": {
		"text": "Selectați o locație de service din lista verticală",
		"isAlreadyTranslated": true
	},
	"The same service center and same engineer is already assigned.": {
		"text": "Același centru de service și același Inginer sunt deja atribuite.",
		"isAlreadyTranslated": true
	},
	"Service center and engineer has been reassigned.": {
		"text": "Centrul de service și Inginerul au fost reatribute.",
		"isAlreadyTranslated": true
	},
	"The same service center is already assigned.": {
		"text": "Același centru de service este deja atribuit.",
		"isAlreadyTranslated": true
	},
	"Service center has been reassigned.": {
		"text": "Centrul de service a fost reatribuit.",
		"isAlreadyTranslated": true
	},
	"First Dialer Dispostion": {
		"text": "Dispoziție pentru prima apelare",
		"isAlreadyTranslated": true
	},
	"Second Dialer Dispostion": {
		"text": "Dispoziție pentru a doua apelare",
		"isAlreadyTranslated": true
	},
	"Reschedule Service": {
		"text": "Reprogramare service",
		"isAlreadyTranslated": true
	},
	"No slots Available": {
		"text": "Nu există intervale disponibile",
		"isAlreadyTranslated": true
	},
	"Reason for reschedule": {
		"text": "Motivul reprogramării",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to reschedule?": {
		"text": "Sigur doriți să reprogramați?",
		"isAlreadyTranslated": true
	},
	"The request was rescheduled successfully": {
		"text": "Solicitarea a fost reprogramată cu succes",
		"isAlreadyTranslated": true
	},
	"Please specify the reason for reschedule": {
		"text": "Specificați motivul reprogramării",
		"isAlreadyTranslated": true
	},
	"Please select a slot for reschedule": {
		"text": "Selectați un interval pentru reprogramare",
		"isAlreadyTranslated": true
	},
	"Service Location": {
		"text": "Locație service",
		"isAlreadyTranslated": true
	},
	"Device Replacement Mode": {
		"text": "Mod de înlocuire a dispozitivului",
		"isAlreadyTranslated": true
	},
	"Generate DOA Certificate": {
		"text": "Generarea certificatului Stare nefuncțională la primire",
		"isAlreadyTranslated": true
	},
	"Get New Device": {
		"text": "Obțineți un dispozitiv nou",
		"isAlreadyTranslated": true
	},
	"Choose Device": {
		"text": "Alegeți dispozitivul",
		"isAlreadyTranslated": true
	},
	"Enter Information for the Device you are issuing": {
		"text": "Introduceți informațiile pentru dispozitivul pe care îl emiteți",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI 1": {
		"text": "Introduceți IMEI 1 corect",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI 2": {
		"text": "Introduceți IMEI 2 corect",
		"isAlreadyTranslated": true
	},
	"Please enter correct Serial Number": {
		"text": "Introduceți numărul de serie corect",
		"isAlreadyTranslated": true
	},
	"Action on Device": {
		"text": "Acțiune asupra dispozitivului",
		"isAlreadyTranslated": true
	},
	"Status Date": {
		"text": "Dată stare",
		"isAlreadyTranslated": true
	},
	"Symptoms reported by Engineer": {
		"text": "Simptome raportate de Inginer",
		"isAlreadyTranslated": true
	},
	"Symptoms": {
		"text": "Simptome",
		"isAlreadyTranslated": true
	},
	"Add New Symptom Codes": {
		"text": "Adăugați noi coduri de simptome",
		"isAlreadyTranslated": true
	},
	"No symptoms added": {
		"text": "Nu s-au adăugat simptome",
		"isAlreadyTranslated": true
	},
	"Symptom already added by the engineer": {
		"text": "Simptom deja adăugat de către Inginer",
		"isAlreadyTranslated": true
	},
	"Please validate all the issues": {
		"text": "Validați toate problemele",
		"isAlreadyTranslated": true
	},
	"Verification Attempts Details": {
		"text": "Detalii privind încercările de verificare",
		"isAlreadyTranslated": true
	},
	"Number of Attempts Remaining": {
		"text": "Numărul de încercări rămase",
		"isAlreadyTranslated": true
	},
	"Value Used For Verification": {
		"text": "Valoarea utilizată pentru verificare",
		"isAlreadyTranslated": true
	},
	"Verified": {
		"text": "Verificat",
		"isAlreadyTranslated": true
	},
	"Not Verified": {
		"text": "Neverificat",
		"isAlreadyTranslated": true
	},
	"Verification Not Attempted": {
		"text": "Nu s-a încercat verificarea",
		"isAlreadyTranslated": true
	},
	"Request is being processed.": {
		"text": "Solicitarea este în curs de procesare.",
		"isAlreadyTranslated": true
	},
	"Hub Details": {
		"text": "Detaliile hubului",
		"isAlreadyTranslated": true
	},
	"Logistic Partners": {
		"text": "Parteneri logistici",
		"isAlreadyTranslated": true
	},
	"Logistic Partner Name": {
		"text": "Numele partenerului logistic",
		"isAlreadyTranslated": true
	},
	"Recommended": {
		"text": "Recomandat",
		"isAlreadyTranslated": true
	},
	"Schedule Request": {
		"text": "Solicitare de programare",
		"isAlreadyTranslated": true
	},
	"Please select a Logistic Partner": {
		"text": "Selectați un partener logistic",
		"isAlreadyTranslated": true
	},
	"SMS Sent Successfully": {
		"text": "SMS trimis cu succes",
		"isAlreadyTranslated": true
	},
	"Please select a Hub": {
		"text": "Selectați un hub",
		"isAlreadyTranslated": true
	},
	"Upload Document": {
		"text": "Încărcare document",
		"isAlreadyTranslated": true
	},
	"Add Certificate": {
		"text": "Adăugați un certificat",
		"isAlreadyTranslated": true
	},
	"Vouchers generated successfully": {
		"text": "Cupoane generate cu succes",
		"isAlreadyTranslated": true
	},
	"Document Type: Cheque": {
		"text": "Tip de document: Cec",
		"isAlreadyTranslated": true
	},
	"Claim Amount": {
		"text": "Sumă de despăgubire solicitată",
		"isAlreadyTranslated": true
	},
	"Please enter Claim Amount:": {
		"text": "Introduceți suma de despăgubire:",
		"isAlreadyTranslated": true
	},
	"Please enter Claim Amount before submitting": {
		"text": "Introduceți suma de despăgubire înainte de a trimite",
		"isAlreadyTranslated": true
	},
	"Invoice Value": {
		"text": "Valoarea facturii",
		"isAlreadyTranslated": true
	},
	"Repair Cost": {
		"text": "Costul reparației",
		"isAlreadyTranslated": true
	},
	"Mandatory Deductible": {
		"text": "Deductibilitate obligatorie",
		"isAlreadyTranslated": true
	},
	"BER Payment Amount": {
		"text": "Suma de plată BER",
		"isAlreadyTranslated": true
	},
	"Approve BER": {
		"text": "Aprobare REC",
		"isAlreadyTranslated": true
	},
	"Please enter Override Amount before submitting": {
		"text": "Introduceți suma de înlocuire înainte de a trimite",
		"isAlreadyTranslated": true
	},
	"Please mention the incident in not less than 100 characters on how, when & where the damage happened": {
		"text": "Menționați incidentul în cel puțin 100 de caractere, precizând cum, când și unde s-a produs deteriorarea.",
		"isAlreadyTranslated": true
	},
	"Enter Loaner Device Details": {
		"text": "Introduceți detaliile dispozitivului de împrumut",
		"isAlreadyTranslated": true
	},
	"Select IMEI Number": {
		"text": "Selectați numărul IMEI",
		"isAlreadyTranslated": true
	},
	"Receive Loaner Device": {
		"text": "Primiți un dispozitiv de împrumut",
		"isAlreadyTranslated": true
	},
	"Condition Of the Loaner Device": {
		"text": "Starea dispozitivului de împrumut",
		"isAlreadyTranslated": true
	},
	"Good": {
		"text": "Bună",
		"isAlreadyTranslated": true
	},
	"Bad": {
		"text": "Proastă",
		"isAlreadyTranslated": true
	},
	"Ugly": {
		"text": "Aspect urât",
		"isAlreadyTranslated": true
	},
	"Loaner Device Details added successfully": {
		"text": "Detalii dispozitiv de împrumut adăugate cu succes",
		"isAlreadyTranslated": true
	},
	"Please choose from the given list only.": {
		"text": "Alegeți numai din lista dată.",
		"isAlreadyTranslated": true
	},
	"Please tell us about the condition of the Loaner Device.": {
		"text": "Spuneți-ne în ce stare se află dispozitivul de împrumut.",
		"isAlreadyTranslated": true
	},
	"Please write about the condition of the device.": {
		"text": "Descrieți în scris starea dispozitivului.",
		"isAlreadyTranslated": true
	},
	"From": {
		"text": "De la",
		"isAlreadyTranslated": true
	},
	"To": {
		"text": "La",
		"isAlreadyTranslated": true
	},
	"Consumer Mobile": {
		"text": "Telefon mobil pentru consumatori",
		"isAlreadyTranslated": true
	},
	"Alternate No./Landline No.": {
		"text": "Nr. alternativ/Nr. fix",
		"isAlreadyTranslated": true
	},
	"Customer Alternate Mobile No.": {
		"text": "Număr de telefon mobil alternativ al clientului",
		"isAlreadyTranslated": true
	},
	"Save & Send SMS/Email": {
		"text": "Salvați și trimiteți SMS/e-mail",
		"isAlreadyTranslated": true
	},
	"consumer(s) are shown above.": {
		"text": "consumatorul (consumatorii) este (sunt) prezentat (prezentați) mai sus.",
		"isAlreadyTranslated": true
	},
	"Call logs": {
		"text": "Jurnale de apeluri",
		"isAlreadyTranslated": true
	},
	"customer@email.com": {
		"text": "customer@email.com",
		"isAlreadyTranslated": true
	},
	"IMEI / Serial No.": {
		"text": "IMEI/număr de serie",
		"isAlreadyTranslated": true
	},
	"Servify Diagnostic App": {
		"text": "Aplicația de diagnosticare Servify",
		"isAlreadyTranslated": true
	},
	"Serial No.": {
		"text": "Nr. de serie",
		"isAlreadyTranslated": true
	},
	"IMEI 2": {
		"text": "IMEI 2",
		"isAlreadyTranslated": true
	},
	"Referece ID": {
		"text": "ID de referință",
		"isAlreadyTranslated": true
	},
	"Claim Form": {
		"text": "Formular de solicitare de despăgubire",
		"isAlreadyTranslated": true
	},
	"Claim Form Servify": {
		"text": "Formular de solicitare de despăgubire Servify",
		"isAlreadyTranslated": true
	},
	"Authorization Letter": {
		"text": "Scrisoare de autorizare",
		"isAlreadyTranslated": true
	},
	"PAN Card Front": {
		"text": "Parte frontală card PAN",
		"isAlreadyTranslated": true
	},
	"Aadhar Card Front": {
		"text": "Aadhar Card Front",
		"isAlreadyTranslated": true
	},
	"Aadhar Card Back": {
		"text": "Aadhar Card Back",
		"isAlreadyTranslated": true
	},
	"Other Documents": {
		"text": "Alte documente",
		"isAlreadyTranslated": true
	},
	"Cheque": {
		"text": "Cec",
		"isAlreadyTranslated": true
	},
	"Video": {
		"text": "Video",
		"isAlreadyTranslated": true
	},
	"Device image": {
		"text": "Imaginea dispozitivului",
		"isAlreadyTranslated": true
	},
	"Destruction Form": {
		"text": "Formular de distrugere",
		"isAlreadyTranslated": true
	},
	"P-Slip": {
		"text": "Borderou de expediție",
		"isAlreadyTranslated": true
	},
	"Consumer Signature": {
		"text": "Semnătura consumatorului",
		"isAlreadyTranslated": true
	},
	"Issue Image": {
		"text": "Imagine cu problema",
		"isAlreadyTranslated": true
	},
	"Diagnosis report": {
		"text": "Raport de diagnosticare",
		"isAlreadyTranslated": true
	},
	"Delivery Note": {
		"text": "Notă de livrare",
		"isAlreadyTranslated": true
	},
	"Product Damage Feedback Item": {
		"text": "Element feedback deteriorare produs",
		"isAlreadyTranslated": true
	},
	"Claim Form in": {
		"text": "Formular de solicitare de despăgubire în",
		"isAlreadyTranslated": true
	},
	"IMEI No": {
		"text": "Nr. IMEI",
		"isAlreadyTranslated": true
	},
	"IssueText": {
		"text": "IssueText",
		"isAlreadyTranslated": true
	},
	"IssueRemark": {
		"text": "IssueRemark",
		"isAlreadyTranslated": true
	},
	"otherIssueText": {
		"text": "otherIssueText",
		"isAlreadyTranslated": true
	},
	"screen": {
		"text": "ecran",
		"isAlreadyTranslated": true
	},
	"Consumer To Service Centre": {
		"text": "De la consumator la centrul de service",
		"isAlreadyTranslated": true
	},
	"Service Centre To Consumer": {
		"text": "De la centrul de service la consumator",
		"isAlreadyTranslated": true
	},
	"On": {
		"text": "Pe",
		"isAlreadyTranslated": true
	},
	"at": {
		"text": "la",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial": {
		"text": "IMEI/serie",
		"isAlreadyTranslated": true
	},
	"Insufficient Data For Graph. Data": {
		"text": "Date insuficiente pentru grafic. Date",
		"isAlreadyTranslated": true
	},
	"is": {
		"text": "sunt",
		"isAlreadyTranslated": true
	},
	"Not a valid user.": {
		"text": "Nu este un utilizator valid.",
		"isAlreadyTranslated": true
	},
	"Rejection Reasons and Expected Actions by APR - Summary": {
		"text": "Motive de respingere și acțiuni preconizate în funcție de APR - Rezumat",
		"isAlreadyTranslated": true
	},
	"Error Message on App": {
		"text": "Mesaj de eroare în aplicație",
		"isAlreadyTranslated": true
	},
	"Error Code": {
		"text": "Cod de eroare",
		"isAlreadyTranslated": true
	},
	"Explanation of Error Code": {
		"text": "Explicația codului de eroare",
		"isAlreadyTranslated": true
	},
	"Expected Action from APR": {
		"text": "Acțiunea așteptată din partea APR",
		"isAlreadyTranslated": true
	},
	"Store Name & Location": {
		"text": "Numele și locația magazinului",
		"isAlreadyTranslated": true
	},
	"Download Activation Report": {
		"text": "Descărcați raportul de activare",
		"isAlreadyTranslated": true
	},
	"Filter Consumers": {
		"text": "Filtru consumatori",
		"isAlreadyTranslated": true
	},
	"Order ID": {
		"text": "ID-ul comenzii",
		"isAlreadyTranslated": true
	},
	"Order Item ID": {
		"text": "ID-ul articolului de comandă",
		"isAlreadyTranslated": true
	},
	"User Details": {
		"text": "Detalii utilizator",
		"isAlreadyTranslated": true
	},
	"Plan Purchased": {
		"text": "Plan achiziționat",
		"isAlreadyTranslated": true
	},
	"Activated On": {
		"text": "Activat pe",
		"isAlreadyTranslated": true
	},
	"Status Message": {
		"text": "Mesaj de stare",
		"isAlreadyTranslated": true
	},
	"Error Code in": {
		"text": "Cod de eroare în",
		"isAlreadyTranslated": true
	},
	"Something went wrong when trying to create a consumer. Please try again.": {
		"text": "Ceva nu a funcționat corect când ați încercat să creați un consumator. Încercați din nou.",
		"isAlreadyTranslated": true
	},
	"Consumer successfully added and SMS sent!": {
		"text": "Consumator adăugat cu succes și SMS trimis!",
		"isAlreadyTranslated": true
	},
	"Please enter correct E-mail ID.": {
		"text": "Introduceți ID-ul de e-mail corect.",
		"isAlreadyTranslated": true
	},
	"Please enter correct Mobile Number.": {
		"text": "Introduceți numărul de telefon mobil corect.",
		"isAlreadyTranslated": true
	},
	"Please enter Mobile Number.": {
		"text": "Introduceți numărul de telefon mobil.",
		"isAlreadyTranslated": true
	},
	"Please select a Device Type.": {
		"text": "Selectați un tip de dispozitiv.",
		"isAlreadyTranslated": true
	},
	"Please enter at least one issue.": {
		"text": "Introduceți cel puțin o problemă.",
		"isAlreadyTranslated": true
	},
	"Please enter Incident ID.": {
		"text": "Introduceți ID-ul incidentului.",
		"isAlreadyTranslated": true
	},
	"Please select a model from the dropdown.": {
		"text": "Selectați un model din lista verticală.",
		"isAlreadyTranslated": true
	},
	"Please enter E-mail ID.": {
		"text": "Introduceți ID-ul de e-mail.",
		"isAlreadyTranslated": true
	},
	"Please enter Customer's Name.": {
		"text": "Introduceți numele clientului.",
		"isAlreadyTranslated": true
	},
	"Doc": {
		"text": "Doc",
		"isAlreadyTranslated": true
	},
	"Allowed Repairs": {
		"text": "Reparații permise",
		"isAlreadyTranslated": true
	},
	"Used Repairs": {
		"text": "Reparații utilizate",
		"isAlreadyTranslated": true
	},
	"Tax Payable": {
		"text": "Taxă de plată",
		"isAlreadyTranslated": true
	},
	"View queued requestsat": {
		"text": "Vizualizați cererile din coada de așteptare la",
		"isAlreadyTranslated": true
	},
	"Generate Vouchers": {
		"text": "Generare cupoane",
		"isAlreadyTranslated": true
	},
	"Customer's Address Details": {
		"text": "Detalii privind adresa clientului",
		"isAlreadyTranslated": true
	},
	"View queued requests": {
		"text": "Vizualizați solicitările din coada de așteptare",
		"isAlreadyTranslated": true
	},
	"Engineer Notification": {
		"text": "Inginer Notificare",
		"isAlreadyTranslated": true
	},
	"Engineer Notifications": {
		"text": "Inginer Notificări",
		"isAlreadyTranslated": true
	},
	"Optin Requests": {
		"text": "Solicitări Optin",
		"isAlreadyTranslated": true
	},
	"Plan Sales Report": {
		"text": "Planificare raport de vânzări",
		"isAlreadyTranslated": true
	},
	"APR Plans": {
		"text": "Planuri APR",
		"isAlreadyTranslated": true
	},
	"Engineer Login Report": {
		"text": "Raport de conectare a Inginerului",
		"isAlreadyTranslated": true
	},
	"Service location Report": {
		"text": "Raport privind locația service-ului",
		"isAlreadyTranslated": true
	},
	"Mapping": {
		"text": "Mapare",
		"isAlreadyTranslated": true
	},
	"Reports": {
		"text": "Rapoarte",
		"isAlreadyTranslated": true
	},
	"billing": {
		"text": "facturare",
		"isAlreadyTranslated": true
	},
	"Engineer Tracking": {
		"text": "Urmărire Inginer",
		"isAlreadyTranslated": true
	},
	"Service Requests": {
		"text": "Solicitări de service",
		"isAlreadyTranslated": true
	},
	"Engineer Report": {
		"text": "Raportul Inginerului",
		"isAlreadyTranslated": true
	},
	"Visit Report": {
		"text": "Raport de vizită",
		"isAlreadyTranslated": true
	},
	"Billing": {
		"text": "Facturare",
		"isAlreadyTranslated": true
	},
	"Upload document": {
		"text": "Încărcare document",
		"isAlreadyTranslated": true
	},
	"View Documents": {
		"text": "Vizualizare documente",
		"isAlreadyTranslated": true
	},
	"File Name": {
		"text": "Numele fișierului",
		"isAlreadyTranslated": true
	},
	"Uploaded By": {
		"text": "Încărcat de",
		"isAlreadyTranslated": true
	},
	"Uploaded Date & Time": {
		"text": "Data și ora încărcării",
		"isAlreadyTranslated": true
	},
	"Wall Mount Or 60 Inch Or Large?": {
		"text": "Suport de perete sau 60 Inch sau mare?",
		"isAlreadyTranslated": true
	},
	"Logistics - From Service Center to Customer's Address to Service Center": {
		"text": "Logistică – De la centrul de service la adresa clientului la centrul de service",
		"isAlreadyTranslated": true
	},
	"Tier": {
		"text": "Nivel",
		"isAlreadyTranslated": true
	},
	"Expected Delivery": {
		"text": "Livrare preconizată",
		"isAlreadyTranslated": true
	},
	"Reason for Reschedule": {
		"text": "Motivul reprogramării",
		"isAlreadyTranslated": true
	},
	"View Job Card": {
		"text": "Vizualizare fișă lucrare",
		"isAlreadyTranslated": true
	},
	"Begin Journey": {
		"text": "Începeți călătoria",
		"isAlreadyTranslated": true
	},
	"Serviceman Reached": {
		"text": "Lucrător service contactat",
		"isAlreadyTranslated": true
	},
	"Initiate Exception": {
		"text": "Inițiați excepția",
		"isAlreadyTranslated": true
	},
	"Complete Refund": {
		"text": "Rambursare completă",
		"isAlreadyTranslated": true
	},
	"View Exception": {
		"text": "Vizualizare excepție",
		"isAlreadyTranslated": true
	},
	"Visit Details": {
		"text": "Detalii vizită",
		"isAlreadyTranslated": true
	},
	"Tasks": {
		"text": "Activități",
		"isAlreadyTranslated": true
	},
	"Diagnosis Report": {
		"text": "Raport de diagnosticare",
		"isAlreadyTranslated": true
	},
	"Please choose the product": {
		"text": "Alegeți produsul",
		"isAlreadyTranslated": true
	},
	"Billing Address": {
		"text": "Adresa de facturare",
		"isAlreadyTranslated": true
	},
	"Change Billing Address": {
		"text": "Modificarea adresei de facturare",
		"isAlreadyTranslated": true
	},
	"Device Received & Inspected": {
		"text": "Dispozitiv primit și inspectat",
		"isAlreadyTranslated": true
	},
	"Complete Inspection": {
		"text": "Inspecție finalizată",
		"isAlreadyTranslated": true
	},
	"Complete Device handover": {
		"text": "Predare dispozitiv finalizată",
		"isAlreadyTranslated": true
	},
	"Create shipment": {
		"text": "Creați expedierea",
		"isAlreadyTranslated": true
	},
	"Waiting for the Refurbishment Hub Manager to receive device and complete mandatory checklist tasks.": {
		"text": "Se așteaptă ca Managerul hubului de recondiționare să primească dispozitivul și să finalizeze activitățile obligatorii din lista de verificare.",
		"isAlreadyTranslated": true
	},
	"No of Repairs Used": {
		"text": "Nr. de reparații utilizate",
		"isAlreadyTranslated": true
	},
	"No of Repairs Allowed": {
		"text": "Nr. de reparații permise",
		"isAlreadyTranslated": true
	},
	"Customer Care notes": {
		"text": "Note asistență pentru clienți",
		"isAlreadyTranslated": true
	},
	"Call Type:": {
		"text": "Tip de apel:",
		"isAlreadyTranslated": true
	},
	"Call Disposition": {
		"text": "Dispoziție apel",
		"isAlreadyTranslated": true
	},
	"Enter Amount": {
		"text": "Introduceți suma",
		"isAlreadyTranslated": true
	},
	"Map": {
		"text": "Hartă",
		"isAlreadyTranslated": true
	},
	"Number": {
		"text": "Număr",
		"isAlreadyTranslated": true
	},
	"G Number": {
		"text": "Număr G",
		"isAlreadyTranslated": true
	},
	"Turn off Find my iPhone": {
		"text": "Dezactivați funcția Găsește-mi iPhone-ul",
		"isAlreadyTranslated": true
	},
	"Add required documents in 'Request Documents' section below.": {
		"text": "Adăugați documentele necesare în secțiunea „Documente solicitate” de mai jos.",
		"isAlreadyTranslated": true
	},
	"Sub Category": {
		"text": "Subcategorie",
		"isAlreadyTranslated": true
	},
	"Generate Estimate": {
		"text": "Generați estimarea",
		"isAlreadyTranslated": true
	},
	"Purchase Order No": {
		"text": "Nr. ordin de achiziție",
		"isAlreadyTranslated": true
	},
	"Enter Information for the parts you have received": {
		"text": "Introduceți informațiile pentru piesele pe care le-ați primit",
		"isAlreadyTranslated": true
	},
	"Enter comptia for DOA parts": {
		"text": "Enter comptia for DOA parts",
		"isAlreadyTranslated": true
	},
	"Waiting for device to be received.": {
		"text": "Se așteaptă primirea dispozitivului.",
		"isAlreadyTranslated": true
	},
	"Repair Complete": {
		"text": "Reparație finalizată",
		"isAlreadyTranslated": true
	},
	"Cannibalize Device": {
		"text": "Canibalizați dispozitivul",
		"isAlreadyTranslated": true
	},
	"test": {
		"text": "test",
		"isAlreadyTranslated": true
	},
	"Part Charge": {
		"text": "Taxă piesă",
		"isAlreadyTranslated": true
	},
	"Approved for repair": {
		"text": "Aprobat pentru reparație",
		"isAlreadyTranslated": true
	},
	"Waiting for store to issue required parts in 'Parts Requested' section below. Cannot complete repair with parts pending.": {
		"text": "Se așteaptă ca magazinul să elibereze piesele solicitate în secțiunea „Piese solicitate” de mai jos. Nu se poate finaliza reparația deoarece există piese în așteptare.",
		"isAlreadyTranslated": true
	},
	"pending": {
		"text": "în așteptare",
		"isAlreadyTranslated": true
	},
	"Entered details do not match with selected serial number": {
		"text": "Detaliile introduse nu corespund cu numărul de serie selectat",
		"isAlreadyTranslated": true
	},
	"BER Accessories Received": {
		"text": "Accesorii BER primite",
		"isAlreadyTranslated": true
	},
	"BER Device Received": {
		"text": "Dispozitiv BER primit",
		"isAlreadyTranslated": true
	},
	"Logistics - From Service Center to Hub": {
		"text": "Logistică – de la centrul de service la hub",
		"isAlreadyTranslated": true
	},
	"Initiate BER Payment": {
		"text": "Inițierea plății BER",
		"isAlreadyTranslated": true
	},
	"BER Payment Complete": {
		"text": "Plată BER finalizată",
		"isAlreadyTranslated": true
	},
	"Complete Process": {
		"text": "Proces finalizat",
		"isAlreadyTranslated": true
	},
	"Show Count": {
		"text": "Afișare număr",
		"isAlreadyTranslated": true
	},
	"Search": {
		"text": "Căutare",
		"isAlreadyTranslated": true
	},
	"Escalation Type": {
		"text": "Tip de escaladare",
		"isAlreadyTranslated": true
	},
	"Flag Requests": {
		"text": "Marcare solicitări",
		"isAlreadyTranslated": true
	},
	"Save this": {
		"text": "Salvați aceasta",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial No": {
		"text": "IMEI/nr. de serie",
		"isAlreadyTranslated": true
	},
	"Hide Count": {
		"text": "Ascundere număr",
		"isAlreadyTranslated": true
	},
	"Enter Activation Token": {
		"text": "Introduceți tokenul de activare",
		"isAlreadyTranslated": true
	},
	"Activation Token": {
		"text": "Token de activare",
		"isAlreadyTranslated": true
	},
	"Download all reports": {
		"text": "Descărcați toate rapoartele",
		"isAlreadyTranslated": true
	},
	"BER Repair": {
		"text": "Reparare BER",
		"isAlreadyTranslated": true
	},
	"Exception raised": {
		"text": "Excepție formulată",
		"isAlreadyTranslated": true
	},
	"Billing Details": {
		"text": "Detalii de facturare",
		"isAlreadyTranslated": true
	},
	"Continue": {
		"text": "Continuare",
		"isAlreadyTranslated": true
	},
	"Scan the QR code from Diagnosis App": {
		"text": "Scanați codul QR din aplicația de diagnosticare",
		"isAlreadyTranslated": true
	},
	"Something went wrong. Your request may have timed out.": {
		"text": "Ceva nu a funcționat corect. Este posibil ca solicitarea dvs. să fi expirat.",
		"isAlreadyTranslated": true
	},
	"Exclusive Brand is created successfully": {
		"text": "Marca exclusivă este creată cu succes",
		"isAlreadyTranslated": true
	},
	"Form no": {
		"text": "Formular nr.",
		"isAlreadyTranslated": true
	},
	"File provided is not in required format": {
		"text": "Fișierul furnizat nu este în formatul cerut",
		"isAlreadyTranslated": true
	},
	"Invalid format": {
		"text": "Format nevalid",
		"isAlreadyTranslated": true
	},
	"Invalid name for Product": {
		"text": "Nume nevalid pentru produs",
		"isAlreadyTranslated": true
	},
	"More than 2 fields supplied": {
		"text": "Mai mult de 2 câmpuri furnizate",
		"isAlreadyTranslated": true
	},
	"Please upload only .csv file.": {
		"text": "Încărcați numai fișierul .csv.",
		"isAlreadyTranslated": true
	},
	"Cannot read file": {
		"text": "Fișierul nu poate fi citit",
		"isAlreadyTranslated": true
	},
	"Invalid IMEI1 for Product": {
		"text": "IMEI1 nevalid pentru produs",
		"isAlreadyTranslated": true
	},
	"Invalid IMEI2 for Product": {
		"text": "IMEI2 nevalid pentru produs",
		"isAlreadyTranslated": true
	},
	"More than 9 fields supplied": {
		"text": "Mai mult de 9 câmpuri furnizate",
		"isAlreadyTranslated": true
	},
	"No file found": {
		"text": "Nu s-a găsit niciun fișier",
		"isAlreadyTranslated": true
	},
	"Name of the Agent": {
		"text": "Numele agentului",
		"isAlreadyTranslated": true
	},
	"Please Select a Product": {
		"text": "Selectați un produs",
		"isAlreadyTranslated": true
	},
	"Select product from dropdown": {
		"text": "Selectați produsul din lista verticală",
		"isAlreadyTranslated": true
	},
	"Select subcategory from dropdown": {
		"text": "Selectați subcategoria din lista verticală",
		"isAlreadyTranslated": true
	},
	"Select brand from dropdown": {
		"text": "Selectați marca din lista verticală",
		"isAlreadyTranslated": true
	},
	"Coupon Code": {
		"text": "Cod cupon",
		"isAlreadyTranslated": true
	},
	"Start date of the coupon": {
		"text": "Data de începere a cuponului",
		"isAlreadyTranslated": true
	},
	"End date of the coupon": {
		"text": "Data de expirare a cuponului",
		"isAlreadyTranslated": true
	},
	"Coupon Type": {
		"text": "Tip cupon",
		"isAlreadyTranslated": true
	},
	"Active coupon": {
		"text": "Cupon activ",
		"isAlreadyTranslated": true
	},
	"Create Coupon": {
		"text": "Creați cuponul",
		"isAlreadyTranslated": true
	},
	"Applicable only after sign up": {
		"text": "Aplicabil numai după conectare",
		"isAlreadyTranslated": true
	},
	"Incentive Type": {
		"text": "Tip de stimulent",
		"isAlreadyTranslated": true
	},
	"Select incentive type after selecting coupon type": {
		"text": "Selectați tipul de stimulent după selectarea tipului de cupon",
		"isAlreadyTranslated": true
	},
	"No. Of Users": {
		"text": "Nr. de utilizatori",
		"isAlreadyTranslated": true
	},
	"Incentive Value": {
		"text": "Valoarea stimulentului",
		"isAlreadyTranslated": true
	},
	"End date": {
		"text": "Data de încheiere",
		"isAlreadyTranslated": true
	},
	"Start date": {
		"text": "Data de începere",
		"isAlreadyTranslated": true
	},
	"Min Amount": {
		"text": "Suma minimă",
		"isAlreadyTranslated": true
	},
	"Please fill out this field": {
		"text": "Completați acest câmp",
		"isAlreadyTranslated": true
	},
	"Please fill value greater than 0": {
		"text": "Completați o valoare mai mare decât 0",
		"isAlreadyTranslated": true
	},
	"End Date cannot be less than start date": {
		"text": "Data de încheiere nu poate fi mai mică decât data de începere",
		"isAlreadyTranslated": true
	},
	"Incentive value cannot exceed": {
		"text": "Valoarea stimulentului nu poate depăși",
		"isAlreadyTranslated": true
	},
	"Please enter serial number": {
		"text": "Introduceți numărul de serie",
		"isAlreadyTranslated": true
	},
	"Please enter brand name": {
		"text": "Introduceți numele mărcii",
		"isAlreadyTranslated": true
	},
	"Please enter product name": {
		"text": "Introduceți numele produsului",
		"isAlreadyTranslated": true
	},
	"Customer Email": {
		"text": "E-mail client",
		"isAlreadyTranslated": true
	},
	"Initiate App Diagnostic": {
		"text": "Inițiați aplicația de diagnosticare",
		"isAlreadyTranslated": true
	},
	"Reliability Records": {
		"text": "Înregistrări de fiabilitate",
		"isAlreadyTranslated": true
	},
	"Drivers list": {
		"text": "Lista de drivere",
		"isAlreadyTranslated": true
	},
	"User Feedback": {
		"text": "Feedbackul utilizatorului",
		"isAlreadyTranslated": true
	},
	"Perform Diagnosis": {
		"text": "Efectuați diagnosticul",
		"isAlreadyTranslated": true
	},
	"Service Completion Date": {
		"text": "Data finalizării service-ului",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching templates": {
		"text": "Ceva nu a funcționat corect la preluarea șabloanelor",
		"isAlreadyTranslated": true
	},
	"Template": {
		"text": "Șablon",
		"isAlreadyTranslated": true
	},
	"Subject": {
		"text": "Subiect",
		"isAlreadyTranslated": true
	},
	"Apply a filter before sending an email": {
		"text": "Aplicați un filtru înainte de a trimite un e-mail",
		"isAlreadyTranslated": true
	},
	"Select a predefined template": {
		"text": "Selectați un șablon predefinit",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching data for graph": {
		"text": "Ceva nu a funcționat corect la preluarea datelor pentru grafic",
		"isAlreadyTranslated": true
	},
	"Please enter a valid IMEI": {
		"text": "Introduceți un IMEI valid",
		"isAlreadyTranslated": true
	},
	"Thank You For Choosing Servify": {
		"text": "Vă mulțumim că ați ales Servify",
		"isAlreadyTranslated": true
	},
	"Product Inward Date": {
		"text": "Data intrării produsului",
		"isAlreadyTranslated": true
	},
	"Expected Delivery Date": {
		"text": "Data de livrare preconizată",
		"isAlreadyTranslated": true
	},
	"Service Provider's Address": {
		"text": "Adresa furnizorului de service",
		"isAlreadyTranslated": true
	},
	"Problem Reported by Customer": {
		"text": "Problema raportată de client",
		"isAlreadyTranslated": true
	},
	"Note: This Equipment is accepted subject to terms and conditions mentioned overleaf.": {
		"text": "Notă: Acest echipament este acceptat sub rezerva termenilor și condițiilor menționate pe verso.",
		"isAlreadyTranslated": true
	},
	"Handed to customer": {
		"text": "Înmânat clientului",
		"isAlreadyTranslated": true
	},
	"Upload Status Report": {
		"text": "Încărcați raportul de stare",
		"isAlreadyTranslated": true
	},
	"Download File": {
		"text": "Descărcați fișierul",
		"isAlreadyTranslated": true
	},
	"Upload File": {
		"text": "Încărcați fișierul",
		"isAlreadyTranslated": true
	},
	"Submit File": {
		"text": "Trimiteți fișierul",
		"isAlreadyTranslated": true
	},
	"Something went wrong. Please upload again": {
		"text": "Ceva nu a funcționat corect. Încărcați din nou",
		"isAlreadyTranslated": true
	},
	"Invalid column headers. You can use sample file": {
		"text": "Antete de coloană nevalide. Puteți utiliza fișierul exemplificativ",
		"isAlreadyTranslated": true
	},
	"Please upload a valid file. You can use sample file": {
		"text": "Încărcați un fișier valid. Puteți utiliza fișierul exemplificativ",
		"isAlreadyTranslated": true
	},
	"There are no requests to download": {
		"text": "Nu există solicitări de descărcare",
		"isAlreadyTranslated": true
	},
	"Map Logistics Request": {
		"text": "Solicitare logistică a hărții",
		"isAlreadyTranslated": true
	},
	"Your logistics request has been successfully mapped to service request": {
		"text": "Solicitarea dvs. logistică a fost cartografiată cu succes cu solicitarea de service",
		"isAlreadyTranslated": true
	},
	"Pickup Name": {
		"text": "Nume preluare",
		"isAlreadyTranslated": true
	},
	"Pickup Date": {
		"text": "Data preluării",
		"isAlreadyTranslated": true
	},
	"Pickup Time": {
		"text": "Ora de preluare",
		"isAlreadyTranslated": true
	},
	"Pickup Contact No": {
		"text": "Nr. de contact preluare",
		"isAlreadyTranslated": true
	},
	"Drop Name": {
		"text": "Nume predare",
		"isAlreadyTranslated": true
	},
	"Drop Address": {
		"text": "Adresa de predare",
		"isAlreadyTranslated": true
	},
	"Drop Contact No": {
		"text": "Nr. de contact predare",
		"isAlreadyTranslated": true
	},
	"Product Price": {
		"text": "Prețul produsului",
		"isAlreadyTranslated": true
	},
	"Brand Name": {
		"text": "Numele mărcii",
		"isAlreadyTranslated": true
	},
	"Mirror Test": {
		"text": "Testul oglinzii",
		"isAlreadyTranslated": true
	},
	"Age of device:": {
		"text": "Vechimea dispozitivului:",
		"isAlreadyTranslated": true
	},
	"optin requests are shown above": {
		"text": "solicitările de optare sunt prezentate mai sus",
		"isAlreadyTranslated": true
	},
	"This device is not eligible for DOA": {
		"text": "Acest dispozitiv nu este eligibil pentru încadrarea în Stare nefuncțională la primire",
		"isAlreadyTranslated": true
	},
	"This device is eligible for DOA": {
		"text": "Acest dispozitiv este eligibil pentru încadrarea în Stare nefuncțională la primire",
		"isAlreadyTranslated": true
	},
	"Do you want to return the defective parts to the customer?": {
		"text": "Doriți să returnați clientului piesele defecte?",
		"isAlreadyTranslated": true
	},
	"Please enter the remarks with at least 25 characters": {
		"text": "Introduceți observațiile în minimum 25 de caractere",
		"isAlreadyTranslated": true
	},
	"Please enter the remarks": {
		"text": "Introduceți observațiile",
		"isAlreadyTranslated": true
	},
	"Do you want to return the damaged parts to the customer": {
		"text": "Doriți să returnați clientului piesele deteriorate?",
		"isAlreadyTranslated": true
	},
	"Enter Order ID": {
		"text": "Introduceți ID-ul comenzii",
		"isAlreadyTranslated": true
	},
	'Device warranty details could not be fetched from GSX currently. Click on "Continue" to proceed with selecting the device details or Click on "Retry" to re-attempt fetching warranty details': {
		"text": "„Detaliile de garanție ale dispozitivului nu au putut fi preluate din GSX. Faceți clic pe „Continuare” pentru a continua cu selectarea detaliilor dispozitivului sau faceți clic pe „Reîncercare” pentru a încerca din nou să obțineți detaliile garanției.",
		"isAlreadyTranslated": true
	},
	"Exceptional Sales": {
		"text": "Vânzări excepționale",
		"isAlreadyTranslated": true
	},
	"Device not eligible": {
		"text": "Dispozitiv neeligibil",
		"isAlreadyTranslated": true
	},
	"Apply a filter before sending a SMS": {
		"text": "Aplicați un filtru înainte de a trimite un SMS",
		"isAlreadyTranslated": true
	},
	"Update New Phone's Serial Number": {
		"text": "Actualizarea numărului de serie al noului telefon",
		"isAlreadyTranslated": true
	},
	"Old Phone's IMEI / Serial Number": {
		"text": "Numărul IMEI/numărul de serie al telefonului vechi",
		"isAlreadyTranslated": true
	},
	"Invite Service User": {
		"text": "Invitați utilizatorul de service",
		"isAlreadyTranslated": true
	},
	"Invite Retailer User": {
		"text": "Invitați utilizatorul de retail",
		"isAlreadyTranslated": true
	},
	"No users found": {
		"text": "Nu s-au găsit utilizatori",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for overriding": {
		"text": "Completați motivul înlocuirii",
		"isAlreadyTranslated": true
	},
	"File size should not be greater than 10 MB": {
		"text": "Dimensiunea fișierului nu trebuie să fie mai mare de 10 MB",
		"isAlreadyTranslated": true
	},
	"Consumer Details is saved successfully": {
		"text": "Detaliile consumatorului sunt salvate cu succes",
		"isAlreadyTranslated": true
	},
	"Select a Vendor": {
		"text": "Selectați un vânzător",
		"isAlreadyTranslated": true
	},
	"Selected Vendor": {
		"text": "Vânzător selectat",
		"isAlreadyTranslated": true
	},
	"Work Order ID": {
		"text": "ID-ul comenzii de lucru",
		"isAlreadyTranslated": true
	},
	"Eligible for RVP": {
		"text": "Eligibil pentru RVP",
		"isAlreadyTranslated": true
	},
	"Make the exclusive brand visible to customers": {
		"text": "Setați marca exclusivă ca vizibilă pentru clienți",
		"isAlreadyTranslated": true
	},
	"Reset": {
		"text": "Resetare",
		"isAlreadyTranslated": true
	},
	"First Name": {
		"text": "Prenume",
		"isAlreadyTranslated": true
	},
	"Last Name": {
		"text": "Nume de familie",
		"isAlreadyTranslated": true
	},
	"Alternate number": {
		"text": "Număr alternativ",
		"isAlreadyTranslated": true
	},
	"Order date": {
		"text": "Data comenzii",
		"isAlreadyTranslated": true
	},
	"Address Line": {
		"text": "Linia de adresă",
		"isAlreadyTranslated": true
	},
	"City": {
		"text": "Oraș",
		"isAlreadyTranslated": true
	},
	"State": {
		"text": "Stat",
		"isAlreadyTranslated": true
	},
	"APR Name": {
		"text": "Denumire APR",
		"isAlreadyTranslated": true
	},
	"Apply Filters": {
		"text": "Aplicați filtre",
		"isAlreadyTranslated": true
	},
	"Reason For Reassignment": {
		"text": "Motivul reatribuirii",
		"isAlreadyTranslated": true
	},
	"Reason For Reassign": {
		"text": "Motivul reatribuirii",
		"isAlreadyTranslated": true
	},
	"No data Found": {
		"text": "Nu s-au găsit date",
		"isAlreadyTranslated": true
	},
	"Total Credit Amount": {
		"text": "Suma totală a creditului",
		"isAlreadyTranslated": true
	},
	"Incentive/Penalty": {
		"text": "Stimulente/penalizări",
		"isAlreadyTranslated": true
	},
	"Taxable amount": {
		"text": "Sumă taxabilă",
		"isAlreadyTranslated": true
	},
	"Total Amount Payable": {
		"text": "Suma totală de plată",
		"isAlreadyTranslated": true
	},
	"Bulk Approve": {
		"text": "Aprobare masivă",
		"isAlreadyTranslated": true
	},
	"Add additional Penalties": {
		"text": "Adăugați sancțiuni suplimentare",
		"isAlreadyTranslated": true
	},
	"Description": {
		"text": "Descriere",
		"isAlreadyTranslated": true
	},
	"Character Limit": {
		"text": "Limită de caractere",
		"isAlreadyTranslated": true
	},
	"Pending Approval": {
		"text": "În curs de aprobare",
		"isAlreadyTranslated": true
	},
	"Rejected Remark": {
		"text": "Observație respinsă",
		"isAlreadyTranslated": true
	},
	"SELECT PRODUCT": {
		"text": "SELECTAȚI PRODUSUL",
		"isAlreadyTranslated": true
	},
	"Upload": {
		"text": "Încărcare",
		"isAlreadyTranslated": true
	},
	"Total Unregistered Products": {
		"text": "Total produse neînregistrate",
		"isAlreadyTranslated": true
	},
	"Change password": {
		"text": "Modificați parola",
		"isAlreadyTranslated": true
	},
	"Password": {
		"text": "Parolă",
		"isAlreadyTranslated": true
	},
	"Confirm Password": {
		"text": "Confirmați parola",
		"isAlreadyTranslated": true
	},
	"Choose another account": {
		"text": "Alegeți un alt cont",
		"isAlreadyTranslated": true
	},
	"Change": {
		"text": "Modificare",
		"isAlreadyTranslated": true
	},
	"child": {
		"text": "copil",
		"isAlreadyTranslated": true
	},
	"Plan Types": {
		"text": "Tipuri de planuri",
		"isAlreadyTranslated": true
	},
	"Plan Descriptions": {
		"text": "Descrierile planurilor",
		"isAlreadyTranslated": true
	},
	"No Communication History": {
		"text": "Nu există istoric de comunicare",
		"isAlreadyTranslated": true
	},
	"First Dialer Disposition": {
		"text": "Dispoziție pentru prima apelare",
		"isAlreadyTranslated": true
	},
	"Second Dialer Disposition": {
		"text": "Dispoziție pentru a doua apelare",
		"isAlreadyTranslated": true
	},
	"Enter First Name": {
		"text": "Introduceți prenumele",
		"isAlreadyTranslated": true
	},
	"Enter correct First Name": {
		"text": "Introduceți prenumele corect",
		"isAlreadyTranslated": true
	},
	"Enter correct Alternate Number": {
		"text": "Introduceți numărul alternativ corect",
		"isAlreadyTranslated": true
	},
	"Enter OderID": {
		"text": "Introduceți OderID",
		"isAlreadyTranslated": true
	},
	"Enter correct OderID": {
		"text": "Introduceți OderID corect",
		"isAlreadyTranslated": true
	},
	"Enter Order Date": {
		"text": "Introduceți data comenzii",
		"isAlreadyTranslated": true
	},
	"Select a plan from the dropdow": {
		"text": "Selectați un plan din lista verticală",
		"isAlreadyTranslated": true
	},
	"Enter correct City": {
		"text": "Introduceți orașul corect",
		"isAlreadyTranslated": true
	},
	"Enter correct State": {
		"text": "Introduceți statul corect",
		"isAlreadyTranslated": true
	},
	"Consumer Details added successfully": {
		"text": "Detalii consumator adăugate cu succes",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to generate Invoice?": {
		"text": "Sigur doriți să generați factura?",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to Approve all the jobs?": {
		"text": "Sigur doriți să aprobați toate lucrările?",
		"isAlreadyTranslated": true
	},
	"Passwords do not match": {
		"text": "Parolele nu corespund",
		"isAlreadyTranslated": true
	},
	"Please enter a password": {
		"text": "Introduceți o parolă",
		"isAlreadyTranslated": true
	},
	"This fault code/ cause code has already been selected": {
		"text": "Acest cod de eroare/cod de cauză a fost deja selectat.",
		"isAlreadyTranslated": true
	},
	"Please complete the QC checklist": {
		"text": "Completați lista de verificare QC",
		"isAlreadyTranslated": true
	},
	"Please add mandatory Symptom Fault Action": {
		"text": "Adăugați acțiunea de defecțiune simptom obligatorie",
		"isAlreadyTranslated": true
	},
	"Branch": {
		"text": "Sucursală",
		"isAlreadyTranslated": true
	},
	"Shop": {
		"text": "Magazin",
		"isAlreadyTranslated": true
	},
	"Product Category": {
		"text": "Categoria de produse",
		"isAlreadyTranslated": true
	},
	"Quantity": {
		"text": "Cantitate",
		"isAlreadyTranslated": true
	},
	"No records found": {
		"text": "Nu s-au găsit evidențe",
		"isAlreadyTranslated": true
	},
	"No Competition Marked": {
		"text": "Nu există concurență marcată",
		"isAlreadyTranslated": true
	},
	"Filter Competition Sales": {
		"text": "Filtrați vânzările concurenței",
		"isAlreadyTranslated": true
	},
	"Download consumer details in csv format.": {
		"text": "Descărcați detaliile consumatorilor în format csv.",
		"isAlreadyTranslated": true
	},
	"No Consumers found for the selected date range.": {
		"text": "Nu s-a găsit niciun consumator pentru intervalul de date selectat.",
		"isAlreadyTranslated": true
	},
	"Details": {
		"text": "Detalii",
		"isAlreadyTranslated": true
	},
	"Create Request": {
		"text": "Creați o solicitare",
		"isAlreadyTranslated": true
	},
	"Brand Description": {
		"text": "Descrierea mărcii",
		"isAlreadyTranslated": true
	},
	"Tab": {
		"text": "Filă",
		"isAlreadyTranslated": true
	},
	"Consumer data": {
		"text": "Date privind consumatorii",
		"isAlreadyTranslated": true
	},
	"No consumer data": {
		"text": "Nu există date privind consumatorii",
		"isAlreadyTranslated": true
	},
	"Something erroneous happened": {
		"text": "A survenit o eroare",
		"isAlreadyTranslated": true
	},
	"Consumer Rating": {
		"text": "Evaluarea consumatorilor",
		"isAlreadyTranslated": true
	},
	"Rate your experience with the customer": {
		"text": "Evaluați experiența dvs. cu clientul",
		"isAlreadyTranslated": true
	},
	"Comments (Optional)": {
		"text": "Comentarii (opțional)",
		"isAlreadyTranslated": true
	},
	"Delivery Report": {
		"text": "Raport de livrare",
		"isAlreadyTranslated": true
	},
	"Authorized Service Centre": {
		"text": "Centru de service autorizat",
		"isAlreadyTranslated": true
	},
	"Device Inward Date & Time": {
		"text": "Data și ora de intrare a dispozitivului",
		"isAlreadyTranslated": true
	},
	"Repair Closed Date & Time": {
		"text": "Data și ora de finalizare a reparației",
		"isAlreadyTranslated": true
	},
	"Device Collection Date & Time": {
		"text": "Data și ora de colectare a dispozitivului",
		"isAlreadyTranslated": true
	},
	"Service Reference ID": {
		"text": "ID de referință service",
		"isAlreadyTranslated": true
	},
	"mode": {
		"text": "mod",
		"isAlreadyTranslated": true
	},
	"Product Details": {
		"text": "Detalii produs",
		"isAlreadyTranslated": true
	},
	"Problems Reported by Customer": {
		"text": "Probleme raportate de client",
		"isAlreadyTranslated": true
	},
	"Condition of Equipment": {
		"text": "Starea echipamentului",
		"isAlreadyTranslated": true
	},
	"Inward Check": {
		"text": "Verificare la interior",
		"isAlreadyTranslated": true
	},
	"Outward Check": {
		"text": "Verificare la exterior",
		"isAlreadyTranslated": true
	},
	"Accessories Received": {
		"text": "Accesorii primite",
		"isAlreadyTranslated": true
	},
	"Repair Cost (for OOW cases)": {
		"text": "Costul reparației (pentru cazurile OOW)",
		"isAlreadyTranslated": true
	},
	"Part No.": {
		"text": "Nr. piesă",
		"isAlreadyTranslated": true
	},
	"Part Description": {
		"text": "Descriere piesă",
		"isAlreadyTranslated": true
	},
	"Damaged Part Serial No/ IMEI (if applicable)": {
		"text": "Numărul de serie/IMEI al piesei deteriorate (dacă este cazul)",
		"isAlreadyTranslated": true
	},
	"Replaced Part Serial No/ IMEI (if applicable)": {
		"text": "Numărul de serie/IMEI al piesei înlocuite (dacă este cazul)",
		"isAlreadyTranslated": true
	},
	"Customer Feedback": {
		"text": "Feedbackul clientului",
		"isAlreadyTranslated": true
	},
	"Neither Satisfied nor Dissatisfied": {
		"text": "Nici mulțumit(ă), nici nemulțumit(ă)",
		"isAlreadyTranslated": true
	},
	"Somewhat Dissatisfied": {
		"text": "Oarecum nemulțumit(ă)",
		"isAlreadyTranslated": true
	},
	"Overall Satisfaction": {
		"text": "Mulțumit(ă) per total",
		"isAlreadyTranslated": true
	},
	"Technical Ability": {
		"text": "Abilitate tehnică",
		"isAlreadyTranslated": true
	},
	"Professionalism": {
		"text": "Profesionalism",
		"isAlreadyTranslated": true
	},
	"Response Time": {
		"text": "Timp de răspuns",
		"isAlreadyTranslated": true
	},
	"Quality of Repair": {
		"text": "Calitatea reparației",
		"isAlreadyTranslated": true
	},
	"Timely Update": {
		"text": "Actualizare în timp util",
		"isAlreadyTranslated": true
	},
	"(I am satified with the performance of the Product and have received the Product and all its Accessories)": {
		"text": "(Sunt mulțumit(ă) de performanțele produsului și am primit produsul și toate accesoriile sale)",
		"isAlreadyTranslated": true
	},
	"(For Repair Closure)": {
		"text": "(Pentru închiderea reparațiilor)",
		"isAlreadyTranslated": true
	},
	"Lifecycle": {
		"text": "Ciclul de viață",
		"isAlreadyTranslated": true
	},
	"No results to display": {
		"text": "Nu se afișează niciun rezultat",
		"isAlreadyTranslated": true
	},
	"No information found": {
		"text": "Nu s-au găsit informații",
		"isAlreadyTranslated": true
	},
	"Diagnosed On": {
		"text": "Diagnosticat pe",
		"isAlreadyTranslated": true
	},
	"Select Time Period": {
		"text": "Selectați perioada",
		"isAlreadyTranslated": true
	},
	"Coming Soon!": {
		"text": "În curând!",
		"isAlreadyTranslated": true
	},
	"No Sufficient Data Available To Plot The Graph": {
		"text": "Nu există suficiente date disponibile pentru a trasa graficul",
		"isAlreadyTranslated": true
	},
	"DIAGNOSIS CERTIFICATE": {
		"text": "CERTIFICAT DE DIAGNOSTICARE",
		"isAlreadyTranslated": true
	},
	"Diagnosis Date": {
		"text": "Data diagnosticului",
		"isAlreadyTranslated": true
	},
	"Sim Activation Date": {
		"text": "Data activării SIM",
		"isAlreadyTranslated": true
	},
	"Detailed Diagnostic Report": {
		"text": "Raport detaliat de diagnosticare",
		"isAlreadyTranslated": true
	},
	"Summary": {
		"text": "Rezumat",
		"isAlreadyTranslated": true
	},
	"Tentative Result": {
		"text": "Rezultat provizoriu",
		"isAlreadyTranslated": true
	},
	"Component Name": {
		"text": "Denumirea componentei",
		"isAlreadyTranslated": true
	},
	"Observed Value": {
		"text": "Valoarea observată",
		"isAlreadyTranslated": true
	},
	"Result": {
		"text": "Rezultat",
		"isAlreadyTranslated": true
	},
	"Reference Range": {
		"text": "Interval de referință",
		"isAlreadyTranslated": true
	},
	"Ready to dispatch": {
		"text": "Gata de expediere",
		"isAlreadyTranslated": true
	},
	"Dispatched": {
		"text": "Expediat",
		"isAlreadyTranslated": true
	},
	"Tracking Number": {
		"text": "Număr de urmărire",
		"isAlreadyTranslated": true
	},
	"Box": {
		"text": "Cutie",
		"isAlreadyTranslated": true
	},
	"Width": {
		"text": "Lățime",
		"isAlreadyTranslated": true
	},
	"Height": {
		"text": "Înălțime",
		"isAlreadyTranslated": true
	},
	"Weight": {
		"text": "Greutate",
		"isAlreadyTranslated": true
	},
	"Please enter Box Height.": {
		"text": "Introduceți înălțimea cutiei.",
		"isAlreadyTranslated": true
	},
	"Please enter Box width.": {
		"text": "Introduceți lățimea cutiei.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Length.": {
		"text": "Introduceți lungimea cutiei.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Weight.": {
		"text": "Introduceți greutatea cutiei.",
		"isAlreadyTranslated": true
	},
	"Length": {
		"text": "Lungime",
		"isAlreadyTranslated": true
	},
	"Please enter tracking Number.": {
		"text": "Introduceți numărul de urmărire.",
		"isAlreadyTranslated": true
	},
	"Please enter Shipper Detail.": {
		"text": "Introduceți detaliile expeditorului.",
		"isAlreadyTranslated": true
	},
	"Please Select & Click Branch Name from the Dropdown List": {
		"text": "Selectați și faceți clic pe numele sucursalei din lista verticală",
		"isAlreadyTranslated": true
	},
	"As per invoice": {
		"text": "Conform facturii",
		"isAlreadyTranslated": true
	},
	"Problematic Driver": {
		"text": "Driver problematic",
		"isAlreadyTranslated": true
	},
	"No Problematic drivers found": {
		"text": "Nu s-au găsit drivere problematice",
		"isAlreadyTranslated": true
	},
	"Installed Drivers": {
		"text": "Drivere instalate",
		"isAlreadyTranslated": true
	},
	"No Installed drivers found": {
		"text": "Nu s-au găsit drivere instalate",
		"isAlreadyTranslated": true
	},
	"Type: cashback, discount or referral": {
		"text": "Tip: cashback, reducere sau recomandare",
		"isAlreadyTranslated": true
	},
	"Save Coupon": {
		"text": "Salvați cuponul",
		"isAlreadyTranslated": true
	},
	"Handset Reset": {
		"text": "Resetarea receptorului",
		"isAlreadyTranslated": true
	},
	"Handset Details": {
		"text": "Detalii despre receptor",
		"isAlreadyTranslated": true
	},
	"Service Location Name": {
		"text": "Numele locației de service",
		"isAlreadyTranslated": true
	},
	"Last Resetted On": {
		"text": "Ultima resetare la",
		"isAlreadyTranslated": true
	},
	"No of Handsets Resets Made": {
		"text": "Nr. de resetări ale receptorului efectuate",
		"isAlreadyTranslated": true
	},
	"Handset reset has been done. Please inform the Engineer to login into his Technician App.": {
		"text": "A fost efectuată resetarea receptorului. Informați-l pe Inginer să se conecteze la aplicația sa tehnică.",
		"isAlreadyTranslated": true
	},
	"Assigned/Finished jobs": {
		"text": "Lucrări atribuite/finalizate",
		"isAlreadyTranslated": true
	},
	"Productivity": {
		"text": "Productivitate",
		"isAlreadyTranslated": true
	},
	"Current Job Details": {
		"text": "Detalii despre lucrarea curentă",
		"isAlreadyTranslated": true
	},
	"Next Job Details": {
		"text": "Detalii despre lucrarea următoare",
		"isAlreadyTranslated": true
	},
	"No next job": {
		"text": "Nu există lucrări următoare",
		"isAlreadyTranslated": true
	},
	"No current job": {
		"text": "Nicio lucrare curentă",
		"isAlreadyTranslated": true
	},
	"No previous jobs": {
		"text": "Nu există lucrări anterioare",
		"isAlreadyTranslated": true
	},
	"No record found": {
		"text": "Nu s-au găsit evidențe",
		"isAlreadyTranslated": true
	},
	"User Remarks": {
		"text": "Observațiile utilizatorului",
		"isAlreadyTranslated": true
	},
	"Audience Selection": {
		"text": "Selecția publicului",
		"isAlreadyTranslated": true
	},
	"Final Review": {
		"text": "Revizuirea finală",
		"isAlreadyTranslated": true
	},
	"Potential Reach": {
		"text": "Acoperire potențială",
		"isAlreadyTranslated": true
	},
	"Personnel": {
		"text": "Personal",
		"isAlreadyTranslated": true
	},
	"Select Advisor": {
		"text": "Selectați consilierul",
		"isAlreadyTranslated": true
	},
	"Notification Expiry Duration (in days)": {
		"text": "Durata de expirare a notificării (în zile)",
		"isAlreadyTranslated": true
	},
	"Click here to upload file": {
		"text": "Faceți clic aici pentru a încărca fișierul",
		"isAlreadyTranslated": true
	},
	"View uploaded file": {
		"text": "Vizualizați fișierul încărcat",
		"isAlreadyTranslated": true
	},
	"Notification Details": {
		"text": "Detaliile notificării",
		"isAlreadyTranslated": true
	},
	"Title": {
		"text": "Titlu",
		"isAlreadyTranslated": true
	},
	"Message": {
		"text": "Mesaj",
		"isAlreadyTranslated": true
	},
	"Attachment": {
		"text": "Anexă",
		"isAlreadyTranslated": true
	},
	"Link": {
		"text": "Link",
		"isAlreadyTranslated": true
	},
	"Link to attachment": {
		"text": "Link la anexă",
		"isAlreadyTranslated": true
	},
	"Audience": {
		"text": "Public",
		"isAlreadyTranslated": true
	},
	"All shops and states": {
		"text": "Toate magazinele și statele",
		"isAlreadyTranslated": true
	},
	"Roster Managment": {
		"text": "Gestionarea listei de nume",
		"isAlreadyTranslated": true
	},
	"Sample Files Data Format": {
		"text": "Format de date fișiere exemplificative",
		"isAlreadyTranslated": true
	},
	"Supported Data Format": {
		"text": "Format de date acceptat",
		"isAlreadyTranslated": true
	},
	"Issue Resolved": {
		"text": "Problemă rezolvată",
		"isAlreadyTranslated": true
	},
	"Approve Exception": {
		"text": "Aprobarea excepției",
		"isAlreadyTranslated": true
	},
	"Raise Exception": {
		"text": "Formularea excepției",
		"isAlreadyTranslated": true
	},
	"Select SKU": {
		"text": "Selectați SKU",
		"isAlreadyTranslated": true
	},
	"Product Purchase Cost": {
		"text": "Costul de achiziție a produsului",
		"isAlreadyTranslated": true
	},
	"Refund Amount": {
		"text": "Suma de rambursat",
		"isAlreadyTranslated": true
	},
	"Suggested amount is based on Depreciation Price": {
		"text": "Suma sugerată se bazează pe prețul de amortizare",
		"isAlreadyTranslated": true
	},
	"Upload Document Proof": {
		"text": "Încărcați documentul doveditor",
		"isAlreadyTranslated": true
	},
	"Uploaded Documents": {
		"text": "Documente încărcate",
		"isAlreadyTranslated": true
	},
	"Revert Changes": {
		"text": "Reveniți la starea anterioară modificărilor",
		"isAlreadyTranslated": true
	},
	"Approve": {
		"text": "Aprobare",
		"isAlreadyTranslated": true
	},
	"No Service Requests found for selected date range.": {
		"text": "Nu s-au găsit solicitări de service pentru intervalul de date selectat.",
		"isAlreadyTranslated": true
	},
	"External Service Request Details": {
		"text": "Detalii privind solicitarea de service externă",
		"isAlreadyTranslated": true
	},
	"Service Center Details": {
		"text": "Detalii despre centrul de service",
		"isAlreadyTranslated": true
	},
	"No such request exists": {
		"text": "Nu există o astfel de solicitare",
		"isAlreadyTranslated": true
	},
	"Job Number": {
		"text": "Numărul lucrării",
		"isAlreadyTranslated": true
	},
	"Supplier Job Number": {
		"text": "Numărul lucrării furnizorului",
		"isAlreadyTranslated": true
	},
	"Temporary Job Number": {
		"text": "Numărul lucrării temporare",
		"isAlreadyTranslated": true
	},
	"Service Center Name": {
		"text": "Numele centrului de service",
		"isAlreadyTranslated": true
	},
	"Service Center Code": {
		"text": "Codul centrului de service",
		"isAlreadyTranslated": true
	},
	"Service Center Phone": {
		"text": "Telefonul centrului de service",
		"isAlreadyTranslated": true
	},
	"Service Center Email": {
		"text": "E-mailul centrului de service",
		"isAlreadyTranslated": true
	},
	"Show filtered data": {
		"text": "Afișați datele filtrate",
		"isAlreadyTranslated": true
	},
	"Please select atleast one escalation": {
		"text": "Selectați cel puțin o escaladare",
		"isAlreadyTranslated": true
	},
	"Reset password": {
		"text": "Resetare parolă",
		"isAlreadyTranslated": true
	},
	"Forgot password": {
		"text": "Parolă uitată",
		"isAlreadyTranslated": true
	},
	"Select a Reset Option": {
		"text": "Selectați o opțiune de resetare",
		"isAlreadyTranslated": true
	},
	"LOCATION RESET": {
		"text": "RESETARE LOCAȚIE",
		"isAlreadyTranslated": true
	},
	"Handset reset has already been done. Please inform the corresponding": {
		"text": "Resetarea telefonului a fost deja efectuată. Anunțați centrul corespunzător",
		"isAlreadyTranslated": true
	},
	"to login Mobile COS.": {
		"text": "pentru a vă conecta la Mobile COS.",
		"isAlreadyTranslated": true
	},
	"sell": {
		"text": "vindeți",
		"isAlreadyTranslated": true
	},
	"Diagnosis attempted": {
		"text": "Diagnosticare încercată",
		"isAlreadyTranslated": true
	},
	"Please select the at least one of the custom diagnosis options to initiate diagnosis": {
		"text": "Selectați cel puțin una dintre opțiunile de diagnosticare personalizate pentru a iniția diagnosticarea",
		"isAlreadyTranslated": true
	},
	"Filter Products": {
		"text": "Filtrare produse",
		"isAlreadyTranslated": true
	},
	"Upload Sales": {
		"text": "Încărcați vânzări",
		"isAlreadyTranslated": true
	},
	"Inventory": {
		"text": "Inventar",
		"isAlreadyTranslated": true
	},
	"Receipt Date": {
		"text": "Data primirii",
		"isAlreadyTranslated": true
	},
	"Currently With": {
		"text": "În prezent, cu",
		"isAlreadyTranslated": true
	},
	"Owner": {
		"text": "Proprietar",
		"isAlreadyTranslated": true
	},
	"Error in uploaded csv file at line number:": {
		"text": "Eroare în fișierul csv încărcat la numărul de linie:",
		"isAlreadyTranslated": true
	},
	"Error in following data at line number: 1": {
		"text": "Eroare în următoarele date la numărul de linie: 1",
		"isAlreadyTranslated": true
	},
	"Uploaded file format is not supported": {
		"text": "Formatul fișierului încărcat nu este acceptat",
		"isAlreadyTranslated": true
	},
	"Receipt End Date": {
		"text": "Data de încheiere a primirii",
		"isAlreadyTranslated": true
	},
	"Receipt Start Date": {
		"text": "Data de începere a primirii",
		"isAlreadyTranslated": true
	},
	"Sale Start Date": {
		"text": "Data de începere a vânzării",
		"isAlreadyTranslated": true
	},
	"Sale End Date": {
		"text": "Data de încheiere a vânzării",
		"isAlreadyTranslated": true
	},
	"Parts Charges (sales taxes included)": {
		"text": "Taxe pentru piese (taxe de vânzare incluse)",
		"isAlreadyTranslated": true
	},
	"Sub total of services": {
		"text": "Subtotalul serviciilor",
		"isAlreadyTranslated": true
	},
	"Advance and Loyalty": {
		"text": "Avans și loialitate",
		"isAlreadyTranslated": true
	},
	"Servify Cash Redeemed": {
		"text": "Servify Cash răscumpărat",
		"isAlreadyTranslated": true
	},
	"Adjustment": {
		"text": "Ajustare",
		"isAlreadyTranslated": true
	},
	"Coupon Discount": {
		"text": "Cupon de reducere",
		"isAlreadyTranslated": true
	},
	"Issues Reported By Customer": {
		"text": "Probleme raportate de client",
		"isAlreadyTranslated": true
	},
	"Symptom Description": {
		"text": "Descrierea simptomului",
		"isAlreadyTranslated": true
	},
	"Fault Description": {
		"text": "Descrierea defecțiunii",
		"isAlreadyTranslated": true
	},
	"Action Description": {
		"text": "Descrierea acțiunii",
		"isAlreadyTranslated": true
	},
	"Total Payable Amount": {
		"text": "Suma totală de plată",
		"isAlreadyTranslated": true
	},
	"Spare Consumption": {
		"text": "Consum piese de schimb",
		"isAlreadyTranslated": true
	},
	"Spares Description": {
		"text": "Descrierea pieselor de schimb",
		"isAlreadyTranslated": true
	},
	"Charges": {
		"text": "Taxe",
		"isAlreadyTranslated": true
	},
	"total": {
		"text": "total",
		"isAlreadyTranslated": true
	},
	"Total": {
		"text": "Total",
		"isAlreadyTranslated": true
	},
	"Location Reset": {
		"text": "Resetare locație",
		"isAlreadyTranslated": true
	},
	"Shop Location Reset Details": {
		"text": "Detalii resetare locație magazin",
		"isAlreadyTranslated": true
	},
	"Last Location resetted on": {
		"text": "Ultima locație a fost resetată la",
		"isAlreadyTranslated": true
	},
	"No. of Resets Made": {
		"text": "Nr. de resetări efectuate",
		"isAlreadyTranslated": true
	},
	"to exit and re-open the Mobile COS": {
		"text": "pentru a ieși și a redeschide Mobile COS",
		"isAlreadyTranslated": true
	},
	"Shop Location reset has already been done. Please inform the corresponding": {
		"text": "Resetarea locației magazinului a fost deja efectuată. Anunțați centrul corespunzător",
		"isAlreadyTranslated": true
	},
	"Previous Locations Reset Details": {
		"text": "Detalii resetare anterioară locații",
		"isAlreadyTranslated": true
	},
	"Sign in": {
		"text": "Conectați-vă",
		"isAlreadyTranslated": true
	},
	"to continue.": {
		"text": "pentru a continua.",
		"isAlreadyTranslated": true
	},
	"Resend OTP": {
		"text": "Retrimiteți OTP",
		"isAlreadyTranslated": true
	},
	"Login": {
		"text": "Autentificare",
		"isAlreadyTranslated": true
	},
	"User Type": {
		"text": "Tip utilizator",
		"isAlreadyTranslated": true
	},
	"Username/Email": {
		"text": "Nume de utilizator/E-mail",
		"isAlreadyTranslated": true
	},
	"View requests": {
		"text": "Vizualizare solicitări",
		"isAlreadyTranslated": true
	},
	"All fields are mandatory.": {
		"text": "Toate câmpurile sunt obligatorii.",
		"isAlreadyTranslated": true
	},
	"Pickup Details": {
		"text": "Detalii preluare",
		"isAlreadyTranslated": true
	},
	"Drop Details": {
		"text": "Detalii predare",
		"isAlreadyTranslated": true
	},
	"Check Service Availability": {
		"text": "Verificați disponibilitatea service-ului",
		"isAlreadyTranslated": true
	},
	"Invoice Date": {
		"text": "Data facturii",
		"isAlreadyTranslated": true
	},
	"Flow": {
		"text": "Flux",
		"isAlreadyTranslated": true
	},
	"Issue": {
		"text": "Problemă",
		"isAlreadyTranslated": true
	},
	"Reference Number": {
		"text": "Număr de referință",
		"isAlreadyTranslated": true
	},
	"Provider": {
		"text": "Furnizor",
		"isAlreadyTranslated": true
	},
	"Create Shipment": {
		"text": "Creați expedierea",
		"isAlreadyTranslated": true
	},
	"Logistics request is not raised. Please try again": {
		"text": "Solicitarea de logistică nu este formulată. Încercați din nou",
		"isAlreadyTranslated": true
	},
	"IMEI has to be atleast 15 characters.": {
		"text": "IMEI trebuie să aibă cel puțin 15 caractere.",
		"isAlreadyTranslated": true
	},
	"The request is already active with this IMEI/Serial No.": {
		"text": "Solicitarea este deja activă cu acest număr IMEI/nr. de serie.",
		"isAlreadyTranslated": true
	},
	"Service Location Engineer Mapping": {
		"text": "Mapare Inginer locație de service",
		"isAlreadyTranslated": true
	},
	"Pincode Engineer Mapping": {
		"text": "Mapare Inginer cod PIN",
		"isAlreadyTranslated": true
	},
	"Last set on": {
		"text": "Setat ultima dată la",
		"isAlreadyTranslated": true
	},
	"Variant": {
		"text": "Variantă",
		"isAlreadyTranslated": true
	},
	"MOP for Trade-in": {
		"text": "MOP pentru schimb",
		"isAlreadyTranslated": true
	},
	"Cashback": {
		"text": "Cashback",
		"isAlreadyTranslated": true
	},
	"Show Trade-In Amount": {
		"text": "Afișați suma de schimb",
		"isAlreadyTranslated": true
	},
	"No records": {
		"text": "Nu există evidențe",
		"isAlreadyTranslated": true
	},
	"Battery Conditions": {
		"text": "Starea bateriei",
		"isAlreadyTranslated": true
	},
	"Body Conditions": {
		"text": "Starea carcasei",
		"isAlreadyTranslated": true
	},
	"Nothing to save": {
		"text": "Nimic de salvat",
		"isAlreadyTranslated": true
	},
	"Push Notification": {
		"text": "Notificare Push",
		"isAlreadyTranslated": true
	},
	"Is Warranty Expired?": {
		"text": "Garanția a expirat?",
		"isAlreadyTranslated": true
	},
	"No. of Service Requests": {
		"text": "Nr. de solicitări de service",
		"isAlreadyTranslated": true
	},
	"Please Close all the tasks": {
		"text": "Închideți toate activitățile",
		"isAlreadyTranslated": true
	},
	"Please rate the service": {
		"text": "Evaluați service-ul",
		"isAlreadyTranslated": true
	},
	"Feedback": {
		"text": "Feedback",
		"isAlreadyTranslated": true
	},
	"Submit with Feedback": {
		"text": "Trimiteți cu feedback",
		"isAlreadyTranslated": true
	},
	"Please select a reason for cancelling service": {
		"text": "Selectați un motiv de anulare a service-ului",
		"isAlreadyTranslated": true
	},
	"Close Visit": {
		"text": "Închideți vizita",
		"isAlreadyTranslated": true
	},
	"Visit Disposition Status": {
		"text": "Starea de dispoziție a vizitei",
		"isAlreadyTranslated": true
	},
	"Request Status": {
		"text": "Starea solicitării",
		"isAlreadyTranslated": true
	},
	"Complete Visit": {
		"text": "Vizită finalizată",
		"isAlreadyTranslated": true
	},
	"Submit IRIS": {
		"text": "Trimiteți IRIS",
		"isAlreadyTranslated": true
	},
	"Select Symptom": {
		"text": "Selectați simptomul",
		"isAlreadyTranslated": true
	},
	"Select Action": {
		"text": "Selectați acțiunea",
		"isAlreadyTranslated": true
	},
	"Select Fault": {
		"text": "Selectați defecțiunea",
		"isAlreadyTranslated": true
	},
	"Symptom": {
		"text": "Simptom",
		"isAlreadyTranslated": true
	},
	"Fault": {
		"text": "Defecțiune",
		"isAlreadyTranslated": true
	},
	"Complete Visit And Submit IRIS": {
		"text": "Finalizați vizita și trimiteți IRIS",
		"isAlreadyTranslated": true
	},
	"Create Visit": {
		"text": "Creați o vizită",
		"isAlreadyTranslated": true
	},
	"Select an Engineer to assign": {
		"text": "Selectați un Inginer pentru a atribui",
		"isAlreadyTranslated": true
	},
	"Reason For Follow Up": {
		"text": "Motivul urmăririi",
		"isAlreadyTranslated": true
	},
	"Clone this request": {
		"text": "Clonați această solicitare",
		"isAlreadyTranslated": true
	},
	"No of requests to be cloned": {
		"text": "Nr. de solicitări care urmează să fie clonate",
		"isAlreadyTranslated": true
	},
	"Priority": {
		"text": "Prioritate",
		"isAlreadyTranslated": true
	},
	"Update Job": {
		"text": "Actualizare lucrare",
		"isAlreadyTranslated": true
	},
	"Journey Details": {
		"text": "Detalii despre călătorie",
		"isAlreadyTranslated": true
	},
	"Enter date": {
		"text": "Introduceți data",
		"isAlreadyTranslated": true
	},
	"Begin time": {
		"text": "Ora de începere",
		"isAlreadyTranslated": true
	},
	"End time": {
		"text": "Ora de încheiere",
		"isAlreadyTranslated": true
	},
	"Note : Time must be in between": {
		"text": "Notă: Ora trebuie să fie între",
		"isAlreadyTranslated": true
	},
	"Add Parts": {
		"text": "Adăugați piese",
		"isAlreadyTranslated": true
	},
	"Avl. Quantity": {
		"text": "Disponibil Cantitate",
		"isAlreadyTranslated": true
	},
	"Added Parts": {
		"text": "Piese adăugate",
		"isAlreadyTranslated": true
	},
	"Document": {
		"text": "Document",
		"isAlreadyTranslated": true
	},
	"View Image": {
		"text": "Vizualizare imagine",
		"isAlreadyTranslated": true
	},
	"No parts found for this product.": {
		"text": "Nu s-au găsit piese pentru acest produs.",
		"isAlreadyTranslated": true
	},
	"Can't add more than one discount": {
		"text": "Nu puteți adăuga mai mult de o reducere",
		"isAlreadyTranslated": true
	},
	"This IRIS has already been added.": {
		"text": "Acest IRIS a fost deja adăugat.",
		"isAlreadyTranslated": true
	},
	"Please add a part from dropdown.": {
		"text": "Adăugați o piesă din lista verticală.",
		"isAlreadyTranslated": true
	},
	"Part is already added": {
		"text": "Piesa este deja adăugată",
		"isAlreadyTranslated": true
	},
	"Full box unit is already selected.": {
		"text": "Unitatea completă a cutiei este deja selectată.",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to create a visit?": {
		"text": "Sigur doriți să creați o vizită?",
		"isAlreadyTranslated": true
	},
	"Please select an engineer to assign": {
		"text": "Selectați un Inginer pentru a atribui",
		"isAlreadyTranslated": true
	},
	"IRIS Codes has been updated successfully.": {
		"text": "Codurile IRIS au fost actualizate cu succes.",
		"isAlreadyTranslated": true
	},
	"The payment has been received and service will be completed.": {
		"text": "Plata a fost primită și service-ul va fi finalizat.",
		"isAlreadyTranslated": true
	},
	"Converted Requests": {
		"text": "Solicitări convertite",
		"isAlreadyTranslated": true
	},
	"Identifiers": {
		"text": "Identificatori",
		"isAlreadyTranslated": true
	},
	"Verify Device": {
		"text": "Verificați dispozitivul",
		"isAlreadyTranslated": true
	},
	"Review Order": {
		"text": "Revizuiți comanda",
		"isAlreadyTranslated": true
	},
	"Order Summary": {
		"text": "Rezumatul comenzii",
		"isAlreadyTranslated": true
	},
	"Congratulations! Your device is eligible for this plan": {
		"text": "Felicitări! Dispozitivul dvs. este eligibil pentru acest plan",
		"isAlreadyTranslated": true
	},
	"This Device is Eligible for this Plan": {
		"text": "Acest dispozitiv este eligibil pentru acest plan",
		"isAlreadyTranslated": true
	},
	"Price of Plan": {
		"text": "Prețul planului",
		"isAlreadyTranslated": true
	},
	"Transaction Details": {
		"text": "Detalii privind tranzacția",
		"isAlreadyTranslated": true
	},
	"Device Images": {
		"text": "Imagini dispozitiv",
		"isAlreadyTranslated": true
	},
	"(All 6 Images are Mandatory)": {
		"text": "(Toate cele 6 imagini sunt obligatorii)",
		"isAlreadyTranslated": true
	},
	"Product Purchase Date": {
		"text": "Data achiziționării produsului",
		"isAlreadyTranslated": true
	},
	"Product value": {
		"text": "Valoarea produsului",
		"isAlreadyTranslated": true
	},
	"Plan Price": {
		"text": "Preț plan",
		"isAlreadyTranslated": true
	},
	"The user has agreed with the": {
		"text": "Utilizatorul a fost de acord cu",
		"isAlreadyTranslated": true
	},
	"Terms and Conditions": {
		"text": "Termeni si Conditii ale asigurarii",
		"isAlreadyTranslated": true
	},
	"before purchasing this plan.": {
		"text": "înainte de a achiziționa acest plan.",
		"isAlreadyTranslated": true
	},
	"Resend Link": {
		"text": "Trimiteți din nou linkul",
		"isAlreadyTranslated": true
	},
	"Refresh": {
		"text": "Reîmprospătare",
		"isAlreadyTranslated": true
	},
	"Resend Payment Link": {
		"text": "Retrimiteți linkul de plată",
		"isAlreadyTranslated": true
	},
	"Refund": {
		"text": "Rambursare",
		"isAlreadyTranslated": true
	},
	"Invoice Number": {
		"text": "Numărul facturii",
		"isAlreadyTranslated": true
	},
	"Documents Uploaded": {
		"text": "Documente încărcate",
		"isAlreadyTranslated": true
	},
	"Transaction Summary": {
		"text": "Rezumatul tranzacției",
		"isAlreadyTranslated": true
	},
	"Download": {
		"text": "Descărcare",
		"isAlreadyTranslated": true
	},
	"Proof of Coverage (POC)": {
		"text": "Dovada de acoperire (POC)",
		"isAlreadyTranslated": true
	},
	"Insurance Certificate": {
		"text": "Certificat de asigurare",
		"isAlreadyTranslated": true
	},
	"Print Invoice": {
		"text": "Imprimare factură",
		"isAlreadyTranslated": true
	},
	"Cancel Plan": {
		"text": "Anulați planul",
		"isAlreadyTranslated": true
	},
	"Go to Home": {
		"text": "Mergeți la pagina de pornire",
		"isAlreadyTranslated": true
	},
	"Plan Cancelled": {
		"text": "Plan anulat",
		"isAlreadyTranslated": true
	},
	"Check Eligibility": {
		"text": "Verificați eligibilitatea",
		"isAlreadyTranslated": true
	},
	"Repair Date": {
		"text": "Data reparației",
		"isAlreadyTranslated": true
	},
	"Plan Purchase Date": {
		"text": "Data achiziționării planului",
		"isAlreadyTranslated": true
	},
	"Sell Now": {
		"text": "Vinde",
		"isAlreadyTranslated": true
	},
	"Payment Link ReSent": {
		"text": "Link de plată ReSent",
		"isAlreadyTranslated": true
	},
	"Payment Link Sent": {
		"text": "Link de plată trimis",
		"isAlreadyTranslated": true
	},
	"Link sent to": {
		"text": "Link trimis către",
		"isAlreadyTranslated": true
	},
	"The Payment link is only valid for 24 hours.": {
		"text": "Linkul de plată este valabil doar 24 de ore.",
		"isAlreadyTranslated": true
	},
	"Email POC to below email address": {
		"text": "Trimiteți POC la adresa de e-mail de mai jos",
		"isAlreadyTranslated": true
	},
	"Send a copy to below email address": {
		"text": "Trimiteți o copie la adresa de e-mail de mai jos",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to cancel the order?": {
		"text": "Sigur doriți să anulați comanda?",
		"isAlreadyTranslated": true
	},
	"Select reason for cancellation": {
		"text": "Selectați motivul anulării",
		"isAlreadyTranslated": true
	},
	"Cancel Order": {
		"text": "Anulați comanda",
		"isAlreadyTranslated": true
	},
	"Plan price data not found.": {
		"text": "Datele privind prețul planului nu au fost găsite.",
		"isAlreadyTranslated": true
	},
	"Discount Value cannot be greater than 100%": {
		"text": "Valoarea reducerii nu poate fi mai mare de 100%.",
		"isAlreadyTranslated": true
	},
	"Discount Value cannot be greater than the Plan Price": {
		"text": "Valoarea reducerii nu poate fi mai mare decât prețul planului",
		"isAlreadyTranslated": true
	},
	"Incorrect discount value": {
		"text": "Valoarea reducerii este incorectă",
		"isAlreadyTranslated": true
	},
	"The payment link has been sent to": {
		"text": "Linkul de plată a fost trimis la",
		"isAlreadyTranslated": true
	},
	"You have successfully purchased": {
		"text": "Achiziție efectuată cu succes",
		"isAlreadyTranslated": true
	},
	"Your AppleCare Protection Plan proof of coverage document will be emailed to you in 24 hours.": {
		"text": "Documentul de dovadă a acoperirii Planului de protecție AppleCare vă va fi trimis prin e-mail în 24 de ore.",
		"isAlreadyTranslated": true
	},
	"Please enter correct email address": {
		"text": "Introduceți adresa de e-mail corectă",
		"isAlreadyTranslated": true
	},
	"Enter Email Address of the user whose rights you want to": {
		"text": "Introduceți adresa de e-mail a utilizatorului ale cărui drepturi doriți să le modificați.",
		"isAlreadyTranslated": true
	},
	"Associated Partner": {
		"text": "Partener asociat",
		"isAlreadyTranslated": true
	},
	"Access given for": {
		"text": "Acces acordat pentru",
		"isAlreadyTranslated": true
	},
	"Add Brands": {
		"text": "Adăugați mărci",
		"isAlreadyTranslated": true
	},
	"CHOOSE BRAND": {
		"text": "ALEGEȚI MARCA",
		"isAlreadyTranslated": true
	},
	"No Other Requests": {
		"text": "Nu există alte solicitări",
		"isAlreadyTranslated": true
	},
	"No parts requests in this section.": {
		"text": "Nu există solicitări de piese în această secțiune.",
		"isAlreadyTranslated": true
	},
	"Charge Code": {
		"text": "Cod de cheltuieli",
		"isAlreadyTranslated": true
	},
	"AWB Number": {
		"text": "Numărul AWB",
		"isAlreadyTranslated": true
	},
	"Add more": {
		"text": "Adăugați mai multe",
		"isAlreadyTranslated": true
	},
	"Vendor name": {
		"text": "Numele furnizorului",
		"isAlreadyTranslated": true
	},
	"Instruction": {
		"text": "Instrucțiune",
		"isAlreadyTranslated": true
	},
	"Logistics Service Type": {
		"text": "Tipul de serviciu logistic",
		"isAlreadyTranslated": true
	},
	"SKU Requested": {
		"text": "SKU solicitat",
		"isAlreadyTranslated": true
	},
	"Code": {
		"text": "Cod",
		"isAlreadyTranslated": true
	},
	"Defective Serial No": {
		"text": "Număr de serie defectuos",
		"isAlreadyTranslated": true
	},
	"All parts consumed will be reverted and the Engineer has to handover these good parts to the Store Manager. Are you sure you want to proceed?": {
		"text": "Toate piesele consumate vor fi returnate, iar Inginerul trebuie să predea aceste piese bune Managerului de magazin. Sigur doriți să continuați?",
		"isAlreadyTranslated": true
	},
	"Diagnostics Event Number": {
		"text": "Numărul evenimentului de diagnosticare",
		"isAlreadyTranslated": true
	},
	"Unable to validate details from GSX currently. Please try again after sometime.": {
		"text": "Nu se poate valida detaliile din GSX în prezent. Încercați din nou mai târziu.",
		"isAlreadyTranslated": true
	},
	"Please specify remarks": {
		"text": "Specificați observațiile",
		"isAlreadyTranslated": true
	},
	"Engineers": {
		"text": "Ingineri",
		"isAlreadyTranslated": true
	},
	"Update Mapping": {
		"text": "Actualizarea mapării",
		"isAlreadyTranslated": true
	},
	"mappings are shown above.": {
		"text": "mapările sunt prezentate mai sus.",
		"isAlreadyTranslated": true
	},
	"Engineers Under TL": {
		"text": "Inginerii în cadrul TL",
		"isAlreadyTranslated": true
	},
	"Plan Header": {
		"text": "Antet de plan",
		"isAlreadyTranslated": true
	},
	"Plan Benefits": {
		"text": "Beneficiile planului",
		"isAlreadyTranslated": true
	},
	"Plan Information": {
		"text": "Informații despre plan",
		"isAlreadyTranslated": true
	},
	"Plan updated successfully": {
		"text": "Planul a fost actualizat cu succes",
		"isAlreadyTranslated": true
	},
	"Tax is payable on Reverse Charge": {
		"text": "Taxa se plătește prin taxare inversă",
		"isAlreadyTranslated": true
	},
	"Invoice No": {
		"text": "Nr. factură",
		"isAlreadyTranslated": true
	},
	"Details of Receiver (Billed to)": {
		"text": "Detalii despre persoana care recepționează (facturat către)",
		"isAlreadyTranslated": true
	},
	"Details of Consignee (Shipped to)": {
		"text": "Detalii despre destinatar (expediat către)",
		"isAlreadyTranslated": true
	},
	"Description of Goods/Services": {
		"text": "Descrierea bunurilor/serviciilor",
		"isAlreadyTranslated": true
	},
	"Invoice Total(In Words)": {
		"text": "Total factură (în cuvinte)",
		"isAlreadyTranslated": true
	},
	"Invoice Total": {
		"text": "Total factură",
		"isAlreadyTranslated": true
	},
	"Advance Received": {
		"text": "Avansul primit",
		"isAlreadyTranslated": true
	},
	"Payable Value": {
		"text": "Valoare de plată",
		"isAlreadyTranslated": true
	},
	"We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.": {
		"text": "Declarăm că această factură indică prețul real al bunurilor descrise și că toate informațiile sunt adevărate și corecte.",
		"isAlreadyTranslated": true
	},
	"View Consumers": {
		"text": "Vizualizare consumatori",
		"isAlreadyTranslated": true
	},
	"Plan Details": {
		"text": "Detalii despre plan",
		"isAlreadyTranslated": true
	},
	"Plan FAQ": {
		"text": "Întrebări frecvente despre plan",
		"isAlreadyTranslated": true
	},
	"Sold Plans": {
		"text": "Planuri vândute",
		"isAlreadyTranslated": true
	},
	"No sold plans found for the selected date range.": {
		"text": "Nu s-au găsit planuri vândute pentru intervalul de date selectat.",
		"isAlreadyTranslated": true
	},
	"Would you like to add a product?": {
		"text": "Doriți să adăugați un produs?",
		"isAlreadyTranslated": true
	},
	"Product is saved successfully": {
		"text": "Produsul este salvat cu succes",
		"isAlreadyTranslated": true
	},
	"Apply a filter before sending push notification.": {
		"text": "Aplicați un filtru înainte de a trimite notificarea push.",
		"isAlreadyTranslated": true
	},
	"Redeemed Coupons": {
		"text": "Cupoane răscumpărate",
		"isAlreadyTranslated": true
	},
	"Start at your Home screen.": {
		"text": "Începeți din ecranul de pornire.",
		"isAlreadyTranslated": true
	},
	"Scroll to the bottom and tap Find My iPhone.": {
		"text": "Derulați până în partea de jos și atingeți Găsește-mi iPhone-ul.",
		"isAlreadyTranslated": true
	},
	"Slide to turn off Find My iPhone and Send Last Location.": {
		"text": "Glisați pentru a dezactiva Găsește-mi iPhone-ul meu și Trimite ultima locație.",
		"isAlreadyTranslated": true
	},
	"Servify has received your device and will be sending it to the eRecycling hub after verification.": {
		"text": "Servify a primit dispozitivul dvs. și îl va trimite la hubul eRecycling după verificare.",
		"isAlreadyTranslated": true
	},
	"Servify has collected your device and will be sending it to the eRecycling hub after verification.": {
		"text": "Servify a colectat dispozitivul dvs. și îl va trimite la hubul eRecycling după verificare.",
		"isAlreadyTranslated": true
	},
	"All the data from your device has been erased.": {
		"text": "Toate datele de pe dispozitivul dvs. au fost șterse.",
		"isAlreadyTranslated": true
	},
	"Please rate your eRecycling experience.": {
		"text": "Evaluați experiența dvs. cu eRecycling.",
		"isAlreadyTranslated": true
	},
	"We have disassembled key components of your device and will be sending it to the eRecycling Hub soon.": {
		"text": "Am dezasamblat componentele cheie ale dispozitivului dvs. și îl vom trimite în curând la hubul eRecycling.",
		"isAlreadyTranslated": true
	},
	"Date of Refund": {
		"text": "Data rambursării",
		"isAlreadyTranslated": true
	},
	"Select Category for the appliance": {
		"text": "Selectați categoria pentru aparat",
		"isAlreadyTranslated": true
	},
	"Select Category": {
		"text": "Selectați categoria",
		"isAlreadyTranslated": true
	},
	"Please select which applies.": {
		"text": "Selectați variantele care se aplică.",
		"isAlreadyTranslated": true
	},
	"Is Electrically OK": {
		"text": "Este în regulă din punct de vedere electric",
		"isAlreadyTranslated": true
	},
	"Are Accessories Available": {
		"text": "Sunt disponibile accesorii",
		"isAlreadyTranslated": true
	},
	"Dents/Scratches": {
		"text": "Lovituri/zgârieturi",
		"isAlreadyTranslated": true
	},
	"Packing Box": {
		"text": "Cutie de ambalare",
		"isAlreadyTranslated": true
	},
	"Approve Refund Request": {
		"text": "Aprobați cererea de rambursare",
		"isAlreadyTranslated": true
	},
	"Please enter Token Number.": {
		"text": "Introduceți numărul tokenului.",
		"isAlreadyTranslated": true
	},
	"Please select a Category from the dropdown": {
		"text": "Selectați o categorie din lista verticală",
		"isAlreadyTranslated": true
	},
	"Reject Refund Request": {
		"text": "Respingeți solicitarea de rambursare",
		"isAlreadyTranslated": true
	},
	"Reason for rejection": {
		"text": "Motivul respingerii",
		"isAlreadyTranslated": true
	},
	"Reason for": {
		"text": "Motivul pentru",
		"isAlreadyTranslated": true
	},
	"Please enter Serial Number.": {
		"text": "Introduceți numărul de serie.",
		"isAlreadyTranslated": true
	},
	"Select a time slot": {
		"text": "Selectați un interval orar",
		"isAlreadyTranslated": true
	},
	"Add reason": {
		"text": "Adăugați un motiv",
		"isAlreadyTranslated": true
	},
	"Please enter correct serial number": {
		"text": "Introduceți numărul de serie corect",
		"isAlreadyTranslated": true
	},
	"Download Report": {
		"text": "Descărcați raportul",
		"isAlreadyTranslated": true
	},
	"Filter Reports": {
		"text": "Filtrarea rapoartelor",
		"isAlreadyTranslated": true
	},
	"Recent Requested Report": {
		"text": "Raport solicitat recent",
		"isAlreadyTranslated": true
	},
	"Report Type": {
		"text": "Tip de raport",
		"isAlreadyTranslated": true
	},
	"Report requested on": {
		"text": "Raport solicitat cu privire la",
		"isAlreadyTranslated": true
	},
	"Report Status": {
		"text": "Starea raportului",
		"isAlreadyTranslated": true
	},
	"Requested Reports": {
		"text": "Rapoarte solicitate",
		"isAlreadyTranslated": true
	},
	"Logistics Vendor": {
		"text": "Furnizor de logistică",
		"isAlreadyTranslated": true
	},
	"Organization Outlet Locations": {
		"text": "Locații ale punctelor de desfacere ale organizației",
		"isAlreadyTranslated": true
	},
	"Type of Report": {
		"text": "Tipul de raport",
		"isAlreadyTranslated": true
	},
	"Request Type": {
		"text": "Tip de solicitare",
		"isAlreadyTranslated": true
	},
	"No OLCSM found for selected Service Location": {
		"text": "Nu s-a găsit niciun OLCSM pentru locația de service selectată",
		"isAlreadyTranslated": true
	},
	"No Engineer found for selected Service Location": {
		"text": "Nu s-a găsit niciun Inginer pentru locația de service selectată",
		"isAlreadyTranslated": true
	},
	"No partner service location details found for": {
		"text": "Nu s-au găsit detalii despre locația service-ului partener pentru",
		"isAlreadyTranslated": true
	},
	"No partner details found for": {
		"text": "Nu s-au găsit detalii despre parteneri pentru",
		"isAlreadyTranslated": true
	},
	"Please select report type to download": {
		"text": "Selectați tipul de raport care să fie descărcat",
		"isAlreadyTranslated": true
	},
	"Please select a vendor from Filter Reports": {
		"text": "Selectați un furnizor din Filtrarea rapoartelor",
		"isAlreadyTranslated": true
	},
	"Please select a organization outlet location from Filter Reports": {
		"text": "Selectați o locație a punctului de desfacere al organizației din Filtrarea rapoartelor",
		"isAlreadyTranslated": true
	},
	"Please select a Service Location Engineer from Filter Reports": {
		"text": "Selectați un Inginer la locația de service din Filtrare rapoarte",
		"isAlreadyTranslated": true
	},
	"Please select a Type of Report from Filter Reports": {
		"text": "Selectați un tip de raport din Filtrare rapoarte",
		"isAlreadyTranslated": true
	},
	"Please select a Product from Filter Reports": {
		"text": "Selectați un produs din Filtrare rapoarte",
		"isAlreadyTranslated": true
	},
	"Please select a Service Type from Filter Reports": {
		"text": "Selectați un tip de serviciu din Filtrare rapoarte",
		"isAlreadyTranslated": true
	},
	"Please select a product category from Filter Reports": {
		"text": "Selectați o categorie de produse din Filtrare rapoarte",
		"isAlreadyTranslated": true
	},
	"Please select request type from Filter Reports": {
		"text": "Selectați tipul de solicitare din Filtrare rapoarte",
		"isAlreadyTranslated": true
	},
	"Error in fetching Service Location IDs": {
		"text": "Eroare la preluarea ID-urilor de locație a service-ului",
		"isAlreadyTranslated": true
	},
	"REQUEST FORM": {
		"text": "FORMULAR DE SOLICITARE",
		"isAlreadyTranslated": true
	},
	"Sales Invoice": {
		"text": "Factură de vânzare",
		"isAlreadyTranslated": true
	},
	"EW claim no": {
		"text": "Nr. solicitare de despăgubire EW",
		"isAlreadyTranslated": true
	},
	"Created By": {
		"text": "Creat de",
		"isAlreadyTranslated": true
	},
	"Google Location": {
		"text": "Locația Google",
		"isAlreadyTranslated": true
	},
	"Upload invoice": {
		"text": "Încărcați factura",
		"isAlreadyTranslated": true
	},
	"VIP/Urgent": {
		"text": "VIP/Urgent",
		"isAlreadyTranslated": true
	},
	"Is Service complimentary ?": {
		"text": "Serviciul este gratuit?",
		"isAlreadyTranslated": true
	},
	"Please enter correct Email ID": {
		"text": "Introduceți ID-ul de e-mail corect",
		"isAlreadyTranslated": true
	},
	"Please enter correct Mobile No": {
		"text": "Introduceți numărul de telefon mobil corect",
		"isAlreadyTranslated": true
	},
	"Please enter correct Pincode": {
		"text": "Introduceți codul PIN corect",
		"isAlreadyTranslated": true
	},
	"Availability": {
		"text": "Disponibilitate",
		"isAlreadyTranslated": true
	},
	"Skills": {
		"text": "Competențe",
		"isAlreadyTranslated": true
	},
	"Shop details updated successfully.": {
		"text": "Detaliile magazinului au fost actualizate cu succes.",
		"isAlreadyTranslated": true
	},
	"New Shop added successfully.": {
		"text": "Magazin nou adăugat cu succes.",
		"isAlreadyTranslated": true
	},
	"Save Details": {
		"text": "Salvați detaliile",
		"isAlreadyTranslated": true
	},
	"Shop Name": {
		"text": "Numele magazinului",
		"isAlreadyTranslated": true
	},
	"Shop Code": {
		"text": "Cod magazin",
		"isAlreadyTranslated": true
	},
	"Carpet Area (in sq. feet)": {
		"text": "Suprafața covorului (în metri pătrați)",
		"isAlreadyTranslated": true
	},
	"Contact Number (10 digit)": {
		"text": "Număr de contact (10 cifre)",
		"isAlreadyTranslated": true
	},
	"Period (Max 31 Days)": {
		"text": "Perioadă (maximum 31 de zile)",
		"isAlreadyTranslated": true
	},
	"Download list of requests": {
		"text": "Descărcați lista de solicitări",
		"isAlreadyTranslated": true
	},
	"Upload list of requests": {
		"text": "Încărcați lista de solicitări",
		"isAlreadyTranslated": true
	},
	"Sample format for uploading requests": {
		"text": "Exemplu de format pentru încărcarea solicitărilor",
		"isAlreadyTranslated": true
	},
	"There are no requests to be downloaded": {
		"text": "Nu există solicitări care să fie descărcate",
		"isAlreadyTranslated": true
	},
	"Requests updated successfully": {
		"text": "Solicitări actualizate cu succes",
		"isAlreadyTranslated": true
	},
	"Cannot read this file": {
		"text": "Acest fișier nu poate fi citit",
		"isAlreadyTranslated": true
	},
	"Please upload file with appropriate data": {
		"text": "Încărcați fișierul cu datele corespunzătoare",
		"isAlreadyTranslated": true
	},
	"Cancel Changes": {
		"text": "Anulare modificări",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to cancel changes you have made?": {
		"text": "Sigur doriți să anulați modificările pe care le-ați făcut?",
		"isAlreadyTranslated": true
	},
	"Add Retail Location": {
		"text": "Adăugați o locație de vânzare cu amănuntul",
		"isAlreadyTranslated": true
	},
	"View Location": {
		"text": "Vizualizare locație",
		"isAlreadyTranslated": true
	},
	"Enter Mobile number of the": {
		"text": "Introduceți numărul de telefon mobil al",
		"isAlreadyTranslated": true
	},
	"whose rights you want to": {
		"text": "ale cărui drepturi doriți să le",
		"isAlreadyTranslated": true
	},
	"modify": {
		"text": "modificare",
		"isAlreadyTranslated": true
	},
	"Associated Shop": {
		"text": "Magazin asociat",
		"isAlreadyTranslated": true
	},
	"choose shop to assign": {
		"text": "alegeți magazinul pe care să îl atribuiți",
		"isAlreadyTranslated": true
	},
	"Branch Code": {
		"text": "Codul sucursalei",
		"isAlreadyTranslated": true
	},
	"TOTAL SALES DETAILS": {
		"text": "DETALII PRIVIND VÂNZĂRILE TOTALE",
		"isAlreadyTranslated": true
	},
	"VERIFIED SALES DETAILS": {
		"text": "DETALII PRIVIND VÂNZĂRILE VERIFICATE",
		"isAlreadyTranslated": true
	},
	"UNVERIFIED SALES DETAILS": {
		"text": "DETALII PRIVIND VÂNZĂRILE NEVERIFICATE",
		"isAlreadyTranslated": true
	},
	"SHOP & ADVISOR DETAILS": {
		"text": "DETALII MAGAZIN ȘI CONSILIER",
		"isAlreadyTranslated": true
	},
	"Todays Attendance": {
		"text": "Prezența de astăzi",
		"isAlreadyTranslated": true
	},
	"Device Model List": {
		"text": "Lista de modele de dispozitive",
		"isAlreadyTranslated": true
	},
	"Select Plans": {
		"text": "Selectați planurile",
		"isAlreadyTranslated": true
	},
	"No Plans Available": {
		"text": "Nu există planuri disponibile",
		"isAlreadyTranslated": true
	},
	"Product details cannot be validated. Please choose from the device list.": {
		"text": "Detaliile produsului nu pot fi validate. Alegeți din lista de dispozitive.",
		"isAlreadyTranslated": true
	},
	"Device is eligible for multiple plans. Please choose a plan.": {
		"text": "Dispozitivul este eligibil pentru mai multe planuri. Alegeți un plan.",
		"isAlreadyTranslated": true
	},
	"Device is eligible!": {
		"text": "Dispozitivul este eligibil!",
		"isAlreadyTranslated": true
	},
	"Invoice No.": {
		"text": "Factura nr.",
		"isAlreadyTranslated": true
	},
	"Invoice Amount": {
		"text": "Valoarea facturii",
		"isAlreadyTranslated": true
	},
	"Sale Date": {
		"text": "Data vânzării",
		"isAlreadyTranslated": true
	},
	"Create": {
		"text": "Creare",
		"isAlreadyTranslated": true
	},
	"Active": {
		"text": "Activ",
		"isAlreadyTranslated": true
	},
	"Inactive": {
		"text": "Inactiv",
		"isAlreadyTranslated": true
	},
	"Pending": {
		"text": "În așteptare",
		"isAlreadyTranslated": true
	},
	"Please select a location": {
		"text": "Selectați o locație",
		"isAlreadyTranslated": true
	},
	"Content ID": {
		"text": "ID conținut",
		"isAlreadyTranslated": true
	},
	"Last Modified": {
		"text": "Ultima modificare",
		"isAlreadyTranslated": true
	},
	"You have no new requests.": {
		"text": "Nu aveți solicitări noi.",
		"isAlreadyTranslated": true
	},
	"Detail View": {
		"text": "Vizualizare detaliată",
		"isAlreadyTranslated": true
	},
	"Complete Analysis": {
		"text": "Analiză finalizată",
		"isAlreadyTranslated": true
	},
	"Root Cause Analysis.": {
		"text": "Analiza cauzelor rădăcină.",
		"isAlreadyTranslated": true
	},
	"Solution Provided.": {
		"text": "Soluție furnizată.",
		"isAlreadyTranslated": true
	},
	"Accessories will not be returned.": {
		"text": "Accesoriile nu vor fi returnate.",
		"isAlreadyTranslated": true
	},
	"Assign Engineer": {
		"text": "Atribuiți un Inginer",
		"isAlreadyTranslated": true
	},
	"Update job details": {
		"text": "Actualizarea detaliilor lucrării",
		"isAlreadyTranslated": true
	},
	"Update device details and then validate issues": {
		"text": "Actualizați detaliile dispozitivului și apoi validați problemele",
		"isAlreadyTranslated": true
	},
	"Update device details": {
		"text": "Actualizarea detaliilor dispozitivului",
		"isAlreadyTranslated": true
	},
	"Receive payment & rate consumer": {
		"text": "Primiți plata și evaluați consumatorul",
		"isAlreadyTranslated": true
	},
	"Update invoice details and then press confirm invoice": {
		"text": "Actualizați detaliile facturii și apoi apăsați confirmare factură",
		"isAlreadyTranslated": true
	},
	"Do you want to accept this request?": {
		"text": "Doriți să acceptați această solicitare?",
		"isAlreadyTranslated": true
	},
	"Visit Time": {
		"text": "Timp de vizită",
		"isAlreadyTranslated": true
	},
	"Filter by": {
		"text": "Filtrare după",
		"isAlreadyTranslated": true
	},
	"Partner User": {
		"text": "Utilizator partener",
		"isAlreadyTranslated": true
	},
	"Choose Partner User": {
		"text": "Alegeți Utilizator partener",
		"isAlreadyTranslated": true
	},
	"Invalid email id entered": {
		"text": "ID de e-mail nevalid introdus",
		"isAlreadyTranslated": true
	},
	"No symptoms found": {
		"text": "Nu s-au găsit simptome",
		"isAlreadyTranslated": true
	},
	"No faults found": {
		"text": "Nu s-au găsit defecte",
		"isAlreadyTranslated": true
	},
	"No actions found": {
		"text": "Nu s-au găsit acțiuni",
		"isAlreadyTranslated": true
	},
	"Month": {
		"text": "Lună",
		"isAlreadyTranslated": true
	},
	"Target Amount": {
		"text": "Sumă țintă",
		"isAlreadyTranslated": true
	},
	"Total Target": {
		"text": "Țintă totală",
		"isAlreadyTranslated": true
	},
	"Past targets cannot be edited.": {
		"text": "Țintele anterioare nu pot fi editate.",
		"isAlreadyTranslated": true
	},
	"Already Closed": {
		"text": "Deja închis",
		"isAlreadyTranslated": true
	},
	"No tasks Available": {
		"text": "Nu există activități disponibile",
		"isAlreadyTranslated": true
	},
	"Close Task": {
		"text": "Închideți activitatea",
		"isAlreadyTranslated": true
	},
	"Task Reference Id": {
		"text": "ID de referință a activității",
		"isAlreadyTranslated": true
	},
	"Edit Remark": {
		"text": "Editare observație",
		"isAlreadyTranslated": true
	},
	"Task Details of": {
		"text": "Detalii activitate pentru",
		"isAlreadyTranslated": true
	},
	"Updated Date": {
		"text": "Dată actualizată",
		"isAlreadyTranslated": true
	},
	"Closed Date": {
		"text": "Data închiderii",
		"isAlreadyTranslated": true
	},
	"Temp Consumer data": {
		"text": "Date temporare privind consumatorii",
		"isAlreadyTranslated": true
	},
	"No temp consumer data": {
		"text": "Nu există date temporare privind consumatorii",
		"isAlreadyTranslated": true
	},
	"Repair completed and Inform customer": {
		"text": "Reparație finalizată și informați clientul",
		"isAlreadyTranslated": true
	},
	"Validate IMEI": {
		"text": "Validați IMEI",
		"isAlreadyTranslated": true
	},
	"Invalid IMEI": {
		"text": "IMEI nevalid",
		"isAlreadyTranslated": true
	},
	"Last Diagnosis Report": {
		"text": "Ultimul raport de diagnosticare",
		"isAlreadyTranslated": true
	},
	"No diagnosis report": {
		"text": "Niciun raport de diagnosticare",
		"isAlreadyTranslated": true
	},
	"Validate Device": {
		"text": "Validați dispozitivul",
		"isAlreadyTranslated": true
	},
	"Enter G Number": {
		"text": "Introduceți numărul G",
		"isAlreadyTranslated": true
	},
	"Please enter valid the G number": {
		"text": "Introduceți numărul G valid",
		"isAlreadyTranslated": true
	},
	"No Such Brand": {
		"text": "Nu există o astfel de marcă",
		"isAlreadyTranslated": true
	},
	"Selected Brands": {
		"text": "Mărci selectate",
		"isAlreadyTranslated": true
	},
	"Remaining Brands": {
		"text": "Mărci rămase",
		"isAlreadyTranslated": true
	},
	"Selected Partner": {
		"text": "Partener selectat",
		"isAlreadyTranslated": true
	},
	"No Such Partner": {
		"text": "Nu există un astfel de partener",
		"isAlreadyTranslated": true
	},
	"CHOOSE PARTNER": {
		"text": "ALEGEȚI PARTENERUL",
		"isAlreadyTranslated": true
	},
	"Add Partner": {
		"text": "Adăugați un partener",
		"isAlreadyTranslated": true
	},
	"Remaining Partners": {
		"text": "Parteneri rămași",
		"isAlreadyTranslated": true
	},
	"Add Plan": {
		"text": "Adăugați planul",
		"isAlreadyTranslated": true
	},
	"CHOOSE PLAN": {
		"text": "ALEGEȚI PLANUL",
		"isAlreadyTranslated": true
	},
	"No Such Plan": {
		"text": "Nu există un astfel de plan",
		"isAlreadyTranslated": true
	},
	"Selected Plan": {
		"text": "Plan selectat",
		"isAlreadyTranslated": true
	},
	"Remaining Plan": {
		"text": "Plan rămas",
		"isAlreadyTranslated": true
	},
	"CHOOSE PRODUCT": {
		"text": "ALEGEȚI PRODUSUL",
		"isAlreadyTranslated": true
	},
	"No Such Product Found": {
		"text": "Nu s-a găsit un astfel de produs",
		"isAlreadyTranslated": true
	},
	"Selected Products": {
		"text": "Produse selectate",
		"isAlreadyTranslated": true
	},
	"Remaining Products": {
		"text": "Produse rămase",
		"isAlreadyTranslated": true
	},
	"Remaining Product Sub-Categories": {
		"text": "Subcategorii de produse rămase",
		"isAlreadyTranslated": true
	},
	"Selected Product Sub-Categories": {
		"text": "Subcategorii de produse selectate",
		"isAlreadyTranslated": true
	},
	"No Such Product Sub-Category": {
		"text": "Nu există o astfel de subcategorie de produse",
		"isAlreadyTranslated": true
	},
	"CHOOSE Product Sub-Category": {
		"text": "ALEGEȚI subcategoria de produse",
		"isAlreadyTranslated": true
	},
	"Add Product Sub Category": {
		"text": "Adăugați o subcategorie de produs",
		"isAlreadyTranslated": true
	},
	"Add Service Centers": {
		"text": "Adăugați centre de service",
		"isAlreadyTranslated": true
	},
	"CHOOSE LOCATION": {
		"text": "ALEGEȚI LOCAȚIA",
		"isAlreadyTranslated": true
	},
	"No Such Service Location": {
		"text": "Nu există o astfel de locație a service-ului",
		"isAlreadyTranslated": true
	},
	"Selected Service Centers": {
		"text": "Centre de service selectate",
		"isAlreadyTranslated": true
	},
	"Remaining Service Centers": {
		"text": "Centre de service rămase",
		"isAlreadyTranslated": true
	},
	"Add Service Type": {
		"text": "Adăugați tipul de service",
		"isAlreadyTranslated": true
	},
	"CHOOSE SERVICE TYPE": {
		"text": "ALEGEȚI TIPUL DE SERVICE",
		"isAlreadyTranslated": true
	},
	"No Such Service Type": {
		"text": "Nu există un astfel de tip de service",
		"isAlreadyTranslated": true
	},
	"Selected Service Type": {
		"text": "Tip de service selectat",
		"isAlreadyTranslated": true
	},
	"Remaining Service Types": {
		"text": "Tipuri de service rămase",
		"isAlreadyTranslated": true
	},
	"Add Store": {
		"text": "Adăugați magazin",
		"isAlreadyTranslated": true
	},
	"CHOOSE STORE": {
		"text": "ALEGEȚI MAGAZINUL",
		"isAlreadyTranslated": true
	},
	"No Such Store": {
		"text": "Nu există un astfel de magazin",
		"isAlreadyTranslated": true
	},
	"Selected Store": {
		"text": "Magazin selectat",
		"isAlreadyTranslated": true
	},
	"Remaining Stores": {
		"text": "Magazine rămase",
		"isAlreadyTranslated": true
	},
	"Back to all users": {
		"text": "Înapoi la toți utilizatorii",
		"isAlreadyTranslated": true
	},
	"Save Rights": {
		"text": "Salvați drepturile",
		"isAlreadyTranslated": true
	},
	"This Email Address has already taken, please enter a new email address.": {
		"text": "Această adresă de e-mail a fost deja selectată, vă rugăm să introduceți o nouă adresă de e-mail.",
		"isAlreadyTranslated": true
	},
	"Enter Email Address of the user whose rights you want to add/modify.": {
		"text": "Introduceți adresa de e-mail a utilizatorului ale cărui drepturi doriți să le adăugați/modificați.",
		"isAlreadyTranslated": true
	},
	"Enter Theme Name": {
		"text": "Introduceți numele temei",
		"isAlreadyTranslated": true
	},
	"Add/Update Country Code": {
		"text": "Adăugare/actualizare cod țară",
		"isAlreadyTranslated": true
	},
	"Add/Update Language Code": {
		"text": "Adăugare/actualizare cod limbă",
		"isAlreadyTranslated": true
	},
	"Modules": {
		"text": "Module",
		"isAlreadyTranslated": true
	},
	"Tracking Details": {
		"text": "Detalii de urmărire",
		"isAlreadyTranslated": true
	},
	"Engineer Details": {
		"text": "Detalii Inginer",
		"isAlreadyTranslated": true
	},
	"Number of assigned jobs": {
		"text": "Numărul de lucrări atribuite",
		"isAlreadyTranslated": true
	},
	"Attendance": {
		"text": "Prezență",
		"isAlreadyTranslated": true
	},
	"App Status": {
		"text": "Starea aplicației",
		"isAlreadyTranslated": true
	},
	"Current Latitude": {
		"text": "Latitudine curentă",
		"isAlreadyTranslated": true
	},
	"Current Longitude": {
		"text": "Longitudine curentă",
		"isAlreadyTranslated": true
	},
	"Current Pincode": {
		"text": "Cod PIN actual",
		"isAlreadyTranslated": true
	},
	"too much data to track": {
		"text": "prea multe date de urmărit",
		"isAlreadyTranslated": true
	},
	"No data to track": {
		"text": "Nu există date de urmărit",
		"isAlreadyTranslated": true
	},
	"Tab Type": {
		"text": "Tipul de filă",
		"isAlreadyTranslated": true
	},
	"Tab Title": {
		"text": "Titlul filei",
		"isAlreadyTranslated": true
	},
	"Content Title": {
		"text": "Titlul conținutului",
		"isAlreadyTranslated": true
	},
	"Paragraphs": {
		"text": "Paragrafe",
		"isAlreadyTranslated": true
	},
	"List": {
		"text": "Listă",
		"isAlreadyTranslated": true
	},
	"Add rejection reason": {
		"text": "Adăugați motivul respingerii",
		"isAlreadyTranslated": true
	},
	"Please fill in the rejection reason before rejecting": {
		"text": "Completați motivul respingerii înainte de respingere",
		"isAlreadyTranslated": true
	},
	"Purchased": {
		"text": "Achiziționat",
		"isAlreadyTranslated": true
	},
	"Activated": {
		"text": "Activat",
		"isAlreadyTranslated": true
	},
	"Registered": {
		"text": "Înregistrat",
		"isAlreadyTranslated": true
	},
	"Rejected": {
		"text": "Respins",
		"isAlreadyTranslated": true
	},
	"No such status found": {
		"text": "Nu s-a găsit o astfel de stare",
		"isAlreadyTranslated": true
	},
	"Plan Expiry Date": {
		"text": "Data de expirare a planului",
		"isAlreadyTranslated": true
	},
	"Plan Activation Date": {
		"text": "Data de activare a planului",
		"isAlreadyTranslated": true
	},
	"Report is empty.": {
		"text": "Raportul este gol.",
		"isAlreadyTranslated": true
	},
	"Upload Bulk Plan": {
		"text": "Încărcați planul masiv",
		"isAlreadyTranslated": true
	},
	"Please download the sample file, make changes in it only and upload it": {
		"text": "Descărcați fișierul exemplificativ, faceți modificări doar în el și încărcați-l",
		"isAlreadyTranslated": true
	},
	"File Uploaded Successfully.": {
		"text": "Fișier încărcat cu succes.",
		"isAlreadyTranslated": true
	},
	"File Uploaded Successfully with errors at following Row Number": {
		"text": "Fișier încărcat cu succes cu erori la următorul număr de rânduri",
		"isAlreadyTranslated": true
	},
	"Bulk Plan Sales Upload": {
		"text": "Încărcare vânzări de planuri masive",
		"isAlreadyTranslated": true
	},
	"Uploaded successfully": {
		"text": "Încărcat cu succes",
		"isAlreadyTranslated": true
	},
	"Visit Start Time": {
		"text": "Ora de începere a vizitei",
		"isAlreadyTranslated": true
	},
	"Visit End Time": {
		"text": "Ora de încheiere a vizitei",
		"isAlreadyTranslated": true
	},
	"Engineer Name": {
		"text": "Nume Inginer",
		"isAlreadyTranslated": true
	},
	"Visit Status": {
		"text": "Starea vizitei",
		"isAlreadyTranslated": true
	},
	"Remarks": {
		"text": "Observații",
		"isAlreadyTranslated": true
	},
	"There are no visits for this request.": {
		"text": "Nu există vizite pentru această solicitare.",
		"isAlreadyTranslated": true
	},
	"Appointment Date": {
		"text": "Data programării",
		"isAlreadyTranslated": true
	},
	"Actual Visit Date": {
		"text": "Data reală a vizitei",
		"isAlreadyTranslated": true
	},
	"Appointment Time Slot": {
		"text": "Intervalul de timp al programării",
		"isAlreadyTranslated": true
	},
	"Actual Visit Time": {
		"text": "Ora reală a vizitei",
		"isAlreadyTranslated": true
	},
	"Technician Name": {
		"text": "Numele tehnicianului",
		"isAlreadyTranslated": true
	},
	"Observation": {
		"text": "Observație",
		"isAlreadyTranslated": true
	},
	"Please follow below steps to Update Activation Token for your linked GSX User ID": {
		"text": "Urmați pașii de mai jos pentru a actualiza tokenul de activare pentru ID-ul de utilizator GSX asociat.",
		"isAlreadyTranslated": true
	},
	"Click on the below link to navigate to the Apple Activation Token Site": {
		"text": "Faceți clic pe linkul de mai jos pentru a naviga către site-ul token de activare Apple.",
		"isAlreadyTranslated": true
	},
	"Use GSX ID and Password to login. Post login, you will be able to view you Activation Token": {
		"text": "Utilizați ID-ul și parola GSX pentru a vă conecta. După autentificare, veți putea vedea tokenul de activare.",
		"isAlreadyTranslated": true
	},
	"Note: Generate the Activation Token for GSX ID mapped to your Servify ID only. GSX ID mapped to your Servify User ID is GSX Email ID": {
		"text": "Notă: Generați tokenul de activare numai pentru ID-ul GSX asociat cu ID-ul dvs. Servify. ID-ul GSX mapat la ID-ului dvs. de utilizator Servify este ID-ul de e-mail GSX",
		"isAlreadyTranslated": true
	},
	"GSX Tech ID Mapped": {
		"text": "ID GSX Tech mapat",
		"isAlreadyTranslated": true
	},
	'Use the "Copy to Clipboard" button to copy the Activation key': {
		"text": "Utilizați butonul „Copiere în clipboard” pentru a copia cheia de activare.",
		"isAlreadyTranslated": true
	},
	'Note: Use the "Copy to Clipboard" button only. Do NOT select manually to copy Activation Token. You are advised NOT to sign out from Apple Activation Token Site before Activation Token is saved in Servify CRM': {
		"text": "Notă: Utilizați numai butonul „Copiere în clipboard”. NU selectați manual pentru a copia tokenul de activare. Sunteți sfătuit să NU vă deconectați de pe site-ul token de activare Apple înainte ca tokenul de activare să fie salvat în Servify CRM",
		"isAlreadyTranslated": true
	},
	"Paste the Activation Token in the text box below & click Save.": {
		"text": "Lipiți tokenul de activare în caseta text de mai jos și faceți clic pe Salvare.",
		"isAlreadyTranslated": true
	},
	"Normal Repair": {
		"text": "Reparație normală",
		"isAlreadyTranslated": true
	},
	"Check whitelisting": {
		"text": "Verificați lista albă",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to receive device.": {
		"text": "Se așteaptă ca Specialistul departamentului de asistență clienți să primească dispozitivul.",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, click on Non Hardware Closure for soft,ware repair or use Provisional Estimate to give a repair estimate to customer.": {
		"text": "Specialistul departamentului de asistență clienți poate rula diagnosticul, inspecta dispozitivul pentru a înregistra o reparație hardware, poate face clic pe Închidere non-hardware pentru o reparație software sau poate utiliza Estimare provizorie pentru a oferi clientului o estimare a reparației.",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, click on Non Hardware Closure for software repair or use Provisional Estimate to give a repair estimate to customer.": {
		"text": "Specialistul departamentului de asistență clienți poate rula diagnosticul, inspecta dispozitivul pentru a înregistra o reparație hardware, poate face clic pe Închidere non-hardware pentru o reparație software sau poate utiliza Estimare provizorie pentru a oferi clientului o estimare a reparației.",
		"isAlreadyTranslated": true
	},
	"Repair has been completed.": {
		"text": "Reparația a fost finalizată.",
		"isAlreadyTranslated": true
	},
	"Service invoice has been generated.": {
		"text": "A fost generată factura de service.",
		"isAlreadyTranslated": true
	},
	"Return Order Number": {
		"text": "Număr de comandă de returnare",
		"isAlreadyTranslated": true
	},
	"Fulfiller Name": {
		"text": "Numele persoanei împuternicite",
		"isAlreadyTranslated": true
	},
	"Inspect Device": {
		"text": "Inspectați dispozitivul",
		"isAlreadyTranslated": true
	},
	"Inbound": {
		"text": "La intrare",
		"isAlreadyTranslated": true
	},
	"Outbound": {
		"text": "La ieșire",
		"isAlreadyTranslated": true
	},
	"Please enter the mandatory field.": {
		"text": "Introduceți câmpul obligatoriu.",
		"isAlreadyTranslated": true
	},
	"This is a mandatory field": {
		"text": "Acesta este un câmp obligatoriu",
		"isAlreadyTranslated": true
	},
	"Please enter steps in less than 1500 characters": {
		"text": "Introduceți pașii în mai puțin de 1.500 de caractere",
		"isAlreadyTranslated": true
	},
	"Please enter notes in less than 1000 characters": {
		"text": "Introduceți note în mai puțin de 1.000 de caractere",
		"isAlreadyTranslated": true
	},
	"/1500 characters entered.": {
		"text": "/1.500 de caractere introduse.",
		"isAlreadyTranslated": true
	},
	"/500 characters entered.": {
		"text": "/500 de caractere introduse.",
		"isAlreadyTranslated": true
	},
	"/1000 characters entered.": {
		"text": "/1.000 de caractere introduse.",
		"isAlreadyTranslated": true
	},
	'Waiting for Engineer to "Validate Issues" and "Add Faults" in "Issues Reported" section below.': {
		"text": "Se așteaptă ca Inginerul „să valideze problemele” și „să adauge defecte” în secțiunea „Probleme raportate” de mai jos.",
		"isAlreadyTranslated": true
	},
	'Waiting for Engineer to "Select Symptom And Action" in "Report Issues to GSX" section below.': {
		"text": "Se așteaptă ca Inginerul să „Selecteze simptomul și acțiunea” în secțiunea „Raportați problemele la GSX” de mai jos.",
		"isAlreadyTranslated": true
	},
	"Please select GSX repair type": {
		"text": "Selectați tipul de reparație GSX",
		"isAlreadyTranslated": true
	},
	"Proceed with creating GSX repair": {
		"text": "Continuați cu crearea reparației GSX",
		"isAlreadyTranslated": true
	},
	"Please add part to Proceed with creating GSX repair": {
		"text": "Adăugați o piesă pentru a trece la crearea reparației GSX",
		"isAlreadyTranslated": true
	},
	"Waiting for Engineer to create GSX repair": {
		"text": "Se așteaptă ca Inginerul să creeze reparația GSX",
		"isAlreadyTranslated": true
	},
	"Component Code": {
		"text": "Codul componentei",
		"isAlreadyTranslated": true
	},
	"Pricing Option": {
		"text": "Opțiunea de stabilire a prețurilor",
		"isAlreadyTranslated": true
	},
	"Coverage Option": {
		"text": "Opțiunea de acoperire",
		"isAlreadyTranslated": true
	},
	"Please select a COMPTIA group from dropdown.": {
		"text": "Selectați un grup COMPTIA din lista verticală.",
		"isAlreadyTranslated": true
	},
	"Please select a COMPTIA Code from dropdown.": {
		"text": "Selectați un cod COMPTIA din lista verticală.",
		"isAlreadyTranslated": true
	},
	"Please select a Component Code from dropdown.": {
		"text": "Selectați un cod de componentă din lista verticală.",
		"isAlreadyTranslated": true
	},
	"Please select a Issue Code from dropdown.": {
		"text": "Selectați un cod de problemă din lista verticală.",
		"isAlreadyTranslated": true
	},
	"Please enter the serial number of the part": {
		"text": "Introduceți numărul de serie al piesei",
		"isAlreadyTranslated": true
	},
	"Please enter correct KBB serial number of the part": {
		"text": "Introduceți numărul de serie KBB corect al piesei",
		"isAlreadyTranslated": true
	},
	"Please select a Covergage Option Code from dropdown.": {
		"text": "Selectați un cod de opțiune de acoperire din lista verticală.",
		"isAlreadyTranslated": true
	},
	"Please select a Coverage Option Code from dropdown.": {
		"text": "Selectați un cod de opțiune de acoperire din lista verticală.",
		"isAlreadyTranslated": true
	},
	"Serial Number is already added": {
		"text": "Numărul de serie este deja adăugat",
		"isAlreadyTranslated": true
	},
	"Charge": {
		"text": "Cheltuială",
		"isAlreadyTranslated": true
	},
	"Charge Name": {
		"text": "Denumire cheltuială",
		"isAlreadyTranslated": true
	},
	"Charge Value": {
		"text": "Valoare cheltuială",
		"isAlreadyTranslated": true
	},
	"Tax": {
		"text": "Taxă",
		"isAlreadyTranslated": true
	},
	"MRP": {
		"text": "Preț maxim de retail",
		"isAlreadyTranslated": true
	},
	"Final Price": {
		"text": "Preț final",
		"isAlreadyTranslated": true
	},
	"Add Discount": {
		"text": "Adăugați reducerea",
		"isAlreadyTranslated": true
	},
	"Good Part Serial No": {
		"text": "Numărul de serie al piesei bune",
		"isAlreadyTranslated": true
	},
	"Bad Part Serial No": {
		"text": "Număr de serie al părții defecte",
		"isAlreadyTranslated": true
	},
	"Create GSX Repair": {
		"text": "Creați reparație GSX",
		"isAlreadyTranslated": true
	},
	"Fetch Parts From GSX": {
		"text": "Preluați piese de la GSX",
		"isAlreadyTranslated": true
	},
	"Fetch Latest Diagnostics Results": {
		"text": "Preluați cele mai recente rezultate ale diagnosticării",
		"isAlreadyTranslated": true
	},
	"If you want to update Repair Type. Please remove parts.": {
		"text": "Dacă doriți să actualizați tipul de reparație. Îndepărtați piesele.",
		"isAlreadyTranslated": true
	},
	"Requested": {
		"text": "Solicitat",
		"isAlreadyTranslated": true
	},
	"customerCare": {
		"text": "customerCare",
		"isAlreadyTranslated": true
	},
	"CustomerCare": {
		"text": "CustomerCare",
		"isAlreadyTranslated": true
	},
	"View Delivery challan": {
		"text": "Vizualizare challan de livrare",
		"isAlreadyTranslated": true
	},
	"Register for Bulk Return": {
		"text": "Înregistrați-vă pentru retur masiv",
		"isAlreadyTranslated": true
	},
	"View Packing List": {
		"text": "Vizualizare borderou",
		"isAlreadyTranslated": true
	},
	"Dispatch": {
		"text": "Dispecerat",
		"isAlreadyTranslated": true
	},
	"View Depot Shipper Label": {
		"text": "Vizualizare etichetă expeditor depozit",
		"isAlreadyTranslated": true
	},
	"View Return Label": {
		"text": "Vizualizare etichetă de returnare",
		"isAlreadyTranslated": true
	},
	"Carrier": {
		"text": "Transportator",
		"isAlreadyTranslated": true
	},
	"Add Details": {
		"text": "Adăugați detalii",
		"isAlreadyTranslated": true
	},
	"Shipper": {
		"text": "Expeditor",
		"isAlreadyTranslated": true
	},
	"Parts handover note": {
		"text": "Notă de predare a pieselor",
		"isAlreadyTranslated": true
	},
	"Signature": {
		"text": "Semnătură",
		"isAlreadyTranslated": true
	},
	"Dispatch Mail In Devices": {
		"text": "Dispozitive expediate prin poștă la dispecerat",
		"isAlreadyTranslated": true
	},
	"Bulk Return ID": {
		"text": "ID de returnare masivă",
		"isAlreadyTranslated": true
	},
	"Custom Notes": {
		"text": "Note personalizate",
		"isAlreadyTranslated": true
	},
	"Receive device from RC": {
		"text": "Primire dispozitiv de la RC",
		"isAlreadyTranslated": true
	},
	"Quotation document": {
		"text": "Document de ofertă",
		"isAlreadyTranslated": true
	},
	"Estimate Generated By": {
		"text": "Estimare generată de",
		"isAlreadyTranslated": true
	},
	"Sl. No": {
		"text": "Serie Nu",
		"isAlreadyTranslated": true
	},
	"Rate": {
		"text": "Tarif",
		"isAlreadyTranslated": true
	},
	"Taxable value": {
		"text": "Valoare taxabilă",
		"isAlreadyTranslated": true
	},
	"Total Tax": {
		"text": "Taxă totală",
		"isAlreadyTranslated": true
	},
	"Service Estimate Total": {
		"text": "Total estimat service",
		"isAlreadyTranslated": true
	},
	"In Words": {
		"text": "În cuvinte",
		"isAlreadyTranslated": true
	},
	"Disclaimers": {
		"text": "Declarații de exonerare a răspunderii",
		"isAlreadyTranslated": true
	},
	"The charges mentioned in this Estimate are an approximation and is based on the anticipated details of the work to be carried out basis the inspection. It is possible for unexpected situations / complications to cause some deviation from this Estimate. If additional parts or labour are required you will be contacted immediately and upon your concurrence to carry out the details of the work, a new Estimate will be created for your consideration and approval.": {
		"text": "Taxele menționate în această estimare sunt aproximative și se bazează pe detaliile anticipate ale lucrărilor care urmează să fie efectuate pe baza inspecției. Este posibil ca situații neașteptate/complicații să provoace unele abateri de la această estimare. În cazul în care sunt necesare piese sau manoperă suplimentare, veți fi contactat imediat și, după ce veți fi de acord cu efectuarea detaliilor lucrării, se va crea o nouă estimare pentru a fi luată în considerare și aprobată de dvs.",
		"isAlreadyTranslated": true
	},
	"Authorised Signatory": {
		"text": "Semnatarul autorizat",
		"isAlreadyTranslated": true
	},
	"Consumed": {
		"text": "Consumat",
		"isAlreadyTranslated": true
	},
	"DOA": {
		"text": "Stare nefuncțională la primire",
		"isAlreadyTranslated": true
	},
	"Receive": {
		"text": "Primire",
		"isAlreadyTranslated": true
	},
	"Return": {
		"text": "Returnare",
		"isAlreadyTranslated": true
	},
	"Return to Customer": {
		"text": "Returnare la client",
		"isAlreadyTranslated": true
	},
	"Repair completed and Intimate customer": {
		"text": "Reparație finalizată și client informat",
		"isAlreadyTranslated": true
	},
	"QC rejected, Work in progress": {
		"text": "QC respins, lucrare în curs de desfășurare",
		"isAlreadyTranslated": true
	},
	"Repair completed, Inform customer": {
		"text": "Reparație finalizată, informarea clientului",
		"isAlreadyTranslated": true
	},
	"Inspection in Progress": {
		"text": "Inspecție în curs de desfășurare",
		"isAlreadyTranslated": true
	},
	"Unused": {
		"text": "Nefolosit",
		"isAlreadyTranslated": true
	},
	"Please select Tier part": {
		"text": "Selectați piesa de nivel",
		"isAlreadyTranslated": true
	},
	"Initiate AST 2 Diagnostics": {
		"text": "Inițierea diagnosticului AST 2",
		"isAlreadyTranslated": true
	},
	"Please add document": {
		"text": "Adăugați documentul",
		"isAlreadyTranslated": true
	},
	"Non Hardware Closure": {
		"text": "Închidere non-hardware",
		"isAlreadyTranslated": true
	},
	"Select Repair Outcomes": {
		"text": "Selectați rezultatele reparațiilor",
		"isAlreadyTranslated": true
	},
	"User can select upto 4 outcomes": {
		"text": "Utilizatorul poate selecta până la 4 rezultate",
		"isAlreadyTranslated": true
	},
	"Create NTF Repair": {
		"text": "Creați reparație NTF",
		"isAlreadyTranslated": true
	},
	"Delivery Notes": {
		"text": "Note de livrare",
		"isAlreadyTranslated": true
	},
	"Notes will be displayed on Customer’s Delivery Report": {
		"text": "Notele vor fi afișate în Raportul de livrare al clientului.",
		"isAlreadyTranslated": true
	},
	"Cancel": {
		"text": "Anulare",
		"isAlreadyTranslated": true
	},
	"Save": {
		"text": "Salvare",
		"isAlreadyTranslated": true
	},
	"Provisional Estimate": {
		"text": "Estimare provizorie",
		"isAlreadyTranslated": true
	},
	"Price": {
		"text": "Preț",
		"isAlreadyTranslated": true
	},
	"Total Provisional Estimate": {
		"text": "Total estimare provizorie",
		"isAlreadyTranslated": true
	},
	"Send Email to Customer": {
		"text": "Trimiteți un e-mail către client",
		"isAlreadyTranslated": true
	},
	"Add New Billing Detail": {
		"text": "Adăugați un nou detaliu de facturare",
		"isAlreadyTranslated": true
	},
	"No Billing Detail found": {
		"text": "Nu s-au găsit detalii de facturare",
		"isAlreadyTranslated": true
	},
	"Select Billing Detail": {
		"text": "Selectați detaliile de facturare",
		"isAlreadyTranslated": true
	},
	"Personal": {
		"text": "Personal",
		"isAlreadyTranslated": true
	},
	"Company": {
		"text": "Companie",
		"isAlreadyTranslated": true
	},
	"Registered Name": {
		"text": "Nume înregistrat",
		"isAlreadyTranslated": true
	},
	"Company Name": {
		"text": "Numele companiei",
		"isAlreadyTranslated": true
	},
	"Why Generate Activation token": {
		"text": "De ce să generați un token de activare",
		"isAlreadyTranslated": true
	},
	"Activation Token is adds an additional layer of security while fetching information from GSX. This additional parameter of security has been added by Apple for accessing information using API. For more information, you can get in touch with Servify Administrator.": {
		"text": "Tokenul de activare adaugă un nivel suplimentar de securitate în timp ce se obțin informații de la GSX. Acest parametru suplimentar de securitate a fost adăugat de Apple pentru accesarea informațiilor prin intermediul API. Pentru mai multe informații, puteți lua legătura cu administratorul Servify.",
		"isAlreadyTranslated": true
	},
	"Repair Type Description": {
		"text": "Descriere tip de reparație",
		"isAlreadyTranslated": true
	},
	"Non hardware closure details updated": {
		"text": "Detalii de închidere non-hardware actualizate",
		"isAlreadyTranslated": true
	},
	"Provisional estimate updated": {
		"text": "Estimare provizorie actualizată",
		"isAlreadyTranslated": true
	},
	"Provisional estimate generated": {
		"text": "Estimare provizorie generată",
		"isAlreadyTranslated": true
	},
	"Device inspected": {
		"text": "Dispozitiv inspectat",
		"isAlreadyTranslated": true
	},
	"Comptia Modifier": {
		"text": "Modificator Comptia",
		"isAlreadyTranslated": true
	},
	"Enter File Name": {
		"text": "Introduceți numele fișierului",
		"isAlreadyTranslated": true
	},
	"Run Diagnosis": {
		"text": "Rulați diagnosticul",
		"isAlreadyTranslated": true
	},
	"Skip diagnosis": {
		"text": "Omiteți diagnosticul",
		"isAlreadyTranslated": true
	},
	"Inspect Device": {
		"text": "Inspectați dispozitivul",
		"isAlreadyTranslated": true
	},
	"Battery usage": {
		"text": "Utilizarea bateriei",
		"isAlreadyTranslated": true
	},
	"Call performance": {
		"text": "Performanța apelurilor",
		"isAlreadyTranslated": true
	},
	"Mobile resource Operator": {
		"text": "Operator de resurse mobile",
		"isAlreadyTranslated": true
	},
	"Loaner diagnostics": {
		"text": "Diagnosticare dispozitiv de împrumut",
		"isAlreadyTranslated": true
	},
	"Alternative Mobile No": {
		"text": "Nr. de telefon mobil alternativ",
		"isAlreadyTranslated": true
	},
	"Logistics Report": {
		"text": "Raport de logistică",
		"isAlreadyTranslated": true
	},
	"Select Report Type": {
		"text": "Selectați tipul de raport",
		"isAlreadyTranslated": true
	},
	"Filter period": {
		"text": "Filtrare perioadă",
		"isAlreadyTranslated": true
	},
	"Service": {
		"text": "Service",
		"isAlreadyTranslated": true
	},
	"Download report": {
		"text": "Descărcați raportul",
		"isAlreadyTranslated": true
	},
	"Parts": {
		"text": "Piese",
		"isAlreadyTranslated": true
	},
	"Select a Type": {
		"text": "Selectați un tip",
		"isAlreadyTranslated": true
	},
	"Filter Report": {
		"text": "Filtrare raport",
		"isAlreadyTranslated": true
	},
	"FILTER PERIOD": {
		"text": "FILTRARE PERIOADĂ",
		"isAlreadyTranslated": true
	},
	"No data found": {
		"text": "Nu s-au găsit date",
		"isAlreadyTranslated": true
	},
	"Apple plans": {
		"text": "Planurile Apple",
		"isAlreadyTranslated": true
	},
	"Know more": {
		"text": "Mai mult",
		"isAlreadyTranslated": true
	},
	"Failure": {
		"text": "Eșec",
		"isAlreadyTranslated": true
	},
	"Pending": {
		"text": "În așteptare",
		"isAlreadyTranslated": true
	},
	"Sell": {
		"text": "Vindeți",
		"isAlreadyTranslated": true
	},
	"Transaction Status": {
		"text": "Starea tranzacției",
		"isAlreadyTranslated": true
	},
	"Period": {
		"text": "Perioadă",
		"isAlreadyTranslated": true
	},
	"Completed": {
		"text": "Finalizat",
		"isAlreadyTranslated": true
	},
	"completed": {
		"text": "finalizat",
		"isAlreadyTranslated": true
	},
	"Filter": {
		"text": "Filtru",
		"isAlreadyTranslated": true
	},
	"Total Count": {
		"text": "Număr total",
		"isAlreadyTranslated": true
	},
	"Total Value": {
		"text": "Valoare totală",
		"isAlreadyTranslated": true
	},
	"Failed": {
		"text": "Eșuat",
		"isAlreadyTranslated": true
	},
	"Brands": {
		"text": "Mărci",
		"isAlreadyTranslated": true
	},
	"Products": {
		"text": "Produse",
		"isAlreadyTranslated": true
	},
	"Task Type": {
		"text": "Tip de activitate",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer assignment": {
		"text": "Se așteaptă atribuirea Inginerului",
		"isAlreadyTranslated": true
	},
	"Provisional Charges Added/Updated": {
		"text": "Taxe provizorii adăugate/actualizate",
		"isAlreadyTranslated": true
	},
	"Part Provisional Charges Removed": {
		"text": "Taxe provizorii pentru piese eliminate",
		"isAlreadyTranslated": true
	},
	"Device handed over by CustomerCare to ServicelocationEngineer": {
		"text": "Dispozitiv predat de CustomerCare către ServicelocationEngineer",
		"isAlreadyTranslated": true
	},
	"Device accepted by ServicelocationEngineer from CustomerCare": {
		"text": "Dispozitiv acceptat de ServicelocationEngineer de la CustomerCare",
		"isAlreadyTranslated": true
	},
	"Select GSX Repair Type": {
		"text": "Selectați tipul de reparație GSX",
		"isAlreadyTranslated": true
	},
	"Remove": {
		"text": "Eliminare",
		"isAlreadyTranslated": true
	},
	"View Provisional Estimate Document": {
		"text": "Vizualizați documentul de estimare provizorie",
		"isAlreadyTranslated": true
	},
	"View Estimation Document": {
		"text": "Vizualizare documentul de estimare",
		"isAlreadyTranslated": true
	},
	"Please Fill Payment Mode Details.": {
		"text": "Completați detaliile privind metoda de plată.",
		"isAlreadyTranslated": true
	},
	"Please Enter Amount.": {
		"text": "Introduceți suma.",
		"isAlreadyTranslated": true
	},
	"Additional COMPTIA codes": {
		"text": "Coduri COMPTIA suplimentare",
		"isAlreadyTranslated": true
	},
	"Please enter correct": {
		"text": "Introduceți datele corecte ",
		"isAlreadyTranslated": true
	},
	"Please Enter Correct": {
		"text": "Introduceți datele corecte ",
		"isAlreadyTranslated": true
	},
	"Out of warranty": {
		"text": "În afara garanției",
		"isAlreadyTranslated": true
	},
	"Advance Collected": {
		"text": "Avans colectat",
		"isAlreadyTranslated": true
	},
	"Enter Serial number": {
		"text": "Introduceți numărul de serie",
		"isAlreadyTranslated": true
	},
	"Enter the Serial Number": {
		"text": "Introduceți numărul de serie",
		"isAlreadyTranslated": true
	},
	"Enter the IMEI/Serial Number": {
		"text": "Introduceți numărul IMEI/numărul de serie",
		"isAlreadyTranslated": true
	},
	"Reject GSX Repair": {
		"text": "Respingeți reparația GSX",
		"isAlreadyTranslated": true
	},
	"Inventory manager": {
		"text": "Manager de inventar",
		"isAlreadyTranslated": true
	},
	"Ordered": {
		"text": "Comandat",
		"isAlreadyTranslated": true
	},
	"Issued": {
		"text": "Emis",
		"isAlreadyTranslated": true
	},
	"Received": {
		"text": "Primit",
		"isAlreadyTranslated": true
	},
	"Consumed": {
		"text": "Consumat",
		"isAlreadyTranslated": true
	},
	"Defective": {
		"text": "Defect",
		"isAlreadyTranslated": true
	},
	"Handover": {
		"text": "Predare",
		"isAlreadyTranslated": true
	},
	"handover": {
		"text": "predare",
		"isAlreadyTranslated": true
	},
	"ordered": {
		"text": "comandat",
		"isAlreadyTranslated": true
	},
	"issued": {
		"text": "emis",
		"isAlreadyTranslated": true
	},
	"received": {
		"text": "primit",
		"isAlreadyTranslated": true
	},
	"consumed": {
		"text": "consumat",
		"isAlreadyTranslated": true
	},
	"defective": {
		"text": "defect",
		"isAlreadyTranslated": true
	},
	"View Delivery Report": {
		"text": "Vizualizați raportul de livrare",
		"isAlreadyTranslated": true
	},
	"Warranty Validated from Brand": {
		"text": "Garanție validată de marcă",
		"isAlreadyTranslated": true
	},
	"Please enter the reason for warranty applicable": {
		"text": "Introduceți motivul pentru care se aplică garanția",
		"isAlreadyTranslated": true
	},
	"Purchase cost can not be zero": {
		"text": "Costul de achiziție nu poate fi zero",
		"isAlreadyTranslated": true
	},
	"Please add tag": {
		"text": "Adăugați eticheta",
		"isAlreadyTranslated": true
	},
	"File size should not be greater than 10 MB": {
		"text": "Dimensiunea fișierului nu trebuie să fie mai mare de 10 MB",
		"isAlreadyTranslated": true
	},
	"Please enter values in all the fields": {
		"text": "Introduceți valori în toate câmpurile",
		"isAlreadyTranslated": true
	},
	"Full box unit cannot be added if another part is already added": {
		"text": "Unitatea cutiei completă nu poate fi adăugată dacă o altă piesă este deja adăugată",
		"isAlreadyTranslated": true
	},
	"Full box unit is already added.": {
		"text": "Unitatea cutiei completă este deja adăugată.",
		"isAlreadyTranslated": true
	},
	"Part price is not updated for the Selected part": {
		"text": "Prețul piesei nu este actualizat pentru piesa selectată",
		"isAlreadyTranslated": true
	},
	"Please contact Admin": {
		"text": "Contactați Administratorul",
		"isAlreadyTranslated": true
	},
	"Repair Completion Type": {
		"text": "Tipul de finalizare a reparației",
		"isAlreadyTranslated": true
	},
	"No Parts Requested": {
		"text": "Nu sunt solicitate piese",
		"isAlreadyTranslated": true
	},
	"NA": {
		"text": "NA",
		"isAlreadyTranslated": true
	},
	"Out Of Warranty (No Coverage)": {
		"text": "În afara garanției (fără acoperire)",
		"isAlreadyTranslated": true
	},
	"Number": {
		"text": "Număr",
		"isAlreadyTranslated": true
	},
	"Workshop Engineer": {
		"text": "Inginer atelier",
		"isAlreadyTranslated": true
	},
	"Front Image": {
		"text": "Imagine din față",
		"isAlreadyTranslated": true
	},
	"Top Image": {
		"text": "Imagine de sus",
		"isAlreadyTranslated": true
	},
	"Bottom Image": {
		"text": "Imagine de jos",
		"isAlreadyTranslated": true
	},
	"Left Image": {
		"text": "Imagine din stânga",
		"isAlreadyTranslated": true
	},
	"Right Image": {
		"text": "Imagine din dreapta",
		"isAlreadyTranslated": true
	},
	"Device Invoice": {
		"text": "Factură dispozitiv",
		"isAlreadyTranslated": true
	},
	"Other": {
		"text": "Altele",
		"isAlreadyTranslated": true
	},
	"Cannot add more than 4 outcomes": {
		"text": "Nu se pot adăuga mai mult de 4 rezultate",
		"isAlreadyTranslated": true
	},
	"Imei Number": {
		"text": "Număr IMEI",
		"isAlreadyTranslated": true
	},
	"By": {
		"text": "De către",
		"isAlreadyTranslated": true
	},
	"Whitelist Document": {
		"text": "Document cu listă albă",
		"isAlreadyTranslated": true
	},
	"Hardware Closure": {
		"text": "Finalizare hardware",
		"isAlreadyTranslated": true
	},
	"Map G-Number": {
		"text": "Mapați numărul G",
		"isAlreadyTranslated": true
	},
	"Please enter G Number.": {
		"text": "Introduceți numărul G.",
		"isAlreadyTranslated": true
	},
	"Please enter Device Recieved Date and Time .": {
		"text": "Introduceți data și ora la care dispozitivul a fost recepționat.",
		"isAlreadyTranslated": true
	},
	"Your G Number has been mapped": {
		"text": "Numărul G a fost mapat",
		"isAlreadyTranslated": true
	},
	"Update KGB": {
		"text": "Actualizare KGB",
		"isAlreadyTranslated": true
	},
	"KGB Number": {
		"text": "Numărul KGB",
		"isAlreadyTranslated": true
	},
	"Please enter valid KGB Number": {
		"text": "Introduceți un număr KGB valid",
		"isAlreadyTranslated": true
	},
	"Servicelocation Engineer": {
		"text": "Inginer locație service",
		"isAlreadyTranslated": true
	},
	"StoreManager": {
		"text": "StoreManager",
		"isAlreadyTranslated": true
	},
	"Return without repair (RWR)": {
		"text": "Returnare fără reparație (RWR)",
		"isAlreadyTranslated": true
	},
	"Log Call": {
		"text": "Jurnal de apeluri",
		"isAlreadyTranslated": true
	},
	"Manufacturer's Warranty Status": {
		"text": "Starea garanției producătorului",
		"isAlreadyTranslated": true
	},
	"Device Value": {
		"text": "Valoarea dispozitivului",
		"isAlreadyTranslated": true
	},
	"Is Manufacturer's Warranty Applicable": {
		"text": "Se aplică garanția producătorului?",
		"isAlreadyTranslated": true
	},
	"Connection Disposition": {
		"text": "Dispoziție conexiune",
		"isAlreadyTranslated": true
	},
	"Non-Connect Disposition": {
		"text": "Dispoziție non-conexiune",
		"isAlreadyTranslated": true
	},
	"Mandatory fields Required.": {
		"text": "Câmpuri obligatorii necesare.",
		"isAlreadyTranslated": true
	},
	"Please enter tracking Number.": {
		"text": "Introduceți numărul de urmărire.",
		"isAlreadyTranslated": true
	},
	"Please enter Shipper Detail.": {
		"text": "Introduceți detaliile expeditorului.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Height.": {
		"text": "Introduceți înălțimea cutiei.",
		"isAlreadyTranslated": true
	},
	"Please enter Box width.": {
		"text": "Introduceți lățimea cutiei.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Length.": {
		"text": "Introduceți lungimea cutiei.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Weight.": {
		"text": "Introduceți greutatea cutiei.",
		"isAlreadyTranslated": true
	},
	"Please enter Over Pack Box Number.": {
		"text": "Introduceți numărul de cutie de pe ambalaj.",
		"isAlreadyTranslated": true
	},
	"Carrier": {
		"text": "Transportator",
		"isAlreadyTranslated": true
	},
	"Depot Shipper Label": {
		"text": "Eticheta expeditorului din depozit",
		"isAlreadyTranslated": true
	},
	"Packing List": {
		"text": "Borderou",
		"isAlreadyTranslated": true
	},
	"Delivery Challan": {
		"text": "Challan de livrare",
		"isAlreadyTranslated": true
	},
	"Over Pack Box Number": {
		"text": "Număr de cutie de pe ambalaj",
		"isAlreadyTranslated": true
	},
	"Add Carrier Details": {
		"text": "Adăugați detalii despre transportator",
		"isAlreadyTranslated": true
	},
	"GSX Confirmation No": {
		"text": "Nr. confirmare GSX",
		"isAlreadyTranslated": true
	},
	"Repair Creation Date": {
		"text": "Data creării reparației",
		"isAlreadyTranslated": true
	},
	"Please enter valid": {
		"text": "Introduceți date valide",
		"isAlreadyTranslated": true
	},
	"Please select Payment Mode.": {
		"text": "Selectați metoda de plată.",
		"isAlreadyTranslated": true
	},
	"Inspection Guide": {
		"text": "Ghid de inspecție",
		"isAlreadyTranslated": true
	},
	"Guide": {
		"text": "Ghid",
		"isAlreadyTranslated": true
	},
	"Please make sure FMIP is switched off.": {
		"text": "Asigurați-vă că FMIP este dezactivat.",
		"isAlreadyTranslated": true
	},
	"If you wish to skip FMIP click on YES else ask customer to Turn off FMIP and try again.": {
		"text": "Dacă doriți să omiteți FMIP, faceți clic pe DA sau cereți clientului să dezactiveze FMIP și încercați din nou.",
		"isAlreadyTranslated": true
	},
	"Instructions": {
		"text": "Instrucțiuni",
		"isAlreadyTranslated": true
	},
	"Start at your Home screen.": {
		"text": "Începeți din ecranul de pornire.",
		"isAlreadyTranslated": true
	},
	"Tap Settings > iCloud.": {
		"text": "Apăsați pe Setări > iCloud.",
		"isAlreadyTranslated": true
	},
	"Scroll to the bottom and tap Find My iPhone.": {
		"text": "Derulați până în partea de jos și atingeți Găsește-mi iPhone-ul.",
		"isAlreadyTranslated": true
	},
	"Slide to turn off Find My iPhone and Send Last Location.": {
		"text": "Glisați pentru a dezactiva Găsește-mi iPhone-ul meu și Trimite ultima locație.",
		"isAlreadyTranslated": true
	},
	"Retry": {
		"text": "Reîncercare",
		"isAlreadyTranslated": true
	},
	"Please update Warranty applicable status in Device Details section.": {
		"text": "Actualizați starea Garanție aplicabilă în secțiunea Detalii dispozitiv.",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, Please update Warranty applicable status in Device Details section to enable Non Hardware Closure.": {
		"text": "Specialistul departamentului de asistență clienți poate rula diagnosticul, inspecta dispozitivul pentru a înregistra o reparație hardware; actualizați starea garanției aplicabile în secțiunea Detalii dispozitiv pentru a permite Finalizarea non-hardware.",
		"isAlreadyTranslated": true
	},
	"Please select file name, or choose 'Others' to enter a custom file name.": {
		"text": "Selectați un nume de fișier sau alegeți „Altele” pentru a introduce un nume de fișier personalizat.",
		"isAlreadyTranslated": true
	},
	"Assigned Engineers": {
		"text": "Inginerii atribuiți",
		"isAlreadyTranslated": true
	},
	"Select File Name": {
		"text": "Selectați numele fișierului",
		"isAlreadyTranslated": true
	},
	"Review By Apple Requested": {
		"text": "Recenzie efectuată de Apple solicitată",
		"isAlreadyTranslated": true
	},
	"CS Code Entered": {
		"text": "Cod CS introdus",
		"isAlreadyTranslated": true
	},
	"Repair Classification Type": {
		"text": "Tip de clasificare a reparațiilor",
		"isAlreadyTranslated": true
	},
	"Guided Troubleshooting Details": {
		"text": "Detalii de depanare ghidată",
		"isAlreadyTranslated": true
	},
	"Please enter Required Fields (*).": {
		"text": "Introduceți câmpurile obligatorii (*).",
		"isAlreadyTranslated": true
	},
	"No Guided Troubleshooting Details": {
		"text": "Nu există detalii de depanare ghidată",
		"isAlreadyTranslated": true
	},
	"Repair Notes (for Delivery Report)": {
		"text": "Note de reparație (pentru raportul de livrare)",
		"isAlreadyTranslated": true
	},
	"Repair Notes": {
		"text": "Note de reparație",
		"isAlreadyTranslated": true
	},
	"Please enter the Repair Notes": {
		"text": "Introduceți notele de reparație",
		"isAlreadyTranslated": true
	},
	"Same as Engineer Diagnosis Notes": {
		"text": "Aceleași Note de diagnosticare ca și în cazul Inginerului",
		"isAlreadyTranslated": true
	},
	"In case you want to select different outcomes for both parts, please select outcome for one part and Save the same. Post that please update the outcome for the remaining parts. For additional help, contact your Servify administrator.": {
		"text": "În cazul în care doriți să selectați rezultate diferite pentru ambele piese, selectați rezultatul pentru una dintre piese și salvați-l. Apoi, actualizați rezultatul pentru piesele rămase. Pentru ajutor suplimentar, contactați administratorul Servify.",
		"isAlreadyTranslated": true
	},
	"Enter KBB Details": {
		"text": "Introduceți detaliile KBB",
		"isAlreadyTranslated": true
	},
	"KBB Number": {
		"text": "Numărul KBB",
		"isAlreadyTranslated": true
	},
	"Reorder Details": {
		"text": "Reordonați detaliile",
		"isAlreadyTranslated": true
	},
	"Reorder Part Number": {
		"text": "Reordonați numărul de piesă",
		"isAlreadyTranslated": true
	},
	"Reorder Part as": {
		"text": "Reordonați piesa ca",
		"isAlreadyTranslated": true
	},
	"Purchase Order": {
		"text": "Ordin de achiziție",
		"isAlreadyTranslated": true
	},
	"Consignment Order": {
		"text": "Comandă de transport",
		"isAlreadyTranslated": true
	},
	"GSX Confirmation Number": {
		"text": "Număr de confirmare GSX",
		"isAlreadyTranslated": true
	},
	"Repair created successfully on GSX": {
		"text": "Reparație creată cu succes în GSX",
		"isAlreadyTranslated": true
	},
	"Repair Status At GSX": {
		"text": "Starea reparațiilor la GSX",
		"isAlreadyTranslated": true
	},
	"Use from Consigned Inventory": {
		"text": "Utilizare din inventarul transportat",
		"isAlreadyTranslated": true
	},
	"KGB Serial Number": {
		"text": "Numărul de serie KGB",
		"isAlreadyTranslated": true
	},
	"KGB Serial Number (Consignment Part Serial No)": {
		"text": "Numărul de serie KGB (numărul de serie al piesei de transport)",
		"isAlreadyTranslated": true
	},
	"Fetch GSX Status": {
		"text": "Preluarea stării GSX",
		"isAlreadyTranslated": true
	},
	"Use Consignment Part": {
		"text": "Utilizați piesa de transport",
		"isAlreadyTranslated": true
	},
	"Enter DOA Parts Details": {
		"text": "Introduceți detaliile pieselor în stare nefuncțională la primire",
		"isAlreadyTranslated": true
	},
	"Raise GSX Request": {
		"text": "Formulați solicitarea GSX",
		"isAlreadyTranslated": true
	},
	"We are unable to establish complete device details from GSX. Diagnosis may not have been performed on this device. Please proceed with request creation by selecting product details.": {
		"text": "Nu suntem în măsură să stabilim detaliile complete ale dispozitivului de la GSX. Este posibil ca diagnosticul să nu fi fost efectuat pe acest dispozitiv. Continuați cu crearea solicitării prin selectarea detaliilor produsului.",
		"isAlreadyTranslated": true
	},
	"We are unable to establish complete device details from GSX. Diagnosis may not have been performed on this device. Please diagnose the device to proceed. Note: Excessive tries without device diagnosis can block your account access.": {
		"text": "Nu suntem în măsură să stabilim detaliile complete ale dispozitivului de la GSX. Este posibil ca diagnosticul să nu fi fost efectuat pe acest dispozitiv. Diagnosticați dispozitivul pentru a continua. Notă: Prea multe încercări fără diagnosticarea dispozitivului vă pot bloca accesul la cont.",
		"isAlreadyTranslated": true
	},
	"Notes": {
		"text": "Note",
		"isAlreadyTranslated": true
	},
	"Service Estimate": {
		"text": "Estimare de service",
		"isAlreadyTranslated": true
	},
	"Please make sure “Find My” feature is switched off before accepting device for repair.": {
		"text": "Asigurați-vă că funcția „Găsește-mi” este dezactivată înainte de a accepta dispozitivul pentru reparații.",
		"isAlreadyTranslated": true
	},
	"Disable Find My Functionality": {
		"text": "Dezactivați funcția Găsește-mi",
		"isAlreadyTranslated": true
	},
	"Engineer reassigned": {
		"text": "Inginerul reatribuit",
		"isAlreadyTranslated": true
	},
	"Order from GSX": {
		"text": "Comandă de la GSX",
		"isAlreadyTranslated": true
	},
	"Please select value for ‘Use from Consigned Inventory’ field": {
		"text": "Selectați o valoare pentru câmpul „Utilizare din inventarul transportat”",
		"isAlreadyTranslated": true
	},
	"Return Details": {
		"text": "Detaliile returnării",
		"isAlreadyTranslated": true
	},
	"Return Part as": {
		"text": "Returnați piesa ca",
		"isAlreadyTranslated": true
	},
	"Stock DOA": {
		"text": "Stoc de piese în stare nefuncțională la primire",
		"isAlreadyTranslated": true
	},
	"Please Enter Component Code": {
		"text": "Introduceți codul componentei",
		"isAlreadyTranslated": true
	},
	"Please Enter Issue Code": {
		"text": "Introduceți codul problemei",
		"isAlreadyTranslated": true
	},
	"Please enter comptia Modifier": {
		"text": "Introduceți modificatorul Comptia",
		"isAlreadyTranslated": true
	},
	"Your inventory does not contain stock for the part number selected. Please add consignment stock to your inventory to proceed.": {
		"text": "Inventarul dvs. nu conține stocuri pentru numărul de piesă selectat. Adăugați stocul de transport la inventarul dvs. pentru a continua.",
		"isAlreadyTranslated": true
	},
	"Please select Part Return Status Code": {
		"text": "Selectați codul de stare de returnare a piesei",
		"isAlreadyTranslated": true
	},
	"Box Width": {
		"text": "Lățimea cutiei",
		"isAlreadyTranslated": true
	},
	"Box Height": {
		"text": "Înălțimea cutiei",
		"isAlreadyTranslated": true
	},
	"Box Weight": {
		"text": "Greutatea cutiei",
		"isAlreadyTranslated": true
	},
	"Box Length": {
		"text": "Lungimea cutiei",
		"isAlreadyTranslated": true
	},
	'Waiting for Engineer to Validate Issues and add Symptom, Fault, Action details in "Issues Reported" section below.': {
		"text": "Se așteaptă ca Inginerul să valideze problemele și să adauge detalii despre simptom, defecțiune și acțiune în secțiunea „Probleme raportate” de mai jos.",
		"isAlreadyTranslated": true
	},
	"Primary": {
		"text": "Primar",
		"isAlreadyTranslated": true
	},
	"Reproducibility": {
		"text": "Reproductibilitate",
		"isAlreadyTranslated": true
	},
	"Engineer Reported Component / Issue Code(s)": {
		"text": "Codul (codurile) de componentă/problemă raportate de Inginer",
		"isAlreadyTranslated": true
	},
	"Customer Reported Component / Issue Code(s) [Optional]": {
		"text": "Codul (codurile) componentei/problemei raportate de client [Opțional]",
		"isAlreadyTranslated": true
	},
	"Customer Reported Issues": {
		"text": "Probleme raportate de client",
		"isAlreadyTranslated": true
	},
	"Please select a Reproducibility from dropdown.": {
		"text": "Selectați o reproductibilitate din lista verticală.",
		"isAlreadyTranslated": true
	},
	"Please enter Reproducibility": {
		"text": "Introduceți reproductibilitatea",
		"isAlreadyTranslated": true
	},
	"Waiting for Engineer to Validate Issues and add component code, issue code, action details in 'Issues Reported' section below.": {
		"text": "Se așteaptă ca Inginerul să valideze problemele și să adauge codul componentei, codul problemei, detaliile acțiunii în secțiunea „Probleme raportate” de mai jos.",
		"isAlreadyTranslated": true
	},
	"Please enter a valid name": {
		"text": "Introduceți un nume valid",
		"isAlreadyTranslated": true
	},
	"File Type": {
		"text": "Tip de fișier",
		"isAlreadyTranslated": true
	},
	"Proof of Purchase": {
		"text": "Dovada achiziției",
		"isAlreadyTranslated": true
	},
	"GSX Upload Status": {
		"text": "Starea încărcării GSX",
		"isAlreadyTranslated": true
	},
	"Send to GSX": {
		"text": "Trimiteți la GSX",
		"isAlreadyTranslated": true
	},
	"Please update the device date of purchase in device details section to proceed further.": {
		"text": "Actualizați data de achiziție a dispozitivului în secțiunea de detalii despre dispozitiv pentru a continua.",
		"isAlreadyTranslated": true
	},
	"GSX Serial No (for Non Serialised Product)": {
		"text": "Nr. de serie GSX (pentru produse neserializate)",
		"isAlreadyTranslated": true
	},
	"The file format uploaded in your zip file is not supported. Please remove any folders, correct the file formats and try again.": {
		"text": "Formatul de fișier încărcat în fișierul dvs. zip nu este acceptat. Eliminați toate folderele, corectați formatele de fișiere și încercați din nou.",
		"isAlreadyTranslated": true
	},
	"Uploaded file format not supported. Pls upload file in one of the following formats - aac, csv, doc, docx, jpeg, jpg, m4a, mp4, ogg, ogx, pdf, png, txt, xls, xlsx, zip.": {
		"text": "Formatul fișierului încărcat nu este acceptat. Încărcați fișierul în unul din următoarele formate – aac, csv, doc, docx, jpeg, jpg, m4a, mp4, ogg, ogx, pdf, png, txt, xls, xlsx, zip.",
		"isAlreadyTranslated": true
	},
	"Zip file uploaded has no content. Check and re-upload file.": {
		"text": "Fișierul zip încărcat nu are conținut. Verificați și reîncărcați fișierul.",
		"isAlreadyTranslated": true
	},
	"Uploaded": {
		"text": "Încărcat",
		"isAlreadyTranslated": true
	},
	"Attached to Repair": {
		"text": "Atașat la reparații",
		"isAlreadyTranslated": true
	},
	"Failed Re-Upload": {
		"text": "Reîncărcare eșuată",
		"isAlreadyTranslated": true
	},
	"Device is not eligible for integrated diagnosis, please click on complete diagnosis/cannot diagnose to capture the notes and proceed.": {
		"text": "Dispozitivul nu este eligibil pentru diagnostic integrat; faceți clic pe diagnostic complet/nu se poate diagnostica pentru a salva notele și a continua.",
		"isAlreadyTranslated": true
	},
	"Warning": {
		"text": "Avertisment",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Inspect Device to log a hardware repair, Please update Warranty applicable status in Device Details section to enable Non Hardware Closure.": {
		"text": "Specialistul departamentului de asistență clienți poate inspecta dispozitivul pentru a înregistra o reparație hardware; actualizați starea garanției aplicabile în secțiunea Detalii dispozitiv pentru a permite Finalizarea non-hardware.",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Inspect Device to log a hardware repair, or click on Non Hardware Closure to complete the repair as a non-part service repair.": {
		"text": "Specialistul departamentului de asistență clienți poate inspecta dispozitivul pentru a înregistra o reparație hardware sau face clic pe Închidere non-hardware pentru a finaliza reparația ca reparație service fără piese.",
		"isAlreadyTranslated": true
	},
	"Please update \"Is Manufacturer's Warranty Applicable\" and save device details.": {
		"text": "Actualizați „Se aplică garanția producătorului” și salvați detaliile dispozitivului.",
		"isAlreadyTranslated": true
	},
	"Request Type": {
		"text": "Tip de solicitare",
		"isAlreadyTranslated": true
	},
	"Fetch Diagnostics Suites": {
		"text": "Preluare suite de diagnosticare",
		"isAlreadyTranslated": true
	},
	"Enter Serial Number / IMEI": {
		"text": "Introduceți numărul de serie/IMEI",
		"isAlreadyTranslated": true
	},
	"Sales Channel": {
		"text": "Canalul de vânzări",
		"isAlreadyTranslated": true
	},
	"Street Address": {
		"text": "Stradă",
		"isAlreadyTranslated": true
	},
	"Postal Code": {
		"text": "Cod poștal",
		"isAlreadyTranslated": true
	},
	"Province": {
		"text": "Provincie",
		"isAlreadyTranslated": true
	},
	"Locality": {
		"text": "Localitate",
		"isAlreadyTranslated": true
	},
	"Enter a location": {
		"text": "Introduceți o locație",
		"isAlreadyTranslated": true
	},
	"Landmark is required": {
		"text": "Este necesar un tara",
		"isAlreadyTranslated": true
	},
	"Street Address is required": {
		"text": "Este necesar să se indice strada",
		"isAlreadyTranslated": true
	},
	"Postal Code is required": {
		"text": "Este necesar să se indice codul poștal",
		"isAlreadyTranslated": true
	},
	"Province is required": {
		"text": "Este necesar să se indice provincia",
		"isAlreadyTranslated": true
	},
	"Postal Code is invalid": {
		"text": "Codul poștal nu este valid",
		"isAlreadyTranslated": true
	},
	"Province is incorrect": {
		"text": "Provincia este incorectă",
		"isAlreadyTranslated": true
	},
	"Some of the details might be missing in the address, please update the address.": {
		"text": "Este posibil ca unele detalii să lipsească din adresă, vă rugăm să actualizați adresa.",
		"isAlreadyTranslated": true
	},
	"email id": {
		"text": "ID e-mail",
		"isAlreadyTranslated": true
	},
	"VAT Number": {
		"text": "Cod fiscal",
		"isAlreadyTranslated": true
	},
	"VAT Number is required": {
		"text": "Este necesar să se indice codul fiscal",
		"isAlreadyTranslated": true
	},
	"Should Be 15 Characters": {
		"text": "Ar trebui să conțină 15 caractere",
		"isAlreadyTranslated": true
	},
	"Should Be 10 Characters": {
		"text": "Ar trebui să conțină 10 caractere",
		"isAlreadyTranslated": true
	},
	"Name is required": {
		"text": "Numele este obligatoriu",
		"isAlreadyTranslated": true
	},
	"There's a problem with this location, please select a different location": {
		"text": "Există o problemă legată de această locație, selectați o altă locație",
		"isAlreadyTranslated": true
	},
	"Unable to Diagnose": {
		"text": "Imposibilitate de a diagnostica",
		"isAlreadyTranslated": true
	},
	"Contact Information": {
		"text": "Date de contact",
		"isAlreadyTranslated": true
	},
	"Disclaimer": {
		"text": "Declarație de exonerare a răspunderii",
		"isAlreadyTranslated": true
	},
	"Send OTP": {
		"text": "Trimiteți OTP",
		"isAlreadyTranslated": true
	},
	"OTP Validation": {
		"text": "Validare OTP",
		"isAlreadyTranslated": true
	},
	"Back": {
		"text": "Înapoi",
		"isAlreadyTranslated": true
	},
	"Enter the OTP sent to": {
		"text": "Introduceți OTP-ul trimis la",
		"isAlreadyTranslated": true
	},
	"Confirm OTP": {
		"text": "Confirmați OTP",
		"isAlreadyTranslated": true
	},
	"Estimation rejected": {
		"text": "Estimare respinsă",
		"isAlreadyTranslated": true
	},
	"Mail-In Dispatched Successfully": {
		"text": "Corespondență expediată cu succes",
		"isAlreadyTranslated": true
	},
	"Battery back up issue": {
		"text": "Problemă la bateria de rezervă",
		"isAlreadyTranslated": true
	},
	"Pending for Dispatch": {
		"text": "Expediere în așteptare",
		"isAlreadyTranslated": true
	},
	"Get Bulk Return": {
		"text": "Obțineți returnare masivă",
		"isAlreadyTranslated": true
	},
	"Please enter valid KGB IMEI Number": {
		"text": "Introduceți un număr IMEI KGB valid",
		"isAlreadyTranslated": true
	},
	"KGB IMEI Number": {
		"text": "Număr IMEI KGB",
		"isAlreadyTranslated": true
	},
	"Update": {
		"text": "Actualizare",
		"isAlreadyTranslated": true
	},
	"OverPack No": {
		"text": "Nr. OverPack",
		"isAlreadyTranslated": true
	},
	"Please enter Overpack No. for": {
		"text": "Introduceți numărul de overpack pentru",
		"isAlreadyTranslated": true
	},
	"Please enter Overpack No. for the selected Reference ID": {
		"text": "Introduceți numărul overpack pentru ID-ul de referință selectat",
		"isAlreadyTranslated": true
	},
	"Please enter Overpack No. between 1 to 999": {
		"text": "Introduceți numărul overpack între 1 și 999",
		"isAlreadyTranslated": true
	},
	"Resolution Initiated": {
		"text": "Rezolutie Initiata",
		"isAlreadyTranslated": true
	},
	"Resolution in Progress": {
		"text": "Rezolutie in Progres",
		"isAlreadyTranslated": true
	},
	"Claim Request closed": {
		"text": "Cerere de Dauna Inchisa",
		"isAlreadyTranslated": true
	},
	"Feedback form (after service request completion)": {
		"text": "Formular de feedback (dupa completarea cererii de service)",
		"isAlreadyTranslated": true
	},
	"Below Address will be used by agent based on details added by you, please ensure it is correct before you proceed.": {
		"text": "Adresa de mai jos va fi folosita de agent in baza detaliilor furnizate de dumneavoastra, va rugam sa va asigurati ca informatiile sunt corecte inainte de a continua.",
		"isAlreadyTranslated": true
	},
	"Police Report Date": {
		"text": "Data Raportului de la Politie",
		"isAlreadyTranslated": true
	},
	"Police Report Number": {
		"text": "Numarul Raportului de la Politie",
		"isAlreadyTranslated": true
	},
	"Date of Theft": {
		"text": "Data Furtului",
		"isAlreadyTranslated": true
	},
	"Place of Theft": {
		"text": "Locatia Furtului",
		"isAlreadyTranslated": true
	},
	"Upload Police Report": {
		"text": "Incarcati Raportul Politiei",
		"isAlreadyTranslated": true
	},
	"Incident Details": {
		"text": "Detaliile Incidentului",
		"isAlreadyTranslated": true
	},
	"Claim Request Closed": {
		"text": "Cerere de Dauna Inchisa",
		"isAlreadyTranslated": true
	},
	"Communication Name": {
		"text": "Nume comunicare",
		"isAlreadyTranslated": true
	},
	"Communication Number": {
		"text": "Numărul de comunicare",
		"isAlreadyTranslated": true
	},
	"Communication EmailID": {
		"text": "ID de e-mail de comunicare",
		"isAlreadyTranslated": true
	},
	"DropOff request accepted": {
		"text": "Solicitarea de predare a fost acceptată",
		"isAlreadyTranslated": true
	},
	"Shipment Charge": {
		"text": "Taxa de expediere",
		"isAlreadyTranslated": true
	},
	"Delivery Address": {
		"text": "Adresă de livrare",
		"isAlreadyTranslated": true
	},
	"Additional Remarks": {
		"text": "Remarci aditionale",
		"isAlreadyTranslated": true
	},
	"Update Logistics Request": {
		"text": "Actualizați cererea de logistică",
		"isAlreadyTranslated": true
	},
	"Vendor tracking URL": {
		"text": "Adresa URL de urmărire a furnizorului",
		"isAlreadyTranslated": true
	},
	"Click here for Vendor Tracking URL": {
		"text": "Faceți clic aici pentru adresa URL de urmărire a furnizorului",
		"isAlreadyTranslated": true
	},
	"Contact number": {
		"text": "Numar de contact",
		"isAlreadyTranslated": true
	},
	"Out of Warranty": {
		"text": "garanție terminată",
		"isAlreadyTranslated": true
	},
	"Plan Validity": {
		"text": "Valabilitate Plan",
		"isAlreadyTranslated": true
	},
	"Final Plan Price": {
		"text": "Pret final al planului",
		"isAlreadyTranslated": true
	},
	"Product Value": {
		"text": "Valoare produs",
		"isAlreadyTranslated": true
	},
	"Payment": {
		"text": "de plata",
		"isAlreadyTranslated": true
	},
	"Damage Protection": {
		"text": "Protectie distrugeri",
		"isAlreadyTranslated": true
	},
	"View Plans": {
		"text": "Vizualizare planuri",
		"isAlreadyTranslated": true
	},
	"TOTAL COMMISSION": {
		"text": "Comision Total",
		"isAlreadyTranslated": true
	},
	"Congratulations! Your device is now registered and the plan is now activated for your device.": {
		"text": "Felicitari! Dispozitivul tau este inregistrat si planul este activat pentru dispozitivul tau.",
		"isAlreadyTranslated": true
	},
	"Agreement Number": {
		"text": "Numar contractual",
		"isAlreadyTranslated": true
	},
	"Your Plan is now pending for Activation.": {
		"text": "Planul tau este in asteptare pentru Activare",
		"isAlreadyTranslated": true
	},
	"Customer Email ID": {
		"text": "ID Email Client",
		"isAlreadyTranslated": true
	},
	"Customer Mobile No": {
		"text": "Telefon Mobil Client",
		"isAlreadyTranslated": true
	},
	"Warranty End Date": {
		"text": "Sfarsit perioada de garantie",
		"isAlreadyTranslated": true
	},
	"Product Value cannot be less than 500": {
		"text": "Valoare produsului nu poate fi mai mica de 500",
		"isAlreadyTranslated": true
	},
	"Sell now": {
		"text": "vinde",
		"isAlreadyTranslated": true
	},
	"Know More": {
		"text": "Aflați mai multe",
		"isAlreadyTranslated": true
	},
	"Transferred": {
		"text": "Transferat",
		"isAlreadyTranslated": true
	},
	"Customer Date of Birth": {
		"text": "Data Nasterii a Clientului",
		"isAlreadyTranslated": true
	},
	"Payment Received": {
		"text": "Plata receptionata",
		"isAlreadyTranslated": true
	},
	"Date of Birth": {
		"text": "Data Nasterii",
		"isAlreadyTranslated": true
	},
	"IPID": {
		"text": "IPID",
		"isAlreadyTranslated": true
	},
	"Successfully Purchased": {
		"text": "achizitionat cu succes",
		"isAlreadyTranslated": true
	},
	"The Plan is now pending for Activation": {
		"text": "Planul este in curs de activare",
		"isAlreadyTranslated": true
	},
	" Plans": {
		"text": " Planuri",
		"isAlreadyTranslated": true
	},
	"registered": {
		"text": "inregistrat",
		"isAlreadyTranslated": true
	},
	"Congratulations!\nThe customer has successfully @@status@@ their device under @@plan@@. Please help the customer to activate the plan.": {
		"text": "Felicitari!\nClientul si-a @@status@@ dispozitivul pentru @@plan@@.Va rugam ajutati clientul cu activarea planului.",
		"isAlreadyTranslated": true
	},
	"Privacy Policy": {
		"text": "Politica de confidentialitate",
		"isAlreadyTranslated": true
	},
	"Invalid OTP": {
		"text": "OTP Invalid",
		"isAlreadyTranslated": true
	},
	"Resend OTP": {
		"text": "Retrimitere OTP",
		"isAlreadyTranslated": true
	},
	"One Time Deductible": {
		"text": "Fransiza",
		"isAlreadyTranslated": true
	},
	"Location Of Incident": {
		"text": "Locatia Incidentului",
		"isAlreadyTranslated": true
	},
	"Resend OTP in": {
		"text": "Retrimite OTP in",
		"isAlreadyTranslated": true
	},
	"Declaration": {
		"text": "Declaratie",
		"isAlreadyTranslated": true
	},
	"Please enter Product Value": {
		"text": "Va rugam introduceti Valoare Produs",
		"isAlreadyTranslated": true
	},
	"is required": {
		"text": "este necesar",
		"isAlreadyTranslated": true
	},
    "other related documents": {
        "text": "Informatii despre Asigurator si Intermediarii in Asigurari",
        "isAlreadyTranslated": true
    },
	"Enter Verification Code": {
		"text": "Introdu codul de verificare",
		"isAlreadyTranslated": true
	},
	"Code sent to customer on": {
		"text": "Cod trimis la client in",
		"isAlreadyTranslated": true
	},
	"and": {
		"text": "si",
		"isAlreadyTranslated": true
	},
	"Resend SMS": {
		"text": "Retrimite SMS",
		"isAlreadyTranslated": true
	},
	"Resend Email": {
		"text": "Retrimite Email",
		"isAlreadyTranslated": true
	},
	"Call me with the Code": {
		"text": "Suna-ma cu Codul",
		"isAlreadyTranslated": true
	},
	"Confirm Code": {
		"text": "Confirm Codul",
		"isAlreadyTranslated": true
	},
	"Didn't receive the code? Resend Code in": {
		"text": "Nu ai primit codul? Retrimite in",
		"isAlreadyTranslated": true
	},
	"Select Brand": {
		"text": "Selectați marca",
		"isAlreadyTranslated": true
	},
	"or": {
		"text": "sau",
		"isAlreadyTranslated": true
	},
	"Select payment plan": {
		"text": "Selectați planul de plată",
		"isAlreadyTranslated": true
	},
	"Subscription plan, cancel anytime": {
		"text": "Plan pe baza de abonament, puteți anula oricând",
		"isAlreadyTranslated": true
	},
	"One time payment": {
		"text": "O singură plată",
		"isAlreadyTranslated": true
	},
	"Enter": {
		"text": "Introduceți",
		"isAlreadyTranslated": true
	},
	"Eligible Plan(s)": {
		"text": "Planul (planurile) eligibil(e)",
		"isAlreadyTranslated": true
	},
	"Payment Options": {
		"text": "Opțiuni de plată",
		"isAlreadyTranslated": true
	},
	"Pay Upfront": {
		"text": "Plătiți în avans",
		"isAlreadyTranslated": true
	},
	"Subscription Payment": {
		"text": "Plata pe baza de abonament",
		"isAlreadyTranslated": true
	},
	"The plan purchase is successful. The customer will receive a confirmation email on the registered email ID.": {
		"text": "Achiziționarea planului s-a efectuat cu succes. Clientul va primi un e-mail de confirmare pe ID-ul de e-mail înregistrat.",
		"isAlreadyTranslated": true
	},
	"You will be redirected in": {
		"text": "Veți fi redirecționat în",
		"isAlreadyTranslated": true
	},
	"seconds": {
		"text": "secunde",
		"isAlreadyTranslated": true
	},
	"If you are not redirected": {
		"text": "Dacă nu sunteți redirecționat",
		"isAlreadyTranslated": true
	},
	"Click Here": {
		"text": "Faceți click aici",
		"isAlreadyTranslated": true
	},
	"Your transaction has encountered an error and could not be processed at this time. Please try again later or contact our support team for help.": {
		"text": "Tranzacția dvs. a întâmpinat o eroare și nu a putut fi procesată în acest moment. Vă rugăm să încercați din nou mai târziu sau să contactați echipa noastră de asistență pentru ajutor.",
		"isAlreadyTranslated": true
	},
	"Payment Failed": {
		"text": "Plata a eșuat",
		"isAlreadyTranslated": true
	},

	"Plan Sales": {
        "text": "Vanzari Asigurari",
        "isAlreadyTranslated": true
    },

    "Store Location": {
		"text": "Locația magazinului",
        "isAlreadyTranslated": true
    },
    "Total Sales Value": {
        "text": "Valoarea totală a vânzărilor",
        "isAlreadyTranslated": true
    },
    "Upfront Payment": {
        "text": "Plata în avans",
        "isAlreadyTranslated": true
    },
    "This includes transaction values associated with plan sales and recurring payments post activation": {
		"text": "Aceasta include valorile tranzacțiilor asociate cu vânzările de asigurari și plățile recurente după activare",
        "isAlreadyTranslated": true
    },
	"Total Earnings": {
		"text": "Câștiguri totale",
        "isAlreadyTranslated": true
    },
	"This includes retailer margin values associated with plan sales and recurring payments post activation": {
        "text": "Aceasta include valorile marjei retailer-ului asociate cu vânzările de asigurari și plățile recurente după activare",
        "isAlreadyTranslated": true
    },
	"Number of Sales": {
		"text": "Numărul de vânzări",
        "isAlreadyTranslated": true
    },
	"This includes all plan sales transactions except those contracts where customer has not completed the payment via link": {
        "text": "Aceasta include toate tranzacțiile de vânzări de asigurari, cu excepția contractelor în care clientul nu a finalizat plata prin link",
        "isAlreadyTranslated": true
    },
	"Total Recurring Payments": {
		"text": "Total plăți recurente",
        "isAlreadyTranslated": true
    },

	"This includes all successful recurring transactions associated with subscription plans": {
		"text": "Aceasta include toate tranzacțiile recurente reușite asociate cu asigurarile lunare",
        "isAlreadyTranslated": true
    },
	"Payment Link Transactions": {
		"text": "Link-ul de plata a tranzactiilor",
        "isAlreadyTranslated": true
    },
	"/ Payment Link Transactions": {
		"text": "/ Link-ul de plata a tranzactiilor",
        "isAlreadyTranslated": true
    },
    "Completed": {
        "text": "Completate",
        "isAlreadyTranslated": true
    },
	"Pending": {
		"text": "În așteptare",
        "isAlreadyTranslated": true
    },
	"Failure": {
		"text": "Esuat",
        "isAlreadyTranslated": true
    },

	"This includes all transactions where customer is using a link to complete the payment": {
        "text": "Aceasta include toate tranzacțiile în care clientul utilizează un link pentru a finaliza plata",
        "isAlreadyTranslated": true
    },
	"Plan Sales Summary": {
        "text": "Rezumat vânzări asigurari",
        "isAlreadyTranslated": true
    },
	"Showing Results For": {
		"text": "Afișarea rezultatelor pentru",
        "isAlreadyTranslated": true
    },
	"Today": {
        "text": "Astăzi",
        "isAlreadyTranslated": true
    },
	" Last 7 Days": {
		"text": "Ultimele 7 zile",
        "isAlreadyTranslated": true
    },
	"Last 15 Days": {
		"text": "Ultimele 15 zile",
        "isAlreadyTranslated": true
    },
	"Last 30 Days": {
		"text": "Ultimele 30 de zile",
        "isAlreadyTranslated": true
    },
	"Custom Date": {
		"text": "Data personalizată",
        "isAlreadyTranslated": true
    },
	"View All Transactions": {
        "text": "Vizualizați toate tranzacțiile",
        "isAlreadyTranslated": true
    },
	"View All": {
        "text": "Vizualizați toate",
        "isAlreadyTranslated": true
    },

	"Sell Plan": {
        "text": "Vinde Asigurare",
        "isAlreadyTranslated": true
    },


	"Renew Plan": {
		"text": "Reînnoiește Plan Asigurare",
        "isAlreadyTranslated": true
    },
	"Bulk Upload": {
		"text": "Încărcare bulk",
        "isAlreadyTranslated": true
    },
	"You can only select a range within 30 days": {
		"text": "Puteți selecta doar un interval de 30 de zile",
        "isAlreadyTranslated": true
    },
	"Select Store Location": {
        "text": "Selectați locația magazinului",
        "isAlreadyTranslated": true
    },
	"Location": {
		"text": "Locație",
        "isAlreadyTranslated": true
    },
	"All Transactions": {
		"text": "Toate tranzacțiile",
        "isAlreadyTranslated": true
    },
	"Sorry, No Upfront Payment Transactions Found": {
		"text": "Ne pare rău, nu s-au găsit tranzacții cu plăți în avans",
        "isAlreadyTranslated": true
    },
	"Payment Plan": {
		"text": "Plata Asigurarii",
        "isAlreadyTranslated": true
    },
	"Sorry, No Subscription Payment Transactions Found": {
        "text": "Ne pare rău, nu s-au găsit tranzacții de plată lunara",
        "isAlreadyTranslated": true
    },
	"Payment Link Transactions": {
        "text": "Link-ul de plata a tranzactiilor",
        "isAlreadyTranslated": true
    },

	"Sorry, No Transactions Found": {
		"text": "Ne pare rău, nu s-au găsit tranzacții",
        "isAlreadyTranslated": true
    },
	"Documents": {
        "text": "Documente",
        "isAlreadyTranslated": true
    },
	"Sorry, No Documents Found": {
		"text": "Ne pare rău, nu s-au găsit documente",
        "isAlreadyTranslated": true
    },
	"Are you sure you want to cancel the plan?": {
		"text": "Sunteți sigur că doriți să anulați asigurarea?",
        "isAlreadyTranslated": true
    },
	"Once cancelled, the customer won't be able to redeem any benefits of the plan": {
        "text": "Odată anulata, clientul nu va mai putea răscumpăra niciun beneficiu al asigurarii",
        "isAlreadyTranslated": true
    },
	"Select Reason for Cancellation": {
		"text": "Selectați motivul anulării",
        "isAlreadyTranslated": true
    },
	"Select Reason": {
		"text": "Selectați motivul",
        "isAlreadyTranslated": true
    },
	"Choose a Reason from the dropdown": {
		"text": "Alegeți un motiv din meniul derulant",
        "isAlreadyTranslated": true
    },
	"The plan has been cancelled": {
		"text": "Planul a fost anulat",
        "isAlreadyTranslated": true
    },
	"Make Payment": {
        "text": "Efectuați plata",
        "isAlreadyTranslated": true
    },

	"More Filters": {
		"text": "Mai multe filtre",
        "isAlreadyTranslated": true
    },
	"More": {
		"text": "Mai",
        "isAlreadyTranslated": true
    },
	"Customer Email ID": {
        "text": "ID-ul de e-mail al clientului",
        "isAlreadyTranslated": true
    },
	"Contract ID": {
		"text": "ID contract",
        "isAlreadyTranslated": true
    },
	"Policy ID": {
		"text": "ID-ul poliței",
        "isAlreadyTranslated": true
    },
	"Clear All": {
		"text": "Ștergeți toate",
        "isAlreadyTranslated": true
    },
	"Transaction Date": {
		"text": "Data tranzacției",
        "isAlreadyTranslated": true
    },
	"Transaction Amount": {
		"text": "Suma tranzacției",
        "isAlreadyTranslated": true
    },
	"Contract Status": {
        "text": "Statutul contractului",
        "isAlreadyTranslated": true
    },
	"Enrolled": {
        "text": "Inregistrat",
        "isAlreadyTranslated": true
    },
	"Diagnosed": {
		"text": "Diagnosticat",
        "isAlreadyTranslated": true
    },
	"Upgraded": {
        "text": "Upgradat",
        "isAlreadyTranslated": true
    },
	"On Hold": {
        "text": "În așteptare",
        "isAlreadyTranslated": true
    },
	"Downgraded": {
		"text": "Retrogradat",
        "isAlreadyTranslated": true
    },
	"Expired": {
		"text": "Expirat",
        "isAlreadyTranslated": true
    },
	"Cancellation Initiated": {
		"text": "Anulare inițiată",
        "isAlreadyTranslated": true
    },
	"Refunded": {
		"text": "Rambursată",
        "isAlreadyTranslated": true
    },
	"Benefits Availed": {
		"text": "Beneficii obținute",
        "isAlreadyTranslated": true
    },
	"Bundled": {
        "text": "Bundled",
        "isAlreadyTranslated": true
    },
	"Under Review": {
        "text": "În curs de revizuire",
        "isAlreadyTranslated": true
    },
	"Payment Plan": {
        "text": "Plata Asigurarii",
        "isAlreadyTranslated": true
    },
	"Transaction Completion Date": {
        "text": "Data finalizării tranzacției",
        "isAlreadyTranslated": true
    },
	"Payment Link Status": {
        "text": "Statusul link-ului de plata",
        "isAlreadyTranslated": true
    },
	"Residential State": {
        "text": "Judetul de resedinta",
        "isAlreadyTranslated": true
    },
	"Residential Country": {
		"text": "Țara de reședință",
        "isAlreadyTranslated": true
    },
	"Plan Code": {
        "text": "Codul Asigurarii",
        "isAlreadyTranslated": true
    },
	"Plan Term": {
		"text": "Termen Asigurare",
        "isAlreadyTranslated": true
    },
	"Store Location": {
        "text": "Locația magazinului",
        "isAlreadyTranslated": true
    },
	"Waiting Period": {
		"text": "Perioada de așteptare",
        "isAlreadyTranslated": true
    },
	"Plan Creation Date": {
        "text": "Data creării a asigurarii",
        "isAlreadyTranslated": true
    },
	"Plan Cancellation Initiation Date": {
        "text": "Data de inițiere a anulării asigurarii",
        "isAlreadyTranslated": true
    },

	"Plan Cancellation Date": {
		"text": "Data de anulare a asigurarii",
        "isAlreadyTranslated": true
    },
	"Cancellation Reason Description": {
        "text": "Motivul anulării Descriere",
        "isAlreadyTranslated": true
    },
	"Coverage Details": {
        "text": "Detalii privind acoperirea",
        "isAlreadyTranslated": true
    },
	"Coverage Name": {
		"text": "Numele acoperirii",
        "isAlreadyTranslated": true
    },
	"Coverage Start Date": {
		"text": "Data de începere a acoperirii",
        "isAlreadyTranslated": true
    },
	"Claim Avail Date": {
        "text": "Data de valabilitate a cererii de despăgubire",
        "isAlreadyTranslated": true
    },
	"Coverage End Date": {
		"text": "Data de încheiere a acoperirii",
        "isAlreadyTranslated": true
    },
	"No. of Claims Allowed": {
		"text": "Nr. de cereri de despăgubire admise",
        "isAlreadyTranslated": true
    },
	"Used Claims": {
		"text": "Cereri de despăgubire utilizate",
        "isAlreadyTranslated": true
    },
	"Device Purchase Price": {
		"text": "Prețul de achiziție al dispozitivului",
        "isAlreadyTranslated": true
    },
	"Mode": {
		"text": "Mod",
        "isAlreadyTranslated": true
    },

	"Payment Gateway Transaction ID": {
		"text": "ID-ul de tranzacție al gateway-ului de plată",
        "isAlreadyTranslated": true
    },
	"External Payment ID": {
        "text": "ID extern de plată",
        "isAlreadyTranslated": true
    },
	"Billed Date (UTC)": {
        "text": "Data facturării (UTC)",
        "isAlreadyTranslated": true
    },
	"Billing Cycle Date (UTC)": {
        "text": "Data ciclului de facturare (UTC)",
        "isAlreadyTranslated": true
    },
	"Grace Period End Date": {
        "text": "Data de sfârșit a perioadei de grație",
        "isAlreadyTranslated": true
    },
	"Retailer Margin": {
		"text": "Marginea retailer-ului",
        "isAlreadyTranslated": true
    },
	"Invoice": {
		"text": "Factura",
        "isAlreadyTranslated": true
    },
	"More": {
        "text": "Więcej",
        "isAlreadyTranslated": true
    },
	"Payment Details": {
		"text": "Detalii de plată",
        "isAlreadyTranslated": true
    },
	

	"PLAN DETAILS": {
		"text": "Detalii Planuri",
        "isAlreadyTranslated": true
	},
	"COVERAGE DETAILS": {
		"text": "Detalii Acoperiri",
        "isAlreadyTranslated": true

	},
	"DEVICE DETAILS": {
		"text": "Detalii Dispozitiv",
        "isAlreadyTranslated": true
	},
	"Model": {
		"text": "Model",
        "isAlreadyTranslated": true
	},

	"Customer Mobile Number": {
		"text": "Numar de telefon mobil al clientului",
		"isAlreadyTranslated": true
	},
	"Overview" : {
        "text": "Prezentare generala",
        "isAlreadyTranslated": true
    },
    "Session Expired": {
		"text": "Sesiune expirata",
        "isAlreadyTranslated": true
    },
    "Your session has expired. The store location needs to be selected again, so you will be redirected to the home page shortly.": {
		"text": "Sesiunea dvs. a expirat. Locatia magazinului trebuie selectata din nou, asa ca veti fi redirectionat catre pagina de start in scurt timp.",
        "isAlreadyTranslated": true
    },
	"Transaction ID": {
		"text": "ID-ul tranzacției",
        "isAlreadyTranslated": true
    },
	"Transaction Date (UTC)": {
        "text": "Data tranzacției (UTC)",
        "isAlreadyTranslated": true
    },
	"Pay upfront": {
		"text": "Plata in avans",
		"isAlreadyTranslated": true
	},
	"month": {
		"text": "luna",
		"isAlreadyTranslated": true
	},
	"Maximum": {
		"text": "Maxim",
		"isAlreadyTranslated": true
	},
	"Characters Allowed": {
		"text": "de caractere permise",
		"isAlreadyTranslated": true
	},
	"Full Name": {
		"text": "Nume și prenume",
		"isAlreadyTranslated": true
	},
	"Make payment": {
		"text": "Efectuați plata",
		"isAlreadyTranslated": true
	},
	"Receive Payment": {
		"text": "Receptioneaza plata",
		"isAlreadyTranslated": true
	},
}
export default lang;