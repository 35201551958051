import React, { useEffect, useState } from 'react';
import {Button, Table, Modal, } from 'sfy-react';

import './DiscountModal.scss';

const DiscountModal = (props) => {
	const {
		localString, consumerServiceRequest, closeModal, showModal, discountDisabled, addDiscount, updateDiscount,
		currencySymbol, charges, setCharges
	} = props;

	useEffect(() => {
		if(charges && charges.length) {
			let arr = [];
			charges.map(item => {
				let obj = {...item};
				obj.finalCharge = item?.TotalAmount?.toFixed(2);
				arr.push(obj);
			})
			setCharges(arr);
		}
	}, []);
	
	return (
		// <div id="discount_modal" className="modal fade filtermodal modal-fullscreen modal-tag" tabindex="-1" role="dialog" aria-labelledby="discount_modalLabel" aria-hidden="true" data-backdrop="static">
		<div className='discount-modal-div'>
			<Modal
				className='discount-modal'
				showModal={showModal}
				closeModal={closeModal}
				showCloseBtn={true}
			>
				<Modal.Header><h2>{localString("Discount")}</h2></Modal.Header>
				<Modal.Body>
					<div className='container'>
						<div className='row'>
							<div className='col-sm-12'>
								<Table 
									className={{
										table: "table table-bordered table-condensed table-striped"
									}}
								>
									<Table.Header>
										<Table.Row>
											<Table.Heading>{localString('Charge Name')}</Table.Heading>
											<Table.Heading>{localString('Charge Value')} (<span dangerouslySetInnerHTML={{__html: currencySymbol }}></span>)</Table.Heading>
											<Table.Heading>{localString('Tax')} </Table.Heading>
											<Table.Heading>{localString('MRP')} (<span dangerouslySetInnerHTML={{__html: currencySymbol }}></span>)</Table.Heading>
											<Table.Heading>{localString('Discount')} (<span dangerouslySetInnerHTML={{__html: currencySymbol }}></span>)</Table.Heading>
											<Table.Heading>{localString('Final Price')} (<span dangerouslySetInnerHTML={{__html: currencySymbol }}></span>)</Table.Heading>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{
											// consumerServiceRequest && consumerServiceRequest.charges && consumerServiceRequest.charges.map((charge,index)=>{
											charges && charges.map((charge, index) => {
												return (
													['ServiceCharge', 'Part', 'Other'].indexOf(charge.Type) > -1 && charge.BaseCharge != charge.WarrantyConcession && charge.BaseCharge > 0 && 
													<Table.Row>
														<Table.Data>{charge.Name}</Table.Data>
														<Table.Data>{charge.BaseCharge}</Table.Data>
														<Table.Data>
															{
																charge.TaxArray && charge.TaxArray.map(tax=>{
																	return (
																		<div className="row">
																			<div className="col-md-6">{tax.TaxDisplay}</div>
																			<div className="col-md-6">{tax.BaseChargeTax}</div>
																		</div>
																	)
																})
															}
														</Table.Data>
														<Table.Data>{charge.MRP.toFixed(2)}</Table.Data>
														<Table.Data>
															<input 
																type="text" 
																disabled={discountDisabled && !charge.Discount} 
																id={"discount_" + index} 
																onChange={(e) => addDiscount(e, index)}
																// oninput={addDiscount.bind(null,index)} 
																// value={Number(charge.Discount).toFixed(2) || '0'}
																value={charge.Discount}
															/>
														</Table.Data>
														<Table.Data>{charge && charge.finalCharge ? Number(charge.finalCharge).toFixed(2) : 0}</Table.Data>
														{/* <Table.Data id = {"finalCharge_" + index}>{(charge.TotalAmount).toFixed(2)}</Table.Data> */}
													</Table.Row>
												)
											})
											
										}
									</Table.Body>
								</Table>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-12">
								<Button 
									className = "btn button-servify-solid float-right" 
									onClick={() => updateDiscount()}
								>
									{localString('Add Discount')} 
								</Button>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
		</div>
	)
}

export default DiscountModal