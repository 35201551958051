import React from 'react';
import {Modal} from 'sfy-react';
import CloseVisitUI from './CloseVisitUI';
import './CloseVisitModal.scss';

const CloseVisitModal=(props)=>{
    const {localString,showModal,closeModal,jobModalState} = props;
		return (
		<>
            <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
                <Modal.Header>
                    <h2> {localString("Close Visit")} </h2>
                </Modal.Header>
                <Modal.Body className="overflow">
                    <CloseVisitUI {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            
        </>
	)
}
export default CloseVisitModal;