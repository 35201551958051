import React, { useState } from 'react';
import {Button, Input, Modal} from 'sfy-react';
import Swal from "sweetalert2";

import { displayError } from '../../../../../Utility/Utils';
import './ApproveDoaModal.scss';

const ApproveDoaModal = (props) => {
	const { 
        localString, showModal, closeModal, setLoader, apiCall, getRequestById, consumerServiceRequest, partsRequested
    } = props;
    const qFilter = JSON.parse(props.qFilter);
    const [remarks, setRemarks] = useState('');

    const approveRejectDoa = (value) => {
        setLoader(true); 
        let reqObj = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            IsDoaApproved: (value && value == 'approve') ? 1 : 0,
            ApprovedBy: qFilter.userID,
            Remarks: remarks.trim()
		}
        apiCall({
            url: 'approveOrRejectDoa',
            data: reqObj,
            callBack: (result) => {
                setLoader(false); 
                if(result && result.success) {
                    getRequestById && getRequestById();
                    closeModal();
                }else {
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(result)), 
                    });
                }
            },
            errorCB: (err) => {
                setLoader(false);
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(err)),  
                });
            }
        });
    }

    return ( <>
        <Modal 
            showModal={showModal} 
            closeModal={closeModal} 
            showCloseBtn={true} 
            className='Filter-Modal approveDoaModal'
        >
            <Modal.Header>
                <h2> {localString("Approve/Reject DOA")} </h2>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-xs-12'>
                        <label>{localString('SKU Requested')}</label>
                        <p>{partsRequested && partsRequested[0] && partsRequested[0].Name}</p>
                    </div>
                    <div className='col-xs-12'>
                        <label>{localString('Code')}</label>
                        <p>{partsRequested && partsRequested[0] && partsRequested[0].PartCode}</p>
                    </div>
                    <div className='col-xs-12'>
                        <label>{localString('Available Quantity')}</label>
                        <p>{partsRequested && partsRequested[0] && partsRequested[0].AvailableQuantity}</p>
                    </div>
                    <div className='col-xs-12'>
                        <label>{localString('Remarks')}</label>
                        <Input
                            value={remarks}
                            handleOnChange={(e) => {
                                let val = (e.target.value).replace(/\s{2,}/gm, ' ') //.trim()
                                setRemarks(val);
                            }}
                            inputComponent='textarea'
                            className={{
                                containerClass: 'input-container',
                                inputClass: 'input',
                                inputContainer: 'autoHeight'
                            }}
                            otherProps={{
                                rows: 2
                            }}
                        />
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                <div className='pull-right'>
                    <Button 
                        className="btn button-servify"
                        onClick={() => approveRejectDoa('approve')}
                        isDisabled={!remarks.trim()}
                    >
                        {localString("Approve")}
                    </Button>
                    <Button 
                        className="btn button-servify"
                        onClick={() => approveRejectDoa('reject')}
                        isDisabled={!remarks.trim()}
                    >
                        {localString("Reject")}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    </> )
}
export default ApproveDoaModal;