import React from 'react';
import {Modal} from 'sfy-react';
import CollectionDatePickerInput from './CollectionDatePickerInput';
import './CollectionDatePickerModal.scss'
const CollectionDatePickerModal=(props)=>{
    const {localString,showModal,closeModal,processRequest,changeBillingDetails,nextStatus} = props;
		return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='collection-date-modal'>
                <Modal.Header>
                    <h4> {nextStatus=='Ready For Collection' ? localString("Please enter the date till pickup would be available."):localString("Please enter the date of Pickup")} </h4>
                </Modal.Header>
                <Modal.Body>
                    <CollectionDatePickerInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default  CollectionDatePickerModal;