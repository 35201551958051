import React from "react";
import { Button, Modal, Dropdown, Pagination} from "sfy-react";
import "./ViewOffersModal.scss";

const ViewOffersModal = (props) => {
	const {localString, publicUrl, offersModal, setOffersModal, offersData, subCatValue, subCatOptions, handleOnBrandChange, 
		handleOnCategoryChange, isDisabled, brandValue, brandOptions, onClick, handleOnClick, footerButtonFunc, footerButtonText, 
		showClearOffers, setPagination, pagination
	} = props;

	return (
		<div className="view-discount-offers">
			<Modal
				showModal={offersModal}
				showCloseBtn={true}
				closeModal={() => setOffersModal(false)}
				className="default-modal modal-width-viewoffers"
			>
				<Modal.Header>
					<h3 className="text-center">
						{localString("Available Offers")}
					</h3>
					<h4>
						{localString("Select brand and category to view available offers")}
					</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="location-modal-body">
						<div className="row fields-to-filter">
							<div className="col-sm-4">
								<h4 className="text-offer">
									{localString("Subcategory")}
								</h4>
								<Dropdown
									value={subCatValue}
									options={subCatOptions}
									handleOnChange={(data) => {
										handleOnCategoryChange(data);
									}}
									classObject={{
										container: "subcat-brand-dropdown",
									}}
									filterBy="value"
									showDownArrow={false}
									placeholder={"Select Subcategory"}
								/>
							</div>

							<div className="col-sm-4">
								<h4 className="text-offer">{localString("Brand")}</h4>
								<Dropdown
									value={brandValue}
									options={brandOptions}
									handleOnChange={(data) => handleOnBrandChange(data)}
									classObject={{
										container: "subcat-brand-dropdown",
									}}
									filterBy="value"
									showDownArrow={false}
									placeholder={"Brand"}
									isDisabled={isDisabled}
								/>
							</div>

							<div className="search-offer-button ">
								<Button
									handleOnClick={(e) => handleOnClick(e)}
									isDisabled={isDisabled}
									type="button"
									classes="button-ghost btnOffer"
									text={localString("Search Offers")}
								></Button>
							</div>

							<div style={{ marginBottom: "12px", marginLeft: "30px" }}>
								{showClearOffers && (
									<span
										onClick={() => onClick()}
										className="resend-text"
										type="button"
									>
										{localString("Clear All")}
									</span>
								)}
							</div>
						</div>

						<hr style={{ borderTop: "1px solid darkgray" }}/>

						<div style={{ padding: "12px" }}>
							<h4 className="text-offer">Top Offers</h4>
						</div>

						<div className="view-offers-sec">
							{offersData &&
								offersData.map((offerText, index) => (
									<>
										<span style={{
											display: "flex",
											alignItems: "center",
										}}>
											<span>
												<img
													src={
														publicUrl +
														"/imgs/percentage.svg"
													}
													width="auto"
													style={{
														marginRight: "3px",
													}}
												/>
											</span>
											<span>
												<h4
													style={{
														fontSize: "14px",
														color: "#333",
													}}
													key={index}
													dangerouslySetInnerHTML={{
														__html: offerText.HomePageDisplayText,
													}}
												/>
											</span>
										</span>
									</>
								))
							}
						</div>
						{pagination.maxPages > 1 && (
							<Pagination
								itemsPerPage={pagination.itemsPerPage}
								pages={pagination.maxPages}
								totalItems={pagination.totalItems}
								activePage={pagination.currentPage}
								paginate={(pageNumber) =>
									setPagination({
										...pagination,
										currentPage: pageNumber,
									})
								}
							/>
						)}
						<div className="modal-confirm-button">
							<Button
								type="submit"
								handleOnClick={() => footerButtonFunc()}
								className="button button-primary mt20 max-width-range"
								text={
									footerButtonText
										? localString(footerButtonText)
										: localString("Okay, Got It")
								}
							></Button>
						</div>
					</div>
					
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</div>
	);
};

export default ViewOffersModal;
