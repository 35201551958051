import React, { useState, useEffect } from "react";
import { Loader, Button, Pagination, Checkbox } from "sfy-react";
import ReactDOM from "react-dom";
import { displayAmount, getDateFormat } from "../../../Utility/Utils";
import Swal from "sweetalert2";
import {
	displayError,
	checkEmail,
	checkProductUniqueID,
	checkName,
	getMobileMaxLength,
	checkMobileNumber,
} from "../../../Utility/Utils";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../../reduxToolkit/features/planSales/planSalesDashboardSlice";
import FilterModalPayment from "../FilterModalPayment/FilterModalPayment";
import DateRangePickerPlanSales from "../DateRangePickerPlanSales/DateRangePickerPlanSales";
import moment from "moment";
import PlanSalesTable from "../PlanSalesTable/PlanSalesTable";
import "./PaymentLinkTransaction.scss";

const PaymentLinkTransaction = (props) => {
	const { localString, apiCall, baseUrl, defaultCountryLocale, accessFn } =
		props;
    const qFilter = JSON.parse(props.qFilter);
	const { soldPlanList, dateRange, selectedPSL } = useSelector(
		(state) => state.PlanSalesDashboard
	);
	const dispatch = useDispatch();
	const [notificationData, setNotificationData] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [showFilterModal, setShowFilterModal] = useState(false);
	const [countriesData, setCountriesData] = useState(null);
	const [countryCode, setCountryCode] = useState(
		qFilter && qFilter.countrycode && qFilter.countrycode.length > 0
			? qFilter.countrycode[0].toUpperCase()
			: "IN"
	);
	const [showCheckbox, setShowCheckbox] = useState(false);
	const [disableApplyButton, setDisableApplyBtn] = useState(true);
	const [viewList, setViewList] = useState(false);
	const [mobileMaxLength, setMobileMaxLength] = useState([]);
	const [disableClearBtn, setDisableClearBtn] = useState(true);
	const [forceApiCall, setForceApiCall] = useState(true);

	const dropDownArray = [
		{
			value: localString("Select All"),
			id: 888,
		},
		{
			value: localString("Completed"),
			id: 7,
		},
		{
			value: localString("Pending"),
			id: -6,
		},

		{
			value: localString("Failed"),
			id: -3,
		},
	];

	const [filterData, setFilterData] = useState({
		ProductUniqueID: "",
		AlternateUniqueKey: "",
		FirstName: "",
		MobileNo: "",
		EmailID: "",
		StartDate: dateRange?.startDate ?? "",
		PartnerServiceLocationID: selectedPSL.PartnerServiceLocationID,
		EndDate: dateRange?.endDate ?? "",
		Status: dropDownArray.map((statusObj) => statusObj.id),
		StatusText: [],
		StatusID: "",
		countryCode: countryCode,
	});

	const [oldFilterData, setOldFilterData] = useState(filterData);
	const [pagination, setPagination] = useState({
		currentPage: 1,
		itemsPerPage: 10,
		maxPages: 5,
		totalItems: 0,
		lastPage: 0,
	});

	const closeModal = () => {
		setFilterData(oldFilterData);
		setShowFilterModal(false);
	};

	const statusText = {
		"-6": localString("Pending"), // 0 1 8
		0: localString("Pending"),
		1: localString("Pending"),
		8: localString("Pending"),
		"-3": localString("Failed"),
		6: localString("Failed"), //  6 4 5 2
		4: localString("Failed"),
		5: localString("Failed"),
		2: localString("Failed"),
		7: localString("Completed"),
	};

	const statusColor = {
		"-6": "#FCF0DF",
		0: "#FCF0DF",
		1: "#FCF0DF",
		8: "#FCF0DF",
		"-3": "#FDE4E1",
		6: "#FDE4E1",
		4: "#FDE4E1",
		5: "#FDE4E1",
		2: "#FDE4E1",
		7: "#E6F3E5",
	};
	const statusTextColor = {
		"-6": "#FF9300",
		0: "#FF9300",
		1: "#FF9300",
		8: "#FF9300",
		"-3": "#F54336",
		6: "#F54336",
		4: "#F54336",
		5: "#F54336",
		2: "#F54336",
		7: "#4EAF51",
	};

	const fetchSalesData = (obj, pageNumber) => {
		let sD = obj?.startDate ? obj?.startDate : filterData?.StartDate;
		let eD = obj?.endDate ? obj?.endDate : filterData?.EndDate;
		let data = {
			FirstName: filterData.FirstName,
			MobileNo: filterData.MobileNo,
			EmailID: filterData.EmailID.trim(),
			ProductUniqueID: filterData.ProductUniqueID,
			AlternateUniqueKey: filterData.AlternateUniqueKey,
			PartnerServiceLocationID: filterData.PartnerServiceLocationID,
			StartDate: sD,
			EndDate: eD,
			Status: filterData?.Status?.filter((status) => status != 888) ?? [],
		};
		data.pagination = pagination;
		setShowLoader(true);
		apiCall({
			url: "getSales",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success && result.data) {
					dispatch(
						setUserDetails(result.data && result.data.offlinePlans)
					);
					setPagination({
						...pagination,
						totalItems:
							result.data &&
							result.data.pagination &&
							result.data.pagination.totalItems,
						lastPage:
							result.data &&
							result.data.pagination &&
							result.data.pagination.lastPage,
					});
					setViewList(true);
					setShowFilterModal(false);
					setDisableClearBtn(false);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getCountries = (country) => {
		let data = {
			specificCountryCode: country ? country.toUpperCase() : countryCode,
			countryConfig: true,
		};
		setShowLoader(true);
		apiCall({
			url: "getCountries",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					let countryData = result.data.countries[0];
					setCountriesData(countryData);
					let maxLength = getMobileMaxLength(countryData);
					setMobileMaxLength(maxLength);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const resetOnFilterPayment = () => {
		setPagination({
			...pagination,
			currentPage: 1,
		});
		setForceApiCall(!forceApiCall);
	};

	useEffect(() => {
		getCountries();
		if (!selectedPSL?.PartnerServiceLocationID?.length) {
			if (accessFn("PLAN_SALE_ADMIN_USER")) {
				fetchSalesData();
			} else {
				Swal.fire({
					title: localString("Session Expired"),
					icon: "info",
					text: localString(
						"Your session has expired. The store location needs to be selected again, so you will be redirected to the home page shortly."
					),
					confirmButtonText: localString("Okay"),
				}).then(function () {
					window.location.href = baseUrl + "/plansales/home";
				});
			}
		} else {
			fetchSalesData();
		}
	}, [pagination.currentPage, filterData.StartDate, forceApiCall]);

	const sendNotification = (obj) => {
		setShowLoader(true);
		let reqObj = {
			OfflinePlanID: obj,
		};
		apiCall({
			url: "sendOfflinePlanNotification",
			data: reqObj,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success && result.data) {
					Swal.fire({
						icon: "success",
						text: localString(displayError(result)),
					});
					setNotificationData(false);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setShowLoader(false),
		});
	};

	const clearSearchFunc = () => {
		setFilterData({
			...filterData,
			FirstName: "",
			MobileNo: "",
			EmailID: "",
			ProductUniqueID: "",
			AlternateUniqueKey: "",
		});
		setDisableClearBtn(true);
	};

	const isMoreFiltersClearButtonDisabled = () => {
		return (
			!filterData.FirstName.length &&
			!filterData.MobileNo.length &&
			!filterData.EmailID.length &&
			!filterData.ProductUniqueID.length &&
			!filterData.AlternateUniqueKey.length
		);
	};

	const isFilterDataModified = () => {
		const isStatusDataModified = () => {
			if (filterData.Status.length != oldFilterData.Status.length)
				return true;
			// compare status array
			if (
				JSON.stringify([...filterData.Status].sort()) !=
				JSON.stringify([...oldFilterData.Status].sort())
			)
				return true;
			return false;
		};

		return (
			filterData.MobileNo != oldFilterData.MobileNo ||
			filterData.FirstName != oldFilterData.FirstName ||
			filterData.EmailID != oldFilterData.EmailID ||
			filterData.ProductUniqueID != oldFilterData.ProductUniqueID ||
			filterData.AlternateUniqueKey != oldFilterData.AlternateUniqueKey ||
			isStatusDataModified()
		);
	};

	const validateMoreFiltersData = () => {
		if (
			filterData.MobileNo.length > 0 &&
			(!mobileMaxLength.includes(filterData.MobileNo.length) ||
				!checkMobileNumber(filterData.MobileNo))
		) {
			return false;
		} else if (
			filterData.ProductUniqueID.length &&
			!checkProductUniqueID(filterData.ProductUniqueID)
		) {
			return false;
		} else if (
			filterData.FirstName.length &&
			!checkName(filterData.FirstName)
		) {
			return false;
		} else if (
			filterData.EmailID.length &&
			!checkEmail(filterData.EmailID)
		) {
			return false;
		} else {
			return true;
		}
	};

	const columns = [
		{
			title: localString("Plan Name"),
			showHeading: true,
			customCell: (row) => row.plan.PlanName || "--",
		},

		{
			title: localString("Serial Number") + " / " + localString("IMEI"),
			showHeading: true,
			customCell: (row) => row.ProductUniqueID || "--",
		},
		{
			title: localString("Customer Details"),
			showHeading: true,
			customCell: (row) =>
				(
					<span>
						{row.FirstName}
						<br />
						{row.EmailID}
						<br />
						{row.MobileNo}
					</span>
				) || "--",
		},

		{
			title: localString("Payment Plan"),
			showHeading: true,
			customCell: (row) => localString(row.PaymentFrequency) || "--",
		},

		{
			title: localString("Transaction Date"),
			showHeading: true,
			customCell: (row) =>
				(row?.TransactionDate &&
					moment(row.TransactionDate, "YYYY-MM-DDTHH:mm:ssZ").format(
						getDateFormat(defaultCountryLocale)
					)) ||
				"--",
		},

		{
			title: localString("Transaction Completion Date"),
			showHeading: true,
			customCell: (row) =>
				(row?.TransactionCompletionDate &&
					moment(
						row.TransactionCompletionDate,
						"YYYY-MM-DDTHH:mm:ssZ"
					).format(getDateFormat(defaultCountryLocale))) ||
				"--",
		},
		{
			title: localString("Transaction Amount"),
			showHeading: true,
			customCell: (row) =>
				!(
					row.TransactionAmount === 0 ||
					!row.TransactionAmount ||
					isNaN(row.TransactionAmount)
				) && (
					<span>
						{/* {(row?.CurrencySymbol && row?.TransactionAmount && (
							<span
								dangerouslySetInnerHTML={{
									__html: row.CurrencySymbol,
								}}
							></span>
						)) ||
							"-"}

						{row.TransactionAmount || "-"} */}
						{displayAmount(row.TransactionAmount, (countriesData?.countryConfig?.Locale?.DEFAULT || navigator?.language), row.CurrencyCode)}
					</span>
				),
		},

		{
			title: localString("Payment Link Status"),
			showHeading: true,
			customCell: (row) => (
				<span
					className="statusPill-payment"
					style={{
						background: statusColor[row.Status],
						color: statusTextColor[row.Status],
					}}
				>
					{statusText[row.Status] || "--"}
				</span>
			),
		},
		{
			title: localString(""),
			showHeading: true,
			customCell: (row) =>
				(row.Status == 1 && (
					<span
						onClick={() => {
							sendNotification(row?.OfflinePlanID);
						}}
						className="resend-text"
						type="button"
					>
						Resend Link
					</span>
				)) ||
				(row.Status == 7 && (
					<a
						href={
							baseUrl +
							"/plansales/viewcontract?spid=" +
							row.SoldPlanID
						}
						target="_blank"
					>
						<span
							className="glyphicon glyphicon-menu-right cursor-pointer"
							type="button"
						></span>
					</a>
				)) ||
				"---",
		},
	];

	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}

			<div className="hidden-xs content-header1">
				<div className="row content-header-background main-div2">
					<div className="col-sm-4">
						<h2 className="transaction">
							<a href={baseUrl + "/plansales/home"}>
								<span
									onClick={() => {}}
									className="resend-text"
									type="button"
								>
									{localString("Plan Sales")}
								</span>
							</a>
							&nbsp;{localString("/ Payment Link Transactions")}
						</h2>
					</div>

					<div className="right-content col-sm-8">
						<div className="period-div-payment">
							<span className="period-text-margin-payment">
								<label className="label-text-payment">
									{localString("Showing Results For")}
								</label>
							</span>
							<DateRangePickerPlanSales
								{...props}
								handleDateRangeApply={(obj) => {
									setFilterData({
										...filterData,
										StartDate:
											obj?.startDate ??
											filterData?.StartDate,
										EndDate:
											obj?.endDate ?? filterData?.EndDate,
									});
									setPagination({
										...pagination,
										currentPage: 1,
									});
								}}
								dateClass="col-sm-5"
								customFormat="dd-mm-yyyy"
								showPreDefinedRange={false}
								customPlaceholder="MM-DD-YYYY"
								dateWidthClass={"date-range-payment"}
							/>
						</div>

						<div style={{ display: "block" }}>
							<Button
								type="button"
								classes="button-ghost filter-button-trans"
								onClick={() => {
									// if checkbox is already shown, set filter to previous data while closing.
									if (showCheckbox) {
										setFilterData(oldFilterData);
									} else {
										setOldFilterData(filterData);
									}
									setShowCheckbox(!showCheckbox);
								}}
							>
								{localString("Transaction Status")} &nbsp;(
								{
									filterData.Status.filter(
										(status) => status != 888
									).length
								}
								)
								<span
									className="glyphicon glyphicon-menu-down"
									style={{ marginLeft: "6px" }}
								/>
							</Button>

							{showCheckbox && (
								<>
									<div className="">
										<div className="checkbox-payment checksize">
											<span
												onClick={() => {
													setFilterData({
														...filterData,
														["Status"]: [],
													});
												}}
												style={{
													float: "right",
													marginTop: "7px",
													color: "#4c10c1",
													fontWeight: "600",
													cursor: "pointer",
												}}
											>
												{localString("Clear All")}
											</span>

											{dropDownArray.map((obj, k) => (
												<Checkbox
													value={filterData.Status.some(
														(status) =>
															status == obj.id
													)}
													name="userType"
													id={obj.id}
													className={{
														container: "mb20",
													}}
													handleOnChange={(e) => {
														if (e.target.checked) {
															if (obj.id == 888) {
																// for select all
																setFilterData({
																	...filterData,
																	Status: dropDownArray.map(
																		(obj) =>
																			obj.id
																	),
																});
															} else {
																setFilterData({
																	...filterData,
																	["Status"]:
																		[
																			...filterData.Status,
																			obj.id,
																		],
																});
															}
														} else {
															// for uncheck
															if (obj.id == 888) {
																// for select all
																setFilterData({
																	...filterData,
																	Status: [],
																});
															} else {
																setFilterData({
																	...filterData,
																	["Status"]:
																		filterData.Status.filter(
																			(
																				status
																			) =>
																				status !=
																				obj.id
																		).filter(
																			(
																				status
																			) =>
																				status !=
																				888
																		),
																});
															}
														}
													}}
												>
													{localString(obj.value)}
												</Checkbox>
											))}
										</div>

										<>
											<div className="checkbox-button-footer1">
												<Button
													type="button"
													classes="button-ghost btn3"
													onClick={() => {
														setShowCheckbox(false);
														setFilterData(
															oldFilterData
														);
													}}
													isDisabled={
														!isFilterDataModified()
													}
												>
													{localString("Cancel")}
												</Button>
												<Button
													type="button"
													className="button button-primary btn12"
													isDisabled={
														!(
															filterData.Status
																.length > 0 &&
															isFilterDataModified()
														)
													}
													onClick={() => {
														setShowCheckbox(false);
														resetOnFilterPayment();
													}}
												>
													{localString("Apply")}
												</Button>
											</div>
										</>
									</div>
								</>
							)}
						</div>

						<Button
							type="button"
							classes="button-ghost filter-button-payment"
							onClick={() => {
								setOldFilterData(filterData);
								setShowFilterModal(true);
							}}
						>
							<span
								className="glyphicon glyphicon-filter icon-color"
								style={{
									marginLeft: "-11px",
									paddingRight: "4px",
								}}
							/>
							{localString("More")}
						</Button>
					</div>
				</div>
			</div>

			<div className="bulkUploadGrid">
				<div className="table-header-div">
					<h4 className="bulkUploadhHeader col-sm-6">
						{localString("Payment Link Transactions")}
					</h4>

					{pagination.totalItems && (
						<Pagination
							paginationInfo={`${
								(pagination.currentPage - 1) *
									pagination.itemsPerPage +
								1
							} - ${Math.min(
								pagination.currentPage *
									pagination.itemsPerPage,
								pagination.totalItems
							)} of ${pagination.totalItems}`}
						/>
					)}
				</div>

				{viewList && (
					<PlanSalesTable
						{...props}
						columns={columns}
						data={soldPlanList}
						localString={localString}
						emptyStateText={localString(
							"Sorry, No Transactions Found"
						)}
						tableClasses={{
							tableContainer: "summary-table",
						}}
					/>
				)}
				{pagination.lastPage > 1 && (
					<Pagination
						itemsPerPage={pagination.itemsPerPage}
						pages={pagination.lastPage}
						totalItems={pagination.totalItems}
						activePage={pagination.currentPage}
						paginate={(pageNumber) =>
							setPagination({
								...pagination,
								currentPage: pageNumber,
							})
						}
						className={{ container: "p22" }}
					/>
				)}
			</div>
			{showFilterModal && (
				<FilterModalPayment
					{...props}
					setFilterData={setFilterData}
					showModal={showFilterModal}
					closeModal={closeModal}
					filterData={filterData}
					disableApplyButton={
						!(isFilterDataModified() && validateMoreFiltersData())
					}
					disableClearButton={isMoreFiltersClearButtonDisabled()}
					clearSearch={clearSearchFunc}
					filterFunc={(e) => {
						e.preventDefault();
						resetOnFilterPayment();
					}}
					getCountries={(country) => getCountries(country)}
					countryCode={countryCode}
					mobileMaxLength={mobileMaxLength}
				/>
			)}
		</>
	);
};

export default PaymentLinkTransaction;
