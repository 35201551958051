import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Input } from 'sfy-react';
import Datetime from 'react-datetime';
import Swal from 'sweetalert2';
import moment from 'moment';

import { displayError } from '../../../Utility/Utils';

export default function DOP(props) {
	const { accessFn, apiCall, localString, publicUrl, setLoader, consumerProduct, submitDOPSuccess } = props;
	const [dop, setDOP] = useState(moment((consumerProduct.DateOfPurchase && consumerProduct.DateOfPurchase != null) ? consumerProduct.DateOfPurchase: moment(Date()).format('YYYY-MM-DD'), ['YYYY-MM-DDTHH:mm:ss.SSS', 'DD-MM-YYYY', 'YYYY-MM-DD']).format('DD-MM-YYYY'));

	const submitDOP = () => {
		if(dop) {
			if(moment(consumerProduct.DateOfPurchase).format('YYYY-MM-DD') == moment(dop, 'DD-MM-YYYY').format('YYYY-MM-DD')) {
				submitDOPSuccess(dop);
			}else {
				let reqObj = {
					ConsumerProductID: consumerProduct.ConsumerProductID,
					ProductID: consumerProduct.ProductID,
					DateOfPurchase: moment(dop, 'DD-MM-YYYY').format('YYYY-MM-DD')
				}
				setLoader(true);
				apiCall({
					url: 'setProductDetailsForRegisteredProducts',
					data: reqObj,
					callBack: (result) => {
						setLoader(false); 
						if(result.success && result.data){
							submitDOPSuccess(dop);
						}else {
							Swal.fire({  
								icon: 'error',  
								text: localString(displayError(result)),  
							});
						}
					},
					errorCB: (err) => {
						setLoader(false);
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(err)),  
						});
					}
				});
			}
		}
	}

	return (
		<div id='DOP' className="raiseReqSection">
			<div className='dop'>						
				<div className="row">
					<div className="col-sm-10 col-xs-8 form-label">
						<label>{localString("Enter Date of Purchase")}</label>
					</div>
				</div>
				<div className='form-group raiseRequestDatePicker'>
					<Datetime
						value={dop}
						inputProps={{
							onKeyDown: e => e.preventDefault()
						}}
						dateFormat={'DD-MM-YYYY'}
						timeFormat={false}
						onChange={(val) => {
							setDOP(moment(val).format('DD-MM-YYYY'));
						}}
						isValidDate={(current) => current.isBefore(new Date())}
						closeOnSelect={true}
					/>
				</div>
				<div className="text-right">
					<Button
						className="btn button-servify nextBtn" 
						onClick={() => submitDOP()}
					>
						<div className="button-next"></div>
					</Button>
				</div>
				
			</div>
		</div>
	);
}