import React,{useState,useEffect} from 'react';
import {Modal} from 'sfy-react';
import Swal from "sweetalert2";
import HandoverModal from './HandoverModal';
import { displayError,SERVICETYPE } from '../../../../../Utility/Utils';
const DeviceHandoverAndRecieved=(props)=>{
    const {apiCall,accessFn,localString,showModal,nextStatus,consumerServiceRequest,setShowModal,claimRequestWorkshopNext,setLoader,getRequestById,claimNewWorkshopChecks} = props;
    const [deviceReturnTo,setDeviceReturnTo]=useState([])
	const [msgId,setMsgId]=useState()
	useEffect(() => {
		setDeviceReturnTo(consumerServiceRequest.DeviceReturnTo);
	}, [consumerServiceRequest])

	const deviceReturnedSave = function(e) {
		e.preventDefault();
		let name = 'deviceReturnedStatus';
		if('Device returned' == nextStatus) { //not used
			//setShowModal({...showModal,showDeviceReturnedModal:false})
		} else {
			setShowModal({...showModal,showDeviceHandoverModal:false})
			name = 'deviceHandoverStatus';
		}

		let deviceReturnedTolocal = {};

		let deviceReturnedRadio = document.getElementsByName(name);

		for(let i=0; i < deviceReturnedRadio.length; i++) {
			if(deviceReturnedRadio[i].checked){
				let msgId = parseInt(deviceReturnedRadio[i].id[0]);
				for(let i=0; i < deviceReturnTo.length; i++){
					if(deviceReturnTo[i].msgId == msgId){
					deviceReturnedTolocal = deviceReturnTo[i];
					break;
					}
				}
			}
		}
		
		if (deviceReturnedTolocal.msgId) {
			if(claimNewWorkshopChecks){
				claimRequestWorkshopNext(nextStatus, '', deviceReturnedTolocal); //claim new workshop
			}else{ //pick up carry in
				setLoader(true);
				let data = {
					ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
					Status: nextStatus,
					DeviceReturnTo: deviceReturnedTolocal
				};
				apiCall({
					url:'servicerequestFulfilmentWorkshop',
					data: data,
					callBack:(result)=>{
						setLoader(false);
						if(result.success){
							getRequestById();
						}else{
							Swal.fire({
								icon: 'error',
								text: localString(displayError(result)),
							});
						}
					}
				});
			}
		} else {
			Swal.fire ({
				title:'',
				text:localString("You have not chosen any option to handover"),
				icon:'info'
			})
		}
	};
    return (
		<>
            {
                showModal.showDeviceHandoverModal &&
                <HandoverModal 
                    {...props}
                    DeviceReturnTo={deviceReturnTo}
                    setMsgId={setMsgId}
                    deviceReturnedSave={deviceReturnedSave}
                />
            }
        </>
	)
}
export default DeviceHandoverAndRecieved;