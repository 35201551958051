import React, { useState, useEffect } from "react";
import { Dropdown } from "sfy-react";
import Snackbar from "../Snackbar/Snackbar";
import moment from "moment";
import "./DateRangePicker.scss";
import {
	setDateRange,
	setDropdownObj,
	setDropDownValue,
} from "../../reduxToolkit/features/planSales/planSalesDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite-no-reset.min.css";

const CustomDateRangePicker = (props) => {
	const {
		handleDateRangeApply,
		publicUrl,
		dateClass,
		localString,
		setShowPSLModal,
		dropDownArray,
		shouldDisableDateFn,
		logicType,
		accessFn,
	} = props;
	const dispatch = useDispatch();
	const { dropdownValue, dropdownObj, dateRange, selectedPSL } = useSelector(
		(state) => state.PlanSalesDashboard
	);
	const [showSnackbar, setShowSnackBar] = useState(false);
	const [openCalendar, setOpenCalendar] = useState(false);
	
	useEffect(() => {
		if (dropdownValue && dropdownObj) {
			dispatch(
				setDateRange({
					startDate: new Date(dropdownObj.startDate),
					endDate: new Date(dropdownObj.endDate),
				})
			);
		} else {
			dispatch(setDropDownValue(dropDownArray[0].value));
			dispatch(setDropdownObj(dropDownArray[0]));
			dispatch(
				setDateRange({
					startDate: new Date(dropDownArray[0].startDate),
					endDate: new Date(dropDownArray[0].endDate),
				})
			);
		}
	}, []);

	const valueType = [
		dateRange?.startDate && dateRange?.startDate != ""
			? new Date(dateRange?.startDate)
			: undefined,
		dateRange?.endDate && dateRange?.endDate != ""
			? new Date(dateRange?.endDate)
			: undefined,
	];

	const handleDateRange = (startDate, endDate) => {
		handleDateRangeApply({
			startDate,
			endDate,
		});
		setOpenCalendar(false);
	};
	
	const processDateRange = (startDate, endDate, maxDays) => {
		const date1 = moment(startDate.toISOString());
		const date2 = moment(endDate.toISOString());
		const days = date2.diff(date1, "days");
	
		if (days > maxDays) {
			setShowSnackBar(true);
		} else if (days <= maxDays) {
			handleDateRange(startDate, endDate);
		}
	};

	const handleDropdownChange = (item) => {
        dispatch(setDropDownValue(item.value));
        dispatch(setDropdownObj(item));
        let obj = {
            startDate: item.startDate,
            endDate: item.endDate,
        };
        dispatch(setDateRange(obj));
        if (selectedPSL?.PartnerServiceLocationID?.length || accessFn("PLAN_SALE_ADMIN_USER")) {
            handleDateRangeApply(obj);
        } else {
            setShowPSLModal(true);
        }
        if (item.value === localString("Custom Date") && (selectedPSL?.PartnerServiceLocationID?.length || accessFn("PLAN_SALE_ADMIN_USER"))) {
            setOpenCalendar(true);
        }
    };

	const handleDateRangeChange = (val) => {
        dispatch(
            setDateRange({
                startDate: val[0],
                endDate: val[1],
            })
        );
        dispatch(
            setDropdownObj({
                startDate: val[0],
                endDate: val[1],
            })
        );
        if (logicType === 'PlanSalesDatePicker') {
            processDateRange(val[0], val[1], 29);
        } else if (logicType === 'PlanRenewalDatePicker') {
            processDateRange(val[0], val[1], 60);
        }
    };

	const handleClick = () => {
		const canOpenCalendar = selectedPSL?.PartnerServiceLocationID?.length || accessFn("PLAN_SALE_ADMIN_USER");
		if (canOpenCalendar) {
			setOpenCalendar(!openCalendar);
		}
	};

	const getMessage = () => {
		switch (logicType) {
			case 'PlanSalesDatePicker':
				return localString("You can only select a range within 30 days");
			case 'PlanRenewalDatePicker':
				return localString("You can only select a range within 60 days");
			default:
				return "";
		}
	};

	const { afterToday } = DateRangePicker;
	return (
		<div className={dateClass ? dateClass : "dashboard-filter"}>
			<div
				className="row"
				style={{ display: "flex", width: "max-content" }}
			>
				<Dropdown
					id="dropdown"
					value={dropdownValue}
					options={dropDownArray}
					placeholder= {localString("Select")}
					handleOnChange={handleDropdownChange}
					classObject={{
						container: "dropdown-container-home",
						placeholder: "placeholder text-dropdown",
					}}
					filterBy="value"
				/>

				<DateRangePicker
					showOneCalendar
					format="dd/MM/yyyy"
					character=" - "
					id="date"
					ranges={[]}
					value={valueType}
					onChange={handleDateRangeChange}
					disabled={
						(selectedPSL?.PartnerServiceLocationID?.length || accessFn("PLAN_SALE_ADMIN_USER")) && 
						dropdownValue == localString("Custom Date")
							? false
							: true
					}
					open={
						dropdownValue == localString("Custom Date") &&
						openCalendar
							? true
							: false
					}
					onClick={handleClick}
					shouldDisableDate={shouldDisableDateFn}
					style={{ width: 220 }}
				/>
			</div>
			{showSnackbar && (
				<Snackbar
					type="error"
					publicUrl={publicUrl}
					message={getMessage()}
					callback={() => {
						setShowSnackBar(false);
					}}
				/>
			)}
		</div>
	);
};

export default CustomDateRangePicker;
