import React from 'react';
import {Button,Table} from 'sfy-react';
import { returnLocalDateTime } from '../../Utility/Utils';
import './RetailerReset.scss'

const LocationAdvisorDetails = (props) => {
	const {advisorLocationArray,localString,resetSucessful,currentLocation,showResetButton,resetHandsetLocation,advisorLabel,tableHeader,totalLogs}=props;
	return (
		<>
		<form className="fluid-form-content fluid-form-active reset-shadow">
			<div className="row">
				<div className="col-md-12 custom-align">
					<div className="request-panel-col handset-advisor">
						<div className="request-panel-header">
							<p>{localString("Shop Location Reset Details")}</p>
						</div>
						<div className="request-panel">
							<Table className="complaint-details-table table table-striped">
								<Table.Body>
								{
									advisorLocationArray && advisorLocationArray.map(data=>{
										return(
											data.Value && 
											<Table.Row>
												<Table.Data className="table-keys">{data.Name}</Table.Data>
												<Table.Data>{data.Value}</Table.Data>
											</Table.Row>
										)
									})
								}
								</Table.Body>
							</Table>
							<div>
								{
									showResetButton && <Button  text={localString('Reset')} className='btn button-servify pull-right' onClick={resetHandsetLocation}></Button>
								}
								{
									resetSucessful && <h2>{localString("Shop Location reset has already been done. Please inform the corresponding")} {advisorLabel} {localString("to exit and re-open the Mobile COS")}</h2>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
		<form className="fluid-form-content fluid-form-active">
			<div className="row">
				<div className="col-md-12 custom-align">
					<div className="request-panel-col handset-advisor">
						<div className="request-panel-header">
							<p>{localString("Previous Locations Reset Details")}</p>
						</div>
						<div className="request-panel consumer-details-height">
							<Table className="table table-striped">
								<Table.Header>
									<Table.Row>
									{tableHeader && tableHeader.length>0 && tableHeader.map((head, index) => {
										return (
											<Table.Heading key={index}>{head.title}</Table.Heading>
										)
									})}
									</Table.Row>
								</Table.Header>
								<Table.Body>
								{
									currentLocation && currentLocation.length>0 && currentLocation.map((logs,index) => {
										return(
											<Table.Row key={index}>
												<Table.Data>{logs.latitude}</Table.Data>
												<Table.Data>{logs.longitude}</Table.Data>
												<Table.Data>{logs.shop}</Table.Data>
												<Table.Data>{logs.updatedDate}
												<span className="inner-padding-L location-color">
													<span className="label label-primary">{localString("Current Location")}</span>
												</span>
												</Table.Data>
											</Table.Row>
										)
									})
								}
								{
									totalLogs && totalLogs.length && totalLogs.map((logs,index)=>{
										return(
											<Table.Row key={index}>
												<Table.Data>{logs.Lat}</Table.Data>
												<Table.Data>{logs.Lng}</Table.Data>
												<Table.Data>{logs.ShopCode + " - " +logs.ShopName}</Table.Data>
												<Table.Data>{returnLocalDateTime(logs.UpdatedDate)}</Table.Data>
											</Table.Row>
										)
									}) 
								}
								
								
								</Table.Body>
							</Table>
						</div>
					</div>
				</div>
			</div>
		</form>
	</>
	)
}

export default LocationAdvisorDetails
