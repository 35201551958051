import React from 'react';
import {Modal} from 'sfy-react';
import InspectDeviceInput from './InspectDeviceInput';
import './InspectDeviceModal.scss';

const InspectDeviceModal=(props)=>{
    const {localString,showModal,closeModal,isTransferIncomplete} = props;
		return (
		<div className='inspect-device-Modal'>
            <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='full-screen-modal'>
                <Modal.Header>
                    {!isTransferIncomplete ? <h4 className="modal-title text-center" id="myModalLabel"> {localString("Upload Device Pictures and Complete Checklist")}</h4>
                    :<h4 className="modal-title text-center" id="myModalLabel">{localString("Complete Checklist")}</h4>}
                </Modal.Header>
                <Modal.Body>
                    <InspectDeviceInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
	)
}
export default InspectDeviceModal;