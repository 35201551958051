import React from 'react';
import FilterInput from './../FilterInputs/FilterInput';
import {Modal} from 'sfy-react';
import './FilterModal.scss';

const FilterModal = (props) => {
		const {localString,showModal,closeModal} = props;
		return (
		<>
			<Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
				<Modal.Header>
					<h2> {localString("Filter")} </h2>
				</Modal.Header>
				<Modal.Body>
					<FilterInput {...props}></FilterInput>
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
		</>
	)
}
export default FilterModal;