import React from 'react';
import {Modal} from 'sfy-react';
import AddShipmentDetailInput from './AddShipmentDetailInput';
import './AddShipmentDetailModal.scss';
const AddShipmentDetailModal=(props)=>{
    const {localString,showModal,closeModal} = props;
		return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
                <Modal.Header>
                    <h2> {localString("Shipping Details")} </h2>
                </Modal.Header>
                <Modal.Body>
                    <AddShipmentDetailInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default AddShipmentDetailModal;