import React, { useState } from "react";
import { Button, Dropdown } from "sfy-react";
import Swal from "sweetalert2";

import { displayError } from "../../../Utility/Utils";

const AddRequestAdditionalDocuments = (props) => {
	const {
		localString,
		consumerServiceRequest,
		documentsList,
		getProofsByGroup,
		setShowLoader,
		apiCall,
		getRequestById,
	} = props;

	const [selectedDocumentType, setSelectedDocumentType] = useState({
		value: "",
	});

	const addProofByGroup = (consumerServiceRequestID, proofGroupCode) => {
		let data = {
			consumerServiceRequestID,
			proofGroupCode,
		};

		setShowLoader(true);
		apiCall({
			url: "addProofByGroup",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					getRequestById();
					getProofsByGroup();
					Swal.fire({
						icon: "success",
						title: localString(result.msg),
						text: result?.data?.msg
							? localString(result.data.msg)
							: "",
					});
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const handleAddAdditionalDocument = () => {
		if (!selectedDocumentType.proofGroupCode) {
			Swal.fire({
				icon: "error",
				text: localString("Select a document first!"),
			});

			return;
		}

		addProofByGroup(
			consumerServiceRequest.ConsumerServiceRequestID,
			selectedDocumentType.proofGroupCode
		);
	};

	return (
		<>
			<div
				className="bold paddingTB15"
				style={{
					fontSize: "16px",
				}}
			>
				Additional Documents
			</div>

			<div className="row add-doc-wrapper">
				<div className="col-sm-6">
					<label for="additional-document-type">
						Additional Document Type
					</label>
					<Dropdown
						id="additional-document-type"
						classObject={{
							container: "rq-border-radius",
							optionsSearch: "rq-border-radius",
							valueContainer: "rq-border-radius",
						}}
						value={selectedDocumentType.value}
						options={documentsList}
						handleOnChange={(val) => {
							setSelectedDocumentType(val);
						}}
						filterBy="value"
						searchText="Document Type"
					/>
				</div>
				<div className="col-sm-3">
					<Button
						classes="button-ghost button-small rp-btn rp-btn-width"
						isDisabled={!selectedDocumentType?.proofGroupCode}
						handleOnClick={() => {
							handleAddAdditionalDocument();
						}}
					>
						{localString("Add")}
					</Button>
				</div>
			</div>
		</>
	);
};

export default AddRequestAdditionalDocuments;
