import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Input, Button, Loader } from "sfy-react";
import CredentialWrapper from "../../components/CredentialsWrapper/CredentialsWrapper";

const ForgotPassword = (props) => {
	const [username, setUsername] = useState(props.email || "");
	const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
	const [showLoader, setLoader] = useState(false);
	const [errorState, setErrorState] = useState({
		errorText: "",
		showError: false,
		type: 1
	});

	useEffect(() => {
		checkDisableSubmitBtn();
	}, [username]);

	const userNameChangeHandler = (username) => {
		setUsername(username);
	};
	const checkDisableSubmitBtn = () => {
		if (username != "" && !errorState.showError) {
			setDisableSubmitBtn(false);
		} else {
			setDisableSubmitBtn(true);
		}
	};

	const submitUsername = (e) => {
		if (e) {
			e.preventDefault();
		}
		let data = {username:username,reason: "Forgot password"};
		let options = {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
			},
		};
		let url = props.baseUrl + "/api/v1/users/passwordReset";
		setLoader(true);
		fetch(url, options)
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((result) => {
				setLoader(false);
				if (result.success) {
					setErrorState((prevState) => {
						return {
							...prevState,
							errorText: result.msg,
							showError: true,
							type: 1,
						};
					});
					setDisableSubmitBtn(true);
				} else {
					setErrorState({
						errorText: result.msg,
						showError: true,
						type: 0
					});
					setDisableSubmitBtn(false);
				}
			})
			.catch((error) => {
				console.log("error: " + error);
			});
	};

	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}
			<CredentialWrapper
				title="Forgot Password"
				publicUrl={props.publicUrl}
			>
				<form onSubmit={submitUsername}>
					<div className="cred-input-div">
						<Input
							handleOnChange={(e) => {
								userNameChangeHandler(e.target.value);
							}}
							value={username}
							placeholder="Username"
							inputType="text"
						/>
					</div>
					<div className="cred-button-div">
						<Button
							handleOnClick={submitUsername}
							isDisabled={disableSubmitBtn}
							type="submit"
							classes="button-ghost cred-button button-small"
							text="Submit"
						/>
					</div>
				</form>
				<div className="cred-link-div">
					<a className="cred-link" href={props.baseUrl}>
						Choose another account
					</a>
				</div>
				{errorState.showError && errorState.type == 0 && (
					<p className="cred-error">{errorState.errorText}</p>
				)}
				{errorState.showError && errorState.type == 1 && (
					<p>{errorState.errorText}</p>
				)}
			</CredentialWrapper>
		</>
	);
};

export default ForgotPassword;
