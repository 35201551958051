import React from 'react';
import {Button,Input,Modal} from 'sfy-react';
import {checkAlphaNumeric} from '../../../../../Utility/Utils';
import './TaskList.scss';

const CloseRemarkModal = (props) => {
	const  {localString, closeTaskModal, closeButtonModal, closeRemark, closeTask, setCloseRemark, cloaseTaskDisable} = props;

	return (
		<Modal showModal={closeTaskModal} closeModal={closeButtonModal} showCloseBtn={true} className="remark-modal close-modal">
			<Modal.Header>
				<h4 className="modal-title">{localString("Close Task")}</h4>   
			</Modal.Header>
			<Modal.Body>    
				<div className="request-filter">
					<div class="form-group">
						<label>{localString("Task Reference Id ")}</label><p>{closeRemark.externalReferenceId}</p>
					</div>
					<div className="form-group">
						<Input
							value={closeRemark.remark}
							inputType="textarea"
							handleOnChange={(e) => {
								let text = e.target.value;
								if(checkAlphaNumeric(text)){   
									setCloseRemark({...closeRemark,remark:text}) 
								}else if(text == ""){
									setCloseRemark({...closeRemark,remark:""}) 
								}
							}}
							id="editCloseRemarkTextArea"
							label={localString('Remark')}
							labelAlign='top'
						/>
					</div>
					<div className="form-group">
						<Button type="submit" isDisabled={cloaseTaskDisable} handleOnClick={closeTask} text={localString('Close Task')} className='btn button-servify '></Button>
						<Button handleOnClick={closeButtonModal} text={localString('Cancel')} className='btn button-servify'></Button>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	)
}

export default CloseRemarkModal
