import React, { useEffect, useState } from 'react';
import {Button, Dropdown, Loader, Input} from 'sfy-react';
import "./NonWarrantyFlow.scss";
import DatePicker from "rsuite/DatePicker";
import "rsuite/dist/rsuite-no-reset.min.css";
import AddressFields from '../../PlanSalesAddressFIelds/AddressFields';
import { displayAmount } from '../../../../Utility/Utils';

const NonWarrantyFlow = (props) => {
	const {publicUrl, localString, nonWarrantyDeviceList, selectedDeviceObj, nonWarrantyDropdownFunction, plansVisible, handleSearchClick,
		displayPlanList, nonWarrantySellNowClick, visiblePlanIndex, eligibilityFieldsCheck, getIMEISerialText, filterDataVerifyDevice, 
		setFilterDataVerifyDevice, warrantyApiAvail, checkEligibilityBeforePlanSelection, checkEligibleFieldsBeforePlanSelection, 
		goToOrderDetails, SKUModelCheck, nonWarrantySKUModelList, setSKUSearchText, disableSearch, showPlanPriceSimple, userCurrencySymbol, 
		deviceSKUDropdownList, noDataMessage, checkIfConsentRequiredForNonWarrantyFlow, eligibilityValidation, showUnDiscountedPriceSimple, 
		showDiscountPriceSimple, address, setAddress, countriesData} = props;
	
	const [updatedPlanList, setUpdatedPlanList] = useState([]);
	
	useEffect(() => {
		setUpdatedPlanList(displayPlanList)
	}, [displayPlanList])

	useEffect(() => {
		if(checkEligibilityBeforePlanSelection?.LG_checkEligibilityBeforePlanSelection ){
			scrollIntoViewFunction();
		}
	}, [updatedPlanList])
	
	const inputConfigs = [
		{
			showInput: eligibilityFieldsCheck.fieldEligibleProductUniqueID,
			labelText: getIMEISerialText(),
			inputType: "input",
			id: "inputImeiOrSerial",
			disable: warrantyApiAvail,
			onInputFunction: (val) =>{
				setFilterDataVerifyDevice({...filterDataVerifyDevice,
					inputImeiOrSerial: val
				})
			},
			className: {
				formControl: 'col-sm-4',
				containerClass: 'input-container',
				inputClass: 'input'
			},
			labelAlign: 'top',
			placeholder: localString("Enter serial number"),
		},
		{
			showInput: eligibilityFieldsCheck.fieldEligibleDateOfPurchase,
			labelText: "Date of Purchase",
			inputType: "date",
			id: "productPurchaseDate",
			disable: false, //disableDateOfPurchase,
			onInputFunction: (val) =>{
				setFilterDataVerifyDevice({...filterDataVerifyDevice,
					productPurchaseDate: val,
					repairDatePicker: "",
					warrantyEndDatePicker: ""
				})
			},
			placeholder: "Enter Purchase Date",
			className: {
				formControl: 'col-sm-4',
				containerClass: 'input-container',
				inputClass: 'input'
			},
			labelAlign: 'top',
			validationForDate: "after"
		},
		{
			showInput: eligibilityFieldsCheck.fieldEligibleDateOfBirth,
			labelText: "Date of Birth",
			inputType: "date",
			id: "dateOfBirthPicker",
			onInputFunction: (val) =>{
				setFilterDataVerifyDevice({...filterDataVerifyDevice,
					dateOfBirthPicker: val
				})
			},
			placeholder: "Enter Date of Birth",
			className: {
				formControl: 'col-sm-4',
				containerClass: 'input-container',
				inputClass: 'input'
			},
			labelAlign: 'top',
			validationForDate: "after"
		},
		{
			showInput: eligibilityFieldsCheck.fieldEligibleProductPurchaseCost,
			labelText: "Product Purchase Cost",
			inputType: "input",
			id: "eligiblityProductPurchaseCost",
			onInputFunction: (val) =>{
				let productCost = val.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
				productCost = productCost.replace(/^[0-9]*\.[0-9]{3,}(.*)$/gi, parseFloat(productCost).toFixed(2));

				setFilterDataVerifyDevice({...filterDataVerifyDevice,
					eligiblityProductPurchaseCost: productCost      
				})
			},
			className: {
				formControl: 'col-sm-4',
				containerClass: 'input-container',
				inputClass: 'input'
			},
			labelAlign: 'top',
		},
		{
			showInput: eligibilityFieldsCheck.fieldEligibleRepairDate,
			labelText: "Repair Date",
			inputType: "date",
			id: "repairDatePicker",
			isDisabled: (filterDataVerifyDevice?.productPurchaseDate == "" || filterDataVerifyDevice?.productPurchaseDate == null), //!isDateOfPurchaseValid
			onInputFunction: (val) =>{
				setFilterDataVerifyDevice({...filterDataVerifyDevice,
					repairDatePicker: val
				})
			},
			placeholder: "Enter Repair Date",
			className: {
				formControl: 'col-sm-4',
				containerClass: 'input-container',
				inputClass: 'input'
			},
			labelAlign: 'top',
			validationForDate: "between",
			DOP: filterDataVerifyDevice.productPurchaseDate
		},
		{
			showInput: eligibilityFieldsCheck.fieldEligibleWarrantyEndDate,
			labelText: "Warranty End Date",
			inputType: "date",
			id: "warrantyEndDatePicker",
			isDisabled:(filterDataVerifyDevice?.productPurchaseDate == "" || filterDataVerifyDevice?.productPurchaseDate == null), //false, //!isDateOfPurchaseValid
			onInputFunction: (val) =>{
				setFilterDataVerifyDevice({...filterDataVerifyDevice,
					warrantyEndDatePicker: val
				})
			},
			// maxDay: new Date(),
			placeholder: "Enter Warranty End Date",
			className: {
				formControl: 'col-sm-4',
				containerClass: 'input-container',
				inputClass: 'input'
			},
			labelAlign: 'top',
			validationForDate: "before",
		},
		{
			showInput: eligibilityFieldsCheck.fieldEligibleDeviceSKU,
			labelText: "Device SKU",
			title: "Please select from dropdown only",
			inputType: "dropdown",
			value: filterDataVerifyDevice.identifierValue,
			dropdownList: deviceSKUDropdownList,
			id: "identifierValue",
			onInputFunction: (val) =>{
				//onkeyup={getStatesValue}
				setFilterDataVerifyDevice({...filterDataVerifyDevice,
					deviceSKU: val.productName,
					identifierValue: val.IdentifierValue,
					deviceIdentifierID: val.deviceIdentifierID
				})
			},
			placeholder: "Select Device SKU*",
			className: "form-control--top col-sm-4",
			labelAlign: 'top',
		},
		// /** Confirmed by vatsal below fields not used on PROD */
		// {
		//     showInput: eligibilityFieldsCheck.fieldEligiblePurchaseDate,
		//     labelText: "Plan Purchase Date",
		//     inputType: "date",
		//     id: "purchaseDatePicker",
		//     onInputFunction: (val) =>{
		//         setFilterDataVerifyDevice({...filterDataVerifyDevice,
		//             purchaseDatePicker: val
		//         })
		//     },
		//     // maxDay: new Date(),
		//     placeholder: "Enter Plan Purchase Date",
		//     className: {
		//         formControl: 'col-sm-4',
		//         containerClass: 'input-container',
		//         inputClass: 'input'
		//     },
		//     labelAlign: 'top',
		// },
		// {
		//     showInput:  eligibilityFieldsCheck.fieldEligibleRemarks,
		//     labelText: "Remarks",
		//     inputType: "input",
		//     id: "eligibleRemarks",
		//     onInputFunction: (val) =>{
		//         //checkRemarksValue
		//         setFilterDataVerifyDevice({...filterDataVerifyDevice,
		//             eligibleRemarks: val 
		//         })
		//     },
		//     className: {
		//         formControl: 'col-sm-4',
		//         containerClass: 'input-container',
		//         inputClass: 'input'
		//     },
		//     labelAlign: 'top',
		// },
	];

	const viewDiscountFunction = (index) => {
		let buttonClick = false;
		let newArr = []
		let a = updatedPlanList?.map((val,i) => {
			if(i === index){
				let obj = {};
				obj = val;
				obj.viewOfferBtnClick = obj.viewOfferBtnClick ? !obj.viewOfferBtnClick : !buttonClick;
				newArr.push(obj)
			}else {
				newArr.push(val)
			}
			return newArr;
		});
		setUpdatedPlanList(newArr)
	}

	const setAutoCompleteOffFunction = () => {
		let  a = document.getElementById("ProductPurchaseDate");
		a.setAttribute("autocomplete","off")
	}

	const scrollIntoViewFunction = () => {
		let  a = document.getElementById("available-plans");
		a && a.scrollIntoView({ 
			block: 'start',  
			behavior: 'smooth' 
		});
	}

	// const intersection = checkEligibleFieldsBeforePlanSelection?.filter(element => addressFields.includes(element));

	return (
		<>
			<div className='non-warranty-Flow non-warranty-mobile-flow'>
				{
					(checkEligibilityBeforePlanSelection?.LG_checkEligibilityBeforePlanSelection 
					|| checkEligibilityBeforePlanSelection?.Dell_checkEligibilityBeforePlanSelection)
						?
							<div className='lg-super-heading'>
								{localString("Find Eligible Plans")}
							</div>
						:
							<div className='d-flex select-header'>
								<h3 className='select-product-header'>{localString('Select Product Model to View Plans')}</h3>
							</div>
				}
				<div className='d-flex section-header'>
					<p className=''>{localString('Product Details')}</p>
				</div>
				<div className='sku-modle-title-info'>
				{
					SKUModelCheck 
					? 	
					<p className='select-product-header'>
						{localString("Product Model") + " (" +localString("Search using min. 3 characters") + ")"}
					</p>
					: 
					<div>
						<h5>{localString('Product Name')}</h5>
					</div> 
				}
				</div>
				{/* <div className='d-flex select-header'>
					<h3 className='select-product-header'>{localString('Select Product Model to View Plans')}</h3>
				</div>
				{
					SKUModelCheck &&
					<div className='sku-modle-title-info'>
						<h5 className='select-product-header'>{localString("Search model using minimum 3 characters")}</h5>
					</div>
				}
				<div>
					<h5>{SKUModelCheck ? localString('Product Model') : localString('Product Name')}</h5>
				</div> */}
				<div>
					<Dropdown
						id='dropdown'
						value = {selectedDeviceObj.value}
						options = {SKUModelCheck ? nonWarrantySKUModelList : nonWarrantyDeviceList}
						placeholder = {SKUModelCheck ? localString('Ex. AHS45632') : localString("Select product name")}
						handleOnChange = {(data) => {
							nonWarrantyDropdownFunction(data)
						}}
						handleOnSearch={ SKUModelCheck ? (e) => {
							setSKUSearchText(e.target.value);
							return nonWarrantySKUModelList;
						}: undefined }
						filterBy = 'value'
						description = 'Description'
						classObject = {{
							container: 'dropdown-container',
							valueContainer: 'some-class',
						}}
						customComponent = {SKUModelCheck && <span><img className='search-icon' src={publicUrl+'/imgs/searchIcon.png'} /></span>}
						showNoOptionsText = {noDataMessage ? true : false}
						showDownArrow = {false}
						editable = {false}
						autoComplete = "false"
					/>
				</div>
				{
					( checkEligibilityBeforePlanSelection?.LG_checkEligibilityBeforePlanSelection 
						||  checkEligibilityBeforePlanSelection?.Dell_checkEligibilityBeforePlanSelection) 
						&&  checkEligibleFieldsBeforePlanSelection?.map((eligibleField) => {
							return(
								<>
									{
										eligibleField === "DateOfPurchase" &&
										<div className="input-options col-sm-4">
											<div>
												<h5>{localString('Product Purchase Date')}</h5>
											</div>
											<div>
												<DatePicker  oneTap
													style = {{ width: '100%' }} 
													placeholder = {localString('Enter product purchase date')}
													format = "dd-MM-yyyy"
													character = " - "
													id = "ProductPurchaseDate"
													shouldDisableDate={date => date > new Date()}
													onChange = {(val) =>{
														setFilterDataVerifyDevice({...filterDataVerifyDevice,
															productPurchaseDate: val
														})
													}}
													onClick = {()=> setAutoCompleteOffFunction()}
												/>
											</div>
										</div>
									}
									{
										eligibleField === "ProductPurchaseCost" && 
										<div className='input-options col-sm-4' style={{marginTop: "10px"}}>
											<div className=''>
												<label class="input__label" style={{width: "auto", margin: "7px 0", display: "block"}}>
													{localString("Product Purchase Cost")}{" ("}
													{userCurrencySymbol && <span dangerouslySetInnerHTML={{__html: userCurrencySymbol}}></span>}{")"}
												</label>
											</div>
											<div className=''>
												<Input 
													value={filterDataVerifyDevice.eligiblityProductPurchaseCost}
													key = "ProductPurchaseCost"
													// label={localString("Product Purchase Cost")}
													// labelAlign= 'top'
													// placeholder={}
													handleOnChange = {(e)=>{
														setFilterDataVerifyDevice({...filterDataVerifyDevice,
															eligiblityProductPurchaseCost: e.target.value
														})
													}}
													placeholder = {localString("Enter product purchase cost")}
													inputType='number'
													className={{
														formControl: 'forms-width',
														containerClass: 'input-container',
														inputClass: 'input'
													}}
												/>
											</div> 
										</div>
									}
									{
										checkEligibilityBeforePlanSelection?.Dell_checkEligibilityBeforePlanSelection &&
										eligibleField === "ProductUniqueID" && 
										<div className='input-options col-sm-4'>
											<Input 
												value={filterDataVerifyDevice.inputImeiOrSerial}
												key = "ProductUniqueID"
												label={localString("Serial Number")}
												labelAlign= 'top'
												placeholder={localString("Enter serial number")}
												handleOnChange = {(e)=>{
													setFilterDataVerifyDevice({...filterDataVerifyDevice,
														inputImeiOrSerial: e.target.value
													})
												}}
												className={{
													formControl: 'forms-width',
													containerClass: 'input-container',
													inputClass: 'input',
													label: 'input-top-label'
												}}
											/>
										</div> 
									}
									<div className='address-sec'>
										{
											eligibleField === "PinCode" && 
											<>
												<div className='d-flex section-header col-sm-12'>
													<p className=''>{localString('Customer Details')}</p>
												</div>
												<AddressFields {...props} address = {address} setAddress = {setAddress} />
											</>
										}
									</div>
								</>
							)
						})
				}
				<div className={
					(checkEligibilityBeforePlanSelection?.LG_checkEligibilityBeforePlanSelection 
						|| checkEligibilityBeforePlanSelection.Dell_checkEligibilityBeforePlanSelection) 
						? 'search-al col-sm-12'
						:'search col-sm-12'
				}>
					<Button 
						type = 'button' 
						className = 'button-ghost search-btn'
						handleOnClick = { handleSearchClick }
						isDisabled = {
							(checkEligibilityBeforePlanSelection?.LG_checkEligibilityBeforePlanSelection 
							||  checkEligibilityBeforePlanSelection?.Dell_checkEligibilityBeforePlanSelection) 
							?   disableSearch 
							:   (!selectedDeviceObj?.value || selectedDeviceObj?.value === null ||selectedDeviceObj?.value === "") 
								? true 
								: false
						}
					>
						{localString('Search')}
					</Button>
				</div>
					{
						plansVisible && (
						<div>
							<div className="divider-gray hidden-xs" style={{display: "inline-block"}}> </div>
							<div className='available-plans' id="available-plans">
								<div className='plan-header'>{
										updatedPlanList && updatedPlanList.length === 0 
										? localString('No Eligible Plans')  
										: localString('Eligible Plans')
									}</div>
								{
									(updatedPlanList && updatedPlanList.length === 0) &&
									// <div className="col-sm-12 plan-lists-wrapper"> 
									<div className=""> 
										{localString("Sorry! We couldn't find any eligible plans for your product.")}
									</div>
								}
								{
									updatedPlanList && updatedPlanList.map((plan, index) => (
										<div className="col-sm-12 plan-lists-wrapper">
											<div className='plan-flow'>
												<div className="visible-xs plan-text">{localString("Plan Name")}</div>
												<div className="plan-data">{localString(plan.PlanDisplayName || plan.PlanName)}</div>
												<div className="pull-right d-flex plan-actions">
													{
														showPlanPriceSimple(plan) && 
														<div class="visible-xs plan-text">{localString("Price")}</div>
													}
													<div onclick = {"(e)=>{e.preventDefault(); sellNowWarranty(val)}"} style={{textAlign:"end"}} class="plan-data">
														{
															<>
															{
																plan.PlanPriceArray.length == 1 && plan.simpleDiscount && 
																<span className="strike-through">
																	{
																		showUnDiscountedPriceSimple(plan) 
																			? 	displayAmount(showUnDiscountedPriceSimple(plan), 
																				countriesData?.countryConfig?.Locale?.DEFAULT,
																				plan.CurrencyCode) 
																			: ''
																	}
																	{/* {
																		plan.CurrencySymbol && <span dangerouslySetInnerHTML={{__html: plan.CurrencySymbol}}></span>
																	}
																	{showUnDiscountedPriceSimple(plan)} */}
																</span>
															}
															
															<span className="plan-price">
																{
																	showPlanPriceSimple(plan) 
																		? 	displayAmount(showPlanPriceSimple(plan), 
																			countriesData?.countryConfig?.Locale?.DEFAULT, 
																			plan.CurrencyCode) 
																		: 	''
																}
																{/* {
																	plan.PlanPriceArray?.length == 1 && showPlanPriceSimple(plan) && 
																	plan.CurrencySymbol && <span dangerouslySetInnerHTML={{__html: plan.CurrencySymbol}}></span>
																}
																{showPlanPriceSimple(plan)} */}
															</span>
															{
																plan.PlanPriceArray.length == 1 && plan.simpleDiscount && 
																<span className="green">
																	{" "}<span dangerouslySetInnerHTML={{__html: showDiscountPriceSimple(plan)}}></span>
																</span>
															}
															</>
														}
													</div>
													<Button 
														type = "button"
														classes = "btn button-servify btn-header button-stack pull-left sell-now-btn"
														handleOnClick = {() => 
														(   checkEligibilityBeforePlanSelection?.LG_checkEligibilityBeforePlanSelection 
															||  checkEligibilityBeforePlanSelection?.Dell_checkEligibilityBeforePlanSelection) 
															?   goToOrderDetails(plan) 
															:   nonWarrantySellNowClick(index, plan)}
														text = {localString("Sell Now")}
													/>
													<Button 
														type = "button"
														classes = "btn button-servify btn-header button-stack know-more-btn"
														handleOnClick = {() => window.open(plan.AboutLink)}
														text = {localString("Know More")}
													/>
													{
														plan?.showViewAllOffers && 
														<Button 
															type = "button"
															classes = "view-offer-btn"
															handleOnClick = {(e)=> {e.preventDefault(); viewDiscountFunction(index) }} //setViewOfferButton(!viewOfferButton)
															text = {localString("View Offers")}
														/>
													}
												</div>
											</div>
											{
												plan.showViewAllOffers && plan.viewOfferBtnClick && 
												<div style={{display:"grid"}}>
													<div id={index} class="col-sm-12 discounts-container">
														{
															plan?.PlanDiscounts?.PlanDiscountOffers.map((discount)=> {
																return (
																	<span style={{display:"flex", padding: "10px 0"}}>
																		<img src={publicUrl+'/imgs/percentage.svg'} className="percent-img" alt="%"/>
																		<span dangerouslySetInnerHTML={{__html: discount.HomePageDisplayText}}></span>
																	</span>
																)
															})
														}
													</div>
												</div>
											}
											{visiblePlanIndex === index && (
												<div>
												<div class="divider-gray hidden-xs"> </div>
												<div className='input-options'>
													{
														inputConfigs.map((config,index) => {
															return (
																config.showInput &&
																<>
																	{
																		config.inputType === "input" &&
																		<Input
																			value={filterDataVerifyDevice[config.id]}
																			key={config.id} 
																			label={config.labelText}
																			labelAlign={config.labelAlign}
																			placeholder={config.placeholder}
																			isDisabled = {config.disable}
																			className={config.className}
																			handleOnChange = {(e)=>{
																				config.onInputFunction(e.target.value)
																			}}
																		/>
																	}
																	{
																		config.inputType === "dropdown" &&
																		<div className={config.className}>
																			<label className='label-deviceSKU'>{config.labelText}</label>
																			<Dropdown
																				id='dropdown-device-sku'
																				key={index}
																				value={config.value}
																				options={config.dropdownList}
																				handleOnChange={(data) => {
																					config.onInputFunction(data);
																				}}
																				filterBy='value'
																				showDownArrow={false}
																				placeholder={localString(config.placeholder)}
																			/>
																		</div>
																	}
																	{
																		config.inputType === "date" &&
																		<div className='form-control--top col-sm-4 ps-non-warranty-date-picker'>
																			<h3 className="input__label">{config.labelText}</h3>
																			<DatePicker oneTap
																				format = "dd-MM-yyyy"
																				character = " - "
																				id = {config.id}
																				onChange = {(val) =>{
																					config.onInputFunction(val)
																				}}
																				disabled = {config.isDisabled}
																				placeholder = {config.placeholder}
																				shouldDisableDate={ 
																					config.validationForDate == "after" 
																					? (date => date > new Date())
																					: config.validationForDate == "before"
																						? (date => date < new Date())
																						: config.validationForDate == "between" 
																							?   ((date) => date <= new Date(config.DOP) || date >= new Date())
																							: ""
																				}
																			/>
																		</div>
																	}
																</>
																)
														})
													}
												</div>
												<div className='check-eligibility col-sm-12'>
													<Button 
														type='button' 
														className='button-ghost search-btn'
														handleOnClick={(e) => {
															e.preventDefault();
															checkIfConsentRequiredForNonWarrantyFlow()
														}}
														isDisabled = {eligibilityValidation}
													>
														{localString('Check Eligibility')}
													</Button>
												</div>
												</div>
											)}
										</div>
									))
								}
							</div>
						</div>
					)}
			</div>
		</>
	)
}

export default NonWarrantyFlow