import React from 'react';
import Datetime from 'react-datetime';
import './FilterInput.scss';
import {Input, Button, Dropdown, Radio, DatePicker, Multiselect} from 'sfy-react';
import IntlTelInputWrapper from '../IntlTelInputWrapper/IntlTelInputWrapper';
////&& !filter.loaded
const FilterInputs = (props) => {

    const {filterFunc,filterInputs,disableApplyBtn,clearSearch,hideClearButton,filterData,localString,saveButtonText,cancelButtonText,hideSaveButton,showLoader} = props;
    return (
        <div>
            <form onSubmit={filterFunc}>
            <div className="col-sm-12">
            {filterInputs && filterInputs.length>0 && filterInputs.map((filter, index) => {
                return(
                        filter.showFilter && <div className={filter.customClass?filter.customClass:'col-sm-4 paddingB10'}>
                        { 
                            (filter.type && filter.type === 'Radio') && 
                            <>
                            <div className="inner-labelText">{filter.labelText && localString(filter.labelText)}</div> 
                            <div className="subclass-radio">
                            {
                                filter.radioButtons && filter.radioButtons.map((radio,i)=>{
                                    return(
                                        <div className="custom-radio">
                                        <Radio
                                            value={radio.value}
                                            name={radio.buttonText}
                                            selectedValue={radio.isSelect}
                                            handleOnUpdate={(e)=>{
                                                radio.onclickupdate(e.target.value)
                                            }}
                                            classes={radio.className}
                                        >{radio.buttonText}</Radio>
                                        </div>
                                    )
                                    
                                })
                            }
                            </div>
                            </>
                        }
                        {
                            filter.type && filter.type == 'DatePicker' && <div className="form-control--top ">
                                <label className="input__label ">{filter.labelText}</label>
                                <DatePicker
                                    date={new Date(filterData[filter.dataKey]) != "Invalid Date"? new Date(filterData[filter.dataKey]):""}
                                    format="dd/mm/yyyy"
                                    maxDay={filter.maxDay}
                                    minDay={filter.minDay}
                                    handleOnChange={(date) => {
                                        filter.oninputFunc(date);
                                    }}
                                    classes={filter.classes || {}}
                                    isDisabled={filter.isDisabled}
                                />
                            </div>
                        }
                        {
                            filter.type && filter.type == 'DateRangePicker' && <div className="form-control--top ">
                                <label className="input__label ">{filter.labelText}</label>
                                <DatePicker
                                    placeholder={filter.placeholder}
                                    format='dd-mm-yyyy'
                                    dateRange={filterData[filter.dataKey] || ''}
                                    handleOnChange={(date) => {
                                        filter.oninputFunc(date);
                                    }}
                                    maxDay={filter.maxDay}
                                    showDateRange={true}
                                    // showFilterBtn = {true}
                                    // onFilterBtnClick={(val) => setDateRange(tempDateRange)}
                                    // showPreDefinedRange={true}
                                />
                            </div>
                        }
                        {
                            filter.type && filter.type == 'DateTime' && <div className="form-control--top custom-datetime">
                                <label className="input__label ">{filter.labelText}</label>                             
                                <Datetime
                                    placeholder={filter.placeholder}
                                    value={filterData[filter.dataKey]}
                                    dateFormat={filter.dateFormat}
                                    timeFormat={filter.timeFormat}
                                    closeOnSelect={true}
                                    onChange={(val) => filter.oninputFunc(val)}
                                    isValidDate={(current) => {
                                        if(filter && filter.maxDay && filter.minDay) {
                                            return current.isAfter(filter.minDay) && current.isBefore(filter.maxDay)
                                        }else if(filter && filter.maxDay) {
                                            return current.isBefore(filter.maxDay)
                                        }else if(filter && filter.minDay) {
                                            return current.isAfter(filter.minDay)
                                        }else {
                                            return true;
                                        }
                                    }}
                                />
                            </div>
                        }
                        {
                            filter.type && filter.type == 'intelInput' && <div className="form-control--top ">
                                <IntlTelInputWrapper 
                                    value = {filterData[filter.dataKey]}
                                    handleOnChange = {(val, countryData) => filter.changeFunc(val, countryData)}
                                    onFlagSelect = {(countryData) => filter.onFlagSelect(countryData)}
                                    label = {filter.labelText}
                                    maxLength = {filter.maxlength}
                                    key={index}
                                    intlTelInputClassWrapper={filter.intlTelInputClassWrapper}
                                    defaultCountry={filter.defaultCountry}
                                ></IntlTelInputWrapper>
                            </div>
                        }
                        {
                        (!filter.type || filter.type=='input') &&<Input
                                value={filterData[filter.dataKey]}
                                key={index}
                                handleOnChange={(e) => {
                                    filter.oninputFunc(e.target.value);
                                }}
                                label={filter.labelText}
                                labelAlign='top'
                                isDisabled={filter.isDisabled}
                                maxLength={filter.maxLength}
                                minLength={filter.minLength}
                                placeholder={filter.placeholder}
                                className={filter.className || {}}
                                inputType={filter.inputType || 'text'}
                                otherProps={filter.otherProps}
                                inputComponent={filter.inputComponent || 'input'}
                            />
                        }
                        {
                            (filter.type=='File') &&
                            <>
                                {
                                    filter.showLabel && <label className="input__label ">{filter.labelText}</label>
                                }
                                <Input
                                    handleOnChange={(e)=>{
                                        filter.oninputFunc(e)
                                    }}
                                    key={index}
                                    id={"fileUpload_"+index}
                                    inputType="file"
                                    otherProps={{ accept: filter.acceptFileType}}
                                />
                            </>
                        }
                        {
                        (filter.type=='MapAutoComplete') &&<Input
                                value={filterData[filter.dataKey]}
                                key={index}
                                handleOnChange={(e) => {
                                    filter.oninputFunc(e.target.value);
                                }}
                                label={filter.labelText}
                                labelAlign='top'
                                placeholder={filter.placeholder}
                                otherProps={{ ref: filter.Ref }}
                            />
                        }
						{
                            filter.type && filter.type == 'Dropdown' && 
                            <div className="form-control--top focus-border">
                                <label className="input__label ">{filter.labelText}{filter?.required && <span className='text-danger'>*</span>}</label>
                                {
                                    filter.showSearchFilter ? 
                                        <Dropdown
                                            id='dropdown'
                                            key={index}
                                            classObject={filter.classObject || {}}
                                            value={filterData[filter.dataKey]}
                                            options={filter.dropdownList}
                                            handleOnChange={(data) => {
                                                filter.oninputFunc(data);
                                            }}
                                            handleOnSearch={(e) => {
                                                filter.onInputSearch(e.target.value)
                                                return filterData
                                            }}
                                            customComponent={filter.showLoader && !filter.loaded && <span className='input-loader form-control-feedback form-control-feedback-padded loader-position'></span>}
                                            filterBy='value'
                                            showDownArrow={false}
                                            searchText={filter.searchText}
                                            // className={}
                                            isDisabled={filter.isDisabled}
                                            placeholder={filter.placeholder}
                                            OptionsComponent={filter.OptionsComponent}
											showNoOptionsText={false}
                                        />:
                                        <Dropdown
                                            id='dropdown'
                                            key={index}
                                            classObject={filter.classObject || {}}
                                            value={filterData[filter.dataKey]}
                                            options={filter.dropdownList}
                                            handleOnChange={(data) => {
                                                filter.oninputFunc(data);
                                            }}
											handleOnFocus={() => {
                                                if(filter.hasOwnProperty("oninputFocus")){
                                                    filter.oninputFocus()
                                                }
                                            }}
                                            customComponent={filter.showLoader && !filter.loaded && <span className='input-loader  form-control-feedback-padded loader-position'></span>}
											showTooltip
                                            filterBy='value'
                                            showDownArrow={false}
                                            searchText={filter.searchText}
                                            isDisabled={filter.isDisabled}
                                            placeholder={filter.placeholder}
                                            OptionsComponent={filter.OptionsComponent}
											showNoOptionsText={false}
                                        />
                                }
                            </div>
                        }
                        {
                            filter.type && filter.type == 'MultiSelect' && 
                            <div className="form-control--top ">
                                <label className="input__label ">{filter.labelText}{filter?.required && <span className='text-danger'>*</span>}</label>
                                <Multiselect
                                    values={filterData[filter.dataKey]}
                                    options={filter.dropdownList}
                                    onSelect={(val) => filter.onSelect(val)}
                                    onRemove={(val) => filter.onRemove(val)}
                                    onClearAll={(val) => filter.onClearAll(val)}
                                    filterBy='value'
                                    showDownArrow={false}
                                    // searchText={filter.searchText}
                                    isDisabled={filter.isDisabled}
                                    placeholder={filter.placeholder}
                                    uniqueKey={filter.uniqueKey}
                                    OptionsComponent={filter.OptionsComponent}
                                />
                            </div>
                        }
                        <div>
                            {
                                filter.errorMessage && <span className = "error-input">{filter.errorMessage}</span>
                            }
                        </div>
                        </div>
                )   
            })}
            </div>
            <div className="button-container">
                {!hideSaveButton && <Button handleOnClick={filterFunc} isDisabled={disableApplyBtn} type='submit' classes='button-ghost' text={saveButtonText ? localString(saveButtonText):localString('Apply')}></Button>}
                {!hideClearButton && <Button handleOnClick={clearSearch} type='button' classes='button-ghost' text={cancelButtonText ? localString(cancelButtonText):localString('Clear')}></Button> }
            </div>
            </form>
        </div>
    ) 
}

export default FilterInputs;
