import React from 'react';
import {Modal} from 'sfy-react';
import ReplacementDetailsInput from './ReplacementDetailsInput';
import './ReplacementDetailsModal.scss';
const ReplacementDetailsModal=(props)=>{
    const {localString,showModal,closeModal,processRequest,changeBillingDetails} = props;
		return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
                <Modal.Header>
                    <h4> {localString("Please Verify and Add the Details")} </h4>
                </Modal.Header>
                <Modal.Body>
                    <ReplacementDetailsInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default ReplacementDetailsModal;

