

import React from 'react';
import {Table,Button,Pagination} from 'sfy-react';
import './UserDetailList.scss';
import {returnLocalDateTime} from '../../../Utility/Utils'

const UserDetailList = (props) => {
	const {headings, userDetailList, baseUrl, setCurrentPage, pagination, accessFn, localString, textColor, bgColor} = props;
	
	return (
		<div className="plan-management-user-details">
			{userDetailList && userDetailList.length>0 ? ( 
				<>
					<Table isResponsive>
						<Table.Header>
						<Table.Row>
						{headings && headings.length>0 && headings.map((head, index) => {
							return (
								head.showHeading && <Table.Heading key={index}>{head.title}</Table.Heading>
							)
						})}
						<Table.Heading></Table.Heading>
						</Table.Row>
						</Table.Header>
						<Table.Body>
							{
								userDetailList.map((data, index) => {
									return(
										<Table.Row key={index}>
											<Table.Data>{data.FirstName+" "+(data.LastName?data.LastName:"")}</Table.Data>
											<Table.Data>{data.MobileNo}</Table.Data>
											<Table.Data>{data.EmailID}</Table.Data>
											<Table.Data>{data.ProductUniqueID || "-"}</Table.Data>
											<Table.Data>{data.SoldPlanCode || "-"}</Table.Data>
											<Table.Data>{data.ExternalReferenceID || "-"}</Table.Data>
											{accessFn('SHOW_VIEW_BUTTON') && <Table.Data>{data.PlanName || '-'}</Table.Data>}
											{accessFn('SHOW_VIEW_BUTTON') && <Table.Data>{(returnLocalDateTime(data.DateOfActivation,'DD-MM-YYYY HH:mm')) || '-'}</Table.Data>}
											<Table.Data>{
												<span className='contract-status'
													style={{
														background: bgColor[data.StatusTextObject && data.StatusTextObject.validityText], 
														color: textColor[data.StatusTextObject && data.StatusTextObject.validityText]
													}}>
													{data.StatusTextObject && data.StatusTextObject.validityText ? localString(data.StatusTextObject.validityText) : '-'}
												</span>
											}</Table.Data>
											{accessFn('SHOW_VIEW_BUTTON') && <Table.Data>
													<div className="button-container">
														<a href={baseUrl+'/plans/consumer/view?spid='+data.SoldPlanID} target="_blank">
															<Button  type='button' text={localString('View')} classes='button-ghost'></Button>
														</a>
													</div>
												</Table.Data>
											}
										</Table.Row>  
									)
								})
							}
						</Table.Body>
					</Table>
					{
						pagination.lastPage > 1 && <Pagination itemsPerPage={pagination.itemsPerPage} pages={pagination.lastPage} totalItems={pagination.totalItems} activePage={pagination.currentPage} paginate={(pageNumber) => {setCurrentPage(pageNumber)}}/>
					}
				</>
				
				):(
					<div className="col-sm-12"> 
						<div className="text-center requests-empty-state"> 
							<div className="empty-state-text"> 
								{localString('No Data Available')}
							</div> 
						</div> 
					</div>
				)
			}
		</div>
	)
}

export default UserDetailList;