import React from "react";
import DonutChart from "../DonutChart/DonutChart";
import "./Cards.scss";
import { useSelector } from "react-redux";

const Cards = (props) => {
	const {
		titleHeader,
		infoText,
		showCurrency = false,
		currency,
		showAmount = true,
		amountClass,
		showFooterText = true,
		amount,
		showLink = false,
		text1,
		href,
		showNumbers = false,
		text2,
		linkText,
		showChart = true,
		successCount,
		setShowPSLModal,
		success,
		pending,
		pendingCount,
		failure,
		upfrontSales,
		subscriptionPlans,
		failureCount,
		handleClick,
		descriptionClass,
	} = props;

	const { selectedPSL } = useSelector((state) => state.PlanSalesDashboard);

	const up = Math.round(
		(upfrontSales * 100) / (upfrontSales + subscriptionPlans)
	);
	const sp = Math.round(
		(subscriptionPlans * 100) / (upfrontSales + subscriptionPlans)
	);

	return (
		<div className="row">
			<div className="pill-hover button-servify pills-servify no-border-radius inactive ">
				<div>
					<h5 className="tile-title">{titleHeader}</h5>&nbsp;&nbsp;
					{(currency && showCurrency && (
						<h5
							className="tile-currency"
							dangerouslySetInnerHTML={{
								__html: "(" + currency + ")",
							}}
						></h5>
					)) || (
						<h5
							style={{ display: "none" }}
							dangerouslySetInnerHTML={{
								__html: "(" + currency + ")",
							}}
						></h5>
					)}
					<span
						className="glyphicon glyphicon-info-sign icon-hover"
						style={{
							paddingLeft: "3px",
							color: "#000000",
							position: "relative",
						}}
					></span>
					<p className="hide-text">{infoText}</p>
					{showChart && (
						<DonutChart
							{...props}
							upfrontSales={upfrontSales}
							subscriptionPlans={subscriptionPlans}
						/>
					)}
					{showLink &&
						(!selectedPSL?.PartnerServiceLocationID?.length ? (
							<a
								className="linkText"
								onClick={() => {
									setShowPSLModal(true);
								}}
							>
								{linkText}
							</a>
						) : (
							<a className="linkText" href={handleClick}>
								{linkText}
							</a>
						))}
				</div>

				{showAmount && (
					<div className={`amount ${amountClass}`}>{amount}</div>
				)}

				{showFooterText && (
					<div className={`description-footer ${descriptionClass}`}>
						<div className="first-heading">
							<span className="dot1"></span>
							<h5 className="text1 text-nowrap">{text1}</h5>

							{up > 0 ? (
								<span style={{ color: "black" }}>&nbsp;({up}%)</span>
							) : (
								<span style={{ color: "black" }}>&nbsp;(0%)</span>
							)}
						</div>

						<div className="second-heading">
							<span className="dot2"></span>
							<h5 className="text2 text-nowrap">{text2}</h5>
							{sp > 0 ? (
								<span style={{ color: "black" }}>&nbsp;({sp}%)</span>
							) : (
								<span style={{ color: "black" }}>&nbsp;(0%)</span>
							)}
						</div>
					</div>
				)}

				{showNumbers && (
					<div class="row">
						<div class="col-md-4">
							<p className="text-success text">{successCount}</p>
							<p className="text-dark">{success}</p>
						</div>
						<div class="col-md-4">
							<p className="text-warning text">{pendingCount}</p>
							<p className="text-dark">{pending}</p>
						</div>
						<div class="col-md-4">
							<p className="text-danger text">{failureCount}</p>
							<p className="text-dark">{failure}</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Cards;
