import React  from 'react';
import './PlanConsumerDetails.scss';
import {Button, Table, Input, Pagination} from 'sfy-react';
import {isEmpty} from 'lodash';
import {displayAmount, getLinkUri, returnLocalDateTime} from '../../../Utility/Utils'
import FilterInputs from '../../../components/FilterInputs/FilterInput';
import Tooltip from '../../../components/Tooltip/Tooltip';
const ConsumerDetailsPage = (props) => {
	const {
		consumerDetailArray, baseUrl, localString, showTable, filterInputs, saveButtonText, filterFunc, filterData, 
		showSearchFilter, paymentDetailArray, headers, coverageTab, coverageDetails, showStatus, statusObject, 
		documentTab, documentsDetailObject, searchValidation, isDocReceived, paymentTable, consumerDetails, pagination, 
		setCurrentPage, paymentStatusFunction, bgColor, textColor, loggedInUserSpicificCountryData 
	} = props;

	const defaultText = "-";

	return(
		<div className="content">
			{
				showStatus && 
				statusObject && !isEmpty(statusObject) && 
					<div className="status-button">
						<Tooltip
							content={() => <span>{statusObject.displayText}</span>}
							className={{
								tooltipText: 'servify-tooltip-text'
							}}
							position='bottom'
						>
							<Button classes='button-ghost status-btn-padding' 
								style={{
									background: bgColor[statusObject && statusObject.validityText], 
									color: textColor[statusObject && statusObject.validityText]
								}}
							> {localString(statusObject.validityText)} </Button>
						</Tooltip>
					</div>
			}
			{!showTable && 
				consumerDetailArray && consumerDetailArray.length>0 && consumerDetailArray.map((consumer, index) => {
				return(
					<>
						{  !consumer.sectionId && consumer.showLabel &&		
							<div className={consumer.customClass ? consumer.customClass  :"col-sm-4 alignment-top"}>
								<div className="form-group item-resize">
									<label  className={consumer.customLabelClass ? consumer.customLabelClass + " text-capitalize consumer-form-label" : " text-capitalize consumer-form-label"}>{consumer.label}:</label>
									{
										!consumer.showLinks && <div style={{marginTop: "5px"}}>
											<span style={{padding: "10px"}}>{consumer.CurrencySymbol && <span dangerouslySetInnerHTML={{__html: consumer.CurrencySymbol}}></span>}{consumer.value}</span>
										</div>
									}
									{
										consumer.showLinks && consumer.linkArray && consumer.linkArray.length > 0 && consumer.linkArray.map((link, index) => {
											return (
												<div>
													<a  href={link.FilePath} target="_blank">
														<Button  type='button' text={"Doc"+(index+1)} classes='button-ghost'></Button>
													</a>
												</div>
											)
										})
									}
									{
										consumer.showLinks && consumer.linkArray && !consumer.linkArray.length && consumer.noLinkText && <div><p>{consumer.noLinkText}</p></div>
									}
								</div>
							</div>
						}
						{
							consumer.sectionId && 
							<div className="col-sm-12 row" id={consumer.sectionId}>
									{   <div className={consumer.customClass ? consumer.customClass  :"col-sm-4"}>
											<div className="form-group">
												<label  className="text-capitalize consumer-form-label">{localString(consumer.label)}:</label>
												<div>
													<p>{consumer.value}</p>
												</div>
											</div>
										</div>
									}
							</div>
						}
					</>
				)	
			})}
			{
				showTable && 
				<>
				{
					showSearchFilter && 
					<div className="col-sm-12 planTab-search-input">
						<div className="col-sm-6">
							{
								filterInputs && filterInputs.map((filter,i)=>{
									return(
										<Input
											value={filterData[filter.dataKey]}
											key={i}
											handleOnChange={(e) => {
												filter.oninputFunc(e.target.value);
											}}
											label={filter.labelText}
											labelAlign='left'
											maxLength={filter.maxLength}
											placeholder={filter.placeholder}
										/>
									)
								})
							}
						</div>
						<div className="plan-button">
							<Button handleOnClick={filterFunc} isDisabled={searchValidation} type='button' classes='button-ghost' text={localString('Search')}/>
						</div>
					</div>
					
				}
				<div className="">
					<Table isResponsive>
						<Table.Header>
							<Table.Row>
							{
								headers && headers.map((head, index) => {
									return (
										<Table.Heading key={index}>{head.label}</Table.Heading>
									)
								})
							}
							<Table.Heading></Table.Heading>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{
								isEmpty(consumerDetailArray) &&
								<Table.Row>
									<Table.Data className="empty-data-message" otherProps={{colSpan:"8"}}><strong>{localString("No Data Found")}</strong></Table.Data> 
								</Table.Row>
							}
							{
								consumerDetailArray && consumerDetailArray.map(data=>{
									return(
										<Table.Row>
										{
											data.map(inner=>{
												return(
													<>
														{
															inner.showStatus 
															?	<Table.Data>
																	<span className='device-coverage-status'
																		style={{
																			background: bgColor[inner.status], 
																			color: textColor[inner.status]
																		}}>
																		{inner.status}
																	</span>
																</Table.Data>
															: 	<Table.Data>{inner.value}</Table.Data>
														}
														{
															inner.showButton && 
															<Table.Data>
																<a href={baseUrl+'/consumers/view?cid='+ inner.consumerID + (inner.imei ? inner.imei : "")} target="_blank" className="a-decor-none">
																	<Button type='button' text={localString('View Details')} className='button-ghost button-to-request'></Button>
																</a>
															</Table.Data>
														}
													</>
												)
											})
										}
										</Table.Row>
									)
								})
							}
						</Table.Body>
					</Table>
				</div>
				</>
			}
			{
				coverageTab && 
				<>
					{
						coverageDetails && coverageDetails.length > 0 && coverageDetails.map(coverage=>{
							return (
								<div className="plan-coverage">
								{
									coverage && coverage.map((inner)=>{
										return(
											<div className={inner.customClass ? inner.customClass + " form-group" :"form-group"}>
												<label  className="consumer-form-label">{inner.label}:</label>
												{
													<div className="">
														<p>{inner.value}</p>
													</div>
												}
											</div>
										)
									})
								}
								</div>
							)
						}) 
					}
					{
						isEmpty(coverageDetails) &&
						<div className=""><strong>{localString("No Coverages Found")}</strong></div>
					}
				</>
			}
			{
				documentTab &&
				<>
					{ 
						isDocReceived &&
						<div className=""><strong>{localString("No Documents Available")}</strong></div>
					}
					{
						documentsDetailObject.otherDocs.length > 0 && 
						<>
							<p className="attempt-title">{localString("Sold Plan Document")}</p>
							{
								documentsDetailObject.otherDocs.map((doc,i) => {
									return(	
										<div className='planManagement-documents'>
											<div className="" style={{marginLeft: "10px"}}>
												<div className="">
													<p>{i+1 +". "} <a href={doc.DocumentURL} target="_blank">{localString(doc.DocumentDisplayText)} </a></p>
												</div>
											</div>
										</div>
									)
								})
							}
						</>
					}
					{
						documentsDetailObject.firstAttempt.length > 0 && documentsDetailObject.firstAttempt.map((data) => {
							return(	
								<div className='planManagement-documents'>
									<p  className="attempt-title">{localString("Diagnostics Attempt ") + data.Attempt + localString(": (Result: ") + data.DiagnosisAttemptStatus + ")" }</p>
									{
										data.ImagesNew && data.ImagesNew.map(img=>{
											return(
												<>
													<p className='image-title'>{img.Tag}</p>
													<div className='image-view'>
														{
															img.Doc && img.Doc.map((pics,i)=>{
																return(
																	<div className='image-outer-margin'>
																		<a className="" href={pics.Link} target="_blank">
																			<div class="documents-box">
																				<div className='doc-inner-box'>
																					<i class="glyphicon glyphicon-file" style={{fontSize: "18px"}}></i>
																					<p class="field-margin"></p>
																					<p>{pics.Type}</p>
																				</div>
																			</div>
																		</a>
																	</div>
																)
															})
														}
													</div>
												</>
											)
										})
									}
								</div>
							)
						})
					}
					{
						documentsDetailObject.secondAttempt.length > 0 && documentsDetailObject.secondAttempt.map((data) => {
							return(	
								<div className='planManagement-documents'>
									<p  className="attempt-title">{localString("Diagnostics Attempt ") + data.Attempt + localString(": (Result: ") + data.DiagnosisAttemptStatus + ")" }</p>
									{
										data.ImagesNew && data.ImagesNew.map(img=>{
											return(
												<>
													<p className='image-title'>{img.Tag}</p>
													<div className='image-view'>
														{
															img.Doc && img.Doc.map((pics,i)=>{
																return(
																	<div className='image-outer-margin'>
																		<a className="" href={pics.Link} target="_blank">
																			<div class="documents-box">
																				<div className='doc-inner-box'>
																					<i class="glyphicon glyphicon-file" style={{fontSize: "18px"}}></i>
																					<p class="field-margin"></p>
																					<p>{pics.Type}</p>
																				</div>
																			</div>
																		</a>
																	</div>
																)
															})
														}
													</div>
												</>
											)
										})
									}
								</div>
							)
						})
					}
				</>
			}
			{
				paymentTable && 
				<div className="plan-management-payment-details">
					<Table isResponsive>
						<Table.Header>
							<Table.Row>
							{
								consumerDetails && consumerDetails.PlanDetails 
								&& consumerDetails.PlanDetails[0].PaymentFrequency === "UPFRONT" 
								? 	<>
										<Table.Heading>{localString("Transaction ID")}</Table.Heading>
										<Table.Heading>{localString("Transaction Date")}</Table.Heading>
										<Table.Heading>
											{localString("Transaction Amount")} ({paymentDetailArray?.PaymentDetails?.[0]?.CurrencySymbol && <span style={{display:"contents"}} dangerouslySetInnerHTML={{__html:  paymentDetailArray.PaymentDetails[0].CurrencySymbol || ""}}></span>})</Table.Heading>
									</>
								: 	<>
										<Table.Heading>{localString("Payment Gateway Transaction ID")}</Table.Heading>
										<Table.Heading>{localString("External Payment ID")}</Table.Heading>
										<Table.Heading>{localString("Billed Date")}</Table.Heading>
										<Table.Heading>{localString("Billing Cycle Date")}</Table.Heading>
										<Table.Heading>{localString("Transaction Amount")} ({paymentDetailArray?.PaymentDetails?.[0]?.CurrencySymbol && <span style={{display:"contents"}} dangerouslySetInnerHTML={{__html:  paymentDetailArray.PaymentDetails[0].CurrencySymbol || ""}}></span>})</Table.Heading>
										<Table.Heading>{localString("Grace Period End Date")}</Table.Heading>
										<Table.Heading>{localString("Transaction Status")}</Table.Heading>
									</>
							}
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{
								isEmpty(paymentDetailArray) &&
								<Table.Row>
									<Table.Data className="empty-data-message" otherProps={{colSpan:"8"}}><strong>{localString("No Data Found")}</strong></Table.Data> 
								</Table.Row>
							}
							{
								consumerDetails && consumerDetails.PlanDetails 
								&& consumerDetails.PlanDetails[0].PaymentFrequency === "UPFRONT" 
								? 	paymentDetailArray && paymentDetailArray.PaymentDetails && paymentDetailArray.PaymentDetails.map(inner=>{
										return(
											<Table.Row>
												<Table.Data>{inner.PaymentGatewayTransactionID || defaultText}</Table.Data>
												<Table.Data>{returnLocalDateTime(inner.TransactionDate, 'DD-MM-YYYY HH:mm') || defaultText}</Table.Data>
												{/* <Table.Data>{inner.TransactionAmount ?? defaultText }</Table.Data> */}
												<Table.Data>
													{inner.TransactionAmount ?  
														displayAmount(
															inner.TransactionAmount, 
															(loggedInUserSpicificCountryData?.countryConfig?.Locale?.DEFAULT || navigator?.language)
														)
														: defaultText
													}
												</Table.Data>
											</Table.Row>
										)
									})
								: 	<>
									{
										paymentDetailArray && paymentDetailArray.PaymentDetails && paymentDetailArray.PaymentDetails.map(inner=>{
											return(
												<Table.Row>
													<Table.Data>{inner.PaymentGatewayTransactionID || defaultText}</Table.Data>
													<Table.Data>{inner.ExternalPaymentID || defaultText}</Table.Data>
													<Table.Data>{returnLocalDateTime(inner.BilledDate, 'DD-MM-YYYY HH:mm')|| defaultText}</Table.Data>
													<Table.Data>{returnLocalDateTime(inner.NextBillingDate, 'DD-MM-YYYY HH:mm') || defaultText}</Table.Data>
													{/* <Table.Data>{inner.Amount ?? defaultText }</Table.Data> */}
													<Table.Data>
														{inner.Amount ?  
															displayAmount(
																inner.Amount, 
																(loggedInUserSpicificCountryData?.countryConfig?.Locale?.DEFAULT || navigator?.language)
															)
															: defaultText
														}
													</Table.Data>
													<Table.Data>{returnLocalDateTime(inner.HoldPeriodDate, 'DD-MM-YYYY HH:mm') || defaultText}</Table.Data>
													<Table.Data><span className='device-coverage-status' style={{ background: paymentStatusFunction(inner.Status).statusBackgroundColor,color: paymentStatusFunction(inner.Status).statusTextColor }}>{localString(paymentStatusFunction(inner.Status).finalStatus || defaultText)}</span></Table.Data>
												</Table.Row>
											)
										})
									}
									</>
							}
						</Table.Body>
					</Table>
					{
						<div className=''>
							{pagination.lastPage > 1 && <Pagination itemsPerPage={pagination.itemsPerPage} pages={pagination.lastPage} totalItems={pagination.totalItems} activePage={pagination.currentPage} paginate={(pageNumber) => {setCurrentPage(pageNumber)}}/>}
						</div>
					}
				</div>
			}
		</div>
	)
}
export default ConsumerDetailsPage;
