import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Dropdown, Radio } from 'sfy-react';
import Swal from "sweetalert2"; 

import { displayError, generateUUID, validateAttachments } from "../../Utility/Utils";

export default function BulkUploadStep5(props) {
	const { 
		apiCall, localString, publicUrl, templateList, setLoader, consentText, getUploadHistory, docServiceUrl,
		useDocServiceBulkUpload, authorization
	} = props;
	const qFilter = JSON.parse(props.qFilter)
	const [file, setFile] = useState(null);
	const hiddenFileInput = useRef(null);
	const [consent, setConsent] = useState(false);
	const [template, setTemplate] = useState(null);
	// const validExtensions = ['.xlsx'];
	const validExtensions = [];
	const maxFileSizeLimit = '10000000'; // Maximum File Size is 10mb
	const uploadFile = () => {
		if(validateAttachments(validExtensions, maxFileSizeLimit, file)) {
			let ext = file.name.split(".").length ? "."+file.name.split(".")[file.name.split(".").length - 1] : "";
			let key = "FEWEBA" + generateUUID() + ext;
			let reqObj = {
				Type: file.type, 
				Key: key
			};
			if (useDocServiceBulkUpload && useDocServiceBulkUpload == 'true') {
				if (docServiceUrl == '') {
					return;
				}
				let dataNew = new FormData();
				dataNew.append('file', file);
				dataNew.append('docType', 'PlansDocument');
				dataNew.append('clientRefId', key);
				dataNew.append('entity-type', 'Plans');
				dataNew.append('entity-id', qFilter.userID);
				let url = docServiceUrl + 'internal/document/upload';
				setLoader(true);
				fetch(url, {
					method: 'POST',
					headers: {
						'App': 'WebApp',
						'module': 'WebApp',
						'authorization': authorization
					},
					body: dataNew
				})
				.then(res => res.json())
				.then(result => {
					setLoader(false);
					if(result.success && result.data) {
						let reqObj = {
							DocServiceUUID: result.data.docServiceUUID,
							UserID: qFilter.userID,
							FileName: file.name,
							Source: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp",
							TemplateCode: template.TemplateCode,
							CountryCode: qFilter?.countrycode?.length ? qFilter.countrycode[0] : 'IN',
						}
						uploadBulkSoldPlanFile(reqObj);
					}else {
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(result)),  
						}); 
					}
				})
				.catch(err => {
					setLoader(false);
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(err)),  
					});
				})
			} else {
				setLoader(true);
				apiCall({
					url:'uploadDocuments',
					data: reqObj,
					callBack: (result) => {
						// setLoader(false); 
						if(result.success){
							let url = result.data;
							let attachmentLink = result.data.split('?')[0];
							fetch(url, {
								method: 'PUT',
								headers: {
									'Cache-Control': 'public,max-age=3600',
									'x-amz-acl': 'public-read'
								},
								body: file
							})
							.then(res => {
								if(res.status === 200) {
									let reqObj = {
										FileURL: attachmentLink,
										FileKey: key,
										UserID: qFilter.userID,
										FileName: file.name,
										Source: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp",
										TemplateCode: template.TemplateCode,
										CountryCode: qFilter?.countrycode?.length ? qFilter.countrycode[0] : 'IN',
									}
									uploadBulkSoldPlanFile(reqObj);
								}
							})
							.catch(() => {
								setLoader(false); 
								Swal.fire({  
									icon: 'error',  
									text: localString('Some error occurred.'),  
								}); 
							})
						}else {
							setLoader(false); 
							Swal.fire({  
								icon: 'error',  
								text: localString(displayError(result)),  
							}); 
						}
					},
					errorCB: (err) => {
						setLoader(false); 
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(err)),  
						}); 
					}
				});
			}
		}else {
			Swal.fire({  
				icon: 'error',  
				text: localString('File size should be less than 10 MB'),  
			});
		}
	}

	const uploadBulkSoldPlanFile = (reqObj) => {
		setLoader(true);
		apiCall({
			url: 'uploadBulkSoldPlanFile',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					setFile(null);
					setTemplate(null);
					setConsent(false);
					Swal.fire({  
						icon: 'info',  
						text: localString('File uploaded succesfully'),  
					}); 
					getUploadHistory()
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	return (
		<>
			<div className='step'>
				<div className='stepHeader'>{localString('Step 5: Select the template and upload the sales data')}</div>
				<div className='fileTypeHelperText'>
					{localString('Please upload the file in')} <b>{localString("'.xlsx'")}</b>{localString(" format only.")}
				</div>
				<div className='stepContent'>
					<div className='row'>
						<div className='col-xs-12 col-sm-4 col-md-3'>
							<Dropdown
								value={template && template.value}
								options={templateList}
								handleOnChange={(data) => {
									setTemplate(data);
								}}
								filterBy='value'
								showDownArrow={true}
								placeholder={'Select Template'}
							/>
						</div>
						<div className='col-xs-12 col-sm-8 col-md-9'>
							<input
								type="file"
								ref={hiddenFileInput}
								name="attachment"
								style={{ display: 'none' }}
								onChange={(e) => setFile(e.target.files[0])}
								onClick={(e) => e.target.value = ''}
								accept=".xlsx"
							/>
							<Button
								type='button'
								classes='button-small servify-button uploadBtn'
								isDisabled={!template || !template.value}
								onClick={() => hiddenFileInput.current.click()}
								title={!template ? 'Please select template' : ''}
							>
								<img 
									src={publicUrl + "/imgs/uploadIcon.png"}
									alt="Upload"
									width='20px' 
								/>
								<span style={{ paddingLeft: '4px' }}>{localString('Choose File...')}</span>
							</Button>
							{
								file && <span> 
									<span className="fileName">{ file && file.name }</span>
									<img 
										className='deleteImg' 
										src={publicUrl + "/imgs/delete-button.png"}
										alt="Delete" 
										width='13px' 
										onClick={() => {
											setFile(null);
											setConsent(false);
										}}
									/>
								</span>
							}
						</div>
					</div>
				</div>
				<div>
					<div className='consentBox'>
						<Checkbox
							value={consent}
							name='first'
							handleOnChange={(e) => {
								setConsent(!consent);
							}}
							isDisabled={!file}
						>
							{localString(consentText)}
						</Checkbox>
					</div>
					<div>
						<Button
							type='button'
							classes='button-small servify-button'
							isDisabled={!consentText || !consent || !template}
							onClick={uploadFile}
						>
							{localString('UPLOAD')}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}