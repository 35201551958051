import React,{useState, useEffect}  from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import moment from 'moment';
import { displayError,returnLocalDateTime,manipulateDate,timeConversion,downloadcsvFile, getLocalDateTime} from '../../Utility/Utils';
import { concat, groupBy,find, result } from 'lodash';
import PageHeader from '../../components/PageHeader/PageHeader';
import FilterRequestModal from './FilterRequests/FilterRequestModal';
import RequestSearch from './RequestSearch';
import FlagEscalation from '../../components/FlagRequest/FlagEscalation';
import ConsumerServiceRequestList from './RequestList/ConsumerServiceRequestList';
import ConsumerRequestTask from './RequestTask/ConsumerRequestTask';
import ConsumerServiceRequestCount from './RequestCount/ConsumerServiceRequestCount';
import './ConsumerServiceRequest.scss'
import GsxAuthTokenModal from './GsxAuthTokenTabModal/GsxAuthTokenModal';
import RunDiagnosisModal from './RunDiagnosisTabModal/RunDiagnosisModal';

const ConsumerServiceRequest = (props) => {
	const {
		accessFn, baseUrl, createBulkRequest, raiseUrl, localString, apiCall, createRequest, titleHeader, qFilter, 
		dispatchDeviceUrl, useQuery
	} = props;
	const query = useQuery();
	const imei = query.get("imei");
	const [showFilterModal , setShowFilterModal] = useState(false);
	const [showFetchGsxAuthTokenModal,setShowFetchGsxAuthTokenModal]=useState(false);
	let [requestFilterData, setRequestFilterData] = useState({
		Name : "",
		MobileNo : "",
		ReferenceID: "",
		brands:"",
		BrandName:"",
		productSubCategories:"",
		productSubCategorieName:"",
		products:"",
		productName:"",
		ImeiNumber: imei || "",
		serviceTypes:"",
		serviceTypeName:"",
		statusString:"",
		statusActualString:"",
		serviceLocations:"",
		serviceLocationName:"",
		logisticsProvider:"",
		sources:"",
		city:"",
		zipcodes:"",
		ExternalReferenceID:"",
		callStatus:"",
		callStatusName:"",
		taskStatus:"",
		IntegrationRefID:"",
		EscalationType:"",
		EscalationTypeID:"",
		gsxNumber:"",
		poNumber:"",
		engineers:"",
		engineerListData:"",
		packagingMaterialRequired:"",
		requirePackagingMaterial:"",
		countryServiceCenter:"",
		// countryServiceCenterID:"",
		state:"",
		partnerServiceLocationID:"",
		CustomerCountryID:"",
		PslCountryID:"",
		customerCountry:"",
		plans:"",
		planName:"",
		applyPlanFilterExplicitly:false,
		pslTransferredRequest:""
	});
	const [flagCSRID, setFlagCSRID] = useState()
	const [flagRequest, setFlagRequest] = useState(false)
	const [showLoader, setLoader] = useState(false);
	const [groupRequestListData, setGroupRequestListData] = useState([]);
	const [showCount, setShowCount] = useState(false);
	const [isDiagnosisFlow,setIsDiagnosisFlow] = useState(false);
	const [activeNav, setActiveNav] = useState({
		name:'inProgress',
		refreshRequestPage:false
	});
	const [currentCount, setCurrentCount] = useState(0);
	const [countriesData, setCountriesData] = useState()
	const [filterApplied,setFilterApplied] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(false);
	const [request,setRequest]=useState();
	const [hideNoDataMessage,setHideNoDataMessage]=useState(false);
	const [displayCountButton,setDisplayCountButton]=useState(true);
	const [disableApply ,setDisableApply]=useState(true);
	const [gsxUserDetails,setGsxUserDetails]=useState({});
	const [localLoader,setLocalLoader]=useState(false);
	const [pagination, setPagination] = useState({
		numberOfPages:0,
		paginationArray:[],
		itemsPerPage: 30,
		pageNo: 1,
		range: 5,
		// finalCount:1	
	});
	let [requestServiceCount,setRequestServiceCount]= useState({
		openCount : 0,
		inProgressCount : 0,
		closedCount : 0,
		claimCount : 0,
		cancelledCount : 0
	})
	const [dateRange, setDateRange] = useState({
		startDate: manipulateDate(30,"minus"),
		endDate: manipulateDate(5, "add"),
	});
	const [isFirstLoad ,setIsFirstLoad]=useState(true);
	const [openTaskRequestCount,setOpenTaskRequestCount]=useState(0);
	const [qFilterData, setqFilterData] = useState(JSON.parse(qFilter))
	let hasUnrestrictedAccess = JSON.parse(qFilter).hasUnrestrictedAccess;
	const [requirePackagingMaterial,setRequirePackagingMaterial]=useState();
	const [serviceRequestNav,setServiceRequestNav]= useState([]);
	const [hideClearButton, setHideClearButton] = useState(true);
	const [countData,setCountData]=useState([])
	const [runGSXDiagnosisModal,setRunGSXDiagnosisModal]=useState(false)
	const [disableUpdateGSXAuthToken,setDisableUpdateGSXAuthToken]=useState(true);
	const [authToken,setAuthToken]=useState("");
	const [serialNumber,setSerialNumber]= useState("");
	const [runDiagnosticsClick,setRunDiagnosticsClick]=useState({
		diagnosticsResultClick :true,
        diagnosticsSuitsClick : false
	});
	const [disableDiagnosis,setDisableDiagnosis]=useState(true)
	const [diagnosticsResult,setDiagnosticsResult]=useState({});
	const [diagnosisSuites,setDiagnosisSuites]=useState([]);
	const [arrDiagnosisSuites,setArrDiagnosisSuites]=useState([]);
	const [disableState,setDisableState] = useState({
		productDisable:true,
		stateDisable:true,
		assignEngineerDisable:true
	});
	const [countryDataList,setCountryDataList] = useState([]);
	const [stateDataList,setStateDataList]= useState([]);
	const [subcategoryData,setSubcategoryData] = useState([]);
	const [brandData,setbrandData] = useState([]);
	const [productData,setProductData] = useState([]);
	const [statusList, setStatusList] = useState([]);
	const [serviceTypes, setServiceTypes] = useState([]);
	const [logisticsProviderList, setLogisticsProviderList] = useState([]);
	const [registrationSourceList, setRegistrationSourceList] = useState([]);
	const [cityList, setCityList] = useState([]);
	const [callStatus, setCallStatus] = useState([]);
	const [escalationTypes, setEscalationTypes] = useState([]);
	const [serviceCenters, setServiceCenters] = useState([]);
	const [engineerList, setEngineerList] = useState([]);
	const [locationInput, setLocationInput] = useState();
	const [searchText, setSearchText] = useState([]);
	const [countryOfCustomerList,setCountryOfCustomerList] = useState([]);
	const [planList,setPlanList] = useState([]);
	const [taskStatusType, setTaskStatusType] = useState([{
		value:"All"
	},{
		value:"Open"
	},{
		value:"Closed"
	}])
	const [emptyBoxAvailbility,setEmptyBoxAvailbility] = useState([
		{
            value:"Yes",
			isValid:true
        },
        {
			value:"No",
			isValid:false
        }
	])

	const [transferRequestOptions, setTransferRequestOptions] = useState([
		{
            value: "Yes",
			isValid: false
        },
        {
			value: "No",
			isValid: false
		},
		{
			value: "All",
			isValid: true
		}
	]);
	let [showFilterLoader, setFilterLoader] = useState({
		showBrandLoader: false,
		showCityLoader:false,
		showProductLoader:false,
		showSubCategoryLoader:false,
		showServiceTypeLoader:false,
		showServiceCenterLoader:false,
		showStatusLoader:false,
		showLogisticLoader:false,
		showSourceLoader:false,
		showCallStatusLoader:false,
		showEscalationTypeLoader:false,
		showEngineerListLoader:false,
		showTaskLoader:false,
		showPackagingLoader:false,
		showServiceCenterCountryLoading:false,
		showServiceCenterStatesLoading:false,
		showCountryofCustomerLoading:false,
		showPlansLoading:false
	});
	const [loaded,setLoaded] = useState({
		brandLoaded:false,
		productLoaded:false,
		subCategoryLoaded:false,
		serviceTypeLoaded:false,
		serviceCentersLoaded:false,
		statusListLoaded:false,
		logisticsProviderLoaded:false,
		registrationSourceLoaded:false,
		cityListLoaded:false,
		callStatusLoaded:false,
		escalationTypeLoaded:false,
		engineerListLoaded:false,
		serviceCenterCountryLoaded:false,
		serviceCenterStatesLoaded:false,
		countryofCustomerLoaded:false,
		plansLoaded:false
	})
	let claimArray = [];
	let normalArray=[];
	const defaultMinDate = new Date("01-01-2000");
	const defaultMaxDate = manipulateDate(5, "add");
	const [minDate, setMinDate] = useState(defaultMinDate);
	const [maxDate, setMaxDate] = useState(new Date(defaultMaxDate));
	const [dateError, setDateError] = useState(false);

	useEffect(() => {
		if(dateRange?.startDate && dateRange?.endDate) {
			let date1 = new Date(dateRange?.startDate);
			let date2 = new Date(dateRange?.endDate);
			let diff = date2.getTime() - date1.getTime();
			diff = diff / (1000 * 60 * 60 * 24 * 30);
			if(diff > 6) {
				setDateError(true);
			}else {
				setDateError(false);
			}
		}		
	}, [dateRange?.startDate, dateRange?.endDate]);

	useEffect(()=>{ //On load getConsumerServiceRequestCount api is called from below code block
		if(!accessFn('RESTRICTED_FILTER_REQUEST_ACCESS') || imei) {
			if(isFirstLoad){
				getRequestData(true);
			}
		} else {
			resetRequestData();
			setIsFirstLoad(false);
		}
	},[])
	useEffect(()=>{
		if(qFilterData.serviceTypes){
			if(qFilterData.serviceTypes.indexOf(18) > -1){
				setIsDiagnosisFlow(true);
			}else{
				setIsDiagnosisFlow(false);
			}
		}
	},[qFilterData.serviceTypes])
	
	useEffect(() => {
		if(accessFn('CLAIM_REQUEST')){
			claimArray = [{
				name: 'claim',
				displayName: localString("Claim Request"),
				navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination();
				}
			}];
			// setActiveNav('claim');
			// activeNav = 'claim';
		}
		else {
			claimArray = [];
			// setActiveNav('open')
			// activeNav = 'open';
		}

		if(accessFn('REPAIR_DEVICE') && !hasUnrestrictedAccess){
		normalArray = [{
			name: 'inProgress',
			displayName: localString("In Progress"),
			navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination();
				}
			}, {
			name: 'closed',
			displayName: localString("Closed"),
			navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination();
				}
			}, {
			name: 'cancelled',
			displayName: localString("Cancelled"),
			navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination();
			}
		}];
		// activeNav = 'inProgress';
		// setActiveNav('inProgress')
		} else if(accessFn('REASSIGN_JOBS') && !hasUnrestrictedAccess) {
		normalArray = [{
			name: 'open',
			displayName: localString("Open"),
			navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination();
				}
			}, {
			name: 'inProgress',
			displayName: localString("In Progress"),
			navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination();
				}
			}, {
			name: 'closed',
			displayName: localString("Closed"),
			navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination();
			}
		}, {
			name: 'cancelled',
			displayName: localString("Cancelled"),
			navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination();
			}
		}];
	} else if(accessFn('STORE_MANAGEMENT') && !hasUnrestrictedAccess) {
		normalArray = [{
			name: 'inProgress',
			displayName: localString("In Progress"),
			navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination();
			}
		}, {
			name: 'closed',
			displayName: localString("Closed"),
			navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination();
			}
		}, {
			name: 'cancelled',
			displayName: localString("Cancelled"),
			navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination();
			}
		}];
	} else {
		normalArray = [{
			name: 'open',
			displayName: localString("Open"),
			navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination()
			}
		}, {
			name: 'inProgress',
			displayName: localString("In Progress"),
			navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination();
			}
		}, {
			name: 'closed',
			displayName: localString("Closed"),
			navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination();
			}
		}, {
			name: 'cancelled',
			displayName: localString("Cancelled"),
			navClick:(nav)=>{
				setActiveNav({...activeNav,name:nav,refreshRequestPage:true})
				resetPagination();
			}
		}];
	}

	setServiceRequestNav([...claimArray,...normalArray]);
	}, [])
	
	useEffect(()=>{
		if(qFilterData && qFilterData.countrycode) {
			getCountries();
		}
	},[qFilterData.countrycode])

	useEffect(()=>{
		if(!isFirstLoad && activeNav.refreshRequestPage===false){
			refreshRequestsBasedOnNav()
		}
	},[qFilterData,filterApplied,pagination.pageNo])//,qFilterData
	
	useEffect(()=>{
		if(activeNav.refreshRequestPage===true){
			refreshRequestsBasedOnNav();
		}
	},[activeNav.name])

	useEffect(() => {
		if(requestFilterData.ReferenceID.length > 0 && requestFilterData.ReferenceID.length <= 4){
			setButtonDisable(true)
		}else{
			setButtonDisable(false)
		}
	}, [requestFilterData.ReferenceID])
	
	useEffect(() => {
		let isEmpty = Object.values(requestFilterData).every(x => (x === null || x === ''));
		isEmpty ? setHideClearButton(true): setHideClearButton(false)
	}, [requestFilterData])

	const setCurrentPage = (pageNo) => {
		setPagination({...pagination,['pageNo']:pageNo});
	}
	const showPopOver =() =>{
		if(!accessFn('RESTRICTED_FILTER_REQUEST_ACCESS') || (accessFn('RESTRICTED_FILTER_REQUEST_ACCESS') )) {//&& !disableApply
			if(showCount || filterApplied){
				setShowCount(true);
				refreshRequestsBasedOnNav();
				//resetPagination();
			} 
			else {
				getRequestData()
				// refreshRequestsBasedOnNav();
			}
		}
    }
	
	const groupRequests = (params) => {
        let groupedObj = groupBy(params, (param) =>{
            return returnLocalDateTime(param.ScheduledDateTime ? param.ScheduledDateTime : param.CreatedDate,'DD-MM-YYYY');
        });
        let groupedArray = [];
        for (let d in groupedObj) {
            groupedArray.push({
                title: d
            });
            groupedArray = concat(groupedArray, groupedObj[d]);
        }
		setGroupRequestListData(groupedArray);
    }

	const checkExternalReferenceCheck = (e)=>{
		if (['Croma','CromaAPI','CromaCare','CromaDashboard','BoseDashboard','SkyworthDashboard','SmartTechnoDashboard','SingularityDashboard'].indexOf(e) > -1) {
			return true;
		}
		else {
			return;
		}
	}
	
	
	let returnCounts = (name) =>{
        switch (name) {			
            case 'claim':
                return requestServiceCount.claimCount;
                break;
            case 'open':
                return requestServiceCount.openCount
                break;
            case 'inProgress':
                return requestServiceCount.inProgressCount;
                break;
            case 'closed':
                return requestServiceCount.closedCount;
                break;
            case 'cancelled':
                return requestServiceCount.cancelledCount;
                break;
        }
		
    };

	const refreshRequestsBasedOnNav = (clearDateRange = false) =>{
		
        // $('#filterrequests').modal('hide');
        // intializeGraph();
        getRequestCounts({qFilter: qFilterData}, function(err, result) {
            if(err) {}
            else {
				setRequestServiceCount((prevFilterData) => {
					return {
						...prevFilterData,
						openCount :result.data && result.data.open ? result.data.open :0 ,
						inProgressCount : result.data && result.data.inProgress ? result.data.inProgress:0, 
						closedCount : result.data && result.data.closed ? result.data.closed :0 ,
						claimCount : result.data && result.data.claim ? result.data.claim  :0,
						cancelledCount :result.data && result.data.cancelled ? result.data.cancelled :0 
					};
				});
                // RequestCount = result.data.open ? result.data.open : 0;
                let totalRequestCount = result.data && result.data.totalCount;
				qFilterData.status = activeNav.name;
				let data = result.data;
				setCountData( result.data);
                let requestObj = {
                    qFilter: qFilterData,
                    pagination: {
						itemsPerPage: pagination.itemsPerPage,
						pageNo: pagination.pageNo,
						range: pagination.range,
					}
                }
				
                if (accessFn('TASK_MANAGEMENT')) {
                    let openTaskRequestCount = (result && result.data && result.data.tasks) ? (result.data.tasks) : 0
					setOpenTaskRequestCount(openTaskRequestCount);
					//isTaskCountFetched = true
                }
                if (result.data && result.data.paginationData) {
					setPagination((prevFilterData) => {
						return {
							...prevFilterData,
							paginationArray : result.data.paginationData.pagination,
							numberOfPages : result.data.paginationData.numberOfPages,
							currentPage : result.data.paginationData.currentPage,
							range : result.data.paginationData.range,
						};
					});
                } else {
					setIsFirstLoad(true);
					setPagination([])
                    //paginationArray = [];
                }
                getRequests(requestObj,data, function(err, result) {
                    if (err) {
                        //swal( '', 'Oops... Something went wrong!', 'error' );
                    } else {
                        if (result.success) {
							setCurrentCount(result.data && result.data.count);
							setRequest(result.data && result.data.consumerServiceRequests)
                            let requests = result.data && result.data.consumerServiceRequests;
                            groupRequests(requests);
							
							if (result.data && result.data.paginationData) {  //set pagination because when count chage pagination not show
								setPagination((prevFilterData) => {
									return {
										...prevFilterData,
										paginationArray : result.data.paginationData.pagination,
										numberOfPages : result.data.paginationData.numberOfPages,
										currentPage : result.data.paginationData.currentPage,
										range : result.data.paginationData.range,
									};
								});
							} else {
								setIsFirstLoad(true);
								setPagination([])
								//paginationArray = [];
							}
							setLocalLoader(false)
                        } else {
                            //swal( '', localString(displayError(result)), 'error' );
                        }
                    }
                    if(totalRequestCount === 1 && filterApplied) {
						setIsFirstLoad(true);
						let url = baseUrl+'/servicerequests/view?csrid='+result.data.consumerServiceRequests[0].ConsumerServiceRequestID;
						window.open(url, '_blank');
						setFilterApplied(false)
                    }
                }, clearDateRange);
            }
        }, clearDateRange);
    };
	const getRequestData = (firstLoad, clearDateRange = false)=>{
		// setShowCount(true);
        if(!firstLoad || !qFilterData.hasUnrestrictedAccess==true ){
			lazyLoadCount(clearDateRange);
		}
        // $('#filterrequests').modal('hide');
        let requestObj = {
            qFilter: qFilterData,
            pagination: {
				itemsPerPage: pagination.itemsPerPage,
				pageNo: pagination.pageNo,
				range: pagination.range,
			}
		}
		if(firstLoad) {
			requestObj.qFilter.ImeiNumber = imei;
		}

        getRequests(requestObj,countData, function(err, result) {
            if (err) {
            } else {
                if (result.success) {
					setCurrentCount( result.data && result.data.count)
					setRequest(result.data && result.data.consumerServiceRequests)
                    let requests =  result.data && result.data.consumerServiceRequests;
                    groupRequests(requests);
					setLocalLoader(false);
					if(firstLoad && imei && (result?.data?.count === 1)) {
						let url = baseUrl+'/servicerequests/view?csrid='+result?.data?.consumerServiceRequests?.[0]?.ConsumerServiceRequestID;
						window.open(url, '_blank');
					}
                } else {
                    //swal( '', localString(displayError(result)), 'error' );
                }
            }
        }, clearDateRange, firstLoad);
    }

	const lazyLoadCount = (clearDateRange) => {
        // intializeGraph();
		
        getRequestCounts({qFilter: qFilterData}, function(err, result) {
            if(err) {}
            else {
				setRequestServiceCount((prevFilterData) => {
					return {
						...prevFilterData,
						openCount :result.data && result.data.open ? result.data.open :0 ,
						inProgressCount : result.data && result.data.inProgress ? result.data.inProgress:0, 
						closedCount : result.data && result.data.closed ? result.data.closed :0 ,
						claimCount : result.data && result.data.claim ? result.data.claim  :0,
						cancelledCount :result.data && result.data.cancelled ? result.data.cancelled :0 
					};
				});
                // RequestCount = result.data.open ? result.data.open : 0;
				let totalRequestCount =result.data && result.data.totalCount;
                qFilterData.status = activeNav.name;
                if (result.data && result.data.paginationData) {
					setPagination((prevFilterData) => {
					return {
						...prevFilterData,
						paginationArray : result.data.paginationData.pagination,
						numberOfPages : result.data.paginationData.numberOfPages,
						currentPage : result.data.paginationData.currentPage,
						range : result.data.paginationData.range,
					};
				});
                } else {
					setPagination([])
                    // paginationArray = [];
                }
                if (accessFn('TASK_MANAGEMENT')) {
					let openTaskRequestCount = (result && result.data && result.data.tasks) ? (result.data.tasks) : 0
					setOpenTaskRequestCount(openTaskRequestCount)
                    // isTaskCountFetched = true
                }
                if(requestServiceCount.inProgressCount === 0){
                    showCount(true);
					setActiveNav({...activeNav,name:'inProgress',refreshRequestPage:false})
                }
            }
        }, clearDateRange);
    }
	
	const getRequestCounts = (params, countCallBack, clearDateRange)=> {
		let dateRangeVal = {...dateRange};
		if(clearDateRange) {
			dateRangeVal.startDate = manipulateDate(30,"minus");
			dateRangeVal.endDate = manipulateDate(5, "add");
			setDateRange(dateRangeVal);
		}
		params.qFilter.startDate = (params.qFilter.ReferenceID || params.qFilter.ImeiNumber) ? undefined : getLocalDateTime(dateRangeVal.startDate,"DD-MM-YYYY");
        params.qFilter.endDate = (params.qFilter.ReferenceID || params.qFilter.ImeiNumber) ? undefined : getLocalDateTime(dateRangeVal.endDate,"DD-MM-YYYY");
		params.pagination = {
			itemsPerPage: pagination.itemsPerPage,
			pageNo: pagination.pageNo,
			range: pagination.range,
		}
		if (qFilterData.brands) {
            params.qFilter.brands = qFilterData.brands;
        }
		if (qFilterData.statusString) {
            params.qFilter.statusString = qFilterData.statusActualString;
        }
        if (qFilterData.serviceLocations) {
            params.qFilter.serviceLocations = qFilterData.serviceLocations;
        }
		
		switch (activeNav.name) {
            case 'claim':
                params.qFilter.status = activeNav.name;
                break;
            case 'open':
                params.qFilter.status = activeNav.name;
                break;
            case 'inProgress':
                params.qFilter.status = activeNav.name;
                break;
            case 'closed':
                params.qFilter.status = activeNav.name;
                break;
            case 'cancelled':
                params.qFilter.status = activeNav.name;
                break;
        }
		if( showCount || filterApplied){
            params.qFilter.status = activeNav.name;
        }else{
            params.qFilter.status = activeNav.name;
        }
        setLoader(true);
		apiCall({
			url:'getFilteredConsumerServiceRequestCount',
			data: params,
			// showLoader: lazyLoad?false:true,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					if(result.data && result.data.activeNav  ) { //used this condition to check 2times api call or not
							setActiveNav((prevFilterData) => {
							return {
								...prevFilterData,
								name: result.data &&result.data.activeNav,
								refreshRequestPage:false
							};
						});
						
					}
				
				setIsFirstLoad(false);
                countCallBack(null, result);
				
				}else{
					Swal.fire({
						title: '',
						icon:'error',
						text: localString(displayError(result)),
						type: 'error',
						confirmButtonText: localString('OK'),
						cancelButtonText: localString('Cancel')
					});
				}
			}
        });
    };

	const getRequests = (params, data, requestCallBack, clearDateRange, firstLoad) => {
		setLocalLoader(true);
		let dateRangeVal = {...dateRange};
		if(clearDateRange) {
			dateRangeVal.startDate = manipulateDate(30,"minus");
			dateRangeVal.endDate = manipulateDate(5, "add");
			setDateRange(dateRangeVal);
		}
        params.qFilter.startDate = (params.qFilter.ReferenceID || params.qFilter.ImeiNumber)? undefined : getLocalDateTime(dateRangeVal.startDate,"DD-MM-YYYY");
        params.qFilter.endDate = (params.qFilter.ReferenceID || params.qFilter.ImeiNumber) ? undefined : getLocalDateTime(dateRangeVal.endDate,"DD-MM-YYYY");
		
		if (qFilterData.brands) {
            params.qFilter.brands = qFilterData.brands;
        }
		if (qFilterData.serviceLocations) {
            params.qFilter.serviceLocations = qFilterData.serviceLocations;
        }
		
        if(showCount || filterApplied){
            setShowCount(true)
			if(!data.activeNav){
				params.qFilter.status = activeNav.name;
			}else {
				params.qFilter.status = data.activeNav
			}
        }else{
            params.qFilter.status =  activeNav.name;
        }

		switch(data.activeNav){
			case 'claim':
                params.qFilter.requestCount= data.claim;
                break;
            case 'open':
				params.qFilter.requestCount=  data.open	
                break;
            case 'inProgress':
				params.qFilter.requestCount= data.inProgress	;
                break;
            case 'closed':
				params.qFilter.requestCount= data.closed;
                break;
            case 'cancelled':
				params.qFilter.requestCount= data.cancelled	;
                break;
		}

		if(imei && firstLoad) delete params.qFilter.status;
		
		apiCall({
			url:'getFilteredConsumerServiceRequest',
			data: params,
			callBack:(result)=>{
				if(result.success){
					requestCallBack(null, result); 
					setIsFirstLoad(false);
				}else{
					Swal.fire({
						title: '',
						icon:'error',
						text: localString(displayError(result)),
						type: 'error',
						confirmButtonText: localString('OK'),
						cancelButtonText: localString('Cancel')
					});
				}
			}
        });
    };

	const resetPagination = () =>{
		setPagination({...pagination,
			pageNo: 1,
            range: 5,
            itemsPerPage: 30
		})
    }

	const resetRequestData =()=>{
		setGroupRequestListData([]);
		setShowCount(false);
		setDisplayCountButton(false)
		setHideNoDataMessage(true);
	}

	const closeModal = () =>{
		setShowFilterModal(false);
	}
	
	const openflagrequest = (e) => {
		setFlagCSRID(e.ConsumerServiceRequestID)
		setFlagRequest(true);
    }

	const applyFilter = (e) =>{
		if(e){
			e.preventDefault();
		}
		if(dateError && !requestFilterData?.ReferenceID) {
			Swal.fire({
				icon: 'error',
				text: localString('Please select a date range within 6 months'),
			});
			return;
		}
		setFilterApplied(true);
		setDisplayCountButton(true);
		setHideNoDataMessage(false)
		let filterObject =  Object.entries(requestFilterData).reduce((data,[key,val]) => (val == "" ? data : (data[key]=val, data)), {});
		for(let i in filterObject){
			if(i === "brandName"){
				delete filterObject['brandName'];
			}
			if(i === "productSubCategorieName"){
				delete filterObject['productSubCategorieName']
			}
			if(i === "productName"){
				delete filterObject['productName']
			}
			if(i === "serviceTypeName"){
				delete filterObject['serviceTypeName']
			}
			// if(i === "EscalationTypeName"){
			// 	delete filterObject['EscalationTypeName']
			// }
			if(i === "serviceLocationName"){
				delete filterObject['serviceLocationName']
			}
			if(i === "engineerList"){
				delete filterObject['engineerList']
			}
			if(i=== 'packagingMaterialRequired'){
				delete filterObject['packagingMaterialRequired']
			}
			if(i==='countryServiceCenter'){
				delete filterObject['countryServiceCenter']
			}
			if(i==='customerCountry'){
				delete filterObject['customerCountry']
			}
			if(i==='planName'){
				delete filterObject['planName']
			}
		}
		if(requestFilterData){
			let defaultFilter = JSON.parse(qFilter)
			if (requestFilterData.packagingMaterialRequired !== '') {
				defaultFilter.requirePackagingMaterial = requirePackagingMaterial;
			}
			setqFilterData({...defaultFilter,...filterObject})
		}
		setShowCount(true)
		setShowFilterModal(false)
		resetPagination();
	}

	const clearButtonFunction = () => {
		setRequestFilterData({
			Name: "",
			MobileNo: "",
			ReferenceID: "",
			brands:"",
			BrandName:"",
			productSubCategories:"",
			productSubCategorieName:"",
			products:"",
			productName:"",
			ImeiNumber:"",
			serviceTypes:"",
			serviceTypeName:"",
			statusString:"",
			statusActualString:"",
			serviceLocations:"",
			serviceLocationName:"",
			logisticsProvider:"",
			sources:"",
			city:"",
			zipcodes:"",
			ExternalReferenceID:"",
			callStatus:"",
			callStatusName:"",
			taskStatus:"",
			IntegrationRefID:"",
			EscalationType:"",
			EscalationTypeID:"",
			gsxNumber:"",
			poNumber:"",
			engineers:"",
			engineerList:"",
			packagingMaterialRequired:"",
			countryServiceCenter:"",
			state:"",
			customerCountry:"",
			engineerListData:"",
			EscalationType:"",
			serviceLocations:"",
			planName:"",
			applyPlanFilterExplicitly:false,
			pslTransferredRequest:""
		})
		// setDisableState({...disableState, productDisable:true,stateDisable:true,assignEngineerDisable:true});
		setDisableState(true);
		setShowFilterModal(false);
		if(accessFn('RESTRICTED_FILTER_REQUEST_ACCESS')){
            resetPagination();
			resetRequestData()
        }else{
			let defaultFilter = JSON.parse(qFilter)
			qFilterData.ServiceTypeID = defaultFilter? defaultFilter.ServiceTypeID: undefined
			qFilterData.serviceTypes = defaultFilter? defaultFilter.serviceTypes: undefined;
			qFilterData.ProductSubCategoryID = defaultFilter?defaultFilter.ProductSubCategoryID: undefined;
			qFilterData.ProductID = defaultFilter ? defaultFilter.ProductID: undefined;
			qFilterData.requirePackagingMaterial = undefined;
			qFilterData.pslTransferredRequest = undefined;

			qFilterData.Name = undefined;
			qFilterData.MobileNo = undefined;
			qFilterData.IntegrationRefID = undefined;
			qFilterData.ExternalReferenceID = undefined;
			qFilterData.ImeiNumber = undefined;
			qFilterData.ReferenceID = undefined;
			qFilterData.brands = defaultFilter ? defaultFilter.brands: undefined;

			qFilterData.productSubCategories = defaultFilter?defaultFilter.productSubCategories: undefined;;
			qFilterData.products = defaultFilter?defaultFilter.products: undefined;;
			qFilterData.statusString = undefined;
			qFilterData.statusActualString = undefined;
			qFilterData.serviceCenterName = undefined;
			qFilterData.logisticsProvider = undefined;
			qFilterData.sources = undefined;
			qFilterData.city = undefined;
			qFilterData.zipcodes = undefined;
			qFilterData.ExternalReferenceID = undefined;
			qFilterData.callStatus = undefined;
			qFilterData.taskStatus = undefined
			qFilterData.gsxNumber = undefined;
			qFilterData.poNumber = undefined;
			qFilterData.engineers = undefined;
			qFilterData.state = undefined;
			qFilterData.PslCountryID = undefined;
			qFilterData.CustomerCountryID = undefined;
			qFilterData.countryServiceCenter = undefined;
			qFilterData.customerCountry = undefined;
			qFilterData.engineerListData = undefined;
			qFilterData.EscalationType = undefined;
			qFilterData.serviceLocations = undefined;
			qFilterData.planName = undefined;
			qFilterData.plans= undefined;
			qFilterData.applyPlanFilterExplicitly= false;
			resetPagination()
			if(showCount){
				refreshRequestsBasedOnNav(true);
				setDisableState({...disableState, productDisable:true,stateDisable:true,assignEngineerDisable:true});
			}
			else{
				getRequestData(undefined, true);
				setDisableState({...disableState, productDisable:true,stateDisable:true,assignEngineerDisable:true});
			}
        }
	}

	const getCountries = (countryCode = null) => {
		setLoader(true);
		apiCall({
			url:'getCountries',
			data: {
				specificCountryCode: countryCode ? countryCode : qFilterData && qFilterData.countrycode ? qFilterData.countrycode[0] : "IN",
				countryConfig: true
			},
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let countryInfo = result.data && result.data.countries[0];
					setCountriesData(countryInfo)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}
	const getRequestsDownload = (params, requestCallBack) => {
		if (qFilterData.brands) {
            params.qFilter.brands = qFilterData.brands;
        }
        if (qFilterData.serviceLocations) {
            params.qFilter.serviceLocations = qFilterData.serviceLocations;
        }
		setLoader(true);
		apiCall({
			url:'getFilteredConsumerServiceRequestForDownload',
			data: params,
			callBack:(result)=>{
				if(result.success){
					setLoader(false);
					requestCallBack(null, result);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const fetchGSXAuthToken = () =>{
		setDisableUpdateGSXAuthToken(true);
		getGSXUserDetails();
    };
	const closeGSXAuthTokenModal = ()=>{
		setAuthToken("")
		setDisableUpdateGSXAuthToken(true);
		setShowFetchGsxAuthTokenModal(false);
    }

	const cancelClick=(e)=> {
		setShowFetchGsxAuthTokenModal(false);
    }
	const getGSXUserDetails =  () =>{
		setLoader(true);
		let data = {};
		apiCall({
			url:'getGSXUserDetails',
			data: data,
			callBack:(result)=>{
				if(result.success){
					setLoader(false);
					setGsxUserDetails(result.data);
					setShowFetchGsxAuthTokenModal(true);
					// $('#fetch-gsx-auth-token').modal('show');
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const updateGSXAuthToken =  (e)=> {
		e.preventDefault();
		let swaltitle = localString("Are you sure")+'?';
        Swal.fire ( {
			title: swaltitle,
			showCancelButton: true,
			closeOnConfirm: false,
			allowEscapeKey: false,
			allowOutsideClick: false,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("OK")
        }).then( function (isConfirm){
			if (isConfirm) {
				setLoader(true);
				let data = {
					"gsxAuthenticationToken": authToken
				}
				apiCall({
				url:'forceAuthenticate',
				data: data,
				callBack:(result)=>{
					setLoader(false);
					if(result.success){
						Swal.fire ({
							title: '',
							icon:"success",
							text: localString(displayError(result)),
							type: 'success',
							confirmButtonText: localString('OK')
						});
					}else{
						Swal.fire({
							title: '',
							icon:"error",
							text: localString(displayError(result)),
							type: 'error',
							confirmButtonText: localString('OK')
						});
					}
				}
				});
				Swal.close();
				closeGSXAuthTokenModal();
			} else {
            return
			}
        })
    };


	const fetchDiagnosisResults = ()=>{
		setLoader(true);
		setRunDiagnosticsClick({...runDiagnosticsClick,diagnosticsResultClick:true,diagnosticsSuitsClick :false})
        let data = {
            "serialNumber":serialNumber
		}
		apiCall({
			url:'fetchDiagnosticsData',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let diagnosticsResult = result.data;
					setDiagnosticsResult( result.data);
					if(diagnosticsResult && diagnosticsResult.diagnosticsEventNumber){
							//pass
						}else{
							Swal.fire({
								title: '',
								text: localString(result.msg),
								icon: 'error',
								confirmButtonText: localString('OK')
							});
						}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const fetchDiagnosisSuits = () =>{
		setLoader(true);
		setRunDiagnosticsClick({...runDiagnosticsClick,diagnosticsResultClick:false,diagnosticsSuitsClick :true})
        let data = {
            "SerialNumber": serialNumber
        };
		apiCall({
			url:'fetchDiagnosticsSuites',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					if(result.data && result.data.length > 0) {
						setDiagnosisSuites(result.data)
						// result.data && result.data.filter(item=>{
						// 	setDiagnosisSuites(item.suiteName);
						// })
						setArrDiagnosisSuites(result.data);
					}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const initiateDiagnosis=(suiteName)=>{
		let suit = suiteName;
        let diagnosisType, diagnosisData
        diagnosisType = 'suite'
        diagnosisData = find(diagnosisSuites, function (o) {
            if (o.suiteName == suit) {
                return o
            }
        })
        let data = {
            "SerialNumber": serialNumber,
            "DiagnosisType": diagnosisType,
            "DiagnosisData": diagnosisData,
            "currentUserID":qFilterData.userID,
            "userRole":getCurrentRole()
        }
		apiCall({
			url:'initiateGSXDiagnosis',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					if (result.data && result.data.diagnosticConsoleURL) {
						window.open(result.data.diagnosticConsoleURL, '_blank')
					}else{
						Swal.fire ({
                            title: '',
							icon:'success',
                            text: localString(result.msg),
                            type: 'success',
                            confirmButtonText: localString('OK')
                        });
                    }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}
	const refreshModal = ()=>{
		setSerialNumber("")
		setRunDiagnosticsClick({...runDiagnosticsClick,diagnosticsResultClick:false,diagnosticsSuitsClick:false});
        setDisableDiagnosis(true);
    }
	const setDownloadData = (data, isEnquiry, requesttype) =>{	// neet to study on this
		let data_array =[];
		let obj = {};
		if (isEnquiry) {
            for (let i = 0; i < data.length; i++) {
                let statusTime = 0;
                let jobAge = 0;
                let jobAgeFinal = 0;
                obj = {};
                statusTime = data[i]['MAX(consumer_servicerequest_log.StatusDate)'];
                let duration = moment.duration(moment().diff(moment(statusTime)));
                let tat = timeConversion(duration);
                jobAge = moment.duration(moment().diff(moment(data[i].CreatedDate)));
                jobAgeFinal = timeConversion(jobAge);
                obj['Reference ID'] = data[i].ReferenceID ? data[i].ReferenceID : '';
                obj['Incident ID'] = data[i].ExternalReferenceID ? data[i].ExternalReferenceID : 'NA';
                obj['Customer Name'] = data[i].Name ? data[i].Name : '';
                obj['Mobile No'] = data[i].MobileNo ? data[i].MobileNo : '';
                obj['Product Name'] = data[i].ProductName ? data[i].ProductName : '';
                obj['Model'] = data[i].Model ? data[i].Model : '';
                obj['IMEI Number/Serial No'] = data[i].ProductUniqueID ? data[i].ProductUniqueID + '' : '';
                obj['Status'] = data[i].Status ? data[i].Status : '';
                obj['Alternate IMEI'] = data[i].AlternateUniqueKey ? data[i].AlternateUniqueKey + '' : data[i].AlternateUniqueKey;
                obj['Request Created Date'] = moment(data[i].CreatedDate).format('MM/DD/YYYY HH:mm');
                obj['Customer Email'] = data[i].EmailID ? data[i].EmailID : '';
                obj['Job Age(HH:MM:SS)'] = jobAgeFinal;
                obj['Tat For Current Status'] = tat;
                obj['Call Status'] = data[i].CallStatus ? data[i].CallStatus : 'NA';
                obj['Is Device DOA'] = data[i].DOAStatus ? 'YES' : 'NO';
                obj['Order ID'] = data[i].OrderID ? data[i].OrderID : '-';
                obj['Work Order ID'] = data[i].WorkOrderID ? data[i].WorkOrderID : '-';
                obj['Date Of Purchase'] = data[i].DateOfPurchase ? data[i].DateOfPurchase : '-';
                obj['Resolution Category'] = data[i].ResolutionCategory ? data[i].ResolutionCategory : '-';
                obj['Resolution Remarks'] = data[i].ResolutionRemarks ? data[i].ResolutionRemarks : '-';
                obj['Device Issue'] = data[i].IssueText ? data[i].IssueText : '-';
                obj['Device Issue Remarks'] = data[i].IssueRemark ? data[i].IssueRemark : '-';
                data_array.push(obj);
            };
        }else {
            for (let i = 0; i < data.length; i++) {
                let statusTime = 0;
                let jobAge = 0;
                let jobAgeFinal = 0;
                obj = {};
                statusTime = data[i]['MAX(consumer_servicerequest_log.StatusDate)'];
                let duration = moment.duration(moment().diff(moment(statusTime)));
                let tat = timeConversion(duration);
                jobAge = moment.duration(moment().diff(moment(data[i].CreatedDate)));
                jobAgeFinal = timeConversion(jobAge);
                obj.ReferenceID = data[i].ReferenceID ? data[i].ReferenceID : '';
                obj['Incident ID'] = data[i].ExternalReferenceID ? data[i].ExternalReferenceID : 'NA';
                obj['External ReferenceID'] = data[i].IntegrationRefID ? data[i].IntegrationRefID : 'NA';
                obj.CustomerName = data[i].Name ? data[i].Name : '';
                obj.MobileNo = data[i].MobileNo ? data[i].MobileNo : '';
                obj.ProductName = data[i].ProductName ? data[i].ProductName : '';
                obj.Model = data[i].Model ? data[i].Model : '';
                obj['IMEINumber/SerialNo'] = data[i].ProductUniqueID ? data[i].ProductUniqueID + '' : '';
                obj['Service Type'] = data[i].Type ? data[i].Type : '';
                obj['Status'] = data[i].Status ? data[i].Status : '';
                obj['ScheduledDateTime'] = data[i].ScheduledDateTime ? moment(data[i].ScheduledDateTime).format('MM/DD/YYYY HH:mm') : 'NA';
                obj['Status Created Date'] = moment(statusTime).format('MM/DD/YYYY HH:mm');
                obj['Total Amount'] = data[i].TotalBilledAmount ? data[i].TotalBilledAmount : '';
                obj.ProductSubCategory = data[i].ProductSubCategory ? data[i].ProductSubCategory : '';
                obj['Alternate IMEI'] = data[i].AlternateUniqueKey ? data[i].AlternateUniqueKey + '' : data[i].AlternateUniqueKey;
                obj['Request Created Date'] = moment(data[i].CreatedDate).format('MM/DD/YYYY HH:mm');
                obj['Request Type'] = requesttype ? requesttype : '';
                obj['Source'] = data[i].Source ? data[i].Source : '';
                obj['Customer Address'] = data[i].CustomerAddress ? data[i].CustomerAddress : '';
                obj['Customer Email'] = data[i].EmailID ? data[i].EmailID : '';
                obj['Plan Name'] = data[i].PlanName ? data[i].PlanName : 'NA';
                obj['Fulfiller Name'] = data[i].FulfillerName ? data[i].FulfillerName : 'NA';
                obj['Landmark'] = data[i].Landmark ? data[i].Landmark : '';
                obj['Zipcode'] = data[i].Zipcode ? data[i].Zipcode : '';
                obj['Secret Code'] = data[i].RequestRemarks && typeof JSON.parse(data[i].RequestRemarks) === 'object' ? JSON.parse(data[i].RequestRemarks).VerificationCode : '';
                obj['Waybill Number'] = data[i].RequestRemarks && typeof JSON.parse(data[i].RequestRemarks) === 'object' ? JSON.parse(data[i].RequestRemarks).waybill : '';
                obj['Brand Name'] = data[i].BrandName ? data[i].BrandName : '';
                obj['Is Under Warranty'] = data[i].IsUnderWarranty === 1 ? 'Yes' : 'No';
                obj['Partner Name'] = data[i].NameOfFirm ? data[i].NameOfFirm : '';
                obj['Service Location Name'] = data[i].ServiceLocationName ? data[i].ServiceLocationName : '';
                obj['Engineer Name'] = data[i].EngineerName ? data[i].EngineerName : '';
                obj['Service Location City'] = data[i].ServiceLocationCity ? data[i].ServiceLocationCity : '';
                obj['Service Location State'] = data[i].ServiceLocationState ? data[i].ServiceLocationState : '';
                obj['Service Location Region'] = data[i].ServiceLocationRegion ? data[i].ServiceLocationRegion : '';
                obj['Job Age(HH:MM:SS)'] = jobAgeFinal;
                obj['Tat For Current Status'] = tat;
                obj['Rating'] = data[i].Rating ? data[i].Rating : '';
                obj['Rating Date'] = data[i].UpdatedDate ? data[i].UpdatedDate : '';
                obj['Feedback'] = data[i].Feedback ?  data[i].Feedback : '';
                obj['Log Status'] = data[i].LogStatus ? data[i].LogStatus : '';
                obj['Inward Date'] = data[i].InwardDate ? moment(data[i].InwardDate).format('MM/DD/YYYY HH:mm') : '';
                obj['Outward Date'] = data[i].OutwardDate ? moment(data[i].OutwardDate).format('MM/DD/YYYY HH:mm') : '';
                obj['Call Status'] = data[i].CallStatus ? data[i].CallStatus : 'NA';
                obj['Is Device DOA'] = data[i].DOAStatus ? 'YES' : 'NO';
                obj['Order ID'] = data[i].OrderID ? data[i].OrderID : '-';
                obj['Work Order ID'] = data[i].WorkOrderID ? data[i].WorkOrderID : '-';
                obj['Date Of Purchase'] = data[i].DateOfPurchase ? data[i].DateOfPurchase : '-';
                data_array.push(obj);
            };
        }
		return data_array;
	}
	const downloadRequests = (event)=> {
		let navNames = serviceRequestNav && serviceRequestNav.map(nav=>({name:nav.name}));
		let serviceRequestNavs = navNames;
        let filter = {};
        filter.qFilter = qFilterData;
        let count = 0;
        let obj = {};
        obj.data = [];
        let isEnquiry = false;
        if (filter.qFilter && filter.qFilter.serviceTypes && filter.qFilter.serviceTypes.indexOf(18) > -1 && filter.qFilter.FeatureRights && filter.qFilter.FeatureRights.indexOf(903) > -1) {
            isEnquiry = true;
        }
        for (let i = 0; i < serviceRequestNavs.length; i++) {
            if (['closed','cancelled'].indexOf(serviceRequestNavs[i].name) < 0 || isEnquiry) {
                filter.qFilter.status = serviceRequestNavs[i].name;
                getRequestsDownload(filter, function(err, result) {
                    count++;
                    let downloadata = setDownloadData(result.data.consumerServiceRequests, isEnquiry, result.data.status);
                    obj.data = concat(obj.data, downloadata);
                    if ( (count === serviceRequestNavs.length - 2 && !isEnquiry) || (count === serviceRequestNavs.length && isEnquiry)) {
                        obj.fileName = 'service-requests ' + moment().format('DD-MM-YYYY HH:mm');
                        downloadcsvFile(obj);
                    }
                });
            }
            //event.stopPropagation();
        };
    }

	const downloadCompletedRequests = () => {
		let data = {};
		let brands = qFilterData.brands;
		let countrycode = qFilterData.countrycode;
		if (brands && brands.length == 1 && brands[0] == 114) {
			data = {
				ReportType: 'RealMeReport'
			};
		} else {
			if (countrycode && countrycode.length == 1 && countrycode[0] == "CN") {
				data = {
					ReportType: 'OnePlusChinaReport'
				};
			}
			else {
				data = {
					ReportType: 'OnePlusReport'
				};
			}
		}
		setLoader(true);
		apiCall({
			url:'getReports',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					window.open(result.data.Path, "_blank");
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	function getCurrentRole() {
        let role;
        if (qFilterData) {
                if (qFilterData.hasUnrestrictedAccess) {
                        role = 'Superadmin';
                } else {
                        switch (true) {
                        case (accessFn('ACCEPT_RETURN_DEVICE')):
                                role = 'CustomerCare';
                                break;
                        case (accessFn('STORE_MANAGEMENT')):
                                role = 'StoreManager';
                                break;
                        case (accessFn('REASSIGN_JOBS')):
                                role = 'Supervisor';
                                break;
                        case (accessFn('REPAIR_DEVICE')):
                                role = 'ServicelocationEngineer';
                                break;
                        case (accessFn('QC_DEVICE')):
                                role = 'QCEngineer';
                                break;
                        }
                        return role;
                }
        }
        return role;
	}

	const closeGSXDiagnosisModal = ()=>{
        setRunGSXDiagnosisModal(false);
        refreshModal();
    }
	const buttonHeader = [{
			buttonText:localString("Filter Requests"),
			btnIcon:"glyphicon glyphicon-filter",
			showButton:(accessFn('REQUEST_LIST_VIEW') && !accessFn('RESTRICTED_FILTER_REQUEST_ACCESS')),
			onButtonClick:()=>{
				setShowFilterModal(true);
			}
		},
		{
			buttonText:localString("Create Bulk Request"),
			btnIcon:"glyphicon glyphicon-user",
			showButton:(accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_DROPOFF') || accessFn('VIEW_QUEUE_SH') || accessFn('CREATE_LOGISTICS_BULK_REQUEST')) && !(accessFn('RECYCLE_ADMIN_VIEW_ONLY')),
			onButtonClick:()=>{
				let url = baseUrl+createBulkRequest;
				window.location.replace(url);
			}
		},
		{
			buttonText:localString("Raise a request"),
			showButton:accessFn( 'REQUEST_RAISE'),
			btnIcon:"glyphicon glyphicon-plus",
			onButtonClick: () => {
				let url = baseUrl+raiseUrl;
				window.location.replace(url);
			},
		},
		{
			buttonText:localString("Create Request"),
			btnIcon:"glyphicon glyphicon-plus",
			showButton:accessFn('CREATE_SERVICE_REQUEST'),
			onButtonClick: () => {
				let url =baseUrl+createRequest;
				window.location.replace(url);
			},
		},
		{
			buttonText:localString("Dispatch Mail In Devices"),
			btnIcon:"glyphicon glyphicon-user",
			showButton:accessFn('DISPATCH_DEVICE_TO_RC'),
			onButtonClick:()=>{
				let url =baseUrl+dispatchDeviceUrl;
				window.location.replace(url);
			}
		},
		{
			buttonText:localString("Enter Activation Token"),
			btnIcon:"glyphicon glyphicon-pencil",
			showButton:accessFn('SHOW_UPDATE_GSX_AUTH_TOKEN'),
			onButtonClick:()=>{
				fetchGSXAuthToken()
			}
		},
		{
			buttonText:localString("Run Diagnosis"),
			btnIcon: false,
			showButton:accessFn('GSX_SUITS_DIAGNOSIS'),
			onButtonClick:()=>{
				setRunGSXDiagnosisModal(true);
			}
		},
	];

	const refreshRequests = ()=>{
        if(showCount || filterApplied){
			setShowCount(true)
            refreshRequestsBasedOnNav();
        }else{
			getRequestData()
			// refreshRequestsBasedOnNav();

			// setFlagRequest(true)
        }
		// setFlagRequest(true);
    }
	
	const headings = [
		{
			title:localString("Reference ID"),
			showHeading:true,
			customCls:""
		},
		{
			title:localString("Customer"),
			showHeading:(!(accessFn('VIEW_QUEUE_DROPOFF')) && !(accessFn('RECYCLE_HUB_ACCESS'))) && ((!(accessFn('REASSIGN_JOBS')) && !(accessFn('REPAIR_DEVICE')) && !(accessFn('QC_DEVICE'))) || accessFn('ACCEPT_RETURN_DEVICE')),
			customCls:""
		},
		{
			title:localString("Device"),
			showHeading:true,
			customCls:""
		},
		{
			title:localString("IMEI/Serial No"),
			showHeading:true,
			customCls:""
		},
		{
			title:localString("Service Type") +" & "+localString("Status"),
			showHeading:true,
			customCls:""
		},
		{
			title:localString("Tasks"),
			showHeading:accessFn('TASK_MANAGEMENT'),
			customCls:"task-width"
		},{
			title:!isDiagnosisFlow ? localString("Schedule"):localString("Model"),
			showHeading: true,
			customCls:"" 
		},
		{
			title:localString("Created"),
			showHeading:true,
			customCls:""
		},
	];


	const toggleCount = () => {
		if(!showCount) {
			if(dateError && !requestFilterData?.ReferenceID) {
				Swal.fire({
					icon: 'error',
					text: localString('Please select a date range within 6 months'),
				});
				return;
			}
			refreshRequestsBasedOnNav();
		}
		setShowCount(!showCount);
		// setShowCount(true)
		// if(!showCount){
		// 	refreshRequestsBasedOnNav()
		// 	setShowCount(true)
			
		// }else if(showCount){
		// 	setShowCount(false);
        // }
    }
	
	let consumerRequestTaskCountButton = [
		{
			labelText: showCount ? localString('Hide Count') : localString('Show Count'),
			type: 'button',
			showTask:true,
			oninputFunc:(val)=>{
				setShowCount(val);			
			},
		},
	];

	let requestInputsOnPage =[
	{
		labelText:localString('Reference ID'),
		dataKey:"ReferenceID",
		customClass:"col-sm-3 register-filter",
		showFilter:true,
		oninputFunc: (val) => {
			setRequestFilterData({...requestFilterData,['ReferenceID']:val})
		}
	},{
		labelText:localString("IMEI/Serial No"),
		dataKey:"ImeiNumber",
		customClass:"col-sm-3 register-filter",
		showFilter:true,
		oninputFunc: (val) => {
			setRequestFilterData({...requestFilterData,['ImeiNumber']:val})
		}
	},{
		labelText:localString('Consumer Name'),
		dataKey:"Name",
		customClass:"col-sm-3 register-filter",
		showFilter:true,
		oninputFunc: (val) => {
			setRequestFilterData({...requestFilterData,['Name']:val})
		}
	},{
		labelText:localString('Contact No'),
		dataKey:"MobileNo",
		customClass:"col-sm-3 register-filter",
		showFilter:true,
		oninputFunc: (val) => {
			setRequestFilterData({...requestFilterData,['MobileNo']:val})
		}
	}]

	let downloadButtonArray = [{
		showIcon:true,
		btnClassName:"downloadBtn-icon",
		accessFunction : 'REQUEST_EXCEL_DOWNLOAD',
		iconClassName:"image-download",
		tooltip:"Download Open/Inprogress requests",
		handleOnClick :()=>{
			downloadRequests()
		}
	},{
		showIcon:true,
		btnClassName:"downloadBtn-icon",
		accessFunction : 'CLOSED_DATA_EXCEL_DOWNLOAD',
		iconClassName:"image-download",
		tooltip:"Download all reports",
		handleOnClick :()=>{
			downloadCompletedRequests();
		}
	}]

	return (
		<>
			{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<PageHeader 
				{...props}
				titleHeader={titleHeader}  
				localString={localString} 
				btnClass="btn button-servify header-btn button-stack text-capital" 
				headerButtonArray={buttonHeader}
				downloadButtonArray={downloadButtonArray}
			/>
			<ConsumerRequestTask
				{...props}
				minDate={minDate}
				maxDate={maxDate}
				dateRange={dateRange}
				openTaskRequestCount={openTaskRequestCount}
				consumerRequestTaskCountButton={ consumerRequestTaskCountButton}
				showCount={showCount}
				toggleCount={toggleCount}
				setDateRange={setDateRange}
				setShowCount={setShowCount}
				displayCountButton={displayCountButton}
				handleDateRangeApply={showPopOver}
				dateError={dateError}
				requestFilterData={requestFilterData} 
			/>
			<ConsumerServiceRequestCount
				{...props}
                activeNav={activeNav.name}
				serviceRequestNav={serviceRequestNav}
				showCount={showCount}
				returnCounts={returnCounts}
				setActiveNav={setActiveNav}
            />
			<RequestSearch 
				{...props} 
				buttonDisable={buttonDisable} 
				applyFilter={applyFilter} 
				requestInputsOnPage={requestInputsOnPage} 
				requestFilterData={requestFilterData} 
				hideClearButton={hideClearButton}
				clearButtonFunction={clearButtonFunction}
			/>
			{ <ConsumerServiceRequestList 
				{...props}
				headings={headings} 
				request={request}
				localLoader={localLoader}
				groupRequestListData={groupRequestListData && groupRequestListData}
				pagination={pagination}
				checkExternalReferenceCheck={checkExternalReferenceCheck}
				setCurrentPage={setCurrentPage}
				isDiagnosisFlow={isDiagnosisFlow}
				currentCount={currentCount}
				openTaskRequestCount={openTaskRequestCount}
				openflagrequest={openflagrequest}
				currentTabCount={returnCounts(activeNav.name)}
				hideNoDataMessage={hideNoDataMessage}
				flagRequest={flagRequest}
				setFlagRequest={setFlagRequest}
				showLoader={showLoader}
			/>}
			{showFilterModal && <FilterRequestModal 
				{...props} 
				buttonDisable={buttonDisable} 
				applyFilter={applyFilter} 
				countryDataList={countryDataList}
				setCountryDataList={setCountryDataList}
				stateDataList={stateDataList}
				subcategoryData={subcategoryData}
				setSubcategoryData={setSubcategoryData}
				setStateDataList={setStateDataList}
				brandData={brandData}
				setbrandData={setbrandData}
				productData={productData}
				setProductData={setProductData}
				statusList={statusList}
				setStatusList={setStatusList}
				serviceTypes={serviceTypes}
				setServiceTypes={setServiceTypes}
				logisticsProviderList={logisticsProviderList}
				setLogisticsProviderList={setLogisticsProviderList}
				registrationSourceList={registrationSourceList}
				setRegistrationSourceList={setRegistrationSourceList}
				engineerList={engineerList}
				setEngineerList={setEngineerList}
				locationInput={locationInput}
				setLocationInput={setLocationInput}
				taskStatusType={taskStatusType}
				emptyBoxAvailbility={emptyBoxAvailbility}
				transferRequestOptions={transferRequestOptions}
				searchText={searchText}
				setSearchText={setSearchText}
				cityList={ cityList}
				setCityList={setCityList}
				callStatus={callStatus}
				setCallStatus={setCallStatus}
				escalationTypes={escalationTypes}
				setEscalationTypes={ setEscalationTypes}
				serviceCenters={serviceCenters}
				setServiceCenters={setServiceCenters}
				disableState={disableState}
				setDisableState={setDisableState}
				countriesData={countriesData} 
				requestFilterData={requestFilterData} 
				hideClearButton={hideClearButton}
				clearButtonFunction={clearButtonFunction} 
				setRequestFilterData={setRequestFilterData} 
				showModal={showFilterModal} 
				closeModal={closeModal}
				setRequirePackagingMaterial={setRequirePackagingMaterial}
				requirePackagingMaterial={requirePackagingMaterial} 
				showLoader={showFilterLoader}
				setLoader={setFilterLoader}
				loaded={loaded}
				setLoaded={setLoaded}
				qFilterData={qFilterData}
				countryOfCustomerList={countryOfCustomerList}
				setCountryOfCustomerList={setCountryOfCustomerList}
				planList={planList}
				setPlanList={setPlanList}
			/>}
			{
				showFetchGsxAuthTokenModal && 
				<GsxAuthTokenModal
					{...props}
					closeModal={closeGSXAuthTokenModal}
					showModal={showFetchGsxAuthTokenModal}
					gsxUserDetails={gsxUserDetails}
					authToken={authToken}
					setAuthToken={setAuthToken}
					disableUpdateGSXAuthToken={disableUpdateGSXAuthToken}
					cancelClick={cancelClick}
					updateGSXAuthToken={updateGSXAuthToken}
					setDisableUpdateGSXAuthToken={setDisableUpdateGSXAuthToken}
				/>
			}
			{
				runGSXDiagnosisModal && 
					<RunDiagnosisModal
						{...props}
						showModal={runGSXDiagnosisModal}
						closeModal={closeGSXDiagnosisModal}
						serialNumber={serialNumber}
						setSerialNumber={setSerialNumber}
						fetchDiagnosisResults={fetchDiagnosisResults}
						diagnosticsResult={diagnosticsResult}
						fetchDiagnosisSuits={fetchDiagnosisSuits}
						arrDiagnosisSuites={arrDiagnosisSuites}
						initiateDiagnosis={initiateDiagnosis}
						disableDiagnosis={disableDiagnosis}
						setDisableDiagnosis={setDisableDiagnosis}
						diagnosticsResultClick={runDiagnosticsClick.diagnosticsResultClick}
						diagnosticsSuitsClick={runDiagnosticsClick.diagnosticsSuitsClick}
						refreshModal={refreshModal}
					/>
			}
			{flagRequest && <FlagEscalation {...props} setFlagRequest={setFlagRequest} flagCSRID={flagCSRID} refreshRequests={refreshRequests} consumerServiceRequests = {request}></FlagEscalation>}
		</>
	)
}
export default ConsumerServiceRequest

