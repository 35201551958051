import React, {useState,useEffect} from 'react';
import './DiagnosisReport.scss';
import {Button, Table, Modal, ModalBody, ModalHeader, ModalFooter} from 'sfy-react';
import moment from 'moment';
import {capitalizeFirstLetter, diagnosticReportStatus} from '../../Utility/Utils';

const DiagnosisReport = (props) => {
    const {showModal, publicUrl, brandDetails, localString, setOpenReport, consumerDetails, reportObj, cpid} = props;
    const headers = ['Component', 'Observed', 'Result', 'Details'];
    const closeModal = () => {
        setOpenReport(false);
    };
    const print = () => {
        window.print();
    };
    const brandImgUrl = brandDetails ? 'https://s3-ap-southeast-1.amazonaws.com/' + 'brand-icons' + '/' + brandDetails.BrandID + '-' + brandDetails.BrandName.toLowerCase() + '.png' : '';
    return (
        <>
        <Modal
            showModal={showModal}
            showCloseBtn={true}
            backdropClassName={"reports-modal"}
            closeBtnClassName={"modal-close"}
            className={"modal-wrapper"}
            closeModal={closeModal}
        >
            <ModalHeader>
                <h4 className="modal-title">
                    {localString("Diagnosis Report")}
                </h4>
            </ModalHeader>
            <ModalBody>
                {!brandImgUrl && <div className="seal-icon-container">
                    <img src={publicUrl + "/imgs/seal.svg"} alt="Servify" className="seal-logo" />
                </div>}
                <div className="print-div">
                    <button onClick={print} className="btn button-servify"><span className="glyphicon glyphicon-print"></span> <strong>{localString("Print")}</strong></button>
                </div>
                <div className="header-container">
                    <div className="col-sm-4 head-div">
                        <img src={publicUrl + "/imgs/logo-purple.png"} alt="Servify" className="report-logo"/>
                    </div>
                    <div className="cols-sm-4 head-div">
                        <h2 className="report-heading">{localString("DIAGNOSIS CERTIFICATE")}</h2>
                    </div>
                    <div className="cols-sm-4 head-div">
                        {brandImgUrl &&  <img alt={brandDetails.BrandName} className="report-logo brand-logo" src={brandImgUrl} />}
                    </div>
                </div>
                <div className="sub-header-section">
                    <div className="col-sm-4 sub-header">
                        {consumerDetails && consumerDetails.Consumer && <>
                            <div><strong>{localString('Consumer Details')}</strong></div>
                            {consumerDetails.Consumer.Name && <div>{consumerDetails.Consumer.Name}</div>}
                            {consumerDetails.Consumer.MobileNo && <div>{consumerDetails.Consumer.MobileNo}</div>}
                            {consumerDetails.Consumer.EmailID && <div>{consumerDetails.Consumer.EmailID}</div>}
                            {consumerDetails.Consumer.AlternateMobileNo && <div>{consumerDetails.Consumer.AlternateMobileNo}</div>}
                        </>}
                    </div>
                    <div className={"col-sm-4 sub-header large-cell"}>
                        {reportObj && reportObj.event && <>
                            <div></div>
                            {reportObj.event.createdDate && <div>{"Time of Diagnois: " + moment(reportObj.event.createdDate).format("DD MMM, YYYY hh:mm a")}</div>}
                            {reportObj.event.type && <div>{"Diagnosis Type: " + reportObj.event.type}</div>}
                            {!cpid && <>
                                {reportObj.SystemManufacturer && <div>{localString("System Manufacturer") + ": " + reportObj.SystemManufacturer}</div>}
                                {reportObj.SystemModel && <div>{localString("System Model") + ": " + reportObj.SystemModel}</div>}
                                {reportObj?.event?.device?.Brand && <div>{localString("Brand") + ": " + capitalizeFirstLetter(reportObj?.event?.device?.Brand)}</div>}
                                {reportObj?.event?.device?.MarketName ? <div>{localString("Product Name") + ": "  + reportObj?.event?.device?.MarketName}</div> : reportObj?.event?.device?.ProductName ? <div>{"Product Name: " + reportObj?.event?.device?.ProductName}</div> : ''}
                            </>}
                            {<div>{"IMEI/Serial Number: " + (reportObj.event.IMEI ? reportObj.event.IMEI : reportObj.event.Serial ? reportObj.event.Serial : reportObj.DeviceSerialNumber ? reportObj.DeviceSerialNumber : '')}</div>}
                            {<div><strong>{"Diagnosis Result: "+ (reportObj.event.status ? diagnosticReportStatus[reportObj.event.status].displayText : '')}</strong></div>}
                        </>}
                        
                    </div>
                    <div className="col-sm-4 sub-header">
                        {brandDetails && consumerDetails && <>
                            <div><strong>{localString('Device Details')}</strong></div>
                            {consumerDetails.ProductSubcategory && consumerDetails.ProductSubcategory.ProductSubCategory && <div>{"Category: "+consumerDetails.ProductSubcategory.ProductSubCategory}</div>}
                            {brandDetails.BrandName && <div>{"Brand: "+brandDetails.BrandName}</div>}
                            {consumerDetails.ProductName && <div>{"Product: "+consumerDetails.ProductName}</div>}
                            {consumerDetails.ModelNo && <div>{"ModelNo: "+consumerDetails.ModelNo}</div>}
                        </>}
                    </div>
                </div>
                <div className="table-section">
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                {headers && headers.length>0 && headers.map((head, index) => {
                                    return (
                                        <Table.Heading key={index}><strong>{head}</strong></Table.Heading>
                                    )
                                })}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {reportObj && reportObj.event && reportObj.event.data && reportObj.event.data.length > 0 && reportObj.event.data.map((component, index) => {
                                if(diagnosticReportStatus[component.status]) {
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Data>{component.name ? component.name : '-'}</Table.Data>
                                            <Table.Data>{component.value ? component.value : '-'}</Table.Data>
                                            <Table.Data>{diagnosticReportStatus[component.status] ? <span style={{color: diagnosticReportStatus[component.status].color}}>{diagnosticReportStatus[component.status].displayText}</span> : '-'}</Table.Data>
                                            <Table.Data>{component.idealValue ? component.idealValue : '-'}</Table.Data>
                                        </Table.Row>
                                    )
                                }
                            })}
                        </Table.Body>
                    </Table>
                </div>
                <div className="signature"><p>This is an electronically generated report and does not need a signature</p>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="col-xs-12">
                    <div className="text-right">
                        <Button 
                            type='button' 
                            classes="button-servify-solid" 
                            handleOnClick={() => closeModal()}
                        >
                            {localString("Close")}
                        </Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
        </>
    )
}

export default DiagnosisReport
