import React from 'react';
import {Modal} from 'sfy-react';

import RejectReason from './RejectReason';

import './RejectReasonModal.scss'

const RejectReasonModal=(props)=>{
    const {localString,showModal,closeModal} = props;
		return ( <>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal rejectReasonModal'>
                <Modal.Header>
                    <h2> {localString("Reject Refund Request")} </h2>
                </Modal.Header>
                <Modal.Body>
                    <RejectReason {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </> )
}
export default RejectReasonModal;