import React,{useState} from 'react';
import {Dropdown,Input,Button} from 'sfy-react';
const BERProductInput = (props) =>{
    const {localString,showModal,closeModal,selectProductList,selectBrandList,consumerServiceRequest,productData,setProductData,disableBrandEdit,saveProductDetails} = props;

    let productInput = [{
        labelText:localString("Brand"),
        type:'dropdown',
        value:consumerServiceRequest?.consumerProduct?.brand?.BrandName,
        dataKey:"brandName",
        customClass:"col-sm-3 register-filter",
        showOptionField: false,
        dropdownList:selectBrandList,
        isDisabled :disableBrandEdit,
        oninputFunc: (val) => {
            setProductData({...productData,['brandName']:val.BrandName,['brandId']:val.BrandID})
		}
	},{
		labelText:localString("Product"),
        type:'dropdown',
        value:consumerServiceRequest?.consumerProduct?.product?.ProductName,
		dataKey:"productName",
		customClass:"col-sm-3 register-filter",
        showOptionField: false,
        dropdownList:selectProductList,
		oninputFunc: (val) => {
			setProductData({...productData,['productName']:val.ProductName,['productId']:val.ProductID})
		}
	},{
        labelText:localString("IMEI 1 / Serial Number 1"),
        type:'input',
        dataKey:"imeiNumber",
        customClass:"col-sm-3 register-filter",
        showOptionField: false,
        maxLength:15,
        oninputFunc: (val) => {
            setProductData({...productData,['imeiNumber']:val})
        }
    },
    {
        labelText:localString("IMEI 2 / Serial Number 2"),
        type:'input',
        dataKey:"newImeiNumber",
        customClass:"col-sm-3 register-filter",
        showOptionField:true ,
        maxLength:15,
        oninputFunc: (val) => {
            setProductData({...productData,['newImeiNumber']:val})
        }
    },
    {
        labelText:localString("SIM SKU"),
        type:'input',
        dataKey:"simSku",
        customClass:"col-sm-3 register-filter",
        showOptionField: true,
        maxLength:40,
        oninputFunc: (val) => {
            setProductData({...productData,['simSku']:val})
        }
    },
    {
        labelText:localString("SIM Number"),
        type:'input',
        dataKey:"simNumber",
        customClass:"col-sm-3 register-filter",
        showOptionField: true,
        maxLength:25,
        oninputFunc: (val) => {
            setProductData({...productData,['simNumber']:val})
        }
    }
    ]
    
    return (
		<>
            <div className='product-input'>
                {
                    productInput && productInput.length >0 && productInput.map((item,index)=>(
                        <>
                        {item.type=='dropdown' && 
                        <div className='productMb'>
                            <label>{item.labelText}</label>
                            <Dropdown
                                value={productData[item.dataKey]}
                                options={item.dropdownList}
                                handleOnChange={(data) => {
                                    item.oninputFunc(data)
                                }}
                                isDisabled={item.isDisabled}
                                filterBy='value'
                                showDownArrow={false}
                            />
                        </div>}
                        {
                            item.type== 'input' &&
                            <div className='productMb'>
                                <label>{item.labelText}</label>
                                {item.showOptionField && <span>{` (${localString('optional')})`}</span>}
                                <Input
                                    value={productData[item.dataKey]}
                                    key={index}
                                    handleOnChange={(e) => {
                                        item.oninputFunc(e.target.value);
                                    }}
                                    labelAlign='top'
                                    maxLength={item.maxLength}
                                />
                            </div>
                        }
                        </>
                    ))
                }
                <div>
                    <Button className='btn button-servify' handleOnClick = {()=>saveProductDetails()}>{localString("Save")}</Button>
                    {/* <input type="button" name="submit" class="btn button-servify" disabled={disableProductDetailsSave}  onclick={saveProductDetails} value={localString("Save")} /> */}
                </div>
            </div>
        </>
	)
}
export default BERProductInput;