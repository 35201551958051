import React, { useState,useEffect,useRef } from 'react';
import {Button,Input} from 'sfy-react';
import Datetime from 'react-datetime';
import { displayError } from '../../../../../Utility/Utils';
import Swal from 'sweetalert2';
import moment from 'moment';
const UTRPymntDateInput=(props)=>{
    const {apiCall, localString, consumerServiceRequest, setLoader, getRequestById,closeModal} = props;
	const [pymntDate,setPymntDate] = useState('');
    const [utrNumber,setUtrNumber] = useState('');
    const [btnDisable,setBtnDisable] = useState(true);
    const imageInput = useRef(null);
    useEffect(() => {
        if(pymntDate != '' && utrNumber.length >0){
            setBtnDisable(false);
        }else{
            setBtnDisable(true);
        }
    }, [pymntDate,utrNumber])

    const submitUTRPymntDate = (e)=>{
        e.preventDefault();
        setLoader(true);
        let utr = utrNumber ? utrNumber :'';
        let pymntDate = pymntDate ? pymntDate : moment().format('DD-MM-YYYY');
        let data = {
            UTRnumber: utr,
            PaymentDate: pymntDate,
            Status: 'BER payment completed',
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            "CountryID":consumerServiceRequest.CountryID
        };
        
        apiCall({
            url: "BERClaimRequest", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    getRequestById();
                    closeModal();
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

    return ( 
        <div className='request-filter'>
            <form className='m10' onSubmit={(e)=>submitUTRPymntDate(e)}>
                <table>
                <tr>
                    <td className="ber-payment-td">{localString("Payment Date")}:</td>
                    <td id="pymntdatepicker" className="input-group ber-payment-td">
                        <Datetime
                            value={pymntDate}
                            dateFormat={'DD-MM-YYYY'}
                            timeFormat={false}
                            initialViewDate={new Date()}
                            onChange={(val) => {
                                setPymntDate(moment(new Date(val),'DD-MM-YYYY').format('DD-MM-YYYY'))
                            }}
                            inputProps={{ ref:imageInput,placeholder:'DD-MM-YYYY'}}
                            closeOnSelect={true}
                        />
                        <span className="input-group-addon"  onClick={() => imageInput.current.click()}>
                            <span className="glyphicon glyphicon-calendar"></span>
                        </span>
                    </td>
                </tr>
                    <tr>
                    <td className="ber-payment-td">{localString("UTR Number")}:</td>
                    <td className="ber-payment-td">
                        <Input 
                            className="form-control" 
                            inputType='text' 
                            value={utrNumber}
                            placeholder = "UTR Number"
                            handleOnChange={(e) => {
                                setUtrNumber(e.target.value);
                            }}
                            maxLength='16'
                            autoComplete='off'
                        />
                    </td>
                </tr>
                </table>
                <div className="row form-group-filter-request">
                    <div className="request-filter-button-div">
                        <Button className='btn button-servify-solid' isDisabled = {btnDisable}>{localString("Save")}</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default UTRPymntDateInput;
