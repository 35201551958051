import React, { useEffect, useState } from 'react';
import { Button } from 'sfy-react';
import Swal from 'sweetalert2';

import { displayError } from '../../../Utility/Utils';

export default function ReplacementReasons(props) {
	const { 
		apiCall, localString, setLoader, selectedReasons, setSelectedReasons, setCallRepairModeClick,
		otherReasonDescription, setOtherReasonDescription, showOtherReasonTextBox, setshowOtherReasonTextBox, 
	} = props;
	const [reasonList, setReasonList] = useState([]);
	const [showMaxLimitText, setShowMaxLimitText] = useState(false);
	const reasonsLimit = {
        max: 4,
        min: 1
    };

	const getReturnReasons = () => {
		setLoader(true);
		apiCall({
			url: 'getReturnReasons',
			data: {},
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data) {
					setReasonList(result.data.returnReasons);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString('No issues found'),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	useEffect(() => {
		getReturnReasons();
		return () => {
			setSelectedReasons([]);
		}
	}, []);

	const reasonItemClick = (item) => {
		let arr = [...selectedReasons];
		if(selectedReasons.find(a => a.ReasonID === item.ReasonID)) {
			if(item.Reason === 'Other') {
				setshowOtherReasonTextBox(false);
				setOtherReasonDescription('');
			}
			arr = selectedReasons.filter(a => a.ReasonID !== item.ReasonID);
			setShowMaxLimitText(false);
		}else {
			if(selectedReasons.length < reasonsLimit.max) {
				if(item.Reason === 'Other') {
					setshowOtherReasonTextBox(true);
				}
				arr.push(item);
				setShowMaxLimitText(false);
			}else {
				setShowMaxLimitText(true);
			}
		}
		setSelectedReasons(arr);
	}

	return (
		<div id='ReplacementReasons' className="raiseReqSection">
			<div className='issuesSelection'>						
				<div className="row">
					<div className="col-sm-10 col-xs-8 form-label">
						<label>{localString("Select a Replacement Reason")}</label>
					</div>
				</div>
				<div className='row'>
                    {
                        reasonList && reasonList.length > 0 && reasonList.map((item, k) => 
                            <div className="issue-div col-sm-4 col-xs-6" key={k}>
                                <div className={`item issue-item ${selectedReasons.find(a => a.ReasonID === item.ReasonID) ? 'item-active' : ''}`} onClick={() => reasonItemClick(item)}>
                                    {item.Reason}
                                </div>
                            </div>
                        )
                    }
				</div>
				{
					showOtherReasonTextBox &&
						<div className="row">
							<div className='col-sm-6 col-xs-12'>
								<textarea 
									value={otherReasonDescription}
									onChange={(e) => setOtherReasonDescription(e.target.value)}
									placeholder={localString("Please enter issue description")}
									maxLength={'255'}
									rows='5'
									cols='80'
									style={{ maxWidth: '100%' }}
								/>
							</div>
						</div>
				}
				{
					showMaxLimitText &&
						<div className="row">
							<div className="text-center" style={{ fontSize: '24px', padding: '20px 0px' }}>
								{localString("Cannot select more than")} { reasonsLimit.max } {localString("issues")}.
							</div>
						</div>
				}
				<div className="text-right">
					<Button
						className="btn button-servify nextBtn" 
						onClick={() => setCallRepairModeClick(true)}
						isDisabled={!selectedReasons.length || (showOtherReasonTextBox && !otherReasonDescription)}
					>
						<div className="button-next"></div>
					</Button>
				</div>
			</div>
		</div>
	)
}