import React from 'react';
import {Modal} from 'sfy-react';
import DocumentPendingInput from './DocumentPendingInput';
import './DocumentPendingModal.scss';

const DocumentPendingModal=(props)=>{
    const {accessFn,localString,showModal,closeModal,consumerServiceRequest} = props;
		return (
		<>
            <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='Filter-Modal document-overFlow'>
                <Modal.Header>
                    <h4 class="modal-title text-center" id="myModalLabel"> {consumerServiceRequest && (['Document pending'].indexOf(consumerServiceRequest.Status)> -1 || consumerServiceRequest.PendingDoc == 1) && accessFn('BER_CUSTOMER_ACTION') ? 
                    localString("Add required documents in 'Request Documents' section below") :
                    localString("Ask for Documents Pending")}</h4>
                </Modal.Header>
                <Modal.Body>
                    <DocumentPendingInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default DocumentPendingModal;