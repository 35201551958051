import React from 'react';
import { Button } from 'sfy-react';

export default function SlotSelection(props) {
	const {
		localString, scheduleDate, setScheduleDate, slot, setSlot, scheduleDateArray, setScheduleDateArray,
		scheduleSlotArray, setScheduleSlotArray, slotsNext, slotAvailable, setSlotAvailable, 
		disableSlotNext, setDisableSlotNext, type = 'raiseRequest', setCallRequestFunction
	} = props;
	
	const scheduleDateClick = (item) => {
		setScheduleDate(item);
		let slots = item.slots;
		setScheduleSlotArray(slots);
		let slotVal = null;
		let slotAvailableFlag = false;
        // this.resetExternalRef();
		for(let i = slots.length - 1; i >= 0; i--) {
            if (slots[i].IsActive) {
                slotVal = slots[i];
                slotAvailableFlag = true;
            }
        };
		setSlot(slotVal);
		setSlotAvailable(slotAvailableFlag);
        if(slotVal) {
			setDisableSlotNext(false);
        } else {
			setDisableSlotNext(true);
        }
	}

	const scheduleSlotClick = (item) => {
		if(item.IsActive) {
			setSlot(item);
        }
		if(item) {
			setDisableSlotNext(false);
        } else {
			setDisableSlotNext(true);
        }
	}

	return (
		<>
			<div id='SlotSelection' className='raiseReqSection'>
				<div className='scheduleDateAndTime'>
					<div className="col-sm-12 form-label" style={{ marginBottom: '15px' }}>
						<label>{localString("Select a Date")}</label>
					</div>
					<div className="row">
						{
							scheduleDateArray && scheduleDateArray.length && scheduleDateArray.map(obj => 
								<div className="date-box col-sm-3 col-xs-4" onClick={() => scheduleDateClick(obj)}>
									<div className={`item slot-item ${(obj.date == (scheduleDate && scheduleDate.date)) ? 'item-active-border' : ''}`}>
										<p>{obj.day} {obj.month}</p>
									</div>
								</div>
							)
						}
					</div>
					<div className="col-sm-12 form-label" style={{ marginBottom: '15px' }}>
						<label>{localString("Select a Slot")}</label>
					</div>
					{
						!slotAvailable &&
							<div className="row">
								<div className="col-sm-8 col-sm-offset-2 text-center empty-message">
									{localString("No Slots Available")}
								</div>
							</div>
					}
					{
						slotAvailable && 
							<div className="row">
								{
									scheduleSlotArray && scheduleSlotArray.map(obj => 
										<div className="col-sm-3 col-xs-4" onClick={() => scheduleSlotClick(obj)}>
											<div className={`item slot-item ${((obj.StartTimeVal == (slot && slot.StartTimeVal)) && (obj.EndTimeVal == (slot && slot.EndTimeVal))) ? 'item-active-border' : ''} ${(!obj.IsActive) ? 'item-disabled' : ''}`}>
												<p className="address-p">{obj.StartTime} - {obj.EndTime}</p>
											</div>
										</div>
									)
								}
							</div>
					}
					<div className="text-right">
						{
							type === 'raiseRequest' ? 
								<Button
									className="btn button-servify nextBtn" 
									onClick={() => slotsNext()}
									isDisabled={!scheduleDate || !slot}
								>
									<div className="button-next"></div>
								</Button> : 
								<Button
									className="btn button-servify no-border-radius" 
									onClick={() => setCallRequestFunction(true)}
									isDisabled={!scheduleDate || !slot}
								>
									{localString('Raise Request')}
								</Button> 
						}
						
					</div>
				</div>
			</div>
		</>
	);
}