import React, { useState, useEffect } from "react";
import { Loader, Button, Modal, Dropdown } from "sfy-react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import "./PlanSalesHome.scss";
import Cards from "../../../components/Cards.js/Cards";
import Swal from "sweetalert2";
import { displayAmount, displayError } from "../../../Utility/Utils";
import {
	setSelectedPSL,
	setPartnerLocationList,
} from "../../../reduxToolkit/features/planSales/planSalesDashboardSlice";
import DateRangePickerPlanSales from "../DateRangePickerPlanSales/DateRangePickerPlanSales";
import moment from "moment";

const PlanSalesHome = (props) => {
	const {
		localString,
		accessFn,
		apiCall,
		publicUrl,
		baseUrl,
		useQuery,
		bulkUploadUrl,
	} = props;
	const { selectedPSL, partnerLocationList, dateRange } = useSelector(
		(state) => state.PlanSalesDashboard
	);
	const dispatch = useDispatch();
	const qFilter = JSON.parse(props.qFilter);
	const [showLoader, setLoader] = useState(false);
	const [PSLSearchText, setPSLSearchText] = useState("");
	const [tempSelectedPSL, setTempSelectedPSL] = useState(selectedPSL);
	const [entityDetails, setEntityDetails] = useState([]);
	const [transactionSummaryDetails, setTransactionSummaryDetails] = useState(
		[]
	);
	const [noResultsFound, setNoResultsFound] = useState(false);
	const [countryData, setCountryData] = useState(null);
	const [countryCurrencyConfig, setCountryCurrencyConfig] = useState({
		currencyCode: 'INR',
		countryLocale: 'en-IN'
	});

	let maxDate = new Date();
	maxDate.setDate(maxDate.getDate() + 5);

	const [showPSLModal, setShowPSLModal] = useState(false);

	const handleInputChange = (e) => {
		e.preventDefault();
	};

	useEffect(() => {
		document.addEventListener("input-datalist", handleInputChange);
		return () => {
			document.removeEventListener("input-datalist", handleInputChange);
		};
	}, [handleInputChange]);

	///PSL Render ////

	useEffect(() => {
		if (
			qFilter &&
			qFilter.serviceLocations &&
			qFilter.serviceLocations.length == 1
		) {
			loadServiceCenters();
		}
		getCountries();
	}, []);

	useEffect(() => {
		getServiceCenterValue(PSLSearchText);
	}, [PSLSearchText]);

	useEffect(() => {
		if (selectedPSL && selectedPSL.PartnerServiceLocationID) {
			setShowPSLModal(false);
			loadFetchEntityDetails();
			loadTransactionSummaryDetails();
		} else if (accessFn("PLAN_SALE_ADMIN_USER")) {
			setShowPSLModal(false);
		} else {
			setShowPSLModal(true);
		}
	}, [selectedPSL]);

	const getServiceCenterValue = debounce((locationInput) => {
		if (locationInput && locationInput.length > 2) {
			loadServiceCenters(locationInput);
		} else {
			dispatch(setPartnerLocationList([]));
			setNoResultsFound(false);
		}
	}, 200);

	const loadServiceCenters = (locationInput) => {
		let data = {};
		if (locationInput) {
			data.ServiceLocationNameSubString = locationInput;
		}
		setLoader(true);
		apiCall({
			url: "serviceCenterList",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					if (
						qFilter &&
						qFilter.serviceLocations &&
						qFilter.serviceLocations.length == 1
					) {
						dispatch(setSelectedPSL(result.data[0]));
					}
					const list =
						result.data &&
						result.data.map((v) => ({
							...v,
							value: v.ServiceLocationName,
						}));
					dispatch(setPartnerLocationList(list));
					setNoResultsFound(result.data.length === 0);
				} else {
					setNoResultsFound(true);
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const loadFetchEntityDetails = (date) => {
		setEntityDetails([]);
		setLoader(true);
		let data = {
			PartnerServiceLocationID: selectedPSL.PartnerServiceLocationID,
			StartDate: date?.startDate
				? moment(date.startDate).format("YYYY-MM-DD")
				: moment(dateRange.startDate).format("YYYY-MM-DD"),
			EndDate: date?.endDate
				? moment(date.endDate).format("YYYY-MM-DD")
				: moment(dateRange.endDate).format("YYYY-MM-DD"),
			getCount: true,
		};
		apiCall({
			url: "getSales",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					setEntityDetails(result.data);
				} else {
					Swal.fire({
						icon: "info",
						text: localString("Something Went Wrong"),
					});
				}
			},
		});
	};

	const loadTransactionSummaryDetails = (date) => {
		setTransactionSummaryDetails([]);
		setLoader(true);
		let data = {
			PartnerServiceLocationID: selectedPSL.PartnerServiceLocationID,
			StartDate: date?.startDate
				? moment(date.startDate).format("YYYY-MM-DD")
				: moment(dateRange.startDate).format("YYYY-MM-DD"),
			EndDate: date?.endDate
				? moment(date.endDate).format("YYYY-MM-DD")
				: moment(dateRange.endDate).format("YYYY-MM-DD"),
		};
		apiCall({
			url: "getTransactionSummary",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					setTransactionSummaryDetails(result.data);
				} else {
					Swal.fire({
						icon: "info",
						text: localString("Data not found"),
					});
				}
			},
		});
	};

	const getCountries = () => {
		setLoader(true);
		let reqObj = {
			specificCountryCode: qFilter?.countrycode?.[0].toUpperCase() || undefined,
			countryConfig: true,
		}
		apiCall({
			url: 'getCountries',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success){
					if(result.data) {
						let countryObj = result.data.countries.length && result.data.countries[0];
						let obj = {};
						if(countryObj?.CurrencyCode) obj.CurrencyCode = countryObj.CurrencyCode;
						if(countryObj?.countryConfig?.Locale?.DEFAULT) obj.countryLocale = countryObj.countryConfig.Locale.DEFAULT;
						setCountryData(countryObj);				
						setCountryCurrencyConfig(obj);
					} else {
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError('No country data found.')),
						});
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}

			<div className="hidden-xs content-header">
				<div className="row content-header-background main-div3">
					<div className="col-xs-12 col-sm-5">
						<h2 className="sell-plan">
							{localString("Plan Sales")}
						</h2>
					</div>
					<div className="store-home text-right">
						<h6
							className="right-text button-stack"
							style={{ marginTop: "auto" }}
						>
							{localString("Store Location")}
						</h6>
						<Dropdown
							id="dropdown"
							value={selectedPSL?.ServiceLocationName}
							options={partnerLocationList}
							handleOnChange={(val) => {
								setTempSelectedPSL(val);
								dispatch(setSelectedPSL(val));
							}}
							handleOnSearch={(e) => {
								setPSLSearchText(e.target.value);
								return partnerLocationList;
							}}
							classObject={{
								container: "psl-dropdown-header",
							}}
							filterBy="value"
							placeholder="Search"
							searchText="Search"
							labelFloat={false}
							showNoOptionsText={false}
						/>
					</div>
				</div>
			</div>

			{!accessFn("PLAN_SALE_ADMIN_USER") && (
				<>
					<div className="plan-sales-container">
						<div className="row">
							<div className="col-sm-3 heading-text">
								{localString("Plan Sales Summary")}
							</div>
							<div className="first-content">
								<div className="period-div">
									<span className="period-text-margin">
										<label className="label-text">
											{localString(
												"Transaction Start Date"
											)}
										</label>
									</span>

									<DateRangePickerPlanSales
										{...props}
										date={maxDate}
										dateRange={dateRange}
										handleDateRangeApply={(obj) => {
											loadTransactionSummaryDetails(obj);
											loadFetchEntityDetails(obj);
										}}
										setShowPSLModal={setShowPSLModal}
										dateClass="col-sm-5"
										customFormat="dd-mm-yyyy"
										customPlaceholder="MM-DD-YYYY"
										dateWidthClass={"date-range-home"}
									/>
								</div>

								<button className="plansalebtn btn view-all-button button-stack no-border-radius">
									{!selectedPSL?.PartnerServiceLocationID
										?.length ? (
										<a
											onClick={() => {
												setShowPSLModal(true);
											}}
										>
											{localString(
												"View All Transactions"
											)}
										</a>
									) : (
										<a
											href={
												baseUrl +
												"/plansales/report?pid=" +
												selectedPSL.PartnerServiceLocationID
											}
										>
											{localString(
												"View All Transactions"
											)}
										</a>
									)}
								</button>
							</div>
						</div>
					</div>

					<div className="row boxes">
						<div className="col-sm-3 pill-width">
							<Cards
								{...props}
								titleHeader={localString("Total Sales Value")}
								upfrontSales={
									transactionSummaryDetails.UpfrontSalesValue
								}
								subscriptionPlans={
									transactionSummaryDetails.SubscriptionSalesValue
								}
								amount={
									displayAmount(transactionSummaryDetails.TotalSalesValue, countryCurrencyConfig.countryLocale)
								}
								text1={localString("Upfront Payment")}
								infoText={localString(
									"This includes transaction values associated with plan sales and recurring payments post activation"
								)}
								text2={localString("Subscription Payment")}
								showCurrency={true}
								currency={
									transactionSummaryDetails.CurrencySymbol
								}
							/>
						</div>

						{!accessFn("HIDE_MARGIN") && (
							<div className="col-sm-3 pill-width">
								<Cards
									{...props}
									titleHeader={localString("Total Earnings")}
									infoText={localString(
										"This includes retailer margin values associated with plan sales and recurring payments post activation"
									)}
									upfrontSales={
										transactionSummaryDetails.UpfrontEarnings
									}
									subscriptionPlans={
										transactionSummaryDetails.SubscriptionEarnings
									}
									amountClass="amount-color"
									amount={
										displayAmount(transactionSummaryDetails.TotalEarnings, countryCurrencyConfig.countryLocale)
									}
									text1={localString("Upfront Payment")}
									text2={localString("Subscription Payment")}
									showCurrency={true}
									currency={
										transactionSummaryDetails.CurrencySymbol
									}
								/>
							</div>
						)}

						<div className="col-sm-3 pill-width">
							<Cards
								{...props}
								titleHeader={localString("Number of Sales")}
								infoText={localString(
									"This includes all plan sales transactions except those contracts where customer has not completed the payment via link"
								)}
								amount={
									transactionSummaryDetails.TotalSalesCount
								}
								upfrontSales={
									transactionSummaryDetails.UpfrontSalesCount
								}
								subscriptionPlans={
									transactionSummaryDetails.SubscriptionSalesCount
								}
								text1={localString("Upfront Payment")}
								text2={localString("Subscription Payment")}
							/>
						</div>

						<div className="col-sm-3 pill-width">
							<Cards
								{...props}
								titleHeader={localString(
									"Total Recurring Payments"
								)}
								infoText={localString(
									"This includes all successful recurring transactions associated with subscription plans"
								)}
								amount={
									transactionSummaryDetails.RecurringPayments
								}
								showFooterText={false}
								showChart={false}
							/>
						</div>

						{entityDetails?.SuccessfulTransactions === 0 &&
						entityDetails?.PendingTransactions === 0 &&
						entityDetails?.FailedTransactions === 0 ? (
							<div
								className="col-sm-3 pill-width"
								style={{ display: "none" }}
							>
								<Cards
									{...props}
									titleHeader={localString(
										"Payment Link Transactions"
									)}
									showFooterText={false}
									infoText={localString(
										"This includes all transactions where customer is using a link to complete the payment"
									)}
									showLink={true}
									setShowPSLModal={setShowPSLModal}
									linkText={localString("View All")}
									showChart={false}
									handleClick={
										baseUrl +
										"/plansales/paymentlink?pid=" +
										selectedPSL.PartnerServiceLocationID
									}
									showNumbers={true}
									successCount={
										entityDetails.SuccessfulTransactions
									}
									success={localString("Completed")}
									pendingCount={
										entityDetails.PendingTransactions
									}
									pending={localString("Pending")}
									failureCount={
										entityDetails.FailedTransactions
									}
									failure={localString("Failure")}
								/>
							</div>
						) : (
							<div className="col-sm-3 pill-width">
								<Cards
									{...props}
									titleHeader={localString(
										"Payment Link Transactions"
									)}
									showFooterText={false}
									infoText={localString(
										"This includes all transactions where customer is using a link to complete the payment"
									)}
									showLink={true}
									setShowPSLModal={setShowPSLModal}
									linkText={localString("View All")}
									showChart={false}
									handleClick={
										baseUrl +
										"/plansales/paymentlink?pid=" +
										selectedPSL.PartnerServiceLocationID
									}
									showNumbers={true}
									successCount={
										entityDetails.SuccessfulTransactions
									}
									success={localString("Completed")}
									pendingCount={
										entityDetails.PendingTransactions
									}
									pending={localString("Pending")}
									failureCount={
										entityDetails.FailedTransactions
									}
									failure={localString("Failure")}
								/>
							</div>
						)}
					</div>
				</>
			)}

			<div className="footer-description">
				<div className="footer-text">
					<p className>{localString("Protection Plans")}</p>
				</div>
				<div style={{ marginLeft: "-5px" }}>
					<span>
						{!selectedPSL?.PartnerServiceLocationID?.length ? (
							<a
								className="text-dark"
								onClick={() => {
									setShowPSLModal(true);
								}}
							>
								<button className="plansalebtn btn footer-button col-sm-3 button-stack no-border-radius footer-pill-width">
									<span>
										<span>
											<img
												src={
													publicUrl + "/imgs/Plan.png"
												}
												width={"30px"}
												style={{ marginRight: "5px" }}
											/>
										</span>
										{localString("Sell Plan")}
									</span>
									<span className="glyphicon glyphicon-menu-right" />
								</button>
							</a>
						) : (
							<a
								className="text-dark"
								href={
									baseUrl +
									"/plansales?pslid=" +
									selectedPSL.PartnerServiceLocationID
								}
							>
								<button className="plansalebtn btn footer-button col-sm-3 button-stack no-border-radius footer-pill-width">
									<span>
										<span>
											<img
												src={
													publicUrl + "/imgs/Plan.png"
												}
												width={"30px"}
												style={{ marginRight: "5px" }}
											/>
										</span>
										{localString("Sell Plan")}
									</span>
									<span className="glyphicon glyphicon-menu-right" />
								</button>
							</a>
						)}
					</span>

					<span>
						{accessFn("ENABLE_RENEWAL") &&
							(!selectedPSL?.PartnerServiceLocationID?.length ? (
								accessFn("PLAN_SALE_ADMIN_USER") ? (
									<a
										className="text-dark"
										href={
											baseUrl + "/plansales/planRenewal"
										}
									>
										<button className="plansalebtn btn footer-button col-sm-3 button-stack no-border-radius footer-pill-width">
											<span>
												<span>
													<img
														src={
															publicUrl +
															"/imgs/reset.png"
														}
														width={"30px"}
														style={{
															marginRight: "5px",
														}}
													/>
												</span>
												<a className="text-dark">
													{localString("Renew Plan")}
												</a>
											</span>
											<a className="glyphicon glyphicon-menu-right" />
										</button>
									</a>
								) : (
									<a
										className="text-dark"
										onClick={() => {
											setShowPSLModal(true);
										}}
									>
										<button className="plansalebtn btn footer-button col-sm-3 button-stack no-border-radius footer-pill-width">
											<span>
												<span>
													<img
														src={
															publicUrl +
															"/imgs/reset.png"
														}
														width={"30px"}
														style={{
															marginRight: "5px",
														}}
													/>
												</span>
												<a className="text-dark">
													{localString("Renew Plan")}
												</a>
											</span>
											<a className="glyphicon glyphicon-menu-right" />
										</button>
									</a>
								)
							) : (
								<a
									className="text-dark"
									href={baseUrl + "/plansales/planRenewal"}
								>
									<button className="plansalebtn btn footer-button col-sm-3 button-stack no-border-radius footer-pill-width">
										<span>
											<span>
												<img
													src={
														publicUrl +
														"/imgs/reset.png"
													}
													width={"30px"}
													style={{
														marginRight: "5px",
													}}
												/>
											</span>
											<a className="text-dark">
												{localString("Renew Plan")}
											</a>
										</span>
										<a className="glyphicon glyphicon-menu-right" />
									</button>
								</a>
							))}
					</span>

					<span>
						{accessFn("PLAN_SALES_BULK_UPLOAD") &&
							(!selectedPSL?.PartnerServiceLocationID?.length ? (
								accessFn("PLAN_SALE_ADMIN_USER") ? (
									<a
										className="text-dark"
										href={baseUrl + bulkUploadUrl}
									>
										<button className="plansalebtn btn footer-button col-sm-3 button-stack no-border-radius footer-pill-width">
											<span>
												<span>
													<img
														src={
															publicUrl +
															"/imgs/upload.png"
														}
														width={"30px"}
														style={{
															marginRight: "5px",
														}}
													/>
												</span>
												{localString("Bulk Upload")}
											</span>
											<span className="glyphicon glyphicon-menu-right" />
										</button>
									</a>
								) : (
									<a
										className="text-dark"
										onClick={() => {
											setShowPSLModal(true);
										}}
									>
										<button className="plansalebtn btn footer-button col-sm-3 button-stack no-border-radius footer-pill-width">
											<span>
												<span>
													<img
														src={
															publicUrl +
															"/imgs/upload.png"
														}
														width={"30px"}
														style={{
															marginRight: "5px",
														}}
													/>
												</span>
												{localString("Bulk Upload")}
											</span>
											<span className="glyphicon glyphicon-menu-right" />
										</button>
									</a>
								)
							) : (
								<a
									className="text-dark"
									href={baseUrl + bulkUploadUrl}
								>
									<button className="plansalebtn btn footer-button col-sm-3 button-stack no-border-radius footer-pill-width">
										<span>
											<span>
												<img
													src={
														publicUrl +
														"/imgs/upload.png"
													}
													width={"30px"}
													style={{
														marginRight: "5px",
													}}
												/>
											</span>
											{localString("Bulk Upload")}
										</span>
										<span className="glyphicon glyphicon-menu-right" />
									</button>
								</a>
							))}
					</span>
				</div>
			</div>

			{
				<Modal
					showModal={showPSLModal}
					showCloseBtn={
						selectedPSL?.PartnerServiceLocationID ? true : false
					}
					closeModal={() => setShowPSLModal(false)}
					className={"default-modal modal-width-psl"}
				>
					<Modal.Header>
						<h3
							className="mb20 text-center"
							style={{
								fontWeight: 600,
								marginTop: "auto",
								fontSize: "20px",
							}}
						>
							{localString("Search Store Location Name")}
						</h3>
					</Modal.Header>
					<Modal.Body className={"location-modal-body"}>
						<h4 className="text-left-box">
							{localString("Store Location Name")}
						</h4>
						<div>
							<Dropdown
								id="dropdown"
								value={tempSelectedPSL.ServiceLocationName}
								options={partnerLocationList}
								handleOnChange={(val) => {
									setTempSelectedPSL(val);
								}}
								handleOnSearch={(e) => {
									setPSLSearchText(e.target.value);
									return partnerLocationList;
								}}
								classObject={{
									container: "psl-dropdown",
								}}
								placeholder={localString("Enter minimum 3 characters to search")}
								labelFloat={false}
								searchText={localString("Enter minimum 3 characters to search")}
								customComponent={
									<span>
										<img
											className="search-icon"
											src={
												publicUrl +
												"/imgs/searchIcon.png"
											}
										/>
									</span>
								}
								showDownArrow={false}
								editable={false}
								showNoOptionsText={false}
								filterBy="value"
							/>
							{noResultsFound && (
								<div className="no-results-message">
									{localString("Sorry, we couldn't find the store you're looking for")}
								</div>
							)}
						</div>
					</Modal.Body>
					<Modal.Footer
						className={"modal-footer-common sales-footer"}
					>
						<Button
							type="submit"
							handleOnClick={() => {
								setShowPSLModal(false);
								dispatch(setSelectedPSL(tempSelectedPSL));
							}}
							className={
								"button button-primary mt20 max-width-range"
							}
							isDisabled={
								selectedPSL.ServiceLocationName ==
								tempSelectedPSL.ServiceLocationName
							}
						>
							{localString("Apply")}
						</Button>
					</Modal.Footer>
				</Modal>
			}
		</>
	);
};

export default PlanSalesHome;
