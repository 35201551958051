import React from 'react';
import {Table } from 'sfy-react';

const BuyoutDetailsTable = (props) => {
	const {buyoutRequestData, localString, currencySymbol} = props;

	const classObject = {
		table:"table table-striped complaint-details-table request-table"
	}

	return (
		<div className="request-panel request-complaint-div buyout-details ">
			{
				buyoutRequestData && 
				<Table className={classObject}>
					<Table.Body >
					{
						buyoutRequestData.Amount && 
						<Table.Row>
							<Table.Data className="table-keys text-capitalize">{localString("Reimbursement Amount")}</Table.Data>
							<Table.Data><span className="currency" dangerouslySetInnerHTML={{__html: currencySymbol }}></span> {buyoutRequestData.Amount} </Table.Data>
						</Table.Row>
					}
					{
						buyoutRequestData.PaymentType && 
						<Table.Row>
							<Table.Data className="table-keys text-capitalize">{localString("Payment Mode")}</Table.Data>
							<Table.Data>{buyoutRequestData.PaymentType == 'CHEQUE' ? 'Check' : buyoutRequestData.PaymentType}</Table.Data>
						</Table.Row>
					}
					{
						buyoutRequestData.ConsumerLegalName && 
						<Table.Row>
							<Table.Data className="table-keys text-capitalize">{localString("Payee Name")}</Table.Data>
							<Table.Data>{buyoutRequestData.ConsumerLegalName}</Table.Data>
						</Table.Row>
					}
					{
						buyoutRequestData.PaymentType == 'CHEQUE' && buyoutRequestData.TransactionID &&
						<Table.Row>
							<Table.Data className="table-keys text-capitalize">{localString("Check Number")}</Table.Data>
							<Table.Data>{buyoutRequestData.TransactionID}</Table.Data>
						</Table.Row>
					}
					</Table.Body>
				</Table>
			}
			{
				(!buyoutRequestData || (buyoutRequestData && Object.keys(buyoutRequestData).length == 0)) && 
				<div className="charges-panel-subheader ">{localString("No Reimbursement Details")}</div>
			}
		</div>
	)
}

export default BuyoutDetailsTable
