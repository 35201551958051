import React,{useState,useRef} from 'react';
import {Input,Radio,Dropdown,Button} from 'sfy-react';

import UploadFileInput from './UploadFileInput';

const DeviceInspectInput =(props)=>{
    const {localString,showModal,closeModal,replacementFulfillment,consumerServiceRequest, docArray,useDocServiceInspect,docServiceUrl,selectedPicType,addDocArray,removeAddedDoc,
        dispositionList,Status,refurbishmentFlowCheck,refurbishmentFlowNext,userID} = props;
	const [selectedDisposition,setSelectedDisposition] = useState('')
    const [showOtherInputForDisposition,setShowOtherInputForDisposition] = useState(false);
    const [otherInputForDisposition,setOtherInputForDisposition] = useState('')
    const [disableBtn,setDisableBtn] = useState(false);
    let picMaster = [
        { 'picType': "Front Image" },
        { 'picType': "Back Image" },
        { 'picType': "Top Image" },
        { 'picType': "Bottom Image" },
        { 'picType': "Left Image" },
        { 'picType': "Right Image" },
    ];

    const selectDisposition = (item) =>{
        setSelectedDisposition(item.disposition)
        setDisableBtn(true);
        if(item.disposition == "Others"){
            setShowOtherInputForDisposition(true);
            setOtherInputForDisposition('');
            setDisableBtn(false);
        }else{
            setShowOtherInputForDisposition(false);
        }
    }

    const checkDisposition =(data)=>{
        if(otherInputForDisposition.length>0){
            setDisableBtn(true)
        }
    }
    const completeDeviceInspect = ()=>{
        let data = {};
        if(refurbishmentFlowCheck){
            let docArr = []
            let obj={}
            if(docArray){
                docArray.filter(item=>{
                    obj = {
                        "tag": item && item.Tag,
                        "type": item && item.Type,
                        "fileUrl":item && item.FileUrl,
                        "filePath":item && item.FilePath,
                        "fileName":item && item.FileName,
                        "documentID":item && item.DocumentID
                    }
                    docArr.push(obj)
                })
            }
            data = {
                "consumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
                "status": 'QC completed',
                "remarks": otherInputForDisposition ? otherInputForDisposition : selectedDisposition ,
                "userID": userID,
                // "disposition": otherInputForDisposition ? otherInputForDisposition : selectedDisposition ,
                
                "documents":docArr
            }
            
            refurbishmentFlowNext(data);
            closeModal();
        }else{
            data = {
                "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
                "Status": Status,
                "Remarks": otherInputForDisposition ? otherInputForDisposition : selectedDisposition ,
                "Documents":docArray
            }
            replacementFulfillment(data);
            closeModal();
        }
        // let data = {
        //     "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
        //     "Status": Status,
        //     "Remarks": otherInputForDisposition ? otherInputForDisposition : selectedDisposition ,
        //     "Documents":docArray
        // }
        // replacementFulfillment(data);
        // closeModal();
    }
    return (
		<>
            <div className='modal-body'>
                <div className='request-filter'>
                    <h3>{localString("Upload Device Pictures")}</h3>
                    <div>
                        <div class="picture-type-label col-sm-12">
                            {localString("Select Picture Type")}
                        </div>
                        <div class="col-sm-12 upload-images-parent">
                            <ul className='row upload-images-ul'>
                                {
                                    picMaster && picMaster.map((pic, k)=>(
                                        <li className='image-col pic-list' key={k}>
                                            <UploadFileInput
                                                pic={pic}
                                                docArray={docArray}
                                                localString={localString}
                                                addDocArray={addDocArray}
                                                selectedPicType={selectedPicType}
                                                removeAddedDoc={removeAddedDoc}
                                            />
                                        </li>
                                    ))
                                }
                            </ul>	
                        </div>
                    </div>
                    {docArray.length == 0 && <div className = "col-sm-12 padding20" >
                        {localString("No pictures added.")}
                    </div>}
                    <br/>
                    <hr></hr>
                    <h3 className = "">{localString("Select Disposition")}</h3>
                    <div className = "docRow">
                        <div className='col-sm-12'>
                            <div className='row'>
                                {
                                dispositionList && dispositionList.length > 0 &&  dispositionList.map((item,i)=>(
                                    <div className='col-sm-12'>
                                        <div className='row checkbox-item text-left'>
                                            <label class = "check-label-ques">
                                                <input type="radio" name={item.dispositionGroup} id={i+"_"+item.dispositionGroup} onClick ={(e)=>selectDisposition(item)} checked={item.isSelect} />
                                                {item.disposition}
                                            </label> 
                                        </div>
                                        
                                    </div> 
                                ))
                                }
                            </div>
                            <div className='row'>
                                {
                                    showOtherInputForDisposition && 
                                    <Input
                                        value={otherInputForDisposition}
                                        // key={i}
                                        autoComplete='off'
                                        id='otherInputForDisposition'
                                        handleOnChange={(e) =>{
                                            setOtherInputForDisposition(e.target.value)
                                            setDisableBtn(false)
                                            checkDisposition(e.target.value)
                                        }}
                                        labelAlign='top'
                                    />
                                }
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12 col-md-9 col-sm-8'>
                        { disableBtn &&  <Button className='btn button-servify float-right ' handleOnClick={()=>completeDeviceInspect()}>{localString("Submit")}</Button>}
                    </div>
                </div>
            </div>
        </>
	)
}
export default DeviceInspectInput;

