import React, { useEffect, useState } from "react";
import { Button, Modal } from 'sfy-react';
import Swal from "sweetalert2";
import Papa from 'papaparse';

import { displayError, downloadCSV } from "../../Utility/Utils";
import ProductCatalogueStep from "./ProductCatalogueStep";

export default function BulkUploadStep1to4(props) {
	const { apiCall, publicUrl, localString, setLoader, pslList, planList, templateList } = props; 
	
	const qFilter = JSON.parse(props.qFilter)
	const [showModal, setShowModal] = useState(false);

	const downloadLocations = () => {
		let arr = pslList.map(psl => {
			let obj = {
				RetailLocationCode: psl.ServiceLocationCode,
				RetailLocationName: psl.ServiceLocationName
			}
			return obj
		})
		let csv = Papa.unparse({
			fields: [
				'RetailLocationCode',
				'RetailLocationName'
			],
			data: arr,
		});
		let CapsString
		let capital = Papa.parse(csv, {
			beforeFirstChunk: (chunk) => {
				let rows = chunk.split( /\r\n|\r|\n/ );
				let result = [];
				let row = rows[0];
				let a = row.split(',');
				for (let iCount = 0;iCount<a.length;iCount++) {
					let item = a[iCount].replace(/([a-z])([A-Z])/g, '$1 $2');
					result.push(item)
				}
				CapsString = result.toString().toUpperCase();
				rows[0] = CapsString;
				return rows.join("\r\n");
			},
		});
		let csvString = Papa.unparse(capital);
		downloadCSV(csvString, 'Retail Location.csv');
	}

	const downloadPlanCsv = () => {
		let arr = planList.map(plan => {
			let obj = {
				'PlanName': plan.PlanName,
				'PlanCode': plan.PlanCode,
				'ProductSubCategoryName': plan.ProductSubCategoryName,
				'BrandName': plan.BrandName,
				'TemplateName': plan.TemplateName,
				'TermsAndConditions': plan.TermsAndConditions
			};
			return obj;
		})
		let csv = Papa.unparse({
			fields: [
				'PlanName',
				'PlanCode',
				'ProductSubCategoryName',
				'BrandName',
				'TemplateName',
				'TermsAndConditions'
			],
			data: arr,
		});
		let CapsString
		let capital = Papa.parse(csv, {
			beforeFirstChunk: (chunk) => {
				let rows = chunk.split( /\r\n|\r|\n/ );
				let result = [];
				let row = rows[0];
				let a = row.split(',');
				for (let iCount = 0;iCount<a.length;iCount++) {
					let item = a[iCount].replace(/([a-z])([A-Z])/g, '$1 $2');
					result.push(item)
				}
				CapsString = result.toString().toUpperCase();
				rows[0] = CapsString;
				return rows.join("\r\n");
			},
		});
		let csvString = Papa.unparse(capital);
		downloadCSV(csvString, 'Plan Master.csv')
	}

	const downloadAllTemplates = (templateArr) => {
		if(templateArr.length) {
			templateArr.map(template => {
				window.open(template.TemplateLink, '_blank')
			})
		}
	}

	const downloadTemplate = (idArr) => {
		setLoader(true);
		let reqObj = {
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		};
		if(!idArr) {
			reqObj.TemplateMasterIDArray = templateList.map(a => a.TemplateMasterID);
		}else {
			reqObj.TemplateMasterIDArray = [idArr];	
		}
		apiCall({
			url: 'fetchPlanTemplateByID',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success) {
					if(result.data.length) {
						downloadAllTemplates(result.data);
					}else {
						Swal.fire({  
							icon: 'error',  
							text: localString('No template found'),  
						});
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	return (
		<>
			<h4 className='bulkUploadhHeader'>{localString('Steps for Bulk Upload')}</h4>
			<div className='step'>
				<div className='stepHeader'>{localString('Step 1:  Download the latest Location and Plan Master from below')}</div>
				<div className='stepContent'>
					<Button
						type='button'
						className='downloadBtn'
						onClick={() => {
							downloadLocations()
							downloadPlanCsv()
						}}
					>
						{localString('Download Files')}
						<div className="download-icon">
							<div className="download-image"></div>
						</div>
					</Button>
				</div>
			</div>
			<div className='step'>
				<div className='stepHeader'>{localString('Step 2:  Download the Product Catalog by selecting the Category and Brand below')}</div>
				<div className='stepContent'>
					<ProductCatalogueStep {...props} setLoader={setLoader}/>
				</div>
			</div>
			<div className='step'>
				<div className='stepHeader'>{localString('Step 3:  Download the bulk upload template from below')}</div>
				<div className='stepContent'>
					<Button
						type='button'
						className='downloadBtn'
						onClick={() => {
							templateList.length && templateList.length > 1 ? setShowModal(true) : downloadTemplate(templateList[0].TemplateMasterID)
						}}
					>
						{localString('Download Template')}
						<div className="download-icon">
							<div className="download-image"></div>
						</div>
					</Button>
				</div>
			</div>
			<div className='step'>
				<div className='stepHeader' style={{ paddingBottom: '20px' }}>
					{localString('Step 4:  Fill in the data according to ')} 
					<a href='#'>{localString('instructions on this page')}</a> 
				</div>
			</div>
			<Modal
				className='Filter-Modal bulkUploadModal'
				showModal={showModal}
				closeModal={() => setShowModal(false)}
				showCloseBtn={true}
			>
				<Modal.Header>
					<h2> {localString("Download Template")} </h2>
				</Modal.Header>
				<Modal.Body>
					<div className="templateList">
						{
							templateList && templateList.length > 0 && templateList.map(template => 
								<div className="" style={{ padding: '10px 15px' }}>
									<div className='uploadedFileBox'>
										<div className='row'>
											<div className='col-xs-1'>
												<img
													className='excelImg' 
													src={publicUrl + "/imgs/xlsx.png"} 
													alt="Upload" 
													width='30px' 
												/>
											</div>
											<div className='col-xs-9'>
												<div className='fileName templateFileName'>{template && template.TemplateName}</div>
											</div>
											<div className='col-xs-2' style={{ textAlign: 'end' }}>
												<Button
													type='button'
													className='downloadBtn'
													onClick={() => downloadTemplate(template.TemplateMasterID)}
												>
													<div className="download-icon">
														<div className="download-image"></div>
													</div>
												</Button>
											</div>
										</div>
									</div>
								</div>
							)
						}
					</div>
					{
						templateList && templateList.length < 1 && 
							<div className="text-center">
								<h4> {localString('No Templates Found')} </h4>
							</div>
					}
					{
						templateList.length > 1 && 
							<div className="text-center" style={{ marginTop: '25px' }}>
								<Button
									type='button'
									className='button-small servify-button'
									onClick={() => downloadTemplate()}
								>
									{localString('Download All')}
								</Button>
							</div>
					}
					
				</Modal.Body>
			</Modal>
		</>
	);
}