import React, { useState , useEffect} from 'react';
import SelectOutcomeUI from './SelectOutcomeUI';
import Swal from "sweetalert2";
import { checkNumberPattern,checkFloatPattern } from '../../../../../Utility/Utils';
const SelectOutcomeInput=(props)=>{
    const {apiCall, localString,showModal,consumerServiceRequest, setShowModal, AdvanceChargeApplicable , getRequestById,setLoader} = props;
	const [selectOutcomeModes,setSelectOutcomeModes] =  useState([]);
	const [selectedOption,setSelectedOption] = useState(null);
	const [mandatoryCost, setMandatoryCost] = useState()
	const [disableOptionSave,setDisableOptionSave] = useState(true);
	
	useEffect(()=>{
		if(selectedOption) {
            if(selectedOption.IsDevicePriceMandatory) {
                let disableOptionSave = mandatoryCost ? false : true;
				setDisableOptionSave(disableOptionSave);
            } else {
				setDisableOptionSave(false);
            }
        } else {
			setDisableOptionSave(true);
        }
	},[selectedOption,mandatoryCost])
	useEffect(() => {
        if(consumerServiceRequest && consumerServiceRequest.selectOutcomeModes && consumerServiceRequest.selectOutcomeModes.length > 0) {
			setSelectOutcomeModes(consumerServiceRequest.selectOutcomeModes);
        }
	}, [])
	const optionModeClick = (option) =>{
		setSelectedOption(option);
    };
	const closeOptionForm = () => {
		setShowModal({...showModal,showSelectOutcomeModal:false});
    };

	const onCostInput = (e) => {
		let cost = e.target.value;
		if(checkFloatPattern(cost)){
			setMandatoryCost(cost)
		}else if(cost == ''){
			setMandatoryCost('');
		}
    };
	const setManualModesForAdjudication = () => {
        setLoader(true);
		let data = {
            AdvanceChargeApplicable: AdvanceChargeApplicable ? true : false,
            ConsumerServiceRequestID:consumerServiceRequest.ConsumerServiceRequestID,
            Attributes: [
                            {DECISION_MATRIX_MODES: []}
                        ]
        };
        data.Attributes[0].DECISION_MATRIX_MODES.push(selectedOption.ModeID);
        if(selectedOption.IsDevicePriceMandatory) {
            data.Attributes.push({REPLACEMENT_COST: mandatoryCost})
        };
		apiCall({
            url: "setManualModesForAdjudication", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    getRequestById();
					closeOptionForm()
                } else {
                    Swal.fire({
                        title: '',
                        text: result.msg ? localString(result.msg) : localString("Something went wrong"),
                        icon: 'error',
                        confirmButtonText: localString('OK'),
                        cancelButtonText: localString('Cancel')
                    });
                }
            },
        });
    };

	const labelInputs =[
		{labelText:'1. Compare cost of repair vs. replacement'},
		{labelText:'2. Consider cost of logistics for each leg - customer to service centre, service centre to customer and/or cost of shipping replacement to customer'},
		{labelText:'3. Choose the outcome with most optimised cost'},
	]

	return (
		<>
            <SelectOutcomeUI 
				{...props}
				labelInputs={labelInputs}
				selectOutcomeModes={selectOutcomeModes}
				optionModeClick={optionModeClick}
				selectedOption={selectedOption}
				closeOptionForm={closeOptionForm}
				onCostInput={onCostInput}
				mandatoryCost={mandatoryCost}
				setManualModesForAdjudication={setManualModesForAdjudication}
				disableOptionSave={disableOptionSave}
			/>
        </>
	)
}
export default  SelectOutcomeInput;