import React,{useState, useEffect}  from 'react';
import ReactDOM from 'react-dom';
import PageContentWrapper from '../../../components/PageContentWrapper/PageContentWrapper';
import FilterConsumer from './../../../components/FilterInputs/FilterInput';
import UserDetailList from './UserDetailList';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import {displayError,checkEmail,checkProductUniqueID,checkName,checkMobile} from '../../../Utility/Utils';

const ViewPlanManagement = (props) => {
		const {accessFn, localString, apiCall, baseUrl} = props;
		let [filterData, setFilterData] = useState({
			FirstName: "",
			OrderID: "",
			MobileNo: "",
			EmailID:"",
			OrderItemID:"",
			ProductUniqueID:"",
			ProductUniqueID2:"",
			ContractID:"",
			PolicyID:"",
		});
		const [viewList , setViewList] = useState(false);
		const [disableApplyButton, setDisableApplyBtn] = useState(true);
		const [hideClearBtn ,setHideClearBtn] = useState(true);
		const [showLoader ,setLoader] = useState(false);
		const [userDetail, setUserDetails] = useState([]);
		const [pagination , setPagination] = useState({
			currentPage: 1,
			itemsPerPage: 15,
			maxPages: 5,
			totalItems:0,
			lastPage:0
		});

		const bgColor = {
			'On Hold': '#FDE4E1',
			'Test': '#E7F2FA',
			'Downgraded': '#F2F2F2',
			'Cancelled': '#FDE4E1',
			'Expired': '#FDE4E1',
			'Invalid': '#FDE4E1',
			'Purchased': '#FCF0DF',
			'Active': '#E6F3E5',
			'Registered': '#FCF0DF',
			'Cancellation Initiated': '#FDE4E1',
			'Refunded': '#FDE4E1',
			'Upgraded': '#EFEAFC',
			'Enrolled': '#FCF0DF',
			'Diagnosed': '#FCF0DF',
			'Benefits Availed': '#F2F2F2',
			'Verification Pending': '#FCF0DF',
			'Bundled': '#EFEAFC',
			'Under Review': '#FCF0DF',
			'Payment Pending': '#FCF0DF',
			'Under Cancellation': '#FDE4E1',
			'Reactivation_Diagnostics Pending': '#FCF0DF',
			'Reactivation_Under Review': '#FCF0DF',
			'Reactivation_Payment Pending':'#FCF0DF'
		}
	
		const textColor = {
			'On Hold': '#F54336',
			'Test': "#0073E7",
			'Downgraded': "#393939",
			'Cancelled': "#F54336",
			'Expired': "#F54336",
			'Invalid': "#F54336",
			'Purchased': "#FF9300",
			'Active': "#4EAF51",
			'Registered': "#FF9300",
			'Cancellation Initiated': "#F54336",
			'Refunded': "#F54336",
			'Upgraded': "#410099",
			'Enrolled': "#FF9300",
			'Diagnosed': "#FF9300",
			'Benefits Availed': "#393939",
			'Verification Pending': "#FF9300",
			'Bundled': "#410099",
			'Under Review': "#FF9300",
			'Payment Pending': "#FF9300",
			'Under Cancellation': '#F54336',
			'Reactivation_Diagnostics Pending': '#FF9300',
			'Reactivation_Under Review': '#FF9300',
			'Reactivation_Payment Pending':'#FF9300'
		}

		// const applyFilter = (e) => {
		// 	if(e){
		// 		e.preventDefault();
		// 	}
		// 	let data = filterData;
		// 	data.pagination = pagination;
		// 	setLoader(true);
		// 	apiCall({
		// 		url:'getSoldPlanCustomers',
		// 		data: data,
		// 		callBack:(result)=>{
		// 			setLoader(false);
		// 			if(result.success && result.data){
		// 				setUserDetails(result.data && result.data.soldPlans);
		// 				setPagination({...pagination,
		// 					totalItems:result.data && result.data.pagination && result.data.pagination.totalItems,
		// 					lastPage:result.data && result.data.pagination && result.data.pagination.lastPage
		// 				})
		// 				setViewList(true);
		// 				setHideClearBtn(false);
		// 			}else{
		// 				Swal.fire({
		// 					icon: 'error',
		// 					text: localString(displayError(result)),
		// 				});
		// 			}
		// 		}
		// 	});
		// };

		const applyFilter = (pageNumber) => {
			let data = {
				FirstName: filterData.FirstName,
				OrderID: filterData.OrderID,
				MobileNo: filterData.MobileNo,
				EmailID: filterData.EmailID.trim(),
				IMEI1: filterData.ProductUniqueID,
				IMEI2: filterData.ProductUniqueID2,
				ContractIDFromFrontend: filterData.ContractID,
				PolicyID: filterData.PolicyID,
			};
			data.pagination = {
				currentPage: pageNumber,
				itemsPerPage: pagination.itemsPerPage,
				maxPages: pagination.maxPages,
				totalItems: pagination.totalItems,
				lastPage: pagination.lastPage
			};
			setLoader(true);
			apiCall({
				url:'getSoldPlanCustomersData',
				data: data,
				callBack:(result)=>{
					setLoader(false);
					if(result.success && result.data){
						setUserDetails(result.data && result.data.PlanDetails && result.data.PlanDetails);
						setPagination({...pagination,
							totalItems:result.data && result.data.pagination && result.data.pagination.totalItems,
							lastPage:result.data && result.data.pagination && result.data.pagination.maxPages,
							currentPage:result.data && result.data.pagination && result.data.pagination.currentPage
						})
						if(result.data.pagination.totalItems === 1) {
							let url = baseUrl+'/plans/consumer/view?spid='+result.data.PlanDetails[0].SoldPlanID
							window.open(url, '_blank');
						}
						setViewList(true);
						setHideClearBtn(false);
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		};

		const setCurrentPage = (pageNo) => {
			applyFilter(pageNo)
		}

		let filterInputs = [
			{
				labelText:localString('Consumer Mobile'),
				dataKey:"MobileNo",
				showFilter:true,
				oninputFunc: (val) => {
					setFilterData({ ...filterData, ['MobileNo'] : val  });
				}
			},
			{
				labelText:localString('Consumer Name'),
				dataKey:"FirstName",
				showFilter:true,
				oninputFunc: (val) => {
					setFilterData({ ...filterData, ['FirstName'] : val  });
				}
			},
			{
				labelText:localString('Consumer Email'),
				dataKey:"EmailID",
				showFilter:accessFn('SHOW_VIEW_BUTTON'),
				oninputFunc: (val) => {
					setFilterData({ ...filterData, ['EmailID'] : val  });
				}
			},
			{
				labelText:localString('IMEI') + ' 1/' + localString('Serial Number'),
				dataKey:"ProductUniqueID",
				showFilter:true,
				oninputFunc: (val) => {
					setFilterData({ ...filterData, ['ProductUniqueID'] : val  });
				}
			},
			{
				labelText:localString('IMEI 2'),
				dataKey:"ProductUniqueID2",
				showFilter:true,
				oninputFunc: (val) => {
					setFilterData({ ...filterData, ['ProductUniqueID2'] : val  });
				}
			},
			{
				labelText:localString('Contract ID'),
				dataKey:"ContractID",
				showFilter:true,
				oninputFunc: (val) => {
					setFilterData({ ...filterData, ['ContractID'] : val  });
				}
			},
			{
				labelText:localString('Policy ID'),
				dataKey:"PolicyID",
				showFilter:true,
				oninputFunc: (val) => {
					setFilterData({ ...filterData, ['PolicyID'] : val  });
				}
			},
			{
				labelText:localString('Order ID'),
				dataKey:"OrderID",
				showFilter:true,
				oninputFunc: (val) => {
					setFilterData({ ...filterData, ['OrderID'] : val  });
				}
			},
			// {
			// 	labelText:localString('Order Item ID'),
			// 	dataKey:"OrderItemID",
			// 	showFilter:true,
			// 	oninputFunc: (val) => {
			// 			setFilterData({ ...filterData, ['OrderItemID'] : val  });
			// 	}
			// }
		];

		const clearSearchFunc = () =>{
			setFilterData({
				FirstName: "",
				OrderID: "",
				MobileNo: "",
				EmailID:"",
				OrderItemID:"",
				ProductUniqueID:"",
				ProductUniqueID2:"",
				ContractID:"",
				PolicyID:"",
			});
			setUserDetails([]);
			setViewList(false);
			setHideClearBtn(true);
		}

		const checkDisableApplyBtn = () => {
			if( filterData.MobileNo.length && checkMobile(filterData.MobileNo)
				|| (checkProductUniqueID(filterData.ProductUniqueID) && (filterData.ProductUniqueID.length >= 5 && filterData.ProductUniqueID.length <= 30))
				|| (checkProductUniqueID(filterData.ProductUniqueID2) && (filterData.ProductUniqueID2.length >= 5 && filterData.ProductUniqueID2.length <= 30))
				|| (filterData.FirstName.length > 0 && checkName(filterData.FirstName))
				|| filterData.EmailID.length > 5 && checkEmail(filterData.EmailID) 
				|| filterData.OrderID.length > 0 
				|| filterData.ContractID.length > 0
				|| filterData.PolicyID.length > 0 ){
					setDisableApplyBtn(false);
			} else {
					setDisableApplyBtn(true);
			}
		}

		useEffect(() => {
			checkDisableApplyBtn();
		}, [filterData]);

		const headings = [
			{
				title:localString("Consumer Name"),
				showHeading:true
			},
			{
				title:localString("Mobile Number"),
				showHeading:true
			},
			{
				title:localString("Email ID"),
				showHeading:true
			},
			{
				title:localString("IMEI/Serial Number"),
				showHeading:true
			},
			{
				title:localString("Contract ID"),
				showHeading:true
			},
			{
				title:localString("Policy ID"),
				showHeading:true
			},
			{
				title:localString("Plan Purchased"),
				showHeading:accessFn('SHOW_VIEW_BUTTON')
			},
			// {
			// 	title:localString("Validity"),
			// 	showHeading:accessFn('SHOW_VIEW_BUTTON')
			// },
			{
				title:localString("Activated On"),
				showHeading:accessFn('SHOW_VIEW_BUTTON')
			},
			{
				title:localString("Status"),
				showHeading:true
			},
			// {
			// 	title:localString("Status Message"),
			// 	showHeading:true
			// }
		]

		return (
			<>
				{showLoader &&
					ReactDOM.createPortal(
						<Loader />,
						document.getElementById("loader-root")
					)}
				<PageContentWrapper title={localString("Filter Consumers")}>
					<FilterConsumer
						{...props}
						filterData={filterData}
						disableApplyBtn={disableApplyButton}
						hideClearButton={hideClearBtn}
						clearSearch={clearSearchFunc}
						filterFunc={(e)=>{
							e.preventDefault();
							applyFilter(1)
						}}
						filterInputs={filterInputs}
					></FilterConsumer>
				</PageContentWrapper>
				{viewList && (
					<PageContentWrapper title={localString("User Details")}>
						<UserDetailList
							setCurrentPage={setCurrentPage}
							pagination={pagination}
							userDetailList={userDetail}
							headings={headings}
							bgColor={bgColor}
							textColor={textColor}
							{...props}
						></UserDetailList>
					</PageContentWrapper>
				)}
			</>
		);
}

export default ViewPlanManagement;
