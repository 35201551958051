import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import CloseVisitModal from '../JobUpdateModal/CloseVisit/CloseVisitModal';
import { SERVICETYPE } from '../../../../../Utility/Utils';
const ResolveModalInput=(props)=>{
    const {visitData,setVisitData,localString,actionVisitStatus,updateJobDetails,consumerServiceRequest,selectActionVisitStatus, visitDispositionDataPro,selectVisitDispositionPro, visitDispositionSelectedFlag,setVisitDispositionSelectedFlag,actionStatusProductiveSelected,setActionStatusProductiveSelected,isBoseFlow,
            setActionCode,nextModal}=props;
	const[displayButton,setDisplayButton]=useState(true);
	useEffect(()=>{
		checkCompleteVisitButton();
	},[visitData])
    
	const requestProStatus=(val)=>{
        setActionStatusProductiveSelected(val); 
    }

	const checkCompleteVisitButton=()=>{
		if(visitData && !isEmpty(visitData.visitDispositionStatus) && visitData.visitDispositionStatus.length>0 &&
			!isEmpty(visitData.actionStatus) && visitData.actionStatus.length>0 && !isEmpty( visitData.visitText) && visitData.visitText.length>0
		){
			setDisplayButton(false)
		}else{
			setDisplayButton(true);
		}
	}

	let visitInputs = [
		{
			labelText:localString('Visit Disposition Status'),
			dataKey:"visitDispositionStatus",
			showVisit:true,
			customClass:'marginB20',
			type :'Dropdown',
			dropdownList:selectVisitDispositionPro,
			oninputFunc: (val) => {
				setVisitDispositionSelectedFlag({...visitDispositionSelectedFlag,resolveVisitFlag:false});
				actionVisitStatus(val);
				setVisitData({...visitData,
					['visitDispositionStatus']:val.VisitStatus,
				});
				
			}
		},
		{
			labelText:localString('Action Status'),
			dataKey:"actionStatus",
			showVisit:true,
			customClass:visitDispositionSelectedFlag.resolveVisitFlag?'disable-input marginB20':'marginB20',
			type :'Dropdown',
			isDisabled:visitDispositionSelectedFlag.resolveVisitFlag,
			dropdownList:selectActionVisitStatus,
			oninputFunc: (val) => {
				requestProStatus(val);
				setVisitData({...visitData,
					['actionStatus']:val.ActionCode,
				});
				setActionCode(val.ActionCode)
				// switchFlows(val.ActionCode);
			}
		},
		{
			labelText:localString('Request Status'),
			type:'text',
			customClass:'marginB20',
			showVisit:true,
			text: actionStatusProductiveSelected && actionStatusProductiveSelected.Status ? actionStatusProductiveSelected.Status :'NA'
		},
		{
			labelText:localString('Remark'),
			type:'textarea',
			dataKey:'visitText',
			customClass:'marginB20',
			showVisit:true,
			inputComponent:'textarea' ,
			oninputFunc: (val) => {
				setVisitData({...visitData,
					['visitText']:val,
				});
			}
		},
	];

	const callUpdateJobDetails = () => {
		updateJobDetails('ResolvedModal')
	}

	let buttonInput=[	
		{
			showResolveVisit:true,
			labelTextNext: ([64].indexOf(consumerServiceRequest.ServiceTypeID) == -1) &&  !isBoseFlow && localString("Next"),
			labelTextComplete: (([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) || ([3].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && isBoseFlow)) && localString("Complete Visit"),
			displayButton:displayButton, 
			onButtonFun: (([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) || isBoseFlow) ? callUpdateJobDetails : nextModal
		}
	]
    return(
        <>
        
            <CloseVisitModal
				{...props} 
				visitInputs={visitInputs}
				visitData={visitData}
				buttonInput={buttonInput}
				visitDispositionDataPro={visitDispositionDataPro}
			/>
        </>
    )
}
export default ResolveModalInput;

