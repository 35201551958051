import React from 'react';
import {Modal} from 'sfy-react';
import UpdateOrderDetailsInput from './updateOrderDetailsInput';
import './updateOrderDetailsModal.scss';
const UpdateOrderDetailsModal=(props)=>{
    const {localString,showModal,closeModal,processRequest,changeBillingDetails} = props;
		return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
                <Modal.Header>
                    <h4 className='modal-title text-center'> {localString("Enter Replacement Order Details")} </h4>
                </Modal.Header>
                <Modal.Body>
                    <UpdateOrderDetailsInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default  UpdateOrderDetailsModal;