import React from 'react';
import {Loader, Dropdown, Button, Input} from 'sfy-react';
import UploadSection from './UploadSection';


const UploadingMode = (props) => {
    const {localString, identificationObjects, identityDocs, damageObjects, setDamageObjects, addDamageObj, checkIfDocsUploaded,
        damageDocs, additionalComments, setAdditionalComments, removeFile, addIdentityObj, setIdentificationObjects,
    } = props;

    return (
        <div>
            <div className="request-panel-details-tag request-issue-validation-div supporting-docs">
                <div className="row row-section">
                    <div className="col-sm-6 row-label">
                        <p>{localString("Proof Of Device Identification")}</p>
                    </div>
                    <div className="col-sm-6 add-docs-column border-left">
                        <div><p>{localString("Add Documents")}</p></div>
                        {
                            identificationObjects && identificationObjects.map((singleObj, i)=>{
                                return(
                                    singleObj &&
                                    <div className="clearfix upload-section">
                                        <div className="col-sm-6">
                                            <Dropdown 
                                                id={"id-doc-selected-"+i}
                                                name="id-doc-selected"
                                                value={singleObj.proofOfDevice}
                                                options={identityDocs}
                                                handleOnChange={(data) => {
                                                    let arr = [...identificationObjects];
                                                    arr[i].proofOfDevice = data.DocumentType
                                                    arr[i].ParentDocumentType = data.ParentDocumentType
                                                    arr[i].DocID = data.DocumentID
                                                    setIdentificationObjects(arr)
                                                }}
                                                filterBy='value'
                                                showDownArrow={false}
                                            />
                                        </div>
                                        <UploadSection {...props} removeClick={removeFile} singleObj={singleObj} index={i} id="identity-file-" originalArr={identificationObjects} setterOgArr={setIdentificationObjects} sectionName="identity-file"/>
                                    </div>
                                )
                            })
                        }
                        <div className="add-btn-div clearfix">
                            <Button 
                                className="btn" 
                                handleOnClick={addIdentityObj}
                            >{localString("+ Add Another")} 
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="row row-section">
                    <div className="col-sm-6 row-label">
                        <p>{localString("Proof Of Damage/Issue")}</p>
                    </div>
                    <div className="col-sm-6 add-docs-column border-left">
                        <div><p>{localString("Add Documents")}</p></div>
                        {
                            damageObjects && damageObjects.map((singleObj, i) => {
                                return(
                                    singleObj && 
                                    <div className="clearfix upload-section" >
                                        <div className="col-sm-6">
                                            <Dropdown 
                                                id={"damage-doc-selected-"+i}
                                                name="damage-doc-selected"
                                                value={singleObj.proofOfDamage}
                                                options={damageDocs}
                                                handleOnChange={(data) => {
                                                    let arr = [...damageObjects];
                                                    arr[i].proofOfDamage = data.DocumentType
                                                    setDamageObjects(arr)
                                                }}
                                                filterBy='value'
                                                showDownArrow={false}
                                            />
                                        </div>
                                        <UploadSection {...props} removeClick={removeFile} singleObj={singleObj} index={i} id="damage-file-" originalArr={damageObjects} setterOgArr={setDamageObjects} sectionName="damage-file" />
                                    </div>
                                )
                            })
                        }
                        <div className="add-btn-div">
                            <Button 
                                className="btn" 
                                handleOnClick={addDamageObj}
                            >{localString("+ Add Another")} 
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="row row-section">
                    <div className="col-sm-6 row-label">
                        <p>{localString("Additional Comments")}</p>
                    </div>
                    <div className="col-sm-6 row-label border-left input-padding">
                        <div>
                            <Input
                                className="form-control charges-form-input"
                                id="additional-comments"
                                value={additionalComments}
                                handleOnChange={(e) => {
                                    setAdditionalComments(e.target.value)
                                }}
                                maxLength={150}
                            />
                        </div>
                    </div>
                </div>
                <div className="">
                    <Button 
                        className="btn button-servify-solid"
                        handleOnClick={checkIfDocsUploaded}
                        text={localString("Save")}
                    />
                </div>
            </div>
        </div>
    )
}

export default UploadingMode
