import React,{useState,useEffect} from 'react';
import {Dropdown} from 'sfy-react';
import CallHistory from './CallHistory';
import EmailHistory from './EmailHistory';
import "./CommunicationHistory.scss"

const CommunicationHistory = (props) => {
	const {communicationLogs,localString,getCallStatus,emailHistoryData,showCallHistory,showEmailHistory} = props;
	const [callHistory, setCallHistory] = useState(showCallHistory);
	const [emailHistory, setEmailHistory] = useState(showEmailHistory);
	const [communicationItems, setCommunicationItems] = useState("All Data");

	const communicationLabels = [{
		value:"All Data"
	},{
		value:"Call History"
	},{
		value:"Email History"
	}];  

	useEffect(() => {
		if(showEmailHistory){
			if(communicationItems === "All Data"){
				setCallHistory(true)
				setEmailHistory(true)
			}else if(communicationItems === "Email History"){
				setCallHistory(false)
				setEmailHistory(true)
			}else if(communicationItems === "Call History"){
				setEmailHistory(false)
				setCallHistory(true)
			}
		}
	}, [communicationItems])

	return(
		<>
		{
			<>
				<div className="communication-item comm-history">{localString("Communication history")}
				{showEmailHistory ? <div className="btn-group comm-drop">
					<Dropdown
						value={communicationItems}
						options={communicationLabels}
						handleOnChange={(e) => {
							setCommunicationItems(e.value)
						}}
						showDownArrow={true}
					/>
					</div> : ""}
				</div>
				{
					showCallHistory && callHistory  && <CallHistory {...props} communicationLogs={communicationLogs} getCallStatus={getCallStatus}></CallHistory>
				}
				{
					showEmailHistory && emailHistory && <EmailHistory {...props} emailHistoryData={emailHistoryData}></EmailHistory>
				}
			</>
		}
		</>
	)
}

export default CommunicationHistory
