
import React from 'react';
import {Input,Button,Dropdown} from 'sfy-react';
import IntlTelInputWrapper from '../../../components/IntlTelInputWrapper/IntlTelInputWrapper';

const AddConsumer = (props) => {
	const {saveFunc,ConsumerFields,disableSaveBtn,addConsumerData,localString,hideViewButton,viewConsumerFunc,hideSaveButton} = props;

	return (
		<div className="clearfix">
			<form onSubmit={saveFunc}>
			<div className="col-sm-12">
			{ConsumerFields && ConsumerFields.length>0 && ConsumerFields.map((Consumer, index) => {
				return(
					Consumer && Consumer.showInput && <div className={Consumer.customClass?Consumer.customClass:'col-sm-4 paddingB10'}>
						{
						(!Consumer.type || Consumer.type=='input') &&<Input
								value={addConsumerData[Consumer.dataKey]}
								key={index}
								handleOnChange={(e) => {
									Consumer.oninputFunc(e.target.value);
								}}
								label={Consumer.labelText}
								labelAlign='top'
								placeholder={Consumer.placeholder}
							/>
						}
						{
							Consumer.type && Consumer.type == 'Dropdown' && <div className="form-control--top ">
								<label className="input__label ">{Consumer.labelText}</label>
								<Dropdown
									id='dropdown'
									value={addConsumerData[Consumer.dataKey]}
									options={Consumer.dropdownList}
									handleOnChange={(data) => {
										Consumer.oninputFunc(data);
									}}
									filterBy='value'
									showDownArrow={false}
									searchText={Consumer.searchText}
									placeholder={Consumer.placeholder}
								/>
							</div>
						}
						{
							Consumer.type && Consumer.type == 'intelInput' && <div className="form-control--top ">
								<IntlTelInputWrapper 
									value = {addConsumerData[Consumer.dataKey]}
									handleOnChange = {(val, countryData) => Consumer.changeFunc(val, countryData)}
									onFlagSelect = {(countryData) => Consumer.onFlagSelect(countryData)}
									label = {Consumer.labelText}
									maxLength = {Consumer.maxlength}
									key={index}
									intlTelInputClassWrapper={Consumer.intlTelInputClassWrapper}
									defaultCountry={Consumer.defaultCountry}
									placeholder={Consumer.placeholder}
								></IntlTelInputWrapper>
							</div>
						}
						
						</div>
				)	
			})}
			</div>
			<div className="button-container">
				{!hideSaveButton && <Button handleOnClick={saveFunc} isDisabled={disableSaveBtn} type='submit' classes='button-ghost' text={localString('Save')}></Button>}
				{ !hideViewButton && <Button handleOnClick={viewConsumerFunc} type='button' classes='button-ghost' text={localString('View Consumer')}></Button> }
			</div>
			</form>
		</div>
	) 
}

export default AddConsumer;