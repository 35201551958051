import React,{useEffect, useState} from 'react';
import {Modal,Input,Button} from 'sfy-react';
import { checkAlphaNumeric,regexTest } from '../../../Utility/Utils';
import './RunDiagnosisModal.scss';
const RunDiagnosisInputs=(props)=>{
    const {localString,fetchDiagnosisSuits,diagnosticsResultClick,closeModal, diagnosticsSuitsClick,fetchDiagnosisResults,serialNumber,setSerialNumber,diagnosticsResult,arrDiagnosisSuites,initiateDiagnosis,disableDiagnosis,setDisableDiagnosis} = props;
    // const getSerialNo=(e)=>{
    //     if(serialNumber.length >= 12 && serialNumber.length <= 15){
    //         setDisableDiagnosis(false);
    //     }else{
    //         setDisableDiagnosis(true);
    //     }
    // }
    
    useEffect(()=>{
        if(serialNumber.length >= 12 && serialNumber.length <= 15){
            setDisableDiagnosis(false);
        }else{
            setDisableDiagnosis(true);
        }
    },[serialNumber])
    return (
        <>
            <div className="modal-body clearfix">           
                <div className="col-sm-12">
                    <div className="diagnosis-row row">
                        <div className='col-sm-12'>
                            <Input
                                value={serialNumber}
                                handleOnChange={(e) => {
                                let serialNo=e.target.value
                                    if(checkAlphaNumeric(serialNo)){
                                        setSerialNumber(serialNo);
                                    }else if(serialNo==''){
                                        setSerialNumber('');
                                    }
                                }}
                                type="text"
                                id="sno"
                                maxLength="15"
                                // otherProps={{onInput:(e)=>getSerialNo()}}
                                label={localString("Enter Serial Number / IMEI")}
                                labelAlign='top'
                                placeholder={localString("Enter Serial Number / IMEI")}
                            />
                        </div>
                        <div className="col-sm-12 tabButton-container">
                            <Button  className="btn button-servify capitalize"  isDisabled={disableDiagnosis} handleOnClick={()=>fetchDiagnosisSuits()}>{localString("Fetch Diagnostics Suites")}</Button>
                            <Button  className="btn button-servify capitalize" isDisabled={disableDiagnosis} handleOnClick={()=>fetchDiagnosisResults()}>{localString("Fetch Latest Diagnostics Results")}</Button>
                        </div>
                        { diagnosticsResult && diagnosticsResultClick &&
                            <div>
                                <div className="col-sm-12 diagnostics-result">
                                    {
                                        diagnosticsResult.diagnosticsEventNumber && <div className="row divider-margin">
                                            <div className="col-sm-6"><label>{localString("Diagnostics Event Number")}</label></div>
                                            <div className="col-sm-6">{diagnosticsResult.diagnosticsEventNumber}</div>
                                        </div>
                                    }
                                    {
                                        diagnosticsResult && diagnosticsResult.result && diagnosticsResult.result.diagnosticTestResults   && diagnosticsResult.result.diagnosticTestResults.testResultsData.map(item=>(
                                            <>
                                                <div className="col-sm-12"><label>{localString(item.moduleName)}</label></div>
                                                <div className="col-sm-6"><p>{localString(item.testName)}</p></div>
                                                <div className={'col-sm-6 ' + ((item.testStatus == 'PASSED' || item.testStatus.indexOf("PASSED")>-1) ? 'green' : ((item.testStatus == 'FAILED' || item.testStatus.indexOf("FAILED")>-1) ? 'red' : 'orange'))}><p>{localString(item.testStatus)}</p></div>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                        {
                            arrDiagnosisSuites && diagnosticsSuitsClick && <div className="col-sm-12">
                                {
                                    arrDiagnosisSuites.map(item=>(
                                        <Button className="btn button-servify capitalize" handleOnClick={()=>initiateDiagnosis(item.suiteName)}>{item.suiteName}</Button>
                                    ))
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className='pull-right tabButton-container'>
                    <Button className="btn button-servify" handleOnClick={()=>closeModal()} >{localString("Close")}</Button>
                </div>
            </div>
        </>
    )
}
export default RunDiagnosisInputs;
