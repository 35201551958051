import React from 'react';
import {Modal} from 'sfy-react';
import InitiateBERPaymentInput from './InitiateBERPaymentInput';
import './InitiateBERPaymentModal.scss';
const InitiateBERPaymentModal =(props)=>{
    const {localString,showModal,closeModal,addedFraudFlag} = props;
		return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
                <Modal.Header>
                    <h4 className="modal-title text-center">{localString("Refund will be processed based on below details")}:</h4>
                </Modal.Header>
                <Modal.Body>
                    <InitiateBERPaymentInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default InitiateBERPaymentModal;