const lang = {
    "Payment Received": {
        "text": "Zahlung erhalten",
        "isAlreadyTranslated": true
    },
    "Plan Validity": {
        "text": "Gültigkeit der Versicherung",
        "isAlreadyTranslated": true
    },
    "Final Plan Price": {
        "text": "Endgültiger Versicherungspreis",
        "isAlreadyTranslated": true
    },
    "Product Value": {
        "text": "Produktwert",
        "isAlreadyTranslated": true
    },
    "Payment": {
        "text": "Zahlung",
        "isAlreadyTranslated": true
    },
    "Damage Protection": {
        "text": "Schadensschutz",
        "isAlreadyTranslated": true
    },
    "View Plans": {
        "text": "Verischerungen anzeigen",
        "isAlreadyTranslated": true
    },
    "TOTAL COMMISSION": {
        "text": "GESAMTBETRAG KOMMISSION",
        "isAlreadyTranslated": true
    },
    "Total Commission": {
        "text": "GESAMTBETRAG KOMMISSION",
        "isAlreadyTranslated": true
    },
    "Congratulations! Your device is now registered and the plan is now activated for your device.": {
        "text": "Herzlichen Glückwunsch! Ihr Gerät ist jetzt registriert und die Versicherung für Ihr Gerät ist jetzt aktiviert.",
        "isAlreadyTranslated": true
    },
    "Agreement Number": {
        "text": "Vertragsnummer",
        "isAlreadyTranslated": true
    },
    "Your Plan is now pending for Activation.": {
        "text": "Die Aktivierung Ihrer Versicherung wird jetzt überprüft",
        "isAlreadyTranslated": true
    },
    "Congratulations! The customer has successfully registered their device under Samsung Care+ 1 Year Accidental and Liquid Damage Protection Plan. Please help the customer to activate the plan.": {
        "text": "Herzlichen Glückwünsch! Der Kunde hat erfolgreich sein Gerät registriert für die Samsung Care+ 1-Jahres-Versicherung für Unfall- und Flüssigkeitsschäden registriert. Bitte helfen Sie dem Kunden, die Versicherung zu aktivieren.",
        "isAlreadyTranslated": true
    },
    "Customer Email ID": {
        "text": "Kunden E-Mail-ID",
        "isAlreadyTranslated": true
    },
    "Customer Mobile No": {
        "text": "Kunden Mobilnummer",
        "isAlreadyTranslated": true
    },
    "Warranty End Date": {
        "text": "Enddatum der Garantie",
        "isAlreadyTranslated": true
    },
    "Product Value cannot be less than 500": {
        "text": "Der Produktwert darf nicht weniger als 500 . betragen",
        "isAlreadyTranslated": true
    },
    "Sell now": {
        "text": "Verkaufen",
        "isAlreadyTranslated": true
    },
    "Sell Now": {
        "text": "Verkaufen",
        "isAlreadyTranslated": true
    },
    "Know more": {
        "text": "Details",
        "isAlreadyTranslated": true
    },
    "Know More": {
        "text": "Details",
        "isAlreadyTranslated": true
    },
    "Transferred": {
        "text": "Übertragen",
        "isAlreadyTranslated": true
    },
    "Please ensure the below address is correct": {
        "text": "Bitte vergewissern Sie sich, dass die folgende Adresse korrekt ist",
        "isAlreadyTranslated": true
    },
    "Preview Address": {
        "text": "Vorschau Adresse",
        "isAlreadyTranslated": true
    },
    "Replacement options provided": {
        "text": "Austausch Optionen verfügbar",
        "isAlreadyTranslated": true
    },
    "Pending for reimbursement": {
        "text": "Ausstehend für Erstattung",
        "isAlreadyTranslated": true
    },
    "Replacement authorized": {
        "text": "Austauschgerät genehmigt",
        "isAlreadyTranslated": true
    },
    "Replacement unavailable": {
        "text": "Austausch nicht verfügbar",
        "isAlreadyTranslated": true
    },
    "In-home request accepted": {
        "text": "Anfrage von zu Hause aus akzeptiert",
        "isAlreadyTranslated": true
    },
    "Okay, Got it": {
        "text": "Okay, verstanden",
        "isAlreadyTranslated": true
    },
    "Order ID Details": {
        "text": "Details zur Bestell-ID",
        "isAlreadyTranslated": true
    },
    "Enter Mobile number/Email ID": {
        "text": "Handynummer/E-Mail Adresse eingeben",
        "isAlreadyTranslated": true
    },
    "Active Plans": {
        "text": "Aktive Pläne",
        "isAlreadyTranslated": true
    },
    "Active": {
        "text": "Aktive",
        "isAlreadyTranslated": true
    },
    "valid till": {
        "text": "gültig bis",
        "isAlreadyTranslated": true
    },
    "covered under": {
        "text": "abgedeckt unter",
        "isAlreadyTranslated": true
    },
    "Overview" : {
        "text": "Übersicht",
        "isAlreadyTranslated": true
    },
    "Session Expired": {
        "text": "Session abgelaufen",
        "isAlreadyTranslated": true
    },
    "Your session has expired. The store location needs to be selected again, so you will be redirected to the home page shortly.": {
        "text": "Ihre Session ist abgelaufen. Der Speicherort muss erneut ausgewählt werden. Sie werden in Kürze zur Startseite zurückgeleitet.",
        "isAlreadyTranslated": true
    },
    "Enter Device Attributes": {
        "text": "Geräteattribute eingeben",
        "isAlreadyTranslated": true
    },
    "Storage": {
        "text": "Speicherplatz",
        "isAlreadyTranslated": true
    },
    "Colour": {
        "text": "Farbe",
        "isAlreadyTranslated": true
    },
    "Skip": {
        "text": "Überspringen",
        "isAlreadyTranslated": true
    },
    "Select a Coverage": {
        "text": "Wählen Sie die Versicherung",
        "isAlreadyTranslated": true
    },
    "Select All": {
        "text": "Wählen",
        "isAlreadyTranslated": true
    },

    "Accidental Damage": {
        "text": "Unfallschaden",
        "isAlreadyTranslated": true
    },
    "Coverage Start Date": {
        "text": "Datum des Versicherungsbeginns",
        "isAlreadyTranslated": true
    },
    "Theft": {
        "text": "Diebstahl",
        "isAlreadyTranslated": true
    },
    "Location of Incident": {
        "text": "Ort des Schadenfalls",
        "isAlreadyTranslated": true
    },
    "City name/Short address": {
        "text": "Stadt/ Adresse",
        "isAlreadyTranslated": true
    },
    "Communication Details": {
        "text": "Kommunikation Details",
        "isAlreadyTranslated": true
    },
    "Use Same Contact Details": {
        "text": "Gleiche Kontaktdaten verwenden",
        "isAlreadyTranslated": true
    },
    "Use Different Contact Details": {
        "text": "Andere Kontaktdaten verwenden",
        "isAlreadyTranslated": true
    },
    "Calling Number": {
        "text": "Telefonnummer",
        "isAlreadyTranslated": true
    },
    "Support Ticket Number": {
        "text": "Support-Ticket Nummer",
        "isAlreadyTranslated": true
    },
    "Communication history": {
        "text": "Kommunikationsverlauf",
        "isAlreadyTranslated": true
    },
    "Initiate": {
        "text": "anfordern",
        "isAlreadyTranslated": true
    },
    "Update Logistics Request": {
        "text": "Logistikanfrage aktualisieren",
        "isAlreadyTranslated": true
    },
    "Customer's Contact name": {
        "text": "Name des Kunden",
        "isAlreadyTranslated": true
    },
    "Customer's Contact Number": {
        "text": "Kontaktnummer des Kunden",
        "isAlreadyTranslated": true
    },
    "Service Center Contact Number": {
        "text": "Kontaktnummer des Servicecenters",
        "isAlreadyTranslated": true
    },
    "Please select a reason for cancelling": {
        "text": "Bitte wählen Sie einen Grund für die Stornierung",
        "isAlreadyTranslated": true
    },
    "Communication Name": {
        "text": "Name der Mitteilung",
        "isAlreadyTranslated": true
    },
    "Communication Number": {
        "text": "Nummer der Mitteilung",
        "isAlreadyTranslated": true
    },
    "Communication EmailID": {
        "text": "E-Mail Adresse der Mitteilung",
        "isAlreadyTranslated": true
    },
    "Your claim request has been accepted. We will notify you about the resolution being offered shortly": {
        "text": "Ihr Antrag wurde angenommen. Wir werden Sie in Kürze über Ihre Möglichkeiten zur Abwicklung informieren.",
        "isAlreadyTranslated": true
    },
    "We have received your claim request. We will notify you once it is approved": {
        "text": "Wir haben Ihren Antrag erhalten. Wir werden Sie benachrichtigen, sobald der Antrag genehmigt ist.",
        "isAlreadyTranslated": true
    },
    "Waiting for customer to make the payment for advance deductible": {
        "text": "Wir warten darauf, dass der Kunde die Zahlung für die Selbstbeteiligung vornimmt",
        "isAlreadyTranslated": true
    },
    "Payment link has been sent to the user on registered mobile number and/or Email ID": {
        "text": "Der Zahlungslink wurde an die registrierte Handynummer und/oder E-Mail Adresse des Kunden gesendet.",
        "isAlreadyTranslated": true
    },
    "Select Pickup Address": {
        "text": "Abholadresse auswählen",
        "isAlreadyTranslated": true
    },
    "Payment link has been sent to the user on registered mobile number and/or Email ID": {
        "text": "Der Zahlungslink wurde an die registrierte Handynummer und/oder die E-Mail Adresse des Benutzers gesendet.",
        "isAlreadyTranslated": true
    },
    "test cancelled": {
        "text": "Test abgebrochen",
        "isAlreadyTranslated": true
    },
    "Service Mode": {
        "text": "Service Optionen",
        "isAlreadyTranslated": true
    },
    "Slot and Date": {
        "text": "Zeitfenster und Datum",
        "isAlreadyTranslated": true
    },
    "Preview Address": {
        "text": "Vorschau Adresse",
        "isAlreadyTranslated": true
    },
    "Please ensure the below address is correct": {
        "text": "Bitte stellen Sie sicher, dass die unten angegebene Adresse korrekt ist",
        "isAlreadyTranslated": true
    },
    "Proceed": {
        "text": "Weiter",
        "isAlreadyTranslated": true
    },
    "Street address": {
        "text": "Straße und Hausnummer",
        "isAlreadyTranslated": true
    },
    "Street address is required": {
        "text": "Adresse ist erforderlich",
        "isAlreadyTranslated": true
    },
    "Postal code": {
        "text": "Postleitzahl",
        "isAlreadyTranslated": true
    },
    "Postal code is required": {
        "text": "Postleitzahl ist erforderlich",
        "isAlreadyTranslated": true
    },
    "City/Town": {
        "text": "Stadt/Ort",
        "isAlreadyTranslated": true
    },
    "City/Town is required": {
        "text": "Stadt/Ort ist erforderlich",
        "isAlreadyTranslated": true
    },
    "Please choose one of the reimbursement options below": {
        "text": "Bitte wählen Sie eine der folgenden Erstattungsoptionen",
        "isAlreadyTranslated": true
    },
    "Inform customer they will get link to upload video, post which reimbursement process will start as we are not asking for the defective device back": {
        "text": "Informieren Sie den Kunden, dass der Kunde einen Link zum Hochladen eines Videos erhält. Anschließend beginnt der Erstattungsprozess, da wir das defekte Gerät nicht zurückverlangen",
        "isAlreadyTranslated": true
    },
    "Location Name": {
        "text": "Standort Name",
        "isAlreadyTranslated": true
    },
    "Send for raising a request": {
        "text": "Senden für die Erstellung einer Anfrage",
        "isAlreadyTranslated": true
    },
    "The request was raised successfully": {
        "text": "Die Anfrage wurde erfolgreich gestellt",
        "isAlreadyTranslated": true
    },
    "Communication Name": {
        "text": "Kommunikationsname",
        "isAlreadyTranslated": true
    },
    "Communication Number": {
        "text": "Kommunikationsnummer",
        "isAlreadyTranslated": true
    },
    "Communication EmailID": {
        "text": "Kommunikations-E-Mail-ID",
        "isAlreadyTranslated": true
    },
    "DropOff request accepted": {
        "text": "Abgabeanfrage akzeptiert",
        "isAlreadyTranslated": true
    },
    "Shipment Charge": {
        "text": "Versandkosten",
        "isAlreadyTranslated": true
    },
    "Delivery Address": {
        "text": "Lieferadresse",
        "isAlreadyTranslated": true
    },
    "Additional Remarks": {
        "text": "Zusätzliche Bemerkungen",
        "isAlreadyTranslated": true
    },
    "ID e-mail": {
        "text": "ID-E-Mail",
        "isAlreadyTranslated": true
    },
    "update logistics request": {
        "text": "Logistikanfrage aktualisieren",
        "isAlreadyTranslated": true
    },
    "OK": {
        "text": "OK",
        "isAlreadyTranslated": true
    },
    "Vendor tracking URL": {
        "text": "Anbieter-Tracking-URL",
        "isAlreadyTranslated": true
    },
    "Click here for Vendor Tracking URL": {
        "text": "Klicken Sie hier für die Anbieter-Tracking-URL",
        "isAlreadyTranslated": true
    },
    "Contact number": {
        "text": "Kontakt Nummer",
        "isAlreadyTranslated": true
    },
    "Yes": {
        "text": "ja",
        "isAlreadyTranslated": true
    },
    "Out of Warranty": {
        "text": "Garantie abgelaufen",
        "isAlreadyTranslated": true
    },
    "Under Warranty": {
        "text": "Unter Garantie",
        "isAlreadyTranslated": true
    },
    "save": {
        "text": "speichern",
        "isAlreadyTranslated": true
    },
    "No": {
        "text": "Nein",
        "isAlreadyTranslated": true
    },
    "Warranty Concession": {
        "text": "Garantiekonzession",
        "isAlreadyTranslated": true
    },
    "0 request(s) have open task(s)": {
        "text": "0 Anfrage(n) haben offene Aufgabe(n)",
        "isAlreadyTranslated": true
    },
    "Select brand": {
        "text": "Marke auswählen",
        "isAlreadyTranslated": true
    },
    "Enter the IMEI / Serial Number": {
        "text": "Geben Sie die IMEI ein",
        "isAlreadyTranslated": true
    },
    "Sorry, your product could not be validated, please contact admin for support.": {
        "text": "Es tut uns leid, Ihr Produkt konnte nicht validiert werden. Bitte kontaktieren Sie den Versicherungsverwalter für Unterstützung.",
        "isAlreadyTranslated": true
    },
    "Claim Carryin": {
        "text": "Abgabe in einer Geschäftsstelle",
        "isAlreadyTranslated": true
    },
    "Claim Pickup": {
        "text": "Anspruch abholen",
        "isAlreadyTranslated": true
    },
    "Pick Up": {
        "text": "Zustellung",
        "isAlreadyTranslated": true
    },
    "Trade In Dropoff": {
        "text": "Trade in Abgabe",
        "isAlreadyTranslated": true
    },
    "Advance Exchange w/o Defective": {
        "text": "Vorabtausch ohne Mängel",
        "isAlreadyTranslated": true
    },
    "Claim Dropoff": {
        "text": "Dropoff in einer Geschäftsstelle",
        "isAlreadyTranslated": true
    },
    "Customers": {
        "text": "Kunden",
        "isAlreadyTranslated": true
    },
    "Filter": {
        "text": "Filter",
        "isAlreadyTranslated": true
    },
    "Consumer Mobile": {
        "text": "Handynummer",
        "isAlreadyTranslated": true
    },
    "Consumer Email": {
        "text": "Kunden E-Mail",
        "isAlreadyTranslated": true
    },
    "Subcategory": {
        "text": "Unterkategorie",
        "isAlreadyTranslated": true
    },
    "Brand": {
        "text": "Marke",
        "isAlreadyTranslated": true
    },
    "Product": {
        "text": "Produkt",
        "isAlreadyTranslated": true
    },
    "IMEI Number 1": {
        "text": "IMEI Nummer 1",
        "isAlreadyTranslated": true
    },
    "IMEI Number 2": {
        "text": "IMEI Nummer 2",
        "isAlreadyTranslated": true
    },
    "Apply": {
        "text": "Zustimmen",
        "isAlreadyTranslated": true
    },
    "Diagnosis": {
        "text": "Diagnose",
        "isAlreadyTranslated": true
    },
    "Mobile Number": {
        "text": "Handynummer",
        "isAlreadyTranslated": true
    },
    "OR": {
        "text": "ODER",
        "isAlreadyTranslated": true
    },
    "Serial Number": {
        "text": "Seriennummer",
        "isAlreadyTranslated": true
    },
    "Serial Number/IMEI": {
        "text": "Seriennummer/IMEI",
        "isAlreadyTranslated": true
    },
    "Jobsheet No./Reference ID": {
        "text": "Arbeitsblatt-Nr./Referenz-ID",
        "isAlreadyTranslated": true
    },
    "Notifications": {
        "text": "Benachrichtigungen",
        "isAlreadyTranslated": true
    },
    "Coupons": {
        "text": "Gutscheine",
        "isAlreadyTranslated": true
    },
    "Last Month:": {
        "text": "Letzter Monat:",
        "isAlreadyTranslated": true
    },
    "Redeemed Coupons": {
        "text": "Eingelöste Gutscheine",
        "isAlreadyTranslated": true
    },
    "Last Week:": {
        "text": "Letzte Woche:",
        "isAlreadyTranslated": true
    },
    "Last Day:": {
        "text": "Letzter Tag:",
        "isAlreadyTranslated": true
    },
    "Create and Edit Coupons": {
        "text": "Coupons erstellen und bearbeiten",
        "isAlreadyTranslated": true
    },
    "Plan Management": {
        "text": "Versicherungs Management",
        "isAlreadyTranslated": true
    },
    "Filter Consumers": {
        "text": "Filter Kunden",
        "isAlreadyTranslated": true
    },
    "Mobile Number": {
        "text": "Handynummer",
        "isAlreadyTranslated": true
    },
    "IMEI/Serial Number": {
        "text": "IMEI/Seriennummer",
        "isAlreadyTranslated": true
    },
    "Email ID": {
        "text": "E-Mail Adresse",
        "isAlreadyTranslated": true
    },
    "Order Item ID": {
        "text": "Bestellung Artikel-ID",
        "isAlreadyTranslated": true
    },
    "Order ID": {
        "text": "Bestell-ID",
        "isAlreadyTranslated": true
    },
    "Apply": {
        "text": "Weiter",
        "isAlreadyTranslated": true
    },
    "Sell plans": {
        "text": "Versicherung verkaufen",
        "isAlreadyTranslated": true
    },
    "Sell Plans": {
        "text": "Versicherung verkaufen",
        "isAlreadyTranslated": true
    },
    "Select brand": {
        "text": "Marke auswählen",
        "isAlreadyTranslated": true
    },
    "SAMSUNG PLANS": {
        "text": "SAMSUNG-CARE+",
        "isAlreadyTranslated": true
    },
    "Exceptional Sales": {
        "text": "Sonderverkäufe",
        "isAlreadyTranslated": true
    },
    "Protect": {
        "text": "Schutz",
        "isAlreadyTranslated": true
    },
    "Damage protection": {
        "text": "Schutz vor Beschädigungen",
        "isAlreadyTranslated": true
    },
    "User list": {
        "text": "Benutzerliste",
        "isAlreadyTranslated": true
    },
    "USER MANAGEMENT": {
        "text": "BENUTZERVERWALTUNG",
        "isAlreadyTranslated": true
    },
    "Invite Service User": {
        "text": "Dienstleister einladen",
        "isAlreadyTranslated": true
    },
    "Invite Retailer user": {
        "text": "Einzelhändler einladen",
        "isAlreadyTranslated": true
    },
    "Search by Email": {
        "text": "Suche per E-Mail",
        "isAlreadyTranslated": true
    },
    "LOGISTICS REQUESTS": {
        "text": "LOGISTIKANFORDERUNGEN",
        "isAlreadyTranslated": true
    },
    "WayBill Number": {
        "text": "Frachtbrief Nummer",
        "isAlreadyTranslated": true
    },
    "Reference Number": {
        "text": "Referenznummer",
        "isAlreadyTranslated": true
    },
    "Pickup Name": {
        "text": "Abholung Name",
        "isAlreadyTranslated": true
    },
    "Pickup Address": {
        "text": "Abholadresse",
        "isAlreadyTranslated": true
    },
    "Drop Name": {
        "text": "Name",
        "isAlreadyTranslated": true
    },
    "Drop Address": {
        "text": "Adresse",
        "isAlreadyTranslated": true
    },
    "Status": {
        "text": "Status",
        "isAlreadyTranslated": true
    },
    "Pickup Contact No": {
        "text": "Abholung Kontakt-Nr.",
        "isAlreadyTranslated": true
    },
    "Drop Contact No": {
        "text": "Zustellung Kontakt-Nr.",
        "isAlreadyTranslated": true
    },
    "Create Request": {
        "text": "Anfrage erstellen",
        "isAlreadyTranslated": true
    },
    "Filter Requests": {
        "text": "Filter Anforderungen",
        "isAlreadyTranslated": true
    },
    "CREATE SHIPMENT": {
        "text": "SENDUNG ERSTELLEN",
        "isAlreadyTranslated": true
    },
    "*All fields are mandatory.": {
        "text": "*Alle Felder sind obligatorisch.",
        "isAlreadyTranslated": true
    },
    "Product Detail": {
        "text": "Produkt Detail",
        "isAlreadyTranslated": true
    },
    "Brand": {
        "text": "Marke",
        "isAlreadyTranslated": true
    },
    "Product": {
        "text": "Produkt",
        "isAlreadyTranslated": true
    },
    "IMEI/Serial No": {
        "text": "IMEI/Seriennummer",
        "isAlreadyTranslated": true
    },
    "Pick Up Detail": {
        "text": "Abholung Detail",
        "isAlreadyTranslated": true
    },
    "Name": {
        "text": "Name",
        "isAlreadyTranslated": true
    },
    "Contact No.": {
        "text": "Kontakt Nr.",
        "isAlreadyTranslated": true
    },
    "Address": {
        "text": "Adresse",
        "isAlreadyTranslated": true
    },
    "Landmark": {
        "text": "Adresszusatz",
        "isAlreadyTranslated": true
    },
    "Pin Code": {
        "text": "Postleitzahl",
        "isAlreadyTranslated": true
    },
    "City": {
        "text": "Stadt",
        "isAlreadyTranslated": true
    },
    "State": {
        "text": "Staat",
        "isAlreadyTranslated": true
    },
    "Country": {
        "text": "Land",
        "isAlreadyTranslated": true
    },
    "Drop Detail": {
        "text": "Detail",
        "isAlreadyTranslated": true
    },
    "Name": {
        "text": "Name",
        "isAlreadyTranslated": true
    },
    "Contact No.": {
        "text": "Kontakt Nr.",
        "isAlreadyTranslated": true
    },
    "Address": {
        "text": "Adresse",
        "isAlreadyTranslated": true
    },
    "Landmark": {
        "text": "Adresszusatz",
        "isAlreadyTranslated": true
    },
    "Pin Code": {
        "text": "Postleitzahl",
        "isAlreadyTranslated": true
    },
    "City": {
        "text": "Stadt",
        "isAlreadyTranslated": true
    },
    "State": {
        "text": "Staat",
        "isAlreadyTranslated": true
    },
    "Country": {
        "text": "Land",
        "isAlreadyTranslated": true
    },
    "Filter": {
        "text": "Filter",
        "isAlreadyTranslated": true
    },
    "WayBill Number": {
        "text": "Frachtbrief Nummer",
        "isAlreadyTranslated": true
    },
    "Reference Number": {
        "text": "Referenznummer",
        "isAlreadyTranslated": true
    },
    "Pickup Name": {
        "text": "Abholung Name",
        "isAlreadyTranslated": true
    },
    "Pickup Address": {
        "text": "Abholadresse",
        "isAlreadyTranslated": true
    },
    "Drop Name": {
        "text": "Name ",
        "isAlreadyTranslated": true
    },
    "Drop Address": {
        "text": "Adress",
        "isAlreadyTranslated": true
    },
    "Status": {
        "text": "Status",
        "isAlreadyTranslated": true
    },
    "Pickup Contact No": {
        "text": "Abholung Kontakt-Nr.",
        "isAlreadyTranslated": true
    },
    "Drop Contact No": {
        "text": "Zustellung Kontakt-Nr.",
        "isAlreadyTranslated": true
    },
    "Status": {
        "text": "Status",
        "isAlreadyTranslated": true
    },
    "Vendor": {
        "text": "Anbieter",
        "isAlreadyTranslated": true
    },
    "Flow": {
        "text": "Prozess",
        "isAlreadyTranslated": true
    },
    "IMEI": {
        "text": "IMEI",
        "isAlreadyTranslated": true
    },
    "Apply": {
        "text": "Weiter",
        "isAlreadyTranslated": true
    },
    "Clear": {
        "text": "Löschen",
        "isAlreadyTranslated": true
    },
    "I have explained the Terms and Conditions and Privacy Policy to the customer, and help them read the IPID before moving ahead with contract activation": {
        "text": "Ich habe dem Kunden die Allgemeinen Geschäftsbedingungen und die Datenschutzrichtlinie erklärt und ihm geholfen, die IPID zu lesen, bevor er mit der Vertragsaktivierung beginnt.",
        "isAlreadyTranslated": true
    },
    "Protect": {
        "text": "Schutz  ",
        "isAlreadyTranslated": true
    },
    "Something went wrong": {
        "text": "Etwas ist schief gelaufen",
        "isAlreadyTranslated": true
    },
    "Customer Mobile No:": {
        "text": "Kunden Handynummer:",
        "isAlreadyTranslated": true
    },
    "Customer Email ID": {
        "text": "Kunden E-Mail",
        "isAlreadyTranslated": true
    },
    "Customer Date of Birth": {
        "text": "Geburtsdatum des Kunden",
        "isAlreadyTranslated": true
    },
    "Warranty End Date": {
        "text": "Enddatum der Garantie",
        "isAlreadyTranslated": true
    },
    "Product Value:": {
        "text": "Produktwert:",
        "isAlreadyTranslated": true
    },
    "Plan Validity": {
        "text": "Gültigkeit der Versicherung",
        "isAlreadyTranslated": true
    },
    "Final Plan Price": {
        "text": "Endgültiger Preis",
        "isAlreadyTranslated": true
    },
    "Privacy Policy": {
        "text": "Datenschutzbestimmungen",
        "isAlreadyTranslated": true
    },
    "Payment Received": {
        "text": "Erhaltene Zahlung",
        "isAlreadyTranslated": true
    },
    "Payment": {
        "text": "Zahlung",
        "isAlreadyTranslated": true
    },
    "Invalid OTP": {
        "text": "Ungültiges OTP",
        "isAlreadyTranslated": true
    },
    "Resend OTP": {
        "text": "OTP erneut senden",
        "isAlreadyTranslated": true
    },
    "View Plans": {
        "text": "Überblick der Versicherungen",
        "isAlreadyTranslated": true
    },
    "Date of Birth": {
        "text": "Geburtsdatum",
        "isAlreadyTranslated": true
    },
    "IPID": {
        "text": "IPID",
        "isAlreadyTranslated": true
    },
    "Product Value:": {
        "text": "Produktwert: ",
        "isAlreadyTranslated": true
    },
    "The Plan is now pending for Activation": {
        "text": "Die Aktivierung Ihrer Versicherung wird jetzt überprüft",
        "isAlreadyTranslated": true
    },
    "Congratulations!": {
        "text": "Herzlichen Glückwünsch!",
        "isAlreadyTranslated": true
    },
    "The customer has successfully registered their device under Samsung Care + Planul de protecție împotriva daunelor accidentale și lichide de 1 an.": {
        "text": "Der Kunde hat erfolgreich sein Gerät für Samsung Care+ für Unfall- und Flüssigkeitsschäden für 1 Jahr registriert.",
        "isAlreadyTranslated": true
    },
    "Please help the customer to activate the plan.": {
        "text": "Bitte helfen Sie dem Kunden, die Versicherung zu aktivieren.",
        "isAlreadyTranslated": true
    },
    "Selected Coverage": {
        "text": "Ausgewählter Versicherungsschutz",
        "isAlreadyTranslated": true
    },
    "Physical damage": {
        "text": "Physischer Schaden",
        "isAlreadyTranslated": true
    },
    "One Time Deductible(Admin Fees)": {
        "text": "Einmaliger Selbstbehalt (Verwaltungsgebühren)",
        "isAlreadyTranslated": true
    },
    "Location Of Incident": {
        "text": "Ort des Unfalls",
        "isAlreadyTranslated": true
    },
    "successfully purchased": {
        "text": "erfolgreich gekauft",
        "isAlreadyTranslated": true
    },
    "Successfully Purchased": {
        "text": "Erfolgreich Gekauft",
        "isAlreadyTranslated": true
    },
    "Purchased": {
        "text": "Gekauft",
        "isAlreadyTranslated": true
    },
    "Customer Mobile No": {
        "text": "Kunden Handynummer",
        "isAlreadyTranslated": true
    },
    "Phone Will Not Hold a Charge": {
        "text": "Telefon wird nicht aufgeladen",
        "isAlreadyTranslated": true
    },
    "Select Drop Address": {
        "text": "Abgabeort auswählen",
        "isAlreadyTranslated": true
    },
    "Customer's Contact name": {
        "text": "Kontaktname des Kunden",
        "isAlreadyTranslated": true
    },
    "Customer's Contact Number": {
        "text": "Kontaktnummer des Kunden",
        "isAlreadyTranslated": true
    },
    "Service Center Contact Number": {
        "text": "Kontaktnummer des Servicecenters",
        "isAlreadyTranslated": true
    },
    "Calling Number": {
        "text": "Telefonnummer",
        "isAlreadyTranslated": true
    },
    "Support Ticket Number:": {
        "text": "Kundensupport Ticket Nummer:",
        "isAlreadyTranslated": true
    },
    "Something went wrong": {
        "text": "Etwas ist schief gelaufen",
        "isAlreadyTranslated": true
    },
    "Scheduled date": {
        "text": "Geplantes Datum",
        "isAlreadyTranslated": true
    },
    "The course": {
        "text": "Der Kurs",
        "isAlreadyTranslated": true
    },
    "Flow": {
        "text": "",
        "isAlreadyTranslated": true
    },
    "Provider": {
        "text": "",
        "isAlreadyTranslated": true
    },
    "No consumer product found for this consumer service request": {
        "text": "Kein Gerät für diese Serviceanfrage gefunden",
        "isAlreadyTranslated": true
    },
    "Servify-Retail-Portal": {
        "text": "Servify Einzelhändler Portal",
        "isAlreadyTranslated": true
    },
    "Please select a reason for cancelling": {
        "text": "Bitte wählen Sie einen Grund für die Stornierung",
        "isAlreadyTranslated": true
    },
    "Sorry": {
        "text": "Entschuldigung",
        "isAlreadyTranslated": true
    },
    "Pickup request accepted": {
        "text": "Abholauftrag angenommen",
        "isAlreadyTranslated": true
    },
    "Resend OTP in": {
        "text": "OTP erneut senden",
        "isAlreadyTranslated": true
    },
    "PSČ is reqiured": {
        "text": "",
        "isAlreadyTranslated": true
    },
    "Existing Data Found": {
        "text": "Bestehende Daten gefunden",
        "isAlreadyTranslated": true
    },
    "We recognise your account and found existing data in it. We will use the same to recheck eligibility for this plan.": {
        "text": "Wir haben bestehende Daten für dieses Gerät gefunden und werden diese zur erneuten Prüfung des Schadensanspruchs für diese Versicherung prüfen.",
        "isAlreadyTranslated": true
    },
    "Please enter Product Value": {
        "text": "Bitte geben Sie den Produktwert an",
        "isAlreadyTranslated": true
    },
    "is required": {
        "text": "ist erforderlich",
        "isAlreadyTranslated": true
    },
    "Sign in to continue.": {
        "text": "Die Eingabe ist verpflichtend",
        "isAlreadyTranslated": true
    },
    "Forgot Password?": {
        "text": "Passwort vergessen?",
        "isAlreadyTranslated": true
    },
    "sign in": {
        "text": "Anmelden",
        "isAlreadyTranslated": true
    },
    "Copyright © 2015 - 2022 Service Lee Technologies Pvt Ltd.All rights reserved. Also known as Servify.": {
        "text": "Copyright © 2015-2022 Service Lee Technologies Pvt Ltd. All rights reserved. Auch bekannt als Servify.",
        "isAlreadyTranslated": true
    },
    "Please enter Product Value": {
        "text": "Bitte geben Sie den Produktwert an",
        "isAlreadyTranslated": true
    },
    "is required": {
        "text": "ist erforderlich",
        "isAlreadyTranslated": true
    },
    "Declaration": {
        "text": "Einverständniserklä",
        "isAlreadyTranslated": true
    },
    "Sorry, check back soon. We're in process of adding more products.": {
        "text": "Entschuldigung, schauen Sie bald wieder vorbei. Wir sind dabei, weitere Produkte hinzuzufügen.",
        "isAlreadyTranslated": true
    },
    "View Transaction Summary": {
        "text": "Transaktionsübersicht anzeigen",
        "isAlreadyTranslated": true
    },
    "Samsung Plans": {
        "text": "Samsung Versicherung",
        "isAlreadyTranslated": true
    },
    "Samsung Planuri": {
        "text": "Samsung Versicherung",
        "isAlreadyTranslated": true
    },
    "Dashboard": {
        "text": "Dashboard",
        "isAlreadyTranslated": true
    },
    "Logout": {
        "text": "Abmelden",
        "isAlreadyTranslated": true
    },
    "Transaction Summary": {
        "text": "Transaktionsübersicht",
        "isAlreadyTranslated": true
    },
    "Select Category": {
        "text": "Kategorie auswählen",
        "isAlreadyTranslated": true
    },
    "Close": {
        "text": "Schließen",
        "isAlreadyTranslated": true
    },
    "Contact Information": {
        "text": "Kontaktinformationen",
        "isAlreadyTranslated": true
    },
    "Send OTP": {
        "text": "Einmalpasswort (OTP)  senden",
        "isAlreadyTranslated": true
    },
    "Back": {
        "text": "Zurück",
        "isAlreadyTranslated": true
    },
    "Enter the OTP sent to": {
        "text": "Einmalpasswort (OTP)  eingeben",
        "isAlreadyTranslated": true
    },
    "Confirm OTP": {
        "text": "Einmalpasswort (OTP)  bestätigen",
        "isAlreadyTranslated": true
    },
    " Plans": {
        "text": " Versicherungen",
        "isAlreadyTranslated": true
    },
    "Plan Name": {
        "text": "Versicherungsname",
        "isAlreadyTranslated": true
    },
    "Price": {
        "text": "Preis",
        "isAlreadyTranslated": true
    },
    "Serial Number": {
        "text": "Seriennummer",
        "isAlreadyTranslated": true
    },
    "Check Eligibility": {
        "text": "Anspruchsberechtigung prüfen",
        "isAlreadyTranslated": true
    },
    "Select Plans": {
        "text": "Versicherungen auswählen",
        "isAlreadyTranslated": true
    },
    "Sell": {
        "text": "Verkaufen",
        "isAlreadyTranslated": true
    },
    "Period": {
        "text": "Zeitraum",
        "isAlreadyTranslated": true
    },
    "Transaction Status": {
        "text": "Transaktionsstatus",
        "isAlreadyTranslated": true
    },
    "completed": {
        "text": "abgeschlossen",
        "isAlreadyTranslated": true
    },
    "Completed": {
        "text": "Abgeschlossen",
        "isAlreadyTranslated": true
    },
    "Pending": {
        "text": "in Bearbeitung",
        "isAlreadyTranslated": true
    },
    "Failed": {
        "text": "fehlgeschlagen",
        "isAlreadyTranslated": true
    },
    "Report is empty.": {
        "text": "Der Bericht ist leer.",
        "isAlreadyTranslated": true
    },
    "Plan": {
        "text": "Versicherung",
        "isAlreadyTranslated": true
    },
    "Invoice": {
        "text": "Rechnung",
        "isAlreadyTranslated": true
    },
    "Mobile No.": {
        "text": "Mobilfunknummer",
        "isAlreadyTranslated": true
    },
    "Alternative Mobile No": {
        "text": "Alternative Mobilfunknummer",
        "isAlreadyTranslated": true
    },
    "Brands": {
        "text": "Marken",
        "isAlreadyTranslated": true
    },
    "Products": {
        "text": "Produkte",
        "isAlreadyTranslated": true
    },
    "Upload Bulk Plan": {
        "text": "Alle Versicherungen hochladen",
        "isAlreadyTranslated": true
    },
    "Please download the sample file, make changes in it only and upload it": {
        "text": "Bitte laden Sie die Beispieldatei herunter, nehmen Sie Ihre Änderungen daran vor und laden Sie es erneut hoch.",
        "isAlreadyTranslated": true
    },
    ".csv files only": {
        "text": "nur .csv Dateien",
        "isAlreadyTranslated": true
    },
    "Download Sample File": {
        "text": "Beispieldatei herunterladen",
        "isAlreadyTranslated": true
    },
    "Upload File": {
        "text": "Datei hochladen",
        "isAlreadyTranslated": true
    },
    "Submit File": {
        "text": "Datei senden",
        "isAlreadyTranslated": true
    },
    "Cancel": {
        "text": "Abbrechen",
        "isAlreadyTranslated": true
    },
    "Custom Range": {
        "text": "Benutzerdefinierter Bereich",
        "isAlreadyTranslated": true
    },
    "Today": {
        "text": "Heute",
        "isAlreadyTranslated": true
    },
    "Yesterday": {
        "text": "Gestern",
        "isAlreadyTranslated": true
    },
    "Last 7 Days": {
        "text": "Letzte 7 Tage",
        "isAlreadyTranslated": true
    },
    "Last 30 Days": {
        "text": "Letzte 30 Tage",
        "isAlreadyTranslated": true
    },
    "This Month": {
        "text": "Dieser Monat",
        "isAlreadyTranslated": true
    },
    "Last Month": {
        "text": "Letzter Monat",
        "isAlreadyTranslated": true
    },
    "Total Value": {
        "text": "Gesamtwert",
        "isAlreadyTranslated": true
    },
    "Total Count": {
        "text": "Gesamtzahl",
        "isAlreadyTranslated": true
    },
    "Device is eligible!": {
        "text": "Das Gerät ist berechtigt!",
        "isAlreadyTranslated": true
    },
    "Device is eligible for multiple plans. Please choose a plan.": {
        "text": "Das Gerät ist für mehrere Tarife zugelassen. Bitte wählen Sie einen Tarif aus.",
        "isAlreadyTranslated": true
    },
    "Verify Device": {
        "text": "Gerät verifizieren",
        "isAlreadyTranslated": true
    },
    "Customer Details": {
        "text": "Kundendetails",
        "isAlreadyTranslated": true
    },
    "Review Order": {
        "text": "Bestellung überprüfen",
        "isAlreadyTranslated": true
    },
    "Order Summary": {
        "text": "Zusammenfassung der Bestellung",
        "isAlreadyTranslated": true
    },
    "Enter your mobile number registered with Paytm": {
        "text": "Geben Sie Ihre bei Paytm registrierte Mobilfunknummer ein",
        "isAlreadyTranslated": true
    },
    "We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.": {
        "text": "Wir erklären, dass diese Rechnung den tatsächlichen Preis der beschriebenen Waren angibt und dass alle Angaben wahrheitsgemäß und korrekt sind.",
        "isAlreadyTranslated": true
    },
    "Tax Invoice": {
        "text": "Steuerquittung",
        "isAlreadyTranslated": true
    },
    "Details of Receiver (Billed to)": {
        "text": "Angaben zum Empfänger (Rechnungsempfänger)",
        "isAlreadyTranslated": true
    },
    "Details of Consignee (Shipped to)": {
        "text": "Angaben zum Empfänger (Versendet an)",
        "isAlreadyTranslated": true
    },
    "Sl. No": {
        "text": "Sl. Nr.",
        "isAlreadyTranslated": true
    },
    "Description of Goods/Services": {
        "text": "Beschreibung der Waren/Dienstleistungen",
        "isAlreadyTranslated": true
    },
    "HSN/SAC Code": {
        "text": "HSN/SAC Code",
        "isAlreadyTranslated": true
    },
    "Qty": {
        "text": "Menge",
        "isAlreadyTranslated": true
    },
    "Rate": {
        "text": "Rate",
        "isAlreadyTranslated": true
    },
    "Total": {
        "text": "Gesamt",
        "isAlreadyTranslated": true
    },
    "Discount": {
        "text": "Rabatt",
        "isAlreadyTranslated": true
    },
    "Taxable value": {
        "text": "Steuerpflichtiger Wert",
        "isAlreadyTranslated": true
    },
    "CGST": {
        "text": "CGST",
        "isAlreadyTranslated": true
    },
    "Amount": {
        "text": "Betrag",
        "isAlreadyTranslated": true
    },
    "SGST": {
        "text": "SGST",
        "isAlreadyTranslated": true
    },
    "IGST": {
        "text": "IGST",
        "isAlreadyTranslated": true
    },
    "Invoice Total": {
        "text": "Rechnungssumme",
        "isAlreadyTranslated": true
    },
    "Advance Received": {
        "text": "Erhaltene Vorschusszahlungen",
        "isAlreadyTranslated": true
    },
    "Payable Value": {
        "text": "zu zahlender Betrag",
        "isAlreadyTranslated": true
    },
    "Email POC to below email address": {
        "text": "POC per E-Mail an untenstehende E-Mail Adresse senden",
        "isAlreadyTranslated": true
    },
    "Send a copy to below email address": {
        "text": "Kopie an untenstehende E-Mail Adresse senden",
        "isAlreadyTranslated": true
    },
    "Add email-id": {
        "text": "E-Mail Adresse hinzufügen",
        "isAlreadyTranslated": true
    },
    "Send": {
        "text": "Senden",
        "isAlreadyTranslated": true
    },
    "Are you sure you want to cancel the order?": {
        "text": "Sind Sie sicher, dass Sie die Bestellung stornieren wollen?",
        "isAlreadyTranslated": true
    },
    "Note : Please request Customer to complete the diagnosis process in 60 minutes": {
        "text": "Hinweis: Bitte fordern Sie den Kunden auf, den Diagnoseprozess in 60 Minuten abzuschließen",
        "isAlreadyTranslated": true
    },
    "Go to Transaction Summary": {
        "text": "Gehen Sie zur Transaktionsübersicht",
        "isAlreadyTranslated": true
    },
    "Please do not close the window or press the back button.": {
        "text": "Bitte schließen Sie das Fenster nicht und drücken Sie nicht die Zurück Taste.",
        "isAlreadyTranslated": true
    },
    "Date of Purchase": {
        "text": "Datum des Kaufs",
        "isAlreadyTranslated": true
    },
    "Congratulations! Your device is eligible for this plan": {
        "text": "Herzlichen Glückwunsch! Das Gerät ist für diesen Tarif berechtigt",
        "isAlreadyTranslated": true
    },
    "This Device is Eligible for this Plan": {
        "text": "Dieses Gerät ist für diesen Tarif berechtigt",
        "isAlreadyTranslated": true
    },
    "Product Name": {
        "text": "Produktname",
        "isAlreadyTranslated": true
    },
    "Validity": {
        "text": "Gültigkeitsdauer",
        "isAlreadyTranslated": true
    },
    "validity": {
        "text": "Gültigkeitsdauer",
        "isAlreadyTranslated": true
    },
    "Price of Plan": {
        "text": "Preis des Tarifs",
        "isAlreadyTranslated": true
    },
    "Enter a location": {
        "text": "Geben Sie einen Standort ein",
        "isAlreadyTranslated": true
    },
    "Landmark is required": {
        "text": "Landmark is required",
        "isAlreadyTranslated": true
    },
    "City is required": {
        "text": "Stadt ist erforderlich",
        "isAlreadyTranslated": true
    },
    "State is required": {
        "text": "Dieses Feld ist erforderlich",
        "isAlreadyTranslated": true
    },
    "Home": {
        "text": "Zuhause",
        "isAlreadyTranslated": true
    },
    "Office": {
        "text": "Büro",
        "isAlreadyTranslated": true
    },
    "Other": {
        "text": "Sonstiges",
        "isAlreadyTranslated": true
    },
    "Transaction Details": {
        "text": "Transaktionsdetails",
        "isAlreadyTranslated": true
    },
    "Device Details": {
        "text": "Gerätedetails",
        "isAlreadyTranslated": true
    },
    "Device Images": {
        "text": "Gerätebilder",
        "isAlreadyTranslated": true
    },
    "(All 6 Images are Mandatory)": {
        "text": "(Alle 6 Bilder sind notwendig)",
        "isAlreadyTranslated": true
    },
    "Device Invoice": {
        "text": "Geräterechnung",
        "isAlreadyTranslated": true
    },
    "Device Image": {
        "text": "Gerätebild",
        "isAlreadyTranslated": true
    },
    "Save": {
        "text": "Speichern",
        "isAlreadyTranslated": true
    },
    "Continue": {
        "text": "Weiter",
        "isAlreadyTranslated": true
    },
    "Minimum length is two characters": {
        "text": "Mindestens zwei Zeichen",
        "isAlreadyTranslated": true
    },
    "Please enter correct Email ID": {
        "text": "Bitte geben Sie die korrekte E-Mail Adresse ein",
        "isAlreadyTranslated": true
    },
    "Please enter correct Mobile No": {
        "text": "Bitte geben Sie die korrekte Mobilfunknummer ein",
        "isAlreadyTranslated": true
    },
    "Customer Name": {
        "text": "Kundenname",
        "isAlreadyTranslated": true
    },
    "Product Details": {
        "text": "Produkt Details",
        "isAlreadyTranslated": true
    },
    "Product Purchase Date": {
        "text": "Kaufdatum",
        "isAlreadyTranslated": true
    },
    "Plan Details": {
        "text": "Tarifdetails",
        "isAlreadyTranslated": true
    },
    "Plan Price": {
        "text": "Preis der Versicherung",
        "isAlreadyTranslated": true
    },
    "Sales Location": {
        "text": "Verkaufsort",
        "isAlreadyTranslated": true
    },
    "Sales Channel": {
        "text": "Verkaufskanal",
        "isAlreadyTranslated": true
    },
    "Product Category": {
		"text": "Produktkategorie",
		"isAlreadyTranslated": true
	},
    "Apologies! It seems we are experiencing some trouble but our team is working to fix it. Please try again in some time or contact our support team": {
        "text": "Entschuldigung! Es scheint ein Problem zu geben, aber unser Team arbeitet daran, es zu beheben. Bitte versuchen Sie es in einiger Zeit erneut oder kontaktieren Sie unser Support Team",
        "isAlreadyTranslated": true
    },
    "Consumer Name": {
        "text": "Kundenname",
        "isAlreadyTranslated": true
    },
    "Raise a request": {
        "text": "Serviceanfrage stellen",
        "isAlreadyTranslated": true
    },
    "show count": {
        "text": "Anzahl anzeigen",
        "isAlreadyTranslated": true
    },
    "IMIE/Serial No": {
        "text": "IMIE/Seriennummer",
        "isAlreadyTranslated": true
    },
    "Contact No": {
        "text": "Telefonnummer",
        "isAlreadyTranslated": true
    },
    "Search": {
        "text": "Suche",
        "isAlreadyTranslated": true
    },
    "Show Graph": {
        "text": "Grafik anzeigen",
        "isAlreadyTranslated": true
    },
    "Customer": {
        "text": "Kunde",
        "isAlreadyTranslated": true
    },
    "Device": {
        "text": "Gerät",
        "isAlreadyTranslated": true
    },
    "Service Type & Status": {
        "text": "Servicetyp & Status",
        "isAlreadyTranslated": true
    },
    "Schedule": {
        "text": "Zeitplan",
        "isAlreadyTranslated": true
    },
    "Created": {
        "text": "Erstellt",
        "isAlreadyTranslated": true
    },
    "View": {
        "text": "Anzeigen",
        "isAlreadyTranslated": true
    },
    "Claim pickup": {
        "text": "Abholung",
        "isAlreadyTranslated": true
    },
    "Service Center": {
        "text": "Servicecenter",
        "isAlreadyTranslated": true
    },
    "Request scheduled for": {
        "text": "Serviceanfrage geplant am",
        "isAlreadyTranslated": true
    },
    "Action Status": {
        "text": "Aktionsstatus",
        "isAlreadyTranslated": true
    },
    "Slot": {
        "text": "Uhrzeit",
        "isAlreadyTranslated": true
    },
    "Device recieved": {
        "text": "Gerät erhalten",
        "isAlreadyTranslated": true
    },
    "Action Taken": {
        "text": "Aktion durchgeführt",
        "isAlreadyTranslated": true
    },
    "Log call": {
        "text": "Anrufprotokoll",
        "isAlreadyTranslated": true
    },
    "Name": {
        "text": "Name",
        "isAlreadyTranslated": true
    },
    "First registered form": {
        "text": "Anmeldeformular",
        "isAlreadyTranslated": true
    },
    "Address details": {
        "text": "Angaben zur Adresse",
        "isAlreadyTranslated": true
    },
    "Storage capacity": {
        "text": "Speicherkapazität",
        "isAlreadyTranslated": true
    },
    "Manufacture's warranty status": {
        "text": "Status der Herstellergarantie",
        "isAlreadyTranslated": true
    },
    "Warranty till": {
        "text": "Garantie bis",
        "isAlreadyTranslated": true
    },
    "Protection status": {
        "text": "Versicherungsstatus",
        "isAlreadyTranslated": true
    },
    "Plan benefit": {
        "text": "Versicherungsleistung",
        "isAlreadyTranslated": true
    },
    "Device added date": {
        "text": "Datum Gerät hinzugefügt",
        "isAlreadyTranslated": true
    },
    "Device value": {
        "text": "Gerätewert",
        "isAlreadyTranslated": true
    },
    "estimation details": {
        "text": "Details Abschätzung",
        "isAlreadyTranslated": true
    },
    "Type": {
        "text": "Typ",
        "isAlreadyTranslated": true
    },
    "Total Amount": {
        "text": "Gesamtbetrag",
        "isAlreadyTranslated": true
    },
    "One time deductible": {
        "text": "Einmaliger Selbstbehalt",
        "isAlreadyTranslated": true
    },
    "Total payable": {
        "text": "Gesamtbetrag",
        "isAlreadyTranslated": true
    },
    "Request details": {
        "text": "Details zur Serviceanfrage",
        "isAlreadyTranslated": true
    },
    "external reference id": {
        "text": "Externe Referenz-ID",
        "isAlreadyTranslated": true
    },
    "fullfiller name": {
        "text": "Name des Dienstleisters",
        "isAlreadyTranslated": true
    },
    "Current status": {
        "text": "Aktueller Status",
        "isAlreadyTranslated": true
    },
    "service type": {
        "text": "Serviceoption",
        "isAlreadyTranslated": true
    },
    "schedule date": {
        "text": "Datum auswählen",
        "isAlreadyTranslated": true
    },
    "slot": {
        "text": "Uhrzeit",
        "isAlreadyTranslated": true
    },
    "zipcode": {
        "text": "Postleitzahl",
        "isAlreadyTranslated": true
    },
    "warranty applicable": {
        "text": "innerhalb der Garantie",
        "isAlreadyTranslated": true
    },
    "Issues": {
        "text": "Problem",
        "isAlreadyTranslated": true
    },
    "Issue description": {
        "text": "Beschreibung des Problems",
        "isAlreadyTranslated": true
    },
    "Logistics - From Customer's Address To Service Center": {
        "text": "Logistik - Von der Adresse des Kunden zum Servicecenter",
        "isAlreadyTranslated": true
    },
    "Verification code": {
        "text": "Überprüfungscode",
        "isAlreadyTranslated": true
    },
    "Vendor tracking url -": {
        "text": "Lieferanten Sendungsverfolgung",
        "isAlreadyTranslated": true
    },
    "Waybill Number": {
        "text": "Frachtbriefnummer",
        "isAlreadyTranslated": true
    },
    "One time deductible (Admin fees)": {
        "text": "Einmaliger Selbstbehalt (Verwaltungsgebühren)",
        "isAlreadyTranslated": true
    },
    "Loss datetime": {
        "text": "Datum des Verlusts",
        "isAlreadyTranslated": true
    },
    "Damage type": {
        "text": "Art des Schadens",
        "isAlreadyTranslated": true
    },
    "loss description": {
        "text": "Beschreibung des Schadens",
        "isAlreadyTranslated": true
    },
    "device switching on": {
        "text": "Gerät schaltet sich ein",
        "isAlreadyTranslated": true
    },
    "Requested documents": {
        "text": "Angeforderte Dokumente",
        "isAlreadyTranslated": true
    },
    "communication history": {
        "text": "Kommunikationsverlauf",
        "isAlreadyTranslated": true
    },
    "consumers": {
        "text": "Kunden",
        "isAlreadyTranslated": true
    },
    "Filter": {
        "text": "Filter",
        "isAlreadyTranslated": true
    },
    "Consumer Mobile": {
        "text": "Mobifunknummer Kundel",
        "isAlreadyTranslated": true
    },
    "Consumer email": {
        "text": "E-Mail Kunde",
        "isAlreadyTranslated": true
    },
    "Subcategory": {
        "text": "Unterkategorie",
        "isAlreadyTranslated": true
    },
    "Brand": {
        "text": "Marke",
        "isAlreadyTranslated": true
    },
    "Product": {
        "text": "Produkt",
        "isAlreadyTranslated": true
    },
    "IMIE Number 1": {
        "text": "IMIE Nummer 1",
        "isAlreadyTranslated": true
    },
    "IMIE Number 2": {
        "text": "IMIE Nummer 2",
        "isAlreadyTranslated": true
    },
    "Apply": {
        "text": "Bestätigen",
        "isAlreadyTranslated": true
    },
    "Download logistic label": {
        "text": "Logistiketikett herunterladen",
        "isAlreadyTranslated": true
    },
    "Cancel service without dispatch": {
        "text": "Service stornieren ohne Versand",
        "isAlreadyTranslated": true
    },
    "complete repair": {
        "text": "komplette Reparatur",
        "isAlreadyTranslated": true
    },
    "Initiate logistics request": {
        "text": "Logistikanfrage einleiten",
        "isAlreadyTranslated": true
    },
    "next steps": {
        "text": "nächster Schritt",
        "isAlreadyTranslated": true
    },
    "Repair completed": {
        "text": "Reparatur abgeschlossen",
        "isAlreadyTranslated": true
    },
    "Device dispatched": {
        "text": "Gerät verschickt",
        "isAlreadyTranslated": true
    },
    "complete service": {
        "text": "kompletter Service",
        "isAlreadyTranslated": true
    },
    "Requests": {
        "text": "Serviceanfragen",
        "isAlreadyTranslated": true
    },
    "Consumers": {
        "text": "Kunden",
        "isAlreadyTranslated": true
    },
    "View Invoice": {
        "text": "Rechnung ansehen",
        "isAlreadyTranslated": true
    },
    "Request Scheduled for": {
        "text": "Serviceanfrage geplant am",
        "isAlreadyTranslated": true
    },
    "Log Call": {
        "text": "Anrufprotokoll",
        "isAlreadyTranslated": true
    },
    "Consumer Details": {
        "text": "Kundendetails",
        "isAlreadyTranslated": true
    },
    "Address Details": {
        "text": "Angaben zur Adresse",
        "isAlreadyTranslated": true
    },
    "Issue Validation": {
        "text": "Überprüfung des Problems",
        "isAlreadyTranslated": true
    },
    "Estimation Details": {
        "text": "Details Abschätzung",
        "isAlreadyTranslated": true
    },
    "Other Requests": {
        "text": "Andere Serviceanfragen",
        "isAlreadyTranslated": true
    },
    "Request Details": {
        "text": "Details zur Serviceanfrage",
        "isAlreadyTranslated": true
    },
    "Logistics Details": {
        "text": "Details Logistik",
        "isAlreadyTranslated": true
    },
    "Communication History": {
        "text": "Kommunikationsverlauf",
        "isAlreadyTranslated": true
    },
    "Request History": {
        "text": "Historie Serviceanfragen",
        "isAlreadyTranslated": true
    },
    "Reassign Service": {
        "text": "Service neu beauftragen",
        "isAlreadyTranslated": true
    },
    "Select a service center": {
        "text": "Servicecenter auswählen",
        "isAlreadyTranslated": true
    },
    "Service Engineer": {
        "text": "Servicetechniker",
        "isAlreadyTranslated": true
    },
    "Select an engineer": {
        "text": "Servicetechniker auswählen",
        "isAlreadyTranslated": true
    },
    "Reassign": {
        "text": "Neu beauftragen",
        "isAlreadyTranslated": true
    },
    "Call logs": {
        "text": "Gesprächsnotiz",
        "isAlreadyTranslated": true
    },
    "Add call log": {
        "text": "Gesprächsnotiz hinzufügen",
        "isAlreadyTranslated": true
    },
    "Call type": {
        "text": "Anrufart",
        "isAlreadyTranslated": true
    },
    "Incoming": {
        "text": "eingehend",
        "isAlreadyTranslated": true
    },
    "Outgoing": {
        "text": "ausgehend",
        "isAlreadyTranslated": true
    },
    "Name of the Agent": {
        "text": "Name Mitarbeiter",
        "isAlreadyTranslated": true
    },
    "Remarks": {
        "text": "Bemerkungen",
        "isAlreadyTranslated": true
    },
    "No call history": {
        "text": "Keine Anrufliste",
        "isAlreadyTranslated": true
    },
    "Initiate Exception": {
        "text": "Ausnahmefall initieren",
        "isAlreadyTranslated": true
    },
    "Raise Exception": {
        "text": "Ausnahmefall anfragen",
        "isAlreadyTranslated": true
    },
    "Reason": {
        "text": "Grund",
        "isAlreadyTranslated": true
    },
    "Upload Document Proof": {
        "text": "Nachweisdokument hochladen",
        "isAlreadyTranslated": true
    },
    "Complete Refund": {
        "text": "Erstattung abschließen",
        "isAlreadyTranslated": true
    },
    "UTR Number": {
        "text": "UTR-Nummer",
        "isAlreadyTranslated": true
    },
    "Date of Refund": {
        "text": "Datum der Erstattung",
        "isAlreadyTranslated": true
    },
    "Complete": {
        "text": "Vollständig",
        "isAlreadyTranslated": true
    },
    "Please choose the product": {
        "text": "Bitte wählen Sie das Produkt",
        "isAlreadyTranslated": true
    },
    "Select a Product": {
        "text": "Produkt auswählen",
        "isAlreadyTranslated": true
    },
    "Flag Requests": {
        "text": "Serviceanfrage markieren",
        "isAlreadyTranslated": true
    },
    "Save this": {
        "text": "Speichern",
        "isAlreadyTranslated": true
    },
    "Billing Details": {
        "text": "Rechnungsdetails",
        "isAlreadyTranslated": true
    },
    "Billing Address": {
        "text": "Rechnungsadresse",
        "isAlreadyTranslated": true
    },
    "Change Billing Address": {
        "text": "Rechnungsadresse ändern",
        "isAlreadyTranslated": true
    },
    "Scheduled Date": {
        "text": "Geplanter Termin",
        "isAlreadyTranslated": true
    },
    "Claim Pickup": {
        "text": "Abholung",
        "isAlreadyTranslated": true
    },
    "View Jobsheet": {
        "text": "Arbeitsauftrag anzeigen",
        "isAlreadyTranslated": true
    },
    "Pickup Request Accepted": {
        "text": "Abholauftrag angenommen",
        "isAlreadyTranslated": true
    },
    "Action taken": {
        "text": "Aktion ausgeführt",
        "isAlreadyTranslated": true
    },
    "On": {
        "text": "Ein",
        "isAlreadyTranslated": true
    },
    "at": {
        "text": "bei",
        "isAlreadyTranslated": true
    },
    "Download Logistics Label": {
        "text": "Versandetikett herunterladen",
        "isAlreadyTranslated": true
    },
    "Loading": {
        "text": "wird geladen",
        "isAlreadyTranslated": true
    },
    "Storage Capacity": {
        "text": "Speicherkapazität",
        "isAlreadyTranslated": true
    },
    "Manufacturer's Warranty Status": {
        "text": "Status Herstellergarantie",
        "isAlreadyTranslated": true
    },
    "Under warranty": {
        "text": "Innerhalb der Herstellergarantie",
        "isAlreadyTranslated": true
    },
    "Is Manufacturer's Warranty Applicable": {
        "text": "innerhalb der Herstellergarantie",
        "isAlreadyTranslated": true
    },
    "Warranty Till": {
        "text": "Herstellergarantie bis",
        "isAlreadyTranslated": true
    },
    "Protection Status": {
        "text": "Versicherungsstatus",
        "isAlreadyTranslated": true
    },
    "No. of Repairs Used": {
        "text": "Anzahl der beanspruchten Reparaturen",
        "isAlreadyTranslated": true
    },
    "No. of Repairs Allowed": {
        "text": "Anzahl der erlaubten Reparaturen",
        "isAlreadyTranslated": true
    },
    "Device Value": {
        "text": "Gerätewert",
        "isAlreadyTranslated": true
    },
    "Device Added Date": {
        "text": "Datum Gerät hinzugefügt",
        "isAlreadyTranslated": true
    },
    "Logistics Agent will arrive at your location on 22nd March 2022. Your WAY BILL NUMBER is 1ZV757E88622834875. Please handover the device to the Logistics Agent only if he provides this way bill number to prove his identity.": {
        "text": "Der Logistiker wird am 22. März 2022 an Ihrem Standort eintreffen. Ihre Frachtbriefnummer ist 1ZV757E88622834875. Bitte übergeben Sie dem Logistiker das Gerät nur, wenn er diese Frachtbriefnummer zum Nachweis seiner Identität angibt.",
        "isAlreadyTranslated": true
    },
    "Anfrage eingegangen": {
        "text": "Anfrage eingegangen",
        "isAlreadyTranslated": true
    },
    "Waiting for Assignment": {
        "text": "Warten auf Zuweisung",
        "isAlreadyTranslated": true
    },
    "Claim raised": {
        "text": "Serviceanfrage gestellt",
        "isAlreadyTranslated": true
    },
    "We have received your claim request. We will notify you once it is approved.": {
        "text": "Wir haben Ihre Serviceanfrage erhalten. Wir werden Sie benachrichtigen, sobald es genehmigt wurde.",
        "isAlreadyTranslated": true
    },
    "Reschedule Service": {
        "text": "Neuen Servicetermin anfragen",
        "isAlreadyTranslated": true
    },
    "Select a Date": {
        "text": "Datum auswählen",
        "isAlreadyTranslated": true
    },
    "Select a Slot": {
        "text": "Zeitfenster auswählen",
        "isAlreadyTranslated": true
    },
    "No slots Available": {
        "text": "Kein Zeitfenster verfügbar",
        "isAlreadyTranslated": true
    },
    "Reason for reschedule": {
        "text": "Grund der Terminverschiebung",
        "isAlreadyTranslated": true
    },
    "Reschedule": {
        "text": "Terminverschiebung",
        "isAlreadyTranslated": true
    },
    "Request Documents": {
        "text": "Dokumente anfragen",
        "isAlreadyTranslated": true
    },
    "Plan Benefits": {
        "text": "Vorteile der Versicherung",
        "isAlreadyTranslated": true
    },
    "Coverage Type": {
        "text": "Art der Versicherung",
        "isAlreadyTranslated": true
    },
    "Covered Value": {
        "text": "gedeckter Versicherungswert",
        "isAlreadyTranslated": true
    },
    "Alternate Mobile No": {
        "text": "Alternative Mobilfunknummer",
        "isAlreadyTranslated": true
    },
    "First Registered From": {
        "text": "Erstmals registriert am",
        "isAlreadyTranslated": true
    },
    "Registered": {
        "text": "registriert",
        "isAlreadyTranslated": true
    },
    "Tax Payable": {
        "text": "Umsatzsteuer",
        "isAlreadyTranslated": true
    },
    "One Time deductible": {
        "text": "Einmaliger Selbstbehalt",
        "isAlreadyTranslated": true
    },
    "Total Payable": {
        "text": "Gesamtbetrag",
        "isAlreadyTranslated": true
    },
    "Reference ID": {
        "text": "Referenz-ID",
        "isAlreadyTranslated": true
    },
    "Fulfiller Name": {
        "text": "Name Versender",
        "isAlreadyTranslated": true
    },
    "Current Status": {
        "text": "Aktueller Status",
        "isAlreadyTranslated": true
    },
    "Warranty Applicable": {
        "text": "innerhalb der Herstellergarantie",
        "isAlreadyTranslated": true
    },
    "Pending Verification": {
        "text": "Überprüfung ausstehend",
        "isAlreadyTranslated": true
    },
    "Verification Code": {
        "text": "Verifizierungscode",
        "isAlreadyTranslated": true
    },
    "Vendor Tracking Url": {
        "text": "Sendungsverfolgungs Url",
        "isAlreadyTranslated": true
    },
    "Click here for Vendor Tracking Url": {
        "text": "Klicken Sie hier für die Sendungsverfolgungs Url",
        "isAlreadyTranslated": true
    },
    "Green Rewards": {
        "text": "Klimafreundlich",
        "isAlreadyTranslated": true
    },
    "Okay": {
        "text": "Ok",
        "isAlreadyTranslated": true
    },
    "Enter Reference ID": {
        "text": "Referenz-ID eingeben",
        "isAlreadyTranslated": true
    },
    "Current Request Reference ID": {
        "text": "Aktuelle Referenz-ID",
        "isAlreadyTranslated": true
    },
    "External Reference ID": {
        "text": "Externe Referenz-ID",
        "isAlreadyTranslated": true
    },
    "Service Type": {
        "text": "Servicetyp",
        "isAlreadyTranslated": true
    },
    "Schedule Date": {
        "text": "Termin buchen",
        "isAlreadyTranslated": true
    },
    "Zipcode": {
        "text": "Postleitzahl",
        "isAlreadyTranslated": true
    },
    "Customer's Version": {
        "text": "Stellungnahme des Kunden",
        "isAlreadyTranslated": true
    },
    "Waybill": {
        "text": "Frachtbrief",
        "isAlreadyTranslated": true
    },
    "Pickup Number": {
        "text": "Abholungsnummer",
        "isAlreadyTranslated": true
    },
    "Contact Number": {
        "text": "Telefonnummer",
        "isAlreadyTranslated": true
    },
    "Admin Fees": {
        "text": "Verwaltungsgebühren",
        "isAlreadyTranslated": true
    },
    "Payable": {
        "text": "zu zahlender Betrag",
        "isAlreadyTranslated": true
    },
    "Loss DateTime": {
        "text": "Datum des Verlusts",
        "isAlreadyTranslated": true
    },
    "Damage Type": {
        "text": "Art des Schadens",
        "isAlreadyTranslated": true
    },
    "Loss Description": {
        "text": "Beschreibung des Schadens",
        "isAlreadyTranslated": true
    },
    "Device switching on": {
        "text": "Gerät schaltet sich ein",
        "isAlreadyTranslated": true
    },
    "No Communication History": {
        "text": "Kein Kommunikationsverlauf",
        "isAlreadyTranslated": true
    },
    "Reference ID": {
        "text": "Referenz-ID",
        "isAlreadyTranslated": true
    },
    "Show graph": {
        "text": "Grafik anzeigen",
        "isAlreadyTranslated": true
    },
    "Show count": {
        "text": "Anzahl anzeigen",
        "isAlreadyTranslated": true
    },
    "Service accepted": {
        "text": "Dienst angenommen",
        "isAlreadyTranslated": true
    },
    "Device received": {
        "text": "Gerät erhalten",
        "isAlreadyTranslated": true
    },
    "UserList": {
        "text": "Benutzerliste",
        "isAlreadyTranslated": true
    },
    "Curate": {
        "text": "verwalten",
        "isAlreadyTranslated": true
    },
    "Distributor": {
        "text": "Distributor",
        "isAlreadyTranslated": true
    },
    "Enterprise Sales": {
        "text": "Verkauf Geschäftskunden",
        "isAlreadyTranslated": true
    },
    "Reseller": {
        "text": "Vertriebspartner Reseller",
        "isAlreadyTranslated": true
    },
    "View Document": {
        "text": "Dokument ansehen",
        "isAlreadyTranslated": true
    },
    "Partner Management": {
        "text": "Partner Management",
        "isAlreadyTranslated": true
    },
    "Logistics Requests": {
        "text": "Logistik Anfragen",
        "isAlreadyTranslated": true
    },
    "Optin Requests": {
        "text": "Optin Anfragen",
        "isAlreadyTranslated": true
    },
    "APR Plans": {
        "text": "APR Versicherungen",
        "isAlreadyTranslated": true
    },
    "Catalogue": {
        "text": "Katalog",
        "isAlreadyTranslated": true
    },
    "Engineer Report": {
        "text": "Techniker Report",
        "isAlreadyTranslated": true
    },
    "Plan Sales Report": {
        "text": "Verkaufszahlen Versicherungen Report",
        "isAlreadyTranslated": true
    },
    "Reports": {
        "text": "Reports",
        "isAlreadyTranslated": true
    },
    "Billing": {
        "text": "Rechnungen",
        "isAlreadyTranslated": true
    },
    "Service Requests": {
        "text": "Serviceanfragen",
        "isAlreadyTranslated": true
    },
    "Exclusive": {
        "text": "exklusive",
        "isAlreadyTranslated": true
    },
    "Role": {
        "text": "Rolle",
        "isAlreadyTranslated": true
    },
    "Superadmin": {
        "text": "Superadmin",
        "isAlreadyTranslated": true
    },
    "Connect Disposition": {
        "text": "Disposition verbinden",
        "isAlreadyTranslated": true
    },
    "First Dialer Disposition": {
        "text": "Disposition des 1. Anrufs",
        "isAlreadyTranslated": true
    },
    "Second Dialer Disposition": {
        "text": "Disposition des 2. Anrufs",
        "isAlreadyTranslated": true
    },
    "Device received": {
        "text": "Gerät erhalten",
        "isAlreadyTranslated": true
    },
    "Next Steps": {
        "text": "Nächste Schritte",
        "isAlreadyTranslated": true
    },
    "Add Device Date of Purchase and Device Value in 'Device Details' below.": {
        "text": "Kaufdatum und Wert des Geräts in 'Gerätedetails' unten hinzufügen.",
        "isAlreadyTranslated": true
    },
    "Ask for Documents Pending": {
        "text": "Ausstehenden Dokumenten anfordern",
        "isAlreadyTranslated": true
    },
    "Enter type of document to add": {
        "text": "Art des Dokuments eingeben",
        "isAlreadyTranslated": true
    },
    "Enter Reference IDs": {
        "text": "Referenz-ID eingeben",
        "isAlreadyTranslated": true
    },
    "Pick Up Request Reference ID": {
        "text": "Referenz-ID für Abholauftrag",
        "isAlreadyTranslated": true
    },
    "Merge": {
        "text": "zusammenfügen",
        "isAlreadyTranslated": true
    },
    "Enter Loaner Device Details": {
        "text": "Details des Leihgeräts eingeben",
        "isAlreadyTranslated": true
    },
    "IMEI Number": {
        "text": "IMEI Nummer",
        "isAlreadyTranslated": true
    },
    "Select IMEI Number": {
        "text": "IMEI Nummer auswählen",
        "isAlreadyTranslated": true
    },
    "Upload document": {
        "text": "Dokument hochladen",
        "isAlreadyTranslated": true
    },
    "Submit": {
        "text": "abschicken",
        "isAlreadyTranslated": true
    },
    "Receive Loaner Device": {
        "text": "Leihgerät erhalten",
        "isAlreadyTranslated": true
    },
    "Condition Of the Loaner Device": {
        "text": "Zustand des Leihgeräts",
        "isAlreadyTranslated": true
    },
    "Good": {
        "text": "Gut",
        "isAlreadyTranslated": true
    },
    "Bad": {
        "text": "Schlecht",
        "isAlreadyTranslated": true
    },
    "Ugly": {
        "text": "Verschlissen",
        "isAlreadyTranslated": true
    },
    "Plan Types": {
        "text": "Versicherungstyp",
        "isAlreadyTranslated": true
    },
    "Plan Descriptions": {
        "text": "Versicherungsbeschreibung",
        "isAlreadyTranslated": true
    },
    "Shipping Details": {
        "text": "Details Versand",
        "isAlreadyTranslated": true
    },
    "Logistics Agent will arrive at your location on 1st April 2022. Your WAY BILL NUMBER is 1ZV757E88600669390. Please handover the device to the Logistics Agent only if he provides this way bill number to prove his identity.": {
        "text": "Der Logistiker wird am 1. April 2022 an Ihrem Standort eintreffen. Ihre Frachtbriefnummer ist 1ZV757E88600669390. Bitte übergeben Sie dem Logistiker das Gerät nur, wenn er diese Frachtbriefnummer zum Nachweis seiner Identität angibt.",
        "isAlreadyTranslated": true
    },
    "Cancel Repair": {
        "text": "Reparaturauftrag stornieren",
        "isAlreadyTranslated": true
    },
    "Cancel Service Without Dispatch": {
        "text": "Serviceanfrage stornieren ohne Rücksendung",
        "isAlreadyTranslated": true
    },
    "Tasks": {
        "text": "Aufgaben",
        "isAlreadyTranslated": true
    },
    "Edit": {
        "text": "bearbeiten",
        "isAlreadyTranslated": true
    },
    "Close Task": {
        "text": "Aufgabe erledigt",
        "isAlreadyTranslated": true
    },
    "Task Reference Id": {
        "text": "Referenz-ID Aufgabe",
        "isAlreadyTranslated": true
    },
    "Remark": {
        "text": "Bemerkung",
        "isAlreadyTranslated": true
    },
    "Edit Remark": {
        "text": "Bemerkung bearbeiten",
        "isAlreadyTranslated": true
    },
    "Task Details of": {
        "text": "Details Aufgabe",
        "isAlreadyTranslated": true
    },
    "Reference Id": {
        "text": "Referenz-ID",
        "isAlreadyTranslated": true
    },
    "Created Date": {
        "text": "Erstellungsdatum",
        "isAlreadyTranslated": true
    },
    "Updated Date": {
        "text": "Aktualisierungsdatum",
        "isAlreadyTranslated": true
    },
    "Closed Date": {
        "text": "Abschlussdatum",
        "isAlreadyTranslated": true
    },
    "Task": {
        "text": "Aufgabe",
        "isAlreadyTranslated": true
    },
    "No tasks Available": {
        "text": "Keine offenen Aufgaben",
        "isAlreadyTranslated": true
    },
    "an address": {
        "text": "Adresse",
        "isAlreadyTranslated": true
    },
    "Postleitzahl": {
        "text": "Postleitzahl",
        "isAlreadyTranslated": true
    },
    "Postleitzahl is required": {
        "text": "Postleitzahl ist erforderlich",
        "isAlreadyTranslated": true
    },
    "Stadt/Ort": {
        "text": "Stadt/Ort",
        "isAlreadyTranslated": true
    },
    "Stadt/Ort is required": {
        "text": "Stadt/Ort ist erforderlich",
        "isAlreadyTranslated": true
    },
    "Stadt": {
        "text": "Stadt",
        "isAlreadyTranslated": true
    },
    "Stadt is required": {
        "text": "Stadt ist erforderlich",
        "isAlreadyTranslated": true
    },
    "Bundesland (optional)": {
        "text": "Bundesland",
        "isAlreadyTranslated": true
    },
    "Bundesland (optional) is required": {
        "text": "",
        "isAlreadyTranslated": true
    },
    "Address updated successfully": {
        "text": "Adresse erfolgreich aktualisiert",
        "isAlreadyTranslated": true
    },
    "Registration Documents": {
        "text": "Registrierungsdokumente",
        "isAlreadyTranslated": true
    },
    "Please select a document type": {
        "text": "Bitte wählen Sie einen Dokumententyp",
        "isAlreadyTranslated": true
    },
    "Doc": {
        "text": "Dokument",
        "isAlreadyTranslated": true
    },
    "Add a part charge in 'Estimation Details' below to generate estimation.": {
        "text": "Fügen Sie in 'Details Geschätzte Schadenszahlung' unten eine Teilgebühr hinzu, um eine korrekte Abschätzung zu erstellen.",
        "isAlreadyTranslated": true
    },
    "Device details validated": {
        "text": "Gerätedetails validiert",
        "isAlreadyTranslated": true
    },
    "IMEI/Serial Number: 355399270842625,\nManufacturer's Warranty Status: Yes,\nIs Manufacturer's Warranty Applicable: No,\nDevice Value: 2000,\nDate of Purchase: 2022-03-23,": {
        "text": "IMEI/Seriennummer: 355399270842625,\nStatus der Herstellergarantie: Ja,\nInnerhalbd der Herstellergarantie: Nein,\nGerätewert: 2000,\nKaufdatum: 2022-03-23,",
        "isAlreadyTranslated": true
    },
    "By": {
        "text": "von",
        "isAlreadyTranslated": true
    },
    "ADD CHARGES": {
        "text": "Gebühren hinzufügen",
        "isAlreadyTranslated": true
    },
    "Part Charge": {
        "text": "Teilkosten",
        "isAlreadyTranslated": true
    },
    "Other Charge": {
        "text": "Andere Gebühren",
        "isAlreadyTranslated": true
    },
    "Advance": {
        "text": "Vorab",
        "isAlreadyTranslated": true
    },
    "Part name": {
        "text": "Teilebezeichnung",
        "isAlreadyTranslated": true
    },
    "HSN Code": {
        "text": "HSN Code",
        "isAlreadyTranslated": true
    },
    "Taxable Amount": {
        "text": "Steuerpflichtiger Betrag",
        "isAlreadyTranslated": true
    },
    "Claim estimation generated": {
        "text": "Geschätzte Schadenszahlung generiert",
        "isAlreadyTranslated": true
    },
    "Approve Claim Estimation": {
        "text": "Geschätzte Schadenszahlung genehmigen",
        "isAlreadyTranslated": true
    },
    "part 1": {
        "text": "Teil 1",
        "isAlreadyTranslated": true
    },
    "Please enter approved claim amount": {
        "text": "Bitte geben Sie den genehmigten Forderungsbetrag ein",
        "isAlreadyTranslated": true
    },
    "Payment pending": {
        "text": "Zahlung ausstehend",
        "isAlreadyTranslated": true
    },
    "Payment Pending": {
        "text": "Zahlung ausstehend",
        "isAlreadyTranslated": true
    },
    "Payment received": {
        "text": "Zahlung erhalten",
        "isAlreadyTranslated": true
    },
    "Send payment link": {
        "text": "Zahlungslink senden",
        "isAlreadyTranslated": true
    },
    "Claim estimation approved": {
        "text": "Geschätzte Schadenszahlung angenommen",
        "isAlreadyTranslated": true
    },
    "Approved claim amount": {
        "text": "Genehmigter Forderungsbetrag",
        "isAlreadyTranslated": true
    },
    "Are you sure": {
        "text": "Sind Sie sich sicher",
        "isAlreadyTranslated": true
    },
    "Approved for repair": {
        "text": "Für die Reparatur genehmigt",
        "isAlreadyTranslated": true
    },
    "Initiate Service": {
        "text": "Service initieren",
        "isAlreadyTranslated": true
    },
    "Service initiated": {
        "text": "Service veranlasst",
        "isAlreadyTranslated": true
    },
    "Complete Repair": {
        "text": "Reparatur abgeschlossen",
        "isAlreadyTranslated": true
    },
    "Parts pending": {
        "text": "Teile ausstehend",
        "isAlreadyTranslated": true
    },
    "Complete Service": {
        "text": "Serviceanfrage ageschlossen",
        "isAlreadyTranslated": true
    },
    "Logistics - Return to Customer": {
        "text": "Logistik - Rücksendung zum Kunden",
        "isAlreadyTranslated": true
    },
    "Shipping Method": {
        "text": "Versandart",
        "isAlreadyTranslated": true
    },
    "Pick Up Confirmation Number": {
        "text": "Bestätigungsnummer für die Abholung",
        "isAlreadyTranslated": true
    },
    "other related documents": {
        "text": "andere relevante Dokumente",
        "isAlreadyTranslated": true
    },
	"Terms and Conditions": {
		"text": "Allgemeine Versicherungsbedingungen",
		"isAlreadyTranslated": true
	},
	"Enter Verification Code": {
		"text": "Eingabe des Verifizierungscodes",
		"isAlreadyTranslated": true
	},
	"Code sent to customer on": {
		"text": "Code wurde dem Kunden am",
		"isAlreadyTranslated": true
	},
	"and": {
		"text": "und",
		"isAlreadyTranslated": true
	},
	"Resend SMS": {
		"text": "SMS erneut senden",
		"isAlreadyTranslated": true
	},
	"Resend Email": {
		"text": "E-Mail erneut senden",
		"isAlreadyTranslated": true
	},
	"Call me with the Code": {
		"text": "Code Mitteilung per Anruf",
		"isAlreadyTranslated": true
	},
	"Confirm Code": {
		"text": "Code bestätiigen",
		"isAlreadyTranslated": true
	},
	"Didn't receive the code? Resend Code in": {
		"text": "Code wurde nicht erhalten? Erneutes Senden in",
		"isAlreadyTranslated": true
	},
    "Select Brand": {
		"text": "Marke auswählen",
		"isAlreadyTranslated": true
	},
	"or": {
		"text": "oder",
		"isAlreadyTranslated": true
	},
	"Select payment plan": {
		"text": "Zahlungsplan auswählen",
		"isAlreadyTranslated": true
	},
	"Subscription plan, cancel anytime": {
		"text": "Abonnementplan, jederzeit kündbar",
		"isAlreadyTranslated": true
	},
	"One time payment": {
		"text": "Einmalige Zahlung",
		"isAlreadyTranslated": true
	},
	"Enter": {
		"text": "eingeben",
		"isAlreadyTranslated": true
	},
	"Eligible Plan(s)": {
		"text": "Berechtigte(r) Plan(e)",
		"isAlreadyTranslated": true
	},
	"Payment Options": {
		"text": "Zahlungsoptionen",
		"isAlreadyTranslated": true
	},
	"Pay Upfront": {
		"text": "Im Voraus bezahlen",
		"isAlreadyTranslated": true
	},
	"Subscription Payment": {
		"text": "Zahlung im Abonnement",
		"isAlreadyTranslated": true
	},
	"The plan purchase is successful. The customer will receive a confirmation email on the registered email ID.": {
		"text": "Der Kauf des Plans ist erfolgreich. Der Kunde erhält eine Bestätigungs-E-Mail an seine registrierte E-Mail-ID.",
		"isAlreadyTranslated": true
	},
    "Plan Purchase Date": {
        "text": "Gültigkeit Kaufs Datum",
		"isAlreadyTranslated": true
    },
	"You will be redirected in": {
		"text": "Sie werden weitergeleitet in",
		"isAlreadyTranslated": true
	},
	"seconds": {
		"text": "Sekunden weitergeleitet",
		"isAlreadyTranslated": true
	},
	"If you are not redirected": {
		"text": "Wenn Sie nicht weitergeleitet werden",
		"isAlreadyTranslated": true
	},
	"Click Here": {
		"text": "Klicken Sie hier",
		"isAlreadyTranslated": true
	},
	"Your transaction has encountered an error and could not be processed at this time. Please try again later or contact our support team for help.": {
		"text": "Bei Ihrer Transaktion ist ein Fehler aufgetreten, der zu diesem Zeitpunkt nicht bearbeitet werden konnte. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an unser Support-Team für Hilfe.",
		"isAlreadyTranslated": true
	},
	"Payment Failed": {
		"text": "Zahlung fehlgeschlagen",
		"isAlreadyTranslated": true
	},
    
    "Plan Sales": {
        "text": "Verkauf",
        "isAlreadyTranslated": true
    },

    "Store Location": {
		"text": "Standort",
        "isAlreadyTranslated": true
    },
    "Total Sales Value": {
        "text": "Gesamtverkaufswert",
        "isAlreadyTranslated": true
    },
    "Upfront Payment": {
        "text": "Vorabzahlung",
        "isAlreadyTranslated": true
    },
    "This includes transaction values associated with plan sales and recurring payments post activation": {
		"text": "Dazu gehören Transaktionswerte im Zusammenhang mit Planverkäufen und wiederkehrenden Zahlungen nach der Aktivierung",
        "isAlreadyTranslated": true
    },
	"Total Earnings": {
		"text": "Gesamteinnahmen",
        "isAlreadyTranslated": true
    },
	"This includes retailer margin values associated with plan sales and recurring payments post activation": {
        "text": "Dazu gehören die Margenwerte der Einzelhändler, die mit Planverkäufen und wiederkehrenden Zahlungen nach der Aktivierung verbunden sind.",
        "isAlreadyTranslated": true
    },
	"Number of Sales": {
		"text": "Anzahl der Verkäufe",
        "isAlreadyTranslated": true
    },
	"This includes all plan sales transactions except those contracts where customer has not completed the payment via link": {
        "text": "Dies umfasst alle Planverkaufstransaktionen mit Ausnahme der Verträge, bei denen der Kunde die Zahlung nicht über einen Link abgeschlossen hat.",
        "isAlreadyTranslated": true
    },
	"Total Recurring Payments": {
		"text": "Wiederkehrende Zahlungen insgesamt ",
        "isAlreadyTranslated": true
    },

	"This includes all successful recurring transactions associated with subscription plans": {
		"text": "Dazu gehören alle erfolgreichen wiederkehrenden Transaktionen im Zusammenhang mit Abonnementplänen",
        "isAlreadyTranslated": true
    },
	"Payment Link Transactions": {
		"text": "Payment Link-Transaktionen ",
        "isAlreadyTranslated": true
    },
    "/ Payment Link Transactions": {
		"text": "/ Payment Link-Transaktionen ",
        "isAlreadyTranslated": true
    },
    "Completed": {
        "text": "Abgeschlossen",
        "isAlreadyTranslated": true
    },
	"Pending": {
		"text": "ausstehend",
        "isAlreadyTranslated": true
    },
	"Failure": {
		"text": "Versagen",
        "isAlreadyTranslated": true
    },

	"This includes all transactions where customer is using a link to complete the payment": {
        "text": "Dazu gehören alle Transaktionen, bei denen der Kunde einen Link verwendet, um die Zahlung abzuschließen.",
        "isAlreadyTranslated": true
    },
	"Plan Sales Summary": {
        "text": "Zusammenfassung der Verkäufe",
        "isAlreadyTranslated": true
    },
	"Showing Results For": {
		"text": "Anzeigen von Ergebnissen für",
        "isAlreadyTranslated": true
    },
	"Today": {
        "text": "Heute",
        "isAlreadyTranslated": true
    },
	" Last 7 Days": {
		"text": " Letzte 7 Tage",
        "isAlreadyTranslated": true
    },
	"Last 15 Days": {
		"text": " Letzte 15 Tage",
        "isAlreadyTranslated": true
    },
	"Last 30 Days": {
		"text": " Letzte 30 Tage",
        "isAlreadyTranslated": true
    },
	"Custom Date": {
		"text": "Benutzerdefiniertes Datum",
        "isAlreadyTranslated": true
    },
	"View All Transactions": {
        "text": "Alle Transaktionen anzeigen",
        "isAlreadyTranslated": true
    },
    "View All": {
        "text": "Alle Transaktionen",
        "isAlreadyTranslated": true
    },
	"Sell Plan": {
        "text": "Versicherung Verkaufen",
        "isAlreadyTranslated": true
    },


	"Renew Plan": {
		"text": "Versicherung erneuern",
        "isAlreadyTranslated": true
    },
	"Bulk Upload": {
		"text": "Massen-Upload",
        "isAlreadyTranslated": true
    },
	"You can only select a range within 30 days": {
		"text": "Sie können nur einen Bereich innerhalb von 30 Tagen auswählen.",
        "isAlreadyTranslated": true
    },
	"Select Store Location": {
        "text": "Standort auswählen",
        "isAlreadyTranslated": true
    },
	"Location": {
		"text": "Standort",
        "isAlreadyTranslated": true
    },
	"All Transactions": {
		"text": "Alle Vorgänge",
        "isAlreadyTranslated": true
    },
	"Sorry, No Upfront Payment Transactions Found": {
		"text": "Leider keine Vorauszahlungstransaktionen gefunden",
        "isAlreadyTranslated": true
    },
	"Payment Plan": {
		"text": "Zahlungsplan",
        "isAlreadyTranslated": true
    },
	"Sorry, No Subscription Payment Transactions Found": {
        "text": "Leider keine Abonnement-Zahlungstransaktionen gefunden",
        "isAlreadyTranslated": true
    },
	"Payment Link Transactions": {
        "text": "Payment Link-Transaktionen ",
        "isAlreadyTranslated": true
    },

	"Sorry, No Transactions Found": {
		"text": "Sorry, keine Transaktionen gefunden",
        "isAlreadyTranslated": true
    },
	"Documents": {
        "text": "Dokumente ",
        "isAlreadyTranslated": true
    },
	"Sorry, No Documents Found": {
		"text": "Leider keine Dokumente gefunden",
        "isAlreadyTranslated": true
    },
	"Are you sure you want to cancel the plan?": {
		"text": "Sind Sie sicher, dass Sie den Plan kündigen wollen?",
        "isAlreadyTranslated": true
    },
	"Once cancelled, the customer won't be able to redeem any benefits of the plan": {
        "text": "Einmal gekündigt, kann der Kunde die Vorteile des Plans nicht mehr in Anspruch nehmen.",
        "isAlreadyTranslated": true
    },
	"Select Reason for Cancellation": {
		"text": "Grund für die Stornierung auswählen",
        "isAlreadyTranslated": true
    },
	"Select Reason": {
		"text": "Grund auswählen",
        "isAlreadyTranslated": true
    },
	"Choose a Reason from the dropdown": {
		"text": "Wählen Sie einen Grund aus der Dropdown-Liste",
        "isAlreadyTranslated": true
    },
	"The plan has been cancelled": {
		"text": "Der Plan wurde gestrichen",
        "isAlreadyTranslated": true
    },
    "Cancelled": {
		"text": "gestrichen",
        "isAlreadyTranslated": true
    },
	"Make Payment": {
        "text": "Zahlung vornehmen ",
        "isAlreadyTranslated": true
    },

	"More Filters": {
		"text": "Mehr Filter",
        "isAlreadyTranslated": true
    },
    "More": {
		"text": "Mehr",
        "isAlreadyTranslated": true
    },
	"Customer Email ID": {
        "text": "E-Mail-ID des Kunden",
        "isAlreadyTranslated": true
    },
	"Contract ID": {
		"text": "Vertrag ID",
        "isAlreadyTranslated": true
    },
	"Policy ID": {
		"text": "Policy ID",
        "isAlreadyTranslated": true
    },
	"Clear All": {
		"text": "Alle löschen",
        "isAlreadyTranslated": true
    },
	"Transaction Date": {
		"text": "Datum des Vorgangs",
        "isAlreadyTranslated": true
    },
	"Transaction Amount": {
		"text": "Betrag der Transaktion",
        "isAlreadyTranslated": true
    },
	"Contract Status": {
        "text": "Status des Vertrags",
        "isAlreadyTranslated": true
    },
	"Enrolled": {
        "text": "Eingeschrieben",
        "isAlreadyTranslated": true
    },
	"Diagnosed": {
		"text": "Diagnostiziert",
        "isAlreadyTranslated": true
    },
	"Upgraded": {
        "text": "Hochgestuft",
        "isAlreadyTranslated": true
    },
	"On Hold": {
        "text": "In Wartestellung",
        "isAlreadyTranslated": true
    },
	"Downgraded": {
		"text": "Herabgestuft",
        "isAlreadyTranslated": true
    },
	"Expired": {
		"text": "Abgelaufen",
        "isAlreadyTranslated": true
    },
	"Cancellation Initiated": {
		"text": "Stornierung Eingeleitet",
        "isAlreadyTranslated": true
    },
	"Refunded": {
		"text": "Erstattet",
        "isAlreadyTranslated": true
    },
	"Benefits Availed": {
		"text": "In Anspruch genommene Leistungen",
        "isAlreadyTranslated": true
    },
	"Bundled": {
        "text": "Gebündelt",
        "isAlreadyTranslated": true
    },
	"Under Review": {
        "text": "In Prüfung",
        "isAlreadyTranslated": true
    },
	"Payment Plan": {
        "text": "Zahlungsplan",
        "isAlreadyTranslated": true
    },
	"Transaction Completion Date": {
        "text": "Datum des Transaktionsabschlusses",
        "isAlreadyTranslated": true
    },
	"Payment Link Status": {
        "text": "Status der Zahlungsverbindung",
        "isAlreadyTranslated": true
    },
	"Residential State": {
        "text": "Staat des Wohnsitzes",
        "isAlreadyTranslated": true
    },
	"Residential Country": {
		"text": "Land des Wohnsitzes",
        "isAlreadyTranslated": true
    },
	"Plan Code": {
        "text": "Plan-Code",
        "isAlreadyTranslated": true
    },
	"Plan Term": {
		"text": "Laufzeit des Plans",
        "isAlreadyTranslated": true
    },
	"Store Location": {
        "text": "Ort der Filiale",
        "isAlreadyTranslated": true
    },
	"Waiting Period": {
		"text": "Wartezeit",
        "isAlreadyTranslated": true
    },
	"Plan Creation Date": {
        "text": "Datum der Planerstellung",
        "isAlreadyTranslated": true
    },
	"Plan Cancellation Initiation Date": {
        "text": "Datum der Planauflösung",
        "isAlreadyTranslated": true
    },
    "Customer Mobile Number": {
        "text": "Handynummer des Kunden",
        "isAlreadyTranslated": true
    },

	"Plan Cancellation Date": {
		"text": "Datum der Planstornierung",
        "isAlreadyTranslated": true
    },
    "Plan Activation Date": {
		"text": "Aktivierungsdatum des Plans",
        "isAlreadyTranslated": true
    },
	"Cancellation Reason Description": {
        "text": "Stornierungsgrund Beschreibung",
        "isAlreadyTranslated": true
    },
	"Coverage Details": {
        "text": "Details zum Versicherungsschutz",
        "isAlreadyTranslated": true
    },
	"Coverage Name": {
		"text": "Name des Versicherungsschutzes",
        "isAlreadyTranslated": true
    },
	"Coverage Start Date": {
		"text": "Datum des Versicherungsbeginns",
        "isAlreadyTranslated": true
    },
	"Claim Avail Date": {
        "text": "Datum der Inanspruchnahme",
        "isAlreadyTranslated": true
    },
	"Coverage End Date": {
		"text": "Enddatum des Versicherungsschutzes",
        "isAlreadyTranslated": true
    },
	"No. of Claims Allowed": {
		"text": "Anzahl der zugelassenen Ansprüche",
        "isAlreadyTranslated": true
    },
	"Used Claims": {
		"text": "Verwendete Ansprüche",
        "isAlreadyTranslated": true
    },
	"Device Purchase Price": {
		"text": "Gerätekaufpreis",
        "isAlreadyTranslated": true
    },
	"Mode": {
		"text": "Modus",
        "isAlreadyTranslated": true
    },

	"Payment Gateway Transaction ID": {
		"text": "Zahlungs-Gateway Transaktions-ID",
        "isAlreadyTranslated": true
    },
	"External Payment ID": {
        "text": "Externe Zahlungs-ID",
        "isAlreadyTranslated": true
    },
	"Billed Date (UTC)": {
        "text": "Abrechnungsdatum (UTC)",
        "isAlreadyTranslated": true
    },
	"Billing Cycle Date (UTC)": {
        "text": "Datum des Abrechnungszyklus (UTC)",
        "isAlreadyTranslated": true
    },
	"Grace Period End Date": {
        "text": "Enddatum der Karenzzeit",
        "isAlreadyTranslated": true
    },
	"Retailer Margin": {
		"text": "Händlermarge",
        "isAlreadyTranslated": true
    },
	"Invoice": {
		"text": "Rechnung",
        "isAlreadyTranslated": true
    },

    "PLAN DETAILS": {
		"text": "PLAN DETAILS",
        "isAlreadyTranslated": true
	},
	"COVERAGE DETAILS": {
		"text": "DETAILS ZUR ABDECKUNG",
        "isAlreadyTranslated": true

	},
	"DEVICE DETAILS": {
		"text": "GERÄTE DETAILS",
        "isAlreadyTranslated": true
	},
	"Model": {
		"text": "Modell",
        "isAlreadyTranslated": true
	},

	"Payment Details": {
		"text": "Details zur Zahlung",
        "isAlreadyTranslated": true
    },
	"Transaction ID": {
		"text": "Transaktions-ID",
        "isAlreadyTranslated": true
    },
	"Transaction Date (UTC)": {
        "text": "Transaktionsdatum (UTC)",
        "isAlreadyTranslated": true
    },
    "Pay upfront": {
		"text": "Im Voraus bezahlen",
		"isAlreadyTranslated": true
	},
	"month": {
		"text": "Monat",
		"isAlreadyTranslated": true
	},
	"Maximum": {
		"text": "Maximal",
		"isAlreadyTranslated": true
	},
	"Characters Allowed": {
		"text": "Zeichen Erlaubt",
		"isAlreadyTranslated": true
	},
	"Full Name": {
		"text": "Vollständiger Name",
		"isAlreadyTranslated": true
	},
	"Make payment": {
		"text": "Zahlung vornehmen",
		"isAlreadyTranslated": true
	},
	"Receive Payment": {
		"text": "Zahlung erhalten",
		"isAlreadyTranslated": true
	},
};

export default lang;