import React, { useEffect, useState } from 'react';
import { Button, Input } from 'sfy-react';
import Swal from "sweetalert2";


export default function ShipToServiceCentreInput(props) {
    const {
        localString, apiCall, setLoader, consumerServiceRequest, getRequestById, closeModal, displayError,
        assignToSelf, shipToDropOFFLoc, accessFn
    } = props;

    const [AWBNumber, setAWBNumber] = useState('');
    const [remarks, setRemarks] = useState('');
    const [mappedAddress,setMappedAddress] = useState('');
    const [mappedLocationName,setMappedLocationName] = useState('');

    // const getMappedServiceLocations = () => {
    //     let data = {
    //         ConsumerServiceRequestID:consumerServiceRequest.ConsumerServiceRequestID
    //     }
    //     setLoader(true);
    //     apiCall({
    //         url: 'getMappedServiceLocations',
    //         data:data,
    //         callBack: (result) => {
    //             setLoader(false);
    //             if (result.success && result.data && result.data.forward[0]) {
    //                 setMappedAddress(result.data.forward[0].Address);
    //             }else {
    //                 Swal.fire({  
    //                     icon: 'error',  
    //                     text: localString(result.msg),
    //                     confirmButtonText: localString('OK')
    //                 });
    //                 return;
    //             }   
    //         }
    //     });

    // }

    const getPartnerServiceLocationById = () => {
        let data = {
            PartnerServiceLocationID: accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE') ? consumerServiceRequest.PartnerServiceLocationID : consumerServiceRequest.OriginServiceLocationID 
        };
        setLoader(true);
		apiCall({
			url: 'getPartnerServiceLocationById',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
                    if (result.success && result.data) {
                        result.data.ServiceLocationName && setMappedLocationName(result.data.ServiceLocationName);
                        result.data.Address && setMappedAddress(result.data.Address);
                    }else {
                        Swal.fire({  
                            icon: 'error',  
                            text: localString(result.msg),
                            confirmButtonText: localString('OK')
                        });
                    }   
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    const ShipTOServiceCentre = (enableRepairConsent=false)=>{
        let data = {
            ConsumerServiceRequestID:consumerServiceRequest.ConsumerServiceRequestID,
            Remarks:remarks,
            AWBNumber:AWBNumber
        }
        if(enableRepairConsent){
            data.overrideRepairEligibility = true;
        }else{
            data.overrideRepairEligibility = false;
        }
        setLoader(true);
        apiCall({
            url: 'shipDeviceWithProvisionalRepair',
            data:data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    getRequestById();
                    Swal.fire({
                        title: '',
                        icon: "success",
                        text: localString('Shipment details saved successfully!'),
                        confirmButtonText: localString('OK')
                    });
                    closeModal();
                }else {
                    if(result.data && !result.data.enableRepairConsent){
                        enableRepairConsent = false;
                        Swal.fire({
                            title: '',
                            text: localString(displayError(result)),
                            type: 'error',
                            confirmButtonText: localString('OK'),
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                        });
                    }else if(result.data && result.data.enableRepairConsent){
                        Swal.fire ({
                            title: '',
                            // text: localString(displayError(result)),
                            html: `<div style="white-space: pre-line;">${localString(displayError(result))}<div/>`,
                            type: 'error',
                            showCancelButton: true,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            cancelButtonText: localString("No"),
                            confirmButtonText: localString("Yes")
                        }).then (function (res) {
                            if (res.isConfirmed) {
                                Swal.close();
                                enableRepairConsent = true;
                                ShipTOServiceCentre(enableRepairConsent);
                            }
                        });
                    }else{
                        Swal.fire ({
                            title: '',
                            text: localString(displayError(result)),
                            type: 'error',
                            confirmButtonText: localString('OK'),
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                        });
                    }
                }   
            }
        });
        closeModal()
    }

   const shipToDropOFFLocation = () => {
    let data = {
        ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
        TransferredServiceLocationID: consumerServiceRequest.OriginServiceLocationID,
        updateDropOffCsrStatus: true,
        Remarks: remarks,
        AWBNumber: AWBNumber
    }
    assignToSelf(data);
   }

    useEffect(() => {
        getPartnerServiceLocationById();
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <label className="text-capitalize">
                        {localString("AWB Number")}
                    </label>
                    <div>
                        <Input
                            value={AWBNumber}
                            handleOnChange={(e) => setAWBNumber(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-sm-12">
                    <label className="text-capitalize pt10">
                        {localString("Remarks")}
                    </label>
                    <div>
                        <Input
                            value={remarks}
                            handleOnChange={(e) => setRemarks(e.target.value)}
                            inputComponent='textarea'
                            className={{
                                inputContainer: 'announceTextarea'
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-12 marginTop10">
                    <label className="text-capitalize">
                        {localString("Shipping Address")}
                    </label>
                    <div className='mappedAddress'>
                        {mappedLocationName && <span>{mappedLocationName}<br /></span> }
                        {mappedAddress ? <span>{mappedAddress}</span> : ''}
                    </div>
                </div>
            </div>
            <div className='pull-right mt-15'>
                <Button 
                    type='button'
                    className="btn button-servify"
                    onClick={() => shipToDropOFFLoc ? shipToDropOFFLocation() : ShipTOServiceCentre()}
                >
                    {localString("Save")}
                </Button>
            </div>
        </>
    );
}