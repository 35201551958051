import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Input, MultiInput, Button, Loader } from "sfy-react";
import CredentialWrapper from "../../components/CredentialsWrapper/CredentialsWrapper";
import "./Login.scss";

const Login = (props) => {
	const [loginState, setLoginState] = useState({
		loginId: "",
		password: "",
	});
	const [otpState, setOtpState] = useState('');
	const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
	const [disableOtpSubmitBtn, setDisableOtpSubmitBtn] = useState(false);
	const [disableResendOtpBtn, setDisableResendOtpBtn] = useState(true);
	const [forgotPasswordURL, setForgotPasswordURL] = useState(
		props.baseUrl + "/forgotpassword"
	);
	const loginWithOtpURL = props.baseUrl + "/loginWithOtp";
	const [showLoader, setLoader] = useState(false);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(60);
	const [showMfa, setShowMfa] = useState(false);
	const [errorState, setErrorState] = useState({
		errorText: "",
		showError: false
	});

	const userNameChangeHandler = (username) => {
		setLoginState((prevState) => {
			return { ...prevState, loginId: username };
		});
		setForgotPasswordURL(props.baseUrl + "/forgotpassword?email=" + username);
	}

	const passwordChangeHandler = (password) => {
		setLoginState((prevState) => {
			return { ...prevState, password: password };
		});
	}

	const login = (e) => {
		if (e) {
			e.preventDefault();
		}
		if (loginState.loginId != "" && loginState.password != "") {
			setErrorState({
				errorText: "",
				showError: false,
			});
			setDisableSubmitBtn(true);
			let data = loginState;
			let options = {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json;charset=UTF-8",
				},
			};
			let url = props.baseUrl + "/userLogin";
			setLoader(true);
			fetch(url, options)
				.then((response) => {
					if (!response.ok) throw new Error(response.status);
					else return response.json();
				})
				.then((result) => {
					if (result.success) {
						console.log("result", result);
						if (result.data && result.data.isMfa) {
							setLoader(false);
							setShowMfa(true);
							setDisableOtpSubmitBtn(true);
							// startTimer();
						} else {
							window.location.reload(true);
						}
					} else {
						setLoader(false);
						setDisableSubmitBtn(false);
						setErrorState({
							errorText: result.msg,
							showError: true,
						});
					}
				})
				.catch((error) => {
					console.log("error: " + error);
				});
		}
		else {
			setErrorState({
				errorText: 'Please enter username and password',
				showError: true,
			});
		}
	}

	const handleOtpChange = (e) => {
		console.log("handleOtpChange", e);
		setOtpState(e);
	}

	const verifyOtp = (e) => {
		if (e) {
			e.preventDefault();
		}
		if (otpState != "" && otpState.length == 6) {
			setErrorState({
				errorText: "",
				showError: false,
			});
			setDisableOtpSubmitBtn(true);
			let data = { otp: otpState, username: loginState.loginId };
			let options = {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json;charset=UTF-8",
				},
			};
			let url = props.baseUrl + "/verifyOtp";
			setLoader(true);
			fetch(url, options)
				.then((response) => {
					if (!response.ok) throw new Error(response.status);
					else return response.json();
				})
				.then((result) => {
					if (result.success) {
						window.location.reload(true);
					} else {
						setErrorState({
							errorText: result.msg,
							showError: true,
						});
						setLoader(false);
						disableOtpSubmitBtn(false);
					}
				})
				.catch((error) => {
					console.log("error: " + error);
				});
		} else {
			setErrorState({
				errorText: "Please enter correct OTP",
				showError: true,
			});
		}
	};

	const resendOtp = (e) => {
		if (e) {
			e.preventDefault();
		}
		if (loginState.loginId != "") {
			setErrorState({
				errorText: "",
				showError: false,
			});
			setDisableResendOtpBtn(true);
			let data = { username: loginState.loginId };
			let options = {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json;charset=UTF-8",
				},
			};
			let url = props.baseUrl + "/api/v1/Otp/resendOtp";
			setLoader(true);
			fetch(url, options)
				.then((response) => {
					if (!response.ok) throw new Error(response.status);
					else return response.json();
				})
				.then((result) => {
					if (result.success) {
						setLoader(false);
						setSeconds(60);
					} else {
						setLoader(false);
						setErrorState({
							errorText: result.msg,
							showError: true,
						});
					}
				})
				.catch((error) => {
					console.log("error: " + error);
				});
		} else {
			setErrorState({
				errorText: "Please enter correct OTP",
				showError: true,
			});
		}
	};

	const getTimerString = () => {
		return minutes === 0 && seconds === 0
				? ""
				: "OTP in " + minutes
				+ ":"
				+ (seconds < 10 ? "0" + seconds : seconds)
	}

	useEffect(() => {
		if (showMfa == true) {
			let myInterval = setInterval(() => {
				if (seconds > 0) {
					setSeconds(seconds - 1);
				}
				if (seconds === 0) {
					if (minutes === 0) {
						clearInterval(myInterval);
						setDisableResendOtpBtn(false);
					} else {
						setMinutes(minutes - 1);
						setSeconds(59);
					}
				}
			}, 1000);
			return () => {
				clearInterval(myInterval);
			};
		}
	}, [showMfa, seconds]);

	useEffect(() => {
		if (otpState != "" && otpState.length == 6) {
			setDisableOtpSubmitBtn(false);
		}
	}, [otpState]);

	// const checkDisableSubmitBtn = () => {
	// 	if (loginState.loginId != "" && loginState.password) {
	// 		setDisableSubmitBtn(false);
	// 	} else {
	// 		setDisableSubmitBtn(true);
	// 	}
	// }
return (
	<>
		{showLoader &&
			ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
		<CredentialWrapper
			title={showMfa ? "Enter OTP to Continue" : "Sign in to continue."}
			publicUrl={props.publicUrl}
		>
			{!showMfa && (
				<>
					<form onSubmit={login}>
						<div className="cred-input-div">
							<Input
								handleOnChange={(e) => {
									userNameChangeHandler(e.target.value);
								}}
								placeholder="Username"
								inputType="text"
							/>
							<Input
								handleOnChange={(e) => {
									passwordChangeHandler(e.target.value);
								}}
								placeholder="Password"
								inputType="password"
							/>
						</div>
						<div className="cred-link-div">
							<a
								className="cred-forgot-password-link underline"
								href={forgotPasswordURL}
							>
								Forgot Password?
							</a>
						</div>
						<div className="cred-button-div">
							<Button
								handleOnClick={login}
								isDisabled={disableSubmitBtn}
								type="submit"
								classes="button-ghost cred-button cred-button"
								text="Sign in"
							/>
						</div>
					</form>
					<div className="text-center" style={{ paddingTop: '5px' }}>
						<a
							className="cred-forgot-password-link underline"
							href={loginWithOtpURL}
						>
							Login with Verification Code
						</a>
					</div>
				</>
			)}
			{showMfa && (
				<>
					<form onSubmit={verifyOtp}>
						<div className="cred-input-div">
							<MultiInput
								length={6}
								handleOnChange={handleOtpChange}
								// message={{ value: "Some msg", type: "success" }}
								classNames={{
									input: "otp-input",
									// message: "custom-msg-class",
								}}
							/>
						</div>
						<div className="cred-button-div">
							<Button
								handleOnClick={verifyOtp}
								isDisabled={disableOtpSubmitBtn}
								type="submit"
								classes="button-ghost cred-button button-small"
								text="Submit"
							/>
						</div>
					</form>
					<div className="cred-link-div">
						<Button
							handleOnClick={resendOtp}
							isDisabled={disableResendOtpBtn}
							type="button"
							classes="cred-otp-button"
							text="Resend"
						/>{" "}
						{getTimerString()}
					</div>
				</>
			)}
			{errorState.showError && (
				<p className="cred-error">{errorState.errorText}</p>
			)}
		</CredentialWrapper>
	</>
);
}

export default Login;
