import React, { useEffect, useMemo } from "react";
import ShadowWrapper from "../../ShadowWrapper";
import ViewDataInModal from "../../ViewDataInModal";
import { dateFormatter } from "sfy-react";

import "./ConsumerHistory.scss";

const ConsumerHistory = (props) => {
	const {
		localString,
		getConsumerClaimHistory,
		caseDetails,
		consumerHistory,
		baseUrl,
	} = props;
	const currentSoldPlanCode = caseDetails?.soldPlan?.soldPlanCode || "";

	useEffect(() => {
		if (
			caseDetails &&
			caseDetails.soldPlan &&
			caseDetails.soldPlan.consumerID
		)
			getConsumerClaimHistory();
	}, []);

	const currentPlan = useMemo(() => {
		let plan = {};
		if (
			currentSoldPlanCode &&
			consumerHistory &&
			consumerHistory.length > 0
		) {
			plan = consumerHistory.find(
				(d) => d.soldPlanCode === currentSoldPlanCode
			);
		}

		return plan;
	}, [currentSoldPlanCode, consumerHistory]);

	const nullSoldPlanIdPlan = useMemo(() => {
		let plans = [];
		if (consumerHistory && consumerHistory.length > 0) {
			plans = consumerHistory.filter(
				(d) => d.soldPlanID === null
			);
		}

		return plans;
	}, [consumerHistory]);

	return (
		<div className="consumer-history-wrapper">
			{currentPlan && (
				<div style={{ marginTop: "25px", marginBottom: "25px" }}>
					<div className="consumer-history-header">
						<div className="consumer-history-heading-text">
							{currentPlan.planDisplayName || ""}
						</div>

						<div
							className="consumer-history-spc-text"
							onClick={(e) => {
								e.stopPropagation();
								if (
									currentPlan &&
									currentPlan.soldPlanID !== null
								) {
									let url = `${baseUrl}/plans/consumer/view?spid=${currentPlan.soldPlanID}`;
									const newWindow = window.open(
										url,
										"_blank",
										"noopener,noreferrer"
									);
									if (newWindow) newWindow.opener = null;
								}
							}}
						>
							{currentPlan.soldPlanCode || "NON_EXISTING"}
						</div>
						<div className="current-tag-tablet">Current</div>
					</div>

					<ShadowWrapper classname="rounded">
						<div className="consumer-history-card">
							<div className="consumer-history-card-row-8">
								<div className="consumer-history-heading-text">
									{localString("Plan")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Sold Plan")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Purchase Date")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Activation Date")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("IMEI/Serial NO.")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Product")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Retailer")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Plan Status")}
								</div>
							</div>

							<div className="consumer-history-card-row-8 second-row-pad">
								<div>{currentPlan.planDisplayName || "NA"}</div>

								<div>{currentPlan.soldPlanCode || "NON_EXISTING"}</div>

								<div>
									{currentPlan.dateOfPurchase
										? dateFormatter(
												new Date(
													currentPlan.dateOfPurchase
												),
												"dd/mm/yyyy"
										  )
										: "NA"}
								</div>

								<div>
									{currentPlan.dateOfActivation
										? dateFormatter(
												new Date(
													currentPlan.dateOfActivation
												),
												"dd/mm/yyyy"
										  )
										: "NA"}
								</div>

								<div>{currentPlan.productUniqueID || "NA"}</div>

								<div>{currentPlan.ProductName || "NA"}</div>

								<div>
									{currentPlan.serviceLocationName || "NA"}
								</div>

								<div>{currentPlan.status || "NA"}</div>
							</div>
						</div>
					</ShadowWrapper>

					<ShadowWrapper classname="rounded">
						<div className="consumer-history-card">
							<div className="consumer-history-card-row-8">
								<div className="consumer-history-heading-text">
									{localString("Reference ID")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Claim Status")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Creation Date")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Service Type")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Issue")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Service Center")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Damage Location")}
								</div>

								<div className="consumer-history-heading-text">
									{localString("Damage Description")}
								</div>
							</div>
							{currentPlan.consumerServiceRequests &&
							currentPlan.consumerServiceRequests.length > 0 ? (
								currentPlan.consumerServiceRequests.map(
									(csr, i) => (
										<div
											key={i}
											className="consumer-history-card-row-8 second-row-pad"
										>
											<div
												className="consumer-history-card-text-link"
												onClick={(e) => {
													e.stopPropagation();
													let url = `${baseUrl}/servicerequests/view?csrid=${csr.ConsumerServiceRequestID}`;
													const newWindow =
														window.open(
															url,
															"_blank",
															"noopener,noreferrer"
														);
													if (newWindow)
														newWindow.opener = null;
												}}
											>
												{csr.referenceID || "NA"}
											</div>

											<div>{csr.status || "NA"}</div>

											<div>
												{csr.createdDate
													? dateFormatter(
															new Date(
																csr.createdDate
															),
															"dd/mm/yyyy"
													  )
													: "NA"}
											</div>

											<div>{csr.type || "NA"}</div>

											<div>
												{csr.issues &&
												csr.issues.length > 1 ? (
													<ViewDataInModal
														content={`${
															csr.issues[0][
																"issueType"
															]
														}  +${
															csr.issues.length -
															1
														}  `}
													>
														<>
															{csr.issues.map(
																(issue, idx) =>
																	idx > 0 && (
																		<div>
																			{
																				issue.issueType
																			}
																		</div>
																	)
															)}
														</>
													</ViewDataInModal>
												) : csr.issues &&
												  csr.issues.length === 1 ? (
													csr.issues[0]["issueType"]
												) : (
													"NA"
												)}
											</div>

											<div>
												{csr.serviceLocationName || "NA"}
											</div>

											<div>
												{csr?.csrDetails
													?.PlaceOfDamage || "NA"}
											</div>

											<div>
												{csr?.csrDetails
													?.descriptionOfLoss || "NA"}
											</div>
										</div>
									)
								)
							) : (
								<div className="text-center consumer-history-heading-text second-row-pad">
									No Data
								</div>
							)}
						</div>
					</ShadowWrapper>
				</div>
			)}

			{consumerHistory &&
				consumerHistory.length > 0 &&
				consumerHistory.map(
					(history, idx) =>
						history.soldPlanCode !== currentSoldPlanCode &&
						history.soldPlanID !== null && (
							<div style={{ margin: "50px 0" }} key={idx}>
								<div className="consumer-history-header">
									<div className="consumer-history-heading-text">
										{history.planDisplayName || ""}
									</div>
									<div
										className="consumer-history-spc-text"
										onClick={(e) => {
											e.stopPropagation();
											if (
												history.soldPlanID &&
												history.soldPlanID !==
													null
											) {
												let url = `${baseUrl}/plans/consumer/view?spid=${history.soldPlanID}`;
												const newWindow = window.open(
													url,
													"_blank",
													"noopener,noreferrer"
												);
												if (newWindow)
													newWindow.opener = null;
											}
										}}
									>
										{history.soldPlanCode || "NON_EXISTING"}
									</div>
								</div>

								<ShadowWrapper classname="rounded">
									<div className="consumer-history-card">
										<div className="consumer-history-card-row-8">
											<div className="consumer-history-heading-text">
												{localString("Plan")}
											</div>

											<div className="consumer-history-heading-text">
												{localString("Sold Plan")}
											</div>

											<div className="consumer-history-heading-text">
												{localString("Purchase Date")}
											</div>

											<div className="consumer-history-heading-text">
												{localString("Activation Date")}
											</div>

											<div className="consumer-history-heading-text">
												{localString("IMEI/Serial NO.")}
											</div>

											<div className="consumer-history-heading-text">
												{localString("Product")}
											</div>

											<div className="consumer-history-heading-text">
												{localString("Retailer")}
											</div>

											<div className="consumer-history-heading-text">
												{localString("Plan Status")}
											</div>
										</div>

										<div className="consumer-history-card-row-8 second-row-pad">
											<div>
												{history.planDisplayName || "NA"}
											</div>

											<div>
												{history.soldPlanCode || "NON_EXISTING"}
											</div>

											<div>
												{history.dateOfPurchase
													? dateFormatter(
															new Date(
																history.dateOfPurchase
															),
															"dd/mm/yyyy"
													  )
													: "NA"}
											</div>

											<div>
												{history.dateOfActivation
													? dateFormatter(
															new Date(
																history.dateOfActivation
															),
															"dd/mm/yyyy"
													  )
													: "NA"}
											</div>

											<div>
												{history.productUniqueID || "NA"}
											</div>

											<div>
												{history.ProductName || "NA"}
											</div>

											<div>
												{history.serviceLocationName ||
													"NA"}
											</div>

											<div>{history.status || "NA"}</div>
										</div>
									</div>
								</ShadowWrapper>

								<ShadowWrapper classname="rounded">
									<div className="consumer-history-card">
										<div className="consumer-history-card-row-8">
											<div className="consumer-history-heading-text">
												{localString("Reference ID")}
											</div>

											<div className="consumer-history-heading-text">
												{localString("Claim Status")}
											</div>

											<div className="consumer-history-heading-text">
												{localString("Creation Date")}
											</div>

											<div className="consumer-history-heading-text">
												{localString("Service Type")}
											</div>

											<div className="consumer-history-heading-text">
												{localString("Issue")}
											</div>

											<div className="consumer-history-heading-text">
												{localString("Service Center")}
											</div>

											<div className="consumer-history-heading-text">
												{localString("Damage Location")}
											</div>

											<div className="consumer-history-heading-text">
												{localString(
													"Damage Description"
												)}
											</div>
										</div>
										{history.consumerServiceRequests &&
										history.consumerServiceRequests.length >
											0 ? (
											history.consumerServiceRequests.map(
												(csr, i) => (
													<div
														key={i}
														className="consumer-history-card-row-8 second-row-pad"
													>
														<div
															className="consumer-history-card-text-link"
															onClick={(e) => {
																e.stopPropagation();
																let url = `${baseUrl}/servicerequests/view?csrid=${csr.ConsumerServiceRequestID}`;
																const newWindow =
																	window.open(
																		url,
																		"_blank",
																		"noopener,noreferrer"
																	);
																if (newWindow)
																	newWindow.opener =
																		null;
															}}
														>
															{csr.referenceID ||
																"NA"}
														</div>

														<div>
															{csr.status || "NA"}
														</div>

														<div>
															{csr.createdDate
																? dateFormatter(
																		new Date(
																			csr.createdDate
																		),
																		"dd/mm/yyyy"
																  )
																: "NA"}
														</div>

														<div>
															{csr.type || "NA"}
														</div>

														<div>
															{csr.issues &&
															csr.issues.length >
																1 ? (
																<ViewDataInModal
																	content={`${
																		csr
																			.issues[0][
																			"issueType"
																		]
																	}  +${
																		csr
																			.issues
																			.length -
																		1
																	}  `}
																>
																	<>
																		{csr.issues.map(
																			(
																				issue,
																				idx
																			) =>
																				idx >
																					0 && (
																					<div>
																						{
																							issue.issueType
																						}
																					</div>
																				)
																		)}
																	</>
																</ViewDataInModal>
															) : csr.issues &&
															  csr.issues
																	.length ===
																	1 ? (
																csr.issues[0][
																	"issueType"
																]
															) : (
																"NA"
															)}
														</div>

														<div>
															{csr.serviceLocationName ||
																"NA"}
														</div>

														<div>
															{csr?.csrDetails
																?.PlaceOfDamage ||
																"NA"}
														</div>

														<div>
															{csr?.csrDetails
																?.descriptionOfLoss ||
																"NA"}
														</div>
													</div>
												)
											)
										) : (
											<div className="text-center consumer-history-heading-text second-row-pad">
												No Data
											</div>
										)}
									</div>
								</ShadowWrapper>
							</div>
						)
				)}

			{nullSoldPlanIdPlan &&
				nullSoldPlanIdPlan.length > 0 &&
				nullSoldPlanIdPlan.map((history, idx) => (
					<div style={{ margin: "50px 0" }} key={idx}>
						<div className="consumer-history-header">
							<div className="consumer-history-heading-text">
								{history.planDisplayName || ""}
							</div>
							<div
								className="consumer-history-spc-text"
								onClick={(e) => {
									e.stopPropagation();

									if (
										history.soldPlanID &&
										history.soldPlanID !== null
									) {
										let url = `${baseUrl}/plans/consumer/view?spid=${history.soldPlanID}`;
										const newWindow = window.open(
											url,
											"_blank",
											"noopener,noreferrer"
										);
										if (newWindow) newWindow.opener = null;
									}
								}}
							>
								{history.soldPlanCode || "NON_EXISTING"}
							</div>
						</div>

						<ShadowWrapper classname="rounded">
							<div className="consumer-history-card">
								<div className="consumer-history-card-row-8">
									<div className="consumer-history-heading-text">
										{localString("Plan")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Sold Plan")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Purchase Date")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Activation Date")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("IMEI/Serial NO.")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Product")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Retailer")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Plan Status")}
									</div>
								</div>

								<div className="consumer-history-card-row-8 second-row-pad">
									<div>{history.planDisplayName || "NA"}</div>

									<div>{history.soldPlanCode || "NON_EXISTING"}</div>

									<div>
										{history.dateOfPurchase
											? dateFormatter(
													new Date(
														history.dateOfPurchase
													),
													"dd/mm/yyyy"
											  )
											: "NA"}
									</div>

									<div>
										{history.dateOfActivation
											? dateFormatter(
													new Date(
														history.dateOfActivation
													),
													"dd/mm/yyyy"
											  )
											: "NA"}
									</div>

									<div>{history.productUniqueID || "NA"}</div>

									<div>{history.ProductName || "NA"}</div>

									<div>
										{history.serviceLocationName || "NA"}
									</div>

									<div>{history.status || "NA"}</div>
								</div>
							</div>
						</ShadowWrapper>

						<ShadowWrapper classname="rounded">
							<div className="consumer-history-card">
								<div className="consumer-history-card-row-8">
									<div className="consumer-history-heading-text">
										{localString("Reference ID")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Claim Status")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Creation Date")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Service Type")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Issue")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Service Center")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Damage Location")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Damage Description")}
									</div>
								</div>
								{history.consumerServiceRequests &&
								history.consumerServiceRequests.length > 0 ? (
									history.consumerServiceRequests.map(
										(csr, i) => (
											<div
												key={i}
												className="consumer-history-card-row-8 second-row-pad"
											>
												<div
													className="consumer-history-card-text-link"
													onClick={(e) => {
														e.stopPropagation();
														let url = `${baseUrl}/servicerequests/view?csrid=${csr.ConsumerServiceRequestID}`;
														const newWindow =
															window.open(
																url,
																"_blank",
																"noopener,noreferrer"
															);
														if (newWindow)
															newWindow.opener =
																null;
													}}
												>
													{csr.referenceID || "NA"}
												</div>

												<div>{csr.status || "NA"}</div>

												<div>
													{csr.createdDate
														? dateFormatter(
																new Date(
																	csr.createdDate
																),
																"dd/mm/yyyy"
														  )
														: "NA"}
												</div>

												<div>{csr.type || "NA"}</div>

												<div>
													{csr.issues &&
													csr.issues.length > 1 ? (
														<ViewDataInModal
															content={`${
																csr.issues[0][
																	"issueType"
																]
															}  +${
																csr.issues
																	.length - 1
															}  `}
														>
															<>
																{csr.issues.map(
																	(
																		issue,
																		idx
																	) =>
																		idx >
																			0 && (
																			<div>
																				{
																					issue.issueType
																				}
																			</div>
																		)
																)}
															</>
														</ViewDataInModal>
													) : csr.issues &&
													  csr.issues.length ===
															1 ? (
														csr.issues[0][
															"issueType"
														]
													) : (
														"NA"
													)}
												</div>

												<div>
													{csr.serviceLocationName ||
														"NA"}
												</div>

												<div>
													{csr?.csrDetails
														?.PlaceOfDamage || "NA"}
												</div>

												<div>
													{csr?.csrDetails
														?.descriptionOfLoss ||
														"NA"}
												</div>
											</div>
										)
									)
								) : (
									<div className="text-center consumer-history-heading-text second-row-pad">
										No Data
									</div>
								)}
							</div>
						</ShadowWrapper>
					</div>
				))}
		</div>
	);
};

export default ConsumerHistory;
