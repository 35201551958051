import React from "react";
import "./FilterInputPlan.scss";
import { Input, Button } from "sfy-react";
import IntlTelInputWrapper from "../../../components/IntlTelInputWrapper/IntlTelInputWrapper";

const FilterInputPlan = (props) => {
	const {
		filterFunc,
		filterInputs,
		disableApplyBtn,
		clearSearch,
		hideClearButton,
		disableClearButton,
		filterData,
		localString,
		saveButtonText,
		cancelButtonText,
		hideSaveButton,
		showLoader,
	} = props;
	return (
		<div>
			<form onSubmit={filterFunc}>
				<div className="col-sm-12">
					{filterInputs &&
						filterInputs.length > 0 &&
						filterInputs.map((filter, index) => {
							return (
								filter.showFilter && (
									<div
										className={
											filter.customClass
												? filter.customClass
												: "col-sm-6 paddingB10"
										}
									>
										{(!filter.type ||
											filter.type == "input") && (
											<Input
												value={
													filterData[filter.dataKey]
												}
												key={index}
												handleOnChange={(e) => {
													filter.oninputFunc(
														e.target.value
													);
												}}
												label={filter.labelText}
												labelAlign="top"
												isDisabled={filter.isDisabled}
												className={{ input: "test1" }}
												maxLength={filter.maxLength}
												placeholder={filter.placeholder}
											/>
										)}
										{filter.type &&
											filter.type == "intelInput" && (
												<div className="userForm">
													<IntlTelInputWrapper
														value={
															filterData[
																filter.dataKey
															]
														}
														handleOnChange={(
															val,
															countryData
														) =>
															filter.changeFunc(
																val,
																countryData
															)
														}
														onFlagSelect={(
															countryData
														) =>
															filter.onFlagSelect(
																countryData
															)
														}
														label={filter.labelText}
														maxLength={
															filter.maxlength
														}
														key={index}
														defaultCountry={
															filter.defaultCountry
														}
														intlTelInputClassWrapper={{
															formControl:
																"form-control--top ",
															label: "input__label",
															containerClass:
																"input-container",
															inputClass: "input",
														}}
														placeholder={
															filter.placeholder
														}
													></IntlTelInputWrapper>
												</div>
											)}

										<div>
											{filter.errorMessage && (
												<span className="error-input">
													{filter.errorMessage}
												</span>
											)}
										</div>
									</div>
								)
							);
						})}
				</div>
				<div className="button-container-plansales">
					{!hideClearButton && (
						<Button
							handleOnClick={clearSearch}
							isDisabled={disableClearButton}
							type="button"
							classes="button-ghost btn1"
							text={
								cancelButtonText
									? localString(cancelButtonText)
									: localString("Clear All")
							}
						></Button>
					)}
					{!hideSaveButton && (
						<Button
							handleOnClick={filterFunc}
							isDisabled={disableApplyBtn}
							type="submit"
							className="button button-primary btn13"
							text={
								saveButtonText
									? localString(saveButtonText)
									: localString("Apply")
							}
						></Button>
					)}
				</div>
			</form>
		</div>
	);
};

export default FilterInputPlan;
