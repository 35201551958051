import React, { useState } from 'react';
import { Dropdown, Input } from 'sfy-react';
import _ from 'lodash';


function RecursiveComponent(props) {
    const {
        localString, mainQuery, questionDetails, setQuestionDetails, questionArr, reference, index
    } = props;

    const defaultQuery = JSON.parse(JSON.stringify(mainQuery));

    const clearAnsFields = (ansObj) => {
        ansObj.isSelect = false;
        delete ansObj.answerIndex;
        ansObj?.questions?.length && ansObj.questions.forEach((questionItem, k) => {
            questionItem.isSelect = false;
            questionItem.value = '';
            if(['FFB', 'INT', 'BBX'].includes(questionItem.answerType)) {
                if(questionItem?.answers?.length) {
                    questionItem.answers[0] = {
                        isSelect: false,
                        answerPhrase: "",
                    }
                }
            }else {
                questionItem?.answers?.length && questionItem.answers.forEach((ansItem) => {
                    ansItem = clearAnsFields(ansItem);
                });
            }
        });
        return ansObj
    }

    const handleDropDownChange = (data, questionIndex) => {
        let query = _.cloneDeep(questionDetails);
        let obj = _.get(query, `${reference}[${questionIndex}]`);
        obj.isSelect = true;
        obj.value = data.answerPhrase;
        if(obj.answers && obj.answers.length) {
            let arr = [];
            obj.answers.map((a, key) => {
                let item = {...a};
                if(item.answerPhrase == data.answerPhrase) {
                    item.isSelect = true;
                    obj.answerIndex = key;
                }else {
                    // item.isSelect = false;
                    item = clearAnsFields(item);
                }
                arr.push(item);
            });
            obj.answers = arr;
        }
        _.set(query, `${reference}[${questionIndex}]`, obj);
        setQuestionDetails(query);
    }

    const handleRadioChange = (e, radioIndex, questionIndex) => {
        let query = _.cloneDeep(questionDetails);
        let obj = _.get(query, `${reference}[${questionIndex}]`);
        obj.isSelect = e.target.checked;
        if(obj.answers && obj.answers.length) {
            obj.answerIndex = radioIndex;
            let arr = [];
            obj.answers.map((a, key) => {
                let item = {...a};
                if(key == radioIndex) {
                    item.isSelect = true;
                }else {
                    // item.isSelect = false;
                    item = clearAnsFields(item);
                }
                arr.push(item);
            });
            obj.answers = arr;
        }
        _.set(query, `${reference}[${questionIndex}]`, obj);
        setQuestionDetails(query);
    }

    const handleCheckBoxChange = (e, checkboxIndex, questionIndex) => {
        let query = _.cloneDeep(questionDetails);
        let obj = _.get(query, `${reference}[${questionIndex}]`);
        // let defaultObj = _.get(defaultQuery, `${reference}[${questionIndex}]`);
        if(obj.answers && obj.answers.length) {
            if(e.target.checked) {
                let arr = [...obj.answers];
                arr[checkboxIndex].isSelect = true;
                obj.isSelect = true;
                obj.answers = arr;
            }else {
                let arr = [...obj.answers];
                arr[checkboxIndex] = clearAnsFields(arr[checkboxIndex]);
                let selectedAns = arr.filter(a => a.isSelect);
                obj.isSelect = selectedAns.length ? true : false;
                obj.answers = arr;
            }
        }
        _.set(query, `${reference}[${questionIndex}]`, obj);
        setQuestionDetails(query);
    }

    const handleTextChange = (e, questionIndex) => {
        let value = e.target.value || '';
        let query = _.cloneDeep(questionDetails);
        let obj = _.get(query, `${reference}[${questionIndex}]`);
        if(value) {
            obj.isSelect = true;
            if(obj.answers && obj.answers.length) {
                obj.answers[0].isSelect = true;
                obj.answers[0].answerPhrase = value;
            }
        }else {
            obj.isSelect = false;
            if(obj.answers && obj.answers.length) {
                obj.answers[0].isSelect = false;
                obj.answers[0].answerPhrase = '';
            }
        }
        _.set(query, `${reference}[${questionIndex}]`, obj);
        setQuestionDetails(query);
    }

    return (
        <div>
            {
                questionArr && (questionArr.length > 0) && questionArr.map((question, questionIndex) => <div style={{ paddingLeft: '20px' }}>
                    { question.answerType != 'INFO' && question.questionPhrase && <div className='labelText'>
                        {question.questionPhrase}{question.questionId && `(${question.questionId})`}{!question.optional && <span className="red">*</span>}
                    </div> }
                    { question.answerType == 'INFO' && <div className="ques-info">
                        <div className="info-div">
                            <span>Note: {<span dangerouslySetInnerHTML={{ __html: question.questionPhrase }} ></span>}{`(${question.questionId})`}</span></div>
                    </div> }
                    { (question.answerType == 'DPD') && <>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <Dropdown
                                    value={question.value || ''}
                                    options={question.answers.map(a => ({...a, value: a.answerPhrase}))}
                                    handleOnChange={(data) => handleDropDownChange(data, questionIndex)}
                                    filterBy='value'
                                    showTooltip={true}
                                    placeholder={localString('Select your option')}
                                />
                            </div>
                        </div>
                    </> }
                    { (question.answerType == 'RAD') && <>
                        <div className='row'>
                            { question.answers && (question.answers.length > 0) && question.answers.map((radioObj, radioIndex) => <>
                                <div className='col-sm-4'>
                                    <label className="check-label-ques">
                                        <input 
                                            type="radio"
                                            name={question.questionId} 
                                            onClick={(e) => handleRadioChange(e, radioIndex, questionIndex)} 
                                            checked={radioObj.isSelect} 
                                            id={radioObj.answerId}
                                            style={{ margin: '0', marginRight:'5px' }}
                                        />
                                        {radioObj.answerPhrase}
                                    </label>
                                </div>
                            </>)
                            }
                        </div>
                    </> }
                    { (question.answerType == 'CHK') && <>
                        <div className=''>
                            { question.answers && (question.answers.length > 0) && question.answers.map((checkboxObj, checkboxIndex) => <>
                                <div className=''>
                                    <label className="check-label-ques">
                                        <input 
                                            type="checkbox"
                                            // name={question.questionId} 
                                            onClick={(e) => handleCheckBoxChange(e, checkboxIndex, questionIndex)} 
                                            checked={checkboxObj.isSelect} 
                                            // id={checkboxObj.answerId}
                                            style={{ margin: '0', marginRight:'5px' }}
                                        />
                                        {checkboxObj.answerPhrase}
                                    </label>
                                </div>
                                { checkboxObj.isSelect && checkboxObj?.questions?.length && 
                                    <RecursiveComponent 
                                        mainQuery={mainQuery}
                                        localString={localString}
                                        questionArr={checkboxObj.questions}
                                        questionDetails={questionDetails} setQuestionDetails={setQuestionDetails}
                                        reference={`${reference}[${questionIndex}]['answers'][${checkboxIndex}]['questions']`}
                                    /> 
                                }
                            </>)
                            }
                        </div>
                    </> }
                    { (question.answerType == 'FFB') && <>
                        <div className='row'>
                            <div className='col-sm-8'>
                                <Input
                                    value={question.answers[0].answerPhrase}
                                    handleOnChange={(e) => handleTextChange(e, questionIndex)}
                                    inputComponent='textarea'
                                    className={{
                                        inputContainer: 'lookUpTextBox'
                                    }}
                                    otherProps={{
                                        rows: 1
                                    }}
                                />
                            </div>
                        </div>
                    </> }
                    { (question.answerType == 'BBX') && <>
                        <div className='row'>
                            <div className='col-sm-8'>
                                <Input
                                    value={question.answers[0].answerPhrase}
                                    handleOnChange={(e) => handleTextChange(e, questionIndex)}
                                    inputComponent='textarea'
                                    className={{
                                        inputContainer: 'lookUpTextBox text-area-remove-resize'
                                    }}
                                    otherProps={{
                                        rows: 7
                                    }}
                                />
                            </div>
                        </div>
                    </> }
                    { (question.answerType == 'INT') && <>
                        <div className='row'>
                            <div className='col-sm-8'>
                                <Input
                                    value={question.answers[0].answerPhrase}
                                    handleOnChange={(e) => handleTextChange(e, questionIndex)}
                                    className={{
                                        inputContainer: 'lookUpTextBox'
                                    }}
                                />
                            </div>
                        </div>
                    </> }
                    { question.isSelect && (question.answerType !== 'CHK') && question?.answers?.length && question?.answers.map((ansObj, ansKey) => <>
                        { ansObj.isSelect && ansObj?.questions?.length && 
                            <RecursiveComponent 
                                // index={treeIndex}
                                mainQuery={mainQuery}
                                localString={localString}
                                questionArr={ansObj.questions}
                                questionDetails={questionDetails} setQuestionDetails={setQuestionDetails}
                                reference={`${reference}[${questionIndex}]['answers'][${ansKey}]['questions']`}
                            /> 
                        }
                    </>)}
                </div>)
            }
        </div>
    );
}

export default RecursiveComponent;