import React from 'react';
import CommunicationInput from './CommunicationInput';
const  WhatsAppDetails = (props)=>{
    const {whatsAppLogData}=props
    return( 
        <CommunicationInput
            {...props}
            communicationData = {whatsAppLogData}
        />
    )
}
export default WhatsAppDetails;