import React, { useCallback, useMemo } from "react";
import { Modal, Carousel } from "sfy-react";

import "./DisplayProofImages.scss";

const DisplayProofImages = (props) => {
	const { localString, showModal, closeModal, selectedDocumentGroupObject } =
		props;

	const bannerList = useMemo(() => {
		let list = [];

		if (
			selectedDocumentGroupObject &&
			selectedDocumentGroupObject.consumerServiceRequestProof &&
			selectedDocumentGroupObject.consumerServiceRequestProof.length > 0
		) {
			list =
				selectedDocumentGroupObject.consumerServiceRequestProof.reduce(
					(accumulator, sd) => {
						if (
							sd.consumerServiceRequestProofRecords &&
							sd.consumerServiceRequestProofRecords.length > 0
						) {
							accumulator.push({
								label: sd.proofMasterName,
								src: sd.consumerServiceRequestProofRecords[0]
									.url,
								type:
									sd.consumerServiceRequestProofRecords[0]
										.contentType || "",
							});
						}

						return accumulator;
					},
					[]
				);
		}

		return list;
	}, [selectedDocumentGroupObject]);

	const Banner = useCallback(({ src, label, type }) => {
		return (
			<div className="proof-images-banner-container">
				<div>
					<h2>{label}</h2>
				</div>

				{type.startsWith("image") ? (
					<div class="image-container">
						<img src={src} alt="Example Image" />
					</div>
				) : type.startsWith("video") ? (
					<video
						controls
						controlsList="nodownload nofullscreen"
						disablePictureInPicture
						style={{
							width: "600px",
							height: "320px",
							padding: "0 30px",
						}}
						width="570"
					>
						<source src={src} />
					</video>
				) : (
					<iframe
						src={src}
						width={800}
						height={850}
						style={{ overflow: "scroll" }}
					></iframe>
				)}
			</div>
		);
	}, []);

	return (
		<>
			<Modal
				showModal={showModal}
				closeModal={closeModal}
				showCloseBtn={true}
				className="proof-images-modal-width"
			>
				<Modal.Header>
					<h4 className="proof-images-header">
						{localString("Proofs")}
					</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="proof-images-wrapper">
						<div className="proof-images-carousel-container">
							<Carousel
								items={bannerList}
								autoPlay={false}
								className={{ arrow: "slider-btn-color" }}
								showDots
								individualBanner={(item) => (
									<Banner
										src={item.src}
										label={item.label}
										type={item.type}
									/>
								)}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
};

export default DisplayProofImages;
