import React from 'react';
import {Modal} from 'sfy-react';
import CreateTaskInput from './CreateTaskInput';
import "./CreateTaskModal.scss";
const CreateTaskModal=(props)=>{
    const {localString,showModal,closeModal,ratingModalTitle,selectPriorityType} = props;
		return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
                <Modal.Header>
                    <h2> {ratingModalTitle || '-'} </h2>
                </Modal.Header>
                <Modal.Body>
                    <CreateTaskInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default CreateTaskModal;