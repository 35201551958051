import React, {useState,useEffect} from 'react';
import './ExpandItem.scss';

const ExpandItem = (props) => {
    const {localString, name, showImage, imgUrl, imgAlt, subHeaderText, subHeaderText1, subHeaderText2, showExpandArrow, setHistoryModalObj, isSubHeaderClickable} = props;
    const [expandItem, setExpandItem] = useState(false);
    return (
        <div className="col-md-4">
            <div className={"diagnosis-item " + (expandItem ? "open" : "")}>
                <div className="item-header">
                    <div className="header-text">{name}</div>
                    {(showImage && imgUrl) ? <img className="item-img" src={imgUrl} alt={imgAlt}></img> : ''}
                    {(showImage && !imgUrl) ? <span>{imgAlt}</span> : ''}
                </div>
                <div className="sub-header" onClick={()=> {isSubHeaderClickable && setHistoryModalObj(name)}}>
                    <p>{subHeaderText ? subHeaderText : ''}</p>
                    <p>{subHeaderText1 ? subHeaderText1 : ''}</p>
                    <p>{subHeaderText2 ? subHeaderText2 : ''}</p>
                </div>
                {showExpandArrow && <div className="diagnosis-bottom-bar">
                    <span className={"glyphicon click-icon " + (expandItem ? "glyphicon-menu-up" : "glyphicon-menu-down")} 
                    aria-hidden="true" onClick={() => setExpandItem(!expandItem)}></span>
                </div>}
                <div className="expanded-section">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default ExpandItem
