import React from 'react';
import {Table} from 'sfy-react';
import { SERVICETYPE } from '../../../../../Utility/Utils';

const ReplacementDeviceDetailsTable = (props) => {
	const {newProductDetail, localString, consumerServiceRequest} = props;

	const classObject = {
		table:"table table-striped complaint-details-table request-table"
	}
	
	return (
		<div>
		{ 
			<Table className={classObject}>
				<Table.Body>
					<Table.Row>
						{
							[SERVICETYPE.CLAIM_DOOR_TO_DOOR_UPGRADE,SERVICETYPE.DOOR_TO_DOOR_UPGRADE,SERVICETYPE.PICKUP_UPGRADE,SERVICETYPE.CLAIM_UPGRADE_PICKUP,SERVICETYPE.MAIL_IN_UPGRAGE,SERVICETYPE.CLAIM_UPGRADE_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && 
							<Table.Data className="table-keys text-capitalize">{localString("Upgraded")} {localString("Device")} </Table.Data>
						}
						{
							[SERVICETYPE.CLAIM_DOOR_TO_DOOR_REPLACEMENT,SERVICETYPE.CLAIM_REPLACEMENT_PICKUP,SERVICETYPE.CLAIM_REPLACEMENT_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && 
							<Table.Data className="table-keys text-capitalize">{localString("Replacement")} {localString("Device")}</Table.Data>
						}
						<Table.Data>{newProductDetail && newProductDetail.newProduct && newProductDetail.newProduct.ProductName} {newProductDetail && newProductDetail.newProduct && newProductDetail.newProduct.ModelNo} {newProductDetail && newProductDetail.newProduct && newProductDetail.newProduct.StorageCapacity}</Table.Data>
					</Table.Row>
					<Table.Row>
						<Table.Data className="table-keys"> {localString("Storage")} {localString("Capacity")}</Table.Data>
						<Table.Data>{newProductDetail && newProductDetail.newProduct && newProductDetail.newProduct.StorageCapacity}</Table.Data>
					</Table.Row>
					<Table.Row>
						<Table.Data className="table-keys"> {localString("Color")}</Table.Data>
						<Table.Data>{newProductDetail && newProductDetail.newProduct && newProductDetail.newProduct.Colour}</Table.Data>
					</Table.Row>
					<Table.Row>
						{   
							[SERVICETYPE.CLAIM_DOOR_TO_DOOR_UPGRADE,SERVICETYPE.DOOR_TO_DOOR_UPGRADE,SERVICETYPE.PICKUP_UPGRADE,SERVICETYPE.CLAIM_UPGRADE_PICKUP,SERVICETYPE.MAIL_IN_UPGRAGE,SERVICETYPE.CLAIM_UPGRADE_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && 
							<Table.Data className="table-keys"> {localString("Upgraded")} {localString("Device's")} {localString("IMEI / Serial Number")}</Table.Data>
						}
						{
							[SERVICETYPE.CLAIM_DOOR_TO_DOOR_REPLACEMENT,SERVICETYPE.CLAIM_REPLACEMENT_PICKUP,SERVICETYPE.CLAIM_REPLACEMENT_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && 
							<Table.Data className="table-keys"> {localString("Replacement")} {localString("Device's")} {localString("IMEI / Serial Number")} </Table.Data>
						}
						<Table.Data>{(newProductDetail && newProductDetail.NewProductUniqueID) || '-'} / {(newProductDetail && newProductDetail.NewAlternateUniqueKey) || '-'}</Table.Data>
					</Table.Row>
					{
						[SERVICETYPE.CLAIM_DOOR_TO_DOOR_UPGRADE,SERVICETYPE.DOOR_TO_DOOR_UPGRADE,SERVICETYPE.PICKUP_UPGRADE,SERVICETYPE.CLAIM_UPGRADE_PICKUP,SERVICETYPE.MAIL_IN_UPGRAGE,SERVICETYPE.CLAIM_UPGRADE_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && 
						<Table.Row>
							{
								newProductDetail.consumerProduct && newProductDetail.consumerProduct.ConfigDescription && 
								<Table.Data className="table-keys"> {localString("Customer's")} {localString("Device")}</Table.Data>
							}
							<Table.Data>{newProductDetail.consumerProduct.ConfigDescription || '-'}</Table.Data>
						</Table.Row>
					}
				</Table.Body>
			</Table>
		}
		</div>
	)
}

export default ReplacementDeviceDetailsTable
