import React,{useCallback,useRef,useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader,setAutocomplete} from 'sfy-react';
import Swal from "sweetalert2";
import {findIndex} from 'lodash';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import {displayError,regexTest,generateUUID} from '../../../../../Utility/Utils';

const ChooseConsumerAddress = (props) => {
	const {localString,editIndividualAddress,accessFn,consumerDetails} = props;
	const [addressSelected, setAddressSelected] = useState(false);


	return (
		<div className="row">
			<div className="col-sm-12">
				{
					consumerDetails && consumerDetails.Addresses && consumerDetails.Addresses.map((address, i)=>{
						return( 
							<div className={addressSelected ? "address-list remove-height address-selected " :"address-list remove-height transparent-border "} onClick="">
								<div className="address-margin">
									<div className="row">
										<div className="col-sm-1 col-xs-12">
											<label for="addressAuto" className="address-type">{localString(address.AddressType)}</label>
										</div>
										<div className="col-sm-4 col-xs-12">
											{
												//!opts.edit && 
												<p className="details-value">{address.addressDisplayString || '-'}</p>
											}
										</div>
										{
											!accessFn('RECYCLE_ADMIN_VIEW_ONLY') && !accessFn('READ_ONLY') && 
											<div className="col-sm-1 col-xs-12 text-right">
												<p onClick={(e)=>editIndividualAddress(e,address)} style={{cursor: "pointer"}}><span className="glyphicon glyphicon-edit {hide: edit} edit-individual-address"></span> {localString("Edit")} </p>
											</div>
										}
										
									</div>
								</div>
							</div>
						)
					})
				}
			</div>
		</div>
	)
}

export default ChooseConsumerAddress
