import React from "react";
import './Footer.scss';
const Footer = (props) => {
	const {localString} = props;
	return (
		<div className="footer-div">
			<p>
				{localString("Copyright")} © 2015-{new Date().getFullYear()} Service Lee
				Technologies Pvt Ltd. {localString("All rights reserved.")}
				<br />
				{localString("Also known as Servify.")}
			</p>
		</div>
	);
}

export default Footer;
