import React, { useState } from 'react';
import {Modal,Button} from 'sfy-react';
import './ScheduleButtonModal.scss';
import Swal from "sweetalert2";
import { returnTimezone,displayError } from '../../../../../Utility/Utils';
import moment from 'moment';
const ScheduleButtonInput=(props)=>{
const {scheduleDateArray,apiCall,hiddenContent,closeModal,localString,setShowModal, ConsumerServiceRequestID,selectedScheduleDate,scheduleSlotArray,slotAvailable,setScheduleSlotArray,setSelectedScheduleDate,setSelectedSlot, setSlotAvailable,selectedSlot,showModal,setLoader,getRequestById} = props;
	const scheduleDateClick=(e)=>{
			setSelectedScheduleDate(e);
			setScheduleSlotArray(e.slots);
			let ScheduleSlotArray= [];
			ScheduleSlotArray =e &&  e.slots
			// setSelectedSlot(undefined);
			setSlotAvailable(false);
			for (let i = ScheduleSlotArray.length - 1; i >= 0; i--) {
				if (ScheduleSlotArray[i].IsActive) {
					setSelectedSlot(ScheduleSlotArray[i])
					setSlotAvailable(true);
					
				}
			};
	}

	const scheduleSlotClick=(e) =>{
		if (e.IsActive) {
			setSelectedSlot(e)
		}
	}

	const rescheduleRequest=(remark)=>{
		let data = {
			"ConsumerServiceRequestID":ConsumerServiceRequestID,
			"ScheduledDateTime": (selectedScheduleDate && selectedScheduleDate.date) ? moment(selectedScheduleDate.date).utc().format() : undefined,
			"ScheduledFromTime": selectedSlot.StartTimeVal,
			"ScheduledToTime": selectedSlot.EndTimeVal,
			"Remarks": remark,
			"timezone": returnTimezone()
		};
        setLoader(true);
		apiCall({
			url: "rescheduleRequest",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					Swal.close();
					Swal.fire( {
						title:	'',
						icon:'success' ,
						text:localString("The request was rescheduled successfully"),
					}).then(function(result) {
						if (result.isConfirmed) {
							getRequestById()
						};
					});
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	}
	const showRescheduleRequest=()=>{
		let  rescheduleText =  document.getElementById('reschedule-text').value;
		if(slotAvailable && rescheduleText ){
			Swal.fire({
			title:localString("Are you sure you want to reschedule?"),
			showCancelButton: true,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("Ok"),
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.close();
				let remark= document.getElementById('reschedule-text').value || ""
				// let remark = result.value || "";
				if (remark) {
					setShowModal({...showModal,showSheduleModal:false});
					rescheduleRequest(remark)
					
					// showCancelAnytimeRequestConfirm(remark);
				} else {
					Swal.fire({
						title: "",
						text:  localString(displayError(result)),
						icon: "error",
					}).then((result) => {
						if (result.isConfirmed) {
                            showRescheduleRequest();
						}
					});
				}
			}
		});
		}else if(!rescheduleText){
			Swal.fire({
				title: "",
				text: localString("Please specify the reason for reschedule"),
				icon: "error",
			})
		}else if(!slotAvailable){
			Swal.fire({
				title: "",
				text: localString("Please select a slot for reschedule"),
				icon: "error",
			})
		}
	}
	
		return (
		<>
            <div className='fluid-form'>
				{/* <div className='modal-fluid-form-container'> fluid-form-content fluid-form-active*/}
					<div className=''>
						<label className="text-capitalize">{localString("Select a Date")}</label>
						<div className='row'>
							{
								scheduleDateArray && scheduleDateArray.map(day=>(
									<div className='col-sm-3 col-xs-4'>
										<div className={   day.day==selectedScheduleDate.day ?  'item-active item slot-item' : 'item slot-item'   } onClick={()=>{scheduleDateClick(day)}}>
											<p className="">{day.day} {day.month}</p>
										</div>
									</div>
								))
							}
						</div>
						<label className="text-capitalize">{localString("Select a Slot")}</label>
						<div>
							{
								!slotAvailable ?  
									<div className="col-sm-8 col-sm-offset-2 text-center empty-message text-capitalize">
										{localString("No slots Available")}
									</div>:
									<div className="" >
									{
										scheduleSlotArray && scheduleSlotArray.map(slotArray=>(
											<div className='col-sm-3 col-xs-4' onClick={()=> scheduleSlotClick(slotArray)}>
												<div className={((slotArray.StartTimeVal == selectedSlot.StartTimeVal) &&
													(slotArray.EndTimeVal == selectedSlot.EndTimeVal))  ? 'item-active item slot-item' : 'item slot-item'}  >
													<p class="address-p">{slotArray.StartTime} - {slotArray.EndTime}</p>
												</div>
											</div>
											
										))
									}
									</div>
								}
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12 textMt">
							<label className="text-capitalize">
								{localString("Reason for reschedule")}
							</label>
							<div>
								<textarea className="reschedule-mesg"  id="reschedule-text">
								</textarea>
							</div>
						</div>
					</div>
				</div>
				<div className="button-container">
					<Button type="button" className="btn button-servify button-stack" onClick={showRescheduleRequest}>{localString("Reschedule")}</Button>
					<Button type="button" className="btn button-servify" onClick={closeModal}>{localString("Close")}</Button>
				</div>
				{/* </div> */}
			</>
		)
}
export default ScheduleButtonInput;


