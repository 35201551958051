import React from 'react';
import {Modal} from 'sfy-react';
import './UpdateJobModal.scss';
import UpdateJobStatusInput from './UpdateJobStatusInput';

const UpdateJobModal=(props)=>{
    const {localString,showModal,closeModal,jobModalState} = props;
		return (
		<>
            {jobModalState==1 && <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='Filter-Modal modal-width'>
                <Modal.Header>
                    <h2> {localString("Update Job")} </h2>
                </Modal.Header>
                <Modal.Body>
                    <UpdateJobStatusInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            }
        </>
	)
}
export default UpdateJobModal;