import React, { useState } from 'react';
import './ServiceLocationModal.scss';
import { Button, Input } from 'sfy-react';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { displayError } from '../../../../../Utility/Utils';



const ServiceLocationList = (props) => {
	const { allTransferLocations, consumerServiceRequest, localString, setLoader, apiCall, bulkrequests, closeModal, getRequestById, isPSLTransferVendorExist, isbulkshipment, getInwardOutwardforTransfer,  showVendorList, availableVendorsList,showRequestLogs} = props;
    
	const [transferRemark,setTransferRemark]=useState('')
	const [selectedNow, setSelectedNow] = useState(false);
	const [selectedLater, setSelectedLater] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [isokayvisible, setIsokayvisible] = useState(false);
	const [selectedtransferLocations,setSelectedtransferLocations] = useState([])
	const concatAddress = function(address, landmark, zipcode) {
		return address + ', ' + landmark + ', (' + zipcode + ')';
	};

	let isRepairComplete = _.find(consumerServiceRequest.logs, {
		Status: "Repair completed",
	});
	let isRepairCancelled = _.find(consumerServiceRequest.logs, {
		Status: "Repair cancel",
	});

	const selectForwardTransfer = () => {
		setSelectedNow(true);
		setSelectedLater(false);
		setSelectedtransferLocations(allTransferLocations && allTransferLocations.forward);
	};
	
	const selectReverseTransfer = () => {
		setSelectedNow(false);
		setSelectedLater(true);
		setSelectedtransferLocations(allTransferLocations && allTransferLocations.reverse);
	};

	const setSelectedLocationAddress = (location) => {
		setSelectedLocation(location);
		setIsokayvisible(true);
		
	};

	console.log("slee",selectedNow)
    //Api calls start
	const submitRequest = () => {
		setLoader(true);
		let data = {
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			Receiver: selectedLocation.PartnerServiceLocationID,
			TransferRemarks: transferRemark
		}	
		apiCall({
			url:'createTransferLogistics',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					closeModal();  
					getRequestById();
					if(result.data.IsOutwardRequired){
						getInwardOutwardforTransfer();
					}
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	};

	const chooseLocation = () => {
		if (!selectedLocation) {
			Swal.fire({
				icon: 'warning',
				text: localString("Please select a location")
			})
		}
		else {
			let data ={
				ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
				Receiver: selectedLocation.PartnerServiceLocationID
			};
			setLoader(true)
			apiCall({
				url: 'checkLogisticsServiceAvailabilty',
				data: data,
				callBack:(result)=>	{
					setLoader(false);
					if (result.success) {
						availableVendorsList = result.data;
						showVendorList = isPSLTransferVendorExist ? true : false;
						showVendorList = isPSLTransferVendorExist ? true : false;;
					} else {
						Swal.fire({
							icon: 'error',  
							text: localString(displayError(result)),
						});
					}
				}
			});
		}
	};

	return (
		<div>
			<div className="reward-new">
				<div>
					<p className="modal-subheader-logistics">
						{localString("Transfer request to " +(allTransferLocations && allTransferLocations.reverse && allTransferLocations.reverse.length ? "collection point" : "repair center"))}{" "}
					</p>
				</div>
				<div className="row address-margin">
					{ ((allTransferLocations && allTransferLocations.forward && allTransferLocations.forward.length != 0 )) &&
						<div
							className={(showRequestLogs.isRepairCompleted || showRequestLogs.isRepairCancelled) ? 'dispaly-none':'col-md-2'}
								onClick={() => selectForwardTransfer()}
						>
							<div className="reward-checkbox">
								{ selectedNow ? <div
									class="reward-inner-div"
								></div>
							: "" }
							</div>
							<span>
								{localString("Transfer to")}
							</span>
						</div> 
					}
					{(allTransferLocations && allTransferLocations.reverse && allTransferLocations.reverse.length !=0) && 
						<div
							className='col-md-2'
							onClick={() => selectReverseTransfer()}
						>
							<div className="reward-checkbox">
								{ selectedLater ? <div
									className="reward-inner-div"
								></div>
								: ""
								}
							</div>
							<span>
								{localString("Transfer to")}
							</span>
						</div>
					}
				</div>
			</div> 
			{  selectedtransferLocations &&  selectedtransferLocations.length>0 &&  selectedtransferLocations.map((location, i)=>(
				<div
					key={i}
					className= {"hub-list remove-height " + (selectedLocation && location && selectedLocation.PartnerServiceLocationID === location.PartnerServiceLocationID ? 'address-selected' : 'transparent-border')}
					onClick={() => setSelectedLocationAddress(location)}
				>
					<div className="address-margin">
						<div className="row">
							<div className="col-sm-3 col-xs-12">
								<label
									for="hubname"
									className="address-type"
								>
									{location.ServiceLocationName}
								</label>
							</div>
							<div className="col-sm-6 col-xs-12">
								<p className="details-value">
									{concatAddress(
										location.Address,
										location.Landmark,
										location.ZipCode
									) || "-"}
								</p>
							</div>
							<div className="col-sm-3 col-xs-12">
								<p className="details-value">
									<strong>
										{location.ZipCode ||
											"-"}
									</strong>
								</p>
							</div>
						</div>
					</div>
				</div>
			))
			}
			<div className="row" style={{ margin: '30px 0px' }}>
				<div className="col-sm-12 ">
					<Input
						value={transferRemark}
						handleOnChange={(e) => {
							setTransferRemark(e.target.value)
						}}
						labelAlign="top"
						inputComponent="textarea"
						id="transfer-remark"
						placeholder={localString("Transfer Remarks")}
						otherProps={{ rows: 2 }}
					/>
				</div>
			</div>
			{/* {showVendorList ? (
				<div className="row">
					<logistic_vendor_list
						logisticvendors={availableVendorsList}
						transferlocationid={selectedLocation.PartnerServiceLocationID}
						csrid={consumerServiceRequest.ConsumerServiceRequestID}
						isbulkshipment={isbulkshipment}
						bulkrequests={bulkrequests}
						transferremark={transferRemark}
					></logistic_vendor_list>
				</div>
				)
				: ""
			} */}
			<div className="row">
				{isokayvisible && showVendorList ?
					(<div className="col-sm-12">
						<Button
							className="btn button-servify pull-right"
							handleOnClick={chooseLocation}
						>
							{localString("Okay")}
						</Button>
					</div>)
					:""
				}
			</div>
			{!showVendorList && selectedLocation && 
				<div className="row" style={{margin: '50px 0px'}}>
					<div className="col-sm-12">
						<Button
							className="btn button-servify pull-right"
							handleOnClick={() => submitRequest()}
						>
							{localString("Transfer request")}
						</Button>
					</div>
				</div>
			}
		</div>
	)
}

export default ServiceLocationList;
