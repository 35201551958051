import React,{useState,useEffect} from 'react';
import {Modal,Input,Button,Dropdown} from 'sfy-react';
import './AddShipmentDetailModal.scss';
const AddShipmentDetailInput=(props)=>{
    const {localString,setClaimFlags,claimFlags,setCurrentShipmentDetail,currentShipmentDetail,vender,setVender,venderOptions,setVenderOption,changeBillingDetails,selectedVenderName,saveCurrentShipmentDetails,nextStatus} = props;
    useEffect(()=>{
        checkShipmentDetails()
    },[vender,venderOptions])
    const checkShipmentDetails=()=>{
        if(venderOptions && vender.waybillNumber){
            setCurrentShipmentDetail({...currentShipmentDetail,WaybillNumber:vender.waybillNumber})
        }
    }
    return (
		<>
            <div className='modal-body shipment-modal'>
                <div className='input-field'>
                    <p>{localString("Vendor")}</p>
                    <Dropdown
                        id='dropdown'
                        value={venderOptions}
                        options={selectedVenderName}
                        handleOnChange={(item) => {
                            setVenderOption(item.VendorName);
                            currentShipmentDetail.VendorName = item.VendorName;
                            currentShipmentDetail.VendorID = item.VendorID;
                            if(item.value=="Other"){
                                setClaimFlags({...claimFlags,showVendorNameInput:true})
                            }
                            // checkShipmentDetails(item)
                        }}
                        showNoOptionsText={false}
                        filterBy='value'
                        showTooltip={false}
                        showDownArrow={false} 
                    />
                </div>
                {
                    claimFlags.showVendorNameInput && <div className='input-field label-text'>
                        <Input
                            value={vender.venderName}
                            handleOnChange={(e) => {
                                setVender({...vender,venderName:e.target.value})
                                currentShipmentDetail.VendorName = e.target.value;
                            }}
                            label={localString("Vendor Name")}
                            labelAlign='top'
                        />
                    </div>
                }
                <div className='input-field label-text'>
                    <Input
                        value={vender.waybillNumber}
                            handleOnChange={(e) => {
                                setVender({...vender,waybillNumber:e.target.value})
                            }}
                        label={localString("Waybill Number")}
                        labelAlign='top'
                    />
                </div>
                <div  className="button-container">
                    <Button className="btn button-servify"  handleOnClick={()=>saveCurrentShipmentDetails(nextStatus)}>{localString("Save")}</Button>
                </div>
            </div>
        </>
	)
}
export default  AddShipmentDetailInput;
