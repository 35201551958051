import React, {useState,useEffect} from 'react'
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import {Button, Loader} from "sfy-react";
import { useLocation } from "react-router-dom";
import {cloneDeep, find, forEach, groupBy} from 'lodash';
import moment from 'moment';
import HistoryTab from './HistoryTab';
import ReliabilityRecords from './ReliabilityRecords';
import UserFeedback from './UserFeedback';
import DriverUpdate from './DriverUpdate';
import DriverList from './DriverList';
import DiagnosisReport from './DiagnosisReport';
import ExpandItem from '../../components/ExpandItem/ExpandItem';
import DiagnosisHistoryModal from './DiagnosisHistoryModal';
import InitiateDiagnosis from '../../components/InitiateDiagnosis/InitiateDiagnosis';
import {checkEncryption, displayError, diagnosisGroupConfig, diagnosticStatus} from '../../Utility/Utils';
import './DiagnosisResult.scss';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const DiagnosisResult = (props) => {
    const {apiCall,localString,accessFn,baseUrl,publicUrl} = props;
    const [showLoader ,setLoader] = useState(false);
    const [consumerDetails, setConsumerDetails] = useState(null);
    const query = useQuery();
    const [cpid ,setCpid] = useState(checkEncryption(query.get("cpid")) ? checkEncryption(query.get("cpid")) : query.get("cpid"));
    const [referenceNumber, setReferenceNumber] = useState(checkEncryption(query.get("refno")) ? checkEncryption(query.get("refno")) : query.get("refno"));
    const [imei, setImei] = useState(checkEncryption(query.get("imei")) ? checkEncryption(query.get("imei")) : query.get("imei"));
    const [sno, setSno] = useState(checkEncryption(query.get("sno")) ? checkEncryption(query.get("sno")) : query.get("sno"));
    const [showDiagnosisModal, setShowDiagnosisModal] = useState(false);
    const [jobsheetNumber, setJobsheetNumber] = useState('');
    const [selectedDeviceMode, setSelectedDeviceMode] = useState(query.get('deviceMode') ? query.get('deviceMode') : 'Mobile');
    const [currentIMEI, setCurrentIMEI] = useState('');
    const [qrCodeID, setQrCodeID] = useState('');
    const [diagnosisData, setDiagnosisData] = useState([]);
    const [diagnosisHistory, setDiagnosisHistory]  = useState([]);
    const [userRemark, setRemark]  = useState('');
    const [latestIssueCount, setLatestIssueCount] = useState(0);
    const [historyGroups, setHistoryGroups] = useState({});
    const [testHistory, setTestHistory] = useState({});
    const [testHistoryCount, setTestHistoryCount] = useState({});
    const [activeTab, setActiveTab] = useState('History');
    const [reliabilityData, setReliabilityData] = useState([]);
    const [userFeedback, setUserFeedback] = useState([]);
    const [driverUpdates, setDriverUpdates] = useState([]);
    const [driverRecords, setDriverRecords] = useState({});
    const [pageNo, setPageNo] = useState(1);
    const [brandDetails, setBrandDetails] = useState(null);
    const [activeReportObj, setActiveReportObj] = useState(null);
    const [openReport, setOpenReport] = useState(false);
    const [openHistoryModal, setOpenHistoryModal] = useState(false);
    const [historyModalObj, setHistoryModalObj] = useState(null);
    const tabs = [
        'History', 
        reliabilityData && reliabilityData.length > 0 && 'Reliability records', 
        driverRecords && Object.keys(driverRecords).length > 0 && 'Driver list', 
        userFeedback && userFeedback.length > 0 && 'User feedback', 
        driverUpdates && driverUpdates.length > 0 && 'Driver updates'
    ];

    // useEffect(() => {
    //     let newCpid = query.get("cpid");
    //     let decryptedID = checkEncryption(newCpid);
    //     setCpid(!decryptedID ? newCpid : decryptedID);
    //     let newRefNo = query.get("refno");
    //     let decryptedRefNo = checkEncryption(newRefNo);
    //     setReferenceNumber(!decryptedRefNo ? newRefNo : decryptedRefNo);
    //     let newImei = query.get("imei");
    //     let decryptedImei = checkEncryption(newImei);
    //     setImei(decryptedImei ? decryptedImei : newImei);
    //     let newSno = query.get("sno");
    //     let decryptedSno = checkEncryption(newSno);
    //     setSno(decryptedSno ? decryptedSno : newSno);
    // }, []);

    // useEffect(() => {
    //    (cpid || referenceNumber || imei || sno) && fetchProductDetails();
    // }, [cpid, referenceNumber, imei, sno]);

    useEffect(() => {
        activeReportObj && Object.keys(activeReportObj).length > 0 && setOpenReport(true);
    }, [activeReportObj]);

    useEffect(() => {
        // (pageNo && pageNo > 1) && fetchProductDetails();
        (pageNo) && fetchProductDetails();
    }, [pageNo]);

    useEffect(() => {
        historyModalObj && testHistory && testHistory[historyModalObj] && setOpenHistoryModal(true)
    }, [historyModalObj]);
 
    useEffect(() => {
        setDiagnosisHistory(cloneDeep(diagnosisData));
    }, [diagnosisData]);

    useEffect(() => {
        if(diagnosisHistory.length > 0) {
            checkLatestIssueCount();
            processHistoryObj();
            processTestHistory();
        }
    }, [diagnosisHistory]);

    const fetchProductDetails = (qrCodeVal, currentIMEIVal, previousDiagnosisData) => {
        let url = '';
        let data = {
			pageNo: pageNo,
		    range: 5,
            itemPerPage: 10
        };
        if(cpid) {
            data.ConsumerProductID = cpid;
            data.sendConsumerDetails = true;
            url = 'getProductDetailsForRegisteredProducts';
        } if(imei){
			data.ProductUniqueID = imei;
            url = 'getDiagnosisForConsumerProduct';
        } else if (jobsheetNumber) {
			data.JobsheetNumber = jobsheetNumber
			url = 'getDiagnosisForConsumerProductWindows';
		} else if (referenceNumber && selectedDeviceMode == 'Mobile') {
			data.JobsheetNumber = referenceNumber
			data.apiFromCore = true;
			data.QrCodeID = qrCodeVal || qrCodeID || undefined;
			data.ProductUniqueID = currentIMEIVal || currentIMEI || undefined;
			if(data.QrCodeID) {
				data.apiFromCore = undefined;
			}
			url = 'getDiagnosisForConsumerProduct';
		} else if (referenceNumber && selectedDeviceMode == 'Laptop') {
			data.JobsheetNumber = referenceNumber;
			data.useWindowsDiagnosis = true;
			url = 'getDiagnosisForConsumerProduct';
		} else if(sno){
			data.ProductUniqueID = sno;
			url = 'getDiagnosisForConsumerProduct';
		}

        setLoader(true);
		apiCall({
			url: url,
			data: data,
			callBack:(result)=>{
                setLoader(false);
				if(result.success && result.data) {
                    let diagnosis = result.data?.DiagnosisData;
                    result.data.ReliabilityRecords && result.data.ReliabilityRecords.length > 0 && setReliabilityData(result.data.ReliabilityRecords);
                    result.data.DriverRecords && Object.keys(result.data.ReliabilityRecords).length > 0 && setDriverRecords(result.data.DriverRecords);
                    if(cpid) {
                        setConsumerDetails(result.data);
                        setBrandDetails(result.data.brand);
                        result.data.diagnosis && result.data.diagnosis.DiagnosisData && 
                        result.data.diagnosis.DiagnosisData.length > 0 && setDiagnosisData(result.data.diagnosis.DiagnosisData);
                    }else if(imei || jobsheetNumber || sno) {
                        diagnosis && diagnosis.length > 0 && setDiagnosisData(diagnosis);
					}else if(referenceNumber) {
                        if(qrCodeVal) {
                            let arr = previousDiagnosisData ? previousDiagnosisData : diagnosisData;
                            let diagArr = cloneDeep(arr);
                            forEach(diagArr, function (o) {
								if (diagnosis && diagnosis.length > 0) {
									if (o.QrCodeID == qrCodeVal) {
										o.event.data = diagnosis[0].event.data;
										o.event.status = diagnosis[0].event.status;
										o.event.type = diagnosis[0].event.type;
                                        if(diagnosis[0].event.device) o.event.device = diagnosis[0].event.device;
									}
								}
							})
                            setDiagnosisData(diagArr);
                        }else {
                            setDiagnosisData(diagnosis);
                        }
                    }
					if(diagnosis && diagnosis.length && !diagnosis?.[0]?.event?.data?.length && diagnosis[0].QrCodeID) {
                        let code = diagnosis[0].QrCodeID, imeiVal = diagnosis[0].event.IMEI;
                        setQrCodeID(code);
                        setCurrentIMEI(imeiVal);
                        fetchProductDetails(code, imeiVal, diagnosis);
                    }
                    result.DiagnosisSource == 'WINDOWS_DIAGNOSIS' && result.data.driverUpdates && result.data.driverUpdates.length > 0 && setDriverUpdates(result.data.driverUpdates); 
                    if(result.DiagnosisSource === 'WINDOWS_DIAGNOSIS' && (imei || sno)) {
                        fetchUserFeedback();
                    }
				} else {
					Swal.fire({icon: 'error', text: localString(displayError(result))}); 
				}
			}	
		});
    }

    const checkDiagnosis = (diagObj) => {
		if (referenceNumber && diagObj && diagObj.QrCodeID) {
            let code = diagObj.QrCodeID, imeiVal = diagObj.event.IMEI;
            setQrCodeID(code);
            setCurrentIMEI(imeiVal);
			if (diagObj && diagObj.event && diagObj.event.data && diagObj.event.data.length == 0) {
                fetchProductDetails(code, imeiVal);
			}            
		}
	}

    const fetchUserFeedback = () => {
        let data = {};
        if(imei){
            data.ProductUniqueID = imei;
        } else if(sno){
            data.DownloadedDeviceUniqueKey = sno;
        }
        apiCall({
			url: 'fetchUserFeedback',
			data: data,
			callBack:(result)=>{
				if(result.success && result.data){
                    setUserFeedback(result.data);
                    setRemark(result.data[0].Sentiment);
				} else {
					Swal.fire({icon: 'error', text: localString(displayError(result))}); 
				}
			}	
		});
    }

    const getBIOS = () => {
        let bios;
        if(diagnosisHistory && diagnosisHistory[0] && diagnosisHistory[0].event && diagnosisHistory[0].event.data) {
          let systemSummaryEvent = find(diagnosisHistory[0].event.data, {name: 'System Summary'});
  
          if(systemSummaryEvent) {
            let biosData = find(systemSummaryEvent.componentMeta, {name: 'BIOS'});
            bios = biosData && biosData.value? biosData.value: null;
          }
        }
        return bios;
      };
  
    const getOSVersion = () => {
        let osVersion;
        if(diagnosisHistory && diagnosisHistory[0] && diagnosisHistory[0].event && diagnosisHistory[0].event.data) {
          let systemSummaryEvent = find(diagnosisHistory[0].event.data, {name: 'System Summary'});
  
          if(systemSummaryEvent) {
            let osVersionData = find(systemSummaryEvent.componentMeta, {name: 'OperatingSystem Version'});
            osVersion = osVersionData && osVersionData.value? osVersionData.value: null;
          }
        }
        return osVersion;
    };

    const checkLatestIssueCount = () => {
        let count = 0
        if(diagnosisHistory[0].event.data && diagnosisHistory[0].event.data.length > 0) {
            diagnosisHistory[0].event.data.forEach((diagnosis) => {
                if (diagnosis.status == 2) {
                    count++
                }
            })
        }
        setLatestIssueCount(count);
    };

    const processTestHistory = () => {
        let testHistory = {}, testHistoryCount = {};
        diagnosisHistory.forEach((obj) => {
            obj.event.data && obj.event.data.length > 0 && obj.event.data.forEach((test) => {
                test.date = obj.event.createdDate;
                if(!testHistory[test.name]) {
                    testHistory[test.name] = [test];
                    testHistoryCount[test.name] = 1;
                } else {
                    testHistory[test.name].push(test);
                    testHistoryCount[test.name] = testHistoryCount[test.name] + 1;
                }
            });
        });
        setTestHistory(testHistory);
        setTestHistoryCount(testHistoryCount);
    };

    const processHistoryObj = () => {
        if(diagnosisHistory[0].event.data && diagnosisHistory[0].event.data.length > 0) {
            setHistoryGroups(groupBy(diagnosisHistory[0].event.data, (obj) => {
                if (diagnosisGroupConfig[obj.name]) {
                    return diagnosisGroupConfig[obj.name];
                } else {
                    return 'Other'
                }
            }));
        }
    };

    return (
        <div>
            <div className="diagnosis-header clearfix">
                {consumerDetails &&
                <>
                    <div className="col-sm-6 col-xs-12">
                        <p className="product-label product-name">{localString('Customer Name')}: {consumerDetails.Consumer.Name}</p>
                        <p className="product-label">{localString('Customer Mobile')}: {consumerDetails.Consumer.MobileNo}</p>
                        <p className="product-label">{localString('Customer Email')}: {consumerDetails.Consumer.EmailID}</p>
                        <p className="product-label">{localString('Customer Alternate Mobile')}: {consumerDetails.Consumer.AlternateMobileNo}</p>
                        <p className="product-label">{localString('IMEI')}: {consumerDetails.ProductUniqueID || localString("Not Updated")}</p>
                        <p className="product-label">{localString('Serial No.')}: {(consumerDetails.DownloadedDeviceUniqueKey) || localString("Not Updated")}</p>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="text-right">
                            <Button type='button' classes="button-servify-solid" text={localString("Initiate App Diagnostics")} handleOnClick={() => setShowDiagnosisModal(true)}></Button>
                        </div>
                    </div>
                </>
                }
            </div>
            <div className="section-container">
                <div className="switch-tab-container">
                    {tabs.map((obj) => {return (
                        obj && <div className={"tab-content " + (activeTab === obj ? 'active' : '')} onClick={() => {setActiveTab(obj)}}>{obj}</div>
                    )})}
                </div>
                {activeTab === 'History' && 
                <div className="section-content clearfix">
                    {!referenceNumber && <div className="latest-diagnosis-section">
                        {!diagnosisHistory || diagnosisHistory.length === 0 && 
                            <div className="header-section">
                                <h3><strong>{localString('No Diagnosis Performed')}</strong></h3>
                            </div>
                        }
                        <div className="header-section">
                            {diagnosisHistory && diagnosisHistory[0] && <>  
                                <div className="margin-bottom-10 clearfix">
                                    <div className="col-sm-8">
                                    {localString("Diagnosed on") + ': ' + moment(diagnosisHistory[0].event.createdDate).format("DD MMM, YYYY hh:mm a") + '(Servify Diagnostic App)'}
                                    </div>
                                    <div className="col-sm-4 clearfix">
                                        <div className="pull-right report-icon" onClick={() => setActiveReportObj({...diagnosisHistory[0]})}>
                                            {localString("Diagnostic Report") + "  "}
                                            <span className="glyphicon glyphicon-print"></span>
                                        </div>
                                    </div>
                                </div>
                                {diagnosisHistory[0].DeviceSerialNumber && <div className="margin-bottom-10">{localString("Device Serial Number") + ': ' + diagnosisHistory[0].DeviceSerialNumber}</div>}
                                {diagnosisHistory[0].MSN && <div className="margin-bottom-10">{localString("MSN") + ': ' + diagnosisHistory[0].MSN}</div>}
                                {diagnosisHistory[0].SystemManufacturer && <div className="margin-bottom-10">{localString("System Manufacturer") + ': ' + diagnosisHistory[0].SystemManufacturer}</div>}
                                {diagnosisHistory[0].SystemModel && <div className="margin-bottom-10">{localString("System Model") + ': ' + diagnosisHistory[0].SystemModel}</div>}
                                {diagnosisHistory[0].SalesChannel && <div className="margin-bottom-10">{localString("Sales Channel") + ': ' + diagnosisHistory[0].SalesChannel}</div>}
                                {getBIOS() && <div className="margin-bottom-10">{localString("BIOS") + ': ' + getBIOS()}</div>}
                                {getOSVersion() && <div className="margin-bottom-10">{localString("OperatingSystem Version") + ': ' + getOSVersion()}</div>}
                                {diagnosisHistory[0].AppVersion && <div className="margin-bottom-10">{localString("Install App Version") + ': ' + diagnosisHistory[0].AppVersion}</div>}
                                {diagnosisHistory[0].event && diagnosisHistory[0].event.type && <div className="margin-bottom-10">{localString("Type") + ': ' + diagnosisHistory[0].event.type}</div>}
                                {userRemark && <div className="margin-bottom-10">{localString("User feedback") + ': ' + userRemark}</div>}
                                <div className="margin-bottom-10">{localString("Diagnosed") + ' ' + diagnosisHistory.length + ' ' + localString('times')}</div>
                                {latestIssueCount ? 
                                    // <div className="margin-bottom-10 issues">{localString(latestIssueCount === 1 ? 'Issue' : 'Issues') + ': ' + latestIssueCount}</div>
                                    <div className="margin-bottom-10 issues">{latestIssueCount + ' ' + localString(latestIssueCount === 1 ? 'Issue' : 'Issues') + ' ' +  localString("Found")}</div>
                                    : <h4><strong>{localString('No Issues Found')}</strong></h4>
                                }
                            </>}
                        </div>
                        <div className="history-section">
                            <div className="section-header">{localString("Diagnosis Result")}</div>
                            <div>
                            {historyGroups && Object.keys(historyGroups).length > 0 ?
                                Object.keys(historyGroups).map((group) => { return(
                                    <div className="group-container">
                                        <div className="group-header">{localString(group)}</div>
                                        <div className="group-content row">
                                            {historyGroups[group] && historyGroups[group].length > 0 &&
                                            historyGroups[group].map((singleObj) => {return( singleObj &&
                                                <ExpandItem 
                                                    name={singleObj.name}
                                                    showImage={singleObj.status?.toString()}
                                                    // showImage={!!(diagnosticStatus[singleObj.status] && diagnosticStatus[singleObj.status.toString()].image)}
                                                    imgUrl={diagnosticStatus[singleObj.status] && diagnosticStatus[singleObj.status.toString()].image} 
                                                    localString={localString} 
                                                    imgAlt={diagnosticStatus[singleObj.status] && diagnosticStatus[singleObj.status.toString()].displayText}
                                                    subHeaderText1={localString("Last Diagnosed ") + moment(diagnosisHistory[0].event.createdDate).format('DD MMM, YYYY hh:mm a')}
                                                    subHeaderText2={localString("Diagnosed " + testHistoryCount[singleObj.name] + " Times")}
                                                    showExpandArrow={singleObj.componentMeta && singleObj.componentMeta.length > 0}
                                                    setHistoryModalObj={setHistoryModalObj}
                                                    isSubHeaderClickable={true}
                                                >
                                                    {singleObj.componentMeta && singleObj.componentMeta.length > 0 && singleObj.componentMeta.map((obj) => {return (<>
                                                        {obj.value ? <div className="item-info">
                                                            <span className="info-label">{obj.name}</span><span> : </span>
                                                            {['Values', 'ValuesList','valueList','Data'].includes(obj.name) ? 
                                                                <span>
                                                                    <br/>
                                                                    { Object.keys(obj.value)?.length && Object.keys(obj.value).map((item) => (<span>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item} : {obj.value[item]} <br/>
                                                                    </span>))}
                                                                </span> 
                                                                : ''}
                                                            {!['Values', 'ValuesList','valueList','Data'].includes(obj.name) ? <span>{(obj.value).toString()}</span> : ''}
                                                        </div> : ''}
                                                    </>)})}
                                                </ExpandItem>
                                            )})}
                                        </div>
                                    </div>
                            )}) : <div>{localString('No results to display')}</div>}
                            </div>
                        </div>
                    </div>}
                    <div className="section-header">{localString("Diagnosis History")}</div>
                    {(diagnosisHistory?.length > 0) ? diagnosisHistory.map((obj, index) => {
                        return (((!referenceNumber && index !== 0) || (referenceNumber))  &&
                            <HistoryTab 
                                key={index}
                                diagnosisObj={obj} 
                                localString={localString} 
                                consumerDetails={consumerDetails} 
                                referenceNumber={referenceNumber} 
                                testHistoryCount={testHistoryCount} 
                                setActiveReportObj={setActiveReportObj}  
                                setHistoryModalObj={setHistoryModalObj}
                                checkDiagnosis={() => checkDiagnosis(obj)}
                            ></HistoryTab>
                        )
                    }) : <div>{localString('No diagnosis history found')}</div>}
                </div>}
                {activeTab === 'Reliability records' && reliabilityData.length > 0 && 
                <div className="section-content clearfix">
                    <ReliabilityRecords reliabilityData={reliabilityData} localString={localString}></ReliabilityRecords>
                </div>}
                {activeTab === 'Driver list' && Object.keys(driverRecords).length > 0 && 
                <div className="section-content clearfix">
                    <DriverList driverRecords={driverRecords} pageNo={pageNo} setCurrentPage={setPageNo} localString={localString}></DriverList>
                </div>}
                {activeTab === 'User feedback' && userFeedback.length > 0 && 
                <div className="section-content clearfix">
                    <UserFeedback userFeedback={userFeedback} localString={localString}></UserFeedback>
                </div>}
                {activeTab === 'Driver updates' && driverUpdates.length > 0 &&
                <div className="section-content clearfix">
                    <DriverUpdate driverUpdates={driverUpdates} localString={localString}></DriverUpdate>
                </div>}
            </div>
            <DiagnosisReport
                cpid={cpid}
                publicUrl={publicUrl}
                brandDetails={brandDetails}
                consumerDetails={consumerDetails}
                reportObj={activeReportObj}
                localString={localString}
                setOpenReport={setOpenReport}
                showModal={openReport}>
            </DiagnosisReport>
            <InitiateDiagnosis 
                showModal={showDiagnosisModal} 
                onCloseClick={() => setShowDiagnosisModal(false)}
                localString={localString}
                accessFn={accessFn}
                showQRSection={false}
                directDiagnosis={false}
                apiCall={apiCall}
                baseUrl={baseUrl}
                ConsumerID={consumerDetails?.ConsumerID || ''}
                ConsumerProductID={consumerDetails?.ConsumerProductID || ''}
                {...props}
            ></InitiateDiagnosis>
            <DiagnosisHistoryModal
                publicUrl={publicUrl}
                localString={localString}
                dataObj={testHistory[historyModalObj]}
                name={historyModalObj}
                setHistoryModalObj={setHistoryModalObj}
                setOpenHistoryModal={setOpenHistoryModal}
                showModal={openHistoryModal}>
            </DiagnosisHistoryModal>
            { showLoader && ReactDOM.createPortal(
                <Loader />,
                document.getElementById("loader-root")
            )}
        </div>
    )
}

export default DiagnosisResult;
