const accessFunction = (accessArray) => {
	const featureList = {
		DASHBOARD_REQUESTS: {
			FeatureId: 101,
			FeatureName: 'dashboard_requests'
		},
		DASHBOARD_CONSUMERS: {
			FeatureId: 102,
			FeatureName: 'dashbord_consumers'
		},
		REQUEST_LIST_VIEW: {
			FeatureId: 201,
			FeatureName: 'View Request List'
		},
		REQUEST_RAISE: {
			FeatureId: 202,
			FeatureName: 'Raise a request'
		},
		REQUEST_DETAILS: {
			FeatureId: 203,
			FeatureName: 'View single request details'
		},
		REQUEST_TRACK: {
			FeatureId: 204,
			FeatureName: 'View track and trace'
		},
		REQUEST_HISTORY: {
			FeatureId: 205,
			FeatureName: 'View request history'
		},
		REQUEST_CONSUMER_DETAILS: {
			FeatureId: 206,
			FeatureName: 'View consumer details on request details page'
		},
		REQUEST_ASSIGN: {
			FeatureId: 207,
			FeatureName: 'Assign Service Center'
		},
		REQUEST_REASSIGN: {
			FeatureId: 208,
			FeatureName: 'Reassign'
		},
		REQUEST_RESCHEDULE: {
			FeatureId: 209,
			FeatureName: 'Reschedule'
		},
		REQUEST_CANCEL: {
			FeatureId: 210,
			FeatureName: 'Cancel request'
		},
		REQUEST_GRAPHS: {
			FeatureId: 211,
			FeatureName: 'Request graphs'
		},
		ADD_NORMAL_CHARGES: {
			FeatureId: 213,
			FeatureName: 'Add Charges Except Insurance Amount'
		},
		CLAIM_REQUEST: {
			FeatureId: 214,
			FeatureName: 'Claim Request'
		},
		CLAIM_APPROVAL: {
			FeatureId: 215,
			FeatureName: 'Claim Approval'
		},
		REOPEN_SERVICE: {
			FeatureId: 217,
			FeatureName: 'Reopen Service'
		},
		REQUEST_EXCEL_DOWNLOAD: {
			FeatureId: 218,
			FeatureName: 'Download Request Excel'
		},
		FILTER_STATUS_REQUEST: {
			FeatureId: 219,
			FeatureName: 'Filter requests as per status'
		},
		TRACK_EXTERNAL_REQUESTS: {
			FeatureId: 220,
			FeatureName: 'Track External Requests'
		},
		CANCEL_REQUEST_ANYTIME: {
			FeatureId: 221,
			FeatureName: 'Cancel request at any status'
		},
		SHOW_DEVICE_SHIPPED_BUTTON: {
			FeatureId: 222,
			FeatureName: 'Show Device Shipped Button'
		},
		APPROVE_BER: {
			FeatureId: 223,
			FeatureName: 'Approve BER'
		},
		VALIDATE_BER_DOCS: {
			FeatureId: 224,
			FeatureName: 'Validate BER docs'
		},
		INITIATE_BER_PAYMENT: {
			FeatureId: 225,
			FeatureName: 'Initiate BER payment'
		},
		COMPLETE_BER_PAYMENT: {
			FeatureId: 226,
			FeatureName: 'Complete BER payment'
		},
		BER_CUSTOMER_ACTION: {
			FeatureId: 227,
			FeatureName: 'BER Customer Action'
		},
		RAISE_CLAIM_REQUEST: {
			FeatureId: 228,
			FeatureName: 'Raise Claim Request'
		},
		CONVERT_CLAIM_REQUEST: {
			FeatureId: 229,
			FeatureName: 'Convert Claim Request'
		},
		DROPOFF_ACCESS: {
			FeatureId: 230,
			FeatureName: 'Dropoff Acess'
		},
		RECIEVE_DEVICE_DROPOFF: {
			FeatureId: 231,
			FeatureName: 'Show Receive Device Button For Dropoff'
		},
		VIEW_QUEUE_ADMIN: {
			FeatureId: 232,
			FeatureName: 'Show View Queued Request Button For Dropoff - Admin'
		},
		VIEW_QUEUE_DROPOFF: {
			FeatureId: 233,
			FeatureName: 'Show View Queued Request Button For Dropoff - DC'
		},
		VIEW_QUEUE_SH: {
			FeatureId: 234,
			FeatureName: 'Show View Queued Request Button For Dropoff - SH'
		},
		RECYCLE_HUB_ACCESS: {
			FeatureId: 235,
			FeatureName: 'Recycle Hub Acess'
		},
		EDIT_GREEN_REWARDS: {
			FeatureId: 236,
			FeatureName: 'Edit Green Rewards'
		},
		RECYCLE_ADMIN_VIEW_ONLY: {
			FeatureId: 237,
			FeatureName: 'Recycle Requests View Only'
		},
		ACCEPT_RETURN_DEVICE: {
			FeatureId: 238,
			FeatureName: 'Accept and Return Device'
		},
		REPAIR_DEVICE: {
			FeatureId: 239,
			FeatureName: 'Repair device'
		},
		STORE_MANAGEMENT: {
			FeatureId: 240,
			FeatureName: 'Store management'
		},
		REASSIGN_JOBS: {
			FeatureId: 241,
			FeatureName: 'Reassign jobs'
		},
		RECEIVE_DEVICE_ACCESSORIES: {
			FeatureId: 242,
			FeatureName: 'Receive Device and Accessories'
		},
		OVERRIDE_ESTIMATION_APPROVAL: {
			FeatureId: 243,
			FeatureName: 'Override estimation approval'
		},
		COMMUNICATION_HISTORY: {
			FeatureId: 244,
			FeatureName: 'Communication History'
		},
		EDIT_DEVICE_DETAILS: {
			FeatureId: 245,
			FeatureName: 'Edit device details'
		},
		QC_DEVICE: {
			FeatureId: 246,
			FeatureName: 'Device QC'
		},
		WORKSHOP_FLOW: {
			FeatureId: 247,
			FeatureName: 'Workshop flow'
		},
		EXTERNAL_REF: {
			FeatureId: 248,
			FeatureName: 'External Reference ID'
		},
		GSX_FLOW: {
			FeatureId: 249,
			FeatureName: 'GSX Flow'
		},
		READ_ONLY: {
			FeatureId: 250,
			FeatureName: 'Read only access'
		},
		TOGGLE_WARRANTY: {
			FeatureId: 252,
			FeatureName: 'Toggle Warranty for Request'
		},
		CLAIM_WORKSHOP: {
			FeatureId: 253,
			FeatureName: 'Claim access Workshop'
		},
		BER_WORKSHOP: {
			FeatureId: 254,
			FeatureName: 'BER access Workshop'
		},
		SUPERADMIN_DOA: {
			FeatureId: 255,
			FeatureName: 'DOA access Workshop'
		},
		CLOSED_DATA_EXCEL_DOWNLOAD: {
			FeatureId: 256,
			FeatureName: 'Download Closed Report'
		},
		EXCEPTIONAL_CLAIM: {
			FeatureId: 257,
			FeatureName: 'Exceptional Claim Override'
		},
		REFUND_PROCESS: {
			FeatureId: 260,
			FeatureName: "Access to refund process"
		},
		CLUSTER_MANAGER: {
			FeatureId: 261,
			FeatureName: "Role Cluster Manager"
		},
		TECHNICIAN_SUPERVISOR: {
			FeatureId: 262,
			FeatureName: "Role Store end for Technician"
		},
		STORE_REFUND_PROCESS: {
			FeatureId: 263,
			FeatureName: "Store for Refund Process"
		},
		REPLACEMENT_PROCESS: {
			FeatureId: 264,
			FeatureName: "Replacement Process"
		},
		ENGINEER_FILTERS: {
			FeatureId: 265,
			FeatureName: 'Show Engineer Filters'
		},
		AVAILABILITY_FILTER: {
			FeatureId: 266,
			FeatureName: 'Availability Filter'
		},
		SKILLS_FILTER: {
			FeatureId: 267,
			FeatureName: 'Skills Filter'
		},
		LOCATION_FILTER: {
			FeatureId: 268,
			FeatureName: 'Location Filter'
		},
		PRODUCTIVITY_FILTER: {
			FeatureId: 269,
			FeatureName: 'Productivity Filter'
		},
		SHOW_ESTIMATION_DOCUMENT: {
			FeatureId: 270,
			FeatureName: 'Show Estimation Invoice'
		},
		TRANSFER_REQUEST: {
			FeatureId: 275,
			FeatureName: 'Transfer Request to Other Location'
		},
		CREATE_LOGISTICS_BULK_REQUEST: {
			FeatureId: 276,
			FeatureName: 'Create Bulk Logistics Request'
		},
		SERVICE_LOCATION_ASSIGN: {
			FeatureId: 277,
			FeatureName: "Assign only service location"
		},
		RAISE_CLAIM_ADMIN_REQUEST: {
			FeatureId: 279,
			FeatureName: 'Raise Claim Request from single request page'
		},
		EDIT_REQUEST_DOCUMENTS: {
			FeatureId: 280,
			FeatureName: 'Allows Editing of Request Documents Anytime'
		},
		CREATE_SERVICE_REQUEST: {
			FeatureId: 281,
			FeatureName: "Request Form"
		},
		APPROVE_DOA: {
			FeatureId: 282,
			FeatureName: 'Approve DOA'
		},
		EXCEPTION_REQUEST: {
			FeatureId: 283,
			FeatureName: 'Initiate an exception'
		},
		APPROVE_EXCEPTION_REQUEST: {
			FeatureId: 284,
			FeatureName: 'Approve an exception request'
		},
		DISPATCH_DEVICE_TO_RC: {
			FeatureId: 285,
			FeatureName: 'Dispatch Device to Rc'
		},
		SHOW_ESTIMATE: {
			FeatureId: 290,
			FeatureName: 'Show estimate for single user flow'
		},
		OVERRIDE_ESTIMATION_BUTTON: {
			FeatureId: 291,
			FeatureName: 'Approve estimation button'
		},
		EXTERNAL_REFERENCE_FILTER: {
			FeatureId: 292,
			FeatureName: 'External Reference ID'
		},
		SERVICE_REQUEST_CLUSTER: {
			FeatureId: 293,
			FeatureName: "Service request for cluster for analysis"
		},
		COMPLETE_ANALYSIS: {
			FeatureId: 294,
			FeatureName: "Complete analysis"
		},
		VENDOR_REFUND: {
			FeatureId: 295,
			FeatureName: "Vendor role for approving refund"
		},
		CANCEL_CLAIM_ON_APPROVAL: {
			FeatureId: 297,
			FeatureName: 'Cancel claims immediately after approval'
		},
		EDIT_REQUEST_ESCALATION: {
			FeatureId: 298,
			FeatureName: 'edit request escalation'
		},
		CANCELLATION_APPROVAL: {
			FeatureId: 299,
			FeatureName: "Approve/Reject Cancellation request raised by technician"
		},
		CONSUMER_LIST_VIEW: {
			FeatureId: 301,
			FeatureName: 'View Consumer list'
		},
		CONSUMER_ADD: {
			FeatureId: 302,
			FeatureName: 'Add new consumer'
		},
		CONSUMER_DETAILS: {
			FeatureId: 303,
			FeatureName: 'Consumer Details'
		},
		CONSUMER_REGISTERED_PRODUCTS: {
			FeatureId: 304,
			FeatureName: 'Consumer registered products'
		},
		CONSUMER_UNREGISTERED_PRODUCTS: {
			FeatureId: 305,
			FeatureName: 'Consumer unregistered products'
		},
		CONSUMER_DELETED_PRODUCTS: { /* Doesnt exist */
			FeatureId: 306,
			FeatureName: 'Consumer deleted products'
		},
		CONSUMER_REQUESTS: {
			FeatureId: 307,
			FeatureName: 'Consumer Requests'
		},
		EDIT_CONSUMER_DETAILS: {
			FeatureId: 308,
			FeatureName: 'Edit Consumer Details'
		},
		FIND_CONSUMER: {
			FeatureId: 309,
			FeatureName: 'Find Consumer By Mobile'
		},
		CONSUMER_ADDRESS_DETAILS: {
			FeatureId: 310,
			FeatureName: 'Consumer Address Details'
		},
		SEARCH_CONSUMER_BY_VOUCHER: {
			FeatureId: 311,
			FeatureName: 'Search consumers by voucher code'
		},
		HIDE_CONSUMER_CALL_LOGS: {
			FeatureId: 312,
			FeatureName: 'Consumer Call Logs'
		},
		CONSUMER_PLAN_DETAILS: {
			FeatureId: 313,
			FeatureName: 'Consumer Plan Details'
		},
		BRAND_DESCRIPTION: {
			FeatureId: 401,
			FeatureName: 'Exclusive Brand Description'
		},
		BRAND_TAB: {
			FeatureId: 402,
			FeatureName: 'Exclusive Brand Tab'
		},
		NOTIFICATION_FILTER: {
			FeatureId: 501,
			FeatureName: 'Filter Notifications'
		},
		NOTIFICATION_SEND_PUSH: {
			FeatureId: 502,
			FeatureName: 'Send Push Notifications'
		},
		NOTIFICATION_SEND_EMAIL: {
			FeatureId: 503,
			FeatureName: 'Send Email Notifications'
		},
		NOTIFICATION_SEND_SMS: {
			FeatureId: 504,
			FeatureName: 'Send SMS Notifications'
		},
		COUPON_CREATE: {
			FeatureId: 601,
			FeatureName: 'Create Coupon'
		},
		COUPON_VIEW: {
			FeatureId: 602,
			FeatureName: 'View Coupon'
		},
		USER_ROLE_DEFINITION: {
			FeatureId: 701,
			FeatureName: 'New Role Definition'
		},
		USER_MANAGEMENT: {
			FeatureId: 702,
			FeatureName: 'User Management'
		},
		APPROVE_BRAND_CONTENT: {
			FeatureId: 801,
			FeatureName: 'Approve Exclusive Brand Content'
		},
		APPROVE_NOTIFICATION: {
			FeatureId: 802,
			FeatureName: 'Approve Notifications'
		},
		DIAGNOSIS: {
			FeatureId: 901,
			FeatureName: 'Diagnosis'
		},
		ADD_DIAGNOSIS_CUSTOMER: {
			FeatureId: 902,
			FeatureName: 'Add Diagnosis Customer'
		},
		DOA_FOR_ENQUIRY: {
			FeatureId: 903,
			FeatureName: 'DOA Replacement flow for Enquiry Request'
		},
		SEARCH_BY_MOBILE: {
			FeatureId: 904,
			FeatureName: 'Search diagnosis result by mobile number'
		},
		SEARCH_BY_IMEI_SERIAL: {
			FeatureId: 905,
			FeatureName: 'Search diagnosis result by IMEI or Serial number'
		},
		SEARCH_BY_JOBSHEET: {
			FeatureId: 906,
			FeatureName: 'Search diagnosis result by jobsheet'
		},
		DIRECT_DIAGNOSIS: {
			FeatureId: 907,
			FeatureName: 'Initiate Diagnosis with serial number and IMEI only'
		},
		UPDATE_JOB_STATUS: {
			FeatureId: 908,
			FeatureName: "Update Job Status"
		},
		SEARCH_BY_REFID: {
			FeatureId: 909,
			FeatureName: "Search diagnosis result by reference ID"
		},
		PHYSICAL_INSPECTION: {
			FeatureId: 910,
			FeatureName: "Perform Physical Inspection of a Device"
		},
		CAPTURE_DEVICE_IMAGES: {
			FeatureId: 911,
			FeatureName: "Capture Device Images and Upload"
		},
		VIEW_SUPERVISOR: {
			FeatureId: 1101,
			FeatureName: 'View Supervisor'
		},
		EDIT_SUPERVISOR: {
			FeatureId: 1102,
			FeatureName: 'Edit Supervisor'
		},
		CREATE_VISIT_ACCESS: {
			FeatureId: 2001,
			FeatureName: "Create Visit Access"
		},
		HIDE_NORMAL_REPAIR: {
			FeatureId: 2002,
			FeatureName: 'Hide normal repair option'
		},
		TASK_MANAGEMENT: {
			FeatureId: 2004,
			FeatureName: 'Task Management'
		},
		FORCE_SERVICE_COMPLETE: {
			FeatureId: 2005,
			FeatureName: 'Service complete over Read only'
		},
		CREATE_TASK_ACCESS: {
			FeatureId: 2006,
			FeatureName: "Create task access"
		},
		BSM_ACCESS: {
			FeatureId: 2007,
			FeatureName: "For BSM Role and Approval"
		},
		SEND_RETAILER_ANNOUNCEMENT: {
			FeatureId: 2202,
			FeatureName: 'Send Retailer announcement'
		},
		APPROVE_PLAN: {
			FeatureId: 2502,
			FeatureName: 'Approve Plan'
		},
		VIEW_PLAN_CONSUMER_LIST: {
			FeatureId: 2503,
			FeatureName: 'View Plan Consumer List'
		},
		INITIATE_REFUND: {
			FeatureId: 2504,
			FeatureName: 'Initiate Refund'
		},
		SHOW_VIEW_BUTTON: {
			FeatureId: 2506,
			FeatureName: 'Show View Button and Extra Filters On Plan Management Consumer List'
		},
		PLAN_CANCEL: {
			FeatureId: 2507,
			FeatureName: 'Plan cancellation'
		},
		SEARCH_BY_VOUCHER: {
			FeatureId: 2508,
			FeatureName: 'Search the plans with voucher code.'
		},
		SHOW_PLAN_BENEFITS: {
			FeatureId: 2509,
			FeatureName: 'Show Plan Benefits'
		},
		HIDE_APR_MATRIX: {
			FeatureId: 2510,
			FeatureName: 'Hide APR Matrix'
		},
		TWO_DEVICE_DIAGNOSIS: {
			FeatureId: 2511,
			FeatureName: 'Manual Device Diagnosis'
		},
		REACTIVATION_UNDER_WARRANTY: {
			FeatureId: 2512,
			FeatureName: 'Sold Plan Reactivation Under Review Contract'
		},
		BUY_PLANS: {
			FeatureId: 2801,
			FeatureName: 'Buy Plans'
		},
		RECEIVE_ONLINE_PAYMENT: {
			FeatureId: 2802,
			FeatureName: 'Receive Online Payment'
		},
		UPLOAD_PRE_PURCHASE_DOCUMENTS: {
			FeatureId: 2803,
			FeatureName: 'Upload Pre Purchase Documents'
		},
		DOWNLOAD_APR_PLANS: {
			FeatureId: 2806,
			FeatureName: 'Download APR Plans'
		},
		BULK_SALES_PLAN_UPLOAD: {
			FeatureId: 2807,
			FeatureName: 'Upload bulk sold plans'
		},
		HIDE_PLAN_SALES_DISCOUNT: {
			FeatureId: 2808,
			FeatureName: "Hide the discount option in plan sales confirmation screen"
		},
		EXCEPTIONAL_SALES: {
			FeatureId: 2809,
			FeatureName: "Show exceptional/back order sales option"
		},
		GST_PLAN_SALES: {
			FeatureId: 2810,
			FeatureName: "Show GST option in plan sales"
		},
		RAZORPAY_CALLBACK_URL: {
			FeatureId: 2811,
			FeatureName: "Use Razorpay callback url implementation in plan sales"
		},
		PLAN_SALES_BULK_UPLOAD: {
			FeatureId: 2812,
			FeatureName: "Plan sales bulk upload"
		},
		ENABLE_RENEWAL: {
			FeatureId: 2814,
			FeatureName: "Give access to renewal plans list"
		},
		HIDE_MARGIN: {
			FeatureId: 2816,
			FeatureName: "Hide Margin"
		},
		PLAN_SALE_ADMIN_USER: {
			FeatureId: 2817,
			FeatureName: "Plan sale admin user"
		},
		CHECK_ELIGIBILITY_FIRST: {
			FeatureId: 2818,
			FeatureName: "Checking plan eligibility before purchase"
		},
		RETAILER_ATTENDANCE_REPORT: {
			FeatureId: 3202,
			FeatureName: 'Retailer Attendance Report'
		},
		RETAILER_SALES_REPORT: {
			FeatureId: 3203,
			FeatureName: 'Retailer Sales Report'
		},
		COMPETITION_REPORT: {
			FeatureId: 3204,
			FeatureName: 'Competition Reports'
		},
		DISPLAY_SHARE: {
			FeatureId: 3205,
			FeatureName: 'Display Share Reports'
		},
		RETAILER_TL_ATTENDANCE: {
			FeatureId: 3206,
			FeatureName: 'Retailer TL Attendance'
		},
		RETAILER_TL_DISPLAY_SHARE: {
			FeatureId: 3207,
			FeatureName: 'Retailer TL Display Share'
		},
		CANCEL_PLAN: {
			FeatureId: 3301,
			FeatureName: 'Cancel Protection Plans'
		},
		VIEW_LOGISTIC_REPORT: {
			FeatureId: 3401,
			FeatureName: 'View Logistic Report'
		},
		CREATE_LOGISTIC_REQUEST: {
			FeatureId: 3402,
			FeatureName: 'Create Logistic Report'
		},
		FILTER_LOGISTIC_REQUEST: {
			FeatureId: 3403,
			FeatureName: 'Filter Logistic Report'
		},
		ORGANISATION_FILTER_LOGISTIC_REQUEST: {
			FeatureId: 3404,
			FeatureName: 'Organisaton Filter Logistic Report'
		},
		ORGANIZATION_CANCEL_REQUEST: {
			FeatureId: 3405,
			FeatureName: 'Cancel Organisation Logistics Request'
		},
		UPLOAD_PICKUP: {
			FeatureId: 3406,
			FeatureName: 'Upload Status Report Logistics'
		},
		CONVERGE_LOGISTICS_REQUEST: {
			FeatureId: 3407,
			FeatureName: 'Converge logistics request to service request'
		},
		// VIEW_PLAN_CONSUMER_LIST: {
		// 	FeatureId: 4001,
		// 	FeatureName: 'View Plan Consumer List'
		// },
		UPLOAD_SALES: {
			FeatureId: 5002,
			FeatureName: 'Upload Excel'
		},
		ENGINEER_HANDSET_RESET: {
			FeatureId: 6101,
			FeatureName: "Engineer Handset Reset"
		},
			ENGINEER_TRACKING: {
			FeatureId: 6102,
			FeatureName: "Engineer Tracking"
		},
		ENGINEER_ROSTER_MANAGEMENT: {
			FeatureId: 6103,
			FeatureName: 'Engineer Roster Management'
		},
		ENGINEER_NOTIFICATION: {
			FeatureId: 6104,
			FeatureName: 'Engineer Notification'
		},
		ENGINEER_FEEDBACK: {
			FeatureId: 6105,
			FeatureName: 'Engineer Feedback'
		},
		ENGINEER_LOGIN_REPORT: {
			FeatureId: 6201,
			FeatureName: 'Engineer Login Report'
		},
		SERVICELOCATION_ENGINEER_MAPPING: {
			FeatureId: 6401,
			FeatureName: 'Servicelocation to Engineer mapping'
		},
		PINCODE_MAPPING: {
			FeatureId: 6402,
			FeatureName: 'Pincode to Engineer Mapping'
		},
		REFURBISHMENT_MANAGER: {
			FeatureId: 7000,
			FeatureName: 'REFURBISHMENT MANAGER'
		},
		RESELLER: {
			FeatureId: 7001,
			FeatureName: 'RESELLER' // Trade-In
		},
		ABB: {
			FeatureId: 7002,
			FeatureName: 'Assured Buy Back'
		},
		REQUOTE: {
			FeatureId: 7003,
			FeatureName: 'Requote'
		},
		HOME_PICKUP: {
			FeatureId: 7004,
			FeatureName: 'Home Pickup'
		},
		REFURBISH_DEVICE: {
			FeatureId: 7100,
			FeatureName: 'REFURBISH DEVICE'
		},
		SERVICE_REQUEST_DETAILS: {
			FeatureId: 7101,
			FeatureName: "Service request details"
		},
		CSR_LOG_REPORT: {
			FeatureId: 7201,
			FeatureName: 'Download CSR log report'
		},
		OLCSM_FILTER: {
			FeatureId: 7201,
			FeatureName: "OLCSM name filter visibility"
		},
		SCHEDULE_REPORTS: {
			FeatureId: 7203,
			FeatureName: 'Schedule Reports'
		},
		UPSERT_MOP: {
			FeatureId: 7500,
			FeatureName: 'UPSERT MOP'
		},
		SHOW_UNIFIED_PORTAL_LINK: {
			FeatureId: 100001,
			FeatureName: "Show Unified Portal Link in sidebar"
		},
		HIDE_CANCEL_SERVICE_BUTTON: {
			FeatureId: 200001,
			FeatureName: 'Hide Cancel Service button on view service request page'
		},
		CANCEL_LOGISTICS: {
			FeatureId: 200002,
			FeatureName: 'Cancel Logistics Request'
		},
		HIDE_REQUEST_DOCUMENTS: {
			FeatureId: 200003,
			FeatureName: 'Show request documents on request page'
		},
		CANCEL_REVERT_INVENTORY: {
			FeatureId: 200004,
			FeatureName: 'Cancel repair and put back the consumed/received parts back in the inventory'
		},
		SHOW_PARTS_LINK: {
			FeatureId: 200005,
			FeatureName: 'Show Parts portal link in sidebar'
		},
		SHOW_ONBOARDING_LINK: {
			FeatureId: 200006,
			FeatureName: "Show Partner onboarding link in sidebar"
		},
		HIDE_RESCHEDULE_BUTTON: {
			FeatureId: 200007,
			FeatureName: 'Hide reschedule button on view service request page'
		},
		HIDE_GENERATE_INVOICE_BUTTON: {
			FeatureId: 200008,
			FeatureName: 'Hide generate invoice button on view service request page'
		},
		HIDE_PARTS_PENDING_BUTTON: {
			FeatureId: 200009,
			FeatureName: 'Hide parts pending button on view service request page'
		},
		CANCEL_REVERT_INVENTORY: {
			FeatureId: 200004,
			FeatureName: "Cancel repair and put back the consumed/received parts back in the inventory"
		},
		NOT_ALLOWED_TO_EDIT_SALES_WARRANTY_DETAILS: {
			FeatureId: 200010,
			FeatureName: 'Edit sale warranty Details'
		},
		RAISE_REPLACEMENT_REQUEST: {
			FeatureId: 200011,
			FeatureName: "Show carry in type"
		},
		BULK_SERVICE_REQUEST: {
			FeatureId: 200012,
			FeatureName: 'Create Bulk Service request'
		},
		INITIATE_CANCEL_REQUEST_ANYTIME: {
			FeatureId: 200013,
			FeatureName: 'Initiate Service cancel for approval process'
		},
		RESERVATION: {
			FeatureId: 200014,
			FeatureName: "Reservation of slots"
		},
		REQUEST_APPROVAL: {
			FeatureId: 200015,
			FeatureName: "Approval Engineer"
		},
		SHOW_UPDATE_GSX_AUTH_TOKEN : {
			FeatureId: 200016,
			FeatureName: "Show Update GSX Authorization Token"
		},
		PROVISIONAL_ESTIMATE: {
			FeatureId: 200017,
			FeatureName: 'Show provisional estimate'
		},
		NON_HARDWARE_CLOSURE: {
			FeatureId: 200018,
			FeatureName: 'Non Hardware closure'
		},
		BER_REPLACEMENT_ACTION: {
			FeatureId: 200019,
			FeatureName: 'Ber Replacement Action'
		},
		TESTING_AUTOMATION_FOR_REQUESTS: {
			FeatureId: 200020,
			FeatureName: "Right to skip certain steps while automation testing"
		},
		BER_REPLACEMENT_INTIMATE_CUSTOMER: {
			FeatureId: 200021,
			FeatureName: "Ber Replacement intimate customer"
		},
		DOWNLOAD_LOGISTICS_LABEL: {
			FeatureId: 200022,
			FeatureName: "Download logistics label"
		},
		ADD_CONSUMER_PRODUCT: {
			FeatureId: 200023,
			FeatureName: "Add consumer product"
		},
		HIDE_REQUEST_DETAILS_REMARKS: {
			FeatureId: 200024,
			FeatureName: "Hide request details and remarks"
		},
		RESTRICTED_FILTER_REQUEST_ACCESS: {
			FeatureId: 200025,
			FeatureName: "Restricted filter requets access"
		},
		ADD_PRODUCT_IN_CONSUMER_PAGE: {
			FeatureId: 200026,
			FeatureName: "Add product in consumer page"
		},
		SEND_PAYMENT_LINK: {
			FeatureId: 200027,
			FeatureName: "Send payment link"
		},
		PICKUP_LOGISTICS_CONTACT_DETAILS_CAPTURE: {
			FeatureId: 200028,
			FeatureName: "Pickup logistics contact details capture"
		},
		HIDE_ADDRESS_DETAILS_TAB: {
			FeatureId: 200029,
			FeatureName: "Hide address details tab"
		},
		HIDE_BILLING_DETAILS_TAB: {
			FeatureId: 200030,
			FeatureName: "Hide billing details Tab"
		},
		HIDE_COMMUNICATION_HISTORY_TAB: {
			FeatureId: 200031,
			FeatureName: "Hide communication history tab"
		},
		HIDE_DEVICE_DETAILS_INFO: {
			FeatureId: 200032,
			FeatureName: "Hide device details info"
		},
		HIDE_LOGISTICS_DETAILS_TAB: {
			FeatureId: 200033,
			FeatureName: "Hide logistics details Tab"
		},
		HIDE_SERVICE_CENTER_NAME: {
			FeatureId: 200034,
			FeatureName: "Hide service center name"
		},
		EDIT_ALTERNATE_PARTS: {
			FeatureId: 200035,
			FeatureName: "Show issue Alternate part"
		},
		UPDATE_LOGISTICS_REQUEST: {
			FeatureId: 200036,
			FeatureName: "Show Update Logistics Request button"
		},
		GSX_SUITS_DIAGNOSIS:{
			FeatureId: 200037,
			FeatureName: "Run Stand Alone Diagnosis"
		},
		VIEW_ADJUDICATION_CHECKLIST:{
			FeatureId: 200038,
			FeatureName: "View adjudication checklist"
		},
		EDIT_ADJUDICATION_CHECKLIST:{
			FeatureId: 200039,
			FeatureName: "Edit adjudication checklist"
		},
		LOANER_FLOW:{
			FeatureId: 200044,
			FeatureName: "Loaner flow"
		},
		CONVERT_TO_REIMBURSEMENT: {
			FeatureId: 200048,
			FeatureName: "Convert to reimbursement"
		},
		PACKAGE_DETAILS: {
			FeatureId: 200049,
			FeatureName: "Allow Adding Package Shipping Details"
		},
		ADVANCE_PAY:{
			FeatureId: 8101,
			FeatureName: "Advance Pay"
		},
		PARTNER_USER_MANAGEMENT:{
			FeatureId: 8301,
			FeatureName: "Partner User Management"
		},
		CREATE_USER:{
			FeatureId: 8302,
			FeatureName: "Create new user"
		},
		UPDATE_USER:{
			FeatureId: 8303,
			FeatureName: "Update user data"
		},
		SELF_USER_UPDATE:{
			FeatureId: 8304,
			FeatureName: "Update self user data"
		},
		UPDATE_USER_ACCESS:{
			FeatureId: 8305,
			FeatureName: "Can Update user access"
		},
		ONBOARD_PROMOTER_USER:{
			FeatureId: 8306,
			FeatureName: "Onboard Promoter User"
		},
		OFFBOARD_PROMOTER_USER:{
			FeatureId: 8307,
			FeatureName: "Off board Promoter User"
		},
		DEACTIVATE_USERS:{
			FeatureId: 8308,
			FeatureName: "Deactivate any users"
		},
		DEACTIVATE_USERS_EXCEPT_PROMOTER:{
			FeatureId: 8309,
			FeatureName: "Deactivate any users other than promoter"
		},
		ASSIGN_USER_TAG:{
			FeatureId: 8310,
			FeatureName: "Assign tag/role to a user"
		},
		BOS_USER:{
			FeatureId: 8311,
			FeatureName: "Create user on partners behalf"
		},
		CAN_GIVE_USER_ACCESS: {
			FeatureId: 8312,
			FeatureName: "Can give user management access"
		},
		UPDATE_USER_PAN_NUMBER: {
			FeatureId: 8313,
			FeatureName: "Can Update user PAN number"
		},
		PAYTM_WALLET: {
			FeatureId: 8401,
			FeatureName: "Allow Linking Mobile Number to Paytm Wallet"
		},
		PACKAGE_DETAILS: {
			FeatureId: 200049,
			FeatureName: "Allow Adding Package Shipping Details"
		},
		PACKAGE_DETAILS_FILTER: {
			FeatureId: 200050,
			FeatureName: "Allow FILTER Package  Details"
		},
		SHOW_INTEGRATION_LOGS: {
			FeatureId: 200057,
			FeatureName: "Create Refurbishment Request"
		},
		UPDATE_LOGISTICS_PSL: {
			FeatureId: 200058,
			FeatureName: "Update Logistics from Different PSL"
		},
		DEVICE_NOT_ELIGIBLE_FOR_REPAIR: {
			FeatureId: 200045,
			FeatureName: "Device not eligible for repair"
		},
		FLAG_FRAUD_REQUEST: {
			FeatureId: 200056,
			FeatureName: "Flag Fraud Request"
		},
		SELECT_DECISION_OUTCOME_MANUAL:{
			FeatureId: 200040,
			FeatureName: "Select decision outcome manual"
		},
		UPDATE_PSL_OF_REQUEST: {
			FeatureId: 200062,
			FeatureName: "Update PSL of a Request"
		},
		VIEW_REPLACEMENT_OPTIONS:{
			FeatureId: 200042,
			FeatureName: "View Replacement options"
		},
		SELECT_REPLACEMENT_OPTION:{
			FeatureId: 200043,
			FeatureName: "Select Replacement option"
		},
		ALLOCATE_DEVICE: {
			FeatureId: 200059,
			FeatureName: "Allocate Device in Advance Exchange Requests"
		},
		WAITING_ON_CUSTOMER_BUTTON: {
			FeatureId: 200067,
			FeatureName: "waiting on customer button"
		},
		ALLOW_ADDITIONAL_REASON: {
			FeatureId: 200063,
			FeatureName: "Allow Users to add Internal Remarks"
		},
		ALLOW_REFUND: {
			FeatureId: 200064,
			FeatureName: "Allow refund option"
		},
		DEVICE_NOT_REPAIRABLE: {
			FeatureId: 200046,
			FeatureName: "Device not repairable"
		},
		NO_FAULT_FOUND: {
			FeatureId: 200047,
			FeatureName: "No fault found"
		},
		ADD_REPLACEMENT_OPTIONS:{
			FeatureId: 200041,
			FeatureName: "Add Replacement options"
		},
		CREATE_EXCHANGE_ORDER: {
			FeatureId: 200061,
			FeatureName: "Create Exchange Order"
		},
		REPLACEMENT_UNAVAILABLE: {
			FeatureId: 200060,
			FeatureName: "Mark Unavailability of Replacement"
		},
		CHARGE_SECURITY_DEPOSIT: {
			FeatureId: 200052,
			FeatureName: "Charge security deposit"
		},
		RELEASE_SECURITY_DEPOSIT: {
			FeatureId: 200053,
			FeatureName: "Release security deposit"
		},
		CREATE_REFURB_REQUEST: {
			FeatureId: 200055,
			FeatureName: "Create Refurbishment Request"
		},
		HIDE_PAYMENT_RECEIVED: {
			FeatureId: 200069,
			FeatureName: "hide payment received"
		},
		HIDE_REPAIR_COMPLETE: {
			FeatureId: 200070,
			FeatureName: "hide repair complete"
		},
		BULK_UPLOAD_DOWNLOAD:{
			FeatureId: 8501,
			FeatureName: "CSV file uploading and downloading functionality"
		},
		ADD_PART_POST_APPROVAL: {
			FeatureId: 200074,
			FeatureName: "add part post aaproval"
    	},
		CASE_MANAGER: {
			FeatureId: 8601,
			FeatureName: "Case Manager page access"
		},
		RULE_MANAGER: {
			FeatureId: 8701,
			FeatureName: "Rule Manager page access"
		},
		CLAIM_HISTORY_TAB: {
			FeatureId: 200068,
			FeatureName: "claim history tab"
		},
		ALLOW_ADDING_DISCOUNT: {
			FeatureId: 200071,
			FeatureName: "allow discount addition"
		},
		REFRESH_INTEGRATION_LOG: {
			FeatureId: 200076,
			FeatureName: "Refresh Integration logs"
		},
		REASSIGN_ASC: {
			FeatureId: 200077,
			FeatureName: "show reassign service center button"
		},
		HIDE_SERVICE_COMPLETE: {
			FeatureId: 200066,
			FeatureName: "Partner status update"
		},
		PARTNER_STATUS_UPDATE: {
			FeatureId: 200065,
			FeatureName: "Partner status update"
		},
		EDIT_EXTERNAL_ORDER_NUMBER: {
			FeatureId: 200054,
			FeatureName: "Release security deposit"
		},
		TRADE_IN_REQUEST_FLOW: {
			FeatureId: 200051,
			FeatureName: "Trade In Short Flow on Request page"
		},
		MULTI_DEVICE_PLANS: {
			FeatureId: 2815,
			FeatureName: "Give access to multi device plans list"
		},
		DROPOFF_CUSTOMER_CARE_EXECUTIVE: {
			FeatureId: 200078,
			FeatureName: "drop off cutomer care executive"
		},
		SHOW_CASE_SCORE_ON_REQUEST: {
			FeatureId: 200079,
			FeatureName: "show case score tab on request page"
		},
		PROOF_ADJUDICATOR_VIEW: {
			FeatureId: 200080,
			FeatureName: "show accept and reject button for requested proof"
		},
		DISPLAY_PAYMENT_DETAILS_TAB: {
			FeatureId: 200081,
			FeatureName: "Display Payment tab of view request page"
		},
		FETCH_REMARKS_DETAILS_TAB: {
			FeatureId: 200082,
			FeatureName: "fetch all remaks details on remarks tab"
		},
		ADD_REMARKS: {
			FeatureId: 200083,
			FeatureName: "add remark on remarks details tab"
		},
		SHOW_PROOF_TAB: {
			FeatureId: 200084,
			FeatureName: "show proof tab"
		},
		SHOW_CALL_RECORDING: {
			FeatureId: 200085,
			FeatureName: "show call recording in communication history"
		},
	};
    const accessFn = (key) =>{
        if (!featureList[key]) {
            return false; // Return false if there is no feature defined.
        }
		accessArray = JSON.parse("["+accessArray+"]");
        if (accessArray.indexOf(featureList[key].FeatureId) > -1) {
            return true;
        } else {
            return false;
        }
    }
    return accessFn;
};
export default accessFunction;
