import React, { useState } from 'react';
import {Modal,Button,Input} from 'sfy-react';
import './PlanTypeButtonModal.scss';
import Swal from "sweetalert2";
import { displayError } from '../../../../../Utility/Utils';
// import { returnTimezone,displayError } from '../../../../../Utility/Utils';

const PlanTypeButtonInput=(props)=>{
	//removed hiddenContent, closeModal, setShowModal, ConsumerServiceRequestID,showModal
	const {apiCall,activeCoverages,setLoader,localString, getRequestById, 
		   getInsuranceDetails, consumerServiceRequest} = props;

    const [enablePlanType, setEnablePlanType] = useState(false);
    const [soldPlanCoverageID, setSoldPlanCoverageID] = useState(false);


    const selectedPlanType = function(i){
	setEnablePlanType(false);
	setSoldPlanCoverageID(i.SoldPlanCoverageID)
    }

	const getPostEstimationGenerationAction = function(){
		let data = {
			"requestDetails":{
			   "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID
			},
			"SoldPlanCoverageID" : soldPlanCoverageID
		}
		apiCall({
		  url:'postEstimationGenerationAction',
		  data:data,
		  callBack: (result) =>{
			if(result.success){
			setLoader(true);
			  getInsuranceDetails();
			  getRequestById();
			} else {
			  Swal.fire('',localString(displayError(result)),'error')
			}
		  }
		})
	}
    

	return (
		<div className='request-filter'>
			<div className="row">
                 <h4>{localString("Plan Descriptions")}</h4>
            </div>
		    {/* <form className='document-form'> */}
                <div className='row'>
                     {
						activeCoverages && activeCoverages.map(i=>{
							return(
							   <div className='row col-sm-12'>
								    <label className="check-label">
									<input
										type="radio"
										name="coveragetype" 
										id={i.CoverageType}
										onClick={(e) => selectedPlanType(i)}>
									</input>{i.CoverageType}-{i.PlanType}
								    </label>
							    </div>
							)
					    })
                    }
                </div>
				<div className='text-right'>
					<Button
					handleOnClick={getPostEstimationGenerationAction}
					// type='submit'
					isDisabled={enablePlanType}
					classes='btn button-servify'
					>
					{localString('Next')}
					</Button>
                </div>
		    {/* </form> */}
        </div>
	)
}
export default PlanTypeButtonInput;


