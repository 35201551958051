import React, { useEffect, useState } from 'react';
import { Button, Input } from 'sfy-react';
import Swal from 'sweetalert2';

import { displayError } from '../../../Utility/Utils';
import { groupBy } from 'lodash';

export default function IssuesSelection(props) {
	const { 
		apiCall, localString, setLoader, consumerProduct, selectedIssues, setSelectedIssues, setShowSecCommunicationDetails,
		otherIssueDescription, setOtherIssueDescription, showOtherIssueTextBox, setshowOtherIssueTextBox, hideSection,
		coverage
	} = props;
	const [searchVal, setSearchVal] = useState('');
	const [issueList, setIssueList] = useState([]);
	const [filteredIssueList, setFilteredIssueList] = useState([]);
	const [groupIssueList, setGroupIssueList] = useState([]);
	const [showMaxLimitText, setShowMaxLimitText] = useState(false);
	const issuesLimit = {
        max: 4,
        min: 1
    };

	const handleFilter = (e) => {
		let val = e.target.value.toLowerCase();
		setSearchVal(e.target.value);
		let filteredArr = [...issueList];
		if(val) {
			let arr = issueList.filter(issue => {
				if((issue.DisplayText.toLowerCase()).includes(val) && !selectedIssues.find(a => a.IssueID === issue.IssueID)) {
					return issue;
				}
			});
			filteredArr = [...arr, ...selectedIssues]
		}else {
			filteredArr = issueList;
		}
		setFilteredIssueList(filteredArr);
		groupIssues(filteredArr);
 	}

	const getIssuesList = () => {
		setLoader(true);
		let reqObj = {
			ProductSubCategoryID: consumerProduct.ProductSubCategoryID,
			BrandID: consumerProduct.BrandID,
			ProductID: consumerProduct.ProductID,
			PlanCoverageID: coverage && coverage.PlanCoverageID ? coverage.PlanCoverageID : undefined,
		}
		apiCall({
			url: 'getIssuesList',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data) {
					setIssueList(result.data);
					setFilteredIssueList(result.data);
					groupIssues(result.data);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString('No issues found'),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	useEffect(() => {
		getIssuesList();
		return () => {
			setSelectedIssues([]);
			setOtherIssueDescription('');
		}
	}, []);

	const groupIssues = (params) => {
        let groupedObj = groupBy(params, function(param) {
            return param.DisplayCategory;
        });
		setGroupIssueList(groupedObj);
    }

	const issueItemClick = (issueItem) => {
		let arr = [...selectedIssues];
		if(selectedIssues.find(a => a.IssueID === issueItem.IssueID)) {
			if(issueItem.Category === 'Other' && (issueItem.Issue === 'Other Issue' || issueItem.Issue === 'Other' || issueItem.Issue === 'Others')) {
				setshowOtherIssueTextBox(false);
				setOtherIssueDescription('');
			}
			arr = selectedIssues.filter(a => a.IssueID !== issueItem.IssueID);
			setShowMaxLimitText(false);
		}else {
			if(selectedIssues.length < issuesLimit.max) {
				if(issueItem.Category === 'Other' && (issueItem.Issue === 'Other Issue' || issueItem.Issue === 'Other' || issueItem.Issue === 'Others')) {
					setshowOtherIssueTextBox(true);
				}
				arr.push(issueItem);
				setShowMaxLimitText(false);
			}else {
				setShowMaxLimitText(true);
			}
		}
		setSelectedIssues(arr);
	}

	const issueSubmit = () => {
		hideSection(['SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation']);
		setShowSecCommunicationDetails(true);
	}

	return (
		<div id='IssuesSelection' className="raiseReqSection">
			<div className='issuesSelection'>						
				<div className="row">
					<div className="col-sm-10 col-xs-8 form-label">
						<label>{localString("Select issues")}</label>
					</div>
					<div className="col-sm-6 col-xs-12 form-label issuesSearchBox">
						<div className="form-group search-box">
							<Input
								value={searchVal}
								handleOnChange={handleFilter}
								placeholder={localString("Search Issues")}
								className={{
									formControl: 'form-group',
									containerClass: 'input-container',
									inputClass: 'input'
								}}
							>
								<span className='align-right search-box-icon'></span>
							</Input>
						</div>
					</div>
				</div>
				<div>
					{	
						Object.keys(groupIssueList) && Object.keys(groupIssueList).length > 0 && Object.keys(groupIssueList).map((keyName, k) => 
							<div className="row" key={k}>
								<div className="issue-category-header">
									{localString(keyName)}
								</div>
								{ 
									groupIssueList[keyName] && groupIssueList[keyName].length > 0 && groupIssueList[keyName].map((issueItem, j) => 
										<div className="issue-div col-sm-4 col-xs-6" key={j}>
											<div className={`item issue-item ${selectedIssues.find(a => a.IssueID === issueItem.IssueID) ? 'item-active' : ''}`} onClick={() => issueItemClick(issueItem)}>
												{issueItem.DisplayText ? issueItem.DisplayText : issueItem.Issue}
											</div>
										</div>
									)
								}								
							</div>
						)						
					}
				</div>
				{
					showOtherIssueTextBox &&
						<div className="row">
							<div className='col-sm-6 col-xs-12'>
								<textarea 
									value={otherIssueDescription}
									onChange={(e) => setOtherIssueDescription(e.target.value)}
									placeholder={localString("Please enter issue description")}
									maxLength={'500'}
									rows='5'
									cols='80'
									style={{ maxWidth: '100%' }}
								/>
							</div>
						</div>
				}
				{
					showMaxLimitText &&
						<div className="row">
							<div className="text-center" style={{ fontSize: '24px', padding: '20px 0px' }}>
								{localString("Cannot select more than")} { issuesLimit.max } {localString("issues")}.
							</div>
						</div>
				}
				<div className="text-right">
					<Button
						className="btn button-servify nextBtn" 
						onClick={() => issueSubmit()}
						isDisabled={!selectedIssues.length || (showOtherIssueTextBox && !otherIssueDescription)}
					>
						<div className="button-next"></div>
					</Button>
				</div>
			</div>
		</div>
	)
}