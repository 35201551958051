import React from 'react';
import {Table} from 'sfy-react';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';

const ChargeDetails = (props) => {
	const {consumerServiceRequest,localString,currencySymbol} = props;

	const classObject = {
		table:"complaint-details-table table table-striped"
	}

	return (
		<>
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper bodyClassName="panel-body-no-padding" title={localString("Decision Matrix Details")} >
						<div className="request-panel request-charges-div">
						{
							consumerServiceRequest && consumerServiceRequest.decisionMatrixAttributes && 
							<Table className={classObject}>
								<Table.Body>
									<Table.Row>
										<Table.Data className="table-keys"> {localString("Repair Cost")} </Table.Data>
										<Table.Data> {consumerServiceRequest.decisionMatrixAttributes.RepairCost} </Table.Data>
									</Table.Row>
									<Table.Row>
										<Table.Data className="table-keys"> {localString("Replacement Cost")} </Table.Data>
										<Table.Data> {consumerServiceRequest.decisionMatrixAttributes.ReplacementCost} </Table.Data>
									</Table.Row>
									<Table.Row>
										<Table.Data className="table-keys"> {localString("Repair Logistics Cost")} </Table.Data>
										<Table.Data> {consumerServiceRequest.decisionMatrixAttributes.RepairLogisticsCost} </Table.Data>
									</Table.Row>
									<Table.Row>
										<Table.Data className="table-keys"> {localString("Replacement Logistics Cost")} </Table.Data>
										<Table.Data> {consumerServiceRequest.decisionMatrixAttributes.ReplacementLogisticsCost} </Table.Data>
									</Table.Row>
									<Table.Row>
										<Table.Data className="table-keys"> {localString("Buyout Amount")} </Table.Data>
										<Table.Data> {consumerServiceRequest.decisionMatrixAttributes.BuyoutAmount} </Table.Data>
									</Table.Row>
								</Table.Body>
							</Table>
						}
						</div>
					</PageContentWrapper>
				</div>
			</div>
		</>
	)
}

export default ChargeDetails
