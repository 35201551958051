import React,{useState, useEffect}  from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import moment from 'moment';
import {find,debounce} from 'lodash'
import { returnYesOrNo,displayError,returnLocalDateTime,getDateFormat,SERVICETYPE,returnLocalSlot,getLocaleDateString} from '../../../../../Utility/Utils';
import RequestTable from './RequestTable';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';

const RequestDetails = (props) => {
	const {
		accessFn,localString,getRequestById,canUpdateServiceRequest,consumerServiceRequest,apiCall,requestDeailsID,
		coverageDetails,defaultCountryLocale,currencySymbol,appleDropOffFlow
	} = props;
	const [showLoader,setLoader]=useState(false);
	const [consumerServiceRequestDetails, setConsumerServiceRequestDetails] = useState([])
	const [getCSRDetailsCalled, setGetCSRDetailsCalled] = useState(false)
	const [getDeliveryDetailsCalled, setGetDeliveryDetailsCalled] = useState(false)
	const [deviceOn, setDeviceOn] = useState()
	const [isWallMount, setIsWallMount] = useState(null)
	const [showEditDescription, setShowEditDescription] = useState(false)
	const [greenRewardsList, setGreenRewardsList] = useState([])
	const [selectedReward, setSelectedReward] = useState()
	const [getGreenRewardsCalled, setGetGreenRewardsCalled] = useState(false)
	const [selectedCoverage, setSelectedCoverage] = useState()
	const [selectedCoverageType, setSelectedCoverageType] = useState()
	const [adminFee, setAdminFee] = useState()
	const [deliveryDetails, setDeliveryDetails] = useState()
	const [inEditDiagnosis, setInEditDiagnosis] = useState(false)
	const [inEditEnggNotes, setInEditEnggNotes] = useState(false)
	const [inputData, setInputData] = useState({
		DiagnosisNotes:"",
		EngineerNotes:"",
		locationDescription:"",
		ExternalReferenceID:"",
		invoiceNumber:"",
		additionalRemark:"",
		replacementOrderNumberInput:"",
		returnOrderNumberInput:""
	})
	const [showEditDiagnosis, setShowEditDiagnosis] = useState(false)
	const [diagnosisStepsWords, setDiagnosisStepsWords] = useState()
	const [engineerNotesWords, setEngineerNotesWords] = useState()
	const [inEditMode, setInEditMode] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [repairCompleted, setRepairCompleted] = useState()
	const [editInvoiceNumber, setEditInvoiceNumber] = useState(false)
	const [editReturnOrderNumber, setEditReturnOrderNumber] = useState(false)
	const [editReplacementOrderNumber, SetEditReplacementOrderNumber] = useState(false)
	const [editAdditionalRemark, setEditAdditionalRemark] = useState(false)
	const [CSRLogAttributeData, setCSRLogAttributeData] = useState([])
	const damageTypeArr = [{
			"id": 1,
			"displayText": "Physical damage"
		},
		{
			"id": 2,
			"displayText": "Liquid damage"
		},
		{
			"id": 3,
			"displayText": "Screen damage"
		},
		{
			"id": 6,
			"displayText": "Mechanical breakdown"
		},
		{
			"id": 100,
			"displayText": "Unknown"
		}]

	const showReturnOrderNumber = (gsxRepairOrderLines)=> {
		let result = [];
		if(gsxRepairOrderLines && gsxRepairOrderLines.length>0){
			for(let i=0; i < gsxRepairOrderLines.length; i++){
				if(gsxRepairOrderLines[i].returnOrderNumber){
				result.append(gsxRepairOrderLines[i].returnOrderNumber)
				}
			}
		}
		return result.join(',')
	};

	const warrantyApplicable = (warrantyStatus)=> {
		if (warrantyStatus != null) {
			return returnYesOrNo(warrantyStatus);
		} else {
			return 'Pending Verification';
		}
	};

	const getLogisticsDisplayObject = (obj)=>{
		let logisticsObj = {};
			logisticsObj['Waybill'] = obj.waybill && obj.waybill;
			logisticsObj['Vendor'] = obj.vendor;
			logisticsObj['Verification Code'] = obj.secretcode;
			if (obj.location && obj.pickupconfirmationnumber) {
				logisticsObj['Pickup Number'] = obj.location + ' ' + obj.pickupconfirmationnumber;
			}
			else if (obj.pickupconfirmationnumber) {
				logisticsObj['Pickup Number'] = obj.pickupconfirmationnumber;
			}
			else if (obj.location) {
				logisticsObj['Location'] = obj.location;
			}
			if (obj.Name) {
				logisticsObj['Name'] = obj.Name;
			}
			if (obj.ContactNo) {
				logisticsObj['Contact Number'] = obj.ContactNo;
			}
			if (obj.Agreement_code) {
				logisticsObj['Agreement Code'] = obj.Agreement_code;
			}
		return logisticsObj;
	};

	const getTypeOfDamage = (damageId)=>{
		let damage = find(damageTypeArr, {id: damageId}); 
		if (damage && damage.displayText) {
			return damage.displayText;
		}else{
			return '-';
		}
	};

	const showSelectedCoverage = ()=> {  
		let consumerProductDetails = consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductDetails && consumerServiceRequest.consumerProduct.ProductDetails.length > 0 && consumerServiceRequest.consumerProduct.ProductDetails[0].SoldPlanCoverage && consumerServiceRequest.consumerProduct.ProductDetails[0] ? consumerServiceRequest.consumerProduct.ProductDetails[0] : null;
		let coverageType = consumerProductDetails && consumerProductDetails.SoldPlanCoverage && consumerProductDetails.SoldPlanCoverage.planCoverage && consumerProductDetails.SoldPlanCoverage.planCoverage.coverageType && consumerProductDetails.SoldPlanCoverage.planCoverage.coverageType.CoverageType;
		setSelectedCoverageType( coverageType ? coverageType : null);
		let coverage = coverageDetails && coverageDetails.length > 0 && coverageDetails.find((obj) => {
			return coverageType == obj.CoverageType;
		});
		let selectedCoverage = coverage ? coverage : null;
		setSelectedCoverage(selectedCoverage)
	};

	const getConsumerServiceRequestDetails = (id) => {
		setLoader(true);
		setGetCSRDetailsCalled(true)
		let reqID;

		if(id){
			reqID = id;
		}else {
			reqID = requestDeailsID;
		}

		apiCall({
			url:'getCSRDetails',
			data: {
				ConsumerServiceRequestID: reqID
			},
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					setConsumerServiceRequestDetails(result.data);
					if(result.data){
						let deviceStatus = (result.data[0] && result.data[0].deviceSwitchOn && result.data[0].deviceSwitchOn === true ? 'Yes' : 'No');
						setDeviceOn(deviceStatus)
					}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const getDeliveryDetails = () => {
		setLoader(true);
		setGetDeliveryDetailsCalled(true);
		apiCall({
			url:'getConsumerServiceRequestDeliveryDetails',
			data: {
				'ConsumerServiceRequestID': consumerServiceRequest.ConsumerServiceRequestID
			},
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					setDeliveryDetails(result.data)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	// const getGreenRewards = () => {
	// 	setLoader(true);
	// 	setGetGreenRewardsCalled(true);
	// 	var data = {
	// 		ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
	// 	}
	// 	if(consumerServiceRequest && consumerServiceRequest.SubscriptionDetailsID) {
	// 		data.SubscriptionDetailsID = consumerServiceRequest.SubscriptionDetailsID;
	// 	}
	// 	apiCall({
	// 		url:'getGreenRewards',
	// 		data: data,
	// 		callBack:(result)=>{
	// 			setLoader(false);
	// 			if(result.success){
	// 				// setGreenRewardsList(result.data);
	// 				// setSelectedReward(result.data[0]);
	// 			}else{
	// 				Swal.fire({
	// 					icon: 'error',
	// 					text: localString(displayError(result)),
	// 				});
	// 			}
	// 		}
	// 	});
	// }

	const EditDiagnosis = ()=> {
		setInEditDiagnosis(true);
	};

	const EditEnggNotes = ()=> {
		setInEditEnggNotes(true);
		setInputData({...inputData,EngineerNotes:consumerServiceRequest.DiagnosisDetails && consumerServiceRequest.DiagnosisDetails.notes})
		countEngineerNotesWords();
	};

	const closeModal = () => {
		setShowModal(false);
	}

	const editDescription = ()=> {
		setInEditMode(true);
	};

	const saveDiagnosisDetails = (e) =>{
		e.preventDefault();
		setLoader(true);
		if(e.target.name == 'diagnosisNotes'){
            setInputData({...inputData,EngineerNotes:""})
            if (inputData.DiagnosisNotes == '') {
				Swal.fire({
					icon:"error",
					text:localString('Please enter issue description'),
				})
                return;
            }
        }
        if(e.target.name == 'EngineerNotes'){
			setInputData({...inputData,DiagnosisNotes:""})
            if (inputData.EngineerNotes == '') {
				Swal.fire({
					icon:"error",
					text:localString('Please enter issue description'),
				})
                return;
            }
        }
		let data = {
            "ConsumerServiceRequestID":consumerServiceRequest.ConsumerServiceRequestID,
            "DiagnosisSteps":inputData.DiagnosisNotes,
            "EngineerNotes":inputData.EngineerNotes,
            "doNotUpdateRequest":true
        };
		apiCall({
			url:'submitDiagnosis',
			data:data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRequestById();
					setInEditDiagnosis(false)
					setInEditEnggNotes(false)
					setInputData({...inputData,DiagnosisNotes:"",EngineerNotes:""})
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const saveIssueDescription = (e) =>{
		e.preventDefault();
		if (inputData.locationDescription == '') {
            Swal.fire({
				icon:"error",
				text:localString('Please enter issue description'),
			});
            return;
        }
        let data = {
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "PartnerServiceLocationNotes": inputData && inputData.locationDescription
        };
		setLoader(true);
		apiCall({
			url:'updateRequestByID',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRequestById();
					setInEditMode(false)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const extRefIDPattern = (data)=> {
		return /^[\w\-\s]+$/.test(data);
	}

	const sendExternalRef = (e) =>{
		e.preventDefault();
		if(inputData.ExternalReferenceID.length>5 && inputData.ExternalReferenceID.length < 31 && extRefIDPattern(inputData.ExternalReferenceID)){
			let data ={
				"ExternalReferenceID": inputData && inputData.ExternalReferenceID,
				"ReferenceID": consumerServiceRequest.ReferenceID
			}
			setLoader(true);
			apiCall({
				url:'addReferenceID',
				data: data,
				callBack:(result)=>{
					setLoader(false);
					if(result.success){
						setShowModal(false)
						Swal.fire({
							title:"",
							text:localString("External ReferenceID added successfully"),
							icon:"success",
							allowEscapeKey:false
						}).then((result)=>{
							if(result.isConfirmed){
								getRequestById();
							}
						})
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		}else{
			Swal.fire({
				icon:"error",
				text:localString('External Reference ID has to be between 6 - 30 characters')
			})
		}
		
	}

	const cancelEditDiagnosis = ()=> {
		setInEditDiagnosis(false)
		setInputData({...inputData,DiagnosisNotes:""})
	};

	const cancelEditEnggNotes = ()=> {
		setInEditEnggNotes(false);
		setInputData({...inputData,EngineerNotes:""})
	};

	const cancelDescEdit = ()=> {
		setInEditMode(false);
		setInputData({...inputData,locationDescription:""})
	};

	const countEngineerNotesWords = debounce(()=> {
		if (inputData.EngineerNotes.length > 0) {
			setEngineerNotesWords(inputData.EngineerNotes.length + ' '+  localString("/1000 characters entered."));
		} else {
			setEngineerNotesWords("")
		}
	}, 200);

	const countDiagnosisStepWords = debounce(()=> {
		if(inputData.DiagnosisNotes.length > 0) {
			setDiagnosisStepsWords(inputData.DiagnosisNotes.length + ' '+  localString("/1500 characters entered."))
		} else {
			setDiagnosisStepsWords("")
		}
	}, 200);

	useEffect(() => {
		if(requestDeailsID){
			getConsumerServiceRequestDetails();
		}
	}, [requestDeailsID])

	useEffect(() => {
		if(!isWallMount){
			if(['VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1){
				let hasWallMountFlag = find(consumerServiceRequest.ConsumerProductAttributesResult, (attribute)=> {
					return attribute.AttributeName === 'IsWallMount';
				});
				if(hasWallMountFlag) {
					setIsWallMount((hasWallMountFlag.AttributeValue === 'true') ? 'Yes' : 'No')
				}
			}
		}
		if (['Repair completed', 'Repair cancel', 'Service completed', 'Service cancel'].indexOf(consumerServiceRequest.Status) == -1) {
			setShowEditDescription(true)
		}
		// if(([9,13].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && !getGreenRewardsCalled){
		// 	getGreenRewards(); // need to ask
		// }
		if (consumerServiceRequest && [9,13,14,15, SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1 && consumerServiceRequest.charges && !adminFee) {
			for (let i = 0; i < consumerServiceRequest.charges.length; i++) {
				if (['Mandatory deductible', 'Mandatorydeductible', 'Admin fees', 'Advance','Adminfees', 'RefundAmount'].indexOf(consumerServiceRequest.charges[i].Type) > -1) {
					setAdminFee(consumerServiceRequest.charges[i]);
				}
			}
		}
		if(consumerServiceRequest && consumerServiceRequest.DiagnosisDetails){
			setShowEditDiagnosis(true);
		}
		if ([SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !getDeliveryDetailsCalled) {
			getDeliveryDetails()
		}
		if(consumerServiceRequest && consumerServiceRequest.logs) {
			let repairComplete = find(consumerServiceRequest.logs, {Status: "Repair completed"});
			setRepairCompleted(repairComplete)
		}
		fetchCSRLogAttribute()
	}, [consumerServiceRequest])

	useEffect(() => {
		showSelectedCoverage();
	}, [coverageDetails,consumerServiceRequest])

	useEffect(() => {
		countDiagnosisStepWords()
	}, [inputData.DiagnosisNotes])

	useEffect(() => {
		countEngineerNotesWords()
	}, [inputData.EngineerNotes])

	const modalArray=[{
		dataKey:"ExternalReferenceID",
		value:consumerServiceRequest && consumerServiceRequest.ReferenceID,
		submitFunction:()=>{
			sendExternalRef()
		},
		onInputFunc:(val)=>{
			setInputData({ ...inputData, ['ExternalReferenceID'] : val  });
		},
	}]

	const editInvoiceField = () => {
		setEditInvoiceNumber(true)
		setInputData({...inputData,['invoiceNumber']: consumerServiceRequest.ExternalInvoiceNumber ? consumerServiceRequest.ExternalInvoiceNumber : ''})
    };

	const editReturnOrderNumberField = () => {
        setEditReturnOrderNumber(true);
		setInputData({...inputData,['returnOrderNumberInput']: consumerServiceRequest.RETURN_ORDER_NUMBER ? consumerServiceRequest.RETURN_ORDER_NUMBER : ''})
    };
    const editReplacementOrderNumberField = () => {
        SetEditReplacementOrderNumber(true);
		setInputData({...inputData,['replacementOrderNumberInput']: consumerServiceRequest.REPLACEMENT_ORDER_NUMBER ? consumerServiceRequest.REPLACEMENT_ORDER_NUMBER : ''})
    };

	const cancelInvoiceField = () => {
		setEditInvoiceNumber(false)
	}

	const cancelReturnOrderNumberField = () => {
		setEditReturnOrderNumber(false);
    };
    const cancelReplacementOrderNumberField = () => {
		SetEditReplacementOrderNumber(false);
    };

	const editAddRemark = () => {
		setEditAdditionalRemark(true);
		setInputData({...inputData,['additionalRemark']: consumerServiceRequest.DAMAGE_TYPE_DESCRIPTION ? consumerServiceRequest.DAMAGE_TYPE_DESCRIPTION : ''})
	};
	
	const cancelRemarkEdit = () => {
		setEditAdditionalRemark(false);
	};
	
	const saveAdditionalRemark = (e) => {
		e.preventDefault();
		let desc = inputData.additionalRemark.trim();
		if (desc == '') {
			Swal.fire({
				title:"",
				icon:"error",
				text:localString('Please enter remarks'),
			})
			return;
		}
		let params = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"Attributes": [
				{
					"AttrName": "DAMAGE_TYPE_DESCRIPTION",
					"AttrValue": desc
				}
			]
		};
		updateRequestAttributesByID(params);
	};

	const saveInvoiceFiels = (e) => {
		e.preventDefault();
        let desc = inputData.invoiceNumber.trim();
        if (desc == '') {
			Swal.fire({
				title:"",
				icon:"error",
				text:localString('Please enter Invoice Number'),
			})
            return;
        }
        let params = {
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "Attributes": [
                {
                    "AttrName": "EXTERNAL_INVOICE_NUMBER",
                    "AttrValue": desc
                }
            ]
        };
        updateRequestAttributesByID(params);
	}

	const saveReturnOrderNumberField = (e) => {
        e.preventDefault();
        let desc = inputData.returnOrderNumberInput.trim();
        if (desc == '') {
			Swal.fire({
				title:"",
				icon:"error",
				text:localString('Please enter Return Order Number'),
			})
            return;
        }
        let params = {
            "ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            "Attributes": [
                {
                    "AttrName": "RETURN_ORDER_NUMBER",
                    "AttrValue": desc
                }
            ]
        };
        saveRequestAttributesByID(params);
    };

    const saveReplacementOrderNumberField = (e) => {
        e.preventDefault();
        let desc = inputData.replacementOrderNumberInput.trim();
        if (desc == '') {
			Swal.fire({
				title:"",
				icon:"error",
				text:localString('Please enter Replacement Order Number'),
			})
            return;
        }
        let params = {
            "ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            "Attributes": [
                {
                    "AttrName": "REPLACEMENT_ORDER_NUMBER",
                    "AttrValue": desc
                }
            ]
        };
        saveRequestAttributesByID(params);
    };

	const saveRequestAttributesByID = (data) => {
		setLoader(true);
		apiCall({
			url:'saveRequestAttributesByID',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRequestById();
					setEditReturnOrderNumber(false);
					SetEditReplacementOrderNumber(false);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    };

	const updateRequestAttributesByID = (data) => {
		setLoader(true);
		apiCall({
			url:'updateRequestAttributesByID',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRequestById();
					setEditAdditionalRemark(false)
					setEditInvoiceNumber(false)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const fetchCSRLogAttribute = () => {
		setLoader(true);
		apiCall({
			url:'fetchCSRLogAttribute',
			data: {
				"ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID
			},
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					const CSRLogAttribute = result.data;
					setCSRLogAttributeData(CSRLogAttribute)
				}
			}
		});
	}
	
	const requestLabels = [
	{
		labelName : localString("Reference ID"),
		showLabel:(!consumerServiceRequest.showEngineerVisits),
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.ReferenceID || '-'
	},{
		labelName : localString("Repair ID"),
		showLabel: (consumerServiceRequest && consumerServiceRequest.REPAIR_REQUEST_REFERENCE_ID),
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.REPAIR_REQUEST_REFERENCE_ID || '-'
	},{
		labelName : localString("External Reference ID"),
		showLabel:accessFn('EXTERNAL_REF') && [SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1,
		customClass : "",
		showModal:true,
		iconCheck:consumerServiceRequest && !consumerServiceRequest.ExternalReferenceID,
		value : consumerServiceRequest && consumerServiceRequest.ExternalReferenceID || '-',
		iconClicked:()=>{
			setShowModal(true)
		}
	},{
		labelName : localString("External Reference ID"),
		showLabel:!accessFn('EXTERNAL_REF') && [SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1,
		customClass : "",
		showModal:false,
		value :consumerServiceRequest && consumerServiceRequest.ExternalReferenceID || '-'
	},{
		labelName : localString("GSX Confirmation Number"),
		showLabel:accessFn('GSX_FLOW') && consumerServiceRequest && consumerServiceRequest.consumerProduct && (consumerServiceRequest.consumerProduct.isAppleBrandID),
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber || '-'
	},{
		labelName: localString("GSX Confirmation Number (Drop-off Requisite)"),
		showLabel: accessFn('GSX_FLOW') && appleDropOffFlow && consumerServiceRequest?.ProvisionalGSXNumber,
		customClass : "",
		value : consumerServiceRequest?.ProvisionalGSXNumber || '-'
	},{
		labelName : localString("Purchase Order Number"),
		showLabel:accessFn('GSX_FLOW') && consumerServiceRequest && consumerServiceRequest.consumerProduct && (consumerServiceRequest.consumerProduct.isAppleBrandID),
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.PurchaseOrderNumber || '-'
	},{
		labelName : localString("Return Order Number"),
		showLabel:accessFn('GSX_FLOW') && consumerServiceRequest && consumerServiceRequest.consumerProduct && (consumerServiceRequest.consumerProduct.isAppleBrandID),
		customClass : "",
		value : consumerServiceRequest.gsxRepairOrderLines && showReturnOrderNumber(consumerServiceRequest.gsxRepairOrderLines) || '-'
	},{
		labelName : localString("Repair Type Description"),
		showLabel:accessFn('GSX_FLOW') && consumerServiceRequest && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.consumerProduct && (consumerServiceRequest.consumerProduct.isAppleBrandID),
		customClass : "",
		value :consumerServiceRequest && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeDescription || '-'
	},{
		labelName : localString("Repair Completion Type"),
		showLabel:consumerServiceRequest && consumerServiceRequest.RepairCompletionType,
		customClass : "",
		value :consumerServiceRequest && consumerServiceRequest.RepairCompletionType || '-'
	},{
		labelName : localString("Repair Outcomes"),
		showLabel:consumerServiceRequest && consumerServiceRequest.repairOutcomes,
		customClass : "",
		value :consumerServiceRequest && consumerServiceRequest.repairOutcomes || '-'
	},{
		labelName : localString("Fulfiller Name"),
		showLabel:true,
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.FulfillerName || '-'
	},{
		labelName : localString("Device"),
		showLabel:true,
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.product && consumerServiceRequest.consumerProduct.product.ProductName
	},{
		labelName : localString("Wall Mount"),
		showLabel: isWallMount && ['VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1,
		customClass : "",
		value : isWallMount && localString(isWallMount)
	},{
		labelName : localString("Current Status"),
		showLabel:true,
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.Status && localString(consumerServiceRequest.Status) || '-'
	},{
		labelName : localString("Invoice Number"),
		showEditNumber: !accessFn('READ_ONLY') && repairCompleted && consumerServiceRequest && consumerServiceRequest.SaveExternalInvoice,
		customClass: "",
		value: consumerServiceRequest.ExternalInvoiceNumber ? consumerServiceRequest.ExternalInvoiceNumber : '',
		editRecord: editInvoiceNumber,
		spanCheck:accessFn('ACCEPT_RETURN_DEVICE'),
		dataKey:"invoiceNumber",
		maxLength:150,
		onEditClick: () => {
			editInvoiceField()
		},
		onInputFunc: (data) => {
			setInputData({...inputData,['invoiceNumber']:data})
		},
		saveClick:(e)=> {
			saveInvoiceFiels(e)
		},
		cancelClick: () => {
			cancelInvoiceField()
		}
	},{
		labelName : localString("Replacement Order Number"),
		showEditNumber: !accessFn('READ_ONLY') && [SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1,
		customClass: "",
		value: consumerServiceRequest.REPLACEMENT_ORDER_NUMBER ? consumerServiceRequest.REPLACEMENT_ORDER_NUMBER : '',
		editRecord: editReplacementOrderNumber,
		dataKey:"replacementOrderNumberInput",
		spanCheck:consumerServiceRequest && !consumerServiceRequest.REPLACEMENT_ORDER_NUMBER && accessFn('EDIT_EXTERNAL_ORDER_NUMBER'),
		maxLength:150,
		onEditClick: () => {
			editReplacementOrderNumberField()
		},
		onInputFunc: (data) => {
			setInputData({...inputData,['replacementOrderNumberInput']:data})
		},
		saveClick:(e)=> {
			saveReplacementOrderNumberField(e)
		},
		cancelClick: () => {
			cancelReplacementOrderNumberField()
		}
	},{
		labelName : localString("Replacement Model Number"),
		showLabel: !accessFn('READ_ONLY') && [SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && consumerServiceRequest && consumerServiceRequest.EXCHANGE_ORDER_MODEL,
		customClass : "",
		value : consumerServiceRequest && (consumerServiceRequest.REPLACEMENT_ORDER_NUMBER ? consumerServiceRequest.EXCHANGE_ORDER_MODEL : '')
	},{
		labelName : localString("Return Order Number"),
		showEditNumber: !accessFn('READ_ONLY') && [SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1,
		customClass: "",
		value: consumerServiceRequest.RETURN_ORDER_NUMBER ? consumerServiceRequest.RETURN_ORDER_NUMBER : '',
		editRecord:editReturnOrderNumber,
		spanCheck:!consumerServiceRequest.RETURN_ORDER_NUMBER && accessFn('EDIT_EXTERNAL_ORDER_NUMBER'),
		dataKey:"returnOrderNumberInput",
		maxLength:150,
		onEditClick: () => {
			editReturnOrderNumberField()
		},
		onInputFunc: (data) => {
			setInputData({...inputData,['returnOrderNumberInput']:data})
		},
		saveClick:(e)=> {
			saveReturnOrderNumberField(e)
		},
		cancelClick: () => {
			cancelReturnOrderNumberField()
		}
	},{
		labelName : localString("Service Type"),  
		showLabel:SERVICETYPE && [SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1,
		customClass : "",
		value : (!consumerServiceRequest.showEngineerVisits || (consumerServiceRequest.showEngineerVisits && !consumerServiceRequest.subservicetype))
		? localString(consumerServiceRequest && consumerServiceRequest.servicetype && consumerServiceRequest.servicetype.Type) 
		: consumerServiceRequest && consumerServiceRequest.showEngineerVisits && consumerServiceRequest.subservicetype && localString(consumerServiceRequest.subservicetype.SubServiceType)
	},{
		labelName : localString("Source Channel"),
		showLabel:consumerServiceRequest.Source == "BoseDashboard",
		customClass : "",
		value :consumerServiceRequest && consumerServiceRequest.Source == "BoseDashboard" && localString(consumerServiceRequest.SourceChannel)
	},{
		labelName : localString("Schedule Date"), 
		showLabel: [SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1,
		customClass : "",
		value :consumerServiceRequest && consumerServiceRequest.ScheduledDateTime && getLocaleDateString(consumerServiceRequest.ScheduledDateTime,defaultCountryLocale) || '-' ,// consumerServiceRequest && consumerServiceRequest.ScheduledDateTime && returnLocalDateTime(consumerServiceRequest.ScheduledDateTime,"DD-MM-YYYY"),//consumerServiceRequest && consumerServiceRequest.ScheduledDateTime && getLocaleDateString(consumerServiceRequest.ScheduledDateTime,defaultCountryLocale) || '-'
	},{
		labelName : localString("Slot"), 
		showLabel:[SERVICETYPE.ENQUIRY,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)== -1,
		customClass : "",
		value : (consumerServiceRequest && (returnLocalSlot(consumerServiceRequest.ScheduledFromTime) + " - " + returnLocalSlot(consumerServiceRequest.ScheduledToTime)))
	},{
		labelName : localString("Reassigned"),
		showLabel:consumerServiceRequest && consumerServiceRequest.ReassignDateTime,
		customClass : "",
		value : consumerServiceRequest && getLocaleDateString(consumerServiceRequest.ReassignDateTime,defaultCountryLocale),//getLocaleDateString(consumerServiceRequest.ReassignDateTime,defaultCountryLocale)
	},{
		labelName : (consumerServiceRequest.ServiceTypeID == 9 ? localString("Pickup Address") : localString("Address")),
		showLabel:accessFn('CONSUMER_ADDRESS_DETAILS') && [SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1,
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.Address || '-'
	},{
		labelName : localString("Landmark"),
		showLabel:accessFn('CONSUMER_ADDRESS_DETAILS') && SERVICETYPE && [SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1,
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.Landmark || '-'
	},{
		labelName : localString("Zipcode"),
		showLabel:accessFn('CONSUMER_ADDRESS_DETAILS') && SERVICETYPE && [SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1,
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.PinCode || '-'
	},{
		labelName : localString("Under Warranty"), 
		showLabel:[9,13,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1,
		customClass : "",
		value :consumerServiceRequest && localString(returnYesOrNo(consumerServiceRequest.IsUnderWarranty))
	},{
		labelName : localString("Warranty Applicable"),
		showLabel: consumerServiceRequest.IsUnderWarranty && [9,13].indexOf(consumerServiceRequest.ServiceTypeID) == -1 && !consumerServiceRequest.showEngineerVisits,
		customClass : "",
		value : consumerServiceRequest && localString(warrantyApplicable(consumerServiceRequest.IsWarrantyApplicable)) 
	},{
		labelName : localString("Selected Coverage"),
		coverage: consumerServiceRequest && (selectedCoverage || selectedCoverageType),
		customClass : "",
		selectedCoverageType:selectedCoverageType,
		selectedCoverage:selectedCoverage,
	},{
		labelName : localString("Issues"),
		customClass : "",
		showList:consumerServiceRequest.issues && [9,13,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1 && !consumerServiceRequest.isAnchorRequest,
		value : consumerServiceRequest && consumerServiceRequest.issues,
	},{
		labelName : consumerServiceRequest && consumerServiceRequest.Source != "SkyworthDashboard" ? localString("Issue description") : localString("Dealer name and Contact Number"), // need to verify ****
		issueList:[9,13,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1,
		customClass : "",
		dataKey:"locationDescription",
		value : "",
		editMode:inEditMode, 
		complaintDetails:showEditDescription && canUpdateServiceRequest ,//&& !accessFn("READ_ONLY") ,//&& canUpdateServiceRequest,
		iconClass:"glyphicon glyphicon-plus",
		title:"Add your version",
		description:consumerServiceRequest && consumerServiceRequest.Description || '-',
		partnerServiceLocationNotes : consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationNotes,
		editDescription:()=>{
			editDescription()
		},
		onInputFunc:(val)=>{
			setInputData({...inputData,['locationDescription']:val})
		},
		cancelButtonFunction:()=>{
			cancelDescEdit()
		}
	},
	{
		labelName : localString("Device Cosmetic Condition"),
		customClass : "",
		showDeviceCosmeticCondition: true
		// value : consumerServiceRequest && consumerServiceRequest.issues,
	},
	// {
	//     labelName : localString("Green Rewards Chosen"),
	//     showLabel:"", //(([9,13].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && (greenRewardsList.length > 0)) ,
	//     customClass : "",
	//     greenRewardsList:true,
	//     value : ""
	//     // <span each={greenRewardsList}>
	//     // <strong>{Rewards.RewardName}: </strong>{ (Remarks) ? Remarks : Rewards.RewardValue} <span if={(['Pickup Request Accepted','Device received','Device shipped','Service accepted','Device dropped'].indexOf(consumerServiceRequest.Status) > -1 && !(consumerServiceRequest.IsVouchersGenerated) && (accessFn('EDIT_GREEN_REWARDS')) && !(SubscriptionReward))} class="glyphicon glyphicon-edit edit-individual-address" style="cursor: pointer;" onclick={editSelectedRewards}></span> <br>
	//     // </span>
	// },
	// {
	//     labelName : localString("Recycle Certificate"),
	//     showLabel:(([9,13].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest.destructionDoc),
	//     customClass : "",
	//     value : ""
	//     // <div class="col-xs-6 col-sm-2">
	//     //     <a href="{baseUrl}/document/view?u={consumerServiceRequest.destructionDoc.FilePath.replace('?','%3F').replace(/&/g,'%26')}" target="_blank" id="certificate-td">
	//     //         <span><i class="glyphicon glyphicon-file" style="font-size:18px;"></i></span>
	//     //     </a>
	//     // </div>
	// },
	{
		labelName : ([9].indexOf(consumerServiceRequest.ServiceTypeID)> -1 ? localString('Logistics - From Customer\'s Address To Servify Hub') : localString("Logistics - From Customer's Address To Service Center")),
		customClass : "",
		logistics: !accessFn('HIDE_REQUEST_DETAILS_REMARKS') && consumerServiceRequest.singleFlow && SERVICETYPE && ([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP,9,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1),
		value : consumerServiceRequest && consumerServiceRequest.singleFlow && getLogisticsDisplayObject(consumerServiceRequest.singleFlow)
	},{
		labelName : ([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID)> -1 ? localString("Logistics - From Service Center to Service Center") : [SERVICETYPE.BER_PAYMENT].indexOf(consumerServiceRequest.ServiceTypeID)> -1 ? localString("Logistics - From Service Center to Hub") : localString("Logistics - From Drop-Off Center to Servify Hub")),
		customClass : "",
		logistics: !accessFn('HIDE_REQUEST_DETAILS_REMARKS') && consumerServiceRequest.sctoshFlow && ([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP,9,13,SERVICETYPE.BER_PAYMENT,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1) ,
		value : consumerServiceRequest && consumerServiceRequest.sctoshFlow && getLogisticsDisplayObject(consumerServiceRequest.sctoshFlow)
	},{
		labelName : localString("Logistics - From Servify Hub to Recycle Hub"),
		customClass : "",
		logistics: !accessFn('HIDE_REQUEST_DETAILS_REMARKS') && (consumerServiceRequest.shtorhFlow && ([9,13].indexOf(consumerServiceRequest.ServiceTypeID)> -1)) ,
		value : consumerServiceRequest && consumerServiceRequest.shtorhFlow && getLogisticsDisplayObject(consumerServiceRequest.shtorhFlow)
	},{
		labelName : localString("Logistics - Return to Customer"),
		customClass : "",
		logistics: !accessFn('HIDE_REQUEST_DETAILS_REMARKS') && consumerServiceRequest.returnFlow && ([SERVICETYPE.PICK_UP,9,SERVICETYPE.CLAIM_PICK_UP,13,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1) ,
		value : consumerServiceRequest && consumerServiceRequest.returnFlow && getLogisticsDisplayObject(consumerServiceRequest.returnFlow)
	},{
		labelName : localString("Logistics - From Service Center to Customer's Address to Service Center"),
		customClass : "",
		logistics: !accessFn('HIDE_REQUEST_DETAILS_REMARKS') && consumerServiceRequest.doortodoorFlow && ([SERVICETYPE.DOOR_TO_DOOR_REPLACEMENT].indexOf(consumerServiceRequest.ServiceTypeID)> -1) ,
		value : consumerServiceRequest && consumerServiceRequest.doortodoorFlow && getLogisticsDisplayObject(consumerServiceRequest.doortodoorFlow)
	},{
		labelName : localString("Verification Code"),
		showLabel:!accessFn('HIDE_REQUEST_DETAILS_REMARKS') && consumerServiceRequest.Remarks && ([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1),
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.Remarks && consumerServiceRequest.Remarks.VerificationCode || '-'
	},{
		labelName : localString("Vendor Tracking Url"),
		customClass : "",
		showLink: consumerServiceRequest && consumerServiceRequest.VendorTrackingUrl,
		linkText : "Click here for Vendor Tracking Url",
		url : consumerServiceRequest && consumerServiceRequest.VendorTrackingUrl,
	},{
		labelName : localString("Waybill Number"),
		showLabel:!accessFn('HIDE_REQUEST_DETAILS_REMARKS') && consumerServiceRequest.Remarks && ([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1),
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.Remarks &&consumerServiceRequest.Remarks.waybill || '-'
	},{
		labelName : localString("Shipping Method"),
		showLabel:consumerServiceRequest && consumerServiceRequest.shippingMethod,
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.shippingMethod
	},{
		labelName : localString("Tier"),
		showLabel:consumerServiceRequest && consumerServiceRequest.DeliveryTier && ([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1),
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.DeliveryTier || '-'
	},{
		labelName : consumerServiceRequest && consumerServiceRequest.returnFlow ? localString("Expected Date of Delivery") : localString("Expected Date of Pick up"),
		showLabel:consumerServiceRequest && consumerServiceRequest && consumerServiceRequest.ExpectedDateofDelivery && ([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1),
		customClass : "",
		value :consumerServiceRequest && consumerServiceRequest.ExpectedDateofDelivery && returnLocalDateTime(consumerServiceRequest.ExpectedDateofDelivery,"DD-MM-YYYY") || '-',//consumerServiceRequest && consumerServiceRequest.ExpectedDateofDelivery ? moment(consumerServiceRequest.ExpectedDateofDelivery, 'DD-MM-YYYY').format(getDateFormat(defaultLanguage)) : '-'
	},{
		labelName : localString("Pick Up Confirmation Number"),
		showLabel:consumerServiceRequest.Remarks && ([9,13,SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && consumerServiceRequest.Remarks.pickupConfirmationNumber,
		customClass : "",							
		value : consumerServiceRequest && consumerServiceRequest.Remarks &&consumerServiceRequest.Remarks.pickupConfirmationNumber || '-'
	},{
		labelName : localString("Location"),
		showLabel:consumerServiceRequest.Remarks && ([9,13,SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && consumerServiceRequest.Remarks.location,
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.Remarks && consumerServiceRequest.Remarks.location || '-'
	},{
		labelName : localString("UTR Number"),
		showLabel:consumerServiceRequest && consumerServiceRequest.BERPaymentDetails && consumerServiceRequest.BERPaymentDetails.identificationNumber,
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.BERPaymentDetails && consumerServiceRequest.BERPaymentDetails.identificationNumber
	},{
		showCharges: consumerServiceRequest && consumerServiceRequest.displayCharges && consumerServiceRequest.displayCharges.length > 0,
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.displayCharges 
	},
	// commented below - as this is commented in exiting tag also
	// {
	// 	labelName : localString("BER Amount"),
	// 	customClass : "",
	// 	currency:currencySymbol,
	// 	spanCurrency: consumerServiceRequest && consumerServiceRequest.BERamount,
	// 	value : consumerServiceRequest && consumerServiceRequest.BERamount, 
	// },{
	// 	labelName : localString("Total Amount"),
	// 	customClass : "",
	// 	currency:currencySymbol,
	// 	spanCurrency: consumerServiceRequest.TotalBilledAmount && ([9,13,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT].indexOf(consumerServiceRequest.ServiceTypeID) == -1),
	// 	value : consumerServiceRequest && consumerServiceRequest.TotalBilledAmount,
	// },{
	// 	labelName : localString("Pickup Charge"),
	// 	customClass : "",
	// 	currency:currencySymbol,
	// 	spanCurrency: consumerServiceRequest.ServiceTypeID == SERVICETYPE['PICK_UP'] && accessFn( 'ADD_NORMAL_CHARGES'),
	// 	value : consumerServiceRequest && consumerServiceRequest.PickupDropCharges || '-',
	// },{
	// 	labelName : adminFee && adminFee.Name && adminFee.Name != "Admin fees" ? localString(adminFee.Name) + '(' + localString("Admin Fees") + ')' : localString("Admin Fees"),
	// 	customClass : "",
	// 	currency:currencySymbol,
	// 	spanCurrency: accessFn('ADD_NORMAL_CHARGES') && SERVICETYPE && [9,13,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1 && adminFee,
	// 	value : adminFee && adminFee.Charge, 
	// },{
	// 	labelName : consumerServiceRequest.Status == 'Service completed' ? localString("Amount Paid") : consumerServiceRequest.Status!='Service completed' && localString("Amount Payable"),
	// 	customClass : "",
	// 	currency:currencySymbol,
	// 	spanCurrency: accessFn( 'ADD_NORMAL_CHARGES') && SERVICETYPE && [9,13,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1,
	// 	value : consumerServiceRequest && consumerServiceRequest.ConsumerAmount || 0 ,
	// },
	{
		labelName : localString("Loss DateTime"),
		showLabel: consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].lossDateTime,
		customClass : "",
		value :consumerServiceRequestDetails.length > 0 && consumerServiceRequestDetails[0].lossDateTime && returnLocalDateTime(consumerServiceRequestDetails[0].lossDateTime)
	},{
		labelName : localString("Incident Description"),
		showLabel: consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].lossDetails,
		customClass : "",
		value :consumerServiceRequestDetails.length > 0 && consumerServiceRequestDetails[0].lossDetails
	},{
		labelName : localString("Repair DateTime"),
		showLabel:consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].dateOfRepair,
		customClass : "",
		value :consumerServiceRequestDetails.length > 0 && consumerServiceRequestDetails[0].lossDateTime && returnLocalDateTime(consumerServiceRequestDetails[0].lossDateTime)
	},{
		labelName : localString("Repair Cost"),
		showLabel:consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].reimbursementCost,
		customClass : "",
		currency:currencySymbol,
		value : consumerServiceRequestDetails.length > 0 && consumerServiceRequestDetails[0].reimbursementCost ? consumerServiceRequestDetails[0].reimbursementCost : "-" ,
	},{
		labelName : localString("Damage Type"),
		showLabel:consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].damageId != 0, 
		customClass : "",
		value : consumerServiceRequestDetails.length > 0 && localString(getTypeOfDamage(consumerServiceRequestDetails[0].damageId)),
	},{
		labelName : localString("Loaner Device IMEI"),
		showLabel:consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].LoanerIMEI , 
		customClass : "",
		value : consumerServiceRequestDetails.length > 0 && consumerServiceRequestDetails[0].LoanerIMEI
	},{
		labelName : localString("Condition Of the Loaner Device After Use"),
		showLabel:consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].ReturnedLoanerDeviceStatus , 
		customClass : "",
		value : consumerServiceRequestDetails.length > 0 &&  consumerServiceRequestDetails[0].ReturnedLoanerDeviceStatus
	},{
		labelName : localString("Remarks for the Loaner Device"),
		showLabel:consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].ReturnedLoanerDeviceRemarks , 
		customClass : "",
		value : consumerServiceRequestDetails.length > 0 && consumerServiceRequestDetails[0].ReturnedLoanerDeviceRemarks
	},{
		labelName : localString("Loss Description"),
		showLabel:consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].descriptionOfLoss , 
		customClass : "",
		value : consumerServiceRequestDetails.length > 0 && consumerServiceRequestDetails[0].descriptionOfLoss
	},{
		labelName : localString("Delivery Address"),
		showLabel:deliveryDetails && deliveryDetails, 
		customClass : "",
		value : deliveryDetails && <>{deliveryDetails.DeliveryAddress}<br/>{deliveryDetails.DeliveryLandmark}</>
	},{
		labelName : localString("Device switching on"),
		showLabel: deviceOn, 
		customClass : "",
		value : deviceOn && localString(deviceOn)
	},{
		labelName : localString("Location of Incident"),
		showLabel:consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].placeOfDamage , 
		customClass : "",
		value : consumerServiceRequestDetails.length > 0 && consumerServiceRequestDetails[0].placeOfDamage
	},{
		labelName : localString("Incident Type"),
		showLabel: consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].incidentType,
		customClass : "",
		value : consumerServiceRequestDetails.length > 0 && consumerServiceRequestDetails[0].incidentType
	},{
		labelName : localString("Date of Theft"),
		showLabel: consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].dateOfTheft,
		customClass : "",
		value : consumerServiceRequestDetails.length > 0 && returnLocalDateTime(consumerServiceRequestDetails[0].dateOfTheft,"DD-MM-YYYY")
	},{
		labelName : localString("Place of Theft"),
		showLabel: consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].placeOfTheft,
		customClass : "",
		value : consumerServiceRequestDetails.length > 0 && consumerServiceRequestDetails[0].placeOfTheft
	},{
		labelName : localString("Police Report Date"),
		showLabel: consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].policeReportDate,
		customClass : "",
		value : consumerServiceRequestDetails.length > 0 && returnLocalDateTime(consumerServiceRequestDetails[0].policeReportDate,"DD-MM-YYYY")
	},{
		labelName : localString("Report Number"),
		showLabel: consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].policeReportNumber,
		customClass : "",
		value : consumerServiceRequestDetails.length > 0 && consumerServiceRequestDetails[0].policeReportNumber
	},{
		labelName : localString("Law Enforcement Agency"),
		showLabel: consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].policeAgency,
		customClass : "",
		value : consumerServiceRequestDetails.length > 0 && consumerServiceRequestDetails[0].policeAgency
	},{
		labelName : localString("Incident Description"),
		showLabel: consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].theftDetails,
		customClass : "",
		value : consumerServiceRequestDetails.length > 0 && consumerServiceRequestDetails[0].theftDetails
	},{
		labelName : localString("Diagnosis Steps"),
		showDiagnosis:consumerServiceRequest && accessFn('GSX_FLOW') , 
		customClass : "",
		diagnosisName:"diagnosisNotes",
		diagnosisInnerCheck:showEditDiagnosis && !accessFn('READ_ONLY') && canUpdateServiceRequest,
		editDiagnosis:inEditDiagnosis,
		value: consumerServiceRequest && consumerServiceRequest.DiagnosisDetails && consumerServiceRequest.DiagnosisDetails.diagnosis ,
		diagnosisFunction : ()=>{
			EditDiagnosis()
		},
		dataKey:"DiagnosisNotes",
		onInputFunc:(val)=>{
			setInputData({ ...inputData, ['DiagnosisNotes'] : val  });
		},
		iconClass:"glyphicon glyphicon-plus {hide: inEditDiagnosis} ",
		diagnosisStepsWords:diagnosisStepsWords,
		cancelButtonFunction:()=>{
			cancelEditDiagnosis()
		}
	},{
		labelName : localString("Engineer notes"),
		showDiagnosis:consumerServiceRequest && accessFn('GSX_FLOW') , 
		customClass : "",
		diagnosisName:"EngineerNotes",
		diagnosisInnerCheck:showEditDiagnosis && !accessFn('READ_ONLY') && canUpdateServiceRequest,
		editDiagnosis:inEditEnggNotes,
		value: consumerServiceRequest && consumerServiceRequest.DiagnosisDetails && consumerServiceRequest.DiagnosisDetails.notes ,
		diagnosisFunction : ()=>{
			EditEnggNotes()
		},
		dataKey:"EngineerNotes",
		onInputFunc:(val)=>{
			setInputData({ ...inputData, ['EngineerNotes'] : val  });
		},
		iconClass:"glyphicon glyphicon-plus {hide: inEditEnggNotes} ",
		diagnosisStepsWords:engineerNotesWords,
		cancelButtonFunction:()=>{
			cancelEditEnggNotes()
		}
	},{
		labelName : localString("Review By Apple Requested"),
		showLabel:consumerServiceRequest && consumerServiceRequest.reviewByAppleRequested && accessFn('GSX_FLOW') , 
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.reviewByAppleRequested
	},{
		labelName : localString("CS Code Entered"),
		showLabel:consumerServiceRequest && consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.CSCode && accessFn('GSX_FLOW') , 
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.CSCode
	},{
		labelName : localString("Repair Classification Type"),
		showLabel:consumerServiceRequest && consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.RepairClassificationType && accessFn('GSX_FLOW') , 
		customClass : "",
		value : consumerServiceRequest && consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.RepairClassificationType
	},{
		labelName : localString("Request Type"),
		showLabel:consumerServiceRequest && consumerServiceRequest.RequestType,
		customClass : "",
		value : consumerServiceRequest.RequestType
	},{
		labelName : localString("Drop-Off Locations"),
		customClass : "",
		showLink:true && consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL,
		linkText : "Click here for Drop-Off Locations",
		url : consumerServiceRequest && consumerServiceRequest.dropOffLocationsURL
	},{
		labelName : localString("External ASC Name"),
		showLabel:consumerServiceRequest && consumerServiceRequest.ClientServiceLocationName,
		customClass : "",
		value : consumerServiceRequest.ClientServiceLocationName
	},{
		labelName : localString("External ASC Address"),
		showLabel:consumerServiceRequest && consumerServiceRequest.ClientServiceLocationAddress,
		customClass : "",
		value : consumerServiceRequest.ClientServiceLocationAddress
	},{
		labelName : localString("Account Holder Name"),
		showLabel: consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].accountHolderFullName,
		customClass : "",
		value : consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].accountHolderBillingAddress
	},{
		labelName : localString("Account Holder Billing Address"),
		showLabel:consumerServiceRequest && consumerServiceRequest.RequestType,
		customClass : "",
		value : consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].accountHolderBillingAddress
	},{
		labelName : localString("Account Holder MobileNo"),
		showLabel:consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].accountHolderMobileNo,
		customClass : "",
		value : consumerServiceRequestDetails && consumerServiceRequestDetails[0] &&consumerServiceRequestDetails[0].accountHolderMobileNo
	},{
		labelName : localString("Account Holder EmailID"),
		showLabel:consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].accountHolderEmailID,
		customClass : "",
		value : consumerServiceRequestDetails && consumerServiceRequestDetails[0] && consumerServiceRequestDetails[0].accountHolderEmailID
	},{
		labelName : localString("Additional Remarks"),
		showEditNumber: !accessFn('READ_ONLY'),
		value: consumerServiceRequest.DAMAGE_TYPE_DESCRIPTION ? consumerServiceRequest.DAMAGE_TYPE_DESCRIPTION : '',
		editRecord: editAdditionalRemark,
		dataKey:"additionalRemark",
		maxLength:150,
		onEditClick: () => {
			editAddRemark()
		},
		onInputFunc: (data) => {
			setInputData({...inputData,['additionalRemark']:data})
		},
		saveClick: (e) => {
			saveAdditionalRemark(e)
		},
		cancelClick: () => {
			cancelRemarkEdit()
		}
	},{
		labelName : localString("Internal Remarks"),
		showLogAttribute: accessFn('ALLOW_ADDITIONAL_REASON') && CSRLogAttributeData,
		customClass : "",
		value : CSRLogAttributeData
	},
	{
		labelName : localString("Old Coverage"),
		showLabel:consumerServiceRequestDetails?.[0]?.updateCoverageDetails,
		customClass : "",
		value : consumerServiceRequestDetails?.[0]?.updateCoverageDetails?.oldCoverage
	},
	{
		labelName : localString("Updated Coverage"),
		showLabel:consumerServiceRequestDetails?.[0]?.updateCoverageDetails,
		customClass : "",
		value : consumerServiceRequestDetails?.[0]?.updateCoverageDetails?.updatedCoverage
	},
	{
		labelName : localString("Revised Deductible"),
		showLabel:consumerServiceRequestDetails?.[0]?.updateCoverageDetails,
		customClass : "",
		value : consumerServiceRequestDetails?.[0]?.updateCoverageDetails?.revisedDeductible
	},{
		labelName : localString("Deductible Collected"),
		showLabel:consumerServiceRequestDetails?.[0]?.updateCoverageDetails,
		customClass : "",
		value : consumerServiceRequestDetails?.[0]?.updateCoverageDetails?.deductibleCollected
	}
]

	return (
		<>
			{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper bodyClassName="panel-body-no-padding" title={localString("Request Details")}>
						<RequestTable {...props} 
							showEditDescription={showEditDescription} 
							requestLabels={requestLabels} 
							getLogisticsDisplayObject={getLogisticsDisplayObject}
							saveDiagnosisDetails={saveDiagnosisDetails}
							saveIssueDescription={saveIssueDescription}
							inputData={inputData}
							showEditDiagnosis={showEditDiagnosis}
							showModal={showModal}
							closeModal={closeModal}
							modalArray={modalArray}
							sendExternalRef={sendExternalRef}
							>
						</RequestTable>
					</PageContentWrapper>
				</div>
			</div>
		</>
	)
}
export default RequestDetails
