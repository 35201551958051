import React from "react";
import { Input, Button } from "sfy-react";
import ShadowWrapper from "./ShadowWrapper";

import "./CaseManager.scss";

const SearchTransactions = (props) => {
	const { getCases, searchFilters, setSearchFilters, localString } = props;

	const handleOnSearchTransactions = (e) => {
		e.preventDefault();
		getCases();
	};

	return (
		<div className="search-transactions">
			<ShadowWrapper classname="rounded">
				<div className="border-bottom search-transactions-heading">
					{localString("Search Transactions")}
				</div>
				<div className="search-input">
					<Input
						id="imeiSerialNumber"
						name="imeiSerialNumber"
						placeholder={localString("Search with IMEI/Serial No.")}
						label={localString("IMEI/Serial No.")}
						className={{
							input: "customised-border customised-border-radius",
						}}
						inputType="text"
						value={searchFilters.productUniqueID}
						handleOnChange={(e) => {
							let val = e.target.value;
							setSearchFilters((prevData) => ({
								...prevData,
								productUniqueID: val,
							}));
						}}
					/>

					<Input
						id="soldPlanCode"
						name="soldPlanCode"
						placeholder={localString("Search with Sold Plan Code.")}
						className={{
							input: "customised-border customised-border-radius",
						}}
						label={localString("Sold Plan Code")}
						inputType="text"
						isDisabled={false}
						value={searchFilters.soldPlanCode}
						handleOnChange={(e) => {
							let val = e.target.value;
							setSearchFilters((prevData) => ({
								...prevData,
								soldPlanCode: val,
							}));
						}}
					/>

					<Input
						id="referenceNo"
						name="referenceNo"
						placeholder={localString("Search with Reference No.")}
						className={{
							input: "customised-border customised-border-radius",
						}}
						label={localString("Reference No.")}
						inputType="text"
						isDisabled={false}
						value={searchFilters.referenceID}
						handleOnChange={(e) => {
							let val = e.target.value;
							setSearchFilters((prevData) => ({
								...prevData,
								referenceID: val,
							}));
						}}
					/>

					<Input
						id="emailId"
						name="emailId"
						placeholder={localString("Search with Email Id")}
						className={{
							input: "customised-border customised-border-radius",
						}}
						label={localString("Email Id")}
						inputType="text"
						isDisabled={false}
						value={searchFilters.email}
						handleOnChange={(e) => {
							let val = e.target.value;
							setSearchFilters((prevData) => ({
								...prevData,
								email: val,
							}));
						}}
					/>

					<Input
						id="contactNumber"
						name="contactNumber"
						placeholder={localString("Search with Contact No.")}
						className={{
							input: "customised-border customised-border-radius",
						}}
						label={localString("Contact No.")}
						inputType="text"
						isDisabled={false}
						value={searchFilters.mobileNo}
						handleOnChange={(e) => {
							let val = e.target.value;
							setSearchFilters((prevData) => ({
								...prevData,
								mobileNo: val,
							}));
						}}
					/>

					<Button
						classes="search-button gray-button button-small"
						type="button"
						data-type="submit"
						handleOnClick={handleOnSearchTransactions}
					>
						{localString("Search")}
					</Button>
				</div>
			</ShadowWrapper>
		</div>
	);
};

export default SearchTransactions;
