import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Loader, MultiInput, Radio, Modal, ModalHeader, ModalBody, ModalFooter } from 'sfy-react';
import Swal from "sweetalert2";

import { displayError } from '../../Utility/Utils';

export default function VerifyUserDetails(props) {
    const {
        displayPlans, localString, clearFields, userDetails, setUserDetails, setLoader, apiCall, userList, setUserList, 
        setUser, setExistingUserDetails
    } = props;

	const otpLength = 6;
    const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(60);
	const [displayResendBtn, setDisplayResendBtn] = useState(false);
    const [showOtpCard, setShowOtpCard] = useState(false);
    const [otp, setOtp] = useState('');
	const [isEmail, setIsEmail] = useState('');
	const infoText = `We have sent the verification code to your ${isEmail ? 'email ID' : 'mobile number'}`;
	// const emailMobileText = (isEmail ? ('+' + flagData.dialCode + ' ') : '') + mobileOrEmail
	const emailMobileText = isEmail ? userDetails?.email : ((userDetails?.phoneCode ? userDetails.phoneCode : '+91') + ' ' + userDetails.mobileNo)

    const getTimerString = () => {
		return minutes === 0 && seconds === 0
				? ""
				: minutes
				+ ":"
				+ (seconds < 10 ? "0" + seconds : seconds)
	}

    const clearOtpFields = () => {
        setShowOtpCard(false);
        setMinutes(0);
        setSeconds(0);
    }

    const updateUserList = (isEmailId) => {
        let obj = {};
        let arr = [...userList].map((item, k) => {
            let newObj = { ...item };
            if(item.userID == userDetails.userID) {
                if(isEmailId) {
                    newObj.emailVerified = 1;
                }else {
                    newObj.mobileNoVerified = 1;
                }
                obj = newObj;
            }
            return newObj;
        })
        setUserList(arr);
        if((obj.email && !obj?.emailVerified) || (obj?.mobileNo && !obj?.mobileNoVerified)) {
            setUser('verify')
            setUserDetails(obj);
            setExistingUserDetails(obj);
        }else { 
            setUser('')
            setUserDetails({});
            setExistingUserDetails({});
        }
    }

    const generateOtp = (isEmailId) => {
		setLoader(true); 
		let reqObj = {
			countryCode: userDetails?.countryCode ? userDetails?.countryCode?.toUpperCase() : 'IN'
        };
		if(isEmailId) {
			reqObj.email = userDetails.email;
		}else {
			reqObj.mobileNo = userDetails.mobileNo;
			reqObj.phoneCode = userDetails?.phoneCode ? userDetails?.phoneCode : undefined
		}
		apiCall({
			url: isEmailId ? 'generateOtpEmail' : 'generateOtpMobile',
			data: reqObj,
			callBack: (result) => { 
				setLoader(false);
				if(result.success) {
					setShowOtpCard(true);
					setSeconds(59);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: displayError(result),  
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
    } 

    const validateOtp = (e) => {
		e.preventDefault();
		if(!otp || (otp.length !== otpLength)) return;
		setLoader(true); 
		let reqObj = {
			OTP: otp,
			countryCode: userDetails?.countryCode ? userDetails?.countryCode?.toUpperCase() : 'IN'
		};
		if(isEmail) {
			reqObj.email = userDetails.email;
		}else {
			reqObj.mobileNo = userDetails.mobileNo;
			reqObj.phoneCode = userDetails?.phoneCode ? userDetails?.phoneCode : undefined
		}
		apiCall({
			url: isEmail ? 'validateOtpEmail' : 'validateOtpMobile',
			data: reqObj,
			callBack: (result) => { 
                setLoader(false);
				if(result.success) {
                    Swal.fire({  
						icon: 'success',  
						text: localString(isEmail ? 'Email Verification Completed' : 'Phone Number Verification Completed'),  
					});
                    setShowOtpCard(false);
                    updateUserList(isEmail);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: displayError(result),  
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if(minutes > 0) {
                if(seconds > 0) {
                    setSeconds(seconds - 1);
                }else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }else {
                if(seconds > 0) {
                    setSeconds(seconds - 1);
                }
            }
        }, 1000);
        if((seconds == 0) && (minutes == 0)) {
            setDisplayResendBtn(true);
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        }
    }, [seconds]);

    return (
        <>
            <div className={`col-xs-12 col-sm-6 col-md-4 col-lg-3 userCardContainer ${!showOtpCard ? '' : 'hideCardInMobileView'}`}>
                <div className={`userCard animated animatedFadeInLeft fadeInLeft ${ !showOtpCard ? 'activeUserCard' : ''}`} >
                    <div className='cardHeader'>
                        {localString(`Verify User Details`)}
                        { !showOtpCard && <span className='pull-right crossBtn' onClick={() => clearFields() }>x</span> }
                    </div>
                    <div className='cardBody'>
                        <div className='userList'>
                            { (userDetails?.mobileNo && !userDetails?.mobileNoVerified) &&
                                <div className={`accordianCard openCard`}>
                                    <div className='accordianCardItem'>{localString('Phone')}: {`${userDetails.phoneCode ? userDetails.phoneCode : ''} ${userDetails.mobileNo ? userDetails.mobileNo : '--'}`}</div> 
                                    <div>
                                        <div className='row'>
                                            <div className='verifyBtn'>
                                                <Button
                                                    className={`btn button-servify w-100`}
                                                    type='button'
                                                    isDisabled={showOtpCard}
                                                    onClick={() => {
                                                        generateOtp(false)
                                                        setIsEmail(false);
                                                        setOtp('');
                                                    }}
                                                >
                                                    {localString('Verify')}
                                                </Button>																
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            { (userDetails?.email && !userDetails?.emailVerified) &&
                                <div className={`accordianCard openCard`}>
                                    <div className='accordianCardItem'>{localString('Email ID')}: {userDetails.email ? userDetails.email : '--'}</div>
                                    <div>
                                        <div className='row'>
                                            <div className='verifyBtn'>
                                                <Button
                                                    className={`btn button-servify w-100`}
                                                    type='button'
                                                    isDisabled={showOtpCard}
                                                    onClick={() => {
                                                        generateOtp(true)
                                                        setIsEmail(true);
                                                        setOtp('');
                                                    }}
                                                >
                                                    {localString('Verify')}
                                                </Button>																
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>											
                </div>
            </div>
            { showOtpCard &&
                <div className={`col-xs-12 col-sm-6 col-md-4 col-lg-3 userCardContainer ${!displayPlans ? '' : 'hideCardInMobileView'}`}>
                    <div className={`userCard animated animatedFadeInLeft fadeInLeft ${ !displayPlans ? 'activeUserCard' : ''}`} >
                        <div className='cardHeader'>
                            {localString(`${isEmail ? 'Email' : 'Phone Number'} Verification`)}
                            <span className='pull-right crossBtn' onClick={() => clearOtpFields() }>x</span>
                        </div>
                        <div className='cardBody'>
                            <form onSubmit={validateOtp}>
                                <div style={{ height: '420px' }}>
                                    <div className="user-info-text">
                                        <p className='info-msg'>{localString(infoText)}</p>
                                        <p className='email-number'>{emailMobileText}</p>
                                    </div>
                                    <div className="text-center">
                                        <MultiInput
                                            length={otpLength}
                                            handleOnChange={(val) => setOtp(val)}
                                            classNames={{
                                                input: "otp-input",
                                            }}
                                        />
                                        { (minutes == 0 && seconds == 0) ? <p className='resendBtn' onClick={() => generateOtp(isEmail)}>Resend Verification Code</p> : <p style={{ marginTop: '15px' }}>{getTimerString()}</p>}
                                    </div>
                                </div>
                                <div className="">
                                    <Button
                                        handleOnClick={validateOtp}
                                        isDisabled={!otp || (otp.length !== otpLength)}
                                        type="submit"
                                        className="btn servify-blue-Btn"
                                        text="Verify"
                                    >
                                        {localString('Verify')}
                                    </Button>
                                </div>
                            </form>
                        </div>											
                    </div>
                </div>
            }
        </>
    );
}