import React,{useCallback,useRef,useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import {Loader,setAutocomplete} from 'sfy-react';
import Swal from "sweetalert2";
import {findIndex} from 'lodash';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import {displayError,regexTest,generateUUID, SERVICETYPE} from '../../../../../Utility/Utils';
import './BillingDetails.scss'
import BillingDetailsJSX from './BillingDetailsJSX';

const BillingDetails = (props) => {
    const {apiCall,countriesChecks,consumerServiceRequest,TaxDetailValue,TaxDetailName,localString,billingDetailList,getBillingDetails,getRequestById,
        consumerDetails} = props;
    const [loader, setLoader] = useState(false);
    const [addNewGst, setAddNewGst] = useState(false)
    const [selectedBilling, setSelectedBilling] = useState({})
    const [billingDetailEditable, setBillingDetailEditable] = useState(false);
    const [entityType, setEntityType] = useState("Individual");
    const identifier = "gstInfoAddress";
    const [isAddressProcessed, setIsAddressProcessed] = useState(false);
    const [stateListLoaded, setStateListLoaded] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [inputData, setInputData] = useState({
        gstNumber:"",
        registeredName:"",
        Landmark:"", 
        Zipcode:"",
        Address:"",
        State:"",
        StateID:"",
        City:"",
        Lat:"",
        Lng:"",
        Country:""
    })
    const [countryConfig, setCountryConfig] = useState()
    const [countryConfigArray, setCountryConfigArray] = useState()
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getBillingDetails()
    }, [])
    
    useEffect(() => {
        if(!inputData.Landmark || inputData.Landmark == ""){
            setInputData({...inputData,['State']:"",['StateID']:"",['City']:""})
        }
    }, [inputData.Landmark])

    // useEffect(() => {      
    //     if(inputData.gstNumber && !inputData.gstNumber.length === countriesChecks.countryConfig.TaxDetailValue.LENGTH[0] ){
    //        setErrors({...errors,['gstNumber']:localString(countriesChecks.countryConfig.TaxDetailName.REGEX_FN + " Number is required")})
    //     }
    // }, [inputData.gstNumber])
    useEffect(() => {
        if(inputData && inputData.gstNumber && inputData.gstNumber.length && (!inputData.gstNumber.length === TaxDetailValue.LENGTH[0])){
            setErrors({...errors,['gstNumber']:localString(TaxDetailName.LENGTH[0] + " Number is required")})
        }
    }, [inputData.gstNumber])

    useEffect(() => {
        getStateList();
    }, [])

    useEffect(() => {
        setCountryConfig(countriesChecks.countryConfig)
    }, [countriesChecks.countryConfig])

    useEffect(() => {
        processCountryObject()
    }, [countryConfig])

    useEffect(() => {
        let billDetail = consumerServiceRequest && consumerServiceRequest.ConsumerAmount > 0 && ( !consumerServiceRequest.InvoiceGeneratedDate || ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && findIndex(consumerServiceRequest.logs, {Status: 'Payment received'}) < 0);
        setBillingDetailEditable(billDetail);
    }, [consumerServiceRequest])

    const checkStateValid = (state) => {
        let selectedState = stateList.find((obj) => { 
			if((obj.State).toLowerCase() == (state).toLowerCase()) {
				// setStateObj(obj)
				return obj;
			}
        });
        return selectedState ? true : false;
    };

    const AddNewBilling =()=>{
        // resetAddBilling();
        setAddNewGst(true)
    }

    const processCountryObject = ()=> {
        if(countryConfig && countryConfig.addressConfig && !isAddressProcessed) {
            setIsAddressProcessed(true);
            let countryArray = JSON.parse(JSON.stringify(countryConfig.addressConfig));
            setCountryConfigArray(countryArray)
            // clearAddressInputs();
            // showFormErrors = false;
        }
    };

    const BillingDetailListClick = (item)=>{
        if(billingDetailEditable){
            billingDetailList && billingDetailList.forEach((billingDetail)=>{
                billingDetail.IsSelected = false;
            })
            item.IsSelected = true;
            setSelectedBilling(item)
        }
    }

    const SaveBillingDetail = (e)=>{
        let data = selectedBilling;
        data.ConsumerServiceRequestID = consumerServiceRequest.ConsumerServiceRequestID;
        setLoader(true);
        apiCall({
			url:'saveRequestMapping',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    getBillingDetails();
                    getRequestById();
                    setAddNewGst(false)
                    // this.disableSave = true;
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const getStateList = (e)=>{
        if(!stateListLoaded) {
            apiCall({
                url:'getStateList',
                data:  {
                    CountryID: consumerServiceRequest && consumerServiceRequest.consumer && consumerServiceRequest.consumer.CountryID ? consumerServiceRequest.consumer.CountryID : null
                },
                callBack:(result)=>{
                    setLoader(false);
                    if(result.success){
                        setStateListLoaded(true)
                        let codes = result.data && result.data.map(v=>({...v,value:v.State}))
                        setStateList(codes);
                    }else{
                        Swal.fire({
                            icon: 'error',
                            text: localString(displayError(result)),
                        });
                    }
                }
            });
        }
    }

    const validate = () => {
		let errorObj = {...errors}
		let flag = {}

		// Landmark validation
		if(!inputData || (inputData && !inputData.Landmark)) {
			flag.Landmark = false;
			errorObj.Landmark = localString("Landmark is required")
		}else if(inputData.Landmark && (!inputData.Lat || inputData.Lat == 0 || !inputData.Lng || inputData.Lng == 0)) {
			flag.Landmark = false;
			errorObj.Landmark = localString("There's a problem with this location, please select a different location")
		}else {
			flag.Landmark = true
			errorObj.Landmark = null
		}

        // GSTIN validation
        //if(inputData.gstNumber==""){
        if(!inputData || (inputData && !inputData.gstNumber)){
            flag.gstNumber = false;
            errorObj.gstNumber = localString("GSTIN Number is required")
        }else if(inputData.gstNumber.length < countriesChecks.countryConfig.TaxDetailValue.LENGTH[0]){
            flag.gstNumber = false;
            errorObj.gstNumber = localString("Should Be 15 Characters")
        }else{
            flag.gstNumber = true
            errorObj.gstNumber = null
        }

        // Registered Name validation
        if(inputData.registeredName==""){
            flag.registeredName = false;
            errorObj.registeredName = localString("Name is required")
        }else{
            flag.registeredName = true
            errorObj.registeredName = null
        }

		countryConfigArray && countryConfigArray.map(item => {
			if(item.keyName !== 'Landmark') {
				let obj = validateFields(item.keyName, inputData ? inputData[item.keyName] : '', true);
				flag[item.keyName] = obj.flag;
				errorObj[item.keyName] = obj.msg;
			}
		}) 
		setErrors(errorObj);
		if(Object.values(flag).some(e => !e)) {
			return false;
		}else {
			return true;
		}
	}

    const onFormSaveClick = () => {
        if(validate()) saveGstInfo();
        // validate()
        // saveGstInfo()
        // this.checkFormInputs();
        // this.CheckdisableSave();
        // !this.disableSave && this.saveGstInfo();
    };

    const saveGstInfo = ()=> {  
        let data = {
            ConsumerID: consumerDetails.ConsumerID,
            EntityType: entityType,
            BillingName: inputData.registeredName,
            Landmark: inputData.Landmark,
            Country: inputData.Country,
            Lat: inputData.Lat,
            Lng: inputData.Lng,
            TaxDetails: {
                TaxDetailName: "GSTIN",
                TaxDetailValue: inputData.gstNumber
            }
        }
        // processAddressObject(data); 
        for(let i = 0; i < countryConfigArray.length; i++) {
            let objKeyName = countryConfigArray[i].keyName;
            if(objKeyName != "Landmark") {
                let objValue = inputData[objKeyName];
                data[objKeyName] = objValue ? objValue : '';
            }
        }

        if(data.State) {
            let selectedState = stateList.find((obj) => obj.State == inputData.State);
            selectedState && selectedState.StateID && (inputData.StateID = selectedState.StateID);
            data.State=selectedState.State;
            data.StateID=selectedState.StateID
        }
        setLoader(true);
        apiCall({
			url:'addOrUpdateBillingDetails',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    setAddNewGst(false);
                    getBillingDetails(); 
                    resetAddBilling();  // need to check
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const validateFields = (key, value, returnValue) => {
		let configObj = countryConfigArray.find(item => (item.keyName === key));
		if(configObj) {
			if(!value && configObj.mandatory) { // Mandatory check
				if(returnValue) {
					let obj = { flag: false, msg: localString(configObj.label + " is required") }
					return obj
				}else {
					setErrors({ ...errors, [key]: localString(configObj.label + " is required") })
				}
			}else if(!value && !configObj.mandatory) { // if value is empty and key in !mandatory then pass
				if(returnValue) {
					let obj = { flag: true, msg: null }
					return obj
				}else {
					setErrors({ ...errors, [key]: null });
				}
			}else if((configObj.regexFunc && ["NUMERIC", "ALPHANUMERIC"].includes(configObj.regexFunc) && !regexTest((configObj.regexFunc).toLowerCase(), value)) || (configObj.length && !configObj.length.includes(value.length))) { //Regex and input length check
				if(returnValue) {
					let obj = { flag: false, msg: localString(configObj.label + " is invalid") }
					return obj
				}else {
					setErrors({ ...errors, [key]: localString(configObj.label + " is invalid") })
				}
			}else if(configObj.keyName == "State" && !checkStateValid(value)) { // State Check
				if(returnValue) {
					let obj = { flag: false, msg: localString(configObj.label + " is invalid") }
					return obj
				}else {
					setErrors({ ...errors, [key]: localString(configObj.label + " is invalid") })
				}
			}
            // else if(configObj.keyName == "District" && !checkDistrictValid(value)) { // State Check
			// 	if(returnValue) {
			// 		let obj = { flag: false, msg: localString(configObj.label + " is invalid") }
			// 		return obj
			// 	}else {
			// 		setErrors({ ...errors, [key]: localString(configObj.label + " is invalid") })
			// 	}
			// }
            else {
				if(returnValue) {
					let obj = { flag: true, msg: null }
					return obj
				}else {
					setErrors({ ...errors, [key]: null });
				}
			}
		}
	}

    const resetAddBilling = () =>{  //need to check
        // self.entityType = "Individual";
        setEntityType("Individual")
        setInputData({
            gstNumber:"",
            registeredName:"",
            Landmark:"", 
            Zipcode:"",
            Address:"",
            State:"",
            StateID:"",
            City:"",
            Lat:"",
            Lng:"",
            Country:""
        })
        setErrors({});
        setAddNewGst(false)
        // $("#registeredName-"+self.identifier).val(""); 
        // $("#gstNumber-"+self.identifier).val("");
        // self.country = "";
        // self.addNewGst = false;
        // self.clearAddressInputs();
        // this.addRemoveAddressFormClass();
    }

    const inputLabels = [
        {
            type : "radio",
            radioButtons:[{
                buttonText: "Personal",
                value:"Individual",
                btnName:"entity-type",
                isSelect:entityType,
                onclickupdate:(val)=>{
                    setEntityType(val)
                }
            },{
                buttonText: "Company",
                value:"Company",
                btnName:"entity-type",
                isSelect:entityType,
                onclickupdate:(val)=>{
                    setEntityType(val)
                }
            }]
        },
        {
            type:"input",
            customBlockClass:"col-sm-3 padding-top-bottom",
            labelText:localString(countriesChecks?.countryConfig?.TaxDetailName?.REGEX_FN+ ' Number'),

			dataKey:"gstNumber",
            maxLength: countriesChecks?.countryConfig?.TaxDetailValue?.LENGTH[0],
            // maxLength:TaxDetailValue.LENGTH[0],
			oninputFunc: (val) => {
                // checkEnteredInput
                setInputData({...inputData,['gstNumber']:val})
            },
            // errorMsg:false,
            // errorText:countriesChecks.countryConfig.TaxDetailName.REGEX_FN + " Number is required"

        },
        {
            type:"input",
            customBlockClass:"col-sm-3 padding-top-bottom",
            labelText:entityType == "Individual"? localString("Registered Name"):localString("Company Name"),
			dataKey:"registeredName",
			oninputFunc: (val) => {
                // checkEnteredInput
                setInputData({...inputData,['registeredName']:val})
            },
            // errorMsg:false,
            // errorText:"Name is required"
        },{
            type:"input",
            customBlockClass:"col-sm-6 padding-top-bottom",
            labelText:localString("Landmark"),
			dataKey:"Landmark",
            id:"autocomplete",
			oninputFunc: (val) => {
                // checkEnteredInput
                setInputData({...inputData,['Landmark']:val})
            },
            placeholder:localString("Enter a location"),
            ref:true
        },{
            countryArray:countryConfigArray,
            oninputFunc:(val,arr)=>{
                if(arr.keyName === "Zipcode"){
                    setInputData({...inputData,['Zipcode']:val})

                }else if(arr.keyName === "Address"){
                    setInputData({...inputData,['Address']:val})

                }else if(arr.keyName === "State"){
                    setInputData({...inputData,['State']:val.State,['StateID']:val.StateID})

                }else if(arr.keyName === "City"){
                    setInputData({...inputData,['City']:val})

                }
            }
        }
    ]

    return (
        <>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="inner-padding">
				<div className="row" id="Billing_Details_Tab">
					<PageContentWrapper bodyClassName="panel-body-no-padding" title={localString("Billing Details")}>
						<BillingDetailsJSX 
                            {...props}
                            billingDetailList={billingDetailList}
                            BillingDetailListClick={BillingDetailListClick}
                            billingDetailEditable={billingDetailEditable}
                            SaveBillingDetail={SaveBillingDetail}
                            inputLabels={inputLabels}
                            setInputData={setInputData}
                            addNewGst={addNewGst}
                            AddNewBilling={AddNewBilling}
                            inputData={inputData}
                            stateList={stateList}
                            onFormSaveClick={onFormSaveClick}
                            resetAddBilling={resetAddBilling}
                            checkStateValid={checkStateValid}
                            countryConfigArray={countryConfigArray}
                            errors={errors} setErrors={setErrors}
                            validateFields={validateFields}
                            countriesChecks={countriesChecks}
                            selectedBilling={selectedBilling}
                        />
					</PageContentWrapper>
				</div>
			</div>
		</>
    )
}

export default BillingDetails
