import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Input, Loader } from 'sfy-react';
import Swal from 'sweetalert2';

import { displayError } from '../../../Utility/Utils';


export default function SelectSubCategory(props) {
	const {
		apiCall, localString, setLoader, showAddProduct, displaySubCat, handleSubCatSelection, setScreenToViewDiv, 
		brandSpecificValidations, validations, isAppleBrandID, imeiOrSerialNo, placehoderText, handleImeiChange, 
		brand, subCat, disableAddProduct, imeiOrSerialNoMaxlength, displaySerialNumberInput, setCallAddProductVerifyApi,
		AdditionalSupportedLengthCheck
	} = props;

	const qFilter = JSON.parse(props.qFilter);
	const [subCatFilter, setSubCatFilter] = useState('');
	const [subCatList, setSubCatList] = useState([]);
	const [filteredSubCatList, setFilteredSubCatList] = useState([]);

	const handleSubCatFilter = (e) => {
		let val = e.target.value.toLowerCase();
		setSubCatFilter(e.target.value);
		if(val) {
			let filteredArr = subCatList.filter(data => {
				if(data && data.ProductSubCategory && data.ProductSubCategory.toLowerCase().includes(val)) {
					return data;
				}
			});
			setFilteredSubCatList(filteredArr);
		} else{
			setFilteredSubCatList(subCatList);
		}
	}

	const getSubcategory = () => {
		setScreenToViewDiv('selectSubCategory');
		setLoader(true)
		let reqObj = {
			qFilter: {}
		};
		if(qFilter.brands) reqObj.qFilter.brands = qFilter.brands;
		if(brand && brand.BrandID) reqObj.qFilter.brands = [brand.BrandID];
		apiCall({
			url: 'getSubcategory',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data){
					setSubCatList(result.data);
					setFilteredSubCatList(result.data);
					brandSpecificValidations();
					if(result.data.length === 0) {
						Swal.fire({  
							icon: 'error',  
							text: localString('No subcategories found'),  
						});
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString('No subcategories found'),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	useEffect(() => {
		displaySubCat && brand.BrandID && getSubcategory();
	}, [brand, displaySubCat]);
	
	return (
		<>
			{ showAddProduct && displaySubCat &&
				<div id='selectSubCategory' className="raiseReqSection">
					<div className='selectSubCategory'>
						<label>{localString("Select a Subcategory")}</label>
						<div className="row">
							<div className="col-sm-10 col-xs-8">
								<div className="form-group">
									<Input
										value={subCatFilter}
										handleOnChange={handleSubCatFilter}
										placeholder={localString("Search subcategories")}
										className={{
											formControl: 'form-group',
											containerClass: 'input-container',
											inputClass: 'input'
										}}
									/>
								</div>
							</div>
							<div className="col-sm-2 col-xs-4" style={{ marginTop: '10px' }}>
								<Button 
									className="pull-right button-refresh" 
									onClick={() => {
										// setSubCatFilter('');
										getSubcategory();
									}}
								/>
							</div>
						</div>
						{/* { (brand.WarrantyCheckDetails && !hideSerialNumberInput) && (!isAppleBrandID || (isAppleBrandID && qFilter['gsx-user-detail-id'])) && <> */}
						{ (brand && brand.WarrantyCheckDetails && displaySerialNumberInput) && (!isAppleBrandID || (isAppleBrandID && qFilter['gsx-user-detail-id'])) && <>
								<div className="row">
									<div className="col-sm-10"><p className="text-center">{localString("OR")}</p></div>
								</div>
								<div className="row">
									<div className="col-sm-10 col-xs-8">
										<div className="form-group">
											<Input
												id='serialNo1'
												value={imeiOrSerialNo}
												handleOnChange={(e) => handleImeiChange('serialNo1', e)}
												maxLength={imeiOrSerialNoMaxlength}
												placeholder={placehoderText}
												className={{
													formControl: 'form-group',
													containerClass: 'input-container',
													inputClass: 'input'
												}} 
											/>
										</div>
									</div>
									<div className="col-sm-2 col-xs-4">
										{
											(validations && (validations.length > 1 || AdditionalSupportedLengthCheck)) && 
												<Button
													className="btn button-servify nextBtn pull-right" 
													// onClick={() => verifyAndCreateProduct('addConsumerProduct')}
													onClick={() => setCallAddProductVerifyApi(true)}
													isDisabled={disableAddProduct}
												>
													<div className="button-next"></div>
												</Button>
										}
										
									</div>
								</div>
							</> }
						<div className="row fixed-height">
							{
								filteredSubCatList && filteredSubCatList.length > 0 && filteredSubCatList.map((item, k) => <div className="col-sm-3 col-xs-6" key={k} onClick={() => handleSubCatSelection(item)}>
									<div className={`item subcat-item ${(subCat.ProductSubCategoryID == item.ProductSubCategoryID) ? 'item-active': ''}`}>
										<img src={item.ImageUrl} alt={item.ProductSubCategory} />
										<p>{localString(item.ProductSubCategory)}</p>
									</div>
								</div>)
							}
						</div>
					</div>
				</div>
			}
		</>
	);
}