import React from "react";
import { Modal, Button } from "sfy-react";
import "../../RequestProof.scss";

const DocPreviewModal = (props) => {
	const {
		localString,
		showModal,
		closeModal,
		pendingFile,
		consumerServiceRequestProofObject,
		handleClickFileUpload,
	} = props;

	return (
		<>
			<Modal
				showModal={showModal}
				closeModal={closeModal}
				showCloseBtn={true}
				className="qr-modal-width"
			>
				<Modal.Header>
					<h4
						style={{
							padding: "10px 2px",
							fontSize: "20px",
							fontWeight: "bold",
							textAlign: "left",
						}}
					>
						{localString(
							`Upload ${consumerServiceRequestProofObject.document.documentType}`
						)}
					</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="rp-qr-code-wrapper">
						{pendingFile &&
							pendingFile.type.startsWith("image") && (
								<img
									src={URL.createObjectURL(pendingFile)}
									alt="Uploaded Preview"
									className="img-responsive"
								/>
							)}

						{pendingFile &&
							pendingFile.type.startsWith("video") && (
								<video
									controls
									controlsList="nodownload nofullscreen"
									disablePictureInPicture
									style={{ width: "570px", height: "300px" }}
									width="570"
								>
									<source
										src={URL.createObjectURL(pendingFile)}
									/>
								</video>
							)}

						<div>
							Name:{" "}
							{pendingFile && pendingFile.name
								? pendingFile.name
								: "-"}
						</div>

						<div>
							Size:{" "}
							{pendingFile && pendingFile.size
								? `${pendingFile.size / 1000} KB`
								: "-"}
						</div>

						<Button
							classes="button-ghost button-small"
							handleOnClick={handleClickFileUpload}
						>
							{localString("Upload")}
						</Button>
					</div>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
};
export default DocPreviewModal;
