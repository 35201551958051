import React, {useState,useEffect} from 'react';
import './DiagnosisHistoryModal.scss';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter} from 'sfy-react';
import moment from 'moment';
import ExpandItem from '../../components/ExpandItem/ExpandItem';
import {diagnosticStatus, diagnosisImage} from '../../Utility/Utils';

const DiagnosisHistoryModal = (props) => {
    const {showModal, publicUrl, localString, dataObj, setOpenHistoryModal, name, setHistoryModalObj} = props;
    const src = diagnosisImage[name] ? diagnosisImage[name] : diagnosisImage["Default"]
    return (
        <Modal
            showModal={showModal}
            showCloseBtn={true}
            backdropClassName={"history-modal"}
            closeBtnClassName={"modal-close"}
            className={"modal-wrapper"}
            closeModal={() => {
                setOpenHistoryModal(false);
                setHistoryModalObj(null);
            }}
        >
            <ModalHeader>
                <h4 className="modal-title">
                    {src && <span><img className="name-logo" src={src} alt="img" /></span>}
                    {localString(name)}
                </h4>
            </ModalHeader>
            <ModalBody>
                {dataObj && dataObj.length > 0 && dataObj.map((obj) => {return(obj &&
                    <ExpandItem 
                        name={"Diagnosed on: "}
                        showImage={true}
                        imgUrl={diagnosticStatus[obj.status] && diagnosticStatus[obj.status.toString()].image} 
                        localString={localString} 
                        imgAlt={diagnosticStatus[obj.status] && diagnosticStatus[obj.status.toString()].displayText}
                        subHeaderText1={moment(obj.date).format('DD MMM, YYYY hh:mm a')}
                        showExpandArrow={false}
                    >
                    </ExpandItem>
                )})}
            </ModalBody>
            <ModalFooter>
                <div className="col-xs-12">
                    <div className="text-right">
                        <Button 
                            type='button' 
                            classes="button-servify-solid" 
                            handleOnClick={() => {
                                setOpenHistoryModal(false);
                                setHistoryModalObj(null);
                            }}
                        >
                            {localString("Close")}
                        </Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default DiagnosisHistoryModal
