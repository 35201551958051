import React from 'react';
import {Loader, Radio, Table, Dropdown, Button} from 'sfy-react';
import moment from 'moment';

const DeadOnArrivalTable = (props) => {
	const {accessFn, localString, consumerServiceRequest, isSalesChannelEditable, checkReplacementMode, editSalesChannel,
		doaOptions, salesChannel, salesChannelValue, setSalesChannelValue, saveSalesChannel, cancelSaveSalesChannel, setDeviceReplacementMode,
		deviceReplacementMode, saveReplacementMode, doaStatus, isHanded, requestStatus, replacedProduct, setChooseReplaceDevice, chooseReplaceDevice,
		orderReplacedPart, setSelectedReplacedProduct, disableDOAInput, firstProduct, openModalProduct
	} = props;

	const doaOptionsMapped = {
		'REPLACE_DEVICE': 1,
		'REFUND_DEVICE': 2,
		'GENERATE_CERTIFICATE': 3,
		'NORMAL_FLOW': 4
	};

	const consumerProduct = consumerServiceRequest.consumerProduct;
	
	const classObject = {
		table:"table table-striped DOA-details-table"
	}

	return (
		<Table className={classObject}>
			<Table.Body>
				<Table.Row>
					<Table.Data className="table-keys">{localString("Device")}</Table.Data>
					<Table.Data>{consumerProduct.product.ProductName} {consumerProduct.ModelNo} {consumerProduct.StorageCapacity} </Table.Data>
				</Table.Row>
				<Table.Row>
					<Table.Data className="table-keys">{localString("Date Of Purchase")}</Table.Data>
					<Table.Data>{moment(consumerProduct.DateOfPurchase).format('DD-MM-YYYY')}</Table.Data>
				</Table.Row>
				<Table.Row>
					<Table.Data className="table-keys">{localString("Service Location")}</Table.Data>
					<Table.Data>{consumerServiceRequest.servicelocation.ServiceLocationName?consumerServiceRequest.servicelocation.ServiceLocationName:''}, {consumerServiceRequest.servicelocation.Address?consumerServiceRequest.servicelocation.Address:''} {consumerServiceRequest.servicelocation.Landmark?consumerServiceRequest.servicelocation.Landmark:''}</Table.Data>
				</Table.Row>
				{
					(consumerServiceRequest.SalesChannelName || ((accessFn('ACCEPT_RETURN_DEVICE') && (consumerServiceRequest.DOAStatus == 'applicable' && !consumerServiceRequest.SalesChannelName)))) && 
					<Table.Row>
						<Table.Data className="table-keys">{localString("Sales Channel")}</Table.Data>
						{
							(consumerServiceRequest.SalesChannelName && !isSalesChannelEditable) &&
							<Table.Data>{consumerServiceRequest.SalesChannelName?consumerServiceRequest.SalesChannelName:''}
							{
								accessFn('ACCEPT_RETURN_DEVICE') && consumerServiceRequest.DOAStatus == 'applicable' && !isSalesChannelEditable && 
								<span className="glyphicon glyphicon-edit" style={{marginLeft:"5px"}} onClick={() => editSalesChannel(consumerServiceRequest.SalesChannelName)}></span>
							}
							</Table.Data>
						}
						{
							(accessFn('ACCEPT_RETURN_DEVICE') && ((consumerServiceRequest.DOAStatus == 'applicable' && !consumerServiceRequest.SalesChannelName) || isSalesChannelEditable)) &&
							<Table.Data>
								<Dropdown
									id="doa_sales_channel"
									classObject="form-control charges-form-input"
									value={salesChannelValue.channelName}
									options={salesChannel}
									handleOnChange={(data) => {
										setSalesChannelValue({
											...salesChannelValue,
											channelID: data.SalesChannelID,
											channelName: data.SalesChannelName
										})
									}}
									filterBy='value'
									showDownArrow={false}
								/>
								<Button 
									type="submit" 
									name="submit"
									id="cancelButton"
									text={localString("Save")}
									isDisabled={!salesChannelValue.channelID} 
									className="btn button-servify pull-right"
									handleOnClick={saveSalesChannel}
								/>
								{
									consumerServiceRequest.SalesChannelName && 
									<Button 
										type="submit" 
										name="cancelButton"
										id="cancelButton"
										text={localString("Cancel")}
										className="btn button-servify pull-right"
										handleOnClick={cancelSaveSalesChannel}
									/>
								}
								
								{/* <input class = "form-control charges-form-input" id = "doa_sales_channel"/>
								<input onclick = {saveSalesChannel} type="submit" name="submit" class="btn button-servify pull-right" value="Save" disabled = {!selectedSalesChannel}/>
								<input if = {consumerServiceRequest.SalesChannelName} onclick = {cancelSaveSalesChannel} type="submit" name="submit" class="btn button-servify pull-right" value="Cancel"/> */}
							</Table.Data>
						}
					</Table.Row>
				}
				{
					(checkReplacementMode() && consumerServiceRequest.SalesChannelName && !accessFn('READ_ONLY') && (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS'))) && 
					<Table.Row>
						<Table.Data className="table-keys">{localString("Device Replacement Mode")}</Table.Data>
						<Table.Data>
							{
								(doaOptions.indexOf(doaOptionsMapped.REPLACE_DEVICE) > -1) || (doaOptions.indexOf(doaOptionsMapped.NORMAL_FLOW) > -1 || doaOptions.length == 0) && 
								<label className = "check-label get-new-device">
									<Radio
										value="GetNewDevice"
										selectedValue={deviceReplacementMode}
										name="modeOfReturn"
										id="refund-radio"
										handleOnUpdate={(e) => {
											setDeviceReplacementMode(e.target.value)
										}}
										// isDisabled={(doaOptions.indexOf(doaOptionsMapped.NORMAL_FLOW) > -1 || doaOptions.length == 0) && disableGetProductRadio}
									></Radio>
									{/* <span className = {(doaOptions.indexOf(doaOptionsMapped.NORMAL_FLOW) > -1 || doaOptions.length == 0) && disableGetProductRadio?'disable-label': ''}>{localString("Get New Device")}</span> */}
									<span className ="">{localString("Get New Device")}</span>
								</label>
							}
							{
								doaOptions.indexOf(doaOptionsMapped.REFUND_DEVICE) > -1 && 
								<label className = "check-label">
									<Radio
										value="Refund"
										selectedValue={deviceReplacementMode}
										name="modeOfReturn"
										id="sale-channel-radio"
										handleOnUpdate={(e) => {
											setDeviceReplacementMode(e.target.value)
										}}
									>{localString('Refund')}</Radio>
								</label>
							}
							{
								doaOptions.indexOf(doaOptionsMapped.GENERATE_CERTIFICATE) > -1 || (doaOptions.indexOf(doaOptionsMapped.NORMAL_FLOW) > -1 || doaOptions.length == 0) && 
								<label className = "check-label">
									<Radio
										value="GenerateDOACertificate"
										selectedValue={deviceReplacementMode}
										name="modeOfReturn"
										id="sale-channel-radio"
										handleOnUpdate={(e) => {
											setDeviceReplacementMode(e.target.value)
										}}
									>{localString("Generate DOA Certificate")}</Radio>
								</label>
							}
							<Button 
								type="submit" 
								name="submitButton"
								id="saveButton"
								text={localString("Save")}
								className="btn button-servify pull-right"
								handleOnClick={saveReplacementMode}
							/>
						</Table.Data>
					</Table.Row>
				}
				{
					doaStatus == 'replacement' && (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS')) && isHanded && requestStatus == 'Work in progress' && 
					<Table.Row>
						<Table.Data className="table-keys">{localString("Choose Device")}</Table.Data>
						<Table.Data>
							<div className = "doa-device-div col-sm-8">
								<Dropdown
									id="doa-device-input"
									classObject="form-control filter-request-height"
									value={chooseReplaceDevice.Description}
									options={replacedProduct}
									handleOnChange={(data) => {
										setChooseReplaceDevice({...chooseReplaceDevice,
											Description:data.Description
										})
										setSelectedReplacedProduct(data)
									}}
									filterBy='value'
									showDownArrow={false}
								/>
							</div>
							<Button 
								type="submit" 
								name="submitButton"
								id="saveButton"
								text={localString("Save")}
								className={disableDOAInput? 'disable-btn btn button-servify float-right': 'btn button-servify float-right'}
								handleOnClick={orderReplacedPart}
								isDisabled = {disableDOAInput}
							/>
						</Table.Data>
					</Table.Row>
				}
				{
					((requestStatus =='Parts issued' && (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS'))) || (requestStatus == 'Parts request accepted' && accessFn('STORE_MANAGEMENT')))&& 
					<Table.Row>
						<Table.Data className="table-keys">{localString("Action on Device")}</Table.Data>
						<Table.Data>
							<div className = "col-sm-6 doa-product-name">{firstProduct && firstProduct.Name}</div>
							{
								requestStatus == 'Parts request accepted' && (accessFn('STORE_MANAGEMENT')) && 
								<label className = "check-label action-device">
									<Radio
										value="Issue"
										selectedValue="Issue"
										name="modeOfReturn"
										id="issue-radio"
									>{localString('Issue')}</Radio>
								</label>
							}
							{
								(requestStatus == 'Parts issued' && (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS')))&& 
								<label className = "check-label action-device">
									<Radio
										value="Receive"
										selectedValue="Receive"
										name="modeOfReturn"
										id="receive-radio"
									>{localString('Receive')}</Radio>
								</label>
							}
							<Button 
								type="submit" 
								name="submitButton"
								id="saveButton"
								text={localString("Save")}
								className="btn button-servify float-right"
								handleOnClick={(e)=>openModalProduct(e)}
							/>
						</Table.Data>
					</Table.Row>
				}
			</Table.Body>
		</Table>
	)
}

export default DeadOnArrivalTable
