import React, { useState,useEffect } from 'react';
import Swal from 'sweetalert2';
import { displayError } from '../../../../../Utility/Utils';
import {Dropdown,Input,Button} from 'sfy-react'
import {findIndex} from 'lodash'
const DocumentPendingInput=(props)=>{
    const {localString,apiCall,setLoader,consumerServiceRequest,getRequestById, setShowModal, showModal,getPendingDocuments}=props
    const [selectedDocuments,setSelectedDocuments]=useState([])
    const [document,setDocument]=useState([]);
    const [documentText,setDocumentText]=useState('');
    const [documentsToBeRequested,setDocumentsToBeRequested]=useState([]);
    useEffect(() => {
        loadMissingDocType()
    }, [])

    const addRemarksForDoc = (val,doc)=>{
        let localDocumentType = {} ;
        document && document.map((item,index)=>{
            if(doc.DocumentType==item.DocumentType){
                localDocumentType[doc.DocumentType] = val;
                setDocumentText(localDocumentType);
                if(val.length > 500) {
                    return true;
                } else {
                    documentsToBeRequested.map(data=>{
                        if(data.DocumentID==doc.DocumentID){
                            return data.Remark =  val
                        }
                    })
                    return true;
                }
            }
        })
    }
     
    const loadMissingDocType = ()=> {
        let data = {};
        setLoader(true);
        apiCall({
            url: "getDocuments", 
            data: data,
            method: 'GET',
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    let documentData=result.data && result.data.map(v=>({...v,value:v.DocumentType}))
                    setSelectedDocuments(documentData);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: localString(displayError(result)),
                    });
                }
            },
        });
    }

    const addMissingDoc = (e)=>{
        setDocument([...document,e])
        let newDoc = findIndex(documentsToBeRequested, [ 'DocumentID', e.DocumentID]);
        if (newDoc > -1) {
            Swal.fire('', localString("You have already added this document type in the list of pending documents"), 'error');
            return;
        }
        else {
            documentsToBeRequested.push({
                DocumentExtension: e.DocumentExtension,
                DocumentID: e.DocumentID,
                DocumentType: e.DocumentType,
                DocumentUrl: e.DocumentUrl,
                ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                IsPendingDocument: 1,
            });
        }
    }

    const deleteInput = (id) =>{
        let deleteArr = documentsToBeRequested && documentsToBeRequested.filter(item=>id !== item.DocumentID)
        setDocumentsToBeRequested(deleteArr);
    }

    const requestDocuments = function(e) {
        e.preventDefault();
        setLoader(true);
        let missingDoc =documentsToBeRequested; 
        if (missingDoc.length > 0) {
            let data = {
                "updatedData": missingDoc
            };
            apiCall({
                url: "createDocuments", 
                data: data,
                callBack: (result) => {
                    setLoader(false);
                    if (result.success) {
                        setShowModal({...showModal,showDocumentPendingModal:false});
                        getRequestById();
                        getPendingDocuments();
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: localString(displayError(result)),
                        });
                    }
                },
            });
        } else {
            setLoader(false);
            Swal.fire({
                title:    '',
                text: localString("Please enter atleast one document"),
                icon: 'error'
            });
        }
    };

    return(
        <>
            <div className='request-filter table-margin'>
                {/* <form className='document-form' onSubmit={(e)=>requestDocuments(e)}> */}
                <div className='document-form'>
                    <div className='documentRow'>
                        <Dropdown
                            id='dropdown'
                            options={selectedDocuments}
                            placeholder='Enter type of document to add'
                            handleOnChange={(item=>{
                                addMissingDoc(item)
                            })}
                            classObject={{
                                container: 'document-type-dropdown',
                            }} 
                            showDownArrow={false}
                            filterBy='value'
                        />
                    </div>
                    {
                        documentsToBeRequested.length > 0 &&
                        <div className='row distinct-document table-border'>
                            {
                                documentsToBeRequested.map((doc,index)=>(
                                    <div className='form-group col-sm-12'>
                                        <Button className="close" handleOnClick={(e)=>deleteInput(doc.DocumentID)}>&times;</Button>
                                            <Input
                                                value={documentText[doc.DocumentType]}
                                                key={index}
                                                id={'missingDocRemark'+index}
                                                handleOnChange={(e) => addRemarksForDoc(e.target.value,doc)}
                                                label={doc.DocumentType}
                                                labelAlign='top'
                                                autoComplete="false"
                                            />
                                    </div>
                                ))
                            }
                        </div>
                    }
                    <Button 
                        handleOnClick={(e)=> requestDocuments(e)} 
                        className='btn button-servify' 
                    >
                        {localString('Save')}
                    </Button>
                </div>
            </div>
        </>
    )
}
export default DocumentPendingInput;
