import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Swal from "sweetalert2"; 
import { Button, Dropdown, Modal, Input } from 'sfy-react';

import { displayError } from '../../../../../Utility/Utils';

export default function KbbUpdateModal(props) {
	const {
		apiCall, accessFn, localString, setLoader, showKbbUpdateModal, setShowKbbUpdateModal, partArr, setPartArr,
		updatePartStatus, 
	} = props;

	const [localPartArr, setLocalPartArr] = useState(partArr);

	const updateKBBNumber = () => {
		let error = false;
		let newSerialNumbers = [];	
		let partArray = _.cloneDeep(localPartArr);
		_.forEach(partArray, function(part, i) {
			if (part.action == 'consumed' && part.Serialized) {
				if (!part.newKbbSerialNumber || (part.newKbbSerialNumber && part.newKbbSerialNumber.length < 10)) {
					error = true;
				} else {
					if (part.newKbbSerialNumber != part.kbbSerialNumber) {
						part.kbbSerialNumber = part.newKbbSerialNumber;
						newSerialNumbers.push(part.newKbbSerialNumber);
					}
				}
			}
		});
		partArray = partArray.filter(a => delete a.newKbbSerialNumber);
		if (!error) {
			if (newSerialNumbers.length) {
				setLoader(true);
				let reqObj = {
					serialNumber: newSerialNumbers
				};
				apiCall({
					url: 'checkKBBSerialNumberExists',
					data: reqObj,
					callBack: (result) => {
						setLoader(false);
						if (result.success && result.data) {
							setShowKbbUpdateModal(false);
							updatePartStatus(partArray);
						} else {
							Swal.fire({
								icon: 'error',
								text: localString(displayError(result)),
							});
						}
					},
					errorCB: (err) => setLoader(false),
				});
			} else {
				setShowKbbUpdateModal(false);
				updatePartStatus(partArray);
			}
		} else {
			Swal.fire({
				icon: 'error',
				title: '',
				text: localString("Please enter correct KBB serial number of the part"),
				confirmButtonText: localString('OK')
			});
		}

	}

	useEffect(() => {
		if (showKbbUpdateModal) {
			let arr = partArr.map(part => {
				let obj = {...part};
				if (obj.kbbSerialNumber) {
					obj.newKbbSerialNumber = obj.kbbSerialNumber;
				}else {
					obj.newKbbSerialNumber = '';
				}
				return obj;
			})
			setLocalPartArr(arr);
		}
 	}, [showKbbUpdateModal]);

	return (
		<>
			<Modal
				className='kbbUpdateModal'
				showModal={showKbbUpdateModal}
				closeModal={() => setShowKbbUpdateModal(false)}
				showCloseBtn={true}
			>
				<Modal.Header>
					<h4 className="modal-title">{localString("Enter KBB Details")}</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-body">
						<div className="padding5">
							{
								localPartArr && localPartArr.length > 0 && localPartArr.map((part, k) => {
									if(part.action == 'consumed' && part.Serialized == 1) {
										return ( <>
											<div style={{ marginBottom: '10px' }}>{part.Name}</div>
											<div className="form-group">
												<Input
													value={part?.newKbbSerialNumber || ''}
													handleOnChange={(e) => {
														let arr = _.cloneDeep(localPartArr);
														arr[k].newKbbSerialNumber = e.target.value.trim();
														setLocalPartArr(arr);
													}}
													labelAlign='left'
													label={localString("KBB Number")}
													placeholder={localString('KBB Number')}
												/>
											</div>
										</>);
									}
								})
							}								
						</div>
						<div className='paddingB10'>
							<Button 
								className="btn button-servify pull-right" 
								onClick={() => updateKBBNumber(localPartArr)}
							>
								{localString("Save")}
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}