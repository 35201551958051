import React from 'react';
import {Modal, Button} from 'sfy-react';
import BillingDetailInput from './BillingDetailInput';
import './BillingDetailModal.scss';
const BillingDetailModal=(props)=>{
    const {localString, showModal, closeModal, processRequest, changeBillingDetails, claimRequestNonWorkshop, claimNewWorkshopChecks, capturePaymentMode, claimChecks} = props;
		return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal billingDetailsButtonModal'>
                <Modal.Header>
                    <h4 className="modal-title"> {localString("Billing Details")} </h4>
                </Modal.Header>
                <Modal.Body>
                    <BillingDetailInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{padding:"10px 10px", display:"inline-block"}}>
                        <Button className='btn button-servify'  handleOnClick = {()=>changeBillingDetails()} >{localString('Change Billing Address')}</Button>
                        {   claimChecks 
                            ?  <Button  className='btn button-servify'  handleOnClick = {(e)=>claimRequestNonWorkshop('Payment received')} >{localString('Continue')}</Button>
                            :  <Button  className='btn button-servify'  handleOnClick = {(e)=>claimNewWorkshopChecks ? capturePaymentMode() :processRequest('Generate Invoice')} name='Generate Invoice' >{localString('Continue')}</Button>
                        }
                    </div>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default  BillingDetailModal;