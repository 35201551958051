import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import { Button, DatePicker, Dropdown, Loader } from 'sfy-react';
import moment from 'moment';

import { displayError, getAbbrivatedZone } from '../../Utility/Utils';
import FilterModal from '../../components/FilterModal/FilterModal';
import { map, uniq, uniqBy } from 'lodash';

let defaultFilter = {
	PartnerServiceLocationIDs: '',
	// ServiceLocationEngineerIDs: '',
	OlcsmNames: '',
	PlanIDs: [],
	BrandIDs: '',
	ProductCategoryIDs: '',
	typeOfReport: '',
	VendorIDs: '',
	OrganizationOutletLocationIDs: '',
	ProductIDs: '',
	ServiceTypeIDs: '',
	RequestType: '',
	StatusIDs: '',
	DateFilter: '',
	SupportType: '',
	SubcategoryIDs: '',
	// PartnerIDs: '',
};

const defaultShowFilters = {
	reportType: false,
	test: false,
	PartnerServiceLocationIDs: false,
	OlcsmNames: false,
	PlanIDs: false,
	BrandIDs: false,
	typeOfReport: false,
	VendorIDs: false,
	OrganizationOutletLocationIDs: false,
	ProductIDs: false,
	ServiceTypeIDs: false,
	RequestType: false,
	StatusIDs: false,
	DateFilter: false,
	SupportType: false,
	SubcategoryIDs: false,
	// PartnerIDs: false,
};

const ReportFilters = (props) => {
	const {
		accessFn,
		localString,
		apiCall,
		fetchRecentReport,
		fetchReportList,
	} = props;
	const qFilter = JSON.parse(props.qFilter);
	const reportTypeIds = qFilter.reportTypes;
	const [mainFilter, setMainFilter] = useState(defaultFilter);
	const [mainFilterLabels, setMainFilterLabels] = useState(defaultFilter);
	const [filters, setFilters] = useState(defaultFilter);
	const [filterLabels, setFilterLabels] = useState(defaultFilter);
	const [showFilter, setShowFilter] = useState(defaultShowFilters);
	const [requiredFilter, setRequiredFilter] = useState(defaultShowFilters);
	const supportedFilters = [
		'UserID',
		'PartnerServiceLocationIDs',
		'OlcsmNames',
		'PlanIDs',
		'BrandIDs',
		'typeOfReport',
		'OrganizationOutletLocationIDs',
		'VendorIDs',
		'ProductIDs',
		'ServiceTypeIDs',
		'RequestType',
		'StatusIDs',
		'DateFilter',
		'SupportType',
		// 'PartnerIDs',
		'FromDate',
		'ToDate',
		'ScheduleFromDate',
		'ScheduleToDate',
		'SubcategoryIDs',
	];
	const [selectAll, setSelectAll] = useState(false);
	const [reportType, setReportType] = useState("");
	const [reportTypeObj, setReportTypeObj] = useState({});
	const [reportTypeOptions, setReportTypeOptions] = useState([]);
	const [debounceTimeout, setDebounceTimeout] = useState(false);
	const [mandateParams, setMandateParams] = useState([]);
	const [optionalParams, setOptionalParams] = useState([]);
	const [reportTypeLoader, setReportTypeLoader] = useState(false);

	const [PSLOptions, setPSLOptions] = useState([]);
	const [PSLObj, setPSLObj] = useState([]);
	const [PSLIds, setPSLIds] = useState([]);
	const [pslLoader, setPslLoader] = useState(false);
	const [getPartnerDetailsResults, setGetPartnerDetailsResults] = useState(
		[]
	);
	const [
		getPartnerServiceLocationsResults,
		setGetPartnerServiceLocationsResults,
	] = useState([]);
	const [partnerDetailsObj, setPartnerDetailsObj] = useState([]);

	const [serviceLocationOptions, setServiceLocationOptions] = useState([]);
	const [allServiceLocations, setAllServiceLocations] = useState([]);
	const [brandServiceLocations, setBrandServiceLocations] = useState([]);
	const [serviceLocationLoader, setServiceLocationLoader] = useState(false);

	const [planOptions, setPlanOptions] = useState([]);
	const [planLoader, setPlanLoader] = useState(false);

	const [requestTypeOptions, setRequestTypeOptions] = useState([
		{ data: 'In Progress', value: 'In Progress' },
		{ data: 'Cancelled', value: 'Cancelled' },
		{ data: 'Completed', value: 'Completed' },
	]);

	const [dateFilterOptions, setDateFilterOptions] = useState([
		{ data: 'DateOfPurchase', value: 'Plan Purchase Date' },
		{ data: 'CreatedDate', value: 'Plan Created Date' },
		{ data: 'UpdateDate', value: 'Plan Update Date' },
	]);

	const [supportTypeOptions, setSSupportTypeOptions] = useState([
		{ data: 'Claim', value: 'Claim' },
		{ data: 'Service', value: 'Service' },
	]);

	const [statusTypeOptions, setStatusTypeOptions] = useState([
		{ data: 'All', value: 'All' },
		{ data: 'Pickup Scheduled', value: 'Pickup Scheduled' },
		{ data: 'Request Scheduled', value: 'Request Scheduled' },
		{ data: 'Device with Logistics Partner', value: 'Device with Logistics Partner' },
		{ data: 'In-Transit', value: 'In-Transit' },
		{ data: 'Device Delivered', value: 'Device Delivered' },
		{ data: 'Out for Delivery', value: 'Out for Delivery' },
		{ data: 'Delivery Attempt Failed', value: 'Delivery Attempt Failed' },
		{ data: 'Pickup Attempt Failed', value: 'Pickup Attempt Failed' },
		{ data: 'Cancelled', value: 'Cancelled' },
		{ data: 'Pickup Attempt Failed - Customer not available', value: 'Pickup Attempt Failed - Customer not available' },
		{ data: 'Pickup Rescheduled', value: 'Pickup Rescheduled' },
		{ data: 'Pickup Attempt Failed - Customer Reschedule', value: 'Pickup Attempt Failed - Customer Reschedule' },
		{ data: 'Pickup Attempt Failed - Address/Mobile Incorrect', value: 'Pickup Attempt Failed - Address/Mobile Incorrect' },
		{ data: 'Device Delivered to Hub', value: 'Device Delivered to Hub' },
		{ data: 'No-Info', value: 'No-Info' },
		{ data: 'Pickup Attempt Failed - Cancelled By Customer', value: 'Pickup Attempt Failed - Cancelled By Customer' },
		{ data: 'Return To Shipper', value: 'Return To Shipper' },
		{ data: 'Delivery Attempt Failed - Address/Mobile Incorrect', value: 'Delivery Attempt Failed - Address/Mobile Incorrect' },
		{ data: 'Delivery Attempt Failed - Customer not available', value: 'Delivery Attempt Failed - Customer not available' },
		{ data: 'Delivery Attempt Failed - Refused by customer', value: 'Delivery Attempt Failed - Refused by customer' },
		{ data: 'Delivered to UPS Access Point', value: 'Delivered to UPS Access Point' },
	]);

	const [brandOptions, setBrandOptions] = useState([]);
	const [brandLoader, setBrandLoader] = useState(false);

	const [olcsmNamesOptions, setOlcsmNamesOptions] = useState([]);
	const [olcsmNamesLoader, setOlcsmNamesLoader] = useState(false);

	const [typeOfReportFilterOptions, settypeOfReportFilterOptions] = useState(['Pending', 'Quality', 'Service', 'Defective',]);

	const [vendorOptions, setVendorOptions] = useState([]);
	const [vendorLoader, setVendorLoader] = useState(false);

	const [organizationOutletOptions, setOrganizationOutletOptions] = useState([]);
	const [organizationOutletLoader, setOrganizationOutletLoader] = useState(false);

	const [productOptions, setProductOptions] = useState([]);
	const [productLoader, setProductLoader] = useState(false);

	const [subcategoryOptions, setSubcategoryOptions] = useState([]);
	const [subcategoryLoader, setSubcategoryLoader] = useState(false);

	const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
	const [serviceTypeLoader, setServiceTypeLoader] = useState(false);

	// const [partnerOptions, setPartnerOptions] = useState([]);
	// const [partnerLoader, setPartnerLoader] = useState(false);

	const [displayDateRangeFilter, setDisplayDateRangeFilter] = useState(false);
	const [disableApplyButton, setDisableApplyButton] = useState(true);
	const [disableDownloadButton, setDisableDownloadButton] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [dateRange, setDateRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
	});
	const [tempDateRange, setTempDateRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
	});
	const defaultMinDate = new Date("01-01-2000");
	const [minDate, setMinDate] = useState(defaultMinDate);
	const [maxDate, setMaxDate] = useState(new Date());

	useEffect(() => {
		if(tempDateRange?.startDate && tempDateRange?.endDate) {
			const date1 = new Date(tempDateRange?.startDate);
			const date2 = new Date(tempDateRange?.startDate);
			const maxDateVal = new Date(date1.setMonth(date1.getMonth() + 3));
			const minDateVal = new Date(date2.setMonth(date2.getMonth() - 3));
			setMinDate(minDateVal);
			if(new Date(maxDateVal).getTime() > new Date().getTime()) {
				setMaxDate(new Date());
			}else {
				setMaxDate(maxDateVal);
			}
		}else {
			setMinDate(new Date(defaultMinDate));
			setMaxDate(new Date());
		}
	}, [tempDateRange?.startDate, tempDateRange?.endDate]);

	const applyBtnCheck = () => {
		let disable = false;
		mandateParams.map((dataKey) => {
			if (filters.hasOwnProperty(dataKey) && !filters[dataKey])
				disable = true;
		});
		setDisableApplyButton(disable);
		setDisableDownloadButton(disable);
	};

	useEffect(() => {
		applyBtnCheck();
	}, [reportType, filters]);

	const CustomOptionComponent = useCallback(
		(props) => (
			<span className='custom-dropdown-option'>
				{ props.option.ImageUrl && <img src={props.option.ImageUrl} alt='' width='20px' height='20px'/>}
				{props.option.value}
			</span>
		),
		[]
	)

	// All the filters list
	let filterInputList = [
		{
			labelText: localString('Subcategory'),
			dataKey: "SubcategoryIDs",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: showFilter.SubcategoryIDs,
			type: 'Dropdown',
			required: requiredFilter.SubcategoryIDs,
			dropdownList: subcategoryOptions,
			oninputFunc: (val) => {
				setFilterLabels({ ...filterLabels, SubcategoryIDs: val.ProductSubCategory, BrandIDs: "", ProductIDs: "" });
				setFilters({ ...filters, SubcategoryIDs: [val.ProductSubCategoryID], BrandIDs: "", ProductIDs: "" });
			},
		},
		{
			labelText: localString('Brand'),
			dataKey: "BrandIDs",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: showFilter.BrandIDs,
			type: 'Dropdown',
			required: requiredFilter.BrandIDs,
			dropdownList: brandOptions,
			oninputFunc: (val) => {
				setFilterLabels({ ...filterLabels, BrandIDs: val.BrandName, PartnerServiceLocationIDs: "", ProductIDs: "" });
				setFilters({ ...filters, BrandIDs: [val.BrandID], PartnerServiceLocationIDs: "", ProductIDs: "" });
			},
		},
		{
			labelText: localString('Product'),
			dataKey: "ProductIDs",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: showFilter.ProductIDs,
			type: 'Dropdown',
			required: requiredFilter.ProductIDs,
			dropdownList: productOptions,
			oninputFunc: (val) => {
				setFilterLabels({ ...filterLabels, ProductIDs: val.value });
				setFilters({ ...filters, ProductIDs: [val.ProductID] });
			},
			isDisabled:( !filters.SubcategoryIDs?.length || !filters.BrandIDs?.length),
			// placeholder: localString('Please select subcategory and brand'),
			// handleOnSearch: (value) => {
			// 	if (value.length > 2) {
			// 		debounceTimeout && clearTimeout(debounceTimeout);
			// 		setDebounceTimeout(
			// 			setTimeout(() => {
			// 				GetProducts(value);
			// 			}, 400)
			// 		);
			// 	}
			// 	return productOptions;
			// },
		},
		{
			labelText: localString('Service Location'),
			dataKey: "PartnerServiceLocationIDs",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: showFilter.PartnerServiceLocationIDs,
			type: 'Dropdown',
			required: requiredFilter.PartnerServiceLocationIDs,
			// dropdownList: PSLOptions,
			dropdownList: serviceLocationOptions,
			oninputFunc: (val) => {
				setPSLObj(val);
				setFilterLabels({ ...filterLabels, PartnerServiceLocationIDs: val.ServiceLocationName, });
				setFilters({ ...filters, PartnerServiceLocationIDs: [val.PartnerServiceLocationID], });
				// if(mandateParams.includes('PartnerServiceLocationIDs') || optionalParams.includes('PartnerServiceLocationIDs')) {
				// 	loadPartner();
				// }
			},
			// searchText: pslSearchText,
			// handleOnSearch: (value) => {
			// 	if (value.length > 2) {
			// 		debounceTimeout && clearTimeout(debounceTimeout);
			// 		setDebounceTimeout(
			// 			setTimeout(() => {
			// 				getPartnerServiceLocations(value);
			// 			}, 400)
			// 		);
			// 	}
			// 	return PSLOptions;
			// },
		},
		{
			labelText: localString('Type of Report'),
			dataKey: "typeOfReport",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: showFilter.typeOfReport,
			type: 'Dropdown',
			required: requiredFilter.typeOfReport,
			dropdownList: typeOfReportFilterOptions,
			oninputFunc: (val) => {
				setFilterLabels({ ...filterLabels, typeOfReport: val });
				setFilters({ ...filters, typeOfReport: val });
			},
		},
		{
			labelText: localString('OLCSM'),
			dataKey: "OlcsmNames",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: showFilter.OlcsmNames,
			type: 'Dropdown',
			required: requiredFilter.OlcsmNames,
			dropdownList: olcsmNamesOptions,
			oninputFunc: (val) => {
				setFilterLabels({ ...filterLabels, OlcsmNames: val.Name });
				setFilters({ ...filters, OlcsmNames: val.Name });
			},
		},
		{
			labelText: localString('Request Type'),
			dataKey: "RequestType",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: showFilter.RequestType,
			type: 'Dropdown',
			required: requiredFilter.RequestType,
			dropdownList: requestTypeOptions,
			oninputFunc: (val) => {
				setFilterLabels({ ...filterLabels, RequestType: val.value });
				setFilters({ ...filters, RequestType: [val.value] });
			},
		},
		{
			labelText: localString('Date Filter'),
			dataKey: "DateFilter",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: showFilter.DateFilter,
			type: 'Dropdown',
			required: requiredFilter.DateFilter,
			dropdownList: dateFilterOptions,
			oninputFunc: (val) => {
				setFilterLabels({ ...filterLabels, DateFilter: val.value });
				setFilters({ ...filters, DateFilter: val.data });
			},
		},
		{
			labelText: localString('Support Type'),
			dataKey: "SupportType",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: showFilter.SupportType,
			type: 'Dropdown',
			required: requiredFilter.SupportType,
			dropdownList: supportTypeOptions,
			oninputFunc: (val) => {
				setFilterLabels({ ...filterLabels, SupportType: val.value });
				setFilters({ ...filters, SupportType: val.value });
			},
		},
		{
			labelText: localString('Status Type'),
			dataKey: "StatusIDs",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: showFilter.StatusIDs,
			type: 'Dropdown',
			required: requiredFilter.StatusIDs,
			dropdownList: statusTypeOptions,
			oninputFunc: (val) => {
				setFilterLabels({ ...filterLabels, StatusIDs: val.value });
				setFilters({ ...filters, StatusIDs: [val.value] });
			},
		},
		{
			labelText: localString('Logistics Vendor'),
			dataKey: "VendorIDs",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: showFilter.VendorIDs,
			type: 'Dropdown',
			required: requiredFilter.VendorIDs,
			dropdownList: vendorOptions,
			oninputFunc: (val) => {
				setFilterLabels({ ...filterLabels, VendorIDs: val.value });
				// setFilters({ ...filters, VendorIDs: [val.PlanID] });
				setFilters({ ...filters, VendorIDs: [val.value] });
			},
		},
		{
			labelText: localString('Organization Outlet Locations'),
			dataKey: "OrganizationOutletLocationIDs",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: showFilter.OrganizationOutletLocationIDs,
			type: 'Dropdown',
			required: requiredFilter.OrganizationOutletLocationIDs,
			dropdownList: organizationOutletOptions,
			oninputFunc: (val) => {
				setFilterLabels({ ...filterLabels, OrganizationOutletLocationIDs: val.value, });
				setFilters({ ...filters, OrganizationOutletLocationIDs: [val.OrganizationOutletLocationID,], });
			},
		},
		{
			labelText: localString('Service Type'),
			dataKey: "ServiceTypeIDs",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: showFilter.ServiceTypeIDs,
			type: 'Dropdown',
			required: requiredFilter.ServiceTypeIDs,
			dropdownList: serviceTypeOptions,
			oninputFunc: (val) => {
				// Todo
				setFilterLabels({ ...filterLabels, ServiceTypeIDs: val.value });
				setFilters({ ...filters, ServiceTypeIDs: [val.ServiceTypeID] });
			},
		},
		{
			labelText: localString('Plan Name'),
			dataKey: "PlanIDs",
			customClass: 'col-sm-12 col-md-6 paddingB10',
			showFilter: showFilter.PlanIDs,
			type: 'MultiSelect',
			required: requiredFilter.PlanIDs,
			dropdownList: [{ PlanID: 'selectAll', value: 'Select All' }, ...planOptions],
			uniqueKey: 'PlanID',
			// oninputFunc: (val) => {
			// 	setFilterLabels({ ...filterLabels, PlanIDs: val.value });
			// 	setFilters({ ...filters, PlanIDs: [val.PlanID] });
			// },
			onSelect: (val) => {
				if(val.PlanID === 'selectAll') {
					setFilterLabels({ ...filterLabels, PlanIDs: [val] })
					setFilters({ ...filters, PlanIDs: planOptions.map(a => a.PlanID) })
					setSelectAll(true);
				}else if(selectAll){
					setFilterLabels({ ...filterLabels, PlanIDs: [val] })
					setFilters({ ...filters, PlanIDs: [val.PlanID] })
					setSelectAll(false);                 
				}else {
					setFilterLabels({ ...filterLabels, PlanIDs: [...filterLabels.PlanIDs, val] })
					setFilters({ ...filters, PlanIDs: [...filters.PlanIDs, val.PlanID] })
					setSelectAll(false);
				}
			},
			onRemove: function(e) {
				let filterarr = filters.PlanIDs.filter(k => k.toString() !== e.target.id.toString());
				let filterLabelarr = filterLabels.PlanIDs.filter(k => k.PlanID.toString() !== e.target.id.toString());
				e.target.id.toString() === 'selectAll' && setSelectAll(false);
				setFilterLabels({ ...filterLabels, PlanIDs: filterLabelarr }); 
				setFilters({ ...filters, PlanIDs: filterarr });
			},
			onClearAll: () => {
				setFilterLabels({ ...filterLabels, PlanIDs: [] })
				setFilters({ ...filters, PlanIDs: [] })
				setSelectAll(false); 
			},
			isDisabled: !planOptions.length,
			OptionsComponent: CustomOptionComponent
		},
		// {
		// 	labelText: localString('Partner'),
		// 	dataKey: "PartnerIDs",
		// 	customClass:'col-sm-12 col-md-6 paddingB10',
		// 	showFilter: showFilter.PartnerIDs,
		// 	type: 'Dropdown',
		// 	required: requiredFilter.PartnerIDs,
		// 	dropdownList: partnerOptions,
		// 	oninputFunc: (val) => {
		// 		// Todo
		// 		setFilterLabels({ ...filterLabels, PartnerIDs: val.value })
		// 		setFilters({ ...filters, PartnerIDs: [val.PartnerID] })
		// 	}
		// }
	];

	const updateMainFilters = (e) => {
		e.preventDefault();
		setMainFilter(filters);
		setMainFilterLabels(filterLabels);
		setShowModal(false);
	};

	const handleReportTypeChange = (data) => {
		let params = data.Params;
		let mandatory = params && params.mandatory ? params.mandatory : [];
		let optional = params && params.optional ? params.optional : [];
		let filterObj = { ...showFilter };
		Object.keys(showFilter).map((dataKey) => {
			if (mandatory.includes(dataKey)) {
				filterObj[dataKey] = true;
			} else if (optional.includes(dataKey)) {
				filterObj[dataKey] = true;
			} else {
				filterObj[dataKey] = false;
			}
		});
		let requiredObj = { ...defaultShowFilters };
		let obj = {
			PlanIDs : 'plans',
			BrandIDs: 'brands',
			PartnerServiceLocationIDs: 'serviceLocations',
			ServiceTypeIDs: 'servicetypes',
		}
		Object.keys(requiredFilter).map((dataKey) => {
			if (mandatory.includes(dataKey) && qFilter[obj[dataKey]] && (qFilter[obj[dataKey]]).length) {
				requiredObj[dataKey] = false;
			} else if (mandatory.includes(dataKey)) {
				requiredObj[dataKey] = true;
			} else {
				requiredObj[dataKey] = false;
			}
		});
		if ((mandatory && (mandatory.includes('FromDate') || mandatory.includes('ToDate') || mandatory.includes('ScheduleFromDate') || mandatory.includes('ScheduleToDate'))) || (optional && (optional.includes('FromDate') || optional.includes('ToDate') || optional.includes('ScheduleFromDate') || optional.includes('ScheduleToDate')))) {
			setDisplayDateRangeFilter(true);
		} else {
			setDisplayDateRangeFilter(false);
		}
		if ((mandatory && mandatory.includes('PartnerServiceLocationIDs')) || (optional && optional.includes('PartnerServiceLocationIDs'))) {
			setServiceLocationOptions(PSLOptions);
		}
		setShowFilter(filterObj);
		setFilters(defaultFilter);
		setFilterLabels(defaultFilter);
		setMainFilter(defaultFilter);
		setMainFilterLabels(defaultFilter);
		setRequiredFilter(requiredObj);
		setMandateParams(mandatory);
		setOptionalParams(optional);
		setReportType(data.Name);
		setReportTypeObj(data);
	};

	const getPartnerServiceLocations = (value) => {
		let reqObj = {};
		setPslLoader(true);
		apiCall({
			url: 'getPartnerServiceLocationIDs',
			data: reqObj,
			callBack: (result) => {
				setPslLoader(false);
				if (result.success && result.data) {
					let arr = result.data.map((v) => ({ ...v, value: v.ServiceLocationName, }));
					let ids = result.data.map((v) => v.PartnerServiceLocationID);
					setPSLIds(ids);
					setPSLOptions(arr);
					setServiceLocationOptions(arr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setPslLoader(false),
		});
	};

	// useEffect(() => {
	//     if(reportType && showFilter.PartnerServiceLocationIDs && PSLOptions.length === 0) {
	//         getPartnerServiceLocations();
	//     }
	// }, [reportType])
	useEffect(() => {
		getPartnerServiceLocations();
	}, []);

	const fetchLocations = () => {
		setServiceLocationLoader(true);
		let reqObj = {};
		if (qFilter.serviceLocations) {
			if (qFilter.serviceLocations.length > 0) {
				reqObj.serviceLocations = qFilter.serviceLocations;
			}
		}
		if (showFilter['BrandIDs'] && filters.BrandIDs) {
			reqObj.brands = filters.BrandIDs;
		}
		apiCall({
			url: 'fetchLocations',
			data: reqObj,
			callBack: (result) => {
				setServiceLocationLoader(false);
				if (result.success && result.data) {
					let serviceLocationArr = result.data;
					setAllServiceLocations(serviceLocationArr);
					let uniqueServiceLocation = [];
					if (serviceLocationArr && serviceLocationArr.length > 0) {
						let serviceLocations = map(serviceLocationArr, "PartnerServiceLocationID");
						uniqueServiceLocation = uniq(serviceLocations);
					}
					setPSLIds(uniqueServiceLocation);
					let serviceLocationsArray = [];
					PSLOptions &&
						PSLOptions.map((o) => {
							uniqueServiceLocation.map((a) => {
								if (o.PartnerServiceLocationID == a) {
									serviceLocationsArray.push(o);
								}
							});
						});
					setServiceLocationOptions(serviceLocationsArray);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setServiceLocationLoader(false),
		});
	};

	useEffect(() => {
		if (reportType && showFilter.PartnerServiceLocationIDs && filters.BrandIDs) {
			fetchLocations();
		}
	}, [filters.BrandIDs]);

	const getPlanIDs = () => {
		setPlanLoader(true);
		let reqObj = {
			ExcludingField: {
				FieldType: "BrandID",
				FieldArray: qFilter.brands && qFilter.brands.length > 0 ? qFilter.brands : undefined,
			},
			CountryCode: qFilter.countrycode ? qFilter.countrycode[0] : undefined,
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp",
			inlcudeInactivePlans: true,
		};
		apiCall({
			url: 'getPlanIDs',
			data: reqObj,
			callBack: (result) => {
				setPlanLoader(false);
				if (result.success && result.data) {
					let arr = result.data.map((v) => ({ ...v, value: v.PlanCode + " - " + v.PlanDisplayName, }));
					// arr.unshift({ PlanID: 'selectAll', value: 'Select All' });
					let uniqArr = uniqBy(arr, 'PlanID');
					setPlanOptions(uniqArr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setPlanLoader(false),
		});
	};

	useEffect(() => {
		if (reportType && showFilter.PlanIDs && planOptions.length === 0) {
			getPlanIDs();
		}
	}, [reportType]);

	const getBrandIDs = () => {
		setBrandLoader(true);
		let reqObj = {};
		reqObj.qFilter = {};
		if (qFilter.brands) {
			reqObj.qFilter.brands = qFilter.brands;
		}
		if (filters?.SubcategoryIDs?.length) {
			reqObj.qFilter.productSubCategories = filters.SubcategoryIDs;
		}

		apiCall({
			url: 'getBrandIDs',
			data: reqObj,
			callBack: (result) => {
				setBrandLoader(false);
				if (result.success && result.data) {
					let arr = result.data.map((v) => ({ ...v, value: v.BrandName, }));
					// if(selectAll) {
					// 	setSelectAll(false);
					// 	setFilters({ ...filters, BrandIDs: result.data.map(val => ({...val, value: val.BrandName})) })
					// }
					setBrandOptions(arr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setBrandLoader(false),
		});
	};

	useEffect(() => {
		if (reportType && showFilter.BrandIDs && (showFilter.SubcategoryIDs || (brandOptions.length === 0))) {
			getBrandIDs();
		}
	}, [reportType, filters.SubcategoryIDs]);

	const getOlcsmNames = () => {
		setOlcsmNamesLoader(true);
		let reqObj = {};
		if (filters['PartnerServiceLocationIDs'] && filters['PartnerServiceLocationIDs'].length > 0) {
			reqObj.serviceLocations = filters['PartnerServiceLocationIDs'];
		} else {
			reqObj.serviceLocations = qFilter.serviceLocations;
		}
		apiCall({
			url: 'getOlcsmNames',
			data: reqObj,
			callBack: (result) => {
				setOlcsmNamesLoader(false);
				if (result.success && result.data) {
					let arr = result.data.map((v) => ({ ...v, value: v.Name }));
					setOlcsmNamesOptions(arr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setOlcsmNamesLoader(false),
		});
	};

	useEffect(() => {
		if (reportType && showFilter.OlcsmNames) {
			getOlcsmNames();
		}
	}, [reportType, filters.PartnerServiceLocationIDs]);

	const getVendorList = () => {
		setVendorLoader(true);
		let reqObj = {};
		apiCall({
			url: 'getVendorArray',
			data: reqObj,
			callBack: (result) => {
				setVendorLoader(false);
				if (result.success && result.data) {
					let arr = result.data.map((v) => ({ ...v, value: v.Vendor, }));
					setVendorOptions(arr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setVendorLoader(false),
		});
	};

	useEffect(() => {
		if (reportType && showFilter.VendorIDs && vendorOptions.length === 0) {
			getVendorList();
		}
	}, [reportType]);

	const getOrganizationOutletLocationList = () => {
		setOrganizationOutletLoader(true);
		let reqObj = {};
		apiCall({
			url: 'getOrganizationOutletLocationList',
			data: reqObj,
			callBack: (result) => {
				setOrganizationOutletLoader(false);
				if (result.success && result.data) {
					let arr = result.data?.organizationOutletLocations?.map((v) => ({ ...v, value: v.Name }));
					setOrganizationOutletOptions(arr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setOrganizationOutletLoader(false),
		});
	};

	useEffect(() => {
		if (reportType && showFilter.OrganizationOutletLocationIDs && organizationOutletOptions.length === 0) {
			getOrganizationOutletLocationList();
		}
	}, [reportType]);

	const getProducts = () => {
		setProductLoader(true);
		let reqObj = {
			qFilter: {
				brands: filters.BrandIDs,
				productSubCategories: filters.SubcategoryIDs
			}
		};
		apiCall({
			url: 'GetProducts',
			data: reqObj,
			callBack: (result) => {
				setProductLoader(false);
				if (result.success && result.data) {
					let arr = result.data.map((v) => ({ ...v, value: v.ProductName, }));
					setProductOptions(arr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setProductLoader(false),
		});
	};

	useEffect(() => {
		if (reportType && showFilter.ProductIDs && filters?.BrandIDs?.length && filters?.SubcategoryIDs?.length) {
			getProducts();
		}
	}, [reportType, filters.BrandIDs]);

	const getSubcategory = () => {
		setSubcategoryLoader(true);
		let reqObj = {};
		apiCall({
			url: 'getSubcategory',
			data: reqObj,
			callBack: (result) => {
				setSubcategoryLoader(false);
				if (result.success && result.data) {
					let arr = result.data.map((v) => ({ ...v, value: v.ProductSubCategory, }));
					setSubcategoryOptions(arr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setSubcategoryLoader(false),
		});
	};

	useEffect(() => {
		if (reportType && showFilter.SubcategoryIDs && subcategoryOptions.length === 0) {
			getSubcategory();
		}
	}, [reportType]);

	const getServiceTypes = () => {
		setServiceTypeLoader(true);
		let reqObj = {};
		apiCall({
			url: 'getAllServiceTypes',
			data: reqObj,
			callBack: (result) => {
				setServiceTypeLoader(false);
				// Todo
				if (result.success && result.data) {
					let arr = result.data.map((v) => ({ ...v, value: v.Type }));
					setServiceTypeOptions(arr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setServiceTypeLoader(false),
		});
	};

	useEffect(() => {
		if (reportType && showFilter.ServiceTypeIDs && serviceTypeOptions.length === 0) {
			getServiceTypes();
		}
	}, [reportType]);

	// Commented handling of report filter PartnerIDs
	// const loadPartner = () => {
	// 	if(filters.PartnerServiceLocationIDs) {
	// 		if(qFilter.partners && qFilter.partners.length) {
	// 			let partnerDetails = getPartnerDetailsResults;
	// 			partnerDetails && partnerDetails.map(a => {
	// 				if(a.PartnerID == PSLObj.PartnerID) {
	// 					setPartnerDetailsObj([a]);
	// 					setPartnerOptions([a]);
	// 				}
	// 			});
	// 		} else {
	// 			let partnerServiceLocationDetails = getPartnerServiceLocationsResults;
	// 			let partnerDetails = getPartnerDetailsResults;
	// 			partnerServiceLocationDetails && partnerServiceLocationDetails.map(a => {
	// 				if(a.PartnerServiceLocationID == filters.PartnerServiceLocationIDs) {
	// 					partnerDetails && partnerDetails.map(b => {
	// 						if(a.PartnerID == b.PartnerID) {
	// 							setPartnerDetailsObj([b])
	// 							setPartnerOptions([b]);
	// 						}
	// 					})
	// 				}
	// 			})
	// 		}
	// 	} else {
	// 		fetchPartnerServiceLocationDetails();
	// 	}
	// }

	// useEffect(() => {
	// 	if(reportType && showFilter.PartnerIDs) {
	// 		loadPartner();
	// 	}
	// }, [reportType]);

	// const fetchPartnerServiceLocationDetails = () => {
	// 	if(qFilter.partners && qFilter.partners.length) {
	// 		fetchPartnerDetails(qFilter.partners);
	// 	} else{
	// 		setPslLoader(true);
	// 		let reqObj = {};
	// 		if(qFilter.serviceLocations && qFilter.serviceLocations.length) {
	// 			reqObj.PartnerServiceLocationID = qFilter.serviceLocations;
	// 		}else {
	// 			reqObj.PartnerServiceLocationID = PSLIds;
	// 		}
	// 		apiCall({
	// 			url: 'serviceCenterList',
	// 			data: reqObj,
	// 			callBack: (result) => {
	// 				setPslLoader(false)
	// 				if(result.success) {
	// 					if(result.data && result.data.length) {
	// 						let pslList = result.data;
	// 						setGetPartnerServiceLocationsResults(pslList);
	// 						let partnerIDsArray = pslList.map(a => a.PartnerID);
	// 						let uniqPartnerIDsArray = uniq(partnerIDsArray);
	// 						fetchPartnerDetails(uniqPartnerIDsArray);
	// 					}else {
	// 						Swal.fire({
	// 							icon: 'info',
	// 							text: localString('No partner service location details found'),
	// 						});
	// 					}
	// 				}else{
	// 					Swal.fire({
	// 						icon: 'error',
	// 						text: localString(displayError(result)),
	// 					});
	// 				}
	// 			},
	// 			errorCB: (err) => setPslLoader(false)
	// 		});
	// 	}
	// }

	// const fetchPartnerDetails = (parameters) => {
	// 	setPartnerLoader(true);
	// 	let reqObj = {};
	// 	reqObj.PartnerIDs = parameters;
	// 	apiCall({
	// 		url: 'getPartnerDetails',
	// 		data: reqObj,
	// 		callBack: (result) => {
	// 			setPartnerLoader(false)
	// 			// Todo
	// 			if(result.success) {
	// 				if(result.data && result.data.length) {
	// 					setGetPartnerDetailsResults(result.data);
	// 					setPartnerOptions(result.data);
	// 				}else {
	// 					Swal.fire({
	// 						icon: 'info',
	// 						text: localString('No partner service location details found'),
	// 					});
	// 				}
	// 			}else{
	// 				Swal.fire({
	// 					icon: 'error',
	// 					text: localString(displayError(result)),
	// 				});
	// 			}
	// 		},
	// 		errorCB: (err) => setPartnerLoader(false)
	// 	});
	// }

	const getReportsType = () => {
		setReportTypeLoader(true);
		apiCall({
			url: 'getReportType',
			callBack: (result) => {
				setReportTypeLoader(false);
				if (result.success && result.data) {
					let reportTypeArr = result.data.filter((v) => {
						if (v.Active && !v.isScheduleReport && reportTypeIds.includes(v.ReportTypeID)) {
							let obj = v;
							obj.value = localString(v.Name);
							return obj;
						}
					});
					// let obj = { value: 'Select Option', Stub: '' }
					// reportTypeArr.unshift(obj)
					setReportTypeOptions(reportTypeArr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setReportTypeLoader(false),
		});
	};

	useEffect(() => {
		getReportsType();
	}, []);

	const updateDownloadReportPayload = (payload) => {
		let reqObj = payload;
		//TODO: Crate common function to construct request body.
		if (mandateParams.includes('FromDate') || optionalParams.includes('FromDate')) {
			reqObj.FromDate = moment(new Date(dateRange.startDate)).format("YYYY-MM-DD");
		}

		if (mandateParams.includes('ToDate') || optionalParams.includes('ToDate')) {
			reqObj.ToDate = moment(new Date(dateRange.endDate)).format("YYYY-MM-DD");
		}

		if (mandateParams.includes('PartnerServiceLocationIDs') || optionalParams.includes('PartnerServiceLocationIDs')) {
			if (mainFilter['PartnerServiceLocationIDs'] && mainFilter['PartnerServiceLocationIDs'].length) {
				reqObj.PartnerServiceLocationIDs = mainFilter['PartnerServiceLocationIDs'];
			}
		}

		if (mandateParams.includes('BrandIDs') || optionalParams.includes('BrandIDs')) {
			if (mainFilter['BrandIDs'] && mainFilter['BrandIDs'].length) {
				reqObj.BrandIDs = mainFilter['BrandIDs'];
			}
		}

		if (mandateParams.includes('PlanIDs') || optionalParams.includes('PlanIDs')) {
			if (mainFilter['PlanIDs'] && mainFilter['PlanIDs'].length) {
				reqObj.PlanIDs = mainFilter['PlanIDs'];
			}
		}

		if (mandateParams.includes('RequestType') || optionalParams.includes('RequestType')) {
			if (mainFilter['RequestType']) {
				reqObj.RequestType = mainFilter['RequestType'];
			}
		}

		if (mandateParams.includes('DateFilter') || optionalParams.includes('DateFilter')) {
			if (mainFilter['DateFilter']) {
				reqObj.DateFilter = mainFilter['DateFilter'];
			}
		}

		if (mandateParams.includes('SupportType') || optionalParams.includes('SupportType')) {
			if (mainFilter['SupportType']) {
				reqObj.SupportType = mainFilter['SupportType'];
			}
		}

		if (mandateParams.includes('StatusIDs') || optionalParams.includes('StatusIDs')) {
			if (mainFilter['StatusIDs']) {
				reqObj.StatusIDs = mainFilter['StatusIDs'];
			}
		}

		if (mandateParams.includes('SubcategoryIDs') || optionalParams.includes('SubcategoryIDs')) {
			if (mainFilter['SubcategoryIDs']) {
				reqObj.SubcategoryIDs = mainFilter['SubcategoryIDs'];
			}
		}

		if (mandateParams.includes('ProductIDs') || optionalParams.includes('ProductIDs')) {
			if (mainFilter['ProductIDs']) {
				reqObj.ProductIDs = mainFilter['ProductIDs'];
			}
		}

		if (mandateParams.includes('typeOfReport') || optionalParams.includes('typeOfReport')) {
			if (mainFilter['typeOfReport']) {
				reqObj.typeOfReport = mainFilter['typeOfReport'];
			}
		}

		if (mandateParams.includes('OlcsmNames') || optionalParams.includes('OlcsmNames')) {
			if (mainFilter['OlcsmNames']) {
				reqObj.OlcsmNames = mainFilter['OlcsmNames'];
			}
		}

		if (mandateParams.includes('VendorIDs') || optionalParams.includes('VendorIDs')) {
			if (mainFilter['VendorIDs']) {
				reqObj.VendorIDs = mainFilter['VendorIDs'];
			}
		}

		if (mandateParams.includes('OrganizationOutletLocationIDs') || optionalParams.includes('OrganizationOutletLocationIDs')) {
			if (mainFilter['OrganizationOutletLocationIDs']) {
				reqObj.OrganizationOutletLocationIDs = mainFilter['OrganizationOutletLocationIDs'];
			}
		}

		if (mandateParams.includes('ServiceTypeIDs') || optionalParams.includes('ServiceTypeIDs')) {
			if (mainFilter['ServiceTypeIDs']) {
				reqObj.ServiceTypeIDs = mainFilter['ServiceTypeIDs'];
			}
		}

		// if(mandateParams.includes('PartnerIDs') || optionalParams.includes('PartnerIDs')) {
		// 	let partnerIDs = [];
		// 	if(mainFilter['PartnerIDs']) {
		// 		reqObj.PartnerIDs = mainFilter['PartnerIDs'];
		// 	}else {
		// 		if(mainFilter['PartnerServiceLocationIDs']) {
		// 			reqObj.PartnerIDs = partnerDetailsObj.map(a => a.PartnerID);
		// 		}else {
		// 			reqObj.PartnerIDs = getPartnerDetailsResults.map(a => a.PartnerID);
		// 		}
		// 	}
		// }
		return reqObj;
	}
	
	const downloadReport = () => {
		fetchReportList();
		setReportTypeLoader(true);
		let reqObj = {
			apiURL: reportTypeObj.Stub,
			reporttypeid: reportTypeObj.ReportTypeID,
			timezone_cutoff: getAbbrivatedZone(),
		};
		reqObj = updateDownloadReportPayload(reqObj);		
		apiCall({
			url: 'downloadReport',
			data: reqObj,
			callBack: (result) => {
				setReportTypeLoader(false);
				if (result.success && result.data) {
					setFilters(defaultFilter);
					setFilterLabels(defaultFilter);
					setMainFilter(defaultFilter);
					setMainFilterLabels(defaultFilter);
					setDisplayDateRangeFilter(false);
					setReportType("");
					setReportTypeObj({});
					setMandateParams([]);
					setOptionalParams([]);
					fetchRecentReport(result.data.task_id, true);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setReportTypeLoader(false),
		});
	};

	const isMandatoryFilterSupported = () => {
		let flag = true;
		mandateParams.map((param) => {
			if (flag && !supportedFilters.includes(param)) {
				flag = false;
				Swal.fire({
					icon: 'info',
					text: localString(
						`Filter Parameter ${param} hasn't been integrated for the request report kindly contact system administrator`
					),
				});
			}
		});
		return flag;
	};

	const requiredFilterCheck = () => {
		let flag = true
		for(let i in requiredFilter) {
			if(requiredFilter[i] && !mainFilter?.[i]?.length) {
				flag = false
				Swal.fire({
					icon: 'error',
					text: localString(
						`Please select all the mandatory filters`
					),
				});
			}
		}
		return flag;
	}

	const handleDownloadReport = () => {
		if (isMandatoryFilterSupported()) {
			if(requiredFilterCheck()) {
				downloadReport();
			}
		}
	};

	return (
		<>
			{(reportTypeLoader || pslLoader || olcsmNamesLoader || planLoader || brandLoader || serviceLocationLoader || vendorLoader || organizationOutletLoader || productLoader || serviceTypeLoader || subcategoryLoader) && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}
			<div className="row report-filter">
				<div className="col-sm-12 col-md-4 paddingB10">
					<Dropdown
						id='dropdown'
						value={localString(reportType)}
						options={reportTypeOptions}
						handleOnChange={handleReportTypeChange}
						filterBy='value'
						showDownArrow={false}
						placeholder={localString('Select Report Type')}
						classObject={{
							container: 'report-type-dropdown',
						}}
						showTooltip={true}
					/>
				</div>
				<div className="col-sm-12 col-md-8 report-filter-right-div">
					<div className='row'>
						<div className='col-sm-12 col-md-3 paddingB10'>
							<Button
								handleOnClick={() => setShowModal(true)}
								isDisabled={!reportType}
								type='button'
								classes='button-ghost report-filter-btn'
							>
								<span className="glyphicon glyphicon-filter" aria-hidden="true"></span>{" "} {localString("Filter Reports")}
							</Button>
						</div>
						{displayDateRangeFilter && (
							<div className='col-sm-12 col-md-5 paddingB10'>
								<DatePicker
									placeholder='DD-MM-YYYY TO DD-MM-YYYY'
									format='dd-mm-yyyy'
									dateRange={tempDateRange}
									handleOnChange={(val) => {
										setTempDateRange(val);
									}}
									// showFilterBtn={true}
									// onFilterBtnClick={(val) => setDateRange(tempDateRange)}
									showDateRange={true}
									showPreDefinedRange={true}
									minDay={new Date(minDate)}
									maxDay={new Date(maxDate)}
									calendarFooter={<>
										<div className='calendar-button-container'>
											<Button
												classes='button-ghost button-small date-filter-btn'
												text={localString('Clear')}
												type='button'
												handleOnClick={() => {
													setTempDateRange(undefined);
												}}
											/>
											<Button 
												text={localString('Apply')}
												type='button' 
												classes='button-primary button-small date-filter-btn' 
												handleOnClick={() => setDateRange(tempDateRange)} 
												isDisabled={!tempDateRange?.startDate || !tempDateRange?.startDate}
											/>
										</div>
									</>}
								/>
							</div>
						)}
						<div className='col-sm-12 col-md-3 paddingB10'>
							<Button
								handleOnClick={handleDownloadReport}
								// isDisabled={!reportType || disableDownloadButton}
								isDisabled={!reportType}
								type='button'
								classes='button-ghost report-filter-btn'
							>
								{localString('Download')}
							</Button>
						</div>
					</div>
				</div>
				<FilterModal
					{...props}
					// disableApplyBtn={disableApplyButton}
					filterFunc={updateMainFilters}
					filterInputs={filterInputList}
					filterData={filterLabels}
					showModal={showModal}
					closeModal={() => {
						setFilters(mainFilter);
						setFilterLabels(mainFilterLabels);
						setShowModal(false);
					}}
					hideClearButton={true}
				/>
			</div>
		</>
	);
};

export default ReportFilters;
