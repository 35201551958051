import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import CloseVisitModal from '../JobUpdateModal/CloseVisit/CloseVisitModal';
import { SERVICETYPE,displayError } from '../../../../../Utility/Utils';
import {Modal,Input,Button} from 'sfy-react'
import Swal from 'sweetalert2';
import './ReturnToSenderModal.scss';
const ReturnToSenderModal=(props)=>{
	const {apiCall,localString,consumerServiceRequest,showModal,closeModal,showLoader,setLoader,getRequestById,userID}=props;
    const [returnText,setReturnText] = useState('')
    console.log("userID",userID)
	const showRefurbReturnToSender = (status) =>{
        let data = {
            "consumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "status":status,
            "remarks": returnText,
            "userID":userID
        }
        setLoader(true);
        apiCall({
            url: "updateRefurbishmentStatus", 
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    getRequestById();
                    closeModal();
                }else {
                    closeModal();
                    Swal.fire({
                        title:'',
                        text:localString(displayError(result)),
                        icon:'error'
                    })
                }
            },
        });
    }
    return(
        <>
            <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='Return-to-sender-Modal'>
                <Modal.Header>
                    <h2 className='modal-title text-center text-weight'> { localString("Return to Sender")} </h2>
                    <h5>{localString('Please share the reason for returning it back to the sender')}</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className='return-device'>
                        <Input 
                            // className="form-control" 
                            inputComponent='textarea' 
                            value={returnText}
                            handleOnChange={(e) => {
                                setReturnText(e.target.value)
                            }}
                            className={{
                                containerClass: 'input-container',
                                inputClass: 'input',
                                inputContainer: 'heightAuto'
                            }}
                        />
                        <div className='return-sender-footer'>
                            <Button 
                                isDisabled={!returnText} 
                                handleOnClick={(e)=>showRefurbReturnToSender('Device delivered')} //for service type 68 Device delivered status
                                className='btn button-servify'
                            >
                                {localString('Submit')}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ReturnToSenderModal;

