import React, {useState } from 'react';
import { Button, Input, Dropdown } from 'sfy-react';
import Swal from 'sweetalert2';
import _ from 'lodash';
import {displayError } from '../../../../../Utility/Utils';
import './UpdateServiceModal.scss';
import LocationAutoComplete from '../../../../../components/LocationAutoComplete/LocationAutoComplete';

const UpdateServiceInput = (props) => {
	const {
		localString, consumerDetails, consumerServiceRequest, setLoader, apiCall, closeModal, getRequestById
	} = props; 
	const [addressList, setAddressList] = useState(consumerDetails?.Addresses || []);
	//const [showUpdateButton, setShowUpdateButton] = useState(false);
	const [serviceLocation, setServiceLocation] = useState(null);
	const [serviceLocationName, setServiceLocationName] = useState(null);
	const [serviceLocationId, setServiceLocationId] = useState(null);
	const [displayServiceCenter, setDisplayServiceCenter] = useState(false);
	const [serviceLocationData,setServiceLocationData] = useState(null);
	const [formData, setFormData] = useState({
		Landmark: ''
	});

	const [error, setError] = useState({
		Landmark: null,
	});

	const updateAddress = (placesObj, field) => {
		if(placesObj && placesObj.formatted_address) {
			let obj = {
				landmark: placesObj.formatted_address
			};
			placesObj.address_components && placesObj.address_components.map(place => {
				if (place && place.types.includes('country')) {
					obj.Country = place.short_name ? place.short_name : ''
				}
			});
			obj.Lat = placesObj.geometry.location.lat();
			obj.Lng = placesObj.geometry.location.lng();
			setFormData({ ...formData, [field]: placesObj?.formatted_address, ...obj});
			getServiceablePartnerLocations(obj)
		} else {
			setFormData({ ...formData, [field]: ''});
		}
	}


	const checkServiceAvailability = () => {
		let data = {
			Lat: formData.Lat,
			Lng: formData.Lng,
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID, 
		};
		delete serviceLocationData.value
		data.serviceLocation = serviceLocationData
		Swal.fire({
			title: 'Your action will reassign the CSR to another service location. Are you sure you want to proceed?',
			showCancelButton: true,
			closeOnConfirm: true,
			allowEscapeKey: false,
			allowOutsideClick: false,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("OK")
		}).then(result=>{
			if (result.isConfirmed) {
				Swal.close();       
				setLoader(true);
				apiCall({
					url: "updateServiceAvailability",
					data: data,
					callBack: (result) => {
						setLoader(false);
						if (result.success) {
							if(result.success && result.data) {                                          
								closeModal();  
								getRequestById(); 
							}
						}else {
							Swal.fire({
								icon: 'error',
								text: localString(displayError(result)),
							});
						}
					},
				});
			}
		});
	}

	// *****Right Code***
	const getServiceablePartnerLocations = (obj) => {
		let data = {
			ConsumerServiceRequestID: consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
			Lat: obj.Lat,
			Lng: obj.Lng
		};
		setLoader(true);
		apiCall({
			url: 'getServiceablePartnerLocations',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					let arr = result.data.map(a => ({...a, value:a.ServiceLocationName}));
					setServiceLocation(arr);
					setDisplayServiceCenter(true);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	}



	
	return (
		<>
			<div className='updateServiceCenter' style={{ padding: '0px 50px' }}>
				{/* {activeTab == 'consumerAddress' ? */}
					<>
						{/* ************** Landmark Location Tab ************** */}
						<div className="col-sm-12" id="addresslist">
							<div className='row service-location-div'>
								<div className="col-sm-4">
									<div className="form-group">
										<label>{localString("Location")}</label>
										<LocationAutoComplete
											{...props}
											event='UpdateServiceCenterForm'
											value={formData.Landmark}
											className={{
												containerClass: 'input-container',
												inputClass: 'input'
											}}
											placeholder={localString('Enter a location')}
											handleOnChange={(e) => {
												if(!e.target.value) {
													setError({ ...error, Landmark: " Please Enter Service Center Location " });
													setFormData({ ...formData, Landmark: '' });
												}else {
													setFormData({ ...formData, Landmark: e.target.value,  });
													setError({ ...error, Landmark: null });
												}
												setServiceLocationName(null)
											}}
											handleSelection={(addressObj) => updateAddress(addressObj, 'Landmark')}
											dependentStateArr={[formData]}
										/>
										{ error.Landmark && <span className="text-danger">{localString(error.Landmark)}</span>}
									</div>
								</div> 
							</div>
						</div>
					</>  
					{ displayServiceCenter && <>
						{/* ************** Location Details Tab ************** */}
						<div className="col-sm-12" id="addresslist">
							<div className='row'>
								<div className="col-sm-4">
									<div className="form-group">
										<label>{localString("Service Center")}</label>
										<Dropdown
											value={serviceLocationName || ''}
											options={serviceLocation}
											handleOnChange={(data) => {
												setServiceLocationName(data.value);
												setServiceLocationId(data.PartnerServiceLocationID);
												// delete data.value
												setServiceLocationData(data);
											}}
											handleSelection={(addressObj) => updateAddress(addressObj, 'Landmark')}
											filterBy='value'
											showDownArrow={false}
											// showTooltip={true}
										/>
									</div>
								</div> 
							</div>
						</div>
					</>}
				{/* }		 */}
			</div>
			<div className='text-right' style={{ padding: '15px 90px' }}>
				{ serviceLocationName && <Button
					handleOnClick={checkServiceAvailability}
					// isDisabled={disableApplyBtn}
					// type='submit'
					classes='button-ghost'
				>
					{localString('Update')}
				</Button> }
				<Button
					handleOnClick={() => closeModal()}
					classes='button-ghost'
				>
					{localString('Close')}
				</Button>
			</div>
		</>
	)
}
export default UpdateServiceInput;