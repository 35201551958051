import React, { useState,useEffect } from 'react';
import {Button,Checkbox} from 'sfy-react';
import Swal from 'sweetalert2';
import { returnTimezone,displayError, SERVICETYPE} from '../../../../../Utility/Utils';

const PerformQcInput = (props)=>{
    const {accessFn, localString,  consumerServiceRequest, showQCChecklistComplete, setShowQCChecklistComplete, acceptQc, rejectQc,processRequestNext,closeModal, claimNewWorkshopChecks}=props;
    const[performQcChecklist,setPerformQcChecklist] = useState([])
    let qcCheckList = consumerServiceRequest.qcList;
    const [selectAll,setSelectAll] = useState(null)
    useEffect(()=>{
        let checklist = qcCheckList && qcCheckList.map((v,index)=>({name:v,value:null,id:index}));
        setPerformQcChecklist(checklist)
    },[])
    const QCcheckListItemChecked = (e, item) => {
		let val = e.target.checked;
        let arr = [...performQcChecklist];
        let checkArray=[]
        arr.map((data,i)=>{
            if(data.name == item.name){
                arr[i].value = val
            }
            checkArray.push({value:arr[i].value})
        })
        let allCheckValue = checkArray.every((i) => {
            return i.value === true
        })
        
		if(allCheckValue===true){
            setShowQCChecklistComplete(true);
        }else{
            setShowQCChecklistComplete(false);
        }
	}

    const QCAllcheckListItemChecked =(e)=>{
        let arr = [...performQcChecklist]
        let val = e.target.checked
        arr.map(item=>{
            if(val){
                return item.value = true;
            }else{
                return item.value = false;
            }
        })
        setPerformQcChecklist(arr)
    }

    const qCChecklistClicked = function() {
		closeModal();
		processRequestNext();
	};
    
    return(
        <>
            {claimNewWorkshopChecks && <h4>{localString("Please check following items before completing the QC.")}</h4>}
            <div className='request-filter'>
                <div className='col-sm-12'>
                    { performQcChecklist && performQcChecklist.length > 0 && <div className='row'>
                        {
                                //gsx not handle because didn't get button 
                                accessFn('GSX_FLOW') && <div class="col-sm-4"> 
                                    <Checkbox
                                        value={selectAll}
                                        name='qcCheckList'
                                        handleOnChange={(e) => QCAllcheckListItemChecked(e)}
                                        // isDisabled={true}
                                    >
                                        {localString("Select All")}
                                    </Checkbox>
                                </div>
                                //
                            }
                        {
                            performQcChecklist.map(item=>(
                                <>
                                <div className='col-sm-4'>
                                    <div className='qc-check checkbox-item text-left'>
                                        <Checkbox
                                            value={item.value}
                                            name='selectAll'
                                            handleOnChange={(e) => QCcheckListItemChecked(e, item)}
                                            // isDisabled={true}
                                        >
                                            {item.name}
                                        </Checkbox>
                                    </div>
                                </div>
                            </>
                            )) 
                        }
                    
                    </div>}
                </div>
            </div>
            {!accessFn('GSX_FLOW') && <div className='marginT10'>
                <Button className='btn button-servify pull-right' handleOnClick={()=>acceptQc()}>{localString("Accept and Complete QC")}</Button>
                <Button className='btn button-servify pull-right' handleOnClick={()=>rejectQc()}>{localString("Reject QC")}</Button>
            </div>}
            {
                accessFn('GSX_FLOW') &&  <div className='marginT10'>
                    <Button className="btn button-servify float-right" type="button" handleOnClick={()=>qCChecklistClicked()} >{localString("Complete")}</Button>
                </div>
            }
        </>
    )
}
export default PerformQcInput;
