import React, { useEffect } from "react";
import {
	inRange,
	calculateAngle,
	describeArc,
} from "../../Utility/Utils";

import "./CaseScore.scss";

const redColor = "#F54336";
const orangeColor = "#FF9300";
const greenColor = "#1ABF3A";

const caseScoreRangeConfig = {
	redZone: {
		min: -1000,
		max: -201,
	},
	orangeZone: {
		min: -200,
		max: 200,
	},
	greenZone: {
		min: 201,
		max: 1000,
	},
};

const CaseScore = (props) => {
	const { getCaseScores, scoreDetails, localString } = props;

	const redArcEndAngle = calculateAngle(caseScoreRangeConfig.redZone.max);
	const orangeArcEndAngle = calculateAngle(
		caseScoreRangeConfig.orangeZone.max
	);
	const greenArcEndAngle = calculateAngle(caseScoreRangeConfig.greenZone.max);
	const pointerAngle = calculateAngle(scoreDetails.score);

	const d1 = describeArc(160, 160, 150, 0, redArcEndAngle + 90);
	const d2 = describeArc(
		160,
		160,
		150,
		redArcEndAngle + 90 + 1,
		orangeArcEndAngle + 90
	);
	const d3 = describeArc(
		160,
		160,
		150,
		orangeArcEndAngle + 90 + 1,
		greenArcEndAngle + 90
	);

	useEffect(() => {
		getCaseScores();
	}, []);

	return (
		<div className="score-section-wrapper">
			<div className="score-left-section">
				<div className="score-arc-container">
					<svg
						className="svg-wrapper"
						xmlns="http://www.w3.org/2000/svg"
						width="350"
						height="400"
						viewBox="100 -30 120 390"
						fill="none"
					>
						<path
							id="arc1"
							fill="none"
							stroke={redColor}
							stroke-width="30"
							d={d1}
						/>
						<path
							id="arc3"
							fill="none"
							stroke={orangeColor}
							stroke-width="30"
							d={d2}
						/>
						<path
							id="arc2"
							fill="none"
							stroke={greenColor}
							stroke-width="30"
							d={d3}
						/>
					</svg>

					<div className="total-score-container">
						<div className="total-score-heading">
							{localString("Total Score")}
						</div>
						<div className="total-score-value">
							{scoreDetails.score}
						</div>
					</div>

					<div
						class="pointer-wrapper"
						style={{
							transform: `rotate(${pointerAngle}deg)`,
						}}
					>
						<div
							class="pointer-pop"
							style={{
								border: `6px solid ${
									inRange(
										caseScoreRangeConfig.redZone.min,
										caseScoreRangeConfig.redZone.max,
										scoreDetails.score
									)
										? redColor
										: inRange(
												caseScoreRangeConfig.orangeZone
													.min,
												caseScoreRangeConfig.orangeZone
													.max,
												scoreDetails.score
										  )
										? orangeColor
										: greenColor
								}`,
							}}
						></div>
						<div class="pointer-stick"></div>
					</div>
				</div>
			</div>
			<div className="score-right-section">
				<header className="row score-section-heading">
					<div className="col-sm-3">{localString("SCORE NAME")}</div>
					<div className="col-sm-3">{localString("CATEGORY")}</div>
					<div className="col-sm-3">{localString("SCORE")}</div>
					<div className="col-sm-3">{localString("Description")}</div>
				</header>

				<main>
					{scoreDetails.scores && scoreDetails.scores.length > 0 ? (
						scoreDetails.scores.map((score, idx) => (
							<div key={idx} className="row score-main-section">
								<div className="col-sm-3">
									{score.scoreSubCategory}
								</div>
								<div className="col-sm-3">
									{score.scoreCategory}
								</div>
								<div
									className="col-sm-3"
									style={{
										color:
											score.score < 0
												? "#f54336"
												: "#4eaf51",
									}}
								>
									{score.score}
								</div>
								<div className="col-sm-3">
									{score.description}
								</div>
							</div>
						))
					) : (
						<div className="text-center">No Data</div>
					)}
				</main>
			</div>
		</div>
	);
};

export default CaseScore;
