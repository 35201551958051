import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import PageContentWrapper from '../../components/PageContentWrapper/PageContentWrapper';
import {displayError,generateUUID} from '../../Utility/Utils';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2"; 
import Papa from 'papaparse';
import CatalogueFileUpload from './CatalogueFileUpload';
import { compact } from 'lodash';

const Catalogue = (props) => {
	const {localString, sampleCSVData, apiCall, title, fileDataType, sampleFileName, useDocServiceCatalogue, 
		authorization, docServiceUrl, qFilter
	} = props;
	const [showLoader ,setLoader] = useState(false);
	const [numberOfCount, setNumberOfCount] = useState();
	const [showCount, setShowCount] = useState(false)
	const [showUploadButton, setUploadShowButton] = useState(false);
	const [uplodedFile, setUplodedFile] = useState({
		fileDetails :"",
		fileName:"",
		fileType:""
	})
	const [message, setMessage] = useState("");
	const qfilter = JSON.parse(qFilter)

	const getCount = () => {
		setLoader(true);
		apiCall({
			url:'countUnregisteredProducts',
			data: {},
			callBack:(result)=>{
				setLoader(false); 
				if(result.success){
					setNumberOfCount(result.data[0].count);
					if(fileDataType === "catalogueData"){
						setShowCount(true)
					}
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	useEffect(() => {
		if(fileDataType === "catalogueData"){
			getCount();
		}
	}, [])

	const downLoadSampleCSVFile = () => {
		let downloadCsv = [];
		const object = sampleCSVData
		downloadCsv.push(object);
		const csv = Papa.unparse({
			header: true,
			dynamicTyping: false,
			trimHeader: true,
			skipEmptyLines: true,
			data: downloadCsv,
		});
		let hiddenElement = document.createElement('a');
		hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
		hiddenElement.target = '_blank';
		hiddenElement.download = sampleFileName;
		document.body.appendChild(hiddenElement);
		hiddenElement.click();
	}

	const selectedFile = (e) =>{
		const file = e.target.files[0];
		setUplodedFile({...uplodedFile,
			fileDetails:file,
			fileName:file.name,
			fileType:file.type
		});
		validateFile(file);
	}

	const validateFile = (file) => {
		const reader = new FileReader();
		reader.readAsText(file);
		reader.onload = (e) => {
			let csv = e.target.result;
			setUploadShowButton(true);
			processData(csv);
		}
		reader.onerror = (e)=>{
			if(e.target.error.name === "NotReadableError"){
				Swal.fire({  
				icon: 'error',  
				text: localString("Cannot read file"),  
				});
			}
		}
	}

	const processData = (csv) => {
		let arrText = csv.split(/\r\n|\n/);
		arrText.shift();
		let iTextLength = arrText.length;
		for (let iCount=0;iCount<iTextLength;iCount++){
			let arrLine = arrText[iCount].split(',');

			if(fileDataType == "catalogueData"){
				if (arrLine.length > 2) {
					for (let i = 0; i< arrLine.length; i++) {
						if (i < (arrLine.length) && i != 0) {
							arrLine[0] = arrLine[0] + ',' + arrLine[i];
							arrLine[i] = null;
						}
						arrLine = compact(arrLine);
					}
				}
			}

			if(fileDataType === "catalogueData" && arrLine[0] === ""){
				continue;
			}
			if(fileDataType === "SalesData"){
				if(arrLine[0] === ""){
					Swal.fire({  
						icon: 'error',  
						title: localString("Invalid Format"), 
						text: localString("Invalid name for Product")
					});
					setUplodedFile("")
					setUploadShowButton(false);
					break;
				}
				if(arrLine[5] && arrLine[5].length > 17){
					Swal.fire({  
						icon: 'error',  
						title: localString("Invalid Format"), 
						text: localString("Invalid IMEI1 for Product")
					});
					setUplodedFile("")
					setUploadShowButton(false);
					break;
				}
				if(arrLine[6] && arrLine[6].length > 17){
					Swal.fire({  
						icon: 'error',  
						title: localString("Invalid Format"), 
						text: localString("Invalid IMEI2 for Product")
					});
					setUplodedFile("")
					setUploadShowButton(false);
					break;
				}
			}else if(fileDataType === "ShipmentData"){
				if(arrLine[0] === ""){
					continue;
				}
				if(arrLine[2] && arrLine[2].length != 15){
					Swal.fire({  
						icon: 'error',  
						title: localString("Invalid Format"), 
						text: localString("Invalid IMEI1 for Product")
					});
					setUplodedFile("")
					setUploadShowButton(false);
					break;
				}
				if(arrLine[2] && arrLine[3].length != 15){
					Swal.fire({  
						icon: 'error',  
						title: localString("Invalid Format"), 
						text: localString("Invalid IMEI2 for Product")
					});
					setUplodedFile("")
					setUploadShowButton(false);
					break;
				}
			}

			if( typeof (arrLine[0]) === "string" && 2 === arrLine.length){
				setUploadShowButton(true);
			}else if(!(typeof(arrLine[0]) === "string")) {
				Swal.fire({  
					icon: 'error',  
					title: localString("Invalid Format"), 
					text: localString("Invalid name for Product")
				});
				setUplodedFile("")
				setUploadShowButton(false);
				break;
			}else if((fileDataType === 'SalesData' || fileDataType === "ShipmentData")  && arrLine.length == 9 ){
				setUploadShowButton(true);
				break;
			}else if(fileDataType == "catalogueData" && 2<arrLine.length){
				Swal.fire({  
					icon: 'error',  
					title: localString("Invalid Format"), 
					text: localString("More than 2 fields supplied")
				});
				setUplodedFile("")
				setUploadShowButton(false);
				break;
			}else if((fileDataType === 'SalesData' || fileDataType === 'ShipmentData') && 9<arrLine.length){
				Swal.fire({  
					icon: 'error',  
					title: localString("Invalid Format"), 
					text: localString("More than 9 fields supplied")
				});
				setUplodedFile("")
				setUploadShowButton(false);
				break;
			}else {
				Swal.fire({  
					icon: 'error',  
					title: localString("Invalid Format"), 
					text: localString("File provided is not in required format")
				});
				setUplodedFile("")
				setUploadShowButton(false);
				break;
			}
		}
	}

	const sendFileCB = () =>{
		if(useDocServiceCatalogue && useDocServiceCatalogue == "true"){
			if (docServiceUrl == '') {
				return;
			}
			let fileType = uplodedFile.fileType;
			let ext = fileType.split("/").length ? "."+fileType.split("/")[fileType.split("/").length - 1] : ""
			let key = "FEWEBA" + generateUUID() + ext;
			if(showUploadButton) {
				let dataNew = new FormData();
				dataNew.append('file', uplodedFile.fileDetails);
				dataNew.append('docType', 'Plans');
				dataNew.append('clientRefId', key);
				dataNew.append('entity-type', 'WebApp');
				dataNew.append('entity-id', qfilter.userID);
				let url = docServiceUrl + 'internal/document/upload';
				setLoader(true);
				fetch(url, {
					method: 'POST',
					headers: {
						'App': 'WebApp',
						'module': 'WebApp',
						'authorization': authorization
					},
					body: dataNew
				})
				.then(res => res.json())
				.then(result => {
					setLoader(false);
					if(result.success && result.data) {
						let uploadURL = result.data.uploadURL;
						let request = {};
						request.Key = uploadURL;
						let url = "";
						if(['SalesData','ShipmentData'].indexOf(fileDataType) > -1){
							request.FileDataType = fileDataType;
							url = 'uploadProductData';
						}else{
							url = 'convertUnregisteredProducts';
						}
						setLoader(true);
						apiCall({
							url:url,
							data: request,
							callBack:(result)=>{
								setLoader(false); 
								if(result.success){
									if(fileDataType === "catalogueData"){
										getCount()
									}
									if(result.data.failed && result.data.failed.length > 0) {
										let msg="";
										for (let iCount = 0;iCount<result.data.failed.length;iCount++){
											for (let key in result.data.failed[iCount]){
												msg = msg + key + ': ' + result.data.failed[iCount][key] + '<br />';
											}
											msg = msg + '<br />';
										}
										setMessage(msg)
										Swal.fire({
											html:msg ,
											customClass: {
												popup: 'format-pre'
											},
											icon:"success",
											title:(localString('Some products failed')),
										}).then(result=>{
											if (result.isConfirmed) {
												Swal.close();
												setUplodedFile("");
												setUploadShowButton(false);
											}
										})
									}else{
										Swal.fire({  
											icon: 'success',  
											title:(localString("Success")),
											text: localString("File Uploaded"),  
										}).then((result)=> {
											if (result.isConfirmed) {
												Swal.close();
												setUplodedFile("");
												setUploadShowButton(false);
											}
										});
									}
								}else{
									Swal.fire({  
										icon: 'error',  
										title: localString("oops"),
										text: localString("Something went wrong")  
									})
								}
							}	
						});
					}
				})
			}else{
				Swal.fire({  
					icon: 'error',  
					text: localString("No file found","Please upload a csv file"),  
				}); 
			}
		}else{
			if(showUploadButton) {
				let fileType = ".csv";
				let key = generateUUID() + fileType;
				setLoader(true);
				apiCall({
					url:'uploadDocuments',
					data: {Type: uplodedFile.fileType, Key: key},
					callBack:(result)=>{
						setLoader(false); 
						if(result.success){
							let xhr = new XMLHttpRequest();
							xhr.open("PUT", result.data);
							xhr.setRequestHeader("Cache-Control", "public,max-age=3600");
							xhr.setRequestHeader('x-amz-acl', 'public-read');
							xhr.onload = function(){
								let request = {};
								request.Key = key;
								let url = "";
								if(['SalesData','ShipmentData'].indexOf(fileDataType) > -1){
									request.FileDataType = fileDataType;
									url = 'uploadProductData';
								}else{
									url = 'convertUnregisteredProducts';
								}
								if(xhr.status === 200){
									setLoader(true);
									apiCall({
										url:url,
										data: request,
										callBack:(result)=>{
											setLoader(false); 
											if(result.success){
												if(fileDataType === "catalogueData"){
													getCount()
												}
												if(result.data.failed && result.data.failed.length > 0) {
													let msg="";
													for (let iCount = 0;iCount<result.data.failed.length;iCount++){
														for (let key in result.data.failed[iCount]){
															msg = msg + key + ': ' + result.data.failed[iCount][key] + '<br />';
														}
														msg = msg + '<br />';
													}
													setMessage(msg)
													Swal.fire({
														html:msg ,
														customClass: {
															popup: 'format-pre'
														},
														icon:"success",
														title:(localString('Some products failed')),
													}).then(result=>{
														if (result.isConfirmed) {
															Swal.close();
															setUplodedFile("");
															setUploadShowButton(false);
														}
													})
												}else{
													Swal.fire({  
														icon: 'success',  
														title:(localString("Success")),
														text: localString("File Uploaded"),  
													}).then((result)=> {
														if (result.isConfirmed) {
															Swal.close();
															setUplodedFile("");
															setUploadShowButton(false);
														}
													});
												}
											}else{
												Swal.fire({  
													icon: 'error',  
													title: localString("oops"),
													text: localString("Something went wrong")  
												})
											}
										}	
									});
								}
							}.bind(this);
							xhr.onerror = function() {
							}.bind(this);
							xhr.send(uplodedFile.fileDetails);
						}
					}	
				});
			}else{
				Swal.fire({  
					icon: 'error',  
					text: localString("No file found","Please upload a csv file"),  
				}); 
			}
		}
	}

	return (
		<div>
			{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<PageContentWrapper title={localString(title)} bodyClassName='text-center' headerClassName="page-container-head" downLoadImageCB={downLoadSampleCSVFile} showDownLoadIcon={true}>
				<CatalogueFileUpload {...props } showCount={showCount} showUploadButton={showUploadButton} acceptFileType=".csv" numberOfCount={numberOfCount} sendFileCB={sendFileCB} selectedFile={selectedFile} uplodedFile={uplodedFile}></CatalogueFileUpload>
				{/* <div className="errorMessage margin-zero">
					<span className="uploadMessage ">{message}</span>
				</div> */}
			</PageContentWrapper>
		</div>
	) 

}

export default Catalogue
