import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import {find,isEmpty,findIndex} from 'lodash'
import moment from 'moment';
import {displayError,returnLocalDateTime,SERVICETYPE,generateUUID,getLocaleDateString,getDateFormat, getLocalDateTime,regexTest} from '../../../../../Utility/Utils';
import '../NavigationBar.scss';
import DeviceDetailsTable from './DeviceDetailsTable';


const DeviceDetails = (props) => {
	const {
		accessFn,apiCall,localString,consumerProductDocuments,consumerServiceRequest,getRequestById,coverageDetails,excludeUnwantedModules,
		getProductDetails,getInsuranceDetails,canUpdateServiceRequest,loanerDeviceList,defaultCountryLocale,highRiskReason,useDocServiceDeviceDetails,
		showClickedTab,currencySymbol,isBoseFlow,useDocServiceDevice,docServiceUrl,authorization,setActiveTab,appleDropOffFlow,soldPlan
	} = props;
	const qFilter = JSON.parse(props.qFilter);
	const [loader, setLoader] = useState(false)
	const [editDevice, setEditDevice] = useState(false)
	const [mirrorTestResults, setMirrorTestResults] = useState([])
	const [getMirrorTestCalled, setGetMirrorTestCalled] = useState(false)
	const [isEWClaim, setIsEWClaim] = useState(false)
	const [warrantyEdit, setWarrantyEdit] = useState(false);
	const [activeWarrantyStatus, setActiveWarrantyStatus] = useState()
	const [activeWarrantyApplication, setActiveWarrantyApplication] = useState()
	const [warrantyValue, setWarrantyValue] = useState()
	const [uploadedDocDetails, setUploadedDocDetails] = useState(null)
	const [showEditButton, setShowEditButton] = useState(false)
	const [deviceDetailsEditable, setDeviceDetailsEditable] = useState(false)
	const [isClaimEstimationGenerated, setIsClaimEstimationGenerated] = useState(false)
	const [fetchAttributesFromSKUCalled, setFetchAttributesFromSKUCalled] = useState(false)
	const [isDeviceAllocated,setIsDeviceAllocated] = useState(false);
	const [isNonSerializedProduct, setIsNonSerializedProduct] = useState()
	const [isUnDiagnosable, setIsUnDiagnosable] = useState()
	const [loanerDeviceCount, setLoanerDeviceCount] = useState()
	const [salesChannel, setSalesChannel] = useState([])
	const [attributesArrayWithValues, setAttributesArrayWithValues] = useState([])
	const [inputData, setInputData] = useState({
		serialNumber:"",
		warrantyDenialReason:"",
		warrantyTill:"",
		dateOfPurchase:"",
		devicePurchaseCost:"",
		deviceDocFile:"",
		salesChannelID:"",
		salesChannelName:""
	})
	
	const documentOptions = [{
		value:""
	},{
		value:"ID Proof"
	},{
		value:"Invoice"
	}]; 

	useEffect(() => {
		if(accessFn("WORKSHOP_FLOW")){
			
			if((((consumerServiceRequest.Source == "SkyworthDashboard" || consumerServiceRequest.Source == "BoseDashboard" || consumerServiceRequest.Source == "SmartTechnoDashboard") && deviceDetailsEditable && !accessFn('READ_ONLY')) 
				|| (deviceDetailsEditable && canUpdateServiceRequest && !accessFn('READ_ONLY')) || qFilter.hasUnrestrictedAccess)){ 
				setShowEditButton(true)
			}
			else{  
				setShowEditButton(false)
			}
		}else{
			if(deviceDetailsEditable && !accessFn('READ_ONLY')){
				setShowEditButton(true)
			}else{
				setShowEditButton(false)
			}
		}
		
		
	}, [deviceDetailsEditable])

	useEffect(() => {
		let isDeviceAllocated = false;
		if(consumerServiceRequest.consumerproduct){
			if(!getMirrorTestCalled && [SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_DOOR_TO_DOOR_UPGRADE,SERVICETYPE.DOOR_TO_DOOR_UPGRADE,SERVICETYPE.CLAIM_DOOR_TO_DOOR_REPLACEMENT,SERVICETYPE.DOOR_TO_DOOR_REPLACEMENT,SERVICETYPE.PICKUP_UPGRADE,SERVICETYPE.CLAIM_REPLACEMENT_PICKUP,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.MAIL_IN_REPLACEMENT,SERVICETYPE.CLAIM_REPLACEMENT_MAIL_IN,SERVICETYPE.CLAIM_UPGRADE_PICKUP,SERVICETYPE.MAIL_IN_UPGRAGE,SERVICETYPE.CLAIM_UPGRADE_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && (!excludeUnwantedModules || excludeUnwantedModules == false)) {
				getMirrorTestResults();
			}
			
		}

		if(accessFn('WORKSHOP_FLOW')){

			if(accessFn('GSX_FLOW')) {
				setActiveWarrantyStatus(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.IsUnderWarranty ? true : false)
			} else {
				setActiveWarrantyStatus(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.IsUnderWarranty ? true : false)
			}
			setActiveWarrantyApplication(consumerServiceRequest && consumerServiceRequest.IsWarrantyApplicable)

			//getSalesChannel()

			if(loanerDeviceList){
				setLoanerDeviceCount(loanerDeviceList.totalQuantity)
			}

			if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && accessFn('EDIT_DEVICE_DETAILS')) {
				if(['Device received','Device inspected','Provisional estimate generated','Provisional estimate updated','Non hardware closure details updated', 'Pending for acceptance','Pending for assignment', 'Work in progress', 'Parts request created','Parts request rejected','Estimation generated','Estimation approval','Estimation rejected','Service in-progress','Advance collected','QC rejected, Work in progress'].indexOf(consumerServiceRequest.Status) > -1 && !consumerServiceRequest.InvoiceGeneratedDate){
					setDeviceDetailsEditable(true)
					setWarrantyEdit(true)
				}
			} else if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && accessFn('EDIT_DEVICE_DETAILS')){
				if(['Repair initiated','QC rejected'].indexOf(consumerServiceRequest.Status) == -1  && !consumerServiceRequest.InvoiceGeneratedDate) {
					for(let i = 0; i < consumerServiceRequest.logs.length; i++){
						let log = consumerServiceRequest.logs[i];
						if(log.Status == "Claim estimation generated") {
							setIsClaimEstimationGenerated(true)
							break;
						}
					}
					
					if((['Repair completed', 'Service completed', 'Service cancel', 'Repair cancel', 'Device dispatched', 'Claim estimation generated', 'Claim estimation approved', 'Parts request accepted', 'Parts issued', 'Parts received', 'Parts rejected'].indexOf(consumerServiceRequest.Status) == -1)  && (!consumerServiceRequest.InvoiceGeneratedDate)){
						setDeviceDetailsEditable(true)
						setWarrantyEdit(isEWClaim && isEWClaim)
					} else {
						setDeviceDetailsEditable(false)
					}
					if(isClaimEstimationGenerated) {
						setDeviceDetailsEditable(false)
					}
				}
			} else if([9,13,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
				if(['Device received', 'Document verification pending'].indexOf(consumerServiceRequest.Status) > -1  ){
					setDeviceDetailsEditable(true)
				}
				else {
					setDeviceDetailsEditable(false)
				}
			}else if ([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && isBoseFlow) {
				if(['Service accepted'].indexOf(consumerServiceRequest.Status) > -1  ){
					setDeviceDetailsEditable(true)
				}
				else {
					setDeviceDetailsEditable(false)
				}
			}

			let deviceAccept = {};
			let logs = consumerServiceRequest && consumerServiceRequest.logs;
			if(logs){
				for (let iCount = 0;iCount < logs.length;iCount++) {
					if (logs[iCount].Status == 'Device accepted') {
						deviceAccept = logs[iCount]
					break;
					}
				}
			}
			
			let isDeviceReceived = find(consumerServiceRequest.logs, {Status: 'Device received'})
			let deviceAcceptedLog
			if (deviceAccept.DestinationType == 'ServicelocationEngineer') {
				deviceAcceptedLog = deviceAccept
			}
			let currentRole = getCurrentRole();
			if(currentRole === 'CustomerCare' && isDeviceReceived && checkDeviceEditAllowedStatus()) {
				setDeviceDetailsEditable(true);
			}
			if(currentRole === 'Supervisor' && ['VizioDashboard','Croma','CromaAPI','CromaCare','CromaDashboard','BrightstarAPI'].indexOf(consumerServiceRequest.Source) === -1) {
				setDeviceDetailsEditable(true);
				if([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && ['Pending for assignment','Pending for approval','Service accepted'].indexOf(consumerServiceRequest.Status) > -1){
					setDeviceDetailsEditable(true);
				}
			}
			if (deviceAcceptedLog && checkDeviceEditAllowedStatus()) {
				if (currentRole === 'ServicelocationEngineer') {
					setDeviceDetailsEditable(true);
				}
			} else {
				if (currentRole === 'ServicelocationEngineer') {
					setDeviceDetailsEditable(false);
				}
			}
			let isEstimateGenerated = find(consumerServiceRequest.logs, {Status: 'Estimation generated'})
			if (isEstimateGenerated && consumerServiceRequest.Status != 'Work in progress') {
				setDeviceDetailsEditable(false);
			}
			let isQcCompleted = find(consumerServiceRequest.logs, {Status: 'QC completed'});
			if (isQcCompleted) {
				setDeviceDetailsEditable(false);
			}
			if (consumerServiceRequest.Status == 'QC pending') {
				setDeviceDetailsEditable(false);
			}
			if (consumerServiceRequest.Status == 'Repair completed') {
				setDeviceDetailsEditable(false);
			}
			let isRepairCompleted = find(consumerServiceRequest.logs, {Status: 'Repair completed'});
			if (consumerServiceRequest && !consumerServiceRequest.isQCPresent && isRepairCompleted) {
				setDeviceDetailsEditable(false);
			}
			if (consumerServiceRequest.Status == 'Service invoice generated') {
				setDeviceDetailsEditable(false);
			}
			if (consumerServiceRequest.Status == 'Service completed') {
				setDeviceDetailsEditable(false);
			}
			if (consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.length > 0 ) {
				if (consumerServiceRequest.logs[0].Status == 'Diagnosis initiated') {
					setDeviceDetailsEditable(false);
				}
				if (!isDiagnosisCompleted()) {
					setDeviceDetailsEditable(false);
				}
			}
			if (['applicable','replacement','certificate'].indexOf(consumerServiceRequest.DOAStatus) > -1) {
				setDeviceDetailsEditable(false);
			}
			if(accessFn('GSX_FLOW') && consumerServiceRequest && consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber){
				setDeviceDetailsEditable(false);
			}
			if([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && ['Pending for assignment','Pending for approval','Service accepted','Service completed'].indexOf(consumerServiceRequest.Status) == -1 && ['VizioDashboard','Croma','CromaAPI','CromaCare','CromaDashboard','BrightstarAPI'].indexOf(consumerServiceRequest.Source) === -1){
				setDeviceDetailsEditable(true);
			}
			if([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && ['Pending for approval','Service accepted','Serviceman reached','Work in progress'].indexOf(consumerServiceRequest.Status) > -1 && (consumerServiceRequest.Source == "SkyworthDashboard" || consumerServiceRequest.Source == "BoseDashboard" || consumerServiceRequest.Source == "SmartTechnoDashboard")){
				setDeviceDetailsEditable(true);
			}
			let isClaimEstimationGeneratedEver = find(consumerServiceRequest.logs, {Status: 'Claim estimation generated'})
			if (isClaimEstimationGeneratedEver) {
				setDeviceDetailsEditable(false);
			}
			if([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) !== -1) {
				setDeviceDetailsEditable(false);
			}
			let isDeviceShipmentRequested = find(consumerServiceRequest.logs, {Status: 'Device shipment requested'})
			if(isDeviceShipmentRequested && appleDropOffFlow) {
				setDeviceDetailsEditable(false);
			}

		}
		if(!accessFn('WORKSHOP_FLOW')){
			if(!fetchAttributesFromSKUCalled && [SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && consumerServiceRequest && consumerServiceRequest.consumerProduct.ProductSkuID) {
				fetchAttributesFromSKU();
			}
			if ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
				if ((consumerServiceRequest.Status === 'Service initiated' ||  consumerServiceRequest.Status  === 'Repair initiated' )&& !consumerServiceRequest.InvoiceGeneratedDate) {
					setDeviceDetailsEditable(true)
					setWarrantyEdit(true)
				}
			}else if ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
				for(let i = 0; i < consumerServiceRequest.logs.length; i++){
					let log = consumerServiceRequest.logs[i];
					if(log.Status == "Claim estimation generated") {
						setIsClaimEstimationGenerated(true)
						break;
					}
					if(log.Status == "Replacement allocated"){
						isDeviceAllocated = true
						break;
					}
				}
				if ((['Repair completed', 'Service completed', 'Service cancel', 'Repair cancel', 'Device dispatched', 'Claim estimation generated', 'Claim estimation approved', 'Parts request accepted', 'Parts issued', 'Parts received', 'Parts rejected'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) == -1 ) && (!consumerServiceRequest.InvoiceGeneratedDate)) {
					setDeviceDetailsEditable(true)
					setWarrantyEdit(isEWClaim && isEWClaim)
				} else {
					setDeviceDetailsEditable(false)
				}
				if(isClaimEstimationGenerated) {
					setDeviceDetailsEditable(false)
				}
				if(isDeviceAllocated) {
					setDeviceDetailsEditable(false)
				}
			}else if ([9,13].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
				if(['Device received', 'Document verification pending'].indexOf(consumerServiceRequest.Status) > -1  ){
					setDeviceDetailsEditable(true)
				}
				else {
					setDeviceDetailsEditable(false)
				}
			}else if ([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && isBoseFlow) {
				if(['Service accepted'].indexOf(consumerServiceRequest.Status) > -1  ){
					setDeviceDetailsEditable(true)
				}
				else {
					setDeviceDetailsEditable(false)
				}

			}
		}

		setIsEWClaim(consumerServiceRequest && consumerServiceRequest.EWRequest)

		// if (insurancePlans) {
		//     setCoverageDetails(insurancePlans.coverageDetails)
		// }

		let nonSerializedProduct = consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig? consumerServiceRequest.consumerProduct.productConfig.isSerializedProduct === false: false;
		setIsNonSerializedProduct(nonSerializedProduct)

		setIsUnDiagnosable(consumerServiceRequest && consumerServiceRequest.isUnDiagnosable)

	}, [consumerServiceRequest,isEWClaim])

	// useEffect(() => {
	//     if(inputData.serialNumber){
	//         checkImeiValueValid(e)
	//     }
	// }, [inputData])

	const checkImeiValueValid = (e)=> {
		if(consumerServiceRequest && consumerServiceRequest.Source && consumerServiceRequest.Source !== "SkyworthDashboard"){
		let evt = (e) ? e : window.event;
		let charCode = (evt.which) ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
		}
		else{
			return true;
		}
	};

	const isDiagnosisCompleted=()=> {
		let diagInit = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis initiated',PerformedByRole:'ServicelocationEngineer'});
		let diagComp = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis completed',PerformedByRole:'ServicelocationEngineer'});
		let cannotDiag = findIndex(consumerServiceRequest.logs, {Status: 'Cannot be diagnosed',PerformedByRole:'ServicelocationEngineer'});
		if (diagInit != -1) {
			if (diagComp != -1) {
				return diagComp < diagInit;
			}
			if (cannotDiag != -1) {
				return cannotDiag < diagInit
			}
			return false
		}
		return true
	}

	const getCurrentRole=()=> {
		let role;
		if (qFilter) {
			if (qFilter.hasUnrestrictedAccess) {
				role = 'Superadmin';
			} else {
				switch (true) {
				case (accessFn('ACCEPT_RETURN_DEVICE')):
					role = 'CustomerCare';
					break;
				case (accessFn('STORE_MANAGEMENT')):
					role = 'StoreManager';
					break;
				case (accessFn('REASSIGN_JOBS')):
					role = 'Supervisor';
					break;
				case (accessFn('REPAIR_DEVICE')):
					role = 'ServicelocationEngineer';
					break;
				case (accessFn('QC_DEVICE')):
					role = 'QCEngineer';
					break;
				}
				return role;
			}
		}
		return role;
	}

	const checkDeviceEditAllowedStatus = () =>{
		if(['Repair completed', 'Service completed', 'Service cancel', 'Repair cancel', 'Device dispatched', 'Claim estimation generated', 'Claim estimation approved', 'Parts request accepted', 'Parts issued', 'Parts received', 'Parts rejected'].indexOf(consumerServiceRequest.Status) == -1) {
			return true;
		} else {
			return false;
		}
	}

	const getMirrorTestResults = ()=>{
		setGetMirrorTestCalled(true)
		let data = {
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
		};
		setLoader(true);
		apiCall({
			url:'getMirrorTestResults',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					setMirrorTestResults(result.data)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const getWarrantyDate = (dateString)=>{
		let date = 'N/A';
		if (dateString) {
			date = (dateString ? moment(dateString).unix != 0 ? moment(dateString).format('DD-MM-YYYY') : '-' : '-');
		};
		return date;
	}

	const enableDeviceClick=()=>{

		setEditDevice(true)

		if(accessFn("WORKSHOP_FLOW")){
			//getSalesChannel();
		}  
		if(accessFn('GSX_FLOW')) {
			setActiveWarrantyStatus(consumerServiceRequest && consumerServiceRequest.consumerProduct.IsUnderWarranty ? true : false)
		} else {
			setActiveWarrantyStatus(consumerServiceRequest && consumerServiceRequest.consumerProduct.IsUnderWarranty ? true : false)
		}

		setActiveWarrantyApplication(isEWClaim ?  consumerServiceRequest.IsPlanEligible : consumerServiceRequest.IsWarrantyApplicable)
		setWarrantyValue(isEWClaim ?  consumerServiceRequest.IsPlanEligible : consumerServiceRequest.IsWarrantyApplicable);

		let temp = {...inputData}
		if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductUniqueID){
			let uniqueID = (consumerServiceRequest.consumerProduct.ProductUniqueID);
			temp.serialNumber = uniqueID
		}

		if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost){
			let deviceValue = (consumerServiceRequest.consumerProduct.ProductPurchaseCost);
			temp.devicePurchaseCost = deviceValue
		}

		if ((consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.WarrantyTill) && !accessFn("WORKSHOP_FLOW")) {
			let warrantyDate = moment(consumerServiceRequest.consumerProduct.WarrantyTill).format('DD-MM-YYYY');
			temp.warrantyTill = warrantyDate;
		}
	
		if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.DateOfPurchase) {
			let purchaseDate = moment(consumerServiceRequest.consumerProduct.DateOfPurchase).format('DD-MM-YYYY');
			temp.dateOfPurchase = purchaseDate; 
		}

		if(consumerServiceRequest && consumerServiceRequest.WarrantyReason){
			temp.warrantyDenialReason = consumerServiceRequest && consumerServiceRequest.WarrantyReason
		}
		
		setInputData(temp)
	}

	const selectedFile = (e) =>{
		const file = e.target.files[0];
		setUploadedDocDetails(file)
	}

	const cancelDetailsClick = ()=>{
		setEditDevice(false)
		setUploadedDocDetails(null);
		setInputData({ ...inputData, ['deviceDocFile'] : ''})
	}

	const checkToAddWarrantyApplicableDefault = ()=> {
		let workInProgressLog = find(consumerServiceRequest.logs, {Status: 'Work in progress'});
		return !isEWClaim && [SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1 && (consumerServiceRequest.Status == 'Work in progress' || workInProgressLog);
	};

	const saveDeviceDetails=(e)=>{
		e.preventDefault();
		// let newfile = [];
		if(accessFn("WORKSHOP_FLOW")){
			let newfile = [];
			if(['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard'].indexOf(consumerServiceRequest.Source) === -1){
				newfile.push(uploadedDocDetails);
			}

			let serialNumber = inputData.serialNumber || undefined;
			let dateOfPurchase = '';
			let salesChannelID = inputData.salesChannelID;
			let salesChannelName = inputData.salesChannelName;
			let data;
			let devicePurchaseCost;
			if (inputData.devicePurchaseCost) {
				if(parseInt(inputData.devicePurchaseCost) > 0){
					devicePurchaseCost = inputData.devicePurchaseCost;
				}else{
					Swal.fire({
						icon:"error",
						text:localString("Purchase cost can not be zero")
					})
					return;
				}
			}
			if (inputData.saleschannel) {
				salesChannelID = salesChannelID ? salesChannelID : '';
				salesChannelName = salesChannelName ? salesChannelName : '';
			}
			if(inputData.dateOfPurchase && inputData.dateOfPurchase != "-"){
				dateOfPurchase = moment(inputData.dateOfPurchase, getDateFormat(defaultCountryLocale)).format('YYYY-MM-DD');
			}
			if (accessFn('GSX_FLOW') && !consumerServiceRequest.isVerifiedProduct && serialNumber == '' ) {//&& serialNumber.trim() == ''
				Swal.fire({
					icon:"error",
					text:localString("Please enter serial number")
				})
				return;
			}
			let warranty = activeWarrantyStatus;
			if (accessFn('GSX_FLOW') && consumerServiceRequest.consumerProduct && (consumerServiceRequest.consumerProduct.isAppleBrandID)) {
				warranty = consumerServiceRequest.consumerProduct.IsUnderWarranty == null || undefined ? false : consumerServiceRequest.consumerProduct.IsUnderWarranty;
			}
			let warrantyApplicable = activeWarrantyApplication;
			if (warranty == true && warrantyEdit) {
				if (serialNumber == ''  && (consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.isSerializedProduct)) {
					Swal.fire({
						icon:"error",
						text:localString("Please enter serial number")
					})
					return;
				}
				if (!isEWClaim && inputData && inputData.warrantyTill) {
					Swal.fire({
						icon:"error",
						text:localString("Please enter warranty till date"),
						type: 'error',
                        confirmButtonText: localString('OK')
					})
					return;
				}
				// if (newfile == undefined && !consumerProductDocuments?.FilePath?.length) {
				// 	Swal.fire({
				// 		icon:"error",
				// 		text:localString("Please upload a warranty supporting document")
				// 	})
				// 	return;
				// }
				if (warrantyApplicable != undefined && warrantyApplicable == false) {
					if ((inputData.warrantyDenialReason == undefined || inputData.warrantyDenialReason == '') && ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1 || isEWClaim)) {
						Swal.fire({
							icon:"error",
							text:localString(isEWClaim ? "Please enter the reason for plan not being covered" : "Please enter the reason for warranty not being applicable"),
						})
						return;
					}
				}
			}

			if((warranty == true || accessFn('GSX_FLOW')) && warrantyEdit) {
				if (warrantyApplicable == undefined || null) {
					if(isEWClaim || checkToAddWarrantyApplicableDefault()) {
						Swal.fire({
							icon:"error",
							text:localString(isEWClaim ? "Please choose if the plan is covered or not" : "Please update \"Is Manufacturer's Warranty Applicable\" and save device details."),
						})
						return;
					}
				}
			}

			if (!consumerServiceRequest.consumerProduct.IsUnderWarranty && warrantyApplicable == true && accessFn('GSX_FLOW')){
				if ((inputData.warrantyDenialReason == undefined || inputData.warrantyDenialReason == '')) {
					Swal.fire({
						icon:"error",
						text:localString("Please enter the reason for warranty applicable")
					})
					return;
				}
			}
			if(checkToAddWarrantyApplicableDefault()) {
				warrantyApplicable = getIsUnderWarranty(warrantyApplicable);
			}

			if(['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard'].indexOf(consumerServiceRequest.Source) === -1 && (uploadedDocDetails )) {
				let deviceDocTag = inputData.deviceDocFile;
				if(!deviceDocTag) {
					Swal.fire({
						icon:"error",
						text:localString("Please select a document type")
					})
					return;
				}
				let fileType =  uploadedDocDetails.type;
				let ext = fileType.split("/").length ? "."+fileType.split("/")[fileType.split("/").length - 1] : ""
				let key = "FEWEBA" + generateUUID() + ext;
				if (useDocServiceDeviceDetails && useDocServiceDeviceDetails == 'true') {
					if (docServiceUrl == '') {
						return;
					}
					let dataNew = new FormData();
					dataNew.append('file', uploadedDocDetails);
					dataNew.append('docType', 'ConsumerProductDocument');
					dataNew.append('clientRefId', key);
					dataNew.append('entity-id', consumerServiceRequest.consumerProduct.ConsumerProductID);
					dataNew.append('entity-type', 'ConsumerProductID');
					let url = docServiceUrl + 'internal/document/upload';
					setLoader(true);
					fetch(url, {
						method: 'POST',
						headers: {
							'App': 'WebApp',
							'module': 'WebApp',
							'authorization': authorization
						},
						body: dataNew
					})
					.then(res => res.json())
					.then(result => {
						setLoader(false);
							if(result.success){
								let uploadURL = result.data.uploadURL;
								let fileNameUUID = result.data.docServiceUUID;
								setLoader(true);
								apiCall({
									url:'addNewProductDocs',
									isFormData: true,
									data: {
										updatedData: [{
											ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID, 
											FilePath: uploadURL, 
											Type: 'image', 
											DisplayName: deviceDocTag, 
											FileName: fileNameUUID
										}]
									},
									callBack:(result)=>{
										setLoader(false);
										if(result.success){
											setInputData({...inputData,deviceDocTag:""})
											data = {
												IsWarrantyDetailsEditable : !accessFn('NOT_ALLOWED_TO_EDIT_SALES_WARRANTY_DETAILS') ? true : false ,
												ProductUniqueID: serialNumber,
												ServiceTypeID: consumerServiceRequest.ServiceTypeID,
												ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID,
												ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
												updatedWarranty: {
													ProductUniqueID: serialNumber,
													IsUnderWarranty: warranty,
													IsWarrantyApplicable: !isEWClaim ? warrantyApplicable : undefined,                                                    
													Reason: inputData.warrantyDenialReason,
													IsPlanEligible: isEWClaim ? getIsUnderWarranty(warrantyApplicable) : undefined
												},
												updateObj: {
													ProductPurchaseCost: devicePurchaseCost,
													DateOfPurchase: dateOfPurchase,
													SalesChannelID: salesChannelID,	
													SalesChannelName: salesChannelName	
												},	
												isSerializedProduct:consumerServiceRequest.consumerProduct.productConfig?consumerServiceRequest.consumerProduct.productConfig.isSerializedProduct:undefined,
												consumerProductAttribute:consumerServiceRequest.consumerProduct.consumerProductAttribute,
												isAppleBrandID:consumerServiceRequest.consumerProduct.isAppleBrandID
											};
											if (["N/A","NA"].indexOf(inputData.warrantyTill) > -1) {
												data.updatedWarranty.WarrantyTill = '';
											}
											else if (warrantyEdit && !accessFn('NOT_ALLOWED_TO_EDIT_SALES_WARRANTY_DETAILS')) {
												if (defaultCountryLocale && !!inputData.warrantyTill) {
													data.updatedWarranty.WarrantyTill = moment(inputData.warrantyTill, getDateFormat(defaultCountryLocale)).format('DD-MM-YYYY')
												}
												else {
													data.updatedWarranty.WarrantyTill = inputData.warrantyTill
												}
											}
											else {
												data.updatedWarranty.WarrantyTill = '';
											}
											updateConsumerProductFunc(data);
										}else{
											Swal.fire({
												icon: 'error',
												text: localString(displayError(result)),
											});
										}
									}
								});
							}else{
								Swal.fire({
									icon: 'error',
									text: localString(displayError(result)),
								});
							}
					})
					.catch(err => {
						setLoader(false);
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(err)),  
						});
					})
				}else {
					let data = {
						Type: fileType, 
						Key: key, 
						BrandID: consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID ? consumerServiceRequest.consumerProduct.BrandID : undefined
					}
					setLoader(true);
					apiCall({
						url:'uploadDocuments',
						data: data,
						callBack:(result)=>{
							setLoader(false);
							if (result.success) {
								var xhr = new XMLHttpRequest();
								xhr.open("PUT", result.data);
								xhr.setRequestHeader("Cache-Control", "public,max-age=3600");
								xhr.setRequestHeader('x-amz-acl', 'public-read');
								xhr.onload = function() {
									if (xhr.status === 200) {
										setLoader(true);
										apiCall({
											url:'addProductDocs',
											data: [{
												ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID, 
												FilePath: key, 
												Type: 'image', 
												DisplayName: deviceDocTag
											}],
											callBack:(result)=>{
												if (result.success) {
													setInputData({...inputData,deviceDocTag:""})
													data = {
														IsWarrantyDetailsEditable : !accessFn('NOT_ALLOWED_TO_EDIT_SALES_WARRANTY_DETAILS') ? true : false ,
														ProductUniqueID: serialNumber,
														ServiceTypeID: consumerServiceRequest.ServiceTypeID,
														ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID,
														ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
														updatedWarranty: {
															ProductUniqueID: serialNumber,
															IsUnderWarranty: warranty,
															IsWarrantyApplicable: !isEWClaim ? warrantyApplicable : undefined,
															Reason:inputData.warrantyDenialReason,
															IsPlanEligible: isEWClaim ? getIsUnderWarranty(warrantyApplicable) : undefined
														},
														updateObj: {
															ProductPurchaseCost: devicePurchaseCost,
															DateOfPurchase: dateOfPurchase,
															SalesChannelID: salesChannelID,
															SalesChannelName: salesChannelName
														},
														isSerializedProduct:consumerServiceRequest.consumerProduct.productConfig?consumerServiceRequest.consumerProduct.productConfig.isSerializedProduct:undefined,
														consumerProductAttribute:consumerServiceRequest.consumerProduct.consumerProductAttribute,
														isAppleBrandID:consumerServiceRequest.consumerProduct.isAppleBrandID
													};
													if (["N/A","NA"].indexOf(inputData.warrantyTill) > -1) {
														data.updatedWarranty.WarrantyTill = '';
													}
													else if (warrantyEdit && !accessFn('NOT_ALLOWED_TO_EDIT_SALES_WARRANTY_DETAILS')) {
														if (defaultCountryLocale && !!inputData.warrantyTill) {
															data.updatedWarranty.WarrantyTill = moment(inputData.warrantyTill, getDateFormat(defaultCountryLocale)).format('DD-MM-YYYY')
														}
														else {
															data.updatedWarranty.WarrantyTill = inputData.warrantyTill
														}
													}
													else {
														data.updatedWarranty.WarrantyTill = '';
													}
													// if(consumerServiceRequest.isAnchorRequest || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard'].indexOf(consumerServiceRequest.Source) > -1){
													//     data.BrandID = self.selectedBrand.BrandID;
													//     data.ProductSubCategoryID= self.selectedCategory.ProductSubCategoryID;
													//     data.updateObj.ProductID = self.selectedProduct.ProductID;
													//     if(self.csr && self.csr.PartnerServiceLocationID){
													//         data.PartnerServiceLocationID = self.csr.PartnerServiceLocationID;
													//     }
													// }
													data.isVerifiedProduct = consumerServiceRequest.isVerifiedProduct;
													updateConsumerProductFunc(data);
												}else{
													Swal.fire({
														icon: 'error',
														text: localString(displayError(result)),
													});
												}
											}
										})
									}
								}.bind(this);
								xhr.onerror = function() {
								}.bind(this);
								xhr.send(uploadedDocDetails);
							}else{
								Swal.fire({
									icon: 'error',
									text: localString(displayError(result)),
								});
							}
						}
					});
				}
			}else {
				data = {
					IsWarrantyDetailsEditable : !accessFn('NOT_ALLOWED_TO_EDIT_SALES_WARRANTY_DETAILS') ? true : false ,
					ProductUniqueID: serialNumber,
					ServiceTypeID: consumerServiceRequest.ServiceTypeID,
					ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID,
					ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
					updatedWarranty: {
						ProductUniqueID: serialNumber,
						IsUnderWarranty: warranty,
						IsWarrantyApplicable: !isEWClaim ? warrantyApplicable : undefined,
						Reason:inputData.warrantyDenialReason,
						IsPlanEligible: isEWClaim ? getIsUnderWarranty(warrantyApplicable) : undefined
					},
					updateObj: {
						ProductPurchaseCost: devicePurchaseCost,
						DateOfPurchase: dateOfPurchase,
						SalesChannelID: salesChannelID,
						SalesChannelName: salesChannelName
					},
					isSerializedProduct:consumerServiceRequest.consumerProduct.productConfig?consumerServiceRequest.consumerProduct.productConfig.isSerializedProduct:undefined,
					consumerProductAttribute:consumerServiceRequest.consumerProduct.consumerProductAttribute,
					isAppleBrandID:consumerServiceRequest.consumerProduct.isAppleBrandID
				};
				if (["N/A","NA"].indexOf(inputData.warrantyTill) > -1) {
					data.updatedWarranty.WarrantyTill = '';
				}
				else if (warrantyEdit && !accessFn('NOT_ALLOWED_TO_EDIT_SALES_WARRANTY_DETAILS')) {
					if (defaultCountryLocale && !!inputData.warrantyTill) {
						data.updatedWarranty.WarrantyTill = moment(inputData.warrantyTill, getDateFormat(defaultCountryLocale)).format('DD-MM-YYYY')
					}
					else {
						data.updatedWarranty.WarrantyTill = inputData.warrantyTill
					}
				}
				else {
					data.updatedWarranty.WarrantyTill = '';
				}
				// if(self.csr.isAnchorRequest || ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard'].indexOf(self.csr.Source) > -1){
				//     data.BrandID = self.selectedBrand.BrandID;
				//     data.ProductSubCategoryID= self.selectedCategory.ProductSubCategoryID;
				//     data.updateObj.ProductID = self.selectedProduct.ProductID;
				//     if(self.csr && self.csr.PartnerServiceLocationID){
				//         data.PartnerServiceLocationID = self.csr.PartnerServiceLocationID;
				//     }
				// }

				data.isVerifiedProduct = consumerServiceRequest.isVerifiedProduct;
				updateConsumerProductFunc(data);
			}
		}
		else{
			let serialNumber = inputData.serialNumber;
			let devicePurchaseCost = inputData.devicePurchaseCost
			let dateOfPurchase = '';
			if(inputData.dateOfPurchase){
				dateOfPurchase = moment(inputData.dateOfPurchase,'DD-MM-YYYY').format('YYYY-MM-DD');
			}
			let data;
			let warranty = activeWarrantyStatus
			let warrantyApplicable = activeWarrantyApplication

			if (warranty == true && warrantyEdit) {
				if (serialNumber == ''  && (consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.isSerializedProduct)) {
					Swal.fire({
						icon:"error",
						text:localString("Please enter serial number")
					})
					return;
				}
				if (inputData.warrantyTill == '' && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
					Swal.fire({
						icon:"error",
						text:localString("Please enter warranty till date")
					})
					return;
				}
				// if (!newfile && !consumerProductDocuments?.FilePath?.length) {
				// 	Swal.fire({
				// 		icon:"error",
				// 		text:localString("Please upload a warranty supporting document")
				// 	})
				// 	return;
				// }
				if (warrantyApplicable == undefined || null) {
					Swal.fire({
						icon:"error",
						text:isEWClaim? localString("Please choose if the plan is covered or not") : localString("Please update \"Is Manufacturer's Warranty Applicable\" and save device details.")
					})
					return;
				}
				if (warrantyApplicable != undefined && warrantyApplicable == false) {
					if(inputData.warrantyDenialReason== '' && ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1 || isEWClaim)){
						Swal.fire({
							icon:"error",
							text:isEWClaim? localString("Please enter the reason for plan not being covered") : localString("Please enter the reason for warranty not being applicable")
						})
						return;
					}
				}
			}

			let newfile = uploadedDocDetails;			
			if(newfile) {
				let type = uploadedDocDetails?.type;
				let ext = type.split("/").length ? "."+type.split("/")[type.split("/").length - 1] : "";
				let key = "FEWEBA" + generateUUID() + ext;
				let deviceDocTag = inputData.deviceDocFile;
				if(!deviceDocTag) {
					Swal.fire({
						icon:"error",
						text:localString("Please select a document type")
					})
					return;
				}
				if (useDocServiceDeviceDetails || useDocServiceDeviceDetails == 'true') {
					if (docServiceUrl == '') {
						return;
					}
					let dataNew = new FormData();
					dataNew.append('file', uploadedDocDetails);
					dataNew.append('docType', 'ConsumerProductDocument');
					dataNew.append('clientRefId', key);
					dataNew.append('entity-type', 'ConsumerProductID');
					dataNew.append('entity-id', consumerServiceRequest?.consumerProduct?.ConsumerProductID);
					let url = docServiceUrl + 'internal/document/upload';
					setLoader(true);
					fetch(url, {
						method: 'POST',
						headers: {
							'App': 'WebApp',
							'module': 'WebApp',
							'authorization': authorization
						},
						body: dataNew
					})
					.then(res => res.json())
					.then(result => {
						setLoader(false);
						if(result.success && result.data) {
							let uploadURL = result.data.uploadURL;
							let fileNameUUID = result.data.docServiceUUID;
							setLoader(true);
							apiCall({
								url:'addNewProductDocs',
								// isFormData: true,
								data: {
									updatedData: [{
										ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID, 
										FilePath: uploadURL, 
										Type: 'image', 
										DisplayName: deviceDocTag, 
										FileName: fileNameUUID
									}]
								},
								callBack:(result)=>{
									setLoader(false);
									if(result.success){
										setInputData({...inputData,deviceDocTag:""})
										data = {
											ProductUniqueID: serialNumber,
											ServiceTypeID: consumerServiceRequest.ServiceTypeID,
											ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID,
											ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
											updatedWarranty: {
												ProductUniqueID: serialNumber,
												IsUnderWarranty: warranty,
												IsWarrantyApplicable: !isEWClaim ? getIsUnderWarranty(warrantyApplicable) : undefined,
												Reason: inputData.warrantyDenialReason,
												WarrantyTill: inputData.warrantyTill,
												IsPlanEligible: isEWClaim ? getIsUnderWarranty(warrantyApplicable) : undefined
											},
											updateObj: {
												ProductPurchaseCost: devicePurchaseCost,
												DateOfPurchase: dateOfPurchase
											}
										};
										updateConsumerProductFunc(data);
									}else{
										Swal.fire({
											icon: 'error',
											text: localString(displayError(result)),
										});
									}
								}
							});
						}else {
							Swal.fire({  
								icon: 'error',  
								text: localString(displayError(result)),  
							}); 
						}
					})
					.catch(err => {
						setLoader(false);
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(err)),  
						});
					})
				}
				else{
					apiCall({
						url:'uploadDocuments',
						data: data,
						callBack:(result)=>{
							setLoader(false);
							if(result.success){
								console.table(result);
								var xhr = new XMLHttpRequest();
								xhr.open("PUT", result.data);
								xhr.setRequestHeader("Cache-Control", "public,max-age=3600");
								xhr.setRequestHeader('x-amz-acl', 'public-read');
								xhr.onload = function() {
									if (xhr.status === 200) {
										setLoader(true);
										apiCall({
											url:'addProductDocs',
											data: [{
												ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID, 
												FilePath: key, 
												Type: 'image', 
												DisplayName: deviceDocTag
											}],
											callBack:(result)=>{
												if(result.success){
													setInputData({...inputData,deviceDocTag:""})
													data = {
														ProductUniqueID: serialNumber,
														ServiceTypeID: consumerServiceRequest.ServiceTypeID,
														ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID,
														ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
														updatedWarranty: {
															ProductUniqueID: serialNumber,
															IsUnderWarranty: warranty,
															IsWarrantyApplicable: !isEWClaim ? getIsUnderWarranty(warrantyApplicable) : undefined,
															Reason: inputData.warrantyDenialReason,
															WarrantyTill: inputData.warrantyTill,
															IsPlanEligible: isEWClaim ? getIsUnderWarranty(warrantyApplicable) : undefined
														},
														updateObj: {
															ProductPurchaseCost: devicePurchaseCost,
															DateOfPurchase: dateOfPurchase
														}
													};
													updateConsumerProductFunc(data);
												}else{
													Swal.fire({
														icon: 'error',
														text: localString(displayError(result)),
													});
												}
											}
										})
									}
								}.bind(this);
								xhr.onerror = function() {
								}.bind(this);
								xhr.send(newfile);
							}else{
								Swal.fire({
									icon: 'error',
									text: localString(displayError(result)),
								});
							}
						}
					});
				}
			}else {
				data = {
					ProductUniqueID: serialNumber,
					ServiceTypeID: consumerServiceRequest.ServiceTypeID,
					ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID,
					ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
					updatedWarranty: {
						ProductUniqueID: serialNumber,
						IsUnderWarranty: warranty,
						IsWarrantyApplicable: !isEWClaim ? getIsUnderWarranty(warrantyApplicable) : undefined,
						Reason: inputData.warrantyDenialReason,
						WarrantyTill: inputData.warrantyTill,
						IsPlanEligible: isEWClaim ? getIsUnderWarranty(warrantyApplicable) : undefined
					},
					updateObj: {
						ProductPurchaseCost: devicePurchaseCost,
						DateOfPurchase: dateOfPurchase
					}
				};
				updateConsumerProductFunc(data);
			}
		}
	}

	const updateConsumerProductFunc = (data)=>{
		setLoader(true);
		apiCall({
			url:'updateConsumerProduct',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					setInputData({ ...inputData, ['deviceDocFile'] : ''})
					setUploadedDocDetails(null);
					if(isEWClaim) {
						data = {
							"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
							"IsPlanEligible": data.updatedWarranty ? data.updatedWarranty.IsPlanEligible : undefined
						};
						setLoader(true);
						apiCall({
							url:'convertClaimToNormalRepair',
							data: data,
							callBack:(result)=>{
								setLoader(false);
								if(result.success){
									getRequestById();
									getProductDetails();
									getInsuranceDetails();
									cancelDetailsClick();
								}else{
									Swal.fire({
										icon: 'error',
										text: localString(displayError(result)),
									});
								}
							}
						});
					} else {
						getRequestById();
						getProductDetails();
						cancelDetailsClick();
					}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const validateDetailsClick=()=>{
		let serialNumber = inputData.serialNumber;
		if (serialNumber == '') {
			Swal.fire({
				icon: 'error',
				text: localString("Please enter IMEI/serial number"),
			});
			return;
		}
		else {
			let data = {
				imeiOrSerial: serialNumber,
				ParameterType: 'ConsumerServiceRequestID',
				ParameterValue: consumerServiceRequest.ConsumerServiceRequestID,
				ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID,
				ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
				// ReceivedProductID: self.ReceivedProductID,
				skipLimitLog: 1
			};
			setLoader(true);
			apiCall({
				url:'checkValidDevice',
				data: data,
				callBack:(result)=>{
					setLoader(false);
					if(result.success){
						getRequestById();
						// self.parent.callValidationAttempts();
						cancelDetailsClick();
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		}
	}

	const getIsUnderWarranty = (warrantyApplicable)=> {
		return isEWClaim || activeWarrantyStatus || accessFn('GSX_FLOW') ? (warrantyApplicable != null || undefined ? warrantyApplicable : false) : false;
	};

	const getSalesChannel = ()=>{
		let data = {
			'BrandID': (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID) ? consumerServiceRequest.consumerProduct.BrandID: undefined,
			'CenterCountryID': consumerServiceRequest && consumerServiceRequest.servicelocation && consumerServiceRequest.servicelocation.CountryID ? consumerServiceRequest.servicelocation.CountryID : undefined
		}
		setLoader(true);
		apiCall({
			url:'getSalesChannel',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let channel = result.data && result.data.map(item=>({...item,value:item.SalesChannelName}))
					setSalesChannel(channel)
					// 	self.initSalesChannels(focusAfterInit) //dropdown
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const showAssignLoanerBtn = () => {
		return consumerServiceRequest && (!consumerServiceRequest.loanerDetails || (consumerServiceRequest.loanerDetails && !consumerServiceRequest.loanerDetails.loanerRequested)) ? true : false;
	}

	const fetchAttributesFromSKU = (e) => {
		setFetchAttributesFromSKUCalled(true);
		let data = {
			ProductSkuID:consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSkuID
		}
		setLoader(true);
		apiCall({
			url:'fetchSkuAttributes',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let attributesArray = result && result.data && result.data.data;
					let attributesWithValues = [];
					for(let i = 0; i < attributesArray.length; i++) {
						attributesWithValues.push({
							AttributeName: attributesArray[i].ProductSubCategoryAttributeName,
							AttributeValue: attributesArray[i].ProductSubCategoryAttributeValue
						});
					}
					setAttributesArrayWithValues(attributesWithValues)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const setLoanerTab = () => {
		const e = {
			target: {
				id: "loaner_device_tab"
			}
		}
		showClickedTab(e);
	}

	const deviceArrayNonWorkshop = [{
		showLabel:true,
		labelText:localString("Device"),
		value:consumerServiceRequest && (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.brand.BrandName ) +" "+ ( consumerServiceRequest.consumerProduct &&  consumerServiceRequest.consumerProduct.product && consumerServiceRequest.consumerProduct.product.ProductName)
	},{
		showLabel:consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ModelNo,
		labelText:localString("Model"),
		value:consumerServiceRequest && (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ModelNo)
	},{
		attributesArrayWithValues: attributesArrayWithValues && attributesArrayWithValues.length > 0,
		value: attributesArrayWithValues 
	},{
		showLabel:true,
		labelText:localString("IMEI/Serial Number"),
		editable:editDevice,
		value:consumerServiceRequest && <>{consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductUniqueID || '-'}<br/>{consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey ? consumerServiceRequest.consumerProduct.AlternateUniqueKey : ''} </>,
		dataKey:"serialNumber",
		type:"input",
		onInputFunc:(val)=>{
			setInputData({ ...inputData, ['serialNumber'] : val  })
		}
	},{
		showLabel:([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1),
		labelText : localString("Storage Capacity"),
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.StorageCapacity ? consumerServiceRequest.consumerProduct.StorageCapacity : '-'
	},{
		deviceAttribute:true,
		value:consumerServiceRequest && consumerServiceRequest.DeviceAttributes && consumerServiceRequest.DeviceAttributes.length > 0 && consumerServiceRequest.DeviceAttributes,
	},{
		showLabel:(!([9,13].indexOf(consumerServiceRequest.ServiceTypeID)> -1)),
		labelText : localString("Manufacturer's Warranty Status"),
		editable:editDevice && [SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_ONSITE,].indexOf(consumerServiceRequest.ServiceTypeID) == -1,
		value : (consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.IsUnderWarranty == true ? localString("Under warranty") : localString("Out of warranty")),
		type:"radio",
		radioButtons:[{
			buttonText: "Out of warranty",
			value:"false",
			isSelect:String(activeWarrantyStatus),
			onclickupdate:(val)=>{
				setActiveWarrantyStatus(false)
			}
		},
		{
			buttonText: "Under warranty",
			value:"true",
			isSelect:String(activeWarrantyStatus),
			onclickupdate:(val)=>{
				setActiveWarrantyStatus(true)
			}
		}],
	},{
		showLabel:isEWClaim || (consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.IsUnderWarranty && !editDevice) || (editDevice && activeWarrantyStatus == true),
		labelText : isEWClaim ? localString("Covered Under Plan") : localString("Is Manufacturer's Warranty Applicable"),
		editable:editDevice && warrantyEdit,
		type:"radio",
		radioButtons:[{
			buttonText: "Yes",
			value: "true",
			isSelect: String(warrantyValue),
			onclickupdate:(val)=>{
				setWarrantyValue(val);
				setActiveWarrantyApplication(true);
			}
		},
		{
			buttonText: "No",
			value: "false",
			isSelect: String(warrantyValue),
			onclickupdate:(val)=>{
				setWarrantyValue(val);
				setActiveWarrantyApplication(false);
			}
		}],
		value :!isEWClaim && (!editDevice || !warrantyEdit) ? (consumerServiceRequest && consumerServiceRequest.IsWarrantyApplicable == true ? localString("Yes") : consumerServiceRequest.IsWarrantyApplicable == false ? localString("No") : '-') : (consumerServiceRequest.IsPlanEligible == true ? localString("Yes") : consumerServiceRequest.IsPlanEligible == false ? localString("No") : '-')
	},{
		showLabel:consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.IsUnderWarranty && !editDevice && consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.IsWarrantyApplicable == false || (editDevice && activeWarrantyStatus == true && activeWarrantyApplication == false),
		labelText : localString("Reason"),
		editable:editDevice && ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1 || isEWClaim),
		value : (!editDevice || editDevice) && ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !isEWClaim)
			&& consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.Reason || 'NA',
		dataKey:"warrantyDenialReason",
		id:"warranty_denial_reason",
		type:"input",
		onInputFunc:(val)=>{
			setInputData({ ...inputData, ['warrantyDenialReason'] : val  })
		}
	},{
		showLabel:(!isEWClaim && ((consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.IsUnderWarranty && !editDevice)) || (editDevice && activeWarrantyStatus == true)),
		labelText : localString("Warranty Till"),
		editable:(editDevice && warrantyEdit) && [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) == -1,
		value :  (!editDevice || !warrantyEdit) && consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.WarrantyTill ? localString(getWarrantyDate(consumerServiceRequest.consumerProduct.WarrantyTill)):'-',
		dataKey:"warrantyTill",
		id:"warranty_till",
		type:"datePicker",
		// minDay:moment().toDate(),
		onInputFunc:(val)=>{
			setInputData({ ...inputData, ['warrantyTill'] : val  })
		},
		reference: "ref1"
	},{
		showLabel:soldPlan && soldPlan.ExternalReferenceID,
		labelText : localString("Policy ID"),
		value : soldPlan && soldPlan.ExternalReferenceID
	},
	{
		showProtectionStatus:consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductDetails && consumerServiceRequest.consumerProduct.ProductDetails.length > 0,
		labelText : localString("Protection Status"),
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductDetails
	},{
		showCoverageDetails:coverageDetails && coverageDetails.length > 0,
		labelText : localString("Plan Benefits"),
		value : coverageDetails && coverageDetails
	},{
		showLabel:highRiskReason && highRiskReason.length > 0,
		labelText : localString("High Risk Reason(s)"),
		value : highRiskReason && highRiskReason
	},{
		showLabel:consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.YearOfPurchase,
		labelText : localString("Year of Purchase"),
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.YearOfPurchase
	},{
		showLabel: ([SERVICETYPE.PICK_UP, SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && !accessFn('HIDE_DEVICE_DETAILS_INFO') ,
		labelText : localString("Date of Purchase"),
		editable:editDevice,
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.DateOfPurchase ? getLocalDateTime(consumerServiceRequest.consumerProduct.DateOfPurchase,"DD-MM-YYYY") : '-',
		dataKey:"dateOfPurchase",
		id:"dateOfPurchase",
		type:"datePicker",
		// maxDay:moment().toDate(),
		onInputFunc:(val)=>{
			setInputData({ ...inputData, ['dateOfPurchase'] : val  })
		},
		reference: "ref2"
	},{
		showLabel:([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.BER_REPLACEMENT].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && !accessFn('HIDE_DEVICE_DETAILS_INFO'),
		labelText : localString("Device Value"),
		editable:editDevice,
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost || '-',
		dataKey:"devicePurchaseCost",
		id:"devicePurchaseCost",
		type:"input",
		currency:currencySymbol,
		// pattern:regexTest(),
		onInputFunc:(val)=>{
			const regex = /^\d*\.?\d*$/;
			if (regex.test(val)) {
				setInputData({ ...inputData, ['devicePurchaseCost'] : val  })
			}
		}
	},{
		showProductDoc:(consumerProductDocuments && ((consumerProductDocuments.FilePath.length !=0 ) && (consumerProductDocuments.FilePath[0] != null) && (consumerProductDocuments.FilePath[0] !="" ) && !editDevice) || editDevice) && [9,13].indexOf(consumerServiceRequest.ServiceTypeID) == -1 && !accessFn('HIDE_DEVICE_DETAILS_INFO'),
		labelText : localString("Registration Documents"),//for="Documents"
		value : consumerProductDocuments && consumerProductDocuments,
		dataKey:"deviceDocFile",
		id:"deviceDocFile",
		type:"file",
		editable:editDevice,
		onInputFunc:(val)=>{
			setInputData({ ...inputData, ['deviceDocFile'] : val.value  })
		},
		documentOptions:documentOptions,

	},{
		showLabel:true,
		labelText : localString("Device Added Date"),
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && returnLocalDateTime(consumerServiceRequest.consumerProduct.CreatedDate)
	},{
		showLabel:([9,13].indexOf(consumerServiceRequest.ServiceTypeID)> -1),
		labelText : localString("GSX Validation Status"),
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.IsVerified ? localString("Validated") : localString("Not Validated")
	},{
		showMirrorTest:consumerServiceRequest && mirrorTestResults && ((mirrorTestResults.ConsumerProductTestResult && mirrorTestResults.ConsumerProductTestResult.length) || (mirrorTestResults.SoldPlanTestResult && mirrorTestResults.SoldPlanTestResult.length)),
		labelText : localString("Crack Screen Test Results"),
		value : mirrorTestResults && mirrorTestResults
	},]

	const deviceArrayWorkshop=[{
		showLabel:true,
		labelText:localString("Device"),
		value: consumerServiceRequest && consumerServiceRequest?.consumerProduct && consumerServiceRequest.consumerProduct.product?.ProductName + " " + (consumerServiceRequest.Source != "SkyworthDashboard" && consumerServiceRequest.consumerProduct.ModelNo ? consumerServiceRequest.consumerProduct.ModelNo + ' ' : '') + (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.StorageCapacity && consumerServiceRequest.consumerProduct.StorageCapacity ? consumerServiceRequest.consumerProduct.StorageCapacity : ""),
	},{
		showLabel:true,
		labelText:localString("Brand"),
		value:consumerServiceRequest && (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.brand && consumerServiceRequest.consumerProduct.brand.BrandName || '-' ) 
	},{
		showLabel:true, 
		labelText:localString("Sub Category"),
		value:(!editDevice || !consumerServiceRequest.isAnchorRequest) && consumerServiceRequest && (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubcategory.ProductSubCategory || '-'),
	},{
		showLabel:true,  
		labelText:localString("Product"),
		value:consumerServiceRequest && (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.product.ProductName || '-'),
	},{
		showLabel:true,
		labelText:localString("IMEI/Serial Number"),
		editable:editDevice && !accessFn('GSX_FLOW') && !accessFn('NOT_ALLOWED_TO_EDIT_SALES_WARRANTY_DETAILS'),
		value:!editDevice || (accessFn('GSX_FLOW') && consumerServiceRequest.isVerifiedProduct)? consumerServiceRequest && 
			<>{consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductUniqueID || '-'}
				<br/>
				{consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey ? consumerServiceRequest.consumerProduct.AlternateUniqueKey : ''} 
			</> :
			editDevice && accessFn('NOT_ALLOWED_TO_EDIT_SALES_WARRANTY_DETAILS') && 
			<>{consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductUniqueID || '-'}
				<br/>
				{consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.AlternateUniqueKey ? consumerServiceRequest.consumerProduct.AlternateUniqueKey : ''} 
			</>,
		dataKey:"serialNumber",
		id:"serial_number",
		type:"input",
		maxlength:15,
		onInputFunc:(val)=>{
			setInputData({ ...inputData, ['serialNumber'] : val  })
		},
		checkImei:(e)=>{
			checkImeiValueValid(e)
		}
	},{
		showLabel:isNonSerializedProduct && accessFn('GSX_FLOW'),
		labelText : localString("GSX Serial No (for Non Serialised Product)"),
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue ? consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue : ''
	},{
		showLabel:([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) == -1),
		labelText : localString("Storage Capacity"),
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.StorageCapacity ? consumerServiceRequest.consumerProduct.StorageCapacity : '-'
	},{
		deviceAttribute:true,
		value:consumerServiceRequest && consumerServiceRequest.DeviceAttributes && consumerServiceRequest.DeviceAttributes.length > 0 && consumerServiceRequest.DeviceAttributes,
	},{
		showLabel:(!([9,13].indexOf(consumerServiceRequest.ServiceTypeID)> -1)),
		labelText : localString("Manufacturer's Warranty Status"),
		editable:editDevice && !accessFn('GSX_FLOW') && !accessFn('NOT_ALLOWED_TO_EDIT_SALES_WARRANTY_DETAILS') && [SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1,
		value : editDevice && (accessFn('NOT_ALLOWED_TO_EDIT_SALES_WARRANTY_DETAILS') || [SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) ? (consumerServiceRequest.consumerProduct.IsUnderWarranty == true ? localString("Under warranty") : localString("Out of warranty")) 
				:   accessFn('GSX_FLOW') ? consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.IsUnderWarranty ? consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.WarrantyType : localString("Not Covered"):consumerServiceRequest &&  (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.IsUnderWarranty == true ? localString("Under warranty") : localString("Out of warranty")),
		type:"radio",
		radioButtons:[{
			buttonText: "Out of warranty",
			value: "false",
			isSelect: String(activeWarrantyStatus),
			onclickupdate:(val)=>{
				setActiveWarrantyStatus(false)
			}
		},
		{
			buttonText: "Under warranty",
			value: "true",
			isSelect: String(activeWarrantyStatus),
			onclickupdate:(val)=>{
				setActiveWarrantyStatus(true)
			}
		}],
	},{
		showLabel:(isEWClaim || (consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.IsUnderWarranty && !editDevice) || (editDevice && activeWarrantyStatus == true) || accessFn("GSX_FLOW")) && [SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest.ServiceTypeID) === -1,
		labelText : isEWClaim ? localString("Covered Under Plan") : localString("Is Manufacturer's Warranty Applicable"),
		editable:editDevice && (warrantyEdit || [SERVICETYPE.INSTALLATION,6,7].indexOf(consumerServiceRequest.ServiceTypeID) > -1),
		type:"radio",
		radioButtons:[{
			buttonText: "Yes",
			value:"true",
			isSelect: String(warrantyValue) ,
			onclickupdate:(val)=>{
				setWarrantyValue(val)
				setActiveWarrantyApplication(true);
			}
		},
		{
			buttonText: "No",
			value:"false",
			isSelect: String(warrantyValue) ,
			onclickupdate:(val)=>{
				setWarrantyValue(val);
				setActiveWarrantyApplication(false);
			}
		}],
		value :!isEWClaim && (!editDevice || (!warrantyEdit && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) === -1)) 
			? (consumerServiceRequest.IsWarrantyApplicable == true ? localString("Yes") : consumerServiceRequest.IsWarrantyApplicable == false ? localString("No") : '-') 
			: isEWClaim && (!editDevice || (!warrantyEdit && [SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) === -1)) && (consumerServiceRequest.IsPlanEligible == true ? localString("Yes") : consumerServiceRequest.IsPlanEligible == false ? localString("No") : '-'),
		loanerButton : accessFn('LOANER_FLOW') && (consumerServiceRequest.IsWarrantyApplicable == true && accessFn("GSX_FLOW") && accessFn('ACCEPT_RETURN_DEVICE') && ['Service accepted','Payment received','QC pending','QC completed','Service invoice generated','Device received','Intimate customer','Repair cancel','Repair cancel initiated','Device transferred','Provisional estimate generated','Provisional estimate updated'].indexOf(consumerServiceRequest.Status) === -1 && showAssignLoanerBtn()),
		loanerCount: loanerDeviceCount,
		setLoanerTabClick: {setLoanerTab},
	},{
		showLabel: (consumerServiceRequest && consumerServiceRequest.consumerProduct && (consumerServiceRequest.consumerProduct.IsUnderWarranty && !editDevice && consumerServiceRequest.IsWarrantyApplicable == false) || (editDevice && activeWarrantyStatus == true && activeWarrantyApplication == false) || activeWarrantyApplication == true && activeWarrantyStatus == false && editDevice && accessFn('GSX_FLOW') || (!consumerServiceRequest?.consumerProduct?.IsUnderWarranty && !editDevice && consumerServiceRequest.IsWarrantyApplicable == true && accessFn('GSX_FLOW'))),
		labelText : localString("Reason"),
		editable:editDevice && ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,61,62].indexOf(consumerServiceRequest.ServiceTypeID) === -1 || isEWClaim), //value read
		value : (!editDevice || (editDevice && ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && !isEWClaim)))
			&& consumerServiceRequest && consumerServiceRequest.WarrantyReason || 'NA',
		dataKey:"warrantyDenialReason",
		id:"warranty_denial_reason",
		type:"input",
		onInputFunc:(val)=>{
			setInputData({ ...inputData, ['warrantyDenialReason'] : val  })
		}
	},{
		showLabel: !isEWClaim && ((consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.IsUnderWarranty && !editDevice) || (editDevice && activeWarrantyStatus == true)) && (!consumerServiceRequest.showEngineerVisits),
		labelText: localString("Warranty Till"),
		editable:editDevice && warrantyEdit && !accessFn('NOT_ALLOWED_TO_EDIT_SALES_WARRANTY_DETAILS') && !accessFn("GSX_FLOW"),
		value :(!editDevice || !warrantyEdit) ? consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.WarrantyTill && localString(getLocaleDateString(consumerServiceRequest.consumerProduct.WarrantyTill,defaultCountryLocale)) : '-',
		dataKey:"warrantyTill",
		id:"warranty_till",
		type:"datePicker",
		// minDay:moment().toDate(),
		onInputFunc:(val)=>{
			setInputData({ ...inputData, ['warrantyTill'] : val  })
		},
		reference: "ref1"
	},{
		showProtectionStatus: consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductDetails && consumerServiceRequest.consumerProduct.ProductDetails.length > 0,
		labelText : localString("Protection Status"),
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductDetails
	},{
		showCoverageDetails:coverageDetails && coverageDetails.length > 0,
		labelText : localString("Plan Benefits"),
		value : coverageDetails && coverageDetails
	},{
		showLabel:highRiskReason && highRiskReason.length > 0,
		labelText : localString("High Risk Reason(s)"),
		value : highRiskReason && highRiskReason
	},{
		showLabel:consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.YearOfPurchase,
		labelText : localString("Year of Purchase"),
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.YearOfPurchase
	},{ 
		showLabel:([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.INTERNAL_JOB,61,62].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && !accessFn('HIDE_DEVICE_DETAILS_INFO'),
		labelText: localString("Date of Purchase"),
		editable :editDevice && !accessFn('NOT_ALLOWED_TO_EDIT_SALES_WARRANTY_DETAILS') &&(!accessFn('GSX_FLOW') || (accessFn('GSX_FLOW') && (!consumerServiceRequest.consumerProduct.isAppleBrandID || ((isNonSerializedProduct || isUnDiagnosable) && !consumerServiceRequest?.consumerProduct?.DateOfPurchase)))),
		// value: (!editDevice || (accessFn('GSX_FLOW') && (editDevice && consumerServiceRequest.consumerProduct.isAppleBrandID && ((!isNonSerializedProduct && !isUnDiagnosable) || ((isNonSerializedProduct || isUnDiagnosable) && consumerProductDocuments?.consumerProduct?.DateOfPurchase))))) ? 
		// 		consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.DateOfPurchase ? getLocalDateTime(consumerServiceRequest.consumerProduct.DateOfPurchase,"DD-MM-YYYY") : '-'
		// 		:editDevice && accessFn('NOT_ALLOWED_TO_EDIT_SALES_WARRANTY_DETAILS')&& 
		// 		consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.DateOfPurchase ? getLocalDateTime(consumerServiceRequest.consumerProduct.DateOfPurchase,"DD-MM-YYYY") : '-',
		value: consumerServiceRequest?.consumerProduct?.DateOfPurchase ? getLocalDateTime(consumerServiceRequest.consumerProduct.DateOfPurchase,"DD-MM-YYYY") : '-',
		dataKey:"dateOfPurchase",
		id:"date-Of-Purchase",
		type:"datePicker",
		// maxDay:moment().toDate(),
		//minDay:moment().toDate(),
		onInputFunc:(val)=>{
			setInputData({ ...inputData, ['dateOfPurchase'] : val  })
		},
		reference: "ref2"
	},{
		showLabel:accessFn('GSX_FLOW') && consumerProductDocuments && consumerProductDocuments.PurchaseCountry,
		labelText : localString("Country of Purchase"),
		value : consumerProductDocuments && consumerProductDocuments.PurchaseCountry 
	},{
		showLabel:[SERVICETYPE.ON_SITE,6,7,61,62,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) === -1,
		labelText : localString("Sales Channel"),
		value : ((!salesChannel || (salesChannel && salesChannel.length == 0) || !editDevice) && consumerServiceRequest && consumerServiceRequest.SalesChannelName ? consumerServiceRequest.SalesChannelName : '-'),
		editable:salesChannel && salesChannel.length > 0 && editDevice,
		type:"dropdown",
		dropdownList:salesChannel,
		dataKey:"salesChannelName",
		id:"saleschannel",
		edittClass:"device-details-sales-channel",
		onInputFunc:(val)=>{
			setInputData({...inputData,['salesChannelID']:val.SalesChannelID,['salesChannelName']:val.SalesChannelName})
		}
	},{
		showLabel:(consumerServiceRequest.Source !== "SkyworthDashboard" && !accessFn('HIDE_DEVICE_DETAILS_INFO')),
		labelText : localString("Device Value"),
		editable:editDevice && (!accessFn('GSX_FLOW') || (accessFn('GSX_FLOW') && consumerServiceRequest.isVerifiedProduct)),
		value :(!editDevice || (accessFn("GSX_FLOW")) && !consumerServiceRequest.isVerifiedProduct) &&  consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductPurchaseCost || '-',
		dataKey:"devicePurchaseCost",
		id:"devicePurchaseCost",
		type:"input",
		// pattern:"^(0|[1-9]\d*)(\.\d+)?$",
		currency:currencySymbol,
		onInputFunc:(val)=>{
			const regex = /^\d*\.?\d*$/;
			if (regex.test(val)) {
				setInputData({ ...inputData, ['devicePurchaseCost'] : val  })
			}
		}
	},{
		showProductDoc:(consumerServiceRequest && consumerProductDocuments && consumerProductDocuments.FilePath &&  ((consumerProductDocuments.FilePath.length !=0 ) && (consumerProductDocuments.FilePath[0] != null) && (consumerProductDocuments.FilePath[0] !="" ) && !editDevice) || (editDevice && ['SkyworthDashboard','BoseDashboard','SmartTechnoDashboard'].indexOf(consumerServiceRequest.Source) === -1)) && [9,13].indexOf(consumerServiceRequest.ServiceTypeID) == -1 && !accessFn('HIDE_DEVICE_DETAILS_INFO'),
		labelText : localString("Registration Documents"),//for="Documents"
		value : consumerProductDocuments && consumerProductDocuments,
		dataKey:"deviceDocFile",
		id:"deviceDocFile",
		type:"file",
		editable:editDevice,
		onInputFunc:(val)=>{
			setInputData({ ...inputData, ['deviceDocFile'] : val.value  })
		},
		documentOptions:documentOptions,
	},{
		showLabel:([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1),
		labelText : localString("Device Added Date"),
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && returnLocalDateTime(consumerServiceRequest.consumerProduct.CreatedDate,"DD-MM-YYYY")
	},{ 
		showLabel:(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.gsxInfo && consumerServiceRequest.consumerProduct.gsxInfo.configDescription || consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ConfigDescription),
		labelText : localString("Config Description"),
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && (consumerServiceRequest.consumerProduct.gsxInfo && consumerServiceRequest.consumerProduct.gsxInfo.configDescription || consumerServiceRequest.consumerProduct.ConfigDescription)
	},{
		showLabel:([9,13].indexOf(consumerServiceRequest.ServiceTypeID)> -1),
		labelText : localString("Validation Status"),
		value : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.IsVerified ? localString("Validated") : localString("Not Validated")
	},{
		showLabel:accessFn('GSX_FLOW'),
		labelText : localString("Warranty Validated from Brand"),
		value : consumerServiceRequest && consumerServiceRequest.isVerifiedProduct ? localString('Yes') : localString('No')
	},{
		showInspGuide:consumerProductDocuments && consumerProductDocuments.productConfig && consumerProductDocuments.productConfig.InspectionGuideUrl && consumerProductDocuments.productConfig.InspectionGuideUrl.length && consumerServiceRequest.Source !== "SkyworthDashboard",
		labelText : localString("Inspection Guide"),
		value : consumerProductDocuments && consumerProductDocuments
	},{
		showMirrorTest:consumerServiceRequest && mirrorTestResults && ((mirrorTestResults.ConsumerProductTestResult && mirrorTestResults.ConsumerProductTestResult.length) || (mirrorTestResults.SoldPlanTestResult && mirrorTestResults.SoldPlanTestResult.length)),
		labelText : localString("Crack Screen Test Results"),
		value : mirrorTestResults && mirrorTestResults
	}]

	return (
		<>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<DeviceDetailsTable 
				{...props}
				deviceDetails={(!accessFn('WORKSHOP_FLOW') && consumerServiceRequest.ConsumerProductID) ? deviceArrayNonWorkshop : deviceArrayWorkshop }
				editDevice={editDevice}
				mirrorTestResults={mirrorTestResults}
				isEWClaim={isEWClaim}
				warrantyEdit={warrantyEdit}
				coverageDetails={coverageDetails}
				inputData={inputData}
				consumerServiceRequest={consumerServiceRequest}
				enableInputFunction={enableDeviceClick}
				selectedFile={selectedFile}
				cancelDetailsClick={cancelDetailsClick}
				saveButtonFunction={saveDeviceDetails}
				validateDetailsClick={validateDetailsClick}
				showEditButton={showEditButton}
				setActiveTab={setActiveTab}
			/>
		</>
	)
}

export default DeviceDetails
