import React,{useState, useEffect}  from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import {find,isEmpty,findIndex,filter,sortBy,map,max,orderBy,uniq} from 'lodash'
import { returnTimezone,displayError,returnLocalDateTime,SERVICETYPE ,returnLocalSlot,checkEncryption,encrypt,getStatusString} from '../../../Utility/Utils';
import ViewButtons from './ViewRequestMastHeadDetails/ViewButtons/ViewButtons';
import RightSidebar from '../../../Partials/RightSidebar/RightSidebar';
import RequestDetails from './NavBar/RequestDetails/RequestDetails';
import NavigationBar from './NavBar/NavigationBar';
import ActionLink from '../../../components/ActionLink/ActionLink';
import ViewMastHeadDetails from './ViewRequestMastHeadDetails/ViewMastheadDetails';
import ConsumerServiceRequest from '../ConsumerServiceRequest';
import './ViewServiceRequest.scss';
import _ from 'lodash';
const ViewServiceRequest = (props)=>{
    const {accessFn,localString,apiCall,useQuery,baseUrl,jobcard,publicUrl,userID} = props;

	const qFilter = JSON.parse(props.qFilter);

    const [showLoader,setLoader]=useState(false);
    const [csrLoader,setCsrLoader]=useState(false);
    const [claimLoader,setClaimLoader]=useState(false);
    const [consumerDetailLoader, setConsumerDetailLoader] = useState(false);
    const [escLoader, setEscLoader] = useState(false);
    const [escDataLoader, setEscDataLoader] = useState(false);
    const [tatLoader, setTatLoader] = useState(false);
	const [actualCostLoader, setActualCostLoader] = useState(false);
	const [countryLoader, setCountryLoader] = useState(false);
	const [configLoader, setConfigLoader] = useState(false);
	const [insuranceLoader, setInsuranceLoader] = useState(false);
	const [spLoader, setSpLoader] = useState(false);
	const [decLoader, setDecLoader] = useState(false);
	const [checkListLoader, setCheckListLoader] = useState(false);
	const [logisticLoader, setLogisticLoader] = useState(false);
	const [emptyBoxLoader, setEmptyBoxLoader] = useState(false);
	const [extChargesLoader, setExtChargesLoader] = useState(false);
	const [billLoader, setBillLoader] = useState(false);
	const [subLoader, setSubLoader] = useState(false);
	const [paymentLinkLoader, setPaymentLinkLoader] = useState(false);
	const [fALoader, setFALoader] = useState(false);
	const [sfaLoader, setSFALoader] = useState(false);
	const [partLoader, setPartLoader] = useState(false);
	const [dSuitesLoader, setDSuitesLoader] = useState(false);
	const [partsLoader, setPartsLoader] = useState(false);

	const [consumerServiceRequest,setConsumerServiceRequest]= useState([]);
	const [serviceType,setServiceType]=useState([]);
    const [editableEscalationData,setEditableEscalationData]=useState([]);
    const [BlockDecisionMatrixFlow,setBlockDecisionMatrixFlow]= useState(false);
    const [AdvanceChargeApplicable,setAdvanceChargeApplicable] = useState(null);
    const [paymentLinkStatus,setPaymentLinkStatus] = useState();
    const [exception,setException] = useState();
    const [exceptionLogs ,setExceptionLogs] = useState();
	const [consumerDetail, setConsumerDetail] = useState([])
	const [insurancePlans, setInsurancePlans] = useState([]);
	const [coverageDetails, setCoverageDetails] = useState([])
	const [defaultCountryLocale, setDefaultCountryLocale] = useState()
	const [currencySymbol, setCurrencySymbol] = useState('&#8377;')
	const [currencyData, setCurrencyData] = useState(null);
    const [invoice,setInvoice]= useState("");
    const [estimationdoc,setEstimationdoc]= useState();
    const [defaultCountry,setDefaultCountry]=useState();
    const [escalationTag,setEscalationTag] = useState([]);
    const [escalationTagColor,setEscalationTagColor] = useState('');
    const [AdvanceReceipts,setAdvanceReceipts]=useState([]);
	const [addedFaultActions, setAddedFaultActions] = useState([]);
	const [addedFaultCodes,setAddedFaultCodes] = useState([]);
	const [planID, setPlanID] = useState();
	const [soldPlanCoverage, setSoldPlanCoverage] = useState([]);
	const [adjudicationChecklistData, setAdjudicationChecklistData] = useState([]);
	const [partsRequested, setPartsRequested] = useState([]);
	// const [isRequestSFANeeded, setIsRequestSFANeeded] = useState();
	const [role,setRole]=useState();
	const [featureConfig,setFeatureConfig]=useState();
	const [logisticsContact ,setLogisticsContact ]=useState([]);
	// const [countryConfig,setCountryConfig]=useState([])
	const [claimDamageId,setClaimDamageId]=useState(null);
	const [paymentAmount,setPaymentAmount]=useState();
	const [tatColor,setTatColor]= useState("gray");
	const [showButton,setShowButton]=useState(true);
	const [showButtonRefresh,setShowButtonRefresh] = useState(false);
	const [showActionButton,setShowActionButton]=useState(false);
	const [taskListArr,setTaskListArr]=useState([]);
	const [accessPoints,setAccessPoints]=useState([]);
	const [advancePaymentCallFetched ,setAdvancePaymentCallFetched ]=useState(false)
	const [requestSFA,setRequestSFA] = useState([]);
	const [consumerProductDocuments, setConsumerProductDocuments] = useState()
	const [loanerDeviceList, setLoanerDeviceList] = useState();
    const [loanerDetails, setLoanerDetails] = useState(null);
	const [tradeInDetails, setTradeInDetails] = useState([])
	const [tradeInDashboardDetails, setTradeInDashboardDetails] = useState([])
	const [highRiskReason, setHighRiskReason] = useState([])
	const [billingDetailList, setBillingDetailList] = useState([])
	const [selectedBillingDetail, setSelectedBillingDetail] = useState("")
	const [countriesData, setCountriesData] = useState()
	const [selectedCountry, setSelectedCountry] = useState()
	const [documents, setDocuments] = useState([])
	// const [partsList,setPartList] = useState([]);
	const [reqiredDocumentsMaster, setReqiredDocumentsMaster] = useState([])
	const [pendingDocuments, setPendingDocuments] = useState([])
	const [canUpdateServiceRequest, setCanUpdateServiceRequest] = useState(false)
	const [openTaskCount, setOpenTaskCount] = useState(0)
	const [logisticsRequestDetailsImage, setLogisticsRequestDetailsImage] = useState([])
	const [logisticsRequestDetails, setLogisticsRequestDetails] = useState([])
	const [newProductDetail, setNewProductDetail] = useState([])
	const [serviceLocationData, setServiceLocationData] = useState([])
	const [arrDiagnosisSuites,setArrDiagnosisSuites] = useState([]);
	const [diagnosisSuites,setDiagnosisSuites] = useState([]);
	const [diagnosticsSuitesCalled, setDiagnosticsSuitesCalled] = useState(false);
	const [isRescheduleApplicable,setIsRescheduleApplicable] = useState(false)
	const [countriesChecks, setCountriesChecks] = useState({
		arrAllowedMobileLengths : [10],
        intAllowedMobileLengths : 10,
        arrAllowedPinCodeLengths : [6],
        intAllowedPinCodeLengths : 6,
        skipZipCodeCheck : false,
		countryConfig: null,
		TaxDetailName: null,
		TaxDetailValue: null,
		currencyCode:"",
		selectedCountryCode:""

	})

	const [showRequestLogs,setShowRequestLogs]=useState({
		isServiceCancel :false,
		isServiceComplete:false,
		isClaimWithdrawn:false,
		enableAttemptCall:false,
		isWorkStarted:false,
		isDiagnosisCompletedFlag:false,
		isDeviceReceived:false,
		isPartRequestCreated:false,
		isInvoiceGenerated:false,
		isPartsPending:false,
		isProvisionalChargesAdded : false ,
		isRepairCompleted : false,
		isAdvanceCollected:false,
		isRepairCancel: false,
		showInitiateException:false,
		isDeviceInspected:false,
		isRepairCancelled:false,
		isHardwareClosure:false,
		showProvisionalEstimationTab:false,
		showEstimationDoc :false,
		isPartsIssued:false,
		isIssuesValidated:false,
		InvoiceGeneratedDate:false,
		isProcessingRefund:false,
		isExceptionApproved:false,
		isExceptionRejected:false,
		isExceptionOverriden :false,
		pendingForApprovalIndex:false,
		exceptionRaisedIndex:false,
		showCompleteRefund:false,
		showApproveException:false,
		isExceptionFlow:false,
		isDOAApplicableLog:false,
		isSupportingDocsRequired:false,
		isQcCompleted:false,
		isIssuesAddedByEng:false,
		isAllPartReceived:false,
		diagnosisIntiateByEngg:false,
		diagnosisCompletedByEngg:false,
		diagnosisCancelByEngg:false,
		diagnosisIntiateByEngg2:false,
		diagnosisCompletedByEngg2:false,
		diagnosisCancelByEngg2:false,
		isAllPartsRejected:true,
		BERConfirmed:false,
		isServiceCompleteRated:false,
		isDeviceDispatch:false,
		isDeviceValidated:false,
		isDefectiveReceived:false,
		securityDepositChargeFailed:false,
		isDOACompleted:false,
		isRefurbishmentRequestCreated:false,
		showDispositionDetailsSec:false,
	})
	const [showRequestValid,setShowRequestValid]=useState({
		showTaskTab:false,
		hasOverridden:false,
		checkRequestedDocuments:false,
		hideNextStepInTradeIn:false,
		canUpdateServiceRequest:true,
		showOnsiteInvoice:false,
		//showNewInvoice:false
		productNotMapped:true,
		isShipmentCountryCodesFetched:false,
		isRequestSFANeeded:false,
		hideIssueLoanerButton:false,
		sendIssuesGSXFlag:false,
		isOutOfWarranty:false,
		isChargeAdded:false,
		showAccessoriesMsg: false,
        showPendingDocMsg : false,
		allowCCToProceedWithService:false
	})
    const [ showViewRequestFlags,setShowViewRequestFlags ]=useState({
        isExceptionCase : false,
        // showAccessoriesMsg: false,
        // showPendingDocMsg : false,
        showShippedButtonForStatus :true,
        planSkipClaimApproval:false,
		isCancelApplicable:false,
		cancelAnytime : true,
		isPaymentLinkSent :false,
		advancePaymentCallFetched:false,
		viewDiagnosis:false,
    })
	const [countryCode,setCountryCode] = useState('')
	const [assignObjCheck, setAssignObjCheck] = useState({
		showservicelocationlabel:false,
		showassignmenttag:false,
		showengineertypeahead:false,
		showassignmentbutton:false,
		showengineerlabel:false,
		showservicelocationtypeahead:false,
		showpartstatusupdatebutton:false,
		showestimationoverride:false,

	});
	const [berFlags,setBerFlags] = useState({
		checkBERDeviceRequiredCalled :false,
		fetchBERConfigurationCalled:false,
		defectiveRequired : false,
		allowRepairBtn:false,
		allowReplacementBtn:false,
		allowRefundBtn:false
	})
	const [showDeviceDetails, setShowDeviceDetails] = useState(false)
	const [SFARequest,setSFARequest] = useState(false);
	const [showRefreshButton,setShowRefreshButton]=useState(false);
	const [isEmptyBoxServiceable,setIsEmptyBoxServiceable]=useState(false);
	const [emptyBoxPackagingDetails,setEmptyBoxPackagingDetails]=useState([]);
	const [callLogs,setCallLogs] = useState([]);
	const [callLogCount, setCallLogCount] = useState('');
	const [currencyCode,setCurrencyCode] = useState();
	const [isSubscriptionEnabled,setIsSubscriptionEnabled] = useState(null);
	const [enableHighRisk,setEnableHighRisk] = useState(false)
    const [showShipPackagingMaterialButton,setShowShipPackagingMaterialButton]=useState(false)
	const [deviceAtLoggedInLoc, setDeviceAtLoggedInLoc] = useState(false)
	const [hideAssignOption, setHideAssignOption] = useState(false)
	const [savedDeviceOptionsFetched,setSavedDeviceOptionsFetched] = useState(false);
    const [savedOptions,setSavedOptions] = useState([]);
    const [savedOptionKeys,setSavedOptionKeys] = useState([]);
	const [partsList, setPartsList] = useState(null)
	const [actualCostAllowed, setActualCostAllowed] = useState(false)
	const [actualCost, setActualCost] = useState([]);
	const [selectPlanBenefit,setSelectPlanBenefit] = useState({})
	const [previousStatus,setPreviousStatus] = useState('')
	const [locationsCalledAlready,setLocationsCalledAlready] = useState(false)
	const [soldPlan ,setSoldPlan] = useState(null);
	const [refurbishmentRequestData, setRefurbishmentRequestData] = useState([])
	const [activeCoverages,setActiveCoverages] = useState(null);
	//loaner
	const [loanerChargesResult, setLoanerChargesResult] = useState([]); 
	const [loanerCharges, setLoanerCharges] = useState();
	const [currentLoanerStatus, setCurrentLoanerStatus] = useState();
	const [DOAEntries, setDOAEntries] = useState([])
	const [fetchDetailsCalled, setFetchDetailsCalled] = useState(false);
	//

	//Case Score
	const [scoreDetails, setScoreDetails] = useState({})

    //  component checks
	const claimNewWorkshopChecks = ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW');
	const claimChecks = [SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && !accessFn('WORKSHOP_FLOW') && !accessFn('READ_ONLY');
    const pickupCarryInGSXChecks = ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW');
	const pickupCarryInChecks = ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('WORKSHOP_FLOW');
	const onsiteChecks = ([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,56,61,62,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID)> -1);
	const berChecks = ([SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPLACEMENT].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && !accessFn('READ_ONLY');
	const replacementFlowChecks = ([46,63,65,66].indexOf(consumerServiceRequest.ServiceTypeID)> -1);
	const isBoseFlow = ([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && qFilter.flowType && qFilter.flowType.fulFillmentFlow && (qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_BOSE') > -1);
	const refundProcessCheck = (([35].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('REFUND_PROCESS'));
	const serviceRequestClusterCheck=([36].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && accessFn('SERVICE_REQUEST_CLUSTER');
	const appleDropOffFlow = consumerServiceRequest?.CREATED_AT_DROPOFF_PSL && (consumerServiceRequest.CREATED_AT_DROPOFF_PSL === "true") && accessFn('GSX_FLOW');
	const refurbishmentFlowCheck = (([SERVICETYPE.DEFECTIVE_REFURBISHMENT].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && !accessFn('WORKSHOP_FLOW'))
	const allTabs = {
		showConsumrDetails : false,
		showOtherRequest : false,
		showAccessPoint : false,
		showPartsTracking : false,
		showConsumrAddress : false,
		showRaiseRequest : false,
		showDeviceDetails : false,
		showRequestDocuments : false,
		showRequestProof : false,
		showIssueValidation : false,
		showSymtomFaultAction : false,
		showSendIssueGSX : false,
		showPartEstimation : false,
		showOverrideApprovalHistory : false,
		showPartRequested : false,
		showDeadOnArrival : false,
		showRequestDetails : false,
		showVisitDetails : false,
		showTaskList : false,
		showLogisticsDetails : false,
		showCommunicationHistory : false,
		showOverrideApproval : false,
		showDiagnosisReport : false,
		ShowGstInfo : false,
		showMirrorTestDetails : false,
		showDepreciationDetails : false,
		showTradeInDetails : false,
		showUploadDoc : false,
		showProvisionalEstimation : false,
		showUpgradedDeviceDetails : false,
		showQuestionLookup : false,
		showAdjudicationChecklist : false,
		ShowReplacementRequestDetails : false,
		showLoanerDeviceDetails : false,
		ShowBuyoutDetails : false,
		showChargeDetails : false,
		showSupportingDocuments : false,
		showPackagingMaterialDetails: false,
		showAddShipmentDetails: false,
		ShowTransferDetails: false,
		showIntegrationLogs: false,
		showActualChargeDetails: false,
		showRedesignCommunicationHistory:false,
		showPartnerStatusUpdate: false,
		showRefurbishmentRequestDetails: false,
		showDispositionDetails:false,
		showCaseScore: false,
		showRemarks:false,
	}

	const [activeTab, setActiveTab] = useState(allTabs);
	const [showPartModal, setShowPartModal] = useState(false);

	useEffect(() => {
		if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')){
            if(accessFn('REFURBISHMENT_MANAGER')){
				setActiveTab({...activeTab,['showDeviceDetails']:true})
            }else{
				setActiveTab({...activeTab,['showConsumrDetails']:true})
            }
        }
        else{
			setActiveTab({...activeTab,['showDeviceDetails']:true})
        }
	}, [])

	useEffect(() => {
		setCountryData();
		let check = {...countriesChecks}

		let countryconfig  = countriesData && countriesData.countryConfig;
		check.countryConfig = countryconfig;
		let countryCode = countriesData && countriesData.CountryCode;
		setCountryCode(countryCode);
		if (countryconfig){
			let taxDetailName = countryconfig.TaxDetailName;
			let taxDetailValue = countryconfig.TaxDetailValue;

			check.TaxDetailName = taxDetailName;
			check.TaxDetailValue = taxDetailValue;
			check.selectedCountryCode = countryCode;

			getBillingDetails();
		}
		setCountriesChecks(check);
	}, [countriesData])

	useEffect(() => {
		checkShipmentCountryCodes();
	}, [selectedCountry])

	let accessObj;
	let query = useQuery();
	const ConsumerServiceRequestID = query.get("csrid");

	useEffect(()=>{
        getRequestById();
		if(accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')){
            if(accessFn('REFURBISHMENT_MANAGER')){
                setShowDeviceDetails(true)
            }else{
                // showConsumrDetails = true;
            }
        }
        else{
            setShowDeviceDetails(true)
        }
    },[])

	useEffect(() => {
        if(!savedDeviceOptionsFetched && consumerServiceRequest && ['Replacement options provided'].indexOf(consumerServiceRequest.Status) > -1) {
            getDisplayableDeviceOptions();
        }
		// if(['In-principle approved', 'Claim initiated', 'Document verification pending'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) > -1) {
		// 	getValueOfDecisionMatrix();
		// }
    }, [consumerServiceRequest.Status])
	useEffect(()=>{
		if(qFilter && qFilter.hasUnrestrictedAccess){
			setRole('Superadmin')
		} else {
			if(accessFn('REFURBISHMENT_MANAGER')) {
				setRole("Refurbishment Hub Manager")
			} else if(accessFn('ACCEPT_RETURN_DEVICE')){
				setRole('Customer Care Executive')
			} else if(accessFn('STORE_MANAGEMENT')){
				setRole('Inventory manager');
			} else if(accessFn('REASSIGN_JOBS')){
				setRole('Supervisor');
			} else if(accessFn('REPAIR_DEVICE')){
				setRole('Engineer');
			} else if(accessFn('QC_DEVICE')){
				setRole('QC Engineer');
			} else if (accessFn('APPROVE_EXCEPTION_REQUEST')) {
				setRole('Approver');
			}else if (accessFn('BSM_ACCESS')) {
				setRole('Branch Service Manager');
			}
		}
	},[role])

	// useEffect(()=>{
	// 	let previousStatus = consumerServiceRequest.Status ? consumerServiceRequest.Status : "";
	// 	if(consumerServiceRequest && consumerServiceRequest.Status){
	// 		if("BER identified" === consumerServiceRequest && consumerServiceRequest.Status && ['Device Received & Inspection in Progress','Device received', 'Claim estimation generated',"Technician reached","In-home request accepted","Device details validated"].indexOf(consumerServiceRequest && consumerServiceRequest.Status) > -1){
	// 			Swal.fire ({
	// 				title: 'BER '+localString('Case Found'),
	// 				text: localString('Repair Estimate is more than the approved claim amount'),
	// 				icon: 'error',
	// 				confirmButtonText: localString('OK')
	// 			}).then(function(result) {
	// 				if (result.isConfirmed) {
	// 					Swal.close();
	// 				};
	// 			});
	// 		}
	// 	}
	// },[consumerServiceRequest.Status])

	useEffect(()=>{
			let viewRequestIsValid = {...showRequestLogs}
			let isEstimationGenerated = find(consumerServiceRequest && consumerServiceRequest.logs, {Status: 'Estimation generated'})
			viewRequestIsValid.isEstimationGenerated = isEstimationGenerated ? true : false;
			let isServiceInitiated = find(consumerServiceRequest && consumerServiceRequest.logs, {Status: 'Service initiated'})
			viewRequestIsValid.isServiceInitiated = isServiceInitiated ? true : false;
			let isClaimEstimationGenerated = find(consumerServiceRequest && consumerServiceRequest.logs, {Status: 'Claim estimation generated'})
			let DOAApplicabale = find(consumerServiceRequest && consumerServiceRequest.logs, {Status: 'DOA applicable'})
			viewRequestIsValid.isDOAApplicableLog= DOAApplicabale ? true : false;
			let invoiceGeneratedDate = consumerServiceRequest && consumerServiceRequest.InvoiceGeneratedDate
			viewRequestIsValid.InvoiceGeneratedDate=invoiceGeneratedDate ? true :false;
			if ((isEstimationGenerated || isClaimEstimationGenerated) && !viewRequestIsValid.InvoiceGeneratedDate){
				viewRequestIsValid.showEstimationDoc=true;
			}else if(viewRequestIsValid.isDOAApplicableLog){
				viewRequestIsValid.showEstimationDoc=false;
			}else{
				viewRequestIsValid.showEstimationDoc=false;
			}

			let workInProgressWithEngn = find(consumerServiceRequest.logs, {Status: "Work in progress"})
			let QcCompleted = find(consumerServiceRequest.logs, {Status: "QC completed"})
			let isClaimApproved = find(consumerServiceRequest.logs, {Status: 'Claim estimation approved'})
			let isPaymentReceived = find(consumerServiceRequest.logs, {Status: 'Payment received'})
			let isClaimRaised = find(consumerServiceRequest.logs, {Status: "Claim raised"})
			let isPartIssued = find(consumerServiceRequest.logs, {Status: "Parts issued"});
			let isRepairCancelInitiated = find(consumerServiceRequest.logs, {Status: 'Repair cancel initiated'});

			let advanceCollected = find(consumerServiceRequest.logs, {Status: "Advance collected"});
			viewRequestIsValid.isAdvanceCollected = advanceCollected ? true : false;

			let repairCompleted = find(consumerServiceRequest.logs, {Status: "Repair completed"})
			viewRequestIsValid.isRepairCompleted = repairCompleted ? true : false;

			let repairCancel = find(consumerServiceRequest.logs, {Status: 'Repair cancel'});
			viewRequestIsValid.isRepairCancel = repairCancel ? true :false;

			let serviceIsCancel = find(consumerServiceRequest.logs, {Status: 'Service cancel'})
			viewRequestIsValid.isServiceCancel= serviceIsCancel ? true :false;

			let serviceComplete = find(consumerServiceRequest.logs, {Status: 'Service completed'})
			viewRequestIsValid.isServiceComplete = serviceComplete ? true :false;

			let claimWithdrawn = find(consumerServiceRequest.logs, {Status: 'Claim withdrawn'})
			viewRequestIsValid.isClaimWithdrawn=claimWithdrawn ? true :false;

			// let refundInitiated = find(consumerServiceRequest.logs, {Status: 'Refund initiated'})
			// viewRequestIsValid.isRefundInitiated = refundInitiated ? true :false;

			let isSupportingDocsRequired = find(consumerServiceRequest.logs, {Status: "Supporting documents required"})
			viewRequestIsValid.isSupportingDocsRequired = isSupportingDocsRequired ? true : false;

			let diagnosisIntiateByEngg = find(consumerServiceRequest.logs, {Status: 'Diagnosis initiated',PerformedByRole:'ServicelocationEngineer'})
			viewRequestIsValid.diagnosisIntiateByEngg = diagnosisIntiateByEngg ? true : false;

			let diagnosisCompletedByEngg = find(consumerServiceRequest.logs, {Status: 'Diagnosis completed',PerformedByRole:'ServicelocationEngineer'})
			viewRequestIsValid.diagnosisCompletedByEngg = diagnosisCompletedByEngg ? true : false;

			let diagnosisCancelByEngg = find(consumerServiceRequest.logs, {Status: 'Cannot be diagnosed',PerformedByRole:'ServicelocationEngineer'})
			viewRequestIsValid.diagnosisCancelByEngg = diagnosisCancelByEngg ? true : false;

			let logIndex = consumerServiceRequest?.logs?.length && consumerServiceRequest.logs.findIndex(obj => (obj.Status === 'Device received at service centre'))
			let tempLogs = logIndex ? consumerServiceRequest.logs.filter((i,k) => (k <= logIndex)) : [];
			viewRequestIsValid.displayDiagnosisStepsToEngFor2ndTime = tempLogs?.length ? true : false;

			let diagnosisIntiateByEngg2 = find(tempLogs, {Status: 'Diagnosis initiated',PerformedByRole:'ServicelocationEngineer'})
			viewRequestIsValid.diagnosisIntiateByEngg2 = diagnosisIntiateByEngg2 ? true : false;

			let diagnosisCompletedByEngg2 = find(tempLogs, {Status: 'Diagnosis completed',PerformedByRole:'ServicelocationEngineer'})
			viewRequestIsValid.diagnosisCompletedByEngg2 = diagnosisCompletedByEngg2 ? true : false;

			let diagnosisCancelByEngg2 = find(tempLogs, {Status: 'Cannot be diagnosed',PerformedByRole:'ServicelocationEngineer'})
			viewRequestIsValid.diagnosisCancelByEngg2 = diagnosisCancelByEngg2 ? true : false;

			if(qFilter && qFilter.flowType && qFilter.flowType.requestFulfilmentFlow && (qFilter.flowType.requestFulfilmentFlow.indexOf('REQ_FULFIL_BOSE') > -1 || qFilter.flowType.requestFulfilmentFlow.indexOf('REQ_FULFIL_VIZIO') > -1 || qFilter.flowType.requestFulfilmentFlow.indexOf('REQ_FULFIL_SKYWORTH') > -1|| qFilter.flowType.requestFulfilmentFlow.indexOf('ATTEMPT_FULFIL_CROMA') > -1)){
				viewRequestIsValid.enableAttemptCall = true;
			}

			//to do
			let initiateExceptionShow = !isClaimRaised && ['Exception raised'].indexOf(consumerServiceRequest.Status) == -1 && !!workInProgressWithEngn && !viewRequestIsValid.isRepairCompleted && !QcCompleted && (!isClaimApproved || (isClaimApproved && !isPaymentReceived)) && !viewRequestIsValid.isAdvanceCollected && !isPartIssued && !viewRequestIsValid.isRepairCancel && !viewRequestIsValid.isServiceCancel && !isRepairCancelInitiated
			viewRequestIsValid.showInitiateException = initiateExceptionShow;

			if(showRequestValid.isRequestSFANeeded){
				if(requestSFA && requestSFA.length > 0 ){
					//nothing
					viewRequestIsValid.showInitiateException = true;
				}else {
					viewRequestIsValid.showInitiateException = false;
				}
			}
			let workStarted = find(consumerServiceRequest.logs, {Status: "Work in progress"}) ;
			viewRequestIsValid.isWorkStarted = workStarted ? true : false;

			let invoiceGenerated = find(consumerServiceRequest.logs, {Status: 'Service invoice generated'});
			viewRequestIsValid.isInvoiceGenerated = invoiceGenerated ?true :false;

			let partsPending = find(consumerServiceRequest.logs, {Status: 'Parts pending'});
			viewRequestIsValid.isPartsPending =  partsPending ? true : false;

			let partRequestCreated = find(consumerServiceRequest.logs, {Status: 'Parts request accepted'});
			viewRequestIsValid.isPartRequestCreated =  partRequestCreated ? true : false;

			let diagnosisCompletedFlag = find(consumerServiceRequest.logs, {Status: 'Diagnosis completed'})
			viewRequestIsValid.isDiagnosisCompletedFlag =  diagnosisCompletedFlag ? true : false ;
			let berConfirmed = find(consumerServiceRequest.logs,{Status: 'BER confirmed'});
			viewRequestIsValid.BERConfirmed = berConfirmed ? true :false;
			let serviceCompleteRated = find(consumerServiceRequest.logs, {Status: 'Service completed and rated'})
			viewRequestIsValid.isServiceCompleteRated =  serviceCompleteRated ? true :false;
			if(exceptionLogs && exceptionLogs) {
				let processingRefund = find(exceptionLogs, {Status: 'Processing refund'});
				viewRequestIsValid.isProcessingRefund=processingRefund ? true :false;
				let exceptionApproved = find(exceptionLogs, {Status: 'Exception approved'});
				viewRequestIsValid.isExceptionApproved = exceptionApproved ? true : false;
				let exceptionRejected = find(exceptionLogs, {Status: 'Exception rejected'});
				viewRequestIsValid.isExceptionRejected = exceptionRejected ? true : false;
				let exceptionOverriden = find(exceptionLogs, {Status: 'Exception overridden'});
				viewRequestIsValid.isExceptionOverriden = exceptionOverriden ? true : false;
				let pendingIsApprovalIndex = findIndex(exceptionLogs, {Status: 'Pending for exception approval'});
				viewRequestIsValid.pendingForApprovalIndex = pendingIsApprovalIndex ? true : false;
				let exceptionIsRaisedIndex = findIndex(exceptionLogs, {Status: 'Exception raised'});
				viewRequestIsValid.exceptionRaisedIndex = exceptionIsRaisedIndex ? true :false;
				viewRequestIsValid.showInitiateException = viewRequestIsValid.showInitiateException && !(viewRequestIsValid.isExceptionApproved || viewRequestIsValid.isExceptionOverriden)
				let showIsCompleteRefund = ['Exception raised'].indexOf(consumerServiceRequest.Status)!== -1 && viewRequestIsValid.isProcessingRefund;
				viewRequestIsValid.showCompleteRefund  = showIsCompleteRefund ? true :false
				let showIsApproveException = viewRequestIsValid.pendingForApprovalIndex == 0 || viewRequestIsValid.exceptionRaisedIndex == 0;
				viewRequestIsValid.showApproveException =  showIsApproveException ? true : false;
			}

			let provisionalChargesAdded = find(consumerServiceRequest.logs, {Status: 'Provisional estimate generated'}) || find(consumerServiceRequest.logs, {Status: 'Provisional estimate updated'});
			viewRequestIsValid.isProvisionalChargesAdded = provisionalChargesAdded ? true : false;

			let deviceInspected = find(consumerServiceRequest.logs, {Status: 'Device inspected'});
			viewRequestIsValid.isDeviceInspected = deviceInspected ? true : false;
			let repairCancelled = find(consumerServiceRequest.logs, {Status: 'Repair cancel'});
			viewRequestIsValid.isRepairCancelled = repairCancelled ? true : false;

			let hardwareClosure =  find(consumerServiceRequest.logs, {Status: 'Non hardware closure details updated'});
			viewRequestIsValid.isHardwareClosure = hardwareClosure ? true : false;

			let deviceReceived = find(consumerServiceRequest.logs, {Status: 'Device received'});
			viewRequestIsValid.isDeviceReceived = deviceReceived ? true : false;

			let partsIssued = find(consumerServiceRequest.logs, {Status: 'Parts received'})
			viewRequestIsValid.isPartsIssued = partsIssued ? true :false;

			if([65].indexOf(consumerServiceRequest.ServiceTypeID)>-1) {
				let isRefurbishmentRequest = find(consumerServiceRequest.logs, {Status: 'Refurb Request Created'});
				viewRequestIsValid.isRefurbishmentRequestCreated = isRefurbishmentRequest ? true : false;
			}

			if (consumerServiceRequest.issues && consumerServiceRequest.issues.length > 0) {
				let issuesValidated = find(consumerServiceRequest.logs, {Status: 'Issues validated'})
				for (let iCount = 0;iCount<consumerServiceRequest.issues.length;iCount++) {
					if (consumerServiceRequest.issues[iCount].validityStatus == 0 || !consumerServiceRequest.issues[iCount].IsValidated) {
						issuesValidated = false;
					}
				}
				viewRequestIsValid.isIssuesValidated=issuesValidated ? true : false
			}
			else {
				viewRequestIsValid.isIssuesValidated = true;
			}
			if (viewRequestIsValid.isPartsIssued && accessFn('WORKSHOP_FLOW')) {
				viewRequestIsValid.showPartsDocument = true;
			}
			if(showRequestLogs.isProvisionalChargesAdded){
				viewRequestIsValid.showProvisionalEstimationTab=true;
			}

			let qcCompleted = find(consumerServiceRequest.logs, {Status: 'QC completed'});
			viewRequestIsValid.isQcCompleted = qcCompleted ? true : false;

			// let serviceComplete= find(consumerServiceRequest.logs, {Status: 'Service completed'});
			// viewRequestIsValid.isServiceComplete = serviceComplete ? true :false;

			//let isExceptionFlow = find(consumerServiceRequest.logs, {Status: 'Exception raised'});
			//Pickup Request Accepted
			let exceptionFlow = find(consumerServiceRequest.logs, {Status: 'Exception raised'});
			viewRequestIsValid.isExceptionFlow=exceptionFlow ? true : false

			let isInformCustomer = find(consumerServiceRequest.logs, {Status: "Intimate customer"})
			viewRequestIsValid.enableReturnProcess = isInformCustomer ? true : false;

			let issuesAddedByEng = false;
			if(consumerServiceRequest && consumerServiceRequest.issues && consumerServiceRequest.issues.length > 0){
				if (uniq(map(consumerServiceRequest.issues, 'IsValidated')).indexOf(false) < 0) {
					issuesAddedByEng = true;
				} else {
					issuesAddedByEng = false;
				}
			}
			viewRequestIsValid.isIssuesAddedByEng = issuesAddedByEng ? true : false;
			let deviceValidated = find(consumerServiceRequest.logs, {Status: 'Device details validated'});
			viewRequestIsValid.isDeviceValidated= deviceValidated ? true : false;
			let deviceDispatch = find (consumerServiceRequest.logs, {Status: 'Device dispatched'});
			viewRequestIsValid.isDeviceDispatch= deviceDispatch ? true : false;

			let isLocalDefectiveReceived = find(consumerServiceRequest.logs, {Status: 'Defective received'});
			viewRequestIsValid.isDefectiveReceived = isLocalDefectiveReceived  ? true : false;
			let securityLocalDepositChargeFaile = find(consumerServiceRequest.logs, {Status: 'Security deposit charging failed'});
			viewRequestIsValid.securityDepositChargeFailed = securityLocalDepositChargeFaile ? true : false;
			let isLocalDOACompleted = find(consumerServiceRequest.logs, {Status: 'DOA completed'})
			viewRequestIsValid.isDOACompleted = isLocalDOACompleted ? true :false
			if(viewRequestIsValid.isExceptionFlow) {
				getServiceRequestException();
			}
			(['QC completed'].indexOf(consumerServiceRequest.Status) > -1) && checkPaymentLinkStatus();

			if(accessFn('WORKSHOP_FLOW')){
				if (workStarted) {
					if(accessFn('GSX_FLOW')){
						getSymptomFaultAction();
					}
				}
			}
			viewRequestIsValid.showDispositionDetailsSec = qcCompleted ? true : false;
			setShowRequestLogs(viewRequestIsValid);

	},[consumerServiceRequest.logs])

	useEffect(() => {	
		if((consumerServiceRequest?.Status=='BER identified') && ['Claim estimation generated','Device Received & Inspection in Progress','Device received',"Technician reached","In-home request accepted","Device details validated"].indexOf(previousStatus)> -1 ){
			Swal.fire ({
				title: 'BER '+localString('Case Found'),
				text: localString('Repair Estimate is more than the approved claim amount'),
				icon: 'error',
				confirmButtonText: localString('OK')
			}).then(function(result) {
				if (result.isConfirmed) {
					Swal.close();
				};
			});
		}
		if(consumerServiceRequest.ConsumerServiceRequestID){
			if (['Service completed', 'Service cancel','Claim withdrawn'].indexOf(consumerServiceRequest.Status) == -1 && [SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
				getTatIndicator(consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID);
			}
		}
		let viewRequestValid={...showRequestValid}
		let consumerService = {...consumerServiceRequest}
		if(accessFn('GSX_FLOW') && consumerServiceRequest.PartnerServiceLocationID){	
			getLoanerDeviceList();	
		}	
		if((!accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REFURBISHMENT_MANAGER')) ){ //&& showDeviceDetails){	
			getProductDetails();	
		}	
		// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR ,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)>-1) {	
		// 	getInsuranceDetails();	
		// }
		if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR ,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE,SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)>-1) {	
			getActualCost()	
		}

		if (qFilter && qFilter.serviceLocations) {
			if ((qFilter && qFilter.serviceLocations && (qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) > -1 || qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 )) || [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,61,62].indexOf(consumerServiceRequest.ServiceTypeID)>-1) {
				viewRequestValid.canUpdateServiceRequest=true;
				setCanUpdateServiceRequest(true);
			}
			else {
				viewRequestValid.canUpdateServiceRequest= false;
				setCanUpdateServiceRequest(false);
			}
		}
		else {
			viewRequestValid.canUpdateServiceRequest=true;
			setCanUpdateServiceRequest(true);
		}

		if (qFilter && qFilter.countrycode && qFilter.countrycode.length > 0) {
			setDefaultCountry(qFilter.countrycode[0]);
		}
		if (qFilter && qFilter.languagecode && qFilter.languagecode.length > 0) {
			setDefaultCountryLocale(qFilter.languagecode[0]);
		}

		viewRequestValid.hideNextStepInTradeIn=consumerServiceRequest.ServiceTypeID === SERVICETYPE.TRADE_IN_DROPOFF &&
			(accessFn('REFURBISHMENT_MANAGER') && ["Device received", "Pending for acceptance", "Device validated", "QC pending", "QC completed", "Repair initiated", "Repair completed"].indexOf(consumerServiceRequest.Status) === -1) ||
			(accessFn('REPAIR_DEVICE') && ["QC completed", "QC pending"].indexOf(consumerServiceRequest.Status) === -1) ||
			(accessFn('QC_DEVICE') && ["Pending for acceptance"].indexOf(consumerServiceRequest.Status) === -1)

		getCountries();

		if(accessFn('WORKSHOP_FLOW') && (accessFn('REPAIR_DEVICE') || accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && !accessFn('GSX_FLOW')){
			getPartnerSymptomFaultAction();
		}
		if(([SERVICETYPE.CLAIM_DOOR_TO_DOOR_UPGRADE,SERVICETYPE.DOOR_TO_DOOR_UPGRADE,SERVICETYPE.CLAIM_DOOR_TO_DOOR_REPLACEMENT,SERVICETYPE.DOOR_TO_DOOR_REPLACEMENT,SERVICETYPE.PICKUP_UPGRADE,SERVICETYPE.CLAIM_REPLACEMENT_PICKUP,SERVICETYPE.CLAIM_REPLACEMENT_MAIL_IN,SERVICETYPE.CLAIM_UPGRADE_PICKUP,SERVICETYPE.MAIL_IN_UPGRAGE,SERVICETYPE.CLAIM_UPGRADE_MAIL_IN,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
			getRequestDetails();
		}
		if (consumerServiceRequest.consumerProduct) {
			getApplicationFeatureConfig();
		}
		// if ([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest.ServiceTypeID)>-1) {
		// 	setIsBoseFlow(isboseFlow)
		// }

		if ([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID)>-1) {
			getConsumerDetailsForPickup();
		}
		// if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)>-1) {
		// 	getInsuranceDetails();
		// }
		if([10,11,12,14,15,16,60,23,38,52,46,63,64,65,66].indexOf(consumerServiceRequest.ServiceTypeID)>-1) {
                        //For all claim types
			getInsuranceDetails();
			// self.getActualCost();
		}
		setCurrencySymbol(consumerServiceRequest && consumerServiceRequest.CurrencySymbol || '&#8377;');


		if(accessFn('TASK_MANAGEMENT')) {
			viewRequestValid.showTaskTab=true
		}
		if(['CromaDashboard','CromaAPI','Croma','CromaCare'].indexOf(consumerServiceRequest.Source) < 0){
			viewRequestValid.checkRequestedDocuments=true;
		}


		viewRequestValid.productNotMapped = consumerServiceRequest && consumerServiceRequest.isProductToBeVerified ? consumerServiceRequest.isProductToBeVerified : false

			// if ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPLACEMENT,46,63].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
			// 	consumerServiceRequest.SoldPlanID && getSoldPlanDetails();
			// }
			// if ([10,11,12,16,23,SERVICETYPE.CLAIM_SELF_REPAIR,52,14,15,60,46,63,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
			// 	consumerServiceRequest && consumerServiceRequest.SoldPlanID && getSoldPlanDetails();
			// 	consumerServiceRequest && consumerServiceRequest.SoldPlanID && getHighRiskDetails();
			// }



		if (showRequestLogs.isWorkStarted) {
			if(accessFn('GSX_FLOW')){
				getSymptomFaultAction();
			}
			if(consumerServiceRequest.ServiceTypeID !== SERVICETYPE.TRADE_IN_DROPOFF) {
				//getPartsList();
			}
			getPartsForRequest();
		} else if ([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && (showRequestLogs.isPartsPending || showRequestLogs.isPartRequestCreated || showRequestLogs.isInvoiceGenerated)) {
			//getPartsList();
			getPartsForRequest();
		}
		if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID == SERVICETYPE.BER_PAYMENT && showRequestLogs.BERConfirmed && !berFlags.checkBERDeviceRequiredCalled){
			checkBERDeviceRequired()
		}
		if ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPLACEMENT,SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE,SERVICETYPE.BUYOUT_WITHOUT_DEFECTIVE,SERVICETYPE.CLAIM_ONSITE,SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
			consumerServiceRequest.SoldPlanID && getSoldPlanDetails();
			consumerServiceRequest.SoldPlanID && getHighRiskDetails();
		}

		let showNewInvoice  = consumerServiceRequest && consumerServiceRequest.ShowNewInvoice
		viewRequestValid.showOnsiteInvoice = true;

		if (defaultCountry == 'IN') {
			if(showNewInvoice ){
				setInvoice('/servicerequests/invoicegst?csrid=');
			} else {
				setInvoice('/servicerequests/invoice?csrid=');
			}
		} else if (defaultCountry == 'US') {
			setInvoice('/servicerequests/invoice?csrid=');
		} else {
			if(showNewInvoice){
				setInvoice('/servicerequests/invoicegst?csrid=');
			} else {
				if (consumerServiceRequest.showEngineerVisits) {
						viewRequestValid.showOnsiteInvoice=false;
				} else {
					setInvoice('/servicerequests/invoice?csrid=');
				}
			}
		}
		if(consumerServiceRequest.AdvanceCollected){
			fetchAdvanceReceipt();
		}


		setEstimationdoc('/servicerequests/estimationdocument?csrid=');
		
		if (([SERVICETYPE.PICK_UP, SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1 && (reschedulecheck.indexOf(consumerServiceRequest.Status) > -1)) || (([SERVICETYPE.PICK_UP, SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1 && consumerServiceRequest && consumerServiceRequest.isReschedulable))){
				setIsRescheduleApplicable(true)
		}
		if (([SERVICETYPE.PICK_UP, SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) == -1 ) || ([SERVICETYPE.PICK_UP, SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && consumerServiceRequest.isReschedulable)){
				viewRequestValid.isRescheduleApplicable = true;
				consumerService.isReschedulable = true;
			} else {
				setIsRescheduleApplicable(false)
				consumerService.isReschedulable = false;
		}
		let sendIssuesGSXFlag  = consumerServiceRequest.gsxRepairSymtomsIssues && consumerServiceRequest.gsxRepairSymtomsIssues.length?false:true
			viewRequestValid.sendIssuesGSXFlag = sendIssuesGSXFlag ? true : false;

		let isAllowCCToProceedWithService = false;
		if(accessFn('TRANSFER_REQUEST')){ //transfer request
			if (qFilter && qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1){
				if(consumerServiceRequest.originCenter){
					isAllowCCToProceedWithService = true;
				}else{
					isAllowCCToProceedWithService = false;
				}
			}
		}else{
			isAllowCCToProceedWithService = true;
		}
		viewRequestValid.allowCCToProceedWithService = isAllowCCToProceedWithService ? true : false;
		if (consumerServiceRequest && consumerServiceRequest.charges && consumerServiceRequest.charges.length>0) {
			for(let i = 0; i < consumerServiceRequest.charges.length; i++) {
				let charge = consumerServiceRequest.charges[i];
				if(['Other', 'Part'].indexOf(charge.Type) > -1){
					viewRequestValid.isChargeAdded = true;
					break;
				}
			}
		}
		let isOutOfWarranty = false;
		if(consumerServiceRequest.charges){
			for (let iCount=0;iCount<consumerServiceRequest.charges.length;iCount++) {
				if (consumerServiceRequest.charges[iCount].Type == "Part" && consumerServiceRequest.charges[iCount].Charge != 0) {
					isOutOfWarranty = true;
				}
			}
		}
		viewRequestValid.isOutOfWarranty = isOutOfWarranty?true:false;
		if([14,15].indexOf(consumerServiceRequest.ServiceTypeID)>-1 && consumerServiceRequest.AccessoriesStatus == -1){
			viewRequestValid.showAccessoriesMsg = true;
		} else {
			viewRequestValid.showAccessoriesMsg = false;
		}
		if([14,15].indexOf(consumerServiceRequest.ServiceTypeID)>-1 && consumerServiceRequest.PendingDoc == 1){
			viewRequestValid.showPendingDocMsg = true;
		} else {
			viewRequestValid.showPendingDocMsg = false;
		}
		setShowRequestValid(viewRequestValid)
		// if(consumerServiceRequest.ConsumerServiceRequestID){
		// 	if (['Service completed', 'Service cancel'].indexOf(consumerServiceRequest.Status) == -1 && [SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
		// 		getTatIndicator(consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID);
		// 	}
		// }
		if ([SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
			getEmptyBoxRequestDetails()
		}
		if(consumerServiceRequest.ConsumerServiceRequestID && [SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,SERVICETYPE.CLAIM_CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)== -1){
			fetchLogisticsDetails();
		}
		let assignObj = checkVisibilityForAssign();
		if(accessFn('WORKSHOP_FLOW')) {
			// let assignObj = {...assignObjCheck}
			// showAttemptCallbtn = false;
			let logLength = consumerServiceRequest.logs && consumerServiceRequest.logs.length;
			viewRequestValid.hasOverridden=false;
				for(let i = 0; i < logLength; i++){
					if(consumerServiceRequest.logs[i] && consumerServiceRequest.logs[i].Status == 'Parts request accepted' && consumerServiceRequest.logs[i].Reason){
						viewRequestValid.hasOverridden=true;
						break;
					}
				}

			if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.TRADE_IN_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.INTERNAL_JOB,56,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 || [SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1){
				if([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
					if(['Device transferred','Service accepted','Pickup Request Accepted','Device shipped','DOA completed','DropOff request accepted'].indexOf(consumerServiceRequest.Status) > -1) {
						assignObj.showassignmenttag = false;
						assignObj.showengineertypeahead = false;
					}
					else {
						assignObj.showassignmenttag = true;
					}
				}else{
					if(['Device transferred','Service accepted','Pickup Request Accepted','Device shipped','DOA completed','DropOff request accepted','Device received','Provisional estimate updated','Provisional estimate generated','Non hardware closure details updated'].indexOf(consumerServiceRequest.Status) > -1) {
						assignObj.showassignmenttag = false;
						assignObj.showengineertypeahead = false;
					}
					else {
						assignObj.showassignmenttag = true;
					}
				}
				if (accessFn('GSX_FLOW')) {
					if (isDiagnosisCompleted()) {
						assignObj.showengineertypeahead = true;
						assignObj.showassignmentbutton = true;
					}
					else {
						assignObj.showengineertypeahead = false;
						assignObj.showassignmentbutton = false;
					}
				}
				else {
					assignObj.showengineertypeahead = true;
					assignObj.showassignmentbutton = true;
				}
				assignObj.showengineerlabel = true;
				assignObj.showservicelocationlabel = true;
				if (accessFn('READ_ONLY')) {
					assignObj.showassignmentbutton = false;
					assignObj.showengineertypeahead = false;
				}
				if(accessFn('SERVICE_LOCATION_ASSIGN')){
					assignObj.showservicelocationtypeahead = true;
					assignObj.showservicelocationlabel = false;
				}
				assignObj.showpartstatusupdatebutton = true;
				if(consumerServiceRequest.Status == 'Estimation approval') {
					assignObj.showestimationoverride = true;
				}
			}

			if(([SERVICETYPE.PICK_UP, SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest.consumerProduct.isAppleBrandID) {
				if(accessFn('LOANER_FLOW') && consumerServiceRequest && consumerServiceRequest.loanerDetails && consumerServiceRequest.loanerDetails.loanerRequested) {
					if(consumerServiceRequest?.PartnerServiceLocationID){
						fetchLoanerDetailsByCsrId();
					}
				}
			}
			// setAssignObjCheck(assignObj)
		}else{
			// if(([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.TRADE_IN_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN,SERVICETYPE.INTERNAL_JOB,56,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1) || ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.PreviousServiceTypeID) > -1)){
			// 	if([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
			// 		if(['Device transferred','Service accepted','Pickup Request Accepted','Device shipped','DOA completed','DropOff request accepted'].indexOf(consumerServiceRequest.Status) > -1) {
			// 			assignObj.showassignmenttag = false;
			// 			assignObj.showengineertypeahead = false;
			// 		}
			// 		else {
			// 			assignObj.showassignmenttag = true;
			// 		}
			// 	}else{
			// 		if(['Device transferred','Service accepted','Pickup Request Accepted','Device shipped','DOA completed','DropOff request accepted','Device received','Provisional estimate updated','Provisional estimate generated','Non hardware closure details updated'].indexOf(consumerServiceRequest.Status) > -1) {
			// 			assignObj.showassignmenttag = false;
			// 			assignObj.showengineertypeahead = false;
			// 		}
			// 		else {
			// 			assignObj.showassignmenttag = true;
			// 		}
			// 	}
			// 	assignObj.showservicelocationlabel = true;
			// 	assignObj.showpartstatusupdatebutton = true;
			// 	if(consumerServiceRequest.Status == 'Estimation approval') {
			// 		assignObj.showestimationoverride = true;
			// 	}
			// }
		}

		if(consumerServiceRequest.IsTenorRequest && consumerServiceRequest.ServiceTypeID == SERVICETYPE.INTERNAL_JOB){
			assignObj.showassignmenttag = true;
			// setAssignObjCheck({...assignObjCheck,showassignmenttag:true});
		}
		if(!accessFn('SERVICE_LOCATION_ASSIGN') && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,56,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ){
			assignObj.showengineertypeahead = false;
			assignObj.showassignmentbutton = false;
			// setAssignObjCheck({...assignObjCheck,showengineertypeahead:false,showassignmentbutton:false});
		}
		// csr.assignObjCheck = assignObjCheck;  -- need more clarity

		setAssignObjCheck(assignObj) // Finaly Update of assignObjCheck state

		if((qFilter && qFilter.serviceLocations && ((qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter) || ((qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) > -1 && !consumerServiceRequest.originCenter))) || ((qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1 && consumerServiceRequest.originCenter) || (qFilter.serviceLocations && qFilter.serviceLocations.indexOf(consumerServiceRequest.PartnerServiceLocationID) > -1 && !consumerServiceRequest.originCenter)) || ['VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 || [SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1)){
			setDeviceAtLoggedInLoc(true)
		}
		if(pickupCarryInGSXChecks && accessFn('GSX_FLOW') && consumerServiceRequest && consumerServiceRequest.consumerProduct){
			if(accessFn('REPAIR_DEVICE') && !isDiagnosisCompleted(true) ) {
				getDiagnosisSuites();
			}
			
			else if (((consumerServiceRequest.consumerProduct.gsxInfo && consumerServiceRequest.consumerProduct.gsxInfo.serialNumber) || (consumerServiceRequest.consumerProduct.AlternateUniqueKey)) && (!arrDiagnosisSuites || (arrDiagnosisSuites && !arrDiagnosisSuites.length)) && !diagnosticsSuitesCalled) {
				getDiagnosisSuites();
			}
			// else if(consumerServiceRequest.consumerProduct.AlternateUniqueKey && (!arrDiagnosisSuites || (arrDiagnosisSuites && !arrDiagnosisSuites.length)) && !diagnosticsSuitesCalled){
			// 	getDiagnosisSuites();
			// }
		}
		// if(consumerServiceRequest && consumerServiceRequest.ServiceTypeID){
		// 	fetchBERConfiguration();
		// }
		// getDiagnosisSuites()
    }, [consumerServiceRequest,showRequestLogs.BERConfirmed])

	useEffect(()=>{
		if(assignObjCheck && assignObjCheck.showservicelocationtypeahead && accessFn('ACCEPT_RETURN_DEVICE')){
			if (consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID && consumerServiceRequest.consumerProduct.ProductID && !locationsCalledAlready) {
				loadServiceLocation(
					consumerServiceRequest.ServiceTypeID,
					consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.BrandID : null,
					consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.ProductID: null
				);
			}
		}
	},[consumerServiceRequest, assignObjCheck.showservicelocationtypeahead])
	useEffect(() => {
		let viewRequestPartLog = {...showRequestLogs}
		let isAllPartReceived = partsRequested && partsRequested.length === 0 ? false : true;
		for(let iCount = 0;iCount< partsRequested && partsRequested.length;iCount++){
			if (partsRequested[iCount].statusDetails.Status == 'pending' || partsRequested[iCount].statusDetails.Status == 'issued' || partsRequested[iCount].statusDetails.Status == 'ordered') {
				isAllPartReceived = false;
				break
			}
		}
		if (accessFn('GSX_FLOW') && !(addedFaultActions && addedFaultActions.length>0)) {
			isAllPartReceived = false;
		}
		viewRequestPartLog.isAllPartReceived = isAllPartReceived ? true : false;
		let isAllPartsRejected = true;
		for(let iCount = 0;iCount<partsRequested && partsRequested.length;iCount++){
			if (partsRequested[iCount].statusDetails.Status != 'rejected') {
				isAllPartsRejected = false;
				break;
			}
		}
		viewRequestPartLog.isAllPartsRejected = isAllPartsRejected ? true : false;
		let receivedPartPresent =  filter(partsRequested, function (o) { return o.statusDetails && o.statusDetails.PartTransactionStatusID == 5});
		viewRequestPartLog.receivedPartPresent = receivedPartPresent.length ? true : false;
		let receivedConsumedConsignmentPart =  filter(partsRequested, function (o) { return o.statusDetails && o.statusDetails.PartTransactionStatusID == 14});
		viewRequestPartLog.receivedConsumedConsignmentPart = receivedConsumedConsignmentPart.length ? true : false;

		let isConsignment = false;
		for (let iCount = 0;iCount<partsRequested && partsRequested.length;iCount++) {
			if (partsRequested[iCount].isConsignment) {
				isConsignment = true;
				break
			}
		}
		viewRequestPartLog.isConsignment = isConsignment ? true : false;
		setShowRequestLogs(viewRequestPartLog);
    }, [partsRequested])

	useEffect(() => {
		if(accessFn('WORKSHOP_FLOW')){
			if (showRequestLogs.isWorkStarted) {
				if(accessFn('GSX_FLOW')){
					// getSymptomFaultAction(); //=> moved this check in CSR.logs useEffect()
				}
				if(consumerServiceRequest.ServiceTypeID !== SERVICETYPE.TRADE_IN_DROPOFF) {
					//getPartsList();
				}
				getPartsForRequest();
			} else if ([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && (showRequestLogs.isPartsPending || showRequestLogs.isPartRequestCreated || showRequestLogs.isInvoiceGenerated)) {
				//getPartsList();
				getPartsForRequest();
			}
		}
		if (showRequestLogs.isQcCompleted || showRequestLogs.isRepairCancel || showRequestLogs.isServiceComplete || showRequestLogs.isServiceCancel) {
			setHideAssignOption(true)
		}

	}, [showRequestLogs.isWorkStarted,
		showRequestLogs.isQcCompleted,
		showRequestLogs.isRepairCancel,
		showRequestLogs.isServiceComplete,
		showRequestLogs.isServiceCancel,
		showRequestLogs.isPartRequestCreated,
		showRequestLogs.isInvoiceGenerated,
		showRequestLogs.isPartsPending])

	useEffect(()=>{
		if(!isSubscriptionEnabled){
			checkToCallAdvancePayment()
		}
		// if((claimDamageId != null) && !advancePaymentCallFetched && (['In-principle approved'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) > -1) && (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM) && (['Airtel-Web', 'Airtel'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) == -1)) {
        //     checkAdvancePaymentRequired();
        // }
	},[soldPlan,isSubscriptionEnabled])

	const reschedulecheck = [
        'Pending for assignment',
        'No engineer assigned',
        'Temp request confirmed',
        'Pending for approval',
        'Service accepted',
        'Service suspended',
        'Pickup Request Accepted',
        'Work in Progress'
    ];

	const checkVisibilityForAssign = (obj) => {
		let accessObj = {...assignObjCheck};
		var servicetypeid = consumerServiceRequest.ServiceTypeID;
		switch (servicetypeid) {
            case SERVICETYPE.ON_SITE:
                accessObj = checkOnsiteAssign();
				// setAssignObjCheck(accessObj)
                break;
            case SERVICETYPE.CARRY_IN:
                accessObj = checkCarryIn();
				// setAssignObjCheck(accessObj);
                break;
            case SERVICETYPE.PICK_UP:
                accessObj = checkPickUp();
				// setAssignObjCheck(accessObj);
                break;
			case SERVICETYPE.DROPOFF:
				accessObj = checkDropOff();
				// setAssignObjCheck(accessObj);
				break;
            case SERVICETYPE.INSTALLATION:
				accessObj = checkInstallation();
				// setAssignObjCheck(accessObj);
                break;
            case SERVICETYPE.DEMO:
                accessObj = checkDemo();
				// setAssignObjCheck(accessObj);
                break;
            case SERVICETYPE.SERVICING:
                accessObj = checkServicing();
				// setAssignObjCheck(accessObj);
                break;
            default:
                break;
        }
		return accessObj
	}

	const showRefundButton = () =>{
		let isRefundInitiated = consumerServiceRequest.logs && consumerServiceRequest.logs.length > 0 && find(consumerServiceRequest.logs, {Status: 'Refund initiated'});
		let didRefundFail = consumerServiceRequest.logs && consumerServiceRequest.logs.length > 0 && find(consumerServiceRequest.logs, {Status: 'Refund failed'});
		if ((['Service cancel', 'Claim withdrawn', 'Claim rejected', 'Defective received'].indexOf(consumerServiceRequest.Status) > -1) && ([10,11,12,23,14,15,16,46,64,65,66].indexOf(consumerServiceRequest.ServiceTypeID)> -1)) {
			if ((!isRefundInitiated) || (isRefundInitiated && didRefundFail)) {
				return true;
			}
		}
		return false;
	}
	let checkOnsiteAssign =()=>{
		let onsiteassignobj = {};
		let engineerid = consumerServiceRequest.ServiceLocationEngineerID;
		let servicelocationid = consumerServiceRequest.PartnerServiceLocationID;
		let isengineerassigned = engineerid ? true : false;
		let isservicelocationassigned = servicelocationid ? true : false;
		let status = consumerServiceRequest && consumerServiceRequest.Status;
		switch (status) {
			case 'Pending for assignment':
				onsiteassignobj.showassignmenttag = true;
				onsiteassignobj.showengineerlabel = false;
				onsiteassignobj.showservicelocationlabel = true;
				onsiteassignobj.showengineertypeahead = true;
				onsiteassignobj.showservicelocationtypeahead = true;
				onsiteassignobj.showassignmentbutton = onsiteassignobj.showservicelocationtypeahead || onsiteassignobj.showengineertypeahead;
				break;
			case 'Pending for approval':
                onsiteassignobj.showassignmenttag = true;
				onsiteassignobj.showservicelocationlabel = isservicelocationassigned;
				onsiteassignobj.showengineerlabel = true;
				onsiteassignobj.showservicelocationtypeahead = false;
				onsiteassignobj.showengineertypeahead = isservicelocationassigned && !isengineerassigned;
				onsiteassignobj.showassignmentbutton = onsiteassignobj.showservicelocationtypeahead || onsiteassignobj.showengineertypeahead;
				break;
			case 'Service accepted':
                onsiteassignobj.showassignmenttag = true;
				onsiteassignobj.showservicelocationlabel = true;
				onsiteassignobj.showengineerlabel = true;
				onsiteassignobj.showengineertypeahead = false;
				onsiteassignobj.showservicelocationtypeahead = false;
				onsiteassignobj.showassignmentbutton = onsiteassignobj.showservicelocationtypeahead || onsiteassignobj.showengineertypeahead;
				break;
			case 'Service suspended':
                onsiteassignobj.showassignmenttag = true;
				onsiteassignobj.showservicelocationlabel = true;
				onsiteassignobj.showengineerlabel = true;
				onsiteassignobj.showengineertypeahead = false;
				onsiteassignobj.showservicelocationtypeahead = false;
				onsiteassignobj.showassignmentbutton = onsiteassignobj.showservicelocationtypeahead || onsiteassignobj.showengineertypeahead;
				break;
			case 'Work in Progress':
                onsiteassignobj.showassignmenttag = true;
				onsiteassignobj.showengineerlabel = true;
				onsiteassignobj.showservicelocationlabel = true;
				onsiteassignobj.showengineertypeahead = true;
				onsiteassignobj.showservicelocationtypeahead = false;
				onsiteassignobj.showassignmentbutton = onsiteassignobj.showservicelocationtypeahead || onsiteassignobj.showengineertypeahead;
				break;
			default:
				onsiteassignobj.showassignmenttag = true;
				onsiteassignobj.showservicelocationlabel = true;
				onsiteassignobj.showengineerlabel = true;
				onsiteassignobj.showengineertypeahead = false;
				onsiteassignobj.showservicelocationtypeahead = false;
				onsiteassignobj.showassignmentbutton = onsiteassignobj.showservicelocationtypeahead || onsiteassignobj.showengineertypeahead;
				break;
		}
		// if (qFilter.type == 3) {
        //     onsiteassignobj.showservicelocationtypeahead = false;
        // }
		return onsiteassignobj
	}

	const checkCarryIn =()=>{
		let carryinassignobj = {};
		carryinassignobj.showengineerlabel = false;
        carryinassignobj.showengineertypeahead = false;
        carryinassignobj.showassignmenttag = true;
        carryinassignobj.showservicelocationlabel = true;
        carryinassignobj.showservicelocationtypeahead = false;
        carryinassignobj.showassignmentbutton = carryinassignobj.showservicelocationtypeahead || carryinassignobj.showengineertypeahead;
        carryinassignobj.showestimationoverride = false;
		return carryinassignobj;
	}

	const checkPickUp = () =>{
		let pickupassignobj = {};
		let  status = consumerServiceRequest && consumerServiceRequest.Status;
		pickupassignobj.showengineerlabel = false;
        pickupassignobj.showengineertypeahead = false;
        pickupassignobj.showassignmenttag = true;
        pickupassignobj.showservicelocationlabel = true;
        pickupassignobj.showservicelocationtypeahead = false;
        pickupassignobj.showassignmentbutton = pickupassignobj.showservicelocationtypeahead || pickupassignobj.showengineertypeahead;
        pickupassignobj.showestimationoverride = false;

		switch (status) {
            case 'Estimation generated':
                pickupassignobj.showestimationoverride = false;
                break;
            case 'Estimation approval':
                pickupassignobj.showestimationoverride = true;
                break;
            default:
                break;
        }
		return pickupassignobj;
	}

	const checkDropOff = () =>{
		let dropoffassignobj = {};
		let status = consumerServiceRequest && consumerServiceRequest.Status;
		dropoffassignobj.showengineerlabel = false;
		dropoffassignobj.showengineertypeahead = false;
		dropoffassignobj.showassignmenttag = true;
		dropoffassignobj.showservicelocationlabel = true;
		dropoffassignobj.showservicelocationtypeahead = false;
		dropoffassignobj.showassignmentbutton = dropoffassignobj.showservicelocationtypeahead || dropoffassignobj.showengineertypeahead;
		dropoffassignobj.showestimationoverride = false;
		switch (status) {
			case 'Estimation generated':
				dropoffassignobj.showestimationoverride = false;
				break;
			case 'Estimation approval':
				dropoffassignobj.showestimationoverride = true;
				break;
			default:
				break;
		}
		return dropoffassignobj;
	}
	const checkDemo = ()=>{
		let demoassignobj = {};
		let status = consumerServiceRequest && consumerServiceRequest.Status;
		let engineerid = consumerServiceRequest.ServiceLocationEngineerID;
		let servicelocationid = consumerServiceRequest.PartnerServiceLocationID;
		let isengineerassigned = engineerid ? true : false;
		let isservicelocationassigned = servicelocationid ? true : false;
		switch (status) {
			case 'Pending for assignment':
				demoassignobj.showassignmenttag = true;
                demoassignobj.showengineerlabel = false;
                demoassignobj.showservicelocationlabel = isservicelocationassigned;
                demoassignobj.showservicelocationtypeahead = !isservicelocationassigned;
                demoassignobj.showengineertypeahead = true;
                demoassignobj.showassignmentbutton = demoassignobj.showservicelocationtypeahead || demoassignobj.showengineertypeahead;
				break;
			case 'Pending for approval':
                demoassignobj.showassignmenttag = true;
                demoassignobj.showservicelocationlabel = isservicelocationassigned;
                demoassignobj.showengineerlabel = false;
                demoassignobj.showservicelocationtypeahead = false;
                demoassignobj.showengineertypeahead = isservicelocationassigned && !isengineerassigned;
                demoassignobj.showassignmentbutton = demoassignobj.showservicelocationtypeahead || demoassignobj.showengineertypeahead;
			case 'Service accepted':
                demoassignobj.showassignmenttag = true;
                demoassignobj.showservicelocationlabel = true;
                demoassignobj.showengineerlabel = true;
                demoassignobj.showservicelocationtypeahead = false;
                demoassignobj.showengineertypeahead = false;
                demoassignobj.showassignmentbutton = demoassignobj.showservicelocationtypeahead || demoassignobj.showengineertypeahead;
				break;
			case 'Service suspended':
                demoassignobj.showassignmenttag = true;
                demoassignobj.showservicelocationlabel = true;
                demoassignobj.showengineerlabel = true;
                demoassignobj.showservicelocationtypeahead = false;
                demoassignobj.showengineertypeahead = false;
                demoassignobj.showassignmentbutton = demoassignobj.showservicelocationtypeahead || demoassignobj.showengineertypeahead;
				break;
			default:
				demoassignobj.showassignmenttag = true;
                demoassignobj.showservicelocationlabel = true;
                demoassignobj.showengineerlabel = true;
                demoassignobj.showservicelocationtypeahead = false;
                demoassignobj.showengineertypeahead = false;
                demoassignobj.showassignmentbutton = demoassignobj.showservicelocationtypeahead || demoassignobj.showengineertypeahead;
				break;
		}
		return demoassignobj;
	}

	const checkServicing = () => {
		let servicingassignobj = {};
		let status = consumerServiceRequest && consumerServiceRequest.Status;
		let engineerid = consumerServiceRequest.ServiceLocationEngineerID;
        let servicelocationid = consumerServiceRequest.PartnerServiceLocationID;
        let isengineerassigned = engineerid ? true : false;
        let isservicelocationassigned = servicelocationid ? true : false;
		switch (status) {
			case 'Pending for assignment':
                servicingassignobj.showassignmenttag = true;
                servicingassignobj.showengineerlabel = false;
                servicingassignobj.showservicelocationlabel = false;
                servicingassignobj.showservicelocationtypeahead = true;
                servicingassignobj.showengineertypeahead = true;
                servicingassignobj.showassignmentbutton = servicingassignobj.showservicelocationtypeahead || servicingassignobj.showengineertypeahead;
				break;
			case 'Pending for approval':
				servicingassignobj.showassignmenttag = true;
                servicingassignobj.showservicelocationlabel = isservicelocationassigned;
                servicingassignobj.showengineerlabel = false;
                servicingassignobj.showservicelocationtypeahead = false;
                servicingassignobj.showengineertypeahead = isservicelocationassigned && !isengineerassigned;
                servicingassignobj.showassignmentbutton = servicingassignobj.showservicelocationtypeahead || servicingassignobj.showengineertypeahead;
				break;
			case 'Service accepted':
                servicingassignobj.showassignmenttag = true;
                servicingassignobj.showservicelocationlabel = true;
                servicingassignobj.showengineerlabel = true;
                servicingassignobj.showservicelocationtypeahead = false;
                servicingassignobj.showengineertypeahead = false;
                servicingassignobj.showassignmentbutton = servicingassignobj.showservicelocationtypeahead || servicingassignobj.showengineertypeahead;
				break
			case 'Service suspended':
                servicingassignobj.showassignmenttag = true;
                servicingassignobj.showservicelocationlabel = true;
                servicingassignobj.showengineerlabel = true;
                servicingassignobj.showservicelocationtypeahead = false;
                servicingassignobj.showengineertypeahead = false;
                servicingassignobj.showassignmentbutton = servicingassignobj.showservicelocationtypeahead || servicingassignobj.showengineertypeahead;
				break;
			default:
				servicingassignobj.showassignmenttag = true;
                servicingassignobj.showservicelocationlabel = true;
                servicingassignobj.showengineerlabel = true;
                servicingassignobj.showservicelocationtypeahead = false;
                servicingassignobj.showengineertypeahead = false;
                servicingassignobj.showassignmentbutton = servicingassignobj.showservicelocationtypeahead || servicingassignobj.showengineertypeahead;
				break;
		}
		return servicingassignobj;
	}
	const checkInstallation = ()=>{
		let installassignobj = {};
		let status = consumerServiceRequest && consumerServiceRequest.Status;
		let engineerid = consumerServiceRequest.ServiceLocationEngineerID;
        let servicelocationid = consumerServiceRequest.PartnerServiceLocationID;
        let isengineerassigned = engineerid ? true : false;
        let isservicelocationassigned = servicelocationid ? true : false;
		switch (status) {
			case 'Pending for assignment':
                installassignobj.showassignmenttag = true;
                installassignobj.showengineerlabel = false;
                installassignobj.showservicelocationlabel = isservicelocationassigned;
                installassignobj.showservicelocationtypeahead = !isservicelocationassigned;
                installassignobj.showengineertypeahead = true;
                installassignobj.showassignmentbutton = installassignobj.showservicelocationtypeahead || installassignobj.showengineertypeahead;
				break;
			case 'Pending for approval':
                installassignobj.showassignmenttag = true;
                installassignobj.showservicelocationlabel = isservicelocationassigned;
                installassignobj.showengineerlabel = false;
                installassignobj.showservicelocationtypeahead = false;
                installassignobj.showengineertypeahead = isservicelocationassigned && !isengineerassigned;
                installassignobj.showassignmentbutton = installassignobj.showservicelocationtypeahead || installassignobj.showengineertypeahead;
                break;
			case 'Service accepted':
                installassignobj.showassignmenttag = true;
                installassignobj.showservicelocationlabel = true;
                installassignobj.showengineerlabel = true;
                installassignobj.showservicelocationtypeahead = false;
                installassignobj.showengineertypeahead = false;
                installassignobj.showassignmentbutton = installassignobj.showservicelocationtypeahead || installassignobj.showengineertypeahead;
				break;
			case 'Service suspended':
				installassignobj.showassignmenttag = true;
                installassignobj.showservicelocationlabel = true;
                installassignobj.showengineerlabel = true;
                installassignobj.showservicelocationtypeahead = false;
                installassignobj.showengineertypeahead = false;
                installassignobj.showassignmentbutton = installassignobj.showservicelocationtypeahead || installassignobj.showengineertypeahead;
			default:
				installassignobj.showassignmenttag = true;
                installassignobj.showservicelocationlabel = true;
                installassignobj.showengineerlabel = true;
                installassignobj.showservicelocationtypeahead = false;
                installassignobj.showengineertypeahead = false;
                installassignobj.showassignmentbutton = installassignobj.showservicelocationtypeahead || installassignobj.showengineertypeahead;
				break;
		}
		return installassignobj;
	}
	const isDiagnosisCompleted = (skipPerformedByRoleFordiagInit) => {
		let diagInit;
		if(skipPerformedByRoleFordiagInit) {
			diagInit = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis initiated'});
		}else {
			diagInit = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis initiated',PerformedByRole:'ServicelocationEngineer'});
		}
        let diagComp = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis completed',PerformedByRole:'ServicelocationEngineer'});
        let cannotDiag = findIndex(consumerServiceRequest.logs, {Status: 'Cannot be diagnosed',PerformedByRole:'ServicelocationEngineer'});

        if (diagInit != -1) {
            if (diagComp != -1) {
                return diagComp < diagInit;
            }
            if (cannotDiag != -1) {
                return cannotDiag < diagInit
            }
            return false
        }
        return true
    }

 	const isDOAEligible = () => {
		if (consumerServiceRequest && consumerServiceRequest.DOAStatus == 'eligible' && ((['CN','NL','FR','BE', 'FI'].indexOf(defaultCountry) > -1) || consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN) && !accessFn('GSX_FLOW')){
			return true;
		}else {
			return false;
		}
	}
	const checkDOAApplicable = () => {
		// return true; // has to be removed ... right now because api doesnt return me any status
		if (consumerServiceRequest && consumerServiceRequest.DOAStatus == 'applicable' && consumerServiceRequest.ServiceTypeID == SERVICETYPE.CARRY_IN) {
			return true;
		} else {
			return false;
		}
	}
	const isHandedToCC = () => {
		let isHanded = false
		let deviceHanded = find(consumerServiceRequest.logs, {Status: 'Device accepted'})
		if (deviceHanded) {
			if (deviceHanded.DestinationType == 'CustomerCare') {
				isHanded = true;
			}
		}
		return isHanded;
	}

	const checkOnsiteEwReq = () => {
        return [SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1 ? (consumerServiceRequest.EWRequest ? true : false) : true; 
    };
    const checkOnlyBuyoutMode =()=> {
        if(consumerServiceRequest && consumerServiceRequest.decisionMatrix && consumerServiceRequest.DecisionMatrixModes && consumerServiceRequest.DecisionMatrixModes.length == 1 && consumerServiceRequest.DecisionMatrixModes[0] == SERVICETYPE.ON_SITE) {
            return false;
        } else {
            return true;
        }
    }
	const checkSkipAdvance = () =>{
        if(countriesChecks && countriesChecks.countryConfig && countriesChecks.countryConfig.SkipAdvance) {
            return false;
        } else {
            return true;
        }
    }
    const checkPaymentStatus =()=> {
		// if(show){
			if(AdvanceChargeApplicable  && checkOnlyBuyoutMode() && checkSkipAdvance() && paymentAmount > 0) {
				if (paymentLinkStatus ) {
					return true;
				} else {
					return false;
				}
			} else {
				return true
			}
		// }

    };
	// const getDecisiomMatrixHelpText = () => {
    //     return consumerServiceRequest && consumerServiceRequest.SkipDecisionForm ? "Waiting for the admin team to select service outcome" : "Waiting for the admin team to fill the Decision form to decide service outcome";
    // };
    const warrantyAppicableNonClaimCheck = ()=> {
        return (consumerServiceRequest.IsWarrantyApplicable != null || undefined) ? true : (consumerServiceRequest.Status == 'Service initiated') ? false : true;
    }
    const checkDecisionMatrixProcessCompleted=() =>{
		if(!BlockDecisionMatrixFlow) {
            if(consumerServiceRequest && consumerServiceRequest.decisionMatrix && !consumerServiceRequest.DecisionMatrixModes) {
				return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const getDeviceRecievedByName = () =>{
        if(consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.length > 0) {
            let deviceRecievedObj = consumerServiceRequest.logs.find((obj)=>{
                return obj.Status == 'Device received'
            });
            let serviceAcceptedObj = consumerServiceRequest.logs.find((obj)=>{
                return obj.Status == 'Service accepted'
            });
            return deviceRecievedObj && deviceRecievedObj.Name ? deviceRecievedObj.Name : (serviceAcceptedObj && serviceAcceptedObj.Name ? serviceAcceptedObj.Name:'');
        } else {
            return '';
        }
    };
    const showStatus =  (status) =>{
		let currentStatus = getStatusString(status,consumerServiceRequest.ServiceTypeID, accessFn);
        switch (currentStatus) {
            case 'In-principle approved':
                if(!checkDecisionMatrixProcessCompleted()){
                    currentStatus = 'Pending for decision outcome';
                } else {}
                break;
        }
        return currentStatus;
        // let currentStatus = getStatusString(status, consumerServiceRequest.ServiceTypeID, accessFn)
		// switch (currentStatus) {
        //     case 'Payment received':
        //         if(accessFn('WORKSHOP_FLOW')){
        //             if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'Payment received';
        //             }
        //         }
        //         else {
        //             if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'Payment received';
        //             }else{
        //                 status = 'Approved for repair';
        //             }
        //         }
        //         break;
        //     case 'Service cancel':
        //         status = 'Service cancelled';
        //         break;
        //     case 'Repair cancel':
        //         if([9,13].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //             status = 'Recycle Request cancelled';
        //         }
        //         else {
        //             status = 'Repair cancel';
        //         }
        //         break;
        //     case 'Service accepted':
        //         if([13].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
        //             status = 'Dropoff Request Registered';
        //         }
        //         else {
        //             status = 'Service accepted';
        //         }
        //         break;
        //     case 'Service initiated':
        //         if([9,13].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
        //             status = 'Verification Complete';
        //         }
        //         else {
        //             status = 'Service initiated';
        //         }
        //         break;
        //     case 'Vouchers generated':
        //         if([9,13].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
        //             status = 'Green Reward Update';
        //         }
        //         else {
        //             status = 'Vouchers generated';
        //         }
        //         break;
        //     case 'Device recycle initiated':
        //         if([9,13].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
        //             status = 'Initial Dismantling Completed';
        //         }
        //         else {
        //             status = 'Device recycle initiated';
        //         }
        //         break;
        //     case 'Service completed':
        //         if([9,13].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
        //             status = 'Recycle Completed';
        //         }
        //         else {
        //             status = 'Service completed';
        //         }
        //         break;
        //     case 'QC completed':
        //         if([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) === -1){
        //             status = 'Repair Completed, Ready To Inform The Customer';
        //         }
        //         break;
        //     case 'Device dispatched':
        //         if(accessFn('WORKSHOP_FLOW')){
        //             if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'Drop Request Created Post Repair';
        //             }
        //         }
        //         else {
        //             // status = 'Device dispatched';
        //         }
        //         break;
        //     case 'Pending for assignment':
        //         if(accessFn('WORKSHOP_FLOW')){
        //             if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'Inspection Completed, Awaiting Engineer Assignment';
        //             }
        //         }
        //         else {
        //             status = 'Pending for assignment';
        //         }
        //         break;
        //     case 'QC pending':
        //         if(accessFn('WORKSHOP_FLOW')){
        //             if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'Repair completed, QC pending';
        //             }
        //         }
        //         else {
        //         }
        //         break;
        //     case 'Pending for acceptance':
        //         if(accessFn('WORKSHOP_FLOW')){
        //             if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'Pending for Acceptance by Engineer';
        //             }
        //         }
        //         else {
        //         }
        //         break;
        //     case 'Work in progress':
        //         if(accessFn('WORKSHOP_FLOW')){
        //             if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'Work in Progress with Engineer';
        //             }
        //         }
        //         else {
        //         }
        //         break;
        //     case 'QC rejected':
        //         if(accessFn('WORKSHOP_FLOW')){
        //             if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'QC rejected, Work in progress';
        //             }
        //         }
        //         else {
        //         }
        //         break;
        //     case 'Repair completed and Intimate customer':
        //         if(accessFn('WORKSHOP_FLOW')){
        //             if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'Repair completed, Intimate customer';
        //             }
        //         }
        //         else {
        //         }
        //         break;
        //     case 'Intimate customer':
        //         if(accessFn('WORKSHOP_FLOW')){
        //             if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'Repair completed and Inform customer';
        //             }
        //         }
        //         else {
        //         }
        //         break;
        //     case 'Diagnosis Completed':
        //         if(accessFn('WORKSHOP_FLOW')){
        //             if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'Diagnosis initiated on device; Work in Progress';
        //             }
        //         }
        //         else {
        //         }
        //         break;
        //     case 'Device received':
        //         if(accessFn('WORKSHOP_FLOW')){
        //             if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'Device received';
        //             }
        //         }
        //         else {
        //         }
        //         break;
        //     case 'Device accepted':
        //         if(accessFn('WORKSHOP_FLOW')){
        //             if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'Device accepted';
        //             }
        //         }
        //         else {
        //         }
        //         break;
        //     case 'Device handed over':
        //         if(accessFn('WORKSHOP_FLOW')){
        //             if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'Device handed over';
        //             }
        //         }
        //         else {
        //         }
        //         break;
        //     case 'Device inspected':
        //         if(accessFn('WORKSHOP_FLOW')){
        //             if([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //                 status = 'Device inspected';
        //             }
        //         }
        //         else {
        //         }
        //         break;
        //     // case 'In-principle approved':
        //     //     if(checkDecisionMatrixProcessCompleted()){
        //     //         status = 'Pending for decision outcome';
        //     //     } else {
        //     //     }
        //     //     break;
        //     case 'Serviceman journey initiated':
        //         if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //             status = 'Technician initiated journey';
        //         } else {
        //         }
        //         break;
        //     case 'Serviceman reached':
        //         if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
        //             status = 'Technician reached';
        //         } else {
        //         }
        //         break;
        // }
        // return status;
    };
	const isSingleRole =  () => {
		let roleFeatureRightsCount = 0;
		if (qFilter) {
			if (qFilter.hasUnrestrictedAccess) {
				return true;
			} else {
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					roleFeatureRightsCount++;
				}
				if (accessFn('STORE_MANAGEMENT')) {
					roleFeatureRightsCount++;
				}
				if (accessFn('REASSIGN_JOBS')) {
					roleFeatureRightsCount++;
				}
				if (accessFn('REPAIR_DEVICE')) {
					roleFeatureRightsCount++;
				}
				if (accessFn('QC_DEVICE')) {
					roleFeatureRightsCount++;
				}
				if (accessFn('APPROVE_EXCEPTION_REQUEST')) {
					roleFeatureRightsCount++;
				}
				if (roleFeatureRightsCount > 1) {
					return false;
				}
				else {
					return true;
				}
			}
		}
		else {
			return false;
		}
	}

	const showStatusLog = (status, logs)=>{
        let currentStatus = find(logs, {Status: status});
        return currentStatus;
    };
	const getServiceCompletedByName = () =>{
        if(consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs.length > 0) {
            let serviceCompletedObj = consumerServiceRequest.logs.find((obj)=>{
                return obj.Status == 'Service completed'
            });
            return serviceCompletedObj && serviceCompletedObj.Name ? serviceCompletedObj.Name : '';
        } else {
            return '';
        }
    };

	const getSumInsured = () => {
        if(consumerServiceRequest && coverageDetails && coverageDetails.length > 0) {
            let selectedCoverage = coverageDetails.find((obj) => {
                return obj.Status == 1 && obj.RequestDetails && obj.RequestDetails.ClaimReferenceID == consumerServiceRequest.ReferenceID;
            });
            return selectedCoverage && selectedCoverage.RequestDetails.SumInsured ? selectedCoverage.RequestDetails.SumInsured : null;
        } else {
            return null;
        }
    };

	const checkDeviceValueAndDateAdded = () => {
        let SumInsured = getSumInsured();
        if(['Homeserve', 'Servify_Test'].indexOf(consumerServiceRequest.Source) == -1 && !SumInsured && consumerServiceRequest.consumerProduct && (!consumerServiceRequest.consumerProduct.ProductPurchaseCost || !consumerServiceRequest.consumerProduct.DateOfPurchase)) {
			return false;
        } else {
            return true;
        }
    };
	const coveredUnderPlanCheck = () => {
        return consumerServiceRequest && consumerServiceRequest.EWRequest ? ((consumerServiceRequest.IsPlanEligible != null || undefined) ? true : false) : true;
    };
	const getSource = ()=>{
		if(consumerServiceRequest){
			if(consumerServiceRequest.isAnchorRequest){
				return 'Anchor';
			}else if(consumerServiceRequest.Source == "SkyworthDashboard"){
				return 'Skyworth';
			}else if(consumerServiceRequest.Source == "BoseDashboard"){
				return 'Bose';
			}else if(consumerServiceRequest.Source == "SmartTechnoDashboard"){
				return 'SmartTechno';
			}else if(consumerServiceRequest.Source == "SingularityDashboard"){
				return 'Singularity';
			} else if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
				return consumerServiceRequest.Source;
			}else if ([SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && isBoseFlow) {
				return 'Servify';
			}else{
				return 'Croma';
			}
		}else{
			return null;
		}
    }
	const isServiceLocationAssigned =  ()=> {
		if (consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID) {
			return true
		}
		return false;
    }

	const getDeviceReceivedHelperText = ()=> {
        var SumInsured = getSumInsured();
        if(accessFn('BER_CUSTOMER_ACTION') && (consumerServiceRequest && consumerServiceRequest.consumerProduct && !SumInsured  && (!consumerServiceRequest.consumerProduct.ProductPurchaseCost || !consumerServiceRequest.consumerProduct.DateOfPurchase))) {
            return "Add Device Date of Purchase and Device Value in 'Device Details' below.";
        } else if(consumerServiceRequest && consumerServiceRequest.EWRequest && (consumerServiceRequest.IsPlanEligible == null || undefined)) {
            return "Please select if the request is covered under plan in Device Details section.";
        } else if(accessFn('ADD_NORMAL_CHARGES')) {
            return "Add a part charge in 'Estimation Details' below to generate estimation.";
        }
    };
	const checkIfSalesChannelIsequiredAndPresent =  () =>{
		if (consumerServiceRequest && consumerServiceRequest && [SERVICETYPE.INSTALLATION].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1) {
			if (consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.SalesChannelID) {
				return true;
			}
			return;
		}
		return true;
	}
	const showUpdateLogisticsRequestButton = () => {
		if (accessFn('UPDATE_LOGISTICS_REQUEST')) {
			if ([SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_PICK_UP].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
				if (['Pickup Request Accepted'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) > -1) {
					return true;
				}
				else if (['Repair completed','Repair cancel'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) > -1) {
					return true;
				}
				return false;
			}
			else if ([24].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
				if (['Service accepted'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) > -1) {
					return true;
				}
				return false;
			}
			else if ([SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.PreviousServiceTypeID) > -1 && [SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
				if (['Repair completed','Repair cancel'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) > -1) {
					return true;
				}
				return false;
			}
			return false;
		}
		return false;
	}

	const showCompleteServiceFunction = () =>{
		if(taskListArr  && taskListArr.length !==0){
			if (consumerServiceRequest && consumerServiceRequest.openTaskCount === 0) {
			// task count is 0
			return true
			} else {
			// task count is not 0
			return false
			}
		} else {
			// there is no task list
			return true
		}
    }
	const showCancelButtonFuncton =  () =>{
		if (accessFn('CANCEL_REQUEST_ANYTIME') && consumerServiceRequest && consumerServiceRequest.EnableCancelButton && consumerServiceRequest.openTaskCount === 0 )  {
			return true
		}
		return false
    }

	const isSymptomMapAdded = () =>{
		if(consumerServiceRequest && consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 ) {
			return true
		}
		return false
    }

    const getRequestById  = (remark) => {
		let viewRequestIsValid = {...showViewRequestFlags}
		setPreviousStatus(consumerServiceRequest.Status)
		setCsrLoader(true);
		if(!remark){
			remark = '';
		}
		let timeZone = returnTimezone();
		// viewRequestIsValid.advancePaymentCallFetched = false;
		setAdvancePaymentCallFetched(false)
		// setShowViewRequestFlags( viewRequestIsValid);
		let data = {
			ConsumerServiceRequestID: ConsumerServiceRequestID ,
			sendImagePath: 1,
			timezone: timeZone,
			Remark: remark
		};
		// setCsrLoader(true);
		setShowRefreshButton(true)
		apiCall({
			url:'getCSRViewDetails',
			data: data,
			callBack:(result)=>{
				setCsrLoader(false);
				setShowRefreshButton(true)
				if(result.success){
					// let viewRequestIsValid = {...showViewRequestFlags};
					let consumerRequest = result.data;
					if (consumerServiceRequest &&!consumerServiceRequest.ConsumerServiceRequestID) {
						fetchConsumerDetail();
						getEscalations();
						getEscalationsData();
					}
					setConsumerServiceRequest(result.data);
					setShowViewRequestFlags(viewRequestIsValid);
					// setShowActionButton(true)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

    const getEscalations = () => {
		let data =  {
			"ConsumerServiceRequestID": ConsumerServiceRequestID
		}
		setEscLoader(true);
        apiCall({
			url:'getEscalations',
			data: data,
			callBack:(result)=>{
				setEscLoader(false);
				if(result.success){
					let escalations = result.data;
                    if(escalations && escalations.length){
                        if(escalations[0].EscalationSource){
							setEscalationTag(escalations[0].EscalationSource.SourceName);
							setEscalationTagColor(escalations[0].EscalationSource.EscalationFlagColor);
                            if(escalations[0].EscalationSourceMedia){
                                setEscalationTag( escalations[0].EscalationSource.SourceName+" "+escalations[0].EscalationSourceMedia.MediaName);
                            }
                        }else{
                            setEscalationTag(escalations[0].EscalationType.EscalationName);
                        }
                        if(escalations.length > 1){
                            let length= escalations.length-1;
                            setEscalationTag( escalations[0].EscalationSource.SourceName+" "+escalations[0].EscalationSourceMedia.MediaName+"+"+length);
                        }
						// setShowActionButton(true)
                    }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }


	const checkAdvancePaymentRequired =()=> {
		let data = {
			"SoldPlanID" : consumerServiceRequest && consumerServiceRequest.SoldPlanID,
			"ConsumerProductID" :consumerServiceRequest && consumerServiceRequest.ConsumerProductID,
			"damageId" : claimDamageId,
			"CountryID": consumerServiceRequest && consumerServiceRequest.CountryID,
			"SoldPlanCoverageID":  soldPlanCoverage.SoldPlanCoverageID
		};

		setLoader(true);
		apiCall({
			url:'getAdvanceDetails',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let responseData = result.data;
					setAdvanceChargeApplicable(responseData.AdvanceChargeApplicable);
					setPaymentAmount(responseData.PaymentAmount);
					// setShowActionButton(true)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    };



	const claimRequestNext = (status,shipmentDetails)=> {
		setClaimLoader(true)
        let data = {};
        data.ConsumerServiceRequestID = ConsumerServiceRequestID;
        data.Status = status;
        data.ServiceTypeID =consumerServiceRequest && consumerServiceRequest.ServiceTypeID;
        data.CountryID = consumerServiceRequest && consumerServiceRequest.CountryID ? consumerServiceRequest.CountryID : undefined;
		if (['In-principle approved'].indexOf(status) > -1) {
			data.SkipClaimApproval = showViewRequestFlags.planSkipClaimApproval;
			data.AdvanceChargeApplicable = AdvanceChargeApplicable;
			data.SoldPlanCoverageID = soldPlanCoverage && soldPlanCoverage.SoldPlanCoverageID;
			data.PlanCoverageID = soldPlanCoverage && soldPlanCoverage.PlanCoverageID;
            data.DecisionMatrix = consumerServiceRequest && consumerServiceRequest.decisionMatrix ? consumerServiceRequest.decisionMatrix : undefined;
		}
        if(shipmentDetails){
            data.shipmentDetails = shipmentDetails;
        }
		apiCall({
			url:'claimRequest',
			data: data,
			callBack:(result)=>{
				setClaimLoader(false);
				if(result.success){
					getRequestById();
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    };
    const getServiceRequestException = () => {
		let data =  {
			"ConsumerServiceRequestID": ConsumerServiceRequestID
		}
		setLoader(true);
		apiCall({
			url:'getException',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    if(!isEmpty(result && result.data)) {
						let Exception = result.data.getExceptionDetails && result.data.getExceptionDetails.data
                        setException(Exception);
                        setExceptionLogs(result.data.getLogs && result.data.getLogs.data);
                        setShowViewRequestFlags({...showViewRequestFlags,isExceptionCase:true});
                        let isExceptionRejected = find(exceptionLogs, {Status: 'Exception rejected'})
                        let isExceptionRaised = find(exceptionLogs, {Status: 'Exception raised'})
                        let exceptionRejectedIndex = findIndex(exceptionLogs, {Status: 'Exception rejected'})
                        let exceptionRaisedIndex = findIndex(exceptionLogs, {Status: 'Exception raised'})
                        // exception is set to null so that normal flow can be continued.
                        if(isExceptionRejected && !(exceptionRaisedIndex < exceptionRejectedIndex)) {
                            setException([]);
                        }
                    } else {
                        setException([]);
                        setExceptionLogs([]);
                    }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const getEscalationsData = ()=>{
        let data = {
            ConsumerServiceRequestID: ConsumerServiceRequestID
        };
		setEscDataLoader(true);
        apiCall({
			url:'getEscalationsByCsrID',
			data: data,
			callBack:(result)=>{
				setEscDataLoader(false);
				if(result.success){
                    setEditableEscalationData(result.data);
					// setShowActionButton(true)
				}
			}
		});
    }

	const checkPaymentLinkStatus = () =>{
        let data = {
            ConsumerServiceRequestID: ConsumerServiceRequestID,
        };
		setPaymentLinkLoader(true);
        apiCall({
			url:'checkPaymentLinkStatus',
			data: data,
			callBack:(result)=>{
				setPaymentLinkLoader(false);
				if(result.success){
					let viewRequestIsValid = {...showViewRequestFlags};
                    let responseData = result.data;
					viewRequestIsValid.isPaymentLinkSent = responseData.StatusValue ? true : false;
					setPaymentLinkStatus(responseData.PaymentStatus)
					setShowViewRequestFlags(viewRequestIsValid)
					// setShowActionButton(true);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});

    };

	const getApplicationFeatureConfig = () => {
		let data = {
            "BrandID":  consumerServiceRequest &&  consumerServiceRequest.consumerProduct &&consumerServiceRequest.consumerProduct.BrandID,
            "PartnerID":consumerServiceRequest && consumerServiceRequest.PartnerID,
            "PartnerServiceLocationID":consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID,
            "ProductSubCategoryID":consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID
		}
		setConfigLoader(true);
        apiCall({
			url:'getApplicationFeatureConfig',
			data: data,
			callBack:(result)=>{
				setConfigLoader(false);
				if(result.success){
					setFeatureConfig(result.data);
					// setShowActionButton(true);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }
    const getValueOfDecisionMatrix = (soldCoverageID) => {
		let data = {
            BrandID:consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID,
            ProductSubCategoryID:consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID,
            PlanCoverageID: soldCoverageID && soldCoverageID.PlanCoverageID,
            SoldPlanID: consumerServiceRequest && consumerServiceRequest.SoldPlanID
        };
		if(['Claim initiated', 'Document verification pending'].indexOf(consumerServiceRequest.Status) > -1) {
            data.skipDecisionFormInputs = true;
            data.skipModes = true;
        }
		setDecLoader(true);
		apiCall({
			url:'getValueOfDecisionMatrix',
			data: data,
			callBack:(result)=>{
				setDecLoader(false);
				// if(result.success){
					if (result && result.success) {
						if(result.data) {
							let consumerServiceRequestObj = {...consumerServiceRequest};

							// setConsumerServiceRequest({...consumerServiceRequest,decisionMatrix : result.data.IsDecisionMatrixEnabled,
							// 	decisionFormQuestions : result.data.DecisionFormQuestions,
							// 	selectOutcomeModes: result.data.Modes
							// });
							// setConsumerServiceRequest({...consumerServiceRequest,decisionFormQuestions : result.data.DecisionFormQuestions});
							// setConsumerServiceRequest({...consumerServiceRequest,selectOutcomeModes: result.data.Modes});


							consumerServiceRequest.decisionMatrix = result.data.IsDecisionMatrixEnabled;
							consumerServiceRequest.decisionFormQuestions = result.data.DecisionFormQuestions;
							consumerServiceRequest.selectOutcomeModes = result.data.Modes;
							// setConsumerServiceRequest(consumerServiceRequestObj)


						}
						// setShowActionButton(true)
					} else {
						if(result && result.data && result.data.BlockDecisionMatrixFlow) {
							setBlockDecisionMatrixFlow(result.data.BlockDecisionMatrixFlow)
						}
						Swal.fire ({
							title: '',
							text: result.msg ? localString(result.msg) : localString("Something went wrong"),
							icon: 'error',
							confirmButtonText: localString('OK'),
							cancelButtonText: localString('Cancel')
						});
					}
				}
				// else{
				// 	Swal.fire({
				// 		icon: 'error',
				// 		text: localString(displayError(result)),
				// 	});
				// }
			// }
		});
	}

	const getConsumerDetailsForPickup = ()=>{
		let data = {
			ConsumerServiceRequestIDorReferenceID:consumerServiceRequest.ConsumerServiceRequestID
		};
		setLoader(true);
		apiCall({
			url:'getConsumerDetailsForPickup',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					setLogisticsContact (result.data)
				}
				// else{
				// 	Swal.fire({
				// 		icon: 'error',
				// 		text: localString(displayError(result)),
				// 	});
				// }
			}
		});
	}
	const getInsuranceDetails = () => {
		let data = {
			"ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID
		}
		setInsuranceLoader(true);
		apiCall({
			url:'fetchInsuranceDetails',
			data: data,
			callBack:(result)=>{
				setInsuranceLoader(false);
				if(result.success){
					let insurancePlan = result.data
					setInsurancePlans(result.data);
					setSelectPlanBenefit(insurancePlan.selectPlanBenefit)

					if(result.data && result.data.coverageDetails){
						setCoverageDetails(result.data.coverageDetails)
					}
					setActiveCoverages(insurancePlan.activeCoverages)
					// if(result.data.HighRiskReason && result.data.HighRiskReason.length > 0 ){
					//     setInsuranceCoverage.enableHighRisk(true);
					// }
					// if(accessFn('SHOW_PLAN_BENEFITS')){
					//     tags["claim"] && tags["claim"].displayInsuranceDetails();
					// }
					// setShowActionButton(true)
				}else{

				}
			}
		});
	}

	const getActualCostConfig = () => {
		let data = {
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
		}
		setActualCostLoader(true);
		apiCall({
			url: "getActualCostConfig",
			data: data,
			callBack: (result) => {
				setActualCostLoader(false);
				if (result.success && result.data) {
					setActualCostAllowed(result.data.isAllowed)
				}
			}
		});
    }

	const getActualCost = () => {
		let data = {
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
		}
		setExtChargesLoader(true);
		apiCall({
			url: "getExternalChrgesByRequest",
			data: data,
			callBack: (result) => {
				setExtChargesLoader(false);
				if (result.success && result.data) {
					setActualCost(result.data)
					getActualCostConfig();
				}
			}
		});
	}

	const getSymptomFaultAction = ()=>{
		let data = {
			ConsumerServiceRequestID:consumerServiceRequest.ConsumerServiceRequestID
		};
		setFALoader(true);
		apiCall({
			url:'getSymptomFaultAction',
			data: data,
			callBack:(result)=>{
				setFALoader(false);
				if(result.success){
					setAddedFaultActions(result.data)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}
	const getPartnerSymptomFaultAction = ()=>{
		if(consumerServiceRequest && consumerServiceRequest.consumerProduct){
			let data = {
				"ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
				"ProductSubCategoryID": consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID,
				"BrandID":consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID
			};
			setLoader(true);
			apiCall({
				url:'getPartnerSymptomFaultAction',
				data: data,
				callBack:(result)=>{
					setLoader(false);
					if(result.success){
						let SFARequest = result.data && result.data.isPartnerFaultActionMapping;
						setShowRequestValid({...showRequestValid,isRequestSFANeeded:SFARequest})
						if(SFARequest){
							getRequestSFA();
						}
						// if(isRequestSFANeeded){
						// 	getRequestSFA();
						// }
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		}
	}


	const getRequestSFA = ()=>{
		if (consumerServiceRequest) {
			let data = {
				"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
				"ProductSubCategoryID": consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID,
				"BrandID":consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID
			}
			setSFALoader(true);
			apiCall({
				url:'getSymptomFaultActionMappings',
				data: data,
				callBack:(result)=>{
					setSFALoader(false);
					if(result.success){
						setRequestSFA(result.data)
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		}
	}


	const getPartsForRequest = ()=>{
		if(accessFn('GSX_FLOW')){
			let data = {
				"ConsumerServiceRequestID": ConsumerServiceRequestID
			}
			setPartsLoader(true);
			apiCall({
				url:'getPartsForRequestGSX',
				data: data,
				callBack:(result)=>{
					setPartsLoader(false);
					if(result.success){
						setPartsRequested(result.data)
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		}else{
			let data = {
				"ConsumerServiceRequestID": ConsumerServiceRequestID
			}
			setPartsLoader(true);
			apiCall({
				url:'getPartsForRequest',
				data: data,
				callBack:(result)=>{
					setPartsLoader(false);
					if(result.success){
						setPartsRequested(result.data)
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		}
	}

	const getCSRDetailsByID =( soldPlanCoverage,planId) =>{
		let data = {
			"ConsumerServiceRequestID": ConsumerServiceRequestID
		}
		setCsrLoader(true);
		apiCall({
			url:'getCSRDetails',
			data: data,
			callBack:(result)=>{
				setCsrLoader(false);
				if(result.success){
					let requestData = result.data;

					if(requestData.length > 0){
						let localClaimDamageID = requestData && requestData[0].damageId;
						setClaimDamageId(localClaimDamageID)
						getSkipAdvanceForPlan(soldPlanCoverage,planId);
						// checkToCallAdvancePayment(localClaimDamageID, soldCoverageID);
					}
					// setShowActionButton(true);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    };

	const getSkipAdvanceForPlan = (soldPlanCoverage,pId) => {
		let data = {
			brandID: consumerServiceRequest && consumerServiceRequest.consumerProduct.BrandID,
			planCoverageID: soldPlanCoverage && soldPlanCoverage.PlanCoverageID,
			planID: pId,
			countryID: consumerServiceRequest && consumerServiceRequest.CountryID
		};
		setSubLoader(true);
		apiCall({
			url:'checkSubscriptionConfiguration',
			data: data,
			callBack:(result)=>{
				setSubLoader(false);
				if(result.success){
					let isSubscriptionEnable = result.data && result.data.isSubscriptionEnabled;
					setIsSubscriptionEnabled(isSubscriptionEnable);

					// if(!isSubscriptionEnable) {
					// 	checkToCallAdvancePayment();
					// }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const getDiagnosisSuites = () =>{
		setDiagnosticsSuitesCalled(true);
		if(consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.productConfig && consumerServiceRequest.consumerProduct.productConfig.EligibleForDiagnosis){
			setDSuitesLoader(true);
			let data = {
				ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID
			};
			apiCall({
				url:'fetchDiagnosticsSuites',
				data: data,
				callBack:(result)=>{
					setDSuitesLoader(false);
					if(result.success){
						let arr = result.data && result.data.filter(suit=>suit.suiteName);
						setDiagnosisSuites(arr);
						setArrDiagnosisSuites(arr.map(suit=>suit.suiteName));
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		}
	}

	const checkToCallAdvancePayment = () =>{
		let viewRequestIsValid = {...showViewRequestFlags};
        if((claimDamageId != null) && !advancePaymentCallFetched && (['In-principle approved'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) > -1) && (consumerServiceRequest && consumerServiceRequest.ServiceTypeID == SERVICETYPE.CLAIM) && (['Airtel-Web', 'Airtel'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) == -1)) {
			// viewRequestIsValid.advancePaymentCallFetched=true;
			setAdvancePaymentCallFetched(true)
			// setShowViewRequestFlags(viewRequestIsValid);
            checkAdvancePaymentRequired();
            checkPaymentLinkStatus();
			// setShow(true);
        }
    };

    const getSoldPlanDetails = () => {
		let data = {
            SoldPlanID :consumerServiceRequest && consumerServiceRequest.SoldPlanID,
            ClaimReferenceID:consumerServiceRequest && consumerServiceRequest.ReferenceID
        };
		setSpLoader(true);
		apiCall({
			url:'getSoldPlan',
			data: data,
			callBack:(result)=>{
				setSpLoader(false);
				//  getCSRDetailsByID();
				if(result.success){
					let responseData = result.data && result.data[0];
					let planId = result.data && result.data[0] && result.data[0].PlanID;
					let soldPlanCoverage=responseData.SoldPlanCoverage;
					setSoldPlan(responseData);
					if (responseData) {
						setPlanID(result.data && result.data[0] && result.data[0].PlanID);
						if (responseData && responseData.SoldPlanCoverage) {
							setSoldPlanCoverage(responseData.SoldPlanCoverage);
							let planSkipClaim = (responseData.SoldPlanCoverage.planCoverage && responseData.SoldPlanCoverage.planCoverage.SkipClaimApproval ? true : false)
							setShowViewRequestFlags({...showViewRequestFlags,['planSkipClaimApproval']:planSkipClaim})
							if (['Airtel-Web', 'Airtel'].indexOf(consumerServiceRequest && consumerServiceRequest.Source) == -1) {
								let advanceChargeApplicable = responseData.SoldPlanCoverage && responseData.SoldPlanCoverage.planCoverage && responseData.SoldPlanCoverage.planCoverage.AdvanceChargeApplicable
								setAdvanceChargeApplicable(advanceChargeApplicable ? true : false);
							}
						}
                        if(['In-principle approved', 'Claim initiated', 'Document verification pending'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) > -1) {
							getValueOfDecisionMatrix(soldPlanCoverage);
                        }
                        getCSRDetailsByID(soldPlanCoverage,planId);
						if([SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) {
							if(responseData.PlanID || responseData.SoldPlanCoverage )
							{
								getAdjudicationChecklist(responseData.PlanID,responseData.SoldPlanCoverage);
							}
						}
					}
					// setShowActionButton(true);
				}
			}
		});
	}

	const getAdjudicationChecklist = (pID,cID) => {
		let data = {
            "ConsumerServiceRequestID":ConsumerServiceRequestID,
            "Status":consumerServiceRequest && consumerServiceRequest.Status,
            "PlanID": pID ? pID : planID
        };
		if(soldPlanCoverage || cID){
			data.PlanCoverageID = cID ? cID.PlanCoverageID : soldPlanCoverage.PlanCoverageID
		}
		setCheckListLoader(true);
		apiCall({
			url:'getAdjudicationChecklist',
			data: data,
			callBack:(result)=>{
				setCheckListLoader(false);
				if(result.success){
					setAdjudicationChecklistData(result.data);
					setShowActionButton(true)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const getHighRiskDetails = ()=>{
		let data = [{
			"entity": "ConsumerServiceRequest",
			"entityID":consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID
		},{
			"entity": "SoldPlan",
			"entityID": consumerServiceRequest && consumerServiceRequest.SoldPlanID
		}];
		setLoader(true);
		apiCall({
			url:'getReasonsforDetectedRaisedflag',
			data: {AttributesForHighRisk: data},
			callBack:(result)=>{
				setLoader(false);
				if(result.success && result.data && result.data.length>0){
					setHighRiskReason(result.data);
					setEnableHighRisk(true)
				}
			}
		});
	}

    const fetchConsumerDetail = (prepareAddress) => {
		setConsumerDetailLoader(true);
		apiCall({
			url:'fetchConsumerByCSRID',
			data: {
				ConsumerServiceRequestID: ConsumerServiceRequestID,
			},
			callBack:(result)=>{
				setConsumerDetailLoader(false);
				if(result.success){
					if (prepareAddress) {
						prepareAddressString(result.data);
					}else{
						setConsumerDetail(result.data);
						setShowActionButton(true);
					}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const getTatIndicator = (ConsumerServiceRequestID) =>{
		setTatLoader(true);
		apiCall({
			url:'getTatIndicator',
			data: {
				ConsumerServiceRequestID: ConsumerServiceRequestID && ConsumerServiceRequestID,
			},
			callBack:(result)=>{
				setTatLoader(false);
				if(result.success){
					if(result.data){
						setTatColor(result.data.color)
					}else{
						setTatColor("gray")
					}
					// setShowActionButton(true);
				}
			}
		});
    };
    const fetchAdvanceReceipt=()=>{
        let data = {
            ConsumerServiceRequestID: ConsumerServiceRequestID
        };
		setLoader(true);
        apiCall({
			url:'getAdvanceReceipts',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    setAdvanceReceipts(result.data && result.data.fetchReceipts && result.data.fetchReceipts.data);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    const fetchInvoice=()=>{
        let data = {
            ConsumerServiceRequestID:consumerServiceRequest.ConsumerServiceRequestID,
            generatePdf: true,
            Source : consumerServiceRequest.Source
        };
		setLoader(true);
        apiCall({
			url:'getInvoice',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    window.open(result.data && result.data.InvoiceUrl);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }
	const getLoanerDeviceList=()=>{
        let data = {
            PartnerServiceLocationID : consumerServiceRequest.PartnerServiceLocationID,
			IsLoaner : true,
			ProductID : consumerServiceRequest.consumerProduct.ProductID
        };
		setLoader(true);
        apiCall({
			url:'fetchLoanerDeviceList',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    setLoanerDeviceList(result.data)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const getLoanerCharges = (loanerData) => {
        let data ={
            "ConsumerServiceRequestID" : consumerServiceRequest.ConsumerServiceRequestID,
            "LoanerRequestID": loanerData.loanerRequestID
        }
        setLoader(true);
		apiCall({
			url: 'getLoanerCharges',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    setLoanerChargesResult(result.data);
                    setLoanerCharges(result.data.charges);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }
	const fetchLoanerDetailsByCsrId = () => {
        let data = {
            ConsumerServiceRequestID: consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            PartnerServiceLocationID: consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID,
            ProductID: consumerServiceRequest && consumerServiceRequest.consumerProduct.ProductID
        };
        setLoader(true);
		apiCall({
			url: 'fetchLoanerDetailsByCsrId',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    let loanerDetails = result.data;
                    setLoanerDetails(loanerDetails);
                    let currentLoanerStatus = loanerDetails.CurrentStatus;
                    setCurrentLoanerStatus(currentLoanerStatus)
                    let doaEntries = [];
                    loanerDetails && loanerDetails.loanerEntryDetails && loanerDetails.loanerEntryDetails.map((value)=> {
                        if(value.currentStatus =="LDOA"){
                            let doaPart = {};
                            doaPart.LoanerDeviceName = value.LoanerDeviceName;
                            doaPart.LoanerDeviceSerialNumber = value.LoanerDeviceSerialNumber;
                            doaEntries.push(doaPart);
                        }
                    });
                    setDOAEntries(doaEntries);
                    if(loanerDetails.chargetoCustomer){
                        getLoanerCharges(loanerDetails)
                    }
                    setFetchDetailsCalled(true);
				}else{
                    setFetchDetailsCalled(true);
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
				// if(result.success){
                //     setLoanerDetails(result.data);
				// }else{
				// 	Swal.fire({
				// 		icon: 'error',
				// 		text: localString(displayError(result)),
				// 	});
				// }
			}
		});
    }
    const fetchJobsheet = ()=>{
        let deviceRecievedByName = getDeviceRecievedByName();
        let data = {
            ConsumerServiceRequestID: ConsumerServiceRequestID
        };
        deviceRecievedByName && (data.Name = deviceRecievedByName);
		setLoader(true);
        apiCall({
			url:'fetchJobsheet',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){

					// let w = window.open();
                    // let html = result.data;
					// $(w.document.body).html(html);
					let w = window.open();
					w.document.write('<html><head><body>' + result.data + '</body>');
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const fetchEstimationDoc=()=>{
		let data = {
			ConsumerServiceRequestID: ConsumerServiceRequestID,
		};
		setLoader(true);
        apiCall({
			url:'fetchServiceEstimateDocument',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    window.open(result.data.FilePath);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }
	const fetchProvisionalEstimate =()=>{
        let data = {
            ConsumerServiceRequestID: ConsumerServiceRequestID
        };
        data.isViewProvisionalSE = false;
        if(find(consumerServiceRequest && consumerServiceRequest.logs,{Status: 'Device inspected'}) || find(consumerServiceRequest && consumerServiceRequest.logs,{Status: 'Repair completed'})){
            data.isViewProvisionalSE = true;
        }
		setLoader(true);
        apiCall({
			url:'fetchProvisionalEstimateData',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    window.open(result.data && result.data.fileUrl);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const getProductDetails = () => {
		if(consumerServiceRequest && consumerServiceRequest.ConsumerProductID){
			let data = {
				'sendConsumerDetails': true,
				ConsumerProductID: consumerServiceRequest.ConsumerProductID,
				"skipDiagnosisData": true
			}
			setLoader(true);
			apiCall({
				url:'getProductDetailsForRegisteredProducts',
				data: data,
				callBack:(result)=>{
					setLoader(false);
					if(result.success){
						setConsumerProductDocuments(result.data)
						if (result.data && result.data.diagnosis && result.data.diagnosis.length > 0 && result.data.diagnosis[0] && result.data.diagnosis[0].event && result.data.diagnosis[0].event.data) {
							//viewDiagnosis = true;
							setShowViewRequestFlags({...showViewRequestFlags,['viewDiagnosis']:true})
						}
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		}
	}

	const getBillingDetails = () => {
		let BillingDetailList = [];
		setBillLoader(true);
		apiCall({
			url:'fetchBillingDetails',
			data : {
				ConsumerID: consumerServiceRequest.ConsumerID,
                ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
            },
			callBack:(result)=>{
				setBillLoader(false);
				if(result.success){
					BillingDetailList = result.data;
					setBillingDetailList(result.data);
					let selectedBill = BillingDetailList.find(function(o){return o.IsSelected})
					setSelectedBillingDetail(selectedBill)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const retrieveAllDocs = () => {
		setLoader(true);
		apiCall({
			url:'retrieveDocsByCsrID',
			data : {ConsumerServiceRequestID: consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID},
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let docs = result.data;
					setDocuments(docs)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}


	const getCountries = (shipmentObj) => {
		if(consumerServiceRequest.CountryID){
			let data = {
				CountryID: consumerServiceRequest.CountryID,
				countryConfig: true
			};
			if(shipmentObj && shipmentObj.CountryID) {
				data.CountryID = shipmentObj.CountryID;
			}
			setCountryLoader(true);
			apiCall({
				url:'getCountries',
				data : data,
				callBack:(result)=>{
					setCountryLoader(false);
					if(result.success){
						if (result.data) {
							if(shipmentObj) {
								if(shipmentObj.type == 'pickup') {
									consumerServiceRequest.shipmentPickupCountryCode = result.data.countries[0].CountryCode;
								} else if(shipmentObj.type == 'drop') {
									consumerServiceRequest.shipmentDropCountryCode = result.data.countries[0].CountryCode;
								}
							} else {
								const countryData = result.data.countries[0];
								setCountriesData(countryData);
								let countriesdata = result.data.countries[0]
								if(['VizioDashboard','CprDashboard'].indexOf(consumerServiceRequest.Source) > -1){ // currentcy hack for vizio
									if(countriesdata && countriesdata.currencies[0]){
										setCurrencySymbol(countriesdata.currencies[0].CurrencySymbol);
									}
								}
								 //setCountryData();
							}
						}
						else {
							Swal.fire({
								icon: 'error',
								text: localString('No country data found.'),
								confirmButtonText: localString('OK'),
								cancelButtonColor: localString('Cancel')
							});
						}
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		}
	}

	const setCountryData = ()=> {
		const selectedCountry = countriesData ;
		setSelectedCountry(selectedCountry)

		// checkShipmentCountryCodes(); // ==> covered in useEffect

		if(selectedCountry && selectedCountry.countryConfig) {
			const countryConfig = selectedCountry.countryConfig;
			const AllowedMobileLengths = selectedCountry.AllowedMobileLengths;
			const AllowedPinCodeLengths = selectedCountry.AllowedPinCodeLengths;
            const pincodeConfig = countryConfig && countryConfig.addressConfig && countryConfig.addressConfig.find((obj)=>{
                return obj.keyName == "PinCode" || "Pincode" || "Zipcode";
            })

			//mobile number validations
			if(countryConfig && countryConfig.MobileNumber){
				if(countryConfig.MobileNumber && countryConfig.MobileNumber.LENGTH){
					let lengthArr = countryConfig.MobileNumber.LENGTH && (countryConfig.MobileNumber.LENGTH.length > 1) ? countryConfig.MobileNumber.LENGTH.map(data=>{
						return parseInt(data)
					}): [countryConfig.MobileNumber.LENGTH[0]];
					setCountriesChecks({...countriesChecks,arrAllowedMobileLengths:lengthArr});

					let dscSort = sortBy(lengthArr).reverse()
					setCountriesChecks({...countriesChecks,intAllowedMobileLengths:dscSort});
				}
			}else if(AllowedMobileLengths){
				let mobLength = AllowedMobileLengths.split(',');
				let lengthArr = mobLength.map((item)=> {
					return parseInt(item);
				});
				setCountriesChecks({...countriesChecks,arrAllowedMobileLengths:lengthArr});

				let dscSort = sortBy(lengthArr).reverse()
				setCountriesChecks({...countriesChecks,intAllowedMobileLengths:dscSort});
			}

            //pincode validations
			if(pincodeConfig && pincodeConfig.length){
				let pinLength = pincodeConfig.length && pincodeConfig.length.map(item=>{
					return parseInt(item)
				})
				setCountriesChecks({...countriesChecks,arrAllowedPinCodeLengths:pinLength})

				let dscSort = sortBy(pinLength).reverse()
					setCountriesChecks({...countriesChecks,intAllowedPinCodeLengths:dscSort});
			}else if(AllowedPinCodeLengths){
				let pinLength = AllowedPinCodeLengths.split(',');
				let pinArr = pinLength.map((item)=> {
					return parseInt(item);
				});
				setCountriesChecks({...countriesChecks,arrAllowedPinCodeLengths:pinArr});

				let dscSort = sortBy(pinArr).reverse()
				setCountriesChecks({...countriesChecks,intAllowedPinCodeLengths:dscSort});
			}

            if (pincodeConfig && pincodeConfig.display == false) {
				setCountriesChecks({...countriesChecks,skipZipCodeCheck:true})
            }

            let currencycode = selectedCountry.CurrencyCode ? selectedCountry.CurrencyCode : undefined;
			setCountriesChecks({...countriesChecks,currencyCode:currencycode})
			setCurrencyCode(currencycode);

            //tax detail validations
        //    if (countryConfig && countryConfig.TaxDetailName && countryConfig.TaxDetailValue){
		// 	let taxDetailName = countryConfig.TaxDetailName;
		// 	let taxDetailValue = countryConfig.TaxDetailValue;

		// 	setCountriesChecks({...countriesChecks,TaxDetailName : taxDetailName})

		// 	setCountriesChecks({...countriesChecks,TaxDetailValue : taxDetailValue})

		// 	setCountriesChecks({...countriesChecks,selectedCountryCode : selectedCountry.CountryCode})

        //   getBillingDetails();
        //     }
		}
    };
	const prepareAddressString = (data)=> {
		let countryConfig = countriesData.countryConfig;
		let addressConfig = orderBy(filter(countryConfig.addressConfig, function (o) { return o.addressPreviewOrder; }), ['addressPreviewOrder']);
		let tempAddressList = data ? data.Addresses :consumerDetail.Addresses;
		for (let i = 0; i < tempAddressList.length; i++) {
			let addressDisplayString = '';
			for (let j = 0; j < addressConfig.length; j++) {
				if (tempAddressList[i][addressConfig[j].keyName] != null && tempAddressList[i][addressConfig[j].keyName] != '') {
					if (addressDisplayString != '') {
						addressDisplayString = addressDisplayString + ', ' + tempAddressList[i][addressConfig[j].keyName];
					}
					else {
						addressDisplayString = tempAddressList[i][addressConfig[j].keyName];
					}
				}
				else {
					if (addressConfig[j].keyName == 'UserLandmark') {
						if (addressDisplayString != '') {
							addressDisplayString = addressDisplayString + ', ' + tempAddressList[i]['Landmark'];
						}
						else {
							addressDisplayString = tempAddressList[i]['Landmark'];
						}
					}
				}
			}
			tempAddressList[i].addressDisplayString = addressDisplayString;
		}
		setConsumerDetail({...consumerDetail,Addresses:tempAddressList})
	}

	const updateAddress = (address)=>{
		fetchConsumerDetail(true);
    }
    const pushAddress = (address)=>{
		let unshiftedData = consumerDetail.Addresses.unshift(address)
		setConsumerDetail(unshiftedData)
		prepareAddressString();
    }

	const checkShipmentCountryCodes = () => {
        if(selectedCountry && !showRequestValid.isShipmentCountryCodesFetched && ((([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE, SERVICETYPE.BER_REPLACEMENT].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && ['BER accessories received', 'BER device received', 'BER accessories rejected', 'BER accessories validated', 'BER replacement issued', 'Replacement allocated', 'Payment received'].indexOf(consumerServiceRequest.Status) > -1) || (([SERVICETYPE.DROPOFF,SERVICETYPE.PICK_UP,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && ['Repair completed'].indexOf(consumerServiceRequest.Status) > -1) || (isEmptyBoxServiceable && (!emptyBoxPackagingDetails || !emptyBoxPackagingDetails.length)) || (([SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && ['Repair cancel'].indexOf(consumerServiceRequest.Status) > -1))) {
            setShowRequestValid({...showRequestValid,isShipmentCountryCodesFetched:true})
            if(consumerServiceRequest.servicelocation && consumerServiceRequest.servicelocation.CountryID) {
                if(consumerServiceRequest.servicelocation.CountryID == selectedCountry.CountryID) {
					consumerServiceRequest.shipmentPickupCountryCode=selectedCountry.CountryCode;
                } else {
                    getCountries({CountryID:consumerServiceRequest.servicelocation.CountryID, type: 'pickup'});
                }
            }

            if(consumerServiceRequest.consumer && consumerServiceRequest.consumer.CountryID) {
                if(consumerServiceRequest.consumer.CountryID == selectedCountry.CountryID) {
					consumerServiceRequest.shipmentDropCountryCode=selectedCountry.CountryCode;
				} else {
                    getCountries({CountryID: consumerServiceRequest.consumer.CountryID, type: 'drop'});
                }
            }
        }
    };

    const fetchDeliveryReport = () =>{
		let serviceCompletedByName = getServiceCompletedByName();
        let data = {
            ConsumerServiceRequestID:ConsumerServiceRequestID
        };
        serviceCompletedByName && (data.Name = serviceCompletedByName);
        data.isViewProvisionalSE = false;
        if(find(consumerServiceRequest && consumerServiceRequest.logs,{Status: 'Device inspected'}) || find(consumerServiceRequest.logs,{Status: 'Repair completed'})){
            data.isViewProvisionalSE = true;
        }
		setLoader(true);
        apiCall({
			url:'fetchDeliveryReport',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    window.open(result.data && result.data.FilePath);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }
    const getShipperLabel = () => {
        let data = {
            ConsumerServiceRequestID:ConsumerServiceRequestID
        };
		setLoader(true);
        apiCall({
			url:'fetchDepotShipperLabel',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					window.open(result.data && result.data.FilePath);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }
	const getTradeInDetails = (logisticsNeeded)=>{
        let data = {
			ProductUniqueID: consumerServiceRequest && consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.ProductUniqueID : undefined,
        };
		setLoader(true);
        apiCall({
			url:'getCalculation',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					if (Array.isArray(result.data)) {
						setTradeInDetails(result.data[0])
					}
					else {
						setTradeInDetails(result.data)
					}
					if (logisticsNeeded == true) {
						// tags['update_logistics_request'].fetchLogisticsDetails();
					}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const getTradeInDashboardDetails = ()=>{
        let data = {
			ProductUniqueID: consumerServiceRequest && consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.ProductUniqueID : undefined,
        };
		setLoader(true);
        apiCall({
			url:'getDashboardDetails',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					if (Array.isArray(result.data)) {
						setTradeInDashboardDetails(result.data[0])
					}
					else {
						setTradeInDashboardDetails(result.data)
					}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }
	const getFaultList = ()=>{
        let data = {
			ConsumerServiceRequestID: consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID 
        };
		setLoader(true);
        apiCall({
			url:'getFaultList',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					setAddedFaultCodes(result.data);			
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const getDocumentsMaster = (openModal = false)=>{
		setLoader(true);
        apiCall({
			url:'getDocuments',
			method:"GET",
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let documentsMaster = result.data;
					setReqiredDocumentsMaster(documentsMaster)
                    if (openModal) {
                    	// $('#upload-claim-document').modal('show');
                    }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const claimRequestWorkshopNext =  (status, remark, deviceReturnedTo,finishedId,modePayment) =>{
        setLoader(true);
		let data = {};
        if(!remark){
			remark = '';
        }
        data.ConsumerServiceRequestID = consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID;
        data.Status = status;
        data.ServiceTypeID = consumerServiceRequest && consumerServiceRequest.ServiceTypeID;
        data.Remarks = remark;
		if(finishedId){
			data.FinishedGoodID = finishedId;
		}
		if(modePayment){
			data.ModeOfPayment = modePayment;
			data.PaymentMode = modePayment;
		}
        if(deviceReturnedTo){
			data.DeviceReturnTo = deviceReturnedTo;
        }

		apiCall({
			url:'claimRequestWorkshop',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRequestById();
				}else{
					Swal.fire({
						icon: 'error',
						title: '',
                        text: localString(displayError(result)),
                        icon: 'error',
                        confirmButtonText: localString('OK')
					});
				}
			}
		});
    };

	const getCommunicationLogs = () =>{
        setLoader(true)
		apiCall({
			url:'getCommunicationLogs',
			data: {
					'ConsumerServiceRequestID': consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID
				},
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let localCallLog = result.data;
					setCallLogs(result.data);
					setCallLogCount(localCallLog.length);
				}else{
					Swal.fire({
						icon: 'error',
						title: '',
                        text: localString(displayError(result)),
                        type: 'error',
                        confirmButtonText: localString('OK')
					});
				}
			}
		});
    };
	const getPendingDocuments = ()=>{
		let data = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            ServiceTypeID: consumerServiceRequest.ServiceTypeID
        };
		setLoader(true);
        apiCall({
			url:'getDocumentDetails',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				// tabLoader = true;
				if(result.success){
					let pendingDoc = result.data;
					setPendingDocuments(pendingDoc)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
				// tabLoader = false;
			}
		});
    }

	const openUploadLogisticsModal = ()=>{
		if (consumerServiceRequest.ServiceTypeID == 24) {
			getTradeInDashboardDetails();
			if (!tradeInDetails) {
				getTradeInDetails(true)
			}
			else {
				// tags['update_logistics_request'].fetchLogisticsDetails();
				// $('#updatelogisticsrequest').modal('show');
			}
		}
		else {
			// tags['update_logistics_request'].fetchLogisticsDetails();
			// $('#updatelogisticsrequest').modal('show');
		}
	}

	const getCurrencyMapping = () => {
		setLoader(true);
		let reqObj = {
			PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID
		};
		if (consumerServiceRequest.consumerProduct) reqObj.BrandID = consumerServiceRequest?.consumerProduct?.BrandID;
		apiCall({
			url: 'organizationOutletLocationCurrencyMapping',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					let data = result.data
					setCurrencyData(data);
					if (data && data.currencyCountryMapping) {
						if (data.currencyCountryMapping.currency) {
							if (data.currencyCountryMapping.currency.CurrencySymbol) {
								setCurrencySymbol(data.currencyCountryMapping.currency.CurrencySymbol);
							} else {
								setCurrencySymbol('&#8377;');
							}
						}
						if (data.currencyCountryMapping.country) {
							setCountriesChecks({ ...countriesChecks,
								['selectedCountryCode'] : data.currencyCountryMapping.country.CountryCode,
							})
						}
					}
				} 
				// else {
				// 	Swal.fire({
				// 		icon: 'error',
				// 		text: localString(displayError(result)),
				// 	});
				// }
			}
		});
    }

	const getTaskValues = ()=>{
		let data = {
            consumerServiceRequestId: ConsumerServiceRequestID,
        };
		setLoader(true);
        apiCall({
			url:'retrieveAllTask',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let taskList = result.data;
					setTaskListArr(taskList)
					// self.parent.taskListArr = self.taskListArr;
					// self.openTaskCount = 0
					let openTask = openTaskCount;
					taskList && taskList.forEach(value=>{
						if(value.status === 'Open'){
							openTask++
						}
					})
					setOpenTaskCount(openTask)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const fetchLogisticsDetails = () => {
		setLogisticLoader(true);
        apiCall({
			url:'getAllLogisticsRequestDetails',
			data: {
				ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
			},
			callBack:(result)=>{
				setLogisticLoader(false);
				if(result.success && result.data){
					let logisticsRequestDetails = result.data;
					setLogisticsRequestDetails(logisticsRequestDetails);
					const detailsLength = logisticsRequestDetails.length;
					for (let iCount = 0; iCount < detailsLength; iCount++){
						if(logisticsRequestDetails[iCount].hasOwnProperty('logisticsImage')){
							let image = logisticsRequestDetails[iCount].logisticsImage;
							setLogisticsRequestDetailsImage(image)
							if (image.length > 0) {
								break;
							}
						} else {
						// pass
						}
					}
				}else{
					// Swal.fire({
					// 	icon: 'error',
					// 	text: localString(displayError(result)),
					// });
				}
			}
		});
	}

	const getRequestDetails = () => {
        let data = {
            ConsumerServiceRequestID: consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
            sendProductDetails: true
        };
		setLoader(true);
        apiCall({
			url:'getRequestDetails',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    let productDetail = result.data;
					setNewProductDetail(productDetail)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const loadServiceLocation = (ServiceTypeID, BrandID, ProductID) => {
		setLocationsCalledAlready(true)
		if (ServiceTypeID && BrandID && ProductID && consumerServiceRequest && !consumerServiceRequest.isProductToBeVerified) {
            let data = {
                ServiceTypeID: ServiceTypeID,
                BrandID: BrandID,
                ProductID: ProductID
            };
			setLoader(true);
			apiCall({
				url:'getServiceLocations',
				data: data,
				callBack:(result)=>{
					setLoader(false);
					if(result.success){
						let LocationData = result.data;
						setServiceLocationData(LocationData);
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
        }
    }

	const getEmptyBoxRequestDetails = () =>{
		setEmptyBoxLoader(true);
		let data = {
			ConsumerServiceRequestID:ConsumerServiceRequestID,
			"sendPackagingDetails": true
		};
		apiCall({
			url:'getEmptyBoxRequestDetails',
			data: data,
			callBack:(result)=>{
				setEmptyBoxLoader(false);
				if(result.success){
					if (result.data) {
						setIsEmptyBoxServiceable(result.data.isEmptyBoxMapped);
						let isEmptyBoxService = result.data.isEmptyBoxMapped;
						if (isEmptyBoxService) {
							setEmptyBoxPackagingDetails(result.data.EmptyBoxPackagingDetails)
							let emptyBoxPackagingDetail = result.data.EmptyBoxPackagingDetails
							if (emptyBoxPackagingDetail && ['DropOff request accepted'].indexOf(consumerServiceRequest.Status) > -1) {
								if (emptyBoxPackagingDetail.length > 0) {
									setShowShipPackagingMaterialButton(true)
								}
								else {
									setShowShipPackagingMaterialButton(false)
									checkShipmentCountryCodes();
								}
							}
							else {
								setShowShipPackagingMaterialButton(false)
							}
						}
						else {
							setShowShipPackagingMaterialButton(false)
						}
					}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	};

	const berRequestNext =  (status, berData) => {
        setLoader(true);
		let data = berData ? berData : {};
        data.ConsumerServiceRequestID = consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID;
        data.Status = status;
		data.CountryID = consumerServiceRequest.CountryID;
		apiCall({
			url:'BERClaimRequest',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    getRequestById();
				}else{
					Swal.fire({
						title: '',
                        text: localString(displayError(result)),
                        icon: 'error',
                        confirmButtonText: localString('OK')
					});
				}
			}
		});
    };

	const getDisplayableDeviceOptions = (BrandID, elementID) =>{
        if(!savedDeviceOptionsFetched) {
            setSavedDeviceOptionsFetched(true);
            let data = {
                "RequestType": "CONSUMER_SERVICEREQUEST",
                "RequestID": consumerServiceRequest.ConsumerServiceRequestID
            }
            apiCall({
                url: "getDisplayableDeviceOptions",
                data: data,
                callBack: (result) => {
                    setLoader(false);
                    if (result.success) {
                        setSavedOptions(result.data)
						let saveOption = result.data
                        processSavedOptionKeys(saveOption);
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: localString(displayError(result)),
                        });
                    }
                },
            });
        }
    };

	const checkBERDeviceRequired = () =>{
		setLoader(true);
		let localBerFlag = {...berFlags};
		if(!berFlags.checkBERDeviceRequiredCalled){
			localBerFlag.checkBERDeviceRequiredCalled = true;
			// setBerFlags({...berFlags,checkBERDeviceRequiredCalled:true});
            let data ={
                "ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
                "SoldPlanID": consumerServiceRequest && consumerServiceRequest.SoldPlanID,
                "ProductSubCategoryID": consumerServiceRequest && consumerServiceRequest.consumerProduct.ProductSubCategoryID,
                "FulfillerID": consumerServiceRequest && consumerServiceRequest.FulfillerID,
                "ServiceTypeID": consumerServiceRequest && consumerServiceRequest.PreviousServiceTypeID
            }
            apiCall({
                url: "checkBERDeviceRequired",
                data: data,
                callBack: (result) => {
                    setLoader(false);
                    if (result.success && result.data) {
						localBerFlag.defectiveRequired = result.data.DefectiveRequired;
						setBerFlags(localBerFlag);
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: localString(displayError(result)),
                        });
                    }
                },
            });
        }
    }

	const fetchBERConfiguration = () =>{
		setLoader(true);
		let localBerFlag = {...berFlags}
        if(!berFlags.fetchBERConfigurationCalled){
			localBerFlag.fetchBERConfigurationCalled = true
			// setBerFlags({...berFlags,fetchBERConfigurationCalled:true});
            let data ={
                "ServiceTypeID":consumerServiceRequest && consumerServiceRequest.ServiceTypeID,
                "SoldPlanID":consumerServiceRequest && consumerServiceRequest.SoldPlanID,
                "ProductSubCategoryID":consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID,
                "FulfillerID":consumerServiceRequest && consumerServiceRequest.FulfillerID,
				"CountryID":consumerServiceRequest && consumerServiceRequest.CountryID
            }
			apiCall({
                url: "fetchBERConfiguration",
                data: data,
                callBack: (result) => {
                    setLoader(false);
                    if (result.success && result.data) {
						localBerFlag.allowRefundBtn = result.data && result.data.Refund;
						localBerFlag.allowRepairBtn = result.data && result.data.Repair;
						localBerFlag.allowReplacementBtn = result.data && result.data.Replacement;
                        setBerFlags(localBerFlag);
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: localString(displayError(result)),
                        });
                    }
                },
            });
        }
    }
    const processSavedOptionKeys = (saveOption) => {
        let savedOptionKey = []
		saveOption.forEach((obj) => {
            obj.OptionValues && Object.keys(obj.OptionValues).forEach((key) => {
				if(!savedOptionKey.includes(key) && (['BrandID', 'ProductID'].indexOf(key) == -1)){
					savedOptionKey.push(key);
				}
            });
        })
        savedOptionKey = savedOptionKey.sort((a, b) => {
            return a.localeCompare(b);
        });
		setSavedOptionKeys(savedOptionKey)
    };

	const mastHead = {
		mastHeadHeader:[
			{
				showService :true,
				serviceImageUrl :consumerServiceRequest && consumerServiceRequest.servicetype && consumerServiceRequest.servicetype.ImageUrl,
				serviceTypeText : consumerServiceRequest && !consumerServiceRequest.showEngineerVisits || (consumerServiceRequest.showEngineerVisits && !consumerServiceRequest.subservicetype) ? consumerServiceRequest.servicetype && localString(consumerServiceRequest.servicetype && consumerServiceRequest.servicetype.Type):"",
				subServiceTypeText : consumerServiceRequest && consumerServiceRequest.showEngineerVisits && consumerServiceRequest.subservicetype && consumerServiceRequest.subservicetype &&consumerServiceRequest.subservicetype.SubServiceType,
				referenceID : consumerServiceRequest && !consumerServiceRequest.showEngineerVisits && "#"+consumerServiceRequest.ReferenceID || consumerServiceRequest && consumerServiceRequest.showEngineerVisits && consumerServiceRequest.ExternalReferenceID && "#"+consumerServiceRequest.ExternalReferenceID
				|| consumerServiceRequest && consumerServiceRequest.showEngineerVisits && consumerServiceRequest.ExternalReferenceID && "#"+consumerServiceRequest.ExternalReferenceID
				|| consumerServiceRequest && ([SERVICETYPE.INTERNAL_JOB].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && "External RequestID #"+consumerServiceRequest.ExternalJobReferenceID ,
				flagImageUrl: publicUrl+`/imgs/Flag-selected.png`,
				escalationTagText : escalationTag && escalationTag,
				escalationTagColor : escalationTagColor || ''
			},{
				showProduct: true,
				productImageUrl : consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubcategory ? consumerServiceRequest.consumerProduct.ProductSubcategory.ImageUrl:"",
				productName : !accessFn('WORKSHOP_FLOW') ? consumerServiceRequest && consumerServiceRequest.consumerProduct && (consumerServiceRequest.consumerProduct.product ? consumerServiceRequest.consumerProduct.product.ProductName : '') + " " +(consumerServiceRequest && consumerServiceRequest.consumerProduct  && consumerServiceRequest.consumerProduct.brand ? consumerServiceRequest.consumerProduct.brand.BrandName : '')
				: consumerServiceRequest && (consumerServiceRequest.PartDetails && consumerServiceRequest.PartDetails.Name)?consumerServiceRequest.PartDetails.Name: (consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.product && consumerServiceRequest.consumerProduct.product.ProductName?consumerServiceRequest.consumerProduct.product.ProductName + ' ' + (consumerServiceRequest.consumerProduct.ModelNo?consumerServiceRequest.consumerProduct.ModelNo:'') + ' ' + (consumerServiceRequest.consumerProduct.StorageCapacity?consumerServiceRequest.consumerProduct.StorageCapacity:''):'NA')
			},{
				showServiceCenter:true,
				serviceCenterText : !accessFn('HIDE_SERVICE_CENTER_NAME')  && localString("Service Center : ") ,
				serviceCenter: consumerServiceRequest?.ClientServiceLocationName || consumerServiceRequest?.servicelocation?.ServiceLocationName || '-',
				// serviceCenter: consumerServiceRequest && consumerServiceRequest.servicelocation ? consumerServiceRequest.servicelocation.ServiceLocationName || '-' : '-',
				source : consumerServiceRequest && consumerServiceRequest.Source != "SkyworthDashboard" && consumerServiceRequest.PartnerName ? ' ('+consumerServiceRequest.PartnerName+')' : '',
				retailerText : !accessFn('HIDE_SERVICE_CENTER_NAME') && consumerServiceRequest && consumerServiceRequest.retailPartnerDetails && consumerServiceRequest.retailPartnerDetails.nameOfFirm != '' && localString("Retailer :"),
				retailerCenter:(consumerServiceRequest && consumerServiceRequest.retailPartnerDetails && consumerServiceRequest.retailPartnerDetails.nameOfFirm != '' ? consumerServiceRequest.retailPartnerDetails.nameOfFirm + ', ' : '') + (consumerServiceRequest && consumerServiceRequest.retailPartnerDetails && consumerServiceRequest.retailPartnerDetails.city ? consumerServiceRequest.retailPartnerDetails.city + ', ' : '') + (consumerServiceRequest && consumerServiceRequest.retailPartnerDetails && consumerServiceRequest.retailPartnerDetails.state ? consumerServiceRequest.retailPartnerDetails.state : '') ,
			},
			{
				showRole:true,
				roleText : localString("Role")
			},{
				showAccessoriesAndPendingDoc : showRequestValid.showAccessoriesMsg  || showRequestValid.showPendingDocMsg ,
				accessoriesMsgText :showRequestValid.showAccessoriesMsg && localString("Accessories will not be returned"),
				pendingDocMsgText : showRequestValid.showPendingDocMsg && localString("Documents are yet to be uploaded.")
			},{
				showPrinter :  !([9,SERVICETYPE.CLAIM,SERVICETYPE.RECYCLE_DROP_OFF,SERVICETYPE.CLAIM_SELF_REPAIR,SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID)> -1),
				printerImageUrl:publicUrl+`/imgs/sprite-5.png`
			}
		],
		mastHeadDetails:[
			{
				showRequestAndSlot:([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) == -1) && ((['VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1) && ['Pending for assignment'].indexOf(consumerServiceRequest.Status) == -1 || ['VizioDashboard'].indexOf(consumerServiceRequest.Source) == -1 ) ,
				requestText:[SERVICETYPE.CLAIM,SERVICETYPE.ENQUIRY,SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) == -1 ? localString("Request Scheduled for") +" "+ ((returnLocalDateTime(consumerServiceRequest.ScheduledDateTime,'DD-MM-YYYY',defaultCountryLocale) || returnLocalDateTime(consumerServiceRequest.CreatedDate,'DD-MM-YYYY',defaultCountryLocale))):"",
				slotText:[SERVICETYPE.CLAIM,SERVICETYPE.ENQUIRY,SERVICETYPE.CLAIM_SELF_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) == -1 ? localString("Slot -") + (consumerServiceRequest.ScheduledFromTime ? returnLocalSlot(consumerServiceRequest.ScheduledFromTime) : '') + (consumerServiceRequest.ScheduledToTime ? "- " + returnLocalSlot(consumerServiceRequest.ScheduledToTime) : '') :"",
				customCls:tatColor +`  tat tatStatus pull-left clear-both  request-details-tabs `
			},{
				showActionStatus:true,
				actionText:localString("Action Status :"),
				statusText:localString(showStatus(consumerServiceRequest && consumerServiceRequest.Status)),
				actionTakenText:consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.Status && !(['Service invoice generated','Estimation generated'].indexOf(consumerServiceRequest.Status)> -1) && localString("Action taken :"),
				actionStatusDate:consumerServiceRequest.logs && consumerServiceRequest.Status && !(['Service invoice generated','Estimation generated'].indexOf(consumerServiceRequest.Status)> -1) && returnLocalDateTime(showStatusLog(consumerServiceRequest.Status,consumerServiceRequest.logs) && showStatusLog(consumerServiceRequest.Status,consumerServiceRequest.logs)['StatusDate'],'DD-MM-YYYY  HH:mm', defaultCountryLocale),
				customCls:tatColor +` tat tatStatus pull-left clear-both  request-details-tabs `
			},
			{
				showLogsAndRemark : ([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,56,61,62].indexOf( consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1),
				currentLogText : localString("Current Log :"),
				exceptionLogsText : exceptionLogs ? localString(showStatus(exceptionLogs && exceptionLogs[0] && exceptionLogs[0].Status)) : consumerServiceRequest && showStatus(consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs[0] && consumerServiceRequest.logs[0].Status) ? localString(showStatus(consumerServiceRequest && consumerServiceRequest.logs && consumerServiceRequest.logs[0].Status)) : '',
				remarkText : ['Parts request accepted','Parts issused','Parts partially issused'].indexOf(consumerServiceRequest.Status) > -1 ? localString("Remarks :") :" " || ['Parts request accepted','Parts issused','Parts partially issused'].indexOf(consumerServiceRequest.Status) > -1  && consumerServiceRequest && consumerServiceRequest.logs[0].Remarks+":" || '-',
				ratingText : consumerServiceRequest && consumerServiceRequest.consumerServiceRequestFeedback && consumerServiceRequest.consumerServiceRequestFeedback.length > 0 && consumerServiceRequest.consumerServiceRequestFeedback[0].Rating ? localString("Rating :") :"",
				ratings : consumerServiceRequest && consumerServiceRequest.consumerServiceRequestFeedback && consumerServiceRequest.consumerServiceRequestFeedback[0] && consumerServiceRequest.consumerServiceRequestFeedback[0].Rating,
				customCls :tatColor +`  tat tatStatus pull-left clear-both  request-details-tabs `,
			},{
				showAssignedEngineer: onsiteChecks,
				assignedEngineerText:consumerServiceRequest && consumerServiceRequest.servicelocation && consumerServiceRequest.servicelocation.EngineerApplicable ? localString("Assigned Engineer")+' :' : localString("Assigned Service Provider")+' :',
				consumerName : ['Pending for approval'].indexOf(consumerServiceRequest.Status) > -1  ? consumerServiceRequest.Name :"",
				engineerName : (['Pending for approval'].indexOf(consumerServiceRequest.Status) == -1) || (['VizioDashboard'].indexOf(consumerServiceRequest.Source) > -1 && ['Pending for approval'].indexOf(consumerServiceRequest.Status) > -1) ? consumerServiceRequest && consumerServiceRequest.engineer && consumerServiceRequest.engineer.Name :"",
				customCls:tatColor +` tat tatStatus pull-left clear-both  request-details-tabs `
			}
		]
	}

	const getPartsList = (partWithRepairType) => {
		setPartLoader(true);
		let data = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "ConsumerProductID": consumerServiceRequest.ConsumerProductID,
            // "OrganizationOutletLocationID": organizationOutletLocationID,  // TODO: Need to check this variable
            "PartnerServiceLocationID": consumerServiceRequest.PartnerServiceLocationID
		};
		let url = "getParts";
        if(accessFn('GSX_FLOW')){
            url = "getPartsForGSX";
        }
		apiCall({
			url: url,
			data: data,
			callBack:(result)=>{
				setPartLoader(false);
				if(result.success){
					let data = result.data.map(v => ({...v, value:v.Description}))
					setPartsList(data);
					if(partWithRepairType) {
						setActiveTab({showPartEstimation:true})
						if(accessFn('GSX_FLOW')) {
							getFaultList();
						}
						setShowPartModal(true);
					}
					// TODO
					// if(self.tags['provisional_estimation']){
					// 	self.tags['provisional_estimation'].partsListCalled = true;
					// }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const getConsentDocument = ()=>{
		let data = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
        };
		setLoader(true);
        apiCall({
			url: 'getConsentDocument',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					if(result.data && result.data[0] && result.data[0].FileUrl){
						window.open(result.data[0].FileUrl);
				   	}else {
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const getRefurbishmentRequestDetails = ()=>{
		let data = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
        };
		if(SERVICETYPE.DEFECTIVE_REFURBISHMENT == consumerServiceRequest.ServiceTypeID){
			data.ServiceType = consumerServiceRequest.ServiceTypeID 
		}
		setLoader(true);
        apiCall({
			url: 'getRefurbishment',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success && result.data){
					let refurbishmentData = result?.data?.refurbishOrder;
					setRefurbishmentRequestData(refurbishmentData)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const showInvoiceButtonPD=['Repair completed','Device dispatched','Service completed','Service completed and rated'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) > -1 && ([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)  && (consumerServiceRequest && !consumerServiceRequest.isFree) ? true :false
	const showInvoiceButtonCarryIn = ['Repair completed','Device dispatched','Service completed','Service completed and rated'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) > -1 && ([SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && (consumerServiceRequest && consumerServiceRequest.InvoiceGeneratedDate ) ? true : false
	const showInvoiceButtonCPD =  (['Service initiated','Repair initiated'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) > -1 ) && ([SERVICETYPE.CARRY_IN,SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && (consumerServiceRequest && consumerServiceRequest.InvoiceGeneratedDate && !consumerServiceRequest.isFree) ? true : false
	const showInvoiceButtonPUD =  (consumerServiceRequest && consumerServiceRequest.Status=='Payment received') && ([SERVICETYPE.PICK_UP,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && true

	const actionLink=[
        {
            showPrint :!accessFn('GSX_FLOW') && (([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)) && consumerServiceRequest.consumerProduct
                    && [28,114].indexOf(consumerServiceRequest && consumerServiceRequest.consumerProduct.BrandID) < 0 && (!defaultCountry || ['US'].indexOf(defaultCountry) == -1),
            text : localString("View Jobsheet"),
            showLink : true,
            href : baseUrl+'/servicerequests/jobsheet?csrid='+encrypt(ConsumerServiceRequestID)
        },
        {
			showPrint: !accessFn('GSX_FLOW') && ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && defaultCountry && (['US'].indexOf(defaultCountry) > -1),
			text : localString("View Jobsheet"),
            showLink : true,
            onClick:()=>{
                fetchJobsheet();
            }
        },
        {
            showPrint : !accessFn('GSX_FLOW') && ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && consumerServiceRequest.consumerProduct && [28].indexOf(consumerServiceRequest.consumerProduct.BrandID) > -1 && defaultCountry && ['IN', 'US'].indexOf(defaultCountry) == -1,
            text : localString("View Jobsheet"),
			showLink : true,
            href : baseUrl+'/servicerequests/jobsheetnewest?csrid='+encrypt(ConsumerServiceRequestID)
        },
        {
            showPrint : !accessFn('GSX_FLOW') && ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && consumerServiceRequest.consumerProduct && [28,114].indexOf(consumerServiceRequest.consumerProduct.BrandID) > -1 && ([114].indexOf(consumerServiceRequest.consumerProduct.BrandID) > -1 || ([28].indexOf(consumerServiceRequest.consumerProduct.BrandID) > -1 && (!defaultCountry || defaultCountry == 'IN'))),
            text : localString("View Jobsheet"),
            showLink : true,
            href : baseUrl+'/servicerequests/jobsheetnew?csrid='+encrypt(ConsumerServiceRequestID)
        },
        {
            showPrint :accessFn('GSX_FLOW') && ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1),
            text : localString("View Jobsheet"),
			showLink : true,
            onClick:()=>{
                fetchJobsheet();
            }
        },
        {
            showPrint : showRequestLogs.isProvisionalChargesAdded,
            text :  localString("View Provisional Estimate Document"),
            showLink : true,
            onClick:()=>{
                fetchProvisionalEstimate();
            }
        },
        {
			showPrint:((([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) &&  (showInvoiceButtonPD || showInvoiceButtonPUD || showInvoiceButtonCPD || showInvoiceButtonCarryIn )) || (([SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && defaultCountry != 'US'
						&& showRequestLogs.InvoiceGeneratedDate && ['Repair cancel', 'Device Dispatched', 'Service cancel'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) == -1)) && !(accessFn('WORKSHOP_FLOW')),
			text :  localString("View Invoice"),
            showLink : true,
            onClick: ()=>{
                fetchInvoice();
            }
        },
        {
			showPrint : (([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1 && showRequestLogs.InvoiceGeneratedDate) || (([SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && showRequestLogs.InvoiceGeneratedDate && ['Repair cancel', 'Device Dispatched', 'Service cancel'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) == -1))
						&& accessFn('WORKSHOP_FLOW') && !(['applicable','replacement','replaced', 'certificate'].indexOf(consumerServiceRequest && consumerServiceRequest.DOAStatus) > -1),
            text :  localString("View Invoice"),
            showLink : true,
            onClick:()=>{
                fetchInvoice();
            }
        },
        {
            showPrint :(([SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && (!defaultCountry || defaultCountry == 'IN') && showRequestLogs.InvoiceGeneratedDate && ['Repair cancel', 'Device Dispatched', 'Service cancel'].indexOf(consumerServiceRequest && consumerServiceRequest.Status) == -1) && (!accessFn('WORKSHOP_FLOW') || (accessFn('WORKSHOP_FLOW') && !(['applicable','replacement','replaced', 'certificate'].indexOf(consumerServiceRequest && consumerServiceRequest.DOAStatus) > -1))),
            text :  localString("View Insurer Invoice"),
			showLink : true,
            href : baseUrl+invoice+ConsumerServiceRequestID
        },
        {
            showPrint : ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF,SERVICETYPE.CLAIM_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && showRequestLogs.showPartsDocument,
            text :  localString("View Parts Document"),
			showLink : true,
			onClick:()=>{
				let  href = baseUrl+'/servicerequests/partdoc?csrid='+encrypt(ConsumerServiceRequestID);
				window.open( href)
                // fetchDeliveryReport();
            }

        },
        {
            showPrint : accessFn('SHOW_ESTIMATION_DOCUMENT') && showRequestLogs.showEstimationDoc && !accessFn('GSX_FLOW'),
            text :  localString("View Estimation Document"),
			showLink : true,
            href:baseUrl+{estimationdoc}+encrypt(ConsumerServiceRequestID),
        },
        {
            showPrint : accessFn('SHOW_ESTIMATION_DOCUMENT') && showRequestLogs.showEstimationDoc && accessFn('GSX_FLOW'),
            text :  localString("View Estimation Document"),
            showLink : true,
			onClick:()=>{
				fetchEstimationDoc();
            }
        },
        {
            showPrint :  ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1) && consumerServiceRequest && consumerServiceRequest.DOAStatus == 'certificate' && accessFn('ACCEPT_RETURN_DEVICE') && accessFn('WORKSHOP_FLOW'),
            text :  localString("View DOA Certificate"),
            showLink : true,
            href : baseUrl+'/servicerequests/doacerti?csrid='+encrypt(ConsumerServiceRequestID)
        },

        {
            showAdvanceReceipt : consumerServiceRequest && consumerServiceRequest.AdvanceCollected,
            advanceReceipts :  AdvanceReceipts,
            text :  localString("View Advance Receipt"),
            showLink : true,
        },
        {
			showPrint:([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1) && (showRequestLogs.isRepairCompleted || showRequestLogs.isServiceCancel) && showRequestLogs.isDeviceReceived && accessFn('GSX_FLOW'),
            text :  localString("View Delivery Report"),
            showLink : true,
            onClick:()=>{
                fetchDeliveryReport();
            }
        },
        {
            showPrint : consumerServiceRequest && consumerServiceRequest.gsxRepairRequest && consumerServiceRequest.gsxRepairRequest.confirmationNumber && consumerServiceRequest.isMailinRequest,
            text :  localString(" View Depot Shipper Label"),
            showLink : true,
            onClick :()=>{
                getShipperLabel();
            }
        },
        {

            showPrint : accessFn('LOANER_FLOW') && consumerServiceRequest && consumerServiceRequest.loanerDetails && consumerServiceRequest.loanerDetails.chargetoCustomer && consumerServiceRequest.loanerDetails.LoanerInvoiceUrl,
            text :  localString("View Loaner Invoice"),
			showLink : true,
            href: consumerServiceRequest && consumerServiceRequest.loanerDetails && consumerServiceRequest.loanerDetails.LoanerInvoiceUrl
        },
        {
            showPrint : (["SkyworthDashboard","BoseDashboard"].indexOf(consumerServiceRequest && consumerServiceRequest.Source)> -1 || (qFilter && qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1)) && showRequestLogs.InvoiceGeneratedDate,
			text :  localString("View Invoice"),
			showLink : true,
            onClick:()=>{
                fetchInvoice();
            }
        },
        {
			showPrint:(["SkyworthDashboard","BoseDashboard","CprDashboard","VizioDashboard"].indexOf(consumerServiceRequest.Source)== -1 && !(qFilter.flowType && qFilter.flowType.fulfilmentFlow && qFilter.flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') > -1)) && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && showRequestValid.showOnsiteInvoice  && (['Service completed', 'Service completed and rated'].indexOf(consumerServiceRequest.Status) > -1 || showButton) ,
			text :  localString("View Invoice"),
            showLink : true,
            href :baseUrl+invoice+ConsumerServiceRequestID
        },
        {
			showPrint:((accessFn('TECHNICIAN_SUPERVISOR') || ["SkyworthDashboard","BoseDashboard","SmartTechnoDashboard"].indexOf(consumerServiceRequest && consumerServiceRequest.Source)> -1 ) && ([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,SERVICETYPE.DEMO,SERVICETYPE.SERVICING,SERVICETYPE.REFUND_CROMA,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1)) ,
			text : localString("View Job Card"),
			showLink : true,
			href : baseUrl+jobcard+encrypt(ConsumerServiceRequestID)
        },
		{
			showPrint:consumerServiceRequest,
			text : localString('User Consent Document'),
			showLink : true,
			onClick:()=>{
                getConsentDocument();
            }
			// href : baseUrl+jobcard+encrypt(ConsumerServiceRequestID)
        },
    ]

	const getCaseScores = () => {
		let data = {
			caseID: consumerServiceRequest.caseID,
		};

		setLoader(true);
		apiCall({
			url: "getCaseScores",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					setScoreDetails(result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const updateConsumerServiceRequestStatus = (status, callBack) => {
		let updateStatusTo = ''

		if(status === 'Proof revision required'){
			updateStatusTo = 'Service center document pending'	
		}else if(status === 'Proof submit'){
			updateStatusTo = 'Service center document verification pending'
		}else if(status === 'Claim estimation revision required'){
			updateStatusTo = 'Claim estimation revision required'
		}

		let data = {
			userID: userID,
			consumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			status: updateStatusTo
		}

		console.log("updateConsumerServiceRequestStatus called", status, data)

		Swal.fire({
			title: localString("Remarks"),
			input: "text",
			inputAttributes: {
				autocapitalize: "off",
			},
			showCancelButton: true,
			confirmButtonText: localString("Submit"),
			inputValidator: (value) => {
				if (!value) {
					return localString("Remarks can not be empty!");
				}
			},
			showLoaderOnConfirm: true,
		}).then((result) => {
			if (result.isConfirmed) {
				if (result.value) data.remarks = result.value;

				setLoader(true);
				apiCall({
					url: "updateConsumerServiceRequestStatus",
					data: data,
					callBack: (result) => {
						setLoader(false);
						if (result.success) {
							if (result.data) {
								getRequestById()
								callBack && callBack()
							}
						} else {
							Swal.fire({
								icon: "error",
								text: localString(displayError(result)),
							});
						}
					},
				});
			}
		});
		
	}

	return(
		<>
		{ 	
			(showLoader || csrLoader || claimLoader || consumerDetailLoader || escLoader || escDataLoader || tatLoader ||
				actualCostLoader || countryLoader || configLoader || insuranceLoader || spLoader || decLoader || checkListLoader ||
				logisticLoader || emptyBoxLoader || extChargesLoader || billLoader || subLoader || paymentLinkLoader ||
				fALoader || sfaLoader || partLoader || dSuitesLoader || partsLoader
			) && ReactDOM.createPortal(
			<Loader />,
			document.getElementById("loader-root")
		)}
		<div className={`content ${enableHighRisk ? 'high-risk-border': '' || "action-bar tags-tabs"}`} >
			<ViewMastHeadDetails
				{...props}
				consumerServiceRequest={consumerServiceRequest}
				serviceType={serviceType}
				mastHead ={mastHead }
				isSingleRole={isSingleRole}
				role={role}
				showAccessoriesMsg={showRequestValid.showAccessoriesMsg}
				showPendingDocMsg={showRequestValid.showPendingDocMsg}
                editableEscalationData={ editableEscalationData}
                actionLink={actionLink}
			>
				<ActionLink {...props} actionLink={actionLink}/>
			</ViewMastHeadDetails>
            <ViewButtons
                {...props}
				countriesChecks={countriesChecks}
				countryCode={countryCode}
				countriesData={countriesData}
				soldPlanCoverage={soldPlanCoverage}
				claimRequestWorkshopNext={claimRequestWorkshopNext}
				viewDiagnosis={showViewRequestFlags.viewDiagnosis}
				claimRequestNext={claimRequestNext}
				isEmptyBoxServiceable={isEmptyBoxServiceable}
				emptyBoxPackagingDetails={emptyBoxPackagingDetails}
				showShipPackagingMaterialButton={showShipPackagingMaterialButton}
				getDeviceReceivedHelperText={getDeviceReceivedHelperText}
				// getDecisiomMatrixHelpText={getDecisiomMatrixHelpText}
				currencySymbol={currencySymbol}
				showRefreshButton={showRefreshButton}
				setShowRefreshButton={setShowRefreshButton}
				coveredUnderPlanCheck={coveredUnderPlanCheck}
				checkDeviceValueAndDateAdded={checkDeviceValueAndDateAdded}
				checkIfSalesChannelIsequiredAndPresent={checkIfSalesChannelIsequiredAndPresent}
				partsRequested={partsRequested}
				isServiceLocationAssigned ={isServiceLocationAssigned }
				ConsumerServiceRequestID={ConsumerServiceRequestID}
				getRequestById={getRequestById}
                cancelAnytime={showViewRequestFlags.cancelAnytime}
                showShippedButtonForStatus={showViewRequestFlags.showShippedButtonForStatus}
                warrantyAppicableNonClaimCheck={warrantyAppicableNonClaimCheck}
                isCancelApplicable={ showViewRequestFlags.isCancelApplicable}
                isServiceCancel={showRequestLogs.isServiceCancel}
                consumerServiceRequest={consumerServiceRequest}
                isRescheduleApplicable={isRescheduleApplicable}
				setShowRequestValid={setShowRequestValid}
                checkPaymentStatus={checkPaymentStatus}
				paymentAmount = {paymentAmount }
                checkDecisionMatrixProcessCompleted={checkDecisionMatrixProcessCompleted}
                hideNextStepInTradeIn={showRequestValid.hideNextStepInTradeIn}
				showUpdateLogisticsRequestButton={showUpdateLogisticsRequestButton}
				showInitiateException={showRequestLogs.showInitiateException}
				isDOAApplicableLog={showRequestLogs.isDOAApplicableLog}
				showCompleteRefund={showRequestLogs.showCompleteRefund}
				planID={planID}
				showLoader={showLoader}
				showViewRequestFlags={showViewRequestFlags}
				setShowViewRequestFlags={setShowViewRequestFlags}
				showButton={showButton}
				showActionButton={showActionButton}
				// setShowActionButton={setShowActionButton}
				consumerDetails={consumerDetail}
				taskListArr={taskListArr}
				isSymptomMapAdded={isSymptomMapAdded}
				showCancelButtonFuncton={showCancelButtonFuncton}
				getSource={getSource}
				accessPoints={accessPoints}
				showCompleteServiceFunction={showCompleteServiceFunction}
				adjudicationChecklistData={adjudicationChecklistData}
				selectedBillingDetail={selectedBillingDetail}
				isRequestSFANeeded ={showRequestValid.isRequestSFANeeded}
				requestSFA={requestSFA}
				defaultCountry={defaultCountry}
				claimDamageId={claimDamageId}
				showButtonRefresh={showButtonRefresh}
				getCommunicationLogs={getCommunicationLogs}
				currencyCode={currencyCode}
				AdvanceChargeApplicable={AdvanceChargeApplicable}
				isSubscriptionEnabled={isSubscriptionEnabled}
				insurancePlans={insurancePlans}
				selectPlanBenefit={selectPlanBenefit}
				hideIssueLoanerButton={showRequestValid.hideIssueLoanerButton}
				exception={exception}
				deviceAtLoggedInLoc={deviceAtLoggedInLoc}
				featureConfig={featureConfig}
				isHardwareClosure={showRequestLogs.isHardwareClosure}
				getSumInsured  = {getSumInsured }
				savedOptions = {savedOptions}
				savedOptionKeys = {savedOptionKeys}
				showRequestLogs={showRequestLogs}
				isDOAEligible={isDOAEligible}
				checkDOAApplicable={checkDOAApplicable}
				isHandedToCC={isHandedToCC}
				arrDiagnosisSuites={arrDiagnosisSuites}
				diagnosisSuites={diagnosisSuites}
				showRequestValid={showRequestValid}
				addedFaultActions={addedFaultActions}
				berFlags = {berFlags}
				berRequestNext = {berRequestNext}
				fetchBERConfiguration ={fetchBERConfiguration}
				selectedCountryData={selectedCountry}
				getApplicationFeatureConfig={getApplicationFeatureConfig}
				getPartsList={getPartsList}
				loanerDetails={loanerDetails}
				fetchLoanerDetailsByCsrId={fetchLoanerDetailsByCsrId}
				getBillingDetails={getBillingDetails}
				claimNewWorkshopChecks={claimNewWorkshopChecks}
				claimChecks={claimChecks}
				pickupCarryInGSXChecks={pickupCarryInGSXChecks}
				pickupCarryInChecks={pickupCarryInChecks}
				onsiteChecks={onsiteChecks}
				berChecks={berChecks}
				replacementFlowChecks = {replacementFlowChecks}
				isBoseFlow = {isBoseFlow}
				activeTab={activeTab} setActiveTab={setActiveTab}
				allTabs={allTabs}
				showRefundButton = {showRefundButton}
				soldPlan ={soldPlan}
				refundProcessCheck = {refundProcessCheck}
				activeCoverages = {activeCoverages}
				serviceRequestClusterCheck = {serviceRequestClusterCheck}
				refurbishmentFlowCheck = {refurbishmentFlowCheck}
				setConsumerServiceRequest={setConsumerServiceRequest}
				appleDropOffFlow={appleDropOffFlow}
				isDiagnosisCompletedByEng={isDiagnosisCompleted}
				getPendingDocuments={getPendingDocuments}
				updateConsumerServiceRequestStatus={updateConsumerServiceRequestStatus}
            />
		</div>
		<div className='navigationMT'>
		<NavigationBar {...props}
			allTabs={allTabs}
			activeTab= {activeTab} setActiveTab= {setActiveTab}
			consumerServiceRequest={consumerServiceRequest}
			exceptionLogs={exceptionLogs}
			setConsumerServiceRequest={setConsumerServiceRequest}
			canUpdateServiceRequest={canUpdateServiceRequest}
			defaultCountryLocale={defaultCountryLocale}
			consumerDetails={consumerDetail}
			requestDeailsID={consumerServiceRequest.ConsumerServiceRequestID}
			coverageDetails={coverageDetails.length >0 && coverageDetails}
			getRequestById={getRequestById}
			currencySymbol={currencySymbol}
			selectedCountryData={selectedCountry}
			checkPaymentStatus={checkPaymentStatus}
			checkDecisionMatrixProcessCompleted={checkDecisionMatrixProcessCompleted}
			showViewRequestFlags={showViewRequestFlags}
			showRequestLogs={showRequestLogs}
			showRequestValid={showRequestValid}
			adjudicationChecklistData={adjudicationChecklistData}
			consumerProductDocuments={consumerProductDocuments}
			getProductDetails={getProductDetails}
			getInsuranceDetails={getInsuranceDetails}
			loanerDeviceList={loanerDeviceList}
			insurancePlans={insurancePlans}
			getTradeInDetails={getTradeInDetails}
			tradeInDetails={tradeInDetails}
			getTradeInDashboardDetails={getTradeInDashboardDetails}
			tradeInDashboardDetails={tradeInDashboardDetails}
			highRiskReason={highRiskReason}
			countriesChecks={countriesChecks}
			selectedBillingDetail={selectedBillingDetail}
			addedFaultActions={addedFaultActions}
			addedFaultCodes={addedFaultCodes}
			getBillingDetails={getBillingDetails}
			requestSFA={requestSFA}
			getPartnerSymptomFaultAction={getPartnerSymptomFaultAction}
			retrieveAllDocs={retrieveAllDocs}
			documents={documents}
			partsList={partsList}
			getAdjudicationChecklist={getAdjudicationChecklist}
			getPendingDocuments={getPendingDocuments}
			getDocumentsMaster={getDocumentsMaster}
			docmaster={reqiredDocumentsMaster}
			docpending={pendingDocuments}
			billingDetailList={billingDetailList}
			prepareAddressString={prepareAddressString}
			updateAddressFetchConsumer={updateAddress}
			pushAddress={pushAddress}
			setPartsRequested={setPartsRequested}
			exception={exception}
			getCurrencyMapping={getCurrencyMapping}
			currencyData={currencyData}
			getTaskValues={getTaskValues}
			taskListArr={taskListArr}
			ConsumerServiceRequestID={ConsumerServiceRequestID}
			logisticsRequestDetailsImage={logisticsRequestDetailsImage}
			logisticsRequestDetails={logisticsRequestDetails}
			partsRequested={partsRequested}
			getPartsForRequest={getPartsForRequest}
			newProductDetail={newProductDetail}
			isEmptyBoxServiceable={isEmptyBoxServiceable}
			emptyBoxPackagingDetails={emptyBoxPackagingDetails}
			assignObjCheck={assignObjCheck}
			deviceAtLoggedInLoc={deviceAtLoggedInLoc}
			hideAssignOption={hideAssignOption}
			serviceLocationData={serviceLocationData}
			soldPlanCoverage={soldPlanCoverage}
			claimDamageId={claimDamageId}
			planID={planID}
			setPartsList={setPartsList}
			getPartsList={getPartsList}
			onsiteChecks={onsiteChecks}
			actualCost={actualCost}
			actualCostAllowed={actualCostAllowed}
			isBoseFlow = {isBoseFlow}
			getFaultList={getFaultList}
			getRequestSFA={getRequestSFA}
			isSubscriptionEnabled={isSubscriptionEnabled}
			checkOnsiteEwReq={checkOnsiteEwReq}
			showPartModal={showPartModal} setShowPartModal={setShowPartModal}
			getRefurbishmentRequestDetails={getRefurbishmentRequestDetails}
			refurbishmentRequestData={refurbishmentRequestData}
			refurbishmentFlowCheck = {refurbishmentFlowCheck}
			loanerChargesResult = {loanerChargesResult}
			setLoanerChargesResult = {setLoanerChargesResult}
			loanerCharges = {loanerCharges}
			setLoanerCharges = {setLoanerCharges}
			currentLoanerStatus = {currentLoanerStatus}
			setCurrentLoanerStatus = {setCurrentLoanerStatus}
			DOAEntries = {DOAEntries}
			setDOAEntries = {setDOAEntries}
			fetchDetailsCalled = {fetchDetailsCalled}
			setFetchDetailsCalled = {setFetchDetailsCalled}
			loanerDetails = {loanerDetails}
			setLoanerDetails = {setLoanerDetails}
			fetchLoanerDetailsByCsrId = {fetchLoanerDetailsByCsrId}
			appleDropOffFlow={appleDropOffFlow}
			soldPlan ={soldPlan}
			getCaseScores={getCaseScores}
			scoreDetails={scoreDetails}
			setScoreDetails={setScoreDetails}
			updateConsumerServiceRequestStatus={updateConsumerServiceRequestStatus}
		></NavigationBar>
		</div>
            <RightSidebar {...props} logs={consumerServiceRequest.logs} title={"Request History"} consumerServiceRequest={consumerServiceRequest} requestPage={true}></RightSidebar>
		</>
	)
}
export default ViewServiceRequest;
