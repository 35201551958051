import React from 'react';
import { formatIndianCurrencyRs } from '../../../../Utility/Utils';

export default function SalesAndPerformanceGrid({salesDetails, advisorDetails, localString}) {
	return (
		<div>
			<table className="table sales-report-table">
				<thead>
					<tr>
						<th className="col-md-1">{localString('Date')}</th>
						<th className="col-md-10" colSpan="9">
							<table className="table table-inner-sales-head">
								<thead>
									<tr>
										<th className="col-md-2">{localString('Customer Name')}</th>
										<th className="col-md-1">{localString('Customer Mobile No')}</th>
										<th className="col-md-2">{localString('Shop')}</th>
										<th className="col-md-1">{localString('Product Category')}</th>
										<th className="col-md-1 text-center">{localString('Product SubCategory')}</th>
										<th className="col-md-1">{localString('Model No')}</th>
										<th className="col-md-2">{localString('Model Description')}</th>
										<th className="col-md-1">{localString('Invoice Amount')}</th>
										<th className="col-md-1">{localString('Status')}</th>
									</tr>
								</thead>
							</table>
						</th>
						<th className="col-md-1">{localString('Total')}</th>
					</tr>
				</thead>
				<tbody>
					{
						salesDetails.length ? salesDetails.map((saleDetail, k) => {
							return (
								<tr>
									<td>{saleDetail.Date}</td>
									<td colSpan="9">
										<table className="table sales-reportview-table">
											<tbody>
												{
													saleDetail.ProductData.length && saleDetail.ProductData.map((productDetail, index) => {
														return (
															<tr>
																<td className="col-md-2">{productDetail.CustomerName || "-"}</td>
																<td className="col-md-1">{productDetail.MobileNo || "-"}</td>
																<td className="col-md-2">{advisorDetails.length ? advisorDetails[0].shop : "-"}</td>
																<td className="col-md-1">{productDetail.ProductCategory || "-"}</td>
																<td className="col-md-1 text-center">{productDetail.ProductSubcategory || "-"}</td>
																<td className="col-md-1">{productDetail.ModelNo || "-"}</td>
																<td className="col-md-2">{productDetail.ModelDesc || "-"}</td>
																<td className="col-md-1">₹ {formatIndianCurrencyRs(productDetail.InvoiceAmount) || "-"}</td>
																<td className="col-md-1 border-left-none" >{productDetail.Status || "-"}</td>
															</tr>
														);
													})
												}
											</tbody>
										</table>
									</td>
									<td style={{ verticalAlign: 'middle'}}>
										₹ {formatIndianCurrencyRs(saleDetail.SumBasedOnEachDay)}
									</td>
								</tr>
							)
						}) : ''
					}
				</tbody>
			</table>
			{ !salesDetails.length && <div className="col-sm-12"> 
				<div className="text-center requests-empty-state"> 
					<div className="empty-state-text"> 
						{localString('No Data Available')}
					</div> 
				</div> 
			</div> }
		</div>
	);
}