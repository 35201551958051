import React, {useState,useEffect} from 'react';
import './HistoryTab.scss';
import moment from 'moment';
import {groupBy} from 'lodash';
import {diagnosisGroupConfig} from '../../Utility/Utils';
import TabCollapse from '../../components/Tab/TabCollapse';
import ExpandItem from '../../components/ExpandItem/ExpandItem';
import {diagnosticStatus} from '../../Utility/Utils';

const HistoryTab = (props) => {
    const {
        localString, diagnosisObj, referenceNumber, testHistoryCount, setActiveReportObj, setHistoryModalObj, checkDiagnosis
    } = props;
    const [issueCount, setIssueCount] = useState(0);
    const [historyGroups, setHistoryGroups] = useState({});

    useEffect(() => {
        checkIssueCount();
        processHistoryObj();
    }, [diagnosisObj]);

    const processHistoryObj = () => {
        if(diagnosisObj.event.data && diagnosisObj.event.data.length > 0) {
            setHistoryGroups(groupBy(diagnosisObj.event.data, (obj) => {
                if (diagnosisGroupConfig[obj.name]) {
                    return diagnosisGroupConfig[obj.name];
                } else {
                    return 'Other'
                }
            }));
        }
    };

    const checkIssueCount = () => {
        let count = 0
        if(diagnosisObj.event.data && diagnosisObj.event.data.length > 0) {
            diagnosisObj.event.data.forEach((diagnosis) => {
                if (diagnosis.status == 2) {
                    count++
                }
            })
        }
        setIssueCount(count);
    }
    return (
        <TabCollapse
            headerText={(diagnosisObj.event.type ? diagnosisObj.event.type + ' - ' : '' ) +  (diagnosisObj.event.IMEI ? diagnosisObj.event.IMEI : '')}
            date={moment(diagnosisObj.event.createdDate).format('DD MMM, YYYY hh:mm a')}
            issueCount={issueCount}
            showIssueCount={true}
            onClickHandler={(isOpen) => isOpen && checkDiagnosis()}
        >
            <div className="col-xs-12 report-icon" onClick={() => setActiveReportObj({...diagnosisObj})}>
                {localString("Diagnostic Report") + "  "}
                <span className="glyphicon glyphicon-print"></span>
            </div>
            {historyGroups && Object.keys(historyGroups).length > 0 ?
            Object.keys(historyGroups).map((group) => { return(
                <div className="group-container">
                    <div className="group-header">{localString(group)}</div>
                    <div className="group-content row">
                        {historyGroups[group] && historyGroups[group].length > 0 &&
                        historyGroups[group].map((singleObj) => {return(singleObj &&
                            <ExpandItem 
                                name={singleObj.name}
                                showImage={singleObj.status?.toString()}
                                // showImage={!!(diagnosticStatus[singleObj.status] && diagnosticStatus[singleObj.status.toString()].image)}
                                imgUrl={diagnosticStatus[singleObj.status] && diagnosticStatus[singleObj.status.toString()].image} 
                                localString={localString} 
                                imgAlt={diagnosticStatus[singleObj.status] && diagnosticStatus[singleObj.status.toString()].displayText}
                                subHeaderText={singleObj.irisCode ? `IRIS Code: ${singleObj.irisCode}` : ''}
                                subHeaderText1={!referenceNumber ? localString("Last Diagnosed ") + moment(diagnosisObj.event.createdDate).format('DD MMM, YYYY hh:mm a') : ''}
                                subHeaderText2={!referenceNumber ? localString("Diagnosed " + testHistoryCount[singleObj.name] + " Times"): ''}
                                showExpandArrow={singleObj.componentMeta && singleObj.componentMeta.length > 0}
                                setHistoryModalObj={setHistoryModalObj}
                                isSubHeaderClickable={true}
                            >
                                {singleObj.componentMeta && singleObj.componentMeta.length > 0 && singleObj.componentMeta.map((obj) => {return (<>
                                    {obj.value ? <div className="item-info">
                                        <span className="info-label">{obj.name}</span><span> : </span>
                                        {['Values', 'ValuesList','valueList','Data'].includes(obj.name) ? 
                                            <span>
                                                <br/>
                                                { Object.keys(obj.value)?.length && Object.keys(obj.value).map((item) => (<span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item} : {obj.value[item]} <br/>
                                                </span>))}
                                            </span> 
                                            : ''}
                                        {!['Values', 'ValuesList','valueList','Data'].includes(obj.name) ? <span>{(obj.value).toString()}</span> : ''}
                                    </div> : ''}
                                </>)})}
                            </ExpandItem>
                        )})}
                    </div>
                </div>
            )}) : <div className="group-container">{localString('No results to display')}</div>}
        </TabCollapse>
    )
}

export default HistoryTab
