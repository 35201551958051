import React,{useState,useEffect} from 'react'
import PageHeader from '../../components/PageHeader/PageHeader';
import RetailerHandsetReset from './RetailerHandsetReset';
import RetailerLocationReset from './RetailerLocationReset';

const RetailerReset = (props) => {
	const {titleHeader,localString,publicUrl} = props;
	const [handsetReset, setHandsetReset] = useState(false);
	const [locationReset, setLocationReset] = useState(false);

	const resetOptions = [{
		labelText:"HANDSET RESET",
		id:"handset-reset",
		customClass:"col-sm-3 col-xs-4 col-md-offset-3",
		imgSrc:publicUrl+'/imgs/handset-purple.png',
		altName:"Pick Up",
		activeBorder : "item-active-border" ,
		onclickChange:()=>{
			setHandsetReset(true);
			setLocationReset(false);
		}
	},{
		labelText:"LOCATION RESET",
		id:"location-reset",
		customClass:"col-sm-3 col-xs-4",
		imgSrc:"https://s3-ap-southeast-1.amazonaws.com/consumerappassets/3-drop-in.png",
		altName:"Carry in",
		activeBorder : "item-active-border" ,
		onclickChange:()=>{
			setHandsetReset(false);
			setLocationReset(true);
		}
	}]

	return (
		<>
			<PageHeader titleHeader={titleHeader} localString={localString}></PageHeader>
			<div className="content-padding reset-shadow">
				<div className="row">
					<div className="col-sm-10 col-xs-8 form-label">
						<label>{localString("Select a Reset Option")}</label>
					</div>
					{
						resetOptions && resetOptions.map((val)=>{
							return(
								<div className={val.customClass} onClick={val.onclickChange}>
									<div className="item subcat-item" id={val.id}>
										<img src={val.imgSrc} alt={val.altName}/>
										<p className="text-capitalize">{localString(val.labelText)}</p>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					{handsetReset && <RetailerHandsetReset {...props}></RetailerHandsetReset>}
					{locationReset && <RetailerLocationReset {...props}></RetailerLocationReset>}
				</div>
			</div>
		</>

	)
}

export default RetailerReset
