import React from 'react';
import { Button } from 'sfy-react';
import './Header.scss';


export default function Header({
	showTitle = true,
	titleHeader,
	buttonClass,
	linkText,
	hrefID,
	linkClass,
	showLink,
	btnClass,
	headerButtonArray,
	showHtmlContent = false,
	htmlContent
}) {
	return (
		<div className="content-head">
			<div className="row servify-header-background"> 
				<div className="col-xs-12">
					{ showTitle && !showHtmlContent && <h3 className={`title-requests ${buttonClass}`}>{titleHeader}</h3> }
					{ showLink && !showHtmlContent && <a href={hrefID} className={linkClass}>{linkText}</a> }
					{
						showHtmlContent && headerButtonArray && headerButtonArray.length>0 && headerButtonArray.map((val, index)=>{
							return(
								val.showButton &&
									<Button className={btnClass} onClick={val.onButtonClick}>
									{
										val.btnIcon ? <span className={val.btnIcon}> </span> : ""
									}
									{val.buttonText} 
									</Button>
							)
						})
					}
					{showHtmlContent && htmlContent()}
				</div>
			</div>
		</div>
	)
}
