import React from 'react';
import {Button,Dropdown,Table,Radio} from 'sfy-react';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import { SERVICETYPE } from '../../../../../Utility/Utils';
import './IssueValidation.scss'

const IssueValidationWorkshop = (props) => {
	const {warrantyApplicableCheck,coveredUnderPlanCheck,editIssuesClick,validate,setIssueSelectedForAddition,
		cancelIssuesClick,consumerServiceRequest,localString,accessFn,editIssues,isReportedLength,isValidatedLength,
		isRequestSFANeeded,isDiagnosisCompleted,addSymptomCode,deleteSymptoms,canUpdateServiceRequest,engineerHasDeviceLog,
		disabeAddSymptom,issueValidityBtn,csrIssuesArr,symptomList,issueSelectedForAddition
	} = props;

	const warrantyValidity=[{
		labelText: "Under Warranty",
		value:"",
		name:"warrantyValidity",
		id:"warrantyValidity-valid",
		isSelect:"",
		onclickupdate:(val)=>{
			
		},
	},
	{
		labelText: "Out of Warranty",
		value:"",
		name:"warrantyValidity",
		id:"warrantyValidity-invalid",
		isSelect:"",
		onclickupdate:(val)=>{
			
		},

	}]

	const classObject = {
		table:"table table-striped table-responsive table-bordered table-condensed "
	}

	return (
		<div className="row">
			<PageContentWrapper 
				title={localString("Issues Reported")} 
				showDownloadButtonIcon={
					canUpdateServiceRequest && !accessFn('READ_ONLY') && (['Work in progress', 'Estimation generated', 'Parts received', 'Parts issued', 'Parts partially issued', 'Diagnosis initiated', 'Diagnosis completed'].indexOf(consumerServiceRequest.Status) > -1) && consumerServiceRequest.logs[0].Status != 'Diagnosis initiated' && isDiagnosisCompleted() && (accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS')) && !consumerServiceRequest.InvoiceGeneratedDate && (engineerHasDeviceLog.DestinationType ==='ServicelocationEngineer' || engineerHasDeviceLog.DestinationType ==='Supervisor') && warrantyApplicableCheck() && coveredUnderPlanCheck()
				}
				editDeviceClick={editIssuesClick}
			>
			<div class="request-panel-details-tag request-issue-validation-div issue-validation-workshop">
			{
				editIssues && 
				<>
					{
						csrIssuesArr && csrIssuesArr.length > 0 && (consumerServiceRequest.EWRequest || [SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) === -1) && 
						<div className="row">
							<div class="col-sm-12">
								<p class="issues-table-header text-capitalize remove-margin">{localString('Consumer Raised Issues')}</p>
								<Table className={classObject}>
									<Table.Header>
										<Table.Row>
											<Table.Heading width={[SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? "30%": "80%"}>{localString('Issues')}</Table.Heading>
											<Table.Heading width={[SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 ? "15%": "20%"}>{localString('Validity')}</Table.Heading>
											{
												[SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && 
												<Table.Heading width="15%">{localString("Warranty Status")}</Table.Heading>
											}
											{
												[SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && 
												<Table.Heading width="25%" >{localString("Actions")}</Table.Heading>
											}
										</Table.Row>
									</Table.Header>
									{
										csrIssuesArr.map((item,index)=>{
											return(
												item.IsReported && 
												<Table.Row>
													<Table.Data>{localString(item.IssueText)}
													{
														item.IssueRemark && <span > - {localString(item.IssueRemark)}</span>
													}
													</Table.Data>
													<Table.Data className="workshop-custom-radio">
														{
															!item.IsValidated && 
															issueValidityBtn && issueValidityBtn.map((button,i)=>{
																return (
																	<Radio
																		value={button.value}
																		selectedValue={item.validityStatus}
																		name={"issueValidity"+index}
																		id={item.ConsumerServiceRequestIssuesId+"_"+button.value}
																		handleOnUpdate={(e) => button.onclickupdate(e.target.value,item)}
																	>{button.labelText}</Radio>
																)
															})
														}
														{
															item.IsValidated && item.validityStatus == 1 && 
															<div>{localString('Valid')}</div>
														}
														{
															item.IsValidated && item.validityStatus == 2 && 
															<div>{localString('Invalid')}</div>
														}
													</Table.Data>
													{
														[SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && 
														<Table.Data>
														{/* {
															item.validityStatus != 2 && !item.IsValidated && 
															warrantyValidity && warrantyValidity.map(item=>{
																return (
																	<div className="">
																		<Radio
																			value={item.value}
																			selectedValue={item.isSelect}
																			name={item.name}
																			id={item.id}
																			handleOnUpdate={(e) => item.onclickupdate(e.target.value)}
																		>{item.labelText}
																		</Radio>
																	</div>
																)
															})
														} */}
														</Table.Data>
													}
													{
														[SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && 
														<Table className="table table-bordered table-condensed table-striped">
															<Table.Header></Table.Header>
															{
																item.validityStatus!=2 && !item.IsValidated 
																//&&
																//<Table.Row each={action in actionLists[String(issue.ProductSubCategoryIssueID)]} >
																//    <Table.Data>
																//        {/* <input type="checkbox" name={ 'action' + issue.ProductSubCategoryIssueID} id={ '' + action.ActionMasterID} />
																//        {action.Action} */}
																//    </Table.Data>
																//</Table.Row>
															}
															
														</Table>
													}
												</Table.Row>
											)
										})
									}
								</Table>
							</div>
						</div>
					}
					{
						isReportedLength > 0 && ([SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR,61,62].indexOf(consumerServiceRequest.ServiceTypeID) == -1 || consumerServiceRequest.EWRequest) && isReportedLength > isValidatedLength && 
						<div className="validate-btn">
							<Button 
								type="submit" 
								className="btn button-servify charges-button"
								text={localString('Validate')}
								handleOnClick={validate}
							/>
						</div>
					}
					{
						csrIssuesArr && csrIssuesArr.length < 1 && ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) == -1 || consumerServiceRequest.EWRequest) && 
						<div className="row">
							<div className="col-sm-12">
								<p className="charges-panel-subheader text-capitalize remove-margin">{localString('Customer did not add issues')}</p>
							</div>
						</div>
					}
					<hr></hr>
					{
						!isRequestSFANeeded && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID)==-1 && 
						<>
						<div class="row">
							<div class="col-sm-12">
								<p class="issues-table-header text-capitalize remove-margin">{localString('Symptoms Reported By Engineer')}</p>
								<Table isResponsive>
									<Table.Header>
										<Table.Row>
											<Table.Heading width="90%">{localString('Symptoms')}</Table.Heading>
										</Table.Row>
									</Table.Header>
									{
										consumerServiceRequest && consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.map((key,value)=>{
											return(
												<Table.Row>
													<Table.Data className="issue-validation-symptom">
														{localString(key.SymptomDescription)}
														<Button 
															type="button" 
															name="delete"
															id={"deletebutton"+value}
															text={localString("x")}
															className="btn button-servify close-symptom-workshop close"
															handleOnClick={()=>deleteSymptoms(key)}
														/>
													</Table.Data>
												</Table.Row>
											)
										})
									}
								</Table>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-12">
								<p className="charges-panel-subheader text-capitalize remove-margin">{localString('Add New Symptom Codes')}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-7 form-group typeahead-left-padding">
								<Dropdown
									id="issues"
									value={issueSelectedForAddition && issueSelectedForAddition.value}
									options={symptomList}
									handleOnChange={(data) => {
										setIssueSelectedForAddition(data)
									}}
									filterBy='value'
									showDownArrow={false}
								/>
							</div>
							<div>
								<Button 
									type="button" 
									isDisabled={disabeAddSymptom}
									className="btn button-servify charges-button btn btn-top-margin"
									text={localString('Add')}
									handleOnClick={addSymptomCode}
								/>
							</div>
						</div>
						</>
					}
					<Button 
						type="button" 
						className="btn button-servify "
						text={localString('Cancel')}
						handleOnClick={cancelIssuesClick}
					/>
				</>
			}
			{
				!editIssues && 
				<>
				{    
					consumerServiceRequest && consumerServiceRequest.issues && consumerServiceRequest.issues.length < 1 && (consumerServiceRequest.EWRequest || [SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) == -1)  && !consumerServiceRequest.showEngineerVisits && 
					<div class="row">
						<div class="col-sm-12">
							<p class="charges-panel-subheader text-capitalize remove-margin">{localString('No issues added')}</p>
						</div>
					</div>
				}
				{
					consumerServiceRequest && consumerServiceRequest.issues && consumerServiceRequest.issues.length >= 1 && ([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) == -1 || consumerServiceRequest.EWRequest) && !consumerServiceRequest.showEngineerVisits && 
					<Table className={classObject}>
						<Table.Header>
							<Table.Row>
								<Table.Heading >{localString('Issues')}</Table.Heading>
								<Table.Heading >{localString('Status')}</Table.Heading>
							</Table.Row>
						</Table.Header>
						{
							consumerServiceRequest && consumerServiceRequest.issues && consumerServiceRequest.issues.map(item=>{
								return(
									<Table.Row>
										<Table.Data>{localString(item.IssueText)}
											{
												item.IssueRemark && <span> - {localString(item.IssueRemark)}</span>
											}
											{
												item.IssueText === 'Other' && <span> - {localString(item.otherIssueText)}</span>
											}
										</Table.Data>
										<Table.Data>
											{
												item.IsValidated && item.validityStatus == 1 ? localString('Valid') : item.IsValidated && item.validityStatus == 2 ? localString('Invalid') : ''
											}
										</Table.Data>
									</Table.Row>
								)
							})
						}
					</Table>
				}
				{
					!isRequestSFANeeded && consumerServiceRequest && consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length < 1 && !consumerServiceRequest.showEngineerVisits && 
					<div className="row">
						<div className="col-sm-12">
							<p className="charges-panel-subheader text-capitalize remove-margin">{localString('No symptoms added')}</p>
						</div>
					</div>
				}
				{
					!isRequestSFANeeded && consumerServiceRequest && consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length >= 1 && !consumerServiceRequest.showEngineerVisits && 
					<div className="table-responsive">
						<Table className="table table-bordered table-condensed table-striped">
							<Table.Header>
								<Table.Row>
									<Table.Heading >{localString('Symptoms')}</Table.Heading>
									<Table.Heading >{localString('Status')}</Table.Heading>
								</Table.Row>
							</Table.Header>
							{
								consumerServiceRequest && consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.map(item=>{
									return(
										<Table.Row>
											<Table.Data>{localString(item.SymptomDescription)}</Table.Data>
											<Table.Data>{localString('Valid')}</Table.Data>
										</Table.Row>
									)
								})
							}
						</Table>
					</div>
				}
				{
					consumerServiceRequest && consumerServiceRequest.symptomFaultActions && consumerServiceRequest.symptomFaultActions.length < 1 && consumerServiceRequest.showEngineerVisits && 
					<div class="row">
						<div class="col-sm-12">
							<p class="charges-panel-subheader text-capitalize remove-margin">{localString("No symptoms added")}</p>
						</div>
					</div>
				}
				{
					consumerServiceRequest && consumerServiceRequest.symptomFaultActions && consumerServiceRequest.symptomFaultActions.length >= 1 && consumerServiceRequest.showEngineerVisits && 
					<div className="table-responsive">
						<Table className="table table-bordered table-condensed table-striped">
						<Table.Header>
							<Table.Row>
								<Table.Heading width="30%">{localString("Symptoms")}</Table.Heading>
								<Table.Heading width="30%">{localString("FaultCode")}</Table.Heading>
								<Table.Heading width="40%">{localString("Actions")}</Table.Heading>
							</Table.Row>
						</Table.Header>
						{
							consumerServiceRequest && consumerServiceRequest.symptomFaultActions && consumerServiceRequest.symptomFaultActions.map(symptomFaultAction=>{
								return(
									<Table.Row>
										<Table.Data>{symptomFaultAction.SymptomDescription}</Table.Data>
										<Table.Data>{symptomFaultAction.FaultDescription? symptomFaultAction.FaultDescription:'' }</Table.Data>
										<Table.Data>{symptomFaultAction.ActionDescription}</Table.Data>
									</Table.Row>
								)
							})
						}
						</Table>
					</div>
				}
				</>
			}
			</div>
			</PageContentWrapper>
		</div>
	)
}

export default IssueValidationWorkshop
