import React from 'react';
import {Modal} from 'sfy-react';
import './GsxAuthTokenModal.scss';
import GsxAuthTokenInput from './GsxAuthTokenInput';
const GsxAuthTokenModal=(props)=>{
    const {localString,showModal,closeModal} = props;
		return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='gsxmodal-width'>
                <Modal.Header>
                    <h4 className='gsxtext-align'> {localString("Enter Activation Token")} </h4>
                    <p className='gsxtext-align'> {localString("Please follow below steps to Update Activation Token for your linked GSX User ID")}</p>
                </Modal.Header>
                <Modal.Body>
                    <GsxAuthTokenInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default GsxAuthTokenModal;



