import React from "react";
import { Modal, Input, Button } from "sfy-react";
import "./ConfirmationModal.scss";

const ConfirmationModal = (props) => {
	const {
		localString,
		showModal,
		closeModal,
		modalData,
		submitFunction,
		disableApplyBtn,
		cancelFunction,
	} = props;
	return (
		<>
			<Modal
				showModal={showModal}
				closeModal={closeModal}
				showCloseBtn={true}
				className="confirmation-modal"
			>
				<Modal.Header>
					<h2> {localString(modalData.heading)} </h2>
				</Modal.Header>
				<Modal.Body>
					<div className="padding15">
						<Input
							value={modalData.inputValue}
							handleOnChange={(e) => {
								modalData.oninputFunc(e.target.value);
							}}
							label={modalData.labelText}
							labelAlign="top"
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="button-container">
						<Button
							handleOnClick={submitFunction}
							isDisabled={disableApplyBtn}
							type="submit"
							classes="button-ghost"
							text={localString(modalData.submitButtonText)}
						></Button>
						{!modalData.hideClearButton && (
							<Button
								handleOnClick={cancelFunction}
								type="button"
								classes="button-ghost"
								text={localString("Close")}
							></Button>
						)}
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};
export default ConfirmationModal;
