import React from "react";
import { Modal,  Button } from "sfy-react";
import './DeviceUniqueModal.scss'

const DeviceUniqueModal = (props) => {
	const {
		localString,
		showModal,
		closeModal,
		consumerServiceRequest,
        partsRequested,
		repairComplete,
	} = props;
	return (
		<>
			<Modal
				showModal={showModal.showDeviceUniqueModal}
				closeModal={closeModal}
				showCloseBtn={true}
				classNameName="device-unique-modal"
			>
				<Modal.Header>
					<h2>{localString("Device Transaction Details")}</h2>
				</Modal.Header>
				<Modal.Body>
					<div classNameName="device-unique-wrapper">
						<div className="row">
							<div className="col-sm-12">
								<form>
									<div className="">
										<div
											className="col-sm-12"
											style="margin-left:6px;"
										>
											<p className="hand-label">
												{localString(
													"Handing over the damaged device to store"
												)}
												.
											</p>
										</div>
									</div>
									<div className="">
										{partsRequested &&
											partsRequested.length > 0 &&
											partsRequested.map((i, part) => (
												<div
													className="col-sm-12"
													style="margin-left:6px;"
												>
													{i.statusDetails.Status ==
														"received" && (
														<label className="label-damage-modal-name">
															{i.PartName
																? i.PartName
																: i.Name}
														</label>
													)}
													<label className="label-damage-modal">
														{localString(
															"Damaged IMEI"
														)}
														:{" "}
														{
															consumerServiceRequest
																.consumerProduct
																.ProductUniqueID
														}
													</label>
												</div>
											))}
									</div>
									<div className="">
										<div
											className="col-sm-12"
											style="margin-left:6px; margin-top:20px"
										>
											<p className="hand-label">
												{localString(
													"Details of the New Replaced Device"
												)}
											</p>
										</div>
									</div>
									<div className="">
										{partsRequested &&
											partsRequested.length > 0 &&
											partsRequested.map((i, part) => (
												<div
													className="col-sm-12"
													style="margin-left:6px;"
												>
													{i.statusDetails.Status ==
														"received" && (
														<label className="label-damage-modal-name">
															{i.PartName
																? i.PartName
																: i.Name}
														</label>
													)}
													<label className="label-damage-modal">
														{localString(
															"Replaced IMEI"
														)}{" "}
														1:{" "}
														{i.IMEI1
															? i.IMEI1
															: localString("NA")}
													</label>
													<label className="label-damage-modal">
														{localString(
															"Replaced IMEI"
														)}{" "}
														2:{" "}
														{i.IMEI2
															? i.IMEI2
															: localString("NA")}
													</label>
													<label className="label-damage-modal">
														{localString(
															"Replaced Serial No"
														)}
														:{" "}
														{i.SerialNumber
															? i.SerialNumber
															: localString("NA")}
													</label>
												</div>
											))}
									</div>
									<div style="margin-top:10px;">
										<Button handleOnClick={repairComplete}>
											Save
										</Button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default DeviceUniqueModal;
