import React  from 'react';
import { SERVICETYPE } from '../../../../Utility/Utils';
import EscalationFlag from '../../../../components/EscalationFlag/EscalationFlag';

import "./ViewMastHeadDetails.scss"
const ViewMastHeadDetails =(props)=>{
    const {mastHead,localString,consumerServiceRequest,isSingleRole,role,showPendingDocMsg,showAccessoriesMsg,editableEscalationData} = props;
    return(
        <div>
            <div className="row">
                {
                    mastHead  && mastHead.mastHeadHeader && mastHead.mastHeadHeader.map(item=>(
                        <>
                        <div>
                            {item.showService && <div className='col-sm-3 action-buttons'>
                                    {/* <div className='display-flex'>
                                        {item.serviceImageUrl && <img className="header-image-div " src={item.serviceImageUrl} alt="" />}
                                        <div className='request-details-tab'>
                                            {item.serviceTypeText && <div>{item.serviceTypeText}</div>}
                                            {item.subServiceTypeText && <div>{item.subServiceTypeText}</div>}
                                            {item.referenceID && <div>{item.referenceID}</div> }
                                            { editableEscalationData && editableEscalationData.count>0 && <div className='display-flex'>
                                                <img src={item.flagImageUrl} alt=""/>
                                                <div className='flag-tag request-page'>{item.escalationTagText }</div>
                                            </div>}
                                        </div> */}
                                    {/* </div> */}

                                    <div class="display-flex-main">
                                        {item.serviceImageUrl && <div>
                                            <img className="header-image-div " src={item.serviceImageUrl} alt="" />
                                        </div>}
                                        <div class="display-flex">
                                            <div class="display-flex">
                                                {item.serviceTypeText && <div><span className='p5'>{item.serviceTypeText}</span></div>}
                                                {item.subServiceTypeText &&<div> <span className='p5' style={{whiteSpace:'nowrap'}}>{item.subServiceTypeText}</span></div>}
                                                {item.referenceID &&<div>  <span className='p5'>{item.referenceID}</span></div>}
                                            {/* </div> */}
                                            </div>
                                            { editableEscalationData && editableEscalationData.count>0 && <div className='display-flex'>
                                                {/* <img src={item.flagImageUrl} alt=""/> */}
                                                {item.flagImageUrl && <EscalationFlag color={item.escalationTagColor}/>}
                                                <div className='flag-tag request-page' 
                                                    style={item.escalationTagColor ? {backgroundColor: item.escalationTagColor} : {}}
                                                >
                                                    {item.escalationTagText}
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>}
                                {
                                    item.showProduct && <div className='col-image col-sm-3 action-buttons col-tab'>
                                            <img className="header-image-div " src={item.productImageUrl} alt="" />
                                            <div>{item.productName }</div>
                                    </div>
                                }
                                {
                                    item.showServiceCenter && <div className='col-image col-sm-3 action-buttons'>
                                        {item.serviceCenterText && <div>{item.serviceCenterText} {item.serviceCenter }</div>}
                                        {item.source && <div>{" "}{item.source}</div>}
                                        {item.retailerText && <div>{item.retailerText} {item.retailerCenter }</div>}
                                    </div>
                                }
                                {
                                    item.showRole &&  <div className='col-image col-sm-2 action-buttons col-tab'>{
                                        role && isSingleRole() && <p>{item.roleText} : {localString(role)}</p>
                                    }</div>
                                }
                                {
                                    item.showAccessoriesAndPendingDoc && <div className='col-sm-2 action-buttons'>
                                        {
                                            showAccessoriesMsg && <div className='service-location-header'>
                                                <p>{item.accessoriesMsgText}</p>
                                            </div>
                                        }
                                        {
                                            showPendingDocMsg && <div className='service-location-header'>
                                                <p>{item.pendingDocMsgText}</p>
                                            </div>
                                        }
                                    </div>//will check later
                                }
                                {
                                    item.showPrinter  && <div className={(showAccessoriesMsg || showPendingDocMsg || role) ? 'col-sm-1 action-buttons' : 'col-sm-3 action-buttons'}>            
                                        <div className='pull-left'>
                                            <a className="dropdown-toggle" data-toggle="dropdown">  
                                                <img className="printIcon printIcon-margin" src={item.printerImageUrl} alt="" />
                                            </a>
                                            <ul className="dropdown-menu print-menu">
                                                {props.children}
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    ))
                }
            </div>
            <hr/>
            <div>
                {
                    mastHead && mastHead.mastHeadDetails &&  mastHead.mastHeadDetails.map(item=>(
                        <>
                        {
                            item.showRequestAndSlot  && <div className={(consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,56,61,62].indexOf( consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) ? 'col-sm-4' : 'col-sm-6'}>
                                {item.requestText && <div className={item.customCls}>{item.requestText}</div>}
                                {item.slotText && <div className={item.customCls}>{item.slotText}</div>}
                            </div>
                        }
                        {
                            item.showActionStatus && <div className={(consumerServiceRequest && [SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,56,61,62].indexOf( consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) ? 'col-sm-4' : 'col-sm-6'}>
                                { item.actionText && <div className={item.customCls}>{ item.actionText}</div>}
                                { item.statusText && <div className={item.customCls}>{ item.statusText}</div>}
                                { item.actionTakenText && <div className={item.customCls}>{ item.actionTakenText}</div>}
                                { item.actionStatusDate && <div className={item.customCls}>On { item.actionStatusDate}</div>}
                            </div>
                        }
                        {
                            item.showLogsAndRemark  && <div className="col-sm-4">
                                <div>
                                    {item.currentLogText && <div className={item.customCls}>{item.currentLogText}</div>}
                                    {item.exceptionLogsText && <div className={item.customCls}>{item.exceptionLogsText}</div>}
                                    {item.remarkText && <div className={item.customCls}>{item.remarkText}</div>}
                                </div>
                                    {item.ratingText && <div className={item.customCls}>{item.ratingText} {item.ratings}  </div>}  
                            </div>
                        }
                        {
                            item.showAssignedEngineer &&
                            <div className='col-sm-4'>
                                <div className='requestMT'>
                                    {item.assignedEngineerText && <div className={item.customCls}>{item.assignedEngineerText}</div>}
                                    {item.consumerName && <div className={item.customCls}>{item.consumerName}</div>}
                                    {item.engineerName && <div className={item.customCls}>{item.engineerName}</div>}
                                </div>
                            </div>
                        }
                        </>
                    ))
                }
                </div>
            </div>

    )
}
export default ViewMastHeadDetails;
