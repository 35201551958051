import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {clone, forEach, values, cloneDeep ,filter} from 'lodash';
import {Loader} from 'sfy-react';
import {displayError} from '../../Utility/Utils';
import Swal from "sweetalert2";
import './FlagEscalation.scss'
import FlagEscalationModal from './FlagEscalationModal';

const FlagEscalation = (props) => {
	const {accessFn,setFlagRequest,localString,apiCall,flagCSRID,refreshRequests,consumerServiceRequests} = props;
	const [showLoader, setLoader] = useState(false);
	const [escaltionArray, setEscaltionArray] = useState([])
	const [editEscalation, setEditEscalation] = useState(false);
	const [showFlagEscalationModal, setShowFlagEscalationModal] = useState(true);
	const [disableSave, setDisableSave] = useState(false);
	

	// useEffect(()=>{
	// 	escaltionArray && escaltionArray.map(escaltionType=>{
	// 		if(escaltionType.IsSaved){
	// 			setDisableSave(true)
	// 		}else{
	// 			setDisableSave(false)
	// 		}
	// 	})
	// },[])
	
	const getEscalation = () => {
		setLoader(true);
		let data = {
			ConsumerServiceRequestID:flagCSRID ,
			// escalations:escalationData         
		}

		let url = "getEscalationsByCsrID"
		// if(editEscalation){
		//     url = "getEscalations"
		// }
		apiCall({
			url:url,
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let flagResponce = result.data && result.data.escalations.map(flag=>{
						flag.IsSaved = (!flag.IsSaved? false : true);
						if(Array.isArray(flag.EscalationSource) && flag.EscalationSource.length > 0){
							flag.EscalationSource.forEach(item=>{
								item.IsSaved = (!item.IsSaved? false : true);
								if(Array.isArray(item.EscalationSourceMedia) && item.EscalationSourceMedia.length >0 ){
									item.EscalationSourceMedia.forEach(inner=>{
										inner.IsSaved = (!inner.IsSaved? false :true);
									})
								}
							})
						}
						return flag;
					})
					setEscaltionArray(flagResponce)
					// if(self.editEscalation){
					//     self.escalationArray = result.data.escalations;
					// }else{
					//     self.escalationArray = result.data;
					// }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}
	
	const cancelFunction = () =>{
		setShowFlagEscalationModal(false)
		setFlagRequest(false);
	}

	// const openflagRequestsModal = (e,csrID)=>{
	//     // self.disableSave = false;
	//     if(['Service completed','Service completed and rated','Repair cancel','Claim withdrawn','Service cancel'].indexOf(e.item.Status) > -1){
	//         editEscalation = false;
	//     }else if(accessFn('EDIT_REQUEST_ESCALATION')){
	//         editEscalation = true;
	//     }
	//     if(e){
	//         self.currentCSRID = e.item.ConsumerServiceRequestID;
	//         if(self.editEscalation || (!self.editEscalation && e.item.escalations.length)) {
	//             self.getEscalation();
	//         }          
	//     }else{
	//         self.currentCSRID = csrID;
	//         self.escalationArray = _.cloneDeep(escalationArrayData);
	//         $('#flagRequestsModal').modal('show');
	//     }
	// }



	const updateFlags = () => {
		let escalationData=[];
		let data = {}
		setLoader(true);
		escaltionArray && escaltionArray.forEach(escalationType=>{
			if(escalationType.IsSaved){
				data.EscalationTypeID = escalationType.EscalationTypeID;
				data.IsActive = escalationType.IsSaved
			}
			delete data.ConsumerservicerequestEscalationMappingID;
			if(escalationType.EscalationSource && escalationType.EscalationSource.length > 0){
				escalationType.EscalationSource && escalationType.EscalationSource.forEach(escalationSource=>{
					if(escalationSource.IsSaved){
						data.EscalationSourceID = escalationSource.EscalationSourceID;
						delete data.EscalationSourceMediaID;
						data.IsActive = escalationSource.IsSaved;
						if(data.IsActive){
							let temp = clone(data);
							escalationData.push(temp);
						}
					}
					if(escalationSource.EscalationSourceMedia && escalationSource.EscalationSourceMedia.length > 0){
						escalationSource.EscalationSourceMedia && escalationSource.EscalationSourceMedia.forEach(escalationSourceMedia=>{
							if(escalationSourceMedia.IsSaved){
								data.EscalationSourceMediaID = escalationSourceMedia.EscalationSourceMediaID;
								data.IsActive = escalationSourceMedia.IsSaved;
								if(data.IsActive){
									let temp = clone(data);
									escalationData.push(temp);
								}
							}
						})
					}
				})
				if(data.IsActive){
					let temp = clone(data);
					escalationData.push(temp);
				}else{
					consumerServiceRequests && consumerServiceRequests.forEach(escalationsData=>{
						if(flagCSRID == escalationsData.ConsumerServiceRequestID){
							escalationsData.escalations.forEach(escalation=>{
								if(escalationType.EscalationName == escalation.EscalationType.EscalationName){
									if(!data.IsActive){
										data.ConsumerservicerequestEscalationMappingID = escalation.ConsumerservicerequestEscalationMappingID;
										data.IsActive = false;
										delete data.EscalationTypeID;
										let temp = clone(data);
										escalationData.push(temp);
									}
								}
							})
						}
					})
				}
			}else if(escalationType.EscalationSource && escalationType.EscalationSource.length ==0){
				data.EscalationTypeID = escalationType.EscalationTypeID;
				data.IsActive = escalationType.IsSaved
				delete data.EscalationSourceMediaID
				delete data.EscalationSourceID;
				delete data.ConsumerservicerequestEscalationMappingID;
				if(data.IsActive){
					let temp = clone(data);
					escalationData.push(temp);
					setDisableSave(false);
				}else{
					setDisableSave(false);
					consumerServiceRequests && consumerServiceRequests.forEach(escalationsData=>{
						if(flagCSRID == escalationsData.ConsumerServiceRequestID){
							escalationsData.escalations.forEach(escalation=>{
								if(escalationType.EscalationName == escalation.EscalationType.EscalationName){
									if(!data.IsActive){
										data.ConsumerservicerequestEscalationMappingID = escalation.ConsumerservicerequestEscalationMappingID;
										data.IsActive = false
										delete data.EscalationTypeID;
										let temp = clone(data);
										escalationData.push(temp);
									}
								}
							})
						}
					})
				}
			}
		})
		apiCall({
			url:"createOrUpdateEscalationServiceRequest",
			data: {
				ConsumerServiceRequestID:flagCSRID,
				'escalations':escalationData
			},
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					refreshRequests()
					setShowFlagEscalationModal(false);
					Swal.fire({
						icon:"success",
						text:"Updated Successfully"
					})
					setFlagRequest(false);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}


	useEffect(() => {
		getEscalation()
	}, [])

	// useEffect(() => {
	//     if(accessFn('EDIT_REQUEST_ESCALATION')){
	//         setEditEscalation(true)
	//     }
	// }, [accessFn])
	const selectFlagEscalation = (flag)=>{
		let flagArr = escaltionArray && escaltionArray.map(item=>{
			let data = {...item}
			if(flag.EscalationTypeID === data.EscalationTypeID){
				data.IsSaved = !data.IsSaved
			}
			if(!data.IsSaved){
				if(Array.isArray(item.EscalationSource) && item.EscalationSource.length > 0){
					item.EscalationSource.forEach(inner=>{
						inner.IsSaved = false;
						if(Array.isArray(inner.EscalationSourceMedia) && inner.EscalationSourceMedia.length >0 ){
							inner.EscalationSourceMedia.forEach(media=>{
								media.IsSaved = false;
							})
						}
					})
				}
			}
			return data;
		})
		setEscaltionArray(flagArr)
	}

	const selectEscalationSource = (innerSource) =>{
		let arr =[];
		escaltionArray && escaltionArray.map(outer=>{
			let obj = {...outer};
			let subArr = [];
			obj && obj.EscalationSource.map(inner=>{
				let data = {...inner}
				if(innerSource.EscalationSourceID === data.EscalationSourceID){
					data.IsSaved = !data.IsSaved
				}
				if(!data.IsSaved){
					if(Array.isArray(inner.EscalationSourceMedia) && inner.EscalationSourceMedia.length >0 ){
						inner.EscalationSourceMedia.forEach(media=>{
							media.IsSaved = false;
						})
					}
				}
				subArr.push(data); 
			})
			obj.EscalationSource = subArr;
			arr.push(obj);
		})
		setEscaltionArray(arr)
	}

	const selectEscalationType = (escaltionArrays,escalationType)=>{
		setDisableSave(false)
		escalationType.IsSaved = !escalationType.IsSaved;
		if(!escalationType.IsSaved){
			let escalationSource = cloneDeep(escalationType.EscalationSource);
			escalationSource && escalationSource.forEach(escalationSourceType=>{
				escalationSourceType.IsSaved = false;
				escalationSource.EscalationSourceMedia && escalationSource.EscalationSourceMedia.forEach(escalationSourceMedia=>{
					escalationSourceMedia.IsSaved = false;
				})
			})
			escalationType.EscalationSource = cloneDeep(escalationSource);
		}
		let selectedEscalationsWithSource = escaltionArrays.filter(o=>{
			return o.IsSaved && o.EscalationSource && o.EscalationSource.length 
		})
		selectedEscalationsWithSource.forEach(escalationType=>{
			let selectedEscalationSource = escalationType.EscalationSource.filter(p=>{
				return p.IsSaved 
			})
			if (selectedEscalationSource && selectedEscalationSource.length > 0 ) {
				let selectedEscalationsWithChild = escalationType.EscalationSource.filter(o=>{
					return o.IsSaved && o.EscalationSourceMedia && o.EscalationSourceMedia.length > 0
				})
                if(selectedEscalationsWithChild && selectedEscalationsWithChild.length > 0){
					selectedEscalationsWithChild.forEach(o=>{
						let selectedChildEscalations = filter(o.EscalationSourceMedia, function(p) {
                            return p.IsSaved
                        })
                        if (selectedChildEscalations && selectedChildEscalations.length > 0 ) {

                        } else {
							setDisableSave(true)
                        }
					})
                }
            } else {
				setDisableSave(true)
            }
		})
    }

	const selectEscalationSourceValidation = function(escalationSourceArray,escalationSource){
		setDisableSave(false);
        escalationSource.IsSaved = !escalationSource.IsSaved;
        if(!escalationSource.IsSaved){
			let escalationSourceMedia = cloneDeep(escalationSource.EscalationSourceMedia);
			escalationSourceMedia && escalationSourceMedia.forEach(escalationSourceMediaType=>{
				escalationSourceMediaType.IsSaved = false;
			})
			escalationSource.EscalationSourceMedia = cloneDeep(escalationSourceMedia);
        }
		let selectedEscalationSource = escalationSourceArray && escalationSourceArray.filter(p=>{
			return p.IsSaved
		})
        
        if (selectedEscalationSource && selectedEscalationSource.length > 0 ) {
            let selectedEscalationsWithChild = escalationSourceArray && escalationSourceArray.filter(o=>{
				return o.IsSaved && o.EscalationSourceMedia && o.EscalationSourceMedia.length > 0
			})
			
            if(selectedEscalationsWithChild && selectedEscalationsWithChild.length > 0){
				selectedEscalationsWithChild.forEach(o=>{
					let selectedChildEscalations = filter(o.EscalationSourceMedia, function(p) {
                        return p.IsSaved
                    })
                    if (selectedChildEscalations && selectedChildEscalations.length > 0 ) {

                    } else {
                        setDisableSave(true);
                    }
				})
            }
        } else {
            setDisableSave(true);
        }
    }
    const selectEscalationMediaValidation = (escalationSourceMediaArray,escalationSourceMedia)=>{
		escalationSourceMedia.IsSaved = !escalationSourceMedia.IsSaved;
        let selectedCount = 0;
		escalationSourceMediaArray && escalationSourceMediaArray.forEach(escalationSourceMediaType=>{
			if(escalationSourceMediaType.IsSaved){
                selectedCount++;
            }
		})
        if(selectedCount < 1){
			setDisableSave(true)
        }else{
			setDisableSave(false)
        }
    }
	const selectEscalationMedia = (mediaFlag) =>{
		let arr =[];
		escaltionArray && escaltionArray.map(outer=>{
			let obj = {...outer};
			let subArr = [];
			obj && obj.EscalationSource.map(inner=>{
				let media = {...inner}
				let thirdArr = [];
					media && media.EscalationSourceMedia.map(third=>{
						let thirdStep = {...third}
						if(mediaFlag.EscalationSourceMediaID === thirdStep.EscalationSourceMediaID){
							thirdStep.IsSaved = !thirdStep.IsSaved
						}
						thirdArr.push(thirdStep);
					}) 
				media.EscalationSourceMedia = thirdArr;
				subArr.push(media)
			})
			obj.EscalationSource = subArr;
			arr.push(obj);
		})
		setEscaltionArray(arr)
	}

	return (
		<>
		{ showLoader && ReactDOM.createPortal(
			<Loader />,
			document.getElementById("loader-root")
		)}
		<div className="flag-escalation">
			<FlagEscalationModal {...props} selectEscalationMedia={selectEscalationMedia} selectFlagEscalation={selectFlagEscalation} selectEscalationSource={selectEscalationSource} cancelFunction={cancelFunction} escaltionArray={escaltionArray} updateFlags={updateFlags} showFlagEscalationModal={showFlagEscalationModal} disableSave={disableSave} setDisableSave={setDisableSave}
			selectEscalationType={selectEscalationType}
			selectEscalationSourceValidation={selectEscalationSourceValidation}
			selectEscalationMediaValidation={selectEscalationMediaValidation}
			></FlagEscalationModal>
		</div>
		</>
	)
}

export default FlagEscalation
