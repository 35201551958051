import React from 'react';

import RecursiveComponent from './RecursiveComponent';

import './QuestionLookup.scss'

export default function QuestionLookup(props) {
    const {
        localString, mainQuery, questionDetails, setQuestionDetails
    } = props;

    // console.log('questionDetails', questionDetails);

    return (
        <div className='queryBuilder'>
            <div className="row">
                <div className="col-sm-12 text-center repair-checklist-header">
                    <h4>{localString("Guided Troubleshooting Details")}</h4>
                </div>
				<div className=''>
					{
						questionDetails && (questionDetails.length > 0) && questionDetails.map((questionObj, parentIndex) => <>
							{
								questionObj.trees && (questionObj.trees.length > 0) && questionObj.trees.map((treeObj, treeIndex) => <>
									{
										treeObj && treeObj.questions && (treeObj.questions.length > 0) && <>
											<RecursiveComponent 
												index={treeIndex}
												questionArr={treeObj.questions}
												reference={`[${parentIndex}]['trees'][${treeIndex}]['questions']`}
												mainQuery={mainQuery}
												localString={localString}
												questionDetails={questionDetails} setQuestionDetails={setQuestionDetails}
											/>
										</>
									}
								</>)
							}
						</>)
					}
				</div>
            </div>
        </div>
    )
}