
import React from 'react';
import {Table} from 'sfy-react';
import {returnLocalDateTimeYear} from '../../../../../Utility/Utils';

const TaskListTable = (props) => {
	const {taskListArr, inEditModeRemark, localString, overAllTaskValues, editRemark, showTaskCloseModal} = props;
	const classObject = {
		table:"table table-striped complaint-details-table",
		th:"col-md-1"
	}
	return (
		<Table className={classObject}>
			<Table.Header>
				<Table.Row>
					<Table.Heading className={classObject.th}>{localString("Ref")}</Table.Heading>
					<Table.Heading className={{th:"col-md-3"}}>{localString("Task")}</Table.Heading>
					<Table.Heading className={{th:"col-md-4"}}>{localString("Remark")}</Table.Heading>
					<Table.Heading className={classObject.th}>{localString("Status")}</Table.Heading>
					<Table.Heading className={classObject.th}>{localString("Created On")}</Table.Heading>
					<Table.Heading className={classObject.th}>{localString("Action")}</Table.Heading>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{
					taskListArr.map((item,i)=>{
						return(
							<Table.Row>
								<Table.Data>{item.taskReferenceId || "-"}</Table.Data>
								<Table.Data> <span className="task-remark" onClick={()=>overAllTaskValues(item)}> <strong className="custom-text-color">{item.title}</strong></span></Table.Data>
								{
									!inEditModeRemark && 
									<td>
										<div className="break-word">{item.remark || "-"}</div>
										{
											item.status == 'Open' && 
											<span className="task-remark"><span  className="glyphicon glyphicon-edit custom-icon-color" onClick={()=>editRemark(item)}></span></span>
										}
									</td>
								}
								<td className="">{item.status || "-"}</td>
								<td>{returnLocalDateTimeYear(item.createdDate)}</td>
								<td>
									{
										item.status === 'Open' &&   
										<a href="#" className="btn button-servify" onClick={()=>showTaskCloseModal(item)}>{localString("Close")}</a>
									}
									{
										item.status === 'Closed' && 
										<span className="label label-default">{localString("Already Closed")}</span>
									}
								</td>
							</Table.Row>
						)
					})
				}
			</Table.Body>
		</Table>
	)
}

export default TaskListTable
