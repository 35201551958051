import React from 'react';
import {Table,Input,Button,Modal} from 'sfy-react';
import './RequestTable.scss'

const RequestTable = (props) => {
    const { accessFn, requestLabels, localString, inputData, modalArray, saveIssueDescription, saveDiagnosisDetails, showModal,
		closeModal, sendExternalRef, canUpdateServiceRequest, consumerServiceRequest} = props;
	const classObject = {
        table:"table table-striped complaint-details-table"
    }
        return (
			<>
            <div className="request-panel consumer-details-height">
            {
                <Table className={classObject}>
                    <Table.Body>
                    {
                        requestLabels && requestLabels.map(item=>{
                            return(
                                <>
                                {
                                    item.showLabel && 
                                    <Table.Row >
                                        <Table.Data className="table-keys text-capitalize custom-table-data">{item.labelName}</Table.Data>
										{
											item.iconCheck ? 
											<Table.Data>{item.value }
												<span 
													className="glyphicon glyphicon-edit" 
													onClick={item.iconClicked}>
												</span>
											</Table.Data>
											:
											item.currency ?<Table.Data dangerouslySetInnerHTML={{__html: item.currency +" " }}>{item.value }</Table.Data> :
											<Table.Data>{item.value }</Table.Data>
										}
                                    </Table.Row>
                                }
                                {
                                    item.showLink &&
                                    <Table.Row >
                                        <Table.Data className="table-keys text-capitalize custom-table-data">{item.labelName}</Table.Data>
                                        <Table.Data><a href={item.url} target="_blank">{localString(item.linkText)}</a></Table.Data>
                                    </Table.Row>
                                }
                                {
                                    !!item.spanCurrency &&
                                    <Table.Row >
                                        <Table.Data className="table-keys text-capitalize custom-table-data">{item.labelName}</Table.Data>
                                        <Table.Data>
                                            <span className="currency" dangerouslySetInnerHTML={{__html: item.currency +" " }}></span>
                                            {item.value}
                                        </Table.Data>
                                    </Table.Row>
                                }
                                {
                                    item.logistics &&
                                    <Table.Row >
                                        <Table.Data className="table-keys text-capitalize custom-table-data">{item.labelName}</Table.Data>
                                        <Table.Data>
										{
											Object.keys(item.value).map(key =>{
												return(
												<span>
													<strong>{localString(key)}: </strong>{item.value[key]} <br/>
												</span>
												)
											})
										}
                                        </Table.Data>
                                    </Table.Row>
                                }
                                {
                                    item.showList && 
                                    <Table.Row >
                                        <Table.Data className="table-keys text-capitalize custom-table-data">{item.labelName}</Table.Data>
                                        <Table.Data>
                                        <ul style={{listStyleType: "none", paddingLeft: "0px"}} >
                                            {
                                                item.value && item.value.map(list=>{
                                                    return <li>{list.IssueText}{list.IssueRemark && <span>{list.IssueRemark}</span>}</li>
                                                })
                                            }
                                        </ul>
                                        </Table.Data>
                                    </Table.Row>
                                }
								{
                                    item.showCharges && 
									item.value && item.value.map((data, index)=>{
										return(
											<Table.Row>
												<Table.Data className="table-keys text-capitalize custom-table-data">{data.Name}</Table.Data>
												<Table.Data>{data.TotalAmount}</Table.Data>
											</Table.Row>
										)
									})
                                }
                                {
                                    item.issueList &&
                                    <Table.Row >
                                        <Table.Data className="table-keys text-capitalize custom-table-data">{item.labelName}</Table.Data>
                                        {
                                            !item.editMode ?
                                            <Table.Data>
                                            <>
                                                <p className="table-keys text-capitalize custom-table-data">{localString("Customer's Version")}:</p>
                                                {
                                                    item.description && 
                                                    <p>
                                                        {item.description}
                                                        {
                                                            item.complaintDetails && 
                                                            <span 
                                                                className={item.iconClass} 
                                                                title={item.title}
                                                                onClick={item.editDescription}>
                                                            </span>
                                                        }
                                                    </p>
                                                }
                                                {
                                                    item.partnerServiceLocationNotes && 
                                                    <p className="table-keys text-capitalize custom-table-data">{localString("Telecon Version")}:</p>
                                                }
                                                {
                                                    item.partnerServiceLocationNotes && 
                                                    <p>{item.partnerServiceLocationNotes || '-'}</p>
                                                }
                                            </>
                                            </Table.Data>
                                            :
                                            <Table.Data>
                                                <form onsubmit={saveIssueDescription} name="descriptionForm">
													<Input
														value={inputData[item.dataKey]}
														handleOnChange={(e) => {
															item.onInputFunc(e.target.value);
														}}
													/>
													<Button handleOnClick={saveIssueDescription} text={localString('Save')} className='btn button-servify'></Button>
													<Button handleOnClick={item.cancelButtonFunction} text={localString('Cancel')} className='btn button-servify'></Button>
                                                </form>
                                            </Table.Data>
                                        }
                                    </Table.Row>
                                }
								{
                                    item.showDeviceCosmeticCondition && 
                                    <Table.Row >
                                        <Table.Data className="table-keys text-capitalize custom-table-data">{item.labelName}</Table.Data>
                                        <Table.Data>
											<div className='row'>
												<div className='col-sm-12 paddingB10'>
													<div className='bold paddingB5'>{localString('Cosmetic Condition of the Device (Inward)')}:</div>
													<p>{consumerServiceRequest.InwardCosmeticCondition || '-'}</p>
												</div>
												<div className='col-sm-12'>
													<div className='bold paddingB5'>{localString('Cosmetic Condition of the Device (Outward)')}:</div>
													<p>{consumerServiceRequest.OutwardCosmeticCondition || '-'}</p>
												</div>
											</div>
                                        </Table.Data>
                                    </Table.Row>
                                }
								{
									item.coverage && 
									<Table.Row >
                                        <Table.Data className="table-keys text-capitalize custom-table-data">{item.labelName}</Table.Data>
                                        <Table.Data>
										<div className="col-sm-6 padding-none">
											{
												item.selectedCoverage && item.selectedCoverage.CoverageType && 
												localString("Coverage Type") + " : " + item.selectedCoverageType
											}
											<br/>
											{
												item.selectedCoverage && (item.selectedCoverage.NoOfRepairsUsed || item.selectedCoverage.NoOfRepairsUsed == 0) && 
												localString("No. of Repairs Used") + " : " + item.selectedCoverage.NoOfRepairsUsed
											}
											<br/>
											{
												item.selectedCoverage && item.selectedCoverage.NoOfRepairsAllowed && 
												localString("No. of Repairs Allowed") + " : " + (item.selectedCoverage.NoOfRepairsAllowed) + (item.selectedCoverage.CoverageType == 'EW' ? '(' + localString('Upto covered value') + ')' : "")
											}
										</div>
                                        </Table.Data>
                                    </Table.Row>
								}
								{ 	!accessFn('READ_ONLY') && canUpdateServiceRequest &&
									item.showDiagnosis && 
									<Table.Row >
                                        <Table.Data className="table-keys text-capitalize custom-table-data">{item.labelName}</Table.Data>
                                        {
											!item.editDiagnosis && 
											<Table.Data>
												{ item.value && item.value } 
												{
													item.diagnosisInnerCheck && 
													<span 
														className={item.iconClass} 
														onClick={item.diagnosisFunction}>
													</span>
												}
											</Table.Data>
										}
										{
											item.editDiagnosis &&
											<Table.Data>
												<form onsubmit={saveDiagnosisDetails} name={item.diagnosisName}>
													<Input
														value={inputData[item.dataKey]}
														handleOnChange={(e) => {
															item.onInputFunc(e.target.value);
														}}
													/>
													{ 
														item.diagnosisStepsWords && 
														<div className="word-left">
															{item.diagnosisStepsWords}
														</div>
													}	
													<Button handleOnClick={saveDiagnosisDetails} text={localString('Save')} className='btn button-servify'></Button>
													<Button handleOnClick={item.cancelButtonFunction} text={localString('Cancel')} className='btn button-servify'></Button>
												</form>
											</Table.Data>
										}
                                    </Table.Row>
								}
								{
									item.showEditNumber && 
									<Table.Row >
                                        <Table.Data className="table-keys text-capitalize custom-table-data">{item.labelName}</Table.Data>
                                        {
											!item.editRecord && 
											<Table.Data>
												<span>{item.value+" "}</span>
												{
													//accessFn('ACCEPT_RETURN_DEVICE') && 
													item.spanCheck 
													? <span className=" glyphicon glyphicon-edit" onClick={item.onEditClick}></span>
													: <span className=" glyphicon glyphicon-edit" onClick={item.onEditClick}></span>
												}
											</Table.Data>
										}
										{
											item.editRecord &&
											<Table.Data>
												<div>
													<Input
														value={inputData[item.dataKey]}
														handleOnChange={(e) => {
															item.onInputFunc(e.target.value);
														}}
														maxLength={item.maxLength}
													/>
													<Button handleOnClick={(e)=>item.saveClick(e)} text={localString('Save')} className='btn button-servify'></Button>
													<Button handleOnClick={item.cancelClick} text={localString('Cancel')} className='btn button-servify'></Button>
												</div>
											</Table.Data>
										}
                                    </Table.Row>
								}
								{
									item.showLogAttribute &&
									<Table.Row>
										<Table.Data className="table-keys text-capitalize custom-table-data">{item.labelName}:</Table.Data>
										<Table.Data>
										{
											item.value && item.value.map((data)=>{
												return(
													<div>
													{
														data.Status &&
														<p>{localString("Status")} : {data.Status}</p>
													}
													{
														data.AttrValue && 
														<p>{localString("Remark")} : {data.AttrValue}</p>
													}
													</div>
												)
											})
										}
										</Table.Data>
									</Table.Row>
								}
                                </>
                            )
                        })
                    }
                    </Table.Body>
                </Table>
            }
			</div>
			<div className="external-reference-modal">
			<Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className="external-ref-id">
				<Modal.Header>
					<h2> {localString("Enter Reference ID")} </h2>
				</Modal.Header>
				<Modal.Body>
					{
						modalArray && modalArray.map(item=>{
							return(
								<form onsubmit={sendExternalRef} name="sendExternalRef"  >
									<Table>
										<Table.Row>
											<Table.Data className="padding10">{localString("Current Request Reference ID")}:</Table.Data>
											<Table.Data className="padding10">{item.value}</Table.Data>
										</Table.Row>
										<Table.Row>
											<Table.Data className="padding10">{localString("External Reference ID")}:</Table.Data>
											<Table.Data className="padding10">
												<Input
													value={inputData[item.dataKey]}
													className="form-control charges-form-input"
													handleOnChange={(e)=>{
														item.onInputFunc(e.target.value)
													}}
													maxLength={30}
												></Input>
											</Table.Data>
										</Table.Row>
									</Table>
									<div className='row form-group-filter-request'>
										<Button handleOnClick={sendExternalRef} text={localString('Save')} className='btn button-servify pull-right'></Button>
									</div>
								</form>
							)
						})
					}
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
            </div>
			</>
        )
}

export default RequestTable
