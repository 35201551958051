import React from "react";
import { Table, Button, Pagination } from "sfy-react";
// import "./LogisticsListData.scss";

const LogisticsListData = (props) => {
	const {
		headings,
		logisticsList,
		source,
		clsName,
		localString,
		accessFn,
	} = props;
	return (
		<div>
			<Table isResponsive>
				<Table.Header>
					<Table.Row>
						{headings &&
							headings.length > 0 &&
							headings.map((head, index) => {
								return (
									head.showHeading && (
										<Table.Heading
											key={index}
											className={clsName}
										>
											{head.title}
										</Table.Heading>
									)
								);
							})}
						<Table.Heading></Table.Heading>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{logisticsList &&
						logisticsList.logisticsRequests &&
						logisticsList.logisticsRequests.length > 0 &&
						logisticsList.logisticsRequests.map((item, index) => {
							return (
								<Table.Row key={index}>
									<Table.Data>
										{item.WaybillNumber
											? item.WaybillNumber
											: "-"}
									</Table.Data>
									<Table.Data>
										{item.ReferenceID
											? item.ReferenceID
											: "-"}
									</Table.Data>
									<Table.Data>
										{item.PickUpName
											? item.PickUpName
											: "-"}
									</Table.Data>
									<Table.Data>
										{item.PickUpAddress
											? item.PickUpAddress
											: "-"}
									</Table.Data>
									<Table.Data>
										{item.DropName ? item.DropName : "-"}
									</Table.Data>
									<Table.Data>
										{item.DropAddress
											? item.DropAddress
											: "-"}
									</Table.Data>
									<Table.Data>
										{item.StatusDisplayText
											? item.StatusDisplayText
											: "-"}
									</Table.Data>
									<Table.Data>
										{item.PickUpContactNo
											? item.PickUpContactNo
											: "-"}
									</Table.Data>
									<Table.Data>
										{item.DropContactNo
											? item.DropContactNo
											: "-"}
									</Table.Data>
									{accessFn("VIEW_LOGISTIC_REPORT") && (
										<Table.Data>
											<div className="button-container">
												<a
													href={"/logistics/view?id="+item.LogisticsRequestID}
													target="_blank"
													className="a-decor-none"
												>
													<Button
														text={localString(
															"View"
														)}
														className="btn button-servify"
													></Button>
												</a>
											</div>
										</Table.Data>
									)}
								</Table.Row>
							);
						})}
				</Table.Body>
			</Table>
			{
				//pagination.lastPage > 1 && <Pagination itemsPerPage={pagination.itemsPerPage} pages={pagination.lastPage} totalItems={pagination.totalItems} activePage={pagination.currentPage} paginate={(pageNumber) => {setCurrentPage(pageNumber)}}/>
			}
		</div>
	);
};

export default LogisticsListData;
