import React from 'react';
import {Modal} from 'sfy-react';
import {returnLocalDateTimeYear} from '../../../../../Utility/Utils';
import './TaskList.scss'

const TaskListModal = (props) => {
	const {taskAllValue, localString, taskListDetail, closeTaskListModal} = props;

	return (
		<>
		{
			<Modal showModal={taskAllValue} closeModal={closeTaskListModal} showCloseBtn={true} className="task-detail-modal">
				<Modal.Header>
					<h4>
						{localString("Task Details of ")}
						{localString("Reference Id :")}
						<strong>{taskListDetail && taskListDetail.taskexternalReferenceId}</strong>
					</h4>
				</Modal.Header>
				<Modal.Body>    
				<div className="request-filter">
					<div className="row">
						<div className="col-md-2">
							<label for="">{localString("Status")}</label>
							<div className="">
								<span className={"label " + taskListDetail.taskStatusClass }>{taskListDetail && taskListDetail.taskStatus}</span>
							</div>
						</div>

						<div className="col-md-3">
						<label for="">{localString("Created Date")}</label>
						<p>{returnLocalDateTimeYear(taskListDetail && taskListDetail.taskcreatedDate)}</p>
						</div>

						<div className="col-md-3">
						<label for="" className="">{localString("Updated Date")}</label>
						<p>{(taskListDetail.taskupdatedDate ? returnLocalDateTimeYear(taskListDetail && taskListDetail.taskupdatedDate) : "N/A")}</p>
						</div>

						<div className="col-md-3">
						<label for="" className="">{localString("Closed Date")}</label>
						<p>{returnLocalDateTimeYear(taskListDetail && taskListDetail.taskclosedDate)}</p>
						</div>
					</div>
					<div className="">
						<hr/>
					</div>
					<div className="row">
						<div className="col-md-6">
						<label for="">{localString("Task")}</label>
						</div>
						<div className="col-md-12">
						<p>{taskListDetail && taskListDetail.taskTitle || "-"}</p>
						</div>
					</div>
					<div className="">
						<label for="">{localString("Description")}</label>
						<p>{taskListDetail && taskListDetail.taskDesc || "-"}</p>
					</div>
					<div className="">
						<label for="">{localString("Remark")}</label>
						<p>{taskListDetail && taskListDetail.taskRemark || "-"}</p>
					</div>
				</div>
				
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
		}
		</>
	)
}

export default TaskListModal
