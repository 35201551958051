import turkish from './languages/turkish';
import chinese from './languages/chinese';
import english from './languages/english';
import french from './languages/french';
import indonesian from './languages/indonesian';
import thai from './languages/thai';
import usenglish from './languages/us-english';
import czech from './languages/czech';
import germanat from './languages/germanat';
import polish from './languages/polish';
import romanian from './languages/romanian';
import spanish from './languages/spanish';
import spanishmx from './languages/spanishmx';

const languageTranslate = (languageCode) => {
	let lang = english;
	if(languageCode === 'tr-TR'){
		lang = turkish;
	}
	if(languageCode === 'zh-CN'){
		lang = chinese;
	}
	if(languageCode === 'fr-FR'){
		lang = french;
	}
	if(languageCode === 'id'){
		lang = indonesian;
	}
	if(languageCode === 'th'){
		lang = thai;
	}
	if(languageCode === 'en-US'){
		lang = usenglish;
	}
	if(languageCode === 'cs'){
		lang = czech;
	}
	if(languageCode === 'ro'){
		lang = romanian;
	}
	if(languageCode === 'de'){
		lang = germanat;
	}
	if(languageCode === 'pl'){
		lang = polish;
	}
	if(languageCode === 'es'){
		lang = spanish;
	}
	if(languageCode === 'es-MX'){
		lang = spanishmx;
	}
	const localString = (string) =>  {
		if(lang && lang[string]){
			return lang[string].text;
		}else{
			return string
		}
	};
	return localString;
}
export default languageTranslate;
