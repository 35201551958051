import React, { useState } from 'react'
import { Button, Input, Table } from 'sfy-react';
import Swal from 'sweetalert2';

import { displayError } from '../../../../../Utility/Utils';


function FinishedGoodItem(props) {
	const {
		partItem: charge, localString, apiCall, setLoader, getRequestById, editable
	} = props

	const [editMode, setEditMode] = useState(false);
	const [serialNumber, setSerialNumber] = useState('');

	const updateFGItem = (data) => {
		let reqObj = {
			PartEstimateTransactionID: data.partEstimate.PartEstimateTransactionID,
			updateData: {
				serialNumber: serialNumber
			}
		};
		setLoader(true);
		apiCall({
			url: 'updatePartEstimationTransactionDetails',
			data: reqObj,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					getRequestById();
					setEditMode(false);
					setSerialNumber('');
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	return (
		<>
			<Table.Row>
				<Table.Data className="w-70" >
					<div className='bold'>
						{localString(charge.Name)}
						{
							charge?.Remarks &&
							<span> - {charge.Remarks}</span>
						}
						{
							charge?.partEstimate?.PartCategoryName &&
							<span> - {charge.partEstimate.PartCategoryName}</span>
						}
						{
							charge?.partEstimate?.PartItemDescription &&
							<span> - {charge.partEstimate.PartItemDescription}</span>
						}
						{
							charge?.ChargeCode &&
							<span> - ({charge.ChargeCode})</span>
						}
						{(editable && !editMode) && 
							<span className="glyphicon glyphicon-edit editDeleteIcon" 
								onClick={() => {
									setEditMode(true);
									setSerialNumber(charge?.partEstimate?.SerialNumber || '')
								}}
							></span>}
					</div>
				</Table.Data>
				<Table.Data>
					{
						!editMode ? 
							<>{charge?.partEstimate?.SerialNumber || '--'}</> : 
							<div>
								<div>
									<Input
										value={serialNumber || ''}
										handleOnChange={(e) => {
											setSerialNumber(e.target.value);
										}}
										className={{
											containerClass: 'input-container',
											inputClass: 'input'
										}}
										maxLength={21}
									/>
								</div>
								<div className='float-right paddingT10'>
									<Button
										type='button'
										className="btn button-servify"
										handleOnClick={() => {
											setEditMode(false);
											setSerialNumber('');
										}}
										text={localString("Cancel")}
									/>
									<Button
										type='button'
										className="btn button-servify"
										handleOnClick={() => {
											updateFGItem(charge)
										}}
										isDisabled={!serialNumber}
										text={localString("Save")}
									/>
								</div>
							</div>
					}
				</Table.Data>
			</Table.Row>
		</>
	)
}

export default FinishedGoodItem