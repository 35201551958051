import React from 'react';
import {Dropdown,Button,Table} from 'sfy-react';

const SubmitIrisModalUI=(props)=>{
    const {localString,irisSelection,removeFromIris,cancelButtonText,submiIrisInputs,addIris,backToCloseVisit,updateJobDetails,submitIrisData,tableInput,selectedIrisActionFlag, showTextButton,submitIris}=props;
    return(
        <>
            <div className="col-sm-12 irisPadding iris-form">
                {submiIrisInputs && submiIrisInputs.length>0 && submiIrisInputs.map((iris, index) => {
                    return(
                            iris.showIris && <div className={`${iris.customCls} iris-main`}>
                            {
                                iris.type && iris.type == 'Dropdown' && 
                                <div className='iris-content'>
                                    <label className="input__label ">{iris.labelText}</label>
                                        {<Dropdown
                                            id='dropdown'
                                            key={index}
                                            value={submitIrisData[iris.dataKey]}
                                            options={iris.dropdownList}
                                            handleOnChange={(data) => {
                                                iris.oninputFunc(data,index);
                                            }}
                                            classObject={{
                                                container: iris.customClass,
                                            }}
                                            irisBy='value'
                                            showDownArrow={false}
                                            isDisabled={iris.isDisabled}
                                        >
                                            {iris.showIrisLoader &&<span className='input-loader form-control-feedback form-control-feedback-padded iris-symptom-loader'></span>}
                                        </Dropdown>
                                    }
                                    
                                </div>
                            }
                            <div>
                            
                        </div>
                    </div>
                    )	
                })}
                <div>
                    <Button className="btn button-servify button-stack pull-right"  handleOnClick={addIris}  isDisabled={selectedIrisActionFlag}>{localString("Add")}</Button>
                </div>
                <div className='row table-iris '>
                    <div className='col-sm-12 marginT10 '>
                        <Table isResponsive>
                            <Table.Header>
                                <Table.Row>
                                    {
                                        tableInput && tableInput.map((item,index)=>(
                                            <Table.Heading key={index}>{item.labelText}</Table.Heading>
                                        ))
                                    } 

                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {irisSelection && irisSelection.length >0 && irisSelection.map((iris , index)=>(
                                    <Table.Row>
                                        {iris.Symptom &&<Table.Data>{iris.Symptom}</Table.Data>}
                                        {iris.Fault &&<Table.Data>{iris.Fault}</Table.Data>}
                                        {iris.Action &&<Table.Data>{iris.Action}</Table.Data>}
                                        <Table.Data> <Button className = "glyphicon glyphicon-trash remove-btn" handleOnClick={()=>removeFromIris(iris.id)} ></Button></Table.Data>
                                    </Table.Row>
                                ))}
                            
                            </Table.Body>
                        </Table>
                    </div>
                </div>
                <div className='modal-footer  paddingR0'>
                    {cancelButtonText &&<Button className='btn button-servify button-stack' handleOnClick={backToCloseVisit} >{localString("Back")}</Button>}
                    <Button className='btn button-servify button-stack' handleOnClick = {showTextButton ? submitIris :updateJobDetails} isDisabled={!(irisSelection && irisSelection.length > 0)} >{ showTextButton ?localString("Submit IRIS"):localString("Complete Visit And Submit IRIS")}</Button>
                </div>
        </div>
        </>
    )
}
export default SubmitIrisModalUI;
