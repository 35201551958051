import React, { useState } from 'react';
import './MultiStepProgressBar.scss'

export default function MultiStepProgressBar({ activeStep = 0, steps = [] }) {
	return (
		<div className='multiStepProgress'>
			<div className="row">
				<div className="col-md-12">
					<ol className="orderprogress">
						{
							steps && steps.length && steps.map((step, k) => {
								return (
									<li className="todo" key={k}>
										<div className={`progressbar-checkmark auto-margin ${(k < activeStep) ? '' : 'hidden' }`} style={{ marginLeft: '50px' }}></div>
										<span className={steps[k + 1] ? 'orderprogressstep' : 'orderprogressstep-last'}><span>•</span></span>
										{ step && <span className="orderprogressname">{step}</span>}
									</li>
								)
							})
						}
					</ol>
				</div>
			</div>
		</div>
	);
}
