import React from 'react';
import {Button} from 'sfy-react';
import './PageHeader.scss';


const PageHeader = (props) => {
	const {titleHeader,localString,buttonClass,accessFn,downloadButtonArray,linkText,hrefID,linkClass,showLink,btnClass,headerButtonArray} = props;
	return (
		<div className="content-head">
			<div className="row content-header-background"> 
				<div className="col-xs-12">
					<div className='col-xs-4 col-md-6 col-lg-6 col-sm-6 '>
						<h2 className={`title-requests ${buttonClass}`}>{localString ? localString(titleHeader) : titleHeader}</h2>
						{
							downloadButtonArray && downloadButtonArray.length > 0 && downloadButtonArray.map(val=>{
								return(
									val.showIcon && 
									<Button className={val.btnClassName} onClick={val.handleOnClick} title={val.tooltip}>
										{
											accessFn(val.accessFunction) && <div className={val.iconClassName} ></div>
										}
									</Button>
								)
							})
						}
					</div>
					<div className='col-xs-8 col-md-6 col-lg-6 col-sm-6 pull-right'>
						{
							headerButtonArray && headerButtonArray.length>0 && headerButtonArray.map((val, index)=>{
								return(
									<>
										{
											val.showButton &&
											<Button className={btnClass} onClick={val.onButtonClick}>
											{
												val.btnIcon ? <span className={val.btnIcon}> </span> : ""
											}{val.buttonText} 
											</Button>
										}
										{
											val.showPSL &&
											<div className={val.mainDivClass}>
												<div className={val.innerDivLabelClass}>{val.PSLName}</div>
												{
													val.PSLName && 
													<div className={val.linkTextClass} onClick={val.onClickEvent}>{localString("Change")}</div>
												}
											</div>
										}
									</>
								)
							})
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default PageHeader;
