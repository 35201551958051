import React, { useState, useEffect, useMemo } from "react";
import { Button, Dropdown, Input, Checkbox } from "sfy-react";
import Swal from "sweetalert2";
import _ from "lodash";

import QuestionLookup from "../../../../../components/QuestionLookup/QuestionLookup";
import { displayError, updateQuestions } from "../../../../../Utility/Utils";
import "./NonHardwareClosure.scss";

const outcomes = {
	max: 4,
};

export default function NonHardwareClosure(props) {
	const {
		localString,
		accessFn,
		apiCall,
		consumerServiceRequest,
		setLoader,
		getRequestById,
		inputStates,
		setInputStates,
		disabledState,
		setDisabledState,
		showSKUSelection,
		setShowSKUSelection,
		showSymptomsCode,
		setShowSymptomsCode,
		SKUSelected,
		setSKUSelected,
		selectedSKU,
		setSelectedSKU,
		selectedSymptom,
		setSelectedSymptom,
		compModItem,
		setCompModItem,
		selectedIssue,
		setSelectedIssue,
		closeNonHardwareClosureModal,
	} = props;
	
	const qFilter = JSON.parse(props.qFilter);
	const isNonSerializedProduct =
		consumerServiceRequest.consumerProduct &&
		consumerServiceRequest.consumerProduct.productConfig
			? consumerServiceRequest.consumerProduct.productConfig
					.isSerializedProduct === false
			: false;
	const isStaticNonSerializedProduct =
		consumerServiceRequest.isStaticNonSerializedProduct;

	const [getSymptomCodesCalled, setGetSymptomCodesCalled] = useState(false);
	const [getCompModListCalled, setGetCompModListCalled] = useState(false);
	const [repairOutcomes, setRepairOutcomes] = useState([]);
	const [selectedOutcomes, setSelectedOutcomes] = useState([]);

	const [skuResult, setSkuResult] = useState([]);
	const [allSymptoms, setAllSymptoms] = useState([]);
	const [allIssues, setAllIssues] = useState([]);
	const [compModList, setCompModList] = useState([]);

	// const [finishedId, setFinishedId] = useState("");
	const [mainQuery, setMainQuery] = useState([]);
	const [questionDetails, setQuestionDetails] = useState([]);
	
	// const enableClosureBtn = selectedOutcomes.length > 0 && (inputStates.delivery_notes !== "") && (inputStates.delivery_notes !== undefined) &&
	const enableClosureBtn = selectedOutcomes.length > 0 && (inputStates.delivery_notes) &&
	(!inputStates.isNTFRepair || (inputStates.isNTFRepair && selectedSymptom?.componentCode && selectedIssue?.code && compModItem?.modifierCode))

	const skuNameDropDownOptions = useMemo(() => {
		let arr = [];

		if (skuResult) {
			arr = skuResult.map((data) => ({
				...data,
				value: data.CodeValue,
			}));
		}

		return arr;
	}, []);

	const symptomcodeDropDownOptions = useMemo(() => {
		let arr = [];

		if (allSymptoms) {
			arr = allSymptoms.map((data) => ({
				...data,
				value: `${data.reportedSymptomCode} ${data.reportedSymptomDesc}`,
			}));
		}

		return arr;
	}, [allSymptoms]);

	const issuecodeDropDownOptions = useMemo(() => {
		let arr = [];

		if (allIssues) {
			arr = allIssues.map((data) => ({
				...data,
				value: `${data.reportedIssueCode} ${data.reportedIssueDesc}`,
			}));
		}

		return arr;
	}, [allIssues]);

	const comptiaModRepairDropDownOptions = useMemo(() => {
		let arr = [];

		if (compModList) {
			arr = compModList.map((data) => ({
				...data,
				value: `${data.modifierCode}-${data.comptiaDescription}`,
			}));
		}

		return arr;
	}, [compModList]);

	// Api calls Start
	const fetchRepairOutcomes = () => {
		let data = {
			ConsumerServiceRequestID:
				consumerServiceRequest.ConsumerServiceRequestID,
		};

		setLoader(true);
		apiCall({
			url: "fetchRepairOutcomes",
			method: "post",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					if (result.data.repairOutcomes.length > 0) {
						let _repairOutcomes = result.data.repairOutcomes;
						let _selectedOutcomes = _repairOutcomes.filter(
							(outcome) => {
								return outcome.IsSelected == true;
							}
						);
						setRepairOutcomes(_repairOutcomes);
						setSelectedOutcomes(_selectedOutcomes);
					}

					if (result.data.repairOutcomeRemarks) {
						setInputStates((prevData) => ({
							...prevData,
							delivery_notes: result.data.repairOutcomeRemarks,
						}));
					}
				} else {
					Swal.fire({
						title: "",
						text: localString(result.msg || "Something went wrong"),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	useEffect(() => {
		fetchRepairOutcomes();
	}, []);

	const getSKUForNonSerialized = () => {
		let data = {
			ProductID: consumerServiceRequest.consumerProduct.ProductID,
		};

		setLoader(true);
		apiCall({
			url: "getSkuByProductID",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					setSkuResult(result.data.skuResult);
				} else {
					Swal.fire({
						title: "",
						text: localString(displayError(result)),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	const updateNonSerializedDevice = (_selectedSKU) => {
		let data = {
			ConsumerServiceRequestID:
				consumerServiceRequest.ConsumerServiceRequestID,
			purchaseDate: consumerServiceRequest.consumerProduct.DateOfPurchase,
			productCode: _selectedSKU.ExternalReference,
			ConsumerProductID:
				consumerServiceRequest.consumerProduct.ConsumerProductID,
			ProductDescription:
				consumerServiceRequest.consumerProduct.product.ProductName,
			ConfigDescription: _selectedSKU.CodeValue,
			isSerializedProduct:
				consumerServiceRequest.consumerProduct &&
				consumerServiceRequest.consumerProduct.productConfig &&
				consumerServiceRequest.consumerProduct.productConfig
					.isSerializedProduct,
			isStaticNonSerializedProduct:
				consumerServiceRequest.isStaticNonSerializedProduct,
		};

		setLoader(true);
		apiCall({
			url: "updateNonSerializedDevice",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					// setFinishedId(result.data.finishedGoodID);
					setSKUSelected(true);
					createNTFChecked(inputStates.isNTFRepair);
				} else {
					Swal.fire({
						title: "",
						text: localString(displayError(result)),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	const getSymptomCodes = () => {
		setGetSymptomCodesCalled(true);
		let data = {
			ConsumerProductID:
				consumerServiceRequest.consumerProduct.ConsumerProductID,
		};

		setLoader(true);
		apiCall({
			url: "fetchReportedSymptoms",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					setAllSymptoms(result.data);
					setDisabledState((prevData) => ({
						...prevData,
						symptomcode: false,
					}));
				} else {
					Swal.fire({
						title: "",
						text: localString(displayError(result)),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	const getCompModList = () => {
		setGetCompModListCalled(true);

		setLoader(true);
		apiCall({
			url: "fetchCompTiaModifiers",
			method: "post",
			data: {},
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					setCompModList(result.data);
					setDisabledState((prevData) => ({
						...prevData,
						comptia_mod_Repair: false,
					}));
				} else {
					Swal.fire({
						title: "",
						text: localString(displayError(result)),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	const initIssueCode = (item) => {
		if (item) {
			let data = {
				reportedSymptomCode: item.reportedSymptomCode,
				ConsumerProductID:
					consumerServiceRequest.consumerProduct.ConsumerProductID,
				serialNumber: consumerServiceRequest.consumerProduct
					.AlternateUniqueKey
					? consumerServiceRequest.consumerProduct.AlternateUniqueKey
					: consumerServiceRequest.consumerProduct
							.consumerProductAttribute &&
					  consumerServiceRequest.consumerProduct
							.consumerProductAttribute.AttributeValue,
			};

			setLoader(true);
			apiCall({
				url: "fetchReportedIssues",
				data: data,
				callBack: (result) => {
					setLoader(false);
					if (result.success) {
						setAllIssues(result.data);
						if (result.data && result.data.length > 0) {
							setDisabledState((prevState) => ({
								...prevState,
								issuecode: false,
							}));
						}
					} else {
						Swal.fire({
							title: "",
							text: localString(displayError(result)),
							icon: "error",
							confirmButtonText: localString("OK"),
						});
					}
				},
			});
		}
	};

	const completeNonHardwareClosure = (enableRepairConsent = false) => {
		let data = {
			ConsumerServiceRequestID:
				consumerServiceRequest.ConsumerServiceRequestID,
			BrandID: consumerServiceRequest.consumerProduct.BrandID,
			NonHardwareClosureRemark: inputStates.delivery_notes,
		};

		if (
			questionDetails &&
			questionDetails.length &&
			inputStates.isNTFRepair
		) {
			data["questionDetails"] = constructQuestionData();

			if (!isQuestionDetailsValid()) {
				Swal.fire({
					title: "Error",
					text: localString("Please enter Required Fields (*)."),
					icon: "error",
					confirmButtonText: localString("OK"),
				});

				return;
			}
		}

		if (inputStates.isNTFRepair) {
			// delete value key added for dropdown before adding it in api call
			let copySelectedSymptom = _.cloneDeep(selectedSymptom);
			let copySelectedIssue = _.cloneDeep(selectedIssue);
			let copyCompModItem = _.cloneDeep(compModItem);

			delete copySelectedSymptom["value"];
			delete copySelectedIssue["value"];
			delete copyCompModItem["value"];

			data.isNTFRepair = true;
			data.ReportedSymptom = copySelectedSymptom;
			data.ReportedIssue = copySelectedIssue;
			data.comptiaModifier = copyCompModItem;
		}

		data.repairOutcomeCodes = repairOutcomes;
		if(enableRepairConsent){
			data.overrideRepairEligibility = true;
		}else{
			data.overrideRepairEligibility = false;
		}
		setLoader(true);
		apiCall({
			url: "nonHardwareClosure",
			method: "post",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					closeNonHardwareClosureModal();
					getRequestById();
				} else {
					if(result.data && !result.data.enableRepairConsent){
							enableRepairConsent = false;
							Swal.fire ({
								title: '',
								text: localString(displayError(result)),
								type: 'error',
								confirmButtonText: localString('OK'),
								allowEscapeKey: false,
								allowOutsideClick: false,
							});
					}else if(result.data && result.data.enableRepairConsent){
						Swal.fire ({
							title: '',
							html: `<div style="white-space: pre-line;">${localString(displayError(result))}<div/>`,
							type: 'error',
							showCancelButton: true,
							allowEscapeKey: false,
							allowOutsideClick: false,
							cancelButtonText: localString("No"),
							confirmButtonText: localString("Yes")
						}).then (function (result) {
							if (result.isConfirmed) {
								Swal.close();
								enableRepairConsent = true;
								completeNonHardwareClosure(enableRepairConsent);
							}
						});
					}else{
						Swal.fire ({
							title: '',
							text: localString(displayError(result)),
							type: 'error',
							confirmButtonText: localString('OK'),
							allowEscapeKey: false,
							allowOutsideClick: false,
						});
					}
				}
			},
		});
	};

	const loadRepairCheckList = (data) => {
		let copySelectedIssue = data._selectedIssue
			? _.cloneDeep(data._selectedIssue)
			: _.cloneDeep(selectedIssue);
		let copyCompModItem = data._compModItem
			? _.cloneDeep(data._compModItem)
			: _.cloneDeep(compModItem);
		let copySelectedSymptom = _.cloneDeep(selectedSymptom);

		if (
			inputStates.isNTFRepair &&
			copySelectedSymptom &&
			copySelectedSymptom.reportedSymptomCode &&
			copySelectedIssue &&
			copySelectedIssue.reportedIssueCode &&
			copyCompModItem &&
			copyCompModItem.modifierCode
		) {
			// delete value key added for dropdown before adding it in api call
			delete copySelectedSymptom["value"];
			delete copySelectedIssue["value"];
			delete copyCompModItem["value"];

			let data = {
				isNTFRepair: inputStates.isNTFRepair,
				ConsumerServiceRequestID:
					consumerServiceRequest.ConsumerServiceRequestID,
				device: {
					id: consumerServiceRequest.consumerProduct
						.AlternateUniqueKey
						? consumerServiceRequest.consumerProduct
								.AlternateUniqueKey
						: consumerServiceRequest.consumerProduct
								.consumerProductAttribute &&
						  consumerServiceRequest.consumerProduct
								.consumerProductAttribute.AttributeValue,
				},
				ReportedSymptom: copySelectedSymptom,
				ReportedIssue: copySelectedIssue,
				comptiaModifier: copyCompModItem,
			};

			setLoader(true);
			apiCall({
				url: "getGsxRepairChecklist",
				data: data,
				callBack: (result) => {
					setLoader(false);
					if (result.success && result.data) {
						let arr = updateQuestions(result.data.questionDetails) || [];
						setQuestionDetails(arr);
						setMainQuery(arr);
					} else {
						Swal.fire({
							title: "",
							text: localString(displayError(result)),
							icon: "error",
							confirmButtonText: localString("OK"),
						});
					}
				},
			});
		}
	};
	// Api calls ended

	const checkRepairOutcomesCountUpperBound = () => {
		return selectedOutcomes.length >= outcomes.max;
	};


	const repairItemClick = function (_selectedOutcome) {
		if (_selectedOutcome) {
			if (_selectedOutcome.IsSelected) {
				setRepairOutcomes((prevData) => {
					let updatedRepairOutcomes = prevData.map((dataObject) => {
						if (
							dataObject.RepairOutcomeID ===
							_selectedOutcome.RepairOutcomeID
						) {
							return { ...dataObject, IsSelected: false };
						}

						return dataObject;
					});

					const updatedSelectedOutcomes =
						updatedRepairOutcomes.filter(function (outcome) {
							return outcome.IsSelected == true;
						});

					setSelectedOutcomes(updatedSelectedOutcomes);
					return updatedRepairOutcomes;
				});
			} else {
				if (checkRepairOutcomesCountUpperBound()) {
					Swal.fire({
						title: "",
						text:
							localString("Cannot add more than 4 outcomes") +
							".",
						icon: "info",
						confirmButtonText: localString("OK"),
					});
				} else {
					setRepairOutcomes((prevData) => {
						let updatedRepairOutcomes = prevData.map(
							(dataObject) => {
								if (
									dataObject.RepairOutcomeID ===
									_selectedOutcome.RepairOutcomeID
								) {
									return { ...dataObject, IsSelected: true };
								}

								return dataObject;
							}
						);

						const updatedSelectedOutcomes =
							updatedRepairOutcomes.filter(function (outcome) {
								return outcome.IsSelected == true;
							});

						setSelectedOutcomes(updatedSelectedOutcomes);

						return updatedRepairOutcomes;
					});
				}
			}
		}
	};

	const createNTFChecked = (isChecked) => {
		setInputStates((prevData) => ({ ...prevData, isNTFRepair: isChecked }));
		if (isChecked) {
			if (
				!isStaticNonSerializedProduct &&
				isNonSerializedProduct &&
				!SKUSelected
			) {
				if (consumerServiceRequest.consumerProduct.DateOfPurchase) {
					getSKUForNonSerialized();
					setShowSKUSelection(true);
				} else {
					Swal.fire({
						title: "",
						text: localString(
							"Please update the device date of purchase in device details section to proceed further."
						),
						icon: "warning",
						confirmButtonText: localString("OK"),
					});
				}
			} else {
				setShowSymptomsCode(true);
				if (
					accessFn("GSX_FLOW") &&
					consumerServiceRequest &&
					consumerServiceRequest.consumerProduct &&
					consumerServiceRequest.consumerProduct.isAppleBrandID &&
					!getSymptomCodesCalled
				) {
					getSymptomCodes();
				} else {
					setDisabledState((prevData) => ({
						...prevData,
						symptomcode: false,
					}));
				}
				if (
					accessFn("GSX_FLOW") &&
					consumerServiceRequest &&
					consumerServiceRequest.consumerProduct &&
					consumerServiceRequest.consumerProduct.isAppleBrandID &&
					!getCompModListCalled
				) {
					getCompModList();
				} else {
					setDisabledState((prevData) => ({
						...prevData,
						comptia_mod_Repair: false,
					}));
				}
			}
		} else {
			setShowSymptomsCode(false);
			setShowSKUSelection(false);
			setSKUSelected(false);
			setSelectedSKU({});
			setSelectedSymptom({});
			setSelectedIssue({});
			setCompModItem({});
		}
	};

	const removeKeys = (questions) => {
		questions.forEach((obj) => {
			// delete obj.questionPhrase;
			delete obj.answerIndex;
			delete obj.value;
			if (obj.answers.length) {
				obj.answers.forEach((ans) => {
					// if (!["FFB", "INT", "BBX"].includes(obj.answerType)) {
					// 	delete ans.answerPhrase;
					// }
					if (ans.questions && ans.questions.length) {
						ans.questions = removeKeys(ans.questions);
					}
				});
			}
		});
		return questions;
	};

	const constructQuestionData = () => {
		let questionsArr = JSON.parse(JSON.stringify(questionDetails));
		questionsArr.forEach((questionObj) => {
			questionObj.trees.forEach((treeObj) => {
				if (treeObj.questions && treeObj.questions.length) {
					treeObj.questions = removeKeys(treeObj.questions);
				}
			});
		});
		return questionsArr;
	};

	const isQuestionDetailsValid = () => {
		let valid = true;
		const checkQuery = (questions) => {
			if (questions.length) {
				questions.forEach((obj) => {
					if (!obj.isSelect && !obj.optional) {
						valid = false;
					} else if (
						obj.isSelect &&
						obj.answers &&
						obj.answers.length
					) {
						if (valid) {
							obj.answers.forEach((ans) => {
								if (
									ans.isSelect &&
									ans.questions &&
									ans.questions.length
								) {
									checkQuery(ans.questions);
								}
							});
						}
					}
				});
			}
		};

		questionDetails.forEach((questionObj) => {
			questionObj.trees.forEach((treeObj) => {
				if (treeObj.questions && treeObj.questions.length) {
					if (valid) {
						checkQuery(treeObj.questions);
					}
				}
			});
		});
		return valid;
	};

	// handle dropdown event changes start
	const handleSelectedSKUChange = (item) => {
		setSelectedSKU(item);
		updateNonSerializedDevice(item);
	};

	const handleSelectedSymptomChange = (item) => {
		setSelectedSymptom(item);
		setQuestionDetails("");
		setSelectedIssue(false);
		setCompModItem(false);
		setDisabledState((prevData) => ({
			...prevData,
			issuecode: true,
			comptia_mod_Repair: true,
		}));
		initIssueCode(item);
	};

	const handleSelectedIssueChange = (item) => {
		setSelectedIssue(item);
		setDisabledState((prevData) => ({
			...prevData,
			comptia_mod_Repair: false,
		}));

		loadRepairCheckList({ _selectedIssue: item });
	};

	const handleCompModItemChange = (item) => {
		setCompModItem(item);
		loadRepairCheckList({ _compModItem: item });
	};
	// handle dropdown event changes end

	return (
		<>
			<div className="flex-col w-full wrapper">
				<div className="flex-col my10">
					<div>
						<p>
							<b>{localString("Select Repair Outcomes")}*</b>
						</p>
						<p>{localString("User can select upto 4 outcomes")}</p>
					</div>

					{/* <div className="wflex">
						{repairOutcomes &&
							repairOutcomes.length > 0 &&
							repairOutcomes.map((outcome) => {
								return (
									<div
										key={outcome.RepairOutcome}
										className="child-wflex"
									>
										<Checkbox
											key={outcome.RepairOutcome}
											id={outcome.RepairOutcome}
											type="tabs"
											value={outcome.IsSelected}
											handleOnChange={() =>
												repairItemClick(outcome)
											}
										>
											{outcome.RepairOutcome
												? localString(
														outcome.RepairOutcome
												  )
												: "-"}
										</Checkbox>
									</div>
								);
							})}
					</div> */}
					<div>
						{ 
							repairOutcomes && repairOutcomes.length > 0 && repairOutcomes.map((outcome) => (
								<div className="issue-div col-sm-4 col-xs-6">
									<div 
										className={`item issue-item ${ outcome.IsSelected ? 'item-active': 'border-color-grey'}`} 
										onClick={() =>repairItemClick(outcome)}
									>
										{outcome.RepairOutcome ? localString(outcome.RepairOutcome) : '-'}
									</div>
								</div>
							)
							)
						}
					</div>
				</div>

				<div className="flex-col my10">
					{accessFn("GSX_FLOW") &&
						consumerServiceRequest &&
						consumerServiceRequest.consumerProduct &&
						consumerServiceRequest.consumerProduct
							.isAppleBrandID && qFilter?.['gsx-user-detail-id'] && (
							<div className="flex-row w-full">
								<div className="flex-row w25 createNTF">
									<Checkbox
										value={inputStates.isNTFRepair}
										name="createNTF"
										handleOnChange={(e) => {
											createNTFChecked(e.target.checked);
										}}
										id="createNTF"
									>
										{localString("Create NTF Repair")}
									</Checkbox>
								</div>
								{showSKUSelection && (
									<div className="flex-col w25">
										<label htmlFor="SkuName">
											{localString("Select SKU")}
										</label>

										<Dropdown
											id="SkuName"
											value={selectedSKU.value}
											options={skuNameDropDownOptions}
											handleOnChange={
												handleSelectedSKUChange
											}
											filterBy="value"
											showTooltip={true}
											searchText={localString(
												"Select SKU"
											)}
											handleOnSearch={(e) =>
												skuNameDropDownOptions.filter(
													(obj) =>
														obj.value
															.toLowerCase()
															.includes(
																e.target.value.toLowerCase()
															)
												)
											}
											noOptionsText="Cannot find the searched option"
											placeholder={localString(
												"Select SKU"
											)}
										/>
									</div>
								)}
							</div>
						)}
				</div>

				<div className="flex-row w-full my10">
					{showSymptomsCode && (
						<>
							<div className="flex-col w25">
								<label
									className="checkboxFeature"
									htmlFor="symptomcode"
								>
									{localString("Component Code")}
								</label>

								<Dropdown
									id="symptomcode"
									isDisabled={disabledState.symptomcode}
									value={selectedSymptom.value}
									options={symptomcodeDropDownOptions}
									handleOnChange={handleSelectedSymptomChange}
									filterBy="value"
									showTooltip={true}
									// searchText={localString("Component Code")}
									handleOnSearch={(e) =>
										symptomcodeDropDownOptions.filter(
											(obj) =>
												obj.value
													.toLowerCase()
													.includes(
														e.target.value.toLowerCase()
													)
										)
									}
									noOptionsText="Cannot find the searched option"
									// placeholder={localString("Component Code")}
								/>
							</div>
							<div className="flex-col w25">
								<label
									className="checkboxFeature"
									htmlFor="issuecode"
								>
									{localString("Issue Code")}
								</label>

								<Dropdown
									id="issuecode"
									isDisabled={disabledState.issuecode}
									value={selectedIssue.value}
									options={issuecodeDropDownOptions}
									showTooltip={true}
									handleOnChange={handleSelectedIssueChange}
									filterBy="value"
									// searchText={localString("Issue Code")}
									handleOnSearch={(e) =>
										issuecodeDropDownOptions.filter((obj) =>
											obj.value
												.toLowerCase()
												.includes(
													e.target.value.toLowerCase()
												)
										)
									}
									noOptionsText="Cannot find the searched option"
									// placeholder={localString("Issue Code")}
								/>
							</div>
							<div className="flex-col w25">
								<label
									className="checkboxFeature"
									htmlFor="comptia_mod_Repair"
								>
									{localString("Reproducibility")}
								</label>

								<Dropdown
									id="comptia_mod_Repair"
									isDisabled={
										disabledState.comptia_mod_Repair
									}
									value={compModItem.value}
									options={comptiaModRepairDropDownOptions}
									handleOnChange={handleCompModItemChange}
									showTooltip={true}
									filterBy="value"
									// searchText={localString("Reproducibility")}
									handleOnSearch={(e) =>
										comptiaModRepairDropDownOptions.filter(
											(obj) =>
												obj.value
													.toLowerCase()
													.includes(
														e.target.value.toLowerCase()
													)
										)
									}
									noOptionsText="Cannot find the searched option"
									// placeholder={localString("Reproducibility")}
								/>
							</div>
						</>
					)}
				</div>

				{questionDetails &&
					questionDetails.length > 0 &&
					inputStates.isNTFRepair && (
						<div className="flex-col w-full my10">
							<QuestionLookup
								mainQuery={[...mainQuery]}
								localString={localString}
								questionDetails={questionDetails}
								setQuestionDetails={setQuestionDetails}
							/>
						</div>
					)}

				<div className="flex-col w-full my10">
					<Input
						label={`${localString("Delivery Notes")}*`}
						labelAlign="top"
						// placeholder="Write down your message here."
						id="delivery-notes"
						inputComponent="textarea"
						otherProps={{
							minlength: 1,
							maxlength: 1000,
							rows: 4,
							cols: 60,
						}}
						className={{
							inputContainer: 'height-auto'
						}}
						value={inputStates.delivery_notes}
						handleOnChange={(e) => {
							let obj = {...inputStates}
							obj.delivery_notes = e.target.value;
							setInputStates(obj);
							// setInputStates((prevData) => ({
							// 	...prevData,
							// 	delivery_notes: e.target?.value,
							// }));
						}}
					/>
					<p>
						*
						{localString(
							"Notes will be displayed on Customer’s Delivery Report"
						)}
						.
					</p>
				</div>

				<div className="flex-row justify-end my10">
					<Button
						classes="button-ghost button-small"
						type="button"
						disabled={!enableClosureBtn}
						handleOnClick={() => completeNonHardwareClosure()}
					>
						{localString("Save")}
					</Button>

					<Button
						classes="button-ghost button-small"
						type="button"
						handleOnClick={closeNonHardwareClosureModal}
					>
						{localString("Cancel")}
					</Button>
				</div>
			</div>
		</>
	);
}
