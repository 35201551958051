import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Swal from "sweetalert2";
import { Button, Modal, Loader, Pagination } from 'sfy-react';
import moment from 'moment';
import Papa from 'papaparse';

import CommonTable from '../../../../components/Table/Table';
import { displayError, downloadCSV, encrypt, formatIndianCurrencyRs } from '../../../../Utility/Utils';
import ReportPageHeader from '../../ReportPageHeader';
import FilterModal from '../../../../components/FilterModal/FilterModal';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import './SalesAndPerformanceReport.scss'

let defaultFilters = {
	shop: '',
	branch: '',
	date: new Date(),
	advisor: '',
}
export default function SalesAndPerformanceReport(props) {
	const { accessFn, localString, apiCall, titleHeader } = props;
	const [loader, setLoader] = useState(false);
	const [data, setData] = useState([]);
	const [showModal , setShowModal] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [mainFilters, setMainFilters] = useState(defaultFilters);
	const [branchList, setBranchList] = useState([]);
	const [branchObj, setBranchObj] = useState([]);
	const [shopList, setShopList] = useState([]);
	const [shopObj, setShopObj] = useState([]);
	const [advisorList, setAdvisorList] = useState([]);
	const [advisorObj, setAdvisorObj] = useState([]);
	const [pagination , setPagination] = useState({
		pageNo : 1,
		itemsPerPage : 30,
		count: 0,
		numberOfPages: 0
	});
	const lastDay = moment().subtract(1,'days').format("YYYY-MM-DD");
	const newDate = filters.date;
	const startDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
	const endDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);

	const buttonHeader = [
		{
			buttonText: localString('Filter Requests'),
			btnIcon: 'glyphicon glyphicon-filter',
			showButton: true,
			onButtonClick: () => {
				setShowModal(true);
			}
		},
	];

	const getSalesReport = (downloadFile) => {
		setLoader(true);
		let reqObj = {
			RetailUserID: filters.advisor ? [advisorObj.RetailUserID] : [],
			ShopCode: filters.shop ? [shopObj.ShopCode] : [],
			Type: "TotalSales",
			branchCode: filters.branch ? [branchObj.BranchID] : [],
			eDate: moment(endDate).format("YYYY-MM-DD"),
			sDate: moment(startDate).format("YYYY-MM-DD"),
		}
		if(!downloadFile) {
			reqObj.Limit = pagination.itemsPerPage;
			reqObj.PageNo = pagination.pageNo;
		}
		apiCall({
			url: 'getSalesReport',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data && !downloadFile) {
					setData(result.data.SalesDetails);
					setPagination({...pagination, 
						count: result.data && result.data.length,
						numberOfPages: result.data && result.data.numberOfPages
					})
				}else if(result.success && result.data && downloadFile) {
					let updatedSalesDetails = result.data && result.data.SalesDetails && result.data.SalesDetails.map((details => {
						let obj = {...details};
						obj.NoOfProductSold = (details.NoOfProductSold ? details.NoOfProductSold :"0");
						obj.ValueOfProductSold = (details.ValueOfProductSold ? formatIndianCurrencyRs(details.ValueOfProductSold) :"0")
						obj.VerifiedAmount = (details.VerifiedAmount ? formatIndianCurrencyRs(details.VerifiedAmount) :"0")
						obj.UnVerifiedAmount = (details.UnVerifiedAmount ? formatIndianCurrencyRs(details.UnVerifiedAmount) :"0")
						obj.Target = (details.Target ? formatIndianCurrencyRs(details.Target) :"0")
						obj.TargetAchieved = (details.TargetAchieved ? details.TargetAchieved :"0.000%")
						return obj;
					}))
					var csv = Papa.unparse({
						fields: [
							'Name',
							'AdvisorCode',
							'shop',
							'City',
							'ShopArea',
							'Branch',
							'MobileNo',
							'NoOfProductSold',
							'ValueOfProductSold',
							'VerifiedAmount',
							'UnVerifiedAmount',
							'Target',
							'TargetAchieved'
						],
						data: updatedSalesDetails,
					});
					let CapsString
					let capital = Papa.parse(csv, {
						beforeFirstChunk: (chunk) => {
							let rows = chunk.split( /\r\n|\r|\n/ );
							let result = [];
							let row = rows[0];
							let a = row.split(',');
							for (let iCount = 0;iCount<a.length;iCount++) {
								let item = a[iCount].replace(/([a-z])([A-Z])/g, '$1 $2');
								result.push(item)
							}
							CapsString = result.toString().toUpperCase();
							rows[0] = CapsString;
							return rows.join("\r\n");
						},
					});
					let csvString = Papa.unparse(capital);
					downloadCSV(csvString, 'SalesReports' + '.csv')                    
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		getSalesReport();
	}, [mainFilters, pagination.pageNo])

	const downloadPersonalReport = (RetailUserID, name) => {
		setLoader(true);
		let reObj = {
			RetailUserID: [RetailUserID],
			ShopCode: [],
			Type: "PersonalSales",
			branchCode: [],
			eDate: moment(endDate).format("YYYY-MM-DD"),
			sDate: moment(startDate).format("YYYY-MM-DD"),
		}
		apiCall({
			url: 'getSalesReport',
			data: reObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let salesDetailsArr = result.data.SalesDetails;
					let salesRecords = [];
					salesDetailsArr.length && salesDetailsArr.map((detail) => {
						detail.ProductData.length && detail.ProductData.map((productDetail, k) => {
							let obj = {...productDetail};
							obj.Total = (detail.SumBasedOnEachDay && k == 0) ? detail.SumBasedOnEachDay : '';
							delete obj['RetailUserID'];
							delete obj['ProductID'];
							salesRecords.push(obj);
						});
						salesRecords.push({});
					});
					let salesDetails = Papa.unparse({
						fields: [
							'SaleDate',
							'CustomerName',
							'MobileNo',
							'ProductCategory',
							'ProductSubcategory',
							'ModelNo',
							'ModelDesc',
							'InvoiceAmount',
							'Status',
							'Total'
						],
						data: salesRecords
					});
					// ToDo: Common handler to capitalize first row in reports
					let CapsString;
					let capital = Papa.parse(salesDetails, {
						beforeFirstChunk :function(chunk) {
							let rows = chunk.split( /\r\n|\r|\n/ );
							let result = [];
							let row = rows[0];
							let a = row.split(',');
							for (let iCount = 0; iCount < a.length; iCount++) {
								let item = a[iCount].replace(/([a-z])([A-Z])/g, '$1 $2');
								result.push(item)
							}
							CapsString = result.toString().toUpperCase();
							rows[0] = CapsString;
							return rows.join("\r\n");
						},
					});
					let advisorData = result.data.AdvisorDetails;
					let salesAchievement = result.data.SalesAchievement;
					let advisorDetails = "NAME" +","+ (advisorData[0].Name ? advisorData[0].Name : '-') + "\r\n" +
						"BRANCH CODE" +","+ (advisorData[0].branchCode ? advisorData[0].branchCode : '-') + "\r\n" +
						"MOBILE NO" +","+(advisorData[0].MobileNo ? advisorData[0].MobileNo : '-') + "\r\n" +
						"SHOP" +","+ (advisorData[0].shop ? advisorData[0].shop : '-') + "\r\n" +
						"SHOP AREA" +","+ (advisorData[0].ShopArea ? advisorData[0].ShopArea : '-') + "\r\n" +
						"CITY" +","+ (advisorData[0].City ? advisorData[0].City : '-') + "\r\n" +
						"BRANCH" +","+ (advisorData[0].Branch ? advisorData[0].Branch : '-') +
						"\r\n\r\n" +
						"TARGET" +","+ (salesAchievement.Target ? salesAchievement.Target : '-') + "\r\n" +
						"VERIFIED AMOUNT" +","+ (salesAchievement.VerifiedAmount ? salesAchievement.VerifiedAmount : '-') + "\r\n" +
						"UNVERIFIED AMOUNT" +","+ (salesAchievement.UnVerifiedAmount ? salesAchievement.UnVerifiedAmount : '-') + "\r\n" +
						"NO OF PRODUCTS SOLD" +","+ (salesAchievement.NoOfProductSold ? salesAchievement.NoOfProductSold : '-');

					let salesDetailsCsv = Papa.unparse(capital);
					let csv = advisorDetails + "\r\n" + "\r\n" + salesDetailsCsv;
					downloadCSV(csv, name + '.csv')
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const branchMasterGetFilter = () => {
		apiCall({
			url: 'branchMasterGetFilter',
			data: {},
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(val => ({ ...val, value: val.displayDetails}))
					setBranchList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		showModal && branchMasterGetFilter();
	}, [showModal])

	const getAllShopsAndDistinctStates = () => {
		let obj = {
			branchCode: filters.branch ? branchObj.BranchID : null
		}
		apiCall({
			url: 'getAllShopsAndDistinctStates',
			data: obj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data && result.data.shops) {
					let arr = result.data.shops.map(val => ({...val, value: val.displayDetails}))
					setShopList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		showModal && getAllShopsAndDistinctStates();
	}, [showModal, filters.branch])

	const getAdvisorsList = () => {
		let obj = {
			branchCode: filters.branch ? branchObj.BranchID : null,
			resetAction: "location"
		}
		if(filters.shop) {
			obj.PartnerRetailLocationID = shopObj.PartnerRetailLocationID;
		}
		apiCall({
			url: 'getAdvisorsList',
			data: obj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(val => ({...val, value: val.displayDetails}))
					setAdvisorList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		showModal && getAdvisorsList();
	}, [showModal, filters.branch, filters.shop])

	const columns = [
		{
			title: localString("Advisor Name"),
			showHeading: true,
			field: 'Name',
			customCell: (row) => {
				return (
					<>
						<Tooltip
							content={() => {
								return (
									<div>
										<div className='reportTooltipHeader'>{localString('Info')}</div>
										<div className='reportTooltipData'>
											<div>Branch: {row.Branch}</div>
											<div>City: {row.City}</div>
											<div>Shop Area: {row.ShopArea}</div>
											<div>Shop Name: {row.shop}</div>
											<div>Mobile No: {row.MobileNo}</div>
										</div>
									</div>
								)
							}}
							className={{
								tooltipText: 'reportTooltipText'
							}}
							position='right'
						>
							<div style={{ borderBottom: '1px dotted black' }}>{row.Name}</div>
						</Tooltip>
					</>
				);
			}
		},
		{
			title: localString("Advisor Code"),
			showHeading: true,
			field: 'AdvisorCode',
		},
		{
			title: localString("No of Products Sold"),
			showHeading: true,
			field: 'NoOfProductSold',
			customCell: (row) => row.NoOfProductSold || '0',
		},
		{
			title: localString("Value of Products Sold"),
			showHeading: true,
			customCell: (row) => <>&#8377; {row.ValueOfProductSold ? formatIndianCurrencyRs(row.ValueOfProductSold) : "0"}</>,
		},
		{
			title: localString("Verified Sales"),
			showHeading: true,
			customCell: (row) => <>&#8377; {row.VerifiedAmount ? formatIndianCurrencyRs(row.VerifiedAmount) : "0"}</>,
		},
		{
			title: localString("Unverified Sales"),
			showHeading: true,
			customCell: (row) => <>&#8377; {row.UnVerifiedAmount ? formatIndianCurrencyRs(row.UnVerifiedAmount) : "0"}</>,
		},
		{
			title: localString("Target"),
			showHeading: true,
			customCell: (row) => <>&#8377; {row.Target ? formatIndianCurrencyRs(row.Target) : "0"}</>,
		},
		{
			title: localString("Target Achieved % (Verified)"), 
			showHeading: true,
			customCell: (row) => row.TargetAchieved || "0.000%",
		},
		{
			title: localString("Download"),
			showHeading: true,
			customCell: (row) => {
				return (
				<>
					<div className="download-icon" title="Download Report" onClick={() => downloadPersonalReport(row.RetailUserID, row.Name)} data-toggle="tooltip" data-placement="right">
						<div className="download-image" id=""></div>
					</div>
				</>
				);
			}
		},
		{
			title: localString(""),
			showHeading: true,
			customCell: (row) => {
				return (
					<>
						<Button
							type='button'
							classes='button-ghost'
							style={{ width: 'auto' }}
							onClick={() => {
								localStorage.setItem('date', filters.date);
								window.open(`/retailerReports/salesReports/view?id=${encrypt(row.RetailUserID)}&name=${encrypt(row.Name)}`);
							}}
						>
							{localString('View')}
						</Button>
					</>
				);
			}
		},
	];

	let filterInputList = [
		{
			labelText: localString('Date'),
			dataKey: 'date',
			customClass:'col-sm-12 paddingB10',
			showFilter: true,
			type: 'DateTime',
			dateFormat: 'MM/YYYY',
			timeFormat: false,
			maxDay: new Date(lastDay),
			oninputFunc: (val) => {
				setFilters({ ...filters, date: new Date(val) });
			}
		},
		{
			labelText: localString('Branch Code'),
			dataKey: "branch",
			customClass:'col-sm-12 paddingB10',
			showFilter: true,
			type: 'Dropdown',
			dropdownList: branchList,
			oninputFunc: (val) => {
				setBranchObj(val);
				setShopObj(null);
				setAdvisorObj(null);
				setFilters({ ...filters, branch: val.displayDetails, shop: '', advisor: '' })
			}
		},
		{
			labelText: localString('Shop Name'),
			dataKey: "shop",
			customClass:'col-sm-12 paddingB10',
			showFilter: true,
			type: 'Dropdown',
			dropdownList: shopList,
			oninputFunc: (val) => {
				setShopObj(val);
				setAdvisorObj(null);
				setFilters({ ...filters, shop: val.displayDetails, advisor: '' })
			}
		},
		{
			labelText: localString('Advisor'),
			dataKey: "advisor",
			customClass:'col-sm-12 paddingB10',
			showFilter: true,
			type: 'Dropdown',
			dropdownList: advisorList,
			oninputFunc: (val) => {
				setAdvisorObj(val);
				setFilters({ ...filters, advisor: val.displayDetails })
			}
		},
	]

	return (
		<div className='salesAndPerformanceReport'> 
			{
				loader && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<ReportPageHeader 
				titleHeader={titleHeader}
				localString={localString}
				btnClass='btn button-servify header-btn button-stack text-capital'
				headerButtonArray={buttonHeader} 
				showHeaderContent={true}
				headerContent={() => {
					return (
						<div style={{ display: 'inline-block' }}>
							<span style={{ fontSize: '20px' }}>
								({moment(filters.date).format("MMM-YYYY")})
							</span>
							<div className="download-icon" title="Download Report" onClick={() => getSalesReport(true)} data-toggle="tooltip" data-placement="right">
								<div className="download-image" id=""></div>
							</div>
						</div>
					);
				}}
			/>
			<FilterModal
				{...props}
				// disableApplyBtn={disableApplyButton}
				filterFunc={(e) => {
					e.preventDefault();
					setMainFilters(filters);
					setPagination({...pagination, pageNo: 1})
					setShowModal(false);
				}}
				filterInputs={filterInputList}
				filterData={filters}
				showModal={showModal}
				closeModal={() => {
					setFilters(mainFilters)
					setShowModal(false);
				}}
				hideClearButton={false}
				clearSearch={() => {
					setFilters(defaultFilters);
					setMainFilters(defaultFilters);
					setPagination({...pagination, pageNo: 1})
				}}
				
			/>
			<CommonTable 
				columns={columns}
				data={data}
				localString={localString}
			/>
			{
				pagination.numberOfPages > 1 && 
					<Pagination
						itemsPerPage={pagination.itemsPerPage}
						pages={pagination.numberOfPages}
						totalItems={pagination.count}
						activePage={pagination.pageNo}
						paginate={(pageNumber) => setPagination({...pagination, pageNo: pageNumber})}
					/> 
			}
		</div>
	);
}