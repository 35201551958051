import React from 'react';
import {Table, Radio, Button, Dropdown, Input} from 'sfy-react';
import LoanerCancelModal from './LoanerCancelModal';
import './LoanerDetails.scss'
import LoanerDispositionModal from './LoanerDispositionModal';

const LoanerDetails = (props) => {
    const {loanerDetails, doaEntries, accessFn, localString, checkGSXRepair, editLoanerDetails, currentLoanerStatus, enableEditLoanerDetails, 
        csrLoanerDetails, loanerRequestedStatus, triggerStatusParentClick, activeLoanerStatus, savedLoanerdetails, loanerDeviceCount,
        cancelDetailsClick, showDispositionModal, updateLoanerDetails, setInputData, inputData, setLoanerDeviceList, loanerDeviceDropdownList,
        checkImeiValueValid, enableLonaerSave, currencySymbol, loanerDispositionModal, loanerSelected, getLoanerCancelStatusList,
        loanerCancelModal, loanerCancelStatusList
    } = props;

        const classObject = {
            table:"complaint-details-table table table-striped",
            td:"loaner_radio"
        }

    return (
        <>
            <div className="request-panel loaner-details-height loaner-details-tab">
            {
                loanerDetails && 
                <div>
                    {
                        (doaEntries && doaEntries.length > 0) &&
                        <p className="margin-10 font-weight-bolder"> 
                            <span style={{color:"red"}} className="glyphicon glyphicon glyphicon-exclamation-sign"></span>
                            {
                                doaEntries && doaEntries.map((item,i) => {
                                    return(
                                        <span>
                                            <span>({item.LoanerDeviceName} : {item.LoanerDeviceSerialNumber})</span>
                                            {
                                                doaEntries.indexOf(item) != doaEntries[doaEntries.length - 1] &&
                                                <span>, </span>
                                            }
                                        </span>
                                    )
                                }) 
                            }
                            {localString("Marked as a non functional device")} 
                        </p>
                    }
                    <Table className={classObject}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Data className="table-keys"> {localString("Available Loaner Device")} </Table.Data>
                                <Table.Data> { loanerDetails && loanerDetails.totalQuantity ? loanerDetails.totalQuantity : '-'} </Table.Data>
                            </Table.Row>
                            {
                                accessFn('ACCEPT_RETURN_DEVICE') && loanerDetails && loanerDetails.totalQuantity > 0 &&
                                <Table.Row>
                                    <Table.Data className="table-keys"> {localString("Loaner Device Requested")} </Table.Data>
                                    {
                                        loanerDetails && loanerDetails.loanerEntryDetails && loanerDetails.loanerEntryDetails.length !== 0 && checkGSXRepair() &&
                                        <Table.Data > {(loanerDetails && loanerDetails.loanerRequested ? "Yes" : "No") || '-'} </Table.Data>
                                    }
                                    {   
                                        loanerDetails && loanerDetails.loanerEntryDetails && loanerDetails.loanerEntryDetails.length === 0 && checkGSXRepair() &&
                                        <Table.Data className={classObject.td}>
                                            {
                                                checkGSXRepair() &&
                                                loanerRequestedStatus && loanerRequestedStatus.map((item,i)=>{
                                                    return( 
                                                        <Radio
                                                            value={item.val}
                                                            id={item.name+"_"+item.val}
                                                            name={item.name}
                                                            selectedValue={activeLoanerStatus}
                                                            handleOnUpdate={(e)=>{
                                                                triggerStatusParentClick(e.target.value)
                                                            }}
                                                        >{item.name}</Radio>
                                                    )
                                                })
                                            }
                                        </Table.Data>
                                    }
                                    {
                                        !checkGSXRepair() &&
                                        <Table.Data>-</Table.Data>
                                    }
                                </Table.Row>
                            }
                            {
                                loanerDetails && loanerDetails.loanerRequested &&
                                <Table.Row>
                                    <Table.Data className="table-keys"> {localString("Loaner Device")} </Table.Data>
                                    {
                                        (!editLoanerDetails || (['LORQ','LDOA','LOSE'].indexOf(currentLoanerStatus) === -1)) && 
                                        <Table.Data> {loanerDetails && loanerDetails.loanerDeviceName ? loanerDetails.loanerDeviceName : "-"} </Table.Data>
                                    }
                                    {
                                        (editLoanerDetails && accessFn('ACCEPT_RETURN_DEVICE') && ['LORQ','LDOA','LOSE'].indexOf(currentLoanerStatus) > -1) &&
                                        <Table.Data> {loanerDetails && loanerDetails.loanerDeviceName ? loanerDetails.loanerDeviceName : "-"} </Table.Data>
                                    }
                                    {
                                        editLoanerDetails && accessFn('REPAIR_DEVICE') && ['LORQ','LDOA','LOSE'].indexOf(currentLoanerStatus) > -1 && enableEditLoanerDetails &&
                                        <Table.Data className="loanerDeviceList-dropdown">
                                            <Dropdown
                                                id='dropdown'
                                                key="loanerDeviceList"
                                                value={inputData.loanerDeviceList}
                                                options={loanerDeviceDropdownList}
                                                handleOnChange={(data) => {
                                                    setLoanerDeviceList(data)
                                                }}
                                                filterBy='value'
                                                showDownArrow={false}
                                            >
                                            </Dropdown>
                                        </Table.Data>
                                    }
                                </Table.Row>
                            }
                            {
                                loanerDetails && loanerDetails.loanerRequested &&
                                <Table.Row>
                                    <Table.Data className="table-keys"> {localString("Loaner Device Serial Number")} </Table.Data>
                                    {
                                        (!editLoanerDetails || (['LORQ','LDOA','LOSE'].indexOf(currentLoanerStatus) === -1)) && 
                                        <Table.Data> {loanerDetails && loanerDetails.loanerDeviceSerialNumber ? loanerDetails.loanerDeviceSerialNumber :'-'} </Table.Data>
                                    }
                                    {
                                        (editLoanerDetails && accessFn('ACCEPT_RETURN_DEVICE') && ['LORQ','LDOA','LOSE'].indexOf(currentLoanerStatus) > -1 ) &&
                                        <Table.Data> {loanerDetails && loanerDetails.loanerDeviceSerialNumber ? loanerDetails.loanerDeviceSerialNumber :'-'} </Table.Data>
                                    }
                                    {
                                        editLoanerDetails && accessFn('REPAIR_DEVICE') && ['LORQ','LDOA','LOSE'].indexOf(currentLoanerStatus) > -1 && enableEditLoanerDetails &&
                                        <Table.Data>
                                            <Input
                                                key="loanerDeviceSerialNumber"
                                                id="loanerDeviceSerialNumbers"
                                                value={inputData.loanerDeviceSerialNumber}
                                                handleOnChange={(e) => {
                                                    checkImeiValueValid(e.target.value);
                                                }}
                                                isDisabled={!loanerSelected}
                                                maxLength={12}
                                                otherProps={{pattern:"\d*"}}
                                            />
                                        </Table.Data>
                                    }
                                </Table.Row>
                            }
                            {
                                loanerDetails && loanerDetails.loanerRequested &&  (['LOAI','LOST','LOIG','LODS','LORC'].indexOf(currentLoanerStatus) > -1) &&
                                <Table.Row>
                                    <Table.Data className="table-keys"> {localString("Loaner Disposition")} </Table.Data>
                                    <Table.Data> {loanerDetails && loanerDetails.dispostionDescription? loanerDetails.dispostionDescription :'-'} </Table.Data>
                                </Table.Row>
                            }                    
                            {
                                loanerDetails && loanerDetails.loanerRequested && loanerDetails.LoanerCosmeticRemark &&
                                <Table.Row>
                                    <Table.Data className="table-keys"> {localString("Loaner Cosmetic Remarks")} </Table.Data>
                                    <Table.Data>{loanerDetails && loanerDetails.LoanerCosmeticRemark ? loanerDetails.LoanerCosmeticRemark :'-'} </Table.Data>
                                </Table.Row>
                            }
                            {
                                loanerDetails && loanerDetails.loanerRequested && loanerDetails.chargetoCustomer &&
                                <Table.Row>
                                    <Table.Data className="table-keys"> {localString("Amount Payable by Customer")} </Table.Data>
                                    <Table.Data> <span className="currency" dangerouslySetInnerHTML={{__html: currencySymbol + " "}}></span> {loanerDetails && loanerDetails.chargetoCustomer? loanerDetails.totalCharges :'-'} </Table.Data>
                                </Table.Row>
                            }
                            {
                                loanerDetails && loanerDetails.cancelledLoaner && loanerDetails.cancelledLoaner.length > 0 &&
                                <Table.Row>
                                    <Table.Data className="table-keys"> {localString("Loaner cancellation reason")} </Table.Data>
                                    <Table.Data>{loanerDetails.cancelledLoaner[0].Remarks ? loanerDetails.cancelledLoaner[0].Remarks : ''}</Table.Data>
                                </Table.Row>
                            }
                        </Table.Body>
                    </Table>
                    <br/>
                    {
                        loanerDetails && loanerDetails.loanerEntryDetails && loanerDetails.loanerEntryDetails.length === 0 && accessFn('ACCEPT_RETURN_DEVICE') && loanerDetails.totalQuantity > 0 && checkGSXRepair() &&
                        <div>
                            <div className="pull-left">
                            <Button 
                                handleOnClick={(e)=>savedLoanerdetails(e)} 
                                isDisabled={(loanerDeviceCount <= 0 || activeLoanerStatus == undefined)} 
                                type='submit' 
                                classes='button-ghost' 
                                text={localString('Save')}
                            />
                            </div>
                        </div>
                    }
                    {
                        editLoanerDetails && 
                        <div className="pull-right">
                            {
                                loanerDetails.loanerRequested && csrLoanerDetails && 
                                ((['LORQ','LOSE','LDOA', 'LOIS'].indexOf(currentLoanerStatus) > -1 && enableEditLoanerDetails) || 
                                (['LORQ', 'LOSE', 'LOIS'].indexOf(currentLoanerStatus) > -1 && accessFn('ACCEPT_RETURN_DEVICE'))) &&
                                <Button 
                                    handleOnClick={getLoanerCancelStatusList} 
                                    type='button' 
                                    classes='button-ghost' 
                                    text={localString('Cancel Loaner request')}
                                />
                            }
                            {
                                loanerDetails.loanerRequested && accessFn('REPAIR_DEVICE') && ['LORQ','LDOA','LOSE'].indexOf(currentLoanerStatus) > -1 && enableEditLoanerDetails &&
                                <Button 
                                    handleOnClick={updateLoanerDetails} 
                                    isDisabled={!enableLonaerSave}
                                    type='button' 
                                    classes='button-ghost' 
                                    text={localString('Save Loaner Details')}
                                />
                            }
                            {
                                loanerDetails.loanerRequested && loanerDetails.loanerDeviceSerialNumber && accessFn('REPAIR_DEVICE') && ['LDOA','LOSE'].indexOf(currentLoanerStatus) === -1 && 
                                <Button 
                                    handleOnClick={showDispositionModal} 
                                    type='button' 
                                    classes='button-ghost' 
                                    text={localString('Loaner Disposition')}
                                />
                            }
                            <Button 
                                handleOnClick={cancelDetailsClick} 
                                type='button' 
                                classes='button-ghost' 
                                text={localString('Cancel')}
                            />
                        </div>
                    }
                </div>
            }
            {
                loanerDispositionModal &&  
                <LoanerDispositionModal
                    {...props}
                />
            }
            {
                loanerCancelModal && 
                <LoanerCancelModal 
                    {...props}
                />
            }
            {
                !loanerDetails && 
                <div className="text-left">
                    <p className='charges-panel-subheader text-capitalize remove-margin'>{localString("No Loaner Details")}</p>
                </div>
            }
            </div>
        </>
    )
}

export default LoanerDetails