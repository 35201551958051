import React, { useState } from 'react';
import {Modal,Button,Input} from 'sfy-react';
import Swal from "sweetalert2";
import { returnTimezone,displayError } from '../../../../../Utility/Utils';

const ReassignInput = (props)=>{
    const {localString,closeModal}=props;
    const reassignrequest=()=>{
        console.log("11111")
    }
    return(
        <>
            <div className="fluid-form" id="fluidForm">
                <div className="modal-fluid-form-container">
                    <div>
                        <div className="form-group register-filter">
                            <Input
                                // value={text}
                                handleOnChange={(e) => {
                                // setText(e.target.value)
                                }}
                                label={localString("Service Center")}
                                placeholder={localString("Select a service center")}
                            />
                        </div>
                        <div className="form-group register-filter {ServiceTypeID == 2 ? 'hidden': ''}">
                            <Input
                                handleOnChange={(e) => {
                                // setText(e.target.value)
                                }}
                                label={localString("Service Engineer")}
                                placeholder={localString("Select an engineer")}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button type="button" className="btn button-servify button-stack" handleOnClick={()=>{reassignrequest()}} >{localString("Reassign")}</Button>
                        <Button type="button" className="btn button-servify" handleOnClick={closeModal}>{localString("Close")}</Button>
                </div>
                </div>
            </div>
        </>
    )
}
export default ReassignInput;