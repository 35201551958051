import React from "react";

const SidebarElement = (props) => {
	// console.log(props.redirectUrl);
	return (
		<>
			{!props.iframeredirect && (<a
				href={props.redirectUrl}
				className={props.divClass + props.showSelected}
			>
				<p>
					{props.showPoweredBy && (
						<span class="powered-by">Powered By</span>
					)}
					<div className={"sidebar-icon " + props.logoClass}></div>
					{props.showSecondaryLogo && (
						<div
							className={
								"sidebar-main-logo sidebar-logo-height " +
								props.secondaryLogoClass
							}
						></div>
					)}
					{props.showText && (
						<span className="sidebar-label">{props.routeText}</span>
					)}
				</p>
			</a>)}
			{props.iframeredirect && (<a
				href='#'
				onClick={(e) => {
					e.preventDefault();
					window.location.href = props.redirectUrl;
					// window.parent.location.reload();
				}}
				className={props.divClass + props.showSelected}
			>
				<p>
					<div className={"sidebar-icon " + props.logoClass}>
						{ props.imageName &&
							<img
								src={`${props.publicUrl}/imgs/${props.imageName}`}
								alt="Redirect"
								// width='20px' 
							/>
						}
					</div>
					{props.showSecondaryLogo && (
						<div
							className={
								"sidebar-main-logo sidebar-logo-height " +
								props.secondaryLogoClass
							}
						></div>
					)}
					{props.showText && (
						<span className="sidebar-label">{props.routeText}</span>
					)}
				</p>
			</a>)}
		</>
	);
};

export default SidebarElement;
