import React, { useEffect, useState } from 'react';
import { Button } from 'sfy-react';

import { displayError, SERVICETYPE } from '../../../Utility/Utils';

export default function ReplacementOptions(props) {
    const {
        localString, serviceMode, replacementOptions, setReplacementOptions, replacementAllocationType, 
        setReplacementAllocationType, selectedReplacementOption, setSelectedReplacementOption, setCallRequestFunction, 
        type = 'raiseRequest', setShowRaiseReplacementRequest, scheduleReplacement, showRaiseReplacementRequest, 
        showRaiseDeviceExchange, setShowRaiseDeviceExchange, getServiceLocationsForCarryIn
    } = props;

    const replacementOptionClick = (obj) => {
        if(type == 'raiseRequest') {
            setSelectedReplacementOption(obj);
            setCallRequestFunction(true);
            //advanceExchangeLegalConfigsList && advanceExchangeLegalConfigsList.length > 0 ? setShowAdvanceExchangeConsent(true) : setCallRequestFunction(true); // Before Raising Request Show the Consent Check for Advance Exchange if consent data is present
        }else {
            setSelectedReplacementOption(obj);
            if([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(serviceMode.ServiceTypeID) > -1){
                // if(advanceExchangeLegalConfigsList && advanceExchangeLegalConfigsList.length){
                //     setShowAdvanceExchangeConsent(true);
                // }else{
                    setShowRaiseDeviceExchange(true);
                //}
            }else if([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(serviceMode.ServiceTypeID) > -1){
                setShowRaiseReplacementRequest(true);
            }else if([SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(serviceMode.ServiceTypeID) > -1){
                getServiceLocationsForCarryIn && getServiceLocationsForCarryIn();
            }
        }
    }

    useEffect(() => {
        return () => {
            setReplacementAllocationType({});
            setReplacementOptions([]);
            setSelectedReplacementOption({});
        }
    }, []);

    return (
        <>
            { (replacementOptions && replacementOptions.length > 0) && 
                <div id='ReplacementOptions' className="raiseReqSection">
                    <div className='serviceModes'>
                        <div className="row">
                            <div className="col-sm-10 col-xs-8 form-label">
                                { replacementAllocationType && (replacementAllocationType == "EXACT") && <label>{localString("We have got an exact match.")}</label> }
                                { replacementAllocationType && (replacementAllocationType == "LIKE-TO-LIKE") && <label>{localString("We have got a like to like match.")}</label> }
                                { replacementAllocationType && (replacementAllocationType == "ALTERNATE") && <label>{localString("Select a Replacement Option")}</label> }
                                { replacementAllocationType && (replacementAllocationType == "UPGRADE") && <label>{localString("Your Device has been upgraded")}</label> }
                            </div>
                        </div>
                        <div className="row service-mode-container">
                            {
                                replacementOptions && replacementOptions.length > 0 && replacementOptions.map((item, k) => 
                                    <Button
                                        // className="btn button-servify nextBtn" 
                                        className="col-sm-3 col-xs-4 mode-button" 
                                        onClick={() => replacementOptionClick(item)}
                                    >
                                        <div className={`item subcat-item field-tip ${(selectedReplacementOption.Option == item.Option) ? 'item-active-border' : ''}`} >
                                            <p className="text-capitalize">{localString(item?.ProductName)}</p>
                                            { item?.ProductSkuAttributes?.Colour && <p><span>{localString("Colour")}</span>:{item.ProductSkuAttributes.Colour}</p> }
                                            { item?.ProductSkuAttributes?.Carrier && <p><span>{localString("Carrier")}</span>:{item.ProductSkuAttributes.Carrier}</p> }
                                            { item?.ProductSkuAttributes?.Storage && <p><span>{localString("Storage")}</span>:{item.ProductSkuAttributes.Storage}</p> }
                                            { item?.ProductSkuAttributes?.RAM && <p><span>{localString("RAM")}</span>:{item.ProductSkuAttributes.RAM}</p> }
                                        </div>
                                    </Button>
                                )
                            }
                    </div>
                    </div>
                </div>
            }
            {
                ((type !== 'raiseRequest') && showRaiseDeviceExchange && serviceMode && (serviceMode.ServiceTypeID == SERVICETYPE.ADVANCE_EXCHANGE)) && 
                    <div className='pull-right' style={{ 'paddingBottom': '10px' }}>
                        <Button 
                            className="btn button-servify no-border-radius"
                            onClick={() => setCallRequestFunction(true)}
                            // isDisabled={disableBtn}
                        >
                            {localString("Raise Request")}
                        </Button>
                    </div>
                        
            }
            {
                ((type !== 'raiseRequest') && showRaiseReplacementRequest && serviceMode && ([SERVICETYPE.CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE, SERVICETYPE.COLLECT_REPLACEMENT_WITHOUT_DEFECTIVE].indexOf(serviceMode.ServiceTypeID) > -1)) && 
                    <div className='pull-right' style={{ 'paddingBottom': '10px' }}>
                        <Button 
                            className="btn button-servify no-border-radius"
                            onClick={() => scheduleReplacement()}
                            // isDisabled={disableBtn}
                        >
                            {localString("Raise Request")}
                        </Button>
                    </div>
                        
            }
        </>
    )
}