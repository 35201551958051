import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2"; 
import {find,concat} from 'lodash';
import {displayError,checkMobile,checkName, getMobileMaxLength, checkMobileNumber} from '../../../Utility/Utils';
import FilterInputs from '../../FilterInputs/FilterInput';
import './CallLogPage.scss'
import CommunicationHistory from './CommunicationHistory';
import { getCookie } from "../../../Utility/Utils";

const CallLogPage = (props) => {
	// removed activeTab
	const {localString, apiCall, qFilter, consumerID, consumerServiceRequest, showCommunicationLogs, consumerDetails, showCallHistory,
		showEmailHistory, CSRViewRequest, setActiveTab, closeModal, allTabs} = props;
	const qfilter = JSON.parse(qFilter);
	const [callTypeValue, setCallTypeValue] = useState("Incoming");
	const [connected, setConnected] = useState("Yes");
	const [showLoader ,setLoader] = useState(false);
	const [communicationLogs, setCommunicationLogs] = useState([])
	const [subcategoryData,setSubcategoryData] = useState({});
	const [brandData,setBrandData] = useState({});
	const [productData, setProductData] = useState({})
	const [firstCallDisposition, setFirstCallDisposition] = useState({})
	const [secondDisposition, setSecondDisposition] = useState({})
	const [callConnected, setCallConnected] = useState({})
	const [callNotConnected, setCallNotConnected] = useState({})
	const [finalSecondDispo, setFinalSecondDispo] = useState([])
	const [disableApplyButton, setDisableApplyBtn] = useState(true);
	const [callDispositionArray, setCallDispositionArray] = useState([])
	const [emailHistoryData, setEmailHistoryData] = useState();
	const [damageTypeArray, setDamageTypeArray] = useState()
	const [defaultSelectedCallConnectedID, setDefaultSelectedCallConnectedID] = useState({});
	const [callLogData, setCallLogData] = useState({
		BrandName:"",
		BrandID:"",
		ProductSubCategory:"",
		ProductSubCategoryID:"",
		ProductName:"",
		ProductID:"",
		CallConnectedID:"",
		CallConnectedText:"",
		FirstDialerDispositionId:"",
		FirstDialerDispositionText:"",
		CallNotConnectedID:"",
		CallNotConnectedText:"",
		SecondDailerDispostionText:"",
		CallingNumber:"",
		SupportTicketNumber:"",
		NameOfAgent:getCookie("username"),
		Remark:"",
		phoneCode:"",
	})
	const [loaded, setLoaded] = useState ({
		brandLoaded:false,
		productLoaded:false,
		subCategoryLoaded:false
	})
	const [disableProduct, setDisableProduct] = useState(true)

	const [countryCode, setCountryCode] = useState(qfilter && qfilter.countrycode && qfilter.countrycode.length > 0 ? qfilter.countrycode[0].toUpperCase()  : "IN")
	const [mobileMaxLength, setMobileMaxLength] = useState([])

	useEffect(() => {
		if((callLogData.ProductSubCategory && callLogData.ProductSubCategory.length > 0) 
		&& (callLogData.BrandName && callLogData.BrandName.length > 0)){
			setDisableProduct(false)
		}else{
			setDisableProduct(true)
		}
	}, [callLogData])

	useEffect(() => {
		let selectedCallConnectedID = find(
			callConnected, function(o) {
				return o.id == 106;
			}
		);
		setDefaultSelectedCallConnectedID(selectedCallConnectedID);
		resetCalllogData();
		setCallLogData({...callLogData,
			['CallConnectedID']: selectedCallConnectedID ? selectedCallConnectedID.id : "",
			['CallConnectedText']: selectedCallConnectedID ? selectedCallConnectedID.displayText : ""
		});
	}, [callConnected])


	const getConsumerCommunicationLog = () => {
		const Data ={
			'ConsumerID' : consumerID
		}
		setLoader(true);
		apiCall({
			url:'getConsumerCommunicationLogs',
			data: Data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					setCommunicationLogs(result.data)
				}else{
					// Swal.fire({  
					// 	icon: 'error',  
					// 	text: localString(displayError(result)),  
					// }); 
				}
			}	
		});
	}

	const loadBrands = () => {
		setLoader(true);
		let data = {
			qFilter:{}
		}
		if(callLogData && callLogData.ProductSubCategoryID){
			data.qFilter.productSubCategories=[callLogData.ProductSubCategoryID]
		}
		apiCall({
			url:'GetBrands',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let data = result.data.map(v => ({...v,value:v.BrandName}))
					setBrandData(data)
					setLoaded({...loaded,brandLoaded:true})
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const loadSubCategories = () => {
		setLoader(true);
		let Data = {
			qFilter:{}
		}
		if(callLogData && callLogData.BrandID){
			Data.qFilter.brands=[callLogData.BrandID]
		}
		apiCall({
			url:'getSubcategory',
			data: Data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let data = result.data.map(v => ({...v, value: v.ProductSubCategory}))
					setSubcategoryData(data);
					setLoaded({...loaded,subCategoryLoaded:true})
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const loadProducts = () => {
		setLoader(true);
		let data = {
			qFilter:{}
		}
		if(callLogData && callLogData.BrandID){
			data.qFilter.brands=[callLogData.BrandID]
			
		}
		if(callLogData && callLogData.ProductSubCategoryID){
			data.qFilter.productSubCategories=[callLogData.ProductSubCategoryID]
		}
		apiCall({
			url:'GetProducts',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let data = result.data.map(v => ({...v, value: v.ProductName}))
					setProductData(data);
					setLoaded({...loaded,productLoaded:true});
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const getCallDispositionValue = ()=> {
		setLoader(true);
		apiCall({
			url: 'getCallStatus',
			data: {},
			showLoader: false,
			callBack:(result)=> {
				setLoader(false);
				if (result.success) {

					let firstDisposition = result.data.firstDisposition.map(v => ({...v, value: v.displayText}))
					setFirstCallDisposition(firstDisposition)
					setSecondDisposition(result.data.secondDisposition);

					let connected = result.data.callConnected.map(v => ({...v, value: v.displayText}))
					setCallConnected(connected);
					// let selectedCallConnectedID = find(
					// 	connected, function(o) {
					// 			return o.id == 106;
					// 	}
					// );
					// setDefaultSelectedCallConnectedID(selectedCallConnectedID);
					// setCallLogData({...callLogData,
					// 	['CallConnectedID']:selectedCallConnectedID ?selectedCallConnectedID.id:"",
					// 	['CallConnectedText']:selectedCallConnectedID?selectedCallConnectedID.displayText:"",
					// });

					let notConnected = result.data.callNotConnected.map(v => ({...v, value: v.displayText}))
					setCallNotConnected(notConnected);

					let callDispositionArr = concat(result.data.callConnected, result.data.callNotConnected);
					setCallDispositionArray(callDispositionArr)
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}
		});
	}

	const cbFunction=()=>{
		closeModal();
		setActiveTab({...allTabs,['showCommunicationHistory']: true})
	}

	const saveCallLog = () => {
		setLoader(true);
		let Data={};
		Data.senderName = callLogData.NameOfAgent;
		Data.customerCallingNumber = callLogData.CallingNumber;
		Data.supportTicketNumber = callLogData.SupportTicketNumber;
		Data.BrandID = callLogData.BrandID;
		Data.ProductSubCategoryID = callLogData.ProductSubCategoryID;
		Data.ProductID = callLogData.ProductID;
		Data.ConsumerServiceRequestID = consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID;
		Data.customerCallingPhoneCode = callLogData.phoneCode ? callLogData.phoneCode : callLogData.countryData.dialCode
		Data.communicationDetail ={
			callIncoming: callTypeValue == "Incoming" ? true : false,
			callConnected : connected == "Yes" ? true : false ,
			callStatusId : callLogData.CallConnectedID && callLogData.CallConnectedID ,
			status: consumerServiceRequest ? consumerServiceRequest.Status : undefined,
			remark : callLogData.Remark ,
			firstDailerDispostion:callLogData.FirstDialerDispositionText,
			secondDailerDispostion:callLogData.SecondDailerDispostionText
		}
		if (consumerServiceRequest) {
			Data.BrandID = consumerServiceRequest.consumerProduct.BrandID;
			Data.ProductSubCategoryID = consumerServiceRequest.consumerProduct.ProductSubCategoryID;
			Data.ProductID = consumerServiceRequest.consumerProduct.ProductID;
			Data.ConsumerServiceRequestID = consumerServiceRequest.ConsumerServiceRequestID;
		}
		if(callTypeValue == "Incoming"){
			Data.receiverRealm = 'user'
			Data.receiverUserId = qfilter.userID
			Data.senderUserId = consumerID;
			Data.senderRealm = 'Consumer';
		}else{
			Data.receiverRealm ='Consumer';
			Data.receiverUserId = consumerID;
		}
		apiCall({
			url:'createCommunicationLog',
			data: Data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					resetCalllogData();
					if (consumerServiceRequest) { 					
						//Request getting from View Service Request Page
						cbFunction();
					}
					else {
						getConsumerCommunicationLog();
						getCallDispositionValue()
					}
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const resetCalllogData = ()=>{
		setCallLogData({
			BrandName:"",
			BrandID:"",
			ProductSubCategory:"",
			ProductSubCategoryID:"",
			ProductName:"",
			ProductID:"",
			CallConnectedID:defaultSelectedCallConnectedID ? defaultSelectedCallConnectedID.id:"",
			CallConnectedText:defaultSelectedCallConnectedID ? defaultSelectedCallConnectedID.displayText:"",
			FirstDialerDispositionId:"",
			FirstDialerDispositionText:"",
			CallNotConnectedID:"",
			CallNotConnectedText:"",
			SecondDailerDispostionText:"",
			CallingNumber:"",
			SupportTicketNumber:"",
			NameOfAgent:getCookie("username"),
			Remark:""
		})
	}

	const saveClickFunction =(e)=>{
		e.preventDefault();
		saveCallLog();
	}

	const getEmailCommunicationLogs = () =>{
		setLoader(true);
		let Data = {
			qFilter:{},
			'EmailID': consumerDetails.EmailID
		}
		apiCall({
			url:'getEmailCommunicationLogs',
			data: Data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let data = result.data
					setEmailHistoryData(data);
				}else{
					// Swal.fire({  
					// 	icon: 'error',  
					// 	text: localString(displayError(result)),  
					// }); 
				}
			}	
		});
	}

	const getCountries = (country) => {
		let data = {
			specificCountryCode: country ? country.toUpperCase() : countryCode,
			countryConfig: true
		}
		setLoader(true);
		apiCall({
			url:'getCountries',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let countryData = result.data.countries[0];
					let maxLength = getMobileMaxLength(countryData);
					setMobileMaxLength(maxLength);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const secondDispositionKey = callLogData.FirstDialerDispositionText ? secondDisposition[callLogData.FirstDialerDispositionText].map(item=>({secondDailerDispostion: item, value: item})) : [];

	useEffect(() => {
		setFinalSecondDispo(secondDispositionKey)
	}, [callLogData.FirstDialerDispositionText])

	useEffect(() => {
		getCountries();
		getCallDispositionValue();
		if(showCallHistory){
			getConsumerCommunicationLog()
		}
		if(showEmailHistory){
			getEmailCommunicationLogs()
		}
	}, []);

	useEffect(() => {
		checkDisableApplyBtn();
	}, [callLogData]);

	const checkDisableApplyBtn = () => {
		if( !CSRViewRequest 
			&& (callLogData.CallingNumber.length && checkMobileNumber(callLogData.CallingNumber) && mobileMaxLength.includes(callLogData.CallingNumber.length))
			&& (callLogData.NameOfAgent.length > 0) 
			&& (callLogData.SupportTicketNumber.length > 0)
			&& (callLogData.Remark.length > 0)
			&& (callLogData.BrandName.length > 0)
			&& (callLogData.ProductSubCategory.length > 0)
			&& (callLogData.ProductName.length > 0)){
				if(callTypeValue === "Outgoing"){
					if(connected === "Yes"){
						if((callLogData.CallConnectedText.length > 0)
							&& (callLogData.FirstDialerDispositionText.length > 0)
							&& (callLogData.SecondDailerDispostionText.length > 0))
						setDisableApplyBtn(false)
					}else if(connected === "No"){
						if(callLogData.CallNotConnectedText.length > 0) {
							setDisableApplyBtn(false);
						}
					}
				}else{
					if((callLogData.CallConnectedText.length > 0)
						&& (callLogData.FirstDialerDispositionText.length > 0)
						&& (callLogData.SecondDailerDispostionText.length > 0))
					setDisableApplyBtn(false)
				}
		} else if( CSRViewRequest 
			&& (callLogData.CallingNumber.length && checkMobileNumber(callLogData.CallingNumber) && mobileMaxLength.includes(callLogData.CallingNumber.length))
			&& (callLogData.NameOfAgent.length > 0) 
			// && (callLogData.SupportTicketNumber.length > 0)
			&& (callLogData.Remark.length > 0)){
				if(callTypeValue === "Outgoing"){
					if(connected === "Yes"){
						if((callLogData.CallConnectedText.length > 0)
							&& (callLogData.FirstDialerDispositionText.length > 0)
							&& (callLogData.SecondDailerDispostionText.length > 0))
						setDisableApplyBtn(false)
					}else if(connected === "No"){
						if(callLogData.CallNotConnectedText.length > 0) {
							setDisableApplyBtn(false);
						}
					}
				}else{
					if((callLogData.CallConnectedText.length > 0)
						&& (callLogData.FirstDialerDispositionText.length > 0)
						&& (callLogData.SecondDailerDispostionText.length > 0))
					setDisableApplyBtn(false)
				}
		}else {
			setDisableApplyBtn(true);
		}
	}

	const getCallStatus = (callStatusId)=> {
		let callStatus = find(callDispositionArray, {
			id: callStatusId
		});
		if (callStatus && callStatus.displayText) {
			return callStatus.displayText;
		} else {
			return '-';
		}
	}

	const getTypeOfDamage = (damageId) =>{
        let damage = find(damageTypeArray, {
            id: damageId
        });
        if (damage && damage.displayText) {
            return damage.displayText;
        } else {
            return '-';
        }
    }

	let inputLables = [
		{
			showFilter:true,
			type: "Radio",
			customClass:"col-md-12 form-group main-radio",
			labelText: localString("Call Type") + " :",
			radioButtons:[{
				buttonText: localString("Incoming"),
				value:"Incoming",
				isSelect:callTypeValue,
				onclickupdate:(val)=>{
					setCallTypeValue(val)
					resetCalllogData()
				}
			},
			{
				buttonText: localString("Outgoing"),
				value:"Outgoing",
				isSelect:callTypeValue,
				onclickupdate:(val)=>{
					setCallTypeValue(val)
					setConnected("Yes")
					resetCalllogData()
				}
			}],
		},
		{
			showFilter:(callTypeValue == "Outgoing" ? true :false),
			type: "Radio",
			labelText: localString("Connect") + " :",
			customClass:"col-md-12 form-group inner-radio",
			radioButtons:[{
				buttonText:localString("Yes"),
				value:"Yes",
				isSelect:connected,
				onclickupdate:(val)=>{
					setConnected(val)
					resetCalllogData()
				}
			},
			{
				buttonText:localString("No"),
				value:"No",
				isSelect:connected,
				onclickupdate:(val)=>{
					setConnected(val)
					resetCalllogData()
				}
			}],
		},
		{
			labelText:localString('Brand'),
			dataKey:"BrandName",
			showFilter:CSRViewRequest ? !CSRViewRequest :true,
			type :'Dropdown',
			dropdownList:brandData,
			customClass:"col-md-4 scrollable-dropdown-menu",
			oninputFunc: (val) => {
				setCallLogData({...callLogData,
					['BrandName']:val.BrandName,
					['BrandID']:val.BrandID,
					// ['ProductSubCategory']:"",
					// ['ProductSubCategoryID']:"",
					['ProductName']:"",
					['ProductID']:""
				});
				setLoaded({...loaded,subCategoryLoaded:false,productLoaded:false})
			},
			oninputFocus: () => {
				if(!loaded.brandLoaded){
					loadBrands();
				}
			}
		},
		{
			labelText:localString('Subcategory'),
			dataKey:"ProductSubCategory",
			type :'Dropdown',
			dropdownList:subcategoryData,
			showFilter:CSRViewRequest ? !CSRViewRequest :true,
			customClass:"col-md-4 scrollable-dropdown-menu",
			oninputFunc: (val) => {
				setCallLogData({...callLogData,
					['ProductSubCategory']:val.ProductSubCategory,
					['ProductSubCategoryID']:val.ProductSubCategoryID,
					['ProductName']:"",
					['ProductID']:""
				});
				setLoaded({...loaded,brandLoaded:false,productLoaded:false})
			},
			oninputFocus: () => {
				if(!loaded.subCategoryLoaded){
					loadSubCategories();
				}
			}
		},
		{
			labelText:localString('Product'),
			dataKey:"ProductName",
			type :'Dropdown',
			dropdownList:productData,
			showFilter:CSRViewRequest ? !CSRViewRequest :true,
			customClass:"col-md-4 scrollable-dropdown-menu",
			isDisabled:disableProduct,
			oninputFunc: (val) => {
				setCallLogData({...callLogData,
					['ProductName']:val.ProductName,
					['ProductID']:val.ProductID
				});
			},
			oninputFocus: () => {
				if(!loaded.productLoaded){
					loadProducts();
				}
			}
		},
		// {
		// 	labelText:localString('Calling Number'),
		// 	dataKey:"CallingNumber",
		// 	showFilter:true,
		// 	type :'input',
		// 	customClass:"col-md-6 scrollable-dropdown-menu",
		// 	oninputFunc: (val) => {
		// 		setCallLogData({...callLogData,
		// 			['CallingNumber']:val
		// 		});
		// 	}
		// },
		{
			labelText: localString('Calling Number'),
			dataKey: "CallingNumber",
			maxlength: mobileMaxLength,
			customClass: 'col-md-6 scrollable-dropdown-menu',
			type: "intelInput",
			showFilter: true,
			defaultCountry: countryCode.toLowerCase(),
			intlTelInputClassWrapper: {
				formControl: 'form-control--top ',
				label: 'input__label',
				containerClass: 'input-container',
				inputClass: 'input'
			},
			changeFunc: (val, countryData) => {
				setCallLogData({...callLogData,
					['CallingNumber']:val , countryData,
					['phoneCode']:countryData.dialCode
				});
			},
			onFlagSelect: (countryData) => {
				setCallLogData({...callLogData,
					['CallingNumber']: "",
					['phoneCode']:countryData.dialCode
				});
				getCountries(countryData.iso2);
			}
		},
		{
			labelText:localString('Support Ticket Number'),
			dataKey:"SupportTicketNumber",
			showFilter:true,
			type :'input',
			customClass:"col-md-6 scrollable-dropdown-menu",
			oninputFunc: (val) => {
				setCallLogData({...callLogData,
					['SupportTicketNumber']:val
				});
			}
		},
		{
			labelText:localString('Name of the Agent'),
			dataKey:"NameOfAgent",
			showFilter:true,
			type :'input',
			customClass:"col-md-12 scrollable-dropdown-menu",
			oninputFunc: (val) => {
				setCallLogData({...callLogData,
					['NameOfAgent']:val
				});
			}
		},
		{
			labelText:localString('Connect Disposition'),
			dataKey:"CallConnectedText",
			type :'Dropdown',
			dropdownList:callConnected,
			showFilter:(connected && connected === "Yes"),
			customClass:"col-md-12 form-group callDisposition-scroll",
			oninputFunc: (val) => {
				setCallLogData({...callLogData,
					['CallConnectedID']:val.id,
					['CallConnectedText']:val.displayText,
				});
			}
		},
		{
			labelText:localString('First Dialer Disposition'),
			dataKey:"FirstDialerDispositionText",
			type :'Dropdown',
			dropdownList:firstCallDisposition,
			showFilter:(connected && connected === "Yes"),
			customClass:"col-md-12 form-group scrollable-dropdown-menu",
			oninputFunc: (val) => {
				setCallLogData({...callLogData,
					['FirstDialerDispositionId']:val.Id,
					['FirstDialerDispositionText']:val.displayText,
					['SecondDailerDispostionText'] : ""
				});
				
			}
		},
		{
			labelText:localString('Second Dialer Disposition'),
			dataKey:"SecondDailerDispostionText",
			type :'Dropdown',
			dropdownList:finalSecondDispo,
			showFilter:(connected && connected === "Yes"),
			customClass:"col-md-12 form-group scrollable-dropdown-menu",
			oninputFunc: (val) => {
				setCallLogData({...callLogData,
					['SecondDailerDispostionText'] : val.secondDailerDispostion
				});
			}
		},
		{
			labelText:localString('Non-Connect Disposition'),
			dataKey:"CallNotConnectedText",
			type :'Dropdown',
			dropdownList:callNotConnected,
			showFilter:(connected && connected === "No"),
			customClass:"col-md-12 form-group scrollable-dropdown-menu",
			oninputFunc: (val) => {
				setCallLogData({...callLogData,
					['CallConnectedID']:val.id,
					['CallNotConnectedText']:val.displayText,
				});
			}
		},
		{
			labelText:localString('Remarks'),
			dataKey:"Remark",
			showFilter:true,
			type :'input',
			inputComponent:'textarea',
			label:"Remark",
			customClass:"col-md-12 form-group scrollable-dropdown-menu",
			oninputFunc: (val) => {
				setCallLogData({...callLogData,
					['Remark']:val
				});
			}
		}
	]

	return (
		<>
			{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="calllog-padding">{localString("Add call log")}
				<div className="call-type">
					<FilterInputs {...props}  callLogTitle="Add call log" classname="form-group" filterInputs={inputLables} filterData={callLogData} saveButtonText={"Save"} connected={connected} filterFunc={saveClickFunction} hideClearButton={true} disableApplyBtn={disableApplyButton}></FilterInputs>
				</div>
			</div>
			{showCommunicationLogs && <CommunicationHistory {...props} showCallHistory={showCallHistory} showEmailHistory={showEmailHistory} consumerDetails={consumerDetails} communicationLogs={communicationLogs} emailHistoryData={emailHistoryData} getCallStatus={getCallStatus}></CommunicationHistory>}
		</>
	)
}

export default CallLogPage