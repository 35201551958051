import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import {find } from 'lodash';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import {displayError, SERVICETYPE} from '../../../../../Utility/Utils';
import DeliveryAddressHeader from './DeliveryAddressHeader';
import ReturnAddressDetails from './ReturnAddressDetails';
import ShippingDetails from './ShippingDetails';

const PackagingMaterialDetails = (props) => {
    const {apiCall, accessFn, localString, consumerServiceRequest, emptyBoxPackagingDetails, addresses, getRequestById} = props;
	const [loader, setLoader] = useState(false);
    const [tabHeading, setTabHeading] = useState("Packaging Material Details");
    const [isAdvanceExchange, setIsAdvanceExchange] = useState();
    const [isReplacementFlow, setIsReplacementFlow] = useState(false);
    const [vendorList, setVendorList] = useState([])
    const [isEmptyBoxEditable, setIsEmptyBoxEditable] = useState(true);
    const [deliveryAddressHeader, setDeliveryAddressHeader] = useState("Delivery Address of Packaging Material");
    const [returnAddressHeader, setReturnAddressHeader] = useState("Service Center Address");
    const [shippingToCustomerHeader, setShippingToCustomerHeader] = useState("For Packaging Material")
    const [shippingToRepairHeader, setShippingToRepairHeader] = useState("For Customer's Device to be Shipped for Repair")
    const [addressString, setAddressString] = useState()
    const [backupAddressString, setBackupAddressString] = useState()
    const [defectivePartner, setDefectivePartner] = useState()
    const [fetchDefectivePartnerCalled, setFetchDefectivePartnerCalled] = useState(false)
    const [isEditInProgress, setIsEditInProgress] = useState(false)
    const [disablePackgingShippingDetailsSave, setDisablePackgingShippingDetailsSave] = useState(true)
    const [packagingMaterialShippingDetails, setPackagingMaterialShippingDetails] = useState()
    const [deviceShippingDetails, setDeviceShippingDetails] = useState()
    const [showDeviceOtherVendorNameInput, setShowDeviceOtherVendorNameInput] = useState(false)
    const [showPackagingOtherVendorNameInput, setShowPackagingOtherVendorNameInput] = useState(false)
    const [inputData, setInputData] = useState({
        packagingVendorName:"",
        packagingVendorID:"",
        deviceVendorName:"",
        deviceVendorID:"",
        deviceAWB:"",
        packagingAWB:"",
        packagingVendorNameOther:"",
        deviceVendorNameOther:""
    })

    useEffect(() => {
        if(([SERVICETYPE.ADVANCE_EXCHANGE].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)){
            setIsAdvanceExchange(true)
            setTabHeading("Add Shipment Details")
            setDeliveryAddressHeader("Delivery Address (Customer/Enterprise)");
            setReturnAddressHeader("Return Address (Repair Service Center)");
            setShippingToCustomerHeader("For Sending Replacement device to Customer/Enterprise")
            setShippingToRepairHeader( "For Customer's Device to be shipped for Repair")
            if(!fetchDefectivePartnerCalled){
                fetchDefectivePartner();
            }
            if(consumerServiceRequest.doortodoorFlow){
                let packagingMaterialShipDetails = consumerServiceRequest.doortodoorFlow
                setPackagingMaterialShippingDetails(packagingMaterialShipDetails)
            }
            if(consumerServiceRequest.advanceExchangeFlow){
                let deviceShipDetails = consumerServiceRequest.advanceExchangeFlow
                setDeviceShippingDetails(deviceShipDetails)
            }
            setIsEmptyBoxEditable(false);
        }
        if(consumerServiceRequest && (consumerServiceRequest.ServiceTypeID == 46 ) ){
            setIsReplacementFlow(true);
            setTabHeading("Shipment Details");
            setDeliveryAddressHeader("Delivery Address (Customer/Enterprise)");
            setReturnAddressHeader("Return Address (Repair Service Center)")
            setShippingToCustomerHeader("For Sending Replacement device to Customer/Enterprise")
            if(consumerServiceRequest.returnFlow){
                let packagingMaterialShipDetails = consumerServiceRequest.returnFlow
                setPackagingMaterialShippingDetails(packagingMaterialShipDetails)
            }
            setIsEmptyBoxEditable(false);
        }
        if (emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0) {
            setIsEmptyBoxEditable(false)
            let packagingMaterialShipDetails = find(emptyBoxPackagingDetails, function (o) { return o.source == 'EmptyBoxLocationID'});
            setPackagingMaterialShippingDetails(packagingMaterialShipDetails)
            let deviceShipDetails = find(emptyBoxPackagingDetails, function (o) { return o.source == 'ConsumerServiceRequestID'});
            setDeviceShippingDetails(deviceShipDetails)
        }

        // if(([SERVICETYPE.DEFECTIVE_REFURBISHMENT].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)> -1)){
        //     setIsAdvanceExchange(true)
        //     setTabHeading("Add Shipment Details")
        //     setDeliveryAddressHeader("Delivery Address (Customer/Enterprise)");
        //     setReturnAddressHeader("Return Address (Repair Service Center)");
        //     setShippingToCustomerHeader("For Sending defective device for refurbishment")
        //     setShippingToRepairHeader( "For shipping refurbished device back to replacement center")
        //     if(!fetchDefectivePartnerCalled){
        //         fetchDefectivePartner();
        //     }
        //     if(consumerServiceRequest.orderedLogisticsData && consumerServiceRequest.orderedLogisticsData.length){
        //         let packagingMaterialShipDetails = find(consumerServiceRequest.orderedLogisticsData,function(o){ return o.sourceType == "PartnerServiceLocationID"})
        //         setPackagingMaterialShippingDetails(packagingMaterialShipDetails)
        //         let deviceShipDetails = find(consumerServiceRequest.orderedLogisticsData,function(o){ return o.sourceType == "ConsumerServiceRequestID"})
        //         setDeviceShippingDetails(deviceShipDetails)
        //     }
        //     setIsEmptyBoxEditable(false);
        // }
    }, [consumerServiceRequest])

    useEffect(() => {
        let addrString;
        if (addresses && addresses.length > 0) {
            addrString = find(addresses, (o) => { return o.ConsumerFavouriteLocationID == consumerServiceRequest.ConsumerFavouriteLocationID})
            setAddressString(addrString)
        }
        if (!addrString) {
            let backupAddrString = (consumerServiceRequest.Landmark + ', ' + consumerServiceRequest.STATE + ', ' + consumerServiceRequest.CITY + ', ' + consumerServiceRequest.PinCode)
            setBackupAddressString(backupAddrString)
        }
    }, [addresses])

    useEffect(() => {
        if (emptyBoxPackagingDetails && emptyBoxPackagingDetails.length > 0) {
            setIsEmptyBoxEditable(false);
            let packagingMaterialShipDetails = find(emptyBoxPackagingDetails, function (o) { return o.source == 'EmptyBoxLocationID'});
            setPackagingMaterialShippingDetails(packagingMaterialShipDetails)
            let deviceShipDetails = find(emptyBoxPackagingDetails, function (o) { return o.source == 'ConsumerServiceRequestID'});
            setDeviceShippingDetails(deviceShipDetails)
        }
    }, [emptyBoxPackagingDetails])

    useEffect(() => {
        checkShipmentDetails()
    }, [inputData])

    // useEffect(() => {
    //     if(inputData.packagingVendorName == "Other"){
           
    //     }
    //     if(inputData.deviceVendorName == "Other"){
    //         setShowDeviceOtherVendorNameInput(true)
    //     }else{
    //         setShowDeviceOtherVendorNameInput(false)
    //     }
    // }, [inputData])

    const packageVendorInput = (data) => {
        setInputData({
            ...inputData,
            ['packagingVendorName']:data.VendorName,
            ['packagingVendorID']:data.VendorID
        })
        if(data.VendorName == "Other"){
            setShowPackagingOtherVendorNameInput(true)
        }else{
            setShowPackagingOtherVendorNameInput(false)
        }
    }

    const deviceVendorInput = (data) => {
        setInputData({
            ...inputData,
            ['deviceVendorName']:data.VendorName,
            ['deviceVendorID']:data.VendorID
        })
        if(data.VendorName == "Other"){
            setShowDeviceOtherVendorNameInput(true)
        }else{
            setShowDeviceOtherVendorNameInput(false)
        }
    }

    const initiateEdit = () => {
        getVendorList()
        setIsEditInProgress(true)
    }

    const fetchDefectivePartner = () => {
        setFetchDefectivePartnerCalled(true)
        let data = {
            ConsumerServiceRequestID: consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
        }
		setLoader(true);
		apiCall({
			url: 'fetchDefectivePartner',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    setDefectivePartner(result.data)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

    const getVendorList = () => {
        let data = {
            pickupCountryCode: consumerServiceRequest && consumerServiceRequest.shipmentPickupCountryCode,
            dropCountryCode: consumerServiceRequest && consumerServiceRequest.shipmentDropCountryCode,
            CountryID:consumerServiceRequest && consumerServiceRequest.CountryID
        }
		setLoader(true);
		apiCall({
			url: 'getVendorListByCountry',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    let list = result.data && result.data.map(v => ({...v,value:v.VendorName}));
                    setVendorList(list)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

    const canceleditClick = () => {
        setIsEditInProgress(false)
    }

    const checkShipmentDetails = () => {
        if(((!showPackagingOtherVendorNameInput && inputData.packagingVendorName) || (showPackagingOtherVendorNameInput && inputData.packagingVendorNameOther)) && inputData.packagingAWB) {
            if((((!showDeviceOtherVendorNameInput && inputData.deviceVendorName) || (showDeviceOtherVendorNameInput && inputData.deviceVendorNameOther)) && inputData.deviceAWB)) {
                setDisablePackgingShippingDetailsSave(false)
            }else{
                setDisablePackgingShippingDetailsSave(true)
            }
        }else{
            setDisablePackgingShippingDetailsSave(true)
        }
    };

    const saveDetails = () => {
        let URL = "storeEmptyBoxLogisticsDetails"; 
        let data = {};
        data.ConsumerServiceRequestID = consumerServiceRequest.ConsumerServiceRequestID;
        if(consumerServiceRequest.ServiceTypeID == 65 || consumerServiceRequest.ServiceTypeID == 46){
            data.Status = "Replacement shipment created";
            data.CountryID = consumerServiceRequest.CountryID;
            data.shipmentDetails = [{
                "VendorName": showDeviceOtherVendorNameInput ? inputData.deviceVendorNameOther : inputData.deviceVendorName,
                "VendorID": inputData.deviceVendorID,
                "WaybillNumber": inputData.deviceAWB,
                "Flow": 5
            }, {
                "VendorName": showPackagingOtherVendorNameInput ? inputData.packagingVendorNameOther : inputData.packagingVendorName,
                "VendorID": inputData.packagingVendorID,
                "WaybillNumber": inputData.packagingAWB,
                "Flow": 6
            }];
            URL = "replacementFulfillment";
        }else if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.DEFECTIVE_REFURBISHMENT){
                data.Status = consumerServiceRequest.Status;
                data.CountryID = consumerServiceRequest.CountryID;
                data.shipmentDetails = [{
                    "VendorName": showDeviceOtherVendorNameInput ? inputData.deviceVendorNameOther : inputData.deviceVendorName,
                    "VendorID": inputData.deviceVendorID,
                    "WaybillNumber": inputData.deviceAWB,
                    "Flow": 5
                }, {
                    "VendorName": showPackagingOtherVendorNameInput ? inputData.packagingVendorNameOther : inputData.packagingVendorName,
                    "VendorID": inputData.packagingVendorID,
                    "WaybillNumber": inputData.packagingAWB,
                    "Flow": 6
                }];
                URL = "updateRefurbishmentStatus";
        }
        else{
            data.PackagingDetails = [{
                "VendorName": showDeviceOtherVendorNameInput ? inputData.deviceVendorNameOther : inputData.deviceVendorName,
                "VendorID": inputData.deviceVendorID,
                "WaybillNumber": inputData.deviceAWB,
                "Flow": 1
            }, {
                "VendorName": showPackagingOtherVendorNameInput ? inputData.packagingVendorNameOther : inputData.packagingVendorName,
                "VendorID": inputData.packagingVendorID,
                "WaybillNumber": inputData.packagingAWB,
                "Flow": 2
            }];
        }
        setLoader(true);
		apiCall({
			url: URL,
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    setShowPackagingOtherVendorNameInput(false)
                    setShowDeviceOtherVendorNameInput(false)
                    getRequestById();
                    setIsEditInProgress(false)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    return (
        <>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper 
                        bodyClassName="panel-body-no-padding" 
                        title={localString(tabHeading)}
                        showDownloadButtonIcon={(isAdvanceExchange && consumerServiceRequest.Status == 'Replacement allocated') || (isEmptyBoxEditable && !accessFn('READ_ONLY') && accessFn('PACKAGE_DETAILS'))}
                        customSymbol={!isEditInProgress ? "glyphicon glyphicon-plus" : "no-icon"}
                        editDeviceClick={initiateEdit}
                    >
						<div class="request-panel-details-tag request-issue-validation-div col-sm-12 packaging-material-details" style={{padding:"12px"}}>
                            <DeliveryAddressHeader
                                {...props}
                                emptyBoxPackagingDetails={emptyBoxPackagingDetails}
                                deliveryAddressHeader={deliveryAddressHeader}
                                AddressString={addressString}
                                backupAddressString={backupAddressString}
                            />
                            {
                                !isReplacementFlow && 
                                <ReturnAddressDetails
                                    {...props}
                                    returnAddressHeader={returnAddressHeader}
                                    defectivePartner={defectivePartner}
                                    isReplacementFlow={isReplacementFlow}
                                />
                            }
                            <ShippingDetails 
                                {...props}
                                shippingToCustomerHeader={shippingToCustomerHeader}
                                isEditInProgress={isEditInProgress}
                                shippingToRepairHeader={shippingToRepairHeader}
                                packagingMaterialShippingDetails={packagingMaterialShippingDetails}
                                deviceShippingDetails={deviceShippingDetails}
                                vendorList={vendorList}
                                inputData={inputData} setInputData={setInputData}
                                canceleditClick={canceleditClick}
                                disablePackgingShippingDetailsSave={disablePackgingShippingDetailsSave}
                                saveDetails={saveDetails}
                                packageVendorInput={packageVendorInput} deviceVendorInput={deviceVendorInput}
                                showDeviceOtherVendorNameInput={showDeviceOtherVendorNameInput}
                                showPackagingOtherVendorNameInput={showPackagingOtherVendorNameInput}
                                isReplacementFlow={isReplacementFlow}
                            />
                        </div>
					</PageContentWrapper>
				</div>
			</div>
		</>
    )
}

export default PackagingMaterialDetails
