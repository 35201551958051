import React from 'react';
import {Modal} from 'sfy-react';
import UpdateLogisticInput from './UpdateLogisticInput';

const UpdateLogisticModal=(props)=>{
    const {localString,showModal,closeModal} = props;
		return ( <>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal updateLogisticsModal full-screen-modal'>
                <Modal.Header>
                    <h4 className="modal-title"> {localString("Update Logistics Request")} </h4>
                </Modal.Header>
                <Modal.Body>
                    <UpdateLogisticInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </> )
}
export default UpdateLogisticModal;