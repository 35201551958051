import React, { useState } from "react";
import { Loader, Pagination } from "sfy-react";
import ReactDOM from "react-dom";
import "./PaymentDetails.scss";
import CommonTable from "../../../../../components/Table/Table";
import moment from "moment";
import { displayAmount, getDateFormat } from "../../../../../Utility/Utils";

const PaymentDetails = (props) => {
	const {
		localString,
		paymentData,
		defaultCountryLocale,
		publicUrl,
		paymentDetails,
		pagination,
		setPagination,
		countryData
	} = props;
	const [showLoader, setShowLoader] = useState(false);

	const statusText = {
		0: localString("Billing Scheduled"),
		1: localString("Payment Completed"),
		2: localString("Missed Payment"),
		"-2": localString("Payment Failed"),
		"-3": localString("Billing Stopped"),
		4: localString("Billing Stopped"),
		5: localString("Payment Initiated"),
		6: localString("Payment Pending"),
	};

	const statusColor = {
		0: "#FCF0DF",
		1: "#E6F3E5",
		2: "#FDE4E1",
		"-2": "#FDE4E1",
		"-3": "#F2F2F2",
		4: "#F2F2F2",
		5: "#FCF0DF",
		6: "#FCF0DF",
	};
	const statusTextColor = {
		0: "#FF9300",
		1: "#4EAF51",
		2: "#F54336",
		"-2": "#F54336",
		"-3": "#393939",
		4: "#393939",
		5: "#FF9300",
		6: "#FF9300",
	};

	const columns = [
		{
			title: localString("Payment Gateway Transaction ID"),
			showHeading: true,
			customCell: (row) => row?.PaymentGatewayTransactionID || "--",
		},

		{
			title: localString("External Payment ID"),
			showHeading: true,
			customCell: (row) => row?.ExternalPaymentID || "--",
		},

		{
			title: localString("Billed Date (UTC)"),
			showHeading: true,
			customCell: (row) =>
				(row?.BilledDate &&
					moment(row?.BilledDate, "YYYY-MM-DDTHH:mm:ssZ").format(
						getDateFormat(defaultCountryLocale)
					)) ||
				"--",
		},
		{
			title: localString("Billing Cycle Date (UTC)"),
			showHeading: true,
			customCell: (row) =>
				(row?.NextBillingDate &&
					moment(row?.NextBillingDate, "YYYY-MM-DDTHH:mm:ssZ").format(
						getDateFormat(defaultCountryLocale)
					)) ||
				"--",
		},

		{
			title: localString("Transaction Amount"),
			showHeading: true,
			customCell: (row) => (
				<span>
					{ row?.Amount ?
						displayAmount(
							row.Amount,
							(countryData?.countryConfig?.Locale?.DEFAULT || navigator?.language),
							row?.CurrencyCode
						) : '-' }
				</span>
			),
		},

		{
			title: localString("Grace Period End Date"),
			showHeading: true,
			customCell: (row) =>
				(row?.HoldPeriodDate &&
					moment(row?.HoldPeriodDate, "YYYY-MM-DDTHH:mm:ssZ").format(
						getDateFormat(defaultCountryLocale)
					)) ||
				"--",
		},

		{
			title: localString("Transaction Status"),
			showHeading: true,
			customCell: (row) => (
				<span
					className="statusPillPayment"
					style={{
						background: statusColor[row?.Status],
						color: statusTextColor[row?.Status],
					}}
				>
					{statusText[row?.Status] || "--"}
				</span>
			),
		},

		{
			title: localString("Retailer Margin"),
			showHeading: true,
			customCell: (row) => (
				<span>
					{ row?.RetailerMargin ?
						displayAmount(
							row.RetailerMargin,
							(countryData?.countryConfig?.Locale?.DEFAULT || navigator?.language),
							row?.CurrencyCode
						) : '-' }
				</span>
			),
		},
		{
			title: localString("Invoice"),
			showHeading: true,
			customCell: (row) =>
				row?.Invoice ? (
					<a href={row?.Invoice?.DocumentURL} target="_blank">
						<span className="invoice-payment">
							<img src={publicUrl + "/imgs/doc.png"} />

							<p
								style={{
									marginTop: "10px",
									paddingLeft: "5px",
									color: "black",
								}}
							>
								{localString("Invoice")}
							</p>
						</span>
					</a>
				) : (
					"--"
				),
		},
	];

	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}

			<div className="inner-padding doc-title">
				<div className="row content-header-background main-div-pay">
					<div className="payment-box">
						<div className="col-sm-6">
							<h3 className="doc-text">
								{localString("Payment Details")}
							</h3>
						</div>

						<div className="right-content">
							{(paymentData?.PlanDetails?.[0]
								?.PaymentFrequency == "MONTHLY" ||
								paymentData?.PlanDetails?.[0]
									?.PaymentFrequency == "YEARLY") &&
								pagination.maxPages > 1 && (
									<Pagination
										className={{ container: "page-info" }}
										paginationInfo={`${
											(pagination.currentPage - 1) *
												pagination.itemsPerPage +
											1
										} - ${Math.min(
											pagination.currentPage *
												pagination.itemsPerPage,
											pagination.totalItems
										)} of ${pagination.totalItems}`}
									/>
								)}
						</div>
					</div>
					<div className="request-panel request-charges-div custom-third-party-charges pay-box">
						{paymentData?.PlanDetails?.[0]?.PaymentFrequency ==
							"MONTHLY" ||
						paymentData?.PlanDetails?.[0]?.PaymentFrequency ==
							"YEARLY" ? (
							<>
								<CommonTable
									columns={columns}
									data={paymentDetails?.PaymentDetails?.map(
										(pd) => {
											return {
												...pd,
											};
										}
									)}
									localString={localString}
									tableClasses={{
										tableContainer: "summary-table",
									}}
								/>

								{pagination.maxPages > 1 && (
									<Pagination
										itemsPerPage={pagination.itemsPerPage}
										pages={pagination.maxPages}
										totalItems={pagination.totalItems}
										activePage={pagination.currentPage}
										paginate={(pageNumber) =>
											setPagination({
												...pagination,
												currentPage: pageNumber,
											})
										}
										className={{ container: "m25" }}
									/>
								)}
							</>
						) : (
							<div
								className="row"
								style={{ marginBottom: "30px" }}
							>
								<div class="col-sm-4">
									<p className="text-capitalize paymentdetails-label">
										{localString("Transaction ID")}
									</p>
									<p className="text-dark">
										{paymentDetails?.PaymentDetails?.[0]
											?.PaymentGatewayTransactionID ||
											"--"}
									</p>
								</div>
								<div class="col-sm-4">
									<p className="text-capitalize paymentdetails-label">
										{localString("Transaction Date (UTC)")}
									</p>
									<p className="text-dark">
										{(paymentDetails?.PaymentDetails?.[0]
											?.TransactionDate &&
											moment(
												paymentDetails
													?.PaymentDetails?.[0]
													?.TransactionDate,
												"YYYY-MM-DDTHH:mm:ssZ"
											).format(
												getDateFormat(
													defaultCountryLocale
												)
											)) ||
											"--"}
									</p>
								</div>
								<div class="col-sm-4">
									<p className="text-capitalize paymentdetails-label">
										{localString("Transaction Amount")} (
										<span
											dangerouslySetInnerHTML={{
												__html:
													paymentDetails
														?.PaymentDetails?.[0]
														?.CurrencySymbol ||
													"--",
											}}
										/>
										)
									</p>
									<p className="text-dark">
										{ paymentDetails?.PaymentDetails?.[0]?.TransactionAmount ?
											displayAmount(
												paymentDetails?.PaymentDetails?.[0]?.TransactionAmount,
												(countryData?.countryConfig?.Locale?.DEFAULT || navigator?.language)
											) : '--' 
										}
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default PaymentDetails;
