import React, { useRef, useEffect } from 'react'

import './Snackbar.scss'

export default function Snackbar({ type, message, callback, reference, imagePath = '', publicUrl }) {

	const snackbarFunction = useRef(null)

	useEffect(() => {
		window.scrollTo(0, 0)
		snackbarFunction.current = setTimeout(() => {
			callback()
		}, 3000)
		return () => {
			clearTimeout(snackbarFunction.current)
		}
	}, [callback])

	return (
		<div id='toaster' className={type} ref={reference}>
			<img className='toaster--icon' src={`${publicUrl}/imgs/${type}.svg`} alt={type} />
			<span className='toaster--message'>{message}</span>
			<button className='toaster--close ' onClick={callback}>
				&times;
			</button>
		</div>
	)
}