const lang = {
	"Consumers": {
		"text": "Konsumenci",
		"isAlreadyTranslated": true
	},
	"Clear Search": {
		"text": "Wyczyść wyszukiwanie",
		"isAlreadyTranslated": true
	},
	"Filter Consumer": {
		"text": "Filtruj konsumentów",
		"isAlreadyTranslated": true
	},
	"Add Consumers": {
		"text": "Dodaj konsumentów",
		"isAlreadyTranslated": true
	},
	"Mobile": {
		"text": "Telefon komórkowy",
		"isAlreadyTranslated": true
	},
	"Device Type": {
		"text": "Typ urządzenia",
		"isAlreadyTranslated": true
	},
	"Mobile No.": {
		"text": "Nr telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"Model": {
		"text": "Model",
		"isAlreadyTranslated": true
	},
	"Alternate No.": {
		"text": "Inny nr",
		"isAlreadyTranslated": true
	},
	"Landline No": {
		"text": "Nr telefonu stacjonarnego",
		"isAlreadyTranslated": true
	},
	"View Consumer": {
		"text": "Wyświetl konsumenta",
		"isAlreadyTranslated": true
	},
	"Add": {
		"text": "Dodaj",
		"isAlreadyTranslated": true
	},
	"Send": {
		"text": "Wyślij",
		"isAlreadyTranslated": true
	},
	"SMS": {
		"text": "SMS",
		"isAlreadyTranslated": true
	},
	"Email": {
		"text": "E-mail",
		"isAlreadyTranslated": true
	},
	"First": {
		"text": "Pierwszy",
		"isAlreadyTranslated": true
	},
	"Last": {
		"text": "Ostatni",
		"isAlreadyTranslated": true
	},
	"Filter": {
		"text": "Filtr",
		"isAlreadyTranslated": true
	},
	"Customer": {
		"text": "Klient",
		"isAlreadyTranslated": true
	},
	"Close": {
		"text": "Zamknij",
		"isAlreadyTranslated": true
	},
	"Call": {
		"text": "Połącz",
		"isAlreadyTranslated": true
	},
	"logs": {
		"text": "dzienniki",
		"isAlreadyTranslated": true
	},
	"Consumer": {
		"text": "Konsument",
		"isAlreadyTranslated": true
	},
	"Subcategory": {
		"text": "Podkategoria",
		"isAlreadyTranslated": true
	},
	"Brand": {
		"text": "Marka",
		"isAlreadyTranslated": true
	},
	"Product": {
		"text": "Produkt",
		"isAlreadyTranslated": true
	},
	"Apply": {
		"text": "Zastosuj",
		"isAlreadyTranslated": true
	},
	"Dashboard": {
		"text": "Pulpit",
		"isAlreadyTranslated": true
	},
	"Requests": {
		"text": "Zgłoszenia",
		"isAlreadyTranslated": true
	},
	"View Document": {
		"text": "Wyświetl dokument",
		"isAlreadyTranslated": true
	},
	"Logout": {
		"text": "Wyloguj",
		"isAlreadyTranslated": true
	},
	"Notifications": {
		"text": "Powiadomienia",
		"isAlreadyTranslated": true
	},
	"Coupons": {
		"text": "Kupony",
		"isAlreadyTranslated": true
	},
	"UserList": {
		"text": "Lista użytkowników",
		"isAlreadyTranslated": true
	},
	"Curate": {
		"text": "Curate",
		"isAlreadyTranslated": true
	},
	"Diagnosis": {
		"text": "Diagnostyka",
		"isAlreadyTranslated": true
	},
	"Distributor": {
		"text": "Dystrybutor",
		"isAlreadyTranslated": true
	},
	"Enterprise Sales": {
		"text": "Sprzedaż korporacyjna",
		"isAlreadyTranslated": true
	},
	"Reseller": {
		"text": "Sprzedawca",
		"isAlreadyTranslated": true
	},
	"Plan Management": {
		"text": "Zarządzanie planem",
		"isAlreadyTranslated": true
	},
	"Sell Plans": {
		"text": "Plany sprzedaży",
		"isAlreadyTranslated": true
	},
	"Partner Management": {
		"text": "Zarządzanie partnerami",
		"isAlreadyTranslated": true
	},
	"Logistics Requests": {
		"text": "Zgłoszenia logistyczne",
		"isAlreadyTranslated": true
	},
	"Catalogue": {
		"text": "Katalog",
		"isAlreadyTranslated": true
	},
	"Source": {
		"text": "Źródło",
		"isAlreadyTranslated": true
	},
	"Device Count": {
		"text": "Liczba urządzeń",
		"isAlreadyTranslated": true
	},
	"Reg/Unreg": {
		"text": "Zarejestruj/wyrejestruj",
		"isAlreadyTranslated": true
	},
	"Phone Number": {
		"text": "Numer telefonu",
		"isAlreadyTranslated": true
	},
	"Mobile No": {
		"text": "Nr telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"Edit": {
		"text": "Edytuj",
		"isAlreadyTranslated": true
	},
	"View Request": {
		"text": "Wyświetl zgłoszenie",
		"isAlreadyTranslated": true
	},
	"Raise A Request": {
		"text": "Wprowadź zgłoszenie",
		"isAlreadyTranslated": true
	},
	"Bills/Documents": {
		"text": "Rachunki/dokumenty",
		"isAlreadyTranslated": true
	},
	"Explore Device Lifecycle": {
		"text": "Poznaj cykl życia urządzenia",
		"isAlreadyTranslated": true
	},
	"History": {
		"text": "Historia",
		"isAlreadyTranslated": true
	},
	"Year Of Purchase": {
		"text": "Rok zakupu",
		"isAlreadyTranslated": true
	},
	"Date Of Purchase": {
		"text": "Data zakupu",
		"isAlreadyTranslated": true
	},
	"IMEI": {
		"text": "IMEI",
		"isAlreadyTranslated": true
	},
	"Serial No.:": {
		"text": "Nr seryjny:",
		"isAlreadyTranslated": true
	},
	"Initiate diagnosis": {
		"text": "Zainicjuj diagnostykę",
		"isAlreadyTranslated": true
	},
	"Initiate diagnosis directly in the Servify App": {
		"text": "Zainicjuj diagnostykę bezpośrednio w aplikacji Servify",
		"isAlreadyTranslated": true
	},
	"No diagnosis performed": {
		"text": "Nie przeprowadzono diagnostyki",
		"isAlreadyTranslated": true
	},
	"View Diagnostic Result": {
		"text": "Wyświetl wyniki diagnostyczne",
		"isAlreadyTranslated": true
	},
	"Last Diagnosis Performed on": {
		"text": "Ostatnią diagnostykę przeprowadzono w dniu",
		"isAlreadyTranslated": true
	},
	"No Issues Found": {
		"text": "Nie znaleziono problemów",
		"isAlreadyTranslated": true
	},
	"Found": {
		"text": "Znaleziono",
		"isAlreadyTranslated": true
	},
	"Issue": {
		"text": "Problem",
		"isAlreadyTranslated": true
	},
	"Created Date": {
		"text": "Data utworzenia",
		"isAlreadyTranslated": true
	},
	"Model No": {
		"text": "Nr modelu",
		"isAlreadyTranslated": true
	},
	"Tag Name": {
		"text": "Nazwa znacznika",
		"isAlreadyTranslated": true
	},
	"Product Subcategory": {
		"text": "Podkategoria produktu",
		"isAlreadyTranslated": true
	},
	"Created On": {
		"text": "Utworzono w dniu",
		"isAlreadyTranslated": true
	},
	"Alternate Mobile No": {
		"text": "Inny nr telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"Add Product": {
		"text": "Dodaj produkt",
		"isAlreadyTranslated": true
	},
	"Upload Documents": {
		"text": "Prześlij dokumenty",
		"isAlreadyTranslated": true
	},
	"Product Name": {
		"text": "Nazwa produktu",
		"isAlreadyTranslated": true
	},
	"Product SubCategory": {
		"text": "Podkategoria produktu",
		"isAlreadyTranslated": true
	},
	"View Consumer Details": {
		"text": "Wyświetl szczegóły konsumenta",
		"isAlreadyTranslated": true
	},
	"Call History": {
		"text": "Historia połączeń",
		"isAlreadyTranslated": true
	},
	"Unregistered Products": {
		"text": "Niezarejestrowane produkty",
		"isAlreadyTranslated": true
	},
	"Registered Products": {
		"text": "Zarejestrowane produkty",
		"isAlreadyTranslated": true
	},
	"No unregistered products found": {
		"text": "Nie znaleziono niezarejestrowanych produktów",
		"isAlreadyTranslated": true
	},
	"Content": {
		"text": "Zawartość",
		"isAlreadyTranslated": true
	},
	"Customer Name": {
		"text": "Nazwisko klienta",
		"isAlreadyTranslated": true
	},
	"Reference ID": {
		"text": "Identyfikator referencyjny",
		"isAlreadyTranslated": true
	},
	"Scheduled Date": {
		"text": "Zaplanowana data",
		"isAlreadyTranslated": true
	},
	"Type": {
		"text": "Typ",
		"isAlreadyTranslated": true
	},
	"Add call log": {
		"text": "Dodaj dziennik połączeń",
		"isAlreadyTranslated": true
	},
	"Call type": {
		"text": "Typ połączenia",
		"isAlreadyTranslated": true
	},
	"Remarks": {
		"text": "Uwagi",
		"isAlreadyTranslated": true
	},
	"No call history": {
		"text": "Brak historii połączeń",
		"isAlreadyTranslated": true
	},
	"Please enter alphabets only": {
		"text": "Wprowadź tylko litery",
		"isAlreadyTranslated": true
	},
	"Please enter correct EmailID": {
		"text": "Wprowadź poprawny adres e-mail",
		"isAlreadyTranslated": true
	},
	"Please enter correct Name": {
		"text": "Wprowadź poprawną nazwę",
		"isAlreadyTranslated": true
	},
	"Please enter correct Alternate Mobile Number": {
		"text": "Wprowadź poprawny inny numer telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"Incoming": {
		"text": "Przychodzące",
		"isAlreadyTranslated": true
	},
	"Outgoing": {
		"text": "Wychodzące",
		"isAlreadyTranslated": true
	},
	"No": {
		"text": "Nie",
		"isAlreadyTranslated": true
	},
	"Call history": {
		"text": "Historia połączeń",
		"isAlreadyTranslated": true
	},
	"No Data Available": {
		"text": "Brak dostępnej daty",
		"isAlreadyTranslated": true
	},
	"Select a model": {
		"text": "Wybierz model",
		"isAlreadyTranslated": true
	},
	"Customer Alternate Mobile No": {
		"text": "Inny nr telefonu komórkowego klienta",
		"isAlreadyTranslated": true
	},
	"Incident ID": {
		"text": "Identyfikator incydentu",
		"isAlreadyTranslated": true
	},
	"Select an issue": {
		"text": "Wybierz problem",
		"isAlreadyTranslated": true
	},
	"Please enter Customer's Name": {
		"text": "Wprowadź nazwę klienta",
		"isAlreadyTranslated": true
	},
	"Please select a model from the dropdown": {
		"text": "Wybierz model z listy rozwijanej",
		"isAlreadyTranslated": true
	},
	"Please enter at least one issue": {
		"text": "Wprowadź co najmniej jeden problem",
		"isAlreadyTranslated": true
	},
	"Please select a Device Type": {
		"text": "Wybierz typ urządzenia",
		"isAlreadyTranslated": true
	},
	"Please enter Mobile Number": {
		"text": "Wprowadź numer telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"Please enter correct Mobile Number": {
		"text": "Wprowadź poprawny numer telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"Please enter correct E-mail ID": {
		"text": "Wprowadź poprawny adres e-mail",
		"isAlreadyTranslated": true
	},
	"Consumer successfully added and SMS sent": {
		"text": "Pomyślnie dodano konsumenta i wysłano SMS",
		"isAlreadyTranslated": true
	},
	"Something went wrong when trying to create a consumer. Please try again": {
		"text": "Coś poszło nie tak podczas próby utworzenia konsumenta. Spróbuj ponownie",
		"isAlreadyTranslated": true
	},
	"You have already added this issue type": {
		"text": "Ten typ problemu został już przez Ciebie dodany",
		"isAlreadyTranslated": true
	},
	"Please select a Product from the dropdown": {
		"text": "Wybierz produkt z listy rozwijanej",
		"isAlreadyTranslated": true
	},
	"Please select a Brand from the dropdown": {
		"text": "Wybierz markę z listy rozwijanej",
		"isAlreadyTranslated": true
	},
	"Please select a Subcategory from the dropdown": {
		"text": "Wybierz podkategorię z listy rozwijanej",
		"isAlreadyTranslated": true
	},
	"Select a year between": {
		"text": "Wybierz rok między",
		"isAlreadyTranslated": true
	},
	"and": {
		"text": "i",
		"isAlreadyTranslated": true
	},
	"Please select a document type": {
		"text": "Wybierz typ dokumentu",
		"isAlreadyTranslated": true
	},
	"Please select a document": {
		"text": "Wybierz dokument",
		"isAlreadyTranslated": true
	},
	"Oops... Something went wrong": {
		"text": "Ups... Coś poszło nie tak",
		"isAlreadyTranslated": true
	},
	"No requests found": {
		"text": "Nie znaleziono zgłoszeń",
		"isAlreadyTranslated": true
	},
	"OK": {
		"text": "OK",
		"isAlreadyTranslated": true
	},
	"REQUESTS": {
		"text": "ZGŁOSZENIA",
		"isAlreadyTranslated": true
	},
	"Claim Request": {
		"text": "Zgłoszenie roszczenia",
		"isAlreadyTranslated": true
	},
	"In Progress": {
		"text": "W trakcie",
		"isAlreadyTranslated": true
	},
	"Closed": {
		"text": "Zamknięte",
		"isAlreadyTranslated": true
	},
	"Cancelled": {
		"text": "Anulowane",
		"isAlreadyTranslated": true
	},
	"Open": {
		"text": "Otwarte",
		"isAlreadyTranslated": true
	},
	"Period": {
		"text": "Okres",
		"isAlreadyTranslated": true
	},
	"Filter Requests": {
		"text": "Filtruj zgłoszenia",
		"isAlreadyTranslated": true
	},
	"Create Bulk Request": {
		"text": "Utwórz zgłoszenie zbiorcze",
		"isAlreadyTranslated": true
	},
	"Raise a request": {
		"text": "Wprowadź zgłoszenie",
		"isAlreadyTranslated": true
	},
	"External Requests": {
		"text": "Zgłoszenia zewnętrzne",
		"isAlreadyTranslated": true
	},
	"Parts Queue": {
		"text": "Kolejka części",
		"isAlreadyTranslated": true
	},
	"Loading requests": {
		"text": "Ładowanie zgłoszeń",
		"isAlreadyTranslated": true
	},
	"Service type": {
		"text": "Typ usługi",
		"isAlreadyTranslated": true
	},
	"Schedule": {
		"text": "Harmonogram",
		"isAlreadyTranslated": true
	},
	"Download requests in csv format": {
		"text": "Pobierz zgłoszenia w formacie CSV",
		"isAlreadyTranslated": true
	},
	"Created": {
		"text": "Utworzono",
		"isAlreadyTranslated": true
	},
	"Show graph": {
		"text": "Pokaż wykres",
		"isAlreadyTranslated": true
	},
	"Hide graph": {
		"text": "Ukryj wykres",
		"isAlreadyTranslated": true
	},
	"out of": {
		"text": "z",
		"isAlreadyTranslated": true
	},
	"service requests are shown above.": {
		"text": "zgłoszeń serwisowych widać powyżej.",
		"isAlreadyTranslated": true
	},
	"Created or found consumer": {
		"text": "Utworzono lub znaleziono konsumenta",
		"isAlreadyTranslated": true
	},
	"Not updated": {
		"text": "Nie zaktualizowano",
		"isAlreadyTranslated": true
	},
	"Please select a call disposition": {
		"text": "Wybierz dyspozycję połączenia",
		"isAlreadyTranslated": true
	},
	"Please enter a name": {
		"text": "Wprowadź nazwę",
		"isAlreadyTranslated": true
	},
	"Please enter remarks": {
		"text": "Wprowadź uwagi",
		"isAlreadyTranslated": true
	},
	"Username": {
		"text": "Nazwa użytkownika",
		"isAlreadyTranslated": true
	},
	"Call Connected": {
		"text": "Połączenie nawiązane",
		"isAlreadyTranslated": true
	},
	"Connection Disposition:": {
		"text": "Dyspozycja połączenia:",
		"isAlreadyTranslated": true
	},
	"Non-Connect Disposition:": {
		"text": "Dyspozycja braku połączenia:",
		"isAlreadyTranslated": true
	},
	"Date and Time of loss/Damage": {
		"text": "Data i godzina utraty/uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Description of Loss/Damage": {
		"text": "Opis utraty/uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Date and Time of Call": {
		"text": "Data i godzina połączenia",
		"isAlreadyTranslated": true
	},
	"Status at which call was made": {
		"text": "Stan, w którym wykonano połączenie",
		"isAlreadyTranslated": true
	},
	"BER Refund": {
		"text": "Zwrot BER",
		"isAlreadyTranslated": true
	},
	"Action Status": {
		"text": "Stan czynności",
		"isAlreadyTranslated": true
	},
	"Action taken": {
		"text": "Wykonana czynność",
		"isAlreadyTranslated": true
	},
	"Rating": {
		"text": "Ocena",
		"isAlreadyTranslated": true
	},
	"Reassign": {
		"text": "Przypisz ponownie",
		"isAlreadyTranslated": true
	},
	"Parts received": {
		"text": "Otrzymano części",
		"isAlreadyTranslated": true
	},
	"Address Details": {
		"text": "Dane adresowe",
		"isAlreadyTranslated": true
	},
	"Raise Request": {
		"text": "Wprowadź zgłoszenie",
		"isAlreadyTranslated": true
	},
	"Please fill in the cancel reason before cancelling": {
		"text": "Wypełnij powód anulowania przed anulowaniem",
		"isAlreadyTranslated": true
	},
	"Add required documents in 'Request Documents' section below": {
		"text": "Dodaj wymagane dokumenty w poniższej sekcji „Dokumenty zgłoszenia”",
		"isAlreadyTranslated": true
	},
	"Customer Details": {
		"text": "Dane klienta",
		"isAlreadyTranslated": true
	},
	"Mobile Number": {
		"text": "Numer telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"First Registered From": {
		"text": "Pierwszy zarejestrowany z",
		"isAlreadyTranslated": true
	},
	"No saved Address": {
		"text": "Brak zapisanego adresu",
		"isAlreadyTranslated": true
	},
	"Under warranty": {
		"text": "Na gwarancji",
		"isAlreadyTranslated": true
	},
	"Plan Name": {
		"text": "Nazwa planu",
		"isAlreadyTranslated": true
	},
	"Purchase Price": {
		"text": "Cena zakupu",
		"isAlreadyTranslated": true
	},
	"Reason": {
		"text": "Powód",
		"isAlreadyTranslated": true
	},
	"Registration Documents": {
		"text": "Dokumenty rejestracyjne",
		"isAlreadyTranslated": true
	},
	"Validated": {
		"text": "Zatwierdzono ważność",
		"isAlreadyTranslated": true
	},
	"Not Validated": {
		"text": "Nie zatwierdzono ważności",
		"isAlreadyTranslated": true
	},
	"Verify": {
		"text": "Zweryfikuj",
		"isAlreadyTranslated": true
	},
	"Please enter IMEI/serial number": {
		"text": "Wprowadź IMEI/numer seryjny",
		"isAlreadyTranslated": true
	},
	"Please enter serial number": {
		"text": "Wprowadź numer seryjny",
		"isAlreadyTranslated": true
	},
	"Please enter warranty till date": {
		"text": "Wprowadź datę obowiązywania gwarancji",
		"isAlreadyTranslated": true
	},
	"Please upload a warranty supporting document": {
		"text": "Prześlij dokument potwierdzający gwarancję",
		"isAlreadyTranslated": true
	},
	"Please choose if the warranty is applicable or not": {
		"text": "Wybierz, czy gwarancja ma zastosowanie, czy nie",
		"isAlreadyTranslated": true
	},
	"Please enter the reason for warranty not being applicable": {
		"text": "Wprowadź powód, dla którego gwarancja nie ma zastosowania",
		"isAlreadyTranslated": true
	},
	"Remark": {
		"text": "Uwaga",
		"isAlreadyTranslated": true
	},
	"Add Pending Documents": {
		"text": "Dodaj oczekujące dokumenty",
		"isAlreadyTranslated": true
	},
	"Add Additional Documents": {
		"text": "Dodaj dodatkowe dokumenty",
		"isAlreadyTranslated": true
	},
	"Add New Issues": {
		"text": "Dodaj nowe problemy",
		"isAlreadyTranslated": true
	},
	"Total Payable": {
		"text": "Razem do zapłaty",
		"isAlreadyTranslated": true
	},
	"Covered Value": {
		"text": "Pokryta wartość",
		"isAlreadyTranslated": true
	},
	"Send For Approval": {
		"text": "Wyślij do zatwierdzenia",
		"isAlreadyTranslated": true
	},
	"Advance cannot be greater than": {
		"text": "Zaliczka nie może być większa niż",
		"isAlreadyTranslated": true
	},
	"Discount cannot be greater than": {
		"text": "Rabat nie może być większy niż",
		"isAlreadyTranslated": true
	},
	"Reassigned": {
		"text": "Przypisano ponownie",
		"isAlreadyTranslated": true
	},
	"Pickup Address": {
		"text": "Adres miejsca odbioru",
		"isAlreadyTranslated": true
	},
	"Address": {
		"text": "Adres",
		"isAlreadyTranslated": true
	},
	"Telecon Version": {
		"text": "Wersja Telecon",
		"isAlreadyTranslated": true
	},
	"Recycle Certificate": {
		"text": "Certyfikat recyklingu",
		"isAlreadyTranslated": true
	},
	"Pick Up Confirmation Number": {
		"text": "Numer potwierdzenia odbioru",
		"isAlreadyTranslated": true
	},
	"Pickup Charge": {
		"text": "Opłata za odbiór",
		"isAlreadyTranslated": true
	},
	"Payable": {
		"text": "Do zapłaty",
		"isAlreadyTranslated": true
	},
	"Place of Damage": {
		"text": "Miejsce uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Please enter issue description": {
		"text": "Wprowadź opis problemu",
		"isAlreadyTranslated": true
	},
	"Request History": {
		"text": "Historia zgłoszeń",
		"isAlreadyTranslated": true
	},
	"Waiting for Assignment": {
		"text": "Oczekiwanie na przypisanie",
		"isAlreadyTranslated": true
	},
	"BER confirmed": {
		"text": "Potwierdzono BER",
		"isAlreadyTranslated": true
	},
	"BER approved": {
		"text": "Zatwierdzono BER",
		"isAlreadyTranslated": true
	},
	"BER identified": {
		"text": "Zidentyfikowano BER",
		"isAlreadyTranslated": true
	},
	"Device received": {
		"text": "Otrzymano urządzenie",
		"isAlreadyTranslated": true
	},
	"Pickup Request Accepted": {
		"text": "Zaakceptowano zgłoszenie odbioru",
		"isAlreadyTranslated": true
	},
	"We have received your claim request. Our representative will connect with you within the next 1-2 business days to process it further": {
		"text": "Otrzymaliśmy Twoje zgłoszenie roszczenia. Nasz przedstawiciel skontaktuje się z Tobą w ciągu 1–2 dni roboczych w celu dalszego przetwarzania",
		"isAlreadyTranslated": true
	},
	"Device has been received by the service centre and is currently being diagnosed.": {
		"text": "Centrum serwisowe otrzymało urządzenie, trwa diagnozowanie.",
		"isAlreadyTranslated": true
	},
	"Looks like the repair cost is more than the depreciated value of your device. Please select the option you would like to proceed with.": {
		"text": "Wygląda na to, że koszt naprawy jest wyższy niż zamortyzowana wartość urządzenia. Wybierz opcję, z którą chcesz kontynuować.",
		"isAlreadyTranslated": true
	},
	"Carry in": {
		"text": "Z przyniesieniem",
		"isAlreadyTranslated": true
	},
	"Assigned To": {
		"text": "Przypisano do",
		"isAlreadyTranslated": true
	},
	"ADD CHARGES": {
		"text": "DODAJ OPŁATY",
		"isAlreadyTranslated": true
	},
	"Amount": {
		"text": "Kwota",
		"isAlreadyTranslated": true
	},
	"Attempt Call": {
		"text": "Próba połączenia",
		"isAlreadyTranslated": true
	},
	"Add Device Date of Purchase and Device Purchase Cost in 'Device Details' below": {
		"text": "Dodaj datę zakupu urządzenia i koszt zakupu urządzenia w poniższej sekcji „Szczegóły urządzenia”",
		"isAlreadyTranslated": true
	},
	"Enter type of document to add": {
		"text": "Wprowadź typ dokumentu do dodania",
		"isAlreadyTranslated": true
	},
	"Please enter Claim intimation number": {
		"text": "Wprowadź numer zgłoszenia roszczenia",
		"isAlreadyTranslated": true
	},
	"Please enter Claim intimation number before submitting": {
		"text": "Wprowadź numer zgłoszenia roszczenia przed przesłaniem",
		"isAlreadyTranslated": true
	},
	"Data displayed": {
		"text": "Wyświetlane dane",
		"isAlreadyTranslated": true
	},
	"from": {
		"text": "od",
		"isAlreadyTranslated": true
	},
	"till": {
		"text": "do",
		"isAlreadyTranslated": true
	},
	"Please upload a clear copy of cancelled cheque with the claimant (your) name on it.": {
		"text": "Przekaż wyraźną kopię anulowanego czeku z widniejącym na nim (Twoim) imieniem i nazwiskiem strony wnoszącej roszczenie.",
		"isAlreadyTranslated": true
	},
	"Customer Mobile number": {
		"text": "Numer telefonu komórkowego klienta",
		"isAlreadyTranslated": true
	},
	"TRACK EXTERNAL REQUEST": {
		"text": "ŚLEDŹ ZEWNĘTRZNE ZGŁOSZENIE",
		"isAlreadyTranslated": true
	},
	"Oops... Something went wrong!": {
		"text": "Ups... Coś poszło nie tak!",
		"isAlreadyTranslated": true
	},
	"an address": {
		"text": "adres",
		"isAlreadyTranslated": true
	},
	"Pincode": {
		"text": "Kod PIN",
		"isAlreadyTranslated": true
	},
	"Clear": {
		"text": "Wyczyść",
		"isAlreadyTranslated": true
	},
	"Cancel": {
		"text": "Anuluj",
		"isAlreadyTranslated": true
	},
	"Please enter Landmark": {
		"text": "Wprowadź punkt orientacyjny",
		"isAlreadyTranslated": true
	},
	"Please enter Customer's address": {
		"text": "Wprowadź adres klienta",
		"isAlreadyTranslated": true
	},
	"Please enter pincode": {
		"text": "Wprowadź kod PIN",
		"isAlreadyTranslated": true
	},
	"Enter correct Pincode": {
		"text": "Wprowadź poprawny kod PIN",
		"isAlreadyTranslated": true
	},
	"Address updated successfully": {
		"text": "Pomyślnie zaktualizowano adres",
		"isAlreadyTranslated": true
	},
	"Documents Pending": {
		"text": "Oczekujące dokumenty",
		"isAlreadyTranslated": true
	},
	"Date and Time of loss/damage": {
		"text": "Data i godzina utraty/uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Please enter UTR Number": {
		"text": "Wprowadź numer UTR",
		"isAlreadyTranslated": true
	},
	"Payment Date": {
		"text": "Termin płatności",
		"isAlreadyTranslated": true
	},
	"UTR Number": {
		"text": "Numer UTR",
		"isAlreadyTranslated": true
	},
	"Service Charge": {
		"text": "Opłata serwisowa",
		"isAlreadyTranslated": true
	},
	"SGST": {
		"text": "SGST",
		"isAlreadyTranslated": true
	},
	"CGST": {
		"text": "CGST",
		"isAlreadyTranslated": true
	},
	"TESTING": {
		"text": "TESTOWANIE",
		"isAlreadyTranslated": true
	},
	"Mandatory deductible": {
		"text": "Obowiązkowy udział własny",
		"isAlreadyTranslated": true
	},
	"Admin fees": {
		"text": "Opłaty administracyjne",
		"isAlreadyTranslated": true
	},
	"Claim amount": {
		"text": "Kwota roszczenia",
		"isAlreadyTranslated": true
	},
	"Advance payment": {
		"text": "Płatność zaliczki",
		"isAlreadyTranslated": true
	},
	"Advance": {
		"text": "Zaliczka",
		"isAlreadyTranslated": true
	},
	"Alternate Mobile Number": {
		"text": "Inny numer telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"Please enter correct mobile Number": {
		"text": "Wprowadź poprawny numer telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"Please Enter Minimum 75 Characters": {
		"text": "Wprowadź minimum 75 znaków",
		"isAlreadyTranslated": true
	},
	"Add an address": {
		"text": "Dodaj adres",
		"isAlreadyTranslated": true
	},
	"Add New Address": {
		"text": "Dodaj nowy adres",
		"isAlreadyTranslated": true
	},
	"Oops! There are no verified service centres around": {
		"text": "Ups! W okolicy nie ma zweryfikowanych centrów serwisowych",
		"isAlreadyTranslated": true
	},
	"Cannot select more than": {
		"text": "Nie można wybrać więcej niż",
		"isAlreadyTranslated": true
	},
	"issues": {
		"text": "problemów",
		"isAlreadyTranslated": true
	},
	"Add a Product": {
		"text": "Dodaj produkt",
		"isAlreadyTranslated": true
	},
	"Add Selected Product": {
		"text": "Dodaj wybrany produkt",
		"isAlreadyTranslated": true
	},
	"Device In Warranty": {
		"text": "Urządzenie na gwarancji",
		"isAlreadyTranslated": true
	},
	"Please Select Damage Date": {
		"text": "Wybierz datę uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Is your device switching ON": {
		"text": "Czy urządzenie się WŁĄCZA?",
		"isAlreadyTranslated": true
	},
	"Characters must be numeric": {
		"text": "Znaki muszą być numeryczne",
		"isAlreadyTranslated": true
	},
	"products under service": {
		"text": "produkty w ramach usługi",
		"isAlreadyTranslated": true
	},
	"Please Select Time": {
		"text": "Wybierz godzinę",
		"isAlreadyTranslated": true
	},
	"Please Select Damage type": {
		"text": "Wybierz typ uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Please Enter Place of Damage": {
		"text": "Wprowadź miejsce uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Please Select an option": {
		"text": "Wybierz opcję",
		"isAlreadyTranslated": true
	},
	"Consumer Name": {
		"text": "Imię i nazwisko konsumenta",
		"isAlreadyTranslated": true
	},
	"Contact No": {
		"text": "Nr kontaktowy",
		"isAlreadyTranslated": true
	},
	"Logistics Provider": {
		"text": "Dostawca usług logistycznych",
		"isAlreadyTranslated": true
	},
	"Registration Source": {
		"text": "Źródło rejestracji",
		"isAlreadyTranslated": true
	},
	"Customer City": {
		"text": "Miasto klienta",
		"isAlreadyTranslated": true
	},
	"Call Type": {
		"text": "Typ połączenia",
		"isAlreadyTranslated": true
	},
	"Re-apply": {
		"text": "Zastosuj ponownie",
		"isAlreadyTranslated": true
	},
	"something went wrong": {
		"text": "coś poszło nie tak",
		"isAlreadyTranslated": true
	},
	"Today": {
		"text": "Dzisiaj",
		"isAlreadyTranslated": true
	},
	"Yesterday": {
		"text": "Wczoraj",
		"isAlreadyTranslated": true
	},
	"Last 7 Days": {
		"text": "Ostatnie 7 dni",
		"isAlreadyTranslated": true
	},
	"Last 30 Days": {
		"text": "Ostatnie 30 dni",
		"isAlreadyTranslated": true
	},
	"This Month": {
		"text": "Ten miesiąc",
		"isAlreadyTranslated": true
	},
	"Last Month": {
		"text": "Zeszły miesiąc",
		"isAlreadyTranslated": true
	},
	"Custom Range": {
		"text": "Zakres niestandardowy",
		"isAlreadyTranslated": true
	},
	"Add a part charge in 'Estimation Details' below to generate estimation": {
		"text": "Dodaj częściową opłatę w poniższej sekcji „Szczegóły oszacowania”, aby wygenerować oszacowanie",
		"isAlreadyTranslated": true
	},
	"Pick Up Request Reference ID": {
		"text": "Identyfikator referencyjny zgłoszenia odbioru",
		"isAlreadyTranslated": true
	},
	"Convert request": {
		"text": "Konwertuj zgłoszenie",
		"isAlreadyTranslated": true
	},
	"No Data Available To Plot The Graph": {
		"text": "Brak dostępnych danych do wykreślenia wykresu",
		"isAlreadyTranslated": true
	},
	"to": {
		"text": "do",
		"isAlreadyTranslated": true
	},
	"Download requests in csv format.": {
		"text": "Pobierz zgłoszenia w formacie CSV.",
		"isAlreadyTranslated": true
	},
	"Service cancelled": {
		"text": "Usługa anulowana",
		"isAlreadyTranslated": true
	},
	"Search consumer products": {
		"text": "Wyszukaj produkty konsumenckie",
		"isAlreadyTranslated": true
	},
	"Serial number": {
		"text": "Numer seryjny",
		"isAlreadyTranslated": true
	},
	"Enter Serial Number": {
		"text": "Wprowadź numer seryjny",
		"isAlreadyTranslated": true
	},
	"Select Customer Type": {
		"text": "Wybierz typ klienta",
		"isAlreadyTranslated": true
	},
	"Walk-in Customer": {
		"text": "Klient nieumówiony",
		"isAlreadyTranslated": true
	},
	"Future Appointment": {
		"text": "Przyszłe spotkanie",
		"isAlreadyTranslated": true
	},
	"Tablet": {
		"text": "Tablet",
		"isAlreadyTranslated": true
	},
	"Computer": {
		"text": "Komputer",
		"isAlreadyTranslated": true
	},
	"Audio Product": {
		"text": "Produkt audio",
		"isAlreadyTranslated": true
	},
	"Streaming Device": {
		"text": "Urządzenie do przesyłania strumieniowego",
		"isAlreadyTranslated": true
	},
	"Smart Watch": {
		"text": "Inteligentny zegarek",
		"isAlreadyTranslated": true
	},
	"Serial number is mandatory for Apple Products.\nPlease enter valid serial number": {
		"text": "Numer seryjny jest obowiązkowy w przypadku produktów Apple.\nWprowadź prawidłowy numer seryjny",
		"isAlreadyTranslated": true
	},
	"Back camera issue": {
		"text": "Problem z tylnym aparatem",
		"isAlreadyTranslated": true
	},
	"Charger not working": {
		"text": "Ładowarka nie działa",
		"isAlreadyTranslated": true
	},
	"Noise issue": {
		"text": "Problem z hałasem",
		"isAlreadyTranslated": true
	},
	"Network Issue": {
		"text": "Problem z siecią",
		"isAlreadyTranslated": true
	},
	"External storage issue": {
		"text": "Problem z pamięcią zewnętrzną",
		"isAlreadyTranslated": true
	},
	"Internal data storage issue": {
		"text": "Problem z wewnętrzną pamięcią danych",
		"isAlreadyTranslated": true
	},
	"Blur display": {
		"text": "Rozmycie wyświetlacza",
		"isAlreadyTranslated": true
	},
	"Flickering": {
		"text": "Migotanie",
		"isAlreadyTranslated": true
	},
	"Improper display": {
		"text": "Nieprawidłowe wyświetlanie",
		"isAlreadyTranslated": true
	},
	"Spot on display": {
		"text": "Plama na wyświetlaczu",
		"isAlreadyTranslated": true
	},
	"Electric Shock": {
		"text": "Porażenie prądem",
		"isAlreadyTranslated": true
	},
	"Body damaged": {
		"text": "Uszkodzenie ciała",
		"isAlreadyTranslated": true
	},
	"Display damaged": {
		"text": "Uszkodzenie wyświetlacza",
		"isAlreadyTranslated": true
	},
	"Intermittently powering off": {
		"text": "Sporadyczne wyłączanie",
		"isAlreadyTranslated": true
	},
	"Not charging": {
		"text": "Brak ładowania",
		"isAlreadyTranslated": true
	},
	"Not powering off": {
		"text": "Brak wyłączania",
		"isAlreadyTranslated": true
	},
	"Cracking sound": {
		"text": "Dźwięk trzaskania",
		"isAlreadyTranslated": true
	},
	"Less sound": {
		"text": "Redukcja dźwięku",
		"isAlreadyTranslated": true
	},
	"Slow processing or hangs": {
		"text": "Powolne przetwarzanie lub zawieszanie",
		"isAlreadyTranslated": true
	},
	"Pickup Drop By": {
		"text": "Nadano do odbioru przez",
		"isAlreadyTranslated": true
	},
	"Qty": {
		"text": "Ilość",
		"isAlreadyTranslated": true
	},
	"Available Quantity": {
		"text": "Dostępna ilość",
		"isAlreadyTranslated": true
	},
	"Issued By": {
		"text": "Wydane przez",
		"isAlreadyTranslated": true
	},
	"Handover Device": {
		"text": "Przekaż urządzenie",
		"isAlreadyTranslated": true
	},
	"Not applicable": {
		"text": "Nie dotyczy",
		"isAlreadyTranslated": true
	},
	"Pending for assignment": {
		"text": "Oczekuje na przypisanie",
		"isAlreadyTranslated": true
	},
	"Device handover details": {
		"text": "Szczegóły przekazania urządzenia",
		"isAlreadyTranslated": true
	},
	"Diagnosis details": {
		"text": "Szczegóły diagnostyki",
		"isAlreadyTranslated": true
	},
	"Diagnosis Steps": {
		"text": "Kroki diagnostyki",
		"isAlreadyTranslated": true
	},
	"Engineer notes": {
		"text": "Notatki inżyniera",
		"isAlreadyTranslated": true
	},
	"Device Transaction Details": {
		"text": "Szczegóły transakcji urządzenia",
		"isAlreadyTranslated": true
	},
	"Upload Device Pictures and Complete Checklist": {
		"text": "Przekaż zdjęcia urządzenia i wypełnij listę kontrolną",
		"isAlreadyTranslated": true
	},
	"Upload Device Pictures": {
		"text": "Przekaż zdjęcia urządzenia",
		"isAlreadyTranslated": true
	},
	"Select OS Version": {
		"text": "Wybierz wersję systemu operacyjnego",
		"isAlreadyTranslated": true
	},
	"Select Picture Type": {
		"text": "Wybierz typ zdjęcia",
		"isAlreadyTranslated": true
	},
	"No pictures added.": {
		"text": "Nie dodano zdjęć.",
		"isAlreadyTranslated": true
	},
	"Complete Checklist": {
		"text": "Zakończ listę kontrolną",
		"isAlreadyTranslated": true
	},
	"Accessories Submitted": {
		"text": "Przesłano akcesoria",
		"isAlreadyTranslated": true
	},
	"Checklist": {
		"text": "Lista kontrolna",
		"isAlreadyTranslated": true
	},
	"Miscellaneous Checklist": {
		"text": "Różnorodna lista kontrolna",
		"isAlreadyTranslated": true
	},
	"Complete": {
		"text": "Zakończ",
		"isAlreadyTranslated": true
	},
	"Select All": {
		"text": "Zaznacz wszystko",
		"isAlreadyTranslated": true
	},
	"Raise in": {
		"text": "Wprowadź w",
		"isAlreadyTranslated": true
	},
	"Request in": {
		"text": "Zgłoś w",
		"isAlreadyTranslated": true
	},
	"Please select Repair Type": {
		"text": "Wybierz typ naprawy",
		"isAlreadyTranslated": true
	},
	"Please select Classification Type": {
		"text": "Wybierz typ klasyfikacji",
		"isAlreadyTranslated": true
	},
	"Repair Checklist": {
		"text": "Lista kontrolna naprawy",
		"isAlreadyTranslated": true
	},
	"Raise": {
		"text": "Wprowadź",
		"isAlreadyTranslated": true
	},
	"Request": {
		"text": "Zgłoszenie",
		"isAlreadyTranslated": true
	},
	"Diagnostics Result": {
		"text": "Wynik diagnostyki",
		"isAlreadyTranslated": true
	},
	"Please mention the incident in not less than 75 characters on how, when & where the damage happened": {
		"text": "Opisz incydent, używając nie mniej 75 znaków, podając sposób, czas i miejsce wystąpienia uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Parts transaction details": {
		"text": "Szczegóły transakcji dotyczącej części",
		"isAlreadyTranslated": true
	},
	"Handing over the parts to store": {
		"text": "Przekazanie części do magazynu",
		"isAlreadyTranslated": true
	},
	"Handing over the damaged device to store": {
		"text": "Przekazanie uszkodzonego urządzenia do magazynu",
		"isAlreadyTranslated": true
	},
	"Details of the New Replaced Device": {
		"text": "Szczegóły nowo wymienionego urządzenia",
		"isAlreadyTranslated": true
	},
	"Mode of payment": {
		"text": "Sposób płatności",
		"isAlreadyTranslated": true
	},
	"Please select mode of payment": {
		"text": "Wybierz sposób płatności",
		"isAlreadyTranslated": true
	},
	"Cash": {
		"text": "Gotówka",
		"isAlreadyTranslated": true
	},
	"Card": {
		"text": "Karta",
		"isAlreadyTranslated": true
	},
	"Accessories will not be returned": {
		"text": "Akcesoria nie będą zwracane",
		"isAlreadyTranslated": true
	},
	"Documents are yet to be uploaded.": {
		"text": "Trzeba jeszcze przekazać dokumenty.",
		"isAlreadyTranslated": true
	},
	"Report Issues": {
		"text": "Raportuj problemy",
		"isAlreadyTranslated": true
	},
	"Re-assign": {
		"text": "Przypisz ponownie",
		"isAlreadyTranslated": true
	},
	"Add Required Parts": {
		"text": "Dodaj wymagane części",
		"isAlreadyTranslated": true
	},
	"Price": {
		"text": "Cena",
		"isAlreadyTranslated": true
	},
	"Tax Slab": {
		"text": "Skala podatkowa",
		"isAlreadyTranslated": true
	},
	"Is Part Abused": {
		"text": "Is Part Abused",
		"isAlreadyTranslated": true
	},
	"Is Returnable Damage": {
		"text": "Czy uszkodzenie podlega zwrotowi",
		"isAlreadyTranslated": true
	},
	"Part Warranty": {
		"text": "Gwarancja na części",
		"isAlreadyTranslated": true
	},
	"Part Price": {
		"text": "Cena części",
		"isAlreadyTranslated": true
	},
	"Available Qty": {
		"text": "Dostępna ilość",
		"isAlreadyTranslated": true
	},
	"Action": {
		"text": "Akcja",
		"isAlreadyTranslated": true
	},
	"Report Issues to": {
		"text": "Raportuj problemy do",
		"isAlreadyTranslated": true
	},
	"Receive & Inspect Device": {
		"text": "Otrzymaj i sprawdź urządzenie",
		"isAlreadyTranslated": true
	},
	"Please select a picture type from dropdown": {
		"text": "Wybierz typ obrazu z listy rozwijanej",
		"isAlreadyTranslated": true
	},
	"Waybill": {
		"text": "List przewozowy",
		"isAlreadyTranslated": true
	},
	"Vendor": {
		"text": "Dostawca",
		"isAlreadyTranslated": true
	},
	"Pick Up": {
		"text": "Odbiór",
		"isAlreadyTranslated": true
	},
	"Is Covered By ACPlus": {
		"text": "Jest objęty usługą ACPlus",
		"isAlreadyTranslated": true
	},
	"Re-assign Service Engineer": {
		"text": "Ponownie przypisz inżyniera serwisu",
		"isAlreadyTranslated": true
	},
	"Device Received & Inspection in Progress": {
		"text": "Otrzymano urządzenie i trwa kontrola",
		"isAlreadyTranslated": true
	},
	"Service accepted": {
		"text": "Usługa zaakceptowana",
		"isAlreadyTranslated": true
	},
	"COMPTIA Modifier": {
		"text": "Modyfikator COMPTIA",
		"isAlreadyTranslated": true
	},
	"Send Issues to": {
		"text": "Wyślij problemy do",
		"isAlreadyTranslated": true
	},
	"Engineer validated issues": {
		"text": "Inżynier sprawdził ważność problemów",
		"isAlreadyTranslated": true
	},
	"Part(s) request under review by storekeeper.": {
		"text": "Właściciel sklepu przeprowadza ocenę żądania części.",
		"isAlreadyTranslated": true
	},
	"ChargeCode": {
		"text": "Kod opłaty",
		"isAlreadyTranslated": true
	},
	"TaxSlab": {
		"text": "Skala podatkowa",
		"isAlreadyTranslated": true
	},
	"Are you sure?": {
		"text": "Czy na pewno?",
		"isAlreadyTranslated": true
	},
	"Are you sure..?": {
		"text": "Czy na pewno...?",
		"isAlreadyTranslated": true
	},
	"Part name": {
		"text": "Nazwa części",
		"isAlreadyTranslated": true
	},
	"Ref Id": {
		"text": "Identyfikator referencyjny",
		"isAlreadyTranslated": true
	},
	"Validation Status": {
		"text": "Stan sprawdzania ważności",
		"isAlreadyTranslated": true
	},
	"Is warranty applicable": {
		"text": "Czy obowiązuje gwarancja",
		"isAlreadyTranslated": true
	},
	"Issue Code": {
		"text": "Kod problemu",
		"isAlreadyTranslated": true
	},
	"Symptom Code": {
		"text": "Kod objawu",
		"isAlreadyTranslated": true
	},
	"Other Charge": {
		"text": "Inna opłata",
		"isAlreadyTranslated": true
	},
	"Discount": {
		"text": "Rabat",
		"isAlreadyTranslated": true
	},
	"Engineer completed the repair. Handed over the device to QC.": {
		"text": "Inżynier zakończył naprawę. Przekazano urządzenie do kontroli jakości.",
		"isAlreadyTranslated": true
	},
	"Device handed over": {
		"text": "Przekazano urządzenie",
		"isAlreadyTranslated": true
	},
	"Repair completed, QC pending": {
		"text": "Naprawa zakończona, trwa kontrola jakości",
		"isAlreadyTranslated": true
	},
	"Repair complete": {
		"text": "Naprawa zakończona",
		"isAlreadyTranslated": true
	},
	"Device details validated": {
		"text": "Zatwierdzono ważność danych urządzenia",
		"isAlreadyTranslated": true
	},
	"Issues validated": {
		"text": "Zatwierdzono ważność problemów",
		"isAlreadyTranslated": true
	},
	"Work in Progress with Engineer": {
		"text": "Trwa praca inżyniera",
		"isAlreadyTranslated": true
	},
	"Cannot be diagnosed": {
		"text": "Nie można zdiagnozować",
		"isAlreadyTranslated": true
	},
	"Parts issued": {
		"text": "Wydano części",
		"isAlreadyTranslated": true
	},
	"Pending for acceptance": {
		"text": "Oczekiwanie na akceptację",
		"isAlreadyTranslated": true
	},
	"Device accepted": {
		"text": "Zaakceptowano urządzenie",
		"isAlreadyTranslated": true
	},
	"Parts request": {
		"text": "Żądanie części",
		"isAlreadyTranslated": true
	},
	"Valid": {
		"text": "Ważny",
		"isAlreadyTranslated": true
	},
	"Validity": {
		"text": "Ważność",
		"isAlreadyTranslated": true
	},
	"validity": {
		"text": "Ważność",
		"isAlreadyTranslated": true
	},
	"Invalid": {
		"text": "Nieważny",
		"isAlreadyTranslated": true
	},
	"Engineer Raised Issues": {
		"text": "Problemy poruszone przez inżyniera",
		"isAlreadyTranslated": true
	},
	"Are you sure": {
		"text": "Czy na pewno",
		"isAlreadyTranslated": true
	},
	"Please specify remarks, if any": {
		"text": "Podaj uwagi, o ile jeśli występują",
		"isAlreadyTranslated": true
	},
	"This service is a Return without repair": {
		"text": "Ta usługa to zwrot bez naprawy",
		"isAlreadyTranslated": true
	},
	"Others": {
		"text": "Inne",
		"isAlreadyTranslated": true
	},
	"Service completed and rated": {
		"text": "Usługa zakończona i oceniona",
		"isAlreadyTranslated": true
	},
	"Drop Request Created Post Repair": {
		"text": "Zgłoszenie nadania utworzone po naprawie",
		"isAlreadyTranslated": true
	},
	"Payment received": {
		"text": "Otrzymano płatność",
		"isAlreadyTranslated": true
	},
	"Service invoice generated": {
		"text": "Wygenerowano fakturę za usługę",
		"isAlreadyTranslated": true
	},
	"Repair Completed, Ready To Inform The Customer": {
		"text": "Naprawa zakończona, gotowa do poinformowania klienta",
		"isAlreadyTranslated": true
	},
	"Repair completed": {
		"text": "Zakończono naprawę",
		"isAlreadyTranslated": true
	},
	"WarrantyConcession": {
		"text": "Umowa gwarancyjna",
		"isAlreadyTranslated": true
	},
	"Estimation generated": {
		"text": "Wygenerowano oszacowanie",
		"isAlreadyTranslated": true
	},
	"Estimation approval": {
		"text": "Zatwierdzenie kosztorysu",
		"isAlreadyTranslated": true
	},
	"Parts request accepted": {
		"text": "Zaakceptowano żądanie części",
		"isAlreadyTranslated": true
	},
	"Repair estimate approved": {
		"text": "Zatwierdzono kosztorys naprawy",
		"isAlreadyTranslated": true
	},
	"Damaged Serial No": {
		"text": "Uszkodzony numer seryjny",
		"isAlreadyTranslated": true
	},
	"Replaced Serial No": {
		"text": "Wymieniony numer seryjny",
		"isAlreadyTranslated": true
	},
	"Damaged IMEI": {
		"text": "Uszkodzony numer IMEI",
		"isAlreadyTranslated": true
	},
	"Replaced IMEI": {
		"text": "Wymieniony numer IMEI",
		"isAlreadyTranslated": true
	},
	"Initiate logistics request": {
		"text": "Zainicjuj zgłoszenie logistyczne",
		"isAlreadyTranslated": true
	},
	"Payment for the service has been received from the customer.": {
		"text": "Otrzymano płatność klienta za usługę.",
		"isAlreadyTranslated": true
	},
	"BER payment completed": {
		"text": "Zakończono płatność BER",
		"isAlreadyTranslated": true
	},
	"BER payment initiated": {
		"text": "Zainicjowano płatność BER",
		"isAlreadyTranslated": true
	},
	"BER device received": {
		"text": "Otrzymano urządzenie BER",
		"isAlreadyTranslated": true
	},
	"BER accessories validated": {
		"text": "Zatwierdzono ważność akcesoriów BER",
		"isAlreadyTranslated": true
	},
	"BER accessories received": {
		"text": "Otrzymano akcesoria BER",
		"isAlreadyTranslated": true
	},
	"BER documents validated": {
		"text": "Zatwierdzono ważność dokumentów BER",
		"isAlreadyTranslated": true
	},
	"Pickup rescheduled": {
		"text": "Zmieniono termin odbioru",
		"isAlreadyTranslated": true
	},
	"Reschedule requested": {
		"text": "Zażądano zmiany terminu",
		"isAlreadyTranslated": true
	},
	"Claim Pickup": {
		"text": "Miejsce odbioru roszczenia",
		"isAlreadyTranslated": true
	},
	"Invalid IMEI or serial number": {
		"text": "Nieprawidłowy numer IMEI lub numer seryjny",
		"isAlreadyTranslated": true
	},
	"No serial number for product": {
		"text": "Brak numeru seryjnego produktu",
		"isAlreadyTranslated": true
	},
	"Invalid product serial number": {
		"text": "Nieprawidłowy numer seryjny produktu",
		"isAlreadyTranslated": true
	},
	"Waiting for the Engineer to raise the request.": {
		"text": "Oczekiwanie na wprowadzenie zgłoszenia przez inżyniera.",
		"isAlreadyTranslated": true
	},
	"getting": {
		"text": "otrzymywanie",
		"isAlreadyTranslated": true
	},
	"Claim Carryin": {
		"text": "Przenoszenie roszczenia",
		"isAlreadyTranslated": true
	},
	"Approved claim amount": {
		"text": "Zatwierdzona kwota roszczenia",
		"isAlreadyTranslated": true
	},
	"Payment pending": {
		"text": "Oczekiwanie na płatność",
		"isAlreadyTranslated": true
	},
	"Work in Progress with Engineer.": {
		"text": "Trwa praca inżyniera.",
		"isAlreadyTranslated": true
	},
	"Diagnosis completed": {
		"text": "Zakończono diagnostykę",
		"isAlreadyTranslated": true
	},
	"Diagnosis initiated": {
		"text": "Zainicjowano diagnostykę",
		"isAlreadyTranslated": true
	},
	"To proceed, update Device Date of Purchase and Device Purchase Cost in 'Device Details' below.": {
		"text": "Aby kontynuować, zaktualizuj datę zakupu urządzenia i koszt zakupu urządzenia w poniższej sekcji „Szczegóły urządzenia”.",
		"isAlreadyTranslated": true
	},
	"Connect Disposition:": {
		"text": "Dyspozycja połączenia:",
		"isAlreadyTranslated": true
	},
	"Handing over the": {
		"text": "Przekazanie",
		"isAlreadyTranslated": true
	},
	"full box unit": {
		"text": "pełnej jednostki opakowania",
		"isAlreadyTranslated": true
	},
	"parts": {
		"text": "części",
		"isAlreadyTranslated": true
	},
	"to store.": {
		"text": "do magazynu.",
		"isAlreadyTranslated": true
	},
	"Damaged Part Imei": {
		"text": "Uszkodzony numer IMEI części",
		"isAlreadyTranslated": true
	},
	"Select Fault Code based on the validated issues.": {
		"text": "Wybierz kod usterki na podstawie problemów o zatwierdzonej ważności.",
		"isAlreadyTranslated": true
	},
	"Fault Code": {
		"text": "Kod usterki",
		"isAlreadyTranslated": true
	},
	"Please check following items before completing the QC.": {
		"text": "Sprawdź następujące elementy przed zakończeniem kontroli jakości.",
		"isAlreadyTranslated": true
	},
	"QC Checklist": {
		"text": "Lista kontrolna kontroli jakości",
		"isAlreadyTranslated": true
	},
	"Oops": {
		"text": "Ups",
		"isAlreadyTranslated": true
	},
	"Please enter type of damage": {
		"text": "Wprowadź typ uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Please enter atleast one document": {
		"text": "Wprowadź co najmniej jeden dokument",
		"isAlreadyTranslated": true
	},
	"Please enter description of loss/damage": {
		"text": "Wprowadź opis utraty/uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Please enter Date and Time of loss/damage": {
		"text": "Wprowadź datę i godzinę utraty/uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Please enter Disposition reason": {
		"text": "Wprowadź powód dyspozycji",
		"isAlreadyTranslated": true
	},
	"Please enter your name": {
		"text": "Wprowadź swoje imię i nazwisko",
		"isAlreadyTranslated": true
	},
	"Please enter the reason for call": {
		"text": "Wprowadź powód połączenia",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for cancelling": {
		"text": "Wypełnij powód anulowania",
		"isAlreadyTranslated": true
	},
	"Please specify the pending parts": {
		"text": "Określ części, których oczekujesz",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for rejecting claim": {
		"text": "Wypełnij powód odrzucenia roszczenia",
		"isAlreadyTranslated": true
	},
	"Please fill in the cancel reason before rejecting": {
		"text": "Wypełnij powód anulowania przed odrzuceniem",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for the claim being invalid": {
		"text": "Podaj powód nieważności roszczenia",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for claim being invalid before rejecting": {
		"text": "Wypełnij powód nieważności roszczenia przed odrzuceniem",
		"isAlreadyTranslated": true
	},
	"You have not chosen any option to handover": {
		"text": "Nie wybrano żadnej opcji do przekazania",
		"isAlreadyTranslated": true
	},
	"Please enter approved claim amount": {
		"text": "Wprowadź zatwierdzoną kwotę roszczenia",
		"isAlreadyTranslated": true
	},
	"Please add a picture.": {
		"text": "Dodaj zdjęcie.",
		"isAlreadyTranslated": true
	},
	"Please specify remarks, if any...": {
		"text": "Podaj uwagi, o ile jeśli występują...",
		"isAlreadyTranslated": true
	},
	"Cannot proceed with this action without providing a reason.": {
		"text": "Nie można kontynuować tej czynności bez podania powodu.",
		"isAlreadyTranslated": true
	},
	"Cannot proceed with this action without providing a reason": {
		"text": "Nie można kontynuować tej czynności bez podania powodu",
		"isAlreadyTranslated": true
	},
	"Device transaction details": {
		"text": "Szczegóły transakcji urządzenia",
		"isAlreadyTranslated": true
	},
	"Select Device": {
		"text": "Wybierz urządzenie",
		"isAlreadyTranslated": true
	},
	"Account Details": {
		"text": "Szczegóły konta",
		"isAlreadyTranslated": true
	},
	"Add Customer's Mobile Number": {
		"text": "Dodaj numer telefonu komórkowego klienta",
		"isAlreadyTranslated": true
	},
	"Re - Enter Mobile Number": {
		"text": "Wprowadź ponownie numer telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"Approve Claim Estimation": {
		"text": "Zatwierdź oszacowanie roszczenia",
		"isAlreadyTranslated": true
	},
	"Are you sure? Please specify remarks, if any...": {
		"text": "Czy na pewno? Podaj uwagi, o ile jeśli występują...",
		"isAlreadyTranslated": true
	},
	"Ask for Documents Pending": {
		"text": "Poproś o dokumenty oczekujące",
		"isAlreadyTranslated": true
	},
	"Assign": {
		"text": "Przypisz",
		"isAlreadyTranslated": true
	},
	"Assigned": {
		"text": "Przypisano",
		"isAlreadyTranslated": true
	},
	"Assignment": {
		"text": "Przypisanie",
		"isAlreadyTranslated": true
	},
	"Attempt": {
		"text": "Próba",
		"isAlreadyTranslated": true
	},
	"Available": {
		"text": "Dostępne",
		"isAlreadyTranslated": true
	},
	"Back": {
		"text": "Wstecz",
		"isAlreadyTranslated": true
	},
	"Back to all consumers": {
		"text": "Wróć do wszystkich konsumentów",
		"isAlreadyTranslated": true
	},
	"Back to all requests": {
		"text": "Wróć do wszystkich zgłoszeń",
		"isAlreadyTranslated": true
	},
	"BER Case Found": {
		"text": "Znaleziono przypadek BER",
		"isAlreadyTranslated": true
	},
	"Brand authorised": {
		"text": "Autoryzowana marka",
		"isAlreadyTranslated": true
	},
	"Broker Name": {
		"text": "Nazwa brokera",
		"isAlreadyTranslated": true
	},
	"Call attempted": {
		"text": "Próba połączenia",
		"isAlreadyTranslated": true
	},
	"Cancel Repair": {
		"text": "Anuluj naprawę",
		"isAlreadyTranslated": true
	},
	"Cancel Request": {
		"text": "Anuluj zgłoszenie",
		"isAlreadyTranslated": true
	},
	"Cancel Service": {
		"text": "Anuluj serwis",
		"isAlreadyTranslated": true
	},
	"Cancel Service Without Dispatch": {
		"text": "Anuluj serwis bez wysyłki",
		"isAlreadyTranslated": true
	},
	"Cannot select more than issues.": {
		"text": "Nie można wybrać więcej niż problemów.",
		"isAlreadyTranslated": true
	},
	"Characters must be numbers": {
		"text": "Znaki muszą być cyframi",
		"isAlreadyTranslated": true
	},
	"Characters must be alphabets": {
		"text": "Znaki muszą być literami",
		"isAlreadyTranslated": true
	},
	"Characters must be alphanumeric": {
		"text": "Znaki muszą być alfanumeryczne",
		"isAlreadyTranslated": true
	},
	"Characters must be alphabets (at least 2 alphabets are mandatory)": {
		"text": "Znaki muszą być literami (co najmniej 2 litery są obowiązkowe)",
		"isAlreadyTranslated": true
	},
	"Characters must be numeric.": {
		"text": "Znaki muszą być numeryczne.",
		"isAlreadyTranslated": true
	},
	"Choose from dropdown list only": {
		"text": "Wybierz tylko z listy rozwijanej",
		"isAlreadyTranslated": true
	},
	"Claim": {
		"text": "Roszczenie",
		"isAlreadyTranslated": true
	},
	"Claim estimation approved": {
		"text": "Zatwierdzono oszacowanie roszczenia",
		"isAlreadyTranslated": true
	},
	"Claim estimation generated": {
		"text": "Wygenerowano oszacowanie roszczenia",
		"isAlreadyTranslated": true
	},
	"Claim initiated": {
		"text": "Zainicjowano roszczenie",
		"isAlreadyTranslated": true
	},
	"Claim invalid": {
		"text": "Roszczenie nieważne",
		"isAlreadyTranslated": true
	},
	"Claim raised": {
		"text": "Roszczenie wprowadzone",
		"isAlreadyTranslated": true
	},
	"Claim rejected": {
		"text": "Roszczenie odrzucone",
		"isAlreadyTranslated": true
	},
	"Communication History": {
		"text": "Historia komunikacji",
		"isAlreadyTranslated": true
	},
	"Complete Repair": {
		"text": "Zakończ naprawę",
		"isAlreadyTranslated": true
	},
	"Complete Service": {
		"text": "Zakończ usługę",
		"isAlreadyTranslated": true
	},
	"COMPTIA Code": {
		"text": "Kod COMPTIA",
		"isAlreadyTranslated": true
	},
	"COMPTIA Group": {
		"text": "Grupa COMPTIA",
		"isAlreadyTranslated": true
	},
	"Confirm": {
		"text": "Potwierdź",
		"isAlreadyTranslated": true
	},
	"Connect": {
		"text": "Połącz",
		"isAlreadyTranslated": true
	},
	"Connect Disposition": {
		"text": "Dyspozycja połączenia",
		"isAlreadyTranslated": true
	},
	"Consumer Details": {
		"text": "Szczegóły konsumenta",
		"isAlreadyTranslated": true
	},
	"Consumer Raised Issues": {
		"text": "Problemy zgłaszane przez konsumenta",
		"isAlreadyTranslated": true
	},
	"Contact": {
		"text": "Kontakt",
		"isAlreadyTranslated": true
	},
	"Contract Type": {
		"text": "Typ umowy",
		"isAlreadyTranslated": true
	},
	"Current Log": {
		"text": "Bieżący dziennik",
		"isAlreadyTranslated": true
	},
	"Current Request Reference ID": {
		"text": "Identyfikator referencyjny bieżącego zgłoszenia",
		"isAlreadyTranslated": true
	},
	"Current Status": {
		"text": "Aktualny stan",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive": {
		"text": "Kierownik ds. obsługi klienta",
		"isAlreadyTranslated": true
	},
	"Customer did not add issues": {
		"text": "Klient nie dodał problemów",
		"isAlreadyTranslated": true
	},
	"Customer's Version": {
		"text": "Wersja klienta",
		"isAlreadyTranslated": true
	},
	"DAMAGE DETAILS": {
		"text": "SZCZEGÓŁY USZKODZENIA",
		"isAlreadyTranslated": true
	},
	"Damage Type": {
		"text": "Typ uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Date and Time of loss/damage:": {
		"text": "Data i godzina utraty/uszkodzenia:",
		"isAlreadyTranslated": true
	},
	"When did your device get damaged?": {
		"text": "Kiedy urządzenie zostało uszkodzone?",
		"isAlreadyTranslated": true
	},
	"Please tell us how was your device damaged": {
		"text": "Powiedz nam, w jaki sposób urządzenie zostało uszkodzone",
		"isAlreadyTranslated": true
	},
	"Date of Purchase": {
		"text": "Data zakupu",
		"isAlreadyTranslated": true
	},
	"Dead on Arrival": {
		"text": "Dostarczono zepsute",
		"isAlreadyTranslated": true
	},
	"Description of loss/damage": {
		"text": "Opis utraty/uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Detail Description of Damage": {
		"text": "Szczegółowy opis uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Device": {
		"text": "Urządzenie",
		"isAlreadyTranslated": true
	},
	"Device Added Date": {
		"text": "Data dodania urządzenia",
		"isAlreadyTranslated": true
	},
	"Device Details": {
		"text": "Szczegóły urządzenia",
		"isAlreadyTranslated": true
	},
	"Device dispatched": {
		"text": "Wysłano urządzenie",
		"isAlreadyTranslated": true
	},
	"Device In Warranty?": {
		"text": "Urządzenie na gwarancji?",
		"isAlreadyTranslated": true
	},
	"Device switching on": {
		"text": "Włączanie urządzenia",
		"isAlreadyTranslated": true
	},
	"device_details": {
		"text": "szczegóły_urządzenia",
		"isAlreadyTranslated": true
	},
	"Dispatch Device": {
		"text": "Wyślij urządzenie",
		"isAlreadyTranslated": true
	},
	"Device shipped": {
		"text": "Nadano urządzenie",
		"isAlreadyTranslated": true
	},
	"Device Received": {
		"text": "Otrzymano urządzenie",
		"isAlreadyTranslated": true
	},
	"Distance from user": {
		"text": "Odległość od użytkownika",
		"isAlreadyTranslated": true
	},
	"Distributor Name": {
		"text": "Nazwa dystrybutora",
		"isAlreadyTranslated": true
	},
	"doc": {
		"text": "doc",
		"isAlreadyTranslated": true
	},
	"Document pending": {
		"text": "Oczekujący dokument",
		"isAlreadyTranslated": true
	},
	"Document verification pending": {
		"text": "Oczekiwanie na weryfikację dokumentu",
		"isAlreadyTranslated": true
	},
	"Documents are yet to be uploaded": {
		"text": "Trzeba jeszcze przekazać dokumenty",
		"isAlreadyTranslated": true
	},
	"Download Jobsheet Label": {
		"text": "Pobierz etykietę arkusza pracy",
		"isAlreadyTranslated": true
	},
	"Download Logistics Label": {
		"text": "Pobierz etykietę logistyczną",
		"isAlreadyTranslated": true
	},
	"DD-MM-YYYY": {
		"text": "DD-MM-RRRR",
		"isAlreadyTranslated": true
	},
	"Email ID": {
		"text": "Adres e-mail",
		"isAlreadyTranslated": true
	},
	"End on": {
		"text": "Zakończenie",
		"isAlreadyTranslated": true
	},
	"Engineer": {
		"text": "Inżynier",
		"isAlreadyTranslated": true
	},
	"Enter Call Details": {
		"text": "Wprowadź szczegóły połączenia",
		"isAlreadyTranslated": true
	},
	"Enter correct Mobile Number": {
		"text": "Wprowadź poprawny numer telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"Enter IMEI or Serial Number": {
		"text": "Wprowadź numer IMEI lub numer seryjny",
		"isAlreadyTranslated": true
	},
	"Enter Information for the parts you are issuing": {
		"text": "Wprowadź informacje dotyczące części, które wydajesz",
		"isAlreadyTranslated": true
	},
	"Enter Mobile Number": {
		"text": "Wprowadź numer telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"Enter Reference ID": {
		"text": "Wprowadź identyfikator referencyjny",
		"isAlreadyTranslated": true
	},
	"Enter Reference IDs": {
		"text": "Wprowadź identyfikatory referencyjne",
		"isAlreadyTranslated": true
	},
	"Error": {
		"text": "Błąd",
		"isAlreadyTranslated": true
	},
	"Estimation Details": {
		"text": "Szczegóły kosztorysu",
		"isAlreadyTranslated": true
	},
	"Exclusive": {
		"text": "Ekskluzywne",
		"isAlreadyTranslated": true
	},
	"External Reference ID": {
		"text": "Zewnętrzny identyfikator referencyjny",
		"isAlreadyTranslated": true
	},
	"External Reference ID has to be between 6 - 20 characters.": {
		"text": "Zewnętrzny identyfikator referencyjny musi zawierać od 6 do 20 znaków.",
		"isAlreadyTranslated": true
	},
	"External ReferenceID added successfully": {
		"text": "Pomyślnie dodano zewnętrzny identyfikator referencyjny",
		"isAlreadyTranslated": true
	},
	"Full name": {
		"text": "Pełne imię i nazwisko",
		"isAlreadyTranslated": true
	},
	"Generate Invoice": {
		"text": "Generuj fakturę",
		"isAlreadyTranslated": true
	},
	"Green Rewards": {
		"text": "Zielone nagrody",
		"isAlreadyTranslated": true
	},
	"Green Rewards Chosen": {
		"text": "Wybrane zielone nagrody",
		"isAlreadyTranslated": true
	},
	"GSX Validation Status": {
		"text": "Stan sprawdzania ważności GSX",
		"isAlreadyTranslated": true
	},
	"Home": {
		"text": "Dom",
		"isAlreadyTranslated": true
	},
	"HSN Code": {
		"text": "Kod HSN",
		"isAlreadyTranslated": true
	},
	"ID Proof": {
		"text": "Dowód tożsamości",
		"isAlreadyTranslated": true
	},
	"IMEI 1": {
		"text": "IMEI 1",
		"isAlreadyTranslated": true
	},
	"IMEI Number": {
		"text": "Numer IMEI",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial Number": {
		"text": "Numer IMEI/numer seryjny",
		"isAlreadyTranslated": true
	},
	"In-principle Approval": {
		"text": "Zasadnicze zatwierdzenie",
		"isAlreadyTranslated": true
	},
	"In-principle approved": {
		"text": "Zasadniczo zatwierdzono",
		"isAlreadyTranslated": true
	},
	"Initiate Claim": {
		"text": "Zainicjuj roszczenie",
		"isAlreadyTranslated": true
	},
	"Initiate Service": {
		"text": "Zainicjuj usługę",
		"isAlreadyTranslated": true
	},
	"Insurance Status": {
		"text": "Stan ubezpieczenia",
		"isAlreadyTranslated": true
	},
	"Insurance team will reach out to you for further action.": {
		"text": "Zespół ubezpieczeniowy skontaktuje się z Tobą w sprawie dalszych działań.",
		"isAlreadyTranslated": true
	},
	"Insurer Name": {
		"text": "Nazwa ubezpieczyciela",
		"isAlreadyTranslated": true
	},
	"Invalid email id": {
		"text": "Nieprawidłowy adres e-mail",
		"isAlreadyTranslated": true
	},
	"Invoice": {
		"text": "Faktura",
		"isAlreadyTranslated": true
	},
	"Is Warranty Applicable": {
		"text": "Czy obowiązuje gwarancja",
		"isAlreadyTranslated": true
	},
	"Is Warranty Applicable for this request?": {
		"text": "Czy gwarancja dotyczy tego zgłoszenia?",
		"isAlreadyTranslated": true
	},
	"Issue description": {
		"text": "Opis problemu",
		"isAlreadyTranslated": true
	},
	"Issue Validation": {
		"text": "Sprawdzanie ważności problemu",
		"isAlreadyTranslated": true
	},
	"Issued": {
		"text": "Zgłoszono",
		"isAlreadyTranslated": true
	},
	"Issues": {
		"text": "Problemy",
		"isAlreadyTranslated": true
	},
	"Issues Reported": {
		"text": "Zgłoszone problemy",
		"isAlreadyTranslated": true
	},
	"Landmark": {
		"text": "Punkt orientacyjny",
		"isAlreadyTranslated": true
	},
	"Loading": {
		"text": "Ładowanie",
		"isAlreadyTranslated": true
	},
	"Loading request": {
		"text": "Ładowanie zgłoszenia",
		"isAlreadyTranslated": true
	},
	"Location": {
		"text": "Lokalizacja",
		"isAlreadyTranslated": true
	},
	"Logistics - From Customer's Address To Service Center": {
		"text": "Logistyka — od adresu klienta do centrum serwisowego",
		"isAlreadyTranslated": true
	},
	"Logistics - From Customer's Address To Servify Hub": {
		"text": "Logistyka — od adresu klienta do centrum Servify",
		"isAlreadyTranslated": true
	},
	"Logistics - From Drop-Off Center to Servify Hub": {
		"text": "Logistyka — od centrum nadania do centrum Servify",
		"isAlreadyTranslated": true
	},
	"Logistics - From Servify Hub to Recycle Hub": {
		"text": "Logistyka — od centrum Servify do centrum recyklingu",
		"isAlreadyTranslated": true
	},
	"Logistics - Return to Customer": {
		"text": "Logistyka — zwrot do klienta",
		"isAlreadyTranslated": true
	},
	"Logistics Details": {
		"text": "Szczegóły logistyczne",
		"isAlreadyTranslated": true
	},
	"Loss DateTime": {
		"text": "Data i godzina utraty",
		"isAlreadyTranslated": true
	},
	"Loss Description": {
		"text": "Opis utraty",
		"isAlreadyTranslated": true
	},
	"Mark Customer Arrival": {
		"text": "Zaznacz przybycie klienta",
		"isAlreadyTranslated": true
	},
	"Merge": {
		"text": "Scal",
		"isAlreadyTranslated": true
	},
	"Min 75 Characters": {
		"text": "Min. 75 znaków",
		"isAlreadyTranslated": true
	},
	"Mobile number": {
		"text": "Numer telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"NA": {
		"text": "ND.",
		"isAlreadyTranslated": true
	},
	"Name": {
		"text": "Nazwa",
		"isAlreadyTranslated": true
	},
	"Name of the caller": {
		"text": "Imię i nazwisko dzwoniącego",
		"isAlreadyTranslated": true
	},
	"Next Steps": {
		"text": "Następne kroki",
		"isAlreadyTranslated": true
	},
	"No addresses found": {
		"text": "Nie znaleziono adresów",
		"isAlreadyTranslated": true
	},
	"No brands found": {
		"text": "Nie znaleziono marek",
		"isAlreadyTranslated": true
	},
	"No documents uploaded": {
		"text": "Nie przekazano dokumentów",
		"isAlreadyTranslated": true
	},
	"No engineers found": {
		"text": "Nie znaleziono inżynierów",
		"isAlreadyTranslated": true
	},
	"No issues added": {
		"text": "Nie dodano problemów",
		"isAlreadyTranslated": true
	},
	"No issues found": {
		"text": "Nie znaleziono problemów",
		"isAlreadyTranslated": true
	},
	"No other requests found": {
		"text": "Nie znaleziono innych zgłoszeń",
		"isAlreadyTranslated": true
	},
	"No parts requested": {
		"text": "Brak zgłoszeń dotyczących części",
		"isAlreadyTranslated": true
	},
	"No Pending Document Specified": {
		"text": "Nie określono dokumentu oczekującego",
		"isAlreadyTranslated": true
	},
	"No products found": {
		"text": "Nie znaleziono produktów",
		"isAlreadyTranslated": true
	},
	"No servicable consumer product found": {
		"text": "Nie znaleziono nadającego się do serwisowania produktu konsumenckiego",
		"isAlreadyTranslated": true
	},
	"No service locations found": {
		"text": "Nie znaleziono lokalizacji usług",
		"isAlreadyTranslated": true
	},
	"No Slots Available": {
		"text": "Brak dostępnych przedziałów",
		"isAlreadyTranslated": true
	},
	"No subcategories found": {
		"text": "Nie znaleziono podkategorii",
		"isAlreadyTranslated": true
	},
	"No such service request exists": {
		"text": "Takie zgłoszenie serwisowe nie istnieje",
		"isAlreadyTranslated": true
	},
	"No supported mode found": {
		"text": "Nie znaleziono obsługiwanego trybu",
		"isAlreadyTranslated": true
	},
	"No supported modes found": {
		"text": "Nie znaleziono obsługiwanych trybów",
		"isAlreadyTranslated": true
	},
	"No. of Repairs Allowed": {
		"text": "Liczba dozwolonych napraw",
		"isAlreadyTranslated": true
	},
	"No. of Claims Allowed": {
		"text": "Liczba roszczenia napraw",
		"isAlreadyTranslated": true
	},
	"No. of Repairs Used": {
		"text": "Liczba użytych napraw",
		"isAlreadyTranslated": true
	},

	"Non-Connect Disposition": {
		"text": "Dyspozycja braku połączenia",
		"isAlreadyTranslated": true
	},
	"Office": {
		"text": "Biuro",
		"isAlreadyTranslated": true
	},
	"Okay": {
		"text": "Dobrze",
		"isAlreadyTranslated": true
	},
	"Oops! There are no verified service centres around.": {
		"text": "Ups! W okolicy nie ma zweryfikowanych centrów serwisowych.",
		"isAlreadyTranslated": true
	},
	"Oops... Something went wrong.": {
		"text": "Ups... Coś poszło nie tak.",
		"isAlreadyTranslated": true
	},
	"OR": {
		"text": "LUB",
		"isAlreadyTranslated": true
	},
	"Other": {
		"text": "Inne",
		"isAlreadyTranslated": true
	},
	"other": {
		"text": "inne",
		"isAlreadyTranslated": true
	},
	"Other Requests": {
		"text": "Inne zgłoszenia",
		"isAlreadyTranslated": true
	},
	"Out of Warranty": {
		"text": "Poza gwarancją",
		"isAlreadyTranslated": true
	},
	"Override Approval": {
		"text": "Nadpisz zatwierdzenie",
		"isAlreadyTranslated": true
	},
	"Override Approval History": {
		"text": "Historia nadpisywania zatwierdzenia",
		"isAlreadyTranslated": true
	},
	"Override only if customer instructs to": {
		"text": "Nadpisz tylko wtedy, gdy klient poleci:",
		"isAlreadyTranslated": true
	},
	"override_approval": {
		"text": "nadpisanie_zatwierdzenia",
		"isAlreadyTranslated": true
	},
	"Paid": {
		"text": "Płatne",
		"isAlreadyTranslated": true
	},
	"Part Code": {
		"text": "Kod części",
		"isAlreadyTranslated": true
	},
	"Part Name": {
		"text": "Nazwa części",
		"isAlreadyTranslated": true
	},
	"Parts and Estimation": {
		"text": "Części i kosztorys",
		"isAlreadyTranslated": true
	},
	"Parts Pending": {
		"text": "Części oczekiwane",
		"isAlreadyTranslated": true
	},
	"Parts Received": {
		"text": "Otrzymano części",
		"isAlreadyTranslated": true
	},
	"Parts Requested": {
		"text": "Zgłoszenie dotyczące części",
		"isAlreadyTranslated": true
	},
	"Parts Supply Location": {
		"text": "Lokalizacja dostawy części",
		"isAlreadyTranslated": true
	},
	"Pickup Drop By Fedex": {
		"text": "Pickup Drop By Fedex",
		"isAlreadyTranslated": true
	},
	"Please enter at least one document": {
		"text": "Wprowadź co najmniej jeden dokument",
		"isAlreadyTranslated": true
	},
	"Please enter correct": {
		"text": "Wprowadź poprawnie",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI": {
		"text": "Wprowadź poprawny numer IMEI",
		"isAlreadyTranslated": true
	},
	"Please enter reason for approval": {
		"text": "Wprowadź powód zatwierdzenia",
		"isAlreadyTranslated": true
	},
	"Please select a subcategory first": {
		"text": "Wybierz najpierw podkategorię",
		"isAlreadyTranslated": true
	},
	"Please specify the reason": {
		"text": "Określ powód",
		"isAlreadyTranslated": true
	},
	"QC Engineer": {
		"text": "Inżynier kontroli jakości",
		"isAlreadyTranslated": true
	},
	"Raise Consumer Service Request": {
		"text": "Wprowadź zgłoszenie obsługi konsumenta",
		"isAlreadyTranslated": true
	},
	"Raise new request": {
		"text": "Wprowadź nowe zgłoszenie",
		"isAlreadyTranslated": true
	},
	"Reason for Approval": {
		"text": "Powód zatwierdzenia",
		"isAlreadyTranslated": true
	},
	"Receive Device": {
		"text": "Otrzymaj urządzenie",
		"isAlreadyTranslated": true
	},
	"Reject Claim": {
		"text": "Odrzuć roszczenie",
		"isAlreadyTranslated": true
	},
	"Repair cancel": {
		"text": "Anulowanie naprawy",
		"isAlreadyTranslated": true
	},
	"Report Issues to GSX": {
		"text": "Raportuj problemy do GSX",
		"isAlreadyTranslated": true
	},
	"Request Cancelled": {
		"text": "Zgłoszenie anulowane",
		"isAlreadyTranslated": true
	},
	"Request Details": {
		"text": "Szczegóły zgłoszenia",
		"isAlreadyTranslated": true
	},
	"Request Documents": {
		"text": "Dokumenty zgłoszenia",
		"isAlreadyTranslated": true
	},
	"Request Has Been Raised": {
		"text": "Zgłoszenie zostało wprowadzone",
		"isAlreadyTranslated": true
	},
	"Request is being processed": {
		"text": "Zgłoszenie jest przetwarzane",
		"isAlreadyTranslated": true
	},
	"Request Scheduled for": {
		"text": "Zapytanie zaplanowane na",
		"isAlreadyTranslated": true
	},
	"Requested By": {
		"text": "Zgłoszenie zgłoszone przez",
		"isAlreadyTranslated": true
	},
	"Requires customer to print documents and safely pack the device": {
		"text": "Wymaga od klienta wydrukowania dokumentów i bezpiecznego zapakowania urządzenia",
		"isAlreadyTranslated": true
	},
	"Reschedule": {
		"text": "Zmień termin",
		"isAlreadyTranslated": true
	},
	"Retailer Name": {
		"text": "Nazwa sprzedawcy",
		"isAlreadyTranslated": true
	},
	"Rewards updated successfully": {
		"text": "Pomyślnie zaktualizowano nagrody",
		"isAlreadyTranslated": true
	},
	"Role": {
		"text": "Rola",
		"isAlreadyTranslated": true
	},
	"Save": {
		"text": "Zapisz",
		"isAlreadyTranslated": true
	},
	"Schedule Date": {
		"text": "Ustal datę",
		"isAlreadyTranslated": true
	},
	"Select a Brand": {
		"text": "Wybierz markę",
		"isAlreadyTranslated": true
	},
	"Select a Consumer Product": {
		"text": "Wybierz produkt konsumencki",
		"isAlreadyTranslated": true
	},
	"Select a Date": {
		"text": "Wybierz datę",
		"isAlreadyTranslated": true
	},
	"Select a Product": {
		"text": "Wybierz produkt",
		"isAlreadyTranslated": true
	},
	"Select a Reward": {
		"text": "Wybierz nagrodę",
		"isAlreadyTranslated": true
	},
	"Select a service center": {
		"text": "Wybierz centrum serwisowe",
		"isAlreadyTranslated": true
	},
	"Select a service location": {
		"text": "Wybierz lokalizację usługi",
		"isAlreadyTranslated": true
	},
	"Select a Service Mode": {
		"text": "Wybierz tryb usługi",
		"isAlreadyTranslated": true
	},
	"Select a Slot": {
		"text": "Wybierz przedział czasu",
		"isAlreadyTranslated": true
	},
	"Select a Subcategory": {
		"text": "Wybierz podkategorię",
		"isAlreadyTranslated": true
	},
	"Select an Address": {
		"text": "Wybierz adres",
		"isAlreadyTranslated": true
	},
	"Select an engineer": {
		"text": "Wybierz inżyniera",
		"isAlreadyTranslated": true
	},
	"Select issues": {
		"text": "Wybierz problemy",
		"isAlreadyTranslated": true
	},
	"Select Logistics Partner": {
		"text": "Wybierz partnera logistycznego",
		"isAlreadyTranslated": true
	},
	"Select type of damage": {
		"text": "Wybierz typ uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Selected Product": {
		"text": "Wybrany produkt",
		"isAlreadyTranslated": true
	},
	"Send for raising a request?": {
		"text": "Wysłać do zgłoszenia żądania?",
		"isAlreadyTranslated": true
	},
	"Search issues": {
		"text": "Wyszukaj problemy",
		"isAlreadyTranslated": true
	},
	"Search brands": {
		"text": "Wyszukaj marki",
		"isAlreadyTranslated": true
	},
	"Search products": {
		"text": "Wyszukaj produkty",
		"isAlreadyTranslated": true
	},
	"Search subcategories": {
		"text": "Wyszukaj podkategorie",
		"isAlreadyTranslated": true
	},
	"Serial No": {
		"text": "Nr seryjny",
		"isAlreadyTranslated": true
	},
	"Serial Number": {
		"text": "Numer seryjny",
		"isAlreadyTranslated": true
	},
	"Service": {
		"text": "Usługa",
		"isAlreadyTranslated": true
	},
	"Service cancel": {
		"text": "Anulowanie usługi",
		"isAlreadyTranslated": true
	},
	"Service Center": {
		"text": "Centrum serwisowe",
		"isAlreadyTranslated": true
	},
	"Service completed": {
		"text": "Usługa zakończona",
		"isAlreadyTranslated": true
	},
	"Service Engineer": {
		"text": "Serwisant",
		"isAlreadyTranslated": true
	},
	"Service Type": {
		"text": "Typ usługi",
		"isAlreadyTranslated": true
	},
	"Slot": {
		"text": "Przedział",
		"isAlreadyTranslated": true
	},
	"Slot Time": {
		"text": "Czas przedziału",
		"isAlreadyTranslated": true
	},
	"Something went wrong": {
		"text": "Coś poszło nie tak",
		"isAlreadyTranslated": true
	},
	"Something went wrong while adding the product": {
		"text": "Coś poszło nie tak podczas dodawania produktu",
		"isAlreadyTranslated": true
	},
	"Something went wrong while raising service request": {
		"text": "Coś poszło nie tak podczas zgłaszania zgłoszenia serwisowego",
		"isAlreadyTranslated": true
	},
	"Start Repair": {
		"text": "Rozpocznij naprawę",
		"isAlreadyTranslated": true
	},
	"Started On": {
		"text": "Rozpoczęto w dniu",
		"isAlreadyTranslated": true
	},
	"Status": {
		"text": "Stan",
		"isAlreadyTranslated": true
	},
	"Storage Capacity": {
		"text": "Pojemność pamięci masowej",
		"isAlreadyTranslated": true
	},
	"Store Manager": {
		"text": "Kierownik magazynu",
		"isAlreadyTranslated": true
	},
	"Submit": {
		"text": "Prześlij",
		"isAlreadyTranslated": true
	},
	"success": {
		"text": "powodzenie",
		"isAlreadyTranslated": true
	},
	"Success": {
		"text": "Powodzenie",
		"isAlreadyTranslated": true
	},
	"Superadmin": {
		"text": "Superadministrator",
		"isAlreadyTranslated": true
	},
	"Supervisor": {
		"text": "Inspektor",
		"isAlreadyTranslated": true
	},
	"Telecon Version:": {
		"text": "Wersja Telecon:",
		"isAlreadyTranslated": true
	},
	"The Green Reward cash amount will be credited to the account associated with this number": {
		"text": "Kwota pieniężna zielonej nagrody zostanie przelana na konto powiązane z tym numerem",
		"isAlreadyTranslated": true
	},
	"The request was raised successfully": {
		"text": "Zgłoszenie zostało wprowadzone pomyślnie",
		"isAlreadyTranslated": true
	},
	"The two numbers do not match": {
		"text": "Te dwa numery nie są zgodne",
		"isAlreadyTranslated": true
	},
	"There is no service for this address at this moment": {
		"text": "W tej chwili nie ma usługi dla tego adresu",
		"isAlreadyTranslated": true
	},
	"There's a problem with this landmark, please select a different landmark": {
		"text": "Wystąpił problem z tym punktem orientacyjnym, wybierz inny punkt orientacyjny",
		"isAlreadyTranslated": true
	},
	"Time of Damage": {
		"text": "Czas uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Total Amount": {
		"text": "Całkowita kwota",
		"isAlreadyTranslated": true
	},
	"Select Damage Type": {
		"text": "Wybierz typ uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Type Of Program": {
		"text": "Typ programu",
		"isAlreadyTranslated": true
	},
	"Under Warranty": {
		"text": "Na gwarancji",
		"isAlreadyTranslated": true
	},
	"Validate": {
		"text": "Sprawdź ważność",
		"isAlreadyTranslated": true
	},
	"Validity Text": {
		"text": "Tekst ważności",
		"isAlreadyTranslated": true
	},
	"Verification Code": {
		"text": "Kod weryfikacyjny",
		"isAlreadyTranslated": true
	},
	"View": {
		"text": "Wyświetl",
		"isAlreadyTranslated": true
	},
	"View Advance Receipt": {
		"text": "Wyświetl pokwitowanie zaliczki",
		"isAlreadyTranslated": true
	},
	"View DOA Certificate": {
		"text": "Wyświetl certyfikat DOA",
		"isAlreadyTranslated": true
	},
	"View Invoice": {
		"text": "Wyświetl fakturę",
		"isAlreadyTranslated": true
	},
	"View Jobsheet": {
		"text": "Wyświetl arkusz pracy",
		"isAlreadyTranslated": true
	},
	"View Parts Document": {
		"text": "Wyświetl dokument części",
		"isAlreadyTranslated": true
	},
	"Waiting for the customer to raise a request": {
		"text": "Oczekiwanie na wprowadzenie zgłoszenia przez klienta",
		"isAlreadyTranslated": true
	},
	"Warranty": {
		"text": "Gwarancja",
		"isAlreadyTranslated": true
	},
	"Warranty Applicable": {
		"text": "Obowiązuje gwarancja",
		"isAlreadyTranslated": true
	},
	"Warranty Status": {
		"text": "Stan gwarancji",
		"isAlreadyTranslated": true
	},
	"Warranty Till": {
		"text": "Termin gwarancji",
		"isAlreadyTranslated": true
	},
	"Waybill Number": {
		"text": "Numer listu przewozowego",
		"isAlreadyTranslated": true
	},
	"Year of Purchase": {
		"text": "Rok zakupu",
		"isAlreadyTranslated": true
	},
	"Yes": {
		"text": "Tak",
		"isAlreadyTranslated": true
	},
	"You have already added this document type in the list of pending documents": {
		"text": "Ten typ dokumentu został już przez Ciebie dodany do listy dokumentów oczekujących",
		"isAlreadyTranslated": true
	},
	"You have already added this issue": {
		"text": "Ten problem został już przez Ciebie dodany",
		"isAlreadyTranslated": true
	},
	"Zipcode": {
		"text": "Kod pocztowy",
		"isAlreadyTranslated": true
	},
	"Raise Claim": {
		"text": "Zgłoś roszczenie",
		"isAlreadyTranslated": true
	},
	"Invalid Claim": {
		"text": "Nieważne roszczenie",
		"isAlreadyTranslated": true
	},
	"Reject Estimation": {
		"text": "Odrzuć kosztorys",
		"isAlreadyTranslated": true
	},
	"Collect Advance": {
		"text": "Pobierz zaliczkę",
		"isAlreadyTranslated": true
	},
	"Complete QC": {
		"text": "Zakończ kontrolę jakości",
		"isAlreadyTranslated": true
	},
	"Handover device": {
		"text": "Przekaż urządzenie",
		"isAlreadyTranslated": true
	},
	"Accept device": {
		"text": "Zaakceptuj urządzenie",
		"isAlreadyTranslated": true
	},
	"Complete Job and Handover Device": {
		"text": "Zakończ zadanie i przekaż urządzenie",
		"isAlreadyTranslated": true
	},
	"Collect Device": {
		"text": "Odbierz urządzenie",
		"isAlreadyTranslated": true
	},
	"Accept/Reject Job": {
		"text": "Zaakceptuj/odrzuć zadanie",
		"isAlreadyTranslated": true
	},
	"Generate estimation": {
		"text": "Wygeneruj kosztorys",
		"isAlreadyTranslated": true
	},
	"characters entered.": {
		"text": "— liczba wprowadzonych znaków.",
		"isAlreadyTranslated": true
	},
	"Your GSX Request has been created": {
		"text": "Utworzono zgłoszenie GSX",
		"isAlreadyTranslated": true
	},
	"Upload Inspection Documents": {
		"text": "Prześlij dokumenty kontrolne",
		"isAlreadyTranslated": true
	},
	"Complete DOA": {
		"text": "Zakończ DOA",
		"isAlreadyTranslated": true
	},
	"QC Completed": {
		"text": "Zakończono kontrolę jakości",
		"isAlreadyTranslated": true
	},
	"Create GSX request": {
		"text": "Utwórz zgłoszenie GSX",
		"isAlreadyTranslated": true
	},
	"DOA Checklist": {
		"text": "Lista kontrolna DOA",
		"isAlreadyTranslated": true
	},
	"Reject Parts Request": {
		"text": "Odrzuć zgłoszenie części",
		"isAlreadyTranslated": true
	},
	"Accept Parts Request": {
		"text": "Zaakceptuj żądanie części",
		"isAlreadyTranslated": true
	},
	"Cannot Diagnose": {
		"text": "Nie można zdiagnozować",
		"isAlreadyTranslated": true
	},
	"Initiate Diagnosis": {
		"text": "Zainicjuj diagnostykę",
		"isAlreadyTranslated": true
	},
	"Diagnosis Completed": {
		"text": "Zakończono diagnostykę",
		"isAlreadyTranslated": true
	},
	"Accept Device": {
		"text": "Zaakceptuj urządzenie",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer to accept the job.": {
		"text": "Oczekiwanie, aż inżynier zaakceptuje zadanie.",
		"isAlreadyTranslated": true
	},
	"Transfer Request": {
		"text": "Przenieś zgłoszenie",
		"isAlreadyTranslated": true
	},
	"Accept and Complete QC": {
		"text": "Zaakceptuj i zakończ kontrolę jakości",
		"isAlreadyTranslated": true
	},
	"Reject QC": {
		"text": "Odrzuć kontrolę jakości",
		"isAlreadyTranslated": true
	},
	"Confirm BER": {
		"text": "Potwierdź BER",
		"isAlreadyTranslated": true
	},
	"Reject BER": {
		"text": "Odrzuć BER",
		"isAlreadyTranslated": true
	},
	"Perform QC": {
		"text": "Wykonaj kontrolę jakości",
		"isAlreadyTranslated": true
	},
	"Fault Codes": {
		"text": "Kody usterek",
		"isAlreadyTranslated": true
	},
	"Actions": {
		"text": "Akcje",
		"isAlreadyTranslated": true
	},
	"Engineer Added Faults": {
		"text": "Usterki dodane przez inżyniera",
		"isAlreadyTranslated": true
	},
	"Faults": {
		"text": "Usterki",
		"isAlreadyTranslated": true
	},
	"Action Code": {
		"text": "Kod czynności",
		"isAlreadyTranslated": true
	},
	"N/A": {
		"text": "ND.",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to receive device and complete mandatory checklist tasks.": {
		"text": "Oczekiwanie na otrzymanie urządzenia przez kierownika ds. obsługi klienta i zakończenie obowiązkowych zadań z listy kontrolnej.",
		"isAlreadyTranslated": true
	},
	"Waiting for CC to receive device and complete mandatory checklist tasks.": {
		"text": "Oczekiwanie na otrzymanie urządzenia przez obsługę klienta i zakończenie obowiązkowych zadań z listy kontrolnej.",
		"isAlreadyTranslated": true
	},
	"Yes, the device is whitelisted. Please upload screenshot of the same": {
		"text": "Tak, urządzenie jest na białej liście. Przekaż zrzut ekranu tego samego",
		"isAlreadyTranslated": true
	},
	"No pictures added": {
		"text": "Nie dodano zdjęć",
		"isAlreadyTranslated": true
	},
	"No, the device is not whitelisted. Please cancel the request and return the device if you have it": {
		"text": "Nie, urządzenia nie ma na białej liście. Anuluj zgłoszenie i zwróć urządzenie, jeśli je masz",
		"isAlreadyTranslated": true
	},
	"Next": {
		"text": "Dalej",
		"isAlreadyTranslated": true
	},
	"Device Name": {
		"text": "Nazwa urządzenia",
		"isAlreadyTranslated": true
	},
	"ELS": {
		"text": "ELS",
		"isAlreadyTranslated": true
	},
	"QC": {
		"text": "Kontrola jakości",
		"isAlreadyTranslated": true
	},
	"Please select Coverage Option": {
		"text": "Wybierz opcję pokrycia",
		"isAlreadyTranslated": true
	},
	"DOA": {
		"text": "DOA",
		"isAlreadyTranslated": true
	},
	"Device Whitelisting Information": {
		"text": "Informacje o białej liście urządzeń",
		"isAlreadyTranslated": true
	},
	"KBB Serial Number": {
		"text": "Numer seryjny KBB",
		"isAlreadyTranslated": true
	},
	"Inspection Completed, Awaiting Engineer Assignment": {
		"text": "Inspekcja zakończona, oczekiwanie na przydział inżyniera",
		"isAlreadyTranslated": true
	},
	"Verification Complete": {
		"text": "Zakończono weryfikację",
		"isAlreadyTranslated": true
	},
	"Dropoff Request Registered": {
		"text": "Zarejestrowano zgłoszenie nadania",
		"isAlreadyTranslated": true
	},
	"Service initiated": {
		"text": "Zainicjowano usługę",
		"isAlreadyTranslated": true
	},
	"Device dropped": {
		"text": "Urządzenie zostało nadane",
		"isAlreadyTranslated": true
	},
	"Recycle Dropoff": {
		"text": "Miejsce nadania do recyklingu",
		"isAlreadyTranslated": true
	},
	"Pledge request created": {
		"text": "Utworzono zgłoszenie zastawu",
		"isAlreadyTranslated": true
	},
	"QC completed": {
		"text": "Zakończono kontrolę jakości",
		"isAlreadyTranslated": true
	},
	"sdv": {
		"text": "sdv",
		"isAlreadyTranslated": true
	},
	"Intimate customer": {
		"text": "Poinformuj klienta",
		"isAlreadyTranslated": true
	},
	"Repair has been cancelled.": {
		"text": "Naprawa została anulowana.",
		"isAlreadyTranslated": true
	},
	"BTB socket": {
		"text": "Gniazdo BTB",
		"isAlreadyTranslated": true
	},
	"SIM Card needle": {
		"text": "Igła karty SIM",
		"isAlreadyTranslated": true
	},
	"SIM card holder EB101 grey aluminum alloy": {
		"text": "Uchwyt karty SIM EB101 szary stop aluminium",
		"isAlreadyTranslated": true
	},
	"Pending for Acceptance by Engineer": {
		"text": "Oczekiwanie na akceptację przez inżyniera",
		"isAlreadyTranslated": true
	},
	"microphone": {
		"text": "mikrofon",
		"isAlreadyTranslated": true
	},
	"for Token generated": {
		"text": "dla wygenerowanego tokenu",
		"isAlreadyTranslated": true
	},
	"Advance collected": {
		"text": "Pobrano zaliczkę",
		"isAlreadyTranslated": true
	},
	"pin added": {
		"text": "dodano kod PIN",
		"isAlreadyTranslated": true
	},
	"Trade In Dropoff": {
		"text": "Nadanie do wymiany za dopłatą",
		"isAlreadyTranslated": true
	},
	"No, the device is not whitelisted. Please return the device if you have it": {
		"text": "Nie, urządzenia nie ma na białej liście. Zwróć urządzenie, jeśli je masz",
		"isAlreadyTranslated": true
	},
	"Download Open/Inprogress requests.": {
		"text": "Pobierz zgłoszenia otwarte/w toku.",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer to accept the job. (Eligible for DOA)": {
		"text": "Oczekiwanie, aż inżynier zaakceptuje zadanie. (Kwalifikuje się do DOA)",
		"isAlreadyTranslated": true
	},
	"(Eligible for DOA)": {
		"text": " (Kwalifikuje się do DOA)",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive will choose action for the device from Dead on Arrival section below.": {
		"text": "Kierownik działu obsługi klienta wybierze akcję dla urządzenia z poniższej sekcji Dostarczono zepsute.",
		"isAlreadyTranslated": true
	},
	"Engineer will select the new device from Dead on Arrival section below": {
		"text": "Inżynier wybierze nowe urządzenie z poniższej sekcji Dostarczono zepsute",
		"isAlreadyTranslated": true
	},
	"Device repair has failed QC. Device is to be given back to the Engineer.": {
		"text": "Naprawa urządzenia nie przeszła kontroli jakości. Urządzenie należy zwrócić inżynierowi.",
		"isAlreadyTranslated": true
	},
	'Waiting for Estimation Approval. Click on "Send for approval" in "Parts and Estimation" section below.': {
		"text": "Oczekiwanie na zatwierdzenie kosztorysu. Kliknij „Wyślij do zatwierdzenia” w poniższej sekcji „Części i kosztorys”.",
		"isAlreadyTranslated": true
	},
	'Waiting for customer approval of estimation or override estimation approval in "Parts and Estimation" section below.': {
		"text": "Oczekiwanie na zatwierdzenie kosztorysu przez klienta lub nadpisanie zatwierdzenia kosztorysu w poniższej sekcji „Części i kosztorys”.",
		"isAlreadyTranslated": true
	},
	"Estimation has been rejected, parts may be added if required by the engineer.": {
		"text": "Kosztorys został odrzucony, części mogą zostać dodane, jeśli wymaga tego inżynier.",
		"isAlreadyTranslated": true
	},
	'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.': {
		"text": "Oczekiwanie na wydanie z magazynu żądanych części poniższej sekcji „Żądane części”. Nie można dokończyć naprawy z oczekiwanymi częściami.",
		"isAlreadyTranslated": true
	},
	'Waiting for store to issue required device in "Dead on arrival" section below.': {
		"text": "Oczekiwanie na wydanie z magazynu wymaganego urządzenia w poniższej sekcji „Dostarczono zepsute”.",
		"isAlreadyTranslated": true
	},
	'Waiting for the Engineer to receive the "Replacement device" from store in "Dead on Arrival" section below.': {
		"text": "Oczekiwanie, aż inżynier otrzyma „Urządzenie zamienne” w poniższej sekcji „Dostarczono zepsute”.",
		"isAlreadyTranslated": true
	},
	'Waiting for Engineer to receive parts from store in "Parts Requested" section below.': {
		"text": "Oczekiwanie, aż inżynier otrzyma części z magazynu w poniższej sekcji „Zamówione części”.",
		"isAlreadyTranslated": true
	},
	"Waiting for the Engineer to complete DOA by handing over the damaged device to Store": {
		"text": "Oczekiwanie, aż inżynier zakończy DOA przez przekazanie uszkodzonego urządzenia do magazynu",
		"isAlreadyTranslated": true
	},
	"All parts received, repair is in progress.": {
		"text": "Otrzymano wszystkie części, trwa naprawa.",
		"isAlreadyTranslated": true
	},
	"Engineer completed the repair.": {
		"text": "Inżynier zakończył naprawę.",
		"isAlreadyTranslated": true
	},
	"Service invoice has been generated, final payment can be received.": {
		"text": "Wygenerowano fakturę za usługę, można otrzymać ostateczną płatność.",
		"isAlreadyTranslated": true
	},
	"Waiting for the engineer to handover device to Store": {
		"text": "Oczekiwanie, aż inżynier przekaże urządzenie do magazynu",
		"isAlreadyTranslated": true
	},
	"Waiting for the Store to handover device to Customer Care Executive": {
		"text": "Oczekiwanie, aż magazyn przekaże urządzenie kierownikowi ds. obsługi klienta",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to complete the DOA": {
		"text": "Oczekiwanie, aż kierownik ds. obsługi klienta zakończy DOA",
		"isAlreadyTranslated": true
	},
	"Please complete the service.": {
		"text": "Zakończ usługę.",
		"isAlreadyTranslated": true
	},
	"Waiting for the customer to rate the service": {
		"text": "Oczekiwanie, aż klient oceni usługę",
		"isAlreadyTranslated": true
	},
	"Intimate customer.": {
		"text": "Poinformuj klienta.",
		"isAlreadyTranslated": true
	},
	"Logistics": {
		"text": "Logistyka",
		"isAlreadyTranslated": true
	},
	"Send link": {
		"text": "Wyślij łącze",
		"isAlreadyTranslated": true
	},
	"Other Image": {
		"text": "Inny obraz",
		"isAlreadyTranslated": true
	},
	"Item": {
		"text": "Element",
		"isAlreadyTranslated": true
	},
	"Transfer Details": {
		"text": "Szczegóły przelewu",
		"isAlreadyTranslated": true
	},
	"Schedule a Logistic Request": {
		"text": "Zaplanuj zgłoszenie logistyczne",
		"isAlreadyTranslated": true
	},
	"Forward": {
		"text": "Do przodu",
		"isAlreadyTranslated": true
	},
	"Reverse": {
		"text": "Wstecz",
		"isAlreadyTranslated": true
	},
	"Transfer Details": {
		"text": "Szczegóły przelewu",
		"isAlreadyTranslated": true
	},
	"Forward": {
		"text": "Do przodu",
		"isAlreadyTranslated": true
	},
	"Reverse": {
		"text": "Wstecz",
		"isAlreadyTranslated": true
	},
	"Track Logistics Requests": {
		"text": "Śledź zgłoszenia logistyczne",
		"isAlreadyTranslated": true
	},
	"Pickup Pincode": {
		"text": "Kod PIN odbioru",
		"isAlreadyTranslated": true
	},
	"Recycle Request Schedule Date": {
		"text": "Data harmonogramu zgłoszenia recyklingu",
		"isAlreadyTranslated": true
	},
	"Recycle Request Created Date": {
		"text": "Data utworzenia zgłoszenia recyklingu",
		"isAlreadyTranslated": true
	},
	"Schedule Logistics Request": {
		"text": "Zaplanuj zgłoszenie logistyczne",
		"isAlreadyTranslated": true
	},
	"Hub's Address Details": {
		"text": "Dane adresowe centrum",
		"isAlreadyTranslated": true
	},
	"Now": {
		"text": "Teraz",
		"isAlreadyTranslated": true
	},
	"Later": {
		"text": "Później",
		"isAlreadyTranslated": true
	},
	"Recycle Hub Details": {
		"text": "Szczegóły centrum recyklingu",
		"isAlreadyTranslated": true
	},
	"This request will be added to a queue. You can schedule a logistics request later.": {
		"text": "To zgłoszenie zostanie dodane do kolejki. Zgłoszenie logistyczne możesz zaplanować później.",
		"isAlreadyTranslated": true
	},
	"Request Added to Queue Successfully": {
		"text": "Zgłoszenie zostało pomyślnie dodane do kolejki",
		"isAlreadyTranslated": true
	},
	"Create Bulk Requests": {
		"text": "Utwórz zgłoszenia zbiorcze",
		"isAlreadyTranslated": true
	},
	"Logistics Status": {
		"text": "Stan logistyczny",
		"isAlreadyTranslated": true
	},
	"Drop Pincode": {
		"text": "Kod pocztowy miejsca dostarczenia",
		"isAlreadyTranslated": true
	},
	"Logistic Request Created Date": {
		"text": "Data utworzenia zgłoszenia logistycznego",
		"isAlreadyTranslated": true
	},
	"Logistic Vendor": {
		"text": "Dostawca logistyki",
		"isAlreadyTranslated": true
	},
	"Bulk Logistic Reference ID": {
		"text": "Identyfikator referencyjny logistyki zbiorczej",
		"isAlreadyTranslated": true
	},
	"Not Covered": {
		"text": "Nieobjęte",
		"isAlreadyTranslated": true
	},
	"Queue": {
		"text": "Kolejka",
		"isAlreadyTranslated": true
	},
	"LOGISTICS REQUESTS": {
		"text": "ZGŁOSZENIA LOGISTYCZNE",
		"isAlreadyTranslated": true
	},
	"Work in progress": {
		"text": "Praca w toku",
		"isAlreadyTranslated": true
	},
	"Recycle Pickup": {
		"text": "Miejsce odbioru do recyklingu",
		"isAlreadyTranslated": true
	},
	"Recycle Request": {
		"text": "Zgłoszenie recyklingu",
		"isAlreadyTranslated": true
	},
	"Beyond Economic Repair": {
		"text": "Naprawa nieopłacalna",
		"isAlreadyTranslated": true
	},
	"Device collected": {
		"text": "Odebrano urządzenie",
		"isAlreadyTranslated": true
	},
	"Green Reward Update": {
		"text": "Aktualizacja zielonych nagród",
		"isAlreadyTranslated": true
	},
	"Vouchers generation pending": {
		"text": "Oczekiwanie na wygenerowanie talonów",
		"isAlreadyTranslated": true
	},
	"Recycle Request cancelled": {
		"text": "Anulowano zgłoszenie recyklingu",
		"isAlreadyTranslated": true
	},
	"Recycle Completed": {
		"text": "Zakończono recykling",
		"isAlreadyTranslated": true
	},
	"Vouchers generated": {
		"text": "Wygenerowano talony",
		"isAlreadyTranslated": true
	},
	"Initial Dismantling Completed": {
		"text": "Zakończono wstępny demontaż",
		"isAlreadyTranslated": true
	},
	"Device recycle initiated": {
		"text": "Zainicjowano recycling urządzenia",
		"isAlreadyTranslated": true
	},
	"Claim withdrawn": {
		"text": "Wycofano roszczenie",
		"isAlreadyTranslated": true
	},
	"Pending Verification": {
		"text": "Oczekiwanie na weryfikację",
		"isAlreadyTranslated": true
	},
	"Screen damage": {
		"text": "Uszkodzenie ekranu",
		"isAlreadyTranslated": true
	},
	"Print": {
		"text": "Drukuj",
		"isAlreadyTranslated": true
	},
	"Pupa Service Record": {
		"text": "Pupa Service Record",
		"isAlreadyTranslated": true
	},
	"User Name": {
		"text": "Nazwa użytkownika",
		"isAlreadyTranslated": true
	},
	"Telephone": {
		"text": "Telefon",
		"isAlreadyTranslated": true
	},
	"Product Model": {
		"text": "Model produktu",
		"isAlreadyTranslated": true
	},
	"Purchase Date": {
		"text": "Data zakupu",
		"isAlreadyTranslated": true
	},
	"Carry-in Time": {
		"text": "Czas dostawy",
		"isAlreadyTranslated": true
	},
	"Pick-up Time": {
		"text": "Godzina odbioru",
		"isAlreadyTranslated": true
	},
	"Key Account Name": {
		"text": "Nazwa kluczowego konta",
		"isAlreadyTranslated": true
	},
	"Service Offering": {
		"text": "Oferta usługi",
		"isAlreadyTranslated": true
	},
	"In warranty": {
		"text": "Na gwarancji",
		"isAlreadyTranslated": true
	},
	"Problem Description by User": {
		"text": "Opis problemu dokonany przez użytkownika",
		"isAlreadyTranslated": true
	},
	"Parts replacement": {
		"text": "Wymiana części",
		"isAlreadyTranslated": true
	},
	"New IMEI/SN": {
		"text": "Nowy numer IMEI/seryjny",
		"isAlreadyTranslated": true
	},
	"Part Number": {
		"text": "Numer części",
		"isAlreadyTranslated": true
	},
	"Model Number": {
		"text": "Numer modelu",
		"isAlreadyTranslated": true
	},
	"Replacement Name": {
		"text": "Nazwa zastępcza",
		"isAlreadyTranslated": true
	},
	"Engineer Signature": {
		"text": "Podpis inżyniera",
		"isAlreadyTranslated": true
	},
	"Finish Date": {
		"text": "Data zakończenia",
		"isAlreadyTranslated": true
	},
	"Very Satisfied": {
		"text": "Bardzo zadowolony",
		"isAlreadyTranslated": true
	},
	"Satisfied": {
		"text": "Zadowolony",
		"isAlreadyTranslated": true
	},
	"Neutral": {
		"text": "Neutralny",
		"isAlreadyTranslated": true
	},
	"Dissatisfied": {
		"text": "Niezadowolony",
		"isAlreadyTranslated": true
	},
	"Very Dissatisfied": {
		"text": "Bardzo niezadowolony",
		"isAlreadyTranslated": true
	},
	"User Signature": {
		"text": "Podpis użytkownika",
		"isAlreadyTranslated": true
	},
	"Date": {
		"text": "Data",
		"isAlreadyTranslated": true
	},
	"Thank you for choosing Pupa": {
		"text": "Dziękujemy za wybranie Pupa",
		"isAlreadyTranslated": true
	},
	"Pupa Service Form": {
		"text": "Formularz usługi Pupa",
		"isAlreadyTranslated": true
	},
	"Recovery Method": {
		"text": "Metoda odzyskiwania",
		"isAlreadyTranslated": true
	},
	"Defective Model Name": {
		"text": "Nazwa wadliwego modelu",
		"isAlreadyTranslated": true
	},
	"Defective Part Name": {
		"text": "Nazwa wadliwej części",
		"isAlreadyTranslated": true
	},
	"Coverage Type": {
		"text": "Typ pokrycia",
		"isAlreadyTranslated": true
	},
	"Service Ticket No": {
		"text": "Numer biletu serwisowego",
		"isAlreadyTranslated": true
	},
	"Customer Care": {
		"text": "Obsługa klienta",
		"isAlreadyTranslated": true
	},
	"Are you satisfied with Pupa service experience": {
		"text": "Czy jesteś zadowolony/zadowolona z usługi Pupa?",
		"isAlreadyTranslated": true
	},
	"Please comment if you are dissatisfied": {
		"text": "W razie niezadowolenie, podaj komentarz",
		"isAlreadyTranslated": true
	},
	"Front Image": {
		"text": "Zdjęcie z przodu",
		"isAlreadyTranslated": true
	},
	"Back Image": {
		"text": "Zdjęcie z tyłu",
		"isAlreadyTranslated": true
	},
	"Top Image": {
		"text": "Zdjęcie z góry",
		"isAlreadyTranslated": true
	},
	"Bottom Image": {
		"text": "Zdjęcie z dołu",
		"isAlreadyTranslated": true
	},
	"Left Image": {
		"text": "Zdjęcie z lewej",
		"isAlreadyTranslated": true
	},
	"Right Image": {
		"text": "Zdjęcie z prawej",
		"isAlreadyTranslated": true
	},
	"Device Invoice": {
		"text": "Faktura za urządzenie",
		"isAlreadyTranslated": true
	},
	"Cosmetic Status of Device": {
		"text": "Stan kosmetyczny urządzenia",
		"isAlreadyTranslated": true
	},
	"Latest diagnostics result": {
		"text": "Ostatni wynik diagnostyki",
		"isAlreadyTranslated": true
	},
	"Purchase Order Number": {
		"text": "Numer zamówienia zakupu",
		"isAlreadyTranslated": true
	},
	"National ID": {
		"text": "Krajowy numer identyfikacyjny",
		"isAlreadyTranslated": true
	},
	"Last Repair Date": {
		"text": "Data ostatniej naprawy",
		"isAlreadyTranslated": true
	},
	"Product Unique ID": {
		"text": "Unikalny identyfikator produktu",
		"isAlreadyTranslated": true
	},
	"We were unable to find details from GSX, please select product manually": {
		"text": "Nie mogliśmy znaleźć szczegółów z GSX, wybierz produkt ręcznie",
		"isAlreadyTranslated": true
	},
	"No serviceable consumer product found": {
		"text": "Nie znaleziono nadającego się do serwisowania produktu konsumenckiego",
		"isAlreadyTranslated": true
	},
	"Is Warranty Applicable for this request": {
		"text": "Czy gwarancja dotyczy tego zgłoszenia",
		"isAlreadyTranslated": true
	},
	"Where was the device kept at the time of incident": {
		"text": "Gdzie znajdowało się urządzenie w momencie zdarzenia?",
		"isAlreadyTranslated": true
	},
	"min 100 characters": {
		"text": "min 100 znaków",
		"isAlreadyTranslated": true
	},
	"LEFT": {
		"text": "LEWO",
		"isAlreadyTranslated": true
	},
	"Please Enter Minimum 100 Characters": {
		"text": "Wprowadź minimum 100 znaków",
		"isAlreadyTranslated": true
	},
	"Enter Other Issue Description": {
		"text": "Wprowadź inny opis problemu",
		"isAlreadyTranslated": true
	},
	"Send for raising request": {
		"text": "Wyślij do zgłoszenia żądania",
		"isAlreadyTranslated": true
	},
	"Send for raising request?": {
		"text": "Wysłać do zgłoszenia żądania?",
		"isAlreadyTranslated": true
	},
	"Air Conditioner": {
		"text": "Klimatyzator",
		"isAlreadyTranslated": true
	},
	"STORAGE": {
		"text": "PRZECHOWYWANIE",
		"isAlreadyTranslated": true
	},
	"TELECOM SERVICES": {
		"text": "USŁUGI TELEKOMUNIKACYJNE",
		"isAlreadyTranslated": true
	},
	"Trade In Pickup": {
		"text": "Odbiór co wymiany za dopłatą",
		"isAlreadyTranslated": true
	},
	"Vibrator": {
		"text": "Wibrator",
		"isAlreadyTranslated": true
	},
	"Sound": {
		"text": "Dźwięk",
		"isAlreadyTranslated": true
	},
	"Display": {
		"text": "Wyświetlacz",
		"isAlreadyTranslated": true
	},
	"Accessories": {
		"text": "Akcesoria",
		"isAlreadyTranslated": true
	},
	"Physical Damage": {
		"text": "Uszkodzenie fizyczne",
		"isAlreadyTranslated": true
	},
	"System Performance": {
		"text": "Wydajność systemu",
		"isAlreadyTranslated": true
	},
	"Power": {
		"text": "Zasilanie",
		"isAlreadyTranslated": true
	},
	"Built in Camera": {
		"text": "Wbudowany aparat fotograficzny",
		"isAlreadyTranslated": true
	},
	"Data Storage": {
		"text": "Przechowywanie danych",
		"isAlreadyTranslated": true
	},
	"Communication/Networking": {
		"text": "Komunikacja/sieć",
		"isAlreadyTranslated": true
	},
	"Consumer Product already exists with Serial/IMEI number": {
		"text": "Produkt konsumencki już istnieje z numerem seryjnym/IMEI",
		"isAlreadyTranslated": true
	},
	"Oops. We don’t service this device at this location. But we’re hard at work and you should get some good news soon. Try later?": {
		"text": "Ups. Nie serwisujemy tego urządzenia w tej lokalizacji. Ale ciężko pracujemy i pewnie wkrótce otrzymasz dobre wieści. Spróbować później?",
		"isAlreadyTranslated": true
	},
	"Enter Issue Decsription": {
		"text": "Wprowadź opis problemu",
		"isAlreadyTranslated": true
	},
	"office": {
		"text": "biuro",
		"isAlreadyTranslated": true
	},
	"Enter a location": {
		"text": "Wprowadź lokalizację",
		"isAlreadyTranslated": true
	},
	"Accept": {
		"text": "Zaakceptuj",
		"isAlreadyTranslated": true
	},
	"Reject": {
		"text": "Odrzuć",
		"isAlreadyTranslated": true
	},
	"Warranty Concession": {
		"text": "Umowa gwarancyjna",
		"isAlreadyTranslated": true
	},
	"No order lines found for given request": {
		"text": "Nie znaleziono pozycji zamówienia dla danego zgłoszenia",
		"isAlreadyTranslated": true
	},
	"Toggle Warranty": {
		"text": "Przełącz gwarancję",
		"isAlreadyTranslated": true
	},
	"Provide link for customer": {
		"text": "Podaj łącze dla klienta",
		"isAlreadyTranslated": true
	},
	"Accept or Reject Request": {
		"text": "Zaakceptuj lub odrzuć zgłoszenie",
		"isAlreadyTranslated": true
	},
	"Inward": {
		"text": "Wewnętrzny",
		"isAlreadyTranslated": true
	},
	"Outward": {
		"text": "Zewnętrzny",
		"isAlreadyTranslated": true
	},
	"Max number of retry attempts exceeded": {
		"text": "Przekroczono maksymalną liczbę ponownych prób",
		"isAlreadyTranslated": true
	},
	"Please Enter Incident Place of Damage": {
		"text": "Wprowadź miejsce zdarzenia uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Please enter the link": {
		"text": "Wprowadź łącze",
		"isAlreadyTranslated": true
	},
	"Please Select Device Receiving Time": {
		"text": "Wybierz czas odbioru urządzenia",
		"isAlreadyTranslated": true
	},
	"Protection Status": {
		"text": "Stan ochrony",
		"isAlreadyTranslated": true
	},
	"Config Description": {
		"text": "Opis konfiguracji",
		"isAlreadyTranslated": true
	},
	"Please specify the reason...": {
		"text": "Określ powód...",
		"isAlreadyTranslated": true
	},
	"An error has occurred. Please contact support@servify.in for help.": {
		"text": "Wystąpił błąd. Skontaktuj się z support@servify.in, aby uzyskać pomoc.",
		"isAlreadyTranslated": true
	},
	"The parts will be returned to Store": {
		"text": "Części zostaną zwrócone do magazynu",
		"isAlreadyTranslated": true
	},
	"Select a Part Name": {
		"text": "Wybierz nazwę części",
		"isAlreadyTranslated": true
	},
	"Restore from Backup History": {
		"text": "Przywróć z historii kopii zapasowej",
		"isAlreadyTranslated": true
	},
	"PASSED": {
		"text": "ZALICZONO",
		"isAlreadyTranslated": true
	},
	"Backup History": {
		"text": "Historia kopii zapasowych",
		"isAlreadyTranslated": true
	},
	"WARNING : This device has not been backed up in the last two weeks.": {
		"text": "OSTRZEŻENIE: W ciągu ostatnich dwóch tygodni nie utworzono kopii zapasowej tego urządzenia.",
		"isAlreadyTranslated": true
	},
	"Sensors": {
		"text": "Czujniki",
		"isAlreadyTranslated": true
	},
	"Temperature History": {
		"text": "Historia temperatury",
		"isAlreadyTranslated": true
	},
	"Accelerometer": {
		"text": "Akcelerometr",
		"isAlreadyTranslated": true
	},
	"Accelerometer Sensor Test": {
		"text": "Test czujnika akcelerometru",
		"isAlreadyTranslated": true
	},
	"ALS": {
		"text": "Czujnik światła otoczenia",
		"isAlreadyTranslated": true
	},
	"Ambient Light Sensor Test": {
		"text": "Test czujnika światła otoczenia",
		"isAlreadyTranslated": true
	},
	"Bluetooth": {
		"text": "Bluetooth",
		"isAlreadyTranslated": true
	},
	"Bluetooth Scan Test": {
		"text": "Test skanowania Bluetooth",
		"isAlreadyTranslated": true
	},
	"Camera": {
		"text": "Aparat",
		"isAlreadyTranslated": true
	},
	"Camera Sensor Test": {
		"text": "Test czujnika aparatu",
		"isAlreadyTranslated": true
	},
	"Gyro": {
		"text": "Żyroskop",
		"isAlreadyTranslated": true
	},
	"Gyro Sensor Test": {
		"text": "Test czujnika żyroskopowego",
		"isAlreadyTranslated": true
	},
	"Touch ID": {
		"text": "Identyfikator dotykowy",
		"isAlreadyTranslated": true
	},
	"Presence Test": {
		"text": "Test obecności",
		"isAlreadyTranslated": true
	},
	"Wi-Fi": {
		"text": "Wi-Fi",
		"isAlreadyTranslated": true
	},
	"Software": {
		"text": "Oprogramowanie",
		"isAlreadyTranslated": true
	},
	"Version Test": {
		"text": "Test wersji",
		"isAlreadyTranslated": true
	},
	"System": {
		"text": "System",
		"isAlreadyTranslated": true
	},
	"Usage Profile": {
		"text": "Profil użytkowania",
		"isAlreadyTranslated": true
	},
	"Battery": {
		"text": "Bateria",
		"isAlreadyTranslated": true
	},
	"Health Check": {
		"text": "Sprawdzanie kondycji",
		"isAlreadyTranslated": true
	},
	"PASSED : Testing completed and no issues were found on this device.": {
		"text": "ZALICZONO: Testowanie zostało zakończone i nie znaleziono żadnych problemów w tym urządzeniu.",
		"isAlreadyTranslated": true
	},
	"App Crash History": {
		"text": "Historia awarii aplikacji",
		"isAlreadyTranslated": true
	},
	"Unexpected Shutdown History": {
		"text": "Historia nieoczekiwanych wyłączeń",
		"isAlreadyTranslated": true
	},
	"Power Button": {
		"text": "Przycisk zasilania",
		"isAlreadyTranslated": true
	},
	"Home Button": {
		"text": "Przycisk startowy",
		"isAlreadyTranslated": true
	},
	"Volume Down Button": {
		"text": "Przycisk zmniejszania głośności",
		"isAlreadyTranslated": true
	},
	"LCD Screen": {
		"text": "Ekran LCD",
		"isAlreadyTranslated": true
	},
	"Ear Piece": {
		"text": "Słuchawka",
		"isAlreadyTranslated": true
	},
	"Back Camera": {
		"text": "Tylny aparat",
		"isAlreadyTranslated": true
	},
	"Network Connection": {
		"text": "Połączenie sieciowe",
		"isAlreadyTranslated": true
	},
	"IMEI Check": {
		"text": "Sprawdzenie IMEI",
		"isAlreadyTranslated": true
	},
	"Incoming call": {
		"text": "Połączenie przychodzące",
		"isAlreadyTranslated": true
	},
	"Microphone": {
		"text": "Mikrofon",
		"isAlreadyTranslated": true
	},
	"USB Connectivity": {
		"text": "Łączność USB",
		"isAlreadyTranslated": true
	},
	"Battery Storage": {
		"text": "Przechowywanie baterii",
		"isAlreadyTranslated": true
	},
	"LED Flash": {
		"text": "Lampa błyskowa LED",
		"isAlreadyTranslated": true
	},
	"TAB Button": {
		"text": "Przycisk TAB",
		"isAlreadyTranslated": true
	},
	"Unlock Button": {
		"text": "Przycisk odblokowania",
		"isAlreadyTranslated": true
	},
	"Full Diagnosis": {
		"text": "Pełna diagnostyka",
		"isAlreadyTranslated": true
	},
	"Proper Assembly": {
		"text": "Prawidłowy montaż",
		"isAlreadyTranslated": true
	},
	"Back Button": {
		"text": "Przycisk powrotu",
		"isAlreadyTranslated": true
	},
	"Volume Up Button": {
		"text": "Przycisk zwiększania głośności",
		"isAlreadyTranslated": true
	},
	"Dead Spots": {
		"text": "Martwe punkty",
		"isAlreadyTranslated": true
	},
	"Charging Port": {
		"text": "Port ładowania",
		"isAlreadyTranslated": true
	},
	"Loud Speaker": {
		"text": "Głośnik",
		"isAlreadyTranslated": true
	},
	"Front Camera": {
		"text": "Aparat przedni",
		"isAlreadyTranslated": true
	},
	"Outgoing call": {
		"text": "Połączenie wychodzące",
		"isAlreadyTranslated": true
	},
	"Software Version": {
		"text": "Wersja oprogramowania",
		"isAlreadyTranslated": true
	},
	"GSX Confirmation Number": {
		"text": "Numer potwierdzenia GSX",
		"isAlreadyTranslated": true
	},
	"Services": {
		"text": "Usługi",
		"isAlreadyTranslated": true
	},
	"Check If Device Box IMEI Matches With Device IMEI": {
		"text": "Sprawdź, czy numer IMEI na opakowaniu urządzenia pasuje do numeru IMEI urządzenia",
		"isAlreadyTranslated": true
	},
	"Check If Device IMEI Matches With Internal IMEI": {
		"text": "Sprawdź, czy numer IMEI urządzenia pasuje do wewnętrznego numeru IMEI",
		"isAlreadyTranslated": true
	},
	"Check If Touch/Display Is Broken": {
		"text": "Sprawdź, czy ekran dotykowy/wyświetlacz nie jest uszkodzony",
		"isAlreadyTranslated": true
	},
	"Check If SIM Tray Is Missing Or Broken": {
		"text": "Sprawdź, czy taca SIM jest brakująca lub uszkodzona",
		"isAlreadyTranslated": true
	},
	"Check If Back Cover Is Damaged": {
		"text": "Sprawdź, czy tylna okładka jest uszkodzona",
		"isAlreadyTranslated": true
	},
	"Check If Screw Heads Are Damaged": {
		"text": "Sprawdź, czy łby śrub są uszkodzone",
		"isAlreadyTranslated": true
	},
	"Check If There Are Dents Or Internal Cracks In Display": {
		"text": "Sprawdź, czy na wyświetlaczu są wgniecenia lub pęknięcia wewnętrzne",
		"isAlreadyTranslated": true
	},
	"Check If Volume Keys Are Loose Or Not Functioning": {
		"text": "Sprawdź, czy klawisze głośności są luźne lub nie działają",
		"isAlreadyTranslated": true
	},
	"Check If Power Keys Are Loose Or Not Functioning": {
		"text": "Sprawdź, czy klawisze zasilania są luźne lub nie działają",
		"isAlreadyTranslated": true
	},
	"Check If Camera's Auto Focus Is Not Working": {
		"text": "Sprawdź, czy autofokus aparatu nie działa",
		"isAlreadyTranslated": true
	},
	"Check If Photos Clicked Are Blurred": {
		"text": "Sprawdź, czy kliknięte zdjęcia są rozmazane",
		"isAlreadyTranslated": true
	},
	"Check If USB Port Is Damaged": {
		"text": "Sprawdź, czy port USB jest uszkodzony",
		"isAlreadyTranslated": true
	},
	"Check If Accessories Are As Mentioned On The Sales Pack": {
		"text": "Sprawdź, czy akcesoria są zgodne z opisem w pakiecie sprzedażowym",
		"isAlreadyTranslated": true
	},
	"Check If There Are Functional Defects": {
		"text": "Sprawdź, czy występują wady funkcjonalne",
		"isAlreadyTranslated": true
	},
	"Ear Pods": {
		"text": "Słuchawki",
		"isAlreadyTranslated": true
	},
	"Power Adapter": {
		"text": "Zasilacz",
		"isAlreadyTranslated": true
	},
	"Lightning to USB Cable": {
		"text": "Kabel ze złącza Lightning do USB",
		"isAlreadyTranslated": true
	},
	"Display Damaged": {
		"text": "Uszkodzenie wyświetlacza",
		"isAlreadyTranslated": true
	},
	"Battery Damaged": {
		"text": "Uszkodzenie baterii",
		"isAlreadyTranslated": true
	},
	"Backup Required": {
		"text": "Wymagana kopia zapasowa",
		"isAlreadyTranslated": true
	},
	"Find my iPhone Active": {
		"text": "Aktywna funkcja Znajdź mój iPhone",
		"isAlreadyTranslated": true
	},
	"Unlock.": {
		"text": "Odblokuj.",
		"isAlreadyTranslated": true
	},
	"Functional Defects": {
		"text": "Wady funkcjonalne",
		"isAlreadyTranslated": true
	},
	"Accessories as per Content printed on sales pack": {
		"text": "Akcesoria zgodnie z zawartością wydrukowaną na opakowaniu sprzedażowym",
		"isAlreadyTranslated": true
	},
	"USB Port Damaged": {
		"text": "Uszkodzony port USB",
		"isAlreadyTranslated": true
	},
	"Camera AF & Blurr Issues": {
		"text": "Problemy z autofokusem i rozmyciem aparatu",
		"isAlreadyTranslated": true
	},
	"Power/Volume Keys Loose or Not Functioning": {
		"text": "Klawisze zasilania/głośności są luźne lub nie działają",
		"isAlreadyTranslated": true
	},
	"Any Dents or Internal Crack in display": {
		"text": "Wszelkie wgniecenia lub wewnętrzne pęknięcia na wyświetlaczu",
		"isAlreadyTranslated": true
	},
	"Screw heads Damaged": {
		"text": "Uszkodzone łby śrub",
		"isAlreadyTranslated": true
	},
	"Back Cover Damaged": {
		"text": "Uszkodzona tylna okładka",
		"isAlreadyTranslated": true
	},
	"SIM Tray Missing or Broken": {
		"text": "Brak lub uszkodzenie tacy SIM",
		"isAlreadyTranslated": true
	},
	"Touch/LCD Broken": {
		"text": "Uszkodzony ekran dotykowy/LCD",
		"isAlreadyTranslated": true
	},
	"Unit IMEI Vs Internal IMEI": {
		"text": "Numer IMEI jednostki a wewnętrzny numer IMEI",
		"isAlreadyTranslated": true
	},
	"Box IMEI Vs Unit IMEI": {
		"text": "Numer IMEI na opakowaniu a numer IMEI jednostki",
		"isAlreadyTranslated": true
	},
	"Please enter alphabets or numbers only": {
		"text": "Wprowadź tylko znaki litery lub cyfry",
		"isAlreadyTranslated": true
	},
	"Please enter numbers only": {
		"text": "Wprowadź tylko cyfry",
		"isAlreadyTranslated": true
	},
	"Consumer Email": {
		"text": "E-mail konsumenta",
		"isAlreadyTranslated": true
	},
	"IMEI Number 1": {
		"text": "Numer IMEI 1",
		"isAlreadyTranslated": true
	},
	"IMEI Number 2": {
		"text": "Numer IMEI 2",
		"isAlreadyTranslated": true
	},
	"Reassign Service": {
		"text": "Ponownie przypisz usługę",
		"isAlreadyTranslated": true
	},
	"Select a service location from the drop down": {
		"text": "Wybierz lokalizację usługi z listy rozwijanej",
		"isAlreadyTranslated": true
	},
	"The same service center and same engineer is already assigned.": {
		"text": "Przypisano już to samo centrum serwisowe i tego samego inżyniera.",
		"isAlreadyTranslated": true
	},
	"Service center and engineer has been reassigned.": {
		"text": "Ponownie przypisano centrum serwisowe i inżyniera.",
		"isAlreadyTranslated": true
	},
	"The same service center is already assigned.": {
		"text": "Przypisano już to samo centrum serwisowe.",
		"isAlreadyTranslated": true
	},
	"Service center has been reassigned.": {
		"text": "Ponownie przypisano centrum serwisowe.",
		"isAlreadyTranslated": true
	},
	"First Dialer Dispostion": {
		"text": "Pierwsza dyspozycja dialera",
		"isAlreadyTranslated": true
	},
	"Second Dialer Dispostion": {
		"text": "Druga dyspozycja dialera",
		"isAlreadyTranslated": true
	},
	"Reschedule Service": {
		"text": "Zmień harmonogram usługi",
		"isAlreadyTranslated": true
	},
	"No slots Available": {
		"text": "Brak dostępnych przedziałów",
		"isAlreadyTranslated": true
	},
	"Reason for reschedule": {
		"text": "Powód zmiany harmonogramu",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to reschedule?": {
		"text": "Czy na pewno chcesz zmienić harmonogram?",
		"isAlreadyTranslated": true
	},
	"The request was rescheduled successfully": {
		"text": "Pomyślnie zmieniono harmonogram zgłoszenia",
		"isAlreadyTranslated": true
	},
	"Please specify the reason for reschedule": {
		"text": "Określ powód zmiany harmonogramu",
		"isAlreadyTranslated": true
	},
	"Please select a slot for reschedule": {
		"text": "Wybierz przedział do zmiany harmonogramu",
		"isAlreadyTranslated": true
	},
	"Service Location": {
		"text": "Lokalizacja serwisu",
		"isAlreadyTranslated": true
	},
	"Device Replacement Mode": {
		"text": "Tryb wymiany urządzenia",
		"isAlreadyTranslated": true
	},
	"Generate DOA Certificate": {
		"text": "Wygeneruj certyfikat DOA",
		"isAlreadyTranslated": true
	},
	"Get New Device": {
		"text": "Uzyskaj nowe urządzenie",
		"isAlreadyTranslated": true
	},
	"Choose Device": {
		"text": "Wybierz urządzenie",
		"isAlreadyTranslated": true
	},
	"Enter Information for the Device you are issuing": {
		"text": "Wprowadź informacje dotyczące urządzenia, które wydajesz",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI 1": {
		"text": "Wprowadź poprawny numer IMEI 1",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI 2": {
		"text": "Wprowadź poprawny numer IMEI 2",
		"isAlreadyTranslated": true
	},
	"Please enter correct Serial Number": {
		"text": "Wprowadź poprawny numer seryjny",
		"isAlreadyTranslated": true
	},
	"Action on Device": {
		"text": "Akcja na urządzeniu",
		"isAlreadyTranslated": true
	},
	"Status Date": {
		"text": "Data stanu",
		"isAlreadyTranslated": true
	},
	"Symptoms reported by Engineer": {
		"text": "Objawy zgłaszane przez Inżyniera",
		"isAlreadyTranslated": true
	},
	"Symptoms": {
		"text": "Objawy",
		"isAlreadyTranslated": true
	},
	"Add New Symptom Codes": {
		"text": "Dodaj nowe kody objawów",
		"isAlreadyTranslated": true
	},
	"No symptoms added": {
		"text": "Nie dodano objawów",
		"isAlreadyTranslated": true
	},
	"Symptom already added by the engineer": {
		"text": "Objaw już dodany przez inżyniera",
		"isAlreadyTranslated": true
	},
	"Please validate all the issues": {
		"text": "Sprawdź ważność wszystkich problemów",
		"isAlreadyTranslated": true
	},
	"Verification Attempts Details": {
		"text": "Szczegóły prób weryfikacji",
		"isAlreadyTranslated": true
	},
	"Number of Attempts Remaining": {
		"text": "Liczba pozostałych prób",
		"isAlreadyTranslated": true
	},
	"Value Used For Verification": {
		"text": "Wartość używana do weryfikacji",
		"isAlreadyTranslated": true
	},
	"Verified": {
		"text": "Zweryfikowano",
		"isAlreadyTranslated": true
	},
	"Not Verified": {
		"text": "Nie zweryfikowano",
		"isAlreadyTranslated": true
	},
	"Verification Not Attempted": {
		"text": "Nie podjęto próby weryfikacji",
		"isAlreadyTranslated": true
	},
	"Request is being processed.": {
		"text": "Zgłoszenie jest przetwarzane.",
		"isAlreadyTranslated": true
	},
	"Hub Details": {
		"text": "Szczegóły centrum",
		"isAlreadyTranslated": true
	},
	"Logistic Partners": {
		"text": "Partnerzy logistyczni",
		"isAlreadyTranslated": true
	},
	"Logistic Partner Name": {
		"text": "Nazwa partnera logistycznego",
		"isAlreadyTranslated": true
	},
	"Recommended": {
		"text": "Polecane",
		"isAlreadyTranslated": true
	},
	"Schedule Request": {
		"text": "Zgłoszenie harmonogramu",
		"isAlreadyTranslated": true
	},
	"Please select a Logistic Partner": {
		"text": "Wybierz partnera logistycznego",
		"isAlreadyTranslated": true
	},
	"SMS Sent Successfully": {
		"text": "Pomyślnie wysłano SMS",
		"isAlreadyTranslated": true
	},
	"Please select a Hub": {
		"text": "Wybierz centrum",
		"isAlreadyTranslated": true
	},
	"Upload Document": {
		"text": "Prześlij dokument",
		"isAlreadyTranslated": true
	},
	"Add Certificate": {
		"text": "Dodaj certyfikat",
		"isAlreadyTranslated": true
	},
	"Vouchers generated successfully": {
		"text": "Pomyślnie wygenerowano talony",
		"isAlreadyTranslated": true
	},
	"Document Type: Cheque": {
		"text": "Typ dokumentu: Czek",
		"isAlreadyTranslated": true
	},
	"Claim Amount": {
		"text": "Kwota roszczenia",
		"isAlreadyTranslated": true
	},
	"Please enter Claim Amount:": {
		"text": "Wprowadź kwotę roszczenia:",
		"isAlreadyTranslated": true
	},
	"Please enter Claim Amount before submitting": {
		"text": "Wprowadź kwotę roszczenia przed przesłaniem",
		"isAlreadyTranslated": true
	},
	"Invoice Value": {
		"text": "Wartość faktury",
		"isAlreadyTranslated": true
	},
	"Repair Cost": {
		"text": "Koszt naprawy",
		"isAlreadyTranslated": true
	},
	"Mandatory Deductible": {
		"text": "Obowiązkowy udział własny",
		"isAlreadyTranslated": true
	},
	"BER Payment Amount": {
		"text": "Kwota płatności BER",
		"isAlreadyTranslated": true
	},
	"Approve BER": {
		"text": "Zatwierdź BER",
		"isAlreadyTranslated": true
	},
	"Please enter Override Amount before submitting": {
		"text": "Wprowadź nadpisaną kwotę przed przesłaniem",
		"isAlreadyTranslated": true
	},
	"Please mention the incident in not less than 100 characters on how, when & where the damage happened": {
		"text": "Opisz incydent, używając nie mniej 100 znaków, podając sposób, czas i miejsce wystąpienia uszkodzenia",
		"isAlreadyTranslated": true
	},
	"Enter Loaner Device Details": {
		"text": "Wprowadź szczegóły wypożyczonego urządzenia",
		"isAlreadyTranslated": true
	},
	"Select IMEI Number": {
		"text": "Wybierz numer IMEI",
		"isAlreadyTranslated": true
	},
	"Receive Loaner Device": {
		"text": "Otrzymaj urządzenie zastępcze",
		"isAlreadyTranslated": true
	},
	"Condition Of the Loaner Device": {
		"text": "Stan urządzenia zastępczego",
		"isAlreadyTranslated": true
	},
	"Good": {
		"text": "Dobry",
		"isAlreadyTranslated": true
	},
	"Bad": {
		"text": "Zły",
		"isAlreadyTranslated": true
	},
	"Ugly": {
		"text": "Brzydki",
		"isAlreadyTranslated": true
	},
	"Loaner Device Details added successfully": {
		"text": "Pomyślnie dodano szczegóły urządzenia zastępczego",
		"isAlreadyTranslated": true
	},
	"Please choose from the given list only.": {
		"text": "Wybierz tylko z podanej listy.",
		"isAlreadyTranslated": true
	},
	"Please tell us about the condition of the Loaner Device.": {
		"text": "Podaj nam stan urządzenia zastępczego.",
		"isAlreadyTranslated": true
	},
	"Please write about the condition of the device.": {
		"text": "Napisz o stanie urządzenia.",
		"isAlreadyTranslated": true
	},
	"From": {
		"text": "Od",
		"isAlreadyTranslated": true
	},
	"To": {
		"text": "Do",
		"isAlreadyTranslated": true
	},
	"Consumer Mobile": {
		"text": "Telefon komórkowy konsumenta",
		"isAlreadyTranslated": true
	},
	"Alternate No./Landline No.": {
		"text": "Inny numer/numer stacjonarny",
		"isAlreadyTranslated": true
	},
	"Customer Alternate Mobile No.": {
		"text": "Inny numer telefonu komórkowego klienta.",
		"isAlreadyTranslated": true
	},
	"Save & Send SMS/Email": {
		"text": "Zapisz i wyślij SMS/e-mail",
		"isAlreadyTranslated": true
	},
	"consumer(s) are shown above.": {
		"text": "konsumenci pokazani powyżej.",
		"isAlreadyTranslated": true
	},
	"Call logs": {
		"text": "Dzienniki połączeń",
		"isAlreadyTranslated": true
	},
	"customer@email.com": {
		"text": "customer@email.com",
		"isAlreadyTranslated": true
	},
	"IMEI / Serial No.": {
		"text": "IMEI / nr seryjny",
		"isAlreadyTranslated": true
	},
	"Servify Diagnostic App": {
		"text": "Aplikacja diagnostyczna Servify",
		"isAlreadyTranslated": true
	},
	"Serial No.": {
		"text": "Nr seryjny",
		"isAlreadyTranslated": true
	},
	"IMEI 2": {
		"text": "IMEI 2",
		"isAlreadyTranslated": true
	},
	"Referece ID": {
		"text": "Identyfikator referencyjny",
		"isAlreadyTranslated": true
	},
	"Claim Form": {
		"text": "Formularz roszczenia",
		"isAlreadyTranslated": true
	},
	"Claim Form Servify": {
		"text": "Formularz roszczenia firmy Servify",
		"isAlreadyTranslated": true
	},
	"Authorization Letter": {
		"text": "List autoryzacyjny",
		"isAlreadyTranslated": true
	},
	"PAN Card Front": {
		"text": "PAN Card Front",
		"isAlreadyTranslated": true
	},
	"Aadhar Card Front": {
		"text": "Aadhar Card Front",
		"isAlreadyTranslated": true
	},
	"Aadhar Card Back": {
		"text": "Aadhar Card Back",
		"isAlreadyTranslated": true
	},
	"Other Documents": {
		"text": "Inne dokumenty",
		"isAlreadyTranslated": true
	},
	"Cheque": {
		"text": "Czek",
		"isAlreadyTranslated": true
	},
	"Video": {
		"text": "Wideo",
		"isAlreadyTranslated": true
	},
	"Device image": {
		"text": "Zdjęcie urządzenia",
		"isAlreadyTranslated": true
	},
	"Destruction Form": {
		"text": "Formularz zniszczenia",
		"isAlreadyTranslated": true
	},
	"P-Slip": {
		"text": "List przewozowy",
		"isAlreadyTranslated": true
	},
	"Consumer Signature": {
		"text": "Podpis konsumenta",
		"isAlreadyTranslated": true
	},
	"Issue Image": {
		"text": "Zdjęcie problemu",
		"isAlreadyTranslated": true
	},
	"Diagnosis report": {
		"text": "Raport diagnostyczny",
		"isAlreadyTranslated": true
	},
	"Delivery Note": {
		"text": "Potwierdzenie dostawy",
		"isAlreadyTranslated": true
	},
	"Product Damage Feedback Item": {
		"text": "Element opinii na temat uszkodzenia produktu",
		"isAlreadyTranslated": true
	},
	"Claim Form in": {
		"text": "Formularz roszczenia w",
		"isAlreadyTranslated": true
	},
	"IMEI No": {
		"text": "Nr IMEI",
		"isAlreadyTranslated": true
	},
	"IssueText": {
		"text": "IssueText",
		"isAlreadyTranslated": true
	},
	"IssueRemark": {
		"text": "IssueRemark",
		"isAlreadyTranslated": true
	},
	"otherIssueText": {
		"text": "otherIssueText",
		"isAlreadyTranslated": true
	},
	"screen": {
		"text": "ekran",
		"isAlreadyTranslated": true
	},
	"Consumer To Service Centre": {
		"text": "Od konsumenta do centrum serwisowego",
		"isAlreadyTranslated": true
	},
	"Service Centre To Consumer": {
		"text": "Od centrum serwisowego do konsumenta",
		"isAlreadyTranslated": true
	},
	"On": {
		"text": "W dniu",
		"isAlreadyTranslated": true
	},
	"at": {
		"text": "o godzinie",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial": {
		"text": "IMEI/seryjny",
		"isAlreadyTranslated": true
	},
	"Insufficient Data For Graph. Data": {
		"text": "Za mało danych do wykresu. Dane",
		"isAlreadyTranslated": true
	},
	"is": {
		"text": "to",
		"isAlreadyTranslated": true
	},
	"Not a valid user.": {
		"text": "Nieprawidłowy użytkownik.",
		"isAlreadyTranslated": true
	},
	"Rejection Reasons and Expected Actions by APR - Summary": {
		"text": "Przyczyny odrzucenia i oczekiwane działania APR — podsumowanie",
		"isAlreadyTranslated": true
	},
	"Error Message on App": {
		"text": "Komunikat o błędzie w aplikacji",
		"isAlreadyTranslated": true
	},
	"Error Code": {
		"text": "Kod błędu",
		"isAlreadyTranslated": true
	},
	"Explanation of Error Code": {
		"text": "Objaśnienie kodu błędu",
		"isAlreadyTranslated": true
	},
	"Expected Action from APR": {
		"text": "Oczekiwane działanie APR",
		"isAlreadyTranslated": true
	},
	"Store Name & Location": {
		"text": "Nazwa i lokalizacja magazynu",
		"isAlreadyTranslated": true
	},
	"Download Activation Report": {
		"text": "Pobierz raport z aktywacji",
		"isAlreadyTranslated": true
	},
	"Filter Consumers": {
		"text": "Filtruj konsumentów",
		"isAlreadyTranslated": true
	},
	"Order ID": {
		"text": "Identyfikator zamówienia",
		"isAlreadyTranslated": true
	},
	"Order Item ID": {
		"text": "Identyfikator elementu zamówienia",
		"isAlreadyTranslated": true
	},
	"User Details": {
		"text": "Dane użytkownika",
		"isAlreadyTranslated": true
	},
	"Plan Purchased": {
		"text": "Zakupiony plan",
		"isAlreadyTranslated": true
	},
	"Activated On": {
		"text": "Aktywowano",
		"isAlreadyTranslated": true
	},
	"Status Message": {
		"text": "Komunikat o stanie",
		"isAlreadyTranslated": true
	},
	"Error Code in": {
		"text": "Kod błędu w",
		"isAlreadyTranslated": true
	},
	"Something went wrong when trying to create a consumer. Please try again.": {
		"text": "Coś poszło nie tak podczas próby utworzenia konsumenta. Spróbuj ponownie.",
		"isAlreadyTranslated": true
	},
	"Consumer successfully added and SMS sent!": {
		"text": "Pomyślnie dodano konsumenta i wysłano SMS!",
		"isAlreadyTranslated": true
	},
	"Please enter correct E-mail ID.": {
		"text": "Wprowadź poprawny adres e-mail.",
		"isAlreadyTranslated": true
	},
	"Please enter correct Mobile Number.": {
		"text": "Wprowadź poprawny numer telefonu komórkowego.",
		"isAlreadyTranslated": true
	},
	"Please enter Mobile Number.": {
		"text": "Wprowadź numer telefonu komórkowego.",
		"isAlreadyTranslated": true
	},
	"Please select a Device Type.": {
		"text": "Wybierz typ urządzenia.",
		"isAlreadyTranslated": true
	},
	"Please enter at least one issue.": {
		"text": "Wprowadź co najmniej jeden problem.",
		"isAlreadyTranslated": true
	},
	"Please enter Incident ID.": {
		"text": "Wprowadź identyfikator incydentu.",
		"isAlreadyTranslated": true
	},
	"Please select a model from the dropdown.": {
		"text": "Wybierz model z listy rozwijanej.",
		"isAlreadyTranslated": true
	},
	"Please enter E-mail ID.": {
		"text": "Wprowadź poprawny adres e-mail.",
		"isAlreadyTranslated": true
	},
	"Please enter Customer's Name.": {
		"text": "Wprowadź nazwę klienta.",
		"isAlreadyTranslated": true
	},
	"Doc": {
		"text": "Doc",
		"isAlreadyTranslated": true
	},
	"Allowed Repairs": {
		"text": "Dozwolone naprawy",
		"isAlreadyTranslated": true
	},
	"Used Repairs": {
		"text": "Użyte naprawy",
		"isAlreadyTranslated": true
	},
	"Tax Payable": {
		"text": "Należny podatek",
		"isAlreadyTranslated": true
	},
	"View queued requestsat": {
		"text": "Wyświetl statystykę zgłoszeń w kolejce",
		"isAlreadyTranslated": true
	},
	"Generate Vouchers": {
		"text": "Generuj talony",
		"isAlreadyTranslated": true
	},
	"Customer's Address Details": {
		"text": "Dane adresowe klienta",
		"isAlreadyTranslated": true
	},
	"View queued requests": {
		"text": "Wyświetl zgłoszenia w kolejce",
		"isAlreadyTranslated": true
	},
	"Engineer Notification": {
		"text": "Powiadomienie inżyniera",
		"isAlreadyTranslated": true
	},
	"Engineer Notifications": {
		"text": "Powiadomienia inżyniera",
		"isAlreadyTranslated": true
	},
	"Optin Requests": {
		"text": "Zgłoszenia zgody",
		"isAlreadyTranslated": true
	},
	"Plan Sales Report": {
		"text": "Zaplanuj raport sprzedaży",
		"isAlreadyTranslated": true
	},
	"APR Plans": {
		"text": "Plany APR",
		"isAlreadyTranslated": true
	},
	"Engineer Login Report": {
		"text": "Raport logowania inżyniera",
		"isAlreadyTranslated": true
	},
	"Service location Report": {
		"text": "Raport lokalizacji usług",
		"isAlreadyTranslated": true
	},
	"Mapping": {
		"text": "Odwzorowanie",
		"isAlreadyTranslated": true
	},
	"Reports": {
		"text": "Raporty",
		"isAlreadyTranslated": true
	},
	"billing": {
		"text": "fakturowanie",
		"isAlreadyTranslated": true
	},
	"Engineer Tracking": {
		"text": "Śledzenie inżyniera",
		"isAlreadyTranslated": true
	},
	"Service Requests": {
		"text": "Zgłoszenia usług",
		"isAlreadyTranslated": true
	},
	"Engineer Report": {
		"text": "Raport inżyniera",
		"isAlreadyTranslated": true
	},
	"Visit Report": {
		"text": "Raport odwiedzin",
		"isAlreadyTranslated": true
	},
	"Billing": {
		"text": "Fakturowanie",
		"isAlreadyTranslated": true
	},
	"Upload document": {
		"text": "Prześlij dokument",
		"isAlreadyTranslated": true
	},
	"View Documents": {
		"text": "Wyświetl dokumenty",
		"isAlreadyTranslated": true
	},
	"File Name": {
		"text": "Nazwa pliku",
		"isAlreadyTranslated": true
	},
	"Uploaded By": {
		"text": "Przekazano przez",
		"isAlreadyTranslated": true
	},
	"Uploaded Date & Time": {
		"text": "Data i godzina przekazania",
		"isAlreadyTranslated": true
	},
	"Wall Mount Or 60 Inch Or Large?": {
		"text": "Montaż ścienny, 60-calowy lub duży?",
		"isAlreadyTranslated": true
	},
	"Logistics - From Service Center to Customer's Address to Service Center": {
		"text": "Logistyka — od centrum serwisowego przez adres klienta do centrum serwisowego",
		"isAlreadyTranslated": true
	},
	"Tier": {
		"text": "Warstwa",
		"isAlreadyTranslated": true
	},
	"Expected Delivery": {
		"text": "Oczekiwana dostawa",
		"isAlreadyTranslated": true
	},
	"Reason for Reschedule": {
		"text": "Powód zmiany harmonogramu",
		"isAlreadyTranslated": true
	},
	"View Job Card": {
		"text": "Wyświetl kartę pracy",
		"isAlreadyTranslated": true
	},
	"Begin Journey": {
		"text": "Rozpocznij podróż",
		"isAlreadyTranslated": true
	},
	"Serviceman Reached": {
		"text": "Osiągnięto serwisanta",
		"isAlreadyTranslated": true
	},
	"Initiate Exception": {
		"text": "Zainicjuj wyjątek",
		"isAlreadyTranslated": true
	},
	"Complete Refund": {
		"text": "Zakończ zwrot pieniędzy",
		"isAlreadyTranslated": true
	},
	"View Exception": {
		"text": "Wyświetl wyjątek",
		"isAlreadyTranslated": true
	},
	"Visit Details": {
		"text": "Szczegóły wizyty",
		"isAlreadyTranslated": true
	},
	"Tasks": {
		"text": "Zadania",
		"isAlreadyTranslated": true
	},
	"Diagnosis Report": {
		"text": "Raport diagnostyczny",
		"isAlreadyTranslated": true
	},
	"Please choose the product": {
		"text": "Wybierz produkt",
		"isAlreadyTranslated": true
	},
	"Billing Address": {
		"text": "Adres rozliczeniowy",
		"isAlreadyTranslated": true
	},
	"Change Billing Address": {
		"text": "Zmień adres rozliczeniowy",
		"isAlreadyTranslated": true
	},
	"Device Received & Inspected": {
		"text": "Urządzenie otrzymane i sprawdzone",
		"isAlreadyTranslated": true
	},
	"Complete Inspection": {
		"text": "Zakończ inspekcję",
		"isAlreadyTranslated": true
	},
	"Complete Device handover": {
		"text": "Zakończ przekazanie urządzenia",
		"isAlreadyTranslated": true
	},
	"Create shipment": {
		"text": "Utwórz przesyłkę",
		"isAlreadyTranslated": true
	},
	"Waiting for the Refurbishment Hub Manager to receive device and complete mandatory checklist tasks.": {
		"text": "Oczekiwanie na otrzymanie urządzenia przez menedżera centrum renowacji i zakończenie obowiązkowych zadań z listy kontrolnej.",
		"isAlreadyTranslated": true
	},
	"No of Repairs Used": {
		"text": "Liczba użytych napraw",
		"isAlreadyTranslated": true
	},
	"No of Repairs Allowed": {
		"text": "Liczba dozwolonych napraw",
		"isAlreadyTranslated": true
	},
	"Customer Care notes": {
		"text": "Uwago obsługi klienta",
		"isAlreadyTranslated": true
	},
	"Call Type:": {
		"text": "Typ połączenia:",
		"isAlreadyTranslated": true
	},
	"Call Disposition": {
		"text": "Dyspozycja połączenia",
		"isAlreadyTranslated": true
	},
	"Enter Amount": {
		"text": "Wprowadź kwotę",
		"isAlreadyTranslated": true
	},
	"Map": {
		"text": "Odwzoruj",
		"isAlreadyTranslated": true
	},
	"Number": {
		"text": "Numer",
		"isAlreadyTranslated": true
	},
	"G Number": {
		"text": "Numer G",
		"isAlreadyTranslated": true
	},
	"Turn off Find my iPhone": {
		"text": "Wyłącz funkcję Znajdź mój iPhone",
		"isAlreadyTranslated": true
	},
	"Add required documents in 'Request Documents' section below.": {
		"text": "Dodaj wymagane dokumenty w poniższej sekcji „Dokumenty zgłoszenia”.",
		"isAlreadyTranslated": true
	},
	"Sub Category": {
		"text": "Podkategoria",
		"isAlreadyTranslated": true
	},
	"Generate Estimate": {
		"text": "Generuj oszacowanie",
		"isAlreadyTranslated": true
	},
	"Purchase Order No": {
		"text": "Nr zamówienia zakupu",
		"isAlreadyTranslated": true
	},
	"Enter Information for the parts you have received": {
		"text": "Wprowadź informacje dotyczące otrzymanych części",
		"isAlreadyTranslated": true
	},
	"Enter comptia for DOA parts": {
		"text": "Enter comptia for DOA parts",
		"isAlreadyTranslated": true
	},
	"Waiting for device to be received.": {
		"text": "Oczekiwanie na otrzymanie urządzenia.",
		"isAlreadyTranslated": true
	},
	"Repair Complete": {
		"text": "Naprawa zakończona",
		"isAlreadyTranslated": true
	},
	"Cannibalize Device": {
		"text": "Kanibalizuj urządzenie",
		"isAlreadyTranslated": true
	},
	"test": {
		"text": "test",
		"isAlreadyTranslated": true
	},
	"Part Charge": {
		"text": "Opłata częściowa",
		"isAlreadyTranslated": true
	},
	"Approved for repair": {
		"text": "Zatwierdzono do naprawy",
		"isAlreadyTranslated": true
	},
	"Waiting for store to issue required parts in 'Parts Requested' section below. Cannot complete repair with parts pending.": {
		"text": "Oczekiwanie na wydanie z magazynu części żądanych w poniższej sekcji „Żądane części”. Nie można zakończyć naprawy z oczekiwanymi częściami.",
		"isAlreadyTranslated": true
	},
	"pending": {
		"text": "oczekiwanie",
		"isAlreadyTranslated": true
	},
	"Entered details do not match with selected serial number": {
		"text": "Wprowadzone szczegóły nie pasują do wybranego numeru seryjnego",
		"isAlreadyTranslated": true
	},
	"BER Accessories Received": {
		"text": "Otrzymano akcesoria BER",
		"isAlreadyTranslated": true
	},
	"BER Device Received": {
		"text": "Otrzymano urządzenie BER",
		"isAlreadyTranslated": true
	},
	"Logistics - From Service Center to Hub": {
		"text": "Logistyka — od centrum serwisowego do centrum",
		"isAlreadyTranslated": true
	},
	"Initiate BER Payment": {
		"text": "Zainicjuj płatność BER",
		"isAlreadyTranslated": true
	},
	"BER Payment Complete": {
		"text": "Zakończenie płatności BER",
		"isAlreadyTranslated": true
	},
	"Complete Process": {
		"text": "Zakończ proces",
		"isAlreadyTranslated": true
	},
	"Show Count": {
		"text": "Pokaż liczbę",
		"isAlreadyTranslated": true
	},
	"Search": {
		"text": "Wyszukaj",
		"isAlreadyTranslated": true
	},
	"Escalation Type": {
		"text": "Typ eskalacji",
		"isAlreadyTranslated": true
	},
	"Flag Requests": {
		"text": "Oznacz zgłoszenia",
		"isAlreadyTranslated": true
	},
	"Save this": {
		"text": "Zapisz to",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial No": {
		"text": "Nr IMEI/seryjny",
		"isAlreadyTranslated": true
	},
	"Hide Count": {
		"text": "Ukryj liczbę",
		"isAlreadyTranslated": true
	},
	"Enter Activation Token": {
		"text": "Wprowadź token aktywacyjny",
		"isAlreadyTranslated": true
	},
	"Activation Token": {
		"text": "Token aktywacyjny",
		"isAlreadyTranslated": true
	},
	"Download all reports": {
		"text": "Pobierz wszystkie raporty",
		"isAlreadyTranslated": true
	},
	"BER Repair": {
		"text": "Naprawa BER",
		"isAlreadyTranslated": true
	},
	"Exception raised": {
		"text": "Zgłoszono wyjątek",
		"isAlreadyTranslated": true
	},
	"Billing Details": {
		"text": "Szczegóły rozliczeniowe",
		"isAlreadyTranslated": true
	},
	"Continue": {
		"text": "Kontynuuj",
		"isAlreadyTranslated": true
	},
	"Scan the QR code from Diagnosis App": {
		"text": "Zeskanuj kod QR z aplikacji diagnostycznej",
		"isAlreadyTranslated": true
	},
	"Something went wrong. Your request may have timed out.": {
		"text": "Coś poszło nie tak. Twoje zgłoszenie mogło wygasnąć.",
		"isAlreadyTranslated": true
	},
	"Exclusive Brand is created successfully": {
		"text": "Pomyślnie utworzono markę ekskluzywną",
		"isAlreadyTranslated": true
	},
	"Form no": {
		"text": "Nr formularza",
		"isAlreadyTranslated": true
	},
	"File provided is not in required format": {
		"text": "Dostarczony plik nie ma wymaganego formatu",
		"isAlreadyTranslated": true
	},
	"Invalid format": {
		"text": "Nieprawidłowy format",
		"isAlreadyTranslated": true
	},
	"Invalid name for Product": {
		"text": "Nieprawidłowa nazwa produktu",
		"isAlreadyTranslated": true
	},
	"More than 2 fields supplied": {
		"text": "Podano więcej niż 2 pola",
		"isAlreadyTranslated": true
	},
	"Please upload only .csv file.": {
		"text": "Przekaż tylko plik CSV.",
		"isAlreadyTranslated": true
	},
	"Cannot read file": {
		"text": "Nie można odczytać pliku",
		"isAlreadyTranslated": true
	},
	"Invalid IMEI1 for Product": {
		"text": "Nieprawidłowy numer IMEI1 dla produktu",
		"isAlreadyTranslated": true
	},
	"Invalid IMEI2 for Product": {
		"text": "Nieprawidłowy numer IMEI2 dla produktu",
		"isAlreadyTranslated": true
	},
	"More than 9 fields supplied": {
		"text": "Podano więcej niż 9 pola",
		"isAlreadyTranslated": true
	},
	"No file found": {
		"text": "Nie znaleziono pliku",
		"isAlreadyTranslated": true
	},
	"Name of the Agent": {
		"text": "Nazwa agenta",
		"isAlreadyTranslated": true
	},
	"Please Select a Product": {
		"text": "Wybierz produkt",
		"isAlreadyTranslated": true
	},
	"Select product from dropdown": {
		"text": "Wybierz produkt z listy rozwijanej",
		"isAlreadyTranslated": true
	},
	"Select subcategory from dropdown": {
		"text": "Wybierz podkategorię z listy rozwijanej",
		"isAlreadyTranslated": true
	},
	"Select brand from dropdown": {
		"text": "Wybierz markę z listy rozwijanej",
		"isAlreadyTranslated": true
	},
	"Coupon Code": {
		"text": "Kod kuponu",
		"isAlreadyTranslated": true
	},
	"Start date of the coupon": {
		"text": "Data początkowa kuponu",
		"isAlreadyTranslated": true
	},
	"End date of the coupon": {
		"text": "Data końcowa kuponu",
		"isAlreadyTranslated": true
	},
	"Coupon Type": {
		"text": "Typ kuponu",
		"isAlreadyTranslated": true
	},
	"Active coupon": {
		"text": "Aktywny kupon",
		"isAlreadyTranslated": true
	},
	"Create Coupon": {
		"text": "Utwórz kupon",
		"isAlreadyTranslated": true
	},
	"Applicable only after sign up": {
		"text": "Obowiązuje tylko po rejestracji",
		"isAlreadyTranslated": true
	},
	"Incentive Type": {
		"text": "Typ zachęty",
		"isAlreadyTranslated": true
	},
	"Select incentive type after selecting coupon type": {
		"text": "Wybierz typ zachęty po wybraniu typu kuponu",
		"isAlreadyTranslated": true
	},
	"No. Of Users": {
		"text": "Liczba użytkowników",
		"isAlreadyTranslated": true
	},
	"Incentive Value": {
		"text": "Wartość zachęty",
		"isAlreadyTranslated": true
	},
	"End date": {
		"text": "Data końcowa",
		"isAlreadyTranslated": true
	},
	"Start date": {
		"text": "Data początkowa",
		"isAlreadyTranslated": true
	},
	"Min Amount": {
		"text": "Minimalna kwota",
		"isAlreadyTranslated": true
	},
	"Please fill out this field": {
		"text": "Wypełnij to pole",
		"isAlreadyTranslated": true
	},
	"Please fill value greater than 0": {
		"text": "Wprowadź wartość większą niż 0",
		"isAlreadyTranslated": true
	},
	"End Date cannot be less than start date": {
		"text": "Data końcowa nie może być wcześniejsza niż data początkowa",
		"isAlreadyTranslated": true
	},
	"Incentive value cannot exceed": {
		"text": "Wartość zachęty nie może przekroczyć",
		"isAlreadyTranslated": true
	},
	"Please enter serial number": {
		"text": "Wprowadź numer seryjny",
		"isAlreadyTranslated": true
	},
	"Please enter brand name": {
		"text": "Wprowadź nazwę marki",
		"isAlreadyTranslated": true
	},
	"Please enter product name": {
		"text": "Wprowadź nazwę produktu",
		"isAlreadyTranslated": true
	},
	"Customer Email": {
		"text": "Adres e-mail klienta",
		"isAlreadyTranslated": true
	},
	"Initiate App Diagnostic": {
		"text": "Zainicjuj diagnostykę aplikacji",
		"isAlreadyTranslated": true
	},
	"Reliability Records": {
		"text": "Rekordy niezawodności",
		"isAlreadyTranslated": true
	},
	"Drivers list": {
		"text": "Lista sterowników",
		"isAlreadyTranslated": true
	},
	"User Feedback": {
		"text": "Opinia użytkownika",
		"isAlreadyTranslated": true
	},
	"Perform Diagnosis": {
		"text": "Przeprowadź diagnostykę",
		"isAlreadyTranslated": true
	},
	"Service Completion Date": {
		"text": "Data zakończenia usługi",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching templates": {
		"text": "Coś poszło nie tak podczas pobierania szablonów",
		"isAlreadyTranslated": true
	},
	"Template": {
		"text": "Szablon",
		"isAlreadyTranslated": true
	},
	"Subject": {
		"text": "Temat",
		"isAlreadyTranslated": true
	},
	"Apply a filter before sending an email": {
		"text": "Zastosuj filtr przed wysłaniem wiadomości e-mail",
		"isAlreadyTranslated": true
	},
	"Select a predefined template": {
		"text": "Wybierz predefiniowany szablon",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching data for graph": {
		"text": "Coś poszło nie tak podczas pobierania danych do wykresu",
		"isAlreadyTranslated": true
	},
	"Please enter a valid IMEI": {
		"text": "Wprowadź prawidłowy numer IMEI",
		"isAlreadyTranslated": true
	},
	"Thank You For Choosing Servify": {
		"text": "Dziękujemy za wybranie Servify",
		"isAlreadyTranslated": true
	},
	"Product Inward Date": {
		"text": "Data wprowadzenia produktu",
		"isAlreadyTranslated": true
	},
	"Expected Delivery Date": {
		"text": "Oczekiwana data dostawy",
		"isAlreadyTranslated": true
	},
	"Service Provider's Address": {
		"text": "Adres usługodawcy",
		"isAlreadyTranslated": true
	},
	"Problem Reported by Customer": {
		"text": "Problem zgłoszony przez klienta",
		"isAlreadyTranslated": true
	},
	"Note: This Equipment is accepted subject to terms and conditions mentioned overleaf.": {
		"text": "Uwaga: Ten sprzęt jest akceptowany zgodnie z warunkami określonymi na odwrocie.",
		"isAlreadyTranslated": true
	},
	"Handed to customer": {
		"text": "Wręczono klientowi",
		"isAlreadyTranslated": true
	},
	"Upload Status Report": {
		"text": "Przekaż raport o stanie",
		"isAlreadyTranslated": true
	},
	"Download File": {
		"text": "Pobierz plik",
		"isAlreadyTranslated": true
	},
	"Upload File": {
		"text": "Przekaż plik",
		"isAlreadyTranslated": true
	},
	"Submit File": {
		"text": "Prześlij plik",
		"isAlreadyTranslated": true
	},
	"Something went wrong. Please upload again": {
		"text": "Coś poszło nie tak. Przekaż ponownie",
		"isAlreadyTranslated": true
	},
	"Invalid column headers. You can use sample file": {
		"text": "Nieprawidłowe nagłówki kolumn. Możesz użyć przykładowego pliku",
		"isAlreadyTranslated": true
	},
	"Please upload a valid file. You can use sample file": {
		"text": "Przekaż prawidłowy plik. Możesz użyć przykładowego pliku",
		"isAlreadyTranslated": true
	},
	"There are no requests to download": {
		"text": "Nie ma zgłoszeń do pobrania",
		"isAlreadyTranslated": true
	},
	"Map Logistics Request": {
		"text": "Odwzoruj zgłoszenie logistyczne",
		"isAlreadyTranslated": true
	},
	"Your logistics request has been successfully mapped to service request": {
		"text": "Twoje zgłoszenie logistyczne zostało pomyślnie odwzorowane na zgłoszenie serwisowe",
		"isAlreadyTranslated": true
	},
	"Pickup Name": {
		"text": "Nazwa miejsca odbioru",
		"isAlreadyTranslated": true
	},
	"Pickup Date": {
		"text": "Data odbioru",
		"isAlreadyTranslated": true
	},
	"Pickup Time": {
		"text": "Godzina odbioru",
		"isAlreadyTranslated": true
	},
	"Pickup Contact No": {
		"text": "Numer kontaktowy miejsca odbioru",
		"isAlreadyTranslated": true
	},
	"Drop Name": {
		"text": "Nazwa nadawcy",
		"isAlreadyTranslated": true
	},
	"Drop Address": {
		"text": "Adres miejsca nadania",
		"isAlreadyTranslated": true
	},
	"Drop Contact No": {
		"text": "Nr kontaktowy miejsca nadania",
		"isAlreadyTranslated": true
	},
	"Product Price": {
		"text": "Cena produktu",
		"isAlreadyTranslated": true
	},
	"Brand Name": {
		"text": "Nazwa marki",
		"isAlreadyTranslated": true
	},
	"Mirror Test": {
		"text": "Test lustrzany",
		"isAlreadyTranslated": true
	},
	"Age of device:": {
		"text": "Wiek urządzenia:",
		"isAlreadyTranslated": true
	},
	"optin requests are shown above": {
		"text": "zgłoszenia zgody są pokazane powyżej",
		"isAlreadyTranslated": true
	},
	"This device is not eligible for DOA": {
		"text": "To urządzenie nie kwalifikuje się do DOA",
		"isAlreadyTranslated": true
	},
	"This device is eligible for DOA": {
		"text": "To urządzenie kwalifikuje się do DOA",
		"isAlreadyTranslated": true
	},
	"Do you want to return the defective parts to the customer?": {
		"text": "Czy chcesz zwrócić wadliwe części do klienta?",
		"isAlreadyTranslated": true
	},
	"Please enter the remarks with at least 25 characters": {
		"text": "Wprowadź uwagi składające się z co najmniej 25 znaków",
		"isAlreadyTranslated": true
	},
	"Please enter the remarks": {
		"text": "Wprowadź uwagi",
		"isAlreadyTranslated": true
	},
	"Do you want to return the damaged parts to the customer": {
		"text": "Czy chcesz zwrócić uszkodzone części do klienta",
		"isAlreadyTranslated": true
	},
	"Enter Order ID": {
		"text": "Wprowadź identyfikator zamówienia",
		"isAlreadyTranslated": true
	},
	'Device warranty details could not be fetched from GSX currently. Click on "Continue" to proceed with selecting the device details or Click on "Retry" to re-attempt fetching warranty details': {
		"text": "Obecnie nie można pobrać szczegółów gwarancji urządzenia z GSX. Kliknij opcję „Kontynuuj”, aby kontynuować wybieranie szczegółów urządzenia, lub „Ponów”, aby ponownie spróbować pobrać szczegóły gwarancji",
		"isAlreadyTranslated": true
	},
	"Exceptional Sales": {
		"text": "Nadzwyczajna sprzedaż",
		"isAlreadyTranslated": true
	},
	"Device not eligible": {
		"text": "Urządzenie nie kwalifikuje się",
		"isAlreadyTranslated": true
	},
	"Apply a filter before sending a SMS": {
		"text": "Zastosuj filtr przed wysłaniem wiadomości SMS",
		"isAlreadyTranslated": true
	},
	"Update New Phone's Serial Number": {
		"text": "Zaktualizuj numer seryjny nowego telefonu",
		"isAlreadyTranslated": true
	},
	"Old Phone's IMEI / Serial Number": {
		"text": "Numer IMEI / numer seryjny starego telefonu",
		"isAlreadyTranslated": true
	},
	"Invite Service User": {
		"text": "Zaproś użytkownika serwisu",
		"isAlreadyTranslated": true
	},
	"Invite Retailer User": {
		"text": "Zaproś użytkownika sprzedawcy",
		"isAlreadyTranslated": true
	},
	"No users found": {
		"text": "Nie znaleziono użytkowników",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for overriding": {
		"text": "Wypełnij powód zastąpienia",
		"isAlreadyTranslated": true
	},
	"File size should not be greater than 10 MB": {
		"text": "Rozmiar pliku nie powinien być większy niż 10 MB",
		"isAlreadyTranslated": true
	},
	"Consumer Details is saved successfully": {
		"text": "Dane klienta zostały pomyślnie zapisane",
		"isAlreadyTranslated": true
	},
	"Select a Vendor": {
		"text": "Wybierz dostawcę",
		"isAlreadyTranslated": true
	},
	"Selected Vendor": {
		"text": "Wybrany dostawca",
		"isAlreadyTranslated": true
	},
	"Work Order ID": {
		"text": "Identyfikator zlecenia pracy",
		"isAlreadyTranslated": true
	},
	"Eligible for RVP": {
		"text": "Kwalifikuje się do RVP",
		"isAlreadyTranslated": true
	},
	"Make the exclusive brand visible to customers": {
		"text": "Spraw, aby ekskluzywna marka była widoczna dla klientów",
		"isAlreadyTranslated": true
	},
	"Reset": {
		"text": "Resetowanie",
		"isAlreadyTranslated": true
	},
	"First Name": {
		"text": "Imię",
		"isAlreadyTranslated": true
	},
	"Last Name": {
		"text": "Nazwisko",
		"isAlreadyTranslated": true
	},
	"Alternate number": {
		"text": "Inny numer",
		"isAlreadyTranslated": true
	},
	"Order date": {
		"text": "Data zamówienia",
		"isAlreadyTranslated": true
	},
	"Address Line": {
		"text": "Wiersz adresu",
		"isAlreadyTranslated": true
	},
	"City": {
		"text": "Miasto",
		"isAlreadyTranslated": true
	},
	"State": {
		"text": "Stan",
		"isAlreadyTranslated": true
	},
	"APR Name": {
		"text": "Nazwa APR",
		"isAlreadyTranslated": true
	},
	"Apply Filters": {
		"text": "Zastosuj filtry",
		"isAlreadyTranslated": true
	},
	"Reason For Reassignment": {
		"text": "Powód ponownego przypisania",
		"isAlreadyTranslated": true
	},
	"Reason For Reassign": {
		"text": "Powód ponownego przypisania",
		"isAlreadyTranslated": true
	},
	"No data Found": {
		"text": "Nie znaleziono danych",
		"isAlreadyTranslated": true
	},
	"Total Credit Amount": {
		"text": "Całkowita kwota kredytu",
		"isAlreadyTranslated": true
	},
	"Incentive/Penalty": {
		"text": "Zachęta/kara",
		"isAlreadyTranslated": true
	},
	"Taxable amount": {
		"text": "Kwota podlegająca opodatkowaniu",
		"isAlreadyTranslated": true
	},
	"Total Amount Payable": {
		"text": "Całkowita kwota do zapłaty",
		"isAlreadyTranslated": true
	},
	"Bulk Approve": {
		"text": "Zatwierdzanie zbiorcze",
		"isAlreadyTranslated": true
	},
	"Add additional Penalties": {
		"text": "Dodaj dodatkowe kary",
		"isAlreadyTranslated": true
	},
	"Description": {
		"text": "Opis",
		"isAlreadyTranslated": true
	},
	"Character Limit": {
		"text": "Limit znaków",
		"isAlreadyTranslated": true
	},
	"Pending Approval": {
		"text": "Oczekiwanie na zatwierdzenie",
		"isAlreadyTranslated": true
	},
	"Rejected Remark": {
		"text": "Odrzucona uwaga",
		"isAlreadyTranslated": true
	},
	"SELECT PRODUCT": {
		"text": "WYBIERZ PRODUKT",
		"isAlreadyTranslated": true
	},
	"Upload": {
		"text": "Przekaż",
		"isAlreadyTranslated": true
	},
	"Total Unregistered Products": {
		"text": "Suma niezarejestrowanych produktów",
		"isAlreadyTranslated": true
	},
	"Change password": {
		"text": "Zmień hasło",
		"isAlreadyTranslated": true
	},
	"Password": {
		"text": "Hasło",
		"isAlreadyTranslated": true
	},
	"Confirm Password": {
		"text": "Potwierdź hasło",
		"isAlreadyTranslated": true
	},
	"Choose another account": {
		"text": "Wybierz inne konto",
		"isAlreadyTranslated": true
	},
	"Change": {
		"text": "Zmień",
		"isAlreadyTranslated": true
	},
	"child": {
		"text": "podrzędny",
		"isAlreadyTranslated": true
	},
	"Plan Types": {
		"text": "Typy planów",
		"isAlreadyTranslated": true
	},
	"Plan Descriptions": {
		"text": "Opisy planów",
		"isAlreadyTranslated": true
	},
	"No Communication History": {
		"text": "Brak historii komunikacji",
		"isAlreadyTranslated": true
	},
	"First Dialer Disposition": {
		"text": "Pierwsza dyspozycja dialera",
		"isAlreadyTranslated": true
	},
	"Second Dialer Disposition": {
		"text": "Druga dyspozycja dialera",
		"isAlreadyTranslated": true
	},
	"Enter First Name": {
		"text": "Wprowadź imię",
		"isAlreadyTranslated": true
	},
	"Enter correct First Name": {
		"text": "Wprowadź poprawne imię",
		"isAlreadyTranslated": true
	},
	"Enter correct Alternate Number": {
		"text": "Wprowadź poprawny inny numer",
		"isAlreadyTranslated": true
	},
	"Enter OderID": {
		"text": "Wprowadź OderID",
		"isAlreadyTranslated": true
	},
	"Enter correct OderID": {
		"text": "Wprowadź poprawny OderID",
		"isAlreadyTranslated": true
	},
	"Enter Order Date": {
		"text": "Wprowadź datę zamówienia",
		"isAlreadyTranslated": true
	},
	"Select a plan from the dropdow": {
		"text": "Wybierz plan z listy rozwijanej",
		"isAlreadyTranslated": true
	},
	"Enter correct City": {
		"text": "Wprowadź poprawne miasto",
		"isAlreadyTranslated": true
	},
	"Enter correct State": {
		"text": "Wprowadź poprawny stan",
		"isAlreadyTranslated": true
	},
	"Consumer Details added successfully": {
		"text": "Pomyślnie dodano szczegóły konsumenta",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to generate Invoice?": {
		"text": "Czy na pewno chcesz wygenerować fakturę?",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to Approve all the jobs?": {
		"text": "Czy na pewno chcesz zatwierdzić wszystkie zadania?",
		"isAlreadyTranslated": true
	},
	"Passwords do not match": {
		"text": "Hasła nie pasują do siebie",
		"isAlreadyTranslated": true
	},
	"Please enter a password": {
		"text": "Wprowadź hasło",
		"isAlreadyTranslated": true
	},
	"This fault code/ cause code has already been selected": {
		"text": "Ten kod usterki / kod przyczyny został już wybrany",
		"isAlreadyTranslated": true
	},
	"Please complete the QC checklist": {
		"text": "Wypełnij listę kontrolną kontroli jakości",
		"isAlreadyTranslated": true
	},
	"Please add mandatory Symptom Fault Action": {
		"text": "Dodaj obowiązkowe: symptom, usterkę, akcję",
		"isAlreadyTranslated": true
	},
	"Branch": {
		"text": "Oddział",
		"isAlreadyTranslated": true
	},
	"Shop": {
		"text": "Sklep",
		"isAlreadyTranslated": true
	},
	"Product Category": {
		"text": "Kategoria produktu",
		"isAlreadyTranslated": true
	},
	"Quantity": {
		"text": "Ilość",
		"isAlreadyTranslated": true
	},
	"No records found": {
		"text": "Nie znaleziono rekordów",
		"isAlreadyTranslated": true
	},
	"No Competition Marked": {
		"text": "Nie oznaczono konkurencji",
		"isAlreadyTranslated": true
	},
	"Filter Competition Sales": {
		"text": "Filtruj sprzedaż konkurencji",
		"isAlreadyTranslated": true
	},
	"Download consumer details in csv format.": {
		"text": "Pobierz dane klienta w formacie CSV.",
		"isAlreadyTranslated": true
	},
	"No Consumers found for the selected date range.": {
		"text": "Nie znaleziono konsumentów w wybranym zakresie dat.",
		"isAlreadyTranslated": true
	},
	"Details": {
		"text": "Szczegóły",
		"isAlreadyTranslated": true
	},
	"Create Request": {
		"text": "Utwórz zgłoszenie",
		"isAlreadyTranslated": true
	},
	"Brand Description": {
		"text": "Opis marki",
		"isAlreadyTranslated": true
	},
	"Tab": {
		"text": "Karta",
		"isAlreadyTranslated": true
	},
	"Consumer data": {
		"text": "Dane konsumenta",
		"isAlreadyTranslated": true
	},
	"No consumer data": {
		"text": "Brak danych konsumenta",
		"isAlreadyTranslated": true
	},
	"Something erroneous happened": {
		"text": "Stało się coś błędnego",
		"isAlreadyTranslated": true
	},
	"Consumer Rating": {
		"text": "Ocena konsumenta",
		"isAlreadyTranslated": true
	},
	"Rate your experience with the customer": {
		"text": "Oceń swoje doświadczenie z klientem",
		"isAlreadyTranslated": true
	},
	"Comments (Optional)": {
		"text": "Komentarze (opcjonalnie)",
		"isAlreadyTranslated": true
	},
	"Delivery Report": {
		"text": "Raport dostawy",
		"isAlreadyTranslated": true
	},
	"Authorized Service Centre": {
		"text": "Autoryzowane centrum serwisowe",
		"isAlreadyTranslated": true
	},
	"Device Inward Date & Time": {
		"text": "Data i godzina wprowadzenia urządzenia",
		"isAlreadyTranslated": true
	},
	"Repair Closed Date & Time": {
		"text": "Data i godzina zamknięcia naprawy",
		"isAlreadyTranslated": true
	},
	"Device Collection Date & Time": {
		"text": "Data i godzina odbioru urządzenia",
		"isAlreadyTranslated": true
	},
	"Service Reference ID": {
		"text": "Identyfikator referencyjny usługi",
		"isAlreadyTranslated": true
	},
	"mode": {
		"text": "tryb",
		"isAlreadyTranslated": true
	},
	"Product Details": {
		"text": "Szczegóły produktu",
		"isAlreadyTranslated": true
	},
	"Problems Reported by Customer": {
		"text": "Problemy zgłoszone przez klienta",
		"isAlreadyTranslated": true
	},
	"Condition of Equipment": {
		"text": "Stan sprzętu",
		"isAlreadyTranslated": true
	},
	"Inward Check": {
		"text": "Kontrola wewnętrzna",
		"isAlreadyTranslated": true
	},
	"Outward Check": {
		"text": "Kontrola zewnętrzna",
		"isAlreadyTranslated": true
	},
	"Accessories Received": {
		"text": "Otrzymano akcesoria",
		"isAlreadyTranslated": true
	},
	"Repair Cost (for OOW cases)": {
		"text": "Koszt naprawy (dla przypadków OOW)",
		"isAlreadyTranslated": true
	},
	"Part No.": {
		"text": "Nr części",
		"isAlreadyTranslated": true
	},
	"Part Description": {
		"text": "Opis części",
		"isAlreadyTranslated": true
	},
	"Damaged Part Serial No/ IMEI (if applicable)": {
		"text": "Numer seryjny/IMEI uszkodzonej części (jeśli dotyczy)",
		"isAlreadyTranslated": true
	},
	"Replaced Part Serial No/ IMEI (if applicable)": {
		"text": "Numer seryjny/IMEI wymienionej części (jeśli dotyczy)",
		"isAlreadyTranslated": true
	},
	"Customer Feedback": {
		"text": "Opinia klienta",
		"isAlreadyTranslated": true
	},
	"Neither Satisfied nor Dissatisfied": {
		"text": "Ani zadowolony, ani niezadowolony",
		"isAlreadyTranslated": true
	},
	"Somewhat Dissatisfied": {
		"text": "Trochę niezadowolony",
		"isAlreadyTranslated": true
	},
	"Overall Satisfaction": {
		"text": "Ogólna satysfakcja",
		"isAlreadyTranslated": true
	},
	"Technical Ability": {
		"text": "Zdolności techniczne",
		"isAlreadyTranslated": true
	},
	"Professionalism": {
		"text": "Profesjonalizm",
		"isAlreadyTranslated": true
	},
	"Response Time": {
		"text": "Czas odpowiedzi",
		"isAlreadyTranslated": true
	},
	"Quality of Repair": {
		"text": "Jakość naprawy",
		"isAlreadyTranslated": true
	},
	"Timely Update": {
		"text": "Terminowa aktualizacja",
		"isAlreadyTranslated": true
	},
	"(I am satified with the performance of the Product and have received the Product and all its Accessories)": {
		"text": "(Jestem zadowolona/zadowolony z działania produktu i otrzymałam/otrzymałem produkt i wszystkie jego akcesoria)",
		"isAlreadyTranslated": true
	},
	"(For Repair Closure)": {
		"text": "(Do zamknięcia naprawy)",
		"isAlreadyTranslated": true
	},
	"Lifecycle": {
		"text": "Cykl życia",
		"isAlreadyTranslated": true
	},
	"No results to display": {
		"text": "Brak wyników do wyświetlenia",
		"isAlreadyTranslated": true
	},
	"No information found": {
		"text": "Nie znaleziono informacji",
		"isAlreadyTranslated": true
	},
	"Diagnosed On": {
		"text": "Zdiagnozowano",
		"isAlreadyTranslated": true
	},
	"Select Time Period": {
		"text": "Wybierz przedział czasu",
		"isAlreadyTranslated": true
	},
	"Coming Soon!": {
		"text": "Wkrótce!",
		"isAlreadyTranslated": true
	},
	"No Sufficient Data Available To Plot The Graph": {
		"text": "Brak dostępnych danych wystarczających do wykreślenia wykresu",
		"isAlreadyTranslated": true
	},
	"DIAGNOSIS CERTIFICATE": {
		"text": "CERTYFIKAT DIAGNOSTYCZNY",
		"isAlreadyTranslated": true
	},
	"Diagnosis Date": {
		"text": "Data diagnostyki",
		"isAlreadyTranslated": true
	},
	"Sim Activation Date": {
		"text": "Data aktywacji karty SIM",
		"isAlreadyTranslated": true
	},
	"Detailed Diagnostic Report": {
		"text": "Szczegółowy raport diagnostyczny",
		"isAlreadyTranslated": true
	},
	"Summary": {
		"text": "Podsumowanie",
		"isAlreadyTranslated": true
	},
	"Tentative Result": {
		"text": "Wstępny wynik",
		"isAlreadyTranslated": true
	},
	"Component Name": {
		"text": "Nazwa składnika",
		"isAlreadyTranslated": true
	},
	"Observed Value": {
		"text": "Obserwowana wartość",
		"isAlreadyTranslated": true
	},
	"Result": {
		"text": "Wynik",
		"isAlreadyTranslated": true
	},
	"Reference Range": {
		"text": "Zakres referencyjny",
		"isAlreadyTranslated": true
	},
	"Ready to dispatch": {
		"text": "Przygotowano do wysyłki",
		"isAlreadyTranslated": true
	},
	"Dispatched": {
		"text": "Wysłano",
		"isAlreadyTranslated": true
	},
	"Tracking Number": {
		"text": "Numer śledzenia",
		"isAlreadyTranslated": true
	},
	"Box": {
		"text": "Opakowanie",
		"isAlreadyTranslated": true
	},
	"Width": {
		"text": "Szerokość",
		"isAlreadyTranslated": true
	},
	"Height": {
		"text": "Wysokość",
		"isAlreadyTranslated": true
	},
	"Weight": {
		"text": "Waga",
		"isAlreadyTranslated": true
	},
	"Please enter Box Height.": {
		"text": "Wprowadź wysokość opakowania.",
		"isAlreadyTranslated": true
	},
	"Please enter Box width.": {
		"text": "Wprowadź szerokość opakowania.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Length.": {
		"text": "Wprowadź długość opakowania.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Weight.": {
		"text": "Wprowadź wagę opakowania.",
		"isAlreadyTranslated": true
	},
	"Length": {
		"text": "Długość",
		"isAlreadyTranslated": true
	},
	"Please enter tracking Number.": {
		"text": "Wprowadź numer śledzenia.",
		"isAlreadyTranslated": true
	},
	"Please enter Shipper Detail.": {
		"text": "Wprowadź dane nadawcy.",
		"isAlreadyTranslated": true
	},
	"Please Select & Click Branch Name from the Dropdown List": {
		"text": "Wybierz nazwę oddziału z listy rozwijanej i kliknij ją",
		"isAlreadyTranslated": true
	},
	"As per invoice": {
		"text": "Zgodnie z fakturą",
		"isAlreadyTranslated": true
	},
	"Problematic Driver": {
		"text": "Problematyczny sterownik",
		"isAlreadyTranslated": true
	},
	"No Problematic drivers found": {
		"text": "Nie znaleziono problematycznych sterowników",
		"isAlreadyTranslated": true
	},
	"Installed Drivers": {
		"text": "Zainstalowane sterowniki",
		"isAlreadyTranslated": true
	},
	"No Installed drivers found": {
		"text": "Nie znaleziono zainstalowanych sterowników",
		"isAlreadyTranslated": true
	},
	"Type: cashback, discount or referral": {
		"text": "Typ: zwrot gotówki, rabat lub odesłanie",
		"isAlreadyTranslated": true
	},
	"Save Coupon": {
		"text": "Zapisz kupon",
		"isAlreadyTranslated": true
	},
	"Handset Reset": {
		"text": "Resetuj słuchawkę",
		"isAlreadyTranslated": true
	},
	"Handset Details": {
		"text": "Szczegóły słuchawki",
		"isAlreadyTranslated": true
	},
	"Service Location Name": {
		"text": "Nazwa lokalizacji serwisu",
		"isAlreadyTranslated": true
	},
	"Last Resetted On": {
		"text": "Ostatnio resetowano w dniu",
		"isAlreadyTranslated": true
	},
	"No of Handsets Resets Made": {
		"text": "Liczba wykonanych resetów słuchawek",
		"isAlreadyTranslated": true
	},
	"Handset reset has been done. Please inform the Engineer to login into his Technician App.": {
		"text": "Zresetowano słuchawkę. Poinformuj inżyniera, aby zalogował się do swojej aplikacji technika.",
		"isAlreadyTranslated": true
	},
	"Assigned/Finished jobs": {
		"text": "Przypisane/zakończone zadania",
		"isAlreadyTranslated": true
	},
	"Productivity": {
		"text": "Produktywność",
		"isAlreadyTranslated": true
	},
	"Current Job Details": {
		"text": "Szczegóły aktualnego zadania",
		"isAlreadyTranslated": true
	},
	"Next Job Details": {
		"text": "Szczegóły następnego zadania",
		"isAlreadyTranslated": true
	},
	"No next job": {
		"text": "Brak następnego zadania",
		"isAlreadyTranslated": true
	},
	"No current job": {
		"text": "Brak aktualnego zadania",
		"isAlreadyTranslated": true
	},
	"No previous jobs": {
		"text": "Brak poprzednich zadań",
		"isAlreadyTranslated": true
	},
	"No record found": {
		"text": "Nie znaleziono rekordów",
		"isAlreadyTranslated": true
	},
	"User Remarks": {
		"text": "Uwagi użytkownika",
		"isAlreadyTranslated": true
	},
	"Audience Selection": {
		"text": "Wybór odbiorców",
		"isAlreadyTranslated": true
	},
	"Final Review": {
		"text": "Ostateczna ocena",
		"isAlreadyTranslated": true
	},
	"Potential Reach": {
		"text": "Potencjalny zasięg",
		"isAlreadyTranslated": true
	},
	"Personnel": {
		"text": "Personel",
		"isAlreadyTranslated": true
	},
	"Select Advisor": {
		"text": "Wybierz doradcę",
		"isAlreadyTranslated": true
	},
	"Notification Expiry Duration (in days)": {
		"text": "Okres ważności powiadomienia (w dniach)",
		"isAlreadyTranslated": true
	},
	"Click here to upload file": {
		"text": "Kliknij tutaj, aby przekazać plik",
		"isAlreadyTranslated": true
	},
	"View uploaded file": {
		"text": "Wyświetl przekazany plik",
		"isAlreadyTranslated": true
	},
	"Notification Details": {
		"text": "Szczegóły powiadomienia",
		"isAlreadyTranslated": true
	},
	"Title": {
		"text": "Tytuł",
		"isAlreadyTranslated": true
	},
	"Message": {
		"text": "Wiadomość",
		"isAlreadyTranslated": true
	},
	"Attachment": {
		"text": "Załącznik",
		"isAlreadyTranslated": true
	},
	"Link": {
		"text": "Łącze",
		"isAlreadyTranslated": true
	},
	"Link to attachment": {
		"text": "Łącze do załącznika",
		"isAlreadyTranslated": true
	},
	"Audience": {
		"text": "Odbiorcy",
		"isAlreadyTranslated": true
	},
	"All shops and states": {
		"text": "Wszystkie sklepy i stany",
		"isAlreadyTranslated": true
	},
	"Roster Managment": {
		"text": "Zarządzanie harmonogramem",
		"isAlreadyTranslated": true
	},
	"Sample Files Data Format": {
		"text": "Format danych przykładowych plików",
		"isAlreadyTranslated": true
	},
	"Supported Data Format": {
		"text": "Obsługiwany format danych",
		"isAlreadyTranslated": true
	},
	"Issue Resolved": {
		"text": "Rozwiązano problem",
		"isAlreadyTranslated": true
	},
	"Approve Exception": {
		"text": "Zatwierdź wyjątek",
		"isAlreadyTranslated": true
	},
	"Raise Exception": {
		"text": "Zgłoś wyjątek",
		"isAlreadyTranslated": true
	},
	"Select SKU": {
		"text": "Wybierz SKU",
		"isAlreadyTranslated": true
	},
	"Product Purchase Cost": {
		"text": "Koszt zakupu produktu",
		"isAlreadyTranslated": true
	},
	"Refund Amount": {
		"text": "Kwota zwrotu",
		"isAlreadyTranslated": true
	},
	"Suggested amount is based on Depreciation Price": {
		"text": "Sugerowana kwota jest oparta na cenie amortyzacji",
		"isAlreadyTranslated": true
	},
	"Upload Document Proof": {
		"text": "Przekaż potwierdzenie dokumentu",
		"isAlreadyTranslated": true
	},
	"Uploaded Documents": {
		"text": "Przekazane dokumenty",
		"isAlreadyTranslated": true
	},
	"Revert Changes": {
		"text": "Wycofaj zmiany",
		"isAlreadyTranslated": true
	},
	"Approve": {
		"text": "Zatwierdź",
		"isAlreadyTranslated": true
	},
	"No Service Requests found for selected date range.": {
		"text": "Nie znaleziono zgłoszeń usług dla wybranego zakresu dat.",
		"isAlreadyTranslated": true
	},
	"External Service Request Details": {
		"text": "Szczegóły zgłoszenia usługi zewnętrznej",
		"isAlreadyTranslated": true
	},
	"Service Center Details": {
		"text": "Szczegóły centrum serwisowego",
		"isAlreadyTranslated": true
	},
	"No such request exists": {
		"text": "Takie zgłoszenie nie istnieje",
		"isAlreadyTranslated": true
	},
	"Job Number": {
		"text": "Numer zadania",
		"isAlreadyTranslated": true
	},
	"Supplier Job Number": {
		"text": "Numer zadania dostawcy",
		"isAlreadyTranslated": true
	},
	"Temporary Job Number": {
		"text": "Tymczasowy numer zadania",
		"isAlreadyTranslated": true
	},
	"Service Center Name": {
		"text": "Nazwa centrum serwisowego",
		"isAlreadyTranslated": true
	},
	"Service Center Code": {
		"text": "Kod centrum serwisowego",
		"isAlreadyTranslated": true
	},
	"Service Center Phone": {
		"text": "Telefon centrum serwisowego",
		"isAlreadyTranslated": true
	},
	"Service Center Email": {
		"text": "E-mail centrum serwisowego",
		"isAlreadyTranslated": true
	},
	"Show filtered data": {
		"text": "Pokaż przefiltrowane dane",
		"isAlreadyTranslated": true
	},
	"Please select atleast one escalation": {
		"text": "Wybierz co najmniej jedną eskalację",
		"isAlreadyTranslated": true
	},
	"Reset password": {
		"text": "Resetuj hasło",
		"isAlreadyTranslated": true
	},
	"Forgot password": {
		"text": "Nie pamiętam hasła",
		"isAlreadyTranslated": true
	},
	"Select a Reset Option": {
		"text": "Wybierz opcję resetowania",
		"isAlreadyTranslated": true
	},
	"LOCATION RESET": {
		"text": "RESETOWANIE LOKALIZACJI",
		"isAlreadyTranslated": true
	},
	"Handset reset has already been done. Please inform the corresponding": {
		"text": "Zresetowano już słuchawkę. Poinformuj odpowiednie",
		"isAlreadyTranslated": true
	},
	"to login Mobile COS.": {
		"text": "aby zalogować się do systemu Mobile COS.",
		"isAlreadyTranslated": true
	},
	"sell": {
		"text": "sprzedaj",
		"isAlreadyTranslated": true
	},
	"Diagnosis attempted": {
		"text": "Podjęto próbę diagnostyki",
		"isAlreadyTranslated": true
	},
	"Please select the at least one of the custom diagnosis options to initiate diagnosis": {
		"text": "Wybierz co najmniej jedną z niestandardowych opcji diagnostyki, aby zainicjować diagnostykę",
		"isAlreadyTranslated": true
	},
	"Filter Products": {
		"text": "Filtruj produkty",
		"isAlreadyTranslated": true
	},
	"Upload Sales": {
		"text": "Przekaż sprzedaż",
		"isAlreadyTranslated": true
	},
	"Inventory": {
		"text": "Inwentarz",
		"isAlreadyTranslated": true
	},
	"Receipt Date": {
		"text": "Data pokwitowania",
		"isAlreadyTranslated": true
	},
	"Currently With": {
		"text": "Obecnie z",
		"isAlreadyTranslated": true
	},
	"Owner": {
		"text": "Właściciel",
		"isAlreadyTranslated": true
	},
	"Error in uploaded csv file at line number:": {
		"text": "Błąd w przekazanym pliku CSV, w wierszu o numerze:",
		"isAlreadyTranslated": true
	},
	"Error in following data at line number: 1": {
		"text": "Błąd w następujących danych, w wierszu o numerze: 1",
		"isAlreadyTranslated": true
	},
	"Uploaded file format is not supported": {
		"text": "Przekazany format pliku nie jest obsługiwany",
		"isAlreadyTranslated": true
	},
	"Receipt End Date": {
		"text": "Data końcowa pokwitowania",
		"isAlreadyTranslated": true
	},
	"Receipt Start Date": {
		"text": "Data początkowa pokwitowania",
		"isAlreadyTranslated": true
	},
	"Sale Start Date": {
		"text": "Data początkowa sprzedaży",
		"isAlreadyTranslated": true
	},
	"Sale End Date": {
		"text": "Data końcowa sprzedaży",
		"isAlreadyTranslated": true
	},
	"Parts Charges (sales taxes included)": {
		"text": "Opłaty za części (w tym podatki od sprzedaży)",
		"isAlreadyTranslated": true
	},
	"Sub total of services": {
		"text": "Suma częściowa usług",
		"isAlreadyTranslated": true
	},
	"Advance and Loyalty": {
		"text": "Zaliczka i lojalność",
		"isAlreadyTranslated": true
	},
	"Servify Cash Redeemed": {
		"text": "Spłata gotówkowa Servify",
		"isAlreadyTranslated": true
	},
	"Adjustment": {
		"text": "Dostosowanie",
		"isAlreadyTranslated": true
	},
	"Coupon Discount": {
		"text": "Kupon rabatowy",
		"isAlreadyTranslated": true
	},
	"Issues Reported By Customer": {
		"text": "Problemy zgłoszone przez klienta",
		"isAlreadyTranslated": true
	},
	"Symptom Description": {
		"text": "Opis objawów",
		"isAlreadyTranslated": true
	},
	"Fault Description": {
		"text": "Opis usterki",
		"isAlreadyTranslated": true
	},
	"Action Description": {
		"text": "Opis działania",
		"isAlreadyTranslated": true
	},
	"Total Payable Amount": {
		"text": "Całkowita kwota do zapłaty",
		"isAlreadyTranslated": true
	},
	"Spare Consumption": {
		"text": "Zużycie części zapasowych",
		"isAlreadyTranslated": true
	},
	"Spares Description": {
		"text": "Opis części zapasowych",
		"isAlreadyTranslated": true
	},
	"Charges": {
		"text": "Opłaty",
		"isAlreadyTranslated": true
	},
	"total": {
		"text": "suma",
		"isAlreadyTranslated": true
	},
	"Total": {
		"text": "Suma",
		"isAlreadyTranslated": true
	},
	"Location Reset": {
		"text": "Resetowanie lokalizacji",
		"isAlreadyTranslated": true
	},
	"Shop Location Reset Details": {
		"text": "Szczegóły resetowania lokalizacji sklepu",
		"isAlreadyTranslated": true
	},
	"Last Location resetted on": {
		"text": "Lokalizacja została ostatnio zresetowana w dniu",
		"isAlreadyTranslated": true
	},
	"No. of Resets Made": {
		"text": "Liczba wykonanych resetów",
		"isAlreadyTranslated": true
	},
	"to exit and re-open the Mobile COS": {
		"text": "aby wyjść i ponownie otworzyć system Mobile COS",
		"isAlreadyTranslated": true
	},
	"Shop Location reset has already been done. Please inform the corresponding": {
		"text": "Resetowanie lokalizacji sklepu zostało już wykonane. Poinformuj odpowiednie",
		"isAlreadyTranslated": true
	},
	"Previous Locations Reset Details": {
		"text": "Szczegóły poprzedniego resetowania lokalizacji",
		"isAlreadyTranslated": true
	},
	"Sign in": {
		"text": "Zaloguj się",
		"isAlreadyTranslated": true
	},
	"to continue.": {
		"text": "aby kontynuować.",
		"isAlreadyTranslated": true
	},
	"Resend OTP": {
		"text": "Ponownie wyślij hasło jednorazowe",
		"isAlreadyTranslated": true
	},
	"Login": {
		"text": "Zaloguj się",
		"isAlreadyTranslated": true
	},
	"User Type": {
		"text": "Typ użytkownika",
		"isAlreadyTranslated": true
	},
	"Username/Email": {
		"text": "Nazwa użytkownika/e-mail",
		"isAlreadyTranslated": true
	},
	"View requests": {
		"text": "Wyświetl zgłoszenia",
		"isAlreadyTranslated": true
	},
	"All fields are mandatory.": {
		"text": "Wszystkie pola są obowiązkowe.",
		"isAlreadyTranslated": true
	},
	"Pickup Details": {
		"text": "Szczegóły miejsca odbioru",
		"isAlreadyTranslated": true
	},
	"Drop Details": {
		"text": "Szczegóły miejsca nadania",
		"isAlreadyTranslated": true
	},
	"Check Service Availability": {
		"text": "Sprawdź dostępność usługi",
		"isAlreadyTranslated": true
	},
	"Invoice Date": {
		"text": "Data faktury",
		"isAlreadyTranslated": true
	},
	"Flow": {
		"text": "Przepływ",
		"isAlreadyTranslated": true
	},
	"Issue": {
		"text": "Problem",
		"isAlreadyTranslated": true
	},
	"Reference Number": {
		"text": "Numer referencyjny",
		"isAlreadyTranslated": true
	},
	"Provider": {
		"text": "Dostawca usług",
		"isAlreadyTranslated": true
	},
	"Create Shipment": {
		"text": "Utwórz przesyłkę",
		"isAlreadyTranslated": true
	},
	"Logistics request is not raised. Please try again": {
		"text": "Zgłoszenie logistyczne nie zostało wprowadzone. Spróbuj ponownie",
		"isAlreadyTranslated": true
	},
	"IMEI has to be atleast 15 characters.": {
		"text": "Numer IMEI musi mieć co najmniej 15 znaków.",
		"isAlreadyTranslated": true
	},
	"The request is already active with this IMEI/Serial No.": {
		"text": "Zgłoszenie jest już aktywne z tym numerem IMEI/seryjnym.",
		"isAlreadyTranslated": true
	},
	"Service Location Engineer Mapping": {
		"text": "Odwzorowanie inżyniera do lokalizacji usługi",
		"isAlreadyTranslated": true
	},
	"Pincode Engineer Mapping": {
		"text": "Odwzorowanie inżyniera do kodu PIN",
		"isAlreadyTranslated": true
	},
	"Last set on": {
		"text": "Ostatnio ustawiono w dniu",
		"isAlreadyTranslated": true
	},
	"Variant": {
		"text": "Wariant",
		"isAlreadyTranslated": true
	},
	"MOP for Trade-in": {
		"text": "MOP do wymiany za dopłatą",
		"isAlreadyTranslated": true
	},
	"Cashback": {
		"text": "Zwrot gotówki",
		"isAlreadyTranslated": true
	},
	"Show Trade-In Amount": {
		"text": "Pokaż kwotę do wymiany za dopłatą",
		"isAlreadyTranslated": true
	},
	"No records": {
		"text": "Brak rekordów",
		"isAlreadyTranslated": true
	},
	"Battery Conditions": {
		"text": "Stan baterii",
		"isAlreadyTranslated": true
	},
	"Body Conditions": {
		"text": "Stan konstrukcji",
		"isAlreadyTranslated": true
	},
	"Nothing to save": {
		"text": "Nie ma nic do zapisania",
		"isAlreadyTranslated": true
	},
	"Push Notification": {
		"text": "Powiadomienie push",
		"isAlreadyTranslated": true
	},
	"Is Warranty Expired?": {
		"text": "Czy gwarancja wygasła?",
		"isAlreadyTranslated": true
	},
	"No. of Service Requests": {
		"text": "Liczba zgłoszeń serwisowych",
		"isAlreadyTranslated": true
	},
	"Please Close all the tasks": {
		"text": "Zamknij wszystkie zadania",
		"isAlreadyTranslated": true
	},
	"Please rate the service": {
		"text": "Oceń usługę",
		"isAlreadyTranslated": true
	},
	"Feedback": {
		"text": "Opinia",
		"isAlreadyTranslated": true
	},
	"Submit with Feedback": {
		"text": "Prześlij z opinią",
		"isAlreadyTranslated": true
	},
	"Please select a reason for cancelling service": {
		"text": "Wybierz powód anulowania usługi",
		"isAlreadyTranslated": true
	},
	"Close Visit": {
		"text": "Zamknij wizytę",
		"isAlreadyTranslated": true
	},
	"Visit Disposition Status": {
		"text": "Stan dyspozycji wizyty",
		"isAlreadyTranslated": true
	},
	"Request Status": {
		"text": "Status zgłoszenia",
		"isAlreadyTranslated": true
	},
	"Complete Visit": {
		"text": "Zakończ wizytę",
		"isAlreadyTranslated": true
	},
	"Submit IRIS": {
		"text": "Prześlij IRIS",
		"isAlreadyTranslated": true
	},
	"Select Symptom": {
		"text": "Wybierz objaw",
		"isAlreadyTranslated": true
	},
	"Select Action": {
		"text": "Wybierz akcję",
		"isAlreadyTranslated": true
	},
	"Select Fault": {
		"text": "Wybierz usterkę",
		"isAlreadyTranslated": true
	},
	"Symptom": {
		"text": "Objaw",
		"isAlreadyTranslated": true
	},
	"Fault": {
		"text": "Usterka",
		"isAlreadyTranslated": true
	},
	"Complete Visit And Submit IRIS": {
		"text": "Zakończ wizytę i prześlij IRIS",
		"isAlreadyTranslated": true
	},
	"Create Visit": {
		"text": "Utwórz wizytę",
		"isAlreadyTranslated": true
	},
	"Select an Engineer to assign": {
		"text": "Wybierz inżyniera do przypisania",
		"isAlreadyTranslated": true
	},
	"Reason For Follow Up": {
		"text": "Powód kontynuacji",
		"isAlreadyTranslated": true
	},
	"Clone this request": {
		"text": "Sklonuj zgłoszenie",
		"isAlreadyTranslated": true
	},
	"No of requests to be cloned": {
		"text": "Liczba zgłoszeń do sklonowania",
		"isAlreadyTranslated": true
	},
	"Priority": {
		"text": "Priorytet",
		"isAlreadyTranslated": true
	},
	"Update Job": {
		"text": "Aktualizuj zadanie",
		"isAlreadyTranslated": true
	},
	"Journey Details": {
		"text": "Szczegóły podróży",
		"isAlreadyTranslated": true
	},
	"Enter date": {
		"text": "Wprowadź datę",
		"isAlreadyTranslated": true
	},
	"Begin time": {
		"text": "Godzina rozpoczęcia",
		"isAlreadyTranslated": true
	},
	"End time": {
		"text": "Godzina zakończenia",
		"isAlreadyTranslated": true
	},
	"Note : Time must be in between": {
		"text": "Uwaga: Czas musi być między",
		"isAlreadyTranslated": true
	},
	"Add Parts": {
		"text": "Dodaj części",
		"isAlreadyTranslated": true
	},
	"Avl. Quantity": {
		"text": "Dost. Ilość",
		"isAlreadyTranslated": true
	},
	"Added Parts": {
		"text": "dodane części",
		"isAlreadyTranslated": true
	},
	"Document": {
		"text": "Dokument",
		"isAlreadyTranslated": true
	},
	"View Image": {
		"text": "Wyświetl obraz",
		"isAlreadyTranslated": true
	},
	"No parts found for this product.": {
		"text": "Nie znaleziono części do tego produktu.",
		"isAlreadyTranslated": true
	},
	"Can't add more than one discount": {
		"text": "Nie można dodać więcej niż jednego rabatu",
		"isAlreadyTranslated": true
	},
	"This IRIS has already been added.": {
		"text": "Dodano już ten kod IRIS.",
		"isAlreadyTranslated": true
	},
	"Please add a part from dropdown.": {
		"text": "Dodaj część z listy rozwijanej.",
		"isAlreadyTranslated": true
	},
	"Part is already added": {
		"text": "Część jest już dodana",
		"isAlreadyTranslated": true
	},
	"Full box unit is already selected.": {
		"text": "Wybrano już pełną jednostkę opakowania.",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to create a visit?": {
		"text": "Czy na pewno chcesz utworzyć wizytę?",
		"isAlreadyTranslated": true
	},
	"Please select an engineer to assign": {
		"text": "Wybierz inżyniera do przypisania",
		"isAlreadyTranslated": true
	},
	"IRIS Codes has been updated successfully.": {
		"text": "Kody IRIS zostały pomyślnie zaktualizowane.",
		"isAlreadyTranslated": true
	},
	"The payment has been received and service will be completed.": {
		"text": "Płatność została otrzymana i usługa zostanie zakończona.",
		"isAlreadyTranslated": true
	},
	"Converted Requests": {
		"text": "Przekonwertowane zgłoszenia",
		"isAlreadyTranslated": true
	},
	"Identifiers": {
		"text": "Identyfikatory",
		"isAlreadyTranslated": true
	},
	"Verify Device": {
		"text": "Zweryfikuj urządzenie",
		"isAlreadyTranslated": true
	},
	"Review Order": {
		"text": "Przejrzyj zamówienie",
		"isAlreadyTranslated": true
	},
	"Order Summary": {
		"text": "Podsumowanie zamówienia",
		"isAlreadyTranslated": true
	},
	"Congratulations! Your device is eligible for this plan": {
		"text": "Gratulacje! Urządzenie kwalifikuje się do tego planu",
		"isAlreadyTranslated": true
	},
	"This Device is Eligible for this Plan": {
		"text": "To urządzenie kwalifikuje się do tego planu",
		"isAlreadyTranslated": true
	},
	"Price of Plan": {
		"text": "Cena planu",
		"isAlreadyTranslated": true
	},
	"Transaction Details": {
		"text": "Szczegóły transakcji",
		"isAlreadyTranslated": true
	},
	"Device Images": {
		"text": "Zdjęcia urządzenia",
		"isAlreadyTranslated": true
	},
	"(All 6 Images are Mandatory)": {
		"text": "(wszystkie 6 zdjęć jest obowiązkowych)",
		"isAlreadyTranslated": true
	},
	"Product Purchase Date": {
		"text": "Data zakupu produktu",
		"isAlreadyTranslated": true
	},
	"Product value": {
		"text": "Wartość produktu",
		"isAlreadyTranslated": true
	},
	"Plan Price": {
		"text": "Cena planu",
		"isAlreadyTranslated": true
	},
	"The user has agreed with the": {
		"text": "Użytkownik zgodził się na",
		"isAlreadyTranslated": true
	},
	"Terms and Conditions": {
		"text": "OWU",
		"isAlreadyTranslated": true
	},
	"before purchasing this plan.": {
		"text": "przed zakupem tego planu.",
		"isAlreadyTranslated": true
	},
	"Resend Link": {
		"text": "Wyślij ponownie łącze",
		"isAlreadyTranslated": true
	},
	"Refresh": {
		"text": "Odśwież",
		"isAlreadyTranslated": true
	},
	"Resend Payment Link": {
		"text": "Wyślij ponownie łącze do płatności",
		"isAlreadyTranslated": true
	},
	"Refund": {
		"text": "Zwrot kosztów",
		"isAlreadyTranslated": true
	},
	"Invoice Number": {
		"text": "Numer faktury",
		"isAlreadyTranslated": true
	},
	"Documents Uploaded": {
		"text": "Przesłane dokumenty",
		"isAlreadyTranslated": true
	},
	"Transaction Summary": {
		"text": "Podsumowanie transakcji",
		"isAlreadyTranslated": true
	},
	"Download": {
		"text": "Pobierz",
		"isAlreadyTranslated": true
	},
	"Proof of Coverage (POC)": {
		"text": "Dowód ubezpieczenia (POC)",
		"isAlreadyTranslated": true
	},
	"Insurance Certificate": {
		"text": "Polisa ubezpieczeniowa",
		"isAlreadyTranslated": true
	},
	"Print Invoice": {
		"text": "Wydrukuj fakturę",
		"isAlreadyTranslated": true
	},
	"Cancel Plan": {
		"text": "Anuluj plan",
		"isAlreadyTranslated": true
	},
	"Go to Home": {
		"text": "Przejdź do strony głównej",
		"isAlreadyTranslated": true
	},
	"Plan Cancelled": {
		"text": "Anulowano plan",
		"isAlreadyTranslated": true
	},
	"Check Eligibility": {
		"text": "Sprawdź kwalifikację",
		"isAlreadyTranslated": true
	},
	"Repair Date": {
		"text": "Data naprawy",
		"isAlreadyTranslated": true
	},
	"Plan Purchase Date": {
		"text": "Data zakupu planu",
		"isAlreadyTranslated": true
	},
	"Sell Now": {
		"text": "Sprzedaj",
		"isAlreadyTranslated": true
	},
	"Payment Link ReSent": {
		"text": "Ponownie wysłano łącze do płatności",
		"isAlreadyTranslated": true
	},
	"Payment Link Sent": {
		"text": "Wysłano łącze do płatności",
		"isAlreadyTranslated": true
	},
	"Link sent to": {
		"text": "Łącze wysłano do",
		"isAlreadyTranslated": true
	},
	"The Payment link is only valid for 24 hours.": {
		"text": "Łącze do płatności jest ważne tylko przez 24 godziny.",
		"isAlreadyTranslated": true
	},
	"Email POC to below email address": {
		"text": "Wyślij POC na poniższy adres e-mail",
		"isAlreadyTranslated": true
	},
	"Send a copy to below email address": {
		"text": "Wyślij kopię na poniższy adres e-mail",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to cancel the order?": {
		"text": "Czy na pewno chcesz anulować zamówienie?",
		"isAlreadyTranslated": true
	},
	"Select reason for cancellation": {
		"text": "Wybierz powód anulowania",
		"isAlreadyTranslated": true
	},
	"Cancel Order": {
		"text": "Anuluj zamówienie",
		"isAlreadyTranslated": true
	},
	"Plan price data not found.": {
		"text": "Nie znaleziono danych cennika planu.",
		"isAlreadyTranslated": true
	},
	"Discount Value cannot be greater than 100%": {
		"text": "Wartość rabatu nie może być większa niż 100%",
		"isAlreadyTranslated": true
	},
	"Discount Value cannot be greater than the Plan Price": {
		"text": "Wartość rabatu nie może być większa niż cena planu",
		"isAlreadyTranslated": true
	},
	"Incorrect discount value": {
		"text": "Niepoprawna wartość rabatu",
		"isAlreadyTranslated": true
	},
	"The payment link has been sent to": {
		"text": "Łącze do płatności zostało wysłane do",
		"isAlreadyTranslated": true
	},
	"You have successfully purchased": {
		"text": "Pomyślnie zakupiono",
		"isAlreadyTranslated": true
	},
	"Your AppleCare Protection Plan proof of coverage document will be emailed to you in 24 hours.": {
		"text": "Dowód objęcia planem ochrony AppleCare zostanie przesłany pocztą e-mail w ciągu 24 godzin.",
		"isAlreadyTranslated": true
	},
	"Please enter correct email address": {
		"text": "Wprowadź poprawny adres e-mail",
		"isAlreadyTranslated": true
	},
	"Enter Email Address of the user whose rights you want to": {
		"text": "Enter Email Address of the user whose rights you want to",
		"isAlreadyTranslated": true
	},
	"Associated Partner": {
		"text": "Partner stowarzyszony",
		"isAlreadyTranslated": true
	},
	"Access given for": {
		"text": "Dostęp przyznany dla",
		"isAlreadyTranslated": true
	},
	"Add Brands": {
		"text": "Dodaj marki",
		"isAlreadyTranslated": true
	},
	"CHOOSE BRAND": {
		"text": "WYBIERZ MARKĘ",
		"isAlreadyTranslated": true
	},
	"No Other Requests": {
		"text": "Brak innych zgłoszeń",
		"isAlreadyTranslated": true
	},
	"No parts requests in this section.": {
		"text": "Brak zgłoszeń części w tej sekcji.",
		"isAlreadyTranslated": true
	},
	"Charge Code": {
		"text": "Kod opłaty",
		"isAlreadyTranslated": true
	},
	"AWB Number": {
		"text": "Numer lotniczego listu przewozowego",
		"isAlreadyTranslated": true
	},
	"Add more": {
		"text": "Dodaj więcej",
		"isAlreadyTranslated": true
	},
	"Vendor name": {
		"text": "Nazwa dostawcy",
		"isAlreadyTranslated": true
	},
	"Instruction": {
		"text": "Instrukcja",
		"isAlreadyTranslated": true
	},
	"Logistics Service Type": {
		"text": "Typ usługi logistycznej",
		"isAlreadyTranslated": true
	},
	"SKU Requested": {
		"text": "Żądane SKU",
		"isAlreadyTranslated": true
	},
	"Code": {
		"text": "Kod",
		"isAlreadyTranslated": true
	},
	"Defective Serial No": {
		"text": "Wadliwy numer seryjny",
		"isAlreadyTranslated": true
	},
	"All parts consumed will be reverted and the Engineer has to handover these good parts to the Store Manager. Are you sure you want to proceed?": {
		"text": "Wszystkie zużyte części zostaną zwrócone, a inżynier musi przekazać te dobre części kierownikowi magazynu. Czy na pewno chcesz kontynuować?",
		"isAlreadyTranslated": true
	},
	"Diagnostics Event Number": {
		"text": "Numer zdarzenia diagnostycznego",
		"isAlreadyTranslated": true
	},
	"Unable to validate details from GSX currently. Please try again after sometime.": {
		"text": "Obecnie nie można sprawdzić ważności danych z GSX. Spróbuj ponownie za jakiś czas.",
		"isAlreadyTranslated": true
	},
	"Please specify remarks": {
		"text": "Podaj uwagi",
		"isAlreadyTranslated": true
	},
	"Engineers": {
		"text": "Inżynierowie",
		"isAlreadyTranslated": true
	},
	"Update Mapping": {
		"text": "Aktualizuj odwzorowanie",
		"isAlreadyTranslated": true
	},
	"mappings are shown above.": {
		"text": "odwzorowania są widoczne powyżej.",
		"isAlreadyTranslated": true
	},
	"Engineers Under TL": {
		"text": "Inżynierowie pod TL",
		"isAlreadyTranslated": true
	},
	"Plan Header": {
		"text": "Nagłówek planu",
		"isAlreadyTranslated": true
	},
	"Plan Benefits": {
		"text": "Korzyści z planu",
		"isAlreadyTranslated": true
	},
	"Plan Information": {
		"text": "Informacje o planie",
		"isAlreadyTranslated": true
	},
	"Plan updated successfully": {
		"text": "Pomyślnie zaktualizowano plan",
		"isAlreadyTranslated": true
	},
	"Tax is payable on Reverse Charge": {
		"text": "Podatek jest płatny na podstawie obciążenia odwrotnego",
		"isAlreadyTranslated": true
	},
	"Invoice No": {
		"text": "Nr faktury",
		"isAlreadyTranslated": true
	},
	"Details of Receiver (Billed to)": {
		"text": "Dane odbiorcy (faktura dla)",
		"isAlreadyTranslated": true
	},
	"Details of Consignee (Shipped to)": {
		"text": "Dane konsygnatariusza (dostawa do)",
		"isAlreadyTranslated": true
	},
	"Description of Goods/Services": {
		"text": "Opis towarów/usług",
		"isAlreadyTranslated": true
	},
	"Invoice Total(In Words)": {
		"text": "Suma faktury (słownie)",
		"isAlreadyTranslated": true
	},
	"Invoice Total": {
		"text": "Suma faktury",
		"isAlreadyTranslated": true
	},
	"Advance Received": {
		"text": "Otrzymano zaliczkę",
		"isAlreadyTranslated": true
	},
	"Payable Value": {
		"text": "Wartość do zapłaty",
		"isAlreadyTranslated": true
	},
	"We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.": {
		"text": "Oświadczamy, że niniejsza faktura zawiera rzeczywistą cenę opisanego towaru oraz że wszystkie dane są prawdziwe i poprawne.",
		"isAlreadyTranslated": true
	},
	"View Consumers": {
		"text": "Wyświetl konsumentów",
		"isAlreadyTranslated": true
	},
	"Plan Details": {
		"text": "Szczegóły planu",
		"isAlreadyTranslated": true
	},
	"Plan FAQ": {
		"text": "Często zadawane pytania dotyczące planu",
		"isAlreadyTranslated": true
	},
	"Sold Plans": {
		"text": "Sprzedane plany",
		"isAlreadyTranslated": true
	},
	"No sold plans found for the selected date range.": {
		"text": "Nie znaleziono planów sprzedanych w wybranym zakresie dat.",
		"isAlreadyTranslated": true
	},
	"Would you like to add a product?": {
		"text": "Chcesz dodać produkt?",
		"isAlreadyTranslated": true
	},
	"Product is saved successfully": {
		"text": "Produkt został pomyślnie zapisany",
		"isAlreadyTranslated": true
	},
	"Apply a filter before sending push notification.": {
		"text": "Zastosuj filtr przed wysłaniem powiadomienia push.",
		"isAlreadyTranslated": true
	},
	"Redeemed Coupons": {
		"text": "Spłacone kupony",
		"isAlreadyTranslated": true
	},
	"Start at your Home screen.": {
		"text": "Zacznij od ekranu głównego.",
		"isAlreadyTranslated": true
	},
	"Scroll to the bottom and tap Find My iPhone.": {
		"text": "Przewiń w dół i dotknij opcji Znajdź mój iPhone.",
		"isAlreadyTranslated": true
	},
	"Slide to turn off Find My iPhone and Send Last Location.": {
		"text": "Przesuń, aby wyłączyć funkcje Znajdź mój iPhone i Wyślij ostatnią lokalizację.",
		"isAlreadyTranslated": true
	},
	"Servify has received your device and will be sending it to the eRecycling hub after verification.": {
		"text": "Firma Servify otrzymała Twoje urządzenie i wyśle je do centrum eRecycling po weryfikacji.",
		"isAlreadyTranslated": true
	},
	"Servify has collected your device and will be sending it to the eRecycling hub after verification.": {
		"text": "Firma Servify odebrała Twoje urządzenie i wyśle je do centrum eRecycling po weryfikacji.",
		"isAlreadyTranslated": true
	},
	"All the data from your device has been erased.": {
		"text": "Wszystkie dane zostały usunięte w urządzenia.",
		"isAlreadyTranslated": true
	},
	"Please rate your eRecycling experience.": {
		"text": "Oceń wrażenia dotyczące usługi eRecycling.",
		"isAlreadyTranslated": true
	},
	"We have disassembled key components of your device and will be sending it to the eRecycling Hub soon.": {
		"text": "Zdemontowaliśmy kluczowe elementy Twojego urządzenia i wkrótce wyślemy je do centrum recyklingu elektronicznego.",
		"isAlreadyTranslated": true
	},
	"Date of Refund": {
		"text": "Data zwrotu kosztów",
		"isAlreadyTranslated": true
	},
	"Select Category for the appliance": {
		"text": "Wybierz kategorię urządzenia",
		"isAlreadyTranslated": true
	},
	"Select Category": {
		"text": "Wybierz kategorię",
		"isAlreadyTranslated": true
	},
	"Please select which applies.": {
		"text": "Wybierz dotyczące opcje.",
		"isAlreadyTranslated": true
	},
	"Is Electrically OK": {
		"text": "Czy elektrycznie jest w porządku",
		"isAlreadyTranslated": true
	},
	"Are Accessories Available": {
		"text": "Czy dostępne są akcesoria",
		"isAlreadyTranslated": true
	},
	"Dents/Scratches": {
		"text": "Wgniecenia/zadrapania",
		"isAlreadyTranslated": true
	},
	"Packing Box": {
		"text": "Opakowanie",
		"isAlreadyTranslated": true
	},
	"Approve Refund Request": {
		"text": "Zatwierdź zgłoszenie zwrotu kosztów",
		"isAlreadyTranslated": true
	},
	"Please enter Token Number.": {
		"text": "Wprowadź numer tokenu.",
		"isAlreadyTranslated": true
	},
	"Please select a Category from the dropdown": {
		"text": "Wybierz kategorię z listy rozwijanej",
		"isAlreadyTranslated": true
	},
	"Reject Refund Request": {
		"text": "Odrzuć zgłoszenie zwrotu kosztów",
		"isAlreadyTranslated": true
	},
	"Reason for rejection": {
		"text": "Powód odrzucenia",
		"isAlreadyTranslated": true
	},
	"Reason for": {
		"text": "Powód",
		"isAlreadyTranslated": true
	},
	"Please enter Serial Number.": {
		"text": "Wprowadź numer seryjny.",
		"isAlreadyTranslated": true
	},
	"Select a time slot": {
		"text": "Wybierz przedział czasu",
		"isAlreadyTranslated": true
	},
	"Add reason": {
		"text": "Dodaj powód",
		"isAlreadyTranslated": true
	},
	"Please enter correct serial number": {
		"text": "Wprowadź poprawny numer seryjny",
		"isAlreadyTranslated": true
	},
	"Download Report": {
		"text": "Pobierz raport",
		"isAlreadyTranslated": true
	},
	"Filter Reports": {
		"text": "Filtruj raporty",
		"isAlreadyTranslated": true
	},
	"Recent Requested Report": {
		"text": "Ostatni żądany raport",
		"isAlreadyTranslated": true
	},
	"Report Type": {
		"text": "Typ raportu",
		"isAlreadyTranslated": true
	},
	"Report requested on": {
		"text": "Raport żądany w dniu",
		"isAlreadyTranslated": true
	},
	"Report Status": {
		"text": "Stan raportu",
		"isAlreadyTranslated": true
	},
	"Requested Reports": {
		"text": "Żądane raporty",
		"isAlreadyTranslated": true
	},
	"Logistics Vendor": {
		"text": "Dostawca logistyki",
		"isAlreadyTranslated": true
	},
	"Organization Outlet Locations": {
		"text": "Lokalizacje sklepów firmowych organizacji",
		"isAlreadyTranslated": true
	},
	"Type of Report": {
		"text": "Typ raportu",
		"isAlreadyTranslated": true
	},
	"Request Type": {
		"text": "Typ zgłoszenia",
		"isAlreadyTranslated": true
	},
	"No OLCSM found for selected Service Location": {
		"text": "Nie znaleziono OLCSM dla wybranej lokalizacji usługi",
		"isAlreadyTranslated": true
	},
	"No Engineer found for selected Service Location": {
		"text": "Nie znaleziono inżyniera dla wybranej lokalizacji usługi",
		"isAlreadyTranslated": true
	},
	"No partner service location details found for": {
		"text": "Nie znaleziono szczegółów lokalizacji usług partnerskich dla",
		"isAlreadyTranslated": true
	},
	"No partner details found for": {
		"text": "Nie znaleziono szczegółów partnera dla",
		"isAlreadyTranslated": true
	},
	"Please select report type to download": {
		"text": "Wybierz typ raportu do pobrania",
		"isAlreadyTranslated": true
	},
	"Please select a vendor from Filter Reports": {
		"text": "Wybierz dostawcę z pola Filtruj raporty",
		"isAlreadyTranslated": true
	},
	"Please select a organization outlet location from Filter Reports": {
		"text": "Wybierz lokalizację sklepu firmowego organizacji z pola Filtruj raporty",
		"isAlreadyTranslated": true
	},
	"Please select a Service Location Engineer from Filter Reports": {
		"text": "Wybierz inżyniera lokalizacji usług z pola Filtruj raporty",
		"isAlreadyTranslated": true
	},
	"Please select a Type of Report from Filter Reports": {
		"text": "Wybierz typ raportu z pola Filtruj raporty",
		"isAlreadyTranslated": true
	},
	"Please select a Product from Filter Reports": {
		"text": "Wybierz produkt z pola Filtruj raporty",
		"isAlreadyTranslated": true
	},
	"Please select a Service Type from Filter Reports": {
		"text": "Wybierz typ usługi z pola Filtruj raporty",
		"isAlreadyTranslated": true
	},
	"Please select a product category from Filter Reports": {
		"text": "Wybierz kategorię produktu z pola Filtruj raporty",
		"isAlreadyTranslated": true
	},
	"Please select request type from Filter Reports": {
		"text": "Wybierz typ zgłoszenia z pola Filtruj raporty",
		"isAlreadyTranslated": true
	},
	"Error in fetching Service Location IDs": {
		"text": "Błąd podczas pobierania identyfikatorów lokalizacji usługi",
		"isAlreadyTranslated": true
	},
	"REQUEST FORM": {
		"text": "FORMULARZ ZGŁOSZENIA",
		"isAlreadyTranslated": true
	},
	"Sales Invoice": {
		"text": "Faktura sprzedaży",
		"isAlreadyTranslated": true
	},
	"EW claim no": {
		"text": "Nr roszczenia rozszerzonej gwarancji",
		"isAlreadyTranslated": true
	},
	"Created By": {
		"text": "Utworzono przez",
		"isAlreadyTranslated": true
	},
	"Google Location": {
		"text": "Lokalizacja Google",
		"isAlreadyTranslated": true
	},
	"Upload invoice": {
		"text": "Prześlij fakturę",
		"isAlreadyTranslated": true
	},
	"VIP/Urgent": {
		"text": "VIP/pilne",
		"isAlreadyTranslated": true
	},
	"Is Service complimentary ?": {
		"text": "Czy usługa jest bezpłatna?",
		"isAlreadyTranslated": true
	},
	"Please enter correct Email ID": {
		"text": "Wprowadź poprawny adres e-mail",
		"isAlreadyTranslated": true
	},
	"Please enter correct Mobile No": {
		"text": "Wprowadź poprawny nr telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"Please enter correct Pincode": {
		"text": "Wprowadź poprawny kod PIN",
		"isAlreadyTranslated": true
	},
	"Availability": {
		"text": "Dostępność",
		"isAlreadyTranslated": true
	},
	"Skills": {
		"text": "Umiejętności",
		"isAlreadyTranslated": true
	},
	"Shop details updated successfully.": {
		"text": "Pomyślnie zaktualizowano szczegóły sklepu.",
		"isAlreadyTranslated": true
	},
	"New Shop added successfully.": {
		"text": "Pomyślnie dodano nowy sklep.",
		"isAlreadyTranslated": true
	},
	"Save Details": {
		"text": "Zapisz szczegóły",
		"isAlreadyTranslated": true
	},
	"Shop Name": {
		"text": "Nazwa sklepu",
		"isAlreadyTranslated": true
	},
	"Shop Code": {
		"text": "Kod sklepu",
		"isAlreadyTranslated": true
	},
	"Carpet Area (in sq. feet)": {
		"text": "Powierzchnia użytkowa (w stopach kwadratowych)",
		"isAlreadyTranslated": true
	},
	"Contact Number (10 digit)": {
		"text": "Numer kontaktowy (10-cyfrowy)",
		"isAlreadyTranslated": true
	},
	"Period (Max 31 Days)": {
		"text": "Okres (maks. 31 dni)",
		"isAlreadyTranslated": true
	},
	"Download list of requests": {
		"text": "Pobierz listę zgłoszeń",
		"isAlreadyTranslated": true
	},
	"Upload list of requests": {
		"text": "Przekaż listę zgłoszeń",
		"isAlreadyTranslated": true
	},
	"Sample format for uploading requests": {
		"text": "Przykładowy format przekazywania zgłoszeń",
		"isAlreadyTranslated": true
	},
	"There are no requests to be downloaded": {
		"text": "Nie ma zgłoszeń do pobrania",
		"isAlreadyTranslated": true
	},
	"Requests updated successfully": {
		"text": "Pomyślnie zaktualizowano zgłoszenia",
		"isAlreadyTranslated": true
	},
	"Cannot read this file": {
		"text": "Nie można odczytać tego pliku",
		"isAlreadyTranslated": true
	},
	"Please upload file with appropriate data": {
		"text": "Przekaż plik z odpowiednimi danymi",
		"isAlreadyTranslated": true
	},
	"Cancel Changes": {
		"text": "Anuluj zmiany",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to cancel changes you have made?": {
		"text": "Czy na pewno chcesz anulować wprowadzone zmiany?",
		"isAlreadyTranslated": true
	},
	"Add Retail Location": {
		"text": "Dodaj lokalizację sprzedaży detalicznej",
		"isAlreadyTranslated": true
	},
	"View Location": {
		"text": "Wyświetl lokalizację",
		"isAlreadyTranslated": true
	},
	"Enter Mobile number of the": {
		"text": "Wprowadź numer telefonu komórkowego należącego do",
		"isAlreadyTranslated": true
	},
	"whose rights you want to": {
		"text": "z prawami, które chcesz",
		"isAlreadyTranslated": true
	},
	"modify": {
		"text": "zmodyfikować",
		"isAlreadyTranslated": true
	},
	"Associated Shop": {
		"text": "Powiązany sklep",
		"isAlreadyTranslated": true
	},
	"choose shop to assign": {
		"text": "wybierz sklep do przypisania",
		"isAlreadyTranslated": true
	},
	"Branch Code": {
		"text": "Kod oddziału",
		"isAlreadyTranslated": true
	},
	"TOTAL SALES DETAILS": {
		"text": "SZCZEGÓŁY SPRZEDAŻY ŁĄCZNIE",
		"isAlreadyTranslated": true
	},
	"VERIFIED SALES DETAILS": {
		"text": "ZWERYFIKOWANE SZCZEGÓŁY SPRZEDAŻY",
		"isAlreadyTranslated": true
	},
	"UNVERIFIED SALES DETAILS": {
		"text": "NIEZWERYFIKOWANE SZCZEGÓŁY SPRZEDAŻY",
		"isAlreadyTranslated": true
	},
	"SHOP & ADVISOR DETAILS": {
		"text": "SZCZEGÓŁY SKLEPU I DORADCY",
		"isAlreadyTranslated": true
	},
	"Todays Attendance": {
		"text": "Dzisiejsza frekwencja",
		"isAlreadyTranslated": true
	},
	"Device Model List": {
		"text": "Lista modeli urządzeń",
		"isAlreadyTranslated": true
	},
	"Select Plans": {
		"text": "Wybierz plany",
		"isAlreadyTranslated": true
	},
	"No Plans Available": {
		"text": "Brak dostępnych planów",
		"isAlreadyTranslated": true
	},
	"Product details cannot be validated. Please choose from the device list.": {
		"text": "Nie można sprawdzić ważności szczegółów produktu. Wybierz z listy urządzeń.",
		"isAlreadyTranslated": true
	},
	"Device is eligible for multiple plans. Please choose a plan.": {
		"text": "Urządzenie kwalifikuje się do wielu planów. Wybierz plan.",
		"isAlreadyTranslated": true
	},
	"Device is eligible!": {
		"text": "Urządzenie kwalifikuje się!",
		"isAlreadyTranslated": true
	},
	"Invoice No.": {
		"text": "Nr faktury",
		"isAlreadyTranslated": true
	},
	"Invoice Amount": {
		"text": "Kwota faktury",
		"isAlreadyTranslated": true
	},
	"Sale Date": {
		"text": "Data sprzedaży",
		"isAlreadyTranslated": true
	},
	"Create": {
		"text": "Utwórz",
		"isAlreadyTranslated": true
	},
	"Active": {
		"text": "Aktywny",
		"isAlreadyTranslated": true
	},
	"Inactive": {
		"text": "Nieaktywny",
		"isAlreadyTranslated": true
	},
	"Pending": {
		"text": "Oczekiwanie",
		"isAlreadyTranslated": true
	},
	"Please select a location": {
		"text": "Wybierz lokalizację",
		"isAlreadyTranslated": true
	},
	"Content ID": {
		"text": "Identyfikator zawartości",
		"isAlreadyTranslated": true
	},
	"Last Modified": {
		"text": "Ostatnia modyfikacja",
		"isAlreadyTranslated": true
	},
	"You have no new requests.": {
		"text": "Nie masz nowych zgłoszeń.",
		"isAlreadyTranslated": true
	},
	"Detail View": {
		"text": "Widok szczegółowy",
		"isAlreadyTranslated": true
	},
	"Complete Analysis": {
		"text": "Pełna analiza",
		"isAlreadyTranslated": true
	},
	"Root Cause Analysis.": {
		"text": "Analiza głównej przyczyny.",
		"isAlreadyTranslated": true
	},
	"Solution Provided.": {
		"text": "Dostarczono rozwiązanie.",
		"isAlreadyTranslated": true
	},
	"Accessories will not be returned.": {
		"text": "Akcesoria nie zostaną zwrócone.",
		"isAlreadyTranslated": true
	},
	"Assign Engineer": {
		"text": "Przypisz inżyniera",
		"isAlreadyTranslated": true
	},
	"Update job details": {
		"text": "Zaktualizuj szczegóły zadania",
		"isAlreadyTranslated": true
	},
	"Update device details and then validate issues": {
		"text": "Zaktualizuj szczegóły urządzenia, a następnie sprawdź ważność problemów",
		"isAlreadyTranslated": true
	},
	"Update device details": {
		"text": "Zaktualizowano szczegóły urządzenia",
		"isAlreadyTranslated": true
	},
	"Receive payment & rate consumer": {
		"text": "Otrzymaj płatność i oceń konsumenta",
		"isAlreadyTranslated": true
	},
	"Update invoice details and then press confirm invoice": {
		"text": "Zaktualizuj dane do faktury, a następnie naciśnij opcję Potwierdź fakturę",
		"isAlreadyTranslated": true
	},
	"Do you want to accept this request?": {
		"text": "Czy chcesz zaakceptować to zgłoszenie?",
		"isAlreadyTranslated": true
	},
	"Visit Time": {
		"text": "Czas wizyty",
		"isAlreadyTranslated": true
	},
	"Filter by": {
		"text": "Filtruj wg",
		"isAlreadyTranslated": true
	},
	"Partner User": {
		"text": "Użytkownik partnerski",
		"isAlreadyTranslated": true
	},
	"Choose Partner User": {
		"text": "Wybierz użytkownika partnerskiego",
		"isAlreadyTranslated": true
	},
	"Invalid email id entered": {
		"text": "Wprowadzono nieprawidłowy adres e-mail",
		"isAlreadyTranslated": true
	},
	"No symptoms found": {
		"text": "Nie znaleziono objawów",
		"isAlreadyTranslated": true
	},
	"No faults found": {
		"text": "Nie znaleziono usterek",
		"isAlreadyTranslated": true
	},
	"No actions found": {
		"text": "Nie znaleziono działań",
		"isAlreadyTranslated": true
	},
	"Month": {
		"text": "Miesiąc",
		"isAlreadyTranslated": true
	},
	"Target Amount": {
		"text": "Kwota docelowa",
		"isAlreadyTranslated": true
	},
	"Total Target": {
		"text": "Docelowa suma",
		"isAlreadyTranslated": true
	},
	"Past targets cannot be edited.": {
		"text": "Nie można edytować wcześniejszych celów.",
		"isAlreadyTranslated": true
	},
	"Already Closed": {
		"text": "Już zamknięto",
		"isAlreadyTranslated": true
	},
	"No tasks Available": {
		"text": "Brak dostępnych zadań",
		"isAlreadyTranslated": true
	},
	"Close Task": {
		"text": "Zamknij zadanie",
		"isAlreadyTranslated": true
	},
	"Task Reference Id": {
		"text": "Identyfikator referencyjny zadania",
		"isAlreadyTranslated": true
	},
	"Edit Remark": {
		"text": "Edytuj uwagę",
		"isAlreadyTranslated": true
	},
	"Task Details of": {
		"text": "Szczegóły zadania",
		"isAlreadyTranslated": true
	},
	"Updated Date": {
		"text": "Data aktualizacji",
		"isAlreadyTranslated": true
	},
	"Closed Date": {
		"text": "Data zamknięcia",
		"isAlreadyTranslated": true
	},
	"Temp Consumer data": {
		"text": "Temp Consumer data",
		"isAlreadyTranslated": true
	},
	"No temp consumer data": {
		"text": "Brak tymczasowych danych konsumenta",
		"isAlreadyTranslated": true
	},
	"Repair completed and Inform customer": {
		"text": "Zakończono naprawę i poinformuj klienta",
		"isAlreadyTranslated": true
	},
	"Validate IMEI": {
		"text": "Sprawdź ważność numeru IMEI",
		"isAlreadyTranslated": true
	},
	"Invalid IMEI": {
		"text": "Nieprawidłowy numer IMEI",
		"isAlreadyTranslated": true
	},
	"Last Diagnosis Report": {
		"text": "Ostatni raport diagnostyczny",
		"isAlreadyTranslated": true
	},
	"No diagnosis report": {
		"text": "Brak raportu diagnostycznego",
		"isAlreadyTranslated": true
	},
	"Validate Device": {
		"text": "Sprawdź ważność urządzenia",
		"isAlreadyTranslated": true
	},
	"Enter G Number": {
		"text": "Wprowadź numer G",
		"isAlreadyTranslated": true
	},
	"Please enter valid the G number": {
		"text": "Wprowadź prawidłowy numer G",
		"isAlreadyTranslated": true
	},
	"No Such Brand": {
		"text": "Nie ma takiej marki",
		"isAlreadyTranslated": true
	},
	"Selected Brands": {
		"text": "Wybrane marki",
		"isAlreadyTranslated": true
	},
	"Remaining Brands": {
		"text": "Pozostałe marki",
		"isAlreadyTranslated": true
	},
	"Selected Partner": {
		"text": "Wybrany partner",
		"isAlreadyTranslated": true
	},
	"No Such Partner": {
		"text": "Nie ma takiego partnera",
		"isAlreadyTranslated": true
	},
	"CHOOSE PARTNER": {
		"text": "WYBIERZ PARTNERA",
		"isAlreadyTranslated": true
	},
	"Add Partner": {
		"text": "Dodaj partnera",
		"isAlreadyTranslated": true
	},
	"Remaining Partners": {
		"text": "Pozostali partnerzy",
		"isAlreadyTranslated": true
	},
	"Add Plan": {
		"text": "Dodaj plan",
		"isAlreadyTranslated": true
	},
	"CHOOSE PLAN": {
		"text": "WYBIERZ PLAN",
		"isAlreadyTranslated": true
	},
	"No Such Plan": {
		"text": "Nie ma takiego planu",
		"isAlreadyTranslated": true
	},
	"Selected Plan": {
		"text": "Wybrany plan",
		"isAlreadyTranslated": true
	},
	"Remaining Plan": {
		"text": "Pozostały plan",
		"isAlreadyTranslated": true
	},
	"CHOOSE PRODUCT": {
		"text": "WYBIERZ PRODUKT",
		"isAlreadyTranslated": true
	},
	"No Such Product Found": {
		"text": "Nie znaleziono takiego produktu",
		"isAlreadyTranslated": true
	},
	"Selected Products": {
		"text": "Wybrane produkty",
		"isAlreadyTranslated": true
	},
	"Remaining Products": {
		"text": "Pozostałe produkty",
		"isAlreadyTranslated": true
	},
	"Remaining Product Sub-Categories": {
		"text": "Pozostałe podkategorie produktów",
		"isAlreadyTranslated": true
	},
	"Selected Product Sub-Categories": {
		"text": "Wybrane podkategorie produktów",
		"isAlreadyTranslated": true
	},
	"No Such Product Sub-Category": {
		"text": "Nie ma takiej podkategorii produktów",
		"isAlreadyTranslated": true
	},
	"CHOOSE Product Sub-Category": {
		"text": "WYBIERZ podkategorię produktu",
		"isAlreadyTranslated": true
	},
	"Add Product Sub Category": {
		"text": "Dodaj podkategorię produktu",
		"isAlreadyTranslated": true
	},
	"Add Service Centers": {
		"text": "Dodaj centra serwisowe",
		"isAlreadyTranslated": true
	},
	"CHOOSE LOCATION": {
		"text": "WYBIERZ LOKALIZACJĘ",
		"isAlreadyTranslated": true
	},
	"No Such Service Location": {
		"text": "Nie ma takiej lokalizacji usług",
		"isAlreadyTranslated": true
	},
	"Selected Service Centers": {
		"text": "Wybrane centra serwisowe",
		"isAlreadyTranslated": true
	},
	"Remaining Service Centers": {
		"text": "Pozostałe centra serwisowe",
		"isAlreadyTranslated": true
	},
	"Add Service Type": {
		"text": "Dodaj typ usługi",
		"isAlreadyTranslated": true
	},
	"CHOOSE SERVICE TYPE": {
		"text": "WYBIERZ TYP USŁUGI",
		"isAlreadyTranslated": true
	},
	"No Such Service Type": {
		"text": "Nie ma takiego typu usługi",
		"isAlreadyTranslated": true
	},
	"Selected Service Type": {
		"text": "Wybrany typ usługi",
		"isAlreadyTranslated": true
	},
	"Remaining Service Types": {
		"text": "Pozostałe typy usług",
		"isAlreadyTranslated": true
	},
	"Add Store": {
		"text": "Dodaj magazyn",
		"isAlreadyTranslated": true
	},
	"CHOOSE STORE": {
		"text": "WYBIERZ MAGAZYN",
		"isAlreadyTranslated": true
	},
	"No Such Store": {
		"text": "Nie ma takiego magazynu",
		"isAlreadyTranslated": true
	},
	"Selected Store": {
		"text": "Wybrany magazyn",
		"isAlreadyTranslated": true
	},
	"Remaining Stores": {
		"text": "Pozostałe magazyny",
		"isAlreadyTranslated": true
	},
	"Back to all users": {
		"text": "Wróć do wszystkich użytkowników",
		"isAlreadyTranslated": true
	},
	"Save Rights": {
		"text": "Zapisz prawa",
		"isAlreadyTranslated": true
	},
	"This Email Address has already taken, please enter a new email address.": {
		"text": "Ten adres e-mail jest już używany, wprowadź nowy adres e-mail.",
		"isAlreadyTranslated": true
	},
	"Enter Email Address of the user whose rights you want to add/modify.": {
		"text": "Wprowadź adres e-mail użytkownika, którego prawa chcesz dodać/zmodyfikować.",
		"isAlreadyTranslated": true
	},
	"Enter Theme Name": {
		"text": "Wprowadź nazwę motywu",
		"isAlreadyTranslated": true
	},
	"Add/Update Country Code": {
		"text": "Dodaj/zaktualizuj kod kraju",
		"isAlreadyTranslated": true
	},
	"Add/Update Language Code": {
		"text": "Dodaj/zaktualizuj kod języka",
		"isAlreadyTranslated": true
	},
	"Modules": {
		"text": "Moduły",
		"isAlreadyTranslated": true
	},
	"Tracking Details": {
		"text": "Szczegóły śledzenia",
		"isAlreadyTranslated": true
	},
	"Engineer Details": {
		"text": "Szczegóły inżyniera",
		"isAlreadyTranslated": true
	},
	"Number of assigned jobs": {
		"text": "Liczba przypisanych zadań",
		"isAlreadyTranslated": true
	},
	"Attendance": {
		"text": "Frekwencja",
		"isAlreadyTranslated": true
	},
	"App Status": {
		"text": "Stan aplikacji",
		"isAlreadyTranslated": true
	},
	"Current Latitude": {
		"text": "Aktualna szerokość geograficzna",
		"isAlreadyTranslated": true
	},
	"Current Longitude": {
		"text": "Aktualna długość geograficzna",
		"isAlreadyTranslated": true
	},
	"Current Pincode": {
		"text": "Aktualny kod PIN",
		"isAlreadyTranslated": true
	},
	"too much data to track": {
		"text": "za dużo danych do śledzenia",
		"isAlreadyTranslated": true
	},
	"No data to track": {
		"text": "Brak danych do śledzenia",
		"isAlreadyTranslated": true
	},
	"Tab Type": {
		"text": "Typ karty",
		"isAlreadyTranslated": true
	},
	"Tab Title": {
		"text": "Tytuł karty",
		"isAlreadyTranslated": true
	},
	"Content Title": {
		"text": "Tytuł treści",
		"isAlreadyTranslated": true
	},
	"Paragraphs": {
		"text": "Akapity",
		"isAlreadyTranslated": true
	},
	"List": {
		"text": "Lista",
		"isAlreadyTranslated": true
	},
	"Add rejection reason": {
		"text": "Dodaj powód odrzucenia",
		"isAlreadyTranslated": true
	},
	"Please fill in the rejection reason before rejecting": {
		"text": "Wypełnij powód odrzucenia przed odrzuceniem",
		"isAlreadyTranslated": true
	},
	"Purchased": {
		"text": "Zakupiono",
		"isAlreadyTranslated": true
	},
	"Activated": {
		"text": "Aktywowano",
		"isAlreadyTranslated": true
	},
	"Registered": {
		"text": "Zarejestrowano",
		"isAlreadyTranslated": true
	},
	"Rejected": {
		"text": "Odrzucono",
		"isAlreadyTranslated": true
	},
	"No such status found": {
		"text": "Nie znaleziono takiego stanu",
		"isAlreadyTranslated": true
	},
	"Plan Expiry Date": {
		"text": "Data wygaśnięcia planu",
		"isAlreadyTranslated": true
	},
	"Plan Activation Date": {
		"text": "Data aktywacji planu",
		"isAlreadyTranslated": true
	},
	"Report is empty.": {
		"text": "Raport jest pusty.",
		"isAlreadyTranslated": true
	},
	"Upload Bulk Plan": {
		"text": "Przekaż plan zbiorczy",
		"isAlreadyTranslated": true
	},
	"Please download the sample file, make changes in it only and upload it": {
		"text": "Pobierz plik przykładowy, tylko wprowadź w nim zmiany i przekaż go",
		"isAlreadyTranslated": true
	},
	"File Uploaded Successfully.": {
		"text": "Plik został pomyślnie przekazany.",
		"isAlreadyTranslated": true
	},
	"File Uploaded Successfully with errors at following Row Number": {
		"text": "Plik przesłany pomyślnie przekazany z błędami w wierszu o następującym numerze",
		"isAlreadyTranslated": true
	},
	"Bulk Plan Sales Upload": {
		"text": "Przekazanie zbiorczego planu sprzedaży",
		"isAlreadyTranslated": true
	},
	"Uploaded successfully": {
		"text": "Pomyślnie przekazano",
		"isAlreadyTranslated": true
	},
	"Visit Start Time": {
		"text": "Godzina rozpoczęcia wizyty",
		"isAlreadyTranslated": true
	},
	"Visit End Time": {
		"text": "Godzina zakończenia wizyty",
		"isAlreadyTranslated": true
	},
	"Engineer Name": {
		"text": "Imię i nazwisko inżyniera",
		"isAlreadyTranslated": true
	},
	"Visit Status": {
		"text": "Stan wizyty",
		"isAlreadyTranslated": true
	},
	"Remarks": {
		"text": "Uwagi",
		"isAlreadyTranslated": true
	},
	"There are no visits for this request.": {
		"text": "Brak wizyt dla tego zgłoszenia.",
		"isAlreadyTranslated": true
	},
	"Appointment Date": {
		"text": "Data spotkania",
		"isAlreadyTranslated": true
	},
	"Actual Visit Date": {
		"text": "Rzeczywista data wizyty",
		"isAlreadyTranslated": true
	},
	"Appointment Time Slot": {
		"text": "Przedział czasu spotkania",
		"isAlreadyTranslated": true
	},
	"Actual Visit Time": {
		"text": "Rzeczywisty czas wizyty",
		"isAlreadyTranslated": true
	},
	"Technician Name": {
		"text": "Imię i nazwisko technika",
		"isAlreadyTranslated": true
	},
	"Observation": {
		"text": "Obserwacja",
		"isAlreadyTranslated": true
	},
	"Please follow below steps to Update Activation Token for your linked GSX User ID": {
		"text": "Wykonaj poniższe czynności, aby zaktualizować token aktywacyjny dla połączonego identyfikatora użytkownika GSX",
		"isAlreadyTranslated": true
	},
	"Click on the below link to navigate to the Apple Activation Token Site": {
		"text": "Kliknij poniższe łącze, aby nawigować do witryny Apple Activation Token",
		"isAlreadyTranslated": true
	},
	"Use GSX ID and Password to login. Post login, you will be able to view you Activation Token": {
		"text": "Użyj identyfikatora GSX i hasła, aby się zalogować. Po zalogowaniu będzie można zobaczyć token aktywacyjny",
		"isAlreadyTranslated": true
	},
	"Note: Generate the Activation Token for GSX ID mapped to your Servify ID only. GSX ID mapped to your Servify User ID is GSX Email ID": {
		"text": "Uwaga: Wygeneruj token aktywacyjny dla identyfikatora GSX odwzorowanego tylko na identyfikator Servify. Identyfikatorem GSX odwzorowanym na identyfikator użytkownika Servify jest adres e-mail GSX",
		"isAlreadyTranslated": true
	},
	"GSX Tech ID Mapped": {
		"text": "Odwzorowany identyfikator techniczny GSX",
		"isAlreadyTranslated": true
	},
	'Use the "Copy to Clipboard" button to copy the Activation key': {
		"text": "Użyj przycisku „Kopiuj do schowka”, aby skopiować klucz aktywacyjny",
		"isAlreadyTranslated": true
	},
	'Note: Use the "Copy to Clipboard" button only. Do NOT select manually to copy Activation Token. You are advised NOT to sign out from Apple Activation Token Site before Activation Token is saved in Servify CRM': {
		"text": "Uwaga: Użyj tylko przycisku „Kopiuj do schowka”. NIE wybieraj ręcznie kopiowania tokenu aktywacyjnego. Zaleca się NIE wylogowywać się z witryny Apple Activation Token, zanim token aktywacyjny zostanie zapisany w systemie Servify CRM",
		"isAlreadyTranslated": true
	},
	"Paste the Activation Token in the text box below & click Save.": {
		"text": "Wklej token aktywacyjny w poniższym polu tekstowym i kliknij przycisk Zapisz.",
		"isAlreadyTranslated": true
	},
	"Normal Repair": {
		"text": "Normalna naprawa",
		"isAlreadyTranslated": true
	},
	"Check whitelisting": {
		"text": "Sprawdź białą listę",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to receive device.": {
		"text": "Oczekiwanie, aż kierownik ds. obsługi klienta otrzyma urządzenie.",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, click on Non Hardware Closure for soft,ware repair or use Provisional Estimate to give a repair estimate to customer.": {
		"text": "Kierownik ds. obsługi klienta może uruchomić diagnostykę, przeprowadzić inspekcję urządzenia, aby zarejestrować naprawę sprzętu, kliknąć opcję Zamknięcie niesprzętowe, aby przeprowadzić naprawę oprogramowania, lub użyć prowizorycznego oszacowania, aby podać klientowi kosztorys naprawy.",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, click on Non Hardware Closure for software repair or use Provisional Estimate to give a repair estimate to customer.": {
		"text": "Kierownik ds. obsługi klienta może uruchomić diagnostykę, przeprowadzić inspekcję urządzenia, aby zarejestrować naprawę sprzętu, kliknąć opcję Zamknięcie niesprzętowe, aby przeprowadzić naprawę oprogramowania, lub użyć prowizorycznego oszacowania, aby podać klientowi kosztorys naprawy.",
		"isAlreadyTranslated": true
	},
	"Repair has been completed.": {
		"text": "Naprawa została zakończona.",
		"isAlreadyTranslated": true
	},
	"Service invoice has been generated.": {
		"text": "Wygenerowano fakturę za usługę.",
		"isAlreadyTranslated": true
	},
	"Return Order Number": {
		"text": "Numer zgłoszenia zwrotu",
		"isAlreadyTranslated": true
	},
	"Fulfiller Name": {
		"text": "Imię i nazwisko osoby wypełniającej",
		"isAlreadyTranslated": true
	},
	"Inspect Device": {
		"text": "Sprawdź urządzenie",
		"isAlreadyTranslated": true
	},
	"Inbound": {
		"text": "Przychodzące",
		"isAlreadyTranslated": true
	},
	"Outbound": {
		"text": "Wychodzące",
		"isAlreadyTranslated": true
	},
	"Please enter the mandatory field.": {
		"text": "Wprowadź obowiązkowe pole.",
		"isAlreadyTranslated": true
	},
	"This is a mandatory field": {
		"text": "To pole jest obowiązkowe",
		"isAlreadyTranslated": true
	},
	"Please enter steps in less than 1500 characters": {
		"text": "Wprowadź kroki, używając mniej niż 1500 znaków",
		"isAlreadyTranslated": true
	},
	"Please enter notes in less than 1000 characters": {
		"text": "Wprowadź uwagi, używając mniej niż 1000 znaków",
		"isAlreadyTranslated": true
	},
	"/1500 characters entered.": {
		"text": "/1500 — liczba wprowadzonych znaków.",
		"isAlreadyTranslated": true
	},
	"/500 characters entered.": {
		"text": "/500 — liczba wprowadzonych znaków.",
		"isAlreadyTranslated": true
	},
	"/1000 characters entered.": {
		"text": "/1000 — liczba wprowadzonych znaków.",
		"isAlreadyTranslated": true
	},
	'Waiting for Engineer to "Validate Issues" and "Add Faults" in "Issues Reported" section below.': {
		"text": "Oczekiwanie, aż inżynier użyje funkcji „Sprawdź ważność problemów” i „Dodaj usterki” w poniższej sekcji „Zgłoszone problemy”.",
		"isAlreadyTranslated": true
	},
	'Waiting for Engineer to "Select Symptom And Action" in "Report Issues to GSX" section below.': {
		"text": "Oczekiwanie, aż inżynier użyje funkcji „Wybierz objaw i działanie” w poniższej sekcji „Zgłoś problemy do GSX”.",
		"isAlreadyTranslated": true
	},
	"Please select GSX repair type": {
		"text": "Wybierz typ naprawy GSX",
		"isAlreadyTranslated": true
	},
	"Proceed with creating GSX repair": {
		"text": "Kontynuuj tworzenie naprawy GSX",
		"isAlreadyTranslated": true
	},
	"Please add part to Proceed with creating GSX repair": {
		"text": "Dodaj część w sekcji Kontynuuj tworzenie naprawy GSX",
		"isAlreadyTranslated": true
	},
	"Waiting for Engineer to create GSX repair": {
		"text": "Oczekiwanie, aż inżynier utworzy naprawę GSX",
		"isAlreadyTranslated": true
	},
	"Component Code": {
		"text": "Kod składnika",
		"isAlreadyTranslated": true
	},
	"Pricing Option": {
		"text": "Opcja cenowa",
		"isAlreadyTranslated": true
	},
	"Coverage Option": {
		"text": "Opcja pokrycia",
		"isAlreadyTranslated": true
	},
	"Please select a COMPTIA group from dropdown.": {
		"text": "Please select a COMPTIA group from dropdown.",
		"isAlreadyTranslated": true
	},
	"Please select a COMPTIA Code from dropdown.": {
		"text": "Wybierz kod COMPTIA z listy rozwijanej.",
		"isAlreadyTranslated": true
	},
	"Please select a Component Code from dropdown.": {
		"text": "Wybierz kod składnika z listy rozwijanej.",
		"isAlreadyTranslated": true
	},
	"Please select a Issue Code from dropdown.": {
		"text": "Wybierz kod problemu z listy rozwijanej.",
		"isAlreadyTranslated": true
	},
	"Please enter the serial number of the part": {
		"text": "Wprowadź numer seryjny części",
		"isAlreadyTranslated": true
	},
	"Please enter correct KBB serial number of the part": {
		"text": "Wprowadź poprawny numer seryjny KBB części",
		"isAlreadyTranslated": true
	},
	"Please select a Covergage Option Code from dropdown.": {
		"text": "Wybierz kod opcji pokrycia z listy rozwijanej.",
		"isAlreadyTranslated": true
	},
	"Please select a Coverage Option Code from dropdown.": {
		"text": "Wybierz kod opcji pokrycia z listy rozwijanej.",
		"isAlreadyTranslated": true
	},
	"Serial Number is already added": {
		"text": "Numer seryjny został już dodany",
		"isAlreadyTranslated": true
	},
	"Charge": {
		"text": "Opłata",
		"isAlreadyTranslated": true
	},
	"Charge Name": {
		"text": "Nazwa opłaty",
		"isAlreadyTranslated": true
	},
	"Charge Value": {
		"text": "Wartość opłaty",
		"isAlreadyTranslated": true
	},
	"Tax": {
		"text": "Podatek",
		"isAlreadyTranslated": true
	},
	"MRP": {
		"text": "Maksymalna cena detaliczna",
		"isAlreadyTranslated": true
	},
	"Final Price": {
		"text": "Cena ostateczna",
		"isAlreadyTranslated": true
	},
	"Add Discount": {
		"text": "Dodaj rabat",
		"isAlreadyTranslated": true
	},
	"Good Part Serial No": {
		"text": "Numer seryjny dobrej części",
		"isAlreadyTranslated": true
	},
	"Bad Part Serial No": {
		"text": "Numer seryjny złej części",
		"isAlreadyTranslated": true
	},
	"Create GSX Repair": {
		"text": "Utwórz naprawę GSX",
		"isAlreadyTranslated": true
	},
	"Fetch Parts From GSX": {
		"text": "Pobierz części z GSX",
		"isAlreadyTranslated": true
	},
	"Fetch Latest Diagnostics Results": {
		"text": "Pobierz ostatnie wyniki diagnostyki",
		"isAlreadyTranslated": true
	},
	"If you want to update Repair Type. Please remove parts.": {
		"text": "Jeśli chcesz zaktualizować typ naprawy. Usuń części.",
		"isAlreadyTranslated": true
	},
	"Requested": {
		"text": "Zażądano",
		"isAlreadyTranslated": true
	},
	"customerCare": {
		"text": "customerCare",
		"isAlreadyTranslated": true
	},
	"CustomerCare": {
		"text": "CustomerCare",
		"isAlreadyTranslated": true
	},
	"View Delivery challan": {
		"text": "Wyświetl dokument dostawy",
		"isAlreadyTranslated": true
	},
	"Register for Bulk Return": {
		"text": "Zarejestruj do zwrotu zbiorczego",
		"isAlreadyTranslated": true
	},
	"View Packing List": {
		"text": "Wyświetl listę pakowania",
		"isAlreadyTranslated": true
	},
	"Dispatch": {
		"text": "Wyślij",
		"isAlreadyTranslated": true
	},
	"View Depot Shipper Label": {
		"text": "Wyświetl etykietę spedytora magazynu",
		"isAlreadyTranslated": true
	},
	"View Return Label": {
		"text": "Wyświetl etykietę zwrotną",
		"isAlreadyTranslated": true
	},
	"Carrier": {
		"text": "Przewoźnik",
		"isAlreadyTranslated": true
	},
	"Add Details": {
		"text": "Dodaj szczegóły",
		"isAlreadyTranslated": true
	},
	"Shipper": {
		"text": "Spedytor",
		"isAlreadyTranslated": true
	},
	"Parts handover note": {
		"text": "Informacja o przekazaniu części",
		"isAlreadyTranslated": true
	},
	"Signature": {
		"text": "Podpis",
		"isAlreadyTranslated": true
	},
	"Dispatch Mail In Devices": {
		"text": "Wyślij pocztę w urządzeniach",
		"isAlreadyTranslated": true
	},
	"Bulk Return ID": {
		"text": "Identyfikator zwrotu zbiorczego",
		"isAlreadyTranslated": true
	},
	"Custom Notes": {
		"text": "Notatki niestandardowe",
		"isAlreadyTranslated": true
	},
	"Receive device from RC": {
		"text": "Otrzymaj urządzenie z RC",
		"isAlreadyTranslated": true
	},
	"Quotation document": {
		"text": "Dokument wyceny",
		"isAlreadyTranslated": true
	},
	"Estimate Generated By": {
		"text": "Kosztorys wygenerowany przez",
		"isAlreadyTranslated": true
	},
	"Sl. No": {
		"text": "Nr ser. Nie",
		"isAlreadyTranslated": true
	},
	"Rate": {
		"text": "Oceń",
		"isAlreadyTranslated": true
	},
	"Taxable value": {
		"text": "Wartość podlegająca opodatkowaniu",
		"isAlreadyTranslated": true
	},
	"Total Tax": {
		"text": "Suma podatku",
		"isAlreadyTranslated": true
	},
	"Service Estimate Total": {
		"text": "Suma kosztorysu usługi",
		"isAlreadyTranslated": true
	},
	"In Words": {
		"text": "Słownie",
		"isAlreadyTranslated": true
	},
	"Disclaimers": {
		"text": "Zastrzeżenia",
		"isAlreadyTranslated": true
	},
	"The charges mentioned in this Estimate are an approximation and is based on the anticipated details of the work to be carried out basis the inspection. It is possible for unexpected situations / complications to cause some deviation from this Estimate. If additional parts or labour are required you will be contacted immediately and upon your concurrence to carry out the details of the work, a new Estimate will be created for your consideration and approval.": {
		"text": "Opłaty wymienione w niniejszym kosztorysie są szacunkowe i opierają się na przewidywanych szczegółach prac, które mają być wykonane na podstawie inspekcji. Możliwe jest, że nieoczekiwane sytuacje/komplikacje spowodują pewne odchylenia od tego kosztorysu. W razie potrzeby użycia dodatkowych części lub nakładów pracy skontaktujemy się z Tobą natychmiast, a jeśli zgodzisz się na przeprowadzenie szczegółów pracy, nowy kosztorys zostanie utworzony do rozpatrzenia i zatwierdzenie przez Ciebie.",
		"isAlreadyTranslated": true
	},
	"Authorised Signatory": {
		"text": "Upoważniony sygnatariusz",
		"isAlreadyTranslated": true
	},
	"Consumed": {
		"text": "Zużyto",
		"isAlreadyTranslated": true
	},
	"DOA": {
		"text": "DOA",
		"isAlreadyTranslated": true
	},
	"Receive": {
		"text": "Otrzymaj",
		"isAlreadyTranslated": true
	},
	"Return": {
		"text": "Zwróć",
		"isAlreadyTranslated": true
	},
	"Return to Customer": {
		"text": "Zwrot do klienta",
		"isAlreadyTranslated": true
	},
	"Repair completed and Intimate customer": {
		"text": "Zakończono naprawę, poinformuj klienta",
		"isAlreadyTranslated": true
	},
	"QC rejected, Work in progress": {
		"text": "Odrzucono kontrolę jakości, praca w toku",
		"isAlreadyTranslated": true
	},
	"Repair completed, Inform customer": {
		"text": "Zakończono naprawę, poinformuj klienta",
		"isAlreadyTranslated": true
	},
	"Inspection in Progress": {
		"text": "Inspekcja w toku",
		"isAlreadyTranslated": true
	},
	"Unused": {
		"text": "Nie używano",
		"isAlreadyTranslated": true
	},
	"Please select Tier part": {
		"text": "Wybierz część poziomu",
		"isAlreadyTranslated": true
	},
	"Initiate AST 2 Diagnostics": {
		"text": "Initiate AST 2 Diagnostics",
		"isAlreadyTranslated": true
	},
	"Please add document": {
		"text": "Dodaj dokument",
		"isAlreadyTranslated": true
	},
	"Non Hardware Closure": {
		"text": "Zamknięcie niesprzętowe",
		"isAlreadyTranslated": true
	},
	"Select Repair Outcomes": {
		"text": "Wybierz wyniki naprawy",
		"isAlreadyTranslated": true
	},
	"User can select upto 4 outcomes": {
		"text": "Użytkownik może wybrać do 4 wyników",
		"isAlreadyTranslated": true
	},
	"Create NTF Repair": {
		"text": "Utwórz naprawę NTF",
		"isAlreadyTranslated": true
	},
	"Delivery Notes": {
		"text": "Uwagi dotyczące dostawy",
		"isAlreadyTranslated": true
	},
	"Notes will be displayed on Customer’s Delivery Report": {
		"text": "Notatki zostaną wyświetlone w raporcie dostawy dla klienta",
		"isAlreadyTranslated": true
	},
	"Cancel": {
		"text": "Anuluj",
		"isAlreadyTranslated": true
	},
	"Save": {
		"text": "Zapisz",
		"isAlreadyTranslated": true
	},
	"Provisional Estimate": {
		"text": "Tymczasowe oszacowanie",
		"isAlreadyTranslated": true
	},
	"Price": {
		"text": "Cena",
		"isAlreadyTranslated": true
	},
	"Total Provisional Estimate": {
		"text": "Tymczasowe oszacowanie łącznie",
		"isAlreadyTranslated": true
	},
	"Send Email to Customer": {
		"text": "Wyślij e-mail do klienta",
		"isAlreadyTranslated": true
	},
	"Add New Billing Detail": {
		"text": "Dodaj nowe szczegóły rachunku",
		"isAlreadyTranslated": true
	},
	"No Billing Detail found": {
		"text": "Nie znaleziono szczegółów rozliczeniowych",
		"isAlreadyTranslated": true
	},
	"Select Billing Detail": {
		"text": "Wybierz szczegóły rozliczeniowe",
		"isAlreadyTranslated": true
	},
	"Personal": {
		"text": "Osobisty",
		"isAlreadyTranslated": true
	},
	"Company": {
		"text": "Firma",
		"isAlreadyTranslated": true
	},
	"Registered Name": {
		"text": "Zarejestrowana nazwa",
		"isAlreadyTranslated": true
	},
	"Company Name": {
		"text": "Nazwa firmy",
		"isAlreadyTranslated": true
	},
	"Why Generate Activation token": {
		"text": "Po co generować token aktywacyjny",
		"isAlreadyTranslated": true
	},
	"Activation Token is adds an additional layer of security while fetching information from GSX. This additional parameter of security has been added by Apple for accessing information using API. For more information, you can get in touch with Servify Administrator.": {
		"text": "Activation Token is adds an additional layer of security while fetching information from GSX. This additional parameter of security has been added by Apple for accessing information using API. For more information, you can get in touch with Servify Administrator.",
		"isAlreadyTranslated": true
	},
	"Repair Type Description": {
		"text": "Opis typu naprawy",
		"isAlreadyTranslated": true
	},
	"Non hardware closure details updated": {
		"text": "Zaktualizowano szczegóły zamknięcia niesprzętowego",
		"isAlreadyTranslated": true
	},
	"Provisional estimate updated": {
		"text": "Zaktualizowano tymczasowe oszacowanie",
		"isAlreadyTranslated": true
	},
	"Provisional estimate generated": {
		"text": "Wygenerowano tymczasowe oszacowanie",
		"isAlreadyTranslated": true
	},
	"Device inspected": {
		"text": "Przeprowadzono inspekcję urządzenia",
		"isAlreadyTranslated": true
	},
	"Comptia Modifier": {
		"text": "Modyfikator COMPTIA",
		"isAlreadyTranslated": true
	},
	"Enter File Name": {
		"text": "Wprowadź nazwę pliku",
		"isAlreadyTranslated": true
	},
	"Run Diagnosis": {
		"text": "Uruchom diagnostykę",
		"isAlreadyTranslated": true
	},
	"Skip diagnosis": {
		"text": "Pomiń diagnostykę",
		"isAlreadyTranslated": true
	},
	"Inspect Device": {
		"text": "Sprawdź urządzenie",
		"isAlreadyTranslated": true
	},
	"Battery usage": {
		"text": "Zużycie baterii",
		"isAlreadyTranslated": true
	},
	"Call performance": {
		"text": "Wydajność połączeń",
		"isAlreadyTranslated": true
	},
	"Mobile resource Operator": {
		"text": "Operator zasobów mobilnych",
		"isAlreadyTranslated": true
	},
	"Loaner diagnostics": {
		"text": "Diagnostyka urządzenia zastępczego",
		"isAlreadyTranslated": true
	},
	"Alternative Mobile No": {
		"text": "Inny nr telefonu komórkowego",
		"isAlreadyTranslated": true
	},
	"Logistics Report": {
		"text": "Raport logistyczny",
		"isAlreadyTranslated": true
	},
	"Select Report Type": {
		"text": "Wybierz typ raportu",
		"isAlreadyTranslated": true
	},
	"Filter period": {
		"text": "Filtruj okres",
		"isAlreadyTranslated": true
	},
	"Service": {
		"text": "Usługa",
		"isAlreadyTranslated": true
	},
	"Download report": {
		"text": "Pobierz raport",
		"isAlreadyTranslated": true
	},
	"Parts": {
		"text": "Części",
		"isAlreadyTranslated": true
	},
	"Select a Type": {
		"text": "Wybierz typ",
		"isAlreadyTranslated": true
	},
	"Filter Report": {
		"text": "Filtruj raport",
		"isAlreadyTranslated": true
	},
	"FILTER PERIOD": {
		"text": "FILTRUJ OKRES",
		"isAlreadyTranslated": true
	},
	"No data found": {
		"text": "Nie znaleziono danych",
		"isAlreadyTranslated": true
	},
	"Apple plans": {
		"text": "Plany Apple",
		"isAlreadyTranslated": true
	},
	"Know more": {
		"text": "Więcej",
		"isAlreadyTranslated": true
	},
	"Know More": {
		"text": "OWU",
		"isAlreadyTranslated": true
	},
	"Failure": {
		"text": "Niepowodzenie",
		"isAlreadyTranslated": true
	},
	"Pending": {
		"text": "Oczekiwanie",
		"isAlreadyTranslated": true
	},
	"Sell": {
		"text": "Sprzedaj",
		"isAlreadyTranslated": true
	},
	"Transaction Status": {
		"text": "Stan transakcji",
		"isAlreadyTranslated": true
	},
	"Period": {
		"text": "Okres",
		"isAlreadyTranslated": true
	},
	"Completed": {
		"text": "Zakończono",
		"isAlreadyTranslated": true
	},
	"completed": {
		"text": "zakończono",
		"isAlreadyTranslated": true
	},
	"Filter": {
		"text": "Filtr",
		"isAlreadyTranslated": true
	},
	"Total Count": {
		"text": "Łączna liczba",
		"isAlreadyTranslated": true
	},
	"Total Value": {
		"text": "Całkowita wartość",
		"isAlreadyTranslated": true
	},
	"Failed": {
		"text": "Niepowodzenie",
		"isAlreadyTranslated": true
	},
	"Brands": {
		"text": "Marki",
		"isAlreadyTranslated": true
	},
	"Products": {
		"text": "Produkty",
		"isAlreadyTranslated": true
	},
	"Task Type": {
		"text": "Typ zadania",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer assignment": {
		"text": "Oczekiwanie na przypisanie inżyniera",
		"isAlreadyTranslated": true
	},
	"Provisional Charges Added/Updated": {
		"text": "Dodano/zaktualizowano tymczasowe opłaty",
		"isAlreadyTranslated": true
	},
	"Part Provisional Charges Removed": {
		"text": "Usunięto tymczasowe opłaty za części",
		"isAlreadyTranslated": true
	},
	"Device handed over by CustomerCare to ServicelocationEngineer": {
		"text": "Urządzenie przekazane przez CustomerCare do ServicelocationEngineer",
		"isAlreadyTranslated": true
	},
	"Device accepted by ServicelocationEngineer from CustomerCare": {
		"text": "Urządzenie przekazane przez ServicelocationEngineer z CustomerCare",
		"isAlreadyTranslated": true
	},
	"Select GSX Repair Type": {
		"text": "Wybierz typ naprawy GSX",
		"isAlreadyTranslated": true
	},
	"Remove": {
		"text": "Usuń",
		"isAlreadyTranslated": true
	},
	"View Provisional Estimate Document": {
		"text": "Wyświetl dokument tymczasowego oszacowania",
		"isAlreadyTranslated": true
	},
	"View Estimation Document": {
		"text": "Wyświetl dokument wyceny",
		"isAlreadyTranslated": true
	},
	"Please Fill Payment Mode Details.": {
		"text": "Wypełnij szczegóły trybu płatności.",
		"isAlreadyTranslated": true
	},
	"Please Enter Amount.": {
		"text": "Wprowadź kwotę.",
		"isAlreadyTranslated": true
	},
	"Additional COMPTIA codes": {
		"text": "Dodatkowe kody COMPTIA",
		"isAlreadyTranslated": true
	},
	"Please enter correct": {
		"text": "Wprowadź poprawnie ",
		"isAlreadyTranslated": true
	},
	"Please Enter Correct": {
		"text": "Wprowadź poprawnie ",
		"isAlreadyTranslated": true
	},
	"Out of warranty": {
		"text": "Poza gwarancją",
		"isAlreadyTranslated": true
	},
	"Advance Collected": {
		"text": "Pobrano zaliczkę",
		"isAlreadyTranslated": true
	},
	"Enter Serial number": {
		"text": "Wprowadź numer seryjny",
		"isAlreadyTranslated": true
	},
	"Enter the Serial Number": {
		"text": "Wprowadź numer seryjny",
		"isAlreadyTranslated": true
	},
	"Enter the IMEI/Serial Number": {
		"text": "Wprowadź numer IMEI/seryjny",
		"isAlreadyTranslated": true
	},
	"Reject GSX Repair": {
		"text": "Odrzuć naprawę GSX",
		"isAlreadyTranslated": true
	},
	"Inventory manager": {
		"text": "Kierownik inwentaryzacji",
		"isAlreadyTranslated": true
	},
	"Ordered": {
		"text": "Zamówiono",
		"isAlreadyTranslated": true
	},
	"Issued": {
		"text": "Zgłoszono",
		"isAlreadyTranslated": true
	},
	"Received": {
		"text": "Otrzymano",
		"isAlreadyTranslated": true
	},
	"Consumed": {
		"text": "Zużyto",
		"isAlreadyTranslated": true
	},
	"Defective": {
		"text": "Wadliwe",
		"isAlreadyTranslated": true
	},
	"Handover": {
		"text": "Przekazanie",
		"isAlreadyTranslated": true
	},
	"handover": {
		"text": "przekazanie",
		"isAlreadyTranslated": true
	},
	"ordered": {
		"text": "zamówiono",
		"isAlreadyTranslated": true
	},
	"issued": {
		"text": "zgłoszono",
		"isAlreadyTranslated": true
	},
	"received": {
		"text": "otrzymano",
		"isAlreadyTranslated": true
	},
	"consumed": {
		"text": "zużyto",
		"isAlreadyTranslated": true
	},
	"defective": {
		"text": "wadliwe",
		"isAlreadyTranslated": true
	},
	"View Delivery Report": {
		"text": "Wyświetl raport dostawy",
		"isAlreadyTranslated": true
	},
	"Warranty Validated from Brand": {
		"text": "Gwarancja zatwierdzona przez markę",
		"isAlreadyTranslated": true
	},
	"Please enter the reason for warranty applicable": {
		"text": "Wprowadź powód zastosowania gwarancji",
		"isAlreadyTranslated": true
	},
	"Purchase cost can not be zero": {
		"text": "Koszt zakupu nie może wynosić zero",
		"isAlreadyTranslated": true
	},
	"Please add tag": {
		"text": "Dodaj znacznik",
		"isAlreadyTranslated": true
	},
	"File size should not be greater than 10 MB": {
		"text": "Rozmiar pliku nie powinien być większy niż 10 MB",
		"isAlreadyTranslated": true
	},
	"Please enter values in all the fields": {
		"text": "Wprowadź wartości we wszystkich polach",
		"isAlreadyTranslated": true
	},
	"Full box unit cannot be added if another part is already added": {
		"text": "Nie można dodać pełnej jednostki opakowania, jeśli jest już dodana inna część",
		"isAlreadyTranslated": true
	},
	"Full box unit is already added.": {
		"text": "Dodano już pełną jednostkę opakowania.",
		"isAlreadyTranslated": true
	},
	"Part price is not updated for the Selected part": {
		"text": "Cena części nie została zaktualizowana dla wybranej części",
		"isAlreadyTranslated": true
	},
	"Please contact Admin": {
		"text": "Skontaktuj się z administratorem",
		"isAlreadyTranslated": true
	},
	"Repair Completion Type": {
		"text": "Typ zakończenia naprawy",
		"isAlreadyTranslated": true
	},
	"No Parts Requested": {
		"text": "Brak zgłoszeń dotyczących części",
		"isAlreadyTranslated": true
	},
	"NA": {
		"text": "ND.",
		"isAlreadyTranslated": true
	},
	"Out Of Warranty (No Coverage)": {
		"text": "Poza gwarancją (brak pokrycia)",
		"isAlreadyTranslated": true
	},
	"Number": {
		"text": "Numer",
		"isAlreadyTranslated": true
	},
	"Workshop Engineer": {
		"text": "Inżynier warsztatu",
		"isAlreadyTranslated": true
	},
	"Front Image": {
		"text": "Zdjęcie z przodu",
		"isAlreadyTranslated": true
	},
	"Top Image": {
		"text": "Zdjęcie z góry",
		"isAlreadyTranslated": true
	},
	"Bottom Image": {
		"text": "Zdjęcie z dołu",
		"isAlreadyTranslated": true
	},
	"Left Image": {
		"text": "Zdjęcie z lewej",
		"isAlreadyTranslated": true
	},
	"Right Image": {
		"text": "Zdjęcie z prawej",
		"isAlreadyTranslated": true
	},
	"Device Invoice": {
		"text": "Faktura za urządzenie",
		"isAlreadyTranslated": true
	},
	"Other": {
		"text": "Inne",
		"isAlreadyTranslated": true
	},
	"Cannot add more than 4 outcomes": {
		"text": "Nie można dodać więcej niż 4 wyników",
		"isAlreadyTranslated": true
	},
	"Imei Number": {
		"text": "Numer IMEI",
		"isAlreadyTranslated": true
	},
	"By": {
		"text": "Wg",
		"isAlreadyTranslated": true
	},
	"Whitelist Document": {
		"text": "Dokument z białej listy",
		"isAlreadyTranslated": true
	},
	"Hardware Closure": {
		"text": "Zamknięcie sprzętowe",
		"isAlreadyTranslated": true
	},
	"Map G-Number": {
		"text": "Odwzoruj numer G",
		"isAlreadyTranslated": true
	},
	"Please enter G Number.": {
		"text": "Wprowadź numer G.",
		"isAlreadyTranslated": true
	},
	"Please enter Device Recieved Date and Time .": {
		"text": "Wprowadź datę i godzinę otrzymania urządzenia.",
		"isAlreadyTranslated": true
	},
	"Your G Number has been mapped": {
		"text": "Odwzorowano Twój numer G",
		"isAlreadyTranslated": true
	},
	"Update KGB": {
		"text": "Aktualizuj KGB",
		"isAlreadyTranslated": true
	},
	"KGB Number": {
		"text": "Numer KGB",
		"isAlreadyTranslated": true
	},
	"Please enter valid KGB Number": {
		"text": "Wprowadź prawidłowy numer KGB",
		"isAlreadyTranslated": true
	},
	"Servicelocation Engineer": {
		"text": "Inżyniera lokalizacji usług",
		"isAlreadyTranslated": true
	},
	"StoreManager": {
		"text": "StoreManager",
		"isAlreadyTranslated": true
	},
	"Return without repair (RWR)": {
		"text": "Zwrot bez naprawy (ZBN)",
		"isAlreadyTranslated": true
	},
	"Log Call": {
		"text": "Zarejestruj połączenie",
		"isAlreadyTranslated": true
	},
	"Manufacturer's Warranty Status": {
		"text": "Status gwarancji producenta",
		"isAlreadyTranslated": true
	},
	"Device Value": {
		"text": "Wartość urządzenia",
		"isAlreadyTranslated": true
	},
	"Is Manufacturer's Warranty Applicable": {
		"text": "Czy obowiązuje gwarancja producenta?",
		"isAlreadyTranslated": true
	},
	"Connection Disposition": {
		"text": "Dyspozycja połączenia",
		"isAlreadyTranslated": true
	},
	"Non-Connect Disposition": {
		"text": "Dyspozycja braku połączenia",
		"isAlreadyTranslated": true
	},
	"Mandatory fields Required.": {
		"text": "Wymagane pola obowiązkowe.",
		"isAlreadyTranslated": true
	},
	"Please enter tracking Number.": {
		"text": "Wprowadź numer śledzenia.",
		"isAlreadyTranslated": true
	},
	"Please enter Shipper Detail.": {
		"text": "Wprowadź dane nadawcy.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Height.": {
		"text": "Wprowadź wysokość opakowania.",
		"isAlreadyTranslated": true
	},
	"Please enter Box width.": {
		"text": "Wprowadź szerokość opakowania.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Length.": {
		"text": "Wprowadź długość opakowania.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Weight.": {
		"text": "Wprowadź wagę opakowania.",
		"isAlreadyTranslated": true
	},
	"Please enter Over Pack Box Number.": {
		"text": "Wprowadź numer opakowania zbiorczego.",
		"isAlreadyTranslated": true
	},
	"Carrier": {
		"text": "Przewoźnik",
		"isAlreadyTranslated": true
	},
	"Depot Shipper Label": {
		"text": "Etykieta spedytora magazynu",
		"isAlreadyTranslated": true
	},
	"Packing List": {
		"text": "Lista pakowa",
		"isAlreadyTranslated": true
	},
	"Delivery Challan": {
		"text": "Dokument dostawy",
		"isAlreadyTranslated": true
	},
	"Over Pack Box Number": {
		"text": "Numer opakowania zbiorczego",
		"isAlreadyTranslated": true
	},
	"Add Carrier Details": {
		"text": "Dodaj szczegóły przewoźnika",
		"isAlreadyTranslated": true
	},
	"GSX Confirmation No": {
		"text": "Nr potwierdzenia GSX",
		"isAlreadyTranslated": true
	},
	"Repair Creation Date": {
		"text": "Data utworzenia naprawy",
		"isAlreadyTranslated": true
	},
	"Please enter valid": {
		"text": "Wprowadź poprawny",
		"isAlreadyTranslated": true
	},
	"Please select Payment Mode.": {
		"text": "Wybierz sposób płatności.",
		"isAlreadyTranslated": true
	},
	"Inspection Guide": {
		"text": "Przewodnik inspekcji",
		"isAlreadyTranslated": true
	},
	"Guide": {
		"text": "Przewodnik",
		"isAlreadyTranslated": true
	},
	"Please make sure FMIP is switched off.": {
		"text": "Upewnij się, że funkcja Znajdź mój iPhone jest wyłączona.",
		"isAlreadyTranslated": true
	},
	"If you wish to skip FMIP click on YES else ask customer to Turn off FMIP and try again.": {
		"text": "Jeśli chcesz pominąć funkcję Znajdź mój iPhone, kliknij TAK, w przeciwnym razie poproś klienta o wyłączenie funkcji Znajdź mój iPhone i spróbuj ponownie.",
		"isAlreadyTranslated": true
	},
	"Instructions": {
		"text": "Instrukcje",
		"isAlreadyTranslated": true
	},
	"Start at your Home screen.": {
		"text": "Zacznij od ekranu głównego.",
		"isAlreadyTranslated": true
	},
	"Tap Settings > iCloud.": {
		"text": "Stuknij menu Ustawienia > iCloud.",
		"isAlreadyTranslated": true
	},
	"Scroll to the bottom and tap Find My iPhone.": {
		"text": "Przewiń w dół i dotknij opcji Znajdź mój iPhone.",
		"isAlreadyTranslated": true
	},
	"Slide to turn off Find My iPhone and Send Last Location.": {
		"text": "Przesuń, aby wyłączyć funkcje Znajdź mój iPhone i Wyślij ostatnią lokalizację.",
		"isAlreadyTranslated": true
	},
	"Retry": {
		"text": "Spróbuj ponownie",
		"isAlreadyTranslated": true
	},
	"Please update Warranty applicable status in Device Details section.": {
		"text": "Zaktualizuj stan obowiązywania gwarancji w sekcji Szczegóły urządzenia.",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, Please update Warranty applicable status in Device Details section to enable Non Hardware Closure.": {
		"text": "Kierownik ds. obsługi klienta może przeprowadzić diagnostykę, sprawdzić urządzenie, aby zarejestrować naprawę sprzętu, poprosić o zaktualizowanie stanu obowiązywania gwarancji w sekcji Szczegóły urządzenia, aby umożliwić zamknięcie niesprzętowe.",
		"isAlreadyTranslated": true
	},
	"Please select file name, or choose 'Others' to enter a custom file name.": {
		"text": "Wybierz nazwę pliku lub wybierz opcję „Inne”, aby wprowadzić niestandardową nazwę pliku.",
		"isAlreadyTranslated": true
	},
	"Assigned Engineers": {
		"text": "Przypisani inżynierowie",
		"isAlreadyTranslated": true
	},
	"Select File Name": {
		"text": "Wybierz nazwę pliku",
		"isAlreadyTranslated": true
	},
	"Review By Apple Requested": {
		"text": "Zażądano przeprowadzenia przeglądu przez Apple",
		"isAlreadyTranslated": true
	},
	"CS Code Entered": {
		"text": "Wprowadzono kod obsługi klienta",
		"isAlreadyTranslated": true
	},
	"Repair Classification Type": {
		"text": "Typ klasyfikacji naprawy",
		"isAlreadyTranslated": true
	},
	"Guided Troubleshooting Details": {
		"text": "Szczegóły rozwiązywania problemów z przewodnikiem",
		"isAlreadyTranslated": true
	},
	"Please enter Required Fields (*).": {
		"text": "Wprowadź wymagane pola (*).",
		"isAlreadyTranslated": true
	},
	"No Guided Troubleshooting Details": {
		"text": "Brak szczegółów rozwiązywania problemów z przewodnikiem",
		"isAlreadyTranslated": true
	},
	"Repair Notes (for Delivery Report)": {
		"text": "Uwagi dotyczące naprawy (do raportu dostawy)",
		"isAlreadyTranslated": true
	},
	"Repair Notes": {
		"text": "Uwagi dotyczące naprawy",
		"isAlreadyTranslated": true
	},
	"Please enter the Repair Notes": {
		"text": "Wprowadź uwagi dotyczące naprawy",
		"isAlreadyTranslated": true
	},
	"Same as Engineer Diagnosis Notes": {
		"text": "Takie same, jak uwagi dotyczące diagnostyki inżyniera",
		"isAlreadyTranslated": true
	},
	"In case you want to select different outcomes for both parts, please select outcome for one part and Save the same. Post that please update the outcome for the remaining parts. For additional help, contact your Servify administrator.": {
		"text": "In case you want to select different outcomes for both parts, please select outcome for one part and Save the same. Post that please update the outcome for the remaining parts. For additional help, contact your Servify administrator.",
		"isAlreadyTranslated": true
	},
	"Enter KBB Details": {
		"text": "Wprowadź szczegóły KBB",
		"isAlreadyTranslated": true
	},
	"KBB Number": {
		"text": "Numer KBB",
		"isAlreadyTranslated": true
	},
	"Reorder Details": {
		"text": "Zamów ponownie szczegóły",
		"isAlreadyTranslated": true
	},
	"Reorder Part Number": {
		"text": "Zamów ponownie numer części",
		"isAlreadyTranslated": true
	},
	"Reorder Part as": {
		"text": "Zamów ponownie część jako",
		"isAlreadyTranslated": true
	},
	"Purchase Order": {
		"text": "Zamówienie zakupu",
		"isAlreadyTranslated": true
	},
	"Consignment Order": {
		"text": "Zamówienie konsygnacyjne",
		"isAlreadyTranslated": true
	},
	"GSX Confirmation Number": {
		"text": "Numer potwierdzenia GSX",
		"isAlreadyTranslated": true
	},
	"Repair created successfully on GSX": {
		"text": "Naprawa została pomyślnie utworzona w GSX",
		"isAlreadyTranslated": true
	},
	"Repair Status At GSX": {
		"text": "Stan naprawy w GSX",
		"isAlreadyTranslated": true
	},
	"Use from Consigned Inventory": {
		"text": "Użyj z konsygnowanego inwentarza",
		"isAlreadyTranslated": true
	},
	"KGB Serial Number": {
		"text": "Numer seryjny KGB",
		"isAlreadyTranslated": true
	},
	"KGB Serial Number (Consignment Part Serial No)": {
		"text": "Numer seryjny KGB (numer seryjny części konsygnacyjnej)",
		"isAlreadyTranslated": true
	},
	"Fetch GSX Status": {
		"text": "Pobierz stan GSX",
		"isAlreadyTranslated": true
	},
	"Use Consignment Part": {
		"text": "Użyj części konsygnacyjnej",
		"isAlreadyTranslated": true
	},
	"Enter DOA Parts Details": {
		"text": "Wprowadź szczegóły części DOA",
		"isAlreadyTranslated": true
	},
	"Raise GSX Request": {
		"text": "Wprowadź zgłoszenie GSX",
		"isAlreadyTranslated": true
	},
	"We are unable to establish complete device details from GSX. Diagnosis may not have been performed on this device. Please proceed with request creation by selecting product details.": {
		"text": "Nie jesteśmy w stanie ustalić pełnych szczegółów urządzenia z GSX. Być może na tym urządzeniu nie przeprowadzono diagnostyki. Kontynuuj tworzenie zgłoszenia, wybierając szczegóły produktu.",
		"isAlreadyTranslated": true
	},
	"We are unable to establish complete device details from GSX. Diagnosis may not have been performed on this device. Please diagnose the device to proceed. Note: Excessive tries without device diagnosis can block your account access.": {
		"text": "Nie jesteśmy w stanie ustalić pełnych szczegółów urządzenia z GSX. Być może na tym urządzeniu nie przeprowadzono diagnostyki. Aby kontynuować, przeprowadź diagnostykę urządzenia. Uwaga: Nadmierna liczba prób bez diagnostyki urządzenia może zablokować dostęp do Twojego konta.",
		"isAlreadyTranslated": true
	},
	"Notes": {
		"text": "Uwagi",
		"isAlreadyTranslated": true
	},
	"Service Estimate": {
		"text": "Kosztorys usługi",
		"isAlreadyTranslated": true
	},
	"Please make sure “Find My” feature is switched off before accepting device for repair.": {
		"text": "Przed przyjęciem urządzenia do naprawy upewnij się, że funkcja znajdowania urządzenia jest wyłączona.",
		"isAlreadyTranslated": true
	},
	"Disable Find My Functionality": {
		"text": "Wyłącz funkcję Znajdź mój",
		"isAlreadyTranslated": true
	},
	"Engineer reassigned": {
		"text": "Ponownie przypisano inżyniera",
		"isAlreadyTranslated": true
	},
	"Order from GSX": {
		"text": "Zgłoszenie z GSX",
		"isAlreadyTranslated": true
	},
	"Please select value for ‘Use from Consigned Inventory’ field": {
		"text": "Wybierz wartość dla pola „Użyj z konsygnowanego inwentarza”",
		"isAlreadyTranslated": true
	},
	"Return Details": {
		"text": "Szczegóły zwrotu",
		"isAlreadyTranslated": true
	},
	"Return Part as": {
		"text": "Zwróć część jako",
		"isAlreadyTranslated": true
	},
	"Stock DOA": {
		"text": "Zapas DOA",
		"isAlreadyTranslated": true
	},
	"Please Enter Component Code": {
		"text": "Wprowadź kod składnika",
		"isAlreadyTranslated": true
	},
	"Please Enter Issue Code": {
		"text": "Wprowadź kod problemu",
		"isAlreadyTranslated": true
	},
	"Please enter comptia Modifier": {
		"text": "Wprowadź modyfikator COMPTIA",
		"isAlreadyTranslated": true
	},
	"Your inventory does not contain stock for the part number selected. Please add consignment stock to your inventory to proceed.": {
		"text": "Twój inwentarz nie zawierają zapasów dla wybranego numeru części. Aby kontynuować, dodaj zapasy konsygnacyjne do swojego inwentarza.",
		"isAlreadyTranslated": true
	},
	"Please select Part Return Status Code": {
		"text": "Wybierz kod stanu zwrotu części",
		"isAlreadyTranslated": true
	},
	"Box Width": {
		"text": "Szerokość opakowania",
		"isAlreadyTranslated": true
	},
	"Box Height": {
		"text": "Wysokość opakowania",
		"isAlreadyTranslated": true
	},
	"Box Weight": {
		"text": "Waga opakowania",
		"isAlreadyTranslated": true
	},
	"Box Length": {
		"text": "Długość opakowania",
		"isAlreadyTranslated": true
	},
	'Waiting for Engineer to Validate Issues and add Symptom, Fault, Action details in "Issues Reported" section below.': {
		"text": "Oczekiwanie, aż inżynier sprawdzi ważność problemów i doda szczegóły objawu, usterki i czynności w poniższej sekcji „Zgłoszone problemy”.",
		"isAlreadyTranslated": true
	},
	"Primary": {
		"text": "Podstawowy",
		"isAlreadyTranslated": true
	},
	"Reproducibility": {
		"text": "Odtwarzalność",
		"isAlreadyTranslated": true
	},
	"Engineer Reported Component / Issue Code(s)": {
		"text": "Składnik zgłoszony przez inżyniera / kod(y) problemu",
		"isAlreadyTranslated": true
	},
	"Customer Reported Component / Issue Code(s) [Optional]": {
		"text": "Składnik zgłoszony przez klienta / kod(y) problemu [opcjonalnie]",
		"isAlreadyTranslated": true
	},
	"Customer Reported Issues": {
		"text": "Problemy zgłoszone przez klienta",
		"isAlreadyTranslated": true
	},
	"Please select a Reproducibility from dropdown.": {
		"text": "Wybierz odtwarzalność z listy rozwijanej.",
		"isAlreadyTranslated": true
	},
	"Please enter Reproducibility": {
		"text": "Wprowadź odtwarzalność",
		"isAlreadyTranslated": true
	},
	"Waiting for Engineer to Validate Issues and add component code, issue code, action details in 'Issues Reported' section below.": {
		"text": "Oczekiwanie, aż inżynier sprawdzi ważność problemów i doda kod składnika, kod problemu i szczegóły czynności w poniższej sekcji „Zgłoszone problemy”.",
		"isAlreadyTranslated": true
	},
	"Please enter a valid name": {
		"text": "Wprowadź prawidłową nazwę",
		"isAlreadyTranslated": true
	},
	"File Type": {
		"text": "Typ pliku",
		"isAlreadyTranslated": true
	},
	"Proof of Purchase": {
		"text": "Dowód zakupu",
		"isAlreadyTranslated": true
	},
	"GSX Upload Status": {
		"text": "Stan przekazania GSX",
		"isAlreadyTranslated": true
	},
	"Send to GSX": {
		"text": "Wyślij do GSX",
		"isAlreadyTranslated": true
	},
	"Please update the device date of purchase in device details section to proceed further.": {
		"text": "Aby kontynuować, zaktualizuj datę zakupu urządzenia w sekcji szczegółów urządzenia.",
		"isAlreadyTranslated": true
	},
	"GSX Serial No (for Non Serialised Product)": {
		"text": "Nr seryjny GSX (dla produktu bez numeru seryjnego)",
		"isAlreadyTranslated": true
	},
	"The file format uploaded in your zip file is not supported. Please remove any folders, correct the file formats and try again.": {
		"text": "Format pliku przekazanego w pliku ZIP nie jest obsługiwany. Usuń foldery, popraw formaty plików i spróbuj ponownie.",
		"isAlreadyTranslated": true
	},
	"Uploaded file format not supported. Pls upload file in one of the following formats - aac, csv, doc, docx, jpeg, jpg, m4a, mp4, ogg, ogx, pdf, png, txt, xls, xlsx, zip.": {
		"text": "Format przekazanego pliku nie jest obsługiwany. Przekaż plik w jednym z następujących formatów: aac, csv, doc, docx, jpeg, jpg, m4a, mp4, ogg, ogx, pdf, png, txt, xls, xlsx, zip.",
		"isAlreadyTranslated": true
	},
	"Zip file uploaded has no content. Check and re-upload file.": {
		"text": "Przekazany plik ZIP nie zawiera treści. Sprawdź i ponownie przekaż plik.",
		"isAlreadyTranslated": true
	},
	"Uploaded": {
		"text": "Przekazano",
		"isAlreadyTranslated": true
	},
	"Attached to Repair": {
		"text": "Dołączono do naprawy",
		"isAlreadyTranslated": true
	},
	"Failed Re-Upload": {
		"text": "Nieudane ponowne przesłanie",
		"isAlreadyTranslated": true
	},
	"Device is not eligible for integrated diagnosis, please click on complete diagnosis/cannot diagnose to capture the notes and proceed.": {
		"text": "Urządzenie nie kwalifikuje się do zintegrowanej diagnostyki. Kliknij opcję pełna diagnostyka/nie można zdiagnozować, aby zapisać uwagi i kontynuować.",
		"isAlreadyTranslated": true
	},
	"Warning": {
		"text": "Ostrzeżenie",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Inspect Device to log a hardware repair, Please update Warranty applicable status in Device Details section to enable Non Hardware Closure.": {
		"text": "Kierownik ds. obsługi klienta może przeprowadzić inspekcję urządzenia, aby zarejestrować naprawę sprzętu. Zaktualizuj stanu obowiązywania gwarancji w sekcji Szczegóły urządzenia, aby umożliwić zamknięcie niesprzętowe.",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Inspect Device to log a hardware repair, or click on Non Hardware Closure to complete the repair as a non-part service repair.": {
		"text": "Kierownik ds. obsługi klienta może przeprowadzić inspekcję urządzenia, aby zarejestrować naprawę sprzętu, lub kliknąć opcję Zamknięcie niesprzętowe, aby zakończyć naprawę jako naprawę serwisową nieobejmującą części.",
		"isAlreadyTranslated": true
	},
	"Please update \"Is Manufacturer's Warranty Applicable\" and save device details.": {
		"text": "Zaktualizuj opcję „Czy obowiązuje gwarancja producenta” i zapisz szczegóły urządzenia.",
		"isAlreadyTranslated": true
	},
	"Request Type": {
		"text": "Typ zgłoszenia",
		"isAlreadyTranslated": true
	},
	"Fetch Diagnostics Suites": {
		"text": "Pobierz pakiety diagnostyczne",
		"isAlreadyTranslated": true
	},
	"Enter Serial Number / IMEI": {
		"text": "Wprowadź numer seryjny / IMEI",
		"isAlreadyTranslated": true
	},
	"Sales Channel": {
		"text": "Kanał sprzedaży",
		"isAlreadyTranslated": true
	},
	"Street Address": {
		"text": "Ulica",
		"isAlreadyTranslated": true
	},
	"Postal Code": {
		"text": "Kod pocztowy",
		"isAlreadyTranslated": true
	},
	"Province": {
		"text": "Województwo",
		"isAlreadyTranslated": true
	},
	"Locality": {
		"text": "Miejscowość",
		"isAlreadyTranslated": true
	},
	"Enter a location": {
		"text": "Wprowadź lokalizację",
		"isAlreadyTranslated": true
	},
	"Landmark is required": {
		"text": "Punkt orientacyjny jest wymagany",
		"isAlreadyTranslated": true
	},
	"Street Address is required": {
		"text": "Ulica jest wymagana",
		"isAlreadyTranslated": true
	},
	"Postal Code is required": {
		"text": "Kod pocztowy jest wymagany",
		"isAlreadyTranslated": true
	},
	"Province is required": {
		"text": "Województwo jest wymagane",
		"isAlreadyTranslated": true
	},
	"Postal Code is invalid": {
		"text": "Kod pocztowy jest nieprawidłowy",
		"isAlreadyTranslated": true
	},
	"Province is incorrect": {
		"text": "Województwo jest nieprawidłowe",
		"isAlreadyTranslated": true
	},
	"Some of the details might be missing in the address, please update the address.": {
		"text": "W adresie może brakować niektórych szczegółów, zaktualizuj adres.",
		"isAlreadyTranslated": true
	},
	"email id": {
		"text": "adres e-mail",
		"isAlreadyTranslated": true
	},
	"VAT Number": {
		"text": "Numer VAT",
		"isAlreadyTranslated": true
	},
	"VAT Number is required": {
		"text": "Numer VAT jest wymagany",
		"isAlreadyTranslated": true
	},
	"Should Be 15 Characters": {
		"text": "Powinien mieć 15 znaków",
		"isAlreadyTranslated": true
	},
	"Should Be 10 Characters": {
		"text": "Powinien mieć 10 znaków",
		"isAlreadyTranslated": true
	},
	"Name is required": {
		"text": "Nazwa jest wymagana",
		"isAlreadyTranslated": true
	},
	"There's a problem with this location, please select a different location": {
		"text": "Wystąpił problem z tą lokalizacją, wybierz inną lokalizację",
		"isAlreadyTranslated": true
	},
	"Unable to Diagnose": {
		"text": "Nie można zdiagnozować",
		"isAlreadyTranslated": true
	},
	"Contact Information": {
		"text": "Informacje kontaktowe",
		"isAlreadyTranslated": true
	},
	"Disclaimer": {
		"text": "Zastrzeżenie",
		"isAlreadyTranslated": true
	},
	"Send OTP": {
		"text": "Wyślij hasło jednorazowe",
		"isAlreadyTranslated": true
	},
	"OTP Validation": {
		"text": "Sprawdzanie ważności hasła jednorazowego",
		"isAlreadyTranslated": true
	},
	"Back": {
		"text": "Wstecz",
		"isAlreadyTranslated": true
	},
	"Enter the OTP sent to": {
		"text": "Wprowadź hasło jednorazowe wysłane do",
		"isAlreadyTranslated": true
	},
	"Confirm OTP": {
		"text": "Potwierdź hasło jednorazowe",
		"isAlreadyTranslated": true
	},
	"Estimation rejected": {
		"text": "Odrzucono oszacowanie",
		"isAlreadyTranslated": true
	},
	"Mail-In Dispatched Successfully": {
		"text": "Poczta wysłana pomyślnie",
		"isAlreadyTranslated": true
	},
	"Battery back up issue": {
		"text": "Problem z baterią zapasową",
		"isAlreadyTranslated": true
	},
	"Pending for Dispatch": {
		"text": "Oczekiwanie na wysyłkę",
		"isAlreadyTranslated": true
	},
	"Get Bulk Return": {
		"text": "Uzyskaj zbiorczy zwrot",
		"isAlreadyTranslated": true
	},
	"Please enter valid KGB IMEI Number": {
		"text": "Wprowadź prawidłowy numer IMEI KGB",
		"isAlreadyTranslated": true
	},
	"KGB IMEI Number": {
		"text": "Numer IMEI KGB",
		"isAlreadyTranslated": true
	},
	"Update": {
		"text": "Aktualizacja",
		"isAlreadyTranslated": true
	},
	"OverPack No": {
		"text": "Nr opakowania zbiorczego",
		"isAlreadyTranslated": true
	},
	"Please enter Overpack No. for": {
		"text": "Proszę podać nr opakowania zbiorczego dla",
		"isAlreadyTranslated": true
	},
	"Please enter Overpack No. for the selected Reference ID": {
		"text": "Wprowadź nr opakowania zbiorczego dla wybranego identyfikatora referencyjnego",
		"isAlreadyTranslated": true
	},
	"Please enter Overpack No. between 1 to 999": {
		"text": "Wprowadź nr opakowania zbiorczego z zakresu od 1 do 999",
		"isAlreadyTranslated": true
	},
	"Resolution Initiated": {
		"text": "Rozwiązanie rozpoczęte",
		"isAlreadyTranslated": true
	},
	"Resolution in Progress": {
		"text": "Rozwiązanie w toku",
		"isAlreadyTranslated": true
	},
	"Claim Request closed": {
		"text": "Zgłoszenie roszczenia zamknięte",
		"isAlreadyTranslated": true
	},
	"Feedback form (after service request completion)": {
		"text": "Formularz informacji zwrotnej (po zakończeniu zgłoszenia serwisowego)",
		"isAlreadyTranslated": true
	},
	"Below Address will be used by agent based on details added by you, please ensure it is correct before you proceed.": {
		"text": "Poniższy adres zostanie użyty przez pośrednika na podstawie danych dodanych przez Ciebie, upewnij się, że jest on poprawny zanim przejdziesz dalej.",
		"isAlreadyTranslated": true
	},
	"Police Report Date": {
		"text": "Data raportu policyjnego",
		"isAlreadyTranslated": true
	},
	"Police Report Number": {
		"text": "Numer raportu policyjnego",
		"isAlreadyTranslated": true
	},
	"Date of Theft": {
		"text": "Data kradzieży",
		"isAlreadyTranslated": true
	},
	"Place of Theft": {
		"text": "Miejsce kradzieży",
		"isAlreadyTranslated": true
	},
	"Upload Police Report": {
		"text": "Prześlij raport policyjny",
		"isAlreadyTranslated": true
	},
	"Incident Details": {
		"text": "Szczegóły zdarzenia",
		"isAlreadyTranslated": true
	},
	"Claim Request Closed": {
		"text": "Zgłoszenie roszczenia zamknięte",
		"isAlreadyTranslated": true
	},
	"Communication Name": {
		"text": "Nazwa komunikacji",
		"isAlreadyTranslated": true
	},
	"Communication Number": {
		"text": "Numer komunikacji",
		"isAlreadyTranslated": true
	},
	"Communication EmailID": {
		"text": "Adres e-mail do komunikacji",
		"isAlreadyTranslated": true
	},
	"DropOff request accepted": {
		"text": "Zaakceptowano żądanie zwrotu",
		"isAlreadyTranslated": true
	},
	"Shipment Charge": {
		"text": "Opłata za wysyłkę",
		"isAlreadyTranslated": true
	},
	"Delivery Address": {
		"text": "Adres dostawy",
		"isAlreadyTranslated": true
	},
	"Additional Remarks": {
		"text": "Dodatkowe uwagi",
		"isAlreadyTranslated": true
	},
	"Update Logistics Request": {
		"text": "Zaktualizuj żądanie logistyczne!",
		"isAlreadyTranslated": true
	},
	"Vendor tracking URL": {
		"text": "Link monitorujący dostawcy",
		"isAlreadyTranslated": true
	},
	"Click here for Vendor Tracking URL": {
		"text": "Kliknij tutaj, aby wyświetlić adres URL śledzenia dostawcy",
		"isAlreadyTranslated": true
	},
	"Contact number": {
		"text": "Numer kontaktowy",
		"isAlreadyTranslated": true
	},
	"Out of Warranty": {
		"text": "poza gwarancją",
		"isAlreadyTranslated": true
	},
	"Plan Validity": {
		"text": "Ważność planu",
		"isAlreadyTranslated": true
	},
	"Final Plan Price": {
		"text": "Cena końcowa planu",
		"isAlreadyTranslated": true
	},
	"Product Value": {
		"text": "Wartość produktu",
		"isAlreadyTranslated": true
	},
	"Payment": {
		"text": "Płatność",
		"isAlreadyTranslated": true
	},
	"Damage Protection": {
		"text": "Ochrona przed uszkodzeniem",
		"isAlreadyTranslated": true
	},
	"View Plans": {
		"text": "Zobacz plany",
		"isAlreadyTranslated": true
	},
	"TOTAL COMMISSION": {
		"text": "CAŁKOWITA PROWIZJA",
		"isAlreadyTranslated": true
	},
	"Congratulations! Your device is now registered and the plan is now activated for your device.": {
		"text": "Gratulacje! Twoje urządzenie jest teraz zarejestrowane, a ubezpieczenie jest teraz aktywowane dla Twojego urządzenia.",
		"isAlreadyTranslated": true
	},
	"Agreement Number": {
		"text": "Numer umowy",
		"isAlreadyTranslated": true
	},
	"Your Plan is now pending for Activation.": {
		"text": "Twoja ochrona oczekuje teraz na aktywację.",
		"isAlreadyTranslated": true
	},
	"Customer Email ID": {
		"text": "Email ID klienta",
		"isAlreadyTranslated": true
	},
	"Customer Mobile No": {
		"text": "Numer telefonu komórkowego klienta",
		"isAlreadyTranslated": true
	},
	"Warranty End Date": {
		"text": "Data zakończenia gwarancji",
		"isAlreadyTranslated": true
	},
	"Product Value cannot be less than 500": {
		"text": "Wartość produktu nie może być mniejsza niż 500",
		"isAlreadyTranslated": true
	},
	"Sell now": {
		"text": "Sprzedaj",
		"isAlreadyTranslated": true
	},
	"Transferred": {
		"text": "Przeniesione",
		"isAlreadyTranslated": true
	},
	"Customer Date of Birth": {
		"text": "Data urodzenia klienta",
		"isAlreadyTranslated": true
	},
	"Payment Received": {
		"text": "Płatność przyjęta",
		"isAlreadyTranslated": true
	},
	"Date of Birth": {
		"text": "Data urodzenia",
		"isAlreadyTranslated": true
	},
	"IPID": {
		"text": "IPID",
		"isAlreadyTranslated": true
	},
	"Successfully Purchased": {
		"text": "pomyślnie zakupiono",
		"isAlreadyTranslated": true
	},
	"The Plan is now pending for Activation": {
		"text": "Ubezpieczenie jest w trakcie aktywacji",
		"isAlreadyTranslated": true
	},
	" Plans": {
		"text": " CARE+",
		"isAlreadyTranslated": true
	},
	"registered": {
		"text": "zarejestrował",
		"isAlreadyTranslated": true
	},
	"Congratulations!\nThe customer has successfully @@status@@ their device under @@plan@@. Please help the customer to activate the plan.": {
		"text": "Gratulacje!\nKlient pomyślnie @@status@@ swoje urządzenie w ramach planu @@plan@@. Prosimy o pomoc klientowi w aktywacji planu.",
		"isAlreadyTranslated": true
	},
	"Privacy Policy": {
		"text": "Politykę prywatności",
		"isAlreadyTranslated": true
	},
	"Invalid OTP": {
		"text": "Nieważny kod SMS",
		"isAlreadyTranslated": true
	},
	"Resend OTP": {
		"text": "Wyślij ponownie kod SMS",
		"isAlreadyTranslated": true
	},
	"One Time Deductible": {
		"text": "Jednorazowy udział własny",
		"isAlreadyTranslated": true
	},
	"Location Of Incident": {
		"text": "Lokalizacja zdarzenia",
		"isAlreadyTranslated": true
	},
	"Resend OTP in": {
		"text": "Ponowne przesłanie hasła jednorazowego",
		"isAlreadyTranslated": true
	},
	"Declaration": {
		"text": "Deklaracja",
		"isAlreadyTranslated": true
	},
	"Please enter Product Value": {
		"text": "Proszę wprowadzić wartość urządzenia",
		"isAlreadyTranslated": true
	},
	"is required": {
		"text": "pole obowiązkowe",
		"isAlreadyTranslated": true
	},
    "other related documents": {
        "text": "inne powiązane dokumenty",
        "isAlreadyTranslated": true
    },
	"Enter Verification Code": {
		"text": "Wprowadź kod weryfikacyjny",
		"isAlreadyTranslated": true
	},
	"Code sent to customer on": {
		"text": "Kod wysłany do klienta na",
		"isAlreadyTranslated": true
	},
	"and": {
		"text": "i",
		"isAlreadyTranslated": true
	},
	"Resend SMS": {
		"text": "Prześlij ponownie SMS",
		"isAlreadyTranslated": true
	},
	"Resend Email": {
		"text": "Wyślij ponownie e-mail",
		"isAlreadyTranslated": true
	},
	"Call me with the Code": {
		"text": "Zadzwoń do mnie z kodem",
		"isAlreadyTranslated": true
	},
	"Confirm Code": {
		"text": "Potwierdź kod",
		"isAlreadyTranslated": true
	},
	"Didn't receive the code? Resend Code in": {
		"text": "Nie otrzymano kodu? Wyślij ponownie za",
		"isAlreadyTranslated": true
	},
	"Select Brand": {
		"text": "Wybierz markę",
		"isAlreadyTranslated": true
	},
	"or": {
		"text": "lub",
		"isAlreadyTranslated": true
	},
	"Select payment plan": {
		"text": "Wybierz plan płatności",
		"isAlreadyTranslated": true
	},
	"Subscription plan, cancel anytime": {
		"text": "Plan subskrypcji, anuluj w dowolnym momencie",
		"isAlreadyTranslated": true
	},
	"One time payment": {
		"text": "Płatność jednorazowa",
		"isAlreadyTranslated": true
	},
	"Enter": {
		"text": "Wprowadź",
		"isAlreadyTranslated": true
	},
	"Eligible Plan(s)": {
		"text": "Kwalifikujące się plany",
		"isAlreadyTranslated": true
	},
	"Payment Options": {
		"text": "Opcje płatności",
		"isAlreadyTranslated": true
	},
	"Pay Upfront": {
		"text": "Płatność z góry",
		"isAlreadyTranslated": true
	},
	"Subscription Payment": {
		"text": "Płatność subskrybcyjna",
		"isAlreadyTranslated": true
	},
	"The plan purchase is successful. The customer will receive a confirmation email on the registered email ID.": {
		"text": "Zakup planu powiódł się. Klient otrzyma wiadomość e-mail z potwierdzeniem na zarejestrowany adres e-mail.",
		"isAlreadyTranslated": true
	},
	"You will be redirected in": {
		"text": "Nastąpi przekierowanie w ciągu",
		"isAlreadyTranslated": true
	},
	"seconds": {
		"text": "sekund",
		"isAlreadyTranslated": true
	},
	"If you are not redirected": {
		"text": "Jeśli nie zostaniesz przekierowany",
		"isAlreadyTranslated": true
	},
	"Click Here": {
		"text": "Kliknij tutaj",
		"isAlreadyTranslated": true
	},
	"Your transaction has encountered an error and could not be processed at this time. Please try again later or contact our support team for help.": {
		"text": "Twoja transakcja napotkała błąd i nie mogła zostać przetworzona w tym momencie. Spróbuj ponownie później lub skontaktuj się z naszym zespołem pomocy technicznej, aby uzyskać pomoc.",
		"isAlreadyTranslated": true
	},
	"Payment Failed": {
		"text": "Płatność nie powiodła się",
		"isAlreadyTranslated": true
	},
	"Plan Sales": {
        "text": "Ubezpieczenia",
        "isAlreadyTranslated": true
    },

    "Store Location": {
        "text": "Lokalizacja sklepu",
        "isAlreadyTranslated": true
    },
    "Total Sales Value": {
        "text": "Całkowita wartość sprzedaży",
        "isAlreadyTranslated": true
    },
    "Upfront Payment": {
        "text": "Płatność z góry",
        "isAlreadyTranslated": true
    },
    "This includes transaction values associated with plan sales and recurring payments post activation": {
        "text": "Obejmuje wartości transakcji związane również ze sprzedażą z płatnościami cyklicznymi po aktywacji.",
        "isAlreadyTranslated": true
    },
	"Total Earnings": {
        "text": "Całkowita prowizja",
        "isAlreadyTranslated": true
    },
	"This includes retailer margin values associated with plan sales and recurring payments post activation": {
        "text": "Obejmuje wartości transakcji związane również ze sprzedażą z płatnościami cyklicznymi po aktywacji.",
        "isAlreadyTranslated": true
    },
	"Number of Sales": {
        "text": "Ilość sprzedanych polis",
        "isAlreadyTranslated": true
    },
	"This includes all plan sales transactions except those contracts where customer has not completed the payment via link": {
        "text": "Obejmuje wszystkie transakcje sprzedaży planu, z wyjątkiem umów, w przypadku których klient nie dokonał płatności za pośrednictwem łącza.",
        "isAlreadyTranslated": true
    },
	"Total Recurring Payments": {
        "text": "Całkowita ilość płatności cyklicznych",
        "isAlreadyTranslated": true
    },

	"This includes all successful recurring transactions associated with subscription plans": {
        "text": "Obejmuje wszystkie udane transakcje cykliczne związane z planami subskrypcji.",
        "isAlreadyTranslated": true
    },
	"Payment Link Transactions": {
        "text": "Transakcje z linku płatności",
        "isAlreadyTranslated": true
    },
	"/ Payment Link Transactions": {
        "text": "/ Transakcje z linku płatności",
        "isAlreadyTranslated": true
    },
    "Completed": {
        "text": "Zakończone",
        "isAlreadyTranslated": true
    },
	"Pending": {
        "text": "Oczekujące",
        "isAlreadyTranslated": true
    },
	"Failure": {
        "text": "Nieudane",
        "isAlreadyTranslated": true
    },

	"This includes all transactions where customer is using a link to complete the payment": {
        "text": "Obejmuje wszystkie transakcje, w których klient korzysta z linku do sfinalizowania płatności.",
        "isAlreadyTranslated": true
    },
	"Plan Sales Summary": {
        "text": "Podsumowanie sprzedaży",
        "isAlreadyTranslated": true
    },
	"Showing Results For": {
        "text": "Wyświetlanie wyników dla",
        "isAlreadyTranslated": true
    },
	"Today": {
        "text": "dzisiaj",
        "isAlreadyTranslated": true
    },
	" Last 7 Days": {
        "text": "ostatnich 7 dni",
        "isAlreadyTranslated": true
    },
	"Last 15 Days": {
        "text": "ostatnich 15 dni",
        "isAlreadyTranslated": true
    },
	"Last 30 Days": {
        "text": "ostatnich 30 dni",
        "isAlreadyTranslated": true
    },
	"Custom Date": {
        "text": "data niestandardowa",
        "isAlreadyTranslated": true
    },
	"View All Transactions": {
        "text": "Wyświetl wszystkie transakcje",
        "isAlreadyTranslated": true
    },
	"View All": {
        "text": "Wyświetl wszystkie",
        "isAlreadyTranslated": true
    },
	"Sell Plan": {
        "text": "Sprzedaj polisę",
        "isAlreadyTranslated": true
    },

	"Renew Plan": {
        "text": "Odnów polisę",
        "isAlreadyTranslated": true
    },
	"Bulk Upload": {
        "text": "Wgrywanie wielu polis",
        "isAlreadyTranslated": true
    },
	"You can only select a range within 30 days": {
        "text": "Można wybrać tylko zakres w ciągu 30 dni",
        "isAlreadyTranslated": true
    },
	"Select Store Location": {
        "text": "Wybierz lokalizację sklepu",
        "isAlreadyTranslated": true
    },
	"Location": {
        "text": "Lokalizacja",
        "isAlreadyTranslated": true
    },
	"All Transactions": {
        "text": "Wszystkie transakcje",
        "isAlreadyTranslated": true
    },
	"Sorry, No Upfront Payment Transactions Found": {
        "text": "Przepraszamy, nie znaleziono transakcji płatności z góry",
        "isAlreadyTranslated": true
    },
	"Payment Plan": {
        "text": "Płatność",
        "isAlreadyTranslated": true
    },
	"Sorry, No Subscription Payment Transactions Found": {
        "text": "Przepraszamy, nie znaleziono transakcji płatności za subskrypcję",
        "isAlreadyTranslated": true
    },
	"Payment Link Transactions": {
        "text": "Transakcje za pomocą linku płatności",
        "isAlreadyTranslated": true
    },

	"Sorry, No Transactions Found": {
        "text": "Przepraszamy, nie znaleziono żadnych transakcji",
        "isAlreadyTranslated": true
    },
	"Documents": {
        "text": "Dokumenty",
        "isAlreadyTranslated": true
    },
	"Sorry, No Documents Found": {
        "text": "Przepraszamy, nie znaleziono dokumentów",
        "isAlreadyTranslated": true
    },
	"Are you sure you want to cancel the plan?": {
        "text": "Czy na pewno chcesz anulować plan?",
        "isAlreadyTranslated": true
    },
	"Once cancelled, the customer won't be able to redeem any benefits of the plan": {
        "text": "Po anulowaniu klient nie będzie mógł zrealizować żadnych korzyści wynikających z planu.",
        "isAlreadyTranslated": true
    },
	"Select Reason for Cancellation": {
        "text": "Wybierz powód anulowania",
        "isAlreadyTranslated": true
    },
	"Select Reason": {
        "text": "Wybierz powód",
        "isAlreadyTranslated": true
    },
	"Choose a Reason from the dropdown": {
        "text": "Wybierz powód z listy rozwijanej",
        "isAlreadyTranslated": true
    },
	"The plan has been cancelled": {
        "text": "Plan został anulowany",
        "isAlreadyTranslated": true
    },
	"Make Payment": {
        "text": "Dokonaj płatności",
        "isAlreadyTranslated": true
    },

	"More Filters": {
        "text": "Więcej filtrów",
        "isAlreadyTranslated": true
    },
	"More": {
        "text": "Więcej",
        "isAlreadyTranslated": true
    },
	"Customer Email ID": {
        "text": "E-mail klienta",
        "isAlreadyTranslated": true
    },
	"Contract ID": {
        "text": "Numer umowy",
        "isAlreadyTranslated": true
    },
	"Policy ID": {
        "text": "Numer polisy",
        "isAlreadyTranslated": true
    },
	"Clear All": {
        "text": "Wyczyść wszystko",
        "isAlreadyTranslated": true
    },
	"Transaction Date": {
        "text": "Data transakcji",
        "isAlreadyTranslated": true
    },
	"Transaction Amount": {
        "text": "Kwota transakcji",
        "isAlreadyTranslated": true
    },
	"Contract Status": {
        "text": "Status umowy",
        "isAlreadyTranslated": true
    },
	"Enrolled": {
        "text": "Zarejestrowany",
        "isAlreadyTranslated": true
    },
	"Diagnosed": {
        "text": "Zdiagnozowany",
        "isAlreadyTranslated": true
    },
	"Upgraded": {
        "text": "Uaktualniony",
        "isAlreadyTranslated": true
    },
	"On Hold": {
        "text": "Wstrzymany",
        "isAlreadyTranslated": true
    },
	"Downgraded": {
        "text": "Obniżony",
        "isAlreadyTranslated": true
    },
	"Expired": {
        "text": "Wygasła",
        "isAlreadyTranslated": true
    },
	"Cancellation Initiated": {
        "text": "Anulowanie rozpoczęte",
        "isAlreadyTranslated": true
    },
	"Refunded": {
        "text": "Zwrócone",
        "isAlreadyTranslated": true
    },
	"Benefits Availed": {
        "text": "Uzyskane korzyści",
        "isAlreadyTranslated": true
    },
	"Bundled": {
        "text": "Wiązane",
        "isAlreadyTranslated": true
    },
	"Under Review": {
        "text": "W trakcie weryfikacji",
        "isAlreadyTranslated": true
    },
	"Payment Plan": {
        "text": "Plan płatności",
        "isAlreadyTranslated": true
    },
	"Transaction Completion Date": {
        "text": "Data zakończenia transakcji",
        "isAlreadyTranslated": true
    },
	"Payment Link Status": {
        "text": "Status powiązania płatności",
        "isAlreadyTranslated": true
    },
	"Residential State": {
        "text": "Państwo zamieszkania",
        "isAlreadyTranslated": true
    },
	"Residential Country": {
        "text": "Kraj zamieszkania",
        "isAlreadyTranslated": true
    },
	"Plan Code": {
        "text": "Kod polisy",
        "isAlreadyTranslated": true
    },
	"Plan Term": {
        "text": "Okres obowiązywania polisy",
        "isAlreadyTranslated": true
    },
	"Store Location": {
        "text": "Lokalizacja sklepu",
        "isAlreadyTranslated": true
    },
	"Waiting Period": {
        "text": "Okres oczekiwania",
        "isAlreadyTranslated": true
    },
	"Plan Creation Date": {
        "text": "Data utworzenia polisy",
        "isAlreadyTranslated": true
    },
	"Plan Cancellation Initiation Date": {
        "text": "Data rozpoczęcia anulowania polisy",
        "isAlreadyTranslated": true
    },

	"Plan Cancellation Date": {
        "text": "Data anulowania polisy",
        "isAlreadyTranslated": true
    },
	"Cancellation Reason Description": {
        "text": "Powód anulowania Opis",
        "isAlreadyTranslated": true
    },
	"Coverage Details": {
        "text": "Szczegóły ubezpieczenia",
        "isAlreadyTranslated": true
    },
	"Coverage Name": {
        "text": "Nazwa ubezpieczenia",
        "isAlreadyTranslated": true
    },
	"Coverage Start Date": {
        "text": "Data rozpoczęcia ochrony",
        "isAlreadyTranslated": true
    },
	"Claim Avail Date": {
        "text": "Data uzyskania roszczenia",
        "isAlreadyTranslated": true
    },
	"Coverage End Date": {
        "text": "Data zakończenia ochrony",
        "isAlreadyTranslated": true
    },
	"No. of Claims Allowed": {
        "text": "Liczba uwzględnionych roszczeń",
        "isAlreadyTranslated": true
    },
	"Used Claims": {
        "text": "Wykorzystane roszczenia",
        "isAlreadyTranslated": true
    },
	"Device Purchase Price": {
        "text": "Cena zakupu urządzenia",
        "isAlreadyTranslated": true
    },
	"Mode": {
        "text": "Tryb",
        "isAlreadyTranslated": true
    },

	"Payment Gateway Transaction ID": {
        "text": "Identyfikator transakcji bramki płatności",
        "isAlreadyTranslated": true
    },
	"External Payment ID": {
        "text": "Zewnętrzny identyfikator płatności",
        "isAlreadyTranslated": true
    },
	"Billed Date (UTC)": {
        "text": "Data rozliczenia (UTC)",
        "isAlreadyTranslated": true
    },
	"Billing Cycle Date (UTC)": {
        "text": "Data cyklu rozliczeniowego (UTC)",
        "isAlreadyTranslated": true
    },
	"Grace Period End Date": {
        "text": "Data zakończenia okresu karencji",
        "isAlreadyTranslated": true
    },
	"Customer Mobile Number": {
		"text": "Numer telefonu komórkowego klienta",
		"isAlreadyTranslated": true
	},
	"Retailer Margin": {
        "text": "Marża sprzedawcy",
        "isAlreadyTranslated": true
    },
	"PLAN DETAILS": {
		"text": "SZCZEGÓŁY UBEZPIECZENIA",
        "isAlreadyTranslated": true
	},
	"COVERAGE DETAILS": {
		"text": "SZCZEGÓŁY OCHRONY",
        "isAlreadyTranslated": true
	},
	"DEVICE DETAILS": {
		"text": "SZCZEGÓŁY URZĄDZENIA",
        "isAlreadyTranslated": true
	},
	"Model": {
		"text": "Model",
        "isAlreadyTranslated": true
	},
	"Invoice": {
        "text": "Faktura",
        "isAlreadyTranslated": true
    },
	"Payment Details": {
        "text": "Szczegóły płatności",
        "isAlreadyTranslated": true
    },
	"Overview" : {
        "text": "Podsumowanie",
        "isAlreadyTranslated": true
    },
    "Session Expired": {
		"text": "Sesja wygasła",
        "isAlreadyTranslated": true
    },
    "Your session has expired. The store location needs to be selected again, so you will be redirected to the home page shortly.": {
		"text": "Twoja sesja wygasła. Lokalizacja sklepu musi zostać wybrana ponownie, więc wkrótce zostaniesz przekierowany na stronę główną.",
        "isAlreadyTranslated": true
    },
	"Transaction ID": {
        "text": "Identyfikator transakcji",
        "isAlreadyTranslated": true
    },
	"Transaction Date (UTC)": {
        "text": "Data transakcji (UTC)",
        "isAlreadyTranslated": true
    },
	"Pay upfront": {
		"text": "Płatność z góry",
		"isAlreadyTranslated": true
	},
	"month": {
		"text": "miesiąc",
		"isAlreadyTranslated": true
	},
	"Maximum": {
		"text": "Maksymalnie",
		"isAlreadyTranslated": true
	},
	"Characters Allowed": {
		"text": "znaków",
		"isAlreadyTranslated": true
	},
	"Full Name": {
		"text": "Pełna nazwa",
		"isAlreadyTranslated": true
	},
	"Make payment": {
		"text": "Dokonaj płatności",
		"isAlreadyTranslated": true
	},
	"Receive Payment": {
		"text": "Odbierz płatność",
		"isAlreadyTranslated": true
	}
}
export default lang;