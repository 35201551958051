import React from 'react';
import {Modal} from 'sfy-react';
import FMIPInputs from './FMIPInputs';
import './FMIPModal.scss';

const FMIPModal=(props)=>{
    const {localString,showModal,closeModal,overrideFMIPCheck} = props;

		return (
		<div className='FMIPModal-main'>
            <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='Filter-Modal FMIPModal'>
                <Modal.Header>
                    <h4>{localString("Disable Find My Functionality")}</h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-12">
                            {localString("Please make sure “Find My” feature is switched off before accepting device for repair.")}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button name="Yes" class="btn button-servify" onClick = {()=>overrideFMIPCheck()}>{localString('Okay')}</button>
                </Modal.Footer>
            </Modal>
        </div>
	)
}
export default FMIPModal;