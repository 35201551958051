import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import {returnLocalDateTime,displayError} from '../../../../Utility/Utils';
import UnRegisteredProductDetails from './UnRegisteredProductDetails';
import UnRegistredProductList from './UnRegistredProductList';
import ActionButtonList from "../../../../components/ActionButtonsList/ActionButtonList";
import AddUpdateConsumerProduct from '../AddUpdateConsumerProduct/AddUpdateConsumerProduct';
import './UnRegisteredProduct.scss';


const UnRegisteredProduct = (props) => {
	const {localString,apiCall,ConsumerID} = props;
	const [showLoader ,setLoader] = useState(false);
	const [unRegisteredProductList, setUnRegisteredProductList] = useState()
	const [unRegisteredProductArray, setUnRegisteredProductArray] = useState([])
	const [consumerUnRegisteredProduct, setConsumerUnRegisteredProduct] = useState()
	const [buttonArray,setButtonArray] = useState([]);
	const [selectedID,setSelectedID] = useState();
	const [editMode,setEditMode] = useState(false);
	const [consumerProductSaveData, setConsumerProductSaveData] = useState({
		BrandID:"",
		ProductSubCategoryID:"",
		ProductID:"",
		BrandName:"",
		ProductSubCategory:"",
		ProductName:"",
		TagName:"",
		ModelNo:"",
		ProductUniqueID:"",
		YearOfPurchase:"",
		WarrantyTill:""
	})

	const getUnRegisteredProducts = () => {
		let data={
			ConsumerID:ConsumerID,
			IsRegistered:false
		};
		setLoader(true);
		apiCall({
			url:'getConsumerProductsByConsumerID',
			data: data,
			callBack:(result)=>{
				setLoader(false); 
				if(result.success){
					let unRegProductList = result.data;
					setUnRegisteredProductList(unRegProductList)
					if(unRegProductList && unRegProductList.length >0 ){
						setSelectedID(unRegProductList[0].ConsumerUnregisteredProductID)
					}
					unRegProductList && unRegProductList.map((unRegProduct)=>{
						if(selectedID && selectedID === unRegProduct.ConsumerUnregisteredProductID){
							setSelectedID("")
						}
					})
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const fetchProductDetails = () => {
		let data={
			ConsumerUnregisteredProductID:selectedID,
		};
		setLoader(true);
		apiCall({
			url:'getProductDetailsForUnregisteredProducts',
			data: data,
			callBack:(result)=>{
				setLoader(false); 
				if(result.success){
					setConsumerUnRegisteredProduct(result.data);
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const UnRegisteredProductData = () => {
		let defaultText = localString("Not Updated");
		let unRegProductArray = [
			{
				label:(localString("Product Subcategory")),
				value:(consumerUnRegisteredProduct && consumerUnRegisteredProduct.ProductSubCategory ) || defaultText
			},
			{
				label:(localString("Brand")),
				value:(consumerUnRegisteredProduct && consumerUnRegisteredProduct.BrandName) || defaultText
			},
			{
				label:(localString("Product")),
				value:(consumerUnRegisteredProduct && consumerUnRegisteredProduct.ProductName) || defaultText
			},
			{
				label:(localString("Tag Name")),
				value:(consumerUnRegisteredProduct && consumerUnRegisteredProduct.TagName) || defaultText
			},
			{
				label:(localString("Model No")),
				value:(consumerUnRegisteredProduct && consumerUnRegisteredProduct.ModelNo) || defaultText
			},
			{
				label:localString("IMEI")+'/'+ localString("Serial No."),
				value:(consumerUnRegisteredProduct && consumerUnRegisteredProduct.ProductUniqueID) || defaultText
			},
			{
				label:(localString("Year Of Purchase")),
				value:(consumerUnRegisteredProduct && consumerUnRegisteredProduct.YearOfPurchase) || defaultText
			},
			{
				label:(localString("Warranty Till")),
				value:(consumerUnRegisteredProduct && consumerUnRegisteredProduct.WarrantyTill && returnLocalDateTime(consumerUnRegisteredProduct.WarrantyTill,'DD-MM-YYYY')) || defaultText
			},
			{
				label:(localString("Bills/Documents")),
				showLinks:true,
				hide:consumerUnRegisteredProduct && (!consumerUnRegisteredProduct.FilePath || (consumerUnRegisteredProduct.FilePath && (consumerUnRegisteredProduct.FilePath.length == 0 || consumerUnRegisteredProduct.FilePath[0] == null || consumerUnRegisteredProduct.FilePath[0] =="" ))),
				linkArray:consumerUnRegisteredProduct && consumerUnRegisteredProduct.FilePath
			},
		]
		setUnRegisteredProductArray(unRegProductArray);
		setButtonList();
	}

	const setButtonList = () => {
		let buttonList = [];
		
		if(consumerUnRegisteredProduct){
			buttonList = [...buttonList,{
				text:localString("Edit"),
				onClickAction:()=>{
					editProduct();
				}
			}]
		}
		setButtonArray(buttonList);
	}

	const saveProduct = (e)=>{
		e.preventDefault();
		let data = consumerProductSaveData;
		data.ConsumerUnregisteredProductID = selectedID;
		setLoader(true);
		apiCall({
			url:'setProductDetailsForUnregisteredProducts',
			data: data,
			callBack:(result)=>{
				setLoader(false); 
				if(result.success){
					fetchProductDetails(result.data.ConsumerUnregisteredProductID);
					setEditMode(false);
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const editProduct = () =>{
		setEditMode(true);
		setConsumerProductSaveData({...consumerProductSaveData,
			BrandName:consumerUnRegisteredProduct && consumerUnRegisteredProduct.BrandName,
			BrandID:consumerUnRegisteredProduct && consumerUnRegisteredProduct.BrandID,
			ProductSubCategory:consumerUnRegisteredProduct && consumerUnRegisteredProduct.ProductSubCategory,
			ProductSubCategoryID:consumerUnRegisteredProduct && consumerUnRegisteredProduct.ProductSubCategoryID,
			ProductName:consumerUnRegisteredProduct && consumerUnRegisteredProduct.ProductName,
			TagName:consumerUnRegisteredProduct && consumerUnRegisteredProduct.TagName,
			ModelNo:consumerUnRegisteredProduct && consumerUnRegisteredProduct.ModelNo,
			ProductUniqueID:consumerUnRegisteredProduct && consumerUnRegisteredProduct.ProductUniqueID,
			YearOfPurchase:consumerUnRegisteredProduct && consumerUnRegisteredProduct.YearOfPurchase,
		})
		if(returnLocalDateTime(consumerUnRegisteredProduct && consumerUnRegisteredProduct.WarrantyTill,'YYYY-MM-DD') != 'Invalid date'){
			setConsumerProductSaveData((prevState) => {
				return { ...prevState, WarrantyTill:consumerUnRegisteredProduct && consumerUnRegisteredProduct.WarrantyTill };
			});
		}
	}

	const cancelEditProduct=()=> {
		setEditMode(false);
	}

	useEffect(() => {
		UnRegisteredProductData();
	}, [consumerUnRegisteredProduct]);

	const showProductetails = (req) => {
		setSelectedID(req.ConsumerUnregisteredProductID)
	}

	useEffect(() => {
		if (selectedID) {
			fetchProductDetails();
		}
	}, [selectedID]);

	useEffect(() => {
		getUnRegisteredProducts()
	}, [])

	return (
		<div>
			{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			{unRegisteredProductList && unRegisteredProductList.length ? <div className="col-sm-12 text-center clearfix reg-sec">
				<div className="col-sm-3 col-xs-12 text-center Unproduct-list">
					<UnRegistredProductList
						unRegisteredProductList={unRegisteredProductList}
						selectedID={selectedID}
						showProductetails={showProductetails}
						{...props}
					></UnRegistredProductList>
				</div>
				<div className="col-sm-9 padding0 Unproduct-details">
					{!editMode && <div>
						<UnRegisteredProductDetails
						{...props}
						unRegisteredProductArray={unRegisteredProductArray}
					></UnRegisteredProductDetails>
					<ActionButtonList buttonArray={buttonArray}></ActionButtonList></div>
					}
					{
						editMode && <AddUpdateConsumerProduct cancelProduct={cancelEditProduct}  saveFunction={saveProduct} unRegistered={true} consumerProductSaveData={consumerProductSaveData} setConsumerProductSaveData={setConsumerProductSaveData} editMode={editMode} {...props}></AddUpdateConsumerProduct>
					}
				</div>
			</div>:<div className="text-center product-list-empty-state"><p>{localString("No Unregistered Products Found")}</p></div>
			}
		</div>
	)
}

export default UnRegisteredProduct
