import React, { useEffect, useState } from 'react';
import { Button, Input, Dropdown } from 'sfy-react';
import Swal from 'sweetalert2';
import Datetime from 'react-datetime';
import _ from 'lodash';
import moment from 'moment';

import { checkNumberPattern, createRefIDLikeString, displayError, SERVICETYPE } from '../../../../../Utility/Utils';

import './UpdateLogisticModal.scss';

const UpdateLogisticInput = (props) => {
    const {
        localString, consumerDetails, tradeInDetails, tradeInDashboardDetails, consumerServiceRequest, setLoader, apiCall,
        accessFn, countriesData, ConsumerServiceRequestID, showRequestLogs, closeModal, getRequestById,
    } = props;
    const [activeTab, setActiveTab] = useState('consumerAddress');
    // const [displayTabs, setDisplayTabs] = useState(false);
    const [addressList, setAddressList] = useState(consumerDetails?.Addresses || []);
    const [address, setAddress] = useState(null);
    //const [showShipmentButton, setShowShipmentButton] = useState(false);
    const [dropPinData, setDropPinData] = useState(null);
    const [serviceLocation, setServiceLocation] = useState(null);
    const [serviceProviders, setServiceProviders] = useState(null);
    const [logisticsRequestDetails, setLogisticsRequestDetails] = useState([]);
    const [sctoh, setSctoh] = useState(false);
    const [showConsumerAddresses, setShowConsumerAddresses] = useState(true);
    const [showLocationDetails, setShowLocationDetails] = useState(true);
    const [providerOptions, setProviderOptions] = useState([]);
    const [providerObj, setProviderObj] = useState(null);
    const [flowObj, setFlowObj] = useState(null);
    const [disableFlow, setDisableFlow] = useState(false);
    const [allowedDates, setAllowedDates] = useState([]);
	const [debounceTimeout, setDebounceTimeout] = useState(false);
	const [pslOptions, setPslOptions] = useState([]);
	const [serviceLocationName, setServiceLocationName] = useState(null);
	const [serviceLocationId, setServiceLocationId] = useState(null);
    const [formData, setFormData] = useState({
		customerName: '',
		customerNumber: '',
		serviceCenterNumber: '',
		scheduledDate: '',
		flow: '',
		provider: ''
	});
    const qFilter = JSON.parse(props.qFilter)
    const isReverseLogstics = showRequestLogs?.isRepairCompleted || showRequestLogs?.isRepairCancelled;
    // const showShipmentButton = formData.customerName && (formData.customerNumber && checkNumberPattern(formData.customerNumber)) && 
    //                              (formData.serviceCenterNumber && checkNumberPattern(formData.serviceCenterNumber)) && 
    //                             formData.scheduledDate && formData.flow && formData.provider;

    const showShipmentButton = formData.scheduledDate && formData.flow && formData.provider && (
        sctoh || ((formData.customerNumber && checkNumberPattern(formData.customerNumber)) && 
        (formData.serviceCenterNumber && checkNumberPattern(formData.serviceCenterNumber)))
    )

    const addressClick = (addressObj, sctohVal) => {
        let obj = {};
        if(addressObj) {
            obj = addressObj;
            setAddress(addressObj);
        }else if(sctohVal) {
            obj.Pincode = tradeInDetails.partnerServiceLocation.Pincode;
            obj.PinCode = tradeInDetails.partnerServiceLocation.Pincode;
            obj.RegionCode = consumerServiceRequest.servicelocation.PickUpCountryCode;
            obj.Address = tradeInDetails.partnerServiceLocation.Address;
            obj.Landmark = tradeInDetails.partnerServiceLocation.Landmark;
            setAddress(obj);
        }
        // setShowShipmentButton(false);
        setProviderObj(null);
        setFlowObj(null);
        setFormData({...formData, flow: '', provider: ''});
        getCityByPincode(obj);
    }

    const getCityByPincode = (addressObj) => {
        let data = {
            "Pincode": addressObj?.PinCode
		};
        setLoader(true);
		apiCall({
			url: "getCityStateByPincode",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    setDropPinData(result.data);
                    checkServiceAvailability(addressObj);
                }else {
                    Swal.fire({
						icon: 'error',
						text: localString('Something went wrong. Your request may have timed out.'),
					});
                }
				
			},
		});
    }

    const getCountryCodeFromCountryID = (CountryID) => {
        let country = _.find(countriesData, function (o) { return o.CountryID == CountryID});
        if (!country) {
            country = countriesData;
        }
        return country.CountryCode;
    }

    const checkServiceAvailability = (addressObj) => {
        let data = {};
        // if (self.showLocationDetails) {
        if (activeTab == 'locationDetails') {
            data = {
                PickUpPincode: serviceLocation.PinCode,
                pickupCountryCode: getCountryCodeFromCountryID(serviceLocation.CountryID) || 'IN',
                DropPincode: consumerServiceRequest.servicelocation.ZipCode,
                dropCountryCode: getCountryCodeFromCountryID(consumerServiceRequest.servicelocation.CountryID) || 'IN'
            }
        }else {
            if(isReverseLogstics) {
                data = {
                    PickUpPincode: consumerServiceRequest.servicelocation.ZipCode,
                    pickupCountryCode: getCountryCodeFromCountryID(consumerServiceRequest.servicelocation.CountryID) || 'IN',
                    DropPincode: addressObj.PinCode,
                    dropCountryCode: addressObj.RegionCode || 'IN'
                }
            }else {
                data = {
                    PickUpPincode: addressObj?.PinCode,
                    pickupCountryCode: addressObj?.RegionCode || 'IN',
                    DropPincode: consumerServiceRequest?.servicelocation?.ZipCode,
                    dropCountryCode: getCountryCodeFromCountryID(consumerServiceRequest?.servicelocation?.CountryID) || 'IN'
                }
            }
        }
        if(consumerServiceRequest.ServiceTypeID == SERVICETYPE.TRADE_IN_DROPOFF) {
            data.logisticsServiceType = [1,4];
        }
        setLoader(true);
		apiCall({
			url: "checkServiceAvailability",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    setServiceProviders(result.data);
                    // self.detailsComplete = true;
                    initFlow(result.data);
                }else {
                    Swal.fire({
						icon: 'error',
						text: localString(result.msg),
					});
                }
				
			},
		});
    }

    const initFlow = (serviceProvidersArr) => {
        let selectedFlow;
        if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.TRADE_IN_DROPOFF) {
            selectedFlow = _.find(serviceProvidersArr, function (o) { return o.LogisticsServiceType == 4})
            if (!selectedFlow) {
                selectedFlow = _.find(serviceProvidersArr, function (o) { return o.LogisticsServiceType == 1})
            }
        }else {
            if (isReverseLogstics) {
                selectedFlow = _.find(serviceProvidersArr, function (o) { return o.LogisticsServiceType == 2})
            }else {
                selectedFlow = _.find(serviceProvidersArr, function (o) { return o.LogisticsServiceType == 1})
            }
        }
        if (selectedFlow) {
            setFlowObj(selectedFlow);
            setFormData({...formData, flow: selectedFlow.LogisticsServiceTypeName, scheduledDate: allowedDates[0]});
            setDisableFlow(true);
            let arr = selectedFlow?.VendorList.map(item => ({...item, value: item.DisplayName}));
            setProviderOptions(arr);
        }
    }

    const createShipment = () => {
        let productDetails = {
            // "productPrice": 0,
            "imei": consumerServiceRequest.consumerProduct.ProductUniqueID,
            "modelNo": "",
            "productID": consumerServiceRequest.consumerProduct.ProductID,
            "brandName": consumerServiceRequest.consumerProduct.brand.BrandName,
            "productName": consumerServiceRequest.consumerProduct.product.ProductName,
        }
        let customerDetails, serviceCenterDetails, differentServiceCenterDetails;
        if (activeTab == 'consumerAddress') {
            customerDetails = {
                "city": dropPinData.City,
                "state": dropPinData.State,
                "address": address.Address,
                "landmark": address.Landmark,
                "pinCode": address.PinCode,
                "phoneNo": sctoh ? tradeInDetails.partnerServiceLocation.MobileNo : formData.customerNumber,
                "name": sctoh ? tradeInDetails.partnerServiceLocation.ServiceLocationName : formData.customerName,
                "emailId": ""
            }
        }
        serviceCenterDetails = {
            "city": consumerServiceRequest.servicelocation.City,
            "state": consumerServiceRequest.servicelocation.State,
            "address": consumerServiceRequest.servicelocation.Address,
            "landmark": consumerServiceRequest.servicelocation.Landmark,
            "pinCode": consumerServiceRequest.servicelocation.PinCode,
            "phoneNo": sctoh ? consumerServiceRequest.servicelocation.ContactNo1 : formData.serviceCenterNumber,
            "name": consumerServiceRequest.servicelocation.ServiceLocationName,
            "emailId": ""
        }
        if (activeTab == 'locationDetails') {
            differentServiceCenterDetails = {
                "city": serviceLocation.City,
                "state": serviceLocation.State,
                "address": serviceLocation.Address,
                "landmark": serviceLocation.Landmark,
                "pinCode": serviceLocation.PinCode,
                "phoneNo": serviceLocation.ContactNo1,
                "name": serviceLocation.ServiceLocationName,
                "emailId": ""
            }
        }

        let data = {
            "productDetails": productDetails,
            // "dropDetails": self.showLocationDetails ? serviceCenterDetails : self.isReverseLogstics ? customerDetails : serviceCenterDetails,
            "dropDetails": (activeTab == 'locationDetails') ? serviceCenterDetails : isReverseLogstics ? customerDetails : serviceCenterDetails,
            "pickupDetails": (activeTab == 'locationDetails') ? differentServiceCenterDetails : isReverseLogstics ? serviceCenterDetails : customerDetails,
            "flow": flowObj.LogisticsServiceType,
            "Source": "WebApp-Logistics",
            "Provider": providerObj.VendorID,
            "secretcode": "",
            "ReferenceID": createRefIDLikeString(10),
            "csrReferenceID": consumerServiceRequest.ReferenceID,
            "subCategoryId": consumerServiceRequest.consumerProduct.ProductSubCategoryID
        }
        if (sctoh && flowObj && (flowObj.LogisticsServiceType == 4)) {
            data.OpenBox = true;
            data.RefurbServiceLocationCode = tradeInDashboardDetails.refurbPsl.ServiceLocationCode;
            data.ProductSubCategory = tradeInDashboardDetails.productSubCategory.ProductSubCategoryID;
            data.ProductPrice = tradeInDetails.calculation.TradeInAmount;
        }
        setLoader(true);
		apiCall({
			url: "regenerateLogisticsRequest",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    if(result.success && result.data) {
                        Swal.fire({
                            title: 'Request raised successfully',
                            html: '<p>Your waybill number is ' + result.data.wayBill + '</p>',
                            icon: 'success',
                            confirmButtonColor: '#4C10C1',
                            confirmButtonText: localString("Okay"),
                        }).then(swalResult => {
                            getRequestById();
                            closeModal();
                        });
                    }else {
                        Swal.fire({
                            icon: 'error',
                            text: localString('Logistics request is not raised. Please try again'),
                        });
                    }
                }else {
                    Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
                }
				
			},
		});
    }

    const getPartnerServiceLocations = (searchText) => {
        let data = {
            ServiceLocationNameSubString: searchText,
            SearchCountryID: (countriesData && qFilter && qFilter.countrycode ? countriesData.CountryID : undefined)
        };
        setLoader(true);
		apiCall({
			url: 'serviceCenterList',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
                    let arr = result.data.map(a => ({...a, value: a.ServiceLocationName}))
                    setPslOptions(arr);
                    setServiceLocationName(null);
                    setServiceLocationId(null);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    const getPartnerServiceLocationById = (id) => {
        let data = {
            PartnerServiceLocationID: id
        };
        setLoader(true);
		apiCall({
			url: 'getPartnerServiceLocationById',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
                    setServiceLocation(result.data);
                    setFormData({...formData, 
                        customerName: '', 
                        customerNumber: result.data?.ContactNo1, 
                        serviceCenterNumber: consumerServiceRequest?.servicelocation?.ContactNo1
                    });
                    checkServiceAvailability();
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    const getAllowedDates = () => {
        let currentDay = moment().weekday();
        let dates = []
        if (currentDay == 0) { // for monday
            dates = [moment(), moment().add(1, 'days'), moment().add(2, 'days')]
        } else if (currentDay == 1 || currentDay == 2) { // for tuesday and wednesday
            dates = [moment(),moment().add(1, 'days'), moment().add(2, 'days'),]
        } else if (currentDay == 3) { // for thursday
            dates = [moment(),moment().add(1, 'days'), moment().add(4, 'days'),]
        } else if (currentDay == 4) { // for friday
            dates = [moment(),moment().add(3, 'days'), moment().add(4, 'days'),]
        } else if (currentDay == 5) { // for saturday
            dates = [moment().add(2, 'days'), moment().add(3, 'days'),moment().add(4, 'days')]
        } else { // for sunday
            dates = [moment().add(1, 'days'), moment().add(2, 'days'),moment().add(3, 'days')]
        }
        setAllowedDates(dates);
    }

    const fetchLogisticsDetails = () => {
        let data = {
            ConsumerServiceRequestID: ConsumerServiceRequestID,
		};
        setLoader(true);
		apiCall({
			url: "getAllLogisticsRequestDetails",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    if(result.data) {
                        setLogisticsRequestDetails(result.data[0])
                        if ([SERVICETYPE.TRADE_IN_DROPOFF].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID) > -1) {
                            setSctoh(true)
                            addressClick(null, true);
                        }else {
							setFormData({...formData, 
								customerName: consumerServiceRequest?.consumer?.Name, 
								customerNumber: consumerServiceRequest?.consumer?.MobileNo, 
								serviceCenterNumber: consumerServiceRequest?.servicelocation?.ContactNo1
							})
                        }
                    }
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    useEffect(() => {
        fetchLogisticsDetails();
        getAllowedDates();
    }, []);

    const showClickedLogisticsTab = () => {
        // setActiveTab();
    }

    return (
        <>
            <div className='updateLogisticsRequest' style={{ padding: '0px 50px' }}>
                <div className="collapse navbar-collapse navbar-static-top remove-navbar-padding" id="logisticsNav">
                    <ul className="nav navbar-nav ul-nav" style={{ paddingBottom: '20px' }}>
                        <li 
                            id="consumer_addresses_li"
                            className={activeTab == 'consumerAddress' ? 'active tab-li' : 'tab-li'} 
                        >
                            <a 
                                id="consumer_addresses_tab" 
                                className="btn button-servify backtoreqbtn no-border-radius tab-buttons" 
                                onClick={() => setActiveTab('consumerAddress')}
                            > 
                                {localString("Select Customer Address")}
                            </a>
                        </li>
                        { (accessFn('UPDATE_LOGISTICS_PSL') && !isReverseLogstics) && <li className={activeTab == 'locationDetails' ? 'active tab-li' : 'tab-li'}>
                            <a 
                                id="locations_tab" 
                                className="btn button-servify backtoreqbtn no-border-radius tab-buttons" 
                                onClick={() => setActiveTab('locationDetails')}
							> 
								{localString("Select ASC Address")}
                            </a>
                        </li> }
                    </ul>
                </div>
                {
					activeTab == 'consumerAddress' ? <>
						{/* ************** Consumer Address Tab ************** */}
                        <div className="col-sm-12" id="addresslist">
                            {
                                addressList && addressList.length == 0 ?
                                    <div className="empty-message">
                                        <p>{localString("No addresses found")}</p>
                                    </div> : 
                                    <div className="row fixed-height">
                                        { !sctoh && addressList && addressList.length > 0 && <div className='col-sm-12 text-bold'>{isReverseLogstics ? localString("Select Drop Address") : localString("Select Pickup Address")}</div>}
                                        {
                                            !sctoh && addressList && addressList.length > 0 && addressList.map((addressObj, k) => <>
                                                <div className="col-sm-4" key={k} onClick={() => addressClick(addressObj)}>
                                                    <div className={`item address-item ${((address && address.ConsumerFavouriteLocationID) == addressObj.ConsumerFavouriteLocationID) ? 'item-active-border' : ''}`}>
                                                        <p className="address-p">
                                                            {addressObj.Address} {addressObj.Landmark} - {!addressObj.skipZipCodeCheck ? '-': ''} {!addressObj.skipZipCodeCheck ? addressObj.PinCode : ''}</p>
                                                        { addressObj.City && <p className="address-p">{addressObj.City} - {addressObj.State}</p>}
                                                        { addressObj.AddressType && <p className="address-type-p">{localString(addressObj.AddressType)}</p> }
                                                    </div>
                                                </div>
                                            </>)
                                        }
                                </div>
                            }
                        </div>
                    </> : 
					<>
						{/* ************** Location Details Tab ************** */}
						<div className="col-sm-12" id="addresslist">
                            <div className='row'>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>{localString("Service Center")}</label>
                                        <Dropdown
                                            value={serviceLocationName || ''}
                                            options={pslOptions}
                                            handleOnChange={(data) => {
                                                setServiceLocationName(data.value);
                                                setServiceLocationId(data.PartnerServiceLocationID);
                                                getPartnerServiceLocationById(data.PartnerServiceLocationID);
                                            }}
                                            // searchText={pslSearchText}
                                            handleOnSearch={(e) => {
                                                let value = e.target.value;
                                                if (value.length > 2) {
                                                    debounceTimeout && clearTimeout(debounceTimeout);
                                                    setDebounceTimeout(
                                                        setTimeout(() => {
                                                            getPartnerServiceLocations(value);
                                                        }, 400)
                                                    );
                                                }
                                                return pslOptions;
                                            }}
                                            filterBy='value'
                                            showDownArrow={false}
                                        />
                                    </div>
                                </div> 
                            </div>
                            <div className='row'>
                                { serviceLocation && <div className=''>
                                    <div className="col-sm-4">
                                        <div className={`item address-item item-active-border`}>
                                            <p className="address-p">
                                                {serviceLocation.Address} {serviceLocation.Landmark} - {!serviceLocation.skipZipCodeCheck ? '-': ''} {!serviceLocation.skipZipCodeCheck ? serviceLocation.PinCode : ''}</p>
                                            { serviceLocation.City && <p className="address-p">{serviceLocation.City} - {serviceLocation.State}</p>}
                                        </div>
                                    </div>
                                </div> }
                            </div>
                        </div>
					</>
                }
				<div className='' style={{ padding: '0px 30px', background: 'white' }}>
					<div className='row'>
						{ !sctoh && <>
							<div className="col-sm-4">
								<div className="form-group">
									<label>{localString("Customer's Contact name")}</label>
									<Input
										value={formData.customerName}
										handleOnChange={(e) => {
											setFormData({ ...formData, customerName: e.target.value });
											// if(!e.target.value) {
											// 	setError({ ...error, policeNumber: "Please Enter Police Report Number" });
											// }else {
											// 	setError({ ...error, policeNumber: null });
											// }
										}}
										className={{
											containerClass: 'input-container',
											inputClass: 'input'
										}}
									/>
								</div>
							</div> 
							<div className="col-sm-4">
								<div className="form-group">
									<label>{localString("Customer's Contact Number")}</label>
									<Input
										value={formData.customerNumber}
                                        inputType={'number'}
										handleOnChange={(e) => {
											setFormData({ ...formData, customerNumber: e.target.value });
											// if(!e.target.value) {
											// 	setError({ ...error, policeNumber: "Please Enter Police Report Number" });
											// }else {
											// 	setError({ ...error, policeNumber: null });
											// }
										}}
										className={{
											containerClass: 'input-container',
											inputClass: 'input'
										}}
									/>
								</div>
							</div> 
							<div className="col-sm-4">
								<div className="form-group">
									<label>{localString("Service Center Contact Number")}</label>
									<Input
										value={formData.serviceCenterNumber}
                                        inputType={'number'}
										handleOnChange={(e) => {
											setFormData({ ...formData, serviceCenterNumber: e.target.value });
											// if(!e.target.value) {
											// 	setError({ ...error, policeNumber: "Please Enter Police Report Number" });
											// }else {
											// 	setError({ ...error, policeNumber: null });
											// }
										}}
										className={{
											containerClass: 'input-container',
											inputClass: 'input'
										}}
									/>
								</div>
							</div> 
						</> }
						<div className="col-sm-4">
							<div className="form-group logisticsDatePicker">
								<label>{localString("Scheduled Date")}</label>
								<Datetime
									value={formData.scheduledDate}
									inputProps={{
										onKeyDown: e => e.preventDefault()
									}}
									dateFormat={'DD-MM-YYYY'}
									timeFormat={false}
									onChange={(val) => {
										setFormData({ ...formData, scheduledDate: new Date(val) });
									}}
									isValidDate={(current) => {
                                        if(allowedDates && allowedDates.length) {
                                            return current.isAfter(allowedDates[0]) && current.isBefore(allowedDates[allowedDates.length - 1])
                                        }else {
                                            return true;
                                        }
                                    }}
									closeOnSelect={true}
								/>
							</div>
						</div>
						<div className="col-sm-4">
							<div className="form-group">
								<label>{localString("Flow")}</label>
								<Input
									value={formData.flow}
									handleOnChange={(e) => {
										setFormData({ ...formData, flow: e.target.value });
										// if(!e.target.value) {
										// 	setError({ ...error, policeNumber: "Please Enter Police Report Number" });
										// }else {
										// 	setError({ ...error, policeNumber: null });
										// }
									}}
									isDisabled={disableFlow}
									className={{
										containerClass: 'input-container',
										inputClass: 'input'
									}}
								/>
							</div>
						</div> 
						<div className="col-sm-4">
							<div className="form-group">
								<label>{localString("Provider")}</label>
								<Dropdown
									value={formData.provider}
									options={providerOptions}
									handleOnChange={(data) => {
										setProviderObj(data);
										setFormData({ ...formData, provider: data.value });
										// if(!data) {
										// 	setError({ ...error, damageType: "Please Select Damage type" });
										// }else {
										// 	setError({ ...error, damageType: null });
										// }
									}}
									filterBy='value'
									showDownArrow={false}
								/>
								{/* { error.damageType && <span className="text-danger">{localString(error.damageType)}</span>} */}
							</div>
						</div> 
					</div>
				</div>
            </div>
            <div className='text-right' style={{ padding: '15px 90px' }}>
                { showShipmentButton && <Button
                    handleOnClick={() => createShipment()}
                    // isDisabled={disableApplyBtn}
                    // type='submit'
                    classes='button-ghost'
                >
                    {localString('Update Shipment')}
                </Button> }
                <Button
                    handleOnClick={() => closeModal()}
                    classes='button-ghost'
                >
                    {localString('Close')}
                </Button>
            </div>
        </>
    )
}
export default UpdateLogisticInput;