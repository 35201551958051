import { log } from 'async';
import React from 'react';
import {Modal, Radio, Button} from 'sfy-react';
import { displayAmount } from '../../../Utility/Utils';
import './PaymentModal.scss'

const PaymentModal = (props) => {
	const {publicurl, localString, paymentModal, closeModal, selectedPlan, planPriceBillingConfig, checkIfSpecificEMIDiscountedPriceToBeShown, 
		getSpecificEMIDiscountedPrice, getSpecificEMIDiscount, showUnDiscountedPriceSimple, showPlanPriceSimple, showDiscountPriceSimple,
		selectedPyamentType, selectPaymentPlan, continuePaymentPlan, enableContinuePaymentPlan, toggleOffers, showOffersInModal, countriesData} = props;

	return (
		<div className='ps-payment-modal'>
			<Modal showModal={paymentModal} closeModal={closeModal} showCloseBtn={true} className='payment-Modal'>
				<Modal.Header></Modal.Header>
				<Modal.Body>
					<div class="payment-plan-wrapper">
						<div class="payment-plan-title text-center">{localString("Select payment plan")}</div>
						<p class="payment-plan-sub-head text-center">{selectedPlan.PlanDisplayName || selectedPlan.PlanName}</p>
					</div>
					<div class="payment-plan-wrapper">
						<div class="radio-block">
							{
								planPriceBillingConfig?.map((price,i) => {
									return (
										<label className="payment-radio-container">
											<div class="row">
												<div class="col-sm-10">
													<span class="payment-plan-price-header">
														{
															selectedPlan.EMIDiscount && 
															<span className="strike-through">
																{/* <span class="currency-0">
																	{selectedPlan.CurrencySymbol && <span dangerouslySetInnerHTML={{__html: selectedPlan.CurrencySymbol}}></span>} 
																</span> 
																{' '}
																{price.TotalEMIAmount ? price.TotalEMIAmount : (price.EMIServiceBasePrice + price.EMIInsuranceBasePrice)} */}
																{
																	displayAmount(
																		price.TotalEMIAmount ? price.TotalEMIAmount : (price.EMIServiceBasePrice + price.EMIInsuranceBasePrice), 
																		countriesData?.countryConfig?.Locale?.DEFAULT,
																		selectedPlan.CurrencyCode) 
																}
																{' '}
																{!checkIfSpecificEMIDiscountedPriceToBeShown(price) ? '/' + localString(price.FrequencyUnitDisplay) : ''}
															</span>
														}
														{
															checkIfSpecificEMIDiscountedPriceToBeShown(price) &&
															<span> 
																{/* <span class="currency-0">
																	{selectedPlan.CurrencySymbol && <span dangerouslySetInnerHTML={{__html: selectedPlan.CurrencySymbol}}></span>} 
																</span>  */}
																{/* {' '} */}
																{
																	displayAmount(
																		getSpecificEMIDiscountedPrice(price), 
																		countriesData?.countryConfig?.Locale?.DEFAULT,
																		selectedPlan.CurrencyCode) 
																}
																/
																{localString(price.FrequencyUnitDisplay)}
																{' '}
																{/* {getSpecificEMIDiscountedPrice(price)} */}
															</span> 
														}
														{
															selectedPlan.EMIDiscount && 
															<span class="green">
																{/* <raw content={getSpecificEMIDiscount(price)}></raw> */}
																<span dangerouslySetInnerHTML={{__html: getSpecificEMIDiscount(price)}}></span>
															</span>
														}
													</span>
													<span class="payment-plan-price-sub-header">{localString("Subscription plan, cancel anytime")}</span>
												</div>
												<div class="col-sm-2">
													<Radio
														value="subscription_payment"
														selectedValue={selectedPyamentType}
														name="paymentOption"
														id={'payment_repeat_'+i}
														handleOnUpdate={(e) => {
															selectPaymentPlan(e.target.value,price)
														}}
													/>
												</div>
											</div>
										</label>
									)
								})
							}
							<label for="payment_full" class="payment-radio-container">
								<div class="row">
									<div class="col-sm-10">
										<span class="payment-plan-price-header">
											{
												selectedPlan.simpleDiscount && 
												<span class="strike-through">
													{/* <span class="currency-0">
														{selectedPlan.CurrencySymbol && <span dangerouslySetInnerHTML={{__html: selectedPlan.CurrencySymbol}}></span>} 
													</span> 
													{' '}
													{showUnDiscountedPriceSimple(selectedPlan)} */}
													{
														displayAmount(
															showUnDiscountedPriceSimple(selectedPlan), 
															countriesData?.countryConfig?.Locale?.DEFAULT,
															selectedPlan.CurrencyCode) 
													}
													{' '}
												</span> 
											}
											<span>
												{/* <span class="currency-0">
													{selectedPlan.CurrencySymbol && <span dangerouslySetInnerHTML={{__html: selectedPlan.CurrencySymbol}}></span>} 
												</span>
												{' '}
												{showPlanPriceSimple(selectedPlan)} */}
												{
													displayAmount(
														showPlanPriceSimple(selectedPlan), 
														countriesData?.countryConfig?.Locale?.DEFAULT,
														selectedPlan.CurrencyCode) 
												}
												{' '}
											</span> 
											{
												selectedPlan.simpleDiscount &&  
												<span class="green">
													{/* <raw content={showDiscountPriceSimple(selectedPlan)}></raw> */}
													<span dangerouslySetInnerHTML={{__html: showDiscountPriceSimple(selectedPlan)}}></span>
												</span>
											}
										</span>
										<span class="payment-plan-price-sub-header">
											{localString("Pay upfront")}
										</span>
									</div>
									<div class="col-sm-2">
										<Radio
											value="payment_full"
											selectedValue={selectedPyamentType}
											name="paymentOption"
											id='payment_full'
											handleOnUpdate={(e) => {
												selectPaymentPlan(e.target.value)
											}}
										/>
									</div>
								</div>
							</label>    
						</div>
						{
							selectedPlan.showViewAllOffers && 
							<div class="row">
								<Button 
									handleOnClick={(e)=> toggleOffers(e)} 
									type='button' 
									classes='button-ghost button-servify reverse-btn' 
									text={showOffersInModal ? localString("Hide Offers") : localString("View Offers")}
								/>
							</div>
						}
						<div class="row offers-in-modal" style={{display: "none"}}>
							<div class="col-sm-12">
								{
									selectedPlan?.PlanDiscounts?.PlanDiscountOffers?.map((discount) => {
										return (
											<p>
												<img src={publicurl + "/imgs/percentage.svg"} class="percent-img" alt="%"/>
												<raw content={discount.HomePageDisplayText}></raw>
											</p>
										)
									})
								}
							</div>
						</div>
						<div class="modal-footer modal-footer-payment-option">
							<Button 
								handleOnClick={continuePaymentPlan} 
								isDisabled={!enableContinuePaymentPlan} 
								type='button' 
								classes='btn button-servify header-btn button-stack sell-now-btn small-btn continue-payment-option' 
								text={localString("Continue")}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</div>
	)
}

export default PaymentModal