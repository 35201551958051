import React, {useState,useEffect} from 'react';
import './DriverList.scss';
import {Table,Pagination,Button} from 'sfy-react';

const DriverList = (props) => {
    const {localString, driverRecords, setCurrentPage} = props;
    const [problematicDrivers, setProblematicDrivers] = useState([]);
    const [installedDrivers, setInstalledDrivers] = useState([]);
    const [paginationData, setPaginationData] = useState({
        itemPerPage:10, lastPage:22, pageNo:1
    });
    const headers = ['Date', 'Signer', 'Version', 'Driver Name', 'Error Text', 'Error code', 'Display Name', 'Manufacturer', 'Device Category'];
    useEffect(() => {
        if(Object.keys(driverRecords).length > 0) {
            driverRecords.InstalledDrivers && driverRecords.InstalledDrivers.length > 0 && setInstalledDrivers(driverRecords.InstalledDrivers);
            driverRecords.ProblematicDrivers && driverRecords.ProblematicDrivers.length > 0 && setProblematicDrivers(driverRecords.ProblematicDrivers);
            driverRecords.Pagination && Object.keys(driverRecords.Pagination).length > 0 && setPaginationData(driverRecords.Pagination);
        }
    }, [driverRecords])
    return (
        <div>
            <p><h4>{localString('Driver List')}</h4></p>
            <div className="driver-container">
                <p><h3>{localString('Problematic Drivers')}</h3></p>
                <div className='driver-table'>
                    <Table isResponsive>
                        <Table.Header>
                            <Table.Row>
                                {headers && headers.length>0 && headers.map((head, index) => {
                                    return (
                                        <Table.Heading key={index}>{head}</Table.Heading>
                                    )
                                })}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {problematicDrivers && problematicDrivers.length > 0 && problematicDrivers.map((driver, index) => {return(
                                <Table.Row key={index}>
                                    <Table.Data>{driver.Date ? driver.Date : '-'}</Table.Data>
                                    <Table.Data>{driver.Signer ? driver.Signer : '-'}</Table.Data>
                                    <Table.Data>{driver.Version ? driver.Version : '-'}</Table.Data>
                                    <Table.Data>{driver.DriverName ? driver.DriverName : '-'}</Table.Data>
                                    <Table.Data>{driver.ErrorText ? driver.ErrorText : '-'}</Table.Data>
                                    <Table.Data>{driver.ErrorCode ? driver.ErrorCode : '-'}</Table.Data>
                                    <Table.Data>{driver.DisplayName ? driver.DisplayName : '-'}</Table.Data>
                                    <Table.Data>{driver.Manufacturer ? driver.Manufacturer : '-'}</Table.Data>
                                    <Table.Data>{driver.DeviceCategory ? driver.DeviceCategory : '-'}</Table.Data>
                                </Table.Row>
                            )})}
                        </Table.Body>
                    </Table>
                </div>
            </div>
            <div className="driver-container">
                <p><h3>{localString('Installed Drivers')}</h3></p>
                <div className='driver-table'>
                    <Table isResponsive>
                        <Table.Header>
                            <Table.Row>
                                {headers && headers.length>0 && headers.map((head, index) => {
                                    return (
                                        <Table.Heading key={index}>{head}</Table.Heading>
                                    )
                                })}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {installedDrivers && installedDrivers.length > 0 && installedDrivers.map((driver, index) => {return(
                                <Table.Row key={index}>
                                    <Table.Data>{driver.Date ? driver.Date : '-'}</Table.Data>
                                    <Table.Data>{driver.Signer ? driver.Signer : '-'}</Table.Data>
                                    <Table.Data>{driver.Version ? driver.Version : '-'}</Table.Data>
                                    <Table.Data>{driver.DriverName ? driver.DriverName : '-'}</Table.Data>
                                    <Table.Data>{driver.ErrorText ? driver.ErrorText : '-'}</Table.Data>
                                    <Table.Data>{driver.ErrorCode ? driver.ErrorCode : '-'}</Table.Data>
                                    <Table.Data>{driver.DisplayName ? driver.DisplayName : '-'}</Table.Data>
                                    <Table.Data>{driver.Manufacturer ? driver.Manufacturer : '-'}</Table.Data>
                                    <Table.Data>{driver.DeviceCategory ? driver.DeviceCategory : '-'}</Table.Data>
                                </Table.Row>
                            )})}
                        </Table.Body>
                    </Table>
                </div>
            </div>
            <Pagination 
                itemsPerPage={paginationData.itemPerPage} 
                pages={paginationData.lastPage} 
                totalItems={paginationData.lastPage} 
                activePage={paginationData.pageNo} 
                paginate={(pageNumber) => {setCurrentPage(pageNumber)}}
                paginationInfo={'Showing page '+paginationData.pageNo +' of '+ paginationData.lastPage}
            />
        </div>
    )
}

export default DriverList
