import React from 'react';
import {getLinkUri} from '../../../../Utility/Utils';
import {Button} from 'sfy-react';
import ShowProductDetails from './ShowProductDetails';

const RegisteredProductDetails = (props) => {
    const {consumerProductArray,baseUrl} = props; 
    return(
        <>
            {consumerProductArray && consumerProductArray.length>0 && consumerProductArray.map((product) => {
                return(
                    <>
                        {!product.hide && <div className="form-group clearfix col-sm-6 padding0">
                            <div className="row detail col-sm-12">
                                {product.label && <div class="col-sm-6 padding0">
                                        <label  className="text-capitalize consumer-label">{product.label}:</label>
                                    </div>
                                }
                                <div className={product.label?"col-sm-6 padding0":"col-sm-12 padding0"}>
                                    {
                                        !product.showLinks && <p>{product.value}</p>
                                    }
                                    <div className='row'>
                                    {
                                        product.showLinks && product.linkArray && product.linkArray.length > 0 && product.linkArray.map((link, index) => {
                                            return (
                                                <div className='col-sm-5'>
                                                    <a  href={link} target="_blank">
                                                        <Button  type='button' text={"Doc"+(index+1)} classes='button-ghost'></Button>
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                    {
                                        product.showProductDetails && <ShowProductDetails
                                            {...props}
                                            ProductDetails={product.ProductDetails}
                                        ></ShowProductDetails>
                                    }
                                </div>
                            </div>
                        </div>
                        }
                    </>
                )	
            })}
        </>
    )
}

export default RegisteredProductDetails;
