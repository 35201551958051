import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Swal from "sweetalert2"; 
import { Button, Dropdown, Modal, Input } from 'sfy-react';

import { displayError, generateUUID, getLanguageCode, returnTimezone } from '../../../../../Utility/Utils';

export default function AddComptiaPartModal(props) {
	const {
		apiCall, accessFn, localString, setLoader, showAddComptiaPartModal, setShowAddComptiaPartModal, partArr, setPartArr,
		consumerServiceRequest, updatePartStatus, baseUrl
	} = props;

	const [componentCodeOptions, setComponentCodeOptions] = useState([]);
	const [issueCodeOptions, setIssueCodeOptions] = useState([]);
	const [reproducibilityOptions, setReproducibilityOptions] = useState([]);
	const [serialNoOptionsOptions, setSerialNoOptionsOptions] = useState([]);

	const getCompModList = () => {
		setLoader(true);
		let reqObj = {};
		apiCall({
			url: 'fetchCompTiaModifiers',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					let arr = result.data.map(a => ({...a, value: a.comptiaDescription}));
					setReproducibilityOptions(arr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({
					icon: 'error',
					text: localString('Something went wrong'),
				});
			},
		});
	}

	const getCompGroupList = () => {
		setLoader(true);
		let reqObj = {
			ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID
		};
		apiCall({
			url: 'fetchReportedSymptoms',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					let arr = result.data.map(a => ({...a, value: `${a.reportedSymptomCode} ${a.reportedSymptomDesc}`}));
					setComponentCodeOptions(arr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({
					icon: 'error',
					text: localString('Something went wrong'),
				});
			},
		});
	}

	const getComptiaCode = (item, index) => {
		setLoader(true);
		let reqObj = {  
			reportedSymptomCode: item.reportedSymptomCode,
			ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID
		};
		apiCall({
			url: 'fetchReportedIssues',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					let arr = _.cloneDeep(issueCodeOptions);
					arr[index] = result.data.map(a => ({...a, value: `${a.reportedIssueCode} ${a.reportedIssueDesc}`}));
					setIssueCodeOptions(arr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({
					icon: 'error',
					text: localString('Something went wrong'),
				});
			},
		});
	}

	// const setDOApartsReorders = () => {
	// 	let partArray = _.cloneDeep(partArr);
	// 	_.forEach(partArray, function(part, i) {
	// 		part.newPartRequest = {};
	// 		part.newPartRequest.isConsignment = part.isConsignment;
	// 		if (!consumerServiceRequest.IsWarrantyApplicable) {
	// 			part.newPartRequest.isConsignment = false;
	// 		}
	// 		// Part return status code handling
	// 		if (part.isConsignment) {
	// 			part.returnStatusCode = "SDOA";
	// 		}
	// 		// new part request CO/PO selection handling
	// 		if (part?.newPartRequest?.isConsignment) {
	// 			// getConsignmentSerialNumbers(part, i);
	// 		}
	// 	});
	// 	setPartArr(partArray);
	// }

	// const getConsignmentSerialNumbers = (part, index) => {
	// 	setLoader(true);
	// 	let reqObj = {  
	// 		part: part,
	// 		PartnerServiceLocationID: consumerServiceRequest?.PartnerServiceLocationID
	// 	};
	// 	apiCall({
	// 		url: 'getPartItemsBySerialNumber',
	// 		data: reqObj,
	// 		callBack: (result) => {
	// 			setLoader(false);
	// 			if (result.success) {
	// 				if (result.data && result.data.length) {
	// 					if(part.Serialized) {
	// 						let arr = _.cloneDeep(serialNoOptionsOptions);
	// 						arr[index] = result.data.map(a => ({...a, value: a.SerialNumber}));
	// 						setSerialNoOptionsOptions(arr);
	// 					}
	// 				} else {
	// 					let arr = _.cloneDeep(partArr);
	// 					arr[index].inventory = 0;
	// 					arr[index].newPartRequest.isConsignment = false;
	// 					setPartArr(arr);
	// 				}	
	// 			} else {
	// 				let arr = _.cloneDeep(partArr);
	// 				arr[index].inventory = 0;
	// 				arr[index].newPartRequest.isConsignment = false;
	// 				setPartArr(arr);
	// 			}
	// 		},
	// 		errorCB: (err) => {
	// 			setLoader(false);
	// 			Swal.fire({
	// 				icon: 'error',
	// 				text: localString('Something went wrong'),
	// 			});
	// 		},
	// 	});
	// }

	const setDOApartsReorders = async () => {
		let partArray = _.cloneDeep(partArr);
		let serialNoOptions = [];
		setLoader(true);
		for(let i = 0; i <= partArray.length - 1; i++) {
			partArray[i].newPartRequest = {};
			partArray[i].newPartRequest.isConsignment = partArray[i].isConsignment;
			if (!consumerServiceRequest.IsWarrantyApplicable) {
				partArray[i].newPartRequest.isConsignment = false;
			}
			// Part return status code handling
			if (partArray[i].isConsignment) {
				partArray[i].returnStatusCode = "SDOA";
			}
			// new part request CO/PO selection handling
			if (partArray[i]?.newPartRequest?.isConsignment) {
				const result = await getConsignmentSerialNumbers(partArray[i], i);
				if (result?.success) {
					if (result.data && result.data.length) {
						if(partArray[i].Serialized) {
							serialNoOptions[i] = result.data.map(a => ({...a, value: a.SerialNumber}));
						}
					} else {
						partArray[i].inventory = 0;
						partArray[i].newPartRequest.isConsignment = false;
					}	
				} else {
					partArray[i].inventory = 0;
					partArray[i].newPartRequest.isConsignment = false;
				}
			}
		}
		setLoader(false);
		setSerialNoOptionsOptions(serialNoOptions);
		setPartArr(partArray);
	}

	const getConsignmentSerialNumbers = async (part, index) => {
		let reqObj = {  
			part: part,
			PartnerServiceLocationID: consumerServiceRequest?.PartnerServiceLocationID
		};
		let apiVersion = '/api/v1/';
        let UUID = generateUUID();
		let url = baseUrl + apiVersion + 'ConsumerServicerequest/getPartItemsBySerialNumber'
        let headers = {
            'Content-Type':'application/json; charset=UTF-8',
            'UUID': UUID,
			'timezone': returnTimezone(),
			'languagecode': getLanguageCode()
        };
		const response = await fetch(url, {
			headers: headers,
			body: JSON.stringify(reqObj),
			method: "POST",
		})
		const data = await response.json();
		return data;
	}

	useEffect(() => {
		if (showAddComptiaPartModal) {
			getCompModList(); 
			getCompGroupList();
			setDOApartsReorders();
		}
	}, [showAddComptiaPartModal]);

	const handleReorderRadio = (value, k) => {
		let arr = _.cloneDeep(partArr);
		arr[k].returnStatusCode = value;																	
		setPartArr(arr);
	}

	const updatePartsStatusForDOA = () => {
		let error = false;
		let partArray = _.cloneDeep(partArr);
		_.forEach(partArray, function(part, i) {
			if (part.action == 'doa') {
				if (!part.comptiaGroup) {
					Swal.fire({
						icon: 'error',
						title: '',
						text: localString('Please Enter Component Code'),
						confirmButtonText: localString('OK')
					});
					error = true;
				} else if (!part.comptiaCode) {
					Swal.fire({
						icon: 'error',
						title: '',
						text: localString('Please Enter Issue Code'),
						confirmButtonText: localString('OK')
					});
					error = true;
				} else if(!part.comptiaModifier) {
					Swal.fire({
						icon: 'error',
						title: '',
						text: localString('Please enter Reproducibility'),
						confirmButtonText: localString('OK')
					});
					error = true;
				} else if(part.newPartRequest && part.newPartRequest.isConsignment == true && part.Serialized && !part.newPartRequest.kgbSerialNumber) {
					Swal.fire({
						icon: 'error',
						title: '',
						text: localString('Your inventory does not contain stock for the part number selected. Please add consignment stock to your inventory to proceed.'),
						confirmButtonText: localString('OK')
					});
					error = true;
				} else if(part.newPartRequest && part.newPartRequest.isConsignment == true && !part.Serialized && part.inventory == 0) {
					Swal.fire({
						icon: 'error',
						title: '',
						text: localString('Your inventory does not contain stock for the part number selected. Please add consignment stock to your inventory to proceed.'),
						confirmButtonText: localString('OK')
					});
					error = true;
				} else if(!part.returnStatusCode) {
					Swal.fire({
						icon: 'error',
						title: '',
						text: localString('Please select Part Return Status Code'),
						confirmButtonText: localString('OK')
					});
					error = true;
				}
			}
		});
		if (!error) {
			Swal.fire({
				title: localString('Are you sure?'),
				text: localString('The parts will be returned to Store'),
				icon: 'question',
				confirmButtonText: localString('Yes'),
				cancelButtonText: localString('No'),
				showConfirmButton: true,
				showCancelButton: true,
			}).then(swalResult => {
				if(swalResult.isConfirmed){
					updatePartStatus(partArray);
				}
			});
		}

	}

	return (
		<>
			<Modal
				className='addComptiaPartModal'
				showModal={showAddComptiaPartModal}
				closeModal={() => setShowAddComptiaPartModal(false)}
				showCloseBtn={true}
			>
				<Modal.Header>
					<div className="modal-title">{localString("Enter DOA Parts Details")}</div>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-body">
						{
							partArr && partArr.length > 0 && partArr.map((part, k) => {
								if(part.action == 'doa') {
									return (
										<div className='doaCard'>
											<div className='text-bold'>{part?.Name}</div>
											<div className='row' style={{padding: '5px 0px'}}>
												<div className="col-sm-4">
													<div className="form-group">
														<label> {localString("Component Code")}</label>
														<Dropdown
															value={part.componentCodeVal}
															options={componentCodeOptions}
															handleOnChange={(data) => {
																let arr = _.cloneDeep(partArr);
																arr[k].componentCodeVal = data.value;
																arr[k].comptiaGroup = _.cloneDeep(data);																		
																arr[k].comptiaCode = undefined;
																arr[k].issueCodeVal = undefined;
																setPartArr(arr);
																getComptiaCode(data, k);
															}}
															filterBy='value'
															showDownArrow={false}
															showTooltip={true}
															placeholder={localString("Component Code")}
														/>
													</div>
												</div> 
												<div className="col-sm-4">
													<div className="form-group">
														<label> {localString("Issue Code")}</label>
														<Dropdown
															value={part.issueCodeVal}
															options={issueCodeOptions[k] || []}
															handleOnChange={(data) => {
																let arr = _.cloneDeep(partArr);
																arr[k].issueCodeVal = data.value;
																arr[k].comptiaCode = _.cloneDeep(data);																		
																arr[k].comptiaCode.comptiaCode = data.code;																		
																setPartArr(arr);
															}}
															filterBy='value'
															showDownArrow={false}
															showTooltip={true}
															placeholder={localString("Issue Code")}
														/>
													</div>
												</div> 
												<div className="col-sm-4">
													<div className="form-group">
														<label> {localString("Reproducibility")}</label>
														<Dropdown
															value={part.reproducibilityVal}
															options={reproducibilityOptions}
															handleOnChange={(data) => {
																let arr = _.cloneDeep(partArr);
																arr[k].reproducibilityVal = data.value;
																arr[k].comptiaModifier = _.cloneDeep(data);																		
																setPartArr(arr);
															}}
															filterBy='value'
															showDownArrow={false}
															showTooltip={true}
															placeholder={localString("Reproducibility")}
														/>
													</div>
												</div> 
											</div>
											<div className='text-bold'>
												{localString("Return Details")}
											</div>
											<div>{localString("Return Part as") + ':'}{ part.isConsignment && <span className='text-bold'>{' ' + localString("Stock DOA")}</span>}</div>
											{ !part.isConsignment && 
												 <div className='row'>
													<div className="col-sm-6">
														<input 
															value="DOA" 
															type="radio" 
															style={{marginRight: '3px'}}
															name={"partReorderRadioBtn_"+k} 
															id={"partReorderRadioBtn_DOA_"+k} 
															checked={part?.returnStatusCode === 'DOA'}
															onClick={() => handleReorderRadio('DOA', k)}
														/>
														<label for={"partReorderRadioBtn_DOA_"+k}>{localString("DOA")}</label>
													</div>
													<div className="col-sm-6">
														<input
															value="SDOA"
															type="radio"
															style={{marginRight: '3px'}}
															name={"partReorderRadioBtn_"+k}
															id={"partReorderRadioBtn_SDOA_"+k}
															checked={part?.returnStatusCode === 'SDOA'}
															onClick={() => handleReorderRadio('SDOA', k)}
														/>
														<label for={"partReorderRadioBtn_SDOA_"+k}>{localString("Stock DOA")}</label>
													</div>
											   </div>
											}
											{ consumerServiceRequest.IsWarrantyApplicable && <>
												<div className='text-bold' style={{marginTop: '15px'}}>{localString("Reorder Details")}</div>
												<div>{localString("Reorder Part as") + ': '}<span className='text-bold'>{(part.newPartRequest && part.newPartRequest.isConsignment ? localString('Consignment Order') : localString('Purchase Order'))}</span></div>
											</> }
											{ (consumerServiceRequest.IsWarrantyApplicable && part.Serialized && part?.newPartRequest?.isConsignment ) && <>
												<div className='row'>
													<div className='col-sm-6'>
														<div className="form-group">
															<label> {localString("KGB Serial Number")}</label>
															<Dropdown
																value={part?.newPartRequest?.kgbSerialNumber || ''}
																options={serialNoOptionsOptions[k] || []}
																handleOnChange={(data) => {
																	let arr = _.cloneDeep(partArr);
																	if (arr?.[k].PartID == part.PartID) {
																		arr[k].newPartRequest.kgbSerialNumber = data.SerialNumber;
																		arr[k].newPartRequest.PartItemID = data.PartItemID;
																		setPartArr(arr);
																	}																
																}}
																// handleOnFocus={() => {
																// 	getConsignmentSerialNumbers(part, k);
																// }}
																filterBy='value'
																showDownArrow={false}
																showTooltip={true}
																placeholder={localString("KGB Serial Number")}
															/>
														</div>
													</div>
												</div>
											</> }
										</div>
									);
								}
							})
						}								
						<div className='paddingB10'>
							<Button 
								className="btn button-servify pull-right" 
								onClick={() => updatePartsStatusForDOA(partArr)}
							>
								{localString("Save")}
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}