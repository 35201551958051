import React,{useCallback,useRef,useState,useEffect} from 'react';
import './BillingDetails.scss';
import {Input,Button,Dropdown,Radio,setAutocomplete} from 'sfy-react';
import AddNewBillingAddress from './AddNewBillingAddress';
import { isEmpty } from 'lodash';

const BillingDetailsJSX = (props) => {
    const {localString,billingDetailList,inputLabels,inputData,BillingDetailListClick,billingDetailEditable,SaveBillingDetail, selectedBilling,
        addNewGst,AddNewBilling,stateList,onFormSaveClick,resetAddBilling
    } = props;
    
    return (
        <div className="request-panel-details-tag billing-details-div">
            <div className="col-sm-12 BillingDetailList" id="BillingDetailList">
            {
                billingDetailList && billingDetailList.length > 0 && 
                <div className="row">
                {
                    billingDetailList.map(item=>{
                        return(
                            <div className="col-sm-4" onClick={(e)=>BillingDetailListClick(item)}>
                                <div className={item.IsSelected ? 'item address-item item-active-border' : 'item address-item '}>
                                    <p className="bold">{item.BillingName}</p>
                                    <p>{item?.TaxDetails?.[0] && item.TaxDetails?.[0]?.TaxDetailValue}</p>
                                    <p className="address-p">
                                        {item.Address} {item.Landmark} {!item.skipZipCodeCheck ? '-':''} {!item.skipZipCodeCheck ? item.PinCode: ''}</p>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            }
            {
                billingDetailList && !billingDetailList.length > 0 &&
                <div className="empty-message"><p>{localString("No Billing Detail found")}</p></div>
            }
            </div>
            {
                billingDetailEditable && 
                <div class="col-sm-12 button-padding" >
                    {
                        billingDetailList && billingDetailList.length > 0 &&
                        <Button className="btn button-servify button-stack pull-right" isDisabled={isEmpty(selectedBilling)} handleOnClick={SaveBillingDetail} text={localString("Select Billing Detail")}/>
                    }
                    {
                        !addNewGst &&    
                        <Button className="btn button-servify button-stack pull-right" handleOnClick={AddNewBilling} text={localString("Add New Billing Detail")} />
                    }
                </div>
            }
            {
                addNewGst && 
                <AddNewBillingAddress 
                    {...props}
                />
            }
            
        </div>
    )
}

export default BillingDetailsJSX
