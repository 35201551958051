import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import {Table, Loader} from 'sfy-react';
import { displayError } from '../../../../../Utility/Utils';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';

const PartnerStatusUpdate = (props) => {
	const { ConsumerServiceRequestID, localString, apiCall} = props;
	const [partnerStatusData, setPartnerStatusData] = useState();
	const [loader,setLoader] = useState(false);

	useEffect(() => {
		getExternalRequestUpdates();
	}, [])
	
	const classObject = {
		table:"table table-striped complaint-details-table request-table"
	}

	const getExternalRequestUpdates = () => {
		let data = {
			ConsumerServiceRequestID: ConsumerServiceRequestID
		}
		setLoader(true);
		apiCall({
			url:'getExternalRequestUpdates',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let partnerData = result.data;
					setPartnerStatusData(partnerData);
				}else{
					setPartnerStatusData({})
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	return (
		<>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<div style={{margin:"-10px"}}>
				<PageContentWrapper 
					bodyClassName="panel-body-no-padding" 
					title={localString("Partner Status Update")} 
					customSymbol="glyphicon glyphicon-refresh glyphicon-refresh-animate cursor-pointer"
					showDownloadButtonIcon={true}
					editDeviceClick={getExternalRequestUpdates}
				>
					<div className="request-panel request-complaint-div">
						{
							partnerStatusData && partnerStatusData.externalReferenceID && 
							<Table className={classObject}>
								<Table.Body>
									<Table.Row>
										<Table.Data className="table-keys text-capitalize">{localString("External ReferenceID")}</Table.Data>
										<Table.Data>{partnerStatusData && partnerStatusData.externalReferenceID}</Table.Data>
									</Table.Row>
									<Table.Row>
										<Table.Data className="table-keys text-capitalize">{localString("IMEI / Serial No")}</Table.Data>
										<Table.Data>{partnerStatusData && partnerStatusData.imeiOrSerialNo}</Table.Data>
									</Table.Row>
									<Table.Row>
										<Table.Data className="table-keys text-capitalize">{localString("Request Order No")}</Table.Data>
										<Table.Data>{partnerStatusData && partnerStatusData.serviceOrderNumber}</Table.Data>
									</Table.Row>
									<Table.Row>
										<Table.Data className="table-keys text-capitalize">{localString("Repair Status Code")}</Table.Data>
										<Table.Data>{partnerStatusData && partnerStatusData.repairStatusCode}</Table.Data>
									</Table.Row>
									<Table.Row>
										<Table.Data className="table-keys text-capitalize">{localString("Current Request Status")}</Table.Data>
										<Table.Data>{partnerStatusData && partnerStatusData.status}</Table.Data>
									</Table.Row>
								</Table.Body>
							</Table>
						}
						{
							(!partnerStatusData || (partnerStatusData && Object.keys(partnerStatusData).length == 0)|| (partnerStatusData && !partnerStatusData.externalReferenceID)) &&
							<div className="charges-panel-subheader" style={{margin:"10px"}}>{localString("No partner details")}</div>
						}
					</div>
				</PageContentWrapper>
			</div>
			
		</>
	)
}

export default PartnerStatusUpdate