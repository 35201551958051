import React, {useState,useEffect} from 'react';
import ReactDOM from "react-dom";
import { Input, Modal, ModalBody, ModalHeader, Button, Loader, Dropdown, DatePicker, Checkbox} from "sfy-react";
import './AddConsumerDiagnosis.scss';
import {displayError, checkMobile, checkEmail} from '../../Utility/Utils';
import Swal from "sweetalert2";
import moment from 'moment';

const AddConsumerDiagnosis = (props) => {
    const {showModal, onCloseClick, localString, apiCall, imeiDiagnosisPageUrl, baseUrl, accessFn} = props;
    const [showLoader ,setLoader] = useState(false);
    const [customerName ,setCustomerName] = useState('');
    const [isNameValid ,setIsNameValid] = useState(false);
    const [customerMobile ,setCustomerMobile] = useState('');
    const [isMobileValid ,setIsMobileValid] = useState(false);
    const [customerEmail ,setCustomerEmail] = useState('');
    const [isEmailValid ,setIsEmailValid] = useState(false);
    const [alternateNo, setAlternateNo] = useState('');
    const [model ,setModel] = useState('');
    const [isModelValid ,setIsModelValid] = useState(false);
    const [incidentID ,setIncidentID] = useState('');
    const [isIncidentIDValid ,setIsIncidentIDValid] = useState(false);
    const [orderID ,setOrderID] = useState('');
    const [isOrderIDlValid ,setIsOrderIDValid] = useState(false);
    const [workOrderID ,setWorkOrderID] = useState('');
    const [isWorkOrderIDValid ,setIsWorkOrderIDValid] = useState(false);
    const [Dop ,setDop] = useState('');
    const [isDopValid ,setIsDopValid] = useState(false);
    const [modelList, setModelList] = useState([]);
    const [issueList, setIssueList] = useState([]);
    const [issue ,setIssue] = useState('');
    const [selectedIssues, setSelectedIssues] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [isRvpEligible, setIsRvpEligible] = useState(false);
    const [isRvpChecked, setIsRvpChecked] = useState(false);

    const closeModal = () => {
        onCloseClick();
        resetFields();
    };

    const resetFields = () => {
        setCustomerName('');
        setIsNameValid(false);
        setCustomerEmail('');
        setIsEmailValid(false);
        setCustomerMobile('');
        setIsMobileValid(false);
        setAlternateNo('');
        setModel('');
        setIsModelValid(false);
        setIncidentID('');
        setIsIncidentIDValid(false);
        setOrderID('');
        setIsOrderIDValid(false);
        setWorkOrderID('');
        setIsWorkOrderIDValid(false);
        setDop('');
        setIsDopValid(false);
        setIssue('');
        setSelectedIssues([]);
        setIsSaveDisabled(true);
    };

    const formInputs = [
        {
            value: customerName,
            label: localString('Name'),
            type: 'text',
            isValid: isNameValid,
            placeholder: 'Customer name',
            changeFunc: (val) => {
                setIsNameValid(val ? true : false);
                setCustomerName(val);
            },
            showErrorMesage: true,
            errorMessage: localString('Please enter valid name')
        },
        {
            value: customerEmail,
            label: localString('Email ID'),
            type: 'text',
            isValid: isEmailValid,
            placeholder: 'Customer@email.com',
            changeFunc: (val) => {
                setIsEmailValid(checkEmail(val) ? true : false);
                setCustomerEmail(val);
            },
            showErrorMesage: true,
            errorMessage: localString('Please enter valid email')
        },
        {
            value: customerMobile,
            label: localString('Mobile No.'),
            type: 'text',
            isValid: isMobileValid,
            placeholder: 'Mobile No.',
            changeFunc: (val) => {
                setIsMobileValid(checkMobile(val) && (val.length == 10) ? true : false);
                setCustomerMobile(val);
            },
            showErrorMesage: true,
            errorMessage: localString('Please enter valid mobile no.')
        },
        {
            value: model,
            label: localString('Model'),
            type: 'dropdown',
            isValid: isModelValid,
            placeholder: 'Select a model',
            changeFunc: (val) => {
                setIsModelValid(val ? true : false);
                setModel(val);
            },
            showErrorMesage: true,
            errorMessage: localString('Please select model from dropdown')
        },
        {
            value: alternateNo,
            label: localString('Alternate No./Landline No.'),
            placeholder: 'Alternate No.',
            changeFunc: (val) => {
                setAlternateNo(val);
            }
        },
        {
            value: incidentID,
            label: localString('Incident ID'),
            type: 'text',
            isValid: isIncidentIDValid,
            placeholder: 'Incident ID',
            changeFunc: (val) => {
                setIsIncidentIDValid(val ? true : false);
                setIncidentID(val);
            },
            showErrorMesage: true,
            errorMessage: localString('Please enter indident ID')
        },
        {
            value: orderID,
            label: localString('Order ID'),
            type: 'text',
            isValid: isOrderIDlValid,
            placeholder: 'Order ID',
            changeFunc: (val) => {
                setIsOrderIDValid(val ? true : false);
                setOrderID(val);
            },
            showErrorMesage: true,
            errorMessage: localString('Please enter order ID')
        },
        {
            value: workOrderID,
            label: localString('Work Order ID'),
            type: 'text',
            isValid: isWorkOrderIDValid,
            placeholder: 'Work order ID',
            changeFunc: (val) => {
                setIsWorkOrderIDValid(val ? true : false);
                setWorkOrderID(val);
            },
            showErrorMesage: true,
            errorMessage: localString('Please enter work order ID')
        },
        accessFn('DOA_FOR_ENQUIRY') && {
            value: Dop,
            label: localString('Date of Purchase'),
            type: 'date',
            isValid: isDopValid,
            placeholder: 'DD-MM-YYYY',
            changeFunc: (val) => {
                setIsDopValid(val ? true : false);
                setDop(val);
                setIsRvpChecked(false);
                val = moment([val.getFullYear(),val.getMonth(),val.getDate()]);
                let today = new Date();
                today = moment([today.getFullYear(),today.getMonth(),today.getDate()]);
                let diff = today.diff(val, 'days');
                setIsRvpEligible(diff > 10 ? true : false);
            },
            showErrorMesage: true,
            errorMessage: localString('Please enter date of purchase'),
            isRvpEligible: isRvpEligible,
            rvpCheckbox: {
                label: localString('Eligible for RVP'),
                type: 'checkbox',
                isChecked: isRvpChecked,
            }
        }
    ];

    useEffect(() => {
        var inputCount = 0;
        var inputLength = accessFn('DOA_FOR_ENQUIRY') ? (formInputs.length - 1) : (formInputs.length - 2);
        formInputs.forEach((obj) => {
            if(obj.isValid) {
                inputCount++
            }
        });
        setIsSaveDisabled(!(inputCount >= inputLength && selectedIssues.length > 0))
    }, [customerEmail,customerName,customerMobile,model,orderID,workOrderID,incidentID,Dop,selectedIssues]);

    useEffect(() => {
        if(accessFn('ADD_DIAGNOSIS_CUSTOMER')) {
            getIssueList();
            getModelList();
        }
    }, []);

    const getIssueList = () => {
        var data = {
            ProductSubCategoryID: 12
        };
		setLoader(true);
		apiCall({
			url:'getIssueList',
			data: data,
			callBack:(result)=>{
                setLoader(false);
				if(result.success && result.data){
                    var newArray = [];
                    if(result.data.length > 0) {
                        result.data.forEach((obj) => { newArray.push({value: obj.Issue, ProductSubCategoryIssueID: obj.ProductSubCategoryIssueID}) });
                        setIssueList(newArray);
                    }
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
    };

    const getModelList = () => {
        var data = {};
        setLoader(true);
		apiCall({
			url:'getModelList',
			data: data,
			callBack:(result)=>{
                setLoader(false);
				if(result.success && result.data){
                    var newArray = [];
                    if(result.data.length > 0) {
                        result.data.forEach((obj) => { newArray.push({value: obj.Model}) });
                        setModelList(newArray);
                    }
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
    };

    const addIssue = () => {
        var element = issueList.find((obj) => obj.value === issue);
        if(element) {
            var newObj = {
                IssueRemark: '',
                IssueText: element.value,
                ProductSubCategoryIssueID: element.ProductSubCategoryIssueID
            }
            element && setSelectedIssues((prevData) => [...prevData, newObj]);
        }
    };

    const remarkChange = (val, index) => {
        var newArry = selectedIssues;
        newArry[index].IssueRemark = val;
        setSelectedIssues(() => [...newArry]);
    };

    const raiseEnquiryRequest = () => {
        var data = {
            "Name": customerName,
            "CountryCode": "+91",
            "MobileNo": customerMobile,
            "AlternateMobileNo": alternateNo,
            "EmailID": customerEmail,
            "DeviceType": 'android',
            "FirstRegisteredFrom": 'WebApp',
            "IssueArray": selectedIssues,
            "ExternalReferenceID": incidentID,
            "Model": model,
            "WorkOrderID": workOrderID,
            "OrderID": orderID,
            "DateOfPurchase": accessFn('DOA_FOR_ENQUIRY') ? moment(Dop).format('DD-MM-YYYY') : undefined,
            "IsReplaceable": isRvpChecked ? true : false
        };
        setLoader(true);
		apiCall({
			url:'raiseEnquiryRequest',
			data: data,
			callBack:(result)=>{
                setLoader(false);
				if(result.success){
                    closeModal();
                    Swal.fire({  
                        icon: 'success',  
                        text: localString("Consumer successfully added and SMS sent!"),  
                    }); 
				} else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
    };

    return (
        <Modal 
            showModal={showModal}
            showCloseBtn={true}
            backdropClassName={"consumer-screen-modal"}
            closeBtnClassName={"modal-close"}
            closeModal={closeModal}
            className={"modal-wrapper"}
        >
            <ModalHeader>
                <h4 class="modal-title">
                    {localString("Add Consumer")}
                </h4>
            </ModalHeader>
            <ModalBody>
                <div className="modal-section">
                    <div className="section-header">{localString("Consumer Details")}</div>
                    <div className="section-wrapper clearfix">
                        {formInputs && formInputs.length > 0 && formInputs.map((obj, index) => {
                            return(obj && obj.type &&
                                <div className="form-input-wrapper col-sm-6 col-xs-12">
                                    {obj.type === 'text' &&
                                        <Input
                                            value = {obj.value}
                                            handleOnChange = {(e) => obj.changeFunc(e.target.value)}
                                            label = {obj.label}
                                            maxLength = {obj.maxlength}
                                            key={index}
                                            placeholder={obj.placeholder}
                                            message={!obj.isValid && obj.value.length > 0 && obj.showErrorMesage && {type: 'error', value: obj.errorMessage}}
                                        ></Input>
                                    }
                                    {obj.type === 'dropdown' &&
                                    <>
                                        <label className="dropdown-label">{obj.label}</label>
                                        <Dropdown
                                            value={model}
                                            options={modelList}
                                            handleOnChange={(val) => {obj.changeFunc(val.value)}}
                                            filterBy='value'
                                            searchText='Search text'
                                            placeholder={obj.placeholder}
                                        ></Dropdown>
                                    </>
                                    }
                                    {obj.type === 'date' &&
                                    <>
                                        <label className="dropdown-label">{obj.label}</label>
                                        <DatePicker
                                            date={Dop}
                                            format='dd-mm-yyyy'
                                            handleOnChange={(val) => {obj.changeFunc(val)}}
                                            placeholder={obj.placeholder}
                                        >
                                           {obj.isRvpEligible && <Checkbox
                                                value={obj.rvpCheckbox.isChecked}
                                                name='first'
                                                handleOnChange={() => {
                                                setIsRvpChecked(!obj.rvpCheckbox.isChecked)
                                                }}
                                            >{obj.rvpCheckbox.label}</Checkbox>}
                                        </DatePicker>
                                    </>
                                    }
                                </div>
                        )})}
                    </div>
                    
                </div>
                <div className="modal-section issue-section">
                    <div className="section-header">{localString("Issues")}</div>
                    <div className="section-wrapper clearfix">
                        <div className="row">
                            <div className="form-input-wrapper col-sm-6 col-xs-12">
                                <Dropdown
                                    value={issue}
                                    options={issueList}
                                    handleOnChange={(val) => {setIssue(val.value)}}
                                    filterBy='value'
                                    searchText='Select an issue'
                                    placeholder={'Select an issue'}
                                ></Dropdown>
                            </div>
                            <div className="form-input-wrapper col-sm-6 col-xs-12">
                                <Button type='button' classes="button-servify-solid" text={localString("Add")} handleOnClick={addIssue}></Button>
                            </div>
                        </div>
                        <div className="row">
                            {selectedIssues && selectedIssues.length > 0 && selectedIssues.map((obj, index) => {
                                return(
                                    <div className="form-input-wrapper col-sm-6 col-xs-12">
                                        <Input
                                            value = {obj.IssueRemark}
                                            handleOnChange = {(e) => remarkChange(e.target.value, index)}
                                            label = {"Remark for " + obj.IssueText}
                                            key={index}
                                        ></Input>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="modal-footer text-right">
                            <Button type='button' classes="button-servify-solid" isDisabled={isSaveDisabled} handleOnClick={raiseEnquiryRequest} text={localString("Save & Send SMS/Email")}></Button>
                        </div>
                    </div>
                </div>
                <div className="modal-footer text-right">
                    <Button type='button' classes="button-servify-solid" handleOnClick={closeModal} text={localString("Close")}></Button>
                </div>
                {showLoader && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}
            </ModalBody>
        </Modal>
    )
}

export default AddConsumerDiagnosis
