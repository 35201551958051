import React, { useState,useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
import './UploadClaimDocumentModal.scss';
import Swal from "sweetalert2";
import { returnTimezone,displayError,generateUUID } from '../../../../../Utility/Utils';
import _ from 'lodash';
import {Loader} from 'sfy-react';
import ClaimDocumentUpload from './ClaimDocumentUpload';


const UploadClaimDocumentInput=(props)=>{
	// removed claimRequestNonWorkshop, setShowModal, getRequestById, ConsumerServiceRequestID, showModal
const {
	apiCall,closeModal,localString,authorization,claimRequestNext,docServiceUrl,
	reqiredDocumentsMaster, useDocServiceXiaomi, consumerServiceRequest, nextStatus
} = props;
const [showLoader, setLoader] = useState(false);
const [showUploadButton, setUploadShowButton] = useState(false);
const [uplodedFile, setUplodedFile] = useState({
	fileDetails :"",
	fileName:"",
	fileType:""
})

const selectedFile = (e) =>{
	if(e && e.target && e.target.value){
    const file = e.target.files[0];
	setUplodedFile({...uplodedFile,
		fileDetails:file,
		fileName:file.name,
		fileType:file.type
	});
	setUploadShowButton(true);
} else {
	setUplodedFile("");
	setUploadShowButton(false);
	// validateFile(file);
       }
}

const sendFile = function(e) {
	e.preventDefault();
	let fileType = uplodedFile.fileType;
	let ext = fileType.split("/").length ? "."+fileType.split("/")[fileType.split("/").length - 1] : ""
	let key = "FEWEBA" + generateUUID() + ext;
	//$("#loader").show();
	setLoader(true);
	let dataImgType = 'image';
	if (fileType == 'application/pdf') {
		  dataImgType = 'pdf';
	}
	if (useDocServiceXiaomi) {
		if (docServiceUrl == '') {
			return;
		}
		let dataNew = new FormData();
		dataNew.append('file', uplodedFile.fileDetails);
		dataNew.append('docType', 'ConsumerServiceRequestDocument');
		dataNew.append('clientRefId', key);
		dataNew.append('entity-id', consumerServiceRequest.ConsumerServiceRequestID);
		dataNew.append('entity-type', 'ConsumerServiceRequestID');
		let url = docServiceUrl + 'internal/document/upload';
		fetch(url, {
			method: 'POST',
			headers: {
				'App': 'WebApp',
				'module': 'WebApp',
				'authorization': authorization
			},
			body: dataNew
		})
		.then(res => res.json())

		// error: function (err) {
		// 	swal('', localString(displayError(err)), 'error');
		// },
		.then(result => {
			// setLoader(false);
			if(result.success && result.data){
				let documentMeta = _.find(reqiredDocumentsMaster, function(documentMaster) {
					return documentMaster.DocumentType === 'Invoice'
				})
				let request = {};
				request.claimDocs =[];
				let uploadURL = result.data.uploadURL;
				let fileNameUUID = result.data.docServiceUUID;
				if (consumerServiceRequest && documentMeta) {
					request.claimDocs.push({
						ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
						ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID,
						// ConsumerServiceRequestDocumentID: 0,
						DocumentID: documentMeta.DocumentID,
						FilePath: uploadURL,
						FileName: fileNameUUID,
						Type: dataImgType,
						Tag: documentMeta.DocumentType,
						app: 'WebApp'
					});
					apiCall({
						url: 'updateClaimDocumentForDS',
						data: request,
						callBack:(result)=> {
							setLoader(false); 
							if (result.success) {
								closeModal();
								claimRequestNext(nextStatus);
							} else {
								Swal.Fire('', localString(displayError(result)), 'error');
							}
						}
					});
				}
			}
			else{
				setLoader(false);
				Swal.fire({
					icon: "error",
					text: localString(displayError(result)),
				});
				// Swal.fire({  
				// 	icon: 'error',  
				// 	text: localString("No file found","Please upload a csv file"),  
				// })
			}
		})					
	}
	else {
		  apiCall({
			url: 'uploadDocuments',
			data: {Type: uplodedFile.fileType, Key: key},
			callBack:(result)=> {
				// setLoader(false); 
				if (result.success) {
					let xhr = new XMLHttpRequest();
					xhr.open("PUT", result.data);
					xhr.setRequestHeader("Cache-Control", "public,max-age=3600");
					xhr.setRequestHeader('x-amz-acl', 'public-read');
					xhr.onload = function () {
						if (xhr.status === 200) {
							let documentMeta = _.find(reqiredDocumentsMaster, function(documentMaster) {
								return documentMaster.DocumentType === 'Invoice'
							})
							let request = {};
							request.claimDocs =[];
							if(consumerServiceRequest && documentMeta){
								request.claimDocs.push({
									ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
									ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID,
									// ConsumerServiceRequestDocumentID: 0,
									DocumentID: documentMeta.DocumentID,
									FilePath: key,
									Type: dataImgType,
									Tag: documentMeta.DocumentType,
									app: 'WebApp'
								});
								//$("#loader").show();
								apiCall({
									url: 'updateClaimDocument',
									data: request,
									callBack:(result)=>{
										setLoader(false); 
										if (result.success) {
											closeModal();
										    claimRequestNext(nextStatus);
										} else {
											Swal.fire('', localString(result.msg), 'error');
										}
									}
								});
							}
						}
					}.bind(this);
					xhr.onerror = function () {
					}.bind(this);
					xhr.send(uplodedFile.fileDetails);
				}
			}
		});
	}
}


		return (
		<div>
			<ClaimDocumentUpload {...props } showUploadButton={showUploadButton} acceptFileType=".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv" sendFile={sendFile} selectedFile={selectedFile} uplodedFile={uplodedFile}></ClaimDocumentUpload>      
        </div>
		)
}

export default UploadClaimDocumentInput;

