import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import {checkEmail,checkMobile,checkAlphaNumericName,displayError,returnLocalDateTime, checkMobileNumber} from '../../../Utility/Utils';
import PageHeader from '../../../components/PageHeader/PageHeader';
import ConsumerDetails from './ConsumerDetails';
import NavBar  from '../../../components/NavBar/NavBar';
import RegisteredProducts from './RegisteredProducts/RegisteredProducts';
import ConsumerRequest from './ConsumerRequest/ConsumerRequest';
import CallLogPage from '../../../components/ConsumerCallLogs/CallLogsPage/CallLogPage';
import UnRegisteredProduct from './UnRegistredProducts/UnRegisteredProduct';
import ConsumerPlans from './ConsumerPlans/ConsumerPlans';
import { sortBy } from 'lodash';

const ConsumerViewDetailsPage = (props) => {
    const {localString, apiCall, useQuery, titleHeader, accessFn, qFilter} = props;

	let query = useQuery();
	let consumerID  = query.get("cid");
	let userFilterImei = query.get('imei');
    let userFilterImei2 = query.get('imei2');

    const [consumerDetails, setConsumerDetails] = useState();
	const [editClick, setEditClick] = useState(false);
	const [showLoader ,setLoader] = useState(false);
	const [editSaveData, setEditSaveData] = useState({})
	const [showRegisteredProducts, setShowRegisteredProducts] = useState(true);
    const [showUnregisteredProducts, setShowUnregisteredProducts] = useState(false);
    const [showCallhistory, setShowCallhistory] = useState(false);
    const [showRequests, setShowRequests] = useState(false);
	const [showPlans, setShowPlans] = useState(false)
    const [activeNav, setActiveNav] = useState("registeredproducts");
	const [specificCountryCode, setSpecificCountryCode] = useState('IN');
	const [mobileMaxLength, setMobileMaxLength] = useState([])

	const defaultText = localString("Not Updated");
	let consumerDataArray = [
		{
			labelText:localString("Name"),
			dataKey:"Name",
			type:"input",
			value:(consumerDetails && consumerDetails.Name) || defaultText,
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['Name'] : val  });
			}
		},
		{
			labelText:localString("Alternate Mobile No"),
			dataKey:"AlternateMobileNo",
			type:"input",
			value:(consumerDetails && consumerDetails.AlternateMobileNo) || defaultText,
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['AlternateMobileNo'] : val  });
			},
			maxLength:mobileMaxLength
		},
		{
			labelText:localString("Mobile No"),
			value:(consumerDetails && consumerDetails.MobileNo) || defaultText
		},
		{
			labelText:localString("Created On"),
			value:(consumerDetails && consumerDetails.CreatedDate && returnLocalDateTime(consumerDetails.CreatedDate,"DD-MM-YYYY HH:mm")) || defaultText
		},
		{
			labelText:localString("Email ID"),
			dataKey:"EmailID",
			type:"input",
			value:(consumerDetails && consumerDetails.EmailID) || defaultText,
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['EmailID'] : val  });
			}
		}
	]

	const cancelClickFun = () =>{
		setEditClick(false);
	}

	const editClickFun = () => {
		setEditClick(true);
		setEditSaveData({
			Name: consumerDetails.Name,
			AlternateMobileNo: consumerDetails.AlternateMobileNo,
			EmailID: consumerDetails.EmailID,
		});
		getCountries();
	}
	
	const saveClickFun = (e) =>{
		e.preventDefault();
		if(editSaveData.Name == consumerDetails.Name && editSaveData.AlternateMobileNo == consumerDetails.AlternateMobileNo && editSaveData.EmailID == consumerDetails.EmailID){
			setEditClick(false);
		}else{
			setConsumerData();
		}
	}

	const getMinimalConsumerDetails = () => {
        const data={
            ConsumerID : consumerID
        }
		setLoader(true);
		apiCall({
			url:'getMinimalConsumerDetails',
			data: data,
			callBack:(result)=>{
				setLoader(false); 
				if(result.success){
					setConsumerDetails(result.data)
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
    }

	const setConsumerData =()=>{
		let isValidate = validation();
			if(isValidate){
				const data={
					...editSaveData,
					ConsumerID : consumerID
				}
				setLoader(true);
				apiCall({
					url:'setConsumerDetails',
					data: data,
					callBack:(result)=>{
						setLoader(false); 
						if(result.success){
							getMinimalConsumerDetails();
							setEditClick(false);
						}else{
							Swal.fire({  
								icon: 'error',  
								text: localString(displayError(result)),  
							}); 
						}
					}	
				});
			}
	}

	const validation = () => {
		let isValidate = true;

		if (isValidate) {
			isValidate = false;
			if(editSaveData.Name === "" || editSaveData.Name === null){
				Swal.fire({
					icon:"warning",
					text:"Please Enter Name"
				})
				return;
			}else{
				if(editSaveData.Name && editSaveData.Name.length && !checkAlphaNumericName(editSaveData.Name)){
					Swal.fire({
						icon:"warning",
						text:localString("Please Enter Correct Name")
					})
					return;
				}
			}
			if (editSaveData.EmailID == '' || editSaveData.EmailID == null) {
				Swal.fire({  
					icon: 'warning',  
					text: localString('Enter Email ID'),  
				});
				return;
			} else {
				if (!checkEmail(editSaveData.EmailID)) {
					Swal.fire({  
						icon: 'warning',  
						text: localString('Please Enter Correct Email ID'),  
					});
					return;
				}
			}
			if (editSaveData.AlternateMobileNo && editSaveData.AlternateMobileNo.length && (!(checkMobileNumber(editSaveData.AlternateMobileNo)) || (mobileMaxLength.indexOf(editSaveData.AlternateMobileNo.length) == -1))) {  //&& !checkMobile(editSaveData.AlternateMobileNo)
				Swal.fire({  
					icon: 'warning',  
					text: localString('Please Enter Correct Alternate Number'),  
				});
				return;
			}
			isValidate = true;
		}
		return isValidate;
	}

	useEffect(() => {
        getMinimalConsumerDetails();
		if(JSON.parse(qFilter) && JSON.parse(qFilter).countrycode && JSON.parse(qFilter).countrycode.length > 0) {
            setSpecificCountryCode(JSON.parse(qFilter).countrycode[0] ? JSON.parse(qFilter).countrycode[0] : 'IN');
        }
    }, [])

	let navItems = [
		{
            name: 'registeredproducts',
            displayName: 'Registered Products',
            navClick: (nav) =>{
                setActiveNav(nav);
                setShowRegisteredProducts(true);
                setShowUnregisteredProducts(false);
                setShowCallhistory(false);
                setShowRequests(false);
				setShowPlans(false);
            },
            showNav: accessFn('CONSUMER_REGISTERED_PRODUCTS')
		},
        {
            name: 'unregisteredproducts',
            displayName: 'Unregistered Products',
            navClick: (nav) =>{
                setActiveNav(nav);
                setShowRegisteredProducts(false);
                setShowUnregisteredProducts(true);
                setShowCallhistory(false);
                setShowRequests(false);
				setShowPlans(false);
            },
            showNav: accessFn('CONSUMER_UNREGISTERED_PRODUCTS')
		},
		{
            name: 'plans',
            displayName: 'Plans',
            navClick: (nav) =>{
                setActiveNav(nav);
                setShowRegisteredProducts(false);
                setShowUnregisteredProducts(false);
                setShowCallhistory(false);
                setShowRequests(false);
				setShowPlans(true);
            },
            showNav: accessFn("CONSUMER_PLAN_DETAILS")
		},
        {
            name: 'requests',
            displayName: 'Requests',
            navClick: (nav) =>{
                setActiveNav(nav);
                setShowRegisteredProducts(false);
                setShowUnregisteredProducts(false);
                setShowCallhistory(false);
                setShowRequests(true);
				setShowPlans(false);
            },
            showNav: accessFn('CONSUMER_REQUESTS')
		},
        {
            name: 'callhistory',
            displayName: 'Communication History',
            navClick: (nav) =>{
                setActiveNav(nav);
                setShowRegisteredProducts(false);
                setShowUnregisteredProducts(false);
                setShowCallhistory(true);
                setShowRequests(false);
				setShowPlans(false);
            },
            showNav: !accessFn('HIDE_CONSUMER_CALL_LOGS')
		},
	]

	const getCountries = () => {
		setLoader(true);
		let data = {
			CountryID: consumerDetails.CountryID,
			countryConfig: true
		}
		apiCall({
			url:'getCountries',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let countryData = result.data.countries[0];
					setCountryValidation(countryData)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const setCountryValidation = (countryData)=>{
		const countryConfig = countryData.countryConfig;
		const AllowedMobileLengths=countryData.AllowedMobileLengths;
		if(countryConfig && countryConfig.MobileNumber){
			if(countryConfig.MobileNumber && countryConfig.MobileNumber.LENGTH){
				let lengthArr = countryConfig.MobileNumber.LENGTH && (countryConfig.MobileNumber.LENGTH.length > 1) ? countryConfig.MobileNumber.LENGTH.map(data=>{
					return parseInt(data)
				}): [countryConfig.MobileNumber.LENGTH[0]];
				let dscSort = sortBy(lengthArr).reverse();
				setMobileMaxLength(dscSort);
			}
		}else if(AllowedMobileLengths){
			let mobLength = AllowedMobileLengths.split(',');
			let lengthArr = mobLength.map((item)=> {
				return parseInt(item);
			});
			let dscSort = sortBy(lengthArr).reverse()
			setMobileMaxLength(dscSort)
		}
	}

    return (
        <>
			{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
            <PageHeader titleHeader={titleHeader}></PageHeader>
			<ConsumerDetails 
				{...props} 
				consumerDataArray={consumerDataArray} 
				editClickFun={editClickFun} 
				editSaveData={editSaveData} 
				editClick={editClick} 
				cancelClickFun={cancelClickFun}	
				saveClickFun={saveClickFun}	
			></ConsumerDetails>
			<NavBar
                activeNav={activeNav}
                {...props}
                navItems={navItems}
            ></NavBar>
			{showRegisteredProducts && <RegisteredProducts
				ConsumerID={consumerID}
				consumerDetails={consumerDetails}
				userFilterImei={userFilterImei}
				userFilterImei2={userFilterImei2}
				{...props}
			></RegisteredProducts>}
			{showRequests && <ConsumerRequest
				ConsumerID = {consumerID}
				{...props}
			></ConsumerRequest>}
			{showCallhistory && <CallLogPage
				consumerID = {consumerID}
				{...props}
				consumerDetails={consumerDetails}
				showCallHistory={true}
				showEmailHistory={true}
				showCommunicationLogs={true}
			></CallLogPage>}
			{showUnregisteredProducts && <UnRegisteredProduct
				ConsumerID={consumerID}
				{...props}
			></UnRegisteredProduct>}
			{showPlans && <ConsumerPlans
				{...props}
				ConsumerID={consumerID}
				consumerDetails={consumerDetails}
			></ConsumerPlans>}
        </>
    )
}

export default ConsumerViewDetailsPage
