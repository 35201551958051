import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Loader } from 'sfy-react';
import Swal from "sweetalert2";

import PageContentWrapper from "../../../../../components/PageContentWrapper/PageContentWrapper";
import { displayError } from "../../../../../Utility/Utils";
import RecursiveQuestion from "./RecursiveQuestion";
import './QuestionLookupTab.scss'

export default function QuestionLookupTab(props) {

    const {
        apiCall, localString, csrId
    } = props;
	const [loader, setLoader] = useState(false);
	const [questionDetails, setQuestionDetails] = useState([]);

    const loadQuestionLookup = () => {
        setLoader(true);
        let data = {
			ConsumerServiceRequestID: csrId,
        }
		apiCall({
			url: 'gsxQuestionLookupAnswers',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if(result.success){
					if (result.data && result.data.questionDetails){
						setQuestionDetails(result.data.questionDetails);
 					}
				}else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

    useEffect(() => {
        loadQuestionLookup();
    }, []);

    return (
        <>
            {
                (loader) && ReactDOM.createPortal(
                    <Loader />,
                    document.getElementById("loader-root")
                )
            }
            <div className="inner-padding">
				<div className="row">
					<PageContentWrapper
						bodyClassName="panel-body-no-padding" 
						title={localString("Guided Troubleshooting Details")}
					>
                        <div className='queryBuilderTab'>
                            {
                                questionDetails && (questionDetails.length > 0) && questionDetails.map((questionObj, parentIndex) => <>
                                    {
                                        questionObj.trees && (questionObj.trees.length > 0) && questionObj.trees.map((treeObj, treeIndex) => <>
                                            {
                                                treeObj && treeObj.questions && (treeObj.questions.length > 0) && <>
                                                    <RecursiveQuestion 
                                                        index={treeIndex}
                                                        localString={localString}
                                                        questionArr={treeObj.questions}
                                                        background={true}
                                                    />
                                                </>
                                            }
                                        </>)
                                    }
                                </>)
                            }
                        </div>
					</PageContentWrapper>
				</div>
			</div>
        </>
    )
}