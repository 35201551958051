import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./styles/index.scss";
import "sfy-react/build/css/index.css";
import "react-datetime/css/react-datetime.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { persistor, store } from "./reduxToolkit/store";

var root = document.getElementById("root");
document.getElementById("loader").style.display = "none";
ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App {...root.dataset} />
		</PersistGate>
	</Provider>,
	root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
