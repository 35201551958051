import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import Swal from "sweetalert2";
import {Loader,Pagination} from 'sfy-react';
import {floor,ceil} from 'lodash';
import PageHeader from '../../components/PageHeader/PageHeader'
import { displayError} from '../../Utility/Utils';
import FilterModal from '../../components/FilterModal/FilterModal';
import AttendanceApprovalDetails from './AttendanceApprovalDetails';
import './AttendanceApproval.scss'

const AttendanceApproval = (props) => {
	const {title,localString,apiCall} = props;
	const [attendanceList, setAttendanceList] = useState([])
	const [showModal , setShowModal] = useState(false);
	const [totalRequestCount, setTotalRequestCount] = useState()
	const [showLoader ,setLoader] = useState(false);
	const [filterData, setFilterData] = useState({
		status:"Pending",
		date:new Date(),
	}); 
	const [pagination , setPagination] = useState({
		pageNo: 1,
		range: 5,
		numberOfPages: 0,
		itemsPerPage: 30
	});
	const newDate = filterData.date;
	const startDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
	const endDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
	const lastDay = moment().subtract(1,'days').format("YYYY-MM-DD");

	const getPendingAttendanceRecords = ()=> {
		setLoader(true);
		apiCall({
			url:'getPendingAttendanceRecords',
			data: {
				'attendancetype':filterData && filterData.status,
				'pageNo': pagination.pageNo,
				'itemsPerPage':pagination.itemsPerPage,
				'range': pagination.range,
				'sortedBy': filterData && filterData.status,
				'eDate':moment(endDate).format("YYYY-MM-DD"),
				'sDate':moment(startDate).format("YYYY-MM-DD")
			},
			callBack:(result)=>{
				setLoader(false); 
				if(result.success){
					if((result.data)) {
						closeModal()
						let customAttendanceList = result.data.pendingRequest.map(item=>({...item,collapse:false,attendanceStatus:""}))
						setAttendanceList(customAttendanceList);
						setTotalRequestCount(result.data.totalRequestCount)
						let totalCount = result.data.totalRequestCount;
							// let paginationArray = [];
							let numberOfPages = 0;
							if (totalCount % pagination.itemsPerPage === 0) {
								numberOfPages = floor(totalCount / pagination.itemsPerPage, 0);
							} else if (totalCount % pagination.itemsPerPage > 0) {
								numberOfPages = ceil(totalCount / pagination.itemsPerPage, 0);
							}
							// let newRange;
							// if (pagination.range > numberOfPages) {
							// 	newRange = numberOfPages;
							// } else {
							// 	newRange = pagination.range;
							// }
							// let limit = floor(newRange / 2, 0);
							// let start;
							// if (pagination.pageNo - limit <= 0) {
							// 	start = 1;
							// } else if (pagination.pageNo <= numberOfPages) {
							// 		if (numberOfPages - pagination.pageNo <= limit) {
							// 			start = numberOfPages - newRange + 1;
							// 		} else {
							// 			start = pagination.pageNo - limit;
							// 			}
							// 	}
							// for (let i = 0; i < newRange; i++) {
							// 	paginationArray[i] = start + i;
							// }
							// let last = numberOfPages;
						 	// //for pagination in html
							// let showFirst
							// if(pagination.pageNo === 1){
							// 	showFirst = false
							// }else{
							// 	showFirst = true
							// }
							// let showLast;
							// if(pagination.pageNo === last){
							// 	showLast = false
							// }else{
							// 	showLast = true
							// }
							setPagination({...pagination, 
								count: totalCount,
								numberOfPages: numberOfPages
							})
						} else {
							setTotalRequestCount("0");
					}
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					})							
				}
			}	
		});
	}
	
	const saveReview = (item)=> {
		let attendancestatus = item.attendanceStatus
		let checkInDate = item.AttendenceDate
		let checkInTime = item.checkInTime
		let checkInDateTime = checkInDate + ' ' + checkInTime;
			if (!attendancestatus) {
			Swal.fire({
				icon:"warning",
				text:localString("Attendance status cannot be blank"),
			})
			} else {
			let data = {
				ApprovedFor: item.salesmanID,
				AttendanceStatus: attendancestatus,
				CheckInDate: moment(checkInDateTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ssZ"),
				ServiceLocationEngineerAttendanceID: item.ServiceLocationEngineerAttendanceID
			}
			setLoader(true);
			apiCall({
				url:'timeOutApprovalForSalesman',
				data:data,
				callBack:(result)=>{
					setLoader(false); 
					if(result.success){
						Swal.fire({
							icon:"success",
							text:localString(result.msg)
						}).then(result=>{
							if(result.isConfirmed){
								getPendingAttendanceRecords();
							}
						})
					}else{
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(result.msg)),  
						})							
					}
				}	
			});
		}
	}

	useEffect(() => {
		getPendingAttendanceRecords()
	}, [pagination.pageNo])

	const dropDownArray=[{
		value : "Pending",
	},{
		value : "Approved"
	}]

	const buttonArray = [{
		buttonText:localString("Filter Attendance"),
		showButton:true,
		btnIcon: 'glyphicon glyphicon-filter',
		onButtonClick:()=>{
			setShowModal(true)
		}
	}]

	const tableHeader = [{
		title : "Name",
	},{
		title : "User Type",
	},{
		title : "ShopName",
	},{
		title : "Attendace Date",
	},{
		title : "Time-In Time",
	},{
		title : "Time-Out Time",
	},{
		title : "Status",
	},{
		title:""
	}]

	let filterInput = [{
			labelText: localString('Status'),
			dataKey: "status",
			customClass:'col-sm-12 paddingB10',
			showFilter: true,
			type: 'Dropdown',
			dropdownList: dropDownArray,
			oninputFunc: (val) => {
				setFilterData({ ...filterData, status:val.value });
			}
		},{
			labelText: localString('Date'),
			dataKey: 'date',
			customClass:'col-sm-12 paddingB10 date-block',
			showFilter: true,
			type: 'DateTime',
			dateFormat: 'MM/YYYY',
			timeFormat: false,
			maxDay: new Date(lastDay),
			oninputFunc: (val) => {
				setFilterData({ ...filterData, date: new Date(val) });
			}
		},
	]

	const radioCheckFunction = (val,item) =>{
		let arr = attendanceList.map(data=>{
			console.log("item",item);
			let obj = {...data}
			if(item.ServiceLocationEngineerAttendanceLogID === data.ServiceLocationEngineerAttendanceLogID ){
				obj.attendanceStatus = val
			}
			return obj;
		})
		setAttendanceList(arr)
	}

	let radioButton = [{
		buttonText: "Full Day",
		value:"1",
		onclickupdate:(val,item)=>{
			radioCheckFunction(val,item)
		}
	},{
		buttonText: "Half Day",
		value:"7",
		onclickupdate:(val,item)=>{
			radioCheckFunction(val,item)
		}
	},{
		buttonText: "Reject",
		value:"5",
		onclickupdate:(val,item)=>{
			radioCheckFunction(val,item)
		}
	}]

	const closeModal = () =>{
		setShowModal(false);
	}

	return (
		<>
			{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<PageHeader titleHeader={title} localString={localString} btnClass="btn button-servify header-btn button-stack text-capital" headerButtonArray={buttonArray}></PageHeader>
			<FilterModal {...props} disableApplyBtn="" filterFunc={(e)=>{e.preventDefault();getPendingAttendanceRecords()}} filterInputs={filterInput} filterData={filterData} showModal={showModal} closeModal={closeModal} hideClearButton={true}></FilterModal>
			<AttendanceApprovalDetails {...props} setAttendanceList={setAttendanceList} saveReview={saveReview} totalRequestCount={totalRequestCount} radioButton={radioButton} tableHeader={tableHeader} attendanceList={attendanceList} ></AttendanceApprovalDetails>
			{
				pagination.numberOfPages >1 &&
					<Pagination
						itemsPerPage={pagination.itemsPerPage}
						pages={pagination.numberOfPages}
						totalItems={totalRequestCount}
						activePage={pagination.pageNo}
						paginate={(pageNumber) => setPagination({...pagination, pageNo: pageNumber})}
					/> 
			}
		</>
	)
}

export default AttendanceApproval
