import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"; 

import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";
import ReportFilters from "./ReportFilters";
import ReportGrid from "./ReportGrid";
import { displayError } from '../../Utility/Utils';


export default function DownloadReport(props) {
	const { localString, apiCall, setLoader } = props;
	const [data, setData] = useState([]);
	const [recentData, setRecentData] = useState([]);

	const fetchReportList = () => {
		setLoader(true);
		apiCall({
			url: 'fetchReports',
			data: {},
			callBack: (result) => { 
				setLoader(false);
				if(result.success && result.data) {
					setData(result.data)
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}				
			}	
		});
	}

	useEffect(() => {
		fetchReportList()
	}, []);

	const fetchRecentReport = (id, showLoader) => {
		showLoader && setLoader(true);
		let reqObj = {
			taskID: id,
			TaskID: id
		}
		apiCall({
			url: 'fetchReportWithTaskID',
			data: reqObj,
			callBack: (result) => { 
				showLoader && setLoader(false);
				if(result.success && result.data) {
					recursivefetchReportCall(result.data[0].fields.Status, id)
					setRecentData(result.data)
				}else {
					setRecentData([]);
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}				
			}	
		});
	}

	const recursivefetchReportCall = (status, id) => {
		let interval
		clearTimeout(interval);
		if(status === 'Pending') {
			interval = setTimeout(() => {
				fetchRecentReport(id)
			}, 20000);
		}else {
			clearTimeout(interval);
		}
	}

	return (
		<>
			{/* ------------Download Reports--------------- */}
			<PageContentWrapper title={localString("Download Report")} bodyClassName={'autoMinHeight'}>
				<ReportFilters { ...props } fetchReportList={fetchReportList} fetchRecentReport={fetchRecentReport} />
			</PageContentWrapper>
			{/* ------------Recently requested report--------------- */}
			{
				recentData.length > 0 && (
					<PageContentWrapper title={localString("Recent Requested Report")}>
						<ReportGrid data={recentData} { ...props }/>
					</PageContentWrapper>
				)
			}
			{/* ------------Listing--------------- */}
			<PageContentWrapper title={localString("Requested Reports")}>
				<ReportGrid data={data} { ...props }/>
			</PageContentWrapper>
		</>
	);
}