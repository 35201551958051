import React, {useState,useEffect} from "react";
import ReactDOM from "react-dom";
import { Modal, Input, Button } from 'sfy-react'
const AddRemark = (props) =>{
    const {localString, showModal, closeModal,addRemark,setAddRemark, getAddRemark} = props;
    return(
        <>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='remark-modal'>
                <Modal.Header>
                    <h2 className="add-remark-heading"> {localString("Add Remark")} </h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="add-remark-main">
                        <div className="add-remark-box form-group scrollable-dropdown-menu">
                            <div className="mb5">Remarks</div>
                            <Input 
                                handleOnChange={(e) =>{
                                    let str = e.target.value;
                                    str = str.trim();
                                    setAddRemark(str);
                                }}
                                inputComponent='textarea'
                                maxLength='500'
                            />
                        </div>
                        <div className="text-center">
                            <Button className={`${!addRemark ? "button-disable":"servify-button"} button-small` } handleOnClick={()=>getAddRemark()} isDisabled={!addRemark}>{localString('Submit')}</Button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default AddRemark;