import React from 'react';

import PageHeader from '../../components/PageHeader/PageHeader';
import './RetailerReports.scss';

const RetailerReports = (props) => {
	const { accessFn, publicUrl, titleHeader, localString } = props;
	const theme = JSON.parse(props.theme);
	return (
		<>
			<PageHeader titleHeader={titleHeader}/>
			<div className='retailerReports'>
				<div className="fluid-form-content fluid-form-active">
					<div className="row">
						<div className="col-sm-12">
							<h2>{theme.salesmanName}</h2>
						</div>
						<div className="col-xs-6 col-sm-3" if={accessFn('RETAILER_ATTENDANCE_REPORT')}>
							<div className="item text-center">
							<a href="/retailerReports/attendanceReports">
								<img src={publicUrl + "/imgs/AttendanceLogo.png"} alt="Pick Up" />
								<p className="text-capitalize">{localString('Attendance')}</p>
							</a>
							</div>
						</div>
						<div className="col-sm-3 col-xs-6" if={accessFn('DISPLAY_SHARE')}>
							<div className="item ">
							<a href="/retailerReports/displayshare">
								<img src={publicUrl + "/imgs/display-share.png"} alt="Display Share" />
								<p className="text-capitalize"> {localString('Display Share')}</p>
							</a>
							</div>
						</div>
						<div className="col-xs-6 col-sm-3" if={accessFn( 'RETAILER_SALES_REPORT')}>
							<div className="item text-center">
							<a href="/retailerReports/salesReports">
								<img src={publicUrl + "/imgs/salesreport.png"} alt="Carry in" />
								<p className="text-capitalize">{localString('Sales and Performance')}</p>
							</a>
							</div>
						</div>
						<div className="col-sm-3 col-xs-6" if={accessFn('COMPETITION_REPORT')}>
							<div className="item">
							<a href="/retailerReports/competitionreports">
								<img src={publicUrl + "/imgs/competition-sales.png"} alt="Competition" />
								<p className="text-capitalize">{localString('Competition Sales')}</p>
							</a>
							</div>
						</div>
					</div>
					<div className="row" if={accessFn('RETAILER_TL_DISPLAY_SHARE')}>
						<div className="col-sm-12">
							<h2>{localString(theme.supervisorName)}</h2>
						</div>
						<div className="col-sm-3 col-xs-6" if={accessFn('RETAILER_TL_ATTENDANCE')}>
							<div className="item">
							<a href="/retailerReports/tlattendance">
								<img src={publicUrl + "/imgs/AttendanceLogo.png"} alt="TL Attendance" />
								<p className="text-capitalize">{localString('Attendance')}</p>
							</a>
							</div>
						</div>
						<div className="col-sm-3 col-xs-6" if={accessFn('RETAILER_TL_DISPLAY_SHARE')}>
							<div className="item">
							<a href="/retailerReports/tldisplay">
								<img src={publicUrl + "/imgs/display-share.png"} alt="Display Share" />
								<p className="text-capitalize">{localString('Display Share')}</p>
							</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default RetailerReports;