import React from 'react';
import {Dropdown, Button} from 'sfy-react';

const AssignNonWorkshop = (props) => {
	const {assignObjCheck, accessFn ,localString, consumerServiceRequest, engineerList, disableAssignEngineer,
		engineerDetails, setEngineerDetails, assignRequest, disableAssign,selectServiceLocation,serviceLocationObj, serviceLocationData,
		setServiceLocationObj,loadEngineers} = props;

	return (
		<>
		<div class="request-panel consumer-details-height">
		{
			!assignObjCheck.showassignmenttag && 
			<div class="assign-empty-state">
				<p>{localString('N/A')}</p>
			</div>
		}
		{
			assignObjCheck.showassignmenttag && 
			<div className="">
				<div className="request-panel-content" >
					{
						assignObjCheck.showservicelocationlabel && 
						<p >{consumerServiceRequest.CREATED_AT_DROPOFF_PSL === "true"?localString("PSL Center"):localString("Service Center")}:
							<br/> 
							{consumerServiceRequest && (consumerServiceRequest.servicelocation && consumerServiceRequest.servicelocation.ServiceLocationName)? (consumerServiceRequest.servicelocation.ServiceLocationName || '-') : '-'}
						</p>
					}
					{
						assignObjCheck.showservicelocationtypeahead && 
						<div class="form-group register-filter assign-input">
							<label for="servicelocation">{consumerServiceRequest.CREATED_AT_DROPOFF_PSL === "true"?localString("PSL Center"):localString("Service Center")}</label>
							<Dropdown 
								id="engineer"
								name="engineer"
								value={serviceLocationObj.serviceLocationName}
								options={selectServiceLocation}
								handleOnChange={(data) => {
									loadEngineers(
										data.PartnerServiceLocationID,
										consumerServiceRequest.ConsumerServiceRequestID,
										consumerServiceRequest.ServiceTypeID
									)
									setServiceLocationObj({
										...serviceLocationObj,
										partnerServiceLocationID:data.PartnerServiceLocationID,
										serviceLocationName:data.ServiceLocationName
									})
								}}
								placeholder={(serviceLocationData && serviceLocationData.length==0 ) ? localString("No service locations found") : localString("Select a service center")}
								// placeholder={(selectServiceLocation && selectServiceLocation.length==0 ) ? localString("No service locations found") : localString("Select a service center")}
								filterBy='value'
								// isDisabled={disableAssignEngineer}
								showDownArrow={false}
							/>
							{/* <input type="text" name="servicelocation" id="servicelocation" onkeyup={getServiceLocationValue} placeholder={(ServiceLocationData.length==0 ) ? localString("No service locations found") : localString("Select a service center")} autocomplete="off" data-required="one" class="form-control" onkeydown="return false;" /> */}
						</div>
					}
					{
						assignObjCheck.showengineerlabel &&
						<p>{localString("Service Engineer")}:
							<br/>
							{(consumerServiceRequest.engineer) ? (consumerServiceRequest.engineer.Name || '-' ): '-'}
						</p>
					}
					{
						assignObjCheck.showengineertypeahead && 
						<div class="form-group register-filter scrollable-dropdown-menu-engg assign-input">
							<label for="engineer">{localString('Service Engineer')}</label>
							<Dropdown 
								id="engineer"
								name="engineer"
								value={engineerDetails.name}
								options={engineerList}
								handleOnChange={(data) => {
									setEngineerDetails({
										...engineerDetails,
										name:data.Name,
										serviceLocationEnggID:data.ServiceLocationEngineerID,
										PartnerServiceLocationID:data.PartnerServiceLocationID
									})
								}}
								placeholder={serviceLocationObj.serviceLocationName ? (engineerList && engineerList.length==0 ) ? localString("No engineers found") : localString("Select an engineer"):''}
								filterBy='value'
								// isDisabled={disableAssignEngineer}
								showDownArrow={false}
							/>
							{/* <input type="text" name="engineer" id="engineer" onkeyup={getEngineerValue} placeholder={(EngineersData.length==0 ) ? localString("No engineers found") : localString("Select an engineer")} autocomplete="off" data-required="one" class="form-control" onkeydown="return false;" disabled={disableAssignEngineer()} /> */}
						</div>
					}
					{
						assignObjCheck.showassignmentbutton && 
						<div class="text-right" >
							<Button 
								handleOnClick={assignRequest} 
								type='button' 
								id="assignButton"
								isDisabled={disableAssign}
								classes='button-ghost' 
								text={localString('Assign')}
							/>
						</div>
					}
					{
						assignObjCheck.showstatusmessage && 
						<div class="request-process">
							<p class="assign-empty-state">{localString('Request is being processed')}.</p>
						</div>
					}
				</div>
			</div>
		}
		</div>
		{
			assignObjCheck.showstatusmessage && 
			<div class="request-process">
				<p class="assign-empty-state">{localString('Request is being processed')}.</p>
			</div>
		}
		</>
	)
}

export default AssignNonWorkshop
