import React from 'react';
import { Button, Modal } from 'sfy-react';

export default function FindMyIphoneModal(props) {
	const {
		localString, showFMIPModal, setShowFMIPModal, FMIPcheck
	} = props;

	return (
		<>
			<Modal
				className='fmip-modal w-600'
				showModal={showFMIPModal}
				closeModal={() => setShowFMIPModal(false)}
				showCloseBtn={true}
			>
				<Modal.Header>
					<h4 className="modal-title">{localString("Turn off Find my iPhone")}</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="row" style={{ padding: '0px 20px'}}>
						<div className="col-sm-12">
							{localString("Start at your Home screen.")}<br />
							{localString("Tap Settings > iCloud.")}<br />
							{localString("Scroll to the bottom and tap Find My iPhone.")}<br />
							{localString("Slide to turn off Find My iPhone and Send Last Location.")}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button 
						className="btn button-servify" 
						onClick={() => FMIPcheck()}
					>
						{localString("Retry")}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}