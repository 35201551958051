const validateStrongPass = function (pass) {
	let validityObj = { isValid: true, msg: "Valid" };
	if (pass != "") {
		if (pass.length < 8) {
			validityObj.msg =
				"Password must contain at least eight characters!";
			validityObj.isValid = false;
			return validityObj;
		}
		let re = /[0-9]/;
		if (!re.test(pass)) {
			validityObj.msg =
				"Password must contain at least one number (0-9)!";
			validityObj.isValid = false;
			return validityObj;
		}
		re = /[a-z]/;
		if (!re.test(pass)) {
			validityObj.msg =
				"Password must contain at least one lowercase letter (a-z)!";
			validityObj.isValid = false;
			return validityObj;
		}
		re = /[A-Z]/;
		if (!re.test(pass)) {
			validityObj.msg =
				"Password must contain at least one uppercase letter (A-Z)!";
			validityObj.isValid = false;
			return validityObj;
		}
		re = /[!@#\$%\^&\*\(\){},\.<>:;"'|`~_\-\+=]/;
		if (!re.test(pass)) {
			validityObj.msg =
				"Password must contain at least one special character out of '!@#$%^&*(){},.<>:;\"'|`~_-+='";
			validityObj.isValid = false;
			return validityObj;
		}
	} else {
		validityObj.msg = "Please enter a password!";
		validityObj.isValid = false;
		return validityObj;
	}
	return validityObj;
};

export {validateStrongPass}
