import React,{useState} from 'react';
import ReactDOM from 'react-dom';
import {Loader, Button} from 'sfy-react';
import Swal from "sweetalert2";
import {displayError} from '../../../../../Utility/Utils';

const LoanerAssignment = (props) => {
    const {apiCall,assignObjCheck, localString, consumerServiceRequest, getRequestById, fetchLoanerDetailsByCsrId} = props;
    const [loader, setLoader] = useState(false)

    const assignLoanerRequest = () => {
        Swal.fire({
            title: localString("Are you sure?"),
            html: '',
            showCancelButton: true,
            confirmButtonText: localString('OK'),
            cancelButtonText: localString("Cancel")
        }).then(result=> {
            if (result.isConfirmed) {
                Swal.close();
                let data = {
                    ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                    LoanerRequestID : consumerServiceRequest.loanerDetails.LoanerRequestID,
                    status : "LOER",
                    Remarks : "Engineer assigned return",
                    ServiceLocationEngineerID : consumerServiceRequest.ServiceLocationEngineerID
                };
                setLoader(true);
                apiCall({
                    url: 'setLoanerStatus',
                    data: data,
                    callBack:(result)=>{
                        setLoader(false);
                        if(result.success){
                            getRequestById();
                            fetchLoanerDetailsByCsrId();
                        }else{
                            Swal.fire({
                                icon: 'error',
                                text: localString(displayError(result)),
                            });
                        }
                    }
                });
            }
        });
    };

    return (
        <div>
            { loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
            <div className="request-panel loaner-details-height">
                {
                    !assignObjCheck.showassignmenttag && 
                    <div className="assign-empty-state"><p>{localString('N/A')}</p></div>
                }
                {
                    assignObjCheck.showassignmenttag && 
                    <div>
                        <div className="request-panel-content">
                        {
                            assignObjCheck.showservicelocationlabel && 
                            <p>{localString('Service Center')}:
                                <br/> {(consumerServiceRequest.servicelocation)? (consumerServiceRequest.servicelocation.ServiceLocationName || '-') : '-'}
                            </p>
                        }
                        {/* ********** Not Used Dropdown Anymore(Discussed with Nilay Mahure) ********** */}
                        {/* {
                            assignObjCheck.showservicelocationtypeahead && 
                            <div className="form-group register-filter">
                                <label for="servicelocation">{localString("Service Center")}</label>
                                <input type="text" name="servicelocation" id="servicelocation" onkeyup={getServiceLocationValue} placeholder={(ServiceLocationData.length==0 ) ? localString("No service locations found") : localString("Select a service center")} autocomplete="off" data-required="one" className="form-control" onkeydown="return false;" />
                            </div>
                        } */}
                        {
                            assignObjCheck.showengineerlabel && 
                            <p>{localString("Service Engineer")}:
                                <br/>{(consumerServiceRequest.engineer) ? (consumerServiceRequest.engineer.Name || '-' ): '-'}
                            </p>
                        }
                        {
                            assignObjCheck.showassignmentbutton && 
                            <div className="text-right">
                                <Button 
                                    handleOnClick={assignLoanerRequest} 
                                    type='button' 
                                    classes='button-ghost' 
                                    text={localString('Assign')}
                                />
                            </div>
                        }
                        {
                            assignObjCheck.showstatusmessage && 
                            <div className="request-process">
                                <p className="assign-empty-state">{localString('Request is being processed')}.</p>
                            </div>
                        }
                        </div>
                    </div>
                }
            </div>
            {
                assignObjCheck.showstatusmessage && 
                <div className="request-process">
                    <p className="assign-empty-state">{localString('Request is being processed')}.</p>
                </div>
            }
        </div>
    )
}

export default LoanerAssignment
