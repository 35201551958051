import React from 'react';
import {Modal} from 'sfy-react';
import CloseVisitPendingInputs from './CloseVisitInputPending';
import './CloseVisitPending.scss';
const CloseVisitPendingModal=(props)=>{
    const {localString,showModal,closeModal, pendingForSpares } = props;
		return (
		<>
            { pendingForSpares &&<Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='madal-width'>
                <Modal.Header>
                    <h2> {localString("Close Visit")} </h2>
                </Modal.Header>
                <Modal.Body>
                    <CloseVisitPendingInputs {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>}
        </>
	)
}
export default CloseVisitPendingModal;