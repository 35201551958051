import React, { useEffect, useMemo ,useState} from "react";
import EstimateSubmissionTable from "./EstimateSubmissionTable"
import "./Estimate.scss";

const Estimate = (props) => {
	const { getChargesByCaseID, localString ,estimateDetails,rightButtonFlag,publicUrl} = props;
	const [showRemovedCharges,setShowRemovedCharges] = useState(false)
	const [showTotalAmount,setShowTotalAmount] = useState(false)

	useEffect(() => {
		getChargesByCaseID();
	}, []);
	useEffect(() => {
		setShowRemovedCharges(rightButtonFlag)
		setShowTotalAmount(rightButtonFlag)
	}, [rightButtonFlag]);

	const currencySymbol = useMemo(() => {
		let symbol = "";
		if (estimateDetails?.estimates && estimateDetails.estimates.length > 0) {
			symbol = estimateDetails.estimates[0].submissions[0].currnencyHTML;
		}

		return symbol;
	}, [estimateDetails]);

	return (
		<div className="estimate-wrapper">
			<div className="header-div clearfix shadow">
				<div className="estimate-text paddingLR10 cursor-pointer" data-toggle="collapse" data-target="#totalAmount"  onClick={() => setShowTotalAmount(!showTotalAmount)}>
					Total Amount (Latest) : 
					{estimateDetails && estimateDetails.payables && estimateDetails.payables[0] && estimateDetails.payables[0].submissions[0] && 
						<span 
							className="amount-icon"
							dangerouslySetInnerHTML={{
								__html: currencySymbol,
							}}
						></span>
					} 
					{estimateDetails && estimateDetails.payables && estimateDetails.payables[0] && estimateDetails.payables[0].submissions[0] && estimateDetails.payables[0].submissions[0].totalAmount}
					<span>
						<img 
							className='arrowIcons'
							src={publicUrl+(showTotalAmount?'/imgs/upArrow.svg':'/imgs/downArrow.svg')} 
							width={'35px'} 
						/>
					</span>
				</div>
				<div id="totalAmount" className={showTotalAmount?"collapse paddingLR10 in height-inherit":"collapse paddingLR10"}>
					{estimateDetails && estimateDetails.payables && estimateDetails.payables[0] && estimateDetails.payables[0].submissions && estimateDetails.payables[0].submissions.map((item)=>{
						return (<span className="amount-span">
							#{item.submission}  
							<span 
								className="amount-icon"
								dangerouslySetInnerHTML={{
									__html: currencySymbol,
								}}
							></span>
							{item.totalAmount}  
						</span>)
					})}
				</div>
				<div className="paddingTB10 amount-sec">
					{estimateDetails && (estimateDetails.consumerAmount || estimateDetails.consumerAmount===0) && <span className="border-seprater">
						Consumer Amount
						<span 
							className="amount-icon"
							dangerouslySetInnerHTML={{
								__html: currencySymbol,
							}}
						></span>
						{estimateDetails.consumerAmount}
					</span>
					}
					{estimateDetails && (estimateDetails.coveredValue || estimateDetails.coveredValue===0) && <span className="border-seprater">
						Covered Amount
						<span 
							className="amount-icon"
							dangerouslySetInnerHTML={{
								__html: currencySymbol,
							}}
						></span>
						{estimateDetails.coveredValue}
					</span>
					}
					{estimateDetails && (estimateDetails.approvedClaimAmount || estimateDetails.approvedClaimAmount===0) && <span className="border-seprater">
						Approved Claim Amount
						<span 
							className="amount-icon"
							dangerouslySetInnerHTML={{
								__html: currencySymbol,
							}}
						></span>
						{estimateDetails.approvedClaimAmount}
					</span>
					}
					{estimateDetails && (estimateDetails.berRefundAmout || estimateDetails.berRefundAmout===0) && <span className="border-seprater">
						BER Refund Amount
						<span 
							className="amount-icon"
							dangerouslySetInnerHTML={{
								__html: currencySymbol,
							}}
						></span>
						{estimateDetails.berRefundAmout?estimateDetails.berRefundAmout:null}
					</span>
					}
					{estimateDetails && estimateDetails.consumerProduct && estimateDetails.consumerProduct.depreciatedValue &&  (estimateDetails.consumerProduct.depreciatedValue.accessoriesCost || estimateDetails.consumerProduct.depreciatedValue.accessoriesCost===0) && <span className="border-seprater">
						Accessory Cost
						<span 
							className="amount-icon"
							dangerouslySetInnerHTML={{
								__html: currencySymbol,
							}}
						></span>
						{estimateDetails.consumerProduct.depreciatedValue.accessoriesCost}
					</span>
					}
					{estimateDetails && estimateDetails.accessoriesStatus && <span className="border-seprater">
						Accessory Status
						<span className="amount-icon">{estimateDetails.accessoriesStatus==-1?"NO":"YES"}
						</span>
					</span>
					}
					{estimateDetails && estimateDetails.consumerProduct && estimateDetails.consumerProduct.depreciatedValue &&  (estimateDetails.consumerProduct.depreciatedValue.devicePurchaseCost || estimateDetails.consumerProduct.depreciatedValue.devicePurchaseCost===0) && <span className="border-seprater">
						Device Purchase Cost
						<span 
							className="amount-icon"
							dangerouslySetInnerHTML={{
								__html: currencySymbol,
							}}
						></span>
						{estimateDetails.consumerProduct.depreciatedValue.devicePurchaseCost?estimateDetails.consumerProduct.depreciatedValue.devicePurchaseCost:0}
					</span>
					}
					{estimateDetails && estimateDetails.consumerProduct && estimateDetails.consumerProduct.depreciatedValue && (estimateDetails.consumerProduct.depreciatedValue.deviceDepreciatedValue || estimateDetails.consumerProduct.depreciatedValue.deviceDepreciatedValue===0) && <span className="border-seprater">
						Device Depreciated Value
						<span 
							className="amount-icon"
							dangerouslySetInnerHTML={{
								__html: currencySymbol,
							}}
						></span>
						{estimateDetails.consumerProduct.depreciatedValue.deviceDepreciatedValue}
					</span>
					}
				</div>
			</div>
			<div className="estimate-col">
				{estimateDetails && estimateDetails.estimates &&
				estimateDetails.estimates.length > 0 ? (
					<div className="shadow clearfix">
						<div className=" row-shadow col-sm-12 estimate-header estimate-row-pad">
							<div className="estimate-heading col-sm-12">
								<div className="estimate-heading col-sm-2">
									{localString("Name")}
								</div>
								<div className="estimate-heading col-sm-2">
									{localString("Description")}
								</div>
								<div className="estimate-heading col-sm-2">
									{localString("Remarks")}
								</div>
								<div className="estimate-heading text-center col-sm-1">
									{localString("Tax Type")}
								</div>
								<div className="estimate-heading text-center col-sm-1">
									{localString("Tax Slab")}
								</div>
								<div className="estimate-heading text-center col-sm-1">
									{localString("Tax Amt")}
								</div>
								<div className="estimate-heading text-center col-sm-1">
									{localString("Total Amount")}
								</div>
								<div className="estimate-heading col-sm-1">
									{localString("(#)")}
								</div>
								<div className="estimate-heading col-sm-1"></div>
							</div>
						</div>
						<EstimateSubmissionTable id="activeEstimte" {...props} estimates={estimateDetails.estimates} currencySymbol={currencySymbol}></EstimateSubmissionTable>
					</div>
				) : (
					<div className="text-center">No Data</div>
				)}
				<div className="estimate-text text-color-primary text-right cursor-pointer" data-toggle="collapse" data-target="#removedCharges"  onClick={() => setShowRemovedCharges(!showRemovedCharges)}>
					{showRemovedCharges?<span >Hide</span>:<span>Show</span>} Removed Charges
					<span className='accordArrow'>
						<img 
							className='arrowIcons'
							src={publicUrl+(showRemovedCharges?'/imgs/upArrow.svg':'/imgs/downArrow.svg')} 
							width={'35px'} 
						/>
					</span>  
				</div>
				{<div id="removedCharges" className={showRemovedCharges?"collapse in height-inherit":"collapse"}>
					{estimateDetails && estimateDetails.deactivedEstimates &&
					estimateDetails.deactivedEstimates.length > 0 ? (
						<div className="shadow clearfix removed-charges-background">
							<EstimateSubmissionTable id="removedCharges" {...props} estimates={estimateDetails.deactivedEstimates} currencySymbol={currencySymbol}></EstimateSubmissionTable>
						</div>
					) :  (
						<div className="text-center">No Data</div>
					)}
				</div>
				}
			</div>
		</div>
	);
};

export default Estimate;
