import React from 'react';
import {Modal} from 'sfy-react';

import LoanerPaymentMode from './LoanerPaymentMode';

import './LoanerPaymentModal.scss';

const LoanerPaymentModal = (props) => {
	const { localString, showModal, closeModal } = props;
		return ( <>
			<Modal 
				showModal={showModal} 
				closeModal={closeModal} 
				showCloseBtn={true} 
				className='Filter-Modal loanerPaymentModal'
			>
				<Modal.Header>
					<h2> {localString("Mode of payment")} </h2>
				</Modal.Header>
				<Modal.Body>
					<LoanerPaymentMode {...props}/>
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
		</> )
}
export default LoanerPaymentModal;