import React from 'react';
import {Modal} from 'sfy-react';

import LoanerDeviceDiagnosis from './LoanerDeviceDiagnosis';

import './LoanerDeviceDiagnosisModal.scss';

const LoanerDeviceDiagnosisModal = (props) => {
	const { localString, showModal, closeModal } = props;
		return ( <>
			<Modal 
				showModal={showModal} 
				closeModal={closeModal} 
				showCloseBtn={true} 
				className='Filter-Modal loanerDeviceDiagnosisModal full-screen-modal'
			>
				<Modal.Header>
					<h2> {localString("Complete Checklist")} </h2>
				</Modal.Header>
				<Modal.Body>
					<LoanerDeviceDiagnosis {...props}/>
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
		</> )
}
export default LoanerDeviceDiagnosisModal;