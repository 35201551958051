import React, { useState, useEffect } from "react";
import { Loader, Button, Checkbox } from "sfy-react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import {
	displayError,
	checkEmail,
	checkProductUniqueID,
	checkName,
	checkMobileNumber,
	getMobileMaxLength,
} from "../../../Utility/Utils";
import { useSelector } from "react-redux";
import FilterModalTransaction from "../FilterModalTransaction/FilterModalTransaction";
import DateRangePickerPlanSales from "../DateRangePickerPlanSales/DateRangePickerPlanSales";
import Upfront from "./Upfront";
import Subscription from "./Subscription";
import "./TransactionSummary.scss";
import moment from "moment";

const TransactionSummary = (props) => {
	const { localString, apiCall, baseUrl, accessFn } = props;
	const { dateRange, selectedPSL } = useSelector(
		(state) => state.PlanSalesDashboard
	);
	const qFilter = JSON.parse(props.qFilter);
	const [showLoader, setShowLoader] = useState(false);
	const [viewList, setViewList] = useState(false);
	const [planDetails, setPlanDetails] = useState([]);
	const [countriesData, setCountriesData] = useState(null);
	const [countryCode, setCountryCode] = useState(
		qFilter && qFilter.countrycode && qFilter.countrycode.length > 0
			? qFilter.countrycode[0].toUpperCase()
			: "IN"
	);
	const [showFilterModal, setShowFilterModal] = useState(false);
	const [mobileMaxLength, setMobileMaxLength] = useState([]);
	const [showCheckbox, setShowCheckbox] = useState(false);
	const [activeTab, setActiveTab] = useState("upfront");
	const [forceApiCall, setForceApiCall] = useState(true);

	const dropDownArray = [
		{
			value: localString("Select All"),
			id: 999,
		},
		{
			value: localString("Active"),
			id: 1,
		},
		{
			value: localString("Enrolled"),
			id: 8,
		},
		{
			value: localString("Diagnosed"),
			id: 9,
		},
		{
			value: localString("Purchased"),
			id: 0,
		},

		{
			value: localString("Registered"),
			id: 2,
		},
		{
			value: localString("Upgraded"),
			id: 5,
		},

		{
			value: localString("On Hold"),
			id: -9,
		},

		{
			value: localString("Downgraded"),
			id: -5,
		},

		{
			value: localString("Cancelled"),
			id: -4,
		},

		{
			value: localString("Expired"),
			id: -3,
		},

		{
			value: localString("Cancellation Initiated"),
			id: 3,
		},
		{
			value: localString("Refunded"),
			id: 4,
		},

		{
			value: localString("Benefits Availed"),
			id: 10,
		},
		{
			value: localString("Bundled"),
			id: 12,
		},
		{
			value: localString("Under Review"),
			id: 13,
		},
		{
			value: localString("Payment Pending"),
			id: 14,
		},
	];

	const [filterData, setFilterData] = useState({
		FirstName: "",
		OrderID: "",
		MobileNo: "",
		EmailID: "",
		ProductUniqueID: "",
		ProductUniqueID2: "",
		SoldPlanCode: "",
		ExternalRefenrenceID: "",
		PartnerServiceLocationID: selectedPSL.PartnerServiceLocationID,
		StartDate: dateRange?.startDate ?? "",
		EndDate: dateRange?.endDate ?? "",
		Status: dropDownArray.map((statusObj) => statusObj.id),
		StatusText: [],
		StatusID: "",
		countryCode: countryCode,
	});

	const [oldFilterData, setOldFilterData] = useState(filterData);

	const [upfrontPagination, setUpfrontPagination] = useState({
		currentPage: 1,
		itemsPerPage: 10,
		maxPages: 0,
		totalItems: 0,
	});
	const [subscriptionPagination, setSubscriptionPagination] = useState({
		currentPage: 1,
		itemsPerPage: 10,
		maxPages: 0,
		totalItems: 0,
	});

	const closeModal = () => {
		setFilterData(oldFilterData);
		setShowFilterModal(false);
	};

	const statusText = {
		1: localString("Active"),
		8: localString("Enrolled"),
		9: localString("Diagnosed"),
		0: localString("Purchased"),
		2: localString("Registered"),
		5: localString("Upgraded"),
		"-9": localString("On Hold"),
		"-5": localString("Downgraded"),
		"-4": localString("Cancelled"),
		"-3": localString("Expired"),
		3: localString("Cancellation Initiated"),
		4: localString("Refunded"),
		10: localString("Benefits Availed"),
		12: localString("Bundled"),
		13: localString("Under Review"),
		14: localString("Payment Pending"),
	};

	const statusColor = {
		"-9": "#FDE4E1",
		"-5": "#F2F2F2",
		"-4": "#FDE4E1",
		"-3": "#FDE4E1",
		0: "#FCF0DF",
		1: "#E6F3E5",
		2: "#FCF0DF",
		3: "#EFEAFC",
		4: "#FDE4E1",
		5: "#EFEAFC",
		8: "#FCF0DF",
		9: "#FCF0DF",
		10: "#F2F2F2",
		12: "#EFEAFC",
		13: "#FCF0DF",
		14: "#FCF0DF",
	};
	const statusTextColor = {
		"-9": "#F54336",
		"-5": "#393939",
		"-4": "#F54336",
		"-3": "#F54336",
		0: "#FF9300",
		1: "#4EAF51",
		2: "#FF9300",
		3: "#410099",
		4: "#F54336",
		5: "#410099",
		8: "#FF9300",
		9: "#FF9300",
		10: "#393939",
		12: "#410099",
		13: "#FF9300",
		14: "#FF9300",
	};

	//upfront and subscription

	///  **** Api call *******  ///

	const fetchSoldPlanCustomersData = (obj, pageNumber) => {
		setPlanDetails([]);
		let sD = obj?.startDate ? moment(obj?.startDate).format("YYYY-MM-DD") : moment(filterData?.StartDate).format("YYYY-MM-DD");
		let eD = obj?.endDate ? moment(obj?.endDate).format("YYYY-MM-DD") : moment(filterData?.EndDate).format("YYYY-MM-DD");
		let data = {
			FirstName: filterData.FirstName,
			MobileNo: filterData.MobileNo,
			OrderID: filterData.OrderID,
			EmailID: filterData.EmailID.trim(),
			ContractIDFromFrontend: filterData.SoldPlanCode,
			PolicyID: filterData.ExternalRefenrenceID,
			PartnerServiceLocationID: filterData.PartnerServiceLocationID,
			getUpfrontData: activeTab == "upfront",
			getSubscriptionData: activeTab == "subscription",
			IMEI1: filterData.ProductUniqueID,
			IMEI2: filterData.ProductUniqueID2,
			StartDate: sD,
			EndDate: eD,
			Status: filterData.Status ?? [],
		};
		data.pagination = {
			currentPage:
				activeTab == "upfront"
					? upfrontPagination.currentPage
					: subscriptionPagination.currentPage,
			itemsPerPage:
				activeTab == "upfront"
					? upfrontPagination.itemsPerPage
					: subscriptionPagination.itemsPerPage,
		};
		setShowLoader(true);
		if (qFilter.languagecode && qFilter.languagecode.length) {
			data.languagecode = qFilter.languagecode[0];
		}
		apiCall({
			url: "getSoldPlanCustomersData",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success && result.data) {
					setPlanDetails(result.data && result.data.PlanDetails);
					if (activeTab == "upfront") {
						setUpfrontPagination({
							...upfrontPagination,
							totalItems: result?.data?.pagination?.totalItems,
							maxPages: result?.data?.pagination?.maxPages,
						});
					} else {
						setSubscriptionPagination({
							...subscriptionPagination,
							totalItems: result?.data?.pagination?.totalItems,
							maxPages: result?.data?.pagination?.maxPages,
						});
					}
					setViewList(true);
					setShowFilterModal(false);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getCountries = (country) => {
		let data = {
			specificCountryCode: country ? country.toUpperCase() : countryCode,
			countryConfig: true,
		};
		setShowLoader(true);
		apiCall({
			url: "getCountries",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					let countryData = result.data.countries[0];
					setCountriesData(countryData);
					let maxLength = getMobileMaxLength(countryData);
					setMobileMaxLength(maxLength);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const navbarArray = [
		{
			buttonName: localString("Upfront Payment"),
			customClass: activeTab == "upfront" ? "active tab-li" : "tab-li",
			listID: "upfront_li",
			tabID: "upfront",
		},

		{
			buttonName: localString("Subscription Payment"),
			customClass:
				activeTab == "subscription" ? "active tab-li" : "tab-li",
			listID: "subscription_tab",
			tabID: "subscription",
		},
	];

	const clearSearchFunc = () => {
		setFilterData({
			...filterData,
			FirstName: "",
			MobileNo: "",
			EmailID: "",
			ProductUniqueID: "",
			ProductUniqueID2: "",
			SoldPlanCode: "",
			ExternalRefenrenceID: "",
			OrderID: "",
		});
	};

	const isMoreFiltersClearButtonDisabled = () => {
		return (
			!filterData.FirstName.length &&
			!filterData.MobileNo.length &&
			!filterData.EmailID.length &&
			!filterData.ProductUniqueID.length &&
			!filterData.ProductUniqueID2.length &&
			!filterData.SoldPlanCode.length &&
			!filterData.ExternalRefenrenceID.length &&
			!filterData.OrderID.length
		);
	};

	const isFilterDataModified = () => {
		const isStatusDataModified = () => {
			if (filterData.Status.length != oldFilterData.Status.length)
				return true;
			// compare status array
			if (
				JSON.stringify([...filterData.Status].sort()) !=
				JSON.stringify([...oldFilterData.Status].sort())
			)
				return true;
			return false;
		};
		return (
			filterData.MobileNo != oldFilterData.MobileNo ||
			filterData.FirstName != oldFilterData.FirstName ||
			filterData.EmailID != oldFilterData.EmailID ||
			filterData.ProductUniqueID != oldFilterData.ProductUniqueID ||
			filterData.ProductUniqueID2 != oldFilterData.ProductUniqueID2 ||
			filterData.SoldPlanCode != oldFilterData.SoldPlanCode ||
			filterData.ExternalRefenrenceID !=
				oldFilterData.ExternalRefenrenceID ||
			filterData.OrderID != oldFilterData.OrderID ||
			isStatusDataModified()
		);
	};

	const validateMoreFiltersData = () => {
		if (
			filterData.MobileNo.length > 0 &&
			(!mobileMaxLength.includes(filterData.MobileNo.length) ||
				!checkMobileNumber(filterData.MobileNo))
		) {
			return false;
		} else if (
			filterData.ProductUniqueID.length &&
			!checkProductUniqueID(filterData.ProductUniqueID)
		) {
			return false;
		} else if (
			filterData.FirstName.length &&
			!checkName(filterData.FirstName)
		) {
			return false;
		} else if (
			filterData.EmailID.length &&
			!checkEmail(filterData.EmailID)
		) {
			return false;
		} else {
			return true;
		}
	};

	const resetOnFilter = () => {
		if (activeTab == "upfront") {
			setUpfrontPagination({
				...upfrontPagination,
				currentPage: 1,
			});
		} else {
			setSubscriptionPagination({
				...subscriptionPagination,
				currentPage: 1,
			});
		}
		setForceApiCall(!forceApiCall);
	};

	useEffect(() => {
		getCountries();
		if (!selectedPSL?.PartnerServiceLocationID?.length) {
			if (accessFn("PLAN_SALE_ADMIN_USER")) {
				fetchSoldPlanCustomersData();
			} else {
				Swal.fire({
					title: localString("Session Expired"),
					icon: "info",
					text: localString(
						"Your session has expired. The store location needs to be selected again, so you will be redirected to the home page shortly."
					),
					confirmButtonText: localString("Okay"),
				}).then(function () {
					window.location.href = baseUrl + "/plansales/home";
				});
			}
		} else {
			fetchSoldPlanCustomersData();
		}
	}, [
		activeTab,
		upfrontPagination.currentPage,
		subscriptionPagination.currentPage,
		filterData.StartDate,
		forceApiCall,
	]);

	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}

			<div className="hidden-xs content-header1">
				<div className="row content-header-background main-div1">
					<div className="col-sm-3">
						<h2 className="transaction">
							<a href={baseUrl + "/plansales/home"}>
								<span
									onClick={() => {}}
									className="resend-text"
									type="button"
								>
									{localString("Plan Sales")}
								</span>
							</a>
							&nbsp;/{" "}{localString("Transaction Details")}
						</h2>
					</div>

					<div className="right-content col-sm-9">
						<div className="period-div-transaction">
							<span className="period-text-margin-transaction">
								<label className="label-text-transaction">
									{localString("Transaction Start Date")}
								</label>
							</span>
							<DateRangePickerPlanSales
								{...props}
								dateRange={dateRange}
								handleDateRangeApply={(obj) => {
									setFilterData({
										...filterData,
										StartDate:
											obj?.startDate ??
											filterData?.StartDate,
										EndDate:
											obj?.endDate ?? filterData?.EndDate,
									});
									setUpfrontPagination({
										...upfrontPagination,
										currentPage: 1,
									});
									setSubscriptionPagination({
										...subscriptionPagination,
										currentPage: 1,
									});
								}}
								dateClass="col-sm-5"
								customFormat="dd-mm-yyyy"
								showPreDefinedRange={false}
								customPlaceholder="MM-DD-YYYY"
								dateWidthClass={"date-range-transaction"}
							/>
						</div>

						<div style={{ display: "block" }}>
							<Button
								type="button"
								classes="button-ghost filter-button-trans"
								onClick={() => {
									// if checkbox is already shown, set filter to previous data while closing.
									if (showCheckbox) {
										setFilterData(oldFilterData);
									} else {
										setOldFilterData(filterData);
									}
									setShowCheckbox(!showCheckbox);
								}}
							>
								{localString("Contract Status")} &nbsp;(
								{
									filterData.Status.filter(
										(status) => status != 999
									).length
								}
								)
								<span
									className="glyphicon glyphicon-menu-down"
									style={{ marginLeft: "6px" }}
								/>
							</Button>

							{showCheckbox && (
								<>
									<div className="">
										<div className="checkbox-trans checksize">
											<span
												onClick={() => {
													setFilterData({
														...filterData,
														["Status"]: [],
													});
												}}
												style={{
													float: "right",
													marginTop: "7px",
													fontWeight: "600",
													color: "#4c10c1",
													cursor: "pointer",
												}}
											>
												{localString("Clear All")}
											</span>
											{dropDownArray.map((obj, k) => (
												<Checkbox
													value={filterData.Status.some(
														(status) =>
															status == obj.id
													)}
													name="userType"
													id={obj.id}
													className={{
														container: "mb20",
													}}
													handleOnChange={(e) => {
														if (e.target.checked) {
															if (obj.id == 999) {
																// for select all
																setFilterData({
																	...filterData,
																	Status: dropDownArray.map(
																		(obj) =>
																			obj.id
																	),
																});
															} else {
																let newStatus =
																	[
																		...filterData.Status,
																		obj.id,
																	];
																if (
																	newStatus.length ==
																		dropDownArray.length -
																			1 &&
																	!newStatus.includes(
																		999
																	)
																) {
																	newStatus.push(
																		999
																	);
																}
																setFilterData({
																	...filterData,
																	["Status"]:
																		newStatus,
																});
															}
														} else {
															// for uncheck
															if (obj.id == 999) {
																// for select all
																setFilterData({
																	...filterData,
																	Status: [],
																});
															} else {
																setFilterData({
																	...filterData,
																	["Status"]:
																		filterData.Status.filter(
																			(
																				status
																			) =>
																				status !=
																				obj.id
																		).filter(
																			(
																				status
																			) =>
																				status !=
																				999
																		),
																});
															}
														}
													}}
												>
													{localString(obj.value)}
												</Checkbox>
											))}
										</div>

										<>
											<div className="checkbox-button-footer">
												<Button
													type="button"
													classes="button-ghost btn3"
													onClick={() => {
														setShowCheckbox(false);
														setFilterData(
															oldFilterData
														);
													}}
													
												>
													{localString("Cancel")}
												</Button>
												<Button
													type="button"
													className="button button-primary btn12"
													isDisabled={
														!(
															filterData.Status
																.length > 0 &&
															isFilterDataModified()
														)
													}
													onClick={() => {
														setShowCheckbox(false);
														resetOnFilter();
													}}
												>
													{localString("Apply")}
												</Button>
											</div>
										</>
									</div>
								</>
							)}
						</div>

						<Button
							type="button"
							classes="button-ghost filter-button-trans1"
							onClick={() => {
								setOldFilterData(filterData);
								setShowFilterModal(true);
							}}
						>
							<span
								className="glyphicon glyphicon-filter icon-color"
								style={{
									marginLeft: "-11px",
									paddingRight: "4px",
								}}
							/>
							{localString("More")}
						</Button>
					</div>
				</div>
			</div>

			<div className="bulkUploadGrid">
				<div className="table-header-div-trans">
					<h4 className="bulkUploadhHeader-trans col-sm-6">
						{localString("All Transactions")}
					</h4>

					{/* new ui */}
					<div className="request-view-navbar">
						<div
							className="navbar content remove-border-radius-navbar-trans"
							id="myNavbar"
						>
							<div className="container-fluid remove-container-padding">
								<div className="navbar-header">
									<button
										type="button"
										className="navbar-toggle"
										data-toggle="collapse"
										data-target="#myNavbar"
									>
										<span className="icon-bar"></span>
										<span className="icon-bar"></span>
										<span className="icon-bar"></span>
									</button>
								</div>
								<div>
									<div
										className="collapse navbar-collapse navbar-static-top remove-navbar-padding"
										id="myNavbar"
									>
										<ul className="nav navbar-nav ul-nav">
											{navbarArray &&
												navbarArray.map((navItem) => {
													return (
														<li
															className={
																navItem.customClass
															}
															id={navItem.listID}
														>
															<a
																className="btn button-servify backtoreqbtn no-border-radius tab-buttons"
																id={
																	navItem.tabID
																}
																onClick={(e) =>
																	setActiveTab(
																		navItem.tabID
																	)
																}
															>
																{
																	navItem.buttonName
																}
															</a>
														</li>
													);
												})}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="content">
						{activeTab == "upfront" && (
							<Upfront
								{...props}
								planDetails={planDetails}
								viewList={viewList}
								showLoader={showLoader}
								statusText={statusText}
								statusColor={statusColor}
								statusTextColor={statusTextColor}
								pagination={upfrontPagination}
								countriesData={countriesData}
								setPagination={setUpfrontPagination}
							/>
						)}
						{activeTab == "subscription" && (
							<Subscription
								{...props}
								planDetails={planDetails}
								showLoader={showLoader}
								statusText={statusText}
								statusColor={statusColor}
								statusTextColor={statusTextColor}
								viewList={viewList}
								countriesData={countriesData}
								pagination={subscriptionPagination}
								setPagination={setSubscriptionPagination}
							/>
						)}
					</div>
				</div>
			</div>
			{showFilterModal && (
				<FilterModalTransaction
					{...props}
					setFilterData={setFilterData}
					getCountries={(country) => getCountries(country)}
					countryCode={countryCode}
					showModal={showFilterModal}
					closeModal={closeModal}
					filterData={filterData}
					mobileMaxLength={mobileMaxLength}
					disableApplyButton={
						!(isFilterDataModified() && validateMoreFiltersData())
					}
					disableClearButton={isMoreFiltersClearButtonDisabled()}
					clearSearch={clearSearchFunc}
					filterFunc={(e) => {
						e.preventDefault();
						resetOnFilter();
					}}
				/>
			)}
		</>
	);
};

export default TransactionSummary;
