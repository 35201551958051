import React from 'react';
import {Modal,Dropdown,Button,Table} from 'sfy-react';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import './SymptomFaultAction.scss'

const SymptomFaultModal = (props) => {
    const {localString,showModal,closeModal,dropdownArray,filterData,showRecords,addSymptomFaultAction,addedSFA,removeAddedFaultCode,
        saveSymptomFaultAction,disableAddButton} = props;

    const classObject = {
		table:"table table-striped table-responsive table-bordered table-condensed "
	}
    
    return (
        <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='SFA-modal'>
            <Modal.Header>
                <h2> {localString("Add Symptom Fault Action")} </h2>
            </Modal.Header>
            <Modal.Body>
                <div className = "iris-row">
                    {
                        dropdownArray && dropdownArray.map((fault,i)=>{
                            return(
                                fault.showLabel && 
                                <div className={fault.divClass}>
                                    <label className="">{fault.labelText}</label>
                                    {!fault.avlQty &&
                                        <Dropdown
                                            id={fault.id}
                                            classObject={{
                                                container:fault.customClass,
                                            }}
                                            value={filterData[fault.dataKey]}
                                            options={fault.dropdownList}
                                            handleOnChange={(data) => {
                                                fault.onInputFunc(data)
                                            }}
                                            isDisabled={fault.disable}
                                            filterBy='value'
                                            showDownArrow={false}
                                        />
                                    }
                                    {
                                        fault.avlQty && 
                                        !fault.showAvailableQuantityLoader && 
                                        <div className={fault.customClass}>{fault.availableQuantity}</div>
                                    }
                                    {
                                        fault.showSymptomLoader && 
                                        <div className = "inner-loader">
                                            <span className="input-loader form-control-feedback form-control-feedback-padded"></span>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                    <div className = "col-sm-1">
                        <Button 
                            type="button" 
                            className="btn border-gray button-stack btn button-servify pull-right"
                            text={localString('Add')}
                            isDisabled={disableAddButton}
                            handleOnClick={()=>addSymptomFaultAction()}
                        />
                    </div>
                </div>
                <div className = "row iris-row">

                </div>
                <div className = "iris-row SFA-custom-margin">
                    <div className = "col-sm-12">
                        <p className="issues-table-header text-capitalize remove-margin">{localString('Symptoms reported by Engineer')} </p>
                        <div className = "table-responsive">
                            <Table className = {classObject}>
                                <Table.Header>
                                    <Table.Row>
                                        {
                                            showRecords.RecordSymptoms && 
                                            <Table.Heading className="col-sm-2">{localString('Symptoms')}</Table.Heading>
                                        }
                                        {
                                            showRecords.RecordFaults && 
                                            <Table.Heading className="col-sm-2">{localString('Faults')}</Table.Heading>
                                        }
                                        {
                                            showRecords.RecordActions && 
                                            <Table.Heading className="col-sm-2">{localString('Actions')}</Table.Heading>
                                        }
                                        {
                                            showRecords.RecordParts && 
                                            <Table.Heading className="col-sm-2">{localString('Parts')}</Table.Heading>
                                        }
                                        {
                                            showRecords.RecordParts && 
                                            <Table.Heading className="col-sm-2">{localString('Warranty Status')}</Table.Heading>
                                        }
                                        <Table.Heading className="col-sm-1"></Table.Heading>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {
                                    addedSFA && addedSFA.map((iris,index)=>{
                                        return(
                                            <Table.Row otherProps={{id:index}}>
                                                {
                                                    showRecords.RecordSymptoms &&
                                                    <Table.Data>{iris && iris.Symptom && iris.Symptom.SymptomCode} 
                                                        {
                                                            iris && iris.Symptom && iris.Symptom.SymptomDescription && 
                                                            <span>- {iris.Symptom.SymptomDescription}</span>
                                                        }
                                                    </Table.Data>
                                                }
                                                {
                                                    showRecords.RecordFaults && 
                                                    <Table.Data>{iris && iris.Fault && iris.Fault.FaultCode}
                                                        {
                                                            iris && iris.Fault && iris.Fault.FaultDescription && 
                                                            <span>- {iris.Fault.FaultDescription}</span>
                                                        }
                                                    </Table.Data>
                                                }
                                                {
                                                    showRecords.RecordActions && 
                                                    <Table.Data>{iris && iris.Action && iris.Action.FaultActionCode}
                                                        {
                                                            iris && iris.Action && iris.Action.Name && 
                                                            <span>- {iris.Action.Name}</span>
                                                        }
                                                    </Table.Data>
                                                }
                                                {
                                                    showRecords.RecordParts && 
                                                    <Table.Data>{iris && iris.Part && iris.Part.PartCode}
                                                        {
                                                            iris && iris.Part && iris.Part.Description && 
                                                            <span>- {iris.Part.Description}</span>
                                                        }
                                                    </Table.Data>
                                                }
                                                {
                                                    showRecords.RecordParts && 
                                                    <Table.Data>{iris && iris.Warranty && iris.Warranty.Type}</Table.Data>
                                                }
                                                {
                                                    <Table.Data className = "trash-glyphicon">
                                                        <span className="glyphicon glyphicon-trash" onClick={(e)=>removeAddedFaultCode(e,index)}></span> 
                                                    </Table.Data>
                                                }
                                            </Table.Row>
                                            
                                        )
                                    })
                                }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button 
                    type="button" 
                    className="btn border-gray button-stack btn button-servify pull-right"
                    text={localString('Save')}
                    isDisabled={!(addedSFA && addedSFA.length > 0)}
                    handleOnClick={saveSymptomFaultAction}
                />
                <Button 
                    type="button" 
                    className="btn border-gray button-stack btn button-servify pull-right"
                    text={localString('Close')}
                    handleOnClick={closeModal} //
                />
            </Modal.Footer>
        </Modal>
    )
}

export default SymptomFaultModal
