import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {Modal, Input, Button, Loader} from 'sfy-react';
import Swal from 'sweetalert2';
import { displayError } from '../../../../../Utility/Utils';

const CompleteAnalysisServiceCluster = (props) => {
	const {localString, showModal, closeModal, processRequestNextServiceCluster, consumerServiceRequest, nextStatus, completeAnalyseData, setCompleteAnalyseData,} = props;

	const isValidateRootCause = completeAnalyseData.rootCause && completeAnalyseData.rootCause.length > 50 && completeAnalyseData.rootCause.length < 1001;
	const isValidateResolution = completeAnalyseData.resolution && completeAnalyseData.resolution.length > 50 && completeAnalyseData.resolution.length < 1001;

	return (
		<>
			<Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='Filter-Modal completeAnalysisServiceCluster'>
				<Modal.Header>
					<h4> {localString("Complete Analysis")} </h4>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-sm-12">
							<div className="row" style ={{marginBottom:"30px" , marginLeft:"0px"}}>
								<div className="col-sm-4 action" style={{paddingLeft:"0px"}}>
									<label className = "">{localString("Root Cause Analysis.")}</label>
								</div>
								<div className ="col-sm-7">
									<Input 
										value={completeAnalyseData['rootCause']}
										handleOnChange={(e) => {
											setCompleteAnalyseData({...completeAnalyseData, rootCause: e.target.value})
										}}
										autoComplete='off'
										inputComponent='textarea'
										className={{
											inputContainer: 'height-auto'
										}}
										maxLength={1000}
									/>
								</div>
							</div>
							<div className="row" style = {{marginBottom:"30px" , marginLeft:"0px"}}>
								<div className="col-sm-4 action" style={{paddingLeft:"0px"}}>
									<label className = "">{localString("Solution Provided.")}</label>
								</div>
								<div className ="col-sm-7">
									<Input 
										value={completeAnalyseData['resolution']}
										handleOnChange={(e) => {
											setCompleteAnalyseData({...completeAnalyseData, resolution: e.target.value})
										}}
										autoComplete='off'
										inputComponent='textarea'
										className={{
											inputContainer: 'height-auto'
										}}
										maxLength={1000}
									/>
								</div>
							</div>
							<div>
								<Button 
									className='btn button-servify float-right' 
									handleOnClick = {closeModal}
								>{localString("Close")}</Button>
								<Button 
									className='btn button-servify float-right' 
									isDisabled={!isValidateRootCause || !isValidateResolution}
									handleOnClick = {(e)=>processRequestNextServiceCluster(e,nextStatus)}
								>{localString("Complete Analysis")}</Button>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default CompleteAnalysisServiceCluster