import React from 'react';
import {Table, Dropdown, Input, Button} from 'sfy-react';
import './PackagingMaterialDetails.scss'

const ShippingDetails = (props) => {
    const {shippingToCustomerHeader, consumerServiceRequest, isEditInProgress, localString, packagingMaterialShippingDetails, 
        deviceShippingDetails, shippingToRepairHeader, vendorList, disablePackgingShippingDetailsSave, inputData, setInputData,
        canceleditClick, saveDetails, showPackagingOtherVendorNameInput, showDeviceOtherVendorNameInput, packageVendorInput,
        deviceVendorInput, isReplacementFlow
    } = props;

    const classObject = {
		table:"table table-striped complaint-details-table request-table"
	}

    return (
        <>
            {
                consumerServiceRequest && consumerServiceRequest.servicelocation &&
                <div className="shipping-details">
                    <p style={{margin: "12px 0", fontSize: "18px", fontWeight: "700"}}>{localString("Shipping Details")}</p>
                    <Table className={classObject}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Data otherProps={{colSpan:"2"}}>{localString(shippingToCustomerHeader)}</Table.Data>
                            </Table.Row>
                            <Table.Row>
                                <Table.Data className="table-keys"> {localString("Name of Logistics Partner")} </Table.Data>
                                {
                                    isEditInProgress && 
                                    <Table.Data>
                                        <Dropdown
                                            id="packagingVendorName"
                                            value={inputData.packagingVendorName}
                                            options={vendorList}
                                            handleOnChange={(data) => {
                                                packageVendorInput(data)
                                            }}
                                            filterBy='value'
                                            showDownArrow={false}
                                        />
                                    </Table.Data>
                                }
                                {
                                    !isEditInProgress && 
                                    <Table.Data>
                                        {(packagingMaterialShippingDetails && packagingMaterialShippingDetails.vendor) || 'NA'}
                                    </Table.Data>
                                }
                            </Table.Row>
                            {
                                showPackagingOtherVendorNameInput && 
                                <Table.Row>
                                    <Table.Data className="table-keys"> {localString("Please Specfy Logistics Partner")} </Table.Data>
                                    {
                                        isEditInProgress && 
                                        <Table.Data>
                                            <Input
                                                value={inputData.packagingVendorNameOther}
                                                handleOnChange={(e) => {
                                                    setInputData({
                                                        ...inputData,
                                                        ['packagingVendorNameOther']:e.target.value
                                                    })
                                                }}
                                                className="form-control"
                                                name="packagingVendorNameOther"
                                                id="packagingVendorNameOther"
                                            />
                                        </Table.Data>
                                        //<input type="text" name="packagingVendorNameOther" id="packagingVendorNameOther" oninput={checkShipmentDetails} class="form-control" /> 
                                    }
                                </Table.Row>
                            }
                            <Table.Row>
                                <Table.Data className="table-keys"> {localString("AWB Number")} </Table.Data>
                                {
                                    isEditInProgress && 
                                    <Table.Data>
                                        <Input
                                            value={inputData.packagingAWB}
                                            handleOnChange={(e) => {
                                                setInputData({
                                                    ...inputData,
                                                    ['packagingAWB']:e.target.value
                                                })
                                            }}
                                            className="form-control"
                                            name="packagingAWB"
                                            id="packagingAWB"
                                        />
                                    {/* <input type="text" class="form-control" id="packagingAWB" name="packagingAWB" oninput={checkShipmentDetails}/>  */}
                                    </Table.Data>
                                }
                                {
                                    !isEditInProgress && 
                                    <Table.Data>
                                        {(packagingMaterialShippingDetails && (packagingMaterialShippingDetails.waybill)) || 'NA'}
                                    </Table.Data>
                                }
                            </Table.Row>
                            {
                                !isReplacementFlow &&
                                <Table.Row>
                                    <Table.Data otherProps={{colSpan:"2"}}>{localString(shippingToRepairHeader)}</Table.Data>
                                </Table.Row>
                            }
                            {
                                !isReplacementFlow &&
                                <Table.Row>
                                    <Table.Data className="table-keys"> {localString("Name of Logistics Partner")} </Table.Data>
                                    {
                                        isEditInProgress && 
                                        <Table.Data>
                                            <Dropdown
                                                id="deviceVendorName"
                                                value={inputData.deviceVendorName}
                                                options={vendorList}
                                                handleOnChange={(data) => {
                                                    deviceVendorInput(data)
                                                }}
                                                filterBy='value'
                                                showDownArrow={false}
                                            />
                                        {/* <input type="text" class="form-control" id="deviceVendorName" name="deviceVendorName" onfocus={initiateVendorTypeahead}/>  */}
                                        </Table.Data>
                                    }
                                    {
                                        !isEditInProgress && 
                                        <Table.Data>
                                            {(deviceShippingDetails && deviceShippingDetails.vendor) || 'NA'}
                                        </Table.Data>
                                    }
                                </Table.Row>
                            }
                            {
                                !isReplacementFlow &&
                                showDeviceOtherVendorNameInput && 
                                <Table.Row>
                                    <Table.Data className="table-keys"> {localString("Please Specfy Logistics Partner")} </Table.Data>
                                    {
                                        isEditInProgress && 
                                        <Table.Data>
                                            <Input
                                                value={inputData.deviceVendorNameOther}
                                                handleOnChange={(e) => {
                                                    setInputData({
                                                        ...inputData,
                                                        ['deviceVendorNameOther']:e.target.value
                                                    })
                                                }}
                                                className="form-control"
                                                name="deviceVendorNameOther"
                                                id="deviceVendorNameOther"
                                            />
                                        </Table.Data>
                                        //<input type="text" name="deviceVendorNameOther" id="deviceVendorNameOther" oninput={checkShipmentDetails} class="form-control" />
                                    }
                                </Table.Row>
                            }
                            {
                                !isReplacementFlow && 
                                <Table.Row>
                                    <Table.Data className="table-keys"> {localString("AWB Number")} </Table.Data>
                                    {
                                        isEditInProgress && 
                                        <Table.Data>
                                            <Input
                                                value={inputData.deviceAWB}
                                                handleOnChange={(e) => {
                                                    setInputData({
                                                        ...inputData,
                                                        ['deviceAWB']:e.target.value
                                                    })
                                                }}
                                                className="form-control"
                                                name="deviceAWB"
                                                id="deviceAWB"
                                            />
                                        {/* <input type="text" class="form-control" id="deviceAWB" name="deviceAWB" oninput={checkShipmentDetails}/> */}
                                        </Table.Data>
                                    }
                                    {
                                        !isEditInProgress && 
                                        <Table.Data>
                                        {(deviceShippingDetails && deviceShippingDetails.waybill) || 'NA'}
                                        </Table.Data>
                                    }
                                </Table.Row>
                            }
                        </Table.Body>
                    </Table>
                    <div> 
                    {
                        isEditInProgress && 
                        <div className="pull-right">
                            <Button 
                                type="submit" 
                                name="submitButton"
                                id="saveButton"
                                isDisabled={disablePackgingShippingDetailsSave}
                                text={localString("Save")}
                                className="btn button-servify"
                                handleOnClick={saveDetails}
                            />
                            <Button 
                                type="button" 
                                name="cancelButton"
                                id="cancelButton"
                                text={localString("Cancel")}
                                className="btn button-servify"
                                handleOnClick={canceleditClick}
                            />
                        </div>
                    }
                    </div>
                </div>
            }
        </>
    )
}

export default ShippingDetails
