import React, { useState , useEffect , useRef} from 'react';
import Swal from "sweetalert2";
import { generateUUID } from '../../../../../Utility/Utils';
import DecisionFormFileUpload from './DecisionFormFileUpload';
import {Dropdown,Input,Button} from 'sfy-react'
const DecisionFormInput = (props) => {
    const {currencyCode, localString,consumerServiceRequest,apiCall,setLoader,documentsArray,setDocumentsArray,decisionFormData,setDecisionFormData,otherSource,setOtherSource,getRecommendedModes, showSkipFormConfirmation} = props;
    const [decionApiResponse, setDecionApiResponse] = useState([]);
    const [disableDecisionSubmit,setDisableDecisionSubmit] = useState(true);
    const [textKey,setTextKey] = useState([]);
    const [numberOfFile,setNumberOfFile] = useState(0);
    const fileRef = useRef([]);
    useEffect(()=>{
        if(consumerServiceRequest && consumerServiceRequest.decisionFormQuestions && consumerServiceRequest.decisionFormQuestions.length > 0) {
            setDecionApiResponse(consumerServiceRequest.decisionFormQuestions);
            getKeysData(consumerServiceRequest.decisionFormQuestions)
        }
        if(consumerServiceRequest && consumerServiceRequest.selectOutcomeModes && consumerServiceRequest.selectOutcomeModes.length > 0) {
            // selectOutcomeModes = consumerServiceRequest.selectOutcomeModes;
        }
    },[consumerServiceRequest]);

    const fileOnClick = (file) =>{
        fileRef.current.click();
	}
    const sourceDropdownList = [
        {
            source: "Amazon.com",
            value: "Amazon.com"
        },
        {
            source: "Bestbuy.com",
            value: "Bestbuy.com"
        },
        {
            source: "ebay.com",
            value: "ebay.com"
        },
        {
            source: "www.appliancesonline.com.au",
            value: "www.appliancesonline.com.au"
        },
        {
            source: "https://www.searspartsdirect.com/",
            value: "https://www.searspartsdirect.com/"
        },
        {
            source: "https://www.whirlpool.com/accessories.html",
            value: "https://www.whirlpool.com/accessories.html"
        },
        {
            source: "https://www.geappliances.com/ge/parts/",
            value: "https://www.geappliances.com/ge/parts/"
        },
        {
            source: "CDW.com",
            value: "CDW.com" 
        },
        {
            source: "Dell.com",
            value: "Dell.com"
        },
        {
            source: "https://www.hvacpartsshop.com/",
            value: "https://www.hvacpartsshop.com/"
        },
        {
            source: "Other",
            value: "Other"
        }
    ];

    const deleteFile = (e,constant) =>{
        let removeDocArray = documentsArray && documentsArray.filter(item=>constant !==item.Tag);
        setDocumentsArray(removeDocArray);
        setDecisionFormData({...decisionFormData,[constant]:''})
    }
    const checkFileInput =(e,file) =>{
        let fileID = e.target;
        let fileSize = 5242880;
        if(fileID){
            if(e.target.files[0].size < fileSize){
                uploadDocuments(fileID, e);
            }
        }else{
            Swal.fire({
                title: "",
                text :localString("File size limit 5MB exceeded"),
                icon:"error"
            });
        }
    }

    
    const getKeysData = (arr)=>{
        let constantNameArr = [];
        let currencyObj = {};
        let fileCount = 0
        arr.map(obj=>{
            obj.inputOption && obj.inputOption.length >0  && obj.inputOption.map(type=>{
                if((type.OptionType == 'File') && type.ConstantName){
                    fileCount++;
                }else{
                    constantNameArr.push(type.ConstantName);
                }
                
                if(type.OptionValue == 'Currency' && type.ConstantName){
                    currencyObj[type.ConstantName] = currencyCode;
                }
            })
            
        })
        setTextKey(constantNameArr)
        setDecisionFormData(currencyObj);
        setNumberOfFile(fileCount);
    }
    
    const checkDisableSubmit = (formValue,otherSourceObj) =>{
        let disable = false;
        textKey.map(item=>{
            if(!disable){
                if(!formValue[item] ){
                    disable = true;
                }else if(otherSourceObj && (formValue[item]=='Other') && !otherSourceObj[item]){
                    disable = true;
                }
            }
        })
        setDisableDecisionSubmit(disable);
    }
    const uploadDocuments = (fileID, e)=>{
        setLoader(true);
        let newfile = fileID.files[0];
        if(newfile) {
            let fileType = newfile.type;
            let ext = newfile.name.split(".").length ? "."+newfile.name.split(".")[newfile.name.split(".").length - 1] : "";
            let key = "FEWEBA" + generateUUID() + ext;
            let tagName = e.target.name;
            let data = {
                Type: fileType,
                Key: key,
                BrandID: consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID ? consumerServiceRequest.consumerProduct.BrandID : undefined
            };
            apiCall({
                url: "uploadDocuments",
                data:  data,
                callBack: (result) => {
                    if (result.success) {
                        let xhr = new XMLHttpRequest();
                        xhr.open("PUT", result.data);
                        xhr.setRequestHeader("Cache-Control", "public,max-age=3600");
                        xhr.setRequestHeader('x-amz-acl', 'public-read');
                        xhr.onload = function () {
                            if (xhr.status === 200) {
                                let doc=[...documentsArray]
                                doc.push({
                                    ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                                    FilePath: key,
                                    Type: fileType,
                                    FileName: key,
                                    Tag:tagName,
                                    OriginalName: newfile.name,
                                    DocumentID: 32,
                                    app: 'WebApp'
                                });
                                setDocumentsArray(doc);
                                setLoader(false);
                            } else {
                                Swal.fire({
                                    title:  "",
                                    text:localString("Something went wrong"),
                                    icon:"error"
                                });
                            }

                            }.bind(this);
                            xhr.onerror = function () {}.bind(this);
                            xhr.send(newfile);
                    } else {
                        Swal.fire({
                            title:  "",
                            text:result.msg ?  localString(result.msg) : localString("Something went wrong"),
                            icon:"error"
                        });
                    }
                }
            });
        }
    }
    
    return(
        <>
        <div className='modal-body decision-modal-form'>
            {
                decionApiResponse && decionApiResponse.length > 0 && decionApiResponse.map(formObject=>(
                    <div className='form-group-filter-request decision-form decision-attributes'>
                        <div className="col-sm-2 margin-up order-input">{localString(formObject.Input)}:</div>
                        {
                            formObject.inputOption && formObject.inputOption.length > 0 && formObject.inputOption.map((formInputObject,index)=>(
                                <div className={`${formInputObject.OptionType == 'File' ? 'col-sm-4 order-last' : formInputObject.OptionType == 'DropDown' && decisionFormData[formInputObject.ConstantName]=='Other' ? 'col-sm-4 source-dropdown': 'col-sm-2'} ${formInputObject && formInputObject.OptionValue == 'Currency' ? 'order-first' : 
                                        formInputObject && formInputObject.OptionValue == 'Cost' ? ' order-second' : ''}` }>
                                        <>
                                            { formInputObject && formInputObject.OptionType != 'File' && formInputObject.OptionType == 'Text'  && 
                                                <div className='input-wrapper'>
                                                    <Input
                                                        value = {decisionFormData[formInputObject.ConstantName]}
                                                        key={index}
                                                        autoComplete='off'
                                                        id = {formInputObject.ConstantName}
                                                        name = {formInputObject.ConstantName}
                                                        handleOnChange = {(e) => {
                                                            checkDisableSubmit({...decisionFormData,[formInputObject.ConstantName]:e.target.value})
                                                            setDecisionFormData({...decisionFormData,[formInputObject.ConstantName]:e.target.value})
                                                        }}
                                                        label = {localString(formInputObject.OptionValue)}
                                                        labelAlign = 'top'
                                                        isDisabled ={ formInputObject.OptionValue == 'Currency' && currencyCode}
                                                    />
                                                </div>
                                            }
                                            {
                                                formInputObject && formInputObject.OptionType != "File" && formInputObject.OptionType != "Text" &&
                                                <div className='input-wrapper decision-dropdown'>
                                                    <label className='decision-form-mb'> {localString(formInputObject.OptionValue)}</label>
                                                    <Dropdown
                                                        id= {formInputObject.ConstantName}
                                                        value={ decisionFormData[formInputObject.ConstantName]}
                                                        className={formInputObject.ConstantName}
                                                        options={sourceDropdownList}
                                                        handleOnChange={(item)=>{
                                                            checkDisableSubmit({...decisionFormData,[formInputObject.ConstantName]:item.value});
                                                            setDecisionFormData({...decisionFormData,[formInputObject.ConstantName]:item.value});
                                                            if(item.value == 'Other'){
                                                                setOtherSource({...otherSource,[formInputObject.ConstantName]:''});
                                                            }else{
                                                                setOtherSource({...otherSource,[formInputObject.ConstantName]:undefined});
                                                            }
                                                        }}
                                                        filterBy='value'
                                                        showDownArrow={false}
                                                />
                                                </div>
                                            }
                                            {
                                                (decisionFormData[formInputObject.ConstantName]=='Other') && 
                                                <div className='input-wrapper'>
                                                    <Input
                                                        value = {otherSource[formInputObject.ConstantName]}
                                                        key={index}
                                                        autoComplete='off'
                                                        id = {formInputObject.ConstantName}
                                                        handleOnChange = {(e) => 
                                                            {setOtherSource({...otherSource,[formInputObject.ConstantName]:e.target.value});
                                                            checkDisableSubmit(decisionFormData,{...otherSource,[formInputObject.ConstantName]:e.target.value});
                                                        }}
                                                        label = {localString("Other Source")}
                                                        labelAlign = 'top'
                                                        className = 'form-control decision-input'
                                                    />
                                                </div>
                                            }
                                            {
                                                formInputObject && formInputObject.OptionType == "File" &&
                                                <div className='file-wrapper'>
                                                    <DecisionFormFileUpload
                                                        {...props}
                                                        index={index}
                                                        formObject = {formInputObject}
                                                        checkFileInput = {checkFileInput}
                                                        documentsArray = {documentsArray}
                                                        deleteFile = {deleteFile}
                                                    />
                                                </div>
                                            }
                                        </>
                                </div>
                            ))
                        }
                    </div> 
                ))
            }
            <div className="form-group-filter-request text-right">
                <Button className="btn button-servify-solid submit-btn" handleOnClick={()=>{showSkipFormConfirmation()}}>{localString("Skip")}</Button>
                <Button className="btn button-servify-solid submit-btn save-submit" handleOnClick={()=>{getRecommendedModes()}} isDisabled = {disableDecisionSubmit || (documentsArray.length != numberOfFile)}>{localString("Submit")}</Button>
            </div>
        </div>
        </>
    )
}
export default  DecisionFormInput;