const BRAND = {
	APPLE: 4,
	ONEPLUS: 28
}
const LogisticsStatuses = [
	// hardcoded status.. api was not ready at this point of time. should be migrated. must be!
	"Unassigned",
	"InTransit",
	"Delivered",
	"Cancelled",
];
export { BRAND, LogisticsStatuses };
