import React, { useEffect } from "react";
import "../ConsumerDetails/ConsumerDetails.scss";

const Rules = (props) => {
	let {
		localString,
		apiCall,
		setShowLoader,
		rulesDetails,
		setRulesDetails,
		ConsumerServiceRequestID,
		SoldPlanID,
	} = props;

	let getReasonsforDetectedRaisedflagForCaseManager = () => {
		let data = [
			{
				entity: "ConsumerServiceRequest",
				entityID: ConsumerServiceRequestID,
			},
			{
				entity: "SoldPlan",
				entityID: SoldPlanID,
			},
		];

		setShowLoader(true);
		apiCall({
			url: "getReasonsforDetectedRaisedflagForCaseManager",
			data: { AttributesForHighRisk: data },
			callBack: (result) => {
				setShowLoader(false);
				if (
					result.success &&
					result.data &&
					result.data.detected_raised_flag_table &&
					result.data.detected_raised_flag_table.Records
				) {
					setRulesDetails(
						result.data.detected_raised_flag_table.Records
					);
				}
			},
		});
	};

	useEffect(() => {
		getReasonsforDetectedRaisedflagForCaseManager();
	}, []);

	return (
		<div style={{ padding: "20px" }} className="csr-details-wrapper">
			<div className="csr-col">
				<div className="row csr-row-pad">
					<div className="csr-heading col-sm-4">
						{localString("Rule Category")}
					</div>
					<div className="csr-heading col-sm-4">
						{localString("Rule Name")}
					</div>
					<div className="csr-heading col-sm-4">
						{localString("Rule Description")}
					</div>
				</div>

				{rulesDetails && rulesDetails.length > 0 ? (
					rulesDetails.map((rules) => (
						<div className="row csr-product-row-pad">
							<div className="col-sm-4">
								{rules.RaisedFlagReasonCode.RuleType || "-"}
							</div>
							<div className="col-sm-4">
								{localString(
									rules.RaisedFlagReasonCode
										.RaisedFlagReasonCode
								)}
							</div>
							<div className="col-sm-4">
								{localString(
									rules.RaisedFlagReasonCode.RaisedFlagReasonDescription
								)}
							</div>
						</div>
					))
				) : (
					<div className="text-center">No Data</div>
				)}
			</div>
		</div>
	);
};

export default Rules;
