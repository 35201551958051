import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import { Loader } from 'sfy-react';
import { clone, cloneDeep, find } from 'lodash';
import { Button } from 'sfy-react';

import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import ShowCharges from '../EstimationDetails/ShowCharges';
import AddChargesInputArrays from '../EstimationDetails/AddChargesInputArrays';
import EstimationButtons from '../EstimationDetails/EstimationButtons';
import DiscountModal from '../EstimationDetails/DiscountModal';
import AddPartModal from '../EstimationDetails/AddPartModal';
import { SERVICETYPE, displayError, validateThis } from '../../../../../Utility/Utils';

import './EstimationDetails.scss';

export default function EstimationDetails(props) {
    const {
        titleHeader, isWorkshopFlow, localString, currencySymbol, consumerServiceRequest, accessFn, addedFaultCodes,
        coverageDetails, isRequestSFANeeded, requestSFA, getRequestById, exceptionLogs, apiCall, partsList, getPartsList,
		getPartsForRequest, getRequestSFA, isBoseFlow, showPartModal, setShowPartModal, setActiveTab,planID
    } = props;

    const qFilter = JSON.parse(props.qFilter);
	const [loader, setLoader] = useState(false);
	const [countryCodeArr, setCountryCodeArr] = useState(qFilter.countrycode || []);
	const [country, setCountry] = useState(countryCodeArr.length ? countryCodeArr[0] : 'IN');
	const [taxSlabRequired, setTaxSlabRequired] = useState(false);
	const [showEstimationButtons, setShowEstimationButtons] = useState(false);
	const [selectedChargeTypeArray, setSelectedChargeTypeArray] = useState([]);
	const [showDiscountModal, setShowDiscountModal] = useState(false);
    const depreciatedValue = consumerServiceRequest?.consumerProduct?.DepreciatedValue;
    const exceptionRejected = find(exceptionLogs, {Status: 'Exception rejected'});
	const exceptionRaised = find(exceptionLogs, {Status: 'Exception raised'});
	const isPaymentReceived = find(consumerServiceRequest.logs, {Status: 'Payment pending'});
	const [isDiscountUpdate, setIsDiscountUpdate] = useState(false);
	const [editPart, setEditPart] = useState([])
	const [editedPart, setEditedPart] = useState()
	const [isEditPartModal, setIsEditPartModal] = useState(false)
	const [consigmentSerialNumberList, setConsigmentSerialNumberList] = useState([])
	const [typeOfWarranty, setTypeOfWarranty] = useState([])
	const [editallIssuesList, setEditallIssuesList] = useState([])
	const [comptiaModifierList, setComptiaModifierList] = useState([])
	const [pricingOptions, setPricingOptions] = useState([])
	const [componentCodeList, setComponentCodeList] = useState([]);
	const [coverageOption, setCoverageOption] = useState([])
	const [isEditedConsignmentNeeded, setIsEditedConsignmentNeeded] = useState(true)
	const [isEditedConsignmentSerialNeeded, setIsEditedConsignmentSerialNeeded] = useState(true)
	const [editPartObject, setEditPartObject] = useState({
		editSelectedComponent: "",
		editSelectedComponentCodeName: "",
		editSelectedIssue: "",
		editSelectedIssueCodeName: "",
		editSelectedReproducibility: "",
		editSelectedReproducibilityName: "",
		editSelectedPartWarranty: "",
		editSelectedConsignment: "",
		editSelectedPartItemID: "",
		editSelectedConsignmentSerialNumber: "",
		editSelectedPartSerialNumber: "",
		editSelectedPricingOption: "",
		editSelectedPricingOptionDescription: "",
		editSelectedCoverageOption: "",
		editSelectedCoverageOptionDescription: "",
	});
	const [editSelectedIsPartAbused, setEditSelectedIsPartAbused] = useState("No");
	const [editSelectedIsReturnableDamage, setEditSelectedIsReturnableDamage] = useState("No");
	const [editSelectedIsCoveredByACPlus, setEditSelectedIsCoveredByACPlus] = useState("No");
	const [editSelectedConsignmentNeeded, setEditSelectedConsignmentNeeded] = useState("No");
	const [discountDisabled, setDiscountDisabled] = useState(false);
	const [charges, setCharges] = useState(consumerServiceRequest?.charges || []);
	const [partConfig, setPartConfig] = useState({});
	const [partCategoryDropdown,setPartCategoryDropDown] = useState([]);

    const workshopChargeType = [
		{
			// display: !isRequestSFANeeded || (([10, 11, 12, 14, 23].indexOf(self.csrid.ServiceTypeID) > -1) && ),
			// Todo: Need to understand when to display Part name field based oon RIOT code base
			display: (isWorkshopFlow && !isRequestSFANeeded),
			// Name: "",
			Placeholder: 'Part name',
			Display: 'Parts',
			Type: 'Part',
			onClick: (val, index) => {
				if(accessFn('WORKSHOP_FLOW')){
					setShowPartModal(true);
				}else{
					let arr = clone(selectedChargeTypeArray);
					let obj = {...val,}
					arr.push(obj);
					setSelectedChargeTypeArray(arr);
				}
			},
			disabled: false
		},
		{
			display: (([10, 11, 12, 14, 23].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && (['Claim estimation generated', 'BER identified', 'Parts request accepted', 'Parts partially issued', 'Parts issued', 'Parts received', 'Work in progress', 'Service initiated','Device details validated', 'Claim estimation revision required'].indexOf(consumerServiceRequest.Status) > -1 && accessFn('ADD_NORMAL_CHARGES'))) || !([10, 11, 12, 14, 23].indexOf(consumerServiceRequest.ServiceTypeID) > -1),
			Name: "",
			Placeholder: 'Other Charge',
			Display: 'Other Charge',
			Type: 'Other',
			onClick: (val) => {
				let arr = clone(selectedChargeTypeArray);
				let obj = {...val}
				arr.push(obj);
				setSelectedChargeTypeArray(arr);
			},
			disabled: consumerServiceRequest.ServiceTypeID == 39
		},
		{
			display: (isWorkshopFlow && !([10, 11, 12, 14, 23].indexOf(consumerServiceRequest.ServiceTypeID) > -1)),
			// Name: "",
			Placeholder: 'Advance',
			Display: 'Advance',
			Type: 'Advance payment',
			onClick: (val) => {
				let arr = clone(selectedChargeTypeArray);
				let obj = {...val}
				arr.push(obj);
				setSelectedChargeTypeArray(arr);
			},
			disabled: consumerServiceRequest.ServiceTypeID == 39
		},
		{
			display: (isWorkshopFlow && !([10, 11, 12, 14, 23].indexOf(consumerServiceRequest.ServiceTypeID) > -1) || (['Claim estimation generated', 'BER identified', 'Parts request accepted', 'Parts partially issued', 'Parts issued', 'Parts received', 'Work in progress', 'Service initiated','Device details validated', 'Claim estimation revision required'].indexOf(consumerServiceRequest.Status) > -1 && accessFn('ADD_NORMAL_CHARGES') && !['US'].indexOf(country) > -1)),
			// Name:"",
			Placeholder: 'Discount',
			Display: 'Discount',
			Type: 'Discount',
			onClick: (val) => {
				if(isWorkshopFlow) {
					if(consumerServiceRequest.ConsumerAmount) {
						setDiscountDisabled(false);
					}else {
						setDiscountDisabled(true);
					}
					let isServiceChargeAdded = find(consumerServiceRequest.charges, {Type: 'ServiceCharge'})
					let isOtherChargeAdded = find(consumerServiceRequest.charges, {Type: 'Other'})
					if((partsList.length > 0 || isServiceChargeAdded || isOtherChargeAdded) && [1,2].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						setShowDiscountModal(true);
					} else if ([1,2].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
						let arr = clone(selectedChargeTypeArray);
						let obj = {...val}
						arr.push(obj);
						setSelectedChargeTypeArray(arr);
					}
				}else {
					let arr = clone(selectedChargeTypeArray);
					let obj = {...val}
					arr.push(obj);
					setSelectedChargeTypeArray(arr);
				}
			},
			disabled: consumerServiceRequest.IsWarrantyApplicable || (consumerServiceRequest.ServiceTypeID == 39)
		}
	]

	//Todo
	const chargeType = [
		{
			// display: true,
			display: (([10, 11, 12, 14, 23, 52].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && 
				( (accessFn('ADD_PART_POST_APPROVAL') && consumerServiceRequest.allowAddPart) ||
					(!(accessFn('ALLOW_ADDING_DISCOUNT') && consumerServiceRequest.allowDiscount && ['Claim estimation generated'].indexOf(consumerServiceRequest.Status) > -1) && 
					(['Device received', 'BER identified', 'Claim estimation revision required'].indexOf(consumerServiceRequest.Status) > -1 && accessFn('ADD_NORMAL_CHARGES')))
				) ? true : (['Claim estimation generated'].indexOf(consumerServiceRequest.Status) > -1) ? false : !([10, 11, 12, 14, 23,52].indexOf(consumerServiceRequest.ServiceTypeID) > -1) ? true : false
			),
			// Name: "",
			Placeholder: 'Part Name',
			Display: 'Part Charge',
			Type: 'Part',
			onClick: (val, index) => {
				if(accessFn('WORKSHOP_FLOW')){
					setShowPartModal(true);
				}else{
					let arr = clone(selectedChargeTypeArray);
					let obj = {...val}
					arr.push(obj);
					setSelectedChargeTypeArray(arr);
					if(partConfig && partConfig.partConfigEnable){
						getPartCategory();
					}
				}
			},
			disabled: false
		},
		{
			// display: true,
			display: (([10, 11, 12, 14, 23, 52].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && 
				(
					!(accessFn('ALLOW_ADDING_DISCOUNT') && consumerServiceRequest.allowDiscount && ['Claim estimation generated'].indexOf(consumerServiceRequest.Status) > -1) && 
					(['Device received', 'BER identified', 'Claim estimation revision required'].indexOf(consumerServiceRequest.Status) > -1 && accessFn('ADD_NORMAL_CHARGES'))
				) ? true : (['Claim estimation generated'].indexOf(consumerServiceRequest.Status) > -1) ? false : !([10, 11, 12, 14, 23,52].indexOf(consumerServiceRequest.ServiceTypeID) > -1) ? true : false
			),
			// Name:"",
			Placeholder: 'Other Charge',
			Display: 'Other Charge',
			Type: 'Other',
			onClick: (val) => {
				let arr = clone(selectedChargeTypeArray);
				let obj = {...val}
				arr.push(obj);
				setSelectedChargeTypeArray(arr);
			},
			disabled: consumerServiceRequest.ServiceTypeID == 39
		},
		{
			// display: true,
			display: (([10, 11, 12, 14, 23, 52].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && 
				(
					!(accessFn('ALLOW_ADDING_DISCOUNT') && consumerServiceRequest.allowDiscount && ['Claim estimation generated'].indexOf(consumerServiceRequest.Status) > -1) && 
					(['Device received', 'BER identified', 'Claim estimation revision required'].indexOf(consumerServiceRequest.Status) > -1 && accessFn('ADD_NORMAL_CHARGES'))
				) ? true : (['Claim estimation generated'].indexOf(consumerServiceRequest.Status) > -1) ? false : !([10, 11, 12, 14, 23,52].indexOf(consumerServiceRequest.ServiceTypeID) > -1) ? true : false
			),
			// Name: "",
			Placeholder: 'Advance',
			Display: 'Advance',
			Type: 'Advance payment',
			onClick: (val) => {
				let arr = clone(selectedChargeTypeArray);
				let obj = {...val}
				arr.push(obj);
				setSelectedChargeTypeArray(arr);
			},
			// disabled: consumerServiceRequest.ServiceTypeID == 39
		},
		{
			display: (([10, 11, 12, 14, 23, 52].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && (
						(accessFn('ALLOW_ADDING_DISCOUNT') && consumerServiceRequest.allowDiscount && ['Claim estimation generated'].indexOf(consumerServiceRequest.Status) > -1) || 
						(['Device received', 'BER identified', 'Claim estimation revision required'].indexOf(consumerServiceRequest.Status) > -1 && accessFn('ADD_NORMAL_CHARGES'))
					)) ? true : ['Claim estimation generated'].indexOf(consumerServiceRequest.Status) > -1 ? false : !([10, 11, 12, 14, 23,52].indexOf(consumerServiceRequest.ServiceTypeID) > -1) ? true : false,
			// Name:"",
			Placeholder: 'Discount',
			Display: 'Discount',
			Type: 'Discount',
			onClick: (val) => {
				if(isWorkshopFlow) {
					if(consumerServiceRequest.ConsumerAmount) {
						setDiscountDisabled(false);
					}else {
						setDiscountDisabled(true);
					}
					let isServiceChargeAdded = find(consumerServiceRequest.charges, {Type: 'ServiceCharge'})
					let isOtherChargeAdded = find(consumerServiceRequest.charges, {Type: 'Other'})
					if((partsList.length > 0 || isServiceChargeAdded || isOtherChargeAdded) && [1,2].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
						setShowDiscountModal(true);
					} else if ([1,2].indexOf(consumerServiceRequest.ServiceTypeID) == -1) {
						let arr = clone(selectedChargeTypeArray);
						let obj = {...val}
						arr.push(obj);
						setSelectedChargeTypeArray(arr);
					}
				}else {
					let arr = clone(selectedChargeTypeArray);
					let obj = {...val}
					if(accessFn('ALLOW_ADDING_DISCOUNT') && consumerServiceRequest.allowDiscount && ['Claim estimation generated'].indexOf(consumerServiceRequest.Status) > -1) {
						obj.Remarks = 'Samsung Care Plus Amount Borne by OEM';
						if(consumerServiceRequest.TotalBilledAmount > depreciatedValue.sumInsured){
							obj.Charge = (consumerServiceRequest.TotalBilledAmount - depreciatedValue.sumInsured).toFixed(2)
						}
					}
					arr.push(obj);
					setSelectedChargeTypeArray(arr);
				}
			},
			disabled: consumerServiceRequest.IsWarrantyApplicable || (consumerServiceRequest.ServiceTypeID == 39)
		}
	]

    const isHandedToEng  = () =>{
		let isHanded = false
		let deviceHanded = find(consumerServiceRequest.logs, {Status: 'Device handed over'})
		if (deviceHanded) {
			if (deviceHanded.DestinationType == 'ServicelocationEngineer' || deviceHanded.DestinationType == 'Supervisor') {
				isHanded = true;
			}
		}
		return isHanded;
	}

    const warrantyApplicableCheck = () => {
        return consumerServiceRequest && (consumerServiceRequest.IsWarrantyApplicable == null || undefined) && (['applicable', 'replacement'].indexOf(consumerServiceRequest.DOAStatus) == -1) ? false : true;
    };

    const getSumInsured = () => {
        if(consumerServiceRequest && coverageDetails && coverageDetails.length > 0) {
			let selectedCoverage = find(coverageDetails, function(obj) {
				return  obj.Status == 1 && obj.RequestDetails && obj.RequestDetails.ClaimReferenceID == consumerServiceRequest.ReferenceID;
			})
            return selectedCoverage && selectedCoverage.RequestDetails.SumInsured ? selectedCoverage.RequestDetails.SumInsured : null;
        } else {
            return null;
        }
    };

    const coveredUnderPlanCheck = () => {
        return consumerServiceRequest && consumerServiceRequest.EWRequest ? ((consumerServiceRequest.IsPlanEligible != null || undefined) ? true : false) : true;
    };

	const saveWorkshopFlow = (isSubmit) => {
		let isArrayValid = true;
        let invalidArrayParam = '';

        selectedChargeTypeArray.forEach(function(charge) {
			delete charge.disabled;
			delete charge.display;
            let validator;
            charge.IsTaxable = true;
            charge.ChargeType = 'Credit';
            if(charge.Type == "Part" && charge.Remarks == "") {
				let arr = [
					{
						checkType: qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 ? 'isAlphaNumericTurkey' : 'isAlphaNumeric',
						key: 'Name'
					}, 
					{
						checkType: 'isFloat',
						key: 'Charge'
					}, 
					{
						checkType: 'isPositive',
						key: 'Charge'
					}, 
					{
						checkType: 'isAlphaNumeric',
						key: 'ChargeCode'
					}, 
					{
						checkType: 'isNotEmpty',
						key: 'TaxSlab'
					}, 
					{
						checkType: 'isNotEmpty',
						key: 'ChargeCode',
					}
				];
              	validator = validateThis(charge, arr);
				charge.ChargeCodeType = 'HSN';
				charge.IsDeducted = false;
				charge.TaxSlab = parseInt(charge.TaxSlab);
            }else if(charge.Type == "Part" && charge.Remarks !== "") {
				let arr = [
					{
						checkType: qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 ? 'isAlphaNumericTurkey' : 'isAlphaNumeric',
						key: 'Name'
					}, 
					{
						checkType: qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 ? 'isAlphaNumericTurkey' : 'isAlphaNumeric',
						key: 'Remarks'
					}, 
					{
						checkType: 'isFloat',
						key: 'Charge'
					}, 
					{
						checkType: 'isPositive',
						key: 'Charge'
					}, 
					{
						checkType: 'isAlphaNumeric',
						key: 'ChargeCode'
					}, 
					{
						checkType: 'isNotEmpty',
						key: 'TaxSlab'
					}, 
					{
						checkType: 'isNotEmpty',
						key: 'ChargeCode'
					}
				];
              	validator = validateThis(charge, arr);
				charge.ChargeCodeType = 'HSN';
				charge.IsDeducted = false;
				charge.TaxSlab = parseInt(charge.TaxSlab);
            } else {
				let arr = [
					{
						checkType: qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 ? 'isAlphaNumericTurkey' : 'isAlphaNumeric',
						key: 'Name'
					}, 
					{
						checkType: 'isFloat',
						key: 'Charge'
					}, 
					{
						checkType: 'isPositive',
						key: 'Charge'
					}, 
					{
						checkType: qFilter.flowType && qFilter.flowType.fulFillmentFlow && qFilter.flowType.fulFillmentFlow.indexOf('REQ_FUL_TURKEY') > -1 ? 'isAlphaNumericTurkey' : 'isAlphaNumeric',
						key: 'Remarks'
					}
				];
              	validator = validateThis(charge, arr);
				charge.ChargeCodeType = 'SAC';
				if(charge.Type == 'Advance payment' || charge.Type == 'Discount') {
					charge.IsDeducted = true;
					if (charge.Type == 'Advance payment') {
						charge.IsTaxable = false;
					}
				}else {
					charge.IsDeducted = false;
				}
            }
            if(!validator.isValid) {
                isArrayValid = false;
                invalidArrayParam = validator.invalidParams[0];
                if(invalidArrayParam === "ChargeCode") {
                  	invalidArrayParam = "Charge Code";
                }else if (invalidArrayParam === "TaxSlab") {
                  	invalidArrayParam = "Tax Amount";
                }
            }
        });

        if(!isArrayValid) {
			Swal.fire({  
				icon: 'error',  
				text: localString("Please enter correct ") + localString(invalidArrayParam),  
                confirmButtonText: localString('OK')
			});
            return;
        }

        var oldDiscounts = 0;
        var oldAdvances = 0;
        var oldParts = 0;
        var oldMandatoryDeducitble = 0;
        var oldAdminFees = 0;
        var oldOthers = 0;
        var oldInsuranceAmount = 0;
        var oldInsuranceApproved = 0;
        var newOthers = 0;
        var newAdvances = 0;
        var newParts = 0;
        var newDiscounts = 0;
        var newMandatoryDeducitble = 0;
        var newAdminFees = 0;
        var newInsuranceAmount = 0;
        var newInsuranceApproved = 0;

        //Calculating old charges
        consumerServiceRequest.charges.forEach(function(charge) {
            switch (charge.Type) {
                case 'Discount':
                    oldDiscounts += charge.Charge;
                    break;
                case 'Other':
                    oldOthers += charge.Charge;
                    break;
                case 'Advance':
                    oldAdvances += charge.Charge;
                    break;
                case 'Advance payment':
                    oldAdvances += charge.Charge;
                    break;
                case 'Part':
                    oldParts += charge.Charge;
                    break;
                case 'Mandatory deductible':
                    oldMandatoryDeducitble += charge.Charge;
                    break;
                case 'Admin fees':
                    oldAdminFees += charge.Charge;
                    break;
                case 'Claim amount':
                    oldInsuranceAmount += charge.Charge;
                    break;
            }
        });

        //Calculating new charges
        selectedChargeTypeArray.forEach(function(charge) {
            switch (charge.Type) {
                case 'Discount':
                    newDiscounts += (parseInt(charge.Charge, 10) || 0);
                    break;
                case 'Other':
                    newOthers += (parseInt(charge.Charge, 10) || 0);
                    break;
                case 'Advance':
                    newAdvances += (parseInt(charge.Charge, 10) || 0);
                    break;
                case 'Advance payment':
                    newAdvances += (parseInt(charge.Charge, 10) || 0);
                    break;
                case 'Part':
                    newParts += (parseInt(charge.Charge, 10) || 0);
                    break;
                case 'Mandatory deductible':
                    newMandatoryDeducitble += (parseInt(charge.Charge, 10) || 0);
                    break;
                case 'Admin fees':
                    newAdminFees += (parseInt(charge.Charge, 10) || 0);
                    break;
                case 'Claim amount':
                    newInsuranceAmount += (parseInt(charge.Charge, 10) || 0);
                    break;
            }
        });
        //Computing all taxable charges without discounts
        var taxableAmount =
            (
                (consumerServiceRequest.ServiceCharge || 0) +
                (consumerServiceRequest.PremiumCharge || 0) +
                (oldOthers || 0) +
                (newOthers || 0)
            ) -
            (
                (consumerServiceRequest.WarrantyConcession || 0)
            );

        if (isNaN(taxableAmount) || (taxableAmount < 0)) {
            taxableAmount = 0;
        }

        if (newAdvances > consumerServiceRequest.ConsumerAmount) {
			Swal.fire({
				icon: 'error',
                // text: localString("Advance cannot be greater than")+' (' + consumerServiceRequest.CurrencySymbol + ')' + consumerServiceRequest.ConsumerAmount,
				html:'<span>'+localString('Advance cannot be greater than ')+ '(' + currencySymbol + ')' + consumerServiceRequest.ConsumerAmount +'</span>',
                confirmButtonText: localString('OK')
			});
            return;

        } else if ((newDiscounts + oldDiscounts) > taxableAmount) {
			Swal.fire({
				icon: 'error',
                // text:localString("Discount cannot be greater than")+' (' + consumerServiceRequest.CurrencySymbol + ')' + taxableAmount,
				html:'<span>'+localString('Discount cannot be greater than ')+ '(' + currencySymbol + ')' + taxableAmount +'</span>',
                confirmButtonText: localString('OK')
			});
            return;
        }
        let data = {
            updatedCharges: selectedChargeTypeArray,
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            ServiceTypeID: consumerServiceRequest.ServiceTypeID,
            PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID
        };
        if(!accessFn('GSX_FLOW') && [10,11,12,16,23,38,52,64].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
            data.Submit = isSubmit ? true : false;
            data.Status = isSubmit ? 'Claim estimation generated' : undefined;
        }
		setLoader(true);
		apiCall({
			url: 'updateChargeWorkshop',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					// updatedCharges = [];
					setSelectedChargeTypeArray([])
                    getRequestById();
                    // showHideSaveButton(selectedChargeTypeArray);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const saveNonWorkshopFlow = (isSubmit) => {
        let isArrayValid = true;
        let invalidArrayParam = '';

        selectedChargeTypeArray.forEach(function(charge) {
			delete charge.disabled;
			delete charge.display;
            let validator;
            charge.IsTaxable = true;
            charge.ChargeType = 'Credit';
			let validatorArray = [];
			if(charge.Type == "Part") {
				if(partConfig && partConfig.partConfigEnable){
					if(partConfig.autocomplete == "MANDATORY"){
						validatorArray.push(
							{
								checkType: 'isNotEmpty',
								key: 'partItemID',
							})
					}
					validatorArray.push(
						{
							checkType: 'isNotEmpty',
							key: 'partItemCode',
						},
						{
							checkType: 'isNotEmpty',
							key: 'partItemDescription',
						},
						{
							checkType: 'isNotEmpty',
							key: 'partCategory',
						},
					);
				}else{
					validatorArray.push(
						{
							checkType: 'isAlphaNumeric',
							key: 'ChargeCode'
						}, 
						{
							checkType: 'isNotEmpty',
							key: 'ChargeCode',
						},
						{
							checkType: 'isAlphaNumeric',
							key: 'Name'
						}
					);
				}
				validatorArray.push(
					{
						checkType: 'isFloat',
						key: 'Charge'
					}, 
					{
						checkType: 'isPositive',
						key: 'Charge'
					}
				)
				
				if(taxSlabRequired) {
					validatorArray.push({
						checkType: 'isNotEmpty',
						key: 'TaxSlab'
					});
				}
				if (charge.Remarks !== "") {
					validatorArray.push({
						checkType: 'isAlphaNumeric',
						key: 'Remarks'
					});
				}
				validator = validateThis(charge, validatorArray);
				charge.ChargeCodeType = 'HSN';
				charge.IsDeducted = false;
				if (taxSlabRequired) {
					charge.TaxSlab = parseInt(charge.TaxSlab);
				}
			}else {
				validatorArray.push({
					checkType: 'isAlphaNumeric',
					key: 'Remarks'
				},
				{
					checkType: 'isAlphaNumeric',
					key: 'Name'
				});
				validator = validateThis(charge, validatorArray);
				charge.ChargeCodeType = 'SAC';
				if(charge.Type == 'Advance payment' || charge.Type == 'Discount') {
					charge.IsDeducted = true;
					if (charge.Type == 'Advance payment') {
						charge.IsTaxable = false;
					}
				} else {
					charge.IsDeducted = false;
				}
			}
            if(!validator.isValid) {
                isArrayValid = false;
                invalidArrayParam = validator.invalidParams[0];
                if (invalidArrayParam === "ChargeCode") {
                  	invalidArrayParam = "Charge Code";
                } else if (invalidArrayParam === "TaxSlab") {
                	invalidArrayParam = "Tax Amount";
                }else if (invalidArrayParam === "partItemCode") {
                	invalidArrayParam = "Part Code";
                }else if (invalidArrayParam === "partItemDescription") {
                	invalidArrayParam = "Part Description";
                }else if(invalidArrayParam === "partCategory") {
                	invalidArrayParam = "Part Category";
                }
            }
        });
		if(invalidArrayParam == "partItemID"){
			Swal.fire({
				icon: 'error',
				text: localString('Please select  part code from the dropdown.'),
			});
            return;
		}

        if (!isArrayValid) {
			Swal.fire({
				icon: 'error',
				text: localString('Please enter correct')+' '+ invalidArrayParam,
			});
            return;
        }
        var oldDiscounts = 0;
        var oldAdvances = 0;
        var oldParts = 0;
        var oldMandatoryDeducitble = 0;
        var oldAdminFees = 0;
        var oldOthers = 0;
        var oldInsuranceAmount = 0;
        var oldInsuranceApproved = 0;
        var newOthers = 0;
        var newAdvances = 0;
        var newParts = 0;
        var newDiscounts = 0;
        var newMandatoryDeducitble = 0;
        var newAdminFees = 0;
        var newInsuranceAmount = 0;
        var newInsuranceApproved = 0;

        //Calculating old charges
        consumerServiceRequest.charges.forEach(function(charge) {
            switch (charge.Type) {
                case 'Discount':
                    oldDiscounts += charge.Charge;
                    break;
                case 'Other':
                    oldOthers += charge.Charge;
                    break;
                case 'Advance':
                    oldAdvances += charge.Charge;
                    break;
                case 'Advance payment':
                    oldAdvances += charge.Charge;
                    break;
                case 'Part':
                    oldParts += charge.Charge;
                    break;
                case 'Mandatory deductible':
                    oldMandatoryDeducitble += charge.Charge;
                    break;
                case 'Admin fees':
                    oldAdminFees += charge.Charge;
                    break;
                case 'Claim amount':
                    oldInsuranceAmount += charge.Charge;
                    break;
            }
        });

        //Calculating new charges
        selectedChargeTypeArray.forEach(function(charge) {
            switch (charge.Type) {
                case 'Discount':
                    newDiscounts += (parseInt(charge.Charge, 10) || 0);
                    break;
                case 'Other':
                    newOthers += (parseInt(charge.Charge, 10) || 0);
                    break;
                case 'Advance':
                    newAdvances += (parseInt(charge.Charge, 10) || 0);
                    break;
                case 'Advance payment':
                    newAdvances += (parseInt(charge.Charge, 10) || 0);
                    break;
                case 'Part':
                    newParts += (parseInt(charge.Charge, 10) || 0);
                    break;
                case 'Mandatory deductible':
                    newMandatoryDeducitble += (parseInt(charge.Charge, 10) || 0);
                    break;
                case 'Admin fees':
                    newAdminFees += (parseInt(charge.Charge, 10) || 0);
                    break;
                case 'Claim amount':
                    newInsuranceAmount += (parseInt(charge.Charge, 10) || 0);
                    break;
            }
        });
        //Computing all taxable charges without discounts
        var taxableAmount =
            (
                (consumerServiceRequest.ServiceCharge || 0) +
                (consumerServiceRequest.PremiumCharge || 0) +
                (oldOthers || 0) +
                (newOthers || 0)
            ) -
            (
                (consumerServiceRequest.WarrantyConcession || 0)
            );

        if (isNaN(taxableAmount) || (taxableAmount < 0)) {
            taxableAmount = 0;
        }

        if (newAdvances > consumerServiceRequest.ConsumerAmount) {
			Swal.fire({
				icon: 'error',
				html:'<span>'+localString('Advance cannot be greater than ')+ '(' + currencySymbol + ')' + consumerServiceRequest.ConsumerAmount +'</span>',
				// text: localString('Advance cannot be greater than') + '(&#8377)' + consumerServiceRequest.ConsumerAmount,
			});
            return;
        }else if (newDiscounts > 0 && (newDiscounts + oldDiscounts) > taxableAmount && !(consumerServiceRequest.allowDiscount && accessFn('ALLOW_ADDING_DISCOUNT'))) {
			Swal.fire({
				icon: 'error',
				html:'<span>'+localString('Discount cannot be greater than ')+ '(' + currencySymbol + ')' + taxableAmount +'</span>',
				// text: localString('Discount cannot be greater than') + '(&#8377)' + taxableAmount,
			});
        	return;
		}
		// Reverting discount validation cz of PROD issue(SAMSUNG)
		// else if ((newDiscounts + oldDiscounts) > consumerServiceRequest.ConsumerAmount) {
		// 	Swal.fire({
		// 		icon: 'error',
		// 		html:'<span>'+localString('Discount cannot be greater than ')+ '(' + currencySymbol + ')' + consumerServiceRequest.ConsumerAmount +'</span>',
		// 	});
        //     return;
        // }

        let data = {
            updatedCharges: selectedChargeTypeArray,
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            ServiceTypeID: consumerServiceRequest.ServiceTypeID,
            PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID,
        };

        if(!accessFn('GSX_FLOW')) {
			if([10,11,12,16,23,38,52,64].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
				data.Submit = isSubmit ? true : false;
				data.Status = isSubmit ? 'Claim estimation generated' : undefined;
			}else if (([3].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && isBoseFlow)) {
				data.Submit = isSubmit ? true : false;
				data.Status = isSubmit ? 'Service invoice generated' : undefined;
			}
        }
		if(consumerServiceRequest.consumerProduct.ProductPurchaseCost > 0 && newParts > consumerServiceRequest.consumerProduct.ProductPurchaseCost){
			Swal.fire({
				icon: "error",
				text: localString("Part(s) charge greater than the product purchase cost is added, please click “cancel” if this was accidental. Please click ‘Proceed’ if you wish to add this part charge and continue."),
				confirmButtonText: localString("Proceed"),
				showCancelButton: true,
				cancelButtonText: localString("Cancel")
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.close();
					updateChargeApiCall(data);
				}
			})
			return;
		}
		updateChargeApiCall(data);
	}

	const updateChargeApiCall = (data) =>{
		let url = "updateCharge";
        if(consumerServiceRequest.allowAddPart){
            url = "updateChargePostEstimate";
        }

		setLoader(true);
		apiCall({
			url: url,
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					// updatedCharges = [];
					setSelectedChargeTypeArray([])
                    getRequestById();
                    // showHideSaveButton(selectedChargeTypeArray);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const submit = () => {
		let addedCharge = consumerServiceRequest.charges.find((obj) => {return ['Adminfees', 'ServiceCharge','Advance payment','Advance'].indexOf(obj.Type) == -1});
		if(!addedCharge && selectedChargeTypeArray.length == 0) {
			Swal.fire({
				text: localString("Please add a charges to proceed"),
				icon: "error"
			})
		} else {
			Swal.fire( {
				title: localString("Are you sure?"),
				text: localString("The estimate once submitted cannot be changed until it has been reviewed by a claims administrator."),
				icon: 'question',
				showCancelButton: true,
				cancelButtonText: localString('No'),
				showConfirmButton: true,
				confirmButtonText: localString('Yes')
			}).then((result)=>{
				if(result.isConfirmed){
					if(isWorkshopFlow) {
						saveWorkshopFlow(true);
					}else {
						saveNonWorkshopFlow(true);
					}
				}
			})
		}
	}

    const setEstimationButtons  = () =>{
		let showestimationbuttons = false;
		let issueAddedValid = true;
		let issuesValidated = false;
		if(consumerServiceRequest.issues && consumerServiceRequest.issues.length > 0) {
			for(let i = 0; i < consumerServiceRequest.issues.length; i++){
				if(consumerServiceRequest.issues[i].IsValidated == false) {
					issueAddedValid = false;
					break;
				}
			}
		}
		let sendIssuesGSXFlag = (consumerServiceRequest.gsxRepairSymtomsIssues && consumerServiceRequest.gsxRepairSymtomsIssues.length ? false : true);
		if (accessFn('GSX_FLOW')) {
			if (sendIssuesGSXFlag) {
				issuesValidated = false;
			}else {
				if (issueAddedValid) {
					issuesValidated = true;
				}else {
					issuesValidated = false;
				}
			}
		}else {
			if(isRequestSFANeeded){
				if(requestSFA && requestSFA.length > 0 && issueAddedValid){
					issuesValidated = true;
				} else if ([1,2].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
					issuesValidated = false;
				}
			}else{
				if(consumerServiceRequest && consumerServiceRequest.Symptoms && consumerServiceRequest.Symptoms.length > 0 && issueAddedValid){
					issuesValidated = true;
				} else if ([1,2].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
					issuesValidated = false;
				}
			}
		}
		showestimationbuttons = false;
		if(accessFn('WORKSHOP_FLOW')){
			if ([10,11,12,14,23].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
				if (accessFn('ADD_NORMAL_CHARGES')) {
					if(['Parts request created', 'Parts request accepted', 'Parts partially issued', 'Parts issued', 'Parts received', 'Work in progress', 'Service initiated', 'Claim estimation revision required', 'Supporting documents required'].indexOf(consumerServiceRequest.Status) > -1 && coveredUnderPlanCheck()) {
					// if(['Claim estimation generated','Parts request created', 'Parts request accepted', 'Parts partially issued', 'Parts issued', 'Parts received', 'Work in progress', 'Service initiated', 'BER identified'].indexOf(consumerServiceRequest.Status) > -1 && coveredUnderPlanCheck()) {
						if(consumerServiceRequest.allowEstimationAddition && issuesValidated){
							if (!isPaymentReceived) {
								showestimationbuttons = true;
							}
						} else {
							if(consumerServiceRequest.consumerProduct.DateOfPurchase && consumerServiceRequest.consumerProduct.ProductPurchaseCost && issuesValidated){
								if (!isPaymentReceived) {
									showestimationbuttons = true;
								}
							}
						}
						if (consumerServiceRequest.Status == 'Work in progress' && !isHandedToEng()) {
							showestimationbuttons = false;
						}
					}
				}
			} else if([39].indexOf(consumerServiceRequest.ServiceTypeID) > -1){
				if (['Estimation generated', 'Estimation rejected', 'Parts request accepted', 'Parts request created', 'Parts issued', 'Parts received', 'Parts rejected', 'Work in progress', 'Service in-progress', 'Advance collected'].indexOf(consumerServiceRequest.Status) > -1 && accessFn('ADD_NORMAL_CHARGES') && warrantyApplicableCheck()) {
					showestimationbuttons = true;
				}
				else{
					showestimationbuttons = false;
				}
			}else {
				if (['Estimation generated', 'Estimation rejected', 'Parts request accepted', 'Parts partially issued', 'Parts request created', 'Parts issued', 'Parts received', 'Parts rejected', 'Work in progress', 'Service in-progress', 'Advance collected'].indexOf(consumerServiceRequest.Status) > -1 && accessFn('ADD_NORMAL_CHARGES') && warrantyApplicableCheck()) {
					if (!consumerServiceRequest.InvoiceGeneratedDate && issuesValidated && (!accessFn('GSX_FLOW') || (consumerServiceRequest.issues.length > 0 || (addedFaultCodes && addedFaultCodes.length > 0)))) {
						showestimationbuttons = true;
					} else {
						showestimationbuttons = false;
					}
				} else {
					showestimationbuttons = false;
				}
			}
		}else {
			if ([10,11,12,14,23,52,64].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
				if (accessFn('ADD_NORMAL_CHARGES')) {
					if (consumerServiceRequest.allowAddPart && accessFn("ADD_PART_POST_APPROVAL")) {
						showestimationbuttons = true;
                    } 
					// if(['Device received', 'Claim estimation generated', 'BER identified', 'In-home request accepted', 'Pending for assignment', 'Issues validated', 'Serviceman reached', 'Service accepted'].indexOf(consumerServiceRequest.Status) > -1 && coveredUnderPlanCheck()) {
					if(['Device received', 'Claim estimation generated', 'Claim estimation revision required', 'In-home request accepted', 'Pending for assignment', 'Issues validated', 'Serviceman reached', 'Service accepted', 'Supporting documents required'].indexOf(consumerServiceRequest.Status) > -1 && coveredUnderPlanCheck()) {
						if(consumerServiceRequest.allowEstimationAddition){
							showestimationbuttons = true;
						} else {
							let SumInsured = getSumInsured();
							if((consumerServiceRequest.consumerProduct.DateOfPurchase && consumerServiceRequest.consumerProduct.ProductPurchaseCost) || SumInsured){
								showestimationbuttons = true;
							}
						}
						if(['Serviceman reached', 'Service accepted'].indexOf(consumerServiceRequest.Status) > -1 && consumerServiceRequest.EWRequest) {
							showestimationbuttons = false;
						}
					}
					let adminfees = 0;
					if(consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.DepreciatedValue && consumerServiceRequest.consumerProduct.DepreciatedValue.deviceDepreciatedValue) {
						if(consumerServiceRequest && consumerServiceRequest.charges) {
							for(var i = 0; i < consumerServiceRequest.charges.length; i++){
								if(consumerServiceRequest.charges[i].Type == 'Adminfees'){
										adminfees = consumerServiceRequest.charges[i].Charge;
										// if(consumerServiceRequest.consumerProduct.depreciatedValue.deviceDepreciatedValue <= (adminfees + consumerServiceRequest.consumerProduct.depreciatedValue.accessoriesCost)){
										// 	showestimationbuttons = false;
										// 	break;
										// }
								}
							}
						}
					}
				}
			}
			else {
				if (((consumerServiceRequest.Status == 'Service initiated') || (consumerServiceRequest.Status =='Repair initiated') || (consumerServiceRequest.Status == 'Estimation generated') || (consumerServiceRequest.Status == 'Estimation rejected')) && warrantyApplicableCheck()) {
					if (!consumerServiceRequest.InvoiceGeneratedDate) {
						showestimationbuttons = true;
					} else {
						showestimationbuttons = false;
					}
				} else {
					if (consumerServiceRequest.Status == 'Service invoice generated' && [3].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && isBoseFlow && !consumerServiceRequest.InvoiceGeneratedDate) {
						showestimationbuttons = true;
					}
					else {
						showestimationbuttons = false;
					}
				}
			}
			if(accessFn('READ_ONLY')) {
				showestimationbuttons = false;
			}
		}
		setShowEstimationButtons(showestimationbuttons);
	}

    const removeCharge = (index) => {
		let remove = [...selectedChargeTypeArray]
		remove.splice(index,1)
		setSelectedChargeTypeArray(remove)
		// showHideSaveButton(remove);
    }

	const removeAddedCharge = (charge) => {
		let url = '';
		if(accessFn("WORKSHOP_FLOW")) {
			if(accessFn("GSX_FLOW")) {
				url = 'removeParts';
			}else {
				url = 'updateChargeWorkshop';
			}
		}else {
			url = 'updateCharge';
		}
		let data = {
            updatedCharges: [charge],
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            ServiceTypeID: consumerServiceRequest.ServiceTypeID,
            PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID,
        };

		if(accessFn("GSX_FLOW")) {
			data.RepairTypeCode = consumerServiceRequest.gsxRepairFlags.repairTypeCode;
		}

        if(!accessFn('GSX_FLOW') && [10,11,12,16,23,38,52,64].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
            data.Submit = false;
        }
		setLoader(true);
		apiCall({
			url: url,
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    getRequestById();
					if(accessFn("WORKSHOP_FLOW")) {
						getPartsList && getPartsList();
						getPartsForRequest && getPartsForRequest();
						getRequestSFA && getRequestSFA();
					}
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const getCompModList = () => {
		setLoader(true);
		apiCall({
			url:'fetchCompTiaModifiers',
			data: {},
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let compModList = result.data.map(v => ({...v, value:v.comptiaDescription}))
					setComptiaModifierList(compModList)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    };

	const getpricingOptionList = () => {
		setLoader(true);
		apiCall({
			url:'getPricingOptions',
			data: {},
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let pricingOptionList = result.data.map(v => ({...v, value: v.pricingOptionDescription}))
					setPricingOptions(pricingOptionList)

				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }

	const getMailinCoverageList = () => {
		setLoader(true);
		apiCall({
			url:'getMailinCoverageOptions',
			data: {},
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let mailinCoverageOptionList = result.data.gsxCoverageOptions.map(v => ({...v, value: v.CoverageOption + ' - ' + v.CoverageDescription}))
					setCoverageOption(mailinCoverageOptionList)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }


	const getEditPartItemsBySerialNumber = (editPart) => {
		setEditPart({...editPart, isConsignment: true })

        let dataForConsignment = {
          part: editPart,
          PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID
        }
		setLoader(true);
        apiCall({
            url: 'getPartItemsBySerialNumber',
            method: 'post',
            data: dataForConsignment,
            callBack: (result) => {
				setLoader(false);
				if(result.success) {
					let data = result.data.map(v => ({...v, value:v.SerialNumber}))
					setConsigmentSerialNumberList(data)
				} else {
					Swal.fire( '',localString(displayError(result)), 'error' );
				}
            }
        });
    };

	const setEditProperties = (editPart) => {
		setEditPartObject((prev) => (
			{
				...prev,
				editSelectedComponentCodeName: editPart.ComponentCode + ' ' + editPart.ComponentDescription,
				editSelectedIssueCodeName: editPart.IssueCode + ' ' + editPart.IssueDescription,
				...(editPart.Serialized && {editSelectedPartSerialNumber: editPart.kbbSerialNumber}),
				...(editPart.kgbSerialNumber && editPart.isConsignment && {editSelectedConsignmentSerialNumber: editPart.kgbSerialNumber}),
				editSelectedReproducibilityName: editPart.comptiaModifier.comptiaModifierDescription,
				editSelectedPricingOptionDescription: editPart.pricingOption.pricingOptionDescription,
				editSelectedCoverageOptionDescription: editPart.coverageOption.partCoverageOptionCode + ' - '+ editPart.coverageOption.partCoverageOptionDescription,
				editSelectedPartWarranty: editPart.IsUnderWarranty ? 'In warranty' : 'Out of warranty',
			}
		))
		setEditSelectedIsPartAbused(editPart.isAbused ?  'Yes' : 'No')
		setEditSelectedIsReturnableDamage(editPart.isReturnableDamage ? "Yes" : 'No')
		setEditSelectedIsCoveredByACPlus(editPart.isCoveredByACPlus ? "Yes" : 'No')
		setEditSelectedConsignmentNeeded(editPart.isConsignment ? "Yes" : 'No')

		if(editPart.isConsignment == 1 && editPart.Serialized && consumerServiceRequest.gsxRepairFlags.repairTypeId == 1){
			setIsEditedConsignmentSerialNeeded(false)
		}
		else{
			setIsEditedConsignmentSerialNeeded(true)
		}

		if(editPart.COGoodQuantity > 0 && consumerServiceRequest.IsWarrantyApplicable){
			setIsEditedConsignmentNeeded(false)
		}
		else{
			setIsEditedConsignmentNeeded(true)
		}
   	}

   	const getPartWarranty = () => {
		let _typeOfWarranty = ["Out of warranty", "In warranty"];
		if(consumerServiceRequest && [10,11,12,23].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
			_typeOfWarranty = ["Out of warranty"];
		}
		setTypeOfWarranty(_typeOfWarranty)
  	}

	const initExistingPartIssueCode =  (editPart) => {
		let data = {
			reportedSymptomCode: editPart.ComponentCode,
			ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID
		}

		setLoader(true);
		apiCall({
			url: 'fetchReportedIssues',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					let editallIssues = result.data
					if(editallIssues && editallIssues.length > 0) {
						let data = editallIssues.map(v => ({...v, value:v.reportedIssueCode + ' ' + v.reportedIssueDesc}))
						setEditallIssuesList(data)
					}
				} else {
					Swal.fire(
						'',
						localString(displayError(result)),
						'error'
					);
				}
			}
		});
	}

	const getSymptomCodes = () => {
		let data = {
			ConsumerProductID: consumerServiceRequest.consumerProduct.ConsumerProductID,
		}
		setLoader(true);
		apiCall({
			url: 'fetchReportedSymptoms',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					let allSymptoms = result.data.map(v => ({...v, value: v.reportedSymptomCode + ' ' + v.reportedSymptomDesc}))
					setComponentCodeList(allSymptoms)
				}
				else{
					Swal.fire({
						title: '',
						text: localString(displayError(result)),
						type: 'error',
						confirmButtonText: localString('OK')
					})
				}
			}
		});
	}

	const editAddedCharge = (charge) => {
		setEditedPart(charge)

		let data = {
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			partID: charge.TypeID
		}
		setLoader(true);
		apiCall({
			url: 'getPartsForRequestGSX',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if(result.success) {
					let editPartCopy = result.data[0]
					setEditPart(editPartCopy)
					setEditProperties(editPartCopy);

					if(editPartCopy.isConsignment && editPartCopy.kgbSerialNumber){
						getEditPartItemsBySerialNumber(editPartCopy);
					}

					if((consumerServiceRequest.consumerProduct && !consumerServiceRequest.consumerProduct.IsUnderWarranty) || (consumerServiceRequest && !consumerServiceRequest.IsWarrantyApplicable)){
						getPartWarranty();
					}

					initExistingPartIssueCode(editPartCopy);
					getCompModList();
					getpricingOptionList();
					getSymptomCodes();
					getMailinCoverageList();

					setIsEditPartModal(true)
					setShowPartModal(true);
				} else {
					Swal.fire( '', localString(displayError(result)), 'error' );
				}
			}
		});
	}

    const processRequest = () => {
		if(accessFn("WORKSHOP_FLOW")){
			Swal.fire({
				title: localString("Are you sure?")+'\n'+localString("Please specify remarks, if any..."),
				html: '<textarea id="request-remark" class="cancel-remark" style="width: 85%;"></textarea>',
				showCancelButton: true,
				closeOnConfirm: false,
				allowEscapeKey: false,
				allowOutsideClick: false,
				confirmButtonText: localString('OK'),
				cancelButtonText: localString("Cancel")
			}).then((result)=>{
				if(result.isConfirmed){
					let remark = document.getElementById("request-remark").value ? document.getElementById("request-remark").value: '';
					Swal.close();
					let data = {
						ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
						Status: 'Estimation approval',
						Remarks: remark
					};
					setLoader(true);
					apiCall({
						url:'servicerequestFulfilmentWorkshop',
						data: data,
						callBack:(result)=>{
							setLoader(false);
							if(result.success){
								getRequestById();
								setActiveTab({showOverrideApproval:true});
							}else{
								Swal.fire({
									icon: 'error',
									text: localString(displayError(result)),
								});
							}
						}
					});
				}
			})
		}else{
			Swal.fire({
				title: localString('Are you sure'),
				html: '',
				showCancelButton: true,
				closeOnConfirm: false,
				allowEscapeKey: false,
				allowOutsideClick: false
			}).then(result=>{
				if(result.isConfirmed){
					Swal.close();
					let data = {
						ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
						Status: 'Estimation approval'
					};
					setLoader(true);
					apiCall({
						url:'servicerequestFulfilment',
						data: data,
						callBack:(result)=>{
							setLoader(false);
							if(result.success){
								getRequestById();

								/* *********** Close Part Estimation and Open Override Approval Tab*********** */
								// setActiveTab({...activeTab,showPartEstimation:false})
								// setActiveTab({...activeTab,showOverrideApproval:true})
								// showPartEstimation = false;
								// showOverrideApproval = true;
							}else{
								Swal.fire({
									icon: 'error',
									text: localString(displayError(result)),
								});
							}
						}
					});
				}
			})
		}
    };

	/* ********** 'Estimation Revision Required' Button ********** */
	const processClaimRequest = () => {
		let selectedActionStatus = 'Claim estimation revision required';

		Swal.fire({
			title: localString("Are you sure?")+'\n'+localString("Please mention the reason for Estimate Revision."),
            html: '<textarea id="estimation-remark" class="cancel-remark"></textarea>',
			showCancelButton: true,
			closeOnConfirm: false,
			allowEscapeKey: false,
			allowOutsideClick: false,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("OK")
		}).then(result=>{
			if(result.isConfirmed){
                let remark = document.getElementById("estimation-remark").value ? document.getElementById("estimation-remark").value: ''; 
				if (remark != '') {
					Swal.close();
					let data = {};
					data.ConsumerServiceRequestID = consumerServiceRequest.ConsumerServiceRequestID;
					data.Status = selectedActionStatus;
					data.ServiceTypeID = consumerServiceRequest.ServiceTypeID;
					data.CountryID = consumerServiceRequest.CountryID ? consumerServiceRequest.CountryID : undefined;
					data.Remarks = remark;
					setLoader(true);
					apiCall({
						url: 'claimRequest',
						data: data,
						callBack:(result)=>{
							setLoader(false);
							if(result.success) {
								getRequestById();
							}else{
								Swal.fire({
									icon: 'error',
									text: localString(displayError(result)),
								});
							}
						}
					});
				} else {
					Swal.fire({
						icon:"error",
						title:localString('Please enter reason for revision')
					})
				}
			}
		})
    };

	const generateEstimate = () => {
		let data = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            ConsumerProductID: consumerServiceRequest.ConsumerProductID
        };
		setLoader(true);
		apiCall({
			url: 'generateEstimateForInternalJob',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if(result.success){
					getRequestById();
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}
	
	const closePartModal = () =>{
		setShowPartModal(false);
		setIsEditPartModal(false);
	}

    const chargeArrayValue = (index,key,value) =>{
		let arr = clone(selectedChargeTypeArray);
		arr[index][key] = value;
		setSelectedChargeTypeArray(arr);
	}

	const addDiscount = (e, index) => {
		let discountVal = e.target.value;
		let totalPayable = parseFloat(consumerServiceRequest.ConsumerAmount);
		let advance = 0;
		for (let i = 0; i < charges.length; i++) {
            if (['Advance', 'Advance payment'].indexOf(charges[i].Type) > -1 && (charges[i].IsAdvancePaid != 1)) {
                advance = advance + charges[i].TotalAmount;
            }
            if (['ServiceCharge', 'Part', 'Other'].indexOf(charges[i].Type) > -1 && charges[i].BaseCharge != charges[i].WarrantyConcession && charges[i].BaseCharge > 0) {
                // if (i != index && (charges[i].Discount || charges[i].Discount == 0)) {
				if (charges[i].Discount) {
                    totalPayable = totalPayable - parseFloat(charges[i].Discount);
                }
            }
		}
		totalPayable = totalPayable - advance;
		let chargeValue = charges[index].MRP;

		let arr = cloneDeep(charges);

		if((discountVal?.trim() == '') || isNaN(discountVal)) {
			arr[index].Discount = 0;
			arr[index].finalCharge = arr[index].MRP;
		}

		if(Number(discountVal) <= chargeValue) {
			arr[index].Discount = discountVal;
			arr[index].finalCharge = (chargeValue - discountVal)//.toFixed(2);
		}
		setCharges(arr);
	}

	const updateChargesCall = (data, closeDiscountModalFlag) => {
		if(!accessFn('GSX_FLOW') && [10,11,12,16,23,38,52,64].indexOf(consumerServiceRequest.ServiceTypeID) > -1) {
            data.Submit = false;
        }
		setLoader(true);
		apiCall({
			url: 'updateChargeWorkshop',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if(result.success){
					closeDiscountModalFlag && closeDiscountModal()
					getRequestById && getRequestById();
                	getPartsList && getPartsList();
                	getPartsForRequest && getPartsForRequest();
					// TODO
					// if (self.isDiscountUpdate) {
					// 	self.isDiscountUpdate = false
					// } else {
					// 	  swal ({
					// 		  title: '',
					// 		  text:localString('Part(s) request under review by storekeeper.'),
					// 		  type: 'success',
					// 		  confirmButtonText: localString('OK')
					// 	  });
					// }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const updateDiscount = () => {
		let updatedCharges = [];
		charges && charges.length && charges.map((charge) => {
			let obj = {...charge};
			obj.Discount = parseFloat(charge?.Discount);
			obj.update = true;
			delete obj.finalCharge;
			updatedCharges.push(obj);
		})
		let data = {
			"ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"ServiceTypeID": consumerServiceRequest.ServiceTypeID,
			"PartnerServiceLocationID": consumerServiceRequest.PartnerServiceLocationID,
			"updatedCharges": updatedCharges,
		}
		updateChargesCall(data, true);
	}

	const closeDiscountModal = () => {
		setShowDiscountModal(false);
		setCharges(consumerServiceRequest?.charges || []);
	}
	
	const getPartConfig = () => {
		let data = {
			"countryID": consumerServiceRequest.CountryID ? consumerServiceRequest.CountryID : undefined,
			"brandID": consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.BrandID : null,
			"planID": planID,
			"partnerServiceLocationID": consumerServiceRequest.PartnerServiceLocationID,
			"partnerID": consumerServiceRequest && consumerServiceRequest.PartnerID,
		}
		setLoader(true);
		apiCall({
			url: 'getPartItemConfig',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					setPartConfig(result.data)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}
	const getPartCategory = ()=>{
        let data = {
            productSubCategoryID:consumerServiceRequest.consumerProduct.ProductSubCategoryID
        }
		setLoader(true);
		apiCall({
			url: 'getPartCategory',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let data = result.data.map(v => ({...v, value:v.partCategoryName}))
					setPartCategoryDropDown(data)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
    }
 

    useEffect(() => {
		setEstimationButtons();
		if (consumerServiceRequest) {
			// this.updateChargeType();
			if (['US'].indexOf(consumerServiceRequest && consumerServiceRequest.CountryCode) < 0) {
				setTaxSlabRequired(true)
			}
			getPartConfig();
		}
	}, [consumerServiceRequest])

	useEffect(() => {
		setCharges(consumerServiceRequest.charges);
	}, [consumerServiceRequest.charges])

    return (
        <>
            {
				(loader) && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper
						bodyClassName="panel-body-no-padding" 
						title={titleHeader}
					>
                        <div className='estimationContent padding15 container-fluid'>
                            {/* ****** Covered Value ****** */}
                            { depreciatedValue && depreciatedValue.sumInsured ? <div className='boxShadowCard mb-15'>
                                {/* <div className='row pb-15'> */}
                                <div className='row'>
                                    <div className='col-xs-8 bold'>
                                        {localString('Covered Value')}
                                    </div>
                                    <div className='col-xs-4 bold text-right'>
                                        <span><span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>{depreciatedValue.sumInsured}</span>
                                    </div>
                                </div>
                                {/* ****** TODO: Approved Claim Amount ****** */}
                                {/* <div className='row'>
                                    <div className='col-xs-8'>
                                        {localString('Approved Claim Amount')}
                                    </div>
                                    <div className='col-xs-4 text-right'>
                                        <span><span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span> 6000</span>
                                    </div>
                                </div> */}
                            </div> : ''}

                            {/* ****** Charges ****** */}
                            <div className='boxShadowCard mb-15'>
                                <div className='row paddingB10'>
                                    <div className={`col-xs-8 bold ${(consumerServiceRequest?.TotalBilledAmount > depreciatedValue?.sumInsured && consumerServiceRequest.Status == 'Claim estimation generated') ? 'red' : ''}`}>
                                        {localString('Total Amount')}
                                    </div>
                                    <div className={`col-xs-4 bold text-right ${(consumerServiceRequest?.TotalBilledAmount > depreciatedValue?.sumInsured && consumerServiceRequest.Status == 'Claim estimation generated') ? 'red' : ''}`}>
                                        <span><span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>{consumerServiceRequest.TotalBilledAmount}</span>
                                    </div>
                                </div>
                                <ShowCharges
                                    {...props}
                                    localString={localString}
                                    editAddedCharge={editAddedCharge}
                                    removeAddedCharge={removeAddedCharge}
                                    consumerServiceRequest={consumerServiceRequest}
                                    // showsavebutton={showsavebutton}
                                    showEstimationButtons={showEstimationButtons}
                                />
                                {/* <div className='row'>
                                    <div className='col-xs-8'>
                                        {localString('Approved Claim Amount')}
                                    </div>
                                    <div className='col-xs-4 text-right'>
                                        <span><span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span> 6000</span>
                                    </div>
                                </div> */}
								{
									// showEstimationButtons && selectedChargeTypeArray.length > 0 && 
									showEstimationButtons && 
										<AddChargesInputArrays
											{...props}
											submit={submit}
											localString={localString}
											removeCharge={removeCharge}
											isWorkshopFlow={isWorkshopFlow}
											taxSlabRequired={taxSlabRequired}
											chargeArrayValue={chargeArrayValue}
											showEstimationButtons={showEstimationButtons}
											saveFunc={isWorkshopFlow ? saveWorkshopFlow : saveNonWorkshopFlow} 
											selectedChargeTypeArray={selectedChargeTypeArray} setSelectedChargeTypeArray={setSelectedChargeTypeArray}
											partConfig={partConfig}
											partCategoryDropdown={partCategoryDropdown}
										/>
								}
                            </div>

                            {/* ****** Total Payable ****** */}
                            <div className='boxShadowCard mb-15'>
                                <div className='row'>
                                    <div className='col-xs-8 bold'>
                                        {localString('Total Payable')}
                                    </div>
                                    <div className='col-xs-4 bold text-right'>
                                        <span><span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>{consumerServiceRequest.ConsumerAmount}</span>
                                    </div>
                                </div>
                            </div>

                            {/* {
                                showEstimationButtons && selectedChargeTypeArray.length > 0  && <div className='row'>
                                    <AddChargesInputArrays
                                        {...props}
                                        localString={localString}
                                        selectedChargeTypeArray={selectedChargeTypeArray} setSelectedChargeTypeArray={setSelectedChargeTypeArray}
                                        saveFunc={saveFunc} 
										removeCharge={removeCharge}
                                        chargeArrayValue={chargeArrayValue}
                                    />
                                </div>
                            } */}
                            {
                                showEstimationButtons && ((isWorkshopFlow && workshopChargeType.length > 0) || (!isWorkshopFlow && chargeType.length > 0)) && 
									<EstimationButtons
										{...props}
										chargeType={isWorkshopFlow ? workshopChargeType : chargeType}
										isBoseFlow={isBoseFlow}
										localString={localString}
										isWorkshopFlow={isWorkshopFlow}
										exceptionRaised={exceptionRaised}
										exceptionRejected={exceptionRejected}
										selectedChargeTypeArray={selectedChargeTypeArray}
									/>
                            }
							{/* TODO: <button class="charges-button btn" type="button" style="float: right;" data-type="submit" onclick={submit} if={(accessFn('ALLOW_ADDING_DISCOUNT') && csrid.allowDiscount && ['Claim estimation generated'].indexOf(csrid.Status) > -1) || ((([10,11,12,16,23,38,52,64].indexOf(csrid.ServiceTypeID) > -1 && ['Claim estimation generated', 'Supporting documents required'].indexOf(csrid.Status) == -1) || ([3].indexOf(csrid.ServiceTypeID) > -1 && ['Service invoice generated'].indexOf(csrid.Status) > -1 && isBoseFlow)) && showestimationbuttons)} >{localString('Submit')} </button> */}
							{ isWorkshopFlow && showEstimationButtons && consumerServiceRequest?.charges?.length > 0 && (consumerServiceRequest.Status == "Parts request created") && (consumerServiceRequest.ServiceTypeID == SERVICETYPE.INTERNAL_JOB) && <>
								<div className="request-panel-padding" style={{padding:"0px 15px 0px 15px"}}>
									<div className="content-footer" style={{padding:"20px 0px"}}>
										<Button 
											type="button" 
											name="EstimationApproval"
											handleOnClick={generateEstimate}  
											// classes='button-ghost' 
											className="btn squareBtnGhost" 
										>
											{localString('Generate Estimate')}
										</Button>
									</div>
								</div>
							</>}
							{showEstimationButtons && ([10,11,12,16,23,38,52,64].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && (['Claim estimation generated', 'Supporting documents required'].indexOf(consumerServiceRequest.Status) > -1) && accessFn('CLAIM_APPROVAL') && <>
								<div className="request-panel-padding" style={{padding:"0px 15px 0px 15px"}}>
									<div className="content-footer" style={{padding:"20px 0px"}}>
										<Button 
											type="button" 
											name="EstimationApproval"
											handleOnClick={processClaimRequest}  
											// classes='button-ghost' 
											className="btn squareBtnGhost" 
										>
											{localString('Estimation Revision Required')}
										</Button>
									</div>
								</div>
							</>}
                            {
                                isWorkshopFlow && !accessFn('READ_ONLY') && ([10,11,12,23].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && 
                                    (accessFn('REASSIGN_JOBS') || accessFn('ACCEPT_RETURN_DEVICE') || accessFn('SEND_FOR_APPROVAL')) && 
                                    consumerServiceRequest.Status == 'Estimation generated' && consumerServiceRequest.ConsumerAmount >= 0 && 
                                    (!consumerServiceRequest.OriginServiceLocationID || (qFilter && qFilter?.serviceLocations?.indexOf(consumerServiceRequest.OriginServiceLocationID) > -1) ||
                                    (consumerServiceRequest.deviceTransferData && consumerServiceRequest.deviceTransferData.isTransferredRequest && consumerServiceRequest.deviceTransferData.showOverrideApprovalToOriginUser)) &&
									<div className="request-panel-padding" style={{padding:"0px 15px 0px 15px"}}>
										<div className="content-footer" style={{padding:"20px 0px"}}>
											<Button 
												type="button" 
												name="EstimationApproval"
												handleOnClick={processRequest}  
												// classes='button-ghost' 
												className="btn squareBtnGhost" 
											>
												{localString('Send For Approval')}
											</Button>
										</div>
									</div>
						    }
							{
                                !isWorkshopFlow && ([10,11,12,16,23,52].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && consumerServiceRequest.Status == 'Estimation generated' &&
									<div className="request-panel-padding" style={{padding:"0px 15px 0px 15px"}}>
										<div className="content-footer" style={{padding:"20px 0px"}}>
											<Button 
												type="button" 
												name="EstimationApproval"
												handleOnClick={processRequest}  
												// classes='button-ghost' 
												className="btn squareBtnGhost" 
											>
												{localString('Send For Approval')}
											</Button>
										</div>
									</div>
						    }
							{
								showPartModal &&
								<AddPartModal
									{...props}
									editPart={editPart}
									partsList={partsList}
									editedPart={editedPart}
									setEditPart={setEditPart}
									showPartModal={showPartModal}
									closePartModal={closePartModal}
									pricingOptions={pricingOptions}
									typeOfWarranty={typeOfWarranty}
									coverageOption={coverageOption}
									editPartObject={editPartObject}
									isEditPartModal={isEditPartModal}
									getPartWarranty={getPartWarranty}
									setShowPartModal={setShowPartModal}
									setCoverageOption={setCoverageOption}
									updateChargesCall={updateChargesCall}
									setPricingOptions={setPricingOptions}
									editallIssuesList={editallIssuesList}
									componentCodeList={componentCodeList}
									setEditPartObject={setEditPartObject}
									setIsEditPartModal={setIsEditPartModal}
									comptiaModifierList={comptiaModifierList}
									setComponentCodeList={setComponentCodeList}
									setEditallIssuesList={setEditallIssuesList}
									setComptiaModifierList={setComptiaModifierList}
									editSelectedIsPartAbused={editSelectedIsPartAbused}
									isEditedConsignmentNeeded={isEditedConsignmentNeeded}
									consigmentSerialNumberList={consigmentSerialNumberList}
									setEditSelectedIsPartAbused={setEditSelectedIsPartAbused}
									setIsEditedConsignmentNeeded={setIsEditedConsignmentNeeded}
									setConsigmentSerialNumberList={setConsigmentSerialNumberList}
									editSelectedIsCoveredByACPlus={editSelectedIsCoveredByACPlus}
									editSelectedConsignmentNeeded={editSelectedConsignmentNeeded}
									editSelectedIsReturnableDamage={editSelectedIsReturnableDamage}
									isEditedConsignmentSerialNeeded={isEditedConsignmentSerialNeeded}
									setEditSelectedIsCoveredByACPlus={setEditSelectedIsCoveredByACPlus}
									setEditSelectedConsignmentNeeded={setEditSelectedConsignmentNeeded}
									setEditSelectedIsReturnableDamage={setEditSelectedIsReturnableDamage}
									setIsEditedConsignmentSerialNeeded={setIsEditedConsignmentSerialNeeded}
								/>
							}
							{
								showDiscountModal &&
								<DiscountModal
									{...props}
									addDiscount={addDiscount}
									showModal={showDiscountModal}
									currencySymbol={currencySymbol}
									updateDiscount={updateDiscount}
									discountDisabled={discountDisabled}
									charges={charges} setCharges={setCharges}
									closeModal={closeDiscountModal}
								/>
							}
                        </div>
                    </PageContentWrapper>
                </div>
            </div>
        </>
    )
}