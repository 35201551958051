import React from 'react';
import {Radio} from 'sfy-react';
import Swal from "sweetalert2";
const HandoverModalInput=(props)=>{
	const {localString,deviceReturnedSave,DeviceReturnTo} = props;
		return (
		<div className=''>
			<div className="row">
				<div className=''> 
					{/* <form onSubmit={(e) => deviceReturnedSave(e) }> */}
						<div className="">
							<div className="col-sm-12 handover-marginL" >
								{
									DeviceReturnTo && DeviceReturnTo.map(device=>(
										device.ShowTemporary && 
										<> 
											<input type="radio" name="deviceHandoverStatus" id={device.msgId + '_deviceHandoverStatus'} />
											<label className='radio-label' for={device.msgId + '_deviceHandoverStatus'} >{device.msg}</label>
										</>
									))
								}
							</div>
						</div>
						{/* <div className='handover-marginT'>
							<button type="submit" name="submit" className="btn button-servify pull-right" onClick={(e)=>deviceReturnedSave(e)}>{localString("Save")}</button>
						</div> */}
					{/* </form> */}
				</div>
			</div>
		</div>
	)
}
export default HandoverModalInput;

