import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import bulkUploadReducer from "./features/bulkUpload/bulkUploadSlice";
import planSalesReducer from "./features/planSales/planSalesSlice";
import planSalesDashboardReducer from "./features/planSales/planSalesDashboardSlice";

const persistConfig = {
	key: "root",
	storage,
	// blacklist: ['PlanSales'], // navigation will not be persisted
};

const rootReducer = combineReducers({
	// BulkUpload: bulkUploadReducer,
	// PlanSales: planSalesReducer,
	PlanSalesDashboard: planSalesDashboardReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
});

export const persistor = persistStore(store);
