import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Loader, Modal, Checkbox, Button, MultiInput } from 'sfy-react';
import Swal from 'sweetalert2';
import IntlTelInputWrapper from '../../../components/IntlTelInputWrapper/IntlTelInputWrapper';
import { displayError, getMobileMaxLength } from '../../../Utility/Utils';
import './PlanSalesConsent.scss'

const PlanSalesConsent = (props) => {
    const {apiCall, showConsentModal, closeModal, localString, dataAbroadConsent, paymentDeclarationConsent, customerDetails, 
        selectedCountryCode, getDeclarationText, onOtpSuccess} = props;
    const OTPBOXES = 6;
    const qFilter = JSON.parse(props.qFilter);
    const [showLoader, setLoader] = useState(false);
    const [usingPrevDetails, setUsingPrevDetails] = useState(false);
	const [otpValidationModal, setOtpValidationModal] = useState(false);
    const [mobileMaxLength, setMobileMaxLength] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const [consent, setConsent] = useState(false);
    const [showResendOtpButton, setShowResendOtpButton] = useState(false);
    const [communicationModes, setCommunicationModes] = useState([])
    const [defaultCountryCode, setDefaultCountryCode] = useState(qFilter?.countrycode && qFilter?.countrycode?.length > 0 && qFilter?.countrycode[0]?.toUpperCase());
    const [selectedCommunicationMode, setSelectedCommunicationMode] = useState()
    const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(30);
	const [otp, setOtp] = useState("");
    const [sendOtpEnabled, setSendOtpEnabled] = useState(true);
    const [confirmOtpEnabled, setConfirmOtpEnabled] = useState(false);
    const [userDetails, setUserDetails] = useState({
		MobileNumber: "",
		countryCode: defaultCountryCode,
	})
    const [tncOtpModal, setTncOtpModal] = useState(props.tncOtpModal ? props.tncOtpModal : undefined );
    const [paymentType, setPaymentType] = useState(props.paymentType ? props.paymentType : undefined );    

    useEffect(() => {
		if(userDetails.MobileNumber.length <= 0 || mobileMaxLength.includes(userDetails.MobileNumber.length)){
			setErrorMessage(false)
		}else{
			setErrorMessage(true)
		}

        if((userDetails.MobileNumber.length != 0 && mobileMaxLength.includes(userDetails.MobileNumber.length))){
            (consent === true) ? setSendOtpEnabled(false) : setSendOtpEnabled(true);
        }else { 
            setSendOtpEnabled(true);
        }
	}, [userDetails.MobileNumber, mobileMaxLength, consent])

    useEffect(() => {
        (otp?.length === OTPBOXES) ? setConfirmOtpEnabled(true) : setConfirmOtpEnabled(false) ;
    }, [otp])
    

    useEffect(() => {
        setUserDetails({...userDetails, countryCode: defaultCountryCode})
        getCountries();
    }, [defaultCountryCode])
    
    useEffect(() => {
		if(otpValidationModal){
			const interval = setInterval(() => {
				if(minutes > 0) {
					if(seconds > 0) {
						setSeconds(seconds - 1);
					}else {
						setSeconds(30);
						setMinutes(minutes - 1);
					}
				}else {
					if(seconds > 0) {
						setSeconds(seconds - 1);
					}
				}
			}, 1000);
			if((seconds == 0) && (minutes == 0)) {
				clearInterval(interval);
				setShowResendOtpButton(true)
			}
			return () => {
				clearInterval(interval);
			}
		}
    }, [seconds, otpValidationModal]);

    useEffect(() => {
        if(tncOtpModal,paymentType){
            openOtpModal(tncOtpModal,paymentType)
        }else {
            openOtpModal()
        }
    }, [])

    const openOtpModal = (tncOtpModal, paymentType) => {
        let CommunicationModes = paymentDeclarationConsent?.legalConfig && paymentDeclarationConsent?.legalConfig?.CommunicationModes;
        if (CommunicationModes) {
            if(customerDetails && (customerDetails.MobileNo || customerDetails.EmailID)) {
                setUsingPrevDetails(true);
                requestOTP();
            }
            else {
                setUsingPrevDetails(false);
            }
        } else {
            setUsingPrevDetails(false);
        }
    };

    const resendRequestOTP = () => {
        setOtp("");
        setSeconds(30);
        requestOTP('SMS', false);
    };

    const resendEmailOTP = () => {
        setOtp("");
        setSeconds(30);
        requestOTP('Email', false);
    };

    const callOTP = () => {
        setOtp("");
        setSeconds(30);
        requestOTP('SMS', true);
    };

    const getCountries = (country) => {
		let data = {
			specificCountryCode: country ? country.toUpperCase() : defaultCountryCode,
			countryConfig: true
		}
		setLoader(true);
		apiCall({
			url:'getCountries',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let countryData = result.data.countries[0];
					let maxLength = getMobileMaxLength(countryData);
					setMobileMaxLength(maxLength);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

    const requestOTP = (selectedMode = false, voiceOtp = false) => {
		setOtp("");
		let data = {};
		if (usingPrevDetails == true) {
			data.MobileNo = communicationModes.indexOf('SMS') > -1 ? customerDetails.MobileNo : undefined;
			data.EmailID = communicationModes.indexOf('Email') > -1 ? customerDetails.EmailID : undefined;
			if ((communicationModes.indexOf('SMS') > -1 && !data.MobileNo) || (communicationModes.indexOf('Email') > -1 && !data.EmailID)) {
				return;
				//required details not found
			}
		} else {
			data.MobileNo = userDetails.MobileNumber;
		}
		data.isConsent = true;
		data.CountryCode = qFilter?.countrycode ? qFilter?.countrycode[0] : undefined;
		data.salesChannel = qFilter?.salesLocation ? qFilter?.salesLocation : "WebApp";
		data.CommunicationModes = communicationModes;
		data.Name = customerDetails ? customerDetails.FirstName : undefined;
		data.PhoneCode = selectedCountryCode && selectedCountryCode.dialCode && parseInt(selectedCountryCode.dialCode);

		if (selectedMode) {
			setSelectedCommunicationMode(selectedMode)
			data.CommunicationModes = [selectedMode];
			if(selectedMode == 'SMS') {
				delete data.EmailID;
			}
			else if (selectedMode == 'Email') {
				delete data.MobileNo;
			}
		}
		if (voiceOtp) {
			data.voiceOtp = voiceOtp
		}
		setLoader(true);
		apiCall({
			url:'requestOTP',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					setOtpValidationModal(true);
					setShowResendOtpButton(false);
					setSeconds(30);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	};

    const verifyOTP = () => {
		let data = {
			"MobileNumber": communicationModes && communicationModes.indexOf('SMS') > -1 ? "customerDetails.MobileNo" : userDetails.MobileNumber,  
			"EmailID": communicationModes && communicationModes.indexOf('Email') > -1 ? "customerDetails.EmailID" : undefined,
			"Otp": otp,
			"ConsentArray": [{
				"MobileNo": communicationModes && communicationModes.indexOf('SMS') > -1 ? "customerDetails.MobileNo" : userDetails.MobileNumber,
				"EmailID": communicationModes && communicationModes.indexOf('Email') > -1 ? "customerDetails.EmailID" : undefined,
				"Attributes": [{
					"AttributeType": "OTP",
					"AttributeValue": otp
				}]
			}],
			"CountryCode" : qFilter.countrycode ? qFilter.countrycode[0]: undefined,
			"salesChannel": qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp",
		};
		data.CommunicationModes = selectedCommunicationMode ? [selectedCommunicationMode] : communicationModes;
		if (data.CommunicationModes.length == 1) {
			if (selectedCommunicationMode == 'SMS') {
				delete data.EmailID;
				delete data.ConsentArray[0].EmailID
			}
			else if (selectedCommunicationMode == 'Email') {
				delete data.MobileNumber;
				delete data.ConsentArray[0].MobileNo
			}
		}

        if(selectedCountryCode?.dialCode){
            data.PhoneCode = (selectedCountryCode?.dialCode && parseInt(selectedCountryCode?.dialCode));
        }

		if(tncOtpModal) {
			data.ConsentArray[0].ApplicationLegalConfigID = paymentDeclarationConsent && paymentDeclarationConsent.ApplicationLegalConfigID;
			data.ConsentArray[0].ConsentText = getDeclarationText();		
		} else {
			data.ConsentArray[0].ApplicationLegalConfigID = dataAbroadConsent.ApplicationLegalConfigID;
			data.ConsentArray[0].ConsentText = dataAbroadConsent.legalConfig.Text;
		}
		setLoader(true);
		apiCall({
			url:'verifyOTP',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					onOtpSuccess();
					closeModal();
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	};

    return (
        <>
            {showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
            {
                showConsentModal && 
                <div id="ConfirmOTPModal" className="consent-block" >
                    <Modal showModal={showConsentModal} closeModal={closeModal} showCloseBtn={true}>
						<Modal.Header>
							<h4 className="modal-title title-spacing" id="myModalLabel"><strong>{usingPrevDetails ? localString("Enter Verification Code") : otpValidationModal ? localString("OTP Validation") : localString("Contact Information")}</strong></h4>
						</Modal.Header>
                        <Modal.Body>
                            {
								!otpValidationModal && 
                                <div class="mobile-info-modal">
                                    <div class=" form-group-filter-request">
                                        <IntlTelInputWrapper 
                                            value={userDetails.MobileNumber}
                                            key="MobileNo"
                                            handleOnChange={(val, countryData) => {
                                                setUserDetails({...userDetails,
                                                    MobileNumber: val,
                                                    countryCode: countryData.iso2
                                                })
                                            }}
                                            label = {localString("Mobile Number")}
                                            intlTelInputClassWrapper = {{
                                                formControl: 'form-control--top ',
                                                label: 'input__label',
                                                containerClass: 'input-container',
                                                inputClass: 'input'
                                            }}
                                            onFlagSelect = {(countryData) => {
                                                setUserDetails({...userDetails,
                                                    countryCode: countryData.iso2
                                                })
                                                getCountries(countryData.iso2)
                                            }}
                                            defaultCountry= {userDetails.countryCode ? userDetails.countryCode.toLowerCase() : defaultCountryCode.toLowerCase()}
                                            maxLength={mobileMaxLength}
                                        />
                                        {
                                            errorMessage 
                                            ? <div class="help-block error-color">{localString('Please enter correct Mobile No')}</div>
                                            : <div class="help-block error-color">&nbsp;</div>
                                        }
                                    </div>
                                    {
                                        // !tncOtpModal && 
                                        dataAbroadConsent?.legalConfig && dataAbroadConsent?.legalConfig?.Text && 
                                        <div class=" form-group-filter-request">
                                            <div class="disclaimer"><strong>{localString("Disclaimer")}</strong></div>
                                                <Checkbox
                                                    value={consent}
                                                    name='Disclaimer'
                                                    handleOnChange={(e) => {
                                                        setConsent(!consent);
                                                    }}
                                                ><p class="inline-text">{localString(dataAbroadConsent.legalConfig.Text)}</p> 
                                                </Checkbox>
                                        </div>
                                    }
                                    <div class=" form-group-filter-request">
                                        <Button 
                                            className= "btn button-servify btn button-servify-solid send-otp-btn" 
                                            type= 'submit' 
                                            text= {localString('Send Verification code')} 
                                            handleOnClick= {(e)=>{
                                                e.preventDefault(); 
                                                requestOTP()
                                            }}
                                            isDisabled= {sendOtpEnabled}
                                        />
                                    </div>
                                </div>
							}
                            {
								otpValidationModal && 
								<div class="otp-modal">
									<div class="row form-group-filter-request">
                                        {
                                            !usingPrevDetails && 
                                            <div class="form-group back-link" onclick={"backToOtpModal"}>
                                                {"< "+localString('Back')}
                                            </div>
                                        }
										{
											!usingPrevDetails && 
											<p>{localString('Code sent to customer on')} {userDetails.MobileNumber}</p>
										}
										{
											usingPrevDetails && communicationModes &&
											<p> 
                                                {localString('Code sent to customer on')} 
                                                {communicationModes.indexOf('Email') > -1 ? customerDetails.EmailID + (communicationModes.length > 1 ? ' ' + localString('and') + ' ' : '') : ''}
                                                {communicationModes.indexOf('SMS') > -1 ? '+' + selectedCountryCode.dialCode + ' ' + customerDetails.MobileNo : ''}
                                            </p>
										}
										<div>
											<MultiInput
                                                id = "consent-otp-input"
												length = {OTPBOXES}
												handleOnChange = {(val) => {
                                                    setOtp(val);
                                                }}
												classNames = {{
													input: "otp-input",
												}}
											/>
										</div>
									</div>
									<div class="row form-group-filter-request">
										{
											!showResendOtpButton && 
											<p className=''>{localString("Didn't receive the code? Resend Code in")} {"00:"+seconds}</p>
										}
										{
											showResendOtpButton && (!usingPrevDetails || (usingPrevDetails && communicationModes && communicationModes.indexOf('SMS') > -1)) && 
											<Button 
												className= "btn button-servify" 
												type= 'submit' 
												text= {localString("Resend SMS")} 
												handleOnClick= {(e)=>{e.preventDefault(); resendRequestOTP()}}
											/>
										}
										{
											showResendOtpButton && communicationModes && communicationModes.indexOf('Email') > -1 && 
											<Button 
												className = "btn button-servify" 
												type = 'submit' 
												text = {localString("Resend Email")} 
												handleOnClick = {(e)=>{e.preventDefault(); resendEmailOTP()}}
											/>
										}
										{
											showResendOtpButton && communicationModes && communicationModes.indexOf('SMS') > -1 && 
											<Button 
												className= "btn button-servify" 
												type= 'submit' 
												text= {localString("Call me with the Code")} 
												handleOnClick= {(e)=>{e.preventDefault(); callOTP()}}
											/>
										} 
									</div>
									<div class="row form-group-filter-request">
										<Button 
                                            className= "btn button-servify-solid send-otp-btn" 
                                            type= 'submit' 
                                            text= {localString("Confirm Code")} 
                                            handleOnClick= {(e)=>{
                                                e.preventDefault(); 
                                                verifyOTP();
                                            }}
                                            isDisabled={!confirmOtpEnabled}
                                        />
									</div>
								</div>
							}
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    )
}

export default PlanSalesConsent