import React, { useRef, useEffect } from "react";
import "./Snackbar.scss";

const Snackbar = ({
	type,
	message,
	callback,
	reference,
	imagePath = "",
	publicUrl,
}) => {
	const snackbarFunction = useRef(null);

	useEffect(() => {
		window.scrollTo(0, 0);
		snackbarFunction.current = setTimeout(() => {
			callback();
		}, 3000);
		return () => {
			clearTimeout(snackbarFunction.current);
		};
	}, [callback]);

	return (
		<div id="snackbar" className={type} ref={reference}>
			<img
				className="snackbar--icon"
				src={`${publicUrl}/imgs/error.png`}
				alt={type}
			/>
			<span className="snackbar--message">{message}</span>
			<button
				type="button"
				className="snackbar--close "
				onClick={callback}
			>
				&times;
			</button>
		</div>
	);
};

export default Snackbar;
