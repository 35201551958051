import React, { useState } from "react";
import './Tooltip.scss';

export default function Tooltip(props) {

	const { content, position = 'top', className, width, children, reference } = props
	const [active, setActive] = useState(false)

	const toggleTip = () => {
		setActive(!active)
	}

	return (
		<div className={`servify-tooltip ${className?.tooltipContainer || ''}`} onMouseEnter={toggleTip} onMouseLeave={toggleTip} ref={reference}>
			<span style={{ cursor: 'pointer' }}>{children}</span>
			{active && (
				<span className={`servify-tooltip-text ${className?.tooltipText || ''} ${position}`} style={{ minWidth: `${width}` }}>
					{content()}
				</span>
			)}
		</div>
	)
}