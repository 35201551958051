import React from 'react';
import {Modal} from 'sfy-react';
import FlagFraudInput from './FlagFraudInput';

import './FlagFraudModal.scss';
const FlagFraudModal=(props)=>{
    const {localString,showModal,closeModal,addedFraudFlag} = props;
		return (
		<>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='fraud-modal'>
                <Modal.Header>
                    <h4 className="modal-title text-center">
                        {
                            !addedFraudFlag || !addedFraudFlag.flagID ? localString("Add New Flag") : localString(" Edit / Delete Flag")
                        }
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <FlagFraudInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default FlagFraudModal;