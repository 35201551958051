import React, { useState, useEffect } from 'react';
import {Button} from 'sfy-react';
import AddNewAddress from '../../../../../components/AddNewAddress/AddNewAddress';
import Swal from "sweetalert2";
import { displayError } from '../../../../../Utility/Utils';

const SelectAddressPopupInput=(props)=>{
    const {apiCall,localString,closeModal,consumerDetails,consumerServiceRequest,countriesChecks, selectedCountryData,setLoader,createBerShipment} = props;
    const [isAddressArrayProcessed,setIsAddressArrayProcessed] = useState(false); 
    const [addressList,setAddressList] = useState([]);
    const [selectedAddress,setSelectedAddress] = useState(null);
    const [newlySelectedAddress,setNewlySelectedAddress] = useState(null);
    const [showNewAddress, setShowNewAddress] = useState(false);
    const [address,setAddress] = useState({});
    const [stateList, setStateList] = useState([]);
    let addresses = consumerDetails.Addresses;

	const countryConfig = selectedCountryData && selectedCountryData.countryConfig;
    useEffect(() => {
        processAddressArray();
    }, [])

    // useEffect(()=>{
    //     getStateList()
    // },[consumerServiceRequest.CountryID])
    const processAddressArray = () =>{
        if(!isAddressArrayProcessed && addresses && addresses.length > 0) {
            setIsAddressArrayProcessed(true)
            let localAddressList = JSON.parse(JSON.stringify(addresses));
            setAddressList(localAddressList);
            let selectedAddressArr = localAddressList.find((obj, index)=>{
                obj.index = index;
                return obj.ConsumerFavouriteLocationID == consumerServiceRequest.ConsumerFavouriteLocationID;
            });
            setSelectedAddress(selectedAddressArr)
            selectedAddressArr && localAddressList.splice(selectedAddressArr.index, 1);
            if(!newlySelectedAddress){
                setNewlySelectedAddress(selectedAddressArr);
            }
            getStateList(addresses.CountryID);
        // 	self.update();
        } else if(!isAddressArrayProcessed && !addresses && (addresses && addresses.length == 0)) {
            setIsAddressArrayProcessed(true)
            setShowNewAddress(true)
        }
    };
    const getStateList = (CountryID) => {
		// setLoader(true);
		let reqObj = {
			CountryID: CountryID ? CountryID: consumerServiceRequest.CountryID 
		}
		apiCall({
			url: 'getStates',
			data: reqObj,
			callBack: (result) => {
				// setLoader(false); 
				if(result.success && result.data){
					setStateList(result.data);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
		});
	}

    const addConsumerAddress =  (address) => {
        setLoader(true);
        apiCall({
			url: (address && address.ConsumerFavouriteLocationID ? 'updateLocation' : 'addLocation'),
			data: address,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data){
					setNewlySelectedAddress(result.data);
                    
				}else {
					Swal.fire({  
						title: '',
                        text: localString("Something went wrong"),
                        icon: 'error',
                        confirmButtonText: localString('OK'),
                        cancelButtonText: localString('Cancel')
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					title: '',
                    text: localString("Something went wrong"),
                    icon: 'error',
                    confirmButtonText: localString('OK'),
                    cancelButtonText: localString('Cancel')
				});
			}
		});
    };

    const sendShipmentAddress = (addressObj) =>{
        createBerShipment(addressObj ? addressObj : newlySelectedAddress);
        closeModal();
    };

    const sendAddress = (addressObj)=> {
        setNewlySelectedAddress(addressObj);
        setShowNewAddress(false)
        sendShipmentAddress(addressObj);
    };

    const addressListClick = (address) =>{
        setShowNewAddress(true);
        if(checkEditAddressListClick(address)) {
            editIncorrectAddress(address)
        } else if(isStateMandatory() && address.State && (!address.StateID || !checkStateInStateList(address.State))) {
            checkCorrectState(address);
        } else {
            setShowNewAddress(false)
            setNewlySelectedAddress(address)
        }
    }

    const editIncorrectAddress = (address) => {
        setAddress(address);
        addNewAddress();
        Swal.fire({
			text: localString('Some of the details might be missing in the address, please update the address.'),
			icon: 'warning',
			confirmButtonText: localString('OK'),
			cancelButtonText: localString('Cancel')
		});
    };

    const checkEditAddressListClick = (address) => {
        setAddress(null);
		if(countriesChecks && countriesChecks.countryConfig && countriesChecks.countryConfig.addressConfig.length > 0) {
			let mandatoryFields = countriesChecks.countryConfig.addressConfig.filter(obj => obj.mandatory && obj.display);
			let count = 0;
			for(var i = 0; i < mandatoryFields.length; i++) {
                if(address[mandatoryFields[i].keyName] && address[mandatoryFields[i].keyName] != '') {
                    count++
                }
            }
            return count == mandatoryFields.length ? false : true;
		}
	}

    const isStateMandatory = () => {
		let stateMandatory;
		if(countriesChecks.countryConfig && countriesChecks.countryConfig.addressConfig && countriesChecks.countryConfig.addressConfig.length > 0) {
            stateMandatory = countriesChecks.countryConfig.addressConfig.filter((obj)=>{
                return obj.keyName == 'State' && obj.mandatory == true && obj.display == true;
            })
        }
        return stateMandatory ? true : false;
	}

    const checkCorrectState = (address) => {
        let selectedState = stateList.find((obj) => { 
            return (obj.State).toLowerCase() == (address.State).toLowerCase();
        });
        if(selectedState && selectedState.StateID) {
            address.StateID = selectedState.StateID;
            addConsumerAddress(address);
        } else {
            editIncorrectAddress(address);
        }
    };

    const checkStateInStateList = (state) => {
		let selectedState = stateList.find((obj) => {
            return (obj.State).toLowerCase() == (state).toLowerCase();
        });
        return selectedState ? true : false;
	}

    const addNewAddress = ()=>{
        setShowNewAddress(true);
    }

    const selectedAddressClick =(e)=> {
        if(newlySelectedAddress) {
            setNewlySelectedAddress(selectedAddress);
        }
    };

    return ( 
        <>
            {!showNewAddress && <div className='modal-body clearfix'>
                {
                    selectedAddress && !showNewAddress && <div className='col-sm-12'>
                        <div>
                            <label>{localString("Selected Address")}:</label>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6' onClick={()=>selectedAddressClick()}>
                                <div className={`item address-item  ${(newlySelectedAddress && newlySelectedAddress.ConsumerFavouriteLocationID == selectedAddress.ConsumerFavouriteLocationID) ? 'item-active-border' : ''}`}>
                                    <p className="address-p">{selectedAddress.Address} {selectedAddress.Landmark} {selectedAddress.PinCode ? '- ' + selectedAddress.PinCode : ''}</p>
                                    <p className="address-p">{selectedAddress.City && <span>{selectedAddress.City} - </span>}{selectedAddress.State && <span>{selectedAddress.State}</span>}</p>
                                    {selectedAddress.AddressType && <p className="address-type-p">{localString(selectedAddress.AddressType)}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    !showNewAddress && <div className="col-sm-12" >
                        <div>
                            <label>{localString("Choose "+ (selectedAddress ? 'another' : '') +" address")}:</label>
                        </div>
                        {addressList && <div className="row">
                            <div className="col-sm-4" onClick={()=>addNewAddress()}>
                                <div className="item address-item add-address" >
                                    <div className="text-center addaddressbtn">
                                        <div className="glyphicon glyphicon-plus gi-3x"></div>
                                        <div>
                                            <label>
                                                {localString("Add New Address")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                addressList && addressList.map(address=>(
                                    <div className="col-sm-4" onClick={(e)=>addressListClick(address)}>
                                        <div className={`item address-item ${(newlySelectedAddress && newlySelectedAddress.ConsumerFavouriteLocationID == address.ConsumerFavouriteLocationID) ? 'item-active-border' : ''}`}>
                                            <p className="address-p">{address.Address} {address.Landmark} {address.PinCode ? '- ' + address.PinCode : ''}</p>
                                            <p className="address-p">{address.City &&<span>{address.City} - </span>}{address.State && <span>{address.State}</span>}</p>
                                            { address.AddressType && <p className="address-type-p">{localString(address.AddressType)}</p>} 
                                        </div>
                                    </div>
                                ))
                            }
                        </div>}
                    </div>
                }
            </div>}
            {
                showNewAddress && <div style={{padding: '0px 20px'}}>
                    <AddNewAddress
                        {...props}
                        pinCodeLengthArr={countriesChecks.intAllowedPinCodeLengths}
                        skipZipCodeCheck={countriesChecks.skipZipCodeCheck}
                        countryConfig={countryConfig}
                        cid={consumerDetails.ConsumerID}
                        countryid={selectedCountryData.CountryID}
                        countryCode={selectedCountryData.CountryCode}
                        address={address}
                        setAddress={setAddress}
                        setShowNewAddress = {setShowNewAddress}
                        event="AddAddressInBER"
                        identifier="selectedAddressPopup"
                        csrid = {consumerServiceRequest.ConsumerServiceRequestID}
                        sendAddress={sendAddress}
                        vcsrLayout={true}
                    />
                </div>
            }
            {!showNewAddress  && <div class="right-align">
                <Button className="btn button-servify" handleOnClick = {()=>sendShipmentAddress()}>{localString("Continue")}</Button>
            </div>}
        </> 
        
        )
}
export default SelectAddressPopupInput;
