import React from 'react';
import {Dropdown, Button} from 'sfy-react';

const AssignWorkshop = (props) => {
	const {assignObjCheck, accessFn ,localString, consumerServiceRequest, canUpdateServiceRequest, showEngineerAssignSection, engineerList, disableAssignEngineer,
		engineerDetails, setEngineerDetails, assignRequest, disableAssign, selectServiceLocation, serviceLocationObj, setServiceLocationObj
	} = props;

	return (
		<div className="request-panel consumer-details-height consumer-assignment-section">
		{
			(assignObjCheck && !assignObjCheck.showassignmenttag) &&
			<div className="assign-empty-state">
				<p>{localString("N/A")}</p>
			</div>
		}
		{
			assignObjCheck && assignObjCheck.showassignmenttag && 
			<div className="request-panel-content">
				{
					assignObjCheck.showservicelocationlabel && 
					<p>{consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true"?localString("PSL Center"):localString("Service Center")}:
						<br/> {(consumerServiceRequest.servicelocation)? (consumerServiceRequest.servicelocation.ServiceLocationName || '-') : '-'}
					</p>
				}
				{
					assignObjCheck.showservicelocationtypeahead && ((consumerServiceRequest.showEngineerVisits && accessFn('SERVICE_LOCATION_ASSIGN') || !consumerServiceRequest.showEngineerVisits)) && 
					<div  className="form-group">
						<label for="servicelocation">{consumerServiceRequest.CREATED_AT_DROPOFF_PSL=== "true"?localString("PSL Center"):localString("Service Center")}</label>
							<Dropdown 
								id="servicelocation"
								name="servicelocation"
								value={serviceLocationObj.serviceLocationName}
								options={selectServiceLocation}
								handleOnChange={(data) => {
									setServiceLocationObj({
										...serviceLocationObj,
										serviceLocationName: data.ServiceLocationName,
										partnerServiceLocationID: data.PartnerServiceLocationID
									})
								}}
								placeholder={(selectServiceLocation && selectServiceLocation.length==0 ) ? localString("No service locations found") : localString("Select a service center")}
								filterBy='value'
								showDownArrow={false}
							/>
					</div>
				}
				{
					assignObjCheck.showengineerlabel && 
					<p>{localString("Service Engineer")}:
						<br/>{(consumerServiceRequest.engineer) ? (consumerServiceRequest.engineer.Name || '-' ): '-'}
					</p>
				}
				{
					showEngineerAssignSection() &&
					
					<div>
					{
						//&& canUpdateServiceRequest 
						assignObjCheck.showengineertypeahead && !consumerServiceRequest.showEngineerVisits && !accessFn('READ_ONLY') && (accessFn('REASSIGN_JOBS') || accessFn('ACCEPT_RETURN_DEVICE')) &&
						<>
							<div class="form-group scrollable-dropdown-menu-engg ">
								<label>{localString("Re-assign Service Engineer")}:</label>
								{/* <input type="text" name="engineer" id="engineer" onkeyup={getEngineerValue} placeholder={(EngineersData && EngineersData.length==0 ) ? localString("No engineers found") : localString("Select an engineer")} autocomplete="off" data-required="one" class="form-control" onkeydown="return false;" disabled={disableAssignEngineer()}/> */}
									<Dropdown 
										id="engineer"
										name="engineer"
										value={engineerDetails.name}
										options={engineerList}
										handleOnChange={(data) => {
											setEngineerDetails({
												...engineerDetails,
												name:data.Name,
												serviceLocationEnggID:data.ServiceLocationEngineerID,
												PartnerServiceLocationID:data.PartnerServiceLocationID
											})
										}}
										placeholder={(engineerList && engineerList.length==0) ? localString("No engineers found") : localString("Select an engineer")}
										filterBy='value'
										// isDisabled={disableAssignEngineer}
										showDownArrow={false}
									/>
							</div>
						</>
					}
					{
						//&& canUpdateServiceRequest
						assignObjCheck.showassignmentbutton && ((consumerServiceRequest.showEngineerVisits && accessFn('SERVICE_LOCATION_ASSIGN')) || (!consumerServiceRequest.showEngineerVisits )) && !accessFn('READ_ONLY') && (accessFn('REASSIGN_JOBS') || accessFn('ACCEPT_RETURN_DEVICE')) &&
						<div class="text-right">
							<Button 
								handleOnClick={assignRequest} 
								type='button' 
								id="assignButton"
								isDisabled={disableAssign}
								classes='button-ghost' 
								text={localString('Assign')}
							/>
						</div>
					}
					</div>
				}
				{
					assignObjCheck.showstatusmessage &&
					<div class="request-process">
						<p class="assign-empty-state">{localString("Request is being processed")}.</p>
					</div>
				}
			</div>
		}
		</div>
	)
}

export default AssignWorkshop
