import React from "react";
import { Modal, Button } from "sfy-react";
import Swal from "sweetalert2";
import {  displayError } from "../../Utility/Utils";


const SendMessageModal = (props) => {
  	const { showModal, closeModal, messageIcon, messageText, modalText, sendButtonText, localString, 
	        setLoader, apiCall,planRenewalOfferID,selectedRows,findConsumerRenewals,reminderCounts,maxManualRetryCount,accessFn
	} = props;
    
	const qFilter = JSON.parse(props.qFilter)
	
	const sendPlanRenewalNotification = () => {
		setLoader(true);
	  
		// Increment UsedManualRetryTrigger for selected rows
		const updatedSelectedRows = selectedRows.map((row) => ({
		  ...row,
		  UsedManualRetryTrigger: (row.UsedManualRetryTrigger || 0) + 1,
		}));
	  
		const updatedReminderCounts = { ...reminderCounts };
	  
		updatedSelectedRows.forEach((row) => {
		  const planRenewalOfferID = row.PlanRenewalOfferID;
		  const currentCount = updatedReminderCounts[planRenewalOfferID] || 0;
	  
		  if (currentCount <= maxManualRetryCount) {
			updatedReminderCounts[planRenewalOfferID] = currentCount + 1;
		  }
		});
	  
		let reqObj = {
			PlanRenewalOfferIDs: selectedRows.length > 0 ? selectedRows.map((item) => item.PlanRenewalOfferID) : [planRenewalOfferID],
		};
		apiCall({
			url: 'sendPlanRenewalNotification',
			data: reqObj,
			callBack: (result) => {
			setLoader(false);  	  
				if (result.data.success.length > 0) {
					Swal.fire({
						icon: "success",
						title: localString("Success"),
						text: localString("Message has been sent successfully"),
						confirmButtonText: localString("OK"),
					});
					findConsumerRenewals();	
					closeModal();
				} else if (result.data.ineligible.length > 0) {
					Swal.fire({
						icon: 'error',
						text: localString("Message not sent"),
					});
					closeModal();
				}  else {
					Swal.fire({
						icon: 'error',
						text: localString("Message not sent"),
					});
					closeModal();
				}
		    },
		    errorCB: (err) => setLoader(false),
		});
	};

	return (
		<Modal
			className="Filter-Modal bulkUploadModal"
			showModal={showModal}
			closeModal={closeModal}
			showCloseBtn={true}
		>
		<Modal.Header className="p0"></Modal.Header>
		<Modal.Body className="pt0">
			<div className="body-content">
				<div className="message-icon">
					{messageIcon}
				</div>
			<div className="modal-text pt20">{messageText}</div>
				<div className="pt12">
					{modalText}
				</div>
			</div>
		</Modal.Body>
		<Modal.Footer className="text-center d-block">
			<Button className="button-small servify-button" onClick={sendPlanRenewalNotification}>
				{sendButtonText}
			</Button>
		</Modal.Footer>
		</Modal>
	);
};

export default SendMessageModal;
