import React, { useState } from 'react';
import _ from 'lodash';
import Swal from "sweetalert2"; 
import { Button, Dropdown, Loader, Modal, Input, Table } from 'sfy-react';
import { displayError } from '../../../../../Utility/Utils';


export default function AlternatePartModal(props) {
	const {
		apiCall, accessFn, localString, setLoader, showAlternatePartModal, setShowAlternatePartModal, partArr, setPartArr, 
		consumerServiceRequest, getRequestById, getPartsForRequest, altPartOptions, setAltPartOptions, setRadioBtnClicked,
		altSerializedPartOptions, setAltSerializedPartOptions, setIsAlternatePartSelected
	} = props;

	const [selectedAltPartArray, setSelectedAltPartArray] = useState([]);

	const getAlternateParts = (part, index) => {
		if(!altPartOptions?.length || !altPartOptions[index]) {
			setLoader(true);
			let reqObj = {
				PartID: part.PartID, 
				ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			};
			apiCall({
				url: 'getAlternateParts',
				data: reqObj,
				callBack: (result) => {
					setLoader(false);
					if (result.success && result.data && result.data.alternateParts) {
						let data = result.data.alternateParts;
						let arr = [...altPartOptions];
						if(data && data.length) {
							arr[index] = data.map(a => ({...a, value: a.Name}));
						}
						setAltPartOptions(arr);
					} else {
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				},
				errorCB: (err) => setLoader(false),
			});
		}
	}

	const getAlternateSerializedParts = (part, index) => {
		if(!altSerializedPartOptions?.length || !altSerializedPartOptions[index]) {
			setLoader(true);
			let reqObj = {
				part: part.alternatePart, 
				PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID
			};
			apiCall({
				url: 'getPartItemsBySerialNumber',
				data: reqObj,
				callBack: (result) => {
					setLoader(false);
					if (result.success && result.data) {
						let data = result.data;
						let arr = [...altSerializedPartOptions];
						if(data && data.length) {
							arr[index] = data.map(a => ({...a, value: a.SerialNumber}));
						}
						setAltSerializedPartOptions(arr);
					} else {
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				},
				errorCB: (err) => setLoader(false),
			});
		}
	}

	const saveAlternateParts = () => {
		let error = false;
		let partArray = _.cloneDeep(partArr);
		_.forEach(partArray, function(part, i) {
			if (part.action == "alternateSelected") {
				if (!part.alternatePart) {
					Swal.fire({
						icon: 'error',
						title: '',
						text: localString(`Please Select Alternate Part for ${part.Name}`),
						confirmButtonText: localString('OK')
					});
					error = true;
					return;
				}
				if (part.alternatePart.Serialized == 1 && accessFn('WORKSHOP_FLOW')) {
					if (!part.PartItemID || !part.SerialNumber) {
						Swal.fire({
							icon: 'error',
							title: '',
							text: localString(`Please Select SerialNumber for ${part.alternatePart.Name}`),
							confirmButtonText: localString('OK')
						});
						error = true;
						return;
					} else {
						part.alternatePart.PartItemID = part.PartItemID;
						part.alternatePart.SerialNumber = part.SerialNumber;
					}
				}
				if (part.alternatePart.RequiresSerialNumber && accessFn('WORKSHOP_FLOW')) {
					if (!part?.alternatePart?.IMEI1) {
						Swal.fire({
							icon: 'error',
							title: 'Sorry',
							text: localString(`Please enter IMEI1 for  ${part.alternatePart.Name}`),
						});
						error = true;
						return;
					}
					if (!part?.alternatePart?.IMEI2) {
						Swal.fire({
							icon: 'error',
							title: 'Sorry',
							text: localString(`Please enter IMEI2 for  ${part.alternatePart.Name}`),
						});
						error = true;
						return;
					}
					if ((part?.alternatePart?.IMEI1.length !== 15) || (part?.alternatePart?.IMEI2 !== 15)) {
						Swal.fire({
							icon: 'error',
							title: 'Sorry',
							text: localString(`Please enter correct IMEI for ${part.alternatePart.Name}`),
						});
						error = true;
					}
				}
			}
		})
		if (!error) {
			setLoader(true);
			let reqObj = {
				ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
           		parts: partArray,
			}
			apiCall({
				url: 'issueAlternateParts',
				data: reqObj,
				callBack: (result) => {
					setLoader(false);
					if (result.success) {
						setRadioBtnClicked(false);
						setPartArr([]);
						setIsAlternatePartSelected(false);
						setAltPartOptions([]);
						setSelectedAltPartArray([]);
						setAltSerializedPartOptions([]);
						setShowAlternatePartModal(false);
						getRequestById();
						getPartsForRequest();
					} else {
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				},
				errorCB: (err) => setLoader(false),
			});
		}
	}

	const handleAlternatPartChange = (data, k) => {
		if (data.inventory && data.inventory.Quantity > 0) { 
			let arr = _.cloneDeep(partArr);
			arr[k].alternatePartValue = data.value;
			arr[k].alternatePart = data;																				
			setPartArr(arr);
			let arr1 = _.cloneDeep(selectedAltPartArray);
			arr1[k] = data;
			setSelectedAltPartArray(arr1);
		} else {
			Swal.fire({
				title: '',
				icon: 'error',
				text: localString('Please select another Part with Quantity'),
				confirmButtonText: localString('OK'),
			});
		}	
	}

	return (
		<>
			<div className='alternatePartModal '>
				<Modal
					className=''
					showModal={showAlternatePartModal}
					closeModal={() => {
						setShowAlternatePartModal(false);
						setSelectedAltPartArray([]);
					}}
					showCloseBtn={true}
				>
					<Modal.Header>
						<h4 className="modal-title">{localString("Select Alternate Parts")}</h4>
					</Modal.Header>
					<Modal.Body>
						<div className="modal-body">
							<div className="">
							{
								partArr && partArr.length > 0 && partArr.map((part, k) => {
									if(part.action == 'alternateSelected') {
										return (
											<>
												<Table 
													className={{
														table: 'table-bordered'
													}}
												>
													<Table.Body>
														<Table.Row>
															<Table.Heading>{localString("Primary Part")}</Table.Heading>
															<Table.Data>{part && part.Name}</Table.Data>
														</Table.Row>
														<Table.Row>
															<Table.Heading>{localString("Select Alternate Part")}</Table.Heading>
															<Table.Data>
																<div className="form-group">
																	<Dropdown
																		value={part.alternatePartValue}
																		options={altPartOptions[k] || []}
																		handleOnChange={(data) => handleAlternatPartChange(data, k)}
																		handleOnFocus={() => getAlternateParts(part, k)}
																		filterBy='value'
																		showDownArrow={false}
																		placeholder={localString('Select a Part Name')}
																	/>
																</div>
															</Table.Data>
														</Table.Row>
														{ (part.alternatePart && part.alternatePart.Serialized == 1 ) && 
															<Table.Row>
																<Table.Heading>{localString("Serial Number")}</Table.Heading>
																<Table.Data>
																	<div className="form-group">
																		<Dropdown
																			value={part.SerialNumber}
																			options={altSerializedPartOptions[k] || []}
																			handleOnChange={(data) => {
																				let arr = _.cloneDeep(partArr);
																				arr[k].PartItemID = data.PartItemID;
																				arr[k].SerialNumber = data.value;																				
																				setPartArr(arr);
																			}}
																			handleOnFocus={() => {
																				getAlternateSerializedParts(part, k)
																			}}
																			filterBy='value'
																			showDownArrow={false}
																			placeholder={localString('Serial Number')}
																		/>
																	</div>
																</Table.Data>
															</Table.Row> 
														}
														{ part.alternatePart && part.alternatePart.RequiresSerialNumber && <Table.Row>
															<Table.Heading>{localString("IMEI1")}</Table.Heading>
															<Table.Data>
																<div className="form-group">
																	<Input
																		value={part?.alternatePart?.IMEI1 || ''}
																		handleOnChange={(e) => {
																			let arr = _.cloneDeep(partArr);
																			arr[k].alternatePart.IMEI1 = e.target.value;
																			setPartArr(arr);
																		}}
																		maxLength={15}
																		placeholder={'IMEI1'}
																	/>
																</div>
															</Table.Data>
														</Table.Row> }
														{ part.alternatePart && part.alternatePart.RequiresSerialNumber && <Table.Row>
															<Table.Heading>{localString("IMEI2")}</Table.Heading>
															<Table.Data>
																<div className="form-group">
																	<Input
																		value={part?.alternatePart?.IMEI2 || ''}
																		handleOnChange={(e) => {
																			let arr = _.cloneDeep(partArr);
																			arr[k].alternatePart.IMEI2 = e.target.value;
																			setPartArr(arr);
																		}}
																		maxLength={15}
																		placeholder={'IMEI2'}
																	/>
																</div>
															</Table.Data>
														</Table.Row> }
														<Table.Row>
															<Table.Heading>{localString("Part Code")}</Table.Heading>
															<Table.Data>{selectedAltPartArray?.[k]?.PartCode || '-'}</Table.Data>
														</Table.Row>
														<Table.Row>
															<Table.Heading>{localString("HSN Code")}</Table.Heading>
															<Table.Data>{selectedAltPartArray?.[k]?.partPrice?.HSNCode || '-'}</Table.Data>
														</Table.Row>
														<Table.Row>
															<Table.Heading>{localString("Part Price")}</Table.Heading>
															<Table.Data>{(selectedAltPartArray?.[k]?.partPrice && selectedAltPartArray[k].partPrice.hasOwnProperty('Price')) ? selectedAltPartArray[k].partPrice.Price : '-' }</Table.Data>
														</Table.Row>
														<Table.Row>
															<Table.Heading>{localString("Qty")}</Table.Heading>
															<Table.Data>1</Table.Data>
														</Table.Row>
														<Table.Row>
															<Table.Heading>{localString("Available Qty")}</Table.Heading>
															<Table.Data>{selectedAltPartArray?.[k]?.inventory?.Quantity || '-'}</Table.Data>
														</Table.Row>
													</Table.Body>
												</Table>
											</>
										);
									}
								})
							}
							</div>
							<div className='paddingB10'>
								<Button 
									className="btn button-servify pull-right" 
									onClick={() => saveAlternateParts()}
								>
									{localString("Save")}
								</Button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		</>
	);
}