const lang = {
	"Consumers": {"text": "Consumers", "isAlreadyTranslated": false},
	"Clear Search": {"text": "Clear Search", "isAlreadyTranslated": false},
	"Filter Consumer": {"text": "Filter Consumer", "isAlreadyTranslated": false},
	"Add Consumers": {"text": "Add Consumers", "isAlreadyTranslated": false},
	"Name": {"text": "Name", "isAlreadyTranslated": false},
	"Email ID": {"text": "Email ID", "isAlreadyTranslated": false},
	"Mobile": {"text": "Mobile", "isAlreadyTranslated": false},
	"Device Type": {"text": "Device Type", "isAlreadyTranslated": false},
	"Consumer Details": {"text": "Consumer Details", "isAlreadyTranslated": false},
	"Mobile No.": {"text": "Mobile No.", "isAlreadyTranslated": false},
	"Model": {"text": "Model", "isAlreadyTranslated": false},
	"Alternate No.": {"text": "Alternate No.", "isAlreadyTranslated": false},
	"Landline No": {"text": "Landline No", "isAlreadyTranslated": false},
	"View Consumer": {"text": "View Consumer", "isAlreadyTranslated": false},
	"Save": {"text": "Save", "isAlreadyTranslated": false},
	"Issues": {"text": "Issues", "isAlreadyTranslated": false},
	"Add": {"text": "Add", "isAlreadyTranslated": false},
	"Send": {"text": "Send", "isAlreadyTranslated": false},
	"SMS": {"text": "SMS", "isAlreadyTranslated": false},
	"Email": {"text": "Email", "isAlreadyTranslated": false},
	"First": {"text": "First", "isAlreadyTranslated": false},
	"Last": {"text": "Last", "isAlreadyTranslated": false},
	"Filter": {"text": "Filter", "isAlreadyTranslated": false},
	"Customer": {"text": "Customer", "isAlreadyTranslated": false},
	"Close": {"text": "Close", "isAlreadyTranslated": false},
	"Call": {"text": "Call", "isAlreadyTranslated": false},
	"logs": {"text": "logs", "isAlreadyTranslated": false},
	"Consumer": {"text": "Consumer", "isAlreadyTranslated": false},
	"Subcategory": {"text": "Subcategory", "isAlreadyTranslated": false},
	"Brand": {"text": "Brand", "isAlreadyTranslated": false},
	"Product": {"text": "Product", "isAlreadyTranslated": false},
	"Apply": {"text": "Apply", "isAlreadyTranslated": false},
	"Dashboard": {"text": "Dashboard", "isAlreadyTranslated": false},
	"Requests": {"text": "Requests", "isAlreadyTranslated": false},
	"View Document": {"text": "View Document", "isAlreadyTranslated": false},
	"Logout": {"text": "Logout", "isAlreadyTranslated": false},
	"Notifications": {"text": "Notifications", "isAlreadyTranslated": false},
	"Coupons": {"text": "Coupons", "isAlreadyTranslated": false},
	"UserList": {"text": "UserList", "isAlreadyTranslated": false},
	"Curate": {"text": "Curate", "isAlreadyTranslated": false},
	"Diagnosis": {"text": "Diagnosis", "isAlreadyTranslated": false},
	"Distributor": {"text": "Distributor", "isAlreadyTranslated": false},
	"Enterprise Sales": {"text": "Enterprise Sales", "isAlreadyTranslated": false},
	"Reseller": {"text": "Reseller", "isAlreadyTranslated": false},
	"Plan Management": {"text": "Plan Management", "isAlreadyTranslated": false},
	"Sell Plans": {"text": "Sell Plans", "isAlreadyTranslated": false},
	"Partner Management": {"text": "Partner Management", "isAlreadyTranslated": false},
	"Logistics Requests": {"text": "Logistics Requests", "isAlreadyTranslated": false},
	"Optin Requests": {"text": "Optin Requests", "isAlreadyTranslated": false},
	"Catalogue": {"text": "Catalogue", "isAlreadyTranslated": false},
	"Exclusive": {"text": "Exclusive", "isAlreadyTranslated": false},
	"Created on": {"text": "Created on", "isAlreadyTranslated": false},
	"Source": {"text": "Source", "isAlreadyTranslated": false},
	"Device Count": {"text": "Device Count", "isAlreadyTranslated": false},
	"Reg/Unreg": {"text": "Registered / Unregistered", "isAlreadyTranslated": false},
	"Phone Number": {"text": "Phone Number", "isAlreadyTranslated": false},
	"View": {"text": "View", "isAlreadyTranslated": false},
	"Mobile No": {"text": "Mobile No", "isAlreadyTranslated": false},
	"Cancel": {"text": "Cancel", "isAlreadyTranslated": false},
	"Edit": {"text": "Edit", "isAlreadyTranslated": false},
	"View Request": {"text": "View Request", "isAlreadyTranslated": false},
	"Raise A Request": {"text": "Raise A Request", "isAlreadyTranslated": false},
	"Bills/Documents": {"text": "Bills/Documents", "isAlreadyTranslated": false},
	"Explore Device Lifecycle": {"text": "Explore Device Lifecycle", "isAlreadyTranslated": false},
	"History": {"text": "History", "isAlreadyTranslated": false},
	"Warranty Till": {"text": "Warranty Till", "isAlreadyTranslated": false},
	"Year Of Purchase": {"text": "Year Of Purchase", "isAlreadyTranslated": false},
	"Date Of Purchase": {"text": "Date Of Purchase", "isAlreadyTranslated": false},
	"IMEI": {"text": "IMEI", "isAlreadyTranslated": false},
	"Serial No.:" : {"text": "Serial No.:", "isAlreadyTranslated": false},
	"Initiate diagnosis": {"text": "Initiate diagnosis", "isAlreadyTranslated": false},
	"Initiate diagnosis directly in the Servify App": {"text": "Initiate diagnosis directly in the Servify App", "isAlreadyTranslated": false},
	"No diagnosis performed": {"text": "No diagnosis performed", "isAlreadyTranslated": false},
	"View Diagnostic Result": {"text": "View Diagnostic Result", "isAlreadyTranslated": false},
	"Last Diagnosis Performed on": {"text": "Last Diagnosis Performed on", "isAlreadyTranslated": false},
	"No Issues Found": {"text": "No Issues Found", "isAlreadyTranslated": false},
	"Found": {"text": "Found", "isAlreadyTranslated": false},
	"Issue": {"text": "Issue", "isAlreadyTranslated": false},
	"Created Date": {"text": "Created Date", "isAlreadyTranslated": false},
	"Model No": {"text": "Model No", "isAlreadyTranslated": false},
	"Tag Name": {"text": "Tag Name", "isAlreadyTranslated": false},
	"Product Subcategory": {"text": "Product Subcategory", "isAlreadyTranslated": false},
	"Add Product": {"text": "Add Product", "isAlreadyTranslated": false},
	"Upload Documents": {"text": "Upload Documents", "isAlreadyTranslated": false},
	"Serial No": {"text": "Serial No", "isAlreadyTranslated": false},
	"Product Name": {"text": "Product Name", "isAlreadyTranslated": false},
	"Product SubCategory": {"text": "Product SubCategory", "isAlreadyTranslated": false},
	"Loading": {"text": "Loading", "isAlreadyTranslated": false},
	"Created On": {"text": "Created On", "isAlreadyTranslated": false},
	"Alternate Mobile No": {"text": "Alternate Mobile No", "isAlreadyTranslated": false},
	"View Consumer Details": {"text": "View Consumer Details", "isAlreadyTranslated": false},
	"Call History": {"text": "Call History", "isAlreadyTranslated": false},
	"Unregistered Products": {"text": "Unregistered Products", "isAlreadyTranslated": false},
	"Registered Products": {"text": "Registered Products", "isAlreadyTranslated": false},
	"No unregistered products found": {"text": "No unregistered products found", "isAlreadyTranslated": false},
	"Content": {"text": "Content", "isAlreadyTranslated": false},
	"Customer Name": {"text": "Customer Name", "isAlreadyTranslated": false},
	"Reference ID": {"text": "Reference ID", "isAlreadyTranslated": false},
	"Device": {"text": "Device", "isAlreadyTranslated": false},
	"Status": {"text": "Status", "isAlreadyTranslated": false},
	"Scheduled Date": {"text": "Scheduled Date", "isAlreadyTranslated": false},
	"Type": {"text": "Type", "isAlreadyTranslated": false},
	"Add call log": {"text": "Add call log", "isAlreadyTranslated": false},
	"Call type": {"text": "Call type", "isAlreadyTranslated": false},
	"Connect": {"text": "Connect", "isAlreadyTranslated": false},
    "Non-Connect Disposition": {"text": "Non-Connect Disposition", "isAlreadyTranslated": false},
    "Connect Disposition": {"text": "Connect Disposition", "isAlreadyTranslated": false},
    "Name of the caller": {"text": "Name of the caller", "isAlreadyTranslated": false},
    "Remarks": {"text": "Remarks", "isAlreadyTranslated": false},
    "No call history": {"text": "No call history", "isAlreadyTranslated": false},
    "Please enter alphabets only": {"text": "Please enter alphabets only", "isAlreadyTranslated": false},
    "Please enter correct EmailID": {"text": "Please enter correct EmailID", "isAlreadyTranslated": false},
    "Please enter correct Name": {"text": "Please enter correct Name", "isAlreadyTranslated": false},
    "Please enter correct Alternate Mobile Number": {"text": "Please enter correct Alternate Mobile Number", "isAlreadyTranslated": false},
    "Incoming": {"text": "Incoming", "isAlreadyTranslated": false},
    "Outgoing": {"text": "Outgoing", "isAlreadyTranslated": false},
    "Yes": {"text": "Yes", "isAlreadyTranslated": false},
    "No": {"text": "No", "isAlreadyTranslated": false},
    "Call history": {"text": "Call history", "isAlreadyTranslated": false},
    "No Data Available": {"text": "No Data Available", "isAlreadyTranslated": false},
    "Select a model": {"text": "Select a model", "isAlreadyTranslated": false},
    "Customer Alternate Mobile No": {"text": "Customer Alternate Mobile No", "isAlreadyTranslated": false},
    "Incident ID": {"text": "Incident ID", "isAlreadyTranslated": false},
    "Select an issue": {"text": "Select an issue", "isAlreadyTranslated": false},
    "Please enter Customer's Name": {"text": "Please enter Customer's Name", "isAlreadyTranslated": false},
    "Please select a model from the dropdown": {"text": "Please select a model from the dropdown", "isAlreadyTranslated": false},
    "Please enter at least one issue": {"text": "Please enter at least one issue", "isAlreadyTranslated": false},
    "Please select a Device Type": {"text": "Please select a Device Type", "isAlreadyTranslated": false},
    "Please enter Mobile Number": {"text": "Please enter Mobile Number", "isAlreadyTranslated": false},
    "Please enter correct Mobile Number": {"text": "Please enter correct Mobile Number", "isAlreadyTranslated": false},
    "Please enter correct E-mail ID": {"text": "Please enter correct E-mail ID", "isAlreadyTranslated": false},
    "Something went wrong": {"text": "Something went wrong", "isAlreadyTranslated": false},
    "Consumer successfully added and SMS sent": {"text": "Consumer successfully added and SMS sent", "isAlreadyTranslated": false},
    "Something went wrong when trying to create a consumer. Please try again": {"text": "Something went wrong when trying to create a consumer. Please try again", "isAlreadyTranslated": false},
    "You have already added this issue type":	{"text": "You have already added this issue type", "isAlreadyTranslated": false},
		"Please select a Product from the dropdown": {"text": "Please select a Product from the dropdown", "isAlreadyTranslated": false},
		"Please select a Brand from the dropdown": {"text": "Please select a Brand from the dropdown", "isAlreadyTranslated": false},
		"Please select a Subcategory from the dropdown": {"text": "Please select a Subcategory from the dropdown", "isAlreadyTranslated": false},
		"Select a year between": {"text": "Select a year between", "isAlreadyTranslated": false},
		"and": {"text": "and", "isAlreadyTranslated": false},
		"Please select a document type": {"text": "Please select a document type", "isAlreadyTranslated": false},
		"Please select a document": {"text": "Please select a document", "isAlreadyTranslated": false},
		"Oops... Something went wrong": {"text": "Oops... Something went wrong", "isAlreadyTranslated": false},
		"The product was added": {"text": "The product was added", "isAlreadyTranslated": false},
		"No requests found": {"text": "No requests found", "isAlreadyTranslated": false},
		"OK": {"text": "OK", "isAlreadyTranslated": false},
		"Cancel": {"text": "Cancel", "isAlreadyTranslated": false},
		"REQUESTS": {"text": "REQUESTS", "isAlreadyTranslated": false},
		"Claim Request": {"text": "Claim Request", "isAlreadyTranslated": false},
		"In Progress": {"text": "In Progress", "isAlreadyTranslated": false},
		"Closed": {"text": "Closed", "isAlreadyTranslated": false},
		"Open": {"text": "Open", "isAlreadyTranslated": false},
		"Cancelled": {"text": "Cancelled", "isAlreadyTranslated": false},
		"Period": {"text": "Period", "isAlreadyTranslated": true},
		"Filter Requests": {"text": "Filter Requests", "isAlreadyTranslated": false},
		"Create Bulk Request": {"text": "Create Bulk Request", "isAlreadyTranslated": false},
		"Raise a request": {"text": "Raise a request", "isAlreadyTranslated": false},
		"External Requests": {"text": "External Requests", "isAlreadyTranslated": false},
		"Parts Queue": {"text": "Parts Queue", "isAlreadyTranslated": false},
		"Loading requests": {"text": "Loading requests", "isAlreadyTranslated": false},
		"Customer": {"text": "Customer", "isAlreadyTranslated": false},
		"Service type": {"text": "Service type", "isAlreadyTranslated": false},
		"Schedule": {"text": "Schedule", "isAlreadyTranslated": false},
		"Download requests in csv format": {"text": "Download requests in csv format", "isAlreadyTranslated": false},
		"Download requests in csv format.": {"text": "		Download requests in csv format.", "isAlreadyTranslated": false},

		"Created": {"text": "Created", "isAlreadyTranslated": false},
		"Customer": {"text": "Customer", "isAlreadyTranslated": false},

		"Show graph": {"text": "Show graph", "isAlreadyTranslated": false},
		"Hide graph": {"text": "Hide graph", "isAlreadyTranslated": false},
		"View": {"text": "View", "isAlreadyTranslated": false},
		"out of": {"text": "out of", "isAlreadyTranslated": false},
		"service requests are shown above.": {"service requests are shown above.": "Customer", "isAlreadyTranslated": false},
		"Created or found consumer": {"text": "Created or found consumer", "isAlreadyTranslated": false},
		"Not updated": {"text": "Not updated", "isAlreadyTranslated": false},
		"Please select a call disposition": {"text": "Please select a call disposition", "isAlreadyTranslated": false},
		"Please enter a name": {"text": "Please enter a name", "isAlreadyTranslated": false},
		"Please enter remarks": {"text": "Please enter remarks", "isAlreadyTranslated": false},

		"Username": {"text": "Username", "isAlreadyTranslated": false},
		"Call Connected": {"text": "Call Connected", "isAlreadyTranslated": false},
		"No": {"text": "No", "isAlreadyTranslated": false},
		"Yes": {"text": "Yes", "isAlreadyTranslated": false},
		"Connection Disposition:": {"text": "Connection Disposition:", "isAlreadyTranslated": false},
		"Non-Connect Disposition:": {"text": "Non-Connect Disposition:", "isAlreadyTranslated": false},
		"Date and Time of loss/Damage": {"text": "Date and Time of loss/Damage", "isAlreadyTranslated": false},
		"Damage Type": {"text": "Damage Type", "isAlreadyTranslated": false},
		"Description of Loss/Damage": {"text": "Description of Loss/Damage", "isAlreadyTranslated": false},
		"Date and Time of Call": {"text": "Date and Time of Call", "isAlreadyTranslated": false},
		"Status at which call was made": {"text": "Status at which call was made", "isAlreadyTranslated": false},
		"Remarks": {"text": "Remarks", "isAlreadyTranslated": false},
		"BER Refund": {"text": "BER Refund", "isAlreadyTranslated": false},



		"Service Center": {"text": "Service Center", "isAlreadyTranslated": false},
		"Role": {"text": "Role", "isAlreadyTranslated": false},
		"Accessories will not be returned": {"text": "Accessories will not be returned", "isAlreadyTranslated": false},
		"Documents are yet to be uploaded": {"text": "Documents are yet to be uploaded", "isAlreadyTranslated": false},
		"Request Scheduled for": {"text": "Request Scheduled for", "isAlreadyTranslated": false},
		"Slot": {"text": "Slot", "isAlreadyTranslated": false},
		"Action Status": {"text": "Action Status", "isAlreadyTranslated": false},
		"Action taken": {"text": "Action taken", "isAlreadyTranslated": false},
		"Current Log": {"text": "Current Log", "isAlreadyTranslated": false},
		"Rating": {"text": "Rating", "isAlreadyTranslated": false},
		"Next Steps": {"text": "Next Steps", "isAlreadyTranslated": false},
		"Waiting for the customer to raise a request": {"text": "Waiting for the customer to raise a request", "isAlreadyTranslated": false},
		"Cancel Request": {"text": "Cancel Request", "isAlreadyTranslated": false},
		"Cancel Service Without Dispatch": {"text": "Cancel Service Without Dispatch", "isAlreadyTranslated": false},
		"Device shipped": {"text": "Device shipped", "isAlreadyTranslated": false},
		"Reschedule": {"text": "Reschedule", "isAlreadyTranslated": false},
		"Reassign": {"text": "Reassign", "isAlreadyTranslated": false},
		"Mark Customer Arrival": {"text": "Mark Customer Arrival", "isAlreadyTranslated": false},
		"Start Repair": {"text": "Start Repair", "isAlreadyTranslated": false},
		"Device Received": {"text": "Device Received", "isAlreadyTranslated": false},
		"Generate Invoice": {"text": "Generate Invoice", "isAlreadyTranslated": false},
		"Complete Repair": {"text": "Complete Repair", "isAlreadyTranslated": false},
		"Dispatch Device": {"text": "Dispatch Device", "isAlreadyTranslated": false},
		"Complete Service": {"text": "Complete Service", "isAlreadyTranslated": false},
		"Cancel Repair": {"text": "Cancel Repair", "isAlreadyTranslated": false},
		"Cancel Service": {"text": "Cancel Service", "isAlreadyTranslated": false},
		"Parts Pending": {"text": "Parts Pending", "isAlreadyTranslated": false},
		"Parts received": {"text": "Parts received", "isAlreadyTranslated": false},
		"Download Logistics Label": {"text": "Download Logistics Label", "isAlreadyTranslated": false},
		"Download Jobsheet Label": {"text": "Download Jobsheet Label", "isAlreadyTranslated": false},
		"Consumer Details": {"text": "Consumer Details", "isAlreadyTranslated": false},
		"Address Details": {"text": "Address Details", "isAlreadyTranslated": false},
		"Raise Request": {"text": "Raise Request", "isAlreadyTranslated": false},
		"Device Details": {"text": "Device Details", "isAlreadyTranslated": false},
		"Request Documents": {"text": "Request Documents", "isAlreadyTranslated": false},
		"Issue Validation": {"text": "Issue Validation", "isAlreadyTranslated": false},
		"Issues Reported": {"text": "Issues Reported", "isAlreadyTranslated": false},
		"Estimation Details": {"text": "Estimation Details", "isAlreadyTranslated": false},
		"Parts and Estimation": {"text": "Parts and Estimation", "isAlreadyTranslated": false},
		"Override Approval History": {"text": "Override Approval History", "isAlreadyTranslated": false},
		"Parts Requested": {"text": "Parts Requested", "isAlreadyTranslated": false},
		"Other Requests": {"text": "Other Requests", "isAlreadyTranslated": false},
		"Request Details": {"text": "Request Details", "isAlreadyTranslated": false},
		"Logistics Details": {"text": "Logistics Details", "isAlreadyTranslated": false},
		"Communication History": {"text": "Communication History", "isAlreadyTranslated": false},
		"Override Approval": {"text": "Override Approval", "isAlreadyTranslated": false},
		"Override Approval": {"text": "Override Approval", "isAlreadyTranslated": false},
		"Loading request": {"text": "Loading request", "isAlreadyTranslated": false},
		"No such service request exists": {"text": "No such service request exists", "isAlreadyTranslated": false},
		"Raise Request": {"text": "Raise Request", "isAlreadyTranslated": false},
		"No such service request exists": {"text": "No such service request exists", "isAlreadyTranslated": false},
		"Please fill in the cancel reason before cancelling": {"text": "Please fill in the cancel reason before cancelling", "isAlreadyTranslated": false},



		"View Jobsheet": {"text": "View Jobsheet", "isAlreadyTranslated": false},
		"Add required documents in 'Request Documents' section below": {"text": "Add required documents in 'Request Documents' section below", "isAlreadyTranslated": false},
		"Add required documents in 'Request Documents' section below": {"text": "Add required documents in 'Request Documents' section below", "isAlreadyTranslated": false},
		"Customer Details": {"text": "Customer Details", "isAlreadyTranslated": false},
		"Name": {"text": "Name", "isAlreadyTranslated": false},
		"Mobile Number": {"text": "Mobile Number", "isAlreadyTranslated": false},
		"Alternate Mobile No": {"text": "Alternate Mobile No", "isAlreadyTranslated": false},
		"Email ID": {"text": "Email ID", "isAlreadyTranslated": false},
		"First Registered From": {"text": "First Registered From", "isAlreadyTranslated": false},
		"Address Details": {"text": "Address Details", "isAlreadyTranslated": false},
		"No saved Address": {"text": "No saved Address", "isAlreadyTranslated": false},
		"Edit": {"text": "Edit", "isAlreadyTranslated": false},
		"Home": {"text": "Home", "isAlreadyTranslated": false},
		"Office": {"text": "Office", "isAlreadyTranslated": false},
		"Device Details": {"text": "Device Details", "isAlreadyTranslated": false},
		"Device": {"text": "Device", "isAlreadyTranslated": false},
		"IMEI": {"text": "IMEI", "isAlreadyTranslated": false},
		"Serial Number": {"text": "Serial Number", "isAlreadyTranslated": false},
		"Storage Capacity": {"text": "Storage Capacity", "isAlreadyTranslated": false},
		"Warranty Status": {"text": "Warranty Status", "isAlreadyTranslated": false},
		"Out of Warranty": {"text": "Out of Warranty", "isAlreadyTranslated": false},
		"Under warranty": {"text": "Under warranty", "isAlreadyTranslated": false},
		"Warranty Till": {"text": "Warranty Till", "isAlreadyTranslated": false},
		"Insurance Status": {"text": "Insurance Status", "isAlreadyTranslated": false},
		"Insurer Name": {"text": "Insurer Name", "isAlreadyTranslated": false},
		"Broker Name": {"text": "Broker Name", "isAlreadyTranslated": false},
		"Plan Name": {"text": "Plan Name", "isAlreadyTranslated": false},
		"Distributor Name": {"text": "Distributor Name", "isAlreadyTranslated": false},
		"Retailer Name": {"text": "Retailer Name", "isAlreadyTranslated": false},
		"Type Of Program": {"text": "Type Of Program", "isAlreadyTranslated": false},
		"Validity Text": {"text": "Validity Text", "isAlreadyTranslated": false},
		"No. of Repairs Used": {"text": "No. of Repairs Used", "isAlreadyTranslated": false},
		"No. of Repairs Allowed": {"text": "No. of Repairs Allowed", "isAlreadyTranslated": false},
		"Year of Purchase": {"text": "Year of Purchase", "isAlreadyTranslated": false},
		"Date of Purchase": {"text": "Date of Purchase", "isAlreadyTranslated": false},
		"Purchase Price": {"text": "Purchase Price", "isAlreadyTranslated": false},
		"Is Warranty Applicable": {"text": "Is Warranty Applicable", "isAlreadyTranslated": false},
		"Reason": {"text": "Reason", "isAlreadyTranslated": false},
		"Registration Documents": {"text": "Registration Documents", "isAlreadyTranslated": false},
		"Device Added Date": {"text": "Device Added Date", "isAlreadyTranslated": false},
		"GSX Validation Status": {"text": "GSX Validation Status", "isAlreadyTranslated": false},
		"Validated": {"text": "Validated", "isAlreadyTranslated": false},
		"Not Validated": {"text": "Not Validated", "isAlreadyTranslated": false},
		"Verify": {"text": "Verify", "isAlreadyTranslated": false},
		"Please enter IMEI/serial number": {"text": "Please enter IMEI/serial number", "isAlreadyTranslated": false},
		"Please enter serial number": {"text": "Please enter serial number", "isAlreadyTranslated": false},
		"Please enter serial number": {"text": "Please enter serial number", "isAlreadyTranslated": false},
		"Please enter warranty till date": {"text": "Please enter warranty till date", "isAlreadyTranslated": false},
		"Please upload a warranty supporting document": {"text": "Please upload a warranty supporting document", "isAlreadyTranslated": false},
		"Please choose if the warranty is applicable or not": {"text": "Please choose if the warranty is applicable or not", "isAlreadyTranslated": false},
		"Please enter the reason for warranty not being applicable": {"text": "Please enter the reason for warranty not being applicable", "isAlreadyTranslated": false},
		"Please select a document type": {"text": "Please select a document type", "isAlreadyTranslated": false},
		"Request Documents": {"text": "Request Documents", "isAlreadyTranslated": false},
		"Remark": {"text": "Remark", "isAlreadyTranslated": false},
		"No documents uploaded": {"text": "No documents uploaded", "isAlreadyTranslated": false},
		"Add Pending Documents": {"text": "Add Pending Documents", "isAlreadyTranslated": false},
		"No Pending Document Specified": {"text": "No Pending Document Specified", "isAlreadyTranslated": false},
		"Add Additional Documents": {"text": "Add Additional Documents", "isAlreadyTranslated": false},
		"Please select a document type": {"text": "Please select a document type", "isAlreadyTranslated": false},
		"Something went wrong": {"text": "Something went wrong", "isAlreadyTranslated": false},
		"Please select a document": {"text": "Please select a document", "isAlreadyTranslated": false},
		"Please select a document type": {"text": "Please select a document type", "isAlreadyTranslated": false},
		"Please select a document": {"text": "Please select a document", "isAlreadyTranslated": false},
		"Issue Validation": {"text": "Issue Validation", "isAlreadyTranslated": false},
		"Consumer Raised Issues": {"text": "Consumer Raised Issues", "isAlreadyTranslated": false},
		"Validate": {"text": "Validate", "isAlreadyTranslated": false},
		"Customer did not add issues": {"text": "Customer did not add issues", "isAlreadyTranslated": false},
		"Add New Issues": {"text": "Add New Issues", "isAlreadyTranslated": false},
		"No issues added": {"text": "No issues added", "isAlreadyTranslated": false},
		"Choose from dropdown list only": {"text": "Choose from dropdown list only", "isAlreadyTranslated": false},
		"You have already added this issue": {"text": "You have already added this issue", "isAlreadyTranslated": false},
		"Estimation Details": {"text": "Estimation Details", "isAlreadyTranslated": false},
		"Total Amount": {"text": "Total Amount", "isAlreadyTranslated": false},
		"Total Payable": {"text": "Total Payable", "isAlreadyTranslated": false},
		"Covered Value": {"text": "Covered Value", "isAlreadyTranslated": false},
		"Send For Approval": {"text": "Send For Approval", "isAlreadyTranslated": false},
		"Advance cannot be greater than": {"text": "Advance cannot be greater than", "isAlreadyTranslated": false},
		"Discount cannot be greater than": {"text": "Discount cannot be greater than", "isAlreadyTranslated": false},
		"Current Status": {"text": "Current Status", "isAlreadyTranslated": false},
		"Service Type": {"text": "Service Type", "isAlreadyTranslated": false},
		"Schedule Date": {"text": "Schedule Date", "isAlreadyTranslated": false},
		"Reassigned": {"text": "Reassigned", "isAlreadyTranslated": false},
		"Pickup Address": {"text": "Pickup Address", "isAlreadyTranslated": false},
		"Address": {"text": "Address", "isAlreadyTranslated": false},
		"Landmark": {"text": "Landmark", "isAlreadyTranslated": false},
		"Zipcode": {"text": "Zipcode", "isAlreadyTranslated": false},
		"Under Warranty": {"text": "Under Warranty", "isAlreadyTranslated": false},
		"Warranty Applicable": {"text": "Warranty Applicable", "isAlreadyTranslated": false},
		"Issue description": {"text": "Issue description", "isAlreadyTranslated": false},
		"Customer's Version": {"text": "Customer's Version", "isAlreadyTranslated": false},
		"Telecon Version": {"text": "Telecon Version", "isAlreadyTranslated": false},
		"Green Rewards Chosen": {"text": "Green Rewards Chosen", "isAlreadyTranslated": false},
		"Recycle Certificate": {"text": "Recycle Certificate", "isAlreadyTranslated": false},
		"Logistics - From Customer\'s Address To Servify Hub": {"text": "Logistics - From Customer\'s Address To Servify Hub", "isAlreadyTranslated": false},
		"Logistics - From Customer\'s Address To Service Center": {"text": "Logistics - From Customer\'s Address To Service Center", "isAlreadyTranslated": false},
		"Logistics - From Drop-Off Center to Servify Hub": {"text": "Logistics - From Drop-Off Center to Servify Hub", "isAlreadyTranslated": false},
		"Logistics - From Servify Hub to Recycle Hub": {"text": "Logistics - From Servify Hub to Recycle Hub", "isAlreadyTranslated": false},
		"Logistics - Return to Customer": {"text": "Logistics - Return to Customer", "isAlreadyTranslated": false},
		"Verification Code": {"text": "Verification Code", "isAlreadyTranslated": false},
		"Waybill Number": {"text": "Waybill Number", "isAlreadyTranslated": false},
		"Pick Up Confirmation Number": {"text": "Pick Up Confirmation Number", "isAlreadyTranslated": false},
		"Location": {"text": "Location", "isAlreadyTranslated": false},
		"Total Amount": {"text": "Total Amount", "isAlreadyTranslated": false},
		"Pickup Charge": {"text": "Pickup Charge", "isAlreadyTranslated": false},
		"Paid": {"text": "Paid", "isAlreadyTranslated": false},
		"Payable": {"text": "Payable", "isAlreadyTranslated": false},
		"Loss DateTime": {"text": "Loss DateTime", "isAlreadyTranslated": false},
		"Damage Type": {"text": "Damage Type", "isAlreadyTranslated": false},
		"Loss Description": {"text": "Loss Description", "isAlreadyTranslated": false},
		"Device switching on": {"text": "Device switching on", "isAlreadyTranslated": false},
		"Place of Damage": {"text": "Place of Damage", "isAlreadyTranslated": false},
		"Green Rewards": {"text": "Green Rewards", "isAlreadyTranslated": false},
		"Please enter issue description": {"text": "Please enter issue description", "isAlreadyTranslated": false},
		"Communication History": {"text": "Communication History", "isAlreadyTranslated": false},
		"Request History": {"text": "Request History", "isAlreadyTranslated": false},
		"Waiting for Assignment": {"text": "Waiting for Assignment", "isAlreadyTranslated": false},
		"Document pending": {"text": "Document pending", "isAlreadyTranslated": false},
		"BER confirmed": {"text": "BER confirmed", "isAlreadyTranslated": false},
		"BER approved": {"text": "BER approved", "isAlreadyTranslated": false},
		"BER identified": {"text": "BER identified", "isAlreadyTranslated": false},
		"Claim estimation generated": {"text": "Claim estimation generated", "isAlreadyTranslated": false},
		"Device received": {"text": "Device received", "isAlreadyTranslated": false},
		"Pickup Request Accepted": {"text": "Pickup Request Accepted", "isAlreadyTranslated": false},
		"In-principle approved": {"text": "In-principle approved", "isAlreadyTranslated": false},
		"Claim initiated": {"text": "Claim initiated", "isAlreadyTranslated": false},
		"Claim raised": {"text": "Claim raised", "isAlreadyTranslated": false},
		"We have received your claim request. Our representative will connect with you within the next 1-2 business days to process it further": {"text": "We have received your claim request. Our representative will connect with you within the next 1-2 business days to process it further", "isAlreadyTranslated": false},
		"Device has been received by the service centre and is currently being diagnosed.": {"text": "Device has been received by the service centre and is currently being diagnosed.", "isAlreadyTranslated": false},
		"Looks like the repair cost is more than the depreciated value of your device. Please select the option you would like to proceed with.": {"text": "Looks like the repair cost is more than the depreciated value of your device. Please select the option you would like to proceed with.", "isAlreadyTranslated": false},


		"Carry in": {"text": "Carry in", "isAlreadyTranslated": false},
		"Assigned To": {"text": "Assigned To", "isAlreadyTranslated": false},
		"Assign": {"text": "Assign", "isAlreadyTranslated": false},
		"No service locations found": {"text": "No service locations found", "isAlreadyTranslated": false},
		"Select a service center": {"text": "Select a service center", "isAlreadyTranslated": false},
		"Service Engineer": {"text": "Service Engineer", "isAlreadyTranslated": false},
		"No engineers found": {"text": "No engineers found", "isAlreadyTranslated": false},
		"Select an engineer": {"text": "Select an engineer", "isAlreadyTranslated": false},
		"Request is being processed": {"text": "Request is being processed", "isAlreadyTranslated": false},
		"ADD CHARGES": {"text": "ADD CHARGES", "isAlreadyTranslated": false},
		"Amount": {"text": "Amount", "isAlreadyTranslated": false},
		"Other": {"text": "Other", "isAlreadyTranslated": false},


		"Initiate Claim": {"text": " Initiate Claim", "isAlreadyTranslated": false},
		"Claim invalid": {"text": "Claim invalid", "isAlreadyTranslated": false},
		"Attempt Call": {"text": "Attempt Call", "isAlreadyTranslated": false},
		"Add Device Date of Purchase and Device Purchase Cost in 'Device Details' below": {"text": "Add Device Date of Purchase and Device Purchase Cost in 'Device Details' below", "isAlreadyTranslated": false},
		"Ask for Documents Pending": {"text": "Ask for Documents Pending", "isAlreadyTranslated": false},
		"Enter type of document to add": {"text": "Enter type of document to add", "isAlreadyTranslated": false},
		"Enter Call Details": {"text": "Enter Call Details", "isAlreadyTranslated": false},

		"Claim": {"text": "Claim", "isAlreadyTranslated": false},
		"Request History": {"text": "Request History", "isAlreadyTranslated": false},
		"Please enter Claim intimation number": {"text": "Please enter Claim intimation number", "isAlreadyTranslated": false},
		"Please enter Claim intimation number before submitting": {"text": "Please enter Claim intimation number before submitting", "isAlreadyTranslated": false},
		"Download requests in csv format": {"text": "Download requests in csv format", "isAlreadyTranslated": false},

		"Data displayed": {"text": "Data displayed", "isAlreadyTranslated": false},
		"from": {"text": "from", "isAlreadyTranslated": false},
		"till": {"text": "till", "isAlreadyTranslated": false},
		"Reject Claim": {"text": "Reject Claim", "isAlreadyTranslated": false},
		"Please upload a clear copy of cancelled cheque with the claimant (your) name on it.": {"text": "Please upload a clear copy of cancelled cheque with the claimant (your) name on it.", "isAlreadyTranslated": false},

		"Customer Mobile number": {"text": "Customer Mobile number", "isAlreadyTranslated": false},
		"TRACK EXTERNAL REQUEST": {"text": "TRACK EXTERNAL REQUEST", "isAlreadyTranslated": false},

		"Oops... Something went wrong!": {"text": "Oops... Something went wrong!", "isAlreadyTranslated": false},
		"Edit": {"text": "Edit", "isAlreadyTranslated": false},
		"Add": {"text": "Add", "isAlreadyTranslated": false},
		"an address": {"text": "an address", "isAlreadyTranslated": false},
		"Landmark": {"text": "Landmark", "isAlreadyTranslated": false},
		"Address": {"text": "Address", "isAlreadyTranslated": false},
		"Pincode": {"text": "Pincode", "isAlreadyTranslated": false},
		"Characters must be numbers": {"text": "Characters must be numbers", "isAlreadyTranslated": false},
		"Clear": {"text": "Clear", "isAlreadyTranslated": false},
		"Cancel": {"text": "Cancel", "isAlreadyTranslated": false},
		"Save": {"text": "Save", "isAlreadyTranslated": false},
		"Please enter Landmark": {"text": "Please enter Landmark", "isAlreadyTranslated": false},
		"Please enter Customer\'s address": {"text": "Please enter Customer\'s address", "isAlreadyTranslated": false},
		"Please enter pincode": {"text": "Please enter pincode", "isAlreadyTranslated": false},
		"Enter correct Pincode": {"text": "Enter correct Pincode", "isAlreadyTranslated": false},
		"Address updated successfully": {"text": "Address updated successfully", "isAlreadyTranslated": false},
		"Home": {"text": "Home", "isAlreadyTranslated": false},
		"Office": {"text": "Office", "isAlreadyTranslated": false},
		"Other": {"text": "Other", "isAlreadyTranslated": false},

		"Documents Pending": {"text": "Documents Pending", "isAlreadyTranslated": false},
		"Date and Time of loss/damage": {"text": "Date and Time of loss/damage", "isAlreadyTranslated": false},
		"Type of damage": {"text": "Type of damage", "isAlreadyTranslated": false},
		"Description of loss/damage": {"text": "Description of loss/damage", "isAlreadyTranslated": false},
		"Please enter UTR Number": {"text": "Please enter UTR Number", "isAlreadyTranslated": false},
		"Payment Date": {"text": "Payment Date", "isAlreadyTranslated": false},
		"UTR Number": {"text": "UTR Number", "isAlreadyTranslated": false},
		"Service Charge": {"text": "Service Charge", "isAlreadyTranslated": false},
		"SGST": {"text": "SGST", "isAlreadyTranslated": false},
		"CGST": {"text": "CGST", "isAlreadyTranslated": false},
		"TESTING": {"text": "TESTING", "isAlreadyTranslated": false},
		"Mandatory deductible": {"text": "Mandatory deductible", "isAlreadyTranslated": false},
		"Mandatorydeductible": {"text": "Mandatorydeductible", "isAlreadyTranslated": false},
		"Admin fees": {"text": "Admin fees", "isAlreadyTranslated": false},
		"Claim amount": {"text": "Claim amount", "isAlreadyTranslated": false},
		"Adminfees": {"text": "Adminfees", "isAlreadyTranslated": false},
		"Advance payment": {"text": "Advance payment", "isAlreadyTranslated": false},
		"Advance": {"text": "Advance", "isAlreadyTranslated": false},

		"Back to all requests": {"text": "Back to all requests", "isAlreadyTranslated": false},
		"Alternate Mobile Number": {"text": "Alternate Mobile Number", "isAlreadyTranslated": false},
		"Please enter correct mobile Number": {"text": "Please enter correct mobile Number", "isAlreadyTranslated": false},
		"Detail Description of Damage": {"text": "Detail Description of Damage", "isAlreadyTranslated": false},
		"Please Enter Minimum 75 Characters": {"text": "Please Enter Minimum 75 Characters", "isAlreadyTranslated": false},
		"Submit": {"text": "Submit", "isAlreadyTranslated": false},
		"Add an address": {"text": "Add an address", "isAlreadyTranslated": false},
		"Select an Address": {"text": "Select an Address", "isAlreadyTranslated": false},
		"Add New Address": {"text": "Add New Address", "isAlreadyTranslated": false},
		"No addresses found": {"text": "No addresses found", "isAlreadyTranslated": false},
		"Select a service location": {"text": "Select a service location", "isAlreadyTranslated": false},
		"Oops! There are no verified service centres around": {"text": "Oops! There are no verified service centres around", "isAlreadyTranslated": false},
		"Service": {"text": "Service", "isAlreadyTranslated": false},
		"Contact": {"text": "Contact", "isAlreadyTranslated": false},
		"Brand authorised": {"text": "Brand authorised", "isAlreadyTranslated": false},
		"Distance from user": {"text": "Distance from user", "isAlreadyTranslated": false},
		"There is no service for this address at this moment": {"text": "There is no service for this address at this moment", "isAlreadyTranslated": false},
		"Select a Date": {"text": "Select a Date", "isAlreadyTranslated": false},
		"Select a Slot": {"text": "Select a Slot", "isAlreadyTranslated": false},
		"No Slots Available": {"text": "No Slots Available", "isAlreadyTranslated": false},
		"Select issues": {"text": "Select issues", "isAlreadyTranslated": false},
		"Cannot select more than": {"text": "Cannot select more than", "isAlreadyTranslated": false},
		"issues": {"text": "issues", "isAlreadyTranslated": false},
		"Select a Reward": {"text": "Select a Reward", "isAlreadyTranslated": false},
		"Select Logistics Partner": {"text": "Select Logistics Partner", "isAlreadyTranslated": false},
		"Confirm": {"text": "Confirm", "isAlreadyTranslated": false},

		"Full name": {"text": "Full name", "isAlreadyTranslated": false},
		"Add a Product": {"text": "Add a Product", "isAlreadyTranslated": false},
		"No servicable consumer product found": {"text": "No servicable consumer product found", "isAlreadyTranslated": false},
		"Select a Subcategory": {"text": "Select a Subcategory", "isAlreadyTranslated": false},
		"Select a Brand": {"text": "Select a Brand", "isAlreadyTranslated": false},
		"Characters must be alphabets": {"text": "Characters must be alphabets", "isAlreadyTranslated": false},
		"Search brands": {"text": "Search brands", "isAlreadyTranslated": false},
		"Select a Product": {"text": "Select a Product", "isAlreadyTranslated": false},
		"Add Selected Product": {"text": "Add Selected Product", "isAlreadyTranslated": false},
		"Device In Warranty": {"text": "Device In Warranty", "isAlreadyTranslated": false},
		"Is Warranty Applicable for this request": {"text": "Is Warranty Applicable for this request", "isAlreadyTranslated": false},
		"Select a Service Mode": {"text": "Select a Service Mode", "isAlreadyTranslated": false},
		"DAMAGE DETAILS": {"text": "DAMAGE DETAILS", "isAlreadyTranslated": false},
		"Date of Damage": {"text": "Date of Damage", "isAlreadyTranslated": false},
		"Please Select Damage Date": {"text": "Please Select Damage Date", "isAlreadyTranslated": false},
		"Time of Damage": {"text": "Time of Damage", "isAlreadyTranslated": false},
		"Select type of damage": {"text": "Select type of damage", "isAlreadyTranslated": false},
		"Is your device switching ON": {"text": "Is your device switching ON", "isAlreadyTranslated": false},
		"Select a Consumer Product": {"text": "Select a Consumer Product", "isAlreadyTranslated": false},

		"Characters must be numeric": {"text": "Characters must be numeric", "isAlreadyTranslated": false},
		"Characters must be alphabets (at least 2 alphabets are mandatory)": {"text": "Characters must be alphabets (at least 2 alphabets are mandatory)", "isAlreadyTranslated": false},
		"Invalid email id": {"text": "Invalid email id", "isAlreadyTranslated": false},
		"Search products": {"text": "Search products", "isAlreadyTranslated": false},
		"products under service": {"text": "products under service", "isAlreadyTranslated": false},
		"Characters must be alphanumeric": {"text": "Characters must be alphanumeric", "isAlreadyTranslated": false},
		"Please Select Time": {"text": "Please Select Time", "isAlreadyTranslated": false},
		"Please Select Damage type": {"text": "Please Select Damage type", "isAlreadyTranslated": false},
		"Please Enter Place of Damage": {"text": "Please Enter Place of Damage", "isAlreadyTranslated": false},
		"Please Select an option": {"text": "Please Select an option", "isAlreadyTranslated": false},
		"Min 75 Characters": {"text": "Min 75 Characters", "isAlreadyTranslated": false},

		"Consumer Name": {"text": "Consumer Name", "isAlreadyTranslated": false},
		"Contact No": {"text": "Contact No", "isAlreadyTranslated": false},
		"Logistics Provider": {"text": "Logistics Provider", "isAlreadyTranslated": false},
		"Registration Source": {"text": "Registration Source", "isAlreadyTranslated": false},
		"Customer City": {"text": "Customer City", "isAlreadyTranslated": false},
		"Call Type": {"text": "Call Type", "isAlreadyTranslated": false},
		"Re-apply": {"text": "Re-apply", "isAlreadyTranslated": false},
		"something went wrong": {"text": "something went wrong", "isAlreadyTranslated": false},
		"Today": {"text": "Today", "isAlreadyTranslated": false},
		"Yesterday": {"text": "Yesterday", "isAlreadyTranslated": false},
		"Last 7 Days": {"text": "Last 7 Days", "isAlreadyTranslated": false},
		"Last 30 Days": {"text": "Last 30 Days", "isAlreadyTranslated": false},
		"This Month": {"text": "This Month", "isAlreadyTranslated": false},
		"Last Month": {"text": "Last Month", "isAlreadyTranslated": false},
		"Custom Range": {"text": "Custom Range", "isAlreadyTranslated": false},

		"Add Device Date of Purchase and Device Purchase Cost in 'Device Details' below": {"text": "Add Device Date of Purchase and Device Purchase Cost in 'Device Details' below", "isAlreadyTranslated": false},
		"Add a part charge in 'Estimation Details' below to generate estimation": {"text": "Add a part charge in 'Estimation Details' below to generate estimation", "isAlreadyTranslated": false},
		"Enter Reference IDs": {"text": "Enter Reference IDs", "isAlreadyTranslated": false},
		"Current Request Reference ID": {"text": "Current Request Reference ID", "isAlreadyTranslated": false},
		"Pick Up Request Reference ID": {"text": "Pick Up Request Reference ID", "isAlreadyTranslated": false},
		"Merge": {"text": "Merge", "isAlreadyTranslated": false},

		"Convert request":{"text":"Convert request" , "isAlreadyTranslated" : false},
		"No Data Available To Plot The Graph": {"text": "No Data Available To Plot The Graph", "isAlreadyTranslated": false},

		"Mobile number": {"text": "Mobile number", "isAlreadyTranslated": false},
		"Characters must be numeric.": {"text": "Characters must be numeric.", "isAlreadyTranslated": false},
		"Search consumer products": {"text": "Search consumer products", "isAlreadyTranslated": false},
		"Search subcategories": {"text": "Search subcategories", "isAlreadyTranslated": false},
		"OR": {"text": "OR", "isAlreadyTranslated": false},
		"Enter IMEI or Serial Number": {"text": "Enter IMEI or Serial Number", "isAlreadyTranslated": false},
		"Serial number": {"text": "Serial number", "isAlreadyTranslated": false},
		"Selected Product": {"text": "Selected Product", "isAlreadyTranslated": false},
		"Serial number": {"text": "Serial number", "isAlreadyTranslated": false},
		"IMEI Number": {"text": "IMEI Number", "isAlreadyTranslated": false},
		"Contract Type": {"text": "Contract Type", "isAlreadyTranslated": false},
		"Warranty": {"text": "Warranty", "isAlreadyTranslated": false},
		"Enter Serial Number": {"text": "Enter Serial Number", "isAlreadyTranslated": false},
		"Select Customer Type": {"text": "Select Customer Type", "isAlreadyTranslated": false},
		"Walk-in Customer": {"text": "Walk-in Customer", "isAlreadyTranslated": false},
		"Future Appointment": {"text": "Future Appointment", "isAlreadyTranslated": false},
		"Oops! There are no verified service centres around.": {"text": "Oops! There are no verified service centres around.", "isAlreadyTranslated": false},
		"Search issues": {"text": "Search issues", "isAlreadyTranslated": false},
		"Mobile": {"text": "Mobile", "isAlreadyTranslated": false},
		"Tablet": {"text": "Tablet", "isAlreadyTranslated": false},
		"Computer": {"text": "Computer", "isAlreadyTranslated": false},
		"Audio Product": {"text": "Audio Product", "isAlreadyTranslated": false},
		"Streaming Device": {"text": "Streaming Device", "isAlreadyTranslated": false},
		"Smart Watch": {"text": "Smart Watch", "isAlreadyTranslated": false},
		"Serial number is mandatory for Apple Products.\nPlease enter valid serial number": {"text": "Serial number is mandatory for Apple Products.\nPlease enter valid serial number", "isAlreadyTranslated": false},
		"Pickup Drop By": {"text": "Pickup Drop By", "isAlreadyTranslated": false},
		"Requires customer to print documents and safely pack the device.": {"text": "Requires customer to print documents and safely pack the device.", "isAlreadyTranslated": false},





		"No parts requested": {"text": "No parts requested", "isAlreadyTranslated": false},
		"Part Name": {"text": "Part Name", "isAlreadyTranslated": false},
		"Part Code": {"text": "Part Code", "isAlreadyTranslated": false},
		"Qty": {"text": "Qty", "isAlreadyTranslated": false},
		"Available Quantity": {"text": "Available Quantity", "isAlreadyTranslated": false},
		"Requested By": {"text": "Requested By", "isAlreadyTranslated": false},
		"Issued By": {"text": "Issued By", "isAlreadyTranslated": false},
		"Enter Information for the parts you are issuing": {"text": "Enter Information for the parts you are issuing", "isAlreadyTranslated": false},
		"External Reference ID": {"text": "External Reference ID", "isAlreadyTranslated": false},
		"Enter Reference ID": {"text": "Enter Reference ID", "isAlreadyTranslated": false},

		"Handover Device": {"text": "Handover Device", "isAlreadyTranslated": false},
		"Not applicable": {"text": "Not applicable", "isAlreadyTranslated": false},
		"Pending for assignment": {"text": "Pending for assignment", "isAlreadyTranslated": false},
		"Device handover details": {"text": "Device handover details", "isAlreadyTranslated": false},
		"Diagnosis details": {"text": "Diagnosis details", "isAlreadyTranslated": false},
		"Diagnosis Steps": {"text": "Diagnosis Steps", "isAlreadyTranslated": false},
		"Engineer notes": {"text": "Engineer notes", "isAlreadyTranslated": false},
		"Device Transaction Details": {"text": "Device Transaction Details", "isAlreadyTranslated": false},
		"Upload Device Pictures and Complete Checklist": {"text": "Upload Device Pictures and Complete Checklist", "isAlreadyTranslated": false},
		"Upload Device Pictures": {"text": "Upload Device Pictures", "isAlreadyTranslated": false},
		"Select OS Version": {"text": "Select OS Version", "isAlreadyTranslated": false},
		"Select Picture Type": {"text": "Select Picture Type", "isAlreadyTranslated": false},
		"No pictures added.": {"text": "No pictures added.", "isAlreadyTranslated": false},
		"Complete Checklist": {"text": "Complete Checklist", "isAlreadyTranslated": false},
		"Accessories Submitted": {"text": "Accessories Submitted", "isAlreadyTranslated": false},
		"Checklist": {"text": "Checklist", "isAlreadyTranslated": false},
		"Miscellaneous Checklist": {"text": "Miscellaneous Checklist", "isAlreadyTranslated": false},
		"Complete": {"text": "Complete", "isAlreadyTranslated": false},
		"Checklist": {"text": "Checklist", "isAlreadyTranslated": false},
		"Select All": {"text": "Select All", "isAlreadyTranslated": false},
		"Raise in": {"text": "Raise in", "isAlreadyTranslated": false},
		"Request in": {"text": "Request in", "isAlreadyTranslated": false},
		"Please select Repair Type": {"text": "Please select Repair Type", "isAlreadyTranslated": false},
		"Please select Classification Type": {"text": "Please select Classification Type", "isAlreadyTranslated": false},
		"Repair Checklist": {"text": "Repair Checklist", "isAlreadyTranslated": false},
		"Raise": {"text": "Raise", "isAlreadyTranslated": false},
		"Request": {"text": "Request", "isAlreadyTranslated": false},
		"Diagnostics Result": {"text": "Diagnostics Result", "isAlreadyTranslated": false},
		"Please mention the incident in not less than 75 characters on how, when & where the damage happened": {"text": "Please mention the incident in not less than 75 characters on how, when & where the damage happened", "isAlreadyTranslated": false},
		"Parts transaction details": {"text": "Parts transaction details", "isAlreadyTranslated": false},
		"Handing over the parts to store": {"text": "Handing over the parts to store", "isAlreadyTranslated": false},
		"Device Transaction Details": {"text": "Device Transaction Details", "isAlreadyTranslated": false},
		"Handing over the damaged device to store": {"text": "Handing over the damaged device to store", "isAlreadyTranslated": false},
		"Details of the New Replaced Device": {"text": "Details of the New Replaced Device", "isAlreadyTranslated": false},
		"Mode of payment": {"text": "Mode of payment", "isAlreadyTranslated": false},
		"Please select mode of payment": {"text": "Please select mode of payment", "isAlreadyTranslated": false},
		"Cash": {"text": "Cash", "isAlreadyTranslated": false},
		"Card": {"text": "Card", "isAlreadyTranslated": false},



		"Accessories will not be returned.": {"text": "Accessories will not be returned.", "isAlreadyTranslated": false},
		"Documents are yet to be uploaded.": {"text": "Documents are yet to be uploaded.", "isAlreadyTranslated": false},
		"View Invoice": {"text": "View Invoice", "isAlreadyTranslated": false},
		"View Parts Document": {"text": "View Parts Document", "isAlreadyTranslated": false},
		"View DOA Certificate": {"text": "View DOA Certificate", "isAlreadyTranslated": false},
		"View Advance Receipt": {"text": "View Advance Receipt", "isAlreadyTranslated": false},
		"Report Issues": {"text": "Report Issues", "isAlreadyTranslated": false},
		"Dead on Arrival": {"text": "Dead on Arrival", "isAlreadyTranslated": false},



		"Assignment": {"text": "Assignment", "isAlreadyTranslated": false},
		"Re-assign": {"text": "Re-assign", "isAlreadyTranslated": false},
		"Add Required Parts": {"text": "Add Required Parts", "isAlreadyTranslated": false},
		"Price": {"text": "Price", "isAlreadyTranslated": false},
		"Tax Slab": {"text": "Tax Slab", "isAlreadyTranslated": false},
		"Is Part Abused": {"text": "Is Part Abused", "isAlreadyTranslated": false},
		"Is Returnable Damage": {"text": "Is Returnable Damage", "isAlreadyTranslated": false},
		"Part Warranty": {"text": "Part Warranty", "isAlreadyTranslated": false},
		"Part Price": {"text": "Part Price", "isAlreadyTranslated": false},
		"Available Qty": {"text": "Available Qty", "isAlreadyTranslated": false},
		"Action": {"text": "Action", "isAlreadyTranslated": false},
		"Report Issues to": {"text": "Report Issues to", "isAlreadyTranslated": false},
		"Receive & Inspect Device": {"text": "Receive & Inspect Device", "isAlreadyTranslated": false},
		"HSN Code": {"text": "HSN Code", "isAlreadyTranslated": false},
		"Please select a picture type from dropdown": {"text": "Please select a picture type from dropdown", "isAlreadyTranslated": false},

		"Okay": {"text": "Okay", "isAlreadyTranslated": false},
		"Back": {"text": "Back", "isAlreadyTranslated": false},
		"Re-assign": {"text": "Re-assign", "isAlreadyTranslated": false},
		"Waybill": {"text": "Waybill", "isAlreadyTranslated": false},
		"Vendor": {"text": "Vendor", "isAlreadyTranslated": false},
		"Pick Up": {"text": "Pick Up", "isAlreadyTranslated": false},
		"an address": {"text": "an address", "isAlreadyTranslated": false},
		"Is Covered By ACPlus": {"text": "Is Covered By ACPlus", "isAlreadyTranslated": false},

		"Re-assign Service Engineer": {"text": "Re-assign Service Engineer", "isAlreadyTranslated": false},
		"Device Received & Inspection in Progress": {"text": "Device Received & Inspection in Progress", "isAlreadyTranslated": false},
		"Service accepted": {"text": "Service accepted", "isAlreadyTranslated": false},

		"COMPTIA Group": {"text": "COMPTIA Group", "isAlreadyTranslated": false},
		"COMPTIA Code": {"text": "COMPTIA Code", "isAlreadyTranslated": false},
		"COMPTIA Modifier": {"text": "COMPTIA Modifier", "isAlreadyTranslated": false},


		"Send Issues to": {"text": "Send Issues to", "isAlreadyTranslated": false},
		"Engineer validated issues": {"text": "Engineer validated issues", "isAlreadyTranslated": false},
		"Part(s) request under review by storekeeper.": {"text": "Part(s) request under review by storekeeper.", "isAlreadyTranslated": false},
		"ChargeCode": {"text": "ChargeCode", "isAlreadyTranslated": false},
		"TaxSlab": {"text": "TaxSlab", "isAlreadyTranslated": false},
		"ChargeCode": {"text": "ChargeCode", "isAlreadyTranslated": false},
		"Started On": {"text": "Started On", "isAlreadyTranslated": false},
		"Are you sure?": {"text": "Are you sure?", "isAlreadyTranslated": false},
		"End on": {"text": "End on", "isAlreadyTranslated": false},
		"Part name": {"text": "Part name", "isAlreadyTranslated": false},
		"Ref Id": {"text": "Ref Id", "isAlreadyTranslated": false},
		"Validation Status": {"text": "Validation Status", "isAlreadyTranslated": false},
		"Is warranty applicable": {"text": "Is warranty applicable", "isAlreadyTranslated": false},
		"Issue Code": {"text": "Issue Code", "isAlreadyTranslated": false},
		"Symptom Code": {"text": "Symptom Code", "isAlreadyTranslated": false},
		"Other Charge": {"text": "Other Charge", "isAlreadyTranslated": false},
		"Discount": {"text": "Discount", "isAlreadyTranslated": false},
		"Engineer completed the repair. Handed over the device to QC.": {"text": "Engineer completed the repair. Handed over the device to QC.", "isAlreadyTranslated": false},
		"Device handed over": {"text": "Device handed over", "isAlreadyTranslated": false},
		"Repair completed, QC pending": {"text": "Repair completed, QC pending", "isAlreadyTranslated": false},
		"Repair complete": {"text": "Repair complete", "isAlreadyTranslated": false},
		"Device details validated": {"text": "Device details validated", "isAlreadyTranslated": false},
		"Issues validated": {"text": "Issues validated", "isAlreadyTranslated": false},
		"Work in Progress with Engineer": {"text": "Work in Progress with Engineer", "isAlreadyTranslated": false},
		"Cannot be diagnosed": {"text": "Cannot be diagnosed", "isAlreadyTranslated": false},
		"Parts issued": {"text": "Parts issued", "isAlreadyTranslated": false},
		"Pending for acceptance": {"text": "Pending for acceptance", "isAlreadyTranslated": false},
		"Device accepted": {"text": "Device accepted", "isAlreadyTranslated": false},
		"Parts request": {"text": "Parts request", "isAlreadyTranslated": false},

		"Valid": {"text": "Valid", "isAlreadyTranslated": false},
		"Validity": {"text": "Validity", "isAlreadyTranslated": false},
		"Invalid": {"text": "Invalid", "isAlreadyTranslated": false},
		"Engineer Raised Issues": {"text": "Engineer Raised Issues", "isAlreadyTranslated": false},
		"Are you sure": {"text": "Are you sure", "isAlreadyTranslated": false},
		"Please specify remarks, if any": {"text": "Please specify remarks, if any", "isAlreadyTranslated": false},
		"This service is a Return without repair": {"text": "This service is a Return without repair", "isAlreadyTranslated": false},



			"Service cancel": {"text": "Service cancel", "isAlreadyTranslated": false},
			"Others": {"text": "Others", "isAlreadyTranslated": false},

			"Service completed and rated": {"text": "Service completed and rated", "isAlreadyTranslated": false},
			"Service completed": {"text": "Service completed", "isAlreadyTranslated": false},
			"Drop Request Created Post Repair": {"text": "Drop Request Created Post Repair", "isAlreadyTranslated": false},
			"Payment received": {"text": "Payment received", "isAlreadyTranslated": false},
			"Service invoice generated": {"text": "Service invoice generated", "isAlreadyTranslated": false},
			"Repair Completed, Ready To Inform The Customer": {"text": "Repair Completed, Ready To Inform The Customer", "isAlreadyTranslated": false},
			"Repair completed": {"text": "Repair completed", "isAlreadyTranslated": false},


			"WarrantyConcession": {"text": "WarrantyConcession", "isAlreadyTranslated": false},
			"Estimation generated": {"text": "Estimation generated", "isAlreadyTranslated": false},
			"Estimation approval": {"text": "Estimation approval", "isAlreadyTranslated": false},
			"Parts request accepted": {"text": "Parts request accepted", "isAlreadyTranslated": false},
			"Repair estimate approved": {"text": "Repair estimate approved", "isAlreadyTranslated": false},
			"Parts request accepted": {"text": "Parts request accepted", "isAlreadyTranslated": false},
			"Damaged Serial No": {"text": "Damaged Serial No", "isAlreadyTranslated": false},
			"Replaced Serial No": {"text": "Replaced Serial No", "isAlreadyTranslated": false},
			"Damaged IMEI": {"text": "Damaged IMEI", "isAlreadyTranslated": false},
			"Replaced IMEI": {"text": "Replaced IMEI", "isAlreadyTranslated": false},


			"Initiate logistics request": {"text": "Initiate logistics request", "isAlreadyTranslated": false},
			"Payment for the service has been received from the customer.": {"text": "Payment for the service has been received from the customer.", "isAlreadyTranslated": false},

			"Device dispatched": {"text": "Device dispatched", "isAlreadyTranslated": false},

			"BER payment completed": {"text": "BER payment completed", "isAlreadyTranslated": false},
			"BER payment initiated": {"text": "BER payment initiated", "isAlreadyTranslated": false},
			"BER device received": {"text": "BER device received", "isAlreadyTranslated": false},
			"BER accessories validated": {"text": "BER accessories validated", "isAlreadyTranslated": false},
			"BER accessories received": {"text": "BER accessories received", "isAlreadyTranslated": false},
			"BER documents validated": {"text": "BER documents validated", "isAlreadyTranslated": false},
			"Document verification pending": {"text": "Document verification pending", "isAlreadyTranslated": false},



			"Pickup rescheduled": {"text": "Pickup rescheduled", "isAlreadyTranslated": false},
			"Reschedule requested": {"text": "Reschedule requested", "isAlreadyTranslated": false},
			"Claim Pickup": {"text": "Claim Pickup", "isAlreadyTranslated": false},
			"Invalid IMEI or serial number": {"text": "Invalid IMEI or serial number", "isAlreadyTranslated": false},
			"No serial number for product": {"text": "No serial number for product", "isAlreadyTranslated": false},
			"Invalid product serial number": {"text": "Invalid product serial number", "isAlreadyTranslated": false},
			"Waiting for the Engineer to raise the request.": {"text": "Waiting for the Engineer to raise the request.", "isAlreadyTranslated": false},
			"getting": {"text": "getting", "isAlreadyTranslated": false},
			"Claim Carryin": {"text": "Claim Carryin", "isAlreadyTranslated": false},
			"Approved claim amount": {"text": "Approved claim amount", "isAlreadyTranslated": false},
			"Claim estimation approved": {"text": "Claim estimation approved", "isAlreadyTranslated": false},
			"Payment pending": {"text": "Payment pending", "isAlreadyTranslated": false},


			"Work in Progress with Engineer.": {"text": "Work in Progress with Engineer.", "isAlreadyTranslated": false},
			"Diagnosis completed": {"text": "Diagnosis completed", "isAlreadyTranslated": false},
			"Diagnosis initiated": {"text": "Diagnosis initiated", "isAlreadyTranslated": false},


			"To proceed, update Device Date of Purchase and Device Purchase Cost in 'Device Details' below.": {"text": "To proceed, update Device Date of Purchase and Device Purchase Cost in 'Device Details' below.", "isAlreadyTranslated": false},
			"Non-Connect Disposition:": {"text": "Non-Connect Disposition:", "isAlreadyTranslated": false},
			"Connect Disposition:": {"text": "Connect Disposition:", "isAlreadyTranslated": false},
			"Handing over the": {"text": "Handing over the", "isAlreadyTranslated": false},
			"full box unit": {"text": "full box unit", "isAlreadyTranslated": false},
			"parts": {"text": "parts", "isAlreadyTranslated": false},
			"to store.": {"text": "to store.", "isAlreadyTranslated": false},
			"Damaged Part Imei": {"text": "Damaged Part Imei", "isAlreadyTranslated": false},
			"Select Fault Code based on the validated issues.": {"text": "Select Fault Code based on the validated issues.", "isAlreadyTranslated": false},
			"Fault Code": {"text": "Fault Code", "isAlreadyTranslated": false},
			"Please check following items before completing the QC.": {"text": "Please check following items before completing the QC.", "isAlreadyTranslated": false},
			"QC Checklist": {"text": "QC Checklist", "isAlreadyTranslated": false},
			"Are you sure? Please specify remarks, if any...": {"text": "Are you sure? Please specify remarks, if any...", "isAlreadyTranslated": false},
			"Oops...": {"text": "Oops...", "isAlreadyTranslated": false},
			"You have already added this document type in the list of pending documents": {"text": "You have already added this document type in the list of pending documents", "isAlreadyTranslated": false},
			"Please enter type of damage": {"text": "Please enter type of damage", "isAlreadyTranslated": false},
			"Please enter atleast one document": {"text": "Please enter atleast one document", "isAlreadyTranslated": false},
			"Please enter description of loss/damage": {"text": "Please enter description of loss/damage", "isAlreadyTranslated": false},
			"Please enter Date and Time of loss/damage": {"text": "Please enter Date and Time of loss/damage", "isAlreadyTranslated": false},
			"Please enter Disposition reason": {"text": "Please enter Disposition reason", "isAlreadyTranslated": false},
			"Please enter your name": {"text": "Please enter your name", "isAlreadyTranslated": false},
			"Please enter the reason for call": {"text": "Please enter the reason for call", "isAlreadyTranslated": false},
			"Are you sure?": {"text": "Are you sure?", "isAlreadyTranslated": false},
			"Please fill in the reason for cancelling": {"text": "Please fill in the reason for cancelling", "isAlreadyTranslated": false},
			"Something went wrong": {"text": "Something went wrong", "isAlreadyTranslated": false},
			"Please specify the pending parts": {"text": "Please specify the pending parts", "isAlreadyTranslated": false},
			"Please fill in the reason for rejecting claim": {"text": "Please fill in the reason for rejecting claim", "isAlreadyTranslated": false},
			"Please fill in the cancel reason before rejecting": {"text": "Please fill in the cancel reason before rejecting", "isAlreadyTranslated": false},
			"Please fill in the reason for the claim being invalid": {"text": "Please fill in the reason for the claim being invalid", "isAlreadyTranslated": false},
			"Please fill in the reason for claim being invalid before rejecting": {"text": "Please fill in the reason for claim being invalid before rejecting", "isAlreadyTranslated": false},
			"You have not chosen any option to handover": {"text": "You have not chosen any option to handover", "isAlreadyTranslated": false},
			"Please enter approved claim amount": {"text": "Please enter approved claim amount", "isAlreadyTranslated": false},
			"Please add a picture.": {"text": "Please add a picture.", "isAlreadyTranslated": false},
			"Please specify remarks, if any...": {"text": "Please specify remarks, if any...", "isAlreadyTranslated": false},
			"Cannot proceed with this action without providing a reason.": {"text": "Cannot proceed with this action without providing a reason.", "isAlreadyTranslated": false},

			"Device transaction details": {"text": "Device transaction details", "isAlreadyTranslated": false},
			"Select Device": {"text": "Select Device", "isAlreadyTranslated": false},


			"Account Details": {"text": "Account Details", "isAlreadyTranslated": false},
			"The Green Reward cash amount will be credited to the account associated with this number": {"text": "The Green Reward cash amount will be credited to the account associated with this number", "isAlreadyTranslated": false},
			"Add Customer's Mobile Number": {"text": "Add Customer's Mobile Number", "isAlreadyTranslated": false},
			"Enter Mobile Number": {"text": "Enter Mobile Number", "isAlreadyTranslated": false},
			"Re - Enter Mobile Number": {"text": "Re - Enter Mobile Number", "isAlreadyTranslated": false},
			"Characters must be numbers": {"text": "Characters must be numbers", "isAlreadyTranslated": false},
			"Enter correct Mobile Number": {"text": "Enter correct Mobile Number", "isAlreadyTranslated": false},
			"The two numbers do not match": {"text": "The two numbers do not match", "isAlreadyTranslated": false},
			"Rewards updated successfully": {"text": "Rewards updated successfully", "isAlreadyTranslated": false},



			"Invalid Claim": {"text": "Invalid Claim", "isAlreadyTranslated": false},
			"Collect Advance": {"text": "Collect Advance", "isAlreadyTranslated": false},
			"Complete QC": {"text": "Complete QC", "isAlreadyTranslated": false},
			"Handover device": {"text": "Handover device", "isAlreadyTranslated": false},
			"Accept device": {"text": "Accept device", "isAlreadyTranslated": false},
			"Complete Job and Handover Device": {"text": "Complete Job and Handover Device", "isAlreadyTranslated": false},
			"Collect Device": {"text": "Collect Device", "isAlreadyTranslated": false},
			"Accept/Reject Job": {"text": "Accept/Reject Job", "isAlreadyTranslated": false},
			"Raise Claim": {"text": "Raise Claim", "isAlreadyTranslated": false},
			"Reject Estimation": {"text": "Reject Estimation", "isAlreadyTranslated": false},
			"Generate estimation": {"text": "Generate estimation", "isAlreadyTranslated": false},


			"In-principle Approval": {"text": "In-principle Approval", "isAlreadyTranslated": false},
			"Approve Claim Estimation": {"text": "Approve Claim Estimation", "isAlreadyTranslated": false},
			"Assigned": {"text": "Assigned", "isAlreadyTranslated": false},
			"Initiate Service": {"text": "Initiate Service", "isAlreadyTranslated": false},
			"Attempt": {"text": "Attempt", "isAlreadyTranslated": false},
			"Available": {"text": "Available", "isAlreadyTranslated": false},
			"Back to all consumers": {"text": "Back to all consumers", "isAlreadyTranslated": false},
			"Call attempted": {"text": "Call attempted", "isAlreadyTranslated": false},
			"External Reference ID has to be between 6 - 20 characters.": {"text": "External Reference ID has to be between 6 - 20 characters.", "isAlreadyTranslated": false},
			"External ReferenceID added successfully": {"text": "External ReferenceID added successfully", "isAlreadyTranslated": false},
			"Insurance team will reach out to you for further action.": {"text": "Insurance team will reach out to you for further action.", "isAlreadyTranslated": false},
			"Invoice": {"text": "Invoice", "isAlreadyTranslated": false},
			"ID Proof": {"text": "ID Proof", "isAlreadyTranslated": false},
			"NA": {"text": "NA", "isAlreadyTranslated": false},
			"No brands found": {"text": "No brands found", "isAlreadyTranslated": false},
			"No issues found": {"text": "No issues found", "isAlreadyTranslated": false},
			"No other requests found": {"text": "No other requests found", "isAlreadyTranslated": false},
			"No products found": {"text": "No products found", "isAlreadyTranslated": false},
			"No subcategories found": {"text": "No subcategories found", "isAlreadyTranslated": false},
			"No supported mode found": {"text": "No supported mode found", "isAlreadyTranslated": false},
			"No supported modes found": {"text": "No supported modes found", "isAlreadyTranslated": false},
			"Override only if customer instructs to": {"text": "Override only if customer instructs to", "isAlreadyTranslated": false},
			"Parts Received": {"text": "Parts Received", "isAlreadyTranslated": false},
			"Request Has Been Raised": {"text": "Request Has Been Raised", "isAlreadyTranslated": false},
			"Send for raising a request?": {"text": "Send for raising a request?", "isAlreadyTranslated": false},
			"Slot Time": {"text": "Slot Time", "isAlreadyTranslated": false},
			"Something went wrong while adding the product": {"text": "Something went wrong while adding the product", "isAlreadyTranslated": false},
			"Something went wrong while raising service request": {"text": "Something went wrong while raising service request", "isAlreadyTranslated": false},
			"Store Manager": {"text": "Store Manager", "isAlreadyTranslated": false},
			"Superadmin": {"text": "Superadmin", "isAlreadyTranslated": false},
			"Supervisor": {"text": "Supervisor", "isAlreadyTranslated": false},
			"Telecon Version:": {"text": "Telecon Version:", "isAlreadyTranslated": false},
			"The request was raised successfully": {"text": "The request was raised successfully", "isAlreadyTranslated": false},
			"There's a problem with this landmark, please select a different landmark": {"text": "There's a problem with this landmark, please select a different landmark", "isAlreadyTranslated": false},
			"Characters must be alphabets (at least 2 alphabets are mandatory)": {"text": "Characters must be alphabets (at least 2 alphabets are mandatory)", "isAlreadyTranslated": false},
			"Characters must be numeric.": {"text": "Characters must be numeric.", "isAlreadyTranslated": false},
			"Claim rejected": {"text": "Claim rejected", "isAlreadyTranslated": false},




			"characters entered.": {"text": "characters entered.", "isAlreadyTranslated": false},
			"Your GSX Request has been created": {"text": "Your GSX Request has been created", "isAlreadyTranslated": false},
			"Upload Inspection Documents": {"text": "Upload Inspection Documents", "isAlreadyTranslated": false},
			"Complete DOA": {"text": "Complete DOA", "isAlreadyTranslated": false},
			"QC Completed": {"text": "QC Completed", "isAlreadyTranslated": false},
			"Create GSX request": {"text": "Create GSX request", "isAlreadyTranslated": false},
			"DOA Checklist": {"text": "DOA Checklist", "isAlreadyTranslated": false},
			"Reject Parts Request": {"text": "Reject Parts Request", "isAlreadyTranslated": false},
			"Accept Parts Request": {"text": "Accept Parts Request", "isAlreadyTranslated": false},
			"Cannot Diagnose": {"text": "Cannot Diagnose", "isAlreadyTranslated": false},
			"Initiate Diagnosis": {"text": "Initiate Diagnosis", "isAlreadyTranslated": false},
			"Diagnosis Completed": {"text": "Diagnosis Completed", "isAlreadyTranslated": false},
			"Accept Device": {"text": "Accept Device", "isAlreadyTranslated": false},


			"Waiting for engineer to accept the job.": {"text": "Waiting for engineer to accept the job.", "isAlreadyTranslated": false},


			"Transfer Request": {"text": "Transfer Request", "isAlreadyTranslated": false},
			"Accept and Complete QC": {"text": "Accept and Complete QC", "isAlreadyTranslated": false},
			"Reject QC": {"text": "Reject QC", "isAlreadyTranslated": false},
			"Confirm BER": {"text": "Confirm BER", "isAlreadyTranslated": false},
			"Reject BER": {"text": "Reject BER", "isAlreadyTranslated": false},
			"Perform QC": {"text": "Perform QC", "isAlreadyTranslated": false},
			"Fault Codes": {"text": "Fault Codes", "isAlreadyTranslated": false},
			"Actions": {"text": "Actions", "isAlreadyTranslated": false},

			"Engineer Added Faults": {"text": "Engineer Added Faults", "isAlreadyTranslated": false},
			"Faults": {"text": "Faults", "isAlreadyTranslated": false},
			"Action Code": {"text": "Action Code", "isAlreadyTranslated": false},
			"N/A": {"text": "N/A", "isAlreadyTranslated": false},

			"Waiting for the Customer Care Executive to receive device and complete mandatory checklist tasks.": {"text": "Waiting for the Customer Care Executive to receive device and complete mandatory checklist tasks.", "isAlreadyTranslated": false},
			"Waiting for CC to receive device and complete mandatory checklist tasks.": {"text": "Waiting for CC to receive device and complete mandatory checklist tasks.", "isAlreadyTranslated": false},


			"Yes, the device is whitelisted. Please upload screenshot of the same": {"text": "Yes, the device is whitelisted. Please upload screenshot of the same", "isAlreadyTranslated": false},
			"No pictures added": {"text": "No pictures added", "isAlreadyTranslated": false},
			"No, the device is not whitelisted. Please cancel the request and return the device if you have it": {"text": "No, the device is not whitelisted. Please cancel the request and return the device if you have it", "isAlreadyTranslated": false},
			"Next": {"text": "Next", "isAlreadyTranslated": false},
			"Device Name": {"text": "Device Name", "isAlreadyTranslated": false},
			"ELS": {"text": "ELS", "isAlreadyTranslated": false},
			"QC": {"text": "QC", "isAlreadyTranslated": false},
			"Please select Coverage Option": {"text": "Please select Coverage Option", "isAlreadyTranslated": false},
			"DOA": {"text": "DOA", "isAlreadyTranslated": false},
			"Device Whitelisting Information": {"text": "Device Whitelisting Information", "isAlreadyTranslated": false},
			"Please select Coverage Option": {"text": "Please select Coverage Option", "isAlreadyTranslated": false},
			"KBB Serial Number": {"text": "KBB Serial Number", "isAlreadyTranslated": false},
			"Device Name": {"text": "Device Name", "isAlreadyTranslated": false},
			"Inspection Completed, Awaiting Engineer Assignment": {"text": "Inspection Completed, Awaiting Engineer Assignment", "isAlreadyTranslated": false},

			"Verification Complete": {"text": "Verification Complete", "isAlreadyTranslated": false},
			"Dropoff Request Registered": {"text": "Dropoff Request Registered", "isAlreadyTranslated": false},
			"Service initiated": {"text": "Service initiated", "isAlreadyTranslated": false},
			"Verification Complete": {"text": "Verification Complete", "isAlreadyTranslated": false},
			"Device dropped": {"text": "Device dropped", "isAlreadyTranslated": false},
			"Dropoff Request Registered": {"text": "Dropoff Request Registered", "isAlreadyTranslated": false},
			"Service initiated": {"text": "Service initiated", "isAlreadyTranslated": false},
			"Recycle Dropoff": {"text": "Recycle Dropoff", "isAlreadyTranslated": false},
			"Pledge request created": {"text": "Pledge request created", "isAlreadyTranslated": false},

			"QC completed": {"text": "QC completed", "isAlreadyTranslated": false},
			"sdv": {"text": "sdv", "isAlreadyTranslated": false},
			"Intimate customer": {"text": "Intimate customer", "isAlreadyTranslated": false},


			"Repair has been cancelled.": {"text": "Repair has been cancelled.", "isAlreadyTranslated": false},
			"BTB socket": {"text": "BTB socket", "isAlreadyTranslated": false},
			"SIM Card needle": {"text": "SIM Card needle", "isAlreadyTranslated": false},
			"SIM card holder EB101 grey aluminum alloy": {"text": "SIM card holder EB101 grey aluminum alloy", "isAlreadyTranslated": false},
			"Pending for Acceptance by Engineer": {"text": "Pending for Acceptance by Engineer", "isAlreadyTranslated": false},
			"microphone": {"text": "microphone", "isAlreadyTranslated": false},
			"for Token generated": {"text": "for Token generated", "isAlreadyTranslated": false},
			"Advance collected": {"text": "Advance collected", "isAlreadyTranslated": false},
			"pin added": {"text": "pin added", "isAlreadyTranslated": false},
			"Trade In Dropoff": {"text": "Trade In Dropoff", "isAlreadyTranslated": false},



			"No, the device is not whitelisted. Please return the device if you have it": {"text": "No, the device is not whitelisted. Please return the device if you have it", "isAlreadyTranslated": false},
			"Download Open/Inprogress requests.": {"text": "Download Open/Inprogress requests.", "isAlreadyTranslated": false},
			"Waiting for engineer to accept the job. (Eligible for DOA)": {"text": "Waiting for engineer to accept the job. (Eligible for DOA)", "isAlreadyTranslated": false},
			" (Eligible for DOA)": {"text": " (Eligible for DOA)", "isAlreadyTranslated": false},
			"Customer Care Executive will choose action for the device from Dead on Arrival section below.": {"text": "Customer Care Executive will choose action for the device from Dead on Arrival section below.", "isAlreadyTranslated": false},
			"Engineer will select the new device from Dead on Arrival section below": {"text": "Engineer will select the new device from Dead on Arrival section below", "isAlreadyTranslated": false},
			"Device repair has failed QC. Device is to be given back to the Engineer.": {"text": "Device repair has failed QC. Device is to be given back to the Engineer.", "isAlreadyTranslated": false},
			'Waiting for Estimation Approval. Click on "Send for approval" in "Parts and Estimation" section below.': {"text": 'Waiting for Estimation Approval. Click on "Send for approval" in "Parts and Estimation" section below.', "isAlreadyTranslated": false},
			'Waiting for customer approval of estimation or override estimation approval in "Parts and Estimation" section below.': {"text": 'Waiting for customer approval of estimation or override estimation approval in "Parts and Estimation" section below.', "isAlreadyTranslated": false},
			"Estimation has been rejected, parts may be added if required by the engineer.": {"text": "sdv", "isAlreadyTranslated": false},
			'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.': {"text": 'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.', "isAlreadyTranslated": false},
			'Waiting for customer approval of estimation or override estimation approval in "Parts and Estimation" section below.': {"text": 'Waiting for customer approval of estimation or override estimation approval in "Parts and Estimation" section below.', "isAlreadyTranslated": false},
			"Estimation has been rejected, parts may be added if required by the engineer.": {"text": "Estimation has been rejected, parts may be added if required by the engineer.", "isAlreadyTranslated": false},
			'Waiting for store to issue required device in "Dead on arrival" section below.': {"text": 'Waiting for store to issue required device in "Dead on arrival" section below.', "isAlreadyTranslated": false},
			'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.': {"text": 'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.', "isAlreadyTranslated": false},
			'Waiting for the Engineer to receive the "Replacement device" from store in "Dead on Arrival" section below.': {"text": 'Waiting for the Engineer to receive the "Replacement device" from store in "Dead on Arrival" section below.', "isAlreadyTranslated": false},
			'Waiting for Engineer to receive parts from store in "Parts Requested" section below.': {"text": 'Waiting for Engineer to receive parts from store in "Parts Requested" section below.', "isAlreadyTranslated": false},
			'Waiting for the Engineer to complete DOA by handing over the damaged device to Store': {"text": 'Waiting for the Engineer to complete DOA by handing over the damaged device to Store', "isAlreadyTranslated": false},
			"All parts received, repair is in progress.": {"text": "All parts received, repair is in progress.", "isAlreadyTranslated": false},
			"Engineer completed the repair.": {"text": "Engineer completed the repair.", "isAlreadyTranslated": false},
			"Service invoice has been generated, final payment can be received.": {"text": "Service invoice has been generated, final payment can be received.", "isAlreadyTranslated": false},
			"Waiting for the engineer to handover device to Store": {"text": "Waiting for the engineer to handover device to Store", "isAlreadyTranslated": false},
			"Waiting for the Store to handover device to Customer Care Executive": {"text": "Waiting for the Store to handover device to Customer Care Executive", "isAlreadyTranslated": false},
			"Waiting for the Customer Care Executive to complete the DOA": {"text": "Waiting for the Customer Care Executive to complete the DOA", "isAlreadyTranslated": false},
			"Please complete the service.": {"text": "Please complete the service.", "isAlreadyTranslated": false},
			"Waiting for the customer to rate the service": {"text": "Waiting for the customer to rate the service", "isAlreadyTranslated": false},
			"Intimate customer.": {"text": "Intimate customer.", "isAlreadyTranslated": false},
			"Logistics": {"text": "Logistics", "isAlreadyTranslated": false},
			"Send link": {"text": "Send link", "isAlreadyTranslated": false},
			"Other Image": {"text": "Other Image", "isAlreadyTranslated": false},
			"Item": {"text": "Item", "isAlreadyTranslated": false},
			"Transfer Details": {"text": "Transfer Details", "isAlreadyTranslated": false},
			"Schedule a Logistic Request": {"text": "Schedule a Logistic Request", "isAlreadyTranslated": false},

			"Forward": {"text": "Forward", "isAlreadyTranslated": false},
			"Reverse": {"text": "Reverse", "isAlreadyTranslated": false},
			"Send link": {"text": "Send link", "isAlreadyTranslated": false},
			"Other Image": {"text": "Other Image", "isAlreadyTranslated": false},
			"Transfer Details": {"text": "Transfer Details", "isAlreadyTranslated": false},
			"Track Logistics Requests": {"text": "Track Logistics Requests", "isAlreadyTranslated": false},
			"Pickup Pincode": {"text": "Pickup Pincode", "isAlreadyTranslated": false},
			"Recycle Request Schedule Date": {"text": "Recycle Request Schedule Date", "isAlreadyTranslated": false},
			"Recycle Request Created Date": {"text": "Recycle Request Created Date", "isAlreadyTranslated": false},
			"Schedule Logistics Request": {"text": "Schedule Logistics Request", "isAlreadyTranslated": false},
			"Hub's Address Details": {"text": "Hub's Address Details", "isAlreadyTranslated": false},
			"Now": {"text": "Now", "isAlreadyTranslated": false},
			"Later": {"text": "Later", "isAlreadyTranslated": false},
			"Recycle Hub Details": {"text": "Recycle Hub Details", "isAlreadyTranslated": false},
			"This request will be added to a queue. You can schedule a logistics request later.": {"text": "This request will be added to a queue. You can schedule a logistics request later.", "isAlreadyTranslated": false},
			"Request Added to Queue Successfully": {"text": "Request Added to Queue Successfully", "isAlreadyTranslated": false},
			"Create Bulk Requests": {"text": "Create Bulk Requests", "isAlreadyTranslated": false},
			"Logistics Status": {"text": "Logistics Status", "isAlreadyTranslated": false},
			"Drop Pincode": {"text": "Drop Pincode", "isAlreadyTranslated": false},
			"Logistic Request Created Date": {"text": "Logistic Request Created Date", "isAlreadyTranslated": false},
			"Logistic Vendor": {"text": "Logistic Vendor", "isAlreadyTranslated": false},
			"Bulk Logistic Reference ID": {"text": "Bulk Logistic Reference ID", "isAlreadyTranslated": false},
			"Not Covered": {"text": "Not Covered", "isAlreadyTranslated": false},

			"Queue": {"text": "Queue", "isAlreadyTranslated": false},
			"LOGISTICS REQUESTS": {"text": "LOGISTICS REQUESTS", "isAlreadyTranslated": false},


			"Work in progress": {"text": "Work in progress", "isAlreadyTranslated": false},
			"Recycle Pickup": {"text": "Recycle Pickup", "isAlreadyTranslated": false},
			"Recycle Request": {"text": "Recycle Request", "isAlreadyTranslated": false},
			"Beyond Economic Repair": {"text": "Beyond Economic Repair", "isAlreadyTranslated": false},
			"Device collected": {"text": "Device collected", "isAlreadyTranslated": false},
			"Green Reward Update": {"text": "Green Reward Update", "isAlreadyTranslated": false},
			"Vouchers generation pending": {"text": "Vouchers generation pending", "isAlreadyTranslated": false},

			"Recycle Request cancelled": {"text": "Recycle Request cancelled", "isAlreadyTranslated": false},
			"Recycle Completed": {"text": "Recycle Completed", "isAlreadyTranslated": false},
			"Service cancelled": {"text": "Service cancelled", "isAlreadyTranslated": false},
			"Repair cancel": {"text": "Repair cancel", "isAlreadyTranslated": false},
			"Vouchers generated": {"text": "Vouchers generated", "isAlreadyTranslated": false},
			"Initial Dismantling Completed": {"text": "Initial Dismantling Completed", "isAlreadyTranslated": false},
			"Device recycle initiated": {"text": "Device recycle initiated", "isAlreadyTranslated": false},


			"Claim withdrawn": {"text": "Claim withdrawn", "isAlreadyTranslated": false},

			"Pending Verification": {"text": "Pending Verification", "isAlreadyTranslated": false},
			"Screen damage": {"text": "Screen damage", "isAlreadyTranslated": false},

			"Print": {"text": "Print", "isAlreadyTranslated": false},
    		"Pupa Service Record": {"text": "Pupa Service Record", "isAlreadyTranslated": false},
    		"Service Center": {"text": "Service Center", "isAlreadyTranslated": false},
    		"No": {"text": "No", "isAlreadyTranslated": false},
    		"User Name": {"text": "User Name", "isAlreadyTranslated": false},
    		"Telephone": {"text": "Telephone", "isAlreadyTranslated": false},
    		"Mobile": {"text": "Mobile", "isAlreadyTranslated": false},
    		"Product Model": {"text": "Product Model", "isAlreadyTranslated": false},
    		"IMEI/Serial Number": {"text": "IMEI/Serial Number", "isAlreadyTranslated": false},
    		"Purchase Date": {"text": "Purchase Date", "isAlreadyTranslated": false},
    		"Carry-in Time": {"text": "Carry-in Time", "isAlreadyTranslated": false},
    		"NA": {"text": "NA", "isAlreadyTranslated": false},
    		"Key Account Name": {"text": "Key Account Name", "isAlreadyTranslated": false},
    		"Service Offering": {"text": "Service Offering", "isAlreadyTranslated": false},
    		"In warranty": {"text": "In warranty", "isAlreadyTranslated": false},
    		"Out of warranty": {"text": "Out of warranty", "isAlreadyTranslated": false},
    		"Problem Description by User": {"text": "Problem Description by User", "isAlreadyTranslated": false},
    		"Recover Method": {"text": "Recover Method", "isAlreadyTranslated": false},
    		"Parts replacement": {"text": "Parts replacement", "isAlreadyTranslated": false},
    		"Service": {"text": "Service", "isAlreadyTranslated": false},
    		"New IMEI/SN": {"text": "New IMEI/SN", "isAlreadyTranslated": false},
    		"Defective Model Name": {"text": "Defective Model Name", "isAlreadyTranslated": false},
    		"Model Number": {"text": "Model Number", "isAlreadyTranslated": false},
    		"Replacement Name": {"text": "Replacement Name", "isAlreadyTranslated": false},
    		"Model Number": {"text": "Model Number", "isAlreadyTranslated": false},
    		"Engineer Signature": {"text": "Engineer Signature", "isAlreadyTranslated": false},
    		"Contact No": {"text": "Contact No", "isAlreadyTranslated": false},
    		"Finish Date": {"text": "Finish Date", "isAlreadyTranslated": false},
    		"Are you satisfied with Pupa service experience": {"text": "Are you satisfied with Pupa service experience", "isAlreadyTranslated": false},
    		"Very Satisfied": {"text": "Very Satisfied", "isAlreadyTranslated": false},
    		"Satisfied": {"text": "Satisfied", "isAlreadyTranslated": false},
    		"Neutral": {"text": "Neutral", "isAlreadyTranslated": false},
    		"Dissatisfied": {"text": "Dissatisfied", "isAlreadyTranslated": false},
    		"Very Dissatisfied": {"text": "Very Dissatisfied", "isAlreadyTranslated": false},
    		"Please comment if you are dissatisfied": {"text": "Please comment if you are dissatisfied", "isAlreadyTranslated": false},
    		"User Signature": {"text": "User Signature", "isAlreadyTranslated": false},
    		"Date": {"text": "Date", "isAlreadyTranslated": false},
    		"Thank you for choosing Pupa": {"text": "Thank you for choosing Pupa", "isAlreadyTranslated": false},



				"No serviceable consumer product found": {"text": "No serviceable consumer product found", "isAlreadyTranslated": false},
				"Is Warranty Applicable for this request": {"text": "Is Warranty Applicable for this request", "isAlreadyTranslated": false},
				"Where was the device kept at the time of incident": {"text": "Where was the device kept at the time of incident", "isAlreadyTranslated": false},
				"Min 100 Characters": {"text": "Min 100 Characters", "isAlreadyTranslated": false},
				"LEFT": {"text": "LEFT", "isAlreadyTranslated": false},
				"Please Enter Minimum 100 Characters": {"text": "Please Enter Minimum 100 Characters", "isAlreadyTranslated": false},
				"Enter Other Issue Description": {"text": "Enter Other Issue Description", "isAlreadyTranslated": false},

				"Send for raising request?": {"text": "Send for raising request?", "isAlreadyTranslated": false},
				"Air Conditioner": {"text": "Air Conditioner", "isAlreadyTranslated": false},
				"STORAGE": {"text": "STORAGE", "isAlreadyTranslated": false},
				"TELECOM SERVICES": {"text": "TELECOM SERVICES", "isAlreadyTranslated": false},

				"Trade In Pickup": {"text": "Trade In Pickup", "isAlreadyTranslated": false},
				"Vibrator": {"text": "Vibrator", "isAlreadyTranslated": false},
				"Sound": {"text": "Sound", "isAlreadyTranslated": false},
				"Display": {"text": "Display", "isAlreadyTranslated": false},
				"Accessories": {"text": "Accessories", "isAlreadyTranslated": false},
				"Physical Damage": {"text": "Physical Damage", "isAlreadyTranslated": false},
				"System Performance": {"text": "System Performance", "isAlreadyTranslated": false},
				"Power": {"text": "Power", "isAlreadyTranslated": false},
				"Built in Camera": {"text": "Built in Camera", "isAlreadyTranslated": false},
				"Data Storage": {"text": "Data Storage", "isAlreadyTranslated": false},
				"Communication/Networking": {"text": "Communication/Networking", "isAlreadyTranslated": false},

				"Consumer Product already exists with Serial/IMEI number": {"text": "Consumer Product already exists with Serial/IMEI number", "isAlreadyTranslated": false},

				"Oops. We don’t service this device at this location. But we’re hard at work and you should get some good news soon. Try later?": {"text": "Oops. We don’t service this device at this location. But we’re hard at work and you should get some good news soon. Try later?", "isAlreadyTranslated": false},
				"Enter Issue Decsription": {"text": "Enter Issue Decsription", "isAlreadyTranslated": false},
				"office": {"text": "office", "isAlreadyTranslated": false},
				"Enter a location": {"text": "Enter a location", "isAlreadyTranslated": false},
				"Accept": {"text": "Accept", "isAlreadyTranslated": false},
				"Reject": {"text": "Reject", "isAlreadyTranslated": false},
				"Warranty Concession": {"text": "Warranty Concession", "isAlreadyTranslated": false},
				"No order lines found for given request": {"text": "No order lines found for given request", "isAlreadyTranslated": false},
				"Toggle Warranty": {"text": "Toggle Warranty", "isAlreadyTranslated": false},
				"Provide link for customer": {"text": "Provide link for customer", "isAlreadyTranslated": false},
				"Accept or Reject Request": {"text": "Accept or Reject Request", "isAlreadyTranslated": false},
				"Inward": {"text": "Inward", "isAlreadyTranslated": false},
				"Outward": {"text": "Outward", "isAlreadyTranslated": false},
				"Max number of retry attempts exceeded": {"text": "Max number of retry attempts exceeded", "isAlreadyTranslated": false},
				"Please Enter Incident Place of Damage": {"text": "Please Enter Incident Place of Damage", "isAlreadyTranslated": false},
				"Please enter the link": {"text": "Please enter the link", "isAlreadyTranslated": false},

				"Please Select Device Receiving Time": {"text": "Please Select Device Receiving Time", "isAlreadyTranslated": false},
				"Protection Status": {"text": "Protection Status", "isAlreadyTranslated": false},
				"Config Description": {"text": "Config Description", "isAlreadyTranslated": false},
				"Please specify the reason...": {"text": "Please specify the reason...", "isAlreadyTranslated": false},
				"An error has occurred. Please contact support@servify.in for help.": {"text": "An error has occurred. Please contact support@servify.in for help.", "isAlreadyTranslated": false},
				"The parts will be returned to Store": {"text": "The parts will be returned to Store", "isAlreadyTranslated": false},

				"Select a Part Name": {"text": "Select a Part Name", "isAlreadyTranslated": false},
				"Restore from Backup History": {"text": "Restore from Backup History", "isAlreadyTranslated": false},
				"PASSED": {"text": "PASSED", "isAlreadyTranslated": false},
				"Backup History": {"text": "Backup History", "isAlreadyTranslated": false},
				"WARNING : This device has not been backed up in the last two weeks.": {"text": "WARNING : This device has not been backed up in the last two weeks.", "isAlreadyTranslated": false},
				"Sensors": {"text": "Sensors", "isAlreadyTranslated": false},
				"Temperature History": {"text": "Temperature History", "isAlreadyTranslated": false},
				"Accelerometer": {"text": "Accelerometer", "isAlreadyTranslated": false},
				"Accelerometer Sensor Test": {"text": "Accelerometer Sensor Test", "isAlreadyTranslated": false},
				"ALS": {"text": "ALS", "isAlreadyTranslated": false},
				"Ambient Light Sensor Test": {"text": "Ambient Light Sensor Test", "isAlreadyTranslated": false},
				"Bluetooth": {"text": "Bluetooth", "isAlreadyTranslated": false},
				"Bluetooth Scan Test": {"text": "Bluetooth Scan Test", "isAlreadyTranslated": false},
				"Camera": {"text": "Camera", "isAlreadyTranslated": false},
				"Camera Sensor Test": {"text": "Camera Sensor Test", "isAlreadyTranslated": false},
				"Gyro": {"text": "Gyro", "isAlreadyTranslated": false},
				"Gyro Sensor Test": {"text": "Gyro Sensor Test", "isAlreadyTranslated": false},
				"Touch ID": {"text": "Touch ID", "isAlreadyTranslated": false},
				"Presence Test": {"text": "Presence Test", "isAlreadyTranslated": false},
				"Wi-Fi": {"text": "Wi-Fi", "isAlreadyTranslated": false},
				"Software": {"text": "Software", "isAlreadyTranslated": false},
				"Version Test": {"text": "Version Test", "isAlreadyTranslated": false},
				"System": {"text": "System", "isAlreadyTranslated": false},
				"Usage Profile": {"text": "Usage Profile", "isAlreadyTranslated": false},
				"Battery": {"text": "Battery", "isAlreadyTranslated": false},
				"Health Check": {"text": "Health Check", "isAlreadyTranslated": false},
				"PASSED : Testing completed and no issues were found on this device.": {"text": "PASSED : Testing completed and no issues were found on this device.", "isAlreadyTranslated": false},
				"App Crash History": {"text": "App Crash History", "isAlreadyTranslated": false},
				"Unexpected Shutdown History": {"text": "Unexpected Shutdown History", "isAlreadyTranslated": false},
				"Power Button": {"text": "Power Button", "isAlreadyTranslated": false},
				"Home Button": {"text": "Home Button", "isAlreadyTranslated": false},
				"Volume Down Button": {"text": "Volume Down Button", "isAlreadyTranslated": false},
				"LCD Screen": {"text": "LCD Screen", "isAlreadyTranslated": false},
				"Ear Piece": {"text": "Ear Piece", "isAlreadyTranslated": false},
				"Sensors": {"text": "Sensors", "isAlreadyTranslated": false},
				"Back Camera": {"text": "Back Camera", "isAlreadyTranslated": false},
				"Network Connection": {"text": "Network Connection", "isAlreadyTranslated": false},
				"Vibrator": {"text": "Vibrator", "isAlreadyTranslated": false},
				"IMEI Check": {"text": "IMEI Check", "isAlreadyTranslated": false},
				"Incoming call": {"text": "Incoming call", "isAlreadyTranslated": false},
				"Bluetooth": {"text": "Bluetooth", "isAlreadyTranslated": false},
				"Microphone": {"text": "Microphone", "isAlreadyTranslated": false},
				"USB Connectivity": {"text": "USB Connectivity", "isAlreadyTranslated": false},
				"Battery Storage": {"text": "Battery Storage", "isAlreadyTranslated": false},
				"LED Flash": {"text": "LED Flash", "isAlreadyTranslated": false},
				"TAB Button": {"text": "TAB Button", "isAlreadyTranslated": false},
				"Unlock Button": {"text": "Unlock Button", "isAlreadyTranslated": false},
				"Full Diagnosis": {"text": "Full Diagnosis", "isAlreadyTranslated": false},
				"Proper Assembly": {"text": "Proper Assembly", "isAlreadyTranslated": false},
				"Back Button": {"text": "Back Button", "isAlreadyTranslated": false},
				"Volume Up Button": {"text": "Volume Up Button", "isAlreadyTranslated": false},
				"Dead Spots": {"text": "Dead Spots", "isAlreadyTranslated": false},
				"Charging Port": {"text": "Charging Port", "isAlreadyTranslated": false},
				"Loud Speaker": {"text": "Loud Speaker", "isAlreadyTranslated": false},
				"Front Camera": {"text": "Front Camera", "isAlreadyTranslated": false},
				"Wi-Fi": {"text": "Wi-Fi", "isAlreadyTranslated": false},
				"Outgoing call": {"text": "Outgoing call", "isAlreadyTranslated": false},
				"Software Version": {"text": "Software Version", "isAlreadyTranslated": false},

				"GSX Confirmation Number": {"text": "GSX Confirmation Number", "isAlreadyTranslated": false},
				"Services": {"text": "Services", "isAlreadyTranslated": false},
				"Check If Device Box IMEI Matches With Device IMEI": {"text": "Check If Device Box IMEI Matches With Device IMEI", "isAlreadyTranslated": false},
				"Check If Device IMEI Matches With Internal IMEI": {"text": "Check If Device IMEI Matches With Internal IMEI", "isAlreadyTranslated": false},
				"Check If Touch/Display Is Broken": {"text": "Check If Touch/Display Is Broken", "isAlreadyTranslated": false},
				"Check If SIM Tray Is Missing Or Broken": {"text": "Check If SIM Tray Is Missing Or Broken", "isAlreadyTranslated": false},
				"Check If Back Cover Is Damaged": {"text": "Check If Back Cover Is Damaged", "isAlreadyTranslated": false},
				"Check If Screw Heads Are Damaged": {"text": "Check If Screw Heads Are Damaged", "isAlreadyTranslated": false},
				"Check If There Are Dents Or Internal Cracks In Display": {"text": "Check If There Are Dents Or Internal Cracks In Display", "isAlreadyTranslated": false},
				"Check If Volume Keys Are Loose Or Not Functioning": {"text": "Check If Volume Keys Are Loose Or Not Functioning", "isAlreadyTranslated": false},
				"Check If Power Keys Are Loose Or Not Functioning": {"text": "Check If Power Keys Are Loose Or Not Functioning", "isAlreadyTranslated": false},
				"Check If Camera's Auto Focus Is Not Working": {"text": "Check If Camera's Auto Focus Is Not Working", "isAlreadyTranslated": false},
				"Check If Photos Clicked Are Blurred": {"text": "Check If Photos Clicked Are Blurred", "isAlreadyTranslated": false},
				"Check If USB Port Is Damaged": {"text": "Check If USB Port Is Damaged", "isAlreadyTranslated": false},
				"Check If Accessories Are As Mentioned On The Sales Pack": {"text": "Check If Accessories Are As Mentioned On The Sales Pack", "isAlreadyTranslated": false},
				"Check If There Are Functional Defects": {"text": "Check If There Are Functional Defects", "isAlreadyTranslated": false},
				"Ear Pods": {"text": "Ear Pods", "isAlreadyTranslated": false},
				"Power Adapter": {"text": "Power Adapter", "isAlreadyTranslated": false},
				"Lightning to USB Cable": {"text": "Lightning to USB Cable", "isAlreadyTranslated": false},
				"Display Damaged": {"text": "Display Damaged", "isAlreadyTranslated": false},
				"Battery Damaged": {"text": "Battery Damaged", "isAlreadyTranslated": false},
				"Backup Required": {"text": "Backup Required", "isAlreadyTranslated": false},
				"Find my iPhone Active": {"text": "Find my iPhone Active", "isAlreadyTranslated": false},
				"Unlock.": {"text": "Unlock.", "isAlreadyTranslated": false},

				"Functional Defects": {"text": "Functional Defects", "isAlreadyTranslated": false},
				"Accessories as per Content printed on sales pack": {"text": "Accessories as per Content printed on sales pack", "isAlreadyTranslated": false},
				"USB Port Damaged": {"text": "USB Port Damaged", "isAlreadyTranslated": false},
				"Camera AF & Blurr Issues": {"text": "Camera AF & Blurr Issues", "isAlreadyTranslated": false},
				"Power/Volume Keys Loose or Not Functioning": {"text": "Power/Volume Keys Loose or Not Functioning", "isAlreadyTranslated": false},
				"Any Dents or Internal Crack in display": {"text": "Any Dents or Internal Crack in display", "isAlreadyTranslated": false},
				"Screw heads Damaged": {"text": "Screw heads Damaged", "isAlreadyTranslated": false},
				"Back Cover Damaged": {"text": "Back Cover Damaged", "isAlreadyTranslated": false},
				"SIM Tray Missing or Broken": {"text": "SIM Tray Missing or Broken", "isAlreadyTranslated": false},
				"Touch/LCD Broken": {"text": "Touch/LCD Broken", "isAlreadyTranslated": false},
				"Unit IMEI Vs Internal IMEI": {"text": "Unit IMEI Vs Internal IMEI", "isAlreadyTranslated": false},
				"Box IMEI Vs Unit IMEI": {"text": "Box IMEI Vs Unit IMEI", "isAlreadyTranslated": false},
				"Functional Defects": {"text": "Functional Defects", "isAlreadyTranslated": false},

				"Please enter alphabets or numbers only": {"text": "Please enter alphabets or numbers only", "isAlreadyTranslated": false},
				"Please enter numbers only": {"text": "Please enter numbers only", "isAlreadyTranslated": false},
				"Consumer Email": {"text": "Consumer Email", "isAlreadyTranslated": false},
				"IMEI Number 1": {"text": "IMEI Number 1", "isAlreadyTranslated": false},
				"IMEI Number 2": {"text": "IMEI Number 2", "isAlreadyTranslated": false},
				"Reassign Service": {"text": "Reassign Service", "isAlreadyTranslated": false},
				"Select a service location from the drop down": {"text": "Select a service location from the drop down", "isAlreadyTranslated": false},
				"The same service center and same engineer is already assigned.": {"text": "The same service center and same engineer is already assigned.", "isAlreadyTranslated": false},
				"Service center and engineer has been reassigned.": {"text": "Service center and engineer has been reassigned.", "isAlreadyTranslated": false},
				"The same service center is already assigned.": {"text": "The same service center is already assigned.", "isAlreadyTranslated": false},
				"Service center has been reassigned.": {"text": "Service center has been reassigned.", "isAlreadyTranslated": false},
				"First Dialer Dispostion": {"text": "First Dialer Dispostion", "isAlreadyTranslated": false},
				"Second Dialer Dispostion": {"text": "Second Dialer Dispostion", "isAlreadyTranslated": false},
				"Reschedule Service": {"text": "Reschedule Service", "isAlreadyTranslated": false},
				"No slots Available": {"text": "No slots Available", "isAlreadyTranslated": false},
				"Reason for reschedule": {"text": "Reason for reschedule", "isAlreadyTranslated": false},
				"Are you sure you want to reschedule?": {"text": "Are you sure you want to reschedule?", "isAlreadyTranslated": false},
				"The request was rescheduled successfully": {"text": "The request was rescheduled successfully", "isAlreadyTranslated": false},
				"Please specify the reason for reschedule": {"text": "Please specify the reason for reschedule", "isAlreadyTranslated": false},
				"Please select a slot for reschedule": {"text": "Please select a slot for reschedule", "isAlreadyTranslated": false},
				"Service Location": {"text": "Service Location", "isAlreadyTranslated": false},
				"Device Replacement Mode": {"text": "Device Replacement Mode", "isAlreadyTranslated": false},
				"Generate DOA Certificate": {"text": "Generate DOA Certificate", "isAlreadyTranslated": false},
				"Get New Device": {"text": "Get New Device", "isAlreadyTranslated": false},
				"Choose Device": {"text": "Choose Device", "isAlreadyTranslated": false},
				"Enter Information for the Device you are issuing": {"text": "Enter Information for the Device you are issuing", "isAlreadyTranslated": false},
				"Please enter correct IMEI 1": {"text": "Please enter correct IMEI 1", "isAlreadyTranslated": false},
				"Please enter correct IMEI 2": {"text": "Please enter correct IMEI 2", "isAlreadyTranslated": false},
				"Please enter correct Serial Number": {"text": "Please enter correct Serial Number", "isAlreadyTranslated": false},
				"Action on Device": {"text": "Action on Device", "isAlreadyTranslated": false},
				"Status Date": {"text": "Status Date", "isAlreadyTranslated": false},
				"Symptoms reported by Engineer": {"text": "Symptoms reported by Engineer", "isAlreadyTranslated": false},
				"Symptoms": {"text": "Symptoms", "isAlreadyTranslated": false},
				"Add New Symptom Codes": {"text": "Add New Symptom Codes", "isAlreadyTranslated": false},
				"No symptoms added": {"text": "No symptoms added", "isAlreadyTranslated": false},
				"Symptom already added by the engineer": {"text": "Symptom already added by the engineer", "isAlreadyTranslated": false},
				"Please validate all the issues": {"text": "Please validate all the issues", "isAlreadyTranslated": false},
				"Customer did not add issues": {"text": "Customer did not add issues", "isAlreadyTranslated": false},
				"Verification Attempts Details": {"text": "Verification Attempts Details", "isAlreadyTranslated": false},
				"Number of Attempts Remaining": {"text": "Number of Attempts Remaining", "isAlreadyTranslated": false},
				"Value Used For Verification": {"text": "Value Used For Verification", "isAlreadyTranslated": false},
				"Verified": {"text": "Verified", "isAlreadyTranslated": false},
				"Not Verified": {"text": "Not Verified", "isAlreadyTranslated": false},
				"Verification Not Attempted": {"text": "Verification Not Attempted", "isAlreadyTranslated": false},
				"Request is being processed.": {"text": "Request is being processed.", "isAlreadyTranslated": false},
				"Hub Details": {"text": "Hub Details", "isAlreadyTranslated": false},
				"Logistic Partners": {"text": "Logistic Partners", "isAlreadyTranslated": false},
				"Logistic Partner Name": {"text": "Logistic Partner Name", "isAlreadyTranslated": false},
				"Recommended": {"text": "Recommended", "isAlreadyTranslated": false},
				"Schedule Request": {"text": "Schedule Request", "isAlreadyTranslated": false},
				"Please select a Logistic Partner": {"text": "Please select a Logistic Partner", "isAlreadyTranslated": false},
				"SMS Sent Successfully": {"text": "SMS Sent Successfully", "isAlreadyTranslated": false},
				"Please select a Hub": {"text": "Please select a Hub", "isAlreadyTranslated": false},
				"Upload Document": {"text": "Upload Document", "isAlreadyTranslated": false},
				"Add Certificate": {"text": "Add Certificate", "isAlreadyTranslated": false},
				"Cancel": {"text": "Cancel", "isAlreadyTranslated": false},
				"Vouchers generated successfully": {"text": "Vouchers generated successfully", "isAlreadyTranslated": false},
				"Document Type: Cheque": {"text": "Document Type: Cheque", "isAlreadyTranslated": false},
				"Covered Value": {"text": "Covered Value", "isAlreadyTranslated": false},
				"Claim Amount": {"text": "Claim Amount", "isAlreadyTranslated": false},
				"Please enter Claim Amount:": {"text": "Please enter Claim Amount:", "isAlreadyTranslated": false},
				"Please enter Claim Amount before submitting": {"text": "Please enter Claim Amount before submitting", "isAlreadyTranslated": false},
				"Invoice Value": {"text": "Invoice Value", "isAlreadyTranslated": false},
				"Repair Cost": {"text": "Repair Cost", "isAlreadyTranslated": false},
				"Covered Value": {"text": "Covered Value", "isAlreadyTranslated": false},
				"Mandatory Deductible": {"text": "Mandatory Deductible", "isAlreadyTranslated": false},
				"BER Payment Amount": {"text": "BER Payment Amount", "isAlreadyTranslated": false},
				"Approve BER": {"text": "Approve BER", "isAlreadyTranslated": false},
				"Please enter Override Amount before submitting": {"text": "Please enter Override Amount before submitting", "isAlreadyTranslated": false},
				"Please mention the incident in not less than 100 characters on how, when & where the damage happened": {"text": "Please mention the incident in not less than 100 characters on how, when & where the damage happened", "isAlreadyTranslated": false},
				"Enter Loaner Device Details": {"text": "Enter Loaner Device Details", "isAlreadyTranslated": false},
				"Select IMEI Number": {"text": "Select IMEI Number", "isAlreadyTranslated": false},
				"Receive Loaner Device": {"text": "Receive Loaner Device", "isAlreadyTranslated": false},
				"Condition Of the Loaner Device": {"text": "Condition Of the Loaner Device", "isAlreadyTranslated": false},
				"Good": {"text": "Good", "isAlreadyTranslated": false},
				"Bad": {"text": "Bad", "isAlreadyTranslated": false},
				"Ugly": {"text": "Ugly", "isAlreadyTranslated": false},
				"Loaner Device Details added successfully": {"text": "Loaner Device Details added successfully", "isAlreadyTranslated": false},
				"Please choose from the given list only.": {"text": "Please choose from the given list only.", "isAlreadyTranslated": false},
				"Please tell us about the condition of the Loaner Device.": {"text": "Please tell us about the condition of the Loaner Device.", "isAlreadyTranslated": false},
				"Please write about the condition of the device.": {"text": "Please write about the condition of the device.", "isAlreadyTranslated": false},
				"from": {"text": "from", "isAlreadyTranslated": false},
				"till": {"text": "till", "isAlreadyTranslated": false},
				"to": {"text": "to", "isAlreadyTranslated": false},
				"Consumer Mobile": {"text": "Consumer Mobile", "isAlreadyTranslated": false},
				"Alternate No./Landline No.": {"text": "Alternate No./Landline No.", "isAlreadyTranslated": false},
				"Customer Alternate Mobile No.": {"text": "Customer Alternate Mobile No.", "isAlreadyTranslated": false},
				"Save & Send SMS/Email": {"text": "Save & Send SMS/Email", "isAlreadyTranslated": false},
				"consumer(s) are shown above.": {"text": "consumer(s) are shown above.", "isAlreadyTranslated": false},
				"Call logs": {"text": "Call logs", "isAlreadyTranslated": false},
				"customer@email.com": {"text": "customer@email.com", "isAlreadyTranslated": false},
				"Customer Name": {"text": "Customer Name", "isAlreadyTranslated": false},
				"Select a model": {"text": "Select a model", "isAlreadyTranslated": false},

				"IMEI / Serial No.": {"text": "IMEI / Serial No.", "isAlreadyTranslated": false},
				"Servify Diagnostic App": {"text": "Servify Diagnostic App", "isAlreadyTranslated": false},
				"Serial No.": {"text": "Serial No.", "isAlreadyTranslated": false},
				"IMEI 2": {"text": "IMEI 2", "isAlreadyTranslated": false},
				"Referece ID": {"text": "Referece ID", "isAlreadyTranslated": false},
				"Claim Form": {"text": "Claim Form", "isAlreadyTranslated": false},
				"Claim Form Servify": {"text": "Claim Form Servify", "isAlreadyTranslated": false},
				"Authorization Letter": {"text": "Authorization Letter", "isAlreadyTranslated": false},
				"PAN Card Front": {"text": "PAN Card Front", "isAlreadyTranslated": false},
				"Aadhar Card Front": {"text": "Aadhar Card Front", "isAlreadyTranslated": false},
				"Aadhar Card Back": {"text": "Aadhar Card Back", "isAlreadyTranslated": false},
				"Other Documents": {"text": "Other Documents", "isAlreadyTranslated": false},
				"Cheque": {"text": "Cheque", "isAlreadyTranslated": false},
				"Video": {"text": "Video", "isAlreadyTranslated": false},
				"Device image": {"text": "Device image", "isAlreadyTranslated": false},
				"Destruction Form": {"text": "Destruction Form", "isAlreadyTranslated": false},
				"P-Slip": {"text": "P-Slip", "isAlreadyTranslated": false},
				"Consumer Signature": {"text": "Consumer Signature", "isAlreadyTranslated": false},
				"Issue Image": {"text": "Issue Image", "isAlreadyTranslated": false},
				"Diagnosis report": {"text": "Diagnosis report", "isAlreadyTranslated": false},
				"Delivery Note": {"text": "Delivery Note", "isAlreadyTranslated": false},
				"Product Damage Feedback Item": {"text": "Product Damage Feedback Item", "isAlreadyTranslated": false},
				"IMEI / Serial No.": {"text": "IMEI / Serial No.", "isAlreadyTranslated": false},
				"Claim Form in": {"text": "Claim Form in", "isAlreadyTranslated": false},
				"Claim Form Servify": {"text": "Claim Form Servify", "isAlreadyTranslated": false},
				"Authorization Letter": {"text": "Authorization Letter", "isAlreadyTranslated": false},
				"PAN Card Front": {"text": "PAN Card Front", "isAlreadyTranslated": false},
				"Aadhar Card Front": {"text": "Aadhar Card Front", "isAlreadyTranslated": false},
				"Other Documents": {"text": "Other Documents", "isAlreadyTranslated": false},
				"IMEI No": {"text": "IMEI No", "isAlreadyTranslated": false},

				"IssueText": {"text": "IssueText", "isAlreadyTranslated": false},
				"IssueRemark": {"text": "IssueRemark", "isAlreadyTranslated": false},
				"otherIssueText": {"text": "otherIssueText", "isAlreadyTranslated": false},
				"screen": {"text": "screen", "isAlreadyTranslated": false},
				"Consumer To Service Centre": {"text": "Consumer To Service Centre", "isAlreadyTranslated": false},
				"Service Centre To Consumer": {"text": "Service Centre To Consumer", "isAlreadyTranslated": false},
				"On": {"text": "On", "isAlreadyTranslated": false},
				"at": {"text": "at", "isAlreadyTranslated": false},



				"IMEI/Serial": {"text": "IMEI/Serial", "isAlreadyTranslated": false},
				"Insufficient Data For Graph. Data": {"text": "Insufficient Data For Graph. Data", "isAlreadyTranslated": false},
				"is": {"text": "is", "isAlreadyTranslated": false},
				"Not a valid user.": {"text": "Not a valid user.", "isAlreadyTranslated": false},
				"Rejection Reasons and Expected Actions by APR - Summary": {"text": "Rejection Reasons and Expected Actions by APR - Summary", "isAlreadyTranslated": false},
				"Error Message on App": {"text": "Error Message on App", "isAlreadyTranslated": false},
				"Error Code": {"text": "Error Code", "isAlreadyTranslated": false},
				"Explanation of Error Code": {"text": "Explanation of Error Code", "isAlreadyTranslated": false},
				"Expected Action from APR": {"text": "Expected Action from APR", "isAlreadyTranslated": false},
				"Error Code": {"text": "Error Code", "isAlreadyTranslated": false},
				"Store Name & Location": {"text": "Store Name & Location", "isAlreadyTranslated": false},
				"Download Activation Report": {"text": "Download Activation Report", "isAlreadyTranslated": false},
				"Filter Consumers": {"text": "Filter Consumers", "isAlreadyTranslated": false},
				"Order ID": {"text": "Order ID", "isAlreadyTranslated": false},
				"Order Item ID": {"text": "Order Item ID", "isAlreadyTranslated": false},
				"User Details": {"text": "User Details", "isAlreadyTranslated": false},
				"Plan Purchased": {"text": "Plan Purchased", "isAlreadyTranslated": false},
				"Activated On": {"text": "Activated On", "isAlreadyTranslated": false},
				"Status Message": {"text": "Status Message", "isAlreadyTranslated": false},
				"Rejection Reasons and Expected Actions by APR - Summary": {"text": "Rejection Reasons and Expected Actions by APR - Summary", "isAlreadyTranslated": false},
				"Error Message on App": {"text": "Error Message on App", "isAlreadyTranslated": false},
				"Error Code in": {"text": "Error Code in", "isAlreadyTranslated": false},


				"Something went wrong when trying to create a consumer. Please try again.": {"text": "Something went wrong when trying to create a consumer. Please try again.", "isAlreadyTranslated": false},
				"Something went wrong when trying to create a consumer. Please try again.": {"text": "Something went wrong when trying to create a consumer. Please try again.", "isAlreadyTranslated": false},
				"Consumer successfully added and SMS sent!": {"text": "Consumer successfully added and SMS sent!", "isAlreadyTranslated": false},
				"Please enter correct E-mail ID.": {"text": "Please enter correct E-mail ID.", "isAlreadyTranslated": false},
				"Please enter correct Mobile Number.": {"text": "Please enter correct Mobile Number.", "isAlreadyTranslated": false},
				"Please enter Mobile Number.": {"text": "Please enter Mobile Number.", "isAlreadyTranslated": false},
				"Please select a Device Type.": {"text": "Please select a Device Type.", "isAlreadyTranslated": false},
				"Please enter at least one issue.": {"text": "Please enter at least one issue.", "isAlreadyTranslated": false},
				"Please enter Incident ID.": {"text": "Please enter Incident ID.", "isAlreadyTranslated": false},
				"Please select a model from the dropdown.": {"text": "Please select a model from the dropdown.", "isAlreadyTranslated": false},
				"Please enter E-mail ID.": {"text": "Please enter E-mail ID.", "isAlreadyTranslated": false},
				"Please enter Customer\'s Name.": {"text": "Please enter Customer\'s Name.", "isAlreadyTranslated": false},

				"Doc": {"text": "Doc", "isAlreadyTranslated": false},
				"Allowed Repairs": {"text": "Allowed Repairs", "isAlreadyTranslated": false},
				"Used Repairs": {"text": "Used Repairs", "isAlreadyTranslated": false},
				"Tax Payable": {"text": "Tax Payable", "isAlreadyTranslated": false},
				"View queued requestsat": {"text": "View queued requests", "isAlreadyTranslated": false},
				"Generate Vouchers": {"text": "Generate Vouchers", "isAlreadyTranslated": false},
				"Customer's Address Details": {"text": "Customer's Address Details", "isAlreadyTranslated": false},
				"View queued requests": {"text": "View queued requests", "isAlreadyTranslated": false},
				"Please specify the reason...": {"text": "Please specify the reason...", "isAlreadyTranslated": false},


	};
	export default lang;