import React,{useState,useEffect} from 'react';
import {Input, Button, Radio, Dropdown} from 'sfy-react';
import {forEach,flattenDeep, find} from 'lodash';
import { displayError, SERVICETYPE,returnLocalDateTime} from '../../../../../Utility/Utils';
import Swal from 'sweetalert2';
import moment from 'moment';
import CallAttemptUI from './CallAttemptUI';

const CallAttemptInput=(props)=>{
    const {apiCall, localString, setLoader, consumerServiceRequest, claimDamageId, getRequestById, getCommunicationLogs, setShowModal, showModal, accessFn, qFilter,
            pickupCarryInGSXChecks,pickupCarryInChecks,onsiteChecks,claimNewWorkshopChecks,closeModal }=props;
    let today = moment().format("DD-MM-YYYY HH:mm");
    const [callLogsField,setCallLogsField] = useState({
        username:'',
        callDisposition:'',
        callDispositionID:'',
        firstDispositionSelectedText:'',
        secondDispositionSelectedText:'',
        datetime:today,
        damageType:'',
        damageDescription:'',
        callreason:'',
        damageTypeID:''
    });
    const [callLogFlags, setCallLogFlags] = useState({
        isGSXCallTypeSelected:false,
        isConnectActionChecked:false,
        showRemainingFields:false,
        showFields : false,
        saveCallBtn:false
    });
    const [isDisable,setIsdisable] = useState({
        enableFirstDisposition:true,
        enableSecondDisposition:true
    })
    const [logsSelectedValue, setLogsSelectedValue] = useState(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.DEMO, SERVICETYPE.SERVICING,56,61,62,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID)> -1) ?'No':'');
    const [GSXCallDisposition, setGSXCallDisposition] = useState([])
    const [selectCallConnected,setSelectCallConnected]= useState([]);
    const [selectCallNotConnected, setSelectCallNotConnected] = useState([]);
    const [selectFirstDisposition, setSelectFirstDisposition] = useState([]);
    const [selectSecondDisposition, setSelectSecondDisposition] = useState([]);
    const [secondDispositionData, setSecondDispositionData] = useState([]);
    const [selectTypeOfDamage,setSelectTypeOfDamage] = useState([]);
    const [selectedCallType, setSelectedCallType] = useState("")
    const [characterCount,setCharacterCount] = useState('');
    const damageTypeArr = [{
			"id": 1,
			"displayText": "Physical damage"
		},
		{
			"id": 2,
			"displayText": "Liquid damage"
		},
		{
			"id": 3,
			"displayText": "Screen damage"
		},
		{
			"id": 100,
			"displayText": "Unknown"
        },
        {
			"id": 6,
			"displayText": "Manufacture Cover Damage"
		},
    ]

    const callTypes = [
        {value:"Inbound",},
        {value:"Outbound",}
    ]
    
    useEffect(()=>{
        enableCallSaveButton() 
    },[callLogsField])

    useEffect(() => {
        getTypeOfDamage()
    }, [claimDamageId])

    useEffect(() => {
        if(pickupCarryInGSXChecks && consumerServiceRequest.consumerProduct.isAppleBrandID && accessFn('GSX_FLOW') && selectedCallType.length > 0){
            if(selectedCallType == "Inbound"){
                setCallLogFlags({
                    ...callLogFlags,
                    isGSXCallTypeSelected:true,
                    isConnectActionChecked:false,
                    showRemainingFields:false,
                    showFields:true,
                    saveCallBtn:true
                });
            }else{
                setCallLogFlags({
                    ...callLogFlags,
                    isGSXCallTypeSelected:false,
                    isConnectActionChecked:false,
                    showRemainingFields:false,
                    showFields:false,
                    saveCallBtn:false
                });
            }
            loadCallDisposition();
            setLogsSelectedValue("")
        }
    }, [selectedCallType])
    
    useEffect(()=>{
        if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.DEMO, SERVICETYPE.SERVICING,56,61,62,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){
            content(logsSelectedValue)
        }
    },[])
    const content = (val)=>{
        if(pickupCarryInGSXChecks && consumerServiceRequest.consumerProduct.isAppleBrandID && accessFn('GSX_FLOW')){
            if(val === 'Yes'){
                setCallLogFlags({
                    ...callLogFlags,
                    isGSXCallTypeSelected:true,
                    isConnectActionChecked:true,
                    showFields:true,
                    saveCallBtn:true
                });
                initDamageTypes(damageTypeArr);
                loadCallDisposition(val);
                // clearAllValue();
                setCallLogsField({callDisposition:''})
            }else{
                setCallLogFlags({
                    ...callLogFlags,
                    isGSXCallTypeSelected:false,
                    showFields:true,
                    isConnectActionChecked:false,
                    saveCallBtn:true
                });
                initDamageTypes(damageTypeArr);
                loadCallDisposition(val);
                // clearAllValue();
                setCallLogsField({callDisposition:''})
            }
        }else if(([SERVICETYPE.ON_SITE, SERVICETYPE.INSTALLATION, SERVICETYPE.DEMO, SERVICETYPE.SERVICING,56,61,62,SERVICETYPE.CLAIM_ONSITE].indexOf(consumerServiceRequest.ServiceTypeID)> -1)){ //onsite
            if(val === 'Yes'){
                setCallLogFlags({
                    ...callLogFlags,
                    isGSXCallTypeSelected:true,
                    isConnectActionChecked:true,
                    showRemainingFields:true,
                    showFields:true,
                    saveCallBtn:true
                });
                initDamageTypes(damageTypeArr);
                loadCallDisposition(val);
                setCallLogsField({callDisposition:''})
                // clearAllValue();
            }else{
                setCallLogFlags({
                    ...callLogFlags,
                    isGSXCallTypeSelected:false,
                    isConnectActionChecked:false,
                    showRemainingFields:false,
                    showFields:true,
                    saveCallBtn:true
                });
                initDamageTypes(damageTypeArr);
                loadCallDisposition(val);
                // clearAllValue();
                setCallLogsField({callDisposition:''})
            }
        }
        else{
            if(val === 'Yes'){
                setCallLogFlags({
                    ...callLogFlags,
                    isGSXCallTypeSelected:false,
                    isConnectActionChecked:true,
                    showRemainingFields:true,
                    showFields:true,
                    saveCallBtn:true
                });
                initDamageTypes(damageTypeArr);
                loadCallDisposition(val);
                // clearAllValue();
                setCallLogsField({callDisposition:''})
            }else{
                setCallLogFlags({
                    ...callLogFlags,
                    isGSXCallTypeSelected:false,
                    isConnectActionChecked:false,
                    showRemainingFields:false,
                    showFields:true,
                    saveCallBtn:true
                });
                initDamageTypes(damageTypeArr);
                loadCallDisposition(val);
                // clearAllValue();
                setCallLogsField({callDisposition:''})
            }
        }
    }

    const clearAllValue = ()=>{
        setCallLogsField({
            username:'',
            callDisposition:'',
            firstDispositionSelectedText:'',
            secondDispositionSelectedText:'',
            datetime:today,
            damageType:'',
            damageDescription:'',
            callreason:'',
        })
    }
    const loadCallDisposition = (isConnectActionChecked) => {
        setLoader(true);
        let callConnectStatus = isConnectActionChecked;
        let data = {};
        apiCall({
			url: "getCallStatus",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    if(result.data){
                        setSecondDispositionData(result.data.secondDisposition)
                        if(callConnectStatus){
                            if(callConnectStatus === 'Yes'){
                                let callList = result.data.callConnected.map(v=>({...v,value:v.displayText}));
                                setSelectCallConnected(callList)
                            }else{
                                let callList = result.data.callNotConnected.map(v=>({...v,value:v.displayText}));
                                setSelectCallNotConnected(callList)
                            }
                        }else{  
                            if(pickupCarryInGSXChecks && consumerServiceRequest.consumerProduct.isAppleBrandID && accessFn('GSX_FLOW')){
                                let callDisposition = result.data.inboundCall.map(v=>({...v,value:v.displayText}));
                                setGSXCallDisposition(callDisposition)
                            }
                        }
                        let firstCallList = result.data.firstDisposition.map(v=>({...v,value:v.displayText}));
                        setSelectFirstDisposition(firstCallList);
                    }
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
    }

    const enterCallLog = (e) => {
        e.preventDefault();
        let callerName = callLogsField.username;
        let damageDescription = callLogsField.damageDescription;
        let callRemark = callLogsField.callreason;
        let lossDateTime = callLogsField.datetime;
		let datetime;
        if (moment(lossDateTime, "DD-MM-YYYY HH:mm").isValid()) {
            datetime = moment(lossDateTime, "DD-MM-YYYY HH:mm").format("YYYY-MM-DDTHH:mm:ssZ");
        }

    if((['Claim raised'].indexOf(consumerServiceRequest.Status)> -1 && callLogFlags.isConnectActionChecked == true)){
        if (callerName != '') {
            if(callLogsField.callDispositionID != '' && callLogsField.callDispositionID != undefined){
                if(callLogsField.datetime != '' && callLogsField.datetime != undefined){
                    if(callLogsField.damageTypeID != '' && callLogsField.damageTypeID != undefined){
                        if (damageDescription != '') {
                            let data = {
                                'senderName': callerName,
                                'receiverUserId': consumerServiceRequest.ConsumerID,
                                'receiverRealm': "Consumer",
                                'ConsumerServiceRequestID': consumerServiceRequest.ConsumerServiceRequestID,
                                'BrandID':consumerServiceRequest.consumerProduct.BrandID,
                                'communicationDetail': {
                                    'callConnected': callLogFlags.isConnectActionChecked ,
                                    'callStatusId': callLogsField.callDispositionID,
                                    'lossDateTime': callLogsField.datetime,
                                    'damageId': callLogsField.damageTypeID,
                                    'descriptionOfLoss': damageDescription,
                                    'status': consumerServiceRequest.Status,
                                    'remark': callRemark,
                                    'firstDailerDispostion':callLogsField.firstDispositionSelectedText,
                                    'secondDailerDispostion':callLogsField.secondDispositionSelectedText
                                }
                            };
                            apiCall({
                                url: "createCommunicationLog",
                                data: data,
                                callBack: (result) => {
                                    setLoader(false);
                                    closeModal()
                                    if (result.success) {
                                        setShowModal({...showModal, showAdditionalCallModal:false});
                                        clearAllValue()
                                        getCommunicationLogs();
                                        getRequestById();
                                    } else {
                                        Swal.fire({
                                            icon: "error",
                                            text: localString(displayError(result)),
                                        });
                                    }
                                },
                            });
                        } else {
                            Swal.fire({
                                title:'',
                                text:localString("Please enter description of loss/damage"),
                                icon:'error'
                            })
                        }
                    } else {
                        Swal.fire({
                            title:'',
                            text:localString("Please enter type of damage"),
                            icon:'error'
                        })
                    }
                } else {
                    Swal.fire({
                        title:'',
                        text:localString("Please enter Date and Time of loss/damage"),
                        icon:'error'
                    })
                }
            } else {
                Swal.fire({
                    title:'',
                    text:localString("Please enter Disposition reason"),
                    icon:'error'
                })
            }
        } else {
            Swal.fire({
                title:'',
                text:localString("Please enter your name"),
                icon:'error'
            });
        }
    } else {
        if (callerName != '') {
            if(callLogsField.callDispositionID != '' && callLogsField.callDispositionID != undefined){
                if(callRemark != '') {
                    let data = {
                        'senderName': callerName,
                        'receiverUserId': consumerServiceRequest.ConsumerID,
                        'receiverRealm': "Consumer",
                        'ConsumerServiceRequestID': consumerServiceRequest.ConsumerServiceRequestID,
                        
                    }
                    if(pickupCarryInGSXChecks && consumerServiceRequest.consumerProduct.isAppleBrandID && accessFn('GSX_FLOW')){
                        data.communicationDetail = {
                            'callConnected': callLogFlags.isConnectActionChecked,
                            'callStatusId': callLogsField.callDispositionID,
                            'status': consumerServiceRequest.Status,
                            'remark': callRemark
                        }
                        if(selectedCallType == 'Inbound'){
                            data.senderName = "";
                            data.receiverName = callerName;
                            data.receiverUserId = JSON.parse(qFilter).userID;
                            data.receiverRealm =  "user";
                            data.senderUserId = consumerServiceRequest.ConsumerID;
                            data.senderRealm = 'Consumer';
                            data.communicationDetail.callIncoming = true;
                        }
                    }else{
                        data.BrandID = consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID
                        data.communicationDetail = {
                            'callConnected': callLogFlags.isConnectActionChecked,
                            'callStatusId': callLogsField.callDispositionID,
                            'status': consumerServiceRequest.Status,
                            'remark': callRemark,
                            'firstDailerDispostion':callLogsField.firstDispositionSelectedText,
                            'secondDailerDispostion':callLogsField.secondDispositionSelectedText
                        }
                    }
                    apiCall({
                        url: "createCommunicationLog",
                        data: data,
                        callBack: (result) => {
                            setLoader(false);
                            closeModal()
                            if (result.success) {
                                getRequestById();
                                // getCommunicationLogs()       // which API gets called (verify once)
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    text: localString(displayError(result)),
                                });
                            }
                        },
                    });
                }  else {
                    Swal.fire({
                        title:'',
                        text:localString("Please enter the reason for call"),
                        icon:'error'
                    })
                }
            } else {
                    Swal.fire({
                        title:'',
                        text:localString("Please enter Disposition reason"),
                        icon:'error'
                    })
                }
        } else {
                Swal.fire({
                    title:'',
                    text:localString("Please enter your name"),
                    icon:'error'
                })
            }
        }
    };
    const initSecondDiposite = (val)=>{
        let secondCallDispositionArr = [];
        forEach(secondDispositionData,function(value,key){
            if(val.displayText === key){
                secondCallDispositionArr.push(value);
            }
        })
        secondCallDispositionArr = flattenDeep(secondCallDispositionArr)
        let secondCallArr = secondCallDispositionArr.map(item=>({displayText:item}));
        let secondCallList = secondCallArr.map(v=>({...v,value:v.displayText}))
        setSelectSecondDisposition(secondCallList);
    }
    const getTypeOfDamage = (claimDamageId) =>{
        let damage = find(damageTypeArr, {
            id: claimDamageId
        });
        if (damage && damage.displayText) {
            return damage.displayText;
        } else {
            return '-';
        }
    }
    
    const initDamageTypes = (damageTypeArr)=>{
        let typeOfDamageList = damageTypeArr.map(v=>({...v,value:v.displayText}));
        setSelectTypeOfDamage(typeOfDamageList);
    }

    const checkDamageDetailsValue = (e)=>{
        if(callLogsField.damageDescription && callLogsField.damageDescription.length-1  >= 100){
            setCharacterCount('');
        }else{
            let localCharacterCount =   ( 100 -  (+callLogsField.damageDescription && callLogsField.damageDescription.length ) );
            setCharacterCount(localCharacterCount);
        }
    }
    
    const enableCallSaveButton = () =>{
        let saveBtn ;
        if((['Claim raised'].indexOf(consumerServiceRequest.Status)> -1 && callLogFlags.isConnectActionChecked == true)){
            saveBtn = (callLogsField.username != "" && callLogsField.callDisposition != "" && callLogsField.firstDispositionSelectedText != "" &&  callLogsField.secondDispositionSelectedText != "" && callLogsField.datetime != "" && callLogsField.damageType != "" && callLogsField.damageDescription.length >= 100 ? false : true)
            setCallLogFlags({...callLogFlags,saveCallBtn:saveBtn})
        }
        else if(callLogFlags.isConnectActionChecked == true){
            saveBtn = (callLogsField.username != "" && callLogsField.callDisposition != "" && callLogsField.firstDispositionSelectedText != "" && callLogsField.secondDispositionSelectedText != "" && callLogsField.callreason != '' ? false : true);
            setCallLogFlags({...callLogFlags,saveCallBtn:saveBtn})
        }
		else{
            saveBtn = (callLogsField.username != "" && callLogsField.callDisposition != "" &&  callLogsField.callreason != '' ? false : true);
            setCallLogFlags({...callLogFlags,saveCallBtn:saveBtn})
		}
		
    }
    
    const radioInput = [
        {
            type:'radio',
            value:'Yes',
            name:'connectAction',
            radioText:'Yes',
            isSelect:logsSelectedValue,
            onUpdateRadio:(val)=>{
                setLogsSelectedValue(val.target.value)
                content(val.target.value)
            }
        },
        {
            type:'radio',
            value:'No',
            name:'connectAction',
            radioText:'No',
            isSelect:logsSelectedValue,
            onUpdateRadio:(val)=>{
                setLogsSelectedValue(val.target.value)
                content(val.target.value)
            }
        }
    ];

    const inputData = [
        {
            type: 'input',
            showInputField: callLogFlags.showFields,
            labelText: localString('Name of the Agent'),
            value:'username',
            onInputFun:(val)=>{
                setCallLogsField({...callLogsField,['username']:val});
            }
        },
        {
            type : 'dropdown',
            showInputField: callLogFlags.showFields,
            optionList : (callLogFlags.isConnectActionChecked || callLogFlags.isGSXCallTypeSelected) ? selectedCallType == "Inbound" ? GSXCallDisposition : selectCallConnected : selectCallNotConnected,
            labelText:  (callLogFlags.isConnectActionChecked || callLogFlags.isGSXCallTypeSelected) ? selectedCallType == "Inbound" ? localString('Call Disposition')+':' : localString('Connect Disposition')+':' : localString('Non-Connect Disposition')+':',
            value : 'callDisposition',
            onInputFun:(val)=>{
                setCallLogsField({...callLogsField,['callDisposition']:val.displayText,['callDispositionID']:val.id});
                setIsdisable({...isDisable,enableFirstDisposition:false});
            }
        },
        {
            type : 'dropdown',
            showInputField:!accessFn('GSX_FLOW') && (['Croma','CromaAPI','CromaCare','CromaDashboard','BoseDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source)==-1)  &&  callLogFlags.isConnectActionChecked ,
            optionList : selectFirstDisposition,
            labelText:localString('First Dialer Disposition'),
            value:'firstDispositionSelectedText',
            disable:isDisable.enableFirstDisposition,
            onInputFun:(val)=>{
                setCallLogsField({...callLogsField,['firstDispositionSelectedText']:val.displayText});
                setIsdisable({...isDisable,enableSecondDisposition:false});
                initSecondDiposite(val);
            }
        },
        {
            type : 'dropdown',
            showInputField: !accessFn('GSX_FLOW') && (['Croma','CromaAPI','CromaCare','CromaDashboard','BoseDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source)==-1)  && callLogFlags.isConnectActionChecked ,
            optionList : selectSecondDisposition,
            labelText: localString('Second Dialer Disposition'),
            value: 'secondDispositionSelectedText',
            disable:isDisable.enableSecondDisposition,
            onInputFun:(val)=>{
                setCallLogsField({...callLogsField,['secondDispositionSelectedText']:val.displayText});
            }
        },
        {
            type: 'dateTime',
            showInputField: ((pickupCarryInGSXChecks || pickupCarryInChecks || claimNewWorkshopChecks ) && callLogFlags.showRemainingFields  && (['Claim raised'].indexOf(consumerServiceRequest.Status)> -1)) || (onsiteChecks  && callLogFlags.showRemainingFields && ['Croma','CromaAPI','CromaCare','CromaDashboard','BoseDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source)==-1) ,
            labelText: localString('Date and Time of loss/damage:'),
            value:'datetime',
            maxDay: new Date(),
            dateFormat: 'DD-MM-YYYY',
            timeFormat: 'HH:mm',
            maxDay : new Date(),
            onInputFun:(val)=>{
                setCallLogsField({...callLogsField,['datetime']:moment(new Date(val)).format("DD-MM-YYYY HH:mm")})
            }
        },
        {   
            type: 'dropdown',
            showInputField: ((pickupCarryInGSXChecks || pickupCarryInChecks || claimNewWorkshopChecks )&& callLogFlags.showRemainingFields  && (['Claim raised'].indexOf(consumerServiceRequest.Status)> -1))||(onsiteChecks  && callLogFlags.showRemainingFields && ['Croma','CromaAPI','CromaCare','CromaDashboard','BoseDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source)==-1) ,
            optionList:selectTypeOfDamage,
            labelText: localString('Type of damage:'),
            value:'damageType',
            onInputFun:(val)=>{
                setCallLogsField({...callLogsField,['damageType']:val.displayText, ['damageTypeID']:val.id});
            }
        },
        {
            type: 'input',
            showInputField: ((pickupCarryInGSXChecks || pickupCarryInChecks || claimNewWorkshopChecks )&& callLogFlags.showRemainingFields  && (['Claim raised'].indexOf(consumerServiceRequest.Status)> -1))||(onsiteChecks  && callLogFlags.showRemainingFields && ['Croma','CromaAPI','CromaCare','CromaDashboard','BoseDashboard','VizioDashboard'].indexOf(consumerServiceRequest.Source)==-1) ,
            labelText: localString('Description of loss/damage:'),
            value:'damageDescription',
            characterCountText: (pickupCarryInChecks || claimNewWorkshopChecks) && localString("LEFT"),
            characterCount : (pickupCarryInChecks || claimNewWorkshopChecks) && characterCount,
            customCls: 'textArea',
            placeholder:localString("Please mention the incident in not less than 75 characters on how, when & where the damage happened"),
            onInputFun:(val)=>{
                setCallLogsField({...callLogsField,['damageDescription']: val})
            }
        },
        {
            type:'input',
            showInputField: callLogFlags.showFields,
            labelText: localString('Remarks:'),
            value:'callreason',
            customCls: 'textArea',
            onInputFun:(val)=>{
                setCallLogsField({...callLogsField,['callreason']: val})
            }
        },
    ]

    
    return(
        <>
            <CallAttemptUI 
                {...props}
                callLogFlags = {callLogFlags} 
                radioInput = {radioInput}
                inputData = {inputData}
                callLogsField = {callLogsField}
                checkDamageDetailsValue = {checkDamageDetailsValue}
                enterCallLog = {enterCallLog}
                characterCount = {characterCount}
                callTypes={callTypes}
                selectedCallType = {selectedCallType} setSelectedCallType = {setSelectedCallType}
            />
        </>
    )
}
export default CallAttemptInput;


