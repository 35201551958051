import React, { useState } from 'react';
import { Button, Checkbox, Modal } from 'sfy-react';

export default function VerifyPan(props) {
    const {
        localString, publicUrl, displayVerifyPanCard, userDetails, panObj, setDisplayVerifyPanCard, 
        setIsPANVerified, logInvalidAttempt, consentText, logConsent, user
    } = props;

    const [activeStep, setActiveStep] = useState('verify');
    const [consent, setConsent] = useState(false);

    return (
        <div>
            <Modal
                showModal={displayVerifyPanCard}
                className='verifyPanModal'
                // showCloseBtn={true}
            >
                <Modal.Body>
                    <div className='verifyPanModal-body'>
                        <div className='text-center'>
                            <div style={{ paddingTop: '40px' }}>
                                <img 
                                    src={publicUrl + "/imgs/success.svg"} 
                                    width='70px'
                                />
                            </div>
                            <h2 className='panHeader'>{localString("Verified!")}</h2>
                            { (activeStep == 'verify') && <div className='panSubHeader'>{localString('You have successfully verified PAN Number')}</div>}
                        </div>
                        <div className='panDetails'>
                            <div className='panItem' style={{ paddingBottom: '15px' }}>
                                <div className='panLabel'>{localString('PAN Number')}</div>
                                <div className='panItemValue'>{userDetails.panNo}</div>
                            </div>
                            <div className='panItem'>
                                <div className='panLabel'>{localString('Name on PAN')}</div>
                                {panObj && <div className='panItemValue'>{panObj.name}</div>}
                            </div>
                        </div>
                        {(activeStep == 'verify') && <div className='panVerifyButtons'>
                            <div>
                                <Button
                                    className="btn servify-blue-Btn w-100"
                                    onClick={() => {
                                        setActiveStep('consent');
                                    }}
                                >
                                    {localString('This is correct')}
                                </Button>
                            </div> 
                            <div>
                                <Button
                                    className="btn button-servify w-100"
                                    onClick={() => {
                                        logInvalidAttempt();
                                        setDisplayVerifyPanCard(false);
                                    }}
                                >
                                    {localString('This is not correct')}
                                </Button>
                            </div> 
                        </div>}
                        {(activeStep == 'consent') && <div className='userCheckBoxes panConsentBox'>
                            <Checkbox
                                value={consent}
                                name='first'
                                handleOnChange={(e) => {
                                    setConsent(!consent);
                                }}
                                isDisabled={!consentText}
                            >
                                { consentText && <span dangerouslySetInnerHTML={{__html: consentText}}></span> }
                                {/* {localString('I hereby confirm that: (i) the consent of the user has been obtained for providing the details herein; and (ii) the details provided are accurate. The user hereby acknowledges that they have read and agreed to the ')}<a href='https://servify.in/termsandconditions/servify' target='_blank'>{localString('Terms of Use')}</a>{` ${localString('and')} `}<a href='https://servify.in/privacy' target='_blank'>{localString('Privacy Policy')}</a>. */}
                            </Checkbox>
                        </div>}
                        {(activeStep == 'consent') && <div className='panVerifyButtons'>
                            <div>
                                <Button
                                    className="btn servify-blue-Btn w-100"
							        isDisabled={!consentText || !consent}
                                    onClick={() => {
                                        if(userDetails.userID && (user == 'edit')) {
                                            logConsent(userDetails.userID);
                                        }else {
                                            setIsPANVerified(true);
                                            setDisplayVerifyPanCard(false);
                                        }
                                    }}
                                >
                                    {localString('Confirm')}
                                </Button>
                            </div> 
                            <div>
                                <Button
                                    className="btn button-servify w-100"
                                    onClick={() => {
                                        setDisplayVerifyPanCard(false);
                                    }}
                                >
                                    {localString('Cancel')}
                                </Button>
                            </div> 
                            
                        </div>}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
