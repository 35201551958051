import React,{useState, useEffect}  from 'react';
import ReactDOM from 'react-dom';
import {Loader,Button} from 'sfy-react';
import Swal from "sweetalert2";
import {displayError,returnLocalDateTime,encrypt,returnLocalTime} from '../../../../../Utility/Utils'
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import './OtherRequest.scss'

const OtherRequest = (props) => {
	const {baseUrl,accessFn,productNotMapped,localString,consumerServiceRequest,apiCall,consumerDetail,viewUrl} = props;
	const [showLoader,setLoader]= useState(false);
	const [servicehistorydetails, setServicehistorydetails] = useState([])
	const [viewCount, setViewCount] = useState(2)
	const [viewCountStep, setViewCountStep] = useState(2)
	useEffect(() => {
		getServiceHistoryByBrand();
	}, [])

	const viewMore = ()=> {
		let countDiff = servicehistorydetails.length - viewCount;
		if (countDiff < viewCountStep){
			setViewCount(servicehistorydetails.length);
		}
		else{
			let count = viewCount + viewCountStep;
			setViewCount(count)
		}
	};

	const viewLess = ()=> {
		let countDiff = viewCount - viewCountStep;
		if ((countDiff - viewCountStep) < viewCountStep){    
			setViewCount(viewCountStep)
		}
		else{
			let count = viewCount - viewCountStep;
			setViewCount(count)
		}
	}

	const getServiceHistoryByBrand = () => {
		let data =  {
			ConsumerID: consumerDetail.ConsumerID,
			BrandID: (consumerServiceRequest.consumerProduct) ? consumerServiceRequest.consumerProduct.BrandID : null,
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
		}
		setLoader(true);
		// if (!productNotMapped) {
			apiCall({
				url:'getServiceHistoryByBrand',
				data: data,
				callBack:(result)=>{
					setLoader(false);
					if(result.success){
						setServicehistorydetails(result.data)
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		// }
	}

	return (
		<>
			{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			{
				// accessFn('REQUEST_HISTORY') && (accessFn('VIEW_QUEUE_ADMIN') || accessFn('VIEW_QUEUE_SH') || [9,13,18].indexOf(consumerServiceRequest.ServiceTypeID) == -1) &&
				// (accessFn('CLAIM_HISTORY_TAB')) || [10,11,12,14,15,16,18,38].indexOf(consumerServiceRequest.ServiceTypeID)== -1 &&
				<div className="inner-padding">
					<div className="row">
						<PageContentWrapper title={localString("Other Requests")}>
							<div className="request-panel request-history-div padding10 custom-other-request-tab">
								{
									!servicehistorydetails.length && 
									<div className="text-left">
										<p className='charges-panel-subheader text-capitalize remove-margin'>{localString("No Other Requests")} </p>
									</div>
								}
								<div className="service-history">
								{
									servicehistorydetails && servicehistorydetails.map((csr,i)=>{
										return(
											i < viewCount && 
											<div className="row history-device-name">
												<div className={accessFn('CLAIM_HISTORY_TAB')?"history-items col-sm-1 zero-right-padding zero-left-padding":"history-items col-sm-3 zero-right-padding zero-left-padding"}>
													<img src={csr && csr.consumerProduct && csr.consumerProduct.ProductSubcategory.ImageUrl} alt={csr && csr.ProductSubcategory && csr.ProductSubcategory.ProductSubCategory} />
												</div>
												<div class={accessFn('CLAIM_HISTORY_TAB')?"history-device-content col-sm-3 zero-right-padding":"history-device-content col-sm-7 zero-right-padding"}>
													{((csr && csr.consumerProduct && csr.consumerProduct.product && csr.consumerProduct.product.ProductName) || (csr.product.ProductName)) &&
														<p>
															{ (csr && csr.consumerProduct && csr.consumerProduct.product && csr.consumerProduct.product.ProductName) || (csr.product.ProductName)}
														</p>
													}
													<div>
														{localString("Ref Id")}: {csr && csr.ReferenceID}
													</div>
													<div>
														{localString("Started On")}: {returnLocalDateTime(csr && csr.ScheduledDateTime)}
													</div>
													{
														csr.InvoiceGeneratedDate && 
														<div>
															{localString("End on")}: {returnLocalDateTime(csr.InvoiceGeneratedDate)}
														</div>
													}
													{
														!csr.InvoiceGeneratedDate && 
														<div>
															{localString(csr.logs[0].Status)}
														</div>
													}
												</div>

												{accessFn('CLAIM_HISTORY_TAB') && <div className="history-device-content col-sm-2 zero-right-padding">
													<div>
														{localString("Final Status")}: {csr.FinalStatus||"NA"}
													</div>
													<div>
														{localString("Service Type")}: {csr.ServiceType || "NA"}
													</div>
												</div>}
												{accessFn('CLAIM_HISTORY_TAB') &&<div className="history-device-content col-sm-3 zero-right-padding">
													<div>
														{localString("Reason")}: {csr.Reason||"NA"}
													</div>
												</div>}
												{accessFn('CLAIM_HISTORY_TAB') &&<div className="history-device-content col-sm-2 zero-right-padding">
													<div>
														{localString("Close Date")}: {returnLocalTime(csr.ClosedDate)}
													</div>
												</div>}
												<div className={accessFn('CLAIM_HISTORY_TAB')?"col-sm-1 zero-left-padding zero-right-padding":"col-sm-2 zero-left-padding zero-right-padding"}>
													<a href={baseUrl+viewUrl+csr.ConsumerServiceRequestID} target="_blank">
														<input type="button" className="btn button-next" value="" />
													</a>
												</div>
											</div>
										)
									})
								}
								</div>
								<div className="text-right">
								{   
									viewCount > viewCountStep && 
									<Button 
										handleOnClick={viewLess} 
										type='submit' 
										classes='btn button-servify'>
										<span className="glyphicon glyphicon-chevron-up" aria-hidden="true"></span>
									</Button>
									
								}
								{
									viewCount < servicehistorydetails.length && 
									<Button 
										handleOnClick={viewMore} 
										type='submit' 
										classes='btn button-servify'>
										<span className="glyphicon glyphicon-chevron-down" aria-hidden="true"></span>
									</Button>
								}
								</div>
							</div>
						</PageContentWrapper>
					</div>
				</div>
			}
		</>
	)
}

export default OtherRequest
