import React from 'react';
import './RequestCount.scss'

const ConsumerServiceRequestCount = (props) => {
    const {localString,activeNav,setActiveNav,serviceRequestNav,showCount,returnCounts,navClick} = props;

    return (
        <div className="content-request">
        { showCount ? <div className="nav-bar" >
            <ul class="nav nav-item nav-justified">
            {
                serviceRequestNav && serviceRequestNav.map((nav,index)=>{
                    return(
                        
                        nav &&<li role="presentation"  className={( nav.name === activeNav)? "active":"" || (returnCounts(nav.name)==0 )?"disable-pointer"  :"" }  onClick={(e) => nav.navClick(nav.name )} >
                            <a href  >
                                <div className="nav-count">
                                    <span>
                                        <p className="nav-count-header" id={nav.name}>{returnCounts(nav.name) || '0'}</p>
                                        <p className="active-nav-bottom nav-count-subheader" >{localString(nav.displayName)}</p>
                                    </span>
                                        {/* <div className="loader">
                                            <div className={(nav.name===activeNav)?"loader__active":"" && "loader__figure"} ></div>
                                        </div>
                                 */}
                                    
                                </div>
                            </a>
                        </li>
                        
                    )
                })
            }
            </ul>
        </div>:""
        }
        </div>
    )
}
export default ConsumerServiceRequestCount;
