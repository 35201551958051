import React, { useState,useRef } from 'react';
import CloseVisitPendingUI from './CloseVisitPendingUI';
import { SERVICETYPE } from '../../../../../../Utility/Utils';

const CloseVisitPendingInputs=(props)=>{
  const {localString,partItem,showLoader,setpartItem,partJobFlag,setPartJobFlag,addJobPart,setAddJobPart,selectJobParts,setSelectJobParts,consumerServiceRequest,partSelected,availableQuantity,
    getAvailableQuantity,price,setPrice,partsArray, setPartsArray,showPartLoader}=props;

  const openImage =  (e)=> {
    e.preventDefault();
    window.open(e.target.name, '_blank');
  }

  const removeAddedPart = (id) =>{
    let removePart=partsArray && partsArray.filter(item=>id !== item.id)
    setPartsArray(removePart);
  };

  let partInputs = [
  {
    showPart:true,
    labelText:localString("Part"),
    dataKey:"part",
    showVisit:true,
    customClass:'col-md-4',
    type :'Dropdown',
    dropdownList:selectJobParts,
    oninputFunc: (val) => {
      setPartJobFlag({...partJobFlag,partSelected:true});
      
      partItem.taxSlab = val.TaxSlab;
      partItem.HSNCode = val.ChargeCode;
      setpartItem({...val,partItem});
      getAvailableQuantity(val);
      setPrice(val.Charge)
      setAddJobPart({...addJobPart,["part"]:`${val.Description}(${val.PartCode})`})
    }
  },
  {
    showPart:true,
    customClass:'col-md-3',
    labelText:localString('Remark'),
    dataKey:"remark",
    type :'input',
    oninputFunc: (val) => {
        setAddJobPart({ ...addJobPart, ['remark'] : val  });
    },
    
  },
  {
    showPart:[SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest && consumerServiceRequest.ServiceTypeID)==-1 && (!consumerServiceRequest.IsUnderWarranty || !consumerServiceRequest.IsWarrantyApplicable) ,
    labelText: localString("Warranty"),
    dataKey:"warranty",
    customClass:'marginB20',
    type :'input',
    oninputFunc: (val) => {
        setAddJobPart({ ...addJobPart, ['warranty'] : val  });
    },
  },
  {
    showPart:[SERVICETYPE.ON_SITE,SERVICETYPE.INSTALLATION,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID)>-1 || (!consumerServiceRequest.IsUnderWarranty || !consumerServiceRequest.IsWarrantyApplicable) ,
    labelText: localString("Price") ,
    type:'text',
    customClass:'col-md-1',
    partSelected:partJobFlag.partSelected?(price?price:'NA'):''
  },
  {
    showPart:true,
    showLoader:showPartLoader,
    labelText:localString("Avl. Quantity") ,
    customClass:'col-md-2 ',
    type:'text',
    partSelected :partJobFlag.partSelected?(availableQuantity?availableQuantity:'NA'):'',
  },{
    showPart:true,
    labelText:localString("Add") ,
    customClass:'col-md-1 ',
    type:'button',
  }
  ];

  let tableInput =[
    {
      labelText:localString("Part Name"),
      customClass:'width15'
    },                             
    {
      labelText:localString("Part Code"),
      customClass:'width15'
    },
		{
      labelText:localString("HSN Code"),
      customClass:'width15'
    },
		{
      labelText:localString("Part Warranty"),
      customClass:'width10'
    },
    {
      labelText:localString("Part Price"),
      customClass:'width10'
    },
		{
      labelText:localString("Remarks"),
      customClass:'width10'
    },
    {
      labelText:`Qty ${consumerServiceRequest && consumerServiceRequest.showEngineerVisits?'to Issue':''}`,
      customClass:'width5'
    },
		{
      labelText:localString("Document"),
      customClass:'width10'
    },
  ]

    return(
        <>
            <CloseVisitPendingUI 
              {...props}
              partInputs={partInputs} 
              partSelected={partJobFlag.partSelected}
              tableInput={tableInput}
              openImage={openImage}
              removeAddedPart ={removeAddedPart }
            />
        </>
    )
}
export default CloseVisitPendingInputs;


