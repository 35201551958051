import React from 'react'
import {Modal, Dropdown, Button} from 'sfy-react';
import './PSLModal.scss';

const PSLModal = (props) => {
	const { showServicelocationModal, localString, closeModal, PSLData, setPSLData, partnerLocationList, setPSLSearchText, 
		loadOffers, selectedPSL, setSelectedPSL} = props;
	return (
		<div className="">
			<Modal
				showModal={showServicelocationModal}
				showCloseBtn={true}
				closeModal={closeModal}
				className={"default-modal modal-width-common"}
			>
				<Modal.Header>
					<h3
						className="mb20 text-center"
						style={{
							fontWeight: 600,
							marginTop: "auto",
							fontSize: "20px",
						}}
					>
						{localString("Select Store Location")}
					</h3>
				</Modal.Header>
				<Modal.Body className={"location-modal-body"}>
					<h4 className="text-left-box">
						{localString("Location")}
					</h4>
					<Dropdown
						id="dropdown"
						value={PSLData.ServiceLocationName}
						options={partnerLocationList}
						handleOnChange={(val) => {
							setPSLData({
								...PSLData,
								ServiceLocationName: val.ServiceLocationName,
								PartnerServiceLocationID: val.PartnerServiceLocationID,
								PartnerID: val.PartnerID
							});
							// closeModal();
						}}
						handleOnSearch={(e) => {
							setPSLSearchText(e.target.value);
							return partnerLocationList;
						}}
						classObject={{
							container: "psl-dropdown",
						}}
						filterBy="value"
						placeholder="Search"
						labelFloat={false}
						searchText="Search"
						showNoOptionsText={false}
					/>
				</Modal.Body>
				<Modal.Footer
					className={"modal-footer-common sales-footer"}
				>
					<Button
						type="submit"
						handleOnClick={() => {
							// closeModal()                            
							// dispatch(setSelectedPSL(tempSelectedPSL));
							loadOffers();
							closeModal();
						}}
						className={
							"button button-primary mt20 max-width-range"
						}
					>
						{localString("Apply")}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	)
}

export default PSLModal