import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Swal from "sweetalert2";
import { Button, Modal, Loader, Pagination } from 'sfy-react';
import moment from 'moment';
import Papa from 'papaparse';

import CommonTable from '../../../../components/Table/Table';
import { displayError, downloadCSV, encrypt, returnLocalDateTime } from '../../../../Utility/Utils';
import ReportPageHeader from '../../ReportPageHeader';
import FilterModal from '../../../../components/FilterModal/FilterModal';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import './TlAttendanceReport.scss'

let defaultFilters = {
	shop: '',
	branch: '',
	advisor: '',
	dateRange: {
		startDate: new Date(moment().subtract(1, 'days').startOf('month')),
		endDate: new Date(moment().subtract(1,'days'))
	}
}
export default function TlAttendanceReport(props) {
	const { accessFn, localString, apiCall, titleHeader } = props;
	const [loader, setLoader] = useState(false);
	const [data, setData] = useState([]);
	const [showModal , setShowModal] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [mainFilters, setMainFilters] = useState(defaultFilters);
	const [branchList, setBranchList] = useState([]);
	const [branchObj, setBranchObj] = useState([]);
	const [advisorList, setAdvisorList] = useState([]);
	const [advisorObj, setAdvisorObj] = useState([]);
	const [pagination , setPagination] = useState({
		pageNo : 1,
		itemsPerPage : 30,
		count: 0,
		numberOfPages: 0
	});
	const lastDay = moment().subtract(1,'days').format("YYYY-MM-DD");

	const buttonHeader = [
		{
			buttonText: localString('Filter Requests'),
			btnIcon: 'glyphicon glyphicon-filter',
			showButton: true,
			onButtonClick: () => {
				setShowModal(true);
			}
		},
		{
			buttonText: localString('TL Attendance'),
			btnIcon: 'glyphicon glyphicon-download',
			showButton: true,
			onButtonClick: () => {
				getAttendanceReport(true)
			}
		},
		{
			buttonText: localString('TL Field Visit'),
			btnIcon: 'glyphicon glyphicon-download',
			showButton: true,
			onButtonClick: () => {
				getAllTLfieldVisitReport();
			}
		},
	];

	const getAttendanceReport = (downloadFile) => {
		setLoader(true);
		let reqObj = {
			RetailUserID: filters.advisor ? [advisorObj.RetailUserID] : [],
			ShopCode: [],
			Type: "TotalAttendance",
			branchCode: filters.branch ? [branchObj.BranchID] : [],
			eDate: moment(filters.dateRange.endDate).format("YYYY-MM-DD"),
			sDate: moment(filters.dateRange.startDate).format("YYYY-MM-DD"),
			tlReports: true
		}
		if(!downloadFile) {
			reqObj.Limit = pagination.itemsPerPage;
			reqObj.PageNo = pagination.pageNo;
		}
		apiCall({
			url: 'getAttendanceReport',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data && !downloadFile) {
					setData(result.data.AttendanceDetails);
					setPagination({...pagination, 
						count: result.data && result.data.length,
						numberOfPages: result.data && result.data.numberOfPages
					})
				}else if(result.success && result.data && downloadFile) {
					let arr = result.data.AttendanceDetails
					let csv = Papa.unparse({
						fields: [
							'Name',
							'AdvisorCode',
							'Branch',
							'MobileNo',
							'present',
							'leave',
							'Unmarked',
							'weekly off',
							'public holiday',
							'meeting'
						],
						data: arr,
					});
					let CapsString
					let capital = Papa.parse(csv,{
						beforeFirstChunk :function(chunk) {
							let rows = chunk.split( /\r\n|\r|\n/ );
							let result = [];
							let row = rows[0];
							let a = row.split(',');
							for (let iCount = 0;iCount<a.length;iCount++) {
							if(a[iCount] == "AdvisorCode"){
								a[iCount] = 'TL CODE'
							}
							let item = a[iCount].replace(/([a-z])([A-Z])/g, '$1 $2');
							result.push(item)
							}
							CapsString = result.toString().toUpperCase();
							rows[0] = CapsString;
							return rows.join("\r\n");
						},
					});
					let csvString = Papa.unparse(capital);
					downloadCSV(csvString, 'TL-Attendance:-'+ moment(filters.dateRange.startDate).format("DD-MM-YYYY") +' - '+ moment(filters.dateRange.endDate).format("DD-MM-YYYY") +'.csv')                    
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		getAttendanceReport();
	}, [mainFilters, pagination.pageNo])

	const downloadPersonalReport = (RetailUserID, name) => {
		setLoader(true);
		let reqObj = {
			RetailUserID: [RetailUserID],
			ShopCode: [],
			Type: "PersonalAttendance",
			branchCode: [],
			eDate: moment(filters.dateRange.endDate).format("YYYY-MM-DD"),
			sDate: moment(filters.dateRange.startDate).format("YYYY-MM-DD"),
			"tlReports": true
		}
		apiCall({
			url: 'getAttendanceReport',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let attendanceDetails = result.data.AttendanceDetails;
					let attendanceArr = [];
					attendanceDetails.length && attendanceDetails.map((detail, k) => {
						let obj = {};
						obj.Date = detail.Date;
						obj.Shop = detail.Shop;
						obj.CheckInDate = detail.CheckInDate ? moment(detail.CheckInDate, "DD-MM-YYYY HH:mm").format("HH:mm") : '-';
						obj.CheckOutDate = detail.CheckOutDate ? moment(detail.CheckOutDate, "DD-MM-YYYY HH:mm").format("HH:mm") : '-';
						obj.Status = detail.Status;
						attendanceArr.push(obj)
					});
					let attendanceCsv = Papa.unparse({
						fields: [
							'Date',
							'Shop',
							'CheckInDate',
							'CheckOutDate',
							'Status',
						],
						data: attendanceArr
					});
					// ToDo: Common handler to capitalize first row in reports
					let CapsString;
					let capital = Papa.parse(attendanceCsv, {
						beforeFirstChunk: function(chunk) {
							let rows = chunk.split( /\r\n|\r|\n/ );
							let result = [];
							let row = rows[0];
							let a = row.split(',');
							for (let iCount = 0;iCount<a.length;iCount++) {
								if(a[iCount] == "CheckInDate"){
									a[iCount] = 'TimeIn';
								}
								else if(a[iCount] == "CheckOutDate"){
									a[iCount] = 'TimeOut';
								}
							let item = a[iCount].replace(/([a-z])([A-Z])/g, '$1 $2');
							result.push(item);
							}
							CapsString = result.toString().toUpperCase();
							rows[0] = CapsString;
							return rows.join("\r\n");
							},
						});
					let advisorData = result.data.AdvisorDetails;
					let advisorDetails =
						"NAME" +","+(advisorData[0].Name ? advisorData[0].Name : '-') + "\r\n" +
						"BRANCH CODE" +","+(advisorData[0].branchCode ? advisorData[0].branchCode : '-') + "\r\n" +
						"MOBILE NO" +","+(advisorData[0].MobileNo ? advisorData[0].MobileNo : '-') + "\r\n" +
						"BRANCH" +","+(advisorData[0].Branch ? advisorData[0].Branch :"-");
					let attendanceDetailsCsv = Papa.unparse(capital);
					let csv = advisorDetails + "\r\n" + "\r\n" + attendanceDetailsCsv;
					downloadCSV(csv, name + '.csv');
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const getAllTLfieldVisitReport = () => {
		setLoader(true);
		let reqObj = {
			eDate: moment(filters.dateRange.endDate).format("YYYY-MM-DD"),
			sDate: moment(filters.dateRange.startDate).format("YYYY-MM-DD"),
		}
		if(filters.advisor) reqObj.advisorCode = advisorObj.RetailUserID;
		if(filters.branch) reqObj.branchCode = branchObj.BranchID;        
		apiCall({
			url: 'getAllTLfieldVisitReport',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let fieldVisitData = result.data && result.data.filter((detail) => {
						let obj = {};
						obj.ThirdPartyBranchName = detail['ThirdPartyBranchName'] || "-";
						obj.TeamLeaderCode = detail['TeamLeaderCode'] || "-";
						obj.TeamLeaderName = detail['TeamLeaderName'] || "-";
						obj.ShopCode = detail['ShopCode'] || "-";
						obj.ShopName = detail['ShopName'] || "-";
						obj.CheckInDate = detail['CheckInDate'] ? returnLocalDateTime(detail['CheckInDate']) : "-";
						obj.CheckOutDate = detail['CheckOutDate'] ? returnLocalDateTime(detail['CheckOutDate']) : "-";
						return obj;
					});
					let fieldVisitCsv = Papa.unparse({
						fields: [
							'ThirdPartyBranchName',
							'TeamLeaderCode',
							'TeamLeaderName',
							'ShopCode',
							'ShopName',
							'CheckInDate',
							'CheckOutDate'
						],
						data: fieldVisitData
					});
					// ToDo: Common handler to capitalize first row in reports
					let CapsString;
					let capital = Papa.parse(fieldVisitCsv, {
						beforeFirstChunk: function(chunk) {
							let rows = chunk.split( /\r\n|\r|\n/ );
							let result = [];
							let row = rows[0];
							let a = row.split(',');
							for (let iCount = 0; iCount < a.length; iCount++) {
								if(a[iCount] == "CheckInDate"){
									a[iCount] = 'Check In Date & Time';
								}
								if(a[iCount] == "CheckOutDate"){
									a[iCount] = 'Check Out Date & Time';
								}
								if(a[iCount] == "ThirdPartyBranchName"){
									a[iCount] = 'Branch Name';
								}
								let item = a[iCount].replace(/([a-z])([A-Z])/g, '$1 $2');
								result.push(item);
							}
							CapsString = result.toString().toUpperCase();
							rows[0] = CapsString;
							return rows.join("\r\n");
						},
					});
					let csvString = Papa.unparse(capital);
					downloadCSV(csvString, 'TLFieldVisitReport:-'+ moment(filters.dateRange.startDate).format("DD-MM-YYYY") +' - '+ moment(filters.dateRange.endDate).format("DD-MM-YYYY") +'.csv')                    
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const branchMasterGetFilter = () => {
		apiCall({
			url: 'branchMasterGetFilter',
			data: {},
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(val => ({ ...val, value: val.displayDetails}))
					setBranchList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		showModal && branchMasterGetFilter();
	}, [showModal])

	const getAdvisorsList = () => {
		let obj = {
			branchCode: filters.branch ? branchObj.BranchID : null,
			resetAction: null,
			noAdvisor: true
		}
		apiCall({
			url: 'getAdvisorsList',
			data: obj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(val => ({...val, value: val.displayDetails}))
					setAdvisorList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		showModal && getAdvisorsList();
	}, [showModal, filters.branch, filters.shop])

	const columns = [
		{
			title: localString("TL Name"),
			showHeading: true,
			field: 'Name',
			customCell: (row) => {
				return (
					<>
						<Tooltip
							content={() => {
								return (
									<div>
										<div className='reportTooltipHeader'>{row.Name}</div>
										<div className='reportTooltipData'>
											<div>Branch: {row.Branch}</div>
											<div>Mobile No: {row.MobileNo}</div>
										</div>
									</div>
								)
							}}
							className={{
								tooltipText: 'reportTooltipText'
							}}
							position='right'
						>
							<div style={{ borderBottom: '1px dotted black' }}>{row.Name}</div>
						</Tooltip>
					</>
				);
			}
		},
		{
			title: localString("TL Code"),
			showHeading: true,
			field: 'AdvisorCode',
			customCell: (row) => row?.AdvisorCode || '--',
		},
		{
			title: localString("Present"),
			showHeading: true,
			field: 'present',
			customCell: (row) => row?.present || '--',
		},
		{
			title: localString("Weekly Off"),
			showHeading: true,
			field: 'weekly off',
			customCell: (row) => row && row['weekly off'] || '--',
		},
		{
			title: localString("Public Holiday"),
			showHeading: true,
			field: 'public holiday',
			customCell: (row) => row && row['public holiday'] || '--',
		},
		{
			title: localString("Leave"),
			showHeading: true,
			field: 'leave',
			customCell: (row) => row?.leave || '--',
		},
		{
			title: localString("Unmarked"),
			showHeading: true,
			field: 'Unmarked',
			customCell: (row) => row?.Unmarked || '--',
		},
		{
			title: localString("Meeting"),
			showHeading: true,
			field: 'meeting',
			customCell: (row) => row?.meeting || '--',
		},
		{
			title: localString("Download"),
			showHeading: true,
			customCell: (row) => {
				return (
				<>
					{ 
						row.downloadOption ? 
							<div className="download-icon" title="Download Report" onClick={() => downloadPersonalReport(row.RetailUserID, row.Name)} data-toggle="tooltip" data-placement="right">
								<div className="download-image" id=""></div>
							</div> : 
							'--'}
				</>
				);
			}
		},
		{
			title: localString("Visit Details"),
			showHeading: true,
			customCell: (row) => {
				return (
					<>
						{
							row.downloadOption ? 
								<Button
									type='button'
									classes='button-ghost'
									style={{ width: 'auto' }}
									onClick={() => {
										localStorage.setItem('startDate', filters.dateRange.startDate);
										localStorage.setItem('endDate', filters.dateRange.endDate);
										window.open(`/retailerReports/tlattendanceView/view?id=${encrypt(row.RetailUserID)}&name=${encrypt(row.Name)}`);
									}}
								>{localString('View')}</Button> :
								'--'
						}
					</>
				);
			}
		},
	];

	let filterInputList = [
		{
			labelText: localString('Date'),
			dataKey: "dateRange",
			customClass:'col-sm-12 paddingB10',
			showFilter: true,
			type: 'DateRangePicker',
			maxDay: new Date(lastDay),
			oninputFunc: (val) => {
				setFilters({ ...filters, dateRange: val });
			}
		},
		{
			labelText: localString('Branch Code'),
			dataKey: "branch",
			customClass:'col-sm-12 paddingB10',
			showFilter: true,
			type: 'Dropdown',
			dropdownList: branchList,
			oninputFunc: (val) => {
				setBranchObj(val);
				setAdvisorObj(null);
				setFilters({ ...filters, branch: val.displayDetails, shop: '', advisor: '' })
			}
		},
		{
			labelText: localString('TL Name'),
			dataKey: "advisor",
			customClass:'col-sm-12 paddingB10',
			showFilter: true,   
			type: 'Dropdown',
			dropdownList: advisorList,
			oninputFunc: (val) => {
				setAdvisorObj(val);
				setFilters({ ...filters, advisor: val.displayDetails })
			}
		},
	]

	return (
		<div className='tlAttendanceReport'> 
			{
				loader && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<ReportPageHeader 
				titleHeader={titleHeader}
				localString={localString}
				btnClass='btn button-servify header-btn button-stack text-capital'
				headerButtonArray={buttonHeader} 
				showHeaderContent={true}
				headerContent={() => {
					return (
						<div style={{ display: 'inline-block' }}>
							<span class="header-date-lowercase">
								({moment(filters.dateRange.startDate).format("DD-MM-YYYY")} to {moment(filters.dateRange.endDate).format("DD-MM-YYYY")})
							</span>
						</div>
					);
				}}
			/>
			<FilterModal
				{...props}
				filterFunc={(e) => {
					e.preventDefault();
					setMainFilters(filters);
					setPagination({...pagination, pageNo: 1})
					setShowModal(false);
				}}
				filterInputs={filterInputList}
				filterData={filters}
				showModal={showModal}
				closeModal={() => {
					setFilters(mainFilters)
					setShowModal(false);
				}}
				hideClearButton={false}
				clearSearch={() => {
					setFilters(defaultFilters);
					setMainFilters(defaultFilters);
					setPagination({...pagination, pageNo: 1})
				}}
				
			/>
			<CommonTable 
				columns={columns}
				data={data}
				localString={localString}
			/>
			{
				pagination.numberOfPages > 1 && 
					<Pagination
						itemsPerPage={pagination.itemsPerPage}
						pages={pagination.numberOfPages}
						totalItems={pagination.count}
						activePage={pagination.pageNo}
						paginate={(pageNumber) => setPagination({...pagination, pageNo: pageNumber})}
					/> 
			}
		</div>
	);
}