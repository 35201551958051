import React from "react";
import "./RuleManager.scss";

const ShadowWrapper = (props) => {
  return (
    <div className={`shadow-wrapper ${props.classname && props.classname}`}>
      {props.children}
    </div>
  );
};

export default ShadowWrapper;
