import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader, Dropdown, Button, Input} from 'sfy-react';
import Swal from "sweetalert2";
import {find, filter, result,} from 'lodash';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import {displayError, generateUUID} from '../../../../../Utility/Utils';
import './SupportingDocuments.scss'
import UploadingMode from './UploadingMode';


const SupportingDocuments = (props) => {
	const {apiCall, localString, consumerServiceRequest, docmaster, docServiceUrl, useDocServiceReqDoc, authorization, getRequestById} = props;
    const [loader, setLoader] = useState(false);
    const [isAdditionMode, setIsAdditionMode] = useState(false);
    const [isValidateMode, setIsValidateMode] = useState(false)
    const [isSavedDocsFetched, setIsSavedDocsFetched] = useState(false);
    const [isDocmasterProcessed, setIsDocmasterProcessed] = useState(false)
    const [identityDocs, setIdentityDocs] = useState([])
    const [damageDocs, setDamageDocs] = useState([])
    const [remainingDocs, setRemainingDocs] = useState([])
    const [additionalComments, setAdditionalComments] = useState("")
    const [identificationObjects, setIdentificationObjects] = useState([{
        fileUploaded: false,
        proofOfDevice: ""
    }]);
    const [damageObjects, setDamageObjects] = useState([{
        fileUploaded: false,
        proofOfDamage: ""
    }]);
    const [savedSupportingDocuments, setSavedSupportingDocuments] = useState(null)
    const [pendingDocuments, setPendingDocuments] = useState(null)

    useEffect(() => {

        // self.docMaster = _.cloneDeep(opts.docmaster);
        const validateLog = find(consumerServiceRequest.logs, {Status: "Supporting document validation pending"});

        const claimEstimation = find(consumerServiceRequest.logs, {Status: "Claim estimation generated"});

        let additionMode = ['Supporting documents required'].indexOf(consumerServiceRequest.Status) > -1 && !validateLog ? true : false;
        setIsAdditionMode(additionMode)

        let validateMode = ['Supporting document validation pending'].indexOf(consumerServiceRequest.Status) > -1 ? true : false;
        setIsValidateMode(validateMode)
        let isViewMode = claimEstimation ? true : false;

        if(!isSavedDocsFetched && !isAdditionMode && (['Supporting document validation pending', 'Supporting documents required', 'Claim estimation generated'].indexOf(consumerServiceRequest.Status) > -1 || claimEstimation)) {
            setIsSavedDocsFetched(true);
            getSupportingDocuments();
        }
    },[consumerServiceRequest])

    useEffect(() => {
        if(!isDocmasterProcessed && docmaster && docmaster.length > 0) {
            processDocMaster();
        }
    }, [docmaster])

    const processDocMaster = () => {
        let identityDocs = [];
        let damageDocs = [];
        let remainingDocs = [];
        docmaster && docmaster.forEach((obj)=>{
            if(obj.ParentDocumentType == 'Proof of Device Identity') {
                identityDocs.push(obj);
            }else if(obj.ParentDocumentType == 'Proof of Damage/Issue') {
                damageDocs.push(obj);
            } else {
                remainingDocs.push(obj);
            }
        })
        
        let identityDocsDropdown = identityDocs && identityDocs.map(v=>({...v,value:v.DocumentType}))
        setIdentityDocs(identityDocsDropdown)
        let identityArr = [...identificationObjects]
        identityArr[0].proofOfDevice = identityDocsDropdown && identityDocsDropdown[0].DocumentType;
        identityArr[0].ParentDocumentType = identityDocsDropdown && identityDocsDropdown[0].ParentDocumentType;
        identityArr[0].DocID = identityDocsDropdown && identityDocsDropdown[0].DocumentID;

        let damageDocsDropdown = damageDocs && damageDocs.map(v=>({...v,value:v.DocumentType}))
        setDamageDocs(damageDocsDropdown)
        let damageArr = [...damageObjects]
        damageArr[0].proofOfDamage = damageDocsDropdown && damageDocsDropdown[0].DocumentType;
        damageArr[0].ParentDocumentType = damageDocsDropdown && damageDocsDropdown[0].ParentDocumentType;
        damageArr[0].DocID = damageDocsDropdown && damageDocsDropdown[0].DocumentID;

        let remainingDocsDropdown = remainingDocs && remainingDocs.map(v=>({...v,value:v.DocumentType}))
        setRemainingDocs(remainingDocsDropdown)
        
        setIsDocmasterProcessed(true);
    };

    const getSupportingDocuments = () => {
        let data = {
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
        };
		setLoader(true);
        apiCall({
			url: 'getSupportingDocuments',
            data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    let savedSupportingDoc = result.data.supportingDocuments ? result.data.supportingDocuments : null;
                    setSavedSupportingDocuments(savedSupportingDoc)
                    let pendingDoc = result.data.pendingDocument ? result.data.pendingDocument : null;
                    setPendingDocuments(pendingDoc)
                    let additionalComm = result.data.additionalComment;
                    setAdditionalComments(additionalComm)
				}
			}
		});
    }

    const addIdentityObj = () => {
        let addedIdentificationObject = [...identificationObjects];
        addedIdentificationObject.push({fileUploaded: false,proofOfDevice:identityDocs[0].DocumentType});
        setIdentificationObjects(addedIdentificationObject)
    };

    const addDamageObj = () => {
        let damageObj = [...damageObjects];
        damageObj.push({fileUploaded: false,proofOfDamage:damageDocs[0].DocumentType});
        setDamageObjects(damageObj)
    }

    const removeFile = () => {

    }

    let uploadedDocs = [];
    const getUploadedDocs = () => {
        identificationObjects.forEach((obj) => {
            if(obj.fileUploaded && obj.docs) {
                uploadedDocs.push(obj.docs);
            }
        });

        damageObjects.forEach((obj) => {
            if(obj.fileUploaded && obj.docs) {
                uploadedDocs.push(obj.docs);
            }
        });
        return uploadedDocs;
    };

    const uploadDocuments = (uploadedFile, index, sectionName, isRejectedReplacement = false) => {
        let dataImgType = 'image';
        let newfile = uploadedFile;
        if (newfile.length > 0) {
            let fileType = newfile[0].type;
            let ext = fileType.split("/").length ? "."+fileType.split("/")[fileType.split("/").length - 1] : "";
            let key = "FEWEBA" + generateUUID() + ext;
            if (fileType == 'application/pdf') {
                dataImgType = 'pdf';
            }
            if (useDocServiceReqDoc) {
                if (docServiceUrl == '') {
                    return;
                }
                let dataNew = new FormData();
                dataNew.append('file', newfile[0]);
                dataNew.append('docType', 'ConsumerServiceRequestDocument');
                dataNew.append('clientRefId', key);
                dataNew.append('entity-type', 'ConsumerServiceRequestID');
                dataNew.append('entity-id', consumerServiceRequest.ConsumerServiceRequestID);
                // self.parent.tabLoader = true;
                let url = docServiceUrl + 'internal/document/upload';
				setLoader(true);
				fetch(url, {
					method: 'POST',
					headers: {
						'App': 'WebApp',
						'module': 'WebApp',
						'authorization': authorization
					},
					body: dataNew
				})
				.then(res => res.json())
				.then(result => {
					setLoader(false);
					if(result.success && result.data) {
						// self.parent.tabLoader = false;
                        let uploadURL = result.data.uploadURL;
                        let fileNameUUID = result.data.docServiceUUID;
                        if(sectionName == "identity-file") {
                            let identityObj = [...identificationObjects];
                            identityObj[index].fileUploaded=true;
                            identityObj[index].fileName = newfile[0].name
                            identityObj[index][isRejectedReplacement ? 'rejectionRepalcement' : 'docs'] = {
                                ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                                ConsumerProductID: consumerServiceRequest.ConsumerProductID,
                                ParentDocumentType: identityObj[index].ParentDocumentType,
                                DocumentID: identityObj[index].DocID,
                                FilePath: uploadURL,
                                FileName: fileNameUUID,
                                Type: dataImgType,
                                Tag: identityObj[index].proofOfDevice,
                                app: 'WebApp',
                                DocDetails : {
                                    "is_accepted_or_rejected" : -1
                                },
                                PendingDocID: "",//e.item.singleObj.PendingDocID
                            }
                            setIdentificationObjects(identityObj)
                        }

                        if(sectionName == "damage-file") {
                            let damageObj = [...damageObjects];
                            damageObj[index].fileUploaded=true;
                            damageObj[index].fileName = newfile[0].name
                            damageObj[index][isRejectedReplacement ? 'rejectionRepalcement' : 'docs'] = {
                                ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
                                ConsumerProductID: consumerServiceRequest.ConsumerProductID,
                                ParentDocumentType: damageObj[index].ParentDocumentType,
                                DocumentID: damageObj[index].DocID,
                                FilePath: uploadURL,
                                FileName: fileNameUUID,
                                Type: dataImgType,
                                Tag: damageObj[index].proofOfDamage,
                                app: 'WebApp',
                                DocDetails : {
                                    "is_accepted_or_rejected" : -1
                                },
                                PendingDocID: "",//e.item.singleObj.PendingDocID
                            }
                            setDamageObjects(damageObj)
                        }
                    }else{
                        Swal.fire({  
                            icon: 'error',  
                            text: localString("No file found","Please upload a csv file"),  
                        }); 
                        let identityObj = [...identificationObjects];
                        identityObj[index].fileUploaded=false;
                        identityObj[index].fileName = "";
                        identityObj[index][isRejectedReplacement ? 'rejectionRepalcement' : 'docs'] = null;
                    }
				})
            } else {
                // self.parent.tabLoader = true;
                // self.parent.update();
                // services.callApi({
                //     url: 'uploadDocuments',
                //     data: {
                //         Type: fileType,
                //         Key: key,
                //         BrandID: self.csr && self.csr.consumerProduct && self.csr.consumerProduct.BrandID ? self.csr.consumerProduct.BrandID : undefined
                //     },
                //     context: this,
                //     showLoader: false,
                //     cb: function (err, result) {
                //         if (result.success) {
                //             var xhr = new XMLHttpRequest();
                //             xhr.open("PUT", result.data);
                //             xhr.setRequestHeader("Cache-Control", "public,max-age=3600");
                //             xhr.setRequestHeader('x-amz-acl', 'public-read');
                //             xhr.onload = function () {
                //                 if (xhr.status === 200) {
                //                     self.parent.tabLoader = false;
                //                     e.item.singleObj.fileName = newfile[0].name;
                //                     e.item.singleObj[isRejectedReplacement ? 'rejectionRepalcement' : 'docs'] = {
                //                         ConsumerServiceRequestID: self.csr.ConsumerServiceRequestID,
                //                         ConsumerProductID: self.csr.ConsumerProductID,
                //                         ParentDocumentType: e.item.singleObj.ParentDocumentType,
                //                         DocumentID: e.item.singleObj.docId,
                //                         FilePath: key,
                //                         Type: dataImgType,
                //                         Tag: e.item.singleObj.docTag,
                //                         app: 'WebApp',
                //                         DocDetails : {
                //                             "is_accepted_or_rejected" : -1
                //                         },
                //                         PendingDocID: e.item.singleObj.PendingDocID
                //                     };
                //                 } else {
                //                     self.parent.tabLoader = false;
                //                     e.item.singleObj[isRejectedReplacement ? 'rejectionRepalcement' : 'docs'] = null;
                //                     e.item.singleObj.fileName = '';
                //                     e.item.singleObj.fileUploaded = false;
                //                 }
                //                 self.parent.update();
                //             }.bind(this);
                //             xhr.onerror = function () {
                //                 self.parent.tabLoader = false;
                //                 self.parent.update();
                //             }.bind(this);
                //             xhr.send(newfile[0]);
                //         } else {
                //             self.parent.tabLoader = false;
                //             e.item.singleObj[isRejectedReplacement ? 'rejectionRepalcement' : 'docs'] = null;
                //             e.item.singleObj.fileName = '';
                //             e.item.singleObj.fileUploaded = false;
                //             swal("", result.msg ?  localString(result.msg) : localString("Something went wrong"),"error");
                //             self.parent.update();
                //         }
                //     }
                // });
            }
        } 
    };

    const resetDocumentSection = () => {
        setIsDocmasterProcessed(false)
        setIdentityDocs([])
        setDamageDocs([])
        uploadedDocs=[];
        setIdentificationObjects = [{
            fileUploaded: false
        }]
        setDamageObjects = [{
            fileUploaded: false
        }]
    };

    const save = () => {
        Swal.fire({
            title:localString('Are you sure'),
            showCancelButton: true,
            closeOnConfirm: true,
            allowEscapeKey: false,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                let data = {
                    supportingDocuments: getUploadedDocs(),
                    additionalComments: additionalComments && additionalComments.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '')
                };
                setLoader(true);
                apiCall({
                    url: 'createRequestDocuments',
                    data: data,
                    callBack:(result)=>{
                        setLoader(false);
                        if(result.success){
                            setIsAdditionMode(false)
                            getSupportingDocuments();
                            resetDocumentSection();
                            getRequestById();
                        }
                    }
                });
            }
        })
    }

    const checkIfDocsUploaded = () => {
        let identityDocsUploaded = identificationObjects.find((obj) => { return obj.fileUploaded == true});
        let damageDocsUploaded = damageObjects.find((obj) => { return obj.fileUploaded == true});
        if(!identityDocsUploaded) {
            Swal.fire({
                icon:"error",
                text:localString("Please upload Proof Of Device Identification")
            });
        } else if(!damageDocsUploaded) {
            Swal.fire({
                icon:"error",
                text:localString("Please upload Proof Of Damage/Issue")
            })
        } else {
            save();
        }
    };


    return (
        <>
            { loader && ReactDOM.createPortal(
                <Loader />,
                document.getElementById("loader-root")
            )}
            <div className="inner-padding">
                <div className="row">
                    <PageContentWrapper bodyClassName="panel-body-no-padding" title={localString("Supporting Documents")}>
                    {
                        /* ******** Upload Mode ******** */
                        isAdditionMode && 
                        <UploadingMode 
                            {...props}
                            identificationObjects={identificationObjects}
                            identityDocs={identityDocs} damageObjects={damageObjects}
                            damageDocs={damageDocs} uploadDocuments={uploadDocuments}
                            additionalComments={additionalComments} setAdditionalComments={setAdditionalComments}
                            addIdentityObj={addIdentityObj} setIdentificationObjects={setIdentificationObjects}
                            damageObjects={damageObjects} setDamageObjects={setDamageObjects} addDamageObj={addDamageObj}
                            checkIfDocsUploaded={checkIfDocsUploaded}
                        />
                    }
                    {
                        isValidateMode && savedSupportingDocuments
                    }
                    </PageContentWrapper>
                </div>
            </div>
        </>
    )
}

export default SupportingDocuments
