import React from 'react';
import {Button,Table,Radio} from 'sfy-react';
import { returnLocalDateTime,returnLocalTimeForADay} from '../../Utility/Utils';
import './AttendanceApproval.scss'

const AttendanceApprovalDetails = (props) => {
	const {tableHeader,attendanceList,localString,totalRequestCount,radioButton,saveReview,setAttendanceList} = props;

	return (
		<div className="content custom-parent">
			<div className="table-responsive">
				<Table>
					<Table.Header>
					<Table.Row>
						{tableHeader && tableHeader.length>0 && tableHeader.map((head, index) => {
							return (
								<Table.Heading className="text-capitalize" key={index}>{head.title}</Table.Heading>
							)
						})}
					</Table.Row>
					</Table.Header>
					<Table.Body>
						{
							attendanceList && attendanceList.length >0 && attendanceList.map((item,index)=>{
								return(
									<>
										<Table.Row>
											<Table.Data>{item.salesmanname && item.salesmanname}</Table.Data>
											<Table.Data><span className={item.UserType == "Team Lead" ? 'label label-info' : 'label label-primary'}>{(item.UserType)}</span></Table.Data>
											<Table.Data>{item.ShopName || "NA"}</Table.Data>
											<Table.Data>{item.AttendenceDate && returnLocalDateTime(item.AttendenceDate,"DD-MM-YYYY")}</Table.Data>
											<Table.Data>{item.checkInTime && returnLocalTimeForADay(item.checkInTime)|| "Invalid Date"}</Table.Data>
											<Table.Data>{item.ClaimTimeOut && returnLocalTimeForADay(item.ClaimTimeOut)}</Table.Data>
											<Table.Data>{item.status}</Table.Data>
											<Table.Data >
												<Button handleOnClick={()=>{
													let arr = attendanceList.map(data=>{
														let obj = {...data}
														if(item.ServiceLocationEngineerAttendanceLogID === data.ServiceLocationEngineerAttendanceLogID ){
															obj.collapse = !item.collapse
														}
														return obj;
													})
													setAttendanceList(arr)
												}} className='accordian-img'></Button>
											</Table.Data>
										</Table.Row>
										{
											item.collapse && 
											<Table.Row>
												<Table.Data className="remove-table-border " otherProps={{colSpan:8}}>
												<div className="overall-margin text-bold">{localString("Reason : ")}</div>
												<div className="Row">
													<div className="col-sm-12 overall-margin">
														{
															//(item.ServiceLocationEngineerAttendanceLogID == reason.ServiceLocationEngineerAttendanceLogID && (item.AllReasons.length != 1)) && localString("TL remark: "+ reason.Reason || " - ")
															//(!(item.ServiceLocationEngineerAttendanceLogID == reason.ServiceLocationEngineerAttendanceLogID && (item.AllReasons.length != 1)) && localString(reason.salesmanname + ": " +  reason.Reason || " - "))
															item.salesmanname + ": " +  item.Reason
														}
													</div>
													<div className="Row custom-attendace">
														<div className="col-sm-12 overall-margin">
															{
																radioButton && radioButton.map((radio)=>{
																	return(
																		<Radio
																			value={radio.value}
																			name={radio.buttonText}
																			selectedValue={item.attendanceStatus}
																			id={item.ServiceLocationEngineerAttendanceLogID+"_"+radio.value}
																			handleOnUpdate={(e)=>{
																				radio.onclickupdate(e.target.value,item)
																			}
																			}
																		>{radio.buttonText}</Radio>
																	)
																})
															}
															<div className="button-container">
																<Button handleOnClick={(e)=>{e.preventDefault();saveReview(item)}} isDisabled="" type='submit' classes='button-ghost' text="Save"></Button>
															</div>
														</div>
													</div>
												</div>
												</Table.Data>
											</Table.Row>
										}
									</>
								)
							})
						}
					</Table.Body>
				</Table>
			</div>
			{
				(!attendanceList || attendanceList.length == 0 || attendanceList == null) &&
				<div class="consumers-header text-center">
					<strong>{localString("No Data")}</strong>
				</div>
			}
			{
				attendanceList && attendanceList.length > 0 && totalRequestCount !=0 && <div class="consumers-header text-center">
					<strong>{totalRequestCount || 0}</strong> {localString(" attendance approvals requests are shown above. ")}
				</div>
			}
		</div>
	)
}

export default AttendanceApprovalDetails
