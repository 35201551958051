import React, { useState } from 'react';
import { Button, Input } from 'sfy-react';
import Datetime from 'react-datetime';
import Swal from "sweetalert2";
import moment from 'moment';

import { displayError } from '../../../../../Utility/Utils';


export default function LoanerPaymentMode(props) {
    const {
        apiCall, localString, setLoader, loanerPayableAmt, loanerPaymentModes, loanerDetails, consumerServiceRequest,
        closeModal, getRequestById, fetchLoanerDetailsByCsrId, paymentModal, isAdvanceAdded, isPaymentReceiveStage, nextStatus,
        setPaymentAmount, appleDropOffFlow, accessFn,
    } = props;

    const [paymentModes, setPaymentModes] = useState(loanerPaymentModes || []);
    const [totalAmountPayable, setTotalAmountPayable] = useState("")
    
    const selectPaymentMode = (e, k) => {
        let arr = [];
        paymentModes.map((item, key) => {
            let obj = {...item};
            if(k == key) {
                obj.isSelected = true;
            }else {
                obj.isSelected = false;
            }
            arr.push(obj)
        })
        setPaymentModes(arr);
    }

    const handleChange = (val, key, paramKey) => {
        let arr = [...paymentModes];
        arr[key]['Params'][paramKey]['value'] = val;
        setPaymentModes(arr);
    }

    const isPayloadValid = (paymentObj) => {
        let isValid = true;
        if(!paymentObj.Mode) {
            return { valid: false, msg: 'Please select Payment Mode.' }
		} 
        if(!paymentObj.Amount) {
            return { valid: false, msg: 'Please Enter Amount.' }
        }
        if(paymentModes && paymentModes.length) {
            paymentModes.forEach((item) => {
                if(item.isSelected && item?.Params?.length) {
                    item.Params.forEach((paramObj) => {
                        if(paramObj.IsMandatory && !paramObj?.value?.length) {
                            isValid = false;
                        }
                    })
                }
            })
            if(!isValid) {
                return { valid: false, msg: 'Please Fill Payment Mode Details.' }
            }
        }
        return { valid: true,  msg: ''}
    }

    const collectLoanerPayment = () => {
        let paymentObj = {};
        paymentModes.forEach((item, k) => {
            if(item.isSelected) {
                paymentObj.Mode = item.PaymentMode;
                paymentObj.Amount = loanerPayableAmt;
                if(item.Params && item.Params.length) {
                    item.Params.forEach((paramObj) => {
                        paymentObj[paramObj.KeyName] = paramObj.value;
                    })
                }
            }
        })
        let checkPayload = isPayloadValid(paymentObj);
        if(checkPayload?.valid) {
            let reqObj = {
                'ConsumerServiceRequestID': consumerServiceRequest.ConsumerServiceRequestID,
                'Entity': 'LoanerRequest',
                'PartnerID': consumerServiceRequest.PartnerID,
                'PartnerServiceLocationID': consumerServiceRequest.PartnerServiceLocationID,
                'PaymentMode': [paymentObj],
                'EntityRequestID': loanerDetails.loanerRequestID
            };
            setLoader(true); 
            apiCall({
                url: 'collectLoanerPayment',
                data: reqObj,
                callBack: (result) => {
                    setLoader(false); 
                    if(result && result.success) {
                        closeModal();
                        getRequestById && getRequestById()
                        fetchLoanerDetailsByCsrId && fetchLoanerDetailsByCsrId()
                    }else {
                        Swal.fire({  
                            icon: 'error',  
                            text: localString(displayError(result)), 
                        });
                    }
                },
                errorCB: (err) => {
                    setLoader(false);
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(err)),  
                    });
                }
            });
        }else {
            Swal.fire({  
                icon: 'error',  
                text: localString(checkPayload.msg),  
            });
        }
    }

    const collectAdvancePayment = () => {
		let data = {
			'ConsumerServiceRequestID': consumerServiceRequest.ConsumerServiceRequestID,
			'isAdvanceAdded':isAdvanceAdded,
			'PartnerID': consumerServiceRequest.PartnerID,
			'PartnerServiceLocationID':consumerServiceRequest.PartnerServiceLocationID,
			'paymentMode':[],
			'isPaymentReceiveStage':false
		};
        if(accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE') && appleDropOffFlow) {
			data.PartnerServiceLocationID = consumerServiceRequest.OriginServiceLocationID;
        }
		if(['Payment received'].indexOf(nextStatus) > -1){
			data.isPaymentReceiveStage = true;
		}
		let paymentObj = {};
        paymentModes.forEach((item, k) => {
            if(item.isSelected) {
                paymentObj.Mode = item.PaymentMode;
                paymentObj.Amount = loanerPayableAmt;
                if(item.Params && item.Params.length) {
                    item.Params.forEach((paramObj) => {
                        paymentObj[paramObj.KeyName] = paramObj.value;
                    })
                }
            }
        })
		let validationData = isPayloadValid(paymentObj);
		if(!validationData.valid){
			Swal.fire({
				title: '',
				text: localString(validationData.msg),
				icon: "error",
				confirmButtonText: localString('OK')
			});
			return;
		}
		data.paymentMode.push(paymentObj);
        setLoader(true); 
        apiCall({
            url: 'collectAdvancePayment',
            data: data,
            callBack: (result) => {
                setLoader(false); 
                if(result && result.success) {
                    closeModal();
                    getRequestById && getRequestById();
                }else {
                    Swal.fire({  
                        icon: 'error',  
                        text: localString(displayError(result)), 
                    });
                }
            },
            errorCB: (err) => {
                setLoader(false);
                Swal.fire({  
                    icon: 'error',  
                    text: localString(displayError(err)),  
                });
            }
        });
	};

    const normalPaymentReceive =(status)=>{
        let data={
            ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            Status: status,
            PaymentMode:[],
        }
        let paymentObj = {};
        paymentModes.forEach((item, k) => {
            if(item.isSelected) {
                paymentObj.Mode = item.PaymentMode;
                paymentObj.Amount = loanerPayableAmt;
                if(item.Params && item.Params.length) {
                    item.Params.forEach((paramObj) => {
                        paymentObj[paramObj.KeyName] = paramObj.value;
                    })
                }
            }
        })
        // if(!validationData.valid){
		// 	Swal.fire({
		// 		title: '',
		// 		text: localString(validationData.msg),
		// 		icon: "error",
		// 		confirmButtonText: localString('OK')
		// 	});
		// 	return;
		// }
		data.PaymentMode.push(paymentObj);
        let servicerequestFulfilmentUrl = accessFn('WORKSHOP_FLOW') ? 'servicerequestFulfilmentWorkshop' : 'servicerequestFulfilment';
        setLoader(true);
		apiCall({
			url: servicerequestFulfilmentUrl,
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
                    getRequestById();
                    closeModal();
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		}); 
    }

    return (
        <div className=''>
            <div className='row'>
                <div for="totalLoanerAmount" className="col-sm-4" style={{ paddingTop: '10px' }}>
                    <label>{localString("Enter Amount")}</label>
                </div>
                <div className="col-sm-6">
                {
                    paymentModal == "PickupCarryinGSX" ? 
                    <Input
                        value={loanerPayableAmt}
                        id="totalAmountPayable"
                        handleOnChange={(e) => {
                            // setTotalAmountPayable(e.target.value)
                            setPaymentAmount && setPaymentAmount(e.target.value);
                        }}
                        placeholder={localString("Enter Amount")} 
                        isDisabled={isAdvanceAdded || isPaymentReceiveStage}
                    /> : 
                    <Input
                        value={loanerPayableAmt}
                        handleOnChange={(e) => {}}
                        placeholder={localString("Enter Amount")} 
                        isDisabled={true}
                    />
                }
                    
                </div>
            </div>
            <div style={{ padding: '12px 0px' }}>
                <label>{localString("Please select mode of payment")}</label>
            </div>
            {
                paymentModes && paymentModes.length && paymentModes.map((payObj, k) => <>
                    <div className='row paymentModes'>
                        <label className="check-label">
                            <input
                                type="radio"
                                name="payment-mode" 
                                id={payObj.PaymentMode}
                                style={{marginRight: '5px'}} 
                                onClick={(e) => selectPaymentMode(e, k)}>
                            </input>{payObj.DisplayText}
                        </label>
                    </div>
                    {
                        payObj?.isSelected && (payObj?.Params?.length > 0) && payObj.Params.map((param, paramKey) => <>
                            <div className='row paramDiv'>
                                <div className='col-sm-4' style={{ paddingTop: '10px' }}>
                                    <label>{localString(param.DisplayName)}</label>
                                </div>
                                {
                                    param.Type == 'TEXT' && <div className="col-sm-6">
                                        <Input
                                            value={param.value || ''}
                                            handleOnChange={(e) => handleChange(e.target.value, k, paramKey)}
                                            placeholder={localString(param.DisplayName)} 
                                            // isDisabled={true}
                                            maxLength={param.KeyDataLength}
                                        />
                                    </div>
                                }
                                {
                                    param.Type == 'DATETIME' && <div className="col-sm-6">
                                        <Datetime
											value={new Date(param.value) || ""}
											inputProps={{
												onKeyDown: e => e.preventDefault(),
                                                placeholder: localString(param.DisplayName)
											}}
											dateFormat={'YYYY-MM-DD'}
											timeFormat={true}
											onChange={(val) => handleChange(moment(val).format('YYYY-MM-DD H:mm:ss'), k, paramKey)}
											// isValidDate={(current) => current.isBefore(new Date())}
											closeOnSelect={true}
										/>
                                    </div>
                                } 
                            </div>
                        </>)
                    }
                </> )
            }
            <div className='pull-right'>
				<Button 
					className="btn button-servify"
					onClick={() => {
                        paymentModal == "PickupCarryinGSX" ? collectAdvancePayment() : !(accessFn('GSX_FLOW')) && !(accessFn('WORKSHOP_FLOW')) ? normalPaymentReceive('Payment received') :collectLoanerPayment()
                    }}
				>
					{localString("Save")}
				</Button>
			</div>
        </div>
    )
}