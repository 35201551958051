import { all } from 'bluebird';
import React,{useState,useEffect} from 'react';
import { returnLocalDateTime } from '../../../../../Utility/Utils';
import CommunicationInput from './CommunicationInput';
const AllDetails = (props)=>{
    const {allCommunication}=props
    return(
        <>
            <CommunicationInput
                {...props}
                communicationData = {allCommunication}
            />
        </>
    )
}
export default AllDetails;