import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Swal from "sweetalert2";
import { Button, Loader, Pagination } from 'sfy-react';
import moment from 'moment';
import Papa from 'papaparse';

import { decrypt, displayError, downloadCSV, returnLocalDateTime } from '../../../../Utility/Utils';
import CommonTable from '../../../../components/Table/Table';
import ReportPageHeader from '../../ReportPageHeader';

export default function AttendanceReportView(props) {
	const { accessFn, localString, apiCall, useQuery } = props;
	let query = useQuery();
	const name  = decrypt(query.get("name"));
	const RetailUserID  = decrypt(query.get("id"));
	const [loader, setLoader] = useState(false);
	const [data, setData] = useState([]);
	const [startDate, setStartDate] = useState(localStorage.getItem('startDate') || moment().subtract(1, 'days').startOf('month').format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(localStorage.getItem('endDate') || moment().subtract(1,'days').format("YYYY-MM-DD"));
	const [pagination , setPagination] = useState({
		pageNo : 1,
		itemsPerPage : 30,
		count: 0,
		numberOfPages: 0
	});

	const columns = [
		{
			title: localString("Date"),
			showHeading: true,
			field: 'Date',
			customCell: (row) => row?.Date || '--',
		},
		{
			title: localString("Shop Code"),
			showHeading: true,
			field: 'ShopCode',
			customCell: (row) => row?.ShopCode || '--',
		},
		{
			title: localString("Shop Name"),
			showHeading: true,
			field: 'ShopName',
			customCell: (row) => row?.ShopName || '--',
		},
		{
			title: localString("Time In"),
			showHeading: true,
			field: 'TimeIn',
			customCell: (row) => (row?.TimeIn ? returnLocalDateTime(row.TimeIn, 'HH:mm') : '--')
		},
		{
			title: localString("Time Out"),
			showHeading: true,
			field: 'TimeOut',
			customCell: (row) => (row?.TimeOut ? returnLocalDateTime(row.TimeOut, 'HH:mm') : '--')
		},
		{
			title: localString("Status"),
			showHeading: true,
			field: 'Status',
			customCell: (row) => row?.Status || '--',
		},
	]

	const getAttendanceReport = (downloadFile) => {
		setLoader(true);
		let reqObj = {
			RetailUserID: [RetailUserID],
			ShopCode: [],
			Type: "PersonalAttendance",
			branchCode: [],
			eDate: moment(endDate).format("YYYY-MM-DD"),
			sDate: moment(startDate).format("YYYY-MM-DD"),
		}
		if(!downloadFile) {
			reqObj.Limit = pagination.itemsPerPage;
			reqObj.PageNo = pagination.pageNo;
		}
		apiCall({
			url: 'getAttendanceReport',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data && downloadFile) {
					let arr = [];
					result.data.AttendanceDetails && result.data.AttendanceDetails.map(item => {
						let obj = {...item}
						obj.TimeIn = item.TimeIn ? returnLocalDateTime(item.TimeIn, 'HH:mm') : '--';
						obj.TimeOut = item.TimeOut ? returnLocalDateTime(item.TimeOut, 'HH:mm') : '--';
						arr.push(obj);
					});
					let attendanceDetails = Papa.unparse({
						fields: [
						  'Date',
						  'ShopCode',
						  'ShopName',
						  'TimeIn',
						  'TimeOut',
						  'Status',
						],
						data: arr
					});
		  
					let CapsString;
					let capital = Papa.parse(attendanceDetails,{
						beforeFirstChunk :function(chunk) {
							let rows = chunk.split( /\r\n|\r|\n/ );
							let result = [];
							let row = rows[0];
							let a = row.split(',');
							for (let iCount = 0;iCount<a.length;iCount++) {
								let item = a[iCount].replace(/([a-z])([A-Z])/g, '$1 $2');
								result.push(item)
							}
							CapsString = result.toString().toUpperCase();
							rows[0] = CapsString;
							return rows.join("\r\n");
						},
					});
		  
					let csvString = Papa.unparse(capital);
					//   let attendanceDetails = Papa.unparse(result.data.AttendanceDetails);
					let advisorData = result.data.AdvisorDetails;
					let advisorDetails =
						"NAME" +","+(advisorData[0].Name ? advisorData[0].Name : '-') + "\r\n" +
						"BRANCH CODE" +","+(advisorData[0].branchCode ? advisorData[0].branchCode : '-') + "\r\n" +
						"MOBILE NO" +","+(advisorData[0].MobileNo ? advisorData[0].MobileNo : '-') + "\r\n" +
						"SHOP"+","+(advisorData[0].shop ? advisorData[0].shop : "-") + "\r\n" +
						"SHOP AREA" +","+(advisorData[0].ShopArea) + "\r\n" +
						"CITY" +","+(advisorData[0].City ? advisorData[0].City : "-") + "\r\n" +
						"BRANCH" +","+(advisorData[0].Branch ? advisorData[0].Branch :"-");
					let csv = advisorDetails + "\r\n\r\n" + csvString;
					downloadCSV(csv, name + '.csv');
				}else if(result.success && result.data) {
					setData(result.data.AttendanceDetails);
					setPagination({...pagination, 
						count: result.data && result.data.length,
						numberOfPages: result.data && result.data.numberOfPages
					})
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		getAttendanceReport();
	}, [pagination.pageNo])

	return (
		<>
			<div className='attendanceReport'> 
				{
					loader && ReactDOM.createPortal(
						<Loader />,
						document.getElementById("loader-root")
					)
				}
				<ReportPageHeader 
					titleHeader={name}
					localString={localString}
					showHeaderContent={true}
					headerContent={() => {
						return (
							<div style={{ display: 'inline-block' }}>
								<span class="header-date-lowercase">
									({moment(startDate).format("DD-MM-YYYY")} to {moment(endDate).format("DD-MM-YYYY")})
								</span>
								<div className="download-icon" title="Download Report" onClick={() => getAttendanceReport(true)} data-toggle="tooltip" data-placement="right">
									<div className="download-image" id=""></div>
								</div>
							</div>
						);
					}}
				/>
				 <CommonTable 
					columns={columns}
					data={data}
					localString={localString}
				/>
				{
					pagination.numberOfPages > 1 && 
						<Pagination
							itemsPerPage={pagination.itemsPerPage}
							pages={pagination.numberOfPages}
							totalItems={pagination.count}
							activePage={pagination.pageNo}
							paginate={(pageNumber) => setPagination({...pagination, pageNo: pageNumber})}
						/> 
				}
			</div>
		</>
	);
}