import React from 'react';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import { SERVICETYPE } from '../../../../../Utility/Utils';
import ReplacementDeviceDetailsTable from './ReplacementDeviceDetailsTable';

const ReplacementDeviceDetails = (props) => {
	const {localString, consumerServiceRequest} = props;

	return (
		<div className="inner-padding">
			<div className="row">
				<PageContentWrapper bodyClassName="panel-body-no-padding" title={
					consumerServiceRequest && 
					(([SERVICETYPE.CLAIM_DOOR_TO_DOOR_UPGRADE,SERVICETYPE.DOOR_TO_DOOR_UPGRADE,SERVICETYPE.PICKUP_UPGRADE,SERVICETYPE.CLAIM_UPGRADE_PICKUP,SERVICETYPE.MAIL_IN_UPGRAGE,SERVICETYPE.CLAIM_UPGRADE_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && localString("Upgraded Device Details")) ||
					([SERVICETYPE.CLAIM_DOOR_TO_DOOR_REPLACEMENT,SERVICETYPE.CLAIM_REPLACEMENT_PICKUP,SERVICETYPE.CLAIM_REPLACEMENT_MAIL_IN].indexOf(consumerServiceRequest.ServiceTypeID)> -1 && localString("Replacement Device Details")))}
				>
					<ReplacementDeviceDetailsTable
						{...props}
					/>
				</PageContentWrapper>
			</div>
		</div>
	)
}

export default ReplacementDeviceDetails
