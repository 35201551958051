import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import _ from 'lodash';
import Swal from "sweetalert2"; 
import { Button, Dropdown, Loader, Modal, Input, Table } from 'sfy-react';

import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import CommonTable from '../../../../../components/Table/Table';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import { displayError, SERVICETYPE } from '../../../../../Utility/Utils';
import AlternatePartModal from './AlternatePartModal';
import IssuePartModal from './IssuePartModal';
import AddComptiaPartModal from './AddComptiaPartModal';
import KbbUpdateModal from './KbbUpdateModal';

import './PartsRequested.scss';

export default function PartsRequested(props) {
	const { 
		apiCall, accessFn, localString, exception, consumerServiceRequest = {}, canUpdateServiceRequest, productNotMapped,
		outletLocationData, getRequestById, getPartsForRequest
	} = props;

	const qFilter = JSON.parse(props.qFilter);
	const [loader, setLoader] = useState(false);
	const [partsRequested, setPartsRequested] = useState([]);
	const [partArr, setPartArr] = useState([]);
	const [partStack, setPartStack] = useState([]);
	const [showPurchaseOrderReferenceNumber, setShowPurchaseOrderReferenceNumber] = useState(false);
	const [showPurchaseRequestReferenceId, setShowPurchaseRequestReferenceId] = useState(false);
	const [showPartStatusUpdateButton, setShowPartStatusUpdateButton] = useState(false);
	const [showSave, setShowSave] = useState(false);
	const [buttonsPerStatus, setButtonsPerStatus] = useState({});

	const displaySaveBtn = partsRequested.map(a => a.buttons).some(e => (e && e.length));

	const [isPartIssued, setIsPartIssued] = useState(false);
	const [isPartReceived, setIsPartReceived] = useState(false);
	const [isAlternatePartSelected, setIsAlternatePartSelected] = useState(false);
	const [isPartOrdered, setIsPartOrdered] = useState(false);
	const [isPartDOA, setIsPartDOA] = useState(false);

	const [showServiceLocation, setShowServiceLocation] = useState(false);
	const [showShippingLocation, setShowShippingLocation] = useState(false);
	const [showBillingLocation, setShowBillingLocation] = useState(false); 
	
	const [serviceLocation, setServiceLocation] = useState(null);
	const [serviceLocationObj, setServiceLocationObj] = useState(null);
	const [serviceLocationOptions, setServiceLocationOptions] = useState([]);

	const [shippingLocation, setShippingLocation] = useState(null);
	const [shippingLocationObj, setShippingLocationObj] = useState(null);
	const [shippingLocationOptions, setShippingLocationOptions] = useState([]);
	
	const [billingLocation, setBillingLocation] = useState(null);
	const [billingLocationObj, setBillingLocationObj] = useState(null);
	const [billingLocationOptions, setBillingLocationOptions] = useState([]);

	const [serializedPartOptions, setSerializedPartOptions] = useState([]); 
	const [selectedSerialNumberArray, setSelectedSerialNumberArray] = useState([]);

	const [altPartOptions, setAltPartOptions] = useState([]);

	const [altSerializedPartOptions, setAltSerializedPartOptions] = useState([]);

	const [selectedSerialNumber, setSelectedSerialNumber] = useState('');

	const [showIssuePartModal, setShowIssuePartModal] = useState(false);
	const [showAddComptiaPartModal, setShowAddComptiaPartModal] = useState(false);
	const [showAlternatePartModal, setShowAlternatePartModal] = useState(false);
	const [showDefectivePartReceiveModal, setShowDefectivePartReceiveModal] = useState(false);
	const [showKbbUpdateModal, setShowKbbUpdateModal] = useState(false);

	const [radioBtnClicked, setRadioBtnClicked] = useState(false);
	const disableSave = !radioBtnClicked || (showServiceLocation && !serviceLocation) || (showServiceLocation && consumerServiceRequest.Source == "SkyworthDashboard" && (!shippingLocation || !billingLocation))
	const getDisplayText = (status) => {
		let displayNames = {
			'alternatePartIssued': 'Alternate Part Issued'
		};
		if(status == "alternatePartIssued") {
			return displayNames[status];
		}else {
			return status;
		}
	};

	const isSingleRole = () => {
		let roleFeatureRightsCount = 0;
		if (qFilter) {
			if (qFilter.hasUnrestrictedAccess) {
				return true;
			} else {
				if (accessFn('ACCEPT_RETURN_DEVICE')) {
					roleFeatureRightsCount++;
				}
				if (accessFn('STORE_MANAGEMENT')) {
					roleFeatureRightsCount++;
				}
				if (accessFn('REASSIGN_JOBS')) {
					roleFeatureRightsCount++;
				}
				if (accessFn('REPAIR_DEVICE')) {
					roleFeatureRightsCount++;
				}
				if (accessFn('QC_DEVICE')) {
					roleFeatureRightsCount++;
				}
				if (accessFn('APPROVE_EXCEPTION_REQUEST')) {
					roleFeatureRightsCount++;
				}
				if (roleFeatureRightsCount > 1) {
					return false;
				}
				else {
					return true;
				}
			}
		} else {
			return false;
		}
	}

	// for service engineer
	const buttonsPerStatusForSE = {
		'issued': ['Receive', 'Reject'],
		'received': accessFn('GSX_FLOW') ? ['Consumed', 'DOA', 'Unused'] : ['DOA', 'Unused'],
		// 'damaged': ['Return']
		// 'requested': ['Cancel'],
		// 'pending': ['Cancel']
	};
	  
	// for store keeper
	const buttonsPerStatusForSK = {
		'pending': (qFilter && qFilter.flowType && qFilter.flowType.requestFulfilmentFlow && qFilter.flowType.requestFulfilmentFlow.indexOf('REQ_FULFIL_CROMA') > -1) ? ['Issue', 'Raise PR'] : ['Issue', 'Order'],
		'ordered': ['Issue'],
		'handover': ['Return to Customer']
		// 'returned': ['Accept Unused'],
		// 'requested': ['Accept'],
		// 'rejected': ['Accept Rejected']
	};

	const buttonsPerStatusForCC = {
		'defective': ['Return']
	}

	const getActionStatus = (btn) => {
		let action = '';
		switch(btn) {
			case "Issue":
				action = "issued";
				break;
			case "Accept":
				action = "pending";
				break;
			case "Order":
				action = "ordered";
				break;
			case "Raise PR":
				action = "purchaseRequested";
				break;
			case "Receive":
				action = "received";
				break;
			case "Reject":
				action = "rejected";
				break;
			case "Unused":
				action = "returned";
				break;
			case "DOA":
				action = "doa";
				break;
			case 'Return':
				action = 'handover'
				break;
			case "Cancel":
				action = "cancelled";
				break;
			case "Return to OEM":
				action = "returnOem";
				break;
			case "Return to Customer":
				action = "returnCustomer";
				break;
			case "Consumed":
				action = "consumed";
				break;
			case "Select Alternate":
				action = "alternateSelected";
				break;
		}
		return action;
	};

	useEffect(() => {
		if (!isPartOrdered) {
			setShowServiceLocation(false);
			setShowShippingLocation(false);
			setShowBillingLocation(false);	
			setServiceLocation(null);
			setServiceLocationObj(null);
			setShippingLocation(null);
			setShippingLocationObj(null);
			setBillingLocation(null);
			setBillingLocationObj(null);
		} else {
			if (!serviceLocationOptions.length) {
				getServiceLocations();
			}
			setShowServiceLocation(true);
		}
	}, [isPartOrdered])

	const handlePartsRequestedState = () => {
		let showPartStatusUpdateButtonFlag = false;
		let showSaveFlag = false;
		let buttonsPerStatusObj = {};
		// let buttonsPerStatusObj = {...buttonsPerStatus};
		if ([SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.ON_SITE].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && ['Service in-progress', 'Advance collected', 'Parts request accepted', 'Parts issued', 'Parts received','Parts partially issued','Replacement Approved'].indexOf(consumerServiceRequest.Status) > -1) {
			showPartStatusUpdateButtonFlag = true;
		} else if([SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN,13,SERVICETYPE.BER_IDENTIFIED,SERVICETYPE.BER_PAYMENT,SERVICETYPE.BER_REPAIR].indexOf(consumerServiceRequest.ServiceTypeID) && ['Service initiated', 'Parts request accepted','Parts issued', 'Parts received','Parts partially issued'].indexOf(consumerServiceRequest.Status) > -1) {
			showPartStatusUpdateButtonFlag = true;
		} else if([SERVICETYPE.INTERNAL_JOB].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && ['Work in progress', 'Parts request created'].indexOf(consumerServiceRequest.Status) > -1){
			showPartStatusUpdateButtonFlag = true;
		}
		let partsRequestedArr =  _.cloneDeep(props.partsRequested);
		if (partsRequestedArr && partsRequestedArr.length) {
			if(partsRequestedArr[0].statusDetails.Status !== 'requested') {
				let isAdvancePaid = true
				showSaveFlag = true;

				// Merge Buttons
				if (accessFn('STORE_MANAGEMENT')) {
					_.merge(buttonsPerStatusObj, buttonsPerStatusForSK);
				}
				if (accessFn('EDIT_ALTERNATE_PARTS') && buttonsPerStatusObj.pending ) {
					buttonsPerStatusObj.pending.push('Select Alternate');
					if (consumerServiceRequest && ['Croma','CromaAPI','CromaCare','CromaDashboard'].indexOf(consumerServiceRequest.Source) < 0) {
						buttonsPerStatusObj.ordered.push('Select Alternate');
					}
				}
				if (accessFn('REPAIR_DEVICE')) {
					_.merge(buttonsPerStatusObj, buttonsPerStatusForSE);
					if (['Repair cancel initiated'].indexOf(consumerServiceRequest.Status) > -1) {
						buttonsPerStatusObj.issued = ['Cancel'];
						buttonsPerStatusObj.received = ['Cancel'];
					}
					if (exception && exception.length) {
						_.pull(buttonsPerStatusObj.issued, "Reject")
						_.pull(buttonsPerStatusObj.received, "Unused")
					}
				}
				if (accessFn('REASSIGN_JOBS') && consumerServiceRequest.ServiceTypeID != SERVICETYPE.INTERNAL_JOB) {
					_.merge(buttonsPerStatusObj, buttonsPerStatusForSE)
					if (exception && exception.length) {
						_.pull(buttonsPerStatusObj.issued, "Reject")
						_.pull(buttonsPerStatusObj.received, "Unused")
					}
				}
				if (accessFn('ACCEPT_RETURN_DEVICE') && exception && !exception.length) {
					if (!consumerServiceRequest.IsTenorRequest) {
						_.merge(buttonsPerStatusObj, buttonsPerStatusForCC);
						if (['Repair cancel initiated'].indexOf(consumerServiceRequest.Status) > -1) {
							buttonsPerStatusObj.defective = [];
						}
					}
				}

				if (consumerServiceRequest?.charges && consumerServiceRequest.charges.length) {
					consumerServiceRequest.charges.map((item, k) => {
						if (item.Type == 'Advance payment' && item.IsAdvancePaid == 0) isAdvancePaid = false;
					})
				}
				_.forEach(partsRequestedArr, function(part) {
					if(['SkyworthDashboard'].indexOf(consumerServiceRequest.Source) > -1 && part?.PurchaseOrderReferenceNumber){
						setShowPurchaseOrderReferenceNumber(true);
					}
					if(['CromaDashboard','Croma'].indexOf(consumerServiceRequest.Source) > -1 && part?.PRReferenceNo){
						setShowPurchaseRequestReferenceId(true);
					}
					// if(['CromaDashboard','Croma'].indexOf(consumerServiceRequest.Source) > -1 && part.Remarks){
					// 	self.ShowRemarks = true;
					// }

					part.buttons = _.cloneDeep(buttonsPerStatusObj[part.statusDetails.Status]);

					if (part.statusDetails.Status == 'pending') {
						if (part.AvailableQuantity > 0) {
						  	if (part && part.buttons && part.buttons.indexOf('Order') > -1) { _.pull(part.buttons, 'Order') }
						  	if (part && part.buttons && part.buttons.indexOf('Raise PR') > -1) { _.pull(part.buttons, 'Raise PR') }
						} else if (!accessFn('GSX_FLOW')  && part.AvailableQuantity >= 1 && consumerServiceRequest.Source == "SkyworthDashboard") {
						  	_.pull(part.buttons, 'Order');
						} else if (accessFn('GSX_FLOW') && part.AvailableQuantity <= 0) {
						  	part.buttons = [];
						} else {
							if (accessFn('GSX_FLOW')) {
								part.buttons = [];
							}
						}
						if (!accessFn('GSX_FLOW') && part.AvailableQuantity <= 0) {
						  	_.pull(part.buttons, 'Issue');
						}
						if (accessFn('GSX_FLOW')  && part.AvailableQuantity >= 1 && part.isConsignment && consumerServiceRequest.gsxRepairFlags.repairTypeId == 2 && consumerServiceRequest.enableFetchGSXStatus) {
						  	_.pull(part.buttons, 'Issue');
						}
						if (accessFn('GSX_FLOW')  && !part.isConsignment && consumerServiceRequest.gsxRepairFlags.repairTypeId == 2) {
						  	_.pull(part.buttons, 'Issue');
						}
					}

					if (part.statusDetails.Status == 'ordered') {
						if (part.AvailableQuantity < 1) {
							_.pull(part.buttons, 'Issue');
						}
					}
					if (!isAdvancePaid) {
					  	part.buttons = []
					} else if (part.statusDetails.Status == 'pending') {
						if (['Parts request accepted', 'Advance collected', 'Parts partially issued', 'Parts pending', 'Replacement Approved', 'Parts request created','Estimation generated'].indexOf(consumerServiceRequest.Status) < 0) {
							if ([SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1 && ['Payment received'].indexOf(consumerServiceRequest.Status) > -1)  {
								// pass
							} else {
								part.buttons = []
							} 
						}
					} else {
						// pass
					}
				})
			}
		}

		// For returning the parts to customer from cc only after repair is completed
		let isRepairComplete = _.find(consumerServiceRequest.logs, { Status: 'Repair completed' });
		let isServiceComplete = _.find(consumerServiceRequest.logs, { Status: 'Service completed' || 'Service completed and rated' });
		let isServiceInvoiceGenerated = _.find(consumerServiceRequest.logs, { Status: 'Service invoice generated' });
		let isPaymentReceivedAfterInvoiceGeneration = false; //OLD FLOW
		if (isServiceInvoiceGenerated && ['Payment received'].indexOf(consumerServiceRequest.Status) > -1) {
			isPaymentReceivedAfterInvoiceGeneration = true;
		}

		if (accessFn('ACCEPT_RETURN_DEVICE')) {
			// self.showpartstatusupdatebutton = true;
			showPartStatusUpdateButtonFlag = true;
			_.forEach(partsRequestedArr, function(part) {
				if (isServiceInvoiceGenerated || isPaymentReceivedAfterInvoiceGeneration) {
					if (part.statusDetails.Status == 'defective' && !part.IsUnderWarranty && [SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN,SERVICETYPE.DROPOFF,SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1) { // add an extra check for the warranty check.. should be out of warranty, then only to be returned to the customer.
						part.buttons = _.cloneDeep(buttonsPerStatusObj[part.statusDetails.Status]);
					} else {
						if (isSingleRole()) {
							part.buttons = []
						}
					}
					if (accessFn('GSX_FLOW') && !consumerServiceRequest.gsxRepairRequest.confirmationNumber) {
						if (isSingleRole()) {
							part.buttons = []
						}
					}
				} else {
					if(!accessFn('STORE_MANAGEMENT')) {
						if (isSingleRole()) {
							part.buttons = [];
						}
					}
				}
			});
		}

		let isQcRejected = _.find(consumerServiceRequest.logs, { Status: 'QC rejected' })
		if(isQcRejected) {
			let qcIndex = _.findIndex(consumerServiceRequest.logs, function(o) { return o.Status == 'QC rejected'; });
			let rcIndex = _.findIndex(consumerServiceRequest.logs, function(o) { return o.Status == 'Repair completed'; });
			if (rcIndex > qcIndex) {
				let usedPartChargeIDs = _.map(_.filter(partsRequestedArr, function (o) {return [SERVICETYPE.CLAIM,SERVICETYPE.CLAIM_PICK_UP,SERVICETYPE.CLAIM_CARRY_IN].indexOf(o.PartTransactionStatusID) > -1}), 'PartID');
				_.forEach(partsRequestedArr, function (part) {
					if(part.statusDetails.Status == 'received') {
						if (usedPartChargeIDs && (usedPartChargeIDs).indexOf(part.PartID) > -1) {
							_.pull(part.buttons, 'Unused')
							_.pull(part.buttons, 'DOA')
						}
					}
				})
			}
			if (rcIndex < qcIndex) {
				_.forEach(partsRequestedArr, function (part) {
					if(part.statusDetails.Status == 'received') {
						_.pull(part.buttons, 'Unused')
						_.pull(part.buttons, 'DOA')
					}
				})
			}
  
		} else {
			if(isRepairComplete || isServiceComplete) {
				_.forEach(partsRequestedArr, function (part) {
					if(part.statusDetails.Status == 'received') {
						_.pull(part.buttons, 'Unused')
						_.pull(part.buttons, 'DOA')
					}
				})
			}
		}

		for (var i=0; i < partsRequestedArr.length; i++) {
			if (partsRequestedArr[i].buttons && partsRequestedArr[i].buttons.length > 0) {
				showSaveFlag = true;
				showPartStatusUpdateButtonFlag = true
				break;
			}
		}
		showSaveFlag ? setShowSave(true) : setShowSave(false);
		setShowPartStatusUpdateButton(showPartStatusUpdateButtonFlag);
		setButtonsPerStatus(buttonsPerStatusObj);
		setPartsRequested(partsRequestedArr);
	}

	useEffect(() => {
		handlePartsRequestedState();
		return () => {
			setPartsRequested([]);
		}
	}, [props.partsRequested]);

	const handleRadio = (value, index) => {
		setRadioBtnClicked(true);
		let partsRequestedArr = [...partsRequested];
		partsRequestedArr[index].radioBtnValue = value;
		let status = getActionStatus(value);
		if (status) {
			partsRequestedArr[index].action = status;
		}
		if (partsRequestedArr.find(a => (a.action == 'ordered' || a.action == 'purchaseRequested'))) {
			setIsPartOrdered(true);
		} else {
			setIsPartOrdered(false);
		}
		setPartsRequested(partsRequestedArr);
	}

	const clearOptionsAndValueStates = () => {
		setSerializedPartOptions([]);
		setSelectedSerialNumberArray([]);
		setAltPartOptions([]);
		setAltSerializedPartOptions([]);
	}

	const displayDifferentOutcomesAlert = () => {
		let partsRequestedArr = partsRequested.map((item) => {
			let obj = {...item}
			delete obj.radioBtnValue;
			delete obj.action;
			return obj;
		});
		setPartsRequested(partsRequestedArr);
		setRadioBtnClicked(false);
		clearOptionsAndValueStates();
		Swal.fire({
			title: '',
			icon: 'error',
			text: localString("In case you want to select different outcomes for both parts, please select outcome for one part and Save the same. Post that please update the outcome for the remaining parts. For additional help, contact your Servify administrator."),
			confirmButtonText: localString('OK'),
		});
	}

	const updateParts = () => {
		let partArray = [];
		let isPartIssuedFlag = false;
		let isPartDOAFlag = false;
		let isPartReceivedFlag = false;
		let isAlternatePartSelectedFlag = false;
		let isPartOrderedFlag = isPartOrdered;
		partsRequested.map((part) => {
			if (part.buttons && part.radioBtnValue) {
				let addPart = {};
				addPart.PartTransactionID = part.PartTransactionID;
				addPart.PartID = part.PartID;
				addPart.Entity = part.Entity;
				addPart.EntityID = part.EntityID;
				addPart.FaultActionID = part.FaultActionID;
				addPart.RequestedByID = part.RequestedByID;
				addPart.IssuedByID = part.IssuedByID;
				addPart.Serialized = part.Serialized;
				addPart.InventoryItemID = part.InventoryItemID;
				addPart.PartItemID = part.PartItemID;
				if (part.PartSerialNumber) {
				  	addPart.PartSerialNumber = part.PartSerialNumber;
				}
				if (part.PartItemID) {
				  	addPart.PartItemID = part.PartItemID;
				}
				addPart.AvailableQuantity = part.AvailableQuantity
				addPart.PartTransactionStatusID = part.PartTransactionStatusID;
				addPart.IsActive = part.IsActive;
				addPart.RequiresSerialNumber = part.RequiresSerialNumber;
				addPart.ChargeID = part.ChargeID;
				addPart.Name = part.Name;
				if (accessFn('GSX_FLOW')) {
				   addPart.isConsignment = part.isConsignment;
				   addPart.kgbSerialNumber = part.kgbSerialNumber;
				   addPart.kbbSerialNumber = part.kbbSerialNumber;
				}
				addPart.action = part.action;
				if(addPart.action == 'issued' && consumerServiceRequest.ServiceTypeID != 39){
					isPartIssuedFlag = true;
				}
				if(addPart.action == 'received' && ((consumerServiceRequest.IsTenorRequest && consumerServiceRequest.ServiceTypeID != SERVICETYPE.INTERNAL_JOB) || consumerServiceRequest.consumerProduct.isAppleBrandID)){
					isPartReceivedFlag = true;
				}
				if (addPart.action == 'ordered') {
					isPartOrderedFlag = true;
				}
				if(addPart.action == 'alternateSelected'){
					isAlternatePartSelectedFlag = true;
				}
				if(addPart.action == 'doa'){
					isPartDOAFlag = true;
				}
				partArray.push(addPart);
			}
		});
		setPartArr(partArray);
		setIsPartIssued(isPartIssuedFlag);
		setIsPartReceived(isPartReceivedFlag);
		setIsAlternatePartSelected(isAlternatePartSelectedFlag);
		setIsPartDOA(isPartDOAFlag); 
		setIsPartOrdered(isPartOrderedFlag);
		if (partArray.find(a => a.action == 'consumed') && isPartDOAFlag && accessFn('GSX_FLOW')) {
			displayDifferentOutcomesAlert()
		} else if (isPartDOAFlag && accessFn('GSX_FLOW')) {
			setShowAddComptiaPartModal(true);
		} else if (partArray.find(a => (a.action == 'doa' || a.action == 'unused' || a.action == 'returned'))) {
			Swal.fire({
				title: localString('Are you sure') + '?',
				text: localString('The parts will be returned to Store'),
				icon: 'question',
				confirmButtonText: localString('Yes'),
				cancelButtonText: localString('No'),
				showConfirmButton: true,
				showCancelButton: true,
	
			}).then(swalResult => {
				if(swalResult.isConfirmed){
					updatePartStatus(partArray, isPartIssuedFlag, isPartReceivedFlag, isPartOrderedFlag);
				}
			});
		} else if (partArray.find(a => (a.action == 'consumed' && a.Serialized == 1)) && accessFn('GSX_FLOW')) {
			setShowKbbUpdateModal(true);
		} else if (partArray.find(a => (a.action != 'alternateSelected')) && isAlternatePartSelectedFlag) {
			displayDifferentOutcomesAlert();
		} else if (!partArray.find(a => (a.action != 'alternateSelected')) && isAlternatePartSelectedFlag) {
			setShowAlternatePartModal(true);
		} else {
			updatePartStatus(partArray, isPartIssuedFlag, isPartReceivedFlag, isPartOrderedFlag);
		} 
	}  

	const updatePartStatus = (partArray, isPartIssued, isPartReceived, isPartOrdered, additionalData) => {
		let serializedReceived = false;
		if (!partArray.length) {
		  	return;
		}
		if (isPartIssued) {
			let parts = _.cloneDeep(partArray);
			let serializedFlag = false;
			let partWithoutConsignment = false;
			let isConsignmentPart = false
			parts.map(item => {
				if ((item.Serialized == 1 || item.RequiresSerialNumber) && item.action == 'issued') {
					// self.selectedSerialNumber = "";
					setSelectedSerialNumber('');
					if (!accessFn('GSX_FLOW')) {
						setShowIssuePartModal(true);
					}
					if (accessFn('GSX_FLOW')) {
						if ((!item.isConsignment) || (item.isConsignment && consumerServiceRequest.gsxRepairFlags.repairTypeId == 2)) {
							partWithoutConsignment = true;
							setShowIssuePartModal(true);
						} else if (item.isConsignment) {
							isConsignmentPart = true;
						}
					}
					serializedFlag = true;
				}
			});
			if(!partWithoutConsignment && isConsignmentPart && accessFn('GSX_FLOW')){
				saveConsignmentPart(parts);
			}
			if (!serializedFlag) {
				issuePartsWithSerial(parts);
			}
		} else if (isPartReceived && consumerServiceRequest?.consumerProduct?.isAppleBrandID) {
			let parts = _.cloneDeep(partArray);
			parts.map(item => {
				if (item.RequiresSerialNumber && item.action == 'received' && item.Serialized != 1 ) {
					serializedReceived = true;
					setSelectedSerialNumber('');
				}
			});
			if (!serializedReceived) {
				issuePartsWithSerial(parts);
			}
		} else if (isPartReceived && consumerServiceRequest?.consumerProduct && consumerServiceRequest.IsTenorRequest) {
			let parts = _.cloneDeep(partArray);
			parts.map(item => {
				if (item.RequiresSerialNumber && item.action == 'received' && item.Serialized != 1 ) {
					setShowDefectivePartReceiveModal(true);
					setSelectedSerialNumber('');
					serializedReceived = true;
				}
			});
			if (!serializedReceived) {
				issuePartsWithSerial(parts);
			}
		} else {
			let parts = _.cloneDeep(partArray);
			if (partStack && partStack.length > 0) {
				if (_.isEqual(partStack, parts)) {
					return;
				} else {
					setPartStack(parts);
				}
			} else {
				setPartStack(parts);
			}
			setLoader(true);
			let data = {
				"ConsumerServiceRequestID": consumerServiceRequest?.ConsumerServiceRequestID,
				"parts": parts,
				"OrderFromID": serviceLocationObj?.OrganizationOutletLocationID,
				"Source": consumerServiceRequest?.Source
			};
			if(isPartOrdered){
				data.PartnerServiceLocationID = consumerServiceRequest?.PartnerServiceLocationID;
				if(consumerServiceRequest.Source == "SkyworthDashboard") {
					data.ShippedTo = shippingLocationObj.OrganizationOutletLocationID;
					data.BilledTo = billingLocationObj.OrganizationOutletLocationID;
				}
			}
			let apiUrl = 'updateReceivedPart';
			if (accessFn('GSX_FLOW')) {
			 	apiUrl = 'updateReceivedPart';
			} else {
			  	apiUrl = 'updatePartStatus';
			}
			if (additionalData) {
				data = {...data, ...additionalData};
			}
			apiCall({
				url: apiUrl,
				data: data,
				callBack: (result) => {
					setLoader(false);
					if (result.success) {						
						setRadioBtnClicked(false);
						setIsPartIssued(false);
						setIsPartDOA(false);
						setPartArr([]);
						setShowServiceLocation(false);
						setShowShippingLocation(false);
						setShowBillingLocation(false);
						setShowAddComptiaPartModal(false);
						getRequestById();
						getPartsForRequest();
					} else {
        				if (accessFn('GSX_FLOW') && result.enableUserConsent && apiUrl == 'updateReceivedPart') {
							Swal.fire({
								title: '',
								text: localString(displayError(result)),
								// icon: 'question',
								confirmButtonText: localString('Proceed without GSX update'),
								cancelButtonText: localString('Cancel'),
								showConfirmButton: true,
								showCancelButton: true,
								allowEscapeKey: false,
								allowOutsideClick: false,
							}).then(swalResult => {
								if (swalResult.isConfirmed) {
									updatePartStatus(partArray, isPartIssued, isPartReceived, isPartOrdered, { isGSXBypass: true });
								}
							});
						} else {
							Swal.fire({
								icon: 'error',
								text: localString(displayError(result)),
							});
							clearOptionsAndValueStates();
						}
						setPartStack([]);
					}
				},
				errorCB: (err) => {
					setLoader(false);
					clearOptionsAndValueStates();
				},
			});
		}
	}

	const saveConsignmentPart = (parts) => {
		setLoader(true);
		let reqObj = {
			"ConsumerServiceRequestID": consumerServiceRequest?.ConsumerServiceRequestID,
            "parts": parts,
            "OrderFromID": serviceLocationObj?.OrganizationOutletLocationID,
            "Source": consumerServiceRequest?.Source
		};
		apiCall({
			url: 'updateReceivedPart',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					setIsPartIssued(false);
					setIsPartDOA(false);
					setShowServiceLocation(false);
					setShowShippingLocation(false);
					setShowBillingLocation(false);
					setShowAddComptiaPartModal(false);
					getRequestById();
					// getPartsForRequest();
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setLoader(false),
		});
	}

	const issuePartsWithSerial = (parts) => {
		let isPartIssuedFlag = false;
		let isPartDoaFlag = false;
		let isPartReceivedFlag = false;
		setIsPartIssued(isPartIssuedFlag);
		setIsPartDOA(isPartDoaFlag);
		setIsPartReceived(isPartReceivedFlag);
		let partArray = _.cloneDeep(parts);

		for (var i = 0; i < partArray.length; i++) {
			if (partArray[i].action == 'issued' && (partArray[i].Serialized == 1)) {
				partArray[i].SerialNumber = partArray[i].issuedPartValue;
				partArray[i].PartSerialNumber = partArray[i].issuedPartValue;
				if (!partArray[i].isConsignment || (partArray[i].isConsignment && consumerServiceRequest.gsxRepairFlags.repairTypeId == 2 && (partArray[i].Serialized == 1 || partArray[i].RequiresSerialNumber))) {
					partArray[i].PartItemID = (selectedSerialNumberArray?.length && selectedSerialNumberArray[i]) ? selectedSerialNumberArray[i].PartItemID : undefined;
				}
			}
		}
		for (var i = 0; i < partArray.length; i++) {
			if (partArray[i].action == 'issued' && partArray[i].RequiresSerialNumber) {
				if (partArray[i]?.productUniqueValue?.length == 15 && partArray[i]?.altUniqueValue?.length == 15) {
					if(consumerServiceRequest.consumerProduct.BrandID === 98){ //hack to save a hack from getting deleted on merge conflict
						partArray[i].IMEI1 = partArray[i].issuedPartValue;
						partArray[i].IMEI2 = partArray[i].issuedPartValue;
						partArray[i].SerialNumber = partArray[i].issuedPartValue;
						partArray[i].PartSerialNumber = partArray[i].issuedPartValue;
						partArray[i].PartItemID = (selectedSerialNumberArray?.length && selectedSerialNumberArray[i]) ? selectedSerialNumberArray[i].PartItemID : undefined;
					} else {
						partArray[i].IMEI1 = partArray[i].productUniqueValue;
						partArray[i].IMEI2 = partArray[i].altUniqueValue;
						partArray[i].SerialNumber = partArray[i].serialNumberValue;
						partArray[i].PartSerialNumber = partArray[i].serialNumberValue;
						if (partArray[i].Serialized == 1) {
							let validSerialArray;
							if (selectedSerialNumberArray[i].SerialNumber == partArray[i].serialNumberValue) {
								validSerialArray = selectedSerialNumberArray[i];
							} else if (selectedSerialNumberArray[i].SerialNumber == partArray[i].altUniqueValue) {
								validSerialArray = selectedSerialNumberArray[i];
							} else if (selectedSerialNumberArray[i].SerialNumber == partArray[i].productUniqueValue) {
								validSerialArray = selectedSerialNumberArray[i];
							} else {
								validSerialArray = undefined;
							}
							if (validSerialArray) {
								partArray[i].PartItemID = validSerialArray.PartItemID;
							} else {
								Swal.fire({
									icon: 'error',
									title: localString('Sorry'),
									text: localString('Entered details do not match with selected serial number'),
								});
								return;
							}
						}
					}		
				} else {
					Swal.fire({
						icon: 'error',
						title: localString('Sorry'),
						text: localString('Please enter correct IMEI'),
					});
					return;
				}
	
			}
		}
		setPartArr(partArray);
		setShowIssuePartModal(false);
		updatePartStatus(partArray, isPartIssuedFlag, isPartReceivedFlag, isPartOrdered);		
	}

	const getServiceLocations = () => {
		if (consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID && ([35].indexOf(consumerServiceRequest.ServiceTypeID) == -1) && !productNotMapped) {
			setLoader(true);
			let reqObj = {
				PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID,
				ServiceTypeID: consumerServiceRequest.ServiceTypeID,
				ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
			};
			if (consumerServiceRequest.consumerProduct) reqObj.BrandID = consumerServiceRequest?.consumerProduct?.BrandID;
			apiCall({
				url: 'getOrganizationOutletLocations',
				data: reqObj,
				callBack: (result) => {
					setLoader(false);
					if (result?.success && result?.data?.organizationOutletLocations) {
						let arr = result.data.organizationOutletLocations.map((v) => ({ ...v, value: v.Name }));
						setServiceLocationOptions(arr);
					} else {
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				},
				errorCB: (err) => setLoader(false),
			});
		}
	}

	const getBillingAndShippingLocations = () => {
		setLoader(true);
		let reqObj = {
			SenderOrganizationOutletLocationID: outletLocationData?.OrganizationOutletLocationID,
			ReceiverOrganizationOutletLocationID: serviceLocationObj?.OrganizationOutletLocationID,
		}
		apiCall({
			url: 'getshippedToBuildtoMapping',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if (result?.success && result?.data?.shippedTo) {
					let arr = result.data.shippedTo.map((v) => ({ ...v, value: v.Name }));
					setShippingLocationOptions(arr);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setLoader(false),
		});
	}

	useEffect(() => {
		showShippingLocation && getBillingAndShippingLocations();
	}, [showShippingLocation]);


	const partsRequestedColumns = [
		{
			title: localString("Part Name"),
			showHeading: true,
			customCell: (row) => <p style={{ margin: '0px' }}>{row?.Name || '--'}</p>
		},
		{
			title: localString("Part Code"),
			showHeading: true,
			customCell: (row) => <p style={{ margin: '0px' }}>{row.PartCode || '--'}</p>
		},
		{
			title: localString("HSN Code"),
			showHeading: !accessFn('GSX_FLOW'),
			customCell: (row) => <p style={{ margin: '0px' }}>{row?.HSNCode || '--'}</p>
		},
		{
			title: localString("Component Code"),
			showHeading: accessFn('GSX_FLOW'),
			customCell: (row) => <p style={{ margin: '0px' }}>{row?.comptiaGroup?.componentName || '--'}</p>
		},
		{
			title: localString("Issue Code"),
			showHeading: accessFn('GSX_FLOW'),
			customCell: (row) => <p style={{ margin: '0px' }}>{row?.comptiaCode?.comptiaCode + ' ' + `${row?.comptiaCode?.comptiaDescription ? row?.comptiaCode?.comptiaDescription : ''}`}</p>
		},
		{
			title: localString("Use Consignment Part"),
			showHeading: accessFn('GSX_FLOW'),
			customCell: (row) => <p style={{ margin: '0px' }}>{row?.isConsignment ? localString("Yes"): localString("No")}</p>
		},
		{
			title: localString("Serial No"),
			showHeading: !accessFn('GSX_FLOW'),
			customCell: (row) => <p style={{ margin: '0px' }}>{row?.SerialNumber ? row?.SerialNumber : row?.kgbSerialNumber ? row?.kgbSerialNumber : '--'}</p>
		},
		{
			title: localString("Good Part Serial No"),
			showHeading: accessFn('GSX_FLOW'),
			customCell: (row) => <p style={{ margin: '0px' }}>{row?.SerialNumber ? row?.SerialNumber : row?.kgbSerialNumber ? row?.kgbSerialNumber : '--'}</p>
		},
		{
			title: localString("Bad Part Serial No"),
			showHeading: accessFn('GSX_FLOW'),
			customCell: (row) => <p style={{ margin: '0px' }}>{row?.BadPartSerialNumber ? row?.BadPartSerialNumber : row?.kbbSerialNumber ? row?.kbbSerialNumber : '--'}</p>
		},
		{
			title: localString("Purchase Order No"),
			showHeading: showPurchaseOrderReferenceNumber,
			customCell: (row) => <p style={{ margin: '0px' }}>{row?.PurchaseOrderReferenceNumber || '--'}</p>
		},
		{
			title: localString("Qty"),
			showHeading: true,
			customCell: (row) => <p style={{ margin: '0px' }}>{1}</p>
		},
		{
			title: localString("Avl. Qty"),
			showHeading: true,
			customCell: (row) => <p style={{ margin: '0px' }}>{row?.AvailableQuantity}</p>
		},
		{
			title: localString("Status"),
			showHeading: true,
			customCell: (row) => {
				return (
					<>
						<p style={{ margin: '0px' }}><span className='first-letter-capitalize'>{localString(getDisplayText(row?.statusDetails?.Status))}</span> 
						{ row.statusDetails && row.statusDetails.PartTransactionStatusID == 8 && 
							<Tooltip
								content={() => <span>{localString(row?.Remarks)}</span>}
								className={{
									tooltipText: 'warningTooltipText'
								}}
								position='top'
							>
								<span class="glyphicon glyphicon-info-sign"></span>
							</Tooltip> }
						</p>
					</>
				)
			}
		},
		{
			title: localString("Requested By"),
			showHeading: true,
			customCell: (row) => <p style={{ margin: '0px' }}>{row?.requestedByEntity?.Name || '--'}</p>
		},
		{
			title: localString("PR Number"),
			showHeading: showPurchaseRequestReferenceId,
			customCell: (row) => <p style={{ margin: '0px' }}>{row?.PRReferenceNo || '--'}</p>
		},
		{
			title: localString("Issued By"),
			showHeading: true,
			customCell: (row) => <p style={{ margin: '0px' }}>{row?.issuedByEntity?.Name || '--'}</p>
		},
		{
			title: localString(""),
			showHeading: canUpdateServiceRequest && showPartStatusUpdateButton && !accessFn('READ_ONLY_WORKSHOP') && showSave && (accessFn('STORE_MANAGEMENT') || accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS') || accessFn('ACCEPT_RETURN_DEVICE')),
			customCell: (row, index) => {
				return (
					<div>
						{row?.buttons && row.buttons.length > 0 && row.buttons.map((item, k) => {
							return (
								<React.Fragment key={k}>
									<input 
										type="radio" 
										name={"partRadioBtn" + index} 
										id={"partRadioBtn" + index + k} 
										checked={row.radioBtnValue === item}
										className='partsRequestedRadioBtn' 
										onChange={() => handleRadio(item, index)}
									/>
									<label for={"partRadioBtn" + index + k}>{localString(item)}</label><br />
								</React.Fragment>
							)
						})}
					</div>
				)
			}
		}
	]


	return (
		<>
			{
				(loader) && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper
						bodyClassName="panel-body-no-padding" 
						title={localString("Parts Requested")}
					>
						{ !partsRequested.length &&
							<div style={{ padding: '15px' }}>{localString("No Parts Requested")}</div>
						}
						{ partsRequested && partsRequested.length > 0 && <div className='partsRequested'>
							<CommonTable 
								columns={partsRequestedColumns}
								data={partsRequested}
								localString={localString}
								tableClasses={{
									table: 'table-striped table-bordered'
								}}
							/> 
						</div> }
						<div className='container-fluid'>
							<div className='row'>
								{
									showServiceLocation && 
										<div className="col-sm-4">
											<div className="form-group">
												<label>{localString("Parts Supply Location")}</label>
												<Dropdown
													value={serviceLocation}
													options={serviceLocationOptions}
													handleOnChange={(data) => {
														setServiceLocation(data.value);
														setServiceLocationObj(data);
														if (consumerServiceRequest.Source == "SkyworthDashboard") {
															setShowShippingLocation(true);
														}
													}}
													filterBy='value'
													showDownArrow={false}
													showTooltip={true}
												/>
											</div>
										</div> 
								}
								{
									showShippingLocation && 
										<div className="col-sm-4">
											<div className="form-group">
												<label>{localString("Shipping Location")}</label>
												<Dropdown
													value={shippingLocation}
													options={shippingLocationOptions}
													handleOnChange={(data) => {
														setShippingLocation(data.value);
														setShippingLocationObj(data);
														setBillingLocationOptions(data.billedTo);
														setShowBillingLocation(true);
													}}													
													filterBy='value'
													showDownArrow={false}
													showTooltip={true}
												/>
											</div>
										</div> 
								}
								{
									showBillingLocation && 
										<div className="col-sm-4">
											<div className="form-group">
												<label>{localString("Billing Location")}</label>
												<Dropdown
													value={billingLocation}
													options={billingLocationOptions}
													handleOnChange={(data) => {
														setBillingLocation(data.value);
														setBillingLocationObj(data);
													}}													
													filterBy='value'
													showDownArrow={false}
													showTooltip={true}
												/>
											</div>
										</div> 
								}
							</div>
						</div>
						{
							(displaySaveBtn && showPartStatusUpdateButton && !accessFn('READ_ONLY') && showSave && (accessFn('STORE_MANAGEMENT') || accessFn('REPAIR_DEVICE') || accessFn('REASSIGN_JOBS') || accessFn('ACCEPT_RETURN_DEVICE'))) &&
								<div className='paddingB10'>
									<Button 
										className="btn button-servify pull-right"
										onClick={() => updateParts()}
										isDisabled={disableSave}
									>
										{localString("Save")}
									</Button>
								</div>	
						}
					</PageContentWrapper>
				</div>

				{/* ********** Issue Part Modal ********** */}
				<IssuePartModal 
					{...props}
					setLoader={setLoader}
					partArr={partArr} setPartArr={setPartArr}
					issuePartsWithSerial={issuePartsWithSerial}
					clearOptionsAndValueStates={clearOptionsAndValueStates}
					showIssuePartModal={showIssuePartModal} setShowIssuePartModal={setShowIssuePartModal}
					serializedPartOptions={serializedPartOptions} setSerializedPartOptions={setSerializedPartOptions}
					selectedSerialNumberArray={selectedSerialNumberArray} setSelectedSerialNumberArray={setSelectedSerialNumberArray}
				/>

				{/* ********** Alternate Part Modal ********** */}
				<AlternatePartModal 
					{...props}
					setLoader={setLoader}
					setRadioBtnClicked={setRadioBtnClicked}
					partArr={partArr} setPartArr={setPartArr}
					setIsAlternatePartSelected={setIsAlternatePartSelected}
					altPartOptions={altPartOptions} setAltPartOptions={setAltPartOptions}
					showAlternatePartModal={showAlternatePartModal} setShowAlternatePartModal={setShowAlternatePartModal}
					altSerializedPartOptions={altSerializedPartOptions} setAltSerializedPartOptions={setAltSerializedPartOptions}
				/>

				{/* ********** Add Comptia Part Modal ********** */}
				<AddComptiaPartModal 
					{...props}
					setLoader={setLoader}
					updatePartStatus={updatePartStatus}
					partArr={partArr} setPartArr={setPartArr}
					showAddComptiaPartModal={showAddComptiaPartModal} setShowAddComptiaPartModal={setShowAddComptiaPartModal}
				/>

				{/* ********** KBB Update Modal ********** */}
				<KbbUpdateModal 
					{...props}
					setLoader={setLoader}
					updatePartStatus={updatePartStatus}
					partArr={partArr} setPartArr={setPartArr}
					showKbbUpdateModal={showKbbUpdateModal} setShowKbbUpdateModal={setShowKbbUpdateModal}
				/>

				{/* ** NOTE ** Skipped defective_part_receive_modal as it is not in use ==> Discussed with DAMA */}

			</div>
		</>
	)
}