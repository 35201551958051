import React, { useState } from 'react';
import {Radio,Button} from 'sfy-react';

const CapturePaymentModeInput=(props)=>{
    const {accessFn, localString, showModal, setShowModal,claimRequestNextWs , modeOfPayment,setModeOfPayment, type,selectedMode,replacementFulfillment,getPaymentLink}=props;
    const [paymentModeRadio,setPaymentModeRadio] = useState('Cash');
    
    const sendPayment = (e) =>{
        e.preventDefault();
        setShowModal({...showModal,showCapturePaymentMode:false});
        if(type== 'claimWorkshop'){
            if (paymentModeRadio == 'Cash' && paymentModeRadio.checked) {
                setModeOfPayment('Cash')
            } else {
                setModeOfPayment('Card')
            }
            claimRequestNextWs()
        }else if(type == 'replacementFlow'){
            if (selectedMode) {
                getPaymentLink();
            }
            else {
                if (paymentModeRadio == 'Cash' && paymentModeRadio.checked) {
                    setModeOfPayment('Cash')
                } else {
                    setModeOfPayment('Card')
                }
                replacementFulfillment(null,paymentModeRadio);
            }
        }
    }

    const radioButton =[
        {
            type:'radio',
            value:'Cash',
            name:'payment-mode',
            radioText:'Cash',
            isSelect:paymentModeRadio,
            onUpdateRadio:(val)=>{
                setPaymentModeRadio(val);
            }
        },
        {
            type:'radio',
            value:'Card',
            name:'payment-mode',
            radioText:'Card',
            isSelect:paymentModeRadio,
            onUpdateRadio:(val)=>{
                setPaymentModeRadio(val);
            }
        },
    ]
    return(
        <>
            <div className='row'>
                <div className='col-sm-12'>
                    <form onSubmit={sendPayment}>
                        <div className='col-sm-12'>
                            <p>
                                {localString("Please select mode of payment")}
                            </p>
                        </div>
                        <div className='col-sm-12'>
                            {
                                radioButton && radioButton.map((radio,index)=>(
                                    <label className='check-label'>
                                        <Radio
                                            value={radio.value}
                                            selectedValue={radio.isSelect}
                                            name={radio.name}
                                            id={radio.value}
                                            classes='customClasses'
                                            handleOnUpdate={(e)=>{
                                                radio.onUpdateRadio(e.target.value)
                                            }}
                                        >
                                            {localString(radio.radioText)}
                                        </Radio> 
                                    </label>
                                ))
                            }
                        </div>
                        <div className='marginT10'>
                            {/* <Button  type="submit" className='btn button-servify pull-right'>{localString("Save")}</Button> */}
                            <input type="submit" name="submit" class="btn button-servify pull-right" value={localString("Save")} />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default CapturePaymentModeInput;
