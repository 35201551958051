import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import _ from "lodash";
import { Loader, Input, Button, Dropdown } from "sfy-react";
import { displayError, SERVICETYPE, validateThis } from "../../../../../Utility/Utils";
import PageContentWrapper from "../../../../../components/PageContentWrapper/PageContentWrapper";
import AddPartsModal from "./AddPartsModal";
import DiscountModal from "./DiscountModal";
import "./ProvisionalEstimate.scss";

const dropDownText = "Tax Payable";
const taxablePercentage = ["5%", "12%", "18%", "28%"];

const ProvisionalEstimate = (props) => {
	const {
		apiCall,
		accessFn,
		consumerServiceRequest,
		localString,
		currencySymbol,
		getRequestById,
		canUpdateServiceRequest,
		isRequestSFANeeded,
		addedfaultcodes,
		requestSFA,
		partsRequested,
		getPartsForRequest,
		appleDropOffFlow,
		showRequestLogs
	} = props;

	const consumerProduct = consumerServiceRequest?.consumerProduct;
	const sendIssuesGSXFlag =
		consumerServiceRequest.DiagnosisDetails &&
		consumerServiceRequest.DiagnosisDetails.ReportedSymptom
			? false
			: true;

	const depreciatedValue = consumerServiceRequest.consumerProduct
		.DepreciatedValue
		? consumerServiceRequest.consumerProduct.DepreciatedValue
		: {};

	const [showLoader, setShowLoader] = useState(false);
	const [provisionalCharges, setProvisionalCharges] = useState()
	const [showestimationbuttons, setShowestimationbuttons] = useState(true);
	const [showsavebutton, setShowsavebutton] = useState(false);
	const [partsArray, setPartsArray] = useState([]);
	const [PartItem, setPartItem] = useState(undefined);
	const [defaultLanguage, setDefaultLanguage] = useState("");
	const [partNameTypeAhead, setPartNameTypeAhead] = useState("");
	const [addNewPart, setAddNewPart] = useState(false);
	const [disableAddBtn, setDisableAddBtn] = useState(false);
	const [issuesValidated, setIssuesValidated] = useState(false);
	const [isSerialized, setIsSerialized] = useState(false);
	const [isAbused, setIsAbused] = useState(false);
	const [isReturnable, setIsReturnable] = useState(false);
	const [isCovered, setIsCovered] = useState(false);
	const [exceptionRaised, setExceptionRaised] = useState("");
	const [exceptionRejected, setExceptionRejected] = useState("");
	const [getPartsCalled, setGetPartsCalled] = useState(false);

	const [chargeType, setChargeType] = useState([]);
	const [serviceChargeAdded, setServiceChargeAdded] = useState(false);
	const [partsListCalled, setPartsListCalled] = useState(false);
	const [isProvisionalChargesAdded, setIsProvisionalChargesAdded] =
		useState(false);
	const [typeOfWarranty, setTypeOfWarranty] = useState([]);
	const [partwarranty, setPartwarranty] = useState("");
	const [partsList, setPartsList] = useState([]);
	const [allParts, setAllParts] = useState([]);
	const [chargesArray, setChargesArray] = useState([]);
	const [isProvisionalCharges, setIsProvisionalCharges] = useState();
	const [partname, setPartname] = useState("");
	const [selectedPart, setSelectedPart] = useState({});
	const [newPartTax, setNewPartTax] = useState();
	const [newPartWarranty, setNewPartWarranty] = useState();
	const [newPartName, setNewPartName] = useState("");
	const [newPartRemark, setNewPartRemark] = useState("");
	const [newPartHSN, setNewPartHSN] = useState("");
	const [newPartPrice, setNewPartPrice] = useState("");
	const [finishedId, setFinishedId] = useState("");
	const [partSelected, setPartSelected] = useState(false);
	const [newFinishedGood, setNewFinishedGood] = useState("");
	const [pricingOptionList, setPricingOptionList] = useState([]);
	const [pricingOption, setPricingOption] = useState("");
	const [pricingOptionobj, setPricingOptionobj] = useState({});
	const [partRemark, setPartRemark] = useState("");
	const [availableQuantity, setAvailableQuantity] = useState("");
	const [newPartsDropDown, setNewPartsDropDown] = useState("");
	const [partSerial, setPartSerial] = useState("");
	const [coverageOption, setCoverageOption] = useState("");
	const [coverageOptionObj, setCoverageOptionObj] = useState({});
	const [price, setPrice] = useState("");
	const [previousDiscount, setPreviousDiscount] = useState([]);
	const [finishedGoodAutoSelected, setFinishedGoodAutoSelected] = useState(false);
	const [modal, setModal] = useState({
		showDiscountModal: false,
		showAddPartsModal: false,
	});
	// useEffect(() => {
	// 	if (
	// 		consumerServiceRequest &&
	// 		consumerServiceRequest.consumerProduct &&
	// 		!partsListCalled
	// 	) {
	// 		getPartsList();
	// 	}
	// }, []);
	const isDiagnosisCompletedByCC = () => {
		let diagInit = _.findIndex(consumerServiceRequest.logs, {
			Status: "Diagnosis initiated",
			PerformedByRole: "CustomerCare",
		});
		let diagComp = _.findIndex(consumerServiceRequest.logs, {
			Status: "Diagnosis completed",
			PerformedByRole: "CustomerCare",
		});
		let cannotDiag = _.findIndex(consumerServiceRequest.logs, {
			Status: "Cannot be diagnosed",
			PerformedByRole: "CustomerCare",
		});

		if (diagInit !== -1) {
			if (diagComp !== -1 && cannotDiag !== -1) {
				if (diagComp < cannotDiag) {
					return diagComp < diagInit;
				} else {
					return cannotDiag < diagInit;
				}
			} else if (diagComp != -1) {
				return diagComp < diagInit;
			} else if (cannotDiag != -1) {
				return cannotDiag < diagInit;
			}
			return false;
		}
		return true;
	};

	const updateChargeType = () => {
		let chargeTypeCopy = [];

		chargeTypeCopy = _.concat(chargeTypeCopy, [
			{
				Name: "",
				Placeholder: localString("Other Charge"),
				Display: "Other Charge",
				Type: "Other",
			},
			{
				Placeholder: localString("Service Charge"),
				Display: "Service Charge",
				Type: "ServiceCharge",
			},
			{
				Name: "",
				Placeholder: localString("Part name"),
				Display: "Parts",
				Type: "Part",
			},
			{
				Placeholder: localString("Discount"),
				Display: "Discount",
				Type: "Discount",
			},
		]);

		setChargeType(chargeTypeCopy);
	};

	const changePrice = (param) => {
		let copyPartWarranty = param.partwarranty
			? param.partwarranty
			: partwarranty;
		let copyIsAbused = param.isAbused ? param.isAbused : isAbused;
		let copySelectedPart = param.selectedPart
			? param.selectedPart
			: selectedPart;
		let copyIsReturnable = param.isReturnable
			? param.isReturnable
			: isReturnable;

		if (copyPartWarranty == "In warranty") {
			if (copyIsAbused) {
				setPrice(copySelectedPart.partPrice.MRP);
			} else {
				if (copyIsReturnable) {
					if (copySelectedPart && copySelectedPart.partPrice) {
						if (copySelectedPart.partPrice.ExchangePriceMRP) {
							setPrice(
								copySelectedPart.partPrice.ExchangePriceMRP
							);
						} else if (copySelectedPart.partPrice.Price) {
							setPrice(copySelectedPart.partPrice.Price);
						} else {
							// pass
						}
					}
				} else {
					setPrice("0");
				}
			}
		} else {
			if (copyIsAbused) {
				setPrice(copySelectedPart.partPrice.MRP);
			} else {
				if (copySelectedPart && copySelectedPart.partPrice) {
					if (copySelectedPart.partPrice.ExchangePriceMRP) {
						setPrice(copySelectedPart.partPrice.ExchangePriceMRP);
					} else if (copySelectedPart.partPrice.Price) {
						setPrice(copySelectedPart.partPrice.Price);
					} else {
						// pass
					}
				}
			}
		}
	};

	const getPart = () => {
		setGetPartsCalled(true);
		if (
			consumerServiceRequest.consumerProduct &&
			consumerServiceRequest.consumerProduct.FinishedGoodID &&
			!consumerServiceRequest.consumerProduct
				.isManuallyUpdatedFinishedGoodID
		) {
			setFinishedId(
				consumerServiceRequest.consumerProduct.FinishedGoodID
			);
			setFinishedGoodAutoSelected(true);

			return;
		}

		let data = {
			ProductID: consumerServiceRequest.consumerProduct.ProductID,
			PartnerServiceLocationID:
				consumerServiceRequest.PartnerServiceLocationID,
		};
		setShowLoader(true)
		apiCall({
			url: "getFinishedGood",
			data: data,
			callBack: (result) => {
				setShowLoader(false)
				if (result.success) {
					setAllParts(result.data);

					let selectedFinishedGood;
					if (
						consumerServiceRequest &&
						consumerServiceRequest.consumerProduct &&
						consumerServiceRequest.consumerProduct.FinishedGoodID
					) {
						selectedFinishedGood = _.find(
							result.data.productFinishedGoodMappings,
							function (o) {
								return (
									o.part.PartID ==
									consumerServiceRequest.consumerProduct
										.FinishedGoodID
								);
							}
						);
						setNewFinishedGood(
							selectedFinishedGood.part.Name +
								" " +
								selectedFinishedGood.part.PartCode
						);
					} else if (
						result.data.productFinishedGoodMappings &&
						result.data.productFinishedGoodMappings.length == 1
					) {
						selectedFinishedGood = _.find(
							result.data.productFinishedGoodMappings,
							function (o) {
								return o.part.VisibilityStatus == 0;
							}
						);

						setFinishedGoodAutoSelected(true);
					} else {
						if (
							consumerServiceRequest &&
							consumerServiceRequest.consumerProduct &&
							consumerServiceRequest.consumerProduct.gsxInfo
						) {
							if (
								consumerServiceRequest.consumerProduct.gsxInfo
									.configDescription
							) {
								selectedFinishedGood = _.find(
									result.data.productFinishedGoodMappings,
									function (o) {
										return (
											o.part.Name ==
											consumerServiceRequest
												.consumerProduct.gsxInfo
												.configDescription
										);
									}
								);
							}
							if (
								!selectedFinishedGood &&
								consumerServiceRequest.consumerProduct.gsxInfo
									.productDescription
							) {
								selectedFinishedGood = _.find(
									result.data.productFinishedGoodMappings,
									function (o) {
										return (
											o.part.Name ==
											consumerServiceRequest
												.consumerProduct.gsxInfo
												.productDescription
										);
									}
								);
							}
						}
					}
					if (selectedFinishedGood) {
						setFinishedId(selectedFinishedGood.PartID);
						setFinishedGoodAutoSelected(true);
					} else {
						setFinishedGoodAutoSelected(false);
					}
				} else {
					Swal.fire({
						title: "",
						text: localString(displayError(result)),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	const getProvisionalCharges =  () => {
		let data = {
		  ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID
		}

		setShowLoader(true)
		apiCall({
			url: 'getProvisionalCharges',
			data: data,
			callBack: (result) => {
				setShowLoader(false)
				if (result.success) {
					
					setProvisionalCharges(result.data)
				
				} else {
				Swal.fire(
					'',
					localString(displayError(result)),
					'error'
				);
				}
			}
		});
	  }

	useEffect(() => {
		getProvisionalCharges()
	}, [])
	

	useEffect(() => {
		if (
			[SERVICETYPE.CLAIM, SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_CARRY_IN, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) > -1
		) {
			setTypeOfWarranty(["Out of warranty"]);
		} else {
			setTypeOfWarranty(["Out of warranty", "In warranty"]);
		}
	}, [consumerServiceRequest]);

	useEffect(() => {
		updateChargeType();

		if (
			provisionalCharges &&
			provisionalCharges.charges &&
			provisionalCharges.charges.length > 0
		) {
			setIsProvisionalChargesAdded(true);
		} else {
			setIsProvisionalChargesAdded(false);
		}

		setIsProvisionalCharges(
			_.find(consumerServiceRequest.logs, {
				Status: "Provisional estimate generated",
			})
		);

		let isDeviceInspected = _.find(consumerServiceRequest.logs, {
			Status: "Device inspected",
		});
		let isRepairCompleted = _.find(consumerServiceRequest.logs, {
			Status: "Repair completed",
		});

		if (consumerServiceRequest.consumerProduct) {
			if (
				!getPartsCalled &&
				(!consumerServiceRequest.consumerProduct.FinishedGoodID ||
					consumerServiceRequest.consumerProduct
						.isManuallyUpdatedFinishedGoodID)
			) {
				getPart();
			}

			if (
				(isDiagnosisCompletedByCC() &&
				consumerServiceRequest.consumerProduct.FinishedGoodID &&
				!isDeviceInspected &&
				!isRepairCompleted &&
				accessFn("ACCEPT_RETURN_DEVICE")) 
				// || (appleDropOffFlow && accessFn("REPAIR_DEVICE") && !showRequestLogs.displayDiagnosisStepsToEngFor2ndTime && (showRequestLogs.diagnosisCompletedByEngg || showRequestLogs.diagnosisCancelByEngg)) 
			) {
				setShowestimationbuttons(true);
			} else {
				setShowestimationbuttons(false);
			}
		}

		let issueAddedValid = true;

		if (
			consumerServiceRequest.issues &&
			consumerServiceRequest.issues.length > 0
		) {
			for (let i = 0; i < consumerServiceRequest.issues.length; i++) {
				if (consumerServiceRequest.issues[i].IsValidated == false) {
					issueAddedValid = false;
					break;
				}
			}
		}

		if (accessFn("GSX_FLOW")) {
			if (sendIssuesGSXFlag) {
				setIssuesValidated(false);
			} else {
				if (issueAddedValid) {
					setIssuesValidated(true);
				} else {
					setIssuesValidated(false);
				}
			}
		} else {
			if (isRequestSFANeeded) {
				if (requestSFA && requestSFA.length > 0 && issueAddedValid) {
					setIssuesValidated(true);
				} else if (
					[SERVICETYPE.PICK_UP, SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1
				) {
					setIssuesValidated(false);
				}
			} else {
				if (
					consumerServiceRequest &&
					consumerServiceRequest.Symptoms &&
					consumerServiceRequest.Symptoms.length > 0 &&
					issueAddedValid
				) {
					setIssuesValidated(true);
				} else if (
					[SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN, SERVICETYPE.DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) >
					-1
				) {
					setIssuesValidated(false);
				}
			}
		}

		let _isServiceChargeAdded = _.find(provisionalCharges?.charges, {
			Type: "ServiceCharge",
		});
		let isTempServiceChargeAdded = _.find(chargesArray, {
			Type: "ServiceCharge",
		});

		if (_isServiceChargeAdded || isTempServiceChargeAdded) {
			setServiceChargeAdded(true);
		} else {
			setServiceChargeAdded(false);
		}

		if (partsList && partsList.length > 0) {
			let copyPartsList = _.cloneDeep(partsList);
			initPartsDropDown(copyPartsList);
		}
	}, [provisionalCharges, chargesArray]);

	const newPartWarrantyAndPart_warrantyOptions = useMemo(() => {
		let partWarrantyOptionsArray = [];
		if (typeOfWarranty && typeOfWarranty.length > 0) {
			partWarrantyOptionsArray = typeOfWarranty.map((data) => ({
				value: localString(data),
			}));
		}

		return partWarrantyOptionsArray;
	}, [typeOfWarranty]);

	const newPartsDropDownOptions = useMemo(() => {
		let partsListArray = [];
		if (partsList && partsList.length > 0) {
			partsListArray = partsList.map((data) => ({
				...data,
				value: data.Name + " " + data.PartCode,
			}));
		}

		return partsListArray;
	}, [partsList]);

	const newPartTaxOptions = useMemo(() => {
		let newPartTaxArray = [];
		if (taxablePercentage && taxablePercentage.length > 0) {
			newPartTaxArray = taxablePercentage.map((data) => ({
				value: data,
			}));
		}

		return newPartTaxArray;
	}, [taxablePercentage]);

	const newFinishedGoodOptions = useMemo(() => {
		let newFinishedGoodArray = [];
		if (
			allParts &&
			allParts.productFinishedGoodMappings &&
			allParts.productFinishedGoodMappings.length > 0
		) {
			newFinishedGoodArray = allParts.productFinishedGoodMappings.map(
				(data) => ({
					value: data.part.Name + " " + data.part.PartCode,
				})
			);
		}

		return newFinishedGoodArray;
	}, [allParts, allParts.productFinishedGoodMappings]);

	const pricingOptionOptions = useMemo(() => {
		let pricingOptionsArray = [];
		if (pricingOptionList && pricingOptionList.length > 0) {
			pricingOptionsArray = pricingOptionList.map((item) => ({...item,
				value: item.pricingOptionDescription,
			}));
		}

		return pricingOptionsArray;
	}, [pricingOptionList]);

	const handlePricingOption = (item) => {
		setPricingOptionobj(item);
		setPricingOption(item.value);
	};

	const handleNewFinishedGood = (item) => {
		setNewFinishedGood(item.value);
		setFinishedId(item.part.PartID);
		setPartSelected(true);
		if (provisionalCharges.length > 0) {
			Swal.fire({
				title: "Changing the SKU of the device will cause all provisional estimate details to be removed you will have to re-enter provisional estimate details. Do you want to continue?",
			    icon: "info",
				showCancelButton: true,
				confirmButtonColor: "#4C10C1",
				confirmButtonText: localString("Continue"),
				cancelButtonColor: "#d33",
				cancelButtonText: localString("Cancel"),
			}).then(function (isConfirm) {
				if (isConfirm === true) {
					updateFinishGoodID();
				} else {
					//
				}
			});
		} else {
			updateFinishGoodID();
		}
	};

	const handleNewPartTaxOptions = (item) => {
		setNewPartTax(item?.value?.slice(0, -1));
	};

	const handleNewPartWarranty = (item) => {
		setNewPartWarranty(item.value);
	};

	const handlePart_warranty = (item) => {
		setPartwarranty(item.value);
		changePrice({ partwarranty: item.value });
	};

	const handleNewPartsDropDown = (item) => {
		if (
			!item.partPrice.PriceUpdated &&
			consumerServiceRequest.consumerProduct.isAppleBrandID
		) {
			Swal.fire({
				title: "",
				text:
					localString(
						"Part price is not updated for the Selected part"
					) +
					"-" +
					item.PartCode +
					", " +
					localString("Please contact Admin"),
				icon: "error",
				confirmButtonText: localString("OK"),
			});
			return;
		}

		setNewPartsDropDown(item.value);
		setPartItem(item);
		setPartname(item.Name);
		setSelectedPart(item);
		changePrice({ selectedPart: item });

		if (!accessFn("GSX_FLOW")) {
			getAvailableQuantity(item);
		}

		if (item.Name == "Other" || item.Name == "Others") {
			setAddNewPart(true);
			setDisableAddBtn(true);

			let _typeOfWarranty = ["Out of warranty", "In warranty"];
			if (
				[SERVICETYPE.CLAIM, SERVICETYPE.CLAIM_PICK_UP, SERVICETYPE.CLAIM_CARRY_IN, SERVICETYPE.CLAIM_DROPOFF].indexOf(consumerServiceRequest.ServiceTypeID) >
				-1
			) {
				_typeOfWarranty = ["Out of warranty"];
			}
			setTypeOfWarranty(_typeOfWarranty);
		} else {
			setAddNewPart(false);
			setDisableAddBtn(false);
		}
	};

	const allowChargeDeletion = (chargeType) => {
		let allow = false; //False for mandatory deductible

		if (
			["Claim amount"].indexOf(chargeType) >= 1 &&
			accessFn("CLAIM_APPROVAL")
		) {
			allow = true;
		}
		if (
			["Part", "Other", "Discount", "Advance", "Advance payment"].indexOf(
				chargeType
			) > -1 &&
			accessFn("ADD_NORMAL_CHARGES")
		) {
			allow = true;
		}

		return allow;
	};

	const updateFinishGoodID = () => {
		let data = {
			ConsumerServiceRequestID:
				consumerServiceRequest.ConsumerServiceRequestID,
			FinishedGoodID: finishedId,
			ConsumerProductID:
				consumerServiceRequest.consumerProduct.ConsumerProductID,
		};

		setShowLoader(true)
		apiCall({
			url: "updateFinishGoodID",
			data: data,
			callBack: (result) => {
				setShowLoader(false)
				if (result.success) {
					setPartsListCalled(false);
					getRequestById();
					getProvisionalCharges();
				} else {
					Swal.fire({
						title: "",
						text: localString(displayError(result)),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	const updateChargesCall = (data, closeModal) => {
		setShowLoader(true)
		apiCall({
			url: "addProvisionalCharges",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					setChargesArray([]);
					setPartsList({});
					setPartsArray([]);
					setPartNameTypeAhead("");
					setPartItem(undefined);
					setAddNewPart(false);
					setDisableAddBtn(false);
					setPartwarranty("");
					getRequestById();
					getProvisionalCharges();
					closeModal && closeModal();
				} else {
					Swal.fire({
						title: "",
						text: localString(displayError(result)),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	const removeAddedCharge = (charge) => {
		let url = "removeProvisionalCharges";
		let data = {
			ProvisionalCharges: [charge],
			ConsumerServiceRequestID:
				consumerServiceRequest.ConsumerServiceRequestID,
		};

		setShowLoader(true)
		apiCall({
			url: url,
			data: data,
			callBack: (result) => {
				setShowLoader(false)
				if (result.success == true) {
					getRequestById();
					getProvisionalCharges();
					getPartsForRequest();
				} else {
					Swal.fire({
						title: "",
						text: localString(displayError(result)),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	const save = () => {
		let isArrayValid = true;
		let invalidArrayParam = "";
		let copyChargesArray = _.cloneDeep(chargesArray);

		copyChargesArray.forEach((charge) => {
			let validator;
			charge.IsTaxable = true;

			if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.INTERNAL_JOB) {
				charge.IsTaxable = false;
			}

			charge.ChargeType = "Credit";

			if (charge.Type == "Part" && charge.Remarks == "") {
				validator = validateThis(charge, [
					{
						checkType: "isAlphaNumeric",
						key: "Name",
					},
					{
						checkType: "isFloat",
						key: "Charge",
					},
					{
						checkType: "isAlphaNumeric",
						key: "ChargeCode",
					},
					{
						checkType: "isNotEmpty",
						key: "TaxSlab",
					},
					{
						checkType: "isNotEmpty",
						key: "ChargeCode",
					},
				]);

				charge.ChargeCodeType = "HSN";
				charge.IsDeducted = false;
				charge.TaxSlab = parseInt(charge.TaxSlab);
			} else if (charge.Type == "Part" && charge.Remarks !== "") {
				validator = validateThis(charge, [
					{
						checkType: "isAlphaNumeric",
						key: "Name",
					},
					{
						checkType: "isAlphaNumeric",
						key: "Remarks",
					},
					{
						checkType: "isFloat",
						key: "Charge",
					},
					{
						checkType: "isAlphaNumeric",
						key: "ChargeCode",
					},
					{
						checkType: "isNotEmpty",
						key: "TaxSlab",
					},
					{
						checkType: "isNotEmpty",
						key: "ChargeCode",
					},
				]);

				charge.ChargeCodeType = "HSN";
				charge.IsDeducted = false;
				charge.TaxSlab = parseInt(charge.TaxSlab);
			} else {
				validator = validateThis(charge, [
					{
						checkType: "isAlphaNumeric",
						key: "Name",
					},
					{
						checkType: "isFloat",
						key: "Charge",
					},
					{
						checkType: "isAlphaNumeric",
						key: "Remarks",
					},
				]);

				charge.ChargeCodeType = "SAC";
				if (
					charge.Type == "Advance payment" ||
					charge.Type == "Discount"
				) {
					charge.IsDeducted = true;
					if (charge.Type == "Advance payment") {
						charge.IsTaxable = false;
					}
				} else {
					charge.IsDeducted = false;
				}
			}

			if (!validator.isValid) {
				isArrayValid = false;
				invalidArrayParam = validator.invalidParams[0];
				if (invalidArrayParam === "ChargeCode") {
					invalidArrayParam = "Charge Code";
				} else if (invalidArrayParam === "TaxSlab") {
					invalidArrayParam = "Tax Amount";
				}
			}
		});

		if (!isArrayValid) {
			Swal.fire({
				title: "",
				text:
					localString("Please enter correct ") +
					localString(invalidArrayParam),
				icon: "error",
				confirmButtonText: localString("OK"),
			});
			return;
		}

		let oldDiscounts = 0;
		let oldAdvances = 0;
		let oldParts = 0;
		let oldMandatoryDeducitble = 0;
		let oldAdminFees = 0;
		let oldOthers = 0;
		let oldInsuranceAmount = 0;
		let oldInsuranceApproved = 0;
		let newOthers = 0;
		let newAdvances = 0;
		let newParts = 0;
		let newDiscounts = 0;
		let newMandatoryDeducitble = 0;
		let newAdminFees = 0;
		let newInsuranceAmount = 0;
		let newInsuranceApproved = 0;

		//Calculating old charges
		consumerServiceRequest.charges.forEach(function (charge) {
			switch (charge.Type) {
				case "Discount":
					oldDiscounts += charge.Charge;
					break;
				case "Other":
					oldOthers += charge.Charge;
					break;
				case "Advance":
					oldAdvances += charge.Charge;
					break;
				case "Advance payment":
					oldAdvances += charge.Charge;
					break;
				case "Part":
					oldParts += charge.Charge;
					break;
				case "Mandatory deductible":
					oldMandatoryDeducitble += charge.Charge;
					break;
				case "Admin fees":
					oldAdminFees += charge.Charge;
					break;
				case "Claim amount":
					oldInsuranceAmount += charge.Charge;
					break;
			}
		});

		//Calculating new charges
		copyChargesArray.forEach(function (charge) {
			switch (charge.Type) {
				case "Discount":
					newDiscounts += parseInt(charge.Charge, 10) || 0;
					break;
				case "Other":
					newOthers += parseInt(charge.Charge, 10) || 0;
					break;
				case "Advance":
					newAdvances += parseInt(charge.Charge, 10) || 0;
					break;
				case "Advance payment":
					newAdvances += parseInt(charge.Charge, 10) || 0;
					break;
				case "Part":
					newParts += parseInt(charge.Charge, 10) || 0;
					break;
				case "Mandatory deductible":
					newMandatoryDeducitble += parseInt(charge.Charge, 10) || 0;
					break;
				case "Admin fees":
					newAdminFees += parseInt(charge.Charge, 10) || 0;
					break;
				case "Claim amount":
					newInsuranceAmount += parseInt(charge.Charge, 10) || 0;
					break;
			}
		});

		//Computing all taxable charges without discounts
		let taxableAmount =
			(consumerServiceRequest.ServiceCharge || 0) +
			(consumerServiceRequest.PremiumCharge || 0) +
			(oldOthers || 0) +
			(newOthers || 0) -
			(consumerServiceRequest.WarrantyConcession || 0);

		if (isNaN(taxableAmount) || taxableAmount < 0) {
			taxableAmount = 0;
		}

		if (newAdvances > consumerServiceRequest.ConsumerAmount) {
			Swal.fire({
				title: "",
				text:
					localString("Advance cannot be greater than") +
					" (" +
					consumerServiceRequest.CurrencySymbol +
					")" +
					consumerServiceRequest.ConsumerAmount,
				icon: "error",
				confirmButtonText: localString("OK"),
			});
			return;
		} else if (newDiscounts + oldDiscounts > taxableAmount) {
			Swal.fire({
				title: "",
				text:
					localString("Discount cannot be greater than") +
					" (" +
					consumerServiceRequest.CurrencySymbol +
					")" +
					taxableAmount,
				icon: "error",
				confirmButtonText: localString("OK"),
			});
			return;
		}

		let data = {
			updatedCharges: copyChargesArray,
			ConsumerServiceRequestID:
				consumerServiceRequest.ConsumerServiceRequestID,
			ServiceTypeID: consumerServiceRequest.ServiceTypeID,
			PartnerServiceLocationID:
				consumerServiceRequest.PartnerServiceLocationID,
		};

		setShowLoader(true)
		apiCall({
			url: "addProvisionalCharges",
			data: data,
			callBack: (result) => {
				setShowLoader(false)
				if (result.success) {
					setChargesArray([]);
					showHideSaveButton([]);
					getRequestById();
					getProvisionalCharges();
					
				} else {
					Swal.fire({
						title: "",
						text: localString(displayError(result)),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	const getPartsList = () => {
		let data = {
			ConsumerServiceRequestID:
				consumerServiceRequest.ConsumerServiceRequestID,
			ConsumerProductID: consumerServiceRequest.ConsumerProductID,
			// OrganizationOutletLocationID: self.OrganizationOutletLocationID, // check this variable
			PartnerServiceLocationID:
				consumerServiceRequest.PartnerServiceLocationID,
		};
		let url = "getParts";

		if (accessFn("GSX_FLOW")) {
			url = "getPartsForGSX";
		}

		setShowLoader(true)
		apiCall({
			url: url,
			data: data,
			callBack: (result) => {
				setShowLoader(false)
				if (result && result.success) {
					// setPartsList(result.data);
					setPartsListCalled(true);
					if (result.data.length > 0) {
						let partListArr = result.data && result.data.map(v=>({...v,value: v.Name + " " + v.PartCode}));
						initPartsDropDown(partListArr);
						/*if ((!self.compGroupList || self.compGroupList.length < 1) && self.accessFn('GSX_FLOW')) {
						self.getCompGroupList();
					  }
					  if ((!self.compModList || self.compModList.length < 1) && self.accessFn('GSX_FLOW')) {
						self.getCompModList();
					  }*/
						if (
							(!pricingOptionList ||
								pricingOptionList.length < 1) &&
							accessFn("GSX_FLOW")
						) {
							getPricingOptionList();
						}
						/*
					  if ((!self.partCoverageOptionList || self.partCoverageOptionList.length < 1) && self.accessFn('GSX_FLOW') && consumerServiceRequest.gsxRepairFlags && consumerServiceRequest.gsxRepairFlags.repairTypeId==1) {
						self.getMailinCoverageList();
					  }
	
					  if ((!self.selectedSymptom || self.selectedSymptom.length < 1) && self.accessFn('GSX_FLOW')) {
						self.getSymptomCodes();
					  }*/
					} else {
						Swal.fire({
							title: "",
							text: localString(
								"No parts found for this product."
							),
							icon: "error",
							confirmButtonText: localString("OK"),
						});
					}
				} else {
					Swal.fire({
						title: "",
						text: localString(displayError(result)),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	const showHideSaveButton = (_chargesArray) => {
		if (_chargesArray.length > 0) {
			setShowsavebutton(true);
		} else {
			setShowsavebutton(false);
		}
	};

	const showCharge = (item) => {
		// check this commented code later
		// if(e.repairTypeId){
		// 	consumerServiceRequest.gsxRepairFlags.repairTypeId = e.repairTypeId;
		// 	//parent.getPartsList();
		// }
		if (item.Type == "Part") {
			setModal({ showDiscountModal: false, showAddPartsModal: true });
		} else if (item.Type == "Discount") {
			let isServiceChargeAdded = _.find(provisionalCharges.charges, {
				Type: "ServiceCharge",
			});
			let isOtherChargeAdded = _.find(provisionalCharges.charges, {
				Type: "Other",
			});

			if (
				(partsList.length > 0 ||
					isServiceChargeAdded ||
					isOtherChargeAdded) &&
				[SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1
			) {
				setModal({ showDiscountModal: true, showAddPartsModal: false });
			} else if (
				[SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) == -1
			) {
				let clonedObj = _.cloneDeep(item);

				setChargesArray((prevData) => {
					let newChargesArray = [...prevData, clonedObj];
					showHideSaveButton(newChargesArray);
					return newChargesArray;
				});
			}
		} else if (item.Type == "ServiceCharge") {
			let isServiceChargeAdded = _.find(consumerServiceRequest.charges, {
				Type: "ServiceCharge",
			});
			setChargesArray((prevData) => {
				let newChargesArray = [...prevData, isServiceChargeAdded];
				showHideSaveButton(newChargesArray);
				return newChargesArray;
			});
		} else {
			let clonedObj = _.cloneDeep(item);
			setChargesArray((prevData) => {
				let newChargesArray = [...prevData, clonedObj];
				showHideSaveButton(newChargesArray);
				return newChargesArray;
			});
		}
	};

	const removeCharge = (idx) => {
		setChargesArray((prevData) => {
			let filteredChargesArray = prevData.filter(
				(item, id) => id !== idx
			);

			showHideSaveButton(filteredChargesArray);

			return filteredChargesArray;
		});
	};

	const initPartsDropDown = (partslist) => {
		let finishedGoodID =
			consumerServiceRequest.consumerProduct.FinishedGoodID;
		if (!accessFn("GSX_FLOW")) {
			let fullBoxUnit;

			if (allParts) {
				fullBoxUnit = allParts?.productFinishedGoodMappings.find(
					(o) => {
						return o.PartID == finishedGoodID;
					}
				);
			}

			let isFullBoxAlreadyAddedInPartArray = partsArray.find((o) => {
				return o.PartID == finishedGoodID;
			});
			let isFullBoxAlreadyAddedInPartList = partslist.find((o) => {
				return o.PartID == finishedGoodID;
			});

			if (
				!isFullBoxAlreadyAddedInPartList &&
				!isFullBoxAlreadyAddedInPartArray &&
				fullBoxUnit &&
				fullBoxUnit.part
			) {
				fullBoxUnit.part.Type = "Part";
				fullBoxUnit.part.Charge = fullBoxUnit.Charge;
				fullBoxUnit.part.TaxSlab = fullBoxUnit.TaxSlab;
				fullBoxUnit.part.TaxPercentage = fullBoxUnit.TaxPercentage;
				fullBoxUnit.part.IsTaxable = fullBoxUnit.IsTaxable;
				if (
					fullBoxUnit.part &&
					fullBoxUnit.part.Serialized &&
					consumerServiceRequest &&
					[SERVICETYPE.PICK_UP,SERVICETYPE.CARRY_IN].indexOf(consumerServiceRequest.ServiceTypeID) > -1
				) {
					// pass when service type is pickup or carryin and full box is serialised
				} else {
					partslist.unshift(fullBoxUnit.part);
				}
			}
		}

		if (consumerServiceRequest.ServiceTypeID == SERVICETYPE.INTERNAL_JOB) {
			partslist = _.remove(partslist, function (o) {
				return o.FinishedGood == 0 || o.PartID != finishedGoodID;
			});
		}

		setPartsList(partslist);
	};

	const addNewPartToPartsArray = () => {
		if (
			!newPartName ||
			!newPartRemark ||
			!newPartPrice ||
			!newPartHSN ||
			!newPartTax
		) {
			if (
				!consumerServiceRequest.IsWarrantyApplicable &&
				(!newPartWarranty || newPartWarranty == "")
			) {
				Swal.fire({
					title: "",
					text: localString("Please enter values in all the fields"),
					icon: "error",
					confirmButtonText: localString("OK"),
				});
			} else {
				Swal.fire({
					title: "",
					text: localString("Please enter values in all the fields"),
				    icon: "error",
					confirmButtonText: localString("OK"),
				});
			}
		} else {
			let partWarranty = "In warranty";
			if (!consumerServiceRequest.IsWarrantyApplicable) {
				partWarranty = newPartWarranty == "In warranty" ? true : false;
			}
			// copyPartItem.Charge = partWarranty ? 0 : self.newPartPrice.value ;
			// copyPartItem.Price = partWarranty ? 0 : self.newPartPrice.value ;
			const copyPartItem = _.cloneDeep(PartItem);
			copyPartItem.Charge = newPartPrice;
			copyPartItem.Price = newPartHSN;
			copyPartItem.Remarks = newPartRemark;
			copyPartItem.IsDeducted = false;
			copyPartItem.InWarranty = partWarranty;
			copyPartItem.ChargeType = "Credit";
			copyPartItem.IsTaxable = true;
			copyPartItem.TaxSlab = newPartTax;
			copyPartItem.ChargeCodeType = "HSN";
			copyPartItem.ChargeCode = newPartHSN;
			copyPartItem.Type = "Part";
			copyPartItem.Name = newPartName;
			copyPartItem.pricingOption = pricingOption;

			setPartsArray((prevData) => [...prevData, copyPartItem]);
			setNewPartName("");
			setNewPartRemark("");
			setNewPartPrice("");
			setNewPartHSN("");
			setNewPartWarranty("");
			setPricingOption("");
			setNewPartTax("");
			setNewPartsDropDown("");
			setPrice("");
			setAvailableQuantity("");
			setAddNewPart(false);
			setDisableAddBtn(false);
			setPartItem(undefined);
		}
	};

	const cancelAddNewPartToPartsArray = () => {
		setNewPartName("");
		setNewPartRemark("");
		setNewPartPrice("");
		setNewPartHSN("");
		setNewPartWarranty("");
		setPricingOption("");
		setNewPartTax("");
		setCoverageOption("");
		setNewPartsDropDown("");
		setPrice("");
		setAvailableQuantity("");
		setAddNewPart(false);
		setDisableAddBtn(false);
		setPartItem(undefined);
	};

	const removeAddedPart = (e, item, index) => {
		e.preventDefault();

		let copyPartsArray = _.cloneDeep(partsArray);
		// copyPartsArray.splice(copyPartsArray.indexOf(item), 1);
		copyPartsArray.splice(index, 1);
		setPartsArray(copyPartsArray);

		if (item.PartID == consumerServiceRequest.consumerProduct.FinishedGoodID) {
			initPartsDropDown(partsList);
		}
	};

	const selectFlags = (e) => {
		if (e.target.name == "abused") {
			if (e.target.value == 1) {
				setIsAbused(true);
				changePrice({ isAbused: true });
			} else {
				setIsAbused(false);
				changePrice({ isAbused: false });
			}
		} else if (e.target.name == "returnable") {
			if (e.target.value == 1) {
				setIsReturnable(true);
				changePrice({ isReturnable: true });
			} else {
				setIsReturnable(false);
				changePrice({ isReturnable: false });
			}
		} else if (e.target.name == "covered") {
			if (e.target.value == 1) {
				setIsCovered(true);
			} else {
				setIsCovered(false);
			}
		}
	};

	const getPricingOptionList = () => {
		setShowLoader(true)
		apiCall({
			url: "getPricingOptions",
			method: "post",
			data: {},
			callBack: (result) => {
				setShowLoader(false)
				if (result.success) {
					setPricingOptionList(result.data);
				} else {
					Swal.fire({
						title: "",
						text: localString(displayError(result)),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	const getAvailableQuantity = (part) => {
		let data = {
			PartnerServiceLocationID:
				consumerServiceRequest.PartnerServiceLocationID,
			part: part,
		};
		if (consumerServiceRequest.consumerProduct) {
			data.BrandID = consumerServiceRequest.consumerProduct.BrandID;
		}

		setShowLoader(true)
		apiCall({
			url: "getAvailableQuantity",
			data: data,
			callBack: (result) => {
				setShowLoader(false)
				if (result.success) {
					setAvailableQuantity(result.data.AvailableQuantity);
				} else {
					Swal.fire({
						title: "",
						text: localString(displayError(result)),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	const addToPartsValidation = () => {
		let clonePartslist = _.cloneDeep(partsList);
		let ispresent = false;
		for (let i = clonePartslist.length - 1; i >= 0; i--) {
			if (
				(
					clonePartslist[i].Name.toLowerCase() +
					" " +
					clonePartslist[i].PartCode.toLowerCase()
				).indexOf(newPartsDropDown.toLowerCase()) > -1 ||
				newPartsDropDown === ""
			) {
				ispresent = true;
				break;
			}
		}

		if (!PartItem || PartItem == {} || !ispresent) {
			Swal.fire({
				title: "",
				text: localString("Please add a part from dropdown."),
			    icon: "error",
				confirmButtonText: localString("OK"),
			});
			return;
		}

		for (let iCount = 0; iCount < partsArray.length; iCount++) {
			if (partsArray[iCount].part.PartCode == PartItem.PartCode) {
				Swal.fire({
					title: "",
					text: localString("Part is already added"),
					icon: "error",
					confirmButtonText: localString("OK"),
				});
				return;
			}
		}

		addToPartsArray();
	};

	const addToPartsArray = () => {
		let copyPartItem = _.cloneDeep(PartItem);
		let partWarranty = true;

		if (
			consumerServiceRequest.IsUnderWarranty &&
			consumerServiceRequest.IsWarrantyApplicable
		) {
			partWarranty = true;
		} else {
			partWarranty = partwarranty == "In warranty" ? true : false;
		}

		if (accessFn("GSX_FLOW")) {
			copyPartItem.displayPrice = price;
		} else {
			if (partWarranty) {
				copyPartItem.Charge = 0;
				copyPartItem.Price = 0;
			}
		}

		let partObject = {};
		let dummyPricingOptionobj = {};

		partObject.part = copyPartItem;
		partObject.abused = isAbused;
		partObject.returnableDamage = isReturnable;
		partObject.coveredByACPlus = isCovered;
		// partObject.comptiaGroup = CompGroupItem;
		// partObject.comptiaCode = CompCodeItem;
		// partObject.comptiaModifier = CompModItem;
		// partObject.issueCode = selectedIssue;
		// partObject.componentCode = selectedSymptom;

		if (!pricingOptionobj) {
			dummyPricingOptionobj.pricingOptionCode = "";
			dummyPricingOptionobj.pricingOptionDescription = "None";
		} else {
			dummyPricingOptionobj = _.cloneDeep(pricingOptionobj);
		}

		partObject.pricingOption = dummyPricingOptionobj;
		partObject.serialNumber = partSerial;
		partObject.isSerialized = isSerialized;

		// This is to call update charges internally from updateCsrDiagnosis
		partObject.IsDeducted = false;
		partObject.InWarranty = partWarranty;
		partObject.ChargeType = "Credit";

		if (coverageOption) {
			partObject.partCoverageOption = coverageOptionObj;
		}

		setPartsArray((prevData) => [...prevData, partObject]);
		setPartItem(undefined);
		setPricingOptionobj(undefined);
		setPartname("");
		setIsAbused(false);
		setIsReturnable(false);
		setIsCovered(false);
		setPrice("");
		setNewPartsDropDown("");
		setPartwarranty("");

		// self.CompGroupItem = undefined;
		// self.CompCodeItem = undefined;
		// self.CompModItem = undefined;
		// self.CompGroupName = "";
		// self.CompCodeName = "";
		// self.CompModName = "";
		// $("#comp_group").val("");
		// $("#comp_code").val("");
		// $("#comp_mod").val("");
		// $("#partsymptomcode").val("");
		// $("#partissuecode").val("");

		setPricingOption("");
		setPartSerial("");
		setCoverageOption("");
		setPartRemark("");
	};

	const addWorkshopParts = () => {
		if (!PartItem || PartItem == {}) {
			Swal.fire({
				title: "",
				text: localString("Please add a part from dropdown."),
				icon: "error",
				confirmButtonText: localString("OK"),
			});
			return;
		}

		let copyPartItem = PartItem;

		for (let iCount = 0; iCount < partsArray.length; iCount++) {
			let validPart = false;
			if (
				[6, 7, 8, 9].indexOf(
					partsArray[iCount].PartTransactionStatusID
				) == -1
			) {
				validPart = true;
			}
			if (
				partsArray[iCount].PartCode == copyPartItem.PartCode &&
				validPart
			) {
				Swal.fire({
					title: "",
					text: localString("Part is already added"),
					icon: "error",
					confirmButtonText: localString("OK"),
				});
				return;
			}

			if (
				partsArray[iCount].PartID ==
					consumerServiceRequest.consumerProduct.FinishedGoodID &&
				validPart
			) {
				if (copyPartItem.partSubSegment.Name == "Accessory") {
				} else {
					Swal.fire({
						title: "",
						text: localString("Full box unit is already added."),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
					return;
				}
			}
		}

		let validAccessory = false;
		if (
			copyPartItem.PartID ==
				consumerServiceRequest.consumerProduct.FinishedGoodID &&
			partsArray.length > 0
		) {
			_.each(partsArray, function (part, index) {
				if (partsArray[index].partSubSegment.Name == "Accessory") {
					validAccessory = true;
				} else {
					validAccessory = false;
				}
			});

			if (validAccessory) {
			} else {
				Swal.fire({
					title: "",
					text: localString(
						"Full box unit cannot be added if another part is already added"
					),
					icon: "error",
					confirmButtonText: localString("OK"),
				});
				return;
			}
		}

		let validParts = false;
		if (partsRequested && partsRequested.length > 0) {
			for (let jCount = 0; jCount < partsRequested.length; jCount++) {
				if (
					[6, 7, 8, 9].indexOf(
						partsRequested[jCount].PartTransactionStatusID
					) == -1
				) {
					validParts = true;
					if (
						partsRequested[jCount].PartID ==
						consumerServiceRequest.consumerProduct.FinishedGoodID
					) {
						Swal.fire({
							title: "",
							text: localString(
								"Full box unit is already added."
							),
							icon: "error",
							confirmButtonText: localString("OK"),
						});
						return;
					}
					break;
				}
			}

			if (
				copyPartItem.PartID ==
					consumerServiceRequest.consumerProduct.FinishedGoodID &&
				validParts
			) {
				Swal.fire({
					title: "",
					text: localString(
						"Full box unit cannot be added if another part is already added"
					),
					icon: "error",
					confirmButtonText: localString("OK"),
				});
				return;
			}
		}

		let partWarranty = true;
		let partwarrantyCopy = partwarranty;

		if (consumerServiceRequest.ServiceTypeID == 39) {
			partwarrantyCopy = "Out of warranty";
			setPartwarranty("Out of warranty");
		}

		if (
			consumerServiceRequest.IsUnderWarranty &&
			consumerServiceRequest.IsWarrantyApplicable
		) {
			partWarranty = true;
		} else {
			partWarranty = partwarrantyCopy == "In warranty" ? true : false;
		}

		copyPartItem.Remarks = partRemark ? partRemark : "";
		copyPartItem.IsDeducted = false;
		copyPartItem.AvailableQuantity = availableQuantity;
		copyPartItem.InWarranty = partWarranty;
		copyPartItem.ChargeType = "Credit";

		if (consumerServiceRequest.ServiceTypeID == 39) {
			copyPartItem.taxIncluded = true;
			copyPartItem.IsTaxable = false;
		}

		setPartsArray((prevData) => [...prevData, copyPartItem]);

		if (
			copyPartItem.PartID ==
			consumerServiceRequest.consumerProduct.FinishedGoodID
		) {
			let filteredPartsList = _.filter(partsList, function (part) {
				return (
					part.partSubSegment &&
					part.partSubSegment.Name == "Accessory"
				);
			});

			initPartsDropDown(filteredPartsList);
		}

		setPartItem(undefined);
		setAvailableQuantity("");
		setPartname("");
		setNewPartsDropDown("");
		setPartRemark("");
		if (typeOfWarranty && typeOfWarranty.length == 1) {
			setPartwarranty(typeOfWarranty[0]);
		} else {
			setPartwarranty("");
		}
	};

	const saveParts = (e) => {
		e.preventDefault();
		setAvailableQuantity("");
		if (partsArray.length < 0) {
			setModal({ showDiscountModal: false, showAddPartsModal: false });
			return;
		}

		let data = {
			updatedCharges: partsArray,
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			ServiceTypeID: consumerServiceRequest.ServiceTypeID,
			PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID,
		};
		updateChargesCall(data, setModal({ showDiscountModal: false, showAddPartsModal: false }));
		// setModal({ showDiscountModal: false, showAddPartsModal: false });
	};

	const sendEmailToCustomer = () => {
		let data = {
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
		};

		setShowLoader(true)
		apiCall({
			url: "sendProvisionalEstimateToCustomer",
			data: data,
			callBack: (result) => {
				setShowLoader(false)
				if (result.success) {
					Swal.fire({
						title: "",
						text: result.msg,
						icon: "success",
						confirmButtonText: localString("OK"),
					});
				} else {
					Swal.fire({
						title: "",
						text: localString(displayError(result)),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	

	return (
		<>
		{showLoader && ReactDOM.createPortal(
			<Loader />,
			document.getElementById("loader-root")
		)}
		<div className="">
			<div className="">
				<PageContentWrapper 
					title={localString("Provisional Estimate")}
					bodyClassName="panel-body-no-padding" 
				>
				<>
					{(!consumerServiceRequest.consumerProduct.FinishedGoodID ||
						consumerServiceRequest.consumerProduct
							.isManuallyUpdatedFinishedGoodID) && (
						<div className="marginy20">
							<label for="newFinishedGood">
								{localString("Select SKU")}
							</label>
							<Dropdown
								id="newFinishedGood"
								value={newFinishedGood}
								options={newFinishedGoodOptions}
								handleOnChange={handleNewFinishedGood}
								showTooltip={true}
								filterBy="value"
								searchText={localString("Select SKU")}
								showDownArrow={false}
								handleOnSearch={(e) =>
									newFinishedGoodOptions.filter((obj) =>
										obj.value
											.toLowerCase()
											.includes(
												e.target.value.toLowerCase()
											)
									)
								}
								noOptionsText="Cannot find the searched option"
								placeholder={localString("Select SKU")}
							/>
						</div>
					)}

					<div className='estimationContent padding15 container-fluid'>

						{/* ****** Covered Value ****** */}
						<div className='boxShadowCard mb-15'>
							<div className="">
								<div className='row'>
									<div className='col-xs-8 bold'>
										{localString('Total Provisional Estimate')}
									</div>
									<div className='col-xs-4 bold text-right'>
										<span>
											<span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>
											{provisionalCharges?.totalAmountWithTax || 0}
										</span>
									</div>
								</div>
								{depreciatedValue && depreciatedValue.sumInsured && <div className='row'>
									<div className='col-xs-8 bold'>
										{localString('Covered Value')}
									</div>
									<div className='col-xs-4 bold text-right'>
										<span><span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>{depreciatedValue.sumInsured}</span>
									</div>
								</div> }
							</div>
						</div>

						{((provisionalCharges?.charges?.length > 0) || (chargesArray && chargesArray.length > 0)) && <div className='boxShadowCard mb-15'>
							{provisionalCharges?.charges?.length > 0 && provisionalCharges.charges.map((charge) => (
								["Advance","Advance payment","Mandatory deductible","Mandatorydeductible","Admin fees","Claim amount","Adminfees"].indexOf(charge.Type) < 0 && (
									<div className="partRow">
										<div className="row">
											<div className="col-xs-8">
												{["Other", "Discount"].indexOf(charge.Type) > -1 && (
													<div className="charges-panel-content bold">
														{" "}
														{["Other"].indexOf(charge.Type) > -1
															? localString(charge.Name)
															: localString(charge.Remarks)}
														{showestimationbuttons && <>
															<span className="glyphicon glyphicon-minus-sign editDeleteIcon" onClick={() =>removeAddedCharge(charge)}></span>
														</>}
													</div>
												)}
												{!(["Other", "Discount"].indexOf(charge.Type) > -1) && (
													<div className="charges-panel-content bold">
														{charge.Name}
														{["Part"].indexOf(charge.Type) > -1 &&
															charge.Remarks && (
																<span>
																	{" "}
																	-{" "}
																	{charge.Remarks}
																</span>
															)}
														{["Service Charge","ServiceCharge","Part"].indexOf(charge.Type) > -1 &&
															charge.ChargeCode && (
																<span>
																	{" "}
																	- (
																	{charge.ChargeCode}
																	)
																</span>
															)}
														{showestimationbuttons && <>
															<span className="glyphicon glyphicon-minus-sign editDeleteIcon" onClick={() =>removeAddedCharge(charge)}></span>
														</>}
													</div>
												)}
											</div>

											<div className="col-xs-4 text-right bold">
												{!(["WarrantyConcession"].indexOf(charge.Type) > -1) && (
													<div className="charges-panel-content text-right">
														<span>
															<span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>
															{charge.TaxArray && charge.TaxArray.length > 0
																? charge.BaseCharge
																: charge.Charge}
														</span>
														
													</div>
												)}
												{["WarrantyConcession"].indexOf(charge.Type) > -1 && (
													<div className="charges-panel-content text-right">
														<span>
															<span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>
															{" "}
															-{" "}
															{charge.TaxArray && charge.TaxArray.length > 0
																? charge.BaseCharge
																: charge.Charge}
														</span>
														
													</div>
												)}
											</div>
										</div>

										{["Discount"].indexOf(charge.Type) < 0 && charge.TaxArray && charge.TaxArray.map((tax) => (
											<>
												{tax.TaxSlab > 0 && <div className='row'>
													<div className="col-xs-8">
														<div className="charges-panel-content">
															{tax.TaxDisplay
																? tax.TaxDisplay
																: tax.TaxType}
														</div>
													</div>
													<div className="col-xs-4 text-right">
														<div className="charges-panel-content text-right">
															<span>
																<span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>
																{tax.BaseChargeTax}
															</span>
														</div>
													</div>
												</div>
												}
											</>
										))}

										{charge.MRP && charge.MRP != 0 ? (
											<div className="row">
												<div className="col-xs-8">
													<div className="charges-panel-content bold">
														{localString("Price")}
													</div>
												</div>
												<div className="col-xs-4 text-right">
													<div className="charges-panel-content text-right bold">
														<span>
															<span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>
															{charge.MRP}
														</span>
													</div>
												</div>
											</div>
										) : ''}

										{charge.Discount != 0 && charge.Discount && (
											<div className="row">
												<div className="col-xs-8">
													<div className="charges-panel-content bold">
														{localString("Discount")}
													</div>
												</div>
												<div className="col-xs-4 text-right">
													<div className="charges-panel-content text-right bold">
														<span>
															<span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>
															-{charge.Discount}
														</span>
													</div>
												</div>
											</div>
										)}
									</div>
								)
							))}

							{provisionalCharges?.charges?.length > 0 && provisionalCharges.charges.map((charge) => (
								["Advance","Advance payment","Mandatory deductible","Mandatorydeductible","Admin fees","Claim amount","Adminfees"].indexOf(charge.Type) > -1 && (
									<div className="partRow">
										<div className="row">
											<div className="col-xs-8">
												<div className="charges-panel-content bold">
													{[ "Mandatory deductible", "Mandatorydeductible", "Admin fees", "Claim amount", "Adminfees"].indexOf(charge.Type) > -1
														? charge.Name
														: charge.Remarks}
													{!((charge.Type == "Advance payment") && (charge.IsAdvancePaid == 1)) && allowChargeDeletion(charge.Type) && <>
														<span className="glyphicon glyphicon-minus-sign editDeleteIcon" onClick={() =>removeAddedCharge(charge)}></span>
													</>}
												</div>
											</div>

											<div className="col-xs-4 text-right bold">
												<div className="charges-panel-content text-right">
													<span>
														<span className="currencySymbol" dangerouslySetInnerHTML={{__html: currencySymbol }}></span>
														{charge.Charge}
													</span>
												</div>
											</div>
										</div>
									</div>
								)
							))}

							{chargesArray && chargesArray.map((ChargeType, idx) => (
								<div
									key={ChargeType.Type + idx}
									style={{ padding: '20px 0px' }}
								>
									<div className="row marginb10">
										{["Other", "ServiceCharge"].indexOf(ChargeType.Type) > -1 && (
											<div className="col-xs-4">
												<Input
													id="trackingNo"
													name={ChargeType.Name + idx}
													placeholder={ChargeType.Placeholder}
													inputType="text"
													isDisabled={(ChargeType.Type == "ServiceCharge") ? true : false}
													value={ChargeType.Name || ""}
													handleOnChange={(e) => {
														const copyItem = _.cloneDeep(ChargeType);
														copyItem.Name = e.target.value;
														let copyChargesArray = _.cloneDeep(chargesArray);
														copyChargesArray[idx] = copyItem;
														setChargesArray(copyChargesArray);
													}}
												/>
											</div>
										)}

										{["Part", "ServiceCharge"].indexOf(ChargeType.Type) < 0 && (
											<div className={`${(ChargeType.Type == 'Other') ? 'col-xs-3' : 'col-xs-4'}`}>
												<Input
													placeholder={(ChargeType.Type == "Other") ? 
														localString("Reason") : 
														localString("Remarks")}
													inputType="text"
													otherProps={{
														required: (ChargeType.Type == "Other") ? "required" : "",
													}}
													value={ChargeType.Remarks || ""}
													handleOnChange={(e) => {
														const copyItem = _.cloneDeep(ChargeType);
														copyItem.Remarks = e.target.value;
														let copyChargesArray = _.cloneDeep(chargesArray);
														copyChargesArray[idx] = copyItem;
														setChargesArray(copyChargesArray);
													}}
												/>
											</div>
										)}

										{["Part"].indexOf(ChargeType.Type) < 0 && (
											<div className="col-xs-3">
												<Input
													placeholder={localString("Amount")}
													inputType="text"
													isDisabled={(ChargeType.Type == "ServiceCharge") ? true : false}
													otherProps={{
														required: true,
														min: "0",
													}}
													value={ChargeType.Charge || ""}
													handleOnChange={(e) => {
														const copyItem = _.cloneDeep(ChargeType);
														copyItem.Charge = e.target.value;
														let copyChargesArray = _.cloneDeep(chargesArray);
														copyChargesArray[idx] = copyItem;
														setChargesArray(copyChargesArray);
													}}
												/>
											</div>
										)}

										{["Part"].indexOf(ChargeType.Type) < 0 && (
											<div className="col-xs-2">
												<span
													className="glyphicon glyphicon-minus-sign editDeleteIcon"
													onClick={() => removeCharge(idx)}
													style={{ top: '10px' }}
												></span>
											</div>
										)}
									</div>
								</div>
							))}

							{ showsavebutton && <div className="text-right">
								<Button
									className="btn squareBtnGhost text-right" 
									type="button"
									data-type="submit"
									handleOnClick={save}
								>
									{localString("Save")}
								</Button>
							</div> }

						</div> }

						{/* {chargeType.length > 0 && showestimationbuttons && (
							<div className="row">
								<div className="col-sm-12">
									<p className="charges-panel-subheader text-capitalize remove-margin">
										{localString("ADD CHARGES")}
									</p>
								</div>
							</div>
						)} */}

						{showestimationbuttons && (
							<div className="pb-15">
								{chargeType &&
									chargeType.map((item) => (
										// <div className="">
											<Button
												type="button"
												// classes="button-primary w-full custom-charges-button"
												className="btn squareBtnGhost" 
												handleOnClick={() => showCharge(item)}
												isDisabled={
													(item.Type == "ServiceCharge" &&
														serviceChargeAdded) ||
													(item.Type == "Discount" &&
														(consumerServiceRequest.IsWarrantyApplicable ||
															!isProvisionalChargesAdded)) ||
													(consumerServiceRequest.ServiceTypeID ==
														SERVICETYPE.INTERNAL_JOB &&
														(item.Type ==
															"Advance payment" ||
															item.Type == "Other" ||
															item.Type ==
																"Discount"))
												}
											>
												<span className="glyphicon glyphicon-plus addBtn"></span>
												{localString(item.Display)}
											</Button>
										// </div>
									))}
							</div>
						)}

						<div className="text-right">
							{showestimationbuttons && !showsavebutton && isProvisionalChargesAdded && (							
								<Button
									type="button"
									name="EstimationApproval"
									className="btn squareBtnGhost text-right" 
									handleOnClick={sendEmailToCustomer}
								>
									{localString("Send Email to Customer")}
								</Button>
							)}
						</div>
						
						{modal.showDiscountModal && (
							<DiscountModal
								setModal={setModal}
								showModal={modal.showDiscountModal}
								closeModal={() =>
									setModal({
										showDiscountModal: false,
										showAddPartsModal: false,
									})
								}
								localString={localString}
								currencySymbol={currencySymbol}
								updateChargesCall={updateChargesCall}
								provisionalCharges={provisionalCharges}
								setProvisionalCharges={setProvisionalCharges}
								consumerServiceRequest={consumerServiceRequest}
							/>
						)}

						{modal.showAddPartsModal && (
							<AddPartsModal
								showModal={modal.showAddPartsModal}
								closeModal={() => { 
									setChargesArray([]);
									// setPartsList({});
									setPartsArray([]);
									setPartNameTypeAhead("");
									setPartItem(undefined);
									setAddNewPart(false);
									setDisableAddBtn(false);
									setPartwarranty("");
									setNewPartsDropDown("");
									setModal({
										showDiscountModal: false,
										showAddPartsModal: false,
									})
								}}
								localString={localString}
								consumerServiceRequest={consumerServiceRequest}
								accessFn={accessFn}
								getPartsList={getPartsList}
								partsListCalled={partsListCalled}
								addNewPart={addNewPart}
								addNewPartToPartsArray={addNewPartToPartsArray}
								cancelAddNewPartToPartsArray={cancelAddNewPartToPartsArray}
								removeAddedPart={removeAddedPart}
								selectedPart={selectedPart}
								newPartsDropDownOptions={newPartsDropDownOptions}
								handleNewPartsDropDown={handleNewPartsDropDown}
								newPartWarranty={newPartWarranty}
								newPartWarrantyAndPart_warrantyOptions={
									newPartWarrantyAndPart_warrantyOptions
								}
								handleNewPartWarranty={handleNewPartWarranty}
								newPartTax={newPartTax}
								newPartTaxOptions={newPartTaxOptions}
								handleNewPartTaxOptions={handleNewPartTaxOptions}
								handlePart_warranty={handlePart_warranty}
								partwarranty={partwarranty}
								selectFlags={selectFlags}
								pricingOption={pricingOption}
								pricingOptionOptions={pricingOptionOptions}
								handlePricingOption={handlePricingOption}
								partRemark={partRemark}
								setPartRemark={setPartRemark}
								partsArray={partsArray}
								availableQuantity={availableQuantity}
								newPartName={newPartName}
								setNewPartName={setNewPartName}
								newPartRemark={newPartRemark}
								setNewPartRemark={setNewPartRemark}
								newPartHSN={newPartHSN}
								setNewPartHSN={setNewPartHSN}
								newPartPrice={newPartPrice}
								setNewPartPrice={setNewPartPrice}
								newPartsDropDown={newPartsDropDown}
								isAbused={isAbused}
								isReturnable={isReturnable}
								price={price}
								addToPartsValidation={addToPartsValidation}
								addWorkshopParts={addWorkshopParts}
								saveParts={saveParts}
								partsList = {partsList}
							/>
						)}
					</div>
				</>
				</PageContentWrapper>
			</div>
		</div>
		</>
	);
};

export default ProvisionalEstimate;
