import React,{useRef,useEffect} from 'react';
import {Button,Input} from 'sfy-react';
import './FileUpload.scss'

const FileUpload = (props) => {
	const {localString,selectedFile,publicUrl,sendFileCB,showUploadButton,acceptFileType,uplodedFile} = props;

	const fileRef = useRef(null);
	const imageClick = () =>{
		fileRef.current.click();
	}

	useEffect(() => {
		if(uplodedFile == ""){
			fileRef.current.value = "";
		}
	}, [uplodedFile])
	return (
		<>
			<div class="uploadSection margin-zero">
				<div className="csvUpload btn">
					<div className="upload">
						<Input
							reference={fileRef}
							handleOnChange={selectedFile}
							inputType="file"
							otherProps={{ accept: acceptFileType }}
						/>
					</div>
					<span><img src = {publicUrl +"/imgs/select-file.png"} onClick={imageClick}/></span>
				</div>
			</div>
			{
				showUploadButton && 
				<div className="sendSection margin-zero">
					<span>{uplodedFile.fileName}</span><Button handleOnClick={sendFileCB} text={localString("Upload")} type='button' classes='button-ghost file-btn' />
				</div>
			}
		</>
	)
}

export default FileUpload
