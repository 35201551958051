import React, { useEffect, useState } from 'react';
import { Button, Input, Loader } from 'sfy-react';
import Datetime from 'react-datetime';
import { max, maxBy } from 'lodash';
import Swal from 'sweetalert2';
import moment from 'moment';

import { displayError, getDateFormat, getLocaleDateString, regexTest } from '../../../Utility/Utils';
import './ConsumerProducts.scss'
import SelectBrand from './SelectBrand';
import SelectSubCategory from './SelectSubCategory';
import SelectProduct from './SelectProduct';


export default function ConsumerProducts(props) {
	const { 
		accessFn, localString, apiCall, publicUrl, loader, setLoader, imeiOrSerialNo, setShowModeType, setShowClaimForm,
		setImeiOrSerialNo, setShowDeviceAttributes, editImei, setEditImei, loadConsumerProducts, inRepairConsumerProducts,
		deviceWarningMsg, consumerProductArray, filteredConsumerProducts, setFilteredConsumerProducts, setScreenToViewDiv,
		defaultCountryLocale, consumerId, setConsumerId, consumerProduct, setConsumerProduct, consumerObj,
		selectedConsumerProduct, setSelectedConsumerProduct, setCallComplianceWarning, checkToEnterAttributes,
		setShowIssuesSelection, isNonSerializedProduct, setIsNonSerializedProduct, isDeviceInWarranty, setIsDeviceInWarranty, 
		warrantyTillDate, setWarrantyTillDate, isWarrantyApplicable, setIsWarrantyApplicable, isDeviceWarrantyApplicable,
		setIsDeviceWarrantyApplicable, callConsumerProductClick, setCallConsumerProductClick, showAddEditSerialNo, setShowAddEditSerialNo,
		showAddProduct, setShowAddProduct, hideSection, showInspectionGuide, setShowInspectionGuide, showCarryInTypeSelection,
		showSelectedProduct, setShowSelectedProduct, showConsumerProducts, setWarrantyTillDateCopy, setDeviceWarningMsg
	} = props;
	const qFilter = JSON.parse(props.qFilter);
	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1); 
	const [cpSearchVal, setCpSearchVal] = useState('');
	const [isAppleBrandID, setIsAppleBrandID] = useState(false)
	const [brand, setBrand] = useState({});
	const [subCat, setSubCat] = useState({});
	const [product, setProduct] = useState({});

	const [imeiOrSerialNoMaxlength, setImeiOrSerialNoMaxlength] = useState(20);

	const [displaySubCat, setDisplaySubCat] = useState(false);
	const [displayProduct, setDisplayProduct] = useState(false);
	const [displayAddProduct, setDisplayAddProduct] = useState(false);
	const [displaySerialNumberInput, setDisplaySerialNumberInput] = useState(true);	
	const [disableAddProduct, setDisableAddProduct] = useState(true);

	//validation
	const [isValidationRequired, setIsValidationRequired] = useState(false);
	const [skipValidationsForProduct, setSkipValidationsForProduct] = useState(false);
	const [currentId, setCurrentId] = useState('');
	const [warrantyTill, setWarrantyTill] = useState('');
	const [checkUniqueId, setCheckUniqueId] = useState(false);
	const [checkAlternateUniqueId, setCheckAlternateUniqueId] = useState(false);
	const [validations, setValidations] = useState([]);
	const [checkUsingApi, setCheckUsingApi] = useState(false);
	const [callApiOnChange, setCallApiOnChange] = useState(false);
	const [validationRules, setValidationRules] = useState({});
	const [allValidationRules, setAllValidationRules] = useState([]);
	const AdditionalSupportedLengthCheck = validations.length == 1 && validations[0].AdditionalSupportedLength && validations[0].AdditionalSupportedLength.length > 0;

	// const [showBTK, setShowBTK] = useState(false);
	// const [wallMount, setWallMount] = useState(false);
	const [isGsxDown, setIsGsxDown] = useState(false);
	const [isDataMasked, setIsDataMasked] = useState(false);
	const [inspectionGuideArray, setInspectionGuideArray] = useState();
	const [uniqueIDToBeChecked, setUniqueIDToBeChecked] = useState('');
	const [callAddProductVerifyApi, setCallAddProductVerifyApi] = useState(false);
	const [callExistingProductVerifyApi, setCallExistingProductVerifyApi] = useState(false);
	const [callHandleImeiChange, setCallHandleImeiChange] = useState(false);

	const ErrorCodes = [
		"GSX.WAR.001",
		"GSX.ACT.001",
		"API.PRD.001",
		"GSX.CFG.001"
	];

	const AllowedValues = [
		{
			"Type": "IMEI",
			"ExtraFilter": "Numeric",
			"Length": 15,
			"MinLengthFlag": false
		},
		{
			"Type": "SerialNumber",
			"ExtraFilter": "Alphanumeric",
			"Length": 12,
			"MinLengthFlag": false
		},
		{
			"Type": "Serial Number",
			"ExtraFilter": "Alphanumeric",
			"Length": 6,
			"MinLengthFlag": true
		}
	];

	const placehoderText = checkUniqueId && checkAlternateUniqueId ? localString('Enter IMEI / Serial Number') : checkUniqueId ? localString('Enter IMEI Number') : localString('Enter Serial Number');

	useEffect(() => {
		if(showConsumerProducts) {
			setScreenToViewDiv('ConsumerProducts');
		}
	}, [showConsumerProducts])

	useEffect(() => {
		if(displayAddProduct) {
			setScreenToViewDiv('DisplayAddProduct');
		}
	}, [displayAddProduct])

	useEffect(() => {
		if(showAddEditSerialNo) {
			setScreenToViewDiv('AddEditSerialNo');
		}
	}, [showAddEditSerialNo])

	const getInputMaxLength = () => {
		let maxLength;
        if(validations && validations.length > 1) {
            maxLength = maxBy(validations, 'Length') && maxBy(validations, 'Length').Length;
        } else if(validations && validations.length == 1) {
			let validationRule = validations[0];
            maxLength = validationRule && validationRule.MinLengthFlag ? 20 : validationRule && validationRule.Length ? validationRule.Length : 20;
        }else {
			maxLength = 20;
		}
		setImeiOrSerialNoMaxlength(maxLength);
    };

	useEffect(() => {
		getInputMaxLength();
	}, [validations])

	useEffect(() => {
		return () => {
			setConsumerProduct({});
			setSelectedConsumerProduct({});
			setImeiOrSerialNo('');
		}
	}, []);

	const getBrandImage = (S3BaseUrl, BrandBucket, BrandID, BrandName) => {
		return S3BaseUrl + '' + BrandBucket + '/' + BrandID + '-' + BrandName.toLowerCase() + '.png';
	}

	const handleCPFilter = (e) => {
		let val = e.target.value.toLowerCase();
		setCpSearchVal(e.target.value);
		if(val) {
			let filteredArr = consumerProductArray.filter(data => {
				if(data && data.product && data.product.ProductName &&  data.product.ProductName.toLowerCase().includes(val)) {
					return data;
				}
			})
			setFilteredConsumerProducts(filteredArr);
		} else{
			setFilteredConsumerProducts(consumerProductArray);
		}
	}

	useEffect(() => {
		if(callConsumerProductClick) {
			consumerProductClick(consumerProduct);
			setCallConsumerProductClick(false);
		}
	}, [callConsumerProductClick]);

	useEffect(() => {
		if(callHandleImeiChange) {
			handleImeiChange('serialNo2');
			setCallHandleImeiChange(false);
		}
	}, [callHandleImeiChange]);

	const consumerProductClick = (product) => {
		setConsumerProduct(product);
		setSelectedConsumerProduct(product);
        hideSection(['AddProduct', 'AddEditSerialNo', 'SelectedProduct', 'InspectionGuide', 'DeviceAttributes', 'ModeType', 'Plans', 'CoverageType', 'Coverage', 'ClaimForm', 'DOP', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		setEditImei(false);
		setIsGsxDown(false);
		setIsDataMasked(false);
		setIsNonSerializedProduct(product.productConfig ? product.productConfig.isSerializedProduct === false : false);
		setIsDeviceInWarranty(product.IsUnderWarranty);
		setInspectionGuideArray(product.productConfig ? product.productConfig.InspectionGuideUrl : []);
		setSelectedProductValidations(product);
		if(showCarryInTypeSelection){
            setScreenToViewDiv('carryInTypeSelection');
        }
	}

	const setSelectedProductValidations = (consumerProduct) => {
		let validationFlag = false;
		// setCheckUniqueId(false);
		// setCheckAlternateUniqueId(false);
		let uniqueId = false;
		let alternateUniqueId = false;
		let checkUsingApiFlag = false;
        let validationArr = [];
        let imei = consumerProduct.AlternateUniqueKey;
        //Check if productConfig exists in the product
        if (consumerProduct && consumerProduct.productConfig) {
            if (consumerProduct.productConfig.IsUniqueIdMandatory || (accessFn('GSX_FLOW') && !consumerProduct.AlternateUniqueKey)) {
                if (consumerProduct.verificationStateMeta && consumerProduct.verificationStateMeta.VerificationStateID) {
                    //2 & 4 means we can skip the validation
                    if (![2,4].includes(consumerProduct.verificationStateMeta.VerificationStateID)) {
                        validationFlag = true;
                    }else {
                        validationFlag = false;
                    }
                }else {
                    validationFlag = false;
                }
                if (consumerProduct.productConfig && consumerProduct.productConfig.WarrantyCheckDetails && consumerProduct.productConfig.WarrantyCheckDetails.AllowedValues && consumerProduct.productConfig.WarrantyCheckDetails.AllowedValues.length > 0) {
                    if (consumerProduct.isAppleBrandID) {
                        validationFlag = true;
                        validationArr = consumerProduct.productConfig.WarrantyCheckDetails.AllowedValues.filter(rule => rule.Type === 'SerialNumber');
                    }else if([12, 13].includes(consumerProduct.ProductSubCategoryID)) {
                        // self.validationRules = find(self.consumerProduct.productConfig.WarrantyCheckDetails.AllowedValues, function (o) { return o.Type == 'IMEI'})
                        validationArr = consumerProduct.productConfig.WarrantyCheckDetails.AllowedValues.filter(rule => rule.Type === 'IMEI');
                    }else {
                        validationArr = [consumerProduct.productConfig.WarrantyCheckDetails.AllowedValues[0]];
                    }

                    if (!validationArr || (validationArr && validationArr.length < 1)) {
                        validationArr = [consumerProduct.productConfig.WarrantyCheckDetails.AllowedValues[0]];
                    }
                }else {
                    validationFlag = false;
                }
                if (consumerProduct && consumerProduct.productConfig && consumerProduct.productConfig.WarrantyCheckDetails && consumerProduct.productConfig.WarrantyCheckDetails.WarrantyCheckValid) {
					checkUsingApiFlag = true
					setCheckUsingApi(true);
                }
            }else {
                validationFlag = false;
            }
			setValidations(validationArr);
			setIsValidationRequired(validationFlag);
			setUniqueIDToBeChecked(imei);
			setImeiOrSerialNo(imei);
        }

        if(validationArr && validationArr[0] && validationArr[0].Type == 'IMEI') {
            uniqueId = true;
            alternateUniqueId = false;
        }else if(validationArr && validationArr[0] && validationArr[0].Type == 'SerialNumber') {
            uniqueId = false;
            alternateUniqueId = true;
        }else {
            if (consumerProduct.ProductSubCategoryID == 12) {
                uniqueId = true;
                alternateUniqueId = false;
            }else {
                uniqueId = false;
                alternateUniqueId = true;
            }
        }
		setCheckUniqueId(uniqueId);
		setCheckAlternateUniqueId(alternateUniqueId);
        if(isNonSerializedProduct){
			setDisableAddProduct(false);
            checkToEnterAttributes();
        }else if(accessFn('GSX_FLOW') && !consumerProduct.AlternateUniqueKey){
			setShowAddEditSerialNo(true);
        }else if(validationFlag) {
            if(checkIfUniqueIDValid(consumerProduct, validationArr, uniqueId, alternateUniqueId)) {
                if(checkUsingApiFlag) {
					setCallExistingProductVerifyApi(true);
                }else {
                    checkToEnterAttributes();
                }
            }else {
				//Make user add the required uniqueID
				setShowAddEditSerialNo(true);
				// resetServiceModes();
			}
        }else {
			setShowAddEditSerialNo(false);
            checkToEnterAttributes();
        }
    }

	const checkIfUniqueIDValid = (consumerProduct, validationArr, uniqueId, alternateUniqueId) => {
		let validationRules = validationArr[0];
		if(alternateUniqueId) {
			if(consumerProduct.ProductSubCategoryID != 12 || (consumerProduct.ProductSubCategoryID == 12 && (consumerProduct.isAppleBrandID))) {
				if(consumerProduct.AlternateUniqueKey) {
					if(checkUniqueIdLength(validationRules, consumerProduct.AlternateUniqueKey) && regexTest(validationRules.ExtraFilter.toLowerCase(),consumerProduct.AlternateUniqueKey)) {
						setUniqueIDToBeChecked(consumerProduct.AlternateUniqueKey);
						setImeiOrSerialNo(consumerProduct.AlternateUniqueKey);
						return true;
					}else {
						return false;
					}
				}else {
					return false;
				}
			}else if(consumerProduct.ProductSubCategoryID == 12 && !consumerProduct.isAppleBrandID) {
				if(consumerProduct.DownloadedDeviceUniqueKey) {
					if(checkUniqueIdLength(validationRules, consumerProduct.DownloadedDeviceUniqueKey) && regexTest(validationRules.ExtraFilter.toLowerCase(),consumerProduct.DownloadedDeviceUniqueKey)) {
						setUniqueIDToBeChecked(consumerProduct.DownloadedDeviceUniqueKey);
						setImeiOrSerialNo(consumerProduct.DownloadedDeviceUniqueKey);
						return true;
					}else {
						return false;
					}
				}else {
					return false;
				}
			}else {
				return false;
			}
		}else if(uniqueId) {
			if(consumerProduct.ProductUniqueID) {
				if(checkUniqueIdLength(validationRules, consumerProduct.ProductUniqueID) && regexTest(validationRules.ExtraFilter.toLowerCase(),consumerProduct.ProductUniqueID)) {
					setUniqueIDToBeChecked(consumerProduct.ProductUniqueID);
					setImeiOrSerialNo(consumerProduct.ProductUniqueID);
					return true;
				}else {
					return false;
				}
			}else {
				return false;
			}
		}else {
			return false;
		}
	}

	const checkUniqueIdLength = (validationRules, uniqueID) => {
		if(validationRules.AdditionalSupportedLength && validationRules.AdditionalSupportedLength.length > 0 && validationRules.AdditionalSupportedLength.indexOf(uniqueID.length) > -1) {
            return true;
        } else if((!validationRules.AdditionalSupportedLength || (validationRules.AdditionalSupportedLength && validationRules.AdditionalSupportedLength.length == 0)) && validationRules.Length == uniqueID.length) {
            return true;
        } else {
            return false;
        }
	}

	const handleBrandSelection = (data) => {
		setBrand(data);
		hideSection(['AddEditSerialNo', 'SelectedProduct', 'InspectionGuide', 'DeviceAttributes', 'ModeType', 'Plans', 'CoverageType', 'Coverage', 'ClaimForm', 'DOP', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		setSubCat({});
		setProduct({});
		setDisplaySerialNumberInput(true);
		setDisplaySubCat(true);
		setDisplayProduct(false);
		setDisplayAddProduct(false);
		setIsAppleBrandID(data.isAppleBrandID);
		setImeiOrSerialNo('');
		setDisableAddProduct(true);
	}

	const handleSubCatSelection = (data) => {
		setSubCat(data);
		hideSection(['AddEditSerialNo', 'SelectedProduct', 'InspectionGuide', 'DeviceAttributes', 'ModeType', 'Plans', 'CoverageType', 'Coverage', 'ClaimForm', 'DOP', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		setProduct({});
		setDisplayProduct(true);
		setDisplaySerialNumberInput(false);
		setDisplayAddProduct(false);
		if(data && data.ProductSubCategoryID == 12) {
			if (!isAppleBrandID) {
				setValidations([AllowedValues[0]]);
				setCheckUniqueId(true);
				setCheckAlternateUniqueId(false);
			}
		}else {
			setCheckUniqueId(false);
			setCheckAlternateUniqueId(true);
			if (isAppleBrandID) {
				setValidations([AllowedValues[1]]);
			} else {
				setValidations([AllowedValues[2]]);
			}
		}
	}

	const handleProductSelection = (data) => {
		setProduct(data);
		hideSection(['AddEditSerialNo', 'SelectedProduct', 'InspectionGuide', 'DeviceAttributes', 'ModeType', 'Plans', 'CoverageType', 'Coverage', 'ClaimForm', 'DOP', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		setIsDeviceInWarranty(false);
		setIsDeviceWarrantyApplicable(false);
		setSkipValidationsForProduct(data.productConfig ? data.productConfig.SkipValidationsForProduct : false);
		setIsNonSerializedProduct(data.productConfig ? data.productConfig.isSerializedProduct === false : false);
		setDisplayAddProduct(true);
		setDisableAddProduct(true);
		if(brand && brand.WarrantyCheckDetails && brand.WarrantyCheckDetails.ValidForEntireBrand) {  // If valid for entire brand then get validiations from brands
			let validationArr;
			if(!isAppleBrandID) {
				validationArr = brand.WarrantyCheckDetails.AllowedValues.filter(rule => rule.ProductList.includes(data.ProductID));
			} else {
				validationArr = brand.WarrantyCheckDetails.AllowedValues.filter(rule => rule.ProductList.includes(data.ProductID) && rule.Type === 'SerialNumber');
			}
			validationArr.length && setValidations(validationArr);
		}else { // Set new validations based on product config
			setProductSpecificValidations(data);
		}
		setCallHandleImeiChange(true);
	}

	const setProductSpecificValidations = (product) => {
		if(product && product.productConfig) {
            let uniqueId = false;
            let alternateUniqueId = false;
            let validationRule = {};
            if(product.productConfig.IsSerialNumberMandatory) {
                validationRule.Type = 'SerialNumber';
                validationRule.ExtraFilter = 'Alphanumeric';
                uniqueId = false;
                alternateUniqueId = true;
            } else {
                validationRule.Type = 'IMEI';
                validationRule.ExtraFilter = 'Numeric';
                uniqueId = true;
                alternateUniqueId = false;
            }

            //Set validation length
            if(product.productConfig.validSerialNoLength) {
                validationRule.Length = max(product.productConfig.validSerialNoLength);
            } else {
                validationRule.Length = 15;
            }
			setCheckUniqueId(uniqueId);
			setCheckAlternateUniqueId(alternateUniqueId);
			setValidations([validationRule]);
        }
	}

	const brandSpecificValidations = () => {
		let uniqueIdFlag = false;
		let alternateUniqueIdFlag = false;
		let checkUsingApiFlag = false;
		let validationFlag = true;
		let validationArr = []

		if(brand.WarrantyCheckDetails && brand.WarrantyCheckDetails.AllowedValues && brand.WarrantyCheckDetails.AllowedValues.length && isAppleBrandID) {
			validationFlag = true;
			validationArr = brand.WarrantyCheckDetails.AllowedValues.filter(obj => obj.Type === 'SerialNumber');
		}else if(brand.WarrantyCheckDetails && brand.WarrantyCheckDetails.AllowedValues && brand.WarrantyCheckDetails.AllowedValues.length) {
			validationFlag = true;
			validationArr = brand.WarrantyCheckDetails.AllowedValues;
		} else{
			validationFlag = false;
			if(accessFn('GSX_FLOW')) {
				validationArr = [AllowedValues[1]];
			}else {
				validationArr = [AllowedValues[0]];
			}
		}
		if (validationFlag) {
			checkUsingApiFlag = true;
			setDisplaySerialNumberInput(true);
        }
		validationArr.length && validationArr.map((rule) => {
			if(rule.Type === 'IMEI') uniqueIdFlag = true; // setCheckUniqueId(true);
			if(rule.Type === 'SerialNumber') alternateUniqueIdFlag = true; // setCheckAlternateUniqueId(true);
		})
		setCheckUniqueId(uniqueIdFlag);
		setCheckAlternateUniqueId(alternateUniqueIdFlag);
		setCheckUsingApi(checkUsingApiFlag);
		setIsValidationRequired(validationFlag);
		setValidations(validationArr); 
	}

	const handleImeiChange = (currentId, e) => {
		setCurrentId(currentId);
		let imei = imeiOrSerialNo;
		if(e) {
			imei = e.target.value.toUpperCase();
			setImeiOrSerialNo(imei);
		}
		let disableAddProductCopy = true;
		if(isValidImei(imei) && validations.length > 1) {
			disableAddProductCopy = false;
		}else if(isValidImei(imei) && (!isValidationRequired || skipValidationsForProduct || isNonSerializedProduct || AdditionalSupportedLengthCheck)) {
			disableAddProductCopy = false;
		// }else if(isValidImei(imei) && (isValidationRequired || !skipValidationsForProduct || !isNonSerializedProduct || !AdditionalSupportedLengthCheck)) {
		}else if(isValidImei(imei) && checkUsingApi) {
			if (consumerProduct && Object.keys(consumerProduct).length > 0) {
				setCallExistingProductVerifyApi(true);
			}else {
				setCallAddProductVerifyApi(true);
			}
		}else {
			disableAddProductCopy = true;
		}
		if(product && product.productConfig && (
			(product.productConfig.hasOwnProperty("SkipValidationsForProduct") && product.productConfig.SkipValidationsForProduct) || 
			(product.productConfig.hasOwnProperty("isSerializedProduct") && !product.productConfig.isSerializedProduct)
			)
		) {
			disableAddProductCopy = false;
		}
		setDisableAddProduct(disableAddProductCopy);
	}
	
	const isValidImei = (imei) => {
		let valid = false;
		validations.length && validations.map(rule => {
			if(!valid) {
				// if(rule.ExtraFilter && regexTest(rule.ExtraFilter.toLowerCase(), imei)) {
				if(rule.ExtraFilter && (rule.ExtraFilter == 'Custom' ? regexTest(rule.ExtraFilter.toLowerCase(), imei, rule.Pattern) : regexTest(rule.ExtraFilter.toLowerCase(), imei))) {
					if(rule.AdditionalSupportedLength && rule.AdditionalSupportedLength.includes(imei.length)) {
						valid = true;
					} else if((rule.Length == imei.length) && !rule.MinLengthFlag) {
						valid = true;
					} else if(rule.MinLengthFlag && imei.length && (imei.length >= rule.Length)) {
						valid = true;
					}
				}else {
					valid = false;
				}
			}
		})
		return valid;
	}

	const verifyAndCreateProduct = (flowType, imei) => {
		setLoader(true)
		let reqObj = {
			fetchDataFromGSX: true,
			ConsumerID: consumerId,
			CountryID: consumerObj?.countryID ? consumerObj.countryID : undefined,
		};
		if(product) reqObj.ProductID = product.ProductID;
		if(flowType === 'consumerProductExisting') {
			// reqObj.BrandID = cp ? cp.BrandID : consumerProduct.BrandID;
			// reqObj.ConsumerProductID = cp ? cp.ConsumerProductID : consumerProduct.ConsumerProductID;
			// reqObj.imeiOrSerial = imei ? imei : uniqueIDToBeChecked;
			reqObj.BrandID = consumerProduct.BrandID;
			reqObj.ConsumerProductID = consumerProduct.ConsumerProductID;
			// reqObj.imeiOrSerial = uniqueIDToBeChecked;
			reqObj.imeiOrSerial = imeiOrSerialNo;
		}else if(flowType === 'addConsumerProduct') {
			reqObj.BrandID = brand.BrandID;
			// reqObj.imeiOrSerial = imei ? imei : imeiOrSerialNo
			reqObj.imeiOrSerial = imeiOrSerialNo;
		}

		if(isGsxDown || isDataMasked || ((isAppleBrandID || consumerProduct.isAppleBrandID) && !qFilter['gsx-user-detail-id'])){
			if(flowType === 'addConsumerProduct') {
			  reqObj.ProductID = product.ProductID;
			  reqObj.ProductSubCategoryID = product.ProductSubCategoryID;
			} else if (flowType === 'consumerProductExisting') {
			  reqObj.ProductID = consumerProduct.ProductID;
			  reqObj.ProductSubCategoryID = consumerProduct.ProductSubCategoryID;
			}
			reqObj.isGsxDown = isGsxDown;
			reqObj.isDataMasked = isDataMasked || ((isAppleBrandID || consumerProduct.isAppleBrandID) && !qFilter['gsx-user-detail-id']);
		  }
		apiCall({
			url: 'verifyAndCreateProduct',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result && result.success) {
					let data = result.data;
					if(consumerProduct && consumerProduct.ProductDetails) { // Need to extract ProductDetails key from the existing data, as verifyAndCreateProduct api does not give ProductDetails specific data
						data.ProductDetails = consumerProduct.ProductDetails;
					}
					setConsumerProduct(data);
					setConsumerId(data.ConsumerID);
					setIsDeviceInWarranty(data.IsUnderWarranty);
					setIsWarrantyApplicable(data.IsWarrantyApplicable)
					// setIsDeviceWarrantyApplicable(data.IsWarrantyApplicable);
					setShowSelectedProduct(true);
					setCallComplianceWarning(true);
					setDeviceWarningMsg('');
					// setScreenToViewDiv('selectedDevice');
					if(flowType == 'addConsumerProduct') {
						// fetch consumer product data and call checkToEnterAttributes()
						getDeviceMeta(data.ConsumerProductID);
					} else {
						checkToEnterAttributes();
					}
				}else {
					let gsxDown = result && result.isGsxDown ? result.isGsxDown : false;
                    let dataMasked = result && result.isDataMasked ? result.isDataMasked : false;
					setIsGsxDown(gsxDown);
					setIsDataMasked(dataMasked);
					if((gsxDown || dataMasked ) && (currentId && currentId == 'serialNo2' || flowType == 'consumerProductExisting')) {
                        if(dataMasked){
                            Swal.fire({
                                text: localString('We are unable to establish complete device details from GSX. Diagnosis may not have been performed on this device. Please proceed with request creation by selecting product details.'),
								icon: 'info',
                                confirmButtonColor: '#4C10C1',
                                confirmButtonText: localString("Continue")
                            }).then(swalResult => {
                                if(swalResult.isConfirmed){
									if(flowType === 'consumerProductExisting') {
										setCallExistingProductVerifyApi(true);
									} else {
										setCallAddProductVerifyApi(true);
									}
                                }
                            });
                        }else{
                            Swal.fire({
                                text: localString('Device warranty details could not be fetched from GSX currently. Click on "Continue" to proceed with selecting the device details or Click on "Retry" to re-attempt fetching warranty details'),
								icon: 'info',
                                showCancelButton: true,
                                confirmButtonColor: '#4C10C1',
                                confirmButtonText: localString("Continue"),
                                cancelButtonColor: '#d33',
                                cancelButtonText: localString("Retry")
                            }).then(swalResult => {
								if(swalResult.isConfirmed){
									if(flowType === 'consumerProductExisting') {
										setCallExistingProductVerifyApi(true);
									} else {
										setCallAddProductVerifyApi(true);
									}
								} else {
									setIsGsxDown(false);
									if(flowType === 'consumerProductExisting') {
										setCallExistingProductVerifyApi(true);
									} else {
										setCallAddProductVerifyApi(true);
									}
								}
                            });
                        }

                    }else if ((result && result.errorCode && ErrorCodes.indexOf(result.errorCode) > -1) || ((gsxDown || dataMasked ) && flowType == 'addConsumerProduct')) {
						// self.showSelectedDevice = false;						
                        if(dataMasked){
                            Swal.fire({
                                text: localString('We are unable to establish complete device details from GSX. Diagnosis may not have been performed on this device. Please proceed with request creation by selecting product details.'),
								icon: 'info',
                                confirmButtonColor: '#4C10C1',
                                confirmButtonText: localString("Continue")
                            })
							// .then(function(isConfirm) {
                            //     if(isConfirm){
                            //         //self.validateProduct(flowType,currentId);
                            //     }
                            // });
                        }else{
                            Swal.fire({
                                text: localString('Device warranty details could not be fetched from GSX currently. Click on "Continue" to proceed with selecting the device details or Click on "Retry" to re-attempt fetching warranty details'),
								icon: 'info',
                                showCancelButton: true,
                                confirmButtonColor: '#4C10C1',
                                confirmButtonText: localString("Continue"),
                                cancelButtonColor: '#d33',
                                cancelButtonText: localString("Retry")
                            }).then(swalResult => {
                                if(swalResult.isConfirmed){
                                } else {
									setIsGsxDown(false);
									if(flowType == 'addConsumerProduct') {
										setCallAddProductVerifyApi(true);
									}else {
										setCallExistingProductVerifyApi(true);
									}
                                }
                            });
                        }
					}else {
                        if (flowType == 'consumerProductExisting') {
                            if(result){
                                Swal.fire({
                                    title: '',
                                    text: localString(displayError(result)),
									icon: 'error',
                                    confirmButtonText: localString('OK'),
                                    cancelButtonText: localString('Cancel')
                                });
                            }
							setShowAddEditSerialNo(true);
							setEditImei(true);
					        // self.setScreenToViewDiv('selectedDevice');
                        }else{
                            // self.showSelectedDevice = false;
                            // self.showBTK = false;
                            // self.update();
                            Swal.fire({
                                title: '',
                                text: localString(displayError(result)),
								icon: 'error',
                                confirmButtonText: localString('OK'),
                                cancelButtonText: localString('Cancel')
                            });
                        }
					}
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	useEffect(() => {
		if(callExistingProductVerifyApi) {
			verifyAndCreateProduct('consumerProductExisting');
			setCallExistingProductVerifyApi(false);
		}
	}, [callExistingProductVerifyApi])

	useEffect(() => {
		if(callAddProductVerifyApi) {
			verifyAndCreateProduct('addConsumerProduct');
			setCallAddProductVerifyApi(false);
		}
	}, [callAddProductVerifyApi])

	const addProduct = () =>  {
		setLoader(true); 
		hideSection(['AddEditSerialNo', 'SelectedProduct', 'InspectionGuide', 'DeviceAttributes', 'ModeType', 'Plans', 'CoverageType', 'Coverage', 'ClaimForm', 'DOP', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation']);
		// if (brand.WarrantyCheckDetails && !isNonSerializedProduct && !skipValidationsForProduct) {
		if(checkUsingApi && !isNonSerializedProduct && !skipValidationsForProduct) {
            //API Call Function
            if (consumerProduct && Object.keys(consumerProduct).length > 0) {
                // verifyAndCreateProduct('consumerProductExisting');
				setCallExistingProductVerifyApi(true);
            }else {
                // verifyAndCreateProduct('addConsumerProduct');
				setCallAddProductVerifyApi(true);
            }
        }else {
			let addProduct = {
                ConsumerID: consumerId,
                BrandID: brand && brand.BrandID,
                ProductID: product && product.ProductID,
                ProductSubCategoryID: product && product.ProductSubCategoryID,
                ProductUniqueID: imeiOrSerialNo,
				// CountryID: consumerObj && consumerObj.countryID,
				// CountryCode: consumerObj && consumerObj.countryCode,
                IsUnderWarranty: isDeviceInWarranty,
                WarrantyTill: isDeviceInWarranty && warrantyTillDate ? moment(warrantyTillDate, getDateFormat(defaultCountryLocale)).format('DD-MM-YYYY') : undefined,
                IsWarrantyApplicable: isDeviceWarrantyApplicable
            };
			if(addProduct.WarrantyTill) {
				setWarrantyTillDateCopy(new Date(warrantyTillDate));
			}else {
				setWarrantyTillDateCopy(undefined);
			}
            if (!addProduct.ProductUniqueID && !skipValidationsForProduct) {
                if(checkAlternateUniqueId) {
                    addProduct.AlternateUniqueKey = imeiOrSerialNo;
                } else {
                    addProduct.ProductUniqueID = imeiOrSerialNo;
                }
            }
            if(isNonSerializedProduct){
                addProduct.skipWarrantyCheck = true
            }
			// TODO	: Use addConsumerProduct api, once all the api's of consumer servie are developed
            // let addProduct = {
            //     consumerID: consumerId,
            //     brandID: brand.BrandID,
            //     productID: product.ProductID,
            //     productSubCategoryID: product.ProductSubCategoryID,
            //     productUniqueID: imeiOrSerialNo,
			// 	countryID: consumerObj && consumerObj.countryID,
			// 	countryCode: consumerObj && consumerObj.countryCode,
            //     isUnderWarranty: isDeviceInWarranty,
            //     warrantyTill: isDeviceInWarranty && warrantyTillDate ? moment(warrantyTillDate, getDateFormat(defaultCountryLocale)).format('DD-MM-YYYY') : undefined,
            //     // isWarrantyApplicable: isDeviceWarrantyApplicable
            // };
            // if (!addProduct.ProductUniqueID && !skipValidationsForProduct) {
            //     if(checkAlternateUniqueId) {
            //         addProduct.alternateUniqueKey = imeiOrSerialNo;
            //     } else {
            //         addProduct.productUniqueID = imeiOrSerialNo;
            //     }
            // }
            // if(isNonSerializedProduct){
            //     addProduct.skipWarrantyCheck = true
            // }

			apiCall({
				url: 'addConsumerProductInServiceFlow',
				// url: 'addConsumerProduct',
				data: addProduct,
				callBack: (result) => {
					setLoader(false); 
					if (result.success) {
						let data = result.data
						// let cp = {
						// 	"AlternateUniqueKey": data.alternateUniqueKey,
						// 	"BrandID": data.brandID,
						// 	"Colour": data.colour,
						// 	"ConsumerID": data.consumerID,
						// 	"ConsumerProductID": data.consumerProductID,
						// 	"ConsumerProductReferenceID": data.consumerProductReferenceID,
						// 	"ConsumerServiceRequestID": data.consumerServiceRequestID,
						// 	"CreatedDate": data.createdDate,
						// 	"DateOfActivation": data.dateOfActivation,
						// 	"DateOfPurchase": data.dateOfPurchase,
						// 	"DeviceIdentifierID": data.deviceIdentifierID,
						// 	"DownloadedDeviceUniqueKey": data.downloadedDeviceUniqueKey,
						// 	"FinishedGoodID": data.finishedGoodID,
						// 	"IsAppDownlodedDevice": data.isAppDownlodedDevice,
						// 	"IsUnderWarranty": data.isUnderWarranty,
						// 	"IsVerified": data.isVerified,
						// 	"LastRepairDate": data.lastRepairDate,
						// 	"ModelNo": data.modelNo,
						// 	"ProductDescription": data.productDescription,
						// 	"ProductID": data.productID,
						// 	"ProductPurchaseCost": data.productPurchaseCost,
						// 	"ProductSubCategory": {
						// 		"ProductSubCategory": data.productSubCategory && data.productSubCategory.productSubCategory ? data.productSubCategory.productSubCategory : null ,
						// 		"ProductSubCategoryCode": data.productSubCategory && data.productSubCategory.ProductSubCategoryCode ? data.productSubCategory.ProductSubCategoryCode : null ,
						// 	},
						// 	"ProductSubCategoryID": data.productSubCategoryID,
						// 	"ProductUniqueID": data.productUniqueID,
						// 	"PurchaseCountry": data.purchaseCountry,
						// 	"RAM": data.RAM,
						// 	"RegistrationDate": data.registrationDate,
						// 	"StorageCapacity": data.storageCapacity,
						// 	"TagName": data.tagName,
						// 	"WarrantyTill": data.warrantyTill,
						// 	...data
						// };
						setConsumerProduct(data);
						setConsumerId(data.ConsumerID);
						setShowInspectionGuide(true);				
                        if(imeiOrSerialNo){
							setCallComplianceWarning(true);
                        }
                        if(data && data.ConsumerProductID) {
                            getDeviceMeta(data.ConsumerProductID);
                        }
                    } else {
                        Swal.fire({  
							icon: 'error',  
							text: localString(displayError(result)),  
						});
                    }
				},
				errorCB: (err) => {
					setLoader(false);
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(err)),  
					});
				}
			});	
        }
	}

	const getDeviceMeta = (id) => {
		setLoader(true)
		let reqObj = {
			"ConsumerProductID": id,
			"CountryID": consumerObj?.countryID ? consumerObj.countryID : undefined,
		};
		apiCall({
			url: 'getConsumerProductDetails',
			// url: 'fetchConsumerProductDetails', // new consumer module api
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data){
					let cp = {...result.data};
					setConsumerId(cp.ConsumerID);
					setConsumerProduct(cp);
					if(cp && cp.ProductSubcategory && cp.ProductSubcategory.productSubCategoryAttributes && cp.ProductSubcategory.productSubCategoryAttributes.length > 0) {
						let obj = {...selectedConsumerProduct};
                        obj.SubcategoryAttributes = cp.ProductSubcategory.productSubCategoryAttributes;
						setSelectedConsumerProduct(obj);
                    }
					checkToEnterAttributes();
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});	
	}

	const addProductClick = () => {
		setShowAddProduct(true);
		setDisplaySubCat(false);
		setDisplayProduct(false);
		hideSection(['AddEditSerialNo', 'SelectedProduct', 'InspectionGuide', 'DeviceAttributes', 'ModeType', 'Plans', 'CoverageType', 'Coverage', 'ClaimForm', 'DOP', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		setConsumerProduct({});
		setSelectedConsumerProduct({});
		setImeiOrSerialNo('');
	}

	return (
		<>
			<div id='ConsumerProducts' className='raiseReqSection'>
				<div className='consumerProducts'>
					<div className="row">
						<div className="col-sm-12 form-label" style={{ marginBottom: '15px' }}>
							<label>{localString("Select a Consumer Product")}</label>
							{
								accessFn('ADD_CONSUMER_PRODUCT') && 
									<Button 
										className="btn button-servify pull-right" 
										onClick={addProductClick}
									>
										{localString("Add a Product")}
									</Button>
							}
							<Button 
								className="pull-right button-refresh" 
								onClick={() => {
									setCpSearchVal('');
									loadConsumerProducts();
								}}
							/>
						</div>
					</div>  
					<div className='row'>
						{/* ***** Serviceable Products ***** */}
						<div className={`${(inRepairConsumerProducts && inRepairConsumerProducts.length > 0) ? 'col-sm-6' : 'col-sm-12'} col-xs-12`}>
							{   
								consumerProductArray && consumerProductArray.length > 0 &&
									<div className="form-group search-box">
										<Input
											value={cpSearchVal}
											handleOnChange={handleCPFilter}
											placeholder={localString("Search consumer products")}
											className={{
												formControl: 'form-group',
												containerClass: 'input-container',
												inputClass: 'input'
											}}
										>
											<span className='align-right search-box-icon'></span>
										</Input>
									</div>
							}
							{
								(!filteredConsumerProducts || (filteredConsumerProducts && filteredConsumerProducts.length == 0)) && 
									<div className="col-sm-10 col-sm-offset-1">
										<div className="text-center empty-message request-form-empty-state-message">
											{localString("No serviceable consumer product found")}
										</div>
									</div>
							}
							{
								filteredConsumerProducts && filteredConsumerProducts.length > 0 && 
									<div className="fixed-height row">
										{
											filteredConsumerProducts && filteredConsumerProducts.length > 0 && filteredConsumerProducts.map((data, k) => {
												return (
													<div className={`${inRepairConsumerProducts && inRepairConsumerProducts.length > 0 ? 'col-sm-12': 'col-sm-6'}`} key={k} onClick={() => consumerProductClick(data)}>
														<div style={{ textAlign: 'left', height: 'auto' }} className={`row item-not-inservice productImg under-service-items ${(consumerProduct.ConsumerProductID == data.ConsumerProductID) && 'item-not-inservice-selected'} subcat-item`}>
															<div className="col-md-2">
																<div className="">
																	{ data.IsAppDownlodedDevice && <span style={{ right: '-10px' }} className="sprite-tick tick-abs-right" ></span> }
																	<img src={data.ProductSubcategory && data.ProductSubcategory.ImageUrl} alt={data.ProductSubcategory && data.ProductSubcategory.ProductSubCategory}/>
																</div>
															</div>
															<div className="col-md-10">
																<div className="row">
																	<label for="" className="col-md-4">{localString("Product Name")}</label>
																	<label className="col-md-1">:</label>
																	<div className="col-md-7">
																		<span className="">{data.product.ProductName}</span>
																	</div>
																</div>
																<div className="row">
																	<label for="" className="col-md-4">{localString("Product Unique ID")}</label>
																	<label className="col-md-1">:</label>
																	<div className="col-md-7">
																		<span>{data.ProductUniqueID || "-"}</span>
																	</div>
																</div>
																{ data.AlternateUniqueKey && <div className="row">
																	<label for="" className="col-md-4">{localString("Serial Number")}</label>
																	<label className="col-md-1">:</label>
																	<div className="col-md-7">
																		<span>{data.AlternateUniqueKey || "-"}</span>
																	</div>
																</div> }
																{ data.ProductDetails && data.ProductDetails.length > 0 && <div className="row">
																	<label for="" className="col-md-4">{localString("Active Plans")}</label>
																	<label className="col-md-1">:</label>
																	<div className="col-md-7">                                                                        
																		{   
																			data.ProductDetails && data.ProductDetails.length > 0 && data.ProductDetails.map((plan, j) => {
																				return (
																					<>
																						{ plan.validity && moment(plan.validity).isValid() && moment(plan.validity).unix() != 0 && 
																							<span key={j}>{localString("valid till")}
																								<span className="warranty-till-date"> {plan.ValidityDate && moment(plan.ValidityDate).isValid() && moment(plan.ValidityDate).unix() != 0 ? getLocaleDateString(plan.ValidityDate, defaultCountryLocale):''}
																							</span>
																						</span> }
																						<span className="covered-under"> {localString("covered under")} {plan.PlanHeader}</span>
																					</>
																				)
																			}) 
																		}
																	</div>
																</div> }
																{ data.LastRepairDate && moment(data.LastRepairDate).unix() != 0 && <div className="row">
																	<label for="" className="col-md-5 text-right">{localString("Last Repair Date")}</label>
																	<label className="col-md-1">:</label>
																	<div className="col-md-7">
																		<span>{(data.LastRepairDate ? moment(data.LastRepairDate).unix() != 0  ?  getLocaleDateString(data.LastRepairDate, defaultCountryLocale): '-' : '-')}</span>
																	</div>
																</div>}
															</div>
														</div>
													</div>
												);
											})
										}
									</div>
							}
						</div>
						{/* ***** Inservice Products ***** */}
						{
							inRepairConsumerProducts && inRepairConsumerProducts.length > 0 && <div className={`col-sm-6 col-xs-12`}>
								<div className="row text-center products-under-service-header">
									{inRepairConsumerProducts.length} {localString("products under service")}
								</div>
								<div className="fixed-height row distinct-products-border">
									{
										inRepairConsumerProducts && inRepairConsumerProducts.length > 0 && inRepairConsumerProducts.map((product, k) => {
											return (
												<div className="col-md-4 col-sm-6 col-xs-12 disabled-div">
													<div className="item-inservice subcat-item">
														{ product.IsAppDownlodedDevice && <span className="sprite-tick tick-abs-right"></span>}
														<img src={product.ProductSubcategory && product.ProductSubcategory.ImageUrl} alt={product.ProductSubcategory && product.ProductSubcategory.ProductSubCategory}/>
														<p>{product.product.ProductName}</p>
													</div>
												</div>
											)
										})
									}
									
								</div>
							</div>
						}
						
					</div>
				</div>
				
			</div>

			{/* ********** Select Brand ********** */}
			<SelectBrand 
				{...props}
				setLoader={setLoader}
				getBrandImage={getBrandImage}
				showAddProduct={showAddProduct}
				brand={brand} setBrand={setBrand}
				setScreenToViewDiv={setScreenToViewDiv}
				handleBrandSelection={handleBrandSelection}
				setDisplaySubCat={setDisplaySubCat}
			/>

			{/* ********** Select SubCategory ********** */}
			<SelectSubCategory 
				{...props}
				setLoader={setLoader}
				validations={validations}
				displaySubCat={displaySubCat}
				isAppleBrandID={isAppleBrandID}
				showAddProduct={showAddProduct}
				imeiOrSerialNo={imeiOrSerialNo}
				placehoderText={placehoderText}
				brand={brand} setBrand={setBrand}
				handleImeiChange={handleImeiChange}
				disableAddProduct={disableAddProduct}
				subCat={subCat} setSubCat={setSubCat}
				setScreenToViewDiv={setScreenToViewDiv}
				handleSubCatSelection={handleSubCatSelection}
				imeiOrSerialNoMaxlength={imeiOrSerialNoMaxlength}
				brandSpecificValidations={brandSpecificValidations}
				displaySerialNumberInput={displaySerialNumberInput}
				setCallAddProductVerifyApi={setCallAddProductVerifyApi}
				AdditionalSupportedLengthCheck={AdditionalSupportedLengthCheck}
			/>

			{/* ********** Select Product ********** */}
			<SelectProduct 
				{...props}
				setLoader={setLoader}
				displayProduct={displayProduct}
				showAddProduct={showAddProduct}
				brand={brand} setBrand={setBrand}
				subCat={subCat} setSubCat={setSubCat}
				setScreenToViewDiv={setScreenToViewDiv}
				product={product} setProduct={setProduct}
				handleProductSelection={handleProductSelection}
			/>

			{ showAddProduct && <>
				{/* ********** Display add product details and verify IMEI/Serial no. ********** */}
				{
					displayAddProduct && 
					<div id='DisplayAddProduct' className="raiseReqSection">
						<div id='addProduct' className='addProduct' style={{ paddingBottom: '10px' }}>
							<label className="text-capitalize">{localString("Add Selected Product")}</label>
							<div className="selected-product">
								<img src={subCat && subCat.ImageUrl} alt={subCat && subCat.ProductSubCategory} />
								<div>
									<p className="prod">{product.ProductName}</p>
									<p className="subcat">{subCat.ProductSubCategory}</p>
								</div>
							</div>
							{/* { !isNonSerializedProduct && <div className="form-group"> */}
							{
								!isNonSerializedProduct && 
									<div className="form-group">
										<label for="serialNo2" className="text-capitalize">{placehoderText}</label>
										<div className="form-group">
											<Input
												id="serialNo2"
												value={imeiOrSerialNo}
												handleOnChange={(e) => handleImeiChange('serialNo2', e)}
												placeholder={placehoderText}
												className={{
													formControl: 'form-group',
													containerClass: 'input-container',
													inputClass: 'input'
												}}
												maxLength={imeiOrSerialNoMaxlength}
											/>
										</div>
									</div> 
								}
							{
								(!brand.WarrantyCheckDetails || skipValidationsForProduct) && 
									<div className="form-group">
										<label for="deviceWarranty" className="text-capitalize">{localString("Device In Warranty?")}</label>
										<div className="row">
											<div className="col-sm-3 col-xs-4">
												<div
													className={`item product-item ${isDeviceInWarranty ? 'item-active-border': ''}`} 
													onClick={() => setIsDeviceInWarranty(true)}
												>
													{localString("Yes")}
												</div>
											</div>
											<div className="col-sm-3 col-xs-4">
												<div 
													className={`item product-item ${!isDeviceInWarranty ? 'item-active-border': ''}`} 
													onClick={() => {
														setIsDeviceInWarranty(false);
														setIsDeviceWarrantyApplicable(false)}
													}
												>
													{localString("No")}
												</div>
											</div>
										</div>
									</div>
							}
							{
								isAppleBrandID && !accessFn('GSX_FLOW') &&
									<div className="form-group" if={isAppleBrandID && !accessFn('GSX_FLOW')}>
										<label for="deviceWarranty" className="text-capitalize">{localString("Device In Warranty?")}</label>
										<div className="row">
											<div className="col-sm-3 col-xs-4">
												<div className="item product-item item-active-border">
													{isDeviceInWarranty ? localString("Yes"): localString("No")}
												</div>
											</div>
										</div>
									</div>
							}
							{
								isDeviceInWarranty && (!isAppleBrandID || !accessFn('GSX_FLOW')) &&
									<div className="form-group raiseRequestDatePicker">
										<label for="warrantyTill">{localString("Warranty Till")}?</label>
										<div className='input-group date col-sm-6 col-xs-6 col-lg-6 col-md-6' id='warrantyTill'>
											<Datetime
												value={warrantyTillDate}
												inputProps={{
													onKeyDown: e => e.preventDefault()
												}}
												dateFormat={'DD-MM-YYYY'}
												timeFormat={false}
												onChange={(val) => {
													setWarrantyTillDate(new Date(val));
												}}
												isValidDate={(current) => current.isAfter(yesterday)}
												closeOnSelect={true}
											/>
										</div>
									</div>
							}
							{
								isDeviceInWarranty && isAppleBrandID && !accessFn('GSX_FLOW') &&
									<div className="form-group">
										<label for="warrantyTill" className="text-capitalize">{localString("Warranty Till")}</label>
										<div className="row">
											<div className="col-sm-3 col-xs-4">
												<div className="item product-item item-active-border">
													{warrantyTill ? getLocaleDateString(warrantyTill,defaultCountryLocale) : '-'}
												</div>
											</div>
										</div>
									</div>
							}
							{
								isDeviceInWarranty && !accessFn('GSX_FLOW') &&
									<div className="form-group">
										<label for="isdeviceunderwarranty" className="text-capitalize">{localString("Is Warranty Applicable for this request?")}</label>
										<div className="row">
											<div className="col-sm-3 col-xs-4">
												<div className={`item product-item ${isDeviceWarrantyApplicable ? 'item-active-border': ''}`} onClick={() => setIsDeviceWarrantyApplicable(true)}>
													{localString("Yes")}
												</div>
											</div>
											<div className="col-sm-3 col-xs-4">
												<div className={`item product-item ${!isDeviceWarrantyApplicable ? 'item-active-border': ''}`} onClick={() => setIsDeviceWarrantyApplicable(false)}>
													{localString("No")}
												</div>
											</div>
										</div>
									</div>
							}
							{
								(!isValidationRequired || skipValidationsForProduct || isNonSerializedProduct || AdditionalSupportedLengthCheck) && 
									<div className="text-right">
										<Button
											className="btn button-servify nextBtn" 
											onClick={() => addProduct()}
											// isDisabled={!imeiOrSerialNo || disableAddProduct}
											isDisabled={disableAddProduct}
										>
											<div className="button-next"></div>
										</Button>
									</div>
							}
						</div>
					</div>
				}
			</> }

			{/* ********** Edit IMEI of selected device ********** */}
			{
				consumerProduct && showAddEditSerialNo &&
				<div id='AddEditSerialNo' className="raiseReqSection">
					<div id="addEditSelectedProduct" className='selectProduct'>
						<label className="text-capitalize">{localString("Selected Product")}</label>
                        <div className="selected-product">
                            <img src={consumerProduct.ProductSubcategory && consumerProduct.ProductSubcategory.ImageUrl} alt={consumerProduct.ProductSubcategory && consumerProduct.ProductSubcategory.ProductSubCategory} />
                            <div>
                                <p className="prod">{consumerProduct.product.ProductName}</p>
                                <p className="subcat">{consumerProduct.ProductSubcategory.ProductSubCategory}</p>
                            </div>
                        </div>
						{ !isNonSerializedProduct && <>
							<div className="form-group">
								<label for="serialNo3" className="text-capitalize">{checkUniqueId ? localString("IMEI number") : checkAlternateUniqueId ? localString("Serial number") : localString("Serial number")}</label>
								{ ((checkUniqueId && consumerProduct.ProductUniqueID) || (checkAlternateUniqueId && consumerProduct.AlternateUniqueKey)) &&
									<p>
										{checkUniqueId ? consumerProduct.ProductUniqueID : checkAlternateUniqueId ? consumerProduct.AlternateUniqueKey : ''} 
										{ !editImei && 
											<span className="glyphicon glyphicon-edit" style={{cursor: 'pointer', paddingLeft: '5px'}} onClick={() => setEditImei(true)}></span>
										}
									</p>
								}
								{
									((checkUniqueId && consumerProduct.ProductUniqueID && editImei) || (checkAlternateUniqueId && consumerProduct.AlternateUniqueKey && editImei) || (!consumerProduct.ProductUniqueID && !consumerProduct.AlternateUniqueKey)) &&
									<div className="form-group">
										<Input
											id="serialNo3"
											value={imeiOrSerialNo}
											handleOnChange={(e) => handleImeiChange('serialNo3', e)}
											placeholder={placehoderText}
											className={{
												formControl: 'form-group',
												containerClass: 'input-container',
												inputClass: 'input'
											}}
											maxLength={imeiOrSerialNoMaxlength}
											otherProps={{
												onKeyDown: (e) => {
													if(e && (e.key == 'Enter') && !disableAddProduct) {
														setCallExistingProductVerifyApi(true);
													}
												}
											}}
										/>
									</div>
								}
							</div> 
						</> }
                        <div className="text-right">
							<Button
								className="btn button-servify nextBtn" 
								// onClick={() => handleImeiChange('serialNo3')}
								onClick={() => setCallExistingProductVerifyApi(true)}
								isDisabled={disableAddProduct}
							>
								<div className="button-next"></div>
							</Button>
                        </div>
					</div>
				</div>
			}

			{/* ********** Show Selected Product ********** */}
			{
				consumerProduct && showSelectedProduct &&
				<div id='SelectedProduct' className="raiseReqSection" style={{ paddingBottom: '15px' }}>
					<div id="selectedProduct" className='selectedProduct'>
                        <div className="row">
                            <div className="col-xs-6">
                                <p>{localString("Product")}</p>
                            </div>
                            <div className="col-xs-6">
                                <p>{consumerProduct.ProductName ? consumerProduct.ProductName : (consumerProduct.product && consumerProduct.product.ProductName) ? consumerProduct.product.ProductName : '-'}</p>
                            </div>
                        </div>
						{ consumerProduct.ProductUniqueID && (!consumerProduct.isAppleBrandID || ((consumerProduct.isAppleBrandID) && (consumerProduct.ProductUniqueID.length == 15 || consumerProduct.ProductUniqueID.length == 16 ) && !isNaN(consumerProduct.ProductUniqueID))) &&
							<div className="row">
								<div className="col-xs-6">
									<p>{(consumerProduct.AlternateUniqueKey && !consumerProduct.isAppleBrandID) ? localString("IMEI") + ' 1' : localString("IMEI Number")}</p>
								</div>
								<div className="col-xs-6">
									<p >{consumerProduct.ProductUniqueID}</p>
								</div>
							</div> 
						}
						{ consumerProduct.AlternateUniqueKey &&
							<div className="row">
								<div className="col-xs-6">
									<p>{(consumerProduct.isAppleBrandID) ? localString("Serial Number") : localString("IMEI") + ' 2'}</p>
								</div>
								<div className="col-xs-6">
									<p>{consumerProduct.AlternateUniqueKey}</p>
								</div>
							</div>
						}
						{ !consumerProduct.isAppleBrandID && consumerProduct.DownloadedDeviceUniqueKey &&
							<div className="row">
								<div className="col-xs-6">
									<p>{localString("Serial Number")}</p>
								</div>
								<div className="col-xs-6">
									<p>{consumerProduct.DownloadedDeviceUniqueKey}</p>
								</div>
							</div>
						}
						{ consumerProduct.gsxInfo && <>
							<div className="row">
								<div className="col-xs-6">
									<p>{localString("Contract Type")}</p>
								</div>
								<div className="col-xs-6">
									<p>{consumerProduct.gsxInfo.contractType}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-xs-6">
									<p>{localString("Date of Purchase")}</p>
								</div>
								<div className="col-xs-6">
									<p>{(consumerProduct.gsxInfo.estimatedPurchaseDate) ? moment(consumerProduct.gsxInfo.estimatedPurchaseDate, ["DD/MM/YYYY","YYYY-MM-DD"]).format('DD-MM-YYYY') : '-'}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-xs-6">
									<p>{localString("Warranty")}</p>
								</div>
								<div className="col-xs-6">
									<p>{localString(consumerProduct.gsxInfo.warrantyStatus)}</p>
								</div>
							</div>
						</> }
                        { deviceWarningMsg &&
							<div className="textbox-padding error-color row">
								<p style={{ marginTop: '5px', display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: 'larger' }}>
									<img src={`${publicUrl}/imgs/WarningSign.png`} style={{ height: '22px', paddingRight: '5px' }}/>
									{ deviceWarningMsg ? deviceWarningMsg : ''}
								</p>
							</div>
						}                        
					</div>
				</div>
			}

			{/* ********** Wall Mount ********** */}
			{/* {
				consumerProduct && show.wallMount &&
				<div className="raiseReqSection">
					<div className='wallMount'>
						<label for="wallmount" className="text-capitalize">{localString("Is the product wall mount?")}</label>
						<div className="row">
							<div className="col-sm-3 col-xs-4">
								<div className={`item product-item ${wallMount === true ? 'item-active-border': ''}`} onClick={() => setWallMount(true)}>
									{localString("Yes")}
								</div>
							</div>
							<div className="col-sm-3 col-xs-4">
								<div className={`item product-item ${wallMount === false ? 'item-active-border': ''}`} onClick={() => setWallMount(false)}>
									{localString("No")}
								</div>
							</div>
						</div>
                        <div className="text-right">
							<Button
								className="btn button-servify nextBtn" 
								onClick={() => checkToEnterAttributes()}
							>
								<div className="button-next"></div>
							</Button>
                        </div>
					</div>
				</div>
			} */}

			{/* ********** Inspection Guide ********** */}
			{
				consumerProduct && showInspectionGuide && inspectionGuideArray && inspectionGuideArray.length > 0 && 
				<div className="raiseReqSection">
					<div id='InspectionGuide' className='inspectionGuide'>
						<div className="form-label">
							<label>{localString("Inspection Guide")}</label>
						</div>
						<div className="row">
							<div className="col-sm-4">
								{/* <a href="{item}" target="_blank" each={item,i in inspectionGuideArray} className="btn button-servify">{localString("Guide")} {i+1}</a> */}
								{
									inspectionGuideArray.map((url, k) => <a href={url} target="_blank" className="btn button-servify">{localString("Guide")} {k+1}</a>)
								}
							</div>
						</div>
					</div>
				</div>
			}
		</>
	);
}