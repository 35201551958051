import React, { useState, useEffect } from "react";
import { Modal, Radio, Button } from "sfy-react";
import "./CancellationOptionModal.scss";
import "./ContractDetails";

const CancellationOptionModal = (props) => {
	const {
		localString,
		reasonModal,
		cancellationOptions,
		setReasonModal,
		getCancelOrder,
		selectCancelOptionCall,
		selectedCancelOption,
	} = props;

	return (
		<div className="canceloption-modal">
			<Modal
				showModal={reasonModal}
				showCloseBtn={true}
				className="option-Modal"
				closeModal={() => setReasonModal(false)}
			>
				<Modal.Header className="cancelplan-header">
					<h4 className="mb20 text-left text-header-canceloption">
						{localString("Cancellation Option")}
					</h4>
				</Modal.Header>
				<Modal.Body>
					<div class="cancel-plan-wrapper">
						<div class="radio-block">
							{cancellationOptions?.map((option, i) => {
								return (
									<label
										className={`cancel-radio-container ${
											selectedCancelOption ===
											option.CancellationOption
												? "active"
												: ""
										}`}
									>
										<div class="row">
											<div class="col-sm-2">
												<Radio
													value={
														option.CancellationOption
													}
													selectedValue={
														selectedCancelOption
													}
													name="cancel-options"
													handleOnUpdate={(e) => {
														selectCancelOptionCall(
															e.target.value,
															option
														);
													}}
												/>
											</div>
											<div
												class="col-sm-10"
												style={{ marginLeft: "-50px" }}
											>
												<span class="cancel-plan-price-sub-header">
													{localString(
														option.CancellationOptionDisplay
													)}
												</span>
												<br />
												<span class="cancel-plan-price-header">
													{
														option.CancellationOptionDescription
													}
												</span>
											</div>
										</div>
									</label>
								);
							})}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className={"modal-footer-cancelplan"}>
					<Button
						type="submit"
						handleOnClick={() => {
							setReasonModal(false);
							getCancelOrder();
						}}
						isDisabled={!selectedCancelOption?.length}
						className={
							"button button-primary mt20 max-width-range continue"
						}
					>
						{localString("Continue")}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default CancellationOptionModal;
