import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Loader, Button, Modal, Dropdown, Radio } from "sfy-react";
import Swal from "sweetalert2";
import { displayError } from "../../../Utility/Utils";
import CustomerDetails from "./CustomerDetails/CustomerDetails";
import ViewContractNavBar from "./ViewContractNavBar/ViewContractNavBar";
// import QRCode from "qrcode.react";
import "./ContractDetails.scss";
import CancellationOptionModal from "./CancellationOptionModal";

const ContractDetails = (props) => {
	const {
		localString,
		apiCall,
		useQuery,
		publicUrl,
		baseUrl,
		accessFn,
	} = props;

	const qFilter = JSON.parse(props.qFilter);
	const [paymentData, setPaymentData] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [showNavButton, setShowNavButton] = useState(true);
	const [countryData, setCountryData] = useState([]);
	const [checkEligibility, setCheckEligibility] = useState([]);
	const [cancelOrder, setCancelOrder] = useState([]);
	const [paymentDetails, setPaymentDetails] = useState([]);
	const [documents, setDocuments] = useState();
	const [modal, setModal] = useState(false);
	const [reasonModal, setReasonModal] = useState(false);
	const [cancellationOptions, setCancellationOptions] = useState([]);
	const [orderCancel, setOrderCancel] = useState(false);
	const [reasonOptions, setReasons] = useState([]);
	const [reasonName, setReasonName] = useState();
	const [reasonCode, setReasonCode] = useState();
	const [resendPaymentLink, setResendPaymentLink] = useState([]);
	// const [qrModal, setQRModal] = useState(false);
	const [pagination, setPagination] = useState({
		currentPage: 1,
		itemsPerPage: 5,
		maxPages: 0,
		totalItems: 0,
	});

	const [enableContinueButton, setEnableContinueButton] = useState(false);
	const [selectedCancelOption, setSelectedCancelOption] = useState("");

	useEffect(() => {
		if (cancellationOptions?.length > 0) setReasonModal(true);
		if (cancellationOptions?.length == 1) {
			setSelectedCancelOption(cancellationOptions[0].CancellationOption);
		}
	}, [cancellationOptions?.length]);

	const selectCancelOptionCall = (type, value) => {
		setSelectedCancelOption(type);
		setEnableContinueButton(true);
	};

	let query = useQuery();
	let spID = query.get("spid");

	let hasUnrestrictedAccess = qFilter.hasUnrestrictedAccess;

	const getSoldPlanCustomersData = () => {
		let data = {
			SoldPlanID: spID,
		};

		setShowLoader(true);
		apiCall({
			url: "getSoldPlanCustomersData",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success && result.data) {
					setPaymentData(result.data && result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	useEffect(() => {
		getSoldPlanCustomersData();
		getSoldPlanDocumentsWithImages();
		getCheckCancellationEligibility();
	}, []);

	const getPaymentDetails = () => {
		let data = {
			SoldPlanID: spID,
		};
		data.pagination = pagination;
		setShowLoader(true);
		apiCall({
			url: "getPaymentDetailsAegis",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success && result.data) {
					setPaymentDetails(result.data && result.data);
					setPagination({
						...pagination,
						totalItems:
							result.data &&
							result.data.pagination &&
							result.data.pagination.totalItems,
						maxPages:
							result.data &&
							result.data.pagination &&
							result.data.pagination.maxPages,
					});
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	useEffect(() => {
		getPaymentDetails();
	}, [pagination.currentPage]);

	const getCountries = () => {
		setShowLoader(true);
		let data = {
			CountryID: paymentData?.PlanDetails?.[0]?.CountryID,
		};
		apiCall({
			url: "getCountries",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					let countryData = result.data.countries[0];
					setCountryData(countryData);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	useEffect(() => {
		paymentData?.PlanDetails?.[0]?.CountryID && getCountries();
	}, [paymentData]);

	const getCheckCancellationEligibility = () => {
		setShowLoader(true);
		let data = {
			SoldPlanID: spID,
			CountryCode: paymentData?.PlanDetails?.[0]?.CountryID,
			LanguageCode: qFilter?.languagecode?.length ? qFilter.languagecode[0] : "en",
		};
		apiCall({
			url: "checkCancellationEligibility",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success && result.data) {
					setCheckEligibility(result.success && result.data);
					let arr = result.data.cancellationReasons.map((a) => ({
						...a,
						value: a.CancellationReasonDescription,
					}));
					setReasons(arr);
					if (arr?.length == 1) {
						setReasonCode(arr[0].CancellationReasonCode)
					}
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getCancellationOptions = (reasonCode) => {
		setShowLoader(true);
		let data = {
			SoldPlanID: spID,
			ReasonCode: reasonCode,
			LanguageCode: qFilter?.languagecode?.length ? qFilter.languagecode[0] : "en",
		};
		apiCall({
			url: "getCancellationOptions",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success && result.data) {
					setCancellationOptions(result.success && result.data);
					if (result.data?.length == 0) getCancelOrder();
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getCancelOrder = () => {
		setShowLoader(true);
		let data = {
			SoldPlanID: spID,
			ReasonCode: reasonCode,
			CountryCode: paymentData?.PlanDetails?.[0]?.CountryID,
		};
		if (selectedCancelOption?.length)
			data.CancellationOption = selectedCancelOption;
		apiCall({
			url: "cancelPlanOrder",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success && result.data) {
					setCancelOrder(result.success && result.data);
					setOrderCancel(true);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getSoldPlanDocumentsWithImages = () => {
		let data = {
			SoldPlanID: spID,
		};

		setShowLoader(true);
		apiCall({
			url: "getSoldPlanDocumentsWithImages",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success && result.data) {
					setDocuments(result.data && result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getResendPaymentLink = () => {
		setShowLoader(true);
		let data = {
			SoldPlanID: spID,
		};
		apiCall({
			url: "resendPaymentLink",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success && result.data) {
					setResendPaymentLink(result.success && result.data);
					Swal.fire({
						text: localString("Payment Link Sent Successfully"),
						icon: "success",
						confirmButtonText: localString("OK"),
					});
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}

			<div className="hidden-xs content-header">
				<div className="row content-header-background main-div5">
					<div className="col-xs-12 col-sm-5">
						<h2 className="sell-plan">
							<a href={baseUrl + "/plansales/home"}>
								<span
									onClick={() => {}}
									className="resend-text"
									type="button"
								>
									{localString("Plan Sales")}
								</span>
							</a>
							&nbsp;
							/{" "}{localString("Transaction Details")} /{" "}
							{paymentData?.PlanDetails?.[0]?.SoldPlanCode}
						</h2>
					</div>
					<div className="store-contract text-right">
						{accessFn("PLAN_CANCEL") &&
							checkEligibility?.isCancellable == true &&
							checkEligibility?.cancellationReasons?.length >
								0 && (
								<Button
									type="button"
									className={
										"button button-ghost cancel-button"
									}
									onClick={() => {
										if (
											checkEligibility
												?.cancellationReasons?.length ==
											1
										) {
											getCancellationOptions(reasonCode);
										} else {
											setModal(true);
										}
									}}
								>
									{localString("Cancel Contract")}
								</Button>
							)}
						{/* {paymentData?.PlanDetails?.[0]?.Status == 8 && (
							<Button
								type="button"
								className={"button button-ghost common-button"}
								onClick={() => setQRModal(true)}
							>
								{localString("Show QRCode")}
							</Button>
						)} */}

						{paymentData?.PlanDetails?.[0]?.Status == 14 && (
							<Button
								type="button"
								className={"button button-ghost common-button"}
								onClick={() => getResendPaymentLink()}
							>
								{localString("Resend Payment Link")}
							</Button>
						)}
						{paymentData?.PlanDetails?.[0]?.Status == 9 && (
							<a
								href={
									baseUrl +
									"/plansales/orderdetails?soldplan=" +
									paymentData?.PlanDetails?.[0]?.SoldPlanID
								}
								target="_blank"
							>
								<Button
									type="button"
									className={
										"button button-ghost common-button"
									}
								>
									{localString("Make Payment")}
								</Button>
							</a>
						)}
					</div>
				</div>
			</div>
			{paymentData?.PlanDetails?.[0]?.Status == 9 && (
				<div className="row content make-payment-mobile-view">
					{paymentData?.PlanDetails?.[0]?.Status == 9 && (
						<a
							href={
								baseUrl +
								"/plansales/orderdetails?soldplan=" +
								paymentData?.PlanDetails?.[0]?.SoldPlanID
							}
							target="_blank"
						>
							<Button
								type="button"
								className={"button button-ghost makepayment"}
								style={{ float: "right" }}
							>
								{localString("Make Payment")}
							</Button>
						</a>
					)}
				</div>
			)}
			{accessFn("PLAN_CANCEL") &&
				checkEligibility?.isCancellable == true &&
				checkEligibility?.cancellationReasons?.length > 0 && (
					<div className="row content make-payment-mobile-view">
						<Button
							type="button"
							className={"button button-ghost cancel-button"}
							style={{ float: "right" }}
							onClick={() => {
								if (
									checkEligibility?.cancellationReasons
										?.length == 1
								) {
									getCancellationOptions(reasonCode);
								} else {
									setModal(true);
								}
							}}
						>
							{localString("Cancel Contract")}
						</Button>
					</div>
				)}

			<div className="customer-details">
				<CustomerDetails
					{...props}
					paymentData={paymentData}
					countryData={countryData}
				/>
			</div>

			<div className="navigationPS">
				<ViewContractNavBar
					{...props}
					showNavButton={showNavButton}
					documents={documents}
					pagination={pagination}
					setPagination={setPagination}
					paymentDetails={paymentDetails}
					paymentData={paymentData}
					countryData={countryData}
				/>
			</div>

			<Modal
				showModal={modal}
				showCloseBtn={true}
				closeModal={() => setModal(false)}
				className={"default-modal modal-width-common modal-border"}
			>
				<Modal.Header className="cancelplan-header">
					<h4 className="mb20 text-left text-header-cancelplan">
						{localString("Reason for Cancellation")}
					</h4>
				</Modal.Header>
				<Modal.Body>
					<div
						className="contract-home text-left-cancelmodal"
						style={{ justifyContent: "flex-start" }}
					>
						<h6 className="header-contract-text">
							{localString("Reason")}
						</h6>

						<Dropdown
							id="dropdown"
							value={reasonName}
							options={reasonOptions}
							handleOnChange={(data) => {
								setReasonName(data.value);
								setReasonCode(
									reasonOptions.find(
										(ro) =>
											ro.CancellationReasonDescription ===
											data.value
									)?.CancellationReasonCode || ""
								);
							}}
							placeholder={localString(
								"Choose a Reason from the dropdown"
							)}
							classObject={{
								container: "contract-dropdown-header",
							}}
							filterBy="value"
							labelFloat={false}
							showNoOptionsText={false}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className={"modal-footer-cancelplan"}>
					<Button
						type="submit"
						handleOnClick={() => {
							setModal(false);
							getCancellationOptions(reasonCode);
						}}
						isDisabled={!reasonCode?.length}
						className={"button button-primary mt20 max-width-range"}
					>
						{localString("Cancel Contract")}
					</Button>
				</Modal.Footer>
			</Modal>

			<CancellationOptionModal
				{...props}
				reasonModal={reasonModal}
				setReasonModal={setReasonModal}
				cancellationOptions={cancellationOptions}
				getCancelOrder={() => getCancelOrder()}
				setOrderCancel={setOrderCancel}
				selectedCancelOption={selectedCancelOption}
				setSelectedCancelOption={setSelectedCancelOption}
				selectCancelOptionCall={selectCancelOptionCall}
			/>

			<Modal
				showModal={orderCancel}
				showCloseBtn={true}
				closeModal={() => setOrderCancel(false)}
				className={"default-modal modal-width-common"}
			>
				<Modal.Header>
					<img
						style={{ marginBottom: "-25px" }}
						src={publicUrl + "/imgs/Check-circle.png"}
					/>
				</Modal.Header>
				<Modal.Body>
					<h3
						className="text-center"
						style={{
							fontWeight: 600,
							marginBottom: "15px",
						}}
					>
						{localString("Your cancellation request is accepted")}
					</h3>
					<h4 className="text-center">
						{localString("You'll receive a confirmation shortly")}
					</h4>
				</Modal.Body>
				<Modal.Footer className={"modal-footer-common"}>
					<Button
						type="submit"
						handleOnClick={() => {
							setOrderCancel(false);
							window.location.href = baseUrl + "/plansales/home";
						}}
						className={"button button-primary mt20 max-width-range"}
					>
						{localString("Back to Home")}
					</Button>
				</Modal.Footer>
			</Modal>

			{/* QR code modal (TODO) */}
			{/* <Modal
				showModal={qrModal}
				showCloseBtn={true}
				closeModal={() => setQRModal(false)}
				className={"default-modal modal-width-common"}
			>
				<Modal.Header>
					<h5 className="mb20 text-center">
						Ask the customer to scan this QR code on the Samsung App
						to successsfully register the plan
					</h5>
				</Modal.Header>
				<Modal.Body className="qrBody">
					<QRCode
						size={150}
						value={
							paymentData?.PlanDetails?.[0]?.PlanActivationCode
						}
						//appname also required in value
						style={{ margin: "auto !important", display: "flex" }}
					/>
				</Modal.Body>
				<Modal.Footer className={"modal-footer-common"}>
					<div style={{ display: "block", alignItems: "center" }}>
						<h5 style={{ fontWeight: 600, textAlign: "center" }}>
							{paymentData?.PlanDetails?.[0]?.PlanActivationCode}
						</h5>
						<h5 className="mb10 mt10 text-center">
							Note : Please request Customer to complete the
							diagnosis process in 60 minutes.
						</h5>
					</div>
				</Modal.Footer>
			</Modal> */}
		</>
	);
};

export default ContractDetails;
