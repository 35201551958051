import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import {filter,debounce} from 'lodash';
import {returnLocalDateTime,displayError, checkProductUniqueID} from '../../../../Utility/Utils';
import ConsumerPlansDetails from './ConsumerPlansDetails';
import './ConsumerPlans.scss'

const ConsumerPlans = (props) => {
	const {localString, apiCall, consumerDetails, ConsumerID} = props;
	const [showLoader ,setLoader] = useState(false);
	const [salePlans, setSalePlans] = useState([])
	const [filterData, setFilterData] = useState({
		IMEI:""
	})
	const [labelsDetail, setLabelsDetail] = useState()
	const [planListSearch, setPlanListSearch] = useState("")
	const [pagination , setPagination] = useState({
		currentPage: 1,
		itemsPerPage: 15,
		maxPages: 5,
		totalItems:0,
		lastPage:0
	});
	const [firstCall, setFirstCall] = useState(true)
	const [searchBtnValidation, setSearchBtnValidation] = useState(true);
	const [showClearButton, setShowClearButton] = useState(false)

	useEffect(() => {
		getSalePlans()
	}, [])

	useEffect(() => {
		labelFunction()
	}, [salePlans])

	// useEffect(() => {
	//     getPlanListArr();
	// }, [planListSearch,salePlans])

	useEffect(() => {
		setPlanListSearch(filterData.IMEI)
		if(filterData.IMEI == ""){
			// getSalePlans()
			setShowClearButton(false)
		}else{
			setShowClearButton(true)
		}
		if(checkProductUniqueID(filterData.IMEI) && (filterData.IMEI.length >= 5)){
			setSearchBtnValidation(false)
		}
		else{
			setSearchBtnValidation(true)
		}
	}, [filterData.IMEI])

	const getSalePlans = (productUniqueID) => {
		let data = {
			FirstName:"",
			MobileNo: "",
			EmailID: "",
			ConsumerID : ConsumerID
		};
		if(productUniqueID){
			data.IMEI1 = productUniqueID
		}
		data.pagination = pagination;
		setLoader(true);
		apiCall({
			url:'getSoldPlanCustomersData',
			data: data,
			callBack:(result)=>{
				setLoader(false); 
				if(result.success && result.data){
					setSalePlans(result.data.PlanDetails);
					setPagination({...pagination,
						totalItems:result.data && result.data.pagination && result.data.pagination.totalItems,
						lastPage:result.data && result.data.pagination && result.data.pagination.maxPages
					});
					if(result.data.pagination && result.data.pagination.currentPage == 1){
						setFirstCall(false)
					}
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	// const searchPlanList = debounce((val)=>{
	//     setPlanListSearch(val)
	// }, 200);

	const filterInputs = [{
		labelText:localString('Search Plan By:'),
		dataKey:"IMEI",
		type:"input",
		showFilter:true,
		placeholder:localString('IMEI/Serial Number'),
		customClass:"search-wrapper col-xs-12 request",
		maxLength:"30",
		oninputFunc: (val) => {
			setFilterData({ ...filterData, ['IMEI'] : val  });
		}
	}]

	const headings = [{
			title:localString("Plan Name"),
			customClass:"col-sm-2 col-xs-12 c-centered medium-width"
		},{
			title:localString("IMEI/Serial Number"),
			customClass:"col-sm-2 col-xs-12 c-centered medium-width"
		},{
			title:localString("Contract ID"),
			customClass:"col-sm-2 col-xs-12 c-centered medium-width"
		},{
			title:localString("Purchase Date"),
			customClass:"col-sm-2 col-xs-12 c-centered"
		},{
			title:localString("Sales Channel"),
			customClass:"col-sm-2 col-xs-12 c-centered"
		},{
			title:localString("Status"),
			customClass:"col-sm-2 col-xs-12 c-centered medium-width"
		},{
			title:localString(""),
			customClass:"col-sm-2 col-xs-12 c-centered medium-width"
		}
	]

	const getPlanListArr =()=> {
		// if(salePlans && salePlans.length > 0) {
		//     // let filteredList = filter(salePlans,(obj)=> {
		//     //     if(planListSearch) {
		//     //         return obj && obj.ProductUniqueID && obj.ProductUniqueID.toLowerCase().indexOf(planListSearch.toLowerCase()) > -1;
		//     //     } else {
		//     //         return obj;
		//     //     }
		//     // });
		//     setPlanLabelsArray(filteredList)
		// }
		getSalePlans(planListSearch)
	};

	const setCurrentPage = (pageNo) => {
		setPagination({...pagination,['currentPage']:pageNo});
	}

	useEffect(() => {
		if(!firstCall){
			getSalePlans();
		}
	}, [pagination.currentPage]);

	const labelFunction=()=>{
		const defaultText= "-";
		let data = salePlans && salePlans.map(val=>{
			return [{
				show:true,
				value :(val && val.PlanName) || defaultText,
				customClass:"col-sm-4"
			},{
				show:true,
				value :(val && val.ProductUniqueID) || defaultText,
			},{
				show:true,
				value :(val && val.SoldPlanCode) || defaultText,
			},{
				show:true,
				value :(val && returnLocalDateTime(val.DateOfPurchase,"YYYY-MM-DD") ) || defaultText,
			},{
				show:true,
				value :(val && val.Source) || defaultText,
			},{
				show:true,
				value :val.StatusTextObject && val.StatusTextObject.displayText|| defaultText,
			},{
				show:false,
				SoldPlanID : val && val.SoldPlanID 
			}]
		})
		setLabelsDetail(data);
	}

	const clearButton = () => {
		setFilterData({IMEI:""})
		getSalePlans()
	}

	return (
		<>
			{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}  
			<ConsumerPlansDetails {...props} 
				filterInputs={filterInputs} 
				headings={headings} 
				planLabelsArray={labelsDetail} 
				filterData={filterData} 
				clearSearch={clearButton}
				pagination={pagination}    
				setCurrentPage={setCurrentPage}
				getSearchList={getPlanListArr}
				searchBtnValidation={searchBtnValidation}
				showClearButton={showClearButton}
			/>
		</>
	)
}

export default ConsumerPlans
