import React from 'react';
import {Table} from 'sfy-react';

const DeliveryAddressHeader = (props) => {
	const {consumerServiceRequest, localString, deliveryAddressHeader, AddressString, backupAddressString} = props;

    const classObject = {
		table:"table table-striped complaint-details-table request-table"
	}

    return (
        <div>
			<p style={{fontWeight: "700", fontSize: "18px"}}>{localString(deliveryAddressHeader)}</p>
            {
                consumerServiceRequest && 
                <Table className={classObject}>
                    <Table.Body>
                    {
                        consumerServiceRequest.consumer && 
                        <Table.Row>
                            <Table.Data className="table-keys text-capitalize">{localString("Name")}</Table.Data>
                            <Table.Data>{consumerServiceRequest.consumer.Name || 'NA'}</Table.Data>
                        </Table.Row>
                    }
                    {
                        consumerServiceRequest.consumer && 
                        <Table.Row>
                            <Table.Data className="table-keys text-capitalize">{localString("Contact Number")}</Table.Data>
                            <Table.Data>{consumerServiceRequest.consumer.MobileNo || 'NA'}</Table.Data>
                        </Table.Row>
                    }
                        <Table.Row>
                            <Table.Data className="table-keys text-capitalize">{localString("Address")}</Table.Data>
                            <Table.Data>{AddressString && AddressString.addressDisplayString ? AddressString.addressDisplayString : backupAddressString ? backupAddressString : 'NA'}</Table.Data>
                        </Table.Row>
                    </Table.Body>
                </Table>
            }
			
		</div>
    )
}

export default DeliveryAddressHeader
