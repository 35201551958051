import React, { useEffect, useState } from "react";
import { Table } from "sfy-react";
import useSortedData from "./useSortedData";
import ReassignModal from "./ReassignModal";
import ViewDataInModal from "./ViewDataInModal";
import { calculateAging } from "../../Utility/Utils";

import "./CaseManager.scss";

const Headings = [
	"Case No.",
	"Reference No.",
	"Claim Status",
	"Plan",
	"Product",
	"Aging",
	"Consumer",
	"Rules",
	"Score",
	"Case Status",
	"Assignee",
];

const ASC = 1;
const DESC = -1;

const AllTransactionsTable = (props) => {
	const { cases, localString, baseUrl } = props;

	const [showReassignModal, setShowReassignModal] = useState(false);
	const [sortedData, config, updateData] = useSortedData();
	const [reassignModalData, setReassignModalData] = useState({
		assigneeName: "",
		assigneeUserID: "",
		caseID: "",
		csrCountryID: "",
		soldPlanCountryID: "",
	});

	const closeModal = () => {
		setShowReassignModal(false);
		setReassignModalData({
			assigneeName: "",
			assigneeUserID: "",
			caseID: "",
			csrCountryID: "",
			soldPlanCountryID: "",
		});
	};

	useEffect(() => {
		if (cases)
			updateData({
				data: cases,
				config: {
					field: Headings[5],
					order: ASC,
				},
			});
	}, [cases]);

	return (
		<>
			{sortedData && sortedData.length > 0 ? (
				<Table isResponsive className={{ table: "custom-table" }}>
					<Table.Header>
						<Table.Row>
							{Headings.map((heading, index) => (
								<Table.Heading
									classObject={{
										header: ``,
										sortContainer: "justify-start",
									}}
									key={index}
									onClick={() => {
										if (
											![
												"Case No.",
												"Rules",
												"Reference No.",
											].includes(heading)
										) {
											let order =
												config.order === ASC
													? DESC
													: ASC;

											updateData({
												config: {
													field: heading,
													order,
												},
											});
										}
									}}
									showSortIcons={
										[
											"Case No.",
											"Rules",
											"Reference No.",
										].includes(heading)
											? false
											: true
									}
									activeSortIcon={
										heading === config.field &&
										(config.order === ASC
											? "up"
											: config.order === DESC && "down")
									}
								>
									{localString(heading)}
								</Table.Heading>
							))}
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{sortedData.map((caseData, i) => {
							return (
								<Table.Row
									key={i}
									className="custom-table-tr"
									otherProps={{
										onClick: () => {
											let url = `${baseUrl}/casemanager/view?caseid=${caseData.caseID}`;
											const newWindow = window.open(
												url,
												"_blank",
												"noopener,noreferrer"
											);
											if (newWindow)
												newWindow.opener = null;
										},
									}}
								>
									<Table.Data>
										<div className="case-reference-id-td">
											{/*<span
												className={`left-border ${
													i < 4
														? "bg-red"
														: i > 4 && i < 7
														? "bg-yellow"
														: "bg-green"
												}`}
											/>*/}
											<span>
												{caseData["caseReferenceID"]}
											</span>
										</div>
									</Table.Data>
									<Table.Data>
										<div className="flex-col">
											<span className="tablet-bg reference-bg">
												{caseData["soldPlanCode"]}
											</span>
											{caseData["referenceID"] && (
												<span
													onClick={(e) => {
														e.stopPropagation();
														let url = `${baseUrl}/servicerequests/view?csrid=${caseData.ConsumerServiceRequestID}`;
														const newWindow =
															window.open(
																url,
																"_blank",
																"noopener,noreferrer"
															);
														if (newWindow)
															newWindow.opener =
																null;
													}}
													className="tablet-bg reference-bg csr-link"
												>
													{caseData["referenceID"]}
												</span>
											)}
										</div>
									</Table.Data>
									<Table.Data>
										{caseData["claimStatus"]
											? localString(caseData["claimStatus"])
											: localString(caseData["soldPlanStatus"])}
									</Table.Data>
									<Table.Data>
										{caseData["planName"]}
									</Table.Data>
									<Table.Data>
										{caseData["productName"]}
									</Table.Data>
									<Table.Data>
										{calculateAging(
											caseData["aegingTimeStamp"]
										)}
									</Table.Data>
									<Table.Data>{caseData["name"]}</Table.Data>
									<Table.Data
										otherProps={{
											style: { overflow: "visible" },
										}}
									>
										{caseData.rules &&
										caseData.rules.length > 1 ? (
											<ViewDataInModal
												content={`${
													caseData.rules[0]["name"]
												} +${
													caseData.rules.length - 1
												}  `}
											>
												<>
													{caseData.rules.map(
														(rule, idx) =>
															idx > 0 && (
																<div>
																	{rule.name}
																</div>
															)
													)}
												</>
											</ViewDataInModal>
										) : caseData.rules &&
										  caseData.rules.length === 1 ? (
											caseData.rules[0]["name"]
										) : (
											"-"
										)}
									</Table.Data>
									<Table.Data>
										<span
											className={`tablet-bg ${
												parseInt(
													caseData["score"],
													10
												) > 0
													? "green-tablet"
													: "red-tablet"
											}`}
										>
											{caseData["score"]}
										</span>
									</Table.Data>
									<Table.Data>
										<span
											className={`tablet-bg ${
												parseInt(
													caseData["caseStatusID"],
													10
												) === 1
													? "blue-tablet"
													: parseInt(
															caseData[
																"caseStatusID"
															],
															10
													  ) === 2
													? "yellow-tablet"
													: "green-tablet"
											}`}
										>
											{localString(caseData["caseStatus"])}
										</span>
									</Table.Data>
									<Table.Data>
										<div className="assignee-td">
											<span>
												{caseData["assigneeName"]}
											</span>
											<span
												className="reassign-btn"
												style={{
													cursor:
														caseData.caseStatusID ===
														3
															? "not-allowed"
															: "pointer",
													opacity:
														caseData.caseStatusID ===
														3
															? "0.5"
															: "1",
												}}
												onClick={(e) => {
													if (
														caseData.caseStatusID !==
														3
													) {
														e.stopPropagation();
														setShowReassignModal(
															true
														);
														setReassignModalData({
															...caseData,
														});
													}
												}}
											>
												{localString("Reassign")}
											</span>
										</div>
									</Table.Data>
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			) : (
				<div className="text-center consumer-nodata">
					<div className="text-center requests-empty-state">
						{localString("No Data Available")}
					</div>
				</div>
			)}

			{showReassignModal && (
				<ReassignModal
					{...props}
					showModal={showReassignModal}
					closeModal={closeModal}
					setShowModal={setShowReassignModal}
					reassignModalData={reassignModalData}
					setReassignModalData={setReassignModalData}
				/>
			)}
		</>
	);
};

export default AllTransactionsTable;
