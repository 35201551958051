import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Loader, Button } from "sfy-react";
import Swal from "sweetalert2";

import RequestProofWrapper from "./RequestProofWrapper/RequestProofWrapper";
import AddRequestAdditionalDocuments from "./AddRequestAdditionalDocuments/AddRequestAdditionalDocuments";

import { displayError } from "../../Utility/Utils";

import "./RequestProof.scss";

const RequestProof = (props) => {
	const {
		localString,
		consumerServiceRequest,
		apiCall,
		updateConsumerServiceRequestStatus,
		accessFn,
	} = props;
	const [showLoader, setShowLoader] = useState(false);
	const [proofGroupListLoader, setProofGroupListLoader] = useState(false);
	const [pendingProofsListConsumer, setPendingProofsListConsumer] = useState(
		[]
	);
	const [pendingProofsListServiceCenter, setPendingProofsListServiceCenter] =
		useState(null);
	const [consumerProofGroupList, setConsumerProofGroupList] = useState([]);
	const [serviceCenterProofGroupList, setServiceCenterProofGroupList] =
		useState([]);

	const getProofsByGroup = (callBackTask) => {
		let data = {
			consumerServiceRequestID:
				consumerServiceRequest.ConsumerServiceRequestID,
			status: ["pending", "accepted", "verified", "rejected"],
		};

		setShowLoader(true);
		apiCall({
			url: "getProofsByGroup",
			data: data,
			callBack: (result) => {
				if (result.success && result.data) {
					setPendingProofsListServiceCenter(result.data);

					callBackTask && callBackTask(result.data);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}

				setShowLoader(false);
			},
		});
	};

	const getProofGroups = () => {
		setProofGroupListLoader(true);
		apiCall({
			url: "getProofGroups",
			data: {},
			callBack: (result) => {
				if (result.success) {
					if (result.data && result.data.length > 0) {
						let consumerDocumentList = result.data.filter(
							(d) => d.requiredFrom === "consumer"
						);

						let serviceCenterDocumentList = result.data.filter(
							(d) => d.requiredFrom === "serviceCenter"
						);

						setConsumerProofGroupList(
							consumerDocumentList.length > 0
								? consumerDocumentList.map((d) => ({
										...d,
										value: d.groupName,
								  }))
								: []
						);

						setServiceCenterProofGroupList(
							serviceCenterDocumentList.length > 0
								? serviceCenterDocumentList.map((d) => ({
										...d,
										value: d.groupName,
								  }))
								: []
						);
					}
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}

				setProofGroupListLoader(false);
			},
		});
	};

	const handleSubmitDocuments = (e) => {
		updateConsumerServiceRequestStatus("Proof submit", getProofsByGroup);
	};

	useEffect(() => {
		getProofsByGroup();
		getProofGroups();
	}, []);

	const isAnyProofUploadedForServiceCenter =
		pendingProofsListServiceCenter &&
		pendingProofsListServiceCenter.some(
			(doc) =>
				doc.consumerServiceRequestProof &&
				doc.consumerServiceRequestProof.length > 0 &&
				doc.consumerServiceRequestProof.some(
					(csrProof) =>
						csrProof.consumerServiceRequestProofRecords.length > 0
				)
		);

	return (
		<>
			{(showLoader || proofGroupListLoader) &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}
			<div className="rp-wrapper">
				<h1 className="rp-main-heading">
					{localString("Pending Documents - Service Center")}
				</h1>

				{pendingProofsListServiceCenter &&
				pendingProofsListServiceCenter.length > 0 ? (
					<RequestProofWrapper
						{...props}
						pendingProofsList={pendingProofsListServiceCenter}
						getProofsByGroup={getProofsByGroup}
						showLoader={showLoader}
						setShowLoader={setShowLoader}
					/>
				) : (
					pendingProofsListServiceCenter &&
					pendingProofsListServiceCenter.length === 0 && (
						<div className="paddingTB15 text-center">
							{localString("No Pending Document Specified")}
						</div>
					)
				)}

				{(accessFn("PROOF_ADJUDICATOR_VIEW") ||
					(!accessFn("PROOF_ADJUDICATOR_VIEW") &&
						consumerServiceRequest.Status ===
							"Service center document pending")) && (
					<>
						<div className="mb-15 mt-15">
							<AddRequestAdditionalDocuments
								{...props}
								documentsList={serviceCenterProofGroupList}
								getProofsByGroup={getProofsByGroup}
								showLoader={showLoader}
								setShowLoader={setShowLoader}
							/>
						</div>

						<div className="mb-15 mt-15">
							<Button
								classes="button-ghost button-small rp-btn rp-btn-width"
								isDisabled={
									consumerServiceRequest.Status !==
										"Service center document pending" ||
									!isAnyProofUploadedForServiceCenter
								}
								handleOnClick={handleSubmitDocuments}
							>
								{localString("Submit Documents")}
							</Button>
						</div>
					</>
				)}
			</div>

			{/*<div className="rp-wrapper">
				<h1 className="rp-main-heading">
					{localString("Pending Documents - Consumer")}
				</h1>

				{pendingProofsListConsumer &&
					pendingProofsListConsumer.length > 0 && (
						<RequestProofWrapper
							{...props}
							pendingProofsList={pendingProofsListConsumer}
							getProofsByGroup={getProofsByGroup}
							showLoader={showLoader}
							setShowLoader={setShowLoader}
						/>
					)}

				<div>
					<AddRequestAdditionalDocuments
						{...props}
						documentsList={consumerProofGroupList}
						getProofsByGroup={getProofsByGroup}
						showLoader={showLoader}
						setShowLoader={setShowLoader}
					/>
				</div>
			</div>*/}
		</>
	);
};

export default RequestProof;
