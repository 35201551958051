import React from 'react';
import CommunicationInput from './CommunicationInput';
const SMSDetails = (props)=>{
    const {smsLogData}=props
    return( 
        <>
            <CommunicationInput
                {...props}
                communicationData = {smsLogData}
            />
        </>
    )
}
export default  SMSDetails;