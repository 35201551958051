import React, { useState } from "react";
import { Modal } from "sfy-react";
import NonHardwareClosure from "./NonHardwareClosure";
import "./NonHardwareClosure.scss";

const NonHardwareClosureModal = (props) => {
	const { localString, showModal, closeModal } = props;

	const [inputStates, setInputStates] = useState({
		isNTFRepair: false,
		delivery_notes: "",
	});
	const [disabledState, setDisabledState] = useState({
		symptomcode: true,
		comptia_mod_Repair: true,
		issuecode: true
	});
	const [showSKUSelection, setShowSKUSelection] = useState(false);
	const [showSymptomsCode, setShowSymptomsCode] = useState(false);
	const [SKUSelected, setSKUSelected] = useState(false);
	const [selectedSKU, setSelectedSKU] = useState({});
	const [selectedSymptom, setSelectedSymptom] = useState({});
	const [compModItem, setCompModItem] = useState({});
	const [selectedIssue, setSelectedIssue] = useState({});

	const closeNonHardwareClosureModal = () => {
		closeModal();
		setInputStates({
			isNTFRepair: false,
			delivery_notes: "",
		});

		setDisabledState({
			issuecode: true,
			symptomcode: true,
			comptia_mod_Repair: true,
		});

		setShowSKUSelection(false);
		setShowSymptomsCode(false);
		setSKUSelected(false);
		setSelectedSKU({});
		setSelectedSymptom({});
		setCompModItem({});
		setSelectedIssue({});
	};

	return (
		<>
			<Modal
				showModal={showModal.showNonHardwareClosureModal}
				closeModal={closeNonHardwareClosureModal}
				showCloseBtn={true}
				className="Filter-Modal full-screen-modal"
			>
				<Modal.Header>
					<h2> {localString("Non Hardware Closure")} </h2>
				</Modal.Header>
				<Modal.Body>
					<NonHardwareClosure
						{...props}
						inputStates={inputStates}
						setInputStates={setInputStates}
						disabledState={disabledState}
						setDisabledState={setDisabledState}
						showSKUSelection={showSKUSelection}
						setShowSKUSelection={setShowSKUSelection}
						showSymptomsCode={showSymptomsCode}
						setShowSymptomsCode={setShowSymptomsCode}
						SKUSelected={SKUSelected}
						setSKUSelected={setSKUSelected}
						selectedSKU={selectedSKU}
						setSelectedSKU={setSelectedSKU}
						selectedSymptom={selectedSymptom}
						setSelectedSymptom={setSelectedSymptom}
						compModItem={compModItem}
						setCompModItem={setCompModItem}
						selectedIssue={selectedIssue}
						setSelectedIssue={setSelectedIssue}
						closeNonHardwareClosureModal={
							closeNonHardwareClosureModal
						}
					/>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
};

export default NonHardwareClosureModal;
