import React, { useState} from 'react';
import {Dropdown,Input,Button} from 'sfy-react';
import { displayError } from '../../../../../Utility/Utils';
import Swal from 'sweetalert2';
const IssueResolvedInput =(props)=>{
    const {apiCall,localString, closeModal,setLoader, selectResolvedResonCat,requestEnquiry,issueResolvedText ,setIssueResolvedText,issueReason,setIssueReason} = props;
    const [hideAdd,setHideAdd] = useState(false);
    
    const saveClick = ()=>{
        if(issueResolvedText){
            requestEnquiry();
            closeModal()
        }else{
            Swal.fire({
                title:   '',
                text:'Please enter the remarks',
                icon:'error'
            });
        }
    }

    // const requestEnquiry = ()=>{
    //     let newParams = {
    //         "ConsumerServiceRequestID":consumerServiceRequest.ConsumerServiceRequestID,
    //         "Remarks": issueResolvedText,
    //         "Status": nextStatus,
    //         "ServiceTypeID": consumerServiceRequest.ServiceTypeID,
    //         "Reason":issueReason
    //     };
    //     setLoader(true);
    //     apiCall({
    //         url: "enquiryRequest", 
    //         data: newParams,
    //         callBack: (result) => {
    //             setLoader(false);
    //             if (result.success) {
    //                 if(result.data){
    //                     getRequestById();
    //                 }
    //             } else {
    //                 Swal.fire({
    //                     title: 'Oops...',
    //                     text: localString(displayError(result)),
    //                     icon: 'error',
    //                 });
    //             }
    //         },
    //     });
    // }
    return (
		<>
            <div className='modal-body'>
                <div className='form-group'>
                    <p>{localString("Reason")}</p>
                    <Dropdown
                        id='dropdown'
                        value={issueReason}
                        options={selectResolvedResonCat}
                        placeholder = "Select a Reason"
                        handleOnChange={(data) => {
                            setIssueReason(data.Reason);
                            setHideAdd(true);
                        }}
                        showDownArrow={false}
                        filterBy="value"
                    />
                </div>
                <div className='textarea form-group'>
                    <p>{localString("Remarks")}</p>
                    <Input 
                        // className="form-control" 
                        inputComponent='textarea' 
                        value={issueResolvedText}
                        handleOnChange={(e) => {
                            setIssueResolvedText(e.target.value)
                        }}
                        classObject={{
                            container: 'issue-resolved',
                        }}
                    />
                </div>
                {
                    hideAdd &&  <div className="form-group">
                        <Button className=" btn button-servify issue-add-btn" handleOnClick={()=>saveClick()}>{localString("Save")}</Button>
                    </div>
                }
            </div>
            <div className="modal-footer">
                <Button id="edit-consumer-close" type="button" className="btn button-servify" handleOnClick={()=>closeModal()}>{localString("Close")}</Button>
            </div>
        </>
	)
}
export default IssueResolvedInput;
