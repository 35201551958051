import React, { useState } from 'react';
import {Modal,Button} from 'sfy-react';
import Swal from 'sweetalert2';
import { returnTimezone,displayError, SERVICETYPE } from '../../../../../Utility/Utils';

const BillingDetailInput=(props)=>{
    const {accessFn, localString, processRequest, changeBillingDetails, selectedBillingDetail, consumerServiceRequest, capturePaymentMode, claimNewWorkshopChecks,claimChecks,claimRequestNonWorkshop}=props;

    return(
        <div className='button-billing-modal-main'>
            <div className=''>
                <div className='col-sm-12 billing-modal-body'>
                    <b>{localString('Billing Address')}</b>
                    <p>{selectedBillingDetail && selectedBillingDetail.BillingName?selectedBillingDetail.BillingName:consumerServiceRequest && consumerServiceRequest.consumer && consumerServiceRequest.consumer.Name}</p>
                    {
                        selectedBillingDetail && selectedBillingDetail.TaxDetails && selectedBillingDetail.TaxDetails[0] && selectedBillingDetail.TaxDetails[0].TaxDetailValue &&
                        <p >GST Number - {selectedBillingDetail.TaxDetails[0].TaxDetailValue}</p>
                    }
                    <p className='address-p'>{selectedBillingDetail && selectedBillingDetail.Address?selectedBillingDetail.Address:consumerServiceRequest.Address} {selectedBillingDetail && selectedBillingDetail.Landmark?selectedBillingDetail.Landmark:consumerServiceRequest.Landmark} - {selectedBillingDetail && selectedBillingDetail.PinCode?selectedBillingDetail.PinCode:consumerServiceRequest.Zipcode}</p> 
                </div>
            </div>
            <div className = ''>
                {/* <div style={{padding:"10px 0 0", display:"inline-block"}}>
                    <Button className='btn button-servify'  handleOnClick = {()=>changeBillingDetails()} >{localString('Change Billing Address')}</Button>
                    {   claimChecks 
                        ?  <Button  className='btn button-servify'  handleOnClick = {(e)=>claimRequestNonWorkshop('Payment received')} >{localString('Continue')}</Button>
                        :  <Button  className='btn button-servify'  handleOnClick = {(e)=>claimNewWorkshopChecks ? capturePaymentMode() :processRequest('Generate Invoice')} name='Generate Invoice' >{localString('Continue')}</Button>
                    }
                </div> */}
            </div>
            
        </div>
    )
}
export default BillingDetailInput;