import React from 'react'
import {Modal, Dropdown, Button} from 'sfy-react';
import './LoanerCancelModal.scss'

const LoanerCancelModal = (props) => {
    const {loanerCancelModal, loanerCancelStatusList, localString, cancelModalStatus, setCancelModalStatus, closeCancelModal, cancelLoaner,
        selectedReason,
    } = props;

    return (
        <div className="">
            <Modal showModal={loanerCancelModal} closeModal={closeCancelModal} showCloseBtn={true} className="filtermodal loaner-cancel-modal" id="loaner_cancel_modal">
				<Modal.Header>
					<h4> {localString("Cancel Loaner Request")} </h4>
				</Modal.Header>
				<Modal.Body>
                    <div className="">
                        <div className="col-sm-12">
                            <form name="">
                                <div className="">
                                    <div className="col-sm-12" style={{marginLeft:"6px", marginBottom: "20px"}}>
                                        <label> {localString("Select Reason")}</label>
                                            <Dropdown
                                                id='cancelReasonList'
                                                name="cancelReasonList"
                                                value={cancelModalStatus}
                                                options={loanerCancelStatusList}
                                                handleOnChange={(data) => {
                                                    setCancelModalStatus(data.CancelReason)
                                                }}
                                                filterBy='value'
                                                showDownArrow={false}
                                                placeholder={localString("Select Reason")}
                                            ></Dropdown>
                                    </div>
                                </div>
                                <div style={{marginTop: "10px"}}>
                                    <Button handleOnClick={closeCancelModal} type='button' classes='button-ghost btn button-servify pull-right' text={localString('Cancel')}/>
                                    <Button handleOnClick={cancelLoaner} isDisabled={!selectedReason} type='submit' classes='button-ghost btn button-servify pull-right' text={localString('Save')}/>
                                </div>
                            </form>
                        </div>
                    </div>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
        </div>
    )
}

export default LoanerCancelModal
