import React from 'react';
import {Dropdown,Input,Button} from 'sfy-react';
const FlagFraudUI =(props)=>{
    const {localString,flagFraudInputs, flagFraud,setFlagFraud,addedFraudFlag,saveFraudFlag,deleteFraudFlag,disableFlagAddUpdateButton} = props;
		return (
		<>
            <div className='modal-body clearfix'>
				<div className='col-sm-12 fraud-border'>
					<div className='fraud-text'>Fraudulent Claims Tracker</div>
					<div className='col-sm-12 fraud-outer-padding'>
						{
                            flagFraudInputs && flagFraudInputs.length>0 && flagFraudInputs.map(item=>(
                                item.showFlagFraud &&<div className={item.customClass}>
                                {item.type=='Dropdown'  && <Dropdown
                                placeholder={item.placeholder}
                                options={item.dropdownList}
                                value={flagFraud[item.dataKey]}
                                handleOnChange={(data) => {
                                    item.oninputFunc(data);
                                }}
                                filterBy='value'
                                showDownArrow={false}
                            />}{
                                item.type=='input'  && <Input
                                    value={flagFraud[item.dataKey]}
                                    handleOnChange={(e) => {
                                        item.oninputFunc(e.target.value);
                                    }}
                                    labelAlign='top'
                                    isDisabled={item.isDisabled}
                                    maxLength={item.maxLength}
                                    placeholder={item.placeholder}
                                />
                            }
                            </div>
                            ))
                        }
                        {
                            !addedFraudFlag || !addedFraudFlag.flagID && <div className='col-sm-3' >
                                <Button className='btn button-servify' handleOnClick={()=>saveFraudFlag()} isDisabled={disableFlagAddUpdateButton}>{localString('Add')}</Button>
                            </div>
                        }
                        {
                            addedFraudFlag && addedFraudFlag.flagID && <div className='col-sm-3'>
                                <Button className='btn button-servify' handleOnClick={()=>saveFraudFlag()} isDisabled={disableFlagAddUpdateButton}>{localString('Save')}</Button>
                                <Button className='btn button-servify' handleOnClick={()=>deleteFraudFlag()}>{localString('Delete')}</Button>
                            </div>
                        }
					</div>
				</div>
			</div>
        </>
	)
}
export default FlagFraudUI;


