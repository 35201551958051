import React, { useState, useEffect } from "react";
import { DatePicker } from "sfy-react";
// import "./PageHeader.scss";
import moment from 'moment';
const DateRangePickerWrapper = (props) => {
	const { 
		handleDateRangeApply, date, dateRange, setDateRange, dateClass, dateWidthClass, customFormat, 
		customPlaceholder, minDate, calendarFooter = <></>, showFilterBtn = true
	} = props;

	return (
		<div className={dateClass ? dateClass:"dashboard-filter"}>
			<div className="row">
				<div className={dateWidthClass ? dateWidthClass:"col-sm-3 col-sm-offset-6 pull-right"}>
					<DatePicker
						placeholder={ customPlaceholder ? customPlaceholder : "DD-MM-YYYY"}
						format={customFormat ? customFormat : "DD-MM-YYYY"}
						dateRange={dateRange || " "}
						handleOnChange={(val) => {
							console.log("val=====",val)
							setDateRange(val);
						}}
						minDay={minDate ? new Date(minDate) : ''}
						maxDay={date ? new Date(date) : ''}
						showDateRange={true}
						showPreDefinedRange={true}
						showFilterBtn={showFilterBtn}
						//isDisabled={new Date()+5}
						onFilterBtnClick={handleDateRangeApply}
						calendarFooter={calendarFooter}
					/>
				</div>
			</div>
		</div>
	);
};

export default DateRangePickerWrapper;
