import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'sfy-react';
import Swal from 'sweetalert2';

import { displayError, regexTest } from '../../../Utility/Utils';

export default function PlanActivation(props) {
	const { 
        accessFn, apiCall, localString, setLoader, consumerProduct, plan, setPlan, showResendAction, setShowClaimForm,
		hideSection
    } = props;
	const [showOrderIdModal, setShowOrderIdModal] = useState(false);
	const [activationCode, setActivationCode] = useState('');
	const [orderId, setOrderId] = useState('');
	const [orderIdError, setOrderIdError] = useState(null);
	const [activationCodeError, setActivationCodeError] = useState(null);
    const disableBtn = !orderId || (orderId && (orderId.length < 15 || orderId.length > 30));
    const disableActivationCodeBtn = !activationCode || (activationCode && !regexTest('alphanumeric', activationCode));

    const resendActivationCode = () => {
        console.log('resendActivationCode');
        setLoader(true);
		let reqObj = {
            OrderID: orderId
		}
        setShowOrderIdModal(false);
		apiCall({
			url: 'resendActivationCode',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success){
					//
				}else { 
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
    }

    const orderIdChange = (e) => {
        console.log('orderIdChange');
        let val = e.target.value // .replace(/^[\w\-\s]+$/gi, '');
        setOrderId(val);
        if(!val) {
            setOrderIdError(localString('Please enter Order id'));
        }else if(val && (val.length < 15 || val.length > 30)) {
            setOrderIdError(localString('Order id has to be between 15 - 30 characters'));
        }else{
            setOrderIdError(null);
        }
    }

    const submitActivationCode = () => {
        console.log('submitActivationCode');
		hideSection(['ClaimForm', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
        let reqObj = {
            "ConsumerProductID": consumerProduct.ConsumerProductID,
            "ConsumerID": consumerProduct.ConsumerID,
            "ActivationCodes": [{
                "ActivationCode": activationCode,
                "Group": plan.Group,
                "Status": 1
            }],
            "Status": 1
		}
		apiCall({
			url: 'verifyAndUpdateSoldPlan',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success){
                    let planObj = {...plan};
                    planObj.Status = 1;
                    setPlan(planObj);
					setShowClaimForm(true);
				}else { 
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});

    }

    const handleActivationCodeChange = (e) => {
        let val = e.target.value // .replace(/^[a-zA-Z0-9_]*$/gi, '');
        setActivationCode(val);
        if(!val) {
            setActivationCodeError(localString('Please enter the Activation Code'));
        }else if(val && regexTest('alphanumeric', val)) {
            setActivationCodeError(localString('Please enter the valid Activation Code'));
        }else {
            setActivationCodeError(null);
        }
    }

	return (
		<div id='Activation' className="raiseReqSection">
			<div className='planActivation'>						
				<div className="row">
                    <div className="col-sm-12 form-label" style={{ marginBottom: '15px' }}>
							<label>{localString("Enter Activation Code")}</label>
							{
								showResendAction && 
									<Button 
										className="btn button-servify pull-right" 
										onClick={() => setShowOrderIdModal(true)}
									>
										{localString("Resend Activation Code")}
									</Button>
							}
						</div>
				</div>
				<div className='form-group'>
                    <Input
                        value={activationCode}
                        handleOnChange={handleActivationCodeChange}
                        maxLength={8}
                        className={{
                            containerClass: 'input-container',
                            inputClass: 'input'
                        }}
                    />
                    {activationCodeError && <span className="text-danger">{activationCodeError}.</span>}
				</div>
				<div className="text-right">
					<Button
						className="btn button-servify nextBtn" 
						onClick={() => submitActivationCode()}
                        isDisabled={disableActivationCodeBtn}
					>
						<div className="button-next"></div>
					</Button>
				</div>
				
			</div>
            <Modal
				className='addOrderId'
				showModal={showOrderIdModal}
				closeModal={() => setShowOrderIdModal(false)}
				showCloseBtn={true}
			>
				<Modal.Header>
					<h4 id="myModalLabel" className="modal-title title-spacing">
						<strong>{localString("Order ID Details")}</strong>
					</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-body">
                        <div className="form-group">
                            <label>{localString("Enter Order ID")}:</label>
                            <Input
                                value={orderId}
                                handleOnChange={orderIdChange}
                                maxLength={'30'}
                                minLength={'15'}
                                className={{
                                    containerClass: 'input-container',
                                    inputClass: 'input'
                                }}
                            />
                            {orderIdError && <span className="text-danger">{orderIdError}.</span>}
                        </div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="">
						<Button 
							className="btn button-servify-solid pull-right" 
							onClick={() => resendActivationCode()}
							isDisabled={disableBtn}
						>
							{localString("Save")}
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
}