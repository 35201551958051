import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import {findIndex} from 'lodash';
import {displayError} from '../../../../../Utility/Utils';
import AssignWorkshop from './AssignWorkshop';
import './Assign.scss'
import AssignNonWorkshop from './AssignNonWorkshop';

const Assign = (props) => {
	const {
		localString, accessFn, baseUrl, hideAssignOption, deviceAtLoggedInLoc, assignObjCheck, consumerServiceRequest, 
		apiCall, serviceLocationData, getRequestById, qFilter, appleDropOffFlow
	} = props;
	const [loadEngineersCalled, setLoadEngineersCalled] = useState(false);
	const [engineerList, setEngineerList] = useState([])
	const [serviceLocation, setServiceLocation] = useState([])
	const [engineerDetails, setEngineerDetails] = useState({
		name:"",
		serviceLocationEnggID:""
	})
	const [loader, setLoader] = useState(false);
	const [disableAssign, setDisableAssign] = useState(true)
	const [selectServiceLocation,setSelectServiceLocation] = useState([]);
	const [serviceLocationObj, setServiceLocationObj] = useState({
		partnerServiceLocationID:'',
		serviceLocationName:''
	})
	useEffect(() => {
		const STID = consumerServiceRequest && consumerServiceRequest.ServiceTypeID;
		if(accessFn('WORKSHOP_FLOW') && !hideAssignOption && (consumerServiceRequest &&  consumerServiceRequest.ConsumerServiceRequestID) && (assignObjCheck && assignObjCheck.showengineertypeahead && assignObjCheck.showassignmenttag) && !loadEngineersCalled && (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && accessFn('REQUEST_ASSIGN')){
			const PSLID = consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID;
			const CSRID = consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID;
			loadEngineers(PSLID, CSRID, STID);
		}

		const BrandID = consumerServiceRequest && consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.BrandID : null;
		const ProductID = consumerServiceRequest && consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.ProductID : null;
		// loadServiceLocation(STID,BrandID,ProductID);
	}, [consumerServiceRequest,assignObjCheck])

	useEffect(() => {
		if(engineerDetails && engineerDetails.name && engineerDetails.name.length > 0){
			setDisableAssign(false)
		}
	}, [engineerDetails])

	useEffect(() => {
		if(serviceLocationObj && serviceLocationObj.serviceLocationName && serviceLocationObj.serviceLocationName.length > 0){
			setDisableAssign(false)
		}
	}, [serviceLocationObj.serviceLocationName])

	useEffect(()=>{
		initServiceLocation(serviceLocationData);
	},[serviceLocationData])

	const initServiceLocation =(serviceLocationData)=>{
		let serviceLocation = serviceLocationData && serviceLocationData.length && serviceLocationData.map(v=>({...v,value:v.ServiceLocationName}));
		setSelectServiceLocation(serviceLocation);
	}
	const showEngineerAssignSection = () => {
		return consumerServiceRequest && ["Parts issued", "Parts received", "Parts rejected"].indexOf(consumerServiceRequest.Status) == -1 && (consumerServiceRequest.RepairCompletionType != "Non Hardware Closure");
	};

	const loadEngineers = (PartnerServiceLocationID, ConsumerServiceRequestID, ServiceTypeID) => {
		setLoadEngineersCalled(true);
		setEngineerDetails({
			name:"",
			serviceLocationEnggID:"",
			PartnerServiceLocationID:"",
		})
		if (!PartnerServiceLocationID || PartnerServiceLocationID == 0) {
			return;
		}
		if (!ConsumerServiceRequestID || ConsumerServiceRequestID == 0) {
			return;
		}
		if (!ServiceTypeID || ServiceTypeID == 0) {
			return;
		}
		let params = {
			PartnerServiceLocationID: PartnerServiceLocationID,
			ConsumerServiceRequestID: ConsumerServiceRequestID,
			ServiceTypeID: ServiceTypeID
		};
		if(consumerServiceRequest) {
			params.BrandID = consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.BrandID : undefined;
			params.ProductSubCategoryID = consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.ProductSubCategoryID : undefined;
			params.Source = consumerServiceRequest.Source;
			params.Zipcode = consumerServiceRequest.Zipcode;
			if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL && (consumerServiceRequest.CREATED_AT_DROPOFF_PSL == 'true') && accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')) {
				params.IsRequestCreatedAtDropOffPSL = true;
			}
		}
		setLoader(true);
		apiCall({
			url: 'getEngineersByServiceLocations',
			data: params,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let enggList = result.data && result.data.map(v=>({...v,value:v.Name}));
					setEngineerList(enggList);

					// if(self.parent.tags['onsite_req']){
					//     self.parent.tags['onsite_req'].engineerData = self.EngineersData
					// }
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const isDiagnosisCompleted = () => {
		let diagInit = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis initiated'});
		let diagComp = findIndex(consumerServiceRequest.logs, {Status: 'Diagnosis completed'});
		let cannotDiag = findIndex(consumerServiceRequest.logs, {Status: 'Cannot be diagnosed'});

		if (diagInit != -1) {
			if (diagComp != -1 && cannotDiag != -1) {
				if(diagComp < cannotDiag){
					return diagComp < diagInit;
				} else {
					return cannotDiag < diagInit;
				}
			}
			else if (diagComp != -1) {
				return diagComp < diagInit;
			}
			else if (cannotDiag != -1) {
				return cannotDiag < diagInit
			}
			return false
		}
		return true
	}

	const disableAssignEngineer = () => {
		if (accessFn('GSX_FLOW') && !isDiagnosisCompleted()){
			return true;
		}
		if (assignObjCheck.showservicelocationlabel === true) {
			return false;
		}
		if (assignObjCheck.showengineertypeahead ) {//&& $('#servicelocation').val() !== ''
			return false;
		}
		return true;
	}

	const loadServiceLocation = (ServiceTypeID, BrandID, ProductID) => {
		let ProductSubCategoryID;
		if (ServiceTypeID && BrandID) {
			let params = {
				ServiceTypeID: ServiceTypeID,
				BrandID: BrandID,
				ProductSubCategoryID: (consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.ProductSubCategoryID) ? consumerServiceRequest.consumerProduct.ProductSubCategoryID : undefined
			};
			if (consumerServiceRequest.showEngineerVisits) {
				if (consumerServiceRequest.consumerProduct) {
					params.ProductSubCategoryID = consumerServiceRequest.consumerProduct.ProductSubCategoryID
					setLoader(true);
					let url = baseUrl + '/api/v1/ServicelocationEngineer/getLocationsByServiceTypeBrandAndSubcategory'
					fetch(url, {
						method: 'POST',
						body: JSON.stringify(params)
					})
					.then(res => res.json())
					.then(result => {
						setLoader(false);
						if(result.success && result.data) {
							let ServiceLocationData = result.data;
							setServiceLocation(ServiceLocationData)
						}else {
							Swal.fire({
								icon: 'error',
								text: localString(displayError(result)),
							});
						}
					})
				} else {

				}
			}else {
				if (consumerServiceRequest.consumerProduct) {
					params.ProductID = consumerServiceRequest.consumerProduct.ProductID
					setLoader(true);
					apiCall({
						url:'getServiceLocations',
						data: params,
						callBack:(result)=>{
							setLoader(false);
							if(result.success){
								let LocationData = result.data;
								setServiceLocation(LocationData);
							}else{
								Swal.fire({
									icon: 'error',
									text: localString(displayError(result)),
								});
							}
						}
					});
				}
			}
		}
	}

	const assignRequest = () => {
		let assignObj = {};
		const PSL = consumerServiceRequest && consumerServiceRequest.PartnerServiceLocationID;
		assignObj.ConsumerServiceRequestID = consumerServiceRequest.ConsumerServiceRequestID;
		assignObj.ServiceTypeID = consumerServiceRequest.ServiceTypeID;
		assignObj.PartnerServiceLocationID = serviceLocationObj && serviceLocationObj.partnerServiceLocationID ? serviceLocationObj.partnerServiceLocationID : PSL;
		if(accessFn('WORKSHOP_FLOW')){
			assignObj.isReassign = 1;
		}
		// if([3,5,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID)>-1){
		//     //add reason in assignObj
		//     self.reason_text = '';
		//     $('#reassign-text').val('');
		// }

		//if service location has label then check only engineer value is present or not
		// if service location has input then check if both engineer and service location has been assigned
		if (((assignObjCheck.showservicelocationlabel) || ((serviceLocationObj.serviceLocationName) && !consumerServiceRequest.showEngineerVisits) || ((assignObjCheck.showservicelocationlabel && engineerDetails.name) || (serviceLocationObj.serviceLocationName && engineerDetails.name)))) { //(('#servicelocation').val() !== '') &&
			if(engineerDetails && engineerDetails.serviceLocationEnggID){
				assignObj.ServiceLocationEngineerID = engineerDetails && engineerDetails.serviceLocationEnggID;
			}
			let url = 'ConsumerAssignServiceRequestToEngineer';
			if(consumerServiceRequest.CREATED_AT_DROPOFF_PSL === "true" && accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')){
				assignObj.TransferredServiceLocationID = engineerDetails && engineerDetails.PartnerServiceLocationID;
				url  = 'assignDropOffRequestToServiceEngineer';
			}
			setLoader(true);
			apiCall({
				url: url,
				data: assignObj,
				callBack:(result)=>{
					setLoader(false);
					if (result.success && result.data) {
						let consumerServiceRequest = result.data;
						let ServiceLocation = consumerServiceRequest.servicelocation;
						if(appleDropOffFlow && accessFn('DROPOFF_CUSTOMER_CARE_EXECUTIVE')) {
							setEngineerDetails({
								name:"",
								serviceLocationEnggID:"",
								PartnerServiceLocationID:"",
							})
						}
						if(consumerServiceRequest){
							if (!consumerServiceRequest.servicelocation && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID && consumerServiceRequest.consumerProduct.ProductID) {
								loadServiceLocation(
									consumerServiceRequest.ServiceTypeID,
									consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.BrandID : null,
									consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.ProductID: null
								);
							}
							if(accessFn("WORKSHOP_FLOW")){
								if (consumerServiceRequest.PartnerServiceLocationID && consumerServiceRequest.ServiceLocationEngineerID) {
									loadEngineers(
										consumerServiceRequest.PartnerServiceLocationID,
										consumerServiceRequest.ConsumerServiceRequestID,
										consumerServiceRequest.ServiceTypeID
									);
								}
							}else{
								if (consumerServiceRequest.PartnerServiceLocationID && !consumerServiceRequest.ServiceLocationEngineerID) {
									loadEngineers(
										consumerServiceRequest.PartnerServiceLocationID,
										consumerServiceRequest.ConsumerServiceRequestID,
										consumerServiceRequest.ServiceTypeID
									);
								}
							}
							
						}
						getRequestById();
					} else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
						getRequestById();
					}
					setDisableAssign(true)
				}
			});
		} else {
			setLoader(true);
			apiCall({
				url: 'assignServiceRequestToServiceLocation',
				data: assignObj,//JSON.stringify(assignObj)
				callBack:(result)=>{
					setLoader(false);
					if (result.success) {
						let consumerServiceRequest = result.data;
						let ServiceLocation = consumerServiceRequest.servicelocation;

						if (!consumerServiceRequest.servicelocation && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.BrandID && consumerServiceRequest.consumerProduct.ProductID) {
							loadServiceLocation(
								consumerServiceRequest.ServiceTypeID,
								consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.BrandID : null,
								consumerServiceRequest.consumerProduct ? consumerServiceRequest.consumerProduct.ProductID: null
							);
						}
						if(accessFn("WORKSHOP_FLOW")){
							if (consumerServiceRequest.PartnerServiceLocationID && consumerServiceRequest.ServiceLocationEngineerID) {
								loadEngineers(
									consumerServiceRequest.PartnerServiceLocationID,
									consumerServiceRequest.ConsumerServiceRequestID,
									consumerServiceRequest.ServiceTypeID
								);
							}
						}else{
							if (consumerServiceRequest.PartnerServiceLocationID && !consumerServiceRequest.ServiceLocationEngineerID) {
								loadEngineers(
									consumerServiceRequest.PartnerServiceLocationID,
									consumerServiceRequest.ConsumerServiceRequestID,
									consumerServiceRequest.ServiceTypeID
								);
							}
						}
						getRequestById();
					}else{
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result.msg)),
						});
						getRequestById();
					}
					setDisableAssign(true)
				}
			});
		}
	}

	return (
		<>
			{ loader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}

			{
				//(accessFn('REFURBISHMENT_MANAGER')) && ((accessFn('WORKSHOP_FLOW') &&  !hideAssignOption && deviceAtLoggedInLoc && (accessFn('ACCEPT_RETURN_DEVICE') || accessFn('REASSIGN_JOBS')) && !([3,5,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1)) || (!hideAssignOption && ([3,5,6,7,61,62].indexOf(consumerServiceRequest.ServiceTypeID) > -1) && !(JSON.parse(qFilter).flowType && JSON.parse(qFilter).flowType.fulfilmentFlow && JSON.parse(qFilter).flowType.fulfilmentFlow.indexOf('REQ_FUL_ONEPLUS') >-1) && !(['CprDashboard'].indexOf(consumerServiceRequest.Source) > -1))) &&
				accessFn('WORKSHOP_FLOW') &&
				<AssignWorkshop
					{...props}
					showEngineerAssignSection = {showEngineerAssignSection}
					engineerList={engineerList} assignRequest={assignRequest}
					engineerDetails={engineerDetails} setEngineerDetails={setEngineerDetails}
					disableAssignEngineer={disableAssignEngineer}
					disableAssign={disableAssign}
					selectServiceLocation={selectServiceLocation}
					serviceLocationObj = {serviceLocationObj}
					setServiceLocationObj = {setServiceLocationObj}
				/>
			}
			{
				(!accessFn('WORKSHOP_FLOW') && assignObjCheck && assignObjCheck.showassignmenttag && accessFn('REQUEST_ASSIGN')) &&
				<AssignNonWorkshop
					{...props}
					showEngineerAssignSection = {showEngineerAssignSection}
					engineerList={engineerList} assignRequest={assignRequest}
					engineerDetails={engineerDetails} setEngineerDetails={setEngineerDetails}
					disableAssignEngineer={disableAssignEngineer}
					disableAssign={disableAssign}
					selectServiceLocation={selectServiceLocation}
					serviceLocationObj = {serviceLocationObj}
					setServiceLocationObj = {setServiceLocationObj}
					loadEngineers={loadEngineers}
					consumerServiceRequest={consumerServiceRequest}
				/>
			}

		</>
	)
}

export default Assign
