import React from 'react';
import Swal from 'sweetalert2';
import { Button, Modal } from 'sfy-react';

import './InstructionModal.scss'
import { SERVICETYPE } from '../../../Utility/Utils';

export default function InstructionModal(props) {
	const {
		localString, showInstructionsModal, setShowInstructionsModal, dropOffInstructionsTitle, instructionsArray,
		serviceMode, serviceLocation, getScheduleDateAndSlot, serviceAvailabilityData, setScheduleDateArray, walkIn,
		setScheduleSlotArray, setSlotAvailable, setScheduleDate, setSlot, setShowSlotSelection, checkUpdateConsumerProduct,
		isEmptyBoxServiceable, slotsNext, type = 'raiseRequest', setActiveStep, setActiveStepIndex, setCallRequestFunction,isSupressLogisticsCutomerToASC
	} = props;

	const instructionClick = () => {
		setShowInstructionsModal(false);
		if ([SERVICETYPE.DROPOFF, SERVICETYPE.CLAIM_DROPOFF].indexOf(serviceMode.ServiceTypeID) > -1) {
			if(type == 'raiseRequest') {
				slotsNext();
			}else {
				setCallRequestFunction(true);
			}
			// if(isEmptyBoxServiceable || serviceMode.logisticsSuppressed || isSupressLogisticsCutomerToASC){
			// 	if(type == 'raiseRequest') {
			// 		slotsNext();
			// 	}else {
			// 		setCallRequestFunction(true);
			// 	}
			// }else {
			// 	let scheduleDateList = [];
			// 	let scheduleSlotList = [];
			// 	if (serviceLocation && serviceLocation.WorkingHours && Object.keys(serviceLocation.WorkingHours).length > 0) {
			// 		scheduleDateList = serviceLocation.WorkingHours;
			// 		setScheduleDateArray(scheduleDateList);
			// 	}else {
			// 		scheduleDateList = serviceAvailabilityData.dropOffVendorList[0].slots;
			// 		setScheduleDateArray(scheduleDateList);
			// 	}
			// 	if(scheduleDateList) {
			// 		let selectedScheduleDate;
			// 		let selectedSlot = undefined;
			// 		for (let i = scheduleDateList.length - 1; i >= 0; i--) {
			// 			if (scheduleDateList[i].slots && (scheduleDateList[i].slots.length > 0)) {
			// 				let slotArray = scheduleDateList[i].slots;
			// 				for (let j = slotArray.length - 1; j >= 0; j--) {
			// 					if (slotArray[j].IsActive) {
			// 						selectedScheduleDate = scheduleDateList[i];
			// 					}
			// 				}
			// 			}
			// 		}
			// 		setScheduleDate(selectedScheduleDate);
			// 		if(selectedScheduleDate) {
			// 			scheduleSlotList = selectedScheduleDate.slots;
			// 			setScheduleSlotArray(scheduleSlotList);
			// 			let slotAvailableFlag = false;
			// 			for (let i = scheduleSlotList.length - 1; i >= 0; i--) {
			// 				if (scheduleSlotList[i].IsActive) {
			// 					slotAvailableFlag = true;
			// 					if(walkIn){
			// 						selectedSlot = scheduleSlotList[i];
			// 						setShowSlotSelection(false);
			// 						checkUpdateConsumerProduct && checkUpdateConsumerProduct();
			// 						return;
			// 					}
			// 				}
			// 			}
			// 			setSlot(selectedSlot);
			// 			setSlotAvailable(slotAvailableFlag);
			// 			setShowSlotSelection(true);
			// 			setActiveStepIndex && setActiveStepIndex((state) => state + 1);
			// 			setActiveStep && setActiveStep('dateAndSlotSelection')
			// 		}else {
			// 			Swal.fire({  
			// 				icon: 'error',  
			// 				text: localString('No slots available'),  
			// 			});
			// 		}
			// 	}
			// }
		}else {
			if(serviceMode.logisticsSuppressed || isSupressLogisticsCutomerToASC){
				slotsNext();
			}else{
				getScheduleDateAndSlot()
			}
		}
		//self.showAddSecondryAddress = true;
	}

	return (
		<>
			<Modal
				className='instructionModal'
				showModal={showInstructionsModal}
				closeModal={() => setShowInstructionsModal(false)}
				showCloseBtn={true}
			>
				<Modal.Header>
					<h4 className="modal-title">{localString("Instructions")}</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-body">
						<div className="row">
							<div className="col-sm-12">
								<div className="mb20 modal-subtitle">
									{dropOffInstructionsTitle && dropOffInstructionsTitle}
								</div>
								{
									instructionsArray && instructionsArray.length > 0 && instructionsArray.map((obj, k) => 
										<div key={k} className="mb10">
											<div className="content-points">
												{obj.text}
											</div>
										</div>
									)
								}
								
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<hr />
					<Button 
						className="btn button-servify pull-right" 
						onClick={() => instructionClick()}
					>
						{localString("Okay, Got it")}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}