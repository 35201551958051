import React from "react";
import { returnLocalDateTime } from "../../../../Utility/Utils.js";
import ShadowWrapper from "../../ShadowWrapper";

import "./DocumentCard.scss";

const EyeIcon = (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="eye-icon"
	>
		<path
			d="M8.00006 12.48C12.1716 12.48 15.4727 8.37346 15.6109 8.19874L15.7681 8.00002L15.6109 7.8013C15.4727 7.62658 12.1716 3.52002 8.00006 3.52002C3.82854 3.52002 0.527416 7.62658 0.389176 7.8013L0.232056 8.00002L0.389176 8.19874C0.527416 8.37346 3.82854 12.48 8.00006 12.48ZM8.00006 4.16002C11.3581 4.16002 14.2305 7.18722 14.9409 8.00002C14.2314 8.81346 11.3629 11.84 8.00006 11.84C4.64198 11.84 1.76966 8.81282 1.05926 8.00002C1.7687 7.18658 4.63718 4.16002 8.00006 4.16002Z"
			fill="#410099"
		/>
		<path
			d="M8 10.8801C9.58816 10.8801 10.88 9.58796 10.88 8.00012C10.88 6.41228 9.58816 5.12012 8 5.12012C6.41184 5.12012 5.12 6.41228 5.12 8.00012C5.12 9.58796 6.41184 10.8801 8 10.8801ZM8 5.76012C9.23488 5.76012 10.24 6.76492 10.24 8.00012C10.24 9.23532 9.23488 10.2401 8 10.2401C6.76512 10.2401 5.76 9.23532 5.76 8.00012C5.76 6.76492 6.76512 5.76012 8 5.76012Z"
			fill="#410099"
		/>
	</svg>
);

const DocumentIcon = (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13.0966 13.5193H7.07996"
			stroke="#410099"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.0966 10.0308H7.07996"
			stroke="#410099"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M9.37579 6.55005H7.07996"
			stroke="#410099"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M13.2572 2.2915C13.2572 2.2915 6.85966 2.29484 6.84966 2.29484C4.54966 2.309 3.12549 3.82234 3.12549 6.13067V13.794C3.12549 16.114 4.56049 17.6332 6.88049 17.6332C6.88049 17.6332 13.2772 17.6307 13.288 17.6307C15.588 17.6165 17.013 16.1023 17.013 13.794V6.13067C17.013 3.81067 15.5772 2.2915 13.2572 2.2915Z"
			stroke="#410099"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const DocumentCard = (props) => {
	const { localString, doc } = props;
	return (
		<ShadowWrapper classname="doc-card-body">
			<div className="doc-card-text">
				{DocumentIcon} {localString(doc.tag)}
			</div>

			<div>
				{returnLocalDateTime(doc.createdDate, "DD MMM, YYYY hh:mma") ||
					""}
			</div>

			<div>
				<a href={doc.filePath} target="_blank" className="doc-link">
					<div id="eye-icon-btn">
						{EyeIcon}
						{localString("View")}
					</div>
				</a>
			</div>
		</ShadowWrapper>
	);
};

export default DocumentCard;
