import React from "react";
import "./Documents.scss";

const Tablets = (props) => {
	const { publicUrl, documents, localString } = props;
	return (
		<div className="row tablet-container">
			{documents?.length && documents?.length > 0 ? (
				documents.map((item, index) => (
					<>
						<a id="tnc" key={index}>
							<span className="tablets">
								<div className="tab-header">
									<img
										src={publicUrl + "/imgs/document.png"}
										style={{
											width: "40px",
											height: "40px",
										}}
									/>

									<p
										style={{
											marginTop: "10px",
											paddingLeft: "5px",
											color: "black",
											whiteSpace: "nowrap !important",
										}}
									>
										{item?.DocumentDisplayText}
									</p>
								</div>

								<button className="btn view-all-button button-stack no-border-radius report-filter-btn-doc">
									<a href={item?.DocumentURL} target="_blank">
										<img
											src={publicUrl + "/imgs/Eye.png"}
											style={{ marginRight: "10px" }}
										/>
										{localString("View")}
									</a>
								</button>
							</span>
						</a>
						&nbsp;&nbsp;&nbsp;&nbsp;
					</>
				))
			) : (
				<div style={{ margin: "auto" }}>
					<img
						style={{ marginBottom: "10px", marginLeft: "60px" }}
						src={publicUrl + "/imgs/Box.png"}
					/>
					<br />

					{localString("Sorry, No Documents Found")}
				</div>
			)}
		</div>
	);
};

export default Tablets;
