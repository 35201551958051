
import React,{useState, useEffect,useCallback, useRef}  from 'react';
import ReactDOM from 'react-dom';
import FilterInputs from '../../../components/FilterInputs/FilterInput';
import {displayError,getValueFromAdressComponent} from '../../../Utility/Utils';
import {Loader,setAutocomplete} from 'sfy-react';
import Swal from "sweetalert2"; 

const DropDetailsInput = (props) => {
	const {localString,setCreateLogisticsData,createLogisticsData,qFilter,apiCall} = props;
	const [showLoader ,setLoader] = useState(false);
	const dropLandmarkRef = useRef(null);
	const [disableDropCountryCode,setDisableDropCountryCode] = useState(true);
	const [disableDropCityState,setDisableDropCityState] = useState(true);
	const [countriesData,setCountriesData] = useState([]);
   
	let DropInput = [
		{
			labelText: localString("Name"),
			dataKey: "DropName",
			showFilter: true,
			placeholder:"Drop Name",   
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						DropName: val
					};
				});
			},
		},
		{
			labelText: localString("Contact No."),
			dataKey: "DropMobile",
			placeholder:"Drop Mobile",
			showFilter: true,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						DropMobile: val
					};
				});
			},
		},
		{
			labelText: localString("Address"),
			dataKey: "DropAddress",
			placeholder:"Drop Address",
			showFilter: true,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						DropAddress: val
					};
				});
			},
		},
		{
			labelText: localString("Landmark"),
			dataKey: "DropLandmark",
			showFilter: true,
			placeholder:"Drop Landmark",
			type:"MapAutoComplete",
			Ref:dropLandmarkRef,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						DropLandmark: val
					};
				});
			},
		},
		{
			labelText: localString("Pin Code"),
			dataKey: "DropPin",
			showFilter: true,
			placeholder:"Drop Pin Code",
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						DropPin: val
					};
				});
			},
		},
		{
			labelText: localString("City"),
			dataKey: "DropCity",
			showFilter: true,
			placeholder:"Drop City",
			isDisabled:disableDropCityState,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						DropCity: val
					};
				});
			},
		},
		{
			labelText: localString("State"),
			dataKey: "DropState",
			placeholder:"Drop State",
			showFilter: true,
			isDisabled:disableDropCityState,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						DropState: val
					};
				});
			},
		},
		{
			labelText: localString("Country"),
			dataKey: "DropCountry",
			placeholder:"Drop Country",
			showFilter: true,
			type :'Dropdown',
			dropdownList:countriesData,
			isDisabled:disableDropCountryCode,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						DropCountryCode: val.CountryCode,
						DropCountry:val.CountryName
					};
				});
			},
		},
	];
	const handleLandmarkSelection = useCallback(
		(value) => {
			let addrComponents = value.address_components
			let pincodeObject = getValueFromAdressComponent(addrComponents, 'postal_code')
			let countryCodeObject = getValueFromAdressComponent(addrComponents, 'country')
			if(countryCodeObject && countryCodeObject.short_name){
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						DropCountryCode: countryCodeObject.short_name,
						DropCountry:countryCodeObject.long_name
					};
				});
			}else{
				getCountries();
			}
			if (pincodeObject) {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						DropPin: pincodeObject.short_name,
					};
				});
			} else {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						DropPin: '',
						DropCity:'',
						DropState:''
					};
				});
			}
			setCreateLogisticsData((prevData) => {
				return {
					...prevData,
					DropLandmark: value.formatted_address
				};
			});
		},[]
	)
	
	useEffect(() => {
		const autocomplete = setAutocomplete(dropLandmarkRef.current, handleLandmarkSelection)
		return () => {
			window.google.maps.event.clearListeners(autocomplete, 'place_changed')
		}
	}, [handleLandmarkSelection]);

	useEffect(() => {
		if(createLogisticsData.DropPin && createLogisticsData.DropPin.length == 6){
			checkDropPin();
		}else{
			setDisableDropCityState(true);
		}
	}, [createLogisticsData.DropPin]);

	const checkDropPin = () => {
		let data = {
			"Pincode":createLogisticsData.DropPin
		}
		setLoader(true);
		apiCall({
			url:'getCityStateByPincode',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if (result.success) {
					let dropPinData = result.data;
					setCreateLogisticsData((prevData) => {
						return {
							...prevData,
							DropCity:dropPinData.City,
							DropState:dropPinData.State
						};
					});
					setDisableDropCityState(false);
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
	}

	const getCountries = () => {
		let data = { countryConfig: {}};
		if(countriesData && countriesData.length==0){
			setLoader(true);
			apiCall({
				url:'getCountries',
				data: data,
				callBack:(result)=>{
					setLoader(false);
					if (result.data) {
						let data = result.data.countries.map(v => ({...v, value: v.CountryName}));
						setCountriesData(data);
						setDisableDropCountryCode(false);
					}
				}	
			});
		}
	}
	
	return (
		<>
		{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
		<div className="padding12">
			<h4>{localString("Drop Detail")}</h4>
			<div className="bottom-divider clearfix">
				<FilterInputs {...props} filterInputs={DropInput} filterData={createLogisticsData} hideSaveButton={true} hideClearButton=
				{true}></FilterInputs>
			</div>
		</div>
		</>
	) 
}

export default DropDetailsInput;