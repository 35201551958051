import React from 'react';
import {Button,Dropdown,Table,Radio} from 'sfy-react';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import './SymptomFaultAction.scss'
import SymptomFaultModal from './SymptomFaultModal';


const SymptomFaultActionJSX = (props) => {
    const {localString,isSymptomEditableFlag,structuredSFA,showRecords,deleteSymptomFaultAction,requestSFA,showSymptomFaultActionModal,
        cancelSymptomClick,irisModal,closeModal,dropdownArray,filterData
    } = props;
    const classObject = {
		table:"table table-striped table-responsive table-bordered table-condensed "
	}
    return (
        <div className="request-panel-details-tag request-issue-validation-div child-tag-body symptom-fault-action-component">
            <div className = "row">
                <div className = "col-sm-12 SFA-zero-padding">
                    <p className="issues-table-header text-capitalize">{localString('SFAP reported by Engineer')} </p>
                    {
                        !isSymptomEditableFlag && structuredSFA && structuredSFA.length > 0 && 
                        <div className = "table-responsive ">
                            <Table className={classObject}>
                                <Table.Header>
                                    <Table.Row>
                                        {
                                            showRecords.RecordSymptoms && 
                                            <Table.Heading>{localString('Symptoms')}</Table.Heading>
                                        }
                                        {
                                            showRecords.RecordFaults && 
                                            <Table.Heading>{localString('Faults')}</Table.Heading>
                                        }
                                        {
                                            showRecords.RecordActions && 
                                            <Table.Heading>{localString('Actions')}</Table.Heading>
                                        }
                                        {
                                            showRecords.RecordParts && 
                                            <Table.Heading>{localString('Parts')}</Table.Heading>
                                        }
                                        {
                                            isSymptomEditableFlag && 
                                            <Table.Heading></Table.Heading>
                                        }
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        structuredSFA && structuredSFA.map((iris,i)=>{
                                            return(
                                                <Table.Row >
                                                {
                                                    showRecords.RecordSymptoms && 
                                                    <Table.Data>{iris && iris.Symptom && iris.Symptom.SymptomCode} 
                                                        {
                                                            iris && iris.Symptom && iris.Symptom.SymptomDescription && 
                                                            <span> - {iris.Symptom.SymptomDescription}</span>
                                                        }
                                                    </Table.Data>
                                                }
                                                {
                                                    showRecords.RecordFaults && 
                                                    <Table.Data>{iris && iris.Fault && iris.Fault.FaultCode} 
                                                    {
                                                        iris && iris.Fault && iris.Fault.FaultDescription && 
                                                        <span> - {iris.Fault.FaultDescription}</span>
                                                    }
                                                    </Table.Data>
                                                }
                                                {
                                                    showRecords.RecordActions && 
                                                    <Table.Data>{iris && iris.Action && iris.Action.FaultActionCode}
                                                    {
                                                        iris && iris.Action && iris.Action.FaultActionDescription && 
                                                        <span> - {iris.Action.FaultActionDescription}</span>
                                                    }
                                                    </Table.Data>
                                                }
                                                {
                                                    showRecords.RecordParts && 
                                                    <Table.Data>{iris && iris.Part && iris.Part.PartCode} 
                                                    {
                                                        iris && iris.Part && iris.Part.Name && 
                                                        <span> - {iris.Part.Name}</span>
                                                    }
                                                    </Table.Data>
                                                }                                                                
                                                </Table.Row>
                                            )
                                        })
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    }
                    {
                        isSymptomEditableFlag && structuredSFA && structuredSFA.length > 0 && 
                        <div className = "table-responsive">
                            <Table className={classObject}>
                                <Table.Header>
                                    <Table.Row>
                                        {
                                            showRecords.RecordSymptoms && 
                                            <Table.Heading>{localString('Symptoms')}</Table.Heading>
                                        }
                                        {
                                            showRecords.RecordFaults && 
                                            <Table.Heading>{localString('Faults')}</Table.Heading>
                                        }
                                        {
                                            showRecords.RecordActions && 
                                            <Table.Heading>{localString('Actions')}</Table.Heading>
                                        }
                                        {
                                            showRecords.RecordParts && 
                                            <Table.Heading>{localString('Parts')}</Table.Heading>
                                        }
                                        {
                                            isSymptomEditableFlag && 
                                            <Table.Heading></Table.Heading>
                                        }
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        structuredSFA && structuredSFA.map((iris,i)=>{
                                            return(
                                                <Table.Row >
                                                {
                                                    showRecords.RecordSymptoms && 
                                                    <Table.Data>{iris && iris.Symptom && iris.Symptom.SymptomCode} 
                                                        {
                                                            iris && iris.Symptom && iris.Symptom.SymptomDescription && 
                                                            <span> - {iris.Symptom.SymptomDescription}</span>
                                                        }
                                                    </Table.Data>
                                                }
                                                {
                                                    showRecords.RecordFaults && 
                                                    <Table.Data>{iris && iris.Fault && iris.Fault.FaultCode} 
                                                    {
                                                        iris && iris.Fault && iris.Fault.FaultDescription && 
                                                        <span> - {iris.Fault.FaultDescription}</span>
                                                    }
                                                    </Table.Data>
                                                }
                                                {
                                                    showRecords.RecordActions && 
                                                    <Table.Data>{iris && iris.Action && iris.Action.FaultActionCode}
                                                    {
                                                        iris && iris.Action && iris.Action.FaultActionDescription && 
                                                        <span> - {iris.Action.FaultActionDescription}</span>
                                                    }
                                                    </Table.Data>
                                                }
                                                {
                                                    showRecords.RecordParts && 
                                                    <Table.Data>{iris && iris.Part && iris.Part.PartCode} 
                                                    {
                                                        iris && iris.Part && iris.Part.Name && 
                                                        <span> - {iris.Part.Name}</span>
                                                    }
                                                    </Table.Data>
                                                }
                                                <td className = "trash-glyphicon">
                                                    <span className="glyphicon glyphicon-trash" onClick={()=>deleteSymptomFaultAction(iris)}></span>
                                                </td>                                                               
                                                </Table.Row>
                                            )
                                        })
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    }
                </div>
            </div>
            {
                ((requestSFA && requestSFA.length == 0) || !requestSFA) && 
                <div className="row">
                    <p className="charges-panel-subheader">{localString('No symptoms fault actions are added for this request ')}</p>
                </div>
            }
            {
                isSymptomEditableFlag && 
                <div className = "row button-row SFA-add-cancel-button">
                    <div className = "col-sm-12 ">
                        <Button 
                            type="button" 
                            name="addSymptom"
                            id="addSymptom"
                            text={localString("Add")}
                            className="btn button-servify "
                            handleOnClick={showSymptomFaultActionModal}
                        />
                        <Button 
                            type="button" 
                            name="cancelButton"
                            id="cancelButton"
                            text={localString("Cancel")}
                            className="btn button-servify "
                            handleOnClick={cancelSymptomClick}
                        />
                    </div>
                </div>
            }
            {
                irisModal &&
                <SymptomFaultModal 
                    {...props}
                    closeModal={closeModal}
                    dropdownArray={dropdownArray}
                    filterData={filterData}
                    showRecords={showRecords}
                />
            }
        </div>	
    )
}

export default SymptomFaultActionJSX
