import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Button, Modal } from 'sfy-react';

import { displayError, getLocaleDateString } from '../../../Utility/Utils';
import ReplacementReasons from './ReplacementReasons';
import ClaimForm from './ClaimForm';
import DOP from './DOP';
import PlanActivation from './PlanActivation';
import ConsentChecklist from './../ConsentChecklist/ConsentChecklist';

export default function ModeType(props) {
    const { 
		accessFn, apiCall, localString, publicUrl, defaultClaimFormData, setLoader, defaultCountryLocale, 
		coverage, setCoverage, showClaimForm, setShowClaimForm, claimFormData, setClaimFormData, claimOrContinue,
		claimForm, setClaimForm, showModeType, setShowModeType, showCoverageType, setShowCoverageType, setShowSlotSelection,
		selectedClaimType, setSelectedClaimType, modeType, setModeType, plan, setPlan, consumerProduct, setConsumerProduct,
		selectedConsumerProduct, claimEligibilityDetails, setClaimEligibilityDetails, mobileMaxLength, setMobileMaxLength, 
		showPlans, setShowPlans, showCoverage, setShowCoverage, showDOP, setShowDOP, setShowIssuesSelection, setServiceMode,
		setShowSecCommunicationDetails, checkToShowAddressList, damageTypeObj, setDamageTypeObj, handleReplacementReasons, 
		showReplacementReasons, setShowReplacementReasons, selectedReasons, setSelectedReasons, otherReasonDescription, 
		setOtherReasonDescription, showOtherReasonTextBox, setshowOtherReasonTextBox, showActivation, setShowActivation,
		setScheduleDate, setShowAddress, warrantyConsent, setWarrantyConsent, consentConfirmed, setConsentConfirmed, 
		hideSection, setScreenToViewDiv, country,legalConfigsList,setLegalConfigsList
	} = props;
	const qFilter = JSON.parse(props.qFilter);
	const [claimArr, setClaimArr] = useState([]);
	const [modeTypeArr, setModeTypeArr] = useState([]);
	const [claimPlanCoverages, setClaimPlanCoverages] = useState([]);
	const [planList, setPlanList] = useState([]);
	const [callRepairModeClick, setCallRepairModeClick] = useState(false);
	const [showResendAction, setShowResendAction] = useState(false);
	const [showIwDisclaimer, setShowIwDisclaimer] = useState(false);
	const [callInitClaimForm, setCallInitClaimForm] = useState(false);
	const [callAfterClaimPlanCoverageClick, setCallAfterClaimPlanCoverageClick] = useState(false);
	const [callAfterCoverageClick, setCallAfterCoverageClick] = useState(false);
	const [showLegalConfigConsent, setShowLegalConfigConsent] = useState(false);
	const [callgetLegalConfig,setCallgetLegalConfig] = useState(false);
	const [claimPlanCoverageClicked,setClaimPlanCoverageClicked] = useState(false);

	const typeArray = [
		{
			"ModeTypeID": 1,
			"Type": "Normal Repair",
			"Category": "repair",
			"ShowRepair": 1,
			"ImageUrl": publicUrl + "/imgs/NormalRepair.png"
		},
		{
			"ModeTypeID": 2,
			"Type": "Claim",
			"Category": "repair",
			"ShowRaiseClaim": 1,
			"ImageUrl": publicUrl + "/imgs/Claim.png"
		},
		{
			"ModeTypeID": 3,
			"Type": "Recycle",
			"Category": "recycle",
			"ShowRecycle": 1,
			"ImageUrl": publicUrl + "/imgs/Recycle.png"
		},
		{
			"ModeTypeID": 4,
			"Type": "Installation And Demo",
			"Category": "install",
			"ShowInstallAndDemo": 1,
			"ImageUrl": publicUrl + "/imgs/Install_Demo.png"
		},
		{
			"ModeTypeID": 5,
			"Type": "Servicing",
			"Category": "service",
			"ShowServicing": 1,
			"ImageUrl": publicUrl + "/imgs/Servicing.png"
		},
		{
			"ModeTypeID": 6,
			"Type": "Enquiry",
			"Category": "enquiry",
			"ShowEnquiry": 1
		},
		{
			"ModeTypeID": 7,
			"Type": "Replacement",
			"Category": "replacement",
			"ShowReplacement": 1,
			"ImageUrl": publicUrl + "/imgs/Replace-device.png"
		}
	];

	const getCoverageStatus = (coverage) => {
        let status;
        switch(coverage.Status) {
            case -3 :
                status = "Coverage is expired";
                break;
            case 2 :
                status = "Coverage is used";
                break;
            case 1 :
                status = "Coverage is active";
                break;
            case 0 :
                status = "Coverage is under cooling period/Coverage is inactive";
                break;
            default :
                status = "Coverage is under cooling period/Coverage is inactive";
        };

        if(coverage.IsUnderCoolingPeriod) {
            status = "Coverage is under cooling period/Coverage is inactive";
        }

        return status;
    };

	const claimClick = (claim) => {
		setPlan(claim.PlanDetails);
		setModeType(claim);
		setSelectedClaimType(true);
		hideSection(['CoverageType', 'Coverage', 'ClaimForm', 'DOP', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		// self.showCarryInTypeSelection = false;
		claimEligibility(claim.PlanDetails.SoldPlanID);
	}

	const claimEligibility = (planId) => {
		setShowClaimForm(false);
		setLoader(true)
		let reqObj = {
			'SoldPlanID': planId,
			'ConsumerID': consumerProduct.ConsumerID,
			'CoverageTypes': ['ADLD','THEFT','EW','SDP','ADH','OEM_WARRANTY','BATTERY_REPLACE','BACK_COVER','SDP_AND_BACK_COVER','ADLD_AND_THEFT','Preventive Maintenance','IW'],
		};
		if(qFilter.languagecode && qFilter.languagecode.length) {
			reqObj.languagecode = qFilter.languagecode[0];
		}
		apiCall({
			url: 'checkClaimEligibility',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success) {
                    // self.showValidClaims = false;
                    // self.showClaimForm = false;
                    // self.showSlotSelection = false;
                    // self.showIssuesSelection = false;
                    // self.showSecondaryContactPreview = false;
                    // self.selectedServiceMode = undefined;
                    // self.selectedScheduleDate = '';
                    // self.claimForm = null;
                    // self.damageDateError = false;
                    // self.damageTimeError = false;
                    // self.mobileDamageTypeError = false;
                    // self.damagePlaceinputError = false;
                    // self.deviceOnError = false;
                    // self.incidentDamagePlaceinputError = false
                    // self.customerAlterateNumberError = false;
                    // self.damageDetailsError = false;
                    // self.showAddAddress = false;
                    // self.showServiceTypes = false;
                    // self.showServiceLocationsCarryIn = false;
                    // self.showServiceLocationsDropOff = false;
                    // self.showCoverageType = false;
					setShowCoverageType(false);
                    // self.showCoverage = false;

                    let eligibilityDetails = result.data;
                    let planCoverages = eligibilityDetails && eligibilityDetails.soldPlanCoverages? eligibilityDetails.soldPlanCoverages: [];
					setClaimEligibilityDetails(eligibilityDetails);
					setClaimPlanCoverages(planCoverages);
                    if(planCoverages.length) {
                        if(planCoverages.length === 1) {
							claimPlanCoverageClick(planCoverages[0]);
                        }else {
							setShowCoverageType(true);
						}
                    }else {
                        if(eligibilityDetails.error_code) {
                            Swal.fire({
                                title: eligibilityDetails.Title,
                                text: eligibilityDetails.Description,
                                // type: 'warning',
								icon: 'warning',
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonColor: '#d33',
                                // backdrop:false
                            });
                        }else {
                            Swal.fire({
                                title: localString('Error'),
                                text: localString('Something went wrong'),
                                icon: 'error',
                                confirmButtonText: localString('OK'),
                                cancelButtonText: localString('Cancel')
                            });
                        }
                    }
				}else {
                    Swal.fire({
                        title: '',
                        text: localString(displayError(result)),
                        icon: 'error',
                        confirmButtonText: localString('OK'),
                        cancelButtonText: localString('Cancel')
                    });
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});	
	}

	const claimPlanCoverageClick = (coverageObj) => {
		// self.showSlotSelection = false;
        // self.showIssuesSelection = false;
        // self.showSecondaryContactPreview = false;
        // self.selectedServiceMode = undefined;
        // self.selectedScheduleDate = '';
        // self.claimForm = null;
        // self.damageDateError = false;
        // self.damageTimeError = false;
        // self.mobileDamageTypeError = false;
        // self.damagePlaceinputError = false;
        // self.deviceOnError = false;
        // self.incidentDamagePlaceinputError = false
        // self.customerAlterateNumberError = false;
        // self.damageDetailsError = false;
        // self.showAddAddress = false;
        // self.showServiceTypes = false;
        // self.showServiceLocationsCarryIn = false;
        // self.showServiceLocationsDropOff = false;

        // self.selectedCoverage = e;
		hideSection(['Coverage', 'ClaimForm', 'DOP', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		setCoverage(coverageObj);
		setClaimPlanCoverageClicked(true);
		if(coverageObj.legalConfigs && coverageObj.legalConfigs.length){
			setLegalConfigsList(coverageObj.legalConfigs);
			setShowLegalConfigConsent(true);
		}else{
			getApplicationLegalConfigs(true, coverageObj);
			setShowLegalConfigConsent(false);
		}
		
	}

	const afterClaimPlanCoverageClick = () => {
		if(coverage.Status === 1 && !coverage.IsUnderCoolingPeriod) {
            if(!coverage.CanRaiseClaim) {
                if(coverage.error_code) {
                    Swal.fire({
                        title: coverage.Title,
                        text: coverage.Description,
                        icon: 'warning',
                        showCancelButton: true,
                        showConfirmButton: !accessFn('HIDE_NORMAL_REPAIR'),
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        // backdrop:false,
                        confirmButtonText: localString(coverage.ButtonText)
                    }).then((swalResult) => {
                        if (swalResult.isConfirmed) {
							setShowClaimForm(false);
                        } else {
							setShowClaimForm(false);
                        }
                    });
                    // self.update();
                }else {
                    Swal.fire({
                        title: localString('Error'),
                        text: localString('Something went wrong'),
                        icon: 'error',
                        confirmButtonText: localString('OK'),
                        cancelButtonText: localString('Cancel')
                    });
                }
            }else {
				Swal.fire({
					title: coverage.Title,
					text: coverage.Description,
					icon: 'warning',
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: localString(coverage.ButtonText)
				}).then((swalResult) => {
					if (swalResult.isConfirmed) {
						if(coverage.RequiresClaimForm) {
							claimEligibilitySuccess(coverage);
							// setShowClaimForm(true);
						}else {
							checkToShowIssuesSelection();
						}
					}
				})
			}
        }else {
            Swal.fire({
                title: localString('Coverage Status'),
                text: localString(getCoverageStatus(coverage)),
                icon: 'warning',
                confirmButtonText: localString('OK'),
                cancelButtonText: localString('Cancel')
            });
        }
	}

	// useEffect(() => {
	// 	if(showClaimForm) {
	// 		setClaimForm(plan.ClaimForm);
	// 	}
 	// }, [showClaimForm]);

	const claimEligibilitySuccess = (coverageObj) => { 
		setShowSlotSelection(false);
		setShowIssuesSelection(false);
        setShowSecCommunicationDetails(false);
		setServiceMode({});
		setScheduleDate(null);
		setShowAddress(false);
		let claimFormObj = coverageObj && coverageObj.ClaimForm ? coverageObj.ClaimForm : plan.ClaimForm;
		setClaimForm(claimFormObj);
		setShowClaimForm(true);
		setCallInitClaimForm(true);
	}

	const fetchCustomerPlans = () => {
		setLoader(true)
		let reqObj = {
			ConsumerProductID: consumerProduct.ConsumerProductID,
            ConsumerID: consumerProduct.ConsumerID,
            action: "ActivationWithClaimRaise",
			PlanType: ['Protect', 'Secure', 'Combo'],
            AllowPlanRegistration: true,
            CheckCoverageBasedRaiseClaim: true
		}
		apiCall({
			url: 'fetchCustomerPlans',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success){
					if(result.data && result.data.length) {
						let filteredPlans = result.data.filter(o => [0,1].includes(o.GroupStatus))
						setPlanList(filteredPlans);
						if(filteredPlans.length) {
							setShowPlans(true);
						}else {
							Swal.fire({  
								icon: 'error',  
								text: localString('No plans were found for this product'),  
							});
						}
					}else {
						Swal.fire({  
							icon: 'error',  
							text: localString('No plans were found for this product'),  
						});
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	const modeTypeClick = (modeTypeObj) => {
		hideSection(['CoverageType', 'Coverage', 'ClaimForm', 'DOP', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		setPlan(null);
		setShowLegalConfigConsent(false);
		setModeType(modeTypeObj);
		if(modeTypeObj && [1,3,4,5].includes(modeTypeObj.ModeTypeID) || (modeTypeObj.ModeTypeID == 7 && !accessFn('RAISE_REPLACEMENT_REQUEST'))) {
			// handleRepairModeClick(); // display Issue selection section
			setCallRepairModeClick(true);
        }else if(modeTypeObj.ModeTypeID == 7 && accessFn('RAISE_REPLACEMENT_REQUEST')){
			handleReplacementReasons();
        } else if(modeTypeObj.ModeTypeID == 2) {
            fetchCustomerPlans();
        }
	}

	useEffect(() => {
		callRepairModeClick && handleRepairModeClick()
		setCallRepairModeClick(false);
	}, [callRepairModeClick])

	const handleRepairModeClick = () => {
		setShowPlans(false);
		setShowClaimForm(false);
		setShowCoverage(false);
		setShowCoverageType(false);
		checkToShowIssuesSelection();
	}

	const initClaimForm = () => {
		if(plan) {
			if(plan.Status == 1) {
				setClaimFormData(defaultClaimFormData);
				setShowClaimForm(true);
			} else if([-2].includes(plan.Status)) {
				setShowClaimForm(false);
				setShowActivation(true);
				setShowResendAction(true);
			}
		}
	}

	const planClick = (planObj) => {
		hideSection(['ClaimForm', 'DOP', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		setPlan(planObj);
		setClaimForm(planObj.ClaimForm);
		if(planObj.soldPlanCoverages.length > 1) {
			setShowCoverage(true);
			setShowLegalConfigConsent(false);
		}
		if(planObj.soldPlanCoverages.length == 1) {
            coverageClick(planObj.soldPlanCoverages[0], planObj);
        }
	}

	const coverageClick = (coverageObj, planObj) => {
		hideSection(['CoverageType', 'ClaimForm', 'DOP', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation']);
		setCoverage(coverageObj);
		if(coverageObj && coverageObj.ClaimForm) {
			setClaimForm(coverageObj.ClaimForm);
		}
		setClaimPlanCoverageClicked(true);
		if(coverageObj.legalConfigs && coverageObj.legalConfigs.length){
			setLegalConfigsList(coverageObj.legalConfigs);
			setShowLegalConfigConsent(true);
		}else{
			setShowLegalConfigConsent(false);
			if(coverageObj && (coverageObj.CoverageType == 'IW')) {
				setShowIwDisclaimer(true);
			}else {
				getApplicationLegalConfigs(false, coverageObj);
			}
		}
	}

	const afterCoverageClick = () => {
		if(coverage.Status === 1 && !coverage.IsUnderCoolingPeriod) {
            if (plan && plan.PlanConfig.RequiresDateOfPurchase) {
				setShowDOP(true);
            } else if(coverage && coverage.RequiresClaimForm) {
                // initClaimForm();
				setCallInitClaimForm(true);
            } else {
                // claimOrContinue();
				checkToShowIssuesSelection()
            }
        } else {
            Swal.fire({
                title: localString('Coverage Status'),
                text: localString(getCoverageStatus(coverage)),
                icon: 'warning',
                confirmButtonText: localString('OK'),
                cancelButtonText: localString('Cancel')
            });
        }
	}
	
	const submitDOPSuccess = (dop) => {
		hideSection(['ClaimForm', 'ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		let obj = {...consumerProduct};
		obj.DateOfPurchase = dop;
		setConsumerProduct(obj);
        if(coverage.RequiresClaimForm) {
            claimEligibilitySuccess(coverage);
			// setShowClaimForm(true);
        } else {
            checkToShowIssuesSelection();
        }
	}

	const checkToShowIssuesSelection = () => {
        if(coverage && coverage.RequiresIssueSelection) {
            goToIssuesSelection();
        } else {
            if (modeType && ([2,3,4,5].includes(modeType.ModeTypeID) || ([7].includes(modeType.ModeTypeID) && accessFn('RAISE_REPLACEMENT_REQUEST')))) {
				setShowAddress(true);
            } else {
                goToIssuesSelection();
            }
        }
    };

	const goToIssuesSelection = () => {
		setShowIssuesSelection(true);
	}

	const handleTypes = () => {
		let modeTypes = [];
        let claims = [];
        if (consumerProduct && consumerProduct.deviceState && consumerProduct.deviceState.deviceStateMeta && Object.keys(consumerProduct.deviceState).length > 0 && Object.keys(consumerProduct.deviceState.deviceStateMeta).length > 0) {
            if (((consumerProduct.deviceState.deviceStateMeta.ShowInstallAndDemo == 1 || (consumerProduct.productConfig && consumerProduct.productConfig.showInstallAndDemo)) && consumerProduct.deviceState.deviceStateMeta.IsServiceable == 1)){
				let filteredType = typeArray.find(obj => obj.ShowInstallAndDemo == 1); 
                modeTypes.push(filteredType);
            }
            if ((consumerProduct.deviceState.deviceStateMeta.ShowServicing == 1 && consumerProduct.deviceState.deviceStateMeta.IsServiceable == 1)){
				let filteredType = typeArray.find(obj => obj.ShowServicing == 1); 
                modeTypes.push(filteredType);
            }
            if ((consumerProduct.deviceState.deviceStateMeta.ShowRepair == 1 && consumerProduct.deviceState.deviceStateMeta.IsServiceable == 1) && !accessFn('HIDE_NORMAL_REPAIR')){
				let filteredType = typeArray.find(obj => obj.ShowRepair == 1); 
                modeTypes.push(filteredType);
            }
            if ((consumerProduct.deviceState.deviceStateMeta.ShowReplacement == 1 && consumerProduct.deviceState.deviceStateMeta.IsServiceable == 1) && !accessFn('HIDE_NORMAL_REPAIR')){
				let filteredType = typeArray.find(obj => obj.ShowReplacement == 1); 
                modeTypes.push(filteredType);
            }
            if ((consumerProduct.deviceState.deviceStateMeta.ShowRaiseClaim == 1 && consumerProduct.deviceState.deviceStateMeta.IsServiceable == 1)){
				let filteredType = typeArray.find(obj => obj.ShowRaiseClaim == 1); 
                modeTypes.push(filteredType);
            } else {
                if(consumerProduct && consumerProduct.ProductDetails && consumerProduct.ProductDetails.length > 0) {
                    let allPlans = consumerProduct.ProductDetails;
					allPlans && allPlans.length && allPlans.map(singlePlan => {
						if(singlePlan.hasOwnProperty('HideDialog') && !singlePlan.HideDialog && singlePlan.Status == 1 && singlePlan.NoOfRepairsAllowed > singlePlan.NoOfRepairsUsed) {
							claims.push({
								"ModeTypeID": 2,
								"Type": singlePlan.PlanHeader,
								"Category": "repair",
								"ShowRaiseClaim": 1,
								"ImageUrl": publicUrl + "/imgs/Claim.png",
								"PlanDetails": singlePlan
							});
						}
					})
                }
            }
            if (consumerProduct.deviceState.deviceStateMeta.ShowRecycle == 1 && consumerProduct.deviceState.deviceStateMeta.IsServiceable == 1) {
				let filteredType = typeArray.find(obj => obj.ShowRecycle == 1); 
                modeTypes.push(filteredType);
            }
            if (consumerProduct.deviceState.deviceStateMeta.ShowEnquiry == 1 && consumerProduct.deviceState.deviceStateMeta.IsServiceable == 1) {
				let filteredType = typeArray.find(obj => obj.ShowEnquiry == 1); 
                modeTypes.push(filteredType);
            }
			setModeTypeArr(modeTypes);
			setClaimArr(claims);
            if(modeTypes.length == 1 && claims.length == 0){
                modeTypeClick(modeTypes[0]);
            }
        }
	}

	const getApplicationLegalConfigs = (isClaimplanCoverageClick, coverage) => {
		if(consumerProduct.IsUnderWarranty) {
			setLoader(true);
			let reqObj = {
				PlanCoverageID: coverage && coverage.PlanCoverageID ? coverage.PlanCoverageID : undefined,
                BrandID: consumerProduct && consumerProduct.BrandID,
			};
			apiCall({
				url: 'getApplicationLegalConfigs',
				data: reqObj,
				callBack: (result) => {
					setLoader(false); 
					if (result && result.data && result.data.LegalConfigs && result.data.LegalConfigs.length > 0) {
						let configs = result.data.LegalConfigs;
						let warrantyConsentObj = configs.find((obj) => {
							return obj.legalConfig && (obj.legalConfig.LegalConfigCode == 'WARRANTY_VOID_CLAIM');
						});
						setWarrantyConsent(warrantyConsentObj);
						if(warrantyConsentObj) {
							Swal.fire({
								title: localString(warrantyConsentObj.legalConfig.Text),
								icon: 'warning',
								showCancelButton: true,
                                showConfirmButton: true,
							}).then(swalResult => {
								if(swalResult.isConfirmed){
									setConsentConfirmed(true);
                                    isClaimplanCoverageClick ? setCallAfterClaimPlanCoverageClick(true) : setCallAfterCoverageClick(true);
								}else {
									setConsentConfirmed(false);
									setCoverage(null);
								}
							});
                        }
					}else {
						isClaimplanCoverageClick ? setCallAfterClaimPlanCoverageClick(true) : setCallAfterCoverageClick(true);
					}
				},
				errorCB: (err) => {
					setLoader(false);
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(err)),  
					});
				}
			});
        } else {
            isClaimplanCoverageClick ? setCallAfterClaimPlanCoverageClick(true) : setCallAfterCoverageClick(true);
        }
	}

	useEffect(() => {
		if(callAfterCoverageClick) {
			afterCoverageClick();
			setCallAfterCoverageClick(false);
		}else if(callAfterClaimPlanCoverageClick) {
			afterClaimPlanCoverageClick();
			setCallAfterClaimPlanCoverageClick(false);
		}else if(callInitClaimForm) {
			initClaimForm();
			setCallInitClaimForm(false);
		}else if(callgetLegalConfig){
			getApplicationLegalConfigs(claimPlanCoverageClicked, coverage);
			setCallgetLegalConfig(false);
		}
	}, [callAfterCoverageClick, callAfterClaimPlanCoverageClick, callInitClaimForm,callgetLegalConfig]);

	useEffect(() => {
		handleTypes();
		return () => {
			setPlan(null)
			setCoverage(null);
			setModeType(null);
			setClaimForm(null);
			setClaimFormData(defaultClaimFormData);
		}
	}, []);

	useEffect(() => {
		if(showModeType) {
			setScreenToViewDiv('ModeType')
		}
	}, [showModeType]);

	useEffect(() => {
		if(showCoverageType) {
			setScreenToViewDiv('CoverageType')
		}
	}, [showCoverageType]);

	useEffect(() => {
		if(showReplacementReasons) {
			setScreenToViewDiv('ReplacementReasons')
		}
	}, [showReplacementReasons]);

	useEffect(() => {
		if(showPlans) {
			setScreenToViewDiv('Plans')
		}
	}, [showPlans]);

	useEffect(() => {
		if(showCoverage) {
			setScreenToViewDiv('Coverage')
		}
	}, [showCoverage]);

	useEffect(() => {
		if(showDOP) {
			setScreenToViewDiv('DOP')
		}
	}, [showDOP]);

	useEffect(() => {
		if(showActivation) {
			setScreenToViewDiv('Activation')
		}
	}, [showActivation]);

	useEffect(() => {
		if(showClaimForm) {
			setScreenToViewDiv('ClaimForm')
		}
	}, [showClaimForm]);

    return (
        <>
            {/* ********** Select Type ********** */}
			{
				showModeType && ((accessFn('RAISE_CLAIM_REQUEST') && claimArr.length > 0) || (modeTypeArr && modeTypeArr.length > 0)) && 
				<div id='ModeType' className="raiseReqSection">
					<div className='selectType'>						
						<div className="row">
                            <div className="col-sm-10 col-xs-8 form-label">
                                <label>{localString("Select a Type")}</label>
                            </div>
                        </div>
                        <div className="row">
							{
								accessFn('RAISE_CLAIM_REQUEST') && claimArr.length > 0 && claimArr.map((obj, k) => 
									<div className="col-sm-4 col-md-3" key={k}>
										<div className={`item subcat-item ${selectedClaimType && plan && obj.PlanDetails && plan.PlanID === obj.PlanDetails.PlanID ? 'item-active-border': ''}`} onClick={() => claimClick(obj)}>
											<img src={`${publicUrl}/imgs/Claim.png`} alt="Claim"/>	
											<p className="text-capitalize">{localString(obj.Type)}</p>
										</div>
									</div>
								)	
 							}
							{
								modeTypeArr.length > 0 && modeTypeArr.map((obj, k) => 
									<div className="col-sm-4 col-md-3"  key={k}>
										<div className={`item subcat-item ${modeType && modeType.ModeTypeID == obj.ModeTypeID ? 'item-active-border': ''}`} onClick={() => modeTypeClick(obj)}>
											<img src={obj.ImageUrl} alt={obj.Type}/>
											<p className="text-capitalize">{localString(obj.Type)}</p>
										</div>
									</div>
								)
							}                            
                        </div>
					</div>
				</div>
			}
			{/* ********** Select Coverage ********** */}
			{
				showCoverageType && 
				<div id='CoverageType' className="raiseReqSection">
					<div className='coverageType'>						
						<div className="row">
                            <div className="col-sm-10 col-xs-8 form-label">
                                <label>{localString("Select a Coverage")}</label>
                            </div>
                        </div>
                        <div className="row">
							{
								claimPlanCoverages && claimPlanCoverages.length > 0 && claimPlanCoverages.map((obj, k) => 
									<div className="col-sm-4 col-md-3" key={k} onClick={() => claimPlanCoverageClick(obj)}>
										<div className={`item subcat-item ${coverage && coverage.SoldPlanCoverageID == obj.SoldPlanCoverageID ? 'item-active-border': ''}`} style={{ height: '220px' }}>
											<img src={`${publicUrl}/imgs/Claim.png`} alt="Claim" />
											{ obj.PlanCoverageDisplayName && <p className="text-capitalize">{localString(obj.PlanCoverageDisplayName)}</p>}
											{ obj.CoverageStartDate && moment(obj.CoverageStartDate).isValid() && moment(obj.CoverageStartDate).unix() != 0 && 
												<p className="text-capitalize">{localString('Coverage Start Date') + ':'} {getLocaleDateString(obj.CoverageStartDate, defaultCountryLocale)}</p>
											}
											{ obj.Status &&
												<p className="text-capitalize">{localString('Status') + ':'} {getCoverageStatus(obj)}</p>
											}
										</div>
									</div>
								)	
 							}                           
                        </div>
					</div>
				</div>
			}
			{/* ********** Replacement reasons selection ********** */}
			{
				showReplacementReasons &&
					<ReplacementReasons
						{...props}
						setLoader={setLoader}
						setCallRepairModeClick={setCallRepairModeClick}
						setShowSecCommunicationDetails={setShowSecCommunicationDetails}
						selectedReasons={selectedReasons} setSelectedReasons={setSelectedReasons}
						showOtherReasonTextBox={showOtherReasonTextBox} setshowOtherReasonTextBox={setshowOtherReasonTextBox}
						otherReasonDescription={otherReasonDescription} setOtherReasonDescription={setOtherReasonDescription}
					/>
				}
			{/* ********** Select Plans ********** */}
			{
				showPlans && accessFn('RAISE_CLAIM_REQUEST') && 
				<div id='Plans' className="raiseReqSection">
					<div className='plans'>						
						<div className="row">
                            <div className="col-sm-10 col-xs-8 form-label">
                                <label>{localString("Select a Plan")}</label>
                            </div>
                        </div>
                        <div className="row">
							{
								planList && planList.length > 0 && planList.map((obj, k) => 
									// <div className="row" if={GroupStatus != -1}>
									<div className="row" key={k}>
										{
											obj.PlanObject && obj.PlanObject.length && obj.PlanObject.map((planObj, j) => (
												<div className="col-sm-3" key={j} onClick={() => planClick(planObj)}>
													<div className={`item subcat-item ${plan && plan.PlanID == planObj.PlanID ? 'item-active-border': ''}`}>
														<p className="text-capitalize">{localString(planObj.PlanName)}</p>
														<p className="text-capitalize">{localString("Plan Activation Code")} : {localString(planObj.PlanActivationCode)}</p>
													</div>
												</div>
											))
										}
									</div>
								)	
 							}                           
                        </div>
					</div>
				</div>
			}
			{/* ********** Select Coverage(US) ********** */}
			{
				showCoverage && accessFn('RAISE_CLAIM_REQUEST') && 
				<div id='Coverage' className="raiseReqSection">
					<div className='coverage'>						
						<div className="row">
                            <div className="col-sm-10 col-xs-8 form-label">
                                <label>{localString("Select a Coverage")}</label>
                            </div>
                        </div>
                        <div className="row">
							{
								plan && plan.soldPlanCoverages && plan.soldPlanCoverages.length > 0 && plan.soldPlanCoverages.map((obj, k) => 
									<div className="col-sm-3" onClick={() => coverageClick(obj, plan)}>
										<div className={`item subcat-item ${coverage && coverage.SoldPlanCoverageID == obj.SoldPlanCoverageID ? 'item-active-border': ''}`} style={{ height: '220px'}}>
											{ obj.PlanCoverageDisplayName && <p className="text-capitalize">{localString(obj.PlanCoverageDisplayName)}</p>}
											{ obj.CoverageStartDate && moment(obj.CoverageStartDate).isValid() && moment(obj.CoverageStartDate).unix() != 0 && 
												<p className="text-capitalize">{localString('Coverage Start Date') + ':'} {getLocaleDateString(obj.CoverageStartDate, defaultCountryLocale)}</p>
											}
											{ obj.Status &&
												<p className="text-capitalize">{localString('Status') + ':'} {getCoverageStatus(obj)}</p>
											}
										</div>
									</div>
								)
 							}                           
                        </div>
					</div>
				</div>
			}
			{
					showLegalConfigConsent &&
						<ConsentChecklist
							{...props}
							legalConfigsList={legalConfigsList}
							setLegalConfigsList={setLegalConfigsList}
							setCallRequestFunction={coverage && coverage.CoverageType == 'IW'?setShowIwDisclaimer:setCallgetLegalConfig}
						/>
			}
			{/* ********** Date Of Purchase(US) ********** */}
			{
				showDOP && accessFn('RAISE_CLAIM_REQUEST') && 
					<DOP 
						{...props}
						setLoader={setLoader}
						submitDOPSuccess={submitDOPSuccess}
						consumerProduct={consumerProduct} setConsumerProduct={setConsumerProduct}
					/>
			}
			{/* ********** Plan Activation(US) - Need to rewrite its logic if needed ********** */}
			{
				showActivation && accessFn('RAISE_CLAIM_REQUEST') &&
					<PlanActivation 
						{...props}
						setLoader={setLoader}
						hideSection={hideSection}
						plan={plan} setPlan={setPlan}
						consumerProduct={consumerProduct}
						setShowClaimForm={setShowClaimForm}
						showActivation={showActivation} setShowActivation={setShowActivation}
						showResendAction={showResendAction} setShowResendAction={setShowResendAction}
					/>
			}
			{/* ********** Claim Form ********** */}
			{
				showClaimForm && accessFn('RAISE_CLAIM_REQUEST') && 
					<ClaimForm 
						{...props}
						plan={plan}
						country={country}
						coverage={coverage}
						claimForm={claimForm}
						setLoader={setLoader}
						hideSection={hideSection}
						consumerProduct={consumerProduct}
						defaultClaimFormData={defaultClaimFormData}
						setShowSecCommunicationDetails={setShowSecCommunicationDetails}
						showClaimForm={showClaimForm} setShowClaimForm={setShowClaimForm}
						claimFormData={claimFormData} setClaimFormData={setClaimFormData}
						damageTypeObj={damageTypeObj} setDamageTypeObj={setDamageTypeObj}
						mobileMaxLength={mobileMaxLength} setMobileMaxLength={setMobileMaxLength}
					/>

			}
			{/* ********** Claim Form ********** */}
			{
				showIwDisclaimer && 
					<Modal
						className='iwDisclaimer'
						showModal={showIwDisclaimer}
						closeModal={() => setShowIwDisclaimer(false)}
						showCloseBtn={true}
					>
						<Modal.Header>
							<h3 id="myModalLabel" className="modal-title title-spacing">
								<img 
									src={publicUrl + "/imgs/WarningSign.png"} 
									width={'40px'} 
									height={'40px'}
									style={{ marginRight: '5px' }}
								/>
								{localString('Attention')}
							</h3>
						</Modal.Header>
						<Modal.Body>
							<div className="modal-body">
								<div>
									{/* {localString('This option is exclusively applicable for Extended Warranty (after the expiration of the manufacturing warranty) and Post Repair (defective replacement) issues.')} */}
									{localString('This option is exclusively applicable for')} <span className='bold'>{localString('Extended Warranty')}</span> {localString('(after the expiration of the manufacturing warranty) and')} <span className='bold'>{localString('Post Repair')}</span> {localString('(defective replacement) issues.')}
								</div>
								<br/>
								<div>
									<><span>{localString('For cases where the device shows no signs of physical damage and is experiencing manufacturing-related (Mechanical Breakdown) issues, kindly direct customers to visit')} </span><a href='https://www.samsung.com/us/' target='_blank'>https://www.samsung.com/us/</a><span> {localString('or contact us at 800-726-7864')}</span></>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<div className="form-group-filter-request text-center">
								<Button 
									className="btn button-servify-solid submit-btn" 
									onClick={() => setShowIwDisclaimer(false)}
								>
									{localString("Cancel")}
								</Button>
								<Button 
									className="btn button-servify-solid submit-btn save-submit" 
									onClick={() => {
										getApplicationLegalConfigs(false, coverage);
										setShowIwDisclaimer(false);
									}}
									// isDisabled={disableBtn}
								>
									{localString("OK")}
								</Button>
							</div>
						</Modal.Footer>
					</Modal>
			}
        </>
    );
}