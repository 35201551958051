import React, { useEffect, useState } from 'react';
import { Dropdown, Button } from 'sfy-react';
import Swal from "sweetalert2"; 
import Papa from 'papaparse';

import { displayError, downloadCSV } from '../../Utility/Utils';


export default function ProductCatalogueStep(props) {
	const { localString, apiCall, setLoader } = props;

	const qFilter = JSON.parse(props.qFilter)
	const [subCategoryList, setSubCategoryList] = useState([]);
	const [subCategoryObj, setSubCategoryObj] = useState(null);
	const [brandList, setBrandList] = useState([]);
	const [brandObj, setBrandObj] = useState(null);

	const getPlanSubCategories = () => {
		setLoader(true);
		let reqObj = {
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		}
		apiCall({
			url: 'getPlanSubCategories',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				// setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(val => ({ ...val, value: val.ProductSubCategory}))
					setSubCategoryList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		getPlanSubCategories();
	}, [])

	const getPlanBrandsForSubcategories = (id) => {
		setLoader(true);
		let reqObj = {
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp",
			ProductSubCategoryID: id
		}
		apiCall({
			url: 'getPlanBrandsForSubcategories',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(val => ({ ...val, value: val.BrandName}))
					setBrandList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const getFilteredPlanProducts = () => {
		setLoader(true);
		let reqObj = {
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp",
			BrandID: brandObj.BrandID,
    		ProductSubCategoryID: subCategoryObj.ProductSubCategoryID,
		}
		apiCall({
			url: 'getFilteredPlanProducts',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success) {
					if(result.data.length) {
						downloadCatalogue(result.data);
					}else {
						Swal.fire({  
							icon: 'error',  
							text: localString('No Products Found.'),  
						}); 
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const downloadCatalogue = (products) => {
		let arr = products.filter(product => {
			let obj = {...product};
			delete obj.BrandID; 
			delete obj.ProductID; 
			delete obj.ProductSubCategoryID; 
			return obj;
		});
		let csv = Papa.unparse({
			fields: [
				'BrandName',
				'BrandCode',
				'ProductSubCategoryName',
				'ProductSubCategoryCode',
				'ProductName',
				'ProductCode'
			],
			data: arr,
		});
		let CapsString
		let capital = Papa.parse(csv, {
			beforeFirstChunk: (chunk) => {
				let rows = chunk.split( /\r\n|\r|\n/ );
				let result = [];
				let row = rows[0];
				let a = row.split(',');
				for (let iCount = 0;iCount<a.length;iCount++) {
					let item = a[iCount].replace(/([a-z])([A-Z])/g, '$1 $2');
					result.push(item)
				}
				CapsString = result.toString().toUpperCase();
				rows[0] = CapsString;
				return rows.join("\r\n");
			},
		});
		let csvString = Papa.unparse(capital);
		let fileName = `${brandObj && brandObj.BrandName} ${subCategoryObj && subCategoryObj.ProductSubCategory} Catalog.csv`;
		downloadCSV(csvString, fileName);
	}


	return (
		<>
			<div className=''>
				<div className='row'>
					<div className='col-sm-6 col-md-3 form-group' style={{ padding: '0px 5px' }}>
						<Dropdown
							value={subCategoryObj && subCategoryObj.value || ''}
							options={subCategoryList}
							handleOnChange={(data) => {
								setSubCategoryObj(data);
								setBrandObj(null);
								getPlanBrandsForSubcategories(data.ProductSubCategoryID);
							}}
							filterBy='value'
							showDownArrow={true}
							placeholder={'Category'}
						/>
					</div>
					<div className='col-sm-6 col-md-3 form-group' style={{ padding: '0px 5px' }}>
						<Dropdown
							value={brandObj && brandObj.value || ''}
							options={brandList}
							handleOnChange={(data) => {
								setBrandObj(data);
							}}
							filterBy='value'
							showDownArrow={true}
							placeholder={'Brand'}
							isDisabled={!subCategoryObj}
						/>
					</div>
					<div className='col-sm-12 col-md-5' style={{ padding: '3px 5px 0px 5px' }}>
						<Button
							type='button'
							className='downloadBtn'
							onClick={() => getFilteredPlanProducts()}
							isDisabled={!brandObj}
						>
							{localString('Download Catalog')}
							<div className="download-icon">
								<div className="download-image"></div>
							</div>
						</Button>
					</div>
				</div>

			</div>
		</>
	);
}