import React, { useState } from 'react';
import { Input, Button } from 'sfy-react';
import { cloneDeep } from 'lodash';
import Swal from 'sweetalert2';
import { displayError } from '../../../../../Utility/Utils';

export default function LoanerDeviceDiagnosis(props) {
	const {
		accessFn, apiCall, localString, gsxCheckList, setGsxCheckList, setLoader, consumerServiceRequest, loanerDetails,
		getRequestById, fetchLoanerDetailsByCsrId, closeModal
	} = props;

	const [cosmeticTextbox, setCosmeticTextbox] = useState('');

	const handleChecklistChange = (val, key) => {
		let arr = cloneDeep(gsxCheckList);
		arr[key].Inward = val;
		setGsxCheckList(arr);
	}

	const isAllChecklistSelected = !gsxCheckList.map(a => a.Inward).some(e => ![1, 2, 3].includes(e));

	const completeLoanerDeviceReceivedClicked = () => {
		Swal.fire({  
			title: localString('Are you sure?'),
            icon: 'question',
            confirmButtonColor: '#4C10C1',
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: localString("Cancel"),
            confirmButtonText: localString("OK"),
            allowEscapeKey: false,
        }).then(swalResult => {
            if(swalResult.isConfirmed){
                setLoader(true); 
                let reqObj = {
					ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
					LoanerRequestID: loanerDetails.loanerRequestID,
					CheckList: gsxCheckList,
					Remarks: cosmeticTextbox.trim()
				};
                apiCall({
                    url: 'addIUpdateLoanerChecklist',
                    data: reqObj,
                    callBack: (result) => {
                        setLoader(false); 
                        if(result && result.success) {
							closeModal();
                            getRequestById();
                            fetchLoanerDetailsByCsrId();
                        }else {
                            Swal.fire({  
                                icon: 'error',  
                                text: localString(displayError(result)), 
                            });
                        }
                    },
                    errorCB: (err) => {
                        setLoader(false);
                        Swal.fire({  
                            icon: 'error',  
                            text: localString(displayError(err)),  
                        });
                    }
                });
            }else {
                window.location.reload();
            }
        });
	}

	return (<>
		<div className='container'>
			{ gsxCheckList && (gsxCheckList.length > 0) && <div className='gsxCheckbox'>
				<div className='row checklistHeader'>
					<div className="col-sm-7">
						{localString("Item")}
					</div>
					<div className="col-sm-2 text-center">
						{localString("Inward")}
					</div>
				</div> 
				{gsxCheckList.map((item, k) => <div className='row checkboxFeature'>
					<div className="col-sm-7">
						<label>{localString(item.Name)}</label>
					</div>
					<div className='col-sm-5'>
						<label className="check-label">
							<input
								type="radio"
								value="1"
								name={"checkList_" + k} 
								checked={(item.Inward && item.Inward === 1)}
								onClick={() => handleChecklistChange(1, k)}
								id={"checkbox_" + k}/>
							{localString("Yes")}
						</label>
						<label className="check-label">
							<input
								type="radio"
								value="2"
								name={"checkList_" + k}
								checked={(item.Inward && item.Inward === 2)}
								onClick={() => handleChecklistChange(2, k)}
								id={"checkbox_" + k}
							/>
							{localString("No")}
						</label>
						<label className="check-label">
							<input
								type="radio"
								value="3"
								name={"checkList_" + k}
								checked={(item.Inward && item.Inward === 3)}
								onClick={() => handleChecklistChange(3, k)}
								id={"checkbox_" + k}
							/>
							{localString("Not applicable")}
						</label>
					</div>
				</div>)}
			</div> }
			<h3 className="">{localString("Cosmetic Conditions of Loaner Device")}</h3>
			<div className="row">
				<div className="col-sm-12">
					<Input
						value={cosmeticTextbox}
						handleOnChange={(e) => setCosmeticTextbox(e.target.value)}
						inputComponent='textarea'
						className={{
							inputContainer: 'textAreaContainer'
						}}
						otherProps={{
							rows: 3
						}}
					/>
				</div>
			</div>
			<div className='pull-right'>
				<Button 
					className="btn button-servify"
					onClick={() => completeLoanerDeviceReceivedClicked()}
					isDisabled={!accessFn('GSX_FLOW') || !isAllChecklistSelected || !(cosmeticTextbox.trim()).length}
				>
					{localString("Complete")}
				</Button>
			</div>

		</div>
	</>)
}