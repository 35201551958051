import React, { useEffect, useState } from "react";
import { Loader } from 'sfy-react';
import ReactDOM from 'react-dom';
import Swal from "sweetalert2";

import PageHeader from "../../components/PageHeader/PageHeader";
import BulkUploadGrid from "./BulkUploadGrid";
import BulkUploadStep1to4 from "./BulkUploadStep1to4";
import BulkUploadStep5 from "./BulkUploadStep5";
import { displayError } from "../../Utility/Utils";
import './BulkUpload.scss'

export default function BulkUpload(props) {
	const { accessFn, localString, titleHeader, publicUrl, apiCall } = props;
	if(!accessFn('PLAN_SALES_BULK_UPLOAD')) {
		window.location.href = '/401';
	}
	const qFilter = JSON.parse(props.qFilter)
	const [templateList, setTemplateList] = useState([]);
	const [pslList, setPSLList] = useState([]);
	const [planList, setPlanList] = useState([]);
	const [uploadHistoryList, setUploadHistoryList] = useState([]);
	const [loader, setLoader] = useState(false);
	const [planLoader, setPlanLoader] = useState(false);
	const [pslLoader, setPSLLoader] = useState(false);
	const [consentLoader, setConsentLoader] = useState(false);
	const [consentText, setConsentText] = useState(null);
	const [pagination , setPagination] = useState({
		pageNo : 1,
		itemsPerPage : 10,
		count: 0,
		numberOfPages: 0
	});

	const buttonHeader = [
		{
			buttonText: localString('Transaction Summary'),
			showButton: true,
			onButtonClick: () => {
				window.location.href = props.viewReportUrl
			}
		}
	];

	const getPSLocations = () => {
		setPSLLoader(true);
		apiCall({
			url: 'getPartnerServiceLocations',
			data: {},
			callBack: (result) => {
				setPSLLoader(false);
				if(result.success) {
					if(result.data.length) {
						setPSLList(result.data);
					}else {
						Swal.fire({  
							icon: 'error',  
							text: localString('No partner service location found'),  
						});
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setPSLLoader(false)
		});
	}

	const getPlans = () => {
		setPlanLoader(true);
		let reqObj = {
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		};
		apiCall({
			url: 'getTemplatePlanDetails',
			data: reqObj,
			callBack: (result) => {
				setPlanLoader(false);
				if(result.success) {
					if(result.data.length) {
						setPlanList(result.data);
						getTemplates(result.data);
					}else {
						getTemplates([]);
						Swal.fire({  
							icon: 'error',  
							text: localString('No Plans found'),  
						});
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setPlanLoader(false)
		});
	}

	const getConsent = () => {
		setConsentLoader(true);
		let reqObj = {
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		}
		apiCall({
			url: 'getApplicationLegalConfigs',
			data: reqObj,
			callBack: (result) => {
				setConsentLoader(false);
				if(result.success && result.data) {
					if(result.data.LegalConfigs && result.data.LegalConfigs.length) {
						let legalConfigObj = result.data.LegalConfigs.find(obj => {
							return obj.legalConfig && obj.legalConfig.Active && obj.legalConfig.LegalConfigCode && obj.legalConfig.LegalConfigCode === 'TNC_UPLOAD'
						});
						if(legalConfigObj) {
							let text = legalConfigObj.legalConfig.Text.replace('@tnc@', localString("Terms and Conditions"));
							setConsentText(text);
						}	
					} else {
						Swal.fire({  
							icon: 'error',  
							text: localString('Legal Configs not found.'),  
						}); 
					}			
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setConsentLoader(false)
		});
	}

	const getUploadHistory = (refID) => {
		setLoader(true);
		let reqObj = {
			UserID: qFilter.userID,
		};
		if(refID) {
			reqObj.ReferenceID = refID;
		}else {
			reqObj.pagination = {
				currentPage: pagination.pageNo,
				itemsPerPage: pagination.itemsPerPage
			}
		}
		apiCall({
			url: 'fetchFileUploadDetails',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result && result.success && !refID) {
					if(result.data && result.data.UploadHistory) {
						setUploadHistoryList(result.data.UploadHistory);
						setPagination({...pagination, 
							count: result.data.Pagination.totalItems,
							numberOfPages: result.data.Pagination.pages.length 
						});
					}
				}else if(result && result.success && refID) {
					if(result.data && result.data.UploadHistory && result.data.UploadHistory.length) {
						window.open(result.data.UploadHistory[0].ProcessedFileURL, '_blank')
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		getUploadHistory();
	}, [pagination.pageNo])

	const getTemplates = (planArr) => {
		setLoader(true);
		let reqObj = {
			salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp",
			PlanArray: planArr.map(a => a.PlanID)
		};
		apiCall({
			url: 'fetchPlanTemplateMaster',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let arr = result.data.map(a => ({...a, value: a.TemplateName}));
					setTemplateList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		getPSLocations();
		getPlans();
		getConsent();
	}, []);

	return (
		<div className='bulkUpload'>
			{
				(loader || pslLoader || planLoader || consentLoader) && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<PageHeader
				titleHeader={titleHeader}
				localString={localString}
				headerButtonArray={buttonHeader} 
				btnClass='btn button-servify header-btn button-stack text-capital'
			/>
			<div className='container-fluid bulkUploadContainer'>
				<div className='row'>
					<div className='col-sm-12 col-md-12 step1to4'>
						<BulkUploadStep1to4
							{...props}
							setLoader={setLoader}
							pslList={pslList}
							planList={planList}
							localString={localString}
							templateList={templateList} setTemplateList={setTemplateList}
						/>
					</div>
				</div>
				<div className="row">
					<div className='col-sm-12 col-md-12 step5'>
						<BulkUploadStep5
							{...props}
							publicUrl={publicUrl}
							setLoader={setLoader}
							consentText={consentText}
							localString={localString}
							templateList={templateList}
							getUploadHistory={getUploadHistory}
						/>
					</div>
				</div>
				<div style={{ paddingTop: '20px' }}>
					<BulkUploadGrid
						{...props}
						publicUrl={publicUrl}
						setLoader={setLoader}
						localString={localString}
						uploadHistoryList={uploadHistoryList}
						getUploadHistory={getUploadHistory}
						pagination={pagination} setPagination={setPagination}
					/>
				</div>
			</div>
		</div>
	);
}