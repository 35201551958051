import React from 'react';
import CommunicationInput from './CommunicationInput';
const EmailDetails = (props)=>{
    const {emailLogData}=props
    return( 
        <>
            <CommunicationInput
            {...props}
            communicationData = {emailLogData}
            />
        </>
    )
}
export default  EmailDetails;