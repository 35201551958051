import React, { useRef } from "react";
import {Input} from 'sfy-react';

export default function UploadFileInput(props) {
    const {
        localString, pic, addDocArray, docArray, removeAddedDoc, selectedPicType
    } = props;
    const fileRef = useRef(null);
    const acceptFileType = '.png,.jpg,.jpeg'
    
    const imageClick = () => {
        fileRef.current.click();
    }

    return (
        <>
            <div className={`${(pic.picType).replace(/\s+/, '')}  image-field`}>
                <Input
                    reference={fileRef}
                    handleOnChange={(e)=>addDocArray(e)}
                    name={pic.picType}
                    id={(pic.picType).replace(/\s+/, '')}
                    inputType='file'
                    className='inputfile'
                    otherProps={{ accept: acceptFileType }}
                />
                <label for={`${(pic.picType).replace(/\s+/, '')} `}>
                    <span className='glyphicon glyphicon-plus upload-sign' onClick={imageClick}/>
                </label>
            </div>
            <p className='image-text'>{localString(pic.picType)}</p>
            <div className='imagePosition'> 
                {docArray && docArray.map((doc,i)=>(
                    pic.picType === doc.Tag && <div className='request-documents-li align'>
                        <span 
                            className='glyphicon glyphicon-minus-sign delete-charges-btn' 
                            onClick={()=> {
                                fileRef.current.value = '';
                                removeAddedDoc(doc,i);
                            }} 
                        ></span>
                        <a href={doc.FileUrl} target='_blank' className='nounderline'>
                            <img src={doc.FileUrl} alt={selectedPicType} className='image'/>
                        </a>
                    </div>
                ))}
            </div>
        </>
    );
}