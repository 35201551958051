import React from 'react';
import { Modal } from 'sfy-react';

import ShipToServiceCentreInput from './ShipToServiceCentreInput';
import './ShipToServiceCentreModal.scss';

const ShipToServiceCentreModal = (props) => {
    const { localString, showModal, closeModal, shipToDropOFFLoc } = props;
		return ( <>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='ShipToServiceCentreModal'>
                <Modal.Header>
                    <h4 className="modal-title"> {localString(`Ship ${shipToDropOFFLoc ? 'Device' : 'To Service Centre'}`)}</h4>
                </Modal.Header>
                <Modal.Body>
                    <ShipToServiceCentreInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </> )
}
export default ShipToServiceCentreModal;