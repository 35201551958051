import React,{useState,useEffect} from 'react';
import './CommunicationHistory.scss'
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import CallLogTabs from './CallLogTabs';
import CommunicationHistoryDetails from './CommunicationHistoryDetails';
import { displayError } from '../../../../../Utility/Utils';
import { uniqBy,concat,find} from 'lodash';
import moment from 'moment';
import Swal from 'sweetalert2';

const CommunicationHistory = (props)=>{
    const {apiCall,localString,consumerServiceRequest} = props;
    const [loader, setLoader] = useState(false);
    const [callStatusLoader, setCallStatusLoader] = useState(false);
    const [emailFilterLog,setEmailFilterLog] = useState([])
    const [callLogFilter,setCallLogFilter] = useState([])
    const [smsFilterLog,setSMSFilterLog] = useState([])
    const [whatsAppData,setWhatsAppData] = useState([])
    const [callValue,setCallValue] = useState({
        // statusVal:'',
        // dateVal:'',
        callTypeVal:'',
        callConnectVal:''
    });
    const [filterCommunication,setFilterCommunication] = useState({
        "CsrStatus":'',
        "CallConnected":'',
        "CallIncoming":'',
        "CallConnectedVal":'',
        "CallIncomingVal":''
    })

    const [callConnected,setCallConnected] = useState('all');
    const [callIncoming,setCallIncoming] = useState('all');
    const [csrStatus,setCsrStatus]=useState('all');
    const [allCommunication,setAllCommunication]=useState(communicationData)
    const [selectDate,setSelectDate] = useState([]);
    const [statusDropdownData,setStatusDropdownData] = useState({
        allStatus:'',
        callLogStatus:'',
        emailStatus:'',
        smsStatus:''
    });
    const [showModal,setShowModal] = useState(false);
    const [showConfirmModal,setShowConfirmModal] = useState(false);
    const [templatData,setTemplatData] = useState({});
    const [isBtnshow,setIsBtnshow] = useState(false);
    const [selectStatus,setSelectStatus]= useState([]);
    const [statusData,setStatusData]=useState('');
    const [communicationData,setCommunicationData] = useState([]);
    const [commHistoryID,setCommHistoryID] = useState(null);
    const [type ,setType] = useState('');
    const [callLogData ,setCallLogData] = useState(callLogFilter);
    const [emailLogData ,setEmailLogData] = useState(emailFilterLog);
    const [smsLogData ,setSMSLogData] = useState(smsFilterLog);
    const [whatsAppLogData ,setWhatsAppLogData] = useState(smsFilterLog);
    const [isDisabledClear,setIsDisabledClear] = useState(true);
    const [selectCallType,setSelectCallType]  = useState([
        {
            value: "Inbound",
            isValid:true
        },
        {
			value: "Outbound",
            isValid:false
		},
    ]);
    const [selectCallConnect,setSelectCallConnect]  = useState([
        {
            value: "Yes",
            isValid:true
        },
        {
			value: "No",
            isValid:false
		},
    ]);
    const [activeLink,setActiveLink]= useState('all')
    const [showSuccessModal,setShowSuccessModal] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate:null,
        endDate: null,
    });
    const [callDispositionArr,setCallDispositionArr] = useState();
    let maxDate = new Date();
    let date1 = new Date(dateRange?.startDate);
    let date2 = new Date(dateRange?.endDate);
    let diff = date2.getTime() - date1.getTime();
    console.log("diff",diff/0)
    const typeCheck = {
        'callLog':'call log',
        'email':'email',
        'sms':'sms',
        'whatsapp':'whatsapp'

    }
    useEffect(() => {
        getCommunicationLogs();
        getCallDispositionValue();
    }, [])

    useEffect(()=>{
        communicationFilterData()
        clearFilter()
    },[communicationData,activeLink])

    useEffect(() => {
        applyFilter();
        //setIsDisabledClear(false);
    }, [callConnected,callIncoming,csrStatus,filterCommunication])
    
    useEffect(()=>{
        if(filterCommunication.CsrStatus
            || filterCommunication.CallConnected 
            || filterCommunication.CallIncoming
            ||dateRange.startDate
            ||dateRange.endDate
            ){
            setIsDisabledClear(false);
        }
    },[filterCommunication,dateRange])

    const clearFilter = () =>{
        setFilterCommunication({
            "CsrStatus":'',
            "CallConnected":'',
            "CallIncoming":'',
            "CallConnectedVal":'',
            "CallIncomingVal":''
        })
        setCsrStatus('all')
        setCallIncoming('all')
        setCallConnected('all')
        // setIsDisabledClear(true);
        // setDateRange({
        //     startDate:null,
        //     endDate: null,
        // })
    }
    const callLogTabs = [
        {
            showTab:true,
            callType:'All',
            tabId:'all',
            customClass:'nav-list',
            onClickTab:(e)=>{
                setActiveLink(e.target.id)
            }
        },{
            showTab:true,
            callType:'Call Logs',
            tabId:'callLogs',
            customClass:'nav-list',
            onClickTab:(e)=>{
                setActiveLink(e.target.id)
            }
        }, {
            showTab:true,
            callType:'Emails',
            tabId:'emails',
            customClass:'nav-list',
            onClickTab:(e)=>{
                setActiveLink(e.target.id)
            }
        } ,{
            showTab:true,
            callType:'SMS',
            tabId:'sms',
            customClass:'nav-list',
            onClickTab:(e)=>{
                // setCsrStatus('')
                // setCallIncoming('')
                // setCallConnected('')
                setActiveLink(e.target.id)
            }
        },{
            showTab:true,
            callType:'WhatsApp',
            tabId:'whatsapp',
            customClass:'nav-list',
            onClickTab:(e)=>{
                // setCsrStatus('')
                // setCallIncoming('')
                // setCallConnected('')
                setActiveLink(e.target.id)
            }
        }
    ]

    const handleOnClear = ()=>{
        setFilterCommunication({
            "CsrStatus":'',
            "CallConnected":'',
            "CallIncoming":'',
            "CallConnectedVal":'',
            "CallIncomingVal":''
        })
        setCsrStatus('all')
        setCallIncoming('all')
        setCallConnected('all')
        setDateRange({
            startDate:null,
            endDate:null
        })
        getCommunicationLogs();
        setIsDisabledClear(true);
    }

    
    const applyFilter = ()=>{
        let filteredItems = [...communicationData];
        if(activeLink=='all'){
            const allFilter =  filteredItems.filter(item=>{
                const createdAt = moment(item.CreatedDate);
                const startOfToday = moment(dateRange.startDate).startOf('day');
                const endOfToday = moment(dateRange.endDate).endOf('day');
                let isBetween = createdAt.isBetween(startOfToday, endOfToday);
                if((csrStatus =='all' || item.CsrStatus==csrStatus) && 
                    ((!dateRange.startDate || !dateRange.endDate) || isBetween))
                    {
                        return item;
                    }
            })
            setAllCommunication(allFilter)
        }

        if(activeLink == 'callLogs') {
            const filter = filteredItems.filter(item => {
                if((item.CommsType=='call log')) {
                    const createdAt = moment(item.CreatedDate);
                    const startOfToday = moment(dateRange.startDate).startOf('day');
                    const endOfToday = moment(dateRange.endDate).endOf('day');
                    let isBetween = createdAt.isBetween(startOfToday, endOfToday);
                    if((callConnected == 'all' || item.CallConnected == callConnected) &&
                        (callIncoming == 'all' || item.CallIncoming == callIncoming) &&
                        (csrStatus == 'all' || item.CsrStatus == csrStatus) && 
                        ((!dateRange.startDate || !dateRange.endDate) || isBetween))
                    {
                        return item;
                    }
                }
            });
            setCallLogData(filter);
        }

        if(activeLink=='emails'){
            const filterEmail = filteredItems.filter(item=>{
                if((item.CommsType=='email')) {
                    const createdAt = moment(item.CreatedDate);
                    const startOfToday = moment(dateRange.startDate).startOf('day');
                    const endOfToday = moment(dateRange.endDate).endOf('day');
                    let isBetween = createdAt.isBetween(startOfToday, endOfToday);
                    if((csrStatus == 'all' || item.CsrStatus == csrStatus) &&
                        ((!dateRange.startDate || !dateRange.endDate) || isBetween))
                    {
                        return item;
                    }
                }
            })
            setEmailLogData(filterEmail)
        }
        if(activeLink=='sms'){
            const filterSms = filteredItems.filter(item=>{
                if((item.CommsType=='sms')) {
                    const createdAt = moment(item.CreatedDate);
                    const startOfToday = moment(dateRange.startDate).startOf('day');
                    const endOfToday = moment(dateRange.endDate).endOf('day');
                    let isBetween = createdAt.isBetween(startOfToday, endOfToday);
                    if((csrStatus == 'all' || item.CsrStatus == csrStatus) &&
                        ((!dateRange.startDate || !dateRange.endDate) || isBetween))
                    {
                        return item;
                    }
                }
            })
            setSMSLogData(filterSms)
        }

        if(activeLink=='whatsapp'){
            const filterSms = filteredItems.filter(item=>{
                if((item.CommsType=='whatsapp')) {
                    const createdAt = moment(item.CreatedDate);
                    const startOfToday = moment(dateRange.startDate).startOf('day');
                    const endOfToday = moment(dateRange.endDate).endOf('day');
                    let isBetween = createdAt.isBetween(startOfToday, endOfToday);
                    if((csrStatus == 'all' || item.CsrStatus == csrStatus) &&
                        ((!dateRange.startDate || !dateRange.endDate) || isBetween))
                    {
                        return item;
                    }
                }
            })
            setWhatsAppLogData(filterSms)
        }
    }

    const handleResend = (commsId,type) =>{
        let id = commsId;
        let typeStr = type;
        Swal.fire({
            html: `<h4 style="font-weight: 600; margin-top:0px; font-size:25px;">${localString( `Are you sure you want to resend ${ typeStr} ?`)}</h4>`,
            icon: "question",
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: localString("NO"),
            confirmButtonText: localString("YES")
        }).then(function (result) {
            if (result.isConfirmed) {
                getReTrigger(id,type)
            }
        });
    }

    const handleSuccess = (type) =>{
        let typeStr = type;
        Swal.fire({
            html: `<h1 style="font-weight: 600; margin:0px; font-size:25px;">${localString(`${typeStr == typeCheck.whatsapp ? 'Whatsapp Message' : typeStr == typeCheck.sms ? 'SMS':'Email'} Sent`)}</h1></br><h4 style="font-weight: 400; margin-top:0px; font-size:16px;">${localString( `The ${typeStr} ${typeStr == typeCheck.whatsapp ? 'message':''} was resent successfully.`)}</h4>`,
            icon: "success",
            showConfirmButton: false,
            showCancelButton: false,
            cancelButtonText: localString("NO"),
            confirmButtonText: localString("YES")
        }).then(function (result) {
            if (result.isConfirmed) {
                
            }
        });
    }

    const getCommunicationLogs = () => {
		let data = {
			'ConsumerServiceRequestID': consumerServiceRequest.ConsumerServiceRequestID
		}
		setLoader(true);
		apiCall({
			url: 'getCommunicationLogsDetails',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    setCommunicationData(result.data);
                    let callLogs = result.data;
                    const statusData = callLogs && callLogs.map(v=>({...v,value:v.CsrStatus}));
                    statusData.sort(function (csrFirst, csrTwo) {
                        if (csrFirst.value < csrTwo.value) {
                            return -1;
                        }
                        if (csrFirst.value > csrTwo.value) {
                            return 1;
                        }
                        return 0;
                    });
                    const uniqValue= uniqBy(statusData,(obj)=>obj.value);
                    setSelectStatus(uniqValue);
                }else{
                    Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
                }
			}
		});
	}

    const getReTrigger = (commHistoryID,type) => {
		setLoader(true);
		apiCall({
			url: 'reTrigger',
			data: {'CommsHistoryID':commHistoryID},
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    handleSuccess(type);
                    getCommunicationLogs();
                }else{
                    Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
                }
			}
		});
	}

    const getFetch = (id) => {
		let data = {
            filter:{'CommsHistoryID':22584}
        }
		setLoader(true);
		apiCall({
			url: 'getFetch',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
                    let fetchData = JSON.parse(result.data[0].CommsRequest); 
                    setTemplatData(fetchData);
                    setShowModal(true)
                }else{
                    Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
                }
			}
		});
	}

    const getCallDispositionValue = function(){
        var data = {};
        setCallStatusLoader(true)
        apiCall({
			url: 'getCallStatus',
			data: data,
			callBack:(result)=>{
				setCallStatusLoader(false);
				if(result.success){
                    let callDispositionArray = concat(result.data.callConnected, result.data.callNotConnected,result.data.inboundCall);
                    setCallDispositionArr(callDispositionArray);
                }else{
                    Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
                }
			}
		});
    }

    const getCallStatus = (callStatusId)=>{
        let callStatus = find(callDispositionArr, {id: callStatusId});
        if (callStatus && callStatus.displayText) {
            return callStatus.displayText;
        }else{
            return '-';
        }
    }
    const communicationFilterData = (data)=>{
        let localFilterData = [...communicationData]
        let emailData = []
        let callLogData=[]
        let smsData = []
        let whatsAppData = []
        let filterData = localFilterData.filter(logs=>{
            if(logs.CommsType=='email'){
                emailData.push(logs)
            }
            if(logs.CommsType=='call log'){
                callLogData.push(logs)
            }
            if(logs.CommsType=='sms'){
                smsData.push(logs)
            }
            if(logs.CommsType=='whatsapp'){
                whatsAppData.push(logs)
            }
            // 
        })
        setSMSFilterLog(smsData)
        setEmailFilterLog(emailData)
        setCallLogFilter(callLogData)
        setWhatsAppData(whatsAppData);
    }

    return(
        <>
            { (loader || callStatusLoader )  && ReactDOM.createPortal(
                <Loader />,
                document.getElementById("loader-root")
            )}
            <div className="inner-padding communication-tab">
                <div className="row">
                    <PageContentWrapper bodyClassName="panel-body-no-padding" title={localString("Communication History")}>
                        <CallLogTabs 
                            {...props}
                            callLogTabs = {callLogTabs}
                            callValue = {callValue}
                            setCallValue = {setCallValue}
                            selectDate = {selectDate}
                            selectCallType={selectCallType}
                            setSelectCallType={setSelectCallType}
                            selectCallConnect={selectCallConnect}
                            activeLink={activeLink}
                            statusDropdownData={statusDropdownData}
                            setCallLogFilter={setCallLogFilter}
                            selectStatus={selectStatus}
                            statusData = {statusData}
                            setStatusData = {setStatusData}
                            // filterByStatus={filterByStatus}
                            maxDate={maxDate}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            handleDateRangeApply={applyFilter}
                            setFilterCommunication={setFilterCommunication}
                            filterCommunication={filterCommunication}
                            callConnected={callConnected}
                            setCallConnected={setCallConnected}
                            callIncoming = {callIncoming}
                            setCallIncoming={setCallIncoming}
                            setCsrStatus={setCsrStatus}
                            csrStatus={csrStatus}
                            communicationFilterData={ communicationFilterData}
                            handleOnClear={handleOnClear}
                            isDisabledClear={isDisabledClear}
                        />
                        {
                            <CommunicationHistoryDetails
                                {...props}
                                activeLink = {activeLink}
                                communicationData = {communicationData}
                                callLogFilter={callLogFilter.callLogData}
                                emailFilterLog={emailFilterLog}
                                smsFilterLog={smsFilterLog}
                                getReTrigger={getReTrigger}
                                showModal = {showModal}
                                setShowModal = {setShowModal}
                                showConfirmModal={showConfirmModal}
                                setShowConfirmModal={setShowConfirmModal}
                                templatData = {templatData}
                                isBtnshow = {isBtnshow}
                                type ={type}
                                setType = {setType}
                                callLogData ={callLogData}
                                setCallLogData={setCallLogData}
                                emailLogData={emailLogData}
                                smsLogData={smsLogData}
                                allCommunication={allCommunication}
                                handleResend={handleResend}
                                showSuccessModal = {showSuccessModal}
                                typeCheck = {typeCheck}
                                getCallStatus={getCallStatus}
                                whatsAppData = {whatsAppData}
                                whatsAppLogData = {whatsAppLogData}
                            />
                        }
                        
                    </PageContentWrapper>
                </div>
            </div>
        </>
    )
}
export default CommunicationHistory;