const lang = {
	"Sign in to continue.": {
		"text": "登录并继续。",
		"isAlreadyTranslated": true
	},
	"Username": {
		"text": "用户名",
		"isAlreadyTranslated": true
	},
	"Forgot Password?": {
		"text": "忘记密码？",
		"isAlreadyTranslated": true
	},
	"Next": {
		"text": "下一个",
		"isAlreadyTranslated": true
	},
	"No symptoms added": {
		"text": "没有症状增加",
		"isAlreadyTranslated": true
	},
	"Logistics - From Customer's Address To Service Center": {
		"text": "物流 - 从客户的地址到服务中心",
		"isAlreadyTranslated": true
	},
	"Waybill": {
		"text": "运单",
		"isAlreadyTranslated": true
	},
	"Verification Code": {
		"text": "验证码",
		"isAlreadyTranslated": true
	},
	"On": {
		"text": "上",
		"isAlreadyTranslated": true
	},
	"at": {
		"text": "在",
		"isAlreadyTranslated": true
	},
	"Registration Document": {
		"text": "登记文件",
		"isAlreadyTranslated": true
	},
	"REQUESTS": {
		"text": "工单管理",
		"isAlreadyTranslated": true
	},
	"Requests": {
		"text": "工单管理",
		"isAlreadyTranslated": true
	},
	"Logout": {
		"text": "退出",
		"isAlreadyTranslated": true
	},
	"Claim Request": {
		"text": "保险工单",
		"isAlreadyTranslated": true
	},
	"Open": {
		"text": "待工程师接收",
		"isAlreadyTranslated": true
	},
	"Search brands": {
		"text": "搜索品牌",
		"isAlreadyTranslated": true
	},
	"In Progress": {
		"text": "进行中",
		"isAlreadyTranslated": true
	},
	"Closed": {
		"text": "已完成",
		"isAlreadyTranslated": true
	},
	"Cancelled": {
		"text": "已取消",
		"isAlreadyTranslated": true
	},
	"Today": {
		"text": "今天",
		"isAlreadyTranslated": true
	},
	"Yesterday": {
		"text": "昨天",
		"isAlreadyTranslated": true
	},
	"Last 7 Days": {
		"text": "最近7天",
		"isAlreadyTranslated": true
	},
	"Last 30 Days": {
		"text": "最近30天",
		"isAlreadyTranslated": true
	},
	"This Month": {
		"text": "这个月",
		"isAlreadyTranslated": true
	},
	"Custom Range": {
		"text": "自定义范围",
		"isAlreadyTranslated": true
	},
	"Consumer Name": {
		"text": "客户名称",
		"isAlreadyTranslated": true
	},
	"Serial No": {
		"text": "序列号",
		"isAlreadyTranslated": true
	},
	"Service Type": {
		"text": "服务类型",
		"isAlreadyTranslated": true
	},
	"Logistics Provider": {
		"text": "物流供应商",
		"isAlreadyTranslated": true
	},
	"Registration Source": {
		"text": "工单来源",
		"isAlreadyTranslated": true
	},
	"Customer City": {
		"text": "客户城市",
		"isAlreadyTranslated": true
	},
	"Zipcode": {
		"text": "邮政编码",
		"isAlreadyTranslated": true
	},
	"Incident ID/External ReferenceID": {
		"text": "事件ID /外部ReferenceID",
		"isAlreadyTranslated": true
	},
	"No engineers found": {
		"text": "没有找到工程师",
		"isAlreadyTranslated": true
	},
	"Call Type": {
		"text": "呼叫类型",
		"isAlreadyTranslated": true
	},
	"IMEI码/Serial No": {
		"text": "IMEI码/序列号",
		"isAlreadyTranslated": true
	},
	"Schedule": {
		"text": "时间",
		"isAlreadyTranslated": true
	},
	"service requests are shown above.": {
		"text": "服务请求如上所示。",
		"isAlreadyTranslated": true
	},
	"Email ID": {
		"text": "电子邮件ID",
		"isAlreadyTranslated": true
	},
	"Back to all requests": {
		"text": "返回所有工单列表",
		"isAlreadyTranslated": true
	},
	"Add a Product": {
		"text": "添加产品",
		"isAlreadyTranslated": true
	},
	"Select a Brand": {
		"text": "选择一个品牌",
		"isAlreadyTranslated": true
	},
	"Search subcategories": {
		"text": "搜索小类",
		"isAlreadyTranslated": true
	},
	"Enter IMEI number": {
		"text": "输入IMEI号码",
		"isAlreadyTranslated": true
	},
	"Enter Serial number": {
		"text": "输入序列号",
		"isAlreadyTranslated": true
	},
	"Select Brand": {
		"text": "选择品牌",
		"isAlreadyTranslated": true
	},
	"Select a Subcategory": {
		"text": "选择一个产品分类",
		"isAlreadyTranslated": true
	},
	"Select Subcategory": {
		"text": "选择子目录",
		"isAlreadyTranslated": true
	},
	"Select a Product": {
		"text": "选择一个产品",
		"isAlreadyTranslated": true
	},
	"Select Product": {
		"text": "选择产品",
		"isAlreadyTranslated": true
	},
	"Add Selected Product": {
		"text": "添加所选产品",
		"isAlreadyTranslated": true
	},
	"OnePlus 5": {
		"text": "万普拉斯5",
		"isAlreadyTranslated": true
	},
	"Mobile": {
		"text": "手机",
		"isAlreadyTranslated": true
	},
	" One Plus": {
		"text": " 一个加",
		"isAlreadyTranslated": true
	},
	"OK": {
		"text": "好",
		"isAlreadyTranslated": true
	},
	"Loading": {
		"text": "载入中",
		"isAlreadyTranslated": true
	},
	"undefined": {
		"text": "未定义",
		"isAlreadyTranslated": true
	},
	"Select a Type": {
		"text": "选择一种类型",
		"isAlreadyTranslated": true
	},
	"Normal Repair": {
		"text": "一般维修",
		"isAlreadyTranslated": true
	},
	"Select Customer Type": {
		"text": "选择客户类型",
		"isAlreadyTranslated": true
	},
	"Walk-in Customer": {
		"text": "送修",
		"isAlreadyTranslated": true
	},
	"Future Appointment": {
		"text": "寄修",
		"isAlreadyTranslated": true
	},
	"Select an Address": {
		"text": "选择一个地址",
		"isAlreadyTranslated": true
	},
	"Add New Address": {
		"text": "添加新地址",
		"isAlreadyTranslated": true
	},
	"Landmark": {
		"text": "里程碑",
		"isAlreadyTranslated": true
	},
	"Address": {
		"text": "地址",
		"isAlreadyTranslated": true
	},
	"Pincode": {
		"text": "邮政编码",
		"isAlreadyTranslated": true
	},
	"Office": {
		"text": "办公室",
		"isAlreadyTranslated": true
	},
	"Select Issues": {
		"text": "选择问题",
		"isAlreadyTranslated": true
	},
	"Search issues": {
		"text": "搜索问题",
		"isAlreadyTranslated": true
	},
	"Other": {
		"text": "其他类",
		"isAlreadyTranslated": true
	},
	"Other Issue": {
		"text": "其他问题",
		"isAlreadyTranslated": true
	},
	"Others": {
		"text": "其他",
		"isAlreadyTranslated": true
	},
	"Please enter issue description": {
		"text": "请输入问题描述",
		"isAlreadyTranslated": true
	},
	"Yes": {
		"text": "是",
		"isAlreadyTranslated": true
	},
	"Cancel": {
		"text": "取消",
		"isAlreadyTranslated": true
	},
	"Role": {
		"text": "角色",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive": {
		"text": "前台",
		"isAlreadyTranslated": true
	},
	"Engineer": {
		"text": "工程师",
		"isAlreadyTranslated": true
	},
	"QC Engineer": {
		"text": "QC工程师",
		"isAlreadyTranslated": true
	},
	"Store Manager": {
		"text": "店经理",
		"isAlreadyTranslated": true
	},
	"Supervisor": {
		"text": "监",
		"isAlreadyTranslated": true
	},
	"Choose another account": {
		"text": "选择其他帐户",
		"isAlreadyTranslated": true
	},
	"Add Consumer": {
		"text": "添加消费者",
		"isAlreadyTranslated": true
	},
	"Reset": {
		"text": "重置",
		"isAlreadyTranslated": true
	},
	"Mobile number": {
		"text": "手机号码",
		"isAlreadyTranslated": true
	},
	"Full name": {
		"text": "全名",
		"isAlreadyTranslated": true
	},
	"Select a Product to View Diagnosis": {
		"text": "选择要展示诊断结果的产品",
		"isAlreadyTranslated": true
	},
	"No serviceable consumer product found": {
		"text": "未发现可用的消费品",
		"isAlreadyTranslated": true
	},
	"This mobile number is not registered with us.": {
		"text": "此手机号码未注册。",
		"isAlreadyTranslated": true
	},
	"Close": {
		"text": "关闭",
		"isAlreadyTranslated": true
	},
	"Something went wrong": {
		"text": "发生错误",
		"isAlreadyTranslated": true
	},
	"Oops... Something went wrong.": {
		"text": "哎呀…发生错误",
		"isAlreadyTranslated": true
	},
	"Customer Name": {
		"text": "客户姓名",
		"isAlreadyTranslated": true
	},
	"Customer Mobile": {
		"text": "客户手机号码",
		"isAlreadyTranslated": true
	},
	"Customer Email": {
		"text": "客户邮箱",
		"isAlreadyTranslated": true
	},
	"Customer Alternate Mobile": {
		"text": "客户备用手机号码",
		"isAlreadyTranslated": true
	},
	"IMEI": {
		"text": "IMEI码",
		"isAlreadyTranslated": true
	},
	"Initiate App Diagnostic": {
		"text": "启动应用程序诊断",
		"isAlreadyTranslated": true
	},
	"History": {
		"text": "历史",
		"isAlreadyTranslated": true
	},
	"Diagnosed on": {
		"text": "已诊断",
		"isAlreadyTranslated": true
	},
	"Diagnostic Report": {
		"text": "诊断报告",
		"isAlreadyTranslated": true
	},
	"Type": {
		"text": "类型",
		"isAlreadyTranslated": true
	},
	"Diagnosed": {
		"text": "已诊断",
		"isAlreadyTranslated": true
	},
	"Issue": {
		"text": "发料",
		"isAlreadyTranslated": true
	},
	"Issues": {
		"text": "故障",
		"isAlreadyTranslated": true
	},
	"issues": {
		"text": "故障",
		"isAlreadyTranslated": true
	},
	"Found": {
		"text": "已发现",
		"isAlreadyTranslated": true
	},
	"No Issues Found": {
		"text": "未发现任何问题",
		"isAlreadyTranslated": true
	},
	"No diagnosis performed": {
		"text": "未进行任何诊断",
		"isAlreadyTranslated": true
	},
	"Diagnosis Result": {
		"text": "诊断结果",
		"isAlreadyTranslated": true
	},
	"Diagnosis History": {
		"text": "诊断历史",
		"isAlreadyTranslated": true
	},
	"No diagnosis history found": {
		"text": "未找到任何诊断历史",
		"isAlreadyTranslated": true
	},
	"No issues": {
		"text": "无任何问题",
		"isAlreadyTranslated": true
	},
	"No diagnosis found for this consumer product": {
		"text": "未发现就此消费品的任何诊断",
		"isAlreadyTranslated": true
	},
	"Initiate diagnosis": {
		"text": "启动诊断",
		"isAlreadyTranslated": true
	},
	"Notification type": {
		"text": "通知类型",
		"isAlreadyTranslated": true
	},
	"Message": {
		"text": "消息",
		"isAlreadyTranslated": true
	},
	"Apply a filter before sending push notification": {
		"text": "在推送通知发送前进行筛选",
		"isAlreadyTranslated": true
	},
	"Something went wrong while sending notifications": {
		"text": "发送通知时出错",
		"isAlreadyTranslated": true
	},
	"Template": {
		"text": "模板",
		"isAlreadyTranslated": true
	},
	"Subject": {
		"text": "主题",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching templates": {
		"text": "获取模板时出错",
		"isAlreadyTranslated": true
	},
	"Apply a filter before sending an email": {
		"text": "发送邮件前进行筛选",
		"isAlreadyTranslated": true
	},
	"Select a predefined template": {
		"text": "选择预设的模板",
		"isAlreadyTranslated": true
	},
	"Something went wrong while sending the email": {
		"text": "发送邮件时出错",
		"isAlreadyTranslated": true
	},
	"Name": {
		"text": "姓名",
		"isAlreadyTranslated": true
	},
	"Email": {
		"text": "邮箱地址",
		"isAlreadyTranslated": true
	},
	"Subcategory": {
		"text": "产品分类",
		"isAlreadyTranslated": true
	},
	"Brand": {
		"text": "品牌",
		"isAlreadyTranslated": true
	},
	"Product": {
		"text": "产品",
		"isAlreadyTranslated": true
	},
	"Registered Consumer": {
		"text": "已注册的消费者",
		"isAlreadyTranslated": true
	},
	"Check this if you want to select registered consumers": {
		"text": "如果要选择已注册的消费者，请选中此项",
		"isAlreadyTranslated": true
	},
	"Check this if you want to select temp consumers": {
		"text": "如果要选择临时的消费者，请选中此项",
		"isAlreadyTranslated": true
	},
	"Check this if you want send notifications. This check will only return registeredconsumers/temp consumers that have deviceTokens": {
		"text": "如果要发送通知，请选中此项。选中此项后通知将会送达至已获取了型号令牌的注册消费者或临时消费者",
		"isAlreadyTranslated": true
	},
	"Check this if you want send emails. This check will only return registered consumers/temp consumers that have email IDs": {
		"text": "如果要发送邮件知，请选中此项。选中此项后邮件将会送达至已提交了邮箱ID的注册消费者或临时消费者",
		"isAlreadyTranslated": true
	},
	"Reset filters": {
		"text": "重置筛选项",
		"isAlreadyTranslated": true
	},
	"No filter applied": {
		"text": "未进行筛选",
		"isAlreadyTranslated": true
	},
	"Something erroneous happened": {
		"text": "出现错误",
		"isAlreadyTranslated": true
	},
	"Enter an approved sms message": {
		"text": "输入已获准的短信息",
		"isAlreadyTranslated": true
	},
	"Apply a filter before sending a SMS": {
		"text": "在发送短信息前进行筛选",
		"isAlreadyTranslated": true
	},
	"Coupons": {
		"text": "优惠券",
		"isAlreadyTranslated": true
	},
	"Redeemed Coupons": {
		"text": "兑换优惠券",
		"isAlreadyTranslated": true
	},
	"Last Month": {
		"text": "上个月",
		"isAlreadyTranslated": true
	},
	"Last Week": {
		"text": "上一周",
		"isAlreadyTranslated": true
	},
	"Last Day": {
		"text": "前一天",
		"isAlreadyTranslated": true
	},
	"Coupon Code": {
		"text": "优惠码",
		"isAlreadyTranslated": true
	},
	"Description": {
		"text": "描述",
		"isAlreadyTranslated": true
	},
	"No. Of Users": {
		"text": "用户数量",
		"isAlreadyTranslated": true
	},
	"Put 99999999999 if applicable to all": {
		"text": "若适用于所有人，可输入99999999999",
		"isAlreadyTranslated": true
	},
	"Min Amount": {
		"text": "最小数量",
		"isAlreadyTranslated": true
	},
	"Start Date": {
		"text": "开始日期",
		"isAlreadyTranslated": true
	},
	"Start date of the coupon": {
		"text": "优惠券的开始日期",
		"isAlreadyTranslated": true
	},
	"End Date": {
		"text": "终止日期",
		"isAlreadyTranslated": true
	},
	"End date of the coupon": {
		"text": "优惠券的终止日期",
		"isAlreadyTranslated": true
	},
	"Coupon Type": {
		"text": "优惠券类型",
		"isAlreadyTranslated": true
	},
	"Type: cashback, discount or referral": {
		"text": "类型：返现、折扣或优惠",
		"isAlreadyTranslated": true
	},
	"Incentive Type": {
		"text": "优惠类型",
		"isAlreadyTranslated": true
	},
	"Applicable only after sign up": {
		"text": "只有在注册后才可适用",
		"isAlreadyTranslated": true
	},
	"Select incentive type after selecting coupon type": {
		"text": "在选择优惠券类型后可选择优惠类型",
		"isAlreadyTranslated": true
	},
	"Incentive Value": {
		"text": "优惠的价格",
		"isAlreadyTranslated": true
	},
	"Active coupon": {
		"text": "有效优惠券",
		"isAlreadyTranslated": true
	},
	"Create Coupon": {
		"text": "创建优惠券",
		"isAlreadyTranslated": true
	},
	"Please fill out this field": {
		"text": "请填写此字段",
		"isAlreadyTranslated": true
	},
	"Please fill value greater than 0": {
		"text": "请输入大于0的价值",
		"isAlreadyTranslated": true
	},
	"End Date cannot be less than start date": {
		"text": "终止日期不能在起始日期之前",
		"isAlreadyTranslated": true
	},
	"Incentive value cannot exceed": {
		"text": "不可超出优惠价格",
		"isAlreadyTranslated": true
	},
	"Select a Coupon Type and Incentive Type first": {
		"text": "首先选择优惠券类型与优惠类型",
		"isAlreadyTranslated": true
	},
	"Coupon Name": {
		"text": "优惠券名称",
		"isAlreadyTranslated": true
	},
	"Applied": {
		"text": "已使用",
		"isAlreadyTranslated": true
	},
	"Redeemed": {
		"text": "已得到补偿",
		"isAlreadyTranslated": true
	},
	"Created on": {
		"text": "已创建于",
		"isAlreadyTranslated": true
	},
	"Edit": {
		"text": "编辑",
		"isAlreadyTranslated": true
	},
	"Plan Type": {
		"text": "方案类型",
		"isAlreadyTranslated": true
	},
	"Extra Plans": {
		"text": "额外方案",
		"isAlreadyTranslated": true
	},
	"Number of Plans": {
		"text": "方案数量",
		"isAlreadyTranslated": true
	},
	"No. of Sales (Today)": {
		"text": "销售数量（当天）",
		"isAlreadyTranslated": true
	},
	"Sale Value (Today)": {
		"text": "销售价值（当天）",
		"isAlreadyTranslated": true
	},
	"No. of Sales (Till Date)": {
		"text": "销售数量（累计至今）",
		"isAlreadyTranslated": true
	},
	"Sale Value (Till Date)": {
		"text": "销售价值（累计至今）",
		"isAlreadyTranslated": true
	},
	"No Plans": {
		"text": "尚无任何方案",
		"isAlreadyTranslated": true
	},
	"Consumer Details": {
		"text": "消费者详情",
		"isAlreadyTranslated": true
	},
	"Report is empty": {
		"text": "报告空白",
		"isAlreadyTranslated": true
	},
	"Date": {
		"text": "日期",
		"isAlreadyTranslated": true
	},
	"Invoice No": {
		"text": "发票号",
		"isAlreadyTranslated": true
	},
	"Plan": {
		"text": "方案",
		"isAlreadyTranslated": true
	},
	"Plan Price": {
		"text": "方案价格",
		"isAlreadyTranslated": true
	},
	"Source": {
		"text": "来源",
		"isAlreadyTranslated": true
	},
	"Status": {
		"text": "状态",
		"isAlreadyTranslated": true
	},
	"Customer": {
		"text": "客户信息",
		"isAlreadyTranslated": true
	},
	"Last": {
		"text": "上一个",
		"isAlreadyTranslated": true
	},
	"First": {
		"text": "第一个",
		"isAlreadyTranslated": true
	},
	"Plans": {
		"text": "方案",
		"isAlreadyTranslated": true
	},
	"Plan ID": {
		"text": "方案ID",
		"isAlreadyTranslated": true
	},
	"Plan Code": {
		"text": "方案代码",
		"isAlreadyTranslated": true
	},
	"Plan Name": {
		"text": "方案名称",
		"isAlreadyTranslated": true
	},
	"Plan Validity": {
		"text": "方案有效情况",
		"isAlreadyTranslated": true
	},
	"Oops... Something went wrong!": {
		"text": "哎呀…发生错误",
		"isAlreadyTranslated": true
	},
	"Transaction Summary": {
		"text": "交易概况",
		"isAlreadyTranslated": true
	},
	"Select brand": {
		"text": "选择品牌",
		"isAlreadyTranslated": true
	},
	"Back": {
		"text": "返回",
		"isAlreadyTranslated": true
	},
	"Check Eligibility": {
		"text": "检查要求情况",
		"isAlreadyTranslated": true
	},
	"Available Plans": {
		"text": "可用方案",
		"isAlreadyTranslated": true
	},
	"These are the plans available for the device": {
		"text": "如下为此型号可用的方案",
		"isAlreadyTranslated": true
	},
	"Serial Number": {
		"text": "序列号",
		"isAlreadyTranslated": true
	},
	"Select a Plan": {
		"text": "选择方案",
		"isAlreadyTranslated": true
	},
	"Sell Now": {
		"text": "现在就卖出",
		"isAlreadyTranslated": true
	},
	"Know more": {
		"text": "了解更多",
		"isAlreadyTranslated": true
	},
	"No Plans Available": {
		"text": "尚无可用方案",
		"isAlreadyTranslated": true
	},
	"Date of Purchase": {
		"text": "购入日期",
		"isAlreadyTranslated": true
	},
	"Buy plan": {
		"text": "购买方案",
		"isAlreadyTranslated": true
	},
	"Do you want to buy the plan": {
		"text": "您是否想购买此方案",
		"isAlreadyTranslated": true
	},
	"Cancel purchase": {
		"text": "取消购买",
		"isAlreadyTranslated": true
	},
	"Device not eligible": {
		"text": "型号不符合要求",
		"isAlreadyTranslated": true
	},
	"Device is not eligible for": {
		"text": "型号不符合此项的要求：",
		"isAlreadyTranslated": true
	},
	"View Plans": {
		"text": "查看方案",
		"isAlreadyTranslated": true
	},
	"Device is not eligible for the plan": {
		"text": "型号不符合此方案的要求",
		"isAlreadyTranslated": true
	},
	"Continuing means the partner has verified the details and he will bear all losses or related expenses if there is a mis-match and fraudulent claim later": {
		"text": "如继续操作则视为合作伙伴已对详细信息进行了验证，若之后出现信息不匹配或欺诈相关的索赔情况时，其应对所有损失负责。",
		"isAlreadyTranslated": true
	},
	"Incorrect IMEI/Serial Number": {
		"text": "IMEI码或序列号不正确",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI/Serial Number": {
		"text": "请输入正确的IMEI码或序列号",
		"isAlreadyTranslated": true
	},
	"Device is eligible": {
		"text": "此型号符合要求",
		"isAlreadyTranslated": true
	},
	"Device is eligible for multiple plans. Please choose a plan": {
		"text": "此型号符合多种方案的要求，请选择一项方案",
		"isAlreadyTranslated": true
	},
	"This device is not eligible for any plan": {
		"text": "此型号不符合任何方案的要求",
		"isAlreadyTranslated": true
	},
	"Sorry": {
		"text": "抱歉",
		"isAlreadyTranslated": true
	},
	"Invalid IMEI/Serial Number": {
		"text": "IMEI码或序列号无效",
		"isAlreadyTranslated": true
	},
	"Device is eligible for the purchase of the plans shown. Please select to continue": {
		"text": "型号符合购买所给出方案的要求，请选取方案以继续",
		"isAlreadyTranslated": true
	},
	"Filter Requests": {
		"text": "工单筛选",
		"isAlreadyTranslated": true
	},
	"FILTERREQUESTS": {
		"text": "工单筛选",
		"isAlreadyTranslated": true
	},
	"Period": {
		"text": "选择时间",
		"isAlreadyTranslated": true
	},
	"Data displayed": {
		"text": "显示的数据",
		"isAlreadyTranslated": true
	},
	"No Data Available": {
		"text": "无可用数据",
		"isAlreadyTranslated": true
	},
	"Reference ID": {
		"text": "工单号",
		"isAlreadyTranslated": true
	},
	"Customer Details": {
		"text": "客户详细信息",
		"isAlreadyTranslated": true
	},
	"Device": {
		"text": "型号",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial No": {
		"text": "IMEI码或序列号",
		"isAlreadyTranslated": true
	},
	"Pledged To eRecycle in": {
		"text": "承诺进入eRecycle",
		"isAlreadyTranslated": true
	},
	"Created": {
		"text": "创建日期",
		"isAlreadyTranslated": true
	},
	"View": {
		"text": "查看",
		"isAlreadyTranslated": true
	},
	"optin requests are shown above": {
		"text": "Optin的请求如上所示",
		"isAlreadyTranslated": true
	},
	"out of": {
		"text": "共",
		"isAlreadyTranslated": true
	},
	"Contact No": {
		"text": "联系电话",
		"isAlreadyTranslated": true
	},
	"Mobile Number": {
		"text": "客户手机号码",
		"isAlreadyTranslated": true
	},
	"Alternate Mobile No": {
		"text": "客户备用手机号码",
		"isAlreadyTranslated": true
	},
	"Device Details": {
		"text": "型号详细信息",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial Number": {
		"text": "IMEI码或序列号",
		"isAlreadyTranslated": true
	},
	"Device Added Date": {
		"text": "型号添加日期",
		"isAlreadyTranslated": true
	},
	"Request Details": {
		"text": "工单详情",
		"isAlreadyTranslated": true
	},
	"Optin Rewards Chosen": {
		"text": "已选择的Optin奖励",
		"isAlreadyTranslated": true
	},
	"Green Rewards Chosen": {
		"text": "已选择的Green奖励",
		"isAlreadyTranslated": true
	},
	"Invite Service User": {
		"text": "邀请服务的用户",
		"isAlreadyTranslated": true
	},
	"Invite Retailer User": {
		"text": "邀请零售商用户",
		"isAlreadyTranslated": true
	},
	"Email Id": {
		"text": "邮箱ID",
		"isAlreadyTranslated": true
	},
	"User Role": {
		"text": "用户状态",
		"isAlreadyTranslated": true
	},
	"No users found": {
		"text": "未发现任何用户",
		"isAlreadyTranslated": true
	},
	"Filter": {
		"text": "筛选",
		"isAlreadyTranslated": true
	},
	"Select User Type": {
		"text": "选择用户类型",
		"isAlreadyTranslated": true
	},
	"Partner User": {
		"text": "合作用户",
		"isAlreadyTranslated": true
	},
	"Create Request": {
		"text": "建立请求",
		"isAlreadyTranslated": true
	},
	"Upload Status Report": {
		"text": "上传状态报告",
		"isAlreadyTranslated": true
	},
	"No Logistics Requests Found": {
		"text": "未发现任何物流请求",
		"isAlreadyTranslated": true
	},
	"WayBill Number": {
		"text": "陆运单编号",
		"isAlreadyTranslated": true
	},
	"Reference Number": {
		"text": "参考识别号",
		"isAlreadyTranslated": true
	},
	"Pickup Name": {
		"text": "收件人姓名",
		"isAlreadyTranslated": true
	},
	"Pickup Address": {
		"text": "收件人地址",
		"isAlreadyTranslated": true
	},
	"Drop Name": {
		"text": "寄件人姓名",
		"isAlreadyTranslated": true
	},
	"Drop Address": {
		"text": "寄件人地址",
		"isAlreadyTranslated": true
	},
	"Pickup Contact No": {
		"text": "收件人电话号码",
		"isAlreadyTranslated": true
	},
	"Drop Contact No": {
		"text": "寄件人电话号码",
		"isAlreadyTranslated": true
	},
	"No records found": {
		"text": "未找到任何记录",
		"isAlreadyTranslated": true
	},
	"Status Date": {
		"text": "状态日期",
		"isAlreadyTranslated": true
	},
	"Please download the sample file, make changes in it only and upload it": {
		"text": "请下载样本文件，并仅在样本文件内进行修改，之后进行上传",
		"isAlreadyTranslated": true
	},
	"Download File": {
		"text": "下载文件",
		"isAlreadyTranslated": true
	},
	"Upload File": {
		"text": "上传文件",
		"isAlreadyTranslated": true
	},
	"Submit File": {
		"text": "提交文件",
		"isAlreadyTranslated": true
	},
	"Waybill Number": {
		"text": "陆运单编号",
		"isAlreadyTranslated": true
	},
	"Pickup Pin Code": {
		"text": "收件人Pin码",
		"isAlreadyTranslated": true
	},
	"Drop Pin Code": {
		"text": "寄件人Pin码",
		"isAlreadyTranslated": true
	},
	"Vendor": {
		"text": "卖家",
		"isAlreadyTranslated": true
	},
	"Organisation": {
		"text": "组织",
		"isAlreadyTranslated": true
	},
	"Flow": {
		"text": "流程",
		"isAlreadyTranslated": true
	},
	"Clear": {
		"text": "清除",
		"isAlreadyTranslated": true
	},
	"Apply": {
		"text": "确认",
		"isAlreadyTranslated": true
	},
	"Re-apply": {
		"text": "确认",
		"isAlreadyTranslated": true
	},
	"Something went wrong. Please upload again": {
		"text": "上传过程出错，请重新上传",
		"isAlreadyTranslated": true
	},
	"Invalid column headers. You can use sample file": {
		"text": "列头无效。您可以直接使用样本文件。",
		"isAlreadyTranslated": true
	},
	"Please upload a valid file. You can use sample file": {
		"text": "请上传有效的文件。您可以直接使用样本文件。",
		"isAlreadyTranslated": true
	},
	"Invalid data": {
		"text": "日期无效",
		"isAlreadyTranslated": true
	},
	"Something went wrong. Your request may have timed out": {
		"text": "发生错误，您的请求已超时",
		"isAlreadyTranslated": true
	},
	"The data has been saved successfully": {
		"text": "数据已保存成功",
		"isAlreadyTranslated": true
	},
	"There are no requests to download": {
		"text": "无下载请求",
		"isAlreadyTranslated": true
	},
	"Something went wrong...!": {
		"text": "发生错误…！",
		"isAlreadyTranslated": true
	},
	"Cancel request": {
		"text": "取消请求",
		"isAlreadyTranslated": true
	},
	"View requests": {
		"text": "查看请求",
		"isAlreadyTranslated": true
	},
	"Map Logistics Request": {
		"text": "地图物流请求",
		"isAlreadyTranslated": true
	},
	"Please enter the Reference ID of the service request": {
		"text": "请输入服务请求下的工单号",
		"isAlreadyTranslated": true
	},
	"Reference No": {
		"text": "参考识别号",
		"isAlreadyTranslated": true
	},
	"Pickup No": {
		"text": "收件人电话号码",
		"isAlreadyTranslated": true
	},
	"Download Delivery Challan": {
		"text": "下载交货单",
		"isAlreadyTranslated": true
	},
	"Download Logistics Label": {
		"text": "下载物流标识",
		"isAlreadyTranslated": true
	},
	"Pickup Date": {
		"text": "收件日期",
		"isAlreadyTranslated": true
	},
	"Pickup Time": {
		"text": "收件时间",
		"isAlreadyTranslated": true
	},
	"Package Details": {
		"text": "包裹详情",
		"isAlreadyTranslated": true
	},
	"Product Price": {
		"text": "产品价格",
		"isAlreadyTranslated": true
	},
	"Model": {
		"text": "产品型号",
		"isAlreadyTranslated": true
	},
	"Brand Name": {
		"text": "品牌名称",
		"isAlreadyTranslated": true
	},
	"Product Name": {
		"text": "产品名称",
		"isAlreadyTranslated": true
	},
	"Logistics Images": {
		"text": "物流图像",
		"isAlreadyTranslated": true
	},
	"Please enter the remarks": {
		"text": "请输入评论",
		"isAlreadyTranslated": true
	},
	"Total Unregistered Products": {
		"text": "所有未登记的产品",
		"isAlreadyTranslated": true
	},
	"Upload": {
		"text": "上传",
		"isAlreadyTranslated": true
	},
	"Oops": {
		"text": "哎呀",
		"isAlreadyTranslated": true
	},
	"Please upload only .csv file.": {
		"text": "请只上传.csv后缀名的文件",
		"isAlreadyTranslated": true
	},
	"Cannot read file": {
		"text": "无法读取文件",
		"isAlreadyTranslated": true
	},
	"Invalid Format": {
		"text": "格式无效",
		"isAlreadyTranslated": true
	},
	"Invalid name for Product": {
		"text": "此产品的名称无效",
		"isAlreadyTranslated": true
	},
	"More than 2 fields supplied": {
		"text": "字段超过2个",
		"isAlreadyTranslated": true
	},
	"File provided is not in required format": {
		"text": "所提供的文件格式不符合要求",
		"isAlreadyTranslated": true
	},
	"Some products failed": {
		"text": "部分产品失败",
		"isAlreadyTranslated": true
	},
	"File uploaded": {
		"text": "文件已上传",
		"isAlreadyTranslated": true
	},
	"Success": {
		"text": "成功",
		"isAlreadyTranslated": true
	},
	"No file found": {
		"text": "未找到任何文件",
		"isAlreadyTranslated": true
	},
	"Please upload a csv file": {
		"text": "请上传csv文件",
		"isAlreadyTranslated": true
	},
	"Not charging": {
		"text": "不收费",
		"isAlreadyTranslated": true
	},
	"Advance amount can't be updated at Present status": {
		"text": "在当前状态下无法更新预付金额",
		"isAlreadyTranslated": true
	},
	"Already booked": {
		"text": "已经预定好了",
		"isAlreadyTranslated": true
	},
	"Request cannot be cancelled right now. Please try again later": {
		"text": "请求目前无法取消。请稍后再试",
		"isAlreadyTranslated": true
	},
	"No consumer found": {
		"text": "找不到消费者",
		"isAlreadyTranslated": true
	},
	"No pincode found": {
		"text": "没有找到密码",
		"isAlreadyTranslated": true
	},
	"no product details found": {
		"text": "没有找到产品细节",
		"isAlreadyTranslated": true
	},
	"No Logistics Detail Found": {
		"text": "找不到物流明细",
		"isAlreadyTranslated": true
	},
	"Customer Pincode is not serviceable by Our Logistics Partner. Please send the device to the Customer using other Courier Services near you": {
		"text": "客户Pincode无法由我们的物流合作伙伴提供服务。请使用您附近的其他快递服务将型号发送给客户",
		"isAlreadyTranslated": true
	},
	"Pincode not found": {
		"text": "找不到Pin码",
		"isAlreadyTranslated": true
	},
	"Oops. Looks like no service center found": {
		"text": "哎呀。看起来没有找到服务中心",
		"isAlreadyTranslated": true
	},
	"Oops. Looks like none of our engineers are present to service this product in your area. But we’re hard at work and you should get some good news soon. Try later?": {
		"text": "哎呀。看起来我们的工程师都没有在您所在地区为此产品提供服务。但我们努力工作，你很快就会得到一些好消息。稍后再试？",
		"isAlreadyTranslated": true
	},
	"Serial number validation failed": {
		"text": "序列号验证失败",
		"isAlreadyTranslated": true
	},
	"Please provide valid Partner Service Location ID": {
		"text": "请提供有效的合作伙伴服务位置ID",
		"isAlreadyTranslated": true
	},
	"Please close other service": {
		"text": "请关闭其他服务",
		"isAlreadyTranslated": true
	},
	"Could not schedule your request. Please try again after some time.": {
		"text": "无法安排您的请求。请过一段时间再试一次。",
		"isAlreadyTranslated": true
	},
	"Request created successfully": {
		"text": "请求已成功创建",
		"isAlreadyTranslated": true
	},
	"No consumer product found with given consumer product id": {
		"text": "没有找到具有特定消费者产品ID的消费品",
		"isAlreadyTranslated": true
	},
	"Could not schedule your request.": {
		"text": "无法安排您的请求。",
		"isAlreadyTranslated": true
	},
	"No issue found": {
		"text": "没有发现问题",
		"isAlreadyTranslated": true
	},
	"No delivery mode found": {
		"text": "找不到送货方式",
		"isAlreadyTranslated": true
	},
	"error occurred": {
		"text": "发生了错误",
		"isAlreadyTranslated": true
	},
	"Dispatch initiated": {
		"text": "派遣发起",
		"isAlreadyTranslated": true
	},
	"Please Provide Valid RequestID": {
		"text": "请提供有效的RequestID",
		"isAlreadyTranslated": true
	},
	"No slot available": {
		"text": "没有可用的插槽",
		"isAlreadyTranslated": true
	},
	"The request is not at the required status for the given action: Generate vouchers first": {
		"text": "请求未达到给定操作的必需状态：首先生成凭证",
		"isAlreadyTranslated": true
	},
	"Your iPhone has been eRecycled. Ref ID:": {
		"text": "您的iPhone已经过eRecycled。工单号：",
		"isAlreadyTranslated": true
	},
	"No result found": {
		"text": "没有找到结果",
		"isAlreadyTranslated": true
	},
	"No need to create bulk shipment": {
		"text": "无需创建批量发货",
		"isAlreadyTranslated": true
	},
	"No address found": {
		"text": "找不到地址",
		"isAlreadyTranslated": true
	},
	"No need to create pick up request": {
		"text": "无需创建提货请求",
		"isAlreadyTranslated": true
	},
	"No status found": {
		"text": "找不到状态",
		"isAlreadyTranslated": true
	},
	"Insufficient Parameters - Either wayBillNo or ConsumerServiceRequestID is required": {
		"text": "参数不足 - 需要wayBillNo或ConsumerServiceRequestID",
		"isAlreadyTranslated": true
	},
	"Error in creating entry in ConsumerServicerequestLogistics": {
		"text": "在ConsumerServicerequestLogistics中创建条目时出错",
		"isAlreadyTranslated": true
	},
	"Success in creating entry in ConsumerServicerequestLogistics": {
		"text": "成功在ConsumerServicerequestLogistics中创建条目",
		"isAlreadyTranslated": true
	},
	"No request found with given refid": {
		"text": "没有给出refid的请求",
		"isAlreadyTranslated": true
	},
	"No Result Found With Given RequestID": {
		"text": "使用给定的RequestID找不到结果",
		"isAlreadyTranslated": true
	},
	"No Result Found With Given LogisticsRequestID": {
		"text": "使用给定的LogisticsRequestID找不到结果",
		"isAlreadyTranslated": true
	},
	"Error in finding ConsumerServicerequestLogistics for": {
		"text": "找到ConsumerServicerequestLogistics时出错",
		"isAlreadyTranslated": true
	},
	"Error in updating remarks": {
		"text": "更新备注时出错",
		"isAlreadyTranslated": true
	},
	"successfully request created": {
		"text": "成功请求已创建",
		"isAlreadyTranslated": true
	},
	"no ref id found": {
		"text": "没有发现任何引用",
		"isAlreadyTranslated": true
	},
	"no ConsumerServicerequestLogistics found with LogisticsRequestID": {
		"text": "没有使用LogisticsRequestID找到ConsumerServicerequestLogistics",
		"isAlreadyTranslated": true
	},
	"no data found for": {
		"text": "找不到相关数据",
		"isAlreadyTranslated": true
	},
	"Error in fetching data for": {
		"text": "获取数据时出错",
		"isAlreadyTranslated": true
	},
	"no logistics request detail found for id": {
		"text": "没有找到id的物流请求详细信息",
		"isAlreadyTranslated": true
	},
	"Your phone's pick up has been scheduled": {
		"text": "您的手机已经安排好了",
		"isAlreadyTranslated": true
	},
	"No Current Location Found For ": {
		"text": "找不到当前位置",
		"isAlreadyTranslated": true
	},
	"notification sent": {
		"text": "发送通知",
		"isAlreadyTranslated": true
	},
	"device not supported": {
		"text": "型号不受支持",
		"isAlreadyTranslated": true
	},
	"cannot find consumer": {
		"text": "找不到消费者",
		"isAlreadyTranslated": true
	},
	"Something went wrong while An email blast was attempted for this list of consumers": {
		"text": "在为此消费者列表尝试电子邮件爆炸时出现问题",
		"isAlreadyTranslated": true
	},
	"Something went wrong while sendOneEmail": {
		"text": "sendOneEmail出了点问题",
		"isAlreadyTranslated": true
	},
	"Something went wrong while sending notifications to this list of consumers and temp consumers": {
		"text": "向此消费者和临时消费者列表发送通知时出现问题",
		"isAlreadyTranslated": true
	},
	"Notification sent to this list of consumers and temp consumers": {
		"text": "通知发送到此消费者和临时消费者列表",
		"isAlreadyTranslated": true
	},
	"Something went wrong while An SMS blast was attempted for this list of consumers": {
		"text": "在为这个消费者名单尝试短信爆炸时出了点问题",
		"isAlreadyTranslated": true
	},
	"An SMS blast was attempted for this list of consumers": {
		"text": "针对此消费者名单尝试了SMS爆炸",
		"isAlreadyTranslated": true
	},
	"Something went wrong while sending sms": {
		"text": "发送短信时出错了",
		"isAlreadyTranslated": true
	},
	"Payment received": {
		"text": "已收到付款",
		"isAlreadyTranslated": true
	},
	"payment initialized": {
		"text": "付款已初始化",
		"isAlreadyTranslated": true
	},
	"payment done": {
		"text": "付款完成",
		"isAlreadyTranslated": true
	},
	"No payment occurred": {
		"text": "没有付款",
		"isAlreadyTranslated": true
	},
	"cannot find a partner": {
		"text": "找不到合作伙伴",
		"isAlreadyTranslated": true
	},
	"fill servicingly table creation": {
		"text": "服务表创建",
		"isAlreadyTranslated": true
	},
	"Please provide correct data": {
		"text": "请提供正确的数据",
		"isAlreadyTranslated": true
	},
	"Payment is not received": {
		"text": "没有收到付款",
		"isAlreadyTranslated": true
	},
	"Your payment is received": {
		"text": "我们收到了您的付款",
		"isAlreadyTranslated": true
	},
	"Amount to be paid does not match": {
		"text": "支付金额不符",
		"isAlreadyTranslated": true
	},
	"Partner billing update failed": {
		"text": "合作伙伴结算更新失败",
		"isAlreadyTranslated": true
	},
	"Payment received successfully": {
		"text": "付款已成功收到",
		"isAlreadyTranslated": true
	},
	"Invalid Device Details": {
		"text": "型号详细信息无效",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send either ConsumerServiceRequestID or PlanArray": {
		"text": "参数不足 - 发送ConsumerServiceRequestID或PlanArray",
		"isAlreadyTranslated": true
	},
	"Plan Purchased": {
		"text": "计划购买",
		"isAlreadyTranslated": true
	},
	"( ) has been successfully Purchased and Activated for your device": {
		"text": "（）已成功为您的型号购买并激活",
		"isAlreadyTranslated": true
	},
	"( ) has been successfully Purchased for your device ": {
		"text": "（）已成功购买您的型号",
		"isAlreadyTranslated": true
	},
	"Advance payment captured": {
		"text": "已预付款",
		"isAlreadyTranslated": true
	},
	"Error occured in capturing advance payment": {
		"text": "捕获预付款时出错",
		"isAlreadyTranslated": true
	},
	"We could not suggest an appropriate engineer": {
		"text": "我们无法建议合适的工程师",
		"isAlreadyTranslated": true
	},
	"Already booked on this date and slot": {
		"text": "已在此日期和时段预订",
		"isAlreadyTranslated": true
	},
	"Your service request is Rescheduled": {
		"text": "您的服务请求已重新安排",
		"isAlreadyTranslated": true
	},
	"Xpert is not assigned to this service": {
		"text": "Xpert未分配给此服务",
		"isAlreadyTranslated": true
	},
	"Service request in not at required stage": {
		"text": "服务请求不在必要阶段",
		"isAlreadyTranslated": true
	},
	"Service request not found": {
		"text": "未找到服务请求",
		"isAlreadyTranslated": true
	},
	"Engineer not found": {
		"text": "找不到工程师",
		"isAlreadyTranslated": true
	},
	"No hub found": {
		"text": "找不到集线器",
		"isAlreadyTranslated": true
	},
	"parts are available": {
		"text": "零件可用",
		"isAlreadyTranslated": true
	},
	"Not all parts are available": {
		"text": "并非所有部件都可用",
		"isAlreadyTranslated": true
	},
	"Inventory updated": {
		"text": "库存已更新",
		"isAlreadyTranslated": true
	},
	"No inventory updation": {
		"text": "没有库存更新",
		"isAlreadyTranslated": true
	},
	"replaced parts": {
		"text": "更换零件",
		"isAlreadyTranslated": true
	},
	"No parts found": {
		"text": "找不到零件",
		"isAlreadyTranslated": true
	},
	"mac verified successfully": {
		"text": "mac验证成功",
		"isAlreadyTranslated": true
	},
	"Payment cannot be captured": {
		"text": "付款无法捕获",
		"isAlreadyTranslated": true
	},
	"Authentication failed": {
		"text": "验证失败",
		"isAlreadyTranslated": true
	},
	"Payment already captured": {
		"text": "付款已被捕获",
		"isAlreadyTranslated": true
	},
	"Oops. We don’t service this device at the moment. But we’re hard at work and you should get some good news soon. Try later?": {
		"text": "哎呀。我们目前不提供此型号。但我们努力工作，你很快就会得到一些好消息。稍后再试？",
		"isAlreadyTranslated": true
	},
	"Something went wrong in fetching from db": {
		"text": "从db获取时出错了",
		"isAlreadyTranslated": true
	},
	"No FAQ Category Data Found": {
		"text": "没有找到FAQ类别数据",
		"isAlreadyTranslated": true
	},
	"Error in Fetching FAQ": {
		"text": "获取常见问题解答出错",
		"isAlreadyTranslated": true
	},
	"Please Provide Valid Category ID": {
		"text": "请提供有效的类别ID",
		"isAlreadyTranslated": true
	},
	"Cannot create recycle request": {
		"text": "无法创建回收请求",
		"isAlreadyTranslated": true
	},
	"Your Pickup Request For eRecycling Your iPhone Has Been Accepted | Ref. ID:": {
		"text": "您的电子转回请求接受您的iPhone已被接受|参考。 ID：",
		"isAlreadyTranslated": true
	},
	"Welcome to eRecycle For iPhones | Ref. ID:": {
		"text": "欢迎使用eRecycle for iPhone |参考。 ID：",
		"isAlreadyTranslated": true
	},
	"Your Friend Has Chosen You as The Next Green Warrior!": {
		"text": "你的朋友选择了你作为下一个绿色战士！",
		"isAlreadyTranslated": true
	},
	"No logistics action need to be done": {
		"text": "不需要采取任何后勤行动",
		"isAlreadyTranslated": true
	},
	"Couldn't find a consumer product with the given ID": {
		"text": "无法找到具有给定ID的消费者产品",
		"isAlreadyTranslated": true
	},
	"No consumer service request found by the given ID.": {
		"text": "没有找到给定ID的消费者服务请求。",
		"isAlreadyTranslated": true
	},
	"You Have Been Acknowledged as a True Green Warrior!": {
		"text": "你被承认为真正的绿色战士！",
		"isAlreadyTranslated": true
	},
	"Congratulations! Your iPhone has been Verified For eRecycling | Ref. ID:": {
		"text": "恭喜！您的iPhone已经过eRecycling验证参考。 ID：",
		"isAlreadyTranslated": true
	},
	"We have an update about your chosen Green Reward | Ref. ID: ": {
		"text": "我们有关于您所选择的绿色奖励的最新信息参考。 ID：",
		"isAlreadyTranslated": true
	},
	"cannot edit the vouchers": {
		"text": "无法编辑优惠券",
		"isAlreadyTranslated": true
	},
	"IMEI eligible": {
		"text": "IMEI符合条件",
		"isAlreadyTranslated": true
	},
	"IMEI not eligible": {
		"text": "IMEI没有资格",
		"isAlreadyTranslated": true
	},
	"IMEI already used for subscription": {
		"text": "IMEI已用于订阅",
		"isAlreadyTranslated": true
	},
	"Error in updating product details": {
		"text": "更新产品详细信息时出错",
		"isAlreadyTranslated": true
	},
	"product successfully updated": {
		"text": "产品成功更新",
		"isAlreadyTranslated": true
	},
	"No consumer product found with given id": {
		"text": "找不到具有给定ID的消费产品",
		"isAlreadyTranslated": true
	},
	"Reward generated": {
		"text": "奖励生成",
		"isAlreadyTranslated": true
	},
	"You have successfully pledged to eRecycle your iPhone in": {
		"text": "您已成功承诺将eRecycle放入您的iPhone中",
		"isAlreadyTranslated": true
	},
	"We will remind you to submit your iPhone for eRecycling a few days prior to your chosen month. \nYou can choose one more Reward when you submit your pledged iPhone for eRecycling in ": {
		"text": "我们会提醒您在您选择的月份前几天提交您的iPhone以进行电子转回。 \n当您提交承诺的iPhone进行电子转回时，您可以再选择一个奖励",
		"isAlreadyTranslated": true
	},
	"Document cannot be added": {
		"text": "无法添加文档",
		"isAlreadyTranslated": true
	},
	"Pending document": {
		"text": "待审文件",
		"isAlreadyTranslated": true
	},
	"No document obtained": {
		"text": "没有获得文件",
		"isAlreadyTranslated": true
	},
	"Issue not found": {
		"text": "找不到问题",
		"isAlreadyTranslated": true
	},
	"Reward Partner not found": {
		"text": "找不到奖励合作伙伴",
		"isAlreadyTranslated": true
	},
	"After your 3rd eRecycle request all your requests deserve a lot more than Green Rewards. For this request, you are eligible for a lifetime supply of good Green Karma!": {
		"text": "在您的第三次eRecycle请求之后，您的所有请求都比Green Rewards要多得多。对于这个要求，您有资格获得终身供应的优质Green Karma！",
		"isAlreadyTranslated": true
	},
	"You are eligible for reward": {
		"text": "您有资格获得奖励",
		"isAlreadyTranslated": true
	},
	"You are not eligible for reward as you have exceeded the eRecycle limit": {
		"text": "由于您已超出eRecycle限制，因此您没有资格获得奖励",
		"isAlreadyTranslated": true
	},
	"Not a manual reward": {
		"text": "不是人工奖励",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send PartnerServiceLocationID": {
		"text": "参数不足 - 发送PartnerServiceLocationID",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send ConsumerServiceRequestID": {
		"text": "参数不足 - 发送ConsumerServiceRequestID",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send ServiceTypeID": {
		"text": "参数不足 - 发送ServiceTypeID",
		"isAlreadyTranslated": true
	},
	"This servive type does not exist": {
		"text": "此servive类型不存在",
		"isAlreadyTranslated": true
	},
	"Something went wrong with getParts": {
		"text": "getParts出了点问题",
		"isAlreadyTranslated": true
	},
	"Parts List has been sent": {
		"text": "零件清单已发送",
		"isAlreadyTranslated": true
	},
	"Engineer is present": {
		"text": "工程师在场",
		"isAlreadyTranslated": true
	},
	"Engineer is not present": {
		"text": "工程师不在场",
		"isAlreadyTranslated": true
	},
	"Failed to get engineer details": {
		"text": "无法获得工程师详细信息",
		"isAlreadyTranslated": true
	},
	"Please provide correct productivity per day.": {
		"text": "请提供每日正确的工作效率。",
		"isAlreadyTranslated": true
	},
	"Your attendance is marked.": {
		"text": "您的出勤已标记。",
		"isAlreadyTranslated": true
	},
	"Failed to mark attendance.": {
		"text": "未能标记出勤率。",
		"isAlreadyTranslated": true
	},
	"Failed to find the Engineer": {
		"text": "找不到工程师",
		"isAlreadyTranslated": true
	},
	"Unusual exception occurred. Please try again after some time.": {
		"text": "发生了异常的异常。请过一段时间再试一次。",
		"isAlreadyTranslated": true
	},
	"Timings updated successfully .": {
		"text": "计时更新成功。",
		"isAlreadyTranslated": true
	},
	"service is not at required state": {
		"text": "服务不是必需的状态",
		"isAlreadyTranslated": true
	},
	"Parts added": {
		"text": "零件添加",
		"isAlreadyTranslated": true
	},
	"Parts updating failed": {
		"text": "部件更新失败",
		"isAlreadyTranslated": true
	},
	"Other costs added": {
		"text": "其他费用增加了",
		"isAlreadyTranslated": true
	},
	"Advance payment added": {
		"text": "预付款已添加",
		"isAlreadyTranslated": true
	},
	"Parts removed": {
		"text": "零件已移除",
		"isAlreadyTranslated": true
	},
	"Discount fetching failed": {
		"text": "折扣提取失败",
		"isAlreadyTranslated": true
	},
	"Tax information fetching failed": {
		"text": "税收信息提取失败",
		"isAlreadyTranslated": true
	},
	"Discount can not be greater than the service amount": {
		"text": "折扣不能大于服务金额",
		"isAlreadyTranslated": true
	},
	"Amount": {
		"text": "金额",
		"isAlreadyTranslated": true
	},
	"Received By": {
		"text": "收到",
		"isAlreadyTranslated": true
	},
	"Discount or Advance can not be greater than the payable amount.": {
		"text": "折扣或预付款不得超过应付金额。",
		"isAlreadyTranslated": true
	},
	"Invoice has been generated with Service requestId -": {
		"text": "已使用服务requestId生成发票 - ",
		"isAlreadyTranslated": true
	},
	"Request charges fetching failed": {
		"text": "请求费用提取失败",
		"isAlreadyTranslated": true
	},
	"Update service request failed": {
		"text": "更新服务请求失败",
		"isAlreadyTranslated": true
	},
	"Service request is not at required stage.": {
		"text": "服务请求不在必需阶段。",
		"isAlreadyTranslated": true
	},
	"Your delivery request has been successfully completed.": {
		"text": "您的送货请求已成功完成。",
		"isAlreadyTranslated": true
	},
	"Parts requested": {
		"text": "要求的零件",
		"isAlreadyTranslated": true
	},
	"List of available engineer": {
		"text": "可用工程师名单",
		"isAlreadyTranslated": true
	},
	"Could not assign engineer, assign manually": {
		"text": "无法指派工程师，手动分配",
		"isAlreadyTranslated": true
	},
	"Job is already assign to another Engineer": {
		"text": "工作已经分配给另一位工程师",
		"isAlreadyTranslated": true
	},
	"No service request found for this ConsumerServiceRequestID": {
		"text": "未找到此ConsumerServiceRequestID的服务请求",
		"isAlreadyTranslated": true
	},
	"No engineer found for this ServiceLocationEngineerID": {
		"text": "没有工程师找到此ServiceLocationEngineerID",
		"isAlreadyTranslated": true
	},
	"A new repair job is assigned to you! Please \"Accept\" the job to initiate the repair process. Job ID: ": {
		"text": "为您分配了一个新的维修工作！请'接受'该作业以启动修复过程。工作ID：",
		"isAlreadyTranslated": true
	},
	"Pending for approval": {
		"text": "待批准",
		"isAlreadyTranslated": true
	},
	"Engineer assigned jobs not updated |": {
		"text": "工程师分配的工作未更新|",
		"isAlreadyTranslated": true
	},
	"Create consumer service request assign service location |": {
		"text": "创建消费者服务请求分配服务位置|",
		"isAlreadyTranslated": true
	},
	"Job is assigned to you successfully": {
		"text": "作业已成功分配给您",
		"isAlreadyTranslated": true
	},
	"Service log creation failed": {
		"text": "服务日志创建失败",
		"isAlreadyTranslated": true
	},
	"Engineer service log creation failed": {
		"text": "工程师服务日志创建失败",
		"isAlreadyTranslated": true
	},
	"Please provide valid input": {
		"text": "请提供有效的输入",
		"isAlreadyTranslated": true
	},
	"Engineer is not registered with this service.": {
		"text": "工程师未注册此服务。",
		"isAlreadyTranslated": true
	},
	"Service not found for the assignment.": {
		"text": "未找到任务的服务。",
		"isAlreadyTranslated": true
	},
	"All requests has been sent": {
		"text": "所有请求都已发送",
		"isAlreadyTranslated": true
	},
	"Service request is not assigned to the Engineer": {
		"text": "服务请求未分配给工程师",
		"isAlreadyTranslated": true
	},
	"Job has already started": {
		"text": "工作已经开始了",
		"isAlreadyTranslated": true
	},
	"Delivery of this device is pending. Please Wait for sometime": {
		"text": "该型号的交付正在等待中。请等一下",
		"isAlreadyTranslated": true
	},
	"Job started": {
		"text": "工作开始了",
		"isAlreadyTranslated": true
	},
	"Please complete job in progress": {
		"text": "请完成正在进行的工作",
		"isAlreadyTranslated": true
	},
	"Engineer is not registered with this service": {
		"text": "工程师未注册此服务",
		"isAlreadyTranslated": true
	},
	"Service request is not at required stage": {
		"text": "服务请求不在必需阶段",
		"isAlreadyTranslated": true
	},
	"Service initiated": {
		"text": "服务已启动",
		"isAlreadyTranslated": true
	},
	"request information fetching failed": {
		"text": "请求信息提取失败",
		"isAlreadyTranslated": true
	},
	"Engineer is not register with this service": {
		"text": "工程师未注册此服务",
		"isAlreadyTranslated": true
	},
	"Service is not at required stage": {
		"text": "服务不在必需阶段",
		"isAlreadyTranslated": true
	},
	"Engineer reached": {
		"text": "工程师达成了",
		"isAlreadyTranslated": true
	},
	"Product fetching failed": {
		"text": "产品提取失败",
		"isAlreadyTranslated": true
	},
	"Service completed successfully": {
		"text": "服务成功完成",
		"isAlreadyTranslated": true
	},
	"No  Jobs available": {
		"text": "没有工作可用",
		"isAlreadyTranslated": true
	},
	"Closed Jobs has been sent": {
		"text": "已发送已关闭的工作",
		"isAlreadyTranslated": true
	},
	"Active jobs has been sent": {
		"text": "已发送活动作业",
		"isAlreadyTranslated": true
	},
	"Task added": {
		"text": "任务已添加",
		"isAlreadyTranslated": true
	},
	"Deletion successful": {
		"text": "删除成功",
		"isAlreadyTranslated": true
	},
	"No such task to remove": {
		"text": "没有这样的任务要删除",
		"isAlreadyTranslated": true
	},
	"Notification updates": {
		"text": "通知更新",
		"isAlreadyTranslated": true
	},
	"Successfully created": {
		"text": "成功创建",
		"isAlreadyTranslated": true
	},
	"Please provide a valid ReferenceID": {
		"text": "请提供有效的ReferenceID",
		"isAlreadyTranslated": true
	},
	"Service location updates fetched": {
		"text": "获取服务位置更新",
		"isAlreadyTranslated": true
	},
	"No entries found for the given ConsumerServiceRequestID": {
		"text": "找不到给定ConsumerServiceRequestID的条目",
		"isAlreadyTranslated": true
	},
	"Updated result": {
		"text": "更新结果",
		"isAlreadyTranslated": true
	},
	"No entries found for the given ServicelocationUpdatesID": {
		"text": "找不到给定ServicelocationUpdatesID的条目",
		"isAlreadyTranslated": true
	},
	"No entries found for the given ConsumerID": {
		"text": "找不到给定ConsumerID的条目",
		"isAlreadyTranslated": true
	},
	"Outlet location id": {
		"text": "出口位置ID",
		"isAlreadyTranslated": true
	},
	"No outlet found": {
		"text": "找不到插座",
		"isAlreadyTranslated": true
	},
	"Eligibility for option rewards": {
		"text": "选项奖励的资格",
		"isAlreadyTranslated": true
	},
	"As you have pledged more than 3 iPhones, you will now be eligible only for the Green Karma Reward.": {
		"text": "由于您已经承诺超过3部iPhone，您现在只有资格获得Green Karma奖励。",
		"isAlreadyTranslated": true
	},
	"Compute results": {
		"text": "计算结果",
		"isAlreadyTranslated": true
	},
	"Looks like you have previously pledged to eRecycle your iPhones What would you like to do?": {
		"text": "看起来您之前曾承诺过eRecycle您的iPhone您想做什么？",
		"isAlreadyTranslated": true
	},
	"As you have eRecycled more than 3 iPhones, you will now be eligible only for the Green Karma Reward.": {
		"text": "由于您已经回收了超过3部iPhone，您现在只能获得Green Karma奖励。",
		"isAlreadyTranslated": true
	},
	"No subscription obtained": {
		"text": "没有订阅",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send SubscriptionDetailsID": {
		"text": "参数不足 - 发送SubscriptionDetailsID",
		"isAlreadyTranslated": true
	},
	"No subscription found for the product": {
		"text": "未找到该产品的订阅",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send SubscriptionState": {
		"text": "参数不足 - 发送SubscriptionState",
		"isAlreadyTranslated": true
	},
	"subscription request details": {
		"text": "订阅请求详细信息",
		"isAlreadyTranslated": true
	},
	"initialized consumer": {
		"text": "初始化消费者",
		"isAlreadyTranslated": true
	},
	"failed to initialize consumer": {
		"text": "未能初始化消费者",
		"isAlreadyTranslated": true
	},
	"Something went wrong with default device addition": {
		"text": "添加默认型号出了点问题",
		"isAlreadyTranslated": true
	},
	"Failed to create consumerProducts": {
		"text": "无法创建consumerProducts",
		"isAlreadyTranslated": true
	},
	"Failed to create ConsumerUnregisteredProducts": {
		"text": "无法创建ConsumerUnregisteredProducts",
		"isAlreadyTranslated": true
	},
	"successfully created default temp consumer product": {
		"text": "成功创建了默认临时消费产品",
		"isAlreadyTranslated": true
	},
	"OTP does not match": {
		"text": "OTP不匹配",
		"isAlreadyTranslated": true
	},
	"No entity created": {
		"text": "没有实体创建",
		"isAlreadyTranslated": true
	},
	"Something went wrong in registering other devices": {
		"text": "注册其他型号时出错了",
		"isAlreadyTranslated": true
	},
	"Invalid input": {
		"text": "输入无效",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching filtered temp consumers": {
		"text": "获取过滤的临时使用者时出错了",
		"isAlreadyTranslated": true
	},
	"List of filtered temp consumers": {
		"text": "过滤的临时使用者列表",
		"isAlreadyTranslated": true
	},
	"Sms success": {
		"text": "短信成功",
		"isAlreadyTranslated": true
	},
	"device token not in the expected format": {
		"text": "型号令牌不是预期的格式",
		"isAlreadyTranslated": true
	},
	"Your home repair request has been registered. You will receive confirmation 30mins prior to the requested time slot.": {
		"text": "您的上门维修申请已经注册。您将在要求的时间段前30分钟收到确认。",
		"isAlreadyTranslated": true
	},
	"Your carry in repair request for ( ) at ( ) has been registered. Please check track repair section to get the direction to the location.": {
		"text": "（）（）at（）的随身携带维修请求已经注册。请检查轨道维修部分以获取到该位置的方向。",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for ( )": {
		"text": "您的重新安排申请已被接受（）",
		"isAlreadyTranslated": true
	},
	"( ) is your OTP for Servify. This OTP is valid for 120 seconds. Do not share this OTP with anyone for security reasons.": {
		"text": "（）是您的Servify OTP。该OTP有效期为120秒。出于安全原因，请勿与任何人共享此OTP。",
		"isAlreadyTranslated": true
	},
	"Download the Servify app from ( ) and get a better service experience.  Servify - for all your service needs.": {
		"text": "从（）下载Servify应用程序，获得更好的服务体验。 Servify - 满足您的所有服务需求。",
		"isAlreadyTranslated": true
	},
	"Download Servify - Personal assistant to all your electronics and home appliances.": {
		"text": "下载Servify - 所有电子产品和家用电器的个人助理。",
		"isAlreadyTranslated": true
	},
	"( ) is your OTP for Servify. This OTP is valid for 120 seconds. Do not share this OTP with anyone for security reasons. We look forward to serving you.": {
		"text": "（）是您的Servify OTP。该OTP有效期为120秒。出于安全原因，请勿与任何人共享此OTP。我们期待您的光临。",
		"isAlreadyTranslated": true
	},
	"You have cancelled your request for scheduled on ( ) We hope to serve you soon.": {
		"text": "您已取消预定的请求（）我们希望尽快为您服务。",
		"isAlreadyTranslated": true
	},
	"Thank you for choosing servify, please rate our service on the App. We look forward to service you again.": {
		"text": "感谢您选择servify，请在应用程序上评价我们的服务。我们期待再次为您服务。",
		"isAlreadyTranslated": true
	},
	"Your request number is ( ) . Our Partner ( ) will collect the device from you as per the scheduled time. To know more, please check Track Request section within the App. T&C apply.": {
		"text": "您的请求号是（）。我们的合作伙伴（）将按照预定时间从您那里收集型号。要了解更多信息，请查看应用程序中的'跟踪请求'部分。条款与条件适用。",
		"isAlreadyTranslated": true
	},
	"Your request number is ( ) . Our Logistics Agent will collect the device from you as per the scheduled time. To know more, please check Track Request section within the App. T&C apply.": {
		"text": "您的请求号是（）。我们的物流代理将按照预定时间从您那里收集型号。要了解更多信息，请查看应用程序中的'跟踪请求'部分。条款与条件适用。",
		"isAlreadyTranslated": true
	},
	"| We have received your claim request. Our representative will connect with you in next 48 hours.": {
		"text": "|我们已收到您的索赔申请。我们的代表将在48小时内与您联系。",
		"isAlreadyTranslated": true
	},
	"Your device will be picked up on ( ) . Your Secret Code is ( ) . Please handover the device to the Logistics Agent only once they provide this secret code to prove their identity.": {
		"text": "您的型号将被接听（）。你的密码是（）。只有在提供此密码以证明其身份后，才能将型号移交给后勤代理。",
		"isAlreadyTranslated": true
	},
	"Your Activation Code to register for the  Plan is ( ). Download the app from and get a better service experience.": {
		"text": "您注册计划的激活码是（）。从中下载应用程序并获得更好的服务体验。",
		"isAlreadyTranslated": true
	},
	"Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips. Download now.": {
		"text": "获取iPhone，iPad，手表和Mac的授权服务，触手可及。现在下载。",
		"isAlreadyTranslated": true
	},
	"Pick up of your (Ref No. -) has been rescheduled to as requested.": {
		"text": "取件（参考编号 - ）已根据要求重新安排。",
		"isAlreadyTranslated": true
	},
	"Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips. iOS App - Web Portal -": {
		"text": "获取iPhone，iPad，手表和Mac的授权服务，触手可及。 iOS应用程序 - Web门户 - ",
		"isAlreadyTranslated": true
	},
	"Your request is registered. We will contact you for an appointment. For assistance, please call us on 1800-108-1333.": {
		"text": "您的请求已注册。我们会与您联系预约。如需帮助，请致电1800-108-1333。",
		"isAlreadyTranslated": true
	},
	"Device Picked up: Your (Ref ID -) was collected by Servify Executive on ( ) and will be delivered to an Authorized Service Centre. To know more, please check Track Repair section within the App. T&C Apply.": {
		"text": "提取的型号：您的（工单号 - ）由Servify Executive收集（），并将交付给授权服务中心。要了解更多信息，请查看应用程序中的'跟踪修复'部分。 T＆C申请。",
		"isAlreadyTranslated": true
	},
	"( ) is your OTP for Micromax Care. This OTP is valid for 120 seconds. Do not share this OTP with any one for security reasons. We look forward to serving you.": {
		"text": "（）是您对Micromax Care的OTP。该OTP有效期为120秒。出于安全原因，请勿与任何人共享此OTP。我们期待您的光临。",
		"isAlreadyTranslated": true
	},
	"| We have received your claim request. Our representative will connect with you in next one business day.": {
		"text": "|我们已收到您的索赔申请。我们的代表将在下一个工作日与您联系。",
		"isAlreadyTranslated": true
	},
	"Pickup Rescheduled: Logistics Agent will attempt pickup of your ( ) on ( ) as requested.": {
		"text": "重新安排取件：后勤代理将根据要求尝试取消（）on（）。",
		"isAlreadyTranslated": true
	},
	"New Request Ref. ID": {
		"text": "新请求工单号",
		"isAlreadyTranslated": true
	},
	"Name": {
		"text": "名称",
		"isAlreadyTranslated": true
	},
	"Phone": {
		"text": "电话",
		"isAlreadyTranslated": true
	},
	"Landmark": {
		"text": "地标",
		"isAlreadyTranslated": true
	},
	"Your dropoff is scheduled on ( ) at ( ) Ref ID: ": {
		"text": "您的下班时间安排在（）at（）工单号：",
		"isAlreadyTranslated": true
	},
	"Download \"eRecycle\" on your iPhone to raise and track eRecycle requests on the go. ": {
		"text": "在iPhone上下载'eRecycle'，随时随地提升和跟踪eRecycle请求。",
		"isAlreadyTranslated": true
	},
	"Dear Customer,We have received a claim request for your device. Please click on the following link to cross check the information shared by you and submit to raise the claim. Kindly note, once submitted, the details cannot be changed.": {
		"text": "尊敬的客户：我们已收到您型号的索赔申请。请单击以下链接以交叉检查您共享的信息并提交以提出索赔。请注意，一旦提交，细节不能更改。",
		"isAlreadyTranslated": true
	},
	"Service Cancelled": {
		"text": "服务已取消",
		"isAlreadyTranslated": true
	},
	"Repair request ( ) has been canceled by the consumer": {
		"text": "维修请求（）已被消费者取消",
		"isAlreadyTranslated": true
	},
	"Repair Completed": {
		"text": "修复完成",
		"isAlreadyTranslated": true
	},
	"Your ( ) has been repaired. Please collect it from ( )": {
		"text": "你的（）已经修好了。请从（）收集",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for carry in repair ": {
		"text": "您的取消请求已被接受进行维修",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for home repair ": {
		"text": "您的取消申请已被接受上门维修",
		"isAlreadyTranslated": true
	},
	"Request Reschedule": {
		"text": "请求重新安排",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for": {
		"text": "您的重新安排请求已被接受",
		"isAlreadyTranslated": true
	},
	"Service Reschedule": {
		"text": "服务重新安排",
		"isAlreadyTranslated": true
	},
	"Repair request": {
		"text": "维修要求",
		"isAlreadyTranslated": true
	},
	"Repair initiated for your request": {
		"text": "为您的请求启动了维修",
		"isAlreadyTranslated": true
	},
	"Service Completed": {
		"text": "服务已完成",
		"isAlreadyTranslated": true
	},
	"Your ( ) has been repaired & delivered.": {
		"text": "您的（）已经修复并交付。",
		"isAlreadyTranslated": true
	},
	"Your repair request has been canceled": {
		"text": "您的维修申请已被取消",
		"isAlreadyTranslated": true
	},
	"Installation request ( )  has been rescheduled by the consumer": {
		"text": "安装请求（）已由消费者重新安排",
		"isAlreadyTranslated": true
	},
	"Demo request ( ) has been rescheduled by the consumer": {
		"text": "演示者请求（）已由消费者重新安排",
		"isAlreadyTranslated": true
	},
	"Service request ( ) has been rescheduled by the consumer": {
		"text": "服务请求（）已由消费者重新安排",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for demo": {
		"text": "您的取消请求已被接受演示",
		"isAlreadyTranslated": true
	},
	"Installation request": {
		"text": "安装要求",
		"isAlreadyTranslated": true
	},
	"has been canceled by the consumer": {
		"text": "已被消费者取消",
		"isAlreadyTranslated": true
	},
	"Demo request": {
		"text": "演示请求",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for service": {
		"text": "您的取消请求已被接受服务",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for pickup drop ": {
		"text": "您的取消请求已被接受以便取件",
		"isAlreadyTranslated": true
	},
	"Service Created": {
		"text": "服务创建",
		"isAlreadyTranslated": true
	},
	"Your request for ( ) has been registered.": {
		"text": "您的（）申请已经注册。",
		"isAlreadyTranslated": true
	},
	"Service suspended ": {
		"text": "服务暂停",
		"isAlreadyTranslated": true
	},
	"Your request ( ) has been suspended": {
		"text": "您的请求（）已被暂停",
		"isAlreadyTranslated": true
	},
	"Service invoice generated": {
		"text": "生成服务发票",
		"isAlreadyTranslated": true
	},
	"Your service request is Rescheduled by ( ) ": {
		"text": "您的服务请求由（）重新安排",
		"isAlreadyTranslated": true
	},
	"Service accepted": {
		"text": "接受服务",
		"isAlreadyTranslated": true
	},
	"Sweet! ( ) has accepted your request to fix your ( )": {
		"text": "甜！ （）已接受你的修复你的请求（）",
		"isAlreadyTranslated": true
	},
	"Serviceman journey initiated": {
		"text": "军人之旅启动",
		"isAlreadyTranslated": true
	},
	" ( ) is on the way to take care of your ( ) ": {
		"text": " （）正在照顾你的（）",
		"isAlreadyTranslated": true
	},
	"Our expert ( ) has initiated the repair work.": {
		"text": "我们的专家（）已启动维修工作。",
		"isAlreadyTranslated": true
	},
	"An invoice has been generated for your service.": {
		"text": "已为您的服务生成发票。",
		"isAlreadyTranslated": true
	},
	"Payment received for repair request": {
		"text": "收到维修请求的付款",
		"isAlreadyTranslated": true
	},
	"Request cancelled": {
		"text": "请求已取消",
		"isAlreadyTranslated": true
	},
	"You have cancelled your request for ( ) scheduled on ( ). We hope to serve you soon.": {
		"text": "您已取消（）安排的（）预定请求。我们希望尽快为您服务。",
		"isAlreadyTranslated": true
	},
	"All required keys are present": {
		"text": "所有必需的密钥都存在",
		"isAlreadyTranslated": true
	},
	"Email sent successfully": {
		"text": "电邮发送成功",
		"isAlreadyTranslated": true
	},
	"error in sending email": {
		"text": "发送电子邮件时出错",
		"isAlreadyTranslated": true
	},
	"Cannot send email": {
		"text": "无法发送电子邮件",
		"isAlreadyTranslated": true
	},
	"Email is sent successfully": {
		"text": "电子邮件已成功发送",
		"isAlreadyTranslated": true
	},
	"Minimum Required Entities Not Found": {
		"text": "找不到最低要求实体",
		"isAlreadyTranslated": true
	},
	"No gsx params found": {
		"text": "没有找到gsx params",
		"isAlreadyTranslated": true
	},
	"Match found": {
		"text": "找到匹配",
		"isAlreadyTranslated": true
	},
	"No match found": {
		"text": "找不到匹配项",
		"isAlreadyTranslated": true
	},
	"The email address entered doesn't seem to be a valid EmailID": {
		"text": "输入的电子邮件地址似乎不是有效的EmailID",
		"isAlreadyTranslated": true
	},
	"Oops! Looks like this email ID does not belong to any organization pre-selected for this program. Please enter correct email ID in case of any errors.": {
		"text": "哎呀！看起来此电子邮件ID不属于为此计划预先选择的任何组织。如有任何错误，请输入正确的电子邮件ID。",
		"isAlreadyTranslated": true
	},
	"Looks like you have already redeemed 2 vouchers.": {
		"text": "看起来你已经兑换了2张优惠券。",
		"isAlreadyTranslated": true
	},
	"Oops! Looks like currently there are no more vouchers available for this program. Please try again after some time.": {
		"text": "哎呀！看起来目前没有更多的优惠券可用于此计划。请过一段时间再试一次。",
		"isAlreadyTranslated": true
	},
	"No valid voucher found": {
		"text": "找不到有效的优惠券",
		"isAlreadyTranslated": true
	},
	"Looks like this voucher is invalid": {
		"text": "看起来这张优惠券无效",
		"isAlreadyTranslated": true
	},
	"Valid Voucher": {
		"text": "有效的优惠券",
		"isAlreadyTranslated": true
	},
	"Seems like the Imei or Serial does not match with that of the device purchased": {
		"text": "好像Imei或Serial与购买的型号不匹配",
		"isAlreadyTranslated": true
	},
	"Looks like you have already activated the enterprise support plan for this device": {
		"text": "您似乎已经为此型号激活了企业支持计划",
		"isAlreadyTranslated": true
	},
	"Insufficient Params - Send either VoucherID or VoucherObj": {
		"text": "Params不足 - 发送VoucherID或VoucherObj",
		"isAlreadyTranslated": true
	},
	"No voucher object found": {
		"text": "找不到凭证对象",
		"isAlreadyTranslated": true
	},
	"This Page has no data": {
		"text": "此页面没有数据",
		"isAlreadyTranslated": true
	},
	"You don't have access to this brand": {
		"text": "您无权访问此品牌",
		"isAlreadyTranslated": true
	},
	"You don't have access to this service Location": {
		"text": "您无权访问此服务位置",
		"isAlreadyTranslated": true
	},
	"You don't have access to this zipcode": {
		"text": "您无权访问此邮政编码",
		"isAlreadyTranslated": true
	},
	"You don't have access to this consumer service request": {
		"text": "您无权访问此使用者服务请求",
		"isAlreadyTranslated": true
	},
	"Invalid ConsumerServiceRequestID": {
		"text": "无效的ConsumerServiceRequestID",
		"isAlreadyTranslated": true
	},
	"This request is already assigned to ( ) ": {
		"text": "此请求已分配给（）",
		"isAlreadyTranslated": true
	},
	"Consumer service request log creation failed |": {
		"text": "消费者服务请求日志创建失败|",
		"isAlreadyTranslated": true
	},
	"Cannot (Re)Assign consumer service request": {
		"text": "不能（重新）分配消费者服务请求",
		"isAlreadyTranslated": true
	},
	"This service location isn't active anymore": {
		"text": "此服务位置不再有效",
		"isAlreadyTranslated": true
	},
	"Service is not at the required stage": {
		"text": "服务未达到规定的阶段",
		"isAlreadyTranslated": true
	},
	"Invalid charge name": {
		"text": "费用名称无效",
		"isAlreadyTranslated": true
	},
	"Product Purchase cost and date are required for this action": {
		"text": "此操作需要产品购买成本和日期",
		"isAlreadyTranslated": true
	},
	"Invalid Type": {
		"text": "无效的类型",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights to approve this claim request": {
		"text": "您没有足够的权利批准此声明请求",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights to add charges": {
		"text": "您没有足够的权利来添加费用",
		"isAlreadyTranslated": true
	},
	"The advance payment cannot be more than the consumer amount": {
		"text": "预付款不能超过消费者金额",
		"isAlreadyTranslated": true
	},
	"Approved claim amount cannot be greater than the total billed amount ": {
		"text": "批准的索赔金额不能超过总开帐金额",
		"isAlreadyTranslated": true
	},
	"Discounts cannot be greater than the taxable amount": {
		"text": "折扣不能超过应税金额",
		"isAlreadyTranslated": true
	},
	"Discount check not requires": {
		"text": "折扣检查不需要",
		"isAlreadyTranslated": true
	},
	"Charges Updated": {
		"text": "收费已更新",
		"isAlreadyTranslated": true
	},
	"Not a valid charge to update": {
		"text": "更新不是有效的费用",
		"isAlreadyTranslated": true
	},
	"Invalid Consumer Service Request": {
		"text": "消费者服务请求无效",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights to modify this claim request": {
		"text": "您没有足够的权限来修改此声明请求",
		"isAlreadyTranslated": true
	},
	"Previous service type not found": {
		"text": "找不到以前的服务类型",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send Remarks to override estimation": {
		"text": "参数不足 - 发送备注以覆盖估算",
		"isAlreadyTranslated": true
	},
	"delivery confirmation sent": {
		"text": "送货确认发送",
		"isAlreadyTranslated": true
	},
	"delivery confirmation not sent": {
		"text": "送货确认未发送",
		"isAlreadyTranslated": true
	},
	"no logistics request found": {
		"text": "没有找到物流请求",
		"isAlreadyTranslated": true
	},
	"Your device is ready to be collected by you. We hope you are satisfied with the service experience. Reference ID:": {
		"text": "您的型号已准备就绪，可由您收集。我们希望您对服务体验感到满意。参考编号：",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching consumer service request logs to calculate tax": {
		"text": "在获取消费者服务请求日志以计算税收时出现问题",
		"isAlreadyTranslated": true
	},
	"Homepage count has been sent successfully": {
		"text": "主页数已成功发送",
		"isAlreadyTranslated": true
	},
	"Invalid Request Remarks": {
		"text": "无效的请求备注",
		"isAlreadyTranslated": true
	},
	"Something went wrong in B2X/updateJob": {
		"text": "B2X / updateJob出了点问题",
		"isAlreadyTranslated": true
	},
	"Status updated": {
		"text": "状态已更新",
		"isAlreadyTranslated": true
	},
	"Status updated but error in updating consumer product": {
		"text": "状态已更新，但更新消费者产品时出错",
		"isAlreadyTranslated": true
	},
	"logistics type updated": {
		"text": "物流类型已更新",
		"isAlreadyTranslated": true
	},
	"logistics type not updated": {
		"text": "物流类型未更新",
		"isAlreadyTranslated": true
	},
	"successfully log created": {
		"text": "成功登录创建",
		"isAlreadyTranslated": true
	},
	"remarks updated ": {
		"text": "备注更新",
		"isAlreadyTranslated": true
	},
	"no previous log found": {
		"text": "没有找到以前的日志",
		"isAlreadyTranslated": true
	},
	"DOA checklist not completed": {
		"text": "DOA清单未完成",
		"isAlreadyTranslated": true
	},
	"DOA checklist not sent": {
		"text": "DOA清单未发送",
		"isAlreadyTranslated": true
	},
	"Something went wrong while creating log": {
		"text": "创建日志时出错了",
		"isAlreadyTranslated": true
	},
	"Cannot update the status": {
		"text": "无法更新状态",
		"isAlreadyTranslated": true
	},
	"workshop request": {
		"text": "研讨会要求",
		"isAlreadyTranslated": true
	},
	"Not a workshop request": {
		"text": "不是研讨会要求",
		"isAlreadyTranslated": true
	},
	"Invalid Service Request": {
		"text": "无效的服务请求",
		"isAlreadyTranslated": true
	},
	"Error in getting data from getCharges": {
		"text": "从getCharges获取数据时出错",
		"isAlreadyTranslated": true
	},
	"Invoice details fetched": {
		"text": "已提取发票详细信息",
		"isAlreadyTranslated": true
	},
	"Error in Finding PartnerServicelocation Detail": {
		"text": "查找PartnerServicelocation详细信息时出错",
		"isAlreadyTranslated": true
	},
	"Error in Finding Consumer Detail": {
		"text": "查找消费者详细信息时出错",
		"isAlreadyTranslated": true
	},
	"No Data Found in getCharges": {
		"text": "在getCharges中找不到数据",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights to delete approved claim amount": {
		"text": "您没有足够的权利删除已批准的索赔金额",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights to delete this charge": {
		"text": "您没有足够的权利删除此费用",
		"isAlreadyTranslated": true
	},
	"You cannot delete service request charge at the given status": {
		"text": "您无法在给定状态下删除服务请求费用",
		"isAlreadyTranslated": true
	},
	"No charge exists by this ID": {
		"text": "此ID不收费",
		"isAlreadyTranslated": true
	},
	"This service request doesn't have an associated partner": {
		"text": "此服务请求没有关联的合作伙伴",
		"isAlreadyTranslated": true
	},
	"No consumer service request found by this id": {
		"text": "此ID未找到任何消费者服务请求",
		"isAlreadyTranslated": true
	},
	"No State Found": {
		"text": "找不到国家",
		"isAlreadyTranslated": true
	},
	"Exclusive Brands": {
		"text": "独家品牌",
		"isAlreadyTranslated": true
	},
	"Exclusive Category": {
		"text": "独家分类",
		"isAlreadyTranslated": true
	},
	"product is not exclusive": {
		"text": "产品不是独家的",
		"isAlreadyTranslated": true
	},
	"Error in fetching exclusive category": {
		"text": "获取独占类别时出错",
		"isAlreadyTranslated": true
	},
	"no brand found with given brand id": {
		"text": "找不到具有特定品牌ID的品牌",
		"isAlreadyTranslated": true
	},
	"Error in finding brand": {
		"text": "寻找品牌时出错",
		"isAlreadyTranslated": true
	},
	"No consumer product found to service": {
		"text": "没有找到服务的消费品",
		"isAlreadyTranslated": true
	},
	"Invalid IsNonPartner parameter": {
		"text": "IsNonPartner参数无效",
		"isAlreadyTranslated": true
	},
	"Cannot create the request": {
		"text": "无法创建请求",
		"isAlreadyTranslated": true
	},
	"No slots available for appointment on this date": {
		"text": "此日期没有可预约的时段",
		"isAlreadyTranslated": true
	},
	"Slots obtained": {
		"text": "获得了老虎机",
		"isAlreadyTranslated": true
	},
	"No SoldPlanID found": {
		"text": "没有找到SoldPlanID",
		"isAlreadyTranslated": true
	},
	"Servify Claim Request": {
		"text": "Servify保险工单",
		"isAlreadyTranslated": true
	},
	"succesfully claim raised": {
		"text": "成功地提出了主张",
		"isAlreadyTranslated": true
	},
	"Product already in repair": {
		"text": "产品已经修复",
		"isAlreadyTranslated": true
	},
	"Error in creating service request logs": {
		"text": "创建服务请求日志时出错",
		"isAlreadyTranslated": true
	},
	"No document sent for adding doc": {
		"text": "没有发送用于添加文档的文档",
		"isAlreadyTranslated": true
	},
	"No new Claim request document uploaded": {
		"text": "没有上传新的索赔申请文件",
		"isAlreadyTranslated": true
	},
	"Documents successfully added": {
		"text": "文件成功添加",
		"isAlreadyTranslated": true
	},
	"Status should be claim approved": {
		"text": "状态应该是声明批准",
		"isAlreadyTranslated": true
	},
	"Request already created": {
		"text": "请求已创建",
		"isAlreadyTranslated": true
	},
	"Error in scheduleRepair": {
		"text": "scheduleRepair出错",
		"isAlreadyTranslated": true
	},
	"no product found for given consumer product ID": {
		"text": "没有找到针对特定消费者产品ID的产品",
		"isAlreadyTranslated": true
	},
	"No consumer service data found": {
		"text": "未找到消费者服务数据",
		"isAlreadyTranslated": true
	},
	"no  request id found": {
		"text": "找不到请求ID",
		"isAlreadyTranslated": true
	},
	"no service center found with given id": {
		"text": "找不到具有给定ID的服务中心",
		"isAlreadyTranslated": true
	},
	"Successfully Carry in Request Created": {
		"text": "成功进行请求创建",
		"isAlreadyTranslated": true
	},
	"Please Provide Valid Servie Type": {
		"text": "请提供有效的服务类型",
		"isAlreadyTranslated": true
	},
	"Call not Attempted": {
		"text": "暂未联系客户",
		"isAlreadyTranslated": true
	},
	"Call not attempted": {
		"text": "暂未联系客户",
		"isAlreadyTranslated": true
	},
	"Connected Call": {
		"text": "已接通",
		"isAlreadyTranslated": true
	},
	"Non-Connected Call": {
		"text": "未接通",
		"isAlreadyTranslated": true
	},
	"Non-connected Call": {
		"text": "未接通",
		"isAlreadyTranslated": true
	},
	"Call Log Details": {
		"text": "呼叫日志详情",
		"isAlreadyTranslated": true
	},
	"Attached with this mail is the call logs data": {
		"text": "附上此邮件的是通话记录数据",
		"isAlreadyTranslated": true
	},
	"No logs found": {
		"text": "找不到日志",
		"isAlreadyTranslated": true
	},
	"ConsumerComplaints success": {
		"text": "ConsumerComplaints成功",
		"isAlreadyTranslated": true
	},
	"ConsumerComplaints failed": {
		"text": "ConsumerComplaints失败",
		"isAlreadyTranslated": true
	},
	"address cannot be updated": {
		"text": "地址无法更新",
		"isAlreadyTranslated": true
	},
	"address cannot be deleted": {
		"text": "地址无法删除",
		"isAlreadyTranslated": true
	},
	"User location fetched": {
		"text": "提取用户位置",
		"isAlreadyTranslated": true
	},
	"user locations for product fetched": {
		"text": "获取产品的用户位置",
		"isAlreadyTranslated": true
	},
	"Query Result not found": {
		"text": "找不到查询结果",
		"isAlreadyTranslated": true
	},
	"Query Result of location not found": {
		"text": "查找未找到位置的结果",
		"isAlreadyTranslated": true
	},
	"Product documents added": {
		"text": "添加了产品文档",
		"isAlreadyTranslated": true
	},
	"failed to add product documents": {
		"text": "无法添加产品文档",
		"isAlreadyTranslated": true
	},
	"Successfully deleted": {
		"text": "成功删除",
		"isAlreadyTranslated": true
	},
	"success in getting brand number": {
		"text": "获得品牌数量的成功",
		"isAlreadyTranslated": true
	},
	"No Brand Found for given brandID": {
		"text": "找不到给定品牌ID的品牌",
		"isAlreadyTranslated": true
	},
	"successfully created  product": {
		"text": "成功创建了产品",
		"isAlreadyTranslated": true
	},
	"Serial Number Not Unique": {
		"text": "序列号不唯一",
		"isAlreadyTranslated": true
	},
	"device added successfully": {
		"text": "型号添加成功",
		"isAlreadyTranslated": true
	},
	"Failed to create ConsumerUnregisteredProduct": {
		"text": "无法创建ConsumerUnregisteredProduct",
		"isAlreadyTranslated": true
	},
	"Purchase date cannot be greater than device damage date": {
		"text": "购买日期不能大于型号损坏日期",
		"isAlreadyTranslated": true
	},
	"Cannot activate ADPP after": {
		"text": "之后无法激活ADPP",
		"isAlreadyTranslated": true
	},
	"No need to activate sold plan as soldplan status = ": {
		"text": "无需激活已售出的计划作为soldplan status =",
		"isAlreadyTranslated": true
	},
	"ConsumerUnregisteredProduct made inactive": {
		"text": "ConsumerUnregisteredProduct变为非活动状态",
		"isAlreadyTranslated": true
	},
	"ConsumerProduct made inactive": {
		"text": "ConsumerProduct无效",
		"isAlreadyTranslated": true
	},
	"Filtered devices of consumer returned": {
		"text": "消费者的过滤型号返回",
		"isAlreadyTranslated": true
	},
	"No devices under repair": {
		"text": "没有型号正在修理中",
		"isAlreadyTranslated": true
	},
	"No devices in repair history yet": {
		"text": "尚未修复历史记录的型号",
		"isAlreadyTranslated": true
	},
	"Device detail obtained": {
		"text": "获得型号细节",
		"isAlreadyTranslated": true
	},
	"Updated Plan": {
		"text": "更新计划",
		"isAlreadyTranslated": true
	},
	"No ConsumerProduct Found with given imei": {
		"text": "在给定的imei中找不到ConsumerProduct",
		"isAlreadyTranslated": true
	},
	"Something Erroneous happened while fetching registered product details": {
		"text": "在获取已注册的产品详细信息时发生了错误",
		"isAlreadyTranslated": true
	},
	"Consumer Product is not a deleted product": {
		"text": "消费者产品不是已删除的产品",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send ConsumerProductID": {
		"text": "参数不足 - 发送ConsumerProductID",
		"isAlreadyTranslated": true
	},
	"Something Erroneous happened while updating the consumer product": {
		"text": "更新消费产品时发生了一些错误",
		"isAlreadyTranslated": true
	},
	"update ConsumerUnregisteredProduct data and converted it to a ConsumerProduct": {
		"text": "更新ConsumerUnregisteredProduct数据并将其转换为ConsumerProduct",
		"isAlreadyTranslated": true
	},
	"Something went wrong with findById in ConsumerUnregisteredProduct table": {
		"text": "ConsumerUnregisteredProduct表中的findById出错了",
		"isAlreadyTranslated": true
	},
	"Device has been validated": {
		"text": "型号已经过验证",
		"isAlreadyTranslated": true
	},
	"Device has been validated without response": {
		"text": "型号已经过验证，没有响应",
		"isAlreadyTranslated": true
	},
	"Unknown Model": {
		"text": "未知模型",
		"isAlreadyTranslated": true
	},
	"Successfully Returned Model Properties": {
		"text": "成功返回模型属性",
		"isAlreadyTranslated": true
	},
	"Error in fetching consumer product details": {
		"text": "获取消费者产品详细信息时出错",
		"isAlreadyTranslated": true
	},
	"ConsumerProduct details fetched": {
		"text": "已提取ConsumerProduct详细信息",
		"isAlreadyTranslated": true
	},
	"Error in adding device": {
		"text": "添加型号时出错",
		"isAlreadyTranslated": true
	},
	"Error in sending Sms": {
		"text": "发送短信时出错",
		"isAlreadyTranslated": true
	},
	"Successfully sent Sms": {
		"text": "成功发送短信",
		"isAlreadyTranslated": true
	},
	"Cannot send sms": {
		"text": "无法发送短信",
		"isAlreadyTranslated": true
	},
	"Error in updating data": {
		"text": "更新数据时出错",
		"isAlreadyTranslated": true
	},
	"Imei already exist": {
		"text": "Imei已经存在",
		"isAlreadyTranslated": true
	},
	"No SerialNo or Imei Present - GSX call not required": {
		"text": "没有Seri​​alNo或Imei存在 - 不需要GSX呼叫",
		"isAlreadyTranslated": true
	},
	"This account/device has been blocked. For assistance, reach out to us.": {
		"text": "此帐户/型号已被阻止。如需帮助，请联系我们。",
		"isAlreadyTranslated": true
	},
	"Looks like the @UNIQUE_ID_TYPE Number you have provided is invalid. Note that multiple invalid attempts may block this account/device": {
		"text": "看起来您提供的@UNIQUE_ID_TYPE号码无效。请注意，多次无效尝试可能会阻止此帐户/型号",
		"isAlreadyTranslated": true
	},
	"An unknown error occurred": {
		"text": "出现未知错误",
		"isAlreadyTranslated": true
	},
	"Error in finding product name match": {
		"text": "找到产品名称匹配时出错",
		"isAlreadyTranslated": true
	},
	"Error in finding product subcategory": {
		"text": "查找产品子类别时出错",
		"isAlreadyTranslated": true
	},
	"Product details fetched": {
		"text": "获取产品详细信息",
		"isAlreadyTranslated": true
	},
	"Service Report": {
		"text": "服务报告",
		"isAlreadyTranslated": true
	},
	"Product Inward Date": {
		"text": "产品内向日期",
		"isAlreadyTranslated": true
	},
	'Service Provider"s Address': {
		"text": "服务提供商'致辞",
		"isAlreadyTranslated": true
	},
	"Problem Reported by Customer": {
		"text": "问题由客户报告",
		"isAlreadyTranslated": true
	},
	"Product Details": {
		"text": "产品详情",
		"isAlreadyTranslated": true
	},
	"Estimated Repair Cost": {
		"text": "估计维修费用",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send Either Device Object or Imei/SerialNo": {
		"text": "参数不足 - 发送型号对象或Imei / SerialNo",
		"isAlreadyTranslated": true
	},
	"Plan successfully created": {
		"text": "计划成功创建",
		"isAlreadyTranslated": true
	},
	"Sorry! No Plans are available for this device": {
		"text": "抱歉!此型号没有可用的计划",
		"isAlreadyTranslated": true
	},
	"NO_PLANS_AVAILABLE": {
		"text": "NO_PLANS_AVAILABLE",
		"isAlreadyTranslated": true
	},
	"This device is already covered under AppleCare Protection Plan": {
		"text": "AppleCare Protection Plan已涵盖此型号",
		"isAlreadyTranslated": true
	},
	"ALREADY_PURCHASED": {
		"text": "已经购买",
		"isAlreadyTranslated": true
	},
	"Limit for imei checks exceeded": {
		"text": "超过imei支票的限制",
		"isAlreadyTranslated": true
	},
	"Your Device is not under warranty": {
		"text": "您的型号不在保修期内",
		"isAlreadyTranslated": true
	},
	"NOT_ELIGIBLE": {
		"text": "不容忽视",
		"isAlreadyTranslated": true
	},
	"Invalid serial number": {
		"text": "无效序列号",
		"isAlreadyTranslated": true
	},
	"INCORRECT_SERIAL_NO": {
		"text": "INCORRECT_SERIAL_NO",
		"isAlreadyTranslated": true
	},
	"All applicable plans are activated for this device.": {
		"text": "此型号已激活所有适用的计划。",
		"isAlreadyTranslated": true
	},
	"Plan is already active against this device": {
		"text": "计划已针对此型号启用",
		"isAlreadyTranslated": true
	},
	"Plans Fetched": {
		"text": "计划成功",
		"isAlreadyTranslated": true
	},
	"IS_ELIGIBLE": {
		"text": "IS_ELIGIBLE",
		"isAlreadyTranslated": true
	},
	"Please enter a valid serial number": {
		"text": "请输入有效的序列号",
		"isAlreadyTranslated": true
	},
	"Oops! Looks like this device is no longer covered under warranty": {
		"text": "哎呀！看起来此型号不再包含在保修范围内",
		"isAlreadyTranslated": true
	},
	"Provide a valid ConsumerProductID": {
		"text": "提供有效的ConsumerProductID",
		"isAlreadyTranslated": true
	},
	"Product mismatch": {
		"text": "产品不匹配",
		"isAlreadyTranslated": true
	},
	"Device already in service": {
		"text": "型号已投入使用",
		"isAlreadyTranslated": true
	},
	"SERIAL_NO_OBSOLETE": {
		"text": "SERIAL_NO_OBSOLETE",
		"isAlreadyTranslated": true
	},
	"No checks yet": {
		"text": "还没检查",
		"isAlreadyTranslated": true
	},
	"Provide a valid check type": {
		"text": "提供有效的支票类型",
		"isAlreadyTranslated": true
	},
	"Plan Invoice fetched": {
		"text": "已提取计划发票",
		"isAlreadyTranslated": true
	},
	"Email address cannot exceed 70 characters": {
		"text": "电子邮件地址不得超过70个字符",
		"isAlreadyTranslated": true
	},
	"Please provide a valid EmailID": {
		"text": "请提供有效的EmailID",
		"isAlreadyTranslated": true
	},
	"First Name/Last Name cannot exceed 34 characters.": {
		"text": "名字/姓氏不能超过34个字符。",
		"isAlreadyTranslated": true
	},
	"Oops! This device is no longer covered under warranty": {
		"text": "哎呀！此型号不在保修范围内",
		"isAlreadyTranslated": true
	},
	"This action is not allowed": {
		"text": "不允许此操作",
		"isAlreadyTranslated": true
	},
	"Error in adding docs": {
		"text": "添加文档时出错",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters": {
		"text": "参数不足",
		"isAlreadyTranslated": true
	},
	"Error in getPlanInvoice": {
		"text": "getPlanInvoice出错",
		"isAlreadyTranslated": true
	},
	"Device name is required": {
		"text": "型号名称是必需的",
		"isAlreadyTranslated": true
	},
	"Device Config fetched": {
		"text": "获取型号配置",
		"isAlreadyTranslated": true
	},
	"No config found for the Device": {
		"text": "找不到型号的配置",
		"isAlreadyTranslated": true
	},
	"Error in fetching device config": {
		"text": "获取型号配置时出错",
		"isAlreadyTranslated": true
	},
	"Unique product sub category ids": {
		"text": "独特的产品子类别ID",
		"isAlreadyTranslated": true
	},
	"Something wrong while finding products": {
		"text": "找到产品时出了点问题",
		"isAlreadyTranslated": true
	},
	"Something wrong while finding devices": {
		"text": "找到型号时出了点问题",
		"isAlreadyTranslated": true
	},
	"No records to update": {
		"text": "没有要更新的记录",
		"isAlreadyTranslated": true
	},
	"Update failed": {
		"text": "更新失败",
		"isAlreadyTranslated": true
	},
	"Error in file conversion": {
		"text": "文件转换错误",
		"isAlreadyTranslated": true
	},
	"Results fetched": {
		"text": "取得了结果",
		"isAlreadyTranslated": true
	},
	"We have saved the details of this device.": {
		"text": "我们保存了此型号的详细信息。",
		"isAlreadyTranslated": true
	},
	"Great!": {
		"text": "太好了！",
		"isAlreadyTranslated": true
	},
	"Looks like you have exhausted the number of attempts to activate the enteprise support plan on your device. Please contact Servify for more assistance": {
		"text": "您似乎已经用尽了在型号上激活企业支持计划的尝试次数。请联系Servify获取更多帮助",
		"isAlreadyTranslated": true
	},
	"Oops!": {
		"text": "哎呀！",
		"isAlreadyTranslated": true
	},
	"It looks like this device is protected under Apple Enterprise Support Plan.": {
		"text": "看起来此型号受Apple Enterprise支持计划保护。",
		"isAlreadyTranslated": true
	},
	"Congratulations!": {
		"text": "恭喜！",
		"isAlreadyTranslated": true
	},
	"Error in validating serial number": {
		"text": "验证序列号时出错",
		"isAlreadyTranslated": true
	},
	"This device is eligible for purchasing the plan": {
		"text": "此型号有资格购买该计划",
		"isAlreadyTranslated": true
	},
	"Device transferred": {
		"text": "型号已转移",
		"isAlreadyTranslated": true
	},
	"Invalid Mobile No": {
		"text": "无效的手机号码",
		"isAlreadyTranslated": true
	},
	"INVALID_MOBILE": {
		"text": "INVALID_MOBILE",
		"isAlreadyTranslated": true
	},
	"Invalid Imei or Serial No": {
		"text": "无效的Imei或序列号",
		"isAlreadyTranslated": true
	},
	"INVALID_IMEI_OR_SERIAL": {
		"text": "INVALID_IMEI_OR_SERIAL",
		"isAlreadyTranslated": true
	},
	"details updated successfully": {
		"text": "细节已成功更新",
		"isAlreadyTranslated": true
	},
	"Service request document deleted": {
		"text": "服务请求文档已删除",
		"isAlreadyTranslated": true
	},
	"Already rated for this particular service request": {
		"text": "已经为此特定服务请求评分",
		"isAlreadyTranslated": true
	},
	"No feed back created": {
		"text": "没有创建反馈",
		"isAlreadyTranslated": true
	},
	"Invalid ServiceLocationAppointmentID": {
		"text": "ServiceLocationAppointmentID无效",
		"isAlreadyTranslated": true
	},
	"Service status updated": {
		"text": "服务状态已更新",
		"isAlreadyTranslated": true
	},
	"Rating details has been successfully sent.": {
		"text": "评级详细信息已成功发送。",
		"isAlreadyTranslated": true
	},
	"All issues to be validated should have ConsumerServiceRequestIssuesId": {
		"text": "要验证的所有问题都应该包含ConsumerServiceRequestIssuesId",
		"isAlreadyTranslated": true
	},
	"Something went wrong while updating issue": {
		"text": "更新问题时出了点问题",
		"isAlreadyTranslated": true
	},
	"Something went wrong while finding issue with id": {
		"text": "找到id问题时出了点问题",
		"isAlreadyTranslated": true
	},
	"All issues to be added should have ProductSubCategoryIssueID": {
		"text": "要添加的所有问题都应该包含ProductSubCategoryIssueID",
		"isAlreadyTranslated": true
	},
	"All issues to be added should not have ConsumerServiceRequestIssuesId": {
		"text": "要添加的所有问题都不应该包含ConsumerServiceRequestIssuesId",
		"isAlreadyTranslated": true
	},
	"You cannot delete a reported issue": {
		"text": "您无法删除报告的问题",
		"isAlreadyTranslated": true
	},
	"No such issue exists for this consumer service request id": {
		"text": "此消费者服务请求ID不存在此类问题",
		"isAlreadyTranslated": true
	},
	"Engineer is not register with this request": {
		"text": "工程师未注册此请求",
		"isAlreadyTranslated": true
	},
	"Please provide at least on issue": {
		"text": "请至少提供问题",
		"isAlreadyTranslated": true
	},
	"Please provide warranty reason": {
		"text": "请提供保修理由",
		"isAlreadyTranslated": true
	},
	"Added issue updation failed": {
		"text": "添加的问题更新失败",
		"isAlreadyTranslated": true
	},
	"Service request information fetching failed.": {
		"text": "服务请求信息提取失败。",
		"isAlreadyTranslated": true
	},
	"issue has been validated": {
		"text": "问题已经过验证",
		"isAlreadyTranslated": true
	},
	"issue has not been validated yet": {
		"text": "问题尚未得到验证",
		"isAlreadyTranslated": true
	},
	"failed to check issue validation": {
		"text": "未能检查问题验证",
		"isAlreadyTranslated": true
	},
	"Invoice has not been generated yet": {
		"text": "尚未生成发票",
		"isAlreadyTranslated": true
	},
	"Final invoice has been generated": {
		"text": "已生成最终发票",
		"isAlreadyTranslated": true
	},
	"Final invoice not generated": {
		"text": "最终发票未生成",
		"isAlreadyTranslated": true
	},
	"eRecycle Request details:": {
		"text": "电子回收请求详细信息：",
		"isAlreadyTranslated": true
	},
	"Customer Name :": {
		"text": "顾客姓名 ：",
		"isAlreadyTranslated": true
	},
	"Phone Number :": {
		"text": "电话号码 ：",
		"isAlreadyTranslated": true
	},
	"Device Detail :": {
		"text": "型号细节：",
		"isAlreadyTranslated": true
	},
	"Day of Visit": {
		"text": "访问日",
		"isAlreadyTranslated": true
	},
	"Time of Visit :": {
		"text": "访问时间：",
		"isAlreadyTranslated": true
	},
	"Regards,": {
		"text": "问候，",
		"isAlreadyTranslated": true
	},
	"New eRecycle Drop off Request: ID": {
		"text": "新的eRecycle下线请求：ID",
		"isAlreadyTranslated": true
	},
	"New eRecycle Pickup Request: ID": {
		"text": "新的eRecycle代理请求：ID",
		"isAlreadyTranslated": true
	},
	"vendor id is not valid for fareye tracking": {
		"text": "供应商ID对于fareye跟踪无效",
		"isAlreadyTranslated": true
	},
	"please provide Pincode and PlanID": {
		"text": "请提供Pincode和PlanID",
		"isAlreadyTranslated": true
	},
	"Request Is Reschedulable": {
		"text": "请求可重新计划",
		"isAlreadyTranslated": true
	},
	"Request Is Not Reschedulable": {
		"text": "请求不可重新安排",
		"isAlreadyTranslated": true
	},
	"Service is already booked for ( )": {
		"text": "服务已预订（）",
		"isAlreadyTranslated": true
	},
	"Please call us at  for further queries": {
		"text": "请致电我们以获取进一步的疑问",
		"isAlreadyTranslated": true
	},
	"Service cannot be created. Please call us at  for further queries": {
		"text": "无法创建服务。请致电我们以获取进一步的疑问",
		"isAlreadyTranslated": true
	},
	"no service locations found for this brand": {
		"text": "找不到此品牌的服务地点",
		"isAlreadyTranslated": true
	},
	"error occured in finding service locations": {
		"text": "在查找服务位置时发生错误",
		"isAlreadyTranslated": true
	},
	"Updated Serial Number": {
		"text": "更新了序列号",
		"isAlreadyTranslated": true
	},
	"No Updated Consumer Product Found": {
		"text": "未找到更新的消费者产品",
		"isAlreadyTranslated": true
	},
	"No Consumer Product Found": {
		"text": "找不到消费品",
		"isAlreadyTranslated": true
	},
	"Serial Number Already Exist With Other Device": {
		"text": "序列号已存在于其他型号",
		"isAlreadyTranslated": true
	},
	"Serial number should be unique": {
		"text": "序列号应该是唯一的",
		"isAlreadyTranslated": true
	},
	"Error in function paymentAction": {
		"text": "函数paymentAction出错",
		"isAlreadyTranslated": true
	},
	"Not servicable at this region": {
		"text": "在这个地区不可维修",
		"isAlreadyTranslated": true
	},
	"Cannot create on site request": {
		"text": "无法创建现场请求",
		"isAlreadyTranslated": true
	},
	"User cannot raise request for this service type id": {
		"text": "用户无法提出此服务类型ID的请求",
		"isAlreadyTranslated": true
	},
	"Invalid csrid": {
		"text": "无效的csrid",
		"isAlreadyTranslated": true
	},
	"DOA certificate has been generated .Please collect your device and DOA certificate": {
		"text": "已生成DOA证书。请收集您的型号和DOA证书",
		"isAlreadyTranslated": true
	},
	"DOA certificate generated": {
		"text": "生成DOA证书",
		"isAlreadyTranslated": true
	},
	"Awesome! Your device is replaced and ready to be collected": {
		"text": "真棒！您的型号已更换并准备好进行收集",
		"isAlreadyTranslated": true
	},
	"Claim Intimation Number Generated": {
		"text": "索赔估计数生成",
		"isAlreadyTranslated": true
	},
	"Estimation is Already approved by service center": {
		"text": "估计已经得到服务中心的批准",
		"isAlreadyTranslated": true
	},
	"Estimation Status updated": {
		"text": "估计状态已更新",
		"isAlreadyTranslated": true
	},
	"Service not available": {
		"text": "服务不可用",
		"isAlreadyTranslated": true
	},
	"Slots has been sent successfully.": {
		"text": "插槽已成功发送。",
		"isAlreadyTranslated": true
	},
	"Service centre detail not found": {
		"text": "未找到服务中心详细信息",
		"isAlreadyTranslated": true
	},
	"Please provide valid condition": {
		"text": "请提供有效条件",
		"isAlreadyTranslated": true
	},
	"updated consumer service request": {
		"text": "更新的消费者服务请求",
		"isAlreadyTranslated": true
	},
	"No Consumer Service request found with given id": {
		"text": "找不到具有给定ID的消费者服务请求",
		"isAlreadyTranslated": true
	},
	"no consumer service request created": {
		"text": "没有创建消费者服务请求",
		"isAlreadyTranslated": true
	},
	"instance updated succesfully": {
		"text": "实例更新成功",
		"isAlreadyTranslated": true
	},
	"no id found in ServiceLocationAppointment": {
		"text": "在ServiceLocationAppointment中找不到ID",
		"isAlreadyTranslated": true
	},
	"New appointment created": {
		"text": "新约会已创建",
		"isAlreadyTranslated": true
	},
	"no data created in ServiceLocationAppointment": {
		"text": "没有在ServiceLocationAppointment中创建的数据",
		"isAlreadyTranslated": true
	},
	"Cannot cancel this request": {
		"text": "无法取消此请求",
		"isAlreadyTranslated": true
	},
	"Your eRecycle Request Has Been Cancelled | Ref. ID.": {
		"text": "您的eRecycle请求已取消|参考。 ID。",
		"isAlreadyTranslated": true
	},
	"cannot allocate the vouchers": {
		"text": "无法分配优惠券",
		"isAlreadyTranslated": true
	},
	"Service cancelled successfully": {
		"text": "服务已成功取消",
		"isAlreadyTranslated": true
	},
	"Service cancelled": {
		"text": "服务已取消",
		"isAlreadyTranslated": true
	},
	"Provided date should not be less then current ": {
		"text": "提供日期不应低于当前日期",
		"isAlreadyTranslated": true
	},
	"Can't reschedule now": {
		"text": "现在不能重新安排",
		"isAlreadyTranslated": true
	},
	"Cannot reschedule on same date and slot": {
		"text": "无法在同一日期和时段重新安排",
		"isAlreadyTranslated": true
	},
	"Can't reschedule more than 3 times": {
		"text": "不能重新安排超过3次",
		"isAlreadyTranslated": true
	},
	"no consumer servicerequest logistics  request found": {
		"text": "未找到任何消费者服务请求物流请求",
		"isAlreadyTranslated": true
	},
	"Non Partnered Track Request Details returned": {
		"text": "返回非合作跟踪请求详细信息",
		"isAlreadyTranslated": true
	},
	"Non Partnered Track Request Details": {
		"text": "非合作跟踪请求详细信息",
		"isAlreadyTranslated": true
	},
	"No PartnerServicelocation found from PartnerServicelocationID": {
		"text": "从PartnerServicelocationID中找不到PartnerServicelocation",
		"isAlreadyTranslated": true
	},
	"no ConsumerProduct found from ConsumerProductID": {
		"text": "从ConsumerProductID找不到ConsumerProduct",
		"isAlreadyTranslated": true
	},
	"no ServiceLocationAppointment found from ServiceLocationAppointmentID": {
		"text": "没有从ServiceLocationAppointmentID找到ServiceLocationAppointment",
		"isAlreadyTranslated": true
	},
	"unable to get the data from ConsumerServiceRequestID": {
		"text": "无法从ConsumerServiceRequestID获取数据",
		"isAlreadyTranslated": true
	},
	"ServiceLocationApiRequest called for estimation failed": {
		"text": "被调用的ServiceLocationApiRequest失败",
		"isAlreadyTranslated": true
	},
	"Consumer Unregistered Product Service Request created": {
		"text": "已创建消费者未注册产品服务请求",
		"isAlreadyTranslated": true
	},
	"Found product price level applicability and other details": {
		"text": "找到产品价格水平适用性和其他细节",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching product price level applicability and other details": {
		"text": "在获取产品价格水平适用性和其他细节时出了点问题",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching issues": {
		"text": "在提出问题时出了点问题",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching consumer service request issues": {
		"text": "在获取消费者服务请求问题时出现问题",
		"isAlreadyTranslated": true
	},
	"No consumer service request was found associated with the given id": {
		"text": "未找到与给定ID关联的消费者服务请求",
		"isAlreadyTranslated": true
	},
	"Claim Request Not At The Required Stage To Be Merged": {
		"text": "保险工单不在合并所需的阶段",
		"isAlreadyTranslated": true
	},
	"Normal Request Not At The Required Stage To Be Merged": {
		"text": "正常请求不在合并所需的阶段",
		"isAlreadyTranslated": true
	},
	"No details found for the given ConsumerID": {
		"text": "没有找到给定ConsumerID的详细信息",
		"isAlreadyTranslated": true
	},
	"Error in fetching service details": {
		"text": "获取服务详细信息时出错",
		"isAlreadyTranslated": true
	},
	"Some issues are already present for this consumer service request": {
		"text": "此消费者服务请求已存在一些问题",
		"isAlreadyTranslated": true
	},
	"No results found for the given claimRequestRefID OR Status Is Not At In-principle approved' Stage": {
		"text": "未找到针对给定claimRequestRefID或状态未在原则上批准的'阶段'的结果",
		"isAlreadyTranslated": true
	},
	"ServicetypeID of the request does not match": {
		"text": "请求的ServicetypeID不匹配",
		"isAlreadyTranslated": true
	},
	"Error in Cancelling ServiceRequest": {
		"text": "取消ServiceRequest时出错",
		"isAlreadyTranslated": true
	},
	"Error in updating claim request": {
		"text": "更新声明请求时出错",
		"isAlreadyTranslated": true
	},
	"No Pickup Request Accepted Log Found For RequestID ": {
		"text": "没有为RequestID找到接收请求的接受日志",
		"isAlreadyTranslated": true
	},
	"Error in updating serviceRequestID for logisticsID ": {
		"text": "更新logisticsID的serviceRequestID时出错",
		"isAlreadyTranslated": true
	},
	"Request successfully merged": {
		"text": "请求已成功合并",
		"isAlreadyTranslated": true
	},
	"Something Erroneous happened while fetching partner details": {
		"text": "在获取合作伙伴详细信息时发生了错误",
		"isAlreadyTranslated": true
	},
	"No record found": {
		"text": "没有找到记录",
		"isAlreadyTranslated": true
	},
	"no appointment found": {
		"text": "没有预约",
		"isAlreadyTranslated": true
	},
	"The brand is no longer active for this product": {
		"text": "该品牌不再有效",
		"isAlreadyTranslated": true
	},
	"No brand found for this product": {
		"text": "没有找到该产品的品牌",
		"isAlreadyTranslated": true
	},
	"This product is no longer active": {
		"text": "此产品不再有效",
		"isAlreadyTranslated": true
	},
	"We Pick up  ": {
		"text": "我们接",
		"isAlreadyTranslated": true
	},
	"We will send an Agent to pick your device and deliver it back after the repair": {
		"text": "我们将派代理人员挑选您的型号并在维修后将其送回",
		"isAlreadyTranslated": true
	},
	"Not available at this location": {
		"text": "此位置不可用",
		"isAlreadyTranslated": true
	},
	"You Visit the Authorised Service Center": {
		"text": "您访问授权服务中心",
		"isAlreadyTranslated": true
	},
	"Book an appointment to drop off your device at the authorised service center": {
		"text": "预约在授权服务中心放下您的型号",
		"isAlreadyTranslated": true
	},
	"No Service Center near you": {
		"text": "你附近没有服务中心",
		"isAlreadyTranslated": true
	},
	"Get an Engineer to Visit Your Location": {
		"text": "让工程师访问您的位置",
		"isAlreadyTranslated": true
	},
	"Engineer visits you at your preferred location for onsite repair": {
		"text": "工程师会在您首选的位置拜访您进行现场维修",
		"isAlreadyTranslated": true
	},
	"Get a Device Installed at Your Location ": {
		"text": "获取安装在您所在位置的型号",
		"isAlreadyTranslated": true
	},
	"Engineer will come to your location at a preferred time to install a device": {
		"text": "工程师将在首选时间到您的位置安装型号",
		"isAlreadyTranslated": true
	},
	"Get Demo of Your Device": {
		"text": "获取您的型号演示",
		"isAlreadyTranslated": true
	},
	"We will send an expert who will demonstrate the features of your device": {
		"text": "我们将派专家来演示您型号的功能",
		"isAlreadyTranslated": true
	},
	"Preventive Maintenance": {
		"text": "预防性的维护",
		"isAlreadyTranslated": true
	},
	"Service at Your Location": {
		"text": "在您的位置服务",
		"isAlreadyTranslated": true
	},
	"We will send an engineer who will perform routine health checks and preventive maintenance routine": {
		"text": "我们将派遣一名工程师进行常规健康检查和预防性维护",
		"isAlreadyTranslated": true
	},
	"Looks like there are no service modes available at your selected location. Please chose near by location or contact brand for further help.": {
		"text": "看起来您所选位置没有可用的服务模式。请选择附近的位置或联系品牌以获得进一步的帮助。",
		"isAlreadyTranslated": true
	},
	"The request is not at required status for this action": {
		"text": "此操作的请求未处于必需状态",
		"isAlreadyTranslated": true
	},
	"Error in fetching requests": {
		"text": "获取请求时出错",
		"isAlreadyTranslated": true
	},
	"Request details fetched successfully": {
		"text": "请求详细信息已成功获取",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights to cancel this request": {
		"text": "您没有足够的权限取消此请求",
		"isAlreadyTranslated": true
	},
	"Something went wrong while updating consumer service request": {
		"text": "更新消费者服务请求时出现问题",
		"isAlreadyTranslated": true
	},
	"Oops. We don’t service this device at this location. But we’re hard at work and you should get some good news soon. Try later?": {
		"text": "哎呀。我们不在此位置为此型号提供服务。但我们努力工作，你很快就会得到一些好消息。稍后再试？",
		"isAlreadyTranslated": true
	},
	"We Pick up": {
		"text": "我们接",
		"isAlreadyTranslated": true
	},
	"Not supported at this location": {
		"text": "此位置不支持",
		"isAlreadyTranslated": true
	},
	"No Service Center nearby": {
		"text": "附近没有服务中心",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights to modify this request": {
		"text": "您没有足够的权限来修改此请求",
		"isAlreadyTranslated": true
	},
	"Claim amount is required": {
		"text": "索赔金额是必需的",
		"isAlreadyTranslated": true
	},
	"UTR and payment date is required": {
		"text": "UTR和付款日期是必需的",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights for this action": {
		"text": "您没有足够的权利执行此操作",
		"isAlreadyTranslated": true
	},
	"BER accessories refund amount": {
		"text": "BER配件退款金额",
		"isAlreadyTranslated": true
	},
	"Accessories": {
		"text": "饰品",
		"isAlreadyTranslated": true
	},
	"Refund": {
		"text": "退",
		"isAlreadyTranslated": true
	},
	"error in storing BER payment details": {
		"text": "存储BER付款细节时出错",
		"isAlreadyTranslated": true
	},
	"Your device has been dispatched from the Service Centre, through": {
		"text": "您的型号已通过服务中心发送",
		"isAlreadyTranslated": true
	},
	"with TrackingID": {
		"text": "使用TrackingID",
		"isAlreadyTranslated": true
	},
	"Failed to create logistics requests": {
		"text": "无法创建物流请求",
		"isAlreadyTranslated": true
	},
	"Please rate your experience.": {
		"text": "请评价您的体验。",
		"isAlreadyTranslated": true
	},
	"Please provide the claimAmount": {
		"text": "请提供claimAmount",
		"isAlreadyTranslated": true
	},
	"Claim amount": {
		"text": "索赔金额",
		"isAlreadyTranslated": true
	},
	"Approved claim Amount": {
		"text": "批准的索赔金额",
		"isAlreadyTranslated": true
	},
	"Request charges not found": {
		"text": "未找到请求费用",
		"isAlreadyTranslated": true
	},
	"Please upload a clear copy of cancelled cheque with the claimant (your) name on it.": {
		"text": "请上传已取消支票的明确副本，并附上索赔人（您）的姓名。",
		"isAlreadyTranslated": true
	},
	"We are verifying and processing your documents. We will initiate the payment soon. ": {
		"text": "我们正在验证和处理您的文件。我们很快就会付款。",
		"isAlreadyTranslated": true
	},
	"Request is not at the required stage": {
		"text": "请求未达到要求的阶段",
		"isAlreadyTranslated": true
	},
	"Display info fetched": {
		"text": "显示获取的信息",
		"isAlreadyTranslated": true
	},
	"Requests fetched": {
		"text": "提取请求",
		"isAlreadyTranslated": true
	},
	"Provide a valid csrID": {
		"text": "提供有效的csrID",
		"isAlreadyTranslated": true
	},
	"Successfully rejected estimate": {
		"text": "成功拒绝估计",
		"isAlreadyTranslated": true
	},
	"No parts found for reference number :": {
		"text": "未找到参考编号的零件：",
		"isAlreadyTranslated": true
	},
	"No request found for reference number :": {
		"text": "未找到参考编号的请求：",
		"isAlreadyTranslated": true
	},
	"Integration find result": {
		"text": "集成查找结果",
		"isAlreadyTranslated": true
	},
	"No service request integration found": {
		"text": "未找到服务请求集成",
		"isAlreadyTranslated": true
	},
	"No service request found": {
		"text": "未找到服务请求",
		"isAlreadyTranslated": true
	},
	"failed to get subcategory of current config version": {
		"text": "无法获得当前配置版本的子类别",
		"isAlreadyTranslated": true
	},
	"ConfigurationParameter Set": {
		"text": "ConfigurationParameter Set",
		"isAlreadyTranslated": true
	},
	"SubCategory updated": {
		"text": "SubCategory已更新",
		"isAlreadyTranslated": true
	},
	"email invoice sent": {
		"text": "发送电子邮件发票",
		"isAlreadyTranslated": true
	},
	"Servify Invoice": {
		"text": "提供发票",
		"isAlreadyTranslated": true
	},
	"Otp sent": {
		"text": "Otp已发送",
		"isAlreadyTranslated": true
	},
	"Check boxes for feedback": {
		"text": "复选框以获得反馈",
		"isAlreadyTranslated": true
	},
	"No service location appointment found": {
		"text": "未找到服务地点预约",
		"isAlreadyTranslated": true
	},
	"No request found": {
		"text": "没有找到请求",
		"isAlreadyTranslated": true
	},
	"Notification list": {
		"text": "通知清单",
		"isAlreadyTranslated": true
	},
	"no new notifications": {
		"text": "没有新的通知",
		"isAlreadyTranslated": true
	},
	"Consumer does not exists": {
		"text": "消费者不存在",
		"isAlreadyTranslated": true
	},
	"Coupon application failed": {
		"text": "优惠券申请失败",
		"isAlreadyTranslated": true
	},
	"Welcome to () Care": {
		"text": "欢迎来到（）关怀",
		"isAlreadyTranslated": true
	},
	"Welcome to Servify - Your Personal Device Assistant": {
		"text": "欢迎使用Servify - 您的个人型号助理",
		"isAlreadyTranslated": true
	},
	"Welcome to Servify - Authorized Service": {
		"text": "欢迎使用Servify - 授权服务",
		"isAlreadyTranslated": true
	},
	"Welcome to eRecycle For iPhones": {
		"text": "欢迎使用eRecycle For iPhone",
		"isAlreadyTranslated": true
	},
	"OTP matched": {
		"text": "OTP匹配",
		"isAlreadyTranslated": true
	},
	"Page number should be greater than 0": {
		"text": "页码应大于0",
		"isAlreadyTranslated": true
	},
	"Items per page should be greater than ()": {
		"text": "每页的项目应大于（）",
		"isAlreadyTranslated": true
	},
	"Range should be greater than 0": {
		"text": "范围应大于0",
		"isAlreadyTranslated": true
	},
	"Something went wrong while applying filters": {
		"text": "应用过滤器时出错了",
		"isAlreadyTranslated": true
	},
	"Something went wrong while getting consumer count": {
		"text": "在获得消费者数量的同时出现问题",
		"isAlreadyTranslated": true
	},
	"Something went wrong while doing pagination": {
		"text": "分页时出错了",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send MobileNo": {
		"text": "参数不足 - 发送MobileNo",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send CountryCode": {
		"text": "参数不足 - 发送CountryCode",
		"isAlreadyTranslated": true
	},
	"Consumer exists with the given mobile number": {
		"text": "消费者存在给定的手机号码",
		"isAlreadyTranslated": true
	},
	"No consumer exists with the given mobile number": {
		"text": "给定的手机号码不存在消费者",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching filtered consumers": {
		"text": "在获取过滤后的消费者时出现问题",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching filtered Tempconsumers": {
		"text": "在获取过滤的Tempconsumers时出了点问题",
		"isAlreadyTranslated": true
	},
	"Please send consumer id": {
		"text": "请发送消费者ID",
		"isAlreadyTranslated": true
	},
	"No New Jobs available": {
		"text": "没有新工作可用",
		"isAlreadyTranslated": true
	},
	"Something went wrong with get Devices": {
		"text": "获取型号出了点问题",
		"isAlreadyTranslated": true
	},
	"Are you an Enterprise Support Program Customer?": {
		"text": "您是企业支持计划客户吗？",
		"isAlreadyTranslated": true
	},
	"Looks like there is a problem!": {
		"text": "看起来有问题！",
		"isAlreadyTranslated": true
	},
	"The IMEI Number entered is not valid. Please contact Servify Customer Support to know more.": {
		"text": "输入的IMEI号无效。请联系Servify客户支持以了解更多信息。",
		"isAlreadyTranslated": true
	},
	"You have already activated the enterprise support plan on your device.": {
		"text": "您已经在型号上激活了企业支持计划。",
		"isAlreadyTranslated": true
	},
	"Welcome!": {
		"text": "欢迎！",
		"isAlreadyTranslated": true
	},
	"To get complete assistance, please add the Serial or IMEI Number of this device.": {
		"text": "要获得完整帮助，请添加此型号的序列号或IMEI号。",
		"isAlreadyTranslated": true
	},
	"You seemed to have generated a voucher but not purchased from Flipkart. Please visit Flipkart and buy an iPhone to avail benefits exclusively for you.": {
		"text": "您似乎已经生成了凭证但未从Flipkart购买。请访问Flipkart并购买iPhone以获得专属的优惠。",
		"isAlreadyTranslated": true
	},
	"For further assistance, please contact Servify": {
		"text": "如需进一步帮助，请联系Servify",
		"isAlreadyTranslated": true
	},
	"coupon is applied": {
		"text": "优惠券适用",
		"isAlreadyTranslated": true
	},
	"coupon applied no. exceeded": {
		"text": "优惠券申请号突破",
		"isAlreadyTranslated": true
	},
	"coupon cannot be  applied": {
		"text": "优惠券无法使用",
		"isAlreadyTranslated": true
	},
	"Coupon is no longer valid": {
		"text": "优惠券不再有效",
		"isAlreadyTranslated": true
	},
	"Cannot find the coupon value": {
		"text": "找不到优惠券价值",
		"isAlreadyTranslated": true
	},
	"coupon redeemed": {
		"text": "优惠券已兑现",
		"isAlreadyTranslated": true
	},
	"Payment is done through servify cash": {
		"text": "付款通过servify现金完成",
		"isAlreadyTranslated": true
	},
	"coupon can't be applied": {
		"text": "优惠券无法使用",
		"isAlreadyTranslated": true
	},
	"coupon does not exist ": {
		"text": "优惠券不存在",
		"isAlreadyTranslated": true
	},
	"coupon cannot be redeem": {
		"text": "优惠券不能兑换",
		"isAlreadyTranslated": true
	},
	"coupon cant be applied": {
		"text": "优惠券不能适用",
		"isAlreadyTranslated": true
	},
	"coupon cannot be destroyed": {
		"text": "优惠券不能被销毁",
		"isAlreadyTranslated": true
	},
	"no coupon applied": {
		"text": "没有优惠券",
		"isAlreadyTranslated": true
	},
	"apply coupon failed": {
		"text": "申请优惠券失败",
		"isAlreadyTranslated": true
	},
	"referral created": {
		"text": "推荐创建",
		"isAlreadyTranslated": true
	},
	"it is case sensitive": {
		"text": "它区分大小写",
		"isAlreadyTranslated": true
	},
	"referral can be applied": {
		"text": "推荐可以适用",
		"isAlreadyTranslated": true
	},
	"maximum limit reached": {
		"text": "达到最大限度",
		"isAlreadyTranslated": true
	},
	"referral already applied": {
		"text": "推荐已经应用",
		"isAlreadyTranslated": true
	},
	"Redeemed updated successfully": {
		"text": "赎回成功更新",
		"isAlreadyTranslated": true
	},
	"Points added": {
		"text": "积分已添加",
		"isAlreadyTranslated": true
	},
	"referral not generated": {
		"text": "转介没有生成",
		"isAlreadyTranslated": true
	},
	"Coupon Applied. Add a device for redeeming": {
		"text": "优惠券已申请。添加用于兑换的型号",
		"isAlreadyTranslated": true
	},
	"record not found": {
		"text": "找不到记录",
		"isAlreadyTranslated": true
	},
	"Coupon cannot be applied": {
		"text": "优惠券无法使用",
		"isAlreadyTranslated": true
	},
	"no referral redeemed": {
		"text": "没有转介被赎回",
		"isAlreadyTranslated": true
	},
	"coupon already applied": {
		"text": "优惠券已经申请",
		"isAlreadyTranslated": true
	},
	"unable to apply referral code": {
		"text": "无法应用推荐代码",
		"isAlreadyTranslated": true
	},
	"invalid referral code": {
		"text": "无效的推荐代码",
		"isAlreadyTranslated": true
	},
	"No User Found": {
		"text": "找不到用户",
		"isAlreadyTranslated": true
	},
	"already redeemed": {
		"text": "已经赎回了",
		"isAlreadyTranslated": true
	},
	"ServiceLocationApiRequest called for getDiagnosis failed": {
		"text": "调用getDiagnosis的ServiceLocationApiRequest失败",
		"isAlreadyTranslated": true
	},
	"No document found": {
		"text": "找不到文件",
		"isAlreadyTranslated": true
	},
	"Documents obtained": {
		"text": "获得的文件",
		"isAlreadyTranslated": true
	},
	"No documents obtained": {
		"text": "没有获得文件",
		"isAlreadyTranslated": true
	},
	"Documents updated": {
		"text": "文件更新",
		"isAlreadyTranslated": true
	},
	"Documents fetched": {
		"text": "提取的文件",
		"isAlreadyTranslated": true
	},
	"Documents received. Verification in progress.": {
		"text": "收到的文件。验证正在进行中。",
		"isAlreadyTranslated": true
	},
	"Claim request document added": {
		"text": "已添加保险工单文件",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching filtered  consumers": {
		"text": "在获取过滤后的消费者时出现问题",
		"isAlreadyTranslated": true
	},
	"Filtered consumer and tempConsumer": {
		"text": "过滤的消费者和tempConsumer",
		"isAlreadyTranslated": true
	},
	"Recommended reads": {
		"text": "推荐阅读",
		"isAlreadyTranslated": true
	},
	"Posts fetched successfully": {
		"text": "帖子取得成功",
		"isAlreadyTranslated": true
	},
	"Posts obtained": {
		"text": "获得的帖子",
		"isAlreadyTranslated": true
	},
	"Feedback recorded": {
		"text": "记录反馈",
		"isAlreadyTranslated": true
	},
	"Super!": {
		"text": "超级棒！",
		"isAlreadyTranslated": true
	},
	"Thank you for your feedback.": {
		"text": "感谢您的反馈意见。",
		"isAlreadyTranslated": true
	},
	"Noted!": {
		"text": "注意！",
		"isAlreadyTranslated": true
	},
	"We'll come up with more helpful solutions.": {
		"text": "我们会提出更多有用的解决方案。",
		"isAlreadyTranslated": true
	},
	"Front Side": {
		"text": "前面",
		"isAlreadyTranslated": true
	},
	"Back Side": {
		"text": "背面",
		"isAlreadyTranslated": true
	},
	"Other Documents": {
		"text": "其他文件",
		"isAlreadyTranslated": true
	},
	"Eg: Passport, Driving License": {
		"text": "例如：护照，驾驶执照",
		"isAlreadyTranslated": true
	},
	"Pickup Request Accepted": {
		"text": "接受取件请求",
		"isAlreadyTranslated": true
	},
	"Device collected by Logistics Executive": {
		"text": "物流主管收集的型号",
		"isAlreadyTranslated": true
	},
	"Inspection completed": {
		"text": "检查完成",
		"isAlreadyTranslated": true
	},
	"Please approve the Service Estimate to initiate the repair process.": {
		"text": "请批准服务预算以启动维修流程。",
		"isAlreadyTranslated": true
	},
	"The Service Estimate was rejected by you. We hope to be at your service sometime soon.": {
		"text": "服务评估被您拒绝。我们希望很快能为您服务。",
		"isAlreadyTranslated": true
	},
	"Device repair completed": {
		"text": "型号维修已完成",
		"isAlreadyTranslated": true
	},
	"Estimate/Invoice is generated for your repair.": {
		"text": "为您的维修生成估算/发票。",
		"isAlreadyTranslated": true
	},
	"Agent has arrived at the pickup location": {
		"text": "特工已到达取件地点",
		"isAlreadyTranslated": true
	},
	"Thank you for using our service, please rate your experience.": {
		"text": "感谢您使用我们的服务，请评价您的体验。",
		"isAlreadyTranslated": true
	},
	"Device returned": {
		"text": "型号返回",
		"isAlreadyTranslated": true
	},
	"Thanks for visting @ hopes to give you a good service experience.": {
		"text": "感谢visting @希望为您提供良好的服务体验。",
		"isAlreadyTranslated": true
	},
	"Your Service Request has been rescheduled as per your choice.": {
		"text": "您的服务请求已根据您的选择重新安排。",
		"isAlreadyTranslated": true
	},
	"Looking for Xperts": {
		"text": "寻找Xperts",
		"isAlreadyTranslated": true
	},
	"We're looking for the best Servify Xpert for your gadget.": {
		"text": "我们正在为您的小工具寻找最好的Servify Xpert。",
		"isAlreadyTranslated": true
	},
	"Our Xpert is on the way!": {
		"text": "我们的Xpert正在路上！",
		"isAlreadyTranslated": true
	},
	"Our Xpert has arrived": {
		"text": "我们的Xpert已经到了",
		"isAlreadyTranslated": true
	},
	"Your repair request has been rescheduled by our Xpert as discussed with you by our Xpert.": {
		"text": "您的维修请求已由我们的Xpert重新安排，正如我们的Xpert所讨论的那样。",
		"isAlreadyTranslated": true
	},
	"You have requested to reschedule your request on @": {
		"text": "您已要求在@上重新安排您的请求",
		"isAlreadyTranslated": true
	},
	"Your request has been registered at @": {
		"text": "您的请求已在@注册",
		"isAlreadyTranslated": true
	},
	"Cancelling your service request.": {
		"text": "取消您的服务请求。",
		"isAlreadyTranslated": true
	},
	"Your Service Request is cancelled.": {
		"text": "您的服务请求已取消。",
		"isAlreadyTranslated": true
	},
	"Payment received!": {
		"text": "已收到付款！",
		"isAlreadyTranslated": true
	},
	"Waiting for Xpert's approval": {
		"text": "等待Xpert的批准",
		"isAlreadyTranslated": true
	},
	"Recycle Request Accepted": {
		"text": "接收回收请求",
		"isAlreadyTranslated": true
	},
	"Request Accepted": {
		"text": "请求已接受",
		"isAlreadyTranslated": true
	},
	"Good news. Parts have been received at the Service Center": {
		"text": "好消息。零件已在服务中心收到",
		"isAlreadyTranslated": true
	},
	"We\n're looking for the best Servify Xpert for your gadget.": {
		"text": "我们正在为您的小工具寻找最好的Servify Xpert。",
		"isAlreadyTranslated": true
	},
	"Estimate Generated": {
		"text": "估计生成",
		"isAlreadyTranslated": true
	},
	"Estimation & Reimbursement": {
		"text": "估算和报销",
		"isAlreadyTranslated": true
	},
	"Drop Off": {
		"text": "放下",
		"isAlreadyTranslated": true
	},
	"Service Estimate generated": {
		"text": "已生成服务估算",
		"isAlreadyTranslated": true
	},
	"Your device has been received at the Authorised Service Center": {
		"text": "您的型号已在授权服务中心收到",
		"isAlreadyTranslated": true
	},
	"Request scheduled": {
		"text": "请求预定",
		"isAlreadyTranslated": true
	},
	"Claim raised": {
		"text": "提出索赔",
		"isAlreadyTranslated": true
	},
	"Claim eligible": {
		"text": "索赔合格",
		"isAlreadyTranslated": true
	},
	"Claim invalid": {
		"text": "索赔无效",
		"isAlreadyTranslated": true
	},
	"Claim initiated": {
		"text": "声称已启动",
		"isAlreadyTranslated": true
	},
	"Document pending": {
		"text": "文件待定",
		"isAlreadyTranslated": true
	},
	"Document verification pending": {
		"text": "文档验证待定",
		"isAlreadyTranslated": true
	},
	"Documentation incomplete": {
		"text": "文件不完整",
		"isAlreadyTranslated": true
	},
	"In-Principle Approval received": {
		"text": "收到原则批准",
		"isAlreadyTranslated": true
	},
	"Claim Estimation generated for your device": {
		"text": "为您的型号生成的索赔估算值",
		"isAlreadyTranslated": true
	},
	"Thank you for approving Claim Estimation": {
		"text": "感谢您批准索赔估算",
		"isAlreadyTranslated": true
	},
	"You rejected the Claim Estimation": {
		"text": "您拒绝了索赔估算",
		"isAlreadyTranslated": true
	},
	"Your request is pending for the want of Parts. Service Center will update you shortly on availability": {
		"text": "您的要求因缺少零件而待定。服务中心将尽快为您提供最新信息",
		"isAlreadyTranslated": true
	},
	"Good news! Engineer has received all required parts for the repair.": {
		"text": "好消息！工程师已收到维修所需的所有部件。",
		"isAlreadyTranslated": true
	},
	"Please make the Payment as per estimation for us to proceed": {
		"text": "请按照估算付款，以便我们继续",
		"isAlreadyTranslated": true
	},
	"Claim rejected by the Risk Underwriter": {
		"text": "风险承销商拒绝了索赔",
		"isAlreadyTranslated": true
	},
	"Call attempted by our Claim Verification Executive": {
		"text": "我们的声明验证主管尝试过呼叫",
		"isAlreadyTranslated": true
	},
	"Your Request is under validation": {
		"text": "您的申请正在验证中",
		"isAlreadyTranslated": true
	},
	"Dropoff Confirmed": {
		"text": "下降确认",
		"isAlreadyTranslated": true
	},
	"Device dropped": {
		"text": "型号掉线了",
		"isAlreadyTranslated": true
	},
	"Green Reward Update": {
		"text": "绿色奖励更新",
		"isAlreadyTranslated": true
	},
	"Vouchers generated": {
		"text": "凭证生成",
		"isAlreadyTranslated": true
	},
	"eRecycle Completed": {
		"text": "eRecycle已完成",
		"isAlreadyTranslated": true
	},
	"Data Erasure Completed": {
		"text": "数据擦除已完成",
		"isAlreadyTranslated": true
	},
	"Destruction Completed": {
		"text": "销毁完成",
		"isAlreadyTranslated": true
	},
	"Device rejected": {
		"text": "型号被拒绝",
		"isAlreadyTranslated": true
	},
	"Claim withdrawn": {
		"text": "索赔撤回",
		"isAlreadyTranslated": true
	},
	"Device reached at the eRecycling Hub": {
		"text": "型号在eRecycling Hub到达",
		"isAlreadyTranslated": true
	},
	"Reward Generation Pending": {
		"text": "奖励生成待定",
		"isAlreadyTranslated": true
	},
	"BER confirmed": {
		"text": "BER确认",
		"isAlreadyTranslated": true
	},
	"Processing request": {
		"text": "处理请求",
		"isAlreadyTranslated": true
	},
	"Payment initiated": {
		"text": "付款已启动",
		"isAlreadyTranslated": true
	},
	"Payment for your device has been completed": {
		"text": "您的型号付款已完成",
		"isAlreadyTranslated": true
	},
	"Payment completed": {
		"text": "付款已完成",
		"isAlreadyTranslated": true
	},
	"Device Diagnosed and Estimate Generated": {
		"text": "型号诊断和估计生成",
		"isAlreadyTranslated": true
	},
	"Looks like the repair cost is more than the depreciated value of your device. Please select the option you would like to proceed with.": {
		"text": "看起来维修成本高于型号的折旧价值。请选择您要继续的选项。",
		"isAlreadyTranslated": true
	},
	"Your device is currently being diagnosed.": {
		"text": "您的型号目前正在被诊断。",
		"isAlreadyTranslated": true
	},
	"Pending for Acceptance": {
		"text": "等待接受",
		"isAlreadyTranslated": true
	},
	"QC rejected": {
		"text": "QC拒绝了",
		"isAlreadyTranslated": true
	},
	"Parts ordered": {
		"text": "零件订购",
		"isAlreadyTranslated": true
	},
	"Engineer is awaiting a few parts to start the repair of your device.": {
		"text": "工程师正在等待几个部件来开始修复您的型号。",
		"isAlreadyTranslated": true
	},
	"QC completed": {
		"text": "质量控制完成",
		"isAlreadyTranslated": true
	},
	"Pending for assignment": {
		"text": "待分配",
		"isAlreadyTranslated": true
	},
	"Repair initiated": {
		"text": "修复已启动",
		"isAlreadyTranslated": true
	},
	"Parts rejected": {
		"text": "零件被拒绝",
		"isAlreadyTranslated": true
	},
	"Advance payment is received.": {
		"text": "收到预付款。",
		"isAlreadyTranslated": true
	},
	"Parts request created": {
		"text": "零件请求已创建",
		"isAlreadyTranslated": true
	},
	"Awesome! Your device is repaired and ready to be collected.": {
		"text": "真棒！您的型号已修复并准备好进行收集。",
		"isAlreadyTranslated": true
	},
	"parts partially issued": {
		"text": "零件部分发行",
		"isAlreadyTranslated": true
	},
	"Pledged successfully": {
		"text": "承诺成功",
		"isAlreadyTranslated": true
	},
	"Pledge voucher generated": {
		"text": "承诺凭证生成",
		"isAlreadyTranslated": true
	},
	"Pledge voucher pending": {
		"text": "承诺凭证待定",
		"isAlreadyTranslated": true
	},
	"Device replaced": {
		"text": "型号已更换",
		"isAlreadyTranslated": true
	},
	"doa completed": {
		"text": "doa完成了",
		"isAlreadyTranslated": true
	},
	"pledge request created": {
		"text": "承诺请求已创建",
		"isAlreadyTranslated": true
	},
	"Service completed and rated": {
		"text": "服务已完成并已评级",
		"isAlreadyTranslated": true
	},
	"Service completed": {
		"text": "服务完成",
		"isAlreadyTranslated": true
	},
	"intimate customer": {
		"text": "亲密的客户",
		"isAlreadyTranslated": true
	},
	"advance collected": {
		"text": "提前收集",
		"isAlreadyTranslated": true
	},
	"parts issued": {
		"text": "发布的部分",
		"isAlreadyTranslated": true
	},
	"parts request accepted": {
		"text": "零件要求被接受",
		"isAlreadyTranslated": true
	},
	"work in progress": {
		"text": "工作正在进行中",
		"isAlreadyTranslated": true
	},
	"BER rejected": {
		"text": "BER拒绝了",
		"isAlreadyTranslated": true
	},
	"BER repair": {
		"text": "BER修复",
		"isAlreadyTranslated": true
	},
	"BER accessories rejected": {
		"text": "BER配件被拒绝",
		"isAlreadyTranslated": true
	},
	"BER accessories validated": {
		"text": "BER配件经过验证",
		"isAlreadyTranslated": true
	},
	"BER accessories received": {
		"text": "收到BER配件",
		"isAlreadyTranslated": true
	},
	"BER documents validated": {
		"text": "BER文件已经过验证",
		"isAlreadyTranslated": true
	},
	"BER device received": {
		"text": "收到BER型号",
		"isAlreadyTranslated": true
	},
	"BER cancel": {
		"text": "BER取消",
		"isAlreadyTranslated": true
	},
	"BER approved": {
		"text": "BER批准",
		"isAlreadyTranslated": true
	},
	"BER payment completed": {
		"text": "BER付款已完成",
		"isAlreadyTranslated": true
	},
	"BER payment initiated": {
		"text": "BER付款已启动",
		"isAlreadyTranslated": true
	},
	"vouchers generation pending": {
		"text": "优惠券一代待定",
		"isAlreadyTranslated": true
	},
	"device collected": {
		"text": "型号收集",
		"isAlreadyTranslated": true
	},
	"data wipe completed": {
		"text": "数据擦除完成",
		"isAlreadyTranslated": true
	},
	"device recycle initiated": {
		"text": "型号回收启动",
		"isAlreadyTranslated": true
	},
	"Under validation": {
		"text": "正在验证中",
		"isAlreadyTranslated": true
	},
	"Call attempted": {
		"text": "呼叫尝试",
		"isAlreadyTranslated": true
	},
	"Claim rejected": {
		"text": "索赔被拒绝",
		"isAlreadyTranslated": true
	},
	"payment pending": {
		"text": "付款等待中",
		"isAlreadyTranslated": true
	},
	"Parts received": {
		"text": "收到的零件",
		"isAlreadyTranslated": true
	},
	"parts pending": {
		"text": "零件待定",
		"isAlreadyTranslated": true
	},
	"claim estimation rejected": {
		"text": "索赔估计被拒绝",
		"isAlreadyTranslated": true
	},
	"claim estimation approved": {
		"text": "索赔估计批准",
		"isAlreadyTranslated": true
	},
	"claim estimation generated": {
		"text": "产生索赔估算",
		"isAlreadyTranslated": true
	},
	"In-principle approved": {
		"text": "原则上批准",
		"isAlreadyTranslated": true
	},
	"document incomplete": {
		"text": "文件不完整",
		"isAlreadyTranslated": true
	},
	"device received": {
		"text": "收到的型号",
		"isAlreadyTranslated": true
	},
	"estimation generated": {
		"text": "估计产生",
		"isAlreadyTranslated": true
	},
	"pending for approval": {
		"text": "待批准",
		"isAlreadyTranslated": true
	},
	"repair cancel": {
		"text": "修理取消",
		"isAlreadyTranslated": true
	},
	"service cancellation initiated": {
		"text": "服务取消已启动",
		"isAlreadyTranslated": true
	},
	"reschedule requested": {
		"text": "要求重新安排",
		"isAlreadyTranslated": true
	},
	"service suspended": {
		"text": "服务暂停",
		"isAlreadyTranslated": true
	},
	"serviceman reached": {
		"text": "军人到达了",
		"isAlreadyTranslated": true
	},
	"serviceman journey initiated": {
		"text": "军人之旅启动",
		"isAlreadyTranslated": true
	},
	"request waiting for assignment": {
		"text": "请求等待分配",
		"isAlreadyTranslated": true
	},
	"service reschedule": {
		"text": "服务重新安排",
		"isAlreadyTranslated": true
	},
	"consumer arrived": {
		"text": "消费者到了",
		"isAlreadyTranslated": true
	},
	"service cancel": {
		"text": "服务取消",
		"isAlreadyTranslated": true
	},
	"service completed": {
		"text": "服务完成",
		"isAlreadyTranslated": true
	},
	"agent arrived": {
		"text": "特工到了",
		"isAlreadyTranslated": true
	},
	"device dispatched": {
		"text": "派遣的型号",
		"isAlreadyTranslated": true
	},
	"Device dispatched": {
		"text": "型号出动",
		"isAlreadyTranslated": true
	},
	"estimation rejected": {
		"text": "估计被拒绝",
		"isAlreadyTranslated": true
	},
	"estimation approval": {
		"text": "估计批准",
		"isAlreadyTranslated": true
	},
	"service initiated": {
		"text": "服务已启动",
		"isAlreadyTranslated": true
	},
	"device shipped": {
		"text": "型号发货",
		"isAlreadyTranslated": true
	},
	"pickup request accepted": {
		"text": "接受请求",
		"isAlreadyTranslated": true
	},
	"@ has accepted your repair request": {
		"text": "@已接受您的维修请求",
		"isAlreadyTranslated": true
	},
	"It's fixed!": {
		"text": "这是固定的！",
		"isAlreadyTranslated": true
	},
	"Installation in progress": {
		"text": "正在安装中",
		"isAlreadyTranslated": true
	},
	"You have successfully cancelled the service request. We hope to be at your service sometime soon.": {
		"text": "您已成功取消服务请求。我们希望很快能为您服务。",
		"isAlreadyTranslated": true
	},
	"Your installation request has been rescheduled as per your choice.": {
		"text": "您的安装请求已根据您的选择重新安排。",
		"isAlreadyTranslated": true
	},
	"Received request for installation": {
		"text": "收到安装请求",
		"isAlreadyTranslated": true
	},
	"Your installation request has been rescheduled by our Xpert.": {
		"text": "您的安装请求已由我们的Xpert重新安排。",
		"isAlreadyTranslated": true
	},
	"@ has accepted your installation request": {
		"text": "@已接受您的安装请求",
		"isAlreadyTranslated": true
	},
	"Demo in progress": {
		"text": "正在进行演示",
		"isAlreadyTranslated": true
	},
	"Your demo request has been rescheduled as per your choice.": {
		"text": "您的演示请求已根据您的选择重新安排。",
		"isAlreadyTranslated": true
	},
	"Received request for demo": {
		"text": "收到演示请求",
		"isAlreadyTranslated": true
	},
	"Your demo request has been rescheduled by our Xpert.": {
		"text": "您的演示请求已由我们的Xpert重新安排。",
		"isAlreadyTranslated": true
	},
	"@ has accepted your demo request": {
		"text": "@已接受您的演示请求",
		"isAlreadyTranslated": true
	},
	"Service in progress": {
		"text": "服务正在进行中",
		"isAlreadyTranslated": true
	},
	"Received request for service": {
		"text": "收到服务请求",
		"isAlreadyTranslated": true
	},
	"@ has accepted your service request": {
		"text": "@已接受您的服务请求",
		"isAlreadyTranslated": true
	},
	"Verification Complete": {
		"text": "验证完成",
		"isAlreadyTranslated": true
	},
	"Recycle Initiated": {
		"text": "回收发起",
		"isAlreadyTranslated": true
	},
	"Your eRecycle Request is cancelled": {
		"text": "您的eRecycle请求已取消",
		"isAlreadyTranslated": true
	},
	"eRecycling Complete": {
		"text": "电子转回完成",
		"isAlreadyTranslated": true
	},
	"Eligible For Claim": {
		"text": "符合索赔条件",
		"isAlreadyTranslated": true
	},
	"Invalid Claim": {
		"text": "索赔无效",
		"isAlreadyTranslated": true
	},
	"Claim number is generated from short formats": {
		"text": "索赔号由短格式生成",
		"isAlreadyTranslated": true
	},
	"Repair completed, please collect the device at the earliest.": {
		"text": "修复完成后，请尽早收集型号。",
		"isAlreadyTranslated": true
	},
	"Repair completed, QC pending": {
		"text": "修复完成，QC待定。",
		"isAlreadyTranslated": true
	},
	"eRecycle Initiated": {
		"text": "eRecycle发起",
		"isAlreadyTranslated": true
	},
	"Your eRecycle Request is cancelled.": {
		"text": "您的eRecycle请求已取消。",
		"isAlreadyTranslated": true
	},
	"eRequest cancelled": {
		"text": "eRequest已取消",
		"isAlreadyTranslated": true
	},
	"Dropoff Request Registered": {
		"text": "下注请求已注册",
		"isAlreadyTranslated": true
	},
	"Get the Depreciated Value of This Device": {
		"text": "获取此型号的折旧价值",
		"isAlreadyTranslated": true
	},
	"You will be eligible to receive the depreciated value of the device amounting to INR @. Please note that an admin fee of INR @ will be deducted from the receivable amount as per the terms of the plan.": {
		"text": "您将有资格获得相当于INR @的型号的折旧价值。请注意，INR @的管理费将根据计划条款从应收金额中扣除。",
		"isAlreadyTranslated": true
	},
	"Repair This Device and I will pay the Difference": {
		"text": "修复此型号，我将支付差价",
		"isAlreadyTranslated": true
	},
	"Repair estimate for this device is INR @. You will pay additional amount of INR @, which includes admin fee of INR @ over and above the covered value of this device of INR @.": {
		"text": "该型号的维修估算值为INR @。您将支付额外的INR @，其中包括INR @的管理费超过此INR @型号的覆盖值。",
		"isAlreadyTranslated": true
	},
	"Cancel Repair and Get Back the Device": {
		"text": "取消修复并取回型号",
		"isAlreadyTranslated": true
	},
	"We will return your device without repairs.": {
		"text": "我们将退回您的型号，无需维修。",
		"isAlreadyTranslated": true
	},
	"Process completed": {
		"text": "流程已完成",
		"isAlreadyTranslated": true
	},
	"Cancel Repair": {
		"text": "取消修复",
		"isAlreadyTranslated": true
	},
	"Option 1 : Proceed With Accessories": {
		"text": "选项1：继续使用附件",
		"isAlreadyTranslated": true
	},
	"You will be eligible to receive a reimbursement of <> after deducting <> towards the admin fee. Please make sure you courier us the accessories at <> within <> days.": {
		"text": "在扣除<>管理费后，您将有资格获得<>的报销。请确保在<>天内快递给我们<>的快递。",
		"isAlreadyTranslated": true
	},
	"Option 2 : Proceed Without Accessories": {
		"text": "选项2：继续进行配件",
		"isAlreadyTranslated": true
	},
	"You will be eligible to receive a reimbursement of <> after deducting <> towards missing accessories and <> towards admin fee.": {
		"text": "扣除<>缺少配件后，您将有资格获得<>的报销，<>支付管理费。",
		"isAlreadyTranslated": true
	},
	"Your Service Request has been assigned to Technician.": {
		"text": "您的服务请求已分配给技术人员。",
		"isAlreadyTranslated": true
	},
	"Service request raised to Godrej Smart Care": {
		"text": "服务请求提交给Godrej Smart Care",
		"isAlreadyTranslated": true
	},
	"pending for assignment": {
		"text": "待分配",
		"isAlreadyTranslated": true
	},
	"Service suspended": {
		"text": "服务暂停",
		"isAlreadyTranslated": true
	},
	"Your Service Request is accepted.": {
		"text": "您的服务请求被接受。",
		"isAlreadyTranslated": true
	},
	"Approve Estimation": {
		"text": "批准估算",
		"isAlreadyTranslated": true
	},
	"You rejected estimation": {
		"text": "你拒绝估计",
		"isAlreadyTranslated": true
	},
	"Invoice/Estimation generated": {
		"text": "生成发票/估算",
		"isAlreadyTranslated": true
	},
	"Good to see you.": {
		"text": "很高兴见到你。",
		"isAlreadyTranslated": true
	},
	"Request Rescheduled": {
		"text": "请求重新安排",
		"isAlreadyTranslated": true
	},
	"Waiting for Assignment": {
		"text": "等待作业",
		"isAlreadyTranslated": true
	},
	"Awaiting Technician assignment": {
		"text": "等待技术人员任务",
		"isAlreadyTranslated": true
	},
	"Request in process!": {
		"text": "请求正在处理中！",
		"isAlreadyTranslated": true
	},
	"Xpert arrived": {
		"text": "Xpert到了",
		"isAlreadyTranslated": true
	},
	"Your device has been received at the Authorised Service Provider": {
		"text": "您的型号已在授权服务提供商处收到",
		"isAlreadyTranslated": true
	},
	"In-Principle Approval": {
		"text": "原则上批准",
		"isAlreadyTranslated": true
	},
	"claim Estimation": {
		"text": "索赔估计",
		"isAlreadyTranslated": true
	},
	"service dismissed": {
		"text": "服务被驳回",
		"isAlreadyTranslated": true
	},
	"Service creation pending at Brand": {
		"text": "在Brand上待定服务",
		"isAlreadyTranslated": true
	},
	"Repair in progress": {
		"text": "正在修理",
		"isAlreadyTranslated": true
	},
	"Service Accepted By Technician": {
		"text": "技术人员接受的服务",
		"isAlreadyTranslated": true
	},
	"Pickup": {
		"text": "捡起",
		"isAlreadyTranslated": true
	},
	"Normally takes 1-3 days": {
		"text": "通常需要1-3天",
		"isAlreadyTranslated": true
	},
	"Diagnosis": {
		"text": "诊断",
		"isAlreadyTranslated": true
	},
	"Normally takes 2-3 hours from the time of receipt of device": {
		"text": "通常需要2-3小时才能收到型号",
		"isAlreadyTranslated": true
	},
	"Estimation & Repair": {
		"text": "估计和修复",
		"isAlreadyTranslated": true
	},
	"Normally takes a day": {
		"text": "通常需要一天",
		"isAlreadyTranslated": true
	},
	"Return to Customer": {
		"text": "返回给顾客",
		"isAlreadyTranslated": true
	},
	"Normally returned immediately after repairs": {
		"text": "通常在维修后立即返回",
		"isAlreadyTranslated": true
	},
	"Repair Initiation": {
		"text": "修复启动",
		"isAlreadyTranslated": true
	},
	"Your request has been registered at @. Please visit as per the appointment scheduled. We hope your service experience will be great": {
		"text": "您的请求已在@注册。请按照预约安排参观。我们希望您的服务体验会很棒",
		"isAlreadyTranslated": true
	},
	"Normally returned immediately after repairs. You will be intimated to collect it accordingly": {
		"text": "通常在维修后立即返回。您将被暗示相应地收集它",
		"isAlreadyTranslated": true
	},
	"Service Initiation": {
		"text": "服务启动",
		"isAlreadyTranslated": true
	},
	"Normally as per the scheduled date and time": {
		"text": "通常按照预定的日期和时间",
		"isAlreadyTranslated": true
	},
	"Device Validation": {
		"text": "型号验证",
		"isAlreadyTranslated": true
	},
	"During the visit of the Technician": {
		"text": "在技​​术人员的访问期间",
		"isAlreadyTranslated": true
	},
	"Service & Payment": {
		"text": "服务与付款",
		"isAlreadyTranslated": true
	},
	"Normally once the technician visits your premise": {
		"text": "通常一旦技术人员访问您的前提",
		"isAlreadyTranslated": true
	},
	"Feedback": {
		"text": "反馈",
		"isAlreadyTranslated": true
	},
	"This is when you can rate service experience": {
		"text": "这是您可以评价服务体验的时间",
		"isAlreadyTranslated": true
	},
	"Pledge to eRecycle": {
		"text": "承诺电子召回",
		"isAlreadyTranslated": true
	},
	"Normally takes a Day": {
		"text": "通常需要一天",
		"isAlreadyTranslated": true
	},
	"Takes approx 1 Day": {
		"text": "大约需要1天",
		"isAlreadyTranslated": true
	},
	"Green Rewards": {
		"text": "绿色奖励",
		"isAlreadyTranslated": true
	},
	"eRecycle": {
		"text": "eRecycle",
		"isAlreadyTranslated": true
	},
	"Claim Request Raised": {
		"text": "提出保险工单",
		"isAlreadyTranslated": true
	},
	"We have received your claim request. Our representative will connect with you within the next 1-2 business days to process it further": {
		"text": "我们已收到您的索赔申请。我们的代表将在接下来的1-2个工作日内与您联系，以进一步处理",
		"isAlreadyTranslated": true
	},
	"Verification": {
		"text": "验证",
		"isAlreadyTranslated": true
	},
	"Device handed over by QCEngineer to StoreManager": {
		"text": "型号由QCEngineer移交给StoreManager",
		"isAlreadyTranslated": true
	},
	"Your device is repaired and dispatched.": {
		"text": "您的型号已修复并已发送。",
		"isAlreadyTranslated": true
	},
	"Your device is currently getting repaired.": {
		"text": "您的型号目前正在修复中。",
		"isAlreadyTranslated": true
	},
	"Your device has been returned. Kindly rate your experience.": {
		"text": "您的型号已退回。请评价您的体验。",
		"isAlreadyTranslated": true
	},
	"Repair complete": {
		"text": "修复完成",
		"isAlreadyTranslated": true
	},
	"Device handed over by QCEngineer to CustomerCare": {
		"text": "型号由QCEngineer移交给CustomerCare",
		"isAlreadyTranslated": true
	},
	"Device handed over by ServicelocationEngineer to QCEngineer": {
		"text": "型号由ServicelocationEngineer移交给QCEngineer",
		"isAlreadyTranslated": true
	},
	"Device accepted by QCEngineer from ServicelocationEngineer": {
		"text": "来自ServicelocationEngineer的QCEngineer接受的型号",
		"isAlreadyTranslated": true
	},
	"Device accepted by ServicelocationEngineer from CustomerCare": {
		"text": "CustomerCare的ServicelocationEngineer接受的型号",
		"isAlreadyTranslated": true
	},
	"Device handed over by CustomerCare to ServicelocationEngineer": {
		"text": "型号由CustomerCare移交给ServicelocationEngineer",
		"isAlreadyTranslated": true
	},
	"Device handed over by ServicelocationEngineer to CustomerCare": {
		"text": "型号由ServicelocationEngineer移交给CustomerCare",
		"isAlreadyTranslated": true
	},
	"Device accepted by CustomerCare from QCEngineer": {
		"text": "QCEngineer的CustomerCare接受的型号",
		"isAlreadyTranslated": true
	},
	"Device handed over by QCEngineer to ServicelocationEngineer": {
		"text": "型号由QCEngineer移交给ServicelocationEngineer",
		"isAlreadyTranslated": true
	},
	"Device handed over by StoreManager to CustomerCare": {
		"text": "StoreManager将型号移交给CustomerCare",
		"isAlreadyTranslated": true
	},
	"Device handed over by StoreManager to ServicelocationEngineer": {
		"text": "型号由StoreManager移交给ServicelocationEngineer",
		"isAlreadyTranslated": true
	},
	"Normally happens immediately on fulfillment of required documentation": {
		"text": "通常在完成所需文档后立即发生",
		"isAlreadyTranslated": true
	},
	"Device handed over by StoreManager to QCEngineer": {
		"text": "StoreManager将型号移交给QCEngineer",
		"isAlreadyTranslated": true
	},
	"Normally takes 2-3 days from the time of receipt of device": {
		"text": "通常需要2-3天才能收到型号",
		"isAlreadyTranslated": true
	},
	"Engineer To Store": {
		"text": "工程师存储",
		"isAlreadyTranslated": true
	},
	"Engineer To CustomerCare": {
		"text": "工程师到CustomerCare",
		"isAlreadyTranslated": true
	},
	"Engineer To QC executive": {
		"text": "工程师到QC执行官",
		"isAlreadyTranslated": true
	},
	"Store To Engineer": {
		"text": "存储到工程师",
		"isAlreadyTranslated": true
	},
	"Store To CustomerCare": {
		"text": "存储到CustomerCare",
		"isAlreadyTranslated": true
	},
	"Store To QC executive": {
		"text": "存储到QC主管",
		"isAlreadyTranslated": true
	},
	"QC To Engineer": {
		"text": "QC工程师",
		"isAlreadyTranslated": true
	},
	"QC To CustomerCare": {
		"text": "QC致CustomerCare",
		"isAlreadyTranslated": true
	},
	"QC To Store": {
		"text": "QC存储",
		"isAlreadyTranslated": true
	},
	"CC To Engineer": {
		"text": "CC To Engineer",
		"isAlreadyTranslated": true
	},
	"CC To Store": {
		"text": "CC存储",
		"isAlreadyTranslated": true
	},
	"CC To QC": {
		"text": "CC到QC",
		"isAlreadyTranslated": true
	},
	"Issues validated": {
		"text": "问题经过验证",
		"isAlreadyTranslated": true
	},
	"Diagnosis completed": {
		"text": "诊断完成",
		"isAlreadyTranslated": true
	},
	"Diagnosis initiated": {
		"text": "诊断已启动",
		"isAlreadyTranslated": true
	},
	"Device accepted": {
		"text": "型号已被接受",
		"isAlreadyTranslated": true
	},
	"Device handed over": {
		"text": "型号移交",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer assignment": {
		"text": "等待工程师的任务",
		"isAlreadyTranslated": true
	},
	"Please handover the device to the Logistics Agent only if he provides this way bill number to prove his identity.": {
		"text": "只有当他提供这种方式的账单号以证明其身份时，才能将型号移交给后勤代理。",
		"isAlreadyTranslated": true
	},
	"Express Check In Available": {
		"text": "快速入住",
		"isAlreadyTranslated": true
	},
	"Your repair request ( @ / @ ) has been cancelled.": {
		"text": "您的维修请求（@ / @）已被取消。",
		"isAlreadyTranslated": true
	},
	"Invoice generated for your repair request (@ / @). Please initiate payment.": {
		"text": "为您的维修请求生成的发票（@ / @）。请开始付款。",
		"isAlreadyTranslated": true
	},
	"Repair initiated for your request (@ / @).": {
		"text": "为您的请求启动修复（@ / @）。",
		"isAlreadyTranslated": true
	},
	"Please approve the estimate for your repair request ( @ / @ )": {
		"text": "请批准您的维修请求估算（@ / @）",
		"isAlreadyTranslated": true
	},
	"Repair completed for your request (@ / @).": {
		"text": "修复完成了您的请求（@ / @）。",
		"isAlreadyTranslated": true
	},
	"Your @ has reached the Authorised Service Centre and is currently being diagnosed ( @ ).": {
		"text": "您的@已到达授权服务中心，目前正在诊断（@）。",
		"isAlreadyTranslated": true
	},
	"Your request (@) has been completed & closed.": {
		"text": "您的请求（@）已完成并已关闭。",
		"isAlreadyTranslated": true
	},
	"Your @ ( @ ) has been repaired & delivered.": {
		"text": "您的@（@）已经修复并交付。",
		"isAlreadyTranslated": true
	},
	"Your payment has been received": {
		"text": "您的付款已收到",
		"isAlreadyTranslated": true
	},
	"Track request result": {
		"text": "跟踪请求结果",
		"isAlreadyTranslated": true
	},
	"Service Charge": {
		"text": "服务费",
		"isAlreadyTranslated": true
	},
	"Total": {
		"text": "总",
		"isAlreadyTranslated": true
	},
	"Amount Payable": {
		"text": "应付款",
		"isAlreadyTranslated": true
	},
	"Earphone/Headphone/Handsfree Issue": {
		"text": "耳机/耳机/免提问题",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on @. Your SECRET CODE is @. Please handover the device to the Logistics Agent only if he provides this secret code to prove his identity.": {
		"text": "后勤代理将在@到达您的位置。你的秘密代码是@。只有在提供此密码以证明其身份时，才能将型号移交给后勤代理。",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on @. Your WAY BILL NUMBER is @. Please handover the device to the Logistics Agent only if he provides this way bill number to prove his identity.": {
		"text": "后勤代理将在@到达您的位置。您的WAY BILL NUMBER是@。只有当他提供这种方式的账单号以证明其身份时，才能将型号移交给后勤代理。",
		"isAlreadyTranslated": true
	},
	"Service estimate will be shared before initiating the installation process": {
		"text": "在启动安装过程之前将共享服务估计",
		"isAlreadyTranslated": true
	},
	"Rate your experience after the service is complete": {
		"text": "在服务完成后评估您的体验",
		"isAlreadyTranslated": true
	},
	"The technician will validate the details of the device provided by you": {
		"text": "技术人员将验证您提供的型号的详细信息",
		"isAlreadyTranslated": true
	},
	"Please approve the estimate for your repair request (@ / @)": {
		"text": "请批准您的维修请求估算（@ / @）",
		"isAlreadyTranslated": true
	},
	"Your @ (@) has been repaired & delivered.": {
		"text": "您的@（@）已经修复并交付。",
		"isAlreadyTranslated": true
	},
	"You will be eligible to receive a reimbursement of INR @ after deducting INR @ towards missing accessories and INR @ towards admin fee.": {
		"text": "在扣除INR @对缺少的配件和INR @管理费后，您将有资格获得INR @的报销。",
		"isAlreadyTranslated": true
	},
	"Your repair request (@ / @) has been cancelled.": {
		"text": "您的维修请求（@ / @）已被取消。",
		"isAlreadyTranslated": true
	},
	"Please approve the Service Estimate": {
		"text": "请批准服务预算",
		"isAlreadyTranslated": true
	},
	"We have received your claim request. It will be processed within the next 48 business hours": {
		"text": "我们已收到您的索赔申请。它将在接下来的48个工作小时内处理",
		"isAlreadyTranslated": true
	},
	"Rating given by customer : @": {
		"text": "客户评价：@",
		"isAlreadyTranslated": true
	},
	"Your @ has reached the Authorised Service Centre and is currently being diagnosed (@).": {
		"text": "您的@已到达授权服务中心，目前正在诊断（@）。",
		"isAlreadyTranslated": true
	},
	"It\n's fixed!": {
		"text": "它已经修好了！",
		"isAlreadyTranslated": true
	},
	"Thank you for eRecycling with Servify": {
		"text": "感谢您使用Servify进行电子转回",
		"isAlreadyTranslated": true
	},
	"Invoice generated": {
		"text": "生成发票",
		"isAlreadyTranslated": true
	},
	"Your device has been succesfully collected and will be delivered to Servify": {
		"text": "您的型号已成功收集，并将交付给Servify",
		"isAlreadyTranslated": true
	},
	"Logistics Agent @ @ (@) will arrive at your location today. Your SECRET CODE is @. Please handover the device to the Logistics Agent only if he provides this secret code to prove his identity.": {
		"text": "物流代理@@（@）将于今天到达您的位置。你的秘密代码是@。只有在提供此密码以证明其身份时，才能将型号移交给后勤代理。",
		"isAlreadyTranslated": true
	},
	"You have rescheduled the pickup of your device to @": {
		"text": "您已将型号的取件重新安排到@",
		"isAlreadyTranslated": true
	},
	"Claim Intimation Number Generated.": {
		"text": "索赔估计数生成。",
		"isAlreadyTranslated": true
	},
	"Your device was not powering on, and so our executive could not collect your device. Please cancel this request and raise another one under 'Carry-in' mode.": {
		"text": "您的型号未启动，因此我们的主管无法收集您的型号。请取消此请求并在'进位'模式下再提出一个请求。",
		"isAlreadyTranslated": true
	},
	"Your device IMEI did not match what was provided by you while raising the request. Please cancel this request and raise another one by providing IMEI of the device for repair.": {
		"text": "您的型号IMEI与提出请求时提供的内容不符。请通过提供型号的IMEI进行维修来取消此请求并提出另一个请求。",
		"isAlreadyTranslated": true
	},
	"You will be eligible to receive a reimbursement of INR @ after deducting INR @ towards the admin fee. Please make sure you courier us the accessories at @ within @ days.": {
		"text": "在扣除INR @管理费后，您将有资格获得INR @的报销。请确保您在@ days内快递我们的配件。",
		"isAlreadyTranslated": true
	},
	"Your device’s data was not backed up. Please reschedule the request as per you convenience.": {
		"text": "您的型号数据未备份。请根据您的方便重新安排请求。",
		"isAlreadyTranslated": true
	},
	"Our Executive was unable to reach you on phone or your address. Please reschedule the pickup as per your convenience.": {
		"text": "我们的行政人员无法通过电话或您的地址与您联系。请根据您的方便重新安排取件。",
		"isAlreadyTranslated": true
	},
	"Logistics Agent @ @ (@) has arrived at your location. Your SECRET CODE is @. Please handover the device to the Logistics Agent only if he provides this secret code to prove his identity.": {
		"text": "物流代理@@（@）已到达您所在的位置。你的秘密代码是@。只有在提供此密码以证明其身份时，才能将型号移交给后勤代理。",
		"isAlreadyTranslated": true
	},
	"Repair completed. Device will be delivered soon": {
		"text": "修复完成。型号即将交付",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on @ to deliver your device.": {
		"text": "后勤代理将通过@到达您的位置以交付您的型号。",
		"isAlreadyTranslated": true
	},
	"Logistics Agent @ @ (@) has picked up your device from the Service Centre and will deliver it today": {
		"text": "后勤代理@@（@）已从服务中心领取您的型号，并将于今天提供",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on rescheduled date - @ to deliver your device.": {
		"text": "后勤代理将在重新安排的日期到达您的位置 - @以交付您的型号。",
		"isAlreadyTranslated": true
	},
	"Device delivered! Please rate your experience": {
		"text": "型号交付！请评价您的体验",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not deliver your device today as you were not available at your location. You can contact Logistics Support (@) to reschedule device delivery": {
		"text": "后勤代理无法在您的位置提供您的型号。您可以联系后勤支持（@）重新安排型号交付",
		"isAlreadyTranslated": true
	},
	"Normally takes 2-5 Days": {
		"text": "通常需要2-5天",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not deliver your device today as you were not reachable on call. You can contact Logistics Support (@) to reschedule device delivery": {
		"text": "物流代理今天无法提供您的型号，因为您无法通过电话联系。您可以联系后勤支持（@）重新安排型号交付",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not deliver your device today. You can contact Logistics Support (@) to reschedule device delivery": {
		"text": "后勤代理今天无法交付您的型号。您可以联系后勤支持（@）重新安排型号交付",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not deliver your device today as you were not available at your location. Please reschedule delivery by tapping the menu on top right corner": {
		"text": "后勤代理无法在您的位置提供您的型号。请点击右上角的菜单重新安排发货",
		"isAlreadyTranslated": true
	},
	"Logistics Agent @ @ (@) has arrived at your location to deliver your device": {
		"text": "物流代理@@（@）已到达您的位置以交付您的型号",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not deliver your device today as you were not reachable on call. Please reschedule delivery by tapping the menu on top right corner": {
		"text": "物流代理今天无法提供您的型号，因为您无法通过电话联系。请点击右上角的菜单重新安排发货",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not deliver your device today. Please reschedule device delivery as per your convenience by tapping the menu on top right corner": {
		"text": "后勤代理今天无法交付您的型号。请点击右上角的菜单，按照您的方便重新安排型号交付",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not deliver your device today. Please contact Customer Support (18001214984) to reschedule device delivery": {
		"text": "后勤代理今天无法交付您的型号。请联系客户支持（18001214984）重新安排型号交付",
		"isAlreadyTranslated": true
	},
	"Logistics Agent @ @ (@) will pick up your device today. Secret Code shared by the Agent for device collection should be @. Please keep an ID Proof ready; a picture of the same is required for ownership validation.": {
		"text": "后勤代理@@（@）将立即拿起您的型号。代理共享的用于型号收集的密码应为@。请准备好身份证明;所有权验证需要相同的图片。",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will pick up your device on rescheduled date - @. Your Secret Code is @. Only handover the device to the Agent only if he provides this code for verification": {
		"text": "后勤代理将在重新安排的日期 - @上提取您的型号。你的密码是@。仅在他提供此代码进行验证时才将型号切换到代理",
		"isAlreadyTranslated": true
	},
	"Logistics Agent @ @ has picked up your device and it will be delivered to Servify.": {
		"text": "物流代理@@已经拿起您的型号，它将被送到Servify。",
		"isAlreadyTranslated": true
	},
	"Device has been shipped to Servify via @.": {
		"text": "型号已通过@运送到Servify。",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on rescheduled date - @. Your Secret Code is @. Handover the device to the Agent only if he provides this code for verification": {
		"text": "后勤代理将在重新安排的日期到达您的位置 - @。你的密码是@。仅当型号提供此代码进行验证时，才将型号切换到代理",
		"isAlreadyTranslated": true
	},
	"IMEI provided and device IMEI did not match. Please contact Customer Support (18001214984) and provide correct IMEI or raise another request with IMEI of the phone for repair.": {
		"text": "IMEI提供和型号IMEI不匹配。请联系客户支持（18001214984）并提供正确的IMEI或通过电话的IMEI提出另一个请求以进行维修。",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not pick up your device today as device data was not backed up. Pickup has been rescheduled to @ as requested.": {
		"text": "物流代理现在无法提取您的型号，因为未备份型号数据。皮卡已根据要求重新安排到@。",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not pick up your device today as you were not available at your location. Please reschedule the pickup by tapping on the top right corner": {
		"text": "物流代理商今天无法接收您的型号，因为您所在的位置无法使用。请点击右上角重新安排取件",
		"isAlreadyTranslated": true
	},
	"The Logistics Agent will receive your @ @ device and deliver it to Servify.": {
		"text": "后勤代理将收到您的@@型号并将其交付给Servify。",
		"isAlreadyTranslated": true
	},
	"Your device has been succesfully collected and will be delivered to Authorised Service Provider": {
		"text": "您的型号已成功收集，并将交付给授权服务提供商",
		"isAlreadyTranslated": true
	},
	"You Visit the\nAuthorised Service Center": {
		"text": "您访问\n授权服务中心",
		"isAlreadyTranslated": true
	},
	"Your device has been succesfully collected and will be delivered to ": {
		"text": "您的型号已成功收集并将交付给您",
		"isAlreadyTranslated": true
	},
	"Device has been shipped to Authorized Service centre via @": {
		"text": "型号已通过@运送到授权服务中心",
		"isAlreadyTranslated": true
	},
	"Our associate will get back to you to schedule a suitable time for pick up of your device": {
		"text": "我们的员工会尽快给您安排合适的时间来领取您的型号",
		"isAlreadyTranslated": true
	},
	"Your dropoff is scheduled on @ at @.": {
		"text": "您的下车时间安排在@ at @。",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on rescheduled date - @, as requested.\n(@)": {
		"text": "后勤代理将根据要求在重新安排的日期 - @到达您的位置。\n（@）",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on rescheduled date - @, as requested.": {
		"text": "后勤代理将根据要求在重新安排的日期 - @到达您的位置。",
		"isAlreadyTranslated": true
	},
	"You have requested to reschedule your request on @.\n(@)": {
		"text": "您已要求在@。\n（@）上重新安排您的请求",
		"isAlreadyTranslated": true
	},
	"You have requested to reschedule your request on @.": {
		"text": "您已要求在@上重新安排您的请求。",
		"isAlreadyTranslated": true
	},
	"Your device has been dispatched from the Service Centre, through @ with TrackingID @": {
		"text": "您的型号已从服务中心发送，通过@with TrackingID @",
		"isAlreadyTranslated": true
	},
	"Service is already booked for @ .Please call us at 1800225511 for further queries": {
		"text": "服务已经预订@。请致电1800225511进一步查询",
		"isAlreadyTranslated": true
	},
	"Service cannot be created. Please call us at 1800225511 for further queries": {
		"text": "无法创建服务。如有任何疑问，请致电1800225511",
		"isAlreadyTranslated": true
	},
	"Service cannot be created.": {
		"text": "无法创建服务。",
		"isAlreadyTranslated": true
	},
	"webhook PaymentID match": {
		"text": "webhook PaymentID匹配",
		"isAlreadyTranslated": true
	},
	"ERR: webhook PaymentID mismatch": {
		"text": "错误：webhook PaymentID不匹配",
		"isAlreadyTranslated": true
	},
	"Customer wants to cancel pickup": {
		"text": "客户想取消取件",
		"isAlreadyTranslated": true
	},
	"Device has been shipped to Authorized Service centre via @.": {
		"text": "型号已通过@运送到授权服务中心。",
		"isAlreadyTranslated": true
	},
	"Your iPhone has been eRecycled. Please rate your eRecycling experience": {
		"text": "您的iPhone已经过eRecycled。请评价您的电子转回体验",
		"isAlreadyTranslated": true
	},
	"Dropoff of your device at @ is confirmed. Servify will collect the device shortly.": {
		"text": "确认您的型号在@的下降。 Servify将很快收集该型号。",
		"isAlreadyTranslated": true
	},
	"We have disassembled key components of your device and will be sending it to the eRecycling Hub soon.": {
		"text": "我们已经拆解了您型号的关键组件，并很快将其发​​送到eRecycling Hub。",
		"isAlreadyTranslated": true
	},
	"Your device has been succesfully collected and will be delivered to @ soon": {
		"text": "您的型号已成功收集，很快就会发送给@",
		"isAlreadyTranslated": true
	},
	"reached destination city warehouse": {
		"text": "到达目的地城市仓库",
		"isAlreadyTranslated": true
	},
	"out for delivery": {
		"text": "交付",
		"isAlreadyTranslated": true
	},
	"Your device has been succesfully collected from @": {
		"text": "您的型号已成功从@收集",
		"isAlreadyTranslated": true
	},
	"Approved Estimation": {
		"text": "批准的估算",
		"isAlreadyTranslated": true
	},
	"Pending": {
		"text": "有待",
		"isAlreadyTranslated": true
	},
	"adding inventory": {
		"text": "添加库存",
		"isAlreadyTranslated": true
	},
	"subtracting inventory": {
		"text": "减去库存",
		"isAlreadyTranslated": true
	},
	"Good News! Your iPhone has been validated succesfully. You are also eligible to participate in the Green Champion Contest to win @.": {
		"text": "好消息！您的iPhone已成功验证。您也有资格参加绿色冠军比赛以赢得@。",
		"isAlreadyTranslated": true
	},
	"Good News! Your iPhone has been validated succesfully.": {
		"text": "好消息！您的iPhone已成功验证。",
		"isAlreadyTranslated": true
	},
	"Device handed over by CustomerCare to QCEngineer": {
		"text": "型号由CustomerCare移交给QCEngineer",
		"isAlreadyTranslated": true
	},
	"Device handed over by CustomerCare to StoreManager": {
		"text": "型号由CustomerCare移交给StoreManager",
		"isAlreadyTranslated": true
	},
	"Part request has been rejected by store": {
		"text": "零件请求已被库管拒绝",
		"isAlreadyTranslated": true
	},
	"Part request has been accepted by store": {
		"text": "零件请求已被库管接受",
		"isAlreadyTranslated": true
	},
	"Requested part(s) has been issued by store": {
		"text": "已请求的部分已由库管发出",
		"isAlreadyTranslated": true
	},
	"Engineer has received part(s) issued by store": {
		"text": "工程师已收到库管发布的部分",
		"isAlreadyTranslated": true
	},
	"@ is your OTP for Servify. This OTP is valid for 120 seconds. Do not share this OTP with anyone for security reasons. We look forward to serving you.": {
		"text": "@是您的Servify OTP。该OTP有效期为120秒。出于安全原因，请勿与任何人共享此OTP。我们期待您的光临。",
		"isAlreadyTranslated": true
	},
	"1 part request is yet to be accepted by store.": {
		"text": "库管尚未接受1件请求。",
		"isAlreadyTranslated": true
	},
	"@ part requests are yet to be accepted by store.": {
		"text": "@ part请求尚未被库管接受。",
		"isAlreadyTranslated": true
	},
	"Download Servify - Personal assistant to all your electronics and home appliances.\n\nhttp://servify.in/app": {
		"text": "下载Servify - 所有电子产品和家用电器的个人助理。\n \nhttp：//servify.in/app",
		"isAlreadyTranslated": true
	},
	"Our expert (@) has initiated the repair work.": {
		"text": "我们的专家（@）已启动维修工作。",
		"isAlreadyTranslated": true
	},
	"Device handed over by ServicelocationEngineer to StoreManager": {
		"text": "型号由ServicelocationEngineer移交给StoreManager",
		"isAlreadyTranslated": true
	},
	"Device accepted from @ by @": {
		"text": "型号从@ by @接受",
		"isAlreadyTranslated": true
	},
	"Your Device has been collected by Servify Executive on @ and will be delivered to Authorized Service Centre.": {
		"text": "您的型号已由Servify Executive在@上收集，并将交付给授权服务中心。",
		"isAlreadyTranslated": true
	},
	"Repair Cancel. Device will be delivered soon": {
		"text": "修复取消。型号即将交付",
		"isAlreadyTranslated": true
	},
	"Logistics Agent @ @ has picked up your device and it will be delivered to Authorized Service Centre.": {
		"text": "后勤代理@ @已经拿起您的型号，它将被送到授权服务中心。",
		"isAlreadyTranslated": true
	},
	"We Pick up\nYour Device": {
		"text": "上门取件",
		"isAlreadyTranslated": true
	},
	"Device handed over by @ to @": {
		"text": "型号由@移交给@",
		"isAlreadyTranslated": true
	},
	"Device handed over by @ from @": {
		"text": "型号由@从@移交",
		"isAlreadyTranslated": true
	},
	"Your request for @(@/@) has been registered.": {
		"text": "您对@（@ / @）的请求已经注册。",
		"isAlreadyTranslated": true
	},
	"Pickup Drop Repair": {
		"text": "皮卡丢修",
		"isAlreadyTranslated": true
	},
	"Carry in Repair": {
		"text": "进行修理",
		"isAlreadyTranslated": true
	},
	"Home Repair": {
		"text": "上门维修",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for pickup drop (@/@/@)": {
		"text": "取消请求已被接受（@ / @ / @）",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for carry in repair (@/@/@)": {
		"text": "您的取消申请已被接受进行维修（@ / @ / @）",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for home repair (@/@/@)": {
		"text": "您的取消申请已被接受上门维修（@ / @ / @）",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for installation (@/@/@)": {
		"text": "您的取消请求已被接受安装（@ / @ / @）",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for demo (@/@/@)": {
		"text": "您的取消请求已被接受演示（@ / @ / @）",
		"isAlreadyTranslated": true
	},
	"With Express Check In you can skip your waiting time at Service Center. You will be attended to within 7 minutes of reaching Service Center": {
		"text": "通过快速入住，您可以跳过服务中心的等待时间。您将在7分钟内到达服务中心",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for service (@/@/@)": {
		"text": "您的取消请求已被接受服务（@ / @ / @）",
		"isAlreadyTranslated": true
	},
	"You have cancelled your request for @ scheduled on @. We hope to serve you soon.": {
		"text": "您已经取消了@in @@的预定请求。我们希望尽快为您服务。",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for Pickup Drop Repair (@/@/@)": {
		"text": "Pickup Drop Repair（@ / @ / @）已接受您重新安排的重新安排请求",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for pickup drop (@ / @ / @)": {
		"text": "取消请求已被接受（@ / @ / @）",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for Carry in Repair (@/@/@)": {
		"text": "您的重新安排请求已被接受进行维修（@ / @ / @）",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for eRecycling (@/@/@)": {
		"text": "您的重新安排申请已被eRecycling接受（@ / @ / @）",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for carry in repair (@ / @ / @)": {
		"text": "您的取消申请已被接受进行维修（@ / @ / @）",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for Home Repair (@/@/@)": {
		"text": "您的住宿维修已接受您的重新安排请求（@ / @ / @）",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for Installation (@/@/@)": {
		"text": "您的重新安排请求已被接受安装（@ / @ / @）",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for demo (@/@/@)": {
		"text": "您的重新安排请求已被接受演示（@ / @ / @）",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for Servicing (@/@/@)": {
		"text": "您的重新安排请求已被接受服务（@ / @ / @）",
		"isAlreadyTranslated": true
	},
	"Your request for Pickup Drop Repair (@/@) has been registered.": {
		"text": "您的皮卡维修服务请求（@ / @）已经注册。",
		"isAlreadyTranslated": true
	},
	"Your request for Carry in Repair (@/@) has been registered.": {
		"text": "您的进行维修（@ / @）的请求已经注册。",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for @": {
		"text": "您已接受@重新安排的重新安排请求",
		"isAlreadyTranslated": true
	},
	"Your request for Home Repair (@/@) has been registered.": {
		"text": "您的家居维修（@ / @）请求已经注册。",
		"isAlreadyTranslated": true
	},
	"You have successfully pledged to eRecycle your iPhone in @! We will remind you to submit your iPhone for eRecycling a few days prior to your chosen month.": {
		"text": "您已成功承诺在@中eRecycle您的iPhone！我们会提醒您在您选择的月份前几天提交您的iPhone以进行电子转回。",
		"isAlreadyTranslated": true
	},
	"Your request number is @. Our Logistics Agent will collect the device from you as per the scheduled time. To know more, please check Track Request section within the App. T&C apply.": {
		"text": "您的请求号是@。我们的物流代理将按照预定时间从您那里收集型号。要了解更多信息，请查看应用程序中的'跟踪请求'部分。条款与条件适用。",
		"isAlreadyTranslated": true
	},
	"Your dropoff is scheduled on @ at @. Ref ID: @": {
		"text": "您的下车时间安排在@ at @。工单号：@",
		"isAlreadyTranslated": true
	},
	"Your eRecycle request (@ / @) has been cancelled as per your request. Ref ID: @": {
		"text": "您的eRecycle请求（@ / @）已根据您的请求被取消。工单号：@",
		"isAlreadyTranslated": true
	},
	"Your home repair request (@ / @) has been registered. You will receive confirmation 30 mins prior to the requested time slot.": {
		"text": "您的上门维修申请（@ / @）已经注册。您将在要求的时间段前30分钟收到确认。",
		"isAlreadyTranslated": true
	},
	"Servify has received your @. It will be disassembled & sent to the eRecycling Hub after verification. Ref ID: @": {
		"text": "Servify已收到您的@。验证后，它将被拆解并发送到eRecycling Hub。工单号：@",
		"isAlreadyTranslated": true
	},
	"Your @ has been eRecycled. Please rate your eRecycling experience at https://xcxa.app.link/rate?csr=@ . Ref ID: @": {
		"text": "你的@已被eRecycled。请通过https://xcxa.app.link/rate?csr=@为您的电子转回体验评分。工单号：@",
		"isAlreadyTranslated": true
	},
	"Good News! Your @ has been validated successfully. You are also eligible to participate in the Green Champion Contest. Click here @ to know how to participate.": {
		"text": "好消息！您的@已成功验证。您也有资格参加绿色冠军比赛。点击这里@了解如何参与。",
		"isAlreadyTranslated": true
	},
	"Your eRecycle request has been rejected. To know more call 18001214984. Ref ID: @": {
		"text": "您的eRecycle请求已被拒绝。要了解更多，请致电18001214984.工单号：@",
		"isAlreadyTranslated": true
	},
	"Your @ has been dropped off at @. Ref ID: @": {
		"text": "你的@已经被@掉了。工单号：@",
		"isAlreadyTranslated": true
	},
	"Your disassembled @ and it's key components have reached the eRecycling Hub and will be eRecycled soon. Ref ID: @": {
		"text": "您的反汇编@及其关键组件已经到达eRecycling Hub并将很快进行eRecycled。工单号：@",
		"isAlreadyTranslated": true
	},
	"We have disassembled key components of your device and will be sending it to the eRecycling Hub soon. Ref ID: @": {
		"text": "我们已经拆解了您型号的关键组件，并很快将其发​​送到eRecycling Hub。工单号：@",
		"isAlreadyTranslated": true
	},
	"Sweet! @ has accepted your request to fix your @": {
		"text": "甜！ @已接受您的修复@的请求",
		"isAlreadyTranslated": true
	},
	"New job from Servify": {
		"text": "Servify的新工作",
		"isAlreadyTranslated": true
	},
	"Your @ has been successfully activated for your device @": {
		"text": "您的@已成功为您的型号激活@",
		"isAlreadyTranslated": true
	},
	"@ has been successfully Purchased and Activated for your device ": {
		"text": "@已成功为您的型号购买并激活",
		"isAlreadyTranslated": true
	},
	"@ has been successfully Purchased for your device": {
		"text": "@已成功购买您的型号",
		"isAlreadyTranslated": true
	},
	"Good News! Your @ has been validated successfully.": {
		"text": "好消息！您的@已成功验证。",
		"isAlreadyTranslated": true
	},
	"Your carry in repair request @ for @ at (@, @) has been registered. Please check track repair section to get the direction to the location.": {
		"text": "你的随身携带维修请求@ for @ at（@，@）已经注册。请检查轨道维修部分以获取到该位置的方向。",
		"isAlreadyTranslated": true
	},
	"@ is your OTP for Servify. This OTP is valid for 120 seconds. Do not share this OTP with anyone for security reasons.": {
		"text": "@是您的Servify OTP。该OTP有效期为120秒。出于安全原因，请勿与任何人共享此OTP。",
		"isAlreadyTranslated": true
	},
	"Your home repair request (@ / @) has been registered. You will receive confirmation 30mins prior to the requested time slot.": {
		"text": "您的上门维修申请（@ / @）已经注册。您将在要求的时间段前30分钟收到确认。",
		"isAlreadyTranslated": true
	},
	"Download the Servify app from http://bit.ly/27WoDAv and get a better service experience. \n Servify - for all your service needs.": {
		"text": "从http://bit.ly/27WoDAv下载Servify应用程序，获得更好的服务体验。 \n Servify - 满足您的所有服务需求。",
		"isAlreadyTranslated": true
	},
	"Your request number is @. Our Partner (@, @)  will collect the device from you as per the scheduled time. To know more, please check Track Request section within the App. T&C apply.": {
		"text": "您的请求号是@。我们的合作伙伴（@，@）将按照预定时间从您那里收集型号。要了解更多信息，请查看应用程序中的'跟踪请求'部分。条款与条件适用。",
		"isAlreadyTranslated": true
	},
	"@ | We have received your claim request. Our representative will connect with you in next 48 hours.": {
		"text": "@ |我们已收到您的索赔申请。我们的代表将在48小时内与您联系。",
		"isAlreadyTranslated": true
	},
	"Your device will be picked up on @. Your Secret Code is @. Please handover the device to the Logistics Agent only once they provide this secret code to prove their identity.": {
		"text": "您的型号将在@上被接收。你的密码是@。只有在提供此密码以证明其身份后，才能将型号移交给后勤代理。",
		"isAlreadyTranslated": true
	},
	"Your Activation Code to register for the @ Plan is @. Download the @ app from @ and get a better service experience.": {
		"text": "注册@计划的激活码是@。从@下载@ app并获得更好的服务体验。",
		"isAlreadyTranslated": true
	},
	"Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips. Download now. @": {
		"text": "获取iPhone，iPad，手表和Mac的授权服务，触手可及。现在下载。 @",
		"isAlreadyTranslated": true
	},
	"Pick up of your @ (Ref No. - @) has been rescheduled to @ as requested.": {
		"text": "取回你的@（Ref No. - @）已根据要求重新安排到@。",
		"isAlreadyTranslated": true
	},
	"Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips.\niOS App - @\nWeb Portal - @": {
		"text": "获取iPhone，iPad，手表和Mac的授权服务，触手可及。\niOS App - @ \nWeb Portal - @",
		"isAlreadyTranslated": true
	},
	"Download Lenovo Care: Personal assistant to all your Lenovo & Motorola smartphones https://bnc.lt/lenovo-care": {
		"text": "下载Lenovo Care：所有Lenovo和Motorola智能手机的个人助理https://bnc.lt/lenovo-care",
		"isAlreadyTranslated": true
	},
	"Device Picked up: Your @ (Ref ID - @) was collected by Servify Executive on @ and will be delivered to an Authorized Service Centre. To know more, please check Track Repair section within the App. T&C Apply.": {
		"text": "提取的型号：@（Ref ID - @）由Servify Executive在@上收集，并将提供给授权服务中心。要了解更多信息，请查看应用程序中的'跟踪修复'部分。 T＆C申请。",
		"isAlreadyTranslated": true
	},
	"@ is your OTP for Micromax Care. This OTP is valid for 120 seconds. Do not share this OTP with any one for security reasons. We look forward to serving you.": {
		"text": "@是Micromax Care的OTP。该OTP有效期为120秒。出于安全原因，请勿与任何人共享此OTP。我们期待您的光临。",
		"isAlreadyTranslated": true
	},
	"Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips. Download now. https://bit.ly/authorized-service": {
		"text": "获取iPhone，iPad，手表和Mac的授权服务，触手可及。现在下载。 https://bit.ly/authorized-service",
		"isAlreadyTranslated": true
	},
	"@ | We have received your claim request. Our representative will connect with you in next one business day.": {
		"text": "@ |我们已收到您的索赔申请。我们的代表将在下一个工作日与您联系。",
		"isAlreadyTranslated": true
	},
	"OnePlus Care App: Manage, Diagnose and Service your OnePlus Mobile Phone, download - https://jf1t.app.link/l1cm5EozpA": {
		"text": "OnePlus Care App：管理，诊断和维护OnePlus手机，下载 - https://jf1t.app.link/l1cm5EozpA",
		"isAlreadyTranslated": true
	},
	"Your Activation Code to register for the @ Plan is @. Download the Micromax Care app from http://bit.ly/MMXCare and get a better service experience.": {
		"text": "注册@计划的激活码是@。从http://bit.ly/MMXCare下载Micromax Care应用程序，获得更好的服务体验。",
		"isAlreadyTranslated": true
	},
	"Pickup Rescheduled: Logistics Agent will attempt pickup of your @ on @ as requested.": {
		"text": "重新安排取件：后勤代理将根据要求尝试取消@ on @。",
		"isAlreadyTranslated": true
	},
	"New Request Ref. ID @ Name: @ Phone: @ Landmark: @": {
		"text": "新请求工单号 @姓名：@电话：@ Landmark：@",
		"isAlreadyTranslated": true
	},
	"Greetings from Micromax. Congratulations! Your Dual 5 is now protected under Micromax Protect. Welcome to Hassle free Service experience.": {
		"text": "来自Micromax的问候。恭喜！您的Dual 5现在受Micromax Protect保护。欢迎来到Hassle免费服务体验。",
		"isAlreadyTranslated": true
	},
	"Greetings from Micromax . Welcome to Micromax Care, your personal device assistant for all Micromax devices.": {
		"text": "来自Micromax的问候。欢迎使用Micromax Care，这是所有Micromax型号的个人型号助手。",
		"isAlreadyTranslated": true
	},
	"Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips.\niOS App - https://bit.ly/authorized-service\nWeb Portal - https://servify.in/iphone": {
		"text": "获取iPhone，iPad，手表和Mac的授权服务，触手可及。\niOS App - https://bit.ly/authorized-service\nWeb Portal - https://servify.in/iphone",
		"isAlreadyTranslated": true
	},
	"Your dropoff is scheduled on @ at @. Ref ID: @ ": {
		"text": "您的下车时间安排在@ at @。工单号：@",
		"isAlreadyTranslated": true
	},
	"Thank you for choosing Micromax Care, please rate our service on the App. We look forward to serving you again.": {
		"text": "感谢您选择Micromax Care，请在应用程序上评价我们的服务。我们期待再次为您服务。",
		"isAlreadyTranslated": true
	},
	"Download \"eRecycle\" on your iPhone to raise and track eRecycle requests on the go. @": {
		"text": "在iPhone上下载'eRecycle'，随时随地提升和跟踪eRecycle请求。 @",
		"isAlreadyTranslated": true
	},
	"Device Picked up: Your @ (Ref ID - @) was collected by Logistics Agent on @ and will be delivered to Micromax Authorized Service Centre. To know more, please check Track Repair section within the App. T&C Apply.": {
		"text": "提取的型号：您的@（Ref ID - @）由物流代理在@上收集，并将交付给Micromax授权服务中心。要了解更多信息，请查看应用程序中的'跟踪修复'部分。 T＆C申请。",
		"isAlreadyTranslated": true
	},
	"Dear Customer,\nWe have received a claim request for your device. Please click on the following link to cross check the information shared by you and submit to raise the claim. Kindly note, once submitted, the details cannot be changed.\n@": {
		"text": "尊敬的客户，\n我们已收到您型号的索赔申请。请单击以下链接以交叉检查您共享的信息并提交以提出索赔。请注意，一旦提交，详细信息将无法更改。\n @",
		"isAlreadyTranslated": true
	},
	"Your device pickup is scheduled on @ with WAYBILL NUMBER @. Please hand over the Packed Device with Printed Documents, emailed by Servify to your Registered Email ID. PLEASE NOTE: Do NOT hand over the invoice copy of your phone to our Logistics Agent.": {
		"text": "您的型号取件预定在@WAYBILL NUMBER @上。请将带有印刷文件的打包型号移交给Servify，通过电子邮件发送到您的注册电子邮件ID。请注意：请勿将手机的发票副本交给我们的后勤代理。",
		"isAlreadyTranslated": true
	},
	"Amount Paid": {
		"text": "支付的金额",
		"isAlreadyTranslated": true
	},
	"Demo request (@/@) has been resheduled by the consumer": {
		"text": "演示者请求（@ / @）已被消费者重新调整",
		"isAlreadyTranslated": true
	},
	"WarrantyConcession": {
		"text": "WarrantyConcession",
		"isAlreadyTranslated": true
	},
	"Your @ has been repaired. Please collect it from (@/@)": {
		"text": "你的@已经修好了。请从（@ / @）收集",
		"isAlreadyTranslated": true
	},
	"Repair request (@/@) has been resheduled by the consumer": {
		"text": "维修请求（@ / @）已被消费者重新调整",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for @ (@/@/@)": {
		"text": "@（@ / @ / @）已接受您的重新安排申请",
		"isAlreadyTranslated": true
	},
	"Your @ has been repaired & delivered.": {
		"text": "你的@已经修好并交付了。",
		"isAlreadyTranslated": true
	},
	"Installation request (@/@) has been canceled by the consumer": {
		"text": "消费者已取消安装请求（@ / @）",
		"isAlreadyTranslated": true
	},
	"Your service request is Rescheduled by @": {
		"text": "您的服务请求由@重新安排",
		"isAlreadyTranslated": true
	},
	"Installation request (@/@) has been resheduled by the consumer": {
		"text": "安装请求（@ / @）已被消费者重新调整",
		"isAlreadyTranslated": true
	},
	"Demo request (@/@) has been canceled by the consumer": {
		"text": "消费者已取消演示请求（@ / @）",
		"isAlreadyTranslated": true
	},
	"Service request (@/@) has been resheduled by the consumer": {
		"text": "服务请求（@ / @）已被消费者重新调整",
		"isAlreadyTranslated": true
	},
	"Your request (@/@) has been suspended": {
		"text": "您的请求（@ / @）已被暂停",
		"isAlreadyTranslated": true
	},
	"@ is on the way to take care of your @.": {
		"text": "@正在照顾你的@。",
		"isAlreadyTranslated": true
	},
	"Payment received for repair request (@)": {
		"text": "收到维修要求的付款（@）",
		"isAlreadyTranslated": true
	},
	"Awaiting Documents. Please Upload by @.": {
		"text": "等待文件。请上传@。",
		"isAlreadyTranslated": true
	},
	"Claim Intimation Number : @.": {
		"text": "索赔数量：@。",
		"isAlreadyTranslated": true
	},
	"Verification successful. Kindly book a repair by @.": {
		"text": "验证成功。请通过@预订维修。",
		"isAlreadyTranslated": true
	},
	"Device has been received by the service centre and is currently being diagnosed.": {
		"text": "型号已被服务中心接收并且当前正在被诊断。",
		"isAlreadyTranslated": true
	},
	"Your payment has been received.": {
		"text": "您的付款已收到。",
		"isAlreadyTranslated": true
	},
	"Your device is dispatched.": {
		"text": "您的型号已分派。",
		"isAlreadyTranslated": true
	},
	"Work in progress": {
		"text": "工作正在进行中",
		"isAlreadyTranslated": true
	},
	"We are working on your request and transfer of the eligible amount will be initiated soon.": {
		"text": "我们正在处理您的请求，并且很快将启动合格金额的转移。",
		"isAlreadyTranslated": true
	},
	"Your device is repaired. Please visit the service centre to collect.": {
		"text": "您的型号已修复。请访问服务中心进行收集。",
		"isAlreadyTranslated": true
	},
	"Repair complete.": {
		"text": "修复完成。",
		"isAlreadyTranslated": true
	},
	"Your request has lapsed due to non submission of the documents.": {
		"text": "由于未提交文件，您的请求已失效。",
		"isAlreadyTranslated": true
	},
	"Your claim has been declined. Please continue with self-paid repair.": {
		"text": "您的申请被拒绝了。请继续自费修理。",
		"isAlreadyTranslated": true
	},
	"Your request has lapsed since no repair has been booked.": {
		"text": "由于未预订维修，因此您的请求已失效。",
		"isAlreadyTranslated": true
	},
	"Your request has lapsed as we have not received payment for the repair": {
		"text": "您的请求已失效，因为我们尚未收到维修付款",
		"isAlreadyTranslated": true
	},
	"Your request has been cancelled": {
		"text": "您的请求已被取消",
		"isAlreadyTranslated": true
	},
	"Your device is ready to be collected by you. We hope you are satisfied with the service experience. Reference ID:@": {
		"text": "您的型号已准备就绪，可由您收集。我们希望您对服务体验感到满意。参考编号：@",
		"isAlreadyTranslated": true
	},
	"External storage issue": {
		"text": "外部存储问题",
		"isAlreadyTranslated": true
	},
	"Noise issue": {
		"text": "前置摄像头问题",
		"isAlreadyTranslated": true
	},
	"Not powering ON": {
		"text": "没电",
		"isAlreadyTranslated": true
	},
	"Not powering off": {
		"text": "没电",
		"isAlreadyTranslated": true
	},
	"Intermittently powering off": {
		"text": "间歇性断电",
		"isAlreadyTranslated": true
	},
	"No display": {
		"text": "没有显示",
		"isAlreadyTranslated": true
	},
	"Flickering": {
		"text": "闪烁",
		"isAlreadyTranslated": true
	},
	"Improper display": {
		"text": "显示不当",
		"isAlreadyTranslated": true
	},
	"Blur display": {
		"text": "模糊显示",
		"isAlreadyTranslated": true
	},
	"Spot on display": {
		"text": "现货显示",
		"isAlreadyTranslated": true
	},
	"No sound": {
		"text": "没有声音",
		"isAlreadyTranslated": true
	},
	"Cracking sound": {
		"text": "破解声音",
		"isAlreadyTranslated": true
	},
	"Less sound": {
		"text": "声音较少",
		"isAlreadyTranslated": true
	},
	"Network Issue": {
		"text": "网络问题",
		"isAlreadyTranslated": true
	},
	"Display damaged": {
		"text": "显示损坏",
		"isAlreadyTranslated": true
	},
	"Body damaged": {
		"text": "身体受损",
		"isAlreadyTranslated": true
	},
	"Vibrator not working": {
		"text": "振动器不工作",
		"isAlreadyTranslated": true
	},
	"Internal data storage issue": {
		"text": "内部储存问题",
		"isAlreadyTranslated": true
	},
	"Charger not working": {
		"text": "充电器不工作",
		"isAlreadyTranslated": true
	},
	"Data cable not working": {
		"text": "数据线无法正常工作",
		"isAlreadyTranslated": true
	},
	"Slow processing or hangs": {
		"text": "处理缓慢或挂起",
		"isAlreadyTranslated": true
	},
	"Back camera issue": {
		"text": "后置摄像头问题",
		"isAlreadyTranslated": true
	},
	"Full Diagnosis": {
		"text": "完全诊断",
		"isAlreadyTranslated": true
	},
	"Proper Assembly": {
		"text": "正确安装",
		"isAlreadyTranslated": true
	},
	"Power Button": {
		"text": "电源按钮",
		"isAlreadyTranslated": true
	},
	"Unlock Button": {
		"text": "解锁按钮",
		"isAlreadyTranslated": true
	},
	"Back Button": {
		"text": "返回键",
		"isAlreadyTranslated": true
	},
	"Home Button": {
		"text": "主页按钮",
		"isAlreadyTranslated": true
	},
	"TAB Button": {
		"text": "TAB按键",
		"isAlreadyTranslated": true
	},
	"Volume Up Button": {
		"text": "音量增大按钮",
		"isAlreadyTranslated": true
	},
	"Volume Down Button": {
		"text": "音量减小按钮",
		"isAlreadyTranslated": true
	},
	"LED Flash": {
		"text": "LED闪光灯",
		"isAlreadyTranslated": true
	},
	"Dead Spots": {
		"text": "坏点",
		"isAlreadyTranslated": true
	},
	"Dead Spot": {
		"text": "坏点",
		"isAlreadyTranslated": true
	},
	"LCD Screen": {
		"text": "液晶屏",
		"isAlreadyTranslated": true
	},
	"Battery Storage": {
		"text": "电池容量",
		"isAlreadyTranslated": true
	},
	"Charging Port": {
		"text": "充电端口",
		"isAlreadyTranslated": true
	},
	"Ear Piece": {
		"text": "耳机",
		"isAlreadyTranslated": true
	},
	"USB Connectivity": {
		"text": "USB连接",
		"isAlreadyTranslated": true
	},
	"Loud Speaker": {
		"text": "扬声器",
		"isAlreadyTranslated": true
	},
	"Sensors": {
		"text": "传感器",
		"isAlreadyTranslated": true
	},
	"Microphone": {
		"text": "麦克风",
		"isAlreadyTranslated": true
	},
	"Front Camera": {
		"text": "前置摄像头",
		"isAlreadyTranslated": true
	},
	"Back Camera": {
		"text": "后置摄像头",
		"isAlreadyTranslated": true
	},
	"Bluetooth": {
		"text": "蓝牙",
		"isAlreadyTranslated": true
	},
	"Wi-Fi": {
		"text": "无线上网",
		"isAlreadyTranslated": true
	},
	"Network Connection": {
		"text": "网络连接",
		"isAlreadyTranslated": true
	},
	"Incoming call": {
		"text": "来电",
		"isAlreadyTranslated": true
	},
	"Outgoing call": {
		"text": "呼出",
		"isAlreadyTranslated": true
	},
	"Vibrator": {
		"text": "振子",
		"isAlreadyTranslated": true
	},
	"IMEI Check": {
		"text": "IMEI检查",
		"isAlreadyTranslated": true
	},
	"Software Version": {
		"text": "软件版本",
		"isAlreadyTranslated": true
	},
	"Check If Device Box IMEI Matches With Device IMEI": {
		"text": "检查型号盒IMEI是否与型号IMEI匹配",
		"isAlreadyTranslated": true
	},
	"Check If Device IMEI Matches With Internal IMEI": {
		"text": "检查型号IMEI是否与内部IMEI匹配",
		"isAlreadyTranslated": true
	},
	"Check If Touch/Display Is Broken": {
		"text": "检查触摸/显示是否损坏",
		"isAlreadyTranslated": true
	},
	"Check If SIM Tray Is Missing Or Broken": {
		"text": "检查SIM卡托是否缺失或损坏",
		"isAlreadyTranslated": true
	},
	"Check If Back Cover Is Damaged": {
		"text": "检查后盖是否损坏",
		"isAlreadyTranslated": true
	},
	"Check If Screw Heads Are Damaged": {
		"text": "检查螺钉头是否损坏",
		"isAlreadyTranslated": true
	},
	"Check If There Are Dents Or Internal Cracks In Display": {
		"text": "检查显示屏中是否有凹痕或内部裂纹",
		"isAlreadyTranslated": true
	},
	"Check If Volume Keys Are Loose Or Not Functioning": {
		"text": "检查音量键是否松动或不起作用",
		"isAlreadyTranslated": true
	},
	"Check If Power Keys Are Loose Or Not Functioning": {
		"text": "检查电源键是否松动或不起作用",
		"isAlreadyTranslated": true
	},
	"Check If Camera's Auto Focus Is Not Working": {
		"text": "检查相机的自动对焦是否不起作用",
		"isAlreadyTranslated": true
	},
	"Check If Photos Clicked Are Blurred": {
		"text": "检查拍摄的照片是否模糊",
		"isAlreadyTranslated": true
	},
	"Check If USB Port Is Damaged": {
		"text": "检查USB端口是否损坏",
		"isAlreadyTranslated": true
	},
	"Check If Accessories Are As Mentioned On The Sales Pack": {
		"text": "检查销售包装上是否提及附件",
		"isAlreadyTranslated": true
	},
	"Check If There Are Functional Defects": {
		"text": "检查是否存在功能缺陷",
		"isAlreadyTranslated": true
	},
	"Box IMEI Vs Unit IMEI": {
		"text": "包装IMEI和设备IMEI匹配",
		"isAlreadyTranslated": true
	},
	"Unit IMEI Vs Internal IMEI": {
		"text": "设备IMEI和系统IMEI匹配",
		"isAlreadyTranslated": true
	},
	"Touch/LCD Broken": {
		"text": "触摸/ LCD坏了",
		"isAlreadyTranslated": true
	},
	"SIM Tray Missing or Broken": {
		"text": "SIM托盘丢失或损坏",
		"isAlreadyTranslated": true
	},
	"Back Cover Damaged": {
		"text": "后盖损坏",
		"isAlreadyTranslated": true
	},
	"Screw heads Damaged": {
		"text": "螺丝头损坏",
		"isAlreadyTranslated": true
	},
	"Any Dents or Internal Crack in display": {
		"text": "屏幕有划痕或内部破裂",
		"isAlreadyTranslated": true
	},
	"Power/Volume Keys Loose or Not Functioning": {
		"text": "电源/音量键松动或不起作用",
		"isAlreadyTranslated": true
	},
	"Camera AF & Blurr Issues": {
		"text": "相机AF和Blurr问题",
		"isAlreadyTranslated": true
	},
	"USB Port Damaged": {
		"text": "USB插口损坏",
		"isAlreadyTranslated": true
	},
	"Accessories as per Content printed on sales pack": {
		"text": "销售包装内自带的配件齐全",
		"isAlreadyTranslated": true
	},
	"Functional Defects": {
		"text": "功能缺陷",
		"isAlreadyTranslated": true
	},
	"@ | Your claim will be processed within the next 6 business hours": {
		"text": "@ |您的索赔将在接下来的6个工作小时内处理",
		"isAlreadyTranslated": true
	},
	"@ | Your request has been cancelled.": {
		"text": "@ |您的请求已被取消。",
		"isAlreadyTranslated": true
	},
	"@ | Your request has lapsed as we have not received payment for the repair.": {
		"text": "@ |您的请求已失效，因为我们尚未收到维修付款。",
		"isAlreadyTranslated": true
	},
	"@ | Document verification was declined. Please visit @ to know the details": {
		"text": "@ |文件验证被拒绝。请访问@了解详情",
		"isAlreadyTranslated": true
	},
	"@ | Your claim has been declined. Please continue with self-paid repair.": {
		"text": "@ |您的申请被拒绝了。请继续自费修理。",
		"isAlreadyTranslated": true
	},
	"@ | Your request has lapsed as you have not booked a repair request.": {
		"text": "@ |由于您尚未预订维修请求，因此您的请求已失效。",
		"isAlreadyTranslated": true
	},
	"@Check here first@ Go to @Settings > General > About@ and look for your device's serial number, IMEI/MEID, and ICCID. Tap and hold on the number and copy it.\nIf you still haven't found the number that you need, see below for other ways to locate it.": {
		"text": "@首先检查@转到@Settings>常规>关于@并查找型号的序列号，IMEI / MEID和ICCID。点击并按住该号码并复制它。\n如果您还没有找到所需的号码，请参阅下面的其他查找方法。",
		"isAlreadyTranslated": true
	},
	"@Or check the case of your iPhone@ On these devices, you'll find the serial number in the Settings menu and the IMEI/MEID on the SIM tray. If you need support but you can't get to the Settings menu, you can use the IMEI/MEID instead of the serial number: • iPhone 7 • iPhone 7 Plus • iPhone 6s • iPhone 6s Plus": {
		"text": "@或者检查iPhone的情况@在这些型号上，您可以在'设置'菜单中找到序列号，在SIM托盘上找到IMEI / MEID。如果您需要支持但无法进入'设置'菜单，则可以使用IMEI / MEID而不是序列号：•iPhone 7•iPhone 7 Plus•iPhone 6s•iPhone 6s Plus",
		"isAlreadyTranslated": true
	},
	"On these devices, you'll find the serial number in the Settings menu and the IMEI/MEID (the MEID is the first 14 digits of the IMEI) on the back. If you need support but you can't get to the Settings menu, you can use the IMEI/MEID instead of the serial number: • iPhone 6 • iPhone 6 Plus • iPhone SE • iPhone 5s • iPhone 5c • iPhone 5": {
		"text": "在这些型号上，您可以在设置菜单中找到序列号，并在背面找到IMEI / MEID（MEID是IMEI的前14位数字）。如果您需要支持但无法进入'设置'菜单，则可以使用IMEI / MEID而不是序列号：•iPhone 6•iPhone 6 Plus•iPhone SE•iPhone 5s•iPhone 5c•iPhone 5",
		"isAlreadyTranslated": true
	},
	"On these devices, you can find the serial number and IMEI/MEID on the SIM tray: • iPhone 3G • iPhone 3GS • iPhone 4 (GSM model) • iPhone 4s": {
		"text": "在这些型号上，您可以在SIM托盘上找到序列号和IMEI / MEID：•iPhone 3G•iPhone 3GS•iPhone 4（GSM型号）•iPhone 4s",
		"isAlreadyTranslated": true
	},
	"Go to @Settings > General > About @ and look for your device's serial number, IMEI/MEID, and ICCID. Tap and hold on the number and copy it.": {
		"text": "转到@Settings>常规>关于@并查找型号的序列号，IMEI / MEID和ICCID。点按并按住该号码并将其复制。",
		"isAlreadyTranslated": true
	},
	"You can find the serial number and IMEI/MEID on the @SIM tray.@": {
		"text": "您可以在@SIM托盘上找到序列号和IMEI / MEID。@",
		"isAlreadyTranslated": true
	},
	"Go to @Settings > General > About@ and look for your device's serial number, IMEI/MEID, and ICCID. Tap and hold on the number and copy it.": {
		"text": "转到@Settings>常规>关于@并查找型号的序列号，IMEI / MEID和ICCID。点按并按住该号码并将其复制。",
		"isAlreadyTranslated": true
	},
	"You can find the IMEI/MEID on the @back of your phone.@": {
		"text": "您可以在手机的@back上找到IMEI / MEID。@",
		"isAlreadyTranslated": true
	},
	"Choose @About This Mac@ from the Apple menu @()@ in the upper-left corner of your screen. @About This Mac@ shows an overview of your Mac, including the name and version of its operating system, its model name, and its serial number:": {
		"text": "从屏幕左上角的Apple菜单@（）@中选择@About This Mac @。 @About此Mac @显示Mac的概述，包括其操作系统的名称和版本，型号名称及其序列号：",
		"isAlreadyTranslated": true
	},
	"If you see an About This Mac window like the following, double-click the version number beneath 'OS X' to reveal the serial number:": {
		"text": "如果您看到如下所示的'关于本机'窗口，请双击'OS X'下方的版本号以显示序列号：",
		"isAlreadyTranslated": true
	},
	"@On the surface of your MacBook@ Close your MacBook and turn it over. The serial number is on the underside of the computer, near the regulatory markings.": {
		"text": "@在MacBook的表面上@关闭MacBook并翻转它。序列号位于计算机底部，靠近监管标记。",
		"isAlreadyTranslated": true
	},
	"@On the surface of your iMac@ The serial number of your iMac is printed on its surface. To find it, shut down your iMac and unplug any connected cables so you can safely move it. Then gently lay the computer face-down on a soft, clean towel or cloth.\nThe serial number is on the underside of the base (or foot) of the iMac, along with a bar code and regulatory markings.": {
		"text": "@在iMac @的表面上iMac的序列号打印在其表面上。要找到它，请关闭iMac并拔下所有连接的电缆，以便安全地移动它。然后将电脑正面朝下轻轻地放在柔软干净的毛巾或布上。\n序列号位于iMac底座（或底脚）的底部，以及条形码和法规标记。",
		"isAlreadyTranslated": true
	},
	"@Check the Settings menu@ On your Apple Watch, open the Settings app, tap General > About, then scroll down.\nOn your iPhone, open the Apple Watch App, go to the My Watch tab, then tap @General > About@.On your iPhone, open the Apple Watch App, go to the My Watch tab, then tap General > About.": {
		"text": "@查看设置菜单@在Apple Watch上，打开设置应用，点击常规>关于，然后向下滚动。\n在iPhone上，打开Apple Watch应用程序，转到我的手表选项卡，然后点击@General>关于@ 。在iPhone上，打开Apple Watch应用程序，转到'我的监视'选项卡，然后点击'常规'>'关于'。",
		"isAlreadyTranslated": true
	},
	"@Or check the case of your Apple Watch@On Apple Watch (1st generation), check the back of your watch for the engraved serial number.": {
		"text": "@或者检查Apple Watch @ On Apple Watch（第1代）的外壳，检查手表背面是否有雕刻的序列号。",
		"isAlreadyTranslated": true
	},
	"On Apple Watch Series 1 and Apple Watch Series 2, including Apple Watch Hermès and Apple Watch Nike+, remove the band from your watch, then check the band slot.": {
		"text": "在Apple Watch Series 1和Apple Watch Series 2上，包括AppleWatchHermès和Apple Watch Nike +，从表中取下表带，然后检查表带插槽。",
		"isAlreadyTranslated": true
	},
	"Find the serial number for your iPad Pro, iPad, or iPod touch on the back of the device. On an iPad (cellular model), you'll also find the IMEI. The MEID is the first 14 digits of the IMEI.": {
		"text": "在型号背面找到iPad Pro，iPad或iPod touch的序列号。在iPad（手机型号）上，您还可以找到IMEI。 MEID是IMEI的前14位数字。",
		"isAlreadyTranslated": true
	},
	"Find the serial number for your iPod touch on the back of the device": {
		"text": "在型号背面找到iPod touch的序列号",
		"isAlreadyTranslated": true
	},
	"@Check the About screen for the serial number@ To see the serial number for your Apple TV, choose Settings > General > About. You can also see the serial number for your Siri Remote or Apple TV Remote* by choosing Settings > Remotes and Devices > Remote.  ": {
		"text": "@查看序列号的关于屏幕@要查看Apple TV的序列号，请选择设置>常规>关于。您还可以通过选择设置>遥控器和型号>遥控器来查看Siri遥控器或Apple TV遥控器*的序列号。",
		"isAlreadyTranslated": true
	},
	"@Check the bottom of your Apple TV for the reflective serial number @ Since the serial number on the bottom of your device is reflective, use a light to read the number.": {
		"text": "@检查Apple TV底部的反光序列号@由于型号底部的序列号是反射的，请使用指示灯读取数字。",
		"isAlreadyTranslated": true
	},
	"@Check the box for the serial number@ If you have your device's original box, you can see the serial number on the barcode.": {
		"text": "@查看序列号的方框@如果您有型号的原始包装盒，则可以在条形码上看到序列号。",
		"isAlreadyTranslated": true
	},
	"Oh no! Tell us what went wrong": {
		"text": "不好了！告诉我们出了什么问题",
		"isAlreadyTranslated": true
	},
	"The ease of using the app": {
		"text": "易于使用的应用程序",
		"isAlreadyTranslated": true
	},
	"Speed & Efficiency": {
		"text": "速度与效率",
		"isAlreadyTranslated": true
	},
	"Overall Service": {
		"text": "整体服务",
		"isAlreadyTranslated": true
	},
	"Oops! Please tell us what can be improved": {
		"text": "哎呀！请告诉我们可以改进的地方",
		"isAlreadyTranslated": true
	},
	"Please tell us what can be improved": {
		"text": "请告诉我们可以改进的地方",
		"isAlreadyTranslated": true
	},
	"What did you like about us?": {
		"text": "你对我们有什么看法？",
		"isAlreadyTranslated": true
	},
	"Great! What did you like about us?": {
		"text": "大！你对我们有什么看法？",
		"isAlreadyTranslated": true
	},
	"Excellent": {
		"text": "优秀",
		"isAlreadyTranslated": true
	},
	"Good": {
		"text": "好",
		"isAlreadyTranslated": true
	},
	"Bad": {
		"text": "坏",
		"isAlreadyTranslated": true
	},
	"Terrible": {
		"text": "可怕",
		"isAlreadyTranslated": true
	},
	"Pickup & Delivery Service": {
		"text": "提货和送货服务",
		"isAlreadyTranslated": true
	},
	"Repair quality": {
		"text": "维修质量",
		"isAlreadyTranslated": true
	},
	"Transparency": {
		"text": "透明度",
		"isAlreadyTranslated": true
	},
	"Service Partner hospitality": {
		"text": "服务合作伙伴款待",
		"isAlreadyTranslated": true
	},
	"Technician": {
		"text": "技术员",
		"isAlreadyTranslated": true
	},
	"Convenience of discarding device": {
		"text": "丢弃型号的便利性",
		"isAlreadyTranslated": true
	},
	"Install Specialist": {
		"text": "安装专家",
		"isAlreadyTranslated": true
	},
	"Demo Specialist": {
		"text": "演示专家",
		"isAlreadyTranslated": true
	},
	"Maintenance quality": {
		"text": "维修质量",
		"isAlreadyTranslated": true
	},
	"Time Flexibility": {
		"text": "时间灵活性",
		"isAlreadyTranslated": true
	},
	"Convenience of submitting an iPhone": {
		"text": "提交iPhone的便利性",
		"isAlreadyTranslated": true
	},
	"Came Late": {
		"text": "迟到了",
		"isAlreadyTranslated": true
	},
	"Inexperienced": {
		"text": "没经验",
		"isAlreadyTranslated": true
	},
	"Unprofessional": {
		"text": "不专业",
		"isAlreadyTranslated": true
	},
	"Expensive": {
		"text": "昂贵",
		"isAlreadyTranslated": true
	},
	"Bad Service": {
		"text": "糟糕的服务",
		"isAlreadyTranslated": true
	},
	"Very Far": {
		"text": "非常远",
		"isAlreadyTranslated": true
	},
	"I confirm that I have removed the SIM Card(s), have taken necessary data backup and removed any security passcode/pattern from this phone. I further declare that Servify will not be responsible for any loss or delay in repair due to misrepresentation of above communication.": {
		"text": "我确认已移除SIM卡，已进行必要的数据备份并从此手机中删除了任何安全密码/模式。我进一步声明，Servify对因上述通信失实陈述而造成的任何损失或延误不承担任何责任。",
		"isAlreadyTranslated": true
	},
	"Remove security passcode/pattern (Mandatory)": {
		"text": "删除安全密码/模式（强制）",
		"isAlreadyTranslated": true
	},
	"Doing so helps our Service Partner quickly start with service of your phone without any hassles.": {
		"text": "这样做有助于我们的服务合作伙伴快速启动您的手机服务，而不会有任何麻烦。",
		"isAlreadyTranslated": true
	},
	"Remove SIM card from your Phone": {
		"text": "从手机中删除SIM卡",
		"isAlreadyTranslated": true
	},
	"This helps prevent any unauthorized use of your SIM Card and data.": {
		"text": "这有助于防止未经授权使用您的SIM卡和数据。",
		"isAlreadyTranslated": true
	},
	"Backup your Phone (recommended)": {
		"text": "备份手机（推荐）",
		"isAlreadyTranslated": true
	},
	"Certain software related repairs might lead to loss of your data. Backing up data ensures you don't lose any valuable data.": {
		"text": "某些与软件相关的维修可能会导致数据丢失。备份数据可确保您不会丢失任何有价值的数据。",
		"isAlreadyTranslated": true
	},
	"I confirm that I have removed the SIM card, have taken necessary data backup and turned off 'Find My iPhone' from this iPhone. I further declare that Servify will not be responsible for any losses or delay in repair due to misrepresentation of above confirmation.": {
		"text": "我确认已移除SIM卡，已进行必要的数据备份并关闭了此iPhone的'查找我的iPhone'。我进一步声明，Servify对由于误报上述确认而导致的任何损失或延误修理概不负责。",
		"isAlreadyTranslated": true
	},
	"Turn Off Find My iPhone": {
		"text": "关闭查找我的iPhone",
		"isAlreadyTranslated": true
	},
	"Start at your Home screen.": {
		"text": "从主屏幕开始。",
		"isAlreadyTranslated": true
	},
	"Tap Settings > iCloud.": {
		"text": "点按设置> iCloud。",
		"isAlreadyTranslated": true
	},
	"Scroll to the bottom and tap Find My iPhone.": {
		"text": "滚动到底部，然后点按'查找我的iPhone'。",
		"isAlreadyTranslated": true
	},
	"Slide to turn off Find My iPhone and Send Last Location.": {
		"text": "滑动以关闭'查找我的iPhone'并发送'上一个位置'。",
		"isAlreadyTranslated": true
	},
	"If you're asked to sign in, enter your Apple ID.": {
		"text": "如果要求您登录，请输入您的Apple ID。",
		"isAlreadyTranslated": true
	},
	"Remove SIM Card From Your Device": {
		"text": "从您的型号中删除SIM卡",
		"isAlreadyTranslated": true
	},
	"After you remove or transfer your SIM card insert the tray completely and in the same orientation that you removed it (it will fit only one way).": {
		"text": "移除或转移SIM卡后，请将托盘完全插入，并将其取下（仅适用于单向）。",
		"isAlreadyTranslated": true
	},
	"Backup Your iOS Device (Recommended)": {
		"text": "备份你的iOS型号（推荐）",
		"isAlreadyTranslated": true
	},
	"Before you get started, learn the difference between making backups with iCloud and iTunes and choose the method that's best for you. In case you ever need an alternative backup, you can make one in iCloud and another in iTunes.": {
		"text": "在开始之前，了解使用iCloud和iTunes进行备份之间的区别，并选择最适合您的方法。如果您需要备用备份，可以在iCloud中制作一个备份，在iTunes中制作另一个备份。",
		"isAlreadyTranslated": true
	},
	"Call": {
		"text": "呼叫",
		"isAlreadyTranslated": true
	},
	"Connect on Facebook": {
		"text": "在Facebook上连接",
		"isAlreadyTranslated": true
	},
	"Connect on Twitter": {
		"text": "在Twitter上连接",
		"isAlreadyTranslated": true
	},
	"Connect on LinkedIn": {
		"text": "在LinkedIn上连接",
		"isAlreadyTranslated": true
	},
	"Terms & Conditions": {
		"text": "条款和条件",
		"isAlreadyTranslated": true
	},
	"Privacy Policy": {
		"text": "隐私政策",
		"isAlreadyTranslated": true
	},
	"Claim Form": {
		"text": "索赔表",
		"isAlreadyTranslated": true
	},
	"Go to @Settings > General > About@ and look for your device's serial number, IMEI/MEID, and ICCID. If you'd like to paste this information into Apple registration or support forms, tap and hold on the number and copy it.": {
		"text": "转到@Settings>常规>关于@并查找型号的序列号，IMEI / MEID和ICCID。如果您要将此信息粘贴到Apple注册表或支持表单中，请点按并按住该号码并将其复制。",
		"isAlreadyTranslated": true
	},
	"Servify is India's largest device management and proactive ownership platform. Starting as an app based customer support service for consumer electronics, today we have created our own end-to-end device management platform. ": {
		"text": "Servify是印度最大的型号管理和主动所有权平台。作为面向消费电子产品的基于应用的客户支持服务，我们今天创建了自己的端到端型号管理平台。",
		"isAlreadyTranslated": true
	},
	"We enable top OEMs & ODMs in device distribution, sales, warranty, after-sales and end of life management through our device management platform. ": {
		"text": "我们通过型号管理平台为型号分销，销售，保修，售后和报废管理提供顶级OEM和ODM。",
		"isAlreadyTranslated": true
	},
	"We have also developed best in class smart device diagnostics for smartphones and have built an end to end service ecosystem including our own service factory and field logistics force ": {
		"text": "我们还为智能手机开发了一流的智能型号诊断，并建立了端到端的服务生态系统，包括我们自己的服务工厂和现场物流部队",
		"isAlreadyTranslated": true
	},
	"Our consumer offerings include one touch service app and a smart ownership platform. It also has Damage Protection and Extended Warranties for smart devices ": {
		"text": "我们的消费者产品包括一键式服务应用和智能所有权平台。它还具有智能型号的损坏保护和延长保修",
		"isAlreadyTranslated": true
	},
	"While some of our technology works behind the scenes to manage your devices, we hope to engage with you directly and make your lives better.": {
		"text": "虽然我们的一些技术可以在幕后管理您的型号，但我们希望能够直接与您互动，让您的生活更美好。",
		"isAlreadyTranslated": true
	},
	"We love devices and we want to help you get the best out of them. Every device has a lifespan and today that life span is getting shorter. Most electronics that are improperly thrown away contain harmful materials like beryllium, cadmium, mercury and lead in some form or another. These materials might be present in traces in individual products, but when added up in volume, the threat to the environment is significant.": {
		"text": "我们喜欢型号，我们希望帮助您充分利用它们。每个型号都有一个生命周期，今天寿命越来越短。大多数被不当丢弃的电子产品都含有铍，镉，汞和铅等有害物质。这些材料可能存在于单个产品的痕迹中，但是当批量添加时，对环境的威胁是显着的。",
		"isAlreadyTranslated": true
	},
	"We want your help to recycle all End of Life (EOL) devices with ease. That is why we are introducing pickup and dropoff options of your EOL device. These devices are recycled by only certified recycling partners.": {
		"text": "我们希望您的帮助可以轻松回收所有End of Life（EOL）型号。这就是我们为您的EOL型号引入拾取和下降选项的原因。这些型号仅由经过认证的回收合作伙伴回收。",
		"isAlreadyTranslated": true
	},
	"Servify is India's largest device management and proactive ownership platform. Starting as an app based customer support service for consumer electronics, today we have created our own end-to-end device management platform.": {
		"text": "Servify是印度最大的型号管理和主动所有权平台。作为面向消费电子产品的基于应用的客户支持服务，我们今天创建了自己的端到端型号管理平台。",
		"isAlreadyTranslated": true
	},
	"We enable top OEMs & ODMs in device distribution, sales, warranty, after-sales and end of life management through our device management platform. We have also developed best in class smart device diagnostics for smartphones and have built an end to end service ecosystem including our own service factory and field logistics force.": {
		"text": "我们通过型号管理平台为型号分销，销售，保修，售后和报废管理提供顶级OEM和ODM。我们还为智能手机开发了一流的智能型号诊断，并建立了端到端服务生态系统，包括我们自己的服务工厂和现场物流部队。",
		"isAlreadyTranslated": true
	},
	"Our consumer offerings include one touch service app and a smart ownership platform. It also has Damage Protection and Extended Warranties for smart devices.": {
		"text": "我们的消费者产品包括一键式服务应用和智能所有权平台。它还具有智能型号的损坏保护和延长保修。",
		"isAlreadyTranslated": true
	},
	"While some of our technology works behind the scenes to manage your devices, we hope to engage with you directly through this recycle initiative. Let's dispose our devices responsibly and make this planet a better place.": {
		"text": "虽然我们的一些技术可以在幕后管理您的型号，但我们希望通过此回收计划直接与您联系。让我们负责任地处理我们的型号，让这个星球变得更美好。",
		"isAlreadyTranslated": true
	},
	"BatteryEvent": {
		"text": "BatteryEvent",
		"isAlreadyTranslated": true
	},
	"Hardware": {
		"text": "硬件",
		"isAlreadyTranslated": true
	},
	"Battery": {
		"text": "电池",
		"isAlreadyTranslated": true
	},
	"Battery State": {
		"text": "电池状态",
		"isAlreadyTranslated": true
	},
	"Capacity": {
		"text": "容量",
		"isAlreadyTranslated": true
	},
	"Health": {
		"text": "健康",
		"isAlreadyTranslated": true
	},
	"Level": {
		"text": "水平",
		"isAlreadyTranslated": true
	},
	"Temperature": {
		"text": "温度",
		"isAlreadyTranslated": true
	},
	"NetworkEvent": {
		"text": "NetworkEvent",
		"isAlreadyTranslated": true
	},
	"Connectivity": {
		"text": "连接",
		"isAlreadyTranslated": true
	},
	"Network Info": {
		"text": "网络信息",
		"isAlreadyTranslated": true
	},
	"Operator Name": {
		"text": "运营商名称",
		"isAlreadyTranslated": true
	},
	"Phone Type": {
		"text": "电话类型",
		"isAlreadyTranslated": true
	},
	"Signal Strength": {
		"text": "信号强度",
		"isAlreadyTranslated": true
	},
	"SimCountry Code": {
		"text": "SimCountry代码",
		"isAlreadyTranslated": true
	},
	"State of Service": {
		"text": "服务状况",
		"isAlreadyTranslated": true
	},
	"SensorEvent": {
		"text": "SensorEvent",
		"isAlreadyTranslated": true
	},
	"The repair cost of your device will be INR @ but you have to pay only INR @. Please make the payment by @ to begin repair.": {
		"text": "您的型号的维修费用为INR @，但您只需支付INR @。请通过@付款以开始修复。",
		"isAlreadyTranslated": true
	},
	"Air Pressure": {
		"text": "空气压力",
		"isAlreadyTranslated": true
	},
	"Call Service": {
		"text": "呼叫服务",
		"isAlreadyTranslated": true
	},
	"Gyroscope": {
		"text": "陀螺仪",
		"isAlreadyTranslated": true
	},
	"Heart Rate": {
		"text": "心率",
		"isAlreadyTranslated": true
	},
	"Humidity": {
		"text": "湿度",
		"isAlreadyTranslated": true
	},
	"Message Service": {
		"text": "消息服务",
		"isAlreadyTranslated": true
	},
	"Magnetic Field": {
		"text": "磁场",
		"isAlreadyTranslated": true
	},
	"IR Sensor": {
		"text": "红外传感器",
		"isAlreadyTranslated": true
	},
	"Gravity": {
		"text": "重力",
		"isAlreadyTranslated": true
	},
	"Accelerometer": {
		"text": "加速度计",
		"isAlreadyTranslated": true
	},
	"Air Temperature": {
		"text": "气温",
		"isAlreadyTranslated": true
	},
	"GPS": {
		"text": "全球定位系统",
		"isAlreadyTranslated": true
	},
	"RAM": {
		"text": "内存",
		"isAlreadyTranslated": true
	},
	"Available": {
		"text": "可得到",
		"isAlreadyTranslated": true
	},
	"GB": {
		"text": "GB",
		"isAlreadyTranslated": true
	},
	"Internal Storage": {
		"text": "内部存储器",
		"isAlreadyTranslated": true
	},
	"CPU": {
		"text": "中央处理器",
		"isAlreadyTranslated": true
	},
	"Minimum Clock": {
		"text": "最小时钟",
		"isAlreadyTranslated": true
	},
	"Processors/Cores": {
		"text": "处理器/内核",
		"isAlreadyTranslated": true
	},
	"Fingerprint": {
		"text": "指纹",
		"isAlreadyTranslated": true
	},
	"Proximity Sensor": {
		"text": "接近传感器",
		"isAlreadyTranslated": true
	},
	"Speaker": {
		"text": "扬声器",
		"isAlreadyTranslated": true
	},
	"Sound": {
		"text": "声音",
		"isAlreadyTranslated": true
	},
	"Ports": {
		"text": "端口",
		"isAlreadyTranslated": true
	},
	"Earphones": {
		"text": "耳机",
		"isAlreadyTranslated": true
	},
	"USB": {
		"text": "USB",
		"isAlreadyTranslated": true
	},
	"Volume Buttons": {
		"text": "音量按钮",
		"isAlreadyTranslated": true
	},
	"Touch Screen": {
		"text": "触摸屏",
		"isAlreadyTranslated": true
	},
	"Dead or White Spots": {
		"text": "死亡或白点",
		"isAlreadyTranslated": true
	},
	"Flashlight": {
		"text": "手电筒",
		"isAlreadyTranslated": true
	},
	"MAC Address": {
		"text": "MAC地址",
		"isAlreadyTranslated": true
	},
	"Max Wifi Speed": {
		"text": "最大Wifi速度",
		"isAlreadyTranslated": true
	},
	"Frequency": {
		"text": "频率",
		"isAlreadyTranslated": true
	},
	"Wi-Fi Statistics": {
		"text": "Wi-Fi统计",
		"isAlreadyTranslated": true
	},
	"Multi Touch": {
		"text": "多点触摸",
		"isAlreadyTranslated": true
	},
	"Brightness": {
		"text": "亮度",
		"isAlreadyTranslated": true
	},
	"Earpiece Speaker": {
		"text": "听筒扬声器",
		"isAlreadyTranslated": true
	},
	"Phone Info": {
		"text": "电话信息",
		"isAlreadyTranslated": true
	},
	"Android Version": {
		"text": "Android版",
		"isAlreadyTranslated": true
	},
	"Air Conditioner": {
		"text": "冷气机",
		"isAlreadyTranslated": true
	},
	"repair": {
		"text": "修理",
		"isAlreadyTranslated": true
	},
	"Repair": {
		"text": "维修",
		"isAlreadyTranslated": true
	},
	"install": {
		"text": "安装",
		"isAlreadyTranslated": true
	},
	"service": {
		"text": "服务",
		"isAlreadyTranslated": true
	},
	"TV": {
		"text": "电视",
		"isAlreadyTranslated": true
	},
	"AC": {
		"text": "AC",
		"isAlreadyTranslated": true
	},
	"Fridge": {
		"text": "冰箱",
		"isAlreadyTranslated": true
	},
	"Washing Machine": {
		"text": "洗衣机",
		"isAlreadyTranslated": true
	},
	"Camera": {
		"text": "相机",
		"isAlreadyTranslated": true
	},
	"Computer": {
		"text": "电脑",
		"isAlreadyTranslated": true
	},
	"Tablet": {
		"text": "片剂",
		"isAlreadyTranslated": true
	},
	"Microwave": {
		"text": "微波",
		"isAlreadyTranslated": true
	},
	"Router": {
		"text": "路由器",
		"isAlreadyTranslated": true
	},
	"Water Purifier": {
		"text": "净水器",
		"isAlreadyTranslated": true
	},
	"Air Purifier": {
		"text": "空气净化器",
		"isAlreadyTranslated": true
	},
	"Audio Product": {
		"text": "音频产品",
		"isAlreadyTranslated": true
	},
	"Vacuum Cleaner": {
		"text": "吸尘器",
		"isAlreadyTranslated": true
	},
	"Juicer Mixer": {
		"text": "榨汁机搅拌机",
		"isAlreadyTranslated": true
	},
	"Printer": {
		"text": "打印机",
		"isAlreadyTranslated": true
	},
	"Chest Coole": {
		"text": "胸部冷却",
		"isAlreadyTranslated": true
	},
	"Streaming Device": {
		"text": "流媒体型号",
		"isAlreadyTranslated": true
	},
	"Chest Freezer": {
		"text": "胸部冷冻机",
		"isAlreadyTranslated": true
	},
	"Security Camera": {
		"text": "监视器",
		"isAlreadyTranslated": true
	},
	"Smart Watch": {
		"text": "智能手表",
		"isAlreadyTranslated": true
	},
	"Office CCTV": {
		"text": "办公室央视",
		"isAlreadyTranslated": true
	},
	"Air Cooler": {
		"text": "冷气机",
		"isAlreadyTranslated": true
	},
	"Room Heater": {
		"text": "室内加热器",
		"isAlreadyTranslated": true
	},
	"Water Heater": {
		"text": "热水器",
		"isAlreadyTranslated": true
	},
	"Dry Iron": {
		"text": "干铁",
		"isAlreadyTranslated": true
	},
	"Kettle": {
		"text": "水壶",
		"isAlreadyTranslated": true
	},
	"Rice Cooker": {
		"text": "电饭锅",
		"isAlreadyTranslated": true
	},
	"Toaster": {
		"text": "烤面包机",
		"isAlreadyTranslated": true
	},
	"Dish TV": {
		"text": "碟电视",
		"isAlreadyTranslated": true
	},
	"Ear Pods": {
		"text": "耳朵荚",
		"isAlreadyTranslated": true
	},
	"Power Adapter": {
		"text": "电源适配器",
		"isAlreadyTranslated": true
	},
	"Lightning to USB Cable": {
		"text": "闪电到USB线",
		"isAlreadyTranslated": true
	},
	"Display Damaged": {
		"text": "显示损坏",
		"isAlreadyTranslated": true
	},
	"Battery Damaged": {
		"text": "电池损坏",
		"isAlreadyTranslated": true
	},
	"Backup Taken": {
		"text": "备份采取",
		"isAlreadyTranslated": true
	},
	"Find my iPhone Active": {
		"text": "找到我的iPhone Active",
		"isAlreadyTranslated": true
	},
	"Apple Limited Warranty": {
		"text": "Apple有限保修",
		"isAlreadyTranslated": true
	},
	"Physical Damage": {
		"text": "物理伤害",
		"isAlreadyTranslated": true
	},
	"Power": {
		"text": "功率",
		"isAlreadyTranslated": true
	},
	"System Performance": {
		"text": "系统性能",
		"isAlreadyTranslated": true
	},
	"Built in Camera": {
		"text": "内置摄像头",
		"isAlreadyTranslated": true
	},
	"Display": {
		"text": "显示",
		"isAlreadyTranslated": true
	},
	"Communication/Networking": {
		"text": "通信/网络",
		"isAlreadyTranslated": true
	},
	"Data Storage": {
		"text": "数据存储",
		"isAlreadyTranslated": true
	},
	"@ | Your payment has been received.": {
		"text": "@ |您的付款已收到。",
		"isAlreadyTranslated": true
	},
	"@ | Your device is repaired. Please visit the service centre to collect.": {
		"text": "@ |您的型号已修复。请访问服务中心进行收集。",
		"isAlreadyTranslated": true
	},
	"@ | Your device is repaired and dispatched.": {
		"text": "@ |您的型号已修复并已发送。",
		"isAlreadyTranslated": true
	},
	"@ | The repair cost of your device will be INR @ but you have to pay only INR @. Payment by @ to begin repair.": {
		"text": "@ |您的型号的维修费用为INR @，但您只需支付INR @。由@支付以开始修复。",
		"isAlreadyTranslated": true
	},
	"@ | Device has been received by the service centre and is currently being diagnosed.": {
		"text": "@ |型号已被服务中心接收并且当前正在被诊断。",
		"isAlreadyTranslated": true
	},
	"@ | Document verification was successful. Please visit @ to book the repair": {
		"text": "@ |文件验证成功。请访问@预订维修",
		"isAlreadyTranslated": true
	},
	"@ | We are awaiting Documents. Please Upload by @ to make sure that your request does not get lapsed. @ ": {
		"text": "@ |我们正在等待文件。请通过@上传，以确保您的请求不会失效。 @",
		"isAlreadyTranslated": true
	},
	"@ | Your Request has been registered. Please check the request for any further documentation required from you.": {
		"text": "@ |您的申请已经注册。请查看您要求的任何进一步文件的请求。",
		"isAlreadyTranslated": true
	},
	"@ Action needs to be taken by Store for @ part request(s).": {
		"text": "对于零件请求，Store需要采取@行动。",
		"isAlreadyTranslated": true
	},
	"@ Part(s) Ordered by Store.": {
		"text": "@库管订购的零件。",
		"isAlreadyTranslated": true
	},
	"@ Part(s) Issued by Store.": {
		"text": "@部分由库管发布。",
		"isAlreadyTranslated": true
	},
	"All parts received, repair is in progress.": {
		"text": "已接收所有备件，维修正在进行中",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive will choose action for the device from Dead on Arrival section below.": {
		"text": "前台需要从DOA栏选择处理方案",
		"isAlreadyTranslated": true
	},
	"Device repair has failed QC. Device is to be given back to the Engineer.": {
		"text": "型号维修QC失败。型号将返还给工程师。",
		"isAlreadyTranslated": true
	},
	"Device Whitelisting Information": {
		"text": "型号白名单信息",
		"isAlreadyTranslated": true
	},
	"Eligible for DOA": {
		"text": "适用于DOA",
		"isAlreadyTranslated": true
	},
	" (Eligible for DOA)": {
		"text": " (这个设备适用于DOA)",
		"isAlreadyTranslated": true
	},
	"Engineer will select the new device from Dead on Arrival section below": {
		"text": "工程师需从DOA栏选择更换新设备",
		"isAlreadyTranslated": true
	},
	"Estimation has been rejected, parts may be added if required by the engineer.": {
		"text": "估计已被拒绝，如果工程师要求，可以添加部件。",
		"isAlreadyTranslated": true
	},
	"Engineer completed the repair. Handed over the device to QC.": {
		"text": "工程师完成了维修，将手机移交给了QC",
		"isAlreadyTranslated": true
	},
	"Engineer completed the repair. Handed over the device to QC": {
		"text": "工程师完成了维修，将手机移交给了QC",
		"isAlreadyTranslated": true
	},
	"Engineer completed the repair.": {
		"text": "工程师完成了维修。",
		"isAlreadyTranslated": true
	},
	"Last Repair Date": {
		"text": "上次修复日期",
		"isAlreadyTranslated": true
	},
	"No, the device is not whitelistied. Please return the device if you have it": {
		"text": "不，型号没有白名单。如果有，请退回型号",
		"isAlreadyTranslated": true
	},
	"No subcategories found": {
		"text": "没有找到子类别",
		"isAlreadyTranslated": true
	},
	"No supported modes found": {
		"text": "找不到支持的模式",
		"isAlreadyTranslated": true
	},
	"No supported mode found": {
		"text": "找不到支持的模式",
		"isAlreadyTranslated": true
	},
	"No issues found": {
		"text": "没有发现问题",
		"isAlreadyTranslated": true
	},
	"Payment for the service has been received from the customer.": {
		"text": "已收到客户付款。",
		"isAlreadyTranslated": true
	},
	"Payment for the service has been received from the customer.Service invoice can be generated.": {
		"text": "已收到客户付款。可生成服务发票",
		"isAlreadyTranslated": true
	},
	"Please complete the service.": {
		"text": "请完成服务。",
		"isAlreadyTranslated": true
	},
	"Product Unique ID": {
		"text": "产品唯一ID",
		"isAlreadyTranslated": true
	},
	"Pickup Drop By": {
		"text": "皮卡掉落",
		"isAlreadyTranslated": true
	},
	"Repair has been cancelled.": {
		"text": "修复已取消。",
		"isAlreadyTranslated": true
	},
	"Request Has Been Raised": {
		"text": "请求已被提升",
		"isAlreadyTranslated": true
	},
	"Requires customer to print documents and safely pack the device.": {
		"text": "要求客户打印文档并安全地打包型号。",
		"isAlreadyTranslated": true
	},
	"Raise new request": {
		"text": "创建新的工单",
		"isAlreadyTranslated": true
	},
	"Service invoice has been generated, final payment can be received.": {
		"text": "已生成服务发票，可以收到最终付款。",
		"isAlreadyTranslated": true
	},
	"Service invoice has been generated. Final payment can be received.": {
		"text": "已生成服务发票. 可以收到最终付款。",
		"isAlreadyTranslated": true
	},
	"Service invoice has been generated.Final payment can be received.": {
		"text": "已生成服务发票.可以收到最终付款。",
		"isAlreadyTranslated": true
	},
	"Service invoice has been generated.Please create logistics request.": {
		"text": "服务发票已生成。请通知物流。",
		"isAlreadyTranslated": true
	},
	"Service invoice has been generated.Please inform the customer.": {
		"text": "服务发票已生成。请通知客户。",
		"isAlreadyTranslated": true
	},
	"Serial Number/IMEI is mandatory for Apple products. Please enter valid Serial Number/IMEI.": {
		"text": "序列号/ IMEI对Apple产品是强制性的。请输入有效的序列号/ IMEI。",
		"isAlreadyTranslated": true
	},
	"Slot Time": {
		"text": "预约时间",
		"isAlreadyTranslated": true
	},
	"Schedule Date": {
		"text": "创建时间",
		"isAlreadyTranslated": true
	},
	"Send for raising a request?": {
		"text": "确认创建工单？",
		"isAlreadyTranslated": true
	},
	"The serial number and the selected product do not match.": {
		"text": "序列号和所选产品不匹配。",
		"isAlreadyTranslated": true
	},
	"There's a problem with this address, please select or add a different adress": {
		"text": "这个地址有问题，请选择或添加不同的地址",
		"isAlreadyTranslated": true
	},
	"There's a problem with this landmark, please select a different landmark": {
		"text": "这个地标有问题，请选择不同的地标",
		"isAlreadyTranslated": true
	},
	"The request was raised successfully": {
		"text": "该请求已成功提出",
		"isAlreadyTranslated": true
	},
	"The entered Srial/IMEI Number is not whitelisted. Please check the number once again.": {
		"text": "输入的Srial / IMEI号码不列入白名单。请再次检查号码。",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to receive device and complete mandatory checklist tasks": {
		"text": "等待客户服务主管接收型号并完成强制检查清单任务",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer to accept the job.": {
		"text": "等待工程师接受这份工作。",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer to accept the job. (Eligible for DOA)": {
		"text": "等待工程师接受工单 (这个设备适用于DOA)",
		"isAlreadyTranslated": true
	},
	"Work in Progress with Engineer.": {
		"text": "与工程师一起工作。",
		"isAlreadyTranslated": true
	},
	"Waiting for the Engineer to raise the request.": {
		"text": "等待工程师提出请求。",
		"isAlreadyTranslated": true
	},
	"Waiting for Estimation Approval. Click on \"Send for approval\" in \"Parts and Estimation\" section below.": {
		"text": "等待估算批准。点击下面'零件和估算'部分中的'发送以供批准'。",
		"isAlreadyTranslated": true
	},
	"Waiting for customer approval of estimation or override estimation approval in \"Parts and Estimation\" section below.": {
		"text": "在下面的'零件和估算'部分中等待客户批准估算或覆盖估算批准。",
		"isAlreadyTranslated": true
	},
	"Waiting for store to issue required parts in \"Parts Requested\" section below. Cannot complete repair with parts pending.": {
		"text": "等待库管在下面的“备件需求”中发料",
		"isAlreadyTranslated": true
	},
	"Waiting for store to issue required device in \"Dead on arrival\" section below.": {
		"text": "等待库管在DOA栏发料",
		"isAlreadyTranslated": true
	},
	"Waiting for the Engineer to receive the \"Replacement device\" from store in \"Dead on Arrival\" section below.": {
		"text": "等待工程师在下面的'抵达时死亡'部分中从库管收到'更换型号'。",
		"isAlreadyTranslated": true
	},
	"Waiting for Engineer to receive parts from store in \"Parts Requested\" section below.": {
		"text": "等待工程师在'请求的零件'部分中从库管接收零件。",
		"isAlreadyTranslated": true
	},
	"Waiting for the Engineer to complete DOA by handing over the damaged device to Store": {
		"text": "等待工程师通过将损坏的型号移交给库管来完成DOA",
		"isAlreadyTranslated": true
	},
	"Waiting for the engineer to handover device to Store": {
		"text": "等待工程师将型号切换到Store",
		"isAlreadyTranslated": true
	},
	"Waiting for the Store to handover device to Customer Care Executive": {
		"text": "等待库管将型号移交给客户服务主管",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to complete the DOA": {
		"text": "等待客户服务主管完成DOA",
		"isAlreadyTranslated": true
	},
	"Waiting for the customer to rate the service": {
		"text": "等待客户评价服务",
		"isAlreadyTranslated": true
	},
	"Warranty Till": {
		"text": "保修截止日期",
		"isAlreadyTranslated": true
	},
	"We were unable to find details from GSX, please select product manually": {
		"text": "我们无法从GSX中找到详细信息，请手动选择产品",
		"isAlreadyTranslated": true
	},
	"Yes, the device is whitelistied. Please upload screenshot of the same": {
		"text": "是的，该型号已白名单。请上传相同的截图",
		"isAlreadyTranslated": true
	},
	"This fault code has already been selected": {
		"text": "此故障代码已被选中",
		"isAlreadyTranslated": true
	},
	"Choose from dropdown list only": {
		"text": "仅从下拉列表中选择",
		"isAlreadyTranslated": true
	},
	"You have already added this issue": {
		"text": "您已添加此问题",
		"isAlreadyTranslated": true
	},
	"Please validate all the issues": {
		"text": "请验证所有问题",
		"isAlreadyTranslated": true
	},
	"Initiate AST 2 Diagnostics": {
		"text": "启动AST 2诊断",
		"isAlreadyTranslated": true
	},
	"Forward": {
		"text": "前锋",
		"isAlreadyTranslated": true
	},
	"Reverse": {
		"text": "相反",
		"isAlreadyTranslated": true
	},
	"Schedule a Logistic Request": {
		"text": "安排后勤请求",
		"isAlreadyTranslated": true
	},
	"Transfer Details": {
		"text": "转账详情",
		"isAlreadyTranslated": true
	},
	"Cash": {
		"text": "现金",
		"isAlreadyTranslated": true
	},
	"Card": {
		"text": "卡",
		"isAlreadyTranslated": true
	},
	"Mode of payment": {
		"text": "付款方式",
		"isAlreadyTranslated": true
	},
	"Please select mode of payment": {
		"text": "请选择付款方式",
		"isAlreadyTranslated": true
	},
	"Device Transaction Details": {
		"text": "型号交易明细",
		"isAlreadyTranslated": true
	},
	"Handing over the damaged device to store": {
		"text": "移交受损型号进行存储",
		"isAlreadyTranslated": true
	},
	"Damaged IMEI": {
		"text": "IMEI损坏",
		"isAlreadyTranslated": true
	},
	"Details of the New Replaced Device": {
		"text": "新替换型号的详细信息",
		"isAlreadyTranslated": true
	},
	"Damaged Serial No": {
		"text": "损坏的序列号",
		"isAlreadyTranslated": true
	},
	"Replaced Serial No": {
		"text": "替换序列号",
		"isAlreadyTranslated": true
	},
	"Damaged Part Imei": {
		"text": "损坏的部分Imei",
		"isAlreadyTranslated": true
	},
	"Replaced Part Imei": {
		"text": "替换了部分Imei",
		"isAlreadyTranslated": true
	},
	"Parts transaction details": {
		"text": "零件交易详情",
		"isAlreadyTranslated": true
	},
	"Handing over the parts to store": {
		"text": "移交零件存储",
		"isAlreadyTranslated": true
	},
	"Enter Call Details": {
		"text": "输入呼叫详细信息",
		"isAlreadyTranslated": true
	},
	"Connect": {
		"text": "连",
		"isAlreadyTranslated": true
	},
	"Name of the caller": {
		"text": "呼叫者的姓名",
		"isAlreadyTranslated": true
	},
	"Connect Disposition": {
		"text": "连接处置",
		"isAlreadyTranslated": true
	},
	"Non-Connect Disposition": {
		"text": "非连接处置",
		"isAlreadyTranslated": true
	},
	"Description of loss/damage": {
		"text": "损失/损坏的描述",
		"isAlreadyTranslated": true
	},
	"Date and Time of loss/damage": {
		"text": "损失/损坏的日期和时间",
		"isAlreadyTranslated": true
	},
	"Checklist": {
		"text": "清单",
		"isAlreadyTranslated": true
	},
	"Please select Repair Type": {
		"text": "请选择修复类型",
		"isAlreadyTranslated": true
	},
	"Please select Classification Type": {
		"text": "请选择分类类型",
		"isAlreadyTranslated": true
	},
	"Please select Coverage Option": {
		"text": "请选择覆盖范围选项",
		"isAlreadyTranslated": true
	},
	"Repair Checklist": {
		"text": "维修清单",
		"isAlreadyTranslated": true
	},
	"Diagnostics Result": {
		"text": "诊断结果",
		"isAlreadyTranslated": true
	},
	"Not Applicable": {
		"text": "不适用",
		"isAlreadyTranslated": true
	},
	"Miscellaneous Checklist": {
		"text": "杂项清单",
		"isAlreadyTranslated": true
	},
	"Accessories Submitted": {
		"text": "配件已提交",
		"isAlreadyTranslated": true
	},
	"Complete Checklist": {
		"text": "完整清单",
		"isAlreadyTranslated": true
	},
	"No pictures added": {
		"text": "没有添加图片",
		"isAlreadyTranslated": true
	},
	"Upload Device Pictures and Complete Checklist": {
		"text": "上传型号图片和完整清单",
		"isAlreadyTranslated": true
	},
	"Upload Device Pictures": {
		"text": "上传型号图片",
		"isAlreadyTranslated": true
	},
	"Device Name": {
		"text": "型号名称",
		"isAlreadyTranslated": true
	},
	"Select OS Version": {
		"text": "选择OS版本",
		"isAlreadyTranslated": true
	},
	"Select Picture Type": {
		"text": "选择图片类型",
		"isAlreadyTranslated": true
	},
	"No, the device is not whitelisted. Please cancel the request and return the device if you have it": {
		"text": "不，该型号未列入白名单。如果您有请求，请取消请求并退回型号",
		"isAlreadyTranslated": true
	},
	"Yes, the device is whitelisted. Please upload screenshot of the same": {
		"text": "是的，该型号已列入白名单。请上传相同的截图",
		"isAlreadyTranslated": true
	},
	"Device handover details": {
		"text": "型号切换详情",
		"isAlreadyTranslated": true
	},
	"Diagnosis details": {
		"text": "诊断细节",
		"isAlreadyTranslated": true
	},
	"Diagnosis Steps": {
		"text": "诊断步骤",
		"isAlreadyTranslated": true
	},
	"Engineer notes": {
		"text": "工程师笔记",
		"isAlreadyTranslated": true
	},
	"Front Image": {
		"text": "正面图片",
		"isAlreadyTranslated": true
	},
	"Back Image": {
		"text": "背面图片",
		"isAlreadyTranslated": true
	},
	"Top Image": {
		"text": "顶部图片",
		"isAlreadyTranslated": true
	},
	"Bottom Image": {
		"text": "底部图像",
		"isAlreadyTranslated": true
	},
	"Left Image": {
		"text": "左侧图片",
		"isAlreadyTranslated": true
	},
	"Right Image": {
		"text": "右侧图片",
		"isAlreadyTranslated": true
	},
	"Customer ID": {
		"text": "客户ID",
		"isAlreadyTranslated": true
	},
	"Device Invoice": {
		"text": "型号发票",
		"isAlreadyTranslated": true
	},
	"Please select a repair type": {
		"text": "请选择修理类型",
		"isAlreadyTranslated": true
	},
	"Please enter": {
		"text": "请输入",
		"isAlreadyTranslated": true
	},
	"Your GSX Request has been created": {
		"text": "您的GSX请求已创建",
		"isAlreadyTranslated": true
	},
	"Recycle Dropoff": {
		"text": "回收下降",
		"isAlreadyTranslated": true
	},
	"ELS": {
		"text": "ELS",
		"isAlreadyTranslated": true
	},
	"QC": {
		"text": "QC",
		"isAlreadyTranslated": true
	},
	"DOA": {
		"text": "DOA",
		"isAlreadyTranslated": true
	},
	"Inspection Completed, Awaiting Engineer Assignment": {
		"text": "检查完成，等待工程师分配",
		"isAlreadyTranslated": true
	},
	"sdv": {
		"text": "SDV",
		"isAlreadyTranslated": true
	},
	"BTB socket": {
		"text": "BTB插座",
		"isAlreadyTranslated": true
	},
	"SIM Card needle": {
		"text": "SIM卡针",
		"isAlreadyTranslated": true
	},
	"SIM card holder EB101 grey aluminum alloy": {
		"text": "SIM卡座EB101灰色铝合金",
		"isAlreadyTranslated": true
	},
	"Pending for Acceptance by Engineer": {
		"text": "待工程师接受",
		"isAlreadyTranslated": true
	},
	"microphone": {
		"text": "麦克风",
		"isAlreadyTranslated": true
	},
	"for Token generated": {
		"text": "为令牌生成",
		"isAlreadyTranslated": true
	},
	"Advance collected": {
		"text": "提前收集",
		"isAlreadyTranslated": true
	},
	"pin added": {
		"text": "针添加",
		"isAlreadyTranslated": true
	},
	"Trade In Dropoff": {
		"text": "交易减少",
		"isAlreadyTranslated": true
	},
	"Your GSX Mail-in Request has been created. Request Added to Queue for dispatch": {
		"text": "您的GSX Mail-in请求已创建。请求已添加到队列以进行调度",
		"isAlreadyTranslated": true
	},
	"This device is not eligible for": {
		"text": "此型号不符合条件",
		"isAlreadyTranslated": true
	},
	"This device is eligible for": {
		"text": "此型号有资格获得",
		"isAlreadyTranslated": true
	},
	"Advance payment": {
		"text": "预付款",
		"isAlreadyTranslated": true
	},
	"Cannot be diagnosed": {
		"text": "无法确诊",
		"isAlreadyTranslated": true
	},
	"Device received": {
		"text": "收到型号",
		"isAlreadyTranslated": true
	},
	"Pending for acceptance": {
		"text": "待接受",
		"isAlreadyTranslated": true
	},
	"Estimation generated": {
		"text": "估算产生",
		"isAlreadyTranslated": true
	},
	"Estimation Approval": {
		"text": "估计批准",
		"isAlreadyTranslated": true
	},
	"Estimation rejected": {
		"text": "估计被拒绝",
		"isAlreadyTranslated": true
	},
	"Parts request accepted": {
		"text": "零件要求已被接受",
		"isAlreadyTranslated": true
	},
	"Parts issued": {
		"text": "发布的零件",
		"isAlreadyTranslated": true
	},
	"QC pending": {
		"text": "QC待定",
		"isAlreadyTranslated": true
	},
	"Repair completed": {
		"text": "修复完成",
		"isAlreadyTranslated": true
	},
	"Intimate customer": {
		"text": "贴心的客户",
		"isAlreadyTranslated": true
	},
	"Repair cancel": {
		"text": "修复取消",
		"isAlreadyTranslated": true
	},
	"Transfer request": {
		"text": "转移要求",
		"isAlreadyTranslated": true
	},
	"Device details validated": {
		"text": "已验证型号详细信息",
		"isAlreadyTranslated": true
	},
	"Fetch Diagnosis Suites": {
		"text": "获取诊断套件",
		"isAlreadyTranslated": true
	},
	"Do you want to return the damaged parts to the customer": {
		"text": "您想将损坏的部件退还给客户吗？",
		"isAlreadyTranslated": true
	},
	"Please enter the reason for call": {
		"text": "请输入通话原因",
		"isAlreadyTranslated": true
	},
	"Please enter Disposition reason": {
		"text": "请输入处理原因",
		"isAlreadyTranslated": true
	},
	"Please enter your name": {
		"text": "请输入你的名字",
		"isAlreadyTranslated": true
	},
	"Please specify remarks, if any": {
		"text": "请指明备注（如果有）",
		"isAlreadyTranslated": true
	},
	"Return without repair (RWR)": {
		"text": "返回无需维修（RWR）",
		"isAlreadyTranslated": true
	},
	"Cannot proceed with this action without providing a reason": {
		"text": "如果没有提供理由，则无法继续执行此操作",
		"isAlreadyTranslated": true
	},
	"This is a mandatory field": {
		"text": "这是一个必填字段",
		"isAlreadyTranslated": true
	},
	"Diagnosis steps has to be from 100 to 1500 characters": {
		"text": "诊断步骤必须为100到1500个字符",
		"isAlreadyTranslated": true
	},
	"Engineer Notes has to be from 100 to 800 characters": {
		"text": "工程师注释必须是100到800个字符",
		"isAlreadyTranslated": true
	},
	"Please select a picture type from dropdown": {
		"text": "请从下拉列表中选择图片类型",
		"isAlreadyTranslated": true
	},
	"Please add a picture": {
		"text": "请添加图片",
		"isAlreadyTranslated": true
	},
	"You have not chosen any option to handover": {
		"text": "您还没有选择任何切换选项",
		"isAlreadyTranslated": true
	},
	"Jan": {
		"text": "一月",
		"isAlreadyTranslated": true
	},
	"Feb": {
		"text": "二月",
		"isAlreadyTranslated": true
	},
	"Mar": {
		"text": "三月",
		"isAlreadyTranslated": true
	},
	"Apr": {
		"text": "四月",
		"isAlreadyTranslated": true
	},
	"May": {
		"text": "五月",
		"isAlreadyTranslated": true
	},
	"Jun": {
		"text": "六月",
		"isAlreadyTranslated": true
	},
	"Jul": {
		"text": "七月",
		"isAlreadyTranslated": true
	},
	"Aug": {
		"text": "八月",
		"isAlreadyTranslated": true
	},
	"Sep": {
		"text": "九月",
		"isAlreadyTranslated": true
	},
	"Oct": {
		"text": "十月",
		"isAlreadyTranslated": true
	},
	"Nov": {
		"text": "十一月",
		"isAlreadyTranslated": true
	},
	"Dec": {
		"text": "十二月",
		"isAlreadyTranslated": true
	},
	"AM": {
		"text": "上午",
		"isAlreadyTranslated": true
	},
	"PM": {
		"text": "下午",
		"isAlreadyTranslated": true
	},
	"Pick Up": {
		"text": "寄修",
		"isAlreadyTranslated": true
	},
	"Carry in": {
		"text": "送修",
		"isAlreadyTranslated": true
	},
	"On-site": {
		"text": "现场",
		"isAlreadyTranslated": true
	},
	"Buy Back": {
		"text": "回购",
		"isAlreadyTranslated": true
	},
	"Installation": {
		"text": "安装",
		"isAlreadyTranslated": true
	},
	"Demo": {
		"text": "演示",
		"isAlreadyTranslated": true
	},
	"Servicing": {
		"text": "维修",
		"isAlreadyTranslated": true
	},
	"Delivery": {
		"text": "交货",
		"isAlreadyTranslated": true
	},
	"Recycle Pickup": {
		"text": "回收皮卡",
		"isAlreadyTranslated": true
	},
	"Claim": {
		"text": "要求",
		"isAlreadyTranslated": true
	},
	"Claim Pickup": {
		"text": "索赔提货",
		"isAlreadyTranslated": true
	},
	"Claim Carryin": {
		"text": "声称Carryin",
		"isAlreadyTranslated": true
	},
	"Beyond Economic Repair": {
		"text": "超越经济修复",
		"isAlreadyTranslated": true
	},
	"BER Refund": {
		"text": "BER退款",
		"isAlreadyTranslated": true
	},
	"BER Repair": {
		"text": "BER修复",
		"isAlreadyTranslated": true
	},
	"Dropoff": {
		"text": "放下",
		"isAlreadyTranslated": true
	},
	"Enquiry": {
		"text": "查询",
		"isAlreadyTranslated": true
	},
	"Field Repair WO": {
		"text": "现场维修WO",
		"isAlreadyTranslated": true
	},
	"Upgrade WO": {
		"text": "升级WO",
		"isAlreadyTranslated": true
	},
	"Install WO": {
		"text": "安装WO",
		"isAlreadyTranslated": true
	},
	"Relocation": {
		"text": "搬迁",
		"isAlreadyTranslated": true
	},
	"Extended Pick Up": {
		"text": "延长提货",
		"isAlreadyTranslated": true
	},
	"Extended Carry in": {
		"text": "延长进入",
		"isAlreadyTranslated": true
	},
	"Trade In Pickup": {
		"text": "皮卡贸易",
		"isAlreadyTranslated": true
	},
	"Your @ request @ is registered. We will contact you for an appointment. For assistance, please call us on 1800-108-1333.": {
		"text": "您的@ request @已注册。我们会与您联系预约。如需帮助，请致电1800-108-1333。",
		"isAlreadyTranslated": true
	},
	"@ | Your request has lapsed due to non submission of the documents.": {
		"text": "@ |由于未提交文件，您的请求已失效。",
		"isAlreadyTranslated": true
	},
	"We have received your claim request. Our representative will connect with you in next 48 hours.": {
		"text": "我们已收到您的索赔申请。我们的代表将在48小时内与您联系。",
		"isAlreadyTranslated": true
	},
	"@ Part(s) Received by Engineer.": {
		"text": "@部分由工程师收到。",
		"isAlreadyTranslated": true
	},
	"Print": {
		"text": "打印",
		"isAlreadyTranslated": true
	},
	"Pupa Service Record": {
		"text": "蛹服务记录",
		"isAlreadyTranslated": true
	},
	"Service Center": {
		"text": "服务中心",
		"isAlreadyTranslated": true
	},
	"No": {
		"text": "否",
		"isAlreadyTranslated": true
	},
	"User Name": {
		"text": "用户名",
		"isAlreadyTranslated": true
	},
	"Telephone": {
		"text": "电话",
		"isAlreadyTranslated": true
	},
	"Product Model": {
		"text": "产品型号",
		"isAlreadyTranslated": true
	},
	"Purchase Date": {
		"text": "购买日期",
		"isAlreadyTranslated": true
	},
	"Carry-in Time": {
		"text": "携带时间",
		"isAlreadyTranslated": true
	},
	"NA": {
		"text": "不适用",
		"isAlreadyTranslated": true
	},
	"Key Account Name": {
		"text": "大客户名称",
		"isAlreadyTranslated": true
	},
	"Service Offering": {
		"text": "服务提供",
		"isAlreadyTranslated": true
	},
	"In warranty": {
		"text": "在保修期内",
		"isAlreadyTranslated": true
	},
	"Out of warranty": {
		"text": "超出保修期",
		"isAlreadyTranslated": true
	},
	"Problem Description by User": {
		"text": "用户问题描述",
		"isAlreadyTranslated": true
	},
	"Thank you for choosing us": {
		"text": "感谢您选择我们",
		"isAlreadyTranslated": true
	},
	"ADVANCE RECEIPT": {
		"text": "预收",
		"isAlreadyTranslated": true
	},
	"Service Record": {
		"text": "服务记录",
		"isAlreadyTranslated": true
	},
	"Receipt No": {
		"text": "没有收据",
		"isAlreadyTranslated": true
	},
	"Paid By": {
		"text": "由...支付",
		"isAlreadyTranslated": true
	},
	"Paid To": {
		"text": "支付给",
		"isAlreadyTranslated": true
	},
	"Recover Method": {
		"text": "恢复方法",
		"isAlreadyTranslated": true
	},
	"Parts replacement": {
		"text": "零件更换",
		"isAlreadyTranslated": true
	},
	"Service": {
		"text": "服务",
		"isAlreadyTranslated": true
	},
	"New IMEI/SN": {
		"text": "新的IMEI / SN",
		"isAlreadyTranslated": true
	},
	"Defective Model Name": {
		"text": "模型名称不良",
		"isAlreadyTranslated": true
	},
	"Model Number": {
		"text": "型号",
		"isAlreadyTranslated": true
	},
	"Replacement Name": {
		"text": "替换名称",
		"isAlreadyTranslated": true
	},
	"Engineer Signature": {
		"text": "工程师签名",
		"isAlreadyTranslated": true
	},
	"Finish Date": {
		"text": "完成日期",
		"isAlreadyTranslated": true
	},
	"Are you satisfied with Pupa service experience": {
		"text": "您对Pupa的服务体验满意吗？",
		"isAlreadyTranslated": true
	},
	"Very Satisfied": {
		"text": "很满意",
		"isAlreadyTranslated": true
	},
	"Satisfied": {
		"text": "满意",
		"isAlreadyTranslated": true
	},
	"Neutral": {
		"text": "中性",
		"isAlreadyTranslated": true
	},
	"Dissatisfied": {
		"text": "不满意",
		"isAlreadyTranslated": true
	},
	"Very Dissatisfied": {
		"text": "非常不满",
		"isAlreadyTranslated": true
	},
	"Please comment if you are dissatisfied": {
		"text": "如果您不满意请评论",
		"isAlreadyTranslated": true
	},
	"User Signature": {
		"text": "用户签名",
		"isAlreadyTranslated": true
	},
	"Thank you for choosing Pupa": {
		"text": "感谢您选择Pupa",
		"isAlreadyTranslated": true
	},
	"Operations team will reach out to you for further action.": {
		"text": "运营团队将与您联系以采取进一步行动。",
		"isAlreadyTranslated": true
	},
	"Protection Status": {
		"text": "保护状况",
		"isAlreadyTranslated": true
	},
	"Warranty Status": {
		"text": "质保状态",
		"isAlreadyTranslated": true
	},
	"valid till": {
		"text": "有效期至",
		"isAlreadyTranslated": true
	},
	"Manufacturer Warranty": {
		"text": "制造商保修",
		"isAlreadyTranslated": true
	},
	"Secure Plan Warranty": {
		"text": "安全计划保修",
		"isAlreadyTranslated": true
	},
	"covered under": {
		"text": "涵盖在",
		"isAlreadyTranslated": true
	},
	"Send for raising request": {
		"text": "发送提出请求",
		"isAlreadyTranslated": true
	},
	"Enter Issue Decsription": {
		"text": "输入Issue Decsription",
		"isAlreadyTranslated": true
	},
	"Home": {
		"text": "家",
		"isAlreadyTranslated": true
	},
	"office ": {
		"text": "办公室",
		"isAlreadyTranslated": true
	},
	"other": {
		"text": "其他",
		"isAlreadyTranslated": true
	},
	"Enter a location": {
		"text": "输入位置",
		"isAlreadyTranslated": true
	},
	"Accept": {
		"text": "接受",
		"isAlreadyTranslated": true
	},
	"Reject": {
		"text": "拒绝",
		"isAlreadyTranslated": true
	},
	"Attempt Call": {
		"text": "尝试联系客户",
		"isAlreadyTranslated": true
	},
	"Warranty Concession": {
		"text": "保修优惠",
		"isAlreadyTranslated": true
	},
	"No order lines found for given request": {
		"text": "找不到给定请求的订单行",
		"isAlreadyTranslated": true
	},
	"Valid": {
		"text": "有效",
		"isAlreadyTranslated": true
	},
	"Invalid": {
		"text": "无效",
		"isAlreadyTranslated": true
	},
	"Toggle Warranty": {
		"text": "切换保修",
		"isAlreadyTranslated": true
	},
	"Please specify the reason": {
		"text": "请说明原因",
		"isAlreadyTranslated": true
	},
	"Provide link for customer": {
		"text": "为客户提供链接",
		"isAlreadyTranslated": true
	},
	"Accept or Reject Request": {
		"text": "接受或拒绝请求",
		"isAlreadyTranslated": true
	},
	"Inward": {
		"text": "向内的",
		"isAlreadyTranslated": true
	},
	"Outward": {
		"text": "向外",
		"isAlreadyTranslated": true
	},
	"Max number of retry attempts exceeded": {
		"text": "超出了最大重试次数",
		"isAlreadyTranslated": true
	},
	"Where was the device kept at the time of incident": {
		"text": "事件发生时型号保存在哪里",
		"isAlreadyTranslated": true
	},
	"Please Enter Incident Place of Damage": {
		"text": "请输入事件伤害地点",
		"isAlreadyTranslated": true
	},
	"Min 100 Characters": {
		"text": "最少100个字符",
		"isAlreadyTranslated": true
	},
	"LEFT": {
		"text": "剩下",
		"isAlreadyTranslated": true
	},
	"Please Enter Minimum 100 Characters": {
		"text": "请输入最少100个字符",
		"isAlreadyTranslated": true
	},
	"Raise a request": {
		"text": "创建工单",
		"isAlreadyTranslated": true
	},
	"RAISE A REQUEST": {
		"text": "创建工单",
		"isAlreadyTranslated": true
	},
	"Request Scheduled for": {
		"text": "创建日期",
		"isAlreadyTranslated": true
	},
	"Action Status": {
		"text": "行动状态",
		"isAlreadyTranslated": true
	},
	"Action taken": {
		"text": "所采取的行动",
		"isAlreadyTranslated": true
	},
	"Current Log": {
		"text": "当前状态",
		"isAlreadyTranslated": true
	},
	"Handover Device": {
		"text": "移交设备",
		"isAlreadyTranslated": true
	},
	"Request Documents": {
		"text": "索取文件",
		"isAlreadyTranslated": true
	},
	"Issues Reported": {
		"text": "反馈的故障",
		"isAlreadyTranslated": true
	},
	"Parts and Estimation": {
		"text": "备件和报价",
		"isAlreadyTranslated": true
	},
	"Engineer validated issues": {
		"text": "工程师验证了问题",
		"isAlreadyTranslated": true
	},
	"Other Requests": {
		"text": "其他要求",
		"isAlreadyTranslated": true
	},
	"Connect Disposition:": {
		"text": "连接处置：",
		"isAlreadyTranslated": true
	},
	"Non-Connect Disposition:": {
		"text": "非连接处置：",
		"isAlreadyTranslated": true
	},
	"Cosmetic Status of Device": {
		"text": "型号的化妆品状态",
		"isAlreadyTranslated": true
	},
	"Latest diagnostics result": {
		"text": "最新诊断结果",
		"isAlreadyTranslated": true
	},
	"Purchase Order Number": {
		"text": "订购单号码",
		"isAlreadyTranslated": true
	},
	"Service Ticket No": {
		"text": "服务票号",
		"isAlreadyTranslated": true
	},
	"National ID": {
		"text": "国民身份证",
		"isAlreadyTranslated": true
	},
	"Select a Consumer Product": {
		"text": "选择消费者产品",
		"isAlreadyTranslated": true
	},
	"products under service": {
		"text": "正在服务的产品",
		"isAlreadyTranslated": true
	},
	"No, the device is not whitelisted. Please return the device if you have it": {
		"text": "不，该型号未列入白名单。如果有，请退回型号",
		"isAlreadyTranslated": true
	},
	"Your request was raised successfully": {
		"text": "您的要求已成功募集",
		"isAlreadyTranslated": true
	},
	"Characters must be numeric.": {
		"text": "字符必须是数字。",
		"isAlreadyTranslated": true
	},
	"Select issues": {
		"text": "选择问题",
		"isAlreadyTranslated": true
	},
	"Selected Product": {
		"text": "选择产品",
		"isAlreadyTranslated": true
	},
	"IMEI number": {
		"text": "IMEI号码",
		"isAlreadyTranslated": true
	},
	"Search consumer products": {
		"text": "搜索消费产品",
		"isAlreadyTranslated": true
	},
	"Next Steps": {
		"text": "下一步",
		"isAlreadyTranslated": true
	},
	"Please specify remarks, if any...": {
		"text": "请注明言论，如果有的话...",
		"isAlreadyTranslated": true
	},
	"Override Approval": {
		"text": "越权批准",
		"isAlreadyTranslated": true
	},
	"(Override only if customer instructs to)*": {
		"text": "（仅在顾客同意的前提下）*",
		"isAlreadyTranslated": true
	},
	"Reason for Approval:>> save button ": {
		"text": "批准原因：>>保存按钮",
		"isAlreadyTranslated": true
	},
	"Parts Requested": {
		"text": "备件需求",
		"isAlreadyTranslated": true
	},
	"Reason for Approval:": {
		"text": "批准原因",
		"isAlreadyTranslated": true
	},
	"Part Name": {
		"text": "零件名称",
		"isAlreadyTranslated": true
	},
	"PART NAME": {
		"text": "备件名称",
		"isAlreadyTranslated": true
	},
	"Part Code": {
		"text": "备件代码",
		"isAlreadyTranslated": true
	},
	"HSN Code": {
		"text": "HSN代码",
		"isAlreadyTranslated": true
	},
	"Qty": {
		"text": "数量",
		"isAlreadyTranslated": true
	},
	"Select a Part Name": {
		"text": "选择一个零件名称",
		"isAlreadyTranslated": true
	},
	"Warranty": {
		"text": "保证",
		"isAlreadyTranslated": true
	},
	"Remark": {
		"text": "备注",
		"isAlreadyTranslated": true
	},
	"ADD CHARGES": {
		"text": "增加费用",
		"isAlreadyTranslated": true
	},
	"Avl. Qty": {
		"text": "可用数量",
		"isAlreadyTranslated": true
	},
	"Available Qty": {
		"text": "可用数量",
		"isAlreadyTranslated": true
	},
	"Part Warranty": {
		"text": "备件保修状态",
		"isAlreadyTranslated": true
	},
	"requested": {
		"text": "要求",
		"isAlreadyTranslated": true
	},
	"Requested By": {
		"text": "被要求",
		"isAlreadyTranslated": true
	},
	"Issued By": {
		"text": "由...发出",
		"isAlreadyTranslated": true
	},
	"External Reference ID": {
		"text": "外部工单号",
		"isAlreadyTranslated": true
	},
	"Current Status": {
		"text": "当前状态",
		"isAlreadyTranslated": true
	},
	"Add Required Parts": {
		"text": "添加所需备件",
		"isAlreadyTranslated": true
	},
	"Action": {
		"text": "动作",
		"isAlreadyTranslated": true
	},
	"Part Warranty": {
		"text": "部分保修",
		"isAlreadyTranslated": true
	},
	"Estimation approval": {
		"text": "估计审批",
		"isAlreadyTranslated": true
	},
	"Slot": {
		"text": "时间段",
		"isAlreadyTranslated": true
	},
	"Validate": {
		"text": "验证",
		"isAlreadyTranslated": true
	},
	"Under warranty": {
		"text": "在保修期内",
		"isAlreadyTranslated": true
	},
	"Under Warranty": {
		"text": "在保修期内",
		"isAlreadyTranslated": true
	},
	"Registration Documents": {
		"text": "注册文件",
		"isAlreadyTranslated": true
	},
	"Warranty Applicable": {
		"text": "保证条款",
		"isAlreadyTranslated": true
	},
	"Issue description": {
		"text": "问题描述",
		"isAlreadyTranslated": true
	},
	"Total Amount": {
		"text": "总金额",
		"isAlreadyTranslated": true
	},
	"Customer's Version": {
		"text": "顾客的问题反馈",
		"isAlreadyTranslated": true
	},
	"Cannot Diagnose": {
		"text": "不能诊断",
		"isAlreadyTranslated": true
	},
	"OnePlus Service Record": {
		"text": "万普拉斯服务记录",
		"isAlreadyTranslated": true
	},
	"Service Center: ": {
		"text": "服务中心：",
		"isAlreadyTranslated": true
	},
	"Defective Part Name": {
		"text": "有缺陷的部件名称",
		"isAlreadyTranslated": true
	},
	"Thank you for choosing OnePlus!": {
		"text": "感谢您选择万普拉斯！",
		"isAlreadyTranslated": true
	},
	"Reference No.": {
		"text": "参考编号。",
		"isAlreadyTranslated": true
	},
	"Part Number/Code": {
		"text": "零件编号/代码",
		"isAlreadyTranslated": true
	},
	"Is the part handed to customer?": {
		"text": "是部件交给客户？",
		"isAlreadyTranslated": true
	},
	"Service Center Contact No": {
		"text": "服务中心联系电话",
		"isAlreadyTranslated": true
	},
	"TERMS AND CONDITIONS OF SERVICES": {
		"text": "和服务方面条件",
		"isAlreadyTranslated": true
	},
	"Warranty is not applicable for customer induced defects like water lock, physical damage, unauthorized repair;": {
		"text": "保修不适用于客户引起的缺陷等水锁，物理损坏，未经授权的修理;",
		"isAlreadyTranslated": true
	},
	"There will be no warranty on cosmetic items;": {
		"text": "定于美容项目不予保修;",
		"isAlreadyTranslated": true
	},
	"Before submitting the device please backup data and remove any confidential, proprietary or valuable information from the product;": {
		"text": "提交型号，请备份数据并从产品的任何保密，专有或有价值的信息之前;",
		"isAlreadyTranslated": true
	},
	"Please decide to repair or not within 7 days if your device diagnosed as CID;": {
		"text": "请确定您的型号诊断为CID修复或没有在7天;",
		"isAlreadyTranslated": true
	},
	"Please pickup your device within 7 days after completing the repair by engineers;": {
		"text": "7天内请领取您完成型号工程师后维修;",
		"isAlreadyTranslated": true
	},
	"Please provide the original proof of purchase (Bill copy) to claim warranty;": {
		"text": "请提供购买（比尔复印件）要求保修证明原件;",
		"isAlreadyTranslated": true
	},
	"Please remove SIM card, memory card and pouches before depositing.": {
		"text": "请沉积前删除SIM卡，存储卡和包装袋。",
		"isAlreadyTranslated": true
	},
	"Please collect invoice / bill copy and the defective parts of out of warranty paid cases while taking delivery.": {
		"text": "请收集发票/账单复印件和保修期外的，而提货付费情况下，有缺陷的部件。",
		"isAlreadyTranslated": true
	},
	"IMEI 1/Serial Number 1": {
		"text": "IMEI 1 /序列号1",
		"isAlreadyTranslated": true
	},
	"IMEI 2/Serial Number 2": {
		"text": "IMEI 2 /序列号2",
		"isAlreadyTranslated": true
	},
	"Warranty applicable for this service": {
		"text": "适用于此保修服务",
		"isAlreadyTranslated": true
	},
	"Page 2 of 2": {
		"text": "第2页2",
		"isAlreadyTranslated": true
	},
	"Page 1 of 2": {
		"text": "页面1的2",
		"isAlreadyTranslated": true
	},
	"Are you sure? Please specify remarks, if any...": {
		"text": "确认提交？如有备注，请在此注明。",
		"isAlreadyTranslated": true
	},
	"Are you sure?": {
		"text": "你确定？",
		"isAlreadyTranslated": true
	},
	"Are you sure? Please specify the reason.": {
		"text": "你确定？请说明理由。",
		"isAlreadyTranslated": true
	},
	"Show Device Diagnosis": {
		"text": "显示诊断结果",
		"isAlreadyTranslated": true
	},
	"Show device diagnosis": {
		"text": "显示诊断结果",
		"isAlreadyTranslated": true
	},
	"Storage Capacity": {
		"text": "存储容量",
		"isAlreadyTranslated": true
	},
	"Year of Purchase": {
		"text": "购入年份",
		"isAlreadyTranslated": true
	},
	"Sales Channel": {
		"text": "销售渠道",
		"isAlreadyTranslated": true
	},
	"Purchase Price": {
		"text": "购买价格",
		"isAlreadyTranslated": true
	},
	"Is Warranty Applicable": {
		"text": "是否适用于保内维修",
		"isAlreadyTranslated": true
	},
	"Reason": {
		"text": "原因",
		"isAlreadyTranslated": true
	},
	"Symptoms": {
		"text": "症状",
		"isAlreadyTranslated": true
	},
	"Consumer Raised Issues": {
		"text": "顾客反馈的故障",
		"isAlreadyTranslated": true
	},
	"Validity": {
		"text": "是否有效",
		"isAlreadyTranslated": true
	},
	"No Parts Requested": {
		"text": "没有零件要求",
		"isAlreadyTranslated": true
	},
	"Pickup Charge": {
		"text": "皮卡费",
		"isAlreadyTranslated": true
	},
	"Symptoms Reported By Engineer": {
		"text": "工程师诊断的故障症状",
		"isAlreadyTranslated": true
	},
	"Add New Symptom Codes": {
		"text": "添加新的症状码",
		"isAlreadyTranslated": true
	},
	"Add": {
		"text": "添加",
		"isAlreadyTranslated": true
	},
	"Standard (16)%": {
		"text": "标准（16）％",
		"isAlreadyTranslated": true
	},
	"Total Payable": {
		"text": "应付总额",
		"isAlreadyTranslated": true
	},
	"Parts": {
		"text": "备件",
		"isAlreadyTranslated": true
	},
	"Advance": {
		"text": "预付款",
		"isAlreadyTranslated": true
	},
	"Other Charge": {
		"text": "其他收费",
		"isAlreadyTranslated": true
	},
	"Discount": {
		"text": "折扣",
		"isAlreadyTranslated": true
	},
	"Receive": {
		"text": "接收",
		"isAlreadyTranslated": true
	},
	"Damaged": {
		"text": "破损",
		"isAlreadyTranslated": true
	},
	"Unused": {
		"text": "没用过",
		"isAlreadyTranslated": true
	},
	"Save": {
		"text": "保存",
		"isAlreadyTranslated": true
	},
	"Issue Description": {
		"text": "问题说明",
		"isAlreadyTranslated": true
	},
	"Repair estimate approved": {
		"text": "维修估算批准",
		"isAlreadyTranslated": true
	},
	"Work in Progress with Engineer": {
		"text": "进展与工程师工作",
		"isAlreadyTranslated": true
	},
	"Device Received & Inspection in Progress": {
		"text": "型号接收和检验进展",
		"isAlreadyTranslated": true
	},
	"Request History": {
		"text": "申请历史纪录",
		"isAlreadyTranslated": true
	},
	"Charge Name": {
		"text": "负责人名",
		"isAlreadyTranslated": true
	},
	"Tax": {
		"text": "税",
		"isAlreadyTranslated": true
	},
	"Add Discount": {
		"text": "加入折扣",
		"isAlreadyTranslated": true
	},
	"Send For Approval": {
		"text": "申请报价批准",
		"isAlreadyTranslated": true
	},
	"Complete Repair": {
		"text": "完成维修",
		"isAlreadyTranslated": true
	},
	"View Jobsheet": {
		"text": "查看工作表",
		"isAlreadyTranslated": true
	},
	"View DOA Certificate": {
		"text": "查看DOA证书",
		"isAlreadyTranslated": true
	},
	"View Parts Document": {
		"text": "查看零件文档",
		"isAlreadyTranslated": true
	},
	"View Invoice": {
		"text": "查看发票",
		"isAlreadyTranslated": true
	},
	"View Advance Receipt": {
		"text": "查看预收",
		"isAlreadyTranslated": true
	},
	"Please collect invoice/bill copy and the defective parts of out of warranty paid cases while taking delivery.": {
		"text": "请收集发票/账单复印件和出保修支付案件有缺陷的部件，而提货",
		"isAlreadyTranslated": true
	},
	"Parts handover note": {
		"text": "部分交接记",
		"isAlreadyTranslated": true
	},
	"To: ": {
		"text": "至：",
		"isAlreadyTranslated": true
	},
	"From:": {
		"text": "从：",
		"isAlreadyTranslated": true
	},
	"Signature": {
		"text": "签名",
		"isAlreadyTranslated": true
	},
	"Part Name": {
		"text": "备件名称",
		"isAlreadyTranslated": true
	},
	"Quantity": {
		"text": "数量",
		"isAlreadyTranslated": true
	},
	"Servicelocation Engineer": {
		"text": "服务定位工程师",
		"isAlreadyTranslated": true
	},
	"Handing over the parts to store.": {
		"text": "移交部分存储。",
		"isAlreadyTranslated": true
	},
	"Handing over the": {
		"text": "移交",
		"isAlreadyTranslated": true
	},
	"Generate Invoice": {
		"text": "生成发票",
		"isAlreadyTranslated": true
	},
	"full box unit": {
		"text": "全箱单元",
		"isAlreadyTranslated": true
	},
	"to store.": {
		"text": "储藏。",
		"isAlreadyTranslated": true
	},
	"parts": {
		"text": "部分",
		"isAlreadyTranslated": true
	},
	"Select Fault Code based on the validated issues.": {
		"text": "根据检测的问题选择故障代码",
		"isAlreadyTranslated": true
	},
	"DIAGNOSIS RESULT": {
		"text": "诊断结果",
		"isAlreadyTranslated": true
	},
	"No results to display": {
		"text": "显示任何结果",
		"isAlreadyTranslated": true
	},
	"DIAGNOSIS HISTORY": {
		"text": "诊断历史",
		"isAlreadyTranslated": true
	},
	"Fault Code": {
		"text": "故障代码",
		"isAlreadyTranslated": true
	},
	"Would you like to prompt the user?": {
		"text": "你想提示用户？",
		"isAlreadyTranslated": true
	},
	"PromptSilent": {
		"text": "提示沉默",
		"isAlreadyTranslated": true
	},
	"Would you like to perform a full/custom diagnosis?": {
		"text": "你想执行完全/自定义的诊断是什么？",
		"isAlreadyTranslated": true
	},
	"FullCusCustomer Pincode is not serviceable by Our Logistics Partner. Please send the device to the Customer using other Courier Services near youtom": {
		"text": "客户邮递区号是不是我们的物流合作伙伴维修。请使用您附近的其他快递服务型号发送给客户",
		"isAlreadyTranslated": true
	},
	"Initiate Diagnosis": {
		"text": "诊断启动",
		"isAlreadyTranslated": true
	},
	"Initiate logistics request": {
		"text": "通知物流",
		"isAlreadyTranslated": true
	},
	"Ok": {
		"text": "好",
		"isAlreadyTranslated": true
	},
	"Perform QC": {
		"text": "执行QC",
		"isAlreadyTranslated": true
	},
	"QC Checklist": {
		"text": "QC清单",
		"isAlreadyTranslated": true
	},
	"Select All": {
		"text": "全选",
		"isAlreadyTranslated": true
	},
	"Accept and Complete QC": {
		"text": "接受并完成QC",
		"isAlreadyTranslated": true
	},
	"Reject QC": {
		"text": "拒绝QC",
		"isAlreadyTranslated": true
	},
	"Please complete the QC checklist": {
		"text": "请填写QC清单",
		"isAlreadyTranslated": true
	},
	"Repair Completed, Ready To Inform The Customer": {
		"text": "修复完成，准备通知客户",
		"isAlreadyTranslated": true
	},
	"Repair completed and Inform customer": {
		"text": "维修完成并通知客户",
		"isAlreadyTranslated": true
	},
	"Drop Request Created Post Repair": {
		"text": "维修完成并已生成快递单",
		"isAlreadyTranslated": true
	},
	"4:00 pm - 7:00 pm": {
		"text": "下午4:00  - 下午7:00",
		"isAlreadyTranslated": true
	},
	"10:00 am - 1:00 pm": {
		"text": "上午10点 - 下午1:00",
		"isAlreadyTranslated": true
	},
	"1:00 pm - 4:00 pm": {
		"text": "下午1:00  - 下午4:00",
		"isAlreadyTranslated": true
	},
	"10:00 am - 7:00 pm": {
		"text": "上午10点 - 下午7:00",
		"isAlreadyTranslated": true
	},
	"Genarate Invoice": {
		"text": "Genarate发票",
		"isAlreadyTranslated": true
	},
	"First Dialer Dispostion": {
		"text": "第一方拨号器",
		"isAlreadyTranslated": true
	},
	"Second Dialer Dispostion": {
		"text": "第二拨号",
		"isAlreadyTranslated": true
	},
	"Remarks": {
		"text": "备注",
		"isAlreadyTranslated": true
	},
	"Part Price": {
		"text": "单价",
		"isAlreadyTranslated": true
	},
	"Payment Received": {
		"text": "已收到付款",
		"isAlreadyTranslated": true
	},
	"Address Details": {
		"text": "详细地址",
		"isAlreadyTranslated": true
	},
	"First Registered From": {
		"text": "工单创建来源",
		"isAlreadyTranslated": true
	},
	"No saved Address": {
		"text": "没有保存的地址",
		"isAlreadyTranslated": true
	},
	"Return": {
		"text": "返回给顾客",
		"isAlreadyTranslated": true
	},
	"From": {
		"text": "从",
		"isAlreadyTranslated": true
	},
	"To": {
		"text": "至",
		"isAlreadyTranslated": true
	},
	"Tax Invoice": {
		"text": "税务发票",
		"isAlreadyTranslated": true
	},
	"Tax is payable on Reverse Charge (Y/N)": {
		"text": "税是对反向充电应付（是/否）",
		"isAlreadyTranslated": true
	},
	"GSTIN/Unique ID": {
		"text": "GSTIN /唯一ID",
		"isAlreadyTranslated": true
	},
	"Date & Time of Supply": {
		"text": "供应的日期和时间",
		"isAlreadyTranslated": true
	},
	"Place Of Supply": {
		"text": "地方分享到",
		"isAlreadyTranslated": true
	},
	"Payment Date": {
		"text": "付款日期",
		"isAlreadyTranslated": true
	},
	"Mode of Payment": {
		"text": "付款方式",
		"isAlreadyTranslated": true
	},
	"PAN No": {
		"text": "PAN无",
		"isAlreadyTranslated": true
	},
	"Tax is payable on Reverse Charge (Y/N): Y": {
		"text": "税是对反向充电（是/否）应付：是",
		"isAlreadyTranslated": true
	},
	"Tax is payable on Reverse Charge (Y/N): N": {
		"text": "税是对反向充电（是/否）应付：否",
		"isAlreadyTranslated": true
	},
	"Repaired by": {
		"text": "通过修复",
		"isAlreadyTranslated": true
	},
	"Details of Receiver (Billed to)": {
		"text": "接收机的细节（帐单到）",
		"isAlreadyTranslated": true
	},
	"Details of Consignee (Shipped to)": {
		"text": "收货人详细信息（运到）",
		"isAlreadyTranslated": true
	},
	"State Code": {
		"text": "国家代码：",
		"isAlreadyTranslated": true
	},
	"Address": {
		"text": "地址：",
		"isAlreadyTranslated": true
	},
	"Sl. No": {
		"text": "SL。没有",
		"isAlreadyTranslated": true
	},
	"Description of Goods/Services": {
		"text": "商品/服务的说明",
		"isAlreadyTranslated": true
	},
	"HSN/SAC Code": {
		"text": "HSN / SAC代码",
		"isAlreadyTranslated": true
	},
	"Rate": {
		"text": "率",
		"isAlreadyTranslated": true
	},
	"Taxable value": {
		"text": "应税价值",
		"isAlreadyTranslated": true
	},
	"Taxable Value": {
		"text": "应税价值",
		"isAlreadyTranslated": true
	},
	"Total Tax": {
		"text": "总税收",
		"isAlreadyTranslated": true
	},
	"Invoice Total": {
		"text": "发票总额",
		"isAlreadyTranslated": true
	},
	"Advance Received": {
		"text": "预收",
		"isAlreadyTranslated": true
	},
	"Round-Off Amount": {
		"text": "四舍五入金额",
		"isAlreadyTranslated": true
	},
	"Payable Value": {
		"text": "应付帐款的价值",
		"isAlreadyTranslated": true
	},
	"Invoice Total (In Words)": {
		"text": "发票合计（大写）",
		"isAlreadyTranslated": true
	},
	"Authorised Signatory": {
		"text": "授权签署",
		"isAlreadyTranslated": true
	},
	"Declaration: We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.": {
		"text": "声明：我们声明，该发票显示所述货物的实际价格与所有细节都是真实和正确的。",
		"isAlreadyTranslated": true
	},
	"Terms & Conditions:": {
		"text": "条款及条件：",
		"isAlreadyTranslated": true
	},
	"Tax Summary of Invoice (For Finance)": {
		"text": "发票的税务摘要（财务）",
		"isAlreadyTranslated": true
	},
	"Declaration": {
		"text": "宣言",
		"isAlreadyTranslated": true
	},
	"We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.": {
		"text": "我们声明本发票显示所述货物的实际价格与所有细节都是真实和正确的。",
		"isAlreadyTranslated": true
	},
	"(I have read the above mentioned terms and conditions are agreeable to the same and further confirm that our signature below confirms receipts of products in good order and noted.)": {
		"text": "（我已阅读上述条款及条件同意到我们下面的签字确认的产品在良好的秩序收据，并指出同样的进一步确认。）",
		"isAlreadyTranslated": true
	},
	"The request is not at the required status for the given action": {
		"text": "该请求是不是在给定动作所需要的状态",
		"isAlreadyTranslated": true
	},
	"Intimate Customer": {
		"text": "通知顾客",
		"isAlreadyTranslated": true
	},
	"Taxable Value": {
		"text": "计税价格",
		"isAlreadyTranslated": true
	},
	"Complete Service": {
		"text": "完成服务",
		"isAlreadyTranslated": true
	},
	"Repair completed and Intimate customer": {
		"text": "修复完成，并贴心的客户",
		"isAlreadyTranslated": true
	},
	"Receive & Inspect Device": {
		"text": "接收和检查设备",
		"isAlreadyTranslated": true
	},
	"Download Jobsheet Label": {
		"text": "下载给工作标签",
		"isAlreadyTranslated": true
	},
	"Cancel Service Without Dispatch": {
		"text": "取消服务，但未寄出",
		"isAlreadyTranslated": true
	},
	"Cancel Service": {
		"text": "取消服务",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to receive device and complete mandatory checklist tasks.": {
		"text": "等待“前台”执行 “接收设备”并完成必检清单。",
		"isAlreadyTranslated": true
	},
	"Assigned To": {
		"text": "分配给",
		"isAlreadyTranslated": true
	},
	"Select SKU": {
		"text": "选择SKU",
		"isAlreadyTranslated": true
	},
	"No pictures added.": {
		"text": "没有照片加。",
		"isAlreadyTranslated": true
	},
	"Other Image": {
		"text": "其它图片",
		"isAlreadyTranslated": true
	},
	"Not applicable": {
		"text": "不适用",
		"isAlreadyTranslated": true
	},
	"Complete": {
		"text": "完成",
		"isAlreadyTranslated": true
	},
	"Assignment": {
		"text": "分配",
		"isAlreadyTranslated": true
	},
	"Service Engineer": {
		"text": "维修工程师",
		"isAlreadyTranslated": true
	},
	"Cancel Repair Without Dispatch": {
		"text": "取消修复没有调度",
		"isAlreadyTranslated": true
	},
	"This service is a Return without repair (RWR)": {
		"text": "工程师维修之前取消服务",
		"isAlreadyTranslated": true
	},
	"Re-assign Service Engineer": {
		"text": "重新分配服务工程师",
		"isAlreadyTranslated": true
	},
	"Select an engineer": {
		"text": "选择一个工程师",
		"isAlreadyTranslated": true
	},
	"Assign": {
		"text": "分配",
		"isAlreadyTranslated": true
	},
	"Show device diagnosis": {
		"text": "显示型号诊断",
		"isAlreadyTranslated": true
	},
	"Accept ": {
		"text": "接受",
		"isAlreadyTranslated": true
	},
	"Service completed": {
		"text": "服务完成",
		"isAlreadyTranslated": true
	},
	"Part(s) request under review by storekeeper.": {
		"text": "由店主审查部（S）的请求。",
		"isAlreadyTranslated": true
	},
	"Send for Approval": {
		"text": "送审",
		"isAlreadyTranslated": true
	},
	"Reason for Approval": {
		"text": "原因批准：",
		"isAlreadyTranslated": true
	},
	"Parts Supply Location": {
		"text": "配件供应地点",
		"isAlreadyTranslated": true
	},
	"Order": {
		"text": "订购",
		"isAlreadyTranslated": true
	},
	"Please enter the remarks with at least 1 characters": {
		"text": "请输入至少1个字符的言论",
		"isAlreadyTranslated": true
	},
	"Please enter the remarks with at least 25 characters": {
		"text": "请输入至少25个字符的言论",
		"isAlreadyTranslated": true
	},
	"Estimated Repair Cost": {
		"text": "估计维修费用",
		"isAlreadyTranslated": true
	},
	'Customer"s Copy': {
		"text": "客户'的拷贝",
		"isAlreadyTranslated": true
	},
	"DOA CERTIFICATE": {
		"text": "DOA证书",
		"isAlreadyTranslated": true
	},
	"Dated": {
		"text": "过时的",
		"isAlreadyTranslated": true
	},
	"Date of Purchase (As per invoice)": {
		"text": "购买日期（按发票）",
		"isAlreadyTranslated": true
	},
	"Service Completion Date": {
		"text": "服务完成日期",
		"isAlreadyTranslated": true
	},
	"Model No": {
		"text": "型号",
		"isAlreadyTranslated": true
	},
	"Model Name": {
		"text": "型号名称",
		"isAlreadyTranslated": true
	},
	"ESC Code": {
		"text": "ESC代码",
		"isAlreadyTranslated": true
	},
	"ESC Name": {
		"text": "ESC名称",
		"isAlreadyTranslated": true
	},
	"ESC Location": {
		"text": "ESC位置",
		"isAlreadyTranslated": true
	},
	"DOA Check List": {
		"text": "DOA检查表",
		"isAlreadyTranslated": true
	},
	"Customer Details & Remarks": {
		"text": "客户详细信息和备注",
		"isAlreadyTranslated": true
	},
	"Contact No.": {
		"text": "联系方式。",
		"isAlreadyTranslated": true
	},
	"DOA Device IMEI": {
		"text": "DOA型号IMEI",
		"isAlreadyTranslated": true
	},
	"Customer Signature": {
		"text": "客户签名",
		"isAlreadyTranslated": true
	},
	"ESC Stamp & Signature": {
		"text": "ESC邮票及签名",
		"isAlreadyTranslated": true
	},
	'Service Center"s Copy': {
		"text": "服务中心'的拷贝",
		"isAlreadyTranslated": true
	},
	'ESC Name and Stamp': {
		"text": "ESC名称和印章",
		"isAlreadyTranslated": true
	},
	"Handover to QC": {
		"text": "切换到QC",
		"isAlreadyTranslated": true
	},
	"Search brands": {
		"text": "搜索品牌",
		"isAlreadyTranslated": true
	},
	"Search subcategories": {
		"text": "搜索子类别",
		"isAlreadyTranslated": true
	},
	"Enter IMEI number": {
		"text": "输入IMEI号码",
		"isAlreadyTranslated": true
	},
	"OR": {
		"text": "或者",
		"isAlreadyTranslated": true
	},
	"Or": {
		"text": "或者",
		"isAlreadyTranslated": true
	},
	"Enter Serial number": {
		"text": "输入序列号",
		"isAlreadyTranslated": true
	},
	"Search products": {
		"text": "搜索产品",
		"isAlreadyTranslated": true
	},
	"Search products": {
		"text": "搜索产品",
		"isAlreadyTranslated": true
	},
	"Add an address": {
		"text": "添加地址",
		"isAlreadyTranslated": true
	},
	"Enter Location": {
		"text": "输入位置",
		"isAlreadyTranslated": true
	},
	"Characters must be alphabets": {
		"text": "字符必须是字母",
		"isAlreadyTranslated": true
	},
	"Characters must be alphabets (at least 2 alphabets are mandatory)": {
		"text": "字符必须是字母（必须至少包含2个字母)",
		"isAlreadyTranslated": true
	},
	"Loading": {
		"text": "载入中",
		"isAlreadyTranslated": true
	},
	"Ref Id": {
		"text": "工单号",
		"isAlreadyTranslated": true
	},
	"Started On": {
		"text": "开始了",
		"isAlreadyTranslated": true
	},
	"Communication History": {
		"text": "传播史",
		"isAlreadyTranslated": true
	},
	"Status at which call was made": {
		"text": "拨打电话的状态",
		"isAlreadyTranslated": true
	},
	"Date and Time of Call": {
		"text": "通话的日期和时间",
		"isAlreadyTranslated": true
	},
	"Second Dialer Disposition": {
		"text": "第二拨号处理",
		"isAlreadyTranslated": true
	},
	"First Dialer Disposition": {
		"text": "第一拨号处理",
		"isAlreadyTranslated": true
	},
	"Connection Disposition": {
		"text": "连接处理",
		"isAlreadyTranslated": true
	},
	"Call Connected": {
		"text": "呼叫已连接",
		"isAlreadyTranslated": true
	},
	"On": {
		"text": "上",
		"isAlreadyTranslated": true
	},
	"at": {
		"text": "在",
		"isAlreadyTranslated": true
	},
	"Telecon Version": {
		"text": "电话联系记录",
		"isAlreadyTranslated": true
	},
	"Accept Device": {
		"text": "接受型号",
		"isAlreadyTranslated": true
	},
	"ID Proof": {
		"text": "身份证明",
		"isAlreadyTranslated": true
	},
	"Invoice": {
		"text": "发票",
		"isAlreadyTranslated": true
	},
	"Others": {
		"text": "其他",
		"isAlreadyTranslated": true
	},
	"Dashboard": {
		"text": "仪表板",
		"isAlreadyTranslated": true
	},
	"Consumers": {
		"text": "消费者",
		"isAlreadyTranslated": true
	},
	"View Document": {
		"text": "查看文档",
		"isAlreadyTranslated": true
	},
	"Upload Inspection Documents": {
		"text": "上传检验文件",
		"isAlreadyTranslated": true
	},
	"Initiate Service": {
		"text": "发起服务",
		"isAlreadyTranslated": true
	},
	"Accept/Reject Job": {
		"text": "接受/拒绝工作",
		"isAlreadyTranslated": true
	},
	"Reject Estimation": {
		"text": "拒绝评估",
		"isAlreadyTranslated": true
	},
	"Collect Advance": {
		"text": "收集进展",
		"isAlreadyTranslated": true
	},
	"QC Completed": {
		"text": "QC已完成",
		"isAlreadyTranslated": true
	},
	"Diagnosis Completed": {
		"text": "诊断完成",
		"isAlreadyTranslated": true
	},
	"Collect Device": {
		"text": "收集型号",
		"isAlreadyTranslated": true
	},
	"Accept Parts Request": {
		"text": "接受零件申请",
		"isAlreadyTranslated": true
	},
	"Reject Parts Request": {
		"text": "拒绝零件请求",
		"isAlreadyTranslated": true
	},
	"DOA Checklist": {
		"text": "DOA清单",
		"isAlreadyTranslated": true
	},
	"Prompt": {
		"text": "提示",
		"isAlreadyTranslated": true
	},
	"Silent": {
		"text": "无声",
		"isAlreadyTranslated": true
	},
	"Full": {
		"text": "充分",
		"isAlreadyTranslated": true
	},
	"Custom": {
		"text": "自定义",
		"isAlreadyTranslated": true
	},
	"BUTTONS": {
		"text": "纽扣",
		"isAlreadyTranslated": true
	},
	"SCREEN": {
		"text": "屏幕",
		"isAlreadyTranslated": true
	},
	"CAMERA": {
		"text": "相机",
		"isAlreadyTranslated": true
	},
	"SOUND": {
		"text": "声音",
		"isAlreadyTranslated": true
	},
	"PORTS": {
		"text": "端口",
		"isAlreadyTranslated": true
	},
	"CONNECTIVITY": {
		"text": "连接性",
		"isAlreadyTranslated": true
	},
	"SENSORS": {
		"text": "传感器",
		"isAlreadyTranslated": true
	},
	"HARDWARE": {
		"text": "硬件",
		"isAlreadyTranslated": true
	},
	"Using Notification": {
		"text": "使用通知",
		"isAlreadyTranslated": true
	},
	"Using QR Code": {
		"text": "使用QR码",
		"isAlreadyTranslated": true
	},
	"Select from the following to create a custom diagnosis": {
		"text": "从以下选项中创建自定义诊断",
		"isAlreadyTranslated": true
	},
	"Is the device applicable for DOA?": {
		"text": "该型号适用于DOA吗？",
		"isAlreadyTranslated": true
	},
	"Scan the QR code from Diagnosis App": {
		"text": "从诊断应用程序中扫描QR码",
		"isAlreadyTranslated": true
	},
	"Check Status": {
		"text": "检查状态",
		"isAlreadyTranslated": true
	},
	"Type of damage": {
		"text": "伤害类型",
		"isAlreadyTranslated": true
	},
	"Please mention the incident in not less than 75 characters on how, when & where the damage happened": {
		"text": "请提供不少于75个字符的事件，说明损坏发生的时间，地点和地点",
		"isAlreadyTranslated": true
	},
	"Handing over the defective device to store.": {
		"text": "移交有缺陷的型号进行存储。",
		"isAlreadyTranslated": true
	},
	"Update KGB": {
		"text": "更新克格勃",
		"isAlreadyTranslated": true
	},
	"Select GSX Repair Type": {
		"text": "选择GSX维修类型",
		"isAlreadyTranslated": true
	},
	"Transfer Request": {
		"text": "转移要求",
		"isAlreadyTranslated": true
	},
	"Send link": {
		"text": "发送链接",
		"isAlreadyTranslated": true
	},
	"Logistic Partners": {
		"text": "物流合作伙伴",
		"isAlreadyTranslated": true
	},
	"Select a Date": {
		"text": "选择日期",
		"isAlreadyTranslated": true
	},
	"Select a Slot": {
		"text": "选择时间段",
		"isAlreadyTranslated": true
	},
	"No Slots Available": {
		"text": "没有可用的插槽",
		"isAlreadyTranslated": true
	},
	"Schedule Request": {
		"text": "安排要求",
		"isAlreadyTranslated": true
	},
	"Item": {
		"text": "项目",
		"isAlreadyTranslated": true
	},
	"Raise": {
		"text": "提高",
		"isAlreadyTranslated": true
	},
	"Request": {
		"text": "请求",
		"isAlreadyTranslated": true
	},
	"Please Select Device Receiving Time": {
		"text": "请选择型号接收时间",
		"isAlreadyTranslated": true
	},
	"Tier part": {
		"text": "等级",
		"isAlreadyTranslated": true
	},
	"Shipper": {
		"text": "托运人",
		"isAlreadyTranslated": true
	},
	"Tracking Number": {
		"text": "追踪号码",
		"isAlreadyTranslated": true
	},
	"COMPTIA Group": {
		"text": "COMPTIA集团",
		"isAlreadyTranslated": true
	},
	"COMPTIA Code": {
		"text": "COMPTIA代码",
		"isAlreadyTranslated": true
	},
	"COMPTIA Modifier": {
		"text": "COMPTIA修饰符",
		"isAlreadyTranslated": true
	},
	"Continue": {
		"text": "继续",
		"isAlreadyTranslated": true
	},
	"To proceed, update Device Date of Purchase and Device Purchase Cost in 'Device Details' below.": {
		"text": "要继续，请在下面的'型号详细信息'中更新型号购买日期和型号购买成本。",
		"isAlreadyTranslated": true
	},
	"Ask for Documents Pending": {
		"text": "要求提供待处理的文件",
		"isAlreadyTranslated": true
	},
	"Enter type of document to add": {
		"text": "输入要添加的文档类型",
		"isAlreadyTranslated": true
	},
	"Enter Reference IDs": {
		"text": "输入工单号",
		"isAlreadyTranslated": true
	},
	"Current Request Reference ID": {
		"text": "当前请求工单号",
		"isAlreadyTranslated": true
	},
	"Pick Up Request Reference ID": {
		"text": "提取请求工单号",
		"isAlreadyTranslated": true
	},
	"Merge": {
		"text": "合并",
		"isAlreadyTranslated": true
	},
	"Device transaction details": {
		"text": "型号交易详情",
		"isAlreadyTranslated": true
	},
	"Select Device": {
		"text": "选择型号",
		"isAlreadyTranslated": true
	},
	"Please check following items before completing the QC.": {
		"text": "在完成质量控制之前，请检查以下项目。",
		"isAlreadyTranslated": true
	},
	"Initiate Claim": {
		"text": "发起索赔",
		"isAlreadyTranslated": true
	},
	"Raise Claim": {
		"text": "提出索赔",
		"isAlreadyTranslated": true
	},
	"In-principle Approval": {
		"text": "原则上批准",
		"isAlreadyTranslated": true
	},
	"Approve Claim Estimation": {
		"text": "批准索赔估算",
		"isAlreadyTranslated": true
	},
	"Complete QC": {
		"text": "完整QC",
		"isAlreadyTranslated": true
	},
	"Accept device": {
		"text": "接受型号",
		"isAlreadyTranslated": true
	},
	"Complete Job and Handover Device": {
		"text": "完成工作和移交型号",
		"isAlreadyTranslated": true
	},
	"Reject Claim": {
		"text": "拒绝索赔",
		"isAlreadyTranslated": true
	},
	"Confirm BER": {
		"text": "确认BER",
		"isAlreadyTranslated": true
	},
	"Reject BER": {
		"text": "拒绝BER",
		"isAlreadyTranslated": true
	},
	"Generate estimation": {
		"text": "生成估计",
		"isAlreadyTranslated": true
	},
	"尝试通话": {
		"text": "尝试通话",
		"isAlreadyTranslated": true
	},
	"Documents Pending": {
		"text": "文件待定",
		"isAlreadyTranslated": true
	},
	"Document Type: Cheque": {
		"text": "文件类型：检查",
		"isAlreadyTranslated": true
	},
	"Please enter UTR Number": {
		"text": "请输入UTR号码",
		"isAlreadyTranslated": true
	},
	"UTR Number": {
		"text": "UTR号码",
		"isAlreadyTranslated": true
	},
	"View queued requests": {
		"text": "查看排队的请求",
		"isAlreadyTranslated": true
	},
	"Generate Vouchers": {
		"text": "生成优惠券",
		"isAlreadyTranslated": true
	},
	"Customer's Address Details": {
		"text": "客户的地址详情",
		"isAlreadyTranslated": true
	},
	"Hub's Address Details": {
		"text": "枢纽的地址详情",
		"isAlreadyTranslated": true
	},
	"Upload Document": {
		"text": "上传文件",
		"isAlreadyTranslated": true
	},
	"Call logs": {
		"text": "通话记录",
		"isAlreadyTranslated": true
	},
	"Okay": {
		"text": "好的",
		"isAlreadyTranslated": true
	},
	"an address": {
		"text": "一个地址",
		"isAlreadyTranslated": true
	},
	"Characters must be numbers": {
		"text": "字符必须是数字",
		"isAlreadyTranslated": true
	},
	"Now": {
		"text": "现在",
		"isAlreadyTranslated": true
	},
	"Later": {
		"text": "后来",
		"isAlreadyTranslated": true
	},
	"Hub Details": {
		"text": "轮毂细节",
		"isAlreadyTranslated": true
	},
	"This request will be added to a queue. You can schedule a logistics request later.": {
		"text": "此请求将被添加到队列中。您可以稍后安排后勤请求。",
		"isAlreadyTranslated": true
	},
	"Recycle Hub Details": {
		"text": "回收中心详细信息",
		"isAlreadyTranslated": true
	},
	"Add Certificate": {
		"text": "添加证书",
		"isAlreadyTranslated": true
	},
	"Incoming": {
		"text": "来电",
		"isAlreadyTranslated": true
	},
	"Outgoing": {
		"text": "发送",
		"isAlreadyTranslated": true
	},
	"Add call log": {
		"text": "添加通话记录",
		"isAlreadyTranslated": true
	},
	"Call type": {
		"text": "通话类型",
		"isAlreadyTranslated": true
	},
	"Call history": {
		"text": "通话记录",
		"isAlreadyTranslated": true
	},
	"No call history": {
		"text": "没有通话记录",
		"isAlreadyTranslated": true
	},
	"N/A": {
		"text": "不适用",
		"isAlreadyTranslated": true
	},
	"Request is being processed": {
		"text": "请求正在处理中",
		"isAlreadyTranslated": true
	},
	"Service Location": {
		"text": "服务地点",
		"isAlreadyTranslated": true
	},
	"No addresses found": {
		"text": "找不到地址",
		"isAlreadyTranslated": true
	},
	"Select a Service Mode": {
		"text": "选择服务模式",
		"isAlreadyTranslated": true
	},
	"Select a service location": {
		"text": "选择服务地点",
		"isAlreadyTranslated": true
	},
	"Oops! There are no verified service centres around.": {
		"text": "哎呀！周围没有经过验证的服务中心。",
		"isAlreadyTranslated": true
	},
	"There is no service for this address at this moment": {
		"text": "此时此地址没有服务",
		"isAlreadyTranslated": true
	},
	"Raise Request": {
		"text": "提出要求",
		"isAlreadyTranslated": true
	},
	"Select Logistics Partner": {
		"text": "选择物流合作伙伴",
		"isAlreadyTranslated": true
	},
	"Confirm": {
		"text": "确认",
		"isAlreadyTranslated": true
	},
	"Config Description": {
		"text": "配置说明",
		"isAlreadyTranslated": true
	},
	"Validation Status": {
		"text": "验证状态",
		"isAlreadyTranslated": true
	},
	"Verify": {
		"text": "校验",
		"isAlreadyTranslated": true
	},
	"Number of Attempts Remaining": {
		"text": "剩余的尝试次数",
		"isAlreadyTranslated": true
	},
	"Verification Not Attempted": {
		"text": "验证未尝试",
		"isAlreadyTranslated": true
	},
	"No documents uploaded": {
		"text": "没有上传文件",
		"isAlreadyTranslated": true
	},
	"Add Pending Documents": {
		"text": "添加待处理文档",
		"isAlreadyTranslated": true
	},
	"No Pending Document Specified": {
		"text": "没有待定文件",
		"isAlreadyTranslated": true
	},
	"Add Additional Documents": {
		"text": "添加其他文件",
		"isAlreadyTranslated": true
	},
	"Customer did not add issues": {
		"text": "客户没有添加问题",
		"isAlreadyTranslated": true
	},
	"No issues added": {
		"text": "没有添加任何问题",
		"isAlreadyTranslated": true
	},
	"Engineer Added Faults": {
		"text": "工程师添加了错误",
		"isAlreadyTranslated": true
	},
	"Faults": {
		"text": "故障",
		"isAlreadyTranslated": true
	},
	"Action Code": {
		"text": "行动守则",
		"isAlreadyTranslated": true
	},
	"Fault Codes": {
		"text": "故障代码",
		"isAlreadyTranslated": true
	},
	"Actions": {
		"text": "操作",
		"isAlreadyTranslated": true
	},
	"Accessories will not be returned": {
		"text": "配件不会退回",
		"isAlreadyTranslated": true
	},
	"Documents are yet to be uploaded.": {
		"text": "文件尚未上传。",
		"isAlreadyTranslated": true
	},
	"Rating": {
		"text": "评分",
		"isAlreadyTranslated": true
	},
	"Waiting for the customer to raise a request": {
		"text": "等待客户创建工单",
		"isAlreadyTranslated": true
	},
	"Cancel Request": {
		"text": "取消请求",
		"isAlreadyTranslated": true
	},
	"Device shipped": {
		"text": "型号已发货",
		"isAlreadyTranslated": true
	},
	"Reschedule": {
		"text": "改期",
		"isAlreadyTranslated": true
	},
	"Reassign": {
		"text": "重新分配",
		"isAlreadyTranslated": true
	},
	"Mark Customer Arrival": {
		"text": "标记客户到货",
		"isAlreadyTranslated": true
	},
	"Start Repair": {
		"text": "开始修复",
		"isAlreadyTranslated": true
	},
	"Device Received": {
		"text": "型号已收到",
		"isAlreadyTranslated": true
	},
	"Dispatch Device": {
		"text": "调度装置",
		"isAlreadyTranslated": true
	},
	"Parts Pending": {
		"text": "零件待定",
		"isAlreadyTranslated": true
	},
	"Issue Validation": {
		"text": "问题验证",
		"isAlreadyTranslated": true
	},
	"Report Issues to": {
		"text": "报告问题",
		"isAlreadyTranslated": true
	},
	"Estimation Details": {
		"text": "评估细节",
		"isAlreadyTranslated": true
	},
	"Override Approval History": {
		"text": "覆盖审批历史记录",
		"isAlreadyTranslated": true
	},
	"Dead on Arrival": {
		"text": "开箱损",
		"isAlreadyTranslated": true
	},
	"Logistics Details": {
		"text": "物流详情",
		"isAlreadyTranslated": true
	},
	"Loading request": {
		"text": "加载请求",
		"isAlreadyTranslated": true
	},
	"No such service request exists": {
		"text": "不存在此类服务请求",
		"isAlreadyTranslated": true
	},
	"Send Issues to": {
		"text": "发送问题",
		"isAlreadyTranslated": true
	},
	"Symptom Code": {
		"text": "症状代码",
		"isAlreadyTranslated": true
	},
	"Issue Code": {
		"text": "问题代码",
		"isAlreadyTranslated": true
	},
	"Part name": {
		"text": "零件名称",
		"isAlreadyTranslated": true
	},
	"Covered Value": {
		"text": "涵盖价值",
		"isAlreadyTranslated": true
	},
	"Price": {
		"text": "价钱",
		"isAlreadyTranslated": true
	},
	"Tax Slab": {
		"text": "税务板",
		"isAlreadyTranslated": true
	},
	"Is Part Abused": {
		"text": "部分被滥用",
		"isAlreadyTranslated": true
	},
	"Is Returnable Damage": {
		"text": "可回收的伤害",
		"isAlreadyTranslated": true
	},
	"Is Covered By ACPlus": {
		"text": "被ACPlus覆盖",
		"isAlreadyTranslated": true
	},
	"KBB Serial Number": {
		"text": "KBB序列号",
		"isAlreadyTranslated": true
	},
	"Pricing Option": {
		"text": "定价选项",
		"isAlreadyTranslated": true
	},
	"Charge Value": {
		"text": "收费价值",
		"isAlreadyTranslated": true
	},
	"MRP": {
		"text": "MRP",
		"isAlreadyTranslated": true
	},
	"Final Price": {
		"text": "最终价格",
		"isAlreadyTranslated": true
	},
	"Good Part Serial No": {
		"text": "好的部件序列号",
		"isAlreadyTranslated": true
	},
	"Bad Part Serial No": {
		"text": "坏部分序列号",
		"isAlreadyTranslated": true
	},
	"Avl.": {
		"text": "可得到",
		"isAlreadyTranslated": true
	},
	"Enter Information for the parts you are issuing": {
		"text": "输入您要发布的部件的信息",
		"isAlreadyTranslated": true
	},
	"Date Of Purchase": {
		"text": "购买日期",
		"isAlreadyTranslated": true
	},
	"Device Replacement Mode": {
		"text": "型号更换模式",
		"isAlreadyTranslated": true
	},
	"Generate DOA Certificate": {
		"text": "生成DOA证书",
		"isAlreadyTranslated": true
	},
	"Get New Device": {
		"text": "更换新设备",
		"isAlreadyTranslated": true
	},
	"Choose Device": {
		"text": "选择型号",
		"isAlreadyTranslated": true
	},
	"Action on Device": {
		"text": "对型号采取行动",
		"isAlreadyTranslated": true
	},
	"Enter Information for the Device you are issuing": {
		"text": "输入您要发出的设备的信息",
		"isAlreadyTranslated": true
	},
	"IMEI 1": {
		"text": "IMEI 1",
		"isAlreadyTranslated": true
	},
	"IMEI 2": {
		"text": "IMEI 2",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI 1": {
		"text": "请输入正确的IMEI 1",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI 2": {
		"text": "请输入正确的IMEI 2",
		"isAlreadyTranslated": true
	},
	"Please enter correct Serial Number": {
		"text": "请输入正确的序列号",
		"isAlreadyTranslated": true
	},
	"Account Details": {
		"text": "帐户详细资料",
		"isAlreadyTranslated": true
	},
	"The Green Reward cash amount will be credited to the account associated with this number": {
		"text": "绿色奖励现金金额将记入与此号码相关联的帐户",
		"isAlreadyTranslated": true
	},
	"Add Customer's Mobile Number": {
		"text": "添加客户的手机号码",
		"isAlreadyTranslated": true
	},
	"Enter Mobile Number": {
		"text": "输入手机号码",
		"isAlreadyTranslated": true
	},
	"Re - Enter Mobile Number": {
		"text": "重新输入手机号码",
		"isAlreadyTranslated": true
	},
	"GSX Confirmation Number": {
		"text": "GSX确认号码",
		"isAlreadyTranslated": true
	},
	"Reassigned": {
		"text": "再分配",
		"isAlreadyTranslated": true
	},
	"Under Warranty": {
		"text": "在保修期内",
		"isAlreadyTranslated": true
	},
	"Pending Verification": {
		"text": "待验证",
		"isAlreadyTranslated": true
	},
	"Recycle Certificate": {
		"text": "回收证书",
		"isAlreadyTranslated": true
	},
	"Logistics - From Drop-Off Center to Servify Hub": {
		"text": "物流 - 从下车中心到Servify中心",
		"isAlreadyTranslated": true
	},
	"Logistics - From Servify Hub to Recycle Hub": {
		"text": "物流 - 从Servify中心到回收中心",
		"isAlreadyTranslated": true
	},
	"Logistics - Return to Customer": {
		"text": "物流 - 回归客户",
		"isAlreadyTranslated": true
	},
	"Pick Up Confirmation Number": {
		"text": "取货确认号码",
		"isAlreadyTranslated": true
	},
	"Location": {
		"text": "位置",
		"isAlreadyTranslated": true
	},
	"Paid": {
		"text": "付费",
		"isAlreadyTranslated": true
	},
	"Payable": {
		"text": "应付款",
		"isAlreadyTranslated": true
	},
	"Loss DateTime": {
		"text": "损失日期时间",
		"isAlreadyTranslated": true
	},
	"Damage Type": {
		"text": "伤害类型",
		"isAlreadyTranslated": true
	},
	"Loss Description": {
		"text": "损失描述",
		"isAlreadyTranslated": true
	},
	"Device switching on": {
		"text": "型号开启",
		"isAlreadyTranslated": true
	},
	"Place of Damage": {
		"text": "伤害的地方",
		"isAlreadyTranslated": true
	},
	"Enter Reference ID": {
		"text": "输入工单号",
		"isAlreadyTranslated": true
	},
	"Reschedule Service": {
		"text": "重新安排服务",
		"isAlreadyTranslated": true
	},
	"No slots Available": {
		"text": "没有可用的插槽",
		"isAlreadyTranslated": true
	},
	"Reason for reschedule": {
		"text": "重新安排的原因",
		"isAlreadyTranslated": true
	},
	"Reassign Service": {
		"text": "重新分配服务",
		"isAlreadyTranslated": true
	},
	"Not Covered": {
		"text": "不包括",
		"isAlreadyTranslated": true
	},
	"Validated": {
		"text": "验证",
		"isAlreadyTranslated": true
	},
	"End on": {
		"text": "结束",
		"isAlreadyTranslated": true
	},
	"Service cancel": {
		"text": "服务取消",
		"isAlreadyTranslated": true
	},//@TODO: CC
	"Waiting for CC to receive device and complete mandatory checklist tasks.": {
		"text": "等待CC接收型号并完成强制检查清单任务。",
		"isAlreadyTranslated": true
	},
	"Date and Time of loss/damage:": {
		"text": "损失/损坏的日期和时间：",
		"isAlreadyTranslated": true
	},
	"Service Mode": {
		"text": "服务模式",
		"isAlreadyTranslated": true
	},
	"Slot and Date": {
		"text": "插槽和日期",
		"isAlreadyTranslated": true
	},
	"Insurance Benefits": {
		"text": "保险福利",
		"isAlreadyTranslated": true
	},
	"Inspection Guide": {
		"text": "检查指南",
		"isAlreadyTranslated": true
	},
	"c": {
		"text": "C",
		"isAlreadyTranslated": true
	},
	"View Delivery Report": {
		"text": "查看交付报告",
		"isAlreadyTranslated": true
	},
	"View Depot shipper label": {
		"text": "查看仓库托运人标签",
		"isAlreadyTranslated": true
	},
	"BER Amount": {
		"text": "BER金额",
		"isAlreadyTranslated": true
	},
	"Loaner Device IMEI": {
		"text": "借用型号IMEI",
		"isAlreadyTranslated": true
	},
	"Condition Of the Loaner Device After Use": {
		"text": "使用后的借用型号的状况",
		"isAlreadyTranslated": true
	},
	"Remarks for the Loaner Device": {
		"text": "借用型号备注",
		"isAlreadyTranslated": true
	},
	"Delivery Address": {
		"text": "邮寄地址",
		"isAlreadyTranslated": true
	},
	"doc": {
		"text": "文件",
		"isAlreadyTranslated": true
	},
	"Token released": {
		"text": "令牌发布",
		"isAlreadyTranslated": true
	},
	"Token generated": {
		"text": "令牌生成",
		"isAlreadyTranslated": true
	},
	"Insufficient Data For Graph. Data for": {
		"text": "图表数据不足。数据为",
		"isAlreadyTranslated": true
	},
	"is": {
		"text": "是",
		"isAlreadyTranslated": true
	},
	"No Data Available To Plot The Graph": {
		"text": "没有数据可用于绘制图表",
		"isAlreadyTranslated": true
	},
	"Create Bulk Request": {
		"text": "创建批量请求",
		"isAlreadyTranslated": true
	},
	"External Requests": {
		"text": "外部请求",
		"isAlreadyTranslated": true
	},
	"Dispatch": {
		"text": "调度",
		"isAlreadyTranslated": true
	},
	"Mail IN": {
		"text": "邮件进入",
		"isAlreadyTranslated": true
	},
	"Devices": {
		"text": "型号",
		"isAlreadyTranslated": true
	},
	"till": {
		"text": "直到",
		"isAlreadyTranslated": true
	},
	"Loading requests": {
		"text": "加载请求",
		"isAlreadyTranslated": true
	},
	"to": {
		"text": "至",
		"isAlreadyTranslated": true
	},
	"Download Open/Inprogress requests.": {
		"text": "下载公开/进行中请求。",
		"isAlreadyTranslated": true
	},
	"Download all reports": {
		"text": "下载所有报告",
		"isAlreadyTranslated": true
	},
	"The parts will be returned to Store": {
		"text": "零件将退回库管",
		"isAlreadyTranslated": true
	},
	"Consumer To Service Centre": {
		"text": "消费者到服务中心",
		"isAlreadyTranslated": true
	},
	"Within S.F. service area coverage": {
		"text": "在S.F.服务区范围内",
		"isAlreadyTranslated": true
	},
	"Logistics - From Service Center to Service Center": {
		"text": "物流 - 从服务中心到服务中心",
		"isAlreadyTranslated": true
	},
	"Remove": {
		"text": "去掉",
		"isAlreadyTranslated": true
	},
	"Test purpose": {
		"text": "测试目的",
		"isAlreadyTranslated": true
	},
	"Defective Serial No": {
		"text": "瑕疵序列号",
		"isAlreadyTranslated": true
	},
	"Defective IMEI": {
		"text": "IMEI瑕疵",
		"isAlreadyTranslated": true
	},
	"Replaced IMEI 1": {
		"text": "取代了IMEI 1",
		"isAlreadyTranslated": true
	},
	"Replaced IMEI 2": {
		"text": "取代了IMEI 2",
		"isAlreadyTranslated": true
	},
	"Replaced IMEI": {
		"text": "取代了IMEI",
		"isAlreadyTranslated": true
	},
	"Other charge": {
		"text": "其他费用",
		"isAlreadyTranslated": true
	},
	"Test remarks": {
		"text": "测试评论",
		"isAlreadyTranslated": true
	},
	"issued": {
		"text": "发行",
		"isAlreadyTranslated": true
	},
	"received": {
		"text": "收到",
		"isAlreadyTranslated": true
	},
	"Would you like to prompt the user?": {
		"text": "你想提示用户吗？",
		"isAlreadyTranslated": true
	},
	"Would you like to perform a full/custom diagnosis?": {
		"text": "你想进行全面/自定义诊断吗？",
		"isAlreadyTranslated": true
	},
	"Select from the following to create a custom diagnosis": {
		"text": "从以下选项中创建自定义诊断",
		"isAlreadyTranslated": true
	},
	"Customer Mobile": {
		"text": "客户手机",
		"isAlreadyTranslated": true
	},
	"Customer Email": {
		"text": "客户电子邮件",
		"isAlreadyTranslated": true
	},
	"Customer Alternate Mobile": {
		"text": "客户备用手机",
		"isAlreadyTranslated": true
	},
	"Initiate App Diagnostic": {
		"text": "启动App诊断",
		"isAlreadyTranslated": true
	},
	"Diagnosed on": {
		"text": "诊断",
		"isAlreadyTranslated": true
	},
	"Diagnostic Report": {
		"text": "诊断报告",
		"isAlreadyTranslated": true
	},
	"Diagnosed": {
		"text": "诊断",
		"isAlreadyTranslated": true
	},
	"times": {
		"text": "时代",
		"isAlreadyTranslated": true
	},
	"Diagnosis Result": {
		"text": "诊断结果",
		"isAlreadyTranslated": true
	},
	"Diagnosis History": {
		"text": "诊断史",
		"isAlreadyTranslated": true
	},
	"No diagnosis history found": {
		"text": "没有发现诊断史",
		"isAlreadyTranslated": true
	},
	"No diagnosis found for this": {
		"text": "没有找到这个诊断",
		"isAlreadyTranslated": true
	},
	"consumer product": {
		"text": "消费品",
		"isAlreadyTranslated": true
	},
	"Diagnostic report": {
		"text": "诊断报告",
		"isAlreadyTranslated": true
	},
	"Mo": {
		"text": "一",
		"isAlreadyTranslated": true
	},
	"Tu": {
		"text": "二",
		"isAlreadyTranslated": true
	},
	"We": {
		"text": "三",
		"isAlreadyTranslated": true
	},
	"Th": {
		"text": "四",
		"isAlreadyTranslated": true
	},
	"Fr": {
		"text": "五",
		"isAlreadyTranslated": true
	},
	"Sa": {
		"text": "六",
		"isAlreadyTranslated": true
	},
	"Su": {
		"text": "日",
		"isAlreadyTranslated": true
	},
	"Quotation document": {
		"text": "报价文件",
		"isAlreadyTranslated": true
	},
	"Estimate Generated By": {
		"text": "估计生成者",
		"isAlreadyTranslated": true
	},
	"In Words": {
		"text": "大写",
		"isAlreadyTranslated": true
	},
	"Service Estimate Total": {
		"text": "服务估算总",
		"isAlreadyTranslated": true
	},
	"The charges mentioned in this Estimate are an approximation and is based on the anticipated details of the work to be carried out basis the inspection. It is possible for unexpected situations / complications to cause some deviation from this Estimate. If additional parts or labour are required you will be contacted immediately and upon your concurrence to carry out the details of the work, a new Estimate will be created for your consideration and approval.": {
		"text": "在此估算中提到的收费是近似值，并根据工作的预期细节进行基础检查。这是可能的突发状况/并发症，使从这个估计有些偏差。如果需要额外的部件或劳务费，你会立即联系并在您的同意开展工作的细节，新的估计会为你的审议和批准创建。",
		"isAlreadyTranslated": true
	},
	"Disclaimer": {
		"text": "放弃",
		"isAlreadyTranslated": true
	},
	"In Words":{
		"text": "用言语",
		"isAlreadyTranslated": true
	},
	"We were unable to find product details, please select product manually":{
		"text": "我们无法找到产品详细信息，请手动选择产品",
		"isAlreadyTranslated": true
	},
	"Enter correct Pincode":{
		"text": "输入正确的Pincode",
		"isAlreadyTranslated": true
	},
	"StoreManager":{
		"text": "店经理",
		"isAlreadyTranslated": true
	},
	"View Estimation Document": {
		"text": "查看估计文档",
		"isAlreadyTranslated": true
	},
	"SE Express": {
		"text": "顺丰",
		"isAlreadyTranslated": true
	},
	"Speaker issue": {
		"text": "扬声器问题",
		"isAlreadyTranslated": true
	},
	"Year": {
		"text": "年",
		"isAlreadyTranslated": true
	},
	"Month": {
		"text": "月",
		"isAlreadyTranslated": true
	},
	"Engineer will add symtoms": {
		"text": "工程师需要添加故障现象代码",
		"isAlreadyTranslated": true
	},
	"Complete DOA":{
		"text": "完成DOA流程",
		"isAlreadyTranslated": true
	},
	"Jan":{
		"text": "一月",
		"isAlreadyTranslated": true
	},
	"Feb":{
		"text": "二月",
		"isAlreadyTranslated": true
	},
	"Mar":{
		"text": "三月",
		"isAlreadyTranslated": true
	},
	"Apr":{
		"text": "四月",
		"isAlreadyTranslated": true
	},
	"May":{
		"text": "五月",
		"isAlreadyTranslated": true
	},
	"Jun 2018":{
		"text": "六月 2018",
		"isAlreadyTranslated": true
	},
	"Jul":{
		"text": "七月",
		"isAlreadyTranslated": true
	},
	"Aug 2018":{
		"text": "八月 2018",
		"isAlreadyTranslated": true
	},
	"Sep":{
		"text": "九月",
		"isAlreadyTranslated": true
	},
	"Oct":{
		"text": "十月",
		"isAlreadyTranslated": true
	},
	"Nov":{
		"text": "十一月",
		"isAlreadyTranslated": true
	},
	"Dec":{
		"text": "十二月",
		"isAlreadyTranslated": true
	},
	"Service Estimate":{
		"text": "服务估算总",
		"isAlreadyTranslated": true
	},
	"Receive Device":{
		"text": "接受设备",
		"isAlreadyTranslated": true
	},
	"Front camera issue":{
		"text": "前置摄像头问题",
		"isAlreadyTranslated": true
	},
	"We Pick Up Your Device":{
		"text": "上门取件",
		"isAlreadyTranslated": true
	},
	"Store":{
		"text": "库管",
		"isAlreadyTranslated": true
	},
	"Add":{
		"text": "添加",
		"isAlreadyTranslated": true
	},
	"Intimate Logistics":{
		"text": "通知物流",
		"isAlreadyTranslated": true
	},
	"Hand Over Device":{
		"text": "移交设备",
		"isAlreadyTranslated": true
	},
	"Dead On Arrival":{
		"text": "开箱损",
		"isAlreadyTranslated": true
	},
	"Replace a new device":{
		"text": "更换新设备",
		"isAlreadyTranslated": true
	},
	"Engineer will select replace new device from the DOA part":{
		"text": "工程师需从DOA栏选择更换新设备",
		"isAlreadyTranslated": true
	},
	"Waiting for store to issue the device required from Dead On Arrival part":{
		"text": "等待库管在DOA栏发料",
		"isAlreadyTranslated": true
	},
	"PART CODE/SKU":{
		"text": "备件代码/SKU",
		"isAlreadyTranslated": true
	},
	"USABLE":{
		"text": "可用备件",
		"isAlreadyTranslated": true
	},
	"DEFECTIVE":{
		"text": "坏件",
		"isAlreadyTranslated": true
	},
	"IW DEFECTIVE":{
		"text": "保内坏件",
		"isAlreadyTranslated": true
	},
	"OOW DEFECTIVE":{
		"text": "保外坏件",
		"isAlreadyTranslated": true
	},
	"Export Inventory":{
		"text": "导出库存",
		"isAlreadyTranslated": true
	},
	"Apply Filter":{
		"text": "应用筛选",
		"isAlreadyTranslated": true
	},
	"Issue Consighnment Orders":{
		"text": "提交寄售订单",
		"isAlreadyTranslated": true
	},
	"Received Consighnment Orders":{
		"text": "收到的寄售订单",
		"isAlreadyTranslated": true
	},
	"Issue Return Orders":{
		"text": "提交退料订单",
		"isAlreadyTranslated": true
	},
	"Received Return Orders":{
		"text": "收到退料订单",
		"isAlreadyTranslated": true
	}	,
	"Add New Parts":{
		"text": "添加新备件",
		"isAlreadyTranslated": true
	},
	"Add Parts":{
		"text": "添加备件",
		"isAlreadyTranslated": true
	},
	"Parts Added":{
		"text": "新增的备件",
		"isAlreadyTranslated": true
	},
	"Add Inventory Return Order":{
		"text": "添加退料订单",
		"isAlreadyTranslated": true
	},
	"Return Type":{
		"text": "回退类型",
		"isAlreadyTranslated": true
	},
	"By JobID":{
		"text": "通过工单ID",
		"isAlreadyTranslated": true
	},
	"By Parts":{
		"text": "通过备件SKU",
		"isAlreadyTranslated": true
	},
	"Parts Consumption":{
		"text": "备件消耗报告",
		"isAlreadyTranslated": true
	},
	"Pending Orders":{
		"text": "欠料报告",
		"isAlreadyTranslated": true
	},
	"In Transit Shipments":{
		"text": "在途物料报表",
		"isAlreadyTranslated": true
	},
	"Order History":{
		"text": "历史订单报告",
		"isAlreadyTranslated": true
	},
	"Inventory":{
		"text": "库存报告",
		"isAlreadyTranslated": true
	},
	"From Date":{
		"text": "起始日期",
		"isAlreadyTranslated": true
	},
	"From Date (Updated On)":{
		"text": "起始日期",
		"isAlreadyTranslated": true
	},
	"To Date":{
		"text": "截止日期",
		"isAlreadyTranslated": true
	},
	"To Date (Updated On)":{
		"text": "截止日期",
		"isAlreadyTranslated": true
	},
	"No file chosen":{
		"text": "没有选中任何文件",
		"isAlreadyTranslated":true
	},
	"Choose File":{
		"text": "选择文件",
		"isAlreadyTranslated":true
	},
	"Parts Requested":{
		"text": "备件需求",
		"isAlreadyTranslated": true
	},
	"Are you sure?\nPlease specify remarks, if any…": {
		"text": "确认提交？如有备注，请在此注明。",
		"isAlreadyTranslated": true
	},
	"Amount Payable":{
		"text": "应付款",
		"isAlreadyTranslated": true
	},
	'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.':{
		"text": "等待库管在下面的“备件需求”中发料",
		"isAlreadyTranslated": true
	},
	"Total Quantity":{
		"text": "总数量",
		"isAlreadyTranslated": true
	},
	"User":{
		"text": "用户",
		"isAlreadyTranslated": true
	},
	"Product Information":{
		"text": "产品信息",
		"isAlreadyTranslated": true
	},
	"Problem Description by User":{
		"text": "用户描述故障",
		"isAlreadyTranslated": true
	},
	"Warranty":{
		"text": "保修",
		"isAlreadyTranslated": true
	},
	"Number/Code":{
		"text": "备件编码",
		"isAlreadyTranslated": true
	},
	"Handed to customer":{
		"text": "坏件是否还给顾客",
		"isAlreadyTranslated": true
	},
	"Replacement":{
		"text": "更换新备件",
		"isAlreadyTranslated": true
	},
	"Symptom Code":{
		"text": "现象代码",
		"isAlreadyTranslated": true
	},
	"Fault Code":{
		"text": "原因代码",
		"isAlreadyTranslated": true
	},
	"Total Billed Amount":{
		"text": "总价格",
		"isAlreadyTranslated": true
	},
	"Detailed Problem Remark by Eng":{
		"text": "工程师备注",
		"isAlreadyTranslated": true
	},
	"OnePlus may use Special diagnostic tool to diagnose your device which will analyse previous usage logs;":{
		"text": "Oneplus可能会使用一些诊断工具来诊断您的设备，该工具将分析您以前的历史使用记录",
		"isAlreadyTranslated": true
	},
	"Symptom already added by the engineer": {
		"text": "工程师已添加故障现象",
		"isAlreadyTranslated": true
	},
	"Please add a part from dropdown": {
		"text": "请从下拉列表选择备件",
		"isAlreadyTranslated": true
	},
	"Full box unit is already added": {
		"text": "整机已添加",
		"isAlreadyTranslated": true
	},
	"DOA applicable": {
		"text": "DOA 适用",
		"isAlreadyTranslated": true
	},
	"Please enter reason for approval": {
		"text": "请输入批准的原因",
		"isAlreadyTranslated": true
	},
	"The device is eligible for DOA": {
		"text": "此设备DOA适用",
		"isAlreadyTranslated": true
	},
	"Cannot select more than": {
		"text": "无法选择多于",
		"isAlreadyTranslated": true
	},
	"No slots available": {
		"text": "无可预约的时间",
		"isAlreadyTranslated": true
	},
	"This device is not eligible for DOA": {
		"text": "此设备不符合DOA的条件",
		"isAlreadyTranslated": true
	},
	"This device is eligible for DOA": {
		"text": "此设备符合DOA的条件",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive will choose action for the device from Dead on Arrival section below.": {
		"text": "服务专员将会在下面DOA的部分选择对该设备的操作",
		"isAlreadyTranslated": true
	},
	"Please enter numbers only": {
		"text": "请只输入数字",
		"isAlreadyTranslated": true
	},
	"Incident ID": {
		"text": "事件ID",
		"isAlreadyTranslated": true
	},
	"Flag Requests": {
		"text": "请求标志",
		"isAlreadyTranslated": true
	},
	"Flag Type": {
		"text": "标志种类",
		"isAlreadyTranslated": true
	},
	"Escalation Source": {
		"text": "升级来源",
		"isAlreadyTranslated": true
	},
	"Escalation Media": {
		"text": "升级媒体",
		"isAlreadyTranslated": true
	},
	"Save this": {
		"text": "保存",
		"isAlreadyTranslated": true
	},
	"IMEI / Serial Number": {
		"text": "IMEI/序列号",
		"isAlreadyTranslated": true
	},
	"Scan the QR code from Wechat Pay": {
		"text": "请扫描微信支付二维码",
		"isAlreadyTranslated": true
	},
	"Select Fault Code / Cause Code based on the validated issues.": {
		"text": "请根据确认到的问题选择故障代码/原因代码",
		"isAlreadyTranslated": true
	},
	"Cause Code": {
		"text": "原因代码",
		"isAlreadyTranslated": true
	},
	"Billing Details": {
		"text": "账单明细",
		"isAlreadyTranslated": true
	},
	"Create Shippment": {
		"text": "发起快递",
		"isAlreadyTranslated": true
	},
	"Update IMEI": {
		"text": "修改IMEI",
		"isAlreadyTranslated": true
	},
	"Old Phone's IMEI / Serial Number": {
		"text": "旧手机的IMEI/序列号",
		"isAlreadyTranslated": true
	},
	"New Phone's IMEI/Serial Number": {
		"text": "信手机的IMEI/序列号",
		"isAlreadyTranslated": true
	},
	"New Phone's IMEI": {
		"text": "新手机的IMEI",
		"isAlreadyTranslated": true
	},
	"New Phone's Serial Number": {
		"text": "新手机的序列号",
		"isAlreadyTranslated": true
	},
	"Update New Phone's Serial Number": {
		"text": "修改新手机的序列号",
		"isAlreadyTranslated": true
	},
	"No Slots Available": {
		"text": "无可预约的时间",
		"isAlreadyTranslated": true
	},
	"Source Channel": {
		"text": "来源渠道",
		"isAlreadyTranslated": true
	},
	"Customer Type": {
		"text": "客户类型",
		"isAlreadyTranslated": true
	},
	"Access Point": {
		"text": "接入点",
		"isAlreadyTranslated": true
	},
	"Parts Tracking": {
		"text": "备件跟踪",
		"isAlreadyTranslated": true
	},
	"Contact Details for Logistics": {
		"text": "物流联络资料",
		"isAlreadyTranslated": true
	},
	"Use Same Contact Details": {
		"text": "使用相同的联络方式",
		"isAlreadyTranslated": true
	},
	"Use Different Contact Details": {
		"text": "使用不同的联络方式",
		"isAlreadyTranslated": true
	},
	"Upgraded Device Details": {
		"text": "升级设备的详细新信息",
		"isAlreadyTranslated": true
	},
	"Replacement Device Details": {
		"text": "更换设备的详细信息",
		"isAlreadyTranslated": true
	},
	"Upgraded": {
		"text": "升级",
		"isAlreadyTranslated": true
	},
	"Storage": {
		"text": "库房",
		"isAlreadyTranslated": true
	},
	"Color": {
		"text": "颜色",
		"isAlreadyTranslated": true
	},
	"Device's": {
		"text": "设备的",
		"isAlreadyTranslated": true
	},
	"Customer's": {
		"text": "用户的",
		"isAlreadyTranslated": true
	},
	"Symptoms Reported": {
		"text": "故障现象报告",
		"isAlreadyTranslated": true
	},
	"SFAP reported by Engineer": {
		"text": "工程师选择的SFAP",
		"isAlreadyTranslated": true
	},
	"No symptoms fault actions are added for this request ": {
		"text": "这个请求没有添加现象、原因、动作代码",
		"isAlreadyTranslated": true
	},
	"Add Symptom Fault Action": {
		"text": "增加现象、故障、动作",
		"isAlreadyTranslated": true
	},
	"Symptom": {
		"text": "现象",
		"isAlreadyTranslated": true
	},
	"Fault": {
		"text": "故障现象报告",
		"isAlreadyTranslated": true
	},
	"Part": {
		"text": "备件跟踪",
		"isAlreadyTranslated": true
	},
	"Avl. Qty.": {
		"text": "可用的数量",
		"isAlreadyTranslated": true
	},
	"Symptoms reported by Engineer": {
		"text": "工程师报告的故障",
		"isAlreadyTranslated": true
	},
	"Inventory manager": {
		"text": "库管",
		"isAlreadyTranslated": true
	},
	"View Insurer Invoice": {
		"text": "浏览保险发票",
		"isAlreadyTranslated": true
	},
	"Set Schedule": {
		"text": "设置预约",
		"isAlreadyTranslated": true
	},
	"Cancel logistics request": {
		"text": "取消物流申请",
		"isAlreadyTranslated": true
	},
	"Symptom Fault Action": {
		"text": "现象 故障 动作",
		"isAlreadyTranslated": true
	},
	"Mirror Test": {
		"text": "镜像测试",
		"isAlreadyTranslated": true
	},
	"Part name": {
		"text": "配件名称",
		"isAlreadyTranslated": true
	},
	"PR Number": {
		"text": "更换配件的号码",
		"isAlreadyTranslated": true
	},
	"Shipping Location": {
		"text": "寄送地址",
		"isAlreadyTranslated": true
	},
	"Billing Location": {
		"text": "结算地址",
		"isAlreadyTranslated": true
	},
	"Fulfiller Name": {
		"text": "执行者姓名",
		"isAlreadyTranslated": true
	},
	"Wall Mount": {
		"text": "壁挂式安装",
		"isAlreadyTranslated": true
	},
	"Dealer name and Contact Number": {
		"text": "经销商名称和联络电话",
		"isAlreadyTranslated": true
	},
	"Repair DateTime": {
		"text": "维修时间",
		"isAlreadyTranslated": true
	},
	"Repair Cost": {
		"text": "维修费",
		"isAlreadyTranslated": true
	},
	"Location of Incident": {
		"text": "事件发生地点",
		"isAlreadyTranslated": true
	},
	"No slots Available": {
		"text": "无可预约的时间",
		"isAlreadyTranslated": true
	},
	"No Billing Detail found": {
		"text": "未找到账单明细",
		"isAlreadyTranslated": true
	},
	"Select Billing Detail": {
		"text": "选择结算明细",
		"isAlreadyTranslated": true
	},
	"Add New Billing Detail": {
		"text": "增加新的结算明细",
		"isAlreadyTranslated": true
	},
	"Pickup Scheduled": {
		"text": "预约物流取件时间",
		"isAlreadyTranslated": true
	},
	"Request Scheduled": {
		"text": "预约申请时间",
		"isAlreadyTranslated": true
	},
	"Contact Number": {
		"text": "联络电话",
		"isAlreadyTranslated": true
	},
	"DOA completed": {
		"text": "DOA完成",
		"isAlreadyTranslated": true
	},
	"Approve/Reject DOA": {
		"text": "同意/拒绝 DOA",
		"isAlreadyTranslated": true
	},
	"Intiate Repair": {
		"text": "开始维修",
		"isAlreadyTranslated": true
	},
	"Receive Payment": {
		"text": "收款",
		"isAlreadyTranslated": true
	},
	"Inform customer": {
		"text": "通知客户",
		"isAlreadyTranslated": true
	},
	"Handover device": {
		"text": "转移设备",
		"isAlreadyTranslated": true
	},
	"Could not schedule your request Please try again after some time.": {
		"text": "无法预约，请稍后重试。",
		"isAlreadyTranslated": true
	},
	"There is already an open request for this IMEI/Serial Number. Another request cannot be raised until you complete or cancel the existing request.": {
		"text": "相同IMEI工单未关闭，请关闭前一工单后重试。",
		"isAlreadyTranslated": true
	},
	"Name of the Agent": {
		"text": "坐席姓名",
		"isAlreadyTranslated": true
	},
	"Received payment": {
		"text": "收到付款",
		"isAlreadyTranslated": true
	},
	"Service invoice can be generated.": {
		"text": "可生成服务发票",
		"isAlreadyTranslated": true
	},
	"Part request has been accepted by store.": {
		"text": "库房已接受物料请求",
		"isAlreadyTranslated": true
	},
	"Inspect Device": {
		"text": "检测设备",
		"isAlreadyTranslated": true
	},
	"Device Value": {
		"text": "手机价值",
		"isAlreadyTranslated": true
	},
	"One time deductible": {  
		"text": "一次免赔额",
		"isAlreadyTranslated": true
	},
	"Is Manufacturer's Warranty Applicable": { 
		"text": "是否厂商保修", 
		"isAlreadyTranslated": true
	},
	"Manufacturer's Warranty Status": { 
		"text": "厂商保修", 
		"isAlreadyTranslated": true
	},
}
export default lang;