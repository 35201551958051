import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Loader } from 'sfy-react';
import Swal from "sweetalert2";
import moment from 'moment';

import { displayError } from '../../../Utility/Utils';
import AudienceSelection from './AudienceSelection';
import Content from './Content';
import FinalReview from './FinalReview';

const NewAnnouncement = (props) => {
	const { localString, setNewAnnouncement, accessFn, apiCall } = props
	const [step, setStep] = useState(1);
	const [retailerUsers, setRetailerUsers] = useState([]);
	const [shopOptions, setShopOptions] = useState([]);
	const [notifyTo, setNotifyTo] = useState('All');
	const [notifyToLabel, setNotifyToLabel] = useState('All');
	const [pills, setPills] = useState([]);
	const [loader, setLoader] = useState(false);
	const [content, setContent] = useState({
		Title: '',
		Message: '',
		Attachment: '',
		Type: 'text'
	});
	const [expiryTime, setExpiryTime] = useState('');
	const [file, setFile] = useState(null);
	const [notificationId, setNotificationId] = useState('')
	const hiddenFileInput = useRef(null);

	const saveNotificationData = (stepParam, url, fileType) => {
		setLoader(true);
		let reqObj = {};
		if(notificationId) reqObj.NotificationGroupNumber = notificationId;
		reqObj.selectedRetailUsers = retailerUsers.map(k => k.RetailUserID);
		if(Number(stepParam) === 2) {
			reqObj.notificationExpiryTime = moment().endOf('day').add(parseInt(expiryTime), 'days');
			if(file) {
				let obj = content;
				obj.Type = fileType;
				obj.Attachment = url;
				reqObj.Content = obj;
			}else {
				reqObj.Content = content;
			}
		}
		apiCall({
			url: 'saveNotificationData',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data){
					result.data && result.data.NotificationGroupNumber && setNotificationId(result.data.NotificationGroupNumber)
					setStep(stepParam + 1);
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: err => setLoader(false)
		});
	}

	return (
		<>
			{
				loader && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<ol className="breadcrumb">
				{ Number(step) >= 1 && <li className={`${Number(step) === 1 ? 'active' : ''}`}><span onClick={() => Number(step) > 1 && setStep(1)}>{localString('Audience Selection')}</span></li>}
				{ Number(step) >= 2 && <li className={`${Number(step) === 2 ? 'active' : ''}`}><span onClick={() => Number(step) > 2 && setStep(2)}>{localString('Content')}</span></li>}
				{ Number(step) >= 3 && <li className={`${Number(step) === 3 ? 'active' : ''}`}><span>{localString('Final Review')}</span></li>}
			</ol>
			{
				Number(step) === 1 && 
					<AudienceSelection
						retailerUsers={retailerUsers}
						setRetailerUsers={setRetailerUsers}
						notifyTo={notifyTo}
						setNotifyTo={setNotifyTo}
						notifyToLabel={notifyToLabel}
						setNotifyToLabel={setNotifyToLabel}
						pills={pills}
						setPills={setPills}
						shopOptions={shopOptions}
						setShopOptions={setShopOptions}
						saveNotificationData={saveNotificationData}
						setNotificationId={setNotificationId}
						{...props}
					/>
			}
			{
				Number(step) === 2 && 
					<Content
						retailerUsers={retailerUsers}
						saveNotificationData={saveNotificationData}
						content={content}
						setContent={setContent}
						expiryTime={expiryTime}
						setExpiryTime={setExpiryTime}
						file={file}
						setFile={setFile}
						hiddenFileInput={hiddenFileInput}
						loader={loader}
						setLoader={setLoader}
						{...props}
					/>

			}
			{
				Number(step) === 3 && 
					<FinalReview 
						retailerUsers={retailerUsers}
						content={content}
						setLoader={setLoader}
						notificationId={notificationId}
						saveNotificationData={saveNotificationData}
						setNewAnnouncement={setNewAnnouncement}
						{...props}
					/>
			}
		</>
	);
};

export default NewAnnouncement;