import React, { useEffect, useState } from 'react';
import { Button } from 'sfy-react';
import Swal from "sweetalert2"; 

import { displayError } from '../../../Utility/Utils';


const FinalReview = (props) => {
	const { accessFn, localString, apiCall, setLoader, content, notificationId, setNewAnnouncement } = props;
	const [data, setData] = useState([]);

	const sendNotification = () => {
		setLoader(true);
		let reqObj = {
			NotificationGroupNumber: notificationId
		}
		apiCall({
			url: 'sendNotification',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data) {
					// setData(result.data);
					Swal.fire({  
						icon: 'success',  
						text: localString(displayError(result)),  
					});
					setNewAnnouncement(false);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const getNotificationInfo = () => {
		setLoader(true);
		let reqObj = {
			NotificationGroupNumber: notificationId
		}
		apiCall({
			url: 'getNotificationInfo',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data){
					setData(result.data);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		getNotificationInfo();
	}, [])

	return (
		<>
			<div className='announcementReview'>
				<div className="col-sm-offset-3 col-sm-6 block-margin">
					<div className="block-border-first">
						<div className="tick-logo">
						</div>
						<div className="message-content-alignment">
							<div className="title-header">{localString('Audience')}</div>
							<div className="review-title-header">
								<div>
									{ !accessFn('ENGINEER_NOTIFICATION') && <span >{localString('All shops and states')}</span> }
								</div>
								{ !accessFn('ENGINEER_NOTIFICATION') && <div className="lower-div-margin-bottom">
									{localString('Reach')} - {data.length}
								</div> }
							</div>
						</div>
					</div>
					<div className="block-border">
						<div className="">
							<div className="tick-logo"></div>
							<div className="message-content-alignment">
								<div className="title-header">{localString('Content')}</div>
								<div className="review-title-header break-word"> {localString('Title')} - {content.Title} </div>
								<div className="review-title-header break-word"> {localString('Message')} - {content.Message} </div>
								{ content.Attachment && <div className="attach-link lower-div-margin-bottom"> <a href={content.Attachment} target="_blank">{localString('Link to attachment')}</a></div> }
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-12">
					<div>
						<Button 
							type="button" 
							className="btn button-servify pull-right" 
							onClick={() => sendNotification()}
						> 
							{localString('Send')}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}

export default FinalReview;