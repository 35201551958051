import React, { useEffect, useState } from "react";
import { Pagination, Button, Input, Modal, Dropdown } from 'sfy-react';
import Swal from "sweetalert2";
import moment from 'moment';
import CommonTable from "../../components/Table/Table";
import { getLocaleDateString, encrypt } from "../../Utility/Utils";
import SendMessageModal from "./SendMessageModal"; 

const PlanRenewalGrid = (props) => {
	const { localString, consumerRenewals, pagination, setPagination, setConsumerRenewals, findConsumerRenewals, publicUrl } = props;
	const qFilter = JSON.parse(props.qFilter)
	const [showModal, setShowModal] = useState(false);
	const [filteredList, setFilteredList] = useState([]);
	const [planRenewalOfferID, setPlanRenewalOfferID] = useState("");
	const [reminderCounts, setReminderCounts] = useState({});
    const [maxManualRetryCount, setMaxManualRetryCount] = useState("")
	const [usedManualRetryTrigger, setUsedManualRetryTrigger] = useState("")
	const [selectedRows, setSelectedRows] = useState([]);
	const [isAnyCheckboxSelected, setIsAnyCheckboxSelected] = useState(false);
    
	const updateReminderCounts = (planRenewalOfferID, newCount) => {
		// Update the reminder count for the given planRenewalOfferID
		const updatedCounts = { ...reminderCounts };
		updatedCounts[planRenewalOfferID] = newCount;
		setReminderCounts(updatedCounts);
	};

	const openModal = (row) => {
		const planRenewalOfferID = row.PlanRenewalOfferID;
		setPlanRenewalOfferID(planRenewalOfferID);
		setShowModal(true);
	};

	const closeModal = () => {
		setPlanRenewalOfferID("")
		setShowModal(false);
	};

    useEffect(() => {
		setConsumerRenewals(props.consumerRenewals);
		setFilteredList(props.consumerRenewals);
		// Initialize UsedManualRetryTrigger state
		const initialUsedManualRetryTriggers = {};
		props.consumerRenewals.forEach((row) => {
			initialUsedManualRetryTriggers[row.PlanRenewalOfferID] = row.UsedManualRetryTrigger;
		});
		setUsedManualRetryTrigger(initialUsedManualRetryTriggers);
		setSelectedRows([]);
		setIsAnyCheckboxSelected(false);
		setMaxManualRetryCount(consumerRenewals.MaxManualRetryCount)
	}, [props.consumerRenewals]);

	const getOrderDeatails = (renewalData) => {
		const baseUrl = props.baseUrl; 
		const orderDetailsUrl = props.orderDetailsUrl; 
        // Constructing the URL with the required parameters
		let url =
			baseUrl +
			orderDetailsUrl +
			(renewalData.PlanID ? '?planId=' + (renewalData.PlanID) : '') + 
			(renewalData.CustomerName ? '&customerName=' + encrypt(renewalData.CustomerName) : '') + 
			(renewalData.PlanName ? '&planName=' + encrypt(renewalData.PlanName) : '') + 
			(renewalData.ExpiryDate ? '&expiryDate=' + encrypt(moment(renewalData.ExpiryDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) : '') +
			(renewalData.RenewalPlanCode ? '&renewalPlanCode=' + encrypt(renewalData.RenewalPlanCode) : '') +
			(renewalData.BrandID ? '&brandId=' + encrypt(renewalData.BrandID) : '') +
			(renewalData.ProductSubCategoryID ? '&subcat=' + encrypt(renewalData.ProductSubCategoryID) : '') +
			(renewalData.ProductID ? '&productId=' + encrypt(renewalData.ProductID) : '') +
			(renewalData.ProductPurchaseCost ? '&productPurchaseCost=' + encrypt(renewalData.ProductPurchaseCost) : '') +
			(renewalData.ProductUniqueID ? '&serial=' + encrypt(renewalData.ProductUniqueID) : '') +
			(renewalData.EmailID ? '&emailID=' + encrypt(renewalData.EmailID) : '') + 
			(renewalData.MobileNo ? '&mobileNo=' + encrypt(renewalData.MobileNo) : '') +
			(renewalData.PlanRenewalOfferID ? '&planRenewalOfferID=' + encrypt(renewalData.PlanRenewalOfferID) : '') +
            (renewalData.PartnerServiceLocationID ? '&pslid=' + renewalData.PartnerServiceLocationID : '');
	    // Redirect to the constructed URL
		window.location.assign(url);
	};

	const handleSelectAllCheckbox = (event) => {
		const isChecked = event.target.checked;
	  
		// Filter out rows with UsedManualRetryTrigger >= 3
		const eligibleRows = consumerRenewals.filter((row) => row.UsedManualRetryTrigger < 3);
	  
		if (isChecked) {
		  setSelectedRows(eligibleRows);
		} else {
		  setSelectedRows([]);
		}
		setIsAnyCheckboxSelected(isChecked);
	};

	const columns = [
		{
			title: (
				<input
					type="checkbox"
					checked={selectedRows.length === consumerRenewals.length && consumerRenewals.every((row) => row.UsedManualRetryTrigger < 3)}
					disabled={consumerRenewals.some((row) => row.UsedManualRetryTrigger === 3)}
					onChange={handleSelectAllCheckbox}
				/>
			),
			showHeading: true,
			customCell: (row) => (
				<input
					type="checkbox"
					checked={selectedRows.includes(row)}
					onChange={() => handleCheckboxChange(row)}
					disabled={row.UsedManualRetryTrigger === 3}
				/>
			),
		},
		{
			title: localString("Customer Name"),
			showHeading: true,
			customCell: (row) => row.CustomerName || '--',
		},
		{
			title: localString("Customer Number"),
			showHeading: true,
			customCell: (row) => (
			  <div className="d-flex">
				{row.MobileNo ? `${row.MobileNo}` : '--'}
				{row.MobileNo && (
					<span
						className="copy-icon"
						title="Copy"
						onClick={() => handleCopyMobileNo(row.MobileNo)}
					>
					<img
						src={publicUrl + "/imgs/Copy.png"}
						width={"16px"}
						style={{ marginLeft: "8px", marginRight: "-16px" }}
					/>
				  </span>
				)}
			  </div>
			),
		},
		{
			title: localString("Customer Email"),
			showHeading: true,
			customCell: (row) => (
				<div className="d-flex">
				<div className="customer-email">{row.EmailID ? `${row.EmailID}` : '--'}</div>
				{row.EmailID && (
					<span
						className="copy-icon"
						title="Copy"
						onClick={() => handleCopyEmailID(row.EmailID)}
					>
					<img
						src={publicUrl + "/imgs/Copy.png"}
						width={"16px"}
						style={{ marginLeft: "8px", marginRight: "-16px" }}
					/>
					</span>
				)}
				</div>
			),
		},  
		{
			title: localString("Existing Plan"),
			showHeading: true,
			customCell: (row) => row.OldPlanName || '--',
		},
		{
			title: localString("New Plan"),
			showHeading: true,
			customCell: (row) => row.PlanName || '--'
		},
		{
			title: localString("Plan Expiry"),
			showHeading: true,
			customCell: (row) => (
			<div className="width-max">{getLocaleDateString(row.ExpiryDate) || '--'}</div>
			),
		},
		{
			title: localString("Payment Link Status"),
			showHeading: true,
			customCell: (row) => (
				<div className="statusPill">
					{localString("Payment Due")}
				</div>
			),
		},	
		{
			title: localString("Reminder Attempts"),
			showHeading: true,
			customCell: (row) => {
				const planRenewalOfferID = row.PlanRenewalOfferID;
				const reminderCount = usedManualRetryTrigger[planRenewalOfferID] || row.UsedManualRetryTrigger;
				const maxAttempts = row.MaxManualRetryCount;
			
				return `${reminderCount}/${maxAttempts}`;
			},
		},		  
		{
			title: localString("Action"),
			showHeading: true,
			customCell: (row) => (
			  <div className="d-flex gap-3">
				<div>
					<button
						className={`btn btn-download ${row.UsedManualRetryTrigger >= row.MaxManualRetryCount ? 'disabled' : ''}`}
						onClick={() => {
							if (row.UsedManualRetryTrigger < row.MaxManualRetryCount) {
							openModal(row);
							}
						}}
						disabled={row.UsedManualRetryTrigger >= row.MaxManualRetryCount}
						>
						{localString('Send Reminder')}
					</button>
				</div>
				<div>
					<div className="btn btn-download" onClick={() => getOrderDeatails(row)}>
						{localString('View')}
					</div>
				</div>
			  </div>
			),
		},
		  		  
	]
    
	const handleCheckboxChange = (row) => {
		// Check if UsedManualRetryTrigger is less than 3
		if (row.UsedManualRetryTrigger < 3) {
		  const index = selectedRows.indexOf(row);
		  let updatedSelectedRows;
		
			if (index === -1) {
				updatedSelectedRows = [...selectedRows, row];
			} else {
				updatedSelectedRows = selectedRows.filter(
				(selectedRow) => selectedRow !== row
				);
			}
	  
			setSelectedRows(updatedSelectedRows);
			setIsAnyCheckboxSelected(updatedSelectedRows.length > 0 || false);
		}
	};

	const handleCopyMobileNo = (mobileNo) => {
		// Create a temporary input element
		const tempInput = document.createElement('input');
		tempInput.value = mobileNo;
		document.body.appendChild(tempInput);

		// Copy the mobile number
		tempInput.select();
		document.execCommand('copy');

		// Remove the temporary input element
		document.body.removeChild(tempInput);

		// Show a confirmation message
		Swal.fire('Customer Number Copied!', '', 'success');
	};

	const handleCopyEmailID = (emailID) => {
		const tempInput = document.createElement('input');
		tempInput.value = emailID;
		document.body.appendChild(tempInput);

		tempInput.select();
		document.execCommand('copy');

		document.body.removeChild(tempInput);

		Swal.fire('Email Address Copied!', '', 'success');
	};
  
	return (
		<div>
			<div className="card-body">
				<div className="d-flex">
					<h4 className="renew-plan-header">
							{localString("Renew Plans")}
					</h4>
				</div>
				<div className="d-flex">
					{selectedRows.some((row) => row.UsedManualRetryTrigger < 3) && (
					<div>
						<Button
						type='button'
						className='button-small servify-button'
						onClick={openModal}
						>
						{localString('Send Reminder')}
						</Button>
					</div>
					)}
					<div className="renew-plan-pagination">
						{pagination.count > 0 && (
							<Pagination
								paginationInfo={`${
									(pagination.pageNo - 1) *
										pagination.itemsPerPage +
									1
								} - ${Math.min(
									pagination.pageNo *
										pagination.itemsPerPage,
									pagination.count
								)} of ${pagination.count}`}
							/>
						)}
					</div>
				</div>
			</div>
			<CommonTable 
				columns={columns}
				data={filteredList}
				localString={localString}
			/>
			{
				pagination.numberOfPages > 1 && (
					<Pagination
						itemsPerPage={pagination.itemsPerPage}
						pages= {pagination.numberOfPages}
						totalItems={pagination.count}
						activePage={pagination.pageNo}
						paginate={(pageNumber) =>
							setPagination({ ...pagination, pageNo: pageNumber })
						}
					/>
				)
			}
			{/* Popup Modal */}
			<SendMessageModal
				{...props}
				showModal={showModal}
				closeModal={closeModal}
				messageIcon={<img src={publicUrl + "/imgs/messageIcon.png"} width={"100px"} alt="Message Icon" />}
				messageText={localString('Send Message')}
				modalText={localString('Are you sure you want to send an email and SMS to the selected customers?')}
				sendButtonText={localString('Send Message')}
				planRenewalOfferID={planRenewalOfferID}
				selectedRows={selectedRows}
				maxManualRetryCount={maxManualRetryCount} // Pass the data as a prop	
				setMaxManualRetryCount={setMaxManualRetryCount}
				reminderCounts={reminderCounts} // Pass reminderCounts as a prop
				updateReminderCounts={updateReminderCounts}
				setReminderCounts={setReminderCounts}
				findConsumerRenewals={findConsumerRenewals}
			/>
		</div>
	);
}

export default PlanRenewalGrid;