const lang = {
	"Consumers": {
		"text": "Spotřebitelé",
		"isAlreadyTranslated": true
	},
	"Clear Search": {
		"text": "Vymazat vyhledávání",
		"isAlreadyTranslated": true
	},
	"Filter Consumer": {
		"text": "Filtrovat spotřebitele",
		"isAlreadyTranslated": true
	},
	"Add Consumers": {
		"text": "Přidat spotřebitele",
		"isAlreadyTranslated": true
	},
	"Mobile": {
		"text": "Mobil",
		"isAlreadyTranslated": true
	},
	"Device Type": {
		"text": "Typ zařízení",
		"isAlreadyTranslated": true
	},
	"Mobile No.": {
		"text": "Mobilní č.",
		"isAlreadyTranslated": true
	},
	"Model": {
		"text": "Model",
		"isAlreadyTranslated": true
	},
	"Alternate No.": {
		"text": "Alternativní č.",
		"isAlreadyTranslated": true
	},
	"Landline No": {
		"text": "Č. pevné linky",
		"isAlreadyTranslated": true
	},
	"View Consumer": {
		"text": "Zobrazit spotřebitele",
		"isAlreadyTranslated": true
	},
	"Add": {
		"text": "Přidat",
		"isAlreadyTranslated": true
	},
	"Send": {
		"text": "Odeslat",
		"isAlreadyTranslated": true
	},
	"SMS": {
		"text": "SMS",
		"isAlreadyTranslated": true
	},
	"Email": {
		"text": "E-mail",
		"isAlreadyTranslated": true
	},
	"First": {
		"text": "První",
		"isAlreadyTranslated": true
	},
	"Last": {
		"text": "Poslední",
		"isAlreadyTranslated": true
	},
	"Filter": {
		"text": "Filtr",
		"isAlreadyTranslated": true
	},
	"Customer": {
		"text": "Zákazník",
		"isAlreadyTranslated": true
	},
	"Close": {
		"text": "Zavřít",
		"isAlreadyTranslated": true
	},
	"Call": {
		"text": "Protokoly",
		"isAlreadyTranslated": true
	},
	"logs": {
		"text": "hovorů",
		"isAlreadyTranslated": true
	},
	"Consumer": {
		"text": "Spotřebitel",
		"isAlreadyTranslated": true
	},
	"Subcategory": {
		"text": "Podkategorie",
		"isAlreadyTranslated": true
	},
	"Brand": {
		"text": "Značka",
		"isAlreadyTranslated": true
	},
	"Product": {
		"text": "Produkt",
		"isAlreadyTranslated": true
	},
	"Apply": {
		"text": "Použít",
		"isAlreadyTranslated": true
	},
	"Dashboard": {
		"text": "Ovládací panel",
		"isAlreadyTranslated": true
	},
	"Requests": {
		"text": "Požadavky",
		"isAlreadyTranslated": true
	},
	"View Document": {
		"text": "Zobrazit dokument",
		"isAlreadyTranslated": true
	},
	"Logout": {
		"text": "Odhlásit se",
		"isAlreadyTranslated": true
	},
	"Notifications": {
		"text": "Oznámení",
		"isAlreadyTranslated": true
	},
	"Coupons": {
		"text": "Kupóny",
		"isAlreadyTranslated": true
	},
	"UserList": {
		"text": "Seznam uživatelů",
		"isAlreadyTranslated": true
	},
	"Curate": {
		"text": "Administrátor",
		"isAlreadyTranslated": true
	},
	"Diagnosis": {
		"text": "Diagnostika",
		"isAlreadyTranslated": true
	},
	"Distributor": {
		"text": "Distributor",
		"isAlreadyTranslated": true
	},
	"Enterprise Sales": {
		"text": "Podnikový prodej",
		"isAlreadyTranslated": true
	},
	"Reseller": {
		"text": "Prodejce",
		"isAlreadyTranslated": true
	},
	"Plan Management": {
		"text": "Správa plánu",
		"isAlreadyTranslated": true
	},
	"Sell Plans": {
		"text": "Prodat plány",
		"isAlreadyTranslated": true
	},
	"Partner Management": {
		"text": "Správa partnerů",
		"isAlreadyTranslated": true
	},
	"Logistics Requests": {
		"text": "Logistické požadavky",
		"isAlreadyTranslated": true
	},
	"Catalogue": {
		"text": "Katalog",
		"isAlreadyTranslated": true
	},
	"Source": {
		"text": "Zdroj",
		"isAlreadyTranslated": true
	},
	"Device Count": {
		"text": "Počet zařízení",
		"isAlreadyTranslated": true
	},
	"Reg/Unreg": {
		"text": "Registrované/neregistrované",
		"isAlreadyTranslated": true
	},
	"Phone Number": {
		"text": "Telefonní číslo",
		"isAlreadyTranslated": true
	},
	"Mobile No": {
		"text": "Mobilní č.",
		"isAlreadyTranslated": true
	},
	"Edit": {
		"text": "Upravit",
		"isAlreadyTranslated": true
	},
	"View Request": {
		"text": "Zobrazit požadavek",
		"isAlreadyTranslated": true
	},
	"Raise A Request": {
		"text": "Podat požadavek",
		"isAlreadyTranslated": true
	},
	"Bills/Documents": {
		"text": "Účty/dokumenty",
		"isAlreadyTranslated": true
	},
	"Explore Device Lifecycle": {
		"text": "Prozkoumat životní cyklus zařízení",
		"isAlreadyTranslated": true
	},
	"History": {
		"text": "Historie",
		"isAlreadyTranslated": true
	},
	"Year Of Purchase": {
		"text": "Rok nákupu",
		"isAlreadyTranslated": true
	},
	"Date Of Purchase": {
		"text": "Datum nákupu",
		"isAlreadyTranslated": true
	},
	"IMEI": {
		"text": "IMEI",
		"isAlreadyTranslated": true
	},
	"Serial No.:": {
		"text": "Sériové č.:",
		"isAlreadyTranslated": true
	},
	"Initiate diagnosis": {
		"text": "Zahájit diagnostiku",
		"isAlreadyTranslated": true
	},
	"Initiate diagnosis directly in the Servify App": {
		"text": "Zahájit diagnostiku přímo v aplikaci Servify",
		"isAlreadyTranslated": true
	},
	"No diagnosis performed": {
		"text": "Diagnostika nebyla provedena",
		"isAlreadyTranslated": true
	},
	"View Diagnostic Result": {
		"text": "Zobrazit výsledek diagnostiky",
		"isAlreadyTranslated": true
	},
	"Last Diagnosis Performed on": {
		"text": "Poslední diagnostika provedena dne",
		"isAlreadyTranslated": true
	},
	"No Issues Found": {
		"text": "Nebyly nalezeny žádné problémy",
		"isAlreadyTranslated": true
	},
	"Found": {
		"text": "Nalezen",
		"isAlreadyTranslated": true
	},
	"Issue": {
		"text": "Problém",
		"isAlreadyTranslated": true
	},
	"Created Date": {
		"text": "Datum vytvoření",
		"isAlreadyTranslated": true
	},
	"Model No": {
		"text": "Č. modelu",
		"isAlreadyTranslated": true
	},
	"Tag Name": {
		"text": "Název značky",
		"isAlreadyTranslated": true
	},
	"Product Subcategory": {
		"text": "Podkategorie produktů",
		"isAlreadyTranslated": true
	},
	"Created On": {
		"text": "Datum vytvoření",
		"isAlreadyTranslated": true
	},
	"Alternate Mobile No": {
		"text": "Alternativní mobilní č.",
		"isAlreadyTranslated": true
	},
	"Add Product": {
		"text": "Přidat produkt",
		"isAlreadyTranslated": true
	},
	"Upload Documents": {
		"text": "Nahrát dokumenty",
		"isAlreadyTranslated": true
	},
	"Product Name": {
		"text": "Název produktu",
		"isAlreadyTranslated": true
	},
	"Product SubCategory": {
		"text": "Podkategorie produktů",
		"isAlreadyTranslated": true
	},
	"View Consumer Details": {
		"text": "Zobrazit detaily spotřebitele",
		"isAlreadyTranslated": true
	},
	"Call History": {
		"text": "Historie hovorů",
		"isAlreadyTranslated": true
	},
	"Unregistered Products": {
		"text": "Neregistrované produkty",
		"isAlreadyTranslated": true
	},
	"Registered Products": {
		"text": "Registrované produkty",
		"isAlreadyTranslated": true
	},
	"No unregistered products found": {
		"text": "Nebyly nalezeny žádné neregistrované produkty",
		"isAlreadyTranslated": true
	},
	"Content": {
		"text": "Obsah",
		"isAlreadyTranslated": true
	},
	"Customer Name": {
		"text": "Jméno zákazníka",
		"isAlreadyTranslated": true
	},
	"Reference ID": {
		"text": "Referenční ID",
		"isAlreadyTranslated": true
	},
	"Scheduled Date": {
		"text": "Plánované datum",
		"isAlreadyTranslated": true
	},
	"Type": {
		"text": "Typ",
		"isAlreadyTranslated": true
	},
	"Add call log": {
		"text": "Přidat protokol hovorů",
		"isAlreadyTranslated": true
	},
	"Call type": {
		"text": "Typ hovoru",
		"isAlreadyTranslated": true
	},
	"Remarks": {
		"text": "Poznámky",
		"isAlreadyTranslated": true
	},
	"No call history": {
		"text": "Žádná historie hovorů",
		"isAlreadyTranslated": true
	},
	"Please enter alphabets only": {
		"text": "Můžete zadat pouze písmena",
		"isAlreadyTranslated": true
	},
	"Please enter correct EmailID": {
		"text": "Zadejte správnou e-mailovou adresu",
		"isAlreadyTranslated": true
	},
	"Please enter correct Name": {
		"text": "Zadejte správné jméno",
		"isAlreadyTranslated": true
	},
	"Please enter correct Alternate Mobile Number": {
		"text": "Zadejte správné alternativní mobilní číslo",
		"isAlreadyTranslated": true
	},
	"Incoming": {
		"text": "Příchozí",
		"isAlreadyTranslated": true
	},
	"Outgoing": {
		"text": "Odchozí",
		"isAlreadyTranslated": true
	},
	"No": {
		"text": "Č.",
		"isAlreadyTranslated": true
	},
	"Call history": {
		"text": "Historie hovorů",
		"isAlreadyTranslated": true
	},
	"No Data Available": {
		"text": "Nejsou k dispozici žádná data",
		"isAlreadyTranslated": true
	},
	"Select a model": {
		"text": "Vyberte model",
		"isAlreadyTranslated": true
	},
	"Customer Alternate Mobile No": {
		"text": "Alternativní mobilní č. zákazníka",
		"isAlreadyTranslated": true
	},
	"Incident ID": {
		"text": "ID incidentu",
		"isAlreadyTranslated": true
	},
	"Select an issue": {
		"text": "Vyberte problém",
		"isAlreadyTranslated": true
	},
	"Please enter Customer's Name": {
		"text": "Zadejte jméno zákazníka",
		"isAlreadyTranslated": true
	},
	"Please select a model from the dropdown": {
		"text": "Vyberte z rozevíracího seznamu model",
		"isAlreadyTranslated": true
	},
	"Please enter at least one issue": {
		"text": "Zadejte alespoň jeden problém",
		"isAlreadyTranslated": true
	},
	"Please select a Device Type": {
		"text": "Vyberte typ zařízení",
		"isAlreadyTranslated": true
	},
	"Please enter Mobile Number": {
		"text": "Zadejte mobilní číslo",
		"isAlreadyTranslated": true
	},
	"Please enter correct Mobile Number": {
		"text": "Zadejte správné mobilní číslo",
		"isAlreadyTranslated": true
	},
	"Please enter correct E-mail ID": {
		"text": "Zadejte správnou e-mailovou adresu",
		"isAlreadyTranslated": true
	},
	"Consumer successfully added and SMS sent": {
		"text": "Spotřebitel byl úspěšně přidán a byla odeslána SMS zpráva",
		"isAlreadyTranslated": true
	},
	"Something went wrong when trying to create a consumer. Please try again": {
		"text": "­Při pokusu o vytvoření spotřebitele se něco pokazilo. Zkuste to znovu",
		"isAlreadyTranslated": true
	},
	"You have already added this issue type": {
		"text": "Tento typ problému jste již přidali",
		"isAlreadyTranslated": true
	},
	"Please select a Product from the dropdown": {
		"text": "Vyberte z rozevíracího seznamu produkt",
		"isAlreadyTranslated": true
	},
	"Please select a Brand from the dropdown": {
		"text": "Vyberte z rozevíracího seznamu značku",
		"isAlreadyTranslated": true
	},
	"Please select a Subcategory from the dropdown": {
		"text": "Vyberte z rozevíracího seznamu podkategorii",
		"isAlreadyTranslated": true
	},
	"Select a year between": {
		"text": "Vyberte rok v rozmezí",
		"isAlreadyTranslated": true
	},
	"and": {
		"text": "a",
		"isAlreadyTranslated": true
	},
	"Please select a document type": {
		"text": "Vyberte typ dokumentu",
		"isAlreadyTranslated": true
	},
	"Please select a document": {
		"text": "Vyberte dokument",
		"isAlreadyTranslated": true
	},
	"Oops... Something went wrong": {
		"text": "Jejda... Něco se pokazilo",
		"isAlreadyTranslated": true
	},
	"No requests found": {
		"text": "Nebyly nalezeny žádné požadavky",
		"isAlreadyTranslated": true
	},
	"OK": {
		"text": "OK",
		"isAlreadyTranslated": true
	},
	"REQUESTS": {
		"text": "POŽADAVKY",
		"isAlreadyTranslated": true
	},
	"Claim Request": {
		"text": "Reklamační požadavek",
		"isAlreadyTranslated": true
	},
	"In Progress": {
		"text": "Zpracovává se",
		"isAlreadyTranslated": true
	},
	"Closed": {
		"text": "Uzavřeno",
		"isAlreadyTranslated": true
	},
	"Cancelled": {
		"text": "Zrušeno",
		"isAlreadyTranslated": true
	},
	"Open": {
		"text": "Otevřeno",
		"isAlreadyTranslated": true
	},
	"Period": {
		"text": "Období",
		"isAlreadyTranslated": true
	},
	"Filter Requests": {
		"text": "Filtrovat požadavky",
		"isAlreadyTranslated": true
	},
	"Create Bulk Request": {
		"text": "Vytvořit hromadný požadavek",
		"isAlreadyTranslated": true
	},
	"Raise a request": {
		"text": "Podat požadavek",
		"isAlreadyTranslated": true
	},
	"External Requests": {
		"text": "Externí požadavky",
		"isAlreadyTranslated": true
	},
	"Parts Queue": {
		"text": "Fronta dílů",
		"isAlreadyTranslated": true
	},
	"Loading requests": {
		"text": "Načítání požadavků",
		"isAlreadyTranslated": true
	},
	"Service type": {
		"text": "Typ servisu",
		"isAlreadyTranslated": true
	},
	"Schedule": {
		"text": "Plán",
		"isAlreadyTranslated": true
	},
	"Download requests in csv format": {
		"text": "Stáhnout požadavky ve formátu CSV",
		"isAlreadyTranslated": true
	},
	"Created": {
		"text": "Vytvořeno",
		"isAlreadyTranslated": true
	},
	"Show graph": {
		"text": "Zobrazit graf",
		"isAlreadyTranslated": true
	},
	"Hide graph": {
		"text": "Skrýt graf",
		"isAlreadyTranslated": true
	},
	"out of": {
		"text": "z",
		"isAlreadyTranslated": true
	},
	"service requests are shown above.": {
		"text": "požadavků na servis je zobrazeno výše.",
		"isAlreadyTranslated": true
	},
	"Created or found consumer": {
		"text": "Vytvořený nebo nalezený spotřebitel",
		"isAlreadyTranslated": true
	},
	"Not updated": {
		"text": "Neaktualizováno",
		"isAlreadyTranslated": true
	},
	"Please select a call disposition": {
		"text": "Vyberte konfiguraci hovoru",
		"isAlreadyTranslated": true
	},
	"Please enter a name": {
		"text": "Zadejte název",
		"isAlreadyTranslated": true
	},
	"Please enter remarks": {
		"text": "Zadejte poznámky",
		"isAlreadyTranslated": true
	},
	"Username": {
		"text": "Uživatelské jméno",
		"isAlreadyTranslated": true
	},
	"Call Connected": {
		"text": "Hovor byl spojen",
		"isAlreadyTranslated": true
	},
	"Connection Disposition:": {
		"text": "Konfigurace spojení:",
		"isAlreadyTranslated": true
	},
	"Non-Connect Disposition:": {
		"text": "Konfigurace bez spojení:",
		"isAlreadyTranslated": true
	},
	"Date and Time of loss/Damage": {
		"text": "Datum a čas ztráty/poškození",
		"isAlreadyTranslated": true
	},
	"Description of Loss/Damage": {
		"text": "Popis ztráty/poškození",
		"isAlreadyTranslated": true
	},
	"Date and Time of Call": {
		"text": "Datum a čas hovoru",
		"isAlreadyTranslated": true
	},
	"Status at which call was made": {
		"text": "Stav, ve kterém hovor proběhl",
		"isAlreadyTranslated": true
	},
	"BER Refund": {
		"text": "Vrácení v případě ekonomické neopravitelnosti",
		"isAlreadyTranslated": true
	},
	"Action Status": {
		"text": "Stav akce",
		"isAlreadyTranslated": true
	},
	"Action taken": {
		"text": "Provedená akce",
		"isAlreadyTranslated": true
	},
	"Rating": {
		"text": "Hodnocení",
		"isAlreadyTranslated": true
	},
	"Reassign": {
		"text": "Znovu přiřadit",
		"isAlreadyTranslated": true
	},
	"Parts received": {
		"text": "Díly přijaty",
		"isAlreadyTranslated": true
	},
	"Address Details": {
		"text": "Detaily adresy",
		"isAlreadyTranslated": true
	},
	"Raise Request": {
		"text": "Podat požadavek",
		"isAlreadyTranslated": true
	},
	"Please fill in the cancel reason before cancelling": {
		"text": "Před zrušením vyplňte důvod zrušení",
		"isAlreadyTranslated": true
	},
	"Add required documents in 'Request Documents' section below": {
		"text": "Přidejte do sekce „Dokumenty k požadavku“ požadované dokumenty",
		"isAlreadyTranslated": true
	},
	"Customer Details": {
		"text": "Detaily zákazníka",
		"isAlreadyTranslated": true
	},
	"Mobile Number": {
		"text": "Mobilní číslo",
		"isAlreadyTranslated": true
	},
	"First Registered From": {
		"text": "První registrovaný formulář",
		"isAlreadyTranslated": true
	},
	"No saved Address": {
		"text": "Žádné uložené adresy",
		"isAlreadyTranslated": true
	},
	"Under warranty": {
		"text": "V záruce",
		"isAlreadyTranslated": true
	},
	"Plan Name": {
		"text": "Název plánu",
		"isAlreadyTranslated": true
	},
	"Purchase Price": {
		"text": "Nákupní cena",
		"isAlreadyTranslated": true
	},
	"Reason": {
		"text": "Důvod",
		"isAlreadyTranslated": true
	},
	"Registration Documents": {
		"text": "Dokumenty k registraci",
		"isAlreadyTranslated": true
	},
	"Validated": {
		"text": "Ověřeno",
		"isAlreadyTranslated": true
	},
	"Not Validated": {
		"text": "Neověřeno",
		"isAlreadyTranslated": true
	},
	"Verify": {
		"text": "Ověřit",
		"isAlreadyTranslated": true
	},
	"Please enter IMEI/serial number": {
		"text": "Zadejte číslo IMEI / sériové číslo",
		"isAlreadyTranslated": true
	},
	"Please enter serial number": {
		"text": "Zadejte sériové číslo",
		"isAlreadyTranslated": true
	},
	"Please enter warranty till date": {
		"text": "Zadejte datum konce záruky",
		"isAlreadyTranslated": true
	},
	"Please upload a warranty supporting document": {
		"text": "Nahrajte záruční dokument",
		"isAlreadyTranslated": true
	},
	"Please choose if the warranty is applicable or not": {
		"text": "Vyberte, zda lze uplatnit záruku nebo ne",
		"isAlreadyTranslated": true
	},
	"Please enter the reason for warranty not being applicable": {
		"text": "Uveďte důvod, proč nelze záruku uplatnit",
		"isAlreadyTranslated": true
	},
	"Remark": {
		"text": "Poznámka",
		"isAlreadyTranslated": true
	},
	"Add Pending Documents": {
		"text": "Přidat nevyřízené dokumenty",
		"isAlreadyTranslated": true
	},
	"Add Additional Documents": {
		"text": "Přidat další dokumenty",
		"isAlreadyTranslated": true
	},
	"Add New Issues": {
		"text": "Přidat nové problémy",
		"isAlreadyTranslated": true
	},
	"Total Payable": {
		"text": "Celkem k úhradě",
		"isAlreadyTranslated": true
	},
	"Covered Value": {
		"text": "Krytá hodnota",
		"isAlreadyTranslated": true
	},
	"Send For Approval": {
		"text": "Odeslat ke schválení",
		"isAlreadyTranslated": true
	},
	"Advance cannot be greater than": {
		"text": "Záloha nesmí být větší než",
		"isAlreadyTranslated": true
	},
	"Discount cannot be greater than": {
		"text": "Sleva nesmí být větší než",
		"isAlreadyTranslated": true
	},
	"Reassigned": {
		"text": "Znovu přiřazeno",
		"isAlreadyTranslated": true
	},
	"Pickup Address": {
		"text": "Adresa vyzvednutí",
		"isAlreadyTranslated": true
	},
	"Address": {
		"text": "Adresa",
		"isAlreadyTranslated": true
	},
	"Telecon Version": {
		"text": "Verze Telecon",
		"isAlreadyTranslated": true
	},
	"Recycle Certificate": {
		"text": "Certifikát o recyklaci",
		"isAlreadyTranslated": true
	},
	"Pick Up Confirmation Number": {
		"text": "Číslo potvrzení o vyzvednutí",
		"isAlreadyTranslated": true
	},
	"Pickup Charge": {
		"text": "Poplatek za vyzvednutí",
		"isAlreadyTranslated": true
	},
	"Payable": {
		"text": "Částka k úhradě",
		"isAlreadyTranslated": true
	},
	"Place of Damage": {
		"text": "Místo poškození",
		"isAlreadyTranslated": true
	},
	"Please enter issue description": {
		"text": "Zadejte popis problému",
		"isAlreadyTranslated": true
	},
	"Request History": {
		"text": "Historie požadavků",
		"isAlreadyTranslated": true
	},
	"Waiting for Assignment": {
		"text": "Čeká na přiřazení",
		"isAlreadyTranslated": true
	},
	"BER confirmed": {
		"text": "Oprava ve stavu ekonomické neopravitelnosti byla potvrzena",
		"isAlreadyTranslated": true
	},
	"BER approved": {
		"text": "Ekonomická neopravitelnost byla schválena",
		"isAlreadyTranslated": true
	},
	"BER identified": {
		"text": "Byla zjištěna ekonomická neopravitelnost",
		"isAlreadyTranslated": true
	},
	"Device received": {
		"text": "Zařízení bylo přijato",
		"isAlreadyTranslated": true
	},
	"Pickup Request Accepted": {
		"text": "Požadavek na vyzvednutí byl přijat",
		"isAlreadyTranslated": true
	},
	"We have received your claim request. Our representative will connect with you within the next 1-2 business days to process it further": {
		"text": "Přijali jsme váš požadavek na reklamaci. Náš pracovník se s vámi spojí do 1–2 pracovních dnů, aby bylo možné pokračovat dále",
		"isAlreadyTranslated": true
	},
	"Device has been received by the service centre and is currently being diagnosed.": {
		"text": "Zařízení bylo přijato do servisního střediska a momentálně probíhá jeho diagnostika.",
		"isAlreadyTranslated": true
	},
	"Looks like the repair cost is more than the depreciated value of your device. Please select the option you would like to proceed with.": {
		"text": "Zdá se, že cena opravy je vyšší než zůstatková hodnota vašeho zařízení. Vyberte jednu z možností, jak chcete pokračovat dále.",
		"isAlreadyTranslated": true
	},
	"Carry in": {
		"text": "Donáška",
		"isAlreadyTranslated": true
	},
	"Assigned To": {
		"text": "Přiřazeno k",
		"isAlreadyTranslated": true
	},
	"ADD CHARGES": {
		"text": "PŘIDAT POPLATKY",
		"isAlreadyTranslated": true
	},
	"Amount": {
		"text": "Částka",
		"isAlreadyTranslated": true
	},
	"Attempt Call": {
		"text": "Pokusit se o telefonát",
		"isAlreadyTranslated": true
	},
	"Add Device Date of Purchase and Device Purchase Cost in 'Device Details' below": {
		"text": "Zadejte do sekce „Detaily zařízení“ níže datum nákupu zařízení a nákupní cenu zařízení",
		"isAlreadyTranslated": true
	},
	"Enter type of document to add": {
		"text": "Zadejte typ dokumentu, který chcete přidat",
		"isAlreadyTranslated": true
	},
	"Please enter Claim intimation number": {
		"text": "Zadejte číslo oznámení reklamace",
		"isAlreadyTranslated": true
	},
	"Please enter Claim intimation number before submitting": {
		"text": "Před odesláním zadejte číslo oznámení reklamace",
		"isAlreadyTranslated": true
	},
	"Data displayed": {
		"text": "Data jsou zobrazena",
		"isAlreadyTranslated": true
	},
	"from": {
		"text": "od",
		"isAlreadyTranslated": true
	},
	"till": {
		"text": "do",
		"isAlreadyTranslated": true
	},
	"Please upload a clear copy of cancelled cheque with the claimant (your) name on it.": {
		"text": "Nahrajte čitelnou kopii zrušeného tiketu s uvedeným jménem reklamujícího (vaším jménem).",
		"isAlreadyTranslated": true
	},
	"Customer Mobile number": {
		"text": "Mobilní číslo zákazníka",
		"isAlreadyTranslated": true
	},
	"TRACK EXTERNAL REQUEST": {
		"text": "SLEDOVAT EXTERNÍ POŽADAVEK",
		"isAlreadyTranslated": true
	},
	"Oops... Something went wrong!": {
		"text": "Jejda... Něco se pokazilo!",
		"isAlreadyTranslated": true
	},
	"an address": {
		"text": "adresa",
		"isAlreadyTranslated": true
	},
	"Pincode": {
		"text": "Kód PIN",
		"isAlreadyTranslated": true
	},
	"Clear": {
		"text": "Vymazat",
		"isAlreadyTranslated": true
	},
	"Cancel": {
		"text": "Zrušit",
		"isAlreadyTranslated": true
	},
	"Please enter Landmark": {
		"text": "Zadejte orientační bod",
		"isAlreadyTranslated": true
	},
	"Please enter Customer's address": {
		"text": "Zadejte adresu zákazníka",
		"isAlreadyTranslated": true
	},
	"Please enter pincode": {
		"text": "Zadejte kód PIN",
		"isAlreadyTranslated": true
	},
	"Enter correct Pincode": {
		"text": "Zadejte správný kód PIN",
		"isAlreadyTranslated": true
	},
	"Address updated successfully": {
		"text": "Adresa byla úspěšně aktualizována",
		"isAlreadyTranslated": true
	},
	"Documents Pending": {
		"text": "Dokumenty čekají na vyřízení",
		"isAlreadyTranslated": true
	},
	"Date and Time of loss/damage": {
		"text": "Datum a čas ztráty/poškození",
		"isAlreadyTranslated": true
	},
	"Please enter UTR Number": {
		"text": "Zadejte číslo UTR",
		"isAlreadyTranslated": true
	},
	"Payment Date": {
		"text": "Datum platby",
		"isAlreadyTranslated": true
	},
	"UTR Number": {
		"text": "Číslo UTR",
		"isAlreadyTranslated": true
	},
	"Service Charge": {
		"text": "Poplatek na servis",
		"isAlreadyTranslated": true
	},
	"SGST": {
		"text": "SGST",
		"isAlreadyTranslated": true
	},
	"CGST": {
		"text": "CGST",
		"isAlreadyTranslated": true
	},
	"TESTING": {
		"text": "TESTOVÁNÍ",
		"isAlreadyTranslated": true
	},
	"Mandatory deductible": {
		"text": "Povinný odpočet",
		"isAlreadyTranslated": true
	},
	"Admin fees": {
		"text": "Administrativní poplatky",
		"isAlreadyTranslated": true
	},
	"Claim amount": {
		"text": "Částka reklamace",
		"isAlreadyTranslated": true
	},
	"Advance payment": {
		"text": "Zálohová platba",
		"isAlreadyTranslated": true
	},
	"Advance": {
		"text": "Záloha",
		"isAlreadyTranslated": true
	},
	"Alternate Mobile Number": {
		"text": "Alternativní mobilní číslo",
		"isAlreadyTranslated": true
	},
	"Please enter correct mobile Number": {
		"text": "Zadejte správné mobilní číslo",
		"isAlreadyTranslated": true
	},
	"Please Enter Minimum 75 Characters": {
		"text": "Zadejte alespoň 75 znaků",
		"isAlreadyTranslated": true
	},
	"Add an address": {
		"text": "Přidejte adresu",
		"isAlreadyTranslated": true
	},
	"Add New Address": {
		"text": "Přidat novou adresu",
		"isAlreadyTranslated": true
	},
	"Oops! There are no verified service centres around": {
		"text": "Jejda! V okolí nejsou žádná ověřená servisní střediska",
		"isAlreadyTranslated": true
	},
	"Cannot select more than": {
		"text": "Nelze vybrat více než",
		"isAlreadyTranslated": true
	},
	"issues": {
		"text": "problémů",
		"isAlreadyTranslated": true
	},
	"Add a Product": {
		"text": "Přidat produkt",
		"isAlreadyTranslated": true
	},
	"Add Selected Product": {
		"text": "Přidat vybraný produkt",
		"isAlreadyTranslated": true
	},
	"Device In Warranty": {
		"text": "Zařízení v záruce",
		"isAlreadyTranslated": true
	},
	"Please Select Damage Date": {
		"text": "Vyberte datum poškození",
		"isAlreadyTranslated": true
	},
	"Is your device switching ON": {
		"text": "Lze zařízení zapnout?",
		"isAlreadyTranslated": true
	},
	"Characters must be numeric": {
		"text": "Znaky musí být číslice",
		"isAlreadyTranslated": true
	},
	"products under service": {
		"text": "produkty v servisu",
		"isAlreadyTranslated": true
	},
	"Please Select Time": {
		"text": "Vyberte čas",
		"isAlreadyTranslated": true
	},
	"Please Select Damage type": {
		"text": "Vyberte typ poškození",
		"isAlreadyTranslated": true
	},
	"Please Enter Place of Damage": {
		"text": "Zadejte místo poškození",
		"isAlreadyTranslated": true
	},
	"Please Select an option": {
		"text": "Vyberte variantu",
		"isAlreadyTranslated": true
	},
	"Consumer Name": {
		"text": "Jméno spotřebitele",
		"isAlreadyTranslated": true
	},
	"Contact No": {
		"text": "Č. kontaktní osoby",
		"isAlreadyTranslated": true
	},
	"Logistics Provider": {
		"text": "Poskytovatel logistických služeb",
		"isAlreadyTranslated": true
	},
	"Registration Source": {
		"text": "Zdroj registrace",
		"isAlreadyTranslated": true
	},
	"Customer City": {
		"text": "Město zákazníka",
		"isAlreadyTranslated": true
	},
	"Call Type": {
		"text": "Typ hovoru",
		"isAlreadyTranslated": true
	},
	"Re-apply": {
		"text": "Znovu použít",
		"isAlreadyTranslated": true
	},
	"something went wrong": {
		"text": "něco se pokazilo",
		"isAlreadyTranslated": true
	},
	"Today": {
		"text": "Dnes",
		"isAlreadyTranslated": true
	},
	"Yesterday": {
		"text": "Včera",
		"isAlreadyTranslated": true
	},
	"Last 7 Days": {
		"text": "Posledních 7 dnů",
		"isAlreadyTranslated": true
	},
	"Last 30 Days": {
		"text": "Posledních 30 dnů",
		"isAlreadyTranslated": true
	},
	"This Month": {
		"text": "Tento měsíc",
		"isAlreadyTranslated": true
	},
	"Last Month": {
		"text": "Minulý měsíc",
		"isAlreadyTranslated": true
	},
	"Custom Range": {
		"text": "Vlastní rozsah",
		"isAlreadyTranslated": true
	},
	"Add a part charge in 'Estimation Details' below to generate estimation": {
		"text": "Přidejte do sekce „Detaily odhadu“ níže poplatek za díl a vygenerujte odhad",
		"isAlreadyTranslated": true
	},
	"Pick Up Request Reference ID": {
		"text": "Referenční ID požadavku na vyzvednutí",
		"isAlreadyTranslated": true
	},
	"Convert request": {
		"text": "Převést požadavek",
		"isAlreadyTranslated": true
	},
	"No Data Available To Plot The Graph": {
		"text": "Nejsou k dispozici žádná data pro vykreslení grafu",
		"isAlreadyTranslated": true
	},
	"to": {
		"text": "do",
		"isAlreadyTranslated": true
	},
	"Download requests in csv format.": {
		"text": "Stáhnout požadavky ve formátu CSV.",
		"isAlreadyTranslated": true
	},
	"Service cancelled": {
		"text": "Servis byl zrušen",
		"isAlreadyTranslated": true
	},
	"Search consumer products": {
		"text": "Hledat produkty spotřebitele",
		"isAlreadyTranslated": true
	},
	"Serial number": {
		"text": "Sériové číslo",
		"isAlreadyTranslated": true
	},
	"Enter Serial Number": {
		"text": "Zadat sériové číslo",
		"isAlreadyTranslated": true
	},
	"Select Customer Type": {
		"text": "Vybrat typ zákazníka",
		"isAlreadyTranslated": true
	},
	"Walk-in Customer": {
		"text": "Zákazník na místě servisu",
		"isAlreadyTranslated": true
	},
	"Future Appointment": {
		"text": "Schůzka v budoucnu",
		"isAlreadyTranslated": true
	},
	"Tablet": {
		"text": "Tablet",
		"isAlreadyTranslated": true
	},
	"Computer": {
		"text": "Počítač",
		"isAlreadyTranslated": true
	},
	"Audio Product": {
		"text": "Audio zařízení",
		"isAlreadyTranslated": true
	},
	"Streaming Device": {
		"text": "Streamovací zařízení",
		"isAlreadyTranslated": true
	},
	"Smart Watch": {
		"text": "Chytré hodinky",
		"isAlreadyTranslated": true
	},
	"Serial number is mandatory for Apple Products.\nPlease enter valid serial number": {
		"text": "Sériové číslo je u produktů Apple povinné.\nZadejte platné sériové číslo",
		"isAlreadyTranslated": true
	},
	"Back camera issue": {
		"text": "Problém se zadním fotoaparátem",
		"isAlreadyTranslated": true
	},
	"Charger not working": {
		"text": "Nefunguje nabíječka",
		"isAlreadyTranslated": true
	},
	"Noise issue": {
		"text": "Problém se šumem",
		"isAlreadyTranslated": true
	},
	"Network Issue": {
		"text": "Problém se sítí",
		"isAlreadyTranslated": true
	},
	"External storage issue": {
		"text": "Problém s externím úložištěm",
		"isAlreadyTranslated": true
	},
	"Internal data storage issue": {
		"text": "Problém s interním datovým úložištěm",
		"isAlreadyTranslated": true
	},
	"Blur display": {
		"text": "Rozostřený displej",
		"isAlreadyTranslated": true
	},
	"Flickering": {
		"text": "Blikání",
		"isAlreadyTranslated": true
	},
	"Improper display": {
		"text": "Nesprávné zobrazení",
		"isAlreadyTranslated": true
	},
	"Spot on display": {
		"text": "Tečka na displeji",
		"isAlreadyTranslated": true
	},
	"Electric Shock": {
		"text": "Úraz elektrickým proudem",
		"isAlreadyTranslated": true
	},
	"Body damaged": {
		"text": "Poškozené tělo",
		"isAlreadyTranslated": true
	},
	"Display damaged": {
		"text": "Poškozený displej",
		"isAlreadyTranslated": true
	},
	"Intermittently powering off": {
		"text": "Příležitostné vypínání",
		"isAlreadyTranslated": true
	},
	"Not charging": {
		"text": "Zařízení se nenabíjí",
		"isAlreadyTranslated": true
	},
	"Not powering off": {
		"text": "Zařízení se nevypíná",
		"isAlreadyTranslated": true
	},
	"Cracking sound": {
		"text": "Praskavý zvuk",
		"isAlreadyTranslated": true
	},
	"Less sound": {
		"text": "Tichý zvuk",
		"isAlreadyTranslated": true
	},
	"Slow processing or hangs": {
		"text": "Pomalé zpracování nebo zamrznutí",
		"isAlreadyTranslated": true
	},
	"Pickup Drop By": {
		"text": "Vyzvednutí/doručení",
		"isAlreadyTranslated": true
	},
	"Qty": {
		"text": "Mn.",
		"isAlreadyTranslated": true
	},
	"Available Quantity": {
		"text": "Dostupné množství",
		"isAlreadyTranslated": true
	},
	"Issued By": {
		"text": "Vydáno",
		"isAlreadyTranslated": true
	},
	"Handover Device": {
		"text": "Předat zařízení",
		"isAlreadyTranslated": true
	},
	"Not applicable": {
		"text": "Nelze použít",
		"isAlreadyTranslated": true
	},
	"Pending for assignment": {
		"text": "Čeká na přiřazení",
		"isAlreadyTranslated": true
	},
	"Device handover details": {
		"text": "Detaily přejímky zařízení",
		"isAlreadyTranslated": true
	},
	"Diagnosis details": {
		"text": "Detaily diagnostiky",
		"isAlreadyTranslated": true
	},
	"Diagnosis Steps": {
		"text": "Kroky diagnostiky",
		"isAlreadyTranslated": true
	},
	"Engineer notes": {
		"text": "Poznámky technika",
		"isAlreadyTranslated": true
	},
	"Device Transaction Details": {
		"text": "Detaily transakce se zařízením",
		"isAlreadyTranslated": true
	},
	"Upload Device Pictures and Complete Checklist": {
		"text": "Nahrát obrázky zařízení a vyplnit kontrolní seznam",
		"isAlreadyTranslated": true
	},
	"Upload Device Pictures": {
		"text": "Nahrát obrázky zařízení",
		"isAlreadyTranslated": true
	},
	"Select OS Version": {
		"text": "Vybrat verzi OS",
		"isAlreadyTranslated": true
	},
	"Select Picture Type": {
		"text": "Vybrat typ obrázku",
		"isAlreadyTranslated": true
	},
	"No pictures added.": {
		"text": "Nebyly přidány žádné obrázky.",
		"isAlreadyTranslated": true
	},
	"Complete Checklist": {
		"text": "Vyplnit kontrolní seznam",
		"isAlreadyTranslated": true
	},
	"Accessories Submitted": {
		"text": "Předané příslušenství",
		"isAlreadyTranslated": true
	},
	"Checklist": {
		"text": "Kontrolní seznam",
		"isAlreadyTranslated": true
	},
	"Miscellaneous Checklist": {
		"text": "Doplňující kontrolní seznam",
		"isAlreadyTranslated": true
	},
	"Complete": {
		"text": "Dokončit",
		"isAlreadyTranslated": true
	},
	"Select All": {
		"text": "Vybrat vše",
		"isAlreadyTranslated": true
	},
	"Raise in": {
		"text": "Podat v",
		"isAlreadyTranslated": true
	},
	"Request in": {
		"text": "Požádat v",
		"isAlreadyTranslated": true
	},
	"Please select Repair Type": {
		"text": "Vyberte typ opravy",
		"isAlreadyTranslated": true
	},
	"Please select Classification Type": {
		"text": "Vyberte typ klasifikace",
		"isAlreadyTranslated": true
	},
	"Repair Checklist": {
		"text": "Kontrolní seznam opravy",
		"isAlreadyTranslated": true
	},
	"Raise": {
		"text": "Podat",
		"isAlreadyTranslated": true
	},
	"Request": {
		"text": "Požadavek",
		"isAlreadyTranslated": true
	},
	"Diagnostics Result": {
		"text": "Výsledek diagnostiky",
		"isAlreadyTranslated": true
	},
	"Please mention the incident in not less than 75 characters on how, when & where the damage happened": {
		"text": "Popište incident pomocí nejvýše 75 znaků; uveďte jak, kdy a kde k poškození došlo",
		"isAlreadyTranslated": true
	},
	"Parts transaction details": {
		"text": "Detaily transakce s díly",
		"isAlreadyTranslated": true
	},
	"Handing over the parts to store": {
		"text": "Předání dílů do prodejny",
		"isAlreadyTranslated": true
	},
	"Handing over the damaged device to store": {
		"text": "Předání poškozeného zařízení do prodejny",
		"isAlreadyTranslated": true
	},
	"Details of the New Replaced Device": {
		"text": "Detaily nového vyměněného zařízení",
		"isAlreadyTranslated": true
	},
	"Mode of payment": {
		"text": "Platební metoda",
		"isAlreadyTranslated": true
	},
	"Please select mode of payment": {
		"text": "Vyberte platební metodu",
		"isAlreadyTranslated": true
	},
	"Cash": {
		"text": "Hotovost",
		"isAlreadyTranslated": true
	},
	"Card": {
		"text": "Karta",
		"isAlreadyTranslated": true
	},
	"Accessories will not be returned": {
		"text": "Příslušenství nebude vráceno",
		"isAlreadyTranslated": true
	},
	"Documents are yet to be uploaded.": {
		"text": "Dokumenty je třeba teprve nahrát.",
		"isAlreadyTranslated": true
	},
	"Report Issues": {
		"text": "Nahlásit problémy",
		"isAlreadyTranslated": true
	},
	"Re-assign": {
		"text": "Znovu přiřadit",
		"isAlreadyTranslated": true
	},
	"Add Required Parts": {
		"text": "Přidat požadované díly",
		"isAlreadyTranslated": true
	},
	"Price": {
		"text": "Cena",
		"isAlreadyTranslated": true
	},
	"Tax Slab": {
		"text": "Daňové pásmo",
		"isAlreadyTranslated": true
	},
	"Is Part Abused": {
		"text": "Došlo ke zneužití dílu?",
		"isAlreadyTranslated": true
	},
	"Is Returnable Damage": {
		"text": "Je poškození vratné?",
		"isAlreadyTranslated": true
	},
	"Part Warranty": {
		"text": "Záruka na díl",
		"isAlreadyTranslated": true
	},
	"Part Price": {
		"text": "Cena dílu",
		"isAlreadyTranslated": true
	},
	"Available Qty": {
		"text": "Dostupné mn.",
		"isAlreadyTranslated": true
	},
	"Action": {
		"text": "Akce",
		"isAlreadyTranslated": true
	},
	"Report Issues to": {
		"text": "Nahlásit problémy",
		"isAlreadyTranslated": true
	},
	"Receive & Inspect Device": {
		"text": "Přijmout a provést inspekci zařízení",
		"isAlreadyTranslated": true
	},
	"Please select a picture type from dropdown": {
		"text": "Vyberte z rozevíracího seznamu typ obrázku",
		"isAlreadyTranslated": true
	},
	"Waybill": {
		"text": "Nákladní list",
		"isAlreadyTranslated": true
	},
	"Vendor": {
		"text": "Dodavatel",
		"isAlreadyTranslated": true
	},
	"Pick Up": {
		"text": "Vyzvednutí",
		"isAlreadyTranslated": true
	},
	"Is Covered By ACPlus": {
		"text": "Lze uplatnit ACPlus",
		"isAlreadyTranslated": true
	},
	"Re-assign Service Engineer": {
		"text": "Znovu přiřadit servisního technika",
		"isAlreadyTranslated": true
	},
	"Device Received & Inspection in Progress": {
		"text": "Zařízení bylo přijato a probíhá inspekce",
		"isAlreadyTranslated": true
	},
	"Service accepted": {
		"text": "Servis byl přijat",
		"isAlreadyTranslated": true
	},
	"COMPTIA Modifier": {
		"text": "Modifikátor CompTIA",
		"isAlreadyTranslated": true
	},
	"Send Issues to": {
		"text": "Odeslat problémy do",
		"isAlreadyTranslated": true
	},
	"Engineer validated issues": {
		"text": "Problémy ověřené technikem",
		"isAlreadyTranslated": true
	},
	"Part(s) request under review by storekeeper.": {
		"text": "Požadavek na díl(y) je kontrolován pracovníkem prodejny.",
		"isAlreadyTranslated": true
	},
	"ChargeCode": {
		"text": "Kód poplatku",
		"isAlreadyTranslated": true
	},
	"TaxSlab": {
		"text": "Daňové pásmo",
		"isAlreadyTranslated": true
	},
	"Are you sure?": {
		"text": "Jste si jisti?",
		"isAlreadyTranslated": true
	},
	"Are you sure..?": {
		"text": "Jste si jisti?",
		"isAlreadyTranslated": true
	},
	"Part name": {
		"text": "Název dílu",
		"isAlreadyTranslated": true
	},
	"Ref Id": {
		"text": "Ref. ID",
		"isAlreadyTranslated": true
	},
	"Validation Status": {
		"text": "Stav ověření",
		"isAlreadyTranslated": true
	},
	"Is warranty applicable": {
		"text": "Lze uplatnit záruku?",
		"isAlreadyTranslated": true
	},
	"Issue Code": {
		"text": "Kód problému",
		"isAlreadyTranslated": true
	},
	"Symptom Code": {
		"text": "Kód příznaku",
		"isAlreadyTranslated": true
	},
	"Other Charge": {
		"text": "Jiný poplatek",
		"isAlreadyTranslated": true
	},
	"Discount": {
		"text": "Sleva",
		"isAlreadyTranslated": true
	},
	"Engineer completed the repair. Handed over the device to QC.": {
		"text": "Technik dokončil opravu. Zařízení předal na kontrolu kvality.",
		"isAlreadyTranslated": true
	},
	"Device handed over": {
		"text": "Zařízení bylo předáno",
		"isAlreadyTranslated": true
	},
	"Repair completed, QC pending": {
		"text": "Oprava byla dokončena, čeká se na kontrolu kvality",
		"isAlreadyTranslated": true
	},
	"Repair complete": {
		"text": "Oprava byla dokončena",
		"isAlreadyTranslated": true
	},
	"Device details validated": {
		"text": "Detaily zařízení byly ověřeny",
		"isAlreadyTranslated": true
	},
	"Issues validated": {
		"text": "Problémy byly ověřeny",
		"isAlreadyTranslated": true
	},
	"Work in Progress with Engineer": {
		"text": "Technik právě provádí práci",
		"isAlreadyTranslated": true
	},
	"Cannot be diagnosed": {
		"text": "Nelze provést diagnostiku",
		"isAlreadyTranslated": true
	},
	"Parts issued": {
		"text": "Díly byly vydány",
		"isAlreadyTranslated": true
	},
	"Pending for acceptance": {
		"text": "Čeká na převzetí",
		"isAlreadyTranslated": true
	},
	"Device accepted": {
		"text": "Zařízení bylo přijato",
		"isAlreadyTranslated": true
	},
	"Parts request": {
		"text": "Požadavek na díly",
		"isAlreadyTranslated": true
	},
	"Valid": {
		"text": "Platí",
		"isAlreadyTranslated": true
	},
	"Validity": {
		"text": "Platnost",
		"isAlreadyTranslated": true
	},
	"validity": {
		"text": "Platnost",
		"isAlreadyTranslated": true
	},
	"Invalid": {
		"text": "Neplatné",
		"isAlreadyTranslated": true
	},
	"Engineer Raised Issues": {
		"text": "Problémy založené technikem",
		"isAlreadyTranslated": true
	},
	"Are you sure": {
		"text": "Jste si jisti?",
		"isAlreadyTranslated": true
	},
	"Please specify remarks, if any": {
		"text": "Zadejte případné poznámky",
		"isAlreadyTranslated": true
	},
	"This service is a Return without repair": {
		"text": "Tento servis je typu vrácení bez opravy",
		"isAlreadyTranslated": true
	},
	"Others": {
		"text": "Jiné",
		"isAlreadyTranslated": true
	},
	"Service completed and rated": {
		"text": "Služba byla dokončena a ohodnocena",
		"isAlreadyTranslated": true
	},
	"Drop Request Created Post Repair": {
		"text": "Po opravě byl vytvořen požadavek na předání",
		"isAlreadyTranslated": true
	},
	"Payment received": {
		"text": "Platba přijata",
		"isAlreadyTranslated": true
	},
	"Service invoice generated": {
		"text": "Faktura za servis byla vygenerována",
		"isAlreadyTranslated": true
	},
	"Repair Completed, Ready To Inform The Customer": {
		"text": "Oprava byla dokončena, je možné informovat zákazníka",
		"isAlreadyTranslated": true
	},
	"Repair completed": {
		"text": "Oprava byla dokončena",
		"isAlreadyTranslated": true
	},
	"WarrantyConcession": {
		"text": "Uznání záruky",
		"isAlreadyTranslated": true
	},
	"Estimation generated": {
		"text": "Odhad byl vygenerován",
		"isAlreadyTranslated": true
	},
	"Estimation approval": {
		"text": "Schválení odhadu",
		"isAlreadyTranslated": true
	},
	"Parts request accepted": {
		"text": "Požadavek na díly byl přijat",
		"isAlreadyTranslated": true
	},
	"Repair estimate approved": {
		"text": "Odhad ceny opravy byl schválen",
		"isAlreadyTranslated": true
	},
	"Damaged Serial No": {
		"text": "Sériové č. poškozeného zařízení",
		"isAlreadyTranslated": true
	},
	"Replaced Serial No": {
		"text": "Sériové č. vyměněného zařízení",
		"isAlreadyTranslated": true
	},
	"Damaged IMEI": {
		"text": "IMEI poškozeného zařízení",
		"isAlreadyTranslated": true
	},
	"Replaced IMEI": {
		"text": "IMEI vyměněného zařízení",
		"isAlreadyTranslated": true
	},
	"Initiate logistics request": {
		"text": "Zahájit požadavek na logistiku",
		"isAlreadyTranslated": true
	},
	"Payment for the service has been received from the customer.": {
		"text": "Platba zákazníka za servis byla přijata.",
		"isAlreadyTranslated": true
	},
	"BER payment completed": {
		"text": "Platba za ekonomicky neopravitelné zařízení byla přijata",
		"isAlreadyTranslated": true
	},
	"BER payment initiated": {
		"text": "Platba za ekonomicky neopravitelné zařízení byla provedena",
		"isAlreadyTranslated": true
	},
	"BER device received": {
		"text": "Ekonomicky neopravitelné zařízení bylo přijato",
		"isAlreadyTranslated": true
	},
	"BER accessories validated": {
		"text": "Příslušenství k ekonomicky neopravitelnému zařízení bylo ověřeno",
		"isAlreadyTranslated": true
	},
	"BER accessories received": {
		"text": "Příslušenství k ekonomicky neopravitelnému zařízení bylo přijato",
		"isAlreadyTranslated": true
	},
	"BER documents validated": {
		"text": "Dokumenty k ekonomicky neopravitelnému zařízení byly ověřeny",
		"isAlreadyTranslated": true
	},
	"Pickup rescheduled": {
		"text": "Termín vyzvednutí byl změněn",
		"isAlreadyTranslated": true
	},
	"Reschedule requested": {
		"text": "Je požadována změna termínu",
		"isAlreadyTranslated": true
	},
	"Claim Pickup": {
		"text": "Reklamace s vyzvednutím",
		"isAlreadyTranslated": true
	},
	"Invalid IMEI or serial number": {
		"text": "Neplatné číslo IMEI nebo sériové číslo",
		"isAlreadyTranslated": true
	},
	"No serial number for product": {
		"text": "Chybí sériové číslo produktu",
		"isAlreadyTranslated": true
	},
	"Invalid product serial number": {
		"text": "Neplatné sériové číslo produktu",
		"isAlreadyTranslated": true
	},
	"Waiting for the Engineer to raise the request.": {
		"text": "Čeká se na technika, aby podal požadavek.",
		"isAlreadyTranslated": true
	},
	"getting": {
		"text": "zjišťování",
		"isAlreadyTranslated": true
	},
	"Claim Carryin": {
		"text": "Reklamace s donáškou",
		"isAlreadyTranslated": true
	},
	"Approved claim amount": {
		"text": "Schválená částka reklamace",
		"isAlreadyTranslated": true
	},
	"Payment pending": {
		"text": "Nevyřízená platba",
		"isAlreadyTranslated": true
	},
	"Work in Progress with Engineer.": {
		"text": "Technik právě provádí práci.",
		"isAlreadyTranslated": true
	},
	"Diagnosis completed": {
		"text": "Diagnostika dokončena",
		"isAlreadyTranslated": true
	},
	"Diagnosis initiated": {
		"text": "Diagnostika zahájena",
		"isAlreadyTranslated": true
	},
	"To proceed, update Device Date of Purchase and Device Purchase Cost in 'Device Details' below.": {
		"text": "Zadejte do sekce „Detaily zařízení“ níže datum nákupu zařízení a nákupní cenu zařízení, aby bylo možné pokračovat.",
		"isAlreadyTranslated": true
	},
	"Connect Disposition:": {
		"text": "Konfigurace se spojením:",
		"isAlreadyTranslated": true
	},
	"Handing over the": {
		"text": "Předání",
		"isAlreadyTranslated": true
	},
	"full box unit": {
		"text": "kompletního balení",
		"isAlreadyTranslated": true
	},
	"parts": {
		"text": "dílů",
		"isAlreadyTranslated": true
	},
	"to store.": {
		"text": "do prodejny.",
		"isAlreadyTranslated": true
	},
	"Damaged Part Imei": {
		"text": "IMEI poškozeného dílu",
		"isAlreadyTranslated": true
	},
	"Select Fault Code based on the validated issues.": {
		"text": "Vybrat kód závady na základě ověřených problémů.",
		"isAlreadyTranslated": true
	},
	"Fault Code": {
		"text": "Kód závady",
		"isAlreadyTranslated": true
	},
	"Please check following items before completing the QC.": {
		"text": "Před dokončením kontroly kvality zkontrolujte následující položky.",
		"isAlreadyTranslated": true
	},
	"QC Checklist": {
		"text": "Kontrolní seznam QC",
		"isAlreadyTranslated": true
	},
	"Oops": {
		"text": "Jejda",
		"isAlreadyTranslated": true
	},
	"Please enter type of damage": {
		"text": "Zadejte typ poškození",
		"isAlreadyTranslated": true
	},
	"Please enter atleast one document": {
		"text": "Zadejte alespoň jeden dokument",
		"isAlreadyTranslated": true
	},
	"Please enter description of loss/damage": {
		"text": "Zadejte popis ztráty/poškození",
		"isAlreadyTranslated": true
	},
	"Please enter Date and Time of loss/damage": {
		"text": "Zadejte datum a čas ztráty/poškození",
		"isAlreadyTranslated": true
	},
	"Please enter Disposition reason": {
		"text": "Zadejte důvod konfigurace",
		"isAlreadyTranslated": true
	},
	"Please enter your name": {
		"text": "Zadejte svoje jméno",
		"isAlreadyTranslated": true
	},
	"Please enter the reason for call": {
		"text": "Zadejte důvod hovoru",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for cancelling": {
		"text": "Vyplňte důvod zrušení",
		"isAlreadyTranslated": true
	},
	"Please specify the pending parts": {
		"text": "Určete čekající díly",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for rejecting claim": {
		"text": "Vyplňte důvod zamítnutí reklamace",
		"isAlreadyTranslated": true
	},
	"Please fill in the cancel reason before rejecting": {
		"text": "Před zamítnutím vyplňte důvod zrušení",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for the claim being invalid": {
		"text": "Vyplňte důvod neplatnosti reklamace",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for claim being invalid before rejecting": {
		"text": "Před zamítnutím vyplňte důvod neplatnosti reklamace",
		"isAlreadyTranslated": true
	},
	"You have not chosen any option to handover": {
		"text": "Nevybrali jste žádnou variantu přejímky",
		"isAlreadyTranslated": true
	},
	"Please enter approved claim amount": {
		"text": "Zadejte schválenou částku reklamace",
		"isAlreadyTranslated": true
	},
	"Please add a picture.": {
		"text": "Přidejte obrázek.",
		"isAlreadyTranslated": true
	},
	"Please specify remarks, if any...": {
		"text": "Zadejte případné poznámky...",
		"isAlreadyTranslated": true
	},
	"Cannot proceed with this action without providing a reason.": {
		"text": "V této akci nelze pokračovat bez uvedení důvodu.",
		"isAlreadyTranslated": true
	},
	"Cannot proceed with this action without providing a reason": {
		"text": "V této akci nelze pokračovat bez uvedení důvodu",
		"isAlreadyTranslated": true
	},
	"Device transaction details": {
		"text": "Detaily transakce se zařízením",
		"isAlreadyTranslated": true
	},
	"Select Device": {
		"text": "Vybrat zařízení",
		"isAlreadyTranslated": true
	},
	"Account Details": {
		"text": "Detaily účtu",
		"isAlreadyTranslated": true
	},
	"Add Customer's Mobile Number": {
		"text": "Přidat mobilní číslo zákazníka",
		"isAlreadyTranslated": true
	},
	"Re - Enter Mobile Number": {
		"text": "Zadejte znovu mobilní číslo",
		"isAlreadyTranslated": true
	},
	"Approve Claim Estimation": {
		"text": "Schválit odhad reklamace",
		"isAlreadyTranslated": true
	},
	"Are you sure? Please specify remarks, if any...": {
		"text": "Jste si jisti? Zadejte případné poznámky...",
		"isAlreadyTranslated": true
	},
	"Ask for Documents Pending": {
		"text": "Požádat o nevyřízené dokumenty",
		"isAlreadyTranslated": true
	},
	"Assign": {
		"text": "Přiřadit",
		"isAlreadyTranslated": true
	},
	"Assigned": {
		"text": "Přiřazeno",
		"isAlreadyTranslated": true
	},
	"Assignment": {
		"text": "Přiřazení",
		"isAlreadyTranslated": true
	},
	"Attempt": {
		"text": "Pokus",
		"isAlreadyTranslated": true
	},
	"Available": {
		"text": "K dispozici",
		"isAlreadyTranslated": true
	},
	"Back": {
		"text": "Zpět",
		"isAlreadyTranslated": true
	},
	"Back to all consumers": {
		"text": "Zpět na všechny spotřebitele",
		"isAlreadyTranslated": true
	},
	"Back to all requests": {
		"text": "Zpět na všechny požadavky",
		"isAlreadyTranslated": true
	},
	"BER Case Found": {
		"text": "Byl nalezen případ ekonomické neopravitelnosti",
		"isAlreadyTranslated": true
	},
	"Brand authorised": {
		"text": "Značka byla autorizována",
		"isAlreadyTranslated": true
	},
	"Broker Name": {
		"text": "Jméno makléře",
		"isAlreadyTranslated": true
	},
	"Call attempted": {
		"text": "Pokus o telefonát",
		"isAlreadyTranslated": true
	},
	"Cancel Repair": {
		"text": "Zrušit opravu",
		"isAlreadyTranslated": true
	},
	"Cancel Request": {
		"text": "Zrušit požadavek",
		"isAlreadyTranslated": true
	},
	"Cancel Service": {
		"text": "Zrušit servis",
		"isAlreadyTranslated": true
	},
	"Cancel Service Without Dispatch": {
		"text": "Zrušit servis bez odeslání",
		"isAlreadyTranslated": true
	},
	"Cannot select more than issues.": {
		"text": "Nelze vybrat více než problémy.",
		"isAlreadyTranslated": true
	},
	"Characters must be numbers": {
		"text": "Znaky musí být číslice",
		"isAlreadyTranslated": true
	},
	"Characters must be alphabets": {
		"text": "Znaky musí být písmena",
		"isAlreadyTranslated": true
	},
	"Characters must be alphanumeric": {
		"text": "Znaky musí být číslice nebo písmena",
		"isAlreadyTranslated": true
	},
	"Characters must be alphabets (at least 2 alphabets are mandatory)": {
		"text": "Znaky musí být písmena (alespoň 2 abecedy)",
		"isAlreadyTranslated": true
	},
	"Characters must be numeric.": {
		"text": "Znaky musí být číslice",
		"isAlreadyTranslated": true
	},
	"Choose from dropdown list only": {
		"text": "Můžete vybírat pouze z rozevíracího seznamu",
		"isAlreadyTranslated": true
	},
	"Claim": {
		"text": "Reklamace",
		"isAlreadyTranslated": true
	},
	"Claim estimation approved": {
		"text": "Odhad reklamace byl schválen",
		"isAlreadyTranslated": true
	},
	"Claim estimation generated": {
		"text": "Odhad reklamace byl vygenerován",
		"isAlreadyTranslated": true
	},
	"Claim initiated": {
		"text": "Reklamace byla zahájena",
		"isAlreadyTranslated": true
	},
	"Claim invalid": {
		"text": "Reklamace je neplatná",
		"isAlreadyTranslated": true
	},
	"Claim raised": {
		"text": "Reklamace byla podána",
		"isAlreadyTranslated": true
	},
	"Claim rejected": {
		"text": "Reklamace byla zamítnuta",
		"isAlreadyTranslated": true
	},
	"Communication History": {
		"text": "Historie komunikace",
		"isAlreadyTranslated": true
	},
	"Complete Repair": {
		"text": "Dokončit opravu",
		"isAlreadyTranslated": true
	},
	"Complete Service": {
		"text": "Dokončit servis",
		"isAlreadyTranslated": true
	},
	"COMPTIA Code": {
		"text": "Kód COMPTIA",
		"isAlreadyTranslated": true
	},
	"COMPTIA Group": {
		"text": "Skupina COMPTIA",
		"isAlreadyTranslated": true
	},
	"Confirm": {
		"text": "Potvrdit",
		"isAlreadyTranslated": true
	},
	"Connect": {
		"text": "Připojit",
		"isAlreadyTranslated": true
	},
	"Connect Disposition": {
		"text": "Připojit možnosti",
		"isAlreadyTranslated": true
	},
	"Consumer Details": {
		"text": "Detaily spotřebitele",
		"isAlreadyTranslated": true
	},
	"Consumer Raised Issues": {
		"text": "Problémy založené spotřebitelem",
		"isAlreadyTranslated": true
	},
	"Contact": {
		"text": "Kontakt",
		"isAlreadyTranslated": true
	},
	"Contract Type": {
		"text": "Typ smlouvy",
		"isAlreadyTranslated": true
	},
	"Current Log": {
		"text": "Aktuální protokol",
		"isAlreadyTranslated": true
	},
	"Current Request Reference ID": {
		"text": "Referenční ID aktuálního požadavku",
		"isAlreadyTranslated": true
	},
	"Current Status": {
		"text": "Aktuální stav",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive": {
		"text": "Pracovník péče o zákazníky",
		"isAlreadyTranslated": true
	},
	"Customer did not add issues": {
		"text": "Zákazník nepřidal problémy",
		"isAlreadyTranslated": true
	},
	"Customer's Version": {
		"text": "Verze zákazníka",
		"isAlreadyTranslated": true
	},
	"DAMAGE DETAILS": {
		"text": "DETAILY POŠKOZENÍ",
		"isAlreadyTranslated": true
	},
	"Damage Type": {
		"text": "Typ poškození",
		"isAlreadyTranslated": true
	},
	"Date and Time of loss/damage:": {
		"text": "Datum a čas ztráty/poškození:",
		"isAlreadyTranslated": true
	},
	"When did your device get damaged?": {
		"text": "Kdy došlo k poškození zařízení?",
		"isAlreadyTranslated": true
	},
	"Please tell us how was your device damaged": {
		"text": "Uveďte, kdy bylo zařízení poškozeno",
		"isAlreadyTranslated": true
	},
	"Date of Purchase": {
		"text": "Datum nákupu",
		"isAlreadyTranslated": true
	},
	"Dead on Arrival": {
		"text": "Poškození při doručení",
		"isAlreadyTranslated": true
	},
	"Description of loss/damage": {
		"text": "Popis ztráty/poškození",
		"isAlreadyTranslated": true
	},
	"Detail Description of Damage": {
		"text": "Detailní popis poškození",
		"isAlreadyTranslated": true
	},
	"Device": {
		"text": "Zařízení",
		"isAlreadyTranslated": true
	},
	"Device Added Date": {
		"text": "Datum přidání zařízení",
		"isAlreadyTranslated": true
	},
	"Device Details": {
		"text": "Detaily zařízení",
		"isAlreadyTranslated": true
	},
	"Device dispatched": {
		"text": "Zařízení bylo odesláno",
		"isAlreadyTranslated": true
	},
	"Device In Warranty?": {
		"text": "Je zařízení v záruce?",
		"isAlreadyTranslated": true
	},
	"Device switching on": {
		"text": "Zařízení lze zapnout",
		"isAlreadyTranslated": true
	},
	"device_details": {
		"text": "detaily_zařízení",
		"isAlreadyTranslated": true
	},
	"Dispatch Device": {
		"text": "Odeslat zařízení",
		"isAlreadyTranslated": true
	},
	"Device shipped": {
		"text": "Zařízení bylo odesláno",
		"isAlreadyTranslated": true
	},
	"Device Received": {
		"text": "Zařízení bylo přijato",
		"isAlreadyTranslated": true
	},
	"Distance from user": {
		"text": "Vzdálenost od uživatele",
		"isAlreadyTranslated": true
	},
	"Distributor Name": {
		"text": "Název distributora",
		"isAlreadyTranslated": true
	},
	"doc": {
		"text": "doc",
		"isAlreadyTranslated": true
	},
	"Document pending": {
		"text": "Dokument čeká na vyřízení",
		"isAlreadyTranslated": true
	},
	"Document verification pending": {
		"text": "Čeká se na ověření dokumentu",
		"isAlreadyTranslated": true
	},
	"Documents are yet to be uploaded": {
		"text": "Dokumenty je třeba teprve nahrát",
		"isAlreadyTranslated": true
	},
	"Download Jobsheet Label": {
		"text": "Stáhnout štítek zakázkového listu",
		"isAlreadyTranslated": true
	},
	"Download Logistics Label": {
		"text": "Stáhnout štítek pro logistiku",
		"isAlreadyTranslated": true
	},
	"DD-MM-YYYY": {
		"text": "DD-MM-RRRR",
		"isAlreadyTranslated": true
	},
	"Email ID": {
		"text": "E-mailová adresa",
		"isAlreadyTranslated": true
	},
	"End on": {
		"text": "Konec v",
		"isAlreadyTranslated": true
	},
	"Engineer": {
		"text": "Technik",
		"isAlreadyTranslated": true
	},
	"Enter Call Details": {
		"text": "Zadejte detaily hovoru",
		"isAlreadyTranslated": true
	},
	"Enter correct Mobile Number": {
		"text": "Zadejte správné mobilní číslo",
		"isAlreadyTranslated": true
	},
	"Enter IMEI or Serial Number": {
		"text": "Zadejte číslo IMEI nebo sériové číslo",
		"isAlreadyTranslated": true
	},
	"Enter Information for the parts you are issuing": {
		"text": "Zadejte informace o dílech, které vydáváte",
		"isAlreadyTranslated": true
	},
	"Enter Mobile Number": {
		"text": "Zadat mobilní číslo",
		"isAlreadyTranslated": true
	},
	"Enter Reference ID": {
		"text": "Zadat referenční ID",
		"isAlreadyTranslated": true
	},
	"Enter Reference IDs": {
		"text": "Zadat referenční ID",
		"isAlreadyTranslated": true
	},
	"Error": {
		"text": "Chyba",
		"isAlreadyTranslated": true
	},
	"Estimation Details": {
		"text": "Detaily odhadu",
		"isAlreadyTranslated": true
	},
	"Exclusive": {
		"text": "Pracovník",
		"isAlreadyTranslated": true
	},
	"External Reference ID": {
		"text": "Externí referenční ID",
		"isAlreadyTranslated": true
	},
	"External Reference ID has to be between 6 - 20 characters.": {
		"text": "Externí referenční ID musí mít délku 6–20 znaků.",
		"isAlreadyTranslated": true
	},
	"External ReferenceID added successfully": {
		"text": "Externí referenční ID bylo úspěšně přidáno",
		"isAlreadyTranslated": true
	},
	"Full name": {
		"text": "Celé jméno",
		"isAlreadyTranslated": true
	},
	"Generate Invoice": {
		"text": "Vygenerovat fakturu",
		"isAlreadyTranslated": true
	},
	"Green Rewards": {
		"text": "Zelené odměny",
		"isAlreadyTranslated": true
	},
	"Green Rewards Chosen": {
		"text": "Zelené odměny jsou vybrány",
		"isAlreadyTranslated": true
	},
	"GSX Validation Status": {
		"text": "Stav ověření GSX",
		"isAlreadyTranslated": true
	},
	"Home": {
		"text": "Domů",
		"isAlreadyTranslated": true
	},
	"HSN Code": {
		"text": "Kód HSN",
		"isAlreadyTranslated": true
	},
	"ID Proof": {
		"text": "Doklad ID",
		"isAlreadyTranslated": true
	},
	"IMEI 1": {
		"text": "IMEI 1",
		"isAlreadyTranslated": true
	},
	"IMEI Number": {
		"text": "Číslo IMEI",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial Number": {
		"text": "číslo IMEI/ sériové číslo",
		"isAlreadyTranslated": true
	},
	"In-principle Approval": {
		"text": "Předběžné schválení",
		"isAlreadyTranslated": true
	},
	"In-principle approved": {
		"text": "Předběžně schváleno",
		"isAlreadyTranslated": true
	},
	"Initiate Claim": {
		"text": "Zahájit reklamaci",
		"isAlreadyTranslated": true
	},
	"Initiate Service": {
		"text": "Zahájit servis",
		"isAlreadyTranslated": true
	},
	"Insurance Status": {
		"text": "Stav pojištění",
		"isAlreadyTranslated": true
	},
	"Insurance team will reach out to you for further action.": {
		"text": "Pojišťovací tým vás bude kontaktovat pro další postup.",
		"isAlreadyTranslated": true
	},
	"Insurer Name": {
		"text": "Název pojistitele",
		"isAlreadyTranslated": true
	},
	"Invalid email id": {
		"text": "Neplatná emailová adresa",
		"isAlreadyTranslated": true
	},
	"Invoice": {
		"text": "Faktura",
		"isAlreadyTranslated": true
	},
	"Is Warranty Applicable": {
		"text": "Lze uplatnit záruku?",
		"isAlreadyTranslated": true
	},
	"Is Warranty Applicable for this request?": {
		"text": "Vztahuje se na tento požadavek záruka?",
		"isAlreadyTranslated": true
	},
	"Issue description": {
		"text": "Popis problému",
		"isAlreadyTranslated": true
	},
	"Issue Validation": {
		"text": "Ověření problému",
		"isAlreadyTranslated": true
	},
	"Issued": {
		"text": "Vydáno",
		"isAlreadyTranslated": true
	},
	"Issues": {
		"text": "Problémy",
		"isAlreadyTranslated": true
	},
	"Issues Reported": {
		"text": "Problémy byly nahlášeny",
		"isAlreadyTranslated": true
	},
	"Landmark": {
		"text": "Orientační bod",
		"isAlreadyTranslated": true
	},
	"Loading": {
		"text": "Načítání",
		"isAlreadyTranslated": true
	},
	"Loading request": {
		"text": "Načítání požadavku",
		"isAlreadyTranslated": true
	},
	"Location": {
		"text": "Místo",
		"isAlreadyTranslated": true
	},
	"Logistics - From Customer's Address To Service Center": {
		"text": "Logistika – z adresy zákazníka do servisního střediska",
		"isAlreadyTranslated": true
	},
	"Logistics - From Customer's Address To Servify Hub": {
		"text": "Logistika – z adresy zákazníka do terminálu společnosti Servify",
		"isAlreadyTranslated": true
	},
	"Logistics - From Drop-Off Center to Servify Hub": {
		"text": "Logistika – z předávacího centra do terminálu společnosti Servify",
		"isAlreadyTranslated": true
	},
	"Logistics - From Servify Hub to Recycle Hub": {
		"text": "Logistika – z terminálu společnosti Servify do centra Recycle Hub",
		"isAlreadyTranslated": true
	},
	"Logistics - Return to Customer": {
		"text": "Logistika – vrácení zákazníkovi",
		"isAlreadyTranslated": true
	},
	"Logistics Details": {
		"text": "Detaily logistiky",
		"isAlreadyTranslated": true
	},
	"Loss DateTime": {
		"text": "Datum/čas ztráty",
		"isAlreadyTranslated": true
	},
	"Loss Description": {
		"text": "Popis ztráty",
		"isAlreadyTranslated": true
	},
	"Mark Customer Arrival": {
		"text": "Označit příjezd zákazníka",
		"isAlreadyTranslated": true
	},
	"Merge": {
		"text": "Sloučit",
		"isAlreadyTranslated": true
	},
	"Min 75 Characters": {
		"text": "Alespoň 75 znaků",
		"isAlreadyTranslated": true
	},
	"Mobile number": {
		"text": "Mobilní číslo",
		"isAlreadyTranslated": true
	},
	"NA": {
		"text": "Neuvedeno",
		"isAlreadyTranslated": true
	},
	"Name": {
		"text": "Jméno",
		"isAlreadyTranslated": true
	},
	"Name of the caller": {
		"text": "Jméno volajícího",
		"isAlreadyTranslated": true
	},
	"Next Steps": {
		"text": "Další kroky",
		"isAlreadyTranslated": true
	},
	"No addresses found": {
		"text": "Nebyly nalezeny žádné adresy",
		"isAlreadyTranslated": true
	},
	"No brands found": {
		"text": "Nebyly nalezeny žádné značky",
		"isAlreadyTranslated": true
	},
	"No documents uploaded": {
		"text": "Nebyly nahrány žádné dokumenty",
		"isAlreadyTranslated": true
	},
	"No engineers found": {
		"text": "Nelze nalézt žádné techniky",
		"isAlreadyTranslated": true
	},
	"No issues added": {
		"text": "Nebyly přidány žádné problémy",
		"isAlreadyTranslated": true
	},
	"No issues found": {
		"text": "Nebyly nalezeny žádné problémy",
		"isAlreadyTranslated": true
	},
	"No other requests found": {
		"text": "Nebyly nalezeny žádné jiné požadavky",
		"isAlreadyTranslated": true
	},
	"No parts requested": {
		"text": "Nejsou požadovány žádné díly",
		"isAlreadyTranslated": true
	},
	"No Pending Document Specified": {
		"text": "Není zadán žádný nevyřízený dokument",
		"isAlreadyTranslated": true
	},
	"No products found": {
		"text": "Nebyly nalezeny žádné produkty",
		"isAlreadyTranslated": true
	},
	"No servicable consumer product found": {
		"text": "Nebyl nalezen žádný produkt spotřebitele umožňující servis",
		"isAlreadyTranslated": true
	},
	"No service locations found": {
		"text": "Nebyla nalezena žádná servisní místa",
		"isAlreadyTranslated": true
	},
	"No Slots Available": {
		"text": "Nejsou k dispozici žádné časy",
		"isAlreadyTranslated": true
	},
	"No subcategories found": {
		"text": "Nebyly nalezeny žádné podkategorie",
		"isAlreadyTranslated": true
	},
	"No such service request exists": {
		"text": "Zadaný požadavek na servis neexistuje",
		"isAlreadyTranslated": true
	},
	"No supported mode found": {
		"text": "Nebyl nalezen žádný podporovaný režim",
		"isAlreadyTranslated": true
	},
	"No supported modes found": {
		"text": "Nebyly nalezeny žádné podporované režimy",
		"isAlreadyTranslated": true
	},
	"No. of Repairs Allowed": {
		"text": "Počet povolených oprav",
		"isAlreadyTranslated": true
	},
	"No. of Repairs Used": {
		"text": "Počet využitých oprav",
		"isAlreadyTranslated": true
	},
	"Non-Connect Disposition": {
		"text": "Konfigurace bez spojení",
		"isAlreadyTranslated": true
	},
	"Office": {
		"text": "Pobočka",
		"isAlreadyTranslated": true
	},
	"Okay": {
		"text": "OK",
		"isAlreadyTranslated": true
	},
	"Oops! There are no verified service centres around.": {
		"text": "Jejda! V okolí nejsou žádná ověřená servisní střediska.",
		"isAlreadyTranslated": true
	},
	"Oops... Something went wrong.": {
		"text": "Jejda... Něco se pokazilo.",
		"isAlreadyTranslated": true
	},
	"OR": {
		"text": "NEBO",
		"isAlreadyTranslated": true
	},
	"Other": {
		"text": "Jiné",
		"isAlreadyTranslated": true
	},
	"other": {
		"text": "jiné",
		"isAlreadyTranslated": true
	},
	"Other Requests": {
		"text": "Ostatní požadavky",
		"isAlreadyTranslated": true
	},
	"Out of Warranty": {
		"text": "Bez záruky",
		"isAlreadyTranslated": true
	},
	"Override Approval": {
		"text": "Schválení potlačení",
		"isAlreadyTranslated": true
	},
	"Override Approval History": {
		"text": "Potlačit historii schválení",
		"isAlreadyTranslated": true
	},
	"Override only if customer instructs to": {
		"text": "Potlačit pouze na přání zákazníka",
		"isAlreadyTranslated": true
	},
	"override_approval": {
		"text": "schválení_potlačení",
		"isAlreadyTranslated": true
	},
	"Paid": {
		"text": "Uhrazeno",
		"isAlreadyTranslated": true
	},
	"Part Code": {
		"text": "Kód dílu",
		"isAlreadyTranslated": true
	},
	"Part Name": {
		"text": "Název dílu",
		"isAlreadyTranslated": true
	},
	"Parts and Estimation": {
		"text": "Díly a odhad",
		"isAlreadyTranslated": true
	},
	"Parts Pending": {
		"text": "Čeká se na díly",
		"isAlreadyTranslated": true
	},
	"Parts Received": {
		"text": "Díly přijaty",
		"isAlreadyTranslated": true
	},
	"Parts Requested": {
		"text": "Požadované díly",
		"isAlreadyTranslated": true
	},
	"Parts Supply Location": {
		"text": "Místo dodání dílů",
		"isAlreadyTranslated": true
	},
	"Pickup Drop By Fedex": {
		"text": "Vyzvednutí/doručení společností Fedex",
		"isAlreadyTranslated": true
	},
	"Please enter at least one document": {
		"text": "Zadejte alespoň jeden dokument",
		"isAlreadyTranslated": true
	},
	"Please enter correct": {
		"text": "Zadejte správnou hodnotu",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI": {
		"text": "Zadejte správné číslo IMEI",
		"isAlreadyTranslated": true
	},
	"Please enter reason for approval": {
		"text": "Zadejte důvod schválení",
		"isAlreadyTranslated": true
	},
	"Please select a subcategory first": {
		"text": "Vyberte nejprve podkategorii",
		"isAlreadyTranslated": true
	},
	"Please specify the reason": {
		"text": "Uveďte důvod",
		"isAlreadyTranslated": true
	},
	"QC Engineer": {
		"text": "Technik kontroly kvality",
		"isAlreadyTranslated": true
	},
	"Raise Consumer Service Request": {
		"text": "Zadat požadavek spotřebitele na servis",
		"isAlreadyTranslated": true
	},
	"Raise new request": {
		"text": "Zadat nový požadavek",
		"isAlreadyTranslated": true
	},
	"Reason for Approval": {
		"text": "Důvod ke schválení",
		"isAlreadyTranslated": true
	},
	"Receive Device": {
		"text": "Přijmout zařízení",
		"isAlreadyTranslated": true
	},
	"Reject Claim": {
		"text": "Zamítnout reklamaci",
		"isAlreadyTranslated": true
	},
	"Repair cancel": {
		"text": "Zrušit opravu",
		"isAlreadyTranslated": true
	},
	"Report Issues to GSX": {
		"text": "Nahlásit problémy do GSX",
		"isAlreadyTranslated": true
	},
	"Request Cancelled": {
		"text": "Požadavek byl zrušen",
		"isAlreadyTranslated": true
	},
	"Request Details": {
		"text": "Detaily požadavku",
		"isAlreadyTranslated": true
	},
	"Request Documents": {
		"text": "Dokumenty k požadavku",
		"isAlreadyTranslated": true
	},
	"Request Has Been Raised": {
		"text": "Požadavek byl zadán",
		"isAlreadyTranslated": true
	},
	"Request is being processed": {
		"text": "Požadavek se zpracovává",
		"isAlreadyTranslated": true
	},
	"Request Scheduled for": {
		"text": "Požadavek byl naplánován na",
		"isAlreadyTranslated": true
	},
	"Requested By": {
		"text": "Požadováno",
		"isAlreadyTranslated": true
	},
	"Requires customer to print documents and safely pack the device": {
		"text": "Zákazník musí vytisknout dokumenty a zařízení bezpečně zabalit",
		"isAlreadyTranslated": true
	},
	"Reschedule": {
		"text": "Změnit termín",
		"isAlreadyTranslated": true
	},
	"Retailer Name": {
		"text": "Název prodejce",
		"isAlreadyTranslated": true
	},
	"Rewards updated successfully": {
		"text": "Odměny byly úspěšně aktualizovány",
		"isAlreadyTranslated": true
	},
	"Role": {
		"text": "Role",
		"isAlreadyTranslated": true
	},
	"Save": {
		"text": "Uložit",
		"isAlreadyTranslated": true
	},
	"Schedule Date": {
		"text": "Naplánovat datum",
		"isAlreadyTranslated": true
	},
	"Select a Brand": {
		"text": "Vyberte značku",
		"isAlreadyTranslated": true
	},
	"Select a Consumer Product": {
		"text": "Vyberte produkt spotřebitele",
		"isAlreadyTranslated": true
	},
	"Select a Date": {
		"text": "Vyberte datum",
		"isAlreadyTranslated": true
	},
	"Select a Product": {
		"text": "Vyberte produkt",
		"isAlreadyTranslated": true
	},
	"Select a Reward": {
		"text": "Vyberte odměnu",
		"isAlreadyTranslated": true
	},
	"Select a service center": {
		"text": "Vyberte servisní středisko",
		"isAlreadyTranslated": true
	},
	"Select a service location": {
		"text": "Vyberte servisní místo",
		"isAlreadyTranslated": true
	},
	"Select a Service Mode": {
		"text": "Vybrat servisní režim",
		"isAlreadyTranslated": true
	},
	"Select a Slot": {
		"text": "Vyberte čas",
		"isAlreadyTranslated": true
	},
	"Select a Subcategory": {
		"text": "Vyberte podkategorii",
		"isAlreadyTranslated": true
	},
	"Select an Address": {
		"text": "Vyberte adresu",
		"isAlreadyTranslated": true
	},
	"Select an engineer": {
		"text": "Vyberte technika",
		"isAlreadyTranslated": true
	},
	"Select issues": {
		"text": "Vyberte problémy",
		"isAlreadyTranslated": true
	},
	"Select Logistics Partner": {
		"text": "Vybrat logistického partnera",
		"isAlreadyTranslated": true
	},
	"Select type of damage": {
		"text": "Vyberte typ poškození",
		"isAlreadyTranslated": true
	},
	"Selected Product": {
		"text": "Vybraný produkt",
		"isAlreadyTranslated": true
	},
	"Send for raising a request?": {
		"text": "Odesláno k podání požadavku?",
		"isAlreadyTranslated": true
	},
	"Search issues": {
		"text": "Hledat problémy",
		"isAlreadyTranslated": true
	},
	"Search brands": {
		"text": "Hledat značky",
		"isAlreadyTranslated": true
	},
	"Search products": {
		"text": "Hledat produkty",
		"isAlreadyTranslated": true
	},
	"Search subcategories": {
		"text": "Hledat podkategorie",
		"isAlreadyTranslated": true
	},
	"Serial No": {
		"text": "Sériové č.",
		"isAlreadyTranslated": true
	},
	"Serial Number": {
		"text": "Sériové číslo",
		"isAlreadyTranslated": true
	},
	"Service": {
		"text": "Servis",
		"isAlreadyTranslated": true
	},
	"Service cancel": {
		"text": "Zrušení služby",
		"isAlreadyTranslated": true
	},
	"Service Center": {
		"text": "Servisní středisko",
		"isAlreadyTranslated": true
	},
	"Service completed": {
		"text": "Servis byl dokončen",
		"isAlreadyTranslated": true
	},
	"Service Engineer": {
		"text": "Servisní technik",
		"isAlreadyTranslated": true
	},
	"Service Type": {
		"text": "Typ servisu",
		"isAlreadyTranslated": true
	},
	"Slot": {
		"text": "Čas",
		"isAlreadyTranslated": true
	},
	"Slot Time": {
		"text": "Časový interval",
		"isAlreadyTranslated": true
	},
	"Something went wrong": {
		"text": "Něco se pokazilo",
		"isAlreadyTranslated": true
	},
	"Something went wrong while adding the product": {
		"text": "Při přidávání produktu se něco pokazilo",
		"isAlreadyTranslated": true
	},
	"Something went wrong while raising service request": {
		"text": "Při podávání servisního požadavku se něco pokazilo",
		"isAlreadyTranslated": true
	},
	"Start Repair": {
		"text": "Zahájit opravu",
		"isAlreadyTranslated": true
	},
	"Started On": {
		"text": "Zahájeno v",
		"isAlreadyTranslated": true
	},
	"Status": {
		"text": "Stav",
		"isAlreadyTranslated": true
	},
	"Storage Capacity": {
		"text": "Kapacita úložiště",
		"isAlreadyTranslated": true
	},
	"Store Manager": {
		"text": "Vedoucí prodejny",
		"isAlreadyTranslated": true
	},
	"Submit": {
		"text": "Odeslat",
		"isAlreadyTranslated": true
	},
	"success": {
		"text": "úspěšně provedeno",
		"isAlreadyTranslated": true
	},
	"Success": {
		"text": "Úspěšně provedeno",
		"isAlreadyTranslated": true
	},
	"Superadmin": {
		"text": "Správce nejvyšší úrovně",
		"isAlreadyTranslated": true
	},
	"Supervisor": {
		"text": "Nadřízený",
		"isAlreadyTranslated": true
	},
	"Telecon Version:": {
		"text": "Verze Telecon:",
		"isAlreadyTranslated": true
	},
	"The Green Reward cash amount will be credited to the account associated with this number": {
		"text": "Zelená odměna bude připsána na účet přiřazený k tomuto číslu.",
		"isAlreadyTranslated": true
	},
	"The request was raised successfully": {
		"text": "Požadavek byl úspěšně podán",
		"isAlreadyTranslated": true
	},
	"The two numbers do not match": {
		"text": "Čísla se neshodují",
		"isAlreadyTranslated": true
	},
	"There is no service for this address at this moment": {
		"text": "Pro tuto adresu momentálně neexistuje žádná služba",
		"isAlreadyTranslated": true
	},
	"There's a problem with this landmark, please select a different landmark": {
		"text": "Došlo k problému s orientačním bodem; vyberte jiný orientační bod",
		"isAlreadyTranslated": true
	},
	"Time of Damage": {
		"text": "Čas poškození",
		"isAlreadyTranslated": true
	},
	"Total Amount": {
		"text": "Částka celkem",
		"isAlreadyTranslated": true
	},
	"Select Damage Type": {
		"text": "Vybrat typ poškození",
		"isAlreadyTranslated": true
	},
	"Type Of Program": {
		"text": "Typ programu",
		"isAlreadyTranslated": true
	},
	"Under Warranty": {
		"text": "V záruce",
		"isAlreadyTranslated": true
	},
	"Validate": {
		"text": "Ověřit",
		"isAlreadyTranslated": true
	},
	"Validity Text": {
		"text": "Text platnosti",
		"isAlreadyTranslated": true
	},
	"Verification Code": {
		"text": "Ověřovací kód",
		"isAlreadyTranslated": true
	},
	"View": {
		"text": "Zobrazit",
		"isAlreadyTranslated": true
	},
	"View Advance Receipt": {
		"text": "Zobrazit zálohový doklad",
		"isAlreadyTranslated": true
	},
	"View DOA Certificate": {
		"text": "Zobrazit certifikát o poškození při doručení",
		"isAlreadyTranslated": true
	},
	"View Invoice": {
		"text": "Zobrazit fakturu",
		"isAlreadyTranslated": true
	},
	"View Jobsheet": {
		"text": "Zobrazit zakázkový list",
		"isAlreadyTranslated": true
	},
	"View Parts Document": {
		"text": "Zobrazit dokumenty dílů",
		"isAlreadyTranslated": true
	},
	"Waiting for the customer to raise a request": {
		"text": "Čeká se na zákazníka, aby podal požadavek",
		"isAlreadyTranslated": true
	},
	"Warranty": {
		"text": "Záruka",
		"isAlreadyTranslated": true
	},
	"Warranty Applicable": {
		"text": "Lze uplatnit záruku",
		"isAlreadyTranslated": true
	},
	"Warranty Status": {
		"text": "Stav záruky",
		"isAlreadyTranslated": true
	},
	"Warranty Till": {
		"text": "V záruce do",
		"isAlreadyTranslated": true
	},
	"Waybill Number": {
		"text": "Číslo nákladního listu",
		"isAlreadyTranslated": true
	},
	"Year of Purchase": {
		"text": "Rok nákupu",
		"isAlreadyTranslated": true
	},
	"Yes": {
		"text": "Ano",
		"isAlreadyTranslated": true
	},
	"You have already added this document type in the list of pending documents": {
		"text": "Tento typ dokumentu jste již přidali do seznamu nevyřízených dokumentů",
		"isAlreadyTranslated": true
	},
	"You have already added this issue": {
		"text": "Tento problém jste již přidali",
		"isAlreadyTranslated": true
	},
	"Zipcode": {
		"text": "PSČ",
		"isAlreadyTranslated": true
	},
	"Raise Claim": {
		"text": "Podat reklamaci",
		"isAlreadyTranslated": true
	},
	"Invalid Claim": {
		"text": "Neplatná reklamace",
		"isAlreadyTranslated": true
	},
	"Reject Estimation": {
		"text": "Zamítnout odhad",
		"isAlreadyTranslated": true
	},
	"Collect Advance": {
		"text": "Vybrat zálohu",
		"isAlreadyTranslated": true
	},
	"Complete QC": {
		"text": "Dokončit kontrolu kvality",
		"isAlreadyTranslated": true
	},
	"Handover device": {
		"text": "Předat zařízení",
		"isAlreadyTranslated": true
	},
	"Accept device": {
		"text": "Přijmout zařízení",
		"isAlreadyTranslated": true
	},
	"Complete Job and Handover Device": {
		"text": "Dokončit zakázku a předat zařízení",
		"isAlreadyTranslated": true
	},
	"Collect Device": {
		"text": "Vyzvednout zařízení",
		"isAlreadyTranslated": true
	},
	"Accept/Reject Job": {
		"text": "Přijmout/odmítnout zakázku",
		"isAlreadyTranslated": true
	},
	"Generate estimation": {
		"text": "Vygenerovat odhad",
		"isAlreadyTranslated": true
	},
	"characters entered.": {
		"text": "zadaných znaků.",
		"isAlreadyTranslated": true
	},
	"Your GSX Request has been created": {
		"text": "Váš požadavek GSX byl vytvořen",
		"isAlreadyTranslated": true
	},
	"Upload Inspection Documents": {
		"text": "Nahrát dokumenty o inspekci",
		"isAlreadyTranslated": true
	},
	"Complete DOA": {
		"text": "Zpracovat certifikát o poškození při doručení",
		"isAlreadyTranslated": true
	},
	"QC Completed": {
		"text": "Kontrola kvality byla dokončena",
		"isAlreadyTranslated": true
	},
	"Create GSX request": {
		"text": "Vytvořit požadavek GSX",
		"isAlreadyTranslated": true
	},
	"DOA Checklist": {
		"text": "Kontrolní seznam poškození při doručení",
		"isAlreadyTranslated": true
	},
	"Reject Parts Request": {
		"text": "Zamítnout požadavek na díly",
		"isAlreadyTranslated": true
	},
	"Accept Parts Request": {
		"text": "Přijmout požadavek na díly",
		"isAlreadyTranslated": true
	},
	"Cannot Diagnose": {
		"text": "Nelze provést diagnostiku",
		"isAlreadyTranslated": true
	},
	"Initiate Diagnosis": {
		"text": "Zahájit diagnostiku",
		"isAlreadyTranslated": true
	},
	"Diagnosis Completed": {
		"text": "Diagnostika dokončena",
		"isAlreadyTranslated": true
	},
	"Accept Device": {
		"text": "Přijmout zařízení",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer to accept the job.": {
		"text": "Čeká se na technika, aby zakázku přijal.",
		"isAlreadyTranslated": true
	},
	"Transfer Request": {
		"text": "Převést požadavek",
		"isAlreadyTranslated": true
	},
	"Accept and Complete QC": {
		"text": "Přijmout a dokončit kontrolu kvality",
		"isAlreadyTranslated": true
	},
	"Reject QC": {
		"text": "Zamítnout kontrolu kvality",
		"isAlreadyTranslated": true
	},
	"Confirm BER": {
		"text": "Potvrdit ekonomickou neopravitelnost",
		"isAlreadyTranslated": true
	},
	"Reject BER": {
		"text": "Zamítnout ekonomickou neopravitelnost",
		"isAlreadyTranslated": true
	},
	"Perform QC": {
		"text": "Provést kontrolu kvality",
		"isAlreadyTranslated": true
	},
	"Fault Codes": {
		"text": "Kódy závad",
		"isAlreadyTranslated": true
	},
	"Actions": {
		"text": "Akce",
		"isAlreadyTranslated": true
	},
	"Engineer Added Faults": {
		"text": "Závady přidané technikem",
		"isAlreadyTranslated": true
	},
	"Faults": {
		"text": "Závady",
		"isAlreadyTranslated": true
	},
	"Action Code": {
		"text": "Kód akce",
		"isAlreadyTranslated": true
	},
	"N/A": {
		"text": "neuvedeno",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to receive device and complete mandatory checklist tasks.": {
		"text": "Čeká se na to, až pracovník péče o zákazníky obdrží zařízení a provede povinné úkony podle kontrolního seznamu.",
		"isAlreadyTranslated": true
	},
	"Waiting for CC to receive device and complete mandatory checklist tasks.": {
		"text": "Čeká se na to, až oddělení péče o zákazníky obdrží zařízení a provede povinné úkony podle kontrolního seznamu.",
		"isAlreadyTranslated": true
	},
	"Yes, the device is whitelisted. Please upload screenshot of the same": {
		"text": "Ano, zařízení je na whitelistu. Nahrajte snímek obrazovky zařízení",
		"isAlreadyTranslated": true
	},
	"No pictures added": {
		"text": "Nebyly přidány žádné obrázky",
		"isAlreadyTranslated": true
	},
	"No, the device is not whitelisted. Please cancel the request and return the device if you have it": {
		"text": "Ne, zařízení není na whitelistu. Zrušte požadavek a vraťte zařízení, pokud jej máte",
		"isAlreadyTranslated": true
	},
	"Next": {
		"text": "Další",
		"isAlreadyTranslated": true
	},
	"Device Name": {
		"text": "Název zařízení",
		"isAlreadyTranslated": true
	},
	"ELS": {
		"text": "ELS",
		"isAlreadyTranslated": true
	},
	"QC": {
		"text": "Kontrola kvality",
		"isAlreadyTranslated": true
	},
	"Please select Coverage Option": {
		"text": "Vyberte variantu rozsahu platnosti",
		"isAlreadyTranslated": true
	},
	"DOA": {
		"text": "Potvrzení o poškození při doručení",
		"isAlreadyTranslated": true
	},
	"Device Whitelisting Information": {
		"text": "Informace o zařazení zařízení na whitelist",
		"isAlreadyTranslated": true
	},
	"KBB Serial Number": {
		"text": "Sériové číslo KBB",
		"isAlreadyTranslated": true
	},
	"Inspection Completed, Awaiting Engineer Assignment": {
		"text": "Kontrola byla dokončena, čeká se na přiřazení technika",
		"isAlreadyTranslated": true
	},
	"Verification Complete": {
		"text": "Ověření bylo dokončeno",
		"isAlreadyTranslated": true
	},
	"Dropoff Request Registered": {
		"text": "Požadavek na předání byl zaregistrován",
		"isAlreadyTranslated": true
	},
	"Service initiated": {
		"text": "Servis byl zahájen",
		"isAlreadyTranslated": true
	},
	"Device dropped": {
		"text": "Zařízení bylo předáno",
		"isAlreadyTranslated": true
	},
	"Recycle Dropoff": {
		"text": "Předání k recyklaci",
		"isAlreadyTranslated": true
	},
	"Pledge request created": {
		"text": "Požadavek na závazek byl vytvořen",
		"isAlreadyTranslated": true
	},
	"QC completed": {
		"text": "Kontrola kvality byla dokončena",
		"isAlreadyTranslated": true
	},
	"sdv": {
		"text": "sdv",
		"isAlreadyTranslated": true
	},
	"Intimate customer": {
		"text": "Oznámit zákazníkovi",
		"isAlreadyTranslated": true
	},
	"Repair has been cancelled.": {
		"text": "Oprava byla zrušena",
		"isAlreadyTranslated": true
	},
	"BTB socket": {
		"text": "BTB soket",
		"isAlreadyTranslated": true
	},
	"SIM Card needle": {
		"text": "Jehla na SIM kartu",
		"isAlreadyTranslated": true
	},
	"SIM card holder EB101 grey aluminum alloy": {
		"text": "Držák SIM karty EB101, šedá hliníková slitina",
		"isAlreadyTranslated": true
	},
	"Pending for Acceptance by Engineer": {
		"text": "Čeká se na převzetí technikem",
		"isAlreadyTranslated": true
	},
	"microphone": {
		"text": "mikrofon",
		"isAlreadyTranslated": true
	},
	"for Token generated": {
		"text": "pro vygenerovaný pořadový tiket",
		"isAlreadyTranslated": true
	},
	"Advance collected": {
		"text": "Záloha vybrána",
		"isAlreadyTranslated": true
	},
	"pin added": {
		"text": "kód PIN byl přidán",
		"isAlreadyTranslated": true
	},
	"Trade In Dropoff": {
		"text": "Předání na protiúčet",
		"isAlreadyTranslated": true
	},
	"No, the device is not whitelisted. Please return the device if you have it": {
		"text": "Ne, zařízení není na whitelistu. Vraťte zařízení, pokud jej máte",
		"isAlreadyTranslated": true
	},
	"Download Open/Inprogress requests.": {
		"text": "Stáhnout otevřené/právě zpracovávané požadavky.",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer to accept the job. (Eligible for DOA)": {
		"text": "Čeká se na technika, aby zakázku přijal. (umožňující vystavení certifikátu o poškození při doručení)",
		"isAlreadyTranslated": true
	},
	"(Eligible for DOA)": {
		"text": " (umožňující vystavení certifikátu o poškození při doručení)",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive will choose action for the device from Dead on Arrival section below.": {
		"text": "Pracovník péče o zákazníky zvolí akci pro dané zařízení v sekci Poškození při doručení níže.",
		"isAlreadyTranslated": true
	},
	"Engineer will select the new device from Dead on Arrival section below": {
		"text": "Technik zvolí nové zařízení v sekci Poškození při doručení níže.",
		"isAlreadyTranslated": true
	},
	"Device repair has failed QC. Device is to be given back to the Engineer.": {
		"text": "Oprava zařízení neprošla kontrolou kvality. Zařízení je nutno vrátit technikovi.",
		"isAlreadyTranslated": true
	},
	'Waiting for Estimation Approval. Click on "Send for approval" in "Parts and Estimation" section below.': {
		"text": "Čeká na schválení odhadu. Klikněte na možnost „Odeslat ke schválení“ v sekci „Díly a odhad“ níže.",
		"isAlreadyTranslated": true
	},
	'Waiting for customer approval of estimation or override estimation approval in "Parts and Estimation" section below.': {
		"text": "Čeká se na schválení odhadu zákazníkem nebo potlačte schválení odhadu v sekci „Díly a odhad“ níže.",
		"isAlreadyTranslated": true
	},
	"Estimation has been rejected, parts may be added if required by the engineer.": {
		"text": "Odhad byl zamítnut; v případě požadavku technika mohou být přidány další díly.",
		"isAlreadyTranslated": true
	},
	'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.': {
		"text": "Čeká se, až prodejna vydá požadované díly uvedené v sekci „Požadované díly“ níže. Opravu nelze provést, dokud nebude požadavek na díly vyřízen.‘",
		"isAlreadyTranslated": true
	},
	'Waiting for store to issue required device in "Dead on arrival" section below.': {
		"text": "Čeká se, až prodejna vydá požadované zařízení uvedené v sekci „Poškození při doručení“ níže.",
		"isAlreadyTranslated": true
	},
	'Waiting for the Engineer to receive the "Replacement device" from store in "Dead on Arrival" section below.': {
		"text": "Čeká se, až technik obdrží z prodejny „Náhradní zařízení“ uvedené v sekci „Poškození při doručení“ níže.",
		"isAlreadyTranslated": true
	},
	'Waiting for Engineer to receive parts from store in "Parts Requested" section below.': {
		"text": "Čeká se, až technik obdrží z prodejny díly uvedené v sekci „Požadované díly“ níže.",
		"isAlreadyTranslated": true
	},
	"Waiting for the Engineer to complete DOA by handing over the damaged device to Store": {
		"text": "Čeká se, až technik vyplní certifikát o poškození při doručení a předá poškozené zařízení do prodejny",
		"isAlreadyTranslated": true
	},
	"All parts received, repair is in progress.": {
		"text": "Všechny díly byly přijaty, probíhá oprava.",
		"isAlreadyTranslated": true
	},
	"Engineer completed the repair.": {
		"text": "Technik dokončil opravu.",
		"isAlreadyTranslated": true
	},
	"Service invoice has been generated, final payment can be received.": {
		"text": "Faktura za servis byla vygenerována a je možné přijmout finální platbu.",
		"isAlreadyTranslated": true
	},
	"Waiting for the engineer to handover device to Store": {
		"text": "Čeká se, až technik předá zařízení do prodejny",
		"isAlreadyTranslated": true
	},
	"Waiting for the Store to handover device to Customer Care Executive": {
		"text": "Čeká se, až prodejna předá zařízení pracovníkovi péče o zákazníky",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to complete the DOA": {
		"text": "Čeká se, až pracovník péče o zákazníky vyplní certifikát o poškození při doručení",
		"isAlreadyTranslated": true
	},
	"Please complete the service.": {
		"text": "Dokončete servis.",
		"isAlreadyTranslated": true
	},
	"Waiting for the customer to rate the service": {
		"text": "Čeká se na zákazníka, až ohodnotí servis",
		"isAlreadyTranslated": true
	},
	"Intimate customer.": {
		"text": "Oznámit zákazníkovi.",
		"isAlreadyTranslated": true
	},
	"Logistics": {
		"text": "Logistika",
		"isAlreadyTranslated": true
	},
	"Send link": {
		"text": "Odeslat odkaz",
		"isAlreadyTranslated": true
	},
	"Other Image": {
		"text": "Jiný obrázek",
		"isAlreadyTranslated": true
	},
	"Item": {
		"text": "Položka",
		"isAlreadyTranslated": true
	},
	"Transfer Details": {
		"text": "Detaily převodu",
		"isAlreadyTranslated": true
	},
	"Schedule a Logistic Request": {
		"text": "Naplánovat logistický požadavek",
		"isAlreadyTranslated": true
	},
	"Forward": {
		"text": "Vpřed",
		"isAlreadyTranslated": true
	},
	"Reverse": {
		"text": "Zpět",
		"isAlreadyTranslated": true
	},
	"Transfer Details": {
		"text": "Detaily převodu",
		"isAlreadyTranslated": true
	},
	"Forward": {
		"text": "Vpřed",
		"isAlreadyTranslated": true
	},
	"Reverse": {
		"text": "Zpět",
		"isAlreadyTranslated": true
	},
	"Track Logistics Requests": {
		"text": "Sledovat logistické požadavky",
		"isAlreadyTranslated": true
	},
	"Pickup Pincode": {
		"text": "Kód PIN pro vyzvednutí",
		"isAlreadyTranslated": true
	},
	"Recycle Request Schedule Date": {
		"text": "Plánované datum požadavku na recyklaci",
		"isAlreadyTranslated": true
	},
	"Recycle Request Created Date": {
		"text": "Datum vytvoření požadavku na recyklaci",
		"isAlreadyTranslated": true
	},
	"Schedule Logistics Request": {
		"text": "Naplánovat logistický požadavek",
		"isAlreadyTranslated": true
	},
	"Hub's Address Details": {
		"text": "Detaily adresy centra",
		"isAlreadyTranslated": true
	},
	"Now": {
		"text": "Nyní",
		"isAlreadyTranslated": true
	},
	"Later": {
		"text": "Později",
		"isAlreadyTranslated": true
	},
	"Recycle Hub Details": {
		"text": "Detaily recyklačního centra",
		"isAlreadyTranslated": true
	},
	"This request will be added to a queue. You can schedule a logistics request later.": {
		"text": "Tento požadavek bude přidán do fronty. Logistický požadavek můžete naplánovat později.",
		"isAlreadyTranslated": true
	},
	"Request Added to Queue Successfully": {
		"text": "Požadavek byl úspěšně přidán do fronty",
		"isAlreadyTranslated": true
	},
	"Create Bulk Requests": {
		"text": "Vytvořit hromadné požadavky",
		"isAlreadyTranslated": true
	},
	"Logistics Status": {
		"text": "Stav logistiky",
		"isAlreadyTranslated": true
	},
	"Drop Pincode": {
		"text": "Kód PIN pro předání",
		"isAlreadyTranslated": true
	},
	"Logistic Request Created Date": {
		"text": "Datum vytvoření logistického požadavku",
		"isAlreadyTranslated": true
	},
	"Logistic Vendor": {
		"text": "Logistický dodavatel",
		"isAlreadyTranslated": true
	},
	"Bulk Logistic Reference ID": {
		"text": "Referenční ID pro hromadné logistické operace",
		"isAlreadyTranslated": true
	},
	"Not Covered": {
		"text": "Není pokryto",
		"isAlreadyTranslated": true
	},
	"Queue": {
		"text": "Fronta",
		"isAlreadyTranslated": true
	},
	"LOGISTICS REQUESTS": {
		"text": "LOGISTICKÉ POŽADAVKY",
		"isAlreadyTranslated": true
	},
	"Work in progress": {
		"text": "Probíhá práce",
		"isAlreadyTranslated": true
	},
	"Recycle Pickup": {
		"text": "Vyzvednutí pro recyklaci",
		"isAlreadyTranslated": true
	},
	"Recycle Request": {
		"text": "Požadavek na recyklaci",
		"isAlreadyTranslated": true
	},
	"Beyond Economic Repair": {
		"text": "Ekonomicky neopravitelný stav",
		"isAlreadyTranslated": true
	},
	"Device collected": {
		"text": "Zařízení bylo vyzvednuto",
		"isAlreadyTranslated": true
	},
	"Green Reward Update": {
		"text": "Aktualizace zelené odměny",
		"isAlreadyTranslated": true
	},
	"Vouchers generation pending": {
		"text": "Čeká se na vygenerování poukazů",
		"isAlreadyTranslated": true
	},
	"Recycle Request cancelled": {
		"text": "Požadavek na recyklaci byl zrušen",
		"isAlreadyTranslated": true
	},
	"Recycle Completed": {
		"text": "Recyklace byla dokončena",
		"isAlreadyTranslated": true
	},
	"Vouchers generated": {
		"text": "Poukazy byly vygenerovány",
		"isAlreadyTranslated": true
	},
	"Initial Dismantling Completed": {
		"text": "Počáteční demontáž byla dokončena",
		"isAlreadyTranslated": true
	},
	"Device recycle initiated": {
		"text": "Recyklace zařízení byla zahájena",
		"isAlreadyTranslated": true
	},
	"Claim withdrawn": {
		"text": "Reklamace byla stažena",
		"isAlreadyTranslated": true
	},
	"Pending Verification": {
		"text": "Čeká se na ověření",
		"isAlreadyTranslated": true
	},
	"Screen damage": {
		"text": "Poškození displeje",
		"isAlreadyTranslated": true
	},
	"Print": {
		"text": "Tisk",
		"isAlreadyTranslated": true
	},
	"Pupa Service Record": {
		"text": "Servisní záznam Pupa",
		"isAlreadyTranslated": true
	},
	"User Name": {
		"text": "Jméno uživatele",
		"isAlreadyTranslated": true
	},
	"Telephone": {
		"text": "Telefon",
		"isAlreadyTranslated": true
	},
	"Product Model": {
		"text": "Model produktu",
		"isAlreadyTranslated": true
	},
	"Purchase Date": {
		"text": "Datum nákupu",
		"isAlreadyTranslated": true
	},
	"Carry-in Time": {
		"text": "Čas donášky",
		"isAlreadyTranslated": true
	},
	"Pick-up Time": {
		"text": "Čas vyzvednutí",
		"isAlreadyTranslated": true
	},
	"Key Account Name": {
		"text": "Název zákazníka",
		"isAlreadyTranslated": true
	},
	"Service Offering": {
		"text": "Nabídka služeb",
		"isAlreadyTranslated": true
	},
	"In warranty": {
		"text": "V záruce",
		"isAlreadyTranslated": true
	},
	"Problem Description by User": {
		"text": "Popis problému uvedený uživatelem",
		"isAlreadyTranslated": true
	},
	"Parts replacement": {
		"text": "Výměna dílů",
		"isAlreadyTranslated": true
	},
	"New IMEI/SN": {
		"text": "Nové číslo IMEI/sériové č.",
		"isAlreadyTranslated": true
	},
	"Part Number": {
		"text": "Číslo dílu",
		"isAlreadyTranslated": true
	},
	"Model Number": {
		"text": "Číslo modelu",
		"isAlreadyTranslated": true
	},
	"Replacement Name": {
		"text": "Název výměny",
		"isAlreadyTranslated": true
	},
	"Engineer Signature": {
		"text": "Podpis technika",
		"isAlreadyTranslated": true
	},
	"Finish Date": {
		"text": "Datum dokončení",
		"isAlreadyTranslated": true
	},
	"Very Satisfied": {
		"text": "Velmi spokojen",
		"isAlreadyTranslated": true
	},
	"Satisfied": {
		"text": "Spokojen",
		"isAlreadyTranslated": true
	},
	"Neutral": {
		"text": "Neutrální",
		"isAlreadyTranslated": true
	},
	"Dissatisfied": {
		"text": "Nespokojen",
		"isAlreadyTranslated": true
	},
	"Very Dissatisfied": {
		"text": "Velmi nespokojen",
		"isAlreadyTranslated": true
	},
	"User Signature": {
		"text": "Podpis uživatele",
		"isAlreadyTranslated": true
	},
	"Date": {
		"text": "Datum",
		"isAlreadyTranslated": true
	},
	"Thank you for choosing Pupa": {
		"text": "Děkujeme, že jste zvolili Pupa",
		"isAlreadyTranslated": true
	},
	"Pupa Service Form": {
		"text": "Servisní formulář Pupa",
		"isAlreadyTranslated": true
	},
	"Recovery Method": {
		"text": "Způsob obnovení",
		"isAlreadyTranslated": true
	},
	"Defective Model Name": {
		"text": "Název vadného modelu",
		"isAlreadyTranslated": true
	},
	"Defective Part Name": {
		"text": "Název vadného dílu",
		"isAlreadyTranslated": true
	},
	"Coverage Type": {
		"text": "Typ rozsahu platnosti",
		"isAlreadyTranslated": true
	},
	"Service Ticket No": {
		"text": "Č. servisního tiketu",
		"isAlreadyTranslated": true
	},
	"Customer Care": {
		"text": "Péče o zákazníky",
		"isAlreadyTranslated": true
	},
	"Are you satisfied with Pupa service experience": {
		"text": "Jste spokojeni se zkušenostmi se servisem Pupa",
		"isAlreadyTranslated": true
	},
	"Please comment if you are dissatisfied": {
		"text": "Jste-li nespokojeni, uveďte komentář",
		"isAlreadyTranslated": true
	},
	"Front Image": {
		"text": "Obrázek zepředu",
		"isAlreadyTranslated": true
	},
	"Back Image": {
		"text": "Obrázek zezadu",
		"isAlreadyTranslated": true
	},
	"Top Image": {
		"text": "Obrázek shora",
		"isAlreadyTranslated": true
	},
	"Bottom Image": {
		"text": "Obrázek zdola",
		"isAlreadyTranslated": true
	},
	"Left Image": {
		"text": "Obrázek zleva",
		"isAlreadyTranslated": true
	},
	"Right Image": {
		"text": "Obrázek zprava",
		"isAlreadyTranslated": true
	},
	"Device Invoice": {
		"text": "Faktura k zařízení",
		"isAlreadyTranslated": true
	},
	"Cosmetic Status of Device": {
		"text": "Stav vzhledu zařízení",
		"isAlreadyTranslated": true
	},
	"Latest diagnostics result": {
		"text": "Výsledek poslední diagnostiky",
		"isAlreadyTranslated": true
	},
	"Purchase Order Number": {
		"text": "Číslo nákupní objednávky",
		"isAlreadyTranslated": true
	},
	"National ID": {
		"text": "Národní ID",
		"isAlreadyTranslated": true
	},
	"Last Repair Date": {
		"text": "Datum poslední opravy",
		"isAlreadyTranslated": true
	},
	"Product Unique ID": {
		"text": "Unikátní ID produktu",
		"isAlreadyTranslated": true
	},
	"We were unable to find details from GSX, please select product manually": {
		"text": "Nemůžeme nalézt detaily v GSX; vyberte produkt ručně",
		"isAlreadyTranslated": true
	},
	"No serviceable consumer product found": {
		"text": "Nebyl nalezen žádný produkt spotřebitele umožňující servis",
		"isAlreadyTranslated": true
	},
	"Is Warranty Applicable for this request": {
		"text": "Vztahuje se na tento požadavek záruka?",
		"isAlreadyTranslated": true
	},
	"Where was the device kept at the time of incident": {
		"text": "Kde se zařízení v době incidentu nacházelo?",
		"isAlreadyTranslated": true
	},
	"min 100 characters": {
		"text": "alespoň 100 znaků",
		"isAlreadyTranslated": true
	},
	"LEFT": {
		"text": "ZBÝVÁ",
		"isAlreadyTranslated": true
	},
	"Please Enter Minimum 100 Characters": {
		"text": "Zadejte alespoň 100 znaků",
		"isAlreadyTranslated": true
	},
	"Enter Other Issue Description": {
		"text": "Zadejte další popis problému",
		"isAlreadyTranslated": true
	},
	"Send for raising request": {
		"text": "Odeslat k podání požadavku",
		"isAlreadyTranslated": true
	},
	"Send for raising request?": {
		"text": "Odeslat k podání požadavku?",
		"isAlreadyTranslated": true
	},
	"Air Conditioner": {
		"text": "Klimatizace",
		"isAlreadyTranslated": true
	},
	"STORAGE": {
		"text": "ÚLOŽIŠTĚ",
		"isAlreadyTranslated": true
	},
	"TELECOM SERVICES": {
		"text": "TELEKOMUNIKAČNÍ SLUŽBY",
		"isAlreadyTranslated": true
	},
	"Trade In Pickup": {
		"text": "Vyzvednutí na protiúčet",
		"isAlreadyTranslated": true
	},
	"Vibrator": {
		"text": "Vibrátor",
		"isAlreadyTranslated": true
	},
	"Sound": {
		"text": "Zvuk",
		"isAlreadyTranslated": true
	},
	"Display": {
		"text": "Displej",
		"isAlreadyTranslated": true
	},
	"Accessories": {
		"text": "Příslušenství",
		"isAlreadyTranslated": true
	},
	"Physical Damage": {
		"text": "Fyzické poškození",
		"isAlreadyTranslated": true
	},
	"System Performance": {
		"text": "Výkon systému",
		"isAlreadyTranslated": true
	},
	"Power": {
		"text": "Napájení",
		"isAlreadyTranslated": true
	},
	"Built in Camera": {
		"text": "Vestavěná kamera",
		"isAlreadyTranslated": true
	},
	"Data Storage": {
		"text": "Datové úložiště",
		"isAlreadyTranslated": true
	},
	"Communication/Networking": {
		"text": "Komunikace/síť",
		"isAlreadyTranslated": true
	},
	"Consumer Product already exists with Serial/IMEI number": {
		"text": "Produkt spotřebitele s tímto sériovým číslem / číslem IMEI již existuje",
		"isAlreadyTranslated": true
	},
	"Oops. We don’t service this device at this location. But we’re hard at work and you should get some good news soon. Try later?": {
		"text": "Jejda! Toto zařízení nemůžeme na tomto místě opravit. Pracujeme ale na tom a brzy se vám ozveme s dobrými zprávami. Zkusit později?",
		"isAlreadyTranslated": true
	},
	"Enter Issue Decsription": {
		"text": "Zadejte popis problému",
		"isAlreadyTranslated": true
	},
	"office": {
		"text": "pobočka",
		"isAlreadyTranslated": true
	},
	"Enter a location": {
		"text": "Zadejte místo",
		"isAlreadyTranslated": true
	},
	"Accept": {
		"text": "Přijmout",
		"isAlreadyTranslated": true
	},
	"Reject": {
		"text": "Zamítnout",
		"isAlreadyTranslated": true
	},
	"Warranty Concession": {
		"text": "Uplatnění záruky",
		"isAlreadyTranslated": true
	},
	"No order lines found for given request": {
		"text": "Pro zadaný požadavek nebyly nalezeny žádné řádky objednávky",
		"isAlreadyTranslated": true
	},
	"Toggle Warranty": {
		"text": "Aktivovat záruku",
		"isAlreadyTranslated": true
	},
	"Provide link for customer": {
		"text": "Předat odkaz pro zákazníka",
		"isAlreadyTranslated": true
	},
	"Accept or Reject Request": {
		"text": "Přijmout nebo zamítnout požadavek",
		"isAlreadyTranslated": true
	},
	"Inward": {
		"text": "Příchozí",
		"isAlreadyTranslated": true
	},
	"Outward": {
		"text": "Odchozí",
		"isAlreadyTranslated": true
	},
	"Max number of retry attempts exceeded": {
		"text": "Byl dosažen max. počet opakovaných pokusů",
		"isAlreadyTranslated": true
	},
	"Please Enter Incident Place of Damage": {
		"text": "Zadejte místo, kde k poškození došlo",
		"isAlreadyTranslated": true
	},
	"Please enter the link": {
		"text": "Zadejte odkaz",
		"isAlreadyTranslated": true
	},
	"Please Select Device Receiving Time": {
		"text": "Vyberte čas příjmu zařízení",
		"isAlreadyTranslated": true
	},
	"Protection Status": {
		"text": "Stav ochrany",
		"isAlreadyTranslated": true
	},
	"Config Description": {
		"text": "Popis konfigurace",
		"isAlreadyTranslated": true
	},
	"Please specify the reason...": {
		"text": "Uveďte důvod...",
		"isAlreadyTranslated": true
	},
	"An error has occurred. Please contact support@servify.in for help.": {
		"text": "Došlo k chybě. Potřebujete-li pomoc, obraťte se na adresu support@servify.in.",
		"isAlreadyTranslated": true
	},
	"The parts will be returned to Store": {
		"text": "Díly budou vráceny do prodejny",
		"isAlreadyTranslated": true
	},
	"Select a Part Name": {
		"text": "Vyberte název dílu",
		"isAlreadyTranslated": true
	},
	"Restore from Backup History": {
		"text": "Obnovit z historie záloh",
		"isAlreadyTranslated": true
	},
	"PASSED": {
		"text": "ÚSPĚCH",
		"isAlreadyTranslated": true
	},
	"Backup History": {
		"text": "Historie záloh",
		"isAlreadyTranslated": true
	},
	"WARNING : This device has not been backed up in the last two weeks.": {
		"text": "VAROVÁNÍ: Toto zařízení nebylo v posledních dvou týdnech zálohováno.",
		"isAlreadyTranslated": true
	},
	"Sensors": {
		"text": "Senzory",
		"isAlreadyTranslated": true
	},
	"Temperature History": {
		"text": "Historie teploty",
		"isAlreadyTranslated": true
	},
	"Accelerometer": {
		"text": "Akcelerometr",
		"isAlreadyTranslated": true
	},
	"Accelerometer Sensor Test": {
		"text": "Test senzoru akcelerometru",
		"isAlreadyTranslated": true
	},
	"ALS": {
		"text": "ALS",
		"isAlreadyTranslated": true
	},
	"Ambient Light Sensor Test": {
		"text": "Test senzoru okolního osvětlení",
		"isAlreadyTranslated": true
	},
	"Bluetooth": {
		"text": "Bluetooth",
		"isAlreadyTranslated": true
	},
	"Bluetooth Scan Test": {
		"text": "Test procházení Bluetooth",
		"isAlreadyTranslated": true
	},
	"Camera": {
		"text": "Fotoaparát",
		"isAlreadyTranslated": true
	},
	"Camera Sensor Test": {
		"text": "Test senzoru fotoaparátu",
		"isAlreadyTranslated": true
	},
	"Gyro": {
		"text": "Gyroskop",
		"isAlreadyTranslated": true
	},
	"Gyro Sensor Test": {
		"text": "Test senzoru gyroskopu",
		"isAlreadyTranslated": true
	},
	"Touch ID": {
		"text": "Touch ID",
		"isAlreadyTranslated": true
	},
	"Presence Test": {
		"text": "Test přítomnosti",
		"isAlreadyTranslated": true
	},
	"Wi-Fi": {
		"text": "Wi-Fi",
		"isAlreadyTranslated": true
	},
	"Software": {
		"text": "Software",
		"isAlreadyTranslated": true
	},
	"Version Test": {
		"text": "Test verze",
		"isAlreadyTranslated": true
	},
	"System": {
		"text": "Systém",
		"isAlreadyTranslated": true
	},
	"Usage Profile": {
		"text": "Profil využití",
		"isAlreadyTranslated": true
	},
	"Battery": {
		"text": "Baterie",
		"isAlreadyTranslated": true
	},
	"Health Check": {
		"text": "Kontrola zdraví",
		"isAlreadyTranslated": true
	},
	"PASSED : Testing completed and no issues were found on this device.": {
		"text": "ÚSPĚCH: Testování bylo dokončeno a na zařízení nebyly nalezeny žádné problémy.",
		"isAlreadyTranslated": true
	},
	"App Crash History": {
		"text": "Historie pádů aplikací",
		"isAlreadyTranslated": true
	},
	"Unexpected Shutdown History": {
		"text": "Historie neočekávaných vypnutí",
		"isAlreadyTranslated": true
	},
	"Power Button": {
		"text": "Vypínač",
		"isAlreadyTranslated": true
	},
	"Home Button": {
		"text": "Tlačítko Domů",
		"isAlreadyTranslated": true
	},
	"Volume Down Button": {
		"text": "Tlačítko snížení hlasitosti",
		"isAlreadyTranslated": true
	},
	"LCD Screen": {
		"text": "LCD displej",
		"isAlreadyTranslated": true
	},
	"Ear Piece": {
		"text": "Kryt sluchátka",
		"isAlreadyTranslated": true
	},
	"Back Camera": {
		"text": "Zadní fotoaparát",
		"isAlreadyTranslated": true
	},
	"Network Connection": {
		"text": "Síťové připojení",
		"isAlreadyTranslated": true
	},
	"IMEI Check": {
		"text": "Kontrola IMEI",
		"isAlreadyTranslated": true
	},
	"Incoming call": {
		"text": "Příchozí hovor",
		"isAlreadyTranslated": true
	},
	"Microphone": {
		"text": "Mikrofon",
		"isAlreadyTranslated": true
	},
	"USB Connectivity": {
		"text": "USB konektivita",
		"isAlreadyTranslated": true
	},
	"Battery Storage": {
		"text": "Přihrádka baterie",
		"isAlreadyTranslated": true
	},
	"LED Flash": {
		"text": "LED blesk",
		"isAlreadyTranslated": true
	},
	"TAB Button": {
		"text": "Tlačítko TAB",
		"isAlreadyTranslated": true
	},
	"Unlock Button": {
		"text": "Tlačítko odemknutí",
		"isAlreadyTranslated": true
	},
	"Full Diagnosis": {
		"text": "Kompletní diagnostika",
		"isAlreadyTranslated": true
	},
	"Proper Assembly": {
		"text": "Správné sestavení",
		"isAlreadyTranslated": true
	},
	"Back Button": {
		"text": "Tlačítko Zpět",
		"isAlreadyTranslated": true
	},
	"Volume Up Button": {
		"text": "Tlačítko zvýšení hlasitosti",
		"isAlreadyTranslated": true
	},
	"Dead Spots": {
		"text": "Slepé skvrny",
		"isAlreadyTranslated": true
	},
	"Charging Port": {
		"text": "Nabíjecí port",
		"isAlreadyTranslated": true
	},
	"Loud Speaker": {
		"text": "Reproduktor",
		"isAlreadyTranslated": true
	},
	"Front Camera": {
		"text": "Přední fotoaparát",
		"isAlreadyTranslated": true
	},
	"Outgoing call": {
		"text": "Odchozí hovor",
		"isAlreadyTranslated": true
	},
	"Software Version": {
		"text": "Verze softwaru",
		"isAlreadyTranslated": true
	},
	"GSX Confirmation Number": {
		"text": "Číslo potvrzení GSX",
		"isAlreadyTranslated": true
	},
	"Services": {
		"text": "Služby",
		"isAlreadyTranslated": true
	},
	"Check If Device Box IMEI Matches With Device IMEI": {
		"text": "Zkontrolovat, zda IMEI na krabici zařízení odpovídá IMEI na zařízení",
		"isAlreadyTranslated": true
	},
	"Check If Device IMEI Matches With Internal IMEI": {
		"text": "Zkontrolovat, zda IMEI na zařízení odpovídá IMEI uvnitř",
		"isAlreadyTranslated": true
	},
	"Check If Touch/Display Is Broken": {
		"text": "Zkontrolovat, zda není (dotykový) displej prasklý",
		"isAlreadyTranslated": true
	},
	"Check If SIM Tray Is Missing Or Broken": {
		"text": "Zkontrolovat, zda nechybí nebo není prasklý držák SIM",
		"isAlreadyTranslated": true
	},
	"Check If Back Cover Is Damaged": {
		"text": "Zkontrolovat, zda není poškozený zadní kryt",
		"isAlreadyTranslated": true
	},
	"Check If Screw Heads Are Damaged": {
		"text": "Zkontrolovat, zda nejsou poškozené hlavy šroubů",
		"isAlreadyTranslated": true
	},
	"Check If There Are Dents Or Internal Cracks In Display": {
		"text": "Zkontrolovat, zda na displeji nejsou vrypy nebo vnitřní praskliny",
		"isAlreadyTranslated": true
	},
	"Check If Volume Keys Are Loose Or Not Functioning": {
		"text": "Zkontrolovat, zda nejsou uvolněná a fungují tlačítka hlasitosti",
		"isAlreadyTranslated": true
	},
	"Check If Power Keys Are Loose Or Not Functioning": {
		"text": "Zkontrolovat, zda není uvolněný a funguje vypínač",
		"isAlreadyTranslated": true
	},
	"Check If Camera's Auto Focus Is Not Working": {
		"text": "Zkontrolovat, zda funguje automatické ostření fotoaparátu",
		"isAlreadyTranslated": true
	},
	"Check If Photos Clicked Are Blurred": {
		"text": "Zkontrolovat, zda nejsou snímky rozmazané",
		"isAlreadyTranslated": true
	},
	"Check If USB Port Is Damaged": {
		"text": "Zkontrolovat, zda není poškozený port USB",
		"isAlreadyTranslated": true
	},
	"Check If Accessories Are As Mentioned On The Sales Pack": {
		"text": "Zkontrolovat, zda příslušenství odpovídá informacím na balení",
		"isAlreadyTranslated": true
	},
	"Check If There Are Functional Defects": {
		"text": "Zkontrolovat, zda zařízení nemá funkční vady",
		"isAlreadyTranslated": true
	},
	"Ear Pods": {
		"text": "Sluchátka",
		"isAlreadyTranslated": true
	},
	"Power Adapter": {
		"text": "Napájecí adaptér",
		"isAlreadyTranslated": true
	},
	"Lightning to USB Cable": {
		"text": "Kabel Lightning/USB",
		"isAlreadyTranslated": true
	},
	"Display Damaged": {
		"text": "Poškozený displej",
		"isAlreadyTranslated": true
	},
	"Battery Damaged": {
		"text": "Poškozená baterie",
		"isAlreadyTranslated": true
	},
	"Backup Required": {
		"text": "Nutno provést zálohu",
		"isAlreadyTranslated": true
	},
	"Find my iPhone Active": {
		"text": "Aktivovaná funkce Najít můj iPhone",
		"isAlreadyTranslated": true
	},
	"Unlock.": {
		"text": "Odemknout.",
		"isAlreadyTranslated": true
	},
	"Functional Defects": {
		"text": "Funkční vady",
		"isAlreadyTranslated": true
	},
	"Accessories as per Content printed on sales pack": {
		"text": "Příslušenství podle informací uvedených na balení",
		"isAlreadyTranslated": true
	},
	"USB Port Damaged": {
		"text": "Poškozený port USB",
		"isAlreadyTranslated": true
	},
	"Camera AF & Blurr Issues": {
		"text": "Problémy s automatickým ostřením a ostrostí snímků",
		"isAlreadyTranslated": true
	},
	"Power/Volume Keys Loose or Not Functioning": {
		"text": "Uvolněný nebo nefungující vypínač/tlačítka hlasitosti",
		"isAlreadyTranslated": true
	},
	"Any Dents or Internal Crack in display": {
		"text": "Vrypy nebo vnitřní praskliny na displeji",
		"isAlreadyTranslated": true
	},
	"Screw heads Damaged": {
		"text": "Poškozené hlavy šroubů",
		"isAlreadyTranslated": true
	},
	"Back Cover Damaged": {
		"text": "Poškozený zadní kryt",
		"isAlreadyTranslated": true
	},
	"SIM Tray Missing or Broken": {
		"text": "Chybějící nebo prasklý držák SIM",
		"isAlreadyTranslated": true
	},
	"Touch/LCD Broken": {
		"text": "Prasklý (dotykový) displej",
		"isAlreadyTranslated": true
	},
	"Unit IMEI Vs Internal IMEI": {
		"text": "IMEI jednotky vs. IMEI uvnitř",
		"isAlreadyTranslated": true
	},
	"Box IMEI Vs Unit IMEI": {
		"text": "IMEI na krabici vs. IMEI jednotky",
		"isAlreadyTranslated": true
	},
	"Please enter alphabets or numbers only": {
		"text": "Můžete zadat pouze písmena nebo číslice",
		"isAlreadyTranslated": true
	},
	"Please enter numbers only": {
		"text": "Můžete zadat pouze číslice",
		"isAlreadyTranslated": true
	},
	"Consumer Email": {
		"text": "E-mail spotřebitele",
		"isAlreadyTranslated": true
	},
	"IMEI Number 1": {
		"text": "Číslo IMEI 1",
		"isAlreadyTranslated": true
	},
	"IMEI Number 2": {
		"text": "Číslo IMEI 2",
		"isAlreadyTranslated": true
	},
	"Reassign Service": {
		"text": "Znovu přiřadit servis",
		"isAlreadyTranslated": true
	},
	"Select a service location from the drop down": {
		"text": "Vyberte z rozevíracího seznamu servisní místo",
		"isAlreadyTranslated": true
	},
	"The same service center and same engineer is already assigned.": {
		"text": "Toto servisní středisko a technik je již přiřazen.",
		"isAlreadyTranslated": true
	},
	"Service center and engineer has been reassigned.": {
		"text": "Servisní středisko a technik byl znovu přiřazen.",
		"isAlreadyTranslated": true
	},
	"The same service center is already assigned.": {
		"text": "Toto servisní středisko je již přiřazeno.",
		"isAlreadyTranslated": true
	},
	"Service center has been reassigned.": {
		"text": "Servisní středisko bylo znovu přiřazeno.",
		"isAlreadyTranslated": true
	},
	"First Dialer Dispostion": {
		"text": "Konfigurace prvního číselníku",
		"isAlreadyTranslated": true
	},
	"Second Dialer Dispostion": {
		"text": "Konfigurace druhého číselníku",
		"isAlreadyTranslated": true
	},
	"Reschedule Service": {
		"text": "Změnit termín servisu",
		"isAlreadyTranslated": true
	},
	"No slots Available": {
		"text": "Nejsou k dispozici žádné časy",
		"isAlreadyTranslated": true
	},
	"Reason for reschedule": {
		"text": "Důvod změny termínu",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to reschedule?": {
		"text": "Opravdu chcete změnit termín?",
		"isAlreadyTranslated": true
	},
	"The request was rescheduled successfully": {
		"text": "Termín požadavku byl úspěšně změněn",
		"isAlreadyTranslated": true
	},
	"Please specify the reason for reschedule": {
		"text": "Uveďte důvod změny termínu",
		"isAlreadyTranslated": true
	},
	"Please select a slot for reschedule": {
		"text": "Vyberte čas pro změnu termínu",
		"isAlreadyTranslated": true
	},
	"Service Location": {
		"text": "Servisní místo",
		"isAlreadyTranslated": true
	},
	"Device Replacement Mode": {
		"text": "Režim výměny zařízení",
		"isAlreadyTranslated": true
	},
	"Generate DOA Certificate": {
		"text": "Vygenerovat certifikát o poškození při doručení",
		"isAlreadyTranslated": true
	},
	"Get New Device": {
		"text": "Získat nové zařízení",
		"isAlreadyTranslated": true
	},
	"Choose Device": {
		"text": "Vybrat zařízení",
		"isAlreadyTranslated": true
	},
	"Enter Information for the Device you are issuing": {
		"text": "Zadejte informace o zařízení, které vydáváte",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI 1": {
		"text": "Zadejte správné číslo IMEI 1",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI 2": {
		"text": "Zadejte správné číslo IMEI 2",
		"isAlreadyTranslated": true
	},
	"Please enter correct Serial Number": {
		"text": "Zadejte správné sériové číslo",
		"isAlreadyTranslated": true
	},
	"Action on Device": {
		"text": "Akce se zařízením",
		"isAlreadyTranslated": true
	},
	"Status Date": {
		"text": "Datum stavu",
		"isAlreadyTranslated": true
	},
	"Symptoms reported by Engineer": {
		"text": "Příznaky hlášené technikem",
		"isAlreadyTranslated": true
	},
	"Symptoms": {
		"text": "Příznaky",
		"isAlreadyTranslated": true
	},
	"Add New Symptom Codes": {
		"text": "Přidat kódy nových příznaků",
		"isAlreadyTranslated": true
	},
	"No symptoms added": {
		"text": "Nebyly přidány žádné příznaky",
		"isAlreadyTranslated": true
	},
	"Symptom already added by the engineer": {
		"text": "Příznak byl již přidán technikem",
		"isAlreadyTranslated": true
	},
	"Please validate all the issues": {
		"text": "Ověřte všechny problémy",
		"isAlreadyTranslated": true
	},
	"Verification Attempts Details": {
		"text": "Detaily pokusů o ověření",
		"isAlreadyTranslated": true
	},
	"Number of Attempts Remaining": {
		"text": "Zbývající počet pokusů",
		"isAlreadyTranslated": true
	},
	"Value Used For Verification": {
		"text": "Hodnota použitá k ověření",
		"isAlreadyTranslated": true
	},
	"Verified": {
		"text": "Ověřeno",
		"isAlreadyTranslated": true
	},
	"Not Verified": {
		"text": "Neověřeno",
		"isAlreadyTranslated": true
	},
	"Verification Not Attempted": {
		"text": "Pokus o ověření neproběhl",
		"isAlreadyTranslated": true
	},
	"Request is being processed.": {
		"text": "Požadavek se zpracovává.",
		"isAlreadyTranslated": true
	},
	"Hub Details": {
		"text": "Detaily centra",
		"isAlreadyTranslated": true
	},
	"Logistic Partners": {
		"text": "Logističtí partneři",
		"isAlreadyTranslated": true
	},
	"Logistic Partner Name": {
		"text": "Název logistického partnera",
		"isAlreadyTranslated": true
	},
	"Recommended": {
		"text": "Doporučeno",
		"isAlreadyTranslated": true
	},
	"Schedule Request": {
		"text": "Naplánovat požadavek",
		"isAlreadyTranslated": true
	},
	"Please select a Logistic Partner": {
		"text": "Vyberte logistického partnera",
		"isAlreadyTranslated": true
	},
	"SMS Sent Successfully": {
		"text": "SMS zpráva byla úspěšně odeslána",
		"isAlreadyTranslated": true
	},
	"Please select a Hub": {
		"text": "Vyberte centrum",
		"isAlreadyTranslated": true
	},
	"Upload Document": {
		"text": "Nahrát dokument",
		"isAlreadyTranslated": true
	},
	"Add Certificate": {
		"text": "Přidat certifikát",
		"isAlreadyTranslated": true
	},
	"Vouchers generated successfully": {
		"text": "Poukazy byly úspěšně vygenerovány",
		"isAlreadyTranslated": true
	},
	"Document Type: Cheque": {
		"text": "Typ dokumentu: Šek",
		"isAlreadyTranslated": true
	},
	"Claim Amount": {
		"text": "Částka reklamace",
		"isAlreadyTranslated": true
	},
	"Please enter Claim Amount:": {
		"text": "Zadejte částku reklamace:",
		"isAlreadyTranslated": true
	},
	"Please enter Claim Amount before submitting": {
		"text": "Před odesláním zadejte částku reklamace",
		"isAlreadyTranslated": true
	},
	"Invoice Value": {
		"text": "Hodnota faktury",
		"isAlreadyTranslated": true
	},
	"Repair Cost": {
		"text": "Cena opravy",
		"isAlreadyTranslated": true
	},
	"Mandatory Deductible": {
		"text": "Povinný odpočet",
		"isAlreadyTranslated": true
	},
	"BER Payment Amount": {
		"text": "Částka platby za ekonomicky neopravitelné zařízení",
		"isAlreadyTranslated": true
	},
	"Approve BER": {
		"text": "Schválit ekonomickou neopravitelnost",
		"isAlreadyTranslated": true
	},
	"Please enter Override Amount before submitting": {
		"text": "Před odesláním zadejte prioritní částku",
		"isAlreadyTranslated": true
	},
	"Please mention the incident in not less than 100 characters on how, when & where the damage happened": {
		"text": "Popište incident pomocí nejvýše 100 znaků; uveďte jak, kdy a kde k poškození došlo",
		"isAlreadyTranslated": true
	},
	"Enter Loaner Device Details": {
		"text": "Zadejte detaily náhradního zařízení",
		"isAlreadyTranslated": true
	},
	"Select IMEI Number": {
		"text": "Vybrat číslo IMEI",
		"isAlreadyTranslated": true
	},
	"Receive Loaner Device": {
		"text": "Přijmout náhradní zařízení",
		"isAlreadyTranslated": true
	},
	"Condition Of the Loaner Device": {
		"text": "Stav náhradního zařízení",
		"isAlreadyTranslated": true
	},
	"Good": {
		"text": "Dobrý",
		"isAlreadyTranslated": true
	},
	"Bad": {
		"text": "Špatný",
		"isAlreadyTranslated": true
	},
	"Ugly": {
		"text": "Velmi špatný",
		"isAlreadyTranslated": true
	},
	"Loaner Device Details added successfully": {
		"text": "Detaily náhradního zařízení byly úspěšně přidány",
		"isAlreadyTranslated": true
	},
	"Please choose from the given list only.": {
		"text": "Můžete vybírat pouze z uvedeného seznamu.",
		"isAlreadyTranslated": true
	},
	"Please tell us about the condition of the Loaner Device.": {
		"text": "Popište nám stav náhradního zařízení.",
		"isAlreadyTranslated": true
	},
	"Please write about the condition of the device.": {
		"text": "Napište více o stavu náhradního zařízení.",
		"isAlreadyTranslated": true
	},
	"From": {
		"text": "Od",
		"isAlreadyTranslated": true
	},
	"To": {
		"text": "Do",
		"isAlreadyTranslated": true
	},
	"Consumer Mobile": {
		"text": "Mobil spotřebitele",
		"isAlreadyTranslated": true
	},
	"Alternate No./Landline No.": {
		"text": "Alternativní č. / pevná linka",
		"isAlreadyTranslated": true
	},
	"Customer Alternate Mobile No.": {
		"text": "Alternativní mobilní č. zákazníka",
		"isAlreadyTranslated": true
	},
	"Save & Send SMS/Email": {
		"text": "Uložit a odeslat SMS/e-mail",
		"isAlreadyTranslated": true
	},
	"consumer(s) are shown above.": {
		"text": "spotřebitelé jsou uvedeni výše.",
		"isAlreadyTranslated": true
	},
	"Call logs": {
		"text": "Protokoly hovorů",
		"isAlreadyTranslated": true
	},
	"customer@email.com": {
		"text": "customer@email.com",
		"isAlreadyTranslated": true
	},
	"IMEI / Serial No.": {
		"text": "IMEI / sériové č.",
		"isAlreadyTranslated": true
	},
	"Servify Diagnostic App": {
		"text": "Diagnostická aplikace Servify",
		"isAlreadyTranslated": true
	},
	"Serial No.": {
		"text": "Sériové č.",
		"isAlreadyTranslated": true
	},
	"IMEI 2": {
		"text": "IMEI 2",
		"isAlreadyTranslated": true
	},
	"Referece ID": {
		"text": "Referenční ID",
		"isAlreadyTranslated": true
	},
	"Claim Form": {
		"text": "Reklamační formulář",
		"isAlreadyTranslated": true
	},
	"Claim Form Servify": {
		"text": "Reklamační formulář Servify",
		"isAlreadyTranslated": true
	},
	"Authorization Letter": {
		"text": "Autorizační dopis",
		"isAlreadyTranslated": true
	},
	"PAN Card Front": {
		"text": "Karta PAN, přední strana",
		"isAlreadyTranslated": true
	},
	"Aadhar Card Front": {
		"text": "Karta Aadhar, přední strana",
		"isAlreadyTranslated": true
	},
	"Aadhar Card Back": {
		"text": "Karta Aadhar, zadní strana",
		"isAlreadyTranslated": true
	},
	"Other Documents": {
		"text": "Další dokumenty",
		"isAlreadyTranslated": true
	},
	"Cheque": {
		"text": "Šek",
		"isAlreadyTranslated": true
	},
	"Video": {
		"text": "Video",
		"isAlreadyTranslated": true
	},
	"Device image": {
		"text": "Obrázek zařízení",
		"isAlreadyTranslated": true
	},
	"Destruction Form": {
		"text": "Formulář zničení",
		"isAlreadyTranslated": true
	},
	"P-Slip": {
		"text": "Složka P",
		"isAlreadyTranslated": true
	},
	"Consumer Signature": {
		"text": "Podpis spotřebitele",
		"isAlreadyTranslated": true
	},
	"Issue Image": {
		"text": "Obrázek problému",
		"isAlreadyTranslated": true
	},
	"Diagnosis report": {
		"text": "Diagnostický protokol",
		"isAlreadyTranslated": true
	},
	"Delivery Note": {
		"text": "Dodací list",
		"isAlreadyTranslated": true
	},
	"Product Damage Feedback Item": {
		"text": "Položka zpětné vazby k poškození produktu",
		"isAlreadyTranslated": true
	},
	"Claim Form in": {
		"text": "Reklamační formulář v",
		"isAlreadyTranslated": true
	},
	"IMEI No": {
		"text": "Č. IMEI",
		"isAlreadyTranslated": true
	},
	"IssueText": {
		"text": "Text problému",
		"isAlreadyTranslated": true
	},
	"IssueRemark": {
		"text": "Poznámka k problému",
		"isAlreadyTranslated": true
	},
	"otherIssueText": {
		"text": "text dalšího problému",
		"isAlreadyTranslated": true
	},
	"screen": {
		"text": "displej",
		"isAlreadyTranslated": true
	},
	"Consumer To Service Centre": {
		"text": "Od spotřebitele do servisního střediska",
		"isAlreadyTranslated": true
	},
	"Service Centre To Consumer": {
		"text": "Ze servisního střediska ke spotřebiteli",
		"isAlreadyTranslated": true
	},
	"On": {
		"text": "V",
		"isAlreadyTranslated": true
	},
	"at": {
		"text": "v",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial": {
		"text": "IMEI/sériové č.",
		"isAlreadyTranslated": true
	},
	"Insufficient Data For Graph. Data": {
		"text": "Nedostatečná data pro graf. Data",
		"isAlreadyTranslated": true
	},
	"is": {
		"text": "není",
		"isAlreadyTranslated": true
	},
	"Not a valid user.": {
		"text": "platný uživatel.",
		"isAlreadyTranslated": true
	},
	"Rejection Reasons and Expected Actions by APR - Summary": {
		"text": "Důvody zamítnutí a očekávané akce ze strany APR – přehled",
		"isAlreadyTranslated": true
	},
	"Error Message on App": {
		"text": "Chybová zpráva v aplikaci",
		"isAlreadyTranslated": true
	},
	"Error Code": {
		"text": "Kód chyby",
		"isAlreadyTranslated": true
	},
	"Explanation of Error Code": {
		"text": "Význam kódu chyby",
		"isAlreadyTranslated": true
	},
	"Expected Action from APR": {
		"text": "Očekávaná akce ze strany APR",
		"isAlreadyTranslated": true
	},
	"Store Name & Location": {
		"text": "Název a místo prodejny",
		"isAlreadyTranslated": true
	},
	"Download Activation Report": {
		"text": "Stáhnout protokol aktivace",
		"isAlreadyTranslated": true
	},
	"Filter Consumers": {
		"text": "Filtrovat spotřebitele",
		"isAlreadyTranslated": true
	},
	"Order ID": {
		"text": "ID objednávky",
		"isAlreadyTranslated": true
	},
	"Order Item ID": {
		"text": "ID položky objednávky",
		"isAlreadyTranslated": true
	},
	"User Details": {
		"text": "Údaje o uživateli",
		"isAlreadyTranslated": true
	},
	"Plan Purchased": {
		"text": "Plán byl zakoupen",
		"isAlreadyTranslated": true
	},
	"Activated On": {
		"text": "Aktivováno dne",
		"isAlreadyTranslated": true
	},
	"Status Message": {
		"text": "Stavová zpráva",
		"isAlreadyTranslated": true
	},
	"Error Code in": {
		"text": "Kód chyby v",
		"isAlreadyTranslated": true
	},
	"Something went wrong when trying to create a consumer. Please try again.": {
		"text": "­Při pokusu o vytvoření spotřebitele se něco pokazilo. Zkuste to znovu.",
		"isAlreadyTranslated": true
	},
	"Consumer successfully added and SMS sent!": {
		"text": "Spotřebitel byl úspěšně přidán a byla odeslána SMS zpráva!",
		"isAlreadyTranslated": true
	},
	"Please enter correct E-mail ID.": {
		"text": "Zadejte správnou e-mailovou adresu.",
		"isAlreadyTranslated": true
	},
	"Please enter correct Mobile Number.": {
		"text": "Zadejte správné mobilní číslo.",
		"isAlreadyTranslated": true
	},
	"Please enter Mobile Number.": {
		"text": "Zadejte mobilní číslo.",
		"isAlreadyTranslated": true
	},
	"Please select a Device Type.": {
		"text": "Vyberte typ zařízení.",
		"isAlreadyTranslated": true
	},
	"Please enter at least one issue.": {
		"text": "Zadejte alespoň jeden problém.",
		"isAlreadyTranslated": true
	},
	"Please enter Incident ID.": {
		"text": "Zadejte ID incidentu.",
		"isAlreadyTranslated": true
	},
	"Please select a model from the dropdown.": {
		"text": "Vyberte z rozevíracího seznamu model.",
		"isAlreadyTranslated": true
	},
	"Please enter E-mail ID.": {
		"text": "Zadejte e-mailovou adresu.",
		"isAlreadyTranslated": true
	},
	"Please enter Customer's Name.": {
		"text": "Zadejte jméno zákazníka.",
		"isAlreadyTranslated": true
	},
	"Doc": {
		"text": "Doc",
		"isAlreadyTranslated": true
	},
	"Allowed Repairs": {
		"text": "Povolený počet oprav",
		"isAlreadyTranslated": true
	},
	"Used Repairs": {
		"text": "Počet využitých oprav",
		"isAlreadyTranslated": true
	},
	"Tax Payable": {
		"text": "Splatná daň",
		"isAlreadyTranslated": true
	},
	"View queued requestsat": {
		"text": "Zobrazit požadavky ve frontě v",
		"isAlreadyTranslated": true
	},
	"Generate Vouchers": {
		"text": "Vygenerovat poukazy",
		"isAlreadyTranslated": true
	},
	"Customer's Address Details": {
		"text": "Detaily adresy zákazníka",
		"isAlreadyTranslated": true
	},
	"View queued requests": {
		"text": "Zobrazit požadavky ve frontě",
		"isAlreadyTranslated": true
	},
	"Engineer Notification": {
		"text": "Oznámení technika",
		"isAlreadyTranslated": true
	},
	"Engineer Notifications": {
		"text": "Oznámení technika",
		"isAlreadyTranslated": true
	},
	"Optin Requests": {
		"text": "Požadavky Optin",
		"isAlreadyTranslated": true
	},
	"Plan Sales Report": {
		"text": "Zpráva o prodeji plánů",
		"isAlreadyTranslated": true
	},
	"APR Plans": {
		"text": "Plány APR",
		"isAlreadyTranslated": true
	},
	"Engineer Login Report": {
		"text": "Zpráva o přihlášení technika",
		"isAlreadyTranslated": true
	},
	"Service location Report": {
		"text": "Zpráva o servisním místě",
		"isAlreadyTranslated": true
	},
	"Mapping": {
		"text": "Mapa",
		"isAlreadyTranslated": true
	},
	"Reports": {
		"text": "Zprávy",
		"isAlreadyTranslated": true
	},
	"billing": {
		"text": "fakturace",
		"isAlreadyTranslated": true
	},
	"Engineer Tracking": {
		"text": "Sledování technika",
		"isAlreadyTranslated": true
	},
	"Service Requests": {
		"text": "Servisní požadavky",
		"isAlreadyTranslated": true
	},
	"Engineer Report": {
		"text": "Zpráva technika",
		"isAlreadyTranslated": true
	},
	"Visit Report": {
		"text": "Zpráva o návštěvě",
		"isAlreadyTranslated": true
	},
	"Billing": {
		"text": "Fakturace",
		"isAlreadyTranslated": true
	},
	"Upload document": {
		"text": "Nahrát dokument",
		"isAlreadyTranslated": true
	},
	"View Documents": {
		"text": "Zobrazit dokumenty",
		"isAlreadyTranslated": true
	},
	"File Name": {
		"text": "Název souboru",
		"isAlreadyTranslated": true
	},
	"Uploaded By": {
		"text": "Nahráno uživatele",
		"isAlreadyTranslated": true
	},
	"Uploaded Date & Time": {
		"text": "Datum a čas nahrání",
		"isAlreadyTranslated": true
	},
	"Wall Mount Or 60 Inch Or Large?": {
		"text": "Nástěnná montáž nebo rozměr přes 150 cm?",
		"isAlreadyTranslated": true
	},
	"Logistics - From Service Center to Customer's Address to Service Center": {
		"text": "Logistika – ze servisního střediska na adresu zákazníka a do servisního střediska",
		"isAlreadyTranslated": true
	},
	"Tier": {
		"text": "Úroveň",
		"isAlreadyTranslated": true
	},
	"Expected Delivery": {
		"text": "Očekávané doručení",
		"isAlreadyTranslated": true
	},
	"Reason for Reschedule": {
		"text": "Důvod změny termínu",
		"isAlreadyTranslated": true
	},
	"View Job Card": {
		"text": "Zobrazit zakázkovou kartu",
		"isAlreadyTranslated": true
	},
	"Begin Journey": {
		"text": "Zahájit cestu",
		"isAlreadyTranslated": true
	},
	"Serviceman Reached": {
		"text": "Doručeno servisnímu technikovi",
		"isAlreadyTranslated": true
	},
	"Initiate Exception": {
		"text": "Zahájit výjimku",
		"isAlreadyTranslated": true
	},
	"Complete Refund": {
		"text": "Provést vrácení peněz",
		"isAlreadyTranslated": true
	},
	"View Exception": {
		"text": "Zobrazit výjimku",
		"isAlreadyTranslated": true
	},
	"Visit Details": {
		"text": "Detaily návštěvy",
		"isAlreadyTranslated": true
	},
	"Tasks": {
		"text": "Úkoly",
		"isAlreadyTranslated": true
	},
	"Diagnosis Report": {
		"text": "Diagnostický protokol",
		"isAlreadyTranslated": true
	},
	"Please choose the product": {
		"text": "Vyberte produkt",
		"isAlreadyTranslated": true
	},
	"Billing Address": {
		"text": "Fakturační adresa",
		"isAlreadyTranslated": true
	},
	"Change Billing Address": {
		"text": "Změnit fakturační adresu",
		"isAlreadyTranslated": true
	},
	"Device Received & Inspected": {
		"text": "Zařízení bylo přijato a prošlo inspekcí",
		"isAlreadyTranslated": true
	},
	"Complete Inspection": {
		"text": "Dokončit inspekci",
		"isAlreadyTranslated": true
	},
	"Complete Device handover": {
		"text": "Dokončit přejímku zařízení",
		"isAlreadyTranslated": true
	},
	"Create shipment": {
		"text": "Vytvořit zásilku",
		"isAlreadyTranslated": true
	},
	"Waiting for the Refurbishment Hub Manager to receive device and complete mandatory checklist tasks.": {
		"text": "Čeká se na to, až vedoucí centra repasí obdrží zařízení a provede povinné úkony podle kontrolního seznamu.",
		"isAlreadyTranslated": true
	},
	"No of Repairs Used": {
		"text": "Počet využitých oprav",
		"isAlreadyTranslated": true
	},
	"No of Repairs Allowed": {
		"text": "Počet povolených oprav",
		"isAlreadyTranslated": true
	},
	"Customer Care notes": {
		"text": "Poznámky oddělení péče o zákazníky",
		"isAlreadyTranslated": true
	},
	"Call Type:": {
		"text": "Typ hovoru:",
		"isAlreadyTranslated": true
	},
	"Call Disposition": {
		"text": "Konfigurace hovoru",
		"isAlreadyTranslated": true
	},
	"Enter Amount": {
		"text": "Zadejte částku",
		"isAlreadyTranslated": true
	},
	"Map": {
		"text": "Mapa",
		"isAlreadyTranslated": true
	},
	"Number": {
		"text": "Číslo",
		"isAlreadyTranslated": true
	},
	"G Number": {
		"text": "G-číslo",
		"isAlreadyTranslated": true
	},
	"Turn off Find my iPhone": {
		"text": "Vypnout funkci Najít můj iPhone",
		"isAlreadyTranslated": true
	},
	"Add required documents in 'Request Documents' section below.": {
		"text": "Přidejte do sekce „Dokumenty k požadavku“ požadované dokumenty.",
		"isAlreadyTranslated": true
	},
	"Sub Category": {
		"text": "Podkategorie",
		"isAlreadyTranslated": true
	},
	"Generate Estimate": {
		"text": "Vygenerovat odhad",
		"isAlreadyTranslated": true
	},
	"Purchase Order No": {
		"text": "Č. nákupní objednávky",
		"isAlreadyTranslated": true
	},
	"Enter Information for the parts you have received": {
		"text": "Zadejte informace o dílech, které jste obdrželi",
		"isAlreadyTranslated": true
	},
	"Enter comptia for DOA parts": {
		"text": "Zadejte informace CompTIA pro díly poškozené při doručení",
		"isAlreadyTranslated": true
	},
	"Waiting for device to be received.": {
		"text": "Čeká se na přijetí zařízení.",
		"isAlreadyTranslated": true
	},
	"Repair Complete": {
		"text": "Oprava byla dokončena",
		"isAlreadyTranslated": true
	},
	"Cannibalize Device": {
		"text": "Vzít díly z jiného zařízení",
		"isAlreadyTranslated": true
	},
	"test": {
		"text": "test",
		"isAlreadyTranslated": true
	},
	"Part Charge": {
		"text": "Poplatek za díl",
		"isAlreadyTranslated": true
	},
	"Approved for repair": {
		"text": "Schváleno k opravě",
		"isAlreadyTranslated": true
	},
	"Waiting for store to issue required parts in 'Parts Requested' section below. Cannot complete repair with parts pending.": {
		"text": "Čeká se, až prodejna vydá požadované díly uvedené v sekci „Požadované díly“ níže. Opravu nelze provést, dokud nebude požadavek na díly vyřízen.",
		"isAlreadyTranslated": true
	},
	"pending": {
		"text": "nevyřízené",
		"isAlreadyTranslated": true
	},
	"Entered details do not match with selected serial number": {
		"text": "Zadané údaje nesouhlasí s vybraným sériovým číslem",
		"isAlreadyTranslated": true
	},
	"BER Accessories Received": {
		"text": "Příslušenství k ekonomicky neopravitelnému zařízení bylo přijato",
		"isAlreadyTranslated": true
	},
	"BER Device Received": {
		"text": "Ekonomicky neopravitelné zařízení bylo přijato",
		"isAlreadyTranslated": true
	},
	"Logistics - From Service Center to Hub": {
		"text": "Logistika – ze servisního střediska do centra",
		"isAlreadyTranslated": true
	},
	"Initiate BER Payment": {
		"text": "Zahájit platbu za ekonomicky neopravitelné zařízení",
		"isAlreadyTranslated": true
	},
	"BER Payment Complete": {
		"text": "Platba za ekonomicky neopravitelné zařízení byla dokončena",
		"isAlreadyTranslated": true
	},
	"Complete Process": {
		"text": "Dokončit proces",
		"isAlreadyTranslated": true
	},
	"Show Count": {
		"text": "Zobrazit počet",
		"isAlreadyTranslated": true
	},
	"Search": {
		"text": "Hledat",
		"isAlreadyTranslated": true
	},
	"Escalation Type": {
		"text": "Typ eskalace",
		"isAlreadyTranslated": true
	},
	"Flag Requests": {
		"text": "Označit požadavky příznakem",
		"isAlreadyTranslated": true
	},
	"Save this": {
		"text": "Uložit toto",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial No": {
		"text": "IMEI/sériové č.",
		"isAlreadyTranslated": true
	},
	"Hide Count": {
		"text": "Skrýt počet",
		"isAlreadyTranslated": true
	},
	"Enter Activation Token": {
		"text": "Zadat pořadový tiket aktivace",
		"isAlreadyTranslated": true
	},
	"Activation Token": {
		"text": "Pořadový tiket aktivace",
		"isAlreadyTranslated": true
	},
	"Download all reports": {
		"text": "Stáhnout všechny zprávy",
		"isAlreadyTranslated": true
	},
	"BER Repair": {
		"text": "Oprava ekonomicky neopravitelného zařízení",
		"isAlreadyTranslated": true
	},
	"Exception raised": {
		"text": "Výjimka byla podána",
		"isAlreadyTranslated": true
	},
	"Billing Details": {
		"text": "Fakturační údaje",
		"isAlreadyTranslated": true
	},
	"Continue": {
		"text": "Pokračovat",
		"isAlreadyTranslated": true
	},
	"Scan the QR code from Diagnosis App": {
		"text": "Naskenujte QR kód z diagnostické aplikace",
		"isAlreadyTranslated": true
	},
	"Something went wrong. Your request may have timed out.": {
		"text": "Něco se pokazilo. Možná vypršel časový limit vašeho požadavku.",
		"isAlreadyTranslated": true
	},
	"Exclusive Brand is created successfully": {
		"text": "Exkluzivní značka byla úspěšně vytvořena",
		"isAlreadyTranslated": true
	},
	"Form no": {
		"text": "Č. formuláře",
		"isAlreadyTranslated": true
	},
	"File provided is not in required format": {
		"text": "Zadaný soubor není v požadovaném formátu",
		"isAlreadyTranslated": true
	},
	"Invalid format": {
		"text": "Neplatný formát",
		"isAlreadyTranslated": true
	},
	"Invalid name for Product": {
		"text": "Neplatný název produktu",
		"isAlreadyTranslated": true
	},
	"More than 2 fields supplied": {
		"text": "Jsou vyplněna více než 2 pole",
		"isAlreadyTranslated": true
	},
	"Please upload only .csv file.": {
		"text": "Nahraný soubor musí být ve formátu .csv.",
		"isAlreadyTranslated": true
	},
	"Cannot read file": {
		"text": "Soubor nelze číst",
		"isAlreadyTranslated": true
	},
	"Invalid IMEI1 for Product": {
		"text": "Neplatné číslo IMEI1 produktu",
		"isAlreadyTranslated": true
	},
	"Invalid IMEI2 for Product": {
		"text": "Neplatné číslo IMEI2 produktu",
		"isAlreadyTranslated": true
	},
	"More than 9 fields supplied": {
		"text": "Jsou vyplněna více než 9 pole",
		"isAlreadyTranslated": true
	},
	"No file found": {
		"text": "Nebyl nalezen žádný soubor",
		"isAlreadyTranslated": true
	},
	"Name of the Agent": {
		"text": "Jméno pracovníka",
		"isAlreadyTranslated": true
	},
	"Please Select a Product": {
		"text": "Vyberte produkt",
		"isAlreadyTranslated": true
	},
	"Select product from dropdown": {
		"text": "Vyberte produkt z rozevíracího seznamu",
		"isAlreadyTranslated": true
	},
	"Select subcategory from dropdown": {
		"text": "Vyberte podkategorii z rozevíracího seznamu",
		"isAlreadyTranslated": true
	},
	"Select brand from dropdown": {
		"text": "Vyberte značku z rozevíracího seznamu",
		"isAlreadyTranslated": true
	},
	"Coupon Code": {
		"text": "Kód poukazu",
		"isAlreadyTranslated": true
	},
	"Start date of the coupon": {
		"text": "Datum začátku platnosti poukazu",
		"isAlreadyTranslated": true
	},
	"End date of the coupon": {
		"text": "Datum konce platnosti poukazu",
		"isAlreadyTranslated": true
	},
	"Coupon Type": {
		"text": "Typ poukazu",
		"isAlreadyTranslated": true
	},
	"Active coupon": {
		"text": "Aktivní poukaz",
		"isAlreadyTranslated": true
	},
	"Create Coupon": {
		"text": "Vytvořit poukaz",
		"isAlreadyTranslated": true
	},
	"Applicable only after sign up": {
		"text": "Lze uplatnit pouze po registraci",
		"isAlreadyTranslated": true
	},
	"Incentive Type": {
		"text": "Typ pobídky",
		"isAlreadyTranslated": true
	},
	"Select incentive type after selecting coupon type": {
		"text": "Zvolte typ poukazu a poté typ pobídky",
		"isAlreadyTranslated": true
	},
	"No. Of Users": {
		"text": "Počet uživatelů",
		"isAlreadyTranslated": true
	},
	"Incentive Value": {
		"text": "Hodnota pobídky",
		"isAlreadyTranslated": true
	},
	"End date": {
		"text": "Datum konce",
		"isAlreadyTranslated": true
	},
	"Start date": {
		"text": "Datum začátku",
		"isAlreadyTranslated": true
	},
	"Min Amount": {
		"text": "Min. částka",
		"isAlreadyTranslated": true
	},
	"Please fill out this field": {
		"text": "Vyplňte toto pole",
		"isAlreadyTranslated": true
	},
	"Please fill value greater than 0": {
		"text": "Vyplňte hodnotu větší než 0",
		"isAlreadyTranslated": true
	},
	"End Date cannot be less than start date": {
		"text": "Datum konce nesmí být dřívější než datum začátku",
		"isAlreadyTranslated": true
	},
	"Incentive value cannot exceed": {
		"text": "Hodnota pobídky nesmí překračovat",
		"isAlreadyTranslated": true
	},
	"Please enter serial number": {
		"text": "Zadejte sériové číslo",
		"isAlreadyTranslated": true
	},
	"Please enter brand name": {
		"text": "Zadejte název značky",
		"isAlreadyTranslated": true
	},
	"Please enter product name": {
		"text": "Zadejte název produktu",
		"isAlreadyTranslated": true
	},
	"Customer Email": {
		"text": "E-mail zákazníka",
		"isAlreadyTranslated": true
	},
	"Initiate App Diagnostic": {
		"text": "Zahájit diagnostiku v aplikaci",
		"isAlreadyTranslated": true
	},
	"Reliability Records": {
		"text": "Záznamy o spolehlivosti",
		"isAlreadyTranslated": true
	},
	"Drivers list": {
		"text": "Seznam řidičů",
		"isAlreadyTranslated": true
	},
	"User Feedback": {
		"text": "Zpětná vazba od uživatelů",
		"isAlreadyTranslated": true
	},
	"Perform Diagnosis": {
		"text": "Provést diagnostiku",
		"isAlreadyTranslated": true
	},
	"Service Completion Date": {
		"text": "Datum dokončení servisu",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching templates": {
		"text": "Při načítání šablon se něco pokazilo",
		"isAlreadyTranslated": true
	},
	"Template": {
		"text": "Šablona",
		"isAlreadyTranslated": true
	},
	"Subject": {
		"text": "Předmět",
		"isAlreadyTranslated": true
	},
	"Apply a filter before sending an email": {
		"text": "Před odesláním e-mailu použijte filtr",
		"isAlreadyTranslated": true
	},
	"Select a predefined template": {
		"text": "Vyberte předdefinovanou šablonu",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching data for graph": {
		"text": "Při načítání dat pro graf se něco pokazilo",
		"isAlreadyTranslated": true
	},
	"Please enter a valid IMEI": {
		"text": "Zadejte platné číslo IMEI",
		"isAlreadyTranslated": true
	},
	"Thank You For Choosing Servify": {
		"text": "Děkujeme, že jste zvolili společnost Servify",
		"isAlreadyTranslated": true
	},
	"Product Inward Date": {
		"text": "Datum dodání produktu",
		"isAlreadyTranslated": true
	},
	"Expected Delivery Date": {
		"text": "Očekávané datum doručení",
		"isAlreadyTranslated": true
	},
	"Service Provider's Address'": {
		"text": "„Adresa poskytovatele služeb“",
		"isAlreadyTranslated": true
	},
	"Problem Reported by Customer": {
		"text": "Problém nahlášený zákazníkem",
		"isAlreadyTranslated": true
	},
	"Note: This Equipment is accepted subject to terms and conditions mentioned overleaf.'": {
		"text": "Poznámka: Převzetí zařízení podléhá obchodním podmínkám uvedeným na druhé straně.",
		"isAlreadyTranslated": true
	},
	"Handed to customer": {
		"text": "Předáno zákazníkovi",
		"isAlreadyTranslated": true
	},
	"Upload Status Report": {
		"text": "Nahrát zprávu o stavu",
		"isAlreadyTranslated": true
	},
	"Download File": {
		"text": "Stáhnout soubor",
		"isAlreadyTranslated": true
	},
	"Upload File": {
		"text": "Nahrát soubor",
		"isAlreadyTranslated": true
	},
	"Submit File": {
		"text": "Odeslat soubor",
		"isAlreadyTranslated": true
	},
	"Something went wrong. Please upload again": {
		"text": "Něco se pokazilo. Nahrajte soubor znovu",
		"isAlreadyTranslated": true
	},
	"Invalid column headers. You can use sample file": {
		"text": "Neplatná záhlaví sloupců. Můžete použít ukázkový soubor",
		"isAlreadyTranslated": true
	},
	"Please upload a valid file. You can use sample file": {
		"text": "Nahrajte platný soubor. Můžete použít ukázkový soubor",
		"isAlreadyTranslated": true
	},
	"There are no requests to download": {
		"text": "Neexistují žádné požadavky ke stažení",
		"isAlreadyTranslated": true
	},
	"Map Logistics Request": {
		"text": "Namapovat logistický požadavek",
		"isAlreadyTranslated": true
	},
	"Your logistics request has been successfully mapped to service request": {
		"text": "Váš logistický požadavek byl úspěšně namapován na servisní požadavek",
		"isAlreadyTranslated": true
	},
	"Pickup Name": {
		"text": "Vyzvednutí, jméno",
		"isAlreadyTranslated": true
	},
	"Pickup Date": {
		"text": "Vyzvednutí, datum",
		"isAlreadyTranslated": true
	},
	"Pickup Time": {
		"text": "Vyzvednutí, čas",
		"isAlreadyTranslated": true
	},
	"Pickup Contact No": {
		"text": "Vyzvednutí, č. smlouvy",
		"isAlreadyTranslated": true
	},
	"Drop Name": {
		"text": "Předání, jméno",
		"isAlreadyTranslated": true
	},
	"Drop Address": {
		"text": "Předání, adresa",
		"isAlreadyTranslated": true
	},
	"Drop Contact No": {
		"text": "Předání, č. kontaktní osoby",
		"isAlreadyTranslated": true
	},
	"Product Price": {
		"text": "Cena produktu",
		"isAlreadyTranslated": true
	},
	"Brand Name": {
		"text": "Název značky",
		"isAlreadyTranslated": true
	},
	"Mirror Test": {
		"text": "Zrcadlový test",
		"isAlreadyTranslated": true
	},
	"Age of device:": {
		"text": "Stáří zařízení:",
		"isAlreadyTranslated": true
	},
	"optin requests are shown above": {
		"text": "požadavky Optin jsou uvedeny výše",
		"isAlreadyTranslated": true
	},
	"This device is not eligible for DOA": {
		"text": "Pro toto zařízení nelze uplatnit poškození při doručení",
		"isAlreadyTranslated": true
	},
	"This device is eligible for DOA": {
		"text": "Pro toto zařízení lze uplatnit poškození při doručení",
		"isAlreadyTranslated": true
	},
	"Do you want to return the defective parts to the customer?": {
		"text": "Chcete vrátit vadné díly zákazníkovi?",
		"isAlreadyTranslated": true
	},
	"Please enter the remarks with at least 25 characters": {
		"text": "Zadejte poznámky v délce alespoň 25 znaků",
		"isAlreadyTranslated": true
	},
	"Please enter the remarks": {
		"text": "Zadejte poznámky",
		"isAlreadyTranslated": true
	},
	"Do you want to return the damaged parts to the customer": {
		"text": "Chcete vrátit vadné díly zákazníkovi?",
		"isAlreadyTranslated": true
	},
	"Enter Order ID": {
		"text": "Zadejte ID objednávky",
		"isAlreadyTranslated": true
	},
	'Device warranty details could not be fetched from GSX currently. Click on "Continue" to proceed with selecting the device details or Click on "Retry" to re-attempt fetching warranty details': {
		"text": "Detaily záruky na zařízení momentálně nelze načíst z GSX. Klikněte na tlačítko „Pokračovat“ a vyberte detaily zařízení nebo stiskněte tlačítko „Zkusit znovu“ a zkuste tak detaily načíst",
		"isAlreadyTranslated": true
	},
	"Exceptional Sales": {
		"text": "Mimořádné prodeje",
		"isAlreadyTranslated": true
	},
	"Device not eligible": {
		"text": "Zařízení není způsobilé",
		"isAlreadyTranslated": true
	},
	"Apply a filter before sending a SMS": {
		"text": "Před odesláním SMS zprávy použijte filtr",
		"isAlreadyTranslated": true
	},
	"Update New Phone's Serial Number": {
		"text": "Aktualizovat sériové číslo nového telefonu",
		"isAlreadyTranslated": true
	},
	"Old Phone's IMEI / Serial Number": {
		"text": "Číslo IMEI / sériové číslo starého telefonu",
		"isAlreadyTranslated": true
	},
	"Invite Service User": {
		"text": "Pozvat uživatele servisu",
		"isAlreadyTranslated": true
	},
	"Invite Retailer User": {
		"text": "Pozvat uživatele prodejce",
		"isAlreadyTranslated": true
	},
	"No users found": {
		"text": "Nebyly nalezeni žádní uživatelé",
		"isAlreadyTranslated": true
	},
	"Please fill in the reason for overriding": {
		"text": "Vyplňte důvod přepsání",
		"isAlreadyTranslated": true
	},
	"File size should not be greater than 10 MB": {
		"text": "Velikost souboru nesmí překračovat 10 MB",
		"isAlreadyTranslated": true
	},
	"Consumer Details is saved successfully": {
		"text": "Detaily spotřebitele byly úspěšně uloženy",
		"isAlreadyTranslated": true
	},
	"Select a Vendor": {
		"text": "Vyberte dodavatele",
		"isAlreadyTranslated": true
	},
	"Selected Vendor": {
		"text": "Vybraný dodavatel",
		"isAlreadyTranslated": true
	},
	"Work Order ID": {
		"text": "ID pracovního příkazu",
		"isAlreadyTranslated": true
	},
	"Eligible for RVP": {
		"text": "Způsobilé pro RVP",
		"isAlreadyTranslated": true
	},
	"Make the exclusive brand visible to customers": {
		"text": "Nastavte exkluzivní značku jako viditelnou pro zákazníky",
		"isAlreadyTranslated": true
	},
	"Reset": {
		"text": "Resetovat",
		"isAlreadyTranslated": true
	},
	"First Name": {
		"text": "Jméno",
		"isAlreadyTranslated": true
	},
	"Last Name": {
		"text": "Příjmení",
		"isAlreadyTranslated": true
	},
	"Alternate number": {
		"text": "Alternativní číslo",
		"isAlreadyTranslated": true
	},
	"Order date": {
		"text": "Datum objednávky",
		"isAlreadyTranslated": true
	},
	"Address Line": {
		"text": "Řádek adresy",
		"isAlreadyTranslated": true
	},
	"City": {
		"text": "Město",
		"isAlreadyTranslated": true
	},
	"State": {
		"text": "Stát",
		"isAlreadyTranslated": true
	},
	"APR Name": {
		"text": "Název APR",
		"isAlreadyTranslated": true
	},
	"Apply Filters": {
		"text": "Použít filtry",
		"isAlreadyTranslated": true
	},
	"Reason For Reassignment": {
		"text": "Důvod změny přiřazení",
		"isAlreadyTranslated": true
	},
	"Reason For Reassign": {
		"text": "Důvod změny přiřazení",
		"isAlreadyTranslated": true
	},
	"No data Found": {
		"text": "Nebyla nalezena žádná data",
		"isAlreadyTranslated": true
	},
	"Total Credit Amount": {
		"text": "Částka kreditu celkem",
		"isAlreadyTranslated": true
	},
	"Incentive/Penalty": {
		"text": "Pobídka/pokuta",
		"isAlreadyTranslated": true
	},
	"Taxable amount": {
		"text": "Základ daně",
		"isAlreadyTranslated": true
	},
	"Total Amount Payable": {
		"text": "Částka k úhradě celkem",
		"isAlreadyTranslated": true
	},
	"Bulk Approve": {
		"text": "Hromadně schválit",
		"isAlreadyTranslated": true
	},
	"Add additional Penalties": {
		"text": "Přidat další pokuty",
		"isAlreadyTranslated": true
	},
	"Description": {
		"text": "Popis",
		"isAlreadyTranslated": true
	},
	"Character Limit": {
		"text": "Maximální počet znaků",
		"isAlreadyTranslated": true
	},
	"Pending Approval": {
		"text": "Čeká na schválení",
		"isAlreadyTranslated": true
	},
	"Rejected Remark": {
		"text": "Zamítnutá poznámka",
		"isAlreadyTranslated": true
	},
	"SELECT PRODUCT": {
		"text": "VYBRAT PRODUKT",
		"isAlreadyTranslated": true
	},
	"Upload": {
		"text": "Nahrát",
		"isAlreadyTranslated": true
	},
	"Total Unregistered Products": {
		"text": "Neregistrované produkty celkem",
		"isAlreadyTranslated": true
	},
	"Change password": {
		"text": "Změnit heslo",
		"isAlreadyTranslated": true
	},
	"Password": {
		"text": "Heslo",
		"isAlreadyTranslated": true
	},
	"Confirm Password": {
		"text": "Potvrdit heslo",
		"isAlreadyTranslated": true
	},
	"Choose another account": {
		"text": "Vyberte jiný účet",
		"isAlreadyTranslated": true
	},
	"Change": {
		"text": "Změnit",
		"isAlreadyTranslated": true
	},
	"child": {
		"text": "potomek",
		"isAlreadyTranslated": true
	},
	"Plan Types": {
		"text": "Typy plánů",
		"isAlreadyTranslated": true
	},
	"Plan Descriptions": {
		"text": "Popisy plánů",
		"isAlreadyTranslated": true
	},
	"No Communication History": {
		"text": "Žádná historie komunikace",
		"isAlreadyTranslated": true
	},
	"First Dialer Disposition": {
		"text": "Konfigurace prvního číselníku",
		"isAlreadyTranslated": true
	},
	"Second Dialer Disposition": {
		"text": "Konfigurace druhého číselníku",
		"isAlreadyTranslated": true
	},
	"Enter First Name": {
		"text": "Zadejte jméno",
		"isAlreadyTranslated": true
	},
	"Enter correct First Name": {
		"text": "Zadejte správné jméno",
		"isAlreadyTranslated": true
	},
	"Enter correct Alternate Number": {
		"text": "Zadejte správné alternativní číslo",
		"isAlreadyTranslated": true
	},
	"Enter OderID": {
		"text": "Zadejte ID objednávky",
		"isAlreadyTranslated": true
	},
	"Enter correct OderID": {
		"text": "Zadejte správné ID objednávky",
		"isAlreadyTranslated": true
	},
	"Enter Order Date": {
		"text": "Zadejte datum objednávky",
		"isAlreadyTranslated": true
	},
	"Select a plan from the dropdow": {
		"text": "Vyberte plán z rozevíracího seznamu",
		"isAlreadyTranslated": true
	},
	"Enter correct City": {
		"text": "Zadejte správné město",
		"isAlreadyTranslated": true
	},
	"Enter correct State": {
		"text": "Zadejte správný stát",
		"isAlreadyTranslated": true
	},
	"Consumer Details added successfully": {
		"text": "Detaily spotřebitele byly úspěšně přidány",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to generate Invoice?": {
		"text": "Opravdu chcete vygenerovat fakturu?",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to Approve all the jobs?": {
		"text": "Opravdu chcete schválit všechny zakázky?",
		"isAlreadyTranslated": true
	},
	"Passwords do not match": {
		"text": "Hesla se neshodují",
		"isAlreadyTranslated": true
	},
	"Please enter a password": {
		"text": "Zadejte heslo",
		"isAlreadyTranslated": true
	},
	"This fault code/ cause code has already been selected": {
		"text": "Tento kód závady / kód příčiny je již vybrán",
		"isAlreadyTranslated": true
	},
	"Please complete the QC checklist": {
		"text": "Vyplňte kontrolní seznam kontroly kvality",
		"isAlreadyTranslated": true
	},
	"Please add mandatory Symptom Fault Action": {
		"text": "Přidejte povinnou akci pro příznak závady",
		"isAlreadyTranslated": true
	},
	"Branch": {
		"text": "Pobočka",
		"isAlreadyTranslated": true
	},
	"Shop": {
		"text": "Prodejna",
		"isAlreadyTranslated": true
	},
	"Product Category": {
		"text": "Kategorie produktu",
		"isAlreadyTranslated": true
	},
	"Quantity": {
		"text": "Množství",
		"isAlreadyTranslated": true
	},
	"No records found": {
		"text": "Nebyly nalezeny žádné záznamy",
		"isAlreadyTranslated": true
	},
	"No Competition Marked": {
		"text": "Není označena žádná konkurence",
		"isAlreadyTranslated": true
	},
	"Filter Competition Sales": {
		"text": "Filtrovat prodej u konkurence",
		"isAlreadyTranslated": true
	},
	"Download consumer details in csv format.": {
		"text": "Stáhnout detaily spotřebitele ve formátu CSV.",
		"isAlreadyTranslated": true
	},
	"No Consumers found for the selected date range.": {
		"text": "Pro vybraný interval dat nebyly nalezeni žádní spotřebitelé",
		"isAlreadyTranslated": true
	},
	"Details": {
		"text": "Detaily",
		"isAlreadyTranslated": true
	},
	"Create Request": {
		"text": "Vytvořit požadavek",
		"isAlreadyTranslated": true
	},
	"Brand Description": {
		"text": "Popis značky",
		"isAlreadyTranslated": true
	},
	"Tab": {
		"text": "Záložka",
		"isAlreadyTranslated": true
	},
	"Consumer data": {
		"text": "Data spotřebitele",
		"isAlreadyTranslated": true
	},
	"No consumer data": {
		"text": "Žádná data spotřebitele",
		"isAlreadyTranslated": true
	},
	"Something erroneous happened": {
		"text": "Něco se nezdařilo",
		"isAlreadyTranslated": true
	},
	"Consumer Rating": {
		"text": "Hodnocení spotřebitele",
		"isAlreadyTranslated": true
	},
	"Rate your experience with the customer": {
		"text": "Ohodnoťte svoji zkušenost se zákazníkem",
		"isAlreadyTranslated": true
	},
	"Comments (Optional)": {
		"text": "Komentáře (nepovinné)",
		"isAlreadyTranslated": true
	},
	"Delivery Report": {
		"text": "Dodávkový list",
		"isAlreadyTranslated": true
	},
	"Authorized Service Centre": {
		"text": "Autorizované servisní středisko",
		"isAlreadyTranslated": true
	},
	"Device Inward Date & Time": {
		"text": "Datum a čas dodání zařízení",
		"isAlreadyTranslated": true
	},
	"Repair Closed Date & Time": {
		"text": "Datum a čas uzavření opravy",
		"isAlreadyTranslated": true
	},
	"Device Collection Date & Time": {
		"text": "Datum a čas vyzvednutí zařízení",
		"isAlreadyTranslated": true
	},
	"Service Reference ID": {
		"text": "Referenční ID servisu",
		"isAlreadyTranslated": true
	},
	"mode": {
		"text": "režim",
		"isAlreadyTranslated": true
	},
	"Product Details": {
		"text": "Detaily produktu",
		"isAlreadyTranslated": true
	},
	"Problems Reported by Customer": {
		"text": "Problémy nahlášené zákazníkem",
		"isAlreadyTranslated": true
	},
	"Condition of Equipment": {
		"text": "Stav zařízení",
		"isAlreadyTranslated": true
	},
	"Inward Check": {
		"text": "Vstupní kontrola",
		"isAlreadyTranslated": true
	},
	"Outward Check": {
		"text": "Výstupní kontrola",
		"isAlreadyTranslated": true
	},
	"Accessories Received": {
		"text": "Přijaté příslušenství",
		"isAlreadyTranslated": true
	},
	"Repair Cost (for OOW cases)": {
		"text": "Cena opravy (pro případy bez záruky)",
		"isAlreadyTranslated": true
	},
	"Part No.": {
		"text": "Č. dílu",
		"isAlreadyTranslated": true
	},
	"Part Description": {
		"text": "Popis dílu",
		"isAlreadyTranslated": true
	},
	"Damaged Part Serial No/ IMEI (if applicable)": {
		"text": "Sériové č./IMEI poškozeného dílu (lze-li použít)",
		"isAlreadyTranslated": true
	},
	"Replaced Part Serial No/ IMEI (if applicable)": {
		"text": "Sériové č./IMEI vyměněného dílu (lze-li použít)",
		"isAlreadyTranslated": true
	},
	"Customer Feedback": {
		"text": "Zpětná vazba od zákazníků",
		"isAlreadyTranslated": true
	},
	"Neither Satisfied nor Dissatisfied": {
		"text": "Spokojen ani nespokojen",
		"isAlreadyTranslated": true
	},
	"Somewhat Dissatisfied": {
		"text": "Částečně nespokojen",
		"isAlreadyTranslated": true
	},
	"Overall Satisfaction": {
		"text": "Celková spokojenost",
		"isAlreadyTranslated": true
	},
	"Technical Ability": {
		"text": "Technické schopnosti",
		"isAlreadyTranslated": true
	},
	"Professionalism": {
		"text": "Profesionalita",
		"isAlreadyTranslated": true
	},
	"Response Time": {
		"text": "Čas odezvy",
		"isAlreadyTranslated": true
	},
	"Quality of Repair": {
		"text": "Kvalita opravy",
		"isAlreadyTranslated": true
	},
	"Timely Update": {
		"text": "Včasné informace",
		"isAlreadyTranslated": true
	},
	"(I am satified with the performance of the Product and have received the Product and all its Accessories)": {
		"text": "(Jsem spokojen s funkčností produktu a převzal jsem produkt i s veškerým poskytnutým příslušenstvím.)",
		"isAlreadyTranslated": true
	},
	"(For Repair Closure)": {
		"text": "(Pro uzavření opravy)",
		"isAlreadyTranslated": true
	},
	"Lifecycle": {
		"text": "Životní cyklus",
		"isAlreadyTranslated": true
	},
	"No results to display": {
		"text": "Žádné výsledky k zobrazení",
		"isAlreadyTranslated": true
	},
	"No information found": {
		"text": "Nebyly nalezeny žádné informace",
		"isAlreadyTranslated": true
	},
	"Diagnosed On": {
		"text": "Diagnostika provedena dne",
		"isAlreadyTranslated": true
	},
	"Select Time Period": {
		"text": "Vybrat časové období",
		"isAlreadyTranslated": true
	},
	"Coming Soon!": {
		"text": "Již brzy!",
		"isAlreadyTranslated": true
	},
	"No Sufficient Data Available To Plot The Graph": {
		"text": "Není k dispozici dostatek dat pro vykreslení grafu",
		"isAlreadyTranslated": true
	},
	"DIAGNOSIS CERTIFICATE": {
		"text": "CERTIFIKÁT O DIAGNOSTICE",
		"isAlreadyTranslated": true
	},
	"Diagnosis Date": {
		"text": "Datum diagnostiky",
		"isAlreadyTranslated": true
	},
	"Sim Activation Date": {
		"text": "Datum aktivace SIM",
		"isAlreadyTranslated": true
	},
	"Detailed Diagnostic Report": {
		"text": "Detailní diagnostický protokol",
		"isAlreadyTranslated": true
	},
	"Summary": {
		"text": "Shrnutí",
		"isAlreadyTranslated": true
	},
	"Tentative Result": {
		"text": "Předběžný výsledek",
		"isAlreadyTranslated": true
	},
	"Component Name": {
		"text": "Název součásti",
		"isAlreadyTranslated": true
	},
	"Observed Value": {
		"text": "Měřená hodnota",
		"isAlreadyTranslated": true
	},
	"Result": {
		"text": "Výsledek",
		"isAlreadyTranslated": true
	},
	"Reference Range": {
		"text": "Referenční rozsah",
		"isAlreadyTranslated": true
	},
	"Ready to dispatch": {
		"text": "Připraveno k odeslání",
		"isAlreadyTranslated": true
	},
	"Dispatched": {
		"text": "Odesláno",
		"isAlreadyTranslated": true
	},
	"Tracking Number": {
		"text": "Sledovací číslo",
		"isAlreadyTranslated": true
	},
	"Box": {
		"text": "Krabice",
		"isAlreadyTranslated": true
	},
	"Width": {
		"text": "Šířka",
		"isAlreadyTranslated": true
	},
	"Height": {
		"text": "Výška",
		"isAlreadyTranslated": true
	},
	"Weight": {
		"text": "Hmotnost",
		"isAlreadyTranslated": true
	},
	"Please enter Box Height.": {
		"text": "Zadejte výšku krabice.",
		"isAlreadyTranslated": true
	},
	"Please enter Box width.": {
		"text": "Zadejte šířku krabice.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Length.": {
		"text": "Zadejte délku krabice.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Weight.": {
		"text": "Zadejte hmotnost krabice.",
		"isAlreadyTranslated": true
	},
	"Length": {
		"text": "Délka",
		"isAlreadyTranslated": true
	},
	"Please enter tracking Number.": {
		"text": "Zadejte sledovací číslo.",
		"isAlreadyTranslated": true
	},
	"Please enter Shipper Detail.": {
		"text": "Zadejte detail přepravce.",
		"isAlreadyTranslated": true
	},
	"Please Select & Click Branch Name from the Dropdown List": {
		"text": "Vyberte kliknutím do rozevíracího seznamu název pobočky",
		"isAlreadyTranslated": true
	},
	"As per invoice": {
		"text": "Podle faktury",
		"isAlreadyTranslated": true
	},
	"Problematic Driver": {
		"text": "Problémový ovladač",
		"isAlreadyTranslated": true
	},
	"No Problematic drivers found": {
		"text": "Nebyl nalezen žádný problémový ovladač",
		"isAlreadyTranslated": true
	},
	"Installed Drivers": {
		"text": "Instalované ovladače",
		"isAlreadyTranslated": true
	},
	"No Installed drivers found": {
		"text": "Nebyly nalezeny žádné problémové ovladače",
		"isAlreadyTranslated": true
	},
	"Type: cashback, discount or referral": {
		"text": "Typ: cashback, sleva nebo doporučení",
		"isAlreadyTranslated": true
	},
	"Save Coupon": {
		"text": "Uložit poukaz",
		"isAlreadyTranslated": true
	},
	"Handset Reset": {
		"text": "Reset sluchátek",
		"isAlreadyTranslated": true
	},
	"Handset Details": {
		"text": "Detaily sluchátek",
		"isAlreadyTranslated": true
	},
	"Service Location Name": {
		"text": "Servisní místo, název",
		"isAlreadyTranslated": true
	},
	"Last Resetted On": {
		"text": "Datum posledního resetování",
		"isAlreadyTranslated": true
	},
	"No of Handsets Resets Made": {
		"text": "Počet provedených resetování sluchátek",
		"isAlreadyTranslated": true
	},
	"Handset reset has been done. Please inform the Engineer to login into his Technician App.": {
		"text": "Reset sluchátek byl proveden. Požádejte technika, aby se přihlásil do aplikace pro technik.",
		"isAlreadyTranslated": true
	},
	"Assigned/Finished jobs": {
		"text": "Přiřazené/dokončené zakázky",
		"isAlreadyTranslated": true
	},
	"Productivity": {
		"text": "Produktivita",
		"isAlreadyTranslated": true
	},
	"Current Job Details": {
		"text": "Detaily aktuální zakázky",
		"isAlreadyTranslated": true
	},
	"Next Job Details": {
		"text": "Detaily další zakázky",
		"isAlreadyTranslated": true
	},
	"No next job": {
		"text": "Žádná další zakázka",
		"isAlreadyTranslated": true
	},
	"No current job": {
		"text": "Žádná aktuální zakázka",
		"isAlreadyTranslated": true
	},
	"No previous jobs": {
		"text": "Žádné předchozí zakázky",
		"isAlreadyTranslated": true
	},
	"No record found": {
		"text": "Nebyl nalezen žádný záznam",
		"isAlreadyTranslated": true
	},
	"User Remarks": {
		"text": "Poznámky uživatele",
		"isAlreadyTranslated": true
	},
	"Audience Selection": {
		"text": "Výběr cílové skupiny",
		"isAlreadyTranslated": true
	},
	"Final Review": {
		"text": "Finální kontrola",
		"isAlreadyTranslated": true
	},
	"Potential Reach": {
		"text": "Potenciální dosah",
		"isAlreadyTranslated": true
	},
	"Personnel": {
		"text": "Pracovníci",
		"isAlreadyTranslated": true
	},
	"Select Advisor": {
		"text": "Vybrat poradce",
		"isAlreadyTranslated": true
	},
	"Notification Expiry Duration (in days)": {
		"text": "Doba platnosti oznámení (ve dnech)",
		"isAlreadyTranslated": true
	},
	"Click here to upload file": {
		"text": "Klikněte sem a nahrajte soubor",
		"isAlreadyTranslated": true
	},
	"View uploaded file": {
		"text": "Zobrazit nahraný soubor",
		"isAlreadyTranslated": true
	},
	"Notification Details": {
		"text": "Detaily oznámení",
		"isAlreadyTranslated": true
	},
	"Title": {
		"text": "Název",
		"isAlreadyTranslated": true
	},
	"Message": {
		"text": "Zpráva",
		"isAlreadyTranslated": true
	},
	"Attachment": {
		"text": "Příloha",
		"isAlreadyTranslated": true
	},
	"Link": {
		"text": "Odkaz",
		"isAlreadyTranslated": true
	},
	"Link to attachment": {
		"text": "Odkaz na přílohu",
		"isAlreadyTranslated": true
	},
	"Audience": {
		"text": "Cílová skupina",
		"isAlreadyTranslated": true
	},
	"All shops and states": {
		"text": "Všechny prodejny a stavy",
		"isAlreadyTranslated": true
	},
	"Roster Managment": {
		"text": "Správa soupisek",
		"isAlreadyTranslated": true
	},
	"Sample Files Data Format": {
		"text": "Datový formát ukázkových souborů",
		"isAlreadyTranslated": true
	},
	"Supported Data Format": {
		"text": "Podporovaný datový formát",
		"isAlreadyTranslated": true
	},
	"Issue Resolved": {
		"text": "Problém byl vyřešen",
		"isAlreadyTranslated": true
	},
	"Approve Exception": {
		"text": "Schválit výjimku",
		"isAlreadyTranslated": true
	},
	"Raise Exception": {
		"text": "Podat výjimku",
		"isAlreadyTranslated": true
	},
	"Select SKU": {
		"text": "Vybrat SKU",
		"isAlreadyTranslated": true
	},
	"Product Purchase Cost": {
		"text": "Nákupní cena produktu",
		"isAlreadyTranslated": true
	},
	"Refund Amount": {
		"text": "Vrácená částka",
		"isAlreadyTranslated": true
	},
	"Suggested amount is based on Depreciation Price": {
		"text": "Navržená částka vychází ze zůstatkové hodnoty",
		"isAlreadyTranslated": true
	},
	"Upload Document Proof": {
		"text": "Nahrát doklad k dokumentu",
		"isAlreadyTranslated": true
	},
	"Uploaded Documents": {
		"text": "Nahrané dokumenty",
		"isAlreadyTranslated": true
	},
	"Revert Changes": {
		"text": "Vrátit změny",
		"isAlreadyTranslated": true
	},
	"Approve": {
		"text": "Schválit",
		"isAlreadyTranslated": true
	},
	"No Service Requests found for selected date range.": {
		"text": "Pro vybraný interval dat nebyly nalezeny žádné požadavky na servis",
		"isAlreadyTranslated": true
	},
	"External Service Request Details": {
		"text": "Detaily externího požadavku na servis",
		"isAlreadyTranslated": true
	},
	"Service Center Details": {
		"text": "Detaily servisního střediska",
		"isAlreadyTranslated": true
	},
	"No such request exists": {
		"text": "Zadaný požadavek neexistuje",
		"isAlreadyTranslated": true
	},
	"Job Number": {
		"text": "Číslo zakázky",
		"isAlreadyTranslated": true
	},
	"Supplier Job Number": {
		"text": "Číslo zakázky dodavatele",
		"isAlreadyTranslated": true
	},
	"Temporary Job Number": {
		"text": "Dočasné číslo zakázky",
		"isAlreadyTranslated": true
	},
	"Service Center Name": {
		"text": "Název servisního střediska",
		"isAlreadyTranslated": true
	},
	"Service Center Code": {
		"text": "Kód servisního střediska",
		"isAlreadyTranslated": true
	},
	"Service Center Phone": {
		"text": "Telefon servisního střediska",
		"isAlreadyTranslated": true
	},
	"Service Center Email": {
		"text": "E-mail servisního střediska",
		"isAlreadyTranslated": true
	},
	"Show filtered data": {
		"text": "Zobrazit filtrovaná data",
		"isAlreadyTranslated": true
	},
	"Please select atleast one escalation": {
		"text": "Vyberte alespoň jednu eskalaci",
		"isAlreadyTranslated": true
	},
	"Reset password": {
		"text": "Resetovat heslo",
		"isAlreadyTranslated": true
	},
	"Forgot password": {
		"text": "Zapomněli jste heslo?",
		"isAlreadyTranslated": true
	},
	"Select a Reset Option": {
		"text": "Vybrat možnost resetování",
		"isAlreadyTranslated": true
	},
	"LOCATION RESET": {
		"text": "RESET MÍSTA",
		"isAlreadyTranslated": true
	},
	"Handset reset has already been done. Please inform the corresponding": {
		"text": "Reset sluchátek byl již proveden. Informujte příslušné osoby",
		"isAlreadyTranslated": true
	},
	"to login Mobile COS.": {
		"text": "pro přihlášení do Mobile COS.",
		"isAlreadyTranslated": true
	},
	"sell": {
		"text": "prodat",
		"isAlreadyTranslated": true
	},
	"Diagnosis attempted": {
		"text": "Proběhl pokus o diagnostiku",
		"isAlreadyTranslated": true
	},
	"Please select the at least one of the custom diagnosis options to initiate diagnosis": {
		"text": "Vyberte alespoň jednu z možností diagnostiky, aby bylo možné zahájit diagnostiku",
		"isAlreadyTranslated": true
	},
	"Filter Products": {
		"text": "Filtrovat produkty",
		"isAlreadyTranslated": true
	},
	"Upload Sales": {
		"text": "Nahrát prodej",
		"isAlreadyTranslated": true
	},
	"Inventory": {
		"text": "Zásoby",
		"isAlreadyTranslated": true
	},
	"Receipt Date": {
		"text": "Datum vystavení dokladu",
		"isAlreadyTranslated": true
	},
	"Currently With": {
		"text": "Aktuálně s",
		"isAlreadyTranslated": true
	},
	"Owner": {
		"text": "Vlastník",
		"isAlreadyTranslated": true
	},
	"Error in uploaded csv file at line number:": {
		"text": "Chyba v nahraném souboru CSV na řádku č.:",
		"isAlreadyTranslated": true
	},
	"Error in following data at line number: 1": {
		"text": "Chyba v následujících datech na řádku č.: 1",
		"isAlreadyTranslated": true
	},
	"Uploaded file format is not supported": {
		"text": "Formát nahraného souboru není podporován",
		"isAlreadyTranslated": true
	},
	"Receipt End Date": {
		"text": "Datum konce platnosti dokladu",
		"isAlreadyTranslated": true
	},
	"Receipt Start Date": {
		"text": "Datum začátku platnosti dokladu",
		"isAlreadyTranslated": true
	},
	"Sale Start Date": {
		"text": "Datum začátku prodeje",
		"isAlreadyTranslated": true
	},
	"Sale End Date": {
		"text": "Datum konce prodeje",
		"isAlreadyTranslated": true
	},
	"Parts Charges (sales taxes included)'": {
		"text": "Poplatky za díly (včetně daně z obratu)",
		"isAlreadyTranslated": true
	},
	"Sub total of services": {
		"text": "Mezisoučet služeb",
		"isAlreadyTranslated": true
	},
	"Advance and Loyalty": {
		"text": "Záloha a věrnostní bonus",
		"isAlreadyTranslated": true
	},
	"Servify Cash Redeemed": {
		"text": "Hotovost vyplacená společností Servify",
		"isAlreadyTranslated": true
	},
	"Adjustment": {
		"text": "Korekce",
		"isAlreadyTranslated": true
	},
	"Coupon Discount": {
		"text": "Slevový poukaz",
		"isAlreadyTranslated": true
	},
	"Issues Reported By Customer": {
		"text": "Problémy nahlášené zákazníkem",
		"isAlreadyTranslated": true
	},
	"Symptom Description": {
		"text": "Popis příznaku",
		"isAlreadyTranslated": true
	},
	"Fault Description": {
		"text": "Popis závady",
		"isAlreadyTranslated": true
	},
	"Action Description": {
		"text": "Popis akce",
		"isAlreadyTranslated": true
	},
	"Total Payable Amount": {
		"text": "Částka k úhradě celkem",
		"isAlreadyTranslated": true
	},
	"Spare Consumption": {
		"text": "Spotřeba náhradních dílů",
		"isAlreadyTranslated": true
	},
	"Spares Description": {
		"text": "Popis náhradních dílů",
		"isAlreadyTranslated": true
	},
	"Charges": {
		"text": "Poplatky",
		"isAlreadyTranslated": true
	},
	"total": {
		"text": "celkem",
		"isAlreadyTranslated": true
	},
	"Total": {
		"text": "Celkem",
		"isAlreadyTranslated": true
	},
	"Location Reset": {
		"text": "Reset místa",
		"isAlreadyTranslated": true
	},
	"Shop Location Reset Details": {
		"text": "Detaily resetování místa prodejny",
		"isAlreadyTranslated": true
	},
	"Last Location resetted on": {
		"text": "Datum posledního resetování místa",
		"isAlreadyTranslated": true
	},
	"No. of Resets Made": {
		"text": "Počet provedených resetování",
		"isAlreadyTranslated": true
	},
	"to exit and re-open the Mobile COS": {
		"text": "ukončit a znovu otevřít Mobile COS",
		"isAlreadyTranslated": true
	},
	"Shop Location reset has already been done. Please inform the corresponding": {
		"text": "Reset místa prodejny byl již proveden. Informujte příslušné osoby",
		"isAlreadyTranslated": true
	},
	"Previous Locations Reset Details": {
		"text": "Detaily resetování předchozích míst",
		"isAlreadyTranslated": true
	},
	"Sign in": {
		"text": "Přihlásit se",
		"isAlreadyTranslated": true
	},
	"to continue.": {
		"text": "pokračovat.",
		"isAlreadyTranslated": true
	},
	"Resend OTP": {
		"text": "Znovu odeslat jednorázové heslo",
		"isAlreadyTranslated": true
	},
	"Login": {
		"text": "Přihlásit se",
		"isAlreadyTranslated": true
	},
	"User Type": {
		"text": "Typ uživatele",
		"isAlreadyTranslated": true
	},
	"Username/Email": {
		"text": "Uživatelské jméno/e-mail",
		"isAlreadyTranslated": true
	},
	"View requests": {
		"text": "Zobrazit požadavky",
		"isAlreadyTranslated": true
	},
	"All fields are mandatory.": {
		"text": "Všechna pole jsou povinná.",
		"isAlreadyTranslated": true
	},
	"Pickup Details": {
		"text": "Detaily vyzvednutí",
		"isAlreadyTranslated": true
	},
	"Drop Details": {
		"text": "Detaily předání",
		"isAlreadyTranslated": true
	},
	"Check Service Availability": {
		"text": "Zkontrolovat dostupnost servisu",
		"isAlreadyTranslated": true
	},
	"Invoice Date": {
		"text": "Datum vystavení faktury",
		"isAlreadyTranslated": true
	},
	"Flow": {
		"text": "Průběh",
		"isAlreadyTranslated": true
	},
	"Issue": {
		"text": "Problém",
		"isAlreadyTranslated": true
	},
	"Reference Number": {
		"text": "Referenční číslo",
		"isAlreadyTranslated": true
	},
	"Provider": {
		"text": "Poskytovatel",
		"isAlreadyTranslated": true
	},
	"Create Shipment": {
		"text": "Vytvořit zásilku",
		"isAlreadyTranslated": true
	},
	"Logistics request is not raised. Please try again": {
		"text": "Logistický požadavek nebyl podán. Zkuste to znovu",
		"isAlreadyTranslated": true
	},
	"IMEI has to be atleast 15 characters.": {
		"text": "Číslo IMEI musí mít alespoň 15 znaků.",
		"isAlreadyTranslated": true
	},
	"The request is already active with this IMEI/Serial No.": {
		"text": "Požadavek pro toto číslo IMEI/sériové č. je již aktivní",
		"isAlreadyTranslated": true
	},
	"Service Location Engineer Mapping": {
		"text": "Mapa servisních míst a techniků",
		"isAlreadyTranslated": true
	},
	"Pincode Engineer Mapping": {
		"text": "Mapa kódů PIN a techniků",
		"isAlreadyTranslated": true
	},
	"Last set on": {
		"text": "Datum posledního nastavení",
		"isAlreadyTranslated": true
	},
	"Variant": {
		"text": "Varianta",
		"isAlreadyTranslated": true
	},
	"MOP for Trade-in": {
		"text": "MOP pro protiúčet",
		"isAlreadyTranslated": true
	},
	"Cashback": {
		"text": "Cashback",
		"isAlreadyTranslated": true
	},
	"Show Trade-In Amount": {
		"text": "Zobrazit částku protiúčtu",
		"isAlreadyTranslated": true
	},
	"No records": {
		"text": "Žádné záznamy",
		"isAlreadyTranslated": true
	},
	"Battery Conditions": {
		"text": "Stavy baterie",
		"isAlreadyTranslated": true
	},
	"Body Conditions": {
		"text": "Stavy těla",
		"isAlreadyTranslated": true
	},
	"Nothing to save": {
		"text": "Není co uložit",
		"isAlreadyTranslated": true
	},
	"Push Notification": {
		"text": "Oznámení Push",
		"isAlreadyTranslated": true
	},
	"Is Warranty Expired?": {
		"text": "Vypršela platnost záruky?",
		"isAlreadyTranslated": true
	},
	"No. of Service Requests": {
		"text": "Počet požadavků na servis",
		"isAlreadyTranslated": true
	},
	"Please Close all the tasks": {
		"text": "Zavřete všechny úkoly",
		"isAlreadyTranslated": true
	},
	"Please rate the service": {
		"text": "Ohodnoťte servis",
		"isAlreadyTranslated": true
	},
	"Feedback": {
		"text": "Zpětná vazba",
		"isAlreadyTranslated": true
	},
	"Submit with Feedback": {
		"text": "Odeslat se zpětnou vazbou",
		"isAlreadyTranslated": true
	},
	"Please select a reason for cancelling service": {
		"text": "Vyberte důvod zrušení servisu",
		"isAlreadyTranslated": true
	},
	"Close Visit": {
		"text": "Zavřít návštěvu",
		"isAlreadyTranslated": true
	},
	"Visit Disposition Status": {
		"text": "Stav konfigurace návštěvy",
		"isAlreadyTranslated": true
	},
	"Request Status": {
		"text": "Stav požadavku",
		"isAlreadyTranslated": true
	},
	"Complete Visit": {
		"text": "Dokončit návštěvu",
		"isAlreadyTranslated": true
	},
	"Submit IRIS": {
		"text": "Odeslat IRIS",
		"isAlreadyTranslated": true
	},
	"Select Symptom": {
		"text": "Vybrat příznak",
		"isAlreadyTranslated": true
	},
	"Select Action": {
		"text": "Vybrat akci",
		"isAlreadyTranslated": true
	},
	"Select Fault": {
		"text": "Vybrat závadu",
		"isAlreadyTranslated": true
	},
	"Symptom": {
		"text": "Příznak",
		"isAlreadyTranslated": true
	},
	"Fault": {
		"text": "Chyba",
		"isAlreadyTranslated": true
	},
	"Complete Visit And Submit IRIS": {
		"text": "Dokončit návštěvu a odeslat IRIS",
		"isAlreadyTranslated": true
	},
	"Create Visit": {
		"text": "Vytvořit návštěvu",
		"isAlreadyTranslated": true
	},
	"Select an Engineer to assign": {
		"text": "Vyberte technika k přiřazení",
		"isAlreadyTranslated": true
	},
	"Reason For Follow Up": {
		"text": "Důvod následné kontroly",
		"isAlreadyTranslated": true
	},
	"Clone this request": {
		"text": "Klonovat tento požadavek",
		"isAlreadyTranslated": true
	},
	"No of requests to be cloned": {
		"text": "Počet požadavků ke klonování",
		"isAlreadyTranslated": true
	},
	"Priority": {
		"text": "Priorita",
		"isAlreadyTranslated": true
	},
	"Update Job": {
		"text": "Aktualizovat zakázku",
		"isAlreadyTranslated": true
	},
	"Journey Details": {
		"text": "Detaily cesty",
		"isAlreadyTranslated": true
	},
	"Enter date": {
		"text": "Zadejte datum",
		"isAlreadyTranslated": true
	},
	"Begin time": {
		"text": "Čas zahájení",
		"isAlreadyTranslated": true
	},
	"End time": {
		"text": "Čas ukončení",
		"isAlreadyTranslated": true
	},
	"Note : Time must be in between": {
		"text": "Poznámka: Čas musí být v rozmezí",
		"isAlreadyTranslated": true
	},
	"Add Parts": {
		"text": "Přidat díly",
		"isAlreadyTranslated": true
	},
	"Avl. Quantity": {
		"text": "Avl. Množství",
		"isAlreadyTranslated": true
	},
	"Added Parts": {
		"text": "Přidané díly",
		"isAlreadyTranslated": true
	},
	"Document": {
		"text": "Dokument",
		"isAlreadyTranslated": true
	},
	"View Image": {
		"text": "Zobrazit obrázek",
		"isAlreadyTranslated": true
	},
	"No parts found for this product.": {
		"text": "Pro tento produkt nebyly nalezeny žádné díly.",
		"isAlreadyTranslated": true
	},
	"Can't add more than one discount": {
		"text": "Nelze přidat více než jednu slevu",
		"isAlreadyTranslated": true
	},
	"This IRIS has already been added.": {
		"text": "Tento IRIS byl již přidán.",
		"isAlreadyTranslated": true
	},
	"Please add a part from dropdown.": {
		"text": "Přidejte díl z rozevíracího seznamu.",
		"isAlreadyTranslated": true
	},
	"Part is already added": {
		"text": "Díl již byl přidán",
		"isAlreadyTranslated": true
	},
	"Full box unit is already selected.": {
		"text": "Již je vybráno kompletní balení.",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to create a visit?": {
		"text": "Opravdu chcete vytvořit návštěvu?",
		"isAlreadyTranslated": true
	},
	"Please select an engineer to assign": {
		"text": "Vyberte technika k přiřazení",
		"isAlreadyTranslated": true
	},
	"IRIS Codes has been updated successfully.": {
		"text": "Kód IRIS byl úspěšně aktualizován.",
		"isAlreadyTranslated": true
	},
	"The payment has been received and service will be completed.": {
		"text": "Platba byla přijata a servis bude dokončen.",
		"isAlreadyTranslated": true
	},
	"Converted Requests": {
		"text": "Převedené požadavky",
		"isAlreadyTranslated": true
	},
	"Identifiers": {
		"text": "Identifikátory",
		"isAlreadyTranslated": true
	},
	"Verify Device": {
		"text": "Ověřit zařízení",
		"isAlreadyTranslated": true
	},
	"Review Order": {
		"text": "Zkontrolovat objednávku",
		"isAlreadyTranslated": true
	},
	"Order Summary": {
		"text": "Přehled objednávky",
		"isAlreadyTranslated": true
	},
	"Congratulations! Your device is eligible for this plan": {
		"text": "Blahopřejeme! Tento plán lze použít pro toto zařízení",
		"isAlreadyTranslated": true
	},
	"This Device is Eligible for this Plan": {
		"text": "Tento plán lze použít pro toto zařízení",
		"isAlreadyTranslated": true
	},
	"Price of Plan": {
		"text": "Cena plánu",
		"isAlreadyTranslated": true
	},
	"Transaction Details": {
		"text": "Detaily transakce",
		"isAlreadyTranslated": true
	},
	"Device Images": {
		"text": "Obrázky zařízení",
		"isAlreadyTranslated": true
	},
	"(All 6 Images are Mandatory)": {
		"text": "(Všech 6 obrázků je povinných)",
		"isAlreadyTranslated": true
	},
	"Product Purchase Date": {
		"text": "Datum nákupu produktu",
		"isAlreadyTranslated": true
	},
	"Product value": {
		"text": "Hodnota produktu",
		"isAlreadyTranslated": true
	},
	"Plan Price": {
		"text": "Cena plánu",
		"isAlreadyTranslated": true
	},
	"The user has agreed with the": {
		"text": "Uživatel odsouhlasil",
		"isAlreadyTranslated": true
	},
	"Terms and Conditions": {
		"text": "Všeobecné podmínky pojištění",
		"isAlreadyTranslated": true
	},
	"before purchasing this plan.": {
		"text": "před zakoupením tohoto plánu.",
		"isAlreadyTranslated": true
	},
	"Resend Link": {
		"text": "Znovu odeslat odkaz",
		"isAlreadyTranslated": true
	},
	"Refresh": {
		"text": "Obnovit",
		"isAlreadyTranslated": true
	},
	"Resend Payment Link": {
		"text": "Znovu odeslat odkaz na platbu",
		"isAlreadyTranslated": true
	},
	"Refund": {
		"text": "Vrácení peněz",
		"isAlreadyTranslated": true
	},
	"Invoice Number": {
		"text": "Číslo faktury",
		"isAlreadyTranslated": true
	},
	"Documents Uploaded": {
		"text": "Dokumenty byly nahrány",
		"isAlreadyTranslated": true
	},
	"Transaction Summary": {
		"text": "Přehled transakce",
		"isAlreadyTranslated": true
	},
	"Download": {
		"text": "Stáhnout",
		"isAlreadyTranslated": true
	},
	"Proof of Coverage (POC)": {
		"text": "Doklad o rozsahu platnosti (POC)",
		"isAlreadyTranslated": true
	},
	"Insurance Certificate": {
		"text": "Certifikát o pojištění",
		"isAlreadyTranslated": true
	},
	"Print Invoice": {
		"text": "Vytisknout fakturu",
		"isAlreadyTranslated": true
	},
	"Cancel Plan": {
		"text": "Zrušit plán",
		"isAlreadyTranslated": true
	},
	"Go to Home": {
		"text": "Domů",
		"isAlreadyTranslated": true
	},
	"Plan Cancelled": {
		"text": "Plán byl zrušen",
		"isAlreadyTranslated": true
	},
	"Check Eligibility": {
		"text": "Zkontrolovat způsobilost",
		"isAlreadyTranslated": true
	},
	"Repair Date": {
		"text": "Datum opravy",
		"isAlreadyTranslated": true
	},
	"Plan Purchase Date": {
		"text": "Datum nákupu plánu",
		"isAlreadyTranslated": true
	},
	"Sell Now": {
		"text": "Prodat",
		"isAlreadyTranslated": true
	},
	"Payment Link ReSent": {
		"text": "Odkaz na platbu byl znovu odeslán",
		"isAlreadyTranslated": true
	},
	"Payment Link Sent": {
		"text": "Odkaz na platbu byl odeslán",
		"isAlreadyTranslated": true
	},
	"Link sent to": {
		"text": "Odkaz byl odeslán na adresu",
		"isAlreadyTranslated": true
	},
	"The Payment link is only valid for 24 hours.": {
		"text": "Odkaz na platbu platí pouze 24 hodin.",
		"isAlreadyTranslated": true
	},
	"Email POC to below email address": {
		"text": "Odeslat e-mail s POC na níže uvedenou e-mailovou adresu",
		"isAlreadyTranslated": true
	},
	"Send a copy to below email address": {
		"text": "Zaslat kopii na níže uvedenou e-mailovou adresu",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to cancel the order?": {
		"text": "Opravdu chcete objednávku zrušit?",
		"isAlreadyTranslated": true
	},
	"Select reason for cancellation": {
		"text": "Vyberte důvod zrušení",
		"isAlreadyTranslated": true
	},
	"Cancel Order": {
		"text": "Zrušit objednávku",
		"isAlreadyTranslated": true
	},
	"Plan price data not found.": {
		"text": "Nebyla nalezena data o ceně plánu.",
		"isAlreadyTranslated": true
	},
	"Discount Value cannot be greater than 100%": {
		"text": "Hodnota slevy nesmí být větší než 100 %",
		"isAlreadyTranslated": true
	},
	"Discount Value cannot be greater than the Plan Price": {
		"text": "Hodnota slevy nesmí být větší než cena plánu",
		"isAlreadyTranslated": true
	},
	"Incorrect discount value": {
		"text": "Nesprávná hodnota slevy",
		"isAlreadyTranslated": true
	},
	"The payment link has been sent to '": {
		"text": "Odkaz na platbu byl odeslán na adresu",
		"isAlreadyTranslated": true
	},
	"You have successfully purchased": {
		"text": "Úspěšně jste zakoupili",
		"isAlreadyTranslated": true
	},
	"Your AppleCare Protection Plan proof of coverage document will be emailed to you in 24 hours.": {
		"text": "Doklad o rozsahu platnosti plánu AppleCare Protection Plan vám bude zaslán e-mailem do 24 hodin.",
		"isAlreadyTranslated": true
	},
	"Please enter correct email address": {
		"text": "Zadejte správnou e-mailovou adresu",
		"isAlreadyTranslated": true
	},
	"Enter Email Address of the user whose rights you want to": {
		"text": "Zadejte e-mailovou adresu uživatele, jehož oprávnění chcete",
		"isAlreadyTranslated": true
	},
	"Associated Partner": {
		"text": "Přidružený partner",
		"isAlreadyTranslated": true
	},
	"Access given for": {
		"text": "Přístup udělen pro",
		"isAlreadyTranslated": true
	},
	"Add Brands": {
		"text": "Přidat značky",
		"isAlreadyTranslated": true
	},
	"CHOOSE BRAND": {
		"text": "VYBRAT ZNAČKU",
		"isAlreadyTranslated": true
	},
	"No Other Requests": {
		"text": "Žádné další požadavky",
		"isAlreadyTranslated": true
	},
	"No parts requests in this section.": {
		"text": "V této sekci nejsou žádné požadavky na díly",
		"isAlreadyTranslated": true
	},
	"Charge Code": {
		"text": "Kód poplatku",
		"isAlreadyTranslated": true
	},
	"AWB Number": {
		"text": "Číslo AWB",
		"isAlreadyTranslated": true
	},
	"Add more": {
		"text": "Přidat další",
		"isAlreadyTranslated": true
	},
	"Vendor name": {
		"text": "Název dodavatele",
		"isAlreadyTranslated": true
	},
	"Instruction": {
		"text": "Pokyny",
		"isAlreadyTranslated": true
	},
	"Logistics Service Type": {
		"text": "Typ logistické služby",
		"isAlreadyTranslated": true
	},
	"SKU Requested": {
		"text": "Požadované SKU",
		"isAlreadyTranslated": true
	},
	"Code": {
		"text": "Kód",
		"isAlreadyTranslated": true
	},
	"Defective Serial No": {
		"text": "Sériové č. vadného dílu",
		"isAlreadyTranslated": true
	},
	"All parts consumed will be reverted and the Engineer has to handover these good parts to the Store Manager. Are you sure you want to proceed?'": {
		"text": "Všechny spotřebované díly budou vráceny zpět a technik musí tyto dobré díly odevzdat vedoucímu prodejny. Opravdu chcete pokračovat?‘",
		"isAlreadyTranslated": true
	},
	"Diagnostics Event Number": {
		"text": "Číslo diagnostické události",
		"isAlreadyTranslated": true
	},
	"Unable to validate details from GSX currently. Please try again after sometime.": {
		"text": "Aktuálně nelze ověřit detaily z GSX. Zkuste to později znovu.",
		"isAlreadyTranslated": true
	},
	"Please specify remarks": {
		"text": "Zadejte poznámky",
		"isAlreadyTranslated": true
	},
	"Engineers": {
		"text": "Technici",
		"isAlreadyTranslated": true
	},
	"Update Mapping": {
		"text": "Aktualizovat mapu",
		"isAlreadyTranslated": true
	},
	"mappings are shown above.": {
		"text": "mapy jsou zobrazeny výše.",
		"isAlreadyTranslated": true
	},
	"Engineers Under TL": {
		"text": "Technici pod TL",
		"isAlreadyTranslated": true
	},
	"Plan Header": {
		"text": "Záhlaví plánu",
		"isAlreadyTranslated": true
	},
	"Plan Benefits": {
		"text": "Benefity plánu",
		"isAlreadyTranslated": true
	},
	"Plan Information": {
		"text": "Informace o plánu",
		"isAlreadyTranslated": true
	},
	"Plan updated successfully": {
		"text": "Plán byl úspěšně aktualizován",
		"isAlreadyTranslated": true
	},
	"Tax is payable on Reverse Charge": {
		"text": "Daň je splatná v režimu reverse charge",
		"isAlreadyTranslated": true
	},
	"Invoice No": {
		"text": "Č. faktury",
		"isAlreadyTranslated": true
	},
	"Details of Receiver (Billed to)": {
		"text": "Údaje o příjemci (fakturační údaje)",
		"isAlreadyTranslated": true
	},
	"Details of Consignee (Shipped to)": {
		"text": "Údaje o příjemci (dodací adresa)",
		"isAlreadyTranslated": true
	},
	"Description of Goods/Services": {
		"text": "Popis zboží/služeb",
		"isAlreadyTranslated": true
	},
	"Invoice Total(In Words)": {
		"text": "Fakturovaná částka celkem (slovy)",
		"isAlreadyTranslated": true
	},
	"Invoice Total": {
		"text": "Fakturovaná částka celkem",
		"isAlreadyTranslated": true
	},
	"Advance Received": {
		"text": "Záloha byla přijata",
		"isAlreadyTranslated": true
	},
	"Payable Value": {
		"text": "Částka k úhradě",
		"isAlreadyTranslated": true
	},
	"We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.": {
		"text": "Prohlašujeme, že na této faktuře je uvedena skutečná cena uvedeného zboží a že všechny údaje jsou pravdivé a přesné.",
		"isAlreadyTranslated": true
	},
	"View Consumers": {
		"text": "Zobrazit spotřebitelé",
		"isAlreadyTranslated": true
	},
	"Plan Details": {
		"text": "Detaily plánu",
		"isAlreadyTranslated": true
	},
	"Plan FAQ": {
		"text": "Často kladné otázky k plánu",
		"isAlreadyTranslated": true
	},
	"Sold Plans": {
		"text": "Prodané plány",
		"isAlreadyTranslated": true
	},
	"No sold plans found for the selected date range.": {
		"text": "Pro vybraný interval dat nebyly nalezeny žádné prodané plány.",
		"isAlreadyTranslated": true
	},
	"Would you like to add a product?": {
		"text": "Chcete přidat produkt?",
		"isAlreadyTranslated": true
	},
	"Product is saved successfully": {
		"text": "Produkt byl úspěšně uložen",
		"isAlreadyTranslated": true
	},
	"Apply a filter before sending push notification.": {
		"text": "Před odesláním oznámení Push použijte filtr.",
		"isAlreadyTranslated": true
	},
	"Redeemed Coupons": {
		"text": "Uplatněné poukazy",
		"isAlreadyTranslated": true
	},
	"Start at your Home screen.": {
		"text": "Začněte na domovské obrazovce.",
		"isAlreadyTranslated": true
	},
	"Scroll to the bottom and tap Find My iPhone.": {
		"text": "Sjeďte dolů a klepněte na možnost Najít můj iPhone.",
		"isAlreadyTranslated": true
	},
	"Slide to turn off Find My iPhone and Send Last Location.": {
		"text": "Pomocí přepínače vypněte funkce Najít můj iPhone a Odeslat poslední polohu.",
		"isAlreadyTranslated": true
	},
	"Servify has received your device and will be sending it to the eRecycling hub after verification.": {
		"text": "Společnost Servify obdržela vaše zařízení a po ověření jej odešle do centra eRecycling.",
		"isAlreadyTranslated": true
	},
	"Servify has collected your device and will be sending it to the eRecycling hub after verification.": {
		"text": "Společnost Servify vyzvedla vaše zařízení a po ověření jej odešle do centra eRecycling.",
		"isAlreadyTranslated": true
	},
	"All the data from your device has been erased.": {
		"text": "Veškerá data z vašeho zařízení byla vymazána.",
		"isAlreadyTranslated": true
	},
	"Please rate your eRecycling experience.": {
		"text": "Ohodnoťte svoji zkušenost se službou eRecycling.",
		"isAlreadyTranslated": true
	},
	"We have disassembled key components of your device and will be sending it to the eRecycling Hub soon.": {
		"text": "Rozebrali jsme vaše zařízení na hlavní komponenty a brzy je odešleme do centra eRecycling Hub.",
		"isAlreadyTranslated": true
	},
	"Date of Refund": {
		"text": "Datum vrácení peněz",
		"isAlreadyTranslated": true
	},
	"Select Category for the appliance": {
		"text": "Vyberte kategorii pro zařízení",
		"isAlreadyTranslated": true
	},
	"Select Category": {
		"text": "Vybrat kategorii",
		"isAlreadyTranslated": true
	},
	"Please select which applies.": {
		"text": "Vyberte možnost, která platí.",
		"isAlreadyTranslated": true
	},
	"Is Electrically OK": {
		"text": "Je v pořádku po elektrické stránce",
		"isAlreadyTranslated": true
	},
	"Are Accessories Available": {
		"text": "Je k dispozici příslušenství",
		"isAlreadyTranslated": true
	},
	"Dents/Scratches": {
		"text": "Vrypy/poškrábání",
		"isAlreadyTranslated": true
	},
	"Packing Box": {
		"text": "Krabice na zabalení",
		"isAlreadyTranslated": true
	},
	"Approve Refund Request": {
		"text": "Schválit požadavek na vrácení peněz",
		"isAlreadyTranslated": true
	},
	"Please enter Token Number.": {
		"text": "Zadejte číslo pořadového tiketu.",
		"isAlreadyTranslated": true
	},
	"Please select a Category from the dropdown": {
		"text": "Vyberte z rozevíracího seznamu kategorii",
		"isAlreadyTranslated": true
	},
	"Reject Refund Request": {
		"text": "Zamítnout požadavek na vrácení peněz",
		"isAlreadyTranslated": true
	},
	"Reason for rejection": {
		"text": "Důvod zamítnutí",
		"isAlreadyTranslated": true
	},
	"Reason for": {
		"text": "Důvod pro",
		"isAlreadyTranslated": true
	},
	"Please enter Serial Number.": {
		"text": "Zadejte sériové číslo.",
		"isAlreadyTranslated": true
	},
	"Select a time slot": {
		"text": "Vyberte čas",
		"isAlreadyTranslated": true
	},
	"Add reason": {
		"text": "Přidat důvod",
		"isAlreadyTranslated": true
	},
	"Please enter correct serial number": {
		"text": "Zadejte správné sériové číslo",
		"isAlreadyTranslated": true
	},
	"Download Report": {
		"text": "Stáhnout zprávu",
		"isAlreadyTranslated": true
	},
	"Filter Reports": {
		"text": "Filtrovat zprávy",
		"isAlreadyTranslated": true
	},
	"Recent Requested Report": {
		"text": "Poslední požadovaná zpráva",
		"isAlreadyTranslated": true
	},
	"Report Type": {
		"text": "Typ zprávy",
		"isAlreadyTranslated": true
	},
	"Report requested on": {
		"text": "Datum požadavku na zprávu",
		"isAlreadyTranslated": true
	},
	"Report Status": {
		"text": "Stav zprávy",
		"isAlreadyTranslated": true
	},
	"Requested Reports": {
		"text": "Požadované zprávy",
		"isAlreadyTranslated": true
	},
	"Logistics Vendor": {
		"text": "Logistický dodavatel",
		"isAlreadyTranslated": true
	},
	"Organization Outlet Locations": {
		"text": "Výdejní místa organizace",
		"isAlreadyTranslated": true
	},
	"Type of Report": {
		"text": "Typ zprávy",
		"isAlreadyTranslated": true
	},
	"Request Type": {
		"text": "Typ požadavku",
		"isAlreadyTranslated": true
	},
	"No OLCSM found for selected Service Location": {
		"text": "Pro vybrané servisní místo nebylo nalezeno žádné OLCSM",
		"isAlreadyTranslated": true
	},
	"No Engineer found for selected Service Location'": {
		"text": "Pro vybrané servisní místo nebyl nalezen žádný technik",
		"isAlreadyTranslated": true
	},
	"No partner service location details found for'": {
		"text": "Nebyly nalezeny žádné detaily partnerského servisního místa pro",
		"isAlreadyTranslated": true
	},
	"No partner details found for": {
		"text": "Nebyly nalezeny žádné detaily partnera pro",
		"isAlreadyTranslated": true
	},
	"Please select report type to download'": {
		"text": "Vyberte typ zprávy ke stažení",
		"isAlreadyTranslated": true
	},
	"Please select a vendor from Filter Reports'": {
		"text": "Vyberte v sekci Filtrovat zprávy dodavatele",
		"isAlreadyTranslated": true
	},
	"Please select a organization outlet location from Filter Reports'": {
		"text": "Vyberte v sekci Filtrovat zprávy výdejní místo organizace",
		"isAlreadyTranslated": true
	},
	"Please select a Service Location Engineer from Filter Reports'": {
		"text": "Vyberte v sekci Filtrovat zprávy technika servisního místa",
		"isAlreadyTranslated": true
	},
	"Please select a Type of Report from Filter Reports": {
		"text": "Vyberte v sekci Filtrovat zprávy typ zprávy",
		"isAlreadyTranslated": true
	},
	"Please select a Product from Filter Reports'": {
		"text": "Vyberte v sekci Filtrovat zprávy produkt",
		"isAlreadyTranslated": true
	},
	"Please select a Service Type from Filter Reports'": {
		"text": "Vyberte v sekci Filtrovat zprávy typ servisu",
		"isAlreadyTranslated": true
	},
	"Please select a product category from Filter Reports'": {
		"text": "Vyberte v sekci Filtrovat zprávy kategorii produktu",
		"isAlreadyTranslated": true
	},
	"Please select request type from Filter Reports'": {
		"text": "Vyberte v sekci Filtrovat zprávy typ požadavku",
		"isAlreadyTranslated": true
	},
	"Error in fetching Service Location IDs": {
		"text": "Při načítání ID servisních míst došlo k chybě",
		"isAlreadyTranslated": true
	},
	"REQUEST FORM": {
		"text": "FORMULÁŘ POŽADAVKU",
		"isAlreadyTranslated": true
	},
	"Sales Invoice": {
		"text": "Prodejní faktura",
		"isAlreadyTranslated": true
	},
	"EW claim no": {
		"text": "Č. reklamace EW",
		"isAlreadyTranslated": true
	},
	"Created By": {
		"text": "Vytvořil/a",
		"isAlreadyTranslated": true
	},
	"Google Location": {
		"text": "Poloha Google",
		"isAlreadyTranslated": true
	},
	"Upload invoice": {
		"text": "Nahrát fakturu",
		"isAlreadyTranslated": true
	},
	"VIP/Urgent": {
		"text": "VIP/urgentní",
		"isAlreadyTranslated": true
	},
	"Is Service complimentary ?": {
		"text": "Je služba bezplatná?",
		"isAlreadyTranslated": true
	},
	"Please enter correct Email ID": {
		"text": "Zadejte správnou e-mailovou adresu",
		"isAlreadyTranslated": true
	},
	"Please enter correct Mobile No": {
		"text": "Zadejte správné mobilní číslo",
		"isAlreadyTranslated": true
	},
	"Please enter correct Pincode": {
		"text": "Zadejte správný kód PIN",
		"isAlreadyTranslated": true
	},
	"Availability": {
		"text": "Dostupnost",
		"isAlreadyTranslated": true
	},
	"Skills": {
		"text": "Dovednosti",
		"isAlreadyTranslated": true
	},
	"Shop details updated successfully.": {
		"text": "Detaily prodejny byly úspěšně aktualizovány.",
		"isAlreadyTranslated": true
	},
	"New Shop added successfully.": {
		"text": "Nová prodejna byla úspěšně přidána.",
		"isAlreadyTranslated": true
	},
	"Save Details": {
		"text": "Uložit detaily",
		"isAlreadyTranslated": true
	},
	"Shop Name": {
		"text": "Název prodejny",
		"isAlreadyTranslated": true
	},
	"Shop Code": {
		"text": "Kód prodejny",
		"isAlreadyTranslated": true
	},
	"Carpet Area (in sq. feet)": {
		"text": "Plocha koberce (ve čtverečních metrech)",
		"isAlreadyTranslated": true
	},
	"Contact Number (10 digit)": {
		"text": "Kontaktní číslo (10 číslic)",
		"isAlreadyTranslated": true
	},
	"Period (Max 31 Days)": {
		"text": "Období (max. 31 dnů)",
		"isAlreadyTranslated": true
	},
	"Download list of requests": {
		"text": "Stáhnout seznam požadavků",
		"isAlreadyTranslated": true
	},
	"Upload list of requests": {
		"text": "Nahrát seznam požadavků",
		"isAlreadyTranslated": true
	},
	"Sample format for uploading requests": {
		"text": "Vzorový formát pro nahrávání požadavků",
		"isAlreadyTranslated": true
	},
	"There are no requests to be downloaded": {
		"text": "Neexistují žádné požadavky ke stažení",
		"isAlreadyTranslated": true
	},
	"Requests updated successfully": {
		"text": "Požadavky byly úspěšně aktualizovány",
		"isAlreadyTranslated": true
	},
	"Cannot read this file": {
		"text": "Tento soubor nelze číst",
		"isAlreadyTranslated": true
	},
	"Please upload file with appropriate data": {
		"text": "Nahrajte soubor se správnými daty",
		"isAlreadyTranslated": true
	},
	"Cancel Changes": {
		"text": "Zrušit změny",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to cancel changes you have made?": {
		"text": "Opravdu chcete zrušit provedené změny?",
		"isAlreadyTranslated": true
	},
	"Add Retail Location": {
		"text": "Přidat prodejní místo",
		"isAlreadyTranslated": true
	},
	"View Location": {
		"text": "Zobrazit místo",
		"isAlreadyTranslated": true
	},
	"Enter Mobile number of the": {
		"text": "Zadejte mobilní číslo uživatele",
		"isAlreadyTranslated": true
	},
	"whose rights you want to": {
		"text": "jehož oprávnění chcete",
		"isAlreadyTranslated": true
	},
	"modify": {
		"text": "změnit",
		"isAlreadyTranslated": true
	},
	"Associated Shop": {
		"text": "Přidružená prodejna",
		"isAlreadyTranslated": true
	},
	"choose shop to assign": {
		"text": "vyberte prodejnu k přiřazení",
		"isAlreadyTranslated": true
	},
	"Branch Code": {
		"text": "Kód pobočky",
		"isAlreadyTranslated": true
	},
	"TOTAL SALES DETAILS": {
		"text": "DETAILY CELKOVÝCH PRODEJŮ",
		"isAlreadyTranslated": true
	},
	"VERIFIED SALES DETAILS": {
		"text": "DETAILY OVĚŘENÝCH PRODEJŮ",
		"isAlreadyTranslated": true
	},
	"UNVERIFIED SALES DETAILS": {
		"text": "DETAILY NEOVĚŘENÝCH PRODEJŮ",
		"isAlreadyTranslated": true
	},
	"SHOP & ADVISOR DETAILS": {
		"text": "DETAILY PRODEJNY A PORADCE",
		"isAlreadyTranslated": true
	},
	"Todays Attendance": {
		"text": "Dnešní přítomnost",
		"isAlreadyTranslated": true
	},
	"Device Model List": {
		"text": "Seznam modelů zařízení",
		"isAlreadyTranslated": true
	},
	"Select Plans": {
		"text": "Vybrat plány",
		"isAlreadyTranslated": true
	},
	"No Plans Available": {
		"text": "Nejsou k dispozici žádné plány",
		"isAlreadyTranslated": true
	},
	"Product details cannot be validated. Please choose from the device list.'": {
		"text": "Detaily produktu nelze ověřit. Vyberte položku ze seznamu zařízení.",
		"isAlreadyTranslated": true
	},
	"Device is eligible for multiple plans. Please choose a plan.": {
		"text": "Pro toto zařízení lze použít více plánů. Vyberte plán.",
		"isAlreadyTranslated": true
	},
	"Device is eligible!": {
		"text": "Zařízení je způsobilé!",
		"isAlreadyTranslated": true
	},
	"Invoice No.": {
		"text": "Č. faktury",
		"isAlreadyTranslated": true
	},
	"Invoice Amount": {
		"text": "Částka faktury",
		"isAlreadyTranslated": true
	},
	"Sale Date": {
		"text": "Datum prodeje",
		"isAlreadyTranslated": true
	},
	"Create": {
		"text": "Vytvořit",
		"isAlreadyTranslated": true
	},
	"Active": {
		"text": "Aktivní",
		"isAlreadyTranslated": true
	},
	"Inactive": {
		"text": "Neaktivní",
		"isAlreadyTranslated": true
	},
	"Pending": {
		"text": "Nevyřízené",
		"isAlreadyTranslated": true
	},
	"Please select a location": {
		"text": "Vyberte místo",
		"isAlreadyTranslated": true
	},
	"Content ID": {
		"text": "ID obsahu",
		"isAlreadyTranslated": true
	},
	"Last Modified": {
		"text": "Datum poslední změny",
		"isAlreadyTranslated": true
	},
	"You have no new requests.": {
		"text": "Nemáte žádné nové požadavky",
		"isAlreadyTranslated": true
	},
	"Detail View": {
		"text": "Detailní zobrazení",
		"isAlreadyTranslated": true
	},
	"Complete Analysis": {
		"text": "Kompletní analýza",
		"isAlreadyTranslated": true
	},
	"Root Cause Analysis.": {
		"text": "Analýza základních příčin.",
		"isAlreadyTranslated": true
	},
	"Solution Provided.": {
		"text": "Řešení bylo poskytnuto.",
		"isAlreadyTranslated": true
	},
	"Accessories will not be returned.": {
		"text": "Příslušenství nebude vráceno.",
		"isAlreadyTranslated": true
	},
	"Assign Engineer": {
		"text": "Přiřadit technika",
		"isAlreadyTranslated": true
	},
	"Update job details": {
		"text": "Aktualizujte detaily zakázky",
		"isAlreadyTranslated": true
	},
	"Update device details and then validate issues": {
		"text": "Aktualizujte detaily zařízení a poté ověřte problémy",
		"isAlreadyTranslated": true
	},
	"Update device details": {
		"text": "Aktualizujte detaily zařízení",
		"isAlreadyTranslated": true
	},
	"Receive payment & rate consumer": {
		"text": "Přijměte platbu a ohodnoťte spotřebitele",
		"isAlreadyTranslated": true
	},
	"Update invoice details and then press confirm invoice": {
		"text": "Aktualizujte údaje na faktuře a poté stiskněte tlačítko Potvrdit fakturu",
		"isAlreadyTranslated": true
	},
	"Do you want to accept this request?": {
		"text": "Chcete tento požadavek přijmout?",
		"isAlreadyTranslated": true
	},
	"Visit Time": {
		"text": "Čas návštěvy",
		"isAlreadyTranslated": true
	},
	"Filter by": {
		"text": "Filtrovat podle",
		"isAlreadyTranslated": true
	},
	"Partner User": {
		"text": "Uživatel partnera",
		"isAlreadyTranslated": true
	},
	"Choose Partner User": {
		"text": "Vybrat uživatele partnera",
		"isAlreadyTranslated": true
	},
	"Invalid email id entered": {
		"text": "Zadaná e-mailová adresa je neplatná",
		"isAlreadyTranslated": true
	},
	"No symptoms found": {
		"text": "Nebyly nalezeny žádné příznaky",
		"isAlreadyTranslated": true
	},
	"No faults found": {
		"text": "Nebyly nalezeny žádné závady",
		"isAlreadyTranslated": true
	},
	"No actions found": {
		"text": "Nebyly nalezeny žádné akce",
		"isAlreadyTranslated": true
	},
	"Month": {
		"text": "Měsíc",
		"isAlreadyTranslated": true
	},
	"Target Amount": {
		"text": "Cílová částka",
		"isAlreadyTranslated": true
	},
	"Total Target": {
		"text": "Celková částka",
		"isAlreadyTranslated": true
	},
	"Past targets cannot be edited.": {
		"text": "Cíle v minulosti nelze upravovat.",
		"isAlreadyTranslated": true
	},
	"Already Closed": {
		"text": "Již uzavřeno",
		"isAlreadyTranslated": true
	},
	"No tasks Available": {
		"text": "Nejsou k dispozici žádné úkoly",
		"isAlreadyTranslated": true
	},
	"Close Task": {
		"text": "Zavřít úkol",
		"isAlreadyTranslated": true
	},
	"Task Reference Id": {
		"text": "Referenční ID úkolu",
		"isAlreadyTranslated": true
	},
	"Edit Remark": {
		"text": "Upravit poznámku",
		"isAlreadyTranslated": true
	},
	"Task Details of": {
		"text": "Detaily úkolu",
		"isAlreadyTranslated": true
	},
	"Updated Date": {
		"text": "Datum aktualizace",
		"isAlreadyTranslated": true
	},
	"Closed Date": {
		"text": "Datum uzavření",
		"isAlreadyTranslated": true
	},
	"Temp Consumer data": {
		"text": "Dočasná data spotřebitele",
		"isAlreadyTranslated": true
	},
	"No temp consumer data": {
		"text": "Žádná dočasná data spotřebitele",
		"isAlreadyTranslated": true
	},
	"Repair completed and Inform customer": {
		"text": "Oprava byla dokončena, je třeba informovat zákazníka",
		"isAlreadyTranslated": true
	},
	"Validate IMEI": {
		"text": "Ověřit IMEI",
		"isAlreadyTranslated": true
	},
	"Invalid IMEI": {
		"text": "Neplatné IMEI",
		"isAlreadyTranslated": true
	},
	"Last Diagnosis Report": {
		"text": "Poslední diagnostický protokol",
		"isAlreadyTranslated": true
	},
	"No diagnosis report": {
		"text": "Žádný diagnostický protokol",
		"isAlreadyTranslated": true
	},
	"Validate Device": {
		"text": "Ověřit zařízení",
		"isAlreadyTranslated": true
	},
	"Enter G Number": {
		"text": "Zadejte G-číslo",
		"isAlreadyTranslated": true
	},
	"Please enter valid the G number": {
		"text": "Zadejte platné G-číslo",
		"isAlreadyTranslated": true
	},
	"No Such Brand": {
		"text": "Zadaná značka neexistuje",
		"isAlreadyTranslated": true
	},
	"Selected Brands": {
		"text": "Vybrané značky",
		"isAlreadyTranslated": true
	},
	"Remaining Brands": {
		"text": "Zbývající značky",
		"isAlreadyTranslated": true
	},
	"Selected Partner": {
		"text": "Vybraný partner",
		"isAlreadyTranslated": true
	},
	"No Such Partner": {
		"text": "Zadaný partner neexistuje",
		"isAlreadyTranslated": true
	},
	"CHOOSE PARTNER": {
		"text": "VYBRAT PARTNERA",
		"isAlreadyTranslated": true
	},
	"Add Partner": {
		"text": "Přidat partnera",
		"isAlreadyTranslated": true
	},
	"Remaining Partners": {
		"text": "Zbývající partneři",
		"isAlreadyTranslated": true
	},
	"Add Plan": {
		"text": "Přidat plán",
		"isAlreadyTranslated": true
	},
	"CHOOSE PLAN": {
		"text": "VYBRAT PLÁN",
		"isAlreadyTranslated": true
	},
	"No Such Plan": {
		"text": "Zadaný plán neexistuje",
		"isAlreadyTranslated": true
	},
	"Selected Plan": {
		"text": "Vybraný plán",
		"isAlreadyTranslated": true
	},
	"Remaining Plan": {
		"text": "Zbývající plán",
		"isAlreadyTranslated": true
	},
	"CHOOSE PRODUCT": {
		"text": "VYBRAT PRODUKT",
		"isAlreadyTranslated": true
	},
	"No Such Product Found": {
		"text": "Zadaný produkt nebyl nalezen",
		"isAlreadyTranslated": true
	},
	"Selected Products": {
		"text": "Vybrané produkty",
		"isAlreadyTranslated": true
	},
	"Remaining Products": {
		"text": "Zbývající produkty",
		"isAlreadyTranslated": true
	},
	"Remaining Product Sub-Categories": {
		"text": "Zbývající podkategorie produktů",
		"isAlreadyTranslated": true
	},
	"Selected Product Sub-Categories": {
		"text": "Vybrané podkategorie produktů",
		"isAlreadyTranslated": true
	},
	"No Such Product Sub-Category": {
		"text": "Zadaná podkategorie produktů neexistuje",
		"isAlreadyTranslated": true
	},
	"CHOOSE Product Sub-Category": {
		"text": "VYBRAT podkategorii produktů",
		"isAlreadyTranslated": true
	},
	"Add Product Sub Category": {
		"text": "Přidat podkategorii produktů",
		"isAlreadyTranslated": true
	},
	"Add Service Centers": {
		"text": "Přidat servisní střediska",
		"isAlreadyTranslated": true
	},
	"CHOOSE LOCATION": {
		"text": "VYBRAT MÍSTO",
		"isAlreadyTranslated": true
	},
	"No Such Service Location": {
		"text": "Zadané servisní místo neexistuje",
		"isAlreadyTranslated": true
	},
	"Selected Service Centers": {
		"text": "Vybraná servisní střediska",
		"isAlreadyTranslated": true
	},
	"Remaining Service Centers": {
		"text": "Zbývající servisní střediska",
		"isAlreadyTranslated": true
	},
	"Add Service Type": {
		"text": "Přidat typ servisu",
		"isAlreadyTranslated": true
	},
	"CHOOSE SERVICE TYPE": {
		"text": "VYBRAT TYP SERVISU",
		"isAlreadyTranslated": true
	},
	"No Such Service Type": {
		"text": "Zadaný typ servisu neexistuje",
		"isAlreadyTranslated": true
	},
	"Selected Service Type": {
		"text": "Vybraný typ servisu",
		"isAlreadyTranslated": true
	},
	"Remaining Service Types": {
		"text": "Zbývající typy servisu",
		"isAlreadyTranslated": true
	},
	"Add Store": {
		"text": "Přidat prodejnu",
		"isAlreadyTranslated": true
	},
	"CHOOSE STORE": {
		"text": "VYBRAT PRODEJNU",
		"isAlreadyTranslated": true
	},
	"No Such Store": {
		"text": "Zadaná prodejna neexistuje",
		"isAlreadyTranslated": true
	},
	"Selected Store": {
		"text": "Vybraná prodejna",
		"isAlreadyTranslated": true
	},
	"Remaining Stores": {
		"text": "Zbývající prodejny",
		"isAlreadyTranslated": true
	},
	"Back to all users": {
		"text": "Zpět na všechny uživatele",
		"isAlreadyTranslated": true
	},
	"Save Rights": {
		"text": "Uložit oprávnění",
		"isAlreadyTranslated": true
	},
	"This Email Address has already taken, please enter a new email address.": {
		"text": "Tato e-mailová adresa je již použita; zadejte jinou adresu.",
		"isAlreadyTranslated": true
	},
	"Enter Email Address of the user whose rights you want to add/modify.": {
		"text": "Zadejte e-mailovou adresu uživatele, jehož oprávnění chcete přidat/změnit.",
		"isAlreadyTranslated": true
	},
	"Enter Theme Name": {
		"text": "Zadat název motivu",
		"isAlreadyTranslated": true
	},
	"Add/Update Country Code": {
		"text": "Přidat/aktualizovat kód země",
		"isAlreadyTranslated": true
	},
	"Add/Update Language Code": {
		"text": "Přidat/aktualizovat kód jazyka",
		"isAlreadyTranslated": true
	},
	"Modules": {
		"text": "Moduly",
		"isAlreadyTranslated": true
	},
	"Tracking Details": {
		"text": "Detaily sledování",
		"isAlreadyTranslated": true
	},
	"Engineer Details": {
		"text": "Detaily technika",
		"isAlreadyTranslated": true
	},
	"Number of assigned jobs": {
		"text": "Počet přiřazených zakázek",
		"isAlreadyTranslated": true
	},
	"Attendance": {
		"text": "Přítomnost",
		"isAlreadyTranslated": true
	},
	"App Status": {
		"text": "Stav aplikace",
		"isAlreadyTranslated": true
	},
	"Current Latitude": {
		"text": "Aktuální zeměpisná šířka",
		"isAlreadyTranslated": true
	},
	"Current Longitude": {
		"text": "Aktuální zeměpisná délka",
		"isAlreadyTranslated": true
	},
	"Current Pincode": {
		"text": "Aktuální kód PIN",
		"isAlreadyTranslated": true
	},
	"too much data to track": {
		"text": "Příliš mnoho dat ke sledování",
		"isAlreadyTranslated": true
	},
	"No data to track": {
		"text": "Žádná data ke sledování",
		"isAlreadyTranslated": true
	},
	"Tab Type": {
		"text": "Typ záložky",
		"isAlreadyTranslated": true
	},
	"Tab Title": {
		"text": "Název záložky",
		"isAlreadyTranslated": true
	},
	"Content Title": {
		"text": "Název obsahu",
		"isAlreadyTranslated": true
	},
	"Paragraphs": {
		"text": "Odstavce",
		"isAlreadyTranslated": true
	},
	"List": {
		"text": "Seznam",
		"isAlreadyTranslated": true
	},
	"Add rejection reason": {
		"text": "Přidejte důvod zamítnutí",
		"isAlreadyTranslated": true
	},
	"Please fill in the rejection reason before rejecting": {
		"text": "Před zamítnutím vyplňte důvod zamítnutí",
		"isAlreadyTranslated": true
	},
	"Purchased": {
		"text": "Zakoupeno",
		"isAlreadyTranslated": true
	},
	"Activated": {
		"text": "Aktivováno",
		"isAlreadyTranslated": true
	},
	"Registered": {
		"text": "Zaregistrováno",
		"isAlreadyTranslated": true
	},
	"Rejected": {
		"text": "Zamítnuto",
		"isAlreadyTranslated": true
	},
	"No such status found": {
		"text": "Zadaný stav nebyl nalezen",
		"isAlreadyTranslated": true
	},
	"Plan Expiry Date": {
		"text": "Datum konce platnosti plánu",
		"isAlreadyTranslated": true
	},
	"Plan Activation Date": {
		"text": "Datum aktivace plánu",
		"isAlreadyTranslated": true
	},
	"Report is empty.": {
		"text": "Zpráva je prázdná.",
		"isAlreadyTranslated": true
	},
	"Upload Bulk Plan": {
		"text": "Nahrát hromadný plán",
		"isAlreadyTranslated": true
	},
	"Please download the sample file, make changes in it only and upload it": {
		"text": "Stáhněte si ukázkový soubor, proveďte v něm změny a nahrajte jej zpět",
		"isAlreadyTranslated": true
	},
	"File Uploaded Successfully.": {
		"text": "Soubor byl úspěšně nahrán.",
		"isAlreadyTranslated": true
	},
	"File Uploaded Successfully with errors at following Row Number": {
		"text": "Soubor byl úspěšně nahrán s chybami na následujícím čísle řádku",
		"isAlreadyTranslated": true
	},
	"Bulk Plan Sales Upload": {
		"text": "Nahrát hromadný plán prodeje",
		"isAlreadyTranslated": true
	},
	"Uploaded successfully": {
		"text": "Nahrávání proběhlo úspěšně",
		"isAlreadyTranslated": true
	},
	"Visit Start Time": {
		"text": "Čas začátku návštěvy",
		"isAlreadyTranslated": true
	},
	"Visit End Time": {
		"text": "Čas konce návštěvy",
		"isAlreadyTranslated": true
	},
	"Engineer Name": {
		"text": "Jméno technika",
		"isAlreadyTranslated": true
	},
	"Visit Status": {
		"text": "Stav návštěvy",
		"isAlreadyTranslated": true
	},
	"Remarks": {
		"text": "Poznámky",
		"isAlreadyTranslated": true
	},
	"There are no visits for this request.": {
		"text": "Pro tento požadavek neexistují žádné návštěvy.",
		"isAlreadyTranslated": true
	},
	"Appointment Date": {
		"text": "Datum schůzky",
		"isAlreadyTranslated": true
	},
	"Actual Visit Date": {
		"text": "Skutečné datum návštěvy",
		"isAlreadyTranslated": true
	},
	"Appointment Time Slot": {
		"text": "Čas schůzky",
		"isAlreadyTranslated": true
	},
	"Actual Visit Time": {
		"text": "Skutečný čas návštěvy",
		"isAlreadyTranslated": true
	},
	"Technician Name": {
		"text": "Jméno technika",
		"isAlreadyTranslated": true
	},
	"Observation": {
		"text": "Pozorování",
		"isAlreadyTranslated": true
	},
	"Please follow below steps to Update Activation Token for your linked GSX User ID": {
		"text": "Podle následujícího postupu aktualizujte pořadový tiket aktivace pro vaše připojené ID uživatele GSX",
		"isAlreadyTranslated": true
	},
	"Click on the below link to navigate to the Apple Activation Token Site": {
		"text": "Kliknutím na níže uvedený odkaz přejděte na stránku pořadových tiketů pro aktivaci Apple",
		"isAlreadyTranslated": true
	},
	"Use GSX ID and Password to login. Post login, you will be able to view you Activation Token": {
		"text": "K přihlášení použijte ID a heslo pro GSX. Po přihlášení si můžete zobrazit svůj pořadový tiket aktivace.",
		"isAlreadyTranslated": true
	},
	"Note: Generate the Activation Token for GSX ID mapped to your Servify ID only. GSX ID mapped to your Servify User ID is GSX Email ID": {
		"text": "Poznámka: Vygenerujte pořadový tiket aktivace pouze pro ID GSX namapované na vaše ID Servify. ID GSX namapované na ID uživatele Servify je e-mailová adresa GSX",
		"isAlreadyTranslated": true
	},
	"GSX Tech ID Mapped": {
		"text": "Namapované ID GSX Tech",
		"isAlreadyTranslated": true
	},
	'Use the "Copy to Clipboard" button to copy the Activation key': {
		"text": "Pomocí tlačítka „Kopírovat do schránky“ zkopírujte aktivační klíč",
		"isAlreadyTranslated": true
	},
	'Note: Use the "Copy to Clipboard" button only. Do NOT select manually to copy Activation Token. You are advised NOT to sign out from Apple Activation Token Site before Activation Token is saved in Servify CRM': {
		"text": "Poznámka: Použijte pouze tlačítko „Kopírovat do schránky“. NEKOPÍRUJTE pořadový tiket aktivace ručně. Doporučujeme se neodhlašovat ze stránky pořadových tiketů aktivace Apple, dokud není tiket uložen v systému Servify CRM",
		"isAlreadyTranslated": true
	},
	"Paste the Activation Token in the text box below & click Save.": {
		"text": "Vložte pořadový tiket aktivace do textového pole níže a klikněte na tlačítko Uložit.",
		"isAlreadyTranslated": true
	},
	"Normal Repair": {
		"text": "Normální oprava",
		"isAlreadyTranslated": true
	},
	"Check whitelisting": {
		"text": "Zkontrolujte whitelist",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to receive device.": {
		"text": "Čeká se, až pracovník péče o zákazníky přijme zařízení.",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, click on Non Hardware Closure for soft,ware repair or use Provisional Estimate to give a repair estimate to customer.": {
		"text": "Pracovník péče o zákazníky může spustit diagnostiku, provést inspekci zařízení a zaregistrovat hardwarovou opravu, v případě softwarové opravy kliknout na možnost „Uzavření bez hardwaru“ nebo pomocí tlačítka Předběžný odhad zpracovat pro zákazníka odhad ceny opravy.",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, click on Non Hardware Closure for software repair or use Provisional Estimate to give a repair estimate to customer.": {
		"text": "Pracovník péče o zákazníky může spustit diagnostiku, provést inspekci zařízení a zaregistrovat hardwarovou opravu, v případě softwarové opravy kliknout na možnost „Uzavření bez hardwaru“ nebo pomocí tlačítka Předběžný odhad zpracovat pro zákazníka odhad ceny opravy.",
		"isAlreadyTranslated": true
	},
	"Repair has been completed.": {
		"text": "Oprava byla dokončena.",
		"isAlreadyTranslated": true
	},
	"Service invoice has been generated.": {
		"text": "Faktura za servis byla vygenerována.",
		"isAlreadyTranslated": true
	},
	"Return Order Number": {
		"text": "Číslo vrácení objednávky",
		"isAlreadyTranslated": true
	},
	"Fulfiller Name": {
		"text": "Jméno zhotovitele",
		"isAlreadyTranslated": true
	},
	"Inspect Device": {
		"text": "Provést inspekci zařízení",
		"isAlreadyTranslated": true
	},
	"Inbound": {
		"text": "Příchozí",
		"isAlreadyTranslated": true
	},
	"Outbound": {
		"text": "Odchozí",
		"isAlreadyTranslated": true
	},
	"Please enter the mandatory field.": {
		"text": "Zadejte údaj do povinného pole.",
		"isAlreadyTranslated": true
	},
	"This is a mandatory field": {
		"text": "Toto pole je povinné.",
		"isAlreadyTranslated": true
	},
	"Please enter steps in less than 1500 characters": {
		"text": "Zadejte návod o délce nejvýše 1500 znaků",
		"isAlreadyTranslated": true
	},
	"Please enter notes in less than 1000 characters": {
		"text": "Zadejte poznámky o délce nejvýše 1000 znaků",
		"isAlreadyTranslated": true
	},
	"/1500 characters entered.": {
		"text": "/1500 zadaných znaků.",
		"isAlreadyTranslated": true
	},
	"/500 characters entered.": {
		"text": "/500 zadaných znaků.",
		"isAlreadyTranslated": true
	},
	"/1000 characters entered.": {
		"text": "/1000 zadaných znaků.",
		"isAlreadyTranslated": true
	},
	'Waiting for Engineer to "Validate Issues" and "Add Faults" in "Issues Reported" section below.': {
		"text": "Čeká se, až technik provede kroky „Ověřit problémy“ a „Přidat závady“ v sekci „Nahlášené problémy“ níže.",
		"isAlreadyTranslated": true
	},
	'Waiting for Engineer to "Select Symptom And Action" in "Report Issues to GSX" section below.': {
		"text": "Čeká se, až technik provede krok „Vybrat příznak a akci“ v sekci „Nahlásit problémy do GSX“ níže.",
		"isAlreadyTranslated": true
	},
	"Please select GSX repair type": {
		"text": "Vyberte typ opravy GSX",
		"isAlreadyTranslated": true
	},
	"Proceed with creating GSX repair": {
		"text": "Pokračujte vytvořením opravy GSX",
		"isAlreadyTranslated": true
	},
	"Please add part to Proceed with creating GSX repair": {
		"text": "Přidejte díl a pokračujte ve vytváření opravy GSX",
		"isAlreadyTranslated": true
	},
	"Waiting for Engineer to create GSX repair": {
		"text": "Čeká se, až technik vytvoří opravu GSX",
		"isAlreadyTranslated": true
	},
	"Component Code": {
		"text": "Kód součásti",
		"isAlreadyTranslated": true
	},
	"Pricing Option": {
		"text": "Varianta určení ceny",
		"isAlreadyTranslated": true
	},
	"Coverage Option": {
		"text": "Varianta rozsahu platnosti",
		"isAlreadyTranslated": true
	},
	"Please select a COMPTIA group from dropdown.": {
		"text": "Vyberte z rozevíracího seznamu skupinu COMPTIA.",
		"isAlreadyTranslated": true
	},
	"Please select a COMPTIA Code from dropdown.": {
		"text": "Vyberte z rozevíracího seznamu kód COMPTIA.",
		"isAlreadyTranslated": true
	},
	"Please select a Component Code from dropdown.": {
		"text": "Vyberte z rozevíracího seznamu kód součásti.",
		"isAlreadyTranslated": true
	},
	"Please select a Issue Code from dropdown.": {
		"text": "Vyberte z rozevíracího seznamu kód problému.",
		"isAlreadyTranslated": true
	},
	"Please enter the serial number of the part": {
		"text": "Zadejte sériové číslo dílu",
		"isAlreadyTranslated": true
	},
	"Please enter correct KBB serial number of the part": {
		"text": "Zadejte správné sériové číslo KBB dílu",
		"isAlreadyTranslated": true
	},
	"Please select a Covergage Option Code from dropdown.": {
		"text": "Vyberte z rozevíracího seznamu kód varianty rozsahu platnosti.",
		"isAlreadyTranslated": true
	},
	"Please select a Coverage Option Code from dropdown.": {
		"text": "Vyberte z rozevíracího seznamu kód varianty rozsahu platnosti.",
		"isAlreadyTranslated": true
	},
	"Serial Number is already added": {
		"text": "Sériové číslo je již přidáno",
		"isAlreadyTranslated": true
	},
	"Charge": {
		"text": "Poplatek",
		"isAlreadyTranslated": true
	},
	"Charge Name": {
		"text": "Název poplatku",
		"isAlreadyTranslated": true
	},
	"Charge Value": {
		"text": "Hodnota poplatku",
		"isAlreadyTranslated": true
	},
	"Tax": {
		"text": "Daň",
		"isAlreadyTranslated": true
	},
	"MRP": {
		"text": "MRP",
		"isAlreadyTranslated": true
	},
	"Final Price": {
		"text": "Finální cena",
		"isAlreadyTranslated": true
	},
	"Add Discount": {
		"text": "Přidat slevu",
		"isAlreadyTranslated": true
	},
	"Good Part Serial No": {
		"text": "Sériové číslo dílu OK",
		"isAlreadyTranslated": true
	},
	"Bad Part Serial No": {
		"text": "Sériové číslo dílu NOK",
		"isAlreadyTranslated": true
	},
	"Create GSX Repair": {
		"text": "Vytvořit opravu GSX",
		"isAlreadyTranslated": true
	},
	"Fetch Parts From GSX": {
		"text": "Načíst díly z GSX",
		"isAlreadyTranslated": true
	},
	"Fetch Latest Diagnostics Results": {
		"text": "Načíst výsledky poslední diagnostiky",
		"isAlreadyTranslated": true
	},
	"If you want to update Repair Type. Please remove parts.": {
		"text": "Chcete-li aktualizovat typ opravy. Odeberte díly.",
		"isAlreadyTranslated": true
	},
	"Requested": {
		"text": "Požadováno",
		"isAlreadyTranslated": true
	},
	"customerCare": {
		"text": "péče o zákazníky",
		"isAlreadyTranslated": true
	},
	"CustomerCare": {
		"text": "Péče o zákazníky",
		"isAlreadyTranslated": true
	},
	"View Delivery challan": {
		"text": "Zobrazit dodávkový list",
		"isAlreadyTranslated": true
	},
	"Register for Bulk Return": {
		"text": "Zaregistrovat pro hromadné vrácení",
		"isAlreadyTranslated": true
	},
	"View Packing List": {
		"text": "Zobrazit dodací list",
		"isAlreadyTranslated": true
	},
	"Dispatch": {
		"text": "Odeslat",
		"isAlreadyTranslated": true
	},
	"View Depot Shipper Label": {
		"text": "Zobrazit štítek přepravce zásilky",
		"isAlreadyTranslated": true
	},
	"View Return Label": {
		"text": "Zobrazit štítek pro vrácení",
		"isAlreadyTranslated": true
	},
	"Carrier": {
		"text": "Přepravce",
		"isAlreadyTranslated": true
	},
	"Add Details": {
		"text": "Přidat detaily",
		"isAlreadyTranslated": true
	},
	"Shipper": {
		"text": "Přepravce",
		"isAlreadyTranslated": true
	},
	"Parts handover note": {
		"text": "Přejímací protokol dílů",
		"isAlreadyTranslated": true
	},
	"Signature": {
		"text": "Podpis",
		"isAlreadyTranslated": true
	},
	"Dispatch Mail In Devices": {
		"text": "Odeslat mail v zařízeních",
		"isAlreadyTranslated": true
	},
	"Bulk Return ID": {
		"text": "ID pro hromadné vrácení",
		"isAlreadyTranslated": true
	},
	"Custom Notes": {
		"text": "Vlastní poznámky",
		"isAlreadyTranslated": true
	},
	"Receive device from RC": {
		"text": "Přijmout zařízení z RC",
		"isAlreadyTranslated": true
	},
	"Quotation document": {
		"text": "Dokument cenové nabídky",
		"isAlreadyTranslated": true
	},
	"Estimate Generated By": {
		"text": "Odhad byl vygenerován uživatelem",
		"isAlreadyTranslated": true
	},
	"Sl. No": {
		"text": "Sl. Č.",
		"isAlreadyTranslated": true
	},
	"Rate": {
		"text": "Sazba",
		"isAlreadyTranslated": true
	},
	"Taxable value": {
		"text": "Zdanitelná hodnota",
		"isAlreadyTranslated": true
	},
	"Total Tax": {
		"text": "Daň celkem",
		"isAlreadyTranslated": true
	},
	"Service Estimate Total": {
		"text": "Odhad ceny servisu celkem",
		"isAlreadyTranslated": true
	},
	"In Words": {
		"text": "Slovy",
		"isAlreadyTranslated": true
	},
	"Disclaimers": {
		"text": "Prohlášení o omezení odpovědnosti",
		"isAlreadyTranslated": true
	},
	"The charges mentioned in this Estimate are an approximation and is based on the anticipated details of the work to be carried out basis the inspection. It is possible for unexpected situations / complications to cause some deviation from this Estimate. If additional parts or labour are required you will be contacted immediately and upon your concurrence to carry out the details of the work, a new Estimate will be created for your consideration and approval.": {
		"text": "Poplatky uvedené v tomto odhadu jsou pouze přibližné a vycházejí z informací o potřebné práci zjištěných na základě prohlídky zařízení. V případě nečekaných situací či komplikací se výsledná cena může od tohoto odhadu lišit. Budou-li zapotřebí další díly nebo práce, budeme vás ihned kontaktovat a jestliže budete souhlasit s provedením těchto prací, bude vytvořen nový odhad ceny k vaší kontrole a schválení.",
		"isAlreadyTranslated": true
	},
	"Authorised Signatory": {
		"text": "Autorizovaný signatář",
		"isAlreadyTranslated": true
	},
	"Consumed": {
		"text": "Spotřebováno",
		"isAlreadyTranslated": true
	},
	"DOA": {
		"text": "Potvrzení o poškození při doručení",
		"isAlreadyTranslated": true
	},
	"Receive": {
		"text": "Přijmout",
		"isAlreadyTranslated": true
	},
	"Return": {
		"text": "Vrátit",
		"isAlreadyTranslated": true
	},
	"Return to Customer": {
		"text": "Vrácení zákazníkovi",
		"isAlreadyTranslated": true
	},
	"Repair completed and Intimate customer": {
		"text": "Oprava byla dokončena, je třeba informovat zákazníka",
		"isAlreadyTranslated": true
	},
	"QC rejected, Work in progress": {
		"text": "Kontrola kvality byla neúspěšná, probíhá práce",
		"isAlreadyTranslated": true
	},
	"Repair completed, Inform customer": {
		"text": "Oprava byla dokončena, je třeba informovat zákazníka",
		"isAlreadyTranslated": true
	},
	"Inspection in Progress": {
		"text": "Probíhá inspekce",
		"isAlreadyTranslated": true
	},
	"Unused": {
		"text": "Nepoužito",
		"isAlreadyTranslated": true
	},
	"Please select Tier part": {
		"text": "Vyberte díl podle úrovně",
		"isAlreadyTranslated": true
	},
	"Initiate AST 2 Diagnostics": {
		"text": "Zahájit diagnostiku AST 2",
		"isAlreadyTranslated": true
	},
	"Please add document": {
		"text": "Přidejte dokument",
		"isAlreadyTranslated": true
	},
	"Non Hardware Closure": {
		"text": "Uzavření bez hardwaru",
		"isAlreadyTranslated": true
	},
	"Select Repair Outcomes": {
		"text": "Vybrat výsledky opravy",
		"isAlreadyTranslated": true
	},
	"User can select upto 4 outcomes": {
		"text": "Uživatel může vybrat nejvýše 4 výsledky",
		"isAlreadyTranslated": true
	},
	"Create NTF Repair": {
		"text": "Vytvořit opravu NTF",
		"isAlreadyTranslated": true
	},
	"Delivery Notes": {
		"text": "Poznámky k doručení",
		"isAlreadyTranslated": true
	},
	"Notes will be displayed on Customer’s Delivery Report": {
		"text": "Poznámky budou uvedeny na dodávkovém listu zákazníka",
		"isAlreadyTranslated": true
	},
	"Cancel": {
		"text": "Zrušit",
		"isAlreadyTranslated": true
	},
	"Save": {
		"text": "Uložit",
		"isAlreadyTranslated": true
	},
	"Provisional Estimate": {
		"text": "Předběžný odhad",
		"isAlreadyTranslated": true
	},
	"Price": {
		"text": "Cena",
		"isAlreadyTranslated": true
	},
	"Total Provisional Estimate": {
		"text": "Předběžný odhad celkem",
		"isAlreadyTranslated": true
	},
	"Send Email to Customer": {
		"text": "Odeslat e-mail zákazníkovi",
		"isAlreadyTranslated": true
	},
	"Add New Billing Detail": {
		"text": "Přidat nový fakturační údaj",
		"isAlreadyTranslated": true
	},
	"No Billing Detail found": {
		"text": "Nebyly nalezeny žádné fakturační údaje",
		"isAlreadyTranslated": true
	},
	"Select Billing Detail": {
		"text": "Vybrat fakturační údaj",
		"isAlreadyTranslated": true
	},
	"Personal": {
		"text": "Fyzická osoba",
		"isAlreadyTranslated": true
	},
	"Company": {
		"text": "Společnost",
		"isAlreadyTranslated": true
	},
	"Registered Name": {
		"text": "Zaregistrované jméno",
		"isAlreadyTranslated": true
	},
	"Company Name": {
		"text": "Název společnosti",
		"isAlreadyTranslated": true
	},
	"Why Generate Activation token": {
		"text": "K čemu slouží pořadový tiket aktivace",
		"isAlreadyTranslated": true
	},
	"Activation Token is adds an additional layer of security while fetching information from GSX. This additional parameter of security has been added by Apple for accessing information using API. For more information, you can get in touch with Servify Administrator.": {
		"text": "Pořadový tiket aktivace přidává další vrstvu zabezpečení při načítání informací z GSX. Tento doplňkový parametr přidala společnost Apple pro přístup k informacím prostřednictvím rozhraní API. Další informace získáte od správce společnosti Servify",
		"isAlreadyTranslated": true
	},
	"Repair Type Description": {
		"text": "Popis typu opravy",
		"isAlreadyTranslated": true
	},
	"Non hardware closure details updated": {
		"text": "Detaily uzavření bez hardwaru byly aktualizovány",
		"isAlreadyTranslated": true
	},
	"Provisional estimate updated": {
		"text": "Předběžný odhad byl aktualizován",
		"isAlreadyTranslated": true
	},
	"Provisional estimate generated": {
		"text": "Předběžný odhad byl vygenerován",
		"isAlreadyTranslated": true
	},
	"Device inspected": {
		"text": "Proběhla inspekce zařízení",
		"isAlreadyTranslated": true
	},
	"Comptia Modifier": {
		"text": "Modifikátor CompTIA",
		"isAlreadyTranslated": true
	},
	"Enter File Name": {
		"text": "Zadat název souboru",
		"isAlreadyTranslated": true
	},
	"Run Diagnosis": {
		"text": "Spustit diagnostiku",
		"isAlreadyTranslated": true
	},
	"Skip diagnosis": {
		"text": "Přeskočit diagnostiku",
		"isAlreadyTranslated": true
	},
	"Inspect Device": {
		"text": "Provést inspekci zařízení",
		"isAlreadyTranslated": true
	},
	"Battery usage": {
		"text": "Využití baterie",
		"isAlreadyTranslated": true
	},
	"Call performance": {
		"text": "Výkonnost při telefonování",
		"isAlreadyTranslated": true
	},
	"Mobile resource Operator": {
		"text": "Operátor mobilních zdrojů",
		"isAlreadyTranslated": true
	},
	"Loaner diagnostics": {
		"text": "Diagnostika náhradního zařízení",
		"isAlreadyTranslated": true
	},
	"Alternative Mobile No": {
		"text": "Alternativní mobilní č.",
		"isAlreadyTranslated": true
	},
	"Logistics Report": {
		"text": "Logistická zpráva",
		"isAlreadyTranslated": true
	},
	"Select Report Type": {
		"text": "Vybrat typ zprávy",
		"isAlreadyTranslated": true
	},
	"Filter period": {
		"text": "Filtrovat období",
		"isAlreadyTranslated": true
	},
	"Service": {
		"text": "Servis",
		"isAlreadyTranslated": true
	},
	"Download report": {
		"text": "Stáhnout zprávu",
		"isAlreadyTranslated": true
	},
	"Parts": {
		"text": "Díly",
		"isAlreadyTranslated": true
	},
	"Select a Type": {
		"text": "Vybrat typ",
		"isAlreadyTranslated": true
	},
	"Filter Report": {
		"text": "Filtrovat zprávu",
		"isAlreadyTranslated": true
	},
	"FILTER PERIOD": {
		"text": "FILTROVAT OBDOBÍ",
		"isAlreadyTranslated": true
	},
	"No data found": {
		"text": "Nebyla nalezena žádná data",
		"isAlreadyTranslated": true
	},
	"Apple plans": {
		"text": "Plány Apple",
		"isAlreadyTranslated": true
	},
	"Know more": {
		"text": "Více",
		"isAlreadyTranslated": true
	},
	"Know More": {
		"text": "Více",
		"isAlreadyTranslated": true
	},
	"Failure": {
		"text": "Selhalo",
		"isAlreadyTranslated": true
	},
	"Pending": {
		"text": "Nevyřízené",
		"isAlreadyTranslated": true
	},
	"Sell": {
		"text": "Prodat",
		"isAlreadyTranslated": true
	},
	"Transaction Status": {
		"text": "Stav transakce",
		"isAlreadyTranslated": true
	},
	"Period": {
		"text": "Období",
		"isAlreadyTranslated": true
	},
	"Completed": {
		"text": "Dokončeno",
		"isAlreadyTranslated": true
	},
	"completed": {
		"text": "dokončeno",
		"isAlreadyTranslated": true
	},
	"Filter": {
		"text": "Filtr",
		"isAlreadyTranslated": true
	},
	"Total Count": {
		"text": "Celkový počet",
		"isAlreadyTranslated": true
	},
	"Total Value": {
		"text": "Celková hodnota",
		"isAlreadyTranslated": true
	},
	"Failed": {
		"text": "Selhalo",
		"isAlreadyTranslated": true
	},
	"Brands": {
		"text": "Značky",
		"isAlreadyTranslated": true
	},
	"Products": {
		"text": "Produkty",
		"isAlreadyTranslated": true
	},
	"Task Type": {
		"text": "Typ úkolu",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer assignment": {
		"text": "Čeká na přiřazení technika",
		"isAlreadyTranslated": true
	},
	"Provisional Charges Added/Updated": {
		"text": "Předběžné poplatky byly přidány/aktualizovány",
		"isAlreadyTranslated": true
	},
	"Part Provisional Charges Removed": {
		"text": "Předběžné poplatky za díly byly odebrány",
		"isAlreadyTranslated": true
	},
	"Device handed over by CustomerCare to ServicelocationEngineer": {
		"text": "Zařízení bylo předáno oddělením péče o zákazníky technikovi servisního pracoviště",
		"isAlreadyTranslated": true
	},
	"Device accepted by ServicelocationEngineer from CustomerCare": {
		"text": "Zařízení bylo přijato technikem servisního pracoviště od oddělení péče o zákazníky",
		"isAlreadyTranslated": true
	},
	"Select GSX Repair Type": {
		"text": "Vybrat typ opravy GSX",
		"isAlreadyTranslated": true
	},
	"Remove": {
		"text": "Odebrat",
		"isAlreadyTranslated": true
	},
	"View Provisional Estimate Document": {
		"text": "Zobrazit dokument předběžného odhadu",
		"isAlreadyTranslated": true
	},
	"View Estimation Document": {
		"text": "Zobrazit dokument odhadu",
		"isAlreadyTranslated": true
	},
	"Please Fill Payment Mode Details.": {
		"text": "Vyplňte detaily platební metody.",
		"isAlreadyTranslated": true
	},
	"Please Enter Amount.": {
		"text": "Zadejte částku.",
		"isAlreadyTranslated": true
	},
	"Additional COMPTIA codes": {
		"text": "Doplňkové kódy COMPTIA",
		"isAlreadyTranslated": true
	},
	"Please enter correct": {
		"text": "Zadejte správnou hodnotu ",
		"isAlreadyTranslated": true
	},
	"Please Enter Correct": {
		"text": "Zadejte správnou hodnotu ",
		"isAlreadyTranslated": true
	},
	"Out of warranty": {
		"text": "Bez záruky",
		"isAlreadyTranslated": true
	},
	"Advance Collected": {
		"text": "Záloha vybrána",
		"isAlreadyTranslated": true
	},
	"Enter Serial number": {
		"text": "Zadat sériové číslo",
		"isAlreadyTranslated": true
	},
	"Enter the Serial Number": {
		"text": "Zadat sériové číslo",
		"isAlreadyTranslated": true
	},
	"Enter the IMEI/Serial Number": {
		"text": "Zadat IMEI/sériové číslo",
		"isAlreadyTranslated": true
	},
	"Reject GSX Repair": {
		"text": "Zamítnout opravu GSX",
		"isAlreadyTranslated": true
	},
	"Inventory manager": {
		"text": "Správce zásob",
		"isAlreadyTranslated": true
	},
	"Ordered": {
		"text": "Objednáno",
		"isAlreadyTranslated": true
	},
	"Issued": {
		"text": "Vydáno",
		"isAlreadyTranslated": true
	},
	"Received": {
		"text": "Přijato",
		"isAlreadyTranslated": true
	},
	"Consumed": {
		"text": "Spotřebováno",
		"isAlreadyTranslated": true
	},
	"Defective": {
		"text": "Vadné",
		"isAlreadyTranslated": true
	},
	"Handover": {
		"text": "Předat",
		"isAlreadyTranslated": true
	},
	"handover": {
		"text": "předat",
		"isAlreadyTranslated": true
	},
	"ordered": {
		"text": "objednáno",
		"isAlreadyTranslated": true
	},
	"issued": {
		"text": "vydáno",
		"isAlreadyTranslated": true
	},
	"received": {
		"text": "přijato",
		"isAlreadyTranslated": true
	},
	"consumed": {
		"text": "spotřebováno",
		"isAlreadyTranslated": true
	},
	"defective": {
		"text": "vadné",
		"isAlreadyTranslated": true
	},
	"View Delivery Report": {
		"text": "Zobrazit dodávkový list",
		"isAlreadyTranslated": true
	},
	"Warranty Validated from Brand": {
		"text": "Záruka ověřena pro značku",
		"isAlreadyTranslated": true
	},
	"Please enter the reason for warranty applicable": {
		"text": "Uveďte důvod, proč lze záruku uplatnit",
		"isAlreadyTranslated": true
	},
	"Purchase cost can not be zero": {
		"text": "Nákupní cena nesmí být nulová",
		"isAlreadyTranslated": true
	},
	"Please add tag": {
		"text": "Přidejte značku",
		"isAlreadyTranslated": true
	},
	"File size should not be greater than 10 MB": {
		"text": "Velikost souboru nesmí překračovat 10 MB",
		"isAlreadyTranslated": true
	},
	"Please enter values in all the fields": {
		"text": "Zadejte hodnoty do všech polí",
		"isAlreadyTranslated": true
	},
	"Full box unit cannot be added if another part is already added": {
		"text": "Nelze přidat kompletní balení, pokud je již přidán jiný díl",
		"isAlreadyTranslated": true
	},
	"Full box unit is already added.": {
		"text": "Již je přidáno kompletní balení.",
		"isAlreadyTranslated": true
	},
	"Part price is not updated for the Selected part": {
		"text": "Cena dílu pro vybraný díl nebyla aktualizována",
		"isAlreadyTranslated": true
	},
	"Please contact Admin": {
		"text": "Obraťte se na správce",
		"isAlreadyTranslated": true
	},
	"Repair Completion Type": {
		"text": "Typ dokončení opravy",
		"isAlreadyTranslated": true
	},
	"No Parts Requested": {
		"text": "Nejsou požadovány žádné díly",
		"isAlreadyTranslated": true
	},
	"NA": {
		"text": "neuvedeno",
		"isAlreadyTranslated": true
	},
	"Out Of Warranty (No Coverage)": {
		"text": "Bez záruky (mimo rozsah platnosti)",
		"isAlreadyTranslated": true
	},
	"Number": {
		"text": "Číslo",
		"isAlreadyTranslated": true
	},
	"Workshop Engineer": {
		"text": "Dílenský technik",
		"isAlreadyTranslated": true
	},
	"Front Image": {
		"text": "Obrázek zepředu",
		"isAlreadyTranslated": true
	},
	"Top Image": {
		"text": "Obrázek shora",
		"isAlreadyTranslated": true
	},
	"Bottom Image": {
		"text": "Obrázek zdola",
		"isAlreadyTranslated": true
	},
	"Left Image": {
		"text": "Obrázek zleva",
		"isAlreadyTranslated": true
	},
	"Right Image": {
		"text": "Obrázek zprava",
		"isAlreadyTranslated": true
	},
	"Device Invoice": {
		"text": "Faktura k zařízení",
		"isAlreadyTranslated": true
	},
	"Other": {
		"text": "Jiné",
		"isAlreadyTranslated": true
	},
	"Cannot add more than 4 outcomes": {
		"text": "Nelze přidat více než 4 výsledky",
		"isAlreadyTranslated": true
	},
	"Imei Number": {
		"text": "Číslo IMEI",
		"isAlreadyTranslated": true
	},
	"By": {
		"text": "Od",
		"isAlreadyTranslated": true
	},
	"Whitelist Document": {
		"text": "Dokument whitelistu",
		"isAlreadyTranslated": true
	},
	"Hardware Closure": {
		"text": "Uzavření s hardwarem",
		"isAlreadyTranslated": true
	},
	"Map G-Number": {
		"text": "Namapovat G-číslo",
		"isAlreadyTranslated": true
	},
	"Please enter G Number.": {
		"text": "Zadejte G-číslo.",
		"isAlreadyTranslated": true
	},
	"Please enter Device Recieved Date and Time .": {
		"text": "Zadejte datum a čas přijetí zařízení.",
		"isAlreadyTranslated": true
	},
	"Your G Number has been mapped": {
		"text": "Vaše G-číslo bylo namapováno",
		"isAlreadyTranslated": true
	},
	"Update KGB": {
		"text": "Aktualizovat KGB",
		"isAlreadyTranslated": true
	},
	"KGB Number": {
		"text": "Číslo KGB",
		"isAlreadyTranslated": true
	},
	"Please enter valid KGB Number": {
		"text": "Zadejte platné číslo KGB",
		"isAlreadyTranslated": true
	},
	"Servicelocation Engineer": {
		"text": "Technik servisního místa",
		"isAlreadyTranslated": true
	},
	"StoreManager": {
		"text": "Vedoucí prodejny",
		"isAlreadyTranslated": true
	},
	"Return without repair (RWR)": {
		"text": "Vrátit bez opravy (RWR)",
		"isAlreadyTranslated": true
	},
	"Log Call": {
		"text": "Zaznamenat hovor",
		"isAlreadyTranslated": true
	},
	"Manufacturer's Warranty Status": {
		"text": "Stav záruky výrobce",
		"isAlreadyTranslated": true
	},
	"Device Value": {
		"text": "Hodnota zařízení",
		"isAlreadyTranslated": true
	},
	"Is Manufacturer's Warranty Applicable": {
		"text": "Platí záruka výrobce",
		"isAlreadyTranslated": true
	},
	"Connection Disposition": {
		"text": "Konfigurace spojení",
		"isAlreadyTranslated": true
	},
	"Non-Connect Disposition": {
		"text": "Konfigurace bez spojení",
		"isAlreadyTranslated": true
	},
	"Mandatory fields Required.": {
		"text": "Je nutno vyplnit povinná pole.",
		"isAlreadyTranslated": true
	},
	"Please enter tracking Number.": {
		"text": "Zadejte sledovací číslo.",
		"isAlreadyTranslated": true
	},
	"Please enter Shipper Detail.": {
		"text": "Zadejte detail přepravce.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Height.": {
		"text": "Zadejte výšku krabice.",
		"isAlreadyTranslated": true
	},
	"Please enter Box width.": {
		"text": "Zadejte šířku krabice.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Length.": {
		"text": "Zadejte délku krabice.",
		"isAlreadyTranslated": true
	},
	"Please enter Box Weight.": {
		"text": "Zadejte hmotnost krabice.",
		"isAlreadyTranslated": true
	},
	"Please enter Over Pack Box Number.": {
		"text": "Zadejte číslo nadměrné zásilky.",
		"isAlreadyTranslated": true
	},
	"Carrier": {
		"text": "Přepravce",
		"isAlreadyTranslated": true
	},
	"Depot Shipper Label": {
		"text": "Štítek přepravce zásilky",
		"isAlreadyTranslated": true
	},
	"Packing List": {
		"text": "Dodací list",
		"isAlreadyTranslated": true
	},
	"Delivery Challan": {
		"text": "Dodávkový list",
		"isAlreadyTranslated": true
	},
	"Over Pack Box Number": {
		"text": "Číslo nadměrné zásilky",
		"isAlreadyTranslated": true
	},
	"Add Carrier Details": {
		"text": "Přidat detaily přepravce",
		"isAlreadyTranslated": true
	},
	"GSX Confirmation No": {
		"text": "Č. potvrzení GSX",
		"isAlreadyTranslated": true
	},
	"Repair Creation Date": {
		"text": "Datum vytvoření opravy",
		"isAlreadyTranslated": true
	},
	"Please enter valid": {
		"text": "Zadejte platnou hodnotu",
		"isAlreadyTranslated": true
	},
	"Please select Payment Mode.": {
		"text": "Vyberte platební metodu.",
		"isAlreadyTranslated": true
	},
	"Inspection Guide": {
		"text": "Návod na inspekci",
		"isAlreadyTranslated": true
	},
	"Guide": {
		"text": "Návod",
		"isAlreadyTranslated": true
	},
	"Please make sure FMIP is switched off.": {
		"text": "Zkontrolujte, zda je vypnutá funkce FMIP.",
		"isAlreadyTranslated": true
	},
	"If you wish to skip FMIP click on YES else ask customer to Turn off FMIP and try again.": {
		"text": "Chcete-li funkci FMIP přeskočit, klikněte na tlačítko ANO; v opačném případě požádejte zákazníka, aby vypnul možnost FMIP, a zkuste to znovu.",
		"isAlreadyTranslated": true
	},
	"Instructions": {
		"text": "Pokyny",
		"isAlreadyTranslated": true
	},
	"Start at your Home screen.": {
		"text": "Začněte na domovské obrazovce.",
		"isAlreadyTranslated": true
	},
	"Tap Settings > iCloud.": {
		"text": "Klepněte na nabídku Nastavení > iCloud.",
		"isAlreadyTranslated": true
	},
	"Scroll to the bottom and tap Find My iPhone.": {
		"text": "Sjeďte dolů a klepněte na možnost Najít můj iPhone.",
		"isAlreadyTranslated": true
	},
	"Slide to turn off Find My iPhone and Send Last Location.": {
		"text": "Pomocí přepínače vypněte funkce Najít můj iPhone a Odeslat poslední polohu.",
		"isAlreadyTranslated": true
	},
	"Retry": {
		"text": "Zkusit znovu",
		"isAlreadyTranslated": true
	},
	"Please update Warranty applicable status in Device Details section.": {
		"text": "Aktualizujte stav uplatnění záruky v sekci Detaily zařízení.",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, Please update Warranty applicable status in Device Details section to enable Non Hardware Closure.": {
		"text": "Pracovník péče o zákazníky může spustit diagnostiku, provést inspekci zařízení a zaregistrovat hardwarovou opravu; aktualizujte stav uplatnění záruky v sekci Detaily zařízení, aby bylo možné provést uzavření bez hardwaru.",
		"isAlreadyTranslated": true
	},
	"Please select file name, or choose 'Others' to enter a custom file name.": {
		"text": "Vyberte název souboru nebo vyberte možnost „Jiný“ a zadejte vlastní název souboru.",
		"isAlreadyTranslated": true
	},
	"Assigned Engineers": {
		"text": "Přiřazení technici",
		"isAlreadyTranslated": true
	},
	"Select File Name": {
		"text": "Vybrat název souboru",
		"isAlreadyTranslated": true
	},
	"Review By Apple Requested": {
		"text": "Nutná kontrola společností Apple",
		"isAlreadyTranslated": true
	},
	"CS Code Entered": {
		"text": "Zadaný kód CS",
		"isAlreadyTranslated": true
	},
	"Repair Classification Type": {
		"text": "Typ klasifikace opravy",
		"isAlreadyTranslated": true
	},
	"Guided Troubleshooting Details": {
		"text": "Detaily řešení problémů podle návodu",
		"isAlreadyTranslated": true
	},
	"Please enter Required Fields (*).": {
		"text": "Vyplňte povinná pole (*).",
		"isAlreadyTranslated": true
	},
	"No Guided Troubleshooting Details": {
		"text": "Neexistují žádné detaily řešení problémů podle návodu",
		"isAlreadyTranslated": true
	},
	"Repair Notes (for Delivery Report)": {
		"text": "Poznámky k opravě (pro dodávkový list)",
		"isAlreadyTranslated": true
	},
	"Repair Notes": {
		"text": "Poznámky k opravě",
		"isAlreadyTranslated": true
	},
	"Please enter the Repair Notes": {
		"text": "Zadejte poznámky k opravě",
		"isAlreadyTranslated": true
	},
	"Same as Engineer Diagnosis Notes": {
		"text": "Totéž jako poznámky technika k opravě",
		"isAlreadyTranslated": true
	},
	"In case you want to select different outcomes for both parts, please select outcome for one part and Save the same. Post that please update the outcome for the remaining parts. For additional help, contact your Servify administrator.": {
		"text": "Chcete-li vybrat pro jednotlivé díly jiné výstupy, vyberte výstupu pro jeden díl a uložte jej. Poté upravte výstup pro ostatní díly. Další informace vám sdělí správce společnosti Servify.",
		"isAlreadyTranslated": true
	},
	"Enter KBB Details": {
		"text": "Zadat detaily KBB",
		"isAlreadyTranslated": true
	},
	"KBB Number": {
		"text": "Číslo KBB",
		"isAlreadyTranslated": true
	},
	"Reorder Details": {
		"text": "Detaily doobjednání",
		"isAlreadyTranslated": true
	},
	"Reorder Part Number": {
		"text": "Číslo dílu k doobjednání",
		"isAlreadyTranslated": true
	},
	"Reorder Part as": {
		"text": "Doobjednat díl jako",
		"isAlreadyTranslated": true
	},
	"Purchase Order": {
		"text": "Nákupní objednávka",
		"isAlreadyTranslated": true
	},
	"Consignment Order": {
		"text": "Objednávka zásilky",
		"isAlreadyTranslated": true
	},
	"GSX Confirmation Number": {
		"text": "Číslo potvrzení GSX",
		"isAlreadyTranslated": true
	},
	"Repair created successfully on GSX": {
		"text": "Oprava byla úspěšně vytvořena v GSX",
		"isAlreadyTranslated": true
	},
	"Repair Status At GSX": {
		"text": "Stav opravy v GSX",
		"isAlreadyTranslated": true
	},
	"Use from Consigned Inventory": {
		"text": "Použít z deponovaných zásob",
		"isAlreadyTranslated": true
	},
	"KGB Serial Number": {
		"text": "Sériové číslo KGB",
		"isAlreadyTranslated": true
	},
	"KGB Serial Number (Consignment Part Serial No)": {
		"text": "Sériové číslo KGB (sériové č. části zásilky)",
		"isAlreadyTranslated": true
	},
	"Fetch GSX Status": {
		"text": "Načíst stav GSX",
		"isAlreadyTranslated": true
	},
	"Use Consignment Part": {
		"text": "Použít část zásilky",
		"isAlreadyTranslated": true
	},
	"Enter DOA Parts Details": {
		"text": "Zadat detaily dílů poškozených při doručení",
		"isAlreadyTranslated": true
	},
	"Raise GSX Request": {
		"text": "Podat požadavek GSX",
		"isAlreadyTranslated": true
	},
	"We are unable to establish complete device details from GSX. Diagnosis may not have been performed on this device. Please proceed with request creation by selecting product details.": {
		"text": "Nemůžeme načíst z GSX úplné informace o zařízení. Na tomto zařízení možná nebyla provedena diagnostika. Pokračujte ve vytváření požadavku tím, že vyberete detaily produktu.",
		"isAlreadyTranslated": true
	},
	"We are unable to establish complete device details from GSX. Diagnosis may not have been performed on this device. Please diagnose the device to proceed. Note: Excessive tries without device diagnosis can block your account access.": {
		"text": "Nemůžeme načíst z GSX úplné informace o zařízení. Na tomto zařízení možná nebyla provedena diagnostika. Proveďte diagnostiku, abyste mohli pokračovat. Poznámka: V případě nadměrného počtu pokusů bez diagnostiky zařízení může dojít k zablokování přístupu k vašemu účtu.",
		"isAlreadyTranslated": true
	},
	"Notes": {
		"text": "Poznámky",
		"isAlreadyTranslated": true
	},
	"Service Estimate": {
		"text": "Odhad ceny servisu",
		"isAlreadyTranslated": true
	},
	"Please make sure “Find My” feature is switched off before accepting device for repair.": {
		"text": "Před převzetím zařízení do opravy zkontrolujte, zda je vypnutá možnost „Najít moje“.",
		"isAlreadyTranslated": true
	},
	"Disable Find My Functionality": {
		"text": "Vypnout funkci Najít moje",
		"isAlreadyTranslated": true
	},
	"Engineer reassigned": {
		"text": "Technik byl znovu přiřazen",
		"isAlreadyTranslated": true
	},
	"Order from GSX": {
		"text": "Objednávka z GSX",
		"isAlreadyTranslated": true
	},
	"Please select value for ‘Use from Consigned Inventory’ field": {
		"text": "Vyberte hodnotu v poli „Použít z deponovaných zásob“",
		"isAlreadyTranslated": true
	},
	"Return Details": {
		"text": "Detaily vrácení",
		"isAlreadyTranslated": true
	},
	"Return Part as": {
		"text": "Vrátit díl jako",
		"isAlreadyTranslated": true
	},
	"Stock DOA": {
		"text": "Zásoby poškozené při doručení",
		"isAlreadyTranslated": true
	},
	"Please Enter Component Code": {
		"text": "Zadejte kód součásti",
		"isAlreadyTranslated": true
	},
	"Please Enter Issue Code": {
		"text": "Zadejte kód problému",
		"isAlreadyTranslated": true
	},
	"Please enter comptia Modifier": {
		"text": "Zadejte modifikátor CompTIA",
		"isAlreadyTranslated": true
	},
	"Your inventory does not contain stock for the part number selected. Please add consignment stock to your inventory to proceed.": {
		"text": "Vaše zásoby neobsahují položky pro vybrané číslo dílu. Přidejte deponované zásoby do zásob a pokračujte dále.",
		"isAlreadyTranslated": true
	},
	"Please select Part Return Status Code": {
		"text": "Vyberte kód stavu vrácení dílu",
		"isAlreadyTranslated": true
	},
	"Box Width": {
		"text": "Šířka krabice",
		"isAlreadyTranslated": true
	},
	"Box Height": {
		"text": "Výška krabice",
		"isAlreadyTranslated": true
	},
	"Box Weight": {
		"text": "Hmotnost krabice",
		"isAlreadyTranslated": true
	},
	"Box Length": {
		"text": "Délka krabice",
		"isAlreadyTranslated": true
	},
	'Waiting for Engineer to Validate Issues and add Symptom, Fault, Action details in "Issues Reported" section below.': {
		"text": "Čeká se, až technik provede kroky „Ověřit problémy“ a zadá detaily příznaku, závady nebo akce v sekci „Nahlášené problémy“ níže.",
		"isAlreadyTranslated": true
	},
	"Primary": {
		"text": "Primární",
		"isAlreadyTranslated": true
	},
	"Reproducibility": {
		"text": "Reprodukovatelnost",
		"isAlreadyTranslated": true
	},
	"Engineer Reported Component / Issue Code(s)": {
		"text": "Kódy součástí / problémy nahlášené technikem",
		"isAlreadyTranslated": true
	},
	"Customer Reported Component / Issue Code(s) [Optional]": {
		"text": "Kódy součástí / problémy nahlášené zákazníkem [nepovinné]",
		"isAlreadyTranslated": true
	},
	"Customer Reported Issues": {
		"text": "Problémy nahlášené zákazníkem",
		"isAlreadyTranslated": true
	},
	"Please select a Reproducibility from dropdown.": {
		"text": "Vyberte z rozevíracího seznamu reprodukovatelnost.",
		"isAlreadyTranslated": true
	},
	"Please enter Reproducibility": {
		"text": "Zadejte reprodukovatelnost",
		"isAlreadyTranslated": true
	},
	"Waiting for Engineer to Validate Issues and add component code, issue code, action details in 'Issues Reported' section below.": {
		"text": "Čeká se, až technik provede kroky „Ověřit problémy“ a zadá kód součásti, kód problému a detaily akce v sekci „Nahlášené problémy“ níže.",
		"isAlreadyTranslated": true
	},
	"Please enter a valid name": {
		"text": "Zadejte platný název",
		"isAlreadyTranslated": true
	},
	"File Type": {
		"text": "Typ souboru",
		"isAlreadyTranslated": true
	},
	"Proof of Purchase": {
		"text": "Doklad o nákupu",
		"isAlreadyTranslated": true
	},
	"GSX Upload Status": {
		"text": "Stav nahrávání GSX",
		"isAlreadyTranslated": true
	},
	"Send to GSX": {
		"text": "Odeslat do GSX",
		"isAlreadyTranslated": true
	},
	"Please update the device date of purchase in device details section to proceed further.": {
		"text": "Chcete-li pokračovat, aktualizujte v detailech zařízení datum nákupu zařízení.",
		"isAlreadyTranslated": true
	},
	"GSX Serial No (for Non Serialised Product)": {
		"text": "Sériové č. GSX (pro neserializovaný produkt)",
		"isAlreadyTranslated": true
	},
	"The file format uploaded in your zip file is not supported. Please remove any folders, correct the file formats and try again.": {
		"text": "Formát souboru nahraného ve vašem souboru ZIP není podporován. Odeberte všechny složky, opravte formát souboru a zkuste to znovu.",
		"isAlreadyTranslated": true
	},
	"Uploaded file format not supported. Pls upload file in one of the following formats - aac, csv, doc, docx, jpeg, jpg, m4a, mp4, ogg, ogx, pdf, png, txt, xls, xlsx, zip.": {
		"text": "Formát nahraného souboru není podporován. Nahrajte soubor v některém z následujících formátů: aac, csv, doc, docx, jpeg, jpg, m4a, mp4, ogg, ogx, pdf, png, txt, xls, xlsx, zip.",
		"isAlreadyTranslated": true
	},
	"Zip file uploaded has no content. Check and re-upload file.": {
		"text": "Nahraný soubor ZIP nemá žádný obsah. Zkontrolujte soubor a nahrajte jej znovu.",
		"isAlreadyTranslated": true
	},
	"Uploaded": {
		"text": "Nahráno",
		"isAlreadyTranslated": true
	},
	"Attached to Repair": {
		"text": "Přiloženo k opravě",
		"isAlreadyTranslated": true
	},
	"Failed Re-Upload": {
		"text": "Opětovné nahrání se nezdařilo",
		"isAlreadyTranslated": true
	},
	"Device is not eligible for integrated diagnosis, please click on complete diagnosis/cannot diagnose to capture the notes and proceed.": {
		"text": "Zařízení neumožňuje provést integrovanou diagnostiku; kliknutím na možnost Dokončit diagnostiku / Nelze provést diagnostiku uložte poznámky a pokračujte dále.",
		"isAlreadyTranslated": true
	},
	"Warning": {
		"text": "Varování",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Inspect Device to log a hardware repair, Please update Warranty applicable status in Device Details section to enable Non Hardware Closure.": {
		"text": "Pracovník péče o zákazníky může provést inspekci zařízení a zaregistrovat hardwarovou opravu; aktualizujte stav uplatnění záruky v sekci Detaily zařízení, aby bylo možné provést uzavření bez hardwaru.",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive can Inspect Device to log a hardware repair, or click on Non Hardware Closure to complete the repair as a non-part service repair.": {
		"text": "Pracovník péče o zákazníky může provést inspekci zařízení a zaregistrovat hardwarovou opravu nebo v případě opravy bez použití dílů kliknout na možnost Uzavření bez hardwaru a dokončit tak opravu.",
		"isAlreadyTranslated": true
	},
	"Please update \"Is Manufacturer's Warranty Applicable\" and save device details.": {
		"text": "Aktualizujte možnost „Platí záruka výrobce“ a uložte detaily zařízení.",
		"isAlreadyTranslated": true
	},
	"Request Type": {
		"text": "Typ požadavku",
		"isAlreadyTranslated": true
	},
	"Fetch Diagnostics Suites": {
		"text": "Načíst diagnostické sady",
		"isAlreadyTranslated": true
	},
	"Enter Serial Number / IMEI": {
		"text": "Zadejte sériové číslo nebo/IMEI",
		"isAlreadyTranslated": true
	},
	"Sales Channel": {
		"text": "Prodejní kanál",
		"isAlreadyTranslated": true
	},
	"Street Address": {
		"text": "Poštovní adresa",
		"isAlreadyTranslated": true
	},
	"Postal Code": {
		"text": "PSČ",
		"isAlreadyTranslated": true
	},
	"Province": {
		"text": "Provincie",
		"isAlreadyTranslated": true
	},
	"Locality": {
		"text": "Lokalita",
		"isAlreadyTranslated": true
	},
	"Enter a location": {
		"text": "Zadejte místo",
		"isAlreadyTranslated": true
	},
	"Landmark is required": {
		"text": "Je nutno zadat orientační bod",
		"isAlreadyTranslated": true
	},
	"Street Address is required": {
		"text": "Je nutno zadat poštovní adresu",
		"isAlreadyTranslated": true
	},
	"Postal Code is required": {
		"text": "Je nutno zadat PSČ",
		"isAlreadyTranslated": true
	},
	"Province is required": {
		"text": "Je nutno zadat provincii",
		"isAlreadyTranslated": true
	},
	"Postal Code is invalid": {
		"text": "Neplatné PSČ",
		"isAlreadyTranslated": true
	},
	"Province is incorrect": {
		"text": "Nesprávná provincie",
		"isAlreadyTranslated": true
	},
	"Some of the details might be missing in the address, please update the address.": {
		"text": "V adrese možná chybí některé údaje, adresu je třeba upravit.",
		"isAlreadyTranslated": true
	},
	"email id": {
		"text": "e-mailová adresa",
		"isAlreadyTranslated": true
	},
	"VAT Number": {
		"text": "DIČ",
		"isAlreadyTranslated": true
	},
	"VAT Number is required": {
		"text": "Je nutno zadat DIČ",
		"isAlreadyTranslated": true
	},
	"Should Be 15 Characters": {
		"text": "Musí mít alespoň 15 znaků",
		"isAlreadyTranslated": true
	},
	"Should Be 10 Characters": {
		"text": "Musí mít alespoň 10 znaků",
		"isAlreadyTranslated": true
	},
	"Name is required": {
		"text": "Je nutno zadat jméno",
		"isAlreadyTranslated": true
	},
	"There's a problem with this location, please select a different location": {
		"text": "Došlo k problému s tímto místem; vyberte jiné místo",
		"isAlreadyTranslated": true
	},
	"Unable to Diagnose": {
		"text": "Nelze provést diagnostiku",
		"isAlreadyTranslated": true
	},
	"Contact Information": {
		"text": "Kontaktní údaje",
		"isAlreadyTranslated": true
	},
	"Disclaimer": {
		"text": "Prohlášení o omezení odpovědnosti",
		"isAlreadyTranslated": true
	},
	"Send OTP": {
		"text": "Odeslat jednorázové heslo",
		"isAlreadyTranslated": true
	},
	"OTP Validation": {
		"text": "Ověření jednorázovým heslem",
		"isAlreadyTranslated": true
	},
	"Back": {
		"text": "Zpět",
		"isAlreadyTranslated": true
	},
	"Enter the OTP sent to": {
		"text": "Zadejte jednorázové heslo zaslané na číslo",
		"isAlreadyTranslated": true
	},
	"Confirm OTP": {
		"text": "Potvrdit jednorázové heslo",
		"isAlreadyTranslated": true
	},
	"Estimation rejected": {
		"text": "Odhad byl zamítnut",
		"isAlreadyTranslated": true
	},
	"Mail-In Dispatched Successfully": {
		"text": "Zásilka byla úspěšně odeslána",
		"isAlreadyTranslated": true
	},
	"Battery back up issue": {
		"text": "Problém se záložní baterií",
		"isAlreadyTranslated": true
	},
	"Pending for Dispatch": {
		"text": "Čeká na odeslání",
		"isAlreadyTranslated": true
	},
	"Get Bulk Return": {
		"text": "Načíst hromadné vrácení",
		"isAlreadyTranslated": true
	},
	"Please enter valid KGB IMEI Number": {
		"text": "Zadejte platné číslo KGB IMEI",
		"isAlreadyTranslated": true
	},
	"KGB IMEI Number": {
		"text": "Číslo KGB IMEI",
		"isAlreadyTranslated": true
	},
	"Update": {
		"text": "Aktualizovat",
		"isAlreadyTranslated": true
	},
	"OverPack No": {
		"text": "Č. nadměrné zásilky",
		"isAlreadyTranslated": true
	},
	"Please enter Overpack No. for": {
		"text": "Zadejte č. nadměrné zásilky pro",
		"isAlreadyTranslated": true
	},
	"Please enter Overpack No. for the selected Reference ID": {
		"text": "Zadejte č. nadměrné zásilky pro vybrané referenční ID",
		"isAlreadyTranslated": true
	},
	"Please enter Overpack No. between 1 to 999": {
		"text": "Zadejte č. nadměrné zásilky v rozmezí 1 až 999",
		"isAlreadyTranslated": true
	},
	"Resolution Initiated": {
		"text": "Řešení bylo zahájeno",
		"isAlreadyTranslated": true
	},
	"Resolution in Progress": {
		"text": "Řešení probíhá",
		"isAlreadyTranslated": true
	},
	"Claim Request closed": {
		"text": "Reklamace byla uzavřena",
		"isAlreadyTranslated": true
	},
	"Feedback form (after service request completion)": {
		"text": "Formulář zpětné vazby (po dokončení servisního požadavku)",
		"isAlreadyTranslated": true
	},
	"Below Address will be used by agent based on details added by you, please ensure it is correct before you proceed.": {
		"text": "Přepravce použije níže uvedenou adresu, kterou jste zadali; proto ji zkontrolujte, než budete pokračovat.",
		"isAlreadyTranslated": true
	},
	"Police Report Date": {
		"text": "Datum policejního protokolu",
		"isAlreadyTranslated": true
	},
	"Police Report Number": {
		"text": "Číslo policejního protokolu",
		"isAlreadyTranslated": true
	},
	"Date of Theft": {
		"text": "Datum odcizení",
		"isAlreadyTranslated": true
	},
	"Place of Theft": {
		"text": "Místo odcizení",
		"isAlreadyTranslated": true
	},
	"Upload Police Report": {
		"text": "Nahrát policejní protokol",
		"isAlreadyTranslated": true
	},
	"Incident Details": {
		"text": "Podrobnosti o incidentu",
		"isAlreadyTranslated": true
	},
	"Claim Request Closed": {
		"text": "Reklamace byla uzavřena",
		"isAlreadyTranslated": true
	},
	"Communication Name": {
		"text": "Název komunikace",
		"isAlreadyTranslated": true
	},
	"Communication Number": {
		"text": "Komunikační číslo",
		"isAlreadyTranslated": true
	},
	"Communication EmailID": {
		"text": "Komunikační EmailID",
		"isAlreadyTranslated": true
	},
	"DropOff request accepted": {
		"text": "Žádost o odevzdání byla přijata",
		"isAlreadyTranslated": true
	},
	"Shipment Charge": {
		"text": "Poplatek za přepravu",
		"isAlreadyTranslated": true
	},
	"Delivery Address": {
		"text": "Doručovací adresa",
		"isAlreadyTranslated": true
	},
	"Additional Remarks": {
		"text": "Další poznámky",
		"isAlreadyTranslated": true
	},
	"Update Logistics Request": {
		"text": "Aktualizovat požadavek logistiky",
		"isAlreadyTranslated": true
	},
	"Vendor tracking URL": {
		"text": "Měřicí adresa URL dodavatele",
		"isAlreadyTranslated": true
	},
	"Click here for Vendor Tracking URL": {
		"text": "Kliknutím sem zobrazíte adresu URL pro sledování dodavatele",
		"isAlreadyTranslated": true
	},
	"Contact number": {
		"text": "Kontaktní číslo",
		"isAlreadyTranslated": true
	},
	"Out of Warranty": {
		"text": "bez záruky",
		"isAlreadyTranslated": true
	},
	"Plan Validity": {
		"text": "Platnost plánu",
		"isAlreadyTranslated": true
	},
	"Final Plan Price": {
		"text": "Konečná cena plánu",
		"isAlreadyTranslated": true
	},
	"Product Value": {
		"text": "Hodnota produktu",
		"isAlreadyTranslated": true
	},
	"Payment": {
		"text": "Způsob platby",
		"isAlreadyTranslated": true
	},
	"Damage Protection": {
		"text": "Ochrana před poškozením",
		"isAlreadyTranslated": true
	},
	"View Plans": {
		"text": "Zobrazit plány",
		"isAlreadyTranslated": true
	},
	"TOTAL COMMISSION": {
		"text": "CELKOVÁ PROVIZE",
		"isAlreadyTranslated": true
	},
	"Congratulations! Your device is now registered and the plan is now activated for your device.": {
		"text": "Blahopřejeme! Vaše zařízení je nyní zaregistrováno a plán je nyní pro vaše zařízení aktivován.",
		"isAlreadyTranslated": true
	},
	"Agreement Number": {
		"text": "Číslo smlouvy",
		"isAlreadyTranslated": true
	},
	"Your Plan is now pending for Activation.": {
		"text": "Váš plán nyní čeká na aktivaci",
		"isAlreadyTranslated": true
	},
	"Customer Email ID": {
		"text": "ID e-mailu zákazníka",
		"isAlreadyTranslated": true
	},
	"Customer Mobile No": {
		"text": "Mobilní číslo zákazníka",
		"isAlreadyTranslated": true
	},
	"Warranty End Date": {
		"text": "Datum ukončení záruky",
		"isAlreadyTranslated": true
	},
	"Product Value cannot be less than 500": {
		"text": "Hodnota produktu nesmí být nižší než 500",
		"isAlreadyTranslated": true
	},
	"Sell now": {
		"text": "Prodat",
		"isAlreadyTranslated": true
	},
	"Transferred": {
		"text": "Přestoupil",
		"isAlreadyTranslated": true
	},
	"Customer Date of Birth": {
		"text": "Datum narození zákazníka",
		"isAlreadyTranslated": true
	},
	"Payment Received": {
		"text": "Platba přijata",
		"isAlreadyTranslated": true
	},
	"Date of Birth": {
		"text": "Datum narození",
		"isAlreadyTranslated": true
	},
	"IPID": {
		"text": "Povinné informační dokumenty o pojistném produktu",
		"isAlreadyTranslated": true
	},
	"Successfully Purchased": {
		"text": "úspěšně zakoupeno",
		"isAlreadyTranslated": true
	},
	"The Plan is now pending for Activation": {
		"text": "Plán nyní čeká na aktivaci",
		"isAlreadyTranslated": true
	},
	" Plans": {
		"text": " Plány",
		"isAlreadyTranslated": true
	},
	"registered": {
		"text": "zaregistroval",
		"isAlreadyTranslated": true
	},
	"Congratulations!\nThe customer has successfully @@status@@ their device under @@plan@@. Please help the customer to activate the plan.": {
		"text": "Gratulujeme!/nZákazník úspěšně @@status@@ své zařízení @@plan@@. Pomozte prosím zákazníkovi aktivovat plán.",
		"isAlreadyTranslated": true
	},
	"Privacy Policy": {
		"text": "Zásady ochrany osobních údajů",
		"isAlreadyTranslated": true
	},
	"Invalid OTP": {
		"text": "Neplatný OTP",
		"isAlreadyTranslated": true
	},
	"Resend OTP": {
		"text": "Znovu odeslat OTP",
		"isAlreadyTranslated": true
	},
	"One Time Deductible": {
		"text": "Jednorázová spoluúčast",
		"isAlreadyTranslated": true
	},
	"Location Of Incident": {
		"text": "Místo incidentu",
		"isAlreadyTranslated": true
	},
	"Resend OTP in": {
		"text": "Znovu odeslat jednorázové heslo",
		"isAlreadyTranslated": true
	},
	"Declaration": {
		"text": "Prohlášení",
		"isAlreadyTranslated": true
	},
	"Please enter Product Value": {
		"text": "Zadejte prosím hodnotu produktu",
		"isAlreadyTranslated": true
	},
	"is required": {
		"text": "je vyžadováno",
		"isAlreadyTranslated": true
	},
    "other related documents": {
        "text": "a další související dokumenty",
        "isAlreadyTranslated": true
    },
	"Enter Verification Code": {
		"text": "Vložte ověřovací kód",
		"isAlreadyTranslated": true
	},
	"Code sent to customer on": {
		"text": "Kód odeslaný zákazníkovi na",
		"isAlreadyTranslated": true
	},
	"and": {
		"text": "a",
		"isAlreadyTranslated": true
	},
	"Resend SMS": {
		"text": "Znovu odeslat SMS",
		"isAlreadyTranslated": true
	},
	"Resend Email": {
		"text": "Znovu odeslat Email",
		"isAlreadyTranslated": true
	},
	"Call me with the Code": {
		"text": "Zavolejte mi s kódem",
		"isAlreadyTranslated": true
	},
	"Confirm Code": {
		"text": "Potvrďte kód",
		"isAlreadyTranslated": true
	},
	"Didn't receive the code? Resend Code in": {
		"text": "Neobdrželi jste kód? Odeslat znovu",
		"isAlreadyTranslated": true
	},
	"Select Brand": {
		"text": "Vybrat značku",
		"isAlreadyTranslated": true
	},
	"or": {
		"text": "nebo",
		"isAlreadyTranslated": true
	},
	"Select payment plan": {
		"text": "Zvolte platební plán",
		"isAlreadyTranslated": true
	},
	"Subscription plan, cancel anytime": {
		"text": "Plán předplatného, zrušení kdykoli",
		"isAlreadyTranslated": true
	},
	"One time payment": {
		"text": "Jednorázová platba",
		"isAlreadyTranslated": true
	},
	"Enter": {
		"text": "Zadejte",
		"isAlreadyTranslated": true
	},
	"Eligible Plan(s)": {
		"text": "Způsobilé plány",
		"isAlreadyTranslated": true
	},
	"Payment Options": {
		"text": "Možnosti platby",
		"isAlreadyTranslated": true
	},
	"Pay Upfront": {
		"text": "Platba předem",
		"isAlreadyTranslated": true
	},
	"Subscription Payment": {
		"text": "Platba předplatného",
		"isAlreadyTranslated": true
	},
	"The plan purchase is successful. The customer will receive a confirmation email on the registered email ID.": {
		"text": "Nákup plánu je úspěšný. Zákazník obdrží potvrzovací e-mail na zaregistrované e-mailové ID.",
		"isAlreadyTranslated": true
	},
	"You will be redirected in": {
		"text": "Budete přesměrováni do",
		"isAlreadyTranslated": true
	},
	"seconds": {
		"text": "sekund",
		"isAlreadyTranslated": true
	},
	"If you are not redirected": {
		"text": "Pokud nejste přesměrováni",
		"isAlreadyTranslated": true
	},
	"Click Here": {
		"text": "Klikněte sem",
		"isAlreadyTranslated": true
	},
	"Your transaction has encountered an error and could not be processed at this time. Please try again later or contact our support team for help.": {
		"text": "Vaše transakce narazila na chybu a nemohla být v tuto chvíli zpracována. Zkuste to prosím později nebo se obraťte na náš tým podpory.",
		"isAlreadyTranslated": true
	},
	"Payment Failed": {
		"text": "Platba se nezdařila",
		"isAlreadyTranslated": true
	},

	"Plan Sales": {
        "text": "Plán prodeje",
        "isAlreadyTranslated": true
    },

    "Store Location": {
		"text": "Umístění obchodu",
        "isAlreadyTranslated": true
    },
    "Total Sales Value": {
        "text": "Celková hodnota prodejů",
        "isAlreadyTranslated": true
    },
    "Upfront Payment": {
        "text": "Platba předem",
        "isAlreadyTranslated": true
    },
    "This includes transaction values associated with plan sales and recurring payments post activation": {
		"text": "Zahrnuje hodnoty transakcí související s prodejem plánu a opakujícími se platbami po aktivaci",
        "isAlreadyTranslated": true
    },
	"Total Earnings": {
		"text": "Celkový výdělek",
        "isAlreadyTranslated": true
    },
	"This includes retailer margin values associated with plan sales and recurring payments post activation": {
        "text": "Zahrnuje hodnoty marží maloobchodníků spojené s prodejem plánů a opakujícími se platbami po aktivaci",
        "isAlreadyTranslated": true
    },
	"Number of Sales": {
		"text": "Počet prodejů",
        "isAlreadyTranslated": true
    },
	"This includes all plan sales transactions except those contracts where customer has not completed the payment via link": {
        "text": "Zahrnuje všechny prodejní transakce plánů kromě těch, kde zákazník nedokončil platbu prostřednictvím odkazu",
        "isAlreadyTranslated": true
    },
	"Total Recurring Payments": {
		"text": "Celkové opakující se platby",
        "isAlreadyTranslated": true
    },

	"This includes all successful recurring transactions associated with subscription plans": {
		"text": "Zahrnuje všechny úspěšné opakující se transakce spojené s plány předplatného",
        "isAlreadyTranslated": true
    },
	"Payment Link Transactions": {
		"text": "Transakce platební odkaz",
        "isAlreadyTranslated": true
    },
	"/ Payment Link Transactions": {
		"text": "Transakce platební odkaz",
        "isAlreadyTranslated": true
    },
    "Completed": {
        "text": "Dokončené",
        "isAlreadyTranslated": true
    },
	"Pending": {
		"text": "Čekající",
        "isAlreadyTranslated": true
    },
	"Failure": {
		"text": "Neúspěšné",
        "isAlreadyTranslated": true
    },

	"This includes all transactions where customer is using a link to complete the payment": {
        "text": "To zahrnuje všechny transakce, kde zákazník používá odkaz k dokončení platby",
        "isAlreadyTranslated": true
    },
	"Plan Sales Summary": {
        "text": "Přehled prodeje plánu",
        "isAlreadyTranslated": true
    },
	"Showing Results For": {
		"text": "Zobrazují se výsledky pro",
        "isAlreadyTranslated": true
    },
	"Today": {
        "text": "Dnes",
        "isAlreadyTranslated": true
    },
	" Last 7 Days": {
		"text": "Posledních 7 dní",
        "isAlreadyTranslated": true
    },
	"Last 15 Days": {
		"text": "Posledních 15 dní",
        "isAlreadyTranslated": true
    },
	"Last 30 Days": {
		"text": "Posledních 30 dní",
        "isAlreadyTranslated": true
    },
	"Custom Date": {
		"text": "Vlastní datum",
        "isAlreadyTranslated": true
    },
	"View All Transactions": {
        "text": "Zobrazit všechny transakce",
        "isAlreadyTranslated": true
    },
	"View All": {
        "text": "Zobrazit všechny",
        "isAlreadyTranslated": true
    },
	"Sell Plan": {
        "text": "Prodat plán",
        "isAlreadyTranslated": true
    },


	"Renew Plan": {
		"text": "Obnovit plán",
        "isAlreadyTranslated": true
    },
	"Bulk Upload": {
		"text": "Hromadné nahrání",
        "isAlreadyTranslated": true
    },
	"You can only select a range within 30 days": {
		"text": "Můžete vybrat pouze rozsah do 30 dnů",
        "isAlreadyTranslated": true
    },
	"Select Store Location": {
        "text": "Vyberte umístění obchodu",
        "isAlreadyTranslated": true
    },
	"Location": {
		"text": "Umístění",
        "isAlreadyTranslated": true
    },
	"All Transactions": {
		"text": "Všechny transakce",
        "isAlreadyTranslated": true
    },
	"Sorry, No Upfront Payment Transactions Found": {
		"text": "Litujeme, nebyly nalezeny žádné platební transakce předem",
        "isAlreadyTranslated": true
    },
	"Payment Plan": {
		"text": "Plán plateb",
        "isAlreadyTranslated": true
    },
	"Sorry, No Subscription Payment Transactions Found": {
        "text": "Litujeme, nebyly nalezeny žádné platební transakce předplatného",
        "isAlreadyTranslated": true
    },
	"Payment Link Transactions": {
        "text": "Transakce s platebním odkazem",
        "isAlreadyTranslated": true
    },

	"Sorry, No Transactions Found": {
		"text": "Litujeme, nebyly nalezeny žádné transakce",
        "isAlreadyTranslated": true
    },
	"Documents": {
        "text": "Dokumenty",
        "isAlreadyTranslated": true
    },
	"Sorry, No Documents Found": {
		"text": "Litujeme, nebyly nalezeny žádné dokumenty",
        "isAlreadyTranslated": true
    },
	"Are you sure you want to cancel the plan?": {
		"text": "Opravdu chcete plán zrušit?",
        "isAlreadyTranslated": true
    },
	"Once cancelled, the customer won't be able to redeem any benefits of the plan": {
        "text": "Po zrušení nebude moci zákazník využít žádné výhody plánu",
        "isAlreadyTranslated": true
    },
	"Select Reason for Cancellation": {
		"text": "Vyberte důvod zrušení",
        "isAlreadyTranslated": true
    },
	"Select Reason": {
		"text": "Vyberte důvod",
        "isAlreadyTranslated": true
    },
	"Choose a Reason from the dropdown": {
		"text": "Z rozbalovací nabídky vyberte důvod",
        "isAlreadyTranslated": true
    },
	"The plan has been cancelled": {
		"text": "Plán byl zrušen",
        "isAlreadyTranslated": true
    },
	"Make Payment": {
        "text": "Zaplatit",
        "isAlreadyTranslated": true
    },

	"More Filters": {
		"text": "Více filtrů",
        "isAlreadyTranslated": true
    },
	"Customer Email ID": {
        "text": "E-mail zákazníka",
        "isAlreadyTranslated": true
    },
	"Contract ID": {
		"text": "Číslo smlouvy",
        "isAlreadyTranslated": true
    },
	"Policy ID": {
		"text": "Číslo pojistky",
        "isAlreadyTranslated": true
    },
	"Clear All": {
		"text": "Vymazat vše",
        "isAlreadyTranslated": true
    },
	"PLAN DETAILS": {
		"text": "PODROBNOSTI PLÁNU",
        "isAlreadyTranslated": true
	},
	"COVERAGE DETAILS": {
		"text": "PODROBNOSTI O KRYTÍ",
        "isAlreadyTranslated": true

	},
	"DEVICE DETAILS": {
		"text": "PODROBNOSTI ZAŘÍZENÍ",
        "isAlreadyTranslated": true
	},
	"Model": {
		"text": "Model",
        "isAlreadyTranslated": true
	},
	"Transaction Date": {
		"text": "Datum transakce",
        "isAlreadyTranslated": true
    },
	"Transaction Amount": {
		"text": "Částka transakce",
        "isAlreadyTranslated": true
    },
	"Contract Status": {
        "text": "Stav smlouvy",
        "isAlreadyTranslated": true
    },
	"Enrolled": {
        "text": "Zapsáno",
        "isAlreadyTranslated": true
    },
	"Diagnosed": {
		"text": "Diagnostikováno",
        "isAlreadyTranslated": true
    },
	"Upgraded": {
        "text": "Vylepšeno",
        "isAlreadyTranslated": true
    },
	"On Hold": {
        "text": "Pozastaveno",
        "isAlreadyTranslated": true
    },
	"Downgraded": {
		"text": "Sníženo",
        "isAlreadyTranslated": true
    },
	"More Filters": {
		"text": "Více",
        "isAlreadyTranslated": true
    },
	"Expired": {
		"text": "Expirováno",
        "isAlreadyTranslated": true
    },
	"Cancellation Initiated": {
		"text": "Zrušení zahájeno",
        "isAlreadyTranslated": true
    },
	"Refunded": {
		"text": "Vráceno",
        "isAlreadyTranslated": true
    },
	"Benefits Availed": {
		"text": "Využité výhody",
        "isAlreadyTranslated": true
    },
	"Bundled": {
        "text": "Spojeno",
        "isAlreadyTranslated": true
    },
	"Under Review": {
        "text": "Pod posouzením",
        "isAlreadyTranslated": true
    },
	"Payment Plan": {
        "text": "Plán plateb",
        "isAlreadyTranslated": true
    },
	"Transaction Completion Date": {
        "text": "Datum dokončení transakce",
        "isAlreadyTranslated": true
    },
	"Payment Link Status": {
        "text": "Stav platebního odkazu",
        "isAlreadyTranslated": true
    },
	"Residential State": {
        "text": "Stát místa pobytu",
        "isAlreadyTranslated": true
    },
	"Residential Country": {
		"text": "Země místa pobytu",
        "isAlreadyTranslated": true
    },
	"Plan Code": {
        "text": "Kód plánu",
        "isAlreadyTranslated": true
    },
	"Plan Term": {
		"text": "Termín plánu",
        "isAlreadyTranslated": true
    },
	"Store Location": {
        "text": "Umístění obchodu",
        "isAlreadyTranslated": true
    },
	"Waiting Period": {
		"text": "Čekací doba",
        "isAlreadyTranslated": true
    },
	"Plan Creation Date": {
        "text": "Datum vytvoření plánu",
        "isAlreadyTranslated": true
    },
	"Plan Cancellation Initiation Date": {
        "text": "Datum zahájení zrušení plánu",
        "isAlreadyTranslated": true
    },

	"Plan Cancellation Date": {
		"text": "Datum zrušení plánu",
        "isAlreadyTranslated": true
    },
	"Cancellation Reason Description": {
        "text": "Popis důvodu zrušení",
        "isAlreadyTranslated": true
    },
	"Coverage Details": {
        "text": "Podrobnosti o pojistném krytí",
        "isAlreadyTranslated": true
    },
	"Coverage Name": {
		"text": "Název pojistného pokrytí",
        "isAlreadyTranslated": true
    },
	"Coverage Start Date": {
		"text": "Datum zahájení pojistného krytí",
        "isAlreadyTranslated": true
    },
	"Claim Avail Date": {
        "text": "Datum uplatnění nároku",
        "isAlreadyTranslated": true
    },
	"Coverage End Date": {
		"text": "Datum ukončení pojistného krytí",
        "isAlreadyTranslated": true
    },
	"No. of Claims Allowed": {
		"text": "Počet povolených nároků",
        "isAlreadyTranslated": true
    },
	"Used Claims": {
		"text": "Využité nároky",
        "isAlreadyTranslated": true
    },
	"Device Purchase Price": {
		"text": "Pořizovací cena zařízení",
        "isAlreadyTranslated": true
    },
	"Mode": {
		"text": "Režim",
        "isAlreadyTranslated": true
    },

	"Payment Gateway Transaction ID": {
		"text": "ID transakce platební brány",
        "isAlreadyTranslated": true
    },
	"External Payment ID": {
        "text": "Externí ID platby",
        "isAlreadyTranslated": true
    },
	"Customer Mobile Number": {
		"text": "Telefonní číslo zákazníka",
		"isAlreadyTranslated": true
	},
	"Billed Date (UTC)": {
        "text": "Datum fakturace",
        "isAlreadyTranslated": true
    },
	"Billing Cycle Date (UTC)": {
        "text": "Datum fakturačního cyklu",
        "isAlreadyTranslated": true
    },
	"Grace Period End Date": {
        "text": "",
        "isAlreadyTranslated": true
    },
	"Retailer Margin": {
		"text": "Obchodní marže",
        "isAlreadyTranslated": true
    },
	"Invoice": {
		"text": "Faktura",
        "isAlreadyTranslated": true
    },
	"Payment Details": {
		"text": "Platební údaje",
        "isAlreadyTranslated": true
    },
	"Overview" : {
        "text": "Přehled",
        "isAlreadyTranslated": true
    },
    "Session Expired": {
		"text": "Relace vypršela",
        "isAlreadyTranslated": true
    },
    "Your session has expired. The store location needs to be selected again, so you will be redirected to the home page shortly.": {
		"text": "Vaše relace vypršela. Lokace obchodu je třeba znovu vybrat, takže budete brzy přesměrováni na domovskou stránku.",
        "isAlreadyTranslated": true
    },
	"Transaction ID": {
		"text": "ID transakce",
        "isAlreadyTranslated": true
    },
	"Transaction Date (UTC)": {
        "text": "Datum transakce",
        "isAlreadyTranslated": true
    },
	"Pay upfront": {
		"text": "Platit předem",
		"isAlreadyTranslated": true
	},
	"month": {
		"text": "měsíc",
		"isAlreadyTranslated": true
	},
	"Maximum": {
		"text": "Povoleno maximálně",
		"isAlreadyTranslated": true
	},
	"Characters Allowed": {
		"text": "znaků",
		"isAlreadyTranslated": true
	},
	"Full Name": {
		"text": "Celé jméno",
		"isAlreadyTranslated": true
	},
	"Make payment": {
		"text": "Zaplatit",
		"isAlreadyTranslated": true
	},
	"Receive Payment": {
		"text": "Přijmout platbu",
		"isAlreadyTranslated": true
	},
}
export default lang;