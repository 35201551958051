import React, {useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Loader, Input, Button, Modal } from 'sfy-react';
import Swal from "sweetalert2";
import PageHeader from '../../../components/PageHeader/PageHeader';
import { convertToNumberFormat, displayError, encrypt, getLocalDateTime, PATTERN } from "../../../Utility/Utils";
import { debounce, filter, find, forEach, includes, maxBy, minBy, union, uniqBy } from "lodash";
import WarrantyFlow from "./WarrantyFlow/WarrantyFlow";
import NonWarrantyFlow from "./NonWarrantyFlow/NonWarrantyFlow";
import "./ProductList.scss";
import moment from "moment";
import PaymentModal from "../PaymentModal/PaymentModal";
import PlanSalesConsent from "../PlanSalesConsent/PlanSalesConsent";

const ProductList = (props) => {
	const { accessFn, localString, brandObj, apiCall, subCategoryObj, PSLData, subCategoryList, setSubCategoryObj, pslidFromQuery, 
		baseUrl, orderDetailsUrl, setLimitationOnAPICall, countriesData} = props;
	
	const [checkEligibilityBeforePlanSelection, setCheckEligibilityBeforePlanSelection] = useState({})
	const [selfCallingFunction, setSelfCallingFunction] = useState(false);
	const [queryRenewalPlanCode, setQueryRenewalPlanCode] = useState(false)  // temporary state
	const [dateOfPurchaseOverriden, setDateOfPurchaseOverriden] = useState(false);
	const [titleHeader, setTitleHeader] = useState()
	const qFilter = JSON.parse(props.qFilter);
	const [loader, setLoader] = useState(false);
	const [protectPlans, setProtectPlans] = useState([]);
	const [securePlans, setSecurePlans] = useState([]);
	const [plans, setPlans] = useState([])
	const [planTypes, setPlanTypes] = useState([])
	const [nonEligibilePlans, setNonEligibilePlans] = useState([]);
	const [selectedPlanType, setSelectedPlanType] = useState('Secure')
	const [selectedHeader, setSelectedHeader] = useState(null)
	const [headers, setHeaders] = useState([])
	const [showPlanTypes, setShowPlanTypes] = useState(null)
	const [tabs, setTabs] = useState(true);
	// const [countriesData, setCountriesData] = useState([])
	let [checkFlags, setCheckFlags] = useState({
		showSalePlan: false,
		showproductTextBox: false,
		// warrantyApiAvail: false,
		showTabs: true,
		tabs: true,
		showPlanTypes: false,
		viewPlans: false,
		planLengthCheck: false,
	})
	const [warrantyDOPFlow, setWarrantyDOPFlow] = useState({
		selectPlanPriceArrayFromPurchaseEligibility: "",
		selectPlanDiscountsFromPurchaseEligibility: "",
		selectPlanPriceId: ""
	})
	const [eligibilityFieldsCheck, setEligibilityFieldsCheck] = useState({
		fieldEligibleRepairDate: false,
		fieldEligibleWarrantyEndDate: false,
		fieldEligibleProductPurchaseCost: false,
		fieldEligibleDateOfBirth: false,
		fieldEligiblePurchaseDate: false,
		fieldEligibleRemarks: false,
		fieldEligibleDeviceSKU: false,
		fieldEligibleDateOfPurchase: false,
		fieldEligibleProductUniqueID: false
	})
	const [selectPlanId, setSelectPlanId] = useState(null)
	const [selectPlanPriceId, setSelectPlanPriceId] = useState(null);
	const [paymentModal, setPaymentModal] = useState(false);
	const [planPriceBillingConfigID, setPlanPriceBillingConfigID] = useState("");
	const [validationWarrantyCheckEligibility, setValidationWarrantyCheckEligibility] = useState(false)
	const [userCurrencySymbol, setUserCurrencySymbol] = useState("");
	const [devicePlansFromIMEIClick, setDevicePlansFromIMEIClick] = useState(false);
	const [checkEligibilityData, setCheckEligibilityData] = useState({})

	const [eligibilityValidation, setEligibilityValidation] = useState(true);
	const [selfUpdating, setSelfUpdating] = useState(false);

	{/** ********** Non-Warranty Flow States ********** */}

	const [nonWarrantyDeviceList, setNonWarrantyDeviceList] = useState([]);
	const [nonWarrantySKUModelList, setNonWarrantySKUModelList] = useState([])
	const [selectedDeviceObj, setSelectedDeviceObj] = useState({});
	const [plansVisible, setPlansVisible] = useState(false);
	const [displayPlanList, setDisplayPlanList] = useState([]);
	const [visiblePlanIndex, setVisiblePlanIndex] = useState(null);
	const [selectedPlan, setSelectedPlan] = useState(null);
	const [brandDetails, setBrandDetails] = useState([]);
	const [allowedValues, setAllowedValues] = useState(null);
	const [currentPlan, setCurrentPlan] = useState(null);
	const [deviceSKUDropdownList, setDeviceSKUDropdownList] = useState([]);
	const [noDataMessage, setNoDataMessage] = useState(false);
	const [requiredFieldsForPurchaseArr, setRequiredFieldsForPurchaseArr] = useState([])
	const [deliveryNoteTitle, setDeliveryNoteTitle] = useState();
	const [warrantyApiAvail, setWarrantyApiAvail] = useState(false);
	const [IMEISerialNumberValidation, setIMEISerialNumberValidation] = useState({});
	const [filterDataVerifyDevice, setFilterDataVerifyDevice] = useState({
		inputImeiOrSerial: "",
		dateOfPurchasePicker: "",
		dateOfBirthPicker: "",
		eligiblityProductPurchaseCost: "",
		repairDatePicker: "",
		warrantyEndDatePicker: "",
		purchaseDatePicker: "",
		eligibleRemarks: "",
		deviceSKU: "",
		identifierValue: "",
		deviceIdentifierID: "",
		productPurchaseDate: ""
	});
	const [checkEligibleFieldsBeforePlanSelection, setCheckEligibleFieldsBeforePlanSelection] = useState([]);
	const [eligibleCheckFields, setEligibleCheckFields] = useState([])
	const [SKUModelCheck, setSKUModelCheck] = useState(false);
	const [SKUSearchText, setSKUSearchText] = useState("");
	const [disableSearch, setDisableSearch] = useState(true);
	const [nonWarrantyEligibleFields, setNonWarrantyEligibleFields] = useState([]);

	const [valuesSetOnSearchClicked, setValuesSetOnSearchClicked] = useState()

	{/** ********** END Non-Warranty Flow States ********** */}

	{/** ********** Address Section States ********** */}

	const [pincodeCheck, setPincodeCheck] = useState(false);
	const [address, setAddress] = useState(null);
	const [errors, setErrors] = useState({});
	const [addressConfigArr, setAddressConfigArr] = useState([]);
	const [productValidate, setProductValidate] = useState(true);
	const [addressValidate, setAddressValidate] = useState(true);

	{/** ********** END Address Section States ********** */}

	{/** ********** Payment Modal Flow States ********** */}

	const [paymentPlanSelected, setPaymentPlanSelected] = useState(false);
	const [paymentPlanOptionsAvailable, setPaymentPlanOptionsAvailable] = useState(false);
	const [planPriceBillingConfig, setPlanPriceBillingConfig] = useState([])
	const [selectedPyamentType, setSelectedPyamentType] = useState(null);
	const [enableContinuePaymentPlan, setEnableContinuePaymentPlan] = useState(false);
	const [showOffersInModal, setShowOffersInModal] = useState(false)

	{/** ********** END Payment Modal Flow States ********** */}

	{/** ********** Consent Flow States ********** */}

	const [dataAbroadConsent, setDataAbroadConsent] = useState({});
	const [agreeTermsConsent, setAgreeTermsConsent] = useState({});
	const [paymentDeclarationConsent, setPaymentDeclarationConsent] = useState({});
	const [showConsentModal, setShowConsentModal] = useState(false);
	const [acceptedDataAbroadConsent, setAcceptedDataAbroadConsent] = useState(false);

	{/** ********** END Consent Flow States ********** */}

	useEffect(() => {
		if(selfCallingFunction){
			setSelfCallingFunction(false);
			gsxEligibility(selectedPlan,true,filterDataVerifyDevice.inputImeiOrSerial);
		}
	}, [selfCallingFunction])

	useEffect(() => {
		getApplicationLegalConfigs();
	}, [])
	
	useEffect(() => {
		if(selfUpdating){
			setSelfUpdating(false);
			checkPaymentPlan(selectedPlan);
		}
	}, [selfUpdating])

	useEffect(() => {
		setCheckEligibilityBeforePlanSelection ({
			LG_checkEligibilityBeforePlanSelection : accessFn("CHECK_ELIGIBILITY_FIRST") && (brandObj?.BrandCode === "LG"),
			Dell_checkEligibilityBeforePlanSelection : accessFn("CHECK_ELIGIBILITY_FIRST") && (brandObj?.BrandCode === "DELL")
		})

	}, [brandObj])

	useEffect(() => {
		let skuCheck = checkEligibleFieldsBeforePlanSelection?.includes("SKUModel");
		let locationAvailable = checkEligibleFieldsBeforePlanSelection?.includes("location");
		setSKUModelCheck(skuCheck)
		setPincodeCheck(locationAvailable)
	}, [checkEligibleFieldsBeforePlanSelection])

	useEffect(() => {
		if((checkEligibilityBeforePlanSelection?.LG_checkEligibilityBeforePlanSelection || checkEligibilityBeforePlanSelection.Dell_checkEligibilityBeforePlanSelection )){
			getEligibiltyCheckFields();
			// qFilter?.countrycode && getCountries(qFilter.countrycode[0]);
		}else {
			if(brandObj){
				if(brandObj?.WarrantyCheckDetails?.ValidForEntireBrand) {
					fetchEligiblePlans();
					setCheckFlags({...checkFlags, showproductTextBox: false})
				} else {
					getPlanProducts();
					setCheckFlags({...checkFlags, showproductTextBox: true})
				}
				brandObj?.BrandName && setTitleHeader(brandObj.BrandName + " " +localString("Plans"));
			}
		}
	}, [checkEligibilityBeforePlanSelection, brandObj])

	useEffect(() => {
		// getApplicationLegalConfigs();
		updatePlans(selectedDeviceObj.ProductID ? true : brandObj?.WarrantyCheckDetails?.ValidForEntireBrand);
	}, [selectedDeviceObj, brandObj])
		
	useEffect(() => {
		let showPlan = ((securePlans && securePlans.length > 0) || (protectPlans && protectPlans.length > 0)) ? true : false;
		setShowPlanTypes(showPlan)
		let planType = [];
	
		//Set plan type tab
		if(securePlans && securePlans.length > 0) {
			planType.push('Secure');
		}
		if(protectPlans &&  protectPlans.length > 0) {
			planType.push('Protect');
		}
		setPlanTypes(planType);
		setSelectedPlanType(planType[0]);
		setTabHeaders()
	}, [securePlans, protectPlans])

	useEffect(() => {
		setIMEISerialValidations();
	}, [allowedValues]);

	useEffect(() => {
		SKUModelCheck && 
		getSKUModelvalue(SKUSearchText);
	}, [SKUSearchText]);

	useEffect(() => {
		searchValidate();
		if(filterDataVerifyDevice.inputImeiOrSerial){
			validateIMEISerial(filterDataVerifyDevice.inputImeiOrSerial)
		}
	}, [filterDataVerifyDevice, filterDataVerifyDevice.inputImeiOrSerial]);

	useEffect(() => {
		checkEligibilityValidate();
	}, [nonWarrantyEligibleFields, filterDataVerifyDevice])

	useEffect(() => {
		let flag = false;
		addressConfigArr && addressConfigArr.map((a)=>{
			if(a.mandatory){
				if(address && address[a.keyName]?.length > 0){
					if(errors && (errors[a.keyName] === null || errors[a.keyName] == "") && areAllValuesEmpty(errors)){
						flag = false;
					}else {
						flag = true;
					}
				}else{	
					flag = true;
				}
			}else {
				flag = false;
			}
			setAddressValidate(flag);
		})
	}, [addressConfigArr, errors, address])

	useEffect(() => {
		if(checkEligibilityBeforePlanSelection.Dell_checkEligibilityBeforePlanSelection){
			if(!productValidate) {
				setDisableSearch(false)
			}else {
				setDisableSearch(true)
			}
		}else if(checkEligibilityBeforePlanSelection.LG_checkEligibilityBeforePlanSelection){
			if(!productValidate && !addressValidate) {
				setDisableSearch(false)
			}else {
				setDisableSearch(true)
			}
		}else {
			setDisableSearch(true)
		}
	}, [productValidate, addressValidate])
	

	const areAllValuesEmpty = (obj) => {
		for (let key in obj) {
			if (obj.hasOwnProperty(key) && (obj[key] != "" && obj[key] != null)) {
				return false;
			}
		}
		return true; 
	}

	useEffect(() => {
		if(countriesData){
			let currencySymbol = countriesData?.currencies?.[0]?.CurrencySymbol;
			setUserCurrencySymbol(currencySymbol)
		}
	}, [countriesData])
	
	const searchValidate = () => {
		let flag = false;
		checkEligibleFieldsBeforePlanSelection.forEach(o=>{
			switch (o) {
				case "ProductUniqueID":
					if((!filterDataVerifyDevice?.inputImeiOrSerial || filterDataVerifyDevice?.inputImeiOrSerial === "")) {
						flag = true;
					}else {
						let res = validateIMEISerial(filterDataVerifyDevice?.inputImeiOrSerial);
						if(res){
							flag = false;
						}else {
							flag = true;
						}
					}
					break;
				case "DateOfPurchase":
					if(!filterDataVerifyDevice?.productPurchaseDate || filterDataVerifyDevice.productPurchaseDate === "") {
						flag = true;
					}
					break;
				case "ProductPurchaseCost":
					if(!filterDataVerifyDevice?.eligiblityProductPurchaseCost || filterDataVerifyDevice.eligiblityProductPurchaseCost === "") {
						flag = true;
					}
					break;
			}
			setProductValidate(flag)
			// setDisableSearch(flag)
		})
	}

	const checkEligibilityValidate = () => {
		let flag = false;
		nonWarrantyEligibleFields.forEach((field)=>{
				switch (field) {
					case "ProductUniqueID":
						if((!filterDataVerifyDevice?.inputImeiOrSerial || filterDataVerifyDevice?.inputImeiOrSerial === "")) {
							flag = true;
						}else {
							let res = validateIMEISerial(filterDataVerifyDevice?.inputImeiOrSerial);
							if(res){
								flag = false;
							}else {
								flag = true;
							}
						}
						break;
					case "ProductPurchaseCost":
						if(!filterDataVerifyDevice?.eligiblityProductPurchaseCost || filterDataVerifyDevice.eligiblityProductPurchaseCost === "") {
							flag = true;
						}
						break;
					case "RepairDate":
						if(!filterDataVerifyDevice?.repairDatePicker || filterDataVerifyDevice.repairDatePicker === "") {
							flag = true;
						}
						break;
					case "WarrantyEndDate":
						if(!filterDataVerifyDevice?.warrantyEndDatePicker || filterDataVerifyDevice.warrantyEndDatePicker === "") {
							flag = true;
						}
						break;
					case "DateOfPurchase":
						if(!filterDataVerifyDevice?.productPurchaseDate || filterDataVerifyDevice.productPurchaseDate === "") {
							flag = true;
						}
						break;
					case "BirthDate":
						if(!filterDataVerifyDevice?.dateOfBirthPicker || filterDataVerifyDevice.dateOfBirthPicker === "") {
							flag = true;
						}
						break;
					case "DeviceSKU":
						if(!filterDataVerifyDevice?.deviceSKU || filterDataVerifyDevice.deviceSKU === "") {
							flag = true;
						}
						break;
					// case "imeiOrSerial":
					// 	if(!self.isImeiSerialValueValid) {
					// 		flag = false;
					// 	}
					// 	break;
				}
			
				setEligibilityValidation(flag)
		})
	}

	const getSKUModelvalue = debounce((modelNoInput) => {
		if (modelNoInput && modelNoInput.length > 2) {
			getPlanProductSKUModelNos(modelNoInput);
			setNoDataMessage(true);
		}else {
			setNoDataMessage(false);
		}
	}, 200);
	
	const buttonHeader = [
		{
			showPSL: true,
			mainDivClass: "location-name-container-header pull-right",
			innerDivLabelClass: "location-name-header",
			linkTextClass: "change-location-header",
			PSLName: PSLData.ServiceLocationName,
			onClickEvent: () => {
				window.location.reload()
			}
		}
	];

	const setDiscounts = (plans) => {
		if (plans && plans.length > 0) {
			for (var i = 0; i < (plans.length); i++) {
				if (plans[i].PlanDiscounts && plans[i].PlanDiscounts.PlanDiscountOffers && plans[i].PlanDiscounts.PlanDiscountOffers.length != 0) {
					if (!plans[i].PlanPriceArray || plans[i].PlanPriceArray.length != 1) {
						plans[i].showViewAllOffers = true;
					}
					else {
						if (plans[i].PlanDiscounts.PlanDiscountOffers.length == 1) {
							if (plans[i].PlanDiscounts.PlanDiscountOffers[0].requiredFieldsPresent && plans[i].PlanDiscounts.PlanDiscountOffers[0].DiscountCategory != "PromoCode") {
								if (plans[i].PlanDiscounts.PlanDiscountOffers[0].PaymentType == 'UPFRONT') {
									plans[i].simpleDiscount = plans[i].PlanDiscounts.PlanDiscountOffers[0];
								} else if (['MONTHLY','YEARLY'].indexOf(plans[i].PlanDiscounts.PlanDiscountOffers[0].PaymentType) > -1) {
									plans[i].EMIDiscount = plans[i].PlanDiscounts.PlanDiscountOffers[0];
								}
							}
							else {
								plans[i].showViewAllOffers = true;
							}
						}
						else if (plans[i].PlanDiscounts.PlanDiscountOffers.length == 2) {
							if (plans[i].PlanDiscounts.PlanDiscountOffers[0].requiredFieldsPresent && plans[i].PlanDiscounts.PlanDiscountOffers[0].DiscountCategory != "PromoCode" && plans[i].PlanDiscounts.PlanDiscountOffers[1].requiredFieldsPresent && plans[i].PlanDiscounts.PlanDiscountOffers[1].DiscountCategory != "PromoCode") {
								if (plans[i].PlanDiscounts.PlanDiscountOffers[0].PaymentType == 'UPFRONT' && ['MONTHLY','YEARLY'].indexOf(plans[i].PlanDiscounts.PlanDiscountOffers[1].PaymentType) > -1) {
									plans[i].simpleDiscount = plans[i].PlanDiscounts.PlanDiscountOffers[0];
									plans[i].EMIDiscount = plans[i].PlanDiscounts.PlanDiscountOffers[1];
								}
								else if (plans[i].PlanDiscounts.PlanDiscountOffers[1].PaymentType == 'UPFRONT' && ['MONTHLY','YEARLY'].indexOf(plans[i].PlanDiscounts.PlanDiscountOffers[0].PaymentType) > -1) {
									plans[i].EMIDiscount = plans[i].PlanDiscounts.PlanDiscountOffers[0];
									plans[i].simpleDiscount = plans[i].PlanDiscounts.PlanDiscountOffers[1];
								}
								else {
									plans[i].showViewAllOffers = true;
								}
							}
							else {
								plans[i].showViewAllOffers = true;
							}
						}
						else {
							plans[i].showViewAllOffers = true;
						}
					}
				}
			}
		}
	}

	const setIMEISerialValidations = () => {
		let obj = {};
		obj.isIMEIValidationPresent = !!find(allowedValues, function (o) {
			return o.Type == "IMEI";
		});
		obj.isSerialValidationPresent = !!find(allowedValues, function (o) {
			return o.Type == "SerialNumber";
		});

		let maxLengthObj = maxBy(allowedValues, "MaxLength");
		let minLengthObj = minBy(allowedValues, "MinLength");
		let lengthObj = maxBy(allowedValues, "Length");

		obj.maxImeiSerialLength = (maxLengthObj && maxLengthObj?.MaxLength) || (lengthObj && lengthObj?.Length) || 30;

		obj.minImeiSerialLength = minLengthObj && minLengthObj?.MinLength;
		setIMEISerialNumberValidation(obj);
	}

	const getIMEISerialText = () => {
		let labelText = "";
		if (IMEISerialNumberValidation.isSerialValidationPresent) {
			labelText += localString("Serial Number");
		}
		if (
			IMEISerialNumberValidation.isIMEIValidationPresent &&
			IMEISerialNumberValidation.isSerialValidationPresent
		) {
			labelText += " / ";
		}
		if (IMEISerialNumberValidation.isIMEIValidationPresent) {
			labelText += localString("IMEI");
		}
		if (
			!IMEISerialNumberValidation.isIMEIValidationPresent &&
			!IMEISerialNumberValidation.isSerialValidationPresent
		) {
			labelText =
				localString("Serial Number") + " / " + localString("IMEI");
		}
		return localString('Enter') + ' ' + labelText;
	};

	const checkIfConsentRequired = (e) => {
		e.preventDefault();
		if(dataAbroadConsent?.legalConfig?.LegalConfigCode === "DATA_ABROAD_360") {
			setShowConsentModal(true);
			setDevicePlansFromIMEIClick(true);
		} else {
			getDevicePlansFromIMEI();
		}
	};

	const onOtpSuccess = () => {
		setAcceptedDataAbroadConsent(true);
		if(devicePlansFromIMEIClick) {
			getDevicePlansFromIMEI();
		} else {
			// IMEI Modal flow
			checkEligibility(checkEligibilityData);
		}
	};

	const goToNonWarrantyProductList = () => {
		getPlanProducts();
		setCheckFlags({...checkFlags, showproductTextBox: true})
	};

	const onGetDevicePlansFromImeiSuccess = (result) => {
		let planData = result.data;
		let plansArr = [...plans];
		let dateOfPurchase = null;
		let nonEligibilePlansArr = [];
		let selectedProduct = null;
	
		if(result.data.ProductObj) {
			selectedProduct = result.data.ProductObj;
			setSelectedDeviceObj(selectedProduct)
			dateOfPurchase = result.data.ProductObj.DateOfPurchase;
			// need input from vatsal - different flow in warranty flow
			// setFilterDataVerifyDevice({...filterDataVerifyDevice, productPurchaseDate: dateOfPurchase})
		}
		if (planData && planData.ProductObj && planData.ProductObj.ModelUnverified) {
			Swal.fire({
				title: '',
				text: localString('Product details cannot be validated. Please choose from the device list.'),
				icon: 'info',
				showConfirmButton: true,
				confirmButtonColor: '#4c10c1'
			}).then ((result) => {
				if(result.isConfirmed){
					plansArr = [];
					goToNonWarrantyProductList();
				}
			});
		} else {
			// when models are not verified
			plansArr = [];
			setCheckFlags({
				showTabs: false,
				tabs: false,
				showPlanTypes: false,
				showproductTextBox: false
			})
			setShowPlanTypes(false);
			setSelectedPlan(null);
			setTabs(false);
			
			if(dateOfPurchase || [28,85].indexOf(planData.ProductObj.BrandID) > -1) {
				forEach(planData.PlanObj, function (group) {
					plansArr = uniqBy(union(plansArr, group.PlanObject), 'PlanID');
				});

				nonEligibilePlansArr = filter(plansArr, function(obj) {
					return obj.Status == -7;
				});

				plansArr = filter(plansArr, function(obj) {
					return obj.Status == -2;
				});
				
				setDiscounts(plansArr);
	
				// for multiple plans
				if (plansArr.length > 1) {
					Swal.fire ({
						title: localString('Device is eligible!'),
						html: '<p>'+localString("Device is eligible for multiple plans. Please choose a plan.")+'</p>',
						icon: 'success',
						showConfirmButton: true,
						confirmButtonText: localString('View Plans'),
						confirmButtonColor: '#4c10c1'
					}).then ((result) => {
						if(result.isConfirmed){
							setCheckFlags({ 
								showTabs: true,
								viewPlans: true,
							})
							
							// self.serialNumber = $('#imeiOrSerial').val(); => need some clarity here
						}
					});
				} else if (plansArr.length == 1) {
					setCheckFlags({
						showTabs: true,
						viewPlans: true,
					})
					// self.selectPlanId = self.plans[0].PlanID;
					let singlePlan = plansArr[0];
					let PPBillingConfig = null;
					// self.selectPlanPriceId = self.plans[0].PlanPriceArray.length > 0? self.plans[0].PlanPriceID: undefined;
					if(plansArr && plansArr[0] && plansArr[0]?.PlanConfig?.Disclaimer){
						showDisclaimer(plansArr[0].PlanConfig);
					} else {
						if ((!singlePlan.PlanPriceArray || (singlePlan.PlanPriceArray && singlePlan.PlanPriceArray.length == 0)) && warrantyDOPFlow.selectPlanPriceArrayFromPurchaseEligibility) {
							singlePlan.PlanPriceArray = [warrantyDOPFlow.selectPlanPriceArrayFromPurchaseEligibility];
						}
						if (warrantyDOPFlow.selectPlanDiscountsFromPurchaseEligibility) {
							singlePlan.PlanDiscounts = warrantyDOPFlow.selectPlanDiscountsFromPurchaseEligibility;
							setSpecificDiscounts(singlePlan) // need to verify
						}
						if (singlePlan && singlePlan.PlanPriceArray && singlePlan.PlanPriceArray.length > 0 && (singlePlan.PlanPriceArray[0].TotalPrice || (singlePlan.PlanPriceArray[0].ServiceBasePrice + singlePlan.PlanPriceArray[0].InsuranceBasePrice)) && singlePlan.PlanPriceArray[0].planPriceBillingConfig && singlePlan.PlanPriceArray[0].UpfrontPaymentAllowed) {
							PPBillingConfig = singlePlan.PlanPriceArray[0].planPriceBillingConfig
							setPlanPriceBillingConfig(PPBillingConfig)
						}
						else {
							setPaymentPlanOptionsAvailable(false);
							if (singlePlan.PlanPriceArray && singlePlan.PlanPriceArray.length > 0 && singlePlan.PlanPriceArray[0].planPriceBillingConfig) {
								PPBillingConfig = singlePlan.PlanPriceArray[0].planPriceBillingConfig;
								let PPBillingConfigID = PPBillingConfig[0].PlanPriceBillingConfigID;
								singlePlan.PlanPriceBillingConfigID = PPBillingConfigID;
								setPlanPriceBillingConfigID(PPBillingConfigID)
							}
							if (singlePlan?.PlanPriceArray && singlePlan?.PlanPriceArray?.length > 0) {
								goToOrderDetails(singlePlan, selectedProduct?.ProductID);
							}
							else {
								Swal.fire({
									icon: "error",
									text: localString('Sorry, we were unable to fetch the plan price against provided details'),
									confirmButtonText: localString("Okay")
								})
							}
						}
						setSelectedPlan(singlePlan);
					}
				} else {
					// when there are no active plans
					let text = '';
					if (result.data && result.data.status_message) {
						if (result.data.status_message === 'ALREADY_PURCHASED') {
							text = result.msg;
						} else if (result.data.status_message === 'NO_PLANS_AVAILABLE') {
							text = result.msg;
						} else if (result.data.status_message === 'NOT_ELIGIBLE') {
							text = result.msg;
						} else {
							text = 'Something went wrong';
						}
					}  else {
						text = 'Something went wrong';
					}
					Swal.fire ({
						icon: "error",
						title: localString("Sorry"),
						text: localString(text)
					});
				}
			}
			{/** need to figure it out */}
			// showOverrideDateOfPurchaseOption(canDateOfPurchaseBeOverridden());
		}
		setPlans(plansArr);
		setNonEligibilePlans(nonEligibilePlansArr)
	};

	const updatePlans = (warrantyApiAvail) => {
		let localFlags = {...checkFlags}
		let Theader = (brandObj?.BrandName ? brandObj.BrandName + localString(" Plans") : props.titleHeader);
		setTitleHeader(Theader)

		localFlags.showTabs = true;
		localFlags.viewPlans = false;
		localFlags.tabs = true;

		setSelectPlanId(null)
		setSelectPlanPriceId(null);
		setCheckFlags(localFlags)
		setTabs(true);
	
		//Add imei/serial checks
		if(brandObj && brandObj.WarrantyCheckDetails) {
			setWarrantyFlow(warrantyApiAvail);
		} else {
			setWarrantyApiAvail(false);
			setCheckFlags({...checkFlags, showproductTextBox: true});
			// self.warrantyApiAvail = false;
			// self.showproductTextBox = true;
		}

		{/** There is no use of below variables in existing codebase */}

			// self.salePlanDetails = opts.saleplandetails;  
			// self.buyPlanButton = false;
			// self.deviceEligible = false;
			// self.parentBrandID = self.parent.selectedBrand && self.parent.selectedBrand.BrandID ? self.parent.selectedBrand.BrandID : null;

	};

	const setWarrantyFlow = (warrantyApiAvailable) => {
		let warrantyApiAvail = warrantyApiAvailable;
		setWarrantyApiAvail(warrantyApiAvail)

		if (brandObj?.WarrantyCheckDetails?.AllowedValues && brandObj?.WarrantyCheckDetails?.AllowedValues.length > 0) {
			let allowedValue = brandObj?.WarrantyCheckDetails?.AllowedValues;
			setAllowedValues(allowedValue)
			setIMEISerialValidations();
		} else {
			setDefaultIMEISerialValidations();
		}
	};

	const setDefaultIMEISerialValidations = () => {
		let allowedValues = [{
			"Type": "IMEI/SerialNumber",
			"ExtraFilter": "Custom",
			"MinLength" : 4.0,
			"MaxLength" : 30.0,
			"Pattern": getImeiSerialValidationPattern()
		}];
		setAllowedValues(allowedValues)
		setIMEISerialValidations();
	};

	const getImeiSerialValidationPattern = () => {
		let pattern;
		switch(brandObj?.BrandID) {
			case 16: 
				pattern = "^[0-9a-zA-Z-]+$";
				break;
			default:
				pattern = "^[0-9a-zA-Z]+$";
		};
		return pattern;
	};

	useEffect(() => {
		setTabHeaders()
	}, [selectedPlanType])
	
	const setTabHeaders = () => {
		let header = [];
		if((selectedPlanType) == 'Secure') {
			forEach(securePlans, function(plan) {
				!includes(header, plan.SuperGroupHeader || 'Others') && header.push(plan.SuperGroupHeader || 'Others');
			});
		} else if((selectedPlanType) == 'Protect') {
			forEach(protectPlans, function(plan) {
				!includes(header, plan.SuperGroupHeader || 'Others') && header.push(plan.SuperGroupHeader || 'Others');
			});
		}
		setHeaders(header)
		selectTab(header[0])
	};

	const selectTab = (header) => {
		let selectedHeaderName = header;
		setSelectedHeader(selectedHeaderName)
		let plansArr = [];

		if(selectedPlanType == 'Secure') {
			forEach(securePlans, function(plan) {
				selectedHeaderName == (plan.SuperGroupHeader ? plan.SuperGroupHeader: 'Others') && plansArr.push(plan);
			});
		} else if(selectedPlanType == 'Protect') {
			forEach(protectPlans, function(plan) {
				selectedHeaderName == (plan.SuperGroupHeader ? plan.SuperGroupHeader: 'Others') && plansArr.push(plan);
			});
		}
		setPlans(plansArr)
	};

	const showPlanPriceSimple = (plan) => {
		if (plan.PlanPriceArray && plan.PlanPriceArray.length == 1) {
			if (plan.simpleDiscount) {
				if (plan.PlanPriceArray[0].TotalPrice) {
					return convertToNumberFormat(plan.simpleDiscount.DiscountedTotalPlanPrice)
				}
				else {
					return convertToNumberFormat(plan.simpleDiscount.DiscountedBasePlanPrice)
				}
				
			}
			else {
				return convertToNumberFormat(plan.PlanPriceArray[0].TotalPrice || (plan.PlanPriceArray[0].ServiceBasePrice + plan.PlanPriceArray[0].InsuranceBasePrice))
			}
		}
		else {
			return ""
		}
	}

	const convertToNumberFormat = (num) => {
		// return convertToNumberFormat(num);
		if(num > 0){
			return num;
		}
	};

	const showUnDiscountedPriceSimple = (plan) => {
		if (plan.PlanPriceArray && plan.PlanPriceArray.length == 1) {
			return convertToNumberFormat(plan.PlanPriceArray[0].TotalPrice || (plan.PlanPriceArray[0].ServiceBasePrice + plan.PlanPriceArray[0].InsuranceBasePrice))
		}
		else {
			return ""
		}
	}

	const showDiscountPriceSimple = (plan) => {
		if (plan.PlanPriceArray && plan.PlanPriceArray.length == 1) {
			if (plan.simpleDiscount) {
				return plan.simpleDiscount.OfferDisplayText;
			}
			else {
				return ""
			}
		}
		else {
			return ""
		}
	}

	const showRecurringAmount = (e) => {
		return e[0].TotalEMIAmount || (e[0].EMIServiceBasePrice + e[0].EMIInsuranceBasePrice)
	}

	const showRecurringFrequency = (e) => {
		return localString(e[0].FrequencyUnitDisplay)
	}

	const checkIfDiscountedPriceToBeShown = (plan) => {
		if (plan.PlanPriceArray && plan.PlanPriceArray.length == 1 && plan.PlanPriceArray[0].planPriceBillingConfig) {
			if (plan.EMIDiscount) {
				if (plan.EMIDiscount.hasConcession) {
					return false;
				}
				else {
					return true;
				}
			}
			else {
				return true;
			}
		}
		else {
			return true
		}
	}

	const showDiscountedRecurringAmount = (plan) => {
		if (plan.PlanPriceArray && plan.PlanPriceArray.length == 1 && plan.PlanPriceArray[0].planPriceBillingConfig) {
			if (plan.EMIDiscount) {
				if (plan.PlanPriceArray[0].planPriceBillingConfig[0].TotalEMIAmount) {
					return convertToNumberFormat(plan.EMIDiscount.DiscountedTotalPlanPrice)
				}
				else {
					return convertToNumberFormat(plan.EMIDiscount.DiscountedBasePlanPrice)
				}
				
			}
			else {
				return convertToNumberFormat(plan.PlanPriceArray[0].planPriceBillingConfig[0].TotalEMIAmount || (plan.PlanPriceArray[0].planPriceBillingConfig[0].EMIServiceBasePrice + plan.PlanPriceArray[0].planPriceBillingConfig[0].EMIInsuranceBasePrice))
			}
		}
		else {
			return ""
		}
	}

	const showDiscountPriceEMI = (plan) => {
		if (plan.PlanPriceArray && plan.PlanPriceArray.length == 1 && plan.PlanPriceArray[0].planPriceBillingConfig) {
			if (plan.EMIDiscount) {
				return plan.EMIDiscount.OfferDisplayText;
			}
			else {
				return ""
			}
		}
		else {
			return ""
		}
	}

	const sellNowWarranty = (val) => {
		setSelectedPlan(val)
		// self.selectedPlan = self.showproductTextBox? e.item.plan: e;
		let selectedPlan = val;
		let selectPlanId = val?.PlanID;
		let selectPlanPriceId = val?.PlanPriceArray?.length > 0 ? val?.PlanPriceID : undefined;

		if (!filterDataVerifyDevice.inputImeiOrSerial || (filterDataVerifyDevice.inputImeiOrSerial === null) || (filterDataVerifyDevice.inputImeiOrSerial === '') || !checkFlags.viewPlans) {  
			Swal.fire ({
				title: localString(getIMEISerialText()),
				icon:'info',
				confirmButtonColor: '#4c10c1',
				confirmButtonText: localString('Continue'),
				html: '<input class="serial-number-swal" id="swalSerialNumber"  type = "text" autocomplete="off" />' //maxlength={'+ self.maxImeiSerialLength +'} minlength={'+ self.minImeiSerialLength +'} autocomplete="off"
			}).then ((result) => {
				if (result.isConfirmed) {
					let serialNumber = document.getElementById("swalSerialNumber")?.value; 
					setFilterDataVerifyDevice({...filterDataVerifyDevice, inputImeiOrSerial: serialNumber})
						{/** handled in useEffect **/}
					// gsxEligibility(val,true,serialNumber); 
					setSelfCallingFunction(true);
				}
			});
		} else {
			gsxEligibility(val,false);
		}
		
		// $(".sweet-confirm").prop('disabled',true)
		// if(self.selectedPlan && self.selectedPlan.PlanConfig && self.selectedPlan.PlanConfig.Disclaimer) {
		//     $(".sweet-confirm").prop('disabled',false);
		// }
		// self.addSwalInputListners();
		// self.update();
	};

	const gsxEligibility = (selectedPlan,callCheckEligibility,serialNumber) => {
		let data  = {
			"warrantyCheckRequired": true,
			"imeiOrSerial": serialNumber ? serialNumber : filterDataVerifyDevice.inputImeiOrSerial,
			"PlanID": selectedPlan?.PlanID,
			"BrandID": brandObj.BrandID,
			"CountryCode" : qFilter.countrycode ? qFilter.countrycode[0]: undefined,
			"salesChannel": qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp",
		};
		if(selectedDeviceObj.ProductID){
			data.ProductID = selectedDeviceObj.ProductID;
		}

		if(serialNumber ? (serialNumber.toString().length < 10) : (filterDataVerifyDevice.inputImeiOrSerial.length < 10)) {
			Swal.fire({
				title: localString('Incorrect IMEI/Serial Number'),
				icon: "error",
				text: localString('Please enter correct IMEI/Serial Number')
			})
		} else if(callCheckEligibility) {
			checkConsentRequiredForEligibility(data,selectedPlan);
		} else {
			if(selectedPlan && selectedPlan.PlanConfig && selectedPlan.PlanConfig.Disclaimer) {
				showDisclaimer(selectedPlan,true);
			}else{
				checkPaymentPlan(selectedPlan);
			}
		}
	};

	const checkConsentRequiredForEligibility = (data,selectedPlan) => {
		if(dataAbroadConsent?.legalConfig?.LegalConfigCode === "DATA_ABROAD_360") {
			setCheckEligibilityData(data);
			setShowConsentModal(true);
			setDevicePlansFromIMEIClick(false);
		} else {
			checkEligibility(data,selectedPlan); //API call
		}
	};

	const validateIMEISerial = (serialNumber) => {
		let validationCheckPass = false;
		if (allowedValues && allowedValues.length > 0 ) {
			//validation check
			for(let i = 0; i < allowedValues.length; i++) {
				if(checkLengthRange(allowedValues[i], serialNumber) && checkLength(allowedValues[i], serialNumber) && checkExtraFilter(allowedValues[i], serialNumber)) {
					validationCheckPass = true;
				}
			}
		} else {
			if(serialNumber.length > 11 && /^[a-z0-9]+$/i.test(serialNumber)) {
				validationCheckPass = false;
			} else if(serialNumber.length <= 11 && /^[a-z0-9]+$/i.test(serialNumber) && serialNumber.substr(0,1) == "0") {
				validationCheckPass = true;
			} else if (serialNumber.length <= 11 && /^[a-z0-9]+$/i.test(serialNumber) ) {
				validationCheckPass = true;
			} else {
				validationCheckPass = true;
			}
		}
		setValidationWarrantyCheckEligibility(validationCheckPass);
		return validationCheckPass;
		// $(".sweet-confirm") && $(".sweet-confirm").prop('disabled',!self.validationCheckPass);
		// $('#swalSerialNumber').val(serialNumber);

		// self.disableCheckEligibilityButton = !self.validationCheckPass;
		// $('#imeiOrSerial').val(serialNumber);
		// self.update();
		// return self.validationCheckPass;
	};

	const checkExtraFilter = (validationRule, value) => {
		if(validationRule.ExtraFilter.toLowerCase() === 'numeric') {
			return !isNaN(value);
		} else if(validationRule.ExtraFilter.toLowerCase() === 'alphanumeric') {
			return PATTERN.alphanumeric.test(value);
		} else if(validationRule.ExtraFilter.toLowerCase() === 'custom' && validationRule.Pattern) {
			return new RegExp(validationRule.Pattern).test(value);
		} else {
			return true;
		}
	};

	const checkLength = (validationRule, value) => {
		if(validationRule && validationRule.AdditionalSupportedLength && validationRule.AdditionalSupportedLength.length > 0) {
			return validationRule.AdditionalSupportedLength.indexOf(value.length) > -1;
		} else if(validationRule && validationRule.Length) {
			return validationRule.Length === value.length;
		} else {
			return true;
		}
	};

	const checkLengthRange = (validationRule, value) => {
		if(validationRule && validationRule.MinLength && validationRule.MaxLength) {
			return validationRule.MinLength <= value.length && validationRule.MaxLength >= value.length;
		} else {
			return true;
		}
	};

	const checkIfSpecificEMIDiscountedPriceToBeShown = (price) => {
		if (price.FrequencyUnit == 'MONTH') {
			if (selectedPlan.EMIDiscount && selectedPlan.EMIDiscount.PaymentType == 'MONTHLY') {
				if (selectedPlan.EMIDiscount.hasConcession) {
					return false;
				}
				return true;
			}
			return true;
		}
		else if (price.FrequencyUnit == 'YEAR') {
			if (selectedPlan.EMIDiscount && selectedPlan.EMIDiscount.PaymentType == 'YEARLY') {
				if (selectedPlan.EMIDiscount.hasConcession) {
					return false;
				}
				return true;
			}
			return true;
		}
		return true;
	}

	const getSpecificEMIDiscountedPrice = (price) => {
		if (price.FrequencyUnit == 'MONTH') {
			if (selectedPlan.EMIDiscount && selectedPlan.EMIDiscount.PaymentType == 'MONTHLY') {
				return price.TotalEMIAmount ? selectedPlan.EMIDiscount.DiscountedTotalPlanPrice : selectedPlan.EMIDiscount.DiscountedBasePlanPrice;
			}
			return price.TotalEMIAmount ? price.TotalEMIAmount : (price.EMIServiceBasePrice + price.EMIInsuranceBasePrice);
		}
		else if (price.FrequencyUnit == 'YEAR') {
			if (selectedPlan.EMIDiscount && selectedPlan.EMIDiscount.PaymentType == 'YEARLY') {
				return price.TotalEMIAmount ? selectedPlan.EMIDiscount.DiscountedTotalPlanPrice : selectedPlan.EMIDiscount.DiscountedBasePlanPrice;
			}
			return price.TotalEMIAmount ? price.TotalEMIAmount : (price.EMIServiceBasePrice + price.EMIInsuranceBasePrice);
		}
		return price.TotalEMIAmount ? price.TotalEMIAmount : (price.EMIServiceBasePrice + price.EMIInsuranceBasePrice);
	}

	const getSpecificEMIDiscount = (price) => {
		if (price.FrequencyUnit == 'MONTH') {
			if (selectedPlan.EMIDiscount && selectedPlan.EMIDiscount.PaymentType == 'MONTHLY') {
				return selectedPlan.EMIDiscount.OfferDisplayText;
			}
			return '';
		}
		else if (price.FrequencyUnit == 'YEAR') {
			if (selectedPlan.EMIDiscount && selectedPlan.EMIDiscount.PaymentType == 'YEARLY') {
				return selectedPlan.EMIDiscount.OfferDisplayText;
			}
			return '';
		}
		return '';
	}

	const selectPaymentPlan = (type,value) => {
		setSelectedPyamentType(type)
		setEnableContinuePaymentPlan(true);
		if (type == 'payment_full') {
			// self.repeatPaymentSelected = false;
			// self.PlanPriceBillingConfigID = undefined;
			setPlanPriceBillingConfigID(undefined)
		}
		else {
			// self.PlanPriceBillingConfigID = e.item.price.PlanPriceBillingConfigID;
			setPlanPriceBillingConfigID(value?.PlanPriceBillingConfigID)
		}
	}

	const toggleOffers = () => {
		// $(".offers-in-modal").slideToggle()
		setShowOffersInModal(!showOffersInModal)
	}

	const closeModal = () => {
		setPaymentModal(false);
		setShowConsentModal(false);
	}

	{/** ********** Non-Warranty Flow functions ********** */}

	const nonWarrantyDropdownFunction = (obj) => {
		setSelectedDeviceObj(obj)
		// checkEligibilityBeforePlanSelection && getEligibiltyCheckFields();
	}

	const handleSearchClick = () => {
		let obj = {...filterDataVerifyDevice, ...address}
		setValuesSetOnSearchClicked(obj)
		setProductAccordinClick();
		setPlansVisible(true);
	};

	const nonWarrantySellNowClick = (index, selectedPlan) => {
		setSelectedPlan(selectedPlan)
		// getCountries();
		getSinglePlanDetailsWithBrand(selectedPlan);
		setVisiblePlanIndex(visiblePlanIndex === index ? null : index);
	};

	const onCheckPurchaseEligibilitySuccess = (result,selectedPlan) => {
		let selectedplan = {};
		let productObj = result?.data?.ProductObj;
		if(result?.data?.IsPurchasedAllowed) {
			if (productObj) {
				let deviceId = (productObj?.ProductID || selectedDeviceObj?.deviceId);
				let obj = selectedPlan;
				obj.ProductID = deviceId;
				selectedplan = obj;
				let dateOfPurchase = (productObj?.DateOfPurchase || selectedDeviceObj?.dateOfPurchase);

				if(dateOfPurchase) {
					let planPriceArrayFromPurchaseEligibility = result.data && result.data.PlanPrice && result.data.PlanPrice;
					let planDiscountsFromPurchaseEligibility = result.data && result.data.PlanPrice && result.data.PlanDiscounts;
					let planPriceId = result.data && result.data.PlanPrice && result.data.PlanPrice.PlanPriceID;
					setWarrantyDOPFlow({...warrantyDOPFlow, 
						selectPlanPriceArrayFromPurchaseEligibility: planPriceArrayFromPurchaseEligibility,
						selectPlanDiscountsFromPurchaseEligibility: planDiscountsFromPurchaseEligibility,
						selectPlanPriceId: planPriceId
					})
					setSelectedPlan(selectedplan);
					if(result.data.Disclaimer){
						showDisclaimer(result.data);
					}else{
						// checkPaymentPlan(selectedplan); // checkPaymentPlan calling from useEffect bcoz, values was not updating in checkPaymentPlan function
						setSelfUpdating(true);
					}
				} else {
					/** OverridenDateOfPurchase Flow is not used on PROD  */
					// self.showOverrideDateOfPurchaseOption(true);
				}
			} else {
				Swal.fire({
					icon: "error",
					text: localString('Sorry, your product could not be validated, please contact admin for support.'),
				})
			}
		} else if(result.msg) {
			Swal.fire({
				icon: 'error',
				text: localString(displayError(result)),
			})
		} else {
			Swal.fire ({
				title: localString('Device not eligible'),
				// html: '<p>'+ localString('Device is not eligible for') + '  ' + (self.selectedPlan.PlanDisplayName || self.selectedPlan.PlanName) + '.</p>',
				icon: 'info',
				showConfirmButton: true,
				confirmButtonColor: '#4c10c1',
				showCancelButton: true,
				confirmButtonText: localString('View Plans')
			});
		}
	};

	const checkGroupEligibilityFields = (currentPlanObj) => {
		/**  since exceptional flow is not valid now so if block is also invalid */
			// if(exceptionalFlow && currentPlan && currentPlan.planConfigDetails && currentPlan.planConfigDetails.ExceptionFlowEligibilityFields) {
			//     operateGroupEligibilityFields(currentPlan.planConfigDetails.ExceptionFlowEligibilityFields);
			// } else 

		if(currentPlanObj && currentPlanObj.planConfigDetails && currentPlanObj.planConfigDetails.GroupEligibilityFields) {
			operateGroupEligibilityFields(currentPlanObj.planConfigDetails.GroupEligibilityFields);
		}

		// if(showCheckEligibilitySection && !queryRenewalPlanCode ) {
		//     // goToVerifyDetailsSection();
		// }else if (queryRenewalPlanCode){
		//     let getProductName = () => {
		//         return currentPlan && currentPlan.productDetails && (currentPlan.productDetails.ProductName || (currentPlan.productDetails.product && currentPlan.productDetails.product.ProductName));
		//     };
		// }
		// else {
		//     checkPlanPriceDetailsForPlan();
		// }
		// checkEligibiltyValues();
	};

	const operateGroupEligibilityFields = (GroupEligibilityFields) => {
		// let GroupEligibilityFields = GroupEligibilityFields;
		setNonWarrantyEligibleFields(GroupEligibilityFields);
		let obj = {};
		if(GroupEligibilityFields) {
			forEach(GroupEligibilityFields, function(field) {
				if(field === 'ProductUniqueID' || field === 'imeiOrSerial') {
					obj.fieldEligibleProductUniqueID = true;
					if(warrantyApiAvail) {
						obj.isImeiSerialValueValid = true;
						// obj.imeiOrSerial = queryImeiOrSerial;
					}
				}
				if(field === 'ProductPurchaseCost') {
					obj.fieldEligibleProductPurchaseCost = true;
				}
				if(field === 'RepairDate') {
					obj.fieldEligibleRepairDate = true;
				}
				if(field === 'WarrantyEndDate') {
					obj.fieldEligibleWarrantyEndDate = true;
				}
				if(field === 'DateOfPurchase') {
					obj.fieldEligibleDateOfPurchase = true;
					// if(self.dateOfPurchase && moment(self.dateOfPurchase, 'YYYY-MM-DD HH:mm:ssZ') !== 'Invalid Date') {
					//     self.isDateOfPurchaseValid = true;
					//     self.disableDateOfPurchase = true;
					// } else {
					//     self.dateOfPurchase = null;
					// }
				}
				if(field === 'BirthDate') {
					obj.fieldEligibleDateOfBirth = true;
				}
				if(field === 'PlanPurchaseDate') {
					obj.fieldEligiblePurchaseDate = true;
				}
				if(field === 'Remarks') {
					obj.fieldEligibleRemarks = true;
				}
				if(field === 'DeviceSKU') {
					obj.fieldEligibleDeviceSKU = true;
					getDeviceSKUList(); // API Call for dropdown
				}
			});
			let showCheckEligibilitySection = 
				(!obj.isImeiSerialValueValid && obj.fieldEligibleProductUniqueID) 
				||  (!obj.isDateOfPurchaseValid && obj.fieldEligibleDateOfPurchase) 
				||  obj.fieldEligibleProductPurchaseCost 
				||  obj.fieldEligibleDateOfBirth 
				||  obj.fieldEligibleRepairDate 
				||  obj.fieldEligibleWarrantyEndDate 
				||  obj.fieldEligibleDeviceSKU;
		}
		setEligibilityFieldsCheck(obj);
	};

	const showDisclaimer = (result, disclaimerFlag) => {
		let disclaimerText = disclaimerFlag ? result.PlanConfig.Disclaimer.description : result.Disclaimer.description;
		Swal.fire({
			title: '',
			text: localString(disclaimerText),
			icon: 'info',
			showConfirmButton: true,
			confirmButtonColor: '#4c10c1',
			confirmButtonText: localString('Understood')
		}).then ((swalResult)=>{
			if(swalResult.isConfirmed){
				if (result?.PlanConfig?.RequiredDiagnosticTestsForPurchase?.length > 0 && result?.PlanConfig?.RequiredDiagnosticTestsForPurchase?.indexOf('DeviceVideo') > -1) {
					Swal.fire({
						title: '',
						icon: "error",
						text: localString("Video verification is required for activating this plan, please enrol using Servify mServ App"),
						type: 'error',
						showConfirmButton: true,
						confirmButtonColor: '#4c10c1',
						confirmButtonText: localString('OK')
					}).then((swalRes)=>{
						if(swalRes.isConfirmed){
							window.location.reload();
						}
					})
				}
				else {
					// onCheckPurchaseEligibilitySuccess(result);
					// goToOrderDetails(result)
					checkPaymentPlan(result)
				}
			}
		})
	}

	const goToOrderDetails = (plan, selectedProductID) => {
		let finalObj = {};
		if(checkEligibilityBeforePlanSelection?.LG_checkEligibilityBeforePlanSelection || checkEligibilityBeforePlanSelection.Dell_checkEligibilityBeforePlanSelection) {
			finalObj = valuesSetOnSearchClicked;
		}else {
			finalObj = filterDataVerifyDevice;
		}
		let planId = plan ? plan.PlanID : selectedPlan.PlanID;
		let planPriceArray = plan ? plan.PlanPriceArray : selectedPlan?.PlanPriceArray;
		let planPriceId = plan ? plan.PlanPriceID : selectedPlan?.PlanPriceID;
		let productID =  (selectedProductID || selectedDeviceObj?.ProductID || selectedDeviceObj.productID || plan.ProductID || selectedPlan.ProductID);
		let url = baseUrl + orderDetailsUrl + '?planId=' + planId +
			(planPriceArray?.length > 0 ? ('&planPriceId=' + planPriceId) : '') +
			(finalObj?.inputImeiOrSerial ? '&serial=' + encrypt(finalObj.inputImeiOrSerial) : '') +
			(finalObj?.eligiblityProductPurchaseCost ? '&productPurchaseCost=' + encrypt(finalObj.eligiblityProductPurchaseCost) : '') +
			(productID ? ('&productId=' + encrypt(productID)) : '') +
			(selectedDeviceObj?.productSkuModelID ? ('&productSkuModelID=' + encrypt(selectedDeviceObj.productSkuModelID)) : '') +
			(brandObj ? '&brandId=' + encrypt(brandObj.BrandID) : '') +
			(subCategoryObj ? '&subcat=' + encrypt(subCategoryObj.ProductSubCategoryID) : '') +
			// (self.dateOfPurchaseOverriden ? '&dateOfPurchase=' + encrypt(moment(self.dateOfPurchase,'YYYY-MM-DD').format('YYYY-MM-DD')) : '') +
			(dateOfPurchaseOverriden ? '&dateOfPurchaseOverriden=true' : '&dateOfPurchaseOverriden=false') +
			(warrantyApiAvail ? '&warranty=true': '') +  //need to verify ===> && !showExceptionalSales
			// (self.showExceptionalSales? '&exception=true': '') + //this flow removed from 360
			(paymentPlanOptionsAvailable ? '&repeat=true' : '&repeat=false') +
			((plan?.PlanPriceBillingConfigID || planPriceBillingConfigID) ? '&ppbcid='+ (plan?.PlanPriceBillingConfigID || planPriceBillingConfigID) : '') +
			(finalObj?.productPurchaseDate ? '&dateOfPurchase=' + encrypt(getLocalDateTime(finalObj?.productPurchaseDate,'YYYY-MM-DD')) : '') +
			(finalObj?.identifierValue ? '&deviceSKUIdentofierValue=' + (finalObj?.identifierValue) : '') +
			(finalObj?.deviceIdentifierID ? '&deviceIdentifierID=' + (finalObj?.deviceIdentifierID) : '') +
			(finalObj?.dateOfBirthPicker ? '&dateOfBirth=' + encrypt(getLocalDateTime(finalObj?.dateOfBirthPicker,'YYYY-MM-DD')) : '') +
			(finalObj?.repairDatePicker ? '&repairDate=' + encrypt(getLocalDateTime(finalObj?.repairDatePicker,'YYYY-MM-DD')) : '') +
			(finalObj?.warrantyEndDatePicker ? '&warrantyEndDate=' + encrypt(getLocalDateTime(finalObj?.warrantyEndDatePicker,'YYYY-MM-DD')) : '') +
			(acceptedDataAbroadConsent === true ? '&acceptedDataAbroadConsent=true' : "") +
			(finalObj?.Address ? '&Address=' + finalObj.Address : '' ) +
			(finalObj?.City ? '&City=' + finalObj.City : '' ) +
			(finalObj?.Country ? '&Country=' + finalObj.Country : '' ) +
			(finalObj?.Landmark ? '&Landmark=' + finalObj.Landmark : '' ) +
			(finalObj?.Lat ? '&Lat=' + finalObj.Lat : '' ) +
			(finalObj?.Lng ? '&Lng=' + finalObj.Lng : '' ) +
			(finalObj?.PinCode ? '&PinCode=' + finalObj.PinCode : '' ) +
			(finalObj?.State ? '&State=' + finalObj.State : '' ) +
			(finalObj?.UserLandmark ? '&UserLandmark=' + finalObj.UserLandmark : '' ) +
			(PSLData?.PartnerServiceLocationID ? '&pslid='+ PSLData?.PartnerServiceLocationID : '');

		window.location.assign(url);
	};

	const checkPaymentPlan = (param) => {
		let planSelected = param ? param : selectedPlan;
		let PPBillingConfig = null;
		if ((!planSelected.PlanPriceArray || (planSelected.PlanPriceArray && planSelected.PlanPriceArray.length == 0)) && warrantyDOPFlow?.selectPlanPriceArrayFromPurchaseEligibility) {
			planSelected.PlanPriceArray = [warrantyDOPFlow.selectPlanPriceArrayFromPurchaseEligibility];
			planSelected.PlanPriceID = warrantyDOPFlow.selectPlanPriceArrayFromPurchaseEligibility.PlanPriceID ;
		}
		if (warrantyDOPFlow?.selectPlanDiscountsFromPurchaseEligibility) {
			planSelected.PlanDiscounts = warrantyDOPFlow.selectPlanDiscountsFromPurchaseEligibility;
			setSelectedPlan(planSelected);
			setSpecificDiscounts(planSelected)
		}
		if (planSelected?.PlanPriceArray?.length > 0 && (planSelected.PlanPriceArray[0]?.TotalPrice || (planSelected.PlanPriceArray[0]?.ServiceBasePrice + planSelected.PlanPriceArray[0]?.InsuranceBasePrice)) && planSelected.PlanPriceArray[0]?.planPriceBillingConfig && planSelected.PlanPriceArray[0].UpfrontPaymentAllowed) {
			PPBillingConfig = planSelected?.PlanPriceArray[0]?.planPriceBillingConfig;
			setPlanPriceBillingConfig(PPBillingConfig)
			if (paymentPlanSelected) {
				setPaymentPlanOptionsAvailable(true);
				goToOrderDetails(planSelected);
			}else {
				setPaymentPlanOptionsAvailable(true);
				setPaymentModal(true);
			}
		}else {
			setPaymentPlanOptionsAvailable(false);
			if (planSelected.PlanPriceArray && planSelected.PlanPriceArray.length > 0 && planSelected.PlanPriceArray[0].planPriceBillingConfig) {
				PPBillingConfig = planSelected.PlanPriceArray[0].planPriceBillingConfig;
				let PPBillingConfigID = PPBillingConfig[0].PlanPriceBillingConfigID;
				planSelected.PlanPriceBillingConfigID = PPBillingConfigID;
				setPlanPriceBillingConfigID(PPBillingConfigID)
			}
			if (planSelected?.PlanPriceArray && planSelected?.PlanPriceArray?.length > 0) {
				goToOrderDetails(planSelected);
			}
			else {
				Swal.fire({
					icon: "error",
					text: localString('Sorry, we were unable to fetch the plan price against provided details'),
					confirmButtonText: localString("Okay")
				})
			}
		}
	}

	const setSpecificDiscounts = (plan) => {
		let planSelected = plan || selectedPlan;
		if (planSelected.PlanDiscounts && planSelected.PlanDiscounts.PlanDiscountOffers && planSelected.PlanDiscounts.PlanDiscountOffers.length != 0) {
			if (!planSelected.PlanPriceArray || planSelected.PlanPriceArray.length != 1) {
				planSelected.showViewAllOffers = true;
			}
			else {
				if (planSelected.PlanDiscounts.PlanDiscountOffers.length == 1) {
					if (planSelected.PlanDiscounts.PlanDiscountOffers[0].requiredFieldsPresent && planSelected.PlanDiscounts.PlanDiscountOffers[0].DiscountCategory != "PromoCode") {
						if (planSelected.PlanDiscounts.PlanDiscountOffers[0].PaymentType == 'UPFRONT') {
							planSelected.simpleDiscount = planSelected.PlanDiscounts.PlanDiscountOffers[0];
						} else if (['MONTHLY','YEARLY'].indexOf(planSelected.PlanDiscounts.PlanDiscountOffers[0].PaymentType) > -1) {
							planSelected.EMIDiscount = planSelected.PlanDiscounts.PlanDiscountOffers[0];
						}
						planSelected.showViewAllOffers = false;
					}
					else {
						planSelected.showViewAllOffers = true;
					}
				}
				else if (planSelected.PlanDiscounts.PlanDiscountOffers.length == 2) {
					if (planSelected.PlanDiscounts.PlanDiscountOffers[0].requiredFieldsPresent && planSelected.PlanDiscounts.PlanDiscountOffers[0].DiscountCategory != "PromoCode" && planSelected.PlanDiscounts.PlanDiscountOffers[1].requiredFieldsPresent && planSelected.PlanDiscounts.PlanDiscountOffers[1].DiscountCategory != "PromoCode") {
						if (planSelected.PlanDiscounts.PlanDiscountOffers[0].PaymentType == 'UPFRONT' && ['MONTHLY','YEARLY'].indexOf(planSelected.PlanDiscounts.PlanDiscountOffers[1].PaymentType) > -1) {
							planSelected.simpleDiscount = planSelected.PlanDiscounts.PlanDiscountOffers[0];
							planSelected.EMIDiscount = planSelected.PlanDiscounts.PlanDiscountOffers[1];
							planSelected.showViewAllOffers = false;
						}
						else if (planSelected.PlanDiscounts.PlanDiscountOffers[1].PaymentType == 'UPFRONT' && ['MONTHLY','YEARLY'].indexOf(planSelected.PlanDiscounts.PlanDiscountOffers[0].PaymentType) > -1) {
							planSelected.EMIDiscount = planSelected.PlanDiscounts.PlanDiscountOffers[0];
							planSelected.simpleDiscount = planSelected.PlanDiscounts.PlanDiscountOffers[1];
							planSelected.showViewAllOffers = false;
						}
						else {
							planSelected.showViewAllOffers = true;
						}
					}
					else {
						planSelected.showViewAllOffers = true;
					}
				}
				else {
					planSelected.showViewAllOffers = true;
				}
			}
		}
		setSelectedPlan(planSelected)
	}

	const continuePaymentPlan = () => {
		setPaymentPlanSelected(true);
		checkPaymentPlan();
	}

	const checkIfConsentRequiredForNonWarrantyFlow = () => {
		if(dataAbroadConsent?.legalConfig?.LegalConfigCode === "DATA_ABROAD_360") {
			setShowConsentModal(true);
		} else {
			onSellNowClick();
		}
	}

	const onOtpSuccessNonWarrantyFlow = function() {
		setAcceptedDataAbroadConsent(true);
		onSellNowClick();
	};

	{/** ********** Non-Warranty Flow functions ********** */}

	{/** ********** API Calls ********** */}

	const fetchEligiblePlans = () => {
		const planIds = qFilter.plans && qFilter.plans.length > 0 ? qFilter.plans : null;
		let data = {
			"BrandID": brandObj.BrandID,
			// "getSuperGroupedPlans": 1, // commented in existing 
			"EntityType": "Product",
			"PlanFilter": planIds,
			"ProductID": selectedDeviceObj.ProductID,
			"ProductSubCategoryID": subCategoryObj && subCategoryObj.ProductSubCategoryID,
			"PartnerServiceLocationID": PSLData.PartnerServiceLocationID,
			"PartnerID": PSLData.PartnerID,
			"CountryCode" : qFilter.countrycode ? qFilter.countrycode[0]: undefined,
			"salesChannel": qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		}

		setLoader(true);
		apiCall({
			url: 'fetchEligiblePlans',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let allPlans = result.data;
					if(allPlans.length == 0) {
						setCheckFlags({...checkFlags, planLengthCheck: true})
					}

					let protectPlansArr = [];
					let securePlansArr = [];

					forEach(allPlans, function (groupPlan) {
						forEach(groupPlan.PlanObject, function (plan) {
							if (plan.PlanType == 'Protect' && plan.PlanCode != 'SCPEW1Y') {
								protectPlansArr.push(plan)	
							}
							else if (plan.PlanType == 'Secure' || (plan.PlanCode && plan.PlanCode == 'SCPEW1Y')) {
								securePlansArr.push(plan)	
							}
						});
					});
					protectPlansArr = uniqBy(protectPlansArr, 'PlanCode');
					securePlansArr = uniqBy(securePlansArr, 'PlanCode');
					setProtectPlans(protectPlansArr);
					setSecurePlans(securePlansArr);
					setDiscounts(protectPlansArr);
					setDiscounts(securePlansArr);
					setCheckFlags({...checkFlags, showSalePlan: true});
				}else {
					Swal.fire({  
						icon: "error",
						title: localString("Error"),
						text: localString(displayError(result))
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	};

	const getDevicePlansFromIMEI = () => {
		if (brandObj && brandObj.WarrantyCheckDetails) {
			let data = {
				BrandID: brandObj.BrandID,
				imeiOrSerial: filterDataVerifyDevice.inputImeiOrSerial,
				getProductObject: 1,
				CountryCode : qFilter.countrycode ? qFilter.countrycode[0]: undefined,
				salesChannel: qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp",
				PartnerServiceLocationID: PSLData.PartnerServiceLocationID,
				PartnerID: PSLData.PartnerID
			};

			setLoader(true);
			apiCall({
				url: 'getDevicePlansFromImei',
				data: data,
				callBack: (result) => {
					setLoader(false);
					setLimitationOnAPICall(true);
					if(result.success && result.data && result.data.ProductObj) {
						let productObj = result.data.ProductObj;

						if(productObj && (productObj.ProductSubCategoryID != (subCategoryObj && subCategoryObj.ProductSubCategoryID))) {
							Swal.fire({
								title: '',
								text: localString('The product’s serial number / IMEI has been validated and it seems to belong to '+ (productObj.ProductSubCategory ? productObj.ProductSubCategory : 'a different') +' category. Please note that the plans will be suggested based on this validation.'),
								icon: 'info',
								showConfirmButton: true,
								confirmButtonText: 'Ok',
								confirmButtonColor: '#4c10c1'
							}).then ((res) => {
								if(res.isConfirmed){
									let currentSubcategory = find(subCategoryList, {ProductSubCategoryID: productObj.ProductSubCategoryID});
									setSubCategoryObj(currentSubcategory);
									onGetDevicePlansFromImeiSuccess(result);
								}});
						} else {
							onGetDevicePlansFromImeiSuccess(result);
						}
					}else {
						// self.updatePlans(self.warrantyApiAvail);
						Swal.fire({  
							icon: "error",
							title: localString("Error"),
							text: localString(displayError(result))
						}); 
					}
				},
				errorCB: (err) => setLoader(false)
			});
		}
	};

	const getPlanProducts = () => {
		let data = {
			"BrandID": brandObj.BrandID,
			"ProductSubCategoryID": subCategoryObj && subCategoryObj.ProductSubCategoryID,
			"EntityType": "Product",
			"CountryCode" : qFilter.countrycode ? qFilter.countrycode[0]: undefined,
			"salesChannel": qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		};

		setLoader(true);
		apiCall({
			url: 'getFilteredPlanProducts',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let productListArr = result?.data && result.data.map(v => ({...v, value: v.ProductName})) ;
					setNonWarrantyDeviceList(productListArr)
					// self.tags['sale_plans'].showProductsList()
					// setProductAccordinClick();   //called on search button click only
				}else {
					Swal.fire({  
						icon: "error",
						title: localString("Error"),
						text: localString(displayError(result))
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	};

	const setProductAccordinClick = () => {
		// self.apiCalled = false;
		// self.deviceId = undefined;
		// if(validProductID && !(self.planData && self.planData.ProductObj && self.planData.ProductObj.ModelUnverified)) {
		//     self.parent.fetchEligiblePlans();
		//     self.showproductTextBox = false;
		//     self.update();
		// } else {
		//      if(!self.apiCalled) {
		//          self.apiCalled = true
		//      }
		// }


		// if(validProductID && !(self.planData && self.planData.ProductObj && self.planData.ProductObj.ModelUnverified)) {
		//     fetchEligiblePlans();
		//     setCheckFlags({...checkFlags, showproductTextBox: false});
		// }else {

			let data = { 
				"ProductID": selectedDeviceObj.ProductID,
				"BrandID": brandObj.BrandID,
				"ProductSubCategoryID": subCategoryObj && subCategoryObj.ProductSubCategoryID,
				"PartnerServiceLocationID": PSLData.PartnerServiceLocationID,
				"PartnerID": PSLData.PartnerID,
				"CountryCode": qFilter.countrycode ? qFilter.countrycode[0] : undefined,
				"salesChannel": qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp",
			}
			if(filterDataVerifyDevice?.productPurchaseDate || filterDataVerifyDevice?.eligiblityProductPurchaseCost || filterDataVerifyDevice?.inputImeiOrSerial){
				data.DateOfPurchase = filterDataVerifyDevice.productPurchaseDate ?  filterDataVerifyDevice.productPurchaseDate : undefined;
				data.ProductPurchaseCost = filterDataVerifyDevice.eligiblityProductPurchaseCost ? filterDataVerifyDevice.eligiblityProductPurchaseCost : undefined;
				data.ProductUniqueID = filterDataVerifyDevice.inputImeiOrSerial ? filterDataVerifyDevice.inputImeiOrSerial : undefined;
			}
			if(address?.PinCode || address?.Zipcode){
				data.Zipcode = address.PinCode || address.Zipcode;
			}
			setLoader(true);
			apiCall({
				url: 'fetchEligiblePlans',
				data: data,
				callBack: (result) => {
					setLoader(false);
					if(result.success && result.data) {
						let res = result.data;
						let filteredPlans = [];
						if(checkEligibilityBeforePlanSelection.LG_checkEligibilityBeforePlanSelection || checkEligibilityBeforePlanSelection.Dell_checkEligibilityBeforePlanSelection){
							res.map(a=> {
								if(a.PlanObject) {
									a.PlanObject.filter(data => {
										if(data?.PlanPriceArray && data?.PlanPriceArray.length > 0) {
											filteredPlans.push(data);
										}
									})
								}
							})
						}else {
							res.map(a => {
								if(a.PlanObject) {
									forEach(a.PlanObject, function (obj) {
										filteredPlans.push(obj)
									});
								}
							})
						}
						
						setDisplayPlanList(filteredPlans)
						setDiscounts(filteredPlans);
					}else {
						Swal.fire({  
							icon: "error",
							title: localString("Error"),
							text: localString(displayError(result))
						}); 
					}
				},
				errorCB: (err) => setLoader(false)
			});
		// }
	};

	// const getCountries = (countryCode) => {
	// 	let data = {
	// 		countryConfig: {},
	// 	};
	// 	if(countryCode){
	// 		data.specificCountryCode = countryCode;
	// 	}
	// 	setLoader(true);
	// 	apiCall({
	// 		url: "getCountries",
	// 		data: data,
	// 		callBack: (result) => {
	// 			setLoader(false);
	// 			if (result.success && result.data && result.data.countries) {
	// 				let countryData = result.data.countries;
	// 				let currencySymbol = countryData && countryData[0]?.currencies[0]?.CurrencySymbol;
	// 				setUserCurrencySymbol(currencySymbol)
	// 			} else {
	// 				Swal.fire({
	// 					icon: "error",
	// 					text: localString(displayError(result)),
	// 				});
	// 			}
	// 		},
	// 	});
	// };

	const getSinglePlanDetailsWithBrand = (selectedPlan) => {
		let data = {
			PlanID: selectedPlan?.PlanID,
			IncludeField: {
				FieldName: "Brand",
				FieldValue: [brandObj.BrandID],
			},
			CountryCode: qFilter.countrycode
				? qFilter.countrycode[0]
				: undefined,
			salesChannel:
				qFilter?.salesLocation
					? qFilter.salesLocation
					: "WebApp",
		};
		setLoader(true);
		apiCall({
			url: "fetchPlanDetails",
			data: data,
			callBack: (result) => {
			setLoader(false);
				if (result.success) {
					if (result.data && 
						result.data.EntityDetails && 
						result.data.EntityDetails[0]
					) {
						let brandDetails = result.data.EntityDetails[0];
						setBrandDetails(brandDetails);
						if (brandDetails?.WarrantyCheckDetails) {
							if (brandDetails?.WarrantyCheckDetails?.AllowedValues?.length > 0) {
								let valuesAllowed = brandDetails.WarrantyCheckDetails.AllowedValues;
								setAllowedValues(valuesAllowed);
								setIMEISerialValidations(valuesAllowed);
							} else {
								setDefaultIMEISerialValidations();
							}
						} else {
							setDefaultIMEISerialValidations();
						}
					}
					getSinglePlanDetails(selectedPlan);
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getSinglePlanDetails = (selectedPlan) => {
		let data = {
			PlanID: selectedPlan?.PlanID,
			BrandID: brandObj.BrandID,
			PlanPriceID: selectedPlan?.PlanPriceArray?.length > 0 ? selectedPlan.PlanPriceID : undefined,
			IncludeField: {
				FieldName: "Product",
				FieldValue: [selectedDeviceObj.ProductID],
			},
			CountryCode: qFilter.countrycode
				? qFilter.countrycode[0]
				: undefined,
			salesChannel:
				qFilter?.salesLocation
					? qFilter.salesLocation
					: "WebApp",
		};
		setLoader(true);
		apiCall({
			url: "fetchPlanDetails",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					let currentPlanObj = {};
					currentPlanObj.planDetails = result?.data?.PlanDetails;
					currentPlanObj.planConfigDetails = result?.data?.PlanConfigDetails;
					currentPlanObj.planDiscountDetails = result?.data?.PlanDiscounts?.PlanDiscountOffers;
					// setDiscounts();
					if(!warrantyApiAvail && result.data.EntityDetails && result.data.EntityDetails[0]) {
						// self.currentPlan.productDetails = result.data.EntityDetails[0];
						currentPlanObj.productDetails = result.data.EntityDetails[0];
					}
					setCurrentPlan(currentPlanObj);
					// if(result.data.EntityDetails && result.data.EntityDetails[0] && result.data.EntityDetails[0].ProductSubCategoryID) {
					//     productSubCategoryID = result.data.EntityDetails[0].ProductSubCategoryID;
					// }

					if(!warrantyApiAvail && !queryRenewalPlanCode) { // !queryRenewalPlanCode this is related to renewal flow 
						checkGroupEligibilityFields(currentPlanObj);
					} else {
						// self.getPlansFromImei();
					}
					getRequiredFieldsForPurchase(currentPlanObj)
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getRequiredFieldsForPurchase = (currentPlan) => {
		let data = {
			"PlanID": currentPlan && currentPlan.planDetails && currentPlan.planDetails.PlanID, 
			"ProductSubCategoryID": subCategoryObj && subCategoryObj.ProductSubCategoryID,
			"CountryCode" : qFilter.countrycode ? qFilter.countrycode[0]: undefined,
			"salesChannel": qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp"
		};
		setLoader(true);
		apiCall({
			url: "getPlanPurchaseConfigByProductSubcategory",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					let currentPlanObj = {...currentPlan};
					currentPlanObj.planConfigDetails.RequiredFieldsForPurchase = result.data && result.data.RequiredFieldsForPurchase;
					setCurrentPlan(currentPlanObj)
					if(currentPlanObj.planConfigDetails && currentPlanObj.planConfigDetails.RequiredFieldsForPurchase) {
						// operateRequiredFieldsForPurchase(currentPlanObj.planConfigDetails.RequiredFieldsForPurchase);
					} 
					// else if(isDateOfPurchaseOverriden) {
					//     operateRequiredFieldsForPurchase([{
					//         FieldName : "DeviceInvoice",
					//         Optional : false
					//     }]);
					// }
				} 
			// Need to verify 
				// else {
				//     Swal.fire({
				//         icon: "error",
				//         text: localString(displayError(result)),
				//     });
				// }
			},
		});
	};

	const getEligibiltyCheckFields = () => {
		let data = {
			"app": "WebApp",
			"ProductSubCategoryID" : subCategoryObj.ProductSubCategoryID,
			"BrandID" : brandObj.BrandID,
			"CountryID" : countriesData?.CountryID,
		};
		setLoader(true);
		apiCall({
			url: "getEligibiltyCheckFields",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					let eligibility = result?.data?.SuperGroupEligibilityCheckFields;
					let SKUCheck = eligibility?.includes("SKUModel");
					SKUCheck ? setNonWarrantySKUModelList([]) : getPlanProducts();
					setCheckEligibleFieldsBeforePlanSelection(eligibility);
					setEligibleCheckFields(result.data)
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getPlanProductSKUModelNos = (inputModelNo) => {
		let data = {
			BrandID: brandObj.BrandID,
			ProductSubCategoryID: subCategoryObj.ProductSubCategoryID,
			CountryID: 157,
			app: "360App",
			ProductSkuModelNo: inputModelNo,
			pagination: {
				"pageNo": 1,
				"range": 3,
				"itemsPerPage": 3
			}
		};
		setLoader(true);
		apiCall({
			url: "getPlanProductSKUModelNos",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					let res = result?.data?.skuModelList.map((v) => ({...v, value: v.productSkuModelName + " | " + v.productSkuName}))
					setNonWarrantySKUModelList(res)
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	}

	const getDeviceSKUList = () => {
		let data = {
			"productID": selectedDeviceObj.ProductID,
			"CountryCode" : qFilter.countrycode ? qFilter.countrycode[0]: undefined,
			"salesChannel": qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp",
			"sourceName": brandObj && brandObj.BrandName
		};
		setLoader(true);
		apiCall({
			url: "getDeviceIdentifierList",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					let filteredDevices = filter(result.data.deviceIdentifierList, {identifierType: "MODELNO"});
					let res = filteredDevices?.map((v) => ({...v, value: v.IdentifierValue }));
					setDeviceSKUDropdownList(res)
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	}

	const checkEligibility = (data,selectedPlan) => {
		if (warrantyApiAvail) {
			data.AlternateUniqueKey = selectedDeviceObj && selectedDeviceObj.AlternateUniqueKey ? selectedDeviceObj.AlternateUniqueKey: undefined;
			setLoader(true);
			apiCall({
				url: "checkPurchaseEligibility",
				data: data,
				callBack: (result) => {
					setLoader(false);
					if (result.success && result.data) {
						if (result.data) {
							let IsPurchasedAllowed = result.data.IsPurchasedAllowed;
							let productObj = result.data.ProductObj;
	
							if(productObj && (productObj.ProductSubCategoryID != (subCategoryObj && subCategoryObj.ProductSubCategoryID))) {
								Swal.fire({
									title: '',
									text: localString('The product’s serial number / IMEI has been validated and it seems to belong to '+ (productObj.ProductSubCategory ? productObj.ProductSubCategory : 'a different') +' category. Please note that the plans will be suggested based on this validation.'),
									icon: 'info',
									showConfirmButton: true,
									confirmButtonText: 'Ok',
									confirmButtonColor: '#4c10c1'
								}).then ((result) =>  {
									if(result.isConfirmed){
										onCheckPurchaseEligibilitySuccess(result,selectedPlan);
									}
								});
							} else {
								onCheckPurchaseEligibilitySuccess(result,selectedPlan);
							}
						}
					} else {
						Swal.fire({
							icon: "error",
							text: localString(displayError(result)),
						});
						// $('#customloader').hide();
						// $('#imeiOrSerial').val('');
						// self.serialNumber = ''
						// swal( '', localString(displayError(result)), 'error' );
					}
				},
			});
		} else {
			checkPaymentPlan();
		}
	};

	const getApplicationLegalConfigs = () => {
		let reqObj = { 
			"CountryCode" : qFilter?.countrycode ? qFilter?.countrycode[0]: undefined,
			"salesChannel": qFilter?.salesLocation ? qFilter?.salesLocation : "WebApp",
			"app" : 'WebApp'
		};
		setLoader(true);
		apiCall({
			url: 'getApplicationLegalConfigs',
			data: reqObj,
			callBack: (result) => {
				setLoader(false);
				if(result.success && result.data) {
					let configData = result.data;
					if(configData.LegalConfigs && configData.LegalConfigs.length > 0) {
						let dataAbroad = configData.LegalConfigs.find((obj) => {
							return obj.legalConfig && (obj.legalConfig.LegalConfigCode == 'DATA_ABROAD_360') && (obj.legalConfig.RequiresOTP == true);
						});
						let agreeTerms = configData.LegalConfigs.find((obj) => {
							return obj.legalConfig && (obj.legalConfig.LegalConfigCode == 'AGREE_TERMS_360');
						});
						let paymentDeclaration = configData.LegalConfigs.find((obj) => {
							return obj.legalConfig && (obj.legalConfig.LegalConfigCode == 'PAYMENT_DECLARATION_360');
						});
						setDataAbroadConsent(dataAbroad)
						setAgreeTermsConsent(agreeTerms)
						setPaymentDeclarationConsent(paymentDeclaration)
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const onSellNowClick = () => {
		let data = {
			"DateOfPurchase": filterDataVerifyDevice.productPurchaseDate ? getLocalDateTime(filterDataVerifyDevice.productPurchaseDate,'YYYY-MM-DD') : undefined,
			"PlanID": selectedPlan?.PlanID,
			"imeiOrSerial": filterDataVerifyDevice.inputImeiOrSerial,
			"RepairDate": filterDataVerifyDevice.repairDatePicker? getLocalDateTime(filterDataVerifyDevice.repairDatePicker,'YYYY-MM-DD'): undefined,
			"WarrantyEndDate": filterDataVerifyDevice.warrantyEndDatePicker? getLocalDateTime(filterDataVerifyDevice.warrantyEndDatePicker,'YYYY-MM-DD'): undefined,
			"ProductPurchaseCost": filterDataVerifyDevice?.eligiblityProductPurchaseCost ? filterDataVerifyDevice?.eligiblityProductPurchaseCost : undefined,
			"BrandID": brandObj.BrandID,
			"warrantyCheckRequired": false, // !!self.exceptionalFlow, //this flow is removed from 360
			"PlanPurchaseDate": filterDataVerifyDevice.purchaseDatePicker? getLocalDateTime(filterDataVerifyDevice.purchaseDatePicker,'YYYY-MM-DD HH:mm:ss'): undefined,
			// "Remarks": filterDataVerifyDevice.eligibleRemarks, // not being use on prod - confirmed by vatsal
			// "ExceptionFlowType": self.exceptionalFlow? 1 : undefined, //this flow is removed from 360
			"CountryCode" : qFilter.countrycode ? qFilter.countrycode[0]: undefined,
			"salesChannel": qFilter && qFilter.salesLocation ? qFilter.salesLocation : "WebApp",
			"ProductID": selectedDeviceObj.ProductID,
			"BirthDate": filterDataVerifyDevice.filterDataVerifyDevice? moment(filterDataVerifyDevice.filterDataVerifyDevice).format('YYYY-MM-DD'): undefined,
		};
		if(warrantyApiAvail && currentPlan && currentPlan.productDetails && currentPlan.productDetails.AlternateUniqueKey) {
			data.AlternateUniqueKey = currentPlan.productDetails.AlternateUniqueKey;
		};
		if(filterDataVerifyDevice?.deviceIdentifierID){
			data.DeviceIdentifierID = filterDataVerifyDevice?.deviceIdentifierID;
		}
		setLoader(true);
		apiCall({
			url: "checkPurchaseEligibility",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					if(result.data.IsPurchasedAllowed) {
						if (result.data.ProductObj && result.data.ProductObj.ProductID != selectedDeviceObj.ProductID) {
							Swal.fire ({
								title:localString("Sorry"),
								html: localString("The IMEI entered belongs to") + " " + result.data.ProductObj.ProductName + ", " + localString("please select the correct model and try again"),
								icon: 'error',
								showConfirmButton:true,
								confirmButtonText: 'OK',
								confirmButtonColor: '#4c10c1'
							}).then((swalResult)=>{
								if(swalResult.isConfirmed){
									//backfunctionality
								}
							})
						}
						else {
							if(result.data.Disclaimer) {
								showDisclaimer(result.data);
							} else {
								// self.onCheckPurchaseEligibilitySuccess(result);
								goToOrderDetails();
							}
						}
					}else{
						Swal.fire ({
							title:localString("Sorry"),
							html: localString(displayError(result)),
							type: 'error',
							showConfirmButton:true,
							confirmButtonText: 'OK',
							confirmButtonColor: '#4c10c1'
						});
					}
				} else {
					Swal.fire({
						title: localString('Sorry'),
						icon: "error",
						text: localString(displayError(result))
					})
					setFilterDataVerifyDevice({...filterDataVerifyDevice,inputImeiOrSerial: ""})
				}
			},
		});
	};
	{/** ********** END API Calls ********** */}
	
	return (
		<>
			{
				(loader) && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<div className="PlanSales-ProductList-main PlanSales-ProductList-mobile-main">
				<PageHeader
					titleHeader={titleHeader}
					localString={localString}
					headerButtonArray={buttonHeader} 
					btnClass='btn button-servify header-btn button-stack text-capital'
					buttonClass= 'title-header'
				/>
				{
					<>
						<div className="content content-wrapper">
							{
								warrantyApiAvail && 
								<div class="">
									<div id="buyplan" class="">
										<div class="row content-margin center">
											<div class="col-sm-12 d-flex align-items-center">
												<Input
													value = {filterDataVerifyDevice.inputImeiOrSerial}
													key = "imeiOrSerial"
													handleOnChange = {(e) => {
														setFilterDataVerifyDevice({...filterDataVerifyDevice, inputImeiOrSerial: e.target.value})
													}}
													labelAlign = 'left'
													className = {{
														formControl: 'form-width',
														containerClass: 'input-container',
														inputClass: 'input'
													}}
													placeholder = {localString(getIMEISerialText())}
												/>
												<Button 
													type = 'button' 
													classes = " btn button-servify col-sm-2 buy-btn pull-right button-servify-solid" 
													handleOnClick = {(e) => {
														checkIfConsentRequired(e)
													}}
													isDisabled = {!validationWarrantyCheckEligibility}
												>
													{localString("Check Eligibility")}
												</Button>
											</div>
										</div>
									</div>
									<div class="divider-gray hidden-xs"> </div>
								</div>
							}
							{/* <div class="divider-gray hidden-xs"> </div> */}
							<div class="nav-tab-outer">
								{/** *********************** NON-Warranty Flow ************************ */}
								<> 
									{
										checkFlags.showproductTextBox && 
										<NonWarrantyFlow
											{...props}
											nonWarrantyDeviceList = {nonWarrantyDeviceList}
											selectedDeviceObj = {selectedDeviceObj} 
											nonWarrantyDropdownFunction = {nonWarrantyDropdownFunction}
											handleSearchClick = {handleSearchClick}
											plansVisible = {plansVisible}
											displayPlanList = {displayPlanList}
											nonWarrantySellNowClick = {nonWarrantySellNowClick}
											visiblePlanIndex = {visiblePlanIndex}
											eligibilityFieldsCheck = {eligibilityFieldsCheck}
											getIMEISerialText = {getIMEISerialText}
											filterDataVerifyDevice = {filterDataVerifyDevice} 
											setFilterDataVerifyDevice = {setFilterDataVerifyDevice}
											warrantyApiAvail = {warrantyApiAvail} 
											onSellNowClick = {onSellNowClick}
											checkEligibilityBeforePlanSelection = {checkEligibilityBeforePlanSelection}
											getEligibiltyCheckFields = {getEligibiltyCheckFields}
											checkEligibleFieldsBeforePlanSelection = {checkEligibleFieldsBeforePlanSelection}
											goToOrderDetails = {goToOrderDetails}  nonWarrantySKUModelList = {nonWarrantySKUModelList}
											SKUModelCheck = {SKUModelCheck} setSKUSearchText = {setSKUSearchText}
											disableSearch = {disableSearch} userCurrencySymbol = {userCurrencySymbol}
											showPlanPriceSimple = {showPlanPriceSimple}
											deviceSKUDropdownList = {deviceSKUDropdownList}
											noDataMessage = {noDataMessage}
											checkIfConsentRequiredForNonWarrantyFlow = {checkIfConsentRequiredForNonWarrantyFlow}
											eligibilityValidation = {eligibilityValidation}
											showDiscountPriceSimple = {showDiscountPriceSimple}
											showUnDiscountedPriceSimple = {showUnDiscountedPriceSimple}
											pincodeCheck = {pincodeCheck} 
											address = {address} setAddress = {setAddress}
											countriesData = {countriesData}
											errors = {errors} setErrors = {setErrors}
											addressConfigArr = {addressConfigArr} setAddressConfigArr = {setAddressConfigArr}
											eligibleCheckFields = {eligibleCheckFields}
											areAllValuesEmpty = {areAllValuesEmpty}
										/>
									}
								</>

								{/** *********************** Warranty Flow *********************** */}
								<>
									{
										!checkFlags.showproductTextBox && 
										<>
											<WarrantyFlow
												{...props}
												checkFlags = {checkFlags} showPlanTypes = {showPlanTypes}
												planTypes = {planTypes} selectedPlanType = {selectedPlanType}
												headers = {headers} plans = {plans} selectPlanId = {selectPlanId}
												showUnDiscountedPriceSimple = {showUnDiscountedPriceSimple}
												showPlanPriceSimple = {showPlanPriceSimple}
												showDiscountPriceSimple = {showDiscountPriceSimple}
												showRecurringAmount = {showRecurringAmount} 
												showRecurringFrequency = {showRecurringFrequency}
												checkIfDiscountedPriceToBeShown = {checkIfDiscountedPriceToBeShown}
												showDiscountedRecurringAmount = {showDiscountedRecurringAmount}
												showDiscountPriceEMI = {showDiscountPriceEMI}
												sellNowWarranty = {sellNowWarranty}
												setSelectedPlanType = {setSelectedPlanType} setTabHeaders = {setTabHeaders}
												selectedHeader = {selectedHeader} selectTab = {selectTab}
												warrantyApiAvail = {warrantyApiAvail} tabs = {tabs}
												setShowConsentModal = {setShowConsentModal}
											/>
										</>
									}
								</>
							</div>
						</div>
					</>
				}
				{
					paymentModal && 
					<PaymentModal
						{...props}
						selectedPlan = {selectedPlan}
						paymentModal = {paymentModal}
						planPriceBillingConfig = {planPriceBillingConfig}
						checkIfSpecificEMIDiscountedPriceToBeShown = {checkIfSpecificEMIDiscountedPriceToBeShown}
						getSpecificEMIDiscountedPrice = {getSpecificEMIDiscountedPrice}
						getSpecificEMIDiscount = {getSpecificEMIDiscount}
						showUnDiscountedPriceSimple = {showUnDiscountedPriceSimple}
						showPlanPriceSimple = {showPlanPriceSimple}
						showDiscountPriceSimple = {showDiscountPriceSimple}
						selectedPyamentType = {selectedPyamentType} 
						setSelectedPyamentType = {setSelectedPyamentType}
						selectPaymentPlan = {selectPaymentPlan} continuePaymentPlan = {continuePaymentPlan}
						enableContinuePaymentPlan = {enableContinuePaymentPlan}
						toggleOffers = {toggleOffers} showOffersInModal = {showOffersInModal}
						closeModal = {closeModal}
					/>
				}
				{
					showConsentModal && 
					<PlanSalesConsent
						{...props}
						showConsentModal = {showConsentModal} 
						closeModal = {closeModal} 
						dataAbroadConsent = {dataAbroadConsent}
						onOtpSuccess = {checkFlags.showproductTextBox ? onOtpSuccessNonWarrantyFlow  : onOtpSuccess}
					/>
				}
			</div>
		</>
	)
}

export default ProductList