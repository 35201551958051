import React, { useEffect, useState } from "react";
import { Loader, Button } from 'sfy-react';
import ReactDOM from 'react-dom';
import Swal from "sweetalert2";
import moment from "moment";
import PlanRenewalGrid from "./PlanRenewalGrid";
import { displayError, checkEmail, checkMobileNumber } from "../../Utility/Utils";
import './PlanRenewal.scss';
import { useSelector} from 'react-redux';
import CustomDateRangePicker from "../../components/DateRangePicker/DateRangePicker";

const PlanRenewal = (props) => {
	const { accessFn, localString, publicUrl, apiCall, baseUrl } = props;
	if(!accessFn('ENABLE_RENEWAL')) {
		window.location.href = '/401';
	}
	
	const qFilter = JSON.parse(props.qFilter)
	const { dateRange, selectedPSL } = useSelector(
		(state) => state.PlanSalesDashboard
	);
	const [loader, setLoader] = useState(false);
	const [consumerRenewals, setConsumerRenewals] = useState([]);
	const [countryCodeArr, setCountryCodeArr] = useState(qFilter.countrycode || []);
	const [country, setCountry] = useState(countryCodeArr.length ? countryCodeArr[0].toLowerCase() : 'in');
	const [selectedCountryData, setSelectedCountryData] = useState(null);
	const [resetDateRange, setResetDateRange] = useState(false);
	const [mobileSearchInput, setMobileSearchInput] = useState('');
	const [emailIDSearchInput, setEmailIDSearchInput] = useState('');

	const [filterData, setFilterData] = useState({
		MobileNo: "",
		EmailID: "",
		StartDate: dateRange?.startDate ?? "",
		EndDate: dateRange?.endDate ?? "",
	});
	const [pagination , setPagination] = useState({
		pageNo : 1,
		itemsPerPage : 10,
		count: 0,
		numberOfPages: 0
	});

	const resetPagination = () => {
		setPagination({
			...pagination,
			pageNo: 1, // Reset the page number to the first page
		  });
	};

	const dropDownOptions = [
		{
			value: localString("Today"),
			id: 1,
			startDate: moment().toDate(),
			endDate: moment().toDate(),
		},
		{
			value: localString("Next 2 Days"),
			id: 2,
			startDate: moment().toDate(),
			endDate: moment().add(2, "days").toDate(),
		},
		{
			value: localString("Next 3 Days"),
			id: 3,
			startDate: moment().toDate(),
			endDate: moment().add(3, "days").toDate(),
		},
		{
			value: localString("Custom Date"),
			id: 4,
			startDate: dateRange?.startDate ?? "",
			endDate: dateRange?.endDate ?? "",
		},
	];

	const shouldDisableDateFn = (date) => {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		return date < today;
	};

	const logicType = 'PlanRenewalDatePicker';

	useEffect(() => {
        if (mobileSearchInput.trim() === '') {
            findConsumerRenewals();
            resetPagination();
        }
    }, [mobileSearchInput]);

	useEffect(() => {
        if (emailIDSearchInput.trim() === '') {
            findConsumerRenewals();
            resetPagination();
        }
    }, [emailIDSearchInput]);

	const handleMobileSearchInputChange = (event) => {
        const { value } = event.target;
        setFilterData({
            ...filterData,
            MobileNo: value,
        });
        setMobileSearchInput(value);	
    };

	const handleEmailSearchInputChange = (event) => {
        const { value } = event.target;
        setFilterData({
            ...filterData,
            EmailID: value,
        });
        setEmailIDSearchInput(value);	
    };


    const handleMobileSearch = () => {
		if (checkMobileNumber(mobileSearchInput.trim())) {
            findConsumerRenewals();
            resetPagination();
        } else {
            Swal.fire({
                icon: 'error',
                text: localString('Please enter a valid mobile number'),
            });
        }
	};

	const handleEmailIDSearch = () => {
        if (checkEmail(emailIDSearchInput.trim())) {
            findConsumerRenewals();
            resetPagination();
        } else {
            Swal.fire({
                icon: 'error',
                text: localString('Please enter a valid email address'),
            });
        }
    };
	
    const handleMobileSearchKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
			handleMobileSearch();
        }
    };

	const handleEmailIDSearchKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
			handleEmailIDSearch();
        }
    };

    const handleMobileSearchIconClick = () => {
		handleMobileSearch();
    };

	const handleEmailIDSearchIconClick = () => {
		handleEmailIDSearch();
    };

	const handleClearAll = () => {
		setMobileSearchInput('');
		setEmailIDSearchInput('');
		if (filterData.MobileNo.trim() !== '' || filterData.EmailID.trim() !== '') {
			setFilterData({
				...filterData,
				MobileNo: '',
				EmailID: '', 
			});
			findConsumerRenewals(); 
			resetPagination();
		}
	};
		
	const clearMobileSearch = () => {
		setMobileSearchInput('');
		if (filterData.MobileNo.trim() !== '') {
			setFilterData({
				...filterData,
				MobileNo: '',
			});
			findConsumerRenewals();
			resetPagination();
		}
	};

	const clearEmailSearch = () => {
		setEmailIDSearchInput('');
		if (filterData.EmailID.trim() !== '') {
			setFilterData({
				...filterData,
				EmailID: '', 
			});
			findConsumerRenewals(); 
			resetPagination();
		}
	};

	const getCountries = () => {
		setLoader(true);
		let reqObj = {
			specificCountryCode: country.toUpperCase(),
			countryConfig: true,
		}
		apiCall({
			url: 'getCountries',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success){
					if(result.data) {
						let countryData = result.data.countries.length && result.data.countries[0];
						setSelectedCountryData(countryData);
					} else {
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError('No country data found.')),
						});
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}
	
	const findConsumerRenewals = (obj) => {
        setLoader(true);
		let startingDate = filterData.StartDate ? moment(filterData.StartDate).startOf('day').format("YYYY-MM-DDTHH:mm:ssZ") : moment().startOf('day').format("YYYY-MM-DDTHH:mm:ssZ");
		let endingDate = filterData.EndDate ? moment(filterData.EndDate).endOf('day').format("YYYY-MM-DDTHH:mm:ssZ") : moment().endOf('day').format("YYYY-MM-DDTHH:mm:ssZ");
        let reqObj = {
            Source: "WebApp",
            app: "WebApp",
            CountryID: selectedCountryData && selectedCountryData.CountryID,
			PartnerServiceLocationID: selectedPSL.PartnerServiceLocationID,
            ItemsPerPage: pagination.itemsPerPage,
            CurrentPage: pagination.pageNo,
			EmailID: filterData.EmailID,
            MobileNo: filterData.MobileNo,
			StartDate: startingDate,
			EndDate: endingDate,
        };
        apiCall({
            url: 'findConsumerRenewals',
            data: reqObj,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    const filteredResult = result.data.filteredResult;
                    const pagination = result.data.pagination;
                    setConsumerRenewals(filteredResult);
                    setPagination({
                        ...pagination,
                        pageNo: pagination.CurrentPage,
                        itemsPerPage: pagination.ItemsPerPage,
                        count: pagination.TotalItems,
                        numberOfPages: pagination.NumberOfPages,
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: localString(displayError(result)),
                    });
                }
            },
            errorCB: (err) => setLoader(false),
        });
    };


	useEffect(() => {
		getCountries();
	}, []);

    useEffect(() => {
		if (!selectedPSL?.PartnerServiceLocationID?.length) {
			if (accessFn("PLAN_SALE_ADMIN_USER")) {
				selectedCountryData && findConsumerRenewals();
			} else {
				Swal.fire({
					title: localString("Session Expired"),
					icon: "info",
					text: localString(
						"Your session has expired. The store location needs to be selected again, so you will be redirected to the home page shortly."
					),
					confirmButtonText: localString("Okay"),
				}).then(function () {
					window.location.href = baseUrl + "/plansales/home";
				});
			}
		} 
		else {
			selectedCountryData && findConsumerRenewals();
		}
	}, [selectedCountryData,pagination.pageNo,filterData.StartDate,filterData.EndDate]);
  
	return (
		<div className='planRenewal'>		
			{
			loader &&
				ReactDOM.createPortal(
				    <Loader />,
					document.getElementById("loader-root")
				)
			}
			<div className="row content-header-background renewal-header">
				<div className="col-sm-2">
					<h2 className="renew-plan">
						<a href={baseUrl + "/plansales/home"}>
							<span
								onClick={() => {}}
								className="resend-text"
								type="button"
							>
								{localString("Plan Sales")}
							</span>
						</a>
						&nbsp;{localString("/ Renew Plans")}
					</h2>
				</div>

				<div className="right-content col-sm-10">
				<div className="renewal-search">
					<div className="search-box">
						<input
							value={mobileSearchInput}
							onChange={handleMobileSearchInputChange}
							onKeyPress={handleMobileSearchKeyPress}
							placeholder={localString("Search by mobile number")}
							className='input'
							type="text"
						/>
						<div
							className={`search-box-icon ${mobileSearchInput.trim() === '' ? 'disabled' : ''}`}
							onClick={mobileSearchInput.trim() !== '' ? handleMobileSearchIconClick : null}
						>
						</div>
						{
							mobileSearchInput.trim() !== '' && 
							<div className='search-clear-icon' onClick={clearMobileSearch}>x
							</div>
						}
					</div>
					<div className="search-box">
						<input
							value={emailIDSearchInput}
							onChange={handleEmailSearchInputChange}
							onKeyPress={handleEmailIDSearchKeyPress}
							placeholder={localString("Search by email")}
							className='input'
							type="text"
						/>
						<div
							className={`search-box-icon ${emailIDSearchInput.trim() === '' ? 'disabled' : ''}`}
							onClick={emailIDSearchInput.trim() !== '' ? handleEmailIDSearchIconClick : null}
						>
						</div>
						{
							emailIDSearchInput.trim() !== '' && 
							<div className='search-clear-icon' onClick={clearEmailSearch}>x
							</div>
						}
					</div>
					<div className="period-div-payment">
						<CustomDateRangePicker
							{...props}
							handleDateRangeApply={(obj) => {
								setFilterData({
								...filterData,
								StartDate: obj?.startDate ?? filterData?.StartDate,
								EndDate: obj?.endDate ?? filterData?.EndDate,
								});
								resetPagination();
							}}
							dateClass="col-sm-5"
							customFormat="dd-mm-yyyy"
							showPreDefinedRange={false}
							customPlaceholder="MM-DD-YYYY"
							dateWidthClass="date-range-payment"
							dropDownArray={dropDownOptions}
							shouldDisableDateFn={shouldDisableDateFn}
							logicType={logicType}
						/>
					</div>
					<div>
						<button 
							onClick={handleClearAll} 
							className="clear-button"
							disabled={
								!mobileSearchInput && !emailIDSearchInput
							}
						>
							{localString("Clear All")}
						</button>
					</div>
				</div>
				</div>
			</div>
			
			<div className='container-fluid planRenewalContainer'>
				<div className='card'>
					<PlanRenewalGrid {...props}
						publicUrl={publicUrl} 
						setLoader={setLoader}
						localString={localString}
						consumerRenewals={consumerRenewals} // Pass the data as a prop
						pagination={pagination}
						setPagination={setPagination}
						setConsumerRenewals={setConsumerRenewals}
						findConsumerRenewals={findConsumerRenewals} // Pass findConsumerRenewals as a prop
				    />
				</div>
			</div>
        </div>
	);
}

export default PlanRenewal;