import React, { useRef} from 'react';
import {Dropdown,Input,Button} from 'sfy-react';

const CloseVisitUI=(props)=>{
    const {localString,buttonInput,showBackButton,visitInputs,visitData,backToJobUpdate,replacementFlow,
    consumerServiceRequest, picMaster,addDocArray,docArray,selectedPicType,otherArrImagesPreview, removeAddedDoc}=props;
    let acceptFileType=".png,.jpg,.jpeg,.pdf,.xlsx,.xls,.csv"

    const fileRef = useRef(null);
    const imageClick = () =>{
        fileRef.current.click();
	}
    return(
        <>
            <div className="col-sm-12 visitPadding">
                {visitInputs && visitInputs.length>0 && visitInputs.map((visit, index) => {
                    return(
                            visit.showVisit && <div className={visit.customCls}>
                            {
                                visit.type=='input' &&<Input
                                    value={visitData[visit.dataKey]}
                                    key={index}
                                    handleOnChange={(e) => {
                                        visit.oninputFunc(e.target.value);
                                    }}
                                    label={visit.labelText}
                                    labelAlign='top'
                                    isDisabled={visit.isDisabled}
                                    maxLength={visit.maxLength}
                                    placeholder={visit.placeholder}
                                />
                            }
                            {
                                visit.type && visit.type == 'Dropdown' && 
                                <div className=''>
                                    <label className="input__label ">{visit.labelText}</label>
                                        {<Dropdown
                                            id='dropdown'
                                            key={index}
                                            value={visitData[visit.dataKey]}
                                            options={visit.dropdownList}
                                            handleOnChange={(data) => {
                                                visit.oninputFunc(data);
                                            }}
                                            classObject={{
                                                container: visit.customClass,
                                            }}

                                            visitBy='value'
                                            showDownArrow={false}
                                            searchText={visit.searchText}
                                            isDisabled={visit.isDisabled}
                                            placeholder={visit.placeholder}
                                            OptionsComponent={visit.OptionsComponent}
                                        />
                                    }
                                    
                                </div>
                            }
                            {
                                visit.type && visit.type == 'text' && <div className={visit.customClass}>
                                    <label>{visit.labelText}</label>
                                    <div>{visit.text}</div>
                                </div>
                            }
                            {
                                visit.type && visit.type == 'textarea' && <div className={`${visit.customClass} textarea`}>
                                    <label>{visit.labelText}</label>
                                    <Input 
                                        className="form-control" 
                                        inputComponent={visit.inputComponent} 
                                        value={visitData[visit.dataKey]}
                                        handleOnChange={(e) => {
                                            visit.oninputFunc(e.target.value);
                                        }}
                                    />
                                </div>
                            }
                            <div>
                        </div>
                    </div>
                    )	
                })}

                {
                    replacementFlow && consumerServiceRequest && consumerServiceRequest.Source == 'SkyworthDashboard' && <div className="col-md-12 register-filter">
                    <div class="picture-type-label col-sm-12">
                        {localString("Select Picture Type")}
                    </div>
                    <div class="col-sm-12 upload-images-parent">
                        <ul class="row upload-images-ul">
                        {
                            picMaster && picMaster.map((pic,index)=>(
                                <li className='col-sm-3 pic-list'>
                                    <div className={`${(pic.picType).replace(/\s+/, "")}  image-field`}>
                                    <Input
                                        reference={fileRef}
                                        handleOnChange={(e)=>addDocArray(e)}
                                        name={pic.picType}
                                        id={(pic.picType).replace(/\s+/, "")}
                                        inputType="file"
                                        className="inputfile"
                                        otherProps={{ accept: acceptFileType }}
                                    />
                                    <label for={`${(pic.picType).replace(/\s+/, "")} `}  >
                                        <span className="glyphicon glyphicon-plus upload-sign" onClick={imageClick}></span>
                                    </label>
                                </div>
                                <p className='image-text'>{localString(pic.picType)}</p>
                                {
                                    docArray && docArray.map((doc,index)=>(
                                    <div className='request-documents-li align'>
                                    {
                                        pic.picType === doc.Tag && <div>
                                            <div className="glyphicon glyphicon-minus-sign delete-charges-btn" onClick={()=>removeAddedDoc(doc.id)}></div>
                                                <a href={doc.FileUrl} target="_blank" className="nounderline">
                                                    <img src={doc.FileUrl} alt={selectedPicType} className='image' />
                                                </a>
                                        </div>
                                    }
                                    </div>
                                    ))
                                }
                            </li>
                            ))
                        }
                        {
                            otherArrImagesPreview && otherArrImagesPreview.map((othdocs,index)=>(
                                <li className='pic-list'>
                                    <div className="request-documents-list align" >
                                        <div className="glyphicon glyphicon-minus-sign delete-charges-btn" onClick={()=>removeAddedDoc(othdocs.id)}></div>
                                        <a href={othdocs.FileUrl} target="_blank" className="nounderline">
                                            <img src={othdocs.FileUrl} alt={selectedPicType} className="image-other"/>
                                        </a>
                                    </div>
                                </li>
                            ))
                        }
                        {
                        <li className=" image-col pic-list">
                            <div className='image-field'>
                                <Input
                                    reference={fileRef}
                                    handleOnChange={(e)=>addDocArray(e)}
                                    name="Other"
                                    id="Other"
                                    inputType="file"
                                    className="inputfile"
                                    otherProps={{ accept: acceptFileType }}
                                />
                                <label for="Other"> 
                                    <span className="glyphicon glyphicon-plus upload-sign" onClick={imageClick}></span>
                                </label>
                            </div>
                            <p className='image-text'>{localString("Other Image")}</p>
                        </li>
                        }
                    </ul>
                    </div>
                    {
                        docArray && docArray.length == 0 && <div className="col-sm-12 padding20" >
                            {localString("No pictures added.")}
                        </div>
                    }      
                </div>
                }
                <div className="button-container paddingR0">
                        {
                            buttonInput && buttonInput.map(button=>(
                                <div>
                                    {button.showUnresolveVisit && <Button  type='submit' className='button-ghost' handleOnClick={button.onButtonFun} isDisabled={button.displayButton}  >{button.labelText}</Button>}
                                    {/* for ResolveModalInput modal*/}
                                    {button.showResolveVisit && button.labelTextNext && <Button  type='submit' className='button-ghost' handleOnClick={button.onButtonFun} isDisabled={button.displayButton}  >{button.labelTextNext}</Button>}
                                    {/* for ResolveVisitInput modal */}
                                    {button.showResolveVisit && button.labelText && <Button  type='submit' className='button-ghost' handleOnClick={button.onButtonFun} isDisabled={button.displayButton}  >{button.labelText}</Button>}
                                    {/* for ResolveModalInput modal*/}
                                    {button.showResolveVisit && button.labelTextComplete && <Button  type='submit' className='button-ghost' handleOnClick={button.onButtonFun} isDisabled={button.displayButton}  >{button.labelTextComplete}</Button>}
                                </div>
                            ))
                        }
                        {showBackButton && <Button  type='button' className='button-ghost'  handleOnClick={backToJobUpdate} >Back</Button>} 
                </div>
			</div>
        </>
    )
}
export default  CloseVisitUI;



