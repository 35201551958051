

import React from 'react';
import './PageContentWrapper.scss';

const PageContentWrapper = (props) => {
	const {title,headerClassName,bodyClassName,wrapperClassName,downLoadImageCB,showDownLoadIcon,customSymbol,showDownloadButtonIcon,editDeviceClick,showRightButton,rightButtonCB,rightButtonText,statusSpanText,showStatusSpan} = props;
	return (
		<div className={`panel-warpper ${ wrapperClassName ? wrapperClassName : ""}`}> 
			<div className={`panel-header ${ headerClassName ? headerClassName : ""}`} > 
				<p className='clearfix'>
					{title} 
					{showStatusSpan && <span className='status-span'>{statusSpanText}</span>}
					{showDownloadButtonIcon && <span  className={customSymbol?customSymbol :"glyphicon glyphicon-edit {hide: editDevice}"} onClick={editDeviceClick}></span>}
					{showRightButton && <span className="text-center pull-right" > 
						<button className="button button-small text-color-primary padding10" onClick={rightButtonCB}>
							{rightButtonText}
						</button>
					</span>}
				</p> 
				{showDownLoadIcon && <div className="text-center" > 
					<div className="download-image" onClick={downLoadImageCB}>
					</div>
				</div>}
			</div> 
			<div className={`panel-body ${ bodyClassName ? bodyClassName : ""}`}> 
				{props.children}
			</div>
		</div>
	);
}

export default PageContentWrapper;
