import React, { useState,useRef } from 'react';
import {DatePicker,Button} from 'sfy-react';
import Datetime from 'react-datetime';
import moment from 'moment';
const CollectionDatePickerInput=(props)=>{
    const {
        localString, closeModal, replacementFulfillment, consumerServiceRequest, nextStatus 
    } = props;
    const [collectionDate,setCollectionDate] = useState()
    const imageInput = useRef(null);
    
    const saveCollectedDate = () =>{
        let data = {
            "ConsumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
            "Status": (nextStatus == 'Ready For Collection') ? "Ready for collection" : "Replacement device collected",
			'CountryID': consumerServiceRequest && consumerServiceRequest.CountryID,
            "estimatedPickupDate": collectionDate
            
        };
        closeModal();
        replacementFulfillment(data);
    }
		return (
		<>
            <div className='clearfix'>
                <div className="col-xs-12 date-center">
                    <div className='input-group date col-xs-6 date-input ' style={{margin: 'auto'}}>
                        <Datetime
                            value={collectionDate}
                            placeholder = {moment(new Date()).format('YYYY-MM-DD')}
                            dateFormat={'YYYY-MM-DD'}
                            timeFormat={false}
                            onChange={(val) => {
                                setCollectionDate(moment(new Date(val),'DD-MM-YYYY').format('YYYY-MM-DD'))
                            }}
                            inputProps={{ ref:imageInput,placeholder: localString('yyyy-mm-dd')}}
                            closeOnSelect={true}
                        />
                        <span className="input-group-addon"  onClick={() => imageInput.current.click()} >
                            <span className="glyphicon glyphicon-calendar"
                            >
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="form-group-filter-request text-center mt10" >
                <Button className='btn button-servify-solid' handleOnClick={()=>saveCollectedDate()}>{localString("Submit")}</Button>
            </div>
        </>
	)
}
export default  CollectionDatePickerInput;
