import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { Button, Dropdown, Input, Loader, Modal, Radio } from 'sfy-react';
import { round } from 'lodash';
import Swal from 'sweetalert2';

import ConsentModal from './ConsentModal';
import Header from '../../components/Header/Header';
import Snackbar from '../../components/Snackbar/Snackbar';
import { displayError, encrypt } from '../../Utility/Utils';
import './AdvancePay.scss'
import Tooltip from '../../components/Tooltip/Tooltip';

export default function AdvancePay(props) {
	const {
		localString, apiCall, accessFn, publicUrl
	} = props;

	if(!accessFn('ADVANCE_PAY')) window.location.href = '/401';

	const history = useHistory();
	const qFilter = JSON.parse(props.qFilter);
	const countryCode = (qFilter && qFilter.countrycode && qFilter.countrycode.length) ? qFilter.countrycode[0].toUpperCase() : 'IN';
	
	const [loader, setLoader] = useState(false);
	const [countryloader, setCountryLoader] = useState(false);
	const [step, setStep] = useState('dashboard');
	const [panLoader, setPanLoader] = useState(false);
	const [walletDetailsloader, setWalletDetailsLoader] = useState(false);
	const [panCardList, setPanCardList] = useState([]);
	const [fundBalance, setFundBalance] = useState('0');
	const [panCard, setPanCard] = useState(null);
	const [panCardDetails, setPanCardDetails] = useState(null);
	const [checkConsent, setCheckConsent] = useState(false);
	const [pendingConsent, setPendingConsent] = useState(0);
	const [totalPartnerCount, setTotalPartnerCount] = useState(null);
	const [consentedPartnerCount, setConsentedPartnerCount] = useState(null);
	const [consentModal, setConsentModal] = useState(false);
	const [partnerList, setPartnerList] = useState([]);
	const [defaultPartnerList, setDefaultPartnerList] = useState([]);
	const [referenceID, setReferenceID] = useState(null);
	const [walletDetails, setWalletDetails] = useState(null);
	const [countryObj, setCountryObj] = useState(null);

	const [amount, setAmount] = useState('')
	const [paymentType, setPaymentType] = useState(null);
	const [beneficiaryName, setBeneficiaryName] = useState(null);
	const [ifscCode, setIfscCode] = useState(null);
	const [accountNumber, setAccountNumber] = useState(null);

	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarType, setSnackbarType] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState(false);

	const displayDisclaimer = () => {
		Swal.fire({  
			icon: 'info',  
			title: 'Important Update',
			text: localString('We have updated our banking partner for better experience to you to Axis Bank. The account Number remains same. Please go to Smart Pay section to get your latest account details and save it as new beneficiary for future transfers. This change is only for future transfers and there is no impact on your existing account with us. Please update these details before 31st October as any transfers to older account will fail and get refunded back to you immediately.Thanks, Servify'),
			showCancelButton: false,
			showConfirmButton: true,
			confirmButtonText: localString('OK'),
			allowEscapeKey: false,
			allowOutsideClick: false
		});
	}

	const displayToast = (type, message) => {
		setSnackbarType(type);
		setSnackbarMessage(message);
		setShowSnackbar(true);
	}

	const getPANList = () => {
		setLoader(true);
		let reqObj = {
			PartnerServiceLocationIDs: (qFilter && qFilter.serviceLocations) ? qFilter.serviceLocations : []
		}
		apiCall({
			url: 'getPANList',
			data: reqObj,
			callBack: (result) => { 
				setLoader(false);
				if(result.success && result.data) {
					let arr = [];
					result.data.length && result.data.filter(a => {
						let obj = {...a};
						if(a.PANNumber) {
							obj.value = a.PANNumber;
							arr.push(obj);
						}
					});
					setPanCardList(arr);
					if(arr && arr.length == 1) {
						setPanCard(arr[0].PANNumber);
						setPanCardDetails(arr[0]); 
					}
				}else {
					displayToast('error', displayError(result));
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	useEffect(() => {
		getPANList();
		getCountries();
		displayDisclaimer();
	}, []);

	const fetchPANPartners = () => {
		setPanLoader(true);
		let reqObj = {
			PanNumber: panCard
		}
		apiCall({
			url: 'fetchPANPartners',
			data: reqObj,
			callBack: (result) => { 
				setPanLoader(false);
				if(result.success && result.data) {
					let obj = result.data;
					setTotalPartnerCount(obj.totalPartnerCount);
					setConsentedPartnerCount(obj.consentedPartnerCount);
					if(obj.consentedPartnerCount == '0') {
						// let val = Number(obj.totalPartnerCount) - Number(obj.consentedPartnerCount);
						// setPendingConsent(val);
						setCheckConsent(true);
					}else {
						// setPendingConsent(0);
						setCheckConsent(false);
					}
					if(obj && obj.partnerList && obj.partnerList.length) {
						let arr = obj.partnerList.map(a => ({...a, disable: a.IsConsented}));
						setPartnerList(arr);
						setDefaultPartnerList(arr);
					}else {
						displayToast('error', localString('No Partners Found'));
					}
				}else {
					displayToast('error', displayError(result));
				}
			},
			errorCB: (err) => setPanLoader(false)
		});
	}

	const fetchWalletDetails = () => {
		setWalletDetailsLoader(true);
		let reqObj = {
			PanNumber: panCard,
			IsDefault: true
		}
		apiCall({
			url: 'fetchWalletDetails',
			data: reqObj,
			callBack: (result) => { 
				setWalletDetailsLoader(false);
				if(result.success) {
					if(result.data && result.data.length) {
						let arr = result.data;
						if(arr && arr.length) {
							let item = arr[0];
							setReferenceID(item.ReferenceID);
							setWalletDetails(item);
							if(item && item.wallet && item.wallet) {
								setFundBalance(item.wallet.BalanceAmount);
							}
						}
					}else {
						setReferenceID(null);
						setWalletDetails(null);
						setFundBalance('0');
					}
				}else {
					setReferenceID(null);
					setWalletDetails(null);
					setFundBalance('0');
					displayToast('error', displayError(result));
				}
			},
			errorCB: (err) => setWalletDetailsLoader(false)
		});
	}

	useEffect(() => {
		if(panCard) {
			fetchPANPartners();
			fetchWalletDetails();
		}
		else {
			if(panCardList && panCardList.length && panCardList.length == 1) {
				setPanCard(panCardList[0].PANNumber);
				setPanCardDetails(panCardList[0]); 
			}
		}
	}, [panCard]);

	const getCountries = () => {
		setCountryLoader(true);
		let reqObj = { 
			specificCountryCode: countryCode,
			countryConfig: true
		};
		apiCall({
			url: 'getCountries',
			data: reqObj,
			callBack: (result) => { 
				setCountryLoader(false);
				if(result.success && result.data) {
					if(result.data.countries && result.data.countries.length) {
						setCountryObj(result.data.countries[0]);
					}
				}else {
					displayToast('error', displayError(result));
				}
			},
			errorCB: (err) => setCountryLoader(false)
		});
	}

	const loadMoney = (isSmartPay) => {
		setLoader(true);
		let reqObj = {
			WalletReferenceID: referenceID,
			PartnerName: panCardDetails?.Name
		}
		if(isSmartPay) {
			reqObj.SmartPay = true;
		}else {
			reqObj.Amount = round(amount, 2);
		}
		apiCall({
			url: 'loadMoney',
			data: reqObj,
			callBack: (result) => { 
				setLoader(false);
				if(result.success && result.data) {
					let data = result.data;
					if(isSmartPay) {
						setBeneficiaryName(data.name);
						setIfscCode(data.ifsc);
						setAccountNumber(data.account_number);
					}else {
						openRazorPay(result.data);
					}
				}else {
					displayToast('error', displayError(result));
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}

	const openRazorPay = (data) => {
		let options = {
			"key": data.apiKey, // Enter the Key ID generated from the Dashboard
			"amount": data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
			"order_id": data.order_id, 
			"handler": function (response){
				setAmount('');
				fetchWalletDetails();
				displayToast('success', localString('Funds Successfully Added'));
				setStep('dashboard');
				setAmount('');
				setPaymentType(null);
				setBeneficiaryName(null);
				setIfscCode(null);
				setAccountNumber(null);
			},
			"prefill": {
				"name": data.buyer_name && data.buyer_name,
				"email": data.email && data.email,
				"contact": data.phone && data.phone
			},
			'notes': {
				id: data && data.id,
				appName: 'WebApp',
				payment_service: data.payment_service && data.payment_service,
				purpose: data.purpose && data.purpose
			},
			"theme": {
				"color": "#4c10c1"
			}
		};
		let rzp = new window.Razorpay(options);
		rzp.open();
	}

	const advancePayClick = () => {
		setStep('dashboard');
		setFundBalance('0');
		setReferenceID(null);
		setWalletDetails(null);
		setPanCard(null);
		setPanCardDetails(null)
		setAmount('');
		setPaymentType(null);
		setBeneficiaryName(null);
		setIfscCode(null);
		setAccountNumber(null);
		setTotalPartnerCount(null);
		setConsentedPartnerCount(null);
	}

	return (
		<>
			{
				(loader || countryloader || panLoader || walletDetailsloader) && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<div className='advancePay'>
				{/* ************* Header ************* */}
				<Header 
					showHtmlContent={true}
					htmlContent={() => {
						return (
							<>
								{(step && step == 'dashboard') && <h3>360 {'>'} <span className='bold'>{localString('Advance Pay')}</span></h3> }
								{(step && step == 'partnerList') && <h3>360 {'>'} <span className='advancePayNav' onClick={() => advancePayClick()}>{localString('Advance Pay')}</span> {'>'} <span className='bold'>{localString('Partner List')}</span></h3> }
								{(step && step == 'addFunds') && <h3>360 {'>'} <span className='advancePayNav' onClick={() => advancePayClick()}>{localString('Advance Pay')}</span> {'>'} <span className='bold'>{localString('Add Funds')}</span></h3> }
							</>
						)
					}}
				/>
				{/* ************* Body ************* */}
				<div className='container-fluid' style={{ 'paddingTop': '30px' }}>
					<div className='advancePayBody'>
						<div className='row advancePayRow'>
							{/* ************* Instructions Card ************* */}
							<div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 paddingB10'>
								<div className='advancePayCard animated animatedFadeInLeft fadeInLeft'>
									<div className='cardHeader'>
										{localString('Instructions')}
									</div>
									<div className='cardBody'>
										<p className='bold'>{localString('Steps to use Advance Pay')}</p>
										<p className='cardBody-steps'>{localString("1. Select a PAN Card from the dropdown on your right")}</p>
										<p className='cardBody-steps'>{localString("2. Kindly verify the company PAN Card before adding funds. If you're unable to add funds, please contact Servify Support at ")}<span> <a href='mailto:customersupport@servify.in'>customersupport@servify.in</a></span></p>
										<p className='cardBody-steps'>{localString("3. Click on the 'Partner List' button and provide consent for at least one partner (or all) to add funds to the selected PAN Card")}</p>
										<p className='cardBody-steps'>{localString("4. Once consent has been provided, you can add funds by clicking on the 'Add Funds' button")}</p>
										<p className='cardBody-steps'>{localString("5. If you select UPI, you'll be redirected to the payment gateway where you'll have to add the amount and select UPI as the payment mode and continue")}</p>
										<p className='cardBody-steps'>{localString("6. If Smart Pay is selected, you'll have to add the beneficiary details to your net banking account and make a payment")}</p>
										<p className='cardBody-steps'>{localString("7. Once the transaction is successful, the amount will reflect in your Advance Pay account")}</p>
									</div>
								</div>
							</div>
							{/* ************* Disabled Add funds card ************* */}
							{
								step && step !== 'dashboard' && 
									<div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 paddingB10'>
										<div className='advancePayCard animated animatedFadeInLeft fadeInLeft'>
											<div className='cardHeader'>
												{localString('Add Funds')}
											</div>
											<div className='cardBody'>
												<div className='panCardDropdown'>
													<Dropdown
														id='dropdown'
														value={panCard}
														options={panCardList}
														handleOnChange={(val) => {
															setPanCard(val.value);
															setPanCardDetails(val);
														}}
														filterBy='value'
														labelFloat={true}
														isDisabled={true}
														searchText='PAN Card Number'
													/>
												</div>
												<div className='fundBalance'>
													<div className='fundBalanceText'>{localString('Account Balance')}</div>
													<div className='fundBalanceAmt' style={{ color: fundBalance == '0' ? '#E01F3D' : '#4CD801' }}>&#x20b9; {fundBalance.toLocaleString('en-IN')}</div>
												</div>
												{ (accessFn('CSR_LOG_REPORT') && walletDetails) && <div className='redirectLink'>
													<a href='/reports'>{localString("Download Transaction History")}</a>
												</div> }
												<div style={{ paddingTop: (accessFn('CSR_LOG_REPORT') && walletDetails) ? '159px' : '198px' }}>
													<div>
														<Button
															className="btn button-servify w-100"
															type='button'
															onClick={() => setStep('partnerList')}
															isDisabled={true}
														>
															{localString('Partner List')}
														</Button>
													</div>
													<div>
														<Button
															className="btn button-servify w-100"
															type='button'
															onClick={() => setStep('addFunds')}
															isDisabled={true}
															>
															{localString('Add Funds')}
														</Button>
													</div>
												</div>
											</div>											
										</div>
									</div>
							}
							{/* ************* Active Add funds card ************* */}
							{
								step && step === 'dashboard' && 
									<div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 paddingB10'>
										<div className='advancePayCard activeAdvancePayCard animated animatedFadeInLeft fadeInLeft' style={{ overflow: 'inherit' }}>
											<div className='activeCardHeader'>
												{localString('Add Funds')}
											</div>
											<div className='activeCardBody'>
												<div className='panCardDropdown' >
													<Dropdown
														id='dropdown'
														value={panCard}
														options={panCardList}
														handleOnChange={(val) => {
															setPanCard(val.value);
															setPanCardDetails(val);
														}}
														filterBy='value'
														labelFloat={true}
														// isDisabled={true}
														searchText='PAN Card Number'
													/>
												</div>
												<div className='fundBalance'>
													<div className='fundBalanceText'>{localString('Account Balance')}</div>
													<div className='fundBalanceAmt' style={{ color: fundBalance == '0' ? '#E01F3D' : '#4CD801' }}>&#x20b9; {fundBalance.toLocaleString('en-IN')}</div>
												</div>
												{ (accessFn('CSR_LOG_REPORT') && walletDetails) && <div className='redirectLink'>
													<a href='/reports'>{localString("Download Transaction History")}</a>
												</div> }
												<div style={{ paddingTop: (accessFn('CSR_LOG_REPORT') && walletDetails) ? '159px' : '198px' }}>
													<div>
														<Button
															className="btn button-servify w-100 position-relative"
															type='button'
															onClick={() => setStep('partnerList')}
															isDisabled={!panCard}
														>
															{localString('Partner List')}
															{ totalPartnerCount && consentedPartnerCount && totalPartnerCount !== consentedPartnerCount ? 
																<Tooltip
																	content={() => <span>{localString('All partners are not consented, please give consent')}</span>}
																	className={{
																		tooltipText: 'warningTooltipText'
																	}}
																	position='top'
																>
																	<span className='warningImg'></span> 
																</Tooltip>
																: ''
															}
														</Button>
													</div>
													<div>
														<Button
															className="btn button-servify w-100"
															type='button'
															onClick={() => setStep('addFunds')}
															isDisabled={!panCard || !walletDetails}
														>
															{localString('Add Funds')}
														</Button>
													</div>
												</div>
											</div>
										</div>
									</div>
							}
							{/* ************* Active payment options Card ************* */}
							{
								step === 'addFunds' && !paymentType &&  
									<div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 paddingB10'>
										<div className='advancePayCard activeAdvancePayCard animated animatedFadeInLeft fadeInLeft'>
											<div className='activeCardHeader'>
												{localString('Select Payment Option')}
											</div>
											<div className='activeCardBody' style={{ overflow: 'scroll' }}>
												<div className='paymentOptions'>
													<Radio
														value='upi'
														selectedValue={paymentType}
														name='paymentType'
														classes='paymentTypeRadio'
														handleOnUpdate={(e) => setPaymentType(e.target.value)}
													>
														{localString('UPI')}
													</Radio>
													<Radio
														value='smartPay'
														selectedValue={paymentType}
														name='paymentType'
														classes='paymentTypeRadio'
														handleOnUpdate={(e) => {
															setPaymentType(e.target.value)
															loadMoney(true);
														}}
													>
														{localString('Smart Pay')}
													</Radio>
												</div>
											</div>
										</div>
									</div>
							}
							{/* ************* Disabled payment options Card ************* */}
							{
								step === 'addFunds' && paymentType && 
									<div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 paddingB10'>
										<div className='advancePayCard animated animatedFadeInLeft fadeInLeft'>
											<div className='cardHeader'>
												{localString('Select Payment Option')}
											</div>
											<div className='cardBody'>
												<div className='paymentOptions'>
													<Radio
														value='upi'
														selectedValue={paymentType}
														name='paymentType'
														classes='paymentTypeRadio'
														handleOnUpdate={(e) => setPaymentType(e.target.value)}
													>
														{localString('UPI')}
													</Radio>
													<Radio
														value='smartPay'
														selectedValue={paymentType}
														name='paymentType'
														classes='paymentTypeRadio'
														handleOnUpdate={(e) => {
															setAmount('');
															setPaymentType(e.target.value)
															loadMoney(true);
														}}
													>
														{localString('Smart Pay')}
													</Radio>
												</div>
											</div>
										</div>
									</div>
							}
							{/* ************* UPI ************* */}
							{
								step === 'addFunds' && paymentType === 'upi' && 
									<div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 paddingB10'>
										<div className='advancePayCard activeAdvancePayCard animated animatedFadeInLeft fadeInLeft'>
											<div className='activeCardHeader'>
												{localString('UPI')}
											</div>
											<div className='activeCardBody' style={{ overflow: 'scroll' }}>
												<div className='depositFund'>
													<div className='depositFund-icon'>
														<img 
															src={publicUrl+'/imgs/card.png'} 
															width={'50px'} 
															style={{ marginLeft: '-3px' }}
														/>
													</div>
													<div style={{ 'paddingTop': '20px' }}>
														<p>{localString("Please add the amount below & click on 'Continue'")}</p>
													</div>
													<div className='depositFund-body'>
														<Input
															value={amount}
															inputType='number'
															id='amount'
															label={localString('Amount')}
                											labelAlign='float'
															handleOnChange={(e) => setAmount(e.target.value)}
														/>
													</div>
													<div style={{ 'paddingTop': '180px' }}>
														<Button
															className="btn advancePay-Btn"
															type='button'
															onClick={() => loadMoney()}
															isDisabled={!amount || (amount && Number(amount) < 1)}
														>
															{localString('Continue')}
														</Button>
													</div>
												</div>
											</div>
										</div>
									</div>
							}
							{/* ************* Smart Pay ************* */}
							{
								step === 'addFunds' && paymentType === 'smartPay' && 
									<div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 paddingB10'>
										<div className='advancePayCard activeAdvancePayCard animated animatedFadeInLeft fadeInLeft'>
											<div className='activeCardHeader'>
												{localString('Smart Pay')}
											</div>
											<div className='activeCardBody' style={{ overflow: 'scroll' }}>
												<div className='smartPay'>
													<div className='smartPayHeader'>
														<p>{localString('Please add the below-mentioned beneficiary details to your bank account to enable net banking transfers on this account')}</p>
													</div>
													<div className='smartPayBody'>
														{ beneficiaryName && <p className='bankDetails'>{localString('1. Beneficiary Name')} - <b>{localString(beneficiaryName)}</b></p> }
														{ accountNumber && <p className='bankDetails'>{localString('2. Virtual Account Number')} - <b>{localString(accountNumber)}</b></p> }
														{ ifscCode && <p className='bankDetails'>{localString('3. IFSC Code')} - <b>{localString(ifscCode)}</b></p> }
													</div>
												</div>
												<div className='smartPayNote'>
													<li>{localString('For immediate funds transfer, please use IMPS')}</li>
													<li>{localString('An NEFT/RTGS will take normal bank transfer time to get money credited to your Servify Advance Pay account')}</li>
												</div>
											</div>
										</div>
									</div>
							}
							{
								step === 'partnerList' && 
									<div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 paddingB10'>
										<div className='advancePayCard activeAdvancePayCard animated animatedFadeInLeft fadeInLeft'>
											<div className='activeCardHeader'>
												{localString('Partner List')}
											</div>
											<div className='activeCardBody' style={{ overflow: 'scroll' }}>
												<ConsentModal 
													{...props}
													panCard={panCard}
													setStep={setStep}
													setLoader={setLoader}
													countryObj={countryObj}
													countryCode={countryCode}
													referenceID={referenceID}
													displayToast={displayToast}
													advancePayClick={advancePayClick}
													fetchPANPartners={fetchPANPartners}
													totalPartnerCount={totalPartnerCount}
													fetchWalletDetails={fetchWalletDetails}
													defaultPartnerList={defaultPartnerList}
													consentedPartnerCount={consentedPartnerCount}
													partnerList={partnerList} setPartnerList={setPartnerList}
													consentModal={consentModal} setConsentModal={setConsentModal}
												/>
											</div>
										</div>
									</div>
							}
						</div>
					</div>
				</div>
				<Modal
					showModal={checkConsent}
					className='consentModal'
				>
					<Modal.Header>
						<div className='consentModal-header'>
							{localString('Partner Consent Required')}
						</div>
					</Modal.Header>
					<Modal.Body>
						<div className='consentModal-body'>
							{localString("To continue atleast one partner's consent is required.")}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className='container'>
							<div className='row'>
								<div className='col-xs-6'>
									<Button
										className="btn button-servify w-100"
										onClick={() => {
											// setPanCard(null);
											// setPanCardDetails(null);
											advancePayClick()
											setCheckConsent(false);
										}}
									>
										{localString('Cancel')}
									</Button> 
								</div>
								<div className='col-xs-6'>
									<Button
										className="btn button-servify w-100"
										onClick={() => {
											setStep('partnerList');
											setCheckConsent(false);
										}}
									>
										{localString('Continue')}
									</Button>
								</div>
							</div>
						</div>
					</Modal.Footer>
				</Modal>
			</div>
			{/* Todo: Create common component */}
			{
				showSnackbar && 
					<Snackbar
						publicUrl={publicUrl}
						message={localString(snackbarMessage)}
						type={snackbarType}
						callback={() => {
							setShowSnackbar(false);
							setSnackbarMessage('');
							setSnackbarType('');
						}}
					/>
			}
		</>
	)
}