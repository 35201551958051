import React from "react";
import { Button } from "sfy-react";

const proofTypetext1 = {
	livecapture: "Scan",
	text: "Add",
};

const proofTypetext2 = {
	livecapture: "Re-Scan",
	text: "Edit",
};

const GroupActionButtons = (props) => {
	const {
		doc,
		title,
		localString,
		handlePreviewClick,
		handleActionButtonClick,
		handleGroupAdjudicationAction,
		accessFn,
		consumerServiceRequest,
		index,
	} = props;

	const isSomeProofUploaded =
		doc.consumerServiceRequestProof &&
		doc.consumerServiceRequestProof.length > 0 &&
		doc.consumerServiceRequestProof.some(
			(csrProof) => csrProof.consumerServiceRequestProofRecords.length > 0
		);

	return (
		<>
			<div className="rp-row">
				<div className="rp-row">
					<div className="rp-row" style={{ minWidth: "370px" }}>
						<div className="rp-label">
							{`${index + 1}. `}
							{localString(title)}
							<span
								style={{
									color:
										doc.status === "rejected"
											? "red"
											: doc.status === "accepted"
											? "green"
											: "gray",
								}}
							>{` (${localString(
								doc.status.charAt(0).toUpperCase() +
									doc.status.slice(1)
							)})`}</span>
						</div>
						<div className="rp-thumbnails-wrapper">
							<div
								className="rp-thumbnails-container"
								style={{
									...(!isSomeProofUploaded && {
										cursor: "not-allowed",
									}),
								}}
								onClick={() => {
									if (isSomeProofUploaded) {
										handlePreviewClick(doc);
									}
								}}
							>
								<span className="glyphicon glyphicon-file"></span>
								<span>View</span>
							</div>
						</div>
					</div>
					{consumerServiceRequest.Status ===
						"Service center document pending" &&
						doc.status === "pending" &&
						["livecapture", "text"].includes(doc.proofType) && (
							<Button
								classes="button-ghost button-small rp-btn rp-btn-width"
								handleOnClick={() => {
									handleActionButtonClick(doc);
								}}
								//isDisabled={
								//	doc.proofType === "text" &&
								//	doc.consumerServiceRequestProof &&
								//	doc.consumerServiceRequestProof.length >
								//		0 &&
								//	doc.consumerServiceRequestProof[0]
								//		.consumerServiceRequestProofRecords &&
								//	doc.consumerServiceRequestProof[0]
								//		.consumerServiceRequestProofRecords
								//		.length > 0 &&
								//	doc.consumerServiceRequestProof[0]
								//		.consumerServiceRequestProofRecords[0]
								//		.text
								//}
							>
								{localString(
									isSomeProofUploaded
										? doc.proofType in proofTypetext2 &&
												proofTypetext2[doc.proofType]
										: doc.proofType in proofTypetext1 &&
												proofTypetext1[doc.proofType]
								)}
							</Button>
						)}

					{accessFn("PROOF_ADJUDICATOR_VIEW") &&
						consumerServiceRequest.Status ===
							"Service center document verification pending" && (
							<>
								<div>
									<Button
										classes="button-ghost button-small rp-btn rp-btn-width"
										isDisabled={
											!isSomeProofUploaded ||
											doc.status !== "pending"
										}
										handleOnClick={() =>
											handleGroupAdjudicationAction(
												doc.proofGroupCode,
												"accepted"
											)
										}
									>
										{localString("Accept")}
									</Button>
								</div>
								<div>
									<Button
										classes="button-ghost button-small rp-btn rp-btn-width"
										isDisabled={
											!isSomeProofUploaded ||
											doc.status !== "pending"
										}
										handleOnClick={() =>
											handleGroupAdjudicationAction(
												doc.proofGroupCode,
												"rejected"
											)
										}
									>
										{localString("Reject")}
									</Button>
								</div>
							</>
						)}
				</div>
			</div>
		</>
	);
};

export default GroupActionButtons;
