import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import { Button, Loader, Modal } from 'sfy-react';
import { displayError, SERVICETYPE } from '../../Utility/Utils';

import './PayoutModesModal.scss';

export default function PayoutModesModal(props) {
	const { 
		localString, apiCall, countryId, showPayoutModal, setShowPayoutModal, ServiceTypeID, customerEmail, customerName, 
		consumerServiceRequest, currencyCode, onPayoutModeSubmit,setShowModal,showModal, berRefundCase = false
	} = props;
	const [loader, setLoader] = useState(false);
	const [optionalModes, setOptionalModes] = useState([]);
	const [buyoutOption, setBuyoutOption] = useState(null);
	const [chequeName, setChequeName] = useState(customerName);
	const [cost, setCost] = useState('');
	const checkReplacementCost = () => !showReplacementCost() || (showReplacementCost() && cost) ? true : false;
	const showReplacementCost = () => consumerServiceRequest && !consumerServiceRequest.ReplacementCost && (['Replacement unavailable', 'Request for reimbursement', 'Device not repairable'].indexOf(consumerServiceRequest.Status) > -1);
	const disableBtn = !buyoutOption || (buyoutOption && buyoutOption.PaymentMode && buyoutOption.PaymentMode == 'CHEQUE' && !chequeName) || !checkReplacementCost();
	
	useEffect(() => {
		showPayoutModal && setChequeName(customerName);
	}, [customerName]);

	const buyoutModeClick = (mode) => {
		setBuyoutOption(mode);
	}

	const handleChequeName = (e) => {
		let val = e.target.value.replace(/[`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi, '');
		setChequeName(val);
	}

	const onCostChange = (e) => {
		let val = e.target.value.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
		val = val.replace(/^[0-9]*\.[0-9]{3,}(.*)$/gi, parseFloat(cost).toFixed(2));
		setCost(val);
	};

	const openBuyoutOptionForm = (modes) => {
		setBuyoutOption(null);
		if(modes && modes.length == 1) {
			setBuyoutOption(modes[0]);
		}
	};

	const getPayoutModes = () => {
		setLoader(true); 
		let data = {
			ServiceTypeID: ServiceTypeID ,
			CountryID: countryId ? countryId : undefined
		};
		apiCall({
			url: 'getPayoutModes',
			data: data,
			callBack: (result) => {
				setLoader(false); 
				if(result.success) {
					if(result.data && result.data.length > 0) {
						setOptionalModes(result.data);
						openBuyoutOptionForm(result.data);
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	useEffect(() => {
		if(showPayoutModal) getPayoutModes();
	}, [showPayoutModal]);


	const onSubmit = () => {
		let payoutAttributes = [
			{
				AttrName: 'CONSUMER_PAYOUT_MODE',
				AttrValue: buyoutOption.PaymentMode
			}
		];

		if(chequeName && buyoutOption.PaymentMode == 'CHEQUE') {
			payoutAttributes = [...payoutAttributes, 
				{
					AttrName: 'CONSUMER_LEGAL_NAME',
					AttrValue: chequeName
				},
				{
					AttrName: 'CONSUMER_LEGAL_EMAIL_ID',
					AttrValue: customerEmail
				}
			];
		}
		if(showReplacementCost()) {
			payoutAttributes = [...payoutAttributes, 
				{
					AttrName: 'REPLACEMENT_COST',
					AttrValue: cost
				}
			];
		}
		onPayoutModeSubmit(payoutAttributes);
		setShowModal({...showModal,showPayoutModesModal:false}); 
	}

	return (
		<>
			{
				loader && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<Modal
				className='buyoutOptionModal'
				showModal={showPayoutModal}
				closeModal={() => setShowPayoutModal(false)}
				showCloseBtn={true}
			>
				<Modal.Header>
					<h4 id="myModalLabel" className="modal-title title-spacing">
						{ !berRefundCase && <strong>{localString("Please choose one of the reimbursement options below")}</strong> }
						{ berRefundCase && <strong>{localString("Please choose from the Refund options given here under:")}</strong> }
					</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-body">
						<div className="outcome-instrutions buyout-instructions">
							{ !berRefundCase && <label>{localString("Inform customer they will get link to upload video, post which reimbursement process will start as we are not asking for the defective device back")}</label> }
						</div>
						<div className="outcome-option-wrapper clearfix">
							{
								optionalModes && optionalModes.length > 0 && optionalModes.map((mode, k) => 
									<div className="col-sm-3 outcome-option" key={k}> 
										<label className="btn button-servify-solid submit-btn outcome-btn" onClick={() => buyoutModeClick(mode)}>
											<input
												className="label-radio"
												type="radio"
												name="loanerCondition"
												value={mode.PaymentMode}
												checked={buyoutOption && mode && buyoutOption.PaymentMode == mode.PaymentMode} 
											/>{localString(mode.DisplayText)}
										</label>
									</div>
								)
							}
						</div>
						{
							buyoutOption && buyoutOption.PaymentMode == 'CHEQUE' && 
								<div className="buyout-input-wrapper clearfix">
									<div className="col-sm-6">
										<label className="option-label">{localString("Confirm name on check")}</label>
										<input
											id="chequeName"
											className="form-control decision-input"
											type="text"
											onChange={handleChequeName}
											autocomplete="off" 
											value={chequeName}
										/>
									</div>
									{
										customerEmail && <div className="email-copy">
											Email id where digital check will be emailed: {customerEmail}
										</div>
									}
								</div>
						}
						{
							showReplacementCost() && <div className="buyout-input-wrapper clearfix">
								<div className="replacemement-cost">
									<label className="option-label">{localString("Replacement Cost")}</label>
									{
										currencyCode && 
										<input 
											className="form-control decision-input currency-input"
											type="text"
											disabled
											value={currencyCode}
										/>
									}
									<input 
										className="form-control decision-input"
										id="mandatory-cost-payout"
										type="text"
										value={cost}
										onChange={onCostChange}
										autocomplete="off"
									/>
								</div>
							</div>
						}

						
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="form-group-filter-request text-center">
						<Button 
							className="btn button-servify-solid submit-btn" 
							onClick={() => setShowPayoutModal(false)}
						>
							{localString("Cancel")}
						</Button>
						<Button 
							className="btn button-servify-solid submit-btn save-submit" 
							onClick={() => onSubmit()}
							isDisabled={disableBtn}
						>
							{localString("Submit")}
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}