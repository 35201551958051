import React from 'react';
import ExpandItem from '../../components/ExpandItem/ExpandItem';

const DriverUpdate = (props) => {
    const {localString, driverUpdates} = props;
    
    return (
        <div>
            <p><h4>{localString('Driver updates')}</h4></p>
            {driverUpdates && driverUpdates.length > 0 && driverUpdates.map((obj) => { return(
                <ExpandItem 
                    name={'Driver Name: ' + (obj.Description ? obj.Description : '')}
                    showImage={false}
                    localString={localString} 
                    showExpandArrow={true}
                >
                    {Object.keys(obj).length > 0 && Object.keys(obj).map((keys) => {return (
                        <div className="item-info">
                            {obj[keys] && <>
                            <span className="info-label">{keys}</span><span> : </span>
                            <span>{(obj[keys]).toString()}</span>
                            </>}
                        </div>
                    )})}
                </ExpandItem>
            )})}
        </div>
    )
}

export default DriverUpdate
