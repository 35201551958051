import React from 'react';
import ChargesRow from './ChargesRow';
import './ThirdPartyCharges.scss'


const ShowCharges = (props) => {
	const {
		localString,consumerServiceRequest,removeAddedCharge,showsavebutton,showEstimationButtons,editAddedCharge
	} = props;

	return (
		<>
		{/* <hr className="remove-margin estimation-details-divider"/> */}
		{
			consumerServiceRequest && consumerServiceRequest.charges && consumerServiceRequest.charges.length > 0 && 
			consumerServiceRequest.charges.map(charge=>{
				return (
					['Advance', 'Advance payment', 'Mandatory deductible','Mandatorydeductible', 'Admin fees', 'Claim amount','Adminfees','RefundAmount'].indexOf(charge.Type) < 0 && 
					<ChargesRow 
						{...props}
						charge={charge}
						localString={localString}
						advanceCharges={false}
						editAddedCharge={editAddedCharge}
						removeAddedCharge={removeAddedCharge}
						consumerServiceRequest={consumerServiceRequest}
						showsavebutton={showsavebutton}
						showEstimationButtons={showEstimationButtons}
					/>   
				)
			})
		}
		{/* <div className="row">
			<div className="col-sm-8 bottom-border mb-5">
				<div className="charges-panel-content">{localString("Total Amount")}</div>
			</div>
			<div className="col-sm-2 bottom-border mb-5">
				<div className="charges-panel-content align-right">{consumerServiceRequest.TotalBilledAmount}</div>
			</div>
		</div> */}
		{/* <hr className="remove-margin estimation-details-divider"/> */}
		 {
			consumerServiceRequest && consumerServiceRequest.charges && consumerServiceRequest.charges.length > 0 && 
			consumerServiceRequest.charges.map(charge=>{
				return (
					['Advance', 'Advance payment', 'Mandatory deductible','Mandatorydeductible', 'Admin fees', 'Claim amount','Adminfees','RefundAmount'].indexOf(charge.Type) > -1 && <>
					<ChargesRow 
						{...props}
						charge={charge}
						localString={localString}
						editAddedCharge={editAddedCharge}
						removeAddedCharge={removeAddedCharge}
						advanceCharges={true}
						consumerServiceRequest={consumerServiceRequest}
						showsavebutton={showsavebutton}
						showEstimationButtons={showEstimationButtons}
					/>
					</> 
				)
			})
		}
		{/* <div className="row">
			<div className="col-sm-8 bottom-border mb-5">
				<div className="charges-panel-content">{localString('Total Payable')}</div>
			</div>
			<div className="col-sm-2 bottom-border mb-5">
				<div className="charges-panel-content align-right">{consumerServiceRequest.ConsumerAmount}</div>
			</div>
		</div>
		{
			consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.DepreciatedValue && consumerServiceRequest.consumerProduct.DepreciatedValue.maxSumInsured > 0 && <div className="row">
				<div class="col-sm-8">
					<div class="charges-panel-content">{localString('Covered Value')}</div>
				</div>
				<div class="col-sm-2">
					<div class="charges-panel-content align-right">{consumerServiceRequest && consumerServiceRequest.consumerProduct && consumerServiceRequest.consumerProduct.DepreciatedValue &&consumerServiceRequest.consumerProduct.DepreciatedValue.maxSumInsured}</div>
				</div>
			</div>
		} */}
	</>
	)
}

export default ShowCharges;
