import React,{useRef} from 'react';
import {Button,Dropdown,Table,Checkbox,Input} from 'sfy-react';
import {returnLocalDateTime} from '../../../../../Utility/Utils';
import './ViewUploadDocuments.scss'

const ViewUploadDocJSX = (props) => {
	const {documents,localString,publicUrl,documentTypeList,editDocuments,setFileName,fileName,setSelectedDocType,selectedDocType,userDocumentID,
		otherType, setOtherType,addNewDocumentArray,newDocLoader,newDocumentUpload,removeNewDoc,uploadNewDocuments,cancelDetailsClick,sendImageToGSXCheck,
		sendImageToGSX,isPOPSelected,isPOPUploaded,isLoanerSelected,disableLoanerDocToGSX,consumerServiceRequest,disableSelection,maxSelected,cancelSelectionForSendGSX
	} = props;

	const fileClick = useRef(null);

	const imageClick = () =>{
		fileClick.current.click();
	}

	const classObject = {
		table:"table table-striped table-responsive table-condensed",
		tableContainer: 'maxHeight300'
	}

	return (
		<>
			<div className = "row">
				<div className = "col-sm-12 doc-zero-padding">
				{
					documents && documents.length> 0 && 
					<div style={{overflowY: "auto", maxHeight: "350px"}}>
						<div className="table-responsive">
							<Table className={classObject}>
								<Table.Header>
									<Table.Row>
										<Table.Heading className="custom-checkbox header p6">
											<Checkbox
												value={true}
												isDisabled={true}
												className={{
													container: "pl2",
												}}
											/>
											<span className="checkmark"></span>
										</Table.Heading>
										<Table.Heading className="text-capitalize">{localString("File Name")}</Table.Heading>
										<Table.Heading className="text-capitalize">{localString("File Type")}</Table.Heading>
										<Table.Heading className="text-capitalize">{localString("Action Status")}</Table.Heading>
										<Table.Heading className="text-capitalize">{localString("Uploaded By")}</Table.Heading>
										<Table.Heading className="text-capitalize">{localString("Uploaded Date & Time")}</Table.Heading>
										<Table.Heading className="text-capitalize">{localString("View")}</Table.Heading>
										<Table.Heading className="text-capitalize">{localString("GSX Upload Status")}</Table.Heading>
									</Table.Row>
								</Table.Header>
									<Table.Body>
									{
										documents && documents.map((doc,i)=>{                                            
											return(
												<Table.Row>
													<Table.Data className="custom-checkbox-align">
														<Checkbox
															className=""
															id={doc.UserDocumentID}
															value={doc.isSelect}
															handleOnChange={()=>sendImageToGSXCheck(doc,i)} 
															isDisabled={disableSelection || (!doc.isSelect && maxSelected) || ['txt','doc','docx','csv','pdf','jpg','png','jpeg','zip','Image'].indexOf(doc.Type) < 0 || ['Sent','Uploaded'].indexOf(doc.GSXStatus) > -1 || (!consumerServiceRequest.consumerProduct.AlternateUniqueKey && !(consumerServiceRequest.consumerProduct.consumerProductAttribute && consumerServiceRequest.consumerProduct.consumerProductAttribute.AttributeValue)) || (!doc.isSelect && (isPOPSelected || isPOPUploaded) && doc.DocumentCode == 'DC-006') || (!doc.isSelect && (isLoanerSelected || disableLoanerDocToGSX) && doc.DocumentCode == 'DC-007')}
														/>
														<span className="checkmark"></span>
													</Table.Data>
													<Table.Data className="">{doc.Tag ? localString(doc.Tag):'-'}</Table.Data>
													<Table.Data className="text-Uppercase">{doc.Type}</Table.Data>
													<Table.Data>{localString(doc.ActionStatus) || '-'}</Table.Data>
													<Table.Data>{localString(doc.UserType) ? localString(doc.UserType) :'-'}</Table.Data>
													<Table.Data>{returnLocalDateTime(doc.CreatedDate,'DD MMM, YYYY hh:mma')}</Table.Data>
													<Table.Data>
														<a href={doc.FilePath} target="_blank" className="nounderline">
															<img src={publicUrl+"/imgs/View.png"} alt="View" />
														</a>
													</Table.Data>
													{
														doc.GSXStatus == 'NA' && 
														<Table.Data>{localString("N/A")}</Table.Data>
													}
													{
														doc.GSXStatus == 'Sent' && doc.isAttachedToRepair && 
														<Table.Data>{localString("Attached to Repair")}</Table.Data>
													}
													{
														doc.GSXStatus == 'Sent' && doc.isAttachedToLoanerReturn && 
														<Table.Data >{localString("Attached to Loaner Return")}</Table.Data>
													}
													{
														doc.GSXStatus == 'Sent' && !doc.isAttachedToRepair && !doc.isAttachedToLoanerReturn && 
														<Table.Data>{localString("Uploaded")}</Table.Data>
													}
													{
														doc.GSXStatus == 'Failed' && 
														<Table.Data>{localString("Failed Re-Upload")}</Table.Data>
													}
												</Table.Row>
											)
										})
									}
									</Table.Body>
							</Table>
						</div>
						<div className="col-sm-12 view-upload-float-right">
						{
							userDocumentID && userDocumentID.length > 0 && 
							<>
								<Button  
									type="button" 
									name="cancelButton"
									id="cancelButton"
									text={localString("Cancel")}
									className="btn button-servify "
									handleOnClick={cancelSelectionForSendGSX}
								/>
								<Button 
									type="button" 
									name="SendToGSX"
									id="SendToGSX"
									text={localString("Send to GSX")}
									className="btn button-servify "
									handleOnClick={sendImageToGSX}
								/>
							</>
						}
						</div>
					</div>
				}
				{
					documents && documents.length == 0 && 
					<div className="no-data-margin">
						<p className="charges-panel-subheader text-capitalize remove-margin">{ localString("No documents uploaded") }</p>
					</div>
				}
				</div>
				{
					editDocuments && 
					<div className="docRow">
						<div className="col-sm-4">
							<div className="col-sm-6">
								<div className="row">
									<div className="col-sm-12">
										<div style={{marginBottom:"10px"}}>{localString('Select File Name')}:</div>
											<Dropdown
												id="documentList"
												value={fileName}
												placeholder={!fileName && localString("Select File Name")}
												options={documentTypeList}
												handleOnChange={(item) => {
													setFileName(item.value)
													setSelectedDocType(item)
												}}
												filterBy='value'
												showDownArrow={false}
											/>
											{
												selectedDocType && selectedDocType.DocumentCode == 'DC-004' && 
												<div className="other-input-field">
													<Input
														value={otherType}
														id="otherType"
														handleOnChange={(e) => {
															setOtherType(e.target.value)
														}}
														labelAlign='top'
														placeholder={localString("Enter File Name")}
													/>
												</div>
											}
									</div>
								</div>
								<div class="row">
									<div class="col-sm-12">
										<div className="view-upload-plus-icon" onClick={imageClick}>
											<div className="upload">
												<Input
													reference={fileClick}
													handleOnChange={(e)=>addNewDocumentArray(e)}
													inputType="file"
													id="newDocument"
													otherProps={{
														accept:".doc,.docx,.csv,.pdf,.jpg,.png,.jpeg,.zip,.xls,.xlsx,.txt,.aac,.m4a,.mp4,.ogg,.ogx",
														onClick:(e) => e.target.value = ''
													}}
												/>
											</div>
											<span className="glyphicon glyphicon-plus"></span>
										</div>
										{
											newDocLoader && 
											<div className="pic-loader" >
												<div className="cs-loader-inner">
													<svg className="spinner-container" width="50px" height="50px" viewBox="0 0 52 52">
														<circle className="path" cx="26px" cy="26px" r="20px" fill="none" stroke-width="3px"></circle>
													</svg>
												</div>
											</div>
										}
										
									</div>
								</div>
								<div class="col-sm-12 " style={{padding: "0px 10px"}}>
									<Button  
										type="button" 
										name="saveButton"
										id="saveButton"
										text={localString("Save")}
										className="btn button-servify"
										handleOnClick={uploadNewDocuments}
									/>
									<Button  
										type="button" 
										name="cancelButtonClick"
										id="cancelButtonClick"
										text={localString("Cancel")}
										className="btn button-servify"
										handleOnClick={cancelDetailsClick}
									/>
								</div>
							</div>
						</div>
						<div className="col-sm-8">
							<div className="upload-images-ul">
							{
								newDocumentUpload && newDocumentUpload.map((othdocs,i)=>{
									return(
										<div className="col">
											<div className="request-documents-li align">
											<div className="">{othdocs.Tag}</div>
											<span className="glyphicon glyphicon-minus-sign delete-charges-btn" onClick={()=>removeNewDoc(i)}></span>
											{
												['jpg','png','jpeg'].indexOf(othdocs.Type) < 0 && 
												<a href={othdocs.FileUrl} target="_blank">
													<img src={publicUrl+"/imgs/File_Folder_Zip.png"}  width="80px" height="80px"/>
												</a>
											}
											{
												['jpg','png','jpeg'].indexOf(othdocs.Type) > -1 && 
												<img src={othdocs.FileUrl} alt="" width="100px" height="100px" />
											}
											</div>
										</div>
									)
								})
							}
							</div>
						</div>
					</div>
				}
			</div>

		</>
	)
}

export default ViewUploadDocJSX
