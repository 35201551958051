import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Input, Loader } from 'sfy-react';
import Swal from 'sweetalert2';

import { displayError } from '../../../Utility/Utils';


export default function SelectBrand(props) {
	const {
		apiCall, localString, showAddProduct, setLoader, handleBrandSelection, setScreenToViewDiv, brand, setBrand,
		getBrandImage, setDisplaySubCat
	} = props;

	const [brandFilter, setBrandFilter] = useState('');
	const [brandList, setBrandList] = useState([]);
	const [filteredBrandList, setFilteredBrandList] = useState([]);

	const handleBrandFilter = (e) => {
		let val = e.target.value.toLowerCase();
		setBrandFilter(e.target.value);
		if(val) {
			let filteredArr = brandList.filter(data => {
				if(data && data.BrandName && data.BrandName.toLowerCase().includes(val)) {
					return data;
				}
			});
			setFilteredBrandList(filteredArr);
		} else{
			setFilteredBrandList(brandList);
		}
	}

	const fetchAllBrandDetails = () => {
		setScreenToViewDiv('SelectBrand');
		setLoader(true)
		apiCall({
			url: 'fetchAllBrandDetails',
			data: {},
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data){
					setBrandList(result.data);
					setFilteredBrandList(result.data);
					if(result.data.length === 0) {
						Swal.fire({  
							icon: 'error',  
							text: localString('No brands found'),  
						});
					}else if(result.data.length === 1) {
						setBrand(result.data[0]);
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString('No brands found'),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	useEffect(() => {
		showAddProduct && fetchAllBrandDetails();
		return () => {
			setBrand({});
		} 
	}, [showAddProduct]);
	
	return (
		<>
			{ showAddProduct && 
				<div id='SelectBrand' className='raiseReqSection'>
					<div className='selectBrand'>
						<label>{localString("Select a Brand")}</label>
						<div className="row">
							<div className="col-sm-10 col-xs-8">
								<div className="form-group">
									<Input
										value={brandFilter}
										handleOnChange={handleBrandFilter}
										placeholder={localString("Search brands")}
										className={{
											formControl: 'form-group',
											containerClass: 'input-container',
											inputClass: 'input'
										}}
									/>
								</div>
							</div>
							<div className="col-sm-2 col-xs-4" style={{ marginTop: '10px' }}>
								<Button 
									className="pull-right button-refresh" 
									onClick={() => {
										// setBrandFilter('');
										fetchAllBrandDetails();
										setDisplaySubCat(false);
									}}
								/>
							</div>
						</div>
						<div className="row fixed-height">
							{
								filteredBrandList && filteredBrandList.length > 0 && filteredBrandList.map((item, k) => <div className="col-sm-3 col-xs-6" key={k} onClick={() => handleBrandSelection(item)}>
									<div className={`item subcat-item ${(brand.BrandID == item.BrandID) ? 'item-active': ''}`}>
										<img src={item.ImageUrl || getBrandImage(item.S3baseUrl, item.BrandBucket, item.BrandID, item.BrandName)} />
										<p>{item.BrandName}</p>
									</div>
								</div> )
							}
						</div>
					</div>
				</div> }
		</>
	);
}