import React, { useState,useEffect } from "react";
import ReactDOM from "react-dom";
import { Input, Button, Loader } from "sfy-react";
import CredentialWrapper from "../../components/CredentialsWrapper/CredentialsWrapper";
import { validateStrongPass } from "../../Utility/validations";

const ResetPassword = (props) => {
	const displayText = props.displayText;
	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
	const [showLoader, setLoader] = useState(false);
	const [successMessage, setSuccessMessage] = useState({
		successMessageText: "",
		showUrl: false,
		url: ''
	});
	const [errorState, setErrorState] = useState({
		errorText: "",
		showError: false,
	});

	const passwordChangeHandler = (password) => {
		setPassword(password);
		let passwordValidity = validateStrongPass(password);
		if (!passwordValidity.isValid) {
			setErrorState({
				errorText: passwordValidity.msg,
				showError: true
			});
		} else {
			setErrorState({
				errorText: "",
				showError: false,
			});
		}
	};
	const repeatPasswordChangeHandler = (password) => {
		setRepeatPassword(password);
	};

	useEffect(() => {
		checkDisableSubmitBtn();
	}, [password,repeatPassword]);

	const checkDisableSubmitBtn = () => {
		if (password != "" && repeatPassword != "") {
			setDisableSubmitBtn(false);
		} else {
			setDisableSubmitBtn(true);
		}
	}

	const submitPassword = (e) => {
		if (e) {
			e.preventDefault();
		}
		if (password != repeatPassword) {
			setErrorState({
				errorText: 'Passwords do not match',
				showError: true
			});
		} else {
			let passwordValidity = validateStrongPass(repeatPassword);
			if (passwordValidity.isValid) {
				let data = {
					access_token: props.token,
					password: repeatPassword,
					reason: displayText ? "Force update" : "Forgot password",
				};
				let options = {
					method: "POST",
					body: JSON.stringify(data),
					headers: {
						"Content-Type": "application/json;charset=UTF-8",
					},
				};
				let url = props.baseUrl + "/api/v1/users/createPassword";
				setLoader(true);
				fetch(url, options)
					.then((response) => {
						if (!response.ok) throw new Error(response.status);
						else return response.json();
					})
					.then((result) => {
						setLoader(false);
						if (result.success) {
							setSuccessMessage((prevState) => {
								return {
									...prevState,
									successMessageText: result.msg,
									showUrl: true,
									url: props.baseUrl + '/logout?cb='+Math.random(13),
									type: 1
								};
							});
							setErrorState({
								errorText: "",
								showError: false,
							});
							setDisableSubmitBtn(true);
						} else {
							setErrorState({
								errorText: result.msg,
								showError: true
							});
							setDisableSubmitBtn(false);
						}
					})
					.catch((error) => {
						console.log("error: " + error);
					});
			} else {
				setErrorState({
					errorText: passwordValidity.msg,
					showError: true,
				});
			}
		}

	};

	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}
			<CredentialWrapper
				title={displayText ? displayText : "Reset password"}
				publicUrl={props.publicUrl}
			>
				<form onSubmit={submitPassword}>
					<div className="cred-input-div">
						<Input
							handleOnChange={(e) => {
								passwordChangeHandler(e.target.value);
							}}
							placeholder="Password"
							inputType="password"
						/>
						<Input
							handleOnChange={(e) => {
								repeatPasswordChangeHandler(e.target.value);
							}}
							placeholder="Confirm Password"
							inputType="password"
						/>
					</div>
					<div className="cred-button-div">
						<Button
							handleOnClick={submitPassword}
							isDisabled={disableSubmitBtn}
							type="submit"
							classes="button-ghost cred-button button-small"
							text="Change"
						/>
					</div>
				</form>
				<div className="cred-link-div">
					{displayText ? (
						<a
							className="cred-link"
							href={
								props.baseUrl + "/logout?cb=" + Math.random(13)
							}
						>
							Back
						</a>
					) : (
						<a className="cred-link" href={props.baseUrl}>
							Choose another account
						</a>
					)}
				</div>
				{errorState.showError && (
					<p className="cred-error">{errorState.errorText}</p>
				)}
				{successMessage.successMessageText && (
					<p>
						{successMessage.successMessageText}{" "}
						<a
							if={successMessage.showUrl}
							className="cred-link"
							href={successMessage.url}
						>
							here
						</a>
					</p>
				)}
			</CredentialWrapper>
		</>
	);
};

export default ResetPassword;
