import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Loader } from "sfy-react";
import Swal from "sweetalert2";
import PageHeader from "../../../components/PageHeader/PageHeader";
import { displayError,checkMobile,checkReferenceNumber,checkEmail,encrypt} from "../../../Utility/Utils";
import {Button} from 'sfy-react';
import ProductDetailsInput from "./ProductDetailsInput";
import PickUpDetailsInput from "./PickUpDetailsInput";
import DropDetailsInput from "./DropDetailsInput";
import OtherDetailsInput from "./OtherDetailsInput";
import moment from "moment";

const CreateLogistics = (props) => {
	const { accessFn, localString, apiCall, titleHeader, qFilter,baseUrl,viewRequestsUrl } = props;
	const [validLogisticsDetails,setValidLogisticsDetails] = useState(false);
	const [validOtherDetails,setValidOtherDetails] = useState(false);
	const [showOtherDetails,setShowOtherDetails] = useState(false);
	const [showLoader ,setLoader] = useState(false);
	const [prevRef ,setPrevRef] = useState("");
	const [serviceProvidersData,setServiceProvidersData] = useState();
	let LogisticsRequestID = "";
	const buttonHeader = [
		{
			buttonText: localString("View Requests"),
			btnIcon: "glyphicon glyphicon-filter",
			showButton: accessFn("FILTER_LOGISTIC_REQUEST"),
			onButtonClick: () => {
				let url = baseUrl + '/logistics';
				window.location.replace(url);
			}
		}
	];
	let [createLogisticsData, setCreateLogisticsData] = useState({
		'BrandID':"",
		'ProductID':"",
		'ProductSubCategoryID':"",
		'ProductSubCategoryName':"",
		'BrandName':"",
		'ProductName':"",
		'ProductUniqueID':"",
		'PickupName':"",
		"PickupMobile":"",
		"PickupAddress":"",
		"PickupLandmark":"",
		"PickupPin":"",
		"PickupCountryCode":"",
		"PickupCountry":"",
		"PickupCity":"",
		"PickupState":"",
		"DropName":"",
		"DropMobile":"",
		"DropAddress":"",
		"DropLandmark":"",
		"DropPin":"",
		"DropCity":"",
		"DropState":"",
		"DropCountry":"",
		"DropCountryCode":"",
		"selectedProduct":"",
	});
	let [otherDetailsLogisticsData, setOtherDetailsLogisticsData] = useState({
		"FlowLogisticsServiceTypeName":"",
		"FlowLogisticsServiceType":"",
		"InvoiceDate":"",
		"Reference":"",
		"ProviderVendorID":"",
		"ProviderDisplayName":""
	});
	useEffect(() => {
		validateLogisticsDetails();
	}, [createLogisticsData]);

	const validateOtherDeatils = () => {
		if(otherDetailsLogisticsData.Reference.length > 0 && otherDetailsLogisticsData.Reference.length < 13 && checkReferenceNumber(otherDetailsLogisticsData.Reference)) {
			setPrevRef(otherDetailsLogisticsData.Reference);
			if(validLogisticsDetails && otherDetailsLogisticsData.FlowLogisticsServiceType && otherDetailsLogisticsData.ProviderVendorID && otherDetailsLogisticsData.InvoiceDate){
				setValidOtherDetails(true);
			}else{
				setValidOtherDetails(false);
			}
		}else{
			if (otherDetailsLogisticsData.Reference.length !=0 && (otherDetailsLogisticsData.Reference.length >= 13 || !checkReferenceNumber(otherDetailsLogisticsData.Reference))) {
				setOtherDetailsLogisticsData((prevData) => {
					return {
						...prevData,
						Reference: prevRef
					};
				});
			}else{
				setPrevRef("");
				setValidOtherDetails(false);
			}
		}
	}

	useEffect(() => {
		validateOtherDeatils();
	}, [otherDetailsLogisticsData]);


	const validateLogisticsDetails = () => {
		if(createLogisticsData && createLogisticsData.BrandID && createLogisticsData.ProductID && createLogisticsData.selectedProduct && ((createLogisticsData.selectedProduct.ProductType && createLogisticsData.selectedProduct.ProductType == 'Parts') || createLogisticsData.ProductUniqueID)
		&& createLogisticsData.PickupName && createLogisticsData.PickupMobile.length > 0 && checkMobile(createLogisticsData.PickupMobile) && createLogisticsData.PickupAddress && createLogisticsData.PickupLandmark && createLogisticsData.PickupPin && createLogisticsData.PickupCity
		&& createLogisticsData.PickupState && createLogisticsData.PickupCountryCode && createLogisticsData.DropName && createLogisticsData.DropMobile.length > 0 && checkMobile(createLogisticsData.DropMobile) && createLogisticsData.DropAddress && createLogisticsData.DropLandmark
		&& createLogisticsData.DropPin && createLogisticsData.DropCity && createLogisticsData.DropState && createLogisticsData.DropCountryCode){
			setValidLogisticsDetails(true)
		}else{
			setValidLogisticsDetails(false)
		}
	}
	const getServiceProvider = () => {
		let data = {
			"DropPincode": createLogisticsData.DropPin,
			"PickUpPincode": createLogisticsData.PickupPin,
			"pickupCountryCode": createLogisticsData.PickupCountryCode,
			"dropCountryCode": createLogisticsData.DropCountryCode
		}
		setLoader(true);
		apiCall({
			url:'checkServiceAvailability',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let data = result.data.map(v => ({...v, value: v.LogisticsServiceTypeName}))
					setServiceProvidersData(data);
					setShowOtherDetails(true);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const CreateShipment = () => {
		let productDetails = {}
		if (createLogisticsData.selectedProduct.isProductPart) {
		  productDetails = {
			// "productPrice": 0,
			"modelNo": "",
			"productID": createLogisticsData.selectedProduct.ProductID,
			"brandName": createLogisticsData.BrandName,
			"productName": createLogisticsData.selectedProduct.ProductName,
			'ProductType': createLogisticsData.selectedProduct.ProductType,
			'IdentifierName': createLogisticsData.selectedProduct.IdentifierName,

		  }
		} else {
		  productDetails = {
			// "productPrice": 0,
			"imei": createLogisticsData.ProductUniqueID,
			"modelNo": "",
			"productID": createLogisticsData.ProductID,
			"brandName": createLogisticsData.BrandName,
			"productName": createLogisticsData.ProductName,
		  }
		}
		let data = {
		  "productDetails": productDetails,
			  "dropDetails": {
				  "city": createLogisticsData.DropCity,
				  "state": createLogisticsData.DropState,
				  "countryCode": createLogisticsData.DropCountryCode,
				  "address": createLogisticsData.DropAddress,
				  "landmark": createLogisticsData.DropLandmark,
				  "pinCode": createLogisticsData.DropPin,
				  "phoneNo": createLogisticsData.DropMobile,
				  "name": createLogisticsData.DropName,
				  "emailId": ""
			  },
			  "pickupDetails": {
				"city": createLogisticsData.PickupCity,
				"state":createLogisticsData.PickupState,
				"countryCode": createLogisticsData.PickupCountryCode,
				"address": createLogisticsData.PickupAddress,
				"landmark": createLogisticsData.PickupLandmark,
				"pinCode":createLogisticsData.PickupPin,
				"phoneNo": createLogisticsData.PickupMobile,
				"name": createLogisticsData.PickupName,
				"emailId": ""
			},
			"flow": otherDetailsLogisticsData.FlowLogisticsServiceType,
			"Source": "WebApp-Logistics",
			"Provider": otherDetailsLogisticsData.ProviderVendorID,
			"invoiceDate": moment(otherDetailsLogisticsData.InvoiceDate).format('YYYY-MM-DD'),
			"secretcode": "",
			"ReferenceID": otherDetailsLogisticsData.Reference,
			"subCategoryId": createLogisticsData.ProductSubCategoryID || undefined
		}
		setLoader(true);
		apiCall({
			url:'createShipmentRequestForm',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let logisticsData = result.data;
					LogisticsRequestID = result.data.LogisticsRequestID;
					Swal.fire({
						title:"Request raised successfully",
						html: '<p>Your waybill number is ' + result.data.wayBill + '</p>',
						icon: 'sucess',
						allowOutsideClick: false,
						showCancelButton:true,
						showConfirmButton:true,
						confirmButtonText: 'Raise new request',
						cancelButtonText: logisticsData.isDocRequired? 'Send Email': 'View Request',
					}).then((result) => {
						if(result.isConfirmed) {
							window.location.reload();
						}else if(logisticsData.isDocRequired) {
							swalEmail()
						}else{
							let url = baseUrl + '/' + viewRequestsUrl + '?id=' + logisticsData.LogisticsRequestID;
							window.location.assign(url);
						}
					})
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError("Logistics request is not raised. Please try again")),
					});
				}
			}
		});
	}
	let errorEmailMessage = ""
	let emailID = "";
	const swalEmail = () => {
		Swal.fire({
			title:"",
			html: '<p>Please enter Email ID</p><br/></br/><input class = "" type="email" id ="emailid" /><div style = "color:red;" id = "errorEmail">' + errorEmailMessage + '</div>',
			icon: 'question',
			allowOutsideClick: false,
			showCancelButton:true,
			showConfirmButton:true,
			confirmButtonText: 'Confirm Email',
			cancelButtonText: 'Raise another request',
		}).then((result) => {
			if(result.isConfirmed) {
				emailID = document.getElementById('emailid').value;
				if (checkEmail(emailID)) {
					sendEmail()
				} else {
					errorEmailMessage = localString('Please enter valid Email ID')
					swalEmail()
				}
			}else{
				window.location.reload();
			}
		})
	}
	const sendEmail = () => {
		let data = {
			"LogisticsRequestID":LogisticsRequestID,
		  	"EmailID":emailID
		}
		setLoader(true);
		apiCall({
			url:'sendDocumentsEmail',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					Swal.fire({  
						icon: 'success', 
						text: 'The documents have been sent to the Email ID.', 
						allowOutsideClick: false,
					}).then((result) => {
						window.location.reload();
					});
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	return (
		<>
			{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<PageHeader
				titleHeader={titleHeader}
				localString={localString}
				btnClass="btn button-servify header-btn button-stack text-capital"
				headerButtonArray={buttonHeader}
			></PageHeader>
			<div class = "mandatory padding12">
				<sup>*</sup>{localString("All fields are mandatory.")}
			</div>
			<ProductDetailsInput
				{...props}
				createLogisticsData={createLogisticsData}
				setCreateLogisticsData={setCreateLogisticsData}
			></ProductDetailsInput>
			<PickUpDetailsInput
				{...props}
				createLogisticsData={createLogisticsData}
				setCreateLogisticsData={setCreateLogisticsData}
			></PickUpDetailsInput>
			<DropDetailsInput
				{...props}
				createLogisticsData={createLogisticsData}
				setCreateLogisticsData={setCreateLogisticsData}
			></DropDetailsInput>
			{validLogisticsDetails && <div className="button-container"><Button handleOnClick={getServiceProvider} type='button' classes='button-ghost text-capital' text={localString("Check Service Availability")}></Button> </div>}
			{showOtherDetails && <OtherDetailsInput
				{...props}
				otherDetailsLogisticsData={otherDetailsLogisticsData}
				setOtherDetailsLogisticsData={setOtherDetailsLogisticsData}
				serviceProvidersData={serviceProvidersData}
			></OtherDetailsInput>}
			{validOtherDetails && <div className="button-container"><Button handleOnClick={CreateShipment} type='button' classes='button-ghost text-capital' text={localString("Create Shipment")}></Button> </div>}
		</>
	)

};

export default CreateLogistics;
