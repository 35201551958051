import React, { useState, useEffect } from "react";
import { Dropdown, dateFormatter } from "sfy-react";
import Swal from "sweetalert2";
import { displayError } from "../../../../Utility/Utils";
import ShadowWrapper from "../../ShadowWrapper";
import ReassignModal from "../../ReassignModal";
import ViewDataInModal from "../../ViewDataInModal";
import "../ViewCase.scss";

const statusIdToStatus = {
	1: "Open",
	2: "In-progress",
	3: "Closed",
};

const CaseCard = (props) => {
	const { setShowLoader, apiCall, localString, caseDetails, getCaseDetails } =
		props;

	const [caseStatusOptions, setCaseStatusOptions] = useState([
		{
			id: "1",
			value: "Open",
		},
	]);
	const [showReassignModal, setShowReassignModal] = useState(false);
	const [reassignModalData, setReassignModalData] = useState({
		assigneeName: "",
		assigneeUserID: "",
		caseID: "",
		csrCountryID: "",
		soldPlanCountryID: "",
	});

	const closeModal = () => {
		setShowReassignModal(false);
		setReassignModalData({
			assigneeName: "",
			assigneeUserID: "",
			caseID: "",
			csrCountryID: "",
			soldPlanCountryID: "",
		});
	};

	const updateCase = (data) => {
		setShowLoader(true);
		apiCall({
			url: "updateCase",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					getCaseDetails();
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	const getServiceOperatorUsers = (
		isForReassignModal = false,
		data,
		callBackForReasssignModal
	) => {
		setShowLoader(true);
		apiCall({
			url: "getServiceOperatorUsers",
			data: {},
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
					if (result.data) {
						let data = result.data.map((v) => ({
							...v,
							value: v.name,
						}));

						if (isForReassignModal && callBackForReasssignModal) {
							callBackForReasssignModal(data);
						}
					}
				} else {
					Swal.fire({
						icon: "error",
						text: localString(displayError(result)),
					});
				}
			},
		});
	};

	useEffect(() => {
		if (caseDetails.caseStatusID === 1) {
			setCaseStatusOptions([
				{
					id: "2",
					value: statusIdToStatus[2],
				},
			]);
		}

		if (caseDetails.caseStatusID === 2) {
			setCaseStatusOptions([
				{
					id: "3",
					value: statusIdToStatus[3],
				},
			]);
		}
	}, [caseDetails]);

	return (
		<>
			<div className="card-outer-wrapper">
				<ShadowWrapper classname="rounded">
					{caseDetails && (
						<div className="card-wrapper">
							<div className="border-bottom card-header-container">
								<div className="card-heading-wrapper">
									<div className="main-heading">
										{localString("Case")}
									</div>
									<div className="sub-heading">
										{caseDetails.caseReferenceID || "-"}
									</div>
									<div
										className={`score-tablet ${
											caseDetails.score &&
											parseInt(caseDetails.score, 10) > 0
												? "green-tablet"
												: "red-tablet"
										}`}
									>
										{caseDetails.score || "-"}
									</div>
								</div>

								<div className="card-dropdown">
									<Dropdown
										id="case-status"
										classObject={{
											container:
												"customised-border customised-border-radius",
											optionsSearch:
												"customised-border-radius",
											valueContainer:
												"customised-border-radius",
										}}
										value={
											caseDetails.caseStatusID
												? statusIdToStatus[
														caseDetails.caseStatusID
												  ]
												: ""
										}
										options={caseStatusOptions}
										handleOnChange={(val) => {
											let data = {
												caseID: caseDetails.caseID,
												caseStatusID: val.id,
											};

											updateCase(data);
										}}
										isDisabled={
											caseDetails.caseStatusID === 3
										}
										showTooltip={true}
										filterBy="value"
										searchText={"Case Status"}
										showDownArrow={true}
										handleOnSearch={(e) => {
											caseStatusOptions &&
												caseStatusOptions.filter(
													(obj) =>
														obj.value
															.toLowerCase()
															.includes(
																e.target.value.toLowerCase()
															)
												);
										}}
										noOptionsText={localString(
											"Cannot find the searched option"
										)}
										placeholder={localString("Case Status")}
									/>
								</div>
							</div>
							<div className="card-body">
								<div className="case-card-row-5 body-heading">
									<div className="consumer-history-heading-text">
										{localString("Case Number")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Created On")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Transaction Subcategory")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Rules Tagged")}
									</div>

									<div className="consumer-history-heading-text">
										{localString("Assignee")}
									</div>
								</div>

								<div className="case-card-row-5 second-row-pad">
									<div>
										{caseDetails.caseReferenceID || "-"}
									</div>

									<div>
										{caseDetails.createdDate
											? dateFormatter(
													new Date(
														caseDetails.createdDate
													),
													"dd/mm/yyyy"
											  )
											: "-"}
									</div>

									<div>
										{caseDetails.transactionSubCategory ||
											"-"}
									</div>

									<div>
										{caseDetails.rules &&
										caseDetails.rules.length > 1 ? (
											<ViewDataInModal
												content={`${
													caseDetails.rules[0]["name"]
												} +${
													caseDetails.rules.length - 1
												}  `}
											>
												<>
													{caseDetails.rules.map(
														(rule, idx) =>
															idx > 0 && (
																<div>
																	{rule.name}
																</div>
															)
													)}
												</>
											</ViewDataInModal>
										) : caseDetails.rules &&
										  caseDetails.rules.length === 1 ? (
											caseDetails.rules[0]["name"]
										) : (
											"-"
										)}
									</div>

									<div>
										<span>
											{caseDetails.assigneeName || "-"}
										</span>
										<span
											className="reassign-btn"
											style={{
												cursor:
													caseDetails.caseStatusID ===
													3
														? "not-allowed"
														: "pointer",
												opacity:
													caseDetails.caseStatusID ===
													3
														? "0.5"
														: "1",
											}}
											onClick={() => {
												if (
													caseDetails.caseStatusID !==
													3
												) {
													setShowReassignModal(true);
													setReassignModalData({
														assigneeName:
															caseDetails.assigneeName ||
															"",
														assigneeUserID:
															caseDetails.assigneeUserID ||
															"",
														caseID: caseDetails.caseID,
														csrCountryID: "",
														soldPlanCountryID: "",
													});
												}
											}}
										>
											{localString("Reassign")}
										</span>
									</div>
								</div>
							</div>
						</div>
					)}
				</ShadowWrapper>
			</div>

			{showReassignModal && (
				<ReassignModal
					{...props}
					showModal={showReassignModal}
					closeModal={closeModal}
					setShowModal={setShowReassignModal}
					getServiceOperatorUsers={getServiceOperatorUsers}
					getCaseDetails={getCaseDetails}
					reassignModalData={reassignModalData}
					setReassignModalData={setReassignModalData}
				/>
			)}
		</>
	);
};

export default CaseCard;
