import React from 'react';

const PlanViewConsumerDetails = (props) => {
	const {localString, navItems, selectedID} = props;
	return (
		<div className="col-sm-3 text-center clearfix plan-nav-list">
			<ul className="nav nav-pills nav-stacked text-left nav-scroll">
				{
					navItems && navItems.map((regProd)=>{
						return(
							regProd.showNav && <li role="presentation"  className={regProd.name == selectedID ? "active":""}  onClick={() => regProd.navClick(regProd.name)}>
								<a className="">{localString(regProd.displayName)}</a>
							</li>
						)
					})
				}
			</ul>
		</div>
	)
}

export default PlanViewConsumerDetails
