import React from 'react';
import {Button} from 'sfy-react';
import FilterInputs from '../../../../components/FilterInputs/FilterInput';
import {encrypt} from '../../../../Utility/Utils';
import './ConsumerRequestDetails.scss'

const ConsumerRequestDetails = (props) => {
	const {localString,requestLabelsArray,headings,filterInputs,baseUrl,filterData,clearSearch} = props;

	return (
		<div>
			{
				<div className="col-xs-12">
				<FilterInputs {...props} filterInputs={filterInputs} filterData={filterData}  hideSaveButton={true}  handleOnClick={clearSearch}></FilterInputs>
				</div>
			}
			<div className="row consumers-header head">
				{
				headings && headings.map(label=>{
					return(
					<div className={label.customClass}>
						<strong>{label.title}</strong>
					</div>
					)
				})
				}
			</div>
			{
				requestLabelsArray && requestLabelsArray.length>0 && 
					requestLabelsArray && requestLabelsArray.map((labels)=>{
					return(
					<div className="consumer-content custom-main">
					<div className="row consumers-header inner-head">
						{labels && labels.map(inner=>{
							return (
								<>
							{inner.show  && 
								<div className={inner.customClass}>
									<p>{inner.value}</p>
								</div>
							}
							{!inner.show && 
								<div className="custom-button">{
									<a target="_blank" href={baseUrl+"/servicerequests/view?csrid="+(inner.consumerServiceRequestID)}>
									<Button  text={localString('View')} className='btn button-servify pull-right'></Button>
									</a>}
								</div>
							}
							</>
							)
						})}
					</div>
					</div>
					)
				})
			}
			{
				(!requestLabelsArray || requestLabelsArray.length === 0) && 
				<div className="text-center product-list-empty-state no-request">
					<p> {localString("No Requests Found")} </p>
				</div>
			}
		</div>
	)
}

export default ConsumerRequestDetails
