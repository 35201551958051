import React,{useState,useEffect} from 'react';
import {Dropdown,Button} from 'sfy-react';

const CancelOnsiteModalUI=(props)=>{
    const {localString,selectCancelOnsiteDropdown,cancelOnsiteFilterData,setCancelOnsiteFilterData,cancelServiceWithRemark}=props;
    const [disableServiceCancel,setDisableServiceCancel]=useState(true);
    // useEffect(() => {
    //     setDisableServiceCancel(true);
    // }, [cancelOnsiteFilterData])
    return(
        <div className='cancel-onsite'>
            <div className = "row">
                <div className = "col-sm-12">
                    <label className = "cancel-service-label">{localString("Please select a reason for cancelling service")}</label>
                </div>
            </div>
            <div className = "row">
                <div className = "col-sm-12 cancel-width">
                    <Dropdown
                        id='cancelOnsiteDropdown'
                        // key={index}
                        value={cancelOnsiteFilterData}
                        options={selectCancelOnsiteDropdown}
                        handleOnChange={(data) => {
                            setCancelOnsiteFilterData(data.value)
                            setDisableServiceCancel(false);
                        }}
                        visitBy='value'
                        showDownArrow={false}
                    />
                </div>
            </div>
            <div className="button-container paddingR0">
                <Button  type='submit' className="btn button-servify"  handleOnClick={()=>cancelServiceWithRemark()} isDisabled={disableServiceCancel}  >{localString("Cancel Service")}</Button>
            </div>
        </div>
    )
}
export default CancelOnsiteModalUI;