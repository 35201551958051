import React, { useState } from "react";
import PageContentWrapper from "../../../../../components/PageContentWrapper/PageContentWrapper";
import { displayAmount, getDateFormat } from "../../../../../Utility/Utils";
import { Loader } from "sfy-react";
import ReactDOM from "react-dom";
import "./Overview.scss";
import { useSelector } from "react-redux";
import moment from "moment";
import CommonTable from "../../../../../components/Table/Table";

const Overview = (props) => {
	const { localString, paymentData, defaultCountryLocale, countryData } = props;
	const [showLoader, setShowLoader] = useState(false);
	const { selectedPSL } = useSelector((state) => state.PlanSalesDashboard);

	const statusText = {
		"-9": localString("On Hold"),
		"-5": localString("Downgraded"),
		"-4": localString("Cancelled"),
		"-3": localString("Expired"),
		0: localString("Purchased"),
		1: localString("Active"),
		2: localString("Registered"),
		3: localString("Cancellation Initiated"),
		4: localString("Refunded"),
		5: localString("Upgraded"),
		8: localString("Enrolled"),
		9: localString("Diagnosed"),
		10: localString("Benefits Availed"),
		12: localString("Bundled"),
		13: localString("Under Review"),
		14: localString("Payment Pending"),
	};

	const statusColor = {
		"-9": "#FDE4E1",
		"-5": "#F2F2F2",
		"-4": "#FDE4E1",
		"-3": "#FDE4E1",
		0: "#FCF0DF",
		1: "#E6F3E5",
		2: "#FCF0DF",
		3: "#EFEAFC",
		4: "#FDE4E1",
		5: "#EFEAFC",
		8: "#FCF0DF",
		9: "#FCF0DF",
		10: "#F2F2F2",
		12: "#EFEAFC",
		13: "#FCF0DF",
		14: "#FCF0DF",
	};
	const statusTextColor = {
		"-9": "#F54336",
		"-5": "#393939",
		"-4": "#F54336",
		"-3": "#F54336",
		0: "#FF9300",
		1: "#4EAF51",
		2: "#FF9300",
		3: "#410099",
		4: "#F54336",
		5: "#410099",
		8: "#FF9300",
		9: "#FF9300",
		10: "#393939",
		12: "#410099",
		13: "#FF9300",
		14: "#FF9300",
	};

	const columns = [
		{
			title: localString("Coverage Name"),
			showHeading: true,
			customCell: (row) => row?.DisplayText || "--",
		},

		{
			title: localString("Coverage Start Date"),
			showHeading: true,
			customCell: (row) =>
				(row?.ValidityStartDate &&
					moment(
						row?.ValidityStartDate,
						"YYYY-MM-DDTHH:mm:ssZ"
					).format(getDateFormat(defaultCountryLocale))) ||
				"--",
		},

		{
			title: localString("Claim Avail Date"),
			showHeading: true,
			customCell: (row) =>
				(row?.ClaimAvailDate &&
					moment(row?.ClaimAvailDate, "YYYY-MM-DDTHH:mm:ssZ").format(
						getDateFormat(defaultCountryLocale)
					)) ||
				"--",
		},
		{
			title: localString("Coverage End Date"),
			showHeading: true,
			customCell: (row) =>
				(row?.CoverageEndDate &&
					moment(row?.CoverageEndDate, "YYYY-MM-DDTHH:mm:ssZ").format(
						getDateFormat(defaultCountryLocale)
					)) ||
				"--",
		},

		{
			title: localString("No. Of Claims Allowed"),
			showHeading: true,
			customCell: (row) => row?.NoOfRepairsAllowed || "--",
		},

		{
			title: localString("Used Claims"),
			showHeading: true,
			customCell: (row) => row?.NoOfRepairsUsed || "--",
		},
	];

	const status = paymentData?.PlanDetails?.[0]?.Status;
	const cancellationDate =
		paymentData?.PlanDetails?.[0]?.PlanCancellationDate;

	const statusCheck =
		status == -4 ||
		status == 3 ||
		status == 4 ||
		(status == 1 && cancellationDate);

	return (
		<>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}

			<div className="inner-padding">
				<div className="row">
					<PageContentWrapper
						title={localString("Overview")}
						headerClassName="custom-header"
						bodyClassName="custom-body"
					>
						<div className="request-panel request-charges-div custom-third-party-charges pad-15">
							<div
								className="row col-sm-12"
								style={{
									display: "flex",
									marginBottom: "10px",
								}}
							>
								<p
									className="text-capitalize"
									style={{
										fontWeight: "600",
										marginRight: "12px",
									}}
								>
									{localString("Plan Details")}
								</p>
								<span
									className="statusPill"
									style={{
										background:
											statusColor[
												paymentData?.PlanDetails?.[0]
													?.Status
											],
										color: statusTextColor[
											paymentData?.PlanDetails?.[0]
												?.Status
										],
									}}
								>
									{statusText[
										paymentData?.PlanDetails?.[0]?.Status
									] || "--"}
								</span>
							</div>
							<div className="row">
								<div class="col-sm-3">
									<p className="text-capitalize overview-section-label">
										{localString("Plan Name")}
									</p>
									<p className="text-dark">
										{paymentData?.PlanDetails?.[0]
											?.PlanName || "--"}
									</p>
								</div>

								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Contract ID")}
									</p>
									<p className="text-dark">
										{paymentData?.PlanDetails?.[0]
											?.SoldPlanCode || "--"}
									</p>
								</div>

								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Policy ID")}
									</p>
									<p
										className="text-dark"
										style={{ overflowWrap: "break-word" }}
									>
										{paymentData?.PlanDetails?.[0]
											?.ExternalReferenceID || "--"}
									</p>
								</div>
								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Order ID")}
									</p>
									<p
										className="text-dark"
										style={{ overflowWrap: "break-word" }}
									>
										{paymentData?.PlanDetails?.[0]
											?.OrderID || "--"}
									</p>
								</div>

								<div class="col-sm-3">
									<p className="text-capitalize overview-section-label">
										{localString("Plan Code")}
									</p>
									<p className="text-dark">
										{paymentData?.PlanDetails?.[0]
											?.PlanCode || "--"}
									</p>
								</div>
							</div>
							<br />

							<div className="row">
								<div class="col-sm-3">
									<p className="text-capitalize overview-section-label">
										{localString("Payment Plan")}
									</p>
									<p className="text-dark">
										{localString(paymentData?.PlanDetails?.[0]
											?.PaymentFrequency) || "--"}
									</p>
								</div>
								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Plan Term")}
									</p>
									<p className="text-dark">
										{(paymentData?.PlanDetails?.[0]
											?.IsFixedTerm == true
											? paymentData?.PlanDetails?.[0]
													?.PlanValidity
											: localString("Non Fixed Term")) ||
											"--"}
									</p>
								</div>
								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Sales Channel")}
									</p>
									<p className="text-dark">
										{paymentData?.PlanDetails?.[0]
											?.Source || "--"}
									</p>
								</div>
								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Store Location")}
									</p>
									<p className="text-dark">
										{selectedPSL.ServiceLocationName ||
											"--"}
									</p>
								</div>
								<div class="col-sm-3">
									<p className="text-capitalize overview-section-label">
										{localString("Waiting Period")}
									</p>
									<p className="text-dark">
										{paymentData?.PlanDetails?.[0]
											?.CoolingPeriod || "--"}
									</p>
								</div>
							</div>
							<br />

							<div className="row">
								<div class="col-sm-3">
									<p className="text-capitalize overview-section-label">
										{localString("Plan Purchase Date")}
									</p>
									<p className="text-dark">
										{(paymentData?.PlanDetails?.[0]
											?.DateOfPurchase &&
											moment(
												paymentData?.PlanDetails?.[0]
													?.DateOfPurchase,
												"YYYY-MM-DDTHH:mm:ssZ"
											).format(
												getDateFormat(
													defaultCountryLocale
												)
											)) ||
											"--"}
									</p>
								</div>

								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Plan Activation Date")}
									</p>
									<p className="text-dark">
										{(paymentData?.PlanDetails?.[0]
											?.DateOfActivation &&
											moment(
												paymentData?.PlanDetails?.[0]
													?.DateOfActivation,
												"YYYY-MM-DDTHH:mm:ssZ"
											).format(
												getDateFormat(
													defaultCountryLocale
												)
											)) ||
											"--"}
									</p>
								</div>
								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Plan Creation Date")}
									</p>
									<p className="text-dark">
										{(paymentData?.PlanDetails?.[0]
											?.CreatedDate &&
											moment(
												paymentData?.PlanDetails?.[0]
													?.CreatedDate,
												"YYYY-MM-DDTHH:mm:ssZ"
											).format(
												getDateFormat(
													defaultCountryLocale
												)
											)) ||
											"--"}
									</p>
								</div>
								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Terms and Conditions")}
									</p>
									<a
										href={
											paymentData?.PlanDetails?.[0]
												?.TermsAndConditionsLink
										}
										target="_blank"
									>
										{localString("View")}
									</a>
								</div>

								{statusCheck && (
									<div class="col-sm-3">
										<p className="text-capitalize overview-section-label">
											{localString(
												"Plan Cancellation Initiation Date"
											)}
										</p>
										<p className="text-dark">
											{((paymentData?.PlanDetails?.[0]
												?.Status == "-4" ||
												3 ||
												4) &&
												paymentData?.PlanDetails?.[0]
													?.CancellationInitiationDate &&
												moment(
													paymentData
														?.PlanDetails?.[0]
														?.CancellationInitiationDate,
													"YYYY-MM-DDTHH:mm:ssZ"
												).format(
													getDateFormat(
														defaultCountryLocale
													)
												)) ||
												"--"}
										</p>
									</div>
								)}
							</div>
							<br />
							<div className="row">
								{statusCheck && (
									<div class="col-sm-3">
										<p className="text-capitalize overview-section-label">
											{localString(
												"Plan Cancellation Date"
											)}
										</p>
										<p className="text-dark">
											{((paymentData?.PlanDetails?.[0]
												?.Status == "-4" ||
												3 ||
												4) &&
												paymentData?.PlanDetails?.[0]
													?.PlanCancellationDate &&
												moment(
													paymentData
														?.PlanDetails?.[0]
														?.PlanCancellationDate,
													"YYYY-MM-DDTHH:mm:ssZ"
												).format(
													getDateFormat(
														defaultCountryLocale
													)
												)) ||
												"--"}
										</p>
									</div>
								)}

								{statusCheck && (
									<div class="col-sm-3">
										<p className="text-capitalize overview-section-label">
											{localString(
												"Cancellation Reason Description"
											)}
										</p>
										<p className="text-dark">
											{((paymentData?.PlanDetails?.[0]
												?.Status == "-4" ||
												3 ||
												4) &&
												paymentData?.PlanDetails?.[0]
													?.CancellationReasonDescription) ||
												"--"}
										</p>
									</div>
								)}

								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{""}
									</p>
									<p className="text-dark">{""}</p>
								</div>
								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{""}
									</p>
									<p className="text-dark">{""}</p>
								</div>
								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{""}
									</p>
									<p className="text-dark">{""}</p>
								</div>
							</div>
							<br />
							<hr />

							<div className="row">
								<div className="col-sm-12">
									<p className="text-capitalize overview-section-label">
										{localString("COVERAGE DETAILS")}
									</p>
								</div>
							</div>

							{paymentData?.SoldPlanCoverages?.length < 2 ? (
								<div className="row">
									<div class="col-sm-2">
										<p className="text-capitalize overview-section-label">
											{localString("Coverage Name")}
										</p>
										<p className="text-dark">
											{paymentData?.SoldPlanCoverages?.[0]
												?.DisplayText || "--"}
										</p>
									</div>
									<div class="col-sm-2">
										<p className="text-capitalize overview-section-label">
											{localString("Coverage Start Date")}
										</p>
										<p className="text-dark">
											{(paymentData
												?.SoldPlanCoverages?.[0]
												?.ValidityStartDate &&
												moment(
													paymentData
														?.SoldPlanCoverages?.[0]
														?.ValidityStartDate,
													"YYYY-MM-DDTHH:mm:ssZ"
												).format(
													getDateFormat(
														defaultCountryLocale
													)
												)) ||
												"--"}
										</p>
									</div>
									<div class="col-sm-2">
										<p className="text-capitalize overview-section-label">
											{localString("Claim Avail Date")}
										</p>
										<p className="text-dark">
											{(paymentData
												?.SoldPlanCoverages?.[0]
												?.ClaimAvailDate &&
												moment(
													paymentData
														?.SoldPlanCoverages?.[0]
														?.ClaimAvailDate,
													"YYYY-MM-DDTHH:mm:ssZ"
												).format(
													getDateFormat(
														defaultCountryLocale
													)
												)) ||
												"--"}
										</p>
									</div>
									<div class="col-sm-2">
										<p className="text-capitalize overview-section-label">
											{localString("Coverage End Date")}
										</p>
										<p className="text-dark">
											{(paymentData
												?.SoldPlanCoverages?.[0]
												?.CoverageEndDate &&
												moment(
													paymentData
														?.SoldPlanCoverages?.[0]
														?.CoverageEndDate,
													"YYYY-MM-DDTHH:mm:ssZ"
												).format(
													getDateFormat(
														defaultCountryLocale
													)
												)) ||
												"--"}
										</p>
									</div>
									<div class="col-sm-2">
										<p className="text-capitalize overview-section-label">
											{localString(
												"No. of Claims Allowed"
											)}
										</p>
										<p className="text-dark">
											{paymentData?.SoldPlanCoverages?.[0]
												?.NoOfRepairsAllowed || "--"}
										</p>
									</div>
									<div class="col-sm-2">
										<p className="text-capitalize overview-section-label">
											{localString("Used Claims")}
										</p>
										<p className="text-dark">
											{paymentData?.SoldPlanCoverages?.[0]
												?.NoOfRepairsUsed || "--"}
										</p>
									</div>
								</div>
							) : (
								<CommonTable
									columns={columns}
									data={paymentData?.SoldPlanCoverages?.map(
										(pdata) => {
											return {
												...pdata,
											};
										}
									)}
									localString={localString}
									tableClasses={{
										tableContainer: "coverage-table",
									}}
								/>
							)}

							<br />
							<hr />

							<div className="row">
								<div className="col-sm-12">
									<p className="text-capitalize overview-section-label">
										{localString("DEVICE DETAILS")}
									</p>
								</div>
							</div>
							<div className="row">
								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Serial Number") +
											" / " +
											localString("IMEI")}
									</p>
									<p className="text-dark">
										{paymentData?.DeviceDetails?.[0]
											?.ProductUniqueID || "--"}
									</p>
								</div>
								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Product Category")}
									</p>
									<p className="text-dark">
										{paymentData?.DeviceDetails?.[0]
											?.ProductSubcategory
											.ProductSubCategory || "--"}
									</p>
								</div>
								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Date of Purchase")}
									</p>
									<p className="text-dark">
										{(paymentData?.DeviceDetails?.[0]
											?.DateOfPurchase &&
											moment(
												paymentData?.DeviceDetails?.[0]
													?.DateOfPurchase,
												"YYYY-MM-DDTHH:mm:ssZ"
											).format(
												getDateFormat(
													defaultCountryLocale
												)
											)) ||
											"--"}
									</p>
								</div>
								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Device Purchase Price")} (
										<span
											dangerouslySetInnerHTML={{
												__html: paymentData
													?.PlanDetails?.[0]
													?.CurrencySymbol,
											}}
										/>
										)
									</p>
									<p className="text-dark">
										{/* {paymentData?.DeviceDetails?.[0]
											?.ProductPurchaseCost || "--"} */}
										{ paymentData?.DeviceDetails?.[0]?.ProductPurchaseCost ? 
											displayAmount(
												paymentData?.DeviceDetails?.[0]?.ProductPurchaseCost, // Amount
												(countryData?.countryConfig?.Locale?.DEFAULT || navigator?.language) // locale
											) : '--' }
									</p>
								</div>
								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Brand")}
									</p>
									<p className="text-dark">
										{paymentData?.DeviceDetails?.[0]?.brand
											.BrandName || "--"}
									</p>
								</div>
								<div class="col-sm-2">
									<p className="text-capitalize overview-section-label">
										{localString("Model")}
									</p>
									<p className="text-dark">
										{paymentData?.DeviceDetails?.[0]
											?.ModelNo || "--"}
									</p>
								</div>
							</div>
						</div>
					</PageContentWrapper>
				</div>
			</div>
		</>
	);
};

export default Overview;
