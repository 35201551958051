import React, {useState,useEffect} from "react";
import ReactDOM from "react-dom";
import { Loader, Dropdown,Pagination } from "sfy-react";
import Swal from "sweetalert2";
import { displayError,returnLocalDateTime } from "../../../../../Utility/Utils";
import PageContentWrapper from "../../../../../components/PageContentWrapper/PageContentWrapper";
import CommonTable from "../../../../../components/Table/Table";
import AddRemark from "./AddRemark";
import './RemarkDetails.scss'
const RemarkDetails = (props) =>{
    const { apiCall, localString, consumerServiceRequest,accessFn} = props;
    const [showLoader, setShowLoader] = useState(false);
    const [remarksDeatils,setRemarksDetails] = useState([]);
	const [showAddRemark,setShowAddRemark] = useState(false);
	const [addRemark,setAddRemark] = useState('');
	const [sortOrder,setSortOrder] = useState('DESC');
	const [pagination , setPagination] = useState({
		pagination :[],
		numberOfPages : 0,
		itemsPerPage : 10,
		range : 5,
		totalRecords:0,
		pageNo:1
	});

	const setCurrentPage = (pageNo) => {
		setPagination({...pagination,['pageNo']:pageNo});
	}

    useEffect(() => {
        getRemarks();
    }, [pagination.pageNo,sortOrder])
    const getRemarks = () => {
		let data = {
			consumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
            skipPagination: false,
            pagination: {
                pageNo: pagination.pageNo,
                range: pagination.range,
                itemsPerPage: pagination.itemsPerPage
            },
            sort: {
                "column": "updatedDate",
                "order": sortOrder
            }
		}
		setShowLoader(true);
		apiCall({
			url: "getRemarks",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
                    if(result.data){
                        setRemarksDetails(result.data.serviceRequestRemarks);
                    }
					setPagination((prevFilterData) => {
						return {
							...prevFilterData,
							pagination : result.data.paginationData.pagination,
							numberOfPages : result.data.paginationData.numberOfPages,
							itemsPerPage : result.data.paginationData.itemsPerPage,
							range : result.data.paginationData.range,
							totalRecords:result.data.paginationData.totalRecords
						};
					});
				} else {
					Swal.fire("", localString(displayError(result)), "error");
				}
			},
		});
	};


	const getAddRemark = () => {
		let data = {
			"consumerServiceRequestID": consumerServiceRequest.ConsumerServiceRequestID,
			"status": consumerServiceRequest.Status,
			"remarks": addRemark
		}
		setShowAddRemark(false);
		setShowLoader(true);
		apiCall({
			url: "addRemarks",
			data: data,
			callBack: (result) => {
				setShowLoader(false);
				if (result.success) {
                    if(result.data){
						getRemarks();
						setAddRemark('');
						setPagination({
							pageNo: 1,
							range: 5,
							itemsPerPage: 10
						});
                    }
				} else {
					Swal.fire("", localString(displayError(result)), "error");
				}
			},
		});
	};

	const handleAddRemark = () =>{
		setShowAddRemark(true);
	}
	const closeModal = () =>{
		setShowAddRemark(false);
		setAddRemark('');
	}

    const columns = [
		{
			title: localString("User"),
			showHeading: true,
			customCell: (row) => row.createdByUsername || '--',
		},
		{
			title: localString("Date and Time"),
			showHeading: true,
			customCell: (row) => returnLocalDateTime(row.createdDate,'YYYY-MM-DD, HH:mm:ss') || '--',
			showSortIcons:true,
			activeSortIcon:sortOrder == 'ASC'?'up':'down',
			handleSort:()=>{
				let order = sortOrder=='ASC' ? 'DESC' : 'ASC';
				setSortOrder(order);
				setPagination({
					pageNo: 1,
					range: 5,
					itemsPerPage: 10
				});
			}
		},
		{
			title: localString("Status"),
			showHeading: true,
			customCell: (row) => row.status || '--',
		},
		{
			title: localString("Remark"),
			showHeading: true,
			customCell: (row) => row.remarks || '--',
		},
		
	];
    return(
        <>
			{showLoader &&
				ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}
			<div className="padding10 remarkDetailsTab">
				<div className="row">
					<PageContentWrapper
						title={localString('Remarks')}
						bodyClassName="panel-body-no-padding" 
						rightButtonText = {localString('Add Remark')}
						showRightButton={accessFn('ADD_REMARKS')}
						rightButtonCB = { handleAddRemark}
					>
						<div>
							<CommonTable
								columns={columns}
								data={remarksDeatils}
								localString={localString}
							/>
						</div>
						{
							pagination.numberOfPages > 1 && 
							<Pagination 
								itemsPerPage={pagination.itemsPerPage} 
								pages={pagination.numberOfPages} 
								activePage={pagination.pageNo} 
								paginate={(pageNumber) => { setCurrentPage(pageNumber) }}
								// paginationInfo={request.length+" out of " + currentTabCount }
								/>
						}
					</PageContentWrapper>
				</div>
			</div>
			{
				showAddRemark && 
				<AddRemark
					{...props}
					showModal={showAddRemark}
					closeModal={closeModal}
					setShowAddRemark ={setShowAddRemark}
					addRemark = {addRemark}
					setAddRemark={setAddRemark}
					getAddRemark = {getAddRemark}
				/>
			}
			
		</>
    )
}
export default RemarkDetails;