import React, {useState,useEffect} from 'react'
import ReactDOM from "react-dom";
import { Input, Modal, ModalBody, ModalHeader, Radio, Button, Loader, Dropdown} from "sfy-react";
import './InitiateDiagnosis.scss';
import ButtonSwitchInput from '../ButtonSwitchInput/ButtonSwitchInput';
import {checkAlphaNumeric, checkExternalRefID, displayError, encrypt} from '../../Utility/Utils';
import Swal from "sweetalert2";
import QRCode from "qrcode.react";
import {groupBy, forEach, filter, map} from 'lodash';


const InitiateDiagnosis = (props) => {
    const {showModal, onCloseClick, localString, accessFn, directDiagnosis, ConsumerID, ConsumerProductID, showQRSection, csrID, apiCall, imeiDiagnosisPageUrl, baseUrl,setShowModal,getRequestById} = props;
	const qFilter = JSON.parse(props.qFilter);
    const hideFullDiagnosisBtn = qFilter?.Channel && ['AHS'].includes(qFilter.Channel);
    const [fullDiagnosis, setFullDiagnosis] = useState(!hideFullDiagnosisBtn ? true : false);
    const [refID ,setRefID] = useState('');
    const [isRefIdValid ,setIsRefIdValid] = useState(false);
    const [customerImei ,setCustomerImei] = useState('');
    const [isImeiValid ,setIsImeiValid] = useState(false);
    const [useQrCode, setUseQrCode] = useState(true);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [showGeneratedQr, setShowGeneratedQr] = useState(false);
    const [qrCodeString, setQrCodeString] = useState('');
    const [showLoader ,setLoader] = useState(false);
    const [diagnosisCompleted ,setDiagnosisCompleted] = useState(false);
    const [diagnosisTypeResult, setDiagnosisTypeResult] = useState([]);
    const [diagnosisGroups, setDiagnosisGroups] = useState({});
    const [sortedDiagnosisGroups, setSortedDiagnosisGroups] = useState([]);
    const inputClassWrapper = {
        formControl: 'diagnosis-input'
    };
    const [customDiagnosisButtons, setCustomDiagnosisButtons] = useState([]);
    const diagnosisInputs = [
        {
            label: localString(`Would you like to perform a ${hideFullDiagnosisBtn ? 'custom' : 'full/custom'} diagnosis?`),
            switchButton: true,
            switchButtonData: [
            !hideFullDiagnosisBtn && {
                label: localString('Full'),
                isSelected: fullDiagnosis,
                type: 'full',
                onButtonSwitch: (val) => toggleDiagnosis(val)
            }, 
            {
                label: localString('Custom'),
                isSelected: !fullDiagnosis,
                type: 'custom',
                onButtonSwitch: (val) => toggleDiagnosis(val)
            }]
        },
        {
            label: localString("Select from the following to create a custom diagnosis"),
            hideBtnSection: fullDiagnosis,
            btnGroups: diagnosisGroups
        },
        showQRSection && {
            switchButton: true,
            qrSection: true,
            label: !directDiagnosis && localString("Select from the following to create a custom diagnosis"),
            switchButtonData: [!directDiagnosis && {
                    label: localString('Using Notification'),
                    isSelected: !useQrCode,
                    type: 'notification',
                    onButtonSwitch: (val) => setUseQrCode(val === 'qrcode' ? true : false)
                },
                {
                    label: localString('Using QR Code'),
                    isSelected: useQrCode,
                    type: 'qrcode',
                    onButtonSwitch: (val) => setUseQrCode(val === 'qrcode' ? true : false)
                }
            ]
        },
        directDiagnosis && {
            value: customerImei,
            maxlength: 16,
            changeFunc: (val) => checkCustomerImei(val),
            label: localString('IMEI / Serial Number'),
            isValid: isImeiValid,
            textInput: true
        },
        directDiagnosis && accessFn('SEARCH_BY_REFID') && {
            value: refID,
            maxlength: 19,
            changeFunc: (val) => checkRefid(val),
            label: localString('External Reference ID'),
            isValid: isRefIdValid,
            showErrorMesage: true,
            errorMessage: localString('Please enter in the format 123-1234567-7654321'),
            textInput: true
        }
    ];

    useEffect(() => {
        setUseQrCode(directDiagnosis);
        enableSubmitBtn();
        getTestPlan();
    },[]);

    useEffect(() => {
        enableSubmitBtn();
    },[fullDiagnosis, refID, customerImei, customDiagnosisButtons]);

    const enableSubmitBtn = () => {
        if((!fullDiagnosis && customDiagnosisButtons.length > 0) || fullDiagnosis) {
            if(directDiagnosis) {
                if(isImeiValid && ((accessFn('SEARCH_BY_REFID') && isRefIdValid) || !accessFn('SEARCH_BY_REFID'))) {
                    setIsSubmitDisabled(false);
                } else {
                    setIsSubmitDisabled(true);
                }
            } else {
                setIsSubmitDisabled(false);
            }
        } else {
            setIsSubmitDisabled(true);
        }
    };

    const checkRefid = (val) => {
        setRefID(val);
        setIsRefIdValid(checkExternalRefID(val) && (val.length == 19) ? true : false);
    };

    const checkCustomerImei = (val) => {
        setCustomerImei(val);
        setIsImeiValid(checkAlphaNumeric(val) && (val.length > 6 && val.length < 17) ? true : false);
    };

    const resetFields = () => {
        setFullDiagnosis(!hideFullDiagnosisBtn ? true : false);
        setCustomerImei('');
        setIsImeiValid(false);
        setRefID('');
        setIsRefIdValid(false);
        setIsSubmitDisabled(true);
        setShowGeneratedQr(false);
        setDiagnosisCompleted(false);
        resetCustomDiagnosisData();
    };

    const closeModal = () => {
        onCloseClick();
        resetFields();
    };

    const onSubmitClick = () => {
        Swal.fire({
            title: localString("Are you sure?"),
            showCancelButton: true
        }).then((result) => {
            if(result.isConfirmed) {
                useQrCode ? initiateDiagnosisQR() : initiateDiagnosisNormal();
            }
        })
    };

    const getTestPlan = () => {
		apiCall({
			url:'getTestPlan',
			data: {},
			callBack:(result)=>{
				if(result.success && result.data){
                    setDiagnosisTypeResult(result.data);
                    let data = groupBy(result.data, "groupName");
                    let arr = [];
                    Object.keys(data).map((item, k) => {
                        if(data[item] && data[item].length && data[item][0].sequence) {
                            arr[data[item][0].sequence] = item;
                        }else {
                            arr.push(item);
                        }
                    })
                    setSortedDiagnosisGroups(arr);
                    setDiagnosisGroups(data);
				} else {
					Swal.fire({icon: 'error', text: localString(displayError(result)),}); 
				}
			}	
		});
    }

    const initiateDiagnosisNormal = () => {
        var request = {
            ConsumerID: ConsumerID,
            ConsumerProductID: ConsumerProductID,
            prompt: true,
            full: fullDiagnosis,
            DiagnosisList : fullDiagnosis ? getFullDiadgnosisList() : customDiagnosisButtons,
            CustomDiagnosis: !fullDiagnosis ? customDiagnosisButtons : undefined,
			DiagnosisTestList: fullDiagnosis ? getFullTestsList() : customTestsList()
        };
        setLoader(true);
		apiCall({
			url:'initiateDiagnosis',
			data: request,
			callBack:(result)=>{
                setLoader(false);
				if(result.success){
                    Swal.fire({icon: 'success', text: localString("Diagnosis attempted")}); 
                    if(csrID) {
                        setLoader(true);
                        let reqObj = {
                            ConsumerServiceRequestID: csrID,
                            Status: 'Diagnosis initiated'
                        }
                        apiCall({
                            url: 'servicerequestFulfilmentWorkshop',
                            data: reqObj,
                            callBack:(result)=>{
                                setLoader(false);
                                if(result.success){
                                    getRequestById && getRequestById();
                                } else {
                                    Swal.fire({icon: 'error', text: localString(displayError(result))});  
                                }
                            }	
                        });
                    }
                    closeModal();
				} else {
                    Swal.fire({icon: 'error', text: localString(displayError(result)),}); 
				}
			}	
		});
    }

    const initiateDiagnosisQR = () => {
        var request = {
            ConsumerID: ConsumerID,
            ConsumerProductID: ConsumerProductID,
            prompt: true,
            full: fullDiagnosis,
            EntityID : csrID,
            Entity : directDiagnosis ? "Diagnosis Request" : "Service Request",
            Full : fullDiagnosis ? 1 : 0,
            Prompt : 1,
            DiagnosisList : fullDiagnosis ? getFullDiadgnosisList() : customDiagnosisButtons,
            CustomDiagnosis: !fullDiagnosis ? customDiagnosisButtons : undefined,
			DiagnosisTestList: fullDiagnosis ? getFullTestsList() : customTestsList(),
            ExternalReferenceID: refID ? refID : undefined,
            eid: customerImei ? customerImei : undefined
        };
        if(!request.DiagnosisList.length || !request.DiagnosisTestList.length) {
            Swal.fire({
                icon: 'error',
                text: localString("Something went wrong. Please try again later"),
            }).then((result) => {
                window.location.reload();
            })
            return;
        }
		setLoader(true);
		apiCall({
			url:'generateQrCode',
			data: request,
			callBack:(result)=>{
		        setLoader(false);
				if(result.success && result.data){
                    setQrCodeString(result.data.QrCode);
                    setShowGeneratedQr(true);
				} else {
					Swal.fire({icon: 'error', text: localString(displayError(result)),});  
				}
			}	
		});
    };

    const checkDiagnosisStatus = () => {
		setLoader(true);
        apiCall({
			url:'checkDiagnosisDetail',
			data: {QrCode: qrCodeString},
			callBack:(result)=>{
		        setLoader(false);
				if(result.success && result.data){
                    setDiagnosisCompleted(result.data.diagnosis_status ? true : false);
				} else {
					Swal.fire({icon: 'error', text: localString(displayError(result)),});
				}
			}	
		});
    };
    
    const openDiagnosisResult = () => {
		window.open(baseUrl + imeiDiagnosisPageUrl +'?refno=' + encrypt(refID), '_blank');
    }
    
    const selectCustomType = (groupName, groupObj) => {
        let newArray = [...customDiagnosisButtons];
        let typeIndex = newArray.indexOf(groupName);
		groupObj = forEach(groupObj, (o) => { 
            o.isSelected = (typeIndex > -1) ? false : !o.isSelected;
        });
        diagnosisGroups[groupName] = groupObj;
        setDiagnosisGroups({...diagnosisGroups});
        if (typeIndex > -1 && newArray.length > 0) {
            newArray.splice(typeIndex, 1)
            setCustomDiagnosisButtons(newArray);
        } else {
            newArray.push(groupName)
            setCustomDiagnosisButtons(newArray);
        }
    }

    const checkDiagnosisType = (singleBtn, index) => {
        singleBtn.isSelected = !singleBtn.isSelected;
        let newArray = [...customDiagnosisButtons];
        let typeIndex = newArray.indexOf(singleBtn.groupName);
        let allUnselected, count = 0;
        diagnosisGroups[singleBtn.groupName][index].isSelected = singleBtn.isSelected;
        setDiagnosisGroups({...diagnosisGroups});
        diagnosisGroups[singleBtn.groupName].forEach((obj) => {
            if(!obj.isSelected) {
                count++
            }
        });
        allUnselected = count === diagnosisGroups[singleBtn.groupName].length;
        if (typeIndex > -1 && allUnselected) {
            newArray.splice(typeIndex, 1)
            setCustomDiagnosisButtons(newArray);
        } else if(typeIndex === -1) {
            newArray.push(singleBtn.groupName)
            setCustomDiagnosisButtons(newArray);
        }
    }
    
    const customTestsList = () => {
        let tests = [];
		tests = filter(diagnosisTypeResult, function (o) { return o.isSelected == true; });
		if (tests.length > 0) {
			tests = map(tests, 'testName');
        }
        return tests.length > 0 ? tests : [];
    }

    const getFullTestsList = () => {
        let fullTestList = [];
        if(diagnosisTypeResult && diagnosisTypeResult.length > 0) {
            forEach(diagnosisTypeResult, (obj) => {
                fullTestList.push(obj.testName)
            });
        }
        return fullTestList.length > 0 ? fullTestList : [];
    };

    const getFullDiadgnosisList = () => {
        let list = [];
        if(diagnosisGroups) {
            let keys = Object.keys(diagnosisGroups);
            keys && keys.length > 0 && forEach(keys, (obj) => {
                list.push(obj)
            });
        }
        return list.length > 0 ? list : [];
    };

    const toggleDiagnosis = (val) => {
        setFullDiagnosis(val === 'full' ? true : false)
        resetCustomDiagnosisData();
    }

    const resetCustomDiagnosisData = () => {
        setCustomDiagnosisButtons([]);
        let newList = [...diagnosisTypeResult];
        forEach(newList, (obj) => {
            obj.isSelected = false;
        });
        setDiagnosisTypeResult(newList);
    };

    const isBtnActive = (btnName) => {
        return customDiagnosisButtons.length > 0 && customDiagnosisButtons.indexOf(btnName) > -1 ? true : false
    };

    const addParentClass = (index) => {
        document.getElementById("custom-btns-container" + index).classList.add('open')
    };
    return (
        <Modal 
            showModal={showModal}
            showCloseBtn={true}
            backdropClassName={"diagnosis-modal"}
            closeBtnClassName={"modal-close"}
            closeModal={closeModal}
            className={"modal-wrapper"}
        >
            <ModalHeader>
                <h4 class="modal-title">
                    {showGeneratedQr ? localString("Scan the QR code from Diagnosis App") : localString("Initiate diagnosis")}
                </h4>
            </ModalHeader>
            <ModalBody>
                {!showGeneratedQr && <>
                    {diagnosisInputs && diagnosisInputs.length > 0 && diagnosisInputs.map((obj, i) => {
                        return (!obj.hideBtnSection &&
                        <>
                            <label>{obj.label}</label>
                            {obj.switchButton && obj.switchButtonData && obj.switchButtonData.length > 0 &&
                                <ButtonSwitchInput switchButtons={obj.switchButtonData} btnWrapperClass={'diagnosis-btns ' + (obj.qrSection && directDiagnosis ? 'single-btn' : '')}></ButtonSwitchInput>
                            }
                            {obj.btnGroups && Object.keys(obj.btnGroups).length > 0 && 
                                <div className="radio-wrapper">
                                    {/* {Object.keys(obj.btnGroups).map((btn, index) => { */}
                                    { sortedDiagnosisGroups?.length && sortedDiagnosisGroups.map((btn, index) => {
                                        if(btn) {
                                            return (
                                                <span className="custom-btns-container" id={"custom-btns-container" + index}>    
                                                    <Button 
                                                        type= 'button'
                                                        classes={"button-servify-solid custom-btn" + (isBtnActive(btn) ? " active" : "")} 
                                                        text={localString(btn)}
                                                        key={index}
                                                        handleOnClick={() => selectCustomType(btn, obj.btnGroups[btn])}
                                                    >
                                                    </Button>
                                                    <Button
                                                        type= 'button'
                                                        classes="dropdown-toggle dropdown-toggle-split button-servify-solid type-drop-button-servify" 
                                                        key={index}
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false" auto-close="outside"
                                                    >
                                                        <span className="glyphicon glyphicon-chevron-down" style={{ padding: '3px 0px' }}></span>
                                                    </Button>
                                                    <div className="dropdown-menu diagnosis-btn-dropdown" onClick={() => {addParentClass(index)}}>
                                                        <div className="dropdown-item">
                                                        <>
                                                        {obj.btnGroups[btn] && obj.btnGroups[btn].length > 0 &&
                                                        obj.btnGroups[btn].map((singleBtn, btnIndex) => {
                                                        return(
                                                            <label className="diagnosis-checkbox-container">
                                                                <input type="checkbox" checked={singleBtn.isSelected} onClick={() => {checkDiagnosisType(singleBtn, btnIndex)}} />
                                                                <span className="checkmark"></span>
                                                                <span className="checkmark-label">{singleBtn.displayName}</span>
                                                            </label>
                                                        )})}
                                                        </>
                                                        </div>
                                                    </div>
                                                </span>    
                                            )}
                                    })}
                                </div>
                            }
                            {obj.textInput && 
                                <Input
                                    value = {obj.value}
                                    handleOnChange = {(e) => obj.changeFunc(e.target.value)}
                                    maxLength = {obj.maxlength}
                                    key={i}
                                    className= {inputClassWrapper}
                                    message={!obj.isValid && obj.value.length > 0 && obj.showErrorMesage && {type: 'error', value: obj.errorMessage}}
                                ></Input>
                            }
                        </>    
                        )
                    })}
                    <div className="btn-wrapper text-right">
                        <Button type='button' classes="button-servify-solid" handleOnClick={onSubmitClick} isDisabled={isSubmitDisabled} text={localString("Initiate Diagnosis")}></Button>
                    </div>
                    <div className="modal-footer text-right">
                        <Button type='button' classes="button-servify-solid" handleOnClick={closeModal} text={localString("Close")}></Button>
                    </div>
                </>}
                {showGeneratedQr &&
                <>
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <QRCode value={qrCodeString} size={256}/>
                        </div>
                    </div>
                    {qrCodeString && <p className="qr-text">{localString("Code to perform diagnosis: " + qrCodeString)}</p>}
                    <div className="modal-footer text-right button-width">
                        {accessFn('SEARCH_BY_REFID') && !diagnosisCompleted && <Button type='button' classes="button-servify-solid" handleOnClick={checkDiagnosisStatus} text={localString("Check Status")}></Button>}
                        {accessFn('SEARCH_BY_REFID') && diagnosisCompleted && <Button type='button' classes="button-servify-solid" handleOnClick={openDiagnosisResult} text={localString("View Result")}></Button>}
                        <Button type='button' classes="button-servify-solid" handleOnClick={closeModal} text={localString("Close")}></Button>
                    </div>
                </>
                }
                { showLoader && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)}
            </ModalBody>
        </Modal>
    )
}

export default InitiateDiagnosis
