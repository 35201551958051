const lang = {
  Consumers: {
    text: "Müşteriler",
    isAlreadyTranslated: true
  },
  "Clear Search": {
    text: "Aramayı Temizle",
    isAlreadyTranslated: true
  },
  "Filter Consumer": {
    text: "Müşteriyi Filtrele",
    isAlreadyTranslated: true
  },
  "Add Consumers": {
    text: "Müşterileri Ekle",
    isAlreadyTranslated: true
  },
  Mobile: {
    text: "Cep Telefonu",
    isAlreadyTranslated: true
  },
  "Device Type": {
    text: "Cihaz Tipi",
    isAlreadyTranslated: true
  },
  "Mobile No.": {
    text: "Cep Numarası",
    isAlreadyTranslated: true
  },
  Model: {
    text: "Model",
    isAlreadyTranslated: true
  },
  "Alternate No.": {
    text: "Alternatif No.",
    isAlreadyTranslated: true
  },
  "Landline No": {
    text: "Sabit Hat Numarası",
    isAlreadyTranslated: true
  },
  "View Consumer": {
    text: "Müşteriyi Görüntüle",
    isAlreadyTranslated: true
  },
  Add: {
    text: "Ekle ",
    isAlreadyTranslated: true
  },
  Send: {
    text: "Gönder",
    isAlreadyTranslated: true
  },
  SMS: {
    text: "SMS",
    isAlreadyTranslated: true
  },
  Email: {
    text: "E-posta",
    isAlreadyTranslated: true
  },
  First: {
    text: "İlk",
    isAlreadyTranslated: true
  },
  Last: {
    text: "Son",
    isAlreadyTranslated: true
  },
  Filter: {
    text: "Filtrele",
    isAlreadyTranslated: true
  },
  Customer: {
    text: "Müşteri",
    isAlreadyTranslated: true
  },
  Close: {
    text: "Kapat",
    isAlreadyTranslated: true
  },
  Call: {
    text: "Arayın",
    isAlreadyTranslated: true
  },
  logs: {
    text: "Kayıtlar",
    isAlreadyTranslated: true
  },
  Consumer: {
    text: "Müşteri",
    isAlreadyTranslated: true
  },
  Subcategory: {
    text: "Alt Kategori",
    isAlreadyTranslated: true
  },
  Brand: {
    text: "Marka",
    isAlreadyTranslated: true
  },
  Product: {
    text: "Ürün",
    isAlreadyTranslated: true
  },
  Apply: {
    text: "Uygula",
    isAlreadyTranslated: true
  },
  Dashboard: {
    text: "Kontrol Paneli",
    isAlreadyTranslated: true
  },
  Requests: {
    text: "Talepler",
    isAlreadyTranslated: true
  },
  "View Document": {
    text: "Belgeyi Görüntüle",
    isAlreadyTranslated: true
  },
  Logout: {
    text: "Çıkış Yap",
    isAlreadyTranslated: true
  },
  Notifications: {
    text: "Bildirimler",
    isAlreadyTranslated: true
  },
  Coupons: {
    text: "Kuponlar",
    isAlreadyTranslated: true
  },
  UserList: {
    text: "Kullanıcı Listesi",
    isAlreadyTranslated: true
  },
  Curate: {
    text: "Sergile",
    isAlreadyTranslated: true
  },
  Diagnosis: {
    text: "Tanı",
    isAlreadyTranslated: true
  },
  Distributor: {
    text: "Distribütör",
    isAlreadyTranslated: true
  },
  "Enterprise Sales": {
    text: "Kurumsal Satış",
    isAlreadyTranslated: true
  },
  Reseller: {
    text: "Bayi",
    isAlreadyTranslated: true
  },
  "Plan Management": {
    text: "Plan Yönetimi",
    isAlreadyTranslated: true
  },
  "Sell Plans": {
    text: "Sigorta Satışı",
    isAlreadyTranslated: true
  },
  "Partner Management": {
    text: "İş Ortağı Yönetimi",
    isAlreadyTranslated: true
  },
  "Logistics Requests": {
    text: "Lojistik Talepler",
    isAlreadyTranslated: true
  },
  Catalogue: {
    text: "Katalog",
    isAlreadyTranslated: true
  },
  Source: {
    text: "Kaynak",
    isAlreadyTranslated: true
  },
  "Device Count": {
    text: "Cihaz Sayısı",
    isAlreadyTranslated: true
  },
  "Reg/Unreg": {
    text: "Kayıtlı/Kayıtsız",
    isAlreadyTranslated: true
  },
  "Phone Number": {
    text: "Telefon Numarası",
    isAlreadyTranslated: true
  },
  "Mobile No": {
    text: "Cep Numarası",
    isAlreadyTranslated: true
  },
  Edit: {
    text: "Düzenle",
    isAlreadyTranslated: true
  },
  "View Request": {
    text: "Talebi Görüntüle",
    isAlreadyTranslated: true
  },
  "Raise A Request": {
    text: "Bir Talep Oluştur",
    isAlreadyTranslated: true
  },
  "Bills/Documents": {
    text: "Faturalar/Dökümanlar",
    isAlreadyTranslated: true
  },
  "Explore Device Lifecycle": {
    text: "Cihaz Ömrünü Kontrol Et",
    isAlreadyTranslated: true
  },
  History: {
    text: "Tarihçe",
    isAlreadyTranslated: true
  },
  "Year Of Purchase": {
    text: "Satın Alma Yılı",
    isAlreadyTranslated: true
  },
  "Date Of Purchase": {
    text: "Satın Alma Tarihi",
    isAlreadyTranslated: true
  },
  IMEI: {
    text: "IMEI",
    isAlreadyTranslated: true
  },
  "Serial No.:": {
    text: "Seri No:",
    isAlreadyTranslated: true
  },
  "Initiate diagnosis": {
    text: "Tanıyı başlat",
    isAlreadyTranslated: true
  },
  "Initiate diagnosis directly in the Servify App": {
    text: "Tanıyı doğrudan Servify uygulamasında başlatın",
    isAlreadyTranslated: true
  },
  "No diagnosis performed": {
    text: "Tanı yapılamadı.",
    isAlreadyTranslated: true
  },
  "View Diagnostic Result": {
    text: "Tanı Sonucunu Görüntüle",
    isAlreadyTranslated: true
  },
  "Last Diagnosis Performed on": {
    text: "Yapılan Son Tanı",
    isAlreadyTranslated: true
  },
  "No Issues Found": {
    text: "Herhangi bir sorun bulunamadı",
    isAlreadyTranslated: true
  },
  "Found": {
    text: "Bulunan",
    isAlreadyTranslated: true
  },
  "Issue": {
    text: "Sorun",
    isAlreadyTranslated: true
  },
  "Created Date": {
    text: "Oluşturulma Tarihi",
    isAlreadyTranslated: true
  },
  "Model No": {
    text: "Model Numarası",
    isAlreadyTranslated: true
  },
  "Tag Name": {
    text: "Etiket adı",
    isAlreadyTranslated: true
  },
  "Product Subcategory": {
    text: "Ürün Alt Kategorisi",
    isAlreadyTranslated: true
  },
  "Created On": {
    text: "Oluşturulma Tarihi",
    isAlreadyTranslated: true
  },
  "Alternate Mobile No": {
    text: "Alternatif Telefon Numarası",
    isAlreadyTranslated: true
  },
  "Add Product": {
    text: "Ürün Ekle",
    isAlreadyTranslated: true
  },
  "Upload Documents": {
    text: "Dökümanları Yükle",
    isAlreadyTranslated: true
  },
  "Product Name": {
    text: "Ürün adı",
    isAlreadyTranslated: true
  },
  "Product SubCategory": {
    text: "Ürün Alt Kategorisi",
    isAlreadyTranslated: true
  },
  "View Consumer Details": {
    text: "Müşteri Detaylarını Görüntüle",
    isAlreadyTranslated: true
  },
  "Call History": {
    text: "Çağrı Geçmişi",
    isAlreadyTranslated: true
  },
  "Unregistered Products": {
    text: "Kayıtlı Olmayan Ürünler",
    isAlreadyTranslated: true
  },
  "Registered Products": {
    text: "Kayıtlı Ürünler",
    isAlreadyTranslated: true
  },
  "No unregistered products found": {
    text: "Kayıtlı ürün bulunamadı",
    isAlreadyTranslated: true
  },
  Content: {
    text: "İçerik",
    isAlreadyTranslated: true
  },
  "Customer Name": {
    text: "Müşteri Adı",
    isAlreadyTranslated: true
  },
  "Reference ID": {
    text: "Servis Takip No",
    isAlreadyTranslated: true
  },
  "Scheduled Date": {
    text: "Randevu Tarihi",
    isAlreadyTranslated: true
  },
  Type: {
    text: "Tür",
    isAlreadyTranslated: true
  },
  "Add call log": {
    text: "Çağrı kaydı ekle",
    isAlreadyTranslated: true
  },
  "Call type": {
    text: "Çağrı türü",
    isAlreadyTranslated: true
  },
  Remarks: {
    text: "Notlar",
    isAlreadyTranslated: true
  },
  "No call history": {
    text: "Çağrı geçmişi bulunmamaktadır",
    isAlreadyTranslated: true
  },
  "Please enter alphabets only": {
    text: "Lütfen sadece harf giriniz",
    isAlreadyTranslated: true
  },
  "Please enter correct EmailID": {
    text: "Lütfen doğru e-posta adresini giriniz",
    isAlreadyTranslated: true
  },
  "Please enter correct Name": {
    text: "Lütfen doğru isim giriniz",
    isAlreadyTranslated: true
  },
  "Please enter correct Alternate Mobile Number": {
    text: "Lütfen doğru Alternatif Telefon Numarısını giriniz",
    isAlreadyTranslated: true
  },
  Incoming: {
    text: "Gelen",
    isAlreadyTranslated: true
  },
  Outgoing: {
    text: "Giden",
    isAlreadyTranslated: true
  },
  No: {
    text: "Hayır",
    isAlreadyTranslated: true
  },
  "Call history": {
    text: "Çağrı geçmişi",
    isAlreadyTranslated: true
  },
  "No Data Available": {
    text: "Mevcut Veri Bulunamadı",
    isAlreadyTranslated: true
  },
  "Select a model": {
    text: "Bir model seçin",
    isAlreadyTranslated: true
  },
  "Customer Alternate Mobile No": {
    text: "Müşteri Alternatif Telefon Numarası",
    isAlreadyTranslated: true
  },
  "Incident ID": {
    text: "Olay kimliği",
    isAlreadyTranslated: true
  },
  "Select an issue": {
    text: "Bir sorun seçiniz",
    isAlreadyTranslated: true
  },
  "Please enter Customer's Name": {
    text: "Lütfen müşterinin adını giriniz",
    isAlreadyTranslated: true
  },
  "Please select a model from the dropdown": {
    text: "Lütfen listeden bir model seçiniz",
    isAlreadyTranslated: true
  },
  "Please enter at least one issue": {
    text: "Lütfen en az bir sorun giriniz",
    isAlreadyTranslated: true
  },
  "Please select a Device Type": {
    text: "Lütfen bir Cihaz Türü seçin",
    isAlreadyTranslated: true
  },
  "Please enter Mobile Number": {
    text: "Lütfen Telefon Numarasını Giriniz",
    isAlreadyTranslated: true
  },
  "Please enter correct Mobile Number": {
    text: "Lütfen doğru Telefon Numarası giriniz",
    isAlreadyTranslated: true
  },
  "Please enter correct E-mail ID": {
    text: "Lütfen doğru e-posta adresi giriniz",
    isAlreadyTranslated: true
  },
  "Consumer successfully added and SMS sent": {
    text: "Müşteri başarıyla eklendi ve SMS gönderildi",
    isAlreadyTranslated: true
  },
  "Something went wrong when trying to create a consumer. Please try again": {
    text:
      "Müşteri oluşturulurken bir hata meydana geldi. Lütfen tekrar deneyiniz",
    isAlreadyTranslated: true
  },
  "You have already added this issue type": {
    text: "Bu sorun türünü zaten eklediniz",
    isAlreadyTranslated: true
  },
  "Please select a Product from the dropdown": {
    text: "Lütfen listeden bir ürün seçiniz",
    isAlreadyTranslated: true
  },
  "Please select a Brand from the dropdown": {
    text: "Lütfen listeden bir marka seçiniz",
    isAlreadyTranslated: true
  },
  "Please select a Subcategory from the dropdown": {
    text: "Lütfen listeden bir alt kategori seçiniz",
    isAlreadyTranslated: true
  },
  "Select a year between": {
    text: "Yıl aralığı seçiniz",
    isAlreadyTranslated: true
  },
  and: {
    text: "ve",
    isAlreadyTranslated: true
  },
  "Please select a document type": {
    text: "Lütfen bir belge türü seçiniz",
    isAlreadyTranslated: true
  },
  "Please select a document": {
    text: "Lütfen bir belge seçiniz",
    isAlreadyTranslated: true
  },
  "Oops... Something went wrong": {
    text: "Oops.. Bir şeyler ters gitti",
    isAlreadyTranslated: true
  },
  "No requests found": {
    text: "Talep bulunamadı",
    isAlreadyTranslated: true
  },
  OK: {
    text: "tamam",
    isAlreadyTranslated: true
  },
  REQUESTS: {
    text: "TALEPLER",
    isAlreadyTranslated: true
  },
  "Claim Request": {
    text: "Talep İste",
    isAlreadyTranslated: true
  },
  "In Progress": {
    text: "Devam ediyor",
    isAlreadyTranslated: true
  },
  Closed: {
    text: "Kapalı",
    isAlreadyTranslated: true
  },
  Cancelled: {
    text: "İptal Edildi",
    isAlreadyTranslated: true
  },
  Open: {
    text: "Açık",
    isAlreadyTranslated: true
  },
  Period: {
    text: "Süre",
    isAlreadyTranslated: true
  },
  "Filter Requests": {
    text: "Talepleri Filtrele",
    isAlreadyTranslated: true
  },
  "Create Bulk Request": {
    text: "Toplu Talep Oluştur",
    isAlreadyTranslated: true
  },
  "Raise a request": {
    text: "Yeni Servis Talebi Oluştur",
    isAlreadyTranslated: true
  },
  "External Requests": {
    text: "Dış Talepler",
    isAlreadyTranslated: true
  },
  "Parts Queue": {
    text: "Sıradaki Parçalar",
    isAlreadyTranslated: true
  },
  "Loading requests": {
    text: "Talepler yükleniyor",
    isAlreadyTranslated: true
  },
  "Service type": {
    text: "Servis Türü",
    isAlreadyTranslated: true
  },
  Schedule: {
    text: "Randevu",
    isAlreadyTranslated: true
  },
  "Download requests in csv format": {
    text: "Talepleri csv formatında indir",
    isAlreadyTranslated: true
  },
  Created: {
    text: "Oluşturulma Tarihi",
    isAlreadyTranslated: true
  },
  "Show graph": {
    text: "Grafiği göster",
    isAlreadyTranslated: true
  },
  "Hide graph": {
    text: "Grafiği gizle",
    isAlreadyTranslated: true
  },
  "out of": {
    text: "dışında",
    isAlreadyTranslated: true
  },
  "service requests are shown above.": {
    text: "Servis talepleri yukarıda gösterilmektedir.",
    isAlreadyTranslated: true
  },
  "Created or found consumer": {
    text: "Oluşturulan veya bulunan müşteri",
    isAlreadyTranslated: true
  },
  "Not updated": {
    text: "Güncellenmedi",
    isAlreadyTranslated: true
  },
  "Please select a call disposition": {
    text: "Lütfen bir çağrı durumu seçin",
    isAlreadyTranslated: true
  },
  "Please enter a name": {
    text: "Lütfen bir isim giriniz",
    isAlreadyTranslated: true
  },
  "Please enter remarks": {
    text: "Lütfen başlıkları giriniz",
    isAlreadyTranslated: true
  },
  Username: {
    text: "Kullanıcı adı",
    isAlreadyTranslated: true
  },
  "Call Connected": {
    text: "Çağrı Bağlandı",
    isAlreadyTranslated: true
  },
  "Connection Disposition:": {
    text: "Bağlanma Durumu:",
    isAlreadyTranslated: true
  },
  "Non-Connect Disposition:": {
    text: "Ulaşılamama Nedeni:",
    isAlreadyTranslated: true
  },
  "Date and Time of loss/Damage": {
    text: "Hasarın Tarihi ve Zamanı",
    isAlreadyTranslated: true
  },
  "Description of Loss/Damage": {
    text: "Hasarın Açıklaması",
    isAlreadyTranslated: true
  },
  "Date and Time of Call": {
    text: "Çağrının Tarihi ve Zamanı",
    isAlreadyTranslated: true
  },
  "Status at which call was made": {
    text: "Çağrı yapıldığındaki durum",
    isAlreadyTranslated: true
  },
  "BER Refund": {
    text: "BER İadesi",
    isAlreadyTranslated: true
  },
  "Action Status": {
    text: "İşlem Durumu",
    isAlreadyTranslated: true
  },
  "Action taken": {
    text: "Son işlem",
    isAlreadyTranslated: true
  },
  Rating: {
    text: "Değerlendirme",
    isAlreadyTranslated: true
  },
  Reassign: {
    text: "Yeniden Görevlendir",
    isAlreadyTranslated: true
  },
  "Parts received": {
    text: "Parçalar teslim alındı",
    isAlreadyTranslated: true
  },
  "Address Details": {
    text: "Adres Detayları",
    isAlreadyTranslated: true
  },
  "Raise Request": {
    text: "Talep Yükseltin",
    isAlreadyTranslated: true
  },
  "Please fill in the cancel reason before cancelling": {
    text: "Lütfen iptal etmeden önce iptal nedeninizi açıklayınız",
    isAlreadyTranslated: true
  },
  "Add required documents in 'Request Documents' section below": {
    text: "Aşağıdaki 'Talep Edilen Dökümanlar' bölümüne gerekli dokümanları ekleyiniz",
    isAlreadyTranslated: true
  },
  "Customer Details": {
    text: "Müşteri Detayları",
    isAlreadyTranslated: true
  },
  "Mobile Number": {
    text: "Telefon Numarası",
    isAlreadyTranslated: true
  },
  "First Registered From": {
    text: "İlk Kayıtlı Olduğu Kişi",
    isAlreadyTranslated: true
  },
  "No saved Address": {
    text: "Kaydedilen adres bulunmamaktadır",
    isAlreadyTranslated: true
  },
  "Under warranty": {
    text: "Garanti kapsamında",
    isAlreadyTranslated: true
  },
  "Plan Name": {
    text: "Sigorta Adı",
    isAlreadyTranslated: true
  },
  "Purchase Price": {
    text: "Satın Alım Fiyatı",
    isAlreadyTranslated: true
  },
  Reason: {
    text: "Neden",
    isAlreadyTranslated: true
  },
  "Registration Documents": {
    text: "Kayıt Belgeleri",
    isAlreadyTranslated: true
  },
  Validated: {
    text: "Doğrulanan",
    isAlreadyTranslated: true
  },
  "Not Validated": {
    text: "Doğrulanmayan",
    isAlreadyTranslated: true
  },
  Verify: {
    text: "Doğrulayınız",
    isAlreadyTranslated: true
  },
  "Please enter IMEI/serial number": {
    text: "Lütfen IMEI/Seri numarasını giriniz",
    isAlreadyTranslated: true
  },
  "Please enter serial number": {
    text: "Lütfen seri numarasını giriniz",
    isAlreadyTranslated: true
  },
  "Please enter warranty till date": {
    text: "Lütfen garanti bitiş tarihini giriniz",
    isAlreadyTranslated: true
  },
  "Please upload a warranty supporting document": {
    text: "Lütfen garanti belgesini destekleyen bir belge yükleyiniz",
    isAlreadyTranslated: true
  },
  "Please choose if the warranty is applicable or not": {
    text: "Lütfen garantinin geçerli olup olmadığını seçin",
    isAlreadyTranslated: true
  },
  "Please enter the reason for warranty not being applicable": {
    text: "Lütfen garantinin neden geçerli olmadığını belirtiniz",
    isAlreadyTranslated: true
  },
  Remark: {
    text: "Not",
    isAlreadyTranslated: true
  },
  "Add Pending Documents": {
    text: "Beklenen Belgeyi Ekleyin",
    isAlreadyTranslated: true
  },
  "Add Additional Documents": {
    text: "Ek Belge Ekleyin",
    isAlreadyTranslated: true
  },
  "Add New Issues": {
    text: "Yeni Sorun Ekleyin",
    isAlreadyTranslated: true
  },
  "Total Payable": {
    text: "Toplam Ödenecek Tutar",
    isAlreadyTranslated: true
  },
  "Covered Value": {
    text: "Kapsam Değeri",
    isAlreadyTranslated: true
  },
  "Send For Approval": {
    text: "Onay İçin Gönder",
    isAlreadyTranslated: true
  },
  "Advance cannot be greater than": {
    text: "Ön Ödeme bu değerden yüksek olamaz",
    isAlreadyTranslated: true
  },
  "Discount cannot be greater than": {
    text: "İndirim,bu değerden yüksek olamaz",
    isAlreadyTranslated: true
  },
  Reassigned: {
    text: "Yeniden Atandı",
    isAlreadyTranslated: true
  },
  "Pickup Address": {
    text: "Teslim Alım Adresi",
    isAlreadyTranslated: true
  },
  Address: {
    text: "Adres",
    isAlreadyTranslated: true
  },
  "Telecon Version": {
    text: "Telecon Sürümü",
    isAlreadyTranslated: true
  },
  "Recycle Certificate": {
    text: "Geri Dönüşüm Sertifikası",
    isAlreadyTranslated: true
  },
  "Pick Up Confirmation Number": {
    text: "Teslimat Onay Numarası",
    isAlreadyTranslated: true
  },
  "Pickup Charge": {
    text: "Teslim Alım Ücreti",
    isAlreadyTranslated: true
  },
  Payable: {
    text: "Ödenecek",
    isAlreadyTranslated: true
  },
  "Place of Damage": {
    text: "Hasarın olduğu bölge",
    isAlreadyTranslated: true
  },
  "Please enter issue description": {
    text: "Lütfen sorunun açıklamasını giriniz",
    isAlreadyTranslated: true
  },
  "Request History": {
    text: "Talep Geçmişi",
    isAlreadyTranslated: true
  },
  "Waiting for Assignment": {
    text: "Atama İçin Bekleniyor",
    isAlreadyTranslated: true
  },
  "BER confirmed": {
    text: "BER doğrulandı",
    isAlreadyTranslated: true
  },
  "BER approved": {
    text: "BER onaylandı",
    isAlreadyTranslated: true
  },
  "BER identified": {
    text: "BER tespit edildi",
    isAlreadyTranslated: true
  },
  "Device received": {
    text: "Cihaz alındı",
    isAlreadyTranslated: true
  },
  "Pickup Request Accepted": {
    text: "Teslim alım talebi kabul edildi",
    isAlreadyTranslated: true
  },
  "We have received your claim request. Our representative will connect with you within the next 1-2 business days to process it further": {
    text:"Talep isteğinizi aldık. Temsilcimiz önümüzdeki 1-2 iş günü içinde sizinle daha fazla işlem yapabilmek adına sizinle bağlantı kuracaktır.",
    isAlreadyTranslated: true
  },
  "Device has been received by the service centre and is currently being diagnosed.": {
    text:"Cihaz servis merkezi tarafından teslim alındı ve şu anda cihaz inceleniyor ediliyor.",
    isAlreadyTranslated: true
  },
  "Looks like the repair cost is more than the depreciated value of your device. Please select the option you would like to proceed with.": {
    text:"Onarım maliyeti, cihazınızın kendi değerinden daha fazla gibi görünüyor. Lütfen devam etmek istediğiniz seçeneği seçin.",
    isAlreadyTranslated: true
  },
  "Carry in": {
    text: "Yerinde",
    isAlreadyTranslated: true
  },
  "Assigned To": {
    text: "Atandı",
    isAlreadyTranslated: true
  },
  "ADD CHARGES": {
    text: "ÜCRETLERİ EKLEYİN",
    isAlreadyTranslated: true
  },
  Amount: {
    text: "Tutar",
    isAlreadyTranslated: true
  },
  "Attempt Call": {
    text: "Çağrı Kaydı Aç",
    isAlreadyTranslated: true
  },
  "Add Device Date of Purchase and Device Purchase Cost in 'Device Details' below": {
    text:"Cihazın Satın Alma Tarihini ve Satın Alım Fiyatını aşağıdaki 'Cihaz Detayları' bölümüne ekleyiniz",
    isAlreadyTranslated: true
  },
  "Enter type of document to add": {
    text: "Eklenecek doküman türünü giriniz",
    isAlreadyTranslated: true
  },
  "Please enter Claim intimation number": {
    text: "Lütfen yükseltme talep numarasını girin",
    isAlreadyTranslated: true
  },
  "Please enter Claim intimation number before submitting": {
    text: "Lütfen göndermeden önce yükseltme talep numarasını girin",
    isAlreadyTranslated: true
  },
  "Data displayed": {
    text: "Görüntülenen veriler",
    isAlreadyTranslated: true
  },
  from: {
    text: "itibaren",
    isAlreadyTranslated: true
  },
  till: {
    text: "kadar",
    isAlreadyTranslated: true
  },
  "Please upload a clear copy of cancelled cheque with the claimant (your) name on it.": {
    text: "Lütfen iptal edilen çekin açık bir kopyasını, yükseltmede bulunan (sizin) adınızla birlikte yükleyin.",
    isAlreadyTranslated: true
  },
  "Customer Mobile number": {
    text: "Müşteri Telefon numarası",
    isAlreadyTranslated: true
  },
  "TRACK EXTERNAL REQUEST": {
    text: "Dış Talepleri İzle",
    isAlreadyTranslated: true
  },
  "Oops... Something went wrong!": {
    text: "Oops.. Bir şeyler ters gitti!",
    isAlreadyTranslated: true
  },
  "an address": {
    text: "bir adres",
    isAlreadyTranslated: true
  },
  Pincode: {
    text: "Posta kodu",
    isAlreadyTranslated: true
  },
  Clear: {
    text: "Temizle",
    isAlreadyTranslated: true
  },
  Cancel: {
    text: "İptal Et",
    isAlreadyTranslated: true
  },
  "Please enter Landmark": {
    text: "Lütfen konumu giriniz",
    isAlreadyTranslated: true
  },
  "Please enter Customer's address": {
    text: "Lütfen müşterinin adresini giriniz",
    isAlreadyTranslated: true
  },
  "Please enter pincode": {
    text: "Lütfen posta kodunu giriniz",
    isAlreadyTranslated: true
  },
  "Enter correct Pincode": {
    text: "Doğru posta kodunu giriniz",
    isAlreadyTranslated: true
  },
  "Address updated successfully": {
    text: "Adres başarıyla güncellendi",
    isAlreadyTranslated: true
  },
  "Documents Pending": {
    text: "Bekleyen Dökümanlar",
    isAlreadyTranslated: true
  },
  "Date and Time of loss/damage": {
    text: "Hasarın tarihi ve zamanı",
    isAlreadyTranslated: true
  },
  "Please enter UTR Number": {
    text: "Lütfen UTR Numarasını giriniz",
    isAlreadyTranslated: true
  },
  "Payment Date": {
    text: "Ödeme Tarihi",
    isAlreadyTranslated: true
  },
  "UTR Number": {
    text: "UTR Numarası",
    isAlreadyTranslated: true
  },
  "Service Charge": {
    text: "Servis Bedeli",
    isAlreadyTranslated: true
  },
  SGST: {
    text: "SGST",
    isAlreadyTranslated: true
  },
  CGST: {
    text: "CGST",
    isAlreadyTranslated: true
  },
  TESTING: {
    text: "TEST EDİLİYOR",
    isAlreadyTranslated: true
  },
  "Mandatory deductible": {
    text: "Zorunlu muafiyet",
    isAlreadyTranslated: true
  },
  "Admin fees": {
    text: "Yönetim ücreti",
    isAlreadyTranslated: true
  },
  "Claim amount": {
    text: "Talep tutarı",
    isAlreadyTranslated: true
  },
  "Advance payment": {
    text: "Ön ödeme",
    isAlreadyTranslated: true
  },
  Advance: {
    text: "ön ödeme",
    isAlreadyTranslated: true
  },
  "Alternate Mobile Number": {
    text: "Alternatif Telefon Numarası",
    isAlreadyTranslated: true
  },
  "Please enter correct mobile Number": {
    text: "Lütfen doğru telefon numarası giriniz",
    isAlreadyTranslated: true
  },
  "Please Enter Minimum 75 Characters": {
    text: "Lütfen en az 75 karakter giriniz",
    isAlreadyTranslated: true
  },
  "Add an address": {
    text: "Bir adres ekleyin",
    isAlreadyTranslated: true
  },
  "Add New Address": {
    text: "Yeni Adres Ekleyin",
    isAlreadyTranslated: true
  },
  "Oops! There are no verified service centres around": {
    text: "Hata! Etrafta doğrulanmış servis merkezi bulunmamaktadır.",
    isAlreadyTranslated: true
  },
  "Cannot select more than": {
    text: "Şundan daha fazla seçilemiyor:",
    isAlreadyTranslated: true
  },
  issues: {
    text: "Sorunlar",
    isAlreadyTranslated: true
  },
  "Add a Product": {
    text: "Bir ürün ekleyiniz",
    isAlreadyTranslated: true
  },
  "Add Selected Product": {
    text: "Seçilen Ürünü Ekleyin",
    isAlreadyTranslated: true
  },
  "Device In Warranty": {
    text: "Cihaz Garanti Kapsamında",
    isAlreadyTranslated: true
  },
  "Please Select Damage Date": {
    text: "Lütfen Hasar Tarihini Seçiniz",
    isAlreadyTranslated: true
  },
  "Is your device switching ON": {
    text: "Cihazınız açılıyor mu ?",
    isAlreadyTranslated: true
  },
  "Characters must be numeric": {
    text: "Karakterler sayısal olmalı",
    isAlreadyTranslated: true
  },
  "products under service": {
    text: "serviste işlem gören ürün sayısı",
    isAlreadyTranslated: true
  },
  "Please Select Time": {
    text: "Lütfen zaman seçiniz",
    isAlreadyTranslated: true
  },
  "Please Select Damage type": {
    text: "Lütfen hasar türünü seçiniz",
    isAlreadyTranslated: true
  },
  "Please Enter Place of Damage": {
    text: "Lütfen hasarın olduğu bölgeyi giriniz",
    isAlreadyTranslated: true
  },
  "Please Select an option": {
    text: "Lütfen bir seçenek seçiniz",
    isAlreadyTranslated: true
  },
  "Consumer Name": {
    text: "Müşteri Adı",
    isAlreadyTranslated: true
  },
  "Contact No": {
    text: "İletişim Numarası",
    isAlreadyTranslated: true
  },
  "Logistics Provider": {
    text: "Lojistik Sağlayıcı",
    isAlreadyTranslated: true
  },
  "Registration Source": {
    text: "Kayıt Kaynağı",
    isAlreadyTranslated: true
  },
  "Customer City": {
    text: "Müşteri Şehri",
    isAlreadyTranslated: true
  },
  "Call Type": {
    text: "Çağrı Türü",
    isAlreadyTranslated: true
  },
  "Re-apply": {
    text: "Yeniden uygula",
    isAlreadyTranslated: true
  },
  "something went wrong": {
    text: "bir şeyler yanlış gitti",
    isAlreadyTranslated: true
  },
  Today: {
    text: "Bugün",
    isAlreadyTranslated: true
  },
  Yesterday: {
    text: "Dün",
    isAlreadyTranslated: true
  },
  "Last 7 Days": {
    text: "Son 7 gün",
    isAlreadyTranslated: true
  },
  "Last 30 Days": {
    text: "Son 30 gün",
    isAlreadyTranslated: true
  },
  "This Month": {
    text: "Bu Ay",
    isAlreadyTranslated: true
  },
  "Last Month": {
    text: "Geçen Ay",
    isAlreadyTranslated: true
  },
  "Custom Range": {
    text: "Özel Tarih Aralığı",
    isAlreadyTranslated: true
  },
  "Add a part charge in 'Estimation Details' below to generate estimation": {
    text:"Teklif oluşturmak için parça ücretini aşağıdaki 'Teklif Detayları' bölümüne giriniz",
    isAlreadyTranslated: true
  },
  "Pick Up Request Reference ID": {
    text: "Teslim Alım Talebi Referans Kimliği",
    isAlreadyTranslated: true
  },
  "Convert request": {
    text: "Talebi Dönüştür",
    isAlreadyTranslated: true
  },
  "No Data Available To Plot The Graph": {
    text: "Grafiğin çizilmesi için kullanılabilir veri bulunamadı.",
    isAlreadyTranslated: true
  },
  to: {
    text: "için",
    isAlreadyTranslated: true
  },
  "Download requests in csv format.": {
    text: "Talepleri csv formatında indirin.",
    isAlreadyTranslated: true
  },
  "Service cancelled": {
    text: "Hizmet iptal edildi",
    isAlreadyTranslated: true
  },
  "Search consumer products": {
    text: "Tüketici ürünlerini ara",
    isAlreadyTranslated: true
  },
  "Serial number": {
    text: "Seri numarası",
    isAlreadyTranslated: true
  },
  "Enter Serial Number": {
    text: "Seri Numarasını Girin",
    isAlreadyTranslated: true
  },
  "Select Customer Type": {
    text: "Müşteri Türünü Seçiniz",
    isAlreadyTranslated: true
  },
  "Walk-in Customer": {
    text: "Mağazadaki Müşteri",
    isAlreadyTranslated: true
  },
  "Future Appointment": {
    text: "Randevulu Müşteri",
    isAlreadyTranslated: true
  },
  Tablet: {
    text: "Tablet",
    isAlreadyTranslated: true
  },
  Computer: {
    text: "Bilgisayar",
    isAlreadyTranslated: true
  },
  "Audio Product": {
    text: "Ses ürünü",
    isAlreadyTranslated: true
  },
  "Streaming Device": {
    text: "Yayın Cihazı",
    isAlreadyTranslated: true
  },
  "Smart Watch": {
    text: "Akıllı Saat",
     "isAlreadyTranslated": true
  },
  "Serial number is mandatory for Apple Products.\nPlease enter valid serial number": {
    text:"Apple ürünleri için seri numarası zorunludur. Lütfen geçerli bir seri numarasını girin",
    isAlreadyTranslated: true
  },
  "Back camera issue": {
    text: "Arka kamera sorunu",
    isAlreadyTranslated: true
  },
  "Charger not working": {
    text: "Şarj aleti çalışmıyor",
    isAlreadyTranslated: true
  },
  "Noise issue": {
    text: "Parazitli görüntü sorunu",
    isAlreadyTranslated: true
  },
  "Network Issue": {
    text: "Ağ sorunu",
    isAlreadyTranslated: true
  },
  "External storage issue": {
    text: "Harici depolama sorunu",
    isAlreadyTranslated: true
  },
  "Internal data storage issue": {
    text: "Dahili depolama sorunu",
    isAlreadyTranslated: true
  },
  "Blur display": {
    text: "Bulanık görüntü",
    isAlreadyTranslated: true
  },
  Flickering: {
    text: "Görüntü titriyor",
    isAlreadyTranslated: true
  },
  "Improper display": {
    text: "Bozuk görüntü",
    isAlreadyTranslated: true
  },
  "Spot on display": {
    text: "Ekranda nokta mevcut",
    isAlreadyTranslated: true
  },
  "Electric Shock": {
    text: "Elektrik Çarpması",
    isAlreadyTranslated: true
  },
  "Body damaged": {
    text: "Kasa hasarlı",
    isAlreadyTranslated: true
  },
  "Display damaged": {
    text: "Ekran hasarlı",
    isAlreadyTranslated: true
  },
  "Intermittently powering off": {
    text: "Beklenmeyen kapanma",
    isAlreadyTranslated: true
  },
  "Not charging": {
    text: "Şarj almıyor",
    isAlreadyTranslated: true
  },
  "Not powering off": {
    text: "Cihaz kapanmıyor",
    isAlreadyTranslated: true
  },
  "Cracking sound": {
    text: "Çıtlama sesi mevcut",
    isAlreadyTranslated: true
  },
  "Less sound": {
    text: "Ses az geliyor",
    isAlreadyTranslated: true
  },
  "Slow processing or hangs": {
    text: "Yavaş çalışıyor ve takılıyor",
    isAlreadyTranslated: true
  },
  "Pickup Drop By": {
    text: "Pickup Drop By",
    isAlreadyTranslated: true
  },
  Qty: {
    text: "Adt",
    isAlreadyTranslated: true
  },
  "Available Quantity": {
    text: "Mevcut Miktar",
    isAlreadyTranslated: true
  },
  "Issued By": {
    text: "Teslim Eden",
    isAlreadyTranslated: true
  },
  "Handover Device": {
    text: "Cihazı Geri Ver",
    isAlreadyTranslated: true
  },
  "Not applicable": {
    text: "Uygulanamaz",
    isAlreadyTranslated: true
  },
  "Pending for assignment": {
    text: "Atama için beklemede",
    isAlreadyTranslated: true
  },
  "Device handover details": {
    text: "Cihaz teslim etme ayrıntıları",
    isAlreadyTranslated: true
  },
  "Diagnosis details": {
    text: "Tanı detayları",
    isAlreadyTranslated: true
  },
  "Diagnosis Steps": {
    text: "Tanı Adımları",
    isAlreadyTranslated: true
  },
  "Engineer notes": {
    text: "Teknisyen notları",
    isAlreadyTranslated: true
  },
  "Device Transaction Details": {
    text: "Cihaz İşlem Ayrıntıları",
    isAlreadyTranslated: true
  },
  "Upload Device Pictures and Complete Checklist": {
    text: "Cihaz Resimlerini Yükleyin ve Kontrol Listesini Tamamlayın",
    isAlreadyTranslated: true
  },
  "Upload Device Pictures": {
    text: "Cihaz Resimlerini Yükleyin",
    isAlreadyTranslated: true
  },
  "Select OS Version": {
    text: "İşletim Sistemini Seçin",
    isAlreadyTranslated: true
  },
  "Select Picture Type": {
    text: "Resim Türünü Seçin",
    isAlreadyTranslated: true
  },
  "No pictures added.": {
    text: "Hiçbir resim eklenmedi.",
    isAlreadyTranslated: true
  },
  "Complete Checklist": {
    text: "Kontrol Listesini Tamamlayın",
    isAlreadyTranslated: true
  },
  "Accessories Submitted": {
    text: "Alınan Aksesuarlar",
    isAlreadyTranslated: true
  },
  Checklist: {
    text: "Kontrol Listesi",
    isAlreadyTranslated: true
  },
  "Miscellaneous Checklist": {
    text: "Ek Kontrol Listesi",
    isAlreadyTranslated: true
  },
  Complete: {
    text: "Tamamlayınız",
    isAlreadyTranslated: true
  },
  "Select All": {
    text: "Hepsini seç",
    isAlreadyTranslated: true
  },
  "Raise in": {
    text: "Yükselt",
    isAlreadyTranslated: true
  },
  "Request in": {
    text: "Talep",
    isAlreadyTranslated: true
  },
  "Please select Repair Type": {
    text: "Lütfen Onarım Türünü Seçin",
    isAlreadyTranslated: true
  },
  "Please select Classification Type": {
    text: "Lütfen Sınıflandırma Türünü seçin",
    isAlreadyTranslated: true
  },
  "Repair Checklist": {
    text: "Onarım Kontrol Listesi",
    isAlreadyTranslated: true
  },
  Raise: {
    text: "Yükselt",
    isAlreadyTranslated: true
  },
  Request: {
    text: "Talep",
    isAlreadyTranslated: true
  },
  "Diagnostics Result": {
    text: "Tanı Sonuçları",
    isAlreadyTranslated: true
  },
  "Please mention the incident in not less than 75 characters on how, when & where the damage happened": {
    text: "Lütfen hasarın nasıl, ne zaman oluştuğunu en az 75 karekter ile anlatın.",
    isAlreadyTranslated: true
  },
  "Parts transaction details": {
    text: "Parça işlem detayları",
    isAlreadyTranslated: true
  },
  "Handing over the parts to store": {
    text: "Parçaları depoya teslim edin",
    isAlreadyTranslated: true
  },
  "Handing over the damaged device to store": {
    text: "Hasarlı cihazı depoya teslim edin",
    isAlreadyTranslated: true
  },
  "Details of the New Replaced Device": {
    text: "Yeni Cihazın Detayları",
    isAlreadyTranslated: true
  },
  "Mode of payment": {
    text: "Ödeme türü",
    isAlreadyTranslated: true
  },
  "Please select mode of payment": {
    text: "Lütfen ödeme türünü seçiniz",
    isAlreadyTranslated: true
  },
  Cash: {
    text: "Nakit",
    isAlreadyTranslated: true
  },
  Card: {
    text: "Kart",
    isAlreadyTranslated: true
  },
  "Accessories will not be returned": {
    text: "Aksesuarlar iade edilmeyecek",
    isAlreadyTranslated: true
  },
  "Documents are yet to be uploaded.": {
    text: "Henüz belgeler yüklenmedi.",
    isAlreadyTranslated: true
  },
  "Report Issues": {
    text: "Sorunları Bildir",
    isAlreadyTranslated: true
  },
  "Re-assign": {
    text: "Yeniden ata",
    isAlreadyTranslated: true
  },
  "Add Required Parts": {
    text: "Gerekli Parçaları Ekle",
    isAlreadyTranslated: true
  },
  Price: {
    text: "Fiyat",
    isAlreadyTranslated: true
  },
  "Tax Slab": {
    text: "Vergi levhası",
    isAlreadyTranslated: true
  },
  "Is Part Abused": {
    text: "Parça Onarılamaz Hasarlı Mı?",
    isAlreadyTranslated: true
  },
  "Is Returnable Damage": {
    text: "İade Edilebilir Hasarlı mı",
    isAlreadyTranslated: true
  },
  "Part Warranty": {
    text: "Parça Garantisi",
    isAlreadyTranslated: true
  },
  "Part Price": {
    text: "Parça Ücreti",
    isAlreadyTranslated: true
  },
  "Available Qty": {
    text: "Mevcut Adt",
    isAlreadyTranslated: true
  },
  Action: {
    text: "İşlem",
    isAlreadyTranslated: true
  },
  "Report Issues to": {
    text: "Sorunları Şuna Bildir:",
    isAlreadyTranslated: true
  },
  "Receive & Inspect Device": {
    text: "Teslim Al ve Cihazı İncele",
    isAlreadyTranslated: true
  },
  "Please select a picture type from dropdown": {
    text: "Lütfen açılır listeden bir fotoğraf türü seçin",
    isAlreadyTranslated: true
  },
  Waybill: {
    text: "İrsaliye",
    isAlreadyTranslated: true
  },
  Vendor: {
    text: "Satıcı",
    isAlreadyTranslated: true
  },
  "Pick Up": {
    text: "Almak",
    isAlreadyTranslated: true
  },
  "Is Covered By ACPlus": {
    text: "Cihaz AppleCare+ Kapsamında mı?",
    isAlreadyTranslated: true
  },
  "Re-assign Service Engineer": {
    text: "Servis Teknisyenini Yeniden Atayın",
    isAlreadyTranslated: true
  },
  "Device Received & Inspection in Progress": {
    text: "Cihaz teslim alındı ve inceleme sürüyor.",
    isAlreadyTranslated: true
  },
  "Service accepted": {
    text: "Servise kabul edildi",
    isAlreadyTranslated: true
  },
  "COMPTIA Modifier": {
    text: "COMPTIA Niteleyici",
    isAlreadyTranslated: true
  },
  "Send Issues to": {
    text: "Sorunları şuraya gönder:",
    isAlreadyTranslated: true
  },
  "Engineer validated issues": {
    text: "Teknisyenin Onayladığı Sorunlar",
    isAlreadyTranslated: true
  },
  "Part(s) request under review by storekeeper.": {
    text: "Parça talebi depocu tarafından incelemede",
    isAlreadyTranslated: true
  },
  ChargeCode: {
    text: "Ücret kodu",
    isAlreadyTranslated: true
  },
  TaxSlab: {
    text: "TaxSlab",
    isAlreadyTranslated: true
  },
  "Are you sure?": {
    text: "Emin misiniz?",
    isAlreadyTranslated: true
  },
  "Are you sure..?": {
    text: "Emin misiniz..?",
    isAlreadyTranslated: true
  },
  "Part name": {
    text: "Parça adı",
    isAlreadyTranslated: true
  },
  "Ref Id": {
    text: "Referans Kimliği",
    isAlreadyTranslated: true
  },
  "Validation Status": {
    text: "Doğrulama Durumu",
    isAlreadyTranslated: true
  },
  "Is warranty applicable": {
    text: "Garanti kapsamında mı?",
    isAlreadyTranslated: true
  },
  "Issue Code": {
    text: "Sorun Kodu",
    isAlreadyTranslated: true
  },
  "Symptom Code": {
    text: "Bulgu Kodu",
    isAlreadyTranslated: true
  },
  "Other Charge": {
    text: "Diğer Ücretler",
    isAlreadyTranslated: true
  },
  Discount: {
    text: "İndirim",
    isAlreadyTranslated: true
  },
  "Engineer completed the repair. Handed over the device to QC.": {
    text:"Teknisyen tamiri tamamladı. Cihazı Kalite Kontrol Teknisyeni'ne teslim etti.",
    isAlreadyTranslated: true
  },
  "Device handed over": {
    text: "Cihaz devredildi",
    isAlreadyTranslated: true
  },
  "Repair completed, QC pending": {
    text: "Onarım tamamlandı, kalite kontrol beklemede",
    isAlreadyTranslated: true
  },
  "Repair complete": {
    text: "Onarım tamamlandı",
    isAlreadyTranslated: true
  },
  "Device details validated": {
    text: "Cihaz ayrıntıları doğrulandı",
    isAlreadyTranslated: true
  },
  "Issues validated": {
    text: "Sorunlar doğrulandı",
    isAlreadyTranslated: true
  },
  "Work in Progress with Engineer": {
    text: "Teknisyen işleme devam ediyor",
    isAlreadyTranslated: true
  },
  "Cannot be diagnosed": {
    text: "Tanı yapılamaz",
    isAlreadyTranslated: true
  },
  "Parts issued": {
    text: "İşlenen parçalar",
    isAlreadyTranslated: true
  },
  "Pending for acceptance": {
    text: "Teknisyenin işi kabul etmesi bekleniyor",
    isAlreadyTranslated: true
  },
  "Device accepted": {
    text: "Cihaz kabul edildi",
    isAlreadyTranslated: true
  },
  "Parts request": {
    text: "Parça talebi",
    isAlreadyTranslated: true
  },
  Valid: {
    text: "Geçerli",
    isAlreadyTranslated: true
  },
  Validity: {
    text: "Geçerlik",
    isAlreadyTranslated: true
  },
  Invalid: {
    text: "Geçerli değil",
    isAlreadyTranslated: true
  },
  "Engineer Raised Issues": {
    text: "Teknisyenin Yükselttiği Sorunlar",
    isAlreadyTranslated: true
  },
  "Are you sure": {
    text: "Emin misiniz?",
    isAlreadyTranslated: true
  },
  "Please specify remarks, if any": {
    text: "Varsa lütfen açıklamaları belirtin",
    isAlreadyTranslated: true
  },
  "This service is a Return without repair": {
    text: "Bu servis, onarım olmadan iade edildi",
    isAlreadyTranslated: true
  },
  Others: {
    text: "Diğerleri",
    isAlreadyTranslated: true
  },
  "Service completed and rated": {
    text: "Servis tamamlandı ve değerlendirildi",
    isAlreadyTranslated: true
  },
  "Drop Request Created Post Repair": {
    text: "Bırakma Talebi Oluşturma Postayla Onarım",
    isAlreadyTranslated: true
  },
  "Payment received": {
    text: "Ödeme alındı",
    isAlreadyTranslated: true
  },
  "Service invoice generated": {
    text: "Servis faturası oluşturuldu",
    isAlreadyTranslated: true
  },
  "Repair Completed, Ready To Inform The Customer": {
    text: "Onarım Tamamlandı, Müşteriyi Bilgilendirmeye Hazır",
    isAlreadyTranslated: true
  },
  "Repair completed": {
    text: "Onarım tamamlandı",
    isAlreadyTranslated: true
  },
  WarrantyConcession: {
    text: "Garanti Ayrıcalığı",
    isAlreadyTranslated: true
  },
  "Estimation generated": {
    text: "Teklif oluşturuldu",
    isAlreadyTranslated: true
  },
  "Estimation approval": {
    text: "Teklif onayı",
    isAlreadyTranslated: true
  },
  "Parts request accepted": {
    text: "Parça talebi kabul edildi",
    isAlreadyTranslated: true
  },
  "Repair estimate approved": {
    text: "Onarım teklifi onaylandı",
    isAlreadyTranslated: true
  },
  "Damaged Serial No": {
    text: "Hasarlı Parçanın Seri Numarası",
    isAlreadyTranslated: true
  },
  "Replaced Serial No": {
    text: "Yeni Parçanın Seri Numarası",
    isAlreadyTranslated: true
  },
  "Damaged IMEI": {
    text: "Hasarlı Cihazın IMEI Numarası",
    isAlreadyTranslated: true
  },
  "Replaced IMEI": {
    text: "IMEI değiştirildi",
    isAlreadyTranslated: true
  },
  "Initiate logistics request": {
    text: "Lojistik talebini başlat",
    isAlreadyTranslated: true
  },
  "Payment for the service has been received from the customer.": {
    text: "Servis için ödeme müşteriden alındı.",
    isAlreadyTranslated: true
  },
  "BER payment completed": {
    text: "BER ödemesi tamamlandı",
    isAlreadyTranslated: true
  },
  "BER payment initiated": {
    text: "BER ödemesi başlatıldı",
    isAlreadyTranslated: true
  },
  "BER device received": {
    text: "BER cihaz teslim alındı",
    isAlreadyTranslated: true
  },
  "BER accessories validated": {
    text: "BER aksesuarları doğrulandı",
    isAlreadyTranslated: true
  },
  "BER accessories received": {
    text: "BER aksesuarları teslim alındı",
    isAlreadyTranslated: true
  },
  "BER documents validated": {
    text: "BER belgeleri doğrulandı",
    isAlreadyTranslated: true
  },
  "Pickup rescheduled": {
    text: "Teslim tarihi yeniden düzenlendi",
    isAlreadyTranslated: true
  },
  "Reschedule requested": {
    text: "Yeniden zamanlama talep edildi",
    isAlreadyTranslated: true
  },
  "Claim Pickup": {
    text: "Teslim Alım Talep Edin",
    isAlreadyTranslated: true
  },
  "Invalid IMEI or serial number": {
    text: "Geçersiz IMEI veya seri numarası",
    isAlreadyTranslated: true
  },
  "No serial number for product": {
    text: "Ürün için seri numarası yok",
    isAlreadyTranslated: true
  },
  "Invalid product serial number": {
    text: "Geçersiz ürün seri numarası",
    isAlreadyTranslated: true
  },
  "Waiting for the Engineer to raise the request.": {
    text: "Teknisyenin talebi yükseltmesi bekleniyor",
    isAlreadyTranslated: true
  },
  getting: {
    text: "alma",
    isAlreadyTranslated: true
  },
  "Claim Carryin": {
    text: "Yerinde Onarım Talep Edin",
    isAlreadyTranslated: true
  },
  "Approved claim amount": {
    text: "Onaylanmış hak talepleri tutarı",
    isAlreadyTranslated: true
  },
  "Payment pending": {
    text: "Ödeme bekleniyor",
    isAlreadyTranslated: true
  },
  "Work in Progress with Engineer.": {
    text: "Teknisyen işleme devam ediyor.",
    isAlreadyTranslated: true
  },
  "Diagnosis completed": {
    text: "Tanı tamamlandı",
    isAlreadyTranslated: true
  },
  "Diagnosis initiated": {
    text: "Tanı başlatıldı",
    isAlreadyTranslated: true
  },
  "To proceed, update Device Date of Purchase and Device Purchase Cost in 'Device Details' below.": {
    text:'Devam etmek için, aşağıdaki "Cihaz Ayrıntıları" bölümünde Satın Alma Gününü ve Cihazı Satın Alma Maliyetini güncelleyin.',
    isAlreadyTranslated: true
  },
  "Connect Disposition:": {
    text: "Konu Başlığı:",
    isAlreadyTranslated: true
  },
  "Handing over the": {
    text: "Teslim etmek",
    isAlreadyTranslated: true
  },
  "full box unit": {
    text: "dolu kutu birimi",
    isAlreadyTranslated: true
  },
  parts: {
    text: "parçalar",
    isAlreadyTranslated: true
  },
  "to store.": {
    text: "depolamak.",
    isAlreadyTranslated: true
  },
  "Damaged Part Imei": {
    text: "Hasarlı Parçanın IMEI numarası",
    isAlreadyTranslated: true
  },
  "Select Fault Code based on the validated issues.": {
    text: "Onaylanmış sorunlara dayanarak Arıza Kodunu seçin.",
    isAlreadyTranslated: true
  },
  "Fault Code": {
    text: "Arıza kodu",
    isAlreadyTranslated: true
  },
  "Please check following items before completing the QC.": {
    text:"Kalite kontrolü tamamlamadan önce lütfen aşağıdaki maddeleri kontrol edin.",
    isAlreadyTranslated: true
  },
  "QC Checklist": {
    text: "Kalite Kontrol Listesi",
    isAlreadyTranslated: true
  },
  Oops: {
    text: "Hata",
    isAlreadyTranslated: true
  },
  "Please enter type of damage": {
    text: "Lütfen hasar türünü giriniz",
    isAlreadyTranslated: true
  },
  "Please enter atleast one document": {
    text: "Lütfen en az bir belge girin",
    isAlreadyTranslated: true
  },
  "Please enter description of loss/damage": {
    text: "Lütfen Hasarın Açıklamasını giriniz",
    isAlreadyTranslated: true
  },
  "Please enter Date and Time of loss/damage": {
    text: "Lütfen Hasarın oluştuğu Tarihi ve Zamanı giriniz",
    isAlreadyTranslated: true
  },
  "Please enter Disposition reason": {
    text: "Lütfen Değişiklik sebebini girin",
    isAlreadyTranslated: true
  },
  "Please enter your name": {
    text: "Lütfen adınızı giriniz",
    isAlreadyTranslated: true
  },
  "Please enter the reason for call": {
    text: "Lütfen çağrı sebebini girin",
    isAlreadyTranslated: true
  },
  "Please fill in the reason for cancelling": {
    text: "Lütfen iptal nedenini açıklayınız",
    isAlreadyTranslated: true
  },
  "Please specify the pending parts": {
    text: "Lütfen bekleyen parçaları belirtiniz",
    isAlreadyTranslated: true
  },
  "Please fill in the reason for rejecting claim": {
    text: "Lütfen yükseltmeyi reddetme nedeninizi açıklayınız",
    isAlreadyTranslated: true
  },
  "Please fill in the cancel reason before rejecting": {
    text: "Lütfen reddetmeden önce iptal nedeninizi açıklayınız",
    isAlreadyTranslated: true
  },
  "Please fill in the reason for the claim being invalid": {
    text: "lütfen yükseltmenin geçersiz olmasının nedenini açıklayınız",
    isAlreadyTranslated: true
  },
  "Please fill in the reason for claim being invalid before rejecting": {
    text:"Reddetmeden önce lütfen yükseltmenin geçersiz olmasının nedenini açıklayınız",
    isAlreadyTranslated: true
  },
  "You have not chosen any option to handover": {
    text: "Teslim etmek için herhangi bir seçenek seçmediniz",
    isAlreadyTranslated: true
  },
  "Please enter approved claim amount": {
    text: "Lütfen onaylanmış yükseltmenin tahmini ücretini girin",
    isAlreadyTranslated: true
  },
  "Please add a picture.": {
    text: "Lütfen bir resim ekleyin.",
    isAlreadyTranslated: true
  },
  "Please specify remarks, if any...": {
    text: "Lütfen varsa, açıklamalarınızı belirtin ...",
    isAlreadyTranslated: true
  },
  "Cannot proceed with this action without providing a reason.": {
    text: "Bir sebep belirtmediğiniz için bu işleme devam edemiyoruz.",
    isAlreadyTranslated: true
  },
  "Cannot proceed with this action without providing a reason": {
    text: "Bir sebep belirtmediğiniz için bu işleme devam edemiyoruz",
    isAlreadyTranslated: true
  },
  "Device transaction details": {
    text: "Cihaz işlem ayrıntıları",
    isAlreadyTranslated: true
  },
  "Select Device": {
    text: "Cihaz seç",
    isAlreadyTranslated: true
  },
  "Account Details": {
    text: "hesap detayları",
    isAlreadyTranslated: true
  },
  "Add Customer's Mobile Number": {
    text: "Müşterinin Telefon Numarasını Ekleyin",
    isAlreadyTranslated: true
  },
  "Re - Enter Mobile Number": {
    text: "Telefon numarasını yeniden girin",
    isAlreadyTranslated: true
  },
  "Approve Claim Estimation": {
    text: "Fiyat Teklifi Onaylandı",
    isAlreadyTranslated: true
  },
  "Are you sure? Please specify remarks, if any...": {
    text: "Emin misiniz? Lütfen varsa, açıklama belirtiniz.",
    isAlreadyTranslated: true
  },
  "Ask for Documents Pending": {
    text: "Bekleyen belgeleri isteyin",
    isAlreadyTranslated: true
  },
  Assign: {
    text: "Atayın",
    isAlreadyTranslated: true
  },
  Assigned: {
    text: "Atanan",
    isAlreadyTranslated: true
  },
  Assignment: {
    text: "Atama",
    isAlreadyTranslated: true
  },
  Attempt: {
    text: "Teşebbüs",
    isAlreadyTranslated: true
  },
  Available: {
    text: "Mevcut",
    isAlreadyTranslated: true
  },
  Back: {
    text: "Geri",
    isAlreadyTranslated: true
  },
  "Back to all consumers": {
    text: "Müşteri Sayfasına Geri Dön",
    isAlreadyTranslated: true
  },
  "Back to all requests": {
    text: "Talep Sayfasına Geri Dön",
    isAlreadyTranslated: true
  },
  "BER Case Found": {
    text: "BER bulundu",
    isAlreadyTranslated: true
  },
  "Brand authorised": {
    text: "Yetkili Marka",
    isAlreadyTranslated: true
  },
  "Broker Name": {
    text: "Aracı Firma Adı",
    isAlreadyTranslated: true
  },
  "Call attempted": {
    text: "Çağrıda denendi",
    isAlreadyTranslated: true
  },
  "Cancel Repair": {
    text: "Onarımı İptal Et",
    isAlreadyTranslated: true
  },
  "Cancel Request": {
    text: "Talebi İptal Et",
    isAlreadyTranslated: true
  },
  "Cancel Service": {
    text: "Servisi İptal Et",
    isAlreadyTranslated: true
  },
  "Cancel Service Without Dispatch": {
    text: "G Numarası olmadan Servisi İptal Et",
    isAlreadyTranslated: true
  },
  "Cannot select more than issues.": {
    text: "Daha fazla sorun seçilemiyor.",
    isAlreadyTranslated: true
  },
  "Characters must be numbers": {
    text: "Girdiğiniz değer sayı içermelidir",
    isAlreadyTranslated: true
  },
  "Characters must be alphabets": {
    text: "Girdiğiniz değer sadece harf içermelidir",
    isAlreadyTranslated: true
  },
  "Characters must be alphanumeric": {
    text: "Girdiğiniz değer alfanümerik olmalı",
    isAlreadyTranslated: true
  },
  "Characters must be alphabets (at least 2 alphabets are mandatory)": {
    text: "Girdiğiniz değer harf içermeli (en az 2 harf içermeli)",
    isAlreadyTranslated: true
  },
  "Characters must be numeric.": {
    text: "Girdiğiniz değer sayısal olmalı",
    isAlreadyTranslated: true
  },
  "Choose from dropdown list only": {
    text: "Sadece açılır listeden seçim yapın",
    isAlreadyTranslated: true
  },
  Claim: {
    text: "Talep Edin",
    isAlreadyTranslated: true
  },
  "Claim estimation approved": {
    text: "Yükseltme için tahmini ücret onaylandı",
    isAlreadyTranslated: true
  },
  "Claim estimation generated": {
    text: "Yükseltme için tahmini ücret oluşturuldu",
    isAlreadyTranslated: true
  },
  "Claim initiated": {
    text: "Yükseltme başlatıldı",
    isAlreadyTranslated: true
  },
  "Claim invalid": {
    text: "Geçersiz yükseltme",
    isAlreadyTranslated: true
  },
  "Claim raised": {
    text: "Talebiniz yükseltildi",
    isAlreadyTranslated: true
  },
  "Claim rejected": {
    text: "Yükseltme reddedildi",
    isAlreadyTranslated: true
  },
  "Communication History": {
    text: "İletişim Geçmişi",
    isAlreadyTranslated: true
  },
  "Complete Repair": {
    text: "Onarımı Tamamlayın",
    isAlreadyTranslated: true
  },
  "Complete Service": {
    text: "Servisi Tamamlayın",
    isAlreadyTranslated: true
  },
  "COMPTIA Code": {
    text: "COMPTIA Kodu",
    isAlreadyTranslated: true
  },
  "COMPTIA Group": {
    text: "COMPTIA Grubu",
    isAlreadyTranslated: true
  },
  Confirm: {
    text: "Onaylayın",
    isAlreadyTranslated: true
  },
  Connect: {
    text: "Ulaşıldı mı?",
    isAlreadyTranslated: true
  },
  "Connect Disposition": {
    text: "Bağlantılığa Bağlan",
    isAlreadyTranslated: true
  },
  "Consumer Details": {
    text: "Müşteri Detayları",
    isAlreadyTranslated: true
  },
  "Consumer Raised Issues": {
    text: "Müşterinin Belirttiği Sorunlar",
    isAlreadyTranslated: true
  },
  Contact: {
    text: "İletişim",
    isAlreadyTranslated: true
  },
  "Contract Type": {
    text: "İletişim Tipi",
    isAlreadyTranslated: true
  },
  "Current Log": {
    text: "Son İşlem",
    isAlreadyTranslated: true
  },
  "Current Request Reference ID": {
    text: "Mevcut Talep Referans Numarası",
    isAlreadyTranslated: true
  },
  "Current Status": {
    text: "Mevcut Durum",
    isAlreadyTranslated: true
  },
  "Customer Care Executive": {
    text: "Müşteri Temsilcisi",
    isAlreadyTranslated: true
  },
  "Customer did not add issues": {
    text: "Müşteri sorun belirtmedi",
    isAlreadyTranslated: true
  },
  "Customer's Version": {
    text: "Müşterinin Versiyonu",
    isAlreadyTranslated: true
  },
  "DAMAGE DETAILS": {
    text: "Hasar Detayları",
    isAlreadyTranslated: true
  },
  "Damage Type": {
    text: "Hasar Türü",
    isAlreadyTranslated: true
  },
  "Date and Time of loss/damage:": {
    text: "Hasarın oluştuğu Tarih ve Zaman",
    isAlreadyTranslated: true
  },
  "When did your device get damaged?": {
    text: "Cihazınız ne zaman zarar gördü?",
    isAlreadyTranslated: true
  },
  "Please tell us how was your device damaged": {
    text: "Lütfen cihazınızın nasıl hasar gördüğünü söyleyin",
    isAlreadyTranslated: true
  },
  "Date of Purchase": {
    text: "Satın Alma Tarih",
    isAlreadyTranslated: true
  },
  "Dead on Arrival": {
    text: "DOA",
    isAlreadyTranslated: true
  },
  "Description of loss/damage": {
    text: "Hasarın tanımı",
    isAlreadyTranslated: true
  },
  "Detail Description of Damage": {
    text: "Hasarın Detaylı Açıklaması",
    isAlreadyTranslated: true
  },
  Device: {
    text: "Cihaz",
    isAlreadyTranslated: true
  },
  "Device Added Date": {
    text: "Cihazın Eklendiği Gün",
    isAlreadyTranslated: true
  },
  "Device Details": {
    text: "Cihaz Detayı",
    isAlreadyTranslated: true
  },
  "Device dispatched": {
    text: "Cihaz Sevk Edildi",
    isAlreadyTranslated: true
  },
  "Device In Warranty?": {
    text: "Cihaz Garanti Kapsamında mı?",
    isAlreadyTranslated: true
  },
  "Device switching on": {
    text: "Cihaz açılıyor mu?",
    isAlreadyTranslated: true
  },
  device_details: {
    text: "cihaz_detayi",
    isAlreadyTranslated: true
  },
  "Dispatch Device": {
    text: "Cihazı Sevk Edin",
    isAlreadyTranslated: true
  },
  "Device shipped": {
    text: "Cihaz gönderildi",
    isAlreadyTranslated: true
  },
  "Device Received": {
    text: "Cihaz Alındı",
    isAlreadyTranslated: true
  },
  "Distance from user": {
    text: "Kullanıcıdan uzaklığı",
    isAlreadyTranslated: true
  },
  "Distributor Name": {
    text: "Distrübitör Adı",
    isAlreadyTranslated: true
  },
  doc: {
    text: "Belge",
    isAlreadyTranslated: true
  },
  "Document pending": {
    text: "Belge bekleniyor",
    isAlreadyTranslated: true
  },
  "Document verification pending": {
    text: "Belge doğrulaması bekleniyor",
    isAlreadyTranslated: true
  },
  "Documents are yet to be uploaded": {
    text: "Henüz Belgeler Yüklenmedi",
    isAlreadyTranslated: true
  },
  "Download Jobsheet Label": {
    text: "Servis Formunu indir",
    isAlreadyTranslated: true
  },
  "Download Logistics Label": {
    text: "Sevkiyat Formunu İndir",
    isAlreadyTranslated: true
  },
  "DD-MM-YYYY": {
    text: "GG-AA-YYYY",
    isAlreadyTranslated: true
  },
  "Email ID": {
    text: "E-posta Adresi",
    isAlreadyTranslated: true
  },
  "End on": {
    text: "Bitiş Tarihi",
    isAlreadyTranslated: true
  },
  Engineer: {
    text: "Teknisyen",
    isAlreadyTranslated: true
  },
  "Enter Call Details": {
    text: "Çağrı Detaylarını Girin",
    isAlreadyTranslated: true
  },
  "Enter correct Mobile Number": {
    text: "Doğru Telefon Numarası Giriniz",
    isAlreadyTranslated: true
  },
  "Enter IMEI or Serial Number": {
    text: "IMEI veya Seri Numarası Giriniz",
    isAlreadyTranslated: true
  },
  "Enter Information for the parts you are issuing": {
    text: "Teslim aldığınız parçalar için bilgi giriniz",
    isAlreadyTranslated: true
  },
  "Enter Mobile Number": {
    text: "Telefon Numarasını Giriniz",
    isAlreadyTranslated: true
  },
  "Enter Reference ID": {
    text: "Referans Kimliği'ni Giriniz",
    isAlreadyTranslated: true
  },
  "Enter Reference IDs": {
    text: "Referans Kimlikleri'ni Giriniz",
    isAlreadyTranslated: true
  },
  Error: {
    text: "Hata",
    isAlreadyTranslated: true
  },
  "Estimation Details": {
    text: "Fiyat teklifi Detayları",
    isAlreadyTranslated: true
  },
  Exclusive: {
    text: "Özel",
    isAlreadyTranslated: true
  },
  "External Reference ID": {
    text: "Harici Referans Kimliği",
    isAlreadyTranslated: true
  },
  "External Reference ID has to be between 6 - 20 characters.": {
    text: "Harici Referans Kimliği 6 - 20 karakter arasında olmalıdır.",
    isAlreadyTranslated: true
  },
  "External ReferenceID added successfully": {
    text: "Harici Referans Kimliği başarılı bir şekilde eklendi",
    isAlreadyTranslated: true
  },
  "Full name": {
    text: "Ad Soyad",
    isAlreadyTranslated: true
  },
  "Generate Invoice": {
    text: "Fatura Oluşturun",
    isAlreadyTranslated: true
  },
  "Green Rewards": {
    text: "Yeşil Ödüller",
    isAlreadyTranslated: true
  },
  "Green Rewards Chosen": {
    text: "Seçilen Yeşil Ödüller",
    isAlreadyTranslated: true
  },
  "GSX Validation Status": {
    text: "GSX Doğrulama Durumu",
    isAlreadyTranslated: true
  },
  Home: {
    text: "Ev",
    isAlreadyTranslated: true
  },
  "HSN Code": {
    text: "HSN Kodu",
    isAlreadyTranslated: true
  },
  "ID Proof": {
    text: "Kimlik Belgesi",
    isAlreadyTranslated: true
  },
  "IMEI 1": {
    text: "IMEI 1",
    isAlreadyTranslated: true
  },
  "IMEI Number": {
    text: "IMEI Numarası",
    isAlreadyTranslated: true
  },
  "IMEI/Serial Number": {
    text: "IMEI/Seri Numarası",
    isAlreadyTranslated: true
  },
  "In-principle Approval": {
    text: "Prensip olarak Onay",
    isAlreadyTranslated: true
  },
  "In-principle approved": {
    text: "Prensip olarak onaylandı",
    isAlreadyTranslated: true
  },
  "Initiate Claim": {
    text: "Yükseltmeyi Başlatın",
    isAlreadyTranslated: true
  },
  "Initiate Service": {
    text: "Servisi Başlatın",
    isAlreadyTranslated: true
  },
  "Insurance Status": {
    text: "Sigorta Durumu",
    isAlreadyTranslated: true
  },
  "Insurance team will reach out to you for further action.": {
    text: "Sigorta ekibi bir sonraki işlem için size ulaşacaktır.",
    isAlreadyTranslated: true
  },
  "Insurer Name": {
    text: "Sigorta Firma Adı",
    isAlreadyTranslated: true
  },
  "Invalid email id": {
    text: "Geçersiz E-posta Adresi",
    isAlreadyTranslated: true
  },
  Invoice: {
    text: "Fatura",
    isAlreadyTranslated: true
  },
  "Is Warranty Applicable": {
    text: "Garanti Kapsamında mı ?",
    isAlreadyTranslated: true
  },
  "Is Warranty Applicable for this request?": {
    text: "Bu talep için garanti kapsamında işlem yapılabilir mi?",
    isAlreadyTranslated: true
  },
  "Issue description": {
    text: "Sorun Açıklaması",
    isAlreadyTranslated: true
  },
  "Issue Validation": {
    text: "Sorun Doğrulaması",
    isAlreadyTranslated: true
  },
  Issued: {
    text: "Sorun onaylandı",
    isAlreadyTranslated: true
  },
  Issues: {
    text: "Sorunlar",
    isAlreadyTranslated: true
  },
  "Issues Reported": {
    text: "Bildirilen Sorunlar",
    isAlreadyTranslated: true
  },
  Landmark: {
    text: "Konum",
    isAlreadyTranslated: true
  },
  Loading: {
    text: "Yükleniyor",
    isAlreadyTranslated: true
  },
  "Loading request": {
    text: "Talep yükleniyor",
    isAlreadyTranslated: true
  },
  Location: {
    text: "Konum",
    isAlreadyTranslated: true
  },
  "Logistics - From Customer's Address To Service Center": {
    text: "Nakliye - Müşterinin Adresinden Servis Mekezi'ne",
    isAlreadyTranslated: true
  },
  "Logistics - From Customer's Address To Servify Hub": {
    text: "Nakliye - Müşterinin Adresinden Servify Merkezi'ne",
    isAlreadyTranslated: true
  },
  "Logistics - From Drop-Off Center to Servify Hub": {
    text: "Nakliye - Teslim Noktasından Servify Merkezi'ne",
    isAlreadyTranslated: true
  },
  "Logistics - From Servify Hub to Recycle Hub": {
    text: "Nakliye - Servify Merkezi'nden Geri Dönüşüm Merkezi'ne",
    isAlreadyTranslated: true
  },
  "Logistics - Return to Customer": {
    text: "Nakliye - Müşteriye Gönder",
    isAlreadyTranslated: true
  },
  "Logistics Details": {
    text: "Lojistik Detayları",
    isAlreadyTranslated: true
  },
  "Loss DateTime": {
    text: "Zarar Tarihi",
    isAlreadyTranslated: true
  },
  "Loss Description": {
    text: "Zarar Tanımı",
    isAlreadyTranslated: true
  },
  "Mark Customer Arrival": {
    text: "Müşterinin Varışını İşaretle",
    isAlreadyTranslated: true
  },
  Merge: {
    text: "Birleştir",
    isAlreadyTranslated: true
  },
  "Min 75 Characters": {
    text: "Minimum 75 Karakter",
    isAlreadyTranslated: true
  },
  "Mobile number": {
    text: "Telefon numarası",
    isAlreadyTranslated: true
  },
  "NA": {
    text: "Bilinmiyor",
    isAlreadyTranslated: true
  },
  "Name": {
    text: "Adı ve Soyadı",
    isAlreadyTranslated: true
  },
  "Name of the caller": {
    text: "Arayanın Adı",
    isAlreadyTranslated: true
  },
  "Next Steps": {
    text: "Sıradaki Adım",
    isAlreadyTranslated: true
  },
  "No addresses found": {
    text: "Adres bulunamadı",
    isAlreadyTranslated: true
  },
  "No brands found": {
    text: "Marka bulunamadı",
    isAlreadyTranslated: true
  },
  "No documents uploaded": {
    text: "Yüklenmiş Belge bulunamadı",
    isAlreadyTranslated: true
  },
  "No engineers found": {
    text: "Teknisyen bulunamadı",
    isAlreadyTranslated: true
  },
  "No issues added": {
    text: "Eklenmiş bir sorun bulunamadı",
    isAlreadyTranslated: true
  },
  "No issues found": {
    text: "Sorun bulunamadı",
    isAlreadyTranslated: true
  },
  "No other requests found": {
    text: "Diğer talepler bulunamadı",
    isAlreadyTranslated: true
  },
  "No parts requested": {
    text: "Talep edilen parça bulunamadı",
    isAlreadyTranslated: true
  },
  "No Pending Document Specified": {
    text: "Beklenen Belge bulunamadı",
    isAlreadyTranslated: true
  },
  "No products found": {
    text: "Ürün bulunamadı",
    isAlreadyTranslated: true
  },
  "No servicable consumer product found": {
    text: "Servis verilebilecek bir müşteri ürünü bulunamadı",
    isAlreadyTranslated: true
  },
  "No service locations found": {
    text: "Servis Konumu bulunamadı",
    isAlreadyTranslated: true
  },
  "No Slots Available": {
    text: "Zaman Aralığı bulunamadı",
    isAlreadyTranslated: true
  },
  "No subcategories found": {
    text: "Alt kategori bulunamadı",
    isAlreadyTranslated: true
  },
  "No such service request exists": {
    text: "Böyle bir sevis talebi mevcut değil",
    isAlreadyTranslated: true
  },
  "No supported mode found": {
    text: "Desteklenen mod bulunamadı",
    isAlreadyTranslated: true
  },
  "No supported modes found": {
    text: "Desteklenen modlar bulunamadı",
    isAlreadyTranslated: true
  },
  "No. of Repairs Allowed": {
    text: "Kabul edilen onarım sayısı",
    isAlreadyTranslated: true
  },
  "No. of Repairs Used": {
    text: "Kullanılan Onarımların Sayısı",
    isAlreadyTranslated: true
  },
  "Non-Connect Disposition": {
    text: "Ulaşılamama Nedeni",
    isAlreadyTranslated: true
  },
  Office: {
    text: "Ofis",
    isAlreadyTranslated: true
  },
  Okay: {
    text: "Tamam",
    isAlreadyTranslated: true
  },
  "Oops! There are no verified service centres around.": {
    text: "Oops! Yakında doğrulanmış servis merkezi yok.",
    isAlreadyTranslated: true
  },
  "Oops... Something went wrong.": {
    text: "Oops.. Bir şeyler ters gitti.",
    isAlreadyTranslated: true
  },
  OR: {
    text: "VEYA",
    isAlreadyTranslated: true
  },
  Other: {
    text: "Diğer",
    isAlreadyTranslated: true
  },
  other: {
    text: "diğer",
    isAlreadyTranslated: true
  },
  "Other Requests": {
    text: "Diğer Kayıtlar",
    isAlreadyTranslated: true
  },
  "Out of Warranty": {
    text: "Garanti dışı",
    isAlreadyTranslated: true
  },
  "Override Approval": {
    text: "Müşteri Onayı",
    isAlreadyTranslated: true
  },
  "Override Approval History": {
    text: "Müşteri Onay Geçmişi",
    isAlreadyTranslated: true
  },
  "Override only if customer instructs to": {
    text: "Yalnızca müşteri onay verirse işlem yap",
    isAlreadyTranslated: true
  },
  override_approval: {
    text: "gecersiz_onay",
    isAlreadyTranslated: true
  },
  Paid: {
    text: "Ödenmiş",
    isAlreadyTranslated: true
  },
  "Part Code": {
    text: "Parça Kodu",
    isAlreadyTranslated: true
  },
  "Part Name": {
    text: "Parça Adı",
    isAlreadyTranslated: true
  },
  "Parts and Estimation": {
    text: "Parçalar ve Teklifler",
    isAlreadyTranslated: true
  },
  "Parts Pending": {
    text: "Bekleyen Parçalar",
    isAlreadyTranslated: true
  },
  "Parts Received": {
    text: "Alınan Parçalar",
    isAlreadyTranslated: true
  },
  "Parts Requested": {
    text: "Talep Edilen Parçalar",
    isAlreadyTranslated: true
  },
  "Parts Supply Location": {
    text: "Parçanın Temin Edilen Konumu",
    isAlreadyTranslated: true
  },
  "Pickup Drop By Fedex": {
    text: "Teslimat Fedex tarafından Yapılacak",
    isAlreadyTranslated: true
  },
  "Please enter at least one document": {
    text: "Lütfen en az bir belge giriniz",
    isAlreadyTranslated: true
  },
  "Please enter correct": {
    text: "Lütfen doğru değer giriniz",
    isAlreadyTranslated: true
  },
  "Please enter correct IMEI": {
    text: "Lütfen doğru IMEI giriniz",
    isAlreadyTranslated: true
  },
  "Please enter reason for approval": {
    text: "Lütfen onay için sebep giriniz",
    isAlreadyTranslated: true
  },
  "Please select a subcategory first": {
    text: "Lütfen ilk önce bir alt kategori seçiniz",
    isAlreadyTranslated: true
  },
  "Please specify the reason": {
    text: "Lütfen neden belirtiniz",
    isAlreadyTranslated: true
  },
  "QC Engineer": {
    text: "Kalite Kontrol Teknisyeni",
    isAlreadyTranslated: true
  },
  "Raise Consumer Service Request": {
    text: "Müşterinin Servis Talebini Yükseltin",
    isAlreadyTranslated: true
  },
  "Raise new request": {
    text: "Yeni bir talep yükselt",
    isAlreadyTranslated: true
  },
  "Reason for Approval": {
    text: "Onay Nedeni",
    isAlreadyTranslated: true
  },
  "Receive Device": {
    text: "Cihazı Teslim Al",
    isAlreadyTranslated: true
  },
  "Reject Claim": {
    text: "Yükseltmeyi Reddedin",
    isAlreadyTranslated: true
  },
  "Repair cancel": {
    text: "Onarım iptali",
    isAlreadyTranslated: true
  },
  "Report Issues to GSX": {
    text: "Sorunları GSX'e bildir",
    isAlreadyTranslated: true
  },
  "Request Cancelled": {
    text: "Talep İptal Edildi",
    isAlreadyTranslated: true
  },
  "Request Details": {
    text: "Servis Detayları",
    isAlreadyTranslated: true
  },
  "Request Documents": {
    text: "Fotoğraf ve Belgeler",
    isAlreadyTranslated: true
  },
  "Request Has Been Raised": {
    text: "Talep Yükseltildi",
    isAlreadyTranslated: true
  },
  "Request is being processed": {
    text: "Talep işlemde",
    isAlreadyTranslated: true
  },
  "Request Scheduled for": {
    text: "Kayıt Tarihi",
    isAlreadyTranslated: true
  },
  "Requested By": {
    text: "Talep Eden Kişi",
    isAlreadyTranslated: true
  },
  "Requires customer to print documents and safely pack the device": {
    text:"Müşterinin belgeleri yazdırması ve cihazın güvenli bir şekilde paketlenmesi gerekir",
    isAlreadyTranslated: true
  },
  Reschedule: {
    text: "Yeniden Tarih Belirleyin",
    isAlreadyTranslated: true
  },
  "Retailer Name": {
    text: "Perakende Adı",
    isAlreadyTranslated: true
  },
  "Rewards updated successfully": {
    text: "Ödüller başarılı bir şekilde güncellendi",
    isAlreadyTranslated: true
  },
  Role: {
    text: "Rol",
    isAlreadyTranslated: true
  },
  Save: {
    text: "Kaydet",
    isAlreadyTranslated: true
  },
  "Schedule Date": {
    text: "Kayıt Tarihi",
    isAlreadyTranslated: true
  },
  "Select a Brand": {
    text: "Bir marka seçiniz",
    isAlreadyTranslated: true
  },
  "Select a Consumer Product": {
    text: "Kayıtlı müşteri ürünü seçiniz",
    isAlreadyTranslated: true
  },
  "Select a Date": {
    text: "Bir tarih seçiniz",
    isAlreadyTranslated: true
  },
  "Select a Product": {
    text: "Bir ürün seçiniz",
    isAlreadyTranslated: true
  },
  "Select a Reward": {
    text: "Bir ödül seçiniz",
    isAlreadyTranslated: true
  },
  "Select a service center": {
    text: "Bir servis merkezi seçiniz",
    isAlreadyTranslated: true
  },
  "Select a service location": {
    text: "Bir servis konumu seçiniz",
    isAlreadyTranslated: true
  },
  "Select a Service Mode": {
    text: "Bir servis modu seçiniz",
    isAlreadyTranslated: true
  },
  "Select a Slot": {
    text: "Zaman aralığı belirleyiniz",
    isAlreadyTranslated: true
  },
  "Select a Subcategory": {
    text: "Alt kategori seçiniz",
    isAlreadyTranslated: true
  },
  "Select an Address": {
    text: "Bir adres seçiniz",
    isAlreadyTranslated: true
  },
  "Select an engineer": {
    text: "Bir teknisyen seçiniz",
    isAlreadyTranslated: true
  },
  "Select issues": {
    text: "Sorunları seçiniz",
    isAlreadyTranslated: true
  },
  "Select Logistics Partner": {
    text: "Nakliye Firması seçiniz",
    isAlreadyTranslated: true
  },
  "Select type of damage": {
    text: "Hasar türünü seçiniz",
    isAlreadyTranslated: true
  },
  "Selected Product": {
    text: "Seçilen ürün",
    isAlreadyTranslated: true
  },
  "Send for raising a request?": {
    text: "Talebi oluşturmak için gönder?",
    isAlreadyTranslated: true
  },
  "Search issues": {
    text: "Sorunları arayın",
    isAlreadyTranslated: true
  },
  "Search brands": {
    text: "Markaları arayın",
    isAlreadyTranslated: true
  },
  "Search products": {
    text: "Ürünleri arayın",
    isAlreadyTranslated: true
  },
  "Search subcategories": {
    text: "Alt kategorileri arayın",
    isAlreadyTranslated: true
  },
  "Serial No": {
    text: "Seri No",
    isAlreadyTranslated: true
  },
  "Serial Number": {
    text: "Seri Numarası",
    isAlreadyTranslated: true
  },
  Service: {
    text: "Servis",
    isAlreadyTranslated: true
  },
  "Service cancel": {
    text: "Servisi İptal Et",
    isAlreadyTranslated: true
  },
  "Service Center": {
    text: "Servis Merkezi",
    isAlreadyTranslated: true
  },
  "Service completed": {
    text: "Servis Tamamlandı",
    isAlreadyTranslated: true
  },
  "Service Engineer": {
    text: "Servis Teknisyeni",
    isAlreadyTranslated: true
  },
  "Service Type": {
    text: "Servis Tipi",
    isAlreadyTranslated: true
  },
  "Slot": {
    text: "Randevu aralığı",
    isAlreadyTranslated: true
  },
  "Slot Time": {
    text: "Mevcut zaman süresi",
    isAlreadyTranslated: true
  },
  "Something went wrong": {
    text: "Bir şeyler ters gitti",
    isAlreadyTranslated: true
  },
  "Something went wrong while adding the product": {
    text: "Ürün eklerken bir şeyler ters gitti",
    isAlreadyTranslated: true
  },
  "Something went wrong while raising service request": {
    text: "Servis Talebi yükseltilirken bir şeyler ters gitti",
    isAlreadyTranslated: true
  },
  "Start Repair": {
    text: "Onarıma Başla",
    isAlreadyTranslated: true
  },
  "Started On": {
    text: "Başladı",
    isAlreadyTranslated: true
  },
  Status: {
    text: "Durum",
    isAlreadyTranslated: true
  },
  "Storage Capacity": {
    text: "Hafıza Kapasitesi",
    isAlreadyTranslated: true
  },
  "Store Manager": {
    text: "Depo Yöneticisi",
    isAlreadyTranslated: true
  },
  Submit: {
    text: "Onayla",
    isAlreadyTranslated: true
  },
  success: {
    text: "Başarılı",
    isAlreadyTranslated: true
  },
  Success: {
    text: "Başarılı",
    isAlreadyTranslated: true
  },
  Superadmin: {
    text: "Süperadmin",
    isAlreadyTranslated: true
  },
  Supervisor: {
    text: "Süpervisör",
    isAlreadyTranslated: true
  },
  "Telecon Version:": {
    text: "Telecon Sürümü",
    isAlreadyTranslated: true
  },
  "The Green Reward cash amount will be credited to the account associated with this number": {
    text: "Yeşil Hediye nakit tutarı, bu numarayla ilişkili hesaba yatırılır",
    isAlreadyTranslated: true
  },
  "The request was raised successfully": {
    text: "Talep başarılı bir şekilde yükseltildi",
    isAlreadyTranslated: true
  },
  "The two numbers do not match": {
    text: "Bu iki sayı birbiriyle uyuşmuyor",
    isAlreadyTranslated: true
  },
  "There is no service for this address at this moment": {
    text: "Şu an bu adres için servis yok",
    isAlreadyTranslated: true
  },
  "There's a problem with this landmark, please select a different landmark": {
    text:"Bu dönüm noktasında bir sorun var, lütfen farklı bir dönüm noktası seçin",
    isAlreadyTranslated: true
  },
  "Time of Damage": {
    text: "Hasar Oluştuğu Zaman",
    isAlreadyTranslated: true
  },
  "Total Amount": {
    text: "Toplam tutar",
    isAlreadyTranslated: true
  },
  "Select Damage Type": {
    text: "Hasar Tipini Seçin",
    isAlreadyTranslated: true
  },
  "Type Of Program": {
    text: "Program Türü",
    isAlreadyTranslated: true
  },
  "Under Warranty": {
    text: "Garanti Kapsamında",
    isAlreadyTranslated: true
  },
  Validate: {
    text: "Doğrulayın",
    isAlreadyTranslated: true
  },
  "Validity Text": {
    text: "Geçerlilik metni",
    isAlreadyTranslated: true
  },
  "Verification Code": {
    text: "Doğrulama Kodu",
    isAlreadyTranslated: true
  },
  "View": {
    text: "Görüntüle",
    isAlreadyTranslated: true
  },
  "View Advance Receipt": {
    text: "Tahsilat Makbuzunu Görüntüle",
    isAlreadyTranslated: true
  },
  "View DOA Certificate": {
    text: "DOA Sertifikasını Görüntüle",
    isAlreadyTranslated: true
  },
  "View Invoice": {
    text: "Faturayı Görüntüle",
    isAlreadyTranslated: true
  },
  "View Jobsheet": {
    text: "Servis Formunu Görüntüle",
    isAlreadyTranslated: true
  },
  "View Parts Document": {
    text: "Parçaların Belgesini Görüntüle",
    isAlreadyTranslated: true
  },
  "Waiting for the customer to raise a request": {
    text: "Müşterinin bir talebi yükseltmesi bekleniyor",
    isAlreadyTranslated: true
  },
  Warranty: {
    text: "Garanti",
    isAlreadyTranslated: true
  },
  "Warranty Applicable": {
    text: "Garanti Uygunluğu",
    isAlreadyTranslated: true
  },
  "Warranty Status": {
    text: "Garanti Durumu",
    isAlreadyTranslated: true
  },
  "Warranty Till": {
    text: "Garanti Bitiş Süresi",
    isAlreadyTranslated: true
  },
  "Waybill Number": {
    text: "İrsaliye Numarası",
    isAlreadyTranslated: true
  },
  "Year of Purchase": {
    text: "Satın Alım Yılı",
    isAlreadyTranslated: true
  },
  Yes: {
    text: "Evet",
    isAlreadyTranslated: true
  },
  "You have already added this document type in the list of pending documents": {
    text: "Bu belge türünü bekleyen belgeler listesine zaten eklediniz",
    isAlreadyTranslated: true
  },
  "You have already added this issue": {
    text: "Bu sorunu zaten eklediniz",
    isAlreadyTranslated: true
  },
  Zipcode: {
    text: "Posta Kodu",
    isAlreadyTranslated: true
  },
  "Raise Claim": {
    text: "Talep Yükselt",
    isAlreadyTranslated: true
  },
  "Invalid Claim": {
    text: "Geçersiz Yükseltme",
    isAlreadyTranslated: true
  },
  "Reject Estimation": {
    text: "Teklifi Reddet",
    isAlreadyTranslated: true
  },
  "Collect Advance": {
    text: "Ön Ödeme Al",
    isAlreadyTranslated: true
  },
  "Complete QC": {
    text: "Kalite Kontrolü tamamla",
    isAlreadyTranslated: true
  },
  "Handover device": {
    text: "Cihazı aktar",
    isAlreadyTranslated: true
  },
  "Accept device": {
    text: "Cihazı kabul et",
    isAlreadyTranslated: true
  },
  "Complete Job and Handover Device": {
    text: "İşi tamamla ve cihazı teslim et",
    isAlreadyTranslated: true
  },
  "Collect Device": {
    text: "Aygıtı Topla",
    isAlreadyTranslated: true
  },
  "Accept/Reject Job": {
    text: "İşi Kabul Et / Reddet",
    isAlreadyTranslated: true
  },
  "Generate estimation": {
    text: "Teklif oluştur",
    isAlreadyTranslated: true
  },
  "characters entered.": {
    text: "karakterler girildi.",
    isAlreadyTranslated: true
  },
  "Your GSX Request has been created": {
    text: "GSX Talebiniz oluşturuldu",
    isAlreadyTranslated: true
  },
  "Upload Inspection Documents": {
    text: "Denetim Belgelerini Yükle",
    isAlreadyTranslated: true
  },
  "Complete DOA": {
    text: "DOA işlemini tamamla.",
    isAlreadyTranslated: true
  },
  "QC Completed": {
    text: "Kalite Kontrol Tamamlandı",
    isAlreadyTranslated: true
  },
  "Create GSX request": {
    text: "GSX isteği oluştur",
    isAlreadyTranslated: true
  },
  "DOA Checklist": {
    text: "DOA Kontrol Listesi",
    isAlreadyTranslated: true
  },
  "Reject Parts Request": {
    text: "Parça İsteğini Reddet",
    isAlreadyTranslated: true
  },
  "Accept Parts Request": {
    text: "Parça İsteğini Kabul Et",
    isAlreadyTranslated: true
  },
  "Cannot Diagnose": {
    text: "Tanılama yapılamadı.",
    isAlreadyTranslated: true
  },
  "Initiate Diagnosis": {
    text: "Tanı Başlat",
    isAlreadyTranslated: true
  },
  "Diagnosis Completed": {
    text: "Tanı Tamamlandı",
    isAlreadyTranslated: true
  },
  "Accept Device": {
    text: "Cihazı Kabul Et",
    isAlreadyTranslated: true
  },
  "Waiting for engineer to accept the job.": {
    text: "Teknisyenin işi kabul etmesi bekleniyor.",
    isAlreadyTranslated: true
  },
  "Transfer Request": {
    text: "Transfer Talebi",
    isAlreadyTranslated: true
  },
  "Accept and Complete QC": {
    text: "Kabul et ve Kalite Kontrolü Tamamla",
    isAlreadyTranslated: true
  },
  "Reject QC": {
    text: "Kalite Kontrolü Reddet",
    isAlreadyTranslated: true
  },
  "Confirm BER": {
    text: "BER'i onaylayın",
    isAlreadyTranslated: true
  },
  "Reject BER": {
    text: "BER'i ​​reddet",
    isAlreadyTranslated: true
  },
  "Perform QC": {
    text: "Kalite Kontrol gerçekleştir",
    isAlreadyTranslated: true
  },
  "Fault Codes": {
    text: "Arıza kodları",
    isAlreadyTranslated: true
  },
  Actions: {
    text: "Eylemler",
    isAlreadyTranslated: true
  },
  "Engineer Added Faults": {
    text: "Teknisyenin Eklediği Arızalar",
    isAlreadyTranslated: true
  },
  Faults: {
    text: "Arızalar",
    isAlreadyTranslated: true
  },
  "Action Code": {
    text: "İşlem Kodu",
    isAlreadyTranslated: true
  },
  "N/A": {
    text: "Yok",
    isAlreadyTranslated: true
  },
  "Waiting for the Customer Care Executive to receive device and complete mandatory checklist tasks.": {
    text:"Müşteri Temsilcisi'nin cihazı alması ve zorunlu kontrol listesi görevlerini tamamlaması bekleniyor.",
    isAlreadyTranslated: true
  },
  "Waiting for CC to receive device and complete mandatory checklist tasks.": {
    text:"Müşteri Temsilcisi'nin cihazı alması ve zorunlu denetim listesi görevlerini tamamlaması bekleniyor.",
    isAlreadyTranslated: true
  },
  "Yes, the device is whitelisted. Please upload screenshot of the same": {
    text: "Evet, cihaz BTK'ya kayıtlıdır. Lütfen ekran görüntüsünü yükleyin",
    isAlreadyTranslated: true
  },
  "No pictures added": {
    text: "Resim eklenmedi",
    isAlreadyTranslated: true
  },
  "No, the device is not whitelisted. Please cancel the request and return the device if you have it": {
    text:"Hayır, cihaz BTK'ya kayıtlı değil. Lütfen talebinizi iptal edin ve cihazı iade edin",
    isAlreadyTranslated: true
  },
  Next: {
    text: "Sonraki",
    isAlreadyTranslated: true
  },
  "Device Name": {
    text: "Cihaz adı",
    isAlreadyTranslated: true
  },
  ELS: {
    text: "ELS",
    isAlreadyTranslated: true
  },
  QC: {
    text: "Kalite Kontrol",
    isAlreadyTranslated: true
  },
  "Please select Coverage Option": {
    text: "Lütfen Garanti Kapsamı Seçeneğini Belirleyin",
    isAlreadyTranslated: true
  },
  DOA: {
    text: "DOA",
    isAlreadyTranslated: true
  },
  "Device Whitelisting Information": {
    text: "Cihazın BTK Kayıt Durumu",
    isAlreadyTranslated: true
  },
  "KBB Serial Number": {
    text: "KBB Seri Numarası",
    isAlreadyTranslated: true
  },
  "Inspection Completed, Awaiting Engineer Assignment": {
    text: "İnceleme Tamamlandı, Teknisyen Ataması Bekleniyor",
    isAlreadyTranslated: true
  },
  "Verification Complete": {
    text: "Doğrulama Tamamlandı",
    isAlreadyTranslated: true
  },
  "Dropoff Request Registered": {
    text: " Bırakma Talebiniz Kaydedildi",
    isAlreadyTranslated: true
  },
  "Service initiated": {
    text: "Servis başlatıldı",
    isAlreadyTranslated: true
  },
  "Device dropped": {
    text: "Cihaz bırakıldı",
    isAlreadyTranslated: true
  },
  "Recycle Dropoff": {
    text: "Geri Dönüşüm Teslim Etme",
    isAlreadyTranslated: true
  },
  "Pledge request created": {
    text: "Rehin talebi oluşturuldu",
    isAlreadyTranslated: true
  },
  "QC completed": {
    text: "Kalite kontrol tamamlandı",
    isAlreadyTranslated: true
  },
  sdv: {
    text: "sdv",
    isAlreadyTranslated: true
  },
  "Intimate customer": {
    text: "Müşteriye bildirin",
    isAlreadyTranslated: true
  },
  "Repair has been cancelled.": {
    text: "Onarım iptal edildi.",
    isAlreadyTranslated: true
  },
  "BTB socket": {
    text: "BTB soketi",
    isAlreadyTranslated: true
  },
  "SIM Card needle": {
    text: "SIM Kart İğnesi",
    isAlreadyTranslated: true
  },
  "SIM card holder EB101 grey aluminum alloy": {
    text: "EB101 gri alüminyum alaşımlı SIM kart tutucu",
    isAlreadyTranslated: true
  },
  "Pending for Acceptance by Engineer": {
    text: "Teknisyen Tarafından Kabul Edilmesi Bekleniyor",
    isAlreadyTranslated: true
  },
  microphone: {
    text: "mikrofon",
    isAlreadyTranslated: true
  },
  "for Token generated": {
    text: "Token için oluşturuldu",
    isAlreadyTranslated: true
  },
  "Advance collected": {
    text: "Ön Ödeme Alındı",
    isAlreadyTranslated: true
  },
  "pin added": {
    text: "posta kodu eklendi",
    isAlreadyTranslated: true
  },
  "Trade In Dropoff": {
    text: "Teslim Edin",
    isAlreadyTranslated: true
  },
  "No, the device is not whitelisted. Please return the device if you have it": {
    text: "Hayır, cihaz beyaz listede değil.",
    isAlreadyTranslated: true
  },
  "Download Open/Inprogress requests.": {
    text: "Açık / Devam Ediyor isteklerini indirin.",
    isAlreadyTranslated: true
  },
  "Waiting for engineer to accept the job. (Eligible for DOA)": {
    text: "Teknisyenin işi kabul etmesi bekleniyor. (DOA için uygundur)",
    isAlreadyTranslated: true
  },
  " (Eligible for DOA)": {
    text: " (DOA için uygundur)",
    isAlreadyTranslated: true
  },
  "Customer Care Executive will choose action for the device from Dead on Arrival section below.": {
    text:"Müşteri Temsilcisi, aşağıdaki DOA alanından cihaz için işlem seçecektir.",
    isAlreadyTranslated: true
  },
  "Engineer will select the new device from Dead on Arrival section below": {
    text: "Teknisyen yeni cihazı aşağıdaki DOA bölümünden seçecek",
    isAlreadyTranslated: true
  },
  "Device repair has failed QC. Device is to be given back to the Engineer.": {
    text:"Cihaz onarımı başarısız oldu Kalite Kontrol Teknisyeni, cihaz Teknisyene geri verecek.",
    isAlreadyTranslated: true
  },
  'Waiting for Estimation Approval. Click on "Send for approval" in "Parts and Estimation" section below.': {
    text:'Teklif Onayı Bekleniyor. Aşağıdaki "Parçalar ve Teklifler" bölümünde "Onay için gönder" düğmesine tıklayın.',
    isAlreadyTranslated: true
  },
  'Waiting for customer approval of estimation or override estimation approval in "Parts and Estimation" section below.': {
    text:'Müşteriden teklif onayı bekleniyor. Ayrıca aşağıdaki "Parçalar ve Teklifler" kısmından da teklif onaylanabilir',
    isAlreadyTranslated: true
  },
  "Estimation has been rejected, parts may be added if required by the engineer.": {
    text:"Teklif reddedildi, teknisyen tarafından istenirse parçalar eklenebilir.",
    isAlreadyTranslated: true
  },
  'Waiting for store to issue required parts in "Parts Requested" section below. Cannot complete repair with parts pending.': {
    text:"Deponun aşağıdaki 'Talep Edilen Parçalar' bölümünde gerekli parçaları temin etmesi bekleniyor. Parça beklenirken, onarım tamamlanamaz.",
    isAlreadyTranslated: true
  },

  'Waiting for store to issue required device in "Dead on arrival" section below.': {
    text:'Aşağıdaki "DOA" bölümünde gerekli cihazın işlenmesi için bekleniyor.',
    isAlreadyTranslated: true
  },

  'Waiting for the Engineer to receive the "Replacement device" from store in "Dead on Arrival" section below.': {
    text:'Teknisyenin aşağıdaki "DOA" bölümünde bulunan "Değişim cihazını" alması bekleniyor.',
    isAlreadyTranslated: true
  },
  'Waiting for Engineer to receive parts from store in "Parts Requested" section below.': {
    text:'Teknisyenin aşağıdaki "Talep Edilen Parçalar" bölümünde depodan parçaları alması bekleniyor.',
    isAlreadyTranslated: true
  },
  "Waiting for the Engineer to complete DOA by handing over the damaged device to Store": {
    text:"Hasarlı cihazı depoya teslim ederek Teknisyenin DOA'yı tamamlamasını bekleniyor",
    isAlreadyTranslated: true
  },
  "All parts received, repair is in progress.": {
    text: "Sipariş edilen tüm parçalar ulaştı, onarım devam ediyor.",
    isAlreadyTranslated: true
  },
  "Engineer completed the repair.": {
    text: "Teknisyen tamiri tamamladı.",
    isAlreadyTranslated: true
  },
  "Service invoice has been generated, final payment can be received.": {
    text: "Servis faturası oluşturuldu, son ödeme alınabilir.",
    isAlreadyTranslated: true
  },
  "Waiting for the engineer to handover device to Store": {
    text: "Teknisyenin cihazı Depo'ya teslim etmesi bekleniyor",
    isAlreadyTranslated: true
  },
  "Waiting for the Store to handover device to Customer Care Executive": {
    text:"Deponun cihazı Müşteri Temsilcisi'ne teslim etmesi bekleniyor.",
    isAlreadyTranslated: true
  },
  "Waiting for the Customer Care Executive to complete the DOA": {
    text: "Müşteri Temsilcisinin DOA'yı tamamlaması bekleniyor.",
    isAlreadyTranslated: true
  },
  "Please complete the service.": {
    text: "Lütfen hizmeti tamamlayın.",
    isAlreadyTranslated: true
  },
  "Waiting for the customer to rate the service": {
    text: "Müşterinin hizmeti değerlendirmesi bekleniyor",
    isAlreadyTranslated: true
  },
  "Intimate customer.": {
    text: "Samimi müşteri.",
    isAlreadyTranslated: true
  },
  Logistics: {
    text: "Lojistik",
    isAlreadyTranslated: true
  },
  "Send link": {
    text: "Link gönder",
    isAlreadyTranslated: true
  },
  "Other Image": {
    text: "Diğer Görüntü",
    isAlreadyTranslated: true
  },
  Item: {
    text: "Öğe",
    isAlreadyTranslated: true
  },
  "Transfer Details": {
    text: "Transfer Ayrıntıları",
    isAlreadyTranslated: true
  },
  "Schedule a Logistic Request": {
    text: "Lojistik İsteğin Planlanması",
    isAlreadyTranslated: true
  },
  Forward: {
    text: "ileri",
    isAlreadyTranslated: true
  },
  Reverse: {
    text: "Ters",
    isAlreadyTranslated: true
  },

  "Transfer Details": {
    text: "Transfer Ayrıntıları",
    isAlreadyTranslated: true
  },
  Forward: {
    text: "ileri",
    isAlreadyTranslated: true
  },
  Reverse: {
    text: "Ters",
    isAlreadyTranslated: true
  },

  "Track Logistics Requests": {
    text: "Lojistik Taleplerini Takip Et",
    isAlreadyTranslated: true
  },
  "Pickup Pincode": {
    text: "Teslim Alınacak Posta Kodu",
    isAlreadyTranslated: true
  },
  "Recycle Request Schedule Date": {
    text: "Geri Dönüşüm İsteği Zamanlama Tarihi",
    isAlreadyTranslated: true
  },
  "Recycle Request Created Date": {
    text: "Geri Dönüşüm İsteği Oluşturulma Tarihi",
    isAlreadyTranslated: true
  },
  "Schedule Logistics Request": {
    text: "Lojistik İsteği Zamanlama",
    isAlreadyTranslated: true
  },
  "Hub's Address Details": {
    text: "Hub'ın Adres Detayları",
    isAlreadyTranslated: true
  },
  Now: {
    text: "şimdi",
    isAlreadyTranslated: true
  },
  Later: {
    text: "Sonra",
    isAlreadyTranslated: true
  },
  "Recycle Hub Details": {
    text: "Geri Dönüşüm Hub Detayları",
    isAlreadyTranslated: true
  },
  "This request will be added to a queue. You can schedule a logistics request later.": {
    text:"Bu istek bir sıraya eklenecektir. Daha sonra bir lojistik talebi planlayabilirsiniz.",
    isAlreadyTranslated: true
  },
  "Request Added to Queue Successfully": {
    text: "Talep Sıraya Başarıyla Eklendi",
    isAlreadyTranslated: true
  },
  "Create Bulk Requests": {
    text: "Toplu Talep Yaratın",
    isAlreadyTranslated: true
  },
  "Logistics Status": {
    text: "Taşıma Durumu",
    isAlreadyTranslated: true
  },
  "Drop Pincode": {
    text: "Teslim Etme Posta Kodu",
    isAlreadyTranslated: true
  },
  "Logistic Request Created Date": {
    text: "Lojistik Talep Oluşturma Tarihi",
    isAlreadyTranslated: true
  },
  "Logistic Vendor": {
    text: "Lojistik Sağlayıcı",
    isAlreadyTranslated: true
  },
  "Bulk Logistic Reference ID": {
    text: "Toplu Lojistik Referans Numarası",
    isAlreadyTranslated: true
  },
  "Not Covered": {
    text: "Kapsamda Değil",
    isAlreadyTranslated: true
  },
  Queue: {
    text: "kuyruk",
    isAlreadyTranslated: true
  },
  "LOGISTICS REQUESTS": {
    text: "LOJİSTİK TALEPLERİ",
    isAlreadyTranslated: true
  },
  "Work in progress": {
    text: "Çalışma devam ediyor",
    isAlreadyTranslated: false
  },
  "Recycle Pickup": {
    text: "Geri Dönüştürme Alma",
    isAlreadyTranslated: false
  },
  "Recycle Request": {
    text: "Geri Dönüşüm İsteği",
    isAlreadyTranslated: false
  },
  "Beyond Economic Repair": {
    text: "Ekonomik Onarımın Ötesinde",
    isAlreadyTranslated: false
  },
  "Device collected": {
    text: "Cihaz toplandı",
    isAlreadyTranslated: false
  },
  "Green Reward Update": {
    text: "Yeşil Ödül Güncellemesi",
    isAlreadyTranslated: false
  },
  "Vouchers generation pending": {
    text: "Belge üretimi beklemede",
    isAlreadyTranslated: false
  },
  "Recycle Request cancelled": {
    text: "Geri Dönüşüm İsteği iptal edildi",
    isAlreadyTranslated: false
  },
  "Recycle Completed": {
    text: "Geri Dönüşüm Tamamlandı",
    isAlreadyTranslated: false
  },

  "Vouchers generated": {
    text: "Belge oluşturuldu",
    isAlreadyTranslated: false
  },
  "Initial Dismantling Completed": {
    text: "İlk Söküm Tamamlandı",
    isAlreadyTranslated: false
  },
  "Device recycle initiated": {
    text: "Cihaz geri dönüşümü başlatıldı",
    isAlreadyTranslated: false
  },
  "Claim withdrawn": {
    text: "Çekildiği iddiası",
    isAlreadyTranslated: false
  },
  "Pending Verification": {
    text: "Doğrulama beklemede",
    isAlreadyTranslated: false
  },
  "Screen damage": {
    text: "Ekran hasarı",
    isAlreadyTranslated: false
  },
  Print: {
    text: "Yazdır",
    isAlreadyTranslated: false
  },
  "Pupa Service Record": {
    text: "Gürgençler Servis Kaydı",
    isAlreadyTranslated: false
  },
  "User Name": {
    text: "Kullanıcı Adı",
    isAlreadyTranslated: false
  },
  Telephone: {
    text: "Telefon",
    isAlreadyTranslated: false
  },
  "Product Model": {
    text: "Cihaz Modeli",
    isAlreadyTranslated: false
  },
  "Purchase Date": {
    text: "Satınalma Tarihi",
    isAlreadyTranslated: false
  },
  "Carry-in Time": {
    text: "Servis Merkezine Varış Tarihi",
    isAlreadyTranslated: false
  },
  "Pick-up Time": {
    text: "Servisten Teslim Tarihi",
    isAlreadyTranslated: false
  },
  "Key Account Name": {
    text: "Anahtar Hesap Adı",
    isAlreadyTranslated: false
  },
  "Service Offering": {
    text: "Servis Teklifi",
    isAlreadyTranslated: false
  },
  "In warranty": {
    text: "Garanti kapsamında",
    isAlreadyTranslated: false
  },
  "Problem Description by User": {
    text: "Kullanıcı Tarafında Belirtilen Sorunlar",
    isAlreadyTranslated: false
  },
  "Parts replacement": {
    text: "Parça değişimi",
    isAlreadyTranslated: false
  },
  "New IMEI/SN": {
    text: "Yeni IMEI/Seri Numarası",
    isAlreadyTranslated: false
  },
  "Part Number": {
    text: "Parça Numarası",
    isAlreadyTranslated: false
  },
  "Model Number": {
    text: "Model Numarası",
    isAlreadyTranslated: false
  },
  "Replacement Name": {
    text: "Yeni Model Adı",
    isAlreadyTranslated: false
  },
  "Engineer Signature": {
    text: "Teknisyen İmzası",
    isAlreadyTranslated: false
  },
  "Finish Date": {
    text: "Bitiş Tarihi",
    isAlreadyTranslated: false
  },
  "Very Satisfied": {
    text: "Çok memnunum",
    isAlreadyTranslated: false
  },
  Satisfied: {
    text: "Memnunum",
    isAlreadyTranslated: false
  },
  Neutral: {
    text: "Nötr",
    isAlreadyTranslated: false
  },
  Dissatisfied: {
    text: "Memnun değilim",
    isAlreadyTranslated: false
  },
  "Very Dissatisfied": {
    text: "Hiç memnun değilim",
    isAlreadyTranslated: false
  },
  "User Signature": {
    text: "Müşteri İmzası",
    isAlreadyTranslated: false
  },
  Date: {
    text: "Tarih",
    isAlreadyTranslated: false
  },
  "Thank you for choosing Pupa": {
    text: "Bizi tercih ettiğiniz için teşekkürler",
    isAlreadyTranslated: false
  },
  "Pupa Service Form": {
    text: "Gürgençler Teknik Servis Formu",
    isAlreadyTranslated: false
  },
  "Recovery Method": {
    text: "Çözüm Yöntemi",
    isAlreadyTranslated: false
  },
  "Defective Model Name": {
    text: "Arızalı Ürün Adı",
    isAlreadyTranslated: false
  },
  "Defective Part Name": {
    text: "Arızalı Parça Açıklaması",
    isAlreadyTranslated: false
  },
  "Coverage Type": {
    text: "Kapsam Türü",
    isAlreadyTranslated: false
  },
  "Service Ticket No": {
    text: "Servis Takip No",
    isAlreadyTranslated: false
  },
  "Customer Care": {
    text: "Müşteri Temsilcisi",
    isAlreadyTranslated: false
  },
  "Are you satisfied with Pupa service experience": {
    text: "Gürgençler Servis deneyiminden memnun kaldınız mı",
    isAlreadyTranslated: false
  },
  "Please comment if you are dissatisfied": {
    text: "Eğer memnun değilseniz yorumunuzu rica ederiz.",
    isAlreadyTranslated: false
  },
  "Front Image": {
    text: "Ön Fotoğraf",
    isAlreadyTranslated: false
  },
  "Back Image": {
    text: "Arka Fotoğraf",
    isAlreadyTranslated: false
  },
  "Top Image": {
    text: "Üst Fotoğraf",
    isAlreadyTranslated: false
  },
  "Bottom Image": {
    text: "Alt Fotoğraf",
    isAlreadyTranslated: false
  },
  "Left Image": {
    text: "Sol Fotoğraf",
    isAlreadyTranslated: false
  },
  "Right Image": {
    text: "Sağ Fotoğraf",
    isAlreadyTranslated: false
  },
  "Device Invoice": {
    text: "Cihaz Faturası",
    isAlreadyTranslated: false
  },
  "Cosmetic Status of Device": {
    text: "Cihaz Dış Görünümü",
    isAlreadyTranslated: false
  },
  "Latest diagnostics result": {
    text: "Son Tanı Sonuçları",
    isAlreadyTranslated: false
  },
  "Purchase Order Number": {
    text: "Sipariş Numarası",
    isAlreadyTranslated: false
  },
  "National ID": {
    text: "T.C. Kimlik No",
    isAlreadyTranslated: false
  },
  "Last Repair Date": {
    text: "Son Onarım Tarihi",
    isAlreadyTranslated: false
  },
  "Product Unique ID": {
    text: "Ürüne Özgün Kimlik",
    isAlreadyTranslated: false
  },
  "We were unable to find details from GSX, please select product manually": {
    text: "Ürün bilgilerine GSX'den ulaşamadığımız için lütfen manuel seçiniz.",
    isAlreadyTranslated: false
  },
  "No serviceable consumer product found": {
    text: "Kullanılabilir hiçbir müşteri ürünü bulunamadı",
    isAlreadyTranslated: false
  },
  "Is Warranty Applicable for this request": {
    text: "Garanti bu istek için geçerli mi?",
    isAlreadyTranslated: false
  },
  "Where was the device kept at the time of incident": {
    text: "Arıza Anında Cihaz Nerede Saklanıyordu",
    isAlreadyTranslated: false
  },
  "Min 100 Characters": {
    text: "En az 100 Karakter",
    isAlreadyTranslated: false
  },
  LEFT: {
    text: "SOL",
    isAlreadyTranslated: false
  },
  "Please Enter Minimum 100 Characters": {
    text: "Lütfen En Az 100 Karakter Giriniz",
    isAlreadyTranslated: false
  },
  "Enter Other Issue Description": {
    text: "Diğer Sorun Açıklaması",
    isAlreadyTranslated: false
  },
  "Send for raising request": {
    text: "Talep oluşturmak için gönder",
    isAlreadyTranslated: false
  },
  "Send for raising request?": {
    text: "Talep oluşturmak için gönder?",
    isAlreadyTranslated: false
  },
  "Air Conditioner": {
    text: "Klima",
    isAlreadyTranslated: false
  },
  STORAGE: {
    text: "DEPOLAMA",
    isAlreadyTranslated: false
  },
  "TELECOM SERVICES": {
    text: "TELEKOM HİZMETLERİ",
    isAlreadyTranslated: false
  },
  "Trade In Pickup": {
    text: "Teslim Alın",
    isAlreadyTranslated: false
  },
  Vibrator: {
    text: "Titreşim motoru",
    isAlreadyTranslated: false
  },
  Sound: {
    text: "Ses",
    isAlreadyTranslated: false
  },
  Display: {
    text: "Ekran",
    isAlreadyTranslated: false
  },
  Accessories: {
    text: "Aksesuarlar",
    isAlreadyTranslated: false
  },
  "Physical Damage": {
    text: "Fiziksel hasar",
    isAlreadyTranslated: false
  },
  "System Performance": {
    text: "Sistem performansı",
    isAlreadyTranslated: false
  },
  Power: {
    text: "Güç",
    isAlreadyTranslated: false
  },
  "Built in Camera": {
    text: "Yerleşik Kamera",
    isAlreadyTranslated: false
  },
  "Data Storage": {
    text: "Veri depolama",
    isAlreadyTranslated: false
  },
  "Communication/Networking": {
    text: "İletişim / Ağ",
    isAlreadyTranslated: false
  },
  "Consumer Product already exists with Serial/IMEI number": {
    text: "Müşteri Ürünü Seri / IMEI numarasıyla zaten sistemde mevcut",
    isAlreadyTranslated: false
  },
  "Oops. We don’t service this device at this location. But we’re hard at work and you should get some good news soon. Try later?": {
    text:"Maalesef, bu bölgede bu ürüne servis veremiyoruz. Fakat çok yakında servis vermek için çaba sarf edeceğiz.",
    isAlreadyTranslated: false
  },
  "Enter Issue Decsription": {
    text: "Sorun Açıklamasını Yazınız",
    isAlreadyTranslated: false
  },
  office: {
    text: "iş",
    isAlreadyTranslated: false
  },
  "Enter a location": {
    text: "Lokasyon Giriniz",
    isAlreadyTranslated: false
  },
  Accept: {
    text: "Kabul Et",
    isAlreadyTranslated: false
  },
  Reject: {
    text: "Reddet",
    isAlreadyTranslated: false
  },
  "Warranty Concession": {
    text: "Garanti İmtiyazı",
    isAlreadyTranslated: false
  },
  "No order lines found for given request": {
    text: "Bildirilen talep için sipariş kalemi bulunamadı",
    isAlreadyTranslated: false
  },
  "Toggle Warranty": {
    text: "Garantiyi Düzenle",
    isAlreadyTranslated: false
  },
  "Provide link for customer": {
    text: "Müşteriye link paylaş",
    isAlreadyTranslated: false
  },
  "Accept or Reject Request": {
    text: "Talebi kabul ya da reddet",
    isAlreadyTranslated: false
  },
  Inward: {
    text: "Gelen",
    isAlreadyTranslated: false
  },
  Outward: {
    text: "Giden",
    isAlreadyTranslated: false
  },
  "Max number of retry attempts exceeded": {
    text: "Maksimum deneme sayısı aşıldı",
    isAlreadyTranslated: false
  },
  "Please Enter Incident Place of Damage": {
    text: "Lütfen Hasarın Yerini Belirtin",
    isAlreadyTranslated: false
  },
  "Please enter the link": {
    text: "Lütfen link giriniz",
    isAlreadyTranslated: false
  },

  "Please Select Device Receiving Time": {
    text: "Lütfen Cihaz Teslim Alma Zamanını Seçiniz",
    isAlreadyTranslated: false
  },
  "Protection Status": {
    text: "Koruma Durumu",
    isAlreadyTranslated: false
  },
  "Config Description": {
    text: "Yapılandırma açıklaması",
    isAlreadyTranslated: false
  },
  "Please specify the reason...": {
    text: "Lütfen sebebini belirtin...",
    isAlreadyTranslated: false
  },
  "An error has occurred. Please contact support@servify.in for help.": {
    text:"Bir hata oluştu. Yardım için lütfen support@servify.in ile iletişime geçin.",
    isAlreadyTranslated: false
  },
  "The parts will be returned to Store": {
    text: "Parçalar Depo'ya iade edilecek",
    isAlreadyTranslated: false
  },
  "Select a Part Name": {
    text: "Bir parça adı seçin",
    isAlreadyTranslated: false
  },
  "Restore from Backup History": {
    text: "Yedekleme Geçmişinden Geri Yükle",
    isAlreadyTranslated: false
  },
  PASSED: {
    text: "BAŞARLI",
    isAlreadyTranslated: false
  },
  "Backup History": {
    text: "Yedekleme Geçmişi",
    isAlreadyTranslated: false
  },
  "WARNING : This device has not been backed up in the last two weeks.": {
    text: "UYARI: Bu cihaz son iki haftada yedeklenmedi.",
    isAlreadyTranslated: false
  },
  Sensors: {
    text: "Sensörler",
    isAlreadyTranslated: false
  },
  "Temperature History": {
    text: "Sıcaklık Geçmişi",
    isAlreadyTranslated: false
  },
  Accelerometer: {
    text: "İvme ölçer",
    isAlreadyTranslated: false
  },
  "Accelerometer Sensor Test": {
    text: "İvmeölçer Sensör Testi",
    isAlreadyTranslated: false
  },
  ALS: {
    text: "ALS",
    isAlreadyTranslated: false
  },
  "Ambient Light Sensor Test": {
    text: "Ortam Işığı Sensör Testi",
    isAlreadyTranslated: false
  },
  Bluetooth: {
    text: "Bluetooth",
    isAlreadyTranslated: false
  },
  "Bluetooth Scan Test": {
    text: "Bluetooth Varlık Testi",
    isAlreadyTranslated: false
  },
  Camera: {
    text: "Kamera",
    isAlreadyTranslated: false
  },
  "Camera Sensor Test": {
    text: "Kamera sensör testi",
    isAlreadyTranslated: false
  },
  Gyro: {
    text: "Jiroskop",
    isAlreadyTranslated: false
  },
  "Gyro Sensor Test": {
    text: "Jiroskop Sensör Testi",
    isAlreadyTranslated: false
  },
  "Touch ID": {
    text: "Touch ID",
    isAlreadyTranslated: false
  },
  "Presence Test": {
    text: "Varlık Testi",
    isAlreadyTranslated: false
  },
  "Wi-Fi": {
    text: "Wi-Fi",
    isAlreadyTranslated: false
  },
  Software: {
    text: "Yazılım",
    isAlreadyTranslated: false
  },
  "Version Test": {
    text: "Sürüm Testi",
    isAlreadyTranslated: false
  },
  System: {
    text: "sistem",
    isAlreadyTranslated: false
  },
  "Usage Profile": {
    text: "Kullanım profili",
    isAlreadyTranslated: false
  },
  Battery: {
    text: "Pil",
    isAlreadyTranslated: false
  },
  "Health Check": {
    text: "Sağlık kontrolü",
    isAlreadyTranslated: false
  },
  "PASSED : Testing completed and no issues were found on this device.": {
    text: "BAŞARLI: Test tamamlandı ve bu cihazda hiçbir sorun bulunmadı.",
    isAlreadyTranslated: false
  },
  "App Crash History": {
    text: "Uygulama Çökme Geçmişi",
    isAlreadyTranslated: false
  },
  "Unexpected Shutdown History": {
    text: "Beklenmeyen Kapanma Geçmişi",
    isAlreadyTranslated: false
  },
  "Power Button": {
    text: "Güç düğmesi",
    isAlreadyTranslated: false
  },
  "Home Button": {
    text: "Ana ekran tuşu",
    isAlreadyTranslated: false
  },
  "Volume Down Button": {
    text: "Ses Kısma Düğmesi",
    isAlreadyTranslated: false
  },
  "LCD Screen": {
    text: "LCD ekran",
    isAlreadyTranslated: false
  },
  "Ear Piece": {
    text: "Kulaklık parçası",
    isAlreadyTranslated: false
  },
  /*Sensors: {
    text: "Sensörler",
    isAlreadyTranslated: false
  },*/
  "Back Camera": {
    text: "Arka kamera",
    isAlreadyTranslated: false
  },
  "Network Connection": {
    text: "Ağ bağlantısı",
    isAlreadyTranslated: false
  },

  "IMEI Check": {
    text: "IMEI Kontrolü",
    isAlreadyTranslated: false
  },
  "Incoming call": {
    text: "Gelen çağrı",
    isAlreadyTranslated: false
  },

  Microphone: {
    text: "Mikrofon",
    isAlreadyTranslated: false
  },
  "USB Connectivity": {
    text: "USB Bağlantısı",
    isAlreadyTranslated: false
  },
  "Battery Storage": {
    text: "Pil depolama",
    isAlreadyTranslated: false
  },
  "LED Flash": {
    text: "LED ışık",
    isAlreadyTranslated: false
  },
  "TAB Button": {
    text: "Sekme Düğmesi",
    isAlreadyTranslated: false
  },
  "Unlock Button": {
    text: "Kilit Açma Düğmesi",
    isAlreadyTranslated: false
  },
  "Full Diagnosis": {
    text: "Tam tanı",
    isAlreadyTranslated: false
  },
  "Proper Assembly": {
    text: "Uygun montaj",
    isAlreadyTranslated: false
  },
  "Back Button": {
    text: "Geri butonu",
    isAlreadyTranslated: false
  },
  "Volume Up Button": {
    text: "Ses Açma Düğmesi",
    isAlreadyTranslated: false
  },
  "Dead Spots": {
    text: "Ölü pikseller",
    isAlreadyTranslated: false
  },
  "Charging Port": {
    text: "Şarj bağlantı noktası",
    isAlreadyTranslated: false
  },
  "Loud Speaker": {
    text: "Hoparlör",
    isAlreadyTranslated: false
  },
  "Front Camera": {
    text: "Ön kamera",
    isAlreadyTranslated: false
  },

  "Outgoing call": {
    text: "Giden çağrı",
    isAlreadyTranslated: false
  },
  "Software Version": {
    text: "Yazılım versiyonu",
    isAlreadyTranslated: false
  },
  "GSX Confirmation Number": {
    text: "GSX Onarım Numarası",
    isAlreadyTranslated: false
  },
  Services: {
    text: "Hizmetler",
    isAlreadyTranslated: false
  },
  "Check If Device Box IMEI Matches With Device IMEI": {
    text: "Cihazın IMEI numarası Kutusundaki IMEI ile Eşleşiyor Mu ?",
    isAlreadyTranslated: false
  },
  "Check If Device IMEI Matches With Internal IMEI": {
    text: "Dahili IMEI numarası ile Cihazdaki IMEI numarası ile Eşleşiyor Mu ?",
    isAlreadyTranslated: false
  },
  "Check If Touch/Display Is Broken": {
    text: "Dokunmatik/Ekran Kırık Mı ?",
    isAlreadyTranslated: false
  },
  "Check If SIM Tray Is Missing Or Broken": {
    text: "SIM Kızağı Eksik veya Kırık Mı ?",
    isAlreadyTranslated: false
  },
  "Check If Back Cover Is Damaged": {
    text: "Arka Kasa Hasarlı Mı ?",
    isAlreadyTranslated: false
  },
  "Check If Screw Heads Are Damaged": {
    text: "Güvenlik Vida Hasarlı Mı ?",
    isAlreadyTranslated: false
  },
  "Check If There Are Dents Or Internal Cracks In Display": {
    text: "Ekranda İç veya Dış Çatlaklar Var Mı ?",
    isAlreadyTranslated: false
  },
  "Check If Volume Keys Are Loose Or Not Functioning": {
    text: "Ses Seviyesi Tuşları Gevşek veya Çalışmıyor Mu ?",
    isAlreadyTranslated: false
  },
  "Check If Power Keys Are Loose Or Not Functioning": {
    text: "Güç Düğmesi Gevşek veya Çalışmıyor Mu ?",
    isAlreadyTranslated: false
  },
  "Check If Camera's Auto Focus Is Not Working": {
    text: "Kameranın Otomatik Odaklama Fonksiyonu Çalışmıyor Mu ?",
    isAlreadyTranslated: false
  },
  "Check If Photos Clicked Are Blurred": {
    text: "Fotoğrafları Bulanık Mı Çekiyor ?",
    isAlreadyTranslated: false
  },
  "Check If USB Port Is Damaged": {
    text: "Şarj Girişi Hasarlı Mı ?",
    isAlreadyTranslated: false
  },
  "Check If Accessories Are As Mentioned On The Sales Pack": {
    text: "Aksesuarlar Tam Mı ?",
    isAlreadyTranslated: false
  },
  "Check If There Are Functional Defects": {
    text: "İşlevsel sorunları Var Mı ?",
    isAlreadyTranslated: false
  },
  "Ear Pods": {
    text: "Ear Pods",
    isAlreadyTranslated: false
  },
  "Power Adapter": {
    text: "Güç adaptörü",
    isAlreadyTranslated: false
  },
  "Lightning to USB Cable": {
    text: "USB to Lightning Kablo",
    isAlreadyTranslated: false
  },
  "Display Damaged": {
    text: "Ekran Hasarlı",
    isAlreadyTranslated: false
  },
  "Battery Damaged": {
    text: "Batarya Hasarlı",
    isAlreadyTranslated: false
  },
  "Backup Required": {
    text: "Alınan Yedekleme Gerekli",
    isAlreadyTranslated: false
  },
  "Find my iPhone Active": {
    text: "iPhone'umu Bul Aktif",
    isAlreadyTranslated: false
  },
  "Unlock.": {
    text: "Kilidi aç.",
    isAlreadyTranslated: false
  },
  "Functional Defects": {
    text: "Fonksiyonel Sorunlar",
    isAlreadyTranslated: false
  },
  "Accessories as per Content printed on sales pack": {
    text: "Satış paketindeki aksesuarlar",
    isAlreadyTranslated: false
  },
  "USB Port Damaged": {
    text: "USB Giriş Noktası Hasarlı",
    isAlreadyTranslated: false
  },
  "Camera AF & Blurr Issues": {
    text: "Kamera Odaklama ve Görüntü Kalietesi Problemleri",
    isAlreadyTranslated: false
  },
  "Power/Volume Keys Loose or Not Functioning": {
    text: "Güç/Ses Düğmeleri Gevşek veya Çalışmıyor",
    isAlreadyTranslated: false
  },
  "Any Dents or Internal Crack in display": {
    text: "Ekranda İç veya Dış Çatlaklar",
    isAlreadyTranslated: false
  },
  "Screw heads Damaged": {
    text: "Güvenlik Vida kafaları hasarlı",
    isAlreadyTranslated: false
  },
  "Back Cover Damaged": {
    text: "Arka Kasa Hasarlı",
    isAlreadyTranslated: false
  },
  "SIM Tray Missing or Broken": {
    text: "SIM Kızağı Eksik veya Kırık",
    isAlreadyTranslated: false
  },
  "Touch/LCD Broken": {
    text: "Dokunmatik/LCD Ekran Kırık",
    isAlreadyTranslated: false
  },
  "Unit IMEI Vs Internal IMEI": {
    text: "Cihaz IMEI ve Dahili IMEI",
    isAlreadyTranslated: false
  },
  "Box IMEI Vs Unit IMEI": {
    text: "Kutu IMEI ve Cihaz IMEI",
    isAlreadyTranslated: false
  },

  "Please enter alphabets or numbers only": {
    text: "Lütfen sadece harf veya sayı gir",
    isAlreadyTranslated: false
  },
  "Please enter numbers only": {
    text: "Sadece sayı giriniz",
    isAlreadyTranslated: false
  },
  "Consumer Email": {
    text: "Tüketici E-postası",
    isAlreadyTranslated: false
  },
  "IMEI Number 1": {
    text: "IMEI Numarası 1",
    isAlreadyTranslated: false
  },
  "IMEI Number 2": {
    text: "IMEI Numarası 2",
    isAlreadyTranslated: false
  },
  "Reassign Service": {
    text: "Servis yerini yeniden Atama",
    isAlreadyTranslated: false
  },
  "Select a service location from the drop down": {
    text: "Açılır menüden bir servis lokasyonu seçin",
    isAlreadyTranslated: false
  },
  "The same service center and same engineer is already assigned.": {
    text: "Aynı servis merkezi ve aynı teknisyen zaten atanmıştır.",
    isAlreadyTranslated: false
  },
  "Service center and engineer has been reassigned.": {
    text: "Servis merkezi ve teknisyen yeniden atandı.",
    isAlreadyTranslated: false
  },
  "The same service center is already assigned.": {
    text: "Aynı servis merkezi zaten atanmış.",
    isAlreadyTranslated: false
  },
  "Service center has been reassigned.": {
    text: "Servis merkezi yeniden atanmıştır.",
    isAlreadyTranslated: false
  },
  "First Dialer Dispostion": {
    text: "İlk Çevirici Yerleşimi",
    isAlreadyTranslated: false
  },
  "Second Dialer Dispostion": {
    text: "İkinci Çevirici Yerleşimi",
    isAlreadyTranslated: false
  },
  "Reschedule Service": {
    text: "Yeniden Randevu Planlama Hizmeti",
    isAlreadyTranslated: false
  },
  "No slots Available": {
    text: "Boş alan yok",
    isAlreadyTranslated: false
  },
  "Reason for reschedule": {
    text: "Yeniden randevu oluşturma nedeni",
    isAlreadyTranslated: false
  },
  "Are you sure you want to reschedule?": {
    text: "Yeniden randevu oluşturmak istediğinizden emin misiniz?",
    isAlreadyTranslated: false
  },
  "The request was rescheduled successfully": {
    text: "Talep başarıyla yeniden planlandı",
    isAlreadyTranslated: false
  },
  "Please specify the reason for reschedule": {
    text: "Lütfen randevu güncelleme nedenini belirtiniz",
    isAlreadyTranslated: false
  },
  "Please select a slot for reschedule": {
    text: "Lütfen yeniden randevu oluşturmak için boş alanı seçin",
    isAlreadyTranslated: false
  },
  "Service Location": {
    text: "Servis yeri",
    isAlreadyTranslated: false
  },
  "Device Replacement Mode": {
    text: "Cihaz Değiştirme Modu",
    isAlreadyTranslated: false
  },
  "Generate DOA Certificate": {
    text: "DOA Belgesi Oluşturun",
    isAlreadyTranslated: false
  },
  "Get New Device": {
    text: "Yeni Cihaz Al",
    isAlreadyTranslated: false
  },
  "Choose Device": {
    text: "Cihaz Seç",
    isAlreadyTranslated: false
  },
  "Enter Information for the Device you are issuing": {
    text: "Verdiğiniz Cihaz için Bilgi Girin",
    isAlreadyTranslated: false
  },
  "Please enter correct IMEI 1": {
    text: "Lütfen doğru IMEI 1 numarası girin",
    isAlreadyTranslated: false
  },
  "Please enter correct IMEI 2": {
    text: "Lütfen doğru IMEI 2 numarası girin",
    isAlreadyTranslated: false
  },
  "Please enter correct Serial Number": {
    text: "Lütfen doğru seri numarasını giriniz",
    isAlreadyTranslated: false
  },
  "Action on Device": {
    text: "Cihazda Eylem",
    isAlreadyTranslated: false
  },
  "Status Date": {
    text: "Durum Tarihi",
    isAlreadyTranslated: false
  },
  "Symptoms reported by Engineer": {
    text: "Teknisyen tarafından bildirilen bulgular",
    isAlreadyTranslated: false
  },
  Symptoms: {
    text: "Bulgular",
    isAlreadyTranslated: false
  },
  "Add New Symptom Codes": {
    text: "Yeni Bulgu Kodları Ekleme",
    isAlreadyTranslated: false
  },
  "No symptoms added": {
    text: "Bulgu eklenmedi",
    isAlreadyTranslated: false
  },
  "Symptom already added by the engineer": {
    text: "Bulgu zaten teknisyen tarafından eklendi",
    isAlreadyTranslated: false
  },
  "Please validate all the issues": {
    text: "Lütfen tüm sorunları doğrulayın",
    isAlreadyTranslated: false
  },

  "Verification Attempts Details": {
    text: "Doğrulama Denemeleri Ayrıntıları",
    isAlreadyTranslated: false
  },
  "Number of Attempts Remaining": {
    text: "Kalan Deneme Sayısı",
    isAlreadyTranslated: false
  },
  "Value Used For Verification": {
    text: "Doğrulama için Kullanılan Değer",
    isAlreadyTranslated: false
  },
  Verified: {
    text: "Doğrulandı",
    isAlreadyTranslated: false
  },
  "Not Verified": {
    text: "Doğrulanmadı",
    isAlreadyTranslated: false
  },
  "Verification Not Attempted": {
    text: "Doğrulama Denenmedi",
    isAlreadyTranslated: false
  },
  "Request is being processed.": {
    text: "Talep işleniyor.",
    isAlreadyTranslated: false
  },
  "Hub Details": {
    text: "Hub Detayları",
    isAlreadyTranslated: false
  },
  "Logistic Partners": {
    text: "Lojistik Firmaları",
    isAlreadyTranslated: false
  },
  "Logistic Partner Name": {
    text: "Lojistik Firma Adı",
    isAlreadyTranslated: false
  },
  Recommended: {
    text: "Tavsiye edilen",
    isAlreadyTranslated: false
  },
  "Schedule Request": {
    text: "Zamanlama İsteği",
    isAlreadyTranslated: false
  },
  "Please select a Logistic Partner": {
    text: "Lütfen bir Lojistik Firması seçin",
    isAlreadyTranslated: false
  },
  "SMS Sent Successfully": {
    text: "SMS başarıyla gönderildi",
    isAlreadyTranslated: false
  },
  "Please select a Hub": {
    text: "Lütfen bir Hub seçin",
    isAlreadyTranslated: false
  },
  "Upload Document": {
    text: "Belge Yükle",
    isAlreadyTranslated: false
  },
  "Add Certificate": {
    text: "Sertifika ekle",
    isAlreadyTranslated: false
  },
  /*Cancel: {
    text: "İptal Edin",
    isAlreadyTranslated: false
  },*/
  "Vouchers generated successfully": {
    text: "Kuponlar başarıyla oluşturuldu",
    isAlreadyTranslated: false
  },
  "Document Type: Cheque": {
    text: "Belge Türü: Çek",
    isAlreadyTranslated: false
  },

  "Claim Amount": {
    text: "Talep Miktarı",
    isAlreadyTranslated: false
  },
  "Please enter Claim Amount:": {
    text: "Lütfen Talep Miktarı Girin:",
    isAlreadyTranslated: false
  },
  "Please enter Claim Amount before submitting": {
    text: "Lütfen göndermeden önce Talep Miktarı girin",
    isAlreadyTranslated: false
  },
  "Invoice Value": {
    text: "Fatura tutarı",
    isAlreadyTranslated: false
  },
  "Repair Cost": {
    text: "Tamir Ücreti",
    isAlreadyTranslated: false
  },

  "Mandatory Deductible": {
    text: "Zorunlu Muafiyet",
    isAlreadyTranslated: false
  },
  "BER Payment Amount": {
    text: "BER Ödeme Tutarı",
    isAlreadyTranslated: false
  },
  "Approve BER": {
    text: "BER'yi onaylayın",
    isAlreadyTranslated: false
  },
  "Please enter Override Amount before submitting": {
    text: "Lütfen göndermeden önce Onay Verilen Miktarı girin",
    isAlreadyTranslated: false
  },
  "Please mention the incident in not less than 100 characters on how, when & where the damage happened": {
    text:
      "Lütfen, olayın ne zaman ve nerede meydana geldiğiyle ilgili 100'den az karakter içeren metinle belirtin.",
    isAlreadyTranslated: false
  },
  "Enter Loaner Device Details": {
    text: "Ödünç Cihaz Ayrıntılarını Girin",
    isAlreadyTranslated: false
  },
  "Select IMEI Number": {
    text: "IMEI Numarası Seç",
    isAlreadyTranslated: false
  },
  "Receive Loaner Device": {
    text: "Ödünç Cihazı Al",
    isAlreadyTranslated: false
  },
  "Condition Of the Loaner Device": {
    text: "Ödünç Cihazının Durumu",
    isAlreadyTranslated: false
  },
  Good: {
    text: "İyi",
    isAlreadyTranslated: false
  },
  Bad: {
    text: "Kötü",
    isAlreadyTranslated: false
  },
  Ugly: {
    text: "Çirkin",
    isAlreadyTranslated: false
  },
  "Loaner Device Details added successfully": {
    text: "Ödünç Cihaz Ayrıntıları başarıyla eklendi",
    isAlreadyTranslated: false
  },
  "Please choose from the given list only.": {
    text: "Lütfen sadece verilen listeden seçim yapın.",
    isAlreadyTranslated: false
  },
  "Please tell us about the condition of the Loaner Device.": {
    text: "Lütfen bize Ödünç Cihaz durumunu bildirin.",
    isAlreadyTranslated: false
  },
  "Please write about the condition of the device.": {
    text: "Lütfen cihazın durumu hakkında yazınız.",
    isAlreadyTranslated: false
  },
  From: {
    text: "itibaren",
    isAlreadyTranslated: false
  },

  To: {
    text: "için",
    isAlreadyTranslated: false
  },
  "Consumer Mobile": {
    text: "Müşteri Mobil Numarası",
    isAlreadyTranslated: false
  },
  "Alternate No./Landline No.": {
    text: "Alternatif No. / Sabit No.",
    isAlreadyTranslated: false
  },
  "Customer Alternate Mobile No.": {
    text: "Müşteri Alternatif Mobil No",
    isAlreadyTranslated: false
  },
  "Save & Send SMS/Email": {
    text: "Kaydet ve  SMS / E-posta Gönder",
    isAlreadyTranslated: false
  },
  "consumer(s) are shown above.": {
    text: "Müşteri (ler) yukarıda gösterilmiştir.",
    isAlreadyTranslated: false
  },
  "Call logs": {
    text: "Çağrı kayıtları",
    isAlreadyTranslated: false
  },
  "customer@email.com": {
    text: "customer@email.com",
    isAlreadyTranslated: false
  },

  "IMEI / Serial No.": {
    text: "IMEI / Seri No.",
    isAlreadyTranslated: false
  },
  "Servify Diagnostic App": {
    text: "Servify Tanı Uygulaması",
    isAlreadyTranslated: false
  },
  "Serial No.": {
    text: "Seri numarası.",
    isAlreadyTranslated: false
  },
  "IMEI 2": {
    text: "IMEI 2",
    isAlreadyTranslated: false
  },
  "Referece ID": {
    text: "Referans Numarası",
    isAlreadyTranslated: false
  },
  "Claim Form": {
    text: "Talep Formu",
    isAlreadyTranslated: false
  },
  "Claim Form Servify": {
    text: "Servify Talep Formu",
    isAlreadyTranslated: false
  },
  "Authorization Letter": {
    text: "Yetki belgesi",
    isAlreadyTranslated: false
  },
  "PAN Card Front": {
    text: "PAN Kartı Önü",
    isAlreadyTranslated: false
  },
  "Aadhar Card Front": {
    text: "Aadhar Kartı Önü",
    isAlreadyTranslated: false
  },
  "Aadhar Card Back": {
    text: "Aadhar Kartı Geri",
    isAlreadyTranslated: false
  },
  "Other Documents": {
    text: "Diğer belgeler",
    isAlreadyTranslated: false
  },
  Cheque: {
    text: "Kontrol",
    isAlreadyTranslated: false
  },
  Video: {
    text: "Video",
    isAlreadyTranslated: false
  },
  "Device image": {
    text: "Cihaz görüntüsü",
    isAlreadyTranslated: false
  },
  "Destruction Form": {
    text: "İmha Formu",
    isAlreadyTranslated: false
  },
  "P-Slip": {
    text: "P kayma",
    isAlreadyTranslated: false
  },
  "Consumer Signature": {
    text: "Müşteri İmzası",
    isAlreadyTranslated: false
  },
  "Issue Image": {
    text: "Sorun Görseli",
    isAlreadyTranslated: false
  },
  "Diagnosis report": {
    text: "Tanı raporu",
    isAlreadyTranslated: false
  },
  "Delivery Note": {
    text: "Teslim notu",
    isAlreadyTranslated: false
  },
  "Product Damage Feedback Item": {
    text: "Ürün Hasar Geri Bildirimi Öğesi",
    isAlreadyTranslated: false
  },

  "Claim Form in": {
    text: "Talep Formu",
    isAlreadyTranslated: false
  },

  "IMEI No": {
    text: "IMEI Numrası",
    isAlreadyTranslated: false
  },
  IssueText: {
    text: "Sorun Metni",
    isAlreadyTranslated: false
  },
  IssueRemark: {
    text: "Sorun Başlaığı",
    isAlreadyTranslated: false
  },
  otherIssueText: {
    text: "Diğer Sorun Metni",
    isAlreadyTranslated: false
  },
  screen: {
    text: "ekran",
    isAlreadyTranslated: false
  },
  "Consumer To Service Centre": {
    text: "Müşteriden Servis Merkezine",
    isAlreadyTranslated: false
  },
  "Service Centre To Consumer": {
    text: "Servis Merkezinden Müşteriye",
    isAlreadyTranslated: false
  },
  On: {
    text: "tarih",
    isAlreadyTranslated: false
  },
  at: {
    text: "saat",
    isAlreadyTranslated: false
  },
  "IMEI/Serial": {
    text: "IMEI / Seri",
    isAlreadyTranslated: false
  },
  "Insufficient Data For Graph. Data": {
    text: "Grafik İçin Yetersiz Veri.",
    isAlreadyTranslated: false
  },
  is: {
    text: "olduğu",
    isAlreadyTranslated: false
  },
  "Not a valid user.": {
    text: "Geçerli bir kullanıcı değil.",
    isAlreadyTranslated: false
  },
  "Rejection Reasons and Expected Actions by APR - Summary": {
    text: "RedR'den İtiraz Nedenleri ve Beklenen Eylemler - Özet",
    isAlreadyTranslated: false
  },
  "Error Message on App": {
    text: "Uygulamada Hata Mesajı",
    isAlreadyTranslated: false
  },
  "Error Code": {
    text: "Hata kodu",
    isAlreadyTranslated: false
  },
  "Explanation of Error Code": {
    text: "Hata Kodunun Açıklaması",
    isAlreadyTranslated: false
  },
  "Expected Action from APR": {
    text: "APR'den Beklenen Eylem",
    isAlreadyTranslated: false
  },

  "Store Name & Location": {
    text: "Mağaza Adı ve Yeri",
    isAlreadyTranslated: false
  },
  "Download Activation Report": {
    text: "Aktivasyon Raporunu İndir",
    isAlreadyTranslated: false
  },
  "Filter Consumers": {
    text: "Müşterileri Filtrele",
    isAlreadyTranslated: false
  },
  "Order ID": {
    text: "Sipariş Numarası",
    isAlreadyTranslated: false
  },
  "Order Item ID": {
    text: "Sipariş Öğe Numarası",
    isAlreadyTranslated: false
  },
  "User Details": {
    text: "Kullanıcı detayları",
    isAlreadyTranslated: false
  },
  "Plan Purchased": {
    text: "Plan Satın Alındı",
    isAlreadyTranslated: false
  },
  "Activated On": {
    text: "Etkinleştirildi",
    isAlreadyTranslated: false
  },
  "Status Message": {
    text: "Durum mesajı",
    isAlreadyTranslated: false
  },

  "Error Code in": {
    text: "Hata Kodu",
    isAlreadyTranslated: false
  },
  "Something went wrong when trying to create a consumer. Please try again.": {
    text:"Müşteriyi oluşturmaya çalışırken bir şeyler ters gitti. Lütfen tekrar deneyin.",
    isAlreadyTranslated: false
  },
  "Consumer successfully added and SMS sent!": {
    text: "Müşteri başarıyla eklendi ve SMS gönderildi!",
    isAlreadyTranslated: false
  },
  "Please enter correct E-mail ID.": {
    text: "Lütfen doğru e-posta adresi giriniz.",
    isAlreadyTranslated: false
  },
  "Please enter correct Mobile Number.": {
    text: "Lütfen doğru Cep Numarasını giriniz.",
    isAlreadyTranslated: false
  },
  "Please enter Mobile Number.": {
    text: "Lütfen Cep Telefonu Numarası giriniz.",
    isAlreadyTranslated: false
  },
  "Please select a Device Type.": {
    text: "Lütfen bir Cihaz Türü seçin.",
    isAlreadyTranslated: false
  },
  "Please enter at least one issue.": {
    text: "Lütfen en az bir sorun girin.",
    isAlreadyTranslated: false
  },
  "Please enter Incident ID.": {
    text: "Lütfen Kimlik Numarası giriniz.",
    isAlreadyTranslated: false
  },
  "Please select a model from the dropdown.": {
    text: "Lütfen açılır listeden bir model seçin.",
    isAlreadyTranslated: false
  },
  "Please enter E-mail ID.": {
    text: "Lütfen e-posta adresi giriniz.",
    isAlreadyTranslated: false
  },
  "Please enter Customer's Name.": {
    text: "Lütfen Müşteri Adını giriniz.",
    isAlreadyTranslated: false
  },
  Doc: {
    text: "Belge",
    isAlreadyTranslated: false
  },
  "Allowed Repairs": {
    text: "İzin Verilen Onarımlar",
    isAlreadyTranslated: false
  },
  "Used Repairs": {
    text: "Eski Onarımlar",
    isAlreadyTranslated: false
  },
  "Tax Payable": {
    text: "Ödenecek vergi",
    isAlreadyTranslated: false
  },
  "View queued requestsat": {
    text: "Sıraya alınmış istekleri görüntüle",
    isAlreadyTranslated: false
  },
  "Generate Vouchers": {
    text: "Kuponlar oluştur",
    isAlreadyTranslated: false
  },
  "Customer's Address Details": {
    text: "Müşterinin Adres Detayları",
    isAlreadyTranslated: false
  },
  "View queued requests": {
    text: "Sıraya alınmış istekleri görüntüle",
    isAlreadyTranslated: false
  },

  "Engineer Notification": {
    text: "Teknisyen Bildirimi",
    isAlreadyTranslated: true
  },
  "Engineer Notifications": {
    text: "Teknisyen Bildirimi",
    isAlreadyTranslated: true
  },
  "Optin Requests": {
    text: "Tanıtım Talepleri",
    isAlreadyTranslated: true
  },
  "Plan Sales Report": {
    text: "Plan Satış Raporu",
    isAlreadyTranslated: true
  },
  "APR Plans": {
    text: "APR Planları",
    isAlreadyTranslated: true
  },
  "Engineer Login Report": {
    text: "Teknisyen Giriş Raporu",
    isAlreadyTranslated: true
  },
  "Service location Report": {
    text: "Servis yeri Raporu",
    isAlreadyTranslated: true
  },
  Mapping: {
    text: "Eşleme",
    isAlreadyTranslated: true
  },
  Reports: {
    text: "Rapor",
    isAlreadyTranslated: true
  },
  billing: {
    text: "Fatura",
    isAlreadyTranslated: true
  },
  "Engineer Tracking": {
    text: "Teknisyen Takibi",
    isAlreadyTranslated: true
  },
  "Service Requests": {
    text: "Servis Talepleri",
    isAlreadyTranslated: true
  },
  "Engineer Report": {
    text: "Teknisyen Raporu",
    isAlreadyTranslated: true
  },
  "Visit Report": {
    text: "Ziyaret Raporu",
    isAlreadyTranslated: true
  },
  Billing: {
    text: "Fatura",
    isAlreadyTranslated: true
  },
  "Upload document": {
    text: "Belge yükleyin",
    isAlreadyTranslated: true
  },
  "View Documents": {
    text: "Belgeleri Görüntüle",
    isAlreadyTranslated: true
  },
  "File Name": {
    text: "Dosya Adı",
    isAlreadyTranslated: true
  },
  "Uploaded By": {
    text: "Yükleyen",
    isAlreadyTranslated: true
  },
  "Uploaded Date & Time": {
    text: "Yüklenen Tarih ve Saat",
    isAlreadyTranslated: true
  },
  "Wall Mount Or 60 Inch Or Large?": {
    text: "Duvara Monte mi veya 60 inç veya büyük?",
    isAlreadyTranslated: true
  },
  "Logistics - From Service Center to Customer's Address to Service Center": {
    text:"Lojistik - Servis Merkezinden Müşterinin Adresine Ve Servis Merkezine",
    isAlreadyTranslated: true
  },
  Tier: {
    text: "Aşama",
    isAlreadyTranslated: true
  },
  "Expected Delivery": {
    text: "Beklenen Teslimat",
    isAlreadyTranslated: true
  },
  "Reason for Reschedule": {
    text: "Yeniden Zamanlama Nedeni",
    isAlreadyTranslated: true
  },
  "View Job Card": {
    text: "İş Kartını Görüntüle",
    isAlreadyTranslated: true
  },
  "Begin Journey": {
    text: "Yolculuğa Başla",
    isAlreadyTranslated: true
  },
  "Serviceman Reached": {
    text: "Yetkili Kişi Ulaştı",
    isAlreadyTranslated: true
  },
  "Initiate Exception": {
    text: "Özel Durum Başlat",
    isAlreadyTranslated: true
  },
  "Complete Refund": {
    text: "İade İşlemini Tamamlayın",
    isAlreadyTranslated: true
  },
  "View Exception": {
    text: "Özel Durumu Görüntüle",
    isAlreadyTranslated: true
  },
  "Visit Details": {
    text: "Ziyaret Detayları",
    isAlreadyTranslated: true
  },
  Tasks: {
    text: "Görevler",
    isAlreadyTranslated: true
  },
  "Diagnosis Report": {
    text: "Tanı Raporu",
    isAlreadyTranslated: true
  },
  "Please choose the product": {
    text: "Lütfen ürünü seçin",
    isAlreadyTranslated: true
  },
  "Billing Address": {
    text: "Fatura Adresi",
    isAlreadyTranslated: true
  },
  "Change Billing Address": {
    text: "Fatura Adresini Değiştirin",
    isAlreadyTranslated: true
  },
  "Device Received & Inspected": {
    text: "Cihaz Alındı ve İncelendi",
    isAlreadyTranslated: true
  },
  "Complete Inspection": {
    text: "İncelemeyi Tamamlayın",
    isAlreadyTranslated: true
  },
  "Complete Device handover": {
    text: "Cihaz teslimatını tamamlayın",
    isAlreadyTranslated: true
  },
  "Create shipment": {
    text: "Sevkiyat oluşturun",
    isAlreadyTranslated: true
  },
  "Waiting for the Refurbishment Hub Manager to receive device and complete mandatory checklist tasks.": {
    text:"Yenileme Hub Yöneticisi'nin cihaz almasını ve zorunlu denetim listesi görevlerini tamamlamasını bekliyorum.",
    isAlreadyTranslated: true
  },
  "No of Repairs Used": {
    text: "Kullanılan Onarım Yok",
    isAlreadyTranslated: true
  },
  "No of Repairs Allowed": {
    text: "İzin Verilen Onarım Yok",
    isAlreadyTranslated: true
  },
  "Customer Care notes": {
    text: "Müşteri Temsilcisi Notları",
    isAlreadyTranslated: true
  },
  "Call Type:": {
    text: "Çağrı Türü:",
    isAlreadyTranslated: true
  },
  "Call Disposition": {
    text: "Çağrı Nedeni",
    isAlreadyTranslated: true
  },
  "Enter Amount": {
    text: "Tutarı Girin",
    isAlreadyTranslated: true
  },
  Map: {
    text: "Harita",
    isAlreadyTranslated: true
  },
  Number: {
    text: "Numara",
    isAlreadyTranslated: true
  },
  "G Number": {
    text: "G Numarası",
    isAlreadyTranslated: true
  },
  "Turn off Find my iPhone": {
    text: "iPhone'umu Bul özelliğini kapatın",
    isAlreadyTranslated: true
  },
  "Add required documents in 'Request Documents' section below.": {
    text: "Gerekli belgeleri aşağıdaki 'Belgeleri Talep Et' bölümüne ekleyin.",
    isAlreadyTranslated: true
  },
  "Sub Category": {
    text: "Alt Kategori",
    isAlreadyTranslated: true
  },
  "Generate Estimate": {
    text: "Teklif Oluştur",
    isAlreadyTranslated: true
  },
  "Purchase Order No": {
    text: "Satınalma Siparişi Numarası",
    isAlreadyTranslated: true
  },
  "Enter Information for the parts you have received": {
    text: "Aldığınız parçalar için bilgileri girin",
    isAlreadyTranslated: true
  },
  "Enter comptia for DOA parts": {
    text: "DOA parçaları için Comptia Kodu girin",
    isAlreadyTranslated: true
  },

  "Waiting for device to be received.": {
    text: "Cihazın teslim alınması bekleniyor.",
    isAlreadyTranslated: true
  },
  "Repair Complete": {
    text: "Onarım Tamamlandı",
    isAlreadyTranslated: true
  },
  "Cannibalize Device": {
    text: "Cihazı Parçala",
    isAlreadyTranslated: true
  },
  test: {
    text: "Test",
    isAlreadyTranslated: true
  },
  "Part Charge": {
    text: "Parça Şarj",
    isAlreadyTranslated: true
  },
  "Approved for repair": {
    text: "Onarım için onaylandı",
    isAlreadyTranslated: true
  },
  "Waiting for store to issue required parts in 'Parts Requested' section below.   Cannot complete repair with parts pending.": {
    text:"Deponun aşağıdaki 'Talep Edilen Parçalar' bölümünde gerekli parçaları temin etmesi bekleniyor. Parça beklenirken, onarım tamamlanamaz.",
    isAlreadyTranslated: true
  },
  pending: {
    text: "Beklemede",
    isAlreadyTranslated: true
  },
  "Entered details do not match with selected serial number": {
    text: "Girilen ayrıntılar seçili seri numarasıyla eşleşmiyor",
    isAlreadyTranslated: true
  },
  "BER Accessories Received": {
    text: "BER Aksesuarları Alındı",
    isAlreadyTranslated: true
  },
  "BER Device Received": {
    text: "BER Cihazı Alındı",
    isAlreadyTranslated: true
  },
  "Logistics - From Service Center to Hub": {
    text: "Lojistik - Servis Merkezinden Hub'a",
    isAlreadyTranslated: true
  },
  "Initiate BER Payment": {
    text: "BER Ödemesi başlatın",
    isAlreadyTranslated: true
  },
  "BER Payment Complete": {
    text: "BER Ödeme Tamamlandı",
    isAlreadyTranslated: true
  },
  "Complete Process": {
    text: "İşlemi Tamamlayın",
    isAlreadyTranslated: true
  },
  "Show Count": {
    text: "Adet Göster",
    isAlreadyTranslated: true
  },
  "Escalation Type": {
    text: "Yükseltme Türü",
    isAlreadyTranslated: true
  },
  "Flag Requests": {
    text: "Talepleri İşaretle",
    isAlreadyTranslated: true
  },
  "Save this": {
    text: "Bunu kaydet",
    isAlreadyTranslated: true
  },
  "IMEI/Serial No": {
    text: "IMEI/Seri No",
    isAlreadyTranslated: true
  },
  "Hide Count": {
    text: "Adeti Gizle",
    isAlreadyTranslated: true
  },
  "Enter Activation Token": {
    text: "Aktivasyon Kodu Girin",
    isAlreadyTranslated: true
  },
  "Activation Token": {
    text: "Aktivasyon Tokeni",
    isAlreadyTranslated: true
  },
  "Download all reports": {
    text: "Tüm raporları indirin",
    isAlreadyTranslated: true
  },
  "BER Repair": {
    text: "BER onarımı",
    isAlreadyTranslated: true
  },
  "Exception raised": {
    text: "İstisna yükseltildi",
    isAlreadyTranslated: true
  },
  "Billing Details": {
    text: "Fatura Ayrıntıları",
    isAlreadyTranslated: true
  },
  Continue: {
    text: "Devam",
    isAlreadyTranslated: true
  },
  "Scan the QR code from Diagnosis App": {
    text: "Tanı Uygulamasından QR kodunu tarayın",
    isAlreadyTranslated: true
  },

  "Something went wrong. Your request may have timed out.": {
    text: "Bir şeyler ters gitti. İsteğiniz zaman aşımına uğramış olabilir.",
    isAlreadyTranslated: true
  },
  "Exclusive Brand is created successfully": {
    text: "Özel Marka başarılı bir şekilde oluşturuldu",
    isAlreadyTranslated: true
  },
  "Form no": {
    text: "Form no",
    isAlreadyTranslated: true
  },
  "File provided is not in required format": {
    text: "Sağlanan dosya gerekli biçimde değil",
    isAlreadyTranslated: true
  },
  "Invalid format": {
    text: "Geçersiz format",
    isAlreadyTranslated: true
  },
  "Invalid name for Product": {
    text: "Ürün için geçersiz ad",
    isAlreadyTranslated: true
  },
  "More than 2 fields supplied": {
    text: "2'den fazla alan sağlandı",
    isAlreadyTranslated: true
  },
  "Please upload only .csv file.": {
    text: "Lütfen sadece .csv dosyası yükleyin.",
    isAlreadyTranslated: true
  },
  "Cannot read file": {
    text: "Dosya okunamıyor",
    isAlreadyTranslated: true
  },
  "Invalid IMEI1 for Product": {
    text: "Ürün için Geçersiz IMEI1 numarası",
    isAlreadyTranslated: true
  },
  "Invalid IMEI2 for Product": {
    text: "Ürün için Geçersiz IMEI2",
    isAlreadyTranslated: true
  },
  "More than 9 fields supplied": {
    text: "9'dan fazla alan sağlandı",
    isAlreadyTranslated: true
  },
  "No file found": {
    text: "Dosya bulunamadı",
    isAlreadyTranslated: true
  },
  "Name of the Agent": {
    text: "Yanıtlayan Kişi",
    isAlreadyTranslated: true
  },
  "Please Select a Product": {
    text: "Lütfen Bir Ürün Seçiniz",
    isAlreadyTranslated: true
  },
  "Select product from dropdown": {
    text: "Açılır menüden ürün seçin",
    isAlreadyTranslated: true
  },
  "Select subcategory from dropdown": {
    text: "Açılır menüden alt kategori seçin",
    isAlreadyTranslated: true
  },
  "Select brand from dropdown": {
    text: "Açılır menüden markayı seçin",
    isAlreadyTranslated: true
  },
  "Coupon Code": {
    text: "Kupon Kodu",
    isAlreadyTranslated: true
  },
  "Start date of the coupon": {
    text: "Kupon başlangıç tarihi",
    isAlreadyTranslated: true
  },
  "End date of the coupon": {
    text: "Kupon bitiş tarihi",
    isAlreadyTranslated: true
  },
  "Coupon Type": {
    text: "Kupon Türü",
    isAlreadyTranslated: true
  },
  "Active coupon": {
    text: "Aktif kupon",
    isAlreadyTranslated: true
  },
  "Create Coupon": {
    text: "Kupon Oluştur",
    isAlreadyTranslated: true
  },
  "Applicable only after sign up": {
    text: "Yalnızca kaydolduktan sonra uygulanabilir",
    isAlreadyTranslated: true
  },
  "Incentive Type": {
    text: "Teşvik Türü",
    isAlreadyTranslated: true
  },
  "Select incentive type after selecting coupon type": {
    text: "Kupon türünü seçtikten sonra teşvik türünü seçin",
    isAlreadyTranslated: true
  },
  "No. Of Users": {
    text: "Kullanıcı Sayısı",
    isAlreadyTranslated: true
  },
  "Incentive Value": {
    text: "Teşvik Değeri",
    isAlreadyTranslated: true
  },
  "End date": {
    text: "Bitiş tarihi",
    isAlreadyTranslated: true
  },
  "Start date": {
    text: "Başlangıç tarihi",
    isAlreadyTranslated: true
  },
  "Min Amount": {
    text: "Minimum Miktar",
    isAlreadyTranslated: true
  },
  "Please fill out this field": {
    text: "Lütfen bu alanı doldurun",
    isAlreadyTranslated: true
  },
  "Please fill value greater than 0": {
    text: "Lütfen 0'dan büyük değeri giriniz",
    isAlreadyTranslated: true
  },
  "End Date cannot be less than start date": {
    text: "Bitiş Tarihi başlangıç tarihinden önce olamaz",
    isAlreadyTranslated: true
  },
  "Incentive value cannot exceed": {
    text: "Teşvik değeri aşılamaz",
    isAlreadyTranslated: true
  },
  "Please enter serial number": {
    text: "Lütfen seri numarasını girin",
    isAlreadyTranslated: true
  },
  "Please enter brand name": {
    text: "Lütfen marka girin",
    isAlreadyTranslated: true
  },
  "Please enter product name": {
    text: "Lütfen ürün adını girin",
    isAlreadyTranslated: true
  },
  "Customer Email": {
    text: "Müşteri E-postası",
    isAlreadyTranslated: true
  },
  "Initiate App Diagnostic": {
    text: "Uygulama Tanı'sını Başlat",
    isAlreadyTranslated: true
  },
  "Reliability Records": {
    text: "Güvenilirlik Kayıtları",
    isAlreadyTranslated: true
  },
  "Drivers list": {
    text: "Sürücüler listesi",
    isAlreadyTranslated: true
  },
  "User Feedback": {
    text: "Kullanıcı Geri Bildirimi",
    isAlreadyTranslated: true
  },
  "Perform Diagnosis": {
    text: "Tanıyı Gerçekleştirin",
    isAlreadyTranslated: true
  },
  "Service Completion Date": {
    text: "Servis Hizmeti Tamamlanış Tarihi",
    isAlreadyTranslated: true
  },
  "Something went wrong while fetching templates": {
    text: "Şablonları getirirken bir şey yanlış gitti",
    isAlreadyTranslated: true
  },
  Template: {
    text: "Şablon",
    isAlreadyTranslated: true
  },
  Subject: {
    text: "Konu",
    isAlreadyTranslated: true
  },
  "Apply a filter before sending an email": {
    text: "E-posta göndermeden önce filtre uygulayın",
    isAlreadyTranslated: true
  },
  "Select a predefined template": {
    text: "Önceden tanımlanmış bir şablon seçin",
    isAlreadyTranslated: true
  },

  "Something went wrong while fetching data for graph": {
    text: "Grafik için veri alırken bir sorun oluştu",
    isAlreadyTranslated: true
  },
  "Please enter a valid IMEI": {
    text: "Lütfen geçerli bir IMEI girin",
    isAlreadyTranslated: true
  },

  "Thank You For Choosing Servify": {
    text: "Servify'ı kullandığınız için teşekkür ederiz",
    isAlreadyTranslated: true
  },
  "Product Inward Date": {
    text: "Ürün İçe Doğru Tarih",
    isAlreadyTranslated: true
  },
  "Expected Delivery Date": {
    text: "Beklenen Teslimat Tarihi",
    isAlreadyTranslated: true
  },
  'Service Provider"s Address': {
    text: "Servis Sağlayıcı'nın Adresi",
    isAlreadyTranslated: true
  },
  "Problem Reported by Customer": {
    text: "Müşteri Tarafından Bildirilen Sorun",
    isAlreadyTranslated: true
  },
  "Note: This Equipment is accepted subject to terms and conditions mentioned overleaf.'": {
    text:"Not: Bu Ekipman, sayfa arkasında belirtilen hüküm ve koşullara tabi olarak kabul edilir.'",
    isAlreadyTranslated: true
  },
  "Handed to customer": {
    text: "Müşteriye teslim edildi",
    isAlreadyTranslated: true
  },
  "Upload Status Report": {
    text: "Yükleme Durum Raporu",
    isAlreadyTranslated: true
  },
  "Download File": {
    text: "Dosyayı İndir",
    isAlreadyTranslated: true
  },
  "Upload File": {
    text: "Dosya Yükle",
    isAlreadyTranslated: true
  },
  "Submit File": {
    text: "Dosya Gönder",
    isAlreadyTranslated: true
  },
  "Something went wrong. Please upload again": {
    text: "Bir şeyler ters gitti. Lütfen tekrar yükleyin",
    isAlreadyTranslated: true
  },
  "Invalid column headers. You can use sample file": {
    text: "Sütun başlığı geçersizdir. Örnek dosyayı kullanabilirsiniz",
    isAlreadyTranslated: true
  },
  "Please upload a valid file. You can use sample file": {
    text: "Lütfen geçerli bir dosya yükleyin. Örnek dosyayı kullanabilirsiniz",
    isAlreadyTranslated: true
  },
  "There are no requests to download": {
    text: "İndirilecek talep yok",
    isAlreadyTranslated: true
  },
  "Map Logistics Request": {
    text: "Harita Lojistik Talebi",
    isAlreadyTranslated: true
  },
  "Your logistics request has been successfully mapped to service request": {
    text: "Lojistik talebiniz, servis talebiniz ile başarıyla eşlendi",
    isAlreadyTranslated: true
  },
  "Pickup Name": {
    text: "Teslimat Adı",
    isAlreadyTranslated: true
  },
  "Pickup Date": {
    text: "Teslim Alma Tarihi",
    isAlreadyTranslated: true
  },
  "Pickup Time": {
    text: "Teslim Alma Süresi",
    isAlreadyTranslated: true
  },
  "Pickup Contact No": {
    text: "Teslim Alma İletişim No",
    isAlreadyTranslated: true
  },
  "Drop Name": {
    text: "Teslim Edilecek Kişinin Adı",
    isAlreadyTranslated: true
  },
  "Drop Address": {
    text: "Teslim Edilecek Adres",
    isAlreadyTranslated: true
  },
  "Drop Contact No": {
    text: "Teslim Edilecek Yerin İletişim Numarası",
    isAlreadyTranslated: true
  },
  "Product Price": {
    text: "Ürün Fiyatı",
    isAlreadyTranslated: true
  },
  "Brand Name": {
    text: "Marka",
    isAlreadyTranslated: true
  },
  "Mirror Test": {
    text: "Ayna Testi",
    isAlreadyTranslated: true
  },
  "Age of device:": {
    text: "Cihazın yaşı:",
    isAlreadyTranslated: true
  },
  "optin requests are shown above": {
    text: "optin istekleri yukarıda gösterilmiştir",
    isAlreadyTranslated: true
  },
  "This device is not eligible for DOA": {
    text: "Bu cihaz DOA için uygun değildir",
    isAlreadyTranslated: true
  },
  "This device is eligible for DOA": {
    text: "Bu cihaz DOA için uygundur",
    isAlreadyTranslated: true
  },
  "Do you want to return the defective parts to the customer?": {
    text: "Arızalı parçaları müşteriye iade etmek istiyor musunuz?",
    isAlreadyTranslated: true
  },
  "Please enter the remarks with at least 25 characters": {
    text: "Lütfen açıklamaları en az 25 karakterle ile belirtin",
    isAlreadyTranslated: true
  },
  "Please enter the remarks": {
    text: "Lütfen açıklama yazınız",
    isAlreadyTranslated: true
  },
  "Do you want to return the damaged parts to the customer": {
    text: "Hasarlı parçaları müşteriye iade etmek istiyor musunuz?",
    isAlreadyTranslated: true
  },
  "Enter Order ID": {
    text: "Sipariş Kimliğini Girin",
    isAlreadyTranslated: true
  },
  'Device warranty details could not be fetched from GSX currently. Click on "Continue" to proceed with selecting the device details or Click on "Retry" to re-attempt fetching warranty details': {
    text:'Cihazın garanti ayrıntıları şu anda GSX ten alınamadı. Cihaz ayrıntılarını seçmek için "Devam Et"e tıklayın veya garanti bilgilerini yeniden almak için "Yeniden Dene" ye tıklayın.',
    isAlreadyTranslated: true
  },
  "Exceptional Sales": {
    text: "İstisnai Satışlar",
    isAlreadyTranslated: true
  },
  "Device not eligible": {
    text: "Cihaz uygun değil",
    isAlreadyTranslated: true
  },
  "Apply a filter before sending a SMS": {
    text: "SMS göndermeden önce filtre uygulayın",
    isAlreadyTranslated: true
  },
  "Update New Phone's Serial Number": {
    text: "Yeni Telefonun Seri Numarasını Güncelleyin",
    isAlreadyTranslated: true
  },
  "Old Phone's IMEI / Serial Number": {
    text: "Eski Telefonun IMEI / Seri Numarası",
    isAlreadyTranslated: true
  },
  "Invite Service User": {
    text: "Servis kullanıcısını davet edin",
    isAlreadyTranslated: true
  },
  "Invite Retailer User": {
    text: "Bayi Kullanıcı'sını davet edin",
    isAlreadyTranslated: true
  },
  "No users found": {
    text: "Kullanıcı bulunamadı",
    isAlreadyTranslated: true
  },
  "Please fill in the reason for overriding": {
    text: "Lütfen elle onaylama nedenini doldurun",
    isAlreadyTranslated: true
  },
  "File size should not be greater than 10 MB": {
    text: "Dosya boyutu 10 MB'dan büyük olmamalıdır",
    isAlreadyTranslated: true
  },
  "Consumer Details is saved successfully": {
    text: "Müşteri Ayrıntıları başarılı bir şekilde kaydedildi",
    isAlreadyTranslated: true
  },
  "Select a Vendor": {
    text: "Sağlayıcı Seçin",
    isAlreadyTranslated: true
  },
  "Selected Vendor": {
    text: "Seçilen Sağlayıcı",
    isAlreadyTranslated: true
  },
  "Work Order ID": {
    text: "İş Emri Kimliği",
    isAlreadyTranslated: true
  },
  "Eligible for RVP": {
    text: "RVP için uygun",
    isAlreadyTranslated: true
  },
  "Make the exclusive brand visible to customers": {
    text: "Özel markayı müşteriler tarafından görünür hale getirin",
    isAlreadyTranslated: true
  },
  Reset: {
    text: "Sıfırla",
    isAlreadyTranslated: true
  },
  "First Name": {
    text: "Ad",
    isAlreadyTranslated: true
  },
  "Last Name": {
    text: "Soyadı",
    isAlreadyTranslated: true
  },
  "Alternate number": {
    text: "Alternatif numara",
    isAlreadyTranslated: true
  },
  "Order date": {
    text: "Sipariş tarihi",
    isAlreadyTranslated: true
  },
  "Address Line": {
    text: "Adres Satırı",
    isAlreadyTranslated: true
  },
  City: {
    text: "Şehir",
    isAlreadyTranslated: true
  },
  State: {
    text: "İlçe",
    isAlreadyTranslated: true
  },
  "APR Name": {
    text: "APR Adı",
    isAlreadyTranslated: true
  },
  "Apply Filters": {
    text: "Filtre Uygula",
    isAlreadyTranslated: true
  },
  "Reason For Reassignment": {
    text: "Yeniden Atanma Nedeni",
    isAlreadyTranslated: true
  },
  "Reason For Reassign": {
    text: "Yeniden Atama Nedeni",
    isAlreadyTranslated: true
  },
  "No data Found": {
    text: "Veri Bulunamadı",
    isAlreadyTranslated: true
  },
  "Total Credit Amount": {
    text: "Toplam Kredi Tutarı",
    isAlreadyTranslated: true
  },
  "Incentive/Penalty": {
    text: "Teşvik/Ceza",
    isAlreadyTranslated: true
  },
  "Taxable amount": {
    text: "Vergiye tabi tutar",
    isAlreadyTranslated: true
  },
  "Total Amount Payable": {
    text: "Ödenecek Toplam Tutar",
    isAlreadyTranslated: true
  },
  "Bulk Approve": {
    text: "Toplu Onay",
    isAlreadyTranslated: true
  },
  "Add additional Penalties": {
    text: "Ek Cezalar Ekleyin",
    isAlreadyTranslated: true
  },
  Description: {
    text: "Açıklama",
    isAlreadyTranslated: true
  },
  "Character Limit": {
    text: "Karakter Limiti",
    isAlreadyTranslated: true
  },
  "Pending Approval": {
    text: "Onay bekleniyor",
    isAlreadyTranslated: true
  },
  "Rejected Remark": {
    text: "Reddedilen Açıklama",
    isAlreadyTranslated: true
  },
  "SELECT PRODUCT": {
    text: "ÜRÜN SEÇİNİZ",
    isAlreadyTranslated: true
  },
  Upload: {
    text: "Yükleyin",
    isAlreadyTranslated: true
  },
  "Total Unregistered Products": {
    text: "Toplam Kayıtsız Ürünler",
    isAlreadyTranslated: true
  },
  "Change password": {
    text: "Parolayı değiştirin",
    isAlreadyTranslated: true
  },
  Password: {
    text: "Parola",
    isAlreadyTranslated: true
  },
  "Confirm Password": {
    text: "Parolayı Onaylayın",
    isAlreadyTranslated: true
  },
  "Choose another account": {
    text: "Başka bir hesap seçin",
    isAlreadyTranslated: true
  },
  Change: {
    text: "Değiştirin",
    isAlreadyTranslated: true
  },
  child: {
    text: "Çocuk",
    isAlreadyTranslated: true
  },
  "Plan Types": {
    text: "Plan Türleri",
    isAlreadyTranslated: true
  },
  "Plan Descriptions": {
    text: "Plan Açıklamaları",
    isAlreadyTranslated: true
  },
  "No Communication History": {
    text: "İletişim Geçmişi Yok",
    isAlreadyTranslated: true
  },
  "First Dialer Disposition": {
    text: "İlk Çevirici Disposition",
    isAlreadyTranslated: true
  },
  "Second Dialer Disposition": {
    text: "İkinci Çevirici Disposition",
    isAlreadyTranslated: true
  },
  "Enter First Name": {
    text: "Adınızı Yazınız",
    isAlreadyTranslated: true
  },
  "Enter correct First Name": {
    text: "Doğru adı girin",
    isAlreadyTranslated: true
  },
  "Enter correct Alternate Number": {
    text: "Doğru Alternatif Numarası giriniz",
    isAlreadyTranslated: true
  },
  "Enter OderID": {
    text: "OrderID girin",
    isAlreadyTranslated: true
  },
  "Enter correct OderID": {
    text: "Doğru OderID girin",
    isAlreadyTranslated: true
  },
  "Enter Order Date": {
    text: "Sipariş Tarihini Girin",
    isAlreadyTranslated: true
  },
  "Select a plan from the dropdow": {
    text: "Açılır menüden bir plan seçin",
    isAlreadyTranslated: true
  },
  "Enter correct City": {
    text: "Doğru Şehir girin",
    isAlreadyTranslated: true
  },
  "Enter correct State": {
    text: "Doğru İlçe girin",
    isAlreadyTranslated: true
  },
  "Consumer Details added successfully": {
    text: "Müşteri Ayrıntıları başarıyla eklendi",
    isAlreadyTranslated: true
  },
  "Are you sure you want to generate Invoice?": {
    text: "Fatura oluşturmak istediğinizden emin misiniz?",
    isAlreadyTranslated: true
  },
  "Are you sure you want to Approve all the jobs?": {
    text: "Tüm işleri onaylamak istediğine emin misin?",
    isAlreadyTranslated: true
  },
  "Passwords do not match": {
    text: "Parolalar eşleşmiyor",
    isAlreadyTranslated: true
  },
  "Please enter a password": {
    text: "Lütfen bir şifre girin",
    isAlreadyTranslated: true
  },
  "This fault code/ cause code has already been selected": {
    text: "Bu arıza/sorun kodu zaten seçildi",
    isAlreadyTranslated: true
  },
  "Please complete the QC checklist": {
    text: "Lütfen QC kontrol listesini doldurun",
    isAlreadyTranslated: true
  },
  "Please add mandatory Symptom Fault Action": {
    text: "Lütfen zorunlu olan Arıza İçin İşlemi ekleyin",
    isAlreadyTranslated: true
  },
  Branch: {
    text: "Şube",
    isAlreadyTranslated: true
  },
  Shop: {
    text: "Dükkan",
    isAlreadyTranslated: true
  },
  "Product Category": {
    text: "Ürün Kategorisi",
    isAlreadyTranslated: true
  },
  Quantity: {
    text: "Miktar",
    isAlreadyTranslated: true
  },
  "No records found": {
    text: "Kayıt bulunamadı",
    isAlreadyTranslated: true
  },
  "No Competition Marked": {
    text: "Rekabet İşaretli Değil",
    isAlreadyTranslated: true
  },
  "Filter Competition Sales": {
    text: "Filtre Yarışması Satışları",
    isAlreadyTranslated: true
  },
  "Download consumer details in csv format.": {
    text: "Müşteri bilgilerini csv formatında indirin.",
    isAlreadyTranslated: true
  },
  "No Consumers found for the selected date range.": {
    text: "Seçili tarih aralığı için Müşteri bulunamadı.",
    isAlreadyTranslated: true
  },
  Details: {
    text: "Detaylar",
    isAlreadyTranslated: true
  },
  "Create Request": {
    text: "Talep Oluşturun",
    isAlreadyTranslated: true
  },

  "Brand Description": {
    text: "Marka Tanımı",
    isAlreadyTranslated: true
  },
  Tab: {
    text: "Sekme",
    isAlreadyTranslated: true
  },
  "Consumer data": {
    text: "Tüketici verileri",
    isAlreadyTranslated: true
  },
  "No consumer data": {
    text: "Müşteri verisi yok",
    isAlreadyTranslated: true
  },
  "Something erroneous happened": {
    text: "Hata oluştu.",
    isAlreadyTranslated: true
  },
  "Consumer Rating": {
    text: "Müşteri Derecelendirmesi",
    isAlreadyTranslated: true
  },
  "Rate your experience with the customer": {
    text: "Müşteriyle deneyiminizi derecelendirin",
    isAlreadyTranslated: true
  },
  "Comments (Optional)": {
    text: "Yorumlar (İsteğe Bağlı)",
    isAlreadyTranslated: true
  },
  "Delivery Report": {
    text: "Teslimat Raporu",
    isAlreadyTranslated: true
  },
  "Authorized Service Centre": {
    text: "Yetkili Servis Merkezi",
    isAlreadyTranslated: true
  },
  "Device Inward Date & Time": {
    text: "Cihaz Giriş Tarihi ve Saati",
    isAlreadyTranslated: true
  },
  "Repair Closed Date & Time": {
    text: "Onarım Kapatılma Tarihi ve Saati",
    isAlreadyTranslated: true
  },
  "Device Collection Date & Time": {
    text: "Cihaz Toplama Tarihi ve Saati",
    isAlreadyTranslated: true
  },
  "Service Reference ID": {
    text: "Servis Referans Kimliği",
    isAlreadyTranslated: true
  },

  mode: {
    text: "Mod",
    isAlreadyTranslated: true
  },
  "Product Details": {
    text: "Ürün Detayları",
    isAlreadyTranslated: true
  },
  "Problems Reported by Customer": {
    text: "Müşteri Tarafından Bildirilen Sorunlar",
    isAlreadyTranslated: true
  },
  "Condition of Equipment": {
    text: "Ekipmanın Durumu",
    isAlreadyTranslated: true
  },
  "Inward Check": {
    text: "İç Kontrol",
    isAlreadyTranslated: true
  },
  "Outward Check": {
    text: "Dışa Dönük Kontrol",
    isAlreadyTranslated: true
  },
  "Accessories Received": {
    text: "Alınan Aksesuarlar",
    isAlreadyTranslated: true
  },
  "Repair Cost (for OOW cases)": {
    text: "Onarım Maliyeti (Garanti dışı durumlar için)",
    isAlreadyTranslated: true
  },
  "Part No.": {
    text: "Parça No.",
    isAlreadyTranslated: true
  },
  "Part Description": {
    text: "Parça Açıklaması",
    isAlreadyTranslated: true
  },
  "Damaged Part Serial No/ IMEI (if applicable)": {
    text: "Hasarlı Parça Seri No/ IMEI (varsa)",
    isAlreadyTranslated: true
  },
  "Replaced Part Serial No/ IMEI (if applicable)": {
    text: "Değiştirilen Parça Seri No/ IMEI (varsa)",
    isAlreadyTranslated: true
  },
  "Customer Feedback": {
    text: "Müşteri Geri Bildirimi",
    isAlreadyTranslated: true
  },

  "Neither Satisfied nor Dissatisfied": {
    text: "Ne Memnun ne de memnun değil",
    isAlreadyTranslated: true
  },
  "Somewhat Dissatisfied": {
    text: "Biraz memnun değil",
    isAlreadyTranslated: true
  },

  "Overall Satisfaction": {
    text: "Genel Memnuniyet",
    isAlreadyTranslated: true
  },
  "Technical Ability": {
    text: "Teknik Yetenek",
    isAlreadyTranslated: true
  },
  Professionalism: {
    text: "Profesyonellik",
    isAlreadyTranslated: true
  },
  "Response Time": {
    text: "Yanıt Süresi",
    isAlreadyTranslated: true
  },
  "Quality of Repair": {
    text: "Onarım Kalitesi",
    isAlreadyTranslated: true
  },
  "Timely Update": {
    text: "Zamanında Güncelleme",
    isAlreadyTranslated: true
  },
  "(I am satified with the performance of the Product and have received the Product and all its Accessories)": {
    text:"(Ürünün performansından memnunum, ürün ve tüm aksesuarları teslim aldım)",
    isAlreadyTranslated: true
  },
  "(For Repair Closure)": {
    text: "(Onarım kapatma için)",
    isAlreadyTranslated: true
  },
  Lifecycle: {
    text: "Yaşam döngüsü",
    isAlreadyTranslated: true
  },
  "No results to display": {
    text: "Görüntülenecek sonuç yok",
    isAlreadyTranslated: true
  },
  "No information found": {
    text: "Bilgi bulunamadı",
    isAlreadyTranslated: true
  },
  "Diagnosed On": {
    text: "Tanı Yapılma Tarihi",
    isAlreadyTranslated: true
  },
  "Select Time Period": {
    text: "Saat Dilimini Seçin",
    isAlreadyTranslated: true
  },
  "Coming Soon!": {
    text: "Çok yakında!",
    isAlreadyTranslated: true
  },
  "No Sufficient Data Available To Plot The Graph": {
    text: "Grafiği çizmek için yeterli veri yok",
    isAlreadyTranslated: true
  },
  "DIAGNOSIS CERTIFICATE": {
    text: "TANI SERTİFİKASI",
    isAlreadyTranslated: true
  },
  "Diagnosis Date": {
    text: "Tanı Tarihi",
    isAlreadyTranslated: true
  },
  "Sim Activation Date": {
    text: "Sim Aktivasyon Tarihi",
    isAlreadyTranslated: true
  },
  "Detailed Diagnostic Report": {
    text: "Ayrıntılı Tanı Raporu",
    isAlreadyTranslated: true
  },
  Summary: {
    text: "Özet",
    isAlreadyTranslated: true
  },
  "Tentative Result": {
    text: "Belirsiz/Geçici Sonuç",
    isAlreadyTranslated: true
  },
  "Component Name": {
    text: "Bileşen Adı",
    isAlreadyTranslated: true
  },
  "Observed Value": {
    text: "Gözlenen Değer",
    isAlreadyTranslated: true
  },
  Result: {
    text: "Sonuç",
    isAlreadyTranslated: true
  },
  "Reference Range": {
    text: "Referans Aralığı",
    isAlreadyTranslated: true
  },
  "Ready to dispatch": {
    text: "Gönderime Hazır",
    isAlreadyTranslated: true
  },
  Dispatched: {
    text: "Gönderildi",
    isAlreadyTranslated: true
  },
  "Tracking Number": {
    text: "Takip Numarası",
    isAlreadyTranslated: true
  },
  Box: {
    text: "Kutu",
    isAlreadyTranslated: true
  },
  Width: {
    text: "Genişlik",
    isAlreadyTranslated: true
  },
  Height: {
    text: "Yükseklik",
    isAlreadyTranslated: true
  },
  Weight: {
    text: "Ağırlık",
    isAlreadyTranslated: true
  },
  "Please enter Box Height.": {
    text: "Lütfen Kutu Yüksekliği'ni girin.",
    isAlreadyTranslated: true
  },
  "Please enter Box width.": {
    text: "Lütfen Kutu genişliğini girin.",
    isAlreadyTranslated: true
  },
  "Please enter Box Length.": {
    text: "Lütfen Kutu Uzunluğu'nu girin.",
    isAlreadyTranslated: true
  },
  "Please enter Box Weight.": {
    text: "Lütfen Kutu Ağırlığı'nı girin.",
    isAlreadyTranslated: true
  },
  Length: {
    text: "Uzunluk",
    isAlreadyTranslated: true
  },
  "Please enter tracking Number.": {
    text: "Lütfen takip numarasını girin.",
    isAlreadyTranslated: true
  },
  "Please enter Shipper Detail.": {
    text: "Lütfen gönderici detaylarını girin.",
    isAlreadyTranslated: true
  },
  '"Please Select & Click Branch Name from the Dropdown List': {
    text: '"Lütfen Açılır Listeden Şube Adını Seçin ve Tıklayın',
    isAlreadyTranslated: true
  },
  "As per invoice": {
    text: "Fatura başına",
    isAlreadyTranslated: true
  },
  "Problematic Driver": {
    text: "Sorunlu Sürücü",
    isAlreadyTranslated: true
  },
  "No Problematic drivers found": {
    text: "Sorunlu sürücü bulunamadı",
    isAlreadyTranslated: true
  },
  "Installed Drivers": {
    text: "Yüklü Sürücüler",
    isAlreadyTranslated: true
  },
  "No Installed drivers found": {
    text: "Yüklü sürücü bulunamadı",
    isAlreadyTranslated: true
  },
  "Type: cashback, discount or referral": {
    text: "Tür: nakit, indirim veya tavsiye",
    isAlreadyTranslated: true
  },
  "Save Coupon": {
    text: "Kupon kaydet",
    isAlreadyTranslated: true
  },
  "Handset Reset": {
    text: "Ahize Sıfırlama",
    isAlreadyTranslated: true
  },
  "Handset Details": {
    text: "Ahize Detayları",
    isAlreadyTranslated: true
  },
  "Service Location Name": {
    text: "Servis Konum Adı",
    isAlreadyTranslated: true
  },
  "Last Resetted On": {
    text: "Son Sıfırlanma Tarihi",
    isAlreadyTranslated: true
  },
  "No of Handsets Resets Made": {
    text: "Yapılan Handset Sıfırlama Sayısı",
    isAlreadyTranslated: true
  },
  "Handset reset has been done. Please inform the Engineer to login into his Technician App.": {
    text:"Ahize sıfırlama yapıldı. Lütfen teknisyene, Teknisyen Uygulaması'na giriş için bilgi verin.",
    isAlreadyTranslated: true
  },
  "Assigned/Finished jobs": {
    text: "Atanan/Bitirilen işler",
    isAlreadyTranslated: true
  },
  Productivity: {
    text: "Verimlilik",
    isAlreadyTranslated: true
  },
  "Current Job Details": {
    text: "Güncel İş Bilgileri",
    isAlreadyTranslated: true
  },
  "Next Job Details": {
    text: "Sonraki İş Detayları",
    isAlreadyTranslated: true
  },
  "No next job": {
    text: "Bir sonraki iş yok",
    isAlreadyTranslated: true
  },
  "No current job": {
    text: "Geçerli iş yok",
    isAlreadyTranslated: true
  },
  "No previous jobs": {
    text: "Önceki işler bulunmamaktadır",
    isAlreadyTranslated: true
  },
  "No record found": {
    text: "Kayıt bulunamadı",
    isAlreadyTranslated: true
  },
  "User Remarks": {
    text: "Kullanıcı Açıklamaları",
    isAlreadyTranslated: true
  },
  "Audience Selection": {
    text: "İzleyici Seçimi",
    isAlreadyTranslated: true
  },
  "Final Review": {
    text: "Son İnceleme",
    isAlreadyTranslated: true
  },
  "Potential Reach": {
    text: "Potansiyel Erişim",
    isAlreadyTranslated: true
  },
  Personnel: {
    text: "Personel",
    isAlreadyTranslated: true
  },
  "Select Advisor": {
    text: "Danışman Seçin",
    isAlreadyTranslated: true
  },
  "Notification Expiry Duration (in days)": {
    text: "Bildirimin Sona Erme Süresi (gün içerisinde)",
    isAlreadyTranslated: true
  },
  "Click here to upload file": {
    text: "Dosya yüklemek için buraya tıklayın",
    isAlreadyTranslated: true
  },
  "View uploaded file": {
    text: "Yüklenen dosyayı görüntüle",
    isAlreadyTranslated: true
  },
  "Notification Details": {
    text: "Bildirim Detayları",
    isAlreadyTranslated: true
  },
  Title: {
    text: "Başlık",
    isAlreadyTranslated: true
  },
  Message: {
    text: "Mesaj",
    isAlreadyTranslated: true
  },
  Attachment: {
    text: "Ek",
    isAlreadyTranslated: true
  },
  Link: {
    text: "Link",
    isAlreadyTranslated: true
  },
  "Link to attachment": {
    text: "Ek için link oluşturun",
    isAlreadyTranslated: true
  },
  Audience: {
    text: "Seyirci",
    isAlreadyTranslated: true
  },
  "All shops and states": {
    text: "Tüm mağazalar ve ilçeler",
    isAlreadyTranslated: true
  },
  "Roster Managment": {
    text: "Kadro Yönetimi",
    isAlreadyTranslated: true
  },
  "Sample Files Data Format": {
    text: "Örnek Dosyaların Veri Formatı",
    isAlreadyTranslated: true
  },
  "Supported Data Format": {
    text: "Desteklenen Veri Formatı",
    isAlreadyTranslated: true
  },
  "Issue Resolved": {
    text: "Sorun Çözüldü",
    isAlreadyTranslated: true
  },
  "Approve Exception": {
    text: "Özel Durumu Onayla",
    isAlreadyTranslated: true
  },
  "Raise Exception": {
    text: "Özel Durumu Yükselt",
    isAlreadyTranslated: true
  },
  /*Reason: {
    text: "Neden",
    isAlreadyTranslated: true
  },*/
  "Select SKU": {
    text: "SKU'yı seçin",
    isAlreadyTranslated: true
  },
  "Product Purchase Cost": {
    text: "Ürün Satın Alma Maliyeti",
    isAlreadyTranslated: true
  },
  "Refund Amount": {
    text: "İade Tutarı",
    isAlreadyTranslated: true
  },
  "Suggested amount is based on Depreciation Price": {
    text: "Önerilen tutar Amortisman Fiyatı'na dayanır",
    isAlreadyTranslated: true
  },
  "Upload Document Proof": {
    text: "Belgeli Kanıt Yükleyin",
    isAlreadyTranslated: true
  },
  "Uploaded Documents": {
    text: "Yüklenen Belgeler",
    isAlreadyTranslated: true
  },
  "Revert Changes": {
    text: "Değişiklikleri Geri Al",
    isAlreadyTranslated: true
  },
  Approve: {
    text: "Onayla",
    isAlreadyTranslated: true
  },
  "No Service Requests found for selected date range.": {
    text: "Seçili tarih aralığı için Servis Talepleri bulunamadı.",
    isAlreadyTranslated: true
  },

  "External Service Request Details": {
    text: "Dış Servis Hizmeti Talep Ayrıntıları",
    isAlreadyTranslated: true
  },
  "Service Center Details": {
    text: "Servis Merkezi Detayları",
    isAlreadyTranslated: true
  },
  "No such request exists": {
    text: "Böyle bir talep yok",
    isAlreadyTranslated: true
  },
  "Job Number": {
    text: "İş Numarası",
    isAlreadyTranslated: true
  },
  "Supplier Job Number": {
    text: "Sağlayıcı İş Numarası",
    isAlreadyTranslated: true
  },
  "Temporary Job Number": {
    text: "Geçici İş Numarası",
    isAlreadyTranslated: true
  },
  "Service Center Name": {
    text: "Servis Merkezi Adı",
    isAlreadyTranslated: true
  },
  "Service Center Code": {
    text: "Servis Merkezi Kodu",
    isAlreadyTranslated: true
  },
  "Service Center Phone": {
    text: "Servis Merkezi Telefonu",
    isAlreadyTranslated: true
  },
  "Service Center Email": {
    text: "Servis Merkezi E-postası",
    isAlreadyTranslated: true
  },
  "Show filtered data": {
    text: "Filtre uygulanmış verileri göster",
    isAlreadyTranslated: true
  },
  "Please select atleast one escalation": {
    text: "Lütfen en az bir yükseltme seçiniz",
    isAlreadyTranslated: true
  },
  "Reset password": {
    text: "Parolayı sıfırla",
    isAlreadyTranslated: true
  },
  "Forgot password": {
    text: "Şifreyi unuttum",
    isAlreadyTranslated: true
  },
  "Select a Reset Option": {
    text: "Sıfırlama Seçeneğini Seçin",
    isAlreadyTranslated: true
  },
  "LOCATION RESET": {
    text: "KONUM SıFıRLAMA",
    isAlreadyTranslated: true
  },
  "Handset reset has already been done. Please inform the corresponding": {
    text: "Ahize sıfırlama zaten yapıldı. Lütfen ilgili kişiyi bilgilendirin",
    isAlreadyTranslated: true
  },
  "to login Mobile COS.": {
    text: "Mobil COS'a giriş yapmak için.",
    isAlreadyTranslated: true
  },
  sell: {
    text: "Sat",
    isAlreadyTranslated: true
  },
  "Diagnosis attempted": {
    text: "Tanı yapıldı",
    isAlreadyTranslated: true
  },
  "Please select the at least one of the custom diagnosis options to initiate diagnosis": {
    text:"Tanıyı başlatmak için lütfen özel tanı seçeneklerinden en az birini seçiniz",
    isAlreadyTranslated: true
  },
  "Filter Products": {
    text: "Filtre Ürünleri",
    isAlreadyTranslated: true
  },
  "Upload Sales": {
    text: "Satış Yükle",
    isAlreadyTranslated: true
  },
  Inventory: {
    text: "Stok",
    isAlreadyTranslated: true
  },
  "Receipt Date": {
    text: "Makbuz Tarihi",
    isAlreadyTranslated: true
  },
  "Currently With": {
    text: "Şu anda birlikte",
    isAlreadyTranslated: true
  },
  Owner: {
    text: "Sahibi",
    isAlreadyTranslated: true
  },
  "Error in uploaded csv file at line number:": {
    text: "Yüklenen csv dosyasındaki hata satır numarası:",
    isAlreadyTranslated: true
  },
  "Error in following data at line number: 1": {
    text: "Satır numarasındaki aşağıdaki verilerde hata: 1",
    isAlreadyTranslated: true
  },
  "Uploaded file format is not supported": {
    text: "Yüklenen dosya biçimi desteklenmiyor",
    isAlreadyTranslated: true
  },
  "Receipt End Date": {
    text: "Makbuz Bitiş Tarihi",
    isAlreadyTranslated: true
  },
  "Receipt Start Date": {
    text: "Makbuz Başlangıç Tarihi",
    isAlreadyTranslated: true
  },
  "Sale Start Date": {
    text: "Satış Başlangıç Tarihi",
    isAlreadyTranslated: true
  },
  "Sale End Date": {
    text: "Satış Bitiş Tarihi",
    isAlreadyTranslated: true
  },
  'Parts Charges (sales taxes included)"': {
    text: 'Parça Ücretleri (satış vergileri dahil)"',
    isAlreadyTranslated: true
  },
  "Sub total of services": {
    text: "Hizmetlerin alt toplamı",
    isAlreadyTranslated: true
  },
  "Advance and Loyalty": {
    text: "Avans ve Sadakat",
    isAlreadyTranslated: true
  },
  "Servify Cash Redeemed": {
    text: "Servify Nakit İtfa",
    isAlreadyTranslated: true
  },
  Adjustment: {
    text: "Ayar",
    isAlreadyTranslated: true
  },
  "Coupon Discount": {
    text: "Kupon İndirimi",
    isAlreadyTranslated: true
  },
  "Issues Reported By Customer": {
    text: "Müşteri tarafından bildirilen sorunlar",
    isAlreadyTranslated: true
  },

  "Symptom Description": {
    text: "Semptom Açıklaması",
    isAlreadyTranslated: true
  },
  "Fault Description": {
    text: "Arıza Açıklaması",
    isAlreadyTranslated: true
  },
  "Action Description": {
    text: "Eylem Açıklaması",
    isAlreadyTranslated: true
  },
  "Total Payable Amount": {
    text: "Toplam Ödenecek Tutar",
    isAlreadyTranslated: true
  },
  "Spare Consumption": {
    text: "Yedek Tüketim",
    isAlreadyTranslated: true
  },
  "Spares Description": {
    text: "Yedek Parça Açıklaması",
    isAlreadyTranslated: true
  },
  Charges: {
    text: "Ücret",
    isAlreadyTranslated: true
  },
  total: {
    text: "Genel Toplam",
    isAlreadyTranslated: true
  },
  Total: {
    text: "Genel Toplam",
    isAlreadyTranslated: true
  },
  "Location Reset": {
    text: "Konum Sıfırlama",
    isAlreadyTranslated: true
  },
  "Shop Location Reset Details": {
    text: "Mağaza Konumu Sıfırlama Ayrıntıları",
    isAlreadyTranslated: true
  },
  "Last Location resetted on": {
    text: "Son Konum Sıfırlama Tarihi",
    isAlreadyTranslated: true
  },
  "No. of Resets Made": {
    text: "Yapılan Sıfırlama Sayısı",
    isAlreadyTranslated: true
  },
  "to exit and re-open the Mobile COS": {
    text: "çıkmak ve Mobil COS yeniden açmak için",
    isAlreadyTranslated: true
  },
  "Shop Location reset has already been done. Please inform the corresponding": {
    text:
      "Mağaza Konumu sıfırlama zaten yapıldı. Lütfen ilgili kişiyi bilgilendirin",
    isAlreadyTranslated: true
  },
  "Previous Locations Reset Details": {
    text: "Önceki Konumların Sıfırlama Ayrıntıları",
    isAlreadyTranslated: true
  },
  "Sign in": {
    text: "Oturum Aç",
    isAlreadyTranslated: true
  },
  "to continue.": {
    text: "devam edin.",
    isAlreadyTranslated: true
  },
  "Resend OTP": {
    text: "Tek Kullanımlık Şifre'yi Yeniden Gönderin",
    isAlreadyTranslated: true
  },
  Login: {
    text: "Oturum Açın",
    isAlreadyTranslated: true
  },
  "User Type": {
    text: "Kullanıcı Türü",
    isAlreadyTranslated: true
  },
  "Username/Email": {
    text: "Kullanıcı Adı/E-posta",
    isAlreadyTranslated: true
  },
  "View requests": {
    text: "Talepleri görüntüleyin",
    isAlreadyTranslated: true
  },
  "All fields are mandatory.": {
    text: "Tüm alanlar zorunludur.",
    isAlreadyTranslated: true
  },
  "Pickup Details": {
    text: "Teslim alım detayları",
    isAlreadyTranslated: true
  },
  "Drop Details": {
    text: "Teslim Ayrıntıları",
    isAlreadyTranslated: true
  },
  "Check Service Availability": {
    text: "Servis Hizmeti Kullanılabilirliğini Kontrol Edin",
    isAlreadyTranslated: true
  },
  "Invoice Date": {
    text: "Fatura Tarihi",
    isAlreadyTranslated: true
  },

  Flow: {
    text: "Akış",
    isAlreadyTranslated: true
  },
  Issue: {
    text: "Teslim Et",
    isAlreadyTranslated: true
  },
  "Reference Number": {
    text: "Referans Numarası",
    isAlreadyTranslated: true
  },
  Provider: {
    text: "Sağlayıcı",
    isAlreadyTranslated: true
  },
  "Create Shipment": {
    text: "Sevkiyat Oluşturun",
    isAlreadyTranslated: true
  },
  "Logistics request is not raised. Please try again": {
    text: "Lojistik talebi yükseltilmedi Lütfen tekrar deneyin",
    isAlreadyTranslated: true
  },
  "IMEI has to be atleast 15 characters.": {
    text: "IMEI en az 15 karakter olmalıdır.",
    isAlreadyTranslated: true
  },
  "The request is already active with this IMEI/Serial No.": {
    text: "Bu IMEI/Seri No ile zaten bir talep etkindir.",
    isAlreadyTranslated: true
  },
  "Service Location Engineer Mapping": {
    text: "Servis Yeri Teknisyeni Haritalaması",
    isAlreadyTranslated: true
  },
  "Pincode Engineer Mapping": {
    text: "Posta Kodu Teknisyen Haritalama",
    isAlreadyTranslated: true
  },
  "Last set on": {
    text: "Son Ayarlanma Tarihi",
    isAlreadyTranslated: true
  },
  Variant: {
    text: "Kapasite & Renk",
    isAlreadyTranslated: true
  },
  "MOP for Trade-in": {
    text: "Takas için MOP",
    isAlreadyTranslated: true
  },
  Cashback: {
    text: "Nakit",
    isAlreadyTranslated: true
  },
  "Show Trade-In Amount": {
    text: "Takas Tutarını Göster",
    isAlreadyTranslated: true
  },
  "No records": {
    text: "Kayıt bulunamadı",
    isAlreadyTranslated: true
  },
  "Battery Conditions": {
    text: "Pil Koşulları",
    isAlreadyTranslated: true
  },
  "Body Conditions": {
    text: "Kasa Koşulları",
    isAlreadyTranslated: true
  },
  "Nothing to save": {
    text: "Kaydedilecek bir şey bulunamadı.",
    isAlreadyTranslated: true
  },
  "Push Notification": {
    text: "Bildirim Gönder",
    isAlreadyTranslated: true
  },
  "Is Warranty Expired?": {
    text: "Garanti Süresi Doldu mu?",
    isAlreadyTranslated: true
  },
  "No. of Service Requests": {
    text: "Servis Talepleri Sayısı",
    isAlreadyTranslated: true
  },
  "Please Close all the tasks": {
    text: "Lütfen Tüm görevleri tamamlayın",
    isAlreadyTranslated: true
  },
  "Please rate the service": {
    text: "Lütfen servis hizmetini derecelendirin",
    isAlreadyTranslated: true
  },
  Feedback: {
    text: "Geri Bildirim",
    isAlreadyTranslated: true
  },
  "Submit with Feedback": {
    text: "Geri Bildirimle Gönder",
    isAlreadyTranslated: true
  },
  "Please select a reason for cancelling service": {
    text: "Lütfen servis hizmetini iptal etmek için bir neden seçin",
    isAlreadyTranslated: true
  },
  "Close Visit": {
    text: "Ziyareti Kapat",
    isAlreadyTranslated: true
  },
  "Visit Disposition Status": {
    text: "Ziyaret Disposition Durumu",
    isAlreadyTranslated: true
  },
  "Request Status": {
    text: "Talep Durumu",
    isAlreadyTranslated: true
  },
  "Complete Visit": {
    text: "Ziyareti Tamamla",
    isAlreadyTranslated: true
  },
  "Submit IRIS": {
    text: "IRIS gönder",
    isAlreadyTranslated: true
  },
  "Select Symptom": {
    text: "Belirti/Semptom Seçin",
    isAlreadyTranslated: true
  },
  "Select Action": {
    text: "Eylem Seçin",
    isAlreadyTranslated: true
  },
  "Select Fault": {
    text: "Arıza Seçin",
    isAlreadyTranslated: true
  },
  Symptom: {
    text: "Belirti/Semptom",
    isAlreadyTranslated: true
  },
  Fault: {
    text: "Arıza",
    isAlreadyTranslated: true
  },

  "Complete Visit And Submit IRIS": {
    text: "IRIS'i ziyaret edin ve gönderin",
    isAlreadyTranslated: true
  },
  "Create Visit": {
    text: "Ziyaret Oluştur",
    isAlreadyTranslated: true
  },
  "Select an Engineer to assign": {
    text: "Atamak için bir teknisyen seçin",
    isAlreadyTranslated: true
  },
  "Reason For Follow Up": {
    text: "Takip Nedeni",
    isAlreadyTranslated: true
  },
  "Clone this request": {
    text: "Bu talebi klonla",
    isAlreadyTranslated: true
  },
  "No of requests to be cloned": {
    text: "Klonlanacak talep bulunmamaktadır",
    isAlreadyTranslated: true
  },
  Priority: {
    text: "Öncelik",
    isAlreadyTranslated: true
  },
  "Update Job": {
    text: "İşi Güncelle",
    isAlreadyTranslated: true
  },
  "Journey Details": {
    text: "Yolculuk Detayları",
    isAlreadyTranslated: true
  },
  "Enter date": {
    text: "Tarih girin",
    isAlreadyTranslated: true
  },
  "Begin time": {
    text: "Başlama zamanı",
    isAlreadyTranslated: true
  },
  "End time": {
    text: "Bitiş zamanı",
    isAlreadyTranslated: true
  },
  "Note : Time must be in between": {
    text: "Not : Tarih aralığı şu aralıkta olmalıdır :",
    isAlreadyTranslated: true
  },
  "Add Parts": {
    text: "Parça Ekle",
    isAlreadyTranslated: true
  },
  "Avl. Quantity": {
    text: "Mvct. Adet",
    isAlreadyTranslated: true
  },
  "Added Parts": {
    text: "Eklenen Parçalar",
    isAlreadyTranslated: true
  },
  Document: {
    text: "Belge",
    isAlreadyTranslated: true
  },
  "View Image": {
    text: "Resmi Görüntüle",
    isAlreadyTranslated: true
  },
  "No parts found for this product.": {
    text: "Bu ürün için parça bulunamadı.",
    isAlreadyTranslated: true
  },
  "Can't add more than one discount": {
    text: "Birden fazla indirim eklenemez",
    isAlreadyTranslated: true
  },
  "This IRIS has already been added.": {
    text: "Bu IRIS zaten eklenmiştir.",
    isAlreadyTranslated: true
  },
  "Please add a part from dropdown.": {
    text: "Lütfen açılır menüden bir parça ekleyin.",
    isAlreadyTranslated: true
  },
  "Part is already added": {
    text: "Parça zaten eklendi",
    isAlreadyTranslated: true
  },
  "Full box unit is already selected.": {
    text: "Tam kutu ünitesi zaten seçildi",
    isAlreadyTranslated: true
  },
  "Are you sure you want to create a visit?": {
    text: "Bir ziyaret oluşturmak istediğinizden emin misiniz?",
    isAlreadyTranslated: true
  },

  "Please select an engineer to assign": {
    text: "Lütfen atamak için bir teknisyen seçin",
    isAlreadyTranslated: true
  },
  "IRIS Codes has been updated successfully.": {
    text: "IRIS Kodları başarıyla güncelleştirildi.",
    isAlreadyTranslated: true
  },
  "The payment has been received and service will be completed.": {
    text: "Ödeme alındı ve servis hizmeti tamamlanacaktır.",
    isAlreadyTranslated: true
  },
  "Converted Requests": {
    text: "Dönüştürülmüş Talepler",
    isAlreadyTranslated: true
  },
  Identifiers: {
    text: "Tanımlayıcılar",
    isAlreadyTranslated: true
  },
  "Verify Device": {
    text: "Cihazı Doğrula",
    isAlreadyTranslated: true
  },
  "Review Order": {
    text: "Siparişi İncele",
    isAlreadyTranslated: true
  },
  "Order Summary": {
    text: "Sipariş Özeti",
    isAlreadyTranslated: true
  },
  "Congratulations! Your device is eligible for this plan": {
    text: "Tebrikler! Cihazınız bu plan için uygundur",
    isAlreadyTranslated: true
  },
  "This Device is Eligible for this Plan": {
    text: "Cihazınız bu plan için uygundur",
    isAlreadyTranslated: true
  },
  "Price of Plan": {
    text: "Sigorta Fiyatı",
    isAlreadyTranslated: true
  },
  "Transaction Details": {
    text: "İşlem Detayları",
    isAlreadyTranslated: true
  },
  "Device Images": {
    text: "Cihaz Görüntüleri",
    isAlreadyTranslated: true
  },
  "(All 6 Images are Mandatory)": {
    text: "(Tüm 6 Görüntü de Zorunludur)",
    isAlreadyTranslated: true
  },
  "Product Purchase Date": {
    text: "Ürün Satın Alma Tarihi",
    isAlreadyTranslated: true
  },
  "Product value": {
    text: "Ürün değeri",
    isAlreadyTranslated: true
  },
  "Plan Price": {
    text: "Sigorta Fiyatı",
    isAlreadyTranslated: true
  },
  "The user has agreed with the": {
    text: "Kullanıcı şunları kabul etti:",
    isAlreadyTranslated: true
  },
  "Terms and Conditions": {
    text: "Şartlar ve Koşullar",
    isAlreadyTranslated: true
  },
  "before purchasing this plan.": {
    text: "bu planı satın almadan önce.",
    isAlreadyTranslated: true
  },
  "Resend Link": {
    text: "Bağlantıyı Yeniden Gönder",
    isAlreadyTranslated: true
  },
  Refresh: {
    text: "Yenile",
    isAlreadyTranslated: true
  },
  "Resend Payment Link": {
    text: "Ödeme Linkini Yeniden Gönder",
    isAlreadyTranslated: true
  },
  Refund: {
    text: "Iade",
    isAlreadyTranslated: true
  },
  "Invoice Number": {
    text: "Fatura Numarası",
    isAlreadyTranslated: true
  },
  "Documents Uploaded": {
    text: "Yüklenen Belgeler",
    isAlreadyTranslated: true
  },
  "Transaction Summary": {
    text: "İşlem Özeti",
    isAlreadyTranslated: true
  },
  "Download": {
    text: "Indir",
    isAlreadyTranslated: true
  },
  "Proof of Coverage (POC)": {
    text: "Kapsam Kanıtı (POC)",
    isAlreadyTranslated: true
  },
  "Insurance Certificate": {
    text: "Sigorta Sertifikası",
    isAlreadyTranslated: true
  },
  "Print Invoice": {
    text: "Faturayı Yazdır",
    isAlreadyTranslated: true
  },
  "Cancel Plan": {
    text: "Planı İptal Et",
    isAlreadyTranslated: true
  },
  "Go to Home": {
    text: "Anasayfaya Git",
    isAlreadyTranslated: true
  },
  "Plan Cancelled": {
    text: "Plan İptal Edildi",
    isAlreadyTranslated: true
  },
  "Check Eligibility": {
    text: "Uygunluğu kontrol edin",
    isAlreadyTranslated: true
  },
  "Repair Date": {
    text: "Onarım Tarihi",
    isAlreadyTranslated: true
  },
  "Plan Purchase Date": {
    text: "Plan Satın Alma Tarihi",
    isAlreadyTranslated: true
  },
  "Sell Now": {
    text: "Şimdi Sat",
    isAlreadyTranslated: true
  },
  "Payment Link ReSent": {
    text: "Ödeme Linkini Tekrar Gönder",
    isAlreadyTranslated: true
  },
  "Payment Link Sent": {
    text: "Ödeme Linki Gönderildi",
    isAlreadyTranslated: true
  },
  "Link sent to": {
    text: "Gönderilen bağlantı",
    isAlreadyTranslated: true
  },
  "The Payment link is only valid for 24 hours.": {
    text: "Ödeme linki yalnızca 24 saat için geçerlidir.",
    isAlreadyTranslated: true
  },
  "Email POC to below email address": {
    text: "POC'yi aşağıdaki e-posta adresine, e-posta ile gönderin",
    isAlreadyTranslated: true
  },
  "Send a copy to below email address": {
    text: "Lütfen aşağıdaki e-posta adresine bir kopyasını gönderin",
    isAlreadyTranslated: true
  },
  "Are you sure you want to cancel the order?": {
    text: "Siparişi iptal etmek istediğinizden emin misiniz?",
    isAlreadyTranslated: true
  },
  "Select reason for cancellation": {
    text: "İptal nedenini seçin",
    isAlreadyTranslated: true
  },
  "Cancel Order": {
    text: "Siparişi İptal Et",
    isAlreadyTranslated: true
  },
  "Plan price data not found.": {
    text: "Plan ücreti verileri bulunamadı.",
    isAlreadyTranslated: true
  },
  "Discount Value cannot be greater than 100%": {
    text: "İndirim Değeri %100'den büyük olamaz",
    isAlreadyTranslated: true
  },
  "Discount Value cannot be greater than the Plan Price": {
    text: "İndirim Değeri, Plan Fiyatından büyük olamaz",
    isAlreadyTranslated: true
  },
  "Incorrect discount value": {
    text: "Geçersiz indirim değeri",
    isAlreadyTranslated: true
  },
  "The payment link has been sent to '": {
    text: "Ödeme Linki Buraya Gönderildi : '",
    isAlreadyTranslated: true
  },
  "You have successfully purchased": {
    text: "Başarılı bir şekilde satın aldınız",
    isAlreadyTranslated: true
  },
  "Your AppleCare Protection Plan proof of coverage document will be emailed to you in 24 hours.": {
    text:"AppleCare Koruma Planı kapsama belgesiniz 24 saat içinde size e-posta ile gönderilecektir.",
    isAlreadyTranslated: true
  },
  "Please enter correct email address": {
    text: "Lütfen doğru e-posta adresini girin",
    isAlreadyTranslated: true
  },
  "Enter Email Address of the user whose rights you want to": {
    text: "Haklarını almak istediğiniz kullanıcının e-posta adresini girin",
    isAlreadyTranslated: true
  },
  "Associated Partner": {
    text: "İlişkili Partner",
    isAlreadyTranslated: true
  },
  "Access given for": {
    text: "Şunun için erişim verildi :",
    isAlreadyTranslated: true
  },
  "Add Brands": {
    text: "Marka Ekle",
    isAlreadyTranslated: true
  },
  "CHOOSE BRAND": {
    text: "MARKA SEÇİN",
    isAlreadyTranslated: true
  },

  "No Other Requests": {
    text: "Başka Talep Bulunmamaktadır",
    isAlreadyTranslated: true
  },
  "No parts requests in this section.": {
    text: "Bu bölümde parça talebi bulunmamaktadır.",
    isAlreadyTranslated: true
  },
  "Charge Code": {
    text: "Şarj Kodu",
    isAlreadyTranslated: true
  },
  "AWB Number": {
    text: "AWB Numarası",
    isAlreadyTranslated: true
  },
  "Add more": {
    text: "Daha fazla Ekle",
    isAlreadyTranslated: true
  },
  "Vendor name": {
    text: "Sağlayıcı adı",
    isAlreadyTranslated: true
  },
  Instruction: {
    text: "Talimat",
    isAlreadyTranslated: true
  },
  "Logistics Service Type": {
    text: "Lojistik Hizmet Türü",
    isAlreadyTranslated: true
  },
  "SKU Requested": {
    text: "SKU İstenen",
    isAlreadyTranslated: true
  },
  Code: {
    text: "Kod",
    isAlreadyTranslated: true
  },

  "Defective Serial No": {
    text: "Arızalı Seri No",
    isAlreadyTranslated: true
  },
  "All parts consumed will be reverted and the Engineer has to handover these good parts to the Store Manager. Are you sure you want to proceed?'": {
    text:"Kullanılan tüm parçalar geri alınır ve teknisyen, bu yeni parçaları Depo Yöneticisi'ne teslim etmek zorundadır. Devam etmek istediğinizden emin misiniz?",
    isAlreadyTranslated: true
  },
  "Diagnostics Event Number": {
    text: "Tanı Etkinlik Numarası",
    isAlreadyTranslated: true
  },
  "Unable to validate details from GSX currently. Please try again after sometime.": {
    text:"Şu anda GSX'ten gelen ayrıntıları doğrulanamıyor. Lütfen bir süre sonra tekrar deneyin.",
    isAlreadyTranslated: true
  },

  "Please specify remarks": {
    text: "Lütfen açıklama belirtin",
    isAlreadyTranslated: true
  },
  Engineers: {
    text: "Teknisyen",
    isAlreadyTranslated: true
  },
  "Update Mapping": {
    text: "Haritalamayı Güncelle",
    isAlreadyTranslated: true
  },
  "mappings are shown above.": {
    text: "haritalamalar yukarıda gösterilmiştir.",
    isAlreadyTranslated: true
  },

  "Engineers Under TL": {
    text: "TL Kapsamındaki Teknisyenler",
    isAlreadyTranslated: true
  },
  "Plan Header": {
    text: "Plan Başlığı",
    isAlreadyTranslated: true
  },
  "Plan Benefits": {
    text: "Plan Avantajları",
    isAlreadyTranslated: true
  },
  "Plan Information": {
    text: "Plan Bilgileri",
    isAlreadyTranslated: true
  },
  "Plan updated successfully": {
    text: "Başarıyla başarılı bir şekilde güncellendi.",
    isAlreadyTranslated: true
  },
  "Tax is payable on Reverse Charge": {
    text: "Karşı taraflı ödemeler için vergi dahildir.",
    isAlreadyTranslated: true
  },
  "Invoice No": {
    text: "Fatura No",
    isAlreadyTranslated: true
  },
  "Details of Receiver (Billed to)": {
    text: "Alıcının Ayrıntıları (Faturalandırılacak)",
    isAlreadyTranslated: true
  },
  "Details of Consignee (Shipped to)": {
    text: "Konsinye Ayrıntıları (Sevk)",
    isAlreadyTranslated: true
  },
  "Description of Goods/Services": {
    text: "Mal/Hizmet Tanımı",
    isAlreadyTranslated: true
  },
  "Invoice Total(In Words)": {
    text: "Fatura Toplamı(Kelimelerle)",
    isAlreadyTranslated: true
  },
  "Invoice Total": {
    text: "Fatura Toplamı",
    isAlreadyTranslated: true
  },
  "Advance Received": {
    text: "Teslim Alınan Ödeme",
    isAlreadyTranslated: true
  },
  "Payable Value": {
    text: "Ödenecek Değer",
    isAlreadyTranslated: true
  },
  "We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.": {
    text:"Bu faturanın açıklanan malın gerçek fiyatını gösterdiğini ve tüm ayrıntıların doğru olduğunu beyan ediyoruz.",
    isAlreadyTranslated: true
  },
  "View Consumers": {
    text: "Müşterileri Görüntüle",
    isAlreadyTranslated: true
  },
  "Plan Details": {
    text: "Sigorta Detayları",
    isAlreadyTranslated: true
  },
  "Plan FAQ": {
    text: "Plan SSS",
    isAlreadyTranslated: true
  },
  "Sold Plans": {
    text: "Satılan Planlar",
    isAlreadyTranslated: true
  },
  "No sold plans found for the selected date range.": {
    text: "Seçili tarih aralığı için satılan plan bulunamadı.",
    isAlreadyTranslated: true
  },
  "Would you like to add a product?": {
    text: "Bir ürün eklemek ister misiniz?",
    isAlreadyTranslated: true
  },
  "Product is saved successfully": {
    text: "Ürün başarıyla kaydedildi",
    isAlreadyTranslated: true
  },
  "Apply a filter before sending push notification.": {
    text: "Bildirimi göndermeden önce bir filtre uygulayın.",
    isAlreadyTranslated: true
  },

  "Redeemed Coupons": {
    text: "Kullanılan Kuponlar",
    isAlreadyTranslated: true
  },
  "Start at your Home screen.": {
    text: "Anasayfadan başlayın.",
    isAlreadyTranslated: true
  },
  "Scroll to the bottom and tap Find My iPhone.": {
    text: "En alta gidin ve iPhone'umu Bul'a dokunun.",
    isAlreadyTranslated: true
  },
  "Slide to turn off Find My iPhone and Send Last Location.": {
    text:"iPhone'umu Bul'u ve Son Konumu Gönder seçeneğini kapatmak için kaydırın.",
    isAlreadyTranslated: true
  },
  "Servify has received your device and will be sending it to the eRecycling hub after verification.": {
    text:"Servify cihazınızı aldı ve doğrulamadan sonra Geri Dönüşüm Ağı'na gönderiyor olacaktır.",
    isAlreadyTranslated: true
  },
  "Servify has collected your device and will be sending it to the eRecycling hub after verification.": {
    text:"Servify cihazınızı teslim aldı ve doğrulamadan sonra Geri Dönüşüm Noktası'na gönderiyor olacak.",
    isAlreadyTranslated: true
  },
  "All the data from your device has been erased.": {
    text: "Cihazınızdaki tüm veriler silindi.",
    isAlreadyTranslated: true
  },
  "Please rate your eRecycling experience.": {
    text: "Lütfen Geri Dönüşüm deneyiminizi derecelendirin.",
    isAlreadyTranslated: true
  },
  "We have disassembled key components of your device and will be sending it to the eRecycling Hub soon.": {
    text:"Cihazınızın temel bileşenlerini demonte ettik ve yakında eGeri Dönüşüm Merkezi'ne göndereceğiz.",
    isAlreadyTranslated: true
  },

  "Date of Refund": {
    text: "İade Tarihi",
    isAlreadyTranslated: true
  },
  "Select Category for the appliance": {
    text: "Cihaz için Kategori seçin",
    isAlreadyTranslated: true
  },
  "Select Category": {
    text: "Kategori Seçin",
    isAlreadyTranslated: true
  },
  "Please select which applies.": {
    text: "Lütfen hangisinin geçerli olduğunu seçin.",
    isAlreadyTranslated: true
  },
  "Is Electrically OK": {
    text: "Elektriksel olarak tamam mı",
    isAlreadyTranslated: true
  },
  "Are Accessories Available": {
    text: "Aksesuarlar Mevcut mu",
    isAlreadyTranslated: true
  },
  "Dents/Scratches": {
    text: "Ezikler/Çizikler",
    isAlreadyTranslated: true
  },
  "Packing Box": {
    text: "Ambalaj Kutusu",
    isAlreadyTranslated: true
  },
  "Approve Refund Request": {
    text: "İade Talebini Onayla",
    isAlreadyTranslated: true
  },
  "Please enter Token Number.": {
    text: "Lütfen Token Numarasını girin.",
    isAlreadyTranslated: true
  },

  "Please select a Category from the dropdown": {
    text: "Lütfen açılır menüden bir Kategori seçin",
    isAlreadyTranslated: true
  },
  "Reject Refund Request": {
    text: "İade Talebini Reddet",
    isAlreadyTranslated: true
  },
  "Reason for rejection": {
    text: "Reddedilme nedeni",
    isAlreadyTranslated: true
  },
  "Reason for": {
    text: "Nedeni",
    isAlreadyTranslated: true
  },
  "Please enter Serial Number.": {
    text: "Lütfen Seri Numarasını girin.",
    isAlreadyTranslated: true
  },
  "Select a time slot": {
    text: "Bir zaman dilimi seçin",
    isAlreadyTranslated: true
  },
  "Add reason": {
    text: "Neden ekleyin",
    isAlreadyTranslated: true
  },
  "Please enter correct serial number": {
    text: "Lütfen doğru seri numarasını girin",
    isAlreadyTranslated: true
  },
  "Download Report": {
    text: "Raporu İndir",
    isAlreadyTranslated: true
  },
  "Filter Reports": {
    text: "Raporları Filtreleyin",
    isAlreadyTranslated: true
  },
  "Recent Requested Report": {
    text: "Güncel Talep Edilen Rapor",
    isAlreadyTranslated: true
  },
  "Report Type": {
    text: "Rapor Türü",
    isAlreadyTranslated: true
  },
  "Report requested on": {
    text: "İstenen rapor",
    isAlreadyTranslated: true
  },
  "Report Status": {
    text: "Rapor Durumu",
    isAlreadyTranslated: true
  },
  "Requested Reports": {
    text: "İstenen Raporlar",
    isAlreadyTranslated: true
  },
  "Logistics Vendor": {
    text: "Lojistik Sağlayıcısı",
    isAlreadyTranslated: true
  },
  "Organization Outlet Locations": {
    text: "Şirket Mağaza Konumları",
    isAlreadyTranslated: true
  },
  "Type of Report": {
    text: "Rapor Türü",
    isAlreadyTranslated: true
  },
  "Request Type": {
    text: "Talep Türü",
    isAlreadyTranslated: true
  },
  "No OLCSM found for selected Service Location": {
    text: "Seçili Servis Konumu için OLCSM bulunamadı",
    isAlreadyTranslated: true
  },
  "No Engineer found for selected Service Location'": {
    text: "Seçili Servis Konumu için Teknisyen Bulunamadı'",
    isAlreadyTranslated: true
  },
  "No partner service location details found for'": {
    text: "Partner servis lokasyonunun yer ayrıntıları bulunamadı'",
    isAlreadyTranslated: true
  },
  "No partner details found for": {
    text: "Partner ayrıntıları şunun için bulunamadı",
    isAlreadyTranslated: true
  },
  "Please select report type to download'": {
    text: "Lütfen indirmek için rapor türünü seçiniz'",
    isAlreadyTranslated: true
  },
  "Please select a vendor from Filter Reports'": {
    text: "Lütfen Rapor Filtreleme bölümünden bir Satıcı seçiniz",
    isAlreadyTranslated: true
  },
  "Please select a organization outlet location from Filter Reports'": {
    text: "Lütfen Rapor Filtreleme bölümünden bir Servis Outlet Konumu seçin",
    isAlreadyTranslated: true
  },
  "Please select a Service Location Engineer from Filter Reports'": {
    text: "Lütfen Rapor Filtreleme bölümünden bir Servis Teknisyeni seçiniz",
    isAlreadyTranslated: true
  },
  "Please select a Type of Report from Filter Reports": {
    text: "Lütfen Rapor Filtreleme bölümünden Rapor Türünü seçin",
    isAlreadyTranslated: true
  },
  "Please select a Product from Filter Reports'": {
    text: "Lütfen Rapor Filtreleme bölümünden bir Ürün seçiniz",
    isAlreadyTranslated: true
  },
  "Please select a Service Type from Filter Reports'": {
    text: "Lütfen Rapor Filtreleme bölümünden bir Servis Türü seçiniz",
    isAlreadyTranslated: true
  },
  "Please select a product category from Filter Reports'": {
    text: "Lütfen Rapor Filtreleme bölümünden bir Ürün Kategorisi seçin",
    isAlreadyTranslated: true
  },
  "Please select request type from Filter Reports'": {
    text: "Lütfen Rapor Filtreleme Talep Türü seçiniz",
    isAlreadyTranslated: true
  },
  '"Error in fetching Service Location IDs"': {
    text: '"Servis Yeri T.C.\'leri alma hatası"',
    isAlreadyTranslated: true
  },
  "REQUEST FORM": {
    text: "TALEP FORMU",
    isAlreadyTranslated: true
  },
  "Sales Invoice": {
    text: "Satış Faturası",
    isAlreadyTranslated: true
  },
  "EW claim no": {
    text: "EW iddiası hayır",
    isAlreadyTranslated: true
  },
  "Created By": {
    text: "Oluşturan",
    isAlreadyTranslated: true
  },
  "Google Location": {
    text: "Google Konumu",
    isAlreadyTranslated: true
  },
  "Upload invoice": {
    text: "Fatura Yükleyin",
    isAlreadyTranslated: true
  },
  "VIP/Urgent": {
    text: "VIP/ Acil",
    isAlreadyTranslated: true
  },
  "Is Service complimentary ?": {
    text: "Servis hizmeti ücretsiz midir?",
    isAlreadyTranslated: true
  },
  "Please enter correct Email ID": {
    text: "Lütfen geçerli bir E-Posta giriniz",
    isAlreadyTranslated: true
  },
  "Please enter correct Pincode": {
    text: "Lütfen doğru Posta Kodunu girin",
    isAlreadyTranslated: true
  },
  Availability: {
    text: "Kullanılabilirlik",
    isAlreadyTranslated: true
  },
  Skills: {
    text: "Beceriler",
    isAlreadyTranslated: true
  },
  "Shop details updated successfully.": {
    text: "Mağaza ayrıntıları başarıyla güncellendi.",
    isAlreadyTranslated: true
  },
  "New Shop added successfully.": {
    text: "New Shop başarıyla ekledi.",
    isAlreadyTranslated: true
  },
  "Save Details": {
    text: "Ayrıntıları Kaydet",
    isAlreadyTranslated: true
  },
  "Shop Name": {
    text: "Dükkan Adı",
    isAlreadyTranslated: true
  },
  "Shop Code": {
    text: "Mağaza Kodu",
    isAlreadyTranslated: true
  },
  "Carpet Area (in sq. feet)": {
    text: "Halı Alanı (metrekare)",
    isAlreadyTranslated: true
  },
  "Contact Number (10 digit)": {
    text: "İletişim Numarası (10 haneli)",
    isAlreadyTranslated: true
  },
  "Period (Max 31 Days)": {
    text: "Süre (Max 31 Gün)",
    isAlreadyTranslated: true
  },
  "Download list of requests": {
    text: "Talep listesini indirin",
    isAlreadyTranslated: true
  },
  "Upload list of requests": {
    text: "Talep listesini yükleyin",
    isAlreadyTranslated: true
  },
  "Sample format for uploading requests": {
    text: "Talepleri yüklemek için örnek format",
    isAlreadyTranslated: true
  },
  "There are no requests to be downloaded": {
    text: "İndirilecek talep yok",
    isAlreadyTranslated: true
  },
  "Requests updated successfully": {
    text: "Talepler başarıyla güncellendi",
    isAlreadyTranslated: true
  },
  "Cannot read this file": {
    text: "Dosya okunamıyor",
    isAlreadyTranslated: true
  },
  "Please upload file with appropriate data": {
    text: "Lütfen uygun verilerle dosya yükleyin",
    isAlreadyTranslated: true
  },
  "Cancel Changes": {
    text: "Değişiklikleri iptal et",
    isAlreadyTranslated: true
  },
  "Are you sure you want to cancel changes you have made?": {
    text: "Yaptığınız değişiklikleri iptal etmek istediğinizden emin misiniz?",
    isAlreadyTranslated: true
  },
  "Add Retail Location": {
    text: "Satış Mağazası Konumu Ekleyin",
    isAlreadyTranslated: true
  },
  "View Location": {
    text: "Konumu Görüntüle",
    isAlreadyTranslated: true
  },
  "Enter Mobile number of the": {
    text: "Şunun mobil numarasını girin :",
    isAlreadyTranslated: true
  },
  "whose rights you want to": {
    text: "kimin haklarını istiyorsunuz",
    isAlreadyTranslated: true
  },
  modify: {
    text: "düzenle",
    isAlreadyTranslated: true
  },
  "Associated Shop": {
    text: "İlişkili Mağaza",
    isAlreadyTranslated: true
  },
  "choose shop to assign": {
    text: "atamak için mağaza seçin",
    isAlreadyTranslated: true
  },
  "Branch Code": {
    text: "Şube Kodu",
    isAlreadyTranslated: true
  },
  "TOTAL SALES DETAILS": {
    text: "TOPLAM SATIŞ DETAYLARI",
    isAlreadyTranslated: true
  },
  "VERIFIED SALES DETAILS": {
    text: "DOĞRULANMIŞ SATIŞ DETAYLARI",
    isAlreadyTranslated: true
  },
  "UNVERIFIED SALES DETAILS": {
    text: "DOĞRULANMAMIŞ SATIŞ DETAYLARI",
    isAlreadyTranslated: true
  },
  "SHOP & ADVISOR DETAILS": {
    text: "ALIŞVERİŞ & DANIŞMAN DETAYLARI",
    isAlreadyTranslated: true
  },
  "Todays Attendance": {
    text: "Bugünün Katılımı",
    isAlreadyTranslated: true
  },
  "Device Model List": {
    text: "Cihaz Model Listesi",
    isAlreadyTranslated: true
  },
  "Select Plans": {
    text: "Planları seçin",
    isAlreadyTranslated: true
  },
  "No Plans Available": {
    text: "Kullanılabilir Plan Yok",
    isAlreadyTranslated: true
  },
  "Product details cannot be validated. Please choose from the device list.'": {
    text:"Ürün ayrıntıları doğrulanamıyor. Lütfen cihaz listesinden seçim yapın.'",
    isAlreadyTranslated: true
  },
  "Device is eligible for multiple plans. Please choose a plan.": {
    text: "Cihaz birden çok plan için uygundur. Lütfen bir plan seçin.",
    isAlreadyTranslated: true
  },
  "Device is eligible!": {
    text: "Cihaz uygundur!",
    isAlreadyTranslated: true
  },
  "Invoice No.": {
    text: "Fatura No",
    isAlreadyTranslated: true
  },
  "Invoice Amount": {
    text: "Fatura Tutarı",
    isAlreadyTranslated: true
  },
  "Sale Date": {
    text: "Satış tarihi",
    isAlreadyTranslated: true
  },
  Create: {
    text: "Oluştur",
    isAlreadyTranslated: true
  },
  Active: {
    text: "Aktif",
    isAlreadyTranslated: true
  },
  Inactive: {
    text: "İnaktif",
    isAlreadyTranslated: true
  },
  Pending: {
    text: "Beklemede",
    isAlreadyTranslated: true
  },
  "Please select a location": {
    text: "Lütfen bir konum seçin",
    isAlreadyTranslated: true
  },
  "Content ID": {
    text: "İçerik Kimliği",
    isAlreadyTranslated: true
  },
  "Last Modified": {
    text: "Son Değiştirilme Tarihi",
    isAlreadyTranslated: true
  },
  "You have no new requests.": {
    text: "Yeni bir talebiniz bulunmamaktadır.",
    isAlreadyTranslated: true
  },
  "Detail View": {
    text: "Ayrıntılı Görünüm",
    isAlreadyTranslated: true
  },
  "Complete Analysis": {
    text: "Analizi Tamamlayın",
    isAlreadyTranslated: true
  },
  "Root Cause Analysis.": {
    text: "Sorun Kaynağı Çözümlemesi.",
    isAlreadyTranslated: true
  },
  "Solution Provided.": {
    text: "Çözüm Sağlandı.",
    isAlreadyTranslated: true
  },
  "Accessories will not be returned.": {
    text: "Aksesuarlar iade edilemez.",
    isAlreadyTranslated: true
  },

  "Assign Engineer": {
    text: "Teknisyen Atayın",
    isAlreadyTranslated: true
  },
  "Update job details": {
    text: "İş ayrıntılarını güncelleyin",
    isAlreadyTranslated: true
  },
  "Update device details and then validate issues": {
    text: "Aygıt ayrıntılarını güncelleyin ve sorunları doğrulayın",
    isAlreadyTranslated: true
  },
  "Update device details": {
    text: "Cihaz ayrıntılarını güncelleyin",
    isAlreadyTranslated: true
  },
  "Receive payment & rate consumer": {
    text: "Ödeme alın ve müşteriyi değerlendirin",
    isAlreadyTranslated: true
  },
  "Update invoice details and then press confirm invoice": {
    text: "Fatura ayrıntılarını güncelleyin, ardından faturayı onayla'ya basın",
    isAlreadyTranslated: true
  },
  "Do you want to accept this request?": {
    text: "Bu talebi kabul etmek istiyor musunuz?",
    isAlreadyTranslated: true
  },
  "Visit Time": {
    text: "Ziyaret Süresi",
    isAlreadyTranslated: true
  },
  "Filter by": {
    text: "Filtreleyen",
    isAlreadyTranslated: true
  },
  "Partner User": {
    text: "Partner Kullanıcısı",
    isAlreadyTranslated: true
  },
  "Choose Partner User": {
    text: "Partner Kullanıcısı Seçin",
    isAlreadyTranslated: true
  },
  "Invalid email id entered": {
    text: "Girilen e-posta kimliği geçersiz",
    isAlreadyTranslated: true
  },
  "No symptoms found": {
    text: "Semptom bulunamadı",
    isAlreadyTranslated: true
  },
  "No faults found": {
    text: "Arıza bulunamadı",
    isAlreadyTranslated: true
  },
  "No actions found": {
    text: "Eylem bulunamadı",
    isAlreadyTranslated: true
  },
  Month: {
    text: "Ay",
    isAlreadyTranslated: true
  },
  "Target Amount": {
    text: "Hedef Tutar",
    isAlreadyTranslated: true
  },
  "Total Target": {
    text: "Toplam Hedef",
    isAlreadyTranslated: true
  },
  "Past targets cannot be edited.": {
    text: "Geçmiş hedefler düzenlenemez.",
    isAlreadyTranslated: true
  },

  "Already Closed": {
    text: "Zaten Kapalı",
    isAlreadyTranslated: true
  },
  "No tasks Available": {
    text: "Kullanılabilir görev yok",
    isAlreadyTranslated: true
  },
  "Close Task": {
    text: "Görevi Kapat/Tamamla",
    isAlreadyTranslated: true
  },
  "Task Reference Id": {
    text: "Görev Referans Kimliği",
    isAlreadyTranslated: true
  },
  "Edit Remark": {
    text: "Açıklamayı Düzeltin",
    isAlreadyTranslated: true
  },
  "Task Details of": {
    text: "Şu Görevin Detayları :",
    isAlreadyTranslated: true
  },

  "Updated Date": {
    text: "Güncellenme Tarihi",
    isAlreadyTranslated: true
  },
  "Closed Date": {
    text: "Kapalı Tarih",
    isAlreadyTranslated: true
  },
  "Temp Consumer data": {
    text: "Geçici Müşteri Verileri",
    isAlreadyTranslated: true
  },
  "No temp consumer data": {
    text: "Geçici müşteri verisi bulunamadı",
    isAlreadyTranslated: true
  },
  "Repair completed and Inform customer": {
    text: "Onarım tamamlandı, müşteriyi bilgilendirin",
    isAlreadyTranslated: true
  },
  "Validate IMEI": {
    text: "IMEI'yi doğrulayın",
    isAlreadyTranslated: true
  },

  "Invalid IMEI": {
    text: "Geçersiz IMEI",
    isAlreadyTranslated: true
  },
  "Last Diagnosis Report": {
    text: "Son Tanı Raporu",
    isAlreadyTranslated: true
  },
  "No diagnosis report": {
    text: "Tanı raporu yok",
    isAlreadyTranslated: true
  },

  "Validate Device": {
    text: "Cihazı Doğrula",
    isAlreadyTranslated: true
  },
  "Enter G Number": {
    text: "G Numarasını Girin",
    isAlreadyTranslated: true
  },
  "Please enter valid the G number": {
    text: "Lütfen Geçerli G numarası girin",
    isAlreadyTranslated: true
  },
  "No Such Brand": {
    text: "Böyle Bir Marka Yok",
    isAlreadyTranslated: true
  },
  "Selected Brands": {
    text: "Seçilen Markalar",
    isAlreadyTranslated: true
  },
  "Remaining Brands": {
    text: "Kalan Markalar",
    isAlreadyTranslated: true
  },
  "Selected Partner": {
    text: "Seçilen Partner",
    isAlreadyTranslated: true
  },
  "No Such Partner": {
    text: "Böyle Bir Partner Yok",
    isAlreadyTranslated: true
  },
  "CHOOSE PARTNER": {
    text: "ORTAK SEÇİN",
    isAlreadyTranslated: true
  },
  "Add Partner": {
    text: "Partner Ekleyin",
    isAlreadyTranslated: true
  },
  "Remaining Partners": {
    text: "Kalan Partnerler",
    isAlreadyTranslated: true
  },
  "Add Plan": {
    text: "Plan Ekle",
    isAlreadyTranslated: true
  },
  "CHOOSE PLAN": {
    text: "PLAN SEÇİN",
    isAlreadyTranslated: true
  },
  "No Such Plan": {
    text: "Böyle Bir Plan Bulunmamaktadır",
    isAlreadyTranslated: true
  },
  "Selected Plan": {
    text: "Seçilen Plan",
    isAlreadyTranslated: true
  },
  "Remaining Plan": {
    text: "Kalan Plan",
    isAlreadyTranslated: true
  },
  "CHOOSE PRODUCT": {
    text: "ÜRÜN SEÇİN",
    isAlreadyTranslated: true
  },
  "No Such Product Found": {
    text: "Böyle Bir Ürün Bulunamadı",
    isAlreadyTranslated: true
  },
  "Selected Products": {
    text: "Seçilen Ürünler",
    isAlreadyTranslated: true
  },
  "Remaining Products": {
    text: "Kalan Ürünler",
    isAlreadyTranslated: true
  },
  "Remaining Product Sub-Categories": {
    text: "Kalan Ürün Alt Kategorileri",
    isAlreadyTranslated: true
  },
  "Selected Product Sub-Categories": {
    text: "Seçili Ürün Alt Kategorileri",
    isAlreadyTranslated: true
  },
  "No Such Product Sub-Category": {
    text: "Böyle Bir Ürün Alt Kategorisi Yok",
    isAlreadyTranslated: true
  },
  "CHOOSE Product Sub-Category": {
    text: "SEÇ Ürün Alt Kategorisi",
    isAlreadyTranslated: true
  },
  "Add Product Sub Category": {
    text: "Ürün Alt Kategorisi Ekle",
    isAlreadyTranslated: true
  },
  "Add Service Centers": {
    text: "Servis Merkezleri'ni Ekleyin",
    isAlreadyTranslated: true
  },
  "CHOOSE LOCATION": {
    text: "KONUM SEÇİN",
    isAlreadyTranslated: true
  },
  "No Such Service Location": {
    text: "Böyle Bir Servis Konumu Bulunmamaktadır",
    isAlreadyTranslated: true
  },
  "Selected Service Centers": {
    text: "Seçilen Servis Merkezleri",
    isAlreadyTranslated: true
  },
  "Remaining Service Centers": {
    text: "Kalan Servis Merkezleri",
    isAlreadyTranslated: true
  },
  "Add Service Type": {
    text: "Servis Türü Ekleyin",
    isAlreadyTranslated: true
  },
  "CHOOSE SERVICE TYPE": {
    text: "SERVIS TÜRÜNÜ SEÇİN",
    isAlreadyTranslated: true
  },
  "No Such Service Type": {
    text: "Böyle bir servis türü bulunmamaktadır",
    isAlreadyTranslated: true
  },
  "Selected Service Type": {
    text: "Seçili Servis Türü",
    isAlreadyTranslated: true
  },
  "Remaining Service Types": {
    text: "Kalan Servis Hizmet Türleri",
    isAlreadyTranslated: true
  },
  "Add Store": {
    text: "Mağaza Ekleyin",
    isAlreadyTranslated: true
  },
  "CHOOSE STORE": {
    text: "MAĞAZA SEÇIN",
    isAlreadyTranslated: true
  },
  "No Such Store": {
    text: "Böyle Bir Mağaza Bulunmamaktadır",
    isAlreadyTranslated: true
  },
  "Selected Store": {
    text: "Seçilen Mağaza",
    isAlreadyTranslated: true
  },
  "Remaining Stores": {
    text: "Kalan Mağazalar",
    isAlreadyTranslated: true
  },
  "Back to all users": {
    text: "Tüm kullanıcılara geri dön",
    isAlreadyTranslated: true
  },
  "Save Rights": {
    text: "Hakları Kaydet",
    isAlreadyTranslated: true
  },
  "This Email Address has already taken, please enter a new email address.": {
    text:"Bu E-posta Adresi zaten almış, lütfen yeni bir e-posta adresi girin.",
    isAlreadyTranslated: true
  },
  "Enter Email Address of the user whose rights you want to add/modify.": {
    text:"Haklarını eklemek/değiştirmek istediğiniz kullanıcının E-posta Adresini girin.",
    isAlreadyTranslated: true
  },
  "Enter Theme Name": {
    text: "Tema Adını Girin",
    isAlreadyTranslated: true
  },
  "Add/Update Country Code": {
    text: "Ülke Kodu Ekle/Güncelle",
    isAlreadyTranslated: true
  },
  "Add/Update Language Code": {
    text: "Dil Kodu Ekle/Güncelle",
    isAlreadyTranslated: true
  },
  "Modules": {
    text: "Modüller",
    isAlreadyTranslated: true
  },
  "Tracking Details": {
    text: "Takip Detayları",
    isAlreadyTranslated: true
  },
  "Engineer Details": {
    text: "Teknisyen Detayları",
    isAlreadyTranslated: true
  },
  "Number of assigned jobs": {
    text: "Atanan iş sayısı",
    isAlreadyTranslated: true
  },
  "Attendance": {
    text: "Katılım",
    isAlreadyTranslated: true
  },
  "App Status": {
    text: "Uygulama Durumu",
    isAlreadyTranslated: true
  },
  "Current Latitude": {
    text: "Geçerli Enlem",
    isAlreadyTranslated: true
  },
  "Current Longitude": {
    text: "Mevcut Boylam",
    isAlreadyTranslated: true
  },
  "Current Pincode": {
    text: "Geçerli Posta Kodu",
    isAlreadyTranslated: true
  },
  "too much data to track": {
    text: "takip etmek için çok fazla veri mevcut",
    isAlreadyTranslated: true
  },
  "No data to track": {
    text: "takip edilecek veri bulunamadı",
    isAlreadyTranslated: true
  },
  "Tab Type": {
    text: "Sekme Türü",
    isAlreadyTranslated: true
  },
  "Tab Title": {
    text: "Sekme Başlığı",
    isAlreadyTranslated: true
  },
  "Content Title": {
    text: "İçerik Başlığı",
    isAlreadyTranslated: true
  },
  Paragraphs: {
    text: "Paragraflar",
    isAlreadyTranslated: true
  },
  List: {
    text: "Liste",
    isAlreadyTranslated: true
  },
  "Add rejection reason": {
    text: "Reddetme nedeninizi ekleyin",
    isAlreadyTranslated: true
  },
  "Please fill in the rejection reason before rejecting": {
    text: "Lütfen reddetmeden önce ret nedenini doldurun",
    isAlreadyTranslated: true
  },
  "Purchased": {
    text: "Satın Alındı",
    isAlreadyTranslated: true
  },
  "Registered": {
    text: "Kayıtlı",
    isAlreadyTranslated: true
  },
  "Rejected": {
    text: "Reddedildi",
    isAlreadyTranslated: true
  },
  "No such status found": {
    text: "Böyle bir durum bulunamadı",
    isAlreadyTranslated: true
  },
  "Plan Expiry Date": {
    text: "Bitiş Tarihini Planlayın",
    isAlreadyTranslated: true
  },
  "Plan Activation Date": {
    text: "Plan Aktivasyon Tarihi",
    isAlreadyTranslated: true
  },
  "Report is empty.": {
    text: "Rapor boş.",
    isAlreadyTranslated: true
  },
  "Upload Bulk Plan": {
    text: "Toplu Plan Yükle",
    isAlreadyTranslated: true
  },
  "Please download the sample file, make changes in it only and upload it": {
    text:"Lütfen örnek dosyayı indirin, yalnızca dosyada değişiklik yapın ve yükleyin",
    isAlreadyTranslated: true
  },
  "File Uploaded Successfully.": {
    text: "Dosya Başarıyla Yüklendi.",
    isAlreadyTranslated: true
  },
  "File Uploaded Successfully with errors at following Row Number": {
    text:"Dosya, aşağıdaki satır numaralarındaki hatalar haricinde başarılı bir şekilde yüklendi",
    isAlreadyTranslated: true
  },
  "Bulk Plan Sales Upload": {
    text: "Toplu Plan Satışı Yükleyin",
    isAlreadyTranslated: true
  },
  "Uploaded successfully": {
    text: "Başarıyla yüklendi",
    isAlreadyTranslated: true
  },
  "Visit Start Time": {
    text: "Ziyaret Başlangıç Tarihi",
    isAlreadyTranslated: true
  },
  "Visit End Time": {
    text: "Ziyaret Bitiş Saati",
    isAlreadyTranslated: true
  },
  "Engineer Name": {
    text: "Teknisyen Adı",
    isAlreadyTranslated: true
  },
  /*"Action Code": {
    text: "İşlem Kodu",
    isAlreadyTranslated: true
  },*/
  "Visit Status": {
    text: "Ziyaret Durumu",
    isAlreadyTranslated: true
  },
  "Remarks": {
    text: "Açıklamalar",
    isAlreadyTranslated: true
  },
  "There are no visits for this request.": {
    text: "Bu talep için ziyaret bulunmamaktadır.",
    isAlreadyTranslated: true
  },
  "Appointment Date": {
    text: "Randevu Tarihi",
    isAlreadyTranslated: true
  },
  "Actual Visit Date": {
    text: "Güncel Ziyaret Tarihi",
    isAlreadyTranslated: true
  },
  "Appointment Time Slot": {
    text: "Randevu Zaman Dilimi",
    isAlreadyTranslated: true
  },
  "Actual Visit Time": {
    text: "Fiili Ziyaret Süresi",
    isAlreadyTranslated: true
  },
  "Technician Name": {
    text: "Teknisyen Adı",
    isAlreadyTranslated: true
  },
  "Observation": {
    text: "Gözlem",
    isAlreadyTranslated: true
  },
  "Please follow below steps to Update Activation Token for your linked GSX User ID": {
    text:"Bağlı GSX Kullanıcı Kimliğiniz için Aktivasyon Kodunu Güncellemek için lütfen aşağıdaki adımları izleyin",
    isAlreadyTranslated: true
  },
  "Click on the below link to navigate to the Apple Activation Token Site": {
    text:"Apple Aktivasyon Kodu Sitesine gitmek için aşağıdaki bağlantıya tıklayın",
    isAlreadyTranslated: true
  },
  "Use GSX ID and Password to login. Post login, you will be able to view you Activation Token": {
    text:"Giriş yapmak için GSX ID ve Şifreyi kullanın. Giriş yaptıktan sonra Aktivasyon Kodu sayfada görünecektir",
    isAlreadyTranslated: true
  },
  "Note: Generate the Activation Token for GSX ID mapped to your Servify ID only. GSX ID mapped to your Servify User ID is GSX Email ID": {
    text:"Not: Yalnızca Servify ID'nizle eşlenen GSX Kimliği için Aktivasyon Kodu oluşturun. Servify Kullanıcı Kimliğinizle eşlenen GSX Kimliğiniz, GSX E-posta Kimliğidir",
    isAlreadyTranslated: true
  },
  "GSX Tech ID Mapped": {
    text: "GSX Teknisyen Kimliği Eşlendi",
    isAlreadyTranslated: true
  },
  'Use the "Copy to Clipboard" button to copy the Activation key': {
    text:'Aktivasyon Kodunu kopyalamak için "Panoya Kopyala" düğmesini kullanın',
    isAlreadyTranslated: true
  },
  'Note: Use the "Copy to Clipboard" button only. Do NOT select manually to copy Activation Token. You are advised NOT to sign out from Apple Activation Token Site before Activation Token is saved in Servify CRM':{
    text:'Not: Yalnızca "Panoya Kopyala" düğmesini kullanın. Aktivasyon Kodunu manuel olarak kopyalamaya çalışmayın. Aktivasyon Simgesi Servify CRM\'e kaydedilmeden önce Apple Aktivasyon Kodu Sitesinden, oturumu kapatmamanız önerilir.',
    isAlreadyTranslated: true
  },
  "Paste the Activation Token in the text box below & click Save.": {
    text:"Aktivasyon Kodunu aşağıdaki metin kutusuna yapıştırın ve Kaydet'i tıklayın.",
    isAlreadyTranslated: true
  },
  "Normal Repair": {
    text: "Normal Onarım",
    isAlreadyTranslated: true
  },
  "Check whitelisting": {
    text: "BTK kayıt kontrolü",
    isAlreadyTranslated: true
  },
  "Waiting for the Customer Care Executive to receive device.": {
    text:"Müşteri Temsilcisinin cihazı teslim alması bekleniyor.",
    isAlreadyTranslated: true
  },
  "Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, click on Non Hardware Closure for soft,ware repair or use Provisional Estimate to give a repair estimate to customer.": {
    text:"Müşteri Temsilcisi, bir cihazın onarımı öncesinde Tanı Çalıştırabilir, Cihazı İnceleyebilir, sadece yazılım desteği için Talebi Yazılım Hizmet ile Kapat tuşunu kullanabilir veya müşteriye onarım teklifi vermek için Ön Teklif'i kullanabilir.",
    isAlreadyTranslated: true
  },
  "Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, click on Non Hardware Closure for software repair or use Provisional Estimate to give a repair estimate to customer.": {
    text:"Müşteri Temsilcisi, bir cihazın onarımı öncesinde Tanı Çalıştırabilir, Cihazı İnceleyebilir, sadece yazılım desteği için Talebi Yazılım Hizmet ile Kapat tuşunu kullanabilir veya müşteriye onarım teklifi vermek için Ön Teklif'i kullanabilir.",
    isAlreadyTranslated: true
  },
  "Repair has been completed.": {
    text: "Onarım tamamlandı.",
    isAlreadyTranslated: true
  },
  "Service invoice has been generated.": {
    text: "Servis faturası oluşturuldu.",
    isAlreadyTranslated: true
  },
  "Return Order Number": {
    text: "İade Talep Numarası",
    isAlreadyTranslated: true
  },
  "Fulfiller Name": {
    text: "Karşılayanın Adı",
    isAlreadyTranslated: true
  },
  "Inspect Device": {
    text: "Cihazı İncele",
    isAlreadyTranslated: true
  },
  "Inbound": {
    text: "Gelen",
    isAlreadyTranslated: true
  },
  "Outbound": {
    text: "Giden",
    isAlreadyTranslated: true
  },
  "Please enter the mandatory field.": {
    text: "Lütfen zorunlu alanı girin.",
    isAlreadyTranslated: true
  },
  "This is a mandatory field": {
    text: "Bu zorunlu bir alandır",
    isAlreadyTranslated: true
  },
  "Please enter steps in less than 1500 characters": {
    text: "Lütfen 1500 karakterden kısa adımlar yazın",
    isAlreadyTranslated: true
  },
  "Please enter notes in less than 1000 characters": {
    text: "Lütfen 1000 karakterden kısa notlar yazın",
    isAlreadyTranslated: true
  },
  "/1500 characters entered.": {
    text: "/1500 karakterden girilen.",
    isAlreadyTranslated: true
  },
  "/500 characters entered.": {
    text: "/500 karakterden girilen.",
    isAlreadyTranslated: true
  },
  "/1000 characters entered.": {
    text: "/1000 karakterden girilen.",
    isAlreadyTranslated: true
  },
  'Waiting for Engineer to "Validate Issues" and "Add Faults" in "Issues Reported" section below.': {
    text:'Teknisyenin aşağıdaki "Bildirilen Sorunlar" bölümünde "Sorunları Doğrula" ve "Arıza Ekle" işlemlerini yapması bekleniyor.',
    isAlreadyTranslated: true
  },
  'Waiting for Engineer to "Select Symptom And Action" in "Report Issues to GSX" section below.': {
    text:'Teknisyenin "Sorunları GSX\'e Bildir" bölümünde "Bulgu ve İşlem" i seçmesi bekleniyor.',
    isAlreadyTranslated: true
  },
  "Please select GSX repair type": {
    text: "Lütfen GSX onarım türünü seçin",
    isAlreadyTranslated: true
  },
  "Proceed with creating GSX repair": {
    text: "GSX onarımı oluşturmaya devam edin",
    isAlreadyTranslated: true
  },
  "Please add part to Proceed with creating GSX repair": {
    text: "GSX onarımı oluşturmaya devam etmek için lütfen bir parça ekleyin",
    isAlreadyTranslated: true
  },
  "Waiting for Engineer to create GSX repair": {
    text: "Teknisyenin GSX onarımı oluşturması bekleniyor",
    isAlreadyTranslated: true
  },
  "Component Code": {
    text: "Bileşen Kodu",
    isAlreadyTranslated: true
  },
  "Pricing Option": {
    text: "Fiyatlandırma Seçeneği",
    isAlreadyTranslated: true
  },
  "Coverage Option": {
    text: "Kapsam Seçeneği",
    isAlreadyTranslated: true
  },
  "Please select a COMPTIA group from dropdown.": {
    text: "Lütfen açılır listeden bir COMPTIA Grubu seçin.",
    isAlreadyTranslated: true
  },
  "Please select a COMPTIA Code from dropdown.": {
    text: "Lütfen açılır listeden bir COMPTIA Kodu seçin.",
    isAlreadyTranslated: true
  },
  "Please select a Component Code from dropdown.": {
    text: "Lütfen açılır listeden bir Bileşen Kodu seçin.",
    isAlreadyTranslated: true
  },
  "Please select a Issue Code from dropdown.": {
    text: "Lütfen açılır listeden bir Sorun Kodu seçin.",
    isAlreadyTranslated: true
  },
  "Please enter the serial number of the part": {
    text: "Lütfen parçanın seri numarasını girin",
    isAlreadyTranslated: true
  },
  "Please enter correct KBB serial number of the part": {
    text: "Lütfen parçanın doğru KBB seri numarasını girin",
    isAlreadyTranslated: true
  },
  "Please select a Covergage Option Code from dropdown.": {
    text: "Lütfen açılır menüden bir Kapsam Seçenek Kodu seçin.",
    isAlreadyTranslated: true
  },
  "Please select a Coverage Option Code from dropdown.": {
    text: "Lütfen açılır menüden bir Kapsam Seçenek Kodu seçin.",
    isAlreadyTranslated: true
  },

  "Serial Number is already added": {
    text: "Seri No zaten ekli",
    isAlreadyTranslated: true
  },

  "Charge": {
    text: "Ücretlendirme",
    isAlreadyTranslated: true
  },
  "Charge Name": {
    text: "Ücretlendirme Adı",
    isAlreadyTranslated: true
  },
  "Charge Value": {
    text: "Ücretlendirme Değeri",
    isAlreadyTranslated: true
  },
  "Tax": {
    text: "Vergi",
    isAlreadyTranslated: true
  },
  "MRP": {
    text: "",
    isAlreadyTranslated: true
  },
  "Final Price": {
    text: "Son Fiyat",
    isAlreadyTranslated: true
  },
  "Add Discount": {
    text: "İndirim Ekle",
    isAlreadyTranslated: true
  },
  "Good Part Serial No": {
    text: "KGB Seri No",
    isAlreadyTranslated: true
  },
  "Bad Part Serial No": {
    text: "KBB Seri No",
    isAlreadyTranslated: true
  },
  "Create GSX Repair": {
    text: "GSX Onarımı Oluştur",
    isAlreadyTranslated: true
  },
  "Fetch Parts From GSX": {
    text: "GSX'ten Parçaları Getir",
    isAlreadyTranslated: true
  },
  "Fetch Latest Diagnostics Results": {
    text: "En Son Tanı Sonuçlarını Getir",
    isAlreadyTranslated: true
  },
  "If you want to update Repair Type. Please remove parts.": {
    text: "Onarım Türünü güncellemek istiyorsanız. Lütfen parçaları çıkarın.",
    isAlreadyTranslated: true
  },
  "Requested": {
    text: "Talep Edildi",
    isAlreadyTranslated: true
  },
  "customerCare": {
    text: "Müşteri Temsilcisi",
    isAlreadyTranslated: true
  },
  "CustomerCare": {
    text: "Müşteri Temsilcisi",
    isAlreadyTranslated: true
  },
  "View Delivery challan": {
    text: "Sevk İrsaliyesini Görüntüle",
    isAlreadyTranslated: true
  },
  "Register for Bulk Return": {
    text: "Toplu İade için Kaydolun",
    isAlreadyTranslated: true
  },
  "View Packing List": {
    text: "Paketleme Listesini Görüntüle",
    isAlreadyTranslated: true
  },
  "Dispatch": {
    text: "Sevk",
    isAlreadyTranslated: true
  },
  "View Depot Shipper Label": {
    text: "Depo Gönderici Etiketini Görüntüle",
    isAlreadyTranslated: true
  },
  "View Return Label": {
    text: "İade Etiketini Görüntüle",
    isAlreadyTranslated: true
  },
  "Carrier": {
    text: "Kurye",
    isAlreadyTranslated: true
  },
  "Add Details": {
    text: "Detayları Ekleyin",
    isAlreadyTranslated: true
  },
  "Shipper": {
    text: "Nakliyeci",
    isAlreadyTranslated: true
  },
  "Parts handover note": {
    text: "Parçaların teslimat notu",
    isAlreadyTranslated: true
  },
  Signature: {
    text: "İmza",
    isAlreadyTranslated: true
  },
  "Dispatch Mail In Devices": {
    text: "Posta ile onarımlı cihazları sevk et",
    isAlreadyTranslated: true
  },
  "Bulk Return ID": {
    text: "Toplu İade Kimliği",
    isAlreadyTranslated: true
  },
  "Custom Notes": {
    text: "Özel Notlar",
    isAlreadyTranslated: true
  },
  "Receive device from RC": {
    text: "Onarım Merkezinden Cihazı Teslim Alın",
    isAlreadyTranslated: true
  },
  "Quotation document": {
    text: "Fiyatlandırma dökümanı",
    isAlreadyTranslated: true
  },
  "Estimate Generated By": {
    text: "Tahmini Değer Şunun Tarafından Oluşturuldu:",
    isAlreadyTranslated: true
  },
  "Sl. No": {
    text: "Seri No",
    isAlreadyTranslated: true
  },
  "Rate": {
    text: "Oran",
    isAlreadyTranslated: true
  },
  "Taxable value": {
    text: "Vergilendirilebilir değer",
    isAlreadyTranslated: true
  },
  "Total Tax": {
    text: "Toplam Vergi",
    isAlreadyTranslated: true
  },
  "Service Estimate Total": {
    text: "Toplam Hizmet Teklifi",
    isAlreadyTranslated: true
  },
  "In Words": {
    text: "Kelimeler ile",
    isAlreadyTranslated: true
  },
  "Disclaimers": {
    text: "Sorumluluk Reddi",
    isAlreadyTranslated: true
  },
  "The charges mentioned in this Estimate are an approximation and is based on the anticipated details of the work to be carried out basis the inspection. It is possible for unexpected situations / complications to cause some deviation from this Estimate. If additional parts or labour are required you will be contacted immediately and upon your concurrence to carry out the details of the work, a new Estimate will be created for your consideration and approval.": {
    text:"Burada belirtilen ücretler tahminidir ve inceleme esasına göre yapılması beklenen işin detaylarına dayanmaktadır. Beklenmedik durumların / komplikasyonların bu Tahmini değerden bir miktar sapmaya neden olması mümkündür. Ek parçalar veya işçilik gerekirse derhal sizinle temasa geçilecek ve çalışmanın ayrıntılarını yerine getirme konusunda size bilgi verilecek, değerlendirmeniz ve onaylamanız için yeni bir Teklif oluşturulacaktır.",
    isAlreadyTranslated: true
  },
  "Authorised Signatory": {
    text: "Yetkili İmza",
    isAlreadyTranslated: true
  },
  "Consumed": {
    text: "Kullanılan",
    isAlreadyTranslated: true
  },

  "DOA": {
    text: "DOA",
    isAlreadyTranslated: true
  },
  "Receive": {
    text: "Teslim",
    isAlreadyTranslated: true
  },
  "Return": {
    text: "İade",
    isAlreadyTranslated: true
  },
  "Return to Customer": {
    text: "Müşteriye dön",
    isAlreadyTranslated: true
  },
  "Repair completed and Intimate customer": {
    text: "Onarım tamamlandı ve müşteriye bildirildi",
    isAlreadyTranslated: true
  },
  "QC rejected, Work in progress": {
    text: "Kalite Kontrol reddedildi, çalışma devam ediyor",
    isAlreadyTranslated: true
  },
  "Repair completed, Inform customer": {
    text: "Onarım tamamnlandı, müşteriyi bilgilendirin",
    isAlreadyTranslated: true
  },
  "Inspection in Progress": {
    text: "İnceleme Sürüyor",
    isAlreadyTranslated: true
  },
  "Unused": {
    text: "Kullanılmadı",
    isAlreadyTranslated: true
  },
  "Please select Tier part":{
    text: "Lütfen Katman parçası seçin",
    isAlreadyTranslated: true
  },
  "Initiate AST 2 Diagnostics":{
    text: "AST 2 Tanı Testi Başlat",
    isAlreadyTranslated: true
  },
  "Please add document":{
    text: "Lütfen belge ekleyiniz",
    isAlreadyTranslated: true
  },
  "Non Hardware Closure":{
    text: "Talebi Yazılım Hizmet ile Kapat",
    isAlreadyTranslated: true
  },
  "Select Repair Outcomes":{
    text: "Onarım Sonuçlarını Seçin",
    isAlreadyTranslated: true
  },
  "User can select upto 4 outcomes":{
    text: "Kullanıcı en fazla 4 sonuç seçebilir",
    isAlreadyTranslated: true
  },
  "Create NTF Repair":{
    text: "NTF Onarımı Oluştur",
    isAlreadyTranslated: true
  },
  "Delivery Notes":{
    text: "Teslim Notları",
    isAlreadyTranslated: true
  },
  "Notes will be displayed on Customer’s Delivery Report":{
    text: "Notlar, Müşteri Teslim Raporunda gösterilecektir ",
    isAlreadyTranslated: true
  },
  "Cancel":{
    text: "İptal",
    isAlreadyTranslated: true
  },
  "Save":{
    text: "Kaydet",
    isAlreadyTranslated: true
  },
  "Provisional Estimate":{
    text: "Ön Teklif",
    isAlreadyTranslated: true
  },
  "Price":{
    text: "Tutar",
    isAlreadyTranslated: true
  },
  "Total Provisional Estimate":{
    text: "Toplam Ön Teklif",
    isAlreadyTranslated: true
  },
  "Send Email to Customer":{
    text: "Müşteriye E-posta Gönder",
    isAlreadyTranslated: true
  },
  "Add New Billing Detail":{
    text: "Yeni Fatura Bilgisi Ekle",
    isAlreadyTranslated: true
  },
  "No Billing Detail found":{
    text: "Fatura Bilgisi bulunamadı",
    isAlreadyTranslated: true
  },
  "Select Billing Detail":{
    text: "Fatura Bilgisi Seç",
    isAlreadyTranslated: true
  },
  "Personal":{
    text: "Şahıs",
    isAlreadyTranslated: true
  },
  "Company":{
    text: "Şirket",
    isAlreadyTranslated: true
  },
  "Registered Name":{
    text: "Ünvan",
    isAlreadyTranslated: true
  },
  "Company Name":{
    text: "Şirket Ünvanı",
    isAlreadyTranslated: true
  },
  "Why Generate Activation token":{
    text: "Neden Aktivasyon kodu oluşturulmalı",
    isAlreadyTranslated: true
  },
  "Activation Token is adds an additional layer of security while fetching information from GSX. This additional parameter of security has been added by Apple for accessing information using API. For more information, you can get in touch with Servify Administrator.":{
    text: "Aktivasyon Kodu, GSX'ten bilgi alınırken ek bir güvenlik katmanı ekler. Bu ek güvenlik parametresi, API kullanarak bilgilere erişmek için Apple tarafından eklenmiştir. Daha fazla bilgi için Servify Yöneticisi ile iletişime geçebilirsiniz.",
    isAlreadyTranslated: true
  },
  "Repair Type Description":{
    text: "Onarım Türü Detayları",
    isAlreadyTranslated: true
  },
  "Non hardware closure details updated":{
    text: "Donanım dışı kapatma ayrıntıları güncellendi",
    isAlreadyTranslated: true
  },
  "Provisional estimate updated":{
    text: "Ön teklif güncellendi",
    isAlreadyTranslated: true
  },
  "Provisional estimate generated":{
    text: "Ön teklif oluşturuldu",
    isAlreadyTranslated: true
  },
  "Device inspected":{
    text: "Cihaz incelendi",
    isAlreadyTranslated: true
  },
  "Comptia Modifier":{
    text: "Comptia Değiştirici",
    isAlreadyTranslated: true
  },
  "Enter File Name":{
    text: "Dosya Adı Girin",
    isAlreadyTranslated: true
  },
  "Run Diagnosis":{
    text: "Tanı Başlat",
    isAlreadyTranslated: true
  },
  "Skip diagnosis":{
    text: "Tanıyı atla",
    isAlreadyTranslated: true
  },
  "Inspect Device":{
    text: "Cihazı İncele",
    isAlreadyTranslated: true
  },
  "Battery usage":{
    text: "Batarya Kullanımı",
    isAlreadyTranslated: true
  },
  "Call performance":{
    text: "Arama performansı",
    isAlreadyTranslated: true
  },
  "Mobile resource Operator":{
    text: "Mobil kaynak operatörü",
    isAlreadyTranslated: true
  },
  "Loaner diagnostics":{
    text: "Cihaz tesliminde yapılan tanı",
    isAlreadyTranslated: true
  },

  "Alternative Mobile No": {
    text: "Alternatif Telefon Numarası",
    isAlreadyTranslated: true
  },
  "Logistics Report":{
    text: "Lojistik Raporu",
    isAlreadyTranslated: true
  },
  "Select Report Type":{
    text: "Rapor Türü Seçin",
    isAlreadyTranslated: true
  },
  "Filter period":{
    text: "Periyodu Filtreleyin",
    isAlreadyTranslated: true
  },
  "Service": {
    text: "Servis",
    isAlreadyTranslated: true
  },
  "Download report": {
    text: "Raporu İndir",
    isAlreadyTranslated: true
  },
  Parts: {
    text: "parçalar",
    isAlreadyTranslated: true
  },
  "Select a Type": {
    text: "Bir tür seçiniz",
    isAlreadyTranslated: true
  },
  "Filter Report": {
    text: "Raporu Filtreleyin",
    isAlreadyTranslated: true
  },
  "FILTER PERIOD":{
    text: "Periyodu Filtreleyin",
    isAlreadyTranslated: true
  },
  "No data found": {
    text: "Veri Bulunamadı",
    isAlreadyTranslated: true
  },
  "Apple plans":{
    text: "Apple planları",
    isAlreadyTranslated: true
  },
  "Know more":{
    text: "Daha fazla bilgi",
    isAlreadyTranslated: true
  },
  "Failure":{
    text: "Başarısız",
    isAlreadyTranslated: true
  },
  "Pending":{
    text: "Bekliyor",
    isAlreadyTranslated: true
  },
  "Sell":{
    text: "Sat",
    isAlreadyTranslated: true
  },
  "Transaction Status":{
    text: "İşlem durumu",
    isAlreadyTranslated: true
  },
  "Period":{
    text: "Periyot",
    isAlreadyTranslated: true
  },
  "Completed":{
    text: "Tamamlanan",
    isAlreadyTranslated: true
  },
  "completed":{
    text: "Tamamlanan",
    isAlreadyTranslated: true
  },
  "Filter":{
    text: "Filtre",
    isAlreadyTranslated: true
  },
  "Total Count":{
    text: "Toplam Miktar",
    isAlreadyTranslated: true
  },
  "Total Value":{
    text: "Toplam Değer",
    isAlreadyTranslated: true
  },
  "Failed":{
    text:"Başarısız Olanlar",
    isAlreadyTranslated: true
  },
  "Brands":{
    text:"Markalar",
    isAlreadyTranslated: true
  },
  "Products":{
    text:"Cihazlar",
    isAlreadyTranslated: true
  },
  "Task Type":{
    text:"Görev Türü",
    isAlreadyTranslated: true
  },
  "Waiting for engineer assignment":{
    text:"Teknisyen ataması bekleniyor",
    isAlreadyTranslated: true
  },
  "Provisional Charges Added/Updated":{
    text:"Tahmini Masraflar Eklendi / Güncellendi",
    isAlreadyTranslated: true
  },
  "Part Provisional Charges Removed":{
    text:"Tahmini Parça Masrafları Kaldırıldı",
    isAlreadyTranslated: true
  },
  "Device handed over by CustomerCare to ServicelocationEngineer":{
    test:"Cihaz Müşteri Temsilcisi tarafından Servis Teknisyeni'ne devredildi",
    isAlreadyTranslated: true
  },
  "Device accepted by ServicelocationEngineer from CustomerCare":{
    text:"Cihaz Servis Teknisyen'i tarafından Müşteri Temsilcisi'nden kabul edildi",
    isAlreadyTranslated: true
  },
  "Select GSX Repair Type":{
    text:"GSX Onarım Türünü Seçin",
    isAlreadyTranslated: true
  },

  "Remove":{
    text: "Kaldır",
    isAlreadyTranslated: true
  },
  "View Provisional Estimate Document":{
    text:"Ön Teklif Belgesini görüntüleyin",
    isAlreadyTranslated: true
  },
  "View Estimation Document":{
    text:"Teklif Belgesini Görüntüleyin",
    isAlreadyTranslated: true
  },
  "Please Fill Payment Mode Details.":{
    text:"Lütfen Ödeme Modu Ayrıntılarını Doldurun",
    isAlreadyTranslated: true
  },
  "Please Enter Amount.":{
    text:"Lütfen Miktar Girin",
    isAlreadyTranslated: true
  },
  "Additional COMPTIA codes":{
    text:"Ek COMPTIA kodları",
    isAlreadyTranslated: true
  },
  "Please enter correct ": {
    text: "Lütfen doğru giriniz",
    isAlreadyTranslated: true
  },
  "Please Enter Correct ": {
    text: "Lütfen doğru giriniz",
    isAlreadyTranslated: true
  },
  "Out of warranty": {
    text: "Garanti dışı",
    isAlreadyTranslated: true
  },
  "Advance Collected": {
    text: "Ön Ödeme Alındı",
    isAlreadyTranslated: true
  },
  "Enter Serial number":{
    text: "Seri Numarasını Girin",
    isAlreadyTranslated: true
  },
  "Enter the Serial Number":{
    text: "Seri Numarasını Girin",
    isAlreadyTranslated: true
  },
  "Enter the IMEI/Serial Number":{
    text: "IMEI/Seri Numarasını Girin",
    isAlreadyTranslated: true
  },
  "Reject GSX Repair":{
    text: "GSX Onarımını Reddet",
    isAlreadyTranslated: true
  },
  "Inventory manager":{
    text:"Envanter yöneticisi",
    isAlreadyTranslated: true
  },
  "Ordered":{
    text:"Sipariş Edildi",
    isAlreadyTranslated: true
  },
  "Issued":{
    text:"Verildi",
    isAlreadyTranslated: true
  },
  "Received":{
    text:"Ulaştı",
    isAlreadyTranslated: true
  },
  "Consumed":{
    text:"Kullanıldı",
    isAlreadyTranslated: true
  },
  "Defective":{
    text:"Kusurlu",
    isAlreadyTranslated: true
  },
  "Handover":{
    text:"Teslim et",
    isAlreadyTranslated: true
  },
  "handover":{
    text:"teslim et",
    isAlreadyTranslated: true
  },
  "ordered":{
    text:"sipariş edildi",
    isAlreadyTranslated: true
  },
  "issued":{
    text:"teslim edildi",
    isAlreadyTranslated: true
  },
  "received":{
    text:"ulaştı",
    isAlreadyTranslated: true
  },
  "consumed":{
    text:"Kullanıldı",
    isAlreadyTranslated: true
  },
  "defective":{
    text:"kusurlu",
    isAlreadyTranslated: true
  },
  "View Delivery Report":{
    text:"Teslimat Raporunu Görüntüle",
    isAlreadyTranslated: true
  },
  "Warranty Validated from Brand":{
    text:"Markadan Onaylanan Garanti",
    isAlreadyTranslated: true
  },
  "Please enter the reason for warranty applicable":{
    text:"Lütfen garantinin geçerlilik nedenini girin",
    isAlreadyTranslated: true
  },
  "Purchase cost can not be zero":{
    text:"Satın alma maliyeti sıfır olamaz",
    isAlreadyTranslated: true
  },
  "Please add tag":{
    text:"Lütfen etiket ekleyin",
    isAlreadyTranslated: true
  },
  /*"Please add document":{
    text:"Lütfen belge ekleyin",
    isAlreadyTranslated: true
  },*/
  "File size should not be greater than 10 MB":{
    text:"Dosya boyutu 10 MB'tan büyük olmamalıdır",
    isAlreadyTranslated: true
  },
  "Please enter values in all the fields":{
    text:"Lütfen tüm alanlara değer girin",
    isAlreadyTranslated: true
  },
  "Full box unit cannot be added if another part is already added":{
    text:"Başka bir parça zaten eklenmişse, dolu kutu ünitesi eklenemez",
    isAlreadyTranslated: true
  },
  "Full box unit is already added.":{
    text:"Dolu kutu ünitesi zaten eklenmiş.",
    isAlreadyTranslated: true
  },
  "Part price is not updated for the Selected part":{
    text:"Seçili parça için parça fiyatı güncellenmiyor",
    isAlreadyTranslated: true
  },
  "Please contact Admin":{
    text:"Lütfen Yönetici ile iletişime geçin",
    isAlreadyTranslated: true
  },
  "Repair Completion Type":{
    text:"Onarım Tamamlama Türü",
    isAlreadyTranslated: true
  },
  "No Parts Requested":{
    text:"Parça İstenmiyor",
    isAlreadyTranslated: true
  },
  "NA":{
    text:"Uygulanamaz",
    isAlreadyTranslated: true
  },
  "Out Of Warranty (No Coverage)":{
    text:"Garanti Dışı (Kapsam dışı)",
    isAlreadyTranslated: true
  },
  "Number":{
    text:"Numarası",
    isAlreadyTranslated: true
  },
  "Workshop Engineer":{
    text:"Teknisyen",
    isAlreadyTranslated: true
  },
  "Front Image":{
    text:"Ön Görüntü",
    isAlreadyTranslated: true
  },
  "Top Image":{
    text:"Üst Görüntü",
    isAlreadyTranslated: true
  },
  "Bottom Image":{
    text:"Alt Görüntü",
    isAlreadyTranslated: true
  },
  "Left Image":{
    text:"Sol Görüntüsü",
    isAlreadyTranslated: true
  },
  "Right Image":{
    text:"Sağ Görüntü",
    isAlreadyTranslated: true
  },
  "Device Invoice":{
    text:"Cihaz Faturası",
    isAlreadyTranslated: true
  },
  "Other":{
    text:"Diğer",
    isAlreadyTranslated: true
  },
  "Cannot add more than 4 outcomes":{
    text:"4'ten fazla sonuç eklenemez",
    isAlreadyTranslated: true
  },
  "Imei Number":{
    text:"iMEI Numarası",
    isAlreadyTranslated: true
  },
  "By":{
    text:"Tarafından",
    isAlreadyTranslated: true
  },
  "Whitelist Document":{
    text:"Beyaz liste belgesi",
    isAlreadyTranslated: true
  },
  "Hardware Closure":{
    text:"Donanım Onarımı ile Sonlandır",
    isAlreadyTranslated: true
  },
  "Map G-Number":{
    text:"G-Numarası Eşle",
    isAlreadyTranslated: true
  },
  "Please enter G Number.":{
    text:"G Numarasını giriniz",
    isAlreadyTranslated: true
  },
  "Please enter Device Recieved Date and Time .":{
    text:"Cihaz alınma Tarih ve Saatini giriniz",
    isAlreadyTranslated: true
  },
  "Your G Number has been mapped":{
    text:"G Numaranız eşlendi",
    isAlreadyTranslated: true
  },
  "Update KGB":{
    text:"KGB Güncelle",
    isAlreadyTranslated: true
  },
  "KGB Number":{
    text:"KGB Numarası",
    isAlreadyTranslated: true
  },
  "Please enter valid KGB Number":{
    text:"Geçerli KGB Numarası giriniz",
    isAlreadyTranslated: true
  },
  "Servicelocation Engineer":{
    text:"Teknisyen",
    isAlreadyTranslated: true
  },
  "StoreManager": {
    text: "Depo Yöneticisi",
    isAlreadyTranslated: true
  },
  "Return without repair (RWR)":{
    text: "Onarım olmadan iade et",
    isAlreadyTranslated: true
  },
  "Log Call":{
    text: "Çağrı Kaydı Aç",
    isAlreadyTranslated: true
  },
  "Manufacturer's Warranty Status":{
    text: "Üretici Garantisi Durumu",
    isAlreadyTranslated: true
  },
  "Device Value":{
    text: "Cihaz Değeri",
    isAlreadyTranslated: true
  },
  "Is Manufacturer's Warranty Applicable":{
    text: "Üretici Garantisi Kapsamında mı",
    isAlreadyTranslated: true
  },
  "Connection Disposition": {
    text: "Bağlanma Durumu",
    isAlreadyTranslated: true
  },
  "Non-Connect Disposition": {
    text: "Ulaşılamama Nedeni",
    isAlreadyTranslated: true
  },
  "Mandatory fields Required.":{
    text: "Zorunlu alanlar Gereklidir.",
    isAlreadyTranslated: true
  },
  "Please enter tracking Number.":{
    text: "Lütfen takip Numarası giriniz.",
    isAlreadyTranslated: true
  },
  "Please enter Shipper Detail.":{
    text: "Lütfen Gönderici Bilgisi giriniz.",
    isAlreadyTranslated: true
  },
  "Please enter Box Height.":{
    text: "Lütfen kutu yüksekliğini giriniz.",
    isAlreadyTranslated: true
  },
  "Please enter Box width.":{
    text: "Lütfen kutu enini giriniz.",
    isAlreadyTranslated: true
  },
  "Please enter Box Length.":{
    text: "Lütfen kutu boyunu giriniz.",
    isAlreadyTranslated: true
  },
  "Please enter Box Weight.":{
    text: "Lütfen kutu ağırlığını giriniz.",
    isAlreadyTranslated: true
  },
  "Please enter Over Pack Box Number.":{
    text: "Lütfen kutu üzerindeki numarayı giriniz.",
    isAlreadyTranslated: true
  },
  "Carrier":{
    text: "Kurye",
    isAlreadyTranslated: true
  },
  "Depot Shipper Label":{
    text: "Depo Nakliye Etiketi",
    isAlreadyTranslated: true
  },
  "Packing List":{
    text: "Paket Listesi",
    isAlreadyTranslated: true
  },
  "Delivery Challan":{
    text: "İrsaliye",
    isAlreadyTranslated: true
  },
  "Over Pack Box Number":{
    text: "Kutu üzeri Numara",
    isAlreadyTranslated: true
  },
  "Add Carrier Details":{
    text: "Kurye Detaylarını Ekleyin",
    isAlreadyTranslated: true
  },
  "GSX Confirmation No":{
    text: "GSX Onay No",
    isAlreadyTranslated: true
  },
  "Repair Creation Date":{
    text: "Onarım Oluşturulma Tarihi",
    isAlreadyTranslated: true
  },
  "Please enter valid":{
    text: "Lütfen bunu doğru giriniz:",
    isAlreadyTranslated: true
  },
  "Please select Payment Mode.":{
    text: "Lütfen Ödeme Türü seçiniz",
    isAlreadyTranslated: true
  },
  "Inspection Guide":{
    text: "İnceleme Kılavuzu",
    isAlreadyTranslated: true
  },
  "Guide":{
    text: "VMI Kılavuzu",
    isAlreadyTranslated: true
  },
  "Please make sure FMIP is switched off.":{
    text: "Lütfen iPhone’umu Bul’un kapalı olduğundan emin olunuz.",
    isAlreadyTranslated: true
  },
  "If you wish to skip FMIP click on YES else ask customer to Turn off FMIP and try again.":{
    text: "Atlamak istiyorsanız EVET’e tıklayın, aksi halde müşteriye iPhone’umu Bul’u kapatmasını sorun ve yeniden deneyin.",
    isAlreadyTranslated: true
  },
  "Instructions":{
    text: "Yönergeler",
    isAlreadyTranslated: true
  },
  "Start at your Home screen.":{
    text: "Ana Ekranda başlayın.",
    isAlreadyTranslated: true
  },
  "Tap Settings > iCloud.":{
    text: "Ayarlar > iCloud yolunu izleyin.",
    isAlreadyTranslated: true
  },
  "Scroll to the bottom and tap Find My iPhone.":{
    text: "Aşağıya inin ve iPhone’umu Bul’a tıklayın.",
    isAlreadyTranslated: true
  },
  "Slide to turn off Find My iPhone and Send Last Location.":{
    text: "iPhone’umu Bul’u ve Son Konumu Gönder’i kapalı duruma getirin.",
    isAlreadyTranslated: true
  },
  "Retry":{
    text: "Tekrar Dene",
    isAlreadyTranslated: true
  },
  "Please update Warranty applicable status in Device Details section.": {
    text: "Lütfen “Cihaz Detayı” bölümündeki Garanti kapsamını güncelleyiniz.",
    isAlreadyTranslated: true
  },
  "Customer Care Executive can Run Diagnosis, Inspect Device to log a hardware repair, Please update Warranty applicable status in Device Details section to enable Non Hardware Closure.": {
    text: "Müşteri Temsilcisi, bir cihazın onarımı öncesinde “Tanı Çalıştırabilir”, “Cihazı İnceleyebilir”. Lütfen sadece yazılım hizmeti vermek için “Cihaz Detayı” bölümündeki Garanti kapsamını güncelleyiniz.",
    isAlreadyTranslated: true
  },
  "Please select file name, or choose 'Others' to enter a custom file name.":{
    text: "Lütfen dosya adını seçiniz veya 'Diğerleri' ni seçerek elle dosya isim giriniz.",
    isAlreadyTranslated: true
  },
  "Assigned Engineers":{
    text: "Atanan Teknisyenler",
    isAlreadyTranslated: true
  },
  "Select File Name":{
    text: "Dosya Adını Seç",
    isAlreadyTranslated: true
  },
  "Review By Apple Requested":{
    text: "Apple İncelemesi İstendi",
    isAlreadyTranslated: true
  },
  "CS Code Entered":{
    text: "CS Kod Girildi",
    isAlreadyTranslated: true
  },
  "Repair Classification Type":{
    text: "Onarım Sınıflandırma Türü",
    isAlreadyTranslated: true
  },
  "Guided Troubleshooting Details":{
    text: "Yönlendirmeli Sorun Giderme",
    isAlreadyTranslated: true
  },
  "Please enter Required Fields (*).":{
    text: "Lütfen zorunlu alanları giriniz (*).",
    isAlreadyTranslated: true
  },
  "No Guided Troubleshooting Details":{
    text: "Yönlendirmesiz Sorun Giderme",
    isAlreadyTranslated: true
  },
  "Repair Notes (for Delivery Report)":{
    text: "Servis Raporu",
    isAlreadyTranslated: true
  },
  "Repair Notes":{
    text: "Onarım Notları",
    isAlreadyTranslated: true
  },
  "Please enter the Repair Notes":{
    text: "Lütfen Onarım Notlarını giriniz",
    isAlreadyTranslated: true
  },
  "Same as Engineer Diagnosis Notes":{
    text:"Teknisyen Tanı Notları ile Aynı",
    isAlreadyTranslated: true
  },
  "In case you want to select different outcomes for both parts, please select outcome for one part and Save the same. Post that please update the outcome for the remaining parts. For additional help, contact your Servify administrator.":{
    text:"Her iki parça için farklı sonuçlar seçmek istiyorsanız, lütfen bir parça için sonuç seçin ve kaydedin. Lütfen kalan parçaların sonuçlarını sonra güncelleyin. Ek yardım için Servify yöneticinize başvurun.",
    isAlreadyTranslated: true
  },
  "Enter KBB Details":{
    text:"KBB Detaylarını Giriniz",
    isAlreadyTranslated: true
  },
  "KBB Number":{
    text:"KBB Numarası",
    isAlreadyTranslated: true
  },
  "Reorder Details":{
    text: "Yeniden Sipariş Detayları",
    isAlreadyTranslated: true
  },
  "Reorder Part Number":{
    text: "Yeniden Sipariş Parça Numarası",
    isAlreadyTranslated: true
  },
  "Reorder Part as":{
    text: "Parçanın Yeniden Sipariş türü",
    isAlreadyTranslated: true
  },
  "Purchase Order":{
    text: "Satınalma Siparişi",
    isAlreadyTranslated: true
  },
  "Consignment Order":{
    text: "Konsinye Sipariş",
    isAlreadyTranslated: true
  },
  "GSX Confirmation Number":{
    text: "GSX Onay Numarası",
    isAlreadyTranslated: true
  },
  "Repair created successfully on GSX":{
    text: "Onarım GSX’te başarı ile oluşturuldu",
    isAlreadyTranslated: true
  },
  "Repair Status At GSX":{
    text: "GSX’deki Onarım Durum",
    isAlreadyTranslated: true
  },
  "Use from Consigned Inventory":{
    text : "Konsinye Stoktan Kullan",
    isAlreadyTranslated : true
  },
  "KGB Serial Number":{
    text : "KGB Seri No",
    isAlreadyTranslated : true
  },
  "KGB Serial Number (Consignment Part Serial No)":{
    text : "KGB Seri No (Konsinye Parça Seri No)",
    isAlreadyTranslated : true
  },
  "Fetch GSX Status":{
    text : "GSX Durumunu Getir",
    isAlreadyTranslated : true
  },
  "Use Consignment Part":{
    text : "Konsinye Parça Kullan",
    isAlreadyTranslated : true
  },
  "Enter DOA Parts Details":{
    text : "DOA Parça Detaylarını Gir",
    isAlreadyTranslated : true
  },
  "Raise GSX Request":{
    text : "GSX Onarımı Oluştur",
    isAlreadyTranslated : true
  },
  "We are unable to establish complete device details from GSX. Diagnosis may not have been performed on this device. Please proceed with request creation by selecting product details.":{
    text : "GSX'ten cihaz ayrıntılarını tamamlayamıyoruz. Bu cihazda tanı yapılmamış olabilir. Lütfen ilerlemek için bu cihazda tanı yapınız. Lütfen kayıt oluşturmaya cihaz ayrıntılarını seçerek devam ediniz.",
    isAlreadyTranslated : true
  },
  "We are unable to establish complete device details from GSX. Diagnosis may not have been performed on this device. Please diagnose the device to proceed. Note: Excessive tries without device diagnosis can block your account access.":{
    text : "GSX'ten cihaz ayrıntılarını tamamlayamıyoruz. Bu cihazda tanı yapılmamış olabilir. Lütfen ilerlemek için bu cihazda tanı yapınız. Not: Tanı yapmadan çok gez deneme yapmak hesabınızı bloke edebilir.",
    isAlreadyTranslated : true
  },
  "Notes":{
    text: "Notları",
    isAlreadyTranslated: true
  },
  "Service Estimate":{
    text:"Servis Teklifi",
    isAlreadyTranslated: true
  },
  "Please make sure “Find My” feature is switched off before accepting device for repair.":{
    text:"Lütfen cihazı onarım için kabul etmeden önce “Aygıtımı Bul” özelliğinin kapalı olduğundan emin olunuz.",
    isAlreadyTranslated: true
  },
  "Disable Find My Functionality":{
    text:"Aygıtımı Bul Özelliğini Kapatınız",
    isAlreadyTranslated: true
  },
  "Engineer reassigned":{
    text:"Teknisyen yeniden atandı",
    isAlreadyTranslated: true
  },
  "Order from GSX":{
    text:"GSX Siparişi",
    isAlreadyTranslated: true
  },
  "Please select value for ‘Use from Consigned Inventory’ field":{
    text:"Lütfen 'Konsinye stoğundan kullan' alanını seçiniz",
    isAlreadyTranslated: true
  },
  "Return Details":{
    text:"İade Detayları",
    isAlreadyTranslated: true
  },
  "Return Part as":{
    text:"Parçayı şöyle iade et",
    isAlreadyTranslated: true
  },
  "Stock DOA":{
    text:"Stok DOA",
    isAlreadyTranslated: true
  },
  "Please Enter Component Code":{
    text:"Lütfen Bileşen Kodunu Girin",
    isAlreadyTranslated: true
  },
  "Please Enter Issue Code":{
    text:"Lütfen Sorun Kodunu Girin",
    isAlreadyTranslated: true
  },
  "Please enter comptia Modifier":{
    text:"Lütfen comptia değişkenini Girin",
    isAlreadyTranslated: true
  },
  "Your inventory does not contain stock for the part number selected. Please add consignment stock to your inventory to proceed.":{
    text:"Envanteriniz seçilen parça numarası için stok içermiyor. Devam etmek için lütfen envanterinize konsinye stok ekleyin.",
    isAlreadyTranslated: true
  },
  "Please select Part Return Status Code":{
    text:"Lütfen Parça İade Durum Kodunu seçin",
    isAlreadyTranslated: true
  },
  "Box Width": {
    text: "Kutu Genişlik",
    isAlreadyTranslated: true
  },
  "Box Height": {
    text: " Kutu Yükseklik",
    isAlreadyTranslated: true
  },
  "Box Weight": {
    text: " Kutu Ağırlık",
    isAlreadyTranslated: true
  },
  "Box Length": {
    text: "Kutu Uzunluk",
    isAlreadyTranslated: true
  },
  'Waiting for Engineer to Validate Issues and add Symptom, Fault, Action details in "Issues Reported" section below.':{
    text: 'Teknisyenin Sorunları Doğrulamasını ve aşağıdaki "Bildirilen Sorunlar" bölümüne Bulgu, Ariza, İşlem ayrıntılarını eklemesi bekleniyor.',
    isAlreadyTranslated: true
  },
  "Primary":{
    text: "Birincil",
    isAlreadyTranslated: true
  },
  "Reproducibility":{
    text: "Yeniden Oluşturabilirlik",
    isAlreadyTranslated: true
  },
  "Engineer Reported Component / Issue Code(s)":{
    text: "Teknisyen Tarafından Bildirilen Bileşen / Sorun Kodları",
    isAlreadyTranslated: true
  },
  "Customer Reported Component / Issue Code(s) [Optional]":{
    text: "Müşteri Tarafından Bildirilen Bileşen / Sorun Kodları [Opsiyonel]",
    isAlreadyTranslated: true
  },
  "Customer Reported Issues":{
    text:"Müşterinin Bildirdiği Sorunlar",
    isAlreadyTranslated: true
  },
  "Please select a Reproducibility from dropdown.":{
    text:"Lütfen açılır menüden Yeniden Oluşturabiliriliği seçiniz.",
    isAlreadyTranslated: true
  },
  "Please enter Reproducibility":{
    text:"Lütfen Yeniden Oluşturabilirliği girini",
    isAlreadyTranslated: true
  },
  "Waiting for Engineer to Validate Issues and add component code, issue code, action details in 'Issues Reported' section below.":{
    text:"Teknisyenin Sorunları Doğrulaması ve aşağıdaki `Bildirilen Sorunlar` bölümüne bileşen kodu, sorun kodu, işlem ayrıntılarını eklemesi bekleniyor.",
    isAlreadyTranslated: true
  },
  "Please enter a valid name":{
    text:"Lütfen geçerli bir isim giriniz",
    isAlreadyTranslated: true
  },
  "File Type":{
    text:"Dosya Türü",
    isAlreadyTranslated: true
  },
  "Proof of Purchase":{
    text:"Satınalma Kanıtı",
    isAlreadyTranslated: true
  },
  "GSX Upload Status":{
    text:"GSX’e Yükleme Durumu",
    isAlreadyTranslated: true
  },
  "Send to GSX":{
    text:"GSX'e gönder",
    isAlreadyTranslated: true
  },
  "Please update the device date of purchase in device details section to proceed further.":{
    text:"Devam etmek için lütfen cihaz ayrıntıları bölümünde cihazın satın alma tarihini güncelleyin.",
    isAlreadyTranslated: true
  },
  "GSX Serial No (for Non Serialised Product)":{
    text:"GSX Seri No (Seri Numarasız Ürün için)",
    isAlreadyTranslated: true
  },
  "The file format uploaded in your zip file is not supported. Please remove any folders, correct the file formats and try again.":{
    text:"Zip dosyasında yüklenen dosya biçimi desteklenmiyor.  Lütfen tüm klasörleri siliniz ve dosya biçimlerini duzelterek tekrar deneyiniz.",
    isAlreadyTranslated: true
  },
  "Uploaded file format not supported. Pls upload file in one of the following formats - aac, csv, doc, docx, jpeg, jpg, m4a, mp4, ogg, ogx, pdf, png, txt, xls, xlsx, zip.":{
    text:"Yüklenen dosya biçimi desteklenmiyor. Lütfen şu biçimlerden birini kullanınız - aac, csv, doc, docx, jpeg, jpg, m4a, mp4, ogg, ogx, pdf, png, txt, xls, xlsx, zip.",
    isAlreadyTranslated: true
  },
  "Zip file uploaded has no content. Check and re-upload file.":{
    text:"Yüklenen zip dosyasında içerik yok. Lütfen dosyayı tekrar yükleyiniz.",
    isAlreadyTranslated: true
  },
  "Uploaded":{
    text:"Yüklendi",
    isAlreadyTranslated: true
  },
  "Attached to Repair":{
    text:"Onarıma Eklendi",
    isAlreadyTranslated: true
  },
  "Failed Re-Upload":{
    text:"Yeniden Gönderim Başarısız",
    isAlreadyTranslated: true
  },
  "Device is not eligible for integrated diagnosis, please click on complete diagnosis/cannot diagnose to capture the notes and proceed.":{
    text:"Cihaz tanılama için uygun değil, not alıp devam etmek için lütfen tanı tamamlandı veya tanı tamamlanmadıya tıklayınız.",
    isAlreadyTranslated: true
  },
  "Warning":{
    text: "Uyari",
    isAlreadyTranslated: false
  },
  "Customer Care Executive can Inspect Device to log a hardware repair, Please update Warranty applicable status in Device Details section to enable Non Hardware Closure.":{
    text: "Müşteri Temsilcisi, bir onarımını başlatmak için Cihazı İnceleyebilir, lütfen talebin Sadece Yazılım Hizmeti ile kapatılmasını etkinleştirmek için Cihaz Ayrıntıları bölümünde Garanti kapsamını güncelleyiniz.",
    isAlreadyTranslated: false
  },
  "Customer Care Executive can Inspect Device to log a hardware repair, or click on Non Hardware Closure to complete the repair as a non-part service repair.":{
    text: "Müşteri Temsilcisi, bir onarım başlatmak için Cihazı İnceleyebilir, veya parça kullanmadan onarımı tamamlamak için Sadece Yazılım Hizmetine tıklayınız.",
    isAlreadyTranslated: false
  },
  "Please update \"Is Manufacturer's Warranty Applicable\" and save device details.":{
    text: "Lütfen, \"Üretici Garantisi Kapsamında Mı?\" sorusunu yanıtlayınız ve cihaz ayrıntılarını kaydediniz.",
  isAlreadyTranslated: false
  },
  "Request Type":{
    text: "Talep Türü",
    isAlreadyTranslated: false
  },
  "Fetch Diagnostics Suites":{
    text: "Tanı Setlerini Getir",
    isAlreadyTranslated: false
  },
  "Enter Serial Number / IMEI":{
    text: "Seri / IMEI Numarası",
    isAlreadyTranslated: false
  },
  "Sales Channel":{
    text: "Satış Kanalı",
    isAlreadyTranslated: false
  },
  "Street Address":{
    text: "Sokak",
    isAlreadyTranslated: false
  },
  "Postal Code":{
    text: "Posta Kodu",
    isAlreadyTranslated: false
  },
  "Province":{
    text: "İl",
    isAlreadyTranslated: false
  },
  "Locality":{
    text: "İlçe",
    isAlreadyTranslated: false
  },
  "Enter a location":{
    text: "Bir Konum Giriniz",
    isAlreadyTranslated: false
  },
  "Landmark is required":{
    text: "Semt Gereklidir",
    isAlreadyTranslated: false
  },
  "Street Address is required":{
    text: "Sokak Gereklidir",
    isAlreadyTranslated: false
  },
  "Postal Code is required":{
    text: "Posta Kodu Gereklidir",
    isAlreadyTranslated: false
  },
  "Province is required":{
    text: "İl bilgisi Gereklidir",
    isAlreadyTranslated: false
  },
  "Postal Code is invalid":{
    text: "Posta Kodu geçersiz",
    isAlreadyTranslated: false
  },
  "Province is incorrect":{
    text: "İl bilgisi yanlış",
    isAlreadyTranslated: false
  },
  "Some of the details might be missing in the address, please update the address.":{
    text: "Adreste bazı ayrıntılar eksik olabilir, lütfen adresi güncelleyin.",
    isAlreadyTranslated: false
  },
  "email id": {
    text: "E-posta Adresi",
    isAlreadyTranslated: true
  },
  "VAT Number":{
    text: "Vergi Numarası",
    isAlreadyTranslated: false
  },
  "VAT Number is required":{
    text: "Vergi Numarası gerekli",
    isAlreadyTranslated: false
  },
  "Should Be 15 Characters":{
    text: "15 Karakter Olmalı",
    isAlreadyTranslated: false
  },
  "Should Be 10 Characters":{
    text: "10 Karakter Olmalı",
    isAlreadyTranslated: false
  },
  "Name is required":{
    text: "Ünvan gerekli",
    isAlreadyTranslated: false
  },
  "There's a problem with this location, please select a different location":{
    text: "Bu konumda bir sorun var lütfen farklı bir konum seçiniz.",
    isAlreadyTranslated: false
  },
  "Unable to Diagnose":{
    text: "Tanı Yapılamıyor",
    isAlreadyTranslated: false
  },
  "Contact Information":{
    text: "İletişim Bilgisi",
    isAlreadyTranslated: false
  },
  "Disclaimer":{
    text: "Feragatname",
    isAlreadyTranslated: false
  },
  "Send OTP":{
    text: "Kod gönder",
    isAlreadyTranslated: false
  },
  "OTP Validation":{
    text: "KOD Doğrulama",
    isAlreadyTranslated: false
  },
  "Back":{
    text: "Geri",
    isAlreadyTranslated: false
  },
  "Enter the OTP sent to":{
    text: "Gönderilen KOD'u giriniz",
    isAlreadyTranslated: false
  },
  "Confirm OTP":{
    text: "KOD'u onayla",
    isAlreadyTranslated: false
  },
  "Estimation rejected":{
    text: "Teklif reddedildi",
    isAlreadyTranslated: true
  },
  "Mail-In Dispatched Successfully":{
    text: "Posta Başarıyla Gönderildi",
    isAlreadyTranslated: true
  },
  "Battery back up issue":{
    text: "Pil yedekleme sorunu",
    isAlreadyTranslated: true
  },
  "Pending for Dispatch":{
    text: "Sevk için Bekliyor",
    isAlreadyTranslated: true
  },
  "Get Bulk Return":{
    text: "TOPLU İADE",
    isAlreadyTranslated: true
  },
  "Please enter valid KGB IMEI Number":{
    text: "Lütfen geçerli KGB IMEI Numarası girin",
    isAlreadyTranslated: true
  },
  "KGB IMEI Number":{
    text: "KGB IMEI Numarası",
    isAlreadyTranslated: true
  },
  "Update":{
    text: "Güncelle",
    isAlreadyTranslated: true
  },
  "OverPack No": {
    text: "Dış Paket Numarası",
    isAlreadyTranslated: true
  },
  "Please enter Overpack No. for": {
    text: "Lütfen şunun için Dış Paket numarasını giriniz:",
    isAlreadyTranslated: true
  },
  "Please enter Overpack No. for the selected Reference ID": {
    text: "Lütfen seçilen Referans Kimliği için Dış Paket No. girin",
    isAlreadyTranslated: true
  },
  "Please enter Overpack No. between 1 to 999": {
    text: "Lütfen Dış Paket No.'yu 1 ile 999 arasında girin",
    isAlreadyTranslated: true
  },
  "Select Brand": {
    text: "Marka seçiniz",
    isAlreadyTranslated: true
  },
  "Search": {
    text: "Ara",
    isAlreadyTranslated: true
  },
  "Available Plans": {
    text: "Uygun Sigortalar",
    isAlreadyTranslated: true
  },
  "Please enter correct Mobile No": {
    text: "Lütfen geçerli bir Cep Numarası giriniz",
    isAlreadyTranslated: true
  },
  "Resend OTP in": {
    text: "Yeni KOD gönder",
    isAlreadyTranslated: true
  },
  "Months": {
    text: "Ay",
    isAlreadyTranslated: true
  },
  "Identity Number": {
    text: "T.C. Kimlik Numarası",
    isAlreadyTranslated: true
  },
  "Product Value": {
    text: "Ürünün Fiyatı",
    isAlreadyTranslated: true
  },
  "Maximum 30 Characters Allowed": {
    text: "En fazla 30 Karekter Girebilirsiniz",
    isAlreadyTranslated: true
  },
  "Please enter Identity Number": {
    text: "Lütfen T.C. Kimlik Numaranızı giriniz",
    isAlreadyTranslated: true
  },
  "Product Value cannot be less than 500": {
    text: "Ürün Tuturarı şundan az olamaz: 500",
    isAlreadyTranslated: true
  },
  "Please enter Reference Value": {
    text: "Lütfen Referans Numarasını giriniz",
    isAlreadyTranslated: true
  },
  "Existing Data Found": {
    text: "Mevcut Veri Bulundu",
    isAlreadyTranslated: true
  },
  "We have found existing data for this product and will use the same to recheck eligibility for this plan.": {
    text: "Bu ürün için var olan veriyi bulduk ve bu sigorta için aynı bilgiler ile uygunluğunu kontrol edeceğiz.",
    isAlreadyTranslated: true
  },
  "Understood": {
    text: "Anladım",
    isAlreadyTranslated: true
  },
  "Customer Mobile No": {
    text: "Müşteri Cep Numarası",
    isAlreadyTranslated: true
  },
  "Customer Email ID": {
    text: "Müşteri E-Posta Adresi",
    isAlreadyTranslated: true
  },
  "Identity Number": {
    text: "T.C. Kimlik No",
    isAlreadyTranslated: true
  },
  "Final Plan Price": {
    text: "Toplam Sigorta Tutarı",
    isAlreadyTranslated: true
  },
  "MAKE PAYMENT": {
    text: "ÖDEME AL",
    isAlreadyTranslated: true
  },
  "Payment": {
    text: "Ödeme",
    isAlreadyTranslated: true
  },
  "Select Payment Mode": {
    text: "Ödeme Türünü Seç",
    isAlreadyTranslated: true
  },
  "Full Payment": {
    text: "Tam Ödeme",
    isAlreadyTranslated: true
  },
  "Online Payment": {
    text: "Online Ödeme",
    isAlreadyTranslated: true
  },
  "Cards, Netbanking & More": {
    text: "Kredi Kartı, Banka Kartı ve Fazlası",
    isAlreadyTranslated: true
  },
  "Plan Validity": {
    text: "Sigorta Süresi",
    isAlreadyTranslated: true
  },
  "Date of Damage": {
    text: "Hasarın oluştuğu Tarih",
    isAlreadyTranslated: true
  },
  "Select brand": {
    text: "Marka seçiniz",
    isAlreadyTranslated: true
  },
  " Plans": {
    text: " Sigortaları",
    isAlreadyTranslated: true
  },
  "Activated": {
    text: "Aktifleştirildi",
    isAlreadyTranslated: true
  },
  "Congratulations! Your device is now registered and the plan is now activated for your device.": {
    text: "Tebrikler! Cihazınız kayıt edildi ve sigortanız cihazınız için etkinleştirildi.",
    isAlreadyTranslated: true
  },
  "Agreement No.": {
    text: "Sözleşme No.",
    isAlreadyTranslated: true
  },
  "Total Commission": {
    text: "Toplam Kazanç",
    isAlreadyTranslated: true
  },
  "Make Payment": {
    text: "Ödeme Yap",
    isAlreadyTranslated: true
  },
  "Payment Successful!": {
    text: "Ödeme Başarılı!",
    isAlreadyTranslated: true
  },
  "You will be redirected in": {
    text: "Yönlendiriliyorsunuz,",
    isAlreadyTranslated: true
  },
  "secs": {
    text: "saniye",
    isAlreadyTranslated: true
  },
  "If you are not redirected": {
    text: "Eğer yönlendirilmediyseniz",
    isAlreadyTranslated: true
  },
  "Click Here": {
    text: "Buraya Tıklayınız",
    isAlreadyTranslated: true
  }
};
export default lang;