import React,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import {filter,debounce} from 'lodash';
import {returnLocalDateTime,displayError} from '../../../../Utility/Utils';
import ConsumerRequestDetails from './ConsumerRequestDetails';

const ConsumerRequest = (props) => {
    const {localString,apiCall,ConsumerID} = props;
    const [serviceRequests, setServiceRequests] = useState([])
    const [requestLabelsArray, setRequestLabelsArray] = useState([])
    const [labelsDetail, setLabelsDetail] = useState()
    const [requestListSearch, setRequestListSearch] = useState("")
    const [filterData, setFilterData] = useState({
        IMEI:""
    })
    const [showLoader ,setLoader] = useState(false);

    const getRequests = () => {
        let data={};
        if(ConsumerID){
            data.ConsumerID = ConsumerID;
        }
        setLoader(true);
		apiCall({
			url:'getConsumerServiceRequestsByConsumerID',
			data: data,
			callBack:(result)=>{
				setLoader(false); 
				if(result.success){
                    setServiceRequests(result.data);
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			}	
		});
    }

    const getRequestListArr =()=> {
        if(serviceRequests && serviceRequests.length > 0) {
            let filteredList = filter(serviceRequests,(obj)=> {
                if(requestListSearch) {
                    return obj.consumerProduct && obj.consumerProduct.ProductUniqueID && obj.consumerProduct.ProductUniqueID.toLowerCase().indexOf(requestListSearch.toLowerCase()) > -1;
                } else {
                    return obj;
                }
            });
            setRequestLabelsArray(filteredList)
        }
    };

    const filterInputs = [{
            labelText:localString('Search by IMEI:'),
            dataKey:"IMEI",
            type:"input",
            showFilter:true,
            customClass:"search-wrapper col-xs-12 request",
            oninputFunc: (val) => {
                setFilterData({ ...filterData, ['IMEI'] : val  });
            }
        }]

    const searchRequestList = debounce((val)=>{
        setRequestListSearch(val)
    }, 200);

    useEffect(() => {
        searchRequestList(filterData.IMEI)
    }, [filterData.IMEI])

    const labelFunction=()=>{
        const defaultText= "-";
        let data = requestLabelsArray && requestLabelsArray.map(val=>{
            return [{
                value :(val && val.ReferenceID) || defaultText,
                show:true,
                customClass:"col-sm-2 col-xs-12 c-centered medium-width"
            },{
                show:true,
                value :(val && val.ProductName) || defaultText,
                customClass:"col-sm-2 col-xs-12 c-centered medium-width"
            },{
                show:true,
                value :(val && val.consumerProduct && val.consumerProduct.ProductUniqueID) || defaultText,
                customClass:"col-sm-2 col-xs-12 c-centered"
            },{
                show:true,
                value :(val && val.Status ) || defaultText,
                customClass:"col-sm-2 col-xs-12 c-centered"
            },{
                show:true,
                value :(val && returnLocalDateTime(val.ScheduledDateTime,"YYYY-MM-DD | HH:mm:ss") ) || defaultText,
                customClass:"col-sm-2 col-xs-12 c-centered medium-width"
            },{ 
                show:true,
                value :(val && val.Type ) || defaultText,
                customClass:"col-sm-2 col-xs-12 c-centered"
            },{
                show:false,
                consumerServiceRequestID : val && val.ConsumerServiceRequestID 
            }]
        })
        setLabelsDetail(data);
    }

    const headings = [
        {
            title:localString("Reference ID"),
            customClass:"col-sm-2 col-xs-12 c-centered medium-width"
        },
        {
            title:localString("Device"),
            customClass:"col-sm-2 col-xs-12 c-centered medium-width"
        },
        {
            title:localString("IMEI"),
            customClass:"col-sm-2 col-xs-12 c-centered"
        },
        {
            title:localString("Status"),
            customClass:"col-sm-2 col-xs-12 c-centered"
        },
        {
            title:localString("Scheduled Date"),
            customClass:"col-sm-2 col-xs-12 c-centered medium-width"
        },
        {
            title:localString("Type"),
            customClass:"col-sm-2 col-xs-12 c-centered"
        },
    ]

    const clearButton=()=>{
        setFilterData({ IMEI:""})
        setRequestListSearch("");
    }

    useEffect(() => {
        getRequestListArr();
    }, [requestListSearch,serviceRequests])

    useEffect(() => {
        labelFunction()
    }, [requestLabelsArray])

    useEffect(() => {
        getRequests()
    }, [])

    return (
        <>
        { showLoader && ReactDOM.createPortal(
            <Loader />,
            document.getElementById("loader-root")
		)}
        <ConsumerRequestDetails {...props} headings={headings} requestLabelsArray={labelsDetail} filterInputs={filterInputs} clearSearch={clearButton} filterData={filterData}></ConsumerRequestDetails>    
        </>
    )
}

export default ConsumerRequest
