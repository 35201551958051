import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Loader, Button, Modal, Input, Dropdown } from 'sfy-react';
import PageHeader from '../../../components/PageHeader/PageHeader';
import PlanConsumerDetails from './PlanConsumerDetails';
import { returnLocalDateTime, displayError, checkEmail, checkMobile, getLocalDateTime } from '../../../Utility/Utils';
import ActionButtonList from '../../../components/ActionButtonsList/ActionButtonList';
import EditConsumerDetails from './EditConsumerDetails';
import Swal from "sweetalert2";
import './ViewConsumerPlanDetailsPage.scss'
import PlanNavBar from './PlanNavigationBar/PlanNavBar';
import CallHistoryModal from '../../Consumer/ConsumerList/CallHistoryModal';

const ViewConsumerDetailsPage = (props) => {
	const { spid, titleHeader, apiCall, localString, accessFn } = props;
	const qFilter = JSON.parse(props.qFilter);
	const [showLoader, setLoader] = useState(false);
	const [countryDataLoader, setCountryDataLoader] = useState(false);
	const [consumerDetails, setConsumerDetails] = useState({});
	const [consumerDetailArray, setConsumerDetailArray] = useState([]);
	const [showHighRiskReasons, setShowHighRiskReasons] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [consumerPlanDetails, setConsumerPlanDetails] = useState([])
	const [editSaveData, setEditSaveData] = useState({});
	const [planListData, setPlanListData] = useState({});
	const editSaveDataRef = useRef(editSaveData);
	const [buttonConditions, setbuttonConditions] = useState({
		showInitiateButton: false,
		showActivationCodeButton: false,
		showApproveButton: false,
		showRejectButton: false,
		showEditButton: false
	});
	const [buttonArray, setButtonArray] = useState([]);
	const [paymentDetails, setPaymentDetails] = useState([])
	let PlanID = "";
	const [singleCountry, setSingleCountry] = useState({});
	const [callLog, setCallLog] = useState(false);
	const [showCallLogPage, setShowCallLogPage] = useState(false);
	const [consumerID, setConsumerID] = useState("")
	const [soldPlanDocuments, setSoldPlanDocuments] = useState()
	const [pagination , setPagination] = useState({
		currentPage: 1,
		itemsPerPage: 5,
		maxPages: 5,
		totalItems:0,
		lastPage:0
	});
	const [partnerServiceLocation, setPartnerServiceLocation] = useState([]);
	const [reasonsList, setReasonsList] = useState([]);
	const [TDDRemark, setTDDRemark] = useState("")
	const [remarkCount, setRemarkCount] = useState(0)
	const [TDDRemarkModal, setTDDRemarkModal] = useState(false);
	const [TDDButtonClicked, setTDDButtonClicked] = useState("")
	const [selectedReason, setSelectedReason] = useState("")
	const [loggedInUserSpicificCountryData, setLoggedInUserSpicificCountryData] = useState({});
	const _ = require("lodash");

	useEffect(() => {
		if(TDDRemark){
			let len = TDDRemark.length;
			setRemarkCount(len)
		}else{
			setRemarkCount(0)
		}
	}, [TDDRemark])
	
	const defaultText = localString("Not Updated");

	const consumerViewDetails = [
		{
			labelText:localString("Name"),
			value: consumerPlanDetails && ((consumerPlanDetails.FirstName) + ((consumerPlanDetails.LastName) || '')) || defaultText,
			showLabel: true
		},
		{
			labelText:localString("Mobile No"),
			value: consumerPlanDetails && consumerPlanDetails.MobileNo || defaultText,
			showLabel: true
		},
		{
			labelText:localString("Email ID"),
			value: consumerPlanDetails && consumerPlanDetails.EmailID || defaultText,
			showLabel: true
		},
		{
			labelText:localString("Alternate Mobile No"),
			value: consumerPlanDetails && consumerPlanDetails.AlternateMobileNo || defaultText,
			showLabel: true
		},
		{
			labelText:localString(""),
			value: "",
			showLabel: false
		}
	]

	const statusText = {
		'0': 'Activation Pending',
		'1': 'Active',
		'2': 'Activation Pending',
		'3': 'Cancelled',
		'4': 'Cancelled',
		'5': 'Plan Upgraded',
		'8': 'Activation Pending due to diagnostic',
		'9': 'Activation Pending due to payment',
		'10': 'Benefits Availed',
		'-1': 'N.A',
		'-2': 'Eligible for Purchase',
		'-3': 'Expired',
		'-4': 'Cancelled',
		'-5': 'Plan Downgraded',
		'-6': 'Plan Transferred',
		'-9': 'Plan On Hold'
	}

	const getIndividualConsumerDetails = () => {
		let data = {
			SoldPlanID: spid
		};
		setLoader(true);
		apiCall({
			url: 'getSoldPlanCustomersData',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					setConsumerDetails(result.data)
					let consumerData = result.data && result.data.PlanDetails[0];
					setConsumerPlanDetails(consumerData)
					PlanID = consumerData.PlanID;
					getCountries(consumerData);
					// let consumerData = result.data && result.data.soldPlans[0];
					// PlanID = consumerData.PlanID;
					// getCountries(consumerData);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	};

	const getSoldPlanDocumentsWithImages = () => {
		const countryID = consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].CountryID
		let data = {
			"SoldPlanID": spid,    
			"LanguageCode": qFilter?.languagecode?.length ? qFilter.languagecode[0] : "en",
			"CountryID": countryID
		};
		setLoader(true);
		apiCall({
			url: 'getSoldPlanDocumentsWithImages',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					let resp = result.data;
					setSoldPlanDocuments(resp);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}; 

	const getDiagnosisRejectionManual = (reactivationFlow) => {
		const countryID = consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].CountryID
		let data = {
			"SoldPlanID": spid,
			"CountryID": countryID,
			"ContractDecisionRemarks": TDDRemark,
			"ContractDecisionReason": selectedReason
		};
		if (reactivationFlow){
			data.reactivation = true
		};
		setLoader(true);
		apiCall({
			url: 'diagnosisRejectionManual',
			data: data,
			callBack: (result) => {
				closeRemarkModal();
				setLoader(false);
				if (result.success && result.data) {
					Swal.fire({
						icon: "success",
						text: localString(reactivationFlow ? "Reactivation Request has been successfully cancelled against this contract." : "The contract has been cancelled") ,
						confirmButtonText: localString("Okay")
					}).then((result) => {
						if (result.isConfirmed) {
							Swal.close();
						}
					})
					getIndividualConsumerDetails();
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	};

	const diagnosisApprove = () => {
		const countryID = consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].CountryID;
		const planID = consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].PlanID;
		let data = {
			"SoldPlanID": spid,
			"CountryID": countryID,
			"sendSlackAlert": true,
			"PlanID": planID,
			"ContractDecisionRemarks": TDDRemark,
			"ContractDecisionReason": selectedReason
		};
		setLoader(true);
		apiCall({
			url: 'fetchVerifyAndUpdateSoldPlan',
			data: data,
			callBack: (result) => {
				setLoader(false);
				closeRemarkModal();
				if (result.success && result.data) {
					Swal.fire({
						icon: "success",
						text: localString("The contract has been activated."),
						confirmButtonText: localString("Okay")
					}).then((result) => {
						if (result.isConfirmed) {
							Swal.close();
						}
					})
					getIndividualConsumerDetails()
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	};

	const reactivateCancelledSoldPlan = () => {
		const countryID = consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].CountryID;
		const planID = consumerDetails && consumerDetails.PlanDetails && consumerDetails.PlanDetails[0].PlanID;
		let data = {
			"SoldPlanID": spid,
			"CountryID": countryID,
			"sendSlackAlert": true,
			"PlanID": planID,
			"ContractDecisionRemarks": TDDRemark,
			"ContractDecisionReason": selectedReason
		};
		setLoader(true);
		apiCall({
			url: 'activateCancelledSoldPlan',
			data: data,
			callBack: (result) => {
				setLoader(false);
				closeRemarkModal();
				if (result.success) {
					Swal.fire({
						icon: "success",
						text: localString(result.msg),
						confirmButtonText: localString("Okay")
					}).then((result) => {
						if (result.isConfirmed) {
							Swal.close();
						}
					})
					getIndividualConsumerDetails()
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	};

	const getPaymentDetails = (pageNumber) => {
		let data = {
			"SoldPlanID": spid,
			"pagination": {
				currentPage: pageNumber,
				itemsPerPage: pagination.itemsPerPage,
				maxPages: pagination.maxPages,
				totalItems: pagination.totalItems,
				lastPage: pagination.lastPage
			}
		};
		setLoader(true);
		apiCall({
			url: 'getPaymentDetailsAegis',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					let paymentData = result.data;
					setPagination({...pagination,
						totalItems: result.data && result.data.pagination && result.data.pagination.totalItems,
						lastPage: result.data && result.data.pagination && result.data.pagination.maxPages,
						currentPage: result.data && result.data.pagination && result.data.pagination.currentPage
					})
					setPaymentDetails(paymentData)
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	};

	const getReasonsList = (action) => {
		let data = {
			"ActionType": (action == "Approve" ? "APPROVAL" : "REJECTION")
		}
		setLoader(true);
		apiCall({
			url: 'fetchDiagnosisReasons',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					let reasons = result.data.Reasons && result.data.Reasons.map(reason=>({value:reason}));
					setReasonsList(reasons)
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	};

	const getPartnerServiceLocation = (PSLID) => {
		let data = {
			serviceLocations: [PSLID]
		}
		setLoader(true);
		apiCall({
			url:'serviceCenterList',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success && result.data){
					let PSLData = result.data;
					setPartnerServiceLocation(PSLData)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const setCurrentPage = (pageNo) => {
		getPaymentDetails(pageNo)
	}

	useEffect(() => {
		getIndividualConsumerDetails();
		getPaymentDetails(1);
		getCountries();
	}, []);

	useEffect(() => {
		setConsumersData();
		if(consumerDetails && consumerDetails.PlanDetails){
			checkButtonConditions(consumerDetails.PlanDetails[0]);
			consumerDetails.PlanDetails[0].PartnerServiceLocationID && 
			getPartnerServiceLocation(consumerDetails.PlanDetails[0].PartnerServiceLocationID)
		}
	}, [consumerDetails]);

	const getCountries = (consumerData) => {
		let data = {
			CountryID: consumerData?.CountryID ? consumerData?.CountryID : undefined,
			countryConfig: true
		};
		if(!consumerData) data.specificCountryCode = (qFilter?.countrycode?.length) ? qFilter.countrycode[0].toUpperCase() : 'IN'
		consumerData ? setLoader(true) : setCountryDataLoader(true);
		apiCall({
			url: 'getCountries',
			data: data,
			callBack: (result) => {
				consumerData ? setLoader(false) : setCountryDataLoader(false);
				if (result.success && result.data && result.data.countries) {
					consumerData ? setSingleCountry(result.data.countries[0]) : setLoggedInUserSpicificCountryData(result.data.countries[0]);
					// setConsumerDetails(consumerData);
					// checkButtonConditions(consumerData);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	};

	const getAddressLabel = (keyName) => {
		if (singleCountry && singleCountry.countryConfig && singleCountry.countryConfig.addressConfig) {
			let addressLabel = _.find(singleCountry.countryConfig.addressConfig, (obj) => {
				return obj.keyName === keyName;
			});
			if (!addressLabel) {
				if (keyName === 'AddressLine1') {
					addressLabel = _.find(singleCountry.countryConfig.addressConfig, (obj) => {
						return obj.keyName === 'Address';
					});
				}
				return (addressLabel && addressLabel.label) || keyName;
			} else {
				return (addressLabel && addressLabel.label) || keyName;
			}
		} else {
			return keyName;
		}
	};

	const checkButtonConditions = (consumerData) => {
		let showInitiateButton = false, showActivationCodeButton = false, showRejectButton = false, showApproveButton = false, showEditButton = false;
		if (consumerData && consumerData.StatusTextObject) {
			if (accessFn('INITIATE_REFUND') && (consumerData.StatusTextObject.status !== 3 && consumerData.StatusTextObject.status !== 4) && (consumerData.Source && ['OnePlus', 'OnePlus-Store'].indexOf(consumerData.Source) > -1 && consumerData.OrderID && consumerData.PaymentID === null)) {
				showInitiateButton = true;
			} else {
				showInitiateButton = false;
			}
		}
		if (consumerData && consumerData.Status == 0 && consumerData.plan && (['OP3TPROTECT', 'OP3TSECURE6', 'OP3TSECURE12', 'OP5PROTECT12', 'OP5PROTECT18', 'OP5PROTECT24', 'OP5SECURE12', 'OP5SECURE24', 'OP5TPROTECT12', 'OP5TSECURE12', 'OP5TSECURE18', 'OP5TSECURE24', 'MI599', 'MI799', 'MI1699', 'MI1199', 'MI999'].indexOf(consumerData.plan.PlanCode) > -1)) {
			showActivationCodeButton = true;
		} else {
			showActivationCodeButton = false;
		}
		if (consumerData && accessFn('APPROVE_PLAN') && (consumerData.Status == 2 || (consumerData.PlanID == 4 && consumerData.Status == 0))) {
			showRejectButton = true;
		} else {
			showRejectButton = false;
		}
		if (consumerData && accessFn('APPROVE_PLAN') && (consumerData.Status == 2 || (consumerData.PlanID == 4 && consumerData.Status == 0))) {
			showApproveButton = true;
		} else {
			showApproveButton = false;
		}
		if (consumerData && consumerData.IsEditable) {
			showEditButton = true;
		} else {
			showEditButton = false;
		}
		setbuttonConditions({
			...buttonConditions,
			showInitiateButton: showInitiateButton,
			showActivationCodeButton: showActivationCodeButton,
			showRejectButton: showRejectButton,
			showApproveButton: showApproveButton,
			showEditButton: showEditButton
		});
	}

	useEffect(() => {
		setButtonList();
	}, [buttonConditions, showEdit]);

	const setConsumersData = () => {
		let defaultText = localString("Not Updated");
		let consumerDetailArray = [
			{
				label: (localString("Contract ID")),
				value: (consumerDetails && consumerDetails.SoldPlanCode) || defaultText
			},
			{
				label: (localString("Plan SKU")),
				value: (consumerDetails && consumerDetails.ExternalPlanCode) || defaultText
			},
			{
				label: (localString("Plan Name")),
				value: (consumerDetails && consumerDetails.plan && consumerDetails.plan.PlanName) || defaultText
			},
			{
				label: localString("Plan Price"),
				CurrencySymbol: (consumerDetails && consumerDetails.PlanAmount && consumerDetails.CurrencySymbol) || "",
				value: (consumerDetails && consumerDetails.PlanAmount) || defaultText
			},
			{
				label: (localString("Plan Purchase Date")),
				value: (consumerDetails && consumerDetails.DateOfPurchase && returnLocalDateTime(consumerDetails.DateOfPurchase, 'DD-MM-YYYY')) || defaultText
			},
			{
				label: localString("Plan Activation Date"),
				value: (consumerDetails && consumerDetails.DateOfActivation && returnLocalDateTime(consumerDetails.DateOfActivation, 'DD-MM-YYYY')) || defaultText
			},
			{
				label: localString("Plan Expiry Date"),
				value: (consumerDetails && consumerDetails.ValidityDate && returnLocalDateTime(consumerDetails.ValidityDate, 'DD-MM-YYYY')) || defaultText
			},
			{
				label: localString("Plan Status"),//TODO update here
				value: (statusText[consumerDetails && consumerDetails.Status]) || 'No such status found'
			},
			{
				label: localString("Sales Channel"),
				value: (consumerDetails && consumerDetails.Source) || defaultText
			},
			{
				label: localString("Retailer Name"),
				value: (consumerDetails && consumerDetails.ServiceLocationName) || defaultText
			},
			{
				label: localString("Partner Transaction ID"),
				value: (consumerDetails && consumerDetails.ExternalReferenceID) || defaultText
			},
			{
				label: localString("Order ID"),
				value: (consumerDetails && consumerDetails.OrderID) || defaultText
			},
			{
				label: localString("Servify Payment ID"),
				value: (consumerDetails && consumerDetails.PaymentID) || defaultText
			},
			{
				label: localString("Payment Gateway Transaction ID"),
				value: (consumerDetails && consumerDetails.GatewayPaymentID) || defaultText
			},
			{
				label: (localString("Customer Name")),
				value: ((consumerDetails && consumerDetails.FirstName) + ((consumerDetails && consumerDetails.LastName) || '')) || defaultText
			},
			{
				label: (localString("Email id")),
				value: (consumerDetails && consumerDetails.EmailID) || defaultText
			},
			{
				label: (localString("Mobile number")),
				value: (consumerDetails && consumerDetails.MobileNo) || defaultText
			},
			{
				label: localString("Alternate number"),
				value: (consumerDetails && consumerDetails.AlternateMobileNo) || defaultText
			},
			{
				label: localString(getAddressLabel("AddressLine1")),
				value: (consumerDetails && consumerDetails.AddressLine1) || defaultText
			},
			{
				label: localString(getAddressLabel("AddressLine2")),
				value: (consumerDetails && consumerDetails.AddressLine2) || defaultText
			},
			{
				label: localString(getAddressLabel("Landmark")),
				value: (consumerDetails && consumerDetails.Landmark) || defaultText
			},
			{
				label: localString(getAddressLabel("City")),
				value: (consumerDetails && consumerDetails.City) || defaultText
			},
			{
				label: localString(getAddressLabel("State")),
				value: (consumerDetails && consumerDetails.State) || defaultText
			},
			{
				label: localString(getAddressLabel("PinCode")),
				value: (consumerDetails && consumerDetails.ZipCode) || defaultText
			},
			{
				label: localString("Documents"),
				linkArray: (consumerDetails && consumerDetails.docArray) || [],
				showLinks: true,
				noLinkText: "Documents not uploaded"
			},
			{
				label: localString("IMEI No.1"),
				value: (consumerDetails && consumerDetails.ProductUniqueID) || defaultText
			},
			{
				label: localString("IMEI No.2"),
				value: (consumerDetails && consumerDetails.AlternateUniqueKey) || defaultText
			},
			{
				label: localString("Device Date Of Purchase"),
				value: (consumerDetails && consumerDetails.DeviceDateOfPurchase && returnLocalDateTime(consumerDetails.DeviceDateOfPurchase, 'DD-MM-YYYY')) || defaultText
			},
			{
				label: localString("Device Purchase Price"),
				CurrencySymbol: (consumerDetails && consumerDetails.ProductPurchaseCost && consumerDetails.CurrencySymbol) || "",
				value: (consumerDetails && consumerDetails.ProductPurchaseCost) || defaultText
			},
			{
				label: localString("Model No"),
				value: (consumerDetails && consumerDetails.ModelNo) || defaultText
			}
		]
		if (consumerDetails && consumerDetails.HighRiskReason && consumerDetails.HighRiskReason.length > 0) {
			let reasonsList = consumerDetails.HighRiskReason.map((reason, index) => {
				return reason.RaisedFlagReasonDescription;
			});
			consumerDetailArray.push({
				label: (localString("High Risk Reason(s)")),
				value: reasonsList || defaultText,
				sectionId: "reasons"
			});
			setShowHighRiskReasons(true);
		}
		setConsumerDetailArray(consumerDetailArray);
	}

	const setButtonList = () => {
		let buttonList = [];
		if (buttonConditions.showInitiateButton) {
			buttonList = [...buttonList, {
				text: localString("Initiate Refund"),
				onClickAction: () => {
					initiatePlanRefund();
				}
			}]
		}
		if (!showEdit) {
			if (buttonConditions.showActivationCodeButton) {
				buttonList = [...buttonList, {
					text: localString("Resend Activation Code"),
					onClickAction: () => {
						resendActivationCode();
					}
				}]
			}
			if (buttonConditions.showRejectButton) {
				buttonList = [...buttonList, {
					text: localString("Reject"),
					onClickAction: () => {
						rejectClick();
					}
				}]
			}
			if (buttonConditions.showApproveButton) {
				buttonList = [...buttonList, {
					text: localString("Approve"),
					onClickAction: () => {
						approveClick();
					}
				}]
			}
			// if(buttonConditions.showEditButton){
			// 	buttonList = [...buttonList,{
			// 		text:localString("Edit"),
			// 		onClickAction:()=>{
			// 			editClick();
			// 		}
			// 	}]
			// }
		} else {
			buttonList = [...buttonList, {
				text: localString("Cancel"),
				onClickAction: () => {
					cancelClick();
				}
			}, {
				text: localString("Save"),
				onClickAction: () => {
					saveplanconsumerdetails();
				}
			}]
		}
		setButtonArray(buttonList);
	}
	const initiatePlanRefund = () => {
		let data = {
			"SoldPlanID": consumerDetails.SoldPlanID
		}
		setLoader(true);
		apiCall({
			url: 'initiateRefund',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					Swal.fire({
						icon: 'success',
						text: localString("Refund has been initiated")
					})
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});

	}
	const resendActivationCode = () => {
		let data = {
			"SoldPlanID": consumerDetails.SoldPlanID
		}
		setLoader(true);
		apiCall({
			url: 'resendActivationCode',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					Swal.fire({
						icon: 'success',
						text: localString(displayError(result)),
					})
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}
	const rejectClick = () => {
		Swal.fire({
			title: localString('Enter rejection reason?'),
			input: "text",
			showCancelButton: true,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("Ok"),
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.close();
				let remark = result.value || "";
				if (remark) {
					planStatus(consumerDetails.SoldPlanID, -1, remark);
				} else {
					Swal.fire({
						title: localString('Oops...'),
						text: localString('Please enter rejection reason before submitting'),
						icon: 'error',
					}).then((result) => {
						if (result.isConfirmed) {
							rejectClick();
						}
					})
				}
			}
		})
	}
	const approveClick = () => {
		Swal.fire({
			title: localString('Are you sure?'),
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText: localString("Cancel"),
			confirmButtonText: localString("Ok"),
			allowOutsideClick: false,
			allowEscapeKey: false
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.close();
				planStatus(consumerDetails.SoldPlanID, 1);
			}
		})
	}
	const editClick = () => {
		setShowEdit(true);
		setEditSaveData({
			FirstName: consumerDetails.FirstName,
			LastName: consumerDetails.LastName,
			EmailID: consumerDetails.EmailID,
			MobileNo: consumerDetails.MobileNo,
			AlternateMobileNo: consumerDetails.AlternateMobileNo,
			OrderID: consumerDetails.OrderID,
			DateOfPurchase: consumerDetails.DateOfPurchase,
			PlanName: consumerDetails.PlanName,
			AddressLine1: consumerDetails.AddressLine1,
			AddressLine2: consumerDetails.AddressLine2,
			Landmark: consumerDetails.Landmark,
			City: consumerDetails.City,
			State: consumerDetails.State,
			ZipCode: consumerDetails.ZipCode,
			PlanID: consumerDetails.PlanID
		});
		loadPlans();
	}
	const cancelClick = () => {
		setShowEdit(false);
		setEditSaveData({});
	}
	useEffect(() => {
		editSaveDataRef.current = editSaveData;
	}, [editSaveData]);

	const loadPlans = () => {
		setLoader(true);
		apiCall({
			url: 'getPlanList',
			data: {},
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					let data = result.data.map(v => ({ ...v, value: v.PlanName }))
					setPlanListData(data);
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}
	let editLabels = [
		{
			labelText: localString('First Name'),
			dataKey: "FirstName",
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['FirstName']: val });
			}
		},
		{
			labelText: localString('Last Name'),
			dataKey: "LastName",
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['LastName']: val });
			}
		},
		{
			labelText: localString('Email ID') + "*",
			dataKey: "EmailID",
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['EmailID']: val });
			}
		},
		{
			labelText: localString('Mobile Number') + '*',
			dataKey: "MobileNo",
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['MobileNo']: val });
			}
		},
		{
			labelText: localString('Alternate Number'),
			dataKey: "AlternateMobileNo",
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['AlternateMobileNo']: val });
			}
		},
		{
			labelText: localString('Order ID'),
			dataKey: "OrderID",
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['OrderID']: val });
			}
		},
		{
			labelText: localString('Order Date') + "*",
			dataKey: "DateOfPurchase",
			type: "DatePicker",
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['DateOfPurchase']: (getLocalDateTime(val, 'YYYY-MM-DDTHH:mm:ss') + '+00:00') });
			}
		},
		{
			labelText: localString('Plan Purchased') + "*",
			dataKey: "PlanName",
			type: 'Dropdown',
			oninputFunc: (val) => {
				setEditSaveData({
					...editSaveData,
					['PlanName']: val.PlanName,
					['PlanID']: val.PlanID
				});
			}
		},
		{
			labelText: localString('Address Line 1'),
			dataKey: "AddressLine1",
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['AddressLine1']: val });
			}
		},
		{
			labelText: localString('Address Line 2'),
			dataKey: "AddressLine2",
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['AddressLine2']: val });
			}
		},
		{
			labelText: localString('Landmark'),
			dataKey: "Landmark",
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['Landmark']: val });
			}
		},
		{
			labelText: localString('City'),
			dataKey: "City",
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['City']: val });
			}
		},
		{
			labelText: localString('State'),
			dataKey: "State",
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['State']: val });
			}
		},
		{
			labelText: localString('Pincode'),
			dataKey: "ZipCode",
			oninputFunc: (val) => {
				setEditSaveData({ ...editSaveData, ['ZipCode']: val });
			}
		}
	];
	const saveplanconsumerdetails = () => {
		let isValidated = validateForm();
		if (isValidated) {
			let data = {
				"SoldPlanID": consumerDetails.SoldPlanID,
				"UpdateObject": editSaveDataRef.current
			};
			setLoader(true);
			apiCall({
				url: 'modifyCustomerDetails',
				data: data,
				callBack: (result) => {
					setLoader(false);
					if (result.success && result.data) {
						getIndividualConsumerDetails();
						setShowEdit(false);
						Swal.fire({
							icon: 'success',
							text: localString(displayError(result))
						})
					} else {
						Swal.fire({
							icon: 'error',
							text: localString(displayError(result)),
						});
					}
				}
			});
		}
	}
	const validateForm = () => {
		let isValidated = true;
		if (isValidated) {
			isValidated = false;
			if (editSaveDataRef.current.EmailID == '' || editSaveDataRef.current.EmailID == null) {
				Swal.fire({
					icon: 'warning',
					text: localString('Enter Email ID'),
				});
				return;
			} else {
				if (!checkEmail(editSaveDataRef.current.EmailID)) {
					Swal.fire({
						icon: 'warning',
						text: localString('Enter correct Email ID'),
					});
					return;
				}
			}
			if (editSaveDataRef.current.MobileNo == '' || editSaveDataRef.current.MobileNo == null) {
				Swal.fire({
					icon: 'warning',
					text: localString('Enter Mobile Number'),
				});
				return;
			} else {
				if (!checkMobile(editSaveDataRef.current.MobileNo)) {
					Swal.fire({
						icon: 'warning',
						text: localString('Enter correct Mobile Number'),
					});
					return;
				}
			}
			if (editSaveDataRef.current.AlternateMobileNo && editSaveDataRef.current.AlternateMobileNo.length && !checkMobile(editSaveDataRef.current.AlternateMobileNo)) {
				Swal.fire({
					icon: 'warning',
					text: localString('Enter correct Alternate Number'),
				});
				return;
			}
			if (editSaveDataRef.current.DateOfPurchase == '' || editSaveDataRef.current.DateOfPurchase == null) {
				Swal.fire({
					icon: 'warning',
					text: localString('Enter Order Date'),
				});
				return;
			}
			if (editSaveDataRef.current.PlanID == '' || editSaveDataRef.current.PlanID == null) {
				Swal.fire({
					icon: 'warning',
					text: localString('Select a Plan Name'),
				});
				return;
			}
			isValidated = true;
		}
		return isValidated;
	}
	const planStatus = (soldPlanID, approveReject, remark) => {
		let data = {
			"SoldPlanID": soldPlanID,
			"Status": approveReject,
			"Remarks": remark || undefined
		}
		setLoader(true);
		apiCall({
			url: 'updateSoldPlan',
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success && result.data) {
					getIndividualConsumerDetails();
					setShowEdit(false);
					Swal.fire({
						icon: 'success',
						text: localString(displayError(result))
					})
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	};

	const callLogFunction = (val)=>{
		setCallLog(true);
		setShowCallLogPage(true);
		setConsumerID(val);
	}

	const getRemark = (btnName) => {
		setSelectedReason("");
		setReasonsList([]);
		setTDDRemarkModal(true);
		setTDDButtonClicked(btnName);
		getReasonsList(btnName);
	}

	const closeModal = () =>{
		setShowCallLogPage(false);
	}

	const closeRemarkModal = () => {
		setTDDRemarkModal(false)
		setTDDRemark("")
		setTDDButtonClicked("");
		setSelectedReason("");
		setReasonsList([])
	}

	return (
		<>
			{(showLoader || countryDataLoader) && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<PageHeader titleHeader={titleHeader} showLink={showHighRiskReasons} localString={localString} linkClass="plan-risk" hrefID="#reasons" linkText={localString("High Risk")}></PageHeader>
			{/* {!showEdit && <PlanConsumerDetails consumerDetailArray={consumerDetailArray} {...props}></PlanConsumerDetails>}
			{showEdit && <EditConsumerDetails {...props} editLabels={editLabels} editSaveData={editSaveData} planListData={planListData}></EditConsumerDetails>}
			<ActionButtonList buttonArray={buttonArray}></ActionButtonList> */}
			<div className="plan-management">
				<div className="content plan-consumer-details">
					{
						consumerViewDetails && consumerViewDetails.map((data,index)=>{
							return(
								<div className="consumer-padding">
									<div className="col-sm-6 col-xs-12">
										<div className="form-group">
											<div className="row">
												{
													data.showLabel && 
													<>
														<div className="col-sm-4 col-xs-6">
															<label className="consumer_details_name">{data.labelText} :</label>
														</div>
														<div className="col-sm-8 col-xs-6">
															<p>{data.value}</p>
														</div>
													</>
												}
											</div>
										</div>
									</div>
								</div>
							)
						})
					}
					{
						consumerPlanDetails && 
						((accessFn('TWO_DEVICE_DIAGNOSIS') && consumerPlanDetails?.Status === 13) || 	
						(accessFn('REACTIVATION_UNDER_WARRANTY') && consumerPlanDetails?.Status === 16)) &&
						<div className="plan-management-2DD-diagnosis col-sm-3">
							<div className='diagnosis-buttons'>
								<Button 
									className= "btn button-servify" 
									type= 'submit' 
									text= {localString('Approve Contract')} 
									handleOnClick= {(e)=>{e.preventDefault(); getRemark("Approve")}}
								/>
								<Button 
									className= "btn button-servify" 
									type= 'submit' 
									text= {localString('Reject Contract')} 
									handleOnClick= {(e)=>{e.preventDefault(); getRemark("Reject")}}
								/>
							</div>
						</div>
					}
					{
						TDDRemarkModal && 
						<Modal showModal={TDDRemarkModal} closeModal={closeRemarkModal} showCloseBtn={true} className='tdd-remark-Modal'>
							<Modal.Header>
								<h2> {localString("Please share the reason for" + (TDDButtonClicked == "Approve" ? " approval " : " rejection ") + "in detail")} </h2>
							</Modal.Header>
							<Modal.Body>
								<div className='remark-body'>
									<label className="reason-dropdown-label">
										{localString(`${TDDButtonClicked == "Approve" ? "Select Approval Reason" : "Select Rejection Reason"}`)}
									</label>
									<Dropdown
										id='dropdown'
										value={selectedReason}
										options={reasonsList}
										handleOnChange={(val) => {
											setSelectedReason(val.value)
										}}
										filterBy='value'
									/>
									<label className="reason-textarea-label">
										{localString("Enter Detailed Description")}
									</label>
									<Input
										labelAlign='top'
										value={TDDRemark}
										placeholder={localString("Add details here")}
										handleOnChange={(e) => setTDDRemark(e.target.value)}
										id='remark-input'
										// maxLength={255}
										className= { remarkCount >= 255 && {
											inputContainer: "danger-textarea-border"
										}}
										inputComponent="textarea"
										otherProps={{rows:"6", cols:"8"}}
									/>
									<div className={remarkCount >= 255 ? 'count-status' : "count"}>
										{remarkCount >= 255 && 
											<p style={{color:"red"}}>
												{localString("Maximum characters allowed is 255.")}
											</p>}
										<p style={remarkCount >=255 ? {color: '#F54336', float:'right'} : {float:'right'}}>
											{remarkCount+"/255"}
										</p>
									</div>
								</div>
								<div style={{float: "right", margin: "10px 10px 0px 0px"}}>
									<Button 
										className= "btn button-servify tdd-submit-cta" 
										type= 'buttton' 
										text= {localString('Cancel')} 
										handleOnClick= {closeRemarkModal}
									/>
									<Button 
										className= {"btn button-servify tdd-submit-cta " + `${(remarkCount == 0 || remarkCount > 255 || selectedReason == null || selectedReason == "") ? " disable-bg-color" : "active-bg-color"}`} 
										type= 'submit' 
										text= {localString('Submit')} 
										isDisabled={remarkCount == 0 || remarkCount > 255 || selectedReason == null || selectedReason == ""}
										handleOnClick= {(e)=>{
											e.preventDefault(); 
											if(TDDButtonClicked == "Approve"){
												if (accessFn('REACTIVATION_UNDER_WARRANTY') && (consumerPlanDetails && (consumerPlanDetails.Status === 16))) {
													reactivateCancelledSoldPlan();
												} else {
													diagnosisApprove();
												}
											}else{
												if (accessFn('REACTIVATION_UNDER_WARRANTY') && (consumerPlanDetails && (consumerPlanDetails.Status === 16))) {
													getDiagnosisRejectionManual(true);
												} else {
													getDiagnosisRejectionManual();
												}
											}
										}}  
									/>
								</div>
							</Modal.Body>
							<Modal.Footer></Modal.Footer>
						</Modal>
					}
					{
						<div className="plan-management-callLog">
							<Button className="btn button-servify icon-hover" >
								{
									accessFn('HIDE_CONSUMER_CALL_LOGS') ? '-':<div className="communication-icon" onClick={()=>callLogFunction(consumerPlanDetails.ConsumerID)}></div> 
								}
							</Button>
						</div>
					}
				</div>
				<div className="plan-management-navigation">
					<PlanNavBar 
						{...props}
						consumerDetails= {consumerDetails}
						paymentDetails = {paymentDetails} 
						buttonArray={buttonArray}
						getSoldPlanDocumentsWithImages= {getSoldPlanDocumentsWithImages}
						soldPlanDocuments= {soldPlanDocuments}
						pagination= {pagination}
						setCurrentPage= {setCurrentPage}
						singleCountry={singleCountry}
						loggedInUserSpicificCountryData={loggedInUserSpicificCountryData}
						partnerServiceLocation= {partnerServiceLocation}
					/>
				</div>
				{callLog && <CallHistoryModal {...props} showCommunicationLogs={true} showCallHistory={true} showEmailHistory={false} showPage={showCallLogPage} closeModal={closeModal} consumerID={consumerID} ></CallHistoryModal>}
			</div>
		</>
	)

}
export default ViewConsumerDetailsPage;