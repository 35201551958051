const lang = {
  "Username": {
    "text": "Username",
    "isAlreadyTranslated": true
  },
  "Forgot Password?": {
    "text": "Lupa Kata Sandi",
    "isAlreadyTranslated": true
  },
  "Next": {
    "text": "Berikutnya",
    "isAlreadyTranslated": true
  },
  "No symptoms added": {
    "text": "Tidak ada kerusakan yang ditambahkan",
    "isAlreadyTranslated": true
  },
  "Logistics - From Customer's Address To Service Center": {
    "text": "Logistik - Dari Alamat Pelanggan Ke Service Center",
    "isAlreadyTranslated": true
  },
  "Waybill": {
    "text": "No Resi",
    "isAlreadyTranslated": true
  },
  "Verification Code": {
    "text": "Kode Verifikasi",
    "isAlreadyTranslated": true
  },
  "On": {
    "text": "Pada",
    "isAlreadyTranslated": true
  },
  "at": {
    "text": "Di",
    "isAlreadyTranslated": true
  },
  "Registration Document": {
    "text": "Dokumen Registrasi",
    "isAlreadyTranslated": true
  },
  "REQUESTS": {
    "text": "PERMINTAAN",
    "isAlreadyTranslated": true
  },
  "Logout": {
    "text": "Keluar",
    "isAlreadyTranslated": true
  },
  "Claim Request": {
    "text": "Permintaan Klaim",
    "isAlreadyTranslated": true
  },
  "Open": {
    "text": "Buka",
    "isAlreadyTranslated": true
  },
  "Search brands": {
    "text": "Cari Merk",
    "isAlreadyTranslated": true
  },
  "In Progress": {
    "text": "Dalam Proses",
    "isAlreadyTranslated": true
  },
  "Closed": {
    "text": "Tutup",
    "isAlreadyTranslated": true
  },
  "Cancelled": {
    "text": "Dibatalkan",
    "isAlreadyTranslated": true
  },
  "Today": {
    "text": "Hari Ini",
    "isAlreadyTranslated": true
  },
  "Yesterday": {
    "text": "Kemarin",
    "isAlreadyTranslated": true
  },
  "Last 7 Days": {
    "text": "7 Hari Terakhir",
    "isAlreadyTranslated": true
  },
  "Last 30 Days": {
    "text": "30 Hari Terakhir",
    "isAlreadyTranslated": true
  },
  "This Month": {
    "text": "Bulan Ini",
    "isAlreadyTranslated": true
  },
  "Custom Range": {
    "text": "Custom",
    "isAlreadyTranslated": true
  },
  "Consumer Name": {
    "text": "Nama Pelanggan",
    "isAlreadyTranslated": true
  },
  "Serial No": {
    "text": "Nomor Serial",
    "isAlreadyTranslated": true
  },
  "Service Type": {
    "text": "Jenis Perbaikan",
    "isAlreadyTranslated": true
  },
  "Logistics Provider": {
    "text": "Logistik Provider",
    "isAlreadyTranslated": true
  },
  "Registration Source": {
    "text": "Sumber Registrasi",
    "isAlreadyTranslated": true
  },
  "Customer City": {
    "text": "Kota Pelanggan",
    "isAlreadyTranslated": true
  },
  "Zipcode": {
    "text": "Kode Pos",
    "isAlreadyTranslated": true
  },
  "Incident ID/External ReferenceID": {
    "text": "Incident ID/External ReferenceID",
    "isAlreadyTranslated": true
  },
  "No engineers found": {
    "text": "Tidak ada Teknisi yang ditemukan",
    "isAlreadyTranslated": true
  },
  "Call Type": {
    "text": "Jenis Panggilan",
    "isAlreadyTranslated": true
  },
  "IMEI码/Serial No": {
    "text": "Imei / Nomor Serial",
    "isAlreadyTranslated": true
  },
  "Schedule": {
    "text": "Jadwal",
    "isAlreadyTranslated": true
  },
  "service requests are shown above.": {
    "text": "Permintaan Perbaikan Ditampilkan Diatas",
    "isAlreadyTranslated": true
  },
  "Email ID": {
    "text": "Email",
    "isAlreadyTranslated": true
  },
  "Back to all requests": {
    "text": "Kembali Ke Semua Permintaan",
    "isAlreadyTranslated": true
  },
  "Add a Product": {
    "text": "Tambah Produk",
    "isAlreadyTranslated": true
  },
  "Select a Brand": {
    "text": "Piilih Merk",
    "isAlreadyTranslated": true
  },
  "Search subcategories": {
    "text": "Cari Sub Kategori",
    "isAlreadyTranslated": true
  },
  "Enter IMEI number": {
    "text": "Masukkan Nomor Imei",
    "isAlreadyTranslated": true
  },
  "Enter Serial number": {
    "text": "Masukkan Nomor Serial",
    "isAlreadyTranslated": true
  },
  "Select Brand": {
    "text": "Pilih Merk",
    "isAlreadyTranslated": true
  },
  "Select a Subcategory": {
    "text": "Pilih Subkategori",
    "isAlreadyTranslated": true
  },
  "Select Subcategory": {
    "text": "Pilih Subkategori",
    "isAlreadyTranslated": true
  },
  "Select a Product": {
    "text": "Pilih Produk",
    "isAlreadyTranslated": true
  },
  "Select Product": {
    "text": "Pilih Produk",
    "isAlreadyTranslated": true
  },
  "Add Selected Product": {
    "text": "Tambah Produk Terpilih",
    "isAlreadyTranslated": true
  },
  "OnePlus 5": {
    "text": "OnePlus 5",
    "isAlreadyTranslated": true
  },
  "Mobile": {
    "text": "Perangkat",
    "isAlreadyTranslated": true
  },
  "One Plus": {
    "text": "Satu ditambah",
    "isAlreadyTranslated": true
  },
  "OK": {
    "text": "Ok",
    "isAlreadyTranslated": true
  },
  "Loading": {
    "text": "Loading",
    "isAlreadyTranslated": true
  },
  "undefined": {
    "text": "Tidak Terdefinisi",
    "isAlreadyTranslated": true
  },
  "Select a Type": {
    "text": "Pilih Jenis Perbaikan",
    "isAlreadyTranslated": true
  },
  "Normal Repair": {
    "text": "Perbaikan",
    "isAlreadyTranslated": true
  },
  "Select Customer Type": {
    "text": "Pilih Jenis Pelanggan",
    "isAlreadyTranslated": true
  },
  "Walk-in Customer": {
    "text": "Walk-In Pelanggan",
    "isAlreadyTranslated": true
  },
  "Future Appointment": {
    "text": "Reservasi",
    "isAlreadyTranslated": true
  },
  "Select an Address": {
    "text": "Pilih Alamat",
    "isAlreadyTranslated": true
  },
  "Add New Address": {
    "text": "Tambahkan Alamat Baru",
    "isAlreadyTranslated": true
  },
  "Landmark": {
    "text": "Kota",
    "isAlreadyTranslated": true
  },
  "Address": {
    "text": "Alamat",
    "isAlreadyTranslated": true
  },
  "Pincode": {
    "text": "Kode Pin",
    "isAlreadyTranslated": true
  },
  "Office": {
    "text": "Kantor",
    "isAlreadyTranslated": true
  },
  "Select Issues": {
    "text": "Keluhan Pelanggan",
    "isAlreadyTranslated": true
  },
  "Search issues": {
    "text": "Cari Masalah",
    "isAlreadyTranslated": true
  },
  "Other": {
    "text": "Lain-Lain",
    "isAlreadyTranslated": true
  },
  "Other Issue": {
    "text": "Lain-Lain",
    "isAlreadyTranslated": true
  },
  "Please enter issue description": {
    "text": "Silahkan Masukkan Deskripsi Masalah",
    "isAlreadyTranslated": true
  },
  "Yes": {
    "text": "Ya",
    "isAlreadyTranslated": true
  },
  "Cancel": {
    "text": "Batalkan",
    "isAlreadyTranslated": true
  },
  "Role": {
    "text": "Role",
    "isAlreadyTranslated": true
  },
  "Customer Care Executive": {
    "text": "Customer Service",
    "isAlreadyTranslated": true
  },
  "Engineer": {
    "text": "Teknisi",
    "isAlreadyTranslated": true
  },
  "QC Engineer": {
    "text": "Teknisi QC",
    "isAlreadyTranslated": true
  },
  "Store manager": {
    "text": "Store Manager",
    "isAlreadyTranslated": true
  },
  "Supervisor": {
    "text": "Supervisor",
    "isAlreadyTranslated": true
  },
  "Choose another account": {
    "text": "Pilih Akun Lain",
    "isAlreadyTranslated": true
  },
  "Add Consumer": {
    "text": "Tambah Pelanggan",
    "isAlreadyTranslated": true
  },
  "Reset": {
    "text": "Reset",
    "isAlreadyTranslated": true
  },
  "Mobile number": {
    "text": "Nomor Pelanggan",
    "isAlreadyTranslated": true
  },
  "Full name": {
    "text": "Nama Lengkap",
    "isAlreadyTranslated": true
  },
  "Select a Product to View Diagnosis": {
    "text": "PIlih Produk Untuk Melihat Diagnosa",
    "isAlreadyTranslated": true
  },
  "No servicable consumer product found": {
    "text": "Tidak Ditemukan Produk Pelanggan Yang Diperbaiki",
    "isAlreadyTranslated": true
  },
  "This mobile number is not registered with us.": {
    "text": "Nomor HP Tidak Terdaftar",
    "isAlreadyTranslated": true
  },
  "Close": {
    "text": "Close",
    "isAlreadyTranslated": true
  },
  "Something went wrong": {
    "text": "Terjadi Kesalahan",
    "isAlreadyTranslated": true
  },
  "Oops... Something went wrong.": {
    "text": "Terjadi Kesalahan",
    "isAlreadyTranslated": true
  },
  "Customer Name": {
    "text": "Nama Pelanggan",
    "isAlreadyTranslated": true
  },
  "Customer Mobile": {
    "text": "Nomor HP",
    "isAlreadyTranslated": true
  },
  "Customer Email": {
    "text": "Email Pelanggan",
    "isAlreadyTranslated": true
  },
  "Customer Alternate Mobile": {
    "text": "Nomor HP Alternatif",
    "isAlreadyTranslated": true
  },
  "IMEI": {
    "text": "IMEI",
    "isAlreadyTranslated": true
  },
  "Initiate App Diagnostic": {
    "text": "Memulai App Diagnostik",
    "isAlreadyTranslated": true
  },
  "History": {
    "text": "Riwayat",
    "isAlreadyTranslated": true
  },
  "Diagnosed on": {
    "text": "Di Diagnosa",
    "isAlreadyTranslated": true
  },
  "Diagnostic Report": {
    "text": "Laporan Diagnosa",
    "isAlreadyTranslated": true
  },
  "Type": {
    "text": "Jenis",
    "isAlreadyTranslated": true
  },
  "Diagnosed": {
    "text": "Di Diagnosa",
    "isAlreadyTranslated": true
  },
  "Issue": {
    "text": "Isu",
    "isAlreadyTranslated": true
  },
  "Issues": {
    "text": "Masalah",
    "isAlreadyTranslated": true
  },
  "Found": {
    "text": "Ditemukan",
    "isAlreadyTranslated": true
  },
  "No Issues Found": {
    "text": "Tidak Ditemukan Masalah",
    "isAlreadyTranslated": true
  },
  "No diagnosis performed": {
    "text": "Tidak Ada Diagnosa Yang Dilakukan",
    "isAlreadyTranslated": true
  },
  "Diagnosis Result": {
    "text": "Hasil Diagnosa",
    "isAlreadyTranslated": true
  },
  "Diagnosis History": {
    "text": "Riwayat Diagnosa",
    "isAlreadyTranslated": true
  },
  "No diagnosis history found": {
    "text": "Tidak ditemukan riwayat diagnosa",
    "isAlreadyTranslated": true
  },
  "No issues": {
    "text": "Tidak Ada Masalah",
    "isAlreadyTranslated": true
  },
  "No diagnosis found for this consumer product": {
    "text": "Tidak Ditemuka Riwayat Diagnosa untuk Produk Pelanggan Ini",
    "isAlreadyTranslated": true
  },
  "Initiate diagnosis": {
    "text": "Mulai Diagnosa",
    "isAlreadyTranslated": true
  },
  "Notification type": {
    "text": "Tipe Notifikasi",
    "isAlreadyTranslated": true
  },
  "Message": {
    "text": "Pesan",
    "isAlreadyTranslated": true
  },
  "Apply a filter before sending push notification": {
    "text": "Lakukan Filter sebelum kirim Push Notification",
    "isAlreadyTranslated": true
  },
  "Something went wrong while sending notifications": {
    "text": "Ada kesalahan dalam mengirim Notification",
    "isAlreadyTranslated": true
  },
  "Template": {
    "text": "Template",
    "isAlreadyTranslated": true
  },
  "Subject": {
    "text": "Subject",
    "isAlreadyTranslated": true
  },
  "Something went wrong while fetching templates": {
    "text": "Ada kesalahan saat pengambilan Template",
    "isAlreadyTranslated": true
  },
  "Apply a filter before sending an email": {
    "text": "Lakukan Filter sebelum kirim Email",
    "isAlreadyTranslated": true
  },
  "Select a predefined template": {
    "text": "Pilih Template yang Tersedia",
    "isAlreadyTranslated": true
  },
  "Something went wrong while sending the email": {
    "text": "Ada kesalahan saat pengiriman Email",
    "isAlreadyTranslated": true
  },
  "Name": {
    "text": "Nama",
    "isAlreadyTranslated": true
  },
  "Email": {
    "text": "Email",
    "isAlreadyTranslated": true
  },
  "Subcategory": {
    "text": "Subkategori",
    "isAlreadyTranslated": true
  },
  "Brand": {
    "text": "Merk",
    "isAlreadyTranslated": true
  },
  "Product": {
    "text": "Produk",
    "isAlreadyTranslated": true
  },
  "Registered Consumer": {
    "text": "Pelanggan Terdaftar",
    "isAlreadyTranslated": true
  },
  "Check this if you want to select registered consumers": {
    "text": "Cek ini jika Anda ingin memilih pelanggan yang terdaftar",
    "isAlreadyTranslated": true
  },
  "Check this if you want to select temp consumers": {
    "text": "Cek ini jika Anda ingin memilih Pelanggan Sementara",
    "isAlreadyTranslated": true
  },
  "Check this if you want send notifications. This check will only return registeredconsumers/temp consumers that have deviceTokens": {
    "text": "Periksa ini jika Anda ingin mengirim pemberitahuan. Pemeriksaan ini hanya akan kembali registeredconsumers / temp konsumen yang memiliki deviceTokens",
    "isAlreadyTranslated": true
  },
  "Check this if you want send emails. This check will only return registered consumers/temp consumers that have email IDs": {
    "text": "Periksa ini jika Anda ingin mengirim email. Pemeriksaan ini hanya akan kembali konsumen terdaftar / temp konsumen yang memiliki ID email",
    "isAlreadyTranslated": true
  },
  "Reset filters": {
    "text": "Reset Filter",
    "isAlreadyTranslated": true
  },
  "No filter applied": {
    "text": "Belum Ada Filter",
    "isAlreadyTranslated": true
  },
  "Something erroneous happened": {
    "text": "Terjadinya Kesalahan",
    "isAlreadyTranslated": true
  },
  "Enter an approved sms message": {
    "text": "Masukkan Pesan sms yang disetujui",
    "isAlreadyTranslated": true
  },
  "Apply a filter before sending a SMS": {
    "text": "Lakukan Filter Sebelum mengirim SMS",
    "isAlreadyTranslated": true
  },
  "Coupons": {
    "text": "kupon",
    "isAlreadyTranslated": true
  },
  "Redeemed Coupons": {
    "text": "Kupon yang ditebus",
    "isAlreadyTranslated": true
  },
  "Last Month": {
    "text": "Bulan Terakhir",
    "isAlreadyTranslated": true
  },
  "Last Week": {
    "text": "Minggu Terakhir",
    "isAlreadyTranslated": true
  },
  "Last Day": {
    "text": "Hari Terakhir",
    "isAlreadyTranslated": true
  },
  "Coupon Code": {
    "text": "Kode Kupon",
    "isAlreadyTranslated": true
  },
  "Description": {
    "text": "Deskripsi",
    "isAlreadyTranslated": true
  },
  "No. Of Users": {
    "text": "No Pelanggan",
    "isAlreadyTranslated": true
  },
  "Put 99999999999 if applicable to all": {
    "text": "Masukan 99999999999 jika berlaku untuk semua",
    "isAlreadyTranslated": true
  },
  "Min Amount": {
    "text": "Jumlah Minimal",
    "isAlreadyTranslated": true
  },
  "Start Date": {
    "text": "Mulai Tanggal",
    "isAlreadyTranslated": true
  },
  "Start date of the coupon": {
    "text": "tanggal kupon mulai",
    "isAlreadyTranslated": true
  },
  "End Date": {
    "text": "Tanggal Berakhir",
    "isAlreadyTranslated": true
  },
  "End date of the coupon": {
    "text": "tanggal akhir kupon",
    "isAlreadyTranslated": true
  },
  "Coupon Type": {
    "text": "kupon Jenis",
    "isAlreadyTranslated": true
  },
  "Type: cashback, discount or referral": {
    "text": "Jenis: cashback, diskon atau rujukan",
    "isAlreadyTranslated": true
  },
  "Incentive Type": {
    "text": "insentif Jenis",
    "isAlreadyTranslated": true
  },
  "Applicable only after sign up": {
    "text": "Berlaku hanya setelah mendaftar",
    "isAlreadyTranslated": true
  },
  "Select incentive type after selecting coupon type": {
    "text": "Pilih jenis insentif setelah memilih jenis kupon",
    "isAlreadyTranslated": true
  },
  "Incentive Value": {
    "text": "Nilai insentif",
    "isAlreadyTranslated": true
  },
  "Active coupon": {
    "text": "kupon aktif",
    "isAlreadyTranslated": true
  },
  "Create Coupon": {
    "text": "Buat kupon",
    "isAlreadyTranslated": true
  },
  "Please fill out this field": {
    "text": "Silahkan diisi kolom ini",
    "isAlreadyTranslated": true
  },
  "Please fill value greater than 0": {
    "text": "Silahkan masukkan nilai lebih dari 0",
    "isAlreadyTranslated": true
  },
  "End Date cannot be less than start date": {
    "text": "Hari berakhir tidak boleh lebih rendah dari tanggal mulai",
    "isAlreadyTranslated": true
  },
  "Incentive value cannot exceed": {
    "text": "nilai insentif tidak dapat melebihi",
    "isAlreadyTranslated": true
  },
  "Select a Coupon Type and Incentive Type first": {
    "text": "Pilih Jenis Kupon dan Insentif Jenis pertama",
    "isAlreadyTranslated": true
  },
  "Coupon Name": {
    "text": "kupon Nama",
    "isAlreadyTranslated": true
  },
  "Applied": {
    "text": "Terpakai",
    "isAlreadyTranslated": true
  },
  "Redeemed": {
    "text": "Ditukarkan",
    "isAlreadyTranslated": true
  },
  "Created on": {
    "text": "Dibuat Pada",
    "isAlreadyTranslated": true
  },
  "Edit": {
    "text": "Edit",
    "isAlreadyTranslated": true
  },
  "Plan Type": {
    "text": "rencana Type",
    "isAlreadyTranslated": true
  },
  "Extra Plans": {
    "text": "Rencana tambahan",
    "isAlreadyTranslated": true
  },
  "Number of Plans": {
    "text": "Jumlah Rencana",
    "isAlreadyTranslated": true
  },
  "No. of Sales (Today)": {
    "text": "No Sales ( hari ini )",
    "isAlreadyTranslated": true
  },
  "Sale Value (Today)": {
    "text": "Nilai Sales ( hari ini )",
    "isAlreadyTranslated": true
  },
  "No. of Sales (Till Date)": {
    "text": "No Sales ( sampai tanggal )",
    "isAlreadyTranslated": true
  },
  "Sale Value (Till Date)": {
    "text": "Nilai Sales ( sampai tanggal )",
    "isAlreadyTranslated": true
  },
  "No Plans": {
    "text": "Tidak ada Rencana",
    "isAlreadyTranslated": true
  },
  "Consumer Details": {
    "text": "Detil Pelanggan",
    "isAlreadyTranslated": true
  },
  "Report is empty": {
    "text": "Laporan Kosong",
    "isAlreadyTranslated": true
  },
  "Date": {
    "text": "Tanggal",
    "isAlreadyTranslated": true
  },
  "Invoice No": {
    "text": "Nomor Invoice",
    "isAlreadyTranslated": true
  },
  "Plan": {
    "text": "Rencana",
    "isAlreadyTranslated": true
  },
  "Plan Price": {
    "text": "rencana Harga",
    "isAlreadyTranslated": true
  },
  "Source": {
    "text": "Sumber",
    "isAlreadyTranslated": true
  },
  "Status": {
    "text": "Status",
    "isAlreadyTranslated": true
  },
  "Customer": {
    "text": "Pelanggan",
    "isAlreadyTranslated": true
  },
  "Last": {
    "text": "Terakhir",
    "isAlreadyTranslated": true
  },
  "First": {
    "text": "Pertama",
    "isAlreadyTranslated": true
  },
  "Plans": {
    "text": "Rencana",
    "isAlreadyTranslated": true
  },
  "Plan ID": {
    "text": "rencana ID",
    "isAlreadyTranslated": true
  },
  "Plan Code": {
    "text": "Kode rencana",
    "isAlreadyTranslated": true
  },
  "Plan Name": {
    "text": "rencana Nama",
    "isAlreadyTranslated": true
  },
  "Plan Validity": {
    "text": "rencana Validitas",
    "isAlreadyTranslated": true
  },
  "Oops... Something went wrong!": {
    "text": "Terjadi Kesalahan!",
    "isAlreadyTranslated": true
  },
  "Transaction Summary": {
    "text": "Ringkasan Transaksi",
    "isAlreadyTranslated": true
  },
  "Back": {
    "text": "Back",
    "isAlreadyTranslated": true
  },
  "Check Eligibility": {
    "text": "Cek Kelayakan",
    "isAlreadyTranslated": true
  },
  "Available Plans": {
    "text": "Rencana tersedia",
    "isAlreadyTranslated": true
  },
  "These are the plans available for the device": {
    "text": "Ini adalah rencana yang tersedia untuk perangkat",
    "isAlreadyTranslated": true
  },
  "Serial Number": {
    "text": "Nomor Serial",
    "isAlreadyTranslated": true
  },
  "Select a Plan": {
    "text": "Pilih Rencana",
    "isAlreadyTranslated": true
  },
  "Sell Now": {
    "text": "Jual Sekarang",
    "isAlreadyTranslated": true
  },
  "Know more": {
    "text": "Tahu lebih banyak",
    "isAlreadyTranslated": true
  },
  "No Plans Available": {
    "text": "Tidak ada Rencana Tersedia",
    "isAlreadyTranslated": true
  },
  "Date of Purchase": {
    "text": "Tanggal Pembelian",
    "isAlreadyTranslated": true
  },
  "Buy plan": {
    "text": "Beli rencana",
    "isAlreadyTranslated": true
  },
  "Do you want to buy the plan": {
    "text": "Apakah Anda ingin membeli rencana",
    "isAlreadyTranslated": true
  },
  "Cancel purchase": {
    "text": "Batal Pembelian",
    "isAlreadyTranslated": true
  },
  "Device not eligible": {
    "text": "Perangkat tidak memenuhi syarat",
    "isAlreadyTranslated": true
  },
  "Device is not eligible for": {
    "text": "Perangkat tidak memenuhi syarat untuk",
    "isAlreadyTranslated": true
  },
  "View Plans": {
    "text": "Lihat Rencana",
    "isAlreadyTranslated": true
  },
  "Device is not eligible for the plan": {
    "text": "Perangkat tidak memenuhi syarat untuk rencana",
    "isAlreadyTranslated": true
  },
  "Continuing means the partner has verified the details and he will bear all losses or related expenses if there is a mis-match and fraudulent claim later": {
    "text": "Melanjutkan berarti mitra kerja sudah menverifikasi detilnya dan akan menanggung semua kerugian dah biaya terkait. Jika ada ketidak sesuaian dan kecurangan dalam klaim kemudian hari",
    "isAlreadyTranslated": true
  },
  "Incorrect IMEI/Serial Number": {
    "text": "Imei / Nomor Serial Salah",
    "isAlreadyTranslated": true
  },
  "Please enter correct IMEI/Serial Number": {
    "text": "Silahkan masukkan Imei / Nomor Serial yang bener",
    "isAlreadyTranslated": true
  },
  "Device is eligible": {
    "text": "Perangkat memenuhi syarat",
    "isAlreadyTranslated": true
  },
  "Device is eligible for multiple plans. Please choose a plan": {
    "text": "Perangkat memenuhi syarat untuk beberapa rencana. Pilih paket",
    "isAlreadyTranslated": true
  },
  "This device is not eligible for any plan": {
    "text": "Perangkat ini tidak memenuhi syarat untuk rencana apapun",
    "isAlreadyTranslated": true
  },
  "Sorry": {
    "text": "Maaf",
    "isAlreadyTranslated": true
  },
  "Invalid IMEI/Serial Number": {
    "text": "Imei / Nomor Serial tidak valid",
    "isAlreadyTranslated": true
  },
  "Device is eligible for the purchase of the plans shown. Please select to continue": {
    "text": "Perangkat memenuhi syarat untuk pembelian rencana ditampilkan. Silakan pilih untuk melanjutkan",
    "isAlreadyTranslated": true
  },
  "Filter Requests": {
    "text": "Filter Permintaan",
    "isAlreadyTranslated": true
  },
  "Period": {
    "text": "Periode",
    "isAlreadyTranslated": true
  },
  "Data displayed": {
    "text": "Data ditampilkan",
    "isAlreadyTranslated": true
  },
  "No Data Available": {
    "text": "Tidak ada data yang tersedia",
    "isAlreadyTranslated": true
  },
  "Reference ID": {
    "text": "ID Referensi",
    "isAlreadyTranslated": true
  },
  "Customer Details": {
    "text": "Detil Pelanggan",
    "isAlreadyTranslated": true
  },
  "Device": {
    "text": "Perangkat",
    "isAlreadyTranslated": true
  },
  "IMEI/Serial No": {
    "text": "Imei / No Serial",
    "isAlreadyTranslated": true
  },
  "Pledged To eRecycle in": {
    "text": "Berjanji Untuk eRecycle di",
    "isAlreadyTranslated": true
  },
  "Created": {
    "text": "Dibuat",
    "isAlreadyTranslated": true
  },
  "View": {
    "text": "Lihat",
    "isAlreadyTranslated": true
  },
  "optin requests are shown above": {
    "text": "Opsi Permintaan tertampil diatas",
    "isAlreadyTranslated": true
  },
  "out of": {
    "text": "Dari",
    "isAlreadyTranslated": true
  },
  "Contact No": {
    "text": "Nomor Kontak",
    "isAlreadyTranslated": true
  },
  "Alternate Mobile No": {
    "text": "Nomor Telepon Cadangan",
    "isAlreadyTranslated": true
  },
  "Device Details": {
    "text": "Detil Perangkat",
    "isAlreadyTranslated": true
  },
  "IMEI/Serial Number": {
    "text": "Imei / No Serial",
    "isAlreadyTranslated": true
  },
  "Device Added Date": {
    "text": "Tanggal perangkat ditambah",
    "isAlreadyTranslated": true
  },
  "Request Details": {
    "text": "Detil Permintaan",
    "isAlreadyTranslated": true
  },
  "Optin Rewards Chosen": {
    "text": "Hadiah Optin Dipilih",
    "isAlreadyTranslated": true
  },
  "Green Rewards Chosen": {
    "text": "Hadiah hijau Dipilih",
    "isAlreadyTranslated": true
  },
  "Invite Service User": {
    "text": "Undang Pengguna Jasa",
    "isAlreadyTranslated": true
  },
  "Invite Retailer User": {
    "text": "Undang Pengecer Pengguna",
    "isAlreadyTranslated": true
  },
  "User Role": {
    "text": "User Role",
    "isAlreadyTranslated": true
  },
  "No users found": {
    "text": "Tidak ada pelanggan ditemukan",
    "isAlreadyTranslated": true
  },
  "Filter": {
    "text": "Filter Permintaan",
    "isAlreadyTranslated": true
  },
  "Select User Type": {
    "text": "Pilih Jenis Pelanggan",
    "isAlreadyTranslated": true
  },
  "Partner User": {
    "text": "Mitra Pelangga",
    "isAlreadyTranslated": true
  },
  "Create Request": {
    "text": "Membuat Permintaan",
    "isAlreadyTranslated": true
  },
  "Upload Status Report": {
    "text": "Upload Laporan Status",
    "isAlreadyTranslated": true
  },
  "No Logistics Requests Found": {
    "text": "Tidak ada Permintaan Logistik Ditemukan",
    "isAlreadyTranslated": true
  },
  "WayBill Number": {
    "text": "No Resi",
    "isAlreadyTranslated": true
  },
  "Reference Number": {
    "text": "No Reference",
    "isAlreadyTranslated": true
  },
  "Pickup Name": {
    "text": "Nama Pickup",
    "isAlreadyTranslated": true
  },
  "Pickup Address": {
    "text": "Alamat Pickup",
    "isAlreadyTranslated": true
  },
  "Drop Name": {
    "text": "Nama Drop",
    "isAlreadyTranslated": true
  },
  "Drop Address": {
    "text": "Alamat Drop",
    "isAlreadyTranslated": true
  },
  "Pickup Contact No": {
    "text": "No kontak Pickup",
    "isAlreadyTranslated": true
  },
  "Drop Contact No": {
    "text": "No kontak Drop",
    "isAlreadyTranslated": true
  },
  "No records found": {
    "text": "Tidak ada Record ditemukan",
    "isAlreadyTranslated": true
  },
  "Status Date": {
    "text": "Tanggal Status",
    "isAlreadyTranslated": true
  },
  "Please download the sample file, make changes in it only and upload it": {
    "text": "Silahkan download contoh file, lakukan perubahan didalam dan diupload",
    "isAlreadyTranslated": true
  },
  "Download File": {
    "text": "Download File",
    "isAlreadyTranslated": true
  },
  "Upload File": {
    "text": "Upload File",
    "isAlreadyTranslated": true
  },
  "Submit File": {
    "text": "Submit File",
    "isAlreadyTranslated": true
  },
  "Pickup Pin Code": {
    "text": "Kode Pin Pickup",
    "isAlreadyTranslated": true
  },
  "Drop Pin Code": {
    "text": "Kode Pin Drop",
    "isAlreadyTranslated": true
  },
  "Vendor": {
    "text": "Vendor",
    "isAlreadyTranslated": true
  },
  "Organisation": {
    "text": "Organisasi",
    "isAlreadyTranslated": true
  },
  "Flow": {
    "text": "Mengalir",
    "isAlreadyTranslated": true
  },
  "Clear": {
    "text": "Kosongkan",
    "isAlreadyTranslated": true
  },
  "Apply": {
    "text": "Apply",
    "isAlreadyTranslated": true
  },
  "Re-apply": {
    "text": "Re-Apply",
    "isAlreadyTranslated": true
  },
  "Something went wrong. Please upload again": {
    "text": "Terjadi kesalahan. Silahkan upload lagi",
    "isAlreadyTranslated": true
  },
  "Invalid column headers. You can use sample file": {
    "text": "Kolom header tidak valid. Kamu bisa memakai contoh file.",
    "isAlreadyTranslated": true
  },
  "Please upload a valid file. You can use sample file": {
    "text": "Silahkan Upload file yang valid. Kamu bisa memakai contoh file",
    "isAlreadyTranslated": true
  },
  "Invalid data": {
    "text": "Data tidak valid",
    "isAlreadyTranslated": true
  },
  "Something went wrong. Your request may have timed out": {
    "text": "Terjadi kesalah. Waktu permintaan anda sudah habis",
    "isAlreadyTranslated": true
  },
  "The data has been saved successfully": {
    "text": "Data sudah sukses tersimpan",
    "isAlreadyTranslated": true
  },
  "There are no requests to download": {
    "text": "Tidak ada permintaan untuk didownload",
    "isAlreadyTranslated": true
  },
  "Something went wrong...!": {
    "text": "Terjadi kesalahan !",
    "isAlreadyTranslated": true
  },
  "Cancel request": {
    "text": "Permintaan dibatalkan",
    "isAlreadyTranslated": true
  },
  "View requests": {
    "text": "Lihat Permintaan",
    "isAlreadyTranslated": true
  },
  "Map Logistics Request": {
    "text": "Peta Logistik Permintaan",
    "isAlreadyTranslated": true
  },
  "Please enter the Reference ID of the service request": {
    "text": "Silahkan masukkan ID Reference dari Permintaan Perbaikan",
    "isAlreadyTranslated": true
  },
  "Reference No": {
    "text": "No Reference",
    "isAlreadyTranslated": true
  },
  "Pickup No": {
    "text": "No Pickup",
    "isAlreadyTranslated": true
  },
  "Download Delivery Challan": {
    "text": "Ambil Pengiriman Challan",
    "isAlreadyTranslated": true
  },
  "Download Logistics Label": {
    "text": "Unduh Logistik Label",
    "isAlreadyTranslated": true
  },
  "Pickup Date": {
    "text": "Tanggal Pickup",
    "isAlreadyTranslated": true
  },
  "Pickup Time": {
    "text": "Waktu Pickup",
    "isAlreadyTranslated": true
  },
  "Package Details": {
    "text": "Detil Paket",
    "isAlreadyTranslated": true
  },
  "Product Price": {
    "text": "Biaya Produk",
    "isAlreadyTranslated": true
  },
  "Model": {
    "text": "Model",
    "isAlreadyTranslated": true
  },
  "Brand Name": {
    "text": "Nama Merk",
    "isAlreadyTranslated": true
  },
  "Product Name": {
    "text": "Nama Produk",
    "isAlreadyTranslated": true
  },
  "Logistics Images": {
    "text": "Gambar logistik",
    "isAlreadyTranslated": true
  },
  "Please enter the remarks": {
    "text": "Silahkan masukkan keterangan",
    "isAlreadyTranslated": true
  },
  "Total Unregistered Products": {
    "text": "Total produk tidak terdaftar",
    "isAlreadyTranslated": true
  },
  "Upload": {
    "text": "Upload",
    "isAlreadyTranslated": true
  },
  "Oops": {
    "text": "Ups",
    "isAlreadyTranslated": true
  },
  "Please upload only .csv file.": {
    "text": "Silahkan upload dengan file .csv",
    "isAlreadyTranslated": true
  },
  "Cannot read file": {
    "text": "Tidak bisa membaca File",
    "isAlreadyTranslated": true
  },
  "Invalid Format": {
    "text": "Format tidak valid",
    "isAlreadyTranslated": true
  },
  "Invalid name for Product": {
    "text": "Nama tidak valid untuk produk",
    "isAlreadyTranslated": true
  },
  "More than 2 fields supplied": {
    "text": "Lebih dari 2 kolom tersedia",
    "isAlreadyTranslated": true
  },
  "File provided is not in required format": {
    "text": "File yang dikasih tidak sesuai dengan format",
    "isAlreadyTranslated": true
  },
  "Some products failed": {
    "text": "Beberapa produk gagal",
    "isAlreadyTranslated": true
  },
  "File uploaded": {
    "text": "File diupload",
    "isAlreadyTranslated": true
  },
  "Success": {
    "text": "Berhasil",
    "isAlreadyTranslated": true
  },
  "No file found": {
    "text": "Tidak file ditemukan",
    "isAlreadyTranslated": true
  },
  "Please upload a csv file": {
    "text": "Silahkan upload dengan file .csv",
    "isAlreadyTranslated": true
  },
  "Not charging": {
    "text": "Tidak mengisi daya",
    "isAlreadyTranslated": true
  },
  "Advance amount can't be updated at Present status": {
    "text": "Jumlah uang muka tidak bisa diperbaharui untuk status sekarang",
    "isAlreadyTranslated": true
  },
  "Already booked": {
    "text": "Sudah dipesan",
    "isAlreadyTranslated": true
  },
  "Request cannot be cancelled right now. Please try again later": {
    "text": "Permntaan tidak bisa dibatalkan sekarang. Silahkan coba lagi nanti",
    "isAlreadyTranslated": true
  },
  "No consumer found": {
    "text": "Tidak ditemukan Pelanggan",
    "isAlreadyTranslated": true
  },
  "No pincode found": {
    "text": "Tidak ditemukan pincode",
    "isAlreadyTranslated": true
  },
  "no product details found": {
    "text": "Tidak ditemukan detil produk",
    "isAlreadyTranslated": true
  },
  "No Logistics Detail Found": {
    "text": "Tidak ditemukan detil logistik",
    "isAlreadyTranslated": true
  },
  "Customer Pincode is not serviceable by Our Logistics Partner. Please send the device to the Customer using other Courier Services near you": {
    "text": "Kode Pin Pelanggan tidak dapat diservis oleh Mitra Logistik Kami. Kirimkan perangkat ke Pelanggan menggunakan Layanan Kurir terdekat Anda",
    "isAlreadyTranslated": true
  },
  "Pincode not found": {
    "text": "Pincode tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "Oops. Looks like no service center found": {
    "text": "Ups. Sepertinya tidak ada pusat servis",
    "isAlreadyTranslated": true
  },
  "Oops. Looks like none of our engineers are present to service this product in your area. But we’re hard at work and you should get some good news soon. Try later?": {
    "text": "Ups. Sepertinya tidak ada teknisi kami yang siap menyervis produk ini di wilayah Anda. Tapi kami akan berusaha semaksimal mungkin dan segera memberikan kabar gembira untuk Anda. Coba nanti?",
    "isAlreadyTranslated": true
  },
  "Serial number validation failed": {
    "text": "Validasi nomor serial gagal",
    "isAlreadyTranslated": true
  },
  "Please provide valid Partner Service Location ID": {
    "text": "Berikan ID Lokasi Servis Mitra yang valid",
    "isAlreadyTranslated": true
  },
  "Please close other service": {
    "text": "Silahkan tutup perbaikan lain",
    "isAlreadyTranslated": true
  },
  "Could not schedule your request. Please try again after some time.": {
    "text": "Tidak dapat menjadwalkan permintaan Anda. Coba lagi setelah beberapa menit.",
    "isAlreadyTranslated": true
  },
  "Request created successfully": {
    "text": "Permintaan berhasil dibuat",
    "isAlreadyTranslated": true
  },
  "No consumer product found with given consumer product id": {
    "text": "Tidak ditemukan produk pelanggan dengan Id produk pelanggan yang diberikan",
    "isAlreadyTranslated": true
  },
  "Could not schedule your request.": {
    "text": "Tidak dapat menjadwalkan permintaan Anda",
    "isAlreadyTranslated": true
  },
  "No issue found": {
    "text": "Tidak Ditemukan Masalah",
    "isAlreadyTranslated": true
  },
  "No delivery mode found": {
    "text": "Tidak ditermukan mode pengiriman",
    "isAlreadyTranslated": true
  },
  "error occurred": {
    "text": "Terjadi masalah",
    "isAlreadyTranslated": true
  },
  "Dispatch initiated": {
    "text": "Pengiriman dimulai",
    "isAlreadyTranslated": true
  },
  "Please Provide Valid RequestID": {
    "text": "Silahkan memberikan ID permintaan yang valid",
    "isAlreadyTranslated": true
  },
  "No slot available": {
    "text": "tidak ada slot yang tersedia",
    "isAlreadyTranslated": true
  },
  "The request is not at the required status for the given action: Generate vouchers first": {
    "text": "Permintaan tidak dalam status wajib untuk tindakan ini:",
    "isAlreadyTranslated": true
  },
  "Your iPhone has been eRecycled. Ref ID:": {
    "text": "iPhone Anda telah masuk eRecycle. ID Ref:",
    "isAlreadyTranslated": true
  },
  "No result found": {
    "text": "Tidak ada hasil yang ditemukan",
    "isAlreadyTranslated": true
  },
  "No need to create bulk shipment": {
    "text": "Tidak perlu membuat pengiriman",
    "isAlreadyTranslated": true
  },
  "No address found": {
    "text": "Alamat tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "No need to create pick up request": {
    "text": "Tidak perlu membuat permintaan Pickup",
    "isAlreadyTranslated": true
  },
  "No status found": {
    "text": "Status tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "Insufficient Parameters - Either wayBillNo or ConsumerServiceRequestID is required": {
    "text": "Parameter Tidak Cukup - Dibutuhkan NoResi atau IDPermintaanServisPelanggan",
    "isAlreadyTranslated": true
  },
  "Error in creating entry in ConsumerServicerequestLogistics": {
    "text": "Terjadi masalah dalam membuat entri di LogistikPermintaanServisPelanggan",
    "isAlreadyTranslated": true
  },
  "Success in creating entry in ConsumerServicerequestLogistics": {
    "text": "Berhasil membuat entri di LogistikPermintaanServisPelanggan",
    "isAlreadyTranslated": true
  },
  "No request found with given refid": {
    "text": "Tidak ada permintaan yang ditemukan dengan ID Ref yang diberikan",
    "isAlreadyTranslated": true
  },
  "No Result Found With Given RequestID": {
    "text": "Tidak ditemukan hasil dengan ID Pemintaan",
    "isAlreadyTranslated": true
  },
  "No Result Found With Given LogisticsRequestID": {
    "text": "Tidak Ada Hasil dengan LogisticRequestID yang Diberikan",
    "isAlreadyTranslated": true
  },
  "Error in finding ConsumerServicerequestLogistics for": {
    "text": "Terjadi masalah dalam mencari ConsumerServicerequestLogistics untuk",
    "isAlreadyTranslated": true
  },
  "Error in updating remarks": {
    "text": "Error dalam pembaharuan Keterangan",
    "isAlreadyTranslated": true
  },
  "successfully request created": {
    "text": "Permintaan berhasil dibuat",
    "isAlreadyTranslated": true
  },
  "no ref id found": {
    "text": "Tidak ditemuka ID Ref",
    "isAlreadyTranslated": true
  },
  "no ConsumerServicerequestLogistics found with LogisticsRequestID": {
    "text": "Tidak ada ConsumerServicerequestLogistics dengan LogisticsRequestID",
    "isAlreadyTranslated": true
  },
  "no data found for": {
    "text": "Tidak ada data yang ditemukan untuk",
    "isAlreadyTranslated": true
  },
  "Error in fetching data for": {
    "text": "Error dalam pengambilan data untuk",
    "isAlreadyTranslated": true
  },
  "no logistics request detail found for id": {
    "text": "tidak ada rincian permintaan logistik untuk id",
    "isAlreadyTranslated": true
  },
  "Your phone's pick up has been scheduled": {
    "text": "Penjemputan ponsel Anda sudah dijadwalkan",
    "isAlreadyTranslated": true
  },
  "No Current Location Found For": {
    "text": "Tidak Ada Lokasi Saat Ini Untuk",
    "isAlreadyTranslated": true
  },
  "notification sent": {
    "text": "Notifikasi dikirim",
    "isAlreadyTranslated": true
  },
  "device not supported": {
    "text": "Perangkat tidak mendukung",
    "isAlreadyTranslated": true
  },
  "cannot find consumer": {
    "text": "Tidak ditemukan Pelanggan",
    "isAlreadyTranslated": true
  },
  "Something went wrong while An email blast was attempted for this list of consumers": {
    "text": "Terjadi masalah saat melakukan email blast untuk daftar pelanggan ini",
    "isAlreadyTranslated": true
  },
  "Something went wrong while sendOneEmail": {
    "text": "Terjadi masalah saat KirimSatuEmail",
    "isAlreadyTranslated": true
  },
  "Something went wrong while sending notifications to this list of consumers and temp consumers": {
    "text": "Terjadi masalah saat mengirimkan notifikasi ke daftar pelanggan dan pelanggan sementara ini",
    "isAlreadyTranslated": true
  },
  "Notification sent to this list of consumers and temp consumers": {
    "text": "Notifikasi dikirimkan ke daftar pelanggan dan pelanggan sementara ini",
    "isAlreadyTranslated": true
  },
  "Something went wrong while An SMS blast was attempted for this list of consumers": {
    "text": "Terjadi masalah saat dilakukan SMS blast untuk daftar pelanggan ini",
    "isAlreadyTranslated": true
  },
  "An SMS blast was attempted for this list of consumers": {
    "text": "Dilakukan SMS blast untuk daftar pelanggan ini",
    "isAlreadyTranslated": true
  },
  "Something went wrong while sending sms": {
    "text": "Terjadi masalah saat mengirimkan sms",
    "isAlreadyTranslated": true
  },
  "Payment received": {
    "text": "Pembayaran diterima",
    "isAlreadyTranslated": true
  },
  "payment initialized": {
    "text": "Pembayaran dimulai",
    "isAlreadyTranslated": true
  },
  "payment done": {
    "text": "Pembayaran selesai",
    "isAlreadyTranslated": true
  },
  "No payment occurred": {
    "text": "Tidak ada pembayaran",
    "isAlreadyTranslated": true
  },
  "cannot find a partner": {
    "text": "Tidak ditemuka mitra",
    "isAlreadyTranslated": true
  },
  "fill servicingly table creation": {
    "text": "Mengisi tabel perbaikan",
    "isAlreadyTranslated": true
  },
  "Please provide correct data": {
    "text": "Silahkan memberikan data yang bener",
    "isAlreadyTranslated": true
  },
  "Payment is not received": {
    "text": "Pembayaran tidak diterima",
    "isAlreadyTranslated": true
  },
  "Your payment is received": {
    "text": "Pembayaran Anda sudah diterima",
    "isAlreadyTranslated": true
  },
  "Amount to be paid does not match": {
    "text": "Jumlah yang dibayar tidak sesuai",
    "isAlreadyTranslated": true
  },
  "Partner billing update failed": {
    "text": "Pembaruan tagihan mitra gagal",
    "isAlreadyTranslated": true
  },
  "Payment received successfully": {
    "text": "Pembayaran berhasil diterima",
    "isAlreadyTranslated": true
  },
  "Invalid Device Details": {
    "text": "Detil Perangkat tidak valid",
    "isAlreadyTranslated": true
  },
  "Insufficient parameters - Send either ConsumerServiceRequestID or PlanArray": {
    "text": "Parameter tidak cukup - Kirimkan IDPermintaanServisPelanggan atau KumpulanPaket",
    "isAlreadyTranslated": true
  },
  "Plan Purchased": {
    "text": "Paket Dibeli",
    "isAlreadyTranslated": true
  },
  "( ) has been successfully Purchased and Activated for your device": {
    "text": "( ) berhasil Dibeli dan Diaktifkan untuk perangkat Anda",
    "isAlreadyTranslated": true
  },
  "( ) has been successfully Purchased for your device": {
    "text": "( ) berhasil Dibeli untuk perangkat Anda",
    "isAlreadyTranslated": true
  },
  "Advance payment captured": {
    "text": "Pembayaran uang dimuka dicatat",
    "isAlreadyTranslated": true
  },
  "Error occured in capturing advance payment": {
    "text": "Terjadi kesalah dalam mencatat uang muka yang dibayar",
    "isAlreadyTranslated": true
  },
  "We could not suggest an appropriate engineer": {
    "text": "Kami tidak dapat menyarankan Teknisi yang sesuai",
    "isAlreadyTranslated": true
  },
  "Already booked on this date and slot": {
    "text": "Sudah dipesan pada tanggal ini dan slot",
    "isAlreadyTranslated": true
  },
  "Your service request is Rescheduled": {
    "text": "Permintaan perbaikan sudah diajadwal ulang",
    "isAlreadyTranslated": true
  },
  "Xpert is not assigned to this service": {
    "text": "Xpert tidak ditetapkan untuk servis ini",
    "isAlreadyTranslated": true
  },
  "Service request in not at required stage": {
    "text": "Permintaan perbaikan tidak pada tahap yang diperlukan",
    "isAlreadyTranslated": true
  },
  "Service request not found": {
    "text": "Permintaan perbaikan tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "Engineer not found": {
    "text": "Teknisi tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "No hub found": {
    "text": "Tidak ada pusat",
    "isAlreadyTranslated": true
  },
  "parts are available": {
    "text": "Part tersedia",
    "isAlreadyTranslated": true
  },
  "Not all parts are available": {
    "text": "Tidak semua part tersedia",
    "isAlreadyTranslated": true
  },
  "Inventory updated": {
    "text": "Inventory sudah diperbaharui",
    "isAlreadyTranslated": true
  },
  "No inventory updation": {
    "text": "Tidak ada perbaharuan Inventory",
    "isAlreadyTranslated": true
  },
  "replaced parts": {
    "text": "part yang digantikan",
    "isAlreadyTranslated": true
  },
  "No parts found": {
    "text": "Part tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "mac verified successfully": {
    "text": "mac berhasil diverifikasi",
    "isAlreadyTranslated": true
  },
  "Payment cannot be captured": {
    "text": "Pembayaran tidak bisa dicatat",
    "isAlreadyTranslated": true
  },
  "Authentication failed": {
    "text": "Otentikasi gagal",
    "isAlreadyTranslated": true
  },
  "Payment already captured": {
    "text": "Pembayaran sudah dicatat",
    "isAlreadyTranslated": true
  },
  "Oops. We don’t service this device at the moment. But we’re hard at work and you should get some good news soon. Try later?": {
    "text": "Ups. Kami belum bisa menyervis perangkat ini. Tapi kami akan berusaha semaksimal mungkin dan segera memberikan kabar gembira untuk Anda. Coba nanti?",
    "isAlreadyTranslated": true
  },
  "Something went wrong in fetching from db": {
    "text": "Terjadi masalah saat mengambil dari db",
    "isAlreadyTranslated": true
  },
  "No FAQ Category Data Found": {
    "text": "Tidak ada kategori data FAQ yang ditemukan",
    "isAlreadyTranslated": true
  },
  "Error in Fetching FAQ": {
    "text": "Error dalam pengumpulan FAQ",
    "isAlreadyTranslated": true
  },
  "Please Provide Valid Category ID": {
    "text": "Silahkan memberikan ID kategori yang valid",
    "isAlreadyTranslated": true
  },
  "Cannot create recycle request": {
    "text": "Tidak dapat membuat permintaan daur ulang",
    "isAlreadyTranslated": true
  },
  "Your Pickup Request For eRecycling Your iPhone Has Been Accepted | Ref. ID:": {
    "text": "Permintaan Jemputan Untuk eRecycle iPhone Anda Sudah Disetujui | ID Ref.:",
    "isAlreadyTranslated": true
  },
  "Welcome to eRecycle For iPhones | Ref. ID:": {
    "text": "Selamat Datang di eRecycle Untuk iPhone | ID Ref.:",
    "isAlreadyTranslated": true
  },
  "Your Friend Has Chosen You as The Next Green Warrior!": {
    "text": "Temanmu Memilihmu sebagai Green Warrior Berikutnya!",
    "isAlreadyTranslated": true
  },
  "No logistics action need to be done": {
    "text": "Tidak ada tindakan logistik yang perlu dilakukan",
    "isAlreadyTranslated": true
  },
  "Couldn't find a consumer product with the given ID": {
    "text": "Tidak dapat menemukan produk pelanggan ID yang diberikan",
    "isAlreadyTranslated": true
  },
  "No consumer service request found by the given ID.": {
    "text": "Tidak ditemukan permintaan perbaikan pelanggan dengan ID yang diberikan",
    "isAlreadyTranslated": true
  },
  "You Have Been Acknowledged as a True Green Warrior!": {
    "text": "Anda Telah Diakui sebagai Green Warrior Sejati!",
    "isAlreadyTranslated": true
  },
  "Congratulations! Your iPhone has been Verified For eRecycling | Ref. ID:": {
    "text": "Selamat! iPhone Anda sudah Diverifikasi untuk eRecycle | ID Ref.:",
    "isAlreadyTranslated": true
  },
  "We have an update about your chosen Green Reward | Ref. ID:": {
    "text": "Kami memiliki pembaruan tentang Hadiah Hijau yang Anda pilih | ID Ref.:",
    "isAlreadyTranslated": true
  },
  "cannot edit the vouchers": {
    "text": "tidak dapat mengedit voucher",
    "isAlreadyTranslated": true
  },
  "IMEI eligible": {
    "text": "Imei memenuhi syarat",
    "isAlreadyTranslated": true
  },
  "IMEI not eligible": {
    "text": "Imei tidak memenuhi syarat",
    "isAlreadyTranslated": true
  },
  "IMEI already used for subscription": {
    "text": "Imei sudah digunakan untuk",
    "isAlreadyTranslated": true
  },
  "Error in updating product details": {
    "text": "Error dalam pembaharuan detil produk",
    "isAlreadyTranslated": true
  },
  "product successfully updated": {
    "text": "Produk berhasil diperbaharui",
    "isAlreadyTranslated": true
  },
  "No consumer product found with given id": {
    "text": "Tidak ditemukan produk pelanggan dengan ID yang diberikan",
    "isAlreadyTranslated": true
  },
  "Reward generated": {
    "text": "Hadiah diberikan",
    "isAlreadyTranslated": true
  },
  "You have successfully pledged to eRecycle your iPhone in": {
    "text": "Berhasil berjanji untuk eRecycle iPhone Anda di",
    "isAlreadyTranslated": true
  },
  "We will remind you to submit your iPhone for eRecycling a few days prior to your chosen month. \\nYou can choose one more Reward when you submit your pledged iPhone for eRecycling in": {
    "text": "Kami akan mengingatkan untuk menyerahkan iPhone Anda untuk eRecycle beberapa hari sebelum memasuki bulan yang Anda pilih. \\nAnda dapat memilih satu lagi Hadiah saat menyerahkan iPhone yang dijanjikan untuk eRecycle di",
    "isAlreadyTranslated": true
  },
  "Document cannot be added": {
    "text": "Dokumen tidak bisa ditambah",
    "isAlreadyTranslated": true
  },
  "Pending document": {
    "text": "Dokumen tertunda",
    "isAlreadyTranslated": true
  },
  "No document obtained": {
    "text": "Tidak ada dokumen yang diterima",
    "isAlreadyTranslated": true
  },
  "Issue not found": {
    "text": "Masalah tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "Reward Partner not found": {
    "text": "Mitra Hadiah tidak ada",
    "isAlreadyTranslated": true
  },
  "After your 3rd eRecycle request all your requests deserve a lot more than Green Rewards. For this request, you are eligible for a lifetime supply of good Green Karma!": {
    "text": "Setelah permintaan eRecycle ketiga, semua permintaan Anda berhak mendapatkan lebih dari Hadiah Hijau. Untuk permintaan ini, Anda berhak mendapatkan Green karma yang baik seumur hidup!",
    "isAlreadyTranslated": true
  },
  "You are eligible for reward": {
    "text": "Anda berhak mendapatkan hadiah",
    "isAlreadyTranslated": true
  },
  "You are not eligible for reward as you have exceeded the eRecycle limit": {
    "text": "Anda tidak berhak mendapatkan hadiah karena telah melampaui batas eRecycle",
    "isAlreadyTranslated": true
  },
  "Not a manual reward": {
    "text": "Bukan hadiah manual",
    "isAlreadyTranslated": true
  },
  "Insufficient parameters - Send PartnerServiceLocationID": {
    "text": "Parameter tidak cukup - Kirimkan IDLokasiServisMitra",
    "isAlreadyTranslated": true
  },
  "Insufficient parameters - Send ConsumerServiceRequestID": {
    "text": "Parameter tidak cukup - Kirimkan IDPermintaanServisPelanggan",
    "isAlreadyTranslated": true
  },
  "Insufficient parameters - Send ServiceTypeID": {
    "text": "Parameter tidak cukup - Kirimkan IDJenisServis",
    "isAlreadyTranslated": true
  },
  "This servive type does not exist": {
    "text": "Jenis perbaikan ini tidak tersedia",
    "isAlreadyTranslated": true
  },
  "Something went wrong with getParts": {
    "text": "Terjadi kesalahan dalam pengambilan Part",
    "isAlreadyTranslated": true
  },
  "Parts List has been sent": {
    "text": "List part sudah dikirim",
    "isAlreadyTranslated": true
  },
  "Engineer is present": {
    "text": "Teknisi Hadir",
    "isAlreadyTranslated": true
  },
  "Engineer is not present": {
    "text": "Teknisi tidak hadir",
    "isAlreadyTranslated": true
  },
  "Failed to get engineer details": {
    "text": "Gagal mendapatkan detil teknisi",
    "isAlreadyTranslated": true
  },
  "Please provide correct productivity per day.": {
    "text": "Tulis produktivitas per hari dengan benar.",
    "isAlreadyTranslated": true
  },
  "Your attendance is marked.": {
    "text": "Kehadiran Anda ditandai.",
    "isAlreadyTranslated": true
  },
  "Failed to mark attendance.": {
    "text": "Gagal menandai kehadiran.",
    "isAlreadyTranslated": true
  },
  "Failed to find the Engineer": {
    "text": "Gagal menemukan Teknisi",
    "isAlreadyTranslated": true
  },
  "Unusual exception occurred. Please try again after some time.": {
    "text": "Terjadi pengecualian yang tidak wajar. Coba lagi setelah beberapa menit.",
    "isAlreadyTranslated": true
  },
  "Timings updated successfully .": {
    "text": "Waktu berhasil diperbaharui",
    "isAlreadyTranslated": true
  },
  "service is not at required state": {
    "text": "Perbaikan tidak pada tahan yg diperlukan",
    "isAlreadyTranslated": true
  },
  "Parts added": {
    "text": "Part ditambah",
    "isAlreadyTranslated": true
  },
  "Parts updating failed": {
    "text": "Perbaharuan part gagal",
    "isAlreadyTranslated": true
  },
  "Other costs added": {
    "text": "Biaya lain ditambah",
    "isAlreadyTranslated": true
  },
  "Advance payment added": {
    "text": "Pembayaran uang dimuka ditambah",
    "isAlreadyTranslated": true
  },
  "Parts removed": {
    "text": "Part dikeluarkan",
    "isAlreadyTranslated": true
  },
  "Discount fetching failed": {
    "text": "Gagal mengambil diskon",
    "isAlreadyTranslated": true
  },
  "Tax information fetching failed": {
    "text": "Gagal mengambil informasi pajak",
    "isAlreadyTranslated": true
  },
  "Discount can not be greater than the service amount": {
    "text": "Diskon tidak boleh melebihi biaya servis",
    "isAlreadyTranslated": true
  },
  "Amount": {
    "text": "Jumlah",
    "isAlreadyTranslated": true
  },
  "Received By": {
    "text": "Diterima oleh",
    "isAlreadyTranslated": true
  },
  "Discount or Advance can not be greater than the payable amount.": {
    "text": "Diskon atau Uang Muka tidak boleh melebihi biaya yang harus dibayar.",
    "isAlreadyTranslated": true
  },
  "Invoice has been generated with Service requestId -": {
    "text": "Invoice sudah diterbitkan dengan ID permintaan perbaikan",
    "isAlreadyTranslated": true
  },
  "Request charges fetching failed": {
    "text": "Gagal mengambil biaya permintaan",
    "isAlreadyTranslated": true
  },
  "Update service request failed": {
    "text": "Perbaharui permintaan perbaikan gagal",
    "isAlreadyTranslated": true
  },
  "Service request is not at required stage.": {
    "text": "Permintaan perbaikan tidak pada tahap yang diperlukan",
    "isAlreadyTranslated": true
  },
  "Your delivery request has been successfully completed.": {
    "text": "Permintaan pengiriman Anda berhasil diselesaikan",
    "isAlreadyTranslated": true
  },
  "Parts requested": {
    "text": "Permintaan Part",
    "isAlreadyTranslated": true
  },
  "List of available engineer": {
    "text": "List teknisi tersedia",
    "isAlreadyTranslated": true
  },
  "Could not assign engineer, assign manually": {
    "text": "Tidak dapat menugaskan teknisi, menugaskan secara manual",
    "isAlreadyTranslated": true
  },
  "Job is already assign to another Engineer": {
    "text": "Pekerjan sudah ditugaskan ke teknisi yang satu lagi",
    "isAlreadyTranslated": true
  },
  "No service request found for this ConsumerServiceRequestID": {
    "text": "Permintaan perbaikan tidak ditemukan untuk ID permintaan perbaikan pelanggan",
    "isAlreadyTranslated": true
  },
  "No engineer found for this ServiceLocationEngineerID": {
    "text": "Tidak ada teknisi untuk TeknisiLokasiServisID ini",
    "isAlreadyTranslated": true
  },
  "A new repair job is assigned to you! Please \"Accept\" the job to initiate the repair process. Job ID:": {
    "text": "Sebuah pekerjaan perbaikan baru ditugaskan untuk Anda! Silakan \"Terima\" pekerjaan untuk memulai proses perbaikan. ID Pekerjaan:",
    "isAlreadyTranslated": true
  },
  "Pending for approval": {
    "text": "Persetujuan tertunda",
    "isAlreadyTranslated": true
  },
  "Engineer assigned jobs not updated |": {
    "text": "Penugasan pekerjaan teknisi belum diperbaharui",
    "isAlreadyTranslated": true
  },
  "Create consumer service request assign service location |": {
    "text": "Buat lokasi servis penugasan permintaan servis pelanggan |",
    "isAlreadyTranslated": true
  },
  "Job is assigned to you successfully": {
    "text": "Pekerjaan sudah berhasil ditugaskan ke Anda",
    "isAlreadyTranslated": true
  },
  "Service log creation failed": {
    "text": "Gagal membuat cacatan perbaikan",
    "isAlreadyTranslated": true
  },
  "Engineer service log creation failed": {
    "text": "Gagal membuat cacatan perbaikan teknisi",
    "isAlreadyTranslated": true
  },
  "Please provide valid input": {
    "text": "Silahkan memberikan penginputan yang valid",
    "isAlreadyTranslated": true
  },
  "Engineer is not registered with this service.": {
    "text": "Teknisi tidak terdaftar untuk perbaikan ini",
    "isAlreadyTranslated": true
  },
  "Service not found for the assignment.": {
    "text": "Perbaikan tidak ditemukan untuk penugasan",
    "isAlreadyTranslated": true
  },
  "All requests has been sent": {
    "text": "Semua permintaan sudah dikirim",
    "isAlreadyTranslated": true
  },
  "Service request is not assigned to the Engineer": {
    "text": "Permintaan perbaikan tidak ditugaskan untuk teknisi",
    "isAlreadyTranslated": true
  },
  "Job has already started": {
    "text": "Pekerjaan sudah dimulai",
    "isAlreadyTranslated": true
  },
  "Delivery of this device is pending. Please Wait for sometime": {
    "text": "Pengiriman perangkat ini tertunda. Silahkan menunggu sesaat",
    "isAlreadyTranslated": true
  },
  "Job started": {
    "text": "Pekerjaan dimulai",
    "isAlreadyTranslated": true
  },
  "Please complete job in progress": {
    "text": "Silahkan selesai pekerjaan yang dalam proses",
    "isAlreadyTranslated": true
  },
  "Engineer is not registered with this service": {
    "text": "Teknisi tidak terdaftar untuk perbaikan ini",
    "isAlreadyTranslated": true
  },
  "Service request is not at required stage": {
    "text": "Permintaan perbaikan tidak pada tahap yang diperlukan",
    "isAlreadyTranslated": true
  },
  "Service initiated": {
    "text": "Perbaikan dimulai",
    "isAlreadyTranslated": true
  },
  "request information fetching failed": {
    "text": "gagal mengambil informasi permintaan",
    "isAlreadyTranslated": true
  },
  "Engineer is not register with this service": {
    "text": "Teknisi tidak terdaftar untuk perbaikan ini",
    "isAlreadyTranslated": true
  },
  "Service is not at required stage": {
    "text": "Perbaikan tidak pada tahan yg diperlukan",
    "isAlreadyTranslated": true
  },
  "Engineer reached": {
    "text": "Teknisi sudah tiba",
    "isAlreadyTranslated": true
  },
  "Product fetching failed": {
    "text": "Gagal mengambil produk",
    "isAlreadyTranslated": true
  },
  "Service completed successfully": {
    "text": "Perbaikan sudah berhasil diselesaikan",
    "isAlreadyTranslated": true
  },
  "No Jobs available": {
    "text": "Tidak ada tugas yang tersedia",
    "isAlreadyTranslated": true
  },
  "Closed Jobs has been sent": {
    "text": "Penutupan pekerjan sudah dikirim",
    "isAlreadyTranslated": true
  },
  "Active jobs has been sent": {
    "text": "Pekerjaan aktif sudah dikirim",
    "isAlreadyTranslated": true
  },
  "Task added": {
    "text": "Tugas ditambah",
    "isAlreadyTranslated": true
  },
  "Deletion successful": {
    "text": "Berhasil menghapus",
    "isAlreadyTranslated": true
  },
  "No such task to remove": {
    "text": "Tidak ada tugas untuk dihilangkan",
    "isAlreadyTranslated": true
  },
  "Notification updates": {
    "text": "Perbaharuan notifikasi",
    "isAlreadyTranslated": true
  },
  "Successfully created": {
    "text": "Pembuatan berhasil",
    "isAlreadyTranslated": true
  },
  "Please provide a valid ReferenceID": {
    "text": "Silahkan memberikan ID reference yang valid",
    "isAlreadyTranslated": true
  },
  "Service location updates fetched": {
    "text": "Pembaruan lokasi servis didapatkan",
    "isAlreadyTranslated": true
  },
  "No entries found for the given ConsumerServiceRequestID": {
    "text": "Tidak ada entri untuk IDPermintaanServisPelanggan yang diberikan",
    "isAlreadyTranslated": true
  },
  "Updated result": {
    "text": "Hasil perbaharuan",
    "isAlreadyTranslated": true
  },
  "No entries found for the given ServicelocationUpdatesID": {
    "text": "Tidak ada entri untuk IDPembaruanLokasiServis yang diberikan",
    "isAlreadyTranslated": true
  },
  "No entries found for the given ConsumerID": {
    "text": "Tidak ada entri untuk IDPelanggan ini",
    "isAlreadyTranslated": true
  },
  "Outlet location id": {
    "text": "Id lokasi outlet",
    "isAlreadyTranslated": true
  },
  "No outlet found": {
    "text": "Tidak ada outlet",
    "isAlreadyTranslated": true
  },
  "Eligibility for option rewards": {
    "text": "Kelayakan untuk opsi hadiah",
    "isAlreadyTranslated": true
  },
  "As you have pledged more than 3 iPhones, you will now be eligible only for the Green Karma Reward.": {
    "text": "Karena Anda telah menjanjikan lebih dari 3 iPhone, sekarang Anda hanya berhak atas Hadiah Green karma.",
    "isAlreadyTranslated": true
  },
  "Compute results": {
    "text": "Hasil komputer",
    "isAlreadyTranslated": true
  },
  "Looks like you have previously pledged to eRecycle your iPhones What would you like to do?": {
    "text": "Sepertinya Anda sudah pernah berjanji untuk eRecycle iPhones sebelumnya Apa yang ingin Anda lakukan?",
    "isAlreadyTranslated": true
  },
  "As you have eRecycled more than 3 iPhones, you will now be eligible only for the Green Karma Reward.": {
    "text": "Karena Anda telah melakukan eRecycle lebih dari 3 iPhone, sekarang Anda hanya berhak atas Hadiah Green karma.",
    "isAlreadyTranslated": true
  },
  "No subscription obtained": {
    "text": "Tidak ada langganan yang diperoleh",
    "isAlreadyTranslated": true
  },
  "Insufficient parameters - Send SubscriptionDetailsID": {
    "text": "Parameter tidak cukup - Kirimkan IDRincianLangganan",
    "isAlreadyTranslated": true
  },
  "No subscription found for the product": {
    "text": "Tidak ada langganan untuk produk ini",
    "isAlreadyTranslated": true
  },
  "Insufficient parameters - Send SubscriptionState": {
    "text": "Parameter tidak cukup - Kirimkan StatusBerlangganan",
    "isAlreadyTranslated": true
  },
  "subscription request details": {
    "text": "rincian permintaan langganan",
    "isAlreadyTranslated": true
  },
  "initialized consumer": {
    "text": "pelanggan yang dimulai",
    "isAlreadyTranslated": true
  },
  "failed to initialize consumer": {
    "text": "gagal memulai pelanggan",
    "isAlreadyTranslated": true
  },
  "Something went wrong with default device addition": {
    "text": "Terjadi masalah saat menambahkan perangkat default",
    "isAlreadyTranslated": true
  },
  "Failed to create consumerProducts": {
    "text": "Gagal membuat ProdukPelanggan",
    "isAlreadyTranslated": true
  },
  "Failed to create ConsumerUnregisteredProducts": {
    "text": "Gagal membuat ProdukTidakTerdaftarPelanggan",
    "isAlreadyTranslated": true
  },
  "successfully created default temp consumer product": {
    "text": "berhasil membuat produk pelanggan sementara default",
    "isAlreadyTranslated": true
  },
  "OTP does not match": {
    "text": "OTP tidak sama",
    "isAlreadyTranslated": true
  },
  "No entity created": {
    "text": "Tidak ada entitas yang dibuat",
    "isAlreadyTranslated": true
  },
  "Something went wrong in registering other devices": {
    "text": "Terjadi masalah saat mendaftarkan perangkat lain",
    "isAlreadyTranslated": true
  },
  "Invalid input": {
    "text": "Input tidak sah",
    "isAlreadyTranslated": true
  },
  "Something went wrong while fetching filtered temp consumers": {
    "text": "Terjadi masalah saat mengambil pelanggan sementara yang difilter",
    "isAlreadyTranslated": true
  },
  "List of filtered temp consumers": {
    "text": "Daftar pelanggan sementara yang difilter",
    "isAlreadyTranslated": true
  },
  "Sms success": {
    "text": "Sms berhasil",
    "isAlreadyTranslated": true
  },
  "device token not in the expected format": {
    "text": "token perangkat tidak memiliki format yang diperkirakan",
    "isAlreadyTranslated": true
  },
  "Your home repair request has been registered. You will receive confirmation 30mins prior to the requested time slot.": {
    "text": "Permintaan perbaikan di rumah Anda telah didaftarkan. Anda akan menerima konfirmasi 30 menit sebelum slot waktu yang diminta.",
    "isAlreadyTranslated": true
  },
  "Your carry in repair request for ( ) at ( ) has been registered. Please check track repair section to get the direction to the location.": {
    "text": "Operator Anda dalam permintaan perbaikan untuk ( ) di ( ) telah didaftarkan. Silakan periksa bagian lacak perbaikan untuk mendapatkan arah menuju lokasi.",
    "isAlreadyTranslated": true
  },
  "Your request for rescheduling has been accepted for ( )": {
    "text": "Permintaan Anda untuk jadwal ulang telah disetujui untuk ( )",
    "isAlreadyTranslated": true
  },
  "( ) is your OTP for Servify. This OTP is valid for 120 seconds. Do not share this OTP with anyone for security reasons.": {
    "text": "( ) adalah OTP Anda untuk Servify. OTP ini berlaku selama 120 detik. Jangan bagikan OTP ini dengan siapa pun demi keamanan.",
    "isAlreadyTranslated": true
  },
  "Download the Servify app from ( ) and get a better service experience. Servify - for all your service needs.": {
    "text": "Unduh aplikasi Servify dari () dan mendapatkan pengalaman layanan yang lebih baik. Servify - untuk semua kebutuhan layanan Anda.",
    "isAlreadyTranslated": true
  },
  "Download Servify - Personal assistant to all your electronics and home appliances.": {
    "text": "Unduh Servify - Asisten pribadi untuk semua alat elektronik dan perabot rumah Anda.",
    "isAlreadyTranslated": true
  },
  "( ) is your OTP for Servify. This OTP is valid for 120 seconds. Do not share this OTP with anyone for security reasons. We look forward to serving you.": {
    "text": "( ) adalah OTP Anda untuk Servify. OTP ini berlaku selama 120 detik. Jangan bagikan OTP ini dengan siapa pun demi keamanan. Kami tak sabar ingin melayani Anda.",
    "isAlreadyTranslated": true
  },
  "You have cancelled your request for scheduled on ( ) We hope to serve you soon.": {
    "text": "Anda telah membatalkan permintaan Anda untuk jadwal pada ( ) Semoga kami dapat melayani Anda lain waktu.",
    "isAlreadyTranslated": true
  },
  "Thank you for choosing servify, please rate our service on the App. We look forward to service you again.": {
    "text": "Terima kasih telah memilih servify, berikanlah nilai untuk layanan kami di App. Kami tak sabar ingin melayani Anda lagi.",
    "isAlreadyTranslated": true
  },
  "Your request number is ( ) . Our Partner ( ) will collect the device from you as per the scheduled time. To know more, please check Track Request section within the App. T&C apply.": {
    "text": "Nomor permintaan Anda adalah ( ). Mitra kami ( ) akan mengambil perangkat Anda sesuai waktu yang dijadwalkan. Untuk informasi selengkapnya, periksa bagian Lacak Permintaan di dalam Aplikasi. Syarat & Ketentuan berlaku.",
    "isAlreadyTranslated": true
  },
  "Your request number is ( ) . Our Logistics Agent will collect the device from you as per the scheduled time. To know more, please check Track Request section within the App. T&C apply.": {
    "text": "Nomor permintaan Anda adalah ( ). Agen Logistik kami akan mengambil perangkat Anda sesuai waktu yang dijadwalkan. Untuk informasi selengkapnya, periksa bagian Lacak Permintaan di dalam Aplikasi. Syarat & Ketentuan berlaku.",
    "isAlreadyTranslated": true
  },
  "| We have received your claim request. Our representative will connect with you in next 48 hours.": {
    "text": "| Kami telah menerima permintaan klaim Anda. Perwakilan kami akan menghubungi Anda dalam 48 jam ke depan.",
    "isAlreadyTranslated": true
  },
  "Your device will be picked up on ( ) . Your Secret Code is ( ) . Please handover the device to the Logistics Agent only once they provide this secret code to prove their identity.": {
    "text": "Perangkat Anda akan dijemput pada ( ). Kode Rahasia Anda adalah ( ). Serahkan perangkat hanya kepada Agen Logistik setelah dia memberikan kode rahasia ini untuk membuktikan identitasnya.",
    "isAlreadyTranslated": true
  },
  "Your Activation Code to register for the Plan is ( ). Download the app from and get a better service experience.": {
    "text": "Kode Aktivasi Anda mendaftar untuk Rencana adalah (). Download aplikasi dari dan mendapatkan pengalaman layanan yang lebih baik.",
    "isAlreadyTranslated": true
  },
  "Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips. Download now.": {
    "text": "Dapatkan Servis Resmi untuk iPhone, iPad, Apple Watch dan Mac dari perangkat Anda. Unduh sekarang.",
    "isAlreadyTranslated": true
  },
  "Pick up of your (Ref No. -) has been rescheduled to as requested.": {
    "text": "Penjemputan (No. Ref -) Anda telah dijadwalkan ulang sesuai permintaan.",
    "isAlreadyTranslated": true
  },
  "Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips. iOS App - Web Portal -": {
    "text": "Dapatkan Servis Resmi untuk iPhone, iPad, Watch dan Mac dari perangkat Anda. iOS App - Web Portal -",
    "isAlreadyTranslated": true
  },
  "Your request is registered. We will contact you for an appointment. For assistance, please call us on 1800-108-1333.": {
    "text": "Permintaan Anda sudah terdaftar. Kami akan menghubungi Anda untuk membuat janji. Untuk bantuan, silakan hubungi kami di 1800-108-1333.",
    "isAlreadyTranslated": true
  },
  "Device Picked up: Your (Ref ID -) was collected by Servify Executive on ( ) and will be delivered to an Authorized Service Centre. To know more, please check Track Repair section within the App. T&C Apply.": {
    "text": "Perangkat Dijemput: (ID Ref -) Anda dikumpulkan oleh Servify Executive pada ( ) dan akan dikirimkan ke Pusat Servis Resmi. Untuk informasi selengkapnya, periksa bagian Lacak Perbaikan di dalam Aplikasi. Syarat & Ketentuan Berlaku.",
    "isAlreadyTranslated": true
  },
  "( ) is your OTP for Micromax Care. This OTP is valid for 120 seconds. Do not share this OTP with any one for security reasons. We look forward to serving you.": {
    "text": "( ) adalah OTP (Sandi Sementara) Anda untuk Micromax Care. OTP ini berlaku selama 120 detik. Jangan membagikan OTP ini dengan siapa pun demi keamanan. Kami tak sabar ingin melayani Anda.",
    "isAlreadyTranslated": true
  },
  "| We have received your claim request. Our representative will connect with you in next one business day.": {
    "text": "| Kami telah menerima permintaan klaim Anda. Perwakilan kami akan menghubungi Anda di hari kerja berikutnya.",
    "isAlreadyTranslated": true
  },
  "Pickup Rescheduled: Logistics Agent will attempt pickup of your ( ) on ( ) as requested.": {
    "text": "Penjemputan Dijadwalkan Ulang: Agen Logistik akan melakukan penjemputan ( ) Anda pada ( ) sesuai permintaan.",
    "isAlreadyTranslated": true
  },
  "New Request Ref. ID": {
    "text": "Permintaan ID Ref Baru",
    "isAlreadyTranslated": true
  },
  "Phone": {
    "text": "Nomor Telephone",
    "isAlreadyTranslated": true
  },
  "Your dropoff is scheduled on ( ) at ( ) Ref ID:": {
    "text": "Pengantaran Anda dijadwalkan pada ( ) di ( ) ID Ref:",
    "isAlreadyTranslated": true
  },
  "Download \"eRecycle\" on your iPhone to raise and track eRecycle requests on the go.": {
    "text": "Ambil \"eRecycle\" pada iPhone Anda untuk meningkatkan dan melacak permintaan eRecycle di perjalanan.",
    "isAlreadyTranslated": true
  },
  "Dear Customer,We have received a claim request for your device. Please click on the following link to cross check the information shared by you and submit to raise the claim. Kindly note, once submitted, the details cannot be changed.": {
    "text": "Pelanggan yang Terhormat, Kami telah menerima permintaan klaim untuk perangkat Anda. Klik tautan berikut untuk memeriksa informasi yang Anda berikan dan kirim untuk mengajukan klaim. Perhatikan, setelah dikirim, rincian tidak bisa diubah.",
    "isAlreadyTranslated": true
  },
  "Service Cancelled": {
    "text": "Perbaikan dibatalkan",
    "isAlreadyTranslated": true
  },
  "Repair request ( ) has been canceled by the consumer": {
    "text": "Permintaan perbaikan ( ) telah dibatalkan oleh pelanggan",
    "isAlreadyTranslated": true
  },
  "Repair Completed": {
    "text": "Perbaikan Selesai",
    "isAlreadyTranslated": true
  },
  "Your ( ) has been repaired. Please collect it from ( )": {
    "text": "( ) Anda telah diperbaiki. Silakan ambil di ( )",
    "isAlreadyTranslated": true
  },
  "Your cancellation request has been accepted for carry in repair": {
    "text": "permintaan pembatalan Anda telah diterima untuk membawa dalam perbaikan",
    "isAlreadyTranslated": true
  },
  "Your cancellation request has been accepted for home repair": {
    "text": "permintaan pembatalan Anda telah diterima untuk perbaikan rumah",
    "isAlreadyTranslated": true
  },
  "Request Reschedule": {
    "text": "Permintaan Jadwal Ulang",
    "isAlreadyTranslated": true
  },
  "Your request for rescheduling has been accepted for": {
    "text": "Permintaan penjadwalan ulang telah diterima untuk",
    "isAlreadyTranslated": true
  },
  "service reschedule": {
    "text": "Jadwal Ulang Perbaikan",
    "isAlreadyTranslated": true
  },
  "Repair request": {
    "text": "Permintaan Perbaikan",
    "isAlreadyTranslated": true
  },
  "Repair initiated for your request": {
    "text": "Perbaikan dimulain untuk permintaan anda",
    "isAlreadyTranslated": true
  },
  "Service Completed": {
    "text": "Perbaikan Selesai",
    "isAlreadyTranslated": true
  },
  "Your ( ) has been repaired & delivered.": {
    "text": "( ) Anda telah diperbaiki & dikirim.",
    "isAlreadyTranslated": true
  },
  "Your repair request has been canceled": {
    "text": "Permintaan perbaikan telah dibatalkan",
    "isAlreadyTranslated": true
  },
  "Installation request ( ) has been rescheduled by the consumer": {
    "text": "permintaan instalasi () telah dijadwal ulang oleh konsumen",
    "isAlreadyTranslated": true
  },
  "Demo request ( ) has been rescheduled by the consumer": {
    "text": "Permintaan demo ( ) telah dijadwalkan ulang oleh pelanggan",
    "isAlreadyTranslated": true
  },
  "Service request ( ) has been rescheduled by the consumer": {
    "text": "Permintaan servis ( ) telah dijadwalkan ulang oleh pelanggan",
    "isAlreadyTranslated": true
  },
  "Your cancellation request has been accepted for demo": {
    "text": "permintaan pembatalan Anda telah diterima untuk demo",
    "isAlreadyTranslated": true
  },
  "Installation request": {
    "text": "Permintaan Instalasi",
    "isAlreadyTranslated": true
  },
  "has been canceled by the consumer": {
    "text": "telah dibatalkan oleh pelanggan",
    "isAlreadyTranslated": true
  },
  "Demo request": {
    "text": "Permintaan demo",
    "isAlreadyTranslated": true
  },
  "Your cancellation request has been accepted for service": {
    "text": "permintaan pembatalan Anda telah diterima untuk layanan",
    "isAlreadyTranslated": true
  },
  "Your cancellation request has been accepted for pickup drop": {
    "text": "permintaan pembatalan Anda telah diterima untuk penurunan pikap",
    "isAlreadyTranslated": true
  },
  "Service Created": {
    "text": "Perbaikan Dibuat",
    "isAlreadyTranslated": true
  },
  "Your request for ( ) has been registered.": {
    "text": "Permintaan Anda untuk ( ) telah didaftarkan.",
    "isAlreadyTranslated": true
  },
  "Service suspended": {
    "text": "Perbaikan dihentikan",
    "isAlreadyTranslated": true
  },
  "Your request ( ) has been suspended": {
    "text": "Permintaan Anda ( ) telah ditunda",
    "isAlreadyTranslated": true
  },
  "Service invoice generated": {
    "text": "Invoice Perbaikan dibuat",
    "isAlreadyTranslated": true
  },
  "Your service request is Rescheduled by ( )": {
    "text": "Permintaan perbaikan Anda telah dijadwalkan ulang oleh ( )",
    "isAlreadyTranslated": true
  },
  "Service accepted": {
    "text": "Perbaikan diterima",
    "isAlreadyTranslated": true
  },
  "Sweet! ( ) has accepted your request to fix your ( )": {
    "text": "Bagus! ( ) telah menerima permintaan Anda untuk memperbaiki ( ) Anda",
    "isAlreadyTranslated": true
  },
  "Serviceman journey initiated": {
    "text": "Tukang servis memulai perjalanan",
    "isAlreadyTranslated": true
  },
  "( ) is on the way to take care of your ( )": {
    "text": "( ) dalam perjalanan untuk mengurus ( ) Anda",
    "isAlreadyTranslated": true
  },
  "Our expert ( ) has initiated the repair work.": {
    "text": "Pakar kami ( ) telah memulai pekerjaan perbaikan.",
    "isAlreadyTranslated": true
  },
  "An invoice has been generated for your service.": {
    "text": "Invoice sudah diterbitkan untuk perbaikan Anda",
    "isAlreadyTranslated": true
  },
  "Payment received for repair request": {
    "text": "Pembayaran diterima untuk permintaan perbaikan",
    "isAlreadyTranslated": true
  },
  "Request cancelled": {
    "text": "Permintaan dibatalkan",
    "isAlreadyTranslated": true
  },
  "You have cancelled your request for ( ) scheduled on ( ). We hope to serve you soon.": {
    "text": "Anda telah membatalkan permintaan Anda untuk ( ) yang dijadwalkan pada ( ). Semoga kami dapat membantu Anda lain kali.",
    "isAlreadyTranslated": true
  },
  "All required keys are present": {
    "text": "Semua kunci yang diperlukan sudah ada",
    "isAlreadyTranslated": true
  },
  "Email sent successfully": {
    "text": "Berhasil mengirim email",
    "isAlreadyTranslated": true
  },
  "error in sending email": {
    "text": "kesalahan saat mengirimkan email",
    "isAlreadyTranslated": true
  },
  "Cannot send email": {
    "text": "Tidak dapat mengirim email",
    "isAlreadyTranslated": true
  },
  "Email is sent successfully": {
    "text": "Berhasil mengirim email",
    "isAlreadyTranslated": true
  },
  "Minimum Required Entities Not Found": {
    "text": "Entitas Minimum yang Diperlukan Tidak Ada",
    "isAlreadyTranslated": true
  },
  "No gsx params found": {
    "text": "Tidak ada param gsx",
    "isAlreadyTranslated": true
  },
  "Match found": {
    "text": "Kecocokan ditemukan",
    "isAlreadyTranslated": true
  },
  "No match found": {
    "text": "Tidak ditemukan kecocokan",
    "isAlreadyTranslated": true
  },
  "The email address entered doesn't seem to be a valid EmailID": {
    "text": "Alamat Email yang dimasukkan tidak sesuai untuk ID email yang valid",
    "isAlreadyTranslated": true
  },
  "Oops! Looks like this email ID does not belong to any organization pre-selected for this program. Please enter correct email ID in case of any errors.": {
    "text": "Ups! Sepertinya ID email ini bukan milik organisasi yang dipilih sebelumnya untuk program ini. Tulis ID email yang benar jika Terjadi masalah.",
    "isAlreadyTranslated": true
  },
  "Looks like you have already redeemed 2 vouchers.": {
    "text": "Sepertinya Anda telah menukarkan 2 voucher.",
    "isAlreadyTranslated": true
  },
  "Oops! Looks like currently there are no more vouchers available for this program. Please try again after some time.": {
    "text": "Ups! Sepertinya saat ini tidak ada voucher yang tersedia untuk program ini. Coba lagi setelah beberapa menit.",
    "isAlreadyTranslated": true
  },
  "No valid voucher found": {
    "text": "Tidak ada voucher yang valid",
    "isAlreadyTranslated": true
  },
  "Looks like this voucher is invalid": {
    "text": "Sepertinya voucher ini tidak valid",
    "isAlreadyTranslated": true
  },
  "Valid Voucher": {
    "text": "Voucher yang Valid",
    "isAlreadyTranslated": true
  },
  "Seems like the Imei or Serial does not match with that of the device purchased": {
    "text": "Sepertinya Imei atau nomor serial tidak cocok dengan perangkat yang dibelikan",
    "isAlreadyTranslated": true
  },
  "Looks like you have already activated the enterprise support plan for this device": {
    "text": "Sepertinya Anda telah mengaktifkan paket dukungan perusahaan untuk perangkat ini",
    "isAlreadyTranslated": true
  },
  "Insufficient Params - Send either VoucherID or VoucherObj": {
    "text": "Parameter tidak cukup - Kirimkan IDVoucher atau ObjVoucher",
    "isAlreadyTranslated": true
  },
  "No voucher object found": {
    "text": "Tidak ada objek voucher",
    "isAlreadyTranslated": true
  },
  "This Page has no data": {
    "text": "Halaman ini tidak memiliki data",
    "isAlreadyTranslated": true
  },
  "You don't have access to this brand": {
    "text": "Anda tidak memiliki akses ke merek ini",
    "isAlreadyTranslated": true
  },
  "You don't have access to this service Location": {
    "text": "Anda tidak memiliki akses ke Lokasi servis ini",
    "isAlreadyTranslated": true
  },
  "You don't have access to this zipcode": {
    "text": "Anda tidak memiliki akses ke kode pos ini",
    "isAlreadyTranslated": true
  },
  "You don't have access to this consumer service request": {
    "text": "Anda tidak memiliki akses ke permintaan servis pelanggan ini",
    "isAlreadyTranslated": true
  },
  "Invalid ConsumerServiceRequestID": {
    "text": "IDPermintaanServisPelanggan tidak valid",
    "isAlreadyTranslated": true
  },
  "This request is already assigned to ( )": {
    "text": "Permintaan ini sudah ditetapkan pada ( )",
    "isAlreadyTranslated": true
  },
  "Consumer service request log creation failed |": {
    "text": "Gagal membuat catatan permintaan servis pelanggan |",
    "isAlreadyTranslated": true
  },
  "Cannot (Re)Assign consumer service request": {
    "text": "Tidak dapat Menetapkan (Ulang) permintaan servis pelanggan",
    "isAlreadyTranslated": true
  },
  "This service location isn't active anymore": {
    "text": "Lokasi servis ini sudah tidak aktif lagi",
    "isAlreadyTranslated": true
  },
  "Service is not at the required stage": {
    "text": "Servis tidak berada dalam tahap yang diperlukan",
    "isAlreadyTranslated": true
  },
  "Invalid charge name": {
    "text": "Nama biaya tidak valid",
    "isAlreadyTranslated": true
  },
  "Product Purchase cost and date are required for this action": {
    "text": "Diperlukan biaya dan tanggal Pembelian Produk untuk tindakan ini",
    "isAlreadyTranslated": true
  },
  "Invalid Type": {
    "text": "Jenis tidak valid",
    "isAlreadyTranslated": true
  },
  "You don't have enough rights to approve this claim request": {
    "text": "Anda tidak berhak menyetujui permintaan klaim ini",
    "isAlreadyTranslated": true
  },
  "You don't have enough rights to add charges": {
    "text": "Anda tidak berhak menambahkan biaya",
    "isAlreadyTranslated": true
  },
  "The advance payment cannot be more than the consumer amount": {
    "text": "Uang muka tidak boleh melebihi biaya pelanggan",
    "isAlreadyTranslated": true
  },
  "Approved claim amount cannot be greater than the total billed amount": {
    "text": "Tidak bisa menyetujui jumlah klaim yang lebih dari jumlah tagihan",
    "isAlreadyTranslated": true
  },
  "Discounts cannot be greater than the taxable amount": {
    "text": "Diskon tidak boleh melebihi jumlah yang dikenai pajak",
    "isAlreadyTranslated": true
  },
  "Discount check not requires": {
    "text": "Tidak diperlukan pemeriksaan diskon",
    "isAlreadyTranslated": true
  },
  "Charges Updated": {
    "text": "Biaya diperbaharui",
    "isAlreadyTranslated": true
  },
  "Not a valid charge to update": {
    "text": "Biaya diperbaharui yang tidak valid",
    "isAlreadyTranslated": true
  },
  "Invalid Consumer Service Request": {
    "text": "Permintaan Perbaikan Pelanggan tidak valid",
    "isAlreadyTranslated": true
  },
  "You don't have enough rights to modify this claim request": {
    "text": "Anda tidak berhak merubah permintaan klaim ini",
    "isAlreadyTranslated": true
  },
  "Previous service type not found": {
    "text": "Jenis perbaikan sebelumnya tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "Insufficient parameters - Send Remarks to override estimation": {
    "text": "Parameter tidak cukup - Kirimkan Keterangan untuk mengabaikan estimasi",
    "isAlreadyTranslated": true
  },
  "delivery confirmation sent": {
    "text": "Konfirmasi Pengiriman terkirim",
    "isAlreadyTranslated": true
  },
  "delivery confirmation not sent": {
    "text": "Konfirmasi Pengiriman belum terkirim",
    "isAlreadyTranslated": true
  },
  "no logistics request found": {
    "text": "tidak ada permintaan logistik",
    "isAlreadyTranslated": true
  },
  "Your device is ready to be collected by you. We hope you are satisfied with the service experience. Reference ID:": {
    "text": "Perangkat Anda siap diambil. Semoga Anda puas dengan pengalaman servis ini. ID Referensi:",
    "isAlreadyTranslated": true
  },
  "Something went wrong while fetching consumer service request logs to calculate tax": {
    "text": "Terjadi masalah saat mengambil catatan permintaan servis pelanggan untuk menghitung pajak",
    "isAlreadyTranslated": true
  },
  "Homepage count has been sent successfully": {
    "text": "Berhasil mengirimkan jumlah halaman depan",
    "isAlreadyTranslated": true
  },
  "Invalid Request Remarks": {
    "text": "Keterangan Permintaan tidak valid",
    "isAlreadyTranslated": true
  },
  "Something went wrong in B2X/updateJob": {
    "text": "Terjadi kesalahan di B2X/perbaruiTugas",
    "isAlreadyTranslated": true
  },
  "Status updated": {
    "text": "Status diperbaharui",
    "isAlreadyTranslated": true
  },
  "Status updated but error in updating consumer product": {
    "text": "Status diperbarui tapi Terjadi masalah saat memperbarui produk pelanggan",
    "isAlreadyTranslated": true
  },
  "logistics type updated": {
    "text": "tipe logistik diperbarui",
    "isAlreadyTranslated": true
  },
  "logistics type not updated": {
    "text": "tipe logistik tidak diperbarui",
    "isAlreadyTranslated": true
  },
  "successfully log created": {
    "text": "berhasil membuat catatan",
    "isAlreadyTranslated": true
  },
  "remarks updated": {
    "text": "Keterangan diperbaharui",
    "isAlreadyTranslated": true
  },
  "no previous log found": {
    "text": "Tidak ditemukan catatan sebelumnya",
    "isAlreadyTranslated": true
  },
  "DOA checklist not completed": {
    "text": "Checklist DOA Belum Selesai",
    "isAlreadyTranslated": true
  },
  "DOA checklist not sent": {
    "text": "Checklist DOA Belum Dikirim",
    "isAlreadyTranslated": true
  },
  "Something went wrong while creating log": {
    "text": "Terjadi kesalahan dalan membuat catatan",
    "isAlreadyTranslated": true
  },
  "Cannot update the status": {
    "text": "Tidak bisa memperbarui status",
    "isAlreadyTranslated": true
  },
  "workshop request": {
    "text": "Permintaan workshop",
    "isAlreadyTranslated": true
  },
  "Not a workshop request": {
    "text": "Bukan permintaan workshop",
    "isAlreadyTranslated": true
  },
  "Invalid Service Request": {
    "text": "Permintaan perbaikan tidak valid",
    "isAlreadyTranslated": true
  },
  "Error in getting data from getCharges": {
    "text": "Kesalahan saat mendapatkan data dari DapatkanBiaya",
    "isAlreadyTranslated": true
  },
  "Invoice details fetched": {
    "text": "Rincian kwitansi diambil",
    "isAlreadyTranslated": true
  },
  "Error in Finding PartnerServicelocation Detail": {
    "text": "Kesalahan dalam Menemukan Rincian LokasiServisMitra",
    "isAlreadyTranslated": true
  },
  "Error in Finding Consumer Detail": {
    "text": "Kesalahan dalam Menemukan Rincian Pelanggan",
    "isAlreadyTranslated": true
  },
  "No Data Found in getCharges": {
    "text": "Tidak Ada Data di DapatkanBiaya",
    "isAlreadyTranslated": true
  },
  "You don't have enough rights to delete approved claim amount": {
    "text": "Anda tidak berhak menghapus permintaan klaim yang disetujui",
    "isAlreadyTranslated": true
  },
  "You don't have enough rights to delete this charge": {
    "text": "Anda tidak berhak menghapus biaya ini",
    "isAlreadyTranslated": true
  },
  "You cannot delete service request charge at the given status": {
    "text": "Anda tidak berhak menghapus biaya permintaan perbaikan dengan status yang diberikan",
    "isAlreadyTranslated": true
  },
  "No charge exists by this ID": {
    "text": "Tidak ada biaya tersedia dengan ID ini",
    "isAlreadyTranslated": true
  },
  "This service request doesn't have an associated partner": {
    "text": "Permintaan perbaikan ini tidak memiliki mitra terkait",
    "isAlreadyTranslated": true
  },
  "No consumer service request found by this id": {
    "text": "Permintaan perbaikan pelanggan tidak ditemukan untuk ID ini",
    "isAlreadyTranslated": true
  },
  "No State Found": {
    "text": "Tidak negara yang ditemukan",
    "isAlreadyTranslated": true
  },
  "Exclusive Brands": {
    "text": "Merek Eksklusif",
    "isAlreadyTranslated": true
  },
  "Exclusive Category": {
    "text": "Kategori Eksklusif",
    "isAlreadyTranslated": true
  },
  "product is not exclusive": {
    "text": "Produk tidak eksklusif",
    "isAlreadyTranslated": true
  },
  "Error in fetching exclusive category": {
    "text": "Kesalahan saat mengambil kategori eksklusif",
    "isAlreadyTranslated": true
  },
  "no brand found with given brand id": {
    "text": "Tidak ditemukan merek untuk ID merek yang diberikan",
    "isAlreadyTranslated": true
  },
  "Error in finding brand": {
    "text": "Kesalahan dalam mencari merek",
    "isAlreadyTranslated": true
  },
  "No consumer product found to service": {
    "text": "Produk pelanggan tidak ditemukan untuk perbaikan",
    "isAlreadyTranslated": true
  },
  "Invalid IsNonPartner parameter": {
    "text": "Parameter BukanMitra yang Tidak Valid",
    "isAlreadyTranslated": true
  },
  "Cannot create the request": {
    "text": "Tidak bisa membuat permintaan",
    "isAlreadyTranslated": true
  },
  "No slots available for appointment on this date": {
    "text": "Tidak ada slot untuk janji di tanggal ini",
    "isAlreadyTranslated": true
  },
  "Slots obtained": {
    "text": "Slot didapatkan",
    "isAlreadyTranslated": true
  },
  "No SoldPlanID found": {
    "text": "Tidak ada IDPaketTerjual",
    "isAlreadyTranslated": true
  },
  "Servify Claim Request": {
    "text": "Permintaan Klaim Servify",
    "isAlreadyTranslated": true
  },
  "succesfully claim raised": {
    "text": "Berhasil mengajukan Klaim",
    "isAlreadyTranslated": true
  },
  "Product already in repair": {
    "text": "Produk sudah diperbaiki",
    "isAlreadyTranslated": true
  },
  "Error in creating service request logs": {
    "text": "Kesalahan dalam membuat catatan permintaan perbaikan",
    "isAlreadyTranslated": true
  },
  "No document sent for adding doc": {
    "text": "Tidak ada dokumen yang dikirim untuk penambahan dokumen",
    "isAlreadyTranslated": true
  },
  "No new Claim request document uploaded": {
    "text": "Tidak ada dokumen permintaan klaim yg diupload",
    "isAlreadyTranslated": true
  },
  "Documents successfully added": {
    "text": "Dokumen berhasil ditambahkan",
    "isAlreadyTranslated": true
  },
  "Status should be claim approved": {
    "text": "Status",
    "isAlreadyTranslated": true
  },
  "Request already created": {
    "text": "Permintaan sudah dibuat",
    "isAlreadyTranslated": true
  },
  "Error in scheduleRepair": {
    "text": "Kesalahan dalam Jadwal perbaikan",
    "isAlreadyTranslated": true
  },
  "no product found for given consumer product ID": {
    "text": "Tidak ada produk ditemukan untuk ID produk pelanggan yang diberikan",
    "isAlreadyTranslated": true
  },
  "No consumer service data found": {
    "text": "Data perbaikan pelanggan tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "no request id found": {
    "text": "Permintaan ID tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "no service center found with given id": {
    "text": "tidak ada pusat servis berdasarkan id yang diberikan",
    "isAlreadyTranslated": true
  },
  "Successfully Carry in Request Created": {
    "text": "Permintaan Carry In berhasil dibuat",
    "isAlreadyTranslated": true
  },
  "Please Provide Valid Servie Type": {
    "text": "Silahkan memberikan Jenis perbaikan yang valid",
    "isAlreadyTranslated": true
  },
  "Call not Attempted": {
    "text": "Belum mencoba menghubungi",
    "isAlreadyTranslated": true
  },
  "Connected Call": {
    "text": "Panggilan tersambung",
    "isAlreadyTranslated": true
  },
  "Non-Connected Call": {
    "text": "Tidak panggilan terhubungi",
    "isAlreadyTranslated": true
  },
  "Call Log Details": {
    "text": "Detil catatan panggilan",
    "isAlreadyTranslated": true
  },
  "Attached with this mail is the call logs data": {
    "text": "Terlampir diemail ini adalah data catatan panggilan",
    "isAlreadyTranslated": true
  },
  "No logs found": {
    "text": "Tidak ditemukan catatan",
    "isAlreadyTranslated": true
  },
  "ConsumerComplaints success": {
    "text": "Keluhan Pelanggan Berhasil",
    "isAlreadyTranslated": true
  },
  "ConsumerComplaints failed": {
    "text": "Keluhan Pelanggan Gagal",
    "isAlreadyTranslated": true
  },
  "address cannot be updated": {
    "text": "Alamat Tidak bisa diperbaharui",
    "isAlreadyTranslated": true
  },
  "address cannot be deleted": {
    "text": "Alamat Tidak bisa dihapus",
    "isAlreadyTranslated": true
  },
  "User location fetched": {
    "text": "Lokasi pengguna diambil",
    "isAlreadyTranslated": true
  },
  "user locations for product fetched": {
    "text": "lokasi pengguna untuk produk diambil",
    "isAlreadyTranslated": true
  },
  "Query Result not found": {
    "text": "Hasil Pertanyaan tidak ada",
    "isAlreadyTranslated": true
  },
  "Query Result of location not found": {
    "text": "Hasil Pertanyaan lokasi tidak ada",
    "isAlreadyTranslated": true
  },
  "Product documents added": {
    "text": "Dokumen produk ditambahkan",
    "isAlreadyTranslated": true
  },
  "failed to add product documents": {
    "text": "gagal menambahkan dokumen produk",
    "isAlreadyTranslated": true
  },
  "Successfully deleted": {
    "text": "Berhasil dihapus",
    "isAlreadyTranslated": true
  },
  "success in getting brand number": {
    "text": "berhasil mendapatkan nomor merek",
    "isAlreadyTranslated": true
  },
  "No Brand Found for given brandID": {
    "text": "Tidak Ada Merek berdasarkan IDMerek ini",
    "isAlreadyTranslated": true
  },
  "successfully created product": {
    "text": "produk berhasil dibuat",
    "isAlreadyTranslated": true
  },
  "Serial Number Not Unique": {
    "text": "Nomor Seri Tidak Unik",
    "isAlreadyTranslated": true
  },
  "device added successfully": {
    "text": "Perangkat berhasil ditambahkan",
    "isAlreadyTranslated": true
  },
  "Failed to create ConsumerUnregisteredProduct": {
    "text": "Gagal membuat ProdukTidakTerdaftarPelanggan",
    "isAlreadyTranslated": true
  },
  "Purchase date cannot be greater than device damage date": {
    "text": "Tanggal pembelian tidak boleh melewati tanggal kerusakan perangkat",
    "isAlreadyTranslated": true
  },
  "Cannot activate ADPP after": {
    "text": "Tidak dapat mengaktifkan ADPP setelah",
    "isAlreadyTranslated": true
  },
  "No need to activate sold plan as soldplan status =": {
    "text": "Tidak perlu mengaktifkan paket penjualan sebagai status PaketTerjual =",
    "isAlreadyTranslated": true
  },
  "ConsumerUnregisteredProduct made inactive": {
    "text": "ProdukTidakTerdaftarPelanggan dimatikan",
    "isAlreadyTranslated": true
  },
  "ConsumerProduct made inactive": {
    "text": "ProdukPelanggan dimatikan",
    "isAlreadyTranslated": true
  },
  "Filtered devices of consumer returned": {
    "text": "Perangkat pelanggan yang difilter sudah dikembalikan",
    "isAlreadyTranslated": true
  },
  "No devices under repair": {
    "text": "Tidak ada perangkat yang diperbaiki",
    "isAlreadyTranslated": true
  },
  "No devices in repair history yet": {
    "text": "Belum ada perangkat dalam riwayat perbaikan",
    "isAlreadyTranslated": true
  },
  "Device detail obtained": {
    "text": "Rincian perangkat sudah didapatkan",
    "isAlreadyTranslated": true
  },
  "Updated Plan": {
    "text": "Paket yang Diperbarui",
    "isAlreadyTranslated": true
  },
  "No ConsumerProduct Found with given imei": {
    "text": "Tidak Ada ProdukPelanggan berdasarkan imei yang diberikan",
    "isAlreadyTranslated": true
  },
  "Something Erroneous happened while fetching registered product details": {
    "text": "Terjadi masalah saat mengambil rincian produk terdaftar",
    "isAlreadyTranslated": true
  },
  "Consumer Product is not a deleted product": {
    "text": "Produk Pelanggan bukan produk yang dihapus",
    "isAlreadyTranslated": true
  },
  "Insufficient parameters - Send ConsumerProductID": {
    "text": "Parameter tidak cukup - Kirimkan IDProdukPelanggan",
    "isAlreadyTranslated": true
  },
  "Something Erroneous happened while updating the consumer product": {
    "text": "Terjadi masalah saat memperbarui produk pelanggan",
    "isAlreadyTranslated": true
  },
  "update ConsumerUnregisteredProduct data and converted it to a ConsumerProduct": {
    "text": "perbarui data ProdukTidakTerdaftarPelanggan dan konversikan ke ProdukPelanggan",
    "isAlreadyTranslated": true
  },
  "Something went wrong with findById in ConsumerUnregisteredProduct table": {
    "text": "Terjadi masalah pada CariMenurutID dalam tabel ProdukTidakTerdaftarPelanggan",
    "isAlreadyTranslated": true
  },
  "Device has been validated": {
    "text": "Perangkat sudah divalidasi",
    "isAlreadyTranslated": true
  },
  "Device has been validated without response": {
    "text": "Perangkat sudah divalidasi tanpa direspon",
    "isAlreadyTranslated": true
  },
  "Unknown Model": {
    "text": "Model tidak diketahui",
    "isAlreadyTranslated": true
  },
  "Successfully Returned Model Properties": {
    "text": "Berhasil Mengembalikan Properti Model",
    "isAlreadyTranslated": true
  },
  "Error in fetching consumer product details": {
    "text": "Terjadi masalah saat mengambil rincian produk konsumen",
    "isAlreadyTranslated": true
  },
  "ConsumerProduct details fetched": {
    "text": "Rincian ProdukPelanggan sudah diambil",
    "isAlreadyTranslated": true
  },
  "Error in adding device": {
    "text": "Terjadi kesalahan dalam penambahan perangkat",
    "isAlreadyTranslated": true
  },
  "Error in sending Sms": {
    "text": "Terjadi kesalah dalam mengirim Sms",
    "isAlreadyTranslated": true
  },
  "Successfully sent Sms": {
    "text": "Berhasil mengirim sms",
    "isAlreadyTranslated": true
  },
  "Cannot send sms": {
    "text": "Tidak bisa mengirim SMS",
    "isAlreadyTranslated": true
  },
  "Error in updating data": {
    "text": "Terjadi kesalahan dalam perbaharuan data",
    "isAlreadyTranslated": true
  },
  "Imei already exist": {
    "text": "Imei sudah ada",
    "isAlreadyTranslated": true
  },
  "No SerialNo or Imei Present - GSX call not required": {
    "text": "No Imei atau NoSeri Tersedia - Tidak diperlukan panggilan GSX",
    "isAlreadyTranslated": true
  },
  "This account/device has been blocked. For assistance, reach out to us.": {
    "text": "Akun/perangkat ini sudah diblokir. Untuk bantuan, hubungi kami.",
    "isAlreadyTranslated": true
  },
  "Looks like the @UNIQUE_ID_TYPE Number you have provided is invalid. Note that multiple invalid attempts may block this account/device": {
    "text": "Sepertinya Nomor @UNIQUE_ID_TYPE yang Anda tulis tidak valid. Ingat, beberapa kali upaya tidak valid dapat memblokir akun/perangkat ini",
    "isAlreadyTranslated": true
  },
  "An unknown error occurred": {
    "text": "Terjadi kesalahan yang tidak diketahui",
    "isAlreadyTranslated": true
  },
  "Error in finding product name match": {
    "text": "Terjadi kesalahan dalam mencari nama produk yang sama",
    "isAlreadyTranslated": true
  },
  "Error in finding product subcategory": {
    "text": "Terjadi kesalahan dalam mencari nama produk subkategori",
    "isAlreadyTranslated": true
  },
  "Product details fetched": {
    "text": "Rincian produk sudah diambil",
    "isAlreadyTranslated": true
  },
  "Service Report": {
    "text": "Laporan Service",
    "isAlreadyTranslated": true
  },
  "Product Inward Date": {
    "text": "Tanggal masuk produk",
    "isAlreadyTranslated": true
  },
  "Service Provider's Address": {
    "text": "Penyedia Layanan 's Alamat",
    "isAlreadyTranslated": true
  },
  "Problem Reported by Customer": {
    "text": "Masalah yang dilaporan oleh pelanggan",
    "isAlreadyTranslated": true
  },
  "Product Details": {
    "text": "Detil Produk",
    "isAlreadyTranslated": true
  },
  "Estimated Repair Cost": {
    "text": "Estimasi biaya perbaikan",
    "isAlreadyTranslated": true
  },
  "Insufficient parameters - Send Either Device Object or Imei/SerialNo": {
    "text": "Parameter tidak cukup - Kirimkan Objek Perangkat atau Imei/NoSeri",
    "isAlreadyTranslated": true
  },
  "Plan successfully created": {
    "text": "Berhasil membuat paket",
    "isAlreadyTranslated": true
  },
  "Sorry! No Plans are available for this device": {
    "text": "Maaf! Tidak tersedia Paket untuk perangkat ini",
    "isAlreadyTranslated": true
  },
  "NO_PLANS_AVAILABLE": {
    "text": "NO_PLANS_AVAILABLE",
    "isAlreadyTranslated": true
  },
  "This device is already covered under AppleCare Protection Plan": {
    "text": "Perangkat ini sudah dijamin dalam Paket Perlindungan AppleCare",
    "isAlreadyTranslated": true
  },
  "ALREADY_PURCHASED": {
    "text": "ALREADY_PURCHASED",
    "isAlreadyTranslated": true
  },
  "Limit for imei checks exceeded": {
    "text": "Batas pemeriksaan imei sudah terlampaui",
    "isAlreadyTranslated": true
  },
  "Your Device is not under warranty": {
    "text": "Perangkat Anda tidak dalam masa garansi",
    "isAlreadyTranslated": true
  },
  "NOT_ELIGIBLE": {
    "text": "Tidak memenuhi syarat",
    "isAlreadyTranslated": true
  },
  "Invalid serial number": {
    "text": "Nomor Serial tidak Valid",
    "isAlreadyTranslated": true
  },
  "INCORRECT_SERIAL_NO": {
    "text": "Nomor Serial salah",
    "isAlreadyTranslated": true
  },
  "All applicable plans are activated for this device.": {
    "text": "Semua paket yang berlaku diaktifkan untuk perangkat ini.",
    "isAlreadyTranslated": true
  },
  "Plan is already active against this device": {
    "text": "Paket sudah aktif pada perangkat ini",
    "isAlreadyTranslated": true
  },
  "Plans Fetched": {
    "text": "Paket Diambil",
    "isAlreadyTranslated": true
  },
  "IS_ELIGIBLE": {
    "text": "Memenuhi syarat",
    "isAlreadyTranslated": true
  },
  "Please enter a valid serial number": {
    "text": "Silahkan memasukkan nomor serial yang valid",
    "isAlreadyTranslated": true
  },
  "Oops! Looks like this device is no longer covered under warranty": {
    "text": "Ups! Sepertinya perangkat ini tidak lagi dijamin garansi",
    "isAlreadyTranslated": true
  },
  "Provide a valid ConsumerProductID": {
    "text": "Tuliskan IDProdukPelanggan yang valid",
    "isAlreadyTranslated": true
  },
  "Product mismatch": {
    "text": "Produk tidak cocok",
    "isAlreadyTranslated": true
  },
  "Device already in service": {
    "text": "Perangkat sudah dalam perbaikan",
    "isAlreadyTranslated": true
  },
  "SERIAL_NO_OBSOLETE": {
    "text": "No Serial tidak terpakai",
    "isAlreadyTranslated": true
  },
  "No checks yet": {
    "text": "Belum dicek",
    "isAlreadyTranslated": true
  },
  "Provide a valid check type": {
    "text": "Memberikan jenis cek yang valid",
    "isAlreadyTranslated": true
  },
  "Plan Invoice fetched": {
    "text": "Tagihan paket diambil",
    "isAlreadyTranslated": true
  },
  "Email address cannot exceed 70 characters": {
    "text": "Alamat email tidak boleh lebih dari 70 karakter",
    "isAlreadyTranslated": true
  },
  "Please provide a valid EmailID": {
    "text": "Silahkan memberikan ID email yang valid",
    "isAlreadyTranslated": true
  },
  "First Name/Last Name cannot exceed 34 characters.": {
    "text": "Nama pertama / nama terakhir tidak boleh lebih dari 34 karakter",
    "isAlreadyTranslated": true
  },
  "Oops! This device is no longer covered under warranty": {
    "text": "Perangkat sudah tidak dalam masa garansi",
    "isAlreadyTranslated": true
  },
  "This action is not allowed": {
    "text": "Tindakan ini tidak diizinkan",
    "isAlreadyTranslated": true
  },
  "Error in adding docs": {
    "text": "Terjadi kesalahan dalam penambahan dokumen",
    "isAlreadyTranslated": true
  },
  "Insufficient parameters": {
    "text": "Parameter tidak cukup",
    "isAlreadyTranslated": true
  },
  "Error in getPlanInvoice": {
    "text": "Kesalahan dalam DapatkanTagihanPaket",
    "isAlreadyTranslated": true
  },
  "Device name is required": {
    "text": "Nama perangkat dibutuhkan",
    "isAlreadyTranslated": true
  },
  "Device Config fetched": {
    "text": "Konfigurasi Perangkat diambil",
    "isAlreadyTranslated": true
  },
  "No config found for the Device": {
    "text": "Tidak ada konfigurasi untuk Perangkat ini",
    "isAlreadyTranslated": true
  },
  "Error in fetching device config": {
    "text": "Kesalahan saat mengambil konfigurasi perangkat",
    "isAlreadyTranslated": true
  },
  "Unique product sub category ids": {
    "text": "Id sub kategori produk yang unik",
    "isAlreadyTranslated": true
  },
  "Something wrong while finding products": {
    "text": "Terjadi kesalahan saat mencari produk",
    "isAlreadyTranslated": true
  },
  "Something wrong while finding devices": {
    "text": "Terjadi kesalahn saat mencari perangkat",
    "isAlreadyTranslated": true
  },
  "No records to update": {
    "text": "Tidak ada Record diperbaharui",
    "isAlreadyTranslated": true
  },
  "Update failed": {
    "text": "Perbaharuan gagal",
    "isAlreadyTranslated": true
  },
  "Error in file conversion": {
    "text": "Kesalahan dalam konversi file",
    "isAlreadyTranslated": true
  },
  "Results fetched": {
    "text": "Hasil diambil",
    "isAlreadyTranslated": true
  },
  "We have saved the details of this device.": {
    "text": "Kami telah menerima rincian perangkat ini.",
    "isAlreadyTranslated": true
  },
  "Great!": {
    "text": "Bagus!",
    "isAlreadyTranslated": true
  },
  "Looks like you have exhausted the number of attempts to activate the enteprise support plan on your device. Please contact Servify for more assistance": {
    "text": "Sepertinya Anda sudah melampaui jumlah percobaan untuk mengaktifkan paket dukungan enterprise di perangkat Anda. Hubungi Serfivy untuk bantuan lebih lanjut",
    "isAlreadyTranslated": true
  },
  "Oops!": {
    "text": "Ups!",
    "isAlreadyTranslated": true
  },
  "It looks like this device is protected under Apple Enterprise Support Plan.": {
    "text": "Sepertinya perangkat ini dilindungi sesuai Paket Dukungan Perusahaan Apple.",
    "isAlreadyTranslated": true
  },
  "Congratulations!": {
    "text": "Selamat!",
    "isAlreadyTranslated": true
  },
  "Error in validating serial number": {
    "text": "Terjadi kesalahan dalam validasi nomor serial",
    "isAlreadyTranslated": true
  },
  "This device is eligible for purchasing the plan": {
    "text": "Perangkat ini dapat membeli paket",
    "isAlreadyTranslated": true
  },
  "Device transferred": {
    "text": "Perangkat dipindahkan",
    "isAlreadyTranslated": true
  },
  "Invalid Mobile No": {
    "text": "Nomor HP tidak valid",
    "isAlreadyTranslated": true
  },
  "INVALID_MOBILE": {
    "text": "Nomor HP tidak valid",
    "isAlreadyTranslated": true
  },
  "Invalid Imei or Serial No": {
    "text": "Imei dan nomor serial tidak valid",
    "isAlreadyTranslated": true
  },
  "INVALID_IMEI_OR_SERIAL": {
    "text": "Imei dan nomor serial tidak valid",
    "isAlreadyTranslated": true
  },
  "details updated successfully": {
    "text": "Detil sukses diperbaharui",
    "isAlreadyTranslated": true
  },
  "Service request document deleted": {
    "text": "Dokumen permintaan perbaikan dihapus",
    "isAlreadyTranslated": true
  },
  "Already rated for this particular service request": {
    "text": "Sudah melakukan penilaian untuk permintaan perbaikan",
    "isAlreadyTranslated": true
  },
  "No feed back created": {
    "text": "Tidak ada umpan balik yang dibuat",
    "isAlreadyTranslated": true
  },
  "Invalid ServiceLocationAppointmentID": {
    "text": "IDJanjiLokasiServis Tidak Valid",
    "isAlreadyTranslated": true
  },
  "Service status updated": {
    "text": "Status perbaikan diperbaharui",
    "isAlreadyTranslated": true
  },
  "Rating details has been successfully sent.": {
    "text": "Detil penilaian sudah berhasil dikirim",
    "isAlreadyTranslated": true
  },
  "All issues to be validated should have ConsumerServiceRequestIssuesId": {
    "text": "Semua masalah yang divalidasi harus mempunyai ID permintaan perbaikana Masalah Pelanggan",
    "isAlreadyTranslated": true
  },
  "Something went wrong while updating issue": {
    "text": "Terjadi kesalahan saat memperbaharui masalah",
    "isAlreadyTranslated": true
  },
  "Something went wrong while finding issue with id": {
    "text": "Terjadi kesalahan saat mencari masalah dengan ID",
    "isAlreadyTranslated": true
  },
  "All issues to be added should have ProductSubCategoryIssueID": {
    "text": "Semua masalah yang ditambahkan harus mempunyai ID Subkategori masalah produk",
    "isAlreadyTranslated": true
  },
  "All issues to be added should not have ConsumerServiceRequestIssuesId": {
    "text": "Semua masalah yang ditambahkan tidak harus mempunyai ID permintaan perbaikan masalah pelanggan",
    "isAlreadyTranslated": true
  },
  "You cannot delete a reported issue": {
    "text": "Anda tidak dapat menghapus masalah yang dilaporkan",
    "isAlreadyTranslated": true
  },
  "No such issue exists for this consumer service request id": {
    "text": "Tidak ada masalah ditemukan untuk ID permintaan perbaikan pelanggan",
    "isAlreadyTranslated": true
  },
  "Engineer is not register with this request": {
    "text": "Teknisi tidak terdaftar pada permintaan ini",
    "isAlreadyTranslated": true
  },
  "Please provide at least on issue": {
    "text": "Tulis sedikitnya satu masalah",
    "isAlreadyTranslated": true
  },
  "Please provide warranty reason": {
    "text": "Tulis alasannya",
    "isAlreadyTranslated": true
  },
  "Added issue updation failed": {
    "text": "Menambahkan Masalah gagal update",
    "isAlreadyTranslated": true
  },
  "Service request information fetching failed.": {
    "text": "Gagal mengambil informasi permintaan servis",
    "isAlreadyTranslated": true
  },
  "issue has been validated": {
    "text": "Masalah sudah divalidasi",
    "isAlreadyTranslated": true
  },
  "issue has not been validated yet": {
    "text": "Masalah belum divalidasi",
    "isAlreadyTranslated": true
  },
  "failed to check issue validation": {
    "text": "Gagal mengecek validasi masalah",
    "isAlreadyTranslated": true
  },
  "Invoice has not been generated yet": {
    "text": "Invoice belum dibuat",
    "isAlreadyTranslated": true
  },
  "Final invoice has been generated": {
    "text": "Invoice sudah diterbitkan",
    "isAlreadyTranslated": true
  },
  "Final invoice not generated": {
    "text": "Invoice belum diterbitkan",
    "isAlreadyTranslated": true
  },
  "eRecycle Request details:": {
    "text": "Rincian Permintaan eRecycle:",
    "isAlreadyTranslated": true
  },
  "Customer Name :": {
    "text": "Nama pelanggan:",
    "isAlreadyTranslated": true
  },
  "Phone Number :": {
    "text": "Nomor Telepon :",
    "isAlreadyTranslated": true
  },
  "Device Detail :": {
    "text": "Rincian Perangkat :",
    "isAlreadyTranslated": true
  },
  "Day of Visit": {
    "text": "Tanggal Kunjungan",
    "isAlreadyTranslated": true
  },
  "Time of Visit :": {
    "text": "Waktu Kunjungan :",
    "isAlreadyTranslated": true
  },
  "Regards,": {
    "text": "Hormat kami,",
    "isAlreadyTranslated": true
  },
  "New eRecycle Drop off Request: ID": {
    "text": "Permintaan Antar eRecycle Baru:",
    "isAlreadyTranslated": true
  },
  "New eRecycle Pickup Request: ID": {
    "text": "Permintaan Jemput eRecycle Baru: ID",
    "isAlreadyTranslated": true
  },
  "vendor id is not valid for fareye tracking": {
    "text": "id vendor tidak valid untuk pelacakan fareye",
    "isAlreadyTranslated": true
  },
  "please provide Pincode and PlanID": {
    "text": "tuliskan Kode pin dan IDPaket",
    "isAlreadyTranslated": true
  },
  "Request Is Reschedulable": {
    "text": "Nama Pelanggan",
    "isAlreadyTranslated": true
  },
  "Request Is Not Reschedulable": {
    "text": "Permintaan belum dijadwalkan",
    "isAlreadyTranslated": true
  },
  "Service is already booked for ( )": {
    "text": "Rincian Perangkat :",
    "isAlreadyTranslated": true
  },
  "Please call us at for further queries": {
    "text": "Tanggal Kunjungan",
    "isAlreadyTranslated": true
  },
  "Service cannot be created. Please call us at for further queries": {
    "text": "Waktu Kunjungan :",
    "isAlreadyTranslated": true
  },
  "no service locations found for this brand": {
    "text": "Hormat kami,",
    "isAlreadyTranslated": true
  },
  "error occured in finding service locations": {
    "text": "Terjadi masalah saat mencari lokasi servis",
    "isAlreadyTranslated": true
  },
  "Updated Serial Number": {
    "text": "Nomor Serial Diperbarui",
    "isAlreadyTranslated": true
  },
  "No Updated Consumer Product Found": {
    "text": "Tidak Ada Produk Pelanggan yang Diperbarui",
    "isAlreadyTranslated": true
  },
  "No Consumer Product Found": {
    "text": "Tidak Ada Produk Pelanggan",
    "isAlreadyTranslated": true
  },
  "Serial Number Already Exist With Other Device": {
    "text": "Nomor Serial sudah ada dengan perangkat lain",
    "isAlreadyTranslated": true
  },
  "Serial number should be unique": {
    "text": "Nomor serial harus unik",
    "isAlreadyTranslated": true
  },
  "Error in function paymentAction": {
    "text": "Kesalahan dalam fungsi TindakanPembayaran",
    "isAlreadyTranslated": true
  },
  "Not servicable at this region": {
    "text": "Tidak dapat diservis di wilayah ini",
    "isAlreadyTranslated": true
  },
  "Cannot create on site request": {
    "text": "Tidak dapat membuat permintaan di lokasi",
    "isAlreadyTranslated": true
  },
  "User cannot raise request for this service type id": {
    "text": "Pengguna tidak dapat mengajukan permintaan untuk id tipe servis ini",
    "isAlreadyTranslated": true
  },
  "Invalid csrid": {
    "text": "idcsr tidak valid",
    "isAlreadyTranslated": true
  },
  "DOA certificate has been generated .Please collect your device and DOA certificate": {
    "text": "Sertifikat DOA / Inspection Form sudah dibuat. Silahkan mengambil perangkat dan Sertifikat DOA/IF",
    "isAlreadyTranslated": true
  },
  "DOA certificate generated": {
    "text": "Sertifikat DOA  / IF dibuat",
    "isAlreadyTranslated": true
  },
  "Awesome! Your device is replaced and ready to be collected": {
    "text": "Perangkat anda telah diperbaiki dan sudah bisa diambil",
    "isAlreadyTranslated": true
  },
  "Claim Intimation Number Generated": {
    "text": "Nomor Pengajuan Klaim dibuat",
    "isAlreadyTranslated": true
  },
  "Estimation is Already approved by service center": {
    "text": "Estimasi Sudah disetujui oleh Service Center",
    "isAlreadyTranslated": true
  },
  "Estimation Status updated": {
    "text": "Status Estimasi diperbarui",
    "isAlreadyTranslated": true
  },
  "Service not available": {
    "text": "Perbaikan tidak tersedia",
    "isAlreadyTranslated": true
  },
  "Slots has been sent successfully.": {
    "text": "Berhasil mengirimkan slot.",
    "isAlreadyTranslated": true
  },
  "Service centre detail not found": {
    "text": "Detil Service Center tidak ditermukan",
    "isAlreadyTranslated": true
  },
  "Please provide valid condition": {
    "text": "Silahkan memberikan kondisi yang valid",
    "isAlreadyTranslated": true
  },
  "updated consumer service request": {
    "text": "Perbaharuan permintaan perbaikan pelanggan",
    "isAlreadyTranslated": true
  },
  "No Consumer Service request found with given id": {
    "text": "Tidak ada permintaan perbaikan ditemukan dengan ID yang diberikan",
    "isAlreadyTranslated": true
  },
  "no consumer service request created": {
    "text": "Tidak ada permintaan perbaikan yang dibuat",
    "isAlreadyTranslated": true
  },
  "instance updated succesfully": {
    "text": "berhasil memperbarui contoh",
    "isAlreadyTranslated": true
  },
  "no id found in ServiceLocationAppointment": {
    "text": "tidak ditemukan id di JanjiLokasiServis",
    "isAlreadyTranslated": true
  },
  "New appointment created": {
    "text": "Janji baru dibuat",
    "isAlreadyTranslated": true
  },
  "no data created in ServiceLocationAppointment": {
    "text": "tidak ada data yang dibuat di JanjiLokasiServis",
    "isAlreadyTranslated": true
  },
  "Cannot cancel this request": {
    "text": "Tidak dapat membatalkan permintaan ini",
    "isAlreadyTranslated": true
  },
  "Your eRecycle Request Has Been Cancelled | Ref. ID.": {
    "text": "Permintaan eRecycle Anda Sudah Dibatalkan | ID Ref.",
    "isAlreadyTranslated": true
  },
  "cannot allocate the vouchers": {
    "text": "tidak dapat mencari voucher",
    "isAlreadyTranslated": true
  },
  "Service cancelled successfully": {
    "text": "Berhasil membatalkan perbaikan",
    "isAlreadyTranslated": true
  },
  "Provided date should not be less then current": {
    "text": "Tanggal diberikan tidak boleh rendah dari sekarang",
    "isAlreadyTranslated": true
  },
  "Can't reschedule now": {
    "text": "Tidak dapat menjadwal ulang saat ini",
    "isAlreadyTranslated": true
  },
  "Cannot reschedule on same date and slot": {
    "text": "Tidak dapat menjadwal ulang pada tanggal dan slot yang sama",
    "isAlreadyTranslated": true
  },
  "Can't reschedule more than 3 times": {
    "text": "Tidak dapat menjadwal ulang lebih dari 3 kali",
    "isAlreadyTranslated": true
  },
  "no consumer servicerequest logistics request found": {
    "text": "tidak ada permintaan konsumen servicerequest logistik ditemukan",
    "isAlreadyTranslated": true
  },
  "Non Partnered Track Request Details returned": {
    "text": "Rincian Permintaan Pelacakan Tidak Bermitra dikembalikan",
    "isAlreadyTranslated": true
  },
  "Non Partnered Track Request Details": {
    "text": "Rincian Permintaan Pelacakan Tidak Bermitra",
    "isAlreadyTranslated": true
  },
  "No PartnerServicelocation found from PartnerServicelocationID": {
    "text": "Tidak ditemukan LokasiServisMitra dari IDLokasiServisMitra",
    "isAlreadyTranslated": true
  },
  "no ConsumerProduct found from ConsumerProductID": {
    "text": "tidak ditemukan ProdukPelanggan dari IDProdukPelanggan",
    "isAlreadyTranslated": true
  },
  "no ServiceLocationAppointment found from ServiceLocationAppointmentID": {
    "text": "tidak ditemukan JanjiLokasiServis dari IDJanjiLokasiServis",
    "isAlreadyTranslated": true
  },
  "unable to get the data from ConsumerServiceRequestID": {
    "text": "tidak dapat memperoleh data dari IDPermintaanServisPelanggan",
    "isAlreadyTranslated": true
  },
  "ServiceLocationApiRequest called for estimation failed": {
    "text": "gagal memanggil PermintaanAPILokasiServis untuk estimasi",
    "isAlreadyTranslated": true
  },
  "Consumer Unregistered Product Service Request created": {
    "text": "Permintaan Servis Produk Tidak Terdaftar Pelanggan dibuat",
    "isAlreadyTranslated": true
  },
  "Found product price level applicability and other details": {
    "text": "Kelayakan tingkat harga produk dan rincian lainnya ditemukan",
    "isAlreadyTranslated": true
  },
  "Something went wrong while fetching product price level applicability and other details": {
    "text": "Terjadi masalah saat mengambil kelayakan tingkat harga produk dan rincian lainnya",
    "isAlreadyTranslated": true
  },
  "Something went wrong while fetching issues": {
    "text": "Terjadi masalah saat mengambil masalah",
    "isAlreadyTranslated": true
  },
  "Something went wrong while fetching consumer service request issues": {
    "text": "Terjadi masalah saat mengambil masalah permintaan servis pelanggan",
    "isAlreadyTranslated": true
  },
  "No consumer service request was found associated with the given id": {
    "text": "Tidak ada permintaan servis pelanggan terkait dengan ID ini",
    "isAlreadyTranslated": true
  },
  "Claim Request Not At The Required Stage To Be Merged": {
    "text": "Permintaan Klaim yang Tidak pada Tahap yang Diperlukan yang Harus Digabungkan",
    "isAlreadyTranslated": true
  },
  "Normal Request Not At The Required Stage To Be Merged": {
    "text": "Permintaan Normal yang Tidak pada Tahap yang Diperlukan yang Harus Digabungkan",
    "isAlreadyTranslated": true
  },
  "No details found for the given ConsumerID": {
    "text": "Tidak ada rincian untuk IDPelanggan yang diberikan",
    "isAlreadyTranslated": true
  },
  "Error in fetching service details": {
    "text": "Kesalahan saat mengambil rincian servis",
    "isAlreadyTranslated": true
  },
  "Some issues are already present for this consumer service request": {
    "text": "Beberapa masalah sudah ada untuk permintaan servis pelanggan ini",
    "isAlreadyTranslated": true
  },
  "No results found for the given claimRequestRefID OR Status Is Not At In-principle approved' Stage": {
    "text": "Tidak ada hasil untuk IDRefPermintaanKlaim ATAU Status Tidak pada Tahap Persetujuan secara pokok yang disetujui",
    "isAlreadyTranslated": true
  },
  "ServicetypeID of the request does not match": {
    "text": "IDJenisServis permintaan tidak cocok",
    "isAlreadyTranslated": true
  },
  "Error in Cancelling ServiceRequest": {
    "text": "Kesalahan saat Membatalkan PermintaanServis",
    "isAlreadyTranslated": true
  },
  "Error in updating claim request": {
    "text": "Kesalahan saat memperbarui permintaan klaim",
    "isAlreadyTranslated": true
  },
  "No Pickup Request Accepted Log Found For RequestID": {
    "text": "Tidak Ada Catatan Permintaan Penjemputan yang Disetujui untuk RequestID",
    "isAlreadyTranslated": true
  },
  "Error in updating serviceRequestID for logisticsID": {
    "text": "Kesalahan dalam memperbarui PermintaanServisID untuk IDLogistik",
    "isAlreadyTranslated": true
  },
  "Request successfully merged": {
    "text": "Berhasil menggabungkan permintaan",
    "isAlreadyTranslated": true
  },
  "Something Erroneous happened while fetching partner details": {
    "text": "Terjadi masalah saat mengambil rincian mitra",
    "isAlreadyTranslated": true
  },
  "No record found": {
    "text": "Tidak ada Record ditemukan",
    "isAlreadyTranslated": true
  },
  "no appointment found": {
    "text": "tidak ada janji",
    "isAlreadyTranslated": true
  },
  "The brand is no longer active for this product": {
    "text": "Merek ini tidak lagi aktif untuk produk ini",
    "isAlreadyTranslated": true
  },
  "No brand found for this product": {
    "text": "Tidak ada merek yang ditemukan untuk produk ini",
    "isAlreadyTranslated": true
  },
  "This product is no longer active": {
    "text": "Produk ini sudah tidak aktif lagi",
    "isAlreadyTranslated": true
  },
  "We Pick up": {
    "text": "Kami Jemput",
    "isAlreadyTranslated": true
  },
  "We will send an Agent to pick your device and deliver it back after the repair": {
    "text": "Kami akan mengirimkan Agen untuk menjemput perangkat Anda dan mengembalikannya setelah diperbaiki",
    "isAlreadyTranslated": true
  },
  "Not available at this location": {
    "text": "Tidak tersedia di lokasi ini",
    "isAlreadyTranslated": true
  },
  "You Visit the Authorised Service Center": {
    "text": "Anda Mengunjungi Pusat Servis Resmi",
    "isAlreadyTranslated": true
  },
  "Book an appointment to drop off your device at the authorised service center": {
    "text": "Membuat janji untuk Drop Off perangkat ke Authorized Service Center",
    "isAlreadyTranslated": true
  },
  "No Service Center near you": {
    "text": "Tidak ada Pusat Servis di dekat Anda",
    "isAlreadyTranslated": true
  },
  "Get an Engineer to Visit Your Location": {
    "text": "Biarkan Teknisi Mendatangi Anda",
    "isAlreadyTranslated": true
  },
  "Engineer visits you at your preferred location for onsite repair": {
    "text": "Teknisi akan mendatangi Anda di lokasi pilihan Anda untuk perbaikan di tempat",
    "isAlreadyTranslated": true
  },
  "Get a Device Installed at Your Location": {
    "text": "Instal Perangkat di Tempat Anda",
    "isAlreadyTranslated": true
  },
  "Engineer will come to your location at a preferred time to install a device": {
    "text": "Teknisi Anda mendatangi Anda di lokasi Anda pada waktu pilihan Anda untuk menginstal perangkat",
    "isAlreadyTranslated": true
  },
  "Get Demo of Your Device": {
    "text": "Lihat Demo Perangkat Anda",
    "isAlreadyTranslated": true
  },
  "We will send an expert who will demonstrate the features of your device": {
    "text": "Kami akan mengirimkan pakar yang dapat mendemonstrasikan fitur-fitur perangkat Anda",
    "isAlreadyTranslated": true
  },
  "Preventive Maintenance": {
    "text": "Perawatan Pencegahan",
    "isAlreadyTranslated": true
  },
  "Service at Your Location": {
    "text": "Servis di Lokasi Anda",
    "isAlreadyTranslated": true
  },
  "We will send an engineer who will perform routine health checks and preventive maintenance routine": {
    "text": "Kami akan mengirim teknisi yang akan melakukan pemeriksaan kondisi rutin dan perawatan pencegahan",
    "isAlreadyTranslated": true
  },
  "Looks like there are no service modes available at your selected location. Please chose near by location or contact brand for further help.": {
    "text": "Sepertinya tidak ada mode servis yang tersedia di lokasi pilihan Anda. Pilih lokasi terdekat atau hubungi brand untuk bantuan lebih lanjut.",
    "isAlreadyTranslated": true
  },
  "The request is not at required status for this action": {
    "text": "Permintaan tidak dalam status wajib untuk tindakan ini",
    "isAlreadyTranslated": true
  },
  "Error in fetching requests": {
    "text": "Kesalahan saat mengambil permintaan",
    "isAlreadyTranslated": true
  },
  "Request details fetched successfully": {
    "text": "Rincian permintaan berhasil diambil",
    "isAlreadyTranslated": true
  },
  "You don't have enough rights to cancel this request": {
    "text": "Anda tidak berhak membatalkan permintaan ini",
    "isAlreadyTranslated": true
  },
  "Something went wrong while updating consumer service request": {
    "text": "Terjadi masalah saat memperbarui permintaan servis pelanggan",
    "isAlreadyTranslated": true
  },
  "Oops. We don’t service this device at this location. But we’re hard at work and you should get some good news soon. Try later?": {
    "text": "Ups. Kami tidak menyervis perangkat ini di lokasi ini. Tapi kami akan berusaha semaksimal mungkin dan segera memberikan kabar gembira untuk Anda. Coba nanti?",
    "isAlreadyTranslated": true
  },
  "Not supported at this location": {
    "text": "Tidak didukung di lokasi ini",
    "isAlreadyTranslated": true
  },
  "No Service Center nearby": {
    "text": "Tidak ada Pusat Servis terdekat",
    "isAlreadyTranslated": true
  },
  "You don't have enough rights to modify this request": {
    "text": "Anda tidak berhak mengubah permintaan ini",
    "isAlreadyTranslated": true
  },
  "Claim amount is required": {
    "text": "Jumlah Klaim dibutuhkan",
    "isAlreadyTranslated": true
  },
  "UTR and payment date is required": {
    "text": "RTU dan tanggal pembayaran wajib diisi",
    "isAlreadyTranslated": true
  },
  "You don't have enough rights for this action": {
    "text": "Anda tidak berhak melakukan tindakan ini",
    "isAlreadyTranslated": true
  },
  "BER accessories refund amount": {
    "text": "Jumlah refund aksesori RT",
    "isAlreadyTranslated": true
  },
  "Accessories": {
    "text": "Aksesoris",
    "isAlreadyTranslated": true
  },
  "Refund": {
    "text": "Pengembalian",
    "isAlreadyTranslated": true
  },
  "error in storing BER payment details": {
    "text": "kesalahan dalam menyimpan rincian pembayaran RT",
    "isAlreadyTranslated": true
  },
  "Your device has been dispatched from the Service Centre, through": {
    "text": "Perangkat Anda telah dikirim dari Pusat Servis, melalui",
    "isAlreadyTranslated": true
  },
  "with TrackingID": {
    "text": "dengan IDPelacakan",
    "isAlreadyTranslated": true
  },
  "Failed to create logistics requests": {
    "text": "Gagal membuat permintaan logistik",
    "isAlreadyTranslated": true
  },
  "Please rate your experience.": {
    "text": "Nilai pengalaman Anda.",
    "isAlreadyTranslated": true
  },
  "Please provide the claimAmount": {
    "text": "Silahkan memberikan jumlah klaim",
    "isAlreadyTranslated": true
  },
  "Claim amount": {
    "text": "Jumlah klaim",
    "isAlreadyTranslated": true
  },
  "Approved claim Amount": {
    "text": "Jumlah klaim disetujui",
    "isAlreadyTranslated": true
  },
  "Request charges not found": {
    "text": "Permintaan biaya tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "Please upload a clear copy of cancelled cheque with the claimant (your) name on it.": {
    "text": "Unggah salinan cek pembatalan yang jelas, yang mencantumkan nama pengaju klaim (nama Anda).",
    "isAlreadyTranslated": true
  },
  "We are verifying and processing your documents. We will initiate the payment soon.": {
    "text": "Kami sedang memverifikasi dan memroses dokumen Anda. Kami akan segera memulai pembayaran.",
    "isAlreadyTranslated": true
  },
  "Request is not at the required stage": {
    "text": "Permintaan tidak pada tahap wajib",
    "isAlreadyTranslated": true
  },
  "Display info fetched": {
    "text": "Tampilkan info diambil",
    "isAlreadyTranslated": true
  },
  "Requests fetched": {
    "text": "Permintaan diambil",
    "isAlreadyTranslated": true
  },
  "Provide a valid csrID": {
    "text": "Tulis idcsr yang valid",
    "isAlreadyTranslated": true
  },
  "Successfully rejected estimate": {
    "text": "Berhasil menolak perkiraan",
    "isAlreadyTranslated": true
  },
  "No parts found for reference number :": {
    "text": "Tidak ditemukan part untuk nomor reference ini",
    "isAlreadyTranslated": true
  },
  "No request found for reference number :": {
    "text": "Tidak ditemukan permintaan untuk nomor reference ini",
    "isAlreadyTranslated": true
  },
  "Integration find result": {
    "text": "Hasil temuan integrasi",
    "isAlreadyTranslated": true
  },
  "No service request integration found": {
    "text": "Tidak ada integrasi permintaan servis",
    "isAlreadyTranslated": true
  },
  "No service request found": {
    "text": "Tidak ditemukan permintaan perbaikan",
    "isAlreadyTranslated": true
  },
  "failed to get subcategory of current config version": {
    "text": "gagal mendapat subkategori untuk versi konfigurasi saat ini",
    "isAlreadyTranslated": true
  },
  "ConfigurationParameter Set": {
    "text": "ParameterKonfig telah diatur",
    "isAlreadyTranslated": true
  },
  "SubCategory updated": {
    "text": "SubKategori telah diperbarui",
    "isAlreadyTranslated": true
  },
  "email invoice sent": {
    "text": "tagihan email terkirim",
    "isAlreadyTranslated": true
  },
  "Servify Invoice": {
    "text": "Tagihan Servify",
    "isAlreadyTranslated": true
  },
  "Otp sent": {
    "text": "OTP terkirim",
    "isAlreadyTranslated": true
  },
  "Check boxes for feedback": {
    "text": "Centang kotak untuk masukan",
    "isAlreadyTranslated": true
  },
  "No service location appointment found": {
    "text": "Tidak ada janji lokasi servis",
    "isAlreadyTranslated": true
  },
  "No request found": {
    "text": "Tidak ditemukan permintaan",
    "isAlreadyTranslated": true
  },
  "Notification list": {
    "text": "list notifikasi",
    "isAlreadyTranslated": true
  },
  "no new notifications": {
    "text": "Tidak ada notifikasi baru",
    "isAlreadyTranslated": true
  },
  "Consumer does not exists": {
    "text": "Pelanggan tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "Coupon application failed": {
    "text": "Gagal menggunakan kupon",
    "isAlreadyTranslated": true
  },
  "Welcome to () Care": {
    "text": "Selamat Datang di () Care",
    "isAlreadyTranslated": true
  },
  "Welcome to Servify - Your Personal Device Assistant": {
    "text": "Selamat Datang di Servify - Asisten Perangkat Pribadi Anda",
    "isAlreadyTranslated": true
  },
  "Welcome to Servify - Authorized Service": {
    "text": "Selamat Datang di Servify - Servis Resmi",
    "isAlreadyTranslated": true
  },
  "Welcome to eRecycle For iPhones": {
    "text": "Selamat datang di eRecycle Untuk iPhone",
    "isAlreadyTranslated": true
  },
  "OTP matched": {
    "text": "OTP sama",
    "isAlreadyTranslated": true
  },
  "Page number should be greater than 0": {
    "text": "Nomor halaman harus lebih besar dari 0",
    "isAlreadyTranslated": true
  },
  "Items per page should be greater than ()": {
    "text": "Item per halaman harus lebih besar dari ()",
    "isAlreadyTranslated": true
  },
  "Range should be greater than 0": {
    "text": "Rentang harus lebih besar dari 0",
    "isAlreadyTranslated": true
  },
  "Something went wrong while applying filters": {
    "text": "Terjadi kesalahan dalam melakukan filter",
    "isAlreadyTranslated": true
  },
  "Something went wrong while getting consumer count": {
    "text": "Terjadi masalah saat menghitung pelanggan",
    "isAlreadyTranslated": true
  },
  "Something went wrong while doing pagination": {
    "text": "Terjadi masalah saat membuat halaman",
    "isAlreadyTranslated": true
  },
  "Insufficient parameters - Send MobileNo": {
    "text": "Parameter tidak cukup - Kirim NoPonsel",
    "isAlreadyTranslated": true
  },
  "Insufficient parameters - Send CountryCode": {
    "text": "Parameter tidak cukup - Kirim KodeNegara",
    "isAlreadyTranslated": true
  },
  "Consumer exists with the given mobile number": {
    "text": "Ada pelanggan dengan nomor ponsel ini",
    "isAlreadyTranslated": true
  },
  "No consumer exists with the given mobile number": {
    "text": "Tidak ada pelanggan dengan nomor ponsel ini",
    "isAlreadyTranslated": true
  },
  "Something went wrong while fetching filtered consumers": {
    "text": "Terjadi masalah saat mengambil pelanggan yang difilter",
    "isAlreadyTranslated": true
  },
  "Something went wrong while fetching filtered Tempconsumers": {
    "text": "Terjadi masalah saat mengambil PelangganSementara yang difilter",
    "isAlreadyTranslated": true
  },
  "Please send consumer id": {
    "text": "Silahkan mengirim ID pelanggan",
    "isAlreadyTranslated": true
  },
  "No New Jobs available": {
    "text": "Tidak ada pekerjaan baru yang tersedia",
    "isAlreadyTranslated": true
  },
  "Something went wrong with get Devices": {
    "text": "Terjadi kesalahan dengan mengambil perangkat",
    "isAlreadyTranslated": true
  },
  "Are you an Enterprise Support Program Customer?": {
    "text": "Apakah Anda Pelanggan Program Dukungan Perusahaan?",
    "isAlreadyTranslated": true
  },
  "Looks like there is a problem!": {
    "text": "Sepertinya ada masalah!",
    "isAlreadyTranslated": true
  },
  "The IMEI Number entered is not valid. Please contact Servify Customer Support to know more.": {
    "text": "Nomor IMEI yang dimasukkan tidak valid. Hubungi Dukungan Pelanggan Servify untuk informasi selengkapnya.",
    "isAlreadyTranslated": true
  },
  "You have already activated the enterprise support plan on your device.": {
    "text": "Anda telah mengaktifkan paket dukungan perusahaan di perangkat Anda.",
    "isAlreadyTranslated": true
  },
  "Welcome!": {
    "text": "Selamat Datang!",
    "isAlreadyTranslated": true
  },
  "To get complete assistance, please add the Serial or IMEI Number of this device.": {
    "text": "Untuk mendapatkan bantuan lengkap, tambahkan Nomor Seri atau IMEI perangkat ini.",
    "isAlreadyTranslated": true
  },
  "You seemed to have generated a voucher but not purchased from Flipkart. Please visit Flipkart and buy an iPhone to avail benefits exclusively for you.": {
    "text": "Anda tampaknya mendapat voucher tetapi belum membeli dari Flipkart. Buka Flipkart dan beli iPhone untuk mendapat manfaat khusus untuk Anda.",
    "isAlreadyTranslated": true
  },
  "For further assistance, please contact Servify": {
    "text": "Untuk bantuan lebih lanjut, hubungi Servify",
    "isAlreadyTranslated": true
  },
  "coupon is applied": {
    "text": "kupon digunakan",
    "isAlreadyTranslated": true
  },
  "coupon applied no. exceeded": {
    "text": "jumlah penggunaan kupon terlampaui",
    "isAlreadyTranslated": true
  },
  "coupon cannot be applied": {
    "text": "Kupon tidak dapat digunakan",
    "isAlreadyTranslated": true
  },
  "Coupon is no longer valid": {
    "text": "Kupon tidak berlaku lagi",
    "isAlreadyTranslated": true
  },
  "Cannot find the coupon value": {
    "text": "Tidak dapat menemukan nilai kupon",
    "isAlreadyTranslated": true
  },
  "coupon redeemed": {
    "text": "kupon ditukarkan",
    "isAlreadyTranslated": true
  },
  "Payment is done through servify cash": {
    "text": "Pembayaran dilakukan dengan uang servify",
    "isAlreadyTranslated": true
  },
  "coupon can't be applied": {
    "text": "kupon tidak dapat digunakan",
    "isAlreadyTranslated": true
  },
  "coupon does not exist": {
    "text": "kupon tidak ada",
    "isAlreadyTranslated": true
  },
  "coupon cannot be redeem": {
    "text": "kupon tidak dapat ditukarkan",
    "isAlreadyTranslated": true
  },
  "coupon cant be applied": {
    "text": "kupon tidak dapat digunakan",
    "isAlreadyTranslated": true
  },
  "coupon cannot be destroyed": {
    "text": "kupon tidak dapat dihancurkan",
    "isAlreadyTranslated": true
  },
  "no coupon applied": {
    "text": "tidak ada kupon yang digunakan",
    "isAlreadyTranslated": true
  },
  "apply coupon failed": {
    "text": "gagal menggunakan kupon",
    "isAlreadyTranslated": true
  },
  "referral created": {
    "text": "rujukan dibuat",
    "isAlreadyTranslated": true
  },
  "it is case sensitive": {
    "text": "membedakan huruf besar dan kecil",
    "isAlreadyTranslated": true
  },
  "referral can be applied": {
    "text": "rujukan dapat digunakan",
    "isAlreadyTranslated": true
  },
  "maximum limit reached": {
    "text": "batas maksimum tercapai",
    "isAlreadyTranslated": true
  },
  "referral already applied": {
    "text": "rujukan sudah digunakan",
    "isAlreadyTranslated": true
  },
  "Redeemed updated successfully": {
    "text": "Berhasil memperbarui yang telah ditukarkan",
    "isAlreadyTranslated": true
  },
  "Points added": {
    "text": "Poin ditambahkan",
    "isAlreadyTranslated": true
  },
  "referral not generated": {
    "text": "rujukan tidak didapat",
    "isAlreadyTranslated": true
  },
  "Coupon Applied. Add a device for redeeming": {
    "text": "Kupon Digunakan. Tambahkan perangkat untuk ditukarkan",
    "isAlreadyTranslated": true
  },
  "record not found": {
    "text": "Record tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "Coupon cannot be applied": {
    "text": "Kupon tidak dapat digunakan",
    "isAlreadyTranslated": true
  },
  "no referral redeemed": {
    "text": "tidak ada rujukan yang ditukarkan",
    "isAlreadyTranslated": true
  },
  "coupon already applied": {
    "text": "kupon sudah digunakan",
    "isAlreadyTranslated": true
  },
  "unable to apply referral code": {
    "text": "tidak dapat menggunakan kode rujukan",
    "isAlreadyTranslated": true
  },
  "invalid referral code": {
    "text": "kode rujukan tidak valid",
    "isAlreadyTranslated": true
  },
  "No User Found": {
    "text": "Pelanggan tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "already redeemed": {
    "text": "sudah ditukarkan",
    "isAlreadyTranslated": true
  },
  "ServiceLocationApiRequest called for getDiagnosis failed": {
    "text": "PermintaanAPILokasiServis gagal menghubungi DapatkanDiagnosis",
    "isAlreadyTranslated": true
  },
  "No document found": {
    "text": "Dokumen tidak ditemukan",
    "isAlreadyTranslated": true
  },
  "Documents obtained": {
    "text": "Dokumen diterima",
    "isAlreadyTranslated": true
  },
  "No documents obtained": {
    "text": "Dokumen tidak diterima",
    "isAlreadyTranslated": true
  },
  "Documents updated": {
    "text": "Dokumen diperbarui",
    "isAlreadyTranslated": true
  },
  "Documents fetched": {
    "text": "Dokumen diambil",
    "isAlreadyTranslated": true
  },
  "Documents received. Verification in progress.": {
    "text": "Dokumen diterima. Verifikasi dalam proses",
    "isAlreadyTranslated": true
  },
  "Claim request document added": {
    "text": "Dokumen Permintaan Klaim ditambahkan",
    "isAlreadyTranslated": true
  },
  "Filtered consumer and tempConsumer": {
    "text": "Pelanggan dan PelangganSementara yang difilter",
    "isAlreadyTranslated": true
  },
  "Recommended reads": {
    "text": "Bacaan yang disarankan",
    "isAlreadyTranslated": true
  },
  "Posts fetched successfully": {
    "text": "Berhasil mengambil posting",
    "isAlreadyTranslated": true
  },
  "Posts obtained": {
    "text": "Tulisan didapat",
    "isAlreadyTranslated": true
  },
  "Feedback recorded": {
    "text": "Umpan balik di catat",
    "isAlreadyTranslated": true
  },
  "Super!": {
    "text": "Bagus!",
    "isAlreadyTranslated": true
  },
  "Thank you for your feedback.": {
    "text": "Terima kasih atas umpan balik anda",
    "isAlreadyTranslated": true
  },
  "Noted!": {
    "text": "Diterima",
    "isAlreadyTranslated": true
  },
  "We'll come up with more helpful solutions.": {
    "text": "Kami akan memiliki solusi yang lebih berguna.",
    "isAlreadyTranslated": true
  },
  "Front Side": {
    "text": "Sisi Depan",
    "isAlreadyTranslated": true
  },
  "Back Side": {
    "text": "Sisi Belakang",
    "isAlreadyTranslated": true
  },
  "Other Documents": {
    "text": "Dokumen lain-lain",
    "isAlreadyTranslated": true
  },
  "Eg: Passport, Driving License": {
    "text": "Misalnya: Paspor, SIM",
    "isAlreadyTranslated": true
  },
  "Pickup Request Accepted": {
    "text": "Permintaan Jemput Diterima",
    "isAlreadyTranslated": true
  },
  "Device collected by Logistics Executive": {
    "text": "Perangkat diambil oleh Eksekutif Logistik",
    "isAlreadyTranslated": true
  },
  "Inspection completed": {
    "text": "Inspkesi selesai",
    "isAlreadyTranslated": true
  },
  "Please approve the Service Estimate to initiate the repair process.": {
    "text": "Silahkan menyetujui estimasi perbaikan untuk memulai proses perbaikan",
    "isAlreadyTranslated": true
  },
  "The Service Estimate was rejected by you. We hope to be at your service sometime soon.": {
    "text": "Anda menolak Estimasi Perbaikan Semoga kami bisa membantu Anda lagi.",
    "isAlreadyTranslated": true
  },
  "Device repair completed": {
    "text": "Perbaikan perangkat selesai",
    "isAlreadyTranslated": true
  },
  "Estimate/Invoice is generated for your repair.": {
    "text": "Perkiraan/tagihan telah dibuat untuk perbaikan Anda.",
    "isAlreadyTranslated": true
  },
  "Agent has arrived at the pickup location": {
    "text": "Agen Telah Tiba di lokasi Pick Up",
    "isAlreadyTranslated": true
  },
  "Thank you for using our service, please rate your experience.": {
    "text": "Terima kasih telah menggunakan layanan kami, berikan nilai untuk pengalaman Anda.",
    "isAlreadyTranslated": true
  },
  "Device returned": {
    "text": "Perangkat dikembalikan",
    "isAlreadyTranslated": true
  },
  "Thanks for visting @ hopes to give you a good service experience.": {
    "text": "Terima kasih telah mengunjungi @ semoga Anda mendapatkan pengalaman servis yang baik.",
    "isAlreadyTranslated": true
  },
  "Your Service Request has been rescheduled as per your choice.": {
    "text": "Permintaan Servis telah dijadwal ulang sesuai pilihan Anda.",
    "isAlreadyTranslated": true
  },
  "Looking for Xperts": {
    "text": "Mencari Xpert",
    "isAlreadyTranslated": true
  },
  "We're looking for the best Servify Xpert for your gadget.": {
    "text": "Kami mencari Xpert Servify Terbaik untuk perangkat Anda.",
    "isAlreadyTranslated": true
  },
  "Our Xpert is on the way!": {
    "text": "Xpert sedang dalam perjalanan!",
    "isAlreadyTranslated": true
  },
  "Our Xpert has arrived": {
    "text": "Xpert telah tiba",
    "isAlreadyTranslated": true
  },
  "Your repair request has been rescheduled by our Xpert as discussed with you by our Xpert.": {
    "text": "Permintaan perbaikan Anda telah dijadwal ulang oleh Xpert kami sesuai dengan diskusi Anda bersama Xpert.",
    "isAlreadyTranslated": true
  },
  "You have requested to reschedule your request on @": {
    "text": "Anda telah meminta untuk menjadwal ulang permintaan Anda pada @",
    "isAlreadyTranslated": true
  },
  "Your request has been registered at @": {
    "text": "Permintaan Anda berhasil didaftarkan di @",
    "isAlreadyTranslated": true
  },
  "Cancelling your service request.": {
    "text": "Membatalkan permintaan perbaikan Anda",
    "isAlreadyTranslated": true
  },
  "Your Service Request is cancelled.": {
    "text": "Permintaan perbaikan dibatalkan",
    "isAlreadyTranslated": true
  },
  "Payment received!": {
    "text": "Pembayaran diterima",
    "isAlreadyTranslated": true
  },
  "Waiting for Xpert's approval": {
    "text": "Menunggu persetujuan Xpert",
    "isAlreadyTranslated": true
  },
  "Recycle Request Accepted": {
    "text": "Permintaan Daur Ulang Diterima",
    "isAlreadyTranslated": true
  },
  "Request Accepted": {
    "text": "Permintaan diterima",
    "isAlreadyTranslated": true
  },
  "Good news. Parts have been received at the Service Center": {
    "text": "Bagus sekali! Suku cadang telah diterima di Pusat Servis",
    "isAlreadyTranslated": true
  },
  "We\\n're looking for the best Servify Xpert for your gadget.": {
    "text": "Kami \\n're mencari yang terbaik Servify Xpert untuk gadget Anda.",
    "isAlreadyTranslated": true
  },
  "Estimate Generated": {
    "text": "Estimasi dibuat",
    "isAlreadyTranslated": true
  },
  "Estimation & Reimbursement": {
    "text": "Perkiraan & Reimburse",
    "isAlreadyTranslated": true
  },
  "Drop Off": {
    "text": "Antar",
    "isAlreadyTranslated": true
  },
  "Service Estimate generated": {
    "text": "Estimasi perbaikan dibuat",
    "isAlreadyTranslated": true
  },
  "Your device has been received at the Authorised Service Center": {
    "text": "Perangkat Anda telah diterima di Pusat Servis Resmi",
    "isAlreadyTranslated": true
  },
  "Request scheduled": {
    "text": "Permintaan dijadwalkan",
    "isAlreadyTranslated": true
  },
  "Claim raised": {
    "text": "Klaim diajukan",
    "isAlreadyTranslated": true
  },
  "Claim eligible": {
    "text": "Klaim memenuhi syarat",
    "isAlreadyTranslated": true
  },
  "Claim invalid": {
    "text": "Klaim tidak valid",
    "isAlreadyTranslated": true
  },
  "Claim initiated": {
    "text": "Klaim dimulai",
    "isAlreadyTranslated": true
  },
  "Document pending": {
    "text": "Dokumen ditunda",
    "isAlreadyTranslated": true
  },
  "Document verification pending": {
    "text": "verifikasi dokumen tertunda",
    "isAlreadyTranslated": true
  },
  "Documentation incomplete": {
    "text": "Dokumentasi tidak lengkap",
    "isAlreadyTranslated": true
  },
  "In-Principle Approval received": {
    "text": "Persetujuan pokok diterima",
    "isAlreadyTranslated": true
  },
  "Claim Estimation generated for your device": {
    "text": "Estimasi Klaim dibuat untuk perangkat Anda",
    "isAlreadyTranslated": true
  },
  "Thank you for approving Claim Estimation": {
    "text": "Terima kasih telah menyetujui Estimasi Klaim",
    "isAlreadyTranslated": true
  },
  "You rejected the Claim Estimation": {
    "text": "Anda menolak Estimasi Klaim",
    "isAlreadyTranslated": true
  },
  "Your request is pending for the want of Parts. Service Center will update you shortly on availability": {
    "text": "Permintaan Anda masih menunggu untuk part yang diminta. Service Center akan segera memberitahu Anda jika tersedia",
    "isAlreadyTranslated": true
  },
  "Good news! Engineer has received all required parts for the repair.": {
    "text": "Bagus sekali! Teknisi telah menerima semua suku cadang yang dibutuhkan untuk perbaikan.",
    "isAlreadyTranslated": true
  },
  "Please make the Payment as per estimation for us to proceed": {
    "text": "Silahkan melakukan pembayaran sesuai estimasi untuk kami proses",
    "isAlreadyTranslated": true
  },
  "Claim rejected by the Risk Underwriter": {
    "text": "Klaim ditolak oleh Risk Underwriter",
    "isAlreadyTranslated": true
  },
  "Call attempted by our Claim Verification Executive": {
    "text": "Mencoba menghubungi oleh Eksekutif Verifikasi klaim kami",
    "isAlreadyTranslated": true
  },
  "Your Request is under validation": {
    "text": "Permintaan Anda sedang divalidasi",
    "isAlreadyTranslated": true
  },
  "Dropoff Confirmed": {
    "text": "konfirmasi Dropoff",
    "isAlreadyTranslated": true
  },
  "Device dropped": {
    "text": "Perangkat diantar",
    "isAlreadyTranslated": true
  },
  "Green Reward Update": {
    "text": "Kabar Hadiah Hijau",
    "isAlreadyTranslated": true
  },
  "Vouchers generated": {
    "text": "Voucher didapat",
    "isAlreadyTranslated": true
  },
  "eRecycle Completed": {
    "text": "eRecycle Selesai",
    "isAlreadyTranslated": true
  },
  "Data Erasure Completed": {
    "text": "Selesai Menghapus Data",
    "isAlreadyTranslated": true
  },
  "Destruction Completed": {
    "text": "Selesai Menghancurkan",
    "isAlreadyTranslated": true
  },
  "Device rejected": {
    "text": "Perangkat Ditolak",
    "isAlreadyTranslated": true
  },
  "Claim withdrawn": {
    "text": "Klaim ditarik",
    "isAlreadyTranslated": true
  },
  "Device reached at the eRecycling Hub": {
    "text": "Perangkat mencapai Pusat eRecycle",
    "isAlreadyTranslated": true
  },
  "Reward Generation Pending": {
    "text": "Menunggu Pemberian Hadiah",
    "isAlreadyTranslated": true
  },
  "BER confirmed": {
    "text": "RT dikonfirmasi",
    "isAlreadyTranslated": true
  },
  "Processing request": {
    "text": "Proses permintaan",
    "isAlreadyTranslated": true
  },
  "Payment initiated": {
    "text": "Pembayaran dimulai",
    "isAlreadyTranslated": true
  },
  "Payment for your device has been completed": {
    "text": "Pembayara untuk perangkat sudah selesai",
    "isAlreadyTranslated": true
  },
  "Payment completed": {
    "text": "Pembayaran selesai",
    "isAlreadyTranslated": true
  },
  "Device Diagnosed and Estimate Generated": {
    "text": "Perangkat Didiagnosis dan Perkiraan Telah Dibuat",
    "isAlreadyTranslated": true
  },
  "Looks like the repair cost is more than the depreciated value of your device. Please select the option you would like to proceed with.": {
    "text": "Sepertinya biaya perbaikan melebihi harga turun dari perangkat Anda. Pilih opsi untuk memulai.",
    "isAlreadyTranslated": true
  },
  "Your device is currently being diagnosed.": {
    "text": "Perangkat Anda sedang didiagnosis.",
    "isAlreadyTranslated": true
  },
  "Pending for Acceptance": {
    "text": "Penerimaan tertunda",
    "isAlreadyTranslated": true
  },
  "QC rejected": {
    "text": "QC Ditolak",
    "isAlreadyTranslated": true
  },
  "Parts ordered": {
    "text": "Part dipesan",
    "isAlreadyTranslated": true
  },
  "Engineer is awaiting a few parts to start the repair of your device.": {
    "text": "Teknisi sedang menunggu beberapa part untuk dimulai perbaikan perangkat",
    "isAlreadyTranslated": true
  },
  "QC completed": {
    "text": "QC selesai",
    "isAlreadyTranslated": true
  },
  "Pending for assignment": {
    "text": "Tertunda untuk penugasan",
    "isAlreadyTranslated": true
  },
  "Repair initiated": {
    "text": "Perbaikan dimulai",
    "isAlreadyTranslated": true
  },
  "Parts rejected": {
    "text": "Part ditolak",
    "isAlreadyTranslated": true
  },
  "Advance payment is received.": {
    "text": "Pembayaran Uang Muka Diterima",
    "isAlreadyTranslated": true
  },
  "Parts request created": {
    "text": "Permintaan part dibuat",
    "isAlreadyTranslated": true
  },
  "Awesome! Your device is repaired and ready to be collected.": {
    "text": "Mantap! Perangkat Anda telah diperbaiki dan siap diambil.",
    "isAlreadyTranslated": true
  },
  "parts partially issued": {
    "text": "Sebagian part dikeluarkan",
    "isAlreadyTranslated": true
  },
  "Pledged successfully": {
    "text": "Berhasil berjanji",
    "isAlreadyTranslated": true
  },
  "Pledge voucher generated": {
    "text": "Voucher janji dibuat",
    "isAlreadyTranslated": true
  },
  "Pledge voucher pending": {
    "text": "Voucher janji ditunda",
    "isAlreadyTranslated": true
  },
  "Device replaced": {
    "text": "Perangkat diganti",
    "isAlreadyTranslated": true
  },
  "doa completed": {
    "text": "DOA Selesai",
    "isAlreadyTranslated": true
  },
  "pledge request created": {
    "text": "permintaan janji dibuat",
    "isAlreadyTranslated": true
  },
  "Service completed and rated": {
    "text": "Perbaikan selesai",
    "isAlreadyTranslated": true
  },
  "intimate customer": {
    "text": "Pelanggan",
    "isAlreadyTranslated": true
  },
  "advance collected": {
    "text": "Uang muka diterima",
    "isAlreadyTranslated": true
  },
  "parts issued": {
    "text": "Part di keluarkan",
    "isAlreadyTranslated": true
  },
  "parts request accepted": {
    "text": "Permintaan part diterima",
    "isAlreadyTranslated": true
  },
  "work in progress": {
    "text": "Dalam proses ",
    "isAlreadyTranslated": true
  },
  "BER rejected": {
    "text": "RT ditolak",
    "isAlreadyTranslated": true
  },
  "BER repair": {
    "text": "Perbaikan RT",
    "isAlreadyTranslated": true
  },
  "BER accessories rejected": {
    "text": "Aksesori RT ditolak",
    "isAlreadyTranslated": true
  },
  "BER accessories validated": {
    "text": "Aksesori RT divalidasi",
    "isAlreadyTranslated": true
  },
  "BER accessories received": {
    "text": "Aksesori RT diterima",
    "isAlreadyTranslated": true
  },
  "BER documents validated": {
    "text": "Dokumen RT divalidasi",
    "isAlreadyTranslated": true
  },
  "BER device received": {
    "text": "Perangkat RT diterima",
    "isAlreadyTranslated": true
  },
  "BER cancel": {
    "text": "Pembatalan RT",
    "isAlreadyTranslated": true
  },
  "BER approved": {
    "text": "RT disetujui",
    "isAlreadyTranslated": true
  },
  "BER payment completed": {
    "text": "Pembayaran RT selesai",
    "isAlreadyTranslated": true
  },
  "BER payment initiated": {
    "text": "Pembayaran RT dimulai",
    "isAlreadyTranslated": true
  },
  "vouchers generation pending": {
    "text": "pemberian voucher tertunda",
    "isAlreadyTranslated": true
  },
  "device collected": {
    "text": "Perangkat diterima",
    "isAlreadyTranslated": true
  },
  "data wipe completed": {
    "text": "Data wipe selesai",
    "isAlreadyTranslated": true
  },
  "device recycle initiated": {
    "text": "daur ulang perangkat dimulai",
    "isAlreadyTranslated": true
  },
  "Under validation": {
    "text": "Sedang validasi",
    "isAlreadyTranslated": true
  },
  "Call attempted": {
    "text": "Mencoba menghubungi",
    "isAlreadyTranslated": true
  },
  "Claim rejected": {
    "text": "Klaim ditolak",
    "isAlreadyTranslated": true
  },
  "payment pending": {
    "text": "Pembayaran tertunda",
    "isAlreadyTranslated": true
  },
  "Parts received": {
    "text": "Part diterima",
    "isAlreadyTranslated": true
  },
  "parts pending": {
    "text": "Part tertunda",
    "isAlreadyTranslated": true
  },
  "claim estimation rejected": {
    "text": "Estimasi klaim ditolak",
    "isAlreadyTranslated": true
  },
  "claim estimation approved": {
    "text": "Estimasi klaim diterima",
    "isAlreadyTranslated": true
  },
  "claim estimation generated": {
    "text": "Estimasi Klaim dibuat",
    "isAlreadyTranslated": true
  },
  "In-principle approved": {
    "text": "Disetujui secara pokok",
    "isAlreadyTranslated": true
  },
  "document incomplete": {
    "text": "Dokumen tidak lengkap",
    "isAlreadyTranslated": true
  },
  "device received": {
    "text": "perangkat diterima",
    "isAlreadyTranslated": true
  },
  "estimation generated": {
    "text": "Estimasi Dibuat",
    "isAlreadyTranslated": true
  },
  "repair cancel": {
    "text": "Perbaikan dibatalkan",
    "isAlreadyTranslated": true
  },
  "service cancellation initiated": {
    "text": "pembatalan servis dimulai",
    "isAlreadyTranslated": true
  },
  "reschedule requested": {
    "text": "penjadwalan ulang diminta",
    "isAlreadyTranslated": true
  },
  "serviceman reached": {
    "text": "tukang servis dihubungi",
    "isAlreadyTranslated": true
  },
  "serviceman journey initiated": {
    "text": "Tukang servis memulai perjalanan",
    "isAlreadyTranslated": true
  },
  "request waiting for assignment": {
    "text": "permintaan menunggu penugasan",
    "isAlreadyTranslated": true
  },
  "consumer arrived": {
    "text": "Pelanggan Tiba",
    "isAlreadyTranslated": true
  },
  "service cancel": {
    "text": "Perbaikan dibatalkan",
    "isAlreadyTranslated": true
  },
  "agent arrived": {
    "text": "agen telah tiba",
    "isAlreadyTranslated": true
  },
  "device dispatched": {
    "text": "perangkat dikirimkan",
    "isAlreadyTranslated": true
  },
  "estimation rejected": {
    "text": "Estimasi ditolak",
    "isAlreadyTranslated": true
  },
  "estimation approval": {
    "text": "persetujuan Estimasi",
    "isAlreadyTranslated": true
  },
  "device shipped": {
    "text": "perangkat dikirim",
    "isAlreadyTranslated": true
  },
  "pickup request accepted": {
    "text": "Permintaan Jemput Diterima",
    "isAlreadyTranslated": true
  },
  "@ has accepted your repair request": {
    "text": "@ menerima permintaan perbaikan Anda",
    "isAlreadyTranslated": true
  },
  "It's fixed!": {
    "text": "Sudah diperbaiki!",
    "isAlreadyTranslated": true
  },
  "Installation in progress": {
    "text": "instalasi dalam proses",
    "isAlreadyTranslated": true
  },
  "You have successfully cancelled the service request. We hope to be at your service sometime soon.": {
    "text": "Anda berhasil membatalkan permintaan servis. Semoga kami bisa membantu Anda lagi.",
    "isAlreadyTranslated": true
  },
  "Your installation request has been rescheduled as per your choice.": {
    "text": "Permintaan instalasi telah dijadwal ulang sesuai pilihan Anda.",
    "isAlreadyTranslated": true
  },
  "Received request for installation": {
    "text": "Menerima permintaan untuk instalasi",
    "isAlreadyTranslated": true
  },
  "Your installation request has been rescheduled by our Xpert.": {
    "text": "Permintaan instalasi Anda telah dijadwal ulang oleh Xpert kami.",
    "isAlreadyTranslated": true
  },
  "@ has accepted your installation request": {
    "text": "@ menerima permintaan instalasi Anda",
    "isAlreadyTranslated": true
  },
  "Demo in progress": {
    "text": "Demo sedang berjalan",
    "isAlreadyTranslated": true
  },
  "Your demo request has been rescheduled as per your choice.": {
    "text": "Permintaan demo telah dijadwal ulang sesuai pilihan Anda.",
    "isAlreadyTranslated": true
  },
  "Received request for demo": {
    "text": "Menerima permintaan untuk demo",
    "isAlreadyTranslated": true
  },
  "Your demo request has been rescheduled by our Xpert.": {
    "text": "Permintaan demo Anda telah dijadwal ulang oleh Xpert kami.",
    "isAlreadyTranslated": true
  },
  "@ has accepted your demo request": {
    "text": "@ menerima permintaan demo Anda",
    "isAlreadyTranslated": true
  },
  "Service in progress": {
    "text": "Perbaikan dalam proses",
    "isAlreadyTranslated": true
  },
  "Received request for service": {
    "text": "Permintaan untuk perbaikan diterima",
    "isAlreadyTranslated": true
  },
  "@ has accepted your service request": {
    "text": "@ menerima permintaan servis Anda",
    "isAlreadyTranslated": true
  },
  "Verification Complete": {
    "text": "Verifikasi selesai",
    "isAlreadyTranslated": true
  },
  "Recycle Initiated": {
    "text": "Daur Ulang Dimulai",
    "isAlreadyTranslated": true
  },
  "Your eRecycle Request is cancelled": {
    "text": "Permintaan eRecycle Anda dibatalkan",
    "isAlreadyTranslated": true
  },
  "eRecycling Complete": {
    "text": "eRecycle Selesai",
    "isAlreadyTranslated": true
  },
  "Eligible For Claim": {
    "text": "Memenuhi syarat untuk Klaim",
    "isAlreadyTranslated": true
  },
  "Invalid Claim": {
    "text": "Klaim tidak valid",
    "isAlreadyTranslated": true
  },
  "Claim number is generated from short formats": {
    "text": "Nomor klaim telah dibuat dari format pendek",
    "isAlreadyTranslated": true
  },
  "Repair completed, please collect the device at the earliest.": {
    "text": "Perbaikan selesai, silakan ambil perangkat Anda secepatnya.",
    "isAlreadyTranslated": true
  },
  "eRecycle Initiated": {
    "text": "eRecycle Dimulai",
    "isAlreadyTranslated": true
  },
  "Your eRecycle Request is cancelled.": {
    "text": "Permintaan eRecycle Anda dibatalkan.",
    "isAlreadyTranslated": true
  },
  "eRequest cancelled": {
    "text": "eRequest Dibatalkan",
    "isAlreadyTranslated": true
  },
  "Dropoff Request Registered": {
    "text": "Permintaan Antar Didaftarkan",
    "isAlreadyTranslated": true
  },
  "Get the Depreciated Value of This Device": {
    "text": "Dapatkan Harga Turun untuk Perangkat Ini",
    "isAlreadyTranslated": true
  },
  "You will be eligible to receive the depreciated value of the device amounting to INR @. Please note that an admin fee of INR @ will be deducted from the receivable amount as per the terms of the plan.": {
    "text": "Anda akan berhak menerima harga turun untuk perangkat yang senilai INR @. Harap diingat bahwa biaya admin sebesar INR @ akan dipotong dari jumlah yang akan Anda terima sesuai ketentuan dari paket ini.",
    "isAlreadyTranslated": true
  },
  "Repair This Device and I will pay the Difference": {
    "text": "Perbaiki Perangkat Ini Dan Saya Akan Membayar Selisihnya",
    "isAlreadyTranslated": true
  },
  "Repair estimate for this device is INR @. You will pay additional amount of INR @, which includes admin fee of INR @ over and above the covered value of this device of INR @.": {
    "text": "Perkiraan perbaikan untuk perangkat ini adalah INR @. Anda akan membayar biaya tambahan sejumlah INR @, yang mencakup biaya admin sebesar INR @ di luar dan di atas biaya total untuk perangkat ini yaitu INR @.",
    "isAlreadyTranslated": true
  },
  "Cancel Repair and Get Back the Device": {
    "text": "Batalkan Perbaikan dan Ambil Kembali Perangkat",
    "isAlreadyTranslated": true
  },
  "We will return your device without repairs.": {
    "text": "Kami akan mengembalikan perangkat Anda tanpa diperbaiki.",
    "isAlreadyTranslated": true
  },
  "Process completed": {
    "text": "Proses selesai",
    "isAlreadyTranslated": true
  },
  "Cancel Repair": {
    "text": "Batal Perbaikan",
    "isAlreadyTranslated": true
  },
  "Option 1 : Proceed With Accessories": {
    "text": "Pilihan 1 : Lanjutkan dengan Aksesoris",
    "isAlreadyTranslated": true
  },
  "You will be eligible to receive a reimbursement of <> after deducting <> towards the admin fee. Please make sure you courier us the accessories at <> within <> days.": {
    "text": "Anda akan berhak menerima reimburse sebesar setelah dipotong biaya admin. Pastikan Anda mengirim aksesorinya di dalam hari.",
    "isAlreadyTranslated": true
  },
  "Option 2 : Proceed Without Accessories": {
    "text": "Pilihan 2 : Lanjutkan tanpa Aksesoris",
    "isAlreadyTranslated": true
  },
  "You will be eligible to receive a reimbursement of <> after deducting <> towards missing accessories and <> towards admin fee.": {
    "text": "Anda akan menerima reimburse sebesar setelah dipotong biaya aksesoris sebesar dan biaya admin sebesar .",
    "isAlreadyTranslated": true
  },
  "Your Service Request has been assigned to Technician.": {
    "text": "Permintaan Servis Anda telah ditugaskan pada Teknisi.",
    "isAlreadyTranslated": true
  },
  "Service request raised to Godrej Smart Care": {
    "text": "Permintaan servis diajukan ke Godrej Smart Care",
    "isAlreadyTranslated": true
  },
  "Your Service Request is accepted.": {
    "text": "Permintaan perbaikan diterima",
    "isAlreadyTranslated": true
  },
  "Approve Estimation": {
    "text": "Estimasi disetujui",
    "isAlreadyTranslated": true
  },
  "You rejected estimation": {
    "text": "Kamu menolak estimas",
    "isAlreadyTranslated": true
  },
  "Invoice/Estimation generated": {
    "text": "Invoice/Estimasi dibuat",
    "isAlreadyTranslated": true
  },
  "Good to see you.": {
    "text": "Senang bertemu Anda.",
    "isAlreadyTranslated": true
  },
  "Request Rescheduled": {
    "text": "Permintaan Dijadwal Ulang",
    "isAlreadyTranslated": true
  },
  "Waiting for Assignment": {
    "text": "Menunggu Penugasan",
    "isAlreadyTranslated": true
  },
  "Awaiting Technician assignment": {
    "text": "Menunggu Penugasan ke Teknisi",
    "isAlreadyTranslated": true
  },
  "Request in process!": {
    "text": "Permintaan dalam proses!",
    "isAlreadyTranslated": true
  },
  "Xpert arrived": {
    "text": "Xpert telah tiba",
    "isAlreadyTranslated": true
  },
  "Your device has been received at the Authorised Service Provider": {
    "text": "Perangkat Anda telah diterima di Authorized Service Center",
    "isAlreadyTranslated": true
  },
  "In-Principle Approval": {
    "text": "Disetujui secara Pokok",
    "isAlreadyTranslated": true
  },
  "claim Estimation": {
    "text": "Estimasi klaim",
    "isAlreadyTranslated": true
  },
  "service dismissed": {
    "text": "Perbaikan dihentikan",
    "isAlreadyTranslated": true
  },
  "Service creation pending at Brand": {
    "text": "Pembuatan Perbaikan Tertunda di merk",
    "isAlreadyTranslated": true
  },
  "Repair in progress": {
    "text": "Perbaikan dalam proses",
    "isAlreadyTranslated": true
  },
  "Service Accepted By Technician": {
    "text": "Perbaikan Diterima Oleh Teknisi",
    "isAlreadyTranslated": true
  },
  "Pickup": {
    "text": "Jemput",
    "isAlreadyTranslated": true
  },
  "Normally takes 1-3 days": {
    "text": "Biasanya memerlukan 1-3 hari",
    "isAlreadyTranslated": true
  },
  "Diagnosis": {
    "text": "Diagnosa",
    "isAlreadyTranslated": true
  },
  "Normally takes 2-3 hours from the time of receipt of device": {
    "text": "Biasanya memerlukan 2-3 jam sejak perangkat diterima",
    "isAlreadyTranslated": true
  },
  "Estimation & Repair": {
    "text": "Estimasi & perbaikan",
    "isAlreadyTranslated": true
  },
  "Normally takes a day": {
    "text": "Biasanya memerlukan satu hari",
    "isAlreadyTranslated": true
  },
  "Return to Customer": {
    "text": "Kembali ke pelanggan",
    "isAlreadyTranslated": true
  },
  "Normally returned immediately after repairs": {
    "text": "Biasanya segera dikembalikan setelah diperbaiki",
    "isAlreadyTranslated": true
  },
  "Repair Initiation": {
    "text": "Perbaikan dimulai",
    "isAlreadyTranslated": true
  },
  "Your request has been registered at @. Please visit as per the appointment scheduled. We hope your service experience will be great": {
    "text": "Permintaan Anda berhasil didaftarkan di @. Datanglah sesuai yang waktu dijadwalkan. Semoga Anda mendapatkan pengalaman servis yang bagus",
    "isAlreadyTranslated": true
  },
  "Normally returned immediately after repairs. You will be intimated to collect it accordingly": {
    "text": "Biasanya segera dikembalikan setelah diperbaiki. Anda akan diberitahu untuk mengambilnya pada saatnya nanti",
    "isAlreadyTranslated": true
  },
  "Service Initiation": {
    "text": "Perbaikan dimulai",
    "isAlreadyTranslated": true
  },
  "Normally as per the scheduled date and time": {
    "text": "Biasanya sesuai tanggal dan waktu yang dijadwalkan",
    "isAlreadyTranslated": true
  },
  "Device Validation": {
    "text": "Validasi Perangkat",
    "isAlreadyTranslated": true
  },
  "During the visit of the Technician": {
    "text": "Selama kunjungan Teknisi",
    "isAlreadyTranslated": true
  },
  "Service & Payment": {
    "text": "Perbaikan & pembayaran",
    "isAlreadyTranslated": true
  },
  "Normally once the technician visits your premise": {
    "text": "Biasanya setelah teknisi mengunjungi lokasi Anda",
    "isAlreadyTranslated": true
  },
  "Feedback": {
    "text": "Umpan Balik",
    "isAlreadyTranslated": true
  },
  "This is when you can rate service experience": {
    "text": "Ini saatnya Anda menilai pengalaman servis",
    "isAlreadyTranslated": true
  },
  "Pledge to eRecycle": {
    "text": "Janji untuk eRecycle",
    "isAlreadyTranslated": true
  },
  "Normally takes a Day": {
    "text": "Biasanya memerlukan satu hari",
    "isAlreadyTranslated": true
  },
  "Takes approx 1 Day": {
    "text": "Memerlukan kira-kira 1 Hari",
    "isAlreadyTranslated": true
  },
  "Green Rewards": {
    "text": "Hadiah Hijau",
    "isAlreadyTranslated": true
  },
  "eRecycle": {
    "text": "eRecycle",
    "isAlreadyTranslated": true
  },
  "Claim Request Raised": {
    "text": "Permintaan klaim diajukan",
    "isAlreadyTranslated": true
  },
  "We have received your claim request. Our representative will connect with you within the next 1-2 business days to process it further": {
    "text": "Kami telah menerima permintaan klaim Anda. Perwakilan kami akan menghubungi Anda dalam 1-2 hari kerja berikutnya untuk memroses lebih lanjut",
    "isAlreadyTranslated": true
  },
  "Verification": {
    "text": "Verifikasi",
    "isAlreadyTranslated": true
  },
  "Device handed over by QCEngineer to StoreManager": {
    "text": "Perangkat diserahkan oleh Teknisi QC ke Store Manager",
    "isAlreadyTranslated": true
  },
  "Your device is repaired and dispatched.": {
    "text": "Perangkat Anda telah diperbaiki dan dikirimkan.",
    "isAlreadyTranslated": true
  },
  "Your device is currently getting repaired.": {
    "text": "Perangkat Anda sedang diperbaiki.",
    "isAlreadyTranslated": true
  },
  "Your device has been returned. Kindly rate your experience.": {
    "text": "Perangkat Anda telah kembali. Nilai pengalaman Anda",
    "isAlreadyTranslated": true
  },
  "Repair complete": {
    "text": "Perbaikan selesai",
    "isAlreadyTranslated": true
  },
  "Device handed over by QCEngineer to CustomerCare": {
    "text": "Perangkat diserahkan oleh Teknisi QC ke customer service",
    "isAlreadyTranslated": true
  },
  "Device handed over by ServicelocationEngineer to QCEngineer": {
    "text": "Perangkat diserahkan oleh Teknisi Perbaikan dilokasi ke teknisi QC",
    "isAlreadyTranslated": true
  },
  "Device accepted by QCEngineer from ServicelocationEngineer": {
    "text": "Perangkat diterima oleh Teknisi QC dari Teknisi perbaikan dilokasi",
    "isAlreadyTranslated": true
  },
  "Device accepted by ServicelocationEngineer from CustomerCare": {
    "text": "Perangkat diterima oleh Teknisi perbaikan dilokasi dari customer service",
    "isAlreadyTranslated": true
  },
  "Device handed over by CustomerCare to ServicelocationEngineer": {
    "text": "Perangkat diserahkan oleh customer service ke teknisi perbaikan dilokasi",
    "isAlreadyTranslated": true
  },
  "Device handed over by ServicelocationEngineer to CustomerCare": {
    "text": "Perangkat diserahkan oleh Teknisi Perbaikan dilokasi ke Customer Service",
    "isAlreadyTranslated": true
  },
  "Device accepted by CustomerCare from QCEngineer": {
    "text": "Perangkat diterima oleh customer service dari teknisi QC",
    "isAlreadyTranslated": true
  },
  "Device handed over by QCEngineer to ServicelocationEngineer": {
    "text": "Perangkat diserahkan oleh Teknisi QC ke Store Manager",
    "isAlreadyTranslated": true
  },
  "Device handed over by StoreManager to CustomerCare": {
    "text": "Perangkat diserahkan oleh store manager ke customer care",
    "isAlreadyTranslated": true
  },
  "Device handed over by StoreManager to ServicelocationEngineer": {
    "text": "Perangkat diserahkan oleh Store Manager ke teknisi perbaikan dilokasi",
    "isAlreadyTranslated": true
  },
  "Normally happens immediately on fulfillment of required documentation": {
    "text": "Biasanya segera dilakukan setelah dokumen yang diperlukan dilengkapi",
    "isAlreadyTranslated": true
  },
  "Device handed over by StoreManager to QCEngineer": {
    "text": "Perangkat diserahkan oleh Store Manager ke teknisi QC",
    "isAlreadyTranslated": true
  },
  "Normally takes 2-3 days from the time of receipt of device": {
    "text": "Biasanya memerlukan 2-3 hari sejak perangkat diterima",
    "isAlreadyTranslated": true
  },
  "Engineer To Store": {
    "text": "Teknisi Ke Store",
    "isAlreadyTranslated": true
  },
  "Engineer To CustomerCare": {
    "text": "Teknisi Ke customer service",
    "isAlreadyTranslated": true
  },
  "Engineer To QC executive": {
    "text": "Teknisi ke QC",
    "isAlreadyTranslated": true
  },
  "Store To Engineer": {
    "text": "Store ke Teknisi",
    "isAlreadyTranslated": true
  },
  "Store To CustomerCare": {
    "text": "Store ke customer Service",
    "isAlreadyTranslated": true
  },
  "Store To QC executive": {
    "text": "Store ke QC",
    "isAlreadyTranslated": true
  },
  "QC To Engineer": {
    "text": "QC Ke Teknisi",
    "isAlreadyTranslated": true
  },
  "QC To CustomerCare": {
    "text": "QC Ke Customer Service",
    "isAlreadyTranslated": true
  },
  "QC To Store": {
    "text": "QC Ke Store",
    "isAlreadyTranslated": true
  },
  "CC To Engineer": {
    "text": "Dikirim ke Teknisi",
    "isAlreadyTranslated": true
  },
  "CC To Store": {
    "text": "Dikirim ke Store",
    "isAlreadyTranslated": true
  },
  "CC To QC": {
    "text": "Dikirim ke QC",
    "isAlreadyTranslated": true
  },
  "Issues validated": {
    "text": "Validasi masalah",
    "isAlreadyTranslated": true
  },
  "Diagnosis completed": {
    "text": "Diagnosa selesai",
    "isAlreadyTranslated": true
  },
  "Diagnosis initiated": {
    "text": "Diagnosis dimulai",
    "isAlreadyTranslated": true
  },
  "Device accepted": {
    "text": "Perangkat diterima",
    "isAlreadyTranslated": true
  },
  "Device handed over": {
    "text": "Perangkat diserahkan",
    "isAlreadyTranslated": true
  },
  "Waiting for engineer assignment": {
    "text": "Menunggu penugasan teknisi",
    "isAlreadyTranslated": true
  },
  "Please handover the device to the Logistics Agent only if he provides this way bill number to prove his identity.": {
    "text": "Serahkan perangkat hanya kepada Agen Logistik jika dia memberikan nomor resi ini untuk membuktikan identitasnya.",
    "isAlreadyTranslated": true
  },
  "Express Check In Available": {
    "text": "Check-In Ekspres Tersedia",
    "isAlreadyTranslated": true
  },
  "Your repair request ( @ / @ ) has been cancelled.": {
    "text": "Permintaan perbaikan ( @ / @ ) telah dibatalkan.",
    "isAlreadyTranslated": true
  },
  "Invoice generated for your repair request (@ / @). Please initiate payment.": {
    "text": "Tagihan telah dibuat untuk permintaan perbaikan Anda (@ / @). Harap mulai pembayaran.",
    "isAlreadyTranslated": true
  },
  "Repair initiated for your request (@ / @).": {
    "text": "Perbaikan dimulai untuk permintaan Anda (@ / @).",
    "isAlreadyTranslated": true
  },
  "Please approve the estimate for your repair request ( @ / @ )": {
    "text": "Setujui permintaan perbaikan Anda ( @ / @ )",
    "isAlreadyTranslated": true
  },
  "Repair completed for your request (@ / @).": {
    "text": "Perbaikan selesai untuk permintaan Anda (@ / @).",
    "isAlreadyTranslated": true
  },
  "Your @ has reached the Authorised Service Centre and is currently being diagnosed ( @ ).": {
    "text": "@ Anda telah sampai di Pusat Servis Resmi dan sekarang sedang didiagnosis ( @ ).",
    "isAlreadyTranslated": true
  },
  "Your request (@) has been completed & closed.": {
    "text": "Permintaan Anda (@) telah selesai & ditutup.",
    "isAlreadyTranslated": true
  },
  "Your @ ( @ ) has been repaired & delivered.": {
    "text": "@ Anda (@) telah diperbaiki & dikirim.",
    "isAlreadyTranslated": true
  },
  "Your payment has been received": {
    "text": "Pembayaran Anda telah diterima",
    "isAlreadyTranslated": true
  },
  "Track request result": {
    "text": "Hasil permintaan pelacakan",
    "isAlreadyTranslated": true
  },
  "Service Charge": {
    "text": "Biaya perbaikan",
    "isAlreadyTranslated": true
  },
  "Total": {
    "text": "Total",
    "isAlreadyTranslated": true
  },
  "Amount Payable": {
    "text": "Jumlah yang harus dibayar",
    "isAlreadyTranslated": true
  },
  "Earphone/Headphone/Handsfree Issue": {
    "text": "Masalah earphone",
    "isAlreadyTranslated": true
  },
  "Logistics Agent will arrive at your location on @. Your SECRET CODE is @. Please handover the device to the Logistics Agent only if he provides this secret code to prove his identity.": {
    "text": "Agen Logistik akan tiba di lokasi Anda pada @. KODE RAHASIA Anda adalah @. Serahkan perangkat hanya kepada Agen Logistik jika dia memberikan kode rahasia ini untuk membuktikan identitasnya.",
    "isAlreadyTranslated": true
  },
  "Logistics Agent will arrive at your location on @. Your WAY BILL NUMBER is @. Please handover the device to the Logistics Agent only if he provides this way bill number to prove his identity.": {
    "text": "Agen Logistik akan tiba di lokasi Anda pada @. NOMOR RESI Anda adalah @. Serahkan perangkat hanya kepada Agen Logistik jika dia memberikan nomor resi ini untuk membuktikan identitasnya.",
    "isAlreadyTranslated": true
  },
  "Service estimate will be shared before initiating the installation process": {
    "text": "Perkiraan servis akan dibagikan sebelum memulai proses instalasi",
    "isAlreadyTranslated": true
  },
  "Rate your experience after the service is complete": {
    "text": "Beri nilai untuk pengalaman Anda setelah servis selesai",
    "isAlreadyTranslated": true
  },
  "The technician will validate the details of the device provided by you": {
    "text": "Teknisi akan memvalidasi rincian perangkat yang Anda berikan",
    "isAlreadyTranslated": true
  },
  "Please approve the estimate for your repair request (@ / @)": {
    "text": "Setujui permintaan perbaikan Anda (@ / @)",
    "isAlreadyTranslated": true
  },
  "Your @ (@) has been repaired & delivered.": {
    "text": "@ Anda (@) telah diperbaiki & dikirim.",
    "isAlreadyTranslated": true
  },
  "You will be eligible to receive a reimbursement of INR @ after deducting INR @ towards missing accessories and INR @ towards admin fee.": {
    "text": "Anda akan menerima reimburse sebesar INR @ setelah dipotong biaya aksesoris sebesar INR @ dan biaya admin sebesar INR @.",
    "isAlreadyTranslated": true
  },
  "Your repair request (@ / @) has been cancelled.": {
    "text": "Permintaan perbaikan (@ / @) telah dibatalkan.",
    "isAlreadyTranslated": true
  },
  "Please approve the Service Estimate": {
    "text": "Setujui Perkiraan Layanan",
    "isAlreadyTranslated": true
  },
  "We have received your claim request. It will be processed within the next 48 business hours": {
    "text": "Kami telah menerima permintaan klaim Anda. Permintaan akan diproses dalam 48 jam kerja",
    "isAlreadyTranslated": true
  },
  "Rating given by customer : @": {
    "text": "Nilai dari pelanggan : @",
    "isAlreadyTranslated": true
  },
  "Your @ has reached the Authorised Service Centre and is currently being diagnosed (@).": {
    "text": "@ Anda telah sampai di Pusat Servis Resmi dan sekarang sedang didiagnosis (@).",
    "isAlreadyTranslated": true
  },
  "It\\n's fixed!": {
    "text": "\\n ini tetap!",
    "isAlreadyTranslated": true
  },
  "Thank you for eRecycling with Servify": {
    "text": "Terima kasih telah menggunakan layanan eRecycle bersama Servify",
    "isAlreadyTranslated": true
  },
  "Invoice generated": {
    "text": "Invoice dibuat",
    "isAlreadyTranslated": true
  },
  "Your device has been succesfully collected and will be delivered to Servify": {
    "text": "Perangkat Anda berhasil diambil dan akan dikirimkan ke Servify",
    "isAlreadyTranslated": true
  },
  "Logistics Agent @ @ (@) will arrive at your location today. Your SECRET CODE is @. Please handover the device to the Logistics Agent only if he provides this secret code to prove his identity.": {
    "text": "Agen Logistik @ @ (@) akan tiba di lokasi Anda hari ini. KODE RAHASIA Anda adalah @. Serahkan perangkat hanya kepada Agen Logistik jika dia memberikan kode rahasia ini untuk membuktikan identitasnya.",
    "isAlreadyTranslated": true
  },
  "You have rescheduled the pickup of your device to @": {
    "text": "Anda telah menjadwal ulang penjemputan perangkat ke @",
    "isAlreadyTranslated": true
  },
  "Claim Intimation Number Generated.": {
    "text": "Nomor Pengajuan Klaim Telah Dibuat.",
    "isAlreadyTranslated": true
  },
  "Your device was not powering on, and so our executive could not collect your device. Please cancel this request and raise another one under 'Carry-in' mode.": {
    "text": "Perangkat Anda tidak powering pada, sehingga eksekutif kami tidak bisa mengumpulkan perangkat Anda. Silakan membatalkan permintaan ini dan meningkatkan satu sama lain dalam mode 'Carry-in'.",
    "isAlreadyTranslated": true
  },
  "Your device IMEI did not match what was provided by you while raising the request. Please cancel this request and raise another one by providing IMEI of the device for repair.": {
    "text": "IMEI perangkat Anda tidak sama dengan yang Anda berikan ketika mengajukan permintaan. Batalkan permintaan ini dan ajukan permintaan lain dengan memberikan IMEI dari perangkat yang akan diperbaiki.",
    "isAlreadyTranslated": true
  },
  "You will be eligible to receive a reimbursement of INR @ after deducting INR @ towards the admin fee. Please make sure you courier us the accessories at @ within @ days.": {
    "text": "Anda akan menerima reimburse sebesar INR @ setelah dipotong biaya admin sebesar INR @. Pastikan Anda mengirimkan aksesorisnya ke @ dalam @ hari.",
    "isAlreadyTranslated": true
  },
  "Your device’s data was not backed up. Please reschedule the request as per you convenience.": {
    "text": "Data perangkat Anda tidak dicadangkan. Jadwalkan ulang permintaan sesuai kenyamanan Anda.",
    "isAlreadyTranslated": true
  },
  "Our Executive was unable to reach you on phone or your address. Please reschedule the pickup as per your convenience.": {
    "text": "Eksekutif kami tidak dapat menghubungi Anda pada nomor telepon atau alamat Anda. Jadwalkan ulang penjemputan sesuai kenyamanan Anda.",
    "isAlreadyTranslated": true
  },
  "Logistics Agent @ @ (@) has arrived at your location. Your SECRET CODE is @. Please handover the device to the Logistics Agent only if he provides this secret code to prove his identity.": {
    "text": "Agen Logistik @ @ (@) telah tiba di lokasi Anda. KODE RAHASIA Anda adalah @. Serahkan perangkat hanya kepada Agen Logistik jika dia memberikan kode rahasia ini untuk membuktikan identitasnya.",
    "isAlreadyTranslated": true
  },
  "Repair completed. Device will be delivered soon": {
    "text": "Perbaikan selesai. Perangkat akan segera dikirimkan",
    "isAlreadyTranslated": true
  },
  "Logistics Agent will arrive at your location on @ to deliver your device.": {
    "text": "Agen Logistik akan tiba di lokasi Anda pada @ untuk menyerahkan perangkat Anda.",
    "isAlreadyTranslated": true
  },
  "Logistics Agent @ @ (@) has picked up your device from the Service Centre and will deliver it today": {
    "text": "Agent Logistik @ @ (@) telah menjemput perangkat Anda dari Pusat Servis dan akan menyerahkannya kepada Anda hari ini",
    "isAlreadyTranslated": true
  },
  "Logistics Agent will arrive at your location on rescheduled date - @ to deliver your device.": {
    "text": "Agen Logistik akan tiba di lokasi Anda pada tanggal yang telah dijadwal ulang - @ untuk menyerahkan perangkat Anda.",
    "isAlreadyTranslated": true
  },
  "Device delivered! Please rate your experience": {
    "text": "Perangkat diserahkan! Nilai pengalaman Anda",
    "isAlreadyTranslated": true
  },
  "Logistics Agent could not deliver your device today as you were not available at your location. You can contact Logistics Support (@) to reschedule device delivery": {
    "text": "Agen Logistik tidak dapat menyerahkan perangkat Anda hari ini karena Anda tidak ada di lokasi. Anda dapat menghubungi Dukungan Logistik (@) untuk menjadwal ulang penyerahan perangkat",
    "isAlreadyTranslated": true
  },
  "Normally takes 2-5 Days": {
    "text": "Biasanya memerlukan 2-5 Hari",
    "isAlreadyTranslated": true
  },
  "Logistics Agent could not deliver your device today as you were not reachable on call. You can contact Logistics Support (@) to reschedule device delivery": {
    "text": "Agen Logistik tidak dapat menyerahkan perangkat Anda hari ini karena Anda tidak dapat dihubungi lewat telepon. Anda dapat menghubungi Dukungan Logistik (@) untuk menjadwal ulang penyerahan perangkat",
    "isAlreadyTranslated": true
  },
  "Logistics Agent could not deliver your device today. You can contact Logistics Support (@) to reschedule device delivery": {
    "text": "Agen Logistik tidak dapat menyerahkan perangkat Anda hari ini. Anda dapat menghubungi Dukungan Logistik (@) untuk menjadwal ulang penyerahan perangkat",
    "isAlreadyTranslated": true
  },
  "Logistics Agent could not deliver your device today as you were not available at your location. Please reschedule delivery by tapping the menu on top right corner": {
    "text": "Agen Logistik tidak dapat menyerahkan perangkat Anda hari ini karena Anda tidak ada di lokasi. Jadwalkan ulang pengiriman dengan mengetuk pada menu di pojok kanan atas",
    "isAlreadyTranslated": true
  },
  "Logistics Agent @ @ (@) has arrived at your location to deliver your device": {
    "text": "Agen Logistik @ @ (@) telah tiba di lokasi Anda untuk menyerahkan perangkat Anda",
    "isAlreadyTranslated": true
  },
  "Logistics Agent could not deliver your device today as you were not reachable on call. Please reschedule delivery by tapping the menu on top right corner": {
    "text": "Agen Logistik tidak dapat menyerahkan perangkat Anda hari ini karena Anda tidak dapat dihubungi lewat telepon. Jadwalkan ulang pengiriman dengan mengetuk pada menu di pojok kanan atas",
    "isAlreadyTranslated": true
  },
  "Logistics Agent could not deliver your device today. Please reschedule device delivery as per your convenience by tapping the menu on top right corner": {
    "text": "Agen Logistik tidak dapat menyerahkan perangkat Anda hari ini. Jadwalkan ulang pengiriman perangkat sesuai kenyamanan Anda dengan mengetuk pada menu di pojok kanan atas",
    "isAlreadyTranslated": true
  },
  "Logistics Agent could not deliver your device today. Please contact Customer Support (18001214984) to reschedule device delivery": {
    "text": "Agen Logistik tidak dapat menyerahkan perangkat Anda hari ini. Silakan hubungi Dukungan Pelanggan (18001214984) untuk menjadwal ulang penyerahan perangkat",
    "isAlreadyTranslated": true
  },
  "Logistics Agent @ @ (@) will pick up your device today. Secret Code shared by the Agent for device collection should be @. Please keep an ID Proof ready; a picture of the same is required for ownership validation.": {
    "text": "Agen Logistik @ @ (@) menjemput perangkat Anda hari ini. Kode Rahasia yang harus disebutkan oleh Agen untuk pengambilan perangkat adalah @. Siapkan Tanda Pengenal; foto tanda pengenal diperlukan untuk validasi kepemilikan.",
    "isAlreadyTranslated": true
  },
  "Logistics Agent will pick up your device on rescheduled date - @. Your Secret Code is @. Only handover the device to the Agent only if he provides this code for verification": {
    "text": "Agen Logistik akan menjemput perangkat Anda pada tanggal yang dijadwal ulang - @. Kode Rahasia Anda adalah @. Serahkan perangkat hanya kepada Agen Logistik jika dia memberikan kode ini untuk verifikasi",
    "isAlreadyTranslated": true
  },
  "Logistics Agent @ @ has picked up your device and it will be delivered to Servify.": {
    "text": "Agen Logistik @ @ telah menjemput perangkat Anda dan akan mengirimkannya ke Servify.",
    "isAlreadyTranslated": true
  },
  "Device has been shipped to Servify via @.": {
    "text": "Perangkat telah dikirimkan ke Servify melalui @.",
    "isAlreadyTranslated": true
  },
  "Logistics Agent will arrive at your location on rescheduled date - @. Your Secret Code is @. Handover the device to the Agent only if he provides this code for verification": {
    "text": "Agen Logistik akan tiba di lokasi Anda pada tanggal yang dijadwal ulang - @. Kode Rahasia Anda adalah @. Serahkan perangkat hanya kepada Agen jika dia memberikan kode ini untuk verifikasi",
    "isAlreadyTranslated": true
  },
  "IMEI provided and device IMEI did not match. Please contact Customer Support (18001214984) and provide correct IMEI or raise another request with IMEI of the phone for repair.": {
    "text": "IMEI yang diberikan dan IMEI perangkat tidak sama. Hubungi Dukungan Pelanggan (18001214984) dan berikan IMEI yang benar atau ajukan permintaan baru dengan IMEI dari ponsel yang akan diperbaiki.",
    "isAlreadyTranslated": true
  },
  "Logistics Agent could not pick up your device today as device data was not backed up. Pickup has been rescheduled to @ as requested.": {
    "text": "Agen Logistik tidak dapat menjemput perangkat Anda hari ini karena data perangkat belum dicadangkan. Penjemputan telah dijadwal ulang ke @ sesuai permintaan.",
    "isAlreadyTranslated": true
  },
  "Logistics Agent could not pick up your device today as you were not available at your location. Please reschedule the pickup by tapping on the top right corner": {
    "text": "Agen Logistik tidak dapat menjemput perangkat Anda hari ini karena Anda tidak ada di lokasi. Jadwalkan ulang penjemputan dengan mengetuk di pojok kanan atas",
    "isAlreadyTranslated": true
  },
  "The Logistics Agent will receive your @ @ device and deliver it to Servify.": {
    "text": "Agen Logistik akan menerima perangkat @ @ Anda dan mengirimkannya ke Servify.",
    "isAlreadyTranslated": true
  },
  "Your device has been succesfully collected and will be delivered to Authorised Service Provider": {
    "text": "Perangkat Anda berhasil diambil dan akan dikirimkan ke Penyedia Servis Resmi",
    "isAlreadyTranslated": true
  },
  "You Visit the\\nAuthorised Service Center": {
    "text": "Anda Mengunjungi\\nPusat Servis Resmi",
    "isAlreadyTranslated": true
  },
  "Your device has been succesfully collected and will be delivered to": {
    "text": "Perangkat Anda berhasil diambil dan akan dikirimkan ke",
    "isAlreadyTranslated": true
  },
  "Device has been shipped to Authorized Service centre via @": {
    "text": "Perangkat telah dikirimkan ke pusat Servis Resmi melalui @",
    "isAlreadyTranslated": true
  },
  "Our associate will get back to you to schedule a suitable time for pick up of your device": {
    "text": "Rekan kami akan menghubungi Anda guna menjadwalkan waktu yang tepat untuk penjemputan perangkat Anda",
    "isAlreadyTranslated": true
  },
  "Your dropoff is scheduled on @ at @.": {
    "text": "Pengantaran Anda dijadwalkan pada @ di @.",
    "isAlreadyTranslated": true
  },
  "Logistics Agent will arrive at your location on rescheduled date - @, as requested.\\n(@)": {
    "text": "Agen Logistik akan tiba di lokasi pada tanggal yang dijadwal ulang - @, sesuai permintaan.\\n(@)",
    "isAlreadyTranslated": true
  },
  "Logistics Agent will arrive at your location on rescheduled date - @, as requested.": {
    "text": "Agen Logistik akan tiba di lokasi pada tanggal yang dijadwal ulang - @, sesuai permintaan.",
    "isAlreadyTranslated": true
  },
  "You have requested to reschedule your request on @.\\n(@)": {
    "text": "Anda telah meminta untuk menjadwal ulang permintaan Anda pada @.\\n(@)",
    "isAlreadyTranslated": true
  },
  "You have requested to reschedule your request on @.": {
    "text": "Anda telah meminta untuk menjadwal ulang permintaan Anda pada @.",
    "isAlreadyTranslated": true
  },
  "Your device has been dispatched from the Service Centre, through @ with TrackingID @": {
    "text": "Perangkat Anda telah dikirimkan dari Pusat Servis, melalui @ dengan TrackingID @",
    "isAlreadyTranslated": true
  },
  "Service is already booked for @ .Please call us at 1800225511 for further queries": {
    "text": "Servis telah dipesan untuk @ .Silakan hubungi kami di 1800225511 untuk pertanyaan lebih lanjut",
    "isAlreadyTranslated": true
  },
  "Service cannot be created. Please call us at 1800225511 for further queries": {
    "text": "Servis tidak bisa dibuat. Hubungi kami di 1800225511 untuk pertanyaan lebih lanjut",
    "isAlreadyTranslated": true
  },
  "Service cannot be created.": {
    "text": "Servis tidak bisa dibuat.",
    "isAlreadyTranslated": true
  },
  "webhook PaymentID match": {
    "text": "IDPembayaran webhook sama",
    "isAlreadyTranslated": true
  },
  "ERR: webhook PaymentID mismatch": {
    "text": "ERR: IDPembayaran webhook tidak sama",
    "isAlreadyTranslated": true
  },
  "Customer wants to cancel pickup": {
    "text": "Pelanggan ingin membatalkan penjemputan",
    "isAlreadyTranslated": true
  },
  "Device has been shipped to Authorized Service centre via @.": {
    "text": "Perangkat telah dikirimkan ke pusat Servis Resmi melalui @.",
    "isAlreadyTranslated": true
  },
  "Your iPhone has been eRecycled. Please rate your eRecycling experience": {
    "text": "iPhone Anda telah masuk eRecycle. Nilai pengalaman eRecycle Anda",
    "isAlreadyTranslated": true
  },
  "Dropoff of your device at @ is confirmed. Servify will collect the device shortly.": {
    "text": "Pengantaran perangkat di @ telah dikonfirmasi. Servify akan segera mengambil perangkat.",
    "isAlreadyTranslated": true
  },
  "We have disassembled key components of your device and will be sending it to the eRecycling Hub soon.": {
    "text": "Kami telah membongkar komponan penting perangkat Anda dan akan segera mengirimkannya ke Pusat eRecycle.",
    "isAlreadyTranslated": true
  },
  "Your device has been succesfully collected and will be delivered to @ soon": {
    "text": "Perangkat Anda berhasil diambil dan akan segera dikirimkan ke @",
    "isAlreadyTranslated": true
  },
  "reached destination city warehouse": {
    "text": "Mencapai tujuan gudang kota",
    "isAlreadyTranslated": true
  },
  "out for delivery": {
    "text": "sedang mengantar",
    "isAlreadyTranslated": true
  },
  "Your device has been succesfully collected from @": {
    "text": "Perangkat Anda berhasil diambil dari @",
    "isAlreadyTranslated": true
  },
  "Approved Estimation": {
    "text": "Estimasi disetujui",
    "isAlreadyTranslated": true
  },
  "Pending": {
    "text": "Pending",
    "isAlreadyTranslated": true
  },
  "adding inventory": {
    "text": "Menambah inventory",
    "isAlreadyTranslated": true
  },
  "subtracting inventory": {
    "text": "Mengurangi Inventory",
    "isAlreadyTranslated": true
  },
  "Good News! Your iPhone has been validated succesfully. You are also eligible to participate in the Green Champion Contest to win @.": {
    "text": "Bagus Sekali! iPhone Anda berhasil divalidasi. Anda juga berhak ikut dalam Kontes Green Champion untuk memenangkan @.",
    "isAlreadyTranslated": true
  },
  "Good News! Your iPhone has been validated succesfully.": {
    "text": "Bagus Sekali! iPhone Anda berhasil divalidasi.",
    "isAlreadyTranslated": true
  },
  "Device handed over by CustomerCare to QCEngineer": {
    "text": "Perangkat diserahkan ke customer service ke teknisi QC",
    "isAlreadyTranslated": true
  },
  "Device handed over by CustomerCare to StoreManager": {
    "text": "Perangkat diserahkan ke customer service ke store manager",
    "isAlreadyTranslated": true
  },
  "Part request has been rejected by store": {
    "text": "Permintaan part di tolak oleh store",
    "isAlreadyTranslated": true
  },
  "Part request has been accepted by store": {
    "text": "Permintaan part diterima oleh store",
    "isAlreadyTranslated": true
  },
  "Requested part(s) has been issued by store": {
    "text": "Permintaan dikeluarkan oleh store",
    "isAlreadyTranslated": true
  },
  "Engineer has received part(s) issued by store": {
    "text": "Teknisi menerima part yang dikeluarkan store",
    "isAlreadyTranslated": true
  },
  "@ is your OTP for Servify. This OTP is valid for 120 seconds. Do not share this OTP with anyone for security reasons. We look forward to serving you.": {
    "text": "@ adalah OTP (Sandi Sementara) Anda untuk Servify. OTP ini berlaku selama 120 detik. Jangan bagikan OTP ini dengan siapa pun demi keamanan. Kami tak sabar ingin melayani Anda.",
    "isAlreadyTranslated": true
  },
  "1 part request is yet to be accepted by store.": {
    "text": "1 permintaan part belum diterima oleh store",
    "isAlreadyTranslated": true
  },
  "@ part requests are yet to be accepted by store.": {
    "text": "permintaan part belum diterima oleh store",
    "isAlreadyTranslated": true
  },
  "Download Servify - Personal assistant to all your electronics and home appliances.\\n\\nhttp://servify.in/app": {
    "text": "Unduh Servify - Asisten pribadi untuk semua perangkat elektronik dan rumah tangga.\\n\\nhttp://servify.in/app",
    "isAlreadyTranslated": true
  },
  "Our expert (@) has initiated the repair work.": {
    "text": "Pakar kami (@) telah memulai pekerjaan perbaikan.",
    "isAlreadyTranslated": true
  },
  "Device handed over by ServicelocationEngineer to StoreManager": {
    "text": "Perangkat diserahkan oleh TeknisiLokasiServis kepada StoreManager",
    "isAlreadyTranslated": true
  },
  "Device accepted from @ by @": {
    "text": "Perangkat diterima dari @dengan@",
    "isAlreadyTranslated": true
  },
  "Your Device has been collected by Servify Executive on @ and will be delivered to Authorized Service Centre.": {
    "text": "Perangkat Anda telah diambil oleh Eksekutif Servify pada @ dan akan dikirimkan ke Pusat Servis Resmi.",
    "isAlreadyTranslated": true
  },
  "Repair Cancel. Device will be delivered soon": {
    "text": "Perbaikan dibatalkan. Perangkat akan dikirim segera",
    "isAlreadyTranslated": true
  },
  "Logistics Agent @ @ has picked up your device and it will be delivered to Authorized Service Centre.": {
    "text": "Agen Logistik @ @ telah menjemput perangkat Anda dan akan mengirimkannya ke Pusat Servis Resmi.",
    "isAlreadyTranslated": true
  },
  "We Pick up\\nYour Device": {
    "text": "Kami Menjemput\\nPerangkat Anda",
    "isAlreadyTranslated": true
  },
  "Device handed over by @ to @": {
    "text": "Perangkat diserahkan dari @ untuk @",
    "isAlreadyTranslated": true
  },
  "Device handed over by @ from @": {
    "text": "Perangkat diserahkan dari @ ke @",
    "isAlreadyTranslated": true
  },
  "Your request for @(@/@) has been registered.": {
    "text": "Permintaan Anda untuk @(@/@) telah didaftarkan.",
    "isAlreadyTranslated": true
  },
  "Pickup Drop Repair": {
    "text": "Perbaikan Antar Jemput",
    "isAlreadyTranslated": true
  },
  "Carry in Repair": {
    "text": "Perbaikan Carry In",
    "isAlreadyTranslated": true
  },
  "Home Repair": {
    "text": "Perbaikan di Rumah",
    "isAlreadyTranslated": true
  },
  "Your cancellation request has been accepted for pickup drop (@/@/@)": {
    "text": "permintaan pembatalan Anda telah diterima untuk penurunan jemput (@ / @ / @)",
    "isAlreadyTranslated": true
  },
  "Your cancellation request has been accepted for carry in repair (@/@/@)": {
    "text": "permintaan pembatalan Anda telah diterima untuk carry dalam perbaikan (@ / @ / @)",
    "isAlreadyTranslated": true
  },
  "Your cancellation request has been accepted for home repair (@/@/@)": {
    "text": "permintaan pembatalan Anda telah diterima untuk perbaikan rumah (@ / @ / @)",
    "isAlreadyTranslated": true
  },
  "Your cancellation request has been accepted for installation (@/@/@)": {
    "text": "permintaan pembatalan Anda telah diterima untuk instalasi (@ / @ / @)",
    "isAlreadyTranslated": true
  },
  "Your cancellation request has been accepted for demo (@/@/@)": {
    "text": "permintaan pembatalan Anda telah diterima untuk demo (@ / @ / @)",
    "isAlreadyTranslated": true
  },
  "With Express Check In you can skip your waiting time at Service Center. You will be attended to within 7 minutes of reaching Service Center": {
    "text": "Dengan Check-In Ekspres Anda dapat melewati waktu tunggu di Pusat Servis. Anda akan dilayani 7 menit setelah sampai di Pusat Servis",
    "isAlreadyTranslated": true
  },
  "Your cancellation request has been accepted for service (@/@/@)": {
    "text": "permintaan pembatalan Anda telah diterima untuk layanan (@ / @ / @)",
    "isAlreadyTranslated": true
  },
  "You have cancelled your request for @ scheduled on @. We hope to serve you soon.": {
    "text": "Anda telah membatalkan permintaan Anda untuk @ yang dijadwalkan pada @. Semoga kami dapat membantu Anda lain kali.",
    "isAlreadyTranslated": true
  },
  "Your request for rescheduling has been accepted for Pickup Drop Repair (@/@/@)": {
    "text": "Permintaan penjadwalan ulang telah diterima untuk Perbaikan Antar Jemput (@/@/@)",
    "isAlreadyTranslated": true
  },
  "Your cancellation request has been accepted for pickup drop (@ / @ / @)": {
    "text": "permintaan pembatalan Anda telah diterima untuk penurunan jemput (@ / @ / @)",
    "isAlreadyTranslated": true
  },
  "Your request for rescheduling has been accepted for Carry in Repair (@/@/@)": {
    "text": "Permintaan penjadwalan ulang sudah diterima untuk perbaikan Carry In Anda( @/@/@)",
    "isAlreadyTranslated": true
  },
  "Your request for rescheduling has been accepted for eRecycling (@/@/@)": {
    "text": "Permintaan penjadwalan ulang telah diterima untuk eRecycle (@/@/@)",
    "isAlreadyTranslated": true
  },
  "Your cancellation request has been accepted for carry in repair (@ / @ / @)": {
    "text": "permintaan pembatalan Anda telah diterima untuk carry dalam perbaikan (@ / @ / @)",
    "isAlreadyTranslated": true
  },
  "Your request for rescheduling has been accepted for Home Repair (@/@/@)": {
    "text": "Permintaan penjadwalan ulang telah diterima untuk Perbaikan di Rumah (@/@/@)",
    "isAlreadyTranslated": true
  },
  "Your request for rescheduling has been accepted for Installation (@/@/@)": {
    "text": "Permintaan penjadwalan ulang telah diterima untuk Instalasi (@/@/@)",
    "isAlreadyTranslated": true
  },
  "Your request for rescheduling has been accepted for demo (@/@/@)": {
    "text": "Permintaan penjadwalan ulang telah diterima untuk demo (@/@/@)",
    "isAlreadyTranslated": true
  },
  "Your request for rescheduling has been accepted for Servicing (@/@/@)": {
    "text": "Permintaan penjadwalan ulang telah diterima untuk Servis (@/@/@)",
    "isAlreadyTranslated": true
  },
  "Your request for Pickup Drop Repair (@/@) has been registered.": {
    "text": "Permintaan Anda untuk Perbaikan Antar Jemput (@/@) telah didaftarkan.",
    "isAlreadyTranslated": true
  },
  "Your request for Carry in Repair (@/@) has been registered.": {
    "text": "Permintaan Anda untuk perbaikan Carry In ( @/@ ) sudah terdaftar",
    "isAlreadyTranslated": true
  },
  "Your request for rescheduling has been accepted for @": {
    "text": "Permintaan penjadwalan ulang telah diterima untuk @",
    "isAlreadyTranslated": true
  },
  "Your request for Home Repair (@/@) has been registered.": {
    "text": "Permintaan Anda untuk Perbaikan di Rumah (@/@) telah didaftarkan.",
    "isAlreadyTranslated": true
  },
  "You have successfully pledged to eRecycle your iPhone in @! We will remind you to submit your iPhone for eRecycling a few days prior to your chosen month.": {
    "text": "Anda berhasil berjanji untuk eRecycle iPhone Anda di @! Kami akan mengingatkan untuk menyerahkan iPhone Anda untuk eRecycle beberapa hari sebelum memasuki bulan yang Anda pilih.",
    "isAlreadyTranslated": true
  },
  "Your request number is @. Our Logistics Agent will collect the device from you as per the scheduled time. To know more, please check Track Request section within the App. T&C apply.": {
    "text": "Nomor permintaan Anda adalah @. Agen Logistik kami akan mengambil perangkat Anda sesuai waktu yang dijadwalkan. Untuk informasi selengkapnya, periksa bagian Lacak Permintaan di dalam Aplikasi. Syarat & Ketentuan berlaku.",
    "isAlreadyTranslated": true
  },
  "Your dropoff is scheduled on @ at @. Ref ID: @": {
    "text": "Pengantaran Anda dijadwalkan pada @ di @. ID Ref: @",
    "isAlreadyTranslated": true
  },
  "Your eRecycle request (@ / @) has been cancelled as per your request. Ref ID: @": {
    "text": "Permintaan eRecycle (@ / @) telah dibatalkan sesuai permintaan Anda. ID Ref: @",
    "isAlreadyTranslated": true
  },
  "Your home repair request (@ / @) has been registered. You will receive confirmation 30 mins prior to the requested time slot.": {
    "text": "Permintaan perbaikan di rumah (@ / @) telah didaftarkan. Anda akan menerima konfirmasi 30 menit sebelum slot waktu yang diminta.",
    "isAlreadyTranslated": true
  },
  "Servify has received your @. It will be disassembled & sent to the eRecycling Hub after verification. Ref ID: @": {
    "text": "Servify telah menerima @ Anda. Perangkat akan dibongkar dan dikirim ke Pusat eRecycle setelah verifikasi. ID Ref: @",
    "isAlreadyTranslated": true
  },
  "Your @ has been eRecycled. Please rate your eRecycling experience at https://xcxa.app.link/rate?csr=@ . Ref ID: @": {
    "text": "@ Anda telah masuk eRecycle. Nilai pengalaman eRecycle Anda di https://xcxa.app.link/rate?csr=@ . ID Ref: @",
    "isAlreadyTranslated": true
  },
  "Good News! Your @ has been validated successfully. You are also eligible to participate in the Green Champion Contest. Click here @ to know how to participate.": {
    "text": "Bagus Sekali! @ Anda berhasil divalidasi. Anda juga berhak ikut dalam Kontes Green Champion untuk memenangkan Kontes Green Champion. Klik di sini @ untuk mengetahui cara ikut serta.",
    "isAlreadyTranslated": true
  },
  "Your eRecycle request has been rejected. To know more call 18001214984. Ref ID: @": {
    "text": "Permintaan eRecycle Anda telah ditolak. Untuk informasi selengkapnya, hubungi 18001214984. ID Ref: @",
    "isAlreadyTranslated": true
  },
  "Your @ has been dropped off at @. Ref ID: @": {
    "text": "@ Anda telah dijemput di @. ID Ref: @",
    "isAlreadyTranslated": true
  },
  "Your disassembled @ and it's key components have reached the eRecycling Hub and will be eRecycled soon. Ref ID: @": {
    "text": "@ Anda yang telah dibongkar beserta suku cadang pentingnya telah sampai di Pusat eRecycle dan akan segera didaur ulang. ID Ref: @",
    "isAlreadyTranslated": true
  },
  "We have disassembled key components of your device and will be sending it to the eRecycling Hub soon. Ref ID: @": {
    "text": "Kami telah membongkar komponan penting perangkat Anda dan akan segera mengirimkannya ke Pusat eRecycle. ID Ref: @",
    "isAlreadyTranslated": true
  },
  "Sweet! @ has accepted your request to fix your @": {
    "text": "Bagus! @ telah menerima permintaan Anda untuk memperbaiki @ Anda",
    "isAlreadyTranslated": true
  },
  "New job from Servify": {
    "text": "Pekerjaan baru dari Servify",
    "isAlreadyTranslated": true
  },
  "Your @ has been successfully activated for your device @": {
    "text": "@ Anda berhasil diaktifkan untuk perangkat @ Anda",
    "isAlreadyTranslated": true
  },
  "@ has been successfully Purchased and Activated for your device": {
    "text": "@ berhasil Dibeli dan Diaktifkan untuk perangkat Anda",
    "isAlreadyTranslated": true
  },
  "@ has been successfully Purchased for your device": {
    "text": "@ berhasil Dibeli untuk perangkat Anda",
    "isAlreadyTranslated": true
  },
  "Good News! Your @ has been validated successfully.": {
    "text": "Bagus Sekali! @ Anda berhasil divalidasi.",
    "isAlreadyTranslated": true
  },
  "Your carry in repair request @ for @ at (@, @) has been registered. Please check track repair section to get the direction to the location.": {
    "text": "Permintaan perbaikan Carry In Anda @ untuk @ di ( @,@ ) sudah terdaftar. Silahkan ke track repair section untuk mendapat arah ke Service Center",
    "isAlreadyTranslated": true
  },
  "@ is your OTP for Servify. This OTP is valid for 120 seconds. Do not share this OTP with anyone for security reasons.": {
    "text": "@ adalah OTP (Sandi Sementara) Anda untuk Servify. OTP ini berlaku selama 120 detik. Jangan bagikan OTP ini dengan siapa pun demi keamanan.",
    "isAlreadyTranslated": true
  },
  "Your home repair request (@ / @) has been registered. You will receive confirmation 30mins prior to the requested time slot.": {
    "text": "Permintaan perbaikan di rumah (@ / @) telah didaftarkan. Anda akan menerima konfirmasi 30 menit sebelum slot waktu yang diminta.",
    "isAlreadyTranslated": true
  },
  "Download the Servify app from http://bit.ly/27WoDAv and get a better service experience. \\n Servify - for all your service needs.": {
    "text": "Unduh aplikasi Servify dari http://bit.ly/27WoDAv dan dapatkan pengalaman lebih baik. \\n Servify - memenuhi semua kebutuhan servis Anda.",
    "isAlreadyTranslated": true
  },
  "Your request number is @. Our Partner (@, @) will collect the device from you as per the scheduled time. To know more, please check Track Request section within the App. T&C apply.": {
    "text": "Nomor permintaan Anda @. Partner kami (@, @) akan mengumpulkan perangkat dari Anda sebagai per waktu yang dijadwalkan. Untuk mengetahui lebih lanjut, silakan cek Lacak bagian Permintaan dalam App. T & C berlaku.",
    "isAlreadyTranslated": true
  },
  "@ | We have received your claim request. Our representative will connect with you in next 48 hours.": {
    "text": "@ | Kami telah menerima permintaan klaim Anda. Perwakilan kami akan menghubungi Anda dalam 48 jam ke depan.",
    "isAlreadyTranslated": true
  },
  "Your device will be picked up on @. Your Secret Code is @. Please handover the device to the Logistics Agent only once they provide this secret code to prove their identity.": {
    "text": "Perangkat Anda akan dijemput pada @. Kode Rahasia Anda adalah @. Serahkan perangkat hanya kepada Agen Logistik setelah dia memberikan kode rahasia ini untuk membuktikan identitasnya.",
    "isAlreadyTranslated": true
  },
  "Your Activation Code to register for the @ Plan is @. Download the @ app from @ and get a better service experience.": {
    "text": "Kode Aktivasi untuk mendaftar Paket @ adalah @. Unduh aplikasi @ dari @ dan dapatkan pengalaman lebih baik.",
    "isAlreadyTranslated": true
  },
  "Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips. Download now. @": {
    "text": "Dapatkan Servis Resmi untuk iPhone, iPad, Apple Watch dan Mac dari perangkat Anda. Unduh sekarang. @",
    "isAlreadyTranslated": true
  },
  "Pick up of your @ (Ref No. - @) has been rescheduled to @ as requested.": {
    "text": "Penjemputan @ Anda (No Ref. - @) telah dijadwalkan ulang ke @ sesuai permintaan.",
    "isAlreadyTranslated": true
  },
  "Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips.\\niOS App - @\\nWeb Portal - @": {
    "text": "Dapatkan Servis Resmi untuk iPhone, iPad, Watch dan Mac dari perangkat Anda.\\niOS App - @\\nWeb Portal - @",
    "isAlreadyTranslated": true
  },
  "Download Lenovo Care: Personal assistant to all your Lenovo & Motorola smartphones https://bnc.lt/lenovo-care": {
    "text": "Unduh Lenovo Care: Asisten pribadi untuk smartphone Lenovo & Motorola Anda https://bnc.lt/lenovo-care",
    "isAlreadyTranslated": true
  },
  "Device Picked up: Your @ (Ref ID - @) was collected by Servify Executive on @ and will be delivered to an Authorized Service Centre. To know more, please check Track Repair section within the App. T&C Apply.": {
    "text": "Perangkat Dijemput: Perangkat @ Anda (ID Ref - @) telah diambil oleh Eksekutif Servify pada @ dan akan dikirimkan ke Pusat Servis Resmi. Untuk informasi selengkapnya, periksa bagian Lacak Perbaikan di dalam Aplikasi. Syarat & Ketentuan Berlaku.",
    "isAlreadyTranslated": true
  },
  "@ is your OTP for Micromax Care. This OTP is valid for 120 seconds. Do not share this OTP with any one for security reasons. We look forward to serving you.": {
    "text": "@ adalah OTP (Sandi Sementara) Anda untuk Micromax Care. OTP ini berlaku selama 120 detik. Jangan membagikan OTP ini dengan siapa pun demi keamanan. Kami tak sabar ingin melayani Anda.",
    "isAlreadyTranslated": true
  },
  "Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips. Download now. https://bit.ly/authorized-service": {
    "text": "Dapatkan Servis Resmi untuk iPhone, iPad, Apple Watch dan Mac dari perangkat Anda. Unduh sekarang. https://bit.ly/authorized-service",
    "isAlreadyTranslated": true
  },
  "@ | We have received your claim request. Our representative will connect with you in next one business day.": {
    "text": "@ | Kami telah menerima permintaan klaim Anda. Perwakilan kami akan menghubungi Anda di hari kerja berikutnya.",
    "isAlreadyTranslated": true
  },
  "OnePlus Care App: Manage, Diagnose and Service your OnePlus Mobile Phone, download - https://jf1t.app.link/l1cm5EozpA": {
    "text": "Aplikasi OnePlus Care: Kelola, Diagnosis dan Servis Ponsel OnePlus Anda, unduh di - https://jf1t.app.link/l1cm5EozpA",
    "isAlreadyTranslated": true
  },
  "Your Activation Code to register for the @ Plan is @. Download the Micromax Care app from http://bit.ly/MMXCare and get a better service experience.": {
    "text": "Kode Aktivasi untuk mendaftar Paket @ adalah @. Unduh aplikasi Micromax Care dari http://bit.ly/MMXCare dan dapatkan pengalaman lebih baik.",
    "isAlreadyTranslated": true
  },
  "Pickup Rescheduled: Logistics Agent will attempt pickup of your @ on @ as requested.": {
    "text": "Penjemputan Dijadwalkan Ulang: Agen Logistik akan mencoba menjemput @ di @ sesuai permintaan.",
    "isAlreadyTranslated": true
  },
  "New Request Ref. ID @ Name: @ Phone: @ Landmark: @": {
    "text": "Permintaan Baru ID Ref. @ Nama: @ Telp: @ Lokasi: @",
    "isAlreadyTranslated": true
  },
  "Greetings from Micromax. Congratulations! Your Dual 5 is now protected under Micromax Protect. Welcome to Hassle free Service experience.": {
    "text": "Salam dari Micromax. Selamat! Dual 5 Anda sekarang dilindungi Micromax Protect. Selamat datang di pengalaman servis cepat.",
    "isAlreadyTranslated": true
  },
  "Greetings from Micromax . Welcome to Micromax Care, your personal device assistant for all Micromax devices.": {
    "text": "Salam dari Micromax. Selamat datang di Micromax Care, asisten pribadi untuk semua perangkat Micromax Anda.",
    "isAlreadyTranslated": true
  },
  "Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips.\\niOS App - https://bit.ly/authorized-service\\nWeb Portal - https://servify.in/iphone": {
    "text": "Dapatkan Layanan Resmi untuk iPhone, iPad, Apple Watch dan Mac dari perangkat Anda.\\nApp iOS - https://bit.ly/authorized-service\\nWeb Portal - https://servify.in/iphone",
    "isAlreadyTranslated": true
  },
  "Thank you for choosing Micromax Care, please rate our service on the App. We look forward to serving you again.": {
    "text": "Terima kasih telah memilih Micromax Care, berikanlah nilai untuk layanan kami di App. Kami tak sabar ingin melayani Anda lagi.",
    "isAlreadyTranslated": true
  },
  "Download \"eRecycle\" on your iPhone to raise and track eRecycle requests on the go. @": {
    "text": "Ambil \"eRecycle\" pada iPhone Anda untuk meningkatkan dan melacak permintaan eRecycle di perjalanan. @",
    "isAlreadyTranslated": true
  },
  "Device Picked up: Your @ (Ref ID - @) was collected by Logistics Agent on @ and will be delivered to Micromax Authorized Service Centre. To know more, please check Track Repair section within the App. T&C Apply.": {
    "text": "Perangkat Dijemput: @ Anda (Ref ID - @) telah diambil oleh Agen Logistik pada @ dan akan dikirimkan ke Pusat Servis Resmi Micromax. Untuk informasi selengkapnya, periksa bagian Lacak Perbaikan di dalam Aplikasi. Syarat & Ketentuan Berlaku.",
    "isAlreadyTranslated": true
  },
  "Dear Customer,\\nWe have received a claim request for your device. Please click on the following link to cross check the information shared by you and submit to raise the claim. Kindly note, once submitted, the details cannot be changed.\\n@": {
    "text": "Pelanggan yang Terhormat,\\nKami telah menerima permintaan klaim untuk perangkat Anda. Klik tautan berikut untuk memeriksa informasi yang Anda berikan dan kirim untuk mengajukan klaim. Perhatikan, setelah dikirim, rincian tidak bisa diubah.\\n@",
    "isAlreadyTranslated": true
  },
  "Your device pickup is scheduled on @ with WAYBILL NUMBER @. Please hand over the Packed Device with Printed Documents, emailed by Servify to your Registered Email ID. PLEASE NOTE: Do NOT hand over the invoice copy of your phone to our Logistics Agent.": {
    "text": "Penjemputan perangkat Anda dijadwalkan pada @ dengan NOMOR RESI @. Serahkan Perangkat yang Dikemas disertai Dokumen Cetak yang telah dikirimkan oleh Servify melalui email ke ID Email Terdaftar Anda. INGAT: JANGAN menyerahkan salinan tagihan ponsel Anda kepada Agen Logistik kami.",
    "isAlreadyTranslated": true
  },
  "Amount Paid": {
    "text": "Jumlah yang dibayar",
    "isAlreadyTranslated": true
  },
  "Demo request (@/@) has been resheduled by the consumer": {
    "text": "Permintaan demo (@/@) telah dijadwalkan ulang oleh pelanggan",
    "isAlreadyTranslated": true
  },
  "WarrantyConcession": {
    "text": "KelonggaranGaransi",
    "isAlreadyTranslated": true
  },
  "Your @ has been repaired. Please collect it from (@/@)": {
    "text": "@ Anda telah diperbaiki. Silakan ambil di (@/@)",
    "isAlreadyTranslated": true
  },
  "Repair request (@/@) has been resheduled by the consumer": {
    "text": "Permintaan perbaikan telah dijadwal ulang oleh pelanggan",
    "isAlreadyTranslated": true
  },
  "Your request for rescheduling has been accepted for @ (@/@/@)": {
    "text": "Permintaan penjadwalan ulang telah diterima untuk @ (@/@/@)",
    "isAlreadyTranslated": true
  },
  "Your @ has been repaired & delivered.": {
    "text": "@ Anda telah diperbaiki & dikirim.",
    "isAlreadyTranslated": true
  },
  "Installation request (@/@) has been canceled by the consumer": {
    "text": "Permintaan Instalasi ( @/@) telah dibatalkan oleh pelanggan",
    "isAlreadyTranslated": true
  },
  "Your service request is Rescheduled by @": {
    "text": "Permintaan servis Anda telah dijadwalkan ulang oleh @",
    "isAlreadyTranslated": true
  },
  "Installation request (@/@) has been resheduled by the consumer": {
    "text": "Permintaan instalasi (@/@) telah dijadwalkan ulang oleh pelanggan",
    "isAlreadyTranslated": true
  },
  "Demo request (@/@) has been canceled by the consumer": {
    "text": "Permintaan demo (@/@) telah dibatalkan oleh pelanggan",
    "isAlreadyTranslated": true
  },
  "Service request (@/@) has been resheduled by the consumer": {
    "text": "Permintaan servis (@/@) telah dijadwalkan ulang oleh pelanggan",
    "isAlreadyTranslated": true
  },
  "Your request (@/@) has been suspended": {
    "text": "Permintaan anda @/@ telah diberhentikan",
    "isAlreadyTranslated": true
  },
  "@ is on the way to take care of your @.": {
    "text": "@ dalam perjalanan untuk mengurus @ Anda.",
    "isAlreadyTranslated": true
  },
  "Payment received for repair request (@)": {
    "text": "Pembayaran diterima untuk permintaan perbaikan",
    "isAlreadyTranslated": true
  },
  "Awaiting Documents. Please Upload by @.": {
    "text": "Menunggu Dokumen, Silahkan upload dengan @",
    "isAlreadyTranslated": true
  },
  "Claim Intimation Number : @.": {
    "text": "Nomor Pengajuan Klaim",
    "isAlreadyTranslated": true
  },
  "Verification successful. Kindly book a repair by @.": {
    "text": "Verifikasi berhasil. Pesan perbaikan sebelum @.",
    "isAlreadyTranslated": true
  },
  "Device has been received by the service centre and is currently being diagnosed.": {
    "text": "Perangkat telah diterima oleh Service Center dan sedang didiagnosis.",
    "isAlreadyTranslated": true
  },
  "Your payment has been received.": {
    "text": "Pembayaran Anda telah diterima.",
    "isAlreadyTranslated": true
  },
  "Your device is dispatched.": {
    "text": "Perangkat Anda telah dikirim.",
    "isAlreadyTranslated": true
  },
  "We are working on your request and transfer of the eligible amount will be initiated soon.": {
    "text": "Kami sedang mengerjakan permintaan Anda, dan transfer untuk jumlah biaya sebenarnya akan segera diberikan.",
    "isAlreadyTranslated": true
  },
  "Your device is repaired. Please visit the service centre to collect.": {
    "text": "Perangkat Anda telah diperbaiki. Kunjungi pusat servis untuk mengambilnya.",
    "isAlreadyTranslated": true
  },
  "Repair complete.": {
    "text": "Perbaikan selesai",
    "isAlreadyTranslated": true
  },
  "Your request has lapsed due to non submission of the documents.": {
    "text": "Permintaan Anda gagal karena Anda tidak mengirimkan dokumen.",
    "isAlreadyTranslated": true
  },
  "Your claim has been declined. Please continue with self-paid repair.": {
    "text": "Klaim Anda telah ditolak. Silakan lanjutkan perbaikan dengan biaya sendiri.",
    "isAlreadyTranslated": true
  },
  "Your request has lapsed since no repair has been booked.": {
    "text": "Permintaan Anda gagal karena perbaikan belum dipesan.",
    "isAlreadyTranslated": true
  },
  "Your request has lapsed as we have not received payment for the repair": {
    "text": "Permintaan Anda gagal karena kami belum menerima pembayaran untuk perbaikan",
    "isAlreadyTranslated": true
  },
  "Your request has been cancelled": {
    "text": "Permintaan Anda telah dibatalkan",
    "isAlreadyTranslated": true
  },
  "Your device is ready to be collected by you. We hope you are satisfied with the service experience. Reference ID:@": {
    "text": "Perangkat Anda siap diambil. Semoga Anda puas dengan pengalaman servis ini. ID Referensi:@",
    "isAlreadyTranslated": true
  },
  "External storage issue": {
    "text": "Masalah memori eksternal",
    "isAlreadyTranslated": true
  },
  "Noise issue": {
    "text": "Maslaah Suara",
    "isAlreadyTranslated": true
  },
  "Not powering ON": {
    "text": "Tidak bisa menyala",
    "isAlreadyTranslated": true
  },
  "Not powering off": {
    "text": "Tidak bisa dimatikan",
    "isAlreadyTranslated": true
  },
  "Intermittently powering off": {
    "text": "Kadang Kadang mati",
    "isAlreadyTranslated": true
  },
  "No display": {
    "text": "Tidak ada tampilan",
    "isAlreadyTranslated": true
  },
  "Flickering": {
    "text": "Berkedip kedip",
    "isAlreadyTranslated": true
  },
  "Improper display": {
    "text": "Tampilan tidak bener",
    "isAlreadyTranslated": true
  },
  "Blur display": {
    "text": "Layar blur",
    "isAlreadyTranslated": true
  },
  "Spot on display": {
    "text": "Bintik bintik dilayar",
    "isAlreadyTranslated": true
  },
  "No sound": {
    "text": "Tidak ada suara",
    "isAlreadyTranslated": true
  },
  "Cracking sound": {
    "text": "Suara pecah - pecah",
    "isAlreadyTranslated": true
  },
  "Less sound": {
    "text": "Suara kecil",
    "isAlreadyTranslated": true
  },
  "Network Issue": {
    "text": "Masalah jaraingan",
    "isAlreadyTranslated": true
  },
  "Display damaged": {
    "text": "Layar rusak",
    "isAlreadyTranslated": true
  },
  "Body damaged": {
    "text": "Casing Rusak",
    "isAlreadyTranslated": true
  },
  "Vibrator not working": {
    "text": "Vibrator tidak berfungsi",
    "isAlreadyTranslated": true
  },
  "Internal data storage issue": {
    "text": "Maslaah penyimpanan internal",
    "isAlreadyTranslated": true
  },
  "Charger not working": {
    "text": "Charger tidak berfungsi",
    "isAlreadyTranslated": true
  },
  "Data cable not working": {
    "text": "Kabel data tidak berfungsi",
    "isAlreadyTranslated": true
  },
  "Slow processing or hangs": {
    "text": "Proses lambat atau hang",
    "isAlreadyTranslated": true
  },
  "Back camera issue": {
    "text": "Masalah kamera belakang",
    "isAlreadyTranslated": true
  },
  "Full Diagnosis": {
    "text": "Diagnosa semuanya",
    "isAlreadyTranslated": true
  },
  "Proper Assembly": {
    "text": "Pemasangan yang bener",
    "isAlreadyTranslated": true
  },
  "Power Button": {
    "text": "Tombol Power",
    "isAlreadyTranslated": true
  },
  "Unlock Button": {
    "text": "Tombol Unlock",
    "isAlreadyTranslated": true
  },
  "Back Button": {
    "text": "Tombol back",
    "isAlreadyTranslated": true
  },
  "Home Button": {
    "text": "Tombol Home",
    "isAlreadyTranslated": true
  },
  "TAB Button": {
    "text": "Tombol Tab",
    "isAlreadyTranslated": true
  },
  "Volume Up Button": {
    "text": "Tombol volume up",
    "isAlreadyTranslated": true
  },
  "Volume Down Button": {
    "text": "Tombol volume down",
    "isAlreadyTranslated": true
  },
  "LED Flash": {
    "text": "LED Flash",
    "isAlreadyTranslated": true
  },
  "Dead Spots": {
    "text": "Dead spot",
    "isAlreadyTranslated": true
  },
  "LCD Screen": {
    "text": "Layar LCD",
    "isAlreadyTranslated": true
  },
  "Battery Storage": {
    "text": "Penyimpanan baterai",
    "isAlreadyTranslated": true
  },
  "Charging Port": {
    "text": "Charging Port",
    "isAlreadyTranslated": true
  },
  "Ear Piece": {
    "text": "Earphone",
    "isAlreadyTranslated": true
  },
  "USB Connectivity": {
    "text": "koneksi USB",
    "isAlreadyTranslated": true
  },
  "Loud Speaker": {
    "text": "Speaker",
    "isAlreadyTranslated": true
  },
  "Sensors": {
    "text": "Sensor",
    "isAlreadyTranslated": true
  },
  "Microphone": {
    "text": "Microphone",
    "isAlreadyTranslated": true
  },
  "Front Camera": {
    "text": "Kamera depan",
    "isAlreadyTranslated": true
  },
  "Back Camera": {
    "text": "kamera belakang",
    "isAlreadyTranslated": true
  },
  "Bluetooth": {
    "text": "Bluetooth",
    "isAlreadyTranslated": true
  },
  "Wi-Fi": {
    "text": "Wi-Fi",
    "isAlreadyTranslated": true
  },
  "Network Connection": {
    "text": "Koneksi jaringan",
    "isAlreadyTranslated": true
  },
  "Incoming call": {
    "text": "Panggilan masuk",
    "isAlreadyTranslated": true
  },
  "Outgoing call": {
    "text": "Panggilan keluar",
    "isAlreadyTranslated": true
  },
  "Vibrator": {
    "text": "Vibrator t",
    "isAlreadyTranslated": true
  },
  "IMEI Check": {
    "text": "Cek Imei",
    "isAlreadyTranslated": true
  },
  "Software Version": {
    "text": "Versi Software",
    "isAlreadyTranslated": true
  },
  "Check If Device Box IMEI Matches With Device IMEI": {
    "text": "Periksa Apakah IMEI Kotak Perangkat sesuai dengan IMEI Perangkat",
    "isAlreadyTranslated": true
  },
  "Check If Device IMEI Matches With Internal IMEI": {
    "text": "Periksa Apakah IMEI Perangkat sesuai dengan IMEI Internal",
    "isAlreadyTranslated": true
  },
  "Check If Touch/Display Is Broken": {
    "text": "Periksa Apakah Touch Screen/Layar Rusak",
    "isAlreadyTranslated": true
  },
  "Check If SIM Tray Is Missing Or Broken": {
    "text": "Periksa Apakah SIM Tray Hilang Atau Rusak",
    "isAlreadyTranslated": true
  },
  "Check If Back Cover Is Damaged": {
    "text": "Periksa Apakah Cover Belakang Rusak",
    "isAlreadyTranslated": true
  },
  "Check If Screw Heads Are Damaged": {
    "text": "Periksa Apakah Kepala Baut Rusak",
    "isAlreadyTranslated": true
  },
  "Check If There Are Dents Or Internal Cracks In Display": {
    "text": "Periksa Apakah Ada Penyok Atau Retakan di Bagian Dalam Layar",
    "isAlreadyTranslated": true
  },
  "Check If Volume Keys Are Loose Or Not Functioning": {
    "text": "Periksa Apakah Tombol Volume key longgar Atau Tidak Berfungsi",
    "isAlreadyTranslated": true
  },
  "Check If Power Keys Are Loose Or Not Functioning": {
    "text": "Periksa Apakah Tombol Power longgar Atau Tidak Berfungsi",
    "isAlreadyTranslated": true
  },
  "Check If Camera's Auto Focus Is Not Working": {
    "text": "Periksa Apakah Fokus Otomatis Kamera Tidak Berfungsi",
    "isAlreadyTranslated": true
  },
  "Check If Photos Clicked Are Blurred": {
    "text": "Periksa Apakah Hasil Foto buram",
    "isAlreadyTranslated": true
  },
  "Check If USB Port Is Damaged": {
    "text": "Periksa Apakah Port USB Rusak",
    "isAlreadyTranslated": true
  },
  "Check If Accessories Are As Mentioned On The Sales Pack": {
    "text": "Periksa Apakah Aksesori sesuai dengan yang Tercantum di Paket Penjualan",
    "isAlreadyTranslated": true
  },
  "Check If There Are Functional Defects": {
    "text": "Periksa Apakah Ada Cacat Fungsi",
    "isAlreadyTranslated": true
  },
  "Box IMEI Vs Unit IMEI": {
    "text": "IMEI Kotak Vs IMEI Unit",
    "isAlreadyTranslated": true
  },
  "Unit IMEI Vs Internal IMEI": {
    "text": "IMEI Unit Vs IMEI Internal",
    "isAlreadyTranslated": true
  },
  "Touch/LCD Broken": {
    "text": "Layar Sentuh/LCD Rusak",
    "isAlreadyTranslated": true
  },
  "SIM Tray Missing or Broken": {
    "text": "SIM tray Hilang atau Rusak",
    "isAlreadyTranslated": true
  },
  "Back Cover Damaged": {
    "text": "Cover belakang rusak",
    "isAlreadyTranslated": true
  },
  "Screw heads Damaged": {
    "text": "Kepala sekrup Rusak",
    "isAlreadyTranslated": true
  },
  "Any Dents or Internal Crack in display": {
    "text": "Ada penyok atau Retak pada bagian dalam layar",
    "isAlreadyTranslated": true
  },
  "Power/Volume Keys Loose or Not Functioning": {
    "text": "Tombol power/ volume longgar atau tidak berfungsi",
    "isAlreadyTranslated": true
  },
  "Camera AF & Blurr Issues": {
    "text": "Masalah AF & Blur Kamera",
    "isAlreadyTranslated": true
  },
  "USB Port Damaged": {
    "text": "Port USB Rusak",
    "isAlreadyTranslated": true
  },
  "Accessories as per Content printed on sales pack": {
    "text": "Aksesori sesuai isi yang tercetak pada paket penjualan",
    "isAlreadyTranslated": true
  },
  "Functional Defects": {
    "text": "Cacat Fungsi",
    "isAlreadyTranslated": true
  },
  "@ | Your claim will be processed within the next 6 business hours": {
    "text": "@ | Klaim Anda akan diproses dalam 6 jam kerja",
    "isAlreadyTranslated": true
  },
  "@ | Your request has been cancelled.": {
    "text": "@ | Permintaan Anda telah dibatalkan.",
    "isAlreadyTranslated": true
  },
  "@ | Your request has lapsed as we have not received payment for the repair.": {
    "text": "@ | Permintaan Anda gagal karena kami belum menerima pembayaran untuk perbaikan.",
    "isAlreadyTranslated": true
  },
  "@ | Document verification was declined. Please visit @ to know the details": {
    "text": "@ | Verifikasi dokumen ditolak. Kunjungi @ untuk membaca rinciannya",
    "isAlreadyTranslated": true
  },
  "@ | Your claim has been declined. Please continue with self-paid repair.": {
    "text": "@ | Klaim Anda telah ditolak. Silakan lanjutkan perbaikan dengan biaya sendiri.",
    "isAlreadyTranslated": true
  },
  "@ | Your request has lapsed as you have not booked a repair request.": {
    "text": "@ | Permintaan Anda gagal karena Anda belum memesan permintaan perbaikan.",
    "isAlreadyTranslated": true
  },
  "@Check here first@ Go to @Settings > General > About@ and look for your device's serial number, IMEI/MEID, and ICCID. Tap and hold on the number and copy it.\\nIf you still haven't found the number that you need, see below for other ways to locate it.": {
    "text": "@Periksa di sini dulu@ Buka @Settings (Pengaturan) > General (Umum) > About (Tentang)@ kemudian cari nomor seri, IMEI/MEID, dan ICCID perangkat. Tekan terus nomor, lalu salin.\\nJika masih belum menemukan nomor yang dibutuhkan, lihat di bawah ini untuk cara lain mencarinya",
    "isAlreadyTranslated": true
  },
  "@Or check the case of your iPhone@ On these devices, you'll find the serial number in the Settings menu and the IMEI/MEID on the SIM tray. If you need support but you can't get to the Settings menu, you can use the IMEI/MEID instead of the serial number: • iPhone 7 • iPhone 7 Plus • iPhone 6s • iPhone 6s Plus": {
    "text": "@Atau cari di casing iPhone Anda@ Pada perangkat ini, Anda akan menemukan nomor seri di menu Pengaturan dan IMEI/MEID pada wadah SIM. Jika Anda memerlukan bantuan tetapi tidak dapat membuka menu Pengaturan, gunakan IMEI/MEID, bukan nomor seri: • iPhone 7 • iPhone 7 Plus • iPhone 6s • iPhone 6s Plus",
    "isAlreadyTranslated": true
  },
  "On these devices, you'll find the serial number in the Settings menu and the IMEI/MEID (the MEID is the first 14 digits of the IMEI) on the back. If you need support but you can't get to the Settings menu, you can use the IMEI/MEID instead of the serial number: • iPhone 6 • iPhone 6 Plus • iPhone SE • iPhone 5s • iPhone 5c • iPhone 5": {
    "text": "Pada perangkat ini, Anda akan menemukan nomor seri di menu Pengaturan dan IMEI/MEID (MEID adalah 14 angka pertama pada IMEI) di bagian belakang. Jika Anda memerlukan bantuan tetapi tidak dapat membuka menu Pengaturan, gunakan IMEI/MEID, bukan nomor seri: • iPhone 6 • iPhone 6 Plus • iPhone SE • iPhone 5s • iPhone 5c • iPhone 5",
    "isAlreadyTranslated": true
  },
  "On these devices, you can find the serial number and IMEI/MEID on the SIM tray: • iPhone 3G • iPhone 3GS • iPhone 4 (GSM model) • iPhone 4s": {
    "text": "Pada perangkat ini, Anda dapat menemukan nomor seri dan IMEI/MEID pada wadah SIM: • iPhone 3G • iPhone 3GS • iPhone 4 (model GSM) • iPhone 4s",
    "isAlreadyTranslated": true
  },
  "Go to @Settings > General > About @ and look for your device's serial number, IMEI/MEID, and ICCID. Tap and hold on the number and copy it.": {
    "text": "Buka @Pengaturan > Umum > Tentang @ kemudian cari nomor seri, IMEI/MEID, dan ICCID perangkat. Tekan terus nomor, lalu salin.",
    "isAlreadyTranslated": true
  },
  "You can find the serial number and IMEI/MEID on the @SIM tray.@": {
    "text": "Anda dapat menemukan nomor seri dan IMEI/MEID pada @wadah SIM.@",
    "isAlreadyTranslated": true
  },
  "Go to @Settings > General > About@ and look for your device's serial number, IMEI/MEID, and ICCID. Tap and hold on the number and copy it.": {
    "text": "Buka @Settings (Pengaturan) > General (Umum) > About (Tentang)@ kemudian cari nomor seri, IMEI/MEID, dan ICCID perangkat. Tekan terus nomor, lalu salin.",
    "isAlreadyTranslated": true
  },
  "You can find the IMEI/MEID on the @back of your phone.@": {
    "text": "Anda dapat menemukan IMEI/MEID di @bagian belakang ponsel.@",
    "isAlreadyTranslated": true
  },
  "Choose @About This Mac@ from the Apple menu @()@ in the upper-left corner of your screen. @About This Mac@ shows an overview of your Mac, including the name and version of its operating system, its model name, and its serial number:": {
    "text": "Pilih @About This Mac (Tentang Mac Ini)@ dari menu Apple @()@ di sudut kiri atas layar. @About This Mac@ menjelaskan gambaran umum Mac Anda, termasuk nama dan versi sistem operasi, nama model, serta nomor serinya:",
    "isAlreadyTranslated": true
  },
  "If you see an About This Mac window like the following, double-click the version number beneath 'OS X' to reveal the serial number:": {
    "text": "Jika Anda melihat jendela Tentang Mac ini seperti berikut ini, klik dua kali nomor versi di bawah 'OS X' untuk mengungkapkan nomor seri:",
    "isAlreadyTranslated": true
  },
  "@On the surface of your MacBook@ Close your MacBook and turn it over. The serial number is on the underside of the computer, near the regulatory markings.": {
    "text": "@Pada permukaan MacBook@ Tutup MacBook kemudian balik. Nomor seri ada di bagian bawah komputer, di dekat tanda kepatuhan.",
    "isAlreadyTranslated": true
  },
  "@On the surface of your iMac@ The serial number of your iMac is printed on its surface. To find it, shut down your iMac and unplug any connected cables so you can safely move it. Then gently lay the computer face-down on a soft, clean towel or cloth.\\nThe serial number is on the underside of the base (or foot) of the iMac, along with a bar code and regulatory markings.": {
    "text": "@Pada permukaan iMac@ Nomor seri iMac dicetak pada permukaannya. Untuk menemukannya, matikan iMac, kemudian lepas semua kabel yang terhubung sehingga Anda dapat memindahkannya dengan aman. Lalu, letakkan komputer menghadap ke bawah di atas handuk atau kain lembut yang bersih secara perlahan.Nomor seri ada di bagian bawah dasar (atau kaki) iMac, bersama dengan barkode dan tanda pengaturan.",
    "isAlreadyTranslated": true
  },
  "@Check the Settings menu@ On your Apple Watch, open the Settings app, tap General > About, then scroll down.\\nOn your iPhone, open the Apple Watch App, go to the My Watch tab, then tap @General > About@.On your iPhone, open the Apple Watch App, go to the My Watch tab, then tap General > About.": {
    "text": "@Periksa menu Settings (Pengaturan)@ Pada Apple Watch, buka aplikasi Settings (Pengaturan), ketuk General (Umum) > About (Tentang), lalu gulir ke bawah.\\nPada iPhone, buka Aplikasi Apple Watch, buka tab My Watch, lalu ketuk @General (Umum) > About (Tentang)@.Pada iPhone, buka Aplikasi Apple Watch, buka tab My Watch, buka tab My Watch, lalu ketuk @General (Umum) > About (Tentang).",
    "isAlreadyTranslated": true
  },
  "@Or check the case of your Apple Watch@On Apple Watch (1st generation), check the back of your watch for the engraved serial number.": {
    "text": "@Atau periksa casing Apple Watch@Pada Apple Watch (generasi pertama), periksa bagian belakang watch untuk melihat nomor seri yang terukir.",
    "isAlreadyTranslated": true
  },
  "On Apple Watch Series 1 and Apple Watch Series 2, including Apple Watch Hermès and Apple Watch Nike+, remove the band from your watch, then check the band slot.": {
    "text": "Pada Apple Watch Seri 1 dan Apple Watch Seri 2, termasuk Apple Watch Hermès dan Apple Watch Nike+, lepas pita dari watch, lalu periksa slot pita.",
    "isAlreadyTranslated": true
  },
  "Find the serial number for your iPad Pro, iPad, or iPod touch on the back of the device. On an iPad (cellular model), you'll also find the IMEI. The MEID is the first 14 digits of the IMEI.": {
    "text": "Temukan nomor seri untuk iPad Pro, iPad, atau iPod touch di bagian belakang perangkat. Pada iPad (model data seluler), Anda juga akan menemukan IMEI. MEID adalah 14 angka pertama pada IMEI.",
    "isAlreadyTranslated": true
  },
  "Find the serial number for your iPod touch on the back of the device": {
    "text": "Temukan nomor seri untuk iPod, sentuh di bagian belakang perangkat",
    "isAlreadyTranslated": true
  },
  "@Check the About screen for the serial number@ To see the serial number for your Apple TV, choose Settings > General > About. You can also see the serial number for your Siri Remote or Apple TV Remote* by choosing Settings > Remotes and Devices > Remote.": {
    "text": "@Periksa layar Tentang untuk nomor seri@ Untuk melihat nomor seri Apple TV, pilih Settings (Pengaturan) > General (Umum) > About (Tentang). Anda juga dapat melihat nomor seri untuk Siri Remote atau Apple TV Remote* dengan memilih Settings (Pengaturan) > Remote dan Devices > Remote (Perangkat > Remote).",
    "isAlreadyTranslated": true
  },
  "@Check the bottom of your Apple TV for the reflective serial number @ Since the serial number on the bottom of your device is reflective, use a light to read the number.": {
    "text": "@Periksa bagian bawah Apple TV untuk melihat nomor seri reflektif @ Karena nomor seri di bagian bawah perangkat Anda mirip cermin, gunakan lampu untuk membaca nomor ini.",
    "isAlreadyTranslated": true
  },
  "@Check the box for the serial number@ If you have your device's original box, you can see the serial number on the barcode.": {
    "text": "@Periksa kotak untuk melihat nomor seri@ Jika memiliki kotak asli perangkat, Anda dapat melihat nomor seri pada barkode.",
    "isAlreadyTranslated": true
  },
  "Oh no! Tell us what went wrong": {
    "text": "Waduh! Beri tahu kesalahan yang terjadi",
    "isAlreadyTranslated": true
  },
  "The ease of using the app": {
    "text": "Kemudahan menggunakan aplikasi",
    "isAlreadyTranslated": true
  },
  "Speed & Efficiency": {
    "text": "Kecepatan & Efisiensi",
    "isAlreadyTranslated": true
  },
  "Overall Service": {
    "text": "Seluruh Layanan",
    "isAlreadyTranslated": true
  },
  "Oops! Please tell us what can be improved": {
    "text": "Ups! Beri tahu apa yang dapat ditingkatkan",
    "isAlreadyTranslated": true
  },
  "Please tell us what can be improved": {
    "text": "Beri tahu apa yang dapat ditingkatkan",
    "isAlreadyTranslated": true
  },
  "What did you like about us?": {
    "text": "Apa yang Anda sukai tentang kami?",
    "isAlreadyTranslated": true
  },
  "Great! What did you like about us?": {
    "text": "Bagus! Apa yang Anda sukai tentang kami?",
    "isAlreadyTranslated": true
  },
  "Excellent": {
    "text": "Sangat bagus",
    "isAlreadyTranslated": true
  },
  "Good": {
    "text": "Bagus",
    "isAlreadyTranslated": true
  },
  "Bad": {
    "text": "Buruk",
    "isAlreadyTranslated": true
  },
  "Terrible": {
    "text": "Sangat buruk",
    "isAlreadyTranslated": true
  },
  "Pickup & Delivery Service": {
    "text": "Layanan Antar Jemput",
    "isAlreadyTranslated": true
  },
  "Repair quality": {
    "text": "Kualitas perbaikan",
    "isAlreadyTranslated": true
  },
  "Transparency": {
    "text": "Transparansi",
    "isAlreadyTranslated": true
  },
  "Service Partner hospitality": {
    "text": "Keramahan Mitra Layanan",
    "isAlreadyTranslated": true
  },
  "Technician": {
    "text": "Teknisi",
    "isAlreadyTranslated": true
  },
  "Convenience of discarding device": {
    "text": "Kemudahan membuang perangkat",
    "isAlreadyTranslated": true
  },
  "Install Specialist": {
    "text": "Spesialis Instalasi",
    "isAlreadyTranslated": true
  },
  "Demo Specialist": {
    "text": "Spesialis Demo",
    "isAlreadyTranslated": true
  },
  "Maintenance quality": {
    "text": "Kualitas perbaikan",
    "isAlreadyTranslated": true
  },
  "Time Flexibility": {
    "text": "Waktu fleksibel",
    "isAlreadyTranslated": true
  },
  "Convenience of submitting an iPhone": {
    "text": "Kemudahan mengirim iPhone",
    "isAlreadyTranslated": true
  },
  "Came Late": {
    "text": "Datang Telat",
    "isAlreadyTranslated": true
  },
  "Inexperienced": {
    "text": "Tidak berpengalaman",
    "isAlreadyTranslated": true
  },
  "Unprofessional": {
    "text": "Tidak profesional",
    "isAlreadyTranslated": true
  },
  "Expensive": {
    "text": "Mahal",
    "isAlreadyTranslated": true
  },
  "Bad Service": {
    "text": "Pelayana Buruk",
    "isAlreadyTranslated": true
  },
  "Very Far": {
    "text": "Terlalu jauh",
    "isAlreadyTranslated": true
  },
  "I confirm that I have removed the SIM Card(s), have taken necessary data backup and removed any security passcode/pattern from this phone. I further declare that Servify will not be responsible for any loss or delay in repair due to misrepresentation of above communication.": {
    "text": "Saya meyakinkan bahwa saya telah mengeluarkan Kartu SIM, mencadangkan data yang diperlukan dan menghapus sandi/pola keamanan dari ponsel ini. Selanjutnya saya menyatakan bahwa Servify tidak akan bertanggung jawab atas kehilangan atau keterlambatan dalam perbaikan karena kesalahan memahami yang di atas.",
    "isAlreadyTranslated": true
  },
  "Remove security passcode/pattern (Mandatory)": {
    "text": "Menghilangkan Kata sandi / pola",
    "isAlreadyTranslated": true
  },
  "Doing so helps our Service Partner quickly start with service of your phone without any hassles.": {
    "text": "Jika dilakukan, akan membantu Mitra Servis kami memulai servis ponsel Anda dengan cepat.",
    "isAlreadyTranslated": true
  },
  "Remove SIM card from your Phone": {
    "text": "Keluarkan simcard dari perangkat",
    "isAlreadyTranslated": true
  },
  "This helps prevent any unauthorized use of your SIM Card and data.": {
    "text": "Ini membantu mencegah penggunaan simcard dan data Anda secara tidak sah.",
    "isAlreadyTranslated": true
  },
  "Backup your Phone (recommended)": {
    "text": "Back up perangkat anda ( rekomendasi )",
    "isAlreadyTranslated": true
  },
  "Certain software related repairs might lead to loss of your data. Backing up data ensures you don't lose any valuable data.": {
    "text": "Perbaikan Software akan kehilangan data. Pastikan Anda sudah memback up data untuk mengantisipasi kehliangan data berharga",
    "isAlreadyTranslated": true
  },
  "I confirm that I have removed the SIM card, have taken necessary data backup and turned off 'Find My iPhone' from this iPhone. I further declare that Servify will not be responsible for any losses or delay in repair due to misrepresentation of above confirmation.": {
    "text": "Saya meyakinkan bahwa saya telah melepas kartu SIM, mencadangan data yang diperlukan, dan mematikan 'Find My iPhone' dari iPhone ini. Selanjutnya saya menyatakan bahwa Servify tidak akan bertanggung jawab atas kehilangan atau keterlambatan dalam perbaikan karena kesalahan memahami konfirmasi yang di atas.",
    "isAlreadyTranslated": true
  },
  "Turn Off Find My iPhone": {
    "text": "Matikan Find My iPhone",
    "isAlreadyTranslated": true
  },
  "Start at your Home screen.": {
    "text": "Mulai dari Layar Depan.",
    "isAlreadyTranslated": true
  },
  "Tap Settings > iCloud.": {
    "text": "Ketuk Settings (Pengaturan) > iCloud.",
    "isAlreadyTranslated": true
  },
  "Scroll to the bottom and tap Find My iPhone.": {
    "text": "Gulir ke bagian terbawah lalu ketuk Find My iPhone.",
    "isAlreadyTranslated": true
  },
  "Slide to turn off Find My iPhone and Send Last Location.": {
    "text": "Usap untuk mematikan Find My iPhone dan mengirimkan Lokasi Terakhir.",
    "isAlreadyTranslated": true
  },
  "If you're asked to sign in, enter your Apple ID.": {
    "text": "Jika Anda diminta untuk login, tulis Apple ID Anda.",
    "isAlreadyTranslated": true
  },
  "Remove SIM Card From Your Device": {
    "text": "Lepas Kartu SIM dari Perangkat Anda",
    "isAlreadyTranslated": true
  },
  "After you remove or transfer your SIM card insert the tray completely and in the same orientation that you removed it (it will fit only one way).": {
    "text": "Setelah melepas atau memindahkan kartu SIM, masukkan laci seluruhnya dengan orientasi yang sama dengan ketika Anda melepasnya (hanya bisa masuk dengan satu cara).",
    "isAlreadyTranslated": true
  },
  "Backup Your iOS Device (Recommended)": {
    "text": "Cadangkan Perangkat iOS Anda (Disarankan)",
    "isAlreadyTranslated": true
  },
  "Before you get started, learn the difference between making backups with iCloud and iTunes and choose the method that's best for you. In case you ever need an alternative backup, you can make one in iCloud and another in iTunes.": {
    "text": "Sebelum memulai, ketahui perbedaan antara mencadangkan iCloud dengan iTunes, lalu pilih metode yang paling cocok dengan Anda. Jika Anda memerlukan pencadangan alternatif, Anda dapat membuatnya di iCloud dan satu lagi di iTunes.",
    "isAlreadyTranslated": true
  },
  "Call": {
    "text": "Menghubungi",
    "isAlreadyTranslated": true
  },
  "Connect on Facebook": {
    "text": "Sambungkan ke Facebook",
    "isAlreadyTranslated": true
  },
  "Connect on Twitter": {
    "text": "Sambungkan ke Twitter",
    "isAlreadyTranslated": true
  },
  "Connect on LinkedIn": {
    "text": "Sambungkan ke LinkedIn",
    "isAlreadyTranslated": true
  },
  "Terms & Conditions": {
    "text": "Syarat & Ketentuan",
    "isAlreadyTranslated": true
  },
  "Privacy Policy": {
    "text": "Privacy Policy",
    "isAlreadyTranslated": true
  },
  "Claim Form": {
    "text": "Form Klaim",
    "isAlreadyTranslated": true
  },
  "Go to @Settings > General > About@ and look for your device's serial number, IMEI/MEID, and ICCID. If you'd like to paste this information into Apple registration or support forms, tap and hold on the number and copy it.": {
    "text": "Buka @Settings (Pengaturan) > General (Umum) > About (Tentang)@ kemudian cari nomor seri, IMEI/MEID, dan ICCID perangkat. Jika ingin menempelkan informasi ini ke formulir pendaftaran atau dukungan Apple, tekan terus lalu salin nomor.",
    "isAlreadyTranslated": true
  },
  "Servify is India's largest device management and proactive ownership platform. Starting as an app based customer support service for consumer electronics, today we have created our own end-to-end device management platform.": {
    "text": "Servify adalah platform manajemen perangkat dan kepemilikan proaktif terbesar di India. Mengawali bisnis sebagai layanan pelanggan berbasis aplikasi bagi perangkat elektronik pelanggan, hari ini kami membuat platform manajemen perangkat end-to-end sendiri.",
    "isAlreadyTranslated": true
  },
  "We enable top OEMs & ODMs in device distribution, sales, warranty, after-sales and end of life management through our device management platform.": {
    "text": "Kami membantu OEM & ODM ternama dalam manajemen distribusi, penjualan, garansi, purnajual, dan akhir masa pakai perangkat di melalui platform manajemen perangkat kami.",
    "isAlreadyTranslated": true
  },
  "We have also developed best in class smart device diagnostics for smartphones and have built an end to end service ecosystem including our own service factory and field logistics force": {
    "text": "Kami juga yang terbaik dalam mengembangkan perangkat diagnostik cerdas untuk smartphone, juga membangun ekosistem layanan end-to-end yang mencakup dalam pabrik servis dan tenaga logistik lapangan kami",
    "isAlreadyTranslated": true
  },
  "Our consumer offerings include one touch service app and a smart ownership platform. It also has Damage Protection and Extended Warranties for smart devices": {
    "text": "Persembahan kami kepada pelanggan mencakup aplikasi layanan sekali sentuh dan platform kepemilikan cerdas. Kami juga menyertakan Jaminan Kerusakan dan Garansi yang Diperluas bagi perangkat cerdas Anda",
    "isAlreadyTranslated": true
  },
  "While some of our technology works behind the scenes to manage your devices, we hope to engage with you directly and make your lives better.": {
    "text": "Meskipun sebagian teknologi kami bekerja di balik layar untuk mengelola perangkat Anda, kami berharap untuk terhubung dengan Anda secara langsung dan membuat kehidupan Anda lebih baik.",
    "isAlreadyTranslated": true
  },
  "We love devices and we want to help you get the best out of them. Every device has a lifespan and today that life span is getting shorter. Most electronics that are improperly thrown away contain harmful materials like beryllium, cadmium, mercury and lead in some form or another. These materials might be present in traces in individual products, but when added up in volume, the threat to the environment is significant.": {
    "text": "Kami menyukai perangkat elektronik dan ingin membantu Anda memakainya dengan lebih baik. Setiap perangkat memiliki masa pakai, dan zaman sekarang ini masa pakai semakin memendek. Sebagian besar elektronik yang dibuang dengan cara yang salah mengandung bahan berbahaya seperti berilium, kadmium, merkuri dan timbal dalam berbagai bentuk. Bahan-bahan ini dapat tersisa sedikit di produk satuan, tapi jika menumpuk, ancaman terhadap lingkungan akan besar.",
    "isAlreadyTranslated": true
  },
  "We want your help to recycle all End of Life (EOL) devices with ease. That is why we are introducing pickup and dropoff options of your EOL device. These devices are recycled by only certified recycling partners.": {
    "text": "Kami ingin Anda mendaur ulang semua perangkat yang sudah mencapai Akhir Masa Pakai (AMP) dengan benar. Inilah alasan kami memperkenalkan pilihan antar-jemput perangkat AMP Anda. Perangkat-perangkat ini hanya bisa didaur ulang oleh mitra daur ulang resmi.",
    "isAlreadyTranslated": true
  },
  "We enable top OEMs & ODMs in device distribution, sales, warranty, after-sales and end of life management through our device management platform. We have also developed best in class smart device diagnostics for smartphones and have built an end to end service ecosystem including our own service factory and field logistics force.": {
    "text": "Kami membantu OEM & ODM ternama dalam manajemen distribusi, penjualan, garansi, purnajual, dan akhir masa pakai perangkat di melalui platform manajemen perangkat kami. Kami juga yang terbaik dalam mengembangkan perangkat diagnostik cerdas untuk smartphone, juga membangun ekosistem layanan end-to-end yang mencakup dalam pabrik servis dan tenaga logistik lapangan kami.",
    "isAlreadyTranslated": true
  },
  "Our consumer offerings include one touch service app and a smart ownership platform. It also has Damage Protection and Extended Warranties for smart devices.": {
    "text": "Persembahan kami kepada pelanggan mencakup aplikasi layanan sekali sentuh dan platform kepemilikan cerdas. Kami juga menyertakan Jaminan Kerusakan dan Garansi yang Diperluas bagi perangkat cerdas Anda.",
    "isAlreadyTranslated": true
  },
  "While some of our technology works behind the scenes to manage your devices, we hope to engage with you directly through this recycle initiative. Let's dispose our devices responsibly and make this planet a better place.": {
    "text": "Meskipun sebagian teknologi kami bekerja di balik layar untuk mengelola perangkat Anda, kami berharap akan berhubungan secara langsung dengan Anda melalui prakarsa daur ulang ini. Mari membuang peralatan elektronik dengan benar dan membuat bumi jadi lebih indah.",
    "isAlreadyTranslated": true
  },
  "BatteryEvent": {
    "text": "KejadianBaterai",
    "isAlreadyTranslated": true
  },
  "Hardware": {
    "text": "Hardware",
    "isAlreadyTranslated": true
  },
  "Battery": {
    "text": "Batertai",
    "isAlreadyTranslated": true
  },
  "Battery State": {
    "text": "Kondisi Baterai",
    "isAlreadyTranslated": true
  },
  "Capacity": {
    "text": "Kapasitas",
    "isAlreadyTranslated": true
  },
  "Health": {
    "text": "Sehat",
    "isAlreadyTranslated": true
  },
  "Level": {
    "text": "Level",
    "isAlreadyTranslated": true
  },
  "Temperature": {
    "text": "Temperatur",
    "isAlreadyTranslated": true
  },
  "NetworkEvent": {
    "text": "KejadianJaringan",
    "isAlreadyTranslated": true
  },
  "Connectivity": {
    "text": "Konektivitas",
    "isAlreadyTranslated": true
  },
  "Network Info": {
    "text": "Info Jaringan",
    "isAlreadyTranslated": true
  },
  "Operator Name": {
    "text": "Nama Operator",
    "isAlreadyTranslated": true
  },
  "Phone Type": {
    "text": "Jenis HP",
    "isAlreadyTranslated": true
  },
  "Signal Strength": {
    "text": "Sinyal kuat",
    "isAlreadyTranslated": true
  },
  "SimCountry Code": {
    "text": "Kode Negara",
    "isAlreadyTranslated": true
  },
  "State of Service": {
    "text": "Keadaan Servis",
    "isAlreadyTranslated": true
  },
  "SensorEvent": {
    "text": "KejadianSensor",
    "isAlreadyTranslated": true
  },
  "The repair cost of your device will be INR @ but you have to pay only INR @. Please make the payment by @ to begin repair.": {
    "text": "Biaya perbaikannya adalah INR @, tapi Anda hanya perlu membayar INR @. Bayar sebelum @ untuk memulai perbaikan.",
    "isAlreadyTranslated": true
  },
  "Air Pressure": {
    "text": "Tekanan Udara",
    "isAlreadyTranslated": true
  },
  "Call Service": {
    "text": "Panggil Servis",
    "isAlreadyTranslated": true
  },
  "Gyroscope": {
    "text": "Gyroscope",
    "isAlreadyTranslated": true
  },
  "Heart Rate": {
    "text": "Detak Jantung",
    "isAlreadyTranslated": true
  },
  "Humidity": {
    "text": "Kelembaban",
    "isAlreadyTranslated": true
  },
  "Message Service": {
    "text": "Kirimi Pesan Servis",
    "isAlreadyTranslated": true
  },
  "Magnetic Field": {
    "text": "Medan Magnet",
    "isAlreadyTranslated": true
  },
  "IR Sensor": {
    "text": "Sensor IR",
    "isAlreadyTranslated": true
  },
  "Gravity": {
    "text": "Gravitasi",
    "isAlreadyTranslated": true
  },
  "Accelerometer": {
    "text": "Accelerometer",
    "isAlreadyTranslated": true
  },
  "Air Temperature": {
    "text": "Temperatur udara",
    "isAlreadyTranslated": true
  },
  "GPS": {
    "text": "GPS",
    "isAlreadyTranslated": true
  },
  "RAM": {
    "text": "RAM",
    "isAlreadyTranslated": true
  },
  "Available": {
    "text": "Tersedia",
    "isAlreadyTranslated": true
  },
  "GB": {
    "text": "GB",
    "isAlreadyTranslated": true
  },
  "Internal Storage": {
    "text": "Penyimpanan Internal",
    "isAlreadyTranslated": true
  },
  "CPU": {
    "text": "CPU",
    "isAlreadyTranslated": true
  },
  "Minimum Clock": {
    "text": "Clock Minimal",
    "isAlreadyTranslated": true
  },
  "Processors/Cores": {
    "text": "Procesor",
    "isAlreadyTranslated": true
  },
  "Fingerprint": {
    "text": "Sidik Jari",
    "isAlreadyTranslated": true
  },
  "Proximity Sensor": {
    "text": "Proximity Sensor",
    "isAlreadyTranslated": true
  },
  "Speaker": {
    "text": "Speaker",
    "isAlreadyTranslated": true
  },
  "Sound": {
    "text": "Suara",
    "isAlreadyTranslated": true
  },
  "Ports": {
    "text": "Port",
    "isAlreadyTranslated": true
  },
  "Earphones": {
    "text": "Earphones",
    "isAlreadyTranslated": true
  },
  "USB": {
    "text": "USB",
    "isAlreadyTranslated": true
  },
  "Volume Buttons": {
    "text": "Tombol volume",
    "isAlreadyTranslated": true
  },
  "Touch Screen": {
    "text": "Layar Sentuh",
    "isAlreadyTranslated": true
  },
  "Dead or White Spots": {
    "text": "Dead or White Spots",
    "isAlreadyTranslated": true
  },
  "Flashlight": {
    "text": "Flashlight",
    "isAlreadyTranslated": true
  },
  "MAC Address": {
    "text": "MAC Address",
    "isAlreadyTranslated": true
  },
  "Max Wifi Speed": {
    "text": "Maximum kecepatan WIFI",
    "isAlreadyTranslated": true
  },
  "Frequency": {
    "text": "Frekuensi",
    "isAlreadyTranslated": true
  },
  "Wi-Fi Statistics": {
    "text": "Wi-Fi Statistics",
    "isAlreadyTranslated": true
  },
  "Multi Touch": {
    "text": "Multi Touch",
    "isAlreadyTranslated": true
  },
  "Brightness": {
    "text": "Brightness",
    "isAlreadyTranslated": true
  },
  "Earpiece Speaker": {
    "text": "Earpiece Speaker",
    "isAlreadyTranslated": true
  },
  "Phone Info": {
    "text": "Info HP",
    "isAlreadyTranslated": true
  },
  "Android Version": {
    "text": "Versi Android",
    "isAlreadyTranslated": true
  },
  "Air Conditioner": {
    "text": "AC",
    "isAlreadyTranslated": true
  },
  "repair": {
    "text": "Perbaiki",
    "isAlreadyTranslated": true
  },
  "install": {
    "text": "instalasi",
    "isAlreadyTranslated": true
  },
  "service": {
    "text": "Layanan",
    "isAlreadyTranslated": true
  },
  "TV": {
    "text": "TV",
    "isAlreadyTranslated": true
  },
  "AC": {
    "text": "AC",
    "isAlreadyTranslated": true
  },
  "Fridge": {
    "text": "Lemari Es",
    "isAlreadyTranslated": true
  },
  "Washing Machine": {
    "text": "Mesin Cuci",
    "isAlreadyTranslated": true
  },
  "Camera": {
    "text": "Kamera",
    "isAlreadyTranslated": true
  },
  "Computer": {
    "text": "Komputer",
    "isAlreadyTranslated": true
  },
  "Tablet": {
    "text": "Tablet",
    "isAlreadyTranslated": true
  },
  "Microwave": {
    "text": "Microwave",
    "isAlreadyTranslated": true
  },
  "Router": {
    "text": "Ruter",
    "isAlreadyTranslated": true
  },
  "Water Purifier": {
    "text": "Pemurni Air",
    "isAlreadyTranslated": true
  },
  "Air Purifier": {
    "text": "Pemurni Udara",
    "isAlreadyTranslated": true
  },
  "Audio Product": {
    "text": "Produk Audio",
    "isAlreadyTranslated": true
  },
  "Vacuum Cleaner": {
    "text": "Vacuum Cleaner",
    "isAlreadyTranslated": true
  },
  "Juicer Mixer": {
    "text": "Blender Jus",
    "isAlreadyTranslated": true
  },
  "Printer": {
    "text": "Printer",
    "isAlreadyTranslated": true
  },
  "Chest Coole": {
    "text": "Peti Cooler",
    "isAlreadyTranslated": true
  },
  "Streaming Device": {
    "text": "Perangkat Streaming",
    "isAlreadyTranslated": true
  },
  "Chest Freezer": {
    "text": "Peti Freezer",
    "isAlreadyTranslated": true
  },
  "Security Camera": {
    "text": "CCTV Umum",
    "isAlreadyTranslated": true
  },
  "Smart Watch": {
    "text": "Smart Watch",
    "isAlreadyTranslated": true
  },
  "Office CCTV": {
    "text": "CCTV Kantor",
    "isAlreadyTranslated": true
  },
  "Air Cooler": {
    "text": "AC Berdiri",
    "isAlreadyTranslated": true
  },
  "Room Heater": {
    "text": "Penghangat Ruangan",
    "isAlreadyTranslated": true
  },
  "Water Heater": {
    "text": "Pemanas Air",
    "isAlreadyTranslated": true
  },
  "Dry Iron": {
    "text": "Setrika Kering",
    "isAlreadyTranslated": true
  },
  "Kettle": {
    "text": "Ceret",
    "isAlreadyTranslated": true
  },
  "Rice Cooker": {
    "text": "Rice Cooker",
    "isAlreadyTranslated": true
  },
  "Toaster": {
    "text": "Toaster",
    "isAlreadyTranslated": true
  },
  "Dish TV": {
    "text": "Parabola",
    "isAlreadyTranslated": true
  },
  "Ear Pods": {
    "text": "Ear Pod",
    "isAlreadyTranslated": true
  },
  "Power Adapter": {
    "text": "Power Adaptor",
    "isAlreadyTranslated": true
  },
  "Lightning to USB Cable": {
    "text": "Lightning ke Kabel USB",
    "isAlreadyTranslated": true
  },
  "Battery Damaged": {
    "text": "Baterai Rusak",
    "isAlreadyTranslated": true
  },
  "Backup Taken": {
    "text": "Backup dilakukan",
    "isAlreadyTranslated": true
  },
  "Find my iPhone Active": {
    "text": "Find my iPhone Aktif",
    "isAlreadyTranslated": true
  },
  "Apple Limited Warranty": {
    "text": "Garansi Terbatas Apple",
    "isAlreadyTranslated": true
  },
  "Physical Damage": {
    "text": "Rusak Fisik",
    "isAlreadyTranslated": true
  },
  "Power": {
    "text": "Power",
    "isAlreadyTranslated": true
  },
  "System Performance": {
    "text": "Perfoma Sistem",
    "isAlreadyTranslated": true
  },
  "Built in Camera": {
    "text": "Kamera Built In",
    "isAlreadyTranslated": true
  },
  "Display": {
    "text": "Tampilan",
    "isAlreadyTranslated": true
  },
  "Communication/Networking": {
    "text": "Komunikasi / Jaringan",
    "isAlreadyTranslated": true
  },
  "Data Storage": {
    "text": "Penyimpanan Data",
    "isAlreadyTranslated": true
  },
  "@ | Your payment has been received.": {
    "text": "@ | Pembayaran Anda telah diterima.",
    "isAlreadyTranslated": true
  },
  "@ | Your device is repaired. Please visit the service centre to collect.": {
    "text": "@ | Perangkat Anda selesai diperbaiki. Silahkan mengunjungi Service Center untuk pengambilan",
    "isAlreadyTranslated": true
  },
  "@ | Your device is repaired and dispatched.": {
    "text": "@ | Perangkat Anda telah diperbaiki dan sudah dikirim",
    "isAlreadyTranslated": true
  },
  "@ | The repair cost of your device will be INR @ but you have to pay only INR @. Payment by @ to begin repair.": {
    "text": "@ | Biaya perbaikan perangkat Anda adalah Rp @, tapi Anda hanya perlu membayar Rp @. Pembayaran dengan @ untuk memulai perbaikan.",
    "isAlreadyTranslated": true
  },
  "@ | Device has been received by the service centre and is currently being diagnosed.": {
    "text": "@ | Perangkat telah diterima oleh Service Center dan sedang di diagnosa",
    "isAlreadyTranslated": true
  },
  "@ | Document verification was successful. Please visit @ to book the repair": {
    "text": "@ | Verifikasi dokumen telah berhasil. Mohon mengunjungi @ untuk perbaikan",
    "isAlreadyTranslated": true
  },
  "@ | We are awaiting Documents. Please Upload by @ to make sure that your request does not get lapsed. @": {
    "text": "@ | Kami menunggu Dokumen Anda. Mohon upload dengan @ untuk memastikan permintaan Anda tidak berakhir. @",
    "isAlreadyTranslated": true
  },
  "@ | Your Request has been registered. Please check the request for any further documentation required from you.": {
    "text": "@ | Permintaan Anda telah terdaftar. Silahkan mengecek permintaan untuk dokumentasi yang dibutuhkan dari Anda.",
    "isAlreadyTranslated": true
  },
  "@ Action needs to be taken by Store for @ part request(s).": {
    "text": "@ Tindakan harus diambil oleh Store untuk @ permintaan part",
    "isAlreadyTranslated": true
  },
  "@ Part(s) Ordered by Store.": {
    "text": "@ Part dipesan oleh Store.",
    "isAlreadyTranslated": true
  },
  "@ Part(s) Issued by Store.": {
    "text": "@ Part Dikeluarkan oleh Store.",
    "isAlreadyTranslated": true
  },
  "All parts received, repair is in progress.": {
    "text": "Semua part diterima, perbaikan dalam proses",
    "isAlreadyTranslated": true
  },
  "Customer Care Executive will choose action for the device from Dead on Arrival section below.": {
    "text": "Customer Service akan memilih tindakan untuk perangkat untuk bagian Dead on Arrival",
    "isAlreadyTranslated": true
  },
  "Device repair has failed QC. Device is to be given back to the Engineer.": {
    "text": "Perbaikan perangkat gagal KK. Perangkat akan dikembalikan kepada Teknisi.",
    "isAlreadyTranslated": true
  },
  "Device Whitelisting Information": {
    "text": "Informasi Daftar Putih Perangkat",
    "isAlreadyTranslated": true
  },
  "Eligible for DOA": {
    "text": "Layak untuk MST",
    "isAlreadyTranslated": true
  },
  "Engineer will select the new device from Dead on Arrival section below": {
    "text": "Teknisi akan memilih perangkat baru dari bagian Mati saat tiba di bawah ini",
    "isAlreadyTranslated": true
  },
  "Estimation has been rejected, parts may be added if required by the engineer.": {
    "text": "Estimasi ditolak, suku cadang dapat ditambahkan apabila diminta oleh teknisi.",
    "isAlreadyTranslated": true
  },
  "Engineer completed the repair. Handed over the device to QC.": {
    "text": "Teknisi telah menyelesaikan perbaikan. Menyerahkan perangkat ke KK.",
    "isAlreadyTranslated": true
  },
  "Engineer completed the repair.": {
    "text": "Teknisi telah menyelesaikan perbaikan.",
    "isAlreadyTranslated": true
  },
  "Last Repair Date": {
    "text": "Tanggal Perbaikan Terakhir",
    "isAlreadyTranslated": true
  },
  "No, the device is not whitelistied. Please return the device if you have it": {
    "text": "Tidak, perangkat ini tidak masuk daftar putih. Kembalikan perangkat bila Anda memilikinya",
    "isAlreadyTranslated": true
  },
  "No subcategories found": {
    "text": "Tidak ada subkategori",
    "isAlreadyTranslated": true
  },
  "No supported modes found": {
    "text": "Tidak ada mode yang didukung",
    "isAlreadyTranslated": true
  },
  "No supported mode found": {
    "text": "Tidak ada mode yang didukung",
    "isAlreadyTranslated": true
  },
  "Payment for the service has been received from the customer.": {
    "text": "Pembayaran servis telah diterima dari pelanggan.",
    "isAlreadyTranslated": true
  },
  "Please complete the service.": {
    "text": "Mohon selesaikan servis.",
    "isAlreadyTranslated": true
  },
  "Product Unique ID": {
    "text": "ID Unik Produk",
    "isAlreadyTranslated": true
  },
  "Pickup Drop By": {
    "text": "Pickup Jatuhkan Dengan",
    "isAlreadyTranslated": true
  },
  "Repair has been cancelled.": {
    "text": "Perbaikan telah dibatalkan.",
    "isAlreadyTranslated": true
  },
  "Request Has Been Raised": {
    "text": "Permintaan telah Disampaikan",
    "isAlreadyTranslated": true
  },
  "Requires customer to print documents and safely pack the device.": {
    "text": "Pengguna harus mencetak dokumen dan mengemas perangkat dengan aman.",
    "isAlreadyTranslated": true
  },
  "Raise new request": {
    "text": "Ajukan permintaan baru",
    "isAlreadyTranslated": true
  },
  "Service invoice has been generated, final payment can be received.": {
    "text": "Faktur servis telah dibuat, pembayaran akhir dapat diterima.",
    "isAlreadyTranslated": true
  },
  "Serial Number/IMEI is mandatory for Apple products. Please enter valid Serial Number/IMEI.": {
    "text": "Nomor Seri/IMEI wajib diisi untuk produk Apple. Masukkan Nomor Seri/IMEI yang benar",
    "isAlreadyTranslated": true
  },
  "Slot Time": {
    "text": "Waktu Slot",
    "isAlreadyTranslated": true
  },
  "Schedule Date": {
    "text": "Tanggal Permintaan",
    "isAlreadyTranslated": true
  },
  "Send for raising a request?": {
    "text": "Kirim untuk mengajukan permintaan?",
    "isAlreadyTranslated": true
  },
  "The serial number and the selected product do not match.": {
    "text": "Nomor seri dan produk yang dipilih tidak sama.",
    "isAlreadyTranslated": true
  },
  "There's a problem with this address, please select or add a different adress": {
    "text": "Ada masalah dengan alamat ini, pilih atau tambah alamat lainnya",
    "isAlreadyTranslated": true
  },
  "There's a problem with this landmark, please select a different landmark": {
    "text": "Ada masalah dengan lokasi ini, pilih atau tambah lokasi lainnya",
    "isAlreadyTranslated": true
  },
  "The request was raised successfully": {
    "text": "Permintaan berhasil diajukan",
    "isAlreadyTranslated": true
  },
  "The entered Srial/IMEI Number is not whitelisted. Please check the number once again.": {
    "text": "Nomor Seri/IMEI yang dimasukkan adalah terlarang. Periksa nomornya sekali lagi.",
    "isAlreadyTranslated": true
  },
  "Waiting for the Customer Care Executive to receive device and complete mandatory checklist tasks": {
    "text": "Menunggu Eksekutif Layanan Pelanggan menerima perangkat dan menyelesaikan tugas wajib dalam daftar centang",
    "isAlreadyTranslated": true
  },
  "Waiting for engineer to accept the job.": {
    "text": "Menunggu Teknisi terima perbaikan",
    "isAlreadyTranslated": true
  },
  "Work in Progress with Engineer.": {
    "text": "Pekerjaan sedang dilakukan oleh Teknisi.",
    "isAlreadyTranslated": true
  },
  "Waiting for the Engineer to raise the request.": {
    "text": "Menunggu Teknisi mengajukan permintaan.",
    "isAlreadyTranslated": true
  },
  "Waiting for Estimation Approval. Click on \"Send for approval\" in \"Parts and Estimation\" section below.": {
    "text": "Menunggu Estimasi Persetujuan. Klik pada \"Kirim untuk persetujuan\" di \"Bagian dan Estimasi\" di bawah.",
    "isAlreadyTranslated": true
  },
  "Waiting for customer approval of estimation or override estimation approval in \"Parts and Estimation\" section below.": {
    "text": "Menunggu persetujuan pelanggan estimasi atau menimpa persetujuan estimasi di \"Bagian dan Estimasi\" di bawah.",
    "isAlreadyTranslated": true
  },
  "Waiting for store to issue required parts in \"Parts Requested\" section below. Cannot complete repair with parts pending.": {
    "text": "Menunggu toko untuk mengeluarkan bagian yang diperlukan di \"Bagian Diminta\" di bawah. tidak dapat menyelesaikan perbaikan dengan bagian-bagian yang tertunda.",
    "isAlreadyTranslated": true
  },
  "Waiting for store to issue required device in \"Dead on arrival\" section below.": {
    "text": "Menunggu toko untuk mengeluarkan perangkat yang diperlukan dalam \"Mati pada saat kedatangan\" di bawah.",
    "isAlreadyTranslated": true
  },
  "Waiting for the Engineer to receive the \"Replacement device\" from store in \"Dead on Arrival\" section below.": {
    "text": "Menunggu Engineer untuk menerima \"perangkat Penggantian\" dari toko di \"Dead on Arrival\" di bawah.",
    "isAlreadyTranslated": true
  },
  "Waiting for Engineer to receive parts from store in \"Parts Requested\" section below.": {
    "text": "Menunggu Engineer untuk menerima bagian dari toko di \"Bagian Diminta\" di bawah.",
    "isAlreadyTranslated": true
  },
  "Waiting for the Engineer to complete DOA by handing over the damaged device to Store": {
    "text": "Menunggu Teknisi untuk menyelesaikan DOA dengan menyerahkan perangkat rusak ke Store",
    "isAlreadyTranslated": true
  },
  "Waiting for the engineer to handover device to Store": {
    "text": "Menunggu Teknisi menyerahkan perangkat ke Store",
    "isAlreadyTranslated": true
  },
  "Waiting for the Store to handover device to Customer Care Executive": {
    "text": "Menunggu Store menyerahkan perangkat ke Customer Service",
    "isAlreadyTranslated": true
  },
  "Waiting for the Customer Care Executive to complete the DOA": {
    "text": "Menunggu Customer Service menyelesaikan DOA",
    "isAlreadyTranslated": true
  },
  "Waiting for the customer to rate the service": {
    "text": "Menunggu pelanggan menilai layanan",
    "isAlreadyTranslated": true
  },
  "Warranty Till": {
    "text": "Garansi Sampai",
    "isAlreadyTranslated": true
  },
  "We were unable to find details from GSX, please select product manually": {
    "text": "Kami tidak dapat menemukan rincian dari GSX, pilih produk secara manual",
    "isAlreadyTranslated": true
  },
  "Yes, the device is whitelistied. Please upload screenshot of the same": {
    "text": "Ya, perangkat ini tercantum dalam daftar putih. Unggah screenshot tentang masalah ini",
    "isAlreadyTranslated": true
  },
  "This fault code has already been selected": {
    "text": "Kode kerusakan sudah dipilih",
    "isAlreadyTranslated": true
  },
  "Choose from dropdown list only": {
    "text": "Pilih dari daftar rentang-turun saja",
    "isAlreadyTranslated": true
  },
  "You have already added this issue": {
    "text": "Anda telah menambahkan masalah ini",
    "isAlreadyTranslated": true
  },
  "Please validate all the issues": {
    "text": "Mohon validasi semua masalah",
    "isAlreadyTranslated": true
  },
  "Initiate AST 2 Diagnostics": {
    "text": "Jalankan Diagnostik AST 2",
    "isAlreadyTranslated": true
  },
  "Forward": {
    "text": "Teruskan",
    "isAlreadyTranslated": true
  },
  "Reverse": {
    "text": "Balik",
    "isAlreadyTranslated": true
  },
  "Schedule a Logistic Request": {
    "text": "Jadwalkan Permintaan Logistik",
    "isAlreadyTranslated": true
  },
  "Transfer Details": {
    "text": "Rincian Transfer",
    "isAlreadyTranslated": true
  },
  "Cash": {
    "text": "Tunai",
    "isAlreadyTranslated": true
  },
  "Card": {
    "text": "Kartu",
    "isAlreadyTranslated": true
  },
  "Mode of payment": {
    "text": "Metode pembayaran",
    "isAlreadyTranslated": true
  },
  "Please select mode of payment": {
    "text": "Pilih metode pembayaran",
    "isAlreadyTranslated": true
  },
  "Device Transaction Details": {
    "text": "Rincian Transaksi Perangkat",
    "isAlreadyTranslated": true
  },
  "Handing over the damaged device to store": {
    "text": "Menyerahkan perangkat rusak ke Store",
    "isAlreadyTranslated": true
  },
  "Damaged IMEI": {
    "text": "IMEI rusak",
    "isAlreadyTranslated": true
  },
  "Details of the New Replaced Device": {
    "text": "Rincian Perangkat yang Baru Diganti",
    "isAlreadyTranslated": true
  },
  "Damaged Serial No": {
    "text": "Nomor Serial Rusak",
    "isAlreadyTranslated": true
  },
  "Replaced Serial No": {
    "text": "Nomor Serial pengganti",
    "isAlreadyTranslated": true
  },
  "Damaged Part Imei": {
    "text": "IMEI part rusak",
    "isAlreadyTranslated": true
  },
  "Replaced Part Imei": {
    "text": "IMEI part pengganti",
    "isAlreadyTranslated": true
  },
  "Parts transaction details": {
    "text": "Rincian transaksi part",
    "isAlreadyTranslated": true
  },
  "Handing over the parts to store": {
    "text": "Menyerahkan Part ke Store",
    "isAlreadyTranslated": true
  },
  "Enter Call Details": {
    "text": "Buka Rincian Panggilan",
    "isAlreadyTranslated": true
  },
  "Connect": {
    "text": "Menyambungkan",
    "isAlreadyTranslated": true
  },
  "Name of the caller": {
    "text": "Nama pemanggil",
    "isAlreadyTranslated": true
  },
  "Connect Disposition": {
    "text": "Disposisi Koneksi",
    "isAlreadyTranslated": true
  },
  "Non-Connect Disposition": {
    "text": "Disposisi Non-Koneksi",
    "isAlreadyTranslated": true
  },
  "Description of loss/damage": {
    "text": "Deskripsi kehilangan/kerusakan",
    "isAlreadyTranslated": true
  },
  "Date and Time of loss/damage": {
    "text": "Tanggal dan Waktu kehilangan/kerusakan",
    "isAlreadyTranslated": true
  },
  "Checklist": {
    "text": "Checklist",
    "isAlreadyTranslated": true
  },
  "Please select Repair Type": {
    "text": "Pilih Jenis Perbaikan",
    "isAlreadyTranslated": true
  },
  "Please select Classification Type": {
    "text": "Pilih Jenis Golongan",
    "isAlreadyTranslated": true
  },
  "Please select Coverage Option": {
    "text": "Pilih Opsi Jaminan",
    "isAlreadyTranslated": true
  },
  "Repair Checklist": {
    "text": "Checklist Perbaikan",
    "isAlreadyTranslated": true
  },
  "Diagnostics Result": {
    "text": "Hasil Diagnostik",
    "isAlreadyTranslated": true
  },
  "Not Applicable": {
    "text": "Tidak Berlaku",
    "isAlreadyTranslated": true
  },
  "Miscellaneous Checklist": {
    "text": "Checklist Lain-lain",
    "isAlreadyTranslated": true
  },
  "Accessories Submitted": {
    "text": "Aksesori yang Dikirim",
    "isAlreadyTranslated": true
  },
  "Complete Checklist": {
    "text": "Checklist Pengecekan",
    "isAlreadyTranslated": true
  },
  "No pictures added": {
    "text": "Tidak ada gambar yang ditambahkan",
    "isAlreadyTranslated": true
  },
  "Upload Device Pictures and Complete Checklist": {
    "text": "Upload Foto Perangkat dan Checklist Pengecekan",
    "isAlreadyTranslated": true
  },
  "Upload Device Pictures": {
    "text": "Upload Foto Perangkat ",
    "isAlreadyTranslated": true
  },
  "Device Name": {
    "text": "Nama Perangkat",
    "isAlreadyTranslated": true
  },
  "Select OS Version": {
    "text": "Pilih Versi OS",
    "isAlreadyTranslated": true
  },
  "Select Picture Type": {
    "text": "Pilih Jenis Gambar",
    "isAlreadyTranslated": true
  },
  "No, the device is not whitelisted. Please cancel the request and return the device if you have it": {
    "text": "Tidak, perangkat ini tidak masuk daftar putih. Batalkan permintaan dan kembalikan perangkat bila Anda memilikinya",
    "isAlreadyTranslated": true
  },
  "Yes, the device is whitelisted. Please upload screenshot of the same": {
    "text": "Ya, perangkat ini tercantum dalam daftar putih. Unggah screenshot tentang masalah ini",
    "isAlreadyTranslated": true
  },
  "Device handover details": {
    "text": "Rincian penyerahan perangkat",
    "isAlreadyTranslated": true
  },
  "Diagnosis details": {
    "text": "Rincian diagnosis",
    "isAlreadyTranslated": true
  },
  "Diagnosis Steps": {
    "text": "Langkah-langkah Diagnosis",
    "isAlreadyTranslated": true
  },
  "Engineer notes": {
    "text": "Catatan teknisi",
    "isAlreadyTranslated": true
  },
  "Front Image": {
    "text": "Tampak depan",
    "isAlreadyTranslated": true
  },
  "Back Image": {
    "text": "Tampak belakang",
    "isAlreadyTranslated": true
  },
  "Top Image": {
    "text": "Tampak atas",
    "isAlreadyTranslated": true
  },
  "Bottom Image": {
    "text": "Tampak bawah",
    "isAlreadyTranslated": true
  },
  "Left Image": {
    "text": "Tampak kiri",
    "isAlreadyTranslated": true
  },
  "Right Image": {
    "text": "Tampak kanan",
    "isAlreadyTranslated": true
  },
  "Customer ID": {
    "text": "ID Pelanggan",
    "isAlreadyTranslated": true
  },
  "Device Invoice": {
    "text": "Tagihan Perangkat",
    "isAlreadyTranslated": true
  },
  "Please select a repair type": {
    "text": "Pilih jenis perbaikan",
    "isAlreadyTranslated": true
  },
  "Please enter": {
    "text": "masukkan",
    "isAlreadyTranslated": true
  },
  "Your GSX Request has been created": {
    "text": "Permintaan GSX Anda telah dibuat",
    "isAlreadyTranslated": true
  },
  "Recycle Dropoff": {
    "text": "Antar Daur Ulang",
    "isAlreadyTranslated": true
  },
  "ELS": {
    "text": "ELS",
    "isAlreadyTranslated": true
  },
  "QC": {
    "text": "KK",
    "isAlreadyTranslated": true
  },
  "DOA": {
    "text": "DOA",
    "isAlreadyTranslated": true
  },
  "Inspection Completed, Awaiting Engineer Assignment": {
    "text": "Pemeriksaan Selesai, Menunggu Penugasan Teknisi",
    "isAlreadyTranslated": true
  },
  "sdv": {
    "text": "sdv",
    "isAlreadyTranslated": true
  },
  "BTB socket": {
    "text": "Soket BTB",
    "isAlreadyTranslated": true
  },
  "SIM Card needle": {
    "text": "Jarum kartu SIM",
    "isAlreadyTranslated": true
  },
  "SIM card holder EB101 grey aluminum alloy": {
    "text": "Wadah kartu SIM aloi aluminium abu-abu EB101",
    "isAlreadyTranslated": true
  },
  "Pending for Acceptance by Engineer": {
    "text": "Menunggu Diterima oleh Teknisi",
    "isAlreadyTranslated": true
  },
  "for Token generated": {
    "text": "untuk yang dihasilkan Token",
    "isAlreadyTranslated": true
  },
  "pin added": {
    "text": "pin ditambahkan",
    "isAlreadyTranslated": true
  },
  "Trade In Dropoff": {
    "text": "Antar Tukar Tambah",
    "isAlreadyTranslated": true
  },
  "Your GSX Mail-in Request has been created. Request Added to Queue for dispatch": {
    "text": "Permintaan Pengiriman GSX Anda telah dibuat. Minta Ditambahkan ke Antrean untuk mengirimkan",
    "isAlreadyTranslated": true
  },
  "This device is not eligible for": {
    "text": "Perangkat ini tidak memenuhi syarat untuk",
    "isAlreadyTranslated": true
  },
  "This device is eligible for": {
    "text": "Perangkat ini memenuhi syarat untuk",
    "isAlreadyTranslated": true
  },
  "Advance payment": {
    "text": "Pembayaran di muka",
    "isAlreadyTranslated": true
  },
  "Cannot be diagnosed": {
    "text": "Tidak bisa didiagnosis",
    "isAlreadyTranslated": true
  },
  "QC pending": {
    "text": "KK tertunda",
    "isAlreadyTranslated": true
  },
  "Transfer request": {
    "text": "Permintaan transfer",
    "isAlreadyTranslated": true
  },
  "Device details validated": {
    "text": "Rincian perangkat divalidasi",
    "isAlreadyTranslated": true
  },
  "Fetch Diagnosis Suites": {
    "text": "Ambil Suite Diagnosis",
    "isAlreadyTranslated": true
  },
  "Do you want to return the damaged parts to the customer": {
    "text": "Kembalikan suku cadang yang rusak ke pelanggan?",
    "isAlreadyTranslated": true
  },
  "Please enter the reason for call": {
    "text": "Tulis alasan panggilan",
    "isAlreadyTranslated": true
  },
  "Please enter Disposition reason": {
    "text": "Tulis alasan Disposisi",
    "isAlreadyTranslated": true
  },
  "Please enter your name": {
    "text": "Tulis nama Anda",
    "isAlreadyTranslated": true
  },
  "Please specify remarks, if any": {
    "text": "Tulis keterangan, jika ada",
    "isAlreadyTranslated": true
  },
  "Return without repair (RWR)": {
    "text": "Kembalikan tanpa diperbaiki (KTD)",
    "isAlreadyTranslated": true
  },
  "Cannot proceed with this action without providing a reason": {
    "text": "Tidak bisa melanjutkan dengan tindakan ini tanpa memberi alasan",
    "isAlreadyTranslated": true
  },
  "This is a mandatory field": {
    "text": "Kolom ini wajib diisi",
    "isAlreadyTranslated": true
  },
  "Diagnosis steps has to be from 100 to 1500 characters": {
    "text": "Langkah-langkah Diagnosis harus berisi 100 sampai 1500 karakter",
    "isAlreadyTranslated": true
  },
  "Engineer Notes has to be from 100 to 800 characters": {
    "text": "Catatan Teknisi harus berisi 100 sampai 800 karakter",
    "isAlreadyTranslated": true
  },
  "Please select a picture type from dropdown": {
    "text": "Pilih jenis gambar dari rentang-turun",
    "isAlreadyTranslated": true
  },
  "Please add a picture": {
    "text": "Tambahkan gambar",
    "isAlreadyTranslated": true
  },
  "You have not chosen any option to handover": {
    "text": "Anda belum memilih opsi manapun untuk penyerahan",
    "isAlreadyTranslated": true
  },
  "Jan": {
    "text": "Jan",
    "isAlreadyTranslated": true
  },
  "Feb": {
    "text": "Feb",
    "isAlreadyTranslated": true
  },
  "Mar": {
    "text": "Mar",
    "isAlreadyTranslated": true
  },
  "Apr": {
    "text": "Apr",
    "isAlreadyTranslated": true
  },
  "May": {
    "text": "Mei",
    "isAlreadyTranslated": true
  },
  "Jun": {
    "text": "Jun",
    "isAlreadyTranslated": true
  },
  "Jul": {
    "text": "Jul",
    "isAlreadyTranslated": true
  },
  "Aug": {
    "text": "Agt",
    "isAlreadyTranslated": true
  },
  "Sep": {
    "text": "Sep",
    "isAlreadyTranslated": true
  },
  "Oct": {
    "text": "Okt",
    "isAlreadyTranslated": true
  },
  "Nov": {
    "text": "Nov",
    "isAlreadyTranslated": true
  },
  "Dec": {
    "text": "Des",
    "isAlreadyTranslated": true
  },
  "AM": {
    "text": "AM",
    "isAlreadyTranslated": true
  },
  "PM": {
    "text": "PM",
    "isAlreadyTranslated": true
  },
  "Pick Up": {
    "text": "Pick Up",
    "isAlreadyTranslated": true
  },
  "Carry in": {
    "text": "Carry In",
    "isAlreadyTranslated": true
  },
  "On-site": {
    "text": "On-Site",
    "isAlreadyTranslated": true
  },
  "Buy Back": {
    "text": "Tukar Tambah",
    "isAlreadyTranslated": true
  },
  "Installation": {
    "text": "Instalasi",
    "isAlreadyTranslated": true
  },
  "Demo": {
    "text": "Demo",
    "isAlreadyTranslated": true
  },
  "Servicing": {
    "text": "Dalam Perbaikan",
    "isAlreadyTranslated": true
  },
  "Delivery": {
    "text": "Pengiriman",
    "isAlreadyTranslated": true
  },
  "Recycle Pickup": {
    "text": "Recycle Pickup",
    "isAlreadyTranslated": true
  },
  "Claim": {
    "text": "Klaim",
    "isAlreadyTranslated": true
  },
  "Claim Pickup": {
    "text": "Klaim Pick",
    "isAlreadyTranslated": true
  },
  "Claim Carryin": {
    "text": "Klaim Carry In",
    "isAlreadyTranslated": true
  },
  "Beyond Economic Repair": {
    "text": "Rusak Total (RT)",
    "isAlreadyTranslated": true
  },
  "BER Refund": {
    "text": "Refund untuk RT",
    "isAlreadyTranslated": true
  },
  "BER Repair": {
    "text": "Perbaikan RT",
    "isAlreadyTranslated": true
  },
  "Dropoff": {
    "text": "Dropoff",
    "isAlreadyTranslated": true
  },
  "Enquiry": {
    "text": "Pertanyaan",
    "isAlreadyTranslated": true
  },
  "Field Repair WO": {
    "text": "Perbaikan DIlokasi WO",
    "isAlreadyTranslated": true
  },
  "Upgrade WO": {
    "text": "Upgrade WO",
    "isAlreadyTranslated": true
  },
  "Install WO": {
    "text": "Instlasi WO",
    "isAlreadyTranslated": true
  },
  "Relocation": {
    "text": "Relokasi",
    "isAlreadyTranslated": true
  },
  "Extended Pick Up": {
    "text": "Extended Pick Up",
    "isAlreadyTranslated": true
  },
  "Extended Carry in": {
    "text": "Extended Carry in",
    "isAlreadyTranslated": true
  },
  "Trade In Pickup": {
    "text": "Trade In Pickup",
    "isAlreadyTranslated": true
  },
  "Your @ request @ is registered. We will contact you for an appointment. For assistance, please call us on 1800-108-1333.": {
    "text": "Permintaan @ Anda @ telah didaftarkan. Kami akan menghubungi Anda untuk membuat janji. Untuk bantuan, silakan hubungi kami di 1800-108-1333.",
    "isAlreadyTranslated": true
  },
  "@ | Your request has lapsed due to non submission of the documents.": {
    "text": "@ | Permintaan Anda gagal karena Anda tidak mengirimkan dokumen.",
    "isAlreadyTranslated": true
  },
  "We have received your claim request. Our representative will connect with you in next 48 hours.": {
    "text": "Kami telah menerima permintaan klaim Anda. Perwakilan kami akan menghubungi Anda dalam 48 jam ke depan.",
    "isAlreadyTranslated": true
  },
  "@ Part(s) Received by Engineer.": {
    "text": "@ Suku Cadang Diterima oleh Teknisi.",
    "isAlreadyTranslated": true
  },
  "Print": {
    "text": "Cetak",
    "isAlreadyTranslated": true
  },
  "Pupa Service Record": {
    "text": "Catatan Servis Pupa",
    "isAlreadyTranslated": true
  },
  "Service Center": {
    "text": "Service Center",
    "isAlreadyTranslated": true
  },
  "No": {
    "text": "No.",
    "isAlreadyTranslated": true
  },
  "User Name": {
    "text": "Nama Pengguna",
    "isAlreadyTranslated": true
  },
  "Telephone": {
    "text": "Telepon",
    "isAlreadyTranslated": true
  },
  "Product Model": {
    "text": "Model produk:",
    "isAlreadyTranslated": true
  },
  "Purchase Date": {
    "text": "Tanggal Pembelian",
    "isAlreadyTranslated": true
  },
  "Carry-in Time": {
    "text": "Waktu Caary In",
    "isAlreadyTranslated": true
  },
  "NA": {
    "text": "Tidak ada",
    "isAlreadyTranslated": true
  },
  "Key Account Name": {
    "text": "Nama Akun Utama",
    "isAlreadyTranslated": true
  },
  "Service Offering": {
    "text": "Penawaran Layanan",
    "isAlreadyTranslated": true
  },
  "In warranty": {
    "text": "IW",
    "isAlreadyTranslated": true
  },
  "Out of warranty": {
    "text": "OOW",
    "isAlreadyTranslated": true
  },
  "Problem Description by User": {
    "text": "Deskripsi Masalah oleh Pengguna",
    "isAlreadyTranslated": true
  },
  "Thank you for choosing us": {
    "text": "Terima kasih telah memilih kami",
    "isAlreadyTranslated": true
  },
  "ADVANCE RECEIPT": {
    "text": "ADVANCE PENERIMAAN",
    "isAlreadyTranslated": true
  },
  "Service Record": {
    "text": "Rekam layanan",
    "isAlreadyTranslated": true
  },
  "Receipt No": {
    "text": "penerimaan Tidak ada",
    "isAlreadyTranslated": true
  },
  "Paid By": {
    "text": "Dibayar oleh",
    "isAlreadyTranslated": true
  },
  "Paid To": {
    "text": "Dibayarkan kepada",
    "isAlreadyTranslated": true
  },
  "Recover Method": {
    "text": "Metode Pengambilan",
    "isAlreadyTranslated": true
  },
  "Parts replacement": {
    "text": "Penggantian suku cadang",
    "isAlreadyTranslated": true
  },
  "New IMEI/SN": {
    "text": "No. Seri/IMEI baru",
    "isAlreadyTranslated": true
  },
  "Defective Model Name": {
    "text": "Nama Model yang Rusak",
    "isAlreadyTranslated": true
  },
  "Model Number": {
    "text": "Nomor Model",
    "isAlreadyTranslated": true
  },
  "Replacement Name": {
    "text": "Nama Pengganti",
    "isAlreadyTranslated": true
  },
  "Engineer Signature": {
    "text": "Tanda Tangan Teknisi",
    "isAlreadyTranslated": true
  },
  "Finish Date": {
    "text": "Tanggal Selesai",
    "isAlreadyTranslated": true
  },
  "Are you satisfied with Pupa service experience": {
    "text": "Apakah Anda puas dengan pengalaman servis Pupa?",
    "isAlreadyTranslated": true
  },
  "Very Satisfied": {
    "text": "Sangat Puas",
    "isAlreadyTranslated": true
  },
  "Satisfied": {
    "text": "Puas",
    "isAlreadyTranslated": true
  },
  "Neutral": {
    "text": "Biasa Saja",
    "isAlreadyTranslated": true
  },
  "Dissatisfied": {
    "text": "Tidak Puas",
    "isAlreadyTranslated": true
  },
  "Very Dissatisfied": {
    "text": "Sangat Tidak Puas",
    "isAlreadyTranslated": true
  },
  "Please comment if you are dissatisfied": {
    "text": "Beri komentar jika Anda tidak puas",
    "isAlreadyTranslated": true
  },
  "User Signature": {
    "text": "Tanda Tangan Pengguna",
    "isAlreadyTranslated": true
  },
  "Thank you for choosing Pupa": {
    "text": "Terima kasih telah memilih Pupa",
    "isAlreadyTranslated": true
  },
  "Operations team will reach out to you for further action.": {
    "text": "Tim operasi akan menghubungi Anda untuk tindakan lebih lanjut.",
    "isAlreadyTranslated": true
  },
  "Protection Status": {
    "text": "Status Perlindungan",
    "isAlreadyTranslated": true
  },
  "Warranty Status": {
    "text": "Status Garansi",
    "isAlreadyTranslated": true
  },
  "valid till": {
    "text": "berlaku sampai",
    "isAlreadyTranslated": true
  },
  "Manufacturer Warranty": {
    "text": "Garansi Pabrik",
    "isAlreadyTranslated": true
  },
  "Secure Plan Warranty": {
    "text": "Garansi Skema Jaminan",
    "isAlreadyTranslated": true
  },
  "covered under": {
    "text": "dijamin di bawah",
    "isAlreadyTranslated": true
  },
  "Send for raising request": {
    "text": "Kirim untuk mengajukan permintaan",
    "isAlreadyTranslated": true
  },
  "Enter Issue Decsription": {
    "text": "Tulis Deskripsi Masalah",
    "isAlreadyTranslated": true
  },
  "Home": {
    "text": "Tempat TInggal",
    "isAlreadyTranslated": true
  },
  "Enter a location": {
    "text": "Masukkan lokasi",
    "isAlreadyTranslated": true
  },
  "Accept": {
    "text": "Setuju",
    "isAlreadyTranslated": true
  },
  "Reject": {
    "text": "Tolak",
    "isAlreadyTranslated": true
  },
  "Attempt Call": {
    "text": "Coba Memanggil",
    "isAlreadyTranslated": true
  },
  "Warranty Concession": {
    "text": "Kelonggaran Garansi",
    "isAlreadyTranslated": true
  },
  "No order lines found for given request": {
    "text": "Tidak ada pesanan untuk permintaan ini",
    "isAlreadyTranslated": true
  },
  "Valid": {
    "text": "Valid",
    "isAlreadyTranslated": true
  },
  "Invalid": {
    "text": "Tidak valid",
    "isAlreadyTranslated": true
  },
  "Toggle Warranty": {
    "text": "Aktifkan Garansi",
    "isAlreadyTranslated": true
  },
  "Please specify the reason": {
    "text": "Berikan alasannya",
    "isAlreadyTranslated": true
  },
  "Provide link for customer": {
    "text": "Beri tautan kepada pelanggan",
    "isAlreadyTranslated": true
  },
  "Accept or Reject Request": {
    "text": "Terima atau Tolak permintaan",
    "isAlreadyTranslated": true
  },
  "Inward": {
    "text": "Masuk",
    "isAlreadyTranslated": true
  },
  "Outward": {
    "text": "Keluar",
    "isAlreadyTranslated": true
  },
  "Max number of retry attempts exceeded": {
    "text": "Jumlah upaya maks mengulang terlampaui",
    "isAlreadyTranslated": true
  },
  "Where was the device kept at the time of incident": {
    "text": "Di mana perangkat ini disimpan saat insiden terjadi",
    "isAlreadyTranslated": true
  },
  "Please Enter Incident Place of Damage": {
    "text": "Tulis Tempat Insiden Kerusakan",
    "isAlreadyTranslated": true
  },
  "Min 100 Characters": {
    "text": "Min 100 Karakter",
    "isAlreadyTranslated": true
  },
  "LEFT": {
    "text": "KIRI",
    "isAlreadyTranslated": true
  },
  "Please Enter Minimum 100 Characters": {
    "text": "Tulis Minimal 100 Karakter",
    "isAlreadyTranslated": true
  },
  "Raise a request": {
    "text": "Ajukan permintaan",
    "isAlreadyTranslated": true
  },
  "Request Scheduled for": {
    "text": "Permintaan Dijadwalkan untuk",
    "isAlreadyTranslated": true
  },
  "Action Status": {
    "text": "Status Tindakan",
    "isAlreadyTranslated": true
  },
  "Action taken": {
    "text": "Waktu Tindakan Dilakukan",
    "isAlreadyTranslated": true
  },
  "Current Log": {
    "text": "Status Sekarang",
    "isAlreadyTranslated": true
  },
  "Handover Device": {
    "text": "Menyerahkan Perangkat",
    "isAlreadyTranslated": true
  },
  "Request Documents": {
    "text": "Minta Dokumen",
    "isAlreadyTranslated": true
  },
  "Issues Reported": {
    "text": "Kerusakan",
    "isAlreadyTranslated": true
  },
  "Parts and Estimation": {
    "text": "Suku Cadang dan Estimasi",
    "isAlreadyTranslated": true
  },
  "Engineer validated issues": {
    "text": "Masalah yang divalidasi teknisi",
    "isAlreadyTranslated": true
  },
  "Other Requests": {
    "text": "Permintaan Lainnya",
    "isAlreadyTranslated": true
  },
  "Connect Disposition:": {
    "text": "Disposisi Koneksi:",
    "isAlreadyTranslated": true
  },
  "Non-Connect Disposition:": {
    "text": "Disposisi Non-Koneksi:",
    "isAlreadyTranslated": true
  },
  "Cosmetic Status of Device": {
    "text": "Status Penampilan Luar Perangkat",
    "isAlreadyTranslated": true
  },
  "Latest diagnostics result": {
    "text": "Hasil diagnostik terbaru",
    "isAlreadyTranslated": true
  },
  "Purchase Order Number": {
    "text": "Nomor Pesanan Pembelian",
    "isAlreadyTranslated": true
  },
  "Service Ticket No": {
    "text": "No Tiket Servis",
    "isAlreadyTranslated": true
  },
  "National ID": {
    "text": "Nomor KTP",
    "isAlreadyTranslated": true
  },
  "Select a Consumer Product": {
    "text": "Pilih Produk Konsumen",
    "isAlreadyTranslated": true
  },
  "products under service": {
    "text": "Perangkat dalam perbaikan",
    "isAlreadyTranslated": true
  },
  "No, the device is not whitelisted. Please return the device if you have it": {
    "text": "Tidak, perangkat ini tidak masuk daftar putih. Kembalikan perangkat bila Anda memilikinya",
    "isAlreadyTranslated": true
  },
  "Your request was raised successfully": {
    "text": "Permintaan Anda berhasil diajukan",
    "isAlreadyTranslated": true
  },
  "Characters must be numeric.": {
    "text": "Karakter harus numerik.",
    "isAlreadyTranslated": true
  },
  "Selected Product": {
    "text": "Produk yang dipilih",
    "isAlreadyTranslated": true
  },
  "IMEI number": {
    "text": "nomor IMEI",
    "isAlreadyTranslated": true
  },
  "Search consumer products": {
    "text": "produk pencarian konsumen",
    "isAlreadyTranslated": true
  },
  "Next Steps": {
    "text": "Langkah selanjutnya",
    "isAlreadyTranslated": true
  },
  "Please specify remarks, if any...": {
    "text": "Keterangan",
    "isAlreadyTranslated": true
  },
  "Override Approval": {
    "text": "override Persetujuan",
    "isAlreadyTranslated": true
  },
  "(Override only if customer instructs to)*": {
    "text": "(Override hanya jika pelanggan menginstruksikan untuk) *",
    "isAlreadyTranslated": true
  },
  "Reason for Approval:>> save button": {
    "text": "Alasan Persetujuan: >> tombol save",
    "isAlreadyTranslated": true
  },
  "Part Name": {
    "text": "Nama Part",
    "isAlreadyTranslated": true
  },
  "Part Code": {
    "text": "Kode bagian",
    "isAlreadyTranslated": true
  },
  "HSN Code": {
    "text": "Kode HSN",
    "isAlreadyTranslated": true
  },
  "Qty": {
    "text": "qty",
    "isAlreadyTranslated": true
  },
  "Select a Part Name": {
    "text": "Pilih nama part",
    "isAlreadyTranslated": true
  },
  "Warranty": {
    "text": "Garansi",
    "isAlreadyTranslated": true
  },
  "Remark": {
    "text": "Keterangan",
    "isAlreadyTranslated": true
  },
  "ADD CHARGES": {
    "text": "ADD BIAYA",
    "isAlreadyTranslated": true
  },
  "Avl. Qty": {
    "text": "Avl. qty",
    "isAlreadyTranslated": true
  },
  "Available Qty": {
    "text": "tersedia Qty",
    "isAlreadyTranslated": true
  },
  "Part Warranty": {
    "text": "Part Garansi",
    "isAlreadyTranslated": true
  },
  "requested": {
    "text": "diminta",
    "isAlreadyTranslated": true
  },
  "Requested By": {
    "text": "Diminta oleh",
    "isAlreadyTranslated": true
  },
  "Issued By": {
    "text": "Dikeluarkan oleh",
    "isAlreadyTranslated": true
  },
  "External Reference ID": {
    "text": "Eksternal ID Referensi",
    "isAlreadyTranslated": true
  },
  "Current Status": {
    "text": "Status sekarang",
    "isAlreadyTranslated": true
  },
  "Add Required Parts": {
    "text": "Tambah permintaan Part",
    "isAlreadyTranslated": true
  },
  "Action": {
    "text": "Tindakan",
    "isAlreadyTranslated": true
  },
  "Slot": {
    "text": "Antara",
    "isAlreadyTranslated": true
  },
  "Validate": {
    "text": "Validasi",
    "isAlreadyTranslated": true
  },
  "Under warranty": {
    "text": "IW",
    "isAlreadyTranslated": true
  },
  "Under Warranty": {
    "text": "IW",
    "isAlreadyTranslated": true
  },
  "Registration Documents": {
    "text": "Pendaftaran Dokumen",
    "isAlreadyTranslated": true
  },
  "Warranty Applicable": {
    "text": "garansi Berlaku",
    "isAlreadyTranslated": true
  },
  "Issue description": {
    "text": "Deskripsi Keluhan",
    "isAlreadyTranslated": true
  },
  "Total Amount": {
    "text": "Jumlah total",
    "isAlreadyTranslated": true
  },
  "Customer's Version": {
    "text": "Versi Pelanggan",
    "isAlreadyTranslated": true
  },
  "Cannot Diagnose": {
    "text": "Tidak dapat Mendiagnosa",
    "isAlreadyTranslated": true
  },
  "OnePlus Service Record": {
    "text": "OnePlus Service Record",
    "isAlreadyTranslated": true
  },
  "Service Center:": {
    "text": "Pusat servis:",
    "isAlreadyTranslated": true
  },
  "Defective Part Name": {
    "text": "Cacat Nama Bagian",
    "isAlreadyTranslated": true
  },
  "Thank you for choosing OnePlus!": {
    "text": "Terima kasih telah memilih OnePlus!",
    "isAlreadyTranslated": true
  },
  "Reference No.": {
    "text": "Nomor referensi.",
    "isAlreadyTranslated": true
  },
  "Part Number/Code": {
    "text": "Nomor / Kode Part",
    "isAlreadyTranslated": true
  },
  "Is the part handed to customer?": {
    "text": "Apakah part sudah diserahkan ke pelanggan ?",
    "isAlreadyTranslated": true
  },
  "Service Center Contact No": {
    "text": "Pusat Layanan Contact ada",
    "isAlreadyTranslated": true
  },
  "TERMS AND CONDITIONS OF SERVICES": {
    "text": "SYARAT DAN KETENTUAN LAYANAN",
    "isAlreadyTranslated": true
  },
  "Warranty is not applicable for customer induced defects like water lock, physical damage, unauthorized repair;": {
    "text": "Garansi tidak berlaku untuk cacat pelanggan diinduksi seperti kunci air, kerusakan fisik, perbaikan tidak sah;",
    "isAlreadyTranslated": true
  },
  "There will be no warranty on cosmetic items;": {
    "text": "Tidak akan ada garansi pada item kosmetik;",
    "isAlreadyTranslated": true
  },
  "Before submitting the device please backup data and remove any confidential, proprietary or valuable information from the product;": {
    "text": "Sebelum mengirimkan perangkat silakan backup data dan menghapus informasi rahasia, kepemilikan atau berharga dari produk;",
    "isAlreadyTranslated": true
  },
  "Please decide to repair or not within 7 days if your device diagnosed as CID;": {
    "text": "Silahkan memutuskan untuk memperbaiki atau tidak dalam waktu 7 hari jika perangkat Anda didiagnosis sebagai CID;",
    "isAlreadyTranslated": true
  },
  "Please pickup your device within 7 days after completing the repair by engineers;": {
    "text": "Silahkan mengambil perangkat Anda dalam waktu 7 hari setelah perbaikan selesai oleh teknisi",
    "isAlreadyTranslated": true
  },
  "Please provide the original proof of purchase (Bill copy) to claim warranty;": {
    "text": "Harap berikan bukti pembelian asli (Bill copy) untuk mengklaim garansi;",
    "isAlreadyTranslated": true
  },
  "Please remove SIM card, memory card and pouches before depositing.": {
    "text": "Hapus kartu SIM, kartu memori dan kantong sebelum deposito.",
    "isAlreadyTranslated": true
  },
  "Please collect invoice / bill copy and the defective parts of out of warranty paid cases while taking delivery.": {
    "text": "Silakan mengumpulkan faktur / tagihan copy dan bagian-bagian yang rusak dari luar garansi kasus dibayar saat mengambil pengiriman.",
    "isAlreadyTranslated": true
  },
  "IMEI 1/Serial Number 1": {
    "text": "IMEI 1 / Serial Number 1",
    "isAlreadyTranslated": true
  },
  "IMEI 2/Serial Number 2": {
    "text": "IMEI 2 / Serial Number 2",
    "isAlreadyTranslated": true
  },
  "Warranty applicable for this service": {
    "text": "Garansi yang berlaku untuk layanan ini",
    "isAlreadyTranslated": true
  },
  "Page 2 of 2": {
    "text": "Halaman 2 dari 2",
    "isAlreadyTranslated": true
  },
  "Page 1 of 2": {
    "text": "Halaman 1 dari 2",
    "isAlreadyTranslated": true
  },
  "Are you sure? Please specify remarks, if any...": {
    "text": "Keterangan CS",
    "isAlreadyTranslated": true
  },
  "Are you sure?": {
    "text": "Apakah Anda yakin?",
    "isAlreadyTranslated": true
  },
  "Are you sure? Please specify the reason.": {
    "text": "Apakah Anda yakin? Sebutkan alasannya.",
    "isAlreadyTranslated": true
  },
  "Show Device Diagnosis": {
    "text": "Tampilkan diagnosis perangkat",
    "isAlreadyTranslated": true
  },
  "Storage Capacity": {
    "text": "Kapasitas penyimpanan",
    "isAlreadyTranslated": true
  },
  "Year of Purchase": {
    "text": "Tahun Pembelian",
    "isAlreadyTranslated": true
  },
  "Sales Channel": {
    "text": "Tempat Pembelian Perangkat",
    "isAlreadyTranslated": true
  },
  "Purchase Price": {
    "text": "Harga pembelian",
    "isAlreadyTranslated": true
  },
  "Is Warranty Applicable": {
    "text": "Apakah Garansi Berlaku",
    "isAlreadyTranslated": true
  },
  "Reason": {
    "text": "Alasan",
    "isAlreadyTranslated": true
  },
  "Symptoms": {
    "text": "Kerusakan",
    "isAlreadyTranslated": true
  },
  "Consumer Raised Issues": {
    "text": "Keluhan Pelanggan ",
    "isAlreadyTranslated": true
  },
  "Validity": {
    "text": "Validitas",
    "isAlreadyTranslated": true
  },
  "No Parts Requested": {
    "text": "Tidak ada Parts Diminta",
    "isAlreadyTranslated": true
  },
  "Pickup Charge": {
    "text": "Pickup Mengisi",
    "isAlreadyTranslated": true
  },
  "Symptoms Reported By Engineer": {
    "text": "Kerusakan menurut Teknisi",
    "isAlreadyTranslated": true
  },
  "Add New Symptom Codes": {
    "text": "Pilih kode Kerusakan",
    "isAlreadyTranslated": true
  },
  "Add": {
    "text": "Menambah",
    "isAlreadyTranslated": true
  },
  "Standard (16)%": {
    "text": "Standard (16)%",
    "isAlreadyTranslated": true
  },
  "Total Payable": {
    "text": "total Hutang",
    "isAlreadyTranslated": true
  },
  "Parts": {
    "text": "Part",
    "isAlreadyTranslated": true
  },
  "Advance": {
    "text": "Muka",
    "isAlreadyTranslated": true
  },
  "Other Charge": {
    "text": "Biaya lain-lain",
    "isAlreadyTranslated": true
  },
  "Discount": {
    "text": "Diskon",
    "isAlreadyTranslated": true
  },
  "Receive": {
    "text": "Menerima",
    "isAlreadyTranslated": true
  },
  "Damaged": {
    "text": "Rusak",
    "isAlreadyTranslated": true
  },
  "Unused": {
    "text": "Tidak Terpakai",
    "isAlreadyTranslated": true
  },
  "Save": {
    "text": "Disimpan",
    "isAlreadyTranslated": true
  },
  "Issue Description": {
    "text": "Deskripsi Keluhan",
    "isAlreadyTranslated": true
  },
  "Repair estimate approved": {
    "text": "estimasi perbaikan disetujui",
    "isAlreadyTranslated": true
  },
  "Work in Progress with Engineer": {
    "text": "Dalam proses pengerjaan oleh Teknisi",
    "isAlreadyTranslated": true
  },
  "Device Received & Inspection in Progress": {
    "text": "Perangkat Diterima & Dalam Proses Inspeksi",
    "isAlreadyTranslated": true
  },
  "Request History": {
    "text": "Riwayat Permintaan",
    "isAlreadyTranslated": true
  },
  "Charge Name": {
    "text": "Nama biaya",
    "isAlreadyTranslated": true
  },
  "Tax": {
    "text": "Pajak",
    "isAlreadyTranslated": true
  },
  "Add Discount": {
    "text": "Ditambah Diskon",
    "isAlreadyTranslated": true
  },
  "Send For Approval": {
    "text": "Kirim untuk Persetujuan",
    "isAlreadyTranslated": true
  },
  "Complete Repair": {
    "text": "Perbaikan Selesai",
    "isAlreadyTranslated": true
  },
  "View Jobsheet": {
    "text": "Lihat Jobsheet",
    "isAlreadyTranslated": true
  },
  "View DOA Certificate": {
    "text": "Lihat Sertifikat DOA / IF",
    "isAlreadyTranslated": true
  },
  "View Parts Document": {
    "text": "Lihat dokumen part",
    "isAlreadyTranslated": true
  },
  "View Invoice": {
    "text": "Lihat Invoice",
    "isAlreadyTranslated": true
  },
  "View Advance Receipt": {
    "text": "Penerimaan Lihat Muka",
    "isAlreadyTranslated": true
  },
  "Please collect invoice/bill copy and the defective parts of out of warranty paid cases while taking delivery.": {
    "text": "Silakan mengumpulkan faktur / tagihan copy dan bagian-bagian yang rusak dari luar garansi kasus dibayar saat mengambil pengiriman.",
    "isAlreadyTranslated": true
  },
  "Parts handover note": {
    "text": "Catatan Penyerahan Part",
    "isAlreadyTranslated": true
  },
  "To:": {
    "text": "Untuk:",
    "isAlreadyTranslated": true
  },
  "From:": {
    "text": "Dari:",
    "isAlreadyTranslated": true
  },
  "Signature": {
    "text": "Tanda tangan",
    "isAlreadyTranslated": true
  },
  "Quantity": {
    "text": "Kuantitas",
    "isAlreadyTranslated": true
  },
  "Servicelocation Engineer": {
    "text": "Service location Teknisi",
    "isAlreadyTranslated": true
  },
  "Handing over the parts to store.": {
    "text": "Menyerahkan part ke store",
    "isAlreadyTranslated": true
  },
  "Handing over the": {
    "text": "Menyerahkan",
    "isAlreadyTranslated": true
  },
  "Generate Invoice": {
    "text": "Membuat Invoice",
    "isAlreadyTranslated": true
  },
  "full box unit": {
    "text": "Unit kotak penuh",
    "isAlreadyTranslated": true
  },
  "to store.": {
    "text": "Ke Store",
    "isAlreadyTranslated": true
  },
  "parts": {
    "text": "Part",
    "isAlreadyTranslated": true
  },
  "Select Fault Code based on the validated issues.": {
    "text": "Pilih Patahan Kode didasarkan pada isu-isu divalidasi.",
    "isAlreadyTranslated": true
  },
  "No results to display": {
    "text": "Tidak ada hasil untuk menampilkan",
    "isAlreadyTranslated": true
  },
  "Fault Code": {
    "text": "Kode Kerusakan",
    "isAlreadyTranslated": true
  },
  "Would you like to prompt the user?": {
    "text": "Apakah Anda ingin meminta pengguna?",
    "isAlreadyTranslated": true
  },
  "PromptSilent": {
    "text": "PromptSilent",
    "isAlreadyTranslated": true
  },
  "Would you like to perform a full/custom diagnosis?": {
    "text": "Apakah anda ingin melakukan Diagnosa menyeluruh atau custom ?",
    "isAlreadyTranslated": true
  },
  "FullCusCustomer Pincode is not serviceable by Our Logistics Partner. Please send the device to the Customer using other Courier Services near youtom": {
    "text": "FullCusCustomer kode pin tidak diservis oleh Logistik Partner kami. Silakan kirim perangkat untuk Nasabah menggunakan Jasa Kurir lainnya di dekat youtom",
    "isAlreadyTranslated": true
  },
  "Initiate logistics request": {
    "text": "Mengajukan permintaan logistik",
    "isAlreadyTranslated": true
  },
  "Perform QC": {
    "text": "melakukan QC",
    "isAlreadyTranslated": true
  },
  "QC Checklist": {
    "text": "Checklist QC",
    "isAlreadyTranslated": true
  },
  "Select All": {
    "text": "Pilih Semua",
    "isAlreadyTranslated": true
  },
  "Accept and Complete QC": {
    "text": "Terima dan Selesaikan QC",
    "isAlreadyTranslated": true
  },
  "Reject QC": {
    "text": "Tolak QC",
    "isAlreadyTranslated": true
  },
  "Please complete the QC checklist": {
    "text": "Silahkan Selesaikan Checklist QC",
    "isAlreadyTranslated": true
  },
  "Repair Completed, Ready To Inform The Customer": {
    "text": "Perbaikan Selesai, Siap Menginformasikan Nasabah",
    "isAlreadyTranslated": true
  },
  "4:00 pm - 7:00 pm": {
    "text": "4:00 pm - 7:00 pm",
    "isAlreadyTranslated": true
  },
  "10:00 am - 1:00 pm": {
    "text": "10:00 am - 1:00 pm",
    "isAlreadyTranslated": true
  },
  "1:00 pm - 4:00 pm": {
    "text": "1:00 pm - 4:00 pm",
    "isAlreadyTranslated": true
  },
  "10:00 am - 7:00 pm": {
    "text": "10:00 am - 7:00 pm",
    "isAlreadyTranslated": true
  },
  "Genarate Invoice": {
    "text": "Membuat Invoice",
    "isAlreadyTranslated": true
  },
  "First Dialer Dispostion": {
    "text": "Pertama Dialer Dispostion",
    "isAlreadyTranslated": true
  },
  "Second Dialer Dispostion": {
    "text": "Kedua Dialer Dispostion",
    "isAlreadyTranslated": true
  },
  "Remarks": {
    "text": "Keterangan",
    "isAlreadyTranslated": true
  },
  "Part Price": {
    "text": "Harga Part",
    "isAlreadyTranslated": true
  },
  "Address Details": {
    "text": "Alamat Lengkap",
    "isAlreadyTranslated": true
  },
  "First Registered From": {
    "text": "Pertama Terdaftar Dari",
    "isAlreadyTranslated": true
  },
  "No saved Address": {
    "text": "Alamat belum pernah disimpan",
    "isAlreadyTranslated": true
  },
  "Return": {
    "text": "Kembali",
    "isAlreadyTranslated": true
  },
  "From": {
    "text": "Dari",
    "isAlreadyTranslated": true
  },
  "To": {
    "text": "Untuk",
    "isAlreadyTranslated": true
  },
  "Tax Invoice": {
    "text": "Invoice Pajak",
    "isAlreadyTranslated": true
  },
  "Tax is payable on Reverse Charge (Y/N)": {
    "text": "Pajak terhutang membalikkan muatan (Y / N)",
    "isAlreadyTranslated": true
  },
  "GSTIN/Unique ID": {
    "text": "GSTIN / ID unik",
    "isAlreadyTranslated": true
  },
  "Date & Time of Supply": {
    "text": "Tanggal & Waktu Supply",
    "isAlreadyTranslated": true
  },
  "Place Of Supply": {
    "text": "Tempat Pasokan",
    "isAlreadyTranslated": true
  },
  "Payment Date": {
    "text": "Tanggal pembayaran",
    "isAlreadyTranslated": true
  },
  "Mode of Payment": {
    "text": "Metode pembayaran",
    "isAlreadyTranslated": true
  },
  "PAN No": {
    "text": "PAN Tidak ada",
    "isAlreadyTranslated": true
  },
  "Tax is payable on Reverse Charge (Y/N): Y": {
    "text": "Pajak terhutang membalikkan muatan (Y / N): Y",
    "isAlreadyTranslated": true
  },
  "Tax is payable on Reverse Charge (Y/N): N": {
    "text": "Pajak terhutang membalikkan muatan (Y / N): N",
    "isAlreadyTranslated": true
  },
  "Repaired by": {
    "text": "diperbaiki oleh",
    "isAlreadyTranslated": true
  },
  "Details of Receiver (Billed to)": {
    "text": "Rincian Receiver (Ditagih ke)",
    "isAlreadyTranslated": true
  },
  "Details of Consignee (Shipped to)": {
    "text": "Rincian Penerima barang (Dikirim ke)",
    "isAlreadyTranslated": true
  },
  "State Code": {
    "text": "Kode negara",
    "isAlreadyTranslated": true
  },
  "Sl. No": {
    "text": "Sl. No",
    "isAlreadyTranslated": true
  },
  "Description of Goods/Services": {
    "text": "Deskripsi Barang / Jasa",
    "isAlreadyTranslated": true
  },
  "HSN/SAC Code": {
    "text": "HSN / Kode SAC",
    "isAlreadyTranslated": true
  },
  "Rate": {
    "text": "Menilai",
    "isAlreadyTranslated": true
  },
  "Taxable value": {
    "text": "Nilai kena Pajak",
    "isAlreadyTranslated": true
  },
  "Total Tax": {
    "text": "Total Pajak",
    "isAlreadyTranslated": true
  },
  "Invoice Total": {
    "text": "Total Invoice",
    "isAlreadyTranslated": true
  },
  "Advance Received": {
    "text": "muka Diterima",
    "isAlreadyTranslated": true
  },
  "Round-Off Amount": {
    "text": "Jumlah dibulatkan",
    "isAlreadyTranslated": true
  },
  "Payable Value": {
    "text": "Nilai yang terutang",
    "isAlreadyTranslated": true
  },
  "Invoice Total (In Words)": {
    "text": "Jumlah invoice ( Terbilang )",
    "isAlreadyTranslated": true
  },
  "Authorised Signatory": {
    "text": "Tanda tangan resmi",
    "isAlreadyTranslated": true
  },
  "Declaration: We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.": {
    "text": "Pernyataan: Kami menyatakan bahwa Invoice ini menunjukkan harga sebenarnya dari barang yang diuraikan dan bahwa semua keterangan yang benar dan sesuai.",
    "isAlreadyTranslated": true
  },
  "Terms & Conditions:": {
    "text": "Syarat & Ketentuan:",
    "isAlreadyTranslated": true
  },
  "Tax Summary of Invoice (For Finance)": {
    "text": "Ringkasan pajak invoice ( untuk Finance )",
    "isAlreadyTranslated": true
  },
  "Declaration": {
    "text": "Pernyataan",
    "isAlreadyTranslated": true
  },
  "We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.": {
    "text": "Kami menyatakan bahwa Invoice ini menunjukkan harga sebenarnya dari barang yang diuraikan dan bahwa semua keterangan itu benar dan sesuai.",
    "isAlreadyTranslated": true
  },
  "(I have read the above mentioned terms and conditions are agreeable to the same and further confirm that our signature below confirms receipts of products in good order and noted.)": {
    "text": "(Saya telah membaca dan menyetujui  syarat dan ketentuan yang disebutkan di atas. konfirmasi lebih lanjut bahwa tanda tangan ditanda terima menyatakan bahwa produk dalam kondisi bagus dan diterima",
    "isAlreadyTranslated": true
  },
  "The request is not at the required status for the given action": {
    "text": "permintaan tidak di status diperlukan untuk tindakan yang diberikan",
    "isAlreadyTranslated": true
  },
  "Complete Service": {
    "text": "Perbaikan Selesai",
    "isAlreadyTranslated": true
  },
  "Repair completed and Intimate customer": {
    "text": "Perbaikan selesai dan pelanggan",
    "isAlreadyTranslated": true
  },
  "Receive & Inspect Device": {
    "text": "Menerima & Periksa Perangkat",
    "isAlreadyTranslated": true
  },
  "Download Jobsheet Label": {
    "text": "Download label Jobsheet",
    "isAlreadyTranslated": true
  },
  "Cancel Service Without Dispatch": {
    "text": "Batal perbaikan tanpa dikirim",
    "isAlreadyTranslated": true
  },
  "Cancel Service": {
    "text": "Batal perbaikan",
    "isAlreadyTranslated": true
  },
  "Waiting for the Customer Care Executive to receive device and complete mandatory checklist tasks.": {
    "text": "Menunggu Customer service untuk menerima perangkat dan menyelesaikan tugas-tugas checklist wajib.",
    "isAlreadyTranslated": true
  },
  "Assigned To": {
    "text": "Ditugaskan untuk",
    "isAlreadyTranslated": true
  },
  "Select SKU": {
    "text": "pilih SKU",
    "isAlreadyTranslated": true
  },
  "No pictures added.": {
    "text": "Tidak ada gambar ditambahkan",
    "isAlreadyTranslated": true
  },
  "Other Image": {
    "text": "Gambar lainnya",
    "isAlreadyTranslated": true
  },
  "Not applicable": {
    "text": "Tidak Berlaku",
    "isAlreadyTranslated": true
  },
  "Complete": {
    "text": "Selesai",
    "isAlreadyTranslated": true
  },
  "Assignment": {
    "text": "Tugas",
    "isAlreadyTranslated": true
  },
  "Service Engineer": {
    "text": "Teknisi",
    "isAlreadyTranslated": true
  },
  "Cancel Repair Without Dispatch": {
    "text": "perbaikan dibatalkan tanpa dikirim",
    "isAlreadyTranslated": true
  },
  "This service is a Return without repair (RWR)": {
    "text": "Layanan ini merupakan pengembalian tanpa perbaikan (RWR)",
    "isAlreadyTranslated": true
  },
  "Re-assign Service Engineer": {
    "text": "Menugaskan kembali teknisi",
    "isAlreadyTranslated": true
  },
  "Select an engineer": {
    "text": "Pilih Teknisi",
    "isAlreadyTranslated": true
  },
  "Assign": {
    "text": "menugaskan",
    "isAlreadyTranslated": true
  },
  "Show device diagnosis": {
    "text": "Tampilkan diagnosis perangkat",
    "isAlreadyTranslated": true
  },
  "Part(s) request under review by storekeeper.": {
    "text": "permintaan  part seding dilhat oleh admin",
    "isAlreadyTranslated": true
  },
  "Send for Approval": {
    "text": "Kirim untuk Persetujuan",
    "isAlreadyTranslated": true
  },
  "Reason for Approval": {
    "text": "Alasan Persetujuan",
    "isAlreadyTranslated": true
  },
  "Parts Supply Location": {
    "text": "Lokasi pembagian part",
    "isAlreadyTranslated": true
  },
  "Order": {
    "text": "Memesan",
    "isAlreadyTranslated": true
  },
  "Please enter the remarks with at least 25 characters": {
    "text": "Masukkan keterangan minimal 25 karakter",
    "isAlreadyTranslated": true
  },
  "Customer's Copy": {
    "text": "copy untuk pelanggan",
    "isAlreadyTranslated": true
  },
  "DOA CERTIFICATE": {
    "text": "Sertifikat DOA / Inspection Form",
    "isAlreadyTranslated": true
  },
  "Dated": {
    "text": "Tanggal",
    "isAlreadyTranslated": true
  },
  "Date of Purchase (As per invoice)": {
    "text": "Tanggal Pembelian (sesuai invoice )",
    "isAlreadyTranslated": true
  },
  "Service Completion Date": {
    "text": "Tanggal selesai perbaikan",
    "isAlreadyTranslated": true
  },
  "Model No": {
    "text": "model nomor",
    "isAlreadyTranslated": true
  },
  "Model Name": {
    "text": "Nama model",
    "isAlreadyTranslated": true
  },
  "ESC Code": {
    "text": "Kode ESC",
    "isAlreadyTranslated": true
  },
  "ESC Name": {
    "text": "Nama ESC",
    "isAlreadyTranslated": true
  },
  "ESC Location": {
    "text": "Alamat ESC",
    "isAlreadyTranslated": true
  },
  "DOA Check List": {
    "text": "Check list DOA",
    "isAlreadyTranslated": true
  },
  "Customer Details & Remarks": {
    "text": "Detil Perangkat & Keterangan",
    "isAlreadyTranslated": true
  },
  "Contact No.": {
    "text": "Nomor kontak.",
    "isAlreadyTranslated": true
  },
  "DOA Device IMEI": {
    "text": "IMEI Perangkat DOA",
    "isAlreadyTranslated": true
  },
  "Customer Signature": {
    "text": "Tanda tangan pelanggan",
    "isAlreadyTranslated": true
  },
  "ESC Stamp & Signature": {
    "text": "ESC Stamp & Signature",
    "isAlreadyTranslated": true
  },
  "Service Center's Copy": {
    "text": "Service Center's Copy",
    "isAlreadyTranslated": true
  },
  "ESC Name and Stamp": {
    "text": "Nama ESC dan Stamp",
    "isAlreadyTranslated": true
  },
  "Handover to QC": {
    "text": "Menyerahkan ke QC",
    "isAlreadyTranslated": true
  },
  "OR": {
    "text": "ATAU",
    "isAlreadyTranslated": true
  },
  "Search products": {
    "text": "Cari Produk",
    "isAlreadyTranslated": true
  },
  "Add an address": {
    "text": "Tambahkan alamat",
    "isAlreadyTranslated": true
  },
  "Enter Location": {
    "text": "Masukkan Lokasi",
    "isAlreadyTranslated": true
  },
  "Characters must be alphabets": {
    "text": "Karakter harus berupa huruf",
    "isAlreadyTranslated": true
  },
  "Characters must be alphabets (at least 2 alphabets are mandatory)": {
    "text": "Karakter harus berupa huruf (minimal 2 huruf yang wajib)",
    "isAlreadyTranslated": true
  },
  "Ref Id": {
    "text": "Ref Id",
    "isAlreadyTranslated": true
  },
  "Started On": {
    "text": "dimulai Pada",
    "isAlreadyTranslated": true
  },
  "Communication History": {
    "text": "Riwayat Komunikasi",
    "isAlreadyTranslated": true
  },
  "Status at which call was made": {
    "text": "Status di mana panggilan dibuat",
    "isAlreadyTranslated": true
  },
  "Date and Time of Call": {
    "text": "Tanggal dan waktu panggilan",
    "isAlreadyTranslated": true
  },
  "Second Dialer Disposition": {
    "text": "Kedua Dialer Disposisi",
    "isAlreadyTranslated": true
  },
  "First Dialer Disposition": {
    "text": "Pertama Dialer Disposisi",
    "isAlreadyTranslated": true
  },
  "Connection Disposition": {
    "text": "koneksi Disposisi",
    "isAlreadyTranslated": true
  },
  "Call Connected": {
    "text": "Hubungi Terhubung",
    "isAlreadyTranslated": true
  },
  "Telecon Version": {
    "text": "Telecon Versi",
    "isAlreadyTranslated": true
  },
  "Accept Device": {
    "text": "Terima Perangkat",
    "isAlreadyTranslated": true
  },
  "ID Proof": {
    "text": "ID Bukti",
    "isAlreadyTranslated": true
  },
  "Invoice": {
    "text": "Invoice",
    "isAlreadyTranslated": true
  },
  "Others": {
    "text": "Lainnya",
    "isAlreadyTranslated": true
  },
  "Dashboard": {
    "text": "Dasbor",
    "isAlreadyTranslated": true
  },
  "Consumers": {
    "text": "konsumen",
    "isAlreadyTranslated": true
  },
  "View Document": {
    "text": "Lihat Dokumen",
    "isAlreadyTranslated": true
  },
  "Upload Inspection Documents": {
    "text": "Upload Dokumen Inspeksi",
    "isAlreadyTranslated": true
  },
  "Initiate Service": {
    "text": "memulai Layanan",
    "isAlreadyTranslated": true
  },
  "Accept/Reject Job": {
    "text": "Terima / Tolak Job",
    "isAlreadyTranslated": true
  },
  "Reject Estimation": {
    "text": "Tolak Estimasi",
    "isAlreadyTranslated": true
  },
  "Collect Advance": {
    "text": "kumpulkan Muka",
    "isAlreadyTranslated": true
  },
  "Collect Device": {
    "text": "Mengambil perangkat",
    "isAlreadyTranslated": true
  },
  "Accept Parts Request": {
    "text": "Terima permintaan part",
    "isAlreadyTranslated": true
  },
  "Reject Parts Request": {
    "text": "Tolak permintaan part",
    "isAlreadyTranslated": true
  },
  "DOA Checklist": {
    "text": "Checklist DOA",
    "isAlreadyTranslated": true
  },
  "Prompt": {
    "text": "Cepat",
    "isAlreadyTranslated": true
  },
  "Silent": {
    "text": "Diam",
    "isAlreadyTranslated": true
  },
  "Full": {
    "text": "Penuh",
    "isAlreadyTranslated": true
  },
  "Custom": {
    "text": "Custom",
    "isAlreadyTranslated": true
  },
  "BUTTONS": {
    "text": "TOMBOL",
    "isAlreadyTranslated": true
  },
  "SCREEN": {
    "text": "LAYAR",
    "isAlreadyTranslated": true
  },
  "Using Notification": {
    "text": "menggunakan notifikasi",
    "isAlreadyTranslated": true
  },
  "Using QR Code": {
    "text": "Menggunakan QR Kode",
    "isAlreadyTranslated": true
  },
  "Select from the following to create a custom diagnosis": {
    "text": "Pilih dibawah ini untuk membuat diagnosis kustom",
    "isAlreadyTranslated": true
  },
  "Is the device applicable for DOA?": {
    "text": "Apakah perangkat berlaku untuk DOA?",
    "isAlreadyTranslated": true
  },
  "Scan the QR code from Diagnosis App": {
    "text": "Memindai kode QR dari Diagnosis App",
    "isAlreadyTranslated": true
  },
  "Check Status": {
    "text": "Cek Status",
    "isAlreadyTranslated": true
  },
  "Type of damage": {
    "text": "Jenis kerusakan",
    "isAlreadyTranslated": true
  },
  "Please mention the incident in not less than 75 characters on how, when & where the damage happened": {
    "text": "Harap menyebutkan insiden itu di tidak kurang dari 75 karakter pada bagaimana, kapan & di mana kerusakan terjadi",
    "isAlreadyTranslated": true
  },
  "Handing over the defective device to store.": {
    "text": "Menyerahkan perangkat rusak ke Store",
    "isAlreadyTranslated": true
  },
  "Update KGB": {
    "text": "Perbarui KGB",
    "isAlreadyTranslated": true
  },
  "Select GSX Repair Type": {
    "text": "Pilih GSX Perbaikan Jenis",
    "isAlreadyTranslated": true
  },
  "Transfer Request": {
    "text": "Permintaan transfer",
    "isAlreadyTranslated": true
  },
  "Send link": {
    "text": "Kirim link",
    "isAlreadyTranslated": true
  },
  "Logistic Partners": {
    "text": "logistik Mitra",
    "isAlreadyTranslated": true
  },
  "Select a Date": {
    "text": "Pilih Tanggal",
    "isAlreadyTranslated": true
  },
  "Select a Slot": {
    "text": "Pilih Slot",
    "isAlreadyTranslated": true
  },
  "No Slots Available": {
    "text": "Tidak ada slot Tersedia",
    "isAlreadyTranslated": true
  },
  "Schedule Request": {
    "text": "Jadwal Permintaan",
    "isAlreadyTranslated": true
  },
  "Item": {
    "text": "Barang",
    "isAlreadyTranslated": true
  },
  "Raise": {
    "text": "Mengajukan",
    "isAlreadyTranslated": true
  },
  "Request": {
    "text": "Permintaan",
    "isAlreadyTranslated": true
  },
  "Please Select Device Receiving Time": {
    "text": "Silakan Pilih Perangkat Menerima Waktu",
    "isAlreadyTranslated": true
  },
  "Tier part": {
    "text": "bagian Tier",
    "isAlreadyTranslated": true
  },
  "Shipper": {
    "text": "Pengirim",
    "isAlreadyTranslated": true
  },
  "Tracking Number": {
    "text": "Melacak nomor",
    "isAlreadyTranslated": true
  },
  "COMPTIA Group": {
    "text": "CompTIA Grup",
    "isAlreadyTranslated": true
  },
  "COMPTIA Code": {
    "text": "Kode CompTIA",
    "isAlreadyTranslated": true
  },
  "COMPTIA Modifier": {
    "text": "CompTIA Modifier",
    "isAlreadyTranslated": true
  },
  "Continue": {
    "text": "Selanjutnya",
    "isAlreadyTranslated": true
  },
  "To proceed, update Device Date of Purchase and Device Purchase Cost in 'Device Details' below.": {
    "text": "Untuk melanjutkan, Pembaruan perangkat Tanggal Pembelian dan Device Pembelian Biaya di 'Detail Perangkat' di bawah ini.",
    "isAlreadyTranslated": true
  },
  "Ask for Documents Pending": {
    "text": "Meminta Dokumen Pending",
    "isAlreadyTranslated": true
  },
  "Enter type of document to add": {
    "text": "Masukkan jenis dokumen untuk menambahkan",
    "isAlreadyTranslated": true
  },
  "Enter Reference IDs": {
    "text": "Masukkan Referensi ID",
    "isAlreadyTranslated": true
  },
  "Current Request Reference ID": {
    "text": "ID Referensi Permintaan saat ini",
    "isAlreadyTranslated": true
  },
  "Pick Up Request Reference ID": {
    "text": "Pick Up Permintaan ID Referensi",
    "isAlreadyTranslated": true
  },
  "Merge": {
    "text": "Menggabungkan",
    "isAlreadyTranslated": true
  },
  "Device transaction details": {
    "text": "Rincian Transaksi Perangkat",
    "isAlreadyTranslated": true
  },
  "Select Device": {
    "text": "Pilih Perangkat",
    "isAlreadyTranslated": true
  },
  "Please check following items before completing the QC.": {
    "text": "Silakan periksa item sebelum menyelesaikan QC berikut.",
    "isAlreadyTranslated": true
  },
  "Initiate Claim": {
    "text": "Mulai Klaim",
    "isAlreadyTranslated": true
  },
  "Raise Claim": {
    "text": "Ajukan Klaim",
    "isAlreadyTranslated": true
  },
  "In-principle Approval": {
    "text": "Disetujui secara Pokok",
    "isAlreadyTranslated": true
  },
  "Approve Claim Estimation": {
    "text": "Menyetujui Estimasi Klaim",
    "isAlreadyTranslated": true
  },
  "Complete QC": {
    "text": "Selesai QC",
    "isAlreadyTranslated": true
  },
  "Handover device": {
    "text": "Menyerahkan Perangkat",
    "isAlreadyTranslated": true
  },
  "Accept device": {
    "text": "Terima perangkat",
    "isAlreadyTranslated": true
  },
  "Complete Job and Handover Device": {
    "text": "Lengkap Pekerjaan dan Penyerahan Perangkat",
    "isAlreadyTranslated": true
  },
  "Reject Claim": {
    "text": "Tolak Klaim",
    "isAlreadyTranslated": true
  },
  "Confirm BER": {
    "text": "konfirmasi BER",
    "isAlreadyTranslated": true
  },
  "Reject BER": {
    "text": "Tolak BER",
    "isAlreadyTranslated": true
  },
  "Generate estimation": {
    "text": "Membuat Estimasi",
    "isAlreadyTranslated": true
  },
  "Documents Pending": {
    "text": "Dokumen Tertunda",
    "isAlreadyTranslated": true
  },
  "Document Type: Cheque": {
    "text": "Jenis Dokumen: Cek",
    "isAlreadyTranslated": true
  },
  "Please enter UTR Number": {
    "text": "Masukkan UTR Nomor",
    "isAlreadyTranslated": true
  },
  "UTR Number": {
    "text": "UTR Nomor",
    "isAlreadyTranslated": true
  },
  "View queued requests": {
    "text": "Melihat antrian pemintaan",
    "isAlreadyTranslated": true
  },
  "Generate Vouchers": {
    "text": "Membuat Voucher",
    "isAlreadyTranslated": true
  },
  "Customer's Address Details": {
    "text": "Detil Alamat Pelanggan",
    "isAlreadyTranslated": true
  },
  "Hub's Address Details": {
    "text": "Hub Detil Alamat",
    "isAlreadyTranslated": true
  },
  "Upload Document": {
    "text": "Upload Dokumen",
    "isAlreadyTranslated": true
  },
  "Call logs": {
    "text": "Catatan Panggilan",
    "isAlreadyTranslated": true
  },
  "Okay": {
    "text": "Baik",
    "isAlreadyTranslated": true
  },
  "an address": {
    "text": "Alamat",
    "isAlreadyTranslated": true
  },
  "Characters must be numbers": {
    "text": "Karakter harus dengan Angka",
    "isAlreadyTranslated": true
  },
  "Now": {
    "text": "Sekarang",
    "isAlreadyTranslated": true
  },
  "Later": {
    "text": "Nanti",
    "isAlreadyTranslated": true
  },
  "Hub Details": {
    "text": "Detail hub",
    "isAlreadyTranslated": true
  },
  "This request will be added to a queue. You can schedule a logistics request later.": {
    "text": "Permintaan ini akan ditambahkan ke antrian. Anda dapat menjadwalkan permintaan logistik kemudian.",
    "isAlreadyTranslated": true
  },
  "Recycle Hub Details": {
    "text": "Recycle Detail Hub",
    "isAlreadyTranslated": true
  },
  "Add Certificate": {
    "text": "Tambahkan Sertifikat",
    "isAlreadyTranslated": true
  },
  "Incoming": {
    "text": "Datang",
    "isAlreadyTranslated": true
  },
  "Outgoing": {
    "text": "Keluar",
    "isAlreadyTranslated": true
  },
  "Add call log": {
    "text": "Tambah Catatan Panggilan",
    "isAlreadyTranslated": true
  },
  "Call history": {
    "text": "Riwayat panggilan",
    "isAlreadyTranslated": true
  },
  "No call history": {
    "text": "Tidak ada riwayat panggilan",
    "isAlreadyTranslated": true
  },
  "N/A": {
    "text": "N / A",
    "isAlreadyTranslated": true
  },
  "Request is being processed": {
    "text": "Permintaan sedang diproses",
    "isAlreadyTranslated": true
  },
  "Service Location": {
    "text": "Alamat Service Center",
    "isAlreadyTranslated": true
  },
  "No addresses found": {
    "text": "Tidak ada alamat yang ditemukan",
    "isAlreadyTranslated": true
  },
  "Select a Service Mode": {
    "text": "Pilih Mode Layanan",
    "isAlreadyTranslated": true
  },
  "Select a service location": {
    "text": "Pilih Service Center",
    "isAlreadyTranslated": true
  },
  "Oops! There are no verified service centres around.": {
    "text": "Ups! Disekitar tidak ada Service Center",
    "isAlreadyTranslated": true
  },
  "There is no service for this address at this moment": {
    "text": "Tidak ada layanan untuk alamat ini pada saat ini",
    "isAlreadyTranslated": true
  },
  "Raise Request": {
    "text": "Mengajukan  Permintaan",
    "isAlreadyTranslated": true
  },
  "Select Logistics Partner": {
    "text": "Pilih Logistik",
    "isAlreadyTranslated": true
  },
  "Confirm": {
    "text": "Konfirmasi",
    "isAlreadyTranslated": true
  },
  "Config Description": {
    "text": "Deskripsi Config",
    "isAlreadyTranslated": true
  },
  "Validation Status": {
    "text": "Status validasi",
    "isAlreadyTranslated": true
  },
  "Verify": {
    "text": "Verifikasi",
    "isAlreadyTranslated": true
  },
  "Number of Attempts Remaining": {
    "text": "Jumlah tersisa untuk mencoba",
    "isAlreadyTranslated": true
  },
  "Verification Not Attempted": {
    "text": "Verifikasi belum dilakukan",
    "isAlreadyTranslated": true
  },
  "No documents uploaded": {
    "text": "Tidak ada dokumen ter upload",
    "isAlreadyTranslated": true
  },
  "Add Pending Documents": {
    "text": "Menambah dokumen yang tertunda",
    "isAlreadyTranslated": true
  },
  "No Pending Document Specified": {
    "text": "Tidak ada dokumen yang ditentukan tertunda",
    "isAlreadyTranslated": true
  },
  "Add Additional Documents": {
    "text": "Menambah Dokumen Tambahan",
    "isAlreadyTranslated": true
  },
  "Customer did not add issues": {
    "text": "Pelanggan tidak menambahkan keluhan",
    "isAlreadyTranslated": true
  },
  "No issues added": {
    "text": "Tidak ada keluhan yang ditambahkan",
    "isAlreadyTranslated": true
  },
  "Engineer Added Faults": {
    "text": "Teknisi menambahkan kerusakan",
    "isAlreadyTranslated": true
  },
  "Faults": {
    "text": "Kerusakan",
    "isAlreadyTranslated": true
  },
  "Action Code": {
    "text": "Kode tindakan",
    "isAlreadyTranslated": true
  },
  "Fault Codes": {
    "text": "Kode kerusakan",
    "isAlreadyTranslated": true
  },
  "Actions": {
    "text": "tindakan",
    "isAlreadyTranslated": true
  },
  "Accessories will not be returned": {
    "text": "Aksesoris tidak akan dikembalikan",
    "isAlreadyTranslated": true
  },
  "Documents are yet to be uploaded.": {
    "text": "Dokumen yang belum di-upload.",
    "isAlreadyTranslated": true
  },
  "Rating": {
    "text": "penilaian",
    "isAlreadyTranslated": true
  },
  "Waiting for the customer to raise a request": {
    "text": "Menunggu pelanggan untuk mengajukanpermintaan",
    "isAlreadyTranslated": true
  },
  "Reschedule": {
    "text": "menjadwal ulang",
    "isAlreadyTranslated": true
  },
  "Reassign": {
    "text": "Menugaskan kembali",
    "isAlreadyTranslated": true
  },
  "Mark Customer Arrival": {
    "text": "Tandai kedatangan pelanggan",
    "isAlreadyTranslated": true
  },
  "Start Repair": {
    "text": "Mulai Perbaikan",
    "isAlreadyTranslated": true
  },
  "Dispatch Device": {
    "text": "Perangkat dikirim",
    "isAlreadyTranslated": true
  },
  "Issue Validation": {
    "text": "Validasi Masalah",
    "isAlreadyTranslated": true
  },
  "Report Issues to": {
    "text": "melaporan Masalah kepada",
    "isAlreadyTranslated": true
  },
  "Estimation Details": {
    "text": "Detil Estimasi",
    "isAlreadyTranslated": true
  },
  "Override Approval History": {
    "text": "Riwayat penolakan persetujuan",
    "isAlreadyTranslated": true
  },
  "Dead on Arrival": {
    "text": "Dead on Arrival",
    "isAlreadyTranslated": true
  },
  "Logistics Details": {
    "text": "Rincian logistik",
    "isAlreadyTranslated": true
  },
  "Loading request": {
    "text": "Loading permintaan",
    "isAlreadyTranslated": true
  },
  "No such service request exists": {
    "text": "Tidak ada  pemintaan perbaikan seperti itu",
    "isAlreadyTranslated": true
  },
  "Send Issues to": {
    "text": "Kirim keluhan ke",
    "isAlreadyTranslated": true
  },
  "Symptom Code": {
    "text": "Kode Kerusakan",
    "isAlreadyTranslated": true
  },
  "Issue Code": {
    "text": "Kode Keluhan",
    "isAlreadyTranslated": true
  },
  "Part name": {
    "text": "Nama Part",
    "isAlreadyTranslated": true
  },
  "Covered Value": {
    "text": "Nilai tercakup",
    "isAlreadyTranslated": true
  },
  "Price": {
    "text": "Harga",
    "isAlreadyTranslated": true
  },
  "Tax Slab": {
    "text": "Slab pajak",
    "isAlreadyTranslated": true
  },
  "Is Part Abused": {
    "text": "Apakah part disalahgunakan",
    "isAlreadyTranslated": true
  },
  "Is Returnable Damage": {
    "text": "Apakah Kerusakan dikembalikan",
    "isAlreadyTranslated": true
  },
  "Is Covered By ACPlus": {
    "text": "Apakah Covered Dengan ACPlus",
    "isAlreadyTranslated": true
  },
  "KBB Serial Number": {
    "text": "KBB Serial Number",
    "isAlreadyTranslated": true
  },
  "Pricing Option": {
    "text": "Option pricing",
    "isAlreadyTranslated": true
  },
  "Charge Value": {
    "text": "Nilai Biaya",
    "isAlreadyTranslated": true
  },
  "MRP": {
    "text": "MRP",
    "isAlreadyTranslated": true
  },
  "Final Price": {
    "text": "Harga akhir",
    "isAlreadyTranslated": true
  },
  "Good Part Serial No": {
    "text": "Nomor Serial Part Bagus",
    "isAlreadyTranslated": true
  },
  "Bad Part Serial No": {
    "text": "Nomor Serial Part Rusak",
    "isAlreadyTranslated": true
  },
  "Avl.": {
    "text": "Avl.",
    "isAlreadyTranslated": true
  },
  "Enter Information for the parts you are issuing": {
    "text": "Masukkan Informasi untuk part yang Anda keluarkan",
    "isAlreadyTranslated": true
  },
  "Device Replacement Mode": {
    "text": "Metode Ganti Unit",
    "isAlreadyTranslated": true
  },
  "Generate DOA Certificate": {
    "text": "Buat Sertifikat DOA / Inspection Form",
    "isAlreadyTranslated": true
  },
  "Get New Device": {
    "text": "Ganti Unit Baru",
    "isAlreadyTranslated": true
  },
  "Choose Device": {
    "text": "Pilih Perangkat",
    "isAlreadyTranslated": true
  },
  "Action on Device": {
    "text": "Tindakan pada Perangkat",
    "isAlreadyTranslated": true
  },
  "Enter Information for the Device you are issuing": {
    "text": "Masukkan Informasi untuk Perangkat yang Anda berikan",
    "isAlreadyTranslated": true
  },
  "IMEI 1": {
    "text": "IMEI 1",
    "isAlreadyTranslated": true
  },
  "IMEI 2": {
    "text": "IMEI 2",
    "isAlreadyTranslated": true
  },
  "Please enter correct IMEI 1": {
    "text": "Masukkan IMEI 1 yang benar",
    "isAlreadyTranslated": true
  },
  "Please enter correct IMEI 2": {
    "text": "Masukkan IMEI 2 yang benar",
    "isAlreadyTranslated": true
  },
  "Please enter correct Serial Number": {
    "text": "Masukkan Nomor Serial yang benar",
    "isAlreadyTranslated": true
  },
  "Account Details": {
    "text": "Detil akun",
    "isAlreadyTranslated": true
  },
  "The Green Reward cash amount will be credited to the account associated with this number": {
    "text": "Jumlah kas Hijau Reward akan dikreditkan ke akun yang terkait dengan nomor ini",
    "isAlreadyTranslated": true
  },
  "Add Customer's Mobile Number": {
    "text": "Menambah Nomor Handphone Pelanggan",
    "isAlreadyTranslated": true
  },
  "Enter Mobile Number": {
    "text": "Masukkan Nomor Handphone",
    "isAlreadyTranslated": true
  },
  "Re - Enter Mobile Number": {
    "text": "Masukkan kembali Nomor handphone",
    "isAlreadyTranslated": true
  },
  "GSX Confirmation Number": {
    "text": "Nomor Konfirmasi GSX",
    "isAlreadyTranslated": true
  },
  "Reassigned": {
    "text": "Menugaskan kembali",
    "isAlreadyTranslated": true
  },
  "Pending Verification": {
    "text": "Verifikasi tertunda",
    "isAlreadyTranslated": true
  },
  "Recycle Certificate": {
    "text": "Sertifikat Recycle",
    "isAlreadyTranslated": true
  },
  "Logistics - From Drop-Off Center to Servify Hub": {
    "text": "Logistik - Dari Drop-Off Center untuk Servify Hub",
    "isAlreadyTranslated": true
  },
  "Logistics - From Servify Hub to Recycle Hub": {
    "text": "Logistik - Dari Servify Hub Recycle Hub",
    "isAlreadyTranslated": true
  },
  "Logistics - Return to Customer": {
    "text": "Logistik - Kembali ke Pelanggan",
    "isAlreadyTranslated": true
  },
  "Pick Up Confirmation Number": {
    "text": "Pick Up Nomor Konfirmasi",
    "isAlreadyTranslated": true
  },
  "Location": {
    "text": "tempat",
    "isAlreadyTranslated": true
  },
  "Paid": {
    "text": "dibayar",
    "isAlreadyTranslated": true
  },
  "Payable": {
    "text": "hutang",
    "isAlreadyTranslated": true
  },
  "Loss DateTime": {
    "text": "Tanggal Jam Kerugian",
    "isAlreadyTranslated": true
  },
  "Damage Type": {
    "text": "Jenis kerusakan",
    "isAlreadyTranslated": true
  },
  "Loss Description": {
    "text": "Keterangan Kerugian",
    "isAlreadyTranslated": true
  },
  "Device switching on": {
    "text": "Perangkat dinyalakan",
    "isAlreadyTranslated": true
  },
  "Place of Damage": {
    "text": "Daerah kerusakan",
    "isAlreadyTranslated": true
  },
  "Enter Reference ID": {
    "text": "Masukkan Referensi ID",
    "isAlreadyTranslated": true
  },
  "Reschedule Service": {
    "text": "menjadwal ulang layanan",
    "isAlreadyTranslated": true
  },
  "No slots Available": {
    "text": "Tidak ada slot Tersedia",
    "isAlreadyTranslated": true
  },
  "Reason for reschedule": {
    "text": "Alasan untuk penjadwalan ulang",
    "isAlreadyTranslated": true
  },
  "Reassign Service": {
    "text": "menugas kembali perbaikan",
    "isAlreadyTranslated": true
  },
  "Not Covered": {
    "text": "Tidak tercakup",
    "isAlreadyTranslated": true
  },
  "Validated": {
    "text": "divalidasi",
    "isAlreadyTranslated": true
  },
  "End on": {
    "text": "berakhir pada",
    "isAlreadyTranslated": true
  },
  "Waiting for CC to receive device and complete mandatory checklist tasks.": {
    "text": "Menunggu Customer service untuk menerima perangkat dan menyelesaikan checklist wajib.",
    "isAlreadyTranslated": true
  },
  "Date and Time of loss/damage:": {
    "text": "Tanggal dan Waktu kehilangan / kerusakan:",
    "isAlreadyTranslated": true
  },
  "Service Mode": {
    "text": "Metode Layanan",
    "isAlreadyTranslated": true
  },
  "Slot and Date": {
    "text": "Antara dan Tanggal",
    "isAlreadyTranslated": true
  },
  "Insurance Benefits": {
    "text": "Manfaat asuransi",
    "isAlreadyTranslated": true
  },
  "Inspection Guide": {
    "text": "Panduan Inspeksi",
    "isAlreadyTranslated": true
  },
  "c": {
    "text": "c",
    "isAlreadyTranslated": true
  },
  "View Delivery Report": {
    "text": "Lihat Laporan Pengiriman",
    "isAlreadyTranslated": true
  },
  "View Depot shipper label": {
    "text": "label pengirim Lihat Depot",
    "isAlreadyTranslated": true
  },
  "BER Amount": {
    "text": "BER Jumlah",
    "isAlreadyTranslated": true
  },
  "Loaner Device IMEI": {
    "text": "Imei Perangkat Pinjaman",
    "isAlreadyTranslated": true
  },
  "Condition Of the Loaner Device After Use": {
    "text": "Kondisi perangkat pinjaman setelah digunakan",
    "isAlreadyTranslated": true
  },
  "Remarks for the Loaner Device": {
    "text": "Keterangan untuk Perangkat Loaner",
    "isAlreadyTranslated": true
  },
  "Delivery Address": {
    "text": "Alamat pengiriman",
    "isAlreadyTranslated": true
  },
  "doc": {
    "text": "Dokumen",
    "isAlreadyTranslated": true
  },
  "Token released": {
    "text": "Token dirilis",
    "isAlreadyTranslated": true
  },
  "Token generated": {
    "text": "Token yang dihasilkan",
    "isAlreadyTranslated": true
  },
  "Insufficient Data For Graph. Data for": {
    "text": "Data yang cukup Untuk Grafik. Data untuk",
    "isAlreadyTranslated": true
  },
  "is": {
    "text": "adalah",
    "isAlreadyTranslated": true
  },
  "No Data Available To Plot The Graph": {
    "text": "Tidak ada data Tersedia Untuk Plot Grafik",
    "isAlreadyTranslated": true
  },
  "Create Bulk Request": {
    "text": "Buat Permintaan Massal",
    "isAlreadyTranslated": true
  },
  "External Requests": {
    "text": "Permintaan eksternal",
    "isAlreadyTranslated": true
  },
  "Dispatch": {
    "text": "Mengirim",
    "isAlreadyTranslated": true
  },
  "Mail IN": {
    "text": "Surat masuk",
    "isAlreadyTranslated": true
  },
  "Devices": {
    "text": "Perangkat",
    "isAlreadyTranslated": true
  },
  "till": {
    "text": "sampai",
    "isAlreadyTranslated": true
  },
  "Loading requests": {
    "text": "Loading permintaan",
    "isAlreadyTranslated": true
  },
  "to": {
    "text": "untuk",
    "isAlreadyTranslated": true
  },
  "Download Open/Inprogress requests.": {
    "text": "Download permintaan yand dibuka / dalam proses.",
    "isAlreadyTranslated": true
  },
  "Download all reports": {
    "text": "Download semua laporan",
    "isAlreadyTranslated": true
  },
  "The parts will be returned to Store": {
    "text": "Part akan dikembalikan ke Store",
    "isAlreadyTranslated": true
  },
  "Consumer To Service Centre": {
    "text": "Pelanggan ke service center",
    "isAlreadyTranslated": true
  },
  "Within S.F. service area coverage": {
    "text": "Dalam S.F. cakupan area layanan",
    "isAlreadyTranslated": true
  },
  "Logistics - From Service Center to Service Center": {
    "text": "Logistik - Dari Service Center untuk Service Center",
    "isAlreadyTranslated": true
  },
  "Remove": {
    "text": "Menghapus",
    "isAlreadyTranslated": true
  },
  "Test purpose": {
    "text": "Tujuan tes",
    "isAlreadyTranslated": true
  },
  "Defective Serial No": {
    "text": "Nomor serial cacat",
    "isAlreadyTranslated": true
  },
  "Defective IMEI": {
    "text": "Imei Cacat",
    "isAlreadyTranslated": true
  },
  "Replaced IMEI 1": {
    "text": "IMEI 1 digantikan",
    "isAlreadyTranslated": true
  },
  "Replaced IMEI 2": {
    "text": "Imei 2 digantikan",
    "isAlreadyTranslated": true
  },
  "Replaced IMEI": {
    "text": "Imei digantikan",
    "isAlreadyTranslated": true
  },
  "Other charge": {
    "text": "Biaya lain-lain",
    "isAlreadyTranslated": true
  },
  "Test remarks": {
    "text": "Keterangan Tes",
    "isAlreadyTranslated": true
  },
  "issued": {
    "text": "Diberikan",
    "isAlreadyTranslated": true
  },
  "received": {
    "text": "Diterima",
    "isAlreadyTranslated": true
  },
  "times": {
    "text": "Waktu",
    "isAlreadyTranslated": true
  },
  "No diagnosis found for this": {
    "text": "Tidak ada diagnosis ditemukan untuk ini",
    "isAlreadyTranslated": true
  },
  "consumer product": {
    "text": "Produk Pelanggan",
    "isAlreadyTranslated": true
  },
  "Mo": {
    "text": "Sen",
    "isAlreadyTranslated": true
  },
  "Tu": {
    "text": "Sel",
    "isAlreadyTranslated": true
  },
  "We": {
    "text": "Rab",
    "isAlreadyTranslated": true
  },
  "Th": {
    "text": "Kam",
    "isAlreadyTranslated": true
  },
  "Fr": {
    "text": "Jum",
    "isAlreadyTranslated": true
  },
  "Sa": {
    "text": "Sab",
    "isAlreadyTranslated": true
  },
  "Su": {
    "text": "Min",
    "isAlreadyTranslated": true
  },
  "Quotation document": {
    "text": "Dokumen penawaran",
    "isAlreadyTranslated": true
  },
  "Estimate Generated By": {
    "text": "Estimasi Dibuat Oleh",
    "isAlreadyTranslated": true
  },
  "Service Estimate Total": {
    "text": "Total Estimasi Biaya perbaikan",
    "isAlreadyTranslated": true
  },
  "The charges mentioned in this Estimate are an approximation and is based on the anticipated details of the work to be carried out basis the inspection. It is possible for unexpected situations / complications to cause some deviation from this Estimate. If additional parts or labour are required you will be contacted immediately and upon your concurrence to carry out the details of the work, a new Estimate will be created for your consideration and approval.": {
    "text": "Biaya yang disebutkan dalam Estimasi ini adalah perkiraan dan didasarkan pada rincian diantisipasi dari pekerjaan yang akan dilakukan secara pemeriksaan. Hal ini dimungkinkan untuk situasi tak terduga / komplikasi menyebabkan beberapa penyimpangan dari estimasi ini. Jika bagian tambahan atau tenaga kerja yang diperlukan Anda akan dihubungi segera dan setelah persetujuan Anda untuk melaksanakan rincian pekerjaan, sebuah Estimasi baru akan dibuat untuk pertimbangan dan persetujuan.",
    "isAlreadyTranslated": true
  },
  "Disclaimer": {
    "text": "Penolakan",
    "isAlreadyTranslated": true
  },
  "In Words": {
    "text": "Terbilang",
    "isAlreadyTranslated": true
  },
  "We were unable to find product details, please select product manually": {
    "text": "Kami tidak dapat menemukan detil produk, silahkan pilih produk secara manual",
    "isAlreadyTranslated": true
  },
  "Enter correct Pincode": {
    "text": "Masukkan kode pin yang benar",
    "isAlreadyTranslated": true
  },
  "StoreManager": {
    "text": "Store Manager",
    "isAlreadyTranslated": true
  },
  "View Estimation Document": {
    "text": "Lihat Dokumen Estimasi",
    "isAlreadyTranslated": true
  },
  "SE Express": {
    "text": "SE Express",
    "isAlreadyTranslated": true
  },
  "Speaker issue": {
    "text": "Masalah speaker",
    "isAlreadyTranslated": true
  },
  "Year": {
    "text": "Tahun",
    "isAlreadyTranslated": true
  },
  "Month": {
    "text": "Bulan",
    "isAlreadyTranslated": true
  },
  "Engineer will add symtoms": {
    "text": "Teknisi akan menambah kerusakan",
    "isAlreadyTranslated": true
  },
  "Complete DOA": {
    "text": "Lengkapi DOA",
    "isAlreadyTranslated": true
  },
  "Address updated successfully": {
    "text": "Alamat berhasil diperbarui",
    "isAlreadyTranslated": true
  },
  "This device is eligible for DOA": {
    "text": "Perangkat ini memenuhi syarat untuk DOA",
    "isAlreadyTranslated": true
  },
  "This device is not eligible for DOA": {
    "text": "Perangkat ini tidak memenuhi syarat untuk DOA",
    "isAlreadyTranslated": true
  },
  "(Eligible for DOA)": {
    "text": "(memenuhi syarat untuk DOA)",
    "isAlreadyTranslated": true
  },
  "Waiting for engineer to accept the job. (Eligible for DOA)": {
    "text": "Menunggu Teknisi menerima tugas ( memenuhi syarat untuk DOA )",
    "isAlreadyTranslated": true
  },
  "Receive Device": {
    "text": "Menerima Perangkat",
    "isAlreadyTranslated": true
  },
  "Waiting for the Customer Care Executive to receive device.": {
    "text": "Menunggu customer Care eksekutif menerima menerima perangkat",
    "isAlreadyTranslated": true
  },
  "Inspect Device": {
    "text": "Mengecek Perangkat",
    "isAlreadyTranslated": true
  },
  "Waiting for the Customer Care Executive to complete mandatory checklist tasks.": {
    "text": "Menunggu customer Care eksekutif menyelesaikan checklist wajib",
    "isAlreadyTranslated": true
  },
  "Device Value": {
        "text": "nilai peralatan",
        "isAlreadyTranslated": true
    },
    "One time deductible": {  
        "text": "Satu kali dikurangkan",
        "isAlreadyTranslated": true
    },
    "Is Manufacturer's Warranty Applicable": { 
        "text": "Apakah garansi pabrik berlaku", 
        "isAlreadyTranslated": true
    },
    "Manufacturer's Warranty Status": { 
        "text": "Garansi pabrik", 
        "isAlreadyTranslated": true
    },
    "Add Device Date of Purchase and Device Value in 'Device Details' below":
    {
        "text": "Tambahkan tanggal Pembelian Perangkat dan Nilai Perangkat di 'Rincian Perangkat' di bawah ini",
        "isAlreadyTranslated": true
    },
    "To proceed, update Device Date of Purchase and Device Value in 'Device Details' below.":
    {
        "text": "Untuk melanjutkan, perbarui Tanggal Pembelian Perangkat dan Nilai Perangkat di 'Rincian Perangkat' di bawah ini.",
        "isAlreadyTranslated": true
    },
};
export default lang;