import React, { useCallback, useEffect, useState } from 'react'
import { Button, Checkbox, Dropdown, Input } from 'sfy-react'
import { cloneDeep } from 'lodash';
import Swal from 'sweetalert2';

import { displayError } from '../../../../../Utility/Utils';


export default function NewActualCharge(props) {
	const {
		localString, newCharges, setNewCharges, currencyOptions, setLoader, apiCall, updateCharge
	} = props;

	const [chargeTypeOptions, setChargeTypeOptions] = useState([]);
	const taxablePercentage = [{ value: 5 }, { value: 12 }, { value: 15 }, { value: 18 }, { value: 28 }];

	const getChargesMaster = useCallback(() => {
		setLoader(true);
		apiCall({
			url: 'chargesMaster',
			method: 'GET',
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					if (result.data) {
						let options = result.data?.length ? result.data.map(i => ({ ...i, value: i.Type })) : [];
						setChargeTypeOptions(options);
					}
				} else {
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			},
			errorCB: (err) => setLoader(false)
		});
	}, [])

	useEffect(() => {
		getChargesMaster();
	}, [getChargesMaster])

	const handleChargeChange = useCallback((index, key, value) => {
		let arr = cloneDeep(newCharges);
		arr[index][key] = value;
		setNewCharges(arr);
	}, [newCharges])

	const removeCharge = useCallback((index) => {
		let arr = cloneDeep(newCharges);
		arr.splice(index, 1);
		setNewCharges(arr);
	}, [newCharges])

	return (
		<>
			{newCharges && newCharges.map((ChargeType, idx) => (
				<div
					key={ChargeType.Type + idx}
					style={{ padding: '20px 0px' }}
				>
					<div className="row marginb10">
						<div className={`col-xs-3`}>
							<Dropdown
								id='chargeID'
								key={idx}
								value={ChargeType.ChargeType || ''}
								options={chargeTypeOptions || []}
								placeholder={localString("Select Charge Type*")}
								handleOnChange={(data) => {
									let arr = cloneDeep(newCharges);
									arr[idx]['ChargeMasterID'] = data.ChargeMasterID;
									arr[idx]['ChargeType'] = data.value;
									setNewCharges(arr);
								}}
								filterBy='value'
								showDownArrow={false}
								isDisabled={updateCharge}
							/>
						</div>
						<div className={`col-xs-3`}>
							<Input
								placeholder={localString("Charge Name*")}
								inputType="text"
								otherProps={{
									required: "required",
								}}
								value={ChargeType.ChargeName || ""}
								handleOnChange={(e) => {
									handleChargeChange(idx, 'ChargeName', e.target.value);
								}}
                                isDisabled={updateCharge}
							/>
						</div>
						<div className={`col-xs-2`}>
							<Dropdown
								value={ChargeType.CurrencyCode}
								options={currencyOptions || []}
								handleOnChange={(data) => {
									handleChargeChange(idx, 'CurrencyCode', data?.value);
								}}
								placeholder={localString("Currency*")}
								filterBy='value'
								showDownArrow={false}
								isDisabled={updateCharge}
							/>
						</div>
						<div className="col-xs-2">
							<Input
								placeholder={localString("Amount*")}
								inputType="text"
								// isDisabled={(ChargeType.Type == "ServiceCharge") ? true : false}
								otherProps={{
									required: true,
									min: "0",
								}}
								value={ChargeType.Amount || ""}
								handleOnChange={(e) => {
									handleChargeChange(idx, 'Amount', e.target.value);
								}}
							/>
						</div>
						<div className={`col-xs-1`}>
							<Dropdown
								id='taxPercent'
								key={idx}
								// value={ChargeType.TaxSlab || localString('Taxable Amount')}
								value={ChargeType.TaxPercentage || ''}
								options={taxablePercentage}
								placeholder={localString("Tax %")}
								handleOnChange={(data) => {
									handleChargeChange(idx, "TaxPercentage", data.value)
								}}
								filterBy='value'
								showDownArrow={false}
							/>
						</div>

						{!updateCharge && <div className={'col-xs-1'}><span style={{ top: "10px" }} className="glyphicon glyphicon-minus-sign" onClick={e => removeCharge(idx)}></span></div> }

					</div>
				</div>
			))}
		</>
	)
}
