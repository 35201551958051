import React from 'react';
import ExpandItem from '../../components/ExpandItem/ExpandItem';
import moment from 'moment';

const UserFeedback = (props) => {
    const {localString, userFeedback} = props;
    
    return (
        <div>
            <p><h4>{localString('User feedback')}</h4></p>
            {userFeedback && userFeedback.length > 0 && userFeedback.map((obj) => { return(
                <ExpandItem 
                    name={"Feedback"}
                    showImage={false}
                    localString={localString} 
                    subHeaderText1={localString("Created Date: ") + moment(obj.CreatedDate).format('DD MMM, YYYY hh:mm a')}
                    showExpandArrow={true}
                >
                    {Object.keys(obj).length > 0 && Object.keys(obj).map((keys) => {return (
                        <div className="item-info">
                            {obj[keys] && <>
                            <span className="info-label">{keys}</span><span> : </span>
                            <span>{(obj[keys]).toString()}</span>
                            </>}
                        </div>
                    )})}
                </ExpandItem>
            )})}
        </div>
    )
}

export default UserFeedback
