import React from 'react';
import {Modal} from 'sfy-react';
import CancelRequestInput from './CancelRequestInput';
import './CancelRequestModal.scss';
const CancelRequsetModal=(props)=>{
    const {localString,showModal,closeModal,setFilterCancelrequest,filterCancelrequest,header} = props;
		return (
		<>
            <Modal showModal={showModal} onOutsideClick={closeModal}  className='cancel-request-modal'>
                <Modal.Header>
                    <h2 className='cancel-heading'> {localString(header)} </h2>
                </Modal.Header>
                <Modal.Body>
                    <CancelRequestInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default  CancelRequsetModal;