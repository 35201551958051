import React from "react";
import "./CredentialsWrapper.scss";

function CredentialsWrapper(props) {
	const title = props.title;

	return (
		<div className="flexbox">
			<div className="cred-panel flexbox-div">
				<div className="cred-panel-header">
					<div className="cred-logo-div">
						<img src={props.publicUrl + "/imgs/logo-purple.png"} className="cred-logo"/>
					</div>
					<p className="cred-title">{title}</p>
				</div>
				<div className="cred-panel-body">{props.children}</div>
			</div>
		</div>
	);
}

export default CredentialsWrapper;
