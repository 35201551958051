import React,{useState, useEffect} from 'react';
import { Loader, Input, Button } from 'sfy-react';
import { displayAmount } from '../../../../Utility/Utils';
import "./WarrantyFlow.scss";

const WarrantyFlow = (props) => {
	const {publicUrl, localString, checkFlags, showPlanTypes, planTypes, selectedPlanType, headers, plans, selectPlanId, showDiscountedRecurringAmount, 
		showUnDiscountedPriceSimple, showPlanPriceSimple, showDiscountPriceSimple, showRecurringAmount, checkIfDiscountedPriceToBeShown, showRecurringFrequency,
		showDiscountPriceEMI, sellNowWarranty, setSelectedPlanType, selectTypeTab, selectedHeader, selectTab, warrantyApiAvail, tabs, countriesData
	} = props;

	const [updatedPlanList, setUpdatedPlanList] = useState([]);

	useEffect(() => {
		setUpdatedPlanList(plans);
	}, [plans]);

	const viewDiscountFunction = (index) => {
		let buttonClick = false;
		let newArr = []
		let a = updatedPlanList?.map((val,i) => {
			if(i === index){
				let obj = {};
				obj = val;
				obj.viewOfferBtnClick = obj.viewOfferBtnClick ? !obj.viewOfferBtnClick : !buttonClick;
				newArr.push(obj)
			}else {
				newArr.push(val)
			}
			return newArr;
		});
		setUpdatedPlanList(newArr);
	}

	return (
		<>
			{
				!checkFlags.showproductTextBox && 
				<div className='warranty-flow'>
					{/** *********************** Tabs for plan type *********************** */}
					{
						showPlanTypes && 
						<ul id="productTypeTab" class="nav nav-tabs nav-tabs-responsive" role="tablist">
						{
							planTypes && planTypes.map((type,i) => {
								return (
									<li role = "presentation" 
										onClick = {(e) => {
											e.preventDefault();
											setSelectedPlanType(type);
										}}
										className = { selectedPlanType == type || (type == 'eAPP' && selectedPlanType == 'Secure') ? 'active nav-tab-li' : 'nav-tab-li' }
									>
										<a href className='a-tab'>
											<span>{localString(type)}</span>
										</a>
									</li>
								)
							})
						}
						</ul>
					}
					{/** *********************** The tabs for the diferent groups *********************** */}
					{ 
						tabs &&
						<ul id="subProductTypeTab" class="tabs-headers nav nav-tabs nav-tabs-responsive" role="tablist">
						{
							headers && headers.map((subType,i)=>{
								return(
									<li role="presentation" 
										onClick={(e)=>{
											e.preventDefault(); 
											selectTab(subType)
										}} 
										className={selectedHeader == subType  ? 'active nav-tabname-li' : 'nav-tabname-li'}>
										<a href class='a-tab'>
										<span>{localString(subType)}</span>
										</a>
									</li>
								)
							})
						}
						</ul>
					}
					{
						<div className="tab-content padding-none">
							{
								warrantyApiAvail && <div className="visible-xs plan-list-header">{localString("Select Plans")}</div>
							}
							{
								(warrantyApiAvail && !showPlanTypes && plans) && <div className="hidden-xs eligible-plan-list-header">{(!updatedPlanList || updatedPlanList.length == 0) ? localString("No Eligible Plan(s)") : localString("Eligible Plan(s)")}</div>
							}
							{
								checkFlags.showproductTextBox && showPlanTypes && <h2>{localString("Select a Plan")}</h2>
							}
							<div className={warrantyApiAvail && !showPlanTypes ? 'margin-top50 tab-pane row active' : 'tab-pane row active'}>
								{
									(!updatedPlanList || updatedPlanList.length == 0) &&
									<div style={{margin: "0 20px"}}>
										<p>{localString("Sorry! We couldn't find any eligible plans for your product.")}</p>
									</div>
								}
								{
									updatedPlanList && updatedPlanList.map((val, index) => {
										return (
											<div className = {val.PlanID == selectPlanId ? 'active-plan col-sm-12 plan-list-wrapper' : 'col-sm-12 plan-list-wrapper'}>
												<div className="visible-xs plan-text">{localString("Plan Name")}</div>
												<div class="col-sm-4 plan-data" onclick ={(e)=>{e.preventDefault(); sellNowWarranty(val)}}>{val.PlanDisplayName || val.PlanName}</div>
												{
													showPlanPriceSimple(val) && 
													<div class="visible-xs plan-text">{localString("Price")}</div>
												}
												{
													showPlanPriceSimple(val) && 
													<div onClick = {(e)=>{e.preventDefault(); sellNowWarranty(val)}} style={{textAlign:"end"}} class="col-sm-4 plan-data">
														{
															!!(val.PlanPriceArray[0]?.UpfrontPaymentAllowed )&& 
															<span>
															{
																<>
																	{
																		val.PlanPriceArray?.length == 1 && val.simpleDiscount &&
																		<span class="strike-through checking">
																			<span class="{'currency-' + index}"></span>
																			{
																				showUnDiscountedPriceSimple(val)
																					? 	displayAmount(showUnDiscountedPriceSimple(val), 
																						countriesData?.countryConfig?.Locale?.DEFAULT,
																						val.CurrencyCode) 
																					: ''
																			}
																			{' '}
																			{/* {val.CurrencySymbol && <span dangerouslySetInnerHTML={{__html: val.CurrencySymbol}}></span>} 
																			{showUnDiscountedPriceSimple(val)} */}
																		</span>
																	}
																	<span class="payment-plan-price-list">
																		{
																			val.PlanPriceArray?.length == 1 && 
																			showPlanPriceSimple(val)
																				? 	displayAmount(showPlanPriceSimple(val), 
																					countriesData?.countryConfig?.Locale?.DEFAULT,
																					val.CurrencyCode) 
																				: ''
																		}
																		{' '}
																		{/* {
																			val.PlanPriceArray?.length == 1 && 
																			val.CurrencySymbol && <span dangerouslySetInnerHTML={{__html: val.CurrencySymbol}}></span>
																		}{showPlanPriceSimple(val)} */}
																	</span>
																	{
																		val.PlanPriceArray.length == 1 && val.simpleDiscount && 
																		<span class="green">
																			<span dangerouslySetInnerHTML={{__html: showDiscountPriceSimple(val)}}></span>
																		</span>
																	}
																</>
															}
															</span>
														}
														{
															val.PlanPriceArray[0].planPriceBillingConfig && 
															<span>
															{
																!!(val.PlanPriceArray[0].UpfrontPaymentAllowed) && 
																<span><br/>{localString("or ")}</span>
															}
															{
																val.EMIDiscount && 
																<span class="strike-through">
																	<span class="{'currency-' + index}"></span> 
																	{/* {val.CurrencySymbol && <span dangerouslySetInnerHTML={{__html: val.CurrencySymbol}}></span>} */}
																	{
																		showRecurringAmount(val.PlanPriceArray[0].planPriceBillingConfig)
																			? 	displayAmount(showRecurringAmount(val.PlanPriceArray[0].planPriceBillingConfig), 
																				countriesData?.countryConfig?.Locale?.DEFAULT,
																				val.CurrencyCode) 
																			: ''
																	}
																	{' '}
																	{/* {showRecurringAmount(val.PlanPriceArray[0].planPriceBillingConfig)} */}
																	{
																		!checkIfDiscountedPriceToBeShown(val) 
																			? 	'/' + showRecurringFrequency(val.PlanPriceArray[0].planPriceBillingConfig)
																			: 	''
																	}
																	{' '}
																</span>
															}
															{
																checkIfDiscountedPriceToBeShown(val) && 
																<span class="payment-plan-price-list">
																	<span class="{'currency-' + index}"></span>
																	{/* {val.CurrencySymbol && <span dangerouslySetInnerHTML={{__html: val.CurrencySymbol}}></span>} */}
																	{/* {showDiscountedRecurringAmount(val)} */}
																	{	
																		showDiscountedRecurringAmount(val) 
																		?	displayAmount(showDiscountedRecurringAmount(val), 
																			countriesData?.countryConfig?.Locale?.DEFAULT,
																			val.CurrencyCode)
																		: ''
																	}
																	/
																	{showRecurringFrequency(val.PlanPriceArray[0].planPriceBillingConfig)}{' '}
																</span>
															}
															{
																val.PlanPriceArray.length == 1 && val.EMIDiscount && 
																<span class="green">
																	<span dangerouslySetInnerHTML={{__html: showDiscountPriceEMI(val)}}></span>
																</span>
															}
															</span>
														}
														<br/>
														{localString("Plan Validity")}: {val.PlanValidity}
													</div>
												}
												<div class="col-xs-12 col-sm-4 pull-right plan-actions">
													<Button 
														type = "button"
														classes = "btn button-servify header-btn button-stack pull-left sell-now-btn"
														handleOnClick = {(e) => {e.preventDefault(); sellNowWarranty(val)}}
														text = {localString("Sell Now")}
													/>
													<a href = {val.AboutLink} target="_blank">
														<Button 
															type = "button"
															classes = "btn button-servify header-btn button-stack know-more-btn"
															text = {localString("Know More")}
														/>
													</a>
													{
														val.showViewAllOffers && 
														<Button 
															type = "button"
															classes = "view-offers-btn button-servify reverse-btn plan-reverse-button"
															handleOnClick = {(e)=> {e.preventDefault(); viewDiscountFunction(index)}}
															text = {localString("View Offers")}
														/>
													}
												</div>
												{
													val.showViewAllOffers && val.viewOfferBtnClick && 
													<div>
														<div id={"discountOnPlan_"+index} class="col-sm-12 discounts-container">
															{
																val?.PlanDiscounts?.PlanDiscountOffers.map((discount)=> {
																	return (
																		<span style={{display: "flex", padding: "5px 0"}}>
																			<img src={publicUrl+'/imgs/percentage.svg'} className="percent-img" alt="%"/>
																			{" "}<span dangerouslySetInnerHTML={{__html: discount.HomePageDisplayText}}></span>
																		</span>
																	)
																})
															}
														</div>
													</div>
												}
											</div>
										)
									})
								}
							</div>
						</div>
					}
				</div>
			}
		</>
	)
}

export default WarrantyFlow