import React from 'react';
import { Table } from 'sfy-react';
import get from 'lodash/get';

const CommonTable = (props) => {
    const { columns, data, localString, tableClasses = {}, handleSort=()=>{}} = props
    return (
        <>
            {
                data && data.length > 0 ?
                <div className='container-fluid'>
                    <Table isResponsive className={tableClasses}>
                        <Table.Header>
                            <Table.Row>
                                {columns && columns.length > 0 && columns.map((column, index) => {
                                    return (
                                        column.showHeading && <Table.Heading key={index} showSortIcons={column.showSortIcons} activeSortIcon = {column.activeSortIcon} onClick={()=>column.handleSort()}>{column.title}</Table.Heading>
                                    )
                                })}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                data && data.length > 0 && data.map((row, index) => {
                                    return (
                                        <Table.Row key={index}>
                                            {
                                                columns && columns.length > 0 && columns.map((col, ind) => {
                                                    return (
                                                        col.showHeading && <Table.Data>{col.hasOwnProperty('customCell') ? col.customCell(row, index) :  get(row, col.field, '--')}</Table.Data>
                                                    );
                                                })
                                            }
                                        </Table.Row>  
                                    )
                                    // Todo : Add tableRow to handle actions
                                })
                            }
                        </Table.Body>
                    </Table>
                </div> : 
                <div className="col-sm-12"> 
                    <div className="text-center requests-empty-state"> 
                        <div className="empty-state-text"> 
                            {localString('No Data Available')}
                        </div> 
                    </div> 
                </div>
            }
        </>
    );
}

export default CommonTable;