import React, { useEffect, useState } from 'react';
import SubmitIrisModalUI from './SubmitIrisModalUI';
import Swal from "sweetalert2";
import { findIndex } from 'lodash';
const SubmitIrisModalInputs=(props)=>{
    const {localString,selectInitIrisSymptom,irisSelection,setIrisSelection,selectedIrisActionFlag,setSelectedIrisActionFlag,symptomLoading,irisMap,submitIrisData
		,selectInitIrisFault,setSelectInitIrisFault,selectInitIrisAction,setSelectInitIrisAction,setSubmitIrisData,showIrisFlags,setShowIrisFlags,showLoader}=props;
	
	const addIris=()=>{
		let checkSameIris=false;
		let irisObject = {}
		let totalData =  irisSelection.length
		irisObject.id=totalData+1
		irisObject.Symptom = submitIrisData.selectSymptom
		irisObject.Fault =  submitIrisData.selectFault
		irisObject.Action = submitIrisData.selectAction
		irisSelection.map(item=>{
			if((item.Symptom==irisObject.Symptom) && (item.Fault==irisObject.Fault) && (item.Action==irisObject.Action) ){
				checkSameIris = true;
			}
		})
		if (checkSameIris) {
			Swal.fire ({title:'Sorry', text:localString('This IRIS has already been added.'),icon: 'error'});
			return;
		}else{
			const irisArray = [...irisSelection];
			irisArray.push(irisObject);
			setIrisSelection(irisArray);
			setShowIrisFlags({...showIrisFlags,
				selectedIrisSymptomFlag:true,
				selectedIrisFaultFlag:true
			})
			setSubmitIrisData({
				...submitIrisData,
				selectSymptom:"",
				selectFault :"",
				selectAction:""
			})
			setSelectedIrisActionFlag(true);
		}
	}
	
	const removeFromIris=(id)=>{
		let removIris=irisSelection && irisSelection.filter(item=>id !== item.id)
		setIrisSelection(removIris)
	}
	const initIrisFault=(val)=>{
		irisMap.filter(item=>{
			if(val.SymptomCode==item.SymptomCode || val.SymptomDescription==item.SymptomDescription) {
				let faultData=item.Faults.map(v=>({...v,value:`${v.FaultCode} - ${v.FaultDescription}`}))
				setSelectInitIrisFault(faultData);
				setSelectedIrisActionFlag(true);
			}
		})
	}

	const initIrisAction=(val)=>{
		selectInitIrisFault.filter(item=>{
			if(val.FaultCode == item.FaultCode){
				let faultAction=item.faultAction.map(v=>({...v,value:`${v.FaultActionCode} - ${v.Name}`}))
				setSelectInitIrisAction( faultAction);
				setSelectedIrisActionFlag(true);
			}
		})
	}
		
	let submiIrisInputs = [
		{
			labelText:`${localString('Select Symptom')} (I1)`,
			dataKey:"selectSymptom",
			showIris:true,
			showIrisLoader:symptomLoading,
			customClass:'marginB20 ',
			type :'Dropdown',
			dropdownList:selectInitIrisSymptom,
			oninputFunc: (val,index) => {
				initIrisFault(val);
				setShowIrisFlags({...showIrisFlags,selectedIrisSymptomFlag:false})
					setSubmitIrisData({...submitIrisData,
					['selectSymptom']:`${val.SymptomCode} - ${val.SymptomDescription}`,
				});
			}
		},
		{
			labelText:`${localString('Select Fault')} (I2)`,
			dataKey:"selectFault",
			showIris:true,
			customClass:showIrisFlags.selectedIrisSymptomFlag?'disable-input input-padding marginB20':'input-padding marginB20',
			type :'Dropdown',
			isDisabled: submitIrisData.selectSymptom ==""? showIrisFlags.selectedIrisSymptomFlag:"",
			dropdownList:selectInitIrisFault,
			oninputFunc: (val,index) => {
				initIrisAction(val);
				setShowIrisFlags({...setShowIrisFlags,selectedIrisFaultFlag:false})
				setSubmitIrisData({...submitIrisData,
					['selectFault']:`${val.FaultCode} - ${val.FaultDescription}`,
				});
			}
		},
		{
			labelText:`${localString('Select Action ')} (I3)`,
			dataKey:"selectAction",
			showIris:true,
			customClass:showIrisFlags.selectedIrisFaultFlag?'disable-input input-padding marginB20':'input-padding marginB20',
			type :'Dropdown',
			isDisabled:showIrisFlags.selectedIrisFaultFlag,
			dropdownList:selectInitIrisAction,
			oninputFunc: (val,index) => {
				initIrisAction(val);
				setSubmitIrisData({...submitIrisData,
					['selectAction']:`${val.FaultActionCode} - ${val.Name}`,
				});
				setSelectedIrisActionFlag(false);
			}
		},
	];

	const tableInput=[
		{labelText:`${localString("Symptom")} (I1)`},
		{labelText:`${localString("Fault")} (I2)`},
		{labelText:`${localString("Action")} (I3)`},
	]

    return(
        <>
			<div>
				<SubmitIrisModalUI {...props}
					submitIrisData={submitIrisData}
					submiIrisInputs={submiIrisInputs}
					addIris={addIris}
					tableInput={tableInput}
					irisSelection={irisSelection}
					removeFromIris={removeFromIris}
					selectedIrisActionFlag={selectedIrisActionFlag}
				/>
			</div>
        </>
    )
}
export default SubmitIrisModalInputs;