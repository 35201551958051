import React, { useEffect, useState } from 'react';
import CloseVisitModal from './CloseVisitModal';
import { isEmpty } from 'lodash';

const UnresolveVisitInput=(props)=>{
    const {localString,selectActionUnresolveVisitStatus,actionVisitUnresolveStatus,actionStatusSelected, setActionStatusSelected, visitData,setVisitData,selectVisitDispositionUnPro,visitDispositionSelectedFlag,setVisitDispositionSelectedFlag,setActionCode,
		pendingForSpares,updateJobDetails,jumpToIrisSubmission}=props;
	const[displayButton,setDisplayButton]=useState(true);
    
	useEffect(()=>{
		checkCompleteVisitButton();
	},[visitData])
	const checkCompleteVisitButton=()=>{
		if(visitData && !isEmpty(visitData.visitDispositionStatus) && visitData.visitDispositionStatus.length>0 &&
			!isEmpty(visitData.actionStatus) && visitData.actionStatus.length>0 && !isEmpty( visitData.visitText) && visitData.visitText.length>0
		){
			setDisplayButton(false)
		}else{
			setDisplayButton(true);
		}
	}

	const  requestStatus=(val)=>{
        setActionStatusSelected(val); 
    }
	let visitInputs = [
		{
			labelText:localString('Visit Disposition Status'),
			dataKey:"visitDispositionStatus",
			showVisit:true,
			customClass:'marginB20',
			type :'Dropdown',
			dropdownList:selectVisitDispositionUnPro,
			oninputFunc: (val) => {
				setVisitDispositionSelectedFlag({...visitDispositionSelectedFlag,unresolveVisitFlag:false});
                actionVisitUnresolveStatus(val)
				setVisitData({...visitData,
					['visitDispositionStatus']:val.VisitStatus,
				});
				
			}
		},
		{
			labelText:localString('Action Status'),
			dataKey:"actionStatus",
			showVisit:true,
			customClass: visitDispositionSelectedFlag.unresolveVisitFlag?'disable-input input-padding marginB20':'input-padding marginB20',
			type :'Dropdown',
			isDisabled: visitDispositionSelectedFlag.unresolveVisitFlag,
			dropdownList:selectActionUnresolveVisitStatus,
			oninputFunc: (val) => {
                requestStatus(val);
				// setVisitDispositionStatus(visitDispositionDataPro.Status)
				setVisitData({...visitData,
					['actionStatus']:val.ActionCode,
				});
				setActionCode(val.ActionCode)
				// switchFlows(val.ActionCode);
			}
		},
		{
			labelText:localString('Request Status'),
			type:'text',
			customClass:'marginB20',
			showVisit:true,
			text: actionStatusSelected && actionStatusSelected.Status ? actionStatusSelected.Status:'NA'
		},
		{
			labelText:localString('Remark'),
			type:'textarea',
			dataKey:'visitText',
			customClass:'marginB20',
			showVisit:true,
			inputComponent:'textarea' ,
			oninputFunc: (val) => {
				setVisitData({...visitData,
					['visitText']:val,
				});
			}
		},
	];

	let buttonInput=[
		{
			showUnresolveVisit:true,
			labelText:!pendingForSpares ? localString("Complete Visit") :localString("Next"),
			displayButton:displayButton,
			onButtonFun:!pendingForSpares ? updateJobDetails :jumpToIrisSubmission
		},
		
	]
    return(
        <>
            <CloseVisitModal
                {...props}
				buttonInput={buttonInput}
                visitInputs={visitInputs}
				visitData={visitData}
				showBackButton={true}
            />
        </>
    )
}
export default  UnresolveVisitInput;