import React, { useRef } from 'react';
import { Button, Input } from 'sfy-react';
import Swal from "sweetalert2"; 

import { generateUUID, validateAttachments } from '../../../Utility/Utils';


const Content = (props) => {
	const { accessFn, localString, apiCall, content, setContent, expiryTime, setExpiryTime, file, setFile, hiddenFileInput, setLoader, saveNotificationData } = props;
	const maxTitlelength = 70;
	const maxMessagelength = 300;
	const validExtensions = ['.jpeg', '.png', '.pdf', '.mp4'];
	const maxFileSizeLimit = '10000000';

	const uploadFile = () => {
		if(validateAttachments(validExtensions, maxFileSizeLimit, file)) {
			let ext = file.type.split('/')[1];
			let key = generateUUID() + '.' + ext;
			let reqObj = {
				Type: file.type, 
				Key: key
			};
			setLoader(true);
			apiCall({
				url:'uploadDocuments',
				data: reqObj,
				callBack: (result) => {
					// setLoader(false); 
					if(result.success){
						let url = result.data;
						let attachmentLink = result.data.split('?')[0];
						let fileType = file.type.split('/')[0];
						setContent({ ...content, Attachment: attachmentLink, Type: fileType});
						fetch(url, {
							method: 'PUT',
							headers: {
								'Cache-Control': 'public,max-age=3600',
								'x-amz-acl': 'public-read'
							},
							body: file
						})
						.then(res => {
							if(res.status === 200) {
								saveNotificationData(2, attachmentLink, fileType);
							}
						})
					}
				}
			});
		}else {
			Swal.fire({  
				icon: 'error',  
				text: localString('File should be in .jpeg, .png, .pdf, .mp4 maximum of 10 MB'),  
			});
		}
	}

	return (
		<>
			<div className='container announcementContent'>
				<div className='container padding15'>
					<div className='row justify-content-center'>
						<div className='col-sm-3 announceLabel'>
							<div>{localString('Title')}</div>
						</div>
						<div className='col-sm-8 textBox'>
							<Input
								value={content.Title}
								handleOnChange={(e) => {
									setContent({ ...content, Title: e.target.value });
								}}
								className={{
									inputContainer: 'announceInputBox'
								}}
								maxLength={maxTitlelength}
							/>
							<span className="pull-right">{maxTitlelength - content.Title.length}</span>
						</div>
					</div>
				</div>
				<div className='container padding15'>
					<div className='row'>
						<div className='col-sm-3 announceLabel'>
							<div>{localString('Message')}</div>
						</div>
						<div className='col-sm-8 textBox'>
							<Input
								value={content.Message}
								handleOnChange={(e) => {
									setContent({ ...content, Message: e.target.value });
								}}
								inputComponent='textarea'
								className={{
									inputContainer: 'announceTextarea'
								}}
								maxLength={maxMessagelength}
							/>
							<span className="pull-right">{maxMessagelength - content.Message.length}</span>
						</div>
					</div>
				</div>
				{ !accessFn('ENGINEER_NOTIFICATION') && 
					<div className='container padding15'>
						<div className='row'>
							<div className='col-sm-3 announceLabel'>
								<div>{localString('Notification Expiry Duration (in days)')}</div>
							</div>
							<div className='col-sm-8 textBox'>
								<Input
									value={expiryTime}
									handleOnChange={(e) => {
										setExpiryTime(e.target.value);
									}}
									className={{
										inputContainer: 'announceInputBox'
									}}
									minLength={1}
									maxLength={2}
									inputType='number'
								/>
							</div>
						</div>
					</div>
				}
				<div className='container padding15'>
					<div className='row'>
						<div className='col-sm-8 col-sm-offset-3 textBox'>
							<div className="dragandrophandler" onClick={() => hiddenFileInput.current.click()}>
								<div className="text-center">
									{localString('Click here to upload file')}
								</div>
								<div className="text-center info-no-padding">
									{localString('(File should be in .jpeg, .png, .pdf, .mp4 maximum of 10 MB)')}
								</div>
								<input
									type="file"
									ref={hiddenFileInput}
									name="attachment"
									style={{ display: 'none' }} 
									onChange={(e) => setFile(e.target.files[0])}
									accept=".png, .jpg, .pdf, .mp4"
								/>
							</div>
							{
								file && file.name && 
									<>
										<div>{localString('uploaded file')}</div>
										<span>{file && file.name && file.name}</span>
									</>
							}
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12" style={{paddingBottom: '15px'}}>
				<div>
					<Button
						type="button"
						className="btn button-servify pull-right button-margin"
						onClick={() => {
							// Todo need to handle validations
							if(content.Title && content.Message && expiryTime) {
								file ? uploadFile() : saveNotificationData(2);
							} else {
								Swal.fire({  
									icon: 'error',  
									text: localString('Title, Message and Duration cannot be blank.'),  
								});  
							}
						}}
					>
						{localString('Next')}
					</Button>
				</div>
			</div>
		</>
	);
}

export default Content;