import React,{useState,useEffect} from 'react';
import {Table,Loader} from 'sfy-react';
import ReactDOM from 'react-dom';
import Swal from "sweetalert2";
import moment from 'moment';
import {displayError,getDateFormat,returnLocalSlotWithMin} from '../../../../../Utility/Utils'
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';
import './VisitDetails.scss'

const VisitDetails = (props) => {
    const {apiCall,defaultCountryLocale,consumerServiceRequest,localString} = props;
    const [loader, setLoader] = useState(false)
    const [visitDetails, setVisitDetails] = useState([])

    useEffect(() => {
        getVisitDetails()
    }, [])

    const classObject = {
		table:"table table-striped complaint-details-table"
	}

    const getVisitDetails = () => {
        let data = {
            "ConsumerServiceRequestID": consumerServiceRequest && consumerServiceRequest.ConsumerServiceRequestID,
        }
        setLoader(true);
        apiCall({
            url:'getEngineerVisits',
            data: data,
            callBack:(result)=>{
                setLoader(false);
                if(result.success){
                    setVisitDetails(result.data)
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: localString(displayError(result)),
                    });
                }
            }
        });
    }

    return (
        <>
            { loader && ReactDOM.createPortal(
                <Loader />,
                document.getElementById("loader-root")
            )}
            <div className="inner-padding">
                <div className="row">
                    <PageContentWrapper title={localString("Visit Details")}>
                    <div className="request-panel request-complaint-div visit-details-tab">
                        {
                            visitDetails && visitDetails.length > 0 && visitDetails.map((key,value)=>{
                                return(
                                    <>
                                        <div className="visit-number">{localString("Visit ")}{visitDetails.length-value}</div>
                                        <Table className={classObject}>
                                            <Table.Body>
                                                {
                                                    key.ActualDateTime && 
                                                    <Table.Row>
                                                        <Table.Data className= "table-keys text-capitalize">{localString("Visit Date")}:</Table.Data>
                                                        <Table.Data>
                                                            {key.ActualDateTime ? moment(key.ActualDateTime, 'YYYY-MM-DDTHH:mm:ssZ').format(getDateFormat(defaultCountryLocale)):localString("NA")}
                                                        </Table.Data>
                                                    </Table.Row>
                                                }
                                                {
                                                    (key.ActualFromTime || key.LocalScheduledFromTime) && 
                                                    <Table.Row>
                                                        <Table.Data className= "table-keys text-capitalize">{localString("Visit Start Time")}:</Table.Data>
                                                        <Table.Data>
                                                            {key.LocalScheduledFromTime ? key.LocalScheduledFromTime : moment.utc(key.ActualFromTime, 'HH:mm:ssZ').utcOffset('+0530').format('hh:mm a')}
                                                        </Table.Data>
                                                    </Table.Row>
                                                }
                                                {
                                                    (key.ActualToTime || key.LocalScheduledToTime) && 
                                                    <Table.Row>
                                                        <Table.Data className= "table-keys text-capitalize">{localString("Visit End Time")}:</Table.Data>
                                                        <Table.Data>
                                                            {(key.LocalScheduledFromTime && key.LocalScheduledToTime) ? key.LocalScheduledFromTime + ' - ' + key.LocalScheduledToTime : (key.ActualFromTime && key.ActualToTime) ? returnLocalSlotWithMin (key.ActualFromTime) + ' - ' + returnLocalSlotWithMin(key.ActualToTime): localString("NA")}
                                                        </Table.Data>
                                                    </Table.Row>
                                                }
                                                {
                                                    <Table.Row>
                                                        <Table.Data className= "table-keys text-capitalize">{localString("Engineer Name")}:</Table.Data>
                                                        <Table.Data>
                                                            {key.engineer && key.engineer.Name?key.engineer.Name:localString("NA")}
                                                        </Table.Data>
                                                    </Table.Row>
                                                }
                                                {
                                                    <Table.Row>
                                                        <Table.Data className= "table-keys text-capitalize">{localString("Mobile No")}:</Table.Data>
                                                        <Table.Data>
                                                            {key.engineer && key.engineer.MobileNo?key.engineer.MobileNo:localString("NA")}
                                                        </Table.Data>
                                                    </Table.Row>
                                                }
                                                {
                                                    key.visitDisposition && key.visitDisposition.ActionCode && 
                                                    <Table.Row>
                                                        <Table.Data className= "table-keys text-capitalize">{localString("Action Code")}:</Table.Data>
                                                        <Table.Data>
                                                            {key.visitDisposition && key.visitDisposition.ActionCode?key.visitDisposition.ActionCode:localString("NA")}
                                                        </Table.Data>
                                                    </Table.Row>
                                                }
                                                {
                                                    key.visitDisposition && key.visitDisposition.VisitStatus && 
                                                    <Table.Row>
                                                        <Table.Data className= "table-keys text-capitalize">{localString("Visit Status")}:</Table.Data>
                                                        <Table.Data>
                                                            {key.visitDisposition && key.visitDisposition.VisitStatus?key.visitDisposition.VisitStatus:localString("NA")}
                                                        </Table.Data>
                                                    </Table.Row>
                                                }
                                                {
                                                    <Table.Row>
                                                        <Table.Data className= "table-keys text-capitalize">{localString("Remarks")}:</Table.Data>
                                                        <Table.Data>
                                                            {key.Remarks?key.Remarks:'-'}
                                                        </Table.Data>
                                                    </Table.Row>
                                                }
                                            </Table.Body>
                                        </Table>
                                    </>
                                )
                            })
                        }
                    </div>
                    {
                        visitDetails && visitDetails.length == 0 && 
                        <div className = "no-visits">
                            <p className="charges-panel-subheader text-capitalize remove-margin">{localString("There are no visits for this request.")}</p>
                        </div>
                    }
                    </PageContentWrapper>
                </div>
            </div>
        </>
    )
}

export default VisitDetails
