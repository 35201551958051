import React from 'react';
import {Table} from 'sfy-react';
import PageContentWrapper from '../../../../../components/PageContentWrapper/PageContentWrapper';

const RequestConsumerDetails = (props) => {
    const {localString, consumerDetails, consumerServiceRequest} = props;

    const classObject = {
        table:"table table-striped complaint-details-table"
    }

    return (
        <div>
            {consumerDetails && consumerServiceRequest && (
                <Table className={classObject}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Data className="table-keys text-capitalize">
                                {localString("Name")}
                            </Table.Data>
                            <Table.Data>
                                {consumerDetails.Name || '-'}
                            </Table.Data>
                        </Table.Row>
                        <Table.Row>
                            <Table.Data className="table-keys text-capitalize">
                                {localString("Mobile Number")}
                            </Table.Data>
                            <Table.Data>
                                {consumerServiceRequest && consumerServiceRequest.consumer && consumerServiceRequest.consumer.CountryCode ? '('+ consumerServiceRequest.consumer.CountryCode +')' : ''} {consumerDetails.MobileNo || '-'} 
                            </Table.Data>
                        </Table.Row>
                        <Table.Row>
                            <Table.Data className="table-keys text-capitalize">
                                {localString("Alternate Mobile No")}
                            </Table.Data>
                            <Table.Data>
                                {consumerDetails.AlternateMobileNo || '-'}
                            </Table.Data>
                        </Table.Row>
                        {
                            consumerServiceRequest && consumerServiceRequest.Source == "SkyworthDashboard" && 
                            <Table.Row>
                                <Table.Data className="table-keys text-capitalize">
                                    {localString("Source Channel")}
                                </Table.Data>
                                <Table.Data>
                                    {consumerServiceRequest.SourceChannel || '-'}
                                </Table.Data>
                            </Table.Row>
                        }
                        {
                            consumerServiceRequest && consumerServiceRequest.Source == "SkyworthDashboard" && 
                            <Table.Row>
                                <Table.Data className="table-keys text-capitalize">
                                    {localString("Customer Type")}
                                </Table.Data>
                                <Table.Data>
                                    {consumerServiceRequest.consumerSegment && consumerServiceRequest.consumerSegment.SegmentType ? consumerServiceRequest.consumerSegment.SegmentType : '-'}
                                </Table.Data>
                            </Table.Row>
                        }
                        {
                            consumerDetails && consumerDetails.CLINumber && 
                            <Table.Row>
                                <Table.Data className="table-keys text-capitalize">
                                    {localString("CLI No")}
                                </Table.Data>
                                <Table.Data>
                                    {consumerDetails.CLINumber || '-'}
                                </Table.Data>
                            </Table.Row>
                        }
                        <Table.Row>
                            <Table.Data className="table-keys text-capitalize">
                                {localString("Email ID")}
                            </Table.Data>
                            <Table.Data>
                                {consumerDetails.EmailID || '-'}
                            </Table.Data>
                        </Table.Row>
                        <Table.Row>
                            <Table.Data className="table-keys text-capitalize">
                                {localString("Communication Name")}
                            </Table.Data>
                            <Table.Data>
                                {consumerDetails.SecondaryContactDetails && consumerDetails.SecondaryContactDetails.ConsumerName || consumerDetails.Name || '-'}
                            </Table.Data>
                        </Table.Row>
                        <Table.Row>
                            <Table.Data className="table-keys text-capitalize">
                                {localString("Communication Number")}
                            </Table.Data>
                            {consumerDetails && consumerDetails.SecondaryContactDetails && consumerDetails.SecondaryContactDetails.ConsumerMobileNo ? 
                            <Table.Data>{consumerDetails && consumerDetails.SecondaryContactDetails && consumerDetails.SecondaryContactDetails.PhoneCode ? '('+ consumerDetails.SecondaryContactDetails.PhoneCode +')' : ''} {consumerDetails.SecondaryContactDetails.ConsumerMobileNo || '-'} </Table.Data>  :
                            <Table.Data>{consumerServiceRequest && consumerServiceRequest.consumer && consumerServiceRequest.consumer.CountryCode ? '('+ consumerServiceRequest.consumer.CountryCode +')' : ''} {consumerDetails.MobileNo || '-'}</Table.Data>
                            }
                        </Table.Row>
                        <Table.Row>
                            <Table.Data className="table-keys text-capitalize">
                                {localString("Communication EmailID")}
                            </Table.Data>
                            <Table.Data>
                                {consumerDetails.SecondaryContactDetails && consumerDetails.SecondaryContactDetails.ConsumerEmailID || consumerDetails.EmailID  || '-'}
                            </Table.Data>
                        </Table.Row>
                        <Table.Row>
                            <Table.Data className="table-keys text-capitalize">
                                {localString("First Registered From")}
                            </Table.Data>
                            <Table.Data>
                                {consumerDetails.FirstRegisteredFrom || '-'}
                            </Table.Data>
                        </Table.Row>
                    </Table.Body>
                </Table>
            )}
        </div>
    )
}

export default RequestConsumerDetails
