
import React,{useState, useEffect}  from 'react';
import FilterInputs from '../../../components/FilterInputs/FilterInput';
import {getLocalDateTime} from '../../../Utility/Utils';
import moment from 'moment';
import './CreateLogistics.scss';

const OtherDetailsInput = (props) => {
	const {localString,setOtherDetailsLogisticsData,otherDetailsLogisticsData,qFilter,apiCall,serviceProvidersData} = props;
	const [disableProvider,setDisableProvider] = useState(true);
	const [providersList,setProvidersList] = useState([]);
   
	let OtherInput = [
		{
			labelText: localString("Invoice Date"),
			dataKey: "InvoiceDate",
			showFilter: true,
			type:"DatePicker",
			placeholder:"Invoice Date",
			dropdownList:[],    
			maxDay:moment().toDate(),
			oninputFunc: (val) => {
				setOtherDetailsLogisticsData((prevData) => {
					return {
						...prevData,
						InvoiceDate: (getLocalDateTime(val,'YYYY-MM-DDTHH:mm:ss')+'+00:00'),
					};
				});
			},
		},
		{
			labelText: localString("Flow"),
			dataKey: "FlowLogisticsServiceTypeName",
			showFilter: true,
			type :'Dropdown',
			placeholder:"Flow",
			dropdownList:serviceProvidersData,
			oninputFunc: (val) => {
				setOtherDetailsLogisticsData((prevData) => {
					return {
						...prevData,
						FlowLogisticsServiceTypeName: val.LogisticsServiceTypeName,
						FlowLogisticsServiceType:val.LogisticsServiceType,
						Provider:""
					};
				});
				setProvidersList(val.VendorList.map(v => ({...v, value: v.DisplayName})));
				setDisableProvider(false);
			},
		},
        {
			labelText: localString("Reference Number"),
			dataKey: "Reference",
			showFilter: true,
			placeholder:"Reference Number",
			oninputFunc: (val) => {
				setOtherDetailsLogisticsData((prevData) => {
					return {
						...prevData,
						Reference: val
					};
				});
			},
		},
        {
			labelText: localString("Provider"),
			dataKey: "ProviderDisplayName",
			showFilter: true,
			type :'Dropdown',
			dropdownList:providersList,
			placeholder:"Provider",
			isDisabled:disableProvider,
			oninputFunc: (val) => {
				setOtherDetailsLogisticsData((prevData) => {
					return {
						...prevData,
						ProviderVendorID: val.VendorID,
						ProviderDisplayName:val.DisplayName
					};
				});
			},
		},
	];
	
	return (
		<>
		<div className="padding12">
			<h4>{localString("Other Detail")}</h4>
			<div class="bottom-divider clearfix">
				<FilterInputs {...props} filterInputs={OtherInput} filterData={otherDetailsLogisticsData} hideSaveButton={true} hideClearButton=
				{true}></FilterInputs>
			</div>
		</div>
		</>
	) 
}

export default OtherDetailsInput;