const lang = {
	"Not charging":{
		"text":"ชาร์จไม่เข้า",
		"isAlreadyTranslated": true
	},
	"Advance amount can't be updated at Present status":{
		"text":"ไม่สามารถอัปเดตจำนวนเงินล่วงหน้าได้ในสถานะปัจจุบัน",
		"isAlreadyTranslated": true
	},
	"Already booked":{
		"text":"จองแล้ว",
		"isAlreadyTranslated": true
	},
	"Request cannot be cancelled right now. Please try again later":{
		"text":"ไม่สามารถยกเลิกคำร้องขอได้ในขณะนี้ โปรดลองอีกครั้งภายหลัง",
		"isAlreadyTranslated": true
	},
	"No consumer found":{
		"text":"ไม่พบผู้ใช้",
		"isAlreadyTranslated": true
	},
	"No pincode found":{
		"text":"ไม่พบรหัส PIN",
		"isAlreadyTranslated": true
	},
	"no product details found":{
		"text":"ไม่พบรายละเอียดเกี่ยวกับผลิตภัณฑ์",
		"isAlreadyTranslated": true
	},
	"No Logistics Detail Found":{
		"text":"ไม่พบรายละเอียดเกี่ยวกับโลจิสติกส์",
		"isAlreadyTranslated": true
	},
	"Customer Pincode is not serviceable by Our Logistics Partner. Please send the device to the Customer using other Courier Services near you":{
		"text":"รหัส PIN ของลูกค้าไม่สามารถให้บริการได้โดยคู่ค้าโลจิสติกส์ของเรา โปรดส่งอุปกรณ์ให้กับลูกค้าโดยใช้บริการจัดส่งอื่นๆ ที่อยู่ในพื้นที่ใกล้เคียง",
		"isAlreadyTranslated": true
	},
	"Pincode not found":{
		"text":"ไม่พบรหัส PIN",
		"isAlreadyTranslated": true
	},
	"Oops. Looks like no service center found":{
		"text":"โอ๊ะ ดูเหมือนว่าจะไม่พบศูนย์บริการ",
		"isAlreadyTranslated": true
	},
	"Oops. Looks like none of our engineers are present to service this product in your area. But we’re hard at work and you should get some good news soon. Try later?":{
		"text":"โอ๊ะ ดูเหมือนว่าผลิตภัณฑ์นี้จะไม่มีวิศวกรที่พร้อมให้บริการได้ในพื้นที่ของคุณ แต่เรากำลังพยายามแก้ปัญหาอยู่ และคุณน่าจะได้รับข่าวดีในเร็วๆ นี้ ลองอีกครั้งในภายหลัง?",
		"isAlreadyTranslated": true
	},
	"Serial number validation failed":{
		"text":"การยืนยันความถูกต้องของเลขประจำเครื่องไม่ผ่าน",
		"isAlreadyTranslated": true
	},
	"Please provide valid Partner Service Location ID":{
		"text":"โปรดระบุ ID ตำแหน่งการบริการของคู่ค้าที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Please close other service":{
		"text":"โปรดปิดบริการอื่น",
		"isAlreadyTranslated": true
	},
	"Could not schedule your request. Please try again after some time.":{
		"text":"ไม่สามารถกำหนดเวลาคำร้องขอของคุณได้ โปรดลองอีกครั้งหลังจากผ่านไปสักพักหนึ่ง",
		"isAlreadyTranslated": true
	},
	"Request created successfully":{
		"text":"ได้สร้างคำร้องขอสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"No consumer product found with given consumer product id":{
		"text":"ไม่พบผลิตภัณฑ์ของผู้ใช้ที่มี id ของผลิตภัณฑ์ของผู้ใช้ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"Could not schedule your request.":{
		"text":"ไม่สามารถกำหนดเวลาคำร้องขอของคุณได้",
		"isAlreadyTranslated": true
	},
	"No issue found":{
		"text":"ไม่พบปัญหาใดๆ",
		"isAlreadyTranslated": true
	},
	"No delivery mode found":{
		"text":"ไม่พบโหมดการจัดส่ง",
		"isAlreadyTranslated": true
	},
	"error occurred":{
		"text":"เกิดข้อผิดพลาดขึ้น",
		"isAlreadyTranslated": true
	},
	"Dispatch initiated":{
		"text":"เริ่มการส่งแล้ว",
		"isAlreadyTranslated": true
	},
	"Please Provide Valid RequestID":{
		"text":"โปรดระบุ 'IDคำร้องขอ' ที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"No slot available":{
		"text":"ไม่มีช่วงเวลา",
		"isAlreadyTranslated": true
	},
	"The request is not at the required status for the given action: Generate vouchers first":{
		"text":"คำร้องขอไม่อยู่ในสถานะที่ต้องการสำหรับการดำเนินการที่ให้: สร้างคูปองก่อน",
		"isAlreadyTranslated": true
	},
	"Your iPhone has been eRecycled. Ref ID:":{
		"text":"iPhone ของคุณได้รับการ eRecycle แล้ว ID อ้างอิง:",
		"isAlreadyTranslated": true
	},
	"No result found":{
		"text":"ไม่พบผลการค้นหา",
		"isAlreadyTranslated": true
	},
	"No need to create bulk shipment":{
		"text":"ไม่จำเป็นต้องสร้างการจัดส่งปริมาณมาก",
		"isAlreadyTranslated": true
	},
	"No address found":{
		"text":"ไม่พบที่อยู่",
		"isAlreadyTranslated": true
	},
	"No need to create pick up request":{
		"text":"ไม่จำเป็นต้องสร้างคำร้องขอการรับ",
		"isAlreadyTranslated": true
	},
	"No status found":{
		"text":"ไม่พบสถานะ",
		"isAlreadyTranslated": true
	},
	"Insufficient Parameters - Either wayBillNo or ConsumerServiceRequestID is required":{
		"text":"พารามิเตอร์ไม่เพียงพอ - ต้องมี 'เลขใบนำส่งสินค้า' หรือ 'IDคำร้องขอการบริการผู้ใช้'",
		"isAlreadyTranslated": true
	},
	"Error in creating entry in ConsumerServicerequestLogistics":{
		"text":"เกิดข้อผิดพลาดในการสร้างรายการใน 'โลจิสติกส์คำร้องขอการบริการผู้ใช้'",
		"isAlreadyTranslated": true
	},
	"Success in creating entry in ConsumerServicerequestLogistics":{
		"text":"สร้างรายการใน 'โลจิสติกส์คำร้องขอการบริการผู้ใช้' สำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"No request found with given refid":{
		"text":"ไม่พบคำร้องขอที่มี 'idอ้างอิง' ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"No Result Found With Given RequestID":{
		"text":"ไม่พบผลลัพธ์ที่มี 'IDคำร้องขอ' ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"No Result Found With Given LogisticsRequestID":{
		"text":"ไม่พบผลลัพธ์ที่มี 'IDคำร้องขอโลจิสติกส์' ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"Error in finding ConsumerServicerequestLogistics for":{
		"text":"เกิดข้อผิดพลาดในการค้นหา 'โลจิสติกส์คำร้องขอการบริการผู้ใช้' สำหรับ",
		"isAlreadyTranslated": true
	},
	"Error in updating remarks":{
		"text":"เกิดข้อผิดพลาดในการอัปเดตหมายเหตุ",
		"isAlreadyTranslated": true
	},
	"successfully request created":{
		"text":"ได้สร้างคำร้องขอสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"no ref id found":{
		"text":"ไม่พบ id อ้างอิง",
		"isAlreadyTranslated": true
	},
	"no ConsumerServicerequestLogistics found with LogisticsRequestID":{
		"text":"ไม่พบ 'โลจิสติกส์คำร้องขอการบริการผู้ใช้' ที่มี 'IDคำร้องขอโลจิสติกส์'",
		"isAlreadyTranslated": true
	},
	"no data found for":{
		"text":"ไม่พบข้อมูลสำหรับ",
		"isAlreadyTranslated": true
	},
	"Error in fetching data for":{
		"text":"เกิดข้อผิดพลาดในการดึงข้อมูลสำหรับ",
		"isAlreadyTranslated": true
	},
	"no logistics request detail found for id":{
		"text":"ไม่พบรายละเอียดคำร้องขอโลจิสติกส์สำหรับ id",
		"isAlreadyTranslated": true
	},
	"Your phone's pick up has been scheduled":{
		"text":"การเข้าไปรับโทรศัพท์ของคุณได้รับการกำหนดเวลาแล้ว",
		"isAlreadyTranslated": true
	},
	"No Current Location Found For":{
		"text":"ไม่พบตำแหน่งปัจจุบันสำหรับ",
		"isAlreadyTranslated": true
	},
	"notification sent":{
		"text":"ส่งการแจ้งเตือนแล้ว",
		"isAlreadyTranslated": true
	},
	"device not supported":{
		"text":"อุปกรณ์ไม่รองรับ",
		"isAlreadyTranslated": true
	},
	"cannot find consumer":{
		"text":"ไม่สามารถค้นหาผู้ใช้ได้",
		"isAlreadyTranslated": true
	},
	"Something went wrong while An email blast was attempted for this list of consumers":{
		"text":"มีความผิดปกติเกิดขึ้นขณะมีการพยายามรวมส่งอีเมลตามรายการผู้ใช้นี้",
		"isAlreadyTranslated": true
	},
	"Something went wrong while sendOneEmail":{
		"text":"มีความผิดปกติเกิดขึ้นขณะ 'ส่งอีเมลหนึ่งฉบับ'",
		"isAlreadyTranslated": true
	},
	"Something went wrong while sending notifications to this list of consumers and temp consumers":{
		"text":"มีความผิดปกติเกิดขึ้นขณะส่งการแจ้งเตือนไปยังรายการผู้ใช้นี้และผู้ใช้ชั่วคราว",
		"isAlreadyTranslated": true
	},
	"Notification sent to this list of consumers and temp consumers":{
		"text":"ส่งการแจ้งเตือนไปยังรายการผู้ใช้นี้และผู้ใช้ชั่วคราวแล้ว",
		"isAlreadyTranslated": true
	},
	"Something went wrong while An SMS blast was attempted for this list of consumers":{
		"text":"มีความผิดปกติเกิดขึ้นขณะมีการพยายามรวมส่ง SMS ตามรายการผู้ใช้นี้",
		"isAlreadyTranslated": true
	},
	"An SMS blast was attempted for this list of consumers":{
		"text":"มีการพยายามรวมส่ง SMS ตามรายการผู้ใช้นี้",
		"isAlreadyTranslated": true
	},
	"Something went wrong while sending sms":{
		"text":"มีความผิดปกติเกิดขึ้นขณะส่ง sms",
		"isAlreadyTranslated": true
	},
	"Payment received":{
		"text":"ได้รับการชำระเงินแล้ว",
		"isAlreadyTranslated": true
	},
	"payment initialized":{
		"text":"การชำระเงินเริ่มดำเนินการแล้ว",
		"isAlreadyTranslated": true
	},
	"payment done":{
		"text":"การชำระเงินเสร็จสิ้น",
		"isAlreadyTranslated": true
	},
	"No payment occurred":{
		"text":"ไม่มีการชำระเงินเกิดขึ้น",
		"isAlreadyTranslated": true
	},
	"cannot find a partner":{
		"text":"ไม่สามารถค้นหาคู่ค้าได้",
		"isAlreadyTranslated": true
	},
	"fill servicingly table creation":{
		"text":"fill servicingly table creation",
		"isAlreadyTranslated": true
	},
	"Please provide correct data":{
		"text":"โปรดระบุข้อมูลที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Payment is not received":{
		"text":"ไม่ได้รับการชำระเงิน",
		"isAlreadyTranslated": true
	},
	"Your payment is received":{
		"text":"ได้รับการชำระเงินของคุณแล้ว",
		"isAlreadyTranslated": true
	},
	"Amount to be paid does not match":{
		"text":"จำนวนเงินที่ชำระไม่ตรงกัน",
		"isAlreadyTranslated": true
	},
	"Partner billing update failed":{
		"text":"การอัปเดตการเรียกเก็บเงินของคู่ค้าไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Payment received successfully":{
		"text":"ได้รับการชำระเงินเรียบร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"Invalid Device Details":{
		"text":"รายละเอียดอุปกรณ์ไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send either ConsumerServiceRequestID or PlanArray":{
		"text":"พารามิเตอร์ไม่เพียงพอ - ส่ง 'IDคำร้องขอการบริการผู้ใช้' หรือ 'อาร์เรย์แผน'",
		"isAlreadyTranslated": true
	},
	"Plan Purchased":{
		"text":"ซื้อแผนแล้ว",
		"isAlreadyTranslated": true
	},
	"( ) has been successfully Purchased and Activated for your device":{
		"text":"ได้ซื้อและเปิดใช้งาน ( ) สำหรับอุปกรณ์ของคุณสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"( ) has been successfully Purchased for your device":{
		"text":"ได้ซื้อ ( ) สำหรับอุปกรณ์ของคุณสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Advance payment captured":{
		"text":"จับภาพการชำระเงินล่วงหน้าแล้ว",
		"isAlreadyTranslated": true
	},
	"Error occured in capturing advance payment":{
		"text":"เกิดข้อผิดพลาดขึ้นในการจับภาพการชำระเงินล่วงหน้า",
		"isAlreadyTranslated": true
	},
	"We could not suggest an appropriate engineer":{
		"text":"เราไม่สามารถแนะนำวิศวกรที่เหมาะสมได้",
		"isAlreadyTranslated": true
	},
	"Already booked on this date and slot":{
		"text":"ได้จองวันที่และช่วงเวลานี้ไว้แล้ว",
		"isAlreadyTranslated": true
	},
	"Your service request is Rescheduled":{
		"text":"คำร้องขอการบริการของคุณได้รับการกำหนดเวลาใหม่",
		"isAlreadyTranslated": true
	},
	"Xpert is not assigned to this service":{
		"text":"Xpert ไม่ได้รับมอบหมายให้กับการบริการนี้",
		"isAlreadyTranslated": true
	},
	"Service request in not at required stage":{
		"text":"คำร้องขอการบริการไม่อยู่ที่ขั้นที่ต้องการ",
		"isAlreadyTranslated": true
	},
	"Service request not found":{
		"text":"ไม่พบคำร้องขอการบริการ",
		"isAlreadyTranslated": true
	},
	"Engineer not found":{
		"text":"ไม่พบวิศวกร",
		"isAlreadyTranslated": true
	},
	"No hub found":{
		"text":"ไม่พบฮับ",
		"isAlreadyTranslated": true
	},
	"parts are available":{
		"text":"มีชิ้นส่วนพร้อมให้บริการ",
		"isAlreadyTranslated": true
	},
	"Not all parts are available":{
		"text":"มีชิ้นส่วนที่พร้อมให้บริการไม่ครบทั้งหมด",
		"isAlreadyTranslated": true
	},
	"Inventory updated":{
		"text":"อัปเดตสินค้าคงคลังแล้ว",
		"isAlreadyTranslated": true
	},
	"No inventory updation":{
		"text":"ไม่มีการอัปเดตสินค้าคงคลัง",
		"isAlreadyTranslated": true
	},
	"replaced parts":{
		"text":"ชิ้นส่วนที่เปลี่ยน",
		"isAlreadyTranslated": true
	},
	"No parts found":{
		"text":"ไม่พบชิ้นส่วน",
		"isAlreadyTranslated": true
	},
	"mac verified successfully":{
		"text":"ยืนยัน mac สำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"payment done":{
		"text":"การชำระเงินเสร็จสิ้น",
		"isAlreadyTranslated": true
	},
	"Payment cannot be captured":{
		"text":"ไม่สามารถจับภาพการชำระเงินได้",
		"isAlreadyTranslated": true
	},
	"Authentication failed":{
		"text":"การตรวจสอบความถูกต้องล้มเหลว",
		"isAlreadyTranslated": true
	},
	"Payment already captured":{
		"text":"มีการจับภาพการชำระเงินไว้แล้ว",
		"isAlreadyTranslated": true
	},
	"Oops. We don’t service this device at the moment. But we’re hard at work and you should get some good news soon. Try later?":{
		"text":"โอ๊ะ เราไม่ให้บริการอุปกรณ์เครื่องนี้ในขณะนี้ แต่เรากำลังพยายามแก้ปัญหาอยู่ และคุณน่าจะได้รับข่าวดีในเร็วๆ นี้ ลองอีกครั้งในภายหลัง?",
		"isAlreadyTranslated": true
	},
	"Something went wrong in fetching from db":{
		"text":"มีความผิดปกติเกิดขึ้นในการดึงข้อมูลจาก db",
		"isAlreadyTranslated": true
	},
	"No FAQ Category Data Found":{
		"text":"ไม่พบข้อมูลหมวดหมู่ของคำถามที่พบบ่อย",
		"isAlreadyTranslated": true
	},
	"Error in Fetching FAQ":{
		"text":"เกิดข้อผิดพลาดในการดึงข้อมูลคำถามที่พบบ่อย",
		"isAlreadyTranslated": true
	},
	"Please Provide Valid Category ID":{
		"text":"โปรดระบุ ID หมวดหมู่ที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Cannot create recycle request":{
		"text":"ไม่สามารถสร้างคำร้องขอการรีไซเคิล",
		"isAlreadyTranslated": true
	},
	"Your Pickup Request For eRecycling Your iPhone Has Been Accepted | Ref. ID:":{
		"text":"คำร้องขอการรับอุปกรณ์เพื่อการ eRecycle เครื่อง iPhone ของคุณได้รับการตอบรับแล้ว | ID อ้างอิง:",
		"isAlreadyTranslated": true
	},
	"Welcome to eRecycle For iPhones | Ref. ID:":{
		"text":"ยินดีต้อนรับสู่การ eRecycle สำหรับ iPhone | ID อ้างอิง:",
		"isAlreadyTranslated": true
	},
	"Your Friend Has Chosen You as The Next Green Warrior!":{
		"text":"เพื่อนของคุณได้เลือกคุณเป็น Green Warrior คนถัดไป!",
		"isAlreadyTranslated": true
	},
	"No logistics action need to be done":{
		"text":"ไม่จำเป็นต้องดำเนินการด้านโลจิสติกส์",
		"isAlreadyTranslated": true
	},
	"Couldn't find a consumer product with the given ID":{
		"text":"ไม่พบผลิตภัณฑ์ของผู้ใช้ที่มี ID ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"No consumer service request found by the given ID.":{
		"text":"ไม่พบคำร้องขอการบริการผู้ใช้จาก ID ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"You Have Been Acknowledged as a True Green Warrior!":{
		"text":"คุณได้รับการยอมรับแล้วว่าเป็น Green Warrior ที่แท้จริง!",
		"isAlreadyTranslated": true
	},
	"Congratulations! Your iPhone has been Verified For eRecycling | Ref. ID:":{
		"text":"ขอแสดงความยินดี! ได้ตรวจยืนยันเครื่อง iPhone ของคุณสำหรับการ eRecycle แล้ว | ID อ้างอิง",
		"isAlreadyTranslated": true
	},
	"We have an update about your chosen Green Reward | Ref. ID:":{
		"text":"เรามีข้อมูลอัปเดตเกี่ยวกับรางวัล Green Reward ของคุณ | ID อ้างอิง",
		"isAlreadyTranslated": true
	},
	"cannot edit the vouchers":{
		"text":"ไม่สามารถแก้ไขคูปองได้",
		"isAlreadyTranslated": true
	},
	"IMEI eligible":{
		"text":"IMEI มีคุณสมบัติเข้าเกณฑ์",
		"isAlreadyTranslated": true
	},
	"IMEI not eligible":{
		"text":"IMEI มีคุณสมบัติไม่เข้าเกณฑ์",
		"isAlreadyTranslated": true
	},
	"IMEI already used for subscription":{
		"text":"IMEI ถูกใช้สำหรับการสมัครสมาชิกแล้ว",
		"isAlreadyTranslated": true
	},
	"Error in updating product details":{
		"text":"เกิดข้อผิดพลาดในการอัปเดตรายละเอียดของผลิตภัณฑ์",
		"isAlreadyTranslated": true
	},
	"product successfully updated":{
		"text":"ได้อัปเดตผลิตภัณฑ์สำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"No consumer product found with given id":{
		"text":"ไม่พบผลิตภัณฑ์ของผู้ใช้ที่มี id ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"Reward generated":{
		"text":"สร้างรางวัลแล้ว",
		"isAlreadyTranslated": true
	},
	"You have successfully pledged to eRecycle your iPhone in":{
		"text":"คุณได้ทำสัญญาเข้าร่วมการ eRecycle เครื่อง iPhone ของคุณสำเร็จแล้วใน",
		"isAlreadyTranslated": true
	},
	"We will remind you to submit your iPhone for eRecycling a few days prior to your chosen month. \nYou can choose one more Reward when you submit your pledged iPhone for eRecycling in":{
		"text":"เราจะแจ้งเตือนคุณให้ส่งเครื่อง iPhone ของคุณสำหรับการ eRecycle ในเวลาสองสามวันก่อนถึงเดือนที่คุณเลือกไว้ \nคุณสามารถเลือกรางวัลได้อีกหนึ่งรางวัลเมื่อคุณส่งเครื่อง iPhone ที่ทำสัญญาเข้าร่วมไว้สำหรับการ eRecycle ใน",
		"isAlreadyTranslated": true
	},
	"Document cannot be added":{
		"text":"ไม่สามารถเพิ่มเอกสารได้",
		"isAlreadyTranslated": true
	},
	"Pending document":{
		"text":"เอกสารรอดำเนินการอยู่",
		"isAlreadyTranslated": true
	},
	"No document obtained":{
		"text":"ไม่ได้รับเอกสาร",
		"isAlreadyTranslated": true
	},
	"Issue not found":{
		"text":"ไม่พบปัญหา",
		"isAlreadyTranslated": true
	},
	"Reward Partner not found":{
		"text":"ไม่พบคู่ค้าของรางวัล",
		"isAlreadyTranslated": true
	},
	"After your 3rd eRecycle request all your requests deserve a lot more than Green Rewards. For this request, you are eligible for a lifetime supply of good Green Karma!":{
		"text":"หลังจากคำร้องขอการ eRecycle ครั้งที่ 3 คำร้องขอของคุณทั้งหมดทำให้คุณมีสิทธิได้รับมากกว่า Green Reward มาก สำหรับคำร้องขอนี้ คุณมีสิทธิในการจัดเตรียม Green Karma ที่ดีแบบตลอดชีพ!",
		"isAlreadyTranslated": true
	},
	"You are eligible for reward":{
		"text":"คุณมีสิทธิที่จะได้รับรางวัล",
		"isAlreadyTranslated": true
	},
	"You are not eligible for reward as you have exceeded the eRecycle limit":{
		"text":"คุณไม่มีสิทธิสำหรับรางวัลเนื่องจากคุณได้ใช้เกินขีดจำกัดของ eRecycle แล้ว",
		"isAlreadyTranslated": true
	},
	"Not a manual reward":{
		"text":"ไม่ใช่รางวัลแบบกำหนดเอง",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send PartnerServiceLocationID":{
		"text":"พารามิเตอร์ไม่เพียงพอ - ส่ง 'IDตำแหน่งการบริการคู่ค้า'",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send ConsumerServiceRequestID":{
		"text":"พารามิเตอร์ไม่เพียงพอ - ส่ง 'IDคำร้องขอการบริการผู้ใช้'",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send ServiceTypeID":{
		"text":"พารามิเตอร์ไม่เพียงพอ - ส่ง 'IDประเภทการบริการ'",
		"isAlreadyTranslated": true
	},
	"This servive type does not exist":{
		"text":"ไม่มีประเภทของบริการนี้",
		"isAlreadyTranslated": true
	},
	"Something went wrong with getParts":{
		"text":"มีความผิดปกติเกิดขึ้นกับ 'รับชิ้นส่วน'",
		"isAlreadyTranslated": true
	},
	"Parts List has been sent":{
		"text":"ได้ส่งรายการชิ้นส่วนแล้ว",
		"isAlreadyTranslated": true
	},
	"Engineer is present":{
		"text":"วิศวกรอยู่",
		"isAlreadyTranslated": true
	},
	"Engineer is not present":{
		"text":"วิศวกรไม่อยู่",
		"isAlreadyTranslated": true
	},
	"Failed to get engineer details":{
		"text":"การรับข้อมูลวิศวกรไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Please provide correct productivity per day.":{
		"text":"โปรดระบุความสามารถในการผลิตที่ถูกต้องต่อวัน",
		"isAlreadyTranslated": true
	},
	"Your attendance is marked.":{
		"text":"มีการทำเครื่องหมายการเข้าร่วมของคุณ",
		"isAlreadyTranslated": true
	},
	"Failed to mark attendance.":{
		"text":"การทำเครื่องหมายการเข้าร่วมไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Failed to find the Engineer":{
		"text":"การค้นหาวิศวกรไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Unusual exception occurred. Please try again after some time.":{
		"text":"มีข้อยกเว้นที่ผิดปกติเกิดขึ้น โปรดลองอีกครั้งหลังจากผ่านไปสักพักหนึ่ง",
		"isAlreadyTranslated": true
	},
	"Timings updated successfully .":{
		"text":"ระยะเวลาได้รับการอัปเดตสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"service is not at required state":{
		"text":"การบริการไม่อยู่ที่สถานะที่ต้องการ",
		"isAlreadyTranslated": true
	},
	"Parts added":{
		"text":"เพิ่มชิ้นส่วนแล้ว",
		"isAlreadyTranslated": true
	},
	"Parts updating failed":{
		"text":"การอัปเดตชิ้นส่วนไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Other costs added":{
		"text":"เพิ่มค่าใช้จ่ายอื่นๆ แล้ว",
		"isAlreadyTranslated": true
	},
	"Advance payment added":{
		"text":"เพิ่มการชำระเงินล่วงหน้าแล้ว",
		"isAlreadyTranslated": true
	},
	"Parts removed":{
		"text":"เอาชิ้นส่วนออกแล้ว",
		"isAlreadyTranslated": true
	},
	"Discount fetching failed":{
		"text":"การดึงข้อมูลส่วนลดไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Tax information fetching failed":{
		"text":"การดึงข้อมูลภาษีไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Discount can not be greater than the service amount":{
		"text":"ส่วนลดไม่สามารถสูงกว่าจำนวนเงินค่าบริการ",
		"isAlreadyTranslated": true
	},
	"Discount or Advance can not be greater than the payable amount.":{
		"text":"ส่วนลดหรือการชำระเงินล่วงหน้าไม่สามารถสูงกว่าจำนวนเงินที่ต้องชำระ",
		"isAlreadyTranslated": true
	},
	"Invoice has been generated with Service requestId -":{
		"text":"ได้สร้างใบกำกับสินค้าที่มี 'IDคำร้องขอ' ของการบริการแล้ว -",
		"isAlreadyTranslated": true
	},
	"Request charges fetching failed":{
		"text":"การดึงข้อมูลค่าธรรมเนียมคำร้องขอไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Update service request failed":{
		"text":"อัปเดตคำร้องขอการบริการไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Service request is not at required stage.":{
		"text":"คำร้องขอการบริการไม่อยู่ที่ขั้นที่ต้องการ",
		"isAlreadyTranslated": true
	},
	"Your delivery request has been successfully completed.":{
		"text":"คำร้องขอการจัดส่งของคุณได้ดำเนินการสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Parts requested":{
		"text":"การเบิกอะไหล่",
		"isAlreadyTranslated": true
	},
	"We could not suggest an appropriate engineer":{
		"text":"เราไม่สามารถแนะนำวิศวกรที่เหมาะสมได้",
		"isAlreadyTranslated": true
	},
	"List of available engineer":{
		"text":"รายการวิศวกรที่พร้อมบริการ",
		"isAlreadyTranslated": true
	},
	"Could not assign engineer, assign manually":{
		"text":"ไม่สามารถมอบหมายให้กับวิศวกรได้ ให้มอบหมายด้วยตนเอง",
		"isAlreadyTranslated": true
	},
	"Job is already assign to another Engineer":{
		"text":"ได้มอบหมายงานให้กับวิศวกรอีกคนหนึ่งแล้ว",
		"isAlreadyTranslated": true
	},
	"No service request found for this ConsumerServiceRequestID":{
		"text":"ไม่พบคำร้องขอการบริการสำหรับ 'IDคำร้องขอการบริการผู้ใช้' นี้",
		"isAlreadyTranslated": true
	},
	"No engineer found for this ServiceLocationEngineerID":{
		"text":"ไม่พบวิศวกรสำหรับ 'IDวิศวกรตำแหน่งการบริการ' นี้",
		"isAlreadyTranslated": true
	},
	"A new repair job is assigned to you! Please 'Accept' the job to initiate the repair process. Job ID:":{
		"text":"มีการมอบหมายงานซ่อมใหม่ให้คุณ! โปรด 'ตอบรับ' งานเพื่อเริ่มขั้นตอนการซ่อม ID งาน:",
		"isAlreadyTranslated": true
	},
	"Pending for approval":{
		"text":"กำลังรอการอนุมัติ",
		"isAlreadyTranslated": true
	},
	"Engineer assigned jobs not updated |":{
		"text":"งานที่มอบหมายให้วิศวกรไม่อัปเดต |",
		"isAlreadyTranslated": true
	},
	"Create consumer service request assign service location |":{
		"text":"สร้างตำแหน่งการบริการการมอบหมายคำร้องขอการบริการผู้ใช้ |",
		"isAlreadyTranslated": true
	},
	"Job is assigned to you successfully":{
		"text":"มอบหมายงานให้คุณสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Service log creation failed":{
		"text":"การสร้างบันทึกการบริการไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Engineer service log creation failed":{
		"text":"การสร้างบันทึกการบริการวิศวกรไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Please provide valid input":{
		"text":"โปรดป้อนข้อมูลที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Engineer is not registered with this service.":{
		"text":"ไม่มีการลงทะเบียนวิศวกรกับการบริการนี้",
		"isAlreadyTranslated": true
	},
	"Service not found for the assignment.":{
		"text":"ไม่พบการบริการสำหรับการมอบหมายนี้",
		"isAlreadyTranslated": true
	},
	"All requests has been sent":{
		"text":"ได้ส่งคำร้องขอทั้งหมดแล้ว",
		"isAlreadyTranslated": true
	},
	"Service request is not assigned to the Engineer":{
		"text":"ไม่มีการมอบหมายคำร้องขอการบริการให้กับวิศวกร",
		"isAlreadyTranslated": true
	},
	"Job has already started":{
		"text":"งานได้เริ่มไว้อยู่แล้ว",
		"isAlreadyTranslated": true
	},
	"Delivery of this device is pending. Please Wait for sometime":{
		"text":"การจัดส่งอุปกรณ์เครื่องนี้อยู่ระหว่างรอดำเนินการ โปรดรอเป็นเวลาสักครู่หนึ่ง",
		"isAlreadyTranslated": true
	},
	"Job started":{
		"text":"งานเริ่มแล้ว",
		"isAlreadyTranslated": true
	},
	"Please complete job in progress":{
		"text":"โปรดทำงานที่ดำเนินการอยู่ให้เสร็จสิ้น",
		"isAlreadyTranslated": true
	},
	"Engineer is not registered with this service":{
		"text":"ไม่มีการลงทะเบียนวิศวกรกับการบริการนี้",
		"isAlreadyTranslated": true
	},
	"Service request is not at required stage":{
		"text":"คำร้องขอการบริการไม่อยู่ที่ขั้นที่ต้องการ",
		"isAlreadyTranslated": true
	},
	"Service initiated":{
		"text":"เริ่มการบริการแล้ว",
		"isAlreadyTranslated": true
	},
	"request information fetching failed":{
		"text":"การดึงข้อมูลคำร้องขอไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Engineer is not register with this service":{
		"text":"ไม่มีการลงทะเบียนวิศวกรกับการบริการนี้",
		"isAlreadyTranslated": true
	},
	"Service is not at required stage":{
		"text":"การบริการไม่อยู่ที่ขั้นที่ต้องการ",
		"isAlreadyTranslated": true
	},
	"Engineer reached":{
		"text":"วิศวกรมาถึงแล้ว",
		"isAlreadyTranslated": true
	},
	"Product fetching failed":{
		"text":"การดึงข้อมูลผลิตภัณฑ์ไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Service completed successfully":{
		"text":"การบริการดำเนินการสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"No  Jobs available":{
		"text":"ไม่มีงาน",
		"isAlreadyTranslated": true
	},
	"Closed Jobs has been sent":{
		"text":"ได้ส่งงานที่ปิดแล้ว",
		"isAlreadyTranslated": true
	},
	"Active jobs has been sent":{
		"text":"ได้ส่งงานที่ทำงานอยู่แล้ว",
		"isAlreadyTranslated": true
	},
	"Task added":{
		"text":"เพิ่มงานแล้ว",
		"isAlreadyTranslated": true
	},
	"Deletion successful":{
		"text":"การลบสำเร็จ",
		"isAlreadyTranslated": true
	},
	"No such task to remove":{
		"text":"ไม่มีงานที่จะลบออก",
		"isAlreadyTranslated": true
	},
	"Notification updates":{
		"text":"การอัปเดตการแจ้งเตือน",
		"isAlreadyTranslated": true
	},
	"Successfully created":{
		"text":"สร้างสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Please provide a valid ReferenceID":{
		"text":"โปรดระบุ 'IDอ้างอิง' ที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Service location updates fetched":{
		"text":"การอัปเดตตำแหน่งการบริการถูกดึงข้อมูลแล้ว",
		"isAlreadyTranslated": true
	},
	"No entries found for the given ConsumerServiceRequestID":{
		"text":"ไม่พบรายการสำหรับ 'IDคำร้องขอการบริการผู้ใช้' ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"Updated result":{
		"text":"ผลลัพธ์ที่อัปเดต",
		"isAlreadyTranslated": true
	},
	"No entries found for the given ServicelocationUpdatesID":{
		"text":"ไม่พบรายการสำหรับ 'IDการอัปเดตตำแหน่งการบริการ' ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"No entries found for the given ConsumerID":{
		"text":"ไม่พบรายการสำหรับ 'IDผู้ใช้' ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"Outlet location id":{
		"text":"id ตำแหน่งทางออก",
		"isAlreadyTranslated": true
	},
	"No outlet found":{
		"text":"ไม่พบทางออก",
		"isAlreadyTranslated": true
	},
	"Eligibility for option rewards":{
		"text":"การมีคุณสมบัติสำหรับรีวอร์ดตัวเลือก",
		"isAlreadyTranslated": true
	},
	"As you have pledged more than 3 iPhones, you will now be eligible only for the Green Karma Reward.":{
		"text":"เนื่องจากคุณได้ทำสัญญาเข้าร่วมด้วยเครื่อง iPhone มากกว่า 3 เครื่อง ตอนนี้คุณจะมีสิทธิเฉพาะสำหรับ Green Karma Reward",
		"isAlreadyTranslated": true
	},
	"Compute results":{
		"text":"ผลลัพธ์การคำนวณ",
		"isAlreadyTranslated": true
	},
	"Looks like you have previously pledged to eRecycle your iPhones What would you like to do?":{
		"text":"ดูเหมือนคุณได้ทำสัญญาเข้าร่วมในการ eRecycle เครื่อง iPhone ของคุณแล้วก่อนหน้านี้ คุณต้องการทำสิ่งใด?",
		"isAlreadyTranslated": true
	},
	"As you have eRecycled more than 3 iPhones, you will now be eligible only for the Green Karma Reward.":{
		"text":"เนื่องจากคุณได้ทำการ eRecycle แล้วด้วยเครื่อง iPhone มากกว่า 3 เครื่อง ตอนนี้คุณจะมีสิทธิเฉพาะสำหรับ Green Karma Reward",
		"isAlreadyTranslated": true
	},
	"No subscription obtained":{
		"text":"ไม่ได้รับการสมัครสมาชิก",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send SubscriptionDetailsID":{
		"text":"พารามิเตอร์ไม่เพียงพอ - ส่ง 'IDรายละเอียดการสมัครสมาชิก'",
		"isAlreadyTranslated": true
	},
	"No subscription found for the product":{
		"text":"ไม่พบการสมัครสมาชิกสำหรับผลิตภัณฑ์",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send SubscriptionState":{
		"text":"พารามิเตอร์ไม่เพียงพอ - ส่ง 'สถานะการสมัครสมาชิก'",
		"isAlreadyTranslated": true
	},
	"subscription request details":{
		"text":"รายละเอียดคำร้องขอการสมัครสมาชิก",
		"isAlreadyTranslated": true
	},
	"initialized consumer":{
		"text":"ผู้ใช้ที่กำหนดค่าเริ่มต้นแล้ว",
		"isAlreadyTranslated": true
	},
	"failed to initialize consumer":{
		"text":"กำหนดค่าเริ่มต้นให้กับผู้ใช้ไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Something went wrong with default device addition":{
		"text":"มีความผิดปกติเกิดขึ้นกับการเพิ่มอุปกรณ์เริ่มต้น",
		"isAlreadyTranslated": true
	},
	"Failed to create consumerProducts":{
		"text":"สร้าง 'ผลิตภัณฑ์ของผู้ใช้' ไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Failed to create ConsumerUnregisteredProducts":{
		"text":"สร้าง 'ผลิตภัณฑ์ที่ไม่ได้ลงทะเบียนของผู้ใช้' ไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"successfully created default temp consumer product":{
		"text":"สร้างผลิตภัณฑ์ของผู้ใช้ชั่วคราวเป็นค่าเริ่มต้นสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"OTP does not match":{
		"text":"รหัส OTP ไม่ตรงกัน",
		"isAlreadyTranslated": true
	},
	"No entity created":{
		"text":"ไม่มีการสร้างเอนทิตี",
		"isAlreadyTranslated": true
	},
	"Something went wrong in registering other devices":{
		"text":"มีความผิดปกติเกิดขึ้นในการลงทะเบียนอุปกรณ์อื่น",
		"isAlreadyTranslated": true
	},
	"Invalid input":{
		"text":"ข้อมูลป้อนเข้าไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching filtered temp consumers":{
		"text":"มีความผิดปกติเกิดขึ้นขณะดึงข้อมูลผู้ใช้ชั่วคราวที่ผ่านการกรองข้อมูล",
		"isAlreadyTranslated": true
	},
	"List of filtered temp consumers":{
		"text":"รายการผู้ใช้ชั่วคราวที่ผ่านการกรองข้อมูล",
		"isAlreadyTranslated": true
	},
	"Sms success":{
		"text":"ความสำเร็จของ Sms",
		"isAlreadyTranslated": true
	},
	"device token not in the expected format":{
		"text":"โทเคนของอุปกรณ์ไม่อยู่ในรูปแบบที่คาดไว้",
		"isAlreadyTranslated": true
	},
	"Your home repair request has been registered. You will receive confirmation 30mins prior to the requested time slot.":{
		"text":"ได้ลงทะเบียนคำร้องขอการซ่อมที่บ้านของคุณแล้ว คุณจะได้รับการยืนยันในเวลา 30 นาทีก่อนถึงกำหนดเวลาที่ร้องขอ",
		"isAlreadyTranslated": true
	},
	"Your carry in repair request for ( ) at ( ) has been registered. Please check track repair section to get the direction to the location.":{
		"text":"ได้ลงทะเบียนคำร้องขอการซ่อมโดยนำอุปกรณ์เข้ามาเองของคุณสำหรับ ( ) ที่ ( ) แล้ว โปรดตรวจดูในส่วน ติดตามการซ่อม เพื่อรับเส้นทางไปยังตำแหน่ง",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for ( )":{
		"text":"คำร้องขอของคุณสำหรับการกำหนดเวลาใหม่สำหรับ ( ) ได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"( ) is your OTP for Servify. This OTP is valid for 120 seconds. Do not share this OTP with anyone for security reasons.":{
		"text":"( ) คือรหัส OTP ของคุณสำหรับ Servify รหัส OTP นี้ใช้งานได้เป็นเวลา 120 วินาที ด้วยเหตุผลด้านความปลอดภัย ห้ามแบ่งปันรหัส OTP นี้กับผู้อื่น",
		"isAlreadyTranslated": true
	},
	"Download the Servify app from ( ) and get a better service experience.  Servify - for all your service needs.":{
		"text":"ดาวน์โหลดแอป Servify จาก ( ) และพบกับประสบการณ์การบริการที่ดียิ่งขึ้น  Servify - สำหรับทุกความต้องการด้านการบริการของคุณ",
		"isAlreadyTranslated": true
	},
	"Download Servify - Personal assistant to all your electronics and home appliances.":{
		"text":"ดาวน์โหลด Servify - ผู้ช่วยส่วนตัวสำหรับอุปกรณ์อิเล็กทรอนิกส์และเครื่องใช้ไฟฟ้าในบ้านของคุณ",
		"isAlreadyTranslated": true
	},
	"( ) is your OTP for Servify. This OTP is valid for 120 seconds. Do not share this OTP with anyone for security reasons. We look forward to serving you.":{
		"text":"( ) คือรหัส OTP ของคุณสำหรับ Servify รหัส OTP นี้ใช้งานได้เป็นเวลา 120 วินาที ด้วยเหตุผลด้านความปลอดภัย ห้ามแบ่งปันรหัส OTP นี้กับผู้อื่น เราหวังว่าจะได้บริการคุณ",
		"isAlreadyTranslated": true
	},
	"You have cancelled your request for scheduled on ( ) We hope to serve you soon.":{
		"text":"คุณได้ยกเลิกคำร้องขอของคุณสำหรับการกำหนดเวลาไว้วันที่ ( ) เราหวังว่าจะได้บริการคุณอีกในเร็วๆ นี้",
		"isAlreadyTranslated": true
	},
	"Thank you for choosing servify, please rate our service on the App. We look forward to service you again.":{
		"text":"ขอบคุณที่เลือก servify โปรดให้คะแนนการบริการของเราสำหรับแอปนี้ เราหวังว่าจะได้บริการคุณอีกครั้ง",
		"isAlreadyTranslated": true
	},
	"Your request number is ( ) . Our Partner ( ) will collect the device from you as per the scheduled time. To know more, please check Track Request section within the App. T&C apply.":{
		"text":"หมายเลขคำร้องขอของคุณคือ ( ) คู่ค้าของเรา ( ) จะมารับอุปกรณ์จากคุณตามเวลาที่กำหนดไว้ หากต้องการทราบข้อมูลเพิ่มเติม โปรดตรวจดูในส่วน ติดตามคำร้องขอ ภายในแอป การปรับใช้ T&C",
		"isAlreadyTranslated": true
	},
	"Your request number is ( ) . Our Logistics Agent will collect the device from you as per the scheduled time. To know more, please check Track Request section within the App. T&C apply.":{
		"text":"หมายเลขคำร้องขอของคุณคือ ( ) ตัวแทนโลจิสติกส์ของเราจะรับอุปกรณ์จากคุณตามเวลาที่กำหนดไว้ หากต้องการทราบข้อมูลเพิ่มเติม โปรดตรวจดูในส่วน ติดตามคำร้องขอ ภายในแอป การปรับใช้ T&C",
		"isAlreadyTranslated": true
	},
	"| We have received your claim request. Our representative will connect with you in next 48 hours.":{
		"text":"| เราได้รับคำร้องขอการเคลมของคุณแล้ว พนักงานตัวแทนของเราจะเชื่อมต่อกับคุณในอีก 48 ชั่วโมงข้างหน้า",
		"isAlreadyTranslated": true
	},
	"Your device will be picked up on ( ) . Your Secret Code is ( ) . Please handover the device to the Logistics Agent only once they provide this secret code to prove their identity.":{
		"text":"จะมีการรับอุปกรณ์ของคุณในวันที่ ( ) รหัสลับของคุณคือ ( ) โปรดส่งมอบอุปกรณ์ให้กับตัวแทนโลจิสติกส์เฉพาะเมื่อเขาแสดงรหัสลับนี้เพื่อยืนยันตัวตนของเขาเท่านั้น",
		"isAlreadyTranslated": true
	},
	"Your Activation Code to register for the  Plan is ( ). Download the app from and get a better service experience.":{
		"text":"รหัสการเปิดใช้งานของคุณในการลงทะเบียนสำหรับแผนนี้คือ ( ) ดาวน์โหลดแอปจาก และพบกับประสบการณ์การบริการที่ดียิ่งขึ้น",
		"isAlreadyTranslated": true
	},
	"Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips. Download now.":{
		"text":"รับบริการสำหรับ iPhone, iPad, Watch และ Mac จากศูนย์ที่ได้รับการแต่งตั้งผ่านปลายนิ้วของคุณ ดาวน์โหลดตอนนี้",
		"isAlreadyTranslated": true
	},
	"Pick up of your (Ref No. -) has been rescheduled to as requested.":{
		"text":"ได้กำหนดเวลาเข้าไปรับอุปกรณ์ของคุณ (หมายเลขอ้างอิง -) ใหม่เป็น  ตามที่ร้องขอ",
		"isAlreadyTranslated": true
	},
	"Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips. iOS App - Web Portal -":{
		"text":"รับบริการสำหรับ iPhone, iPad, Watch และ Mac จากศูนย์ที่ได้รับการแต่งตั้งผ่านปลายนิ้วของคุณ แอป iOS - เว็บพอร์ทัล -",
		"isAlreadyTranslated": true
	},
	"Your request is registered. We will contact you for an appointment. For assistance, please call us on 1800-108-1333.":{
		"text":"ได้ลงทะเบียนคำร้องขอของคุณแล้ว เราจะติดต่อคุณเพื่อทำการนัดหมาย หากต้องการสอบถาม โปรดโทรหาเราที่ 1800-108-1333",
		"isAlreadyTranslated": true
	},
	"Device Picked up: Your (Ref ID -) was collected by Servify Executive on ( ) and will be delivered to an Authorized Service Centre. To know more, please check Track Repair section within the App. T&C Apply.":{
		"text":"รับอุปกรณ์แล้ว: เจ้าหน้าที่ Servify ได้มารับ (ID อ้างอิง -) ของคุณแล้วเมื่อวันที่ ( ) และจะจัดส่งไปยังศูนย์บริการที่ได้รับการแต่งตั้ง หากต้องการทราบข้อมูลเพิ่มเติม โปรดตรวจดูในส่วน ติดตามการซ่อม ภายในแอป การปรับใช้ T&C",
		"isAlreadyTranslated": true
	},
	"( ) is your OTP for Micromax Care. This OTP is valid for 120 seconds. Do not share this OTP with any one for security reasons. We look forward to serving you.":{
		"text":"( ) คือรหัส OTP ของคุณสำหรับ Micromax Care รหัส OTP นี้ใช้งานได้เป็นเวลา 120 วินาที ด้วยเหตุผลด้านความปลอดภัย ห้ามแบ่งปันรหัส OTP นี้กับผู้อื่น เราหวังว่าจะได้บริการคุณ",
		"isAlreadyTranslated": true
	},
	"| We have received your claim request. Our representative will connect with you in next one business day.":{
		"text":"| เราได้รับคำร้องขอการเคลมของคุณแล้ว พนักงานตัวแทนของเราจะเชื่อมต่อกับคุณในวันทำการถัดไป",
		"isAlreadyTranslated": true
	},
	"Pickup Rescheduled: Logistics Agent will attempt pickup of your ( ) on ( ) as requested.":{
		"text":"กำหนดเวลารับอุปกรณ์ใหม่แล้ว: ตัวแทนโลจิสติกส์จะมารับ ( ) ของคุณในวันที่ ( ) ตามที่ร้องขอ",
		"isAlreadyTranslated": true
	},
	"New Request Ref. ID":{
		"text":"ID อ้างอิงคำร้องขอใหม่",
		"isAlreadyTranslated": true
	},
	"Name:":{
		"text":"ชื่อ:",
		"isAlreadyTranslated": true
	},
	"Phone:":{
		"text":"โทรศัพท์:",
		"isAlreadyTranslated": true
	},
	"Landmark:":{
		"text":"จุดสังเกต:",
		"isAlreadyTranslated": true
	},
	"Your dropoff is scheduled on ( ) at ( ) Ref ID:":{
		"text":"การส่งอุปกรณ์ของคุณถูกกำหนดไว้ในวันที่ ( ) ที่ ( ) ID อ้างอิง:",
		"isAlreadyTranslated": true
	},
	"Download 'eRecycle' on your iPhone to raise and track eRecycle requests on the go.":{
		"text":"ดาวน์โหลด \"eRecycle\" บน iPhone ของคุณเพื่อยื่นเรื่องและติดตามคำร้องขอสำหรับ eRecycle ได้ในทุกที่",
		"isAlreadyTranslated": true
	},
	"Dear Customer,We have received a claim request for your device. Please click on the following link to cross check the information shared by you and submit to raise the claim. Kindly note, once submitted, the details cannot be changed.":{
		"text":"เรียนท่านลูกค้า เราได้รับคำร้องขอการเคลมสำหรับอุปกรณ์ของคุณแล้ว โปรดคลิกที่ลิงก์ต่อไปนี้เพื่อตรวจสอบข้อมูลที่คุณได้แบ่งปันเอาไว้อีกครั้ง และส่งข้อมูลนั้นเพื่อยื่นเรื่องคำร้องขอการเคลม โปรดทราบว่า เมื่อส่งแล้ว รายละเอียดต่างๆ จะไม่สามารถเปลี่ยนแปลงได้",
		"isAlreadyTranslated": true
	},
	"Service Cancelled":{
		"text":"การบริการถูกยกเลิกแล้ว",
		"isAlreadyTranslated": true
	},
	"Repair request ( ) has been canceled by the consumer":{
		"text":"คำร้องขอการซ่อม ( ) ถูกยกเลิกแล้วโดยผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Repair Completed":{
		"text":"การซ่อมเสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"Your ( ) has been repaired. Please collect it from ( )":{
		"text":"( ) ของท่านได้รับการซ่อมเสร็จแล้ว โปรดมารับอุปกรณ์จาก ( )",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for carry in repair":{
		"text":"คำร้องขอการยกเลิกของคุณสำหรับการซ่อมโดยนำอุปกรณ์เข้ามาเองได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for home repair":{
		"text":"คำร้องขอการยกเลิกของคุณสำหรับการซ่อมที่บ้านได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"Request Reschedule":{
		"text":"การกำหนดเวลาใหม่สำหรับคำร้องขอ",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for":{
		"text":"คำร้องขอของคุณสำหรับการกำหนดเวลาใหม่ได้รับการตอบรับแล้วสำหรับ",
		"isAlreadyTranslated": true
	},
	"Service Reschedule":{
		"text":"การกำหนดเวลาใหม่สำหรับการบริการ",
		"isAlreadyTranslated": true
	},
	"Repair request":{
		"text":"คำร้องขอการซ่อม",
		"isAlreadyTranslated": true
	},
	"Repair initiated for your request":{
		"text":"เริ่มการซ่อมสำหรับคำร้องขอของคุณ",
		"isAlreadyTranslated": true
	},
	"Service Completed":{
		"text":"การบริการเสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"Your ( ) has been repaired & delivered.":{
		"text":"( ) ได้รับการซ่อมและจัดส่งแล้ว",
		"isAlreadyTranslated": true
	},
	"Your repair request has been canceled":{
		"text":"คำร้องขอการซ่อมของคุณถูกยกเลิกแล้ว",
		"isAlreadyTranslated": true
	},
	"Service Reschedule":{
		"text":"การกำหนดเวลาใหม่สำหรับการบริการ",
		"isAlreadyTranslated": true
	},
	"Installation request ( )  has been rescheduled by the consumer":{
		"text":"คำร้องขอการติดตั้ง ( ) ได้รับการกำหนดเวลาใหม่แล้วโดยผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Service Reschedule":{
		"text":"การกำหนดเวลาใหม่สำหรับการบริการ",
		"isAlreadyTranslated": true
	},
	"Demo request ( ) has been rescheduled by the consumer":{
		"text":"คำร้องขอการสาธิต ( ) ได้รับการกำหนดเวลาใหม่แล้วโดยผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Service request ( ) has been rescheduled by the consumer":{
		"text":"คำร้องขอการบริการ ( ) ได้รับการกำหนดเวลาใหม่แล้วโดยผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for demo":{
		"text":"คำร้องขอการยกเลิกของคุณสำหรับการสาธิตได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"Installation request":{
		"text":"คำร้องขอการติดตั้ง",
		"isAlreadyTranslated": true
	},
	"has been canceled by the consumer":{
		"text":"ถูกยกเลิกแล้วโดยผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Demo request":{
		"text":"คำร้องขอการสาธิต",
		"isAlreadyTranslated": true
	},
	"has been canceled by the consumer":{
		"text":"ถูกยกเลิกแล้วโดยผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for service":{
		"text":"คำร้องขอการยกเลิกของคุณสำหรับการบริการได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for pickup drop":{
		"text":"คำร้องขอการยกเลิกของคุณสำหรับบริการรับ-ส่งอุปกรณ์ได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"Service Created":{
		"text":"สร้างการบริการแล้ว",
		"isAlreadyTranslated": true
	},
	"Your request for ( ) has been registered.":{
		"text":"ได้ลงทะเบียนคำร้องขอของคุณสำหรับ ( ) แล้ว",
		"isAlreadyTranslated": true
	},
	"Service suspended":{
		"text":"การบริการถูกพักไว้",
		"isAlreadyTranslated": true
	},
	"Your request ( ) has been suspended":{
		"text":"คำร้องขอของคุณ ( ) ถูกพักแล้ว",
		"isAlreadyTranslated": true
	},
	"Service invoice generated":{
		"text":"สร้างใบกำกับสินค้าสำหรับบริการแล้ว",
		"isAlreadyTranslated": true
	},
	"Your service request is Rescheduled by ( )":{
		"text":"คำร้องขอการบริการของคุณได้รับการกำหนดเวลาใหม่โดย ( )",
		"isAlreadyTranslated": true
	},
	"Service accepted":{
		"text":"การบริการได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"Sweet! ( ) has accepted your request to fix your ( )":{
		"text":"น่าชื่นใจมาก! ( ) ได้ตอบรับคำร้องขอในการซ่อมเครื่อง ( ) ของคุณแล้ว",
		"isAlreadyTranslated": true
	},
	"Serviceman journey initiated":{
		"text":"เจ้าหน้าที่ปฏิบัติงานซ่อมเริ่มเดินทางแล้ว",
		"isAlreadyTranslated": true
	},
	"( ) is on the way to take care of your ( )":{
		"text":"( ) อยู่ระหว่างการเดินทางเพื่อดูแลเครื่อง ( ) ของคุณ",
		"isAlreadyTranslated": true
	},
	"Our expert ( ) has initiated the repair work.":{
		"text":"ผู้เชี่ยวชาญ ( ) ของเราได้เริ่มดำเนินการซ่อมแล้ว",
		"isAlreadyTranslated": true
	},
	"An invoice has been generated for your service.":{
		"text":"ได้สร้างใบกำกับสินค้าสำหรับบริการของคุณแล้ว",
		"isAlreadyTranslated": true
	},
	"Payment received for repair request":{
		"text":"ได้รับการชำระเงินสำหรับคำร้องขอการซ่อม แล้ว",
		"isAlreadyTranslated": true
	},
	"Request cancelled":{
		"text":"คำร้องขอถูกยกเลิกแล้ว",
		"isAlreadyTranslated": true
	},
	"You have cancelled your request for ( ) scheduled on ( ). We hope to serve you soon.":{
		"text":"คุณได้ยกเลิกคำร้องขอของคุณสำหรับ ( ) ที่กำหนดเวลาไว้วันที่ ( ) เราหวังว่าจะได้บริการคุณอีกในเร็วๆ นี้",
		"isAlreadyTranslated": true
	},
	"All required keys are present":{
		"text":"มีการแสดงปุ่มที่ต้องใช้ทั้งหมด",
		"isAlreadyTranslated": true
	},
	"Email sent successfully":{
		"text":"ส่งอีเมลสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"error in sending email":{
		"text":"เกิดข้อผิดพลาดในการส่งอีเมล",
		"isAlreadyTranslated": true
	},
	"Cannot send email":{
		"text":"ไม่สามารถส่งอีเมลได้",
		"isAlreadyTranslated": true
	},
	"Email is sent successfully":{
		"text":"ส่งอีเมลสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Minimum Required Entities Not Found":{
		"text":"ไม่พบเอนทิตีที่ต้องใช้ต่ำสุด",
		"isAlreadyTranslated": true
	},
	"No gsx params found":{
		"text":"ไม่พบพารามิเตอร์ gsx",
		"isAlreadyTranslated": true
	},
	"Match found":{
		"text":"พบรายการที่ตรงกัน",
		"isAlreadyTranslated": true
	},
	"No match found":{
		"text":"ไม่พบรายการที่ตรงกัน",
		"isAlreadyTranslated": true
	},
	"The email address entered doesn't seem to be a valid EmailID":{
		"text":"ที่อยู่อีเมลที่ป้อนดูไม่เหมือน 'IDอีเมล' ที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Oops! Looks like this email ID does not belong to any organization pre-selected for this program. Please enter correct email ID in case of any errors.":{
		"text":"โอ๊ะ! ดูเหมือนว่า ID อีเมลนี้ไม่ได้เป็นขององค์กรใดๆ ที่เลือกไว้ก่อนหน้าสำหรับโปรแกรมนี้ โปรดป้อน ID อีเมลที่ถูกต้องในกรณีที่เกิดข้อผิดพลาดใดๆ",
		"isAlreadyTranslated": true
	},
	"Looks like you have already redeemed 2 vouchers.":{
		"text":"ดูเหมือนว่าคุณได้แลกเงินคืนสำหรับคูปอง 2 ใบแล้ว",
		"isAlreadyTranslated": true
	},
	"Oops! Looks like currently there are no more vouchers available for this program. Please try again after some time.":{
		"text":"โอ๊ะ! ดูเหมือนว่าขณะนี้ไม่มีคูปองอื่นสำหรับโปรแกรมนี้ โปรดลองอีกครั้งหลังจากผ่านไปสักพักหนึ่ง",
		"isAlreadyTranslated": true
	},
	"No valid voucher found":{
		"text":"ไม่พบคูปองที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Looks like this voucher is invalid":{
		"text":"ดูเหมือนว่าคูปองนี้จะไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Valid Voucher":{
		"text":"คูปองที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Seems like the Imei or Serial does not match with that of the device purchased":{
		"text":"ดูเหมือนว่า Imei หรือเลขประจำเครื่องจะไม่ตรงกับของอุปกรณ์ที่ซื้อ",
		"isAlreadyTranslated": true
	},
	"Looks like you have already activated the enterprise support plan for this device":{
		"text":"ดูเหมือนว่าคุณได้เปิดใช้งานแผนการสนับสนุนแบบ Enterprise ไว้อยู่แล้วสำหรับอุปกรณ์เครื่องนี้",
		"isAlreadyTranslated": true
	},
	"Insufficient Params - Send either VoucherID or VoucherObj":{
		"text":"พารามิเตอร์ไม่เพียงพอ - ส่ง 'IDคูปอง' หรือ 'ออบเจกต์คูปอง'",
		"isAlreadyTranslated": true
	},
	"No voucher object found":{
		"text":"ไม่พบออบเจกต์คูปอง",
		"isAlreadyTranslated": true
	},
	"This Page has no data":{
		"text":"หน้านี้ไม่มีข้อมูล",
		"isAlreadyTranslated": true
	},
	"You don't have access to this brand":{
		"text":"คุณไม่มีการเข้าถึงแบรนด์นี้",
		"isAlreadyTranslated": true
	},
	"You don't have access to this service Location":{
		"text":"คุณไม่มีการเข้าถึงตำแหน่งการบริการนี้",
		"isAlreadyTranslated": true
	},
	"You don't have access to this zipcode":{
		"text":"คุณไม่มีการเข้าถึงรหัสไปรษณีย์นี้",
		"isAlreadyTranslated": true
	},
	"You don't have access to this consumer service request":{
		"text":"คุณไม่มีการเข้าถึงคำร้องขอการบริการผู้ใช้นี้",
		"isAlreadyTranslated": true
	},
	"Invalid ConsumerServiceRequestID":{
		"text":"'IDคำร้องขอการบริการผู้ใช้' ไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"This request is already assigned to ( )":{
		"text":"คำร้องขอนี้ได้มอบหมายให้กับ ( ) อยู่แล้ว",
		"isAlreadyTranslated": true
	},
	"Consumer service request log creation failed |":{
		"text":"การสร้างบันทึกคำร้องขอการบริการผู้ใช้ไม่สำเร็จ |",
		"isAlreadyTranslated": true
	},
	"Cannot (Re)Assign consumer service request":{
		"text":"ไม่สามารถมอบหมายคำร้องขอการบริการผู้ใช้ (อีกครั้ง) ได้",
		"isAlreadyTranslated": true
	},
	"No service request found for this ConsumerServiceRequestID":{
		"text":"ไม่พบคำร้องขอการบริการสำหรับ 'IDคำร้องขอการบริการผู้ใช้' นี้",
		"isAlreadyTranslated": true
	},
	"This service location isn't active anymore":{
		"text":"ตำแหน่งการบริการนี้ใช้งานไม่ได้อีกต่อไป",
		"isAlreadyTranslated": true
	},
	"Service is not at the required stage":{
		"text":"การบริการไม่อยู่ที่ขั้นที่ต้องการ",
		"isAlreadyTranslated": true
	},
	"Invalid charge name":{
		"text":"ชื่อค่าธรรมเนียมไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Product Purchase cost and date are required for this action":{
		"text":"ค่าใช้จ่ายการซื้อผลิตภัณฑ์และวันที่เป็นสิ่งที่ต้องมีสำหรับการดำเนินการนี้",
		"isAlreadyTranslated": true
	},
	"Invalid Type":{
		"text":"ชนิดไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights to approve this claim request":{
		"text":"คุณไม่มีสิทธิเพียงพอที่จะอนุมัติคำร้องขอการเคลมนี้",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights to add charges":{
		"text":"คุณไม่มีสิทธิเพียงพอที่จะเพิ่มค่าธรรมเนียม",
		"isAlreadyTranslated": true
	},
	"The advance payment cannot be more than the consumer amount":{
		"text":"การชำระเงินล่วงหน้าไม่สามารถสูงกว่าจำนวนเงินของผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Approved claim amount cannot be greater than the total billed amount":{
		"text":"จำนวนเงินการเคลมที่อนุมัติไม่สามารถสูงกว่าจำนวนที่เรียกเก็บทั้งหมด",
		"isAlreadyTranslated": true
	},
	"Discounts cannot be greater than the taxable amount":{
		"text":"ส่วนลดไม่สามารถสูงกว่าจำนวนที่ต้องชำระภาษี",
		"isAlreadyTranslated": true
	},
	"Discount check not requires":{
		"text":"ไม่ต้องตรวจสอบส่วนลด",
		"isAlreadyTranslated": true
	},
	"Charges Updated":{
		"text":"อัปเดตค่าธรรมเนียมแล้ว",
		"isAlreadyTranslated": true
	},
	"Not a valid charge to update":{
		"text":"ไม่ใช่ค่าธรรมเนียมที่ถูกต้องสำหรับการอัปเดต",
		"isAlreadyTranslated": true
	},
	"Invalid Consumer Service Request":{
		"text":"คำร้องขอการบริการผู้ใช้ไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights to modify this claim request":{
		"text":"คุณไม่มีสิทธิเพียงพอที่จะปรับเปลี่ยนคำร้องขอการเคลมนี้",
		"isAlreadyTranslated": true
	},
	"Previous service type not found":{
		"text":"ไม่พบประเภทการบริการก่อนหน้านี้",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send Remarks to override estimation":{
		"text":"พารามิเตอร์ไม่เพียงพอ - ส่งหมายเหตุเพื่อข้ามใบประเมิน",
		"isAlreadyTranslated": true
	},
	"delivery confirmation sent":{
		"text":"ส่งการยืนยันการจัดส่งแล้ว",
		"isAlreadyTranslated": true
	},
	"delivery confirmation not sent":{
		"text":"ไม่ได้ส่งการยืนยันการจัดส่ง",
		"isAlreadyTranslated": true
	},
	"no logistics request found":{
		"text":"ไม่พบคำร้องขอโลจิสติกส์",
		"isAlreadyTranslated": true
	},
	"Your device is ready to be collected by you. We hope you are satisfied with the service experience. Reference ID:":{
		"text":"อุปกรณ์ของคุณพร้อมให้คุณมารับคืนแล้ว เราหวังว่าคุณจะพอใจกับการบริการของเรา ID อ้างอิง:",
		"isAlreadyTranslated": true
	},
	"Couldn't find a consumer product with the given ID":{
		"text":"ไม่พบผลิตภัณฑ์ของผู้ใช้ที่มี ID ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching consumer service request logs to calculate tax":{
		"text":"มีความผิดปกติเกิดขึ้นขณะดึงข้อมูลบันทึกคำร้องขอการบริการผู้ใช้เพื่อคำนวณภาษี",
		"isAlreadyTranslated": true
	},
	"Homepage count has been sent successfully":{
		"text":"ได้ส่งการนับจำนวนผู้เยี่ยมชมหน้าโฮมเพจสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Invalid Request Remarks":{
		"text":"หมายเหตุคำร้องขอที่ไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Something went wrong in B2X/updateJob":{
		"text":"มีความผิดปกติเกิดขึ้นใน 'B2X/อัปเดตงาน'",
		"isAlreadyTranslated": true
	},
	"Status updated":{
		"text":"อัปเดตสถานะแล้ว",
		"isAlreadyTranslated": true
	},
	"Status updated but error in updating consumer product":{
		"text":"อัปเดตสถานะแล้ว แต่เกิดข้อผิดพลาดในการอัปเดตผลิตภัณฑ์ของผู้ใช้",
		"isAlreadyTranslated": true
	},
	"logistics type updated":{
		"text":"อัปเดตประเภทโลจิสติกส์แล้ว",
		"isAlreadyTranslated": true
	},
	"logistics type not updated":{
		"text":"ไม่ได้อัปเดตประเภทโลจิสติกส์",
		"isAlreadyTranslated": true
	},
	"successfully log created":{
		"text":"สร้างบันทึกสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"remarks updated":{
		"text":"อัปเดตหมายเหตุแล้ว",
		"isAlreadyTranslated": true
	},
	"no previous log found":{
		"text":"ไม่พบบันทึกก่อนหน้า",
		"isAlreadyTranslated": true
	},
	"DOA checklist not completed":{
		"text":"รายการตรวจสอบ DOA ไม่สมบูรณ์",
		"isAlreadyTranslated": true
	},
	"DOA checklist not sent":{
		"text":"ไม่ได้ส่งรายการตรวจสอบ DOA",
		"isAlreadyTranslated": true
	},
	"Something went wrong while creating log":{
		"text":"มีความผิดปกติเกิดขึ้นขณะกำลังสร้างบันทึก",
		"isAlreadyTranslated": true
	},
	"Cannot update the status":{
		"text":"ไม่สามารถอัปเดตสถานะ",
		"isAlreadyTranslated": true
	},
	"workshop request":{
		"text":"คำร้องขอเวิร์กช็อป",
		"isAlreadyTranslated": true
	},
	"Not a workshop request":{
		"text":"ไม่ใช่คำร้องขอเวิร์กช็อป",
		"isAlreadyTranslated": true
	},
	"Invalid Service Request":{
		"text":"คำร้องขอการบริการไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Error in getting data from getCharges":{
		"text":"เกิดข้อผิดพลาดในการรับข้อมูลจาก 'รับค่าธรรมเนียม'",
		"isAlreadyTranslated": true
	},
	"Invoice details fetched":{
		"text":"ดึงข้อมูลรายละเอียดใบกำกับสินค้าแล้ว",
		"isAlreadyTranslated": true
	},
	"Error in Finding PartnerServicelocation Detail":{
		"text":"เกิดข้อผิดพลาดในการค้นหารายละเอียดของ 'ตำแหน่งการบริการคู่ค้า'",
		"isAlreadyTranslated": true
	},
	"Error in Finding Consumer Detail":{
		"text":"เกิดข้อผิดพลาดในการค้นหารายละเอียดของผู้ใช้",
		"isAlreadyTranslated": true
	},
	"No Data Found in getCharges":{
		"text":"ไม่พบข้อมูลใน 'รับค่าธรรมเนียม'",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights to delete approved claim amount":{
		"text":"คุณไม่มีสิทธิเพียงพอที่จะลบจำนวนเงินการเคลมที่อนุมัติแล้ว",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights to delete this charge":{
		"text":"คุณไม่มีสิทธิเพียงพอที่จะลบค่าธรรมเนียมนี้",
		"isAlreadyTranslated": true
	},
	"You cannot delete service request charge at the given status":{
		"text":"คุณไม่สามารถลบค่าธรรมเนียมคำร้องขอการบริการที่สถานะที่ให้มาได้",
		"isAlreadyTranslated": true
	},
	"No charge exists by this ID":{
		"text":"ไม่มีค่าธรรมเนียมอยู่จาก ID นี้",
		"isAlreadyTranslated": true
	},
	"This service request doesn't have an associated partner":{
		"text":"คำร้องขอการบริการนี้ไม่มีคู่ค้าที่สัมพันธ์กัน",
		"isAlreadyTranslated": true
	},
	"No consumer service request found by this id":{
		"text":"ไม่พบคำร้องขอการบริการผู้ใช้จาก id นี้",
		"isAlreadyTranslated": true
	},
	"No State Found":{
		"text":"ไม่พบสถานะ",
		"isAlreadyTranslated": true
	},
	"Exclusive Brands":{
		"text":"แบรนด์พิเศษ",
		"isAlreadyTranslated": true
	},
	"Exclusive Category":{
		"text":"หมวดหมู่พิเศษ",
		"isAlreadyTranslated": true
	},
	"product is not exclusive":{
		"text":"ผลิตภัณฑ์ไม่ใช่ผลิตภัณฑ์พิเศษ",
		"isAlreadyTranslated": true
	},
	"Error in fetching exclusive category":{
		"text":"เกิดข้อผิดพลาดในการดึงข้อมูลหมวดหมู่พิเศษ",
		"isAlreadyTranslated": true
	},
	"no brand found with given brand id":{
		"text":"ไม่พบแบรนด์จาก id แบรนด์ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"Error in finding brand":{
		"text":"เกิดข้อผิดพลาดในการค้นหาแบรนด์",
		"isAlreadyTranslated": true
	},
	"No consumer product found to service":{
		"text":"ไม่พบผลิตภัณฑ์ของผู้ใช้ที่จะให้บริการ",
		"isAlreadyTranslated": true
	},
	"Invalid IsNonPartner parameter":{
		"text":"พารามิเตอร์ 'ไม่ใช่คู่ค้า' ไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Cannot create the request":{
		"text":"ไม่สามารถสร้างคำร้องขอนั้น",
		"isAlreadyTranslated": true
	},
	"No slots available for appointment on this date":{
		"text":"ไม่มีช่วงเวลาสำหรับการนัดหมายในวันที่นี้",
		"isAlreadyTranslated": true
	},
	"Slots obtained":{
		"text":"ได้ช่วงเวลาแล้ว",
		"isAlreadyTranslated": true
	},
	"No SoldPlanID found":{
		"text":"ไม่พบ 'IDแผนที่ขายแล้ว'",
		"isAlreadyTranslated": true
	},
	"Servify Claim Request":{
		"text":"คำร้องขอการเคลมของ Servify",
		"isAlreadyTranslated": true
	},
	"succesfully claim raised":{
		"text":"ยื่นเรื่องการเคลมสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Product already in repair":{
		"text":"ผลิตภัณฑ์อยู่ในขั้นตอนการซ่อมอยู่แล้ว",
		"isAlreadyTranslated": true
	},
	"Error in creating service request logs":{
		"text":"เกิดข้อผิดพลาดในการสร้างบันทึกคำร้องขอการบริการ",
		"isAlreadyTranslated": true
	},
	"No document sent for adding doc":{
		"text":"ไม่มีการส่งเอกสารสำหรับการเพิ่มเอกสาร",
		"isAlreadyTranslated": true
	},
	"No new Claim request document uploaded":{
		"text":"ไม่ได้อัปโหลดเอกสารคำร้องขอการเคลมใหม่",
		"isAlreadyTranslated": true
	},
	"Documents successfully added":{
		"text":"เพิ่มเอกสารสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Status should be claim approved":{
		"text":"สถานะควรเป็นอนุมัติการเคลมแล้ว",
		"isAlreadyTranslated": true
	},
	"Request already created":{
		"text":"ได้สร้างคำร้องขอไว้แล้ว",
		"isAlreadyTranslated": true
	},
	"Error in scheduleRepair":{
		"text":"เกิดข้อผิดพลาดใน 'กำหนดเวลาการซ่อม'",
		"isAlreadyTranslated": true
	},
	"no product found for given consumer product ID":{
		"text":"ไม่พบผลิตภัณฑ์สำหรับ ID ผลิตภัณฑ์ของผู้ใช้ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"No consumer service data found":{
		"text":"ไม่พบข้อมูลการบริการผู้ใช้",
		"isAlreadyTranslated": true
	},
	"no  request id found":{
		"text":"ไม่พบ id คำร้องขอ",
		"isAlreadyTranslated": true
	},
	"no service center found with given id":{
		"text":"ไม่พบศูนย์บริการด้วย id ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"Successfully Carry in Request Created":{
		"text":"สร้างคำร้องขอโดยนำอุปกรณ์เข้ามาเองสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Please Provide Valid Servie Type":{
		"text":"โปรดระบุประเภทการบริการที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Call not Attempted":{
		"text":"ไม่มีการพยายามโทร",
		"isAlreadyTranslated": true
	},
	"Connected Call":{
		"text":"การโทรที่เชื่อมต่ออยู่",
		"isAlreadyTranslated": true
	},
	"Non-Connected Call":{
		"text":"การโทรที่ไม่ได้เชื่อมต่อ",
		"isAlreadyTranslated": true
	},
	"Call Log Details":{
		"text":"รายละเอียดบันทึกการโทร",
		"isAlreadyTranslated": true
	},
	"Attached with this mail is the call logs data":{
		"text":"ที่แนบมากับเมลนี้คือข้อมูลบันทึกการโทร",
		"isAlreadyTranslated": true
	},
	"No logs found":{
		"text":"ไม่พบบันทึก",
		"isAlreadyTranslated": true
	},
	"ConsumerComplaints success":{
		"text":"ความสำเร็จของ 'ข้อร้องเรียนของผู้ใช้'",
		"isAlreadyTranslated": true
	},
	"ConsumerComplaints failed":{
		"text":"'ข้อร้องเรียนของผู้ใช้' ไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"address cannot be updated":{
		"text":"ไม่สามารถอัปเดตที่อยู่ได้",
		"isAlreadyTranslated": true
	},
	"address cannot be deleted":{
		"text":"ไม่สามารถลบที่อยู่ได้",
		"isAlreadyTranslated": true
	},
	"User location fetched":{
		"text":"ดึงข้อมูลตำแหน่งผู้ใช้แล้ว",
		"isAlreadyTranslated": true
	},
	"user locations for product fetched":{
		"text":"ดึงข้อมูลตำแหน่งผู้ใช้สำหรับผลิตภัณฑ์แล้ว",
		"isAlreadyTranslated": true
	},
	"Query Result not found":{
		"text":"ไม่พบผลลัพธ์ของแบบสอบถาม",
		"isAlreadyTranslated": true
	},
	"Query Result of location not found":{
		"text":"ไม่พบตำแหน่งของแบบสอบถาม",
		"isAlreadyTranslated": true
	},
	"Product documents added":{
		"text":"เพิ่มเอกสารผลิตภัณฑ์แล้ว",
		"isAlreadyTranslated": true
	},
	"failed to add product documents":{
		"text":"เพิ่มเอกสารผลิตภัณฑ์ไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Successfully deleted":{
		"text":"ลบสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"success in getting brand number":{
		"text":"ประสบความสำเร็จในการรับหมายเลขแบรนด์",
		"isAlreadyTranslated": true
	},
	"No Brand Found for given brandID":{
		"text":"ไม่พบแบรนด์สำหรับ 'IDแบรนด์' ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"successfully created  product":{
		"text":"สร้างผลิตภัณฑ์สำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Serial Number Not Unique":{
		"text":"เลขประจำเครื่องซ้ำ",
		"isAlreadyTranslated": true
	},
	"device added successfully":{
		"text":"เพิ่มอุปกรณ์สำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Failed to create ConsumerUnregisteredProduct":{
		"text":"สร้าง 'ผลิตภัณฑ์ที่ไม่ได้ลงทะเบียนของผู้ใช้' ไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Purchase date cannot be greater than device damage date":{
		"text":"วันที่ซื้อไม่สามารถเป็นวันหลังจากวันที่อุปกรณ์เสียหาย",
		"isAlreadyTranslated": true
	},
	"Cannot activate ADPP after":{
		"text":"ไม่สามารถเปิดใช้งาน ADPP หลังจาก",
		"isAlreadyTranslated": true
	},
	"No need to activate sold plan as soldplan status =":{
		"text":"ไม่จำเป็นต้องเปิดใช้งานแผนการขายตามสถานะ 'แผนที่ขายแล้ว' =",
		"isAlreadyTranslated": true
	},
	"ConsumerUnregisteredProduct made inactive":{
		"text":"'ผลิตภัณฑ์ที่ไม่ได้ลงทะเบียนของผู้ใช้' ถูกทำให้ไม่เปิดใช้งาน",
		"isAlreadyTranslated": true
	},
	"ConsumerProduct made inactive":{
		"text":"'ผลิตภัณฑ์ของผู้ใช้' ถูกทำให้ไม่เปิดใช้งาน",
		"isAlreadyTranslated": true
	},
	"Filtered devices of consumer returned":{
		"text":"ส่งอุปกรณ์ที่กรองแล้วของผู้ใช้กลับคืนแล้ว",
		"isAlreadyTranslated": true
	},
	"No devices under repair":{
		"text":"ไม่มีอุปกรณ์อยู่ในขั้นตอนการซ่อม",
		"isAlreadyTranslated": true
	},
	"No devices in repair history yet":{
		"text":"ยังไม่มีอุปกรณ์อยู่ในประวัติการซ่อม",
		"isAlreadyTranslated": true
	},
	"Device detail obtained":{
		"text":"ได้รับรายละเอียดของอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"Updated Plan":{
		"text":"แผนที่อัปเดต",
		"isAlreadyTranslated": true
	},
	"No ConsumerProduct Found with given imei":{
		"text":"ไม่พบ 'ผลิตภัณฑ์ของผู้ใช้' ที่มี imei ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"Something Erroneous happened while fetching registered product details":{
		"text":"เกิดสิ่งผิดพลาดขึ้นขณะดึงข้อมูลรายละเอียดผลิตภัณฑ์ที่ลงทะเบียนแล้ว",
		"isAlreadyTranslated": true
	},
	"Consumer Product is not a deleted product":{
		"text":"ผลิตภัณฑ์ของผู้ใช้ไม่ใช่ผลิตภัณฑ์ที่ลบ",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send ConsumerProductID":{
		"text":"พารามิเตอร์ไม่เพียงพอ - ส่ง 'IDผลิตภัณฑ์ของผู้ใช้'",
		"isAlreadyTranslated": true
	},
	"Something Erroneous happened while updating the consumer product":{
		"text":"เกิดสิ่งผิดพลาดขึ้นขณะอัปเดตผลิตภัณฑ์ของผู้ใช้",
		"isAlreadyTranslated": true
	},
	"update ConsumerUnregisteredProduct data and converted it to a ConsumerProduct":{
		"text":"อัปเดตข้อมูล 'ผลิตภัณฑ์ที่ไม่ได้ลงทะเบียนของผู้ใช้' และแปลงข้อมูลเป็น 'ผลิตภัณฑ์ของผู้ใช้'",
		"isAlreadyTranslated": true
	},
	"Something went wrong with findById in ConsumerUnregisteredProduct table":{
		"text":"เกิดสิ่งผิดพลาดขึ้นกับ 'ค้นหาตามID' ในตาราง 'ผลิตภัณฑ์ที่ไม่ได้ลงทะเบียนของผู้ใช้'",
		"isAlreadyTranslated": true
	},
	"Device has been validated":{
		"text":"ได้ยืนยันความถูกต้องของอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"Device has been validated without response":{
		"text":"ได้ยืนยันความถูกต้องของอุปกรณ์แล้วโดยไม่มีการตอบสนอง",
		"isAlreadyTranslated": true
	},
	"Unknown Model":{
		"text":"รุ่นที่ไม่รู้จัก",
		"isAlreadyTranslated": true
	},
	"Successfully Returned Model Properties":{
		"text":"คุณสมบัติของรุ่นที่ส่งคืนสำเร็จ",
		"isAlreadyTranslated": true
	},
	"Error in fetching consumer product details":{
		"text":"ข้อผิดพลาดในการดึงข้อมูลรายละเอียดผลิตภัณฑ์ของผู้ใช้",
		"isAlreadyTranslated": true
	},
	"ConsumerProduct details fetched":{
		"text":"ดึงข้อมูลรายละเอียดของ 'ผลิตภัณฑ์ของผู้ใช้' แล้ว",
		"isAlreadyTranslated": true
	},
	"Error in adding device":{
		"text":"เกิดข้อผิดพลาดในการเพิ่มอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Error in sending Sms":{
		"text":"เกิดข้อผิดพลาดในการส่ง SMS",
		"isAlreadyTranslated": true
	},
	"Successfully sent Sms":{
		"text":"ส่ง SMS สำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Cannot send sms":{
		"text":"ไม่สามารถส่ง SMS",
		"isAlreadyTranslated": true
	},
	"Error in updating data":{
		"text":"เกิดข้อผิดพลาดในการอัปเดตข้อมูล",
		"isAlreadyTranslated": true
	},
	"Imei already exist":{
		"text":"IMEI มีอยู่แล้ว",
		"isAlreadyTranslated": true
	},
	"No SerialNo or Imei Present - GSX call not required":{
		"text":"ไม่มี SerialNo หรือ Imei อยู่ - ไม่ต้องทำการโทร GSX",
		"isAlreadyTranslated": true
	},
	"This account/device has been blocked. For assistance, reach out to us.":{
		"text":"บัญชี/อุปกรณ์นี้ถูกระงับอยู่แล้ว หากต้องการความช่วยเหลือ โปรดติดต่อมาหาเรา",
		"isAlreadyTranslated": true
	},
	"Looks like the @UNIQUE_ID_TYPE Number you have provided is invalid. Note that multiple invalid attempts may block this account/device":{
		"text":"ดูเหมือนว่าหมายเลข @UNIQUE_ID_TYPE ที่คุณให้ไว้จะไม่ถูกต้อง โปรดทราบว่าหากพยายามหลายครั้งโดยใช้หมายเลขที่ไม่ถูกต้อง อาจระงับบัญชี/อุปกรณ์เครื่องนี้ได้",
		"isAlreadyTranslated": true
	},
	"An unknown error occurred":{
		"text":"เกิดข้อผิดพลาดที่ไม่มีชื่อ",
		"isAlreadyTranslated": true
	},
	"Error in finding product name match":{
		"text":"เกิดข้อผิดพลาดในการค้นหารายการผลิตภัณฑ์ที่ตรงกัน",
		"isAlreadyTranslated": true
	},
	"Error in finding product subcategory":{
		"text":"เกิดข้อผิดพลาดในการค้นหาหมวดย่อยของผลิตภัณฑ์",
		"isAlreadyTranslated": true
	},
	"Product details fetched":{
		"text":"ดึงข้อมูลรายละเอียดผลิตภัณฑ์แล้ว",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send Either Device Object or Imei/SerialNo":{
		"text":"พารามิเตอร์ไม่เพียงพอ - ส่งออบเจกต์อุปกรณ์หรือ 'Imei/เลขประจำเครื่อง'",
		"isAlreadyTranslated": true
	},
	"Plan successfully created":{
		"text":"สร้างแผนสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Sorry! No Plans are available for this device":{
		"text":"ขออภัย! ไม่มีแผนที่พร้อมสำหรับอุปกรณ์เครื่องนี้",
		"isAlreadyTranslated": true
	},
	"NO_PLANS_AVAILABLE":{
		"text":"NO_PLANS_AVAILABLE",
		"isAlreadyTranslated": true
	},
	"This device is already covered under AppleCare Protection Plan":{
		"text":"อุปกรณ์เครื่องนี้ได้รับความคุ้มครองภายใต้แผนป้องกัน AppleCare",
		"isAlreadyTranslated": true
	},
	"ALREADY_PURCHASED":{
		"text":"ALREADY_PURCHASED",
		"isAlreadyTranslated": true
	},
	"Limit for imei checks exceeded":{
		"text":"เกินขีดจำกัดสำหรับการพยายามตรวจสอบ imei",
		"isAlreadyTranslated": true
	},
	"Your Device is not under warranty":{
		"text":"อุปกรณ์ของคุณไม่อยู่ภายใต้การรับประกัน",
		"isAlreadyTranslated": true
	},
	"NOT_ELIGIBLE":{
		"text":"NOT_ELIGIBLE",
		"isAlreadyTranslated": true
	},
	"Invalid serial number":{
		"text":"เลขประจำเครื่องไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"INCORRECT_SERIAL_NO":{
		"text":"INCORRECT_SERIAL_NO",
		"isAlreadyTranslated": true
	},
	"All applicable plans are activated for this device.":{
		"text":"เปิดใช้งานแผนที่ปรับใช้ได้ทั้งหมดสำหรับอุปกรณ์เครื่องนี้",
		"isAlreadyTranslated": true
	},
	"Plan is already active against this device":{
		"text":"เปิดใช้งานแผนไว้แล้วกับอุปกรณ์เครื่องนี้",
		"isAlreadyTranslated": true
	},
	"Plans Fetched":{
		"text":"ดึงข้อมูลแผนแล้ว",
		"isAlreadyTranslated": true
	},
	"IS_ELIGIBLE":{
		"text":"IS_ELIGIBLE",
		"isAlreadyTranslated": true
	},
	"Please enter a valid serial number":{
		"text":"โปรดป้อนเลขประจำเครื่องที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Oops! Looks like this device is no longer covered under warranty":{
		"text":"โอ๊ะ! ดูเหมือนว่าอุปกรณ์เครื่องนี้จะไม่ได้รับความคุ้มครองภายใต้การรับประกันแล้ว",
		"isAlreadyTranslated": true
	},
	"Provide a valid ConsumerProductID":{
		"text":"ระบุ 'IDผลิตภัณฑ์ของผู้ใช้' ที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Product mismatch":{
		"text":"ผลิตภัณฑ์ไม่ตรงกัน",
		"isAlreadyTranslated": true
	},
	"Device already in service":{
		"text":"ผลิตภัณฑ์อยู่ในขั้นตอนการบริการ",
		"isAlreadyTranslated": true
	},
	"SERIAL_NO_OBSOLETE":{
		"text":"SERIAL_NO_OBSOLETE",
		"isAlreadyTranslated": true
	},
	"No checks yet":{
		"text":"ยังไม่ได้ตรวจสอบ",
		"isAlreadyTranslated": true
	},
	"Provide a valid check type":{
		"text":"ระบุประเภทการตรวจสอบที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Plan Invoice fetched":{
		"text":"ดึงข้อมูลใบกำกับสินค้าของแผน",
		"isAlreadyTranslated": true
	},
	"Email address cannot exceed 70 characters":{
		"text":"ที่อยู่อีเมลไม่สามารถมีอักขระเกิน 70 ตัว",
		"isAlreadyTranslated": true
	},
	"Please provide a valid EmailID":{
		"text":"โปรดระบุ 'IDอีเมล' ที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"First Name/Last Name cannot exceed 34 characters.":{
		"text":"ชื่อ/นามสกุลไม่สามารถมีอักขระเกิน 34 ตัว",
		"isAlreadyTranslated": true
	},
	"Oops! This device is no longer covered under warranty":{
		"text":"โอ๊ะ! อุปกรณ์เครื่องนี้ไม่ได้รับความคุ้มครองภายใต้การรับประกันแล้ว",
		"isAlreadyTranslated": true
	},
	"This action is not allowed":{
		"text":"การดำเนินการนี้ไม่ได้รับอนุญาต",
		"isAlreadyTranslated": true
	},
	"Error in adding docs":{
		"text":"เกิดข้อผิดพลาดในการเพิ่มเอกสาร",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters":{
		"text":"พารามิเตอร์ไม่เพียงพอ",
		"isAlreadyTranslated": true
	},
	"Error in getPlanInvoice":{
		"text":"เกิดข้อผิดพลาดใน 'รับใบกำกับสินค้าของแผน'",
		"isAlreadyTranslated": true
	},
	"Device name is required":{
		"text":"ต้องมีชื่ออุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Device Config fetched":{
		"text":"ดึงข้อมูลการกำหนดค่าของอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"No config found for the Device":{
		"text":"ไม่พบการกำหนดค่าสำหรับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Error in fetching device config":{
		"text":"เกิดข้อผิดพลาดในการดึงข้อมูลการกำหนดค่าของอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Unique product sub category ids":{
		"text":"id หมวดย่อยของผลิตภัณฑ์ที่ไม่ซ้ำกัน",
		"isAlreadyTranslated": true
	},
	"Something wrong while finding products":{
		"text":"มีความผิดปกติเกิดขึ้นขณะค้นหาผลิตภัณฑ์",
		"isAlreadyTranslated": true
	},
	"Something wrong while finding devices":{
		"text":"มีความผิดปกติเกิดขึ้นขณะค้นหาอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"No records to update":{
		"text":"ไม่มีระเบียนสำหรับการอัปเดต",
		"isAlreadyTranslated": true
	},
	"Update failed":{
		"text":"การอัปเดตล้มเหลว",
		"isAlreadyTranslated": true
	},
	"Error in file conversion":{
		"text":"เกิดข้อผิดพลาดในการแปลงไฟล์",
		"isAlreadyTranslated": true
	},
	"Results fetched":{
		"text":"ดึงข้อมูลผลลัพธ์แล้ว",
		"isAlreadyTranslated": true
	},
	"We have saved the details of this device.":{
		"text":"เราได้บันทึกรายละเอียดของอุปกรณ์เครื่องนี้แล้ว",
		"isAlreadyTranslated": true
	},
	"Great!":{
		"text":"เยี่ยมมาก",
		"isAlreadyTranslated": true
	},
	"Looks like you have exhausted the number of attempts to activate the enteprise support plan on your device. Please contact Servify for more assistance":{
		"text":"ดูเหมือนว่าคุณหมดความพยายามที่จะเปิดใช้งานแผนสนับสนุนแบบองค์กรบนอุปกรณ์ของคุณแล้ว โปรดติดต่อ Servify เพื่อขอความช่วยเหลือเพิ่มเติม",
		"isAlreadyTranslated": true
	},
	"Oops!":{
		"text":"โอ๊ะ!",
		"isAlreadyTranslated": true
	},
	"It looks like this device is protected under Apple Enterprise Support Plan.":{
		"text":"ดูเหมือนว่าอุปกรณ์เครื่องนี้จะได้รับความคุ้มครองภายใต้แผนการสนับสนุนแบบ Enterprise ของ Apple",
		"isAlreadyTranslated": true
	},
	"Congratulations!":{
		"text":"ขอแสดงความยินดี!",
		"isAlreadyTranslated": true
	},
	"Error in validating serial number":{
		"text":"เกิดข้อผิดพลาดในการยืนยันความถูกต้องของเลขประจำเครื่อง",
		"isAlreadyTranslated": true
	},
	"This device is eligible for purchasing the plan":{
		"text":"อุปกรณ์เครื่องนี้มีคุณสมบัติเข้าเกณฑ์สำหรับการซื้อแผน",
		"isAlreadyTranslated": true
	},
	"This device is already covered under AppleCare Protection Plan":{
		"text":"อุปกรณ์เครื่องนี้ได้รับความคุ้มครองภายใต้แผนป้องกัน AppleCare",
		"isAlreadyTranslated": true
	},
	"Device transferred":{
		"text":"โอนย้ายอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"Invalid Mobile No":{
		"text":"หมายเลขมือถือไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"INVALID_MOBILE":{
		"text":"INVALID_MOBILE",
		"isAlreadyTranslated": true
	},
	"Invalid Imei or Serial No":{
		"text":"Imei หรือเลขประจำเครื่องไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"INVALID_IMEI_OR_SERIAL":{
		"text":"INVALID_IMEI_OR_SERIAL",
		"isAlreadyTranslated": true
	},
	"details updated successfully":{
		"text":"อัปเดตรายละเอียดสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Service request document deleted":{
		"text":"ลบเอกสารคำร้องขอการบริการแล้ว",
		"isAlreadyTranslated": true
	},
	"Already rated for this particular service request":{
		"text":"ให้คะแนนสำหรับคำร้องขอการบริการนี้เป็นการเฉพาะแล้ว",
		"isAlreadyTranslated": true
	},
	"No feed back created":{
		"text":"ไม่มีการสร้างข้อคิดเห็น",
		"isAlreadyTranslated": true
	},
	"Invalid ServiceLocationAppointmentID":{
		"text":"'IDการนัดหมายตำแหน่งการบริการ' ไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Service status updated":{
		"text":"อัปเดตสถานะการบริการแล้ว",
		"isAlreadyTranslated": true
	},
	"Rating details has been successfully sent.":{
		"text":"ได้ส่งรายละเอียดการให้คะแนนสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"All issues to be validated should have ConsumerServiceRequestIssuesId":{
		"text":"ปัญหาทั้งหมดที่ต้องยืนยันความถูกต้องควรมี 'Idปัญหาคำร้องขอการบริการผู้ใช้'",
		"isAlreadyTranslated": true
	},
	"Something went wrong while updating issue":{
		"text":"มีความผิดปกติเกิดขึ้นขณะอัปเดตปัญหา",
		"isAlreadyTranslated": true
	},
	"Something went wrong while finding issue with id":{
		"text":"มีความผิดปกติเกิดขึ้นขณะค้นหาปัญหาที่มี id",
		"isAlreadyTranslated": true
	},
	"All issues to be added should have ProductSubCategoryIssueID":{
		"text":"ปัญหาทั้งหมดที่ต้องเพิ่มควรมี 'IDปัญหาหมวดย่อยของผลิตภัณฑ์'",
		"isAlreadyTranslated": true
	},
	"All issues to be added should not have ConsumerServiceRequestIssuesId":{
		"text":"ปัญหาทั้งหมดที่ต้องเพิ่มไม่ควรมี 'IDปัญหาคำร้องขอการบริการผู้ใช้'",
		"isAlreadyTranslated": true
	},
	"You cannot delete a reported issue":{
		"text":"คุณไม่สามารถลบปัญหาที่รายงานแล้ว",
		"isAlreadyTranslated": true
	},
	"No such issue exists for this consumer service request id":{
		"text":"ไม่มีปัญหาดังกล่าวสำหรับ id คำร้องขอการบริการผู้ใช้นี้",
		"isAlreadyTranslated": true
	},
	"Engineer is not register with this request":{
		"text":"ไม่มีการลงทะเบียนวิศวกรกับคำร้องขอนี้",
		"isAlreadyTranslated": true
	},
	"Please provide at least on issue":{
		"text":"โปรดระบุปัญหาอย่างน้อยหนึ่งข้อ",
		"isAlreadyTranslated": true
	},
	"Please provide warranty reason":{
		"text":"โปรดระบุเหตุผลการรับประกัน",
		"isAlreadyTranslated": true
	},
	"Added issue updation failed":{
		"text":"การอัปเดตปัญหาที่เพิ่มแล้วไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Service request information fetching failed.":{
		"text":"การดึงข้อมูลคำร้องขอการบริการไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"issue has been validated":{
		"text":"ได้ยืนยันความถูกต้องของปัญหาแล้ว",
		"isAlreadyTranslated": true
	},
	"issue has not been validated yet":{
		"text":"ยังไม่ได้ยืนยันความถูกต้องของปัญหา",
		"isAlreadyTranslated": true
	},
	"failed to check issue validation":{
		"text":"ตรวจสอบการยืนยันความถูกต้องของปัญหาไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Invoice has not been generated yet":{
		"text":"ยังไม่ได้สร้างใบกำกับสินค้า",
		"isAlreadyTranslated": true
	},
	"Final invoice has been generated":{
		"text":"สร้างใบกำกับสินค้าขั้นสุดท้ายแล้ว",
		"isAlreadyTranslated": true
	},
	"Final invoice not generated":{
		"text":"ไม่ได้สร้างใบกำกับสินค้าขั้นสุดท้าย",
		"isAlreadyTranslated": true
	},
	"eRecycle Request details:":{
		"text":"รายละเอียดคำร้องขอ eRecycle:",
		"isAlreadyTranslated": true
	},
	"Customer Name :":{
		"text":"ชื่อลูกค้า :",
		"isAlreadyTranslated": true
	},
	"Phone Number :":{
		"text":"หมายเลขโทรศัพท์ :",
		"isAlreadyTranslated": true
	},
	"Device Detail :":{
		"text":"รายละเอียดอุปกรณ์ :",
		"isAlreadyTranslated": true
	},
	"Day of Visit":{
		"text":"วันที่ไป",
		"isAlreadyTranslated": true
	},
	"Time of Visit :":{
		"text":"เวลาที่ไป :",
		"isAlreadyTranslated": true
	},
	"Regards,":{
		"text":"ด้วยความเคารพ",
		"isAlreadyTranslated": true
	},
	"New eRecycle Drop off Request: ID":{
		"text":"คำร้องขอใหม่สำหรับการส่งอุปกรณ์ eRecycle: ID",
		"isAlreadyTranslated": true
	},
	"New eRecycle Pickup Request: ID":{
		"text":"คำร้องขอใหม่สำหรับการรับอุปกรณ์ eRecycle: ID",
		"isAlreadyTranslated": true
	},
	"vendor id is not valid for fareye tracking":{
		"text":"id ผู้จำหน่ายไม่ถูกต้องสำหรับการติดตาม fareye",
		"isAlreadyTranslated": true
	},
	"please provide Pincode and PlanID":{
		"text":"โปรดระบุรหัส PIN และ 'IDแผน'",
		"isAlreadyTranslated": true
	},
	"Request Is Reschedulable":{
		"text":"คำร้องขอสามารถกำหนดเวลาใหม่ได้",
		"isAlreadyTranslated": true
	},
	"Request Is Not Reschedulable":{
		"text":"คำร้องขอไม่สามารถกำหนดเวลาใหม่ได้",
		"isAlreadyTranslated": true
	},
	"Service is already booked for ( )":{
		"text":"จองการบริการแล้วสำหรับ ( )",
		"isAlreadyTranslated": true
	},
	"Please call us at  for further queries":{
		"text":"หากมีข้อสงสัยเพิ่มเติม โปรดโทรหาเราที่",
		"isAlreadyTranslated": true
	},
	"Service cannot be created. Please call us at  for further queries":{
		"text":"ไม่สามารถสร้างบริการได้ หากมีข้อสงสัยเพิ่มเติม โปรดโทรหาเราที่",
		"isAlreadyTranslated": true
	},
	"no service locations found for this brand":{
		"text":"ไม่พบตำแหน่งการบริการสำหรับแบรนด์นี้",
		"isAlreadyTranslated": true
	},
	"error occured in finding service locations":{
		"text":"เกิดข้อผิดพลาดขึ้นในการค้นหาตำแหน่งการบริการ",
		"isAlreadyTranslated": true
	},
	"Updated Serial Number":{
		"text":"เลขประจำเครื่องที่อัปเดตแล้ว",
		"isAlreadyTranslated": true
	},
	"No Updated Consumer Product Found":{
		"text":"ไม่พบผลิตภัณฑ์ของผู้ใช้ที่อัปเดต",
		"isAlreadyTranslated": true
	},
	"No Consumer Product Found":{
		"text":"ไม่พบผลิตภัณฑ์ของผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Serial Number Already Exist With Other Device":{
		"text":"มีเลขประจำเครื่องอยู่แล้วกับอุปกรณ์อื่น",
		"isAlreadyTranslated": true
	},
	"Serial number should be unique":{
		"text":"เลขประจำเครื่องไม่ควรซ้ำ",
		"isAlreadyTranslated": true
	},
	"Error in function paymentAction":{
		"text":"เกิดข้อผิดพลาดในฟังก์ชั่น 'การดำเนินการชำระเงิน'",
		"isAlreadyTranslated": true
	},
	"Not servicable at this region":{
		"text":"ไม่สามารถให้บริการได้ในภูมิภาคนี้",
		"isAlreadyTranslated": true
	},
	"Cannot create on site request":{
		"text":"ไม่สามารถสร้างคำร้องขอนอกสถานที่",
		"isAlreadyTranslated": true
	},
	"User cannot raise request for this service type id":{
		"text":"ผู้ใช้ไม่สามารถยื่นเรื่องคำร้องขอสำหรับ id ประเภทการบริการนี้",
		"isAlreadyTranslated": true
	},
	"Invalid csrid":{
		"text":"csrid ไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"DOA certificate has been generated .Please collect your device and DOA certificate":{
		"text":"มีการสร้างใบรับรอง DOA แล้ว โปรดมารับอุปกรณ์ของคุณและใบรับรอง DOA",
		"isAlreadyTranslated": true
	},
	"DOA certificate generated":{
		"text":"สร้างใบรับรอง DOA แล้ว",
		"isAlreadyTranslated": true
	},
	"Awesome! Your device is replaced and ready to be collected":{
		"text":"เยี่ยมมาก! อุปกรณ์ของคุณได้รับการเปลี่ยนและพร้อมสำหรับการรับคืน",
		"isAlreadyTranslated": true
	},
	"Claim Intimation Number Generated":{
		"text":"สร้างหมายเลขแจ้งการเคลมแล้ว",
		"isAlreadyTranslated": true
	},
	"Estimation is Already approved by service center":{
		"text":"ใบประเมินได้รับการอนุมัติแล้วโดยศูนย์บริการ",
		"isAlreadyTranslated": true
	},
	"Estimation Status updated":{
		"text":"อัปเดตสถานะใบประเมินแล้ว",
		"isAlreadyTranslated": true
	},
	"Service not available":{
		"text":"การบริการไม่พร้อม",
		"isAlreadyTranslated": true
	},
	"Slots has been sent successfully.":{
		"text":"ได้ส่งช่วงเวลาสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Service centre detail not found":{
		"text":"ไม่พบรายละเอียดศูนย์บริการ",
		"isAlreadyTranslated": true
	},
	"Please provide valid condition":{
		"text":"โปรดระบุเงื่อนไขที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"updated consumer service request":{
		"text":"คำร้องขอการบริการผู้ใช้ที่อัปเดตแล้ว",
		"isAlreadyTranslated": true
	},
	"No Consumer Service request found with given id":{
		"text":"ไม่พบคำร้องขอการบริการผู้ใช้ที่มี id ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"no consumer service request created":{
		"text":"ไม่ได้สร้างคำร้องขอการบริการผู้ใช้",
		"isAlreadyTranslated": true
	},
	"instance updated succesfully":{
		"text":"อัปเดตอินสแตนซ์สำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"no id found in ServiceLocationAppointment":{
		"text":"ไม่พบ id ใน 'การนัดหมายตำแหน่งการบริการ'",
		"isAlreadyTranslated": true
	},
	"New appointment created":{
		"text":"สร้างนัดหมายใหม่แล้ว",
		"isAlreadyTranslated": true
	},
	"no data created in ServiceLocationAppointment":{
		"text":"ไม่ได้สร้างข้อมูลใน 'การนัดหมายตำแหน่งการบริการ'",
		"isAlreadyTranslated": true
	},
	"Cannot cancel this request":{
		"text":"ไม่สามารถยกเลิกคำร้องขอนี้",
		"isAlreadyTranslated": true
	},
	"Your eRecycle Request Has Been Cancelled | Ref. ID.":{
		"text":"คำร้องขอ eRecycle ของคุณได้ถูกยกเลิกแล้ว | ID อ้างอิง",
		"isAlreadyTranslated": true
	},
	"cannot allocate the vouchers":{
		"text":"ไม่สามารถจัดสรรคูปองได้",
		"isAlreadyTranslated": true
	},
	"Service cancelled successfully":{
		"text":"ยกเลิกการบริการสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Provided date should not be less then current":{
		"text":"วันที่ที่ให้มาต้องไม่ใช่วันก่อนหน้าวันปัจจุบัน",
		"isAlreadyTranslated": true
	},
	"Can't reschedule now":{
		"text":"ไม่สามารถกำหนดเวลาใหม่ได้ในตอนนี้",
		"isAlreadyTranslated": true
	},
	"Cannot reschedule on same date and slot":{
		"text":"ไม่สามารถกำหนดเวลาใหม่เป็นวันเดียวกันและช่วงเวลาเดียวกันได้",
		"isAlreadyTranslated": true
	},
	"Can't reschedule more than 3 times":{
		"text":"ไม่สามารถกำหนดเวลาใหม่ได้มากกว่า 3 ครั้ง",
		"isAlreadyTranslated": true
	},
	"Already booked on this date and slot":{
		"text":"ได้จองวันที่และช่วงเวลานี้ไว้แล้ว",
		"isAlreadyTranslated": true
	},
	"no consumer servicerequest logistics  request found":{
		"text":"ไม่พบคำร้องขอโลจิสติกส์ 'คำร้องขอการบริการ' ผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Non Partnered Track Request Details returned":{
		"text":"รายละเอียดการติดตามคำร้องขอที่ไม่ใช่คู่ค้าถูกส่งคืนแล้ว",
		"isAlreadyTranslated": true
	},
	"Non Partnered Track Request Details":{
		"text":"รายละเอียดการติดตามคำร้องขอที่ไม่ใช่คู่ค้า",
		"isAlreadyTranslated": true
	},
	"No PartnerServicelocation found from PartnerServicelocationID":{
		"text":"ไม่พบ 'ตำแหน่งการบริการคู่ค้า' จาก 'IDตำแหน่งการบริการคู่ค้า'",
		"isAlreadyTranslated": true
	},
	"no ConsumerProduct found from ConsumerProductID":{
		"text":"ไม่พบ 'ผลิตภัณฑ์ของผู้ใช้' จาก 'IDผลิตภัณฑ์ของผู้ใช้'",
		"isAlreadyTranslated": true
	},
	"no ServiceLocationAppointment found from ServiceLocationAppointmentID":{
		"text":"ไม่พบ 'การนัดหมายตำแหน่งการบริการ' จาก 'IDการนัดหมายตำแหน่งการบริการ'",
		"isAlreadyTranslated": true
	},
	"unable to get the data from ConsumerServiceRequestID":{
		"text":"ไม่สามารถรับข้อมูลจาก 'IDคำร้องขอการบริการผู้ใช้'",
		"isAlreadyTranslated": true
	},
	"ServiceLocationApiRequest called for estimation failed":{
		"text":"'คำร้องขอApiตำแหน่งการบริการ' ที่เรียกขอใบประเมินไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Consumer Unregistered Product Service Request created":{
		"text":"สร้างคำร้องขอการบริการผลิตภัณฑ์ที่ไม่ได้ลงทะเบียนของผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Found product price level applicability and other details":{
		"text":"พบระดับราคาผลิตภัณฑ์ที่สามารถนำมาปรับใช้ได้ และรายละเอียดอื่นๆ",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching product price level applicability and other details":{
		"text":"มีความผิดปกติเกิดขึ้นขณะดึงข้อมูลระดับราคาผลิตภัณฑ์ที่สามารถนำมาปรับใช้ได้ และรายละเอียดอื่นๆ",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching issues":{
		"text":"มีความผิดปกติเกิดขึ้นขณะดึงข้อมูลปัญหา",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching consumer service request issues":{
		"text":"มีความผิดปกติเกิดขึ้นขณะดึงข้อมูลปัญหาเกี่ยวกับคำร้องขอการบริการผู้ใช้",
		"isAlreadyTranslated": true
	},
	"No consumer service request was found associated with the given id":{
		"text":"ไม่พบคำร้องขอการบริการผู้ใช้ที่เกี่ยวข้องกับ id ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"Claim Request Not At The Required Stage To Be Merged":{
		"text":"Claim Request Not At The Required Stage To Be Merged",
		"isAlreadyTranslated": true
	},
	"Normal Request Not At The Required Stage To Be Merged":{
		"text":"Normal Request Not At The Required Stage To Be Merged",
		"isAlreadyTranslated": true
	},
	"No details found for the given ConsumerID":{
		"text":"ไม่พบรายละเอียดสำหรับ 'IDผู้ใช้' ที่ให้มา",
		"isAlreadyTranslated": true
	},
	"Error in fetching service details":{
		"text":"เกิดข้อผิดพลาดในการดึงข้อมูลรายละเอียดการบริการ",
		"isAlreadyTranslated": true
	},
	"Some issues are already present for this consumer service request":{
		"text":"บางปัญหามีอยู่แล้วสำหรับคำร้องขอการบริการผู้ใช้นี้",
		"isAlreadyTranslated": true
	},
	"No results found for the given claimRequestRefID OR Status Is Not At In-principle approved' Stage":{
		"text":"ไม่พบผลการค้นหาสำหรับสถานะ 'IDอ้างอิงคำร้องขอการเคลม' ที่ให้มา หรือ สถานะไม่อยู่ที่ In-principle approved",
		"isAlreadyTranslated": true
	},
	"ServicetypeID of the request does not match":{
		"text":"'IDประเภทการบริการ' ของคำร้องขอไม่ตรงกัน",
		"isAlreadyTranslated": true
	},
	"Error in Cancelling ServiceRequest":{
		"text":"เกิดข้อผิดพลาดในการยกเลิก 'คำร้องขอการบริการ'",
		"isAlreadyTranslated": true
	},
	"Error in updating claim request":{
		"text":"เกิดข้อผิดพลาดในการอัปเดตคำร้องขอการเคลม",
		"isAlreadyTranslated": true
	},
	"No Pickup Request Accepted Log Found For RequestID":{
		"text":"ไม่พบบันทึกการตอบรับคำร้องขอการรับอุปกรณ์สำหรับ 'IDคำร้องขอ'",
		"isAlreadyTranslated": true
	},
	"Error in updating serviceRequestID for logisticsID":{
		"text":"เกิดข้อผิดพลาดในการอัปเดต 'IDคำร้องขอการบริการ' สำหรับ 'IDโลจิสติกส์'",
		"isAlreadyTranslated": true
	},
	"Request successfully merged":{
		"text":"ผนวกรวมคำร้องขอสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Something Erroneous happened while fetching partner details":{
		"text":"เกิดสิ่งผิดพลาดขึ้นขณะดึงข้อมูลรายละเอียดคู่ค้า",
		"isAlreadyTranslated": true
	},
	"No record found":{
		"text":"ไม่พบระเบียน",
		"isAlreadyTranslated": true
	},
	"no appointment found":{
		"text":"ไม่พบการนัดหมาย",
		"isAlreadyTranslated": true
	},
	"The brand is no longer active for this product":{
		"text":"แบรนด์นี้ไม่สามารถใช้งานกับผลิตภัณฑ์นี้ได้อีกต่อไป",
		"isAlreadyTranslated": true
	},
	"No brand found for this product":{
		"text":"ไม่พบแบรนด์สำหรับผลิตภัณฑ์นี้",
		"isAlreadyTranslated": true
	},
	"This product is no longer active":{
		"text":"ผลิตภัณฑ์นี้ไม่สามารถใช้งานได้อีกต่อไป",
		"isAlreadyTranslated": true
	},
	"We Pick up":{
		"text":"เราเข้าไปรับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"We will send an Agent to pick your device and deliver it back after the repair":{
		"text":"เราจะส่งตัวแทนเข้าไปรับอุปกรณ์ของคุณและส่งคืนหลังจากการซ่อมแซม",
		"isAlreadyTranslated": true
	},
	"Not available at this location":{
		"text":"ไม่พร้อมที่ตำแหน่งนี้",
		"isAlreadyTranslated": true
	},
	"You Visit the Authorised Service Center":{
		"text":"คุณไปที่ศูนย์บริการที่ได้รับการแต่งตั้ง",
		"isAlreadyTranslated": true
	},
	"Book an appointment to drop off your device at the authorised service center":{
		"text":"จองนัดหมายเพื่อส่งอุปกรณ์ของคุณที่ศูนย์บริการที่ได้รับการแต่งตั้ง",
		"isAlreadyTranslated": true
	},
	"No Service Center near you":{
		"text":"ไม่มีศูนย์บริการใกล้บ้านคุณ",
		"isAlreadyTranslated": true
	},
	"Get an Engineer to Visit Your Location":{
		"text":"ให้วิศวกรไปยังตำแหน่งของคุณ",
		"isAlreadyTranslated": true
	},
	"Engineer visits you at your preferred location for onsite repair":{
		"text":"วิศวกรไปหาคุณที่สถานที่ที่คุณต้องการเพื่อทำการซ่อมนอกสถานที่",
		"isAlreadyTranslated": true
	},
	"Get a Device Installed at Your Location":{
		"text":"ติดตั้งอุปกรณ์ที่ตำแหน่งของคุณ",
		"isAlreadyTranslated": true
	},
	"Engineer will come to your location at a preferred time to install a device":{
		"text":"วิศวกรจะมาที่ตำแหน่งของคุณในเวลาที่ต้องการเพื่อติดตั้งอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Get Demo of Your Device":{
		"text":"ทำการสาธิตกับอุปกรณ์ของคุณ",
		"isAlreadyTranslated": true
	},
	"We will send an expert who will demonstrate the features of your device":{
		"text":"เราจะส่งผู้เชี่ยวชาญที่จะช่วยสาธิตคุณลักษณะต่างๆ ของอุปกรณ์ของคุณ",
		"isAlreadyTranslated": true
	},
	"Preventive Maintenance":{
		"text":"การบำรุงรักษาเชิงป้องกัน",
		"isAlreadyTranslated": true
	},
	"Service at Your Location":{
		"text":"การบริการที่ตำแหน่งของคุณ",
		"isAlreadyTranslated": true
	},
	"We will send an engineer who will perform routine health checks and preventive maintenance routine":{
		"text":"เราจะส่งวิศวกรที่จะตรวจสอบสถานภาพเป็นประจำ และดำเนินขั้นตอนการบำรุงรักษาเชิงป้องกัน",
		"isAlreadyTranslated": true
	},
	"Looks like there are no service modes available at your selected location. Please chose near by location or contact brand for further help.":{
		"text":"ดูเหมือนว่าจะไม่มีโหมดบริการที่สามารถใช้งานได้ในตำแหน่งที่เลือก โปรดเลือกตำแหน่งใกล้เคียงหรือติดต่อไปที่แบรนด์เพื่อขอความช่วยเหลือเพิ่มเติม",
		"isAlreadyTranslated": true
	},
	"The request is not at required status for this action":{
		"text":"คำร้องขอไม่อยู่ในสถานะที่ต้องการสำหรับการดำเนินการนี้",
		"isAlreadyTranslated": true
	},
	"Error in fetching requests":{
		"text":"เกิดข้อผิดพลาดในการดึงข้อมูลคำร้องขอ",
		"isAlreadyTranslated": true
	},
	"Request details fetched successfully":{
		"text":"ดึงข้อมูลรายละเอียดคำร้องขอสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights to cancel this request":{
		"text":"คุณไม่มีสิทธิเพียงพอที่จะยกเลิกคำร้องขอนี้",
		"isAlreadyTranslated": true
	},
	"Something went wrong while updating consumer service request":{
		"text":"มีความผิดปกติเกิดขึ้นขณะอัปเดตคำร้องขอการบริการผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Oops. We don’t service this device at this location. But we’re hard at work and you should get some good news soon. Try later?":{
		"text":"โอ๊ะ เราไม่ให้บริการอุปกรณ์เครื่องนี้ในตำแหน่งนี้ แต่เรากำลังพยายามแก้ปัญหาอยู่ และคุณน่าจะได้รับข่าวดีในเร็วๆ นี้ ลองอีกครั้งในภายหลัง?",
		"isAlreadyTranslated": true
	},
	"We Pick up":{
		"text":"เราเข้าไปรับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Not supported at this location":{
		"text":"ไม่รองรับที่ตำแหน่งนี้",
		"isAlreadyTranslated": true
	},
	"You Visit the Authorised Service Center":{
		"text":"คุณไปที่ศูนย์บริการที่ได้รับการแต่งตั้ง",
		"isAlreadyTranslated": true
	},
	"No Service Center nearby":{
		"text":"ไม่มีศูนย์บริการใกล้เคียง",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights to modify this request":{
		"text":"คุณไม่มีสิทธิเพียงพอที่จะปรับเปลี่ยนคำร้องขอนี้",
		"isAlreadyTranslated": true
	},
	"Claim amount is required":{
		"text":"ต้องระบุจำนวนเงินการเคลม",
		"isAlreadyTranslated": true
	},
	"UTR and payment date is required":{
		"text":"ต้องระบุ UTR และวันที่ชำระเงิน",
		"isAlreadyTranslated": true
	},
	"You don't have enough rights for this action":{
		"text":"คุณไม่มีสิทธิเพียงพอสำหรับการดำเนินการนี้",
		"isAlreadyTranslated": true
	},
	"BER accessories refund amount":{
		"text":"จำนวนเงินคืนสำหรับอุปกรณ์เสริม BER",
		"isAlreadyTranslated": true
	},
	"Accessories":{
		"text":"อุปกรณ์เสริม",
		"isAlreadyTranslated": true
	},
	"Refund":{
		"text":"คืนเงิน",
		"isAlreadyTranslated": true
	},
	"error in storing BER payment details":{
		"text":"เกิดข้อผิดพลาดในการจัดเก็บรายละเอียดการชำระเงิน BER",
		"isAlreadyTranslated": true
	},
	"Your device has been dispatched from the Service Centre, through":{
		"text":"อุปกรณ์ของคุณถูกส่งออกไปจากศูนย์บริการแล้ว ผ่านทาง",
		"isAlreadyTranslated": true
	},
	"with TrackingID":{
		"text":"โดยใช้ 'IDการติดตาม'",
		"isAlreadyTranslated": true
	},
	"Failed to create logistics requests":{
		"text":"สร้างคำร้องขอโลจิสติกส์ไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Please rate your experience.":{
		"text":"โปรดให้คะแนนประสบการณ์ที่คุณได้รับ",
		"isAlreadyTranslated": true
	},
	"Please provide the claimAmount":{
		"text":"โปรดระบุ 'จำนวนเงินการเคลม'",
		"isAlreadyTranslated": true
	},
	"Claim amount":{
		"text":"จำนวนเงินการเคลม",
		"isAlreadyTranslated": true
	},
	"Approved claim Amount":{
		"text":"จำนวนเงินการเคลมที่อนุมัติแล้ว",
		"isAlreadyTranslated": true
	},
	"Request charges not found":{
		"text":"ไม่พบค่าธรรมเนียมคำร้องขอ",
		"isAlreadyTranslated": true
	},
	"Please upload a clear copy of cancelled cheque with the claimant (your) name on it.":{
		"text":"โปรดอัปโหลดสำเนาที่ชัดเจนของเช็คฉบับที่ยกเลิกแล้วซึ่งมีชื่อของผู้อ้างสิทธิ (ชื่อคุณ) อยู่บนเช็คนั้น",
		"isAlreadyTranslated": true
	},
	"We are verifying and processing your documents. We will initiate the payment soon.":{
		"text":"เรากำลังตรวจยืนยันและดำเนินการเอกสารของคุณตามกระบวนการ เราจะเริ่มการชำระเงินในเร็วๆ นี้",
		"isAlreadyTranslated": true
	},
	"Request is not at the required stage":{
		"text":"คำร้องขอไม่อยู่ที่ขั้นที่ต้องการ",
		"isAlreadyTranslated": true
	},
	"Display info fetched":{
		"text":"ดึงข้อมูลการแสดงผลแล้ว",
		"isAlreadyTranslated": true
	},
	"Requests fetched":{
		"text":"ดึงข้อมูลคำร้องขอแล้ว",
		"isAlreadyTranslated": true
	},
	"Provide a valid csrID":{
		"text":"ระบุ csrID ที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Successfully rejected estimate":{
		"text":"ใบประเมินที่ปฏิเสธสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"No parts found for reference number :":{
		"text":"ไม่พบชิ้นส่วนสำหรับหมายเลขอ้างอิง :",
		"isAlreadyTranslated": true
	},
	"No request found for reference number :":{
		"text":"ไม่พบคำร้องขอสำหรับหมายเลขอ้างอิง :",
		"isAlreadyTranslated": true
	},
	"Integration find result":{
		"text":"ผลการค้นหาการรวม",
		"isAlreadyTranslated": true
	},
	"No service request integration found":{
		"text":"ไม่พบการรวมคำร้องขอการบริการ",
		"isAlreadyTranslated": true
	},
	"No service request found":{
		"text":"ไม่พบคำร้องขอการบริการ",
		"isAlreadyTranslated": true
	},
	"failed to get subcategory of current config version":{
		"text":"การรับหมวดย่อยของเวอร์ชั่นการกำหนดค่าปัจจุบันไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"ConfigurationParameter Set":{
		"text":"ตั้งค่า 'พารามิเตอร์การกำหนดค่า' แล้ว",
		"isAlreadyTranslated": true
	},
	"SubCategory updated":{
		"text":"อัปเดตหมวดย่อยแล้ว",
		"isAlreadyTranslated": true
	},
	"email invoice sent":{
		"text":"ส่งอีเมลใบกำกับสินค้าแล้ว",
		"isAlreadyTranslated": true
	},
	"Servify Invoice":{
		"text":"ใบกำกับสินค้า Servify",
		"isAlreadyTranslated": true
	},
	"Otp sent":{
		"text":"ส่งรหัส OTP แล้ว",
		"isAlreadyTranslated": true
	},
	"Check boxes for feedback":{
		"text":"กล่องกาเครื่องหมายสำหรับข้อคิดเห็น",
		"isAlreadyTranslated": true
	},
	"No service location appointment found":{
		"text":"ไม่พบนัดหมายในตำแหน่งการบริการ",
		"isAlreadyTranslated": true
	},
	"No request found":{
		"text":"ไม่พบคำร้องขอ",
		"isAlreadyTranslated": true
	},
	"Notification list":{
		"text":"รายการแจ้งเตือน",
		"isAlreadyTranslated": true
	},
	"no new notifications":{
		"text":"ไม่มีการแจ้งเตือนใหม่",
		"isAlreadyTranslated": true
	},
	"Consumer does not exists":{
		"text":"ไม่มีผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Coupon application failed":{
		"text":"การใช้คูปองไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"Welcome to () Care":{
		"text":"ยินดีต้อนรับสู่ () Care",
		"isAlreadyTranslated": true
	},
	"Welcome to Servify - Your Personal Device Assistant":{
		"text":"ยินดีต้อนรับสู่ Servify - ผู้ช่วยส่วนตัวสำหรับอุปกรณ์ของคุณ",
		"isAlreadyTranslated": true
	},
	"Welcome to Servify - Authorized Service":{
		"text":"ยินดีต้อนรับสู่ Servify - การบริการจากศูนย์ที่ได้รับการแต่งตั้ง",
		"isAlreadyTranslated": true
	},
	"Welcome to eRecycle For iPhones":{
		"text":"ยินดีต้อนรับสู่ eRecycle สำหรับ iPhone",
		"isAlreadyTranslated": true
	},
	"OTP matched":{
		"text":"รหัส OTP ตรงกัน",
		"isAlreadyTranslated": true
	},
	"Page number should be greater than 0":{
		"text":"เลขหน้าควรมากกว่า 0",
		"isAlreadyTranslated": true
	},
	"Items per page should be greater than ()":{
		"text":"รายการต่อหน้าควรมากกว่า ( )",
		"isAlreadyTranslated": true
	},
	"Range should be greater than 0":{
		"text":"ช่วงควรมากกว่า 0",
		"isAlreadyTranslated": true
	},
	"Something went wrong while applying filters":{
		"text":"มีความผิดปกติเกิดขึ้นขณะปรับใช้ตัวกรอง",
		"isAlreadyTranslated": true
	},
	"Something went wrong while getting consumer count":{
		"text":"มีความผิดปกติเกิดขึ้นขณะกำลังรับจำนวนนับผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Something went wrong while doing pagination":{
		"text":"มีความผิดปกติเกิดขึ้นขณะทำการแบ่งหน้า",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send MobileNo":{
		"text":"พารามิเตอร์ไม่เพียงพอ - ส่ง 'หมายเลขมือถือ'",
		"isAlreadyTranslated": true
	},
	"Insufficient parameters - Send CountryCode":{
		"text":"พารามิเตอร์ไม่เพียงพอ - ส่ง 'รหัสประเทศ'",
		"isAlreadyTranslated": true
	},
	"Consumer exists with the given mobile number":{
		"text":"มีผู้ใช้ที่มีหมายเลขมือถือที่ให้มา",
		"isAlreadyTranslated": true
	},
	"No consumer exists with the given mobile number":{
		"text":"ไม่มีผู้ใช้ที่มีหมายเลขมือถือที่ให้มา",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching filtered consumers":{
		"text":"มีความผิดปกติเกิดขึ้นขณะดึงข้อมูลผู้ใช้ที่ผ่านการกรองข้อมูล",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching filtered Tempconsumers":{
		"text":"มีความผิดปกติเกิดขึ้นขณะดึงข้อมูล 'ผู้ใช้ชั่วคราว' ที่ผ่านการกรองข้อมูล",
		"isAlreadyTranslated": true
	},
	"Please send consumer id":{
		"text":"โปรดส่ง id ผู้ใช้",
		"isAlreadyTranslated": true
	},
	"No New Jobs available":{
		"text":"ไม่มีงานใหม่",
		"isAlreadyTranslated": true
	},
	"Something went wrong with get Devices":{
		"text":"มีความผิดปกติเกิดขึ้นกับการรับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Are you an Enterprise Support Program Customer?":{
		"text":"คุณคือลูกค้าโครงการสนับสนุนแบบ Enterprise หรือไม่?",
		"isAlreadyTranslated": true
	},
	"Looks like there is a problem!":{
		"text":"ดูเหมือนว่าจะมีปัญหา!",
		"isAlreadyTranslated": true
	},
	"The IMEI Number entered is not valid. Please contact Servify Customer Support to know more.":{
		"text":"หมายเลข IMEI ที่ป้อนไว้ไม่ถูกต้อง โปรดติดต่อฝ่ายบริการลูกค้า Servify เพื่อขอทราบข้อมูลเพิ่มเติม",
		"isAlreadyTranslated": true
	},
	"You have already activated the enterprise support plan on your device.":{
		"text":"คุณได้เปิดใช้งานแผนการสนับสนุนแบบ Enterprise บนอุปกรณ์ของคุณแล้ว",
		"isAlreadyTranslated": true
	},
	"Welcome!":{
		"text":"ยินดีต้อนรับ!",
		"isAlreadyTranslated": true
	},
	"To get complete assistance, please add the Serial or IMEI Number of this device.":{
		"text":"หากต้องการความช่วยเหลือทั้งหมด โปรดใส่เลขประจำเครื่องหรือหมายเลข IMEI ของอุปกรณ์เครื่องนี้",
		"isAlreadyTranslated": true
	},
	"You seemed to have generated a voucher but not purchased from Flipkart. Please visit Flipkart and buy an iPhone to avail benefits exclusively for you.":{
		"text":"ดูเหมือนว่าคุณได้สร้างคูปองแล้วแต่ยังไม่ได้ซื้อจาก Flipkart โปรดไปที่ Flipkart และซื้อ iPhone เพื่อใช้สิทธิประโยชน์ที่มีเฉพาะสำหรับคุณ",
		"isAlreadyTranslated": true
	},
	"For further assistance, please contact Servify":{
		"text":"หากต้องการความช่วยเหลือเพิ่มเติม โปรดติดต่อ Servify",
		"isAlreadyTranslated": true
	},
	"coupon is applied":{
		"text":"มีการใช้คูปอง",
		"isAlreadyTranslated": true
	},
	"coupon applied no. exceeded":{
		"text":"เกินหมายเลขที่ใช้คูปอง",
		"isAlreadyTranslated": true
	},
	"coupon cannot be  applied":{
		"text":"ไม่สามารถใช้คูปองได้",
		"isAlreadyTranslated": true
	},
	"Coupon is no longer valid":{
		"text":"คูปองไม่สามารถใช้ได้อีกต่อไป",
		"isAlreadyTranslated": true
	},
	"Cannot find the coupon value":{
		"text":"ไม่พบมูลค่าของคูปอง",
		"isAlreadyTranslated": true
	},
	"coupon redeemed":{
		"text":"แลกใช้คูปองแล้ว",
		"isAlreadyTranslated": true
	},
	"Payment is done through servify cash":{
		"text":"ทำการชำระเงินด้วยเงินสด servify",
		"isAlreadyTranslated": true
	},
	"coupon can't be applied":{
		"text":"ไม่สามารถใช้คูปองได้",
		"isAlreadyTranslated": true
	},
	"coupon does not exist":{
		"text":"ไม่มีคูปอง",
		"isAlreadyTranslated": true
	},
	"coupon cannot be redeem":{
		"text":"ไม่สามารถแลกใช้คูปองได้",
		"isAlreadyTranslated": true
	},
	"coupon cant be applied":{
		"text":"ไม่สามารถใช้คูปองได้",
		"isAlreadyTranslated": true
	},
	"coupon cannot be destroyed":{
		"text":"ไม่สามารถทำลายคูปอง",
		"isAlreadyTranslated": true
	},
	"no coupon applied":{
		"text":"ไม่มีการใช้คูปอง",
		"isAlreadyTranslated": true
	},
	"apply coupon failed":{
		"text":"ใช้คูปองไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"referral created":{
		"text":"สร้างแหล่งอ้างอิงแล้ว",
		"isAlreadyTranslated": true
	},
	"it is case sensitive":{
		"text":"ตรงตามตัวพิมพ์ใหญ่-เล็ก",
		"isAlreadyTranslated": true
	},
	"referral can be applied":{
		"text":"สามารถใช้แหล่งอ้างอิงได้",
		"isAlreadyTranslated": true
	},
	"maximum limit reached":{
		"text":"ถึงขีดจำกัดสูงสุดแล้ว",
		"isAlreadyTranslated": true
	},
	"referral already applied":{
		"text":"แหล่งอ้างอิงถูกใช้อยู่แล้ว",
		"isAlreadyTranslated": true
	},
	"Redeemed updated successfully":{
		"text":"อัปเดตการแลกใช้สำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Points added":{
		"text":"เพิ่มคะแนนแล้ว",
		"isAlreadyTranslated": true
	},
	"referral not generated":{
		"text":"ไม่ได้สร้างแหล่งอ้างอิง",
		"isAlreadyTranslated": true
	},
	"Coupon Applied. Add a device for redeeming":{
		"text":"ใช้คูปองแล้ว เพิ่มอุปกรณ์สำหรับการแลกใช้",
		"isAlreadyTranslated": true
	},
	"record not found":{
		"text":"ไม่พบระเบียน",
		"isAlreadyTranslated": true
	},
	"Coupon cannot be applied":{
		"text":"ไม่สามารถใช้คูปองได้",
		"isAlreadyTranslated": true
	},
	"no referral redeemed":{
		"text":"ไม่มีแหล่งอ้างอิงที่แลกใช้",
		"isAlreadyTranslated": true
	},
	"coupon already applied":{
		"text":"คูปองถูกใช้ไปแล้ว",
		"isAlreadyTranslated": true
	},
	"unable to apply referral code":{
		"text":"ไม่สามารถใช้รหัสแหล่งอ้างอิงได้",
		"isAlreadyTranslated": true
	},
	"invalid referral code":{
		"text":"รหัสแหล่งอ้างอิงไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"No User Found":{
		"text":"ไม่พบผู้ใช้",
		"isAlreadyTranslated": true
	},
	"already redeemed":{
		"text":"แลกใช้ไปแล้ว",
		"isAlreadyTranslated": true
	},
	"ServiceLocationApiRequest called for getDiagnosis failed":{
		"text":"'คำร้องขอApiตำแหน่งการบริการ' ที่เรียกขอ 'รับการวินิจฉัย' ไม่สำเร็จ",
		"isAlreadyTranslated": true
	},
	"No document found":{
		"text":"ไม่พบเอกสาร",
		"isAlreadyTranslated": true
	},
	"Documents obtained":{
		"text":"ได้รับเอกสารแล้ว",
		"isAlreadyTranslated": true
	},
	"No documents obtained":{
		"text":"ไม่ได้รับเอกสาร",
		"isAlreadyTranslated": true
	},
	"Documents updated":{
		"text":"อัปเดตเอกสารแล้ว",
		"isAlreadyTranslated": true
	},
	"Documents fetched":{
		"text":"ดึงข้อมูลเอกสารแล้ว",
		"isAlreadyTranslated": true
	},
	"Documents received. Verification in progress.":{
		"text":"ได้รับเอกสารแล้ว กำลังตรวจยืนยัน",
		"isAlreadyTranslated": true
	},
	"Claim request document added":{
		"text":"เพิ่มเอกสารคำร้องขอการเคลมแล้ว",
		"isAlreadyTranslated": true
	},
	"Something went wrong while fetching filtered  consumers":{
		"text":"มีความผิดปกติเกิดขึ้นขณะดึงข้อมูลผู้ใช้ที่ผ่านการกรองข้อมูล",
		"isAlreadyTranslated": true
	},
	"Filtered consumer and tempConsumer":{
		"text":"ผู้ใช้ที่กรองแล้ว และ 'ผู้ใช้ชั่วคราว'",
		"isAlreadyTranslated": true
	},
	"Recommended reads":{
		"text":"การอ่านที่แนะนำ",
		"isAlreadyTranslated": true
	},
	"Posts fetched successfully":{
		"text":"ดึงข้อมูลโพสต์สำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Posts obtained":{
		"text":"ได้รับโพสต์แล้ว",
		"isAlreadyTranslated": true
	},
	"Feedback recorded":{
		"text":"บันทึกข้อคิดเห็นแล้ว",
		"isAlreadyTranslated": true
	},
	"Super!":{
		"text":"ดีเยี่ยม!",
		"isAlreadyTranslated": true
	},
	"Thank you for your feedback.":{
		"text":"ขอบคุณสำหรับข้อคิดเห็น",
		"isAlreadyTranslated": true
	},
	"Noted!":{
		"text":"รับทราบ!",
		"isAlreadyTranslated": true
	},
	"We'll come up with more helpful solutions.":{
		"text":"เราจะนำเสนอโซลูชันที่มีประโยชน์มากขึ้น",
		"isAlreadyTranslated": true
	},
	"Front Side":{
		"text":"ด้านหน้า",
		"isAlreadyTranslated": true
	},
	"Back Side":{
		"text":"ด้านหลัง",
		"isAlreadyTranslated": true
	},
	"Other Documents":{
		"text":"เอกสารอื่นๆ",
		"isAlreadyTranslated": true
	},
	"Eg: Passport, Driving License":{
		"text":"เช่น: พาสปอร์ต, ใบอนุญาตขับขี่",
		"isAlreadyTranslated": true
	},
	"Pickup Request Accepted":{
		"text":"คำร้องขอการรับอุปกรณ์ได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"Device collected by Logistics Executive":{
		"text":"อุปกรณ์ที่รวบรวมโดยเจ้าหน้าที่โลจิสติกส์",
		"isAlreadyTranslated": true
	},
	"Inspection completed":{
		"text":"การตรวจสอบเสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"Please approve the Service Estimate to initiate the repair process.":{
		"text":"โปรดอนุมัติใบประเมินการบริการเพื่อเริ่มขั้นตอนการซ่อม",
		"isAlreadyTranslated": true
	},
	"The Service Estimate was rejected by you. We hope to be at your service sometime soon.":{
		"text":"ใบประเมินการบริการถูกปฏิเสธจากคุณ เราหวังว่าจะมีโอกาสให้บริการคุณในเวลาอันใกล้นี้",
		"isAlreadyTranslated": true
	},
	"Device repair completed":{
		"text":"การซ่อมอุปกรณ์เสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"Estimate/Invoice is generated for your repair.":{
		"text":"มีการสร้างใบประเมิน/ใบกำกับสินค้าขึ้นสำหรับการซ่อมของคุณ",
		"isAlreadyTranslated": true
	},
	"Agent has arrived at the pickup location":{
		"text":"ตัวแทนมาถึงที่ตำแหน่งรับอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"Thank you for using our service, please rate your experience.":{
		"text":"ขอบคุณที่ใช้บริการของเรา โปรดให้คะแนนจากประสบการณ์ที่คุณได้รับ",
		"isAlreadyTranslated": true
	},
	"Device returned":{
		"text":"ส่งอุปกรณ์คืนแล้ว",
		"isAlreadyTranslated": true
	},
	"Thanks for visting @ hopes to give you a good service experience.":{
		"text":"ขอบคุณสำหรับการเข้าเยี่ยมชม @ หวังว่าคุณจะได้รับประสบการณ์การบริการที่ดี",
		"isAlreadyTranslated": true
	},
	"Your Service Request has been rescheduled as per your choice.":{
		"text":"คำร้องขอการบริการของคุณได้รับการกำหนดเวลาใหม่ตามตัวเลือกของคุณ",
		"isAlreadyTranslated": true
	},
	"Looking for Xperts":{
		"text":"กำลังหา Xpert",
		"isAlreadyTranslated": true
	},
	"We're looking for the best Servify Xpert for your gadget.":{
		"text":"เรากำลังหา Servify Xpert ที่ดีที่สุดสำหรับแกดเจ็ตของคุณ",
		"isAlreadyTranslated": true
	},
	"Our Xpert is on the way!":{
		"text":"Xpert ของเรากำลังมา!",
		"isAlreadyTranslated": true
	},
	"Our Xpert has arrived":{
		"text":"Xpert ของเรามาถึงแล้ว",
		"isAlreadyTranslated": true
	},
	"Your repair request has been rescheduled by our Xpert as discussed with you by our Xpert.":{
		"text":"คำร้องขอการซ่อมของคุณได้รับการกำหนดเวลาใหม่โดย Xpert ของเราตามที่คุณได้พูดคุยกับ Xpert ของเรา",
		"isAlreadyTranslated": true
	},
	"You have requested to reschedule your request on @":{
		"text":"คุณได้ร้องขอให้กำหนดเวลาใหม่สำหรับคำร้องขอของคุณในวันที่ @",
		"isAlreadyTranslated": true
	},
	"Your request has been registered at @":{
		"text":"ได้ลงทะเบียนคำร้องขอของคุณที่ @ แล้ว",
		"isAlreadyTranslated": true
	},
	"Cancelling your service request.":{
		"text":"กำลังยกเลิกคำร้องขอการบริการของคุณ",
		"isAlreadyTranslated": true
	},
	"Your Service Request is cancelled.":{
		"text":"คำร้องขอการบริการของคุณถูกยกเลิกแล้ว",
		"isAlreadyTranslated": true
	},
	"Payment received!":{
		"text":"ได้รับการชำระเงินแล้ว!",
		"isAlreadyTranslated": true
	},
	"Waiting for Xpert's approval":{
		"text":"กำลังรอการอนุมัติจาก Xpert",
		"isAlreadyTranslated": true
	},
	"Recycle Request Accepted":{
		"text":"คำร้องขอการรีไซเคิลได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"Request Accepted":{
		"text":"คำร้องขอได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"Good news. Parts have been received at the Service Center":{
		"text":"ข่าวดี ได้รับชิ้นส่วนที่ศูนย์บริการแล้ว",
		"isAlreadyTranslated": true
	},
	"We're looking for the best Servify Xpert for your gadget.":{
		"text":"เรากำลังหา Servify Xpert ที่ดีที่สุดสำหรับแกดเจ็ตของคุณ",
		"isAlreadyTranslated": true
	},
	"Estimate Generated":{
		"text":"สร้างใบประเมินแล้ว",
		"isAlreadyTranslated": true
	},
	"Estimation & Reimbursement":{
		"text":"การประเมินและการชำระเงินคืน",
		"isAlreadyTranslated": true
	},
	"Drop Off":{
		"text":"ส่งอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Service Estimate generated":{
		"text":"สร้างใบประเมินการบริการแล้ว",
		"isAlreadyTranslated": true
	},
	"Your device has been received at the Authorised Service Center":{
		"text":"มีการรับอุปกรณ์ของคุณแล้วที่ศูนย์บริการที่ได้รับการแต่งตั้ง",
		"isAlreadyTranslated": true
	},
	"Request scheduled":{
		"text":"คำร้องขอได้รับการกำหนดเวลาแล้ว",
		"isAlreadyTranslated": true
	},
	"Claim raised":{
		"text":"ยื่นเรื่องการเคลมแล้ว",
		"isAlreadyTranslated": true
	},
	"Claim eligible":{
		"text":"การเคลมมีคุณสมบัติเข้าเกณฑ์",
		"isAlreadyTranslated": true
	},
	"Claim invalid":{
		"text":"การเคลมเป็นโมฆะ",
		"isAlreadyTranslated": true
	},
	"Claim initiated":{
		"text":"เริ่มการเคลมแล้ว",
		"isAlreadyTranslated": true
	},
	"Document pending":{
		"text":"เอกสารรอดำเนินการอยู่",
		"isAlreadyTranslated": true
	},
	"Document verification pending":{
		"text":"การตรวจยืนยันเอกสารรอดำเนินการอยู่",
		"isAlreadyTranslated": true
	},
	"Documentation incomplete":{
		"text":"การจัดทำเอกสารไม่สมบูรณ์",
		"isAlreadyTranslated": true
	},
	"In-Principle Approval received":{
		"text":"ได้รับสถานะ In-Principle Approval แล้ว",
		"isAlreadyTranslated": true
	},
	"Claim Estimation generated for your device":{
		"text":"ได้สร้างใบประเมินการเคลมขึ้นสำหรับอุปกรณ์ของคุณ",
		"isAlreadyTranslated": true
	},
	"Thank you for approving Claim Estimation":{
		"text":"ขอบคุณสำหรับการอนุมัติใบประเมินการเคลม",
		"isAlreadyTranslated": true
	},
	"You rejected the Claim Estimation":{
		"text":"คุณปฏิเสธใบประเมินการเคลม",
		"isAlreadyTranslated": true
	},
	"Your request is pending for the want of Parts. Service Center will update you shortly on availability":{
		"text":"คำร้องขอของคุณอยู่ระหว่างรอดำเนินการเนื่องจากขาดชิ้นส่วน ศูนย์บริการจะแจ้งให้คุณทราบถึงความพร้อมให้บริการในเร็วๆ นี้",
		"isAlreadyTranslated": true
	},
	"Good news! Engineer has received all required parts for the repair.":{
		"text":"ข่าวดี! วิศวกรได้รับชิ้นส่วนที่ต้องการสำหรับการซ่อมครบแล้ว",
		"isAlreadyTranslated": true
	},
	"Please make the Payment as per estimation for us to proceed":{
		"text":"โปรดชำระเงินตามใบประเมินเพื่อให้เราดำเนินการต่อ",
		"isAlreadyTranslated": true
	},
	"Claim rejected by the Risk Underwriter":{
		"text":"การเคลมถูกปฏิเสธจากผู้รับประกันความเสี่ยง",
		"isAlreadyTranslated": true
	},
	"Call attempted by our Claim Verification Executive":{
		"text":"มีการพยายามโทรจากเจ้าหน้าที่ตรวจยืนยันการเคลม",
		"isAlreadyTranslated": true
	},
	"Your Request is under validation":{
		"text":"คำร้องขอของคุณอยู่ระหว่างการยืนยันความถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Dropoff Confirmed":{
		"text":"การส่งอุปกรณ์ได้รับการยืนยันแล้ว",
		"isAlreadyTranslated": true
	},
	"Device dropped":{
		"text":"ส่งอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"Green Reward Update":{
		"text":"การอัปเดตรางวัล Green Reward",
		"isAlreadyTranslated": true
	},
	"Vouchers generated":{
		"text":"สร้างคูปองแล้ว",
		"isAlreadyTranslated": true
	},
	"eRecycle Completed":{
		"text":"eRecycle เสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"Data Erasure Completed":{
		"text":"การลบข้อมูลเสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"Destruction Completed":{
		"text":"การทำลายเสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"Device rejected":{
		"text":"อุปกรณ์ถูกปฏิเสธ",
		"isAlreadyTranslated": true
	},
	"Claim withdrawn":{
		"text":"การเคลมถูกถอนออกแล้ว",
		"isAlreadyTranslated": true
	},
	"Request cancelled":{
		"text":"คำร้องขอถูกยกเลิกแล้ว",
		"isAlreadyTranslated": true
	},
	"Device reached at the eRecycling Hub":{
		"text":"อุปกรณ์ถึงที่ eRecycling Hub แล้ว",
		"isAlreadyTranslated": true
	},
	"Reward Generation Pending":{
		"text":"การสร้างรางวัลรอดำเนินการอยู่",
		"isAlreadyTranslated": true
	},
	"BER confirmed":{
		"text":"BER ได้รับการยืนยันแล้ว",
		"isAlreadyTranslated": true
	},
	"Processing request":{
		"text":"กำลังประมวลผลคำร้องขอ",
		"isAlreadyTranslated": true
	},
	"Payment initiated":{
		"text":"เริ่มการชำระเงินแล้ว",
		"isAlreadyTranslated": true
	},
	"Payment for your device has been completed":{
		"text":"การชำระเงินสำหรับอุปกรณ์ของคุณเสร็จเรียบร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"Payment completed":{
		"text":"การชำระเงินเสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"Device Diagnosed and Estimate Generated":{
		"text":"อุปกรณ์ได้รับการวินิจฉัยและสร้างใบประเมินแล้ว",
		"isAlreadyTranslated": true
	},
	"Looks like the repair cost is more than the depreciated value of your device. Please select the option you would like to proceed with.":{
		"text":"ดูเหมือนว่าค่าใช้จ่ายในการซ่อมจะมากกว่ามูลค่าส่วนลดของอุปกรณ์ของคุณ โปรดเลือกตัวเลือกที่คุณต้องการดำเนินการ",
		"isAlreadyTranslated": true
	},
	"Your device is currently being diagnosed.":{
		"text":"ขณะนี้อุปกรณ์ของคุณอยู่ระหว่างการวินิจฉัย",
		"isAlreadyTranslated": true
	},
	"Pending for Acceptance":{
		"text":"กำลังรอการตอบรับ",
		"isAlreadyTranslated": true
	},
	"QC rejected":{
		"text":"QC ถูกปฏิเสธ",
		"isAlreadyTranslated": true
	},
	"Parts ordered":{
		"text":"สั่งซื้อชิ้นส่วนแล้ว",
		"isAlreadyTranslated": true
	},
	"Engineer is awaiting a few parts to start the repair of your device.":{
		"text":"วิศวกรกำลังรอชิ้นส่วนอีกเล็กน้อยเพื่อจะเริ่มการซ่อมอุปกรณ์ของคุณ",
		"isAlreadyTranslated": true
	},
	"QC completed":{
		"text":"QC เสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"Pending for assignment":{
		"text":"กำลังรอการมอบหมายงาน",
		"isAlreadyTranslated": true
	},
	"Repair initiated":{
		"text":"เริ่มการซ่อมแล้ว",
		"isAlreadyTranslated": true
	},
	"Parts rejected":{
		"text":"ชิ้นส่วนถูกปฏิเสธ",
		"isAlreadyTranslated": true
	},
	"Advance payment is received.":{
		"text":"ได้รับการชำระเงินล่วงหน้าแล้ว",
		"isAlreadyTranslated": true
	},
	"Parts request created":{
		"text":"สร้างคำร้องขอชิ้นส่วนแล้ว",
		"isAlreadyTranslated": true
	},
	"Awesome! Your device is repaired and ready to be collected.":{
		"text":"เยี่ยมมาก! อุปกรณ์ของคุณได้รับการซ่อมแล้วและพร้อมสำหรับการรับคืน",
		"isAlreadyTranslated": true
	},
	"parts partially issued":{
		"text":"มีการออกชิ้นส่วนบางส่วน",
		"isAlreadyTranslated": true
	},
	"Thank you for your feedback.":{
		"text":"ขอบคุณสำหรับข้อคิดเห็น",
		"isAlreadyTranslated": true
	},
	"Pledged successfully":{
		"text":"ทำสัญญาเข้าร่วมสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Pledge voucher generated":{
		"text":"สร้างคูปองสมนาคุณการทำสัญญาแล้ว",
		"isAlreadyTranslated": true
	},
	"Pledge voucher pending":{
		"text":"คูปองสมนาคุณการทำสัญญารอดำเนินการอยู่",
		"isAlreadyTranslated": true
	},
	"Device replaced":{
		"text":"เปลี่ยนอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"doa completed":{
		"text":"doa เสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"pledge request created":{
		"text":"สร้างคำร้องขอการทำสัญญาเข้าร่วมแล้ว",
		"isAlreadyTranslated": true
	},
	"Service completed and rated":{
		"text":"การบริการเสร็จสิ้นแล้วและให้คะแนนแล้ว",
		"isAlreadyTranslated": true
	},
	"intimate customer":{
		"text":"แจ้งลูกค้า",
		"isAlreadyTranslated": true
	},
	"advance collected":{
		"text":"รับอุปกรณ์ล่วงหน้าแล้ว",
		"isAlreadyTranslated": true
	},
	"parts issued":{
		"text":"ชิ้นส่วนออกแล้ว",
		"isAlreadyTranslated": true
	},
	"parts request accepted":{
		"text":"คำร้องขอชิ้นส่วนได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"work in progress":{
		"text":"งานอยู่ระหว่างดำเนินการ",
		"isAlreadyTranslated": true
	},
	"BER rejected":{
		"text":"BER ถูกปฏิเสธ",
		"isAlreadyTranslated": true
	},
	"BER repair":{
		"text":"การซ่อม BER",
		"isAlreadyTranslated": true
	},
	"BER accessories rejected":{
		"text":"อุปกรณ์เสริม BER ถูกปฏิเสธ",
		"isAlreadyTranslated": true
	},
	"BER accessories validated":{
		"text":"ยืนยันความถูกต้องของอุปกรณ์เสริม BER แล้ว",
		"isAlreadyTranslated": true
	},
	"BER accessories received":{
		"text":"ได้รับอุปกรณ์เสริม BER แล้ว",
		"isAlreadyTranslated": true
	},
	"BER documents validated":{
		"text":"ยืนยันความถูกต้องของเอกสาร BER แล้ว",
		"isAlreadyTranslated": true
	},
	"BER device received":{
		"text":"ได้รับอุปกรณ์ BER แล้ว",
		"isAlreadyTranslated": true
	},
	"BER cancel":{
		"text":"การยกเลิก BER",
		"isAlreadyTranslated": true
	},
	"BER approved":{
		"text":"อนุมัติ BER แล้ว",
		"isAlreadyTranslated": true
	},
	"BER payment completed":{
		"text":"การชำระเงิน BER เสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"BER payment initiated":{
		"text":"เริ่มการชำระเงิน BER แล้ว",
		"isAlreadyTranslated": true
	},
	"vouchers generation pending":{
		"text":"การสร้างคูปองรอดำเนินการอยู่",
		"isAlreadyTranslated": true
	},
	"device collected":{
		"text":"รับอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"data wipe completed":{
		"text":"ลบล้างข้อมูลเสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"device recycle initiated":{
		"text":"เริ่มการรีไซเคิลอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"Under validation":{
		"text":"อยู่ระหว่างการยืนยันความถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Call attempted":{
		"text":"มีการพยายามโทรแล้ว",
		"isAlreadyTranslated": true
	},
	"Claim rejected":{
		"text":"การเคลมถูกปฏิเสธ",
		"isAlreadyTranslated": true
	},
	"payment pending":{
		"text":"การชำระเงินรอดำเนินการอยู่",
		"isAlreadyTranslated": true
	},
	"Parts received":{
		"text":"ได้รับชิ้นส่วนแล้ว",
		"isAlreadyTranslated": true
	},
	"parts pending":{
		"text":"ชิ้นส่วนรอดำเนินการอยู่",
		"isAlreadyTranslated": true
	},
	"claim estimation rejected":{
		"text":"ใบประเมินการเคลมถูกปฏิเสธ",
		"isAlreadyTranslated": true
	},
	"claim estimation approved":{
		"text":"ใบประเมินการเคลมได้รับการอนุมัติแล้ว",
		"isAlreadyTranslated": true
	},
	"claim estimation generated":{
		"text":"สร้างใบประเมินการเคลมแล้ว",
		"isAlreadyTranslated": true
	},
	"In-principle approved":{
		"text":"In-principle approved",
		"isAlreadyTranslated": true
	},
	"document incomplete":{
		"text":"เอกสารไม่สมบูรณ์",
		"isAlreadyTranslated": true
	},
	"device received":{
		"text":"ได้รับอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"estimation generated":{
		"text":"สร้างใบประเมินแล้ว",
		"isAlreadyTranslated": true
	},
	"pending for approval":{
		"text":"กำลังรอการอนุมัติ",
		"isAlreadyTranslated": true
	},
	"repair cancel":{
		"text":"การยกเลิกการซ่อม",
		"isAlreadyTranslated": true
	},
	"service cancellation initiated":{
		"text":"เริ่มการยกเลิกการบริการแล้ว",
		"isAlreadyTranslated": true
	},
	"reschedule requested":{
		"text":"ร้องขอการกำหนดเวลาใหม่แล้ว",
		"isAlreadyTranslated": true
	},
	"service suspended":{
		"text":"การบริการถูกพักไว้",
		"isAlreadyTranslated": true
	},
	"serviceman reached":{
		"text":"เจ้าหน้าที่ปฏิบัติงานซ่อมมาถึงแล้ว",
		"isAlreadyTranslated": true
	},
	"serviceman journey initiated":{
		"text":"เจ้าหน้าที่ปฏิบัติงานซ่อมเริ่มเดินทางแล้ว",
		"isAlreadyTranslated": true
	},
	"request waiting for assignment":{
		"text":"คำร้องขอกำลังรอการมอบหมายงาน",
		"isAlreadyTranslated": true
	},
	"service reschedule":{
		"text":"การกำหนดเวลาใหม่สำหรับการบริการ",
		"isAlreadyTranslated": true
	},
	"consumer arrived":{
		"text":"ผู้ใช้มาถึงแล้ว",
		"isAlreadyTranslated": true
	},
	"service cancel":{
		"text":"การยกเลิกการบริการ",
		"isAlreadyTranslated": true
	},
	"service completed":{
		"text":"การบริการเสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"agent arrived":{
		"text":"ตัวแทนมาถึงแล้ว",
		"isAlreadyTranslated": true
	},
	"device dispatched":{
		"text":"ส่งอุปกรณ์ไปแล้ว",
		"isAlreadyTranslated": true
	},
	"estimation rejected":{
		"text":"ใบประเมินถูกปฏิเสธ",
		"isAlreadyTranslated": true
	},
	"estimation approval":{
		"text":"การอนุมัติใบประเมิน",
		"isAlreadyTranslated": true
	},
	"service initiated":{
		"text":"เริ่มการบริการแล้ว",
		"isAlreadyTranslated": true
	},
	"device shipped":{
		"text":"จัดส่งอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"pickup request accepted":{
		"text":"คำร้องขอการรับอุปกรณ์ได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"@ has accepted your repair request":{
		"text":"@ ได้ตอบรับคำร้องขอการซ่อมของคุณแล้ว",
		"isAlreadyTranslated": true
	},
	"It's fixed!":{
		"text":"ทำการแก้ไขแล้ว!",
		"isAlreadyTranslated": true
	},
	"Installation in progress":{
		"text":"กำลังทำการติดตั้ง",
		"isAlreadyTranslated": true
	},
	"You have successfully cancelled the service request. We hope to be at your service sometime soon.":{
		"text":"คุณได้ยกเลิกคำร้องขอการบริการสำเร็จแล้ว เราหวังว่าจะมีโอกาสให้บริการคุณในเวลาอันใกล้นี้",
		"isAlreadyTranslated": true
	},
	"Your installation request has been rescheduled as per your choice.":{
		"text":"คำร้องขอการติดตั้งของคุณได้รับการกำหนดเวลาใหม่ตามตัวเลือกของคุณ",
		"isAlreadyTranslated": true
	},
	"Received request for installation":{
		"text":"ได้รับคำร้องขอการติดตั้งแล้ว",
		"isAlreadyTranslated": true
	},
	"Your installation request has been rescheduled by our Xpert.":{
		"text":"คำร้องขอการติดตั้งของคุณได้รับการกำหนดเวลาใหม่โดย Xpert ของเรา",
		"isAlreadyTranslated": true
	},
	"@ has accepted your installation request":{
		"text":"@ ได้ตอบรับคำร้องขอการติดตั้งของคุณแล้ว",
		"isAlreadyTranslated": true
	},
	"Demo in progress":{
		"text":"กำลังทำการสาธิต",
		"isAlreadyTranslated": true
	},
	"Your demo request has been rescheduled as per your choice.":{
		"text":"คำร้องขอการสาธิตของคุณได้รับการกำหนดเวลาใหม่ตามตัวเลือกของคุณ",
		"isAlreadyTranslated": true
	},
	"Received request for demo":{
		"text":"ได้รับคำร้องขอการสาธิตแล้ว",
		"isAlreadyTranslated": true
	},
	"Your demo request has been rescheduled by our Xpert.":{
		"text":"คำร้องขอการสาธิตของคุณได้รับการกำหนดเวลาใหม่โดย Xpert ของเรา",
		"isAlreadyTranslated": true
	},
	"@ has accepted your demo request":{
		"text":"@ ได้ตอบรับคำร้องขอการสาธิตของคุณแล้ว",
		"isAlreadyTranslated": true
	},
	"Service in progress":{
		"text":"กำลังให้บริการ",
		"isAlreadyTranslated": true
	},
	"Received request for service":{
		"text":"ได้รับคำร้องขอการบริการแล้ว",
		"isAlreadyTranslated": true
	},
	"@ has accepted your service request":{
		"text":"@ ได้ตอบรับคำร้องขอการบริการของคุณแล้ว",
		"isAlreadyTranslated": true
	},
	"Verification Complete":{
		"text":"การตรวจยืนยันเสร็จสมบูรณ์",
		"isAlreadyTranslated": true
	},
	"Recycle Initiated":{
		"text":"เริ่มการ eRecycle แล้ว",
		"isAlreadyTranslated": true
	},
	"Your eRecycle Request is cancelled":{
		"text":"คำร้องขอ eRecycle ของคุณถูกยกเลิกแล้ว",
		"isAlreadyTranslated": true
	},
	"eRecycling Complete":{
		"text":"การ eRecycle เสร็จสมบูรณ์",
		"isAlreadyTranslated": true
	},
	"Eligible For Claim":{
		"text":"มีคุณสมบัติเข้าเกณฑ์สำหรับการเคลม",
		"isAlreadyTranslated": true
	},
	"Invalid Claim":{
		"text":"การเคลมไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Claim number is generated from short formats":{
		"text":"ได้สร้างหมายเลขการเคลมขึ้นจากรูปแบบสั้น",
		"isAlreadyTranslated": true
	},
	"Repair completed, please collect the device at the earliest.":{
		"text":"การซ่อมเสร็จสิ้นแล้ว โปรดรับอุปกรณ์คืนในเวลาสะดวกที่เร็วที่สุด",
		"isAlreadyTranslated": true
	},
	"eRecycle Initiated":{
		"text":"เริ่มการ eRecycle แล้ว",
		"isAlreadyTranslated": true
	},
	"Your eRecycle Request is cancelled.":{
		"text":"คำร้องขอ eRecycle ของคุณถูกยกเลิกแล้ว",
		"isAlreadyTranslated": true
	},
	"eRequest cancelled":{
		"text":"eRequest ถูกยกเลิกแล้ว",
		"isAlreadyTranslated": true
	},
	"Dropoff Request Registered":{
		"text":"ได้ลงทะเบียนคำร้องขอการส่งอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"BER confirmed":{
		"text":"BER ได้รับการยืนยันแล้ว",
		"isAlreadyTranslated": true
	},
	"Get the Depreciated Value of This Device":{
		"text":"รับมูลค่าส่วนลดของอุปกรณ์เครื่องนี้",
		"isAlreadyTranslated": true
	},
	"You will be eligible to receive the depreciated value of the device amounting to INR @. Please note that an admin fee of INR @ will be deducted from the receivable amount as per the terms of the plan.":{
		"text":"คุณจะมีสิทธิได้รับมูลค่าส่วนลดของอุปกรณ์เป็นจำนวนเงิน @ INR โปรดสังเกตว่าค่าจัดการ @ INR จะถูกหักออกจากจำนวนเงินที่รับโดยเป็นไปตามเงื่อนไขของแผน",
		"isAlreadyTranslated": true
	},
	"BER confirmed":{
		"text":"BER ได้รับการยืนยันแล้ว",
		"isAlreadyTranslated": true
	},
	"BER repair":{
		"text":"การซ่อม BER",
		"isAlreadyTranslated": true
	},
	"Repair This Device and I will pay the Difference":{
		"text":"ซ่อมอุปกรณ์เครื่องนี้และฉันจะจ่ายส่วนต่าง",
		"isAlreadyTranslated": true
	},
	"Repair estimate for this device is INR @. You will pay additional amount of INR @, which includes admin fee of INR @ over and above the covered value of this device of INR @.":{
		"text":"ใบประเมินค่าซ่อมสำหรับอุปกรณ์เครื่องนี้เท่ากับ @ INR คุณจะชำระเงินเพิ่มเติมเป็นจำนวน @ INR ซึ่งรวมค่าจัดการ @ INR และสูงกว่ามูลค่าที่ครอบคลุมของค่าใช้จ่ายอุปกรณ์เครื่องนี้ @ INR",
		"isAlreadyTranslated": true
	},
	"BER repair":{
		"text":"การซ่อม BER",
		"isAlreadyTranslated": true
	},
	"BER cancel":{
		"text":"การยกเลิก BER",
		"isAlreadyTranslated": true
	},
	"Cancel Repair and Get Back the Device":{
		"text":"ยกเลิกการซ่อมและรับอุปกรณ์คืน",
		"isAlreadyTranslated": true
	},
	"We will return your device without repairs.":{
		"text":"เราจะคืนอุปกรณ์ของคุณให้โดยไม่มีการซ่อม",
		"isAlreadyTranslated": true
	},
	"BER cancel":{
		"text":"การยกเลิก BER",
		"isAlreadyTranslated": true
	},
	"Process completed":{
		"text":"การประมวลผลเสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"Option 1 : Proceed With Accessories":{
		"text":"ตัวเลือก 1 : ดำเนินการโดยมีอุปกรณ์เสริม",
		"isAlreadyTranslated": true
	},
	"You will be eligible to receive a reimbursement of <> after deducting <> towards the admin fee. Please make sure you courier us the accessories at <> within <> days.":{
		"text":"คุณจะมีสิทธิได้รับการชำระเงินคืน หลังจากหักออก ในส่วนของค่าจัดการ โปรดตรวจสอบให้แน่ใจว่าคุณจัดส่งอุปกรณ์เสริมให้เราที่  ภายใน วัน",
		"isAlreadyTranslated": true
	},
	"Option 2 : Proceed Without Accessories":{
		"text":"ตัวเลือก 2 : ดำเนินการโดยไม่มีอุปกรณ์เสริม",
		"isAlreadyTranslated": true
	},
	"You will be eligible to receive a reimbursement of <> after deducting <> towards missing accessories and <> towards admin fee.":{
		"text":"คุณจะมีสิทธิได้รับการชำระเงินคืน หลังจากหักออก ในส่วนของอุปกรณ์เสริมที่ขาดไปและ ในส่วนของค่าจัดการ",
		"isAlreadyTranslated": true
	},
	"Your Service Request has been assigned to Technician.":{
		"text":"คำร้องขอการบริการของคุณได้รับการมอบหมายให้กับช่างเทคนิคแล้ว",
		"isAlreadyTranslated": true
	},
	"request waiting for assignment":{
		"text":"คำร้องขอกำลังรอการมอบหมายงาน",
		"isAlreadyTranslated": true
	},
	"Service request raised to Godrej Smart Care":{
		"text":"ยื่นเรื่องคำร้องขอการบริการไปที่ Godrej Smart Care แล้ว",
		"isAlreadyTranslated": true
	},
	"pending for assignment":{
		"text":"กำลังรอการมอบหมายงาน",
		"isAlreadyTranslated": true
	},
	"Service suspended":{
		"text":"การบริการถูกพักไว้",
		"isAlreadyTranslated": true
	},
	"Your Service Request is accepted.":{
		"text":"คำร้องขอการบริการของคุณได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"Your Service Request has been assigned to Technician.":{
		"text":"คำร้องขอการบริการของคุณได้รับการมอบหมายให้กับช่างเทคนิคแล้ว",
		"isAlreadyTranslated": true
	},
	"Your Service Request is cancelled.":{
		"text":"คำร้องขอการบริการของคุณถูกยกเลิกแล้ว",
		"isAlreadyTranslated": true
	},
	"Your installation request has been rescheduled as per your choice.":{
		"text":"คำร้องขอการติดตั้งของคุณได้รับการกำหนดเวลาใหม่ตามตัวเลือกของคุณ",
		"isAlreadyTranslated": true
	},
	"Service suspended":{
		"text":"การบริการถูกพักไว้",
		"isAlreadyTranslated": true
	},
	"Your Service Request is accepted.":{
		"text":"คำร้องขอการบริการของคุณได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"Your Service Request has been assigned to Technician.":{
		"text":"คำร้องขอการบริการของคุณได้รับการมอบหมายให้กับช่างเทคนิคแล้ว",
		"isAlreadyTranslated": true
	},
	"Your Service Request is cancelled.":{
		"text":"คำร้องขอการบริการของคุณถูกยกเลิกแล้ว",
		"isAlreadyTranslated": true
	},
	"Approve Estimation":{
		"text":"อนุมัติใบประเมิน",
		"isAlreadyTranslated": true
	},
	"You rejected estimation":{
		"text":"คุณปฏิเสธใบประเมิน",
		"isAlreadyTranslated": true
	},
	"Invoice/Estimation generated":{
		"text":"สร้างใบกำกับสินค้า/ใบประเมินแล้ว",
		"isAlreadyTranslated": true
	},
	"Good to see you.":{
		"text":"ยินดีที่ได้พบคุณ",
		"isAlreadyTranslated": true
	},
	"Request Rescheduled":{
		"text":"คำร้องขอได้รับการกำหนดเวลาใหม่แล้ว",
		"isAlreadyTranslated": true
	},
	"Waiting for Assignment":{
		"text":"กำลังรอการมอบหมายงาน",
		"isAlreadyTranslated": true
	},
	"Awaiting Technician assignment":{
		"text":"กำลังรอการมอบหมายช่างเทคนิค",
		"isAlreadyTranslated": true
	},
	"Request in process!":{
		"text":"คำร้องขออยู่ระหว่างดำเนินการ!",
		"isAlreadyTranslated": true
	},
	"Xpert arrived":{
		"text":"Xpert มาถึงแล้ว",
		"isAlreadyTranslated": true
	},
	"Your device has been received at the Authorised Service Provider":{
		"text":"มีการรับอุปกรณ์ของคุณแล้วที่ผู้ให้บริการที่ได้รับการแต่งตั้ง",
		"isAlreadyTranslated": true
	},
	"In-Principle Approval":{
		"text":"In-Principle Approval",
		"isAlreadyTranslated": true
	},
	"claim Estimation":{
		"text":"ใบประเมินการเคลม",
		"isAlreadyTranslated": true
	},
	"service dismissed":{
		"text":"บอกเลิกการบริการแล้ว",
		"isAlreadyTranslated": true
	},
	"Service creation pending at Brand":{
		"text":"การสร้างการบริการรอดำเนินการอยู่ที่แบรนด์",
		"isAlreadyTranslated": true
	},
	"Repair in progress":{
		"text":"กำลังทำการซ่อมแซม",
		"isAlreadyTranslated": true
	},
	"Service Accepted By Technician":{
		"text":"ช่างเทคนิคได้ตอบรับการบริการแล้ว",
		"isAlreadyTranslated": true
	},
	"Pickup":{
		"text":"การรับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Normally takes 1-3 days":{
		"text":"โดยปกติจะใช้เวลา 1-3 วัน",
		"isAlreadyTranslated": true
	},
	"Diagnosis":{
		"text":"การวินิจฉัย",
		"isAlreadyTranslated": true
	},
	"Normally takes 2-3 hours from the time of receipt of device":{
		"text":"โดยปกติจะใช้เวลา 2-3 ชั่วโมงจากเวลาที่ได้รับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Estimation & Repair":{
		"text":"การประเมินและการซ่อม",
		"isAlreadyTranslated": true
	},
	"Normally takes a day":{
		"text":"โดยปกติจะใช้เวลาหนึ่งวัน",
		"isAlreadyTranslated": true
	},
	"Return to Customer":{
		"text":"ส่งคืนลูกค้า",
		"isAlreadyTranslated": true
	},
	"Normally returned immediately after repairs":{
		"text":"โดยปกติจะส่งกลับทันทีหลังจากการซ่อม",
		"isAlreadyTranslated": true
	},
	"Repair Initiation":{
		"text":"การเริ่มดำเนินการซ่อม",
		"isAlreadyTranslated": true
	},
	"Your request has been registered at @. Please visit as per the appointment scheduled. We hope your service experience will be great":{
		"text":"ได้ลงทะเบียนคำร้องขอของคุณที่ @ แล้ว โปรดเข้ามาตามนัดหมายที่กำหนดไว้ เราหวังว่าคุณจะได้รับประสบการณ์การบริการที่ดีเยี่ยม",
		"isAlreadyTranslated": true
	},
	"Normally takes a day":{
		"text":"โดยปกติจะใช้เวลาหนึ่งวัน",
		"isAlreadyTranslated": true
	},
	"Normally returned immediately after repairs. You will be intimated to collect it accordingly":{
		"text":"โดยปกติจะส่งกลับทันทีหลังจากการซ่อม คุณจะได้รับแจ้งให้มารับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Service Initiation":{
		"text":"การเริ่มการให้บริการ",
		"isAlreadyTranslated": true
	},
	"Normally as per the scheduled date and time":{
		"text":"โดยปกติจะเป็นไปตามวันและเวลาที่กำหนดไว้",
		"isAlreadyTranslated": true
	},
	"Device Validation":{
		"text":"การยืนยันความถูกต้องของอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"During the visit of the Technician":{
		"text":"ในระหว่างการเข้าพบช่างเทคนิค",
		"isAlreadyTranslated": true
	},
	"Service & Payment":{
		"text":"การบริการและการชำระเงิน",
		"isAlreadyTranslated": true
	},
	"Normally once the technician visits your premise":{
		"text":"โดยปกติเมื่อช่างเทคนิคมาเข้าพบที่สถานที่ของคุณ",
		"isAlreadyTranslated": true
	},
	"Feedback":{
		"text":"ข้อคิดเห็น",
		"isAlreadyTranslated": true
	},
	"This is when you can rate service experience":{
		"text":"นี่คือเวลาที่คุณสามารถให้คะแนนประสบการณ์การบริการ",
		"isAlreadyTranslated": true
	},
	"Pledge to eRecycle":{
		"text":"ทำสัญญาเข้าร่วม eRecycle",
		"isAlreadyTranslated": true
	},
	"Normally takes a Day":{
		"text":"โดยปกติจะใช้เวลาหนึ่งวัน",
		"isAlreadyTranslated": true
	},
	"Takes approx 1 Day":{
		"text":"ใช้เวลาประมาณ 1 วัน",
		"isAlreadyTranslated": true
	},
	"Green Rewards":{
		"text":"Green Reward",
		"isAlreadyTranslated": true
	},
	"eRecycle":{
		"text":"eRecycle",
		"isAlreadyTranslated": true
	},
	"Cancel":{
		"text":"ยกเลิก",
		"isAlreadyTranslated": true
	},
	"Claim Request Raised":{
		"text":"ยื่นเรื่องคำร้องขอการเคลมแล้ว",
		"isAlreadyTranslated": true
	},
	"We have received your claim request. Our representative will connect with you within the next 1-2 business days to process it further":{
		"text":"เราได้รับคำร้องขอการเคลมของคุณแล้ว พนักงานตัวแทนของเราจะเชื่อมต่อกับคุณภายใน 1-2 วันทำการถัดไปเพื่อดำเนินการต่อ",
		"isAlreadyTranslated": true
	},
	"Verification":{
		"text":"การตรวจยืนยัน",
		"isAlreadyTranslated": true
	},
	"Device handed over by QCEngineer to StoreManager":{
		"text":"\วิศวกรQC'\ ส่งมอบอุปกรณ์ให้กับ '\ผู้จัดการร้านค้า'\ แล้ว",
		"isAlreadyTranslated": true
	},
	"Your device is repaired and dispatched.":{
		"text":"อุปกรณ์ของคุณได้รับการซ่อมและส่งคืน",
		"isAlreadyTranslated": true
	},
	"Your device is currently getting repaired.":{
		"text":"ขณะนี้อุปกรณ์ของคุณกำลังได้รับการซ่อมอยู่",
		"isAlreadyTranslated": true
	},
	"Your device has been returned. Kindly rate your experience.":{
		"text":"อุปกรณ์ของคุณถูกส่งคืนแล้ว โปรดให้คะแนนประสบการณ์ที่คุณได้รับ",
		"isAlreadyTranslated": true
	},
	"Repair complete":{
		"text":"การซ่อมเสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"Device handed over by QCEngineer to CustomerCare":{
		"text":"\วิศวกรQC'\ ส่งมอบอุปกรณ์ให้กับ '\ฝ่ายบริการลูกค้า'\ แล้ว",
		"isAlreadyTranslated": true
	},
	"Device handed over by ServicelocationEngineer to QCEngineer":{
		"text":"'วิศวกรตำแหน่งการบริการ' ส่งมอบอุปกรณ์ให้กับ 'วิศวกรQC' แล้ว",
		"isAlreadyTranslated": true
	},
	"Device accepted by QCEngineer from ServicelocationEngineer":{
		"text":"'วิศวกรQC' ได้ตอบรับอุปกรณ์จาก 'วิศวกรตำแหน่งการบริการ' แล้ว",
		"isAlreadyTranslated": true
	},
	"Device accepted by ServicelocationEngineer from CustomerCare":{
		"text":"'วิศวกรตำแหน่งการบริการ' ได้ตอบรับอุปกรณ์จาก 'ฝ่ายบริการลูกค้า' แล้ว",
		"isAlreadyTranslated": true
	},
	"Device handed over by CustomerCare to ServicelocationEngineer":{
		"text":"'ฝ่ายบริการลูกค้า' ส่งมอบอุปกรณ์ให้กับ 'วิศวกรตำแหน่งการบริการ' แล้ว",
		"isAlreadyTranslated": true
	},
	"Device handed over by ServicelocationEngineer to CustomerCare":{
		"text":"'วิศวกรตำแหน่งการบริการ' ส่งมอบอุปกรณ์ให้กับ 'ฝ่ายบริการลูกค้า' แล้ว",
		"isAlreadyTranslated": true
	},
	"Device accepted by CustomerCare from QCEngineer":{
		"text":"'ฝ่ายบริการลูกค้า' ได้ตอบรับอุปกรณ์จาก 'วิศวกรQC' แล้ว",
		"isAlreadyTranslated": true
	},
	"Device handed over by QCEngineer to ServicelocationEngineer":{
		"text":"\วิศวกรQC'\ ส่งมอบอุปกรณ์ให้กับ '\วิศวกรตำแหน่งการบริการ'\ แล้ว",
		"isAlreadyTranslated": true
	},
	"Device handed over by StoreManager to CustomerCare":{
		"text":"\ผู้จัดการร้านค้า'\ ส่งมอบอุปกรณ์ให้กับ '\ฝ่ายบริการลูกค้า'\ แล้ว",
		"isAlreadyTranslated": true
	},
	"Device handed over by StoreManager to ServicelocationEngineer":{
		"text":"'ผู้จัดการร้านค้า' ส่งมอบอุปกรณ์ให้กับ 'วิศวกรตำแหน่งการบริการ' แล้ว",
		"isAlreadyTranslated": true
	},
	"Normally happens immediately on fulfillment of required documentation":{
		"text":"โดยปกติจะเกิดขึ้นทันทีเมื่อเอกสารที่จำเป็นครบถ้วนสมบูรณ์",
		"isAlreadyTranslated": true
	},
	"Device handed over by StoreManager to QCEngineer":{
		"text":"\ผู้จัดการร้านค้า'\ ส่งมอบอุปกรณ์ให้กับ '\วิศวกรQC'\ แล้ว",
		"isAlreadyTranslated": true
	},
	"Normally takes 2-3 days from the time of receipt of device":{
		"text":"โดยปกติจะใช้เวลา 2-3 วันจากเวลาที่ได้รับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Engineer To Store":{
		"text":"วิศวกรไปยังร้านค้า",
		"isAlreadyTranslated": true
	},
	"Engineer To CustomerCare":{
		"text":"วิศวกรไปยัง 'ฝ่ายบริการลูกค้า'",
		"isAlreadyTranslated": true
	},
	"Engineer To QC executive":{
		"text":"วิศวกรไปยังเจ้าหน้าที่ QC",
		"isAlreadyTranslated": true
	},
	"Store To Engineer":{
		"text":"ร้านค้าไปยังวิศวกร",
		"isAlreadyTranslated": true
	},
	"Store To CustomerCare":{
		"text":"ร้านค้าไปยัง 'ฝ่ายบริการลูกค้า'",
		"isAlreadyTranslated": true
	},
	"Store To QC executive":{
		"text":"ร้านค้าไปยังเจ้าหน้าที่ QC",
		"isAlreadyTranslated": true
	},
	"QC To Engineer":{
		"text":"QC ไปยังวิศวกร",
		"isAlreadyTranslated": true
	},
	"QC To CustomerCare":{
		"text":"QC ไปยัง 'ฝ่ายบริการลูกค้า'",
		"isAlreadyTranslated": true
	},
	"QC To Store":{
		"text":"QC ไปยังร้านค้า",
		"isAlreadyTranslated": true
	},
	"CC To Engineer":{
		"text":"CC ไปยังร้านค้า",
		"isAlreadyTranslated": true
	},
	"CC To Store":{
		"text":"CC ไปยังร้าน",
		"isAlreadyTranslated": true
	},
	"CC To QC":{
		"text":"CC ไปยัง QC",
		"isAlreadyTranslated": true
	},
	"Issues validated":{
		"text":"ยืนยันความถูกต้องของปัญหาแล้ว",
		"isAlreadyTranslated": true
	},
	"Diagnosis completed":{
		"text":"การวินิจฉัยเสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"Diagnosis initiated":{
		"text":"เริ่มการวินิจฉัยแล้ว",
		"isAlreadyTranslated": true
	},
	"Device accepted":{
		"text":"อุปกรณ์ได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"Device handed over":{
		"text":"ส่งมอบอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer assignment":{
		"text":"กำลังรอการมอบหมายงานวิศวกร",
		"isAlreadyTranslated": true
	},
	"Please handover the device to the Logistics Agent only if he provides this way bill number to prove his identity.":{
		"text":"โปรดส่งมอบอุปกรณ์ให้กับตัวแทนโลจิสติกส์เฉพาะเมื่อเขาแสดงหมายเลขใบนำส่งสินค้านี้เพื่อยืนยันตัวตนของเขาเท่านั้น",
		"isAlreadyTranslated": true
	},
	"Express Check In Available":{
		"text":"มีบริการเข้าตรวจแบบด่วน",
		"isAlreadyTranslated": true
	},
	"Your repair request ( @ / @ ) has been cancelled.":{
		"text":"คำร้องขอการซ่อมของคุณ ( @ / @ ) ถูกยกเลิกแล้ว",
		"isAlreadyTranslated": true
	},
	"Invoice generated for your repair request (@ / @). Please initiate payment.":{
		"text":"สร้างใบกำกับสินค้าสำหรับคำร้องขอการซ่อมของคุณแล้ว (@ / @) โปรดเริ่มดำเนินการชำระเงิน",
		"isAlreadyTranslated": true
	},
	"Repair initiated for your request (@ / @).":{
		"text":"เริ่มการซ่อมสำหรับคำร้องขอของคุณ (@ / @) แล้ว",
		"isAlreadyTranslated": true
	},
	"Please approve the estimate for your repair request ( @ / @ )":{
		"text":"โปรดอนุมัติใบประเมินสำหรับคำร้องขอการซ่อมของคุณ ( @ / @ )",
		"isAlreadyTranslated": true
	},
	"Repair completed for your request (@ / @).":{
		"text":"การซ่อมสำหรับคำร้องขอของคุณ (@ / @) เสร็จเรียบร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"Your @ has reached the Authorised Service Centre and is currently being diagnosed ( @ ).":{
		"text":"@ ของคุณได้มาถึงศูนย์บริการที่ได้รับการแต่งตั้งแล้ว และขณะนี้กำลังวินิจฉัยอาการอยู่ ( @ )",
		"isAlreadyTranslated": true
	},
	"Your request (@) has been completed & closed.":{
		"text":"คำร้องขอของคุณ (@) ได้ดำเนินการเสร็จสิ้นและปิดแล้ว",
		"isAlreadyTranslated": true
	},
	"Your @ ( @ ) has been repaired & delivered.":{
		"text":"@ (@) ของคุณได้รับการซ่อมและจัดส่งแล้ว",
		"isAlreadyTranslated": true
	},
	"Your payment has been received":{
		"text":"ได้รับการชำระเงินของคุณแล้ว",
		"isAlreadyTranslated": true
	},
	"Track request result":{
		"text":"ผลการติดตามคำร้องขอ",
		"isAlreadyTranslated": true
	},
	"Service Charge":{
		"text":"ค่าธรรมเนียมการบริการ",
		"isAlreadyTranslated": true
	},
	"Total":{
		"text":"ทั้งหมด",
		"isAlreadyTranslated": true
	},
	"Amount Payable":{
		"text":"จำนวนเงินที่ต้องชำระ",
		"isAlreadyTranslated": true
	},
	"Earphone/Headphone/Handsfree Issue":{
		"text":"ปัญหาหูฟัง/เฮดโฟน/อุปกรณ์แฮนด์ฟรี",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on @. Your SECRET CODE is @. Please handover the device to the Logistics Agent only if he provides this secret code to prove his identity.":{
		"text":"ตัวแทนโลจิสติกส์จะมาถึงตำแหน่งของคุณในวันที่ @ 'รหัสลับ' ของคุณคือ @ โปรดส่งมอบอุปกรณ์ให้กับตัวแทนโลจิสติกส์เฉพาะเมื่อเขาแสดงรหัสลับนี้เพื่อยืนยันตัวตนของเขาเท่านั้น",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on @. Your WAY BILL NUMBER is @. Please handover the device to the Logistics Agent only if he provides this way bill number to prove his identity.":{
		"text":"ตัวแทนโลจิสติกส์จะมาถึงตำแหน่งของคุณในวันที่ @ 'หมายเลขใบนำส่งสินค้า' ของคุณคือ @ โปรดส่งมอบอุปกรณ์ให้กับตัวแทนโลจิสติกส์เฉพาะเมื่อเขาแสดงหมายเลขใบนำส่งสินค้านี้เพื่อยืนยันตัวตนของเขาเท่านั้น",
		"isAlreadyTranslated": true
	},
	"Service estimate will be shared before initiating the installation process":{
		"text":"ใบประเมินการบริการจะถูกแชร์ก่อนที่จะเริ่มดำเนินการติดตั้ง",
		"isAlreadyTranslated": true
	},
	"Rate your experience after the service is complete":{
		"text":"ให้คะแนนประสบการณ์ที่คุณได้รับหลังจากการบริการเสร็จเรียบร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"The technician will validate the details of the device provided by you":{
		"text":"ช่างเทคนิคจะยืนยันความถูกต้องของรายละเอียดอุปกรณ์ที่คุณให้ไว้",
		"isAlreadyTranslated": true
	},
	"Please approve the estimate for your repair request (@ / @)":{
		"text":"โปรดอนุมัติใบประเมินสำหรับคำร้องขอการซ่อมของคุณ (@ / @)",
		"isAlreadyTranslated": true
	},
	"Your @ (@) has been repaired & delivered.":{
		"text":"@ (@) ของคุณได้รับการซ่อมและจัดส่งแล้ว",
		"isAlreadyTranslated": true
	},
	"You will be eligible to receive a reimbursement of INR @ after deducting INR @ towards missing accessories and INR @ towards admin fee.":{
		"text":"คุณจะมีสิทธิได้รับการชำระเงินคืน @ INR หลังจากหักออก @ INR ในส่วนของอุปกรณ์เสริมที่ขาดไปและ @ INR ในส่วนของค่าจัดการ",
		"isAlreadyTranslated": true
	},
	"Your repair request (@ / @) has been cancelled.":{
		"text":"คำร้องขอการซ่อมของคุณ (@ / @) ถูกยกเลิกแล้ว",
		"isAlreadyTranslated": true
	},
	"Please approve the Service Estimate":{
		"text":"โปรดอนุมัติใบประเมินการบริการ",
		"isAlreadyTranslated": true
	},
	"We have received your claim request. It will be processed within the next 48 business hours":{
		"text":"เราได้รับคำร้องขอการเคลมของคุณแล้ว จะมีการดำเนินการภายใน 48 ชั่วโมงทำงานนับจากนี้",
		"isAlreadyTranslated": true
	},
	"Rating given by customer : @":{
		"text":"การให้คะแนนโดยลูกค้า : @",
		"isAlreadyTranslated": true
	},
	"Your @ has reached the Authorised Service Centre and is currently being diagnosed (@).":{
		"text":"@ ของคุณได้มาถึงศูนย์บริการที่ได้รับการแต่งตั้งแล้ว และขณะนี้กำลังวินิจฉัยอาการอยู่ (@)",
		"isAlreadyTranslated": true
	},
	"Thank you for eRecycling with Servify":{
		"text":"ขอบคุณสำหรับการ eRecycle กับ Servify",
		"isAlreadyTranslated": true
	},
	"Invoice generated":{
		"text":"สร้างใบกำกับสินค้าแล้ว",
		"isAlreadyTranslated": true
	},
	"Your device has been succesfully collected and will be delivered to Servify":{
		"text":"ได้รับอุปกรณ์ของคุณเรียบร้อยแล้ว และจะจัดส่งไปที่ Servify",
		"isAlreadyTranslated": true
	},
	"Logistics Agent @ @ (@) will arrive at your location today. Your SECRET CODE is @. Please handover the device to the Logistics Agent only if he provides this secret code to prove his identity.":{
		"text":"ตัวแทนโลจิสติกส์ @ @ (@) จะมาถึงตำแหน่งของคุณวันนี้ 'รหัสลับ' ของคุณคือ @ โปรดส่งมอบอุปกรณ์ให้กับตัวแทนโลจิสติกส์เฉพาะเมื่อเขาแสดงรหัสลับนี้เพื่อยืนยันตัวตนของเขาเท่านั้น",
		"isAlreadyTranslated": true
	},
	"You have rescheduled the pickup of your device to @":{
		"text":"คุณได้กำหนดเวลาใหม่ในการรับอุปกรณ์ของคุณไปที่ @",
		"isAlreadyTranslated": true
	},
	"Claim Intimation Number Generated.":{
		"text":"สร้างหมายเลขแจ้งการเคลมแล้ว",
		"isAlreadyTranslated": true
	},
	"Your device was not powering on, and so our executive could not collect your device. Please cancel this request and raise another one under “Carry-in” mode.":{
		"text":"อุปกรณ์ของคุณไม่ได้เปิดเครื่องอยู่ และเจ้าหน้าที่ของเราไม่สามารถรับอุปกรณ์ของคุณได้ โปรดยกเลิกคำร้องขอนี้และยื่นเรื่องคำร้องขอใหม่ภายใต้โหมด \"นำอุปกรณ์เข้ามาเอง\"",
		"isAlreadyTranslated": true
	},
	"Your device IMEI did not match what was provided by you while raising the request. Please cancel this request and raise another one by providing IMEI of the device for repair.":{
		"text":"IMEI อุปกรณ์ของคุณไม่ตรงกับข้อมูลที่คุณให้ในขณะยื่นเรื่องคำร้องขอ โปรดยกเลิกคำร้องขอนี้และยื่นเรื่องคำร้องขอใหม่โดยใส่ข้อมูล IMEI ของอุปกรณ์ที่จะซ่อม",
		"isAlreadyTranslated": true
	},
	"You will be eligible to receive a reimbursement of INR @ after deducting INR @ towards the admin fee. Please make sure you courier us the accessories at @ within @ days.":{
		"text":"คุณจะมีสิทธิได้รับการชำระเงินคืน @ INR หลังจากหักออก @ INR ในส่วนของค่าจัดการ โปรดตรวจสอบให้แน่ใจว่าคุณจัดส่งอุปกรณ์เสริมให้เราที่ @ ภายใน @ วัน",
		"isAlreadyTranslated": true
	},
	"Your device’s data was not backed up. Please reschedule the request as per you convenience.":{
		"text":"อุปกรณ์ของคุณไม่ได้สำรองข้อมูลไว้ โปรดกำหนดเวลาใหม่สำหรับคำร้องขอตามความสะดวกของคุณ",
		"isAlreadyTranslated": true
	},
	"Our Executive was unable to reach you on phone or your address. Please reschedule the pickup as per your convenience.":{
		"text":"เจ้าหน้าที่ของเราไม่สามารถติดต่อคุณทางโทรศัพท์หรือตามที่อยู่ของคุณได้ โปรดกำหนดเวลาใหม่สำหรับการรับอุปกรณ์ตามความสะดวกของคุณ",
		"isAlreadyTranslated": true
	},
	"Logistics Agent @ @ (@) has arrived at your location. Your SECRET CODE is @. Please handover the device to the Logistics Agent only if he provides this secret code to prove his identity.":{
		"text":"ตัวแทนโลจิสติกส์ @ @ (@) มาถึงตำแหน่งของคุณแล้ว 'รหัสลับ' ของคุณคือ @ โปรดส่งมอบอุปกรณ์ให้กับตัวแทนโลจิสติกส์เฉพาะเมื่อเขาแสดงรหัสลับนี้เพื่อยืนยันตัวตนของเขาเท่านั้น",
		"isAlreadyTranslated": true
	},
	"Repair completed. Device will be delivered soon":{
		"text":"ซ่อมแซมเสร็จแล้ว จะจัดส่งอุปกรณ์ให้ในเร็วๆ นี้",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on @ to deliver your device.":{
		"text":"ตัวแทนโลจิสติกส์จะมาถึงตำแหน่งของคุณในวันที่ @ เพื่อส่งอุปกรณ์ให้คุณ",
		"isAlreadyTranslated": true
	},
	"Logistics Agent @ @ (@) has picked up your device from the Service Centre and will deliver it today":{
		"text":"ตัวแทนโลจิสติกส์ @ @ (@) ได้รับอุปกรณ์ของคุณจากศูนย์บริการแล้ว และจะส่งอุปกรณ์ไปวันนี้",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on rescheduled date - @ to deliver your device.":{
		"text":"ตัวแทนโลจิสติกส์จะมาถึงตำแหน่งของคุณตามวันที่กำหนดใหม่ - @ เพื่อส่งอุปกรณ์ให้คุณ",
		"isAlreadyTranslated": true
	},
	"Device delivered! Please rate your experience":{
		"text":"ส่งอุปกรณ์แล้ว! โปรดให้คะแนนประสบการณ์ที่คุณได้รับ",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not deliver your device today as you were not available at your location. You can contact Logistics Support (@) to reschedule device delivery":{
		"text":"ตัวแทนโลจิสติกส์ไม่สามารถส่งอุปกรณ์ของคุณในวันนี้ได้เนื่องจากคุณไม่พร้อมที่ตำแหน่งของคุณ คุณสามารถติดต่อฝ่ายสนับสนุนโลจิสติกส์ (@) เพื่อกำหนดเวลาในการส่งอุปกรณ์อีกครั้ง",
		"isAlreadyTranslated": true
	},
	"Normally takes 2-5 Days":{
		"text":"โดยปกติจะใช้เวลา 2-5 วัน",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not deliver your device today as you were not reachable on call. You can contact Logistics Support (@) to reschedule device delivery":{
		"text":"ตัวแทนโลจิสติกส์ไม่สามารถส่งอุปกรณ์ของคุณในวันนี้ได้เนื่องจากไม่สามารถติดต่อคุณทางโทรศัพท์ คุณสามารถติดต่อฝ่ายสนับสนุนโลจิสติกส์ (@) เพื่อกำหนดเวลาในการส่งอุปกรณ์อีกครั้ง",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not deliver your device today. You can contact Logistics Support (@) to reschedule device delivery":{
		"text":"ตัวแทนโลจิสติกส์ไม่สามารถส่งอุปกรณ์ของคุณในวันนี้ได้ คุณสามารถติดต่อฝ่ายสนับสนุนโลจิสติกส์ (@) เพื่อกำหนดเวลาในการส่งอุปกรณ์อีกครั้ง",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not deliver your device today as you were not available at your location. Please reschedule delivery by tapping the menu on top right corner":{
		"text":"ตัวแทนโลจิสติกส์ไม่สามารถส่งอุปกรณ์ของคุณในวันนี้ได้เนื่องจากคุณไม่พร้อมที่ตำแหน่งของคุณ โปรดกำหนดเวลาการส่งใหม่โดยแตะเมนูที่มุมด้านบนขวา",
		"isAlreadyTranslated": true
	},
	"Logistics Agent @ @ (@) has arrived at your location to deliver your device":{
		"text":"ตัวแทนโลจิสติกส์ @ @ (@) ได้มาถึงตำแหน่งของคุณเพื่อส่งอุปกรณ์ให้คุณแล้ว",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not deliver your device today as you were not reachable on call. Please reschedule delivery by tapping the menu on top right corner":{
		"text":"ตัวแทนโลจิสติกส์ไม่สามารถส่งอุปกรณ์ของคุณในวันนี้ได้เนื่องจากไม่สามารถติดต่อคุณทางโทรศัพท์ โปรดกำหนดเวลาการส่งใหม่โดยแตะเมนูที่มุมด้านบนขวา",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not deliver your device today. Please reschedule device delivery as per your convenience by tapping the menu on top right corner":{
		"text":"ตัวแทนโลจิสติกส์ไม่สามารถส่งอุปกรณ์ของคุณในวันนี้ได้ โปรดกำหนดเวลาการส่งอุปกรณ์ใหม่ตามความสะดวกของคุณโดยแตะเมนูที่ด้านบนขวา",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not deliver your device today. Please contact Customer Support (18001214984) to reschedule device delivery":{
		"text":"ตัวแทนโลจิสติกส์ไม่สามารถส่งอุปกรณ์ของคุณในวันนี้ได้ โปรดติดต่อฝ่ายบริการลูกค้า (18001214984) เพื่อกำหนดเวลาในการส่งอุปกรณ์อีกครั้ง",
		"isAlreadyTranslated": true
	},
	"Logistics Agent @ @ (@) will pick up your device today. Secret Code shared by the Agent for device collection should be @. Please keep an ID Proof ready; a picture of the same is required for ownership validation.":{
		"text":"ตัวแทนโลจิสติกส์ @ @ (@) จะเข้าไปรับอุปกรณ์ของคุณในวันนี้ รหัสลับที่แบ่งปันโดยตัวแทนเพื่อรับอุปกรณ์คือ @ โปรดเก็บ ID สำหรับพิสูจน์ตัวตนให้พร้อมใช้งาน; ต้องใช้รูปภาพที่เหมือนกันในการยืนยันความถูกต้องของสถานะความเป็นเจ้าของ",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will pick up your device on rescheduled date - @. Your Secret Code is @. Only handover the device to the Agent only if he provides this code for verification":{
		"text":"ตัวแทนโลจิสติกส์จะรับอุปกรณ์ของคุณตามวันที่กำหนดใหม่ - @ รหัสลับของคุณคือ @ โปรดส่งมอบอุปกรณ์ให้กับตัวแทนเฉพาะเมื่อเขาแสดงรหัสลับนี้เพื่อตรวจยืนยันเท่านั้น",
		"isAlreadyTranslated": true
	},
	"Logistics Agent @ @ has picked up your device and it will be delivered to Servify.":{
		"text":"ตัวแทนโลจิสติกส์ @ @ ได้รับอุปกรณ์ของคุณแล้ว และจะส่งไปที่ Servify",
		"isAlreadyTranslated": true
	},
	"Device has been shipped to Servify via @.":{
		"text":"อุปกรณ์ถูกจัดส่งไปที่ Servify ผ่าน @",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on rescheduled date - @. Your Secret Code is @. Handover the device to the Agent only if he provides this code for verification":{
		"text":"ตัวแทนโลจิสติกส์จะมาที่ตำแหน่งของคุณตามวันที่กำหนดใหม่ - @ รหัสลับของคุณคือ @ โปรดส่งมอบอุปกรณ์ให้กับตัวแทนเฉพาะเมื่อเขาแสดงรหัสลับนี้เพื่อตรวจยืนยันเท่านั้น",
		"isAlreadyTranslated": true
	},
	"IMEI provided and device IMEI did not match. Please contact Customer Support (18001214984) and provide correct IMEI or raise another request with IMEI of the phone for repair.":{
		"text":"IMEI ที่ให้ไว้กับ IMEI ของอุปกรณ์ไม่ตรงกัน โปรดติดต่อฝ่ายบริการลูกค้า (18001214984) และแจ้งข้อมูล IMEI ที่ถูกต้อง หรือยื่นเรื่องคำร้องขออีกรายการหนึ่งเกี่ยวกับ IMEI ของโทรศัพท์สำหรับการซ่อมแซม",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not pick up your device today as device data was not backed up. Pickup has been rescheduled to @ as requested.":{
		"text":"ตัวแทนโลจิสติกส์ไม่สามารถรับอุปกรณ์ของคุณได้วันนี้เนื่องจากอุปกรณ์ไม่มีการสำรองข้อมูลไว้ การรับอุปกรณ์ได้รับการกำหนดเวลาใหม่เป็น @ ตามที่ร้องขอ",
		"isAlreadyTranslated": true
	},
	"Logistics Agent could not pick up your device today as you were not available at your location. Please reschedule the pickup by tapping on the top right corner":{
		"text":"ตัวแทนโลจิสติกส์ไม่สามารถรับอุปกรณ์ของคุณในวันนี้ได้เนื่องจากคุณไม่พร้อมที่ตำแหน่งของคุณ โปรดกำหนดเวลาใหม่สำหรับการรับอุปกรณ์โดยแตะที่มุมด้านบนขวา",
		"isAlreadyTranslated": true
	},
	"The Logistics Agent will receive your @ @ device and deliver it to Servify.":{
		"text":"ตัวแทนโลจิสติกส์จะรับอุปกรณ์ @ @ ของคุณ และส่งไปที่ Servify",
		"isAlreadyTranslated": true
	},
	"Your device has been succesfully collected and will be delivered to Authorised Service Provider":{
		"text":"ได้รับอุปกรณ์ของคุณเรียบร้อยแล้ว และจะจัดส่งไปที่ผู้ให้บริการที่ได้รับการแต่งตั้ง",
		"isAlreadyTranslated": true
	},
	"You Visit the\nAuthorised Service Center":{
		"text":"คุณไปที่\nศูนย์บริการที่ได้รับการแต่งตั้ง",
		"isAlreadyTranslated": true
	},
	"Your device has been succesfully collected and will be delivered to":{
		"text":"ได้รับอุปกรณ์ของคุณเรียบร้อยแล้ว และจะจัดส่งไปที่",
		"isAlreadyTranslated": true
	},
	"Device has been shipped to Authorized Service centre via @":{
		"text":"อุปกรณ์ได้รับการจัดส่งไปที่ศูนย์บริการที่ได้รับการแต่งตั้งผ่าน @",
		"isAlreadyTranslated": true
	},
	"Our associate will get back to you to schedule a suitable time for pick up of your device":{
		"text":"คู่ค้าของเราจะติดต่อกลับไปหาคุณเพื่อจัดกำหนดเวลาที่เหมาะสมเพื่อรับอุปกรณ์ของคุณ",
		"isAlreadyTranslated": true
	},
	"Your dropoff is scheduled on @ at @.":{
		"text":"การส่งอุปกรณ์ของคุณถูกกำหนดไว้ในวันที่ @ ที่ @",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on rescheduled date - @, as requested.\n(@)":{
		"text":"ตัวแทนโลจิสติกส์จะมาถึงตำแหน่งของคุณตามวันที่กำหนดใหม่ - @, ตามที่ร้องขอ \n(@)",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on rescheduled date - @, as requested.":{
		"text":"ตัวแทนโลจิสติกส์จะมาถึงตำแหน่งของคุณตามวันที่กำหนดใหม่ - @ ตามที่ร้องขอ",
		"isAlreadyTranslated": true
	},
	"You have requested to reschedule your request on @.\n(@)":{
		"text":"คุณได้ร้องขอให้กำหนดเวลาใหม่สำหรับคำร้องขอของคุณในวันที่ @\n(@)",
		"isAlreadyTranslated": true
	},
	"You have requested to reschedule your request on @.":{
		"text":"คุณได้ร้องขอให้กำหนดเวลาใหม่สำหรับคำร้องขอของคุณในวันที่ @",
		"isAlreadyTranslated": true
	},
	"Your device has been dispatched from the Service Centre, through @ with TrackingID @":{
		"text":"อุปกรณ์ของคุณถูกส่งออกไปจากศูนย์บริการแล้ว ผ่านทาง @ โดยใช้ 'IDการติดตาม' @",
		"isAlreadyTranslated": true
	},
	"Service is already booked for @ .Please call us at 1800225511 for further queries":{
		"text":"จองบริการสำหรับ @ แล้ว หากมีข้อสงสัยเพิ่มเติม โปรดโทรหาเราที่หมายเลข 1800225511",
		"isAlreadyTranslated": true
	},
	"Service cannot be created. Please call us at 1800225511 for further queries":{
		"text":"ไม่สามารถสร้างบริการได้ หากมีข้อสงสัยเพิ่มเติม โปรดโทรหาเราที่หมายเลข 1800225511",
		"isAlreadyTranslated": true
	},
	"Service cannot be created.":{
		"text":"ไม่สามารถสร้างบริการได้",
		"isAlreadyTranslated": true
	},
	"webhook PaymentID match":{
		"text":"'IDการชำระเงิน' ของ Webhook ตรงกัน",
		"isAlreadyTranslated": true
	},
	"ERR: webhook PaymentID mismatch":{
		"text":"ข้อผิดพลาด: 'IDการชำระเงิน' ของ Webhook ไม่ตรงกัน",
		"isAlreadyTranslated": true
	},
	"Customer wants to cancel pickup":{
		"text":"ลูกค้าต้องการยกเลิกการรับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Device has been shipped to Authorized Service centre via @.":{
		"text":"อุปกรณ์ได้รับการจัดส่งไปที่ศูนย์บริการที่ได้รับการแต่งตั้งผ่าน @",
		"isAlreadyTranslated": true
	},
	"Your iPhone has been eRecycled. Please rate your eRecycling experience":{
		"text":"iPhone ของคุณได้รับการ eRecycle แล้ว โปรดให้คะแนนประสบการณ์ eRecycle ที่คุณได้รับ",
		"isAlreadyTranslated": true
	},
	"Dropoff of your device at @ is confirmed. Servify will collect the device shortly.":{
		"text":"การส่งอุปกรณ์ของคุณที่ @ ได้รับการยืนยัน Servify จะมารับอุปกรณ์ในเร็วๆ นี้",
		"isAlreadyTranslated": true
	},
	"We have disassembled key components of your device and will be sending it to the eRecycling Hub soon.":{
		"text":"เราได้แยกชิ้นส่วนส่วนประกอบหลักๆ ของอุปกรณ์ของคุณแล้ว และจะส่งไปยัง eRecycling Hub ในเร็วๆ นี้",
		"isAlreadyTranslated": true
	},
	"Your device has been succesfully collected and will be delivered to @ soon":{
		"text":"ได้รับอุปกรณ์ของคุณเรียบร้อยแล้ว และจะจัดส่งไปที่ @ ในเร็วๆ นี้",
		"isAlreadyTranslated": true
	},
	"reached destination city warehouse":{
		"text":"ส่งถึงคลังสินค้าในเมืองที่เป็นจุดหมายปลายทางแล้ว",
		"isAlreadyTranslated": true
	},
	"out for delivery":{
		"text":"เพื่อการจัดส่ง",
		"isAlreadyTranslated": true
	},
	"Your device has been succesfully collected from @":{
		"text":"ได้รับอุปกรณ์ของคุณเรียบร้อยแล้ว จาก @",
		"isAlreadyTranslated": true
	},
	"Approved Estimation":{
		"text":"ใบประเมินที่อนุมัติ",
		"isAlreadyTranslated": true
	},
	"Pending":{
		"text":"กำลังรอ",
		"isAlreadyTranslated": true
	},
	"adding inventory":{
		"text":"การเพิ่มสินค้าคงคลัง",
		"isAlreadyTranslated": true
	},
	"subtracting inventory":{
		"text":"การลบสินค้าคงคลัง",
		"isAlreadyTranslated": true
	},
	"Good News! Your iPhone has been validated succesfully. You are also eligible to participate in the Green Champion Contest to win @.":{
		"text":"ข่าวดี! ยืนยันความถูกต้องของ iPhone ของคุณเรียบร้อยแล้ว คุณมีสิทธิเข้าร่วมใน Green Champion Contest ได้ด้วย เพื่อลุ้นรางวัล @",
		"isAlreadyTranslated": true
	},
	"Good News! Your iPhone has been validated succesfully.":{
		"text":"ข่าวดี! ยืนยันความถูกต้องของ iPhone ของคุณเรียบร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"Device handed over by CustomerCare to QCEngineer":{
		"text":"'ฝ่ายบริการลูกค้า' ส่งมอบอุปกรณ์ให้กับ 'วิศวกรQC' แล้ว",
		"isAlreadyTranslated": true
	},
	"Device handed over by CustomerCare to StoreManager":{
		"text":"'ฝ่ายบริการลูกค้า' ส่งมอบอุปกรณ์ให้กับ 'ผู้จัดการร้านค้า' แล้ว",
		"isAlreadyTranslated": true
	},
	"Part request has been rejected by store":{
		"text":"ร้านค้าได้ปฏิเสธคำร้องขอชิ้นส่วน",
		"isAlreadyTranslated": true
	},
	"Part request has been accepted by store":{
		"text":"ร้านค้าได้ตอบรับคำร้องขอชิ้นส่วนแล้ว",
		"isAlreadyTranslated": true
	},
	"Requested part(s) has been issued by store":{
		"text":"ร้านค้าได้ออกชิ้นส่วนที่ร้องขอ",
		"isAlreadyTranslated": true
	},
	"Engineer has received part(s) issued by store":{
		"text":"วิศวกรได้รับชิ้นส่วนที่ออกโดยร้านค้า",
		"isAlreadyTranslated": true
	},
	"@ is your OTP for Servify. This OTP is valid for 120 seconds. Do not share this OTP with anyone for security reasons. We look forward to serving you.":{
		"text":"@ คือรหัส OTP ของคุณสำหรับ Servify รหัส OTP นี้ใช้งานได้เป็นเวลา 120 วินาที ด้วยเหตุผลด้านความปลอดภัย ห้ามแบ่งปันรหัส OTP นี้กับผู้อื่น เราหวังว่าจะได้บริการคุณ",
		"isAlreadyTranslated": true
	},
	"1 part request is yet to be accepted by store.":{
		"text":"ยังมีคำร้องขอชิ้นส่วน 1 รายการที่รอการตอบรับจากร้านค้า",
		"isAlreadyTranslated": true
	},
	"@ part requests are yet to be accepted by store.":{
		"text":"ยังมีคำร้องขอชิ้นส่วน @ รายการที่รอการตอบรับจากร้านค้า",
		"isAlreadyTranslated": true
	},
	"Download Servify - Personal assistant to all your electronics and home appliances.\n\nhttp://servify.in/app":{
		"text":"ดาวน์โหลด Servify - ผู้ช่วยส่วนตัวสำหรับอุปกรณ์อิเล็กทรอนิกส์และเครื่องใช้ไฟฟ้าในบ้านทั้งหมดของคุณ\n\nhttp://servify.in/app",
		"isAlreadyTranslated": true
	},
	"Our expert (@) has initiated the repair work.":{
		"text":"ผู้เชี่ยวชาญของเรา (@) ได้เริ่มดำเนินการซ่อมแล้ว",
		"isAlreadyTranslated": true
	},
	"Device handed over by ServicelocationEngineer to StoreManager":{
		"text":"'วิศวกรตำแหน่งการบริการ' ส่งมอบอุปกรณ์ให้กับ 'ผู้จัดการร้านค้า' แล้ว",
		"isAlreadyTranslated": true
	},
	"Device accepted from @ by @":{
		"text":"อุปกรณ์ได้รับการตอบรับจาก @ โดย @",
		"isAlreadyTranslated": true
	},
	"Your Device has been collected by Servify Executive on @ and will be delivered to Authorized Service Centre.":{
		"text":"เจ้าหน้าที่ Servify ได้มารับอุปกรณ์ของคุณแล้วเมื่อวันที่ @ และจะจัดส่งไปยังศูนย์บริการที่ได้รับการแต่งตั้ง",
		"isAlreadyTranslated": true
	},
	"Repair Cancel. Device will be delivered soon":{
		"text":"การยกเลิกการซ่อม จะจัดส่งอุปกรณ์ให้ในเร็วๆ นี้",
		"isAlreadyTranslated": true
	},
	"Logistics Agent @ @ has picked up your device and it will be delivered to Authorized Service Centre.":{
		"text":"ตัวแทนโลจิสติกส์ @ @ ได้เข้าไปรับอุปกรณ์ของคุณแล้ว และจะจัดส่งไปที่ศูนย์บริการที่ได้รับการแต่งตั้ง",
		"isAlreadyTranslated": true
	},
	"We Pick up\nYour Device":{
		"text":"เราเข้าไปรับ\nอุปกรณ์ของคุณ",
		"isAlreadyTranslated": true
	},
	"Device handed over by @ to @":{
		"text":"@ ส่งมอบอุปกรณ์ให้กับ @ แล้ว",
		"isAlreadyTranslated": true
	},
	"Device handed over by @ from @":{
		"text":"@ ส่งมอบอุปกรณ์จาก @ แล้ว",
		"isAlreadyTranslated": true
	},
	"Your request for @(@/@) has been registered.":{
		"text":"ได้ลงทะเบียนคำร้องขอของคุณสำหรับ @(@/@) แล้ว",
		"isAlreadyTranslated": true
	},
	"Pickup Drop Repair":{
		"text":"การซ่อมโดยศูนย์รับ-ส่งอุปกรณ์ให้",
		"isAlreadyTranslated": true
	},
	"Carry in Repair":{
		"text":"การซ่อมโดยนำอุปกรณ์เข้ามาเอง",
		"isAlreadyTranslated": true
	},
	"Home Repair":{
		"text":"การซ่อมที่บ้าน",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for pickup drop (@/@/@)":{
		"text":"คำร้องขอการยกเลิกของคุณสำหรับบริการรับ-ส่งอุปกรณ์ได้รับการตอบรับแล้ว (@/@/@)",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for carry in repair (@/@/@)":{
		"text":"คำร้องขอการยกเลิกของคุณสำหรับการซ่อมโดยนำอุปกรณ์เข้ามาเองได้รับการตอบรับแล้ว (@/@/@)",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for home repair (@/@/@)":{
		"text":"คำร้องขอการยกเลิกของคุณสำหรับการซ่อมที่บ้านได้รับการตอบรับแล้ว (@/@/@)",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for installation (@/@/@)":{
		"text":"คำร้องขอการยกเลิกของคุณสำหรับการติดตั้งได้รับการตอบรับแล้ว (@/@/@)",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for demo (@/@/@)":{
		"text":"คำร้องขอการยกเลิกของคุณสำหรับการสาธิตได้รับการตอบรับแล้ว (@/@/@)",
		"isAlreadyTranslated": true
	},
	"With Express Check In you can skip your waiting time at Service Center. You will be attended to within 7 minutes of reaching Service Center":{
		"text":"เมื่อใช้บริการเข้าตรวจแบบด่วน ทำให้คุณไม่ต้องรอที่ศูนย์บริการ คุณจะได้รับการดูแลภายใน 7 นาทีหลังจากเดินทางถึงศูนย์บริการ",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for service (@/@/@)":{
		"text":"คำร้องขอการยกเลิกของคุณสำหรับการบริการได้รับการตอบรับแล้ว (@/@/@)",
		"isAlreadyTranslated": true
	},
	"You have cancelled your request for @ scheduled on @. We hope to serve you soon.":{
		"text":"คุณได้ยกเลิกคำร้องขอของคุณสำหรับ @ ที่กำหนดเวลาไว้วันที่ @ แล้ว เราหวังว่าจะมีโอกาสบริการคุณอีกในเร็วๆ นี้",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for Pickup Drop Repair (@/@/@)":{
		"text":"คำร้องขอของคุณในการกำหนดเวลาใหม่สำหรับการซ่อมโดยศูนย์รับ-ส่งอุปกรณ์ให้ได้รับการตอบรับแล้ว (@/@/@)",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for pickup drop (@ / @ / @)":{
		"text":"คำร้องขอการยกเลิกของคุณสำหรับบริการรับ-ส่งอุปกรณ์ได้รับการตอบรับแล้ว (@ / @ / @)",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for Carry in Repair (@/@/@)":{
		"text":"คำร้องขอของคุณในการกำหนดเวลาใหม่สำหรับการซ่อมโดยนำอุปกรณ์เข้ามาเองได้รับการตอบรับแล้ว (@/@/@)",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for eRecycling (@/@/@)":{
		"text":"คำร้องขอของคุณในการกำหนดเวลาใหม่สำหรับการ eRecycle ได้รับการตอบรับแล้ว (@/@/@)",
		"isAlreadyTranslated": true
	},
	"Your cancellation request  has been accepted for carry in repair (@ / @ / @)":{
		"text":"คำร้องขอการยกเลิกของคุณสำหรับการซ่อมโดยนำอุปกรณ์เข้ามาเองได้รับการตอบรับแล้ว (@ / @ / @)",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for Home Repair (@/@/@)":{
		"text":"คำร้องขอของคุณในการกำหนดเวลาใหม่สำหรับการซ่อมที่บ้านแล้ว (@/@/@)",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for Installation (@/@/@)":{
		"text":"คำร้องขอของคุณในการกำหนดเวลาใหม่สำหรับการติดตั้งได้รับการตอบรับแล้ว (@/@/@)",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for demo (@/@/@)":{
		"text":"คำร้องขอของคุณในการกำหนดเวลาใหม่สำหรับการสาธิตได้รับการตอบรับแล้ว (@/@/@)",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for Servicing (@/@/@)":{
		"text":"คำร้องขอของคุณในการกำหนดเวลาใหม่สำหรับการบริการได้รับการตอบรับแล้ว (@/@/@)",
		"isAlreadyTranslated": true
	},
	"Your request for Pickup Drop Repair (@/@) has been registered.":{
		"text":"ได้ลงทะเบียนคำร้องขอของคุณสำหรับบริการซ่อมโดยศูนย์รับ-ส่งอุปกรณ์ให้ (@/@) แล้ว",
		"isAlreadyTranslated": true
	},
	"Your request for Carry in Repair (@/@) has been registered.":{
		"text":"ได้ลงทะเบียนคำร้องขอของคุณสำหรับบริการซ่อมโดยนำอุปกรณ์เข้ามาเอง (@/@) แล้ว",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for @":{
		"text":"คำร้องขอของคุณในการกำหนดเวลาใหม่สำหรับ @ ได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"Your request for Home Repair (@/@) has been registered.":{
		"text":"ได้ลงทะเบียนคำร้องขอของคุณสำหรับบริการซ่อมที่บ้าน (@/@) แล้ว",
		"isAlreadyTranslated": true
	},
	"You have successfully pledged to eRecycle your iPhone in @! We will remind you to submit your iPhone for eRecycling a few days prior to your chosen month.":{
		"text":"คุณได้ทำสัญญาเข้าร่วมการ eRecycle เครื่อง iPhone ของคุณในวันที่ @ สำเร็จแล้ว! เราจะแจ้งเตือนให้คุณส่งเครื่อง iPhone ของคุณเพื่อดำเนินการ eRecycle ในช่วงเวลาสองสามวันก่อนถึงเดือนที่คุณเลือกไว้",
		"isAlreadyTranslated": true
	},
	"Your request number is @. Our Logistics Agent will collect the device from you as per the scheduled time. To know more, please check Track Request section within the App. T&C apply.":{
		"text":"หมายเลขคำร้องขอของคุณคือ @ ตัวแทนโลจิสติกส์ของเราจะมารับอุปกรณ์จากคุณตามเวลาที่กำหนดไว้ หากต้องการทราบข้อมูลเพิ่มเติม โปรดตรวจดูในส่วน ติดตามคำร้องขอ ภายในแอป การปรับใช้ T&C",
		"isAlreadyTranslated": true
	},
	"Your dropoff is scheduled on @ at @. Ref ID: @":{
		"text":"การส่งอุปกรณ์ของคุณถูกกำหนดไว้ในวันที่ @ ที่ @ ID อ้างอิง: @",
		"isAlreadyTranslated": true
	},
	"Your eRecycle request (@ / @) has been cancelled as per your request. Ref ID: @":{
		"text":"ได้ยกเลิกคำร้องขอ eRecycle ของคุณ (@ / @) แล้วตามที่คุณร้องขอ ID อ้างอิง: @",
		"isAlreadyTranslated": true
	},
	"Your home repair request (@ / @) has been registered. You will receive confirmation 30 mins prior to the requested time slot.":{
		"text":"ได้ลงทะเบียนคำร้องขอการซ่อมที่บ้านของคุณ (@ / @) แล้ว คุณจะได้รับการยืนยันในเวลา 30 นาทีก่อนถึงกำหนดเวลาที่ร้องขอ",
		"isAlreadyTranslated": true
	},
	"Servify has received your @. It will be disassembled & sent to the eRecycling Hub after verification. Ref ID: @":{
		"text":"Servify ได้รับ @ ของคุณแล้ว จะมีการแยกชิ้นส่วนและส่งไปยัง eRecycling Hub หลังจากการตรวจยืนยัน ID อ้างอิง: @",
		"isAlreadyTranslated": true
	},
	"Your @ has been eRecycled. Please rate your eRecycling experience at https://xcxa.app.link/rate?csr=@ . Ref ID: @":{
		"text":"@ ของคุณได้รับการ eRecycle แล้ว โปรดให้คะแนนประสบการณ์การ eRecycle ที่คุณได้รับที่ https://xcxa.app.link/rate?csr=@  ID อ้างอิง: @",
		"isAlreadyTranslated": true
	},
	"Good News! Your @ has been validated successfully. You are also eligible to participate in the Green Champion Contest. Click here @ to know how to participate.":{
		"text":"ข่าวดี! ยืนยันความถูกต้องของ @ ของคุณเรียบร้อยแล้ว คุณมีสิทธิเข้าร่วมใน Green Champion Contest ได้ด้วย คลิกที่นี่ @ เพื่อดูวิธีการเข้าร่วม",
		"isAlreadyTranslated": true
	},
	"Your eRecycle request has been rejected. To know more call 18001214984. Ref ID: @":{
		"text":"คำร้องขอ eRecycle ของคุณถูกปฏิเสธแล้ว หากต้องการทราบข้อมูลเพิ่มเติม โปรดโทรมาที่ 18001214984 ID อ้างอิง: @",
		"isAlreadyTranslated": true
	},
	"Your @ has been dropped off at @. Ref ID: @":{
		"text":"ได้มีการส่ง @ แล้วที่ @ ID อ้างอิง: @",
		"isAlreadyTranslated": true
	},
	"Your disassembled @ and it's key components have reached the eRecycling Hub and will be eRecycled soon. Ref ID: @":{
		"text":"@ ของคุณที่แยกชิ้นส่วนแล้วและส่วนประกอบหลักของเครื่องได้ส่งถึง eRecycling Hub แล้ว และจะดำเนินการ eRecycle ในเร็วๆ นี้ ID อ้างอิง: @",
		"isAlreadyTranslated": true
	},
	"We have disassembled key components of your device and will be sending it to the eRecycling Hub soon. Ref ID: @":{
		"text":"เราได้แยกชิ้นส่วนส่วนประกอบหลักๆ ของอุปกรณ์ของคุณแล้ว และจะส่งไปยัง eRecycling Hub ในเร็วๆ นี้ ID อ้างอิง: @",
		"isAlreadyTranslated": true
	},
	"Sweet! @ has accepted your request to fix your @":{
		"text":"น่าชื่นใจมาก! @ ได้ตอบรับคำร้องขอในการซ่อมเครื่อง @ ของคุณ",
		"isAlreadyTranslated": true
	},
	"New job from Servify":{
		"text":"งานใหม่จาก Servify",
		"isAlreadyTranslated": true
	},
	"Your @ has been successfully activated for your device @":{
		"text":"ได้เปิดใช้งาน @ ของคุณสำหรับอุปกรณ์ @ ของคุณสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"@ has been successfully Purchased and Activated for your device":{
		"text":"ได้ซื้อและเปิดใช้งาน @ สำหรับอุปกรณ์ของคุณสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"@ has been successfully Purchased for your device":{
		"text":"ได้ซื้อ @ สำหรับอุปกรณ์ของคุณสำเร็จแล้ว",
		"isAlreadyTranslated": true
	},
	"Good News! Your @ has been validated successfully.":{
		"text":"ข่าวดี! ยืนยันความถูกต้องของ @ ของคุณเรียบร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"Your carry in repair request @ for @ at (@, @) has been registered. Please check track repair section to get the direction to the location.":{
		"text":"ได้ลงทะเบียนคำร้องขอการซ่อมโดยนำอุปกรณ์เข้ามาเอง @ สำหรับ @ ที่ (@, @) แล้ว โปรดตรวจดูในส่วน ติดตามการซ่อม เพื่อรับเส้นทางไปยังตำแหน่ง",
		"isAlreadyTranslated": true
	},
	"@ is your OTP for Servify. This OTP is valid for 120 seconds. Do not share this OTP with anyone for security reasons.":{
		"text":"@ คือรหัส OTP ของคุณสำหรับ Servify รหัส OTP นี้ใช้งานได้เป็นเวลา 120 วินาที ด้วยเหตุผลด้านความปลอดภัย ห้ามแบ่งปันรหัส OTP นี้กับผู้อื่น",
		"isAlreadyTranslated": true
	},
	"Your home repair request (@ / @) has been registered. You will receive confirmation 30mins prior to the requested time slot.":{
		"text":"ได้ลงทะเบียนคำร้องขอการซ่อมที่บ้านของคุณ (@ / @) แล้ว คุณจะได้รับการยืนยันในเวลา 30 นาทีก่อนถึงกำหนดเวลาที่ร้องขอ",
		"isAlreadyTranslated": true
	},
	"Download the Servify app from http://bit.ly/27WoDAv and get a better service experience. \n Servify - for all your service needs.":{
		"text":"ดาวน์โหลดแอป Servify จาก http://bit.ly/27WoDAv และพบกับประสบการณ์การบริการที่ดียิ่งขึ้น \n Servify - สำหรับทุกความต้องการด้านการบริการของคุณ",
		"isAlreadyTranslated": true
	},
	"Your request number is @. Our Partner (@, @)  will collect the device from you as per the scheduled time. To know more, please check Track Request section within the App. T&C apply.":{
		"text":"หมายเลขคำร้องขอของคุณคือ @ พันธมิตรของเรา (@, @) จะมารับอุปกรณ์จากคุณตามเวลาที่กำหนดไว้ หากต้องการทราบข้อมูลเพิ่มเติม โปรดตรวจดูในส่วน ติดตามคำร้องขอ ภายในแอป การปรับใช้ T&C",
		"isAlreadyTranslated": true
	},
	"@ | We have received your claim request. Our representative will connect with you in next 48 hours.":{
		"text":"@ | เราได้รับคำร้องขอการเคลมของคุณแล้ว พนักงานตัวแทนของเราจะเชื่อมต่อกับคุณในอีก 48 ชั่วโมงข้างหน้า",
		"isAlreadyTranslated": true
	},
	"Your device will be picked up on @. Your Secret Code is @. Please handover the device to the Logistics Agent only once they provide this secret code to prove their identity.":{
		"text":"จะเข้าไปรับอุปกรณ์ของคุณในวันที่ @ รหัสลับของคุณคือ @ โปรดส่งมอบอุปกรณ์ให้กับตัวแทนโลจิสติกส์เฉพาะเมื่อเขาแสดงรหัสลับนี้เพื่อยืนยันตัวตนของเขาเท่านั้น",
		"isAlreadyTranslated": true
	},
	"Your Activation Code to register for the @ Plan is @. Download the @ app from @ and get a better service experience.":{
		"text":"รหัสการเปิดใช้งานของคุณในการลงทะเบียนสำหรับแผน @ คือ @ ดาวน์โหลดแอป @ จาก @ และพบกับประสบการณ์การบริการที่ดียิ่งขึ้น",
		"isAlreadyTranslated": true
	},
	"Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips. Download now. @":{
		"text":"รับบริการสำหรับ iPhone, iPad, Watch และ Mac จากศูนย์ที่ได้รับการแต่งตั้งผ่านปลายนิ้วของคุณ ดาวน์โหลดตอนนี้ @",
		"isAlreadyTranslated": true
	},
	"Pick up of your @ (Ref No. - @) has been rescheduled to @ as requested.":{
		"text":"ได้กำหนดเวลาเข้าไปรับ @ ของคุณ (หมายเลขอ้างอิง - @) ใหม่เป็น @ ตามที่ร้องขอ",
		"isAlreadyTranslated": true
	},
	"Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips.\niOS App - @\nWeb Portal - @":{
		"text":"รับบริการสำหรับ iPhone, iPad, Watch และ Mac จากศูนย์ที่ได้รับการแต่งตั้งผ่านปลายนิ้วของคุณ \nแอป iOS - @\nเว็บพอร์ทัล - @",
		"isAlreadyTranslated": true
	},
	"Download Lenovo Care: Personal assistant to all your Lenovo & Motorola smartphones https://bnc.lt/lenovo-care":{
		"text":"ดาวน์โหลด Lenovo Care: ผู้ช่วยส่วนตัวสำหรับโทรศัพท์สมาร์ทโฟน Lenovo และ Motorola ทั้งหมดของคุณ https://bnc.lt/lenovo-care",
		"isAlreadyTranslated": true
	},
	"Device Picked up: Your @ (Ref ID - @) was collected by Servify Executive on @ and will be delivered to an Authorized Service Centre. To know more, please check Track Repair section within the App. T&C Apply.":{
		"text":"รับอุปกรณ์แล้ว: เจ้าหน้าที่ Servify ได้มารับ @ ของคุณ (ID อ้างอิง - @) แล้วเมื่อวันที่ @ และจะจัดส่งไปยังศูนย์บริการที่ได้รับการแต่งตั้ง หากต้องการทราบข้อมูลเพิ่มเติม โปรดตรวจดูในส่วน ติดตามการซ่อม ภายในแอป การปรับใช้ T&C",
		"isAlreadyTranslated": true
	},
	"@ is your OTP for Micromax Care. This OTP is valid for 120 seconds. Do not share this OTP with any one for security reasons. We look forward to serving you.":{
		"text":"@ คือรหัส OTP ของคุณสำหรับ Micromax Care รหัส OTP นี้ใช้งานได้เป็นเวลา 120 วินาที ด้วยเหตุผลด้านความปลอดภัย ห้ามแบ่งปันรหัส OTP นี้กับผู้อื่น เราหวังว่าจะได้บริการคุณ",
		"isAlreadyTranslated": true
	},
	"Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips. Download now. https://bit.ly/authorized-service":{
		"text":"รับบริการสำหรับ iPhone, iPad, Watch และ Mac จากศูนย์ที่ได้รับการแต่งตั้งผ่านปลายนิ้วของคุณ ดาวน์โหลดตอนนี้ https://bit.ly/authorized-service",
		"isAlreadyTranslated": true
	},
	"@ | We have received your claim request. Our representative will connect with you in next one business day.":{
		"text":"@ | เราได้รับคำร้องขอการเคลมของคุณแล้ว พนักงานตัวแทนของเราจะเชื่อมต่อกับคุณในวันทำการถัดไป",
		"isAlreadyTranslated": true
	},
	"OnePlus Care App: Manage, Diagnose and Service your OnePlus Mobile Phone, download - https://jf1t.app.link/l1cm5EozpA":{
		"text":"แอป OnePlus Care: จัดการ วินิจฉัย และให้บริการเกี่ยวกับโทรศัพท์มือถือ OnePlus ของคุณ ดาวน์โหลด - https://jf1t.app.link/l1cm5EozpA",
		"isAlreadyTranslated": true
	},
	"Your Activation Code to register for the @ Plan is @. Download the Micromax Care app from http://bit.ly/MMXCare and get a better service experience.":{
		"text":"รหัสการเปิดใช้งานของคุณในการลงทะเบียนสำหรับแผน @ คือ @ ดาวน์โหลดแอป Micromax Care จาก http://bit.ly/MMXCare และพบกับประสบการณ์การบริการที่ดียิ่งขึ้น",
		"isAlreadyTranslated": true
	},
	"Pickup Rescheduled: Logistics Agent will attempt pickup of your @ on @ as requested.":{
		"text":"กำหนดเวลารับอุปกรณ์ใหม่แล้ว: ตัวแทนโลจิสติกส์จะเข้าไปรับ @ ของคุณในวันที่ @ ตามที่ร้องขอ",
		"isAlreadyTranslated": true
	},
	"New Request Ref. ID @ Name: @ Phone: @ Landmark: @":{
		"text":"ID อ้างอิงคำร้องขอใหม่ ชื่อ @: โทรศัพท์ @: จุดสังเกต @: @",
		"isAlreadyTranslated": true
	},
	"Greetings from Micromax. Congratulations! Your Dual 5 is now protected under Micromax Protect. Welcome to Hassle free Service experience.":{
		"text":"คำทักทายจาก Micromax ขอแสดงความยินดี! ขณะนี้ Dual 5 ของคุณได้รับการป้องกันภายใต้ Micromax Protect ยินดีต้อนรับสู่ประสบการณ์การบริการที่ไม่มีความยุ่งยากใดๆ",
		"isAlreadyTranslated": true
	},
	"Greetings from Micromax . Welcome to Micromax Care, your personal device assistant for all Micromax devices.":{
		"text":"คำทักทายจาก Micromax ยินดีต้อนรับสู่ Micromax Care ผู้ช่วยส่วนตัวสำหรับอุปกรณ์ของคุณ ซึ่งครอบคลุมอุปกรณ์ Micromax ทั้งหมด",
		"isAlreadyTranslated": true
	},
	"Get Authorized Service for iPhone, iPad, Watch and Mac right at your fingertips.\niOS App - https://bit.ly/authorized-service\nWeb Portal - https://servify.in/iphone":{
		"text":"รับบริการสำหรับ iPhone, iPad, Watch และ Mac จากศูนย์ที่ได้รับการแต่งตั้งผ่านปลายนิ้วของคุณ \nแอป iOS - https://bit.ly/authorized-service \nเว็บพอร์ทัล - https://servify.in/iphone",
		"isAlreadyTranslated": true
	},
	"Thank you for choosing Micromax Care, please rate our service on the App. We look forward to serving you again.":{
		"text":"ขอบคุณที่เลือก Micromax Care โปรดให้คะแนนการบริการของเราสำหรับแอปนี้ เราหวังว่าจะได้บริการคุณอีกครั้ง",
		"isAlreadyTranslated": true
	},
	"Download 'eRecycle' on your iPhone to raise and track eRecycle requests on the go. @":{
		"text":"ดาวน์โหลด \"eRecycle\" บน iPhone ของคุณเพื่อยื่นเรื่องและติดตามคำร้องขอสำหรับ eRecycle ได้ในทุกที่ @",
		"isAlreadyTranslated": true
	},
	"Device Picked up: Your @ (Ref ID - @) was collected by Logistics Agent on @ and will be delivered to Micromax Authorized Service Centre. To know more, please check Track Repair section within the App. T&C Apply.":{
		"text":"รับอุปกรณ์แล้ว: ตัวแทนโลจิสติกส์ได้มารับ @ ของคุณ (ID อ้างอิง - @) แล้วเมื่อวันที่ @ และจะจัดส่งไปยังศูนย์บริการที่ได้รับการแต่งตั้งจาก Micromax หากต้องการทราบข้อมูลเพิ่มเติม โปรดตรวจดูในส่วน ติดตามการซ่อม ภายในแอป การปรับใช้ T&C",
		"isAlreadyTranslated": true
	},
	"Dear Customer,\nWe have received a claim request for your device. Please click on the following link to cross check the information shared by you and submit to raise the claim. Kindly note, once submitted, the details cannot be changed.\n@":{
		"text":"เรียนท่านลูกค้า \nเราได้รับคำร้องขอการเคลมสำหรับอุปกรณ์ของคุณแล้ว โปรดคลิกที่ลิงก์ต่อไปนี้เพื่อตรวจสอบข้อมูลที่คุณได้แบ่งปันเอาไว้อีกครั้ง และส่งข้อมูลนั้นเพื่อยื่นเรื่องคำร้องขอการเคลม โปรดทราบว่า เมื่อส่งข้อมูลแล้ว จะไม่สามารถเปลี่ยนแปลงรายละเอียดได้อีก \n@",
		"isAlreadyTranslated": true
	},
	"Your device pickup is scheduled on @ with WAYBILL NUMBER @. Please hand over the Packed Device with Printed Documents, emailed by Servify to your Registered Email ID. PLEASE NOTE: Do NOT hand over the invoice copy of your phone to our Logistics Agent.":{
		"text":"มีการกำหนดเวลาเข้าไปรับอุปกรณ์ของคุณในวันที่ @ พร้อมหมายเลขใบนำส่งสินค้า @ โปรดส่งมอบอุปกรณ์ที่บรรจุหีบห่อแล้วพร้อมกับเอกสารสั่งพิมพ์ซึ่งเป็นอีเมลที่ Servify ส่งถึง ID อีเมลของคุณที่ลงทะเบียนไว้ โปรดทราบ: 'อย่ามอบ' สำเนาใบกำกับสินค้าของโทรศัพท์ของคุณให้กับตัวแทนโลจิสติกส์",
		"isAlreadyTranslated": true
	},
	"Amount Paid":{
		"text":"จำนวนเงินที่ชำระแล้ว",
		"isAlreadyTranslated": true
	},
	"Demo request (@/@) has been resheduled by the consumer":{
		"text":"คำร้องขอการสาธิต (@/@) ได้รับการกำหนดเวลาใหม่แล้วโดยผู้ใช้",
		"isAlreadyTranslated": true
	},
	"WarrantyConcession":{
		"text":"การยินยอมการรับประกัน",
		"isAlreadyTranslated": true
	},
	"Your @ has been repaired. Please collect it from (@/@)":{
		"text":"@ ของคุณได้รับการซ่อมแล้ว โปรดมารับอุปกรณ์ของคุณจาก (@/@)",
		"isAlreadyTranslated": true
	},
	"Repair request (@/@) has been resheduled by the consumer":{
		"text":"คำร้องขอการซ่อม (@/@) ได้รับการกำหนดเวลาใหม่แล้วโดยผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Your request for rescheduling has been accepted for @ (@/@/@)":{
		"text":"คำร้องขอของคุณในการกำหนดเวลาใหม่สำหรับ @ ได้รับการตอบรับแล้ว (@/@/@)",
		"isAlreadyTranslated": true
	},
	"Your @ has been repaired & delivered.":{
		"text":"@ ของคุณได้รับการซ่อมและจัดส่งแล้ว",
		"isAlreadyTranslated": true
	},
	"Installation request (@/@) has been canceled by the consumer":{
		"text":"คำร้องขอการติดตั้ง (@/@) ถูกยกเลิกแล้วโดยผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Your service request is Rescheduled by @":{
		"text":"คำร้องขอการบริการของคุณได้รับการกำหนดเวลาใหม่โดย @",
		"isAlreadyTranslated": true
	},
	"Installation request (@/@) has been resheduled by the consumer":{
		"text":"คำร้องขอการติดตั้ง (@/@) ได้รับการกำหนดเวลาใหม่แล้วโดยผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Demo request (@/@) has been canceled by the consumer":{
		"text":"คำร้องขอการสาธิต (@/@) ถูกยกเลิกแล้วโดยผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Service request (@/@) has been resheduled by the consumer":{
		"text":"คำร้องขอการบริการ (@/@) ได้รับการกำหนดเวลาใหม่แล้วโดยผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Your request (@/@) has been suspended":{
		"text":"คำร้องขอของคุณ (@/@) ถูกพักแล้ว",
		"isAlreadyTranslated": true
	},
	"@ is on the way to take care of your @.":{
		"text":"@ อยู่ระหว่างการเดินทางเพื่อดูแลเครื่อง @ ของคุณ",
		"isAlreadyTranslated": true
	},
	"Payment received for repair request (@)":{
		"text":"ได้รับการชำระเงินสำหรับคำร้องขอการซ่อม (@) แล้ว",
		"isAlreadyTranslated": true
	},
	"Awaiting Documents. Please Upload by @.":{
		"text":"กำลังรอเอกสาร โปรดอัปโหลดโดยใช้ @",
		"isAlreadyTranslated": true
	},
	"Claim Intimation Number : @.":{
		"text":"หมายเลขแจ้งการเคลม : @",
		"isAlreadyTranslated": true
	},
	"Verification successful. Kindly book a repair by @.":{
		"text":"การตรวจยืนยันเสร็จสมบูรณ์ โปรดจองการซ่อมในวันที่ @",
		"isAlreadyTranslated": true
	},
	"Device has been received by the service centre and is currently being diagnosed.":{
		"text":"ศูนย์บริการได้รับอุปกรณ์แล้ว และขณะนี้กำลังวินิจฉัยอาการอยู่",
		"isAlreadyTranslated": true
	},
	"Your payment has been received.":{
		"text":"ได้รับการชำระเงินของคุณแล้ว",
		"isAlreadyTranslated": true
	},
	"Your device is dispatched.":{
		"text":"อุปกรณ์ของคุณถูกส่งไปแล้ว",
		"isAlreadyTranslated": true
	},
	"Work in progress":{
		"text":"งานอยู่ระหว่างดำเนินการ",
		"isAlreadyTranslated": true
	},
	"We are working on your request and transfer of the eligible amount will be initiated soon.":{
		"text":"เรากำลังดำเนินการกับคำร้องขอของคุณ และจะเริ่มการโอนจำนวนเงินที่เข้าเกณฑ์ในเร็วๆ นี้",
		"isAlreadyTranslated": true
	},
	"Your device is repaired. Please visit the service centre to collect.":{
		"text":"อุปกรณ์ของคุณได้รับการซ่อม โปรดไปที่ศูนย์บริการเพื่อรับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Repair complete.":{
		"text":"การซ่อมเสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"Your request has lapsed due to non submission of the documents.":{
		"text":"คำร้องขอของคุณมีข้อผิดพลาดเนื่องจากไม่พบว่ามีการส่งเอกสาร",
		"isAlreadyTranslated": true
	},
	"Your claim has been declined. Please continue with self-paid repair.":{
		"text":"การเคลมของคุณถูกปฏิเสธ โปรดส่งซ่อมโดยออกค่าใช้จ่ายเอง",
		"isAlreadyTranslated": true
	},
	"Your request has lapsed since no repair has been booked.":{
		"text":"คำร้องขอของคุณมีข้อผิดพลาดเนื่องจากยังไม่ได้จองการซ่อม",
		"isAlreadyTranslated": true
	},
	"Your request has lapsed as we have not received payment for the repair":{
		"text":"คำร้องขอของคุณมีข้อผิดพลาดเนื่องจากเรายังไม่ได้รับการชำระเงินค่าซ่อม",
		"isAlreadyTranslated": true
	},
	"Your request has been cancelled":{
		"text":"คำร้องขอของคุณถูกยกเลิก",
		"isAlreadyTranslated": true
	},
	"Your device is ready to be collected by you. We hope you are satisfied with the service experience. Reference ID:@":{
		"text":"อุปกรณ์ของคุณพร้อมให้คุณมารับคืนแล้ว เราหวังว่าคุณจะพอใจกับการบริการของเรา ID อ้างอิง:@",
		"isAlreadyTranslated": true
	},
	"External storage issue":{
		"text":"ปัญหาเกี่ยวกับพื้นที่จัดเก็บข้อมูลภายนอก",
		"isAlreadyTranslated": true
	},
	"Noise issue":{
		"text":"ปัญหาเกี่ยวกับเสียง",
		"isAlreadyTranslated": true
	},
	"Not powering ON":{
		"text":"เปิดไม่ติด",
		"isAlreadyTranslated": true
	},
	"Not powering off":{
		"text":"ไม่สามารถปิดเครื่องได้",
		"isAlreadyTranslated": true
	},
	"Intermittently powering off":{
		"text":"เครื่องรีสตาร์ทหลายรอบ",
		"isAlreadyTranslated": true
	},
	"No display":{
		"text":"ไม่มีการแสดงผล",
		"isAlreadyTranslated": true
	},
	"Flickering":{
		"text":"จอกระพริบ",
		"isAlreadyTranslated": true
	},
	"Improper display":{
		"text":"การแสดงผลไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Blur display":{
		"text":"ภาพไม่ชัด",
		"isAlreadyTranslated": true
	},
	"Spot on display":{
		"text":"มีจุดบนจอแสดงผล",
		"isAlreadyTranslated": true
	},
	"No sound":{
		"text":"ไม่มีเสียง",
		"isAlreadyTranslated": true
	},
	"Cracking sound":{
		"text":"เสียงแตก",
		"isAlreadyTranslated": true
	},
	"Less sound":{
		"text":"เสียงเบา",
		"isAlreadyTranslated": true
	},
	"Network Issue":{
		"text":"ปัญหาเกี่ยวกับเครือข่าย",
		"isAlreadyTranslated": true
	},
	"Display damaged":{
		"text":"จอแสดงผลเสียหาย",
		"isAlreadyTranslated": true
	},
	"Body damaged":{
		"text":"ตัวเครื่องเสียหาย",
		"isAlreadyTranslated": true
	},
	"Vibrator not working":{
		"text":"การสั่นไม่ทำงาน",
		"isAlreadyTranslated": true
	},
	"Internal data storage issue":{
		"text":"ปัญหาเกี่ยวกับพื้นที่จัดเก็บข้อมูลภายใน",
		"isAlreadyTranslated": true
	},
	"Charger not working":{
		"text":"หัวชาร์จ",
		"isAlreadyTranslated": true
	},
	"Data cable not working":{
		"text":"สายข้อมูลไม่ทำงาน",
		"isAlreadyTranslated": true
	},
	"Earphone/Headphone/Handsfree Issue":{
		"text":"ปัญหาหูฟัง/เฮดโฟน/อุปกรณ์แฮนด์ฟรี",
		"isAlreadyTranslated": true
	},
	"Slow processing or hangs":{
		"text":"ประมวลผลช้าหรือค้าง",
		"isAlreadyTranslated": true
	},
	"Other":{
		"text":"อื่นๆ",
		"isAlreadyTranslated": true
	},
	"Back camera issue":{
		"text":"ปัญหาเกี่ยวกับกล้องด้านหลัง",
		"isAlreadyTranslated": true
	},
	"Full Diagnosis":{
		"text":"การวินิจฉัยแบบเต็ม",
		"isAlreadyTranslated": true
	},
	"Proper Assembly":{
		"text":"การประกอบที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Power Button":{
		"text":"ปุ่มเปิดปิด",
		"isAlreadyTranslated": true
	},
	"Unlock Button":{
		"text":"ปุ่มปลดล็อก",
		"isAlreadyTranslated": true
	},
	"Back Button":{
		"text":"ปุ่มกลับ",
		"isAlreadyTranslated": true
	},
	"Home Button":{
		"text":"ปุ่มโฮม",
		"isAlreadyTranslated": true
	},
	"TAB Button":{
		"text":"ปุ่ม TAB",
		"isAlreadyTranslated": true
	},
	"Volume Up Button":{
		"text":"ปุ่มเพิ่มเสียง",
		"isAlreadyTranslated": true
	},
	"Volume Down Button":{
		"text":"ปุ่มลดเสียง",
		"isAlreadyTranslated": true
	},
	"LED Flash":{
		"text":"แฟลช LED",
		"isAlreadyTranslated": true
	},
	"Dead Spots":{
		"text":"จุดอับสัญญาณ",
		"isAlreadyTranslated": true
	},
	"LCD Screen":{
		"text":"จอ LCD",
		"isAlreadyTranslated": true
	},
	"Battery Storage":{
		"text":"ที่เก็บแบตเตอรี่",
		"isAlreadyTranslated": true
	},
	"Charging Port":{
		"text":"พอร์ตสำหรับชาร์จไฟ",
		"isAlreadyTranslated": true
	},
	"Ear Piece":{
		"text":"หูฟัง",
		"isAlreadyTranslated": true
	},
	"USB Connectivity":{
		"text":"การเชื่อมต่อ USB",
		"isAlreadyTranslated": true
	},
	"Loud Speaker":{
		"text":"ลำโพง",
		"isAlreadyTranslated": true
	},
	"Sensors":{
		"text":"เซ็นเซอร์",
		"isAlreadyTranslated": true
	},
	"Microphone":{
		"text":"ไมโครโฟน",
		"isAlreadyTranslated": true
	},
	"Front Camera":{
		"text":"กล้องหน้า",
		"isAlreadyTranslated": true
	},
	"Back Camera":{
		"text":"กล้องหลัง",
		"isAlreadyTranslated": true
	},
	"Bluetooth":{
		"text":"บลูทูธ",
		"isAlreadyTranslated": true
	},
	"Wi-Fi":{
		"text":"Wi-Fi",
		"isAlreadyTranslated": true
	},
	"Network Connection":{
		"text":"การเชื่อมต่อเครือข่าย",
		"isAlreadyTranslated": true
	},
	"Incoming call":{
		"text":"สายเรียกเข้า",
		"isAlreadyTranslated": true
	},
	"Outgoing call":{
		"text":"สายออก",
		"isAlreadyTranslated": true
	},
	"Vibrator":{
		"text":"การสั่น",
		"isAlreadyTranslated": true
	},
	"IMEI Check":{
		"text":"การตรวจสอบ IMEI",
		"isAlreadyTranslated": true
	},
	"Software Version":{
		"text":"เวอร์ชั่นซอฟต์แวร์",
		"isAlreadyTranslated": true
	},
	"Check If Device Box IMEI Matches With Device IMEI":{
		"text":"ตรวจสอบว่า IMEI ของกล่องอุปกรณ์ตรงกับ IMEI ของอุปกรณ์หรือไม่",
		"isAlreadyTranslated": true
	},
	"Check If Device IMEI Matches With Internal IMEI":{
		"text":"ตรวจสอบว่า IMEI ของอุปกรณ์ตรงกับ IMEI ภายในหรือไม่",
		"isAlreadyTranslated": true
	},
	"Check If Touch/Display Is Broken":{
		"text":"ตรวจสอบว่าฟังก์ชั่นทัช/จอแสดงผลเสียหรือไม่",
		"isAlreadyTranslated": true
	},
	"Check If SIM Tray Is Missing Or Broken":{
		"text":"ตรวจสอบว่าถาดใส่ซิมขาดหายไปหรือแตกชำรุดหรือไม่",
		"isAlreadyTranslated": true
	},
	"Check If Back Cover Is Damaged":{
		"text":"ตรวจสอบว่าฝาครอบด้านหลังชำรุดเสียหายหรือไม่",
		"isAlreadyTranslated": true
	},
	"Check If Screw Heads Are Damaged":{
		"text":"ตรวจสอบว่าหัวสกรูชำรุดเสียหายหรือไม่",
		"isAlreadyTranslated": true
	},
	"Check If There Are Dents Or Internal Cracks In Display":{
		"text":"ตรวจสอบว่าหน้าจอมีแอ่งไม่เรียบหรือรอยแตกร้าวภายในหรือไม่",
		"isAlreadyTranslated": true
	},
	"Check If Volume Keys Are Loose Or Not Functioning":{
		"text":"ตรวจสอบว่าปุ่มปรับเสียงหลวมหรือปุ่มไม่ทำงานหรือไม่",
		"isAlreadyTranslated": true
	},
	"Check If Power Keys Are Loose Or Not Functioning":{
		"text":"ตรวจสอบว่าปุ่มเปิดปิดหลวมหรือปุ่มไม่ทำงานหรือไม่",
		"isAlreadyTranslated": true
	},
	"Check If Camera's Auto Focus Is Not Working":{
		"text":"ตรวจสอบว่าระบบโฟกัสอัตโนมัติของกล้องไม่ทำงานหรือไม่",
		"isAlreadyTranslated": true
	},
	"Check If Photos Clicked Are Blurred":{
		"text":"ตรวจสอบว่ารูปภาพที่กดถ่ายแล้วเป็นภาพเบลอหรือไม่",
		"isAlreadyTranslated": true
	},
	"Check If USB Port Is Damaged":{
		"text":"ตรวจสอบว่าพอร์ต USB ชำรุดเสียหายหรือไม่",
		"isAlreadyTranslated": true
	},
	"Check If Accessories Are As Mentioned On The Sales Pack":{
		"text":"ตรวจสอบว่าอุปกรณ์เสริมเป็นไปตามที่ระบุบนชุดสำหรับขายหรือไม่",
		"isAlreadyTranslated": true
	},
	"Check If There Are Functional Defects":{
		"text":"ตรวจสอบว่ามีข้อบกพร่องในการใช้งานหรือไม่",
		"isAlreadyTranslated": true
	},
	"Box IMEI Vs Unit IMEI":{
		"text":"IMEI ของกล่องกับ IMEI ของเครื่อง",
		"isAlreadyTranslated": true
	},
	"Unit IMEI Vs Internal IMEI":{
		"text":"IMEI ของเครื่องกับ IMEI ภายใน",
		"isAlreadyTranslated": true
	},
	"Touch/LCD Broken":{
		"text":"ฟังก์ชั่นทัช/จอ LCD เสีย",
		"isAlreadyTranslated": true
	},
	"SIM Tray Missing or Broken":{
		"text":"ถาดใส่ซิมขาดหายหรือแตกชำรุด",
		"isAlreadyTranslated": true
	},
	"Back Cover Damaged":{
		"text":"ฝาครอบด้านหลังชำรุดเสียหาย",
		"isAlreadyTranslated": true
	},
	"Screw heads Damaged":{
		"text":"หัวสกรูชำรุดเสียหาย",
		"isAlreadyTranslated": true
	},
	"Any Dents or Internal Crack in display":{
		"text":"หน้าจอมีแอ่งไม่เรียบหรือรอยแตกร้าวภายใน",
		"isAlreadyTranslated": true
	},
	"Power/Volume Keys Loose or Not Functioning":{
		"text":"ปุ่มเปิดปิด/ปุ่มปรับเสียงหลวมหรือไม่ทำงาน",
		"isAlreadyTranslated": true
	},
	"Camera AF & Blurr Issues":{
		"text":"ระบบโฟกัสของกล้องและปัญหาภาพเบลอ",
		"isAlreadyTranslated": true
	},
	"USB Port Damaged":{
		"text":"พอร์ต USB ชำรุดเสียหาย",
		"isAlreadyTranslated": true
	},
	"Accessories as per Content printed on sales pack":{
		"text":"อุปกรณ์เสริมครบตามข้อมูลที่พิมพ์ไว้บนชุดสำหรับขาย",
		"isAlreadyTranslated": true
	},
	"Functional Defects":{
		"text":"ข้อบกพร่องในการใช้งาน",
		"isAlreadyTranslated": true
	},
	"@ | Your claim will be processed within the next 6 business hours":{
		"text":"@ | การเคลมของคุณจะได้รับการดำเนินการภายใน 6 ชั่วโมงทำงานนับจากนี้",
		"isAlreadyTranslated": true
	},
	"@ | Your request has been cancelled.":{
		"text":"@ | คำร้องขอของคุณถูกยกเลิก",
		"isAlreadyTranslated": true
	},
	"@ | Your request has lapsed as we have not received payment for the repair.":{
		"text":"@ | คำร้องขอของคุณมีข้อผิดพลาดเนื่องจากเรายังไม่ได้รับการชำระเงินค่าซ่อม",
		"isAlreadyTranslated": true
	},
	"@ | Document verification was declined. Please visit @ to know the details":{
		"text":"@ | การตรวจยืนยันเอกสารของคุณถูกปฏิเสธ โปรดไปที่ @ เพื่อดูรายละเอียด",
		"isAlreadyTranslated": true
	},
	"@ | Your claim has been declined. Please continue with self-paid repair.":{
		"text":"@ | การเคลมของคุณถูกปฏิเสธ โปรดส่งซ่อมโดยออกค่าใช้จ่ายเอง",
		"isAlreadyTranslated": true
	},
	"@ | Your request has lapsed as you have not booked a repair request.":{
		"text":"@ | คำร้องขอของคุณมีข้อผิดพลาดเนื่องจากคุณยังไม่ได้จองคำร้องขอการซ่อม",
		"isAlreadyTranslated": true
	},
	"@Check here first@ Go to @Settings > General > About@ and look for your device's serial number, IMEI/MEID, and ICCID. Tap and hold on the number and copy it.\nIf you still haven't found the number that you need, see below for other ways to locate it.":{
		"text":"@ดูที่นี่ก่อน@ ไปที่ @การตั้งค่า > ทั่วไป > เกี่ยวกับ@ และมองหาเลขประจำเครื่อง, IMEI/MEID และ ICCID ของอุปกรณ์ของคุณ แตะค้างที่หมายเลขและทำการคัดลอก\nถ้าคุณยังไม่พบหมายเลขที่คุณต้องการ โปรดดูด้านล่างนี้สำหรับการหาด้วยวิธีอื่น",
		"isAlreadyTranslated": true
	},
	"@Or check the case of your iPhone@ On these devices, you'll find the serial number in the Settings menu and the IMEI/MEID on the SIM tray. If you need support but you can't get to the Settings menu, you can use the IMEI/MEID instead of the serial number: • iPhone 7 • iPhone 7 Plus • iPhone 6s • iPhone 6s Plus":{
		"text":"@หรือดูที่เคสเครื่อง iPhone ของคุณ@ บนอุปกรณ์เหล่านี้ คุณจะพบเลขประจำเครื่องได้ในเมนู การตั้งค่า และ IMEI/MEID บนถาดใส่ซิม หากคุณต้องการความช่วยเหลือแต่คุณไม่สามารถเข้าเมนู การตั้งค่า ได้ คุณสามารถใช้ IMEI/MEID แทนเลขประจำเครื่องได้: • iPhone 7 • iPhone 7 Plus • iPhone 6s • iPhone 6s Plus",
		"isAlreadyTranslated": true
	},
	"On these devices, you'll find the serial number in the Settings menu and the IMEI/MEID (the MEID is the first 14 digits of the IMEI) on the back. If you need support but you can't get to the Settings menu, you can use the IMEI/MEID instead of the serial number: • iPhone 6 • iPhone 6 Plus • iPhone SE • iPhone 5s • iPhone 5c • iPhone 5":{
		"text":"บนอุปกรณ์เหล่านี้ คุณจะพบเลขประจำเครื่องในเมนู การตั้งค่า และ IMEI/MEID (MEID คือเลข 14 ตัวแรกของ IMEI) ที่ด้านหลังโทรศัพท์ หากคุณต้องการความช่วยเหลือแต่คุณไม่สามารถเข้าเมนู การตั้งค่า ได้ คุณสามารถใช้ IMEI/MEID แทนเลขประจำเครื่องได้: • iPhone 6 • iPhone 6 Plus • iPhone SE • iPhone 5s • iPhone 5c • iPhone 5",
		"isAlreadyTranslated": true
	},
	"On these devices, you can find the serial number and IMEI/MEID on the SIM tray: • iPhone 3G • iPhone 3GS • iPhone 4 (GSM model) • iPhone 4s":{
		"text":"บนอุปกรณ์เหล่านี้ คุณสามารถพบเลขประจำเครื่องและ IMEI/MEID ได้บนถาดใส่ซิม: • iPhone 3G • iPhone 3GS • iPhone 4 (รุ่น GSM) • iPhone 4s",
		"isAlreadyTranslated": true
	},
	"Go to @Settings > General > About @ and look for your device's serial number, IMEI/MEID, and ICCID. Tap and hold on the number and copy it.":{
		"text":"ไปที่ @การตั้งค่า > ทั่วไป > เกี่ยวกับ @ และมองหาเลขประจำเครื่อง IMEI/MEID และ ICCID ของอุปกรณ์ของคุณ แตะค้างที่หมายเลขและทำการคัดลอก",
		"isAlreadyTranslated": true
	},
	"You can find the serial number and IMEI/MEID on the @SIM tray.@":{
		"text":"คุณสามารถพบเลขประจำเครื่องและ IMEI/MEID ได้บน@ถาดใส่ซิม@",
		"isAlreadyTranslated": true
	},
	"Go to @Settings > General > About@ and look for your device's serial number, IMEI/MEID, and ICCID. Tap and hold on the number and copy it.":{
		"text":"ไปที่ @การตั้งค่า > ทั่วไป > เกี่ยวกับ@ และมองหาเลขประจำเครื่อง, IMEI/MEID และ ICCID ของอุปกรณ์ของคุณ แตะค้างที่หมายเลขและทำการคัดลอก",
		"isAlreadyTranslated": true
	},
	"You can find the serial number and IMEI/MEID on the @SIM tray.@":{
		"text":"คุณสามารถพบเลขประจำเครื่องและ IMEI/MEID ได้บน@ถาดใส่ซิม@",
		"isAlreadyTranslated": true
	},
	"Go to @Settings > General > About@ and look for your device's serial number, IMEI/MEID, and ICCID. Tap and hold on the number and copy it.":{
		"text":"ไปที่ @การตั้งค่า > ทั่วไป > เกี่ยวกับ@ และมองหาเลขประจำเครื่อง, IMEI/MEID และ ICCID ของอุปกรณ์ของคุณ แตะค้างที่หมายเลขและทำการคัดลอก",
		"isAlreadyTranslated": true
	},
	"You can find the IMEI/MEID on the @back of your phone.@":{
		"text":"คุณสามารถพบ IMEI/MEID ได้ที่@ด้านหลังโทรศัพท์ของคุณ@",
		"isAlreadyTranslated": true
	},
	"Go to @Settings > General > About@ and look for your device's serial number, IMEI/MEID, and ICCID. Tap and hold on the number and copy it.":{
		"text":"ไปที่ @การตั้งค่า > ทั่วไป > เกี่ยวกับ@ และมองหาเลขประจำเครื่อง, IMEI/MEID และ ICCID ของอุปกรณ์ของคุณ แตะค้างที่หมายเลขและทำการคัดลอก",
		"isAlreadyTranslated": true
	},
	"You can find the serial number and IMEI/MEID on the @SIM tray.@":{
		"text":"คุณสามารถพบเลขประจำเครื่องและ IMEI/MEID ได้บน@ถาดใส่ซิม@",
		"isAlreadyTranslated": true
	},
	"Choose @About This Mac@ from the Apple menu @()@ in the upper-left corner of your screen. @About This Mac@ shows an overview of your Mac, including the name and version of its operating system, its model name, and its serial number:":{
		"text":"เลือก @เกี่ยวกับ Mac เครื่องนี้@ จากเมนู Apple @()@ ที่มุมด้านซ้ายบนของหน้าจอของคุณ @เกี่ยวกับ Mac เครื่องนี้@ จะแสดงข้อมูลโดยรวมของเครื่อง Mac ของคุณ รวมทั้งชื่อและเวอร์ชั่นของระบบปฏิบัติการ ชื่อรุ่น และเลขประจำเครื่อง:",
		"isAlreadyTranslated": true
	},
	"If you see an About This Mac window like the following, double-click the version number beneath “OS X” to reveal the serial number:":{
		"text":"หากคุณเห็นหน้าต่าง เกี่ยวกับ Mac เครื่องนี้ เหมือนกับที่แสดงต่อไปนี้ ให้คลิกสองครั้งที่หมายเลขรุ่นใต้ \"OS X\" เพื่อเปิดเลขประจำเครื่อง:",
		"isAlreadyTranslated": true
	},
	"Choose @About This Mac@ from the Apple menu @()@ in the upper-left corner of your screen. @About This Mac@ shows an overview of your Mac, including the name and version of its operating system, its model name, and its serial number:":{
		"text":"เลือก @เกี่ยวกับ Mac เครื่องนี้@ จากเมนู Apple @()@ ที่มุมด้านซ้ายบนของหน้าจอของคุณ @เกี่ยวกับ Mac เครื่องนี้@ จะแสดงข้อมูลโดยรวมของเครื่อง Mac ของคุณ รวมทั้งชื่อและเวอร์ชั่นของระบบปฏิบัติการ ชื่อรุ่น และเลขประจำเครื่อง:",
		"isAlreadyTranslated": true
	},
	"If you see an About This Mac window like the following, double-click the version number beneath “OS X” to reveal the serial number:":{
		"text":"หากคุณเห็นหน้าต่าง เกี่ยวกับ Mac เครื่องนี้ เหมือนกับที่แสดงต่อไปนี้ ให้คลิกสองครั้งที่หมายเลขรุ่นใต้ \"OS X\" เพื่อเปิดเลขประจำเครื่อง:",
		"isAlreadyTranslated": true
	},
	"@On the surface of your MacBook@ Close your MacBook and turn it over. The serial number is on the underside of the computer, near the regulatory markings.":{
		"text":"@บนตัวเครื่อง MacBook ของคุณ@ ให้ปิดเครื่อง MacBook แล้วพลิกด้านล่างเครื่องขึ้นมา เลขประจำเครื่องจะแสดงอยู่ใต้เครื่องคอมพิวเตอร์ ใกล้กับเครื่องหมายข้อกำหนดต่างๆ",
		"isAlreadyTranslated": true
	},
	"Choose @About This Mac@ from the Apple menu @()@ in the upper-left corner of your screen. @About This Mac@ shows an overview of your Mac, including the name and version of its operating system, its model name, and its serial number:":{
		"text":"เลือก @เกี่ยวกับ Mac เครื่องนี้@ จากเมนู Apple @()@ ที่มุมด้านซ้ายบนของหน้าจอของคุณ @เกี่ยวกับ Mac เครื่องนี้@ จะแสดงข้อมูลโดยรวมของเครื่อง Mac ของคุณ รวมทั้งชื่อและเวอร์ชั่นของระบบปฏิบัติการ ชื่อรุ่น และเลขประจำเครื่อง:",
		"isAlreadyTranslated": true
	},
	"If you see an About This Mac window like the following, double-click the version number beneath “OS X” to reveal the serial number:":{
		"text":"หากคุณเห็นหน้าต่าง เกี่ยวกับ Mac เครื่องนี้ เหมือนกับที่แสดงต่อไปนี้ ให้คลิกสองครั้งที่หมายเลขรุ่นใต้ \"OS X\" เพื่อเปิดเลขประจำเครื่อง:",
		"isAlreadyTranslated": true
	},
	"@On the surface of your iMac@ The serial number of your iMac is printed on its surface. To find it, shut down your iMac and unplug any connected cables so you can safely move it. Then gently lay the computer face-down on a soft, clean towel or cloth.\nThe serial number is on the underside of the base (or foot) of the iMac, along with a bar code and regulatory markings.":{
		"text":"@บนตัวเครื่อง iMac ของคุณ@ เลขประจำเครื่องของเครื่อง iMac จะพิมพ์อยู่บนพื้นผิวตัวเครื่อง ในการหาเลขประจำเครื่อง ให้ปิดเครื่อง iMac ของคุณและถอดปลั๊กสายต่างๆ ที่เชื่อมต่ออยู่เพื่อให้คุณสามารถเคลื่อนย้ายเครื่องได้อย่างปลอดภัย จากนั้น ค่อยๆ วางเครื่องคอมพิวเตอร์โดยคว่ำหน้าลงบนผ้าขนหนูหรือผ้านุ่มๆ ที่สะอาด\nเลขประจำเครื่องจะอยู่ด้านล่างของฐาน (หรือขาตั้ง) ของเครื่อง iMac โดยอยู่กับเครื่องหมายบาร์โค้ดและเครื่องหมายข้อกำหนดต่างๆ",
		"isAlreadyTranslated": true
	},
	"@Check the Settings menu@ On your Apple Watch, open the Settings app, tap General > About, then scroll down.\nOn your iPhone, open the Apple Watch App, go to the My Watch tab, then tap @General > About@.On your iPhone, open the Apple Watch App, go to the My Watch tab, then tap General > About.":{
		"text":"@ดูที่เมนู การตั้งค่า@ บนนาฬิกา Apple Watch ของคุณ เปิดแอป การตั้งค่า แตะที่ ทั่วไป > เกี่ยวกับ จากนั้นเลื่อนลง\nบนเครื่อง iPhone ของคุณ เปิดแอป Apple Watch และไปที่แท็บ Apple Watch ของฉัน จากนั้นแตะ @ทั่วไป > เกี่ยวกับ@ บนเครื่อง iPhone ของคุณ เปิดแอป Apple Watch และไปที่แท็บ Apple Watch ของฉัน จากนั้นแตะที่ ทั่วไป > เกี่ยวกับ",
		"isAlreadyTranslated": true
	},
	"@Or check the case of your Apple Watch@On Apple Watch (1st generation), check the back of your watch for the engraved serial number.":{
		"text":"@หรือดูที่ตัวเรือนนาฬิกา Apple Watch ของคุณ@บนนาฬิกา Apple Watch (รุ่นที่ 1) ดูที่ด้านหลังนาฬิกาของคุณเพื่อหาเลขประจำเครื่องที่สลักไว้",
		"isAlreadyTranslated": true
	},
	"On Apple Watch Series 1 and Apple Watch Series 2, including Apple Watch Hermès and Apple Watch Nike+, remove the band from your watch, then check the band slot.":{
		"text":"บนนาฬิกา Apple Watch ซีรี่ส์ 1 และ Apple Watch ซีรี่ส์ 2 รวมถึง Apple Watch Hermès และ Apple Watch Nike+ ให้ถอดสายออกจากนาฬิกาของคุณ แล้วดูในช่องยึดสาย",
		"isAlreadyTranslated": true
	},
	"Find the serial number for your iPad Pro, iPad, or iPod touch on the back of the device. On an iPad (cellular model), you'll also find the IMEI. The MEID is the first 14 digits of the IMEI.":{
		"text":"ค้นหาเลขประจำเครื่องสำหรับเครื่อง iPad Pro, iPad หรือ iPod touch ของคุณที่ด้านหลังอุปกรณ์ บนเครื่อง iPad (รุ่นเซลลูล่าร์) คุณจะพบ IMEI ด้วย ซึ่ง MEID จะเป็นตัวเลข 14 ตัวแรกของ IMEI",
		"isAlreadyTranslated": true
	},
	"Find the serial number for your iPod touch on the back of the device":{
		"text":"ค้นหาเลขประจำเครื่องสำหรับเครื่อง iPod touch ของคุณที่ด้านหลังอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"@Check the About screen for the serial number@ To see the serial number for your Apple TV, choose Settings > General > About. You can also see the serial number for your Siri Remote or Apple TV Remote* by choosing Settings > Remotes and Devices > Remote.":{
		"text":"@ดูที่หน้าจอ เกี่ยวกับ เพื่อหาเลขประจำเครื่อง@ ในการดูเลขประจำเครื่องสำหรับ Apple TV ให้เลือก การตั้งค่า > ทั่วไป > เกี่ยวกับ และคุณยังสามารถดูเลขประจำเครื่องสำหรับรีโมต Siri หรือรีโมต Apple TV* ได้โดยการเลือก การตั้งค่า > รีโมตและอุปกรณ์ > รีโมต",
		"isAlreadyTranslated": true
	},
	"@Check the bottom of your Apple TV for the reflective serial number @ Since the serial number on the bottom of your device is reflective, use a light to read the number.":{
		"text":"@ดูที่ด้านล่างของอุปกรณ์ Apple TV ของคุณเพื่อหาเลขประจำเครื่องที่สะท้อนแสง @ เนื่องจากเลขประจำเครื่องที่ด้านล่างของอุปกรณ์ของคุณเป็นแบบสะท้อนแสง ดังนั้นให้ใช้ไฟส่องเพื่ออ่านตัวเลข",
		"isAlreadyTranslated": true
	},
	"@Check the box for the serial number@ If you have your device's original box, you can see the serial number on the barcode.":{
		"text":"@ดูที่กล่องเพื่อหาเลขประจำเครื่อง@ ถ้าคุณมีกล่องดั้งเดิมของอุปกรณ์ คุณจะสามารถดูเลขประจำเครื่องได้บนบาร์โค้ด",
		"isAlreadyTranslated": true
	},
	"Oh no! Tell us what went wrong":{
		"text":"โอ้ ไม่! บอกเราว่ามีอะไรไม่ดี",
		"isAlreadyTranslated": true
	},
	"The ease of using the app":{
		"text":"ความง่ายของการใช้งานแอป",
		"isAlreadyTranslated": true
	},
	"Speed & Efficiency":{
		"text":"ความเร็วและประสิทธิภาพ",
		"isAlreadyTranslated": true
	},
	"Overall Service":{
		"text":"การบริการโดยรวม",
		"isAlreadyTranslated": true
	},
	"Oops! Please tell us what can be improved":{
		"text":"โอ๊ะ! โปรดบอกเราว่าจะสามารถปรับปรุงเรื่องใดได้บ้าง",
		"isAlreadyTranslated": true
	},
	"Please tell us what can be improved":{
		"text":"โปรดบอกเราว่าจะสามารถปรับปรุงเรื่องใดได้บ้าง",
		"isAlreadyTranslated": true
	},
	"What did you like about us?":{
		"text":"สิ่งที่คุณชอบเกี่ยวกับเราคืออะไร?",
		"isAlreadyTranslated": true
	},
	"Great! What did you like about us?":{
		"text":"เยี่ยมมาก สิ่งที่คุณชอบเกี่ยวกับเราคืออะไร?",
		"isAlreadyTranslated": true
	},
	"Excellent":{
		"text":"ยอดเยี่ยม",
		"isAlreadyTranslated": true
	},
	"Good":{
		"text":"ดี",
		"isAlreadyTranslated": true
	},
	"OK":{
		"text":"ตกลง",
		"isAlreadyTranslated": true
	},
	"Bad":{
		"text":"แย่",
		"isAlreadyTranslated": true
	},
	"Terrible":{
		"text":"แย่มาก",
		"isAlreadyTranslated": true
	},
	"Pickup & Delivery Service":{
		"text":"บริการรับและจัดส่งอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Repair quality":{
		"text":"คุณภาพการซ่อม",
		"isAlreadyTranslated": true
	},
	"Transparency":{
		"text":"ความโปร่งใส",
		"isAlreadyTranslated": true
	},
	"Service Partner hospitality":{
		"text":"การต้อนรับของคู่ค้าด้านการบริการ",
		"isAlreadyTranslated": true
	},
	"Technician":{
		"text":"ช่างเทคนิค",
		"isAlreadyTranslated": true
	},
	"Convenience of discarding device":{
		"text":"ความสะดวกในการทิ้งอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Install Specialist":{
		"text":"ผู้เชี่ยวชาญในการติดตั้ง",
		"isAlreadyTranslated": true
	},
	"Demo Specialist":{
		"text":"ผู้เชี่ยวชาญในการสาธิต",
		"isAlreadyTranslated": true
	},
	"Maintenance quality":{
		"text":"คุณภาพการบำรุงรักษา",
		"isAlreadyTranslated": true
	},
	"Time Flexibility":{
		"text":"ความยืดหยุ่นเรื่องเวลา",
		"isAlreadyTranslated": true
	},
	"Maintenance quality":{
		"text":"คุณภาพการบำรุงรักษา",
		"isAlreadyTranslated": true
	},
	"Green Rewards":{
		"text":"Green Reward",
		"isAlreadyTranslated": true
	},
	"Convenience of submitting an iPhone":{
		"text":"ความสะดวกในการส่งเครื่อง iPhone",
		"isAlreadyTranslated": true
	},
	"Came Late":{
		"text":"มาสาย",
		"isAlreadyTranslated": true
	},
	"Inexperienced":{
		"text":"ขาดประสบการณ์",
		"isAlreadyTranslated": true
	},
	"Unprofessional":{
		"text":"ไม่เป็นมืออาชีพ",
		"isAlreadyTranslated": true
	},
	"Expensive":{
		"text":"ราคาแพง",
		"isAlreadyTranslated": true
	},
	"Bad Service":{
		"text":"บริการแย่",
		"isAlreadyTranslated": true
	},
	"Very Far":{
		"text":"ไกลมาก",
		"isAlreadyTranslated": true
	},
	"I confirm that I have removed the SIM Card(s), have taken necessary data backup and removed any security passcode/pattern from this phone. I further declare that Servify will not be responsible for any loss or delay in repair due to misrepresentation of above communication.":{
		"text":"ฉันขอยืนยันว่าฉันได้ถอดซิมการ์ดออกแล้ว ได้สำรองข้อมูลที่จำเป็น และลบรหัสผ่าน/รูปแบบการล็อกเพื่อความปลอดภัยต่างๆ ออกจากโทรศัพท์เครื่องนี้แล้ว นอกจากนี้ ฉันยังขอยืนยันว่า Servify จะไม่ต้องรับผิดชอบต่อการสูญหายใดๆ หรือการล่าช้าในการซ่อมอันเนื่องมาจากการรับรองที่เป็นเท็จจากการสื่อสารข้างต้น",
		"isAlreadyTranslated": true
	},
	"Remove security passcode/pattern (Mandatory)":{
		"text":"ลบรหัสผ่าน/รูปแบบการล็อกเพื่อความปลอดภัย (จำเป็น)",
		"isAlreadyTranslated": true
	},
	"Doing so helps our Service Partner quickly start with service of your phone without any hassles.":{
		"text":"การทำเช่นนี้จะช่วยให้คู่ค้าด้านการบริการของเราเริ่มให้บริการโทรศัพท์ของคุณได้อย่างรวดเร็วโดยไม่มีความยุ่งยากใดๆ",
		"isAlreadyTranslated": true
	},
	"Remove SIM card from your Phone":{
		"text":"ถอดซิมการ์ดออกจากเครื่องโทรศัพท์ของคุณ",
		"isAlreadyTranslated": true
	},
	"This helps prevent any unauthorized use of your SIM Card and data.":{
		"text":"การทำเช่นนี้จะช่วยป้องกันไม่ให้มีการใช้งานซิมการ์ดและข้อมูลของคุณโดยไม่ได้รับอนุญาต",
		"isAlreadyTranslated": true
	},
	"Backup your Phone (recommended)":{
		"text":"สำรองข้อมูลโทรศัพท์ของคุณ (ขอแนะนำ)",
		"isAlreadyTranslated": true
	},
	"Certain software related repairs might lead to loss of your data. Backing up data ensures you don't lose any valuable data.":{
		"text":"ซอฟต์แวร์ที่เกี่ยวข้องกับการซ่อมบางตัวอาจส่งผลให้สูญเสียข้อมูลของคุณ การสำรองข้อมูลไว้จะช่วยให้แน่ใจว่าคุณจะไม่สูญเสียข้อมูลอันมีค่า",
		"isAlreadyTranslated": true
	},
	"I confirm that I have removed the SIM card, have taken necessary data backup and turned off 'Find My iPhone' from this iPhone. I further declare that Servify will not be responsible for any losses or delay in repair due to misrepresentation of above confirmation.":{
		"text":"ฉันยืนยันว่าฉันได้ถอดซิมการ์ดออกแล้ว ได้สำรองข้อมูลที่จำเป็น และปิด 'ค้นหา iPhone ของฉัน' จาก iPhone เครื่องนี้แล้ว นอกจากนี้ ฉันยังขอยืนยันว่า Servify จะไม่ต้องรับผิดชอบต่อการสูญหายใดๆ หรือการล่าช้าในการซ่อมอันเนื่องมาจากการรับรองที่เป็นเท็จจากการยืนยันข้างต้น",
		"isAlreadyTranslated": true
	},
	"Turn Off Find My iPhone":{
		"text":"ปิด ค้นหา iPhone ของฉัน",
		"isAlreadyTranslated": true
	},
	"Start at your Home screen.":{
		"text":"เริ่มที่หน้าจอโฮมของคุณ",
		"isAlreadyTranslated": true
	},
	"Tap Settings > iCloud.":{
		"text":"แตะ การตั้งค่า > iCloud",
		"isAlreadyTranslated": true
	},
	"Scroll to the bottom and tap Find My iPhone.":{
		"text":"เลื่อนลงด้านล่างและแตะ ค้นหา iPhone ของฉัน",
		"isAlreadyTranslated": true
	},
	"Slide to turn off Find My iPhone and Send Last Location.":{
		"text":"เลื่อนเพื่อปิด ค้นหา iPhone ของฉัน และ ส่งตำแหน่งที่ตั้งล่าสุด",
		"isAlreadyTranslated": true
	},
	"If you're asked to sign in, enter your Apple ID.":{
		"text":"ถ้าระบบขอให้คุณลงชื่อเข้า ให้ป้อน Apple ID ของคุณ",
		"isAlreadyTranslated": true
	},
	"Remove SIM Card From Your Device":{
		"text":"ถอดซิมการ์ดออกจากอุปกรณ์ของคุณ",
		"isAlreadyTranslated": true
	},
	"After you remove or transfer your SIM card insert the tray completely and in the same orientation that you removed it (it will fit only one way).":{
		"text":"หลังจากคุณถอดหรือโอนซิมการ์ดของคุณแล้ว ให้สอดถาดกลับเข้าไปจนสุดในทิศทางเดียวกับที่คุณดึงออกมา (ถาดจะเข้าพอดีจากด้านเดียวเท่านั้น)",
		"isAlreadyTranslated": true
	},
	"Backup Your iOS Device (Recommended)":{
		"text":"สำรองข้อมูลอุปกรณ์ iOS ของคุณ (ขอแนะนำ)",
		"isAlreadyTranslated": true
	},
	"Before you get started, learn the difference between making backups with iCloud and iTunes and choose the method that's best for you. In case you ever need an alternative backup, you can make one in iCloud and another in iTunes.":{
		"text":"ก่อนที่คุณจะเริ่มต้น ให้เรียนรู้ความแตกต่างระหว่างการสำรองข้อมูลกับ iCloud และ iTunes และเลือกวิธีที่ดีที่สุดสำหรับคุณ ในกรณีที่คุณต้องการการสำรองข้อมูลเพิ่มเติม คุณสามารถสำรองชุดหนึ่งไว้ใน iCloud และอีกชุดหนึ่งใน iTunes",
		"isAlreadyTranslated": true
	},
	"Call":{
		"text":"การโทร",
		"isAlreadyTranslated": true
	},
	"Email":{
		"text":"อีเมล",
		"isAlreadyTranslated": true
	},
	"Connect on Facebook":{
		"text":"เชื่อมต่อบน Facebook",
		"isAlreadyTranslated": true
	},
	"Connect on Twitter":{
		"text":"เชื่อมต่อบน Twitter",
		"isAlreadyTranslated": true
	},
	"Connect on LinkedIn":{
		"text":"เชื่อมต่อบน LinkedIn",
		"isAlreadyTranslated": true
	},
	"Terms & Conditions":{
		"text":"ข้อกำหนดและเงื่อนไข",
		"isAlreadyTranslated": true
	},
	"Privacy Policy":{
		"text":"นโยบายความเป็นส่วนตัว",
		"isAlreadyTranslated": true
	},
	"Claim Form":{
		"text":"แบบฟอร์มการเคลม",
		"isAlreadyTranslated": true
	},
	"Go to @Settings > General > About@ and look for your device's serial number, IMEI/MEID, and ICCID. If you'd like to paste this information into Apple registration or support forms, tap and hold on the number and copy it.":{
		"text":"ไปที่ @การตั้งค่า > ทั่วไป > เกี่ยวกับ@ และหาเลขประจำเครื่อง, IMEI/MEID และ ICCID ของอุปกรณ์ของคุณ ถ้าคุณต้องการวางข้อมูลนี้ลงในแบบฟอร์มการลงทะเบียนของ Apple หรือแบบฟอร์มขอความช่วยเหลือ ให้แตะค้างที่หมายเลขและทำการคัดลอก",
		"isAlreadyTranslated": true
	},
	"You can find the serial number and IMEI/MEID on the @SIM tray.@":{
		"text":"คุณสามารถพบเลขประจำเครื่องและ IMEI/MEID ได้บน@ถาดใส่ซิม@",
		"isAlreadyTranslated": true
	},
	"You can find the IMEI/MEID on the @back of your phone.@":{
		"text":"คุณสามารถพบ IMEI/MEID ได้ที่@ด้านหลังโทรศัพท์ของคุณ@",
		"isAlreadyTranslated": true
	},
	"Servify is India's largest device management and proactive ownership platform. Starting as an app based customer support service for consumer electronics, today we have created our own end-to-end device management platform.":{
		"text":"Servify เป็นระบบการจัดการอุปกรณ์ที่ใหญ่ที่สุดในอินเดีย และเป็นแพลตฟอร์มความเป็นเจ้าของแบบเชิงรุก เริ่มต้นจากบริการสนับสนุนลูกค้าผ่านแอปสำหรับเครื่องใช้ไฟฟ้าอุปโภคบริโภค จนถึงวันนี้เราได้สร้างแพลตฟอร์มของเราเองที่ทำหน้าที่จัดการอุปกรณ์แบบครบวงจร",
		"isAlreadyTranslated": true
	},
	"We enable top OEMs & ODMs in device distribution, sales, warranty, after-sales and end of life management through our device management platform.":{
		"text":"เราเปิดให้บริการสำหรับอุปกรณ์ OEM และ ODM ระดับบนในการจัดจำหน่ายอุปกรณ์ การขาย การรับประกัน การบริการหลังการขาย ตลอดจนถึงการจัดการอุปกรณ์ที่หมดอายุการใช้งาน โดยผ่านแพลตฟอร์มการจัดการอุปกรณ์ของเรา",
		"isAlreadyTranslated": true
	},
	"We have also developed best in class smart device diagnostics for smartphones and have built an end to end service ecosystem including our own service factory and field logistics force":{
		"text":"นอกจากนี้ เรายังได้พัฒนาระบบการวินิจฉัยอุปกรณ์สมาร์ทสำหรับสมาร์ทโฟนที่ดีที่สุดในระดับเดียวกัน และได้สร้างระบบนิเวศสำหรับการบริการแบบครบวงจร รวมทั้งมีโรงงานเพื่อการบริการเป็นของเราเองและมีทีมงานสนับสนุนด้านโลจิสติกส์ภาคสนาม",
		"isAlreadyTranslated": true
	},
	"Our consumer offerings include one touch service app and a smart ownership platform. It also has Damage Protection and Extended Warranties for smart devices":{
		"text":"ข้อเสนอที่มอบให้กับผู้ใช้ของเราได้แก่ แอปบริการแบบวันทัช และแพลตฟอร์มความเป็นเจ้าของแบบอัจฉริยะ และยังมีเรื่องของการป้องกันความเสียหายและส่วนขยายการรับประกันสำหรับอุปกรณ์สมาร์ทต่างๆ",
		"isAlreadyTranslated": true
	},
	"While some of our technology works behind the scenes to manage your devices, we hope to engage with you directly and make your lives better.":{
		"text":"โดยที่เทคโนโลยีบางส่วนของเราทำงานอยู่เบื้องหลังเพื่อดูแลจัดการอุปกรณ์ของคุณ เราหวังว่าจะสามารถเชื่อมต่อกับคุณได้โดยตรงและช่วยให้คุณใช้ชีวิตได้ดีขึ้น",
		"isAlreadyTranslated": true
	},
	"We love devices and we want to help you get the best out of them. Every device has a lifespan and today that life span is getting shorter. Most electronics that are improperly thrown away contain harmful materials like beryllium, cadmium, mercury and lead in some form or another. These materials might be present in traces in individual products, but when added up in volume, the threat to the environment is significant.":{
		"text":"เรามีความสนใจในอุปกรณ์ต่างๆ และปรารถนาที่จะช่วยคุณให้ได้รับสิ่งที่ดีที่สุด อุปกรณ์ทุกชิ้นมีอายุการใช้งาน และในวันนี้อายุการใช้งานอุปกรณ์ก็เริ่มที่จะสั้นลง อุปกรณ์อิเล็กทรอนิกส์ส่วนใหญ่ที่ถูกนำไปทิ้งอย่างไม่ถูกต้อง ซึ่งมีสารอันตรายอย่างเบอริลเลียม แคดเมียม เมอร์คิวรี และตะกั่วอยู่ในรูปแบบใดรูปแบบหนึ่ง วัสดุเหล่านี้อาจพบอยู่ในผลิตภัณฑ์แต่ละชิ้นในปริมาณเล็กน้อย แต่เมื่อสะสมจนมีปริมาณสูงขึ้น จะก่อให้เกิดภัยคุกคามสิ่งแวดล้อมอย่างมาก",
		"isAlreadyTranslated": true
	},
	"We want your help to recycle all End of Life (EOL) devices with ease. That is why we are introducing pickup and dropoff options of your EOL device. These devices are recycled by only certified recycling partners.":{
		"text":"เราต้องการความช่วยเหลือของคุณในการรีไซเคิลอุปกรณ์ต่างๆ ที่หมดอายุการใช้งาน (EOL) ด้วยความง่ายดาย ด้วยเหตุนี้ เราจึงขอแนะนำตัวเลือกสำหรับบริการรับ-ส่งอุปกรณ์ EOL ของคุณ อุปกรณ์เหล่านี้จะถูกนำไปรีไซเคิลโดยคู่ค้าด้านการรีไซเคิลที่ผ่านการรับรองแล้วเท่านั้น",
		"isAlreadyTranslated": true
	},
	"Servify is India's largest device management and proactive ownership platform. Starting as an app based customer support service for consumer electronics, today we have created our own end-to-end device management platform.":{
		"text":"Servify เป็นระบบการจัดการอุปกรณ์ที่ใหญ่ที่สุดในอินเดีย และเป็นแพลตฟอร์มความเป็นเจ้าของแบบเชิงรุก เริ่มต้นจากบริการสนับสนุนลูกค้าผ่านแอปสำหรับเครื่องใช้ไฟฟ้าอุปโภคบริโภค จนถึงวันนี้เราได้สร้างแพลตฟอร์มของเราเองที่ทำหน้าที่จัดการอุปกรณ์แบบครบวงจร",
		"isAlreadyTranslated": true
	},
	"We enable top OEMs & ODMs in device distribution, sales, warranty, after-sales and end of life management through our device management platform. We have also developed best in class smart device diagnostics for smartphones and have built an end to end service ecosystem including our own service factory and field logistics force.":{
		"text":"เราเปิดให้บริการสำหรับอุปกรณ์ OEM และ ODM ระดับบนในการจัดจำหน่ายอุปกรณ์ การขาย การรับประกัน การบริการหลังการขาย ตลอดจนถึงการจัดการอุปกรณ์ที่หมดอายุการใช้งาน โดยผ่านแพลตฟอร์มการจัดการอุปกรณ์ของเรา นอกจากนี้ เรายังได้พัฒนาระบบการวินิจฉัยอุปกรณ์สมาร์ทสำหรับสมาร์ทโฟนที่ดีที่สุดในระดับเดียวกัน และได้สร้างระบบนิเวศสำหรับการบริการแบบครบวงจร รวมทั้งมีโรงงานเพื่อการบริการเป็นของเราเองและมีทีมงานสนับสนุนด้านโลจิสติกส์ภาคสนาม",
		"isAlreadyTranslated": true
	},
	"Our consumer offerings include one touch service app and a smart ownership platform. It also has Damage Protection and Extended Warranties for smart devices.":{
		"text":"ข้อเสนอที่มอบให้กับผู้ใช้ของเราได้แก่ แอปบริการแบบวันทัช และแพลตฟอร์มความเป็นเจ้าของแบบอัจฉริยะ และยังมีเรื่องของการป้องกันความเสียหายและส่วนขยายการรับประกันสำหรับอุปกรณ์สมาร์ทต่างๆ",
		"isAlreadyTranslated": true
	},
	"While some of our technology works behind the scenes to manage your devices, we hope to engage with you directly through this recycle initiative. Let's dispose our devices responsibly and make this planet a better place.":{
		"text":"โดยที่เทคโนโลยีบางส่วนของเราทำงานอยู่เบื้องหลังเพื่อจัดการกับอุปกรณ์ของคุณ เราหวังว่าจะสามารถเชื่อมต่อกับคุณได้โดยตรงผ่านโครงการรีไซเคิลนี้ มากำจัดทิ้งอุปกรณ์ของเราด้วยความรับผิดชอบต่อสิ่งแวดล้อมกันดีกว่า ช่วยให้โลกของเราน่าอยู่ยิ่งขึ้น",
		"isAlreadyTranslated": true
	},
	"BatteryEvent":{
		"text":"'เหตุการณ์แบตเตอรี่'",
		"isAlreadyTranslated": true
	},
	"Hardware":{
		"text":"ฮาร์ดแวร์",
		"isAlreadyTranslated": true
	},
	"Battery":{
		"text":"แบตเตอรี่",
		"isAlreadyTranslated": true
	},
	"Battery State":{
		"text":"สถานะแบตเตอรี่",
		"isAlreadyTranslated": true
	},
	"Capacity":{
		"text":"ความจุ",
		"isAlreadyTranslated": true
	},
	"Health":{
		"text":"สุขภาพ",
		"isAlreadyTranslated": true
	},
	"Level":{
		"text":"ระดับ",
		"isAlreadyTranslated": true
	},
	"Temperature":{
		"text":"อุณหภูมิ",
		"isAlreadyTranslated": true
	},
	"NetworkEvent":{
		"text":"'เหตุการณ์เครือข่าย'",
		"isAlreadyTranslated": true
	},
	"Connectivity":{
		"text":"การเชื่อมต่อ",
		"isAlreadyTranslated": true
	},
	"Network Info":{
		"text":"ข้อมูลเครือข่าย",
		"isAlreadyTranslated": true
	},
	"Operator Name":{
		"text":"ชื่อผู้ให้บริการ",
		"isAlreadyTranslated": true
	},
	"Phone Type":{
		"text":"ประเภทโทรศัพท์",
		"isAlreadyTranslated": true
	},
	"Signal Strength":{
		"text":"ความแรงสัญญาณ",
		"isAlreadyTranslated": true
	},
	"SimCountry Code":{
		"text":"รหัสประเทศของซิม",
		"isAlreadyTranslated": true
	},
	"State of Service":{
		"text":"สถานะของการบริการ",
		"isAlreadyTranslated": true
	},
	"Operator Name":{
		"text":"ชื่อผู้ให้บริการ",
		"isAlreadyTranslated": true
	},
	"Phone Type":{
		"text":"ประเภทโทรศัพท์",
		"isAlreadyTranslated": true
	},
	"SimCountry Code":{
		"text":"รหัสประเทศของซิม",
		"isAlreadyTranslated": true
	},
	"State of Service":{
		"text":"สถานะของการบริการ",
		"isAlreadyTranslated": true
	},
	"Signal Strength":{
		"text":"ความแรงสัญญาณ",
		"isAlreadyTranslated": true
	},
	"SensorEvent":{
		"text":"'เหตุการณ์เซ็นเซอร์'",
		"isAlreadyTranslated": true
	},
	"Sensors":{
		"text":"เซ็นเซอร์",
		"isAlreadyTranslated": true
	},
	"The repair cost of your device will be INR @ but you have to pay only INR @. Please make the payment by @ to begin repair.":{
		"text":"ค่าใช้จ่ายในการซ่อมอุปกรณ์ของคุณจะเท่ากับ @ INR แต่คุณต้องชำระเป็นจำนวน @ INR เท่านั้น โปรดชำระเงินโดย @ เพื่อเริ่มทำการซ่อม",
		"isAlreadyTranslated": true
	},
	"Air Pressure":{
		"text":"ความกดอากาศ",
		"isAlreadyTranslated": true
	},
	"SensorEvent":{
		"text":"'เหตุการณ์เซ็นเซอร์'",
		"isAlreadyTranslated": true
	},
	"Connectivity":{
		"text":"การเชื่อมต่อ",
		"isAlreadyTranslated": true
	},
	"Call Service":{
		"text":"บริการด้านการโทร",
		"isAlreadyTranslated": true
	},
	"SensorEvent":{
		"text":"'เหตุการณ์เซ็นเซอร์'",
		"isAlreadyTranslated": true
	},
	"Sensors":{
		"text":"เซ็นเซอร์",
		"isAlreadyTranslated": true
	},
	"Gyroscope":{
		"text":"ไจโรสโคป",
		"isAlreadyTranslated": true
	},
	"Heart Rate":{
		"text":"อัตราการเต้นของหัวใจ",
		"isAlreadyTranslated": true
	},
	"Humidity":{
		"text":"ความชื้น",
		"isAlreadyTranslated": true
	},
	"Connectivity":{
		"text":"การเชื่อมต่อ",
		"isAlreadyTranslated": true
	},
	"Message Service":{
		"text":"บริการเกี่ยวกับข้อความ",
		"isAlreadyTranslated": true
	},
	"Magnetic Field":{
		"text":"สนามแม่เหล็ก",
		"isAlreadyTranslated": true
	},
	"IR Sensor":{
		"text":"เซ็นเซอร์ IR",
		"isAlreadyTranslated": true
	},
	"Gravity":{
		"text":"แรงโน้มถ่วง",
		"isAlreadyTranslated": true
	},
	"Accelerometer":{
		"text":"มาตรวัดอัตราเร่ง",
		"isAlreadyTranslated": true
	},
	"Air Temperature":{
		"text":"อุณหภูมิอากาศ",
		"isAlreadyTranslated": true
	},
	"GPS":{
		"text":"GPS",
		"isAlreadyTranslated": true
	},
	"RAM":{
		"text":"RAM",
		"isAlreadyTranslated": true
	},
	"Available":{
		"text":"ใช้งานได้",
		"isAlreadyTranslated": true
	},
	"Total":{
		"text":"ทั้งหมด",
		"isAlreadyTranslated": true
	},
	"GB":{
		"text":"GB",
		"isAlreadyTranslated": true
	},
	"Internal Storage":{
		"text":"พื้นที่จัดเก็บข้อมูลภายใน",
		"isAlreadyTranslated": true
	},
	"CPU":{
		"text":"CPU",
		"isAlreadyTranslated": true
	},
	"Minimum Clock":{
		"text":"ความเร็วนาฬิกาต่ำสุด",
		"isAlreadyTranslated": true
	},
	"Processors/Cores":{
		"text":"โปรเซสเซอร์/คอร์",
		"isAlreadyTranslated": true
	},
	"Fingerprint":{
		"text":"ลายนิ้วมือ",
		"isAlreadyTranslated": true
	},
	"Vibrator":{
		"text":"การสั่น",
		"isAlreadyTranslated": true
	},
	"Hardware":{
		"text":"ฮาร์ดแวร์",
		"isAlreadyTranslated": true
	},
	"Proximity Sensor":{
		"text":"เซ็นเซอร์ตรวจจับระยะใกล้เคียง",
		"isAlreadyTranslated": true
	},
	"Speaker":{
		"text":"ลำโพง",
		"isAlreadyTranslated": true
	},
	"Sound":{
		"text":"เสียง",
		"isAlreadyTranslated": true
	},
	"Ports":{
		"text":"พอร์ต",
		"isAlreadyTranslated": true
	},
	"Earphones":{
		"text":"หูฟัง",
		"isAlreadyTranslated": true
	},
	"USB":{
		"text":"USB",
		"isAlreadyTranslated": true
	},
	"Volume Buttons":{
		"text":"ปุ่มปรับเสียง",
		"isAlreadyTranslated": true
	},
	"Touch Screen":{
		"text":"จอสัมผัส",
		"isAlreadyTranslated": true
	},
	"Dead or White Spots":{
		"text":"จุดบอดหรือจุดสีขาว",
		"isAlreadyTranslated": true
	},
	"Flashlight":{
		"text":"ไฟฉาย",
		"isAlreadyTranslated": true
	},
	"MAC Address":{
		"text":"ที่อยู่ MAC",
		"isAlreadyTranslated": true
	},
	"Signal Strength":{
		"text":"ความแรงสัญญาณ",
		"isAlreadyTranslated": true
	},
	"Max Wifi Speed":{
		"text":"ความเร็ว Wifi สูงสุด",
		"isAlreadyTranslated": true
	},
	"Frequency":{
		"text":"ความถี่",
		"isAlreadyTranslated": true
	},
	"Wi-Fi Statistics":{
		"text":"สถิติ Wi-Fi",
		"isAlreadyTranslated": true
	},
	"Multi Touch":{
		"text":"มัลติทัช",
		"isAlreadyTranslated": true
	},
	"Brightness":{
		"text":"ความสว่าง",
		"isAlreadyTranslated": true
	},
	"Earpiece Speaker":{
		"text":"ลำโพงหูฟัง",
		"isAlreadyTranslated": true
	},
	"Phone Info":{
		"text":"ข้อมูลโทรศัพท์",
		"isAlreadyTranslated": true
	},
	"Android Version":{
		"text":"เวอร์ชั่น Android",
		"isAlreadyTranslated": true
	},
	"Air Conditioner":{
		"text":"เครื่องปรับอากาศ",
		"isAlreadyTranslated": true
	},
	"repair":{
		"text":"ซ่อม",
		"isAlreadyTranslated": true
	},
	"install":{
		"text":"ติดตั้ง",
		"isAlreadyTranslated": true
	},
	"service":{
		"text":"บริการ",
		"isAlreadyTranslated": true
	},
	"TV":{
		"text":"โทรทัศน์",
		"isAlreadyTranslated": true
	},
	"AC":{
		"text":"เครื่องปรับอากาศ",
		"isAlreadyTranslated": true
	},
	"Fridge":{
		"text":"ตู้เย็น",
		"isAlreadyTranslated": true
	},
	"Washing Machine":{
		"text":"เครื่องซักผ้า",
		"isAlreadyTranslated": true
	},
	"Camera":{
		"text":"กล้อง",
		"isAlreadyTranslated": true
	},
	"Computer":{
		"text":"คอมพิวเตอร์",
		"isAlreadyTranslated": true
	},
	"Mobile":{
		"text":"มือถือ",
		"isAlreadyTranslated": true
	},
	"Tablet":{
		"text":"แท็บเล็ต",
		"isAlreadyTranslated": true
	},
	"Microwave":{
		"text":"ไมโครเวฟ",
		"isAlreadyTranslated": true
	},
	"Router":{
		"text":"เราเตอร์",
		"isAlreadyTranslated": true
	},
	"Water Purifier":{
		"text":"เครื่องกรองน้ำ",
		"isAlreadyTranslated": true
	},
	"Air Purifier":{
		"text":"เครื่องกรองอากาศ",
		"isAlreadyTranslated": true
	},
	"Audio Product":{
		"text":"เครื่องเสียง",
		"isAlreadyTranslated": true
	},
	"Vacuum Cleaner":{
		"text":"เครื่องดูดฝุ่น",
		"isAlreadyTranslated": true
	},
	"Juicer Mixer":{
		"text":"เครื่องปั่นน้ำผลไม้",
		"isAlreadyTranslated": true
	},
	"Printer":{
		"text":"เครื่องพิมพ์",
		"isAlreadyTranslated": true
	},
	"Chest Coole":{
		"text":"คูลเลอร์แช่เครื่องดื่ม",
		"isAlreadyTranslated": true
	},
	"Streaming Device":{
		"text":"อุปกรณ์สตรีมมิ่ง",
		"isAlreadyTranslated": true
	},
	"Chest Freezer":{
		"text":"ตู้แช่ฟรีซ",
		"isAlreadyTranslated": true
	},
	"Security Camera":{
		"text":"กล้องระบบรักษาความปลอดภัย",
		"isAlreadyTranslated": true
	},
	"Smart Watch":{
		"text":"สมาร์ทวอช",
		"isAlreadyTranslated": true
	},
	"Office CCTV":{
		"text":"กล้อง CCTV สำหรับสำนักงาน",
		"isAlreadyTranslated": true
	},
	"Air Cooler":{
		"text":"พัดลมไอเย็น",
		"isAlreadyTranslated": true
	},
	"Room Heater":{
		"text":"เครื่องทำความร้อนในห้อง",
		"isAlreadyTranslated": true
	},
	"Water Heater":{
		"text":"เครื่องทำน้ำอุ่น",
		"isAlreadyTranslated": true
	},
	"Dry Iron":{
		"text":"เตารีด",
		"isAlreadyTranslated": true
	},
	"Kettle":{
		"text":"กาต้มน้ำ",
		"isAlreadyTranslated": true
	},
	"Rice Cooker":{
		"text":"หม้อหุงข้าว",
		"isAlreadyTranslated": true
	},
	"Toaster":{
		"text":"Toaster",
		"isAlreadyTranslated": true
	},
	"Dish TV":{
		"text":"Dish TV",
		"isAlreadyTranslated": true
	},
	"Ear Pods":{
		"text":"หูฟัง",
		"isAlreadyTranslated": true
	},
	"Power Adapter":{
		"text":"อะแดปเตอร์จ่ายไฟ",
		"isAlreadyTranslated": true
	},
	"Lightning to USB Cable":{
		"text":"Lightning ไปยังสาย USB",
		"isAlreadyTranslated": true
	},
	"Display Damaged":{
		"text":"จอแสดงผลเสียหาย",
		"isAlreadyTranslated": true
	},
	"Battery Damaged":{
		"text":"แบตเตอรี่เสียหาย",
		"isAlreadyTranslated": true
	},
	"Backup Taken":{
		"text":"ทำการสำรองข้อมูลแล้ว",
		"isAlreadyTranslated": true
	},
	"Find my iPhone Active":{
		"text":"ค้นหา iPhone ของฉัน ทำงานอยู่",
		"isAlreadyTranslated": true
	},
	"Apple Limited Warranty":{
		"text":"การรับประกันแบบจำกัดของ Apple",
		"isAlreadyTranslated": true
	},
	"Accessories":{
		"text":"อุปกรณ์เสริม",
		"isAlreadyTranslated": true
	},
	"Physical Damage":{
		"text":"ความเสียหายของตัวเครื่อง",
		"isAlreadyTranslated": true
	},
	"Power":{
		"text":"ปัญหาการเปิดปิดเครื่อง",
		"isAlreadyTranslated": true
	},
	"System Performance":{
		"text":"ประสิทธิภาพของระบบ",
		"isAlreadyTranslated": true
	},
	"Built in Camera":{
		"text":"ปัญหาของกล้อง",
		"isAlreadyTranslated": true
	},
	"Display":{
		"text":"จอแสดงผล",
		"isAlreadyTranslated": true
	},
	"Communication/Networking":{
		"text":"การติดต่อสื่อสาร/ระบบเครือข่าย",
		"isAlreadyTranslated": true
	},
	"Data Storage":{
		"text":"พื้นที่จัดเก็บข้อมูล",
		"isAlreadyTranslated": true
	},
	"Vibrator":{
		"text":"การสั่น",
		"isAlreadyTranslated": true
	},
	"Sound":{
		"text":"เสียง",
		"isAlreadyTranslated": true
	},
	"Other":{
		"text":"อื่นๆ",
		"isAlreadyTranslated": true
	},
	"@ | Your payment has been received.":{
		"text":"@ | ได้รับการชำระเงินของคุณแล้ว",
		"isAlreadyTranslated": true
	},
	"@ | Your device is repaired. Please visit the service centre to collect.":{
		"text":"@ | อุปกรณ์ของคุณได้รับการซ่อม โปรดไปที่ศูนย์บริการเพื่อรับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"@ | Your device is repaired and dispatched.":{
		"text":"@ | อุปกรณ์ของคุณได้รับการซ่อมและส่งคืน",
		"isAlreadyTranslated": true
	},
	"@ | The repair cost of your device will be INR @ but you have to pay only INR @. Payment by @ to begin repair.":{
		"text":"@ | ค่าใช้จ่ายในการซ่อมอุปกรณ์ของคุณจะเท่ากับ @ INR แต่คุณต้องชำระเป็นจำนวน @ INR เท่านั้น การชำระเงินโดย @ เพื่อเริ่มทำการซ่อม",
		"isAlreadyTranslated": true
	},
	"@ | Device has been received by the service centre and is currently being diagnosed.":{
		"text":"@ | ศูนย์บริการได้รับอุปกรณ์แล้ว และขณะนี้กำลังวินิจฉัยอาการอยู่",
		"isAlreadyTranslated": true
	},
	"@ | Document verification was successful. Please visit @ to book the repair":{
		"text":"@ | การตรวจยืนยันเอกสารของคุณเสร็จสมบูรณ์ โปรดไปที่ @ เพื่อจองการซ่อม",
		"isAlreadyTranslated": true
	},
	"@ | We are awaiting Documents. Please Upload by @ to make sure that your request does not get lapsed. @":{
		"text":"@ | เรากำลังรอเอกสาร โปรดอัปโหลดโดยใช้ @ เพื่อให้แน่ใจว่าไม่มีความผิดพลาดเกิดขึ้นกับคำร้องขอของคุณ @",
		"isAlreadyTranslated": true
	},
	"@ | Your Request has been registered. Please check the request for any further documentation required from you.":{
		"text":"@ | ได้ลงทะเบียนคำร้องขอของคุณแล้ว โปรดตรวจสอบคำร้องขอเอกสารเพิ่มเติมใดๆ ที่คุณได้ร้องขอไว้",
		"isAlreadyTranslated": true
	},
	"Repair request (@/@) has been canceled by the consumer":{
		"text":"คำร้องขอการซ่อม (@/@) ถูกยกเลิกแล้วโดยผู้ใช้",
		"isAlreadyTranslated": true
	},
	"@ Action needs to be taken by Store for @ part request(s).":{
		"text":"ร้านค้าจำเป็นต้องดำเนินการ @ สำหรับคำร้องขอชิ้นส่วน @",
		"isAlreadyTranslated": true
	},
	"@ Part(s) Ordered by Store.":{
		"text":"ชิ้นส่วน @ ที่สั่งโดยร้านค้า",
		"isAlreadyTranslated": true
	},
	"@ Part(s) Issued by Store.":{
		"text":"ชิ้นส่วน @ ที่ออกโดยร้านค้า",
		"isAlreadyTranslated": true
	},
	"All parts received, repair is in progress.":{
		"text":"ได้รับชิ้นส่วนครบทั้งหมดแล้ว การซ่อมกำลังดำเนินการอยู่",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive will choose action for the device from Dead on Arrival section below.":{
		"text":"เจ้าหน้าที่ดูแลลูกค้าจะเลือกการดำเนินการสำหรับอุปกรณ์จากส่วน 'เสียเมื่อมาถึง' (DOA) ด้านล่างนี้",
		"isAlreadyTranslated": true
	},
	"Device repair has failed QC. Device is to be given back to the Engineer.":{
		"text":"การซ่อมอุปกรณ์ไม่ผ่าน QC อุปกรณ์จะถูกส่งกลับไปยังวิศวกร",
		"isAlreadyTranslated": true
	},
	"Device Whitelisting Information":{
		"text":"ข้อมูลบัญชีขาวของอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Eligible for DOA":{
		"text":"เข้าเกณฑ์สำหรับ DOA",
		"isAlreadyTranslated": true
	},
	"Engineer will select the new device from Dead on Arrival section below":{
		"text":"วิศวกรจะเลือกอุปกรณ์ใหม่จากส่วน 'เสียเมื่อมาถึง' (DOA) ด้านล่างนี้",
		"isAlreadyTranslated": true
	},
	"Estimation has been rejected, parts may be added if required by the engineer.":{
		"text":"ใบประเมินถูกปฏิเสธแล้ว อาจมีการเพิ่มชิ้นส่วนถ้าวิศวกรต้องการใช้",
		"isAlreadyTranslated": true
	},
	"Estimation has been rejected, parts may be added if required by the engineer.":{
		"text":"ใบประเมินถูกปฏิเสธแล้ว อาจมีการเพิ่มชิ้นส่วนถ้าวิศวกรต้องการใช้",
		"isAlreadyTranslated": true
	},
	"Engineer completed the repair. Handed over the device to QC.":{
		"text":"วิศวกรทำการซ่อมเสร็จแล้ว ส่งอุปกรณ์ไปยังแผนก QC แล้ว",
		"isAlreadyTranslated": true
	},
	"Engineer completed the repair.":{
		"text":"ช่างเทคนิคทำการซ่อมเสร็จเรียบร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"Last Repair Date":{
		"text":"วันที่ซ่อมครั้งสุดท้าย",
		"isAlreadyTranslated": true
	},
	"No, the device is not whitelistied. Please return the device if you have it":{
		"text":"ไม่ใช่ อุปกรณ์เครื่องนี้ไม่ได้อยู่ในบัญชีขาว โปรดส่งอุปกรณ์คืนถ้าอุปกรณ์อยู่ที่คุณ",
		"isAlreadyTranslated": true
	},
	"No subcategories found":{
		"text":"ไม่พบหมวดย่อย",
		"isAlreadyTranslated": true
	},
	"No supported modes found":{
		"text":"ไม่พบโหมดที่รองรับ",
		"isAlreadyTranslated": true
	},
	"No supported mode found":{
		"text":"ไม่พบโหมดที่รองรับ",
		"isAlreadyTranslated": true
	},
	"No issues found":{
		"text":"ไม่พบปัญหา",
		"isAlreadyTranslated": true
	},
	"Payment for the service has been received from the customer.":{
		"text":"ได้รับการชำระเงินสำหรับการบริการแล้วจากลูกค้า",
		"isAlreadyTranslated": true
	},
	"Please complete the service.":{
		"text":"โปรดให้บริการจนเสร็จเรียบร้อย",
		"isAlreadyTranslated": true
	},
	"Product Name":{
		"text":"ชื่อผลิตภัณฑ์",
		"isAlreadyTranslated": true
	},
	"Product Unique ID":{
		"text":"ID ที่ไม่ซ้ำของผลิตภัณฑ์",
		"isAlreadyTranslated": true
	},
	"Pickup Drop By $providerName":{
		"text":"รับ-ส่งโดย '$ชื่อผู้ให้บริการ'",
		"isAlreadyTranslated": true
	},
	"Repair has been cancelled.":{
		"text":"การซ่อมถูกยกเลิกแล้ว",
		"isAlreadyTranslated": true
	},
	"Request Has Been Raised":{
		"text":"ยื่นเรื่องคำร้องขอแล้ว",
		"isAlreadyTranslated": true
	},
	"Requires customer to print documents and safely pack the device.":{
		"text":"ลูกค้าต้องพิมพ์เอกสารและบรรจุอุปกรณ์ลงหีบห่ออย่างแน่นหนา",
		"isAlreadyTranslated": true
	},
	"Reference ID":{
		"text":"ID อ้างอิง",
		"isAlreadyTranslated": true
	},
	"Raise new request":{
		"text":"ยื่นเรื่องคำร้องขอใหม่",
		"isAlreadyTranslated": true
	},
	"Service invoice has been generated, final payment can be received.":{
		"text":"สร้างใบกำกับสินค้าแล้ว สามารถรับการชำระเงินส่วนสุดท้ายได้",
		"isAlreadyTranslated": true
	},
	"Serial Number/IMEI is mandatory for Apple products. Please enter valid Serial Number/IMEI.":{
		"text":"เลขประจำเครื่อง/IMEI เป็นข้อมูลที่จำเป็นต้องป้อนสำหรับผลิตภัณฑ์ Apple โปรดป้อนเลขประจำเครื่อง/IMEI",
		"isAlreadyTranslated": true
	},
	"Slot Time":{
		"text":"ช่วงเวลา",
		"isAlreadyTranslated": true
	},
	"Schedule Date":{
		"text":"วันที่กำหนด",
		"isAlreadyTranslated": true
	},
	"Send for raising a request?":{
		"text":"ส่งเพื่อยื่นเรื่องคำร้องขอหรือไม่?",
		"isAlreadyTranslated": true
	},
	"The serial number and the selected product do not match.":{
		"text":"เลขประจำเครื่องกับผลิตภัณฑ์ที่เลือกไม่ตรงกัน",
		"isAlreadyTranslated": true
	},
	"There's a problem with this address, please select or add a different adress":{
		"text":"มีปัญหากับที่อยู่นี้ โปรดเลือกหรือเพิ่มที่อยู่อื่น",
		"isAlreadyTranslated": true
	},
	"There's a problem with this landmark, please select a different landmark":{
		"text":"มีปัญหากับจุดสังเกตนี้ โปรดเลือกจุดสังเกตอื่น",
		"isAlreadyTranslated": true
	},
	"The request was raised successfully.":{
		"text":"ยื่นใบรับงานสำเร็จ",
		"isAlreadyTranslated": true
	},
	"The entered Srial/IMEI Number is not whitelisted. Please check the number once again.":{
		"text":"เลขประจำเครื่อง/IMEI ที่ป้อนไม่ได้อยู่ในบัญชีขาว โปรดตรวจสอบหมายเลขอีกครั้งหนึ่ง",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to receive device and complete mandatory checklist tasks":{
		"text":"กำลังรอเจ้าหน้าที่บริการลูกค้ามารับอุปกรณ์และทำงานรายการตรวจสอบที่จำเป็นให้เสร็จสิ้น",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer to accept the job.":{
		"text":"กำลังรอวิศวกรตอบรับงาน",
		"isAlreadyTranslated": true
	},
	"Work in Progress with Engineer.":{
		"text":"งานอยู่ระหว่างดำเนินการกับวิศวกร",
		"isAlreadyTranslated": true
	},
	"Waiting for the Engineer to raise the request.":{
		"text":"กำลังรอวิศวกรยื่นเรื่องคำร้องขอ",
		"isAlreadyTranslated": true
	},
	"Waiting for Estimation Approval. Click on 'Send for approval' in 'Parts and Estimation' section below.":{
		"text":"กำลังรอการอนุมัติใบประเมิน คลิกที่ \"ส่งเพื่ออนุมัติ\" ในส่วน \"ชิ้นส่วนและการประเมิน\" ด้านล่าง",
		"isAlreadyTranslated": true
	},
	"Waiting for customer approval of estimation or override estimation approval in 'Parts and Estimation' section below.":{
		"text":"กำลังรอการอนุมัติจากลูกค้าสำหรับใบประเมิน หรือข้ามการอนุมัติใบประเมินในส่วน \"ชิ้นส่วนและการประเมิน\" ด้านล่าง",
		"isAlreadyTranslated": true
	},
	"Waiting for store to issue required parts in 'Parts Requested' section below. Cannot complete repair with parts pending.":{
		"text":"กำลังรอให้ร้านค้าออกชิ้นส่วนที่ต้องการในส่วน \"ชิ้นส่วนที่ร้องขอ\" ด้านล่าง ไม่สามารถทำงานซ่อมให้เสร็จสิ้นโดยที่มีชิ้นส่วนที่รออยู่",
		"isAlreadyTranslated": true
	},
	"Waiting for customer approval of estimation or override estimation approval in 'Parts and Estimation' section below.":{
		"text":"กำลังรอการอนุมัติจากลูกค้าสำหรับใบประเมิน หรือข้ามการอนุมัติใบประเมินในส่วน \"ชิ้นส่วนและการประเมิน\" ด้านล่าง",
		"isAlreadyTranslated": true
	},
	"Waiting for store to issue required device in 'Dead on arrival' section below.":{
		"text":"กำลังรอให้ร้านค้าออกอุปกรณ์ที่ต้องการในส่วน \"เสียเมื่อมาถึง\" ด้านล่าง",
		"isAlreadyTranslated": true
	},
	"Waiting for the Engineer to receive the 'Replacement device' from store in 'Dead on Arrival' section below.":{
		"text":"กำลังรอวิศวกรมารับ \"ชิ้นส่วนสำหรับเปลี่ยน\" จากร้านค้าในส่วน \"เสียเมื่อมาถึง\" ด้านล่าง",
		"isAlreadyTranslated": true
	},
	"Waiting for Engineer to receive parts from store in 'Parts Requested' section below.":{
		"text":"กำลังรอวิศวกรมารับชิ้นส่วนจากร้านค้าในส่วน \"ชิ้นส่วนที่ร้องขอ\" ด้านล่าง",
		"isAlreadyTranslated": true
	},
	"Waiting for the Engineer to complete DOA by handing over the damaged device to Store":{
		"text":"กำลังรอวิศวกรดำเนินการกับ DOA ให้เสร็จสิ้นโดยส่งอุปกรณ์ที่เสียไปที่ร้านค้า",
		"isAlreadyTranslated": true
	},
	"Waiting for the engineer to handover device to Store":{
		"text":"กำลังรอวิศวกรส่งอุปกรณ์ไปที่ร้านค้า",
		"isAlreadyTranslated": true
	},
	"Waiting for the Store to handover device to Customer Care Executive":{
		"text":"กำลังรอให้ร้านค้าส่งอุปกรณ์ให้กับเจ้าหน้าที่บริการลูกค้า",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to complete the DOA":{
		"text":"กำลังรอเจ้าหน้าที่บริการลูกค้าดำเนินการ DOA ให้เสร็จสิ้น",
		"isAlreadyTranslated": true
	},
	"Waiting for the customer to rate the service":{
		"text":"กำลังรอลูกค้าให้คะแนนการบริการ",
		"isAlreadyTranslated": true
	},
	"Warranty Till":{
		"text":"รับประกันจนถึง",
		"isAlreadyTranslated": true
	},
	"We were unable to find details from GSX, please select product manually":{
		"text":"เราไม่สามารถค้นหารายละเอียดจาก GSX ได้ โปรดเลือกผลิตภัณฑ์ด้วยตัวเอง",
		"isAlreadyTranslated": true
	},
	"Yes, the device is whitelistied. Please upload screenshot of the same":{
		"text":"ใช่ อุปกรณ์เครื่องนี้อยู่ในบัญชีขาว โปรดอัปโหลดภาพถ่ายหน้าจอของ",
		"isAlreadyTranslated": true
	},
	"This fault code has already been selected":{
		"text":"รหัสความผิดปกตินี้ได้รับการเลือกไว้แล้ว",
		"isAlreadyTranslated": true
	},
	"Choose from dropdown list only":{
		"text":"เลือกจากรายการดรอปดาวน์เท่านั้น",
		"isAlreadyTranslated": true
	},
	"You have already added this issue":{
		"text":"คุณได้เพิ่มปัญหานี้ไว้แล้ว",
		"isAlreadyTranslated": true
	},
	"Please validate all the issues":{
		"text":"โปรดยืนยันความถูกต้องของปัญหาทั้งหมด",
		"isAlreadyTranslated": true
	},
	"Initiate AST 2 Diagnostics":{
		"text":"เริ่มต้นการวินิจฉัย AST 2",
		"isAlreadyTranslated": true
	},
	"Forward":{
		"text":"ส่งต่อ",
		"isAlreadyTranslated": true
	},
	"Reverse":{
		"text":"ย้อนกลับ",
		"isAlreadyTranslated": true
	},
	"Schedule a Logistic Request":{
		"text":"กำหนดเวลาคำร้องขอโลจิสติกส์",
		"isAlreadyTranslated": true
	},
	"Transfer Details":{
		"text":"รายละเอียดการโอน",
		"isAlreadyTranslated": true
	},
	"Cash":{
		"text":"เงินสด",
		"isAlreadyTranslated": true
	},
	"Card":{
		"text":"การ์ด",
		"isAlreadyTranslated": true
	},
	"Mode of payment":{
		"text":"ช่องทางการชำระเงิน",
		"isAlreadyTranslated": true
	},
	"Please select mode of payment":{
		"text":"โปรดเลือกช่องทางการชำระเงิน",
		"isAlreadyTranslated": true
	},
	"Device Transaction Details":{
		"text":"รายละเอียดธุรกรรมของอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Handing over the damaged device to store":{
		"text":"การส่งอุปกรณ์ที่เสียหายไปที่ร้านค้า",
		"isAlreadyTranslated": true
	},
	"Damaged IMEI":{
		"text":"IMEI ที่เสียหาย",
		"isAlreadyTranslated": true
	},
	"Details of the New Replaced Device":{
		"text":"รายละเอียดของอุปกรณ์ที่เปลี่ยนใหม่",
		"isAlreadyTranslated": true
	},
	"Damaged Serial No":{
		"text":"เลขประจำเครื่องที่เสียหาย",
		"isAlreadyTranslated": true
	},
	"Replaced Serial No":{
		"text":"เลขประจำเครื่องที่เปลี่ยน",
		"isAlreadyTranslated": true
	},
	"Damaged Part Imei":{
		"text":"Imei ชิ้นส่วนที่เสียหาย",
		"isAlreadyTranslated": true
	},
	"Replaced Part Imei":{
		"text":"Imei ชิ้นส่วนที่เปลี่ยน",
		"isAlreadyTranslated": true
	},
	"Parts transaction details":{
		"text":"รายละเอียดธุรกรรมของชิ้นส่วน",
		"isAlreadyTranslated": true
	},
	"Handing over the parts to store":{
		"text":"กำลังส่งชิ้นส่วนไปที่ร้านค้า",
		"isAlreadyTranslated": true
	},
	"Enter Call Details":{
		"text":"ป้อนรายละเอียดการโทร",
		"isAlreadyTranslated": true
	},
	"Connect":{
		"text":"เชื่อมต่อ",
		"isAlreadyTranslated": true
	},
	"Name of the caller":{
		"text":"ชื่อของผู้โทร",
		"isAlreadyTranslated": true
	},
	"Connect Disposition":{
		"text":"เชื่อมต่อการจัดการ",
		"isAlreadyTranslated": true
	},
	"Non-Connect Disposition":{
		"text":"ไม่เชื่อมต่อการจัดการ",
		"isAlreadyTranslated": true
	},
	"Description of loss/damage":{
		"text":"รายละเอียดการสูญหาย/ความเสียหาย",
		"isAlreadyTranslated": true
	},
	"Date and Time of loss/damage":{
		"text":"วันที่และเวลาที่เกิดการสูญหาย/ความเสียหาย",
		"isAlreadyTranslated": true
	},
	"Checklist":{
		"text":"รายการตรวจสอบ",
		"isAlreadyTranslated": true
	},
	"Please select Repair Type":{
		"text":"โปรดเลือกประเภทการซ่อม",
		"isAlreadyTranslated": true
	},
	"Please select Classification Type":{
		"text":"โปรดเลือกประเภทการจำแนก",
		"isAlreadyTranslated": true
	},
	"Please select Coverage Option":{
		"text":"โปรดเลือกประเภทตัวเลือกการครอบคลุม",
		"isAlreadyTranslated": true
	},
	"Repair Checklist":{
		"text":"รายการตรวจสอบการซ่อม",
		"isAlreadyTranslated": true
	},
	"Diagnostics Result":{
		"text":"ผลการวินิจฉัย",
		"isAlreadyTranslated": true
	},
	"Not Applicable":{
		"text":"ไม่สามารถปรับใช้ได้",
		"isAlreadyTranslated": true
	},
	"Miscellaneous Checklist":{
		"text":"รายการตรวจสอบอื่นๆ",
		"isAlreadyTranslated": true
	},
	"Accessories Submitted":{
		"text":"ส่งอุปกรณ์เสริมแล้ว",
		"isAlreadyTranslated": true
	},
	"Complete Checklist":{
		"text":"ทำรายการตรวจสอบให้เสร็จสิ้น",
		"isAlreadyTranslated": true
	},
	"No pictures added":{
		"text":"ไม่มีรูปภาพที่เพิ่ม",
		"isAlreadyTranslated": true
	},
	"Upload Device Pictures and Complete Checklist":{
		"text":"อัปโหลดรูปภาพอุปกรณ์และทำรายการตรวจสอบให้เสร็จสิ้น",
		"isAlreadyTranslated": true
	},
	"Upload Device Pictures":{
		"text":"อัปโหลดรูปภาพอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Device Name":{
		"text":"ชื่ออุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Select OS Version":{
		"text":"เลือกเวอร์ชั่นของ OS",
		"isAlreadyTranslated": true
	},
	"Select Picture Type":{
		"text":"เลือกประเภทรูปภาพ",
		"isAlreadyTranslated": true
	},
	"No, the device is not whitelisted. Please cancel the request and return the device if you have it":{
		"text":"ไม่ใช่ อุปกรณ์เครื่องนี้ไม่ได้อยู่ในบัญชีขาว โปรดยกเลิกคำร้องขอและส่งอุปกรณ์กลับคืนถ้าอุปกรณ์อยู่ที่คุณ",
		"isAlreadyTranslated": true
	},
	"Yes, the device is whitelisted. Please upload screenshot of the same":{
		"text":"ใช่ อุปกรณ์เครื่องนี้อยู่ในบัญชีขาว โปรดอัปโหลดภาพถ่ายหน้าจอของ",
		"isAlreadyTranslated": true
	},
	"Device handover details":{
		"text":"รายละเอียดการส่งมอบอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Diagnosis details":{
		"text":"รายละเอียดการวินิจฉัย",
		"isAlreadyTranslated": true
	},
	"Diagnosis Steps":{
		"text":"ขั้นตอนการวินิจฉัย",
		"isAlreadyTranslated": true
	},
	"Engineer notes":{
		"text":"โน้ตของวิศวกร",
		"isAlreadyTranslated": true
	},
	"Front Image":{
		"text":"รูปภาพด้านหน้า",
		"isAlreadyTranslated": true
	},
	"Back Image":{
		"text":"ภาพด้านหลัง",
		"isAlreadyTranslated": true
	},
	"Top Image":{
		"text":"รูปภาพด้านบน",
		"isAlreadyTranslated": true
	},
	"Bottom Image":{
		"text":"รูปภาพด้านล่าง",
		"isAlreadyTranslated": true
	},
	"Left Image":{
		"text":"รูปภาพด้านซ้าย",
		"isAlreadyTranslated": true
	},
	"Right Image":{
		"text":"รูปภาพด้านขวา",
		"isAlreadyTranslated": true
	},
	"Customer ID":{
		"text":"ID ลูกค้า",
		"isAlreadyTranslated": true
	},
	"Device Invoice":{
		"text":"ใบกำกับสินค้าของอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Please select a repair type":{
		"text":"โปรดเลือกประเภทการซ่อม",
		"isAlreadyTranslated": true
	},
	"Please enter $description":{
		"text":"โปรดป้อน$คำอธิบาย",
		"isAlreadyTranslated": true
	},
	"Your GSX Request has been created":{
		"text":"สร้างคำร้องขอ GSX ของคุณแล้ว",
		"isAlreadyTranslated": true
	},
	"Next":{
		"text":"ถัดไป",
		"isAlreadyTranslated": true
	},
	"Service initiated":{
		"text":"เริ่มการบริการแล้ว",
		"isAlreadyTranslated": true
	},
	"Recycle Dropoff":{
		"text":"การส่งอุปกรณ์เพื่อรีไซเคิล",
		"isAlreadyTranslated": true
	},
	"ELS":{
		"text":"ELS",
		"isAlreadyTranslated": true
	},
	"QC":{
		"text":"QC",
		"isAlreadyTranslated": true
	},
	"DOA":{
		"text":"DOA",
		"isAlreadyTranslated": true
	},
	"Inspection Completed, Awaiting Engineer Assignment":{
		"text":"การตรวจสอบเสร็จสิ้นแล้ว กำลังรอการมอบหมายวิศวกร",
		"isAlreadyTranslated": true
	},
	"sdv":{
		"text":"sdv",
		"isAlreadyTranslated": true
	},
	"Repair has been cancelled.":{
		"text":"การซ่อมถูกยกเลิกแล้ว",
		"isAlreadyTranslated": true
	},
	"BTB socket":{
		"text":"ซอคเก็ต BTB",
		"isAlreadyTranslated": true
	},
	"SIM Card needle":{
		"text":"เข็มสำหรับซิมการ์ด",
		"isAlreadyTranslated": true
	},
	"SIM card holder EB101 grey aluminum alloy":{
		"text":"ที่ใส่ซิมการ์ด EB101 อลูมิเนียมอัลลอยสีเทา",
		"isAlreadyTranslated": true
	},
	"Pending for Acceptance by Engineer":{
		"text":"รอการตอบรับจากช่างเทคนิค",
		"isAlreadyTranslated": true
	},
	"microphone":{
		"text":"ไมโครโฟน",
		"isAlreadyTranslated": true
	},
	"for Token generated":{
		"text":"สำหรับโทเค็นที่สร้างขึ้น",
		"isAlreadyTranslated": true
	},
	"Advance collected":{
		"text":"รับอุปกรณ์ล่วงหน้าแล้ว",
		"isAlreadyTranslated": true
	},
	"pin added":{
		"text":"เพิ่มรหัส PIN แล้ว",
		"isAlreadyTranslated": true
	},
	"Trade In Dropoff":{
		"text":"การส่งอุปกรณ์เพื่อการค้าแลกเปลี่ยน",
		"isAlreadyTranslated": true
	},
	"Your GSX Mail-in Request has been created. Request Added to Queue for dispatch":{
		"text":"สร้างคำร้องขอการโต้ตอบทางเมลสำหรับ GSX ของคุณแล้ว เพิ่มคำร้องขอไปยังคิวสำหรับการส่งแล้ว",
		"isAlreadyTranslated": true
	},
	"This device is not eligible for $service":{
		"text":"อุปกรณ์เครื่องนี้มีคุณสมบัติไม่เข้าเกณฑ์สำหรับ$การบริการ",
		"isAlreadyTranslated": true
	},
	"This device is eligible for $service":{
		"text":"อุปกรณ์เครื่องนี้มีคุณสมบัติเข้าเกณฑ์สำหรับ$การบริการ",
		"isAlreadyTranslated": true
	},
	"Advance payment":{
		"text":"การชำระเงินล่วงหน้า",
		"isAlreadyTranslated": true
	},
	"Cannot be diagnosed":{
		"text":"ไม่สามารถวินิจฉัยได้",
		"isAlreadyTranslated": true
	},
	"Device received":{
		"text":"ได้รับอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"Pending for acceptance":{
		"text":"กำลังรอการตอบรับ",
		"isAlreadyTranslated": true
	},
	"Estimation generated":{
		"text":"สร้างใบประเมินแล้ว",
		"isAlreadyTranslated": true
	},
	"Estimation Approval":{
		"text":"การอนุมัติใบประเมิน",
		"isAlreadyTranslated": true
	},
	"Estimation rejected":{
		"text":"ใบประเมินถูกปฏิเสธ",
		"isAlreadyTranslated": true
	},
	"Parts request accepted":{
		"text":"คำร้องขอชิ้นส่วนได้รับการตอบรับแล้ว",
		"isAlreadyTranslated": true
	},
	"Parts issued":{
		"text":"ชิ้นส่วนออกแล้ว",
		"isAlreadyTranslated": true
	},
	"QC pending":{
		"text":"รอการตรวจสอบ QC อยู่",
		"isAlreadyTranslated": true
	},
	"Repair completed":{
		"text":"การซ่อมเสร็จสิ้นแล้ว",
		"isAlreadyTranslated": true
	},
	"Intimate customer":{
		"text":"แจ้งลูกค้า",
		"isAlreadyTranslated": true
	},
	"Repair cancel":{
		"text":"การยกเลิกการซ่อม",
		"isAlreadyTranslated": true
	},
	"Transfer request":{
		"text":"คำร้องขอการโอน",
		"isAlreadyTranslated": true
	},
	"Device details validated":{
		"text":"ยืนยันความถูกต้องของรายละเอียดอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"Fetch Diagnosis Suites":{
		"text":"ดึงข้อมูลชุดการวินิจฉัย",
		"isAlreadyTranslated": true
	},
	"Do you want to return the damaged parts to the customer":{
		"text":"คุณต้องการให้ส่งชิ้นส่วนที่เสียหายคืนให้กับลูกค้าหรือไม่",
		"isAlreadyTranslated": true
	},
	"Please enter the reason for call":{
		"text":"โปรดป้อนเหตุผลของการโทร",
		"isAlreadyTranslated": true
	},
	"Please enter Disposition reason":{
		"text":"โปรดป้อนเหตุผลของการจัดการ",
		"isAlreadyTranslated": true
	},
	"Please enter your name":{
		"text":"โปรดป้อนชื่อของคุณ",
		"isAlreadyTranslated": true
	},
	"Please specify remarks, if any":{
		"text":"โปรดระบุหมายเหตุ (ถ้ามี)",
		"isAlreadyTranslated": true
	},
	"Please enter the remarks":{
		"text":"โปรดป้อนหมายเหตุ",
		"isAlreadyTranslated": true
	},
	"Return without repair (RWR)":{
		"text":"ส่งคืนโดยไม่ต้องซ่อม (RWR)",
		"isAlreadyTranslated": true
	},
	"Cannot proceed with this action without providing a reason":{
		"text":"ไม่สามารถดำเนินการการกระทำนี้ต่อไปโดยไม่ระบุเหตุผล",
		"isAlreadyTranslated": true
	},
	"This is a mandatory field":{
		"text":"นี่เป็นฟิลด์ที่จำเป็นต้องป้อนข้อมูล",
		"isAlreadyTranslated": true
	},
	"Diagnosis steps has to be from 100 to 1500 characters":{
		"text":"ขั้นตอนการวินิจฉัยต้องมีอักขระตั้งแต่ 100 ถึง 1500 ตัว",
		"isAlreadyTranslated": true
	},
	"Engineer Notes has to be from 100 to 800 characters":{
		"text":"โน้ตของวิศวกรต้องมีอักขระตั้งแต่ 100 ถึง 800 ตัว",
		"isAlreadyTranslated": true
	},
	"Please select a picture type from dropdown":{
		"text":"โปรดเลือกประเภทรูปภาพจากรายการดรอปดาวน์",
		"isAlreadyTranslated": true
	},
	"Please add a picture":{
		"text":"โปรดเพิ่มรูปภาพ",
		"isAlreadyTranslated": true
	},
	"You have not chosen any option to handover":{
		"text":"คุณยังไม่ได้เลือกตัวเลือกในการส่ง",
		"isAlreadyTranslated": true
	},
	"Jan":{
		"text":"ม.ค.",
		"isAlreadyTranslated": true
	},
	"Feb":{
		"text":"ก.พ.",
		"isAlreadyTranslated": true
	},
	"Mar":{
		"text":"มี.ค.",
		"isAlreadyTranslated": true
	},
	"Apr":{
		"text":"เม.ย.",
		"isAlreadyTranslated": true
	},
	"May":{
		"text":"พฤษภาคม",
		"isAlreadyTranslated": true
	},
	"Jun":{
		"text":"มิ.ย.",
		"isAlreadyTranslated": true
	},
	"Jul":{
		"text":"ก.ค.",
		"isAlreadyTranslated": true
	},
	"Aug":{
		"text":"ส.ค.",
		"isAlreadyTranslated": true
	},
	"Sep":{
		"text":"ก.ย.",
		"isAlreadyTranslated": true
	},
	"Oct":{
		"text":"ต.ค.",
		"isAlreadyTranslated": true
	},
	"Nov":{
		"text":"พ.ย.",
		"isAlreadyTranslated": true
	},
	"Dec":{
		"text":"ธ.ค.",
		"isAlreadyTranslated": true
	},
	"AM":{
		"text":"AM",
		"isAlreadyTranslated": true
	},
	"PM":{
		"text":"PM",
		"isAlreadyTranslated": true
	},
	"Pick Up":{
		"text":"การรับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Carry in":{
		"text":"การนำอุปกรณ์เข้ามาเอง",
		"isAlreadyTranslated": true
	},
	"On-site":{
		"text":"บริการนอกสถานที่",
		"isAlreadyTranslated": true
	},
	"Buy Back":{
		"text":"ซื้อคืน",
		"isAlreadyTranslated": true
	},
	"Installation":{
		"text":"การติดตั้ง",
		"isAlreadyTranslated": true
	},
	"Demo":{
		"text":"การสาธิต",
		"isAlreadyTranslated": true
	},
	"Servicing":{
		"text":"การบริการ",
		"isAlreadyTranslated": true
	},
	"Delivery":{
		"text":"การจัดส่ง",
		"isAlreadyTranslated": true
	},
	"Recycle Pickup":{
		"text":"การรับอุปกรณ์เพื่อรีไซเคิล",
		"isAlreadyTranslated": true
	},
	"Claim":{
		"text":"การเคลม",
		"isAlreadyTranslated": true
	},
	"Claim Pickup":{
		"text":"การรับอุปกรณ์เพื่อเคลม",
		"isAlreadyTranslated": true
	},
	"Claim Carryin":{
		"text":"การนำอุปกรณ์เข้ามาเองเพื่อเคลม",
		"isAlreadyTranslated": true
	},
	"Recycle Dropoff":{
		"text":"การส่งอุปกรณ์เพื่อรีไซเคิล",
		"isAlreadyTranslated": true
	},
	"Beyond Economic Repair":{
		"text":"การซ่อมที่อยู่นอกเหนือการรับประกัน",
		"isAlreadyTranslated": true
	},
	"BER Refund":{
		"text":"การชำระคืน BER",
		"isAlreadyTranslated": true
	},
	"BER Repair":{
		"text":"การซ่อม BER",
		"isAlreadyTranslated": true
	},
	"Dropoff":{
		"text":"การส่งอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Enquiry":{
		"text":"ข้อสงสัย",
		"isAlreadyTranslated": true
	},
	"Field Repair WO":{
		"text":"WO การซ่อมนอกสถานที่",
		"isAlreadyTranslated": true
	},
	"Upgrade WO":{
		"text":"WO การอัปเกรด",
		"isAlreadyTranslated": true
	},
	"Install WO":{
		"text":"WO การติดตั้ง",
		"isAlreadyTranslated": true
	},
	"Relocation":{
		"text":"การกำหนดตำแหน่งใหม่",
		"isAlreadyTranslated": true
	},
	"Extended Pick Up":{
		"text":"Extended Pick Up",
		"isAlreadyTranslated": true
	},
	"Extended Carry in":{
		"text":"Extended Carry in",
		"isAlreadyTranslated": true
	},
	"Trade In Pickup":{
		"text":"การรับอุปกรณ์เพื่อการค้าแลกเปลี่ยน",
		"isAlreadyTranslated": true
	},
	"Trade In Dropoff":{
		"text":"การส่งอุปกรณ์เพื่อการค้าแลกเปลี่ยน",
		"isAlreadyTranslated": true
	},
	"Refund":{
		"text":"คืนเงิน",
		"isAlreadyTranslated": true
	},
	"Ear Pods":{
		"text":"หูฟัง",
		"isAlreadyTranslated": true
	},
	"Power Adapter":{
		"text":"อะแดปเตอร์จ่ายไฟ",
		"isAlreadyTranslated": true
	},
	"Lightning to USB Cable":{
		"text":"Lightning ไปยังสาย USB",
		"isAlreadyTranslated": true
	},
	"Display Damaged":{
		"text":"จอแสดงผลเสียหาย",
		"isAlreadyTranslated": true
	},
	"Battery Damaged":{
		"text":"แบตเตอรี่เสียหาย",
		"isAlreadyTranslated": true
	},
	"Backup Taken":{
		"text":"ทำการสำรองข้อมูลแล้ว",
		"isAlreadyTranslated": true
	},
	"Find my iPhone Active":{
		"text":"ค้นหา iPhone ของฉัน ทำงานอยู่",
		"isAlreadyTranslated": true
	},
	"Your @ request @ is registered. We will contact you for an appointment. For assistance, please call us on 1800-108-1333.":{
		"text":"ลงทะเบียนคำร้องขอ @ ของคุณ @ แล้ว เราจะติดต่อคุณเพื่อทำการนัดหมาย หากต้องการสอบถาม โปรดโทรหาเราที่ 1800-108-1333",
		"isAlreadyTranslated": true
	},
	"@ | Your request has lapsed due to non submission of the documents.":{
		"text":"@ | คำร้องขอของคุณมีข้อผิดพลาดเนื่องจากไม่พบว่ามีการส่งเอกสาร",
		"isAlreadyTranslated": true
	},
	"We have received your claim request. Our representative will connect with you in next 48 hours.":{
		"text":"เราได้รับคำร้องขอการเคลมของคุณแล้ว พนักงานตัวแทนของเราจะเชื่อมต่อกับคุณในอีก 48 ชั่วโมงข้างหน้า",
		"isAlreadyTranslated": true
	},
	"@ Part(s) Received by Engineer.":{
		"text":"ชิ้นส่วน @ ที่รับโดยวิศวกร",
		"isAlreadyTranslated": true
	},
	"Print":{
		"text":"พิมพ์",
		"isAlreadyTranslated": true
	},
	"Pupa Service Record":{
		"text":"ระเบียนการบริการ Pupa",
		"isAlreadyTranslated": true
	},
	"Service Center":{
		"text":"ศูนย์บริการ",
		"isAlreadyTranslated": true
	},
	"No":{
		"text":"ไม่ใช่",
		"isAlreadyTranslated": true
	},
	"User Name":{
		"text":"ชื่อผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Telephone":{
		"text":"โทรศัพท์",
		"isAlreadyTranslated": true
	},
	"Mobile":{
		"text":"มือถือ",
		"isAlreadyTranslated": true
	},
	"Product Model":{
		"text":"รุ่นผลิตภัณฑ์",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial Number":{
		"text":"IMEI/เลขประจำเครื่อง",
		"isAlreadyTranslated": true
	},
	"Purchase Date":{
		"text":"วันที่ซื้อ",
		"isAlreadyTranslated": true
	},
	"Carry-in Time":{
		"text":"เวลาที่นำอุปกรณ์เข้ามา",
		"isAlreadyTranslated": true
	},
	"NA":{
		"text":"ไม่มี",
		"isAlreadyTranslated": true
	},
	"Key Account Name":{
		"text":"ชื่อบัญชีหลัก",
		"isAlreadyTranslated": true
	},
	"Service Offering":{
		"text":"ข้อเสนอการบริการ",
		"isAlreadyTranslated": true
	},
	"In warranty":{
		"text":"อยู่ในการรับประกัน",
		"isAlreadyTranslated": true
	},
	"Out of warranty":{
		"text":"ไม่อยู่ในการรับประกัน",
		"isAlreadyTranslated": true
	},
	"Problem Description by User":{
		"text":"คำอธิบายปัญหาโดยผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Recover Method":{
		"text":"วิธีการกู้คืน",
		"isAlreadyTranslated": true
	},
	"Parts replacement":{
		"text":"การเปลี่ยนชิ้นส่วน",
		"isAlreadyTranslated": true
	},
	"Service":{
		"text":"บริการ",
		"isAlreadyTranslated": true
	},
	"New IMEI/SN":{
		"text":"IMEI/SN ใหม่",
		"isAlreadyTranslated": true
	},
	"Defective Model Name":{
		"text":"ชื่อรุ่นที่เกิดข้อบกพร่อง",
		"isAlreadyTranslated": true
	},
	"Model Number":{
		"text":"หมายเลขรุ่น",
		"isAlreadyTranslated": true
	},
	"Replacement Name":{
		"text":"ชื่อการเปลี่ยน",
		"isAlreadyTranslated": true
	},
	"Model Number":{
		"text":"หมายเลขรุ่น",
		"isAlreadyTranslated": true
	},
	"Engineer Signature":{
		"text":"ลายเซ็นของวิศวกร",
		"isAlreadyTranslated": true
	},
	"Contact No":{
		"text":"หมายเลขผู้ติดต่อ",
		"isAlreadyTranslated": true
	},
	"Finish Date":{
		"text":"วันที่เสร็จ",
		"isAlreadyTranslated": true
	},
	"Are you satisfied with Pupa service experience":{
		"text":"คุณพึงพอใจกับประสบการณ์การบริการของ Pupa หรือไม่",
		"isAlreadyTranslated": true
	},
	"Very Satisfied":{
		"text":"พึงพอใจมาก",
		"isAlreadyTranslated": true
	},
	"Satisfied":{
		"text":"พึงพอใจ",
		"isAlreadyTranslated": true
	},
	"Neutral":{
		"text":"เฉยๆ",
		"isAlreadyTranslated": true
	},
	"Dissatisfied":{
		"text":"ไม่พอใจ",
		"isAlreadyTranslated": true
	},
	"Very Dissatisfied":{
		"text":"ไม่พอใจอย่างมาก",
		"isAlreadyTranslated": true
	},
	"Please comment if you are dissatisfied":{
		"text":"โปรดให้ความเห็นกรณีที่คุณไม่พอใจ",
		"isAlreadyTranslated": true
	},
	"User Signature":{
		"text":"ลายเซ็นของผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Date":{
		"text":"วันที่",
		"isAlreadyTranslated": true
	},
	"Thank you for choosing Pupa":{
		"text":"ขอบคุณที่เลือกใช้บริการ Pupa",
		"isAlreadyTranslated": true
	},
	"Operations team will reach out to you for further action.":{
		"text":"ทีมปฏิบัติการจะไปหาคุณเพื่อดำเนินการต่อไป",
		"isAlreadyTranslated": true
	},
	"Protection Status":{
		"text":"สถานะการป้องกัน",
		"isAlreadyTranslated": true
	},
	"Warranty Status":{
		"text":"สถานะการรับประกัน",
		"isAlreadyTranslated": true
	},
	"valid till":{
		"text":"มีผลจนถึง",
		"isAlreadyTranslated": true
	},
	"Manufacturer Warranty":{
		"text":"การรับประกันของผู้ผลิต",
		"isAlreadyTranslated": true
	},
	"Secure Plan Warranty":{
		"text":"การรับประกันตามแผนด้านความปลอดภัย",
		"isAlreadyTranslated": true
	},
	"covered under":{
		"text":"ครอบคลุมภายใต้",
		"isAlreadyTranslated": true
	},
	"Send for raising request":{
		"text":"ส่งเพื่อยื่นเรื่องคำร้องขอ",
		"isAlreadyTranslated": true
	},
	"Enter Issue Decsription":{
		"text":"ป้อนคำอธิบายปัญหา",
		"isAlreadyTranslated": true
	},
	"Home":{
		"text":"บ้าน",
		"isAlreadyTranslated": true
	},
	"office":{
		"text":"ออฟฟิต",
		"isAlreadyTranslated": true
	},
	"other":{
		"text":"อื่นๆ",
		"isAlreadyTranslated": true
	},
	"Enter a location":{
		"text":"ป้อนตำแหน่งที่ตั้ง",
		"isAlreadyTranslated": true
	},
	"Accept":{
		"text":"ยอมรับ",
		"isAlreadyTranslated": true
	},
	"Reject":{
		"text":"ปฏิเสธ",
		"isAlreadyTranslated": true
	},
	"Attempt Call":{
		"text":"พยายามโทร",
		"isAlreadyTranslated": true
	},
	"Warranty Concession":{
		"text":"การยินยอมการรับประกัน",
		"isAlreadyTranslated": true
	},
	"No order lines found for given request":{
		"text":"ไม่พบบรรทัดคำสั่งสำหรับคำร้องขอที่ให้มา",
		"isAlreadyTranslated": true
	},
	"Valid":{
		"text":"ใช่",
		"isAlreadyTranslated": true
	},
	"Invalid":{
		"text":"ไม่ใช่",
		"isAlreadyTranslated": true
	},
	"Toggle Warranty":{
		"text":"เปลี่ยนการรับประกัน",
		"isAlreadyTranslated": true
	},
	"Please specify the reason":{
		"text":"โปรดระบุเหตุผล",
		"isAlreadyTranslated": true
	},
	"Provide link for customer":{
		"text":"ให้ลิงก์สำหรับลูกค้า",
		"isAlreadyTranslated": true
	},
	"Accept or Reject Request":{
		"text":"ตอบรับหรือปฏิเสธคำร้องขอ",
		"isAlreadyTranslated": true
	},
	"Inward":{
		"text":"เข้าด้านใน",
		"isAlreadyTranslated": true
	},
	"Outward":{
		"text":"ออกด้านนอก",
		"isAlreadyTranslated": true
	},
	"Max number of retry attempts exceeded":{
		"text":"เกินจำนวนครั้งสูงสุดของการพยายามลองใหม่แล้ว",
		"isAlreadyTranslated": true
	},
	"Product Unique ID":{
		"text":"ID ที่ไม่ซ้ำของผลิตภัณฑ์",
		"isAlreadyTranslated": true
	},
	"Where was the device kept at the time of incident":{
		"text":"อุปกรณ์เก็บอยู่ที่ใดขณะที่เกิดเหตุ",
		"isAlreadyTranslated": true
	},
	"Please Enter Incident Place of Damage":{
		"text":"โปรดป้อนสถานที่ของเหตุการณ์ที่เกิดความเสียหาย",
		"isAlreadyTranslated": true
	},
	"Min 100 Characters":{
		"text":"จำนวนอักขระต่ำสุด 100 ตัว",
		"isAlreadyTranslated": true
	},
	"LEFT":{
		"text":"ซ้าย",
		"isAlreadyTranslated": true
	},
	"Please Enter Minimum 100 Characters":{
		"text":"โปรดป้อนอักขระต่ำสุด 100 ตัว",
		"isAlreadyTranslated": true
	},
	"Raise a request":{
		"text":"สร้างใบรับงาน",
		"isAlreadyTranslated": true
	},
	"Request Scheduled for":{
		"text":"กำหนดเวลาคำร้องขอแล้วสำหรับ",
		"isAlreadyTranslated": true
	},
	"Action Status":{
		"text":"สถานะการดำเนินการ",
		"isAlreadyTranslated": true
	},
	"Action taken":{
		"text":"ได้ดำเนินการแล้ว",
		"isAlreadyTranslated": true
	},
	"Current Log":{
		"text":"บันทึกปัจจุบัน",
		"isAlreadyTranslated": true
	},
	"Handover Device":{
		"text":"ส่งอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Attempt Call":{
		"text":"พยายามโทร",
		"isAlreadyTranslated": true
	},
	"Request Documents":{
		"text":"เอกสารคำร้องขอ",
		"isAlreadyTranslated": true
	},
	"Issues Reported":{
		"text":"ปัญหาที่ได้รับแจ้ง",
		"isAlreadyTranslated": true
	},
	"Parts and Estimation":{
		"text":"การประเมินราคาอะไหล่",
		"isAlreadyTranslated": true
	},
	"Engineer validated issues":{
		"text":"ปัญหาที่ยืนยันความถูกต้องจากวิศวกรแล้ว",
		"isAlreadyTranslated": true
	},
	"Other Requests":{
		"text":"คำร้องขออื่นๆ",
		"isAlreadyTranslated": true
	},
	"Request Details":{
		"text":"รายละเอียดใบรับงาน",
		"isAlreadyTranslated": true
	},
	"Connect":{
		"text":"เชื่อมต่อ",
		"isAlreadyTranslated": true
	},
	"Connect Disposition:":{
		"text":"เชื่อมต่อการจัดการ",
		"isAlreadyTranslated": true
	},
	"Non-Connect Disposition:":{
		"text":"ไม่เชื่อมต่อการจัดการ",
		"isAlreadyTranslated": true
	},
	"Front Image":{
		"text":"รูปภาพด้านหน้า",
		"isAlreadyTranslated": true
	},
	"Back Image":{
		"text":"ภาพด้านหลัง",
		"isAlreadyTranslated": true
	},
	"Top Image":{
		"text":"รูปภาพด้านบน",
		"isAlreadyTranslated": true
	},
	"Bottom Image":{
		"text":"รูปภาพด้านล่าง",
		"isAlreadyTranslated": true
	},
	"Left Image":{
		"text":"รูปภาพด้านซ้าย",
		"isAlreadyTranslated": true
	},
	"Right Image":{
		"text":"รูปภาพด้านขวา",
		"isAlreadyTranslated": true
	},
	"Device Invoice":{
		"text":"ใบกำกับสินค้าของอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Cosmetic Status of Device":{
		"text":"สถานะการแต่งเติมของอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Latest diagnostics result":{
		"text":"ผลการวินิจฉัยล่าสุด",
		"isAlreadyTranslated": true
	},
	"Purchase Order Number":{
		"text":"หมายเลขคำสั่งซื้อ",
		"isAlreadyTranslated": true
	},
	"Service Ticket No":{
		"text":"หมายเลขตั๋วการบริการ",
		"isAlreadyTranslated": true
	},
	"National ID":{
		"text":"หมายเลขบัตรประชาชน",
		"isAlreadyTranslated": true
	},
	"Select a Consumer Product":{
		"text":"เลือกผลิตภัณฑ์ของลูกค้า",
		"isAlreadyTranslated": true
	},
	"products under service":{
		"text":"ผลิตภัณฑ์ภายใต้การบริการ",
		"isAlreadyTranslated": true
	},
	"We were unable to find details from GSX, please select product manually":{
		"text":"เราไม่สามารถค้นหารายละเอียดจาก GSX ได้ โปรดเลือกผลิตภัณฑ์ด้วยตัวเอง",
		"isAlreadyTranslated": true
	},
	"Device Whitelisting Information":{
		"text":"ข้อมูลบัญชีขาวของอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"No, the device is not whitelisted. Please return the device if you have it":{
		"text":"ไม่ใช่ อุปกรณ์เครื่องนี้ไม่ได้อยู่ในบัญชีขาว โปรดส่งอุปกรณ์คืนถ้าอุปกรณ์อยู่ที่คุณ",
		"isAlreadyTranslated": true
	},
	"Plan Sales Report":{
		"text":"รายงานแผนการขาย",
		"isAlreadyTranslated": true
	},
	"APR Plans":{
		"text":"การวางแผน APR",
		"isAlreadyTranslated": true
	},
	"Insufficient Data For Graph. Data for":{
		"text":"ข้อมูลในตารางไม่ครบ",
		"isAlreadyTranslated": true
	},
	"Incident ID/External ReferenceID":{
		"text":"รหัสเหตุการณ์/รหัสอ้างอิงภายนอก",
		"isAlreadyTranslated": true
	},
	"Dispatch":{
		"text":"การจัดการ",
		"isAlreadyTranslated": true
	},
	"Mail IN":{
		"text":"กล่องจดหมายขาเข้า",
		"isAlreadyTranslated": true
	},
	"Devices":{
		"text":"อุปกรณ์",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial No":{
		"text":"IMEI/Serial No",
		"isAlreadyTranslated": true
	},
	"18/11/23":{
		"text":"18/11/23",
		"isAlreadyTranslated": true
	},
	"Download all reports":{
		"text":"ดาวน์โหลดรายงานทั้งหมด",
		"isAlreadyTranslated": true
	},
	"Claim Request":{
		"text":"คำร้องขอการเคลม",
		"isAlreadyTranslated": true
	},
	"Open":{
		"text":"เปิด",
		"isAlreadyTranslated": true
	},
	"In Progress":{
		"text":"อยู่ระหว่างการดำเนินการ",
		"isAlreadyTranslated": true
	},
	"Closed":{
		"text":"ปิด",
		"isAlreadyTranslated": true
	},
	"Cancelled":{
		"text":"ยกเลิก",
		"isAlreadyTranslated": true
	},
	"is":{
		"text":"ใช่",
		"isAlreadyTranslated": true
	},
	"No Data Available To Plot The Graph":{
		"text":"ไม่มีข้อมูลสำหรับการสร้างแผนภูมิ",
		"isAlreadyTranslated": true
	},
	"Consumer Name":{
		"text":"ชื่อลูกค้า",
		"isAlreadyTranslated": true
	},
	"Brand":{
		"text":"ยี้ห้อ",
		"isAlreadyTranslated": true
	},
	"Subcategory":{
		"text":"ประเภทย่อย",
		"isAlreadyTranslated": true
	},
	"Product":{
		"text":"สินค้า",
		"isAlreadyTranslated": true
	},
	"IMEI":{
		"text":"IMEI",
		"isAlreadyTranslated": true
	},
	"Serial No":{
		"text":"Serial No",
		"isAlreadyTranslated": true
	},
	"Service Type":{
		"text":"ประเภทการให้บริการ",
		"isAlreadyTranslated": true
	},
	"Status":{
		"text":"สถานะ",
		"isAlreadyTranslated": true
	},
	"Logistics Provider":{
		"text":"ผู้ให้บริการขนส่ง",
		"isAlreadyTranslated": true
	},
	"Registration Source":{
		"text":"แหล่งลงทะเบียน",
		"isAlreadyTranslated": true
	},
	"Customer City":{
		"text":"ที่อยู่ลูกค้า",
		"isAlreadyTranslated": true
	},
	"Zipcode":{
		"text":"รหัสไปรษณีย์",
		"isAlreadyTranslated": true
	},
	"Call Type":{
		"text":"ประเภทการโทร",
		"isAlreadyTranslated": true
	},
	"GSX Confirmation Number":{
		"text":"หมายเลขยืนยัน GSX",
		"isAlreadyTranslated": true
	},
	"Clear":{
		"text":"ลบ",
		"isAlreadyTranslated": true
	},
	"Apply":{
		"text":"การใช้งาน",
		"isAlreadyTranslated": true
	},
	"Re-apply":{
		"text":"การใช้งานใหม่",
		"isAlreadyTranslated": true
	},
	"Filter Requests":{
		"text":"กรองคำร้องของ",
		"isAlreadyTranslated": true
	},
	"Create Bulk Request":{
		"text":"สร้างคำร้องขอเป็นกลุ่ม",
		"isAlreadyTranslated": true
	},
	"External Requests":{
		"text":"คำร้องขอจากภายนอก",
		"isAlreadyTranslated": true
	},
	"Period":{
		"text":"ระยะเวลา",
		"isAlreadyTranslated": true
	},
	"Data displayed":{
		"text":"แสดงข้อมูล",
		"isAlreadyTranslated": true
	},
	"till":{
		"text":"ไปยัง",
		"isAlreadyTranslated": true
	},
	"Loading requests":{
		"text":"กำลังโหลดคำร้อง",
		"isAlreadyTranslated": true
	},
	"No Data Available":{
		"text":"ไม่มีข้อมูลการใช้งาน",
		"isAlreadyTranslated": true
	},
	"Customer":{
		"text":"ลูกค้า",
		"isAlreadyTranslated": true
	},
	"Device":{
		"text":"อุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Schedule":{
		"text":"ตารางเวลา",
		"isAlreadyTranslated": true
	},
	"Model":{
		"text":"รุ่น",
		"isAlreadyTranslated": true
	},
	"Created":{
		"text":"สร้าง",
		"isAlreadyTranslated": true
	},
	"out of":{
		"text":"ออกจาก",
		"isAlreadyTranslated": true
	},
	"service requests are shown above.":{
		"text":"คำร้องการบริการแสดงอยู่ด้านบน",
		"isAlreadyTranslated": true
	},
	"First":{
		"text":"ก่อน",
		"isAlreadyTranslated": true
	},
	"Last":{
		"text":"หลัง",
		"isAlreadyTranslated": true
	},
	"to":{
		"text":"ถึง",
		"isAlreadyTranslated": true
	},
	"Custom Range":{
		"text":"ช่วงที่กำหนด",
		"isAlreadyTranslated": true
	},
	"Today":{
		"text":"วันนี้",
		"isAlreadyTranslated": true
	},
	"Yesterday":{
		"text":"เมื่อวาน",
		"isAlreadyTranslated": true
	},
	"Last 7 Days":{
		"text":"เกิน 7 วัน",
		"isAlreadyTranslated": true
	},
	"Last 30 Days":{
		"text":"เกิน 30 วัน",
		"isAlreadyTranslated": true
	},
	"This Month":{
		"text":"เดือนนี้",
		"isAlreadyTranslated": true
	},
	"Last Month":{
		"text":"เดือนที่แล้ว",
		"isAlreadyTranslated": true
	},
	"Download Open/Inprogress requests.":{
		"text":"เปิดการดาวน์โหลด/กำลังดำเนินการคำร้อง",
		"isAlreadyTranslated": true
	},
	"View":{
		"text":"ดู",
		"isAlreadyTranslated": true
	},
	"DOA completed":{
		"text":"DOA เรียบร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"Exception raised":{
		"text":"ข้อยกเว้น",
		"isAlreadyTranslated": true
	},
	"ID Proof":{
		"text":"ยืนยันตัวตน",
		"isAlreadyTranslated": true
	},
	"Invoice":{
		"text":"Invoice",
		"isAlreadyTranslated": true
	},
	"Receive & Inspect Device":{
		"text":"รับและตรวจสอบอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Upload Inspection Documents":{
		"text":"การอัปโหลดเอกสารตรวจสอบ",
		"isAlreadyTranslated": true
	},
	"Initiate Service":{
		"text":"เริ่มต้นการบริการ",
		"isAlreadyTranslated": true
	},
	"Accept/Reject Job":{
		"text":"ยืนยัน/ปฎิเสธใบรับงานซ่อม",
		"isAlreadyTranslated": true
	},
	"Complete Repair":{
		"text":"ซ่อมเสร็จเรียบร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"Complete Service":{
		"text":"บริการเรียบร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"Cancel Repair":{
		"text":"ยกเลิกซ่อม",
		"isAlreadyTranslated": true
	},
	"Cancel Service":{
		"text":"ยกเลิกการให้บริการ",
		"isAlreadyTranslated": true
	},
	"Reject Estimation":{
		"text":"ปฎิเสธการประเมินราคา",
		"isAlreadyTranslated": true
	},
	"Collect Advance":{
		"text":"รวบรวมความคืบหน้า",
		"isAlreadyTranslated": true
	},
	"QC Completed":{
		"text":"QC เรียบร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"Generate Invoice":{
		"text":"สร้าง  Invoice",
		"isAlreadyTranslated": true
	},
	"Accept Device":{
		"text":"รับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Diagnosis Completed":{
		"text":"วิเคราะห์เรียบร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"Initiate Diagnosis":{
		"text":"เริ่มการวิเคราะห์",
		"isAlreadyTranslated": true
	},
	"Cannot Diagnose":{
		"text":"ไม่สามารถวิเคราะห์ได้",
		"isAlreadyTranslated": true
	},
	"Collect Device":{
		"text":"รวบรวมอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Accept Parts Request":{
		"text":"ยอมรับคำขออะไหล่",
		"isAlreadyTranslated": true
	},
	"Reject Parts Request":{
		"text":"ปฏิเสธคำขออะไหล่",
		"isAlreadyTranslated": true
	},
	"DOA Checklist":{
		"text":"ใบตรวจสอบ DOA",
		"isAlreadyTranslated": true
	},
	"Handover to QC":{
		"text":"ส่งไปยัง QC",
		"isAlreadyTranslated": true
	},
	"Perform QC":{
		"text":"การดำเนินการ QC",
		"isAlreadyTranslated": true
	},
	"Approve/Reject DOA":{
		"text":"อนุมัติ/ปฎิเสธ DOA",
		"isAlreadyTranslated": true
	},
	"Intiate Repair":{
		"text":"เริ่มต้นการซ่อมแซม",
		"isAlreadyTranslated": true
	},
	"Prompt":{
		"text":"เตือน",
		"isAlreadyTranslated": true
	},
	"Silent":{
		"text":"ไม่มีเสียง",
		"isAlreadyTranslated": true
	},
	"Full":{
		"text":"เต็ม",
		"isAlreadyTranslated": true
	},
	"Custom":{
		"text":"เคยชิน",
		"isAlreadyTranslated": true
	},
	"BUTTONS":{
		"text":"ปุ่มกด",
		"isAlreadyTranslated": true
	},
	"SCREEN":{
		"text":"หน้าจอ",
		"isAlreadyTranslated": true
	},
	"CAMERA":{
		"text":"กล้อง",
		"isAlreadyTranslated": true
	},
	"SOUND":{
		"text":"เสียง",
		"isAlreadyTranslated": true
	},
	"PORTS":{
		"text":"PORTS",
		"isAlreadyTranslated": true
	},
	"CONNECTIVITY":{
		"text":"การเชื่อมต่อ",
		"isAlreadyTranslated": true
	},
	"SENSORS":{
		"text":"SENSORS",
		"isAlreadyTranslated": true
	},
	"HARDWARE":{
		"text":"HARDWARE",
		"isAlreadyTranslated": true
	},
	"Using Notification":{
		"text":"ใช้งานการแจ้งเตือน",
		"isAlreadyTranslated": true
	},
	"Using QR Code":{
		"text":"ใช้งาน QR Code",
		"isAlreadyTranslated": true
	},
	"Would you like to prompt the user?":{
		"text":"คุณต้องการแจ้งให้ผู้ใช้หรือไม่?",
		"isAlreadyTranslated": true
	},
	"Would you like to perform a full/custom diagnosis?":{
		"text":"คุณต้องการดำเนินการทั้งหมด/กำหนดวิเคราะห์หรือไม่?",
		"isAlreadyTranslated": true
	},
	"Select from the following to create a custom diagnosis":{
		"text":"ดำเนินการกรอกจากด้านล่างโดยการกำหนดเอง",
		"isAlreadyTranslated": true
	},
	"Save":{
		"text":"บันทึก",
		"isAlreadyTranslated": true
	},
	"Select SKU":{
		"text":"เลือกรุ่น",
		"isAlreadyTranslated": true
	},
	"Other Image":{
		"text":"รูปอื่นๆ",
		"isAlreadyTranslated": true
	},
	"No pictures added.":{
		"text":"ไม่แนบรูปภาพ",
		"isAlreadyTranslated": true
	},
	"Complete":{
		"text":"เรียบร้อย",
		"isAlreadyTranslated": true
	},
	"QC Checklist":{
		"text":"ใบตรวจสอบ QC",
		"isAlreadyTranslated": true
	},
	"Select All":{
		"text":"เลือกทั้งหมด",
		"isAlreadyTranslated": true
	},
	"Accept and Complete QC":{
		"text":"ยอมรับและเสร็จสิ้น QC",
		"isAlreadyTranslated": true
	},
	"Reject QC":{
		"text":"ปฎิเสธการ QC",
		"isAlreadyTranslated": true
	},
	"Is the device applicable for DOA?":{
		"text":"อุปกรณ์นี้เป็น DOA ใช่หรือไม่?",
		"isAlreadyTranslated": true
	},
	"Yes":{
		"text":"ใช่",
		"isAlreadyTranslated": true
	},
	"Initiate diagnosis":{
		"text":"ดำเนินการวิเคราะห์",
		"isAlreadyTranslated": true
	},
	"Scan the QR code from Diagnosis App":{
		"text":"สแกนโค้ด QR จาก App",
		"isAlreadyTranslated": true
	},
	"Check Status":{
		"text":"สถานะการตรวจสอบ",
		"isAlreadyTranslated": true
	},
	"First Dialer Dispostion":{
		"text":"เบอร์ที่หนึ่ง",
		"isAlreadyTranslated": true
	},
	"Second Dialer Dispostion":{
		"text":"เบอร์ที่สอง",
		"isAlreadyTranslated": true
	},
	"Type of damage":{
		"text":"ประเภทอาการเสีย",
		"isAlreadyTranslated": true
	},
	"Please mention the incident in not less than 75 characters on how":{
		"text":"อธิบายอาการเสียไม่ต่ำกว่า 75 ตัวอักษร",
		"isAlreadyTranslated": true
	},
	"Remarks":{
		"text":"หมายเหตุ",
		"isAlreadyTranslated": true
	},
	"Handing over the":{
		"text":"ส่งมอบ",
		"isAlreadyTranslated": true
	},
	"parts":{
		"text":"บางส่วน",
		"isAlreadyTranslated": true
	},
	"to store.":{
		"text":"การเก็บรักษา",
		"isAlreadyTranslated": true
	},
	"Select Fault Code based on the validated issues.":{
		"text":"รหัสสาเหตุการเสีย",
		"isAlreadyTranslated": true
	},
	"Fault Code":{
		"text":"รหัสอาการเสีย",
		"isAlreadyTranslated": true
	},
	"Add":{
		"text":"เพิ่ม",
		"isAlreadyTranslated": true
	},
	"Handing over the defective device to store.":{
		"text":"การจัดเก็บอุปกรณ์เกิดข้อบกพร่อง",
		"isAlreadyTranslated": true
	},
	"Update KGB":{
		"text":"อัปเดต KGB",
		"isAlreadyTranslated": true
	},
	"Select GSX Repair Type":{
		"text":"เลือกประเภทการซ่อม GSX",
		"isAlreadyTranslated": true
	},
	"Transfer Request":{
		"text":"คำขอโอนเงิน",
		"isAlreadyTranslated": true
	},
	"Send link":{
		"text":"ส่งลิ้งค์",
		"isAlreadyTranslated": true
	},
	"Logistic Partners":{
		"text":"Logistic Partners",
		"isAlreadyTranslated": true
	},
	"Select a Date":{
		"text":"เลือกวันที่",
		"isAlreadyTranslated": true
	},
	"Select a Slot":{
		"text":"เลือกช่วงเวลา",
		"isAlreadyTranslated": true
	},
	"No Slots Available":{
		"text":"ไม่มีเวลาให้เลือก",
		"isAlreadyTranslated": true
	},
	"Schedule Request":{
		"text":"ร้องขอการจัดเตรียม",
		"isAlreadyTranslated": true
	},
	"Item":{
		"text":"รายการ",
		"isAlreadyTranslated": true
	},
	"Raise":{
		"text":"เริ่มต้น",
		"isAlreadyTranslated": true
	},
	"Request":{
		"text":"คำร้องขอ",
		"isAlreadyTranslated": true
	},
	"Please Select Device Receiving Time":{
		"text":"เลือกเวลาที่ได้รับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Tier part":{
		"text":"ระดับ",
		"isAlreadyTranslated": true
	},
	"Shipper":{
		"text":"ผู้ส่ง",
		"isAlreadyTranslated": true
	},
	"Tracking Number":{
		"text":"Tracking Number",
		"isAlreadyTranslated": true
	},
	"COMPTIA Group":{
		"text":"กลุ่ม COMPTIA",
		"isAlreadyTranslated": true
	},
	"COMPTIA Code":{
		"text":"รหัส COMPTIA",
		"isAlreadyTranslated": true
	},
	"COMPTIA Modifier":{
		"text":"การแก้ไข COMPTIA",
		"isAlreadyTranslated": true
	},
	"Action":{
		"text":"การกระทำ",
		"isAlreadyTranslated": true
	},
	"Continue":{
		"text":"ต่อเนื่อง",
		"isAlreadyTranslated": true
	},
	"Date and Time of loss/damage:":{
		"text":"วัน/เวลาที่ได้รับความเสียหาย",
		"isAlreadyTranslated": true
	},
	"To proceed":{
		"text":"ต่อเนื่อง",
		"isAlreadyTranslated": true
	},
	"Ask for Documents Pending":{
		"text":"ขอไฟล์ที่รอดำเนินการ",
		"isAlreadyTranslated": true
	},
	"Enter type of document to add":{
		"text":"ป้อนประเภทเอกสารที่คุณต้องการเพิ่ม",
		"isAlreadyTranslated": true
	},
	"Enter Reference IDs":{
		"text":"ป้อนรหัสอ้างอิง",
		"isAlreadyTranslated": true
	},
	"Current Request Reference ID":{
		"text":"รหัสอ้างอิงคำขอปัจจุบัน",
		"isAlreadyTranslated": true
	},
	"Merge":{
		"text":"รวมกัน",
		"isAlreadyTranslated": true
	},
	"Device transaction details":{
		"text":"รายละเอียดอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Select Device":{
		"text":"เลือกอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Please check following items before completing the QC.":{
		"text":"โปรดตรวจสอบรายการต่อไปนี้ก่อนที่จะทำ QC",
		"isAlreadyTranslated": true
	},
	"In-principle Approval":{
		"text":"อนัติตามหลักการ",
		"isAlreadyTranslated": true
	},
	"Complete QC":{
		"text":"QC เรียร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"Handover device":{
		"text":"ส่งอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Accept device":{
		"text":"ยอมรับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Complete Job and Handover Device":{
		"text":"ส่งอุปกณ์กับปิดงานเรียบร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"Generate estimation":{
		"text":"ประเมินราคา",
		"isAlreadyTranslated": true
	},
	"Cancel Service Without Dispatch":{
		"text":"ยกเลิกบริการโดยไม่ต้องจัดส่ง",
		"isAlreadyTranslated": true
	},
	"Documents Pending":{
		"text":"เอกสารที่รอดำเนินการ",
		"isAlreadyTranslated": true
	},
	"Document Type: Cheque":{
		"text":"ประเภทเอกสาร ：ตรวจสอบ",
		"isAlreadyTranslated": true
	},
	"Please enter UTR Number":{
		"text":"โปรดกรอก UTR",
		"isAlreadyTranslated": true
	},
	"Payment Date":{
		"text":"วันเวลาที่ชำระ",
		"isAlreadyTranslated": true
	},
	"UTR Number":{
		"text":"หมายเลข UTR",
		"isAlreadyTranslated": true
	},
	"View queued requests":{
		"text":"ดูคำขอที่รอคิว",
		"isAlreadyTranslated": true
	},
	"Generate Vouchers":{
		"text":"สร้าง Vouchers",
		"isAlreadyTranslated": true
	},
	"Customer's Address Details":{
		"text":"รายละเอียดที่อยู่ของลูกค้า",
		"isAlreadyTranslated": true
	},
	"Hub's Address Details":{
		"text":"รายละเอียดที่อยู่ของ HUB",
		"isAlreadyTranslated": true
	},
	"Upload Document":{
		"text":"อัปโหลดเอกสาร",
		"isAlreadyTranslated": true
	},
	"Call logs":{
		"text":"บันทึกการโทร",
		"isAlreadyTranslated": true
	},
	"Okay":{
		"text":"ตกลง",
		"isAlreadyTranslated": true
	},
	"Back":{
		"text":"กลับ",
		"isAlreadyTranslated": true
	},
	"Office":{
		"text":"ออฟฟิต",
		"isAlreadyTranslated": true
	},
	"an address":{
		"text":"ที่อยู่",
		"isAlreadyTranslated": true
	},
	"Landmark":{
		"text":"ที่ตั้ง",
		"isAlreadyTranslated": true
	},
	"Address":{
		"text":"ที่อยู่",
		"isAlreadyTranslated": true
	},
	"Pincode":{
		"text":"รหัสไปรษณีย์",
		"isAlreadyTranslated": true
	},
	"Characters must be numbers":{
		"text":"อักขระต้องเป็นตัวเลข",
		"isAlreadyTranslated": true
	},
	"Address Details":{
		"text":"รายละเอียดสถานที่",
		"isAlreadyTranslated": true
	},
	"No saved Address":{
		"text":"ไม่มีการบันทึกที่อยู่",
		"isAlreadyTranslated": true
	},
	"Now":{
		"text":"ปัจจุบัน",
		"isAlreadyTranslated": true
	},
	"Later":{
		"text":"ภายหลัง",
		"isAlreadyTranslated": true
	},
	"Hub Details":{
		"text":"รายละเอียด HUB",
		"isAlreadyTranslated": true
	},
	"This request will be added to a queue. You can schedule a logistics request later.":{
		"text":"คำขอนี้จะถูกเพิ่มลงในคิว คุณสามารถกำหนดเวลาการขอการขนส่งได้ในภายหลัง",
		"isAlreadyTranslated": true
	},
	"Recycle Hub Details":{
		"text":"รายละเอียดการรับของ HUB",
		"isAlreadyTranslated": true
	},
	"Add Certificate":{
		"text":"เพิ่มการรับรอง",
		"isAlreadyTranslated": true
	},
	"Incoming":{
		"text":"มา",
		"isAlreadyTranslated": true
	},
	"Outgoing":{
		"text":"ส่งออก",
		"isAlreadyTranslated": true
	},
	"Add call log":{
		"text":"เพิ่มบันทึกการโทร",
		"isAlreadyTranslated": true
	},
	"Call type":{
		"text":"ประเภทการโทร",
		"isAlreadyTranslated": true
	},
	"Call history":{
		"text":"บันทึกการโทร",
		"isAlreadyTranslated": true
	},
	"No call history":{
		"text":"ไม่มีการบันทึกการโทร",
		"isAlreadyTranslated": true
	},
	"Customer Details":{
		"text":"ข้อมูลลูกค้า",
		"isAlreadyTranslated": true
	},
	"Mobile Number":{
		"text":"เบอร์โทรศัพท์",
		"isAlreadyTranslated": true
	},
	"Alternate Mobile No":{
		"text":"หมายเลขโทรศัพท์สำรอง",
		"isAlreadyTranslated": true
	},
	"Email ID":{
		"text":"Email",
		"isAlreadyTranslated": true
	},
	"First Registered From":{
		"text":"ลงทะเบียนครั้งแรกจาก",
		"isAlreadyTranslated": true
	},
	"Assigned To":{
		"text":"ได้รับมอบหมายให้",
		"isAlreadyTranslated": true
	},
	"Assignment":{
		"text":"การมอบหมาย",
		"isAlreadyTranslated": true
	},
	"N/A":{
		"text":"N / A",
		"isAlreadyTranslated": true
	},
	"Service Engineer":{
		"text":"ช่างให้บริการ",
		"isAlreadyTranslated": true
	},
	"Re-assign Service Engineer":{
		"text":"ช่างให้บริการใหม่",
		"isAlreadyTranslated": true
	},
	"Assign":{
		"text":"เลือก",
		"isAlreadyTranslated": true
	},
	"Request is being processed":{
		"text":"กำลังประมวลผลคำขอ",
		"isAlreadyTranslated": true
	},
	"Service Mode":{
		"text":"โหมดบริการ",
		"isAlreadyTranslated": true
	},
	"Slot and Date":{
		"text":"ระยะเวลากับวันเวลา",
		"isAlreadyTranslated": true
	},
	"Service Location":{
		"text":"สถานที่ให้บริการ",
		"isAlreadyTranslated": true
	},
	"Add an address":{
		"text":"เพิ่มที่อยู่",
		"isAlreadyTranslated": true
	},
	"Select an Address":{
		"text":"เลือกที่อยู่",
		"isAlreadyTranslated": true
	},
	"Add New Address":{
		"text":"เพิ่มที่อยู่ใหม่",
		"isAlreadyTranslated": true
	},
	"No addresses found":{
		"text":"ค้นหาสถานที่ไม่พบ",
		"isAlreadyTranslated": true
	},
	"Select a Service Mode":{
		"text":"เลือกประเภทการบริการ",
		"isAlreadyTranslated": true
	},
	"Select a service location":{
		"text":"เลือกตำแหน่งบริการ",
		"isAlreadyTranslated": true
	},
	"Oops! There are no verified service centres around.":{
		"text":"อ๊ะ! ไม่มีศูนย์บริการที่ผ่านการยืนยันแล้ว",
		"isAlreadyTranslated": true
	},
	"There is no service for this address at this moment":{
		"text":"ขณะนี้ยังไม่มีบริการสำหรับที่อยู่นี้",
		"isAlreadyTranslated": true
	},
	"Raise Request":{
		"text":"เพิ่มคำขอ",
		"isAlreadyTranslated": true
	},
	"Loading":{
		"text":"กำลังโหลด",
		"isAlreadyTranslated": true
	},
	"Select Logistics Partner":{
		"text":"เลือกคู่ค้าการขนส่ว",
		"isAlreadyTranslated": true
	},
	"Confirm":{
		"text":"ยืนยัน",
		"isAlreadyTranslated": true
	},
	"Device Details":{
		"text":"รายละเอียดอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Serial Number":{
		"text":"Serial Number",
		"isAlreadyTranslated": true
	},
	"Storage Capacity":{
		"text":"ความจุ",
		"isAlreadyTranslated": true
	},
	"Insurance Benefits":{
		"text":"ประโยชน์การประกันภัย",
		"isAlreadyTranslated": true
	},
	"Year of Purchase":{
		"text":"ปีที่ซื้อ",
		"isAlreadyTranslated": true
	},
	"Date of Purchase":{
		"text":"วันที่ซื้อ",
		"isAlreadyTranslated": true
	},
	"Sales Channel":{
		"text":"ช่องทางการขาย",
		"isAlreadyTranslated": true
	},
	"Purchase Price":{
		"text":"ราคาซื้อ",
		"isAlreadyTranslated": true
	},
	"Is Warranty Applicable":{
		"text":"มีการรับประกันหรือไม่",
		"isAlreadyTranslated": true
	},
	"Reason":{
		"text":"สาเหตุ",
		"isAlreadyTranslated": true
	},
	"Registration Documents":{
		"text":"เอกสารการลงทะเบียน",
		"isAlreadyTranslated": true
	},
	"Device Added Date":{
		"text":"วันที่รับเครื่อง",
		"isAlreadyTranslated": true
	},
	"Config Description":{
		"text":"คำอธิบายองค์ประกอบ",
		"isAlreadyTranslated": true
	},
	"Validation Status":{
		"text":"ยืนยันสถานะ",
		"isAlreadyTranslated": true
	},
	"Inspection Guide":{
		"text":"คู่มือการตรวจสอบ",
		"isAlreadyTranslated": true
	},
	"Verify":{
		"text":"ตรวจสอบ",
		"isAlreadyTranslated": true
	},
	"Under warranty":{
		"text":"ภายใต้การรับประกัน",
		"isAlreadyTranslated": true
	},
	"c":{
		"text":"C",
		"isAlreadyTranslated": true
	},
	"Number of Attempts Remaining":{
		"text":"จำนวนที่เหลืออยู่",
		"isAlreadyTranslated": true
	},
	"Verification Not Attempted":{
		"text":"ไม่ได้รับการยืนยัน",
		"isAlreadyTranslated": true
	},
	"No documents uploaded":{
		"text":"ไม่มีไฟล์ที่อัปโหลด",
		"isAlreadyTranslated": true
	},
	"Add Pending Documents":{
		"text":"เพิ่มเอกสารที่รอดำเนินการ",
		"isAlreadyTranslated": true
	},
	"No Pending Document Specified":{
		"text":"ไม่มีไฟล์ที่รอดำเนินการเฉพาะ",
		"isAlreadyTranslated": true
	},
	"Add Additional Documents":{
		"text":"เพิ่มเอกสารอื่น",
		"isAlreadyTranslated": true
	},
	"Request History":{
		"text":"ประวัติ",
		"isAlreadyTranslated": true
	},
	"Consumer Raised Issues":{
		"text":"คำอธิบายปัญหาผู้ใช้",
		"isAlreadyTranslated": true
	},
	"Issues":{
		"text":"ปัญหา",
		"isAlreadyTranslated": true
	},
	"Validity":{
		"text":"พบการอาการเสียจริง",
		"isAlreadyTranslated": true
	},
	"Validate":{
		"text":"การตรวจสอบ",
		"isAlreadyTranslated": true
	},
	"Customer did not add issues":{
		"text":"ลูกค้าไม่ได้เพิ่มปัญหา",
		"isAlreadyTranslated": true
	},
	"Symptoms Reported By Engineer":{
		"text":"ค้นพบปัญหาโดยช่าง",
		"isAlreadyTranslated": true
	},
	"Symptoms":{
		"text":"อาการเสีย",
		"isAlreadyTranslated": true
	},
	"Add New Symptom Codes":{
		"text":"เพิ่มรหัสอาการเสียใหม่",
		"isAlreadyTranslated": true
	},
	"No issues added":{
		"text":"ไม่มีปัญหาที่ต้องการเพิ่ม",
		"isAlreadyTranslated": true
	},
	"No symptoms added":{
		"text":"ไม่มีอาการเสียเพิ่ม",
		"isAlreadyTranslated": true
	},
	"Engineer Added Faults":{
		"text":"ช่างเพิ่มสาหุอาการเสีย",
		"isAlreadyTranslated": true
	},
	"Faults":{
		"text":"สาเหตุอาการเสีย",
		"isAlreadyTranslated": true
	},
	"Action Code":{
		"text":"รหัสการทำงาน",
		"isAlreadyTranslated": true
	},
	"Fault Codes":{
		"text":"รหัสอารเสีย",
		"isAlreadyTranslated": true
	},
	"Actions":{
		"text":"ดำเนินการ",
		"isAlreadyTranslated": true
	},
	"Role":{
		"text":"บทบาท",
		"isAlreadyTranslated": true
	},
	"Customer Care Executive":{
		"text":"หัวหน้างานบริการลูกค้า",
		"isAlreadyTranslated": true
	},
	"Accessories will not be returned":{
		"text":"อุปกรณ์เสริมไม่ได้รับการคืน",
		"isAlreadyTranslated": true
	},
	"Documents are yet to be uploaded.":{
		"text":"ยังไม่ได้อัปโหลดเอกสาร",
		"isAlreadyTranslated": true
	},
	"View Jobsheet":{
		"text":"ดูเอกสารงาน",
		"isAlreadyTranslated": true
	},
	"View Invoice":{
		"text":"ดูใบแจ้งหนี้",
		"isAlreadyTranslated": true
	},
	"View Insurer Invoice":{
		"text":"ดูใบกำกับสินค้าของผู้รับประกันภัย",
		"isAlreadyTranslated": true
	},
	"View Parts Document":{
		"text":"ดูเอกสารชิ้นส่วน",
		"isAlreadyTranslated": true
	},
	"View Estimation Document":{
		"text":"ดูเอกสารประเมินราคา",
		"isAlreadyTranslated": true
	},
	"View DOA Certificate":{
		"text":"ดูใบรับรอง DOA",
		"isAlreadyTranslated": true
	},
	"View Advance Receipt":{
		"text":"ดูการรับล่วงหน้า",
		"isAlreadyTranslated": true
	},
	"View Delivery Report":{
		"text":"ดูรายงานการจัดส่ง",
		"isAlreadyTranslated": true
	},
	"View Depot shipper label":{
		"text":"ดูป้ายกำกับการจัดส่งของ Depot",
		"isAlreadyTranslated": true
	},
	"Slot":{
		"text":"ช่วงเวลา",
		"isAlreadyTranslated": true
	},
	"Rating":{
		"text":"คะแนน",
		"isAlreadyTranslated": true
	},
	"Next Steps":{
		"text":"ขั้นตอนถัดไป",
		"isAlreadyTranslated": true
	},
	"Waiting for the customer to raise a request":{
		"text":"กำลังรอให้ลูกค้าเริ่มต้น",
		"isAlreadyTranslated": true
	},
	"Cancel Request":{
		"text":"ยกเลิกคำขอ",
		"isAlreadyTranslated": true
	},
	"Device shipped":{
		"text":"มีการจัดส่งอุปกรณ์แล้ว",
		"isAlreadyTranslated": true
	},
	"Reschedule":{
		"text":"แก้ไขเวลา",
		"isAlreadyTranslated": true
	},
	"Reassign":{
		"text":"เริ่มการแบ่งใหม่",
		"isAlreadyTranslated": true
	},
	"Mark Customer Arrival":{
		"text":"การระบุสินค้าที่ลูกค้าได้รับ",
		"isAlreadyTranslated": true
	},
	"Start Repair":{
		"text":"เริ่มการซ่อมแซม",
		"isAlreadyTranslated": true
	},
	"Device Received":{
		"text":"อุปกรณ์ได้รับแล้ว",
		"isAlreadyTranslated": true
	},
	"Dispatch Device":{
		"text":"อุปกรณ์จัดจำหน่าย",
		"isAlreadyTranslated": true
	},
	"Parts Pending":{
		"text":"ชิ้นส่วนอะไหล่ที่รอการอนุมัติ",
		"isAlreadyTranslated": true
	},
	"Download Jobsheet Label":{
		"text":"ดาวน์โหลดแผ่นงาน",
		"isAlreadyTranslated": true
	},
	"Download Logistics Label":{
		"text":"ดาวน์โหลดการขนส่ง",
		"isAlreadyTranslated": true
	},
	"Consumer Details":{
		"text":"รายละเอียดของลูกค้า",
		"isAlreadyTranslated": true
	},
	"Issue Validation":{
		"text":"การยืนยันปัญหา",
		"isAlreadyTranslated": true
	},
	"Report Issues to":{
		"text":"รายงานปัญหา",
		"isAlreadyTranslated": true
	},
	"Estimation Details":{
		"text":"รายละเอียดการประเมินราคา",
		"isAlreadyTranslated": true
	},
	"Override Approval History":{
		"text":"ล้างประวัติการอนุมัติ",
		"isAlreadyTranslated": true
	},
	"Parts Requested":{
		"text":"การเบิกอะไหล่",
		"isAlreadyTranslated": true
	},
	"Dead on Arrival":{
		"text":"การมาที่ไม่ดี",
		"isAlreadyTranslated": true
	},
	"Logistics Details":{
		"text":"รายละเอียดการขนส่ง",
		"isAlreadyTranslated": true
	},
	"Communication History":{
		"text":"ประวัติการสื่อสาร",
		"isAlreadyTranslated": true
	},
	"Override Approval":{
		"text":"แทนที่การอนุมัติ",
		"isAlreadyTranslated": true
	},
	"Loading request":{
		"text":"กำลังโหลดคำขอ",
		"isAlreadyTranslated": true
	},
	"No such service request exists":{
		"text":"ไม่มีคำขอบริการดังกล่าวอยู่",
		"isAlreadyTranslated": true
	},
	"Send Issues to":{
		"text":"ส่งปัญหาไปที่",
		"isAlreadyTranslated": true
	},
	"Symptom Code":{
		"text":"รหัสอาการ",
		"isAlreadyTranslated": true
	},
	"Issue Code":{
		"text":"รหัสปัญหา",
		"isAlreadyTranslated": true
	},
	"Part name":{
		"text":"ชื่ออะไหล่",
		"isAlreadyTranslated": true
	},
	"Other Charge":{
		"text":"ค่าใช้จ่ายอื่นๆ",
		"isAlreadyTranslated": true
	},
	"Advance":{
		"text":"ความก้าวหน้า",
		"isAlreadyTranslated": true
	},
	"Discount":{
		"text":"ส่วนลด",
		"isAlreadyTranslated": true
	},
	"Parts":{
		"text":"อะไหล่",
		"isAlreadyTranslated": true
	},
	"Type":{
		"text":"ประเภท",
		"isAlreadyTranslated": true
	},
	"Amount":{
		"text":"จำนวน",
		"isAlreadyTranslated": true
	},
	"Total Amount":{
		"text":"จำนวนเงินทั้งหมด",
		"isAlreadyTranslated": true
	},
	"Total Payable":{
		"text":"ยอดรวมที่ต้องชำระ",
		"isAlreadyTranslated": true
	},
	"Covered Value":{
		"text":"มูลค่าที่ครอบคลุม",
		"isAlreadyTranslated": true
	},
	"ADD CHARGES":{
		"text":"เพิ่มค่าใช้จ่าย",
		"isAlreadyTranslated": true
	},
	"Send For Approval":{
		"text":"ส่งเพื่อขออนุมัติ",
		"isAlreadyTranslated": true
	},
	"Add Required Parts":{
		"text":"เพิ่มคำร้องขออะไหล่",
		"isAlreadyTranslated": true
	},
	"Part Name":{
		"text":"ชื่ออะไหล่",
		"isAlreadyTranslated": true
	},
	"HSN Code":{
		"text":"รหัส HSN",
		"isAlreadyTranslated": true
	},
	"Price":{
		"text":"ราคา",
		"isAlreadyTranslated": true
	},
	"Warranty":{
		"text":"การประกัน",
		"isAlreadyTranslated": true
	},
	"Tax Slab":{
		"text":"Tax Slab",
		"isAlreadyTranslated": true
	},
	"Select a Part Name":{
		"text":"เลือกชื่ออะไหล่",
		"isAlreadyTranslated": true
	},
	"Part Code":{
		"text":"รหัสอะไหล่",
		"isAlreadyTranslated": true
	},
	"Part Warranty":{
		"text":"การรับประกันอะไหล่",
		"isAlreadyTranslated": true
	},
	"Pricing Option":{
		"text":"ตัวเลือกการกำหนดราคา",
		"isAlreadyTranslated": true
	},
	"Part Price":{
		"text":"ราคาอะไหล่",
		"isAlreadyTranslated": true
	},
	"Remark":{
		"text":"หมายเหตุ",
		"isAlreadyTranslated": true
	},
	"Qty":{
		"text":"จำนวน",
		"isAlreadyTranslated": true
	},
	"Available Qty":{
		"text":"จำนวนที่มีอยู่",
		"isAlreadyTranslated": true
	},
	"Charge Name":{
		"text":"Charge Name",
		"isAlreadyTranslated": true
	},
	"Charge Value":{
		"text":"Charge Value",
		"isAlreadyTranslated": true
	},
	"Tax":{
		"text":"Tax",
		"isAlreadyTranslated": true
	},
	"MRP":{
		"text":"MRP",
		"isAlreadyTranslated": true
	},
	"Final Price":{
		"text":"ราคาสุดท้าย",
		"isAlreadyTranslated": true
	},
	"Add Discount":{
		"text":"เพิ่มส่วนลด",
		"isAlreadyTranslated": true
	},
	"(Override only if customer instructs to)*":{
		"text":"(แทนที่เฉพาะเมื่อลูกค้าสั่งให้) *",
		"isAlreadyTranslated": true
	},
	"Reason for Approval":{
		"text":"เหตุผลในการอนุมัติ",
		"isAlreadyTranslated": true
	},
	"Status Date":{
		"text":"วันที่สถานะ",
		"isAlreadyTranslated": true
	},
	"No Parts Requested":{
		"text":"ไม่มีการเบิกอะไหล่",
		"isAlreadyTranslated": true
	},
	"Good Part Serial No":{
		"text":"หมายเลขอะไหล่ที่ดี",
		"isAlreadyTranslated": true
	},
	"Bad Part Serial No":{
		"text":"หมายเลขอะไหล่ที่ไม่ดี",
		"isAlreadyTranslated": true
	},
	"Avl.":{
		"text":"Avl",
		"isAlreadyTranslated": true
	},
	"Requested By":{
		"text":"การร้องขอจาก",
		"isAlreadyTranslated": true
	},
	"Issued By":{
		"text":"ออกโดย",
		"isAlreadyTranslated": true
	},
	"Parts Supply Location":{
		"text":"สถานที่จัดหาอะไหล่",
		"isAlreadyTranslated": true
	},
	"Enter Information for the parts you are issuing":{
		"text":"ป้อนข้อมูลเกี่ยวกับอะไหล่ที่คุณต้องการสงออก",
		"isAlreadyTranslated": true
	},
	"Date Of Purchase":{
		"text":"วันที่ซื้อ",
		"isAlreadyTranslated": true
	},
	"Get New Device":{
		"text":"รับอุปกรณ์ใหม่",
		"isAlreadyTranslated": true
	},
	"Generate DOA Certificate":{
		"text":"สร้างใบรับรอง DOA",
		"isAlreadyTranslated": true
	},
	"Choose Device":{
		"text":"เลือกอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Action on Device":{
		"text":"การทำงานบนอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Issue":{
		"text":"ปัญหา",
		"isAlreadyTranslated": true
	},
	"Receive":{
		"text":"รับ",
		"isAlreadyTranslated": true
	},
	"Enter Information for the Device you are issuing":{
		"text":"ป้อนใส่ข้อมูลเกี่ยวกับอุปกรณ์ที่คุณต้องการส่ง",
		"isAlreadyTranslated": true
	},
	"IMEI 1":{
		"text":"IMEI 1",
		"isAlreadyTranslated": true
	},
	"IMEI 2":{
		"text":"IMEI 2",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI 1":{
		"text":"โปรดป้อน IMEI ที่ถูกต้อง 1",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI 2":{
		"text":"โปรดป้อน IMEI ที่ถูกต้อง 2",
		"isAlreadyTranslated": true
	},
	"Please enter correct Serial Number":{
		"text":"โปรดป้อนหมายเลขซีเรียลที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Account Details":{
		"text":"รายละเอียดบัญชี",
		"isAlreadyTranslated": true
	},
	"The Green Reward cash amount will be credited to the account associated with this number":{
		"text":"จำนวนเงินสดที่ได้รับรางวัลจะถูกโอนไปยังบัญชีที่เชื่อมโยงกับหมายเลขนี้",
		"isAlreadyTranslated": true
	},
	"Add Customer's Mobile Number":{
		"text":"เพิ่มหมายเลขโทรศัพท์มือถือของลูกค้า",
		"isAlreadyTranslated": true
	},
	"Enter Mobile Number":{
		"text":"ป้อนหมายเลขโทรศัพท์มือถือ",
		"isAlreadyTranslated": true
	},
	"Re - Enter Mobile Number":{
		"text":"ป้อนหมายเลขโทรศัพท์มือถืออีกครั้ง",
		"isAlreadyTranslated": true
	},
	"External Reference ID":{
		"text":"รหัสอ้างอิงภายนอก",
		"isAlreadyTranslated": true
	},
	"Current Status":{
		"text":"สถานะปัจจุบัน",
		"isAlreadyTranslated": true
	},
	"Reassigned":{
		"text":"แจกจ่าย",
		"isAlreadyTranslated": true
	},
	"Under Warranty":{
		"text":"ภายใต้การรับประกัน",
		"isAlreadyTranslated": true
	},
	"Warranty Applicable":{
		"text":"รับประกันการใช้งาน",
		"isAlreadyTranslated": true
	},
	"Pending Verification":{
		"text":"รอการยืนยัน",
		"isAlreadyTranslated": true
	},
	"Issue description":{
		"text":"อธิบายปัญหา",
		"isAlreadyTranslated": true
	},
	"Customer's Version":{
		"text":"เวอร์ชันของลูกค้า",
		"isAlreadyTranslated": true
	},
	"Telecon Version":{
		"text":"เวอร์ชัน Telecon",
		"isAlreadyTranslated": true
	},
	"Green Rewards Chosen":{
		"text":"การเลือกรางวัล",
		"isAlreadyTranslated": true
	},
	"Recycle Certificate":{
		"text":"การได้รับใบรับรอง",
		"isAlreadyTranslated": true
	},
	"Verification Code":{
		"text":"รหัสยืนยัน",
		"isAlreadyTranslated": true
	},
	"Waybill Number":{
		"text":"เลขที่ใบกับกำ",
		"isAlreadyTranslated": true
	},
	"Location":{
		"text":"สถานที่",
		"isAlreadyTranslated": true
	},
	"Paid":{
		"text":"ชำระ",
		"isAlreadyTranslated": true
	},
	"Payable":{
		"text":"ที่ต้องชำระ",
		"isAlreadyTranslated": true
	},
	"Loss DateTime":{
		"text":"วันเวลาหมดอายุ",
		"isAlreadyTranslated": true
	},
	"Damage Type":{
		"text":"ประเภทความเสียหาย",
		"isAlreadyTranslated": true
	},
	"Loaner Device IMEI":{
		"text":"IMEI เครื่องสำรองการใช้",
		"isAlreadyTranslated": true
	},
	"Condition Of the Loaner Device After Use":{
		"text":"สถานะการใช้เครื่องสำรอง",
		"isAlreadyTranslated": true
	},
	"Remarks for the Loaner Device":{
		"text":"ข้อสังเกตสำหรับเครื่องสำรองใช้",
		"isAlreadyTranslated": true
	},
	"Loss Description":{
		"text":"คำอธิบายความเสียหาย",
		"isAlreadyTranslated": true
	},
	"Delivery Address":{
		"text":"ที่อยู่การจัดส่ง",
		"isAlreadyTranslated": true
	},
	"Device switching on":{
		"text":"เริ่มต้นอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Place of Damage":{
		"text":"สถานที่ที่ได้รับความเสียหาย",
		"isAlreadyTranslated": true
	},
	"Enter Reference ID":{
		"text":"ป้อนรหัสอ้างอิง",
		"isAlreadyTranslated": true
	},
	"Reschedule Service":{
		"text":"กำหนดการให้บริการใหม่",
		"isAlreadyTranslated": true
	},
	"No slots Available":{
		"text":"ไม่มีเวลาให้เลือก",
		"isAlreadyTranslated": true
	},
	"Reason for reschedule":{
		"text":"เหตุผลในการเลื่อนกำหนดเวลาใหม่",
		"isAlreadyTranslated": true
	},
	"Reassign Service":{
		"text":"การกำหนดบริการใหม่",
		"isAlreadyTranslated": true
	},
	"Not applicable":{
		"text":"ไม่สามารถใช้งาน",
		"isAlreadyTranslated": true
	},
	"Edit":{
		"text":"แก้ไข",
		"isAlreadyTranslated": true
	},
	"Others":{
		"text":"อื่นๆ",
		"isAlreadyTranslated": true
	},
	"Not Validated":{
		"text":"ไม่ได้รับการยืนยัน",
		"isAlreadyTranslated": true
	},
	"On":{
		"text":"บน",
		"isAlreadyTranslated": true
	},
	"at":{
		"text":"อยู่",
		"isAlreadyTranslated": true
	},
	"Device Received & Inspection in Progress":{
		"text":"อุปกรณ์ที่ได้รับอยู่ในระหว่างการตรวจสอบดำเนินการ",
		"isAlreadyTranslated": true
	},
	"No engineers found":{
		"text":"ช่างไม่พบอาการ",
		"isAlreadyTranslated": true
	},
	"Ref Id":{
		"text":"อ้างอิงรหัส ID",
		"isAlreadyTranslated": true
	},
	"Started On":{
		"text":"เริ่มต้นแล้ว",
		"isAlreadyTranslated": true
	},
	"End on":{
		"text":"สิ้นสุด",
		"isAlreadyTranslated": true
	},
	"Service completed":{
		"text":"บริการเรียบร้อยแล้ว",
		"isAlreadyTranslated": true
	},
	"Service cancel":{
		"text":"ยกเลิกการบริการ",
		"isAlreadyTranslated": true
	},
	"User Management":{
		"text":"การจัดการลูกค้า",
		"isAlreadyTranslated": true
	},
	"Invite Service User":{
		"text":"เชิญผู้ใช้บริการ",
		"isAlreadyTranslated": true
	},
	"Invite Retailer User":{
		"text":"เชิญผู้ค้าปลีก",
		"isAlreadyTranslated": true
	},
	"Email Id":{
		"text":"Email",
		"isAlreadyTranslated": true
	},
	"User Role":{
		"text":"บทบาทลูกค้า",
		"isAlreadyTranslated": true
	},
	"No users found":{
		"text":"ไม่พบลูกค้า",
		"isAlreadyTranslated": true
	},
	"Select User Type":{
		"text":"เลือกประเภทลูกค้า",
		"isAlreadyTranslated": true
	},
	"Partner User":{
		"text":"Partner User",
		"isAlreadyTranslated": true
	},
	"Dashboard":{
		"text":"Dashboard",
		"isAlreadyTranslated": true
	},
	"Requests":{
		"text":"การร้องขอ",
		"isAlreadyTranslated": true
	},
	"Consumers":{
		"text":"ผู้บริโภค",
		"isAlreadyTranslated": true
	},
	"View Document":{
		"text":"ดูเอกสาร",
		"isAlreadyTranslated": true
	},
	"UserList":{
		"text":"UserList",
		"isAlreadyTranslated": true
	},
	"Logout":{
		"text":"Logout",
		"isAlreadyTranslated": true
	},
	"Close":{
		"text":"ปิด",
		"isAlreadyTranslated": true
	},
	"Name":{
		"text":"ชื่อ",
		"isAlreadyTranslated": true
	},
	"Select an engineer":{
		"text":"เลือกชื่อช่าง",
		"isAlreadyTranslated": true
	},
	"Something went wrong":{
		"text":"บางอย่างผิดพลาด",
		"isAlreadyTranslated": true
	},
	"Back to all requests":{
		"text":"กลับไปร้องขอทั้งหมด",
		"isAlreadyTranslated": true
	},
	"Enter Location":{
		"text":"ใส่สถานที่ตั้ง",
		"isAlreadyTranslated": true
	},
	"Characters must be numeric.":{
		"text":"ตัวละครต้องเป็นตัวเลข",
		"isAlreadyTranslated": true
	},
	"Full name":{
		"text":"ชื่อ นามสกุล",
		"isAlreadyTranslated": true
	},
	"Characters must be alphabets (at least 2 alphabets are mandatory)":{
		"text":"ตัวละครจะต้องเป็นตัวอักษร (อย่างน้อย 2 ตัวอักษรที่มีผลบังคับ)",
		"isAlreadyTranslated": true
	},
	"Invalid email id":{
		"text":"ID อีเมลไม่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Add a Product":{
		"text":"เพิ่มสินค้า",
		"isAlreadyTranslated": true
	},
	"Search consumer products":{
		"text":"ค้นหาผลิตภัณฑ์ของผู้บริโภค",
		"isAlreadyTranslated": true
	},
	"Characters must be alphabets":{
		"text":"ตัวละครจะต้องเป็นตัวอักษร",
		"isAlreadyTranslated": true
	},
	"No serviceable consumer product found":{
		"text":"ไม่พบผลิตภัณฑ์ที่ค้นหา",
		"isAlreadyTranslated": true
	},
	"Select a Brand":{
		"text":"เลือกยี่ห้อ",
		"isAlreadyTranslated": true
	},
	"Search brands":{
		"text":"ค้นหายี่ห้อ",
		"isAlreadyTranslated": true
	},
	"Select a Subcategory":{
		"text":"เลือกหมวดหมู่ย่อย",
		"isAlreadyTranslated": true
	},
	"Search subcategories":{
		"text":"หมวดหมู่ย่อยการค้นหา",
		"isAlreadyTranslated": true
	},
	"OR":{
		"text":"หรือ",
		"isAlreadyTranslated": true
	},
	"Enter Serial number":{
		"text":"ใส่หมายเลขผลิตภัณฑ์",
		"isAlreadyTranslated": true
	},
	"Search products":{
		"text":"ค้นหาผลิตภัณฑ์",
		"isAlreadyTranslated": true
	},
	"Add Selected Product":{
		"text":"เพิ่มผลิตภัณฑ์ที่เลือก",
		"isAlreadyTranslated": true
	},
	"Characters must be alphanumeric":{
		"text":"ตัวละครจะต้องเป็นตัวอักษรและตัวเลข",
		"isAlreadyTranslated": true
	},
	"Device In Warranty?":{
		"text":"อยู่ในการรับประกันหรือไม่",
		"isAlreadyTranslated": true
	},
	"Is Warranty Applicable for this request?":{
		"text":"อะไหล่อยู่ในการรับประกันใช่หรือไม่",
		"isAlreadyTranslated": true
	},
	"Selected Product":{
		"text":"เลือกสินค้า",
		"isAlreadyTranslated": true
	},
	"Contract Type":{
		"text":"ประเภทสัญญา",
		"isAlreadyTranslated": true
	},
	"Select a Type":{
		"text":"เลือกประเภท",
		"isAlreadyTranslated": true
	},
	"Select a Plan":{
		"text":"เลือกแผน",
		"isAlreadyTranslated": true
	},
	"Enter Date of Purchase":{
		"text":"ใส่วันที่ซื้อ",
		"isAlreadyTranslated": true
	},
	"Enter Activation Code":{
		"text":"ใส่รหัสการใช้งาน",
		"isAlreadyTranslated": true
	},
	"Resend Activation Code":{
		"text":"ส่งรหัสอีกครั้งการเปิดใช้งาน",
		"isAlreadyTranslated": true
	},
	"Please Select Purchase Date":{
		"text":"กรุณาเลือกวันที่ซื้อ",
		"isAlreadyTranslated": true
	},
	"Date of Damage":{
		"text":"วันที่ของความเสียหาย",
		"isAlreadyTranslated": true
	},
	"Please Select Damage Date":{
		"text":"กรุณาเลือกวันที่ความเสียหาย",
		"isAlreadyTranslated": true
	},
	"Time of Damage":{
		"text":"ช่วงเวลาของความเสียหาย",
		"isAlreadyTranslated": true
	},
	"Please Select Time":{
		"text":"กรุณาเลือกเวลา",
		"isAlreadyTranslated": true
	},
	"Select type of damage":{
		"text":"เลือกประเภทของความเสียหาย",
		"isAlreadyTranslated": true
	},
	"Please Select Damage type":{
		"text":"กรุณาเลือกประเภทความเสียหาย",
		"isAlreadyTranslated": true
	},
	"Please Enter Place of Damage":{
		"text":"กรุณาใส่สถานที่ได้รับความเสียหาย",
		"isAlreadyTranslated": true
	},
	"Is your device switching ON":{
		"text":"เป็นอุปกรณ์ของคุณเปิด",
		"isAlreadyTranslated": true
	},
	"Please Select an option":{
		"text":"โปรดเลือกตัวเลือก",
		"isAlreadyTranslated": true
	},
	"Alternate Mobile Number":{
		"text":"สลับหมายเลขโทรศัพท์มือถือ",
		"isAlreadyTranslated": true
	},
	"Please enter correct mobile Number":{
		"text":"กรุณากรอกหมายเลขโทรศัพท์มือถือที่ถูกต้อง",
		"isAlreadyTranslated": true
	},
	"Detail Description of Damage":{
		"text":"รายละเอียดของความเสียหาย",
		"isAlreadyTranslated": true
	},
	"Submit":{
		"text":"เสนอ",
		"isAlreadyTranslated": true
	},
	"Select Customer Type":{
		"text":"เลือกประเภทของลูกค้า",
		"isAlreadyTranslated": true
	},
	"Walk-in Customer":{
		"text":"Walk-in ของลูกค้า",
		"isAlreadyTranslated": true
	},
	"Future Appointment":{
		"text":"การแต่งตั้งในอนาคต",
		"isAlreadyTranslated": true
	},
	"Get the device delivered to the same address.":{
		"text":"ได้รับอุปกรณ์ที่จัดส่งไปยังที่อยู่เดียวกัน",
		"isAlreadyTranslated": true
	},
	"Proceed":{
		"text":"ดำเนิน",
		"isAlreadyTranslated": true
	},
	"Select issues":{
		"text":"เลือกอาการเสีย",
		"isAlreadyTranslated": true
	},
	"Search issues":{
		"text":"ค้นหาอาการเสีย",
		"isAlreadyTranslated": true
	},
	"Please enter issue description":{
		"text":"โปรดป้อนคำอธิบายปัญหา",
		"isAlreadyTranslated": true
	},
	"Cannot select more than":{
		"text":"ไม่สามารถเลือกได้มากกว่า",
		"isAlreadyTranslated": true
	},
	"Select a Reward":{
		"text":"เลือกรางวัล",
		"isAlreadyTranslated": true
	},
	"Instructions":{
		"text":"คำแนะนำ",
		"isAlreadyTranslated": true
	},
	"Okay, Got it":{
		"text":"เอาล่ะเข้าใจแล้ว",
		"isAlreadyTranslated": true
	},
	"Order ID Details":{
		"text":"สั่งซื้อรายละเอียดรหัส",
		"isAlreadyTranslated": true
	},
	"Enter Order ID":{
		"text":"ใส่รหัสการสั่งซื้อ",
		"isAlreadyTranslated": true
	},
	"IMEI Number":{
		"text":"หมายเลข IMEI",
		"isAlreadyTranslated": true
	},
	"Enter IMEI number":{
		"text":"ใส่หมายเลข IMEI",
		"isAlreadyTranslated": true
	},
	"IMEI number":{
		"text":"หมายเลข IMEI",
		"isAlreadyTranslated": true
	},
	"Normal Repair":{
		"text":"ซ่อม",
		"isAlreadyTranslated": true
	},
	"The request was raised successfully":{
		"text":"ขอยกประสบความสำเร็จ",
		"isAlreadyTranslated": true
	},
	"พยายามโทร":{
		"text":"พยายามโทร",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to receive device and complete mandatory checklist tasks.":{
		"text":"รอให้ผู้บริหารดูแลลูกค้าที่จะได้รับอุปกรณ์และเสร็จงานรายการตรวจสอบการบังคับใช้",
		"isAlreadyTranslated": true
	},
	"Waiting for CC to receive device and complete mandatory checklist tasks.":{
		"text":"รอให้ซีซีจะได้รับอุปกรณ์และเสร็จงานรายการตรวจสอบการบังคับใช้",
		"isAlreadyTranslated": true
	},
	"Are you sure? Please specify remarks, if any...":{
		"text":"โปรดระบุหมายเหตุ",
		"isAlreadyTranslated": true
	},
	"Waiting for engineer to accept the job. (Eligible for DOA)":{
		"text":"รอให้วิศวกรที่จะยอมรับงาน (ที่มีสิทธิ์สำหรับกรมวิชาการเกษตร)",
		"isAlreadyTranslated": true
	},
 	"(Eligible for DOA)":{
 		"text":" (ที่มีสิทธิ์สำหรับกรมวิชาการเกษตร)",
 		"isAlreadyTranslated": true
	},
	"Battery back up issue":{
		"text":"กลับขึ้นปัญหาแบตเตอรี่",
		"isAlreadyTranslated": true
	},
	"This service is a Return without repair (RWR)":{
		"text":"บริการนี้เป็นผลตอบแทนโดยไม่ต้องซ่อมแซม (RWR)",
		"isAlreadyTranslated": true
	},
	"Service cancelled":{
		"text":"บริการยกเลิก",
		"isAlreadyTranslated": true
	},
	"Front camera issue":{
		"text":"ปัญหาเกี่ยวกับกล้องหน้า",
		"isAlreadyTranslated": true
	},
	"Waiting for Estimation Approval. Click on \"Send for approval\" in \"Parts and Estimation\" section below.":{
		"text":"รอการอนุมัติการประเมิน คลิกที่ \"ส่งเพื่อขออนุมัติ \" ใน \"อะไหล่และการประเมินค่า\" ส่วนด้านล่าง",
		"isAlreadyTranslated": true
	},
	"Devices crashes/Auto shutdown in the pocket":{
		"text":"อุปกรณ์ล่ม / ปิดอัตโนมัติในกระเป๋า",
		"isAlreadyTranslated": true
	},
	"Touch with LCD Red Black":{
		"text":"สัมผัสกับจอแอลซีดีสีแดงสีดำ",
		"isAlreadyTranslated": true
	},
	"Defective Serial No":{
		"text":"มีข้อบกพร่องไม่มี Serial",
		"isAlreadyTranslated": true
	},
	"Defective IMEI":{
		"text":"มีข้อบกพร่อง IMEI",
		"isAlreadyTranslated": true
	},
	"Replaced IMEI 1":{
		"text":"แทนที่ IMEI 1",
		"isAlreadyTranslated": true
	},
	"Replaced IMEI 2":{
		"text":"แทนที่ IMEI 2",
		"isAlreadyTranslated": true
	},
	"requested":{
		"text":"ร้องขอ",
		"isAlreadyTranslated": true
	},
	"Are you sure?":{
		"text":"คุณแน่ใจไหม?",
		"isAlreadyTranslated": true
	},
	"Please specify remarks, if any...":{
		"text":"โปรดระบุหมายเหตุถ้าใด ๆ ...",
		"isAlreadyTranslated": true
	},
	"Waiting for customer approval of estimation or override estimation approval in \"Parts and Estimation\" section below.":{
		"text":"รอการอนุมัติของลูกค้าประมาณค่าหรือแทนที่ได้รับการอนุมัติการประมาณค่าใน \"อะไหล่และการประเมินค่า\" ส่วนด้านล่าง",
		"isAlreadyTranslated": true
	},
	"Waiting for store to issue required parts in \"Parts Requested\" section below. Cannot complete repair with parts pending.":{
		"text":"กำลังรอสำหรับการจัดเก็บที่จะออกชิ้นส่วนที่จำเป็นใน \"อะไหล่ขอ\" ส่วนด้านล่าง ไม่สามารถดำเนินการซ่อมแซมส่วนที่ค้างอยู่",
		"isAlreadyTranslated": true
	},
	"pending":{
		"text":"อยู่ระหว่างดำเนินการ",
		"isAlreadyTranslated": true
	},
	"Waiting for Engineer to receive parts from store in \"Parts Requested\" section below.":{
		"text":"รอให้วิศวกรที่จะได้รับชิ้นส่วนจากร้านค้าใน \"อะไหล่ขอ\" ส่วนด้านล่าง",
		"isAlreadyTranslated": true
	},
	"issued":{
		"text":"ออก",
		"isAlreadyTranslated": true
	},
	"QC rejected, Work in progress":{
		"text":"QC ปฏิเสธการทำงานในความคืบหน้า",
		"isAlreadyTranslated": true
	},
	"OOW":{
		"text":"OOW",
		"isAlreadyTranslated": true
	},
	"PCB semi-finished product(Sub PCB)":{
		"text":"สินค้ากึ่งสำเร็จรูป PCB (PCB ย่อย)",
		"isAlreadyTranslated": true
	},
	"IW":{
		"text":"IW",
		"isAlreadyTranslated": true
	},
	"Front Image":{
		"text":"ภาพด้านหน้า",
		"isAlreadyTranslated": true
	},
	"Back Image":{
		"text":"ภาพด้านหลัง",
		"isAlreadyTranslated": true
	},
	"Top Image":{
		"text":"ภาพด้านบน",
		"isAlreadyTranslated": true
	},
	"Bottom Image":{
		"text":"ภาพด้านล่าง",
		"isAlreadyTranslated": true
	},
	"Left Image":{
		"text":"ภาพซ้าย",
		"isAlreadyTranslated": true
	},
	"Right Image":{
		"text":"ภาพขวา",
		"isAlreadyTranslated": true
	},
	"Customer ID":{
		"text":"รหัสลูกค้า",
		"isAlreadyTranslated": true
	},
	"Device Invoice":{
		"text":"ใบแจ้งหนี้อุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Cosmetic Status of Device":{
		"text":"สถานะของอุปกรณ์เครื่องสำอาง",
		"isAlreadyTranslated": true
	},
	"Approve Estimation":{
		"text":"อนุมัติการประเมิน",
		"isAlreadyTranslated": true
	},
	"Purchase Order Number":{
		"text":"หมายเลขคำสั่งซื้อ",
		"isAlreadyTranslated": true
	},
	"received":{
		"text":"ที่ได้รับ",
		"isAlreadyTranslated": true
	},
	"defective":{
		"text":"บกพร่อง",
		"isAlreadyTranslated": true
	},
	"Receiver issue":{
		"text":"ปัญหารีซีฟเวอร์",
		"isAlreadyTranslated": true
	},
	"Volume key issue":{
		"text":"ปัญหาปุ่มเสียง",
		"isAlreadyTranslated": true
	},
	"Accessory":{
		"text":"อุปกรณ์เสริม",
		"isAlreadyTranslated": true
	},
	"Receive Device": {
		"text": "รับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to receive device.": {
		"text": "รอแอดมินกดยืนยันการรับอุปกรณ์",
		"isAlreadyTranslated": true
	},
	"Inspect Device": {
		"text": "ตรวจสอบรายละเอียดตัวเครื่อง",
		"isAlreadyTranslated": true
	},
	"Waiting for the Customer Care Executive to complete mandatory checklist tasks.": {
		"text": "รอแอดมินดำเนินรายการตรวจสอบรายละเอียดตัวเครื่อง",
		"isAlreadyTranslated": true
	},
	"Device Value": {
        "text": "ค่าอุปกรณ์",
        "isAlreadyTranslated": true
    },
    "One time deductible": {  
        "text": "นำไปหักลดหย่อนได้ครั้งเดียว",
        "isAlreadyTranslated": true
    },
    "Is Manufacturer's Warranty Applicable": { 
        "text": "รับประกันของผู้ผลิตตามความเหมาะสม", 
        "isAlreadyTranslated": true
    },
    "Manufacturer's Warranty Status": { 
        "text": "การรับประกันของผู้ผลิต", 
        "isAlreadyTranslated": true
    },
    "Add Device Date of Purchase and Device Value in 'Device Details' below":
    {
        "text": "เพิ่มวันที่ซื้ออุปกรณ์และค่าอุปกรณ์ใน 'รายละเอียดอุปกรณ์' ด้านล่าง",
        "isAlreadyTranslated": true
    },
    "To proceed, update Device Date of Purchase and Device Value in 'Device Details' below.":
    {
        "text": "หากต้องการดำเนินการต่อ โปรดอัปเดตวันที่ซื้ออุปกรณ์และค่าอุปกรณ์ใน 'รายละเอียดอุปกรณ์' ด้านล่าง",
        "isAlreadyTranslated": true
    },
}
export default lang;