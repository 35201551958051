import React from 'react';
import {Modal} from 'sfy-react';

import SerialNumberTextBox from './SerialNumberTextBox';

import './SerialNumberModal.scss'

const SerialNumberModal=(props)=>{
    const {localString,showModal,closeModal} = props;
		return ( <>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal serialNumberModal'>
                <Modal.Header>
                    <h2> {localString("Approve Refund Request")} </h2>
                </Modal.Header>
                <Modal.Body>
                    <SerialNumberTextBox {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </> )
}
export default SerialNumberModal;