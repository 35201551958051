import React, { useEffect, useRef, useState } from 'react';

export default function GridActionBtn({row, scanQr, copyLink, publicUrl, localString }) {
	const [displayOptions, setDisplayOptions] = useState(false);
	const customDropdownRef = useRef(null);
	// const openStatusCheck = (row) => (row.status && (row.status === 'Open' || row.status === 'open'));
	
	useEffect(() => {
		const isClickedOutside = (e) => {
			if (displayOptions && customDropdownRef.current && !customDropdownRef.current.contains(e.target)) {
				setDisplayOptions(false)
			}
		}
		document.addEventListener('mousedown', isClickedOutside)
	
		return () => document.removeEventListener('mousedown', isClickedOutside)
	}, [displayOptions])

	return (
		<>
			<div
				// className={`vectorIcon ${(openStatusCheck(row) && row.shortUrl) ? '' : 'disabled'}`}
				className={`vectorIcon ${row.shortUrl ? '' : 'disabled'}`}
				onClick={() => {
					// if(openStatusCheck(row) && row.shortUrl) {
					if(row.shortUrl) {
						setDisplayOptions(!displayOptions);
					}
				}}
				ref={customDropdownRef}
			>
				<img 
					src={publicUrl + "/imgs/vector.svg"} 
					height={'15px'}
					style={{ marginTop: '-9px' }}
				/>
				{displayOptions && <div className="customDropdownOptions">
					<div 
						className="customDropdownOption"
						onClick={() => scanQr(row)}
					>
						{localString('Scan QR Code')}
					</div>
					<div
						className="customDropdownOption"
						onClick={() => copyLink(row)}
					>
						{localString('Copy Link')}
					</div>
				</div>}
			</div>
		</>
	)
}