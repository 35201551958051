import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './UpdateJobModal.scss';
import UpdateJob from './UpdateJob';

const UpdateJobStatusModal=(props)=>{
    const {localString,backToJobUpdate,toResolvedDisposition,toUnresolvedDisposition, updateJobData,setUpdateJobData,fromJobVisitDispositionProd,fromJobVisitDisposition,consumerServiceRequest,time,getSource , getVisitDispositions, visitDispositionLoaded,showModal,closeModal,jobModalState,setJobModalState}=props;
    const [isAllTimeEntered,setIsAllTimeEntered]=useState(true);
    const lastDay = moment().subtract(1,'days').format("YYYY-MM-DD");
    useEffect(()=>{
            if(updateJobData.date && updateJobData.picker1BiginTimeVal && updateJobData.picker1EndTimeVal && updateJobData.picker2BiginTimeVal && updateJobData.picker2EndTimeVal){
                setIsAllTimeEntered(false);
            }else{
                setIsAllTimeEntered(true);
            }
    },[updateJobData])

    let filterInputs={
        datePicker:[
            {
			labelText: localString('Enter date'),
            placeholder:"Enter date",
			dataKey: "date",
			customClass:'col-sm-12 paddingB10 ',
			showFilter: true,
			type: 'DatePicker',
			maxDay: new Date(),
            minDay:moment(consumerServiceRequest && consumerServiceRequest.CreatedDate,'YYYY-MM-DDTHH:mm:ss.SSSZ').toDate(),
            oninputFunc: (val) => {
                    setUpdateJobData({ ...updateJobData, date:moment(val).format("DD/MM/YYYY") });
                }
            },
        ],
        details:[
            {
                mainlabelText:"Journey Details",
                labelText: localString('Begin time'),
                dataKey: 'picker1BiginTimeVal',
                placeholder:'Begin time',
                customClass:'col-sm-12 paddingB10 input-width',
                showFilter: true,
                type: 'DateTime',
                dateFormat: false,
                timeFormat: "HH:mm",
                maxDay: new Date(lastDay),
                oninputFunc: (val) => {
                    setUpdateJobData({ ...updateJobData, 
                        picker1BiginTimeVal:val,
                        picker1EndTimeVal:val,
                        picker2BiginTimeVal:val,
                        picker2EndTimeVal:val 
                    });
                }
            },
            {
                labelText: localString('End time'),
                dataKey: 'picker1EndTimeVal',
                placeholder:'End time',
                customClass:'col-sm-12 paddingB10 input-width',
                showFilter: true,
                type: 'DateTime',
                dateFormat: false,
                timeFormat: "HH:mm",
                maxDay: new Date(lastDay),
                oninputFunc: (val) => {
                    setUpdateJobData({ ...updateJobData, 
                        picker1EndTimeVal:val,
                        picker2BiginTimeVal:val,
                        picker2EndTimeVal:val 
                    });
                }
            },
            {
                mainlabelText:"Service Details",
                labelText: localString('Begin time'),
                placeholder:'Begin time',
                dataKey: 'picker2BiginTimeVal',
                customClass:' col-sm-12 paddingB10 input-width',
                showFilter: true,
                type: 'DateTime',
                dateFormat: false,
                timeFormat: "HH:mm",
                maxDay: new Date(lastDay),
                oninputFunc: (val) => {
                    setUpdateJobData({ ...updateJobData,  
                        picker2BiginTimeVal:val,
                        picker2EndTimeVal:val });
                }
            },
            {
                labelText: localString('End time'),
                dataKey: 'picker2EndTimeVal',
                placeholder:'End time',
                customClass:' input-width col-sm-12 paddingB10',
                showFilter: true,
                type: 'DateTime',
                dateFormat: false,
                timeFormat: "HH:mm",
                maxDay: new Date(lastDay),
                oninputFunc: (val) => {
                    setUpdateJobData({ ...updateJobData, picker2EndTimeVal:val });
                }
            },
        ]
    }
    return(
        <> 
            <UpdateJob
                contentHeader={localString("Update Job")}
                {...props} 
                setUpdateJobData={setUpdateJobData}
                updateJobData={updateJobData}
                filterInputs={filterInputs}
                disableApplyBtn={isAllTimeEntered}
                toResolvedDisposition={toResolvedDisposition}
                toUnresolvedDisposition={toUnresolvedDisposition}
            />
        </>
    )
}
export default  UpdateJobStatusModal;

