import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Dropdown, Loader } from 'sfy-react';
import Swal from "sweetalert2"; 

import { displayError } from '../../../Utility/Utils';


const AudienceSelection = (props) => {
	const { accessFn, localString, apiCall, retailerUsers, setRetailerUsers, notifyTo, setNotifyTo, notifyToLabel, setNotifyToLabel, pills, setPills, shopOptions, setShopOptions, saveNotificationData } = props;
	const [options, setOptions] = useState([
		{ name: 'All', value: 'All' },
		{ name: 'Salesman', value: 'All Advisor' },
		{ name: 'Shop', value: 'Shop Specific' },
		{ name: 'Supervisor', value: 'All Team Lead' },
		{ name: 'BranchCode', value: 'Branch Code' },
	]);
	const [loader, setLoader] = useState(false);
	const [shopLoader, setShopLoader] = useState(false);

	const removePillItem = (item) => {
		let arr = pills.filter(p => {
			if(notifyToLabel === 'Shop' && (item.displayDetails !== p.displayDetails)) {
				return p;
			}else if(notifyToLabel === 'BranchCode' && (item.value !== p.value)) {
				return p;
			}
		});
		setPills(arr);
	}

	const handleShopStateChange = (item) => {
		if((notifyToLabel === 'Shop' && pills.filter(k => k.displayDetails === item.displayDetails).length) || (notifyToLabel === 'BranchCode' && pills.filter(k => k.value === item.value).length)) {
			Swal.fire({  
				icon: 'error',  
				text: localString('Cannot select same state/shop again.'),  
			}); 
		}else{
			setPills([...pills, item]);
		}
	}

	const getAllUsersToSendNotif = () => {
		let reqObj = {};
		if(notifyToLabel && notifyToLabel === 'Shop') {
			reqObj.shopFilter = pills.map(k => k.ShopCode)
			reqObj.stateType = [];
		}else if(notifyToLabel && notifyToLabel === 'BranchCode') {
			reqObj.branchCodes = pills.map(k => k.value)
			reqObj.FilterType = "Branch";
		}else if(notifyToLabel && notifyToLabel !== 'All') {
			reqObj.Type = notifyToLabel
		}else {
			reqObj = {};
		}
		setLoader(true);
		apiCall({
			url: 'getAllUsersToSendNotif',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data){
					setRetailerUsers(result.data.AllSupervisors)
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: err => setLoader(false)
		});
	}

	useEffect(() => {
		getAllUsersToSendNotif();
	}, [notifyTo, pills]);

	const getAllShopsAndDistinctStates = () => {
		let reqObj = {};
		setShopLoader(true);
		apiCall({
			url: 'getAllShopsAndDistinctStates',
			data: reqObj,
			callBack: (result) => {
				setShopLoader(false); 
				if(result.success && result.data){
					let arr = result.data.shops && result.data.shops.map(v => ({...v, value: v.displayDetails}))
					setShopOptions(arr);
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: err => setShopLoader(false)
		});
	}
	
	useEffect(() => {
		notifyToLabel === 'Shop' && getAllShopsAndDistinctStates();
	}, [notifyTo]);

	const getBranchlist = () => {
		setShopLoader(true);
		apiCall({
			url: 'getBranchlist',
			data: {},
			callBack: (result) => {
				setShopLoader(false); 
				if(result.success && result.data){
					let arr = result.data.map(v => ({value: v}))
					setShopOptions(arr);
				}else{
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					}); 
				}
			},
			errorCB: err => setShopLoader(false)
		});
	}

	useEffect(() => {
		notifyToLabel === 'BranchCode' && getBranchlist();
	}, [notifyTo]);

	return (
		<>  
			{
				(loader || shopLoader) && ReactDOM.createPortal(
					<Loader />,
					document.getElementById("loader-root")
				)
			}
			<div className='container-fluid announcementAudience' style={{ marginTop: '50px', padding: '0 40px'}}>
				<div className='container text-center'>
					<div className='col-sm-12'>
						<div className='text-capital font-half data'>{localString('POTENTIAL REACH')}</div>
						<div className='font-large primary number'>{retailerUsers.length ? retailerUsers.length : 0}</div>
						<div className='font-quarter text-capital extra'>{localString('PERSONNEL')}</div>
					</div>
				</div>
				<br/>
				{   
					!accessFn('ENGINEER_NOTIFICATION') && 
					<><div className='row'>
						<div className='col-sm-4 padding12 audienceDropdown'>
							<Dropdown
								// id='dropdown'
								value={notifyTo}
								options={options}
								handleOnChange={(data) => {
									setNotifyTo(data.value);
									setNotifyToLabel(data.name)
									setPills([]);
								}}
								filterBy='value'
								showDownArrow={true}
								// placeholder={''}
							/>
						</div>
						<div className='col-sm-4 padding12 audienceDropdown'>
							<Dropdown
								// id='dropdown'
								// value={''}
								options={shopOptions}
								handleOnChange={handleShopStateChange}
								filterBy='value'
								showDownArrow={false}
								// searchText={filter.searchText}
								isDisabled={!notifyToLabel || notifyToLabel === 'All' || notifyToLabel === 'Salesman' || notifyToLabel === 'Supervisor'}
								placeholder={'Type State/Shop'}
							/>
						</div>                
					</div> 
					<div>
						<div className="col-sm-12 states-added-margin" id="statesAdded">
							{/* <div className="empty-message">
								<p>No states found</p>
							</div> */}
							<div className="row box-margin-top pillsContainer">
								{
									pills.length > 0 && pills.map((pill, k) => {
										return (
											<div className="col-sm-3">
												<div className="row pills">
													<div className="col-sm-10 pillLabel">
														{ localString(notifyToLabel === 'Shop' ? pill.displayDetails : pill.value) }
													</div>
													<div className="col-sm-1 cross" onClick={() => removePillItem(pill)}>
														<p className="glyphicon glyphicon-remove"></p>
													</div>
												</div>
											</div>
										);
									}) 
								}
							</div>
						</div>
						<div className="row">
							<div className="col-sm-12">
								<div>
									<Button
										type="button"
										className="btn button-servify pull-right box-margin-top"
										onClick={(e) => {
											saveNotificationData(1);
										}}
									>
										{localString('Next')}
									</Button>
								</div>
							</div>
						</div>
					</div></>
				}
			</div>
		</>
	);
}

export default AudienceSelection;