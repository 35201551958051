import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, Input } from 'sfy-react';
import Datetime from 'react-datetime';
import Swal from 'sweetalert2';

import { checkMobileNumber, displayError, generateUUID } from '../../../Utility/Utils';
import LocationAutoComplete from '../../../components/LocationAutoComplete/LocationAutoComplete';

export default function ClaimForm(props) {
	const { 
		apiCall, claimForm, claimFormData, plan, coverage, consumerProduct, setClaimFormData, setShowClaimForm,
		localString, setLoader, mobileMaxLength, damageTypeObj, setDamageTypeObj, setShowSecCommunicationDetails,
		docServiceUrl, useDocServiceTheft, authorization, hideSection, country
	} = props;
	const [error, setError] = useState({
		purchaseDate: null,
		damageDate: null,
		damageTime: null,
		damageType: null,
		damagePlace: null,
		deviceOn: null,
		incidentDamagePlace: null,
		alternateNumber: null,
		damageDetails: null,
		theftDate: null,
		policeDate: null,
		policeFile: null,
		policeNumber: null,
		theftPlace: null,
		theftDetails: null
	});

	const [minDamageDetailsChar, setMinDamageDetailsChar] = useState(30);
	const [damageTypeList, setDamageTypeList] = useState([]);
	const [policeFile, setPoliceFile] = useState(null);

	const updateAddress = (placesObj, field) => {
		if(placesObj && placesObj.formatted_address) {
			let obj = {
				landmark: placesObj.formatted_address
			};
			placesObj.address_components && placesObj.address_components.map(place => {
				// if (place && place.types.includes('postal_code') && addressConfigArr.find((obj) => obj.keyName == 'Zipcode')) {
				// 	addressObj.Zipcode = place.short_name ? place.short_name : ''
				// 	let obj = validateFields('Zipcode', addressObj.Zipcode, true);
				// 	errorsObj.Zipcode = obj.msg;
				// }

				// if (place && place.types.includes('postal_code') && addressConfigArr.find((obj) => obj.keyName == 'PinCode')) {
				// 	addressObj.PinCode = place.short_name ? place.short_name : ''
				// 	let obj = validateFields('PinCode', addressObj.PinCode, true);
				// 	errorsObj.PinCode = obj.msg;
				// }

				if (place && place.types.includes('country')) {
					obj.Country = place.short_name ? place.short_name : ''
				}

				// if (place && place.types.includes('administrative_area_level_1') && addressConfigArr.find((obj) => obj.keyName == 'State')) {
				// 	if(place && place.long_name && checkStateValid(place.long_name)) {
				// 		addressObj.State = place.long_name;
				// 	}else {
				// 		addressObj.State = '';
				// 	}
				// 	let obj = validateFields('State', addressObj.State, true);
				// 	errorsObj.State = obj.msg;
				// }

				// if (place.types.includes('locality') && addressConfigArr.find((obj) => obj.keyName == 'City')) {
				// 	addressObj.City = place.long_name ? place.long_name : ''
				// 	let obj = validateFields('City', addressObj.City, true);
				// 	errorsObj.City = obj.msg;
				// }
			});
			obj.lat = placesObj.geometry.location.lat();
			obj.lng = placesObj.geometry.location.lng();
			setClaimFormData({ ...claimFormData, [field]: placesObj?.formatted_address, locationOfDamage: obj});
		} else {
			setClaimFormData({ ...claimFormData, [field]: '', locationOfDamage: ''});
		}
	}


	const getDamageTypes = () => {
		setLoader(true)
		// let reqObj = {
		// 	ConsumerID: consumerProduct.ConsumerID,
		// 	SoldPlanCoverageID: coverage.SoldPlanCoverageID,
		// 	SoldPlanID: plan.SoldPlanID,
		// 	fetchPlanSpecificData: true
		// }
		let reqObj = {
			consumerProductID: consumerProduct.ConsumerProductID,
			soldPlanID: plan.SoldPlanID,
			soldPlanCoverageID: coverage.SoldPlanCoverageID,
			app: 'WebApp',
			countryCode: country ? country : 'in'
		}
		apiCall({
			url: 'getDamageTypes',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data){
					let arr = result.data.map(o => ({...o, value: o.displayText}));
					setDamageTypeList(arr);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString('No damage types found'),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

	useEffect(() => {
		if(claimForm && claimForm.RequiresTypeOfDamage) {
			getDamageTypes();
		}
	}, [claimForm]);

	const CustomOptionComponent = useCallback(
		(props) => (
			<span className='custom-dropdown-option'>
				{ props.option.icon && <img src={props.option.icon} alt='' width='20px' height='20px'/>}
				<span style={{ paddingLeft: '5px' }}>{props.option.value}</span>
			</span>
		),
		[]
	)

	const disableBtn = !claimForm || (!Object.keys(claimForm).length) || 
		((!claimForm.HideDevicePurchaseDate && !claimFormData.purchaseDate) || 
		(claimForm.RequiresDateOfDamage && !claimFormData.damageDate) ||
		(claimForm.RequiresDateOfTheft && !claimFormData.theftDate) ||
		(claimForm.RequiresTimeOfDamage && !claimFormData.damageTime) ||
		(claimForm.RequiresTypeOfDamage && !claimFormData.damageType) ||
		(claimForm.RequiresPoliceReportDate && !claimFormData.policeDate) ||
		(claimForm.RequiresPoliceReportUrl && !policeFile) ||
		(claimForm.RequiresPoliceReportNumber && !claimFormData.policeNumber) ||
		(claimForm.RequiresPlaceOfDamage && !claimFormData.damagePlace) ||
		(claimForm.RequiresPlaceOfDamage && (!claimFormData.damagePlace || !claimFormData.locationOfDamage.lat || !claimFormData.locationOfDamage.lng)) ||
		(claimForm.RequiresPlaceOfTheft && !claimFormData.theftPlace) ||
		(claimForm.RequiresDeviceSwitchingOnStatus && !claimFormData.deviceOn) ||
		(claimForm.RequiresPlaceOfDevice && !claimFormData.incidentDamagePlace) ||
		(claimForm.RequiresAlternateNumber && (claimFormData.alternateNumber && (!checkMobileNumber(claimFormData.alternateNumber) || !mobileMaxLength.includes(claimFormData.alternateNumber.length)))) ||
		(claimForm.RequiresDamageDescriptionText && (!claimFormData.damageDetails || claimFormData.damageDetails.length < minDamageDetailsChar)) || 
		(claimForm.RequiresTheftDetails && (!claimFormData.theftDetails || claimFormData.theftDetails.length < minDamageDetailsChar)));


	const submitClaimForm = () => {
		hideSection(['ReplacementReasons', 'IssuesSelection', 'OtherIssueTextBox', 'OtherReasonTextBox', 'SecCommunicationDetails', 'CarryInTypeSelection', 'Address', 'SecondaryAddress', 'SlotSelection', 'ServiceModes', 'ReplacementOptions', 'ConsentSection', 'NoService', 'ServiceLocationsCarryIn', 'ServiceLocationsDropOff', 'ExternalReferenceID', 'FMIPModal', 'LogisticsPartnerModal', 'IsSameAddress', 'InstructionsModal', 'PayoutModal', 'Activation'])
		if (claimForm.RequiresPoliceReportUrl) {
			if (!policeFile) {
				Swal.fire({  
					icon: 'error',  
					text: localString('Please upload a Police Report copy'),  
				});
				return;
			}
			let fileType = policeFile.type;
			let ext = fileType.split("/").length ? "."+fileType.split("/")[fileType.split("/").length - 1] : "";
			let key = "FEWEBA" + generateUUID() + ext;
			if (useDocServiceTheft) {
				if (docServiceUrl == '') {
					return;
				}
				let dataNew = new FormData();
				dataNew.append('file', policeFile);
				dataNew.append('docType', 'ConsumerServiceRequestDocument');
				dataNew.append('clientRefId', key);
				dataNew.append('entity-type', 'ConsumerProductID');
				dataNew.append('entity-id', consumerProduct.ConsumerProductID);
				let url = docServiceUrl + 'internal/document/upload';
				setLoader(true);
				fetch(url, {
					method: 'POST',
					headers: {
						'App': 'WebApp',
						'module': 'WebApp',
						'authorization': authorization
					},
					body: dataNew
				})
				.then(res => res.json())
				.then(result => {
					setLoader(false);
					if(result.success) {
						setClaimFormData({ ...claimFormData, policeFile: result.data.uploadURL });
						setShowClaimForm(false);
						setShowSecCommunicationDetails(true);
					}
				})
				.catch(err => {
					setLoader(false);
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(err)),  
					});
				})
			} else {
				setLoader(true); 
				let reqObj = {
					Type: fileType, 
					Key: key, 
					BrandID: consumerProduct && consumerProduct.BrandID ? consumerProduct.BrandID : undefined
				}
				apiCall({
					url:'uploadDocuments',
					data: reqObj,
					callBack: (result) => {
						// setLoader(false); 
						if(result.success){
							let url = result.data;
							fetch(url, {
								method: 'PUT',
								headers: {
									'Cache-Control': 'public,max-age=3600',
									'x-amz-acl': 'public-read'
								},
								body: policeFile
							})
							.then(res => {
								setLoader(false);
								if(res.status === 200) {
									// self.uploadURL = key;
									setClaimFormData({ ...claimFormData, policeFile: key });
									setShowClaimForm(false);
									setShowSecCommunicationDetails(true);
								}
							})
							.catch(err => {
								setLoader(false);
								Swal.fire({  
									icon: 'error',  
									text: localString(displayError(err)),  
								});
							})
						}else {
							Swal.fire({  
								icon: 'error',  
								text: localString(displayError(result)),  
							});
						}
					},
					errorCB: (err) => {
						setLoader(false);
						Swal.fire({  
							icon: 'error',  
							text: localString(displayError(err)),  
						});
					}
				});
			}
			
		}
		else {
			setShowClaimForm(false);
			setShowSecCommunicationDetails(true);
		}
	}

	return (
		<>
			<div id='ClaimForm' className="raiseReqSection" style={{ paddingBottom: '20px' }}>
				<div className='claimForm'>						
					<div className="row">
						<div className="col-sm-12">
							<div className="form-label">
								<label>{localString("Claim Form")}</label>
							</div>
						</div>
						{
							claimForm && !claimForm.HideDevicePurchaseDate && 
								<div className="col-sm-4">
									<div className="form-group raiseRequestDatePicker">
										<label>{localString("Date of Purchase")}*</label>
										<Datetime
											value={claimFormData.purchaseDate}
											inputProps={{
												onKeyDown: e => e.preventDefault()
											}}
											dateFormat={'DD-MM-YYYY'}
											timeFormat={false}
											onChange={(val) => {
												setClaimFormData({ ...claimFormData, purchaseDate: new Date(val) });
												if(!val) {
													setError({ ...error, purchaseDate: 'Please Select Purchase Date' });
												}else {
													setError({ ...error, purchaseDate: null });
												}
											}}
											isValidDate={(current) => current.isBefore(new Date())}
											closeOnSelect={true}
										/>
										{ error.purchaseDate && <span className="text-danger">{localString(error.purchaseDate)}</span>}
									</div>
								</div> 
						}
						{
							claimForm && claimForm.RequiresDateOfDamage && 
								<div className="col-sm-4">
									<div className="form-group raiseRequestDatePicker">
										<label>{localString("When did your device get damaged?")}*</label>
										<Datetime
											value={claimFormData.damageDate}
											inputProps={{
												onKeyDown: e => e.preventDefault()
											}}
											dateFormat={'DD-MM-YYYY'}
											timeFormat={false}
											onChange={(val) => {
												setClaimFormData({ ...claimFormData, damageDate: new Date(val) })
												if(!val) {
													setError({ ...error, damageDate: "Please Select Damage Date" });
												}else {
													setError({ ...error, damageDate: null });
												}
											}}
											isValidDate={(current) => current.isBefore(new Date())}
											closeOnSelect={true}
										/>
										{ error.damageDate && <span className="text-danger">{localString(error.damageDate)}</span>}
									</div>
								</div> 
						}
						{
							claimForm && claimForm.RequiresDateOfTheft && 
								<div className="col-sm-4">
									<div className="form-group raiseRequestDatePicker">
										<label>{localString("When did your device get stolen?")}*</label>
										<Datetime
											value={claimFormData.theftDate}
											inputProps={{
												onKeyDown: e => e.preventDefault()
											}}
											dateFormat={'DD-MM-YYYY'}
											timeFormat={false}
											onChange={(val) => {
												setClaimFormData({ ...claimFormData, theftDate: new Date(val) })
												if(!val) {
													setError({ ...error, theftDate: "Please Select Theft Date" });
												}else {
													setError({ ...error, theftDate: null });
												}
											}}
											isValidDate={(current) => current.isBefore(new Date())}
											closeOnSelect={true}
										/>
										{ error.theftDate && <span className="text-danger">{localString(error.theftDate)}</span>}
									</div>
								</div> 
						}
						{
							claimForm && claimForm.RequiresTimeOfDamage && 
								<div className="col-sm-4">
									<div className="form-group raiseRequestDatePicker">
										<label>{localString("Time of Damage")}*</label>
										<Datetime
											value={claimFormData.damageTime}
											inputProps={{
												onKeyDown: (e) => e.preventDefault()
											}}
											dateFormat={false}
											timeFormat={'HH:mm'}
											onChange={(val) => {
												setClaimFormData({ ...claimFormData, damageTime: new Date(val) });
												if(!val) {
													setError({ ...error, damageTime: "Please Select Time" });
												}else {
													setError({ ...error, damageTime: null });
												}
											}}
										/>
										{ error.damageTime && <span className="text-danger">{localString(error.damageTime)}</span>}
									</div>
								</div> 
						}
						{
							claimForm && claimForm.RequiresTypeOfDamage && 
								<div className="col-sm-4">
									<div className="form-group">
										<label>{localString("Select Damage Type")}*</label>
										<Dropdown
											value={claimFormData.damageType}
											options={damageTypeList}
											handleOnChange={(data) => {
												setDamageTypeObj(data)
												setClaimFormData({ ...claimFormData, damageType: data.value })
												if(!data) {
													setError({ ...error, damageType: "Please Select Damage type" });
												}else {
													setError({ ...error, damageType: null });
												}
											}}
											filterBy='value'
											showDownArrow={false}
                                            OptionsComponent={CustomOptionComponent}
										/>
										{ error.damageType && <span className="text-danger">{localString(error.damageType)}</span>}
									</div>
								</div> 
						}
						{
							claimForm && claimForm.RequiresPoliceReportDate && // Theft flow
								<div className="col-sm-4">
									<div className="form-group raiseRequestDatePicker">
										<label>{localString("Police Report Date")}*</label>
										<Datetime
											value={claimFormData.policeDate}
											inputProps={{
												onKeyDown: e => e.preventDefault()
											}}
											dateFormat={'DD-MM-YYYY'}
											timeFormat={false}
											onChange={(val) => {
												setClaimFormData({ ...claimFormData, policeDate: new Date(val) })
												if(!val) {
													setError({ ...error, policeDate: "Please Select Theft Date" });
												}else {
													setError({ ...error, policeDate: null });
												}
											}}
											isValidDate={(current) => current.isBefore(new Date())}
											closeOnSelect={true}
										/>
										{ error.policeDate && <span className="text-danger">{localString(error.policeDate)}</span>}
									</div>
								</div> 
						}
						{
							claimForm && claimForm.RequiresPoliceReportUrl && 
								<div className="col-sm-4">
									<div className="form-group">
										<label>{localString("Police Report Image")}*</label>
										<Input
											inputType="file"
											handleOnChange={(e) => {
												if(e.target.files[0]) {
													setPoliceFile(e.target.files[0])
													setError({ ...error, policeFile: null });
												}else {
													setError({ ...error, policeFile: "Please Upload Police Report" });
												}
											}}
											className={{
												inputContainer: 'file-input-container input-container',
											}}
											otherProps={{
												accept: ".png,.jpg,.jpeg,.pdf"
											}}
										/>
										{ error.policeFile && <span className="text-danger">{localString(error.policeFile)}</span>}
									</div>
								</div> 
						}{
							claimForm && claimForm.RequiresPoliceReportNumber && 
								<div className="col-sm-4">
									<div className="form-group">
										<label>{localString("Police Report Number")}*</label>
										<Input
											value={claimFormData.policeNumber}
											handleOnChange={(e) => {
												setClaimFormData({ ...claimFormData, policeNumber: e.target.value });
												if(!e.target.value) {
													setError({ ...error, policeNumber: "Please Enter Police Report Number" });
												}else {
													setError({ ...error, policeNumber: null });
												}
											}}
											className={{
												containerClass: 'input-container',
												inputClass: 'input'
											}}
										/>
										{ error.policeNumber && <span className="text-danger">{localString(error.policeNumber)}</span>}
									</div>
								</div> 
						}
						{
							claimForm && claimForm.RequiresPlaceOfDamage && 
								<div className="col-sm-4">
									<div className="form-group">
										<label>{localString("Location of Incident")}*</label><span>({localString("City name/Short address")}) </span>
										<LocationAutoComplete
											{...props}
											event='RaiseRequestClaimForm'
											cid={consumerProduct.ConsumerID}
											value={claimFormData.damagePlace}
											className={{
												containerClass: 'input-container',
												inputClass: 'input'
											}}
											handleOnChange={(e) => {
												if(!e.target.value) {
													setError({ ...error, damagePlace: "Please Enter Place of Damage" });
													setClaimFormData({ ...claimFormData, damagePlace: '', locationOfDamage: {} });
												}else {
													setClaimFormData({ ...claimFormData, damagePlace: e.target.value, locationOfDamage: { ...claimFormData?.locationOfDamage, landmark: e.target.value} });
													setError({ ...error, damagePlace: null });
												}
											}}
											handleSelection={(addressObj) => updateAddress(addressObj, 'damagePlace')}
											dependentStateArr={[claimFormData]}
										/>
										{ error.damagePlace && <span className="text-danger">{localString(error.damagePlace)}</span>}
									</div>
								</div> 
						}
						{
							claimForm && claimForm.RequiresPlaceOfTheft && 
								<div className="col-sm-4">
									<div className="form-group">
										<label>{localString("Location of Incident")}*</label><span>({localString("City name/Short address")}) </span>
										<LocationAutoComplete
											event='RaiseRequestClaimForm'
											cid={consumerProduct.ConsumerID}
											value={claimFormData.theftPlace}
											className={{
												containerClass: 'input-container',
												inputClass: 'input'
											}}
											handleOnChange={(e) => {
												if(!e.target.value) {
													setError({ ...error, theftPlace: "Please Enter Place of Damage" });
													setClaimFormData({ ...claimFormData, theftPlace: '', locationOfDamage: {} });
												}else {
													setClaimFormData({ ...claimFormData, theftPlace: e.target.value, locationOfDamage: { ...claimFormData?.locationOfDamage, landmark: e.target.value} });
													setError({ ...error, theftPlace: null });
												}
											}}
											handleSelection={(addressObj) => updateAddress(addressObj, 'theftPlace')}
										/>
										{ error.theftPlace && <span className="text-danger">{localString(error.theftPlace)}</span>}
									</div>
								</div> 
						}
						{
							claimForm && claimForm.RequiresDeviceSwitchingOnStatus && 
								<div className="col-sm-4">
									<div className="form-group">
										<label>{localString("Is your device switching ON")}?*</label>
										<Dropdown
											value={claimFormData.deviceOn}
											options={[{ value: 'Yes' }, { value: 'No' }]}
											handleOnChange={(data) => {
												setClaimFormData({ ...claimFormData, deviceOn: data.value })
												if(!data) {
													setError({ ...error, deviceOn: "Please Select an option" });
												}else {
													setError({ ...error, deviceOn: null });
												}
											}}
											filterBy='value'
											showDownArrow={false}
										/>
										{ error.deviceOn && <span className="text-danger">{localString(error.deviceOn)}</span>}
									</div>
								</div> 
						}
						{
							claimForm && claimForm.RequiresPlaceOfDevice && 
								<div className="col-sm-4">
									<div className="form-group">
										<label>{localString("Where was the device kept at the time of incident")}?*</label>
										<Input
											value={claimFormData.incidentDamagePlace}
											handleOnChange={(e) => {
												setClaimFormData({ ...claimFormData, incidentDamagePlace: e.target.value });
												if(!e.target.value) {
													setError({ ...error, incidentDamagePlace: "Please Enter Incident Place of Damage" });
												}else {
													setError({ ...error, incidentDamagePlace: null });
												}
											}}
											className={{
												containerClass: 'input-container',
												inputClass: 'input'
											}}
										/>
										{ error.incidentDamagePlace && <span className="text-danger">{localString(error.incidentDamagePlace)}</span>}
									</div>
								</div> 
						}
						{
							claimForm && claimForm.RequiresAlternateNumber && 
								<div className="col-sm-4">
									<div className="form-group">
										<label>{localString("Alternate Mobile Number")}</label>
										<Input
											value={claimFormData.alternateNumber}
											handleOnChange={(e) => {
												setClaimFormData({ ...claimFormData, alternateNumber: e.target.value });
												if(e.target.value && (!mobileMaxLength.includes(e.target.value.length) || !checkMobileNumber(e.target.value))) {
													setError({ ...error, alternateNumber: "Please enter correct mobile Number" });
												}else {
													setError({ ...error, alternateNumber: null });
												}
											}}
											maxLength={mobileMaxLength && mobileMaxLength.length ? mobileMaxLength[0] : null}
											className={{
												containerClass: 'input-container',
												inputClass: 'input'
											}}
										/>
										{ error.alternateNumber && <span className="text-danger">{localString(error.alternateNumber)}</span>}
									</div>
								</div> 
						}
						{
							claimForm && claimForm.RequiresDamageDescriptionText && 
								<div className="col-sm-12">
									<div className="form-group">
										<label>{localString("Please tell us how was your device damaged")}*</label>
										<Input
											value={claimFormData.damageDetails}
											handleOnChange={(e) => {
												let val = (e.target.value).replace(/\s{2,}/gm, ' ') //.trim()
												setClaimFormData({ ...claimFormData, damageDetails: val });
												if(!val || (val && val.length < minDamageDetailsChar)) {
													setError({ ...error, damageDetails: "Please Enter Minimum 30 Characters" });
												}else {
													setError({ ...error, damageDetails: null });
												}
											}}
											inputComponent='textarea'
											className={{
												containerClass: 'input-container',
												inputClass: 'input'
											}}
										/>
										{ error.damageDetails && <span className="text-danger">{localString(error.damageDetails)}</span>}
									</div>
								</div> 
						}
						{
							claimForm && claimForm.RequiresTheftDetails && 
								<div className="col-sm-12">
									<div className="form-group">
										<label>{localString("Please tell us how was your device stolen")}*</label>
										<Input
											value={claimFormData.theftDetails}
											handleOnChange={(e) => {
												let val = (e.target.value).replace(/\s{2,}/gm, ' ') //.trim()
												setClaimFormData({ ...claimFormData, theftDetails: val });
												if(!val || (val && val.length < minDamageDetailsChar)) {
													setError({ ...error, theftDetails: "Please Enter Minimum 30 Characters" });
												}else {
													setError({ ...error, theftDetails: null });
												}
											}}
											inputComponent='textarea'
											className={{
												containerClass: 'input-container',
												inputClass: 'input'
											}}
										/>
										{ error.theftDetails && <span className="text-danger">{localString(error.theftDetails)}</span>}
									</div>
								</div> 
						}
						<div className='col-sm-12'>
							<Button 
								className="btn button-servify"
								onClick={() => submitClaimForm()}
								isDisabled={disableBtn}
							>
								{localString("Submit")}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}