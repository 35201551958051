import React, { useEffect } from 'react';
import { Button } from 'sfy-react';

export default function CarryInTypeSelection(props) {
    const { localString, carryInType, setCarryInType, carryInCustomerTypeSelected, type = 'raiseRequest', walkIn } = props;

	useEffect(() => {
		if(type == 'adminRaiseRequest') {
			setCarryInType && setCarryInType('');
		}
	}, [])

    return (
        <>
            <div id='CarryInTypeSelection' className="raiseReqSection">
				<div className='carryInTypeSelection'>						
					<div className="form-label">
						<label>{localString("Select Customer Type")}</label>
					</div>
					{ (type == 'raiseRequest') && <div className="row" style={{ paddingLeft: '35px', paddingBottom: '20px' }}>
						<div className="col-sm-4 padding-left50">
							<div className="radio">
								<input
									type="radio"
									name="carryInTypeRadio"
									id="radio_walkin"
									onClick={() => carryInCustomerTypeSelected('radio_walkin')}
									checked={carryInType === 'radio_walkin'}
								/>
								<label for="radio_walkin">{localString("Walk-in Customer")}</label>
							</div>
							<div className="radio">
								<input
									type="radio"
									name="carryInTypeRadio"
									id="radio_future"
									onClick={() => carryInCustomerTypeSelected('radio_future')}
									checked={carryInType === 'radio_future'}
								/>
								<label for="radio_future">{localString("Future Appointment")}</label>
							</div>
						</div>
					</div> }
					{ (type == 'adminRaiseRequest') && <div className="row" style={{ paddingLeft: '60px', paddingBottom: '20px' }}>
						<div className="col-sm-4 padding-left50">
							<div className="radio">
								<input
									type="radio"
									name="carryInTypeRadio"
									id="radio_walkin"
									onClick={() => setCarryInType('radio_walkin')}
									checked={carryInType === 'radio_walkin'}
								/>
								<label for="radio_walkin">{localString("Walk-in Customer")}</label>
							</div>
						</div>
						<div className="col-sm-4 padding-left50">
							<div className="radio">
								<input
									type="radio"
									name="carryInTypeRadio"
									id="radio_future"
									onClick={() => setCarryInType('radio_future')}
									checked={carryInType === 'radio_future'}
								/>
								<label for="radio_future">{localString("Future Appointment")}</label>
							</div>
						</div>
					</div> }
				</div>
				{ type && (type == 'adminRaiseRequest') && (carryInType === 'radio_walkin') && carryInType &&
                    <div className='pull-right' style={{ 'paddingBottom': '10px', 'paddingRight': '10px' }}>
                        <Button 
                            className="btn button-servify no-border-radius"
                            onClick={() => carryInCustomerTypeSelected('radio_walkin')}
                        >
                            {localString("Raise Request")}
                        </Button>
                    </div>
            	}
				{ type && (type == 'adminRaiseRequest') && (carryInType === 'radio_future') && carryInType &&
                    <div className='pull-right' style={{ 'paddingBottom': '10px', 'paddingRight': '10px' }}>
                        <Button 
                            className="btn button-servify no-border-radius"
                            onClick={() => carryInCustomerTypeSelected('radio_future')}
                        >
                            {localString("Next")}
                        </Button>
                    </div>
            	}
			</div>
        </>
    );
}