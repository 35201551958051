import React from 'react';
import {Button,Table} from 'sfy-react';
import './RetailerReset.scss'

const HandsetAdvisorDetails = (props) => {
    const {advisorDetailsArray,localString,resetSucessful,showResetButton,retailerHandsetReset,advisorLabel}=props;
	return (
		<form className="fluid-form-content fluid-form-active">
			<div className="row">
				<div className="col-md-12 custom-align">
					<div className="request-panel-col handset-advisor">
						<div className="request-panel-header">
							<p>{localString("Handset Details")}</p>
						</div>
						<div className="request-panel">
							<Table className="complaint-details-table table table-striped">
								<Table.Body>
								{
									advisorDetailsArray && advisorDetailsArray.map(data=>{
										return(
											data.Value && 
											<Table.Row>
												<Table.Data className="table-keys">{data.Name}</Table.Data>
												<Table.Data>{data.Value}</Table.Data>
											</Table.Row>
										)
									})
								}
								</Table.Body>
							</Table>
							<div>
								{
									showResetButton && <Button  text={localString('Reset')} className='btn button-servify pull-right' onClick={retailerHandsetReset}></Button>
								}
								{
									resetSucessful && <h2>{localString("Handset reset has already been done. Please inform the corresponding")} {advisorLabel} {localString("to login Mobile COS.")}</h2>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	)
}

export default HandsetAdvisorDetails
