import React, { useState } from "react";
import { Modal, Input, Button } from "sfy-react";
import Swal from "sweetalert2";
import moment from "moment";
import Datetime from 'react-datetime';

import "./MapGNumberModal.scss";

const MapGNumberModal = (props) => {
	const {
		localString,
		showModal,
		closeModal,
		setLoader,
		getRequestById,
		apiCall,
		accessFn,
		consumerServiceRequest,
		appleDropOffFlow
	} = props;
	
	const [GNumber, setGNumber] = useState("");
	const [unitReceivedDateTime, setUnitReceivedDateTime] = useState("");
	const maxDate = new Date();

	const closeGnumberModal = () => {
		setGNumber("");
		setUnitReceivedDateTime("");
		closeModal();
	};

	const mapGNumber = () => {
		if (!GNumber.trim()) {
			Swal.fire({
				title: "",
				text: localString("Please enter G Number."),
				icon: "error",
				confirmButtonText: localString("OK"),
			});
			return;
		}

		if (!unitReceivedDateTime) {
			Swal.fire({
				title: "",
				text: localString(
					"Please enter Device Recieved Date and Time ."
				),
				icon: "error",
				confirmButtonText: localString("OK"),
			});
			return;
		}

		let data = {};
		data["UnitReceivedDateTime"] = moment(
			unitReceivedDateTime,
			"YYYY-MM-DD HH:mm:ss"
		).format();
		data["repairConfirmationNumber"] = GNumber.trim();
		data["ConsumerServiceRequestID"] =
			consumerServiceRequest.ConsumerServiceRequestID;
		if(appleDropOffFlow && !consumerServiceRequest.ProvisionalGSXNumber) {
			data["isProvisionalGSXRepair"] = true;
		}
		setLoader(true);
		apiCall({
			url: "mapGsxRepairToRequest",
			data: data,
			callBack: (result) => {
				setLoader(false);
				if (result.success) {
					getRequestById();
					Swal.fire({
						title: "Success",
						text: localString("Your G Number has been mapped"),
						icon: "success",
						confirmButtonText: localString("OK"),
					});
					closeGnumberModal();
				} else {
					Swal.fire({
						title: "",
						text: localString(result.msg),
						icon: "error",
						confirmButtonText: localString("OK"),
					});
				}
			},
		});
	};

	return (
		<>
			<Modal
				showModal={showModal.showMapGNumberModal}
				closeModal={closeGnumberModal}
				showCloseBtn={true}
				className="mapG-modal"
			>
				<Modal.Header>
					<h2>
						{localString("Map")} G {localString("Number")}{" "}
					</h2>
				</Modal.Header>
				<Modal.Body>
					<div className="mapG-wrapper">
						<div className="row">
							<div className="col-sm-6">
								<div className="">
									<label>{appleDropOffFlow && !consumerServiceRequest.ProvisionalGSXNumber ? localString("Provisional G Number"):localString("G Numberr")}</label>
									<Input
										placeholder={localString("G Number")}
										id="GNumber"
										name="GNumber"
										inputType="text"
										otherProps={{
											required: true,
										}}
										value={GNumber || ""}
										handleOnChange={(e) => {
											setGNumber(e.target.value);
										}}
									/>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="mapGDatePicker" id="unitReceivedTimePicker">
									<label>{localString("Please Select Device Receiving Time")}</label>
									<Datetime
										value={unitReceivedDateTime}
										inputProps={{
											onKeyDown: e => e.preventDefault(),
											placeholder: 'YYYY-MM-DD HH:mm:ss'
										}}
										// dateFormat={'DD-MM-YYYY'}
										dateFormat={'YYYY-MM-DD'}
										timeFormat={true}
										onChange={(val) => {
											setUnitReceivedDateTime(new Date(val));
										}}
										isValidDate={(current) => current.isBefore(maxDate)}
										closeOnSelect={true}
									/>
								</div>
							</div>
						</div>
						<div className='pull-right' style={{ marginTop: "10px" }}>
							<Button 
								className="btn button-servify"
								onClick={() => mapGNumber()}
							>
								{localString("Save")}
							</Button>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
};

export default MapGNumberModal;
