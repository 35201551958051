import React,{useState,useEffect} from 'react';
import "./EmailHistory.scss"

const EmailHistory = (props) => {
	const {emailHistoryData,localString} = props;
	const [emailDetails, setEmailDetails] = useState()

	const getEmailData = () =>{
		let emailData = emailHistoryData && emailHistoryData.map((eData)=>{
			return [{
				labelText:"Ticket ID",
				value:(eData.TicketID ? eData.TicketID :"-"),
			},{
				labelText:"Status",
				value:(eData.Status ? eData.Status : "-"),
			},{
				labelText:"Priority",
				value:(eData.Priority? eData.Priority : "-"),
			},{
				labelText:"Source",
				value:eData.Source ? eData.Source : "-",
			},{
				labelText:"Agent",
				value:eData.AgentName ? eData.AgentName : "-",
			},{
				labelText:"Group",
				value:(eData.GroupName ? eData.GroupName : "-"),
			},{
				labelText:"Created Time",
				value:(eData.CreatedAt ? eData.CreatedAt : "-"),
			},{
				labelText:"Resolved Time",
				value:(eData.ResolvedAt ? eData.ResolvedAt : "-"),
			},{
				labelText:"Last Updated Time",
				value: eData.UpdatedAt ? eData.UpdatedAt : "-",
			},{
				labelText:"Initial Response Time",
				value:eData.InitialResponseTime ? eData.InitialResponseTime : "-",
			},{
				labelText:'First Response Time',
				value:(eData.FirstResponseTime ? eData.FirstResponseTime :"-"),
			},{
				labelText:"Resolution Time",
				value:(eData.ResolutionTime ? eData.ResolutionTime : "-"),
			},{
				labelText:"Agent Interaction",
				value:(eData.AgentInteraction ? eData.AgentInteraction :"-"),
			},{
				labelText:"Customer Interactions",
				value:(eData.CustomerInteractions ? eData.CustomerInteractions :"-"),
			},{
				labelText:"Resolution Status",
				value:(eData.ResolutionStatus ? eData.ResolutionStatus : "-"),
			},{
				labelText:"First Response Status",
				value:(eData.FirstResponseStatus ? eData.FirstResponseStatus : "-"),
			},{
				labelText:"Brand",
				value:(eData.Brand ? eData.Brand : "-"),
			},{
				labelText:"Product",
				value:(eData.Product ? eData.Product : "-"),
			},{
				labelText:"Ticket Type",
				value:(eData.TicketType ? eData.TicketType : "-"),
			},{
				labelText:"Request Type",
				value:(eData.RequestType ? eData.RequestType : "-"),
			},{
				labelText:"Bucket",
				value:(eData.Bucket ? eData.Bucket : "-"),
			},{
				labelText:"First Disposition",
				value:(eData.FirstDisposition ? eData.FirstDisposition : "-"),
			},{
				labelText:"Second Disposition",
				value:(eData.SecondDisposition ? eData.SecondDisposition : "-"),
			},{
				labelText:"Resolution Code International",
				value:(eData.ResolutionCodeInternational ? eData.ResolutionCodeInternational : "-"),
			},
			{
				labelText:"FD Link",
				value:<><a href={eData.Url} target="_blank" if={eData.Url}>{eData.Url}</a>
					<span if={eData.Url}>-</span></>
			}
			]
		})
		return emailData;
	}

	useEffect(() => {
		setEmailDetails(getEmailData());
	}, [emailHistoryData])

	return(
	<>
		{
		<div className="main">
			{
				emailDetails &&
				emailDetails.length>0 ? emailDetails.map(logs=>{
				return(
				<div className="communication-item">
					{
						logs && logs.map(inner=>{
							return(
							inner.value && 
							<div className="row">
								<div className="col-sm-4">
									<div>
										<label>{inner.labelText}:</label>
									</div>
								</div>
								<div className="col-sm-8">
									<div>{inner.value}</div>
								</div>
							</div>
							)
						})
					}
					</div>
					)
				})
				:
				<div className="communication-item">{localString("No Email History")}</div>
			}
		</div>
		}
	</>
	)
}

export default EmailHistory
