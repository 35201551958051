import React from 'react';
import { Button, Input } from 'sfy-react';

export default function ApprovalRemarks(props) {
    const {
        localString, approvalVariable, approvalRemarks, setApprovalRemarks, processRequestNext, closeModal
    } = props;

    const processApprovalForBullbox = () => {
        processRequestNext();
        closeModal();
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <label className="text-capitalize">
                        {localString("Reason for")} {approvalVariable} :
                    </label>
                    <div>
                        <Input
                            value={approvalRemarks}
                            handleOnChange={(e) => setApprovalRemarks(e.target.value)}
                            inputComponent='textarea'
                            className={{
                                inputContainer: 'heightAuto'
                            }}
                            otherProps={{
                                onKeyDown: (e) => {
                                    if(e && (e.key == 'Enter')) {
                                        processApprovalForBullbox();
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='pull-right mt-15'>
                <Button 
                    type='button'
                    className="btn button-servify"
                    onClick={() => processApprovalForBullbox()}
                    isDisabled={!approvalRemarks || !approvalRemarks.trim().length}
                >
                    {localString("Save")}
                </Button>
            </div>
        </>
    );
}