
import React,{useState, useEffect}  from 'react';
import ReactDOM from 'react-dom';
import FilterInputs from '../../../components/FilterInputs/FilterInput';
import {displayError} from '../../../Utility/Utils';
import { filter } from 'lodash';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import './CreateLogistics.scss';

const ProductDetailsInput = (props) => {
	const {localString,setCreateLogisticsData,createLogisticsData,qFilter,apiCall} = props;
	const [showLoader ,setLoader] = useState(false);
	const [brandData,setbrandData] = useState({});
	const [productSubCategoryData, setProductSubCategoryData] = useState({})
	const [productData,setProductData] = useState({});
	const [disableProduct,setDisableProduct] = useState(true);
	const [disableProductSubCategory,setDisableProductSubCategory] = useState(true);
	const [imeiErrorMessage,setImeiErrorMessage] = useState("");
	let currentreq = null;

	const loadBrands = () => {
		setLoader(true);
		let data = {}
		apiCall({
			url:'getBrandConfig',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let data = result.data.map(v => ({...v, value: v.BrandName}))
					if (qFilter && qFilter.brands && qFilter.brands.length > 0){
						data = filter(data,function(o){
							if (qFilter.brands.indexOf(o.BrandID) > -1) {
								return o
							}
						})
					}
					setCreateLogisticsData((prevData) => {
						return {
							...prevData,
							ProductSubCategoryID: '',
							ProductSubCategoryName:'',
						};
					});
					setbrandData(data);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const loadProductSubcategories = () => {
		setLoader(true);

		let data = {
			"qFilter":{
				"brands":[createLogisticsData.BrandID]
			}
		}

		apiCall({
			url:'getSubcategory',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let data = result.data.map(v => ({...v, value: v.ProductSubCategory}))
					setProductSubCategoryData(data);
					setCreateLogisticsData((prevData) => {
						return {
							...prevData,
							ProductSubCategoryID: '',
							ProductSubCategoryName:''
						};
					});
					setDisableProductSubCategory(false);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}


	const loadProducts = () => {
		setLoader(true);
		var data = {
			"qFilter": {
			  "productSubCategories": [createLogisticsData.ProductSubCategoryID],
			  "brands":[createLogisticsData.BrandID]
			}
		}
		apiCall({
			url:'getProductConfig',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let data = result.data.map(v => ({...v, value: v.ProductName}))
					setProductData(data);
					setCreateLogisticsData((prevData) => {
						return {
							...prevData,
							ProductID: '',
							ProductName:''
						};
					});
					setDisableProduct(false);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	let ProductInput = [
		{
			labelText: localString("Brand"),
			dataKey: "BrandName",
			showFilter: true,
			type :'Dropdown',
			placeholder:"Brand",
			dropdownList:brandData,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					setProductSubCategoryData({})
					setProductData({})
					return {
						...prevData,
						BrandID: val.BrandID,
						BrandName:val.BrandName,
						ProductSubCategoryID: '',
						ProductSubCategoryName: '',
						ProductID: '',
						ProductName: '',
						selectedProduct: ''
					};
				});
			},
		},
		{
			labelText: localString("Product Sub Category"),
			dataKey: "ProductSubCategoryName",
			type :'Dropdown',
			dropdownList:productSubCategoryData,
			placeholder:"Product Sub Category",
			showFilter: true,
			isDisabled:disableProductSubCategory,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					setProductData({})
					return {
						...prevData,
						ProductSubCategoryID: val.ProductSubCategoryID,
						ProductSubCategoryName: val.ProductSubCategory,
						ProductID: '',
						ProductName: '',
						selectedProduct: ''
					};
				});
			},
		},
		{
			labelText: localString("Product"),
			dataKey: "ProductName",
			type :'Dropdown',
			dropdownList:productData,
			placeholder:"Product",
			showFilter: true,
			isDisabled:disableProduct,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						ProductID: val.ProductID,
						ProductName:val.ProductName,
						selectedProduct:val
					};
				});
			},
		},
		{
			labelText: localString("IMEI/Serial No"),
			dataKey: "ProductUniqueID",
			showFilter: true,
			placeholder:"IMEI/Serial No",
			maxLength:20,
			errorMessage:imeiErrorMessage,
			oninputFunc: (val) => {
				setCreateLogisticsData((prevData) => {
					return {
						...prevData,
						ProductUniqueID: val
					};
				});
			},
		},
	];
	useEffect(() => {
		loadBrands();
	}, []);
	useEffect(() => {
		validateImei();
	}, [createLogisticsData.ProductUniqueID,createLogisticsData.BrandName]);

	useEffect(() => {
		if(createLogisticsData.BrandID){
			loadProductSubcategories();
		}
	}, [createLogisticsData.BrandID]);

	useEffect(() => {
		if(createLogisticsData.BrandID && createLogisticsData.ProductSubCategoryID){
			loadProducts();
		}
	}, [createLogisticsData.BrandID, createLogisticsData.ProductSubCategoryID]);

	const validateImei = () => {
		let data = {
			"IdentifierValue":createLogisticsData.ProductUniqueID
		}
		if(createLogisticsData && createLogisticsData.BrandName == 'Apple' && createLogisticsData.ProductUniqueID && createLogisticsData.ProductUniqueID.length < 10) {
			setImeiErrorMessage(localString('IMEI/Serial No. has to be atleast 10 characters.'))
			return;
		} else if(((createLogisticsData && createLogisticsData.BrandName != 'Apple') || !createLogisticsData.BrandName) && createLogisticsData.ProductUniqueID && createLogisticsData.ProductUniqueID.length < 15) {
				setImeiErrorMessage(localString('IMEI has to be atleast 15 characters.'))
				return;
		} else {
			setImeiErrorMessage('');
		}
		if (currentreq != null) {
			currentreq.abort();
		}
		if(createLogisticsData.ProductUniqueID && createLogisticsData.ProductUniqueID.length){
			setLoader(true);
			currentreq = apiCall({
				url:'checkExistingLogisticsRequest',
				data: data,
				callBack:(result)=>{
					setLoader(false);
					if(result.success){
						let activeRequests = result.data;
						let activeRequestIDs = [];
						setImeiErrorMessage(localString('The request is already active with this IMEI/Serial No.'));
						let requesthtml = '<h4>' + activeRequests.length + ' Request(s) ' + (activeRequests.length > 1?'are':'is') + ' already active on this IMEI/Serial No. Do you want to remove active requests?' + '</h4><div class="table-responsive imei-table">';
						requesthtml += '<table class="table">';
						requesthtml += '<thead><td>Active Request No.</td><td>Waybill Number</td><td>Pickup Name</td></thead>'
						requesthtml += '<tbody>';
						for (let iCount=0;iCount<activeRequests.length;iCount++) {
							activeRequestIDs.push(activeRequests[iCount].LogisticsRequestID)
							requesthtml += '<tr>';
							requesthtml += '<td>';
							requesthtml += '# ' + (parseInt(iCount) + 1)
							requesthtml += '</td>';
							requesthtml += '<td>';
							requesthtml += (activeRequests[iCount].WaybillNumber?activeRequests[iCount].WaybillNumber:'NA');
							requesthtml += '</td>';
							requesthtml += '<td>';
							requesthtml += activeRequests[iCount].PickUpName;
							requesthtml += '</td>';
							requesthtml += '</tr>';
						}
						requesthtml += '</tbody>';
						requesthtml += '</table>'
						requesthtml += '</div>';
						Swal.fire({
							title:activeRequests.length + ' Request(s) ' + (activeRequests.length > 1?'are':'is') + ' already active',
							text:localString('Please enter rejection reason before submitting'),
							icon: 'question',
							html:requesthtml,
							allowOutsideClick: false,
							showCancelButton:true,
							showConfirmButton:true
						}).then((result) => {
							if (result.isConfirmed) {
								let data = {
									"LogisticsRequestIDs": activeRequestIDs,
								}
								setLoader(true);
								apiCall({
									url:'cancelDuplicateLogisticsRequest',
									data: data,
									callBack:(result)=>{
										setLoader(false);
										if(result.success){
											Swal.fire({
												icon: 'success',
												text: localString('Request(s) cancelled successfully.'),
											});
											setImeiErrorMessage('');
										}else{
											Swal.fire({
												icon: 'error',
												text: localString(displayError(result)),
											});
										}
									}
								});
							}
						})
					}else{
						setImeiErrorMessage('');
					}
				}
			});
		}
	}
	return (
		<>
		{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
		<div className="padding12">
			<h4>{localString("Product Detail")}</h4>
			<div class="bottom-divider clearfix">
				<FilterInputs {...props} filterInputs={ProductInput} filterData={createLogisticsData} hideSaveButton={true} hideClearButton=
				{true}></FilterInputs>
			</div>
		</div>
		</>
	)
}

export default ProductDetailsInput;
