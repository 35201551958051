import React, { useEffect, useState } from "react";
import {MultiInput,Button} from 'sfy-react'
import Swal from "sweetalert2";
import { displayError } from "../../../Utility/Utils";
const CustomerVerification = (props) => {
    const {localString,qFilter, apiCall,consumerObj,selectedCountryData,setLoader,setShowConsumerProducts,loadConsumerProducts,showOtpScreen,setShowOtpScreen,
        minutes, setMinutes,seconds, setSeconds,requestConsumerOtp, disableResendOtpBtn, setDisableResendOtpBtn
    } = props;
    // const [otpVerify,setOtpVerify] = useState(false)
    const [otpValue,setOtpValue] = useState('');
    const [disableOtpVerifyBtn, setDisableOtpVerifyBtn] = useState(true);
    const [isDisableOtpInput,setIsDisableOtpInput] = useState(false);
    const [isVerified,setIsVerified] = useState(false);
    const [errorState, setErrorState] = useState({
        errorText: "",
        showError: false
    });

    useEffect(()=>{
        if(otpValue != '' && otpValue.length=== 6){
            setDisableOtpVerifyBtn(false);
        }else{
            setDisableOtpVerifyBtn(true);
        }
    },[otpValue])

    useEffect(() => {
		if (showOtpScreen == true) {
			let myInterval = setInterval(() => {
				if (seconds > 0) {
					setSeconds(seconds - 1);
				}
				if (seconds === 0) {
					if (minutes === 0) {
						clearInterval(myInterval);
                        if(isVerified == true){
                            setDisableResendOtpBtn(true);
                        }else{
                            setDisableResendOtpBtn(false);
                        }
					} else {
						setMinutes(minutes - 1);
						setSeconds(59);
					}
                    // setDisableResendOtpBtn(true);
				}
			}, 1000);
			return () => {
				clearInterval(myInterval);
			};
		}
	}, [showOtpScreen, seconds,isVerified]);

   
    const handleOtpChange = (e) => {
        setOtpValue(e)
    }

    const verifyConsumerOtp = () => {
		setLoader(true)
        let reqObj = {};
		reqObj = {
			partnerServiceLocationIDs: qFilter.serviceLocations,
			emailID: consumerObj?.emailID,
			mobileNo: consumerObj?.mobileNo,
			phoneCode: consumerObj?.phoneCode,
			countryID: consumerObj?.countryID,
			countryCode:selectedCountryData?.CountryCode,
			otp: otpValue
		}
        if(consumerObj?.mobileNo){
			reqObj.phoneCode= consumerObj?.phoneCode
		}
		apiCall({
			url: 'verifyConsumerOtp',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success){
                    if(result?.data?.otpVerified){
                        setShowOtpScreen(false);
                        setIsVerified(result.data.otpVerified) 
                        setIsDisableOtpInput(true);
                        setDisableOtpVerifyBtn(true);
                        setShowConsumerProducts(true);
                        loadConsumerProducts();
                        setSeconds(0);
                    }
				}else {
                    setErrorState({
                        errorText: result.msg,
                        showError: true,
                    });
                    setLoader(false);
                    setDisableOtpVerifyBtn(true);
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});
	}

    const getTimerString = () => {
		return minutes === 0 && seconds === 0
		? ""
		: "in " + minutes
		+ ":"
		+ (seconds < 10 ? "0" + seconds : seconds)
	}

    const resentOtpLink = () =>{
        requestConsumerOtp(consumerObj)
    }
    return(
        <>
            {
            // otpVerify && 
                <div className="raiseReqSection">
                    <div className="row">
                        <div className="col-sm-12"> 
                            <div className="form-label">
                                {localString('Customer Verification')}
                                <div className="varification_div pt20">
                                    {localString('Please ask the customer for the verification code received on their registered contact information')}
                                </div>
                                <div className="cred-input-div">
                                    <MultiInput
                                        length={6}
                                        handleOnChange={handleOtpChange}
                                        classNames={{
                                            input: "otp-input input_text",
                                        }}
                                        isDisabled = {isDisableOtpInput}
                                    />
                                </div>
                                { errorState.showError &&
                                    <p className="varification_div error_msg">
                                        {errorState.errorText}
                                    </p>
                                }
                                <div className="varification_div pb10 pt10">
                                    {
                                        localString('Did not receive a verification code?')
                                    }
                                </div>
                                <div className="varification_div pb10 pt10">
                                    <button
                                        className={`${!disableResendOtpBtn 
                                            ? 'resend_link'
                                            :'disable_link'} resend_btn`}
                                        disabled= {disableResendOtpBtn}
                                        onClick={resentOtpLink}
                                    >
                                        {localString('Resend verification code ')}
                                        {" "}
                                        <span className="resend_link">{getTimerString()}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-left varify_btn_container ">
                        <button
                            className={`${disableOtpVerifyBtn
                                    ? 'verify_btn_disabled'
                                    :  'button-servify'} btn_font btn` }
                            disabled = {disableOtpVerifyBtn} 
                            onClick ={()=>{verifyConsumerOtp()}}
                        >
                            {localString("Verify")}
                        </button>
                    </div>
                </div>
            }
        </>
    )
}
export default CustomerVerification;