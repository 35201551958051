import React from 'react';
import {Modal} from 'sfy-react';
import ShowDiagnosticsResultInput from './ShowDiagnosticsResultInput';
import './ShowDiagnosticsResultModal.scss'

const ShowDiagnosticsResultModal = (props) => {
    const {localString,showModal,closeModal} = props;
		return ( <>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
                <Modal.Header>
                    <h2 className='modal-title'> {localString("Diagnostics Result")} </h2>
                </Modal.Header>
                <Modal.Body>
                    <ShowDiagnosticsResultInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </> )
}
export default ShowDiagnosticsResultModal;