import React, {useState,useEffect} from 'react';
import './SelectConsumerProduct.scss';

const SelectConsumerProduct = (props) => {
    const {servicibleProducts, inrepairProducts, productSectionHeader, showAddProduct, showRefreshBtn, localString, onProductClick} = props;
    const [filteredConsumerProducts, setFilteredConsumerProducts] = useState([]);
    const [selectedConsumerProduct, setSelectedConsumerProduct] = useState(null);
    const [filterInput, setFilterInput] = useState('');

    useEffect(() => {
        setFilterInput('');
        setFilteredConsumerProducts(servicibleProducts);
    }, [servicibleProducts]);

    useEffect(() => {
        if(filterInput) {
            setFilteredConsumerProducts(servicibleProducts.filter((obj) => (obj.product.ProductName.toLowerCase()).indexOf(filterInput.toLowerCase()) > -1))
        } else {
            setFilteredConsumerProducts(servicibleProducts);
        }
    }, [filterInput]);

    const setProduct = (product) => {
        setSelectedConsumerProduct(product);
        onProductClick(product);
    };

    return (
        <div className="fluid-form-content fluid-form-active">
            <div className="row">
                <div className="col-sm-12 form-label">
                    <label>{productSectionHeader}</label>
                    {showAddProduct &&
                        <button className="btn button-servify button-stack pull-right">Add a Product</button>
                    }
                    {showRefreshBtn &&
                        <button className="btn button-servify pull-right button-refresh"></button>
                    }
                 </div>
            </div>
            <div className="row">
                <div className={'col-xs-12 ' + (!inrepairProducts || (inrepairProducts && inrepairProducts.length > 0) ? 'col-sm-12' : 'col-sm-6')}>
                    <div className="form-group search-box" if={servicibleProducts && servicibleProducts.length > 0}>
                        <input className="form-control search-box-icon search-box-no-border" type="text" pattern="[a-zA-Z][a-zA-Z ]+" 
                        placeholder={localString("Search consumer products")} value={filterInput} onInput={(e) => setFilterInput(e.target.value)}
                        title={localString("Characters must be alphabets")} />
                    </div>
                    {(!filteredConsumerProducts || (filteredConsumerProducts && filteredConsumerProducts.length === 0)) &&
                        <div className="col-sm-10 col-sm-offset-1">
                            <div className="text-center empty-message request-form-empty-state-message">
                                {localString("No serviceable consumer product found")}
                            </div>
                        </div>
                    }
                    <div>
                        <div className="fixed-height row">
                            {filteredConsumerProducts && filteredConsumerProducts.length > 0 && filteredConsumerProducts.map((obj) => {
                                return(
                                    obj &&
                                    <div className="col-md-4 col-sm-6 col-xs-12" onClick={() => setProduct(obj)}>
                                        <div className={"item-not-inservice subcat-item under-service-items " + (selectedConsumerProduct && obj.ConsumerProductID === selectedConsumerProduct.ConsumerProductID ? 'active' : '')}>
                                            {obj.IsAppDownlodedDevice && <span className="sprite-tick tick-abs-right"></span>}
                                            <img src={obj.ProductSubcategory && obj.ProductSubcategory.ImageUrl} 
                                                 alt={obj.ProductSubcategory && obj.ProductSubcategory.ProductSubCategory}/>
                                            <p>{obj.product.ProductName}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                {inrepairProducts && inrepairProducts.length > 0 &&
                    <div className="col-sm-6 col-xs-12">
                        <div>
                            <div className="fixed-height row">
                                {inrepairProducts.map((obj) => {
                                    return(
                                        obj &&
                                        <div className="col-md-4 col-sm-6 col-xs-12">
                                            <div className="item-not-inservice subcat-item under-service-items subcat-item">
                                                {obj.IsAppDownlodedDevice && <span className="sprite-tick tick-abs-right"></span>}
                                                <img src={obj.ProductSubcategory && obj.ProductSubcategory.ImageUrl} 
                                                    alt={obj.ProductSubcategory && obj.ProductSubcategory.ProductSubCategory}/>
                                                <p>{obj.product.ProductName}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default SelectConsumerProduct
