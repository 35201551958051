import React, { useCallback, useEffect, useRef, useState } from 'react';
import {Button, Dropdown, Loader, Input, setAutocomplete} from 'sfy-react';
import { intersection, max, orderBy } from 'lodash';
import { displayError, regexTest } from '../../../Utility/Utils';
import Swal from 'sweetalert2';

const AddressFields = (props) => {
    const {publicUrl, localString, address, setAddress, apiCall, errors, setErrors, gmap, countriesData,
		addressConfigArr, setAddressConfigArr, checkEligibleFieldsBeforePlanSelection, eligibleCheckFields, areAllValuesEmpty} = props;
    const autoCompleteRef = useRef(null);
    const [autoCompleteVal, setAutoCompleteVal] = useState(null);
    const [stateList, setStateList] = useState([]);
	const [stateObj, setStateObj] = useState(null);
	const [districtList, setDistrictList] = useState([]);
	const [districtObj, setDistrictObj] = useState(null);
	const [updateAddressErrors, setUpdateAddressErrors] = useState({});
    const configArr = countriesData && countriesData?.countryConfig?.addressConfig || [];
	const [selfCallingFunction, setSelfCallingFunction] = useState(false);


	const addressFields = [
		"Address",
		"Landmark",
		"UserLandmark",
		"State",
		"StateCode",
		"City",
		"PinCode",
		"District",
		"Zipcode",
	];

	const setAddressConfigArrFunction = () => {
		if (eligibleCheckFields?.RequiredFieldsForPurchase) {
			const showCompleteAddress = eligibleCheckFields.RequiredFieldsForPurchase.find(field => field.FieldName === "ConsumerAddress");
			if (showCompleteAddress) {
				let updatedOrder = orderBy(configArr, ['displayOrder'])
				setAddressConfigArr(updatedOrder);
			} else {
				let commonFields = intersection(addressFields, checkEligibleFieldsBeforePlanSelection);
				customAddressObj(commonFields);
			}
		} else {
			let commonFields = intersection(addressFields, checkEligibleFieldsBeforePlanSelection);
			customAddressObj(commonFields);
		}
	}

	const customAddressObj = (commonFields) => {
		let arr = []; 
		let addressObj = countriesData && countriesData?.countryConfig?.addressConfig;

		addressObj && addressObj.map((field)=>{
			if(field.keyName == "Landmark"){
				arr.push(field)
			}
		})

		commonFields.forEach((field)=>{
			switch(field) {
				case 'Zipcode':
					addressObj && addressObj.map((field) => {
						if(field.keyName === "PinCode" || field.keyName === "Zipcode"){
							arr.push(field)
						}
					})
					break;
				case 'Pincode':
					addressObj && addressObj.map((field) => {
						if(field.keyName === "PinCode"){
							arr.push(field)
						}
					})
					break;
				case 'City':
					addressObj && addressObj.map((field) => {
						if(field.keyName === "City"){
							arr.push(field)
						}
					})
					break;
				case 'StateCode':
					addressObj && addressObj.map((field) => {
						if(field.keyName === "StateCode"){
							arr.push(field)
						}
					})
					break;
				case 'State':
					addressObj && addressObj.map((field) => {
						if(field.keyName === "State"){
							arr.push(field)
						}
					})
					break;
				case 'District':
					addressObj && addressObj.map((field) => {
						if(field.keyName === "District"){
							arr.push(field)
						}
					})
					break;
				case 'UserLandmark':
					addressObj && addressObj.map((field) => {
						if(field.keyName === "UserLandmark"){
							arr.push(field)
						}
					})
					break;
				case 'Address':
					addressObj && addressObj.map((field) => {
						if(field.keyName === "Address"){
							arr.push(field)
						}
					})
					break;
			}
		})		
		setAddressConfigArr(arr)
	}

    useEffect(() => {
        // let updated = orderBy(configArr, ['displayOrder'])
        // setAddressConfigArr(updated);
		setAddressConfigArrFunction();
    }, [configArr, eligibleCheckFields.RequiredFieldsForPurchase, checkEligibleFieldsBeforePlanSelection])

    useEffect(() => {
        let PlanSalesAddressObj = {...address}
        sessionStorage.setItem('PlanSalesAddressObj', JSON.stringify(PlanSalesAddressObj))
    }, [address])

    useEffect(() => {
		const autocomplete = setAutocomplete(autoCompleteRef.current, handleLandmarkSelection)
		return () => {
			window.google.maps.event.clearListeners(autocomplete, 'place_changed')
		}
	}, [handleLandmarkSelection])

	const handleLandmarkSelection = useCallback(
		(value) => {
			setAutoCompleteVal(value);
		}, []
	)

	useEffect(() => {
		if(autoCompleteVal) {
            updateAddress(autoCompleteVal)
		}else {
			// yet to decide
		}
	}, [autoCompleteVal]);

    useEffect(() => {
		setErrors({ ...errors, ...updateAddressErrors });
	}, [updateAddressErrors]);

	useEffect(() => {
		if(countriesData)
		getStates();
	}, [countriesData])

	useEffect(() => {
		if(address && address.State && isDistrictMandatory()) {
			setAddress({ ...address, District: '' });
			getDistricts();
		}
	}, [address && address.State])

	useEffect(() => {
		if(selfCallingFunction){
			setSelfCallingFunction(false);
			validate();
		}
	}, [selfCallingFunction])

    const callLoggingApi = (apiName, value) => {
		let reqObj = {
			apiName: apiName,
			value: value,
			key: gmap,
			identifiers: [{ 'BrandID': 1 }]
		}
		apiCall({
			url: 'addLogOfApi',
			data: reqObj,
			callBack: () => {},
		});
	}

	const getStates = () => {
		// setLoader(true);
		let reqObj = {
			CountryID: countriesData && countriesData?.CountryID
		}
		apiCall({
			url: 'getStates',
			data: reqObj,
			callBack: (result) => {
				// setLoader(false);
				if(result.success && result.data){
					let states = result.data.map(a => ({...a, value: a.State}));
					setStateList(states);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
			// errorCB: (err) => setLoader(false)
		});
	}

	const getDistricts = () => {
		let reqObj = {
			CountryCode: countriesData?.CountryCode ? countriesData.CountryCode : undefined,
			StateCode: stateObj && stateObj.StateCode  ? stateObj.StateCode.toString() : undefined
		}
		apiCall({
			url: 'getDistricts',
			data: reqObj,
			callBack: (result) => {
				if(result.success && result.data){
					let districts = result.data.map(a => ({...a, value: a.DistrictName}));
					setDistrictList(districts);
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
				}
			},
		});
	}

    const updateAddress = (placesObj) => {
		let addressObj = {};
		let errorsObj = {...updateAddressErrors};
		if(placesObj){
			addressObj.Landmark = placesObj && placesObj?.formatted_address ;	
			addressObj.Lat = placesObj && placesObj?.geometry?.location.lat() ;
			addressObj.Lng = placesObj && placesObj?.geometry?.location.lng() ;
		}

        placesObj && placesObj.address_components && placesObj.address_components.map(place => {
			if (place && place.types.includes('postal_code') && addressConfigArr.find((obj) => (obj.keyName == 'Zipcode' && obj.display))) {
				addressObj.Zipcode = place.short_name ? place.short_name : ''
				let obj = validateFields('Zipcode', addressObj.Zipcode, true);
				errorsObj.Zipcode = obj.msg;
			}

			if (place && place.types.includes('postal_code') && addressConfigArr.find((obj) => (obj.keyName == 'PinCode' && obj.display))) {
				addressObj.PinCode = place.short_name ? place.short_name : ''
				let obj = validateFields('PinCode', addressObj.PinCode, true);
				errorsObj.PinCode = obj.msg;
			}

			if (place && place.types.includes('country')) {
				addressObj.Country = place.short_name ? place.short_name : ''
			}

			if (place && place.types.includes('administrative_area_level_1') && addressConfigArr.find((obj) => (obj.keyName == 'State' && obj.display))) {
				if(place && place.long_name && checkStateValid(place.long_name)) { 
					addressObj.State = place.long_name;
				}else {
					addressObj.State = '';
				}
				let obj = validateFields('State', addressObj.State, true);
				errorsObj.State = obj.msg;
			}

			if (place.types.includes('locality') && addressConfigArr.find((obj) => (obj.keyName == 'City' && obj.display))) {
				addressObj.City = place.long_name ? place.long_name : ''
				let obj = validateFields('City', addressObj.City, true);
				errorsObj.City = obj.msg;
			}

			if (place && place.types.length && (place.types.includes('route') || place.types.includes('street_number')) && addressConfigArr.find((obj) => (obj.keyName == 'UserLandmark' && obj.display))) {
				let val = addressObj.UserLandmark ? addressObj.UserLandmark : '';
				let name = place.long_name ? place.long_name : '';
				addressObj.UserLandmark = val ? (val + ' ' + name) : name;
				let obj = validateFields('UserLandmark', addressObj.UserLandmark, true);
				errorsObj.UserLandmark = obj.msg;
			}

			if (place && place.types.length && (place.types.includes('subpremise') || place.types.includes('premise')) && addressConfigArr.find((obj) => (obj.keyName == 'Address' && obj.display))) {
				let val = addressObj.Address ? addressObj.Address : '';
				let name = place.long_name ? place.long_name : '';
				addressObj.Address = val ? (val + ' ' + name) : name;
				let obj = validateFields('Address', addressObj.Address, true);
				errorsObj.Address = obj.msg;
			}
			
		});

		setAddress(addressObj);
		setUpdateAddressErrors(errorsObj);
		setSelfCallingFunction(true);
	}

	const validate = () => {
		let errorObj = {...errors}
		let flag = {}

		// Landmark validation
		if(!address || (address && !address.Landmark)) {
			flag.Landmark = false;
			errorObj.Landmark = localString("Landmark is required")
		}else if(address.Landmark && (!address.Lat || address.Lat == 0 || !address.Lng || address.Lng == 0)) {
			flag.Landmark = false;
			errorObj.Landmark = localString("There's a problem with this location, please select a different location")
		}else {
			flag.Landmark = true
			errorObj.Landmark = null
		}

		addressConfigArr && addressConfigArr.map(item => {
			if((item.keyName !== 'Landmark') && item.display) {
				let obj = validateFields(item.keyName, address ? address[item.keyName] : '', true);
				flag[item.keyName] = obj.flag;
				errorObj[item.keyName] = obj.msg;
			}
		}) 
		setErrors(errorObj);
		if(Object.values(flag).some(e => !e)) {
			return false;
		}else {
			return true;
		}
	}

    const validateFields = (key, value, returnValue) => {
		let configObj = addressConfigArr.find(item => (item.keyName === key));
		if(configObj) {
			if(!value && configObj.mandatory) { // Mandatory check
				if(returnValue) {
					let obj = { flag: false, msg: localString(configObj.label) + " " + localString("is required") }
					return obj
				}else {
					setErrors({ ...errors, [key]: localString(configObj.label) + " " + localString("is required") })
				}
			}else if(!value && !configObj.mandatory) { // if value is empty and key in !mandatory then pass
				if(returnValue) {
					let obj = { flag: true, msg: null }
					return obj
				}else {
					setErrors({ ...errors, [key]: null });
				}
			}else if((configObj.regexFunc && ["NUMERIC", "ALPHANUMERIC"].includes(configObj.regexFunc) && configObj.length && !checkLengthValid(value, configObj))) { 
				if(returnValue) {
					let obj = { flag: false, msg: localString(configObj.label ) + " " + localString("is invalid") }
					return obj
				}else {
					setErrors({ ...errors, [key]: localString(configObj.label ) + " " + localString("is invalid") })
				}
			}else if(configObj.keyName == "State" && !checkStateValid(value)) { // State Check
				if(returnValue) {
					let obj = { flag: false, msg: localString(configObj.label ) + " " + localString("is invalid") }
					return obj
				}else {
					setErrors({ ...errors, [key]: localString(configObj.label ) + " " + localString("is invalid") })
				}
			}else if(configObj.keyName == "District" && !checkDistrictValid(value)) { // State Check
				if(returnValue) {
					let obj = { flag: false, msg: localString(configObj.label ) + " " + localString("is invalid") }
					return obj
				}else {
					setErrors({ ...errors, [key]: localString(configObj.label ) + " " + localString("is invalid") })
				}
			}else {
				if(returnValue) {
					let obj = { flag: true, msg: null }
					return obj
				}else {
					setErrors({ ...errors, [key]: null });
				}
			}
		}
	}

    const checkLengthValid = (value, configElement) => {
		if(configElement && configElement.regexFunc) {
			if(regexTest((configElement.regexFunc).toLowerCase(), value.replace(" ",""))) {
				if(configElement.length.indexOf(value.length) > -1) {
					return true;
				}else {
					return;
				}
			}else {
				return;
			}
		}else if (configElement && configElement.minLength && configElement.minLength <= value.length) {
			return true;
		}else {
			return
		}
	}

    const checkStateValid = (state) => {
        let selectedState = stateList.find((obj) => { 
			if((obj.State).toLowerCase() == (state).toLowerCase()) {
				setStateObj(obj)
				return obj;
			}
        });
        return selectedState ? true : false;
    };

    const checkDistrictValid = (district) => {
        let selectedDistrict = districtList && districtList.length > 0 && districtList.find((obj) => {
			if((obj.DistrictName).toLowerCase() == (district).toLowerCase()) {
				setDistrictObj(obj);
				return obj;
			}
            return (obj.DistrictName).toLowerCase() == (district).toLowerCase();
        });
        return selectedDistrict ? true : false;
    };

    const getInputLength = (item) => {
		return `${(address && address[item.keyName] && (address[item.keyName]).length) || 0}/${item.inputLength}`
	}

    const getMaxLength = (item) => {
		return item.inputLength ? item.inputLength : (["NUMERIC", "ALPHANUMERIC"].indexOf(item.regexFunc) > -1) && item.length ? max(item.length) : false 
	}

	const isDistrictMandatory = () => {
		const countryConfig = countriesData && countriesData?.countryConfig;
        let isDistrictMandatory = false;
        if(countryConfig && countryConfig.addressConfig && countryConfig.addressConfig.length > 0) {
            countryConfig.addressConfig.filter((obj)=>{
				if((obj.keyName == 'District') && obj.mandatory && obj.display) {
					isDistrictMandatory = true;
				}
            })
        }
        return isDistrictMandatory ? true : false;
    };

    return (
        <div className='address-block-plan-sale col-sm-12'>
            <div className='input-options location-sec'>
                <Input
                    value={address?.Landmark}
                    handleOnChange={(e) => {
                        setAddress({ ...address, Landmark: e.target.value, Lat: '', Lng: ''});
                        validateFields('Landmark', e.target.value);
                        callLoggingApi('AutocompletionService.GetPredictions', e.target.value);
                    }}
                    placeholder={localString("Enter a location")}
                    id={'Landmark'}
                    label={localString("Location")}
                    labelAlign='top'
					// message={{type: 'error', value: errors["Landmark"]}}
                    className={{
                        formControl: 'forms-width',
                        containerClass: 'input-container',
                        inputClass: 'input location-input',
                    }}
                    reference={autoCompleteRef}
                >
                <i><img className='location-icon' src={publicUrl+'/imgs/locationIcon3x.png'} /></i>
                </Input>
				<div className='error errMessage'>{errors?.["Landmark"] || ""}</div>
            </div>
            {
                addressConfigArr && addressConfigArr.length > 0 && addressConfigArr.map((item, k) => {
                    if(item.keyName != "Landmark" && item.keyName != "State" && item.keyName != "District" && item.display == true) {
                        return (
                            <div className="input-options col-sm-4" key={k} style={{ order: '-1' }}>
                                <Input
                                    value={(address && address[item.keyName]) ? address[item.keyName] : ''}
                                    handleOnChange={(e) => {
                                        setAddress({ ...address, [item.keyName]: e.target.value })
                                        validateFields(item.keyName, e.target.value)
                                    }}
                                    maxLength={getMaxLength(item)}
                                    label={!item.mandatory ? localString(item.label) + " " + localString("(Optional)") : localString(item.label) }
                                    id={'addressField-'+k}
                                    labelAlign='top'
                                    // message={{type: 'error', value: errors[item.keyName]}}
                                    className={{
                                        formControl: 'forms-width',
                                        containerClass: 'input-container',
                                        inputClass: 'input',
                                        message: 'errMessage'
                                    }}
									placeholder={localString('Enter' + " " +`${(item.label)}`)}
                                >
                                    { item.inputLength && <span className='character-limit pull-right'>{ getInputLength(item)}</span>}
                                </Input>
								{/* <div className='error errMessage'>{errors?.[item.keyName] || ""}</div> */}
								<div className={!areAllValuesEmpty(errors) ? "add-mb error errMessage" :'error errMessage'}>{errors?.[item.keyName] || ""}</div>
                            </div>
                        )
                    }else if(item.keyName === "State" && item.display == true) {
                        return (
                            <div className="input-options col-sm-4 state-dpdw-div" key={k} style={{ order: '-1' }}>
                                <label style={{marginBottom: "10px", fontWeight: "500", color: "black"}}>{!item.mandatory ? localString(item.label) + " " + localString("(Optional)") : localString(item.label)}</label>
                                <Dropdown
                                    value={address && address[item.keyName]}
                                    options={stateList}
                                    id={'state-'+k}
                                    handleOnChange={(data) => {
                                        setAddress({ ...address, State: data.State, StateID: data.StateID, District: '' });
                                        setStateObj(data);
                                        validateFields(item.keyName, data.State)
                                    }}
                                    filterBy='value'
                                    showDownArrow={false}
									placeholder={localString('Enter' + " " +`${(item.label)}`)}
                                />
                                {/* { errors[item.keyName] && <span className="error errMessage">{localString(errors[item.keyName])}</span>} */}
								<div className={!areAllValuesEmpty(errors) ? "add-mb error errMessage" :'error errMessage'}>{errors?.[item.keyName] || ""}</div>
                            </div>
                        )
                    }else if(item.keyName === "District" && item.display == true) {
                        return (
                            <div className="input-options col-sm-4" key={k} style={{ order: '-1' }}>
                                <label style={{marginBottom: "10px", fontWeight: "500", color: "black"}}>{!item.mandatory ? localString(item.label) + " " + localString("(Optional)") : localString(item.label)}</label>
                                <Dropdown
                                    value={address && address[item.keyName]}
                                    options={districtList}
                                    id={'district-'+k}
                                    handleOnChange={(data) => {
                                        setAddress({ ...address, District: data.DistrictName });
                                        validateFields(item.keyName, data.DistrictName);
                                    }}
                                    filterBy='value'
                                    showDownArrow={false}
									placeholder={localString('Enter' + " " +`${(item.label)}`)}
                                />
                                {/* { errors[item.keyName] && <span className="error errMessage">{localString(errors[item.keyName])}</span>} */}
								<div className={!areAllValuesEmpty(errors) ? "add-mb error errMessage" :'error errMessage'}>{errors?.[item.keyName] || ""}</div>
                            </div>
                        )
                    }
                })
            }
        </div>
    )
}

export default AddressFields