import React from 'react';
import {Button} from 'sfy-react';
import './ReportPageHeader.scss';


const ReportPageHeader = (props) => {
	const {titleHeader,buttonClass,btnClass,headerButtonArray,showHeaderContent,headerContent,localString} = props;
	return (
		<div className="content-head">
			<div className="row content-header-background"> 
				<div className="col-xs-12">
					<h2 className={`title-requests ${buttonClass}`}>{localString(titleHeader)}</h2>
                    { showHeaderContent && headerContent() }
					{
						headerButtonArray && headerButtonArray.length>0 && headerButtonArray.map((val, index)=>{
							return(
								val.showButton &&
									<Button className={btnClass} onClick={val.onButtonClick}>
									{
										val.btnIcon ? <span className={val.btnIcon}> </span> : ""
									}
									{val.buttonText} 
									</Button>
							)
						})
					}
				</div>
			</div>
		</div>
	)
}

export default ReportPageHeader;
