import React, { useEffect } from "react";
import { dateFormatter } from "sfy-react";
import DocumentCard from "../DocumentCard/DocumentCard";
import "./ConsumerDetails.scss";

const ConsumerDetails = (props) => {
	const {
		getConsumerByCaseID,
		consumerDetails,
		localString,
		csrDocuments,
		getCSRDocuments,
		caseDetails,
	} = props;

	useEffect(() => {
		getConsumerByCaseID();
	}, []);

	useEffect(() => {
		if (caseDetails?.consumerServiceRequest?.ConsumerServiceRequestID)
			getCSRDocuments();
	}, [caseDetails?.consumerServiceRequest?.ConsumerServiceRequestID]);

	return (
		<div className="csr-details-wrapper">
			{consumerDetails && consumerDetails.consumer && (
				<>
					<div className="csr-col section-padding">
						<div className="csr-heading vertical-margin">
							{localString("Personal")}
						</div>

						<div className="row csr-row-pad">
							<div className="sub-heading col-sm-3">
								{localString("Name")}
							</div>
							<div className="sub-heading col-sm-3">
								{localString("Email")}
							</div>
							<div className="sub-heading col-sm-3">
								{localString("Phone Number")}
							</div>
							<div className="sub-heading col-sm-3">
								{localString("Address")}
							</div>
						</div>

						<div className="row csr-row-pad">
							<div className="col-sm-3">
								{consumerDetails.consumer.name || "-"}
							</div>
							<div className="col-sm-3">
								{consumerDetails.consumer.emailID || "-"}
							</div>
							<div className="col-sm-3">{`${
								consumerDetails.consumer.countryCode
									? consumerDetails.consumer.countryCode
									: ""
							} ${
								consumerDetails.consumer.mobileNo
									? consumerDetails.consumer.mobileNo
									: "-"
							}`}</div>
							<div className="col-sm-3">
								{consumerDetails.consumer.address || "-"}
							</div>
						</div>
					</div>

					<div className="csr-border vertical-margin"></div>

					<div className="csr-col section-padding">
						<div className="csr-heading vertical-margin">
							{localString("Products")}
						</div>

						<div className="row csr-row-pad">
							<div className="sub-heading col-sm-3">
								{localString("IMEI/Serial No.")}
							</div>
							<div className="sub-heading col-sm-3">
								{localString("Product Name")}
							</div>
							<div className="sub-heading col-sm-3">
								{localString("Date of Purchase")}
							</div>
						</div>

						{consumerDetails.devices &&
						consumerDetails.devices.length > 0 ? (
							consumerDetails.devices.map((device) => (
								<div className="row csr-product-row-pad">
									<div className="col-sm-3">
										{device.ProductUniqueID || "-"}
									</div>
									<div className="col-sm-3">
										{device.ProductName}
									</div>
									<div className="col-sm-3">
										{device.DateOfPurchase
											? dateFormatter(
													new Date(
														device.DateOfPurchase
													),
													"dd/mm/yyyy"
											  )
											: "-"}
									</div>
								</div>
							))
						) : (
							<div className="text-center">No Data</div>
						)}
					</div>

					<div className="csr-border vertical-margin"></div>

					<div className="csr-col section-padding">
						<div className="score-heading-wrapper">
							<div className="csr-heading vertical-margin">
								{localString("Score")}
							</div>
							<div
								style={{
									color: `${
										consumerDetails.consumer.score > 0
											? "#4eaf51"
											: "#f54336"
									}`,
									backgroundColor: `${
										consumerDetails.consumer.score > 0
											? "#e6f3e5"
											: "#fde4e1"
									}`,
									width: "fit-content",
									textWrap: "nowrap",
									padding: "5px 25px",
									textAlign: "center",
									display: "inline-block",
									margin: "5px",
									borderRadius: "30px",
								}}
							>
								{consumerDetails.consumer.score || "-"}
							</div>
						</div>

						{consumerDetails.consumer.scores &&
						consumerDetails.consumer.scores.length > 0 ? (
							<div className="csr-score-wrapper">
								{consumerDetails.consumer.scores.map(
									(score) => (
										<div className="csr-score-container">
											<div className="sub-heading">
												{score.scoreSubCategory}
											</div>

											<div
												style={{
													color: `${
														score.score > 0
															? "#4eaf51"
															: "#f54336"
													}`,
												}}
											>
												{score.score || "-"}
											</div>
										</div>
									)
								)}
							</div>
						) : (
							<div className="text-center">No Data</div>
						)}
					</div>

					<div className="csr-border vertical-margin"></div>

					<div className="csr-col section-padding">
						<div className="csr-heading vertical-margin">
							Documents
						</div>

						{csrDocuments && csrDocuments.length > 0 ? (
							<div className="csr-score-wrapper">
								{csrDocuments.map((doc) => {
									return (
										<div className="csr-score-container">
											<DocumentCard
												{...props}
												doc={doc}
											/>
										</div>
									);
								})}
							</div>
						) : (
							<div className="text-center">No Data</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default ConsumerDetails;
