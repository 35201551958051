import React from 'react';
import { Button, Input } from 'sfy-react';

export default function RejectReason(props) {
    const {
        localString, processRequestNext, closeModal, refundRejectionReason, setRefundRejectionReason
    } = props;

    const processRefundRejection = () => {
        processRequestNext();
        closeModal();
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <label className="text-capitalize">
                        {localString("Reason for rejection")}
                    </label>
                    <div>
                        <Input
                            value={refundRejectionReason}
                            handleOnChange={(e) => setRefundRejectionReason(e.target.value)}
                            inputComponent='textarea'
                            className={{
                                inputContainer: 'heightAuto'
                            }}
                            otherProps={{
                                onKeyDown: (e) => {
                                    if(e && (e.key == 'Enter')) {
                                        processRefundRejection();
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='pull-right mt-15'>
                <Button 
                    type='button'
                    className="btn button-servify"
                    onClick={() => processRefundRejection()}
                    isDisabled={!refundRejectionReason || !refundRejectionReason.trim().length}
                >
                    {localString("Save")}
                </Button>
            </div>
        </>
    );
}