import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Input, Loader } from 'sfy-react';
import Swal from 'sweetalert2';

import { displayError } from '../../../Utility/Utils';


export default function SelectProduct(props) {
	const {
		apiCall, localString, setLoader, showAddProduct, displayProduct, setScreenToViewDiv, handleProductSelection,
		brand, subCat, product
	} = props;

	const [productFilter, setProductFilter] = useState('');
	const [productList, setProductList] = useState([]);
	const [filteredproductList, setFilteredProductList] = useState([]);

	const handleProductFilter = (e) => {
		let val = e.target.value.toLowerCase();
		setProductFilter(e.target.value);
		if(val) {
			let filteredArr = productList.filter(data => {
				if(data && data.ProductName && data.ProductName.toLowerCase().includes(val)) {
					return data;
				}
			});
			setFilteredProductList(filteredArr);
		} else{
			setFilteredProductList(productList);
		}
	}

	const GetProducts = () => {
		setScreenToViewDiv('selectProduct');
		setLoader(true)
		let reqObj = {
			qFilter: {},
			Active: true
		};
		if(brand) reqObj.qFilter.brands = [brand.BrandID];
		if(subCat) reqObj.qFilter.productSubCategories = [subCat.ProductSubCategoryID];
		apiCall({
			url: 'GetProducts',
			data: reqObj,
			callBack: (result) => {
				setLoader(false); 
				if(result.success && result.data){
					setProductList(result.data);
					setFilteredProductList(result.data);
					if(result.data.length === 0) {
						Swal.fire({  
							icon: 'error',
							text: localString('No products found'),  
						});
					}
				}else {
					Swal.fire({  
						icon: 'error',  
						text: localString('No products found'),  
					});
				}
			},
			errorCB: (err) => {
				setLoader(false);
				Swal.fire({  
					icon: 'error',  
					text: localString(displayError(err)),  
				});
			}
		});	
	}

	useEffect(() => {
		displayProduct && GetProducts();
	}, [subCat, displayProduct]);
	
	return (
		<>
			{ showAddProduct && displayProduct && 
				<div id='selectProduct' className="raiseReqSection">
					<div className='selectProduct'>
						<label>{localString("Select a Product")}</label>
						<div className="row">
							<div className="col-sm-10 col-xs-8">
								<div className="form-group">
									<Input
										value={productFilter}
										handleOnChange={handleProductFilter}
										placeholder={localString("Search products")}
										className={{
											formControl: 'form-group',
											containerClass: 'input-container',
											inputClass: 'input'
										}}
									/>
								</div>
							</div>
							<div className="col-sm-2 col-xs-4" style={{ marginTop: '10px' }}>
								<Button 
									className="pull-right button-refresh" 
									onClick={() => {
										setProductFilter('');
										GetProducts();
									}}
								/>
							</div>
						</div>
						<div className="row product-list fixed-height">
							{
								filteredproductList && filteredproductList.length > 0 && filteredproductList.map((item, k) => <div className="product-list-item col-sm-3 col-xs-4" key={k} onClick={() => handleProductSelection(item)}>
									<div className={`item product-item ${(product.ProductID == item.ProductID) ? 'item-active': ''}`}>
										<p>{item.ProductName}</p>
									</div>
								</div>)
							}
						</div>
					</div>
				</div>
			}
		</>
	);
}