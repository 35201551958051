import React,{useState, useEffect}  from 'react';
import ReactDOM from 'react-dom';
import {Loader} from 'sfy-react';
import Swal from "sweetalert2";
import PageHeader from '../../../components/PageHeader/PageHeader';
import {displayError,returnLocalDateTime,checkEmail,checkMobileNumber,checkProductUniqueID,checkAlphaNumericName,checkMobile,checkAlphaNumeric} from '../../../Utility/Utils';
import FilterModal from '../../../components/FilterModal/FilterModal';
import ConsumerListData from './ConsumerListData';
import { concat, max, groupBy, map, sortBy } from 'lodash';
import AddConsumerModal from './../AddConsumer/AddConsumerModal';
import CallLogsPage from '../../../components/ConsumerCallLogs/CallLogsPage/CallLogPage';
import CallHistoryModal from './CallHistoryModal';
import PageContentWrapper from '../../../components/PageContentWrapper/PageContentWrapper';
import FilterConsumer from '../../../components/FilterInputs/FilterInput';

const ViewConsumerList = (props) => {
	const {accessFn,localString,apiCall,titleHeader,qFilter,baseUrl,viewConsumerUrl,useQuery} = props;
	const query = useQuery();
	const imei = query.get("imei");
	const [ConsumerID,setConsumerID] = useState();
	const [showLoader ,setLoader] = useState(false);
	const [groupedConsumers, setGroupedConsumers] = useState();
	const [disableApplyButton, setDisableApplyBtn] = useState(true);
	const [hideViewButton, setHideViewButton] = useState(true);
	const [disableSaveBtn, setDisableSaveBtn] = useState(true);
	const [hideSaveButton, setHideSaveButton] = useState(false);
	const [viewList , setViewList] = useState(false);
	const [callLog, setCallLog] = useState(false);
	const [showCallLogPage, setShowCallLogPage] = useState(false)
	const [message,setMessage] = useState("");
	const [pagination , setPagination] = useState({
		pageNo : 1,
		itemsPerPage : 5,
		range : 5,
		count:-1,
		numberOfPages:"",
		consumerCount:""
	});
	let [filterData, setFilterData] = useState({
		ConsumerMobileNumber: "",
		ConsumerName: "",
		ConsumerEmail: "",
		ProductSubCategory:"",
		ProductSubCategoryID:"",
		BrandName:"",
		BrandID:"",
		ProductName:"",
		ProductID:"",
		ProductUniqueID: imei || "",
		AlternateUniqueKey:"",
		VoucherCode:"",
		PolicyID:"",
		OrderID:"",
		ContractID:"",
	});
	let [addConsumerData, setAddConsumerData] = useState({
		Name: "",
		MobileNo: "",
		EmailID: "",
		// DeviceType:"",
	});
	const [showModal , setShowModal] = useState(true);
	const [showConsumerModal , setConsumerShowModal] = useState(false);
	const [subcategoryData,setSubcategoryData] = useState({});
	const [brandData,setbrandData] = useState({});
	const [productData,setProductData] = useState({});
	const [showClearSearch, setShowClearSearch] = useState(true);
	const [countryID, setCountryID] = useState("")
	const setCurrentPage = (pageNo) => {
		setPagination({...pagination,['pageNo']:pageNo});
	}
	const [mobileMaxLength, setMobileMaxLength] = useState([]);
    const [specificCountryCode, setSpecificCountryCode] = useState('IN');
	const [loaded, setLoaded] = useState ({
		brandLoaded:false,
		productLoaded:false,
		subCategoryLoaded:false
	})
	const [disableProduct, setDisableProduct] = useState(true)
	const callLogFunction = (val)=>{
		setCallLog(true);
		setShowCallLogPage(true);
		setConsumerID(val);
	}

	/*
		As per new Requirement, 
		Filter Modal has Removed so,
		Clear Search And Filter Consumer Button code is Commented
	*/
	const buttonHeader = [
		// {
		// 	buttonText:localString("Clear Search"),
		// 	showButton:showClearSearch,
		// 	onButtonClick:()=>{
		// 		clearSearch();
		// 	}
		// },
		// {
		// 	buttonText:localString("Filter Consumer"),
		// 	btnIcon:"glyphicon glyphicon-filter",
		// 	showButton:accessFn( 'CONSUMER_LIST_VIEW'),
		// 	onButtonClick:()=>{
		// 		setShowModal(true);
		// 	}
		// },
		{
			buttonText:localString("Add Consumer"),
			btnIcon:"glyphicon glyphicon-plus",
			showButton:accessFn( 'CONSUMER_ADD'),
			onButtonClick:()=>{
				setConsumerShowModal(true);
			}
		},
	];

	/*  
		Newly Migrate to consumerAPI
		Integrate New API filter landing page
		@consumerSearch
		with Added 3 New Filters in Payload
	*/
	const getConsumerList = (e) =>{
		if(e){
			e.preventDefault();
		}
		let data = {
            "pagination": {
                "pageNo": pagination.pageNo,
                "itemsPerPage": pagination.itemsPerPage,
                "range": pagination.range,
            },
            "consumer": {
                "countryCode": filterData.countryData?"+"+filterData.countryData.dialCode:"",
                "mobileNo": filterData.ConsumerMobileNumber,
                "name": filterData.ConsumerName,
                "emailID": filterData.ConsumerEmail,
            },
			"soldPlan" : {
				"policyID": filterData.PolicyID,
				"contractID": filterData.ContractID,
				"orderID": filterData.OrderID
			},
            "qFilter": {
                "featureRights": (JSON.parse(qFilter) && JSON.parse(qFilter).FeatureRights) || ""
            },
            "consumerProduct": {
                "productUniqueID": filterData.ProductUniqueID,
                "alternateUniqueKey": filterData.AlternateUniqueKey
            }
        }
		// if(filterData.ProductSubCategoryID){
		// 	data.qFilter.productSubCategories = [filterData.ProductSubCategoryID]
		// }
		// if(filterData.BrandID){
		// 	data.qFilter.brands = [filterData.BrandID]
		// }else if(JSON.parse(qFilter).brands){
		// 	data.qFilter.brands = JSON.parse(qFilter).brands
		// }
		// if(filterData.ProductID){
		// 	data.qFilter.products = [filterData.ProductID]
		// }
		setLoader(true);
		apiCall({
			// url: 'getPaginatedList',
			url: 'consumerSearch',
			data:data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success && result.data){
					groupConsumers(result.data.consumer);
					setPagination({...pagination,
						count:result.data && result.data.count,
						numberOfPages:result.data && result.data.numberOfPages,
						consumerCount:result.data && result.data.consumer && result.data.consumer.length
					});
					if(result.data.count === 1) {
						let url = baseUrl+'/consumers/view?cid='+result.data.consumer[0].consumerID+getImei();
						window.open(url, '_blank');
					}
					setViewList(true);
					setShowModal(false);
					setShowClearSearch(false);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}				
			}
		});
	}

	// const loadSubCategories = () => {
	// 	setLoader(true);
	// 	apiCall({
	// 		url:'getSubcategory',
	// 		data: {},
	// 		callBack:(result)=>{
	// 			setLoader(false);
	// 			if(result.success){
	// 				let data = result.data.map(v => ({...v, value: v.ProductSubCategory}))
	// 				setSubcategoryData(data);
	// 				setLoaded({...loaded,subCategoryLoaded:true})
	// 			}else{
	// 				Swal.fire({
	// 					icon: 'error',
	// 					text: localString(displayError(result)),
	// 				});
	// 			}
	// 		}
	// 	});
	// }

	// const loadBrands = () => {
	// 	setLoader(true);
	// 	let data = {
	// 		qFilter:{}
	// 	}
	// 	if(filterData && filterData.ProductSubCategoryID){
	// 		data.qFilter.productSubCategories = [filterData.ProductSubCategoryID]
	// 	}
	// 	apiCall({
	// 		url:'GetBrands',
	// 		data: data,
	// 		callBack:(result)=>{
	// 			setLoader(false);
	// 			if(result.success){
	// 				let data = result.data.map(v => ({...v, value: v.BrandName}))
	// 				setbrandData(data);
	// 				setLoaded({...loaded,brandLoaded:true})
	// 			}else{
	// 				Swal.fire({
	// 					icon: 'error',
	// 					text: localString(displayError(result)),
	// 				});
	// 			}
	// 		}
	// 	});
	// }

	// const loadProducts = () => {
	// 	setLoader(true);
	// 	let data = {
	// 		qFilter:{}
	// 	}
	// 	if(filterData && filterData.ProductSubCategoryID){
	// 		data.qFilter.productSubCategories = [filterData.ProductSubCategoryID]
	// 	}
	// 	if(filterData && filterData.BrandID){
	// 		data.qFilter.brands = [filterData.BrandID]
	// 	}
	// 	apiCall({
	// 		url:'GetProducts',
	// 		data: data,
	// 		callBack:(result)=>{
	// 			setLoader(false);
	// 			if(result.success){
	// 				let data = result.data.map(v => ({...v, value: v.ProductName}))
	// 				setProductData(data);
	// 				setLoaded({...loaded,productLoaded:true});
	// 			}else{
	// 				Swal.fire({
	// 					icon: 'error',
	// 					text: localString(displayError(result)),
	// 				});
	// 			}
	// 		}
	// 	});
	// }

	const getCountries = () => {
		setLoader(true);
		let data = {
			specificCountryCode: specificCountryCode,
			countryConfig: true
		}
		apiCall({
			url:'getCountries',
			data: data,
			callBack:(result)=>{
				setLoader(false);
				if(result.success){
					let countryData = result.data.countries[0];
					setCountryValidation(countryData)
					setCountryID(countryData.CountryID)
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}
			}
		});
	}

	const setCountryValidation = (countryData)=>{
		const countryConfig = countryData.countryConfig;
		const AllowedMobileLengths=countryData.AllowedMobileLengths;
		if(countryConfig && countryConfig.MobileNumber){
			if(countryConfig.MobileNumber && countryConfig.MobileNumber.LENGTH){
				let lengthArr = countryConfig.MobileNumber.LENGTH && (countryConfig.MobileNumber.LENGTH.length > 1) ? countryConfig.MobileNumber.LENGTH.map(data=>{
					return parseInt(data)
				}): [countryConfig.MobileNumber.LENGTH[0]];
				let dscSort = sortBy(lengthArr).reverse()
				setMobileMaxLength(dscSort);
			}
		}else if(AllowedMobileLengths){
			let mobLength = AllowedMobileLengths.split(',');
			let lengthArr = mobLength.map((item)=> {
				return parseInt(item);
			});
			let dscSort = sortBy(lengthArr).reverse()
			setMobileMaxLength(dscSort)
		}
	}
	
	useEffect(() => {
        getCountries();
		setFilterData({ ...filterData, ['ConsumerMobileNumber'] : "" });
    }, [specificCountryCode]);

	useEffect(() => {
		setShowModal(true);
		if(JSON.parse(qFilter) && JSON.parse(qFilter).countrycode && JSON.parse(qFilter).countrycode.length > 0) {
            setSpecificCountryCode(JSON.parse(qFilter).countrycode[0] ? JSON.parse(qFilter).countrycode[0] : 'IN');
        }
		if(imei) {
			getConsumerList();
		}
	}, []);

	useEffect(() => {
		if((filterData.ProductSubCategory && filterData.ProductSubCategory.length > 0) 
		&& (filterData.BrandName && filterData.BrandName.length > 0)){
			setDisableProduct(false)
		}else{
			setDisableProduct(true)
		}
	}, [filterData])

	useEffect(() => {
		if(viewList){
		getConsumerList();
		}
	}, [pagination.pageNo]);

	const groupConsumers = function(params) {
		let groupedObj = groupBy(params, function(param) {
			return returnLocalDateTime(param.createdDate,'DD-MM-YYYY');
		});
		var groupedArray = [];
		for (let d in groupedObj) {
			groupedArray.push({
				title: d
			});
			groupedArray = concat(groupedArray, groupedObj[d]);
		}
		setGroupedConsumers(groupedArray);
	}

	const checkDisableApplyBtn = () => {
		if( (filterData.ConsumerMobileNumber.length && (mobileMaxLength.indexOf(filterData.ConsumerMobileNumber.length)>-1) && checkMobileNumber(filterData.ConsumerMobileNumber) )
			|| (checkProductUniqueID(filterData.ProductUniqueID) && (filterData.ProductUniqueID.length >=5 && filterData.ProductUniqueID.length <= 30))
			|| (checkProductUniqueID(filterData.AlternateUniqueKey) && (filterData.AlternateUniqueKey.length >=5 && filterData.AlternateUniqueKey.length <= 30))
			|| (filterData.ConsumerName.length > 0 && checkAlphaNumericName(filterData.ConsumerName))
			|| (filterData.ConsumerEmail.length > 5 && checkEmail(filterData.ConsumerEmail))
			|| (filterData.VoucherCode.length > 0 && checkAlphaNumeric(filterData.VoucherCode)) 
			|| filterData.ProductSubCategory.length > 0 
			|| filterData.BrandName.length > 0
			|| filterData.ProductName.length > 0
			|| filterData.ContractID.length > 0 
			|| filterData.PolicyID.length > 0 
			|| filterData.OrderID.length > 0 
			){
				setDisableApplyBtn(false);
		} else {
				setDisableApplyBtn(true);
		}
	}

	useEffect(() => {
		checkDisableApplyBtn();
	}, [filterData]);

	const onCountryCodeChange = (countryData) => {
        if(countryData && countryData.iso2 && countryData.iso2.toUpperCase() !== specificCountryCode) {
            setSpecificCountryCode(countryData.iso2.toUpperCase());
        }
    };	

	/* 
		As per requirement
		Removed - Brand, SubCategory, Product, Voucher Code
		Added - PolicyID, ContractID, OrderID
	*/
	let filterInputs = [
		{
			labelText: localString('Consumer Mobile'),
			dataKey:"ConsumerMobileNumber",
			maxlength: mobileMaxLength,
			customClass:'col-sm-4 paddingB10',
			type: "intelInput",
			showFilter:true,
			defaultCountry: filterData && filterData.countryData &&  filterData.countryData.iso2 ? filterData.countryData.iso2  : (JSON.parse(qFilter) && JSON.parse(qFilter).countrycode && JSON.parse(qFilter).countrycode.length > 0 ? JSON.parse(qFilter).countrycode[0].toLowerCase()  : "in"),
			intlTelInputClassWrapper :{
				formControl: 'form-control--top ',
				label: 'input__label',
				containerClass: 'input-container',
				inputClass: 'input'
			},
			changeFunc: (val, countryData) => {
				setFilterData({ ...filterData, ['ConsumerMobileNumber'] : val,countryData });
			},
			onFlagSelect: (countryData) => {
				onCountryCodeChange(countryData)
			}
		},
		{
			labelText:localString('Consumer Name'),
			dataKey:"ConsumerName",
			showFilter:true,
			oninputFunc: (val) => {
					setFilterData({ ...filterData, ['ConsumerName'] : val  });
			}
		},
		{
			labelText:localString('Consumer Email'),
			dataKey:"ConsumerEmail",
			showFilter:true,
			oninputFunc: (val) => {
					setFilterData({ ...filterData, ['ConsumerEmail'] : val  });
			}
		},
		// {
		// 	labelText:localString('Subcategory'),
		// 	dataKey:"ProductSubCategory",
		// 	type :'Dropdown',
		// 	dropdownList:subcategoryData,
		// 	showFilter:true,
		// 	oninputFunc: (val) => {
		// 		setFilterData({...filterData,
		// 			['ProductSubCategory']:val.ProductSubCategory,
		// 			['ProductSubCategoryID']:val.ProductSubCategoryID,
		// 			['BrandName']:"",
		// 			['BrandID']:"",
		// 			['ProductName']:"",
		// 			['ProductID']:""
		// 		});
		// 		setLoaded({...loaded,brandLoaded:false,productLoaded:false})
		// 	},
		// 	oninputFocus: () => {
		// 		if(!loaded.subCategoryLoaded){
		// 			loadSubCategories();
		// 		}
		// 	},
		// },
		// {
		// 	labelText:localString('Brand'),
		// 	dataKey:"BrandName",
		// 	showFilter:true,
		// 	type :'Dropdown',
		// 	dropdownList:brandData,
		// 	oninputFunc: (val) => {
		// 		setFilterData({...filterData,
		// 			['BrandName']:val.BrandName,
		// 			['BrandID']:val.BrandID,
		// 			['ProductName']:"",
		// 			['ProductID']:""
		// 		});
		// 		setLoaded({...loaded,productLoaded:false})
		// 	},
		// 	oninputFocus: () => {
		// 		if(!loaded.brandLoaded){
		// 			loadBrands();
		// 		}
		// 	},
		// },
		// {
		// 	labelText:localString('Product'),
		// 	dataKey:"ProductName",
		// 	showFilter:true,
		// 	type :'Dropdown',
		// 	dropdownList:productData,
		// 	isDisabled:disableProduct,
		// 	oninputFunc: (val) => {
		// 		setFilterData({...filterData,
		// 			['ProductName']:val.ProductName,
		// 			['ProductID']:val.ProductID
		// 		});
		// 	},
		// 	oninputFocus: () => {
		// 		if(!loaded.productLoaded){
		// 			loadProducts();
		// 		}
		// 	},
		// },
		{
			labelText:localString('IMEI 1/ Serial Number'),
			dataKey:"ProductUniqueID",
			showFilter:true,
			oninputFunc: (val) => {
				setFilterData({ ...filterData, ['ProductUniqueID'] : val  });
			}
		},
		{
			labelText:localString('IMEI 2'),
			dataKey:"AlternateUniqueKey",
			showFilter:true,
			oninputFunc: (val) => {
					setFilterData({ ...filterData, ['AlternateUniqueKey'] : val  });
			}
		},
		// {
		// 	labelText:localString('Voucher Code'),
		// 	dataKey:"VoucherCode",
		// 	showFilter:accessFn('SEARCH_CONSUMER_BY_VOUCHER'),
		// 	oninputFunc: (val) => {
		// 			setFilterData({ ...filterData, ['VoucherCode'] : val  });
		// 	}
		// },
		{
			labelText:localString('Contract ID'),
			dataKey:"ContractID",
			showFilter:true,
			oninputFunc: (val) => {
					setFilterData({ ...filterData, ['ContractID'] : val  });
			}
		},
		{
			labelText:localString('Policy ID'),
			dataKey:"PolicyID",
			showFilter:true,
			oninputFunc: (val) => {
					setFilterData({ ...filterData, ['PolicyID'] : val  });
			}
		},
		{
			labelText:localString('Order ID'),
			dataKey:"OrderID",
			showFilter:true,
			oninputFunc: (val) => {
					setFilterData({ ...filterData, ['OrderID'] : val  });
			}
		}
	];

	const closeModal = () =>{
		setShowModal(false);
		setShowCallLogPage(false);
	}

	const clearSearch = () => {
		setShowClearSearch(true);
		setViewList(false);
		setPagination({
			pageNo : 1,
			itemsPerPage : 5,
			range : 5,
			count:-1,
			numberOfPages:"",
			consumerCount:""
		})
		setFilterData({
			ConsumerMobileNumber: "",
			ConsumerName: "",
			ConsumerEmail: "",
			ProductSubCategory:"",
			ProductSubCategoryID:"",
			BrandName:"",
			BrandID:"",
			ProductName:"",
			ProductID:"",
			ProductUniqueID:"",
			AlternateUniqueKey:"",
			VoucherCode:"",
			PolicyID:"",
			OrderID:"",
			ContractID:"",
		})
		setShowModal(true);
		setSpecificCountryCode("IN")
	}

	/*
		Removed Created On from Showing Details 
	*/
	const headings = [
		{
			title:localString("Name"),
			showHeading:true
		},
		{
			title:localString("Phone Number"),
			showHeading:true
		},
		{
			title:localString("Email ID"),
			showHeading:true
		},
		{
			title:localString("Voucher Code"),
			showHeading:accessFn('SEARCH_CONSUMER_BY_VOUCHER')
		},
		// {
		// 	title:localString("Created on"),
		// 	showHeading:true
		// },
		{
			title:localString("Created From"),
			showHeading:true
		},
		{
			title:"",
			showHeading:true
		}
	]

	const getImei=(e)=>{
		let filterToBeSent = '';
		let filteredIMEI1 = '';
		let filteredIMEI2 = '';
		if (filterData && filterData.ProductUniqueID) {
			filteredIMEI1 = filterData.ProductUniqueID && filterData && filterData.ProductUniqueID.trim();
		}
		if (filterData && filterData.AlternateUniqueKey) {
			filteredIMEI2 = filterData.AlternateUniqueKey && filterData.AlternateUniqueKey.trim();
		}
		if (filteredIMEI1) {
			filterToBeSent = filterToBeSent + "&imei="+filteredIMEI1
		}
		if (filteredIMEI2) {
			filterToBeSent = filterToBeSent + "&imei2="+filteredIMEI2
		}
		return filterToBeSent;
	}

	const getSource =  () => {
		if(JSON.parse(qFilter) && JSON.parse(qFilter).flowType && JSON.parse(qFilter).flowType.requestFulfilmentFlow){
			if(JSON.parse(qFilter).flowType.requestFulfilmentFlow.indexOf('REQ_FULFIL_BOSE') > -1){
				return 'BoseDashboard'
			}
			else if(JSON.parse(qFilter).flowType.requestFulfilmentFlow.indexOf('REQ_FULFIL_ONEPLUS') > -1){
				return 'OneplusDashboard'
			}else if(JSON.parse(qFilter).flowType.requestFulfilmentFlow.indexOf('REQ_FULFIL_SINGULARITY') > -1){
				return 'SingularityDashboard'
			}else if(JSON.parse(qFilter).flowType.requestFulfilmentFlow.indexOf('REQ_FULFIL_SMART_TECHNO') > -1){
				return 'SmartTechnoDashboard'
			}else{
				return 'SkyworthDashboard'
			}
			}else{
			return null
		}
	}

	/*
		Removed Device Type Filter From Add Consumer Section
	*/

	let ConsumerFields = [
		{
			labelText:localString('Name'),
			dataKey:"Name",
			customClass:'col-sm-6 paddingB10',
			placeholder:localString('Name'),
			showInput:true,
			oninputFunc: (val) => {
				setAddConsumerData({ ...addConsumerData, ['Name'] : val  });
			}
		},
		{
			labelText: localString('Mobile No'),
			dataKey:"MobileNo",
			maxlength: mobileMaxLength,
			placeholder:localString('Mobile NO'),
			customClass:'col-sm-6 paddingB10',
			type: "intelInput",
			showInput:true,
			intlTelInputClassWrapper:{
				formControl: 'form-control--top ',
				label: 'input__label',
				containerClass: 'input-container',
				inputClass: 'input'
			},
			defaultCountry: JSON.parse(qFilter) && JSON.parse(qFilter).countrycode && JSON.parse(qFilter).countrycode.length > 0 ? JSON.parse(qFilter).countrycode[0].toLowerCase()  : "in",
			changeFunc: (val, countryData) => {
				setAddConsumerData({ ...addConsumerData, ['MobileNo'] : val,countryData });
			},
			onFlagSelect: (countryData) => {
				onCountryCodeChange(countryData)
			}
		},
		{
			labelText:localString('Email ID'),
			dataKey:"EmailID",
			placeholder:localString('Email ID'),
			customClass:'col-sm-6 paddingB10',
			showInput:true,
			oninputFunc: (val) => {
				setAddConsumerData({ ...addConsumerData, ['EmailID'] : val  });
			}
		},
		// {
		// 	labelText:localString('Device Type'),
		// 	dataKey:"DeviceType",
		// 	placeholder:localString('Device Type'),
		// 	type :'Dropdown',
		// 	customClass:'col-sm-6 paddingB10',
		// 	dropdownList:[
		// 		{
		// 			value:"ios"
		// 		},
		// 		{
		// 			value:"android"
		// 		}
		// 	],
		// 	showInput:getSource() !== 'SkyworthDashboard',
		// 	oninputFunc: (val) => {
		// 		setAddConsumerData({...addConsumerData,
		// 			['DeviceType']:val.value
		// 		});
		// 	}
		// }
	];

	const closeConsumerModal = () =>{
		setConsumerShowModal(false);
	}

	useEffect(() => {
		checkConsumerDisableApplyBtn();
	}, [addConsumerData]);

	const checkConsumerDisableApplyBtn = () => {
		if((addConsumerData.MobileNo.length && (mobileMaxLength.indexOf(addConsumerData.MobileNo.length)>-1) && checkMobileNumber(addConsumerData.MobileNo))
			// && addConsumerData.DeviceType.length > 0
			&& (addConsumerData.Name.length > 0 && checkAlphaNumericName(addConsumerData.Name))
			&& (addConsumerData.EmailID=="" || (addConsumerData.EmailID && addConsumerData.EmailID.length > 5 && checkEmail(addConsumerData.EmailID)))){
				setDisableSaveBtn(false);
		} else {
				setDisableSaveBtn(true);
		}
	}

	const viewConsumerFunc = (e) => {
		if(e){
			e.preventDefault();
		}
		window.open(baseUrl + viewConsumerUrl + ConsumerID, '_blank');
	}

	const saveConsumerFunc = (e) => {
		if(e){
			e.preventDefault();
		}
		let source = getSource();
		let data = {
			"Name": addConsumerData.Name,
			"CountryID":countryID,
			"CountryCode":"+"+addConsumerData.countryData.dialCode,
			"PhoneCode": addConsumerData.countryData.dialCode,
			"MobileNo": addConsumerData.MobileNo,
			"EmailID": addConsumerData.EmailID,
			// "DeviceType": addConsumerData.DeviceType,
			"FirstRegisteredFrom": 'WebApp'
		}
		// if(source !== 'SkyworthDashboard'){
		// 	data.DeviceType = addConsumerData.DeviceType
		// }
		if(source){
			data.FirstRegisteredFrom = source
		} else {
			data.FirstRegisteredFrom = 'WebApp'
		}
		setLoader(true);
		apiCall({
			url: 'createConsumer',
			data:data,
			callBack:(result)=>{
				setLoader(false);
				setMessage(result.msg);
				if(result.success && result.data){
					setConsumerID(result.data.ConsumerID);
					setHideViewButton(false);
					setHideSaveButton(true);
				}else{
					Swal.fire({
						icon: 'error',
						text: localString(displayError(result)),
					});
				}				}
		});
	}

	return (
		<>
			{ showLoader && ReactDOM.createPortal(
				<Loader />,
				document.getElementById("loader-root")
			)}
			<PageHeader titleHeader={titleHeader} localString={localString} btnClass="btn button-servify header-btn button-stack text-capital" headerButtonArray={buttonHeader}></PageHeader>
			<PageContentWrapper
					title={localString("Filter Consumers")}
				>
					<FilterConsumer
						{...props}
						filterData={filterData}
						disableApplyBtn={disableApplyButton}
						hideClearButton={showClearSearch}
						clearSearch={clearSearch}
						filterFunc={getConsumerList}
						filterInputs={filterInputs}
					></FilterConsumer>
			</PageContentWrapper>
			{viewList && (
				<PageContentWrapper
					title={localString("Consumer Details")}
				>
					<ConsumerListData 
						setCurrentPage={setCurrentPage} 
						getImei={getImei}  {...props} 
						headings={headings} 
						clsName="text-capitalize" 
						groupedConsumers={groupedConsumers} 
						pagination={pagination} 
						callLogFunction={callLogFunction}>
					</ConsumerListData>
				</PageContentWrapper>
			)}
			{/* <FilterModal {...props} disableApplyBtn={disableApplyButton} filterFunc={getConsumerList} filterInputs={filterInputs} filterData={filterData} showModal={showModal} closeModal={closeModal} hideClearButton={true}></FilterModal> */}
			{/* {viewList && <ConsumerListData setCurrentPage={setCurrentPage} getImei={getImei}  {...props} headings={headings} clsName="text-capitalize" groupedConsumers={groupedConsumers} pagination={pagination} callLogFunction={callLogFunction}></ConsumerListData>} */}
			{callLog && <CallHistoryModal {...props} showCommunicationLogs={true} showCallHistory={true} showEmailHistory={false} showPage={showCallLogPage} closeModal={closeModal} consumerID={ConsumerID} ></CallHistoryModal>}
			<AddConsumerModal message={message} customModalClass='consumer-modal-class' saveFunc={saveConsumerFunc} viewConsumerFunc={viewConsumerFunc} hideViewButton={hideViewButton} hideSaveButton={hideSaveButton} disableSaveBtn={disableSaveBtn} addConsumerData={addConsumerData} ConsumerFields={ConsumerFields} showConsumerModal={showConsumerModal} closeConsumerModal={closeConsumerModal} {...props}></AddConsumerModal>
		</>
	)
}

export default ViewConsumerList;
