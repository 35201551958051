const lang = {
	"Payment Received": {
		"text": "Pago recibido",
		"isAlreadyTranslated": true
	},
	"Plan Validity": {
		"text": "Fecha de validez del plan",
		"isAlreadyTranslated": true
	},
	"Final Plan Price": {
		"text": "Precio final del plan",
		"isAlreadyTranslated": true
	},
	"Product Value": {
		"text": "Valor del producto",
		"isAlreadyTranslated": true
	},
	"Payment": {
		"text": "Pago",
		"isAlreadyTranslated": true
	},
	"Damage Protection": {
		"text": "Protección contra daños",
		"isAlreadyTranslated": true
	},
	"View Plans": {
		"text": "Ver planes",
		"isAlreadyTranslated": true
	},
	"TOTAL COMMISSION": {
		"text": "COMISIÓN TOTAL",
		"isAlreadyTranslated": true
	},
	"Congratulations! Your device is now registered and the plan is now activated for your device.": {
		"text": "¡Felicidades! Su dispositivo ya está registrado y el plan está activado para su dispositivo.",
		"isAlreadyTranslated": true
	},
	"Agreement Number": {
		"text": "Número de acuerdo",
		"isAlreadyTranslated": true
	},
	"Your Plan is now pending for Activation.": {
		"text": "Su plan está pendiente de activación.",
		"isAlreadyTranslated": true
	},
	"Congratulations! The customer has successfully registered their device under <APP NAME> 1 Year Accidental and Liquid Damage Protection Plan. Please help the customer to activate the plan.": {
		"text": "¡Felicidades! El cliente ha registrado con éxito su dispositivo en el plan de protección contra daños accidentales y líquidos de 1 año de <APP NAME>. Por favor, ayude al cliente a activar el plan.",
		"isAlreadyTranslated": true
	},
	"Customer Email ID": {
		"text": "Dirección de correo electrónico del cliente",
		"isAlreadyTranslated": true
	},
	"Customer Mobile No": {
		"text": "Número de móvil del cliente",
		"isAlreadyTranslated": true
	},
	"Warranty End Date": {
		"text": "Fecha de finalización de la garantía",
		"isAlreadyTranslated": true
	},
	"Maximum": {
		"text": "Máximo",
		"isAlreadyTranslated": true
	},
	"Characters Allowed": {
		"text": "caracteres permitidos",
		"isAlreadyTranslated": true
	},
	"Product Value cannot be less than 500": {
		"text": "El valor del producto no puede ser inferior a 500",
		"isAlreadyTranslated": true
	},
	"Sell now": {
		"text": "Vender ahora",
		"isAlreadyTranslated": true
	},
	"Sell Now": {
		"text": "Vender ahora",
		"isAlreadyTranslated": true
	},
	"Know more": {
		"text": "Saber más",
		"isAlreadyTranslated": true
	},
	"Know More": {
		"text": "Saber más",
		"isAlreadyTranslated": true
	},
	"Transferred": {
		"text": "Transferido",
		"isAlreadyTranslated": true
	},
	"Please ensure the below address is correct": {
		"text": "Por favor, asegúrese de que la siguiente dirección es correcta",
		"isAlreadyTranslated": true
	},
	"Preview Address": {
		"text": "Vista previa de la dirección",
		"isAlreadyTranslated": true
	},
	"Replacement options provided": {
		"text": "Opciones de sustitución proporcionadas",
		"isAlreadyTranslated": true
	},
	"Pending for reimbursement": {
		"text": "Pendiente de reembolso",
		"isAlreadyTranslated": true
	},
	"Replacement authorized": {
		"text": "Sustitución autorizada",
		"isAlreadyTranslated": true
	},
	"Replacement unavailable": {
		"text": "Sustitución no disponible",
		"isAlreadyTranslated": true
	},
	"In-home request accepted": {
		"text": "Solicitud a domicilio aceptada",
		"isAlreadyTranslated": true
	},
	"Okay, Got it": {
		"text": "Bien, lo tengo",
		"isAlreadyTranslated": true
	},
	"Order ID Details": {
		"text": "Detalles del pedido",
		"isAlreadyTranslated": true
	},
	"Enter Mobile number/Email ID": {
		"text": "Introduzca el número de móvil/la dirección de correo electrónico",
		"isAlreadyTranslated": true
	},
	"Enter Mobile Number": {
		"text": "Introduzca el número de móvil",
		"isAlreadyTranslated": true
	},
	"Active Plans": {
		"text": "Planes activos",
		"isAlreadyTranslated": true
	},
	"valid till": {
		"text": "Válido hasta",
		"isAlreadyTranslated": true
	},
	"covered under": {
		"text": "cubierto por",
		"isAlreadyTranslated": true
	},
	"Enter Device Attributes": {
		"text": "Introducir los detalles del dispositivo",
		"isAlreadyTranslated": true
	},
	"Storage": {
		"text": "Almacenamiento",
		"isAlreadyTranslated": true
	},
	"Colour": {
		"text": "Color",
		"isAlreadyTranslated": true
	},
	"Skip": {
		"text": "Saltar",
		"isAlreadyTranslated": true
	},
	"Select a Coverage": {
		"text": "Seleccione una cobertura",
		"isAlreadyTranslated": true
	},
	"Accidental Damage": {
		"text": "Daños accidentales",
		"isAlreadyTranslated": true
	},
	"Coverage Start Date": {
		"text": "Fecha de incio de la cobertura",
		"isAlreadyTranslated": true
	},
	"Theft": {
		"text": "Robo",
		"isAlreadyTranslated": true
	},
	"Location of Incident": {
		"text": "Ubicación del incidente",
		"isAlreadyTranslated": true
	},
	"City name/Short address": {
		"text": "Nombre de la ciudad/dirección",
		"isAlreadyTranslated": true
	},
	"Communication Details": {
		"text": "Detalles de la comunicación",
		"isAlreadyTranslated": true
	},
	"Use Same Contact Details": {
		"text": "Utilizar los mismos datos de contacto",
		"isAlreadyTranslated": true
	},
	"Use Different Contact Details": {
		"text": "Utilizar diferentes datos de contacto",
		"isAlreadyTranslated": true
	},
	"Calling Number": {
		"text": "Número de teléfono",
		"isAlreadyTranslated": true
	},
	"Support Ticket Number": {
		"text": "Número de recibo de soporte",
		"isAlreadyTranslated": true
	},
	"Communication history": {
		"text": "Historia de la comunicación",
		"isAlreadyTranslated": true
	},
	"Initiate": {
		"text": "Iniciar",
		"isAlreadyTranslated": true
	},
	"Update Logistics Request": {
		"text": "Actualizar la solicitud de envío",
		"isAlreadyTranslated": true
	},
	"Customer's Contact name": {
		"text": "Nombre de contacto del cliente",
		"isAlreadyTranslated": true
	},
	"Customer's Contact Number": {
		"text": "Número de contacto del cliente",
		"isAlreadyTranslated": true
	},
	"Service Center Contact Number": {
		"text": "Número de contacto del servicio técnico",
		"isAlreadyTranslated": true
	},
	"Please select a reason for cancelling": {
		"text": "Por favor, seleccione un motivo de cancelación",
		"isAlreadyTranslated": true
	},
	"Communication Name": {
		"text": "Nombre de la comunicación",
		"isAlreadyTranslated": true
	},
	"Communication Number": {
		"text": "Número de comunicación",
		"isAlreadyTranslated": true
	},
	"Communication EmailID": {
		"text": "Correo electrónico para la comunicación",
		"isAlreadyTranslated": true
	},
	"Your claim request has been accepted. We will notify you about the resolution being offered shortly": {
		"text": "La solicitud de reclamación ha sido aceptada. En breve le informaremos de la resolución que podemos ofrecerle",
		"isAlreadyTranslated": true
	},
	"We have received your claim request. We will notify you once it is approved": {
		"text": "Hemos recibido su solicitud de reclamación. Le notificaremos una vez que se apruebe",
		"isAlreadyTranslated": true
	},
	"Waiting for customer to make the payment for advance deductible": {
		"text": "A la espera de que el cliente realice el pago de la franquicia anticipada",
		"isAlreadyTranslated": true
	},
	"Payment link has been sent to the user on registered mobile number and/or Email ID": {
		"text": "Se ha enviado el enlace de pago al usuario al número de móvil registrado y/o al correo electrónico",
		"isAlreadyTranslated": true
	},
	"Select Pickup Address": {
		"text": "Seleccionar la dirección de recogida",
		"isAlreadyTranslated": true
	},
	"Payment link has been sent to the user on registered mobile number and/or Email ID": {
		"text": "Se ha enviado el enlace de pago al usuario al número de móvil registrado y/o al correo electrónico",
		"isAlreadyTranslated": true
	},
	"test cancelled": {
		"text": "prueba anulada",
		"isAlreadyTranslated": true
	},
	"Service Mode": {
		"text": "Modo de mantenimiento",
		"isAlreadyTranslated": true
	},
	"Slot and Date": {
		"text": "Franja horaria y fecha",
		"isAlreadyTranslated": true
	},
	"Preview Address": {
		"text": "Vista previa de la dirección",
		"isAlreadyTranslated": true
	},
	"Please ensure the below address is correct": {
		"text": "Por favor, asegúrese de que la siguiente dirección es correcta",
		"isAlreadyTranslated": true
	},
	"Proceed": {
		"text": "Proceder",
		"isAlreadyTranslated": true
	},
	"Street address": {
		"text": "Calle",
		"isAlreadyTranslated": true
	},
	"Street address is required": {
		"text": "Se requiere el nombre de la calle",
		"isAlreadyTranslated": true
	},
	"Postal code": {
		"text": "Código postal",
		"isAlreadyTranslated": true
	},
	"Postal code is required": {
		"text": "Se requiere el código postal",
		"isAlreadyTranslated": true
	},
	"City/Town": {
		"text": "Ciudad/Pueblo",
		"isAlreadyTranslated": true
	},
	"City/Town is required": {
		"text": "Se requiere la ciudad/localidad",
		"isAlreadyTranslated": true
	},
	"Please choose one of the reimbursement options below": {
		"text": "Elija una de las opciones de reembolso siguientes",
		"isAlreadyTranslated": true
	},
	"Inform customer they will get link to upload video, post which reimbursement process will start as we are not asking for the defective device back": {
		"text": "Informar al cliente de que recibirá un enlace para subir el vídeo, tras lo cual se iniciará el proceso de reembolso, ya que no pedimos la devolución del dispositivo defectuoso",
		"isAlreadyTranslated": true
	},
	"Location Name": {
		"text": "Nombre de la ubicación",
		"isAlreadyTranslated": true
	},
	"Send for raising a request": {
		"text": "Enviar para iniciar una solicitud",
		"isAlreadyTranslated": true
	},
	"The request was raised successfully": {
		"text": "La solicitud se ha iniciado con éxito",
		"isAlreadyTranslated": true
	},
	"Interval": {
		"text": "Intervalo",
		"isAlreadyTranslated": true
	},
	"Communication Name": {
		"text": "Nombre de la comunicación",
		"isAlreadyTranslated": true
	},
	"Communication Number": {
		"text": "Número de comunicación",
		"isAlreadyTranslated": true
	},
	"Communication EmailID": {
		"text": "Correo electrónico para la comunicación",
		"isAlreadyTranslated": true
	},
	"DropOff request accepted": {
		"text": "Solicitud de entrega aceptada",
		"isAlreadyTranslated": true
	},
	"Shipment Charge": {
		"text": "Gastos de envío",
		"isAlreadyTranslated": true
	},
	"Delivery Address": {
		"text": "Dirección de entrega",
		"isAlreadyTranslated": true
	},
	"Additional Remarks": {
		"text": "Observaciones adicionales",
		"isAlreadyTranslated": true
	},
	"ID e-mail": {
		"text": "Correo electrónico",
		"isAlreadyTranslated": true
	},
	"update logistics request": {
		"text": "actualizar la solicitud de envío",
		"isAlreadyTranslated": true
	},
	"Vendor tracking URL": {
		"text": "URL de seguimiento de proveedores",
		"isAlreadyTranslated": true
	},
	"Click here for Vendor Tracking URL": {
		"text": "Haga clic aquí para ver la URL de seguimiento de los proveedores",
		"isAlreadyTranslated": true
	},
	"Contact number": {
		"text": "Número de contacto",
		"isAlreadyTranslated": true
	},
	"Yes": {
		"text": "Sí",
		"isAlreadyTranslated": true
	},
	"Out of Warranty ": {
		"text": "Fuera de garantía ",
		"isAlreadyTranslated": true
	},
	"Under Warranty": {
		"text": "En garantía",
		"isAlreadyTranslated": true
	},
	"save": {
		"text": "guardar",
		"isAlreadyTranslated": true
	},
	"No": {
		"text": "No",
		"isAlreadyTranslated": true
	},
	"Warranty Concession": {
		"text": "Concesión de garantía",
		"isAlreadyTranslated": true
	},
	"ADD NEW ENGLISH STRINGS": {
		"text": "ADD NEW ENGLISH STRINGS",
		"isAlreadyTranslated": true
	},
	"0 request(s) have open task(s)": {
		"text": "0 solicitud(es) tiene(n) tarea(s) abierta(s)",
		"isAlreadyTranslated": true
	},
	"Select brand": {
		"text": "Seleccione la marca",
		"isAlreadyTranslated": true
	},
	"Enter the IMEI / Sériové číslo": {
		"text": "Introduzca el IMEI",
		"isAlreadyTranslated": true
	},
	"Sorry, your product could not be validated, please contact admin for support.": {
		"text": "Lo sentimos, su producto no ha podido ser validado, por favor, póngase en contacto con el administrador para obtener ayuda.",
		"isAlreadyTranslated": true
	},
	"Claim Carryin ": {
		"text": "Reclamación de transporte ",
		"isAlreadyTranslated": true
	},
	"Claim Pickup ": {
		"text": "Reclamación de recogida ",
		"isAlreadyTranslated": true
	},
	"Pick Up ": {
		"text": "Recogida ",
		"isAlreadyTranslated": true
	},
	"Trade In Dropoff ": {
		"text": "Entrega de la mercancía ",
		"isAlreadyTranslated": true
	},
	"Advance Exchange w/o Defective ": {
		"text": "Intercambio por adelantado sin defecto ",
		"isAlreadyTranslated": true
	},
	"Claim Dropoff ": {
		"text": "Reclamación de entrega ",
		"isAlreadyTranslated": true
	},
	"Customers": {
		"text": "Clientes",
		"isAlreadyTranslated": true
	},
	"Filter": {
		"text": "Filtrar",
		"isAlreadyTranslated": true
	},
	"Consumer Mobile": {
		"text": "Teléfono del cliente",
		"isAlreadyTranslated": true
	},
	"Consumer Email": {
		"text": "Correo electrónico del cliente",
		"isAlreadyTranslated": true
	},
	"Subcategory": {
		"text": "Subcategoría",
		"isAlreadyTranslated": true
	},
	"Brand": {
		"text": "Marca",
		"isAlreadyTranslated": true
	},
	"Product": {
		"text": "Producto",
		"isAlreadyTranslated": true
	},
	"IMEI Number 1": {
		"text": "Número IMEI 1",
		"isAlreadyTranslated": true
	},
	"IMEI Number 2": {
		"text": "Número IMEI 2",
		"isAlreadyTranslated": true
	},
	"Apply": {
		"text": "Aplicar",
		"isAlreadyTranslated": true
	},
	"Diagnosis": {
		"text": "Diagnóstico",
		"isAlreadyTranslated": true
	},
	"Mobile Number": {
		"text": "Número de móvil",
		"isAlreadyTranslated": true
	},
	"OR": {
		"text": "O",
		"isAlreadyTranslated": true
	},
	"Serial Number/IMEI": {
		"text": "IMEI/Número de serie",
		"isAlreadyTranslated": true
	},
	"Jobsheet No./Reference ID": {
		"text": "Número de albarán/identificación de referencia",
		"isAlreadyTranslated": true
	},
	"Notifications": {
		"text": "Notificaciones",
		"isAlreadyTranslated": true
	},
	"Coupons": {
		"text": "Cupones",
		"isAlreadyTranslated": true
	},
	"Last Month:": {
		"text": "El mes pasado:",
		"isAlreadyTranslated": true
	},
	"Redeemed Coupons": {
		"text": "Cupones canjeados",
		"isAlreadyTranslated": true
	},
	"Last Week:": {
		"text": "La semana pasada:",
		"isAlreadyTranslated": true
	},
	"Last Day:": {
		"text": "Ayer:",
		"isAlreadyTranslated": true
	},
	"Create and Edit Coupons": {
		"text": "Crear y editar cupones",
		"isAlreadyTranslated": true
	},
	"Plan Management": {
		"text": "Gestión de planes",
		"isAlreadyTranslated": true
	},
	"Filter Consumers": {
		"text": "Filtrar clientes",
		"isAlreadyTranslated": true
	},
	"Mobile Number": {
		"text": "Número de móvil",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial Number": {
		"text": "IMEI/Número de serie",
		"isAlreadyTranslated": true
	},
	"Email ID": {
		"text": "Dirección de correo electrónico",
		"isAlreadyTranslated": true
	},
	"Order Item ID": {
		"text": "Identificador del artículo",
		"isAlreadyTranslated": true
	},
	"Order ID": {
		"text": "ID de la orden",
		"isAlreadyTranslated": true
	},
	"Apply": {
		"text": "Aplicar",
		"isAlreadyTranslated": true
	},
	"Sell plans": {
		"text": "Vender planes",
		"isAlreadyTranslated": true
	},
	"Sell Plans": {
		"text": "Vender planes",
		"isAlreadyTranslated": true
	},
	"Select Brand": {
		"text": "Seleccione la marca",
		"isAlreadyTranslated": true
	},
	"PLANS": {
		"text": "PLANES",
		"isAlreadyTranslated": true
	},
	"Exceptional Sales": {
		"text": "Ventas excepcionales",
		"isAlreadyTranslated": true
	},
	"Protect": {
		"text": "Proteger",
		"isAlreadyTranslated": true
	},
	"Damage protection": {
		"text": "Protección contra daños",
		"isAlreadyTranslated": true
	},
	"User list": {
		"text": "Lista de usuarios",
		"isAlreadyTranslated": true
	},
	"USER MANAGEMENT": {
		"text": "GESTIÓN DE USUARIOS",
		"isAlreadyTranslated": true
	},
	"Invite Service User": {
		"text": "Invitar al usuario del servicio",
		"isAlreadyTranslated": true
	},
	"Invite Retailer user": {
		"text": "Invitar al usuario minorista",
		"isAlreadyTranslated": true
	},
	"Search by Email": {
		"text": "Búsqueda por correo electrónico",
		"isAlreadyTranslated": true
	},
	"LOGISTICS REQUESTS": {
		"text": "SOLICITUDES DE LOGÍSTICA",
		"isAlreadyTranslated": true
	},
	"WayBill Number": {
		"text": "Número de albarán",
		"isAlreadyTranslated": true
	},
	"Reference Number": {
		"text": "Número de referencia",
		"isAlreadyTranslated": true
	},
	"Pickup Name": {
		"text": "Nombre de la recogida",
		"isAlreadyTranslated": true
	},
	"Pickup Address": {
		"text": "Dirección de recogida",
		"isAlreadyTranslated": true
	},
	"Drop Name": {
		"text": "Nombre de la entrega",
		"isAlreadyTranslated": true
	},
	"Drop Address": {
		"text": "Dirección de la entrega",
		"isAlreadyTranslated": true
	},
	"Status": {
		"text": "Estado",
		"isAlreadyTranslated": true
	},
	"Pickup Contact No": {
		"text": "Número de contacto de recogida",
		"isAlreadyTranslated": true
	},
	"Drop Contact No": {
		"text": "Número de contacto de la entrega",
		"isAlreadyTranslated": true
	},
	"Create Request": {
		"text": "Crear solicitud",
		"isAlreadyTranslated": true
	},
	"Filter Requests": {
		"text": "Filtrar solicitudes",
		"isAlreadyTranslated": true
	},
	"CREATE SHIPMENT": {
		"text": "CREAR EL ENVÍO",
		"isAlreadyTranslated": true
	},
	"*All fields are mandatory.": {
		"text": "*Todos los campos son obligatorios.",
		"isAlreadyTranslated": true
	},
	"Product Detail": {
		"text": "Detalle del producto",
		"isAlreadyTranslated": true
	},
	"Brand": {
		"text": "Marca",
		"isAlreadyTranslated": true
	},
	"Product": {
		"text": "Producto",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial No": {
		"text": "IMEI/Número de serie",
		"isAlreadyTranslated": true
	},
	"Pick Up Detail": {
		"text": "Detalle de la recogida",
		"isAlreadyTranslated": true
	},
	"Name": {
		"text": "Nombre",
		"isAlreadyTranslated": true
	},
	"Contact No.": {
		"text": "Número de contacto",
		"isAlreadyTranslated": true
	},
	"Address": {
		"text": "Dirección",
		"isAlreadyTranslated": true
	},
	"Pin Code": {
		"text": "Código Pin",
		"isAlreadyTranslated": true
	},
	"City": {
		"text": "Ciudad",
		"isAlreadyTranslated": true
	},
	"State": {
		"text": "Estado",
		"isAlreadyTranslated": true
	},
	"Country": {
		"text": "País",
		"isAlreadyTranslated": true
	},
	"Drop Detail": {
		"text": "Detalle de la entrega",
		"isAlreadyTranslated": true
	},
	"Name": {
		"text": "Nombre",
		"isAlreadyTranslated": true
	},
	"Contact No.": {
		"text": "Número de contacto",
		"isAlreadyTranslated": true
	},
	"Address": {
		"text": "Dirección",
		"isAlreadyTranslated": true
	},
	"Landmark": {
		"text": "Dirección",
		"isAlreadyTranslated": true
	},
	"Pin Code": {
		"text": "Código Pin",
		"isAlreadyTranslated": true
	},
	"City": {
		"text": "Ciudad",
		"isAlreadyTranslated": true
	},
	"State": {
		"text": "Estado",
		"isAlreadyTranslated": true
	},
	"Country": {
		"text": "País",
		"isAlreadyTranslated": true
	},
	"Filter": {
		"text": "Filtrar",
		"isAlreadyTranslated": true
	},
	"WayBill Number": {
		"text": "Número de albarán",
		"isAlreadyTranslated": true
	},
	"Reference Number": {
		"text": "Número de referencia",
		"isAlreadyTranslated": true
	},
	"Pickup Name": {
		"text": "Nombre de la recogida",
		"isAlreadyTranslated": true
	},
	"Pickup Address": {
		"text": "Dirección de recogida",
		"isAlreadyTranslated": true
	},
	"Drop Name": {
		"text": "Nombre de la entrega",
		"isAlreadyTranslated": true
	},
	"Drop Address": {
		"text": "Dirección de la entrega",
		"isAlreadyTranslated": true
	},
	"Status": {
		"text": "Estado",
		"isAlreadyTranslated": true
	},
	"Pickup Contact No": {
		"text": "Número de contacto de recogida",
		"isAlreadyTranslated": true
	},
	"Drop Contact No": {
		"text": "Número de contacto de la entrega",
		"isAlreadyTranslated": true
	},
	"Status": {
		"text": "Estado",
		"isAlreadyTranslated": true
	},
	"Vendor": {
		"text": "Proveedor",
		"isAlreadyTranslated": true
	},
	"Flow": {
		"text": "Flujo",
		"isAlreadyTranslated": true
	},
	"IMEI": {
		"text": "IMEI",
		"isAlreadyTranslated": true
	},
	"Apply": {
		"text": "Aplicar",
		"isAlreadyTranslated": true
	},
	"Clear": {
		"text": "Borrar",
		"isAlreadyTranslated": true
	},
	"I have explained the Terms and Conditions and Privacy Policy to the customer, and help them read the IPID before moving ahead with contract activation": {
		"text": "Le he explicado al cliente los Términos y Condicionesy la Política de Privacidad, y le he ayudado a leer el Documento de Información Previa (IPID) antes de seguir adelante con la activación del contrato",
		"isAlreadyTranslated": true
	},
	"Protect": {
		"text": "Proteger",
		"isAlreadyTranslated": true
	},
	"Something went wrong": {
		"text": "Algo salió mal",
		"isAlreadyTranslated": true
	},
	"Customer Mobile No:": {
		"text": "Número de móvil del cliente",
		"isAlreadyTranslated": true
	},
	"Customer Email ID": {
		"text": "Dirección de correo electrónico del cliente",
		"isAlreadyTranslated": true
	},
	"Customer Date of Birth": {
		"text": "Fecha de nacimiento del cliente",
		"isAlreadyTranslated": true
	},
	"Warranty End Date": {
		"text": "Fecha de finalización de la garantía",
		"isAlreadyTranslated": true
	},
	"Product Value:": {
		"text": "Valor del producto:",
		"isAlreadyTranslated": true
	},
	"Plan Validity": {
		"text": "Fecha de validez del plan",
		"isAlreadyTranslated": true
	},
	"Final Plan Price": {
		"text": "Precio final del plan",
		"isAlreadyTranslated": true
	},
	"Privacy Policy": {
		"text": "Política de privacidad",
		"isAlreadyTranslated": true
	},
	"Payment Received": {
		"text": "Pago recibido",
		"isAlreadyTranslated": true
	},
	"Payment": {
		"text": "Pago",
		"isAlreadyTranslated": true
	},
	"Invalid OTP": {
		"text": "OTP inválido",
		"isAlreadyTranslated": true
	},
	"Resend OTP": {
		"text": "Reenviar OTP",
		"isAlreadyTranslated": true
	},
	"Maximum 30 Characters Allowed": {
		"text": "Máximo 30 caracteres permitidos",
		"isAlreadyTranslated": true
	},
	"View Plans": {
		"text": "Ver planes",
		"isAlreadyTranslated": true
	},
	"Date of Birth": {
		"text": "Fecha de nacimiento",
		"isAlreadyTranslated": true
	},
	"IPID": {
		"text": "Documento de Información Previa (IPID)",
		"isAlreadyTranslated": true
	},
	"Product Value:": {
		"text": "Valor del producto:",
		"isAlreadyTranslated": true
	},
	"The Plan is now pending for Activation": {
		"text": "El plan está pendiente de activación",
		"isAlreadyTranslated": true
	},
	"Congratulations!": {
		"text": "¡Felicidades!",
		"isAlreadyTranslated": true
	},
	"The customer has successfully registered their device under <BrandName> Care + Planul de protecție împotriva daunelor accidentale și lichide de 1 an.": {
		"text": "El cliente ha registrado correctamente su dispositivo en el plan <APP NAME> contra daños accidentales y líquidos de 1 año.",
		"isAlreadyTranslated": true
	},
	"Please help the customer to activate the plan. ": {
		"text": "Por favor, ayude al cliente a activar el plan. ",
		"isAlreadyTranslated": true
	},
	"Selected Coverage": {
		"text": "Cobertura seleccionada",
		"isAlreadyTranslated": true
	},
	"Physical damage": {
		"text": "Daños físicos",
		"isAlreadyTranslated": true
	},
	"One Time Deductible(Admin Fees)": {
		"text": "Deducible único (tasas administrativas)",
		"isAlreadyTranslated": true
	},
	"Location Of Incident": {
		"text": "Ubicación del incidente",
		"isAlreadyTranslated": true
	},
	"Maximum 30 Characters Allowed": {
		"text": "Máximo 30 caracteres permitidos",
		"isAlreadyTranslated": true
	},
	"Successfully Purchased": {
		"text": "comprado con éxito",
		"isAlreadyTranslated": true
	},
	"Phone Will Not Hold a Charge": {
		"text": "El teléfono no carga",
		"isAlreadyTranslated": true
	},
	"Select Drop Address": {
		"text": "Seleccionar la dirección",
		"isAlreadyTranslated": true
	},
	"Customer's Contact name": {
		"text": "Nombre de contacto del cliente",
		"isAlreadyTranslated": true
	},
	"Customer's Contact Number": {
		"text": "Número de contacto del cliente",
		"isAlreadyTranslated": true
	},
	"Service Center Contact Number": {
		"text": "Número de contacto del servicio técnico",
		"isAlreadyTranslated": true
	},
	"Calling Number": {
		"text": "Número de teléfono",
		"isAlreadyTranslated": true
	},
	"Support Ticket Number:": {
		"text": "Número de recibo de soporte:",
		"isAlreadyTranslated": true
	},
	"Something went wrong": {
		"text": "Algo salió mal",
		"isAlreadyTranslated": true
	},
	"Scheduled date": {
		"text": "Fecha prevista",
		"isAlreadyTranslated": true
	},
	"The course": {
		"text": "El curso",
		"isAlreadyTranslated": true
	},
	"Flow Provider": {
		"text": "Proveedor de flujo",
		"isAlreadyTranslated": true
	},
	"No consumer product found for this consumer service request": {
		"text": "No se ha encontrado ningún producto para esta solicitud de servicio",
		"isAlreadyTranslated": true
	},
	"Servify-Retail-Portal": {
		"text": "Portal de minoristas de Servify",
		"isAlreadyTranslated": true
	},
	"Please select a reason for cancelling": {
		"text": "Por favor, seleccione un motivo de cancelación",
		"isAlreadyTranslated": true
	},
	"Sorry": {
		"text": "Lo sentimos",
		"isAlreadyTranslated": true
	},
	"Pickup request accepted": {
		"text": "Solicitud de recogida aceptada",
		"isAlreadyTranslated": true
	},
	"Resend OTP in": {
		"text": "Reenviar OTP en",
		"isAlreadyTranslated": true
	},
	"PSČ is reqiured": {
		"text": "Se requiere OTP para la realización de la prueba",
		"isAlreadyTranslated": true
	},
	"Existing Data Found We have found existing data for this product and will use the same to recheck eligibility for this plan.": {
		"text": "Datos existentes encontrados Hemos encontrado datos existentes para este producto y los utilizaremos para volver a comprobar la elegibilidad de este plan.",
		"isAlreadyTranslated": true
	},
	"Please enter Product Value": {
		"text": "Por favor, introduzca el valor del producto",
		"isAlreadyTranslated": true
	},
	"is required": {
		"text": "es necesario",
		"isAlreadyTranslated": true
	},
	"Sign in to continue.": {
		"text": "Inicia sesión para continuar.",
		"isAlreadyTranslated": true
	},
	"Forgot Password?": {
		"text": "¿Ha olvidado su contraseña?",
		"isAlreadyTranslated": true
	},
	"sign in": {
		"text": "iniciar sesión",
		"isAlreadyTranslated": true
	},
	"Copyright © 2015-2022 Service Lee Technologies Pvt Ltd. All rights reserved.Also known as Servify.": {
		"text": "Copyright © 2015-2022 Service Lee Technologies Pvt Ltd. Todos los derechos reservadosTambién conocido como Servify.",
		"isAlreadyTranslated": true
	},
	"Sorry, check back soon. We're in process of adding more products": {
		"text": "Lo sentimos, vuelva a comprobarlo pronto. Estamos en proceso de añadir más productos",
		"isAlreadyTranslated": true
	},
	"View Transaction Summary": {
		"text": "Ver el resumen de la transacción",
		"isAlreadyTranslated": true
	},
	"<BrandName> Plans": {
		"text": "Planes <BrandName>",
		"isAlreadyTranslated": true
	},
	"<BrandName> Planuri": {
		"text": "<BrandName> Planuri",
		"isAlreadyTranslated": true
	},
	"Dashboard": {
		"text": "Interfaz",
		"isAlreadyTranslated": true
	},
	"Logout": {
		"text": "Cerrar sesión",
		"isAlreadyTranslated": true
	},
	"Transaction Summary": {
		"text": "Resumen de la transacción",
		"isAlreadyTranslated": true
	},
	"Select Category": {
		"text": "Selecciona una categoría",
		"isAlreadyTranslated": true
	},
	"Close": {
		"text": "Cerrar",
		"isAlreadyTranslated": true
	},
	"Contact Information": {
		"text": "Información de contacto",
		"isAlreadyTranslated": true
	},
	"Send OTP": {
		"text": "Enviar OTP",
		"isAlreadyTranslated": true
	},
	"Back": {
		"text": "Volver",
		"isAlreadyTranslated": true
	},
	"Enter the OTP sent to": {
		"text": "Introduzca la OTP enviada a",
		"isAlreadyTranslated": true
	},
	"Confirm OTP": {
		"text": "Confirmar OTP",
		"isAlreadyTranslated": true
	},
	"Plans": {
		"text": "Planes",
		"isAlreadyTranslated": true
	},
	"Plan Name": {
		"text": "Nombre del plan",
		"isAlreadyTranslated": true
	},
	"Price": {
		"text": "Precio",
		"isAlreadyTranslated": true
	},
	"Serial Number": {
		"text": "Número de serie",
		"isAlreadyTranslated": true
	},
	"Check Eligibility": {
		"text": "Comprobar la elegibilidad",
		"isAlreadyTranslated": true
	},
	"Select Plans": {
		"text": "Seleccione los planes",
		"isAlreadyTranslated": true
	},
	"Sell": {
		"text": "Vender",
		"isAlreadyTranslated": true
	},
	"Period": {
		"text": "Período",
		"isAlreadyTranslated": true
	},
	"Transaction Status": {
		"text": "Estado de la transacción",
		"isAlreadyTranslated": true
	},
	"completed": {
		"text": "completado",
		"isAlreadyTranslated": true
	},
	"Completed": {
		"text": "Completado",
		"isAlreadyTranslated": true
	},
	"Pending": {
		"text": "Pendiente",
		"isAlreadyTranslated": true
	},
	"Failed": {
		"text": "Fallido",
		"isAlreadyTranslated": true
	},
	"Report is empty.": {
		"text": "El informe está vacío.",
		"isAlreadyTranslated": true
	},
	"Plan": {
		"text": "Plan",
		"isAlreadyTranslated": true
	},
	"Invoice": {
		"text": "Factura",
		"isAlreadyTranslated": true
	},
	"Mobile No.": {
		"text": "Número de teléfono",
		"isAlreadyTranslated": true
	},
	"Alternative Mobile No": {
		"text": "Móvil alternativo No",
		"isAlreadyTranslated": true
	},
	"Brands": {
		"text": "Marcas",
		"isAlreadyTranslated": true
	},
	"Products": {
		"text": "Productos",
		"isAlreadyTranslated": true
	},
	"Upload Bulk Plan": {
		"text": "Cargar planes",
		"isAlreadyTranslated": true
	},
	"Please download the sample file, make changes in it only and upload it": {
		"text": "Por favor, descargue el archivo de muestra, realice los cambios en él solamente y súbalo",
		"isAlreadyTranslated": true
	},
	".csv files only": {
		"text": "solo archivos .csv",
		"isAlreadyTranslated": true
	},
	"Download Sample File": {
		"text": "Descargar archivo de muestra",
		"isAlreadyTranslated": true
	},
	"Upload File": {
		"text": "Cargar archivo",
		"isAlreadyTranslated": true
	},
	"Submit File": {
		"text": "Enviar archivo",
		"isAlreadyTranslated": true
	},
	"Cancel": {
		"text": "Cancelar",
		"isAlreadyTranslated": true
	},
	"Custom Range": {
		"text": "Personalizar rango",
		"isAlreadyTranslated": true
	},
	"Total Count": {
		"text": "Recuento total",
		"isAlreadyTranslated": true
	},
	"Device is eligible!": {
		"text": "¡El dispositivo es elegible!",
		"isAlreadyTranslated": true
	},
	"Device is eligible for multiple plans. Please choose a plan.": {
		"text": "El dispositivo es elegible para múltiples planes. Por favor, elija un plan.",
		"isAlreadyTranslated": true
	},
	"Verify Device": {
		"text": "Verificar el dispositivo",
		"isAlreadyTranslated": true
	},
	"Customer Details": {
		"text": "Detalles del cliente",
		"isAlreadyTranslated": true
	},
	"Review Order": {
		"text": "Pedido de revisión",
		"isAlreadyTranslated": true
	},
	"Order Summary": {
		"text": "Resumen del pedido",
		"isAlreadyTranslated": true
	},
	"Enter your mobile number registered with Paytm": {
		"text": "Introduzca su número de móvil registrado en Paytm",
		"isAlreadyTranslated": true
	},
	"Qty": {
		"text": "Cantidad",
		"isAlreadyTranslated": true
	},
	"Rate": {
		"text": "Valor",
		"isAlreadyTranslated": true
	},
	"Total": {
		"text": "Total",
		"isAlreadyTranslated": true
	},
	"Discount": {
		"text": "Descuento",
		"isAlreadyTranslated": true
	},
	"Taxable value": {
		"text": "Valor imponible",
		"isAlreadyTranslated": true
	},
	"CGST": {
		"text": "GST central",
		"isAlreadyTranslated": true
	},
	"Amount": {
		"text": "Importe",
		"isAlreadyTranslated": true
	},
	"SGST": {
		"text": "GST estatal",
		"isAlreadyTranslated": true
	},
	"IGST": {
		"text": "GST integrado",
		"isAlreadyTranslated": true
	},
	"Invoice Total": {
		"text": "Total de la factura",
		"isAlreadyTranslated": true
	},
	"Go to Transaction Summary": {
		"text": "Ir al resumen de la transacción",
		"isAlreadyTranslated": true
	},
	"Please do not close the window or press the back button.": {
		"text": "Por favor, no cierre la ventana ni pulse el botón de retroceso.",
		"isAlreadyTranslated": true
	},
	"Date of Purchase": {
		"text": "Fecha de compra",
		"isAlreadyTranslated": true
	},
	"Congratulations! Your device is eligible for this plan": {
		"text": "¡Felicidades! Su dispositivo es elegible para este plan",
		"isAlreadyTranslated": true
	},
	"This Device is Eligible for this Plan": {
		"text": "Este dispositivo es elegible para este plan",
		"isAlreadyTranslated": true
	},
	"Product Name": {
		"text": "Nombre del producto",
		"isAlreadyTranslated": true
	},
	"Validity": {
		"text": "Validez",
		"isAlreadyTranslated": true
	},
	"Price of Plan": {
		"text": "Precio del plan",
		"isAlreadyTranslated": true
	},
	"Enter a location": {
		"text": "Introduzca una ubicación",
		"isAlreadyTranslated": true
	},
	"Landmark is required": {
		"text": "Se requiere un punto de referencia",
		"isAlreadyTranslated": true
	},
	"City is required": {
		"text": "La ciudad es necesaria",
		"isAlreadyTranslated": true
	},
	"State is required": {
		"text": "El estado es necesario",
		"isAlreadyTranslated": true
	},
	"Home": {
		"text": "Inicio",
		"isAlreadyTranslated": true
	},
	"Office": {
		"text": "Oficina",
		"isAlreadyTranslated": true
	},
	"Other": {
		"text": "Otros",
		"isAlreadyTranslated": true
	},
	"Transaction Details": {
		"text": "Detalles de Transacciones",
		"isAlreadyTranslated": true
	},
	"Device Details": {
		"text": "Detalles del dispositivo",
		"isAlreadyTranslated": true
	},
	"Device Images": {
		"text": "Imágenes del dispositivo",
		"isAlreadyTranslated": true
	},
	"(All 6 Images are Mandatory)": {
		"text": "(Las 6 imágenes son obligatorias)",
		"isAlreadyTranslated": true
	},
	"Device Invoice": {
		"text": "Factura del dispositivo",
		"isAlreadyTranslated": true
	},
	"Device Image": {
		"text": "Imagen del dispositivo",
		"isAlreadyTranslated": true
	},
	"Save": {
		"text": "Guardar",
		"isAlreadyTranslated": true
	},
	"Continue": {
		"text": "Continuar",
		"isAlreadyTranslated": true
	},
	"Minimum length is two characters": {
		"text": "La longitud mínima es de dos caracteres",
		"isAlreadyTranslated": true
	},
	"Please enter correct Email ID": {
		"text": "Por favor, introduzca una dirección de correo electrónico válida",
		"isAlreadyTranslated": true
	},
	"Please enter correct Mobile No": {
		"text": "Por favor, introduzca un número de móvil válido",
		"isAlreadyTranslated": true
	},
	"Customer Name": {
		"text": "Nombre del cliente",
		"isAlreadyTranslated": true
	},
	"Product Details": {
		"text": "Detalles del producto",
		"isAlreadyTranslated": true
	},
	"Product Purchase Date": {
		"text": "Fecha de compra del producto",
		"isAlreadyTranslated": true
	},
	"Plan Details": {
		"text": "Detalles del plan",
		"isAlreadyTranslated": true
	},
	"Plan Price": {
		"text": "Precio del plan",
		"isAlreadyTranslated": true
	},
	"Sales Location": {
		"text": "Ubicación de las ventas",
		"isAlreadyTranslated": true
	},
	"Apologies! It seems we are experiencing some trouble but our team is working to fix it. Please try again in some time or contact our support team": {
		"text": "¡Lo sentimos! Parece que estamos experimentando algunos problemas, pero nuestro equipo está trabajando para solucionarlo. Por favor, inténtelo de nuevo dentro de un tiempo o póngase en contacto con nuestro equipo de soporte",
		"isAlreadyTranslated": true
	},
	"This device is already registered under the desired plan": {
		"text": "Este dispositivo ya está registrado en el plan deseado",
		"isAlreadyTranslated": true
	},
	"Reference ID": {
		"text": "Referencia",
		"isAlreadyTranslated": true
	},
	"Consumer Name": {
		"text": "Nombre del cliente",
		"isAlreadyTranslated": true
	},
	"Raise a request": {
		"text": "Inicie una solicitud",
		"isAlreadyTranslated": true
	},
	"show count": {
		"text": "mostrar el recuento",
		"isAlreadyTranslated": true
	},
	"IMIE/Serial No": {
		"text": "IMIE/Número de serie",
		"isAlreadyTranslated": true
	},
	"Contact No": {
		"text": "Contacto",
		"isAlreadyTranslated": true
	},
	"Search": {
		"text": "Buscar",
		"isAlreadyTranslated": true
	},
	"Show Graph": {
		"text": "Mostrar gráfico",
		"isAlreadyTranslated": true
	},
	"Customer": {
		"text": "Cliente",
		"isAlreadyTranslated": true
	},
	"Device": {
		"text": "Dispositivo",
		"isAlreadyTranslated": true
	},
	"Service Type & Status": {
		"text": "Tipo de servicio y estado",
		"isAlreadyTranslated": true
	},
	"Schedule": {
		"text": "Horario",
		"isAlreadyTranslated": true
	},
	"Created": {
		"text": "Creado",
		"isAlreadyTranslated": true
	},
	"View": {
		"text": "Vista",
		"isAlreadyTranslated": true
	},
	"Claim pickup": {
		"text": "Reclamación de recogida",
		"isAlreadyTranslated": true
	},
	"Service Center": {
		"text": "Servicio técnico",
		"isAlreadyTranslated": true
	},
	"Request scheduled for": {
		"text": "Solicitud programada para",
		"isAlreadyTranslated": true
	},
	"Action Status": {
		"text": "Estado de la acción",
		"isAlreadyTranslated": true
	},
	"Slot": {
		"text": "Franja",
		"isAlreadyTranslated": true
	},
	"Device recieved": {
		"text": "Dispositivo recibido",
		"isAlreadyTranslated": true
	},
	"Action Taken": {
		"text": "Medidas adoptadas",
		"isAlreadyTranslated": true
	},
	"Log call": {
		"text": "Registro de llamada",
		"isAlreadyTranslated": true
	},
	"Name": {
		"text": "Nombre",
		"isAlreadyTranslated": true
	},
	"First registered form": {
		"text": "Primer formulario registrado",
		"isAlreadyTranslated": true
	},
	"Address details": {
		"text": "Detalles de la dirección",
		"isAlreadyTranslated": true
	},
	"Storage capacity": {
		"text": "Capacidad de almacenamiento",
		"isAlreadyTranslated": true
	},
	"Manufacture's warranty status": {
		"text": "Estado de la garantía del fabricante",
		"isAlreadyTranslated": true
	},
	"Warranty till": {
		"text": "Garantía hasta",
		"isAlreadyTranslated": true
	},
	"Protection status": {
		"text": "Estado de protección",
		"isAlreadyTranslated": true
	},
	"Plan benefit": {
		"text": "Prestación del plan",
		"isAlreadyTranslated": true
	},
	"Device added date": {
		"text": "Fecha de registro del dispositivo",
		"isAlreadyTranslated": true
	},
	"Device value": {
		"text": "Valor del dispositivo",
		"isAlreadyTranslated": true
	},
	"estimation details": {
		"text": "detalles de estimación",
		"isAlreadyTranslated": true
	},
	"Type": {
		"text": "Tipo",
		"isAlreadyTranslated": true
	},
	"Total Amount": {
		"text": "Importe total",
		"isAlreadyTranslated": true
	},
	"One time deductible": {
		"text": "Deducción única",
		"isAlreadyTranslated": true
	},
	"Total payable": {
		"text": "Total a pagar",
		"isAlreadyTranslated": true
	},
	"Request details": {
		"text": "Solicitar detalles",
		"isAlreadyTranslated": true
	},
	"External reference id": {
		"text": "Referencia externa",
		"isAlreadyTranslated": true
	},
	"fullfiller name": {
		"text": "nombre completo",
		"isAlreadyTranslated": true
	},
	"Current status": {
		"text": "Estado actual",
		"isAlreadyTranslated": true
	},
	"service type": {
		"text": "tipo de servicio",
		"isAlreadyTranslated": true
	},
	"schedule date": {
		"text": "Programar fecha",
		"isAlreadyTranslated": true
	},
	"slot": {
		"text": "franja",
		"isAlreadyTranslated": true
	},
	"zipcode": {
		"text": "código postal",
		"isAlreadyTranslated": true
	},
	"warranty applicable": {
		"text": "garantía aplicable",
		"isAlreadyTranslated": true
	},
	"Issues": {
		"text": "Incidencias",
		"isAlreadyTranslated": true
	},
	"Issue description": {
		"text": "Descripción del problema",
		"isAlreadyTranslated": true
	},
	"Logistics - From Customer's Address To Service Center": {
		"text": "Logística - De la dirección del cliente al servicio técnico",
		"isAlreadyTranslated": true
	},
	"Verification code": {
		"text": "Código de verificación",
		"isAlreadyTranslated": true
	},
	"Vendor tracking url -": {
		"text": "URL de seguimiento de proveedores -",
		"isAlreadyTranslated": true
	},
	"Waybill Number": {
		"text": "Número de albarán",
		"isAlreadyTranslated": true
	},
	"One time deductible (Admin fees)": {
		"text": "Deducción única (tasas administrativas)",
		"isAlreadyTranslated": true
	},
	"Loss datetime": {
		"text": "Fecha de pérdida",
		"isAlreadyTranslated": true
	},
	"Damage type": {
		"text": "Tipo de daño",
		"isAlreadyTranslated": true
	},
	"loss description": {
		"text": "descripción de la pérdida",
		"isAlreadyTranslated": true
	},
	"device switching on": {
		"text": "encendido del dispositivo",
		"isAlreadyTranslated": true
	},
	"Requested documents": {
		"text": "Documentos solicitados",
		"isAlreadyTranslated": true
	},
	"communication history": {
		"text": "historia de la comunicación",
		"isAlreadyTranslated": true
	},
	"consumers": {
		"text": "clientes",
		"isAlreadyTranslated": true
	},
	"Filter": {
		"text": "Filtrar",
		"isAlreadyTranslated": true
	},
	"Consumer Mobile": {
		"text": "Teléfono del cliente",
		"isAlreadyTranslated": true
	},
	"Consumer email": {
		"text": "Correo electrónico del cliente",
		"isAlreadyTranslated": true
	},
	"Subcategory": {
		"text": "Subcategoría",
		"isAlreadyTranslated": true
	},
	"Brand": {
		"text": "Marca",
		"isAlreadyTranslated": true
	},
	"Product": {
		"text": "Producto",
		"isAlreadyTranslated": true
	},
	"IMIE Number 1": {
		"text": "IMIE Número 1",
		"isAlreadyTranslated": true
	},
	"IMIE Number 2": {
		"text": "IMIE Número 2",
		"isAlreadyTranslated": true
	},
	"Apply": {
		"text": "Aplicar",
		"isAlreadyTranslated": true
	},
	"Copyright © 2015-2022 Service Lee Technologies Pvt Ltd. All rights reserved. Also known as Servify.": {
		"text": "Copyright © 2015-2022 Service Lee Technologies Pvt Ltd. Todos los derechos reservadosTambién conocido como Servify.",
		"isAlreadyTranslated": true
	},
	"Requests": {
		"text": "Solicitudes",
		"isAlreadyTranslated": true
	},
	"Consumers": {
		"text": "Clientes",
		"isAlreadyTranslated": true
	},
	"View Invoice": {
		"text": "Ver factura",
		"isAlreadyTranslated": true
	},
	"Request Scheduled for": {
		"text": "Solicitud programada para",
		"isAlreadyTranslated": true
	},
	"Log Call": {
		"text": "Registro de llamada",
		"isAlreadyTranslated": true
	},
	"Consumer Details": {
		"text": "Detalles del cliente",
		"isAlreadyTranslated": true
	},
	"Address Details": {
		"text": "Detalles de la dirección",
		"isAlreadyTranslated": true
	},
	"Issue Validation": {
		"text": "Incidencia validada",
		"isAlreadyTranslated": true
	},
	"Estimation Details": {
		"text": "Detalles de la estimación",
		"isAlreadyTranslated": true
	},
	"Other Requests": {
		"text": "Otras peticiones",
		"isAlreadyTranslated": true
	},
	"Request Details": {
		"text": "Solicitar detalles",
		"isAlreadyTranslated": true
	},
	"Logistics Details": {
		"text": "Detalles logísticos",
		"isAlreadyTranslated": true
	},
	"Communication History": {
		"text": "Historia de la comunicación",
		"isAlreadyTranslated": true
	},
	"Request History": {
		"text": "Historial de solicitudes",
		"isAlreadyTranslated": true
	},
	"Reassign Service": {
		"text": "Reasignar el servicio",
		"isAlreadyTranslated": true
	},
	"Select a service center": {
		"text": "Seleccione un servicio técnico",
		"isAlreadyTranslated": true
	},
	"Service Engineer": {
		"text": "Técnico de servicio",
		"isAlreadyTranslated": true
	},
	"Select an engineer": {
		"text": "Seleccione un técnico",
		"isAlreadyTranslated": true
	},
	"Reassign": {
		"text": "Reasignar",
		"isAlreadyTranslated": true
	},
	"Call logs": {
		"text": "Registros de llamadas",
		"isAlreadyTranslated": true
	},
	"Call Logs": {
		"text": "Registros de llamadas",
		"isAlreadyTranslated": true
	},
	"Add call log": {
		"text": "Añadir registro de llamadas",
		"isAlreadyTranslated": true
	},
	"Call type": {
		"text": "Tipo de llamada",
		"isAlreadyTranslated": true
	},
	"Call Type": {
		"text": "Tipo de llamada",
		"isAlreadyTranslated": true
	},
	"Incoming": {
		"text": "Entrante",
		"isAlreadyTranslated": true
	},
	"Outgoing": {
		"text": "Saliente",
		"isAlreadyTranslated": true
	},
	"Name of the Agent": {
		"text": "Nombre del agente",
		"isAlreadyTranslated": true
	},
	"Connect Disposition": {
		"text": "Conectar Disposición",
		"isAlreadyTranslated": true
	},
	"Connect": {
		"text": "Conectar",
		"isAlreadyTranslated": true
	},
	"First Dialer Disposition": {
		"text": "Disposición del primer marcador",
		"isAlreadyTranslated": true
	},
	"Second Dialer Disposition": {
		"text": "Disposición del segundo marcador",
		"isAlreadyTranslated": true
	},
	"Remarks": {
		"text": "Observaciones",
		"isAlreadyTranslated": true
	},
	"No call history": {
		"text": "No hay historial de llamadas",
		"isAlreadyTranslated": true
	},
	"Initiate Exception": {
		"text": "Iniciar excepción",
		"isAlreadyTranslated": true
	},
	"Raise Exception": {
		"text": "Iniciar excepción",
		"isAlreadyTranslated": true
	},
	"Reason": {
		"text": "Motivo",
		"isAlreadyTranslated": true
	},
	"Upload Document Proof": {
		"text": "Cargar documento de prueba",
		"isAlreadyTranslated": true
	},
	"Complete Refund": {
		"text": "Reembolso completo",
		"isAlreadyTranslated": true
	},
	"UTR Number": {
		"text": "Número UTR",
		"isAlreadyTranslated": true
	},
	"Date of Refund": {
		"text": "Fecha de reembolso",
		"isAlreadyTranslated": true
	},
	"Complete": {
		"text": "Completo",
		"isAlreadyTranslated": true
	},
	"Please choose the product": {
		"text": "Por favor, elija el producto",
		"isAlreadyTranslated": true
	},
	"Select a Product": {
		"text": "Seleccione un producto",
		"isAlreadyTranslated": true
	},
	"Flag Requests": {
		"text": "Solicitudes de aviso",
		"isAlreadyTranslated": true
	},
	"Save this": {
		"text": "Guardar esto",
		"isAlreadyTranslated": true
	},
	"Billing Details": {
		"text": "Datos de facturación",
		"isAlreadyTranslated": true
	},
	"Billing Address": {
		"text": "Dirección de facturación",
		"isAlreadyTranslated": true
	},
	"Change Billing Address": {
		"text": "Cambiar la dirección de facturación",
		"isAlreadyTranslated": true
	},
	"Scheduled Date": {
		"text": "Fecha programada",
		"isAlreadyTranslated": true
	},
	"Claim Pickup": {
		"text": "Reclamación de recogida",
		"isAlreadyTranslated": true
	},
	"View Jobsheet": {
		"text": "Ver albarán",
		"isAlreadyTranslated": true
	},
	"Pickup Request Accepted": {
		"text": "Solicitud de recogida aceptada",
		"isAlreadyTranslated": true
	},
	"Action taken": {
		"text": "Acción tomada",
		"isAlreadyTranslated": true
	},
	"On": {
		"text": "en",
		"isAlreadyTranslated": true
	},
	"at": {
		"text": "en",
		"isAlreadyTranslated": true
	},
	"Download Logistics Label": {
		"text": "Descargar la etiqueta de envío",
		"isAlreadyTranslated": true
	},
	"Loading": {
		"text": "Cargando",
		"isAlreadyTranslated": true
	},
	"Storage Capacity": {
		"text": "Capacidad de almacenamiento",
		"isAlreadyTranslated": true
	},
	"Manufacturer's Warranty Status": {
		"text": "Estado de la garantía del fabricante",
		"isAlreadyTranslated": true
	},
	"Under warranty": {
		"text": "En garantía",
		"isAlreadyTranslated": true
	},
	"Is Manufacturer's Warranty Applicable": {
		"text": "¿Es aplicable la garantía del fabricante?",
		"isAlreadyTranslated": true
	},
	"Warranty Till": {
		"text": "Garantía hasta",
		"isAlreadyTranslated": true
	},
	"Protection Status": {
		"text": "Estado de protección",
		"isAlreadyTranslated": true
	},
	"No. of Repairs Used": {
		"text": "Número de reparaciones utilizadas",
		"isAlreadyTranslated": true
	},
	"No. of Repairs Allowed": {
		"text": "Número de reparaciones permitidas",
		"isAlreadyTranslated": true
	},
	"Device Value": {
		"text": "Valor del dispositivo",
		"isAlreadyTranslated": true
	},
	"Device Added Date": {
		"text": "Fecha de registro del dispositivo",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on 22nd March 2022. Your WAY BILL NUMBER is 1ZV757E88622834875. Please over the device to the Logistics Agent only if he provides this way bill number to prove his identity.": {
		"text": "El mensajero llegará a su ubicación el 22 de marzo de 2022. Su NÚMERO DE ALBARÁN es 1ZV757E88622834875. Por favor, entregue el dispositivo al mensajero solo si proporciona este número de albarán para validar su identidad",
		"isAlreadyTranslated": true
	},
	"Anfrage eingegangen": {
		"text": "Anfrage eingegangen",
		"isAlreadyTranslated": true
	},
	"Waiting for Assignment": {
		"text": "Esperando la asignación",
		"isAlreadyTranslated": true
	},
	"Claim raised": {
		"text": "Reclamación iniciada",
		"isAlreadyTranslated": true
	},
	"We have received your claim request. We will notify you once it is approved.": {
		"text": "Hemos recibido su solicitud de reclamación. Se lo notificaremos una vez aprobada",
		"isAlreadyTranslated": true
	},
	"Reschedule Service": {
		"text": "Reprogramar servicio",
		"isAlreadyTranslated": true
	},
	"Select a Date": {
		"text": "Seleccione una fecha",
		"isAlreadyTranslated": true
	},
	"Select a Slot": {
		"text": "Seleccione una franja",
		"isAlreadyTranslated": true
	},
	"No slots Available": {
		"text": "No hay franjas disponibles",
		"isAlreadyTranslated": true
	},
	"Reason for reschedule": {
		"text": "Motivo de la reprogramación",
		"isAlreadyTranslated": true
	},
	"Reschedule": {
		"text": "Reprogramar",
		"isAlreadyTranslated": true
	},
	"Request Documents": {
		"text": "Solicitar documentos",
		"isAlreadyTranslated": true
	},
	"Plan Benefits": {
		"text": "Prestación del plan",
		"isAlreadyTranslated": true
	},
	"Coverage Type": {
		"text": "Tipo de cobertura",
		"isAlreadyTranslated": true
	},
	"Covered Value": {
		"text": "Valor cubierto",
		"isAlreadyTranslated": true
	},
	"First Registered From": {
		"text": "Primer registro en",
		"isAlreadyTranslated": true
	},
	"Tax Payable": {
		"text": "Impuesto a pagar",
		"isAlreadyTranslated": true
	},
	"One Time deductible": {
		"text": "Deducción única",
		"isAlreadyTranslated": true
	},
	"Total Payable": {
		"text": "Total a pagar",
		"isAlreadyTranslated": true
	},
	"Fulfiller Name": {
		"text": "Nombre",
		"isAlreadyTranslated": true
	},
	"Current Status": {
		"text": "Estado actual",
		"isAlreadyTranslated": true
	},
	"Warranty Applicable": {
		"text": "Garantía aplicable",
		"isAlreadyTranslated": true
	},
	"Pending Verification": {
		"text": "Pendiente de verificación",
		"isAlreadyTranslated": true
	},
	"Verification Code": {
		"text": "Código de verificación",
		"isAlreadyTranslated": true
	},
	"Vendor Tracking Url": {
		"text": "URL de seguimiento de proveedores",
		"isAlreadyTranslated": true
	},
	"Click here for Vendor Tracking Url": {
		"text": "Haga clic aquí para ver la URL de seguimiento del proveedor",
		"isAlreadyTranslated": true
	},
	"Green Rewards": {
		"text": "Recompensas ecológicas",
		"isAlreadyTranslated": true
	},
	"Okay": {
		"text": "De acuerdo",
		"isAlreadyTranslated": true
	},
	"Enter Reference ID": {
		"text": "Introducir referencia",
		"isAlreadyTranslated": true
	},
	"Current Request Reference ID": {
		"text": "Referencia de la solicitud actual",
		"isAlreadyTranslated": true
	},
	"External Reference ID": {
		"text": "Referencia externa",
		"isAlreadyTranslated": true
	},
	"Service Type": {
		"text": "Tipo de servicio",
		"isAlreadyTranslated": true
	},
	"Schedule Date": {
		"text": "Fecha de programación",
		"isAlreadyTranslated": true
	},
	"Zipcode": {
		"text": "Código postal",
		"isAlreadyTranslated": true
	},
	"Customer's Version": {
		"text": "Versión del cliente",
		"isAlreadyTranslated": true
	},
	"Waybill": {
		"text": "Albarán",
		"isAlreadyTranslated": true
	},
	"Pickup Number": {
		"text": "Número de recogida",
		"isAlreadyTranslated": true
	},
	"Contact Number": {
		"text": "Número de contacto",
		"isAlreadyTranslated": true
	},
	"Admin Fees": {
		"text": "Tasas administrativas",
		"isAlreadyTranslated": true
	},
	"Payable": {
		"text": "A pagar",
		"isAlreadyTranslated": true
	},
	"Loss DateTime": {
		"text": "Fecha de pérdida",
		"isAlreadyTranslated": true
	},
	"Damage Type": {
		"text": "Tipo de daño",
		"isAlreadyTranslated": true
	},
	"Loss Description": {
		"text": "Descripción de la pérdida",
		"isAlreadyTranslated": true
	},
	"Device switching on": {
		"text": "Encendido del dispositivo",
		"isAlreadyTranslated": true
	},
	"No Communication History": {
		"text": "Sin historial de comunicación",
		"isAlreadyTranslated": true
	},
	"Today": {
		"text": "Hoy",
		"isAlreadyTranslated": true
	},
	"Yesterday": {
		"text": "Ayer",
		"isAlreadyTranslated": true
	},
	"Last 7 Days": {
		"text": "Últimos 7 días",
		"isAlreadyTranslated": true
	},
	"Last 30 Days": {
		"text": "Últimos 30 días",
		"isAlreadyTranslated": true
	},
	"This Month": {
		"text": "Este mes",
		"isAlreadyTranslated": true
	},
	"Last Month": {
		"text": "Último mes",
		"isAlreadyTranslated": true
	},
	"Total Value": {
		"text": "Valor total",
		"isAlreadyTranslated": true
	},
	"We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.": {
		"text": "Declaramos que esta factura muestra el precio real de las mercancías descritas y que todos los datos son verdaderos y correctos.",
		"isAlreadyTranslated": true
	},
	"Tax Invoice": {
		"text": "Factura de impuestos",
		"isAlreadyTranslated": true
	},
	"Details of Receiver (Billed to)": {
		"text": "Datos del receptor (facturado a)",
		"isAlreadyTranslated": true
	},
	"Details of Consignee (Shipped to)": {
		"text": "Datos del destinatario (enviado a)",
		"isAlreadyTranslated": true
	},
	"Sl. No": {
		"text": "Sl. No",
		"isAlreadyTranslated": true
	},
	"Description of Goods/Services": {
		"text": "Descripción de los bienes/servicios",
		"isAlreadyTranslated": true
	},
	"HSN/SAC Code": {
		"text": "Código HSN/SAC",
		"isAlreadyTranslated": true
	},
	"Advance Received": {
		"text": "Anticipo recibido",
		"isAlreadyTranslated": true
	},
	"Payable Value": {
		"text": "Valor a pagar",
		"isAlreadyTranslated": true
	},
	"Email POC to below email address": {
		"text": "Envíe el POC por correo electrónico a la siguiente dirección",
		"isAlreadyTranslated": true
	},
	"Send a copy to below email address": {
		"text": "Envíe una copia a la siguiente dirección de correo electrónico",
		"isAlreadyTranslated": true
	},
	"Add email-id": {
		"text": "Añadir correo electrónico",
		"isAlreadyTranslated": true
	},
	"Send": {
		"text": "Enviar",
		"isAlreadyTranslated": true
	},
	"Are you sure you want to cancel the order?": {
		"text": "¿Está seguro de que quiere cancelar la orden?",
		"isAlreadyTranslated": true
	},
	"Note Please request Customer to complete the diagnosis process in 60 minutes": {
		"text": "Nota: Solicite al cliente que complete el proceso de diagnóstico en 60 minutos",
		"isAlreadyTranslated": true
	},
	"This device is already registered under the desired plan": {
		"text": "Este dispositivo ya está registrado en el plan deseado",
		"isAlreadyTranslated": true
	},
	"UserList": {
		"text": "Lista de usuarios",
		"isAlreadyTranslated": true
	},
	"Curate": {
		"text": "Gestión",
		"isAlreadyTranslated": true
	},
	"Distributor": {
		"text": "Distribuidor",
		"isAlreadyTranslated": true
	},
	"Enterprise Sales": {
		"text": "Ventas",
		"isAlreadyTranslated": true
	},
	"Reseller": {
		"text": "Vendedor",
		"isAlreadyTranslated": true
	},
	"View Document": {
		"text": "Ver documento",
		"isAlreadyTranslated": true
	},
	"Partner Management": {
		"text": "Gestión de socios",
		"isAlreadyTranslated": true
	},
	"Logistics Requests": {
		"text": "Solicitudes de logística",
		"isAlreadyTranslated": true
	},
	"Optin Requests": {
		"text": "Solicitudes de adhesión",
		"isAlreadyTranslated": true
	},
	"APR Plans": {
		"text": "Planes de la TAE",
		"isAlreadyTranslated": true
	},
	"Catalogue": {
		"text": "Catálogo",
		"isAlreadyTranslated": true
	},
	"Engineer Report": {
		"text": "Informe del técnico",
		"isAlreadyTranslated": true
	},
	"Plan Sales Report": {
		"text": "Informe de venta de planes",
		"isAlreadyTranslated": true
	},
	"Reports": {
		"text": "Informes",
		"isAlreadyTranslated": true
	},
	"Billing": {
		"text": "Facturación",
		"isAlreadyTranslated": true
	},
	"Service Requests": {
		"text": "Solicitudes de servicio",
		"isAlreadyTranslated": true
	},
	"Exclusive": {
		"text": "Exclusivo",
		"isAlreadyTranslated": true
	},
	"Role": {
		"text": "Papel",
		"isAlreadyTranslated": true
	},
	"Superadmin": {
		"text": "Superadmin",
		"isAlreadyTranslated": true
	},
	"Device received": {
		"text": "Dispositivo recibido",
		"isAlreadyTranslated": true
	},
	"Next Steps": {
		"text": "Próximos pasos",
		"isAlreadyTranslated": true
	},
	"Add Device Date of Purchase and Device Value in 'Device Details' below.": {
		"text": "Añadir la fecha de compra del dispositivo y el valor del dispositivo enDetalles del dispositivo más abajo",
		"isAlreadyTranslated": true
	},
	"Ask for Documents Pending": {
		"text": "Pedir documentos pendientes",
		"isAlreadyTranslated": true
	},
	"Enter type of document to add": {
		"text": "Introduzca el tipo de documento a añadir",
		"isAlreadyTranslated": true
	},
	"Enter Reference IDs": {
		"text": "Introducir referencia",
		"isAlreadyTranslated": true
	},
	"Pick Up Request Reference ID": {
		"text": "Referencia de la solicitud de recogida",
		"isAlreadyTranslated": true
	},
	"Merge": {
		"text": "Fusión",
		"isAlreadyTranslated": true
	},
	"Enter Loaner Device Details": {
		"text": "Introduzca los detalles del dispositivo prestado",
		"isAlreadyTranslated": true
	},
	"IMEI Number": {
		"text": "Número IMEI",
		"isAlreadyTranslated": true
	},
	"Select IMEI Number": {
		"text": "Seleccione el número IMEI",
		"isAlreadyTranslated": true
	},
	"Upload document": {
		"text": "Cargar documento",
		"isAlreadyTranslated": true
	},
	"Submit": {
		"text": "Enviar",
		"isAlreadyTranslated": true
	},
	"Receive Loaner Device": {
		"text": "Recibir dispositivo de préstamo",
		"isAlreadyTranslated": true
	},
	"Condition Of the Loaner Device": {
		"text": "Estado del dispositivo de préstamo",
		"isAlreadyTranslated": true
	},
	"Good": {
		"text": "Bueno",
		"isAlreadyTranslated": true
	},
	"Bad": {
		"text": "Malo",
		"isAlreadyTranslated": true
	},
	"Ugly": {
		"text": "Regular",
		"isAlreadyTranslated": true
	},
	"Plan Types": {
		"text": "Tipos de planes",
		"isAlreadyTranslated": true
	},
	"Plan Descriptions": {
		"text": "Descripciones del plan",
		"isAlreadyTranslated": true
	},
	"Shipping Details": {
		"text": "Detalles del envío",
		"isAlreadyTranslated": true
	},
	"Logistics Agent will arrive at your location on 1st April 2022. Your WAY BILL NUMBER is 1ZV757E88600669390. Please handover the device to the Logistics Agent only if he provides this way bill number to prove his identity.": {
		"text": "El mensajero llegará a su ubicación el 1 de abril de 2022. Su NÚMERO DE ALBARÁN es 1ZV757E88600669390. Por favor, entregue el dispositivo al mensajero solo si proporciona este número de albarán para validar su identidad",
		"isAlreadyTranslated": true
	},
	"Cancel Repair": {
		"text": "Cancelar la reparación",
		"isAlreadyTranslated": true
	},
	"Cancel Service Without Dispatch": {
		"text": "Cancelación del servicio sin envío",
		"isAlreadyTranslated": true
	},
	"Tasks": {
		"text": "Tareas",
		"isAlreadyTranslated": true
	},
	"Edit": {
		"text": "Editar",
		"isAlreadyTranslated": true
	},
	"Close Task": {
		"text": "Cerrar tarea",
		"isAlreadyTranslated": true
	},
	"Task Reference Id": {
		"text": "Referencia de la tarea",
		"isAlreadyTranslated": true
	},
	"Remark": {
		"text": "Observaciones",
		"isAlreadyTranslated": true
	},
	"Edit Remark": {
		"text": "Editar comentario",
		"isAlreadyTranslated": true
	},
	"Task Details of": {
		"text": "Detalles de la tarea de",
		"isAlreadyTranslated": true
	},
	"Reference Id": {
		"text": "Referencia",
		"isAlreadyTranslated": true
	},
	"Created Date": {
		"text": "Fecha de creación",
		"isAlreadyTranslated": true
	},
	"Updated Date": {
		"text": "Fecha de actualización",
		"isAlreadyTranslated": true
	},
	"Closed Date": {
		"text": "Fecha de cierre",
		"isAlreadyTranslated": true
	},
	"Task": {
		"text": "Tarea",
		"isAlreadyTranslated": true
	},
	"No tasks Available": {
		"text": "No hay tareas disponibles",
		"isAlreadyTranslated": true
	},
	"an address": {
		"text": "una dirección",
		"isAlreadyTranslated": true
	},
	"Postcode": {
		"text": "Postleitzahl",
		"isAlreadyTranslated": true
	},
	"Postcode is required": {
		"text": "Se requiere Postleitzahl",
		"isAlreadyTranslated": true
	},
	"City/ town": {
		"text": "Stadt/Ort",
		"isAlreadyTranslated": true
	},
	"City  / Town is required": {
		"text": "Se requiere Stadt/Ort",
		"isAlreadyTranslated": true
	},
	"City": {
		"text": "Stadt",
		"isAlreadyTranslated": true
	},
	"City is required": {
		"text": "Se requiere Stadt",
		"isAlreadyTranslated": true
	},
	"State": {
		"text": "Bundesland",
		"isAlreadyTranslated": true
	},
	"State  (optional) is required": {
		"text": "Se requiere Bundesland (opcional)",
		"isAlreadyTranslated": true
	},
	"Address updated successfully": {
		"text": "Dirección actualizada con éxito",
		"isAlreadyTranslated": true
	},
	"Registration Documents": {
		"text": "Documentos de registro",
		"isAlreadyTranslated": true
	},
	"Please select a document type": {
		"text": "Por favor, seleccione un tipo de documento",
		"isAlreadyTranslated": true
	},
	"Doc": {
		"text": "Doc",
		"isAlreadyTranslated": true
	},
	"Add a part charge in 'Estimation Details' below to generate estimation.": {
		"text": "Agregue un cargo de piezas en 'Detalles de la estimación' a continuación para generar la estimación",
		"isAlreadyTranslated": true
	},
	"Device details validated": {
		"text": "Detalles del dispositivo validados",
		"isAlreadyTranslated": true
	},
	"IMEI/Serial Number: 355399270842625,\nManufacturer's Warranty Status: Yes,\nIs Manufacturer's Warranty Applicable: No,\nDevice Value: 2000,\nDate of Purchase: 2022-03-23,": {
		"text": "IMEI/Número de serie 355399270842625\nEstado de la garantía del fabricante: Sí,\nes aplicable la garantía del fabricante: No,\nel valor del dispositivo: 2000,\nfecha de compra: 2022-03-23,",
		"isAlreadyTranslated": true
	},
	"By": {
		"text": "Por",
		"isAlreadyTranslated": true
	},
	"ADD CHARGES": {
		"text": "AÑADIR CARGOS",
		"isAlreadyTranslated": true
	},
	"Part Charge": {
		"text": "Cargo por piezas",
		"isAlreadyTranslated": true
	},
	"Other Charge": {
		"text": "Otros cargos",
		"isAlreadyTranslated": true
	},
	"Advance": {
		"text": "Avanzar",
		"isAlreadyTranslated": true
	},
	"Part name": {
		"text": "Nombre de la pieza",
		"isAlreadyTranslated": true
	},
	"HSN Code": {
		"text": "Código HSN",
		"isAlreadyTranslated": true
	},
	"Taxable Amount": {
		"text": "Importe imponible",
		"isAlreadyTranslated": true
	},
	"Claim estimation generated": {
		"text": "Estimación de la reclamación",
		"isAlreadyTranslated": true
	},
	"Approve Claim Estimation": {
		"text": "Aprobar la estimación del siniestro",
		"isAlreadyTranslated": true
	},
	"part 1": {
		"text": "parte 1",
		"isAlreadyTranslated": true
	},
	"Please enter approved claim amount": {
		"text": "Por favor, introduzca el importe de la reclamación aprobada",
		"isAlreadyTranslated": true
	},
	"Payment pending": {
		"text": "Pago pendiente",
		"isAlreadyTranslated": true
	},
	"Payment Pending": {
		"text": "Pago pendiente",
		"isAlreadyTranslated": true
	},
	"Payment received": {
		"text": "Pago recibido",
		"isAlreadyTranslated": true
	},
	"Send payment link": {
		"text": "Enviar enlace de pago",
		"isAlreadyTranslated": true
	},
	"Claim estimation approved": {
		"text": "Estimación de la reclamación aprobada",
		"isAlreadyTranslated": true
	},
	"Approved claim amount": {
		"text": "Importe de la reclamación aprobada",
		"isAlreadyTranslated": true
	},
	"Approved Claim Amount": {
		"text": "Importe de la reclamación aprobada",
		"isAlreadyTranslated": true
	},
	"Are you sure": {
		"text": "¿Está seguro?",
		"isAlreadyTranslated": true
	},
	"Approved for repair": {
		"text": "Aprobado para la reparación",
		"isAlreadyTranslated": true
	},
	"Initiate Service": {
		"text": "Iniciar el servicio",
		"isAlreadyTranslated": true
	},
	"Service initiated": {
		"text": "Servicio iniciado",
		"isAlreadyTranslated": true
	},
	"Complete Repair": {
		"text": "Reparación completa",
		"isAlreadyTranslated": true
	},
	"Parts pending": {
		"text": "Piezas pendientes",
		"isAlreadyTranslated": true
	},
	"Complete Service": {
		"text": "Servicio completo",
		"isAlreadyTranslated": true
	},
	"Logistics - Return to Customer": {
		"text": "Logística - Devolución al cliente",
		"isAlreadyTranslated": true
	},
	"Shipping Method": {
		"text": "Método de envío",
		"isAlreadyTranslated": true
	},
	"Pick Up Confirmation Number": {
		"text": "Número de confirmación de recogida",
		"isAlreadyTranslated": true
	},
	"Jan": {
		"text": "Enero",
		"isAlreadyTranslated": true
	},
	"Feb": {
		"text": "Febrero",
		"isAlreadyTranslated": true
	},
	"Mar": {
		"text": "Marzo",
		"isAlreadyTranslated": true
	},
	"Apr": {
		"text": "Abril",
		"isAlreadyTranslated": true
	},
	"May": {
		"text": "Mayo",
		"isAlreadyTranslated": true
	},
	"Jun": {
		"text": "Junio",
		"isAlreadyTranslated": true
	},
	"Jul": {
		"text": "Julio",
		"isAlreadyTranslated": true
	},
	"Aug": {
		"text": "Agosto",
		"isAlreadyTranslated": true
	},
	"Sep": {
		"text": "Septiembre",
		"isAlreadyTranslated": true
	},
	"Oct": {
		"text": "Octubre",
		"isAlreadyTranslated": true
	},
	"Nov": {
		"text": "Noviembre",
		"isAlreadyTranslated": true
	},
	"Dec": {
		"text": "Diciembre",
		"isAlreadyTranslated": true
	},
	"Years": {
		"text": "Años",
		"isAlreadyTranslated": true
	},
	"years": {
		"text": "años",
		"isAlreadyTranslated": true
	},
	"Active": {
		"text": "Activo",
		"isAlreadyTranslated": true
	},
	"Benefits Availed": {
		"text": "Beneficios disponibles",
		"isAlreadyTranslated": true
	},
	"Cancel Plan": {
		"text": "Cancelar plan",
		"isAlreadyTranslated": true
	},
	"Cancelled": {
		"text": "Cancelado",
		"isAlreadyTranslated": true
	},
	"Change": {
		"text": "Cambiar",
		"isAlreadyTranslated": true
	},
	"Confirm": {
		"text": "Confirmar",
		"isAlreadyTranslated": true
	},
	"Contract ID": {
		"text": "ID del contrato",
		"isAlreadyTranslated": true
	},
	"Coverage Details": {
		"text": "DETALLES DE COBERTURAS",
		"isAlreadyTranslated": true
	},
	"Coverage End Date": {
		"text": "Fecha de fin de la cobertura",
		"isAlreadyTranslated": true
	},
	"Coverage Name": {
		"text": "Nombre de la cobertura",
		"isAlreadyTranslated": true
	},
	"Customer Mobile number": {
		"text": "Número de teléfono del cliente",
		"isAlreadyTranslated": true
	},
	"Declaration": {
		"text": "Declaración",
		"isAlreadyTranslated": true
	},
	"Declaration": {
		"text": "Declaración",
		"isAlreadyTranslated": true
	},
	"Device Purchase Price": {
		"text": "Precio de compra del dispositivo",
		"isAlreadyTranslated": true
	},
	"Diagnosed": {
		"text": "Diagnostícado",
		"isAlreadyTranslated": true
	},
	"Documents": {
		"text": "Documentación",
		"isAlreadyTranslated": true
	},
	"Downgraded": {
		"text": "Degradado",
		"isAlreadyTranslated": true
	},
	"Download": {
		"text": "Descargar",
		"isAlreadyTranslated": true
	},
	"Download Report": {
		"text": "Descargar Informe",
		"isAlreadyTranslated": true
	},
	"Enrolled": {
		"text": "Inscrito",
		"isAlreadyTranslated": true
	},
	"enrolled": {
		"text": "Inscrito",
		"isAlreadyTranslated": true
	},
	"Enter Email ID": {
		"text": "Introduzca el email",
		"isAlreadyTranslated": true
	},
	"Enter Serial number": {
		"text": "Introduzca Número de Serie",
		"isAlreadyTranslated": true
	},
	"Expired": {
		"text": "Expirado",
		"isAlreadyTranslated": true
	},
	"Full name": {
		"text": "Nombre completo",
		"isAlreadyTranslated": true
	},
	"Full Name": {
		"text": "Nombre Completo",
		"isAlreadyTranslated": true
	},
	"Go to Home": {
		"text": "Ir a página principal",
		"isAlreadyTranslated": true
	},
	"IMEI 1": {
		"text": "IMEI 1",
		"isAlreadyTranslated": true
	},
	"IMEI 2": {
		"text": "IMEI2",
		"isAlreadyTranslated": true
	},
	"Invoice Number": {
		"text": "Número de factura",
		"isAlreadyTranslated": true
	},
	"Location": {
		"text": "Ubicación",
		"isAlreadyTranslated": true
	},
	"Make Payment": {
		"text": "Make Payment",
		"isAlreadyTranslated": true
	},
	"Model": {
		"text": "Modelo",
		"isAlreadyTranslated": true
	},
	"month": {
		"text": "mes",
		"isAlreadyTranslated": true
	},
	"Month": {
		"text": "mes",
		"isAlreadyTranslated": true
	},
	"No Data Available": {
		"text": "No hay datos disponibles",
		"isAlreadyTranslated": true
	},
	"No Plans Available": {
		"text": "No hay planes disponibles.",
		"isAlreadyTranslated": true
	},
	"No plans available": {
		"text": "No hay planes disponibles.",
		"isAlreadyTranslated": true
	},
	"No. Of Claims Allowed": {
		"text": "Nº de reclamaciones permitidas",
		"isAlreadyTranslated": true
	},
	"Others": {
		"text": "Otros",
		"isAlreadyTranslated": true
	},
	"Payment details": {
		"text": "Detalles del Pago",
		"isAlreadyTranslated": true
	},
	"Payment Details": {
		"text": "Detalles del Pago",
		"isAlreadyTranslated": true
	},
	"Payment Gateway Transaction ID": {
		"text": "ID de la transaccion de la pasarela de pago",
		"isAlreadyTranslated": true
	},
	"Plan Activation Date": {
		"text": "Fecha de activación del plan",
		"isAlreadyTranslated": true
	},
	"Plan Code": {
		"text": "Código del Plan",
		"isAlreadyTranslated": true
	},
	"Plan Creation Date": {
		"text": "Fecha de creación del plan",
		"isAlreadyTranslated": true
	},
	"Plan Purchase Date": {
		"text": "Fecha de compra del plan",
		"isAlreadyTranslated": true
	},
	"Plan Term": {
		"text": "Plazo del plan",
		"isAlreadyTranslated": true
	},
	"Policy ID": {
		"text": "Póliza ID",
		"isAlreadyTranslated": true
	},
	"Product Category": {
		"text": "Categoria del producto",
		"isAlreadyTranslated": true
	},
	"Purchased": {
		"text": "Comprado",
		"isAlreadyTranslated": true
	},
	"Refunded": {
		"text": "Reintegrado",
		"isAlreadyTranslated": true
	},
	"Registered": {
		"text": "Registrado",
		"isAlreadyTranslated": true
	},
	"registered": {
		"text": "Registrado",
		"isAlreadyTranslated": true
	},
	"Reports": {
		"text": "Informes",
		"isAlreadyTranslated": true
	},
	"Requested Reports": {
		"text": "Informes solicitados",
		"isAlreadyTranslated": true
	},
	"Resend Link": {
		"text": "Reenviar link",
		"isAlreadyTranslated": true
	},
	"Resend Payment Link": {
		"text": "Reenviar link de pago",
		"isAlreadyTranslated": true
	},
	"Retailer Margin": {
		"text": "Margen de la tienda",
		"isAlreadyTranslated": true
	},
	"Sales Channel": {
		"text": "Canal de ventas",
		"isAlreadyTranslated": true
	},
	"Select All": {
		"text": "Selecciona todo",
		"isAlreadyTranslated": true
	},
	"Select Report Type": {
		"text": "Selecciona el tipo de informe",
		"isAlreadyTranslated": true
	},
	"Send Payment Link": {
		"text": "Enviar enlace de pago",
		"isAlreadyTranslated": true
	},
	"Terms and Conditions": {
		"text": "Términos y condiciones",
		"isAlreadyTranslated": true
	},
	"Used Claims": {
		"text": "Relamaciones usadas",
		"isAlreadyTranslated": true
	},
	"Payment Options": {
		"text": "Opciones de pago",
		"isAlreadyTranslated": true
	},
	"Pay Upfront": {
		"text": "Pagar por adelantado",
		"isAlreadyTranslated": true
	},
	"Subscription Payment": {
		"text": "Pago de suscripción",
		"isAlreadyTranslated": true
	},
	"Purchase Details": {
		"text": "Detalles de compra",
		"isAlreadyTranslated": true
	},
	"Review Details": {
		"text": "Detalles de la revisión",
		"isAlreadyTranslated": true
	},
	"Purchase Confirmation": {
		"text": "Compra cofirmada",
		"isAlreadyTranslated": true
	},
	"Enter Full Name": {
		"text": "Introduzca el nombre completo",
		"isAlreadyTranslated": true
	},
	"Enter Email ID": {
		"text": "Introduzca el email",
		"isAlreadyTranslated": true
	},
	"Plan Sales": {
		"text": "Ventas de planes",
		"isAlreadyTranslated": true
	},
	"Store Location": {
		"text": "Localización de la tienda",
		"isAlreadyTranslated": true
	},
	"Total Sales Value": {
		"text": "Importe Total Ventas",
		"isAlreadyTranslated": true
	},
	"Upfront Payment": {
		"text": "Pagar por adelantado",
		"isAlreadyTranslated": true
	},
	"This includes transaction values associated with plan sales and recurring payments post activation": {
		"text": "Esto incluye el valor de las transacciones de venta de planes y pagos recurrentes despues de la activación",
		"isAlreadyTranslated": true
	},
	"Total Earnings": {
		"text": "Ganancias Totales",
		"isAlreadyTranslated": true
	},
	"This includes retailer margin values associated with plan sales and recurring payments post activation": {
		"text": "Esto incluye el valor del margen de la tienda sobre la venta de planes y sobre los pagos recurrentes después de la activación",
		"isAlreadyTranslated": true
	},
	"Number of Sales": {
		"text": "Número de Ventas",
		"isAlreadyTranslated": true
	},
	"This includes all plan sales transactions except those contracts where customer has not completed the payment via link": {
		"text": "Esto incluye todas las transacciones de venta excepto los contratos que los clientes no han completado el proceso de pago a través del link",
		"isAlreadyTranslated": true
	},
	"Total Recurring Payments": {
		"text": "Total de Pagos Recurrentes",
		"isAlreadyTranslated": true
	},
	"This includes all successful recurring transactions associated with subscription plans": {
		"text": "Esto incluye todas las transacciones completadas asociadas a la subscripcion de los planes",
		"isAlreadyTranslated": true
	},
	"Payment Link Transactions": {
		"text": "Links de transacciones de pago",
		"isAlreadyTranslated": true
	},
	"Completed": {
		"text": "Completado",
		"isAlreadyTranslated": true
	},
	"Pending": {
		"text": "Pendiente",
		"isAlreadyTranslated": true
	},
	"Plan Sales Summary": {
		"text": "Resmen de ventas de planes",
		"isAlreadyTranslated": true
	},
	"Showing Results For": {
		"text": "Mostrando resultados de",
		"isAlreadyTranslated": true
	},
	"Today": {
		"text": "Hoy",
		"isAlreadyTranslated": true
	},
	"Last 15 Days": {
		"text": "Últimos 15 dias",
		"isAlreadyTranslated": true
	},
	"Last 30 Days": {
		"text": "Últimos 30 días",
		"isAlreadyTranslated": true
	},
	"Custom Date": {
		"text": "Seleccione fechas",
		"isAlreadyTranslated": true
	},
	"View All Transactions": {
		"text": "Ver todas las transacciones",
		"isAlreadyTranslated": true
	},
	"Sell Plan": {
		"text": "Venta del Plan",
		"isAlreadyTranslated": true
	},
	"You can only select a range within 30 days": {
		"text": "Sólo puedes seleccionar un rango de 30 días máximo",
		"isAlreadyTranslated": true
	},
	"Location": {
		"text": "Ubicación",
		"isAlreadyTranslated": true
	},
	"All Transactions": {
		"text": "Todas las Transacciones",
		"isAlreadyTranslated": true
	},
	"Sorry, No Upfront Payment Transactions Found": {
		"text": "Lo siento, Transacciones de pago único al principio no encontradas",
		"isAlreadyTranslated": true
	},
	"Payment Plan": {
		"text": "Plan de Pago",
		"isAlreadyTranslated": true
	},
	"Sorry, No Subscription Payment Transactions Found": {
		"text": "Lo siento, Transacciones de pago recurrente no encontradas",
		"isAlreadyTranslated": true
	},
	"Sorry, No Transactions Found": {
		"text": "Lo siento, transacciones no encontradas",
		"isAlreadyTranslated": true
	},
	"Sorry, No Documents Found": {
		"text": "Lo siento, documentación no encontrada",
		"isAlreadyTranslated": true
	},
	"More Filters": {
		"text": "Más Filtros",
		"isAlreadyTranslated": true
	},
	"Customer Email ID": {
		"text": "email del cliente",
		"isAlreadyTranslated": true
	},
	"Contract ID": {
		"text": "ID del contrato",
		"isAlreadyTranslated": true
	},
	"Policy ID": {
		"text": "Póliza ID",
		"isAlreadyTranslated": true
	},
	"Clear All": {
		"text": "Limpiart todo",
		"isAlreadyTranslated": true
	},
	"Transaction Date": {
		"text": "Fecha de la transacción",
		"isAlreadyTranslated": true
	},
	"Transaction Amount": {
		"text": "Importe de la transacción",
		"isAlreadyTranslated": true
	},
	"Contract Status": {
		"text": "Estatus de los Contratos",
		"isAlreadyTranslated": true
	},
	"Enrolled": {
		"text": "Inscrito",
		"isAlreadyTranslated": true
	},
	"Diagnosed": {
		"text": "Diagnostícado",
		"isAlreadyTranslated": true
	},
	"On Hold": {
		"text": "Pendiente",
		"isAlreadyTranslated": true
	},
	"Downgraded": {
		"text": "Degradado",
		"isAlreadyTranslated": true
	},
	"Cancellation Initiated": {
		"text": "Cancelación iniciada",
		"isAlreadyTranslated": true
	},
	"Refunded": {
		"text": "Reintegrado",
		"isAlreadyTranslated": true
	},
	"Benefits Availed": {
		"text": "Beneficios disponibles",
		"isAlreadyTranslated": true
	},
	"Bundled": {
		"text": "Incluido",
		"isAlreadyTranslated": true
	},
	"Under Review": {
		"text": "Bajo Revisión",
		"isAlreadyTranslated": true
	},
	"Payment Plan": {
		"text": "Plan de Pago",
		"isAlreadyTranslated": true
	},
	"Transaction Completion Date": {
		"text": "Fecha de finalización de la transacción",
		"isAlreadyTranslated": true
	},
	"Payment Link Status": {
		"text": "Estado del link de pago",
		"isAlreadyTranslated": true
	},
	"Residential State": {
		"text": "CCAA de Residencia",
		"isAlreadyTranslated": true
	},
	"Residential Country": {
		"text": "País de residencia",
		"isAlreadyTranslated": true
	},
	"Plan Code": {
		"text": "Código del Plan",
		"isAlreadyTranslated": true
	},
	"Plan Term": {
		"text": "Plazo del plan",
		"isAlreadyTranslated": true
	},
	"Store Location": {
		"text": "Localización de la tienda",
		"isAlreadyTranslated": true
	},
	"Waiting Period": {
		"text": "Periodo de espera",
		"isAlreadyTranslated": true
	},
	"Plan Creation Date": {
		"text": "Fecha de creación del plan",
		"isAlreadyTranslated": true
	},
	"Plan Cancellation Initiation Date": {
		"text": "Fecha de inicio de cancelación del plan",
		"isAlreadyTranslated": true
	},
	"Plan Cancellation Date": {
		"text": "Fecha de cancelacion del plan",
		"isAlreadyTranslated": true
	},
	"Cancellation Reason Description": {
		"text": "Descripcion de la razón de cancelación.",
		"isAlreadyTranslated": true
	},
	"Claim Avail Date": {
		"text": "Fecha para iniciar una reclamación",
		"isAlreadyTranslated": true
	},
	"No. of Claims Allowed": {
		"text": "Nº de reclamaciones permitidas",
		"isAlreadyTranslated": true
	},
	"Used Claims": {
		"text": "Relamaciones usadas",
		"isAlreadyTranslated": true
	},
	"Device Purchase Price": {
		"text": "Precio de compra del dispositivo",
		"isAlreadyTranslated": true
	},
	"Payment Gateway Transaction ID": {
		"text": "ID de la transaccion de la pasarela de pago",
		"isAlreadyTranslated": true
	},
	"External Payment ID": {
		"text": "ID de pago externo",
		"isAlreadyTranslated": true
	},
	"Billed Date": {
		"text": "Fecha de facturación",
		"isAlreadyTranslated": true
	},
	"Billed Date (UTC)": {
		"text": "Fecha de facturación (UTC)",
		"isAlreadyTranslated": true
	},
	"Billing Cycle Date": {
		"text": "Fecha del ciclo de facturación",
		"isAlreadyTranslated": true
	},
	"Billing Cycle Date (UTC)": {
		"text": "Fecha del ciclo de facturación (UTC)",
		"isAlreadyTranslated": true
	},
	"Grace Period End Date": {
		"text": "Último día del periodo de gracia",
		"isAlreadyTranslated": true
	},
	"Retailer Margin": {
		"text": "Margen de la tienda",
		"isAlreadyTranslated": true
	},
	"Transaction ID": {
		"text": "ID de la transacción",
		"isAlreadyTranslated": true
	},
	"Transaction Date (UTC)": {
		"text": "Fecha de la transacción (UTC)",
		"isAlreadyTranslated": true
	},
	"Pay upfront": {
		"text": "Pagar por adelantado",
		"isAlreadyTranslated": true
	},
	"month": {
		"text": "mes",
		"isAlreadyTranslated": true
	},
	"Full Name": {
		"text": "Nombre completo",
		"isAlreadyTranslated": true
	},
	"Make payment": {
		"text": "Make Payment",
		"isAlreadyTranslated": true
	},
	"COVERAGE DETAILS": {
		"text": "DETALLES DE COBERTURAS",
		"isAlreadyTranslated": true
	},
	"DEVICE DETAILS": {
		"text": "Detalles del dispositivo",
		"isAlreadyTranslated": true
	},
	"Model": {
		"text": "Modelo",
		"isAlreadyTranslated": true
	},
	"Overview": {
		"text": "Vista general",
		"isAlreadyTranslated": true
	},
	"Customer Mobile Number": {
		"text": "Número de teléfono del cliente",
		"isAlreadyTranslated": true
	},
	"Change": {
		"text": "Cambiar",
		"isAlreadyTranslated": true
	},
	"Apply": {
		"text": "Aplicar",
		"isAlreadyTranslated": true
	},
	"Su": {
		"text": "Dom",
		"isAlreadyTranslated": true
	},
	"Mo": {
		"text": "Lun",
		"isAlreadyTranslated": true
	},
	"Tu": {
		"text": "Mar",
		"isAlreadyTranslated": true
	},
	"We": {
		"text": "Mierc",
		"isAlreadyTranslated": true
	},
	"Th": {
		"text": "Juev",
		"isAlreadyTranslated": true
	},
	"Fr": {
		"text": "Vier",
		"isAlreadyTranslated": true
	},
	"Sa": {
		"text": "Sab",
		"isAlreadyTranslated": true
	},
	"Protection Plans": {
		"text": "Planes de Proteccion",
		"isAlreadyTranslated": true
	},
	"Copyright © 2015-2024 Service Lee Technologies Pvt Ltd. All rights reserved.": {
		"text": "Copyright © 2015-2024 Service Lee Technologies Pvt Ltd. Todos los derechos reservados.",
		"isAlreadyTranslated": true
	},
	"More": {
		"text": "Más",
		"isAlreadyTranslated": true
	},
	"of": {
		"text": "de",
		"isAlreadyTranslated": true
	},
	"The contract is pending for activation because payment is pending by the retailer": {
		"text": "El contrato está pendiente de activación por que está pendiente el pago de la tienda.",
		"isAlreadyTranslated": true
	},
	"The contract has been activated": {
		"text": "El contrato ha sido activado.",
		"isAlreadyTranslated": true
	},
	"Yearly": {
		"text": "Anual",
		"isAlreadyTranslated": true
	},
	"yearly": {
		"text": "anual",
		"isAlreadyTranslated": true
	},
	"Monthly": {
		"text": "Mensual",
		"isAlreadyTranslated": true
	},
	"MONTHLY": {
		"text": "Mensual",
		"isAlreadyTranslated": true
	},
	"monthly": {
		"text": "mensual",
		"isAlreadyTranslated": true
	},
	"Payment Link Transaction": {
		"text": "Links de transacciones de pago",
		"isAlreadyTranslated": true
	},
	"Success! Payment Link Sent.": {
		"text": "¡Bien! Link de pago enviado",
		"isAlreadyTranslated": true
	},
	"Success!": {
		"text": "¡Bien!",
		"isAlreadyTranslated": true
	},
	"Insurance Product Information Document": {
		"text": "Documento de información del producto de seguro.",
		"isAlreadyTranslated": true
	},
	"Terms and Conditions Document": {
		"text": "Documento de terminos y condiciones",
		"isAlreadyTranslated": true
	},
	"Product Information Sheet": {
		"text": "Hoja de información del producto",
		"isAlreadyTranslated": true
	},
	"Information to Insurance Intermediary": {
		"text": "Información al intermediario del seguro",
		"isAlreadyTranslated": true
	},
	"Select Location": {
		"text": "Seleccionar ubicación",
		"isAlreadyTranslated": true
	},
	"To see the list, type the first three characters.": {
		"text": "Para ver la lista, escriba los primeros tres caracteres",
		"isAlreadyTranslated": true
	},
	"Smartphones": {
		"text": "Smartphones",
		"isAlreadyTranslated": true
	},
	"Tablets": {
		"text": "Tablets",
		"isAlreadyTranslated": true
	},
	"Smart Watches": {
		"text": "Smart Watches",
		"isAlreadyTranslated": true
	},
	"Streaming Devices": {
		"text": "Dispositivos de streaming",
		"isAlreadyTranslated": true
	},
	"Monitors": {
		"text": "Monitores",
		"isAlreadyTranslated": true
	},
	"Laptops": {
		"text": "Ordenadores portátiles",
		"isAlreadyTranslated": true
	},
	"Desktops (All-in-One)": {
		"text": "Ordendores de sobremesa (todo en uno)",
		"isAlreadyTranslated": true
	},
	"Apple Plans": {
		"text": "Planes de Apple",
		"isAlreadyTranslated": true
	},
	"Validaty": {
		"text": "Validez",
		"isAlreadyTranslated": true
	},
	"Ongoing until cancelled": {
		"text": "continua hasta cancelación",
		"isAlreadyTranslated": true
	},
	"The product’s serial number / IMEI has been validated and it seems to belong to {{ProductSubCategory}} category. Please note that the plans will be suggested based on this validation.": {
		"text": "El número de serie/IMEI del producto ha sido validado y parece pertenecer a la categoría {{ProductSubCategory}}. Tenga en cuenta que los planes se sugerirán en función de esta validación.",
		"isAlreadyTranslated": true
	},
	"Congratulations! Your device is eligible for this plan.": {
		"text": "¡Felicidades! Tu dispositivo es elegible para este plan",
		"isAlreadyTranslated": true
	},
	"Year": {
		"text": "Año",
		"isAlreadyTranslated": true
	},
	"year": {
		"text": "año",
		"isAlreadyTranslated": true
	},
	"Enter Mobile ID": {
		"text": "Introduzca el número de mobil",
		"isAlreadyTranslated": true
	},
	"External Transaction ID": {
		"text": "ID de transacción externa",
		"isAlreadyTranslated": true
	},
	"Enter External Transaction ID": {
		"text": "Introduzca el ID de transacción externa",
		"isAlreadyTranslated": true
	},
	"Bill Details": {
		"text": "Detalles de la factura",
		"isAlreadyTranslated": true
	},
	"Base Price": {
		"text": "Precio base",
		"isAlreadyTranslated": true
	},
	"IPT": {
		"text": "IPT",
		"isAlreadyTranslated": true
	},
	"I have explained the plan Terms and Conditions to the customer and the same will be emailed to the customer on registered contact details": {
		"text": "Le he explicado los Términos y condiciones del plan al cliente y los mismos se enviarán por correo electrónico al cliente con los datos de contacto registrados.",
		"isAlreadyTranslated": true
	},
	"Paid in Store": {
		"text": "Pagado en la tienda",
		"isAlreadyTranslated": true
	},
	"Pay in easy installments": {
		"text": "Paga en cómodas cuotas",
		"isAlreadyTranslated": true
	},
	"Contract purchase date cannot be a future date": {
		"text": "La fecha de compra del contrato no puede ser una fecha futura",
		"isAlreadyTranslated": true
	},
	"Filter Type": {
		"text": "Tipo de filtro",
		"isAlreadyTranslated": true
	},
	"No results found": {
		"text": "No se han encontrado resultados",
		"isAlreadyTranslated": true
	},
	"AppleCare+ for Mac": {
		"text": "AppleCare+ para Mac",
		"isAlreadyTranslated": true
	},
	"AppleCare+ for iPhone": {
		"text": "AppleCare+ para iPhone",
		"isAlreadyTranslated": true
	},
	"AppleCare+ for iPhone with Theft and Loss": {
		"text": "AppleCare+ para iPhone con Robo y Pérdida",
		"isAlreadyTranslated": true
	},
	"AppleCare+ for iPad": {
		"text": "AppleCare+ para iPad",
		"isAlreadyTranslated": true
	},
	"AppleCare+ for Watch": {
		"text": "AppleCare+ para Watch",
		"isAlreadyTranslated": true
	},
	"AppleCare+ for AirPods / Headphones": {
		"text": "AppleCare+ para AirPods / Auriculares",
		"isAlreadyTranslated": true
	},
	"AppleCare+ for HomePod": {
		"text": "AppleCare+ para HomePod",
		"isAlreadyTranslated": true
	},
	"AppleCare+ for Apple TV": {
		"text": "AppleCare+ para Apple TV",
		"isAlreadyTranslated": true
	},
	"Please enter a OTP": {
		"text": "Por favor ingrese un código OTP",
		"isAlreadyTranslated": true
	},
	"Resending OTP": {
		"text": "Reenviar OTP",
		"isAlreadyTranslated": true
	},
	"Transaction Start Date": {
		"text": "Fecha de inicio de la transacción",
		"isAlreadyTranslated": true
	},
	"Transaction End Date": {
		"text": "Fecha de finalización de la transacción",
		"isAlreadyTranslated": true
	},
	"Payment Initiated": {
		"text": "Pago iniciado",
		"isAlreadyTranslated": true
	},
	"Cancellation Option": {
		"text": "Opción de cancelación",
		"isAlreadyTranslated": true
	},
	"The customer will receive a confirmation shortly.": {
		"text": "El cliente recibirá una confirmación en breve.",
		"isAlreadyTranslated": true
	},
	"Verify your account": {
		"text": "Verifica tu cuenta",
		"isAlreadyTranslated": true
	},
	"Please enter the verification code sent on this email ID": {
		"text": "Ingrese el código de verificación enviado en este ID de correo electrónico",
		"isAlreadyTranslated": true
	},
	"Deferred Cancellation": {
		"text": "Cancelación Diferida",
		"isAlreadyTranslated": true
	},
	"Immediate Cancellation": {
		"text": "Cancelación Inmediata",
		"isAlreadyTranslated": true
	},
	"The product will be covered until the last day of the current billing period. Contract will be cancelled at the end of the current billing period.": {
		"text": "El producto estará cubierto hasta el último día del periodo de facturación actual. El contrato se cancelará al final del período de facturación actual.",
		"isAlreadyTranslated": true
	},
	"The contract will be cancelled immediately, and the product will no longer be protected. Any applicable refund will be credited back to the original payment source.": {
		"text": "El contrato será cancelado inmediatamente y el producto dejará de estar protegido. Cualquier reembolso aplicable se acreditará a la fuente de pago original.",
		"isAlreadyTranslated": true
	},
	"Reason for Cancellation": {
		"text": "Razón de la cancelación",
		"isAlreadyTranslated": true
	},
	"DOA / Device Failure": {
		"text": "DOA / Fallo del dispositivo",
		"isAlreadyTranslated": true
	},
	"Customer Remorse": {
		"text": "Arrepentimiento del cliente",
		"isAlreadyTranslated": true
	},
	"Order input error": {
		"text": "Error de entrada de pedido",
		"isAlreadyTranslated": true
	},
	"Device Upgrade": {
		"text": "Actualización del dispositivo",
		"isAlreadyTranslated": true
	},
	"Non-Payment MRC": {
		"text": "MRC de impago",
		"isAlreadyTranslated": true
	},
	"Cancel Contract": {
		"text": "Cancelar contrato",
		"isAlreadyTranslated": true
	},
	"View All": {
		"text": "Ver todo",
		"isAlreadyTranslated": true
	},
	"Print Hard Copy": {
		"text": "Imprimir copia impresa",
		"isAlreadyTranslated": true
	},
	"Are you sure for cancellation?": {
		"text": "¿Estás seguro de la cancelación?",
		"isAlreadyTranslated": true
	},
	"Plan Purchased Date": {
		"text": "Fecha de compra del plan",
		"isAlreadyTranslated": true
	},
	"Powered By": {
		"text": "Gestionado por",
		"isAlreadyTranslated": true
	},
	"Please enter a minimum of {{x}} digits and a maximum of {{x}} digits for {{XXX}}.": {
		"text": "Introduzca un mínimo de x dígitos y un máximo de x dígitos para XXX.",
		"isAlreadyTranslated": true
	},
	"Price Summary": {
		"text": "Resumen de precio",
		"isAlreadyTranslated": true
	},
	"Version": {
		"text": "Versión",
		"isAlreadyTranslated": true
	},
	"The product’s serial number / IMEI has been validated and it seems to belong to Smartphones category. Please note that the plans will be suggested based on this validation.": {
		"text": "El número de serie/IMEI del producto ha sido validado y parece pertenecer a la categoría Smartphones. Tenga en cuenta que los planes se sugerirán en función de esta validación.",
		"isAlreadyTranslated": true
	},
	"The product's serial number / IMEI has been validated and it seems to belong to a different category. Please note that the plans will be suggested based on this validation.": {
		"text": "El número de serie / IMEI del producto ha sido validado y parece pertenecer a una categoría diferente. Tenga en cuenta que los planes se sugerirán en función de esta validación.",
		"isAlreadyTranslated": true
	},
	"The product’s serial number / IMEI has been validated and it seems to belong to Tablets category. Please note that the plans will be suggested based on this validation.": {
		"text": "El número de serie/IMEI del producto ha sido validado y parece pertenecer a la categoría Tablets. Tenga en cuenta que los planes se sugerirán en función de esta validación.",
		"isAlreadyTranslated": true
	},
	"The product’s serial number / IMEI has been validated and it seems to belong to Smart Watches category. Please note that the plans will be suggested based on this validation.": {
		"text": "El número de serie/IMEI del producto ha sido validado y parece pertenecer a la categoría Smart Watches. Tenga en cuenta que los planes se sugerirán en función de esta validación.",
		"isAlreadyTranslated": true
	},
	"The product’s serial number / IMEI has been validated and it seems to belong to Streaming Devices category. Please note that the plans will be suggested based on this validation.": {
		"text": "El número de serie/IMEI del producto ha sido validado y parece pertenecer a la categoría Dispositivos de streaming. Tenga en cuenta que los planes se sugerirán en función de esta validación.",
		"isAlreadyTranslated": true
	},
	"The product’s serial number / IMEI has been validated and it seems to belong to Monitors category. Please note that the plans will be suggested based on this validation.": {
		"text": "El número de serie/IMEI del producto ha sido validado y parece pertenecer a la categoría Monitores. Tenga en cuenta que los planes se sugerirán en función de esta validación.",
		"isAlreadyTranslated": true
	},
	"The product’s serial number / IMEI has been validated and it seems to belong to Laptops category. Please note that the plans will be suggested based on this validation.": {
		"text": "El número de serie/IMEI del producto ha sido validado y parece pertenecer a la categoría Ordenadores portátiles. Tenga en cuenta que los planes se sugerirán en función de esta validación.",
		"isAlreadyTranslated": true
	},
	"The product’s serial number / IMEI has been validated and it seems to belong to Desktops (All-in-One) category. Please note that the plans will be suggested based on this validation.": {
		"text": "El número de serie/IMEI del producto ha sido validado y parece pertenecer a la categoría Ordendores de sobremesa (todo en uno). Tenga en cuenta que los planes se sugerirán en función de esta validación.",
		"isAlreadyTranslated": true
	},
	"The product’s serial number / IMEI has been validated and it seems to belong to Audio Products category. Please note that the plans will be suggested based on this validation.": {
		"text": "El número de serie/IMEI del producto ha sido validado y parece pertenecer a la categoría Productos de audio. Tenga en cuenta que los planes se sugerirán en función de esta validación.",
		"isAlreadyTranslated": true
	},
	"The product’s serial number / IMEI has been validated and it seems to belong to Headphones / Earphones category. Please note that the plans will be suggested based on this validation.": {
		"text": "El número de serie/IMEI del producto ha sido validado y parece pertenecer a la categoría Cascos / Auriculares. Tenga en cuenta que los planes se sugerirán en función de esta validación.",
		"isAlreadyTranslated": true
	},
	"Enter": {
		"text": "Introduzca",
		"isAlreadyTranslated": true
	},
	"optional": {
		"text": "opcional",
		"isAlreadyTranslated": true
	},
	"Eligible Plan(s)": {
		"text": "Plan(es) elegible(s)",
		"isAlreadyTranslated": true
	},
	"or": {
		"text": "o",
		"isAlreadyTranslated": true
	},
	"Select payment plan": {
		"text": "Seleccionar plan de pago",
		"isAlreadyTranslated": true
	},
	"Plan is already active against this device": {
		"text": "El plan ya está activo para este dispositivo",
		"isAlreadyTranslated": true
	},
	"Please enter correct Name": {
		"text": "Por favor ingrese el nombre correcto",
		"isAlreadyTranslated": true
	},
	"Please enter Reference Value": {
		"text": "Por favor ingrese el valor de referencia",
		"isAlreadyTranslated": true
	},
	"Change Payment Plan": {
		"text": "Cambiar plan de pago",
		"isAlreadyTranslated": true
	},
	"Disclaimer: Total is rounded off to the nearest value": {
		"text": "Descargo de responsabilidad: el total se redondea al valor más cercano",
		"isAlreadyTranslated": true
	},
	"Link sent to": {
		"text": "Enlace enviado a",
		"isAlreadyTranslated": true
	},
	"Payment Link Sent": {
		"text": "Enlace de pago enviado",
		"isAlreadyTranslated": true
	},
	"Payment Link Resent": {
		"text": "Enlace de pago reenviado",
		"isAlreadyTranslated": true
	},
	"upgraded": {
		"text": "actualizado",
		"isAlreadyTranslated": true
	},
	"The Contract activation is pending due to pending payment": {
		"text": "La activación del Contrato está pendiente por pago pendiente",
		"isAlreadyTranslated": true
	},
	"Spain": {
		"text": "España",
		"isAlreadyTranslated": true
	},
	"Information about Insurance Mediator": {
		"text": "Información sobre Mediador de Seguros",
		"isAlreadyTranslated": true
	},
	"Choose a Reason from the dropdown": {
		"text": "Elija un motivo en el menú desplegable",
		"isAlreadyTranslated": true
	},
	"Your cancellation request is accepted": {
		"text": "Su solicitud de cancelación es aceptada.",
		"isAlreadyTranslated": true
	},
	"You'll receive a confirmation shortly": {
		"text": "Recibirás una confirmación en breve",
		"isAlreadyTranslated": true
	},
	"Back to Home": {
		"text": "De vuelta a casa",
		"isAlreadyTranslated": true
	},
	"Select reason for cancellation": {
		"text": "Selecciona el motivo de la cancelación",
		"isAlreadyTranslated": true
	},
	"Cancel Order": {
		"text": "Cancelar orden",
		"isAlreadyTranslated": true
	},
	"Order Cancelled": {
		"text": "Orden cancelada",
		"isAlreadyTranslated": true
	},
	"OK": {
		"text": "De acuerdo",
		"isAlreadyTranslated": true
	},
	"Ok": {
		"text": "De acuerdo",
		"isAlreadyTranslated": true
	},
	"View Contract Details": {
		"text": "Ver Detalles Del Contrato",
		"isAlreadyTranslated": true
	},
	"Not Updated": {
		"text": "No actualizado",
		"isAlreadyTranslated": true
	},
	"Partner Name": {
		"text": "Nombre del socio",
		"isAlreadyTranslated": true
	},
	"Plan Expiry Date": {
		"text": "Fecha de vencimiento del plan",
		"isAlreadyTranslated": true
	},
	"User Name": {
		"text": "Nombre de usuario",
		"isAlreadyTranslated": true
	},
	"Terms&Conditions": {
		"text": "Términos y condiciones",
		"isAlreadyTranslated": true
	},
	"Coverage validity starts on": {
		"text": "La validez de la cobertura comienza el",
		"isAlreadyTranslated": true
	},
	"Coverage validity ends on": {
		"text": "La validez de la cobertura finaliza el",
		"isAlreadyTranslated": true
	},
	"Claim can be filed from": {
		"text": "La reclamación se puede presentar desde",
		"isAlreadyTranslated": true
	},
	"Product Purchase Price": {
		"text": "Precio de compra del producto",
		"isAlreadyTranslated": true
	},
	"Protected": {
		"text": "Protegido",
		"isAlreadyTranslated": true
	},
	"Search by IMEI/Serial Number": {
		"text": "Buscar por IMEI/Número de serie",
		"isAlreadyTranslated": true
	},
	"View Details": {
		"text": "Ver detalles",
		"isAlreadyTranslated": true
	},
	"No Data Found": {
		"text": "Datos no encontrados",
		"isAlreadyTranslated": true
	},
	"Claim History": {
		"text": "Historial de reclamaciones",
		"isAlreadyTranslated": true
	},
	"Claim Reference ID": {
		"text": "ID de referencia del reclamo",
		"isAlreadyTranslated": true
	},
	"Claimed Device": {
		"text": "Dispositivo reclamado",
		"isAlreadyTranslated": true
	},
	"Claim Request Date": {
		"text": "Fecha de solicitud de reclamo",
		"isAlreadyTranslated": true
	},
	"Claim Approved Date": {
		"text": "Fecha de aprobación del reclamo",
		"isAlreadyTranslated": true
	},
	"Claim Status": {
		"text": "Estado del reclamo",
		"isAlreadyTranslated": true
	},
	"No Documents available": {
		"text": "No hay documentos disponibles",
		"isAlreadyTranslated": true
	},
	"Sold Plan Document": {
		"text": "Documento del Plan Vendido",
		"isAlreadyTranslated": true
	},
	"Payment Completed": {
		"text": "Pago completado",
		"isAlreadyTranslated": true
	},
	"Billing Scheduled": {
		"text": "Facturación programada",
		"isAlreadyTranslated": true
	},
	"Payment Failed": {
		"text": "El pago ha fallado",
		"isAlreadyTranslated": true
	},
	"Billing Stopped": {
		"text": "Facturación detenida",
		"isAlreadyTranslated": true
	},
	"Billing Stopped": {
		"text": "Facturacion detenida",
		"isAlreadyTranslated": true
	},
	"Payment Initiated": {
		"text": "Pago iniciado",
		"isAlreadyTranslated": true
	},
	"Payment Pending": {
		"text": "Pago Pendiente",
		"isAlreadyTranslated": true
	},
	"Accidental Damage": {
		"text": "Daño Accidental",
		"isAlreadyTranslated": true
	},
	"Screen Damage": {
		"text": "Pantalla dañada",
		"isAlreadyTranslated": true
	},
	"Functional Issues": {
		"text": "Problemas funcionales",
		"isAlreadyTranslated": true
	},
	"Buyback": {
		"text": "Recompra",
		"isAlreadyTranslated": true
	},
	"Upgrade": {
		"text": "Mejora",
		"isAlreadyTranslated": true
	},
	"Like-to-Like Replacement": {
		"text": "Reemplazo similar",
		"isAlreadyTranslated": true
	},
	"Back Cover Damage": {
		"text": "Carcasa trasera dañada",
		"isAlreadyTranslated": true
	},
	"Battery Malfunction": {
		"text": "Mal funcionamiento de la Bateria",
		"isAlreadyTranslated": true
	},
	"Theft": {
		"text": "Robo",
		"isAlreadyTranslated": true
	},
	"Accidental & Liquid Damage Protection": {
		"text": "Protección contra daños accidentales y líquidos",
		"isAlreadyTranslated": true
	},
	"Upfront": {
		"text": "Por adelantado",
		"isAlreadyTranslated": true
	},
	"Alternate Mobile No": {
		"text": "Número de móvil alternativo",
		"isAlreadyTranslated": true
	},
	"Pincode": {
		"text": "Código PIN",
		"isAlreadyTranslated": true
	},
	"Search Store Location Name": {
		"text": "Buscar ubicación por nombre de tienda",
		"isAlreadyTranslated": true
	},
	"Store Location Name": {
		"text": "Nombre de la ubicación de la tienda",
		"isAlreadyTranslated": true
	},
	"Enter minimum 3 characters to search": {
		"text": "Introduzca mínimo 3 caracteres para buscar",
		"isAlreadyTranslated": true
	},
	"Sorry, we couldn't find the store you're looking for": {
		"text": "Lo sentimos, no pudimos encontrar la tienda que estás buscando.",
		"isAlreadyTranslated": true
	},
	"Copyright": {
		"text": "Copyright",
		"isAlreadyTranslated": true
	},
	"All rights reserved.": {
		"text": "Todos los derechos reservados.",
		"isAlreadyTranslated": true
	},
	"Also known as Servify.": {
		"text": "También conocido como Servify.",
		"isAlreadyTranslated": true
	},
	"Upfront": {
		"text": "Por adelantado",
		"isAlreadyTranslated": true
	},
	"months": {
		"text": "meses",
		"isAlreadyTranslated": true
	},
	"Months": {
		"text": "Meses",
		"isAlreadyTranslated": true
	},
	"Subscription plan, cancel anytime": {
		"text": "Plan de suscripción, cancela en cualquier momento",
		"isAlreadyTranslated": true
	},
	"Oops! Looks like there are no plans available for this device": {
		"text": "¡Ups! Parece que no hay planes disponibles para este dispositivo",
		"isAlreadyTranslated": true
	},
	"Promoter ID": {
		"text": "ID del promotor",
		"isAlreadyTranslated": true
	},
	"Please enter Promoter ID": {
		"text": "Por favor ingrese el ID del promotor",
		"isAlreadyTranslated": true
	},
	"Change Location": {
		"text": "Cambiar ubicación",
		"isAlreadyTranslated": true
	},
	"User Details": {
		"text": "Detalles de usuario",
		"isAlreadyTranslated": true
	},
	"Sorry, no upfront payment transactions found": {
		"text": "Lo siento, Transacciones de pago único al principio no encontradas",
		"isAlreadyTranslated": true
	},
	"Sorry, no subscription payment transactions found": {
		"text": "Lo siento, Transacciones de pago recurrente no encontradas",
		"isAlreadyTranslated": true
	},
	"Transaction Number": {
		"text": "Número de transacción",
		"isAlreadyTranslated": true
	},
	"Activated": {
		"text": "Activado",
		"isAlreadyTranslated": true
	},
	"Product details are unavailable": {
		"text": "Los detalles del producto no están disponibles",
		"isAlreadyTranslated": true
	},
	"Mobile No": {
		"text": "Número de teléfono",
		"isAlreadyTranslated": true
	},
	"Sorry, no payment gateway identified. Please contact the administrator to complete the transaction": {
		"text": "Lo sentimos, no se ha identificado ninguna pasarela de pago. Póngase en contacto con el administrador para completar la transacción",
		"isAlreadyTranslated": true
	},
	"Filter Reports": {
		"text": "Filtro de Informes",
		"isAlreadyTranslated": true
	},
	"Payment Due & Overdue for Recurring Billing Report": {
		"text": "Informe de pago vencido y atrasados para facturación recurrente",
		"isAlreadyTranslated": true
	},
	"Sales and Cancellation Report - Internal": {
		"text": "Informe de Ventas y Cancelaciones - Interno",
		"isAlreadyTranslated": true
	},
	"Payment Due & Overdue for Recurring Billing Report - Internal": {
		"text": "Informe de pagos vencidos y atrasados para facturación recurrente: interno",
		"isAlreadyTranslated": true
	},
	"Sales and Cancellation Report": {
		"text": "Informe de Ventas y Cancelaciones",
		"isAlreadyTranslated": true
	},
	"Recent Requested Report": {
		"text": "Informe solicitado recientemente",
		"isAlreadyTranslated": true
	},
	"Report Type": {
		"text": "Selecciona el tipo de informe",
		"isAlreadyTranslated": true
	},
	"Report Requested On": {
		"text": "Informe solicitado el",
		"isAlreadyTranslated": true
	},
	"Download File": {
		"text": "Descargar archivo",
		"isAlreadyTranslated": true
	},
	"No data found": {
		"text": "Datos no encontrados",
		"isAlreadyTranslated": true
	},
	"Success": {
		"text": "Bien",
		"isAlreadyTranslated": true
	},
	"Pending": {
		"text": "Pendiente",
		"isAlreadyTranslated": true
	},
	"Failure": {
		"text": "Fallo",
		"isAlreadyTranslated": true
	},
	"Report Status": {
		"text": "Estado del informe",
		"isAlreadyTranslated": true
	},
	"Select Store Location": {
		"text": "Seleccione la ubicación de la tienda",
		"isAlreadyTranslated": true
	},
	"Payment Link Sent Successfully": {
		"text": "Enlace de pago enviado correctamente",
		"isAlreadyTranslated": true
	},
	"Address Line 1": {
		"text": "Dirección Línea 1",
		"isAlreadyTranslated": true
	},
	"Address Line 2": {
		"text": "Dirección Línea 2",
		"isAlreadyTranslated": true
	},
	"Incorrect IMEI/Serial Number": {
		"text": "IMEI/Número de serie incorrecto",
		"isAlreadyTranslated": true
	},
	"Please enter correct IMEI/Serial Number": {
		"text": "Introduzca el IMEI/Número de serie correcto",
		"isAlreadyTranslated": true
	},
	"Insufficent params for updation": {
		"text": "Parámetros insuficientes para la actualización",
		"isAlreadyTranslated": true
	},
	"Enter City": {
		"text": "Introduzca la ciudad",
		"isAlreadyTranslated": true
	},
	"Enter State/Province": {
		"text": "Introduzca Estado/Provincia",
		"isAlreadyTranslated": true
	},
	"Enter Postal Code": {
		"text": "ntroduzca el código postal",
		"isAlreadyTranslated": true
	},
	"Enter Apartment, Suite, Building": {
		"text": "Entrar en apartamento, suite, edificio",
		"isAlreadyTranslated": true
	},
	"Find Eligible Plans": {
		"text": "Buscar planes subvencionables",
		"isAlreadyTranslated": true
	},
	"No Eligible Plans": {
		"text": "No hay planes subvencionables",
		"isAlreadyTranslated": true
	},
	"Sorry! We couldn't find any eligible plans for your product.": {
		"text": "Lo sentimos. No hemos podido encontrar ningún plan elegible para su producto.",
		"isAlreadyTranslated": true
	},
	"Enter product purchase date": {
		"text": "Introduzca la fecha de compra del producto",
		"isAlreadyTranslated": true
	},
	"Enter product purchase cost": {
		"text": "Introduzca el coste de compra del producto",
		"isAlreadyTranslated": true
	},
	"Product Model": {
		"text": "Modelo de producto",
		"isAlreadyTranslated": true
	},
	"Search using min. 3 characters": {
		"text": "Buscar utilizando min. 3 caracteres",
		"isAlreadyTranslated": true
	},
	"Enter Street Address": {
		"text": "Introduzca la dirección",
		"isAlreadyTranslated": true
	},
	"Product Purchase Cost": {
		"text": "Coste de compra del producto",
		"isAlreadyTranslated": true
	},
	"Congratulations! Your product is eligible for this plan": {
		"text": "Enhorabuena. Su producto puede acogerse a este plan",
		"isAlreadyTranslated": true
	},
	"Upload Product Purchase Invoice": {
		"text": "Cargar factura de compra de producto",
		"isAlreadyTranslated": true
	},
	"Eligible Plans": {
		"text": "Planes admisibles",
		"isAlreadyTranslated": true
	},
	"choose file": {
		"text": "elegir archivo",
		"isAlreadyTranslated": true
	},
	"No file chosen": {
		"text": "Ningún archivo elegido",
		"isAlreadyTranslated": true
	},
	"Supported file formats: jpg, png, pdf, doc, docx": {
		"text": "Formatos de archivo compatibles: jpg, png, pdf, doc, docx",
		"isAlreadyTranslated": true
	},
	"Select Product Model to View Plans": {
		"text": "Seleccione el modelo de producto para ver los planos",
		"isAlreadyTranslated": true
	},
	"is required": {
		"text": "es necesario",
		"isAlreadyTranslated": true
	},
	"is invalid": {
		"text": "no es válido",
		"isAlreadyTranslated": true
	},
}

export default lang;