import React, { useState} from 'react';
import { Modal,Button } from 'sfy-react';
const UploadFileModal = (props) => {
	const {closeModal, uploadFile, acceptedFileTypes, firstModalTitle, browseButtonText,browseFileClassName, uploadModalClassName,
		selectedFile, setSelectedFile,localString,setFile,showUploadModal, setShowUploadModal,publicUrl,confirmUploafile,setConfirmUploafile} = props; 
	const [dragging, setDragging] = useState(false);

	const uploadConfirmFile =()=>{
		uploadFile()
	}
	const handleDragEnter = (event) => {
		event.preventDefault();
		setDragging(true);
	};

	const handleDragLeave = (event) => {
		event.preventDefault();
		setDragging(false);
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const deleteUpload = ()=>{
		setConfirmUploafile(false)
		setShowUploadModal(true)
	}
	const handleDrop = (event) => {
		event.preventDefault();
		setDragging(false);
		const file = event.dataTransfer.files[0];
		setFile(file)
		setConfirmUploafile(true)
		setSelectedFile(file);;
		closeModal();// Close the original modal
	};

	const handleFileChange = (event) => {
		let file = event.target.files[0];
		setFile(file)
		setConfirmUploafile(true)
		setSelectedFile(file);
		closeModal();// Close the original modal
	};

	const bytesToSize =(bytes)=> {
		let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes == 0) return 'n/a';
		let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		if (i == 0) return bytes + ' ' + sizes[i];
		return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
	};

	return (
		<div>
			{showUploadModal && <div
				className={`modal ${showUploadModal ? 'show' : ''}`}
				tabIndex='-1'
				role='dialog'
				onDragEnter={handleDragEnter}
				onDragLeave={handleDragLeave}
				onDragOver={handleDragOver}
				onDrop={handleDrop}
			>
				<Modal showModal={showUploadModal} closeModal={closeModal} showCloseBtn={true} className='upload-Modal bulkUploadModal'>
					<Modal.Header className='p0 border-none'>
						<h2 className='modal-title'>{firstModalTitle}</h2>
					</Modal.Header>
					<Modal.Body className='pt20'>
						<strong className=''>{localString("Note")}:</strong>
						<ol className='pl20'>
							<li>{localString('Only ‘.csv’ file format is supported.')}</li>
							<li>{localString('Maximum 10MB can be uploaded together')}</li>
							<li>{localString('Do not change the auto-populated values in the sample file download ')}</li>
						</ol> 
						<div className='modal-body mt20'>
							<div className='text-center'>{localString('Upload filled-in template(s) here')}</div>
							<div className='text-center pt6 pb6'>{localString('Drag and drop')}</div>
							<div className='text-center pb6'>{localString('or')}</div>
							<div className={`file-dropzone text-center overflow-anywhere ${dragging ? localString('dragging') : ''}`}>
								<label htmlFor='fileInput' className={`btn button-ghost ${browseFileClassName}`} >
									{browseButtonText}
								</label>
								<input
									// ref={fileRef}
									type='file'
									name='fileInput'
									id='fileInput'
									onChange={(e)=>handleFileChange(e)}
									accept={acceptedFileTypes} 
									style={{ display: 'none' }}
								/>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</div>}
			{
			confirmUploafile && 
			<Modal
				showModal={confirmUploafile}
				closeModal={() => setConfirmUploafile(false)}
				showCloseBtn={true}
				className={uploadModalClassName}
			>
				<Modal.Header className='p0 pb4'>
					<h4 className='modal-title'>Upload</h4>
				</Modal.Header>
				<Modal.Body>
					<div className='upload-section'>
						<div className='doc-upload'>
							<div className='row'>
								<div className='col-sm-1'>
									<img src={publicUrl + "/imgs/csvfile.png"}
										className='status-img' 
										alt="csv file" 
										width='34px' 
										height='36px'> 
									</img>
								</div>
								<div className='col-sm-9 pl30'>
									{localString(selectedFile && selectedFile.name)}
									{selectedFile && (
									<div className='' style={{ color: '#00000040' }}>
										{bytesToSize(selectedFile.size)}
									</div>
									)}
								</div>
								<div className='col-sm-2'>
									<img src={publicUrl + "/imgs/delete.png"}
										className='mt10 mb10' 
										onClick={deleteUpload}
										alt="csv file" 
										width='20px' 
										height='20px'> 
									</img>
								</div>
							</div>
						</div>
						<div className='file-dropzone text-center overflow-anywhere mt10 '>
							<Button className="button-small servify-button" handleOnClick={()=>uploadConfirmFile()}>{localString('Upload')}</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			
			}
		</div>
	);
};

export default UploadFileModal;
