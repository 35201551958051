import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input, Loader, Dropdown, Modal, setAutocomplete } from 'sfy-react';

export default function LocationAutoComplete(props) {
    const {
        apiCall, cid, csrid, event = '', gmap, value, handleOnChange = () => {}, handleSelection = () => {}, dependentState
    } = props;

	const autoCompleteRef = useRef(null);
	const [autoCompleteVal, setAutoCompleteVal] = useState(null);

    const callLoggingApi = (apiName, value) => {
		let reqObj = {
			apiName: apiName,
			value: value,
			key: gmap,
			event: event,
			identifiers: [{ 'ConsumerID': cid }]
		}
		if (csrid) {
			reqObj.identifiers.push({ 'ConsumerServiceRequestID' : csrid })
		}
		apiCall({
			url: 'addLogOfApi',
			data: reqObj,
			callBack: () => {},
		});
	}

	const handleLandmarkSelection = useCallback(
		(value) => {
            setAutoCompleteVal(value);
            value?.formatted_address && callLoggingApi('PlaceService.GetPlaceDetails', value.formatted_address);
		}, []
	)

    useEffect(() => {
		const autocomplete = setAutocomplete(autoCompleteRef.current, handleLandmarkSelection)
		return () => {
			window.google.maps.event.clearListeners(autocomplete, 'place_changed')
		}
	}, [handleLandmarkSelection])

    useEffect(() => {
        handleSelection(autoCompleteVal);
    }, [autoCompleteVal])

    return (
        <>
            <Input
                {...props}
                value={value}
                reference={autoCompleteRef}
                handleOnChange={(e) => {
                    handleOnChange(e);
                    callLoggingApi('AutocompletionService.GetPredictions', e.target.value);
                }}
            />
        </>
    )
}