import React, { useState,useEffect } from 'react';
import {Button,Checkbox, Dropdown, Table} from 'sfy-react';
import Swal from 'sweetalert2';
import { returnTimezone,displayError } from '../../../../../Utility/Utils';

const PartUniqueInput = (props)=>{
    const {localString, partsRequested, anyPartReturned, isFullBoxUnit, defectivePartsRequested, consumerServiceRequest, isRequestSFANeeded, faultArray, partcheck, repairComplete, 
        selectedFault, showFaultLoader, faultCode, setFaultCode, addToFaultArray, setSelectedFaultItems, removeFaultCode, setDisableAddBtn, disableAddBtn }=props;

	return(
        <>
            <div className='row'>
                <div className='col-sm-12'>
                    <form onSubmit={repairComplete}>
                        {
                            partsRequested && partsRequested.length > 0 && <div className='col-sm-12 marginL6'>
                                {
                                    anyPartReturned && <p>
                                        {localString("Handing over the")} {isFullBoxUnit? localString("full box unit"):  localString("parts")} {localString("to store.")}
                                    </p>
                                }
                            </div>
                        }
                        {
                            defectivePartsRequested && defectivePartsRequested.length > 0 && <div className='col-sm-12 checkbox-marginL'>
                                {
                                    defectivePartsRequested.map(part=>(
                                        part.statusDetails.Status == 'received'  && 

                                        <Checkbox
                                            value={partcheck}
                                            name='partcheck_'
                                            isDisabled={true}
                                        >
                                            {part.PartName ? part.PartName : part.Name} {part.RequiresSerialNumber && localString("Defective Serial No")}: {consumerServiceRequest.consumerProduct.ProductUniqueID}
                                        </Checkbox>
                                    ))
                                }
                            </div>
                        }
						<br></br>
						<hr></hr>
						{
							!isRequestSFANeeded  && <div className='row form-group-filter-request'>
								<div className='col-xs-9 parts-dropdown register-filter scrollable-dropdown-menu paddingL30'>
									<label>{localString("Select Fault Code / Cause Code based on the validated issues.")}</label>
                                    <Dropdown
                                        id='faultcode'
                                        value={faultCode['faultCodeText']}
                                        options={selectedFault}
                                        visitBy='value'
                                        showDownArrow={false}
                                        placeholder={ localString( "  Fault Code") + ' / ' + localString("Cause Code")}
                                        handleOnChange={(data) => {
                                            setFaultCode({...faultCode,['faultCodeText']:data.value});
                                            setSelectedFaultItems(data);
                                            setDisableAddBtn(false);
                                        }}
                                        customComponent={showFaultLoader && <span className='input-loader form-control-feedback form-control-feedback-padded loader-position'></span>}
                                    />
                                </div>
								<div class="col-xs-1 register-filter ">
									<Button className='btn button-servify add_part_btn' isDisabled={disableAddBtn} handleOnClick={()=>addToFaultArray()}>{localString('add')}</Button>
								</div>
							</div>
						}
						{
							faultArray && faultArray.length > 0 && <div className='row form-group-filter-request'>
								<div className='col-sm-12 marginL6'>
                                    <Table isResponsive>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.Heading>{localString("Fault Code")} / {localString("Cause Code")}</Table.Heading>
                                                <Table.Heading></Table.Heading>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {
                                                faultArray.length > 0 && faultArray.map(fault=>(
                                                    <Table.Row>
                                                        {fault.FaultDescription && <Table.Data>{fault.FaultDescription}</Table.Data>}
                                                        <Table.Data ><div className='col-sm-2 glyphicon glyphicon-minus-sign' onClick={()=>removeFaultCode(fault.FaultID)}></div></Table.Data>
                                                    </Table.Row>
                                                ))
                                            }
                                        </Table.Body>
                                    </Table>
								</div>
							</div>
                        } 
						<div className='marginT10'>
							<Button type="submit" className="btn button-servify pull-right" isDisabled={faultArray.length == 0 && !isRequestSFANeeded}>{localString("Save")}</Button>
						</div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default PartUniqueInput;
