import React, {useState} from 'react';
import './TabCollapse.scss';

const TabCollapse = (props) => {
    const [openTab, setOpenTab] = useState(false);
    const {
        headerText, date, issueCount, showIssueCount, onClickHandler = () => {}
    } = props;

    return (
        <div className={"collapsable-container " + (openTab ? "open" : "")}> 
            <div 
                className="collapse-header" 
                onClick={() => {
                    setOpenTab(!openTab);
                    onClickHandler(!openTab);
                }}
            >
                <span className="header-text">
                    <strong>{headerText}</strong> - {date}
                </span>
                {showIssueCount && <span className={"pull-right" + (issueCount > 0 ? " issue" : "")}>
                    <strong>{issueCount > 0 ? issueCount + (issueCount == 1 ? ' issue' : 'issues') : 'No issues'}</strong>
                </span>}
            </div>
            <div className="tab-content">
                {props.children}
            </div>
        </div>
    )
}

export default TabCollapse
