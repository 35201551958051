import React from 'react';
import {Modal} from 'sfy-react';
import ReplacementOptionInput from './ReplacementOptionInput';
import './ReplacementOptionModal.scss';

const ReplacementOptionModal=(props)=>{
    const {localString,showModal,closeModal,chooseOptionsMode, viewOptionsMode} = props;
		return (
		<>
            <Modal showModal={showModal}  closeModal={closeModal} showCloseBtn={true} className='replacement-Modal'>
                <Modal.Header>
                    <h4> {chooseOptionsMode ? localString("Choose replacement options") : viewOptionsMode ? localString("View replacement options") : localString("Provide replacement options")} </h4>
                </Modal.Header>
                <Modal.Body>
                    <ReplacementOptionInput {...props}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
	)
}
export default ReplacementOptionModal;



