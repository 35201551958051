import React,{useEffect, useState} from 'react';
import {Modal,Input,Button} from 'sfy-react';
import './BERInitiateRefundModal.scss'
const BERInitiateRefundModal = (props)=>{
    const {localString,showModal,closeModal,consumerServiceRequest,berRequestNext,nextStatus} = props;
    const [inputField,setInputField] = useState({
        refundAmount:consumerServiceRequest?.BER_REFUND_AMOUNT,
        // coveredAmount:8000 ,
        coveredAmount:consumerServiceRequest?.consumerProduct?.DepreciatedValue?.sumInsured ,
        deductedAccessoryCost:consumerServiceRequest?.DeductedAccessoriesCost
    })
    const [isDisableBtn,setIsDisableBtn] = useState(true)
    const initiateRefund = () =>{
        let data = {};
        if(inputField.refundAmount){
			data.RefundAmount = (+inputField.refundAmount) ? (+inputField.refundAmount) : undefined
		}
        berRequestNext(nextStatus,data);
        closeModal();
    }

    useEffect(()=>{
        if(inputField.coveredAmount >= inputField.refundAmount && inputField.refundAmount > 0){
            setIsDisableBtn(false)
        }else{
            setIsDisableBtn(true)
        }
    },[inputField.refundAmount])
    const refundInput = [
        {
			labelText:'Refund Amount',
            dataKey: 'refundAmount',
			showField:true,
            isDisabled:consumerServiceRequest.Status == 'BER payment requested',
			oninputFunc: (val) => {
				setInputField({...inputField,
					['refundAmount']:val,
				});
			}
		},
        {
			labelText:'Covered Amount',
            dataKey: 'coveredAmount',
			showField:true,
			customClass:'col-sm-3',
            isDisabled:true,
			oninputFunc: (val) => {
				setInputField({...inputField,
					['coveredAmount']:val,
				});
			}
		},
        {
            labelText:'Deducted Accessory Cost',
            dataKey: 'deductedAccessoryCost',
            showField:true,
            customClass:'col-sm-3',
            isDisabled:true,
            oninputFunc: (val) => {
                setInputField({...inputField,
                    ['deductedAccessoryCost']:val,
                });
            }
        }
    ]

    let consumerDetails = [
        {
            showConsumer: consumerServiceRequest.Status == 'BER payment requested' && consumerServiceRequest?.CONSUMER_LEGAL_NAME,
            labelText: 'Name',
            berPaymentData: consumerServiceRequest?.CONSUMER_LEGAL_NAME ,
            isDisabled: consumerServiceRequest.Status == 'BER payment requested'
        },
        {
            showConsumer: consumerServiceRequest.Status == 'BER payment requested' && consumerServiceRequest?.CONSUMER_LEGAL_EMAIL_ID,
            labelText: 'Email ID',
            berPaymentData: consumerServiceRequest?.CONSUMER_LEGAL_EMAIL_ID,
            isDisabled: consumerServiceRequest.Status == 'BER payment requested'
        },
    ]
    return(
        <div className='ber-modal'>
            <Modal showModal={showModal} closeModal={closeModal} showCloseBtn={true} className='Filter-Modal'>
                <Modal.Header>
                    <h2 className='refund-modal-title'> {localString("Are you sure you want to initiate refund?")} </h2>
                </Modal.Header>
                <Modal.Body>
                    <div className='refund-initiate-main'>
                        <div className='refund-initiate-input'>
                            {
                                consumerDetails && consumerDetails.map((consumer,index)=>(
                                    consumer.showConsumer && <div className='row mb10'> 
                                        <div className='col-sm-6'>
                                            <label className="text-capitalize">
                                                {localString(consumer.labelText)}
                                            </label>
                                        </div>
                                        <div className='col-sm-6 ber-refund-field'>
                                            <Input
                                                value={consumer.berPaymentData}
                                                key={index}
                                                handleOnChange={(e) => {
                                                }}
                                                autoComplete='off'
                                                // label={item.labelText}
                                                labelAlign='top'
                                                isDisabled={consumer.isDisabled}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                refundInput && refundInput.map((item,index)=>(
                                    item.showField && <div className='row mb10'>
                                        <div className='col-sm-6'>
                                            <label className="text-capitalize">
                                                {localString(item.labelText)}
                                            </label>
                                        </div>
                                        <div className='col-sm-6 ber-refund-field'>
                                            <Input
                                                value={inputField[item.dataKey]}
                                                key={index}
                                                handleOnChange={(e) => {
                                                    item.oninputFunc(e.target.value);
                                                }}
                                                autoComplete='off'
                                                // label={item.labelText}
                                                labelAlign='top'
                                                isDisabled={item.isDisabled}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='refund-button'>
                            <Button 
                                className="btn button-servify"
                                onClick={() => closeModal()}
                            >
                                {localString("Cancel")}
                            </Button>
                            <Button 
                                className="btn button-servify"
                                onClick={() =>initiateRefund() }
                                isDisabled={isDisableBtn}
                            >
                                {localString("Initiate Refund")}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default BERInitiateRefundModal;