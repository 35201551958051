import React, { useEffect, useState } from 'react';
import { Button, Input } from 'sfy-react';
import Swal from "sweetalert2";

import { checkNumberPattern, displayError } from '../../../../../Utility/Utils';

export default function UpdateKGBInput(props) {
    const {
        localString, apiCall, setLoader, consumerServiceRequest, getRequestById, closeModal
    } = props;

    const [KGBNumber, setKGBNumber] = useState('');
    const [KGBImei, setKGBImei] = useState('');
    const [warrantyCheckDetails, setWarrantyCheckDetails] = useState(null);
    const [warrantyCheckDetailsForIMEI, setWarrantyCheckDetailsForIMEI] = useState(null);

    const updateKGB = (additionalData) => {
        let kgbNumber = KGBNumber.trim().toUpperCase();
		let kgbIMEI = KGBImei.trim();
		let regex = /^[A-Z0-9-]*$/;
		if (!regex.test(kgbNumber) || (warrantyCheckDetails && warrantyCheckDetails.AdditionalSupportedLength.indexOf(kgbNumber.length) == -1)) {
            Swal.fire({  
                icon: 'error',  
                text: localString('Please enter valid KGB Number'),
				confirmButtonText: localString('OK')
            });
			return;
		}
		if (kgbIMEI) {
			if(!checkNumberPattern(kgbIMEI) || (warrantyCheckDetailsForIMEI && (kgbIMEI.length !== warrantyCheckDetailsForIMEI.Length))){
                Swal.fire({  
                    icon: 'error',  
                    text: localString('Please enter valid KGB IMEI Number'),
                    confirmButtonText: localString('OK')
                });
                return;
			}
		}

        if(warrantyCheckDetails.AdditionalSupportedLength.indexOf(kgbNumber.length) > -1) {
			let data = {
				ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
				kgbNumber: kgbNumber,
				isMailinRequest: consumerServiceRequest.isMailinRequest,
				updateMailinKgb: consumerServiceRequest.updateMailinKgb,
				kgbIMEI: kgbIMEI
			};
			if(additionalData) {
				data = {...data, ...additionalData};
			}
			setLoader(true);
            apiCall({
                url: 'updateKgbForMailin',
                data: data,
                callBack: (result) => {
                    setLoader(false);
                    if (result.success) {
                        getRequestById();
                        closeModal();
                    }else {
                        if(result.enableUserConsent) {
                            Swal.fire({  
                                icon: 'error',  
                                text: localString(displayError(result)),  
                                showCancelButton: true,
                                closeOnConfirm: true,
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                cancelButtonText: localString("Cancel"),
                                confirmButtonText: localString("Proceed without GSX update")
                            }).then(function(result) {
                                if (result.isConfirmed) {
                                    updateKGB({isGSXBypass: true});
                                }
                            });
                        } else {
                            Swal.fire({  
                                icon: 'error',  
                                text: localString(displayError(result)),  
                                confirmButtonText: localString('OK')
                            });
                        }
                    }
                }
            });
		}else {
            Swal.fire({  
                icon: 'error',  
				text: localString('Please Fill Mandatory Fields'),
                confirmButtonText: localString('OK')
            });
			return;
		}

    }

    const getWarrantyCheckDetails = () => {
        let data = {
            BrandID: consumerServiceRequest?.consumerProduct?.BrandID || undefined
        };
        setLoader(true);
        apiCall({
            url: 'getWarrantyCheckDetails',
            data: data,
            callBack: (result) => {
                setLoader(false);
                if (result.success) {
                    if(result.data && result.data.validationConfig){
                        let validationConfig = result.data.validationConfig;
                        let warrantyCheckDetailsArr = validationConfig.filter(item => (item.Type == 'SerialNumber'));
                        let warrantyCheckDetailsIMEIArr = validationConfig.filter(item => (item.Type == 'IMEI'));
                        warrantyCheckDetailsArr && warrantyCheckDetailsArr.length && setWarrantyCheckDetails(warrantyCheckDetailsArr[0]);
                        warrantyCheckDetailsIMEIArr && warrantyCheckDetailsIMEIArr.length && setWarrantyCheckDetailsForIMEI(warrantyCheckDetailsIMEIArr[0]);
                    }
                }else {
                    Swal.fire({  
						icon: 'error',  
						text: localString(displayError(result)),  
					});
                }
            }
        });
    }

    useEffect(() => {
        getWarrantyCheckDetails();
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-sm-4">
                    <label className="text-capitalize">
                        {localString("KGB Number")}*
                    </label>
                    <div>
                        <Input
                            value={KGBNumber}
                            handleOnChange={(e) => setKGBNumber(e.target.value)}
                            className={{
                                inputContainer: 'lookUpTextBox'
                            }}
                            maxLength={12}
                        />
                    </div>
                </div>
                <div className="col-sm-4">
                    <label className="text-capitalize">
                        {localString("KGB IMEI Number")}
                    </label>
                    <div>
                        <Input
                            value={KGBImei}
                            handleOnChange={(e) => setKGBImei(e.target.value)}
                            className={{
                                inputContainer: 'lookUpTextBox'
                            }}
                            maxLength={17}
                        />
                    </div>
                </div>
            </div>
            <div className='pull-right mt-15'>
                <Button 
                    type='button'
                    className="btn button-servify"
                    onClick={() => updateKGB()}
                >
                    {localString("Save")}
                </Button>
            </div>
        </>
    );
}