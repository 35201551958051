import React, { useState } from 'react'
import {Table,Button,Pagination} from 'sfy-react';
import EscalationFlag from '../../../components/EscalationFlag/EscalationFlag';
import { returnLocalDateTime,returnLocalSlot,SERVICETYPE, getStatusString } from '../../../Utility/Utils';
import './RequestList.scss';
const ConsumerServiceRequestList = (props) => {
    const {headings,setFlagRequest,localLoader,request,groupRequestListData,baseUrl,currentTabCount,localString,accessFn,hideNoDataMessage,publicUrl,setCurrentPage,pagination,checkExternalReferenceCheck,isDiagnosisFlow,currentCount,flagRequest,openflagrequest,} = props;

    return (
		<>
            {accessFn( 'REQUEST_LIST_VIEW')  && <div className="content-request time-period">
                <div className='request-list'>
                    <div className="request-padding-top">
                        {
                            localLoader  && <span className='input-loader request-loader form-control-feedback form-control-feedback loader-position'></span>
                        }
                        {(request && request.length == 0) && !hideNoDataMessage && <div className="text-center consumer-nodata">
                            <div className="text-center requests-empty-state">
                                {localString("No Data Available")}
                            </div>
                        </div>}
                        {
                            request && request.length !=0 &&
                                <div className="table-responsive table">
                                    <Table isResponsive>
                                        <Table.Header >
                                            <Table.Row >
                                                <Table.Heading></Table.Heading>
                                                    { headings && headings.length>0 && headings.map((head, index) => {
                                                        return (
                                                            head.showHeading && <Table.Heading key={index} className='margin-request'>{head.title}</Table.Heading>
                                                        )
                                                    })}
                                                    <Table.Heading></Table.Heading>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {
                                            groupRequestListData && groupRequestListData.map((item,index)=>{
                                                return(
                                                    item && item.title ?
                                                        <Table.Row  className={accessFn( 'REQUEST_DETAILS') && !item.title ? 'view-requests': ''}>
                                                            <Table.Data otherProps={{colSpan:"9"}} className="group-date group-date-width" ><strong >{localString(item.title)}</strong></Table.Data>
                                                        </Table.Row>
                                                        :
                                                        <Table.Row>
                                                            <Table.Data>
                                                                <div className="flag-icon flag-pointer"   onClick={()=>openflagrequest(item)} >
                                                                    {
                                                                        !item.escalationsCount ? <img  src={publicUrl+`/imgs/Flag-not_selected.png`} alt=""/>
                                                                        // : <img  src={publicUrl+`/imgs/Flag-selected.png`} alt=""/>
                                                                        : <EscalationFlag color={item.escalations?.[0]?.EscalationSource?.EscalationFlagColor}/>
                                                                    }
                                                                </div>
                                                            </Table.Data>
                                                            <Table.Data>
                                                                {
                                                                    item.IsUrgent ? <img src={publicUrl+`/imgs/flag.png`}/> :""
                                                                }
                                                                {
                                                                    item.openTaskCount ? <span className="task-active glyphicon glyphicon-star"></span>:""
                                                                }
                                                                { checkExternalReferenceCheck(item.Source) ? (item.ExternalReferenceID || item.IntegrationRefID || item.ReferenceID) : item.ReferenceID}
                                                                { item.isICICIRequest && <span className = "tag" > ICICI </span>}
                                                                {
                                                                    item.escalationsCount ?
                                                                    <div 
                                                                        className="flag-tag"
                                                                        style={item.escalations?.[0]?.EscalationSource?.EscalationFlagColor ? { backgroundColor: item.escalations?.[0]?.EscalationSource?.EscalationFlagColor } : {}}
                                                                    >
                                                                        { item.escalations[0].EscalationSource?item.escalations[0].EscalationSource.SourceName:item.escalations[0]?.EscalationType?.EscalationName} 
                                                                        { item.escalations[0] && item.escalations[0].EscalationSourceMedia && item.escalations[0].EscalationSourceMedia.MediaName}
                                                                        { item.escalationsCount-1 > 0 ?"+":""}{item.escalationsCount-1 > 0 ?item.escalationsCount-1:""}
                                                                    </div>:""
                                                                }
                                                            </Table.Data>
                                                                {
                                                                    (!(accessFn('VIEW_QUEUE_DROPOFF')) && !(accessFn('RECYCLE_HUB_ACCESS'))) && ((!(accessFn('REASSIGN_JOBS')) && !(accessFn('REPAIR_DEVICE')) && !(accessFn('QC_DEVICE'))) || accessFn('ACCEPT_RETURN_DEVICE')) &&
                                                                    <Table.Data>{item.Name || '-'} <br></br> {item.MobileNo || '-'}</Table.Data>
                                                                }
                                                            <Table.Data>
                                                                {item.ProductName}<br></br>{item.ProductSubCategory}
                                                            </Table.Data>
                                                            <Table.Data>
                                                                {item.ProductUniqueID || '-' } <br></br>{item.AlternateUniqueKey ? item.AlternateUniqueKey : ''}
                                                            </Table.Data>
                                                            <Table.Data>
                                                                {item.Type} <br></br>{getStatusString(item.Status, item.ServiceTypeID, accessFn)}
                                                            </Table.Data>
                                                                {
                                                                    accessFn('TASK_MANAGEMENT')  &&<Table.Data>
                                                                        <>
                                                                            <div className= "row">{item.openTaskCount ? item.openTaskCount:'0'} {localString('open')} </div>
                                                                            <div className = "row">{item.closeTaskCount ? item.closeTaskCount:'0'} {localString('closed')}</div>
                                                                        </>
                                                                    </Table.Data>
                                                                }
                                                            <Table.Data>
                                                                <div className='margin-request'>
                                                                    {
                                                                        !isDiagnosisFlow && returnLocalDateTime(item.ScheduledDateTime ?item.ScheduledDateTime:item.CreatedDate,'DD-MM-YYYY')
                                                                    } <br></br>
                                                                    {
                                                                        returnLocalSlot(item.ScheduledFromTime)} - {returnLocalSlot(item.ScheduledToTime)}
                                                                </div>
                                                                    {isDiagnosisFlow && <span>{item.Model || '-'}</span>}
                                                                    {item.ServiceTypeID === SERVICETYPE.TRADE_IN_DROPOFF && <span> - </span>}
                                                            </Table.Data>
                                                                {
                                                                    ['SkyworthDashboard','BoseDashboard'].indexOf(item.Source)>-1 && <Table.Data>
                                                                            {item.ServiceLocationName || '-'}
                                                                    </Table.Data>
                                                                }
                                                            <Table.Data>
                                                                {returnLocalDateTime(item.CreatedDate,'DD-MM-YYYY')}
                                                            </Table.Data>  
                                                            <Table.Data>
                                                                {accessFn( 'REQUEST_DETAILS') && <a href={baseUrl+'/servicerequests/view?csrid='+item.ConsumerServiceRequestID} target="_blank">
                                                                <Button  text={localString('View')} className='btn button-servify '></Button></a>}
                                                            </Table.Data>   
                                                        </Table.Row>
                                                    )
                                            })
                                        }
                                        </Table.Body>
                                    </Table>
                                    {
                                        
                                        pagination.numberOfPages > 1 && <Pagination 
                                        itemsPerPage={pagination.itemsPerPage} 
                                        pages={pagination.numberOfPages} 
                                        activePage={pagination.pageNo} 
                                        paginate={(pageNumber) => { setCurrentPage(pageNumber) }}
                                        paginationInfo={request.length+" out of " + currentTabCount }
                                    />
                                    }
                                </div>
                            }
                    </div>
                </div>
            </div>}
		</>
	)
}
export default ConsumerServiceRequestList;