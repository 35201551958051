import React from 'react';
import {Button} from 'sfy-react';

const ActionButtonArray = (props) => {
    const {buttonArray} = props;
    return(
        <div className="content">
           {buttonArray && buttonArray.length>0 && buttonArray.map((button, index) => {
				return(
                    <Button handleOnClick={button.onClickAction} type='button' classes='button-ghost pull-right' text={button.text} isDisabled={button.isDisabled}></Button>
				)	
			})}
        </div>
    )
}
export default ActionButtonArray;
