import React, { useEffect, useState } from "react";
import { Modal, Input, Button } from "sfy-react";
import { cloneDeep } from "lodash";
import "./ProvisionalEstimate.scss";

const DiscountModal = (props) => {
	const {
		setModal,
		showModal,
		closeModal,
		localString,
		// addDiscount,
		// updateDiscount,
		currencySymbol,
		updateChargesCall,
		provisionalCharges,
		consumerServiceRequest
	} = props;

	const [charges, setCharges] = useState(provisionalCharges.charges || [])
 
	const addDiscount = (e, index) => {
		let discountVal = e.target.value;
		let totalPayable = parseFloat(provisionalCharges.totalAmountWithTax);

		for (let i = 0; i < charges.length; i++) {
            if (['ServiceCharge', 'Part', 'Other'].indexOf(charges[i].Type) > -1 && charges[i].BaseCharge != charges[i].WarrantyConcession && charges[i].BaseCharge > 0) {
                if (i != index && (!charges[i].Discount || charges[i].Discount == 0)) {
                    totalPayable = totalPayable - charges[i].Discount;
                }
                else if (i == index && discountVal) {
                    totalPayable = totalPayable + discountVal;
                }
            }
		}
		let chargeValue = charges[index].MRP;
		let arr = cloneDeep(charges);

		if((discountVal?.trim() == '') || isNaN(discountVal)) {
			arr[index].Discount = 0;
			arr[index].finalCharge = arr[index].MRP;
		}

		if(Number(discountVal) <= chargeValue) {
			arr[index].Discount = discountVal;
			arr[index].finalCharge = (chargeValue - discountVal)//.toFixed(2);
		}
		// setCharges(arr);
		setCharges(arr)
	}

	const updateDiscount = function () {
		let updatedCharges = [];
		charges && charges.length && charges.map((charge) => {
			let obj = {...charge};
			obj.Discount = parseFloat(charge?.Discount);
			obj.update = true;
			delete obj.finalCharge;
			updatedCharges.push(obj);
		})
		let data = {
			ConsumerServiceRequestID: consumerServiceRequest.ConsumerServiceRequestID,
			ServiceTypeID: consumerServiceRequest.ServiceTypeID,
			PartnerServiceLocationID: consumerServiceRequest.PartnerServiceLocationID,
			updatedCharges: updatedCharges,
		};
		updateChargesCall(data, setModal((prevData) => ({ ...prevData, showDiscountModal: false })));
		// setModal((prevData) => ({ ...prevData, showDiscountModal: false }));
	};

	const finalChargeKeyCheck = (charge) => {
		return charge && (charge.hasOwnProperty('finalCharge')) ? Number(charge.finalCharge).toFixed(2) : charge.TotalAmount.toFixed(2);
	}

	return (
		<>
			<Modal
				showModal={showModal}
				closeModal={closeModal}
				showCloseBtn={true}
				className="Filter-Modal full-screen-modal"
			>
				<Modal.Header>
					<h2> {localString("Discount")} </h2>
				</Modal.Header>
				<Modal.Body>
					<div style={{padding: '10px 40px'}}>
						<div className="row">
							<table className="table table-bordered table-condensed table-striped">
								<thead>
									<tr>
										<th width="15%">
											{localString("Charge Name")}
										</th>
										<th width="15%">
											{localString("Charge Value")} (
											<span
												dangerouslySetInnerHTML={{
													__html: currencySymbol,
												}}
											></span>
											)
										</th>
										<th width="15%">
											{localString("Tax")}{" "}
										</th>
										<th width="15%">
											{localString("MRP")}{" "}
											<span
												dangerouslySetInnerHTML={{
													__html: currencySymbol,
												}}
											></span>
										</th>
										<th width="15%">
											{localString("Discount")}{" "}
											<span
												dangerouslySetInnerHTML={{
													__html: currencySymbol,
												}}
											></span>
										</th>
										<th width="15%">
											{localString("Final Price")} (
											<span
												dangerouslySetInnerHTML={{
													__html: currencySymbol,
												}}
											></span>
											)
										</th>
									</tr>
								</thead>
								<tbody>
									{charges &&
										charges.length > 0 &&
										charges.map(
											(charge, index) =>
												["ServiceCharge", "Part", "Other"].indexOf(charge.Type) > -1 &&
												(charge.BaseCharge != charge.WarrantyConcession) &&
												charge.BaseCharge > 0 && (
													<tr
														key={charge.Name + index}
													>
														<td>{charge.Name}</td>
														<td>
															{charge.BaseCharge}
														</td>
														<td>
															{charge.TaxArray &&
																(charge.TaxArray.length > 0) &&
																charge.TaxArray.map((tax,id) => (
																		<div
																			className="col-md-12"
																			key={
																				tax.TaxDisplay +
																				id +
																				index
																			}
																		>
																			<div className="row">
																				<div className="col-md-6">
																					{
																						tax.TaxDisplay
																					}
																				</div>
																				<div className="col-md-6">
																					{
																						tax.BaseChargeTax
																					}
																				</div>
																			</div>
																		</div>
																	)
																)}
														</td>
														<td>
															{charge.MRP.toFixed(2)}
														</td>
														<td>
															<Input
																id={"discount_" +index}
																inputType="text"
																value={charge.Discount}
																handleOnChange={(e) => addDiscount(e, index)}
															/>
														</td>
														<td
															id={"finalCharge_" +index}
														>
															{finalChargeKeyCheck(charge)}
															{/* {charge && charge.finalCharge ? Number(charge.finalCharge).toFixed(2) : 0} */}
															{/* {charge.TotalAmount.toFixed(2)} */}
														</td>
													</tr>
												)
										)}
								</tbody>
							</table>
						</div>
						{/* <div className="row">
							<div className="col-sm-11">
								<Button
									type="button"
									classes="button-ghost"
									className="float-right"
									handleOnClick={updateDiscount}
								>
									{localString("Add Discount")}{" "}
								</Button>
							</div>
						</div> */}
						<div className="row">
							<div className="col-sm-12">
								<Button 
									className = "btn button-servify-solid float-right" 
									onClick={() => updateDiscount()}
								>
									{localString('Add Discount')} 
								</Button>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
};

export default DiscountModal;
